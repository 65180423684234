import React from "react";
import { Box } from "@mui/material";
import Sidebar from "../components/Sidebar";
import HomePage from "./HomePage/HomePage";
import Footer from "../components/Footer";

function Home() {
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar></Sidebar>
      <div>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          className="mainStructurePage pt-0px"
        >
          <HomePage></HomePage>
        </Box>
        <Footer></Footer>
      </div>
    </Box>
  );
}

export default Home;
