import { React, useState, useEffect } from "react";
import {
  IconButton,
  Card,
  CardContent,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

// icons import
import peopleIcon from "../../assets/images/homepageIcons/people.svg";
import deadBodyIcon from "../../assets/images/homepageIcons/dead-body.svg";
import medicalIcon from "../../assets/images/homepageIcons/pills-bottle.svg";
import searchIcon from "../../assets/images/homepageIcons/search.svg";
import redArrowIcon from "../../assets/images/homepageIcons/reduparrow.svg";
import greenDownArrowIcon from "../../assets/images/homepageIcons/greendownarrow.svg";
import lightRedUpArrowIcon from "../../assets/images/homepageIcons/lightreduparrow.svg";
import targetMainIcon from "../../assets/images/homepageIcons/target.svg";
import infoIcon from "../../assets/images/homepageIcons/infoicon.svg";
import coverageIcon from "../../assets/images/homepageIcons/medical.svg";
import imgUrl from "../../assets/images/imgUrl";
import { useLanguage } from "../../contexts/LanguageContext";
import translations from "../../config/hometranslation";

// leaflet
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  GeoJSON,
} from "react-leaflet";
import L from "leaflet";
import countrygeojson from "../../config/countrygeojson";

function HomePage() {
  const { language } = useLanguage();
  const [countrySelect, setCountrySelect] = useState(0);
  const [geoJson, setGeoJson] = useState();

  const t = (key) => translations[language][key] || key;

  const handleCountrySelectChange = (event) => {
    setCountrySelect(event.target.value);
    setGeoJson(countrygeojson[event.target.value]);
  };

  const onEachFeature = (feature, layer) => {
    layer.bindPopup(feature.properties.name);
  };

  const confirmedStyle = (feature) => ({
    color: "#ff7800",
    weight: 2,
    opacity: 0.5,
    fillOpacity: 0.8,
  });

  return (
    <div className="homePageCardSubSection">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} className="rightSideCard">
          <Card className="h-100">
            <CardContent>
              <Typography className="cardHeading">
                {t("countriesInDRAF")}
              </Typography>
              <div className="mapSection">
                {geoJson && (
                  <MapContainer
                    className="markercluster-map"
                    style={{ height: "500px", width: "100%" }}
                    scrollWheelZoom={false}
                    center={[1, 15]}
                    zoom={3}
                    key={`map-${countrySelect}`}
                  >
                    {/* <TileLayer
                      url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
                      attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    /> */}
                    <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />

                    <GeoJSON
                      onEachFeature={onEachFeature}
                      style={confirmedStyle}
                      key={`map-${countrySelect}`}
                      data={geoJson}
                    />
                  </MapContainer>
                )}
                {geoJson === undefined && (
                  <MapContainer
                    className="markercluster-map"
                    style={{ height: "500px", width: "100%" }}
                    scrollWheelZoom={false}
                    center={[1, 15]}
                    zoom={3}
                    key={`map`}
                  >
                    {/* <TileLayer
                      url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
                      attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    /> */}
                    <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
                  </MapContainer>
                )}
              </div>
              <div className="text-left selectFieldSection">
                <Card className="w-50">
                  <CardContent>
                    <Typography className="text-left">
                      {t("selectCountry")}
                    </Typography>
                    <div className="d-flex justify-content-center align-items-center mt-10px">
                      <FormControl fullWidth className="countrySelectField">
                        <Select
                          labelId="demo-country-select-label"
                          id="demo-country-select"
                          value={countrySelect}
                          label=""
                          onChange={handleCountrySelectChange}
                        >
                          <MenuItem value={10} disabled>
                            Select country
                            {/* Placeholder text or label for the initial option */}
                          </MenuItem>
                          <MenuItem value={0}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.globeLogo}></img>
                              <span className="ml-10px countryName">
                                Global
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={20}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.cameroonLogo}></img>
                              <span className="ml-10px countryName">
                                Cameroon
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={30}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.beninLogo}></img>
                              <span className="ml-10px countryName">Benin</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={40}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.rdcLogo}></img>
                              <span className="ml-10px countryName">RDC</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={50}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.congoLogo}></img>
                              <span className="ml-10px countryName">Congo</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={60}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.nigerLogo}></img>
                              <span className="ml-10px countryName">Niger</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={70}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.burkinaLogo}></img>
                              <span className="ml-10px countryName">
                                Burkina Faso
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={80}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.guineeLogo}></img>
                              <span className="ml-10px countryName">
                                Guinée
                              </span>
                            </div>
                          </MenuItem>{" "}
                          <MenuItem value={90}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.burundiLogo}></img>
                              <span className="ml-10px countryName">
                                Burundi
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={100}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.togoLogo}></img>
                              <span className="ml-10px countryName">Togo</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={101}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.maliLogo}></img>
                              <span className="ml-10px countryName">Mali</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={102}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.mauritanieLogo}></img>
                              <span className="ml-10px countryName">
                                Mauritanie
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={103}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.chadLogo}></img>
                              <span className="ml-10px countryName">Chad</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={104}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.senegalLogo}></img>
                              <span className="ml-10px countryName">
                                Sénégal
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={105}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.gabonLogo}></img>
                              <span className="ml-10px countryName">Gabon</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={106}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.centrafriqueLogo}></img>
                              <span className="ml-10px countryName">
                                Centrafrique
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={107}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.coteLogo}></img>
                              <span className="ml-10px countryName">
                                Côte d’Ivoire
                              </span>
                            </div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4} className="leftSideCard">
          <Card>
            <CardContent className="pb-0px">
              <div className="d-flex justify-content-start align-items-center">
                <IconButton
                  variant="contained"
                  className="lightblueButton pb-0px"
                >
                  <img src={peopleIcon}></img>
                </IconButton>
                <Typography className="leftCardHeading">
                  {t("estimatedTBCases")}
                </Typography>
              </div>
              <div className="d-flex justify-space-evenly align-items-center mt-10px">
                <Typography className="indicatorCount">23,46</Typography>
                <img src={redArrowIcon}></img>
                <p className="indicatorDate">
                  <span className="indicatorSubCount">+2,489</span> {t("since")}
                  <br></br>
                  {t("lastYear")}
                </p>
              </div>
            </CardContent>
          </Card>
          {/* <Card className="mt-20px">
            <CardContent className="pb-0px">
              <div className="d-flex justify-content-start align-items-center">
                <IconButton
                  variant="contained"
                  className="lightblueButton lightredButton"
                >
                  <img src={deadBodyIcon}></img>
                </IconButton>
                <Typography className="leftCardHeading">
                  {t("estimatedDeaths")}
                </Typography>
              </div>
              <div className="d-flex justify-space-evenly align-items-center mt-10px">
                <Typography className="indicatorCount">943</Typography>
                <img src={greenDownArrowIcon}></img>
                <p className="indicatorDate">
                  <span className="indicatorSubCount color-green">-104</span>{" "}
                  {t("since")}
                  <br></br>
                  {t("lastMonth")}
                </p>
              </div>
            </CardContent>
          </Card> */}
          <Card className="mt-20px">
            <CardContent className="pb-0px">
              <div className="d-flex justify-content-start align-items-center">
                <IconButton
                  variant="contained"
                  className="lightblueButton lightorangeButton"
                >
                  <img src={coverageIcon}></img>
                </IconButton>
                <Typography className="leftCardHeading">
                  {t("estimatedDrugResistant")}
                </Typography>
              </div>
              <div className="d-flex justify-space-evenly align-items-center mt-10px">
                <Typography className="indicatorCount">5,043</Typography>
                <img src={lightRedUpArrowIcon}></img>
                <p className="indicatorDate">
                  <span className="indicatorSubCount">+104</span> {t("since")}
                  <br></br>
                  {t("lastYear")}
                </p>
              </div>
            </CardContent>
          </Card>
          <Card className="mt-20px">
            <CardContent className="pb-0px">
              <div className="d-flex justify-content-start align-items-center">
                <IconButton variant="contained" className="lightblueButton">
                  <img src={medicalIcon}></img>
                </IconButton>
                <Typography className="leftCardHeading">
                  {t("nationalDetectionRate")}
                </Typography>
              </div>
              <div className="d-flex justify-space-evenly align-items-center mt-10px">
                <Typography className="indicatorCount">67%</Typography>
                <img src={greenDownArrowIcon}></img>
                <p className="indicatorDate">
                  <span className="indicatorSubCount color-green">-104</span>{" "}
                  {t("since")}
                  <br></br>
                  {t("lastMonth")}
                </p>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-10px ">
        <Grid item xs={12} lg={6}>
          <Card className="defineHomeCardHeight">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Typography className="cardHeaders">
                    {t("tbAndHIVEstimates")}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography className="cardHeaders">
                    {t("tbAndHIVDeaths")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="pt-10px">
                <Grid item xs={7}>
                  <Typography className="cardCounts">6,032</Typography>
                </Grid>
                <Grid item xs={5} className="border-left-orange">
                  <Typography className="cardCounts">12,784</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card className="defineHomeCardHeight d-flex align-items-center">
            <CardContent className="pb-0px w-100">
              <div className="d-flex justify-content-start align-items-center">
                <IconButton variant="contained" className="lightOrangeButton">
                  <img src={targetMainIcon}></img>
                </IconButton>
                <div className="ml-10px">
                  <Typography className="cardHeaders ">
                    {t("unhmlScoreCard")}
                  </Typography>
                  <Typography className="cardCounts text-left mt-10px">
                    7.6/10
                  </Typography>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <img src={infoIcon} className="infoImg"></img>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container className="mt-10px cardParaComponent">
        <Grid item xs={12} lg={12}>
          <Card>
            <CardContent className="pb-16px">
              <div className="text-left">
                <Typography className="cardHeading"> DRAF - TB</Typography>
                <p>{t("drafTbContent1")}</p>
                <p>{t("drafTbContent2")}</p>
                <p>{t("drafTbContent3")}</p>
                <p className="mb-0px">{t("drafTbContent4")}</p>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default HomePage;
