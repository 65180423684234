const countrygeojson = {
  20: {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: { GID_0: "CMR", COUNTRY: "Cameroon" },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [9.839321136000024, 2.631871938000131],
              [9.840205192000155, 2.636023760000114],
              [9.840862273000084, 2.637599946000137],
              [9.841423989000191, 2.639461756000173],
              [9.842220307000162, 2.641610622000144],
              [9.84249782500001, 2.643328190000148],
              [9.842160224000054, 2.644757272000106],
              [9.842392922000158, 2.645854951000047],
              [9.843051911000032, 2.647097349000035],
              [9.844085694000057, 2.649152040000104],
              [9.845210074000022, 2.652398825000091],
              [9.84591293200009, 2.654452086000049],
              [9.845759392000161, 2.657264471000133],
              [9.845891953000034, 2.659506083000167],
              [9.845886231000065, 2.66093659500001],
              [9.846400260000053, 2.662798167000062],
              [9.846725463000041, 2.664277792000178],
              [9.846715927000162, 2.666518689000043],
              [9.846376419000137, 2.668329001000075],
              [9.846366883000087, 2.670665503000123],
              [9.84645652800009, 2.672000646000015],
              [9.847254753000186, 2.673911333000092],
              [9.847954750000099, 2.676536799000076],
              [9.849272727000141, 2.678831101000014],
              [9.849833488000115, 2.680692911000051],
              [9.850207328000124, 2.682077408000168],
              [9.850440025000125, 2.68312716500003],
              [9.851240159000042, 2.684561015000043],
              [9.852139474000126, 2.684803248000094],
              [9.85308265599997, 2.686142444000041],
              [9.854302406000045, 2.689246894000064],
              [9.855338096000082, 2.69092011500004],
              [9.856284141000117, 2.691448690000129],
              [9.857180595000045, 2.6925013070001],
              [9.85948085800004, 2.697946787000035],
              [9.859852790000048, 2.699712515000101],
              [9.860081673000082, 2.701716186000056],
              [9.859740257999988, 2.704051018000143],
              [9.859399796000048, 2.706004619000055],
              [9.859107971000185, 2.707719802000099],
              [9.85910129500013, 2.709341048000169],
              [9.858190537000098, 2.711578131000067],
              [9.856853485000158, 2.713956595000013],
              [9.856749535000063, 2.716006278000066],
              [9.85731506400009, 2.716771604000144],
              [9.859582900000135, 2.718831778000094],
              [9.861857414000042, 2.719032287000061],
              [9.863322258000096, 2.720325948000095],
              [9.86469078000016, 2.72185754700007],
              [9.864827156000047, 2.723145485000032],
              [9.865438462000043, 2.724387884000066],
              [9.866902351000078, 2.725776910999969],
              [9.868412972000101, 2.727499962000024],
              [9.868740081999988, 2.728550434000056],
              [9.869351388000155, 2.72969746699999],
              [9.870392799000058, 2.730226279000078],
              [9.870573043000036, 2.732420682000168],
              [9.871563912000056, 2.733569145999979],
              [9.872513771000058, 2.733191728],
              [9.873744964000025, 2.733578444000045],
              [9.874353410000083, 2.735393047000173],
              [9.874487876000046, 2.737396478000107],
              [9.874670982000112, 2.738923072000148],
              [9.87436771300014, 2.74321317800019],
              [9.873932838000144, 2.745070935000172],
              [9.873970984999971, 2.74721670100007],
              [9.874299050000047, 2.748219490000167],
              [9.875101090000158, 2.749081373000138],
              [9.87571716400015, 2.749322414000119],
              [9.87661647800013, 2.749564648000103],
              [9.87713050800005, 2.751474142000177],
              [9.877312661000076, 2.753239155000074],
              [9.877688407999983, 2.754003764],
              [9.877725601000122, 2.756483555000102],
              [9.877293587000111, 2.757721425000057],
              [9.876525878999985, 2.75967287900005],
              [9.875947952000047, 2.761768579999966],
              [9.875889779000147, 2.764199972000085],
              [9.876028061, 2.765202046000013],
              [9.876165390000097, 2.766251565000118],
              [9.876204491000067, 2.768397570000104],
              [9.876910210000119, 2.769687891000046],
              [9.877665520000051, 2.770406485000137],
              [9.87780380200013, 2.771217823000143],
              [9.878653526000107, 2.772270442000092],
              [9.878930092000076, 2.773940563000167],
              [9.87954521100005, 2.77427697100012],
              [9.879825590999985, 2.775279523000108],
              [9.880438805000097, 2.776140451000117],
              [9.880385400000023, 2.777427911000075],
              [9.881934165000189, 2.781153918000086],
              [9.882638931000088, 2.782730342000093],
              [9.883772851000117, 2.783689022000033],
              [9.884567261000086, 2.786410570000157],
              [9.885313988000178, 2.789036275000058],
              [9.88634681700006, 2.791568041000119],
              [9.887616157000139, 2.794053078000104],
              [9.889170647000128, 2.796491862000096],
              [9.890491486000087, 2.798071146000041],
              [9.892242431000113, 2.799032689000114],
              [9.892089844000168, 2.801273108000089],
              [9.891121863000137, 2.805894137000109],
              [9.890056611000091, 2.810896159000095],
              [9.887900352000088, 2.81598877800019],
              [9.88655471900006, 2.819988251000041],
              [9.885201452999979, 2.825752021000028],
              [9.88391208600018, 2.827796699000089],
              [9.883327483000187, 2.831418038000152],
              [9.882840157000032, 2.834276915000146],
              [9.881979942000157, 2.835894108000105],
              [9.881306648000077, 2.83789396200018],
              [9.881104469000093, 2.840754031000131],
              [9.881660462000014, 2.843808174000174],
              [9.882935524000175, 2.845101357000146],
              [9.883637427999986, 2.847107172000108],
              [9.88405323000012, 2.849588395000126],
              [9.884173393000026, 2.854595662000122],
              [9.884097099000087, 2.861032724000154],
              [9.884417534000079, 2.863752126000065],
              [9.885126115000162, 2.864327431000163],
              [9.886494636000123, 2.865859508000028],
              [9.887293816000181, 2.867341280000062],
              [9.887996674000078, 2.869347096000126],
              [9.888794899000118, 2.871162653000113],
              [9.888884544000177, 2.872212173000094],
              [9.888828278000119, 2.87426233399998],
              [9.889393807000147, 2.875170709000088],
              [9.889721870000187, 2.876030684000114],
              [9.889478684000153, 2.877364635999982],
              [9.889756202000172, 2.878844023000113],
              [9.890083312000058, 2.880037547000086],
              [9.891548156000113, 2.881236314999967],
              [9.893444062000128, 2.881483317000175],
              [9.894061088000058, 2.881390810000085],
              [9.894063948999985, 2.88072323900019],
              [9.894307137000055, 2.879245997000169],
              [9.895680427000173, 2.87987232200004],
              [9.896626473000083, 2.880353452],
              [9.896333695000067, 2.882259369000053],
              [9.89636993400012, 2.884691477000047],
              [9.896642684000142, 2.887219906000098],
              [9.897343635000141, 2.88975024199999],
              [9.89856624700019, 2.891901733000111],
              [9.89927005800007, 2.893764495000085],
              [9.899263382000072, 2.895195007000098],
              [9.899065018000158, 2.897006274000148],
              [9.898581505000038, 2.899101973000086],
              [9.898861886000077, 2.900009393],
              [9.89952278200002, 2.900822878000099],
              [9.899707793000175, 2.90192055700004],
              [9.899701118000053, 2.9032080180001],
              [9.899360657000045, 2.905113935000145],
              [9.898828507000076, 2.907591105000108],
              [9.89872550900003, 2.909307241000022],
              [9.897910119000073, 2.911353827000028],
              [9.896901131000107, 2.914210321000155],
              [9.897032738000064, 2.916642667000133],
              [9.897354125000106, 2.91902852100003],
              [9.897919654000077, 2.91979408200018],
              [9.898862838000127, 2.920895099000063],
              [9.898857116000045, 2.92213487700019],
              [9.899272918000122, 2.924616336000042],
              [9.900305748000164, 2.927052975000152],
              [9.901908874000128, 2.929110766000122],
              [9.901710510000044, 2.930969476000087],
              [9.90232181500005, 2.932212114000038],
              [9.902219773000184, 2.933689833000187],
              [9.902019501000098, 2.935977936000086],
              [9.902726174000065, 2.937030077000145],
              [9.904008865000037, 2.936654568000108],
              [9.905193330000088, 2.936803102000113],
              [9.905094148000046, 2.937803984000141],
              [9.904898644000014, 2.93913817400005],
              [9.90546226500004, 2.94033289000015],
              [9.904934883000124, 2.941570283000146],
              [9.904782296000178, 2.943715333000171],
              [9.905180932000121, 2.950011731000132],
              [9.906413077000025, 2.950160503000177],
              [9.907652855000094, 2.94878339800016],
              [9.907644272000027, 2.950690747000181],
              [9.908021927000107, 2.95112156800019],
              [9.908913612000049, 2.953080655000179],
              [9.90880870899997, 2.955369234000102],
              [9.908982277000121, 2.958803177999982],
              [9.909588814000131, 2.960951806000139],
              [9.910671234000176, 2.962816716000077],
              [9.910749435000184, 2.965017557000181],
              [9.913439751000169, 2.962514402000124],
              [9.910790443000167, 2.9652752880001],
              [9.911883354000111, 2.967590809000114],
              [9.912293435000038, 2.971216679000122],
              [9.912428856000133, 2.972695589000068],
              [9.913228034000156, 2.97422528300018],
              [9.91459846600003, 2.975328445000116],
              [9.91544914300016, 2.975999831000081],
              [9.915866852000022, 2.978004695000038],
              [9.916666985000063, 2.979343653000171],
              [9.918512344000021, 2.980210543000169],
              [9.919692992000023, 2.981503487000168],
              [9.920266151000135, 2.980647802000021],
              [9.92035293500004, 2.982269525000163],
              [9.922428130000014, 2.984854221000148],
              [9.923513413000023, 2.986194372000114],
              [9.924029350000183, 2.987389087000111],
              [9.923830986000098, 2.989295482000159],
              [9.924156189000087, 2.99072766400019],
              [9.924622536000072, 2.992446422000114],
              [9.92494964600013, 2.993449449000082],
              [9.925943375000031, 2.993931056],
              [9.926610946000096, 2.993314028000157],
              [9.92746448600019, 2.993365765000078],
              [9.928839683000035, 2.993324518000122],
              [9.92864322600019, 2.994801997000081],
              [9.92925834700003, 2.995090961000187],
              [9.929444314000023, 2.995997906000127],
              [9.930203437000046, 2.996048928000107],
              [9.929859160999968, 2.998622418000082],
              [9.929191590000073, 2.999430179000171],
              [9.929564477000099, 3.000862361000031],
              [9.930987359000085, 3.000869036000154],
              [9.932082175000062, 3.000063419000185],
              [9.934024811000029, 3.000501872000143],
              [9.935674668000047, 3.002655268000126],
              [9.938747405000186, 3.00495863000009],
              [9.940167427000063, 3.005632877000039],
              [9.940872192000029, 3.007019042000081],
              [9.941629410000132, 3.007404090000136],
              [9.942577362000066, 3.007551670000055],
              [9.945046424999987, 3.007086278000145],
              [9.946090699000081, 3.006995916000108],
              [9.947743416000151, 3.008481741000026],
              [9.949114800000075, 3.009489775000134],
              [9.949814796000112, 3.011877298000059],
              [9.950854302000153, 3.012740613000176],
              [9.951889038000104, 3.014748335000036],
              [9.952401162000058, 3.016896725000152],
              [9.953627586999971, 3.018237830000146],
              [9.955137253000032, 3.020056963000116],
              [9.955081940000184, 3.021916628000099],
              [9.955258369000092, 3.02473092200006],
              [9.955819130000066, 3.026545762000126],
              [9.956570625000154, 3.028075219000073],
              [9.956183433999968, 3.029885769000146],
              [9.956795693000117, 3.030794621000041],
              [9.95788192700013, 3.031705856000031],
              [9.958739280999964, 3.030946731000029],
              [9.959688186000051, 3.030903579000153],
              [9.960056306000183, 3.033528089000072],
              [9.960618020000084, 3.035152198000048],
              [9.961124420999965, 3.038444997000113],
              [9.96149253800013, 3.040831327000035],
              [9.961143494000055, 3.044549466000092],
              [9.961465835000183, 3.04655385],
              [9.962213517, 3.04903721900007],
              [9.962726593000014, 3.050947190000102],
              [9.962006570000028, 3.05275607100009],
              [9.961380004999967, 3.054803609000032],
              [9.961183549000054, 3.05628109],
              [9.96151351900005, 3.056663991000164],
              [9.96246623900015, 3.05585789800017],
              [9.963177682000151, 3.055956603000084],
              [9.965016366000043, 3.058302165000157],
              [9.965911865000123, 3.059546233000049],
              [9.966425897000022, 3.061122417000092],
              [9.966793061000033, 3.063890219000143],
              [9.966446876000077, 3.06694054500008],
              [9.965817450999964, 3.069512843000098],
              [9.964427948000036, 3.072463036000045],
              [9.96385097600006, 3.074177027000189],
              [9.963880540000048, 3.077944518000095],
              [9.963968277000106, 3.079375745000107],
              [9.965206147, 3.078523160000032],
              [9.966107369000156, 3.078479768000136],
              [9.966952323999976, 3.080439090000084],
              [9.968507767000176, 3.082544804000065],
              [9.969310760000099, 3.083216189999973],
              [9.971064567000155, 3.08365392800016],
              [9.97253227300007, 3.08423304500019],
              [9.97380733500006, 3.085574388000055],
              [9.974459648000163, 3.088009835000037],
              [9.974259376000077, 3.090297937000173],
              [9.97118568500008, 3.098199845000124],
              [9.969215393000127, 3.103436233000139],
              [9.967724800000156, 3.107768773000146],
              [9.966095924, 3.11095619300005],
              [9.964374543000133, 3.113857031000123],
              [9.961689950000164, 3.120670558000143],
              [9.961532593000186, 3.120665312000028],
              [9.959580421000112, 3.124277592000169],
              [9.956464767999989, 3.130986691000089],
              [9.953451156000028, 3.136265516000094],
              [9.952160836000076, 3.138262034000093],
              [9.951295853000147, 3.140451670000118],
              [9.949143410000147, 3.144160748000047],
              [9.947322846000134, 3.147871495000175],
              [9.946504593000043, 3.150251866000076],
              [9.945685387000083, 3.15291857700015],
              [9.944052696000085, 3.156868459000123],
              [9.942612647999965, 3.160438061000093],
              [9.941167831000087, 3.164865971000154],
              [9.939916610000182, 3.168436289000169],
              [9.939236640000047, 3.171723365000162],
              [9.938611031000107, 3.173484803000065],
              [9.937272071000166, 3.175623893000136],
              [9.93707561400015, 3.177006007000102],
              [9.936776160000079, 3.180056573000059],
              [9.935958862000121, 3.182293653000102],
              [9.934903145000078, 3.18472051700013],
              [9.934179306000146, 3.187196732000132],
              [9.933172226000124, 3.189480542000069],
              [9.932825088000186, 3.19248318800004],
              [9.932343482000135, 3.194006682000122],
              [9.931957244000103, 3.195387841000127],
              [9.93066692400015, 3.197193384000173],
              [9.929899215000091, 3.199001788999965],
              [9.928648949000035, 3.20233368900017],
              [9.927452088000052, 3.204521179000153],
              [9.925636290999989, 3.207182647000082],
              [9.924530029000152, 3.210371970999972],
              [9.924762727000029, 3.211183787000039],
              [9.926277161000087, 3.21200204000013],
              [9.926315307000039, 3.213909627000135],
              [9.925117493000073, 3.216144801000155],
              [9.923782349000078, 3.217521191000174],
              [9.922638893000169, 3.218612195000048],
              [9.921725272000117, 3.220991851000122],
              [9.920952796000165, 3.22365856100015],
              [9.919134141000086, 3.226939678000065],
              [9.917741776000071, 3.230222941000079],
              [9.912279129000069, 3.241067647000136],
              [9.909262658000159, 3.246440889000098],
              [9.906723022000165, 3.251530170000081],
              [9.903799057000072, 3.257380486000102],
              [9.902933121000046, 3.259855747000131],
              [9.903115273000026, 3.261287212000184],
              [9.902985572000034, 3.26309084900015],
              [9.902707101000146, 3.266973734000146],
              [9.902361870000107, 3.269546747000163],
              [9.900783538000098, 3.27211380000017],
              [9.900488854, 3.274067163000041],
              [9.900134086000151, 3.278547763000063],
              [9.899981498000159, 3.280597449000027],
              [9.899458886000048, 3.28078556100013],
              [9.899407386000064, 3.281452893999983],
              [9.89930248200011, 3.283407211000167],
              [9.898908615000096, 3.286218643000041],
              [9.898563384000056, 3.288839578000136],
              [9.896409036000136, 3.292690754000091],
              [9.894013404000191, 3.297256232000166],
              [9.892436027000144, 3.299584627000172],
              [9.891716003000056, 3.301202060000151],
              [9.891364097000064, 3.305015088000175],
              [9.891025544000058, 3.306348324000169],
              [9.889399528000183, 3.308867217000113],
              [9.888155937000022, 3.310720444000083],
              [9.886672020000106, 3.313239814000156],
              [9.883945465000181, 3.317707778],
              [9.881076813000163, 3.321841240000083],
              [9.87892436900006, 3.32516789500005],
              [9.876489640000102, 3.328111411000066],
              [9.87362861600019, 3.330957414000125],
              [9.870383264000054, 3.334707260000016],
              [9.868570329000079, 3.336605072000054],
              [9.866380691000074, 3.337928773000044],
              [9.864806175000012, 3.339684726000144],
              [9.862896918000047, 3.341915608000136],
              [9.861601830000041, 3.344626665000021],
              [9.860211372000094, 3.347289562000185],
              [9.857542992000106, 3.349468709000064],
              [9.856395721000126, 3.351131440000074],
              [9.855294227000115, 3.353223563000086],
              [9.853570938000075, 3.356075286000021],
              [9.851943970000093, 3.358641386000102],
              [9.850184440000021, 3.359538078000185],
              [9.847944260000077, 3.36148119],
              [9.846654892000174, 3.363095283000064],
              [9.844792366999968, 3.365326405000133],
              [9.843168259000095, 3.367367983000122],
              [9.842354775000104, 3.368841887000031],
              [9.84107112800001, 3.369264127000179],
              [9.839350700000125, 3.371638775000179],
              [9.837489128000129, 3.373822213000153],
              [9.835916519000136, 3.375005723000015],
              [9.834674835000044, 3.37657260899999],
              [9.833385468000074, 3.37823438700002],
              [9.831285477000108, 3.380559444000085],
              [9.829189301000042, 3.382169247000149],
              [9.825088501000039, 3.386009216000161],
              [9.823800086000119, 3.387432813000089],
              [9.823031425000181, 3.389192581000032],
              [9.821979522999982, 3.390760422000028],
              [9.820453644000168, 3.392230273000052],
              [9.815876007000043, 3.39649653500004],
              [9.813777924000078, 3.398535251000169],
              [9.812822342000061, 3.399817466000116],
              [9.811767577000069, 3.401766540000097],
              [9.810953141000027, 3.403240204000156],
              [9.809904098000175, 3.404235601000039],
              [9.806949616000168, 3.406794310000066],
              [9.803084373000161, 3.410825729000123],
              [9.801507949000097, 3.412962675000188],
              [9.798309326000037, 3.41652107300007],
              [9.796066284000062, 3.419035674000043],
              [9.79510688900001, 3.420889855999974],
              [9.793721199000117, 3.422789335000061],
              [9.792288779999979, 3.424545527000021],
              [9.788518905000103, 3.428434372000027],
              [9.78632640800015, 3.430329323000024],
              [9.784085275000052, 3.432462453000142],
              [9.783130645000142, 3.433506012000066],
              [9.781886102000101, 3.435596943000121],
              [9.781023979000111, 3.437070132000088],
              [9.779643059000023, 3.437920571],
              [9.777977944000043, 3.438864946000081],
              [9.777024269000094, 3.439670086000092],
              [9.775925635000135, 3.441094398000189],
              [9.773491859000046, 3.44365549000014],
              [9.770864486000164, 3.446978092999984],
              [9.768316269000081, 3.449601174000065],
              [9.76819229, 3.449728490000098],
              [9.767142296000031, 3.45086670000012],
              [9.765094757000156, 3.452333212000156],
              [9.763328552000132, 3.454278231000046],
              [9.760088921000033, 3.456739188000029],
              [9.758996009000043, 3.457066774000111],
              [9.758279801000072, 3.457921029000033],
              [9.757317543000056, 3.460251570000025],
              [9.756362916000057, 3.461438179000083],
              [9.754174233000015, 3.462617874000046],
              [9.753172874000029, 3.463422776000016],
              [9.750740052000026, 3.465840577000165],
              [9.74639892600004, 3.470106840000085],
              [9.743584632000022, 3.472999095000034],
              [9.742583275000072, 3.473899365000079],
              [9.740254401000072, 3.474696636999965],
              [9.738682747000098, 3.475736618000155],
              [9.736395835000053, 3.477392195999983],
              [9.73338794700004, 3.480855466000037],
              [9.730815887000063, 3.48274779400009],
              [9.729098321000038, 3.48440671000003],
              [9.728761673000065, 3.485357999000144],
              [9.727757453000095, 3.486639739000168],
              [9.726662637000118, 3.487348557000189],
              [9.725523948000159, 3.487342120000108],
              [9.724147796000125, 3.487524986000153],
              [9.72195720600007, 3.488990545000149],
              [9.719906806999973, 3.490790367000045],
              [9.718240737000144, 3.491877317000103],
              [9.716383935000181, 3.493058443000109],
              [9.714570045000187, 3.494955064000124],
              [9.712422372000049, 3.497326375000114],
              [9.710282326000083, 3.498267652000095],
              [9.708754540000143, 3.49997520400018],
              [9.706040383000186, 3.501628161000042],
              [9.703227996000123, 3.504091025000093],
              [9.697699547000184, 3.508635760000175],
              [9.696511269000098, 3.508914948000154],
              [9.694891930000154, 3.510097266000116],
              [9.692459106000115, 3.512466907000146],
              [9.687691688000029, 3.516586780000068],
              [9.684783935000041, 3.519096613000158],
              [9.681309699000167, 3.520983219000129],
              [9.678787231000172, 3.522398710000061],
              [9.676308631999973, 3.524529457000085],
              [9.674162864000095, 3.526519298000153],
              [9.672118186000034, 3.527365445000044],
              [9.669546128000036, 3.529066563000129],
              [9.667354583000133, 3.530817747000071],
              [9.664785385000187, 3.531994582000039],
              [9.664639473000023, 3.532660960999976],
              [9.662114144000043, 3.534553052000149],
              [9.660021782000115, 3.53544664400016],
              [9.657603263000112, 3.535432578000098],
              [9.656801223, 3.5346653470001],
              [9.655513763000101, 3.535706521000122],
              [9.652855873000078, 3.535929442000168],
              [9.652474403000156, 3.536356211000054],
              [9.651285172000087, 3.536873578000098],
              [9.650043487000062, 3.538296223000145],
              [9.648564339000075, 3.539860726000029],
              [9.643298150000021, 3.539973021000151],
              [9.641388893000055, 3.542059183999982],
              [9.640007973000024, 3.54290914600017],
              [9.640477181000108, 3.543864966],
              [9.64165782900011, 3.5447299490001],
              [9.64232158700014, 3.544781446000172],
              [9.643179893000081, 3.54402375300009],
              [9.644465447000073, 3.543220998000038],
              [9.645610809000118, 3.542036057000132],
              [9.648844718000134, 3.540529490000097],
              [9.649796485000081, 3.540058375000115],
              [9.650603295000053, 3.53996777600014],
              [9.651255608000099, 3.542021275000138],
              [9.65210056300009, 3.543599129000143],
              [9.653800010000111, 3.545086623000145],
              [9.655360223000173, 3.54600119600002],
              [9.657350541000028, 3.546394110000051],
              [9.660620690000144, 3.546842337999976],
              [9.66474819300015, 3.546770812000091],
              [9.668539048000184, 3.547507764000159],
              [9.669911385000148, 3.548087836000036],
              [9.673026086000164, 3.55096578600012],
              [9.676153183, 3.551651478000167],
              [9.678947448000031, 3.552525521000121],
              [9.680696488000137, 3.553536892000011],
              [9.684099197000023, 3.555892229000165],
              [9.685940743000174, 3.557332992000056],
              [9.686455727000123, 3.558575393000126],
              [9.689007759999981, 3.56030607200006],
              [9.688851357000146, 3.562831640000127],
              [9.686072350000131, 3.567534685000112],
              [9.684930801000121, 3.568052292000061],
              [9.682703019000144, 3.567610503000026],
              [9.678956031000098, 3.567540885000085],
              [9.674686431000168, 3.567420722000179],
              [9.673977853000167, 3.566940070000044],
              [9.673074723000013, 3.567268372],
              [9.67312908100007, 3.566029309999976],
              [9.673180580000178, 3.565314531000126],
              [9.672279358000026, 3.565309286000115],
              [9.671146393000186, 3.564349413000173],
              [9.67096328700012, 3.563251972000046],
              [9.670061112000155, 3.563294411000129],
              [9.669535637000138, 3.564006329000051],
              [9.670289992999983, 3.564821005000113],
              [9.671847343000081, 3.566212416000042],
              [9.671316146000095, 3.567782402000091],
              [9.669796944000154, 3.568012],
              [9.66846656700011, 3.568385362000072],
              [9.666616440000098, 3.568374634000065],
              [9.666422844000067, 3.569040776000122],
              [9.666939736000188, 3.569806576000076],
              [9.666606903000115, 3.570090532000108],
              [9.664378166000176, 3.56988692300007],
              [9.663814544000047, 3.568835021000041],
              [9.661774636000018, 3.568823100000031],
              [9.661252022000156, 3.569010734000131],
              [9.661200524000151, 3.569725275000167],
              [9.660345077000159, 3.569958686000064],
              [9.659778594999978, 3.569431066000107],
              [9.659213066000177, 3.568808080000054],
              [9.659409523000022, 3.567713023000124],
              [9.658275604999972, 3.566800595000132],
              [9.659088135000047, 3.56585216600007],
              [9.659757614000114, 3.564950229000147],
              [9.659286499000132, 3.564327955000181],
              [9.656690598000012, 3.56197714699999],
              [9.655746461000149, 3.561208964000116],
              [9.654796599000065, 3.561441660000185],
              [9.655021667000028, 3.563492536000069],
              [9.654826163000053, 3.564587832000086],
              [9.654066085000011, 3.564678669000045],
              [9.653446198000154, 3.565294743000038],
              [9.652122497000164, 3.564524412000026],
              [9.651227952000113, 3.563327313000173],
              [9.651233672000046, 3.562231302000157],
              [9.651760102000083, 3.561566830000061],
              [9.65091323900009, 3.560322762000169],
              [9.64987373300005, 3.55955410100006],
              [9.648780822000163, 3.559881211000118],
              [9.647549628999968, 3.559445143000119],
              [9.645277978000024, 3.558478593000075],
              [9.644472123000071, 3.558521510000162],
              [9.644002914000055, 3.557613135000054],
              [9.644620895000116, 3.557283162000033],
              [9.643265724000173, 3.553795576000141],
              [9.642271042000118, 3.553503752000154],
              [9.64194583900013, 3.55235791199999],
              [9.640765189000149, 3.551445484000169],
              [9.640201568000123, 3.550393582000027],
              [9.638874053000166, 3.550385714000072],
              [9.637825012000121, 3.551237584000035],
              [9.635492325000143, 3.552606345000072],
              [9.634822846000077, 3.55365085700015],
              [9.632863044000089, 3.556213379000042],
              [9.632522583000082, 3.557641269000101],
              [9.631661415000053, 3.55887532200012],
              [9.632071494000172, 3.56169009100006],
              [9.631499290000079, 3.562306165000052],
              [9.631070137000165, 3.562685013000134],
              [9.631964684000025, 3.563834191000183],
              [9.63244056700006, 3.563503504000096],
              [9.63251590800013, 3.558832885000072],
              [9.632900238000161, 3.557881833000067],
              [9.633613586000024, 3.557695389000116],
              [9.634085656000025, 3.558031560000131],
              [9.634125710000149, 3.559271335000119],
              [9.634644508000065, 3.559893847000126],
              [9.636201858000106, 3.561285257000122],
              [9.636191367000038, 3.563001155000166],
              [9.635667800000022, 3.563284158000045],
              [9.63566494000014, 3.563855887000045],
              [9.636234284000125, 3.563859225000101],
              [9.636986732000082, 3.564912320000133],
              [9.637194634000025, 3.561720133000051],
              [9.635925291999968, 3.559853793000173],
              [9.635132790000114, 3.557465791000141],
              [9.634906769000168, 3.555510282000057],
              [9.635525704000145, 3.555037260000177],
              [9.640810966000117, 3.559977531000129],
              [9.642695427000149, 3.562181234000093],
              [9.643115044000126, 3.563422918000185],
              [9.644340514000135, 3.564717055000074],
              [9.645043371999975, 3.566246510000042],
              [9.645655631000125, 3.567012788],
              [9.646891594000181, 3.566638708000141],
              [9.647696494000115, 3.566929341000105],
              [9.647785186000021, 3.567883254000094],
              [9.647069931999965, 3.568498612000042],
              [9.646257401000185, 3.56963777600015],
              [9.646303176000117, 3.569971799000143],
              [9.64643478500011, 3.571688414000164],
              [9.647336007000092, 3.571789027000079],
              [9.64822483100005, 3.573939323000161],
              [9.647272109000085, 3.574600936000081],
              [9.644472123000071, 3.574822903999973],
              [9.643472671000154, 3.575341226000091],
              [9.638077736000071, 3.581315277999977],
              [9.637457847000064, 3.581835985000168],
              [9.638162612000031, 3.582888604000175],
              [9.638148309000144, 3.585367203000146],
              [9.637480735000167, 3.585982800000181],
              [9.637808800000016, 3.586604356000123],
              [9.639451980000104, 3.589617015000044],
              [9.64036846099998, 3.591553211000019],
              [9.639955521000104, 3.591681719000178],
              [9.639950753000051, 3.592498780000028],
              [9.640588761000117, 3.594626904000052],
              [9.639880180000034, 3.595276117000026],
              [9.640251160000162, 3.596640111000113],
              [9.64007568400001, 3.598926783000138],
              [9.639689446000034, 3.600068332000149],
              [9.637122154000053, 3.603266717000054],
              [9.636680603000059, 3.604625703000011],
              [9.636889457999985, 3.605988742000079],
              [9.636372566000034, 3.610887766000076],
              [9.637385368000025, 3.613834857000143],
              [9.638174058000061, 3.61808824600007],
              [9.638545035000107, 3.619452],
              [9.639783858000044, 3.620821237000143],
              [9.640815734000171, 3.620445967000023],
              [9.640557289000185, 3.618429185000139],
              [9.638662338000131, 3.617982149000056],
              [9.638668060999976, 3.617056131000027],
              [9.639430999000069, 3.616298200000131],
              [9.641061783000168, 3.615490914000077],
              [9.642843246000041, 3.616808654000067],
              [9.644187927000189, 3.618723154000179],
              [9.644773484000041, 3.620469570000125],
              [9.645636558000035, 3.62118268100005],
              [9.646944999000027, 3.620046616000082],
              [9.648517610000056, 3.619947196000055],
              [9.650142669000104, 3.620120049000036],
              [9.651537896000036, 3.622579574000042],
              [9.652933121999979, 3.625038862000167],
              [9.652327538000179, 3.626560451000046],
              [9.651123047000056, 3.628514290000112],
              [9.652472495000154, 3.629611492000151],
              [9.65350055600004, 3.629889965000189],
              [9.654697417000023, 3.629243612000096],
              [9.657028198999967, 3.629311800000039],
              [9.656845093000129, 3.632742406000034],
              [9.654835701000138, 3.63338399],
              [9.654179573000022, 3.634251594000034],
              [9.654168130000016, 3.636266948000127],
              [9.654045104000147, 3.638662815000032],
              [9.653440476000071, 3.640075444000104],
              [9.651909829000147, 3.642190696000171],
              [9.650441170000022, 3.643107891000113],
              [9.649005890000126, 3.647293330000139],
              [9.648126602000048, 3.649412394000137],
              [9.648058891, 3.651699544],
              [9.647063254000159, 3.654961824000111],
              [9.645964623000111, 3.657351732000166],
              [9.644651414000066, 3.65941357600002],
              [9.643332482, 3.662401438000131],
              [9.642444610000098, 3.665827513000011],
              [9.640643121000153, 3.667940855000097],
              [9.639328957000146, 3.67011165700012],
              [9.638722421000068, 3.671741962999988],
              [9.637996674000135, 3.675169229000062],
              [9.63706016499998, 3.677723646000118],
              [9.63607597399999, 3.679133654000111],
              [9.634967805000088, 3.683048725000106],
              [9.633927346000064, 3.684785367000075],
              [9.627633094000089, 3.694605589000105],
              [9.622044564000078, 3.704266547000088],
              [9.613506316999974, 3.717612743000188],
              [9.611963272000139, 3.721633673000156],
              [9.606262207000157, 3.731892587000061],
              [9.602707863000035, 3.736826898000061],
              [9.598166466000066, 3.743661166000095],
              [9.593229294000139, 3.753052951000143],
              [9.588249206000171, 3.760537624000051],
              [9.584034921000125, 3.766937971],
              [9.577081680000049, 3.777841330000058],
              [9.57347011600001, 3.783101559000158],
              [9.569201468000131, 3.789501191000113],
              [9.562418938000121, 3.798988819000158],
              [9.558208466000053, 3.804625988000055],
              [9.553070068000181, 3.811291933000121],
              [9.552510261000123, 3.814174890000174],
              [9.553370476000168, 3.815323829000135],
              [9.555268287999979, 3.815227269000104],
              [9.557769775000111, 3.814099551000084],
              [9.560272217000033, 3.812699318000114],
              [9.561802864000129, 3.810802937000176],
              [9.563827514000138, 3.807820320000189],
              [9.565195083000049, 3.8058140280001],
              [9.567209245000072, 3.80457425200018],
              [9.569327354000109, 3.803988695000157],
              [9.571019173000025, 3.802256584000077],
              [9.572542190000149, 3.801394939000147],
              [9.575859070000149, 3.799890995000055],
              [9.577223777000029, 3.798374652000064],
              [9.57918834600008, 3.796426296000163],
              [9.582341194000037, 3.795139075000122],
              [9.585217475999968, 3.794612646000189],
              [9.590033532, 3.796222449000084],
              [9.592133523000143, 3.797484636000149],
              [9.592340470000124, 3.797084569000162],
              [9.593490601000156, 3.798208237000097],
              [9.595854760000123, 3.801708698000084],
              [9.595464705999973, 3.803394795000031],
              [9.595563889000118, 3.804811239000173],
              [9.595878601000038, 3.806556225000122],
              [9.597335816000054, 3.807600259000026],
              [9.599069595000117, 3.807883500000116],
              [9.600803374000122, 3.808112145999985],
              [9.60062885300016, 3.809962988],
              [9.600782395000067, 3.811379909000095],
              [9.601906776000078, 3.813729049000131],
              [9.602607726000031, 3.815596104000179],
              [9.602640152000049, 3.815682173000084],
              [9.602706908000016, 3.815858126000023],
              [9.602588653000112, 3.817436933000181],
              [9.602515221999965, 3.820377588000042],
              [9.602668762000064, 3.821903467000084],
              [9.602866173000166, 3.825063705000048],
              [9.603066445000025, 3.827570200000082],
              [9.602353097000162, 3.829036474000077],
              [9.602184296000132, 3.829961300000036],
              [9.602121352999973, 3.831376791000139],
              [9.600812911999981, 3.832457782000176],
              [9.600699424000027, 3.833274127000095],
              [9.602353097000162, 3.837587357000132],
              [9.603040696000107, 3.840423822000048],
              [9.60406494200015, 3.841356278000148],
              [9.605095863000088, 3.841199399],
              [9.605581283000106, 3.841583729000035],
              [9.60653209700007, 3.845674516000031],
              [9.60717773500005, 3.846386672000165],
              [9.607849122000062, 3.848903657000108],
              [9.608145713, 3.849639654000157],
              [9.609257698000079, 3.85195541500002],
              [9.611345291000077, 3.856217145000016],
              [9.612789153000165, 3.859494209000104],
              [9.613041878000047, 3.862382412000045],
              [9.614219665000064, 3.864732027000059],
              [9.615677834000167, 3.865612745000078],
              [9.617736817000093, 3.865898133000087],
              [9.619576454000139, 3.86650896000009],
              [9.62206459000015, 3.867614269000171],
              [9.624448777000168, 3.867847205000032],
              [9.628081322000071, 3.867979289000175],
              [9.629281998000124, 3.866788625000027],
              [9.629343033999987, 3.865699769000173],
              [9.629999160000125, 3.864832639000042],
              [9.632773400000133, 3.863379478000127],
              [9.63592720000014, 3.862037898000153],
              [9.635880470000188, 3.860948324000049],
              [9.636588096000139, 3.860408069000073],
              [9.637190819000182, 3.859485865000124],
              [9.638878823000027, 3.858352900000114],
              [9.641264915000079, 3.858422518000168],
              [9.642300606000106, 3.857502938000096],
              [9.644309043000078, 3.857134581000082],
              [9.645943642000077, 3.8560009],
              [9.647636414000147, 3.854050876000144],
              [9.649115563000066, 3.851663589000054],
              [9.651239395000118, 3.850315333000026],
              [9.655426979000026, 3.848380805000033],
              [9.658529282000075, 3.846548319000078],
              [9.659573555000065, 3.844267368000089],
              [9.659912109, 3.842145205000122],
              [9.661224365000066, 3.840410472000031],
              [9.661448478000068, 3.839267968000172],
              [9.660270691000051, 3.836863756000184],
              [9.661251069000173, 3.836161853000135],
              [9.662225722000073, 3.836440326000172],
              [9.665577889000019, 3.838041068000109],
              [9.66763687100007, 3.838271857000109],
              [9.673831939000138, 3.83618640900005],
              [9.682081223000068, 3.83514857300014],
              [9.68489170200013, 3.836745740000026],
              [9.688555718000089, 3.840472697000109],
              [9.689531327000054, 3.840533495000159],
              [9.689521788999969, 3.842113019000124],
              [9.690915106999967, 3.844845295000084],
              [9.692366600000071, 3.84692430500013],
              [9.693603515, 3.848511934000101],
              [9.693810461999988, 3.850147248000098],
              [9.695106506000116, 3.851135969000097],
              [9.698127747000115, 3.853606225000021],
              [9.699969291000059, 3.853998898999976],
              [9.701975822000065, 3.853957177000098],
              [9.705013275000113, 3.853976012000146],
              [9.709890365999968, 3.854551315000037],
              [9.715889932000096, 3.857802869000125],
              [9.717600822000065, 3.86184453900006],
              [9.71615982000003, 3.866738320000081],
              [9.713818550000099, 3.868248939000068],
              [9.711311340000123, 3.870303155000045],
              [9.709300995000149, 3.87094426200008],
              [9.706789018000052, 3.873597860000075],
              [9.706617355000162, 3.875067473000058],
              [9.704714776, 3.875709296000139],
              [9.70362568000013, 3.876519441000141],
              [9.703510284, 3.877608060000057],
              [9.701327324000033, 3.87982773900012],
              [9.698373794000133, 3.883676767000111],
              [9.697761535000154, 3.886287689000142],
              [9.698081017000106, 3.887161255000081],
              [9.697859764000043, 3.887641430000144],
              [9.696651458000076, 3.890257120000172],
              [9.695940017000112, 3.891287566000187],
              [9.693171502000098, 3.891760350000027],
              [9.692135811000071, 3.892570734000117],
              [9.690924645000052, 3.895341158],
              [9.687979699000096, 3.898046255000111],
              [9.687539101000084, 3.899078369000051],
              [9.684535027000038, 3.902436496000178],
              [9.682631493000088, 3.903295994000075],
              [9.678214074000096, 3.907080889000156],
              [9.677498818000061, 3.908819199999982],
              [9.677490235000164, 3.910126447999971],
              [9.676773071000127, 3.911973952999972],
              [9.67870521500015, 3.915199996000183],
              [9.679219246000116, 3.919670106000069],
              [9.678354263000074, 3.926710606000086],
              [9.67834854, 3.926755668000112],
              [9.678086281000105, 3.928894043000184],
              [9.676931381000088, 3.938284636000105],
              [9.675826072, 3.941545726000129],
              [9.673336028000051, 3.940821647000178],
              [9.671328544000062, 3.940863134000097],
              [9.669106484000054, 3.940685511000083],
              [9.666337013000145, 3.941212178000058],
              [9.665907860000061, 3.940501452000035],
              [9.66243744000019, 3.940315723000026],
              [9.661250114000154, 3.939436437000097],
              [9.658593177000171, 3.939364911000041],
              [9.657451629000036, 3.939793111000142],
              [9.655381202000058, 3.94125056200005],
              [9.653647423000052, 3.940966844000172],
              [9.650550843000133, 3.941764116000059],
              [9.647726058000103, 3.942617179000024],
              [9.645836829000189, 3.941079854000122],
              [9.644373893000136, 3.940961361000177],
              [9.64339351699999, 3.941554309000026],
              [9.642091751000123, 3.941545726000129],
              [9.641500473000065, 3.940834045000088],
              [9.638899804000062, 3.940326929000094],
              [9.638085365999984, 3.940539361000049],
              [9.634892464000131, 3.939483882000047],
              [9.633098602000075, 3.94007134400016],
              [9.63217067700009, 3.941045762000158],
              [9.630056382000021, 3.940977574000158],
              [9.628798486000051, 3.942548990000148],
              [9.626072883000063, 3.944710016999977],
              [9.625083924000023, 3.946718693000094],
              [9.623644829000057, 3.951175690000184],
              [9.623016357000097, 3.956128121000063],
              [9.623044968999977, 3.956707954000024],
              [9.623160362000022, 3.959070207000139],
              [9.622924804999968, 3.96195531],
              [9.623713493000082, 3.96571850800018],
              [9.624610900000164, 3.969591617999981],
              [9.625363350000043, 3.97063136100013],
              [9.626503944000092, 3.970421076000093],
              [9.628302574999964, 3.968962193000039],
              [9.629722594000157, 3.967446327000062],
              [9.631144523000103, 3.965658187999964],
              [9.633596421000107, 3.963822365000056],
              [9.635230065000087, 3.962907077000182],
              [9.636025429000029, 3.965690137000024],
              [9.635265350999987, 3.96579408700012],
              [9.634385110000096, 3.967749119000075],
              [9.633425713, 3.97346186600015],
              [9.633297919000086, 3.976402282000038],
              [9.634163856999976, 3.97678923699999],
              [9.634151459, 3.978695632000097],
              [9.634469032000084, 3.979787111000064],
              [9.635650635000104, 3.98170113499998],
              [9.635633469000084, 3.981930733000183],
              [9.635483742000019, 3.983938932000172],
              [9.635472297999968, 3.984096527000133],
              [9.635595322000029, 3.984374763000062],
              [9.636219979000146, 3.985789777000093],
              [9.63714027400016, 3.986122607000084],
              [9.637780189000068, 3.987815381000189],
              [9.63975715700002, 3.990563870000131],
              [9.638042449000125, 3.996170043000063],
              [9.637983320999979, 3.996361972000045],
              [9.630584717000147, 4.00715828],
              [9.62719726600011, 4.009276867000096],
              [9.625969886000121, 4.01004505100002],
              [9.625586510000062, 4.01028442400002],
              [9.62553310300018, 4.010257243000183],
              [9.625491143000033, 4.010344030000169],
              [9.616642951000188, 4.015877724000063],
              [9.610340118000124, 4.016076088000148],
              [9.609482766000042, 4.015078544000062],
              [9.607693671999982, 4.015067101000057],
              [9.606155394999973, 4.014313221000066],
              [9.604373931000055, 4.013185978000138],
              [9.603151322000031, 4.011442661000046],
              [9.600933075000114, 4.010869980000166],
              [9.600236893000044, 4.010028362000185],
              [9.599953652000124, 4.009685994000108],
              [9.597364425000023, 4.009297371000173],
              [9.59527397200003, 4.008167743000115],
              [9.59398460400007, 4.007167816000106],
              [9.592136383000025, 4.006783963000032],
              [9.590472222000074, 4.006525041000089],
              [9.589246750000143, 4.005091668000091],
              [9.586472512000057, 4.004763126000057],
              [9.585052491000113, 4.004939556000124],
              [9.584182740000188, 4.005801202000157],
              [9.583318710000071, 4.005795479000142],
              [9.582028389000186, 4.004981518000022],
              [9.580733299000144, 4.004848957000149],
              [9.579802513, 4.005648612000186],
              [9.577210426000079, 4.005754947000185],
              [9.573746681000102, 4.007033347000061],
              [9.569415093000146, 4.008925438000063],
              [9.563961029999973, 4.012606621000089],
              [9.562400817000139, 4.015136719000168],
              [9.559053420000168, 4.017592907000164],
              [9.556835174000128, 4.017082215000187],
              [9.556408881000152, 4.016273975000104],
              [9.553143501000136, 4.015446664000137],
              [9.550491334000185, 4.015305042000136],
              [9.547954560000051, 4.016465187000108],
              [9.547726632000035, 4.016573907000065],
              [9.547607421, 4.016630649000149],
              [9.544239998000023, 4.018237114999977],
              [9.541707040000063, 4.018715859000167],
              [9.540530205000096, 4.01945114200015],
              [9.540150642000072, 4.020812035000063],
              [9.540325165000013, 4.022362233000081],
              [9.539386749000187, 4.024276733000022],
              [9.53752994600012, 4.025255681000033],
              [9.53536892, 4.025364876000083],
              [9.53129291499999, 4.02595710900016],
              [9.527536393000048, 4.026595116000067],
              [9.5275077820001, 4.026619435000043],
              [9.526697159000094, 4.027300834000016],
              [9.526670455000158, 4.027322770000069],
              [9.523568153000156, 4.027849675000084],
              [9.521609306000187, 4.025481701000047],
              [9.520499229000109, 4.025412561000053],
              [9.517842292000069, 4.025951863000046],
              [9.513607978000039, 4.022639751000099],
              [9.512768746000063, 4.018978119],
              [9.514440537000098, 4.017998218000059],
              [9.515938760000154, 4.015468120000151],
              [9.519895553000026, 4.014193535000118],
              [9.522859574000052, 4.013903619000075],
              [9.524596215000145, 4.012552261000167],
              [9.528018951000036, 4.008114338000098],
              [9.527600289000191, 4.006128787000023],
              [9.527764321000063, 3.999995470000158],
              [9.526906014000019, 3.999184369999966],
              [9.526625633000037, 3.994907141000056],
              [9.528365135000058, 3.993121863000113],
              [9.530420303000085, 3.990285397000093],
              [9.530461311000124, 3.984151602000168],
              [9.531147957000087, 3.982917070000156],
              [9.530733108000163, 3.980373860000043],
              [9.529500008000071, 3.980241776000128],
              [9.529257774000087, 3.979434729000047],
              [9.531799316000047, 3.977654696000116],
              [9.532080651000172, 3.97251391500015],
              [9.528779983999982, 3.967844726000067],
              [9.527836800000102, 3.966885566000087],
              [9.52678108300006, 3.965810538000142],
              [9.525041581000039, 3.964040280000063],
              [9.524737357000106, 3.963294745000098],
              [9.523567200000173, 3.962977170000045],
              [9.520472526999981, 3.964505434000159],
              [9.517444610000041, 3.965228796000133],
              [9.515233994000084, 3.963665010000113],
              [9.513504981000096, 3.963963271000182],
              [9.512461662000021, 3.963150977999987],
              [9.510304450999968, 3.962764740000011],
              [9.50746822300016, 3.962498187999984],
              [9.504754066000032, 3.96254182000007],
              [9.501844407000078, 3.964009524000176],
              [9.498632431000033, 3.964607477000186],
              [9.496149063000075, 3.966945411000154],
              [9.494960784000114, 3.969415664],
              [9.49463748900007, 3.971581936000177],
              [9.492358207000109, 3.971132995000062],
              [9.491113662000089, 3.972735643000021],
              [9.489196777000018, 3.973404169000048],
              [9.487656594000043, 3.973084211000071],
              [9.485012055000027, 3.971889258000033],
              [9.482913970000027, 3.971998930000041],
              [9.482348443000035, 3.973482132000015],
              [9.479995728000176, 3.97476744700009],
              [9.476960183000074, 3.976605654000025],
              [9.475281715000108, 3.978576899000132],
              [9.472604752000052, 3.982152224000117],
              [9.46807766000012, 3.985714913000095],
              [9.464112281000041, 3.988352298999985],
              [9.463051796000116, 3.990079642000183],
              [9.461504937000143, 3.990688801000033],
              [9.458731652000097, 3.990298271000086],
              [9.451268197000104, 3.990123750000123],
              [9.446289063000052, 3.987488031000055],
              [9.448592186000099, 3.984529973000122],
              [9.450019836000081, 3.983114719000071],
              [9.45237922699999, 3.9809002880001],
              [9.454003335000095, 3.977875471000175],
              [9.454451561000042, 3.975462438000079],
              [9.456249238000055, 3.974111557000128],
              [9.45804596, 3.973008633],
              [9.459171295000147, 3.97072386800005],
              [9.459494590000134, 3.968557597000142],
              [9.459094048000054, 3.963784695000129],
              [9.456867217000081, 3.955529691000095],
              [9.455027581000138, 3.953906535000101],
              [9.453943251999988, 3.949934483999982],
              [9.452353478000077, 3.947817325000074],
              [9.449959755, 3.946066380000048],
              [9.44860363, 3.945933580999963],
              [9.447928428000068, 3.945433378000075],
              [9.44782066300013, 3.943078518000107],
              [9.44790363400017, 3.939857484000072],
              [9.446807861000025, 3.937619687000051],
              [9.446577072000025, 3.936591385999975],
              [9.446208955000031, 3.934951782000042],
              [9.447021485000107, 3.933284521000189],
              [9.447093009000128, 3.931860209000149],
              [9.445990562000134, 3.930737734000047],
              [9.443956374000038, 3.930476190000093],
              [9.44229602900009, 3.92965960600003],
              [9.440510750000044, 3.929214001000048],
              [9.44053363900008, 3.925683022000158],
              [9.439801217000081, 3.924500942000066],
              [9.44130516000007, 3.920979739000131],
              [9.443225859999984, 3.919691802000045],
              [9.445699691000129, 3.918778897000095],
              [9.448227881999969, 3.918919803000051],
              [9.451979638000069, 3.920493602000136],
              [9.453276634000076, 3.920316219000142],
              [9.457281113000079, 3.921024562000184],
              [9.459259034000183, 3.920418024000128],
              [9.460988044999965, 3.920181752000076],
              [9.462533950000079, 3.919634581000025],
              [9.464145660000042, 3.918406248999986],
              [9.46532249500018, 3.917670489000045],
              [9.470293046000109, 3.912251711000067],
              [9.472710609000046, 3.91047096200009],
              [9.474940301000117, 3.909122706000062],
              [9.47525596600002, 3.907885791000126],
              [9.470774651000056, 3.904634238000142],
              [9.466526032000161, 3.903491020000047],
              [9.464008331000116, 3.901677608000114],
              [9.459090232999984, 3.899228811000057],
              [9.452569008000182, 3.896645547000048],
              [9.442422867000119, 3.891869783000118],
              [9.433998107999969, 3.887911082000073],
              [9.432763100000102, 3.887537957000177],
              [9.431878091000158, 3.887270450000187],
              [9.42519569500007, 3.885250568000174],
              [9.418913841000062, 3.883784057000071],
              [9.412505150000129, 3.882997990000149],
              [9.406521798000142, 3.88308215100011],
              [9.399607659000139, 3.884027243000162],
              [9.391700745000151, 3.885956764000184],
              [9.387688637, 3.88642549500014],
              [9.381695747000094, 3.887933969000073],
              [9.375327111000047, 3.890369178000071],
              [9.368587494999986, 3.89292550000016],
              [9.365858077000098, 3.894673347000094],
              [9.36515045200008, 3.895126821000133],
              [9.364376068000127, 3.895622731000117],
              [9.359673500000099, 3.89788293800018],
              [9.353113175000033, 3.901369333000105],
              [9.34542942000013, 3.906768084000134],
              [9.337488175000033, 3.913775682000107],
              [9.336413383000149, 3.914806843000065],
              [9.33580112400017, 3.915394545000027],
              [9.335873604000142, 3.915457964000041],
              [9.336660384000027, 3.916199683000059],
              [9.335785866000151, 3.915423154],
              [9.334824563000097, 3.916345358000115],
              [9.327680588000078, 3.923199891000138],
              [9.320051193000097, 3.929651261000174],
              [9.31298351200013, 3.935115337000184],
              [9.304167747, 3.943664075000129],
              [9.299940108999977, 3.948713779000173],
              [9.29594993600017, 3.95506572700009],
              [9.294071198000154, 3.959264279000024],
              [9.293967246000079, 3.960048913000094],
              [9.293941497000162, 3.960245847000067],
              [9.293745041000079, 3.96173954000011],
              [9.293905258000109, 3.965332746000058],
              [9.294000625000081, 3.969483137000168],
              [9.294419289000075, 3.971406222000042],
              [9.29521083899999, 3.972836257000154],
              [9.297977447000108, 3.974094153000124],
              [9.300504684999964, 3.983278274000043],
              [9.303321839000034, 3.986023188000104],
              [9.308547019000173, 3.988537073000032],
              [9.311248778000163, 3.99022817700012],
              [9.31185722400005, 3.990589381000177],
              [9.312035560000084, 3.990694999000027],
              [9.31536483799999, 3.992672444000164],
              [9.317335128000138, 3.993119717000127],
              [9.322232246000169, 3.998418571000116],
              [9.322202682000182, 3.999085665000109],
              [9.322227478000116, 3.999118089000092],
              [9.322222710000062, 3.9999051100001],
              [9.317285538000135, 4.000242234000098],
              [9.31351947700017, 4.000897407000082],
              [9.310436248000087, 4.000751973000092],
              [9.310304642, 4.001989841000011],
              [9.308259963000012, 4.003338337000059],
              [9.308188438000059, 4.005117893000033],
              [9.308235169000113, 4.005165100000113],
              [9.308178902000179, 4.005197049000174],
              [9.3062706, 4.005616188000147],
              [9.304054261000033, 4.004981041000065],
              [9.303444862000163, 4.003985882000052],
              [9.302347184000098, 4.003978252000138],
              [9.302178383000069, 4.003920556000026],
              [9.300796509000122, 4.003409862000183],
              [9.299076080000191, 4.002530576000083],
              [9.297233581000057, 4.001340866000078],
              [9.296687126000052, 4.000160218000076],
              [9.295725823000169, 3.996499301000142],
              [9.293442726000023, 3.996669292000092],
              [9.292282104000094, 3.994988918000161],
              [9.290070533000119, 3.993796586999963],
              [9.289846420000174, 3.99051261000011],
              [9.290876389, 3.98426413500016],
              [9.287973405000173, 3.984863281000173],
              [9.286005020000118, 3.98423004100016],
              [9.284779548000131, 3.982982875000175],
              [9.284937858000092, 3.977967263000153],
              [9.285511017000033, 3.975369931000159],
              [9.28378772700006, 3.974862575000145],
              [9.282748223000056, 3.973554611000054],
              [9.281773567000016, 3.971751691000122],
              [9.27911758499999, 3.972476483000037],
              [9.275897979000092, 3.974188089999984],
              [9.27330494, 3.974727393000137],
              [9.269961356000124, 3.976686],
              [9.267251969000029, 3.976047755000138],
              [9.266027451000127, 3.974738599000148],
              [9.266101838000168, 3.972943067000131],
              [9.267411231999972, 3.970784426000137],
              [9.269705772000123, 3.968942405],
              [9.270108223000136, 3.964362145000109],
              [9.26974964100009, 3.962625504000073],
              [9.265129090000187, 3.96203589400011],
              [9.263147354000125, 3.963198901000169],
              [9.259317397000075, 3.964163066000083],
              [9.259329795000042, 3.962367059000087],
              [9.256677628000034, 3.962472439000067],
              [9.254964828000084, 3.960478783000156],
              [9.25367260000013, 3.960036040000034],
              [9.252082826000049, 3.958043337000106],
              [9.252652168000054, 3.956065416000115],
              [9.251257897000073, 3.952525616000173],
              [9.251584053000045, 3.949988604000055],
              [9.253008842000099, 3.949069739000152],
              [9.252219200000127, 3.947268009000084],
              [9.250676155000065, 3.947505235000165],
              [9.250301362000073, 3.948183775000189],
              [9.249505997000085, 3.947311163000109],
              [9.248957634000078, 3.946254492000151],
              [9.245143891000112, 3.944989442000122],
              [9.243066788000078, 3.94206404800002],
              [9.239857673000074, 3.942475319000039],
              [9.237553597000044, 3.945679665000114],
              [9.236000062000187, 3.947464706000119],
              [9.234025955000163, 3.947574854000152],
              [9.232603073000064, 3.948246002000076],
              [9.232223510000154, 3.949667692000105],
              [9.23270607000012, 3.951219321000167],
              [9.234364510000034, 3.952098131000071],
              [9.234843253000122, 3.954268932000161],
              [9.234461785000178, 3.955938340000103],
              [9.232103347000077, 3.958151341000132],
              [9.231413842000166, 3.959756611999978],
              [9.231026649000171, 3.962107182000182],
              [9.230515480000065, 3.963289262000103],
              [9.229192733000105, 3.961717129000135],
              [9.228243828000018, 3.96171045300008],
              [9.227490426000031, 3.960990667000033],
              [9.226404190000039, 3.960316181000053],
              [9.225068092000186, 3.961402416000112],
              [9.22241401600013, 3.961193324000021],
              [9.219711304000157, 3.961079121000068],
              [9.219046593000144, 3.961217165000164],
              [9.218792916000041, 3.963549377000049],
              [9.218449592000013, 3.965214015000072],
              [9.217160224000111, 3.966443300000094],
              [9.215690613000106, 3.966433049000045],
              [9.215420722999966, 3.964287759000172],
              [9.21476841000009, 3.962568521000037],
              [9.215203285000087, 3.961523532000172],
              [9.216489793000164, 3.960675240000171],
              [9.217781066000157, 3.959112644000186],
              [9.218218804000117, 3.957543850000036],
              [9.217327116999968, 3.956108808000181],
              [9.21533584600013, 3.95599937500009],
              [9.215392114, 3.954809190000105],
              [9.215919494000104, 3.953955650000182],
              [9.217345238000121, 3.953584433000117],
              [9.217257499000084, 3.952535868000098],
              [9.214369774000147, 3.951753617000122],
              [9.213804245000176, 3.951225756999975],
              [9.211524010000062, 3.951781273000108],
              [9.210326194000061, 3.95353508],
              [9.210176467000053, 3.954629659000091],
              [9.210642814000153, 3.95572829300005],
              [9.209828376000075, 3.956913471000064],
              [9.207739830000151, 3.957232236000039],
              [9.207489013999975, 3.957599163000111],
              [9.207375526000021, 3.957765579000181],
              [9.205439568000031, 3.960597516000121],
              [9.20561885800015, 3.962075473000084],
              [9.205136299000117, 3.963310241000158],
              [9.205408096000099, 3.965074539000113],
              [9.206064224000045, 3.966222287000051],
              [9.207104683000068, 3.96661067000008],
              [9.209141731000045, 3.967005969000013],
              [9.209657670000183, 3.967866897000022],
              [9.209222792999981, 3.968959570000095],
              [9.208830833999969, 3.970766545000117],
              [9.20772647900003, 3.972663879000095],
              [9.207861901, 3.973617554000043],
              [9.208472252000149, 3.974526882000134],
              [9.209795952000093, 3.975203038000132],
              [9.210975647000055, 3.976068736000173],
              [9.211683273000176, 3.976597548000029],
              [9.211815834000049, 3.97797966100012],
              [9.211288452000133, 3.978690386000039],
              [9.210760117000177, 3.979686976000039],
              [9.211508752000043, 3.981216430000131],
              [9.211306572000183, 3.982977389999974],
              [9.210334777000185, 3.986209154000051],
              [9.207849503000034, 3.988906383000028],
              [9.207510947000117, 3.989951849000022],
              [9.207071304000067, 3.991758824000044],
              [9.206299783000077, 3.993563176000123],
              [9.204434395000135, 3.995740891000082],
              [9.204947472000185, 3.996935369000141],
              [9.205562591000046, 3.997225285000184],
              [9.207181931000093, 3.996189117000029],
              [9.208367347000092, 3.996197462000112],
              [9.209654808000153, 3.995301725000161],
              [9.210125922000032, 3.995733738000126],
              [9.210627556000134, 3.998642684000117],
              [9.211469650000026, 4.000220298000102],
              [9.212597848000087, 4.001609803],
              [9.212540625999964, 4.002990723000096],
              [9.211490631000061, 4.004030704000058],
              [9.211276054000166, 4.004185201000155],
              [9.210227967000037, 4.004940987000168],
              [9.209392548000096, 4.005540372000098],
              [9.207862853999984, 4.007244109999988],
              [9.206103324000083, 4.00789833000016],
              [9.204529762000107, 4.009077550000029],
              [9.203402520000111, 4.009069920000115],
              [9.203327179000155, 4.009082794999983],
              [9.20158004699999, 4.010437966000154],
              [9.200292587000092, 4.01147556300009],
              [9.199987412000155, 4.011720657000126],
              [9.199971199000117, 4.011759759000142],
              [9.199528693000104, 4.012090207000142],
              [9.197583199000064, 4.012267112000131],
              [9.195782662000056, 4.012015820000102],
              [9.195546149000109, 4.011767387000077],
              [9.194229125000049, 4.010385514000063],
              [9.191665650000118, 4.010700704000044],
              [9.189584732000071, 4.009923458000174],
              [9.187868119000029, 4.011149883000144],
              [9.187623978000033, 4.011225701],
              [9.187596321000115, 4.011233807000167],
              [9.185159682000062, 4.011987686999987],
              [9.182837486000096, 4.011685372000159],
              [9.179571153000097, 4.010852336000141],
              [9.177728653999964, 4.009838581000167],
              [9.176583290000053, 4.010783194999988],
              [9.175296783000135, 4.011678695],
              [9.174447060000091, 4.01110124500002],
              [9.172077178999984, 4.010893823000117],
              [9.170662880000123, 4.009693147000064],
              [9.17067527800009, 4.008026124000082],
              [9.168920516000014, 4.008013726000115],
              [9.166971207000131, 4.008666516000119],
              [9.164738655000178, 4.008421422000083],
              [9.164649009000186, 4.00841140700004],
              [9.162612916000057, 4.007968426000105],
              [9.160147667999979, 4.007855416000155],
              [9.157722474000082, 4.00883817700003],
              [9.154599190000113, 4.007910728000127],
              [9.153174401000058, 4.008138656000142],
              [9.150218963000157, 4.010355473000118],
              [9.14992713900017, 4.011306287000139],
              [9.150442123000119, 4.012262345000181],
              [9.150051117000089, 4.01397419000017],
              [9.149098397000159, 4.01449108200012],
              [9.146300316000179, 4.014565945000186],
              [9.143692017000092, 4.014499664000084],
              [9.140609742000095, 4.014477253000109],
              [9.136621475000027, 4.015115261000176],
              [9.135961532000067, 4.01458692600005],
              [9.134347916000138, 4.014813424000124],
              [9.132839203000117, 4.013611793000109],
              [9.132849694000186, 4.01213550600005],
              [9.130498886000169, 4.009308816000157],
              [9.129459382000107, 4.008682251999971],
              [9.129088401999979, 4.007536411000103],
              [9.128165244000172, 4.007112981000091],
              [9.128112794000117, 4.007088662000115],
              [9.127717971000038, 4.006907464000051],
              [9.126248359000101, 4.006801606000181],
              [9.124917984000092, 4.007125379000058],
              [9.124386787000105, 4.008502483000143],
              [9.12301159000009, 4.00854015300007],
              [9.121489524000083, 4.009196281000129],
              [9.121480941000186, 4.010338784000112],
              [9.122374535000063, 4.011345387000176],
              [9.122369764999974, 4.01210737200006],
              [9.121273994000035, 4.012718678000169],
              [9.118850708000082, 4.013367654000035],
              [9.117147445000114, 4.012831689000052],
              [9.116542817000152, 4.011255742000117],
              [9.115175248000071, 4.010245800000064],
              [9.114175796000154, 4.010666847000152],
              [9.113264083000104, 4.012136459000089],
              [9.111981391000029, 4.012508393000132],
              [9.111356736000118, 4.01359892],
              [9.110642433000123, 4.014070034000099],
              [9.109173774000169, 4.013868810000133],
              [9.106225014000074, 4.015085697000188],
              [9.104512215000057, 4.015835285000037],
              [9.104638099000169, 4.018169404000162],
              [9.101395607000086, 4.020622254000102],
              [9.099482537000085, 4.02284669800008],
              [9.098656654000024, 4.025602340000035],
              [9.096745492000025, 4.027636052000048],
              [9.092640877000122, 4.031320573000187],
              [9.089257241000098, 4.033581734000165],
              [9.086632728000097, 4.03580045800004],
              [9.084486007000066, 4.03759431900005],
              [9.079298019000134, 4.040223122000043],
              [9.075916291000112, 4.042340756000101],
              [9.068340301000148, 4.047332763000043],
              [9.06518840800004, 4.048886299000174],
              [9.059012413000176, 4.051930428000048],
              [9.048729897000101, 4.057473183000127],
              [9.04445266700003, 4.059436322000067],
              [9.043115615999966, 4.06005048899999],
              [9.039936065000063, 4.060407638000186],
              [9.038271904000169, 4.061061859],
              [9.03418254799999, 4.062602521000144],
              [9.031952858000068, 4.062728882000045],
              [9.030475616000047, 4.063717843000063],
              [9.03018188499999, 4.064953804000083],
              [9.029375077000054, 4.065090657000155],
              [9.028034210000044, 4.066889762000017],
              [9.026312829000176, 4.068829060000098],
              [9.024827957000071, 4.069223880999971],
              [9.024744988, 4.069246292000173],
              [9.023658752000017, 4.068666458000166],
              [9.023432731000071, 4.067141534000086],
              [9.021066666000138, 4.066504956000188],
              [9.020211220000078, 4.066783905000079],
              [9.01949119599999, 4.067968845000053],
              [9.019074440000168, 4.066680432000112],
              [9.017838479000147, 4.067099572000188],
              [9.01561260200009, 4.066749573000095],
              [9.014941216000125, 4.067744732000108],
              [9.015835763000155, 4.068560600000069],
              [9.016059876000156, 4.070323945000041],
              [9.015203476000124, 4.070698261000132],
              [9.014358521000133, 4.069597244000079],
              [9.012835502000144, 4.070347309000056],
              [9.012995720000106, 4.074300767000125],
              [9.012747765000086, 4.075726986000063],
              [9.01146411799999, 4.076288700000021],
              [9.010805130000051, 4.075569629000142],
              [9.010044099000027, 4.075897217000033],
              [9.008810996000079, 4.075935841000046],
              [9.007375717000059, 4.077733993000152],
              [9.00543499000014, 4.077196122000146],
              [9.004626275000135, 4.077618600000108],
              [9.003968239000187, 4.076851846000068],
              [9.002635002000091, 4.077555657000119],
              [9.001150132000021, 4.079591752000113],
              [8.998517036000067, 4.082808495000109],
              [8.998381614999971, 4.082973958000025],
              [8.997896195000124, 4.083566666000024],
              [8.993014336000101, 4.089672089000089],
              [8.988430024000024, 4.094017506000171],
              [8.985757828000089, 4.09633016600003],
              [8.983765602000062, 4.096363068000073],
              [8.980496407000032, 4.096099853000055],
              [8.978505134000045, 4.096085072000165],
              [8.977789879999989, 4.096603394000056],
              [8.977878571000133, 4.097460747000071],
              [8.977865219000137, 4.099269866999975],
              [8.977318763000028, 4.102503301000184],
              [8.978304864000165, 4.103748799000016],
              [8.979479789000152, 4.105233669000143],
              [8.980470658000115, 4.105859755999973],
              [8.982504844000061, 4.106446743000163],
              [8.983539582000049, 4.107549667000114],
              [8.985002518000101, 4.108512880000149],
              [8.985883712000032, 4.11113834300005],
              [8.98663520700012, 4.112000943000169],
              [8.987100601000066, 4.113194467000142],
              [8.986900330000083, 4.114668846000029],
              [8.987553597000044, 4.116054536000092],
              [8.988636971000176, 4.117015361000085],
              [8.988913536000041, 4.118064879999963],
              [8.988521576000153, 4.119680405000167],
              [8.98707294500008, 4.123240470000098],
              [8.986816406000116, 4.125714303000052],
              [8.987655640000071, 4.12767267300012],
              [8.988031386999978, 4.128151417000083],
              [8.987402916000121, 4.129670144000102],
              [8.987530709000112, 4.131623268000055],
              [8.987756730000115, 4.133100987000034],
              [8.988222122000025, 4.134294510000075],
              [8.987880708000034, 4.135529996000173],
              [8.988009454000064, 4.137292386000127],
              [8.98771953500011, 4.137956620000068],
              [8.987755775000096, 4.139528276000078],
              [8.987977982000075, 4.14148187700016],
              [8.988004683999975, 4.144243717000052],
              [8.987804412000116, 4.145527362000109],
              [8.986555099000043, 4.147803306000128],
              [8.986723898999969, 4.15047073400001],
              [8.98803711, 4.152432918000045],
              [8.988445282000043, 4.154864312000086],
              [8.989765165999984, 4.155921936000027],
              [8.990328788000113, 4.156640053000047],
              [8.990459441000155, 4.158164979000105],
              [8.991490364000129, 4.159743786000092],
              [8.99123478000007, 4.162122250000039],
              [8.994878768000149, 4.166874409000059],
              [8.994944572000065, 4.166959763000023],
              [8.995106697000097, 4.170579433000171],
              [8.996095658000115, 4.171491623000179],
              [8.996124267000084, 4.173967362000099],
              [8.996833802000083, 4.174163341000053],
              [8.99682521699998, 4.17525863700007],
              [8.997720719000029, 4.176027298000179],
              [8.997994422000033, 4.177410126000041],
              [8.998970985000085, 4.179988384000183],
              [8.99910163900006, 4.181417941000063],
              [8.998090743999967, 4.183505059000083],
              [8.997605324000176, 4.18492937100018],
              [8.997637748000159, 4.186929702000043],
              [8.994825363000075, 4.188716888000101],
              [8.994862557000147, 4.19009828500009],
              [8.99594879100016, 4.190677643000072],
              [8.997078896000119, 4.191781522000042],
              [8.997721672000182, 4.194452762000083],
              [8.996912003000091, 4.194922447000124],
              [8.995480537000105, 4.196101666000061],
              [8.994434357000046, 4.19642686900005],
              [8.993093490000035, 4.198130607000167],
              [8.993370055000128, 4.199227811000185],
              [8.994073868000157, 4.200185298000122],
              [8.994112968000025, 4.201328754000031],
              [8.992727280000111, 4.202603341000042],
              [8.992341042000135, 4.203505039000049],
              [8.991613388000133, 4.205689431000167],
              [8.98908233700007, 4.207954884000117],
              [8.987600327000052, 4.209514618000185],
              [8.986923218000072, 4.211270810000031],
              [8.98657703399999, 4.21312522900007],
              [8.985760689000074, 4.214356900000098],
              [8.985753058000057, 4.215451718000054],
              [8.986643790000187, 4.216791631000035],
              [8.986442566000051, 4.218218326],
              [8.985339164000095, 4.219828606000021],
              [8.983563423000135, 4.222623825000085],
              [8.981176376000065, 4.224699974000032],
              [8.978178023000055, 4.226057530000105],
              [8.977224351000018, 4.226716519000149],
              [8.975446700000134, 4.22965431200015],
              [8.972491263999984, 4.231678486000135],
              [8.97110271400004, 4.233381271000042],
              [8.969484329000181, 4.23417806599997],
              [8.969328881000081, 4.23584318200011],
              [8.968467713000052, 4.236788751000063],
              [8.969320298000184, 4.236938478000127],
              [8.970895767000059, 4.235569954000027],
              [8.9716558450001, 4.23548078500005],
              [8.966964723000046, 4.241109371000164],
              [8.964819907000049, 4.242520809000041],
              [8.961385727000106, 4.24496936900016],
              [8.954259872000023, 4.249248027000135],
              [8.953805924000108, 4.249520302],
              [8.953715324000086, 4.2495746620001],
              [8.950428008000074, 4.251500607000025],
              [8.947996139, 4.253242969000098],
              [8.945186615000068, 4.254744052000035],
              [8.943097114000125, 4.255108357000097],
              [8.940334320000147, 4.254834176000031],
              [8.939970016000189, 4.25479793500017],
              [8.93758773899998, 4.255505085000038],
              [8.937500000000114, 4.255495548000056],
              [8.93465232900013, 4.255660535000118],
              [8.933413506000022, 4.256412506000061],
              [8.932977675000132, 4.257551671000101],
              [8.93248748700006, 4.259594442000093],
              [8.932284355000149, 4.261259080000173],
              [8.932890891000056, 4.262501716000145],
              [8.933495522000044, 4.264077664000126],
              [8.933200836000026, 4.265313150000054],
              [8.93352317800003, 4.266601085000104],
              [8.93413543700018, 4.267034053000032],
              [8.933934212000111, 4.268508435000172],
              [8.932489395000061, 4.27130556100019],
              [8.930755616, 4.274766923000016],
              [8.92936515800011, 4.276660442000093],
              [8.927695275000019, 4.277932166000028],
              [8.924834251000107, 4.27990913400015],
              [8.921205521000047, 4.28306961200002],
              [8.916427612000064, 4.287649154000178],
              [8.915849685000126, 4.288691521000033],
              [8.915859223000041, 4.290368081000167],
              [8.915860176000024, 4.290450096000143],
              [8.915869713000177, 4.292214393000165],
              [8.915665626000077, 4.293974400000025],
              [8.915422440000043, 4.294781686000079],
              [8.915402412000162, 4.297256947000108],
              [8.915671349000093, 4.29925823300016],
              [8.916277885, 4.300500869000132],
              [8.918677330000037, 4.302900314000169],
              [8.919708250999975, 4.304384708000043],
              [8.920077323000157, 4.305720329000167],
              [8.920484542000168, 4.308151722000162],
              [8.92122554700012, 4.310394765000012],
              [8.921156884000027, 4.313107967000178],
              [8.922089577, 4.315019607000068],
              [8.922874452000031, 4.317691804000106],
              [8.923006058000112, 4.319025517000057],
              [8.923470497000039, 4.320219518000158],
              [8.923743248000164, 4.321744918999968],
              [8.923308372000065, 4.322693826000091],
              [8.923089027000117, 4.326405048000027],
              [8.922131539000134, 4.327539921000039],
              [8.921452523000085, 4.329486370000041],
              [8.921053886000038, 4.33186340300017],
              [8.921174050000047, 4.334672928000032],
              [8.920969010000135, 4.33657550900017],
              [8.919850350000104, 4.339993954000079],
              [8.919847488000073, 4.340084553000054],
              [8.919784547000063, 4.342278481000051],
              [8.919488906000026, 4.34365654100003],
              [8.91770839700007, 4.346926689000043],
              [8.915218353000114, 4.34985828400005],
              [8.911881447000042, 4.351973058000056],
              [8.907404899000028, 4.354364873000179],
              [8.902403832000061, 4.356989861000102],
              [8.900923728000123, 4.358263016000024],
              [8.899965286000054, 4.359492778000174],
              [8.891974449000145, 4.374041557000169],
              [8.890584946000047, 4.379069328000071],
              [8.890522003000058, 4.379296303000103],
              [8.888114928000107, 4.388004779000141],
              [8.887703896000062, 4.39180946499999],
              [8.887378693000017, 4.396805287000177],
              [8.886712075000105, 4.402940274],
              [8.886410713000089, 4.404984475000163],
              [8.88672351800011, 4.407272339000087],
              [8.886789321000151, 4.410890579000125],
              [8.886712075000105, 4.414507389000107],
              [8.886843681000187, 4.415793896000025],
              [8.887655258999985, 4.415181637999979],
              [8.888190269000063, 4.413472653000099],
              [8.88819503700006, 4.418708801000093],
              [8.887844084999983, 4.421038152000165],
              [8.885281563000092, 4.42701482700005],
              [8.884744645000069, 4.428771497000071],
              [8.884551048000105, 4.4350533490001],
              [8.88409710000002, 4.438286781000102],
              [8.884889602000044, 4.440006733000132],
              [8.885966300000177, 4.44168186200011],
              [8.887226104000092, 4.444167614000037],
              [8.888027191000049, 4.444840908000174],
              [8.889457703000119, 4.443948268000042],
              [8.890254972999969, 4.445002080000165],
              [8.890950203000159, 4.446959497000023],
              [8.892545700000085, 4.449019908000082],
              [8.894191743000135, 4.450699807000092],
              [8.89764022900016, 4.45239448600006],
              [8.898428916000114, 4.454543113999989],
              [8.897939681000082, 4.456300258999988],
              [8.896643638000057, 4.458146096000064],
              [8.895146370000134, 4.461465836000116],
              [8.894366264000098, 4.463934422000079],
              [8.893821716000161, 4.466691017000187],
              [8.893852234000178, 4.468785762000039],
              [8.893317222000121, 4.470352173000038],
              [8.89268970500018, 4.471632003000082],
              [8.892441749999989, 4.472962857000027],
              [8.892514229000028, 4.475724220000188],
              [8.893074034999984, 4.476823806000027],
              [8.894020080000018, 4.477165222000053],
              [8.894875527000011, 4.4769344340001],
              [8.894886970000187, 4.476870061000057],
              [8.89517402600012, 4.475270748000128],
              [8.89611625800012, 4.475992680000161],
              [8.897004127000116, 4.477571011000123],
              [8.898444175000066, 4.481248379000078],
              [8.900277138000092, 4.483310700000118],
              [8.902956008999979, 4.486237050000113],
              [8.904226303000087, 4.487485408000055],
              [8.906812667000054, 4.490220547000092],
              [8.90831470500018, 4.492089749000058],
              [8.908388138000134, 4.492301941000051],
              [8.90909004299999, 4.494327546000022],
              [8.906455040000026, 4.498207568000055],
              [8.905167580000125, 4.500102997000113],
              [8.90449714600004, 4.500634194000099],
              [8.901196481000056, 4.503246784000112],
              [8.900613784000086, 4.503537178000158],
              [8.900389671000085, 4.5034494410001],
              [8.899206162000155, 4.503201484999977],
              [8.89830112500016, 4.503622055000164],
              [8.896819116000188, 4.505085468000175],
              [8.895158768000101, 4.505118847000176],
              [8.89347743899998, 4.505769730000111],
              [8.893112182000039, 4.505910874000108],
              [8.890727998000159, 4.507414342000118],
              [8.889341355000056, 4.508782864000068],
              [8.888854980000076, 4.510159493000174],
              [8.886645317000102, 4.513520241000038],
              [8.883474350000029, 4.51830101000013],
              [8.875446319000048, 4.525325776000102],
              [8.868474960000128, 4.525266648000013],
              [8.866394043000128, 4.524487019000105],
              [8.863509178000129, 4.523510456000054],
              [8.861615181000161, 4.523161412000093],
              [8.858618736000153, 4.524087906000034],
              [8.856945992000078, 4.525596619000055],
              [8.855695724000043, 4.527632714000049],
              [8.854786874000183, 4.528576851000082],
              [8.85212707400018, 4.528934956000171],
              [8.849457741000038, 4.530483246000188],
              [8.847648622000179, 4.531276703000174],
              [8.846070290000171, 4.532881738000015],
              [8.844027520000054, 4.533245087000182],
              [8.84254646300019, 4.534564972000055],
              [8.841261863000113, 4.534982204000073],
              [8.840112686000168, 4.536257744000125],
              [8.839488029000052, 4.537251950000155],
              [8.839519500999984, 4.539108276999968],
              [8.838936806000049, 4.540721893000068],
              [8.838645935000045, 4.541385651000098],
              [8.837635040000123, 4.543185711000035],
              [8.837622641999985, 4.544613362000121],
              [8.837798118000137, 4.546328545000165],
              [8.838691712000184, 4.54724073400007],
              [8.840864181000086, 4.548258781000186],
              [8.841801643, 4.549599649000129],
              [8.838901519000046, 4.550383568000029],
              [8.835297585000092, 4.550257682000108],
              [8.834611893000044, 4.547300816000075],
              [8.832584381000117, 4.545902728000044],
              [8.827839851000135, 4.546100140000021],
              [8.810564041000021, 4.547474384000054],
              [8.801142693000088, 4.545536996000124],
              [8.799496650000094, 4.543904305000126],
              [8.797107696000126, 4.540456773000187],
              [8.795505522999974, 4.539253234000171],
              [8.794279098, 4.538433553000118],
              [8.79210185900007, 4.536375999000029],
              [8.791346551000117, 4.535662651000166],
              [8.789856911000129, 4.534255027000086],
              [8.788583756000037, 4.533482551000077],
              [8.786593438000068, 4.533226967000076],
              [8.785370826000133, 4.532074452000188],
              [8.783284188000152, 4.532008647000168],
              [8.781261444000108, 4.530087472000105],
              [8.776241303000177, 4.529282569000031],
              [8.770704269000134, 4.527949810000166],
              [8.744956970000089, 4.516685964000089],
              [8.729577064000125, 4.513078690000043],
              [8.719926834000148, 4.510425091000116],
              [8.719875337000076, 4.510326863000159],
              [8.719826697000144, 4.510234832000094],
              [8.717927933000055, 4.506597519000138],
              [8.717909812000073, 4.506562231999965],
              [8.714258194000081, 4.499568939000028],
              [8.711011887000154, 4.493316651000157],
              [8.70606231700009, 4.486649514000135],
              [8.702671051000152, 4.484107972000174],
              [8.698931693000191, 4.482247830000119],
              [8.69633579200007, 4.479712964000157],
              [8.693395614000167, 4.47763204600011],
              [8.690727234000178, 4.476980686000047],
              [8.687210084000128, 4.475751401000025],
              [8.685285568000097, 4.474649429000124],
              [8.680397987000049, 4.474036218000151],
              [8.67237567900014, 4.474023820000184],
              [8.66839027400016, 4.474388600000168],
              [8.664400100000023, 4.475267888000076],
              [8.659547805000102, 4.477166652000165],
              [8.655270577000067, 4.478385449000029],
              [8.650589943, 4.48017215800013],
              [8.64801693000004, 4.481691362000049],
              [8.64771843, 4.483287334000124],
              [8.647036550999985, 4.48328113600013],
              [8.645688058000076, 4.481328010000027],
              [8.64415073400005, 4.481486320000158],
              [8.641407967000077, 4.482832433000056],
              [8.639539718000094, 4.481788159000189],
              [8.636527061000152, 4.481476784000051],
              [8.630546571000025, 4.48233795200008],
              [8.62149238700016, 4.483400344000074],
              [8.614951133000034, 4.483285904000184],
              [8.609775543000069, 4.483183384000085],
              [8.605670928000166, 4.483899117000021],
              [8.605243683000083, 4.483973504000062],
              [8.604476929000043, 4.484107016999985],
              [8.600952149000079, 4.483904838000058],
              [8.593567848000077, 4.482584001000134],
              [8.590383529000121, 4.482441902000176],
              [8.5867395410001, 4.482923507000123],
              [8.583038330000022, 4.483290196000041],
              [8.578048706000175, 4.481533527000067],
              [8.57588958700012, 4.481172085000026],
              [8.570256233000123, 4.481407643000125],
              [8.566267014, 4.482285977000174],
              [8.561191559000122, 4.483782292000058],
              [8.557772636000095, 4.484436988000027],
              [8.555657387000167, 4.485674381000024],
              [8.555751801000156, 4.487844468000048],
              [8.556878089000065, 4.489167214000077],
              [8.559203148000165, 4.489986898000041],
              [8.560842515000161, 4.491143227000066],
              [8.561965942000143, 4.492751598000041],
              [8.563302992999979, 4.495903493000128],
              [8.565078736000089, 4.500942707000149],
              [8.56619930300019, 4.502893925000137],
              [8.568230629000027, 4.504738332000045],
              [8.569634437000048, 4.50686311700008],
              [8.571213723000142, 4.508418559000177],
              [8.573422432000029, 4.509465695000131],
              [8.576537133000045, 4.511092187000088],
              [8.576661111000021, 4.516402246000041],
              [8.571565627000098, 4.520067692000112],
              [8.568716049000045, 4.520670413000119],
              [8.565710068000101, 4.51961612700012],
              [8.564301492000084, 4.51817607900017],
              [8.563134194000156, 4.515082836000147],
              [8.561783791000096, 4.513358593000021],
              [8.555552482000053, 4.510505677000026],
              [8.553627014000085, 4.509518146000119],
              [8.551938057000086, 4.50756216100001],
              [8.549365997000052, 4.50245857200008],
              [8.548132897000187, 4.50044965800015],
              [8.546603203000075, 4.499693870000101],
              [8.544954299999972, 4.499621869000123],
              [8.544114113000035, 4.498244762000127],
              [8.54276943300016, 4.495834827000124],
              [8.540452957000127, 4.494158746000096],
              [8.538660050000033, 4.493577004000088],
              [8.535486222000145, 4.494064331000175],
              [8.527247428000067, 4.495882512000094],
              [8.519946098000105, 4.498224259000097],
              [8.517031669000062, 4.498456002000182],
              [8.514539718000037, 4.499379158000181],
              [8.50928783400019, 4.502426624000123],
              [8.507820130000084, 4.503703118000033],
              [8.506001472000037, 4.505921840000099],
              [8.505121231000146, 4.508578777000139],
              [8.50396442400006, 4.513381959000185],
              [8.503926278000108, 4.517679691000126],
              [8.503606795000053, 4.524639607000097],
              [8.502983093000125, 4.527384758000153],
              [8.502172470000119, 4.531847477000156],
              [8.50179863000011, 4.535282613000163],
              [8.50064754400006, 4.548939705000066],
              [8.499824523000086, 4.555099487000064],
              [8.49981975500009, 4.555130483000141],
              [8.499637603999986, 4.556495190000021],
              [8.50066280499999, 4.556762219000177],
              [8.501614572000165, 4.555739404000178],
              [8.503427505000161, 4.554208755000104],
              [8.505401612000128, 4.553796768000041],
              [8.508477211000184, 4.554684162000058],
              [8.513095855000017, 4.555413724000061],
              [8.51702880900001, 4.556222917000127],
              [8.526332855000135, 4.559917927000129],
              [8.528119087000164, 4.561395646000108],
              [8.528871537000043, 4.563379287000032],
              [8.528678895000098, 4.565784455000085],
              [8.527884483000094, 4.568442344000175],
              [8.525975228000107, 4.57183075],
              [8.525930404000178, 4.571910859000184],
              [8.522921563000182, 4.577251912000065],
              [8.518560409000088, 4.581424713000047],
              [8.516959191000126, 4.58295726700004],
              [8.514417649000166, 4.586062432000176],
              [8.51011562299999, 4.591319084000077],
              [8.505873679000047, 4.596265793000043],
              [8.503976822000027, 4.597537995000153],
              [8.501468657999965, 4.600179672000138],
              [8.500236512000186, 4.603778840000189],
              [8.49969005700018, 4.607384204000084],
              [8.499454498000148, 4.614430427000173],
              [8.499759675000064, 4.618473531000063],
              [8.501253128000087, 4.623816967000039],
              [8.503437995000127, 4.628479005000088],
              [8.5058956150001, 4.633115292000127],
              [8.505951882000034, 4.633221150000168],
              [8.506048201999988, 4.633402826000122],
              [8.509769439000138, 4.638680936000185],
              [8.512394905000122, 4.64197158800016],
              [8.513656616000162, 4.644562244000099],
              [8.513121606000141, 4.646878242000071],
              [8.51352501000008, 4.649546623000106],
              [8.516640663000032, 4.655507088999968],
              [8.515825273000075, 4.660227298],
              [8.516054153000027, 4.663324356000089],
              [8.500316621000138, 4.669796944000098],
              [8.530911445000072, 4.693350315000146],
              [8.532643318000112, 4.694683552000072],
              [8.538852692000148, 4.699641227000029],
              [8.540198325, 4.702318668000146],
              [8.540435791000164, 4.704470158000163],
              [8.540319443000101, 4.705998420000128],
              [8.54031562800003, 4.706052780000164],
              [8.540240287000131, 4.707047462000048],
              [8.539191245000041, 4.709272861000045],
              [8.528035165000119, 4.729628087000094],
              [8.526206017000106, 4.732791901000041],
              [8.525799749999976, 4.734583855000153],
              [8.525758743000097, 4.734764576000089],
              [8.524912834000133, 4.742751600000133],
              [8.524903298000083, 4.743792056000075],
              [8.524902343000065, 4.74391078900004],
              [8.52488422499999, 4.745845795000093],
              [8.526114465000035, 4.751703262000092],
              [8.52692031800018, 4.757212640000091],
              [8.528543471000091, 4.76685619400007],
              [8.52937221500008, 4.769786834000058],
              [8.533656121000149, 4.779025555000033],
              [8.535561562000169, 4.783779145000096],
              [8.537548066000056, 4.788733959000069],
              [8.537841797000112, 4.789467334000051],
              [8.538758277000056, 4.792312622000111],
              [8.540104866000036, 4.794904709000093],
              [8.541288376000068, 4.796635152000022],
              [8.544938087000162, 4.800366402000179],
              [8.546133041000076, 4.80080747500017],
              [8.547933578000141, 4.800738812000077],
              [8.55129146500002, 4.799051285000189],
              [8.555856704, 4.796601296000063],
              [8.557828903000029, 4.796448231000113],
              [8.56022739399998, 4.796556949000035],
              [8.563055991000169, 4.796583652000038],
              [8.566987989999973, 4.797652245000052],
              [8.570398330000103, 4.799489976000132],
              [8.572279931000082, 4.799937725000177],
              [8.576225281000177, 4.799631120000072],
              [8.582061767000084, 4.79865455700002],
              [8.584118843000113, 4.798674108000114],
              [8.592931748000012, 4.800218583000117],
              [8.59695053199999, 4.801202297000145],
              [8.59976959200003, 4.802174569000158],
              [8.604372025000089, 4.804969310000161],
              [8.616041184000039, 4.812731266000071],
              [8.618598939000037, 4.814131260000067],
              [8.624237060000155, 4.816161632000046],
              [8.626539231000095, 4.817473411000151],
              [8.628655435000042, 4.82033062],
              [8.629487039000139, 4.823089599000127],
              [8.629792213000144, 4.827133656000171],
              [8.629057884000076, 4.832285881000018],
              [8.627624512000125, 4.838892937000139],
              [8.625520706000145, 4.843859673],
              [8.623435975, 4.846763611000142],
              [8.622819901000014, 4.847298146000014],
              [8.619028091000189, 4.850590706000162],
              [8.617483139000058, 4.85175323500016],
              [8.616250991000072, 4.852680206],
              [8.614625929999988, 4.853902341000151],
              [8.612073898000062, 4.855414867000093],
              [8.610056876000158, 4.856610298000135],
              [8.608617783, 4.858081341000059],
              [8.607807160000164, 4.858910083000069],
              [8.6062307360001, 4.862420560000032],
              [8.603003503000025, 4.868321896000168],
              [8.602970123999967, 4.871853829000088],
              [8.603002548000177, 4.872448445000089],
              [8.604391098000122, 4.875643254000181],
              [8.606059074000143, 4.88047409100011],
              [8.608072280000101, 4.88513612700001],
              [8.611879349000049, 4.890417577000051],
              [8.622027397000181, 4.90495920300009],
              [8.624927520000085, 4.906534672000134],
              [8.627580642000055, 4.906904222000151],
              [8.630495071000041, 4.906931878000137],
              [8.635560036000186, 4.906206609000094],
              [8.638730049000174, 4.906409264000047],
              [8.641122819000088, 4.907205581000142],
              [8.643684387000121, 4.908262252999975],
              [8.647017478000123, 4.909411907000106],
              [8.649480821000111, 4.911756992000051],
              [8.651936531000047, 4.914961815000083],
              [8.652772902999971, 4.917205334000187],
              [8.653010369000072, 4.919271468999966],
              [8.653035165, 4.921399116000089],
              [8.653057098000147, 4.923313141000108],
              [8.652894021000066, 4.924118996000061],
              [8.652738571000157, 4.924885750000101],
              [8.652713775000052, 4.925006867000093],
              [8.652699471000119, 4.925075054000104],
              [8.652517318000093, 4.925973415000101],
              [8.651900292000164, 4.927421571000025],
              [8.651640892000046, 4.928029062000121],
              [8.649893760000055, 4.931451320000122],
              [8.649609567000084, 4.932545186000027],
              [8.649003028000095, 4.934882165000033],
              [8.649432183000158, 4.937363624000113],
              [8.649897575000125, 4.940050125000084],
              [8.650899887000094, 4.94272518300005],
              [8.652503967000143, 4.945406436000155],
              [8.653502464000042, 4.94569730700016],
              [8.654641152000124, 4.946028710000121],
              [8.659545898000033, 4.946152688000041],
              [8.660126687000059, 4.946167469000159],
              [8.663198470999987, 4.947744845999978],
              [8.665205955000033, 4.950737476000143],
              [8.665267944999982, 4.950815202],
              [8.665338516000077, 4.950920582000038],
              [8.66538333900013, 4.95098781500019],
              [8.666158677000169, 4.952157020000186],
              [8.665740013000175, 4.955025674000069],
              [8.665538788000106, 4.956408024000041],
              [8.665490151000086, 4.956902027000126],
              [8.66491603899999, 4.962698936000095],
              [8.665579795000042, 4.963809491],
              [8.666233063000107, 4.964901925000163],
              [8.666724204000161, 4.965303898000116],
              [8.668333054000016, 4.966619968000146],
              [8.671420097000123, 4.968205452000063],
              [8.671762467000178, 4.968413354000177],
              [8.675557137000112, 4.970721245000107],
              [8.676692963000107, 4.971753121000063],
              [8.678074836000121, 4.973008632000074],
              [8.679100991000041, 4.976212979000081],
              [8.678863525000111, 4.979100705000121],
              [8.68032074100006, 4.981518268000059],
              [8.688262940000016, 4.984353066000097],
              [8.692829132000043, 4.986236097000017],
              [8.693577767000079, 4.987385751000147],
              [8.694080353000061, 4.988158227000099],
              [8.694334982999976, 4.990920066000058],
              [8.691925049000076, 4.992310524000175],
              [8.689452171000141, 4.992711068000119],
              [8.686851502000138, 4.993515015000128],
              [8.684571265000045, 4.994220256000119],
              [8.683703422000065, 4.994686603000048],
              [8.682373047000056, 4.995401384000161],
              [8.68079567000018, 4.998505592000129],
              [8.681482315000096, 5.000061035000101],
              [8.683442115000048, 5.001636028000121],
              [8.684985162000089, 5.002499581000109],
              [8.688286782000034, 5.003875733000086],
              [8.691939353, 5.005466938000041],
              [8.692833900000039, 5.007810115000041],
              [8.69321823000007, 5.011845589000131],
              [8.694184303000156, 5.014047623000067],
              [8.695656777000124, 5.014981271000067],
              [8.697629929000129, 5.015141964000122],
              [8.701267242000085, 5.018289566000135],
              [8.702512741000021, 5.019072533000156],
              [8.702676773000064, 5.019175530000098],
              [8.70435237900017, 5.020229340000071],
              [8.706782341000121, 5.024284362000174],
              [8.708651543000087, 5.027910232000181],
              [8.70918846100011, 5.030745030000048],
              [8.710304261000033, 5.032099724000091],
              [8.712490081000055, 5.03219175300012],
              [8.715596199000061, 5.031938553000146],
              [8.716413497000133, 5.034917355000061],
              [8.716604233000112, 5.037112236000041],
              [8.717224119999969, 5.038603783000042],
              [8.719540596000172, 5.039828778000072],
              [8.721139908000168, 5.041833877000158],
              [8.717253684000127, 5.050288201000171],
              [8.717371940000135, 5.052610875000141],
              [8.718865394999966, 5.058638097000028],
              [8.720531462999986, 5.061342239000112],
              [8.72304344299999, 5.064267158000121],
              [8.72371864199999, 5.067386150000061],
              [8.724306107000018, 5.072201730000074],
              [8.725268364000158, 5.074828626000169],
              [8.727080345000047, 5.077180386000066],
              [8.728906632000076, 5.077976703000161],
              [8.730334282000115, 5.078129293000131],
              [8.73136329700003, 5.078494072000069],
              [8.737798692000069, 5.084868431000132],
              [8.740683555000089, 5.085674286000142],
              [8.74235534800016, 5.087883472000101],
              [8.742854119000128, 5.090335370000162],
              [8.742960929000049, 5.090860844000076],
              [8.743049623000104, 5.092371465000099],
              [8.743143082000131, 5.09397506800002],
              [8.743396759000063, 5.095891000000108],
              [8.743536948000042, 5.096950055000036],
              [8.744154929999979, 5.097499848000155],
              [8.748363494000046, 5.101241588000107],
              [8.751462937000099, 5.101696015000186],
              [8.754687308000143, 5.103920459000165],
              [8.757855414000062, 5.10458850800012],
              [8.759275436000166, 5.103682518000142],
              [8.762785912000027, 5.105414391000181],
              [8.767142297000135, 5.107225419000088],
              [8.770877839000093, 5.107686520000129],
              [8.774816514000122, 5.108927251000182],
              [8.77958870000009, 5.111591339000029],
              [8.781618118000154, 5.1133089060001],
              [8.783073426000101, 5.115867614000081],
              [8.782693863000077, 5.118832112],
              [8.782881736999968, 5.121230603000129],
              [8.783648490000076, 5.122228622000023],
              [8.788639069000112, 5.124116898000125],
              [8.790235519000021, 5.126820565000116],
              [8.790908813000158, 5.130223274000173],
              [8.794463157000052, 5.134585858000037],
              [8.794078827000078, 5.138109684000085],
              [8.795043944000099, 5.140380381999989],
              [8.797071457000072, 5.14223957199999],
              [8.798457146000033, 5.144870758000138],
              [8.799489975000142, 5.147569179000186],
              [8.799576759000047, 5.153088093000122],
              [8.800396920000139, 5.155855655000153],
              [8.802780152000139, 5.157505990000175],
              [8.803463936000071, 5.159706116000109],
              [8.806264877000103, 5.161926270000151],
              [8.808146476000047, 5.16442108200016],
              [8.809529305000183, 5.167264462000048],
              [8.810053825000068, 5.171443940000188],
              [8.811366082000177, 5.174357415000088],
              [8.81324100500018, 5.177418233000083],
              [8.814482690000148, 5.180331230000093],
              [8.816072464000058, 5.183742524000081],
              [8.818734169000095, 5.185620784999969],
              [8.814775466000185, 5.20852470300008],
              [8.814748765000047, 5.208680153000103],
              [8.81460857400009, 5.209488868000165],
              [8.812751768999988, 5.220225334000133],
              [8.816658974000063, 5.260450364000178],
              [8.81515693700004, 5.283845902000053],
              [8.817012786000021, 5.288744450000024],
              [8.829330444000107, 5.320493698000064],
              [8.830784797000092, 5.323267460000068],
              [8.833020211000132, 5.325624943000037],
              [8.834191321000048, 5.328466893000154],
              [8.835232735000034, 5.339148044000069],
              [8.835238458000106, 5.339229108000041],
              [8.83543300700012, 5.341221333000135],
              [8.835682868999982, 5.34378528600007],
              [8.835688591000121, 5.343848705000085],
              [8.836006164000025, 5.347103119000053],
              [8.836029052000129, 5.347337723000123],
              [8.836048126000094, 5.347531797000158],
              [8.840230942000176, 5.387761592000175],
              [8.840033532000177, 5.388791561000062],
              [8.839783668000109, 5.390091896000058],
              [8.838285446000157, 5.39163351100018],
              [8.838088036000158, 5.392174721000174],
              [8.83748722100006, 5.393818856000053],
              [8.836895943000172, 5.396430493000082],
              [8.836764336000044, 5.39770698600006],
              [8.836582183000132, 5.399469853000028],
              [8.836682320000023, 5.403503417000138],
              [8.835170747000063, 5.406388760000027],
              [8.834858895000025, 5.409286498000029],
              [8.835394860000065, 5.412122251000085],
              [8.835217477000015, 5.415657997000039],
              [8.834671021000133, 5.418051719000061],
              [8.834331513000109, 5.41954040600001],
              [8.832388877000142, 5.423129082000116],
              [8.831834793000098, 5.424700260000122],
              [8.831370354000114, 5.426019192000183],
              [8.831090926000115, 5.429455758000131],
              [8.831048011, 5.429977895000036],
              [8.834805488000143, 5.438233375000095],
              [8.836709977000055, 5.442417621000118],
              [8.838191033000044, 5.444803714999978],
              [8.840113639000151, 5.447900773000072],
              [8.840713502000142, 5.45137357800013],
              [8.840468408000106, 5.454625608000072],
              [8.84270668, 5.456700802000171],
              [8.843392372000096, 5.458069324000064],
              [8.843882560000168, 5.459047795000117],
              [8.847517013000015, 5.462622643000145],
              [8.84840488500015, 5.463221073000113],
              [8.850182533000123, 5.464418889000058],
              [8.851219177000132, 5.464101314000175],
              [8.853086472000143, 5.463529110000081],
              [8.854837418000102, 5.464891434000037],
              [8.857277870000019, 5.467888356000174],
              [8.860007286000098, 5.470251083000164],
              [8.863030433, 5.471485138000048],
              [8.865269660000138, 5.473489284000152],
              [8.866655350000031, 5.476121425000088],
              [8.866557121000142, 5.478738308000118],
              [8.86801624400016, 5.481088163000152],
              [8.867072105000148, 5.483696460000033],
              [8.866126060000113, 5.486446381000121],
              [8.8667306910001, 5.489424228000189],
              [8.869186401000093, 5.490935802000024],
              [8.870786666000072, 5.493216516000075],
              [8.872377396000047, 5.496487617000128],
              [8.87540435700015, 5.497438431000091],
              [8.876790047000043, 5.500000001000103],
              [8.879014969000082, 5.503348828000185],
              [8.883068084000172, 5.507565021000062],
              [8.886958121000077, 5.513832091999973],
              [8.888637543000129, 5.51533556000004],
              [8.891793249999978, 5.517490864000024],
              [8.89311027499997, 5.519839286000035],
              [8.894989012999986, 5.522618294000097],
              [8.895528793000096, 5.52502965900004],
              [8.897120475000122, 5.528159618000075],
              [8.899479866000036, 5.532217503000027],
              [8.89958858500006, 5.532376289000183],
              [8.899821282000062, 5.53271913500015],
              [8.90170669500003, 5.535495282000056],
              [8.902517318000037, 5.539041996000094],
              [8.905725478000079, 5.542966842000112],
              [8.906616210000038, 5.545594215000165],
              [8.906445504000146, 5.548493862000157],
              [8.907761574000119, 5.55098390600017],
              [8.90920066800004, 5.555244445000142],
              [8.91092395700008, 5.559366702999967],
              [8.912734984000053, 5.561791420000134],
              [8.915977478000116, 5.562461853000116],
              [8.917875290000097, 5.563330651000058],
              [8.920332908000091, 5.564700603000063],
              [8.919384003000175, 5.567662717000189],
              [8.919782639000118, 5.570072650000043],
              [8.920679092000114, 5.572205067000141],
              [8.920012475000135, 5.575241090000077],
              [8.918366433000188, 5.577276230000052],
              [8.916790009000124, 5.579524040000081],
              [8.914929390000111, 5.581840039000099],
              [8.91469192600016, 5.584313870000074],
              [8.913898469000117, 5.585933685999976],
              [8.913224220000018, 5.589606285],
              [8.912348746000077, 5.592356682000116],
              [8.911122321000107, 5.594820500000083],
              [8.909897804000138, 5.597072125000125],
              [8.909925460000125, 5.601247311000066],
              [8.908772468000109, 5.603429318000053],
              [8.906180382000059, 5.608142854000107],
              [8.903692245000173, 5.616466999000068],
              [8.901481629000045, 5.618495941999981],
              [8.899829864000083, 5.621096611000155],
              [8.89859676400016, 5.624197006000088],
              [8.89044761700012, 5.626800538000055],
              [8.889359474000059, 5.629477979000171],
              [8.887219429000027, 5.631507396000131],
              [8.885642052000151, 5.633755207000036],
              [8.885400773000185, 5.636582852000117],
              [8.884815216000106, 5.638487339000051],
              [8.882247925000058, 5.640795231000027],
              [8.87826156599999, 5.643795490000059],
              [8.873510361000115, 5.645585061000077],
              [8.870042801000068, 5.646255970000027],
              [8.870041846000106, 5.6462950720001],
              [8.870035171000154, 5.646923541000149],
              [8.870007515000168, 5.649510385000099],
              [8.869004250000046, 5.650915146000102],
              [8.865205765000042, 5.656792641000095],
              [8.863190650000036, 5.656912327000043],
              [8.860983849000149, 5.657041073000073],
              [8.858838081000044, 5.658693790000143],
              [8.857899665000048, 5.661197185000049],
              [8.857194901000185, 5.66309356700009],
              [8.854478837000158, 5.668319701000144],
              [8.852948188000084, 5.669293404000143],
              [8.84790420600018, 5.671752930000082],
              [8.846059799999978, 5.673636913000109],
              [8.843985558000156, 5.675594807000095],
              [8.841080666000153, 5.677086830000178],
              [8.83881187500009, 5.675996781000094],
              [8.837195397000073, 5.677883149000081],
              [8.836645126000121, 5.679552555000157],
              [8.837463379000042, 5.681236744000159],
              [8.837477685000181, 5.687025070000175],
              [8.835253716000125, 5.688828945000125],
              [8.836045265000166, 5.693026066000016],
              [8.835120202000098, 5.694234371000107],
              [8.833199502000127, 5.696041585000046],
              [8.832245827000179, 5.699915409000141],
              [8.831817627000135, 5.704403877000061],
              [8.832182884000019, 5.705854893000037],
              [8.83345317900006, 5.707848549000119],
              [8.835199356000032, 5.708095550000053],
              [8.836501122000129, 5.707272054000157],
              [8.837658881000095, 5.705608846000189],
              [8.839202881000119, 5.703417301000116],
              [8.842177391000178, 5.702535152000053],
              [8.841444015999969, 5.700014114],
              [8.842210769000133, 5.699413301000163],
              [8.843573571000093, 5.699960709000152],
              [8.843704224000135, 5.702018261000035],
              [8.844828604999975, 5.703477384000053],
              [8.846712113000024, 5.705097199000022],
              [8.848607064000078, 5.705650330000083],
              [8.85064983500007, 5.706586362000053],
              [8.852303505000123, 5.70835590500019],
              [8.85412502299999, 5.708680153000046],
              [8.853184701000032, 5.711259365999979],
              [8.85285186800013, 5.713921071000073],
              [8.853269578000038, 5.717504979000125],
              [8.854161262000105, 5.71949481900009],
              [8.855519295000079, 5.720423223000182],
              [8.857573509000076, 5.72029304400013],
              [8.85925197600011, 5.719778060000181],
              [8.860005378000096, 5.720471383000074],
              [8.859912872000109, 5.721993924000174],
              [8.860167503000128, 5.726565838000113],
              [8.860665321999989, 5.729846002000102],
              [8.861849785000061, 5.732829094000181],
              [8.864182472000039, 5.735063076000074],
              [8.865095139000175, 5.735072612000181],
              [8.86775493600004, 5.735177517000068],
              [8.869096757000136, 5.737705230000188],
              [8.869377137000072, 5.739916801000163],
              [8.869817733000105, 5.741368772000101],
              [8.871405602000095, 5.742223739000053],
              [8.87396907800013, 5.744231224000146],
              [8.875003814000138, 5.746984482000073],
              [8.875206948000027, 5.749347688000171],
              [8.872483253000041, 5.748099805000038],
              [8.870817185000021, 5.747472286000118],
              [8.86975002299999, 5.747689248000086],
              [8.868970871000045, 5.749432565000177],
              [8.86902523100008, 5.751413345000117],
              [8.870075225000107, 5.752795697000124],
              [8.870367051000073, 5.753941059],
              [8.869367599000157, 5.754920482000159],
              [8.868202210000106, 5.757268907000025],
              [8.868415832000039, 5.758642196000039],
              [8.86938381300007, 5.76048040500018],
              [8.871191978000013, 5.762099266000121],
              [8.87278175400013, 5.762725830000079],
              [8.872691154999984, 5.764095784000119],
              [8.87343788000004, 5.765398503000142],
              [8.87280464100013, 5.767676830000085],
              [8.87317085300009, 5.768975258000069],
              [8.874526024000033, 5.770208358000105],
              [8.877339363000033, 5.770238877000054],
              [8.880305290000024, 5.770270824000136],
              [8.881745339000077, 5.770667552000077],
              [8.881950379000159, 5.772801877000063],
              [8.882148743000016, 5.775546073000101],
              [8.883420943000147, 5.777463913000133],
              [8.882182122000017, 5.779506684000126],
              [8.881080628, 5.782922269000039],
              [8.881094932000167, 5.783348561000139],
              [8.881134986000063, 5.784575462000134],
              [8.881137848000151, 5.784674644000177],
              [8.882534027000133, 5.789259434000087],
              [8.883347512, 5.791324615000178],
              [8.882950783000126, 5.792843819000098],
              [8.879047393000121, 5.795162200000164],
              [8.876759528999969, 5.795823098000142],
              [8.874544144000083, 5.796712875000139],
              [8.874060632000067, 5.799221040000077],
              [8.872360230000083, 5.801792146000025],
              [8.871474266000121, 5.799345016000189],
              [8.871115684000131, 5.797285080000108],
              [8.869382858000108, 5.795819283000071],
              [8.866270065000037, 5.795328618000042],
              [8.863599777000047, 5.796137333000104],
              [8.861454011000149, 5.797713280000039],
              [8.860366819999967, 5.799970628000096],
              [8.860286712000118, 5.800137521000124],
              [8.859125137000035, 5.799265862000027],
              [8.856062889000157, 5.796968937000145],
              [8.854071618000148, 5.795579434000047],
              [8.852515220000157, 5.794493199000158],
              [8.849567414000148, 5.792861939000147],
              [8.848220826000102, 5.792560101000049],
              [8.847064019000015, 5.792301178000059],
              [8.846177100000091, 5.793603420000125],
              [8.845828056000187, 5.794116021000036],
              [8.846117020000122, 5.795489788000054],
              [8.846637727000086, 5.796561718000135],
              [8.848210335000033, 5.798787595000022],
              [8.848792076000109, 5.799810409999964],
              [8.84925556200011, 5.800626756000042],
              [8.849234581000019, 5.802530288000128],
              [8.8484497070001, 5.804806232000146],
              [8.84729290000007, 5.806882381999969],
              [8.84650993299999, 5.808288575000063],
              [8.846341133000067, 5.809809684000072],
              [8.846006393000096, 5.812623978000033],
              [8.844790458000148, 5.814351082000144],
              [8.843612670000027, 5.816024780000078],
              [8.841311455000152, 5.819334985000126],
              [8.840985297000145, 5.819804192000163],
              [8.839655877000098, 5.823140144000092],
              [8.840001106000159, 5.826342583000155],
              [8.841028214000062, 5.827394008000169],
              [8.841204642999969, 5.827574254000183],
              [8.841596603000085, 5.827943326000138],
              [8.843086243000073, 5.829346657000031],
              [8.843406678, 5.829407692000189],
              [8.845211030000087, 5.829750538000099],
              [8.847415924000131, 5.829774857000075],
              [8.850007057000028, 5.829346180000073],
              [8.852058411000087, 5.829444886000033],
              [8.85380458800006, 5.829768659000081],
              [8.855999946000168, 5.83070659599997],
              [8.857951164000156, 5.833088875000044],
              [8.858999251000057, 5.834547520000172],
              [8.85913086000005, 5.836452962000124],
              [8.857880591000082, 5.839485645000138],
              [8.857326508000142, 5.841536045000112],
              [8.857683182000187, 5.843672276000063],
              [8.858390808000081, 5.844542504000117],
              [8.858808517000114, 5.845055580000121],
              [8.860940932000062, 5.846209527000099],
              [8.861680984000032, 5.846610069000178],
              [8.865015982999978, 5.847636699000077],
              [8.867209434000017, 5.84880352100015],
              [8.869170188000055, 5.850271702000043],
              [8.871125221999989, 5.852349758000173],
              [8.873211860000026, 5.853867054000148],
              [8.873464584000146, 5.854051114000072],
              [8.873736382000061, 5.85413932900002],
              [8.875889778000044, 5.854838848999975],
              [8.87922000899999, 5.85624647200018],
              [8.879611015000023, 5.856751919000033],
              [8.879778862000137, 5.85696935600015],
              [8.880172729000151, 5.857478618000187],
              [8.880644798000049, 5.858089924000126],
              [8.882976532000043, 5.860476492999965],
              [8.885701180000069, 5.861724854000158],
              [8.88798046200003, 5.861902236999981],
              [8.888679504000095, 5.861862183000085],
              [8.888709068000082, 5.861860753000144],
              [8.891329766000183, 5.861710548000019],
              [8.89611721000017, 5.862067700000125],
              [8.90044498400016, 5.862724304000096],
              [8.902874947000043, 5.863131523000106],
              [8.903006553000068, 5.86503744200013],
              [8.902830125000037, 5.867244243000016],
              [8.902949333000152, 5.870292188000065],
              [8.903374672999973, 5.87311458500011],
              [8.904561996000098, 5.875793458000146],
              [8.906285287000117, 5.87817335200009],
              [8.909358978000171, 5.882320403999984],
              [8.912571906999972, 5.887610912000071],
              [8.91331291099999, 5.889370919000101],
              [8.913971902000071, 5.89173936800006],
              [8.913263321000159, 5.893940449000183],
              [8.912554741000179, 5.896141529000033],
              [8.911150932000112, 5.899324894000074],
              [8.911283494000031, 5.900415897000187],
              [8.911290169000154, 5.90046930200009],
              [8.912257194000119, 5.90246009800012],
              [8.914216996000107, 5.90408086900004],
              [8.916193009000097, 5.904254914000148],
              [8.917562485000076, 5.9042701730001],
              [8.918607712000153, 5.904116630999965],
              [8.920456886000011, 5.903844833000051],
              [8.921895980999977, 5.903885841999966],
              [8.924791336, 5.903968811000027],
              [8.926115036000113, 5.904426575000059],
              [8.926837920000082, 5.904676437000092],
              [8.927932739000141, 5.905254840999987],
              [8.929636001, 5.906154633000028],
              [8.933715819999975, 5.908712864000052],
              [8.937108039000066, 5.910644531000116],
              [8.939836502000162, 5.911617279000097],
              [8.94271469099999, 5.911813736000113],
              [8.942829132000156, 5.911821366000027],
              [8.945055961000094, 5.91119432500011],
              [8.991372109000167, 5.948593617000142],
              [9.023438454000086, 5.974679470000126],
              [9.023780823000095, 5.974957943000163],
              [9.053595542000039, 5.999209881000184],
              [9.082030297000074, 6.026161194000054],
              [9.082104683000182, 6.026231290000169],
              [9.102220536000061, 6.04529762300001],
              [9.104216577000102, 6.047189711999977],
              [9.107350349, 6.05023813300005],
              [9.107482910000044, 6.050367356000038],
              [9.133093835000011, 6.072074891000113],
              [9.137645721000069, 6.07638931300005],
              [9.152491569000063, 6.09046125500015],
              [9.152613640000141, 6.090577125000152],
              [9.153542518000052, 6.093019962000028],
              [9.153586388000122, 6.093371869000066],
              [9.153672219000043, 6.094066619000046],
              [9.153731347000132, 6.094539166000118],
              [9.154353141000058, 6.096191883000188],
              [9.154980660000035, 6.096800327000096],
              [9.156339645000116, 6.098316670000031],
              [9.15622520400018, 6.100073338000073],
              [9.156156541000087, 6.100201129000084],
              [9.156075477000115, 6.100351334000152],
              [9.155623436000099, 6.101189137000119],
              [9.156574250000062, 6.101931095000055],
              [9.156531332999975, 6.102398397000172],
              [9.156470297999988, 6.103073121000023],
              [9.156571388000032, 6.103376865000143],
              [9.156868934000158, 6.104266167000162],
              [9.157123566000053, 6.105369091000114],
              [9.15726089500015, 6.105961799000113],
              [9.15734004900014, 6.106034280000188],
              [9.157713891000128, 6.106378556000038],
              [9.158063888000072, 6.107753753000054],
              [9.158535004000157, 6.107978344000117],
              [9.159202576000155, 6.10829687100005],
              [9.15938568199999, 6.108384132000083],
              [9.165139199000066, 6.123512269000116],
              [9.165345191000085, 6.124752999000066],
              [9.165496826000094, 6.125666142000057],
              [9.165846825000187, 6.126312732999963],
              [9.166169167000021, 6.126907825000046],
              [9.167325019000089, 6.130221843000129],
              [9.167497635000188, 6.130717279000066],
              [9.167719840000189, 6.130804062999971],
              [9.167987823000033, 6.130908490000024],
              [9.179024697000045, 6.140129090000187],
              [9.187273026000128, 6.147026063000169],
              [9.211695671000143, 6.167308808000144],
              [9.230620385000123, 6.17317676600004],
              [9.242700576000175, 6.176947593000023],
              [9.249752999000066, 6.178641797000182],
              [9.265599251000083, 6.184134960000051],
              [9.265694619000158, 6.18416786099999],
              [9.266119003000085, 6.184940337000171],
              [9.280026435000082, 6.210219860000052],
              [9.283883096000068, 6.217230320000112],
              [9.284741401000076, 6.218753814000024],
              [9.289235114, 6.226732253000023],
              [9.29379654000013, 6.234668255000088],
              [9.308239937000167, 6.262758731000133],
              [9.309422493000113, 6.264301775999968],
              [9.310146332000045, 6.26524686800019],
              [9.311788559000149, 6.265981197000144],
              [9.313768386000106, 6.26777505900003],
              [9.31529522000011, 6.269220829000119],
              [9.316031455000029, 6.269778252000151],
              [9.316597939000189, 6.270206928000107],
              [9.317953110000133, 6.27165079100007],
              [9.319388390000029, 6.272671700999979],
              [9.319993973000123, 6.273102283000128],
              [9.321127891000174, 6.273915291000037],
              [9.321196557000178, 6.274029732000031],
              [9.321744920000128, 6.274950982],
              [9.321825981000188, 6.275317192000159],
              [9.321908950000022, 6.2756958],
              [9.32195472699999, 6.276513576000184],
              [9.322682381000163, 6.2781057360001],
              [9.323179245000063, 6.279711723000048],
              [9.324096680000025, 6.280333520000056],
              [9.324017524000055, 6.28137874600003],
              [9.32369518400003, 6.281891345000076],
              [9.323553085000071, 6.282116891000101],
              [9.322516441000118, 6.282849313000099],
              [9.322023391000187, 6.283400536000158],
              [9.321706771000095, 6.283754825000074],
              [9.321130754000137, 6.284149171000024],
              [9.321004868000046, 6.28433370700003],
              [9.320899010000119, 6.284489632000032],
              [9.320257188000142, 6.285740376000149],
              [9.320336342000132, 6.28676414600011],
              [9.320406915000035, 6.287685872000168],
              [9.321014404000096, 6.288502217000143],
              [9.321308137000131, 6.288896084000101],
              [9.321698188000028, 6.289129734000085],
              [9.322669983000026, 6.289711476000093],
              [9.324741362999987, 6.288362026000129],
              [9.32493305200012, 6.288268567000159],
              [9.327038765, 6.287243366000155],
              [9.328353882000045, 6.287200451000047],
              [9.329411507000032, 6.287353516],
              [9.330064773000117, 6.287447930000042],
              [9.330092431000082, 6.287738324000088],
              [9.33016777, 6.288535119000187],
              [9.330077171000085, 6.288907529000085],
              [9.329987526000025, 6.28927660100004],
              [9.329977036000059, 6.290248394000116],
              [9.330343246000155, 6.291485787000056],
              [9.330638884999985, 6.292485237000165],
              [9.331044198000029, 6.292517663000183],
              [9.332294464000142, 6.292617798000038],
              [9.332911491000118, 6.292897701000186],
              [9.333715439000173, 6.293262005000145],
              [9.334319114000152, 6.295262337000054],
              [9.334597587000189, 6.296187400000122],
              [9.334743499000183, 6.298532963000127],
              [9.335582733000138, 6.300200463000124],
              [9.336027145000116, 6.301348687000086],
              [9.336405754000111, 6.303296566000029],
              [9.336155891000146, 6.305294992000029],
              [9.336176872000181, 6.305956841000125],
              [9.336197852000169, 6.306610584000168],
              [9.336329461000105, 6.307489395000118],
              [9.336411477000127, 6.308042050000097],
              [9.336680412000078, 6.309588433000045],
              [9.336724281999977, 6.310257435000153],
              [9.336777687000051, 6.311076165000031],
              [9.336870193999971, 6.313078404000066],
              [9.336984634000032, 6.31374693000015],
              [9.337085724000076, 6.314338683000187],
              [9.337182999000049, 6.314927101000023],
              [9.337245941000106, 6.315312386000187],
              [9.337095262000162, 6.315934181000046],
              [9.336887360000162, 6.316794873000163],
              [9.336359977000143, 6.317989827000133],
              [9.33520698600006, 6.318949222000185],
              [9.334309579000149, 6.319443703000161],
              [9.333884239000099, 6.319677831000149],
              [9.334152222000171, 6.320479393000028],
              [9.334383010000067, 6.321169853000129],
              [9.334772110000131, 6.322145939000109],
              [9.336075781999966, 6.323074818000123],
              [9.336476324999978, 6.323340415000132],
              [9.337039947000108, 6.323714257000177],
              [9.337660789000154, 6.32440710000003],
              [9.33880329200008, 6.324476718000085],
              [9.339303970000117, 6.324683666000169],
              [9.339371681000102, 6.324711799000113],
              [9.340615272000093, 6.325634003],
              [9.341015815999981, 6.325930596000148],
              [9.341482162000034, 6.326390743000104],
              [9.342153549000045, 6.327053547000048],
              [9.343196868000177, 6.328412055],
              [9.343548774000112, 6.328870774000052],
              [9.343523026000128, 6.331464291000145],
              [9.343515395000111, 6.332219124000176],
              [9.343471528000123, 6.336534501000074],
              [9.342134476000183, 6.338578224000173],
              [9.342295646000025, 6.338931560999981],
              [9.342786789000058, 6.340010644000188],
              [9.342851639000116, 6.340638161000186],
              [9.342905999000152, 6.341159345000108],
              [9.34296512700007, 6.341699123000183],
              [9.343062401000111, 6.3425903320001],
              [9.343153953000069, 6.34304523600008],
              [9.343207358000143, 6.343308448000016],
              [9.343266486000061, 6.344001293000076],
              [9.34332942899999, 6.344727517000138],
              [9.343156814000054, 6.345455646000062],
              [9.343144418000065, 6.345507146000045],
              [9.343220711000072, 6.346281528000191],
              [9.343276024000147, 6.346558093000056],
              [9.343394280000155, 6.347152233000031],
              [9.343402862000119, 6.34802579899997],
              [9.343562127000098, 6.349113941000155],
              [9.343501092000111, 6.349943162000102],
              [9.343470573000161, 6.350531102000048],
              [9.343441964000022, 6.351086140000177],
              [9.345125198000176, 6.352316380000161],
              [9.345347404000108, 6.352478981000047],
              [9.348309517000132, 6.353911400000186],
              [9.350005149000083, 6.353621959000122],
              [9.350828170000057, 6.353481293000186],
              [9.352433205000068, 6.352984429000117],
              [9.353833199000121, 6.352287292000085],
              [9.354904174000183, 6.351753712000175],
              [9.361059190000162, 6.348548889000085],
              [9.362432479000177, 6.347833633000107],
              [9.362741471000163, 6.347620488000132],
              [9.36439037299999, 6.346482754000135],
              [9.373220444000083, 6.343720437000116],
              [9.380736352000042, 6.340886117000082],
              [9.386762619000137, 6.338492870000039],
              [9.387470245000031, 6.3379549980001],
              [9.393215179000038, 6.333588123000027],
              [9.408886909000159, 6.327010632000167],
              [9.413949966000132, 6.323805809000135],
              [9.415686607, 6.321709156000111],
              [9.416954041000111, 6.321343422000041],
              [9.420272827000076, 6.320385934000058],
              [9.422830582000017, 6.319908142000088],
              [9.425769807000108, 6.319358826000155],
              [9.431278228000167, 6.317245007000111],
              [9.432669640000029, 6.320690631000105],
              [9.432704925, 6.321850778000112],
              [9.432754517000035, 6.323493957000039],
              [9.432958604000135, 6.325783252],
              [9.433269500999984, 6.326642037000113],
              [9.433457375000103, 6.327160836000132],
              [9.43353176100004, 6.327808380000079],
              [9.433614730000045, 6.328535081000098],
              [9.43414688200005, 6.329353809000168],
              [9.434288979000144, 6.329571724000118],
              [9.43436908700005, 6.329719544000113],
              [9.434849740000118, 6.330606938000187],
              [9.435376167000072, 6.331823350000093],
              [9.435530662000133, 6.332180024000138],
              [9.43640518300009, 6.333063126000184],
              [9.436700822000148, 6.333361627000102],
              [9.436969757000099, 6.334588052000072],
              [9.436820985000054, 6.33619642300016],
              [9.436939240000129, 6.337411404000079],
              [9.437335014000041, 6.338078023000094],
              [9.437586784000075, 6.338500978000184],
              [9.437856674000045, 6.339632988000062],
              [9.43800544800007, 6.34006214100009],
              [9.438174248, 6.340547083999979],
              [9.438421250000033, 6.341616631000136],
              [9.438684463000016, 6.342753887000072],
              [9.438901902000168, 6.343015672000149],
              [9.439226150000025, 6.34340572300016],
              [9.43967533100016, 6.344153881000068],
              [9.440141678000089, 6.344860076000146],
              [9.440259934000096, 6.345038892000048],
              [9.440318109000032, 6.345839977000139],
              [9.441100120000158, 6.347516060000032],
              [9.441370963000111, 6.347832680000067],
              [9.442021371000067, 6.348593236000113],
              [9.441558837, 6.349720956000169],
              [9.441135407000161, 6.35038471200005],
              [9.440674783000077, 6.352170468000168],
              [9.440789223000081, 6.353229523000152],
              [9.440834045000088, 6.353650093000113],
              [9.44097328200013, 6.354060172000061],
              [9.441221236000047, 6.354788304000067],
              [9.441657067000165, 6.355031968000162],
              [9.44195079800005, 6.355196475000184],
              [9.44328403500009, 6.355210781000096],
              [9.443653107000102, 6.356119156000034],
              [9.443170547000136, 6.356768131000024],
              [9.442889214000047, 6.357146264000107],
              [9.442893029000118, 6.35741329199999],
              [9.442914963000135, 6.359076500000128],
              [9.4431562420001, 6.360106945000041],
              [9.443380355000102, 6.361063003000083],
              [9.443013189999988, 6.363289357000099],
              [9.442774772000178, 6.364941597000041],
              [9.442642213000113, 6.365858555000045],
              [9.443305016000181, 6.366724968000028],
              [9.44365692100007, 6.367184639000186],
              [9.444180490000122, 6.36826658300015],
              [9.444022180000161, 6.368773937000015],
              [9.443981171000075, 6.368903638000177],
              [9.444146156000102, 6.36947727200004],
              [9.444446565000021, 6.369938374000185],
              [9.444596292000085, 6.370168209000099],
              [9.445247650000113, 6.371041775000094],
              [9.445735930000012, 6.373034001000065],
              [9.445884705000026, 6.37363863000013],
              [9.445005416000186, 6.374576091000108],
              [9.444475174000161, 6.376057148000029],
              [9.444496155000024, 6.377244473000133],
              [9.444502830000147, 6.377635002000034],
              [9.444896698000036, 6.37852382599999],
              [9.445073127000171, 6.378922462000105],
              [9.445164681000108, 6.379424096000093],
              [9.445383071000038, 6.380628109000099],
              [9.445292472000119, 6.381028176000086],
              [9.445145607000143, 6.381679536000149],
              [9.446109772000057, 6.38304567300014],
              [9.447426797000048, 6.38324737500011],
              [9.448505402000023, 6.383811951000098],
              [9.449302673000034, 6.384229183000173],
              [9.45059204, 6.386530399000151],
              [9.450345994000088, 6.388129234000189],
              [9.450407982000058, 6.388790607000146],
              [9.450491904999979, 6.389684677000048],
              [9.450483323000185, 6.39047432100017],
              [9.45047760000017, 6.390952111000104],
              [9.450794220000034, 6.392489433000094],
              [9.451772689999984, 6.394501687000172],
              [9.451642037000113, 6.395997525000098],
              [9.451621056000079, 6.396234512999968],
              [9.451600075000044, 6.398168564000059],
              [9.451910972000064, 6.39853239100006],
              [9.453443527000161, 6.400323392000189],
              [9.453743935000148, 6.401604174999989],
              [9.454097747000105, 6.402925492000179],
              [9.454403877, 6.404069423000067],
              [9.454841614000088, 6.4058475490001],
              [9.455766678000089, 6.407553196000094],
              [9.456261635999965, 6.408553601000165],
              [9.456800461000057, 6.409643174000166],
              [9.45684146799999, 6.411130429000025],
              [9.456939698000156, 6.412618161000069],
              [9.456765174999987, 6.413834573000145],
              [9.456753731000106, 6.413911819000134],
              [9.456622123000045, 6.414767266000126],
              [9.457139015, 6.415296079000086],
              [9.457676887000105, 6.415845872000034],
              [9.457857133000118, 6.417648791999966],
              [9.457743645000107, 6.418763161000072],
              [9.4576339730001, 6.419847965000145],
              [9.456802369000059, 6.420553208000115],
              [9.45561409000004, 6.421560288000137],
              [9.456536294000159, 6.422637940000129],
              [9.457359315000133, 6.422646999000108],
              [9.45760345399998, 6.42264938400001],
              [9.457879067000135, 6.422791005000136],
              [9.458531380000011, 6.42312669800009],
              [9.458813668000119, 6.424372674000153],
              [9.458849907000172, 6.424530984000114],
              [9.458433150000076, 6.42606067600002],
              [9.460099221000178, 6.426145555],
              [9.460492134000106, 6.428225517000158],
              [9.460540771000126, 6.428484917000162],
              [9.458238601000062, 6.431662083000106],
              [9.457908631000123, 6.43234062300013],
              [9.457625390000032, 6.43292284000006],
              [9.460071564000089, 6.434884072000159],
              [9.460195540000029, 6.436049462000142],
              [9.460312843000054, 6.437154771],
              [9.460554122000076, 6.439492225000151],
              [9.461393356000031, 6.440392970000175],
              [9.461873054000137, 6.440907479000032],
              [9.461915971000053, 6.44125318499999],
              [9.46217918300016, 6.443379402000176],
              [9.46349716200001, 6.444861412000023],
              [9.463465691000181, 6.445673943000031],
              [9.463404656000023, 6.447261810000043],
              [9.463253976000146, 6.448861122000039],
              [9.463248253000131, 6.449327946000096],
              [9.463241578, 6.449928284000066],
              [9.464124679000179, 6.453084945],
              [9.464488984000013, 6.454388141000038],
              [9.466995239000028, 6.455812455000114],
              [9.467367172000138, 6.45602369300002],
              [9.467670441000109, 6.456195831],
              [9.510541917000069, 6.443979741000021],
              [9.513636589000157, 6.443098069000087],
              [9.518320083999981, 6.443180561000133],
              [9.520549774000074, 6.443220140000108],
              [9.528005600000086, 6.443352223000147],
              [9.541947364000066, 6.449392797000087],
              [9.568456651000076, 6.464035988000092],
              [9.569494247000137, 6.464608670000018],
              [9.583552361000159, 6.472373485999981],
              [9.585883139999964, 6.473599434000164],
              [9.587982178000118, 6.475166322000177],
              [9.587274552, 6.476882935000162],
              [9.587160110000127, 6.477159500000028],
              [9.585935592000055, 6.479376794000189],
              [9.58528232700013, 6.481657981000183],
              [9.585145950000083, 6.483715535000101],
              [9.585176467000053, 6.484472753000034],
              [9.585246085000108, 6.486206053999979],
              [9.584957124000084, 6.488271236000173],
              [9.58447170300019, 6.490001202000144],
              [9.583124161000058, 6.491254331000164],
              [9.582773209000152, 6.49285173300018],
              [9.583025933000101, 6.49418926300018],
              [9.583291053000039, 6.495013714000095],
              [9.58354187000009, 6.495796204000158],
              [9.583941459000187, 6.496579647999965],
              [9.584260941000139, 6.497204780000175],
              [9.584323882000092, 6.497829914000022],
              [9.584441185000117, 6.4990081790001],
              [9.584434509000118, 6.499686718000021],
              [9.584426879000034, 6.500409603000037],
              [9.584730148000176, 6.503214836000097],
              [9.585182190000069, 6.504621030000067],
              [9.585876465000069, 6.508298397000147],
              [9.586286544000132, 6.508958340000106],
              [9.586793900000032, 6.509776116000126],
              [9.587095261000172, 6.509915353000167],
              [9.588122367000096, 6.510390757999971],
              [9.588936806000049, 6.511603355000034],
              [9.58956623000006, 6.512541771000088],
              [9.591878891000022, 6.514635086000169],
              [9.592665673000113, 6.515911103000121],
              [9.592642784000077, 6.518099786000107],
              [9.592613220000146, 6.520848275000049],
              [9.592859269000144, 6.52278566300015],
              [9.593776702000127, 6.524071216000039],
              [9.594107628000131, 6.524534225000025],
              [9.594136239000136, 6.52498245400011],
              [9.594222068000022, 6.526336670000035],
              [9.594839096999976, 6.528948307000064],
              [9.596094131000086, 6.529763698000124],
              [9.597167016000128, 6.530460358000028],
              [9.599738120999973, 6.530716895000182],
              [9.600720405000061, 6.53045034500019],
              [9.602777481000089, 6.529891491000115],
              [9.605015754000135, 6.529114723000134],
              [9.60731601700013, 6.527938367000161],
              [9.608929635000038, 6.526754379000067],
              [9.610767364000139, 6.526030540000136],
              [9.612149238000086, 6.52518749300009],
              [9.613753318000079, 6.524918556000159],
              [9.615131378000058, 6.524418830000172],
              [9.616162301000031, 6.524258137000118],
              [9.617421150000155, 6.524214268000151],
              [9.618793487000119, 6.52422905100002],
              [9.620792388000041, 6.524422170000094],
              [9.623138428000175, 6.524275781000142],
              [9.623909949000165, 6.524055005000037],
              [9.624273301000187, 6.523950578000154],
              [9.625642776000063, 6.523558617000163],
              [9.625834465000025, 6.523503780000169],
              [9.627673148000042, 6.522722721000093],
              [9.629334451000091, 6.5224542630001],
              [9.630312919000062, 6.521892549000143],
              [9.63192367500011, 6.520994664],
              [9.632697106000023, 6.520370960000093],
              [9.632904053, 6.520204067000066],
              [9.633577347000141, 6.520175934000122],
              [9.633991242000036, 6.520158768000158],
              [9.634568214000126, 6.520200730000056],
              [9.634904862000099, 6.520225526000161],
              [9.635781288000146, 6.520643234999966],
              [9.636705399000164, 6.521587372000056],
              [9.63836669900013, 6.522722245000068],
              [9.64042186800009, 6.52308750200018],
              [9.642138481000075, 6.52304840100004],
              [9.643852234, 6.523180962000083],
              [9.644825935000029, 6.52350425700007],
              [9.645057678000114, 6.523581028000137],
              [9.645277023000062, 6.523653984000134],
              [9.645755768000129, 6.523658752000188],
              [9.646420478999971, 6.523665905000144],
              [9.646916390000058, 6.523671150000155],
              [9.647335052000074, 6.523675917999981],
              [9.647798539000121, 6.523707867000042],
              [9.648306847000129, 6.523743153000169],
              [9.648769378999987, 6.523842334999983],
              [9.649319650000166, 6.523960113000157],
              [9.649952889000076, 6.524397851000117],
              [9.65009498600017, 6.52460527400018],
              [9.650412559000074, 6.525069713000107],
              [9.650425912, 6.525361062000172],
              [9.65046691900011, 6.526271344000179],
              [9.6507854460001, 6.527316093000025],
              [9.650888442999985, 6.528404237000132],
              [9.65167999300013, 6.529213429000151],
              [9.652883530000167, 6.528997422000145],
              [9.653976441000054, 6.528379917999985],
              [9.654481887000145, 6.527869702000089],
              [9.655884743000058, 6.526455403],
              [9.656155586000182, 6.524756431000128],
              [9.656312943000103, 6.523771287000159],
              [9.65627193500012, 6.522283555000172],
              [9.657037734000141, 6.52171278100019],
              [9.657276153000055, 6.521534442000075],
              [9.657482147000053, 6.521381378000058],
              [9.658803939000165, 6.520823479000171],
              [9.659951211000077, 6.520492075999982],
              [9.660520555000119, 6.520255090000092],
              [9.662363051000114, 6.519488334000073],
              [9.663299560000098, 6.517496108000103],
              [9.664580345000104, 6.515392780000127],
              [9.664907455000162, 6.514779568000051],
              [9.66533851600002, 6.51397085300016],
              [9.665583610000056, 6.513619899000105],
              [9.666210174000014, 6.51272153900004],
              [9.666968345000157, 6.511908054000116],
              [9.667424201000017, 6.511418821000063],
              [9.668286324000064, 6.511027336999973],
              [9.668536186000097, 6.510926247000157],
              [9.668975831000182, 6.510748864000107],
              [9.669663428000149, 6.510641574000147],
              [9.670464516000152, 6.510592937000126],
              [9.671713829000055, 6.510881901000062],
              [9.672220230000107, 6.511013984000044],
              [9.672779084000183, 6.511159896000038],
              [9.673126221000189, 6.511450290000084],
              [9.673505783000167, 6.511768342000096],
              [9.673497200000099, 6.512588501000039],
              [9.673494338000182, 6.51290273799998],
              [9.673614501000088, 6.513001920000022],
              [9.674491882000154, 6.513724327000034],
              [9.675801278000108, 6.514253139000061],
              [9.67655467899999, 6.514167309000072],
              [9.677175523000017, 6.514096261000077],
              [9.677684784000178, 6.513903142000174],
              [9.678496360000167, 6.513595104999979],
              [9.679530143000022, 6.513205529000118],
              [9.680256843999985, 6.513073921000057],
              [9.681019783000124, 6.512935639000148],
              [9.682337762000032, 6.51268625300014],
              [9.683025360000102, 6.512556076000067],
              [9.683727265000186, 6.512493610999968],
              [9.684169770000096, 6.512454033999973],
              [9.685367584000062, 6.512526513000069],
              [9.686456680000163, 6.512592316000053],
              [9.687633515000073, 6.513519287000122],
              [9.688255310000102, 6.514105797000127],
              [9.688491821000014, 6.514328957000146],
              [9.690021515000126, 6.515422821000072],
              [9.69015312099998, 6.515516758000103],
              [9.690767287000028, 6.515955926],
              [9.69362545100006, 6.516100407000181],
              [9.695867539000119, 6.51503706],
              [9.696464539000147, 6.513940811000168],
              [9.696858405000057, 6.513216973000169],
              [9.697172165000097, 6.51187038400019],
              [9.697217942000066, 6.511676313000066],
              [9.698161126000116, 6.511079788000131],
              [9.69827651900016, 6.511042596000095],
              [9.698499680000055, 6.511001586000077],
              [9.70025920900008, 6.51067829100009],
              [9.701474189000066, 6.510728359000098],
              [9.701858520000144, 6.512267590000022],
              [9.702446937000048, 6.512650491000159],
              [9.702588080999988, 6.512742520000074],
              [9.703317643000162, 6.513570786],
              [9.703742028000022, 6.513890744000037],
              [9.704395293000175, 6.514383317000181],
              [9.706126212000129, 6.515422344000115],
              [9.70634555700002, 6.515554429000133],
              [9.706363678000059, 6.515593052000043],
              [9.706538201000171, 6.516197682000154],
              [9.706654549, 6.516602516000035],
              [9.706747056000154, 6.51692390400018],
              [9.706936837000114, 6.51731825000013],
              [9.707162858000061, 6.517787457000111],
              [9.707438468000134, 6.519247055000108],
              [9.707541466000123, 6.519793988000174],
              [9.707639695000182, 6.52087068600008],
              [9.70788192700013, 6.521392822999985],
              [9.708204269000021, 6.522089006000158],
              [9.708686829000158, 6.52294015800004],
              [9.708882331000154, 6.52328634200012],
              [9.708925248000071, 6.524250985000037],
              [9.709044456000129, 6.526958466000167],
              [9.709126472000037, 6.527779580000072],
              [9.709164619000035, 6.52816105],
              [9.709196091000024, 6.528292656000076],
              [9.709203721000108, 6.528323650000175],
              [9.70922279399997, 6.528404237000132],
              [9.709484099, 6.529499053000166],
              [9.709668160000092, 6.530368329000112],
              [9.70973873100013, 6.530703067000104],
              [9.710150718000023, 6.531351567000115],
              [9.71100902600017, 6.532702446000087],
              [9.711165428000129, 6.533143521000056],
              [9.711620332000109, 6.534424781000041],
              [9.711817741, 6.536063671000079],
              [9.711812973000178, 6.536447049],
              [9.711807251000039, 6.536998272000062],
              [9.711456299000133, 6.537720681000053],
              [9.711194039000134, 6.538259506000145],
              [9.710936547000131, 6.53895568900009],
              [9.710506440000131, 6.54012107799997],
              [9.71129703400004, 6.541130543000122],
              [9.711446761000047, 6.541316032000111],
              [9.712219238000103, 6.54227447400018],
              [9.712938309000037, 6.543683530000067],
              [9.712928773000101, 6.543795585000169],
              [9.712862015000098, 6.544617176000031],
              [9.713582039000187, 6.54595947200005],
              [9.713816642000097, 6.546815872000025],
              [9.71383857800015, 6.546896458000163],
              [9.713854788999981, 6.546937943000103],
              [9.714168549000021, 6.547724247000133],
              [9.714429856000038, 6.548133373999974],
              [9.714754104000065, 6.548641682000152],
              [9.715007782999976, 6.549911976999965],
              [9.715530395000087, 6.550985337000157],
              [9.715892792000147, 6.552319050000108],
              [9.716219902000034, 6.553810120000037],
              [9.718147278000174, 6.555778027000031],
              [9.71848392600009, 6.556122303000109],
              [9.719947815000182, 6.558311463000052],
              [9.719979286000012, 6.560714721000124],
              [9.719561577000036, 6.562369346000139],
              [9.719325064999964, 6.563110828000049],
              [9.719281196000168, 6.563228608000031],
              [9.719089507000035, 6.563737392000178],
              [9.719088554000052, 6.563821316000031],
              [9.7190818790001, 6.56448125899999],
              [9.718778610000186, 6.565701962000048],
              [9.719070434000173, 6.566283704],
              [9.719286919000012, 6.566714287000025],
              [9.719402314000092, 6.567489623000085],
              [9.719675064000057, 6.569315434000032],
              [9.719794273000105, 6.570651531000181],
              [9.721188545000189, 6.571333408000044],
              [9.720993996000118, 6.573196411000083],
              [9.72096538500017, 6.573466778000181],
              [9.72101783700009, 6.57351255500015],
              [9.721891403000029, 6.574277401000188],
              [9.722072602000026, 6.576081276000139],
              [9.722867011000119, 6.576690197000175],
              [9.72281646700003, 6.577913762000037],
              [9.72277641200003, 6.578891755000029],
              [9.723182679000161, 6.580890656000179],
              [9.723277093000149, 6.581353665000165],
              [9.724035263000133, 6.58289766300004],
              [9.724418639000078, 6.584503651000034],
              [9.724483490000011, 6.584729196000126],
              [9.724915505000183, 6.586225033000176],
              [9.72506904700009, 6.587943077000034],
              [9.725392342000077, 6.588856220000025],
              [9.725679398000011, 6.589665890000049],
              [9.725758552, 6.590416908000179],
              [9.725835800000027, 6.591155052000147],
              [9.72585010600011, 6.59138012000011],
              [9.725872994000042, 6.59174299200015],
              [9.725982665000174, 6.593444824000073],
              [9.726289748999989, 6.59412908600001],
              [9.726726532000157, 6.595103265000091],
              [9.727371215000119, 6.597406865000096],
              [9.727441788000022, 6.598262788000113],
              [9.727522850000128, 6.599239349000186],
              [9.727775574000077, 6.600295543000186],
              [9.727961540000024, 6.601074697000172],
              [9.729534149000017, 6.603837491000149],
              [9.730711937000137, 6.606023789000176],
              [9.73084068300011, 6.606464862000166],
              [9.731098175000113, 6.607344151000177],
              [9.73099136400009, 6.608134270000107],
              [9.73091220800012, 6.60871505800003],
              [9.730924606000087, 6.609025479000025],
              [9.731001855000159, 6.611004352000066],
              [9.731568337000113, 6.612364293000042],
              [9.731839179000133, 6.613015652000058],
              [9.733885765000025, 6.614238739000086],
              [9.734941482000068, 6.614993096000035],
              [9.735530852000181, 6.615635872000155],
              [9.735930443000086, 6.616071224999985],
              [9.735706330000085, 6.618338108000046],
              [9.736233710000022, 6.619355679000137],
              [9.736700057000178, 6.619900227000073],
              [9.737025260000166, 6.620279312000093],
              [9.737236022000047, 6.621047498000053],
              [9.737355233000017, 6.621484281000051],
              [9.737401008000177, 6.621564865000153],
              [9.737474441000131, 6.621694088000083],
              [9.737491607000095, 6.621724129000029],
              [9.737553597000101, 6.621833325000182],
              [9.738272666000171, 6.623103142000161],
              [9.738607405999971, 6.623693467000066],
              [9.738917351000112, 6.624239445000114],
              [9.739110946999972, 6.62458086100014],
              [9.739027976000159, 6.625564099000144],
              [9.73902606900009, 6.625895023000169],
              [9.739024163000067, 6.626197814000079],
              [9.739021301000037, 6.62657499400018],
              [9.739017485999966, 6.627049447000104],
              [9.739225388000136, 6.627895356000181],
              [9.739341735999972, 6.628370286000063],
              [9.739459039, 6.628789426000083],
              [9.739851952000095, 6.630195141000115],
              [9.740356446000078, 6.631343365000078],
              [9.740764618000128, 6.632273674000089],
              [9.740870475000065, 6.634877681000148],
              [9.741143228, 6.63551044500008],
              [9.741391182000086, 6.636084557000174],
              [9.742694855000025, 6.639035226000146],
              [9.743091584000126, 6.639548779999984],
              [9.743682862000185, 6.640313626000079],
              [9.744075775000113, 6.640430451000043],
              [9.744613648000097, 6.640590668000073],
              [9.744659425000066, 6.642593385000112],
              [9.744922637000173, 6.64323425300006],
              [9.745375634000084, 6.644336223999972],
              [9.74521446100016, 6.644849778000037],
              [9.745053291000147, 6.645365715000025],
              [9.744608880000044, 6.647331237000117],
              [9.74499511800002, 6.648670197000058],
              [9.745119094000188, 6.64994335200015],
              [9.745277404000149, 6.650091649000046],
              [9.74590873600016, 6.650681972000143],
              [9.746761321000065, 6.651562690000162],
              [9.747637749000091, 6.651902199000119],
              [9.748128890000146, 6.652092457000038],
              [9.749666214000172, 6.652795315000105],
              [9.75028800900003, 6.653488160000165],
              [9.75085067800012, 6.654409886000053],
              [9.751465797000094, 6.65573215600017],
              [9.752185822000115, 6.658665181000117],
              [9.752507210000033, 6.659976960000051],
              [9.752991675999965, 6.662842751000085],
              [9.752971649000187, 6.663460255000018],
              [9.75290679900013, 6.66547346200008],
              [9.752985955000156, 6.66675663000018],
              [9.753101347999973, 6.668622495000136],
              [9.753154755000082, 6.669715406000023],
              [9.753195763000065, 6.670568466000134],
              [9.753101347999973, 6.674057484000116],
              [9.751574517000051, 6.675590991000092],
              [9.75090313000004, 6.676265240000021],
              [9.75065231300016, 6.67832231400007],
              [9.751382828000089, 6.679531574000066],
              [9.756082535000189, 6.67883777600008],
              [9.756542207000052, 6.679288864000057],
              [9.757497787000034, 6.680226326000025],
              [9.758986474000039, 6.680725097000163],
              [9.759436607000055, 6.680876256000147],
              [9.762179374000084, 6.681248664000066],
              [9.763447761000123, 6.680289746000142],
              [9.764412881000055, 6.680712701],
              [9.764394760000073, 6.682381154000154],
              [9.764776230000166, 6.684120656000175],
              [9.764884948000088, 6.684996129000069],
              [9.76498794500003, 6.686084748000155],
              [9.765081405000103, 6.686793804000047],
              [9.764804839000135, 6.687725067000088],
              [9.764089585000136, 6.689850807000084],
              [9.763905525000155, 6.691050530000155],
              [9.762699128000065, 6.691438199000174],
              [9.761897088000183, 6.691544056000112],
              [9.76068782800013, 6.691952705000119],
              [9.758853913000166, 6.692598343000043],
              [9.756777763000173, 6.694120884000029],
              [9.756073952000122, 6.695715427000039],
              [9.756400108000094, 6.697320939000178],
              [9.756785393000087, 6.698755264000113],
              [9.757121086000041, 6.699445724000043],
              [9.757570267000176, 6.700251102000095],
              [9.758418082000105, 6.701175690000184],
              [9.759969712000043, 6.703950405000171],
              [9.76008415299998, 6.705687046000094],
              [9.760117531999981, 6.70817375300004],
              [9.760729790000028, 6.709782601000086],
              [9.761057853000068, 6.711216450000165],
              [9.761674880000101, 6.712309837000134],
              [9.761208533000115, 6.713162899000167],
              [9.760653497000021, 6.714836598000034],
              [9.760232925000025, 6.71517181400003],
              [9.760174752000125, 6.715899468000032],
              [9.759391785000105, 6.717091561000188],
              [9.759716033000075, 6.718174457000089],
              [9.75988960300009, 6.718755722000083],
              [9.761021614000015, 6.719912053000144],
              [9.762039184, 6.721010209000042],
              [9.763168335000103, 6.722452640000086],
              [9.763762475000078, 6.723813534000101],
              [9.764221192000093, 6.724619389000054],
              [9.764002799000025, 6.726285457000074],
              [9.764951706000147, 6.728354454000112],
              [9.765026092000085, 6.728516101000082],
              [9.765633583000124, 6.729840278000097],
              [9.765793799999983, 6.733579636000059],
              [9.765175819000092, 6.735108375000152],
              [9.76533317600007, 6.739114285000142],
              [9.765334129000053, 6.740106582000124],
              [9.765532493000137, 6.740488052000046],
              [9.766079904000037, 6.741025448000073],
              [9.767029763000039, 6.744416237000053],
              [9.767270087000043, 6.745275497000137],
              [9.768430709000143, 6.749092578000045],
              [9.768432617000087, 6.755099297000072],
              [9.767444610000155, 6.755853652000042],
              [9.765660286000127, 6.756180763000032],
              [9.765379906000021, 6.75623226200014],
              [9.763365745999977, 6.757297515000062],
              [9.763253211, 6.757642746000101],
              [9.762715339000124, 6.759293079000145],
              [9.763330460000077, 6.760615348000044],
              [9.764007569000057, 6.761480808000044],
              [9.764506340000025, 6.761863231000177],
              [9.765142440000091, 6.762351514000159],
              [9.765543938000121, 6.762693405000107],
              [9.765823364000141, 6.762930871000037],
              [9.766537665000158, 6.763273715000025],
              [9.766676902000029, 6.763340473000028],
              [9.766941071000133, 6.763662338000074],
              [9.76779556200006, 6.764703274000055],
              [9.768943786000023, 6.765424728000085],
              [9.769679070000109, 6.766233444000022],
              [9.770011903000011, 6.767210007000074],
              [9.770348549000175, 6.769402982000088],
              [9.770425796999973, 6.769886017000147],
              [9.770723344000032, 6.771743297000171],
              [9.771171570000149, 6.773912906000021],
              [9.771358491000058, 6.774820328000146],
              [9.773084639999979, 6.781913758000144],
              [9.774584770000104, 6.78520012000007],
              [9.776021004000143, 6.78821897600011],
              [9.778143882000109, 6.792155266],
              [9.779424667000114, 6.794529915000112],
              [9.780814172000021, 6.795018197000047],
              [9.782763481000131, 6.794695854999986],
              [9.784899711000151, 6.79294538500011],
              [9.786752700000079, 6.790962697000055],
              [9.790021896000042, 6.790368557000079],
              [9.792134285000088, 6.790791989000127],
              [9.79425811700014, 6.790242671000158],
              [9.795419693000156, 6.788652897000077],
              [9.796585083000139, 6.786720276000096],
              [9.796900748000041, 6.786734582000065],
              [9.797065735999979, 6.786741734000088],
              [9.798244476000036, 6.786795140000095],
              [9.799556732000042, 6.78720998700004],
              [9.80104637200003, 6.786997319000022],
              [9.801279068000099, 6.786902426999973],
              [9.802139281000109, 6.786550998000109],
              [9.803088188000061, 6.786745548000056],
              [9.803100585999971, 6.786776544000134],
              [9.803733826000155, 6.788423538000188],
              [9.803924560000155, 6.788920402000088],
              [9.804353715000048, 6.789336681000123],
              [9.806501388000186, 6.79141759900017],
              [9.807500840000103, 6.791628838000179],
              [9.809183121000103, 6.791019440000184],
              [9.809762956000043, 6.790809633000151],
              [9.809898377000138, 6.790557384000181],
              [9.811620713000025, 6.787334442000145],
              [9.813834191000183, 6.786356927000156],
              [9.815373421000174, 6.785319806000075],
              [9.817160607000062, 6.784137725000051],
              [9.819077492000133, 6.783142091000059],
              [9.820953370000098, 6.782633305000104],
              [9.822325707000061, 6.782762528000035],
              [9.823164939000037, 6.783028603000105],
              [9.824379921, 6.783413886000119],
              [9.826425552000103, 6.784808636000037],
              [9.829242706000116, 6.783580304000168],
              [9.830620766000095, 6.783095836000086],
              [9.831770897000126, 6.782691479999983],
              [9.834005357000081, 6.782486438000092],
              [9.83629989700006, 6.781939030000103],
              [9.836715698999967, 6.781882287000087],
              [9.837949754000022, 6.781714439000098],
              [9.838249206000114, 6.781673432000162],
              [9.84088420900008, 6.781472683000175],
              [9.843640328000163, 6.780643941000164],
              [9.845191001000103, 6.780145168000161],
              [9.847079277000034, 6.780165672000123],
              [9.848908425000047, 6.780414105000148],
              [9.851703644000111, 6.781301975000076],
              [9.856107711999982, 6.781577588000062],
              [9.858978272, 6.780807019000122],
              [9.862443924000047, 6.777868271000159],
              [9.863946915000156, 6.776453495000169],
              [9.866103173000056, 6.778250694000121],
              [9.883076668000115, 6.79153442400019],
              [9.886252403000071, 6.793872834000013],
              [9.88835906900016, 6.795423984000138],
              [9.899466513999983, 6.806070805000047],
              [9.911084176000031, 6.817181111000082],
              [9.919135093000136, 6.824591161000171],
              [9.938575744000104, 6.84316635100015],
              [9.946076393000112, 6.85068607400018],
              [9.946485520000124, 6.851096630000029],
              [9.946669579000172, 6.851281166000035],
              [9.948036193000121, 6.852651596000101],
              [9.950181961, 6.85483455800005],
              [9.964407920000156, 6.869306564000056],
              [9.966918944999975, 6.871494294000058],
              [9.975537301000031, 6.879003048000129],
              [9.975892068000178, 6.879312038000137],
              [9.989940644000114, 6.891551971000069],
              [9.999426841000116, 6.898863316000075],
              [10.003182412000058, 6.901134968000065],
              [10.007017136000115, 6.90166807200012],
              [10.009312629000078, 6.901987553000026],
              [10.013423919000047, 6.903146744000082],
              [10.0148553840001, 6.905908583999974],
              [10.019785882, 6.919564246000107],
              [10.020113945000105, 6.920471669000108],
              [10.023216247999983, 6.922545433000096],
              [10.026767732000053, 6.924919605000071],
              [10.028384208000034, 6.92588091000016],
              [10.03573226900005, 6.930250167000054],
              [10.043915748000074, 6.936345100000153],
              [10.048264504000031, 6.939481259000047],
              [10.049947738000014, 6.941798688000176],
              [10.050211906000015, 6.942162990000156],
              [10.05963134700005, 6.953336239000123],
              [10.062747955000134, 6.956690788000117],
              [10.065834045000031, 6.960011482000084],
              [10.066735267000183, 6.960533143000134],
              [10.067797662000089, 6.961148262000165],
              [10.072659492000184, 6.964547157000084],
              [10.076749802000052, 6.967766285000153],
              [10.079485892000093, 6.96908283200014],
              [10.117686272000128, 7.005539895000084],
              [10.12127113300005, 7.00789547],
              [10.126122475000159, 7.01241064200002],
              [10.134563446000186, 7.018852234000121],
              [10.141710281000087, 7.02570915299998],
              [10.146893501000136, 7.031869411000059],
              [10.148925781000059, 7.03428459200012],
              [10.153054237000049, 7.038171292000016],
              [10.153351784000051, 7.038451672000122],
              [10.153666495999971, 7.03837299300011],
              [10.162916184000039, 7.036060810000038],
              [10.165774346000092, 7.033944131000112],
              [10.165030479000109, 7.031102658000123],
              [10.163121224000179, 7.024557591000132],
              [10.163241387000085, 7.021295548000126],
              [10.165404321000153, 7.019858361000161],
              [10.16808414400009, 7.01842641900015],
              [10.177921295000033, 7.014577866000138],
              [10.178359985000043, 7.013768673000072],
              [10.17949771900004, 7.011674882000079],
              [10.177723885000034, 7.008651256],
              [10.175032615000077, 7.006880760000058],
              [10.172124863000022, 7.0049672130001],
              [10.171492576000048, 7.004551411000023],
              [10.168615341000077, 7.000228881000112],
              [10.171132087000103, 6.997850417000109],
              [10.171504020999976, 6.995106698000029],
              [10.174185752000085, 6.993416787000115],
              [10.174166679000052, 6.993389606000108],
              [10.172322273000191, 6.990736009000159],
              [10.176926613000148, 6.985974788000135],
              [10.180275918000063, 6.986180783],
              [10.182609557000148, 6.984830857000077],
              [10.180624961999968, 6.977254391999963],
              [10.181677818000026, 6.975118637000094],
              [10.1812782290001, 6.972195150000061],
              [10.18224811500005, 6.969800473000078],
              [10.184184074000143, 6.967033863000154],
              [10.184515954000062, 6.966560841000103],
              [10.183766366000043, 6.964234829000191],
              [10.184219360000043, 6.961921216000178],
              [10.184123040000031, 6.961555004000047],
              [10.18347454000002, 6.959080220000089],
              [10.180877686000088, 6.952528001000189],
              [10.179276466000147, 6.949506284000051],
              [10.178751946000091, 6.947029590999989],
              [10.178672790000121, 6.946654796000189],
              [10.178456307000033, 6.945633888000089],
              [10.178466798000102, 6.944573880000121],
              [10.178492547000189, 6.942113878000157],
              [10.176054000000136, 6.941926479000188],
              [10.174954414000126, 6.941841603000114],
              [10.173340798000027, 6.941717624000034],
              [10.173181533000047, 6.940428257000065],
              [10.17406368200011, 6.93820476500008],
              [10.176800727, 6.935065270000109],
              [10.17738342199999, 6.93439722100004],
              [10.217097283000101, 6.888836861000129],
              [10.222160340000073, 6.889402868000161],
              [10.334617615000013, 6.900908470000161],
              [10.396183968000059, 6.907444953000038],
              [10.396794319000037, 6.907509326000081],
              [10.410382270000127, 6.908951284000011],
              [10.410425187000044, 6.908905984],
              [10.410423279999975, 6.908955574000061],
              [10.410640717000149, 6.90897846300004],
              [10.413450241000078, 6.909276486000124],
              [10.444174766000174, 6.91253471400006],
              [10.463063239000064, 6.91477680200012],
              [10.467793465000113, 6.914156438000134],
              [10.468528748000097, 6.914059638000083],
              [10.469776153000055, 6.913896083000168],
              [10.473053932000084, 6.912724496000124],
              [10.477433205000125, 6.90417623500008],
              [10.480290413000091, 6.90205574100014],
              [10.482963562000066, 6.901136398],
              [10.486588479000034, 6.899624347000042],
              [10.490369795999982, 6.899660111000173],
              [10.490926742000056, 6.900030613000126],
              [10.492334366000023, 6.900967596999976],
              [10.493507385000157, 6.904157162000047],
              [10.493567466000059, 6.906906605000074],
              [10.493747712000072, 6.908898831000045],
              [10.494034768000176, 6.912065030000122],
              [10.495039940000026, 6.913556577000122],
              [10.49589920000011, 6.914831639000113],
              [10.50155067500009, 6.917118549000179],
              [10.514733315000115, 6.923201084000084],
              [10.516186713000138, 6.923871518000169],
              [10.517248153000025, 6.924088478000158],
              [10.519275665000066, 6.924502373000053],
              [10.523138998999968, 6.925054073000013],
              [10.52394294700008, 6.925311565000186],
              [10.52811050400004, 6.926647186000139],
              [10.528408051000042, 6.927434445000188],
              [10.528697014000045, 6.928199291000055],
              [10.530641555999978, 6.932637691000082],
              [10.532310486000085, 6.936447620000138],
              [10.532488822000062, 6.936703204000139],
              [10.532620431000055, 6.936891078000031],
              [10.53407478400004, 6.9384613040001],
              [10.535208702000034, 6.93968629900013],
              [10.536260604000063, 6.939452171000085],
              [10.53891277300005, 6.938861848000045],
              [10.540107727000134, 6.938724041000057],
              [10.540259361000039, 6.938706398000136],
              [10.540978432000145, 6.938623905000156],
              [10.54087448000007, 6.939213752000171],
              [10.540615083000034, 6.9406819350001],
              [10.539905547000103, 6.945581913000126],
              [10.53969574000007, 6.947030545000075],
              [10.540420531000052, 6.95410299200006],
              [10.540438652000091, 6.954279900000131],
              [10.540555955000116, 6.955419541000026],
              [10.540798187000064, 6.957779407000032],
              [10.540909767000073, 6.958542825000166],
              [10.541443824000112, 6.962166787000115],
              [10.541236877000131, 6.965858936000188],
              [10.541214943000057, 6.968178272000046],
              [10.542369843000074, 6.96935415199999],
              [10.543940544000066, 6.970953464000161],
              [10.54869556400007, 6.975503922000087],
              [10.551012040000046, 6.97772073900012],
              [10.551403046000075, 6.983730793000177],
              [10.551918983000064, 6.991660119000073],
              [10.551938056000097, 6.991956711000114],
              [10.552042960999984, 6.99356937400006],
              [10.552062989000035, 6.993881703000056],
              [10.55332756100006, 6.996557236000172],
              [10.553949354999986, 6.99815368600008],
              [10.555006027000047, 7.000868321000098],
              [10.554183959000056, 7.005929471000172],
              [10.555015565000133, 7.008944034000081],
              [10.554944039000077, 7.010219574000132],
              [10.554808616000116, 7.012636185000133],
              [10.555401803000109, 7.014795781000146],
              [10.558880806000104, 7.027451515999985],
              [10.560274124000102, 7.03468084300016],
              [10.560183525000127, 7.035209655000017],
              [10.559819221, 7.037340165000103],
              [10.557982444000174, 7.040586949000158],
              [10.558033943999988, 7.044195651000109],
              [10.557808876000024, 7.044895172],
              [10.55723571700014, 7.04667949800006],
              [10.557143212000028, 7.047962665000057],
              [10.557038307000141, 7.049427034000189],
              [10.557169914000099, 7.053637504000051],
              [10.557402610000167, 7.056303024000101],
              [10.558764458000041, 7.057776450000176],
              [10.558831214000065, 7.059752941],
              [10.559717178000028, 7.066032887000063],
              [10.559679986000106, 7.067075253000155],
              [10.559598923000067, 7.069382191000045],
              [10.55949783300008, 7.072095871000045],
              [10.559483527000111, 7.072474004000185],
              [10.559498788000042, 7.072641371999964],
              [10.559717178000028, 7.074967861000175],
              [10.55942249400016, 7.079971313000101],
              [10.55930709900008, 7.081922531999965],
              [10.559477807000178, 7.082739352999965],
              [10.559882163000054, 7.084677220000174],
              [10.56039333199999, 7.086475372000109],
              [10.560714722000114, 7.08760595300015],
              [10.560581208000087, 7.089070321000122],
              [10.560425759000111, 7.090782167000043],
              [10.561900138000169, 7.098214150000047],
              [10.56296920800014, 7.103607178000061],
              [10.563797952000129, 7.106879711000033],
              [10.563827515000185, 7.108390809000184],
              [10.563855172000103, 7.109801292999975],
              [10.563817978000031, 7.113666534000174],
              [10.565262795000137, 7.133354187000123],
              [10.565282820999983, 7.134772301000055],
              [10.565364838000107, 7.140657424000153],
              [10.566157340000132, 7.14346408800003],
              [10.566362382000079, 7.144189357000073],
              [10.566336631000127, 7.146852494000086],
              [10.566715241000054, 7.148567677000131],
              [10.567078590000165, 7.150209904000064],
              [10.567635536000068, 7.15249824600005],
              [10.568567275000134, 7.156323910000026],
              [10.570091247000107, 7.158830166000087],
              [10.572036743000126, 7.162285805000124],
              [10.574092864000022, 7.160703658000045],
              [10.574462891000167, 7.160418987000185],
              [10.575445175000084, 7.159991265000144],
              [10.577745438000079, 7.158989906000159],
              [10.583791733000055, 7.156384946000117],
              [10.585626602000048, 7.1534814850001],
              [10.5874881740001, 7.147657394000078],
              [10.589639663000185, 7.147592069000098],
              [10.596272468, 7.146624566000071],
              [10.597832679000021, 7.145350456000131],
              [10.599692344000175, 7.139783860000023],
              [10.601531030000046, 7.136364938000099],
              [10.601733207, 7.13327407800017],
              [10.602707863000035, 7.130276203000165],
              [10.602914810000186, 7.126584054000148],
              [10.602275848000033, 7.121508599000038],
              [10.601279258000034, 7.117719174000115],
              [10.601902962000111, 7.115491390000159],
              [10.606117248000089, 7.106424332000074],
              [10.607007027000122, 7.10333967300005],
              [10.60686302199997, 7.102825166],
              [10.606092454000191, 7.100066185000117],
              [10.605518342000096, 7.097054006000178],
              [10.605469703999972, 7.093186855000056],
              [10.604887962000021, 7.091033936000144],
              [10.60328102200009, 7.08818340300013],
              [10.602107047000061, 7.084993363000137],
              [10.600501061000045, 7.082057],
              [10.59770202600015, 7.077906609000138],
              [10.598145484000099, 7.076450348000037],
              [10.600483894000092, 7.074754238000026],
              [10.606368064000094, 7.070943355000111],
              [10.607421876000046, 7.068719863000126],
              [10.611174584000025, 7.062912940999979],
              [10.61291122300014, 7.061124802000109],
              [10.616544723, 7.05883932200004],
              [10.621395111000083, 7.0551905640001],
              [10.623993873000131, 7.053238870000087],
              [10.626082420000046, 7.050681115000089],
              [10.627641678000089, 7.049407005000035],
              [10.629609108000125, 7.050628185000164],
              [10.631207466000035, 7.050283432000128],
              [10.631470680000177, 7.050226689000169],
              [10.632280351000077, 7.050051688000167],
              [10.634435654000185, 7.049470426000028],
              [10.637377739000158, 7.047521592000066],
              [10.639266968000072, 7.047797203000073],
              [10.642451287000029, 7.047569275],
              [10.644956590000106, 7.046389579000106],
              [10.647459984000079, 7.045382022000126],
              [10.654706002000125, 7.043129921000059],
              [10.655460357000095, 7.045198918000097],
              [10.655504227000165, 7.046032905000118],
              [10.655679702000043, 7.049324989000184],
              [10.65644455000006, 7.050277234000021],
              [10.658668517000137, 7.051587105000124],
              [10.65992259899997, 7.051231384000062],
              [10.660135269000023, 7.051170826000032],
              [10.660910607, 7.05073166000011],
              [10.668348313000081, 7.046521663000021],
              [10.671291351000036, 7.044486523000046],
              [10.67456817700014, 7.043571949000068],
              [10.678020477000132, 7.042315006000024],
              [10.680191041000114, 7.040101052000182],
              [10.682358742000133, 7.038230897000062],
              [10.683583260000034, 7.036008358000117],
              [10.682121276000032, 7.026714802000186],
              [10.682497979000061, 7.023195267000176],
              [10.686451912000052, 7.019235134000155],
              [10.686577797000098, 7.019108773000085],
              [10.68654918600015, 7.019036770000128],
              [10.68556881, 7.016607761000103],
              [10.686524390000045, 7.015585423000061],
              [10.690985679000107, 7.016743183000131],
              [10.693566323000141, 7.016681194000057],
              [10.695293426000148, 7.01583767000011],
              [10.697028160000116, 7.014307023000185],
              [10.699100494000163, 7.013381005000099],
              [10.700561522999976, 7.013480187000141],
              [10.704014777000111, 7.012051105000069],
              [10.70523738900016, 7.010000229000184],
              [10.707874298000149, 7.003837585000099],
              [10.711114883000107, 6.997422695000068],
              [10.71509647400012, 6.994623184000034],
              [10.718646050000189, 6.992077827000173],
              [10.720821380000075, 6.989347936000172],
              [10.723569871000166, 6.989716531],
              [10.72855663200005, 6.989847660000123],
              [10.732078552000132, 6.990309239000055],
              [10.732698440999968, 6.991183757000101],
              [10.733181954000145, 6.991866112000139],
              [10.735474586000123, 6.991550447000066],
              [10.736109734000081, 6.9914631850001],
              [10.738444328000128, 6.990109444000098],
              [10.740351677000149, 6.988408089000131],
              [10.742362022000123, 6.984902858000055],
              [10.743849754000109, 6.982080935999989],
              [10.745715141000176, 6.980494499000088],
              [10.746676444000059, 6.979677201000186],
              [10.747749329000101, 6.978764535000153],
              [10.75014591200005, 6.974390030000109],
              [10.750267982000025, 6.974166870000147],
              [10.750328065000133, 6.974056722000057],
              [10.750372887000083, 6.973976136000147],
              [10.750299454000128, 6.972708226000066],
              [10.750224113000058, 6.971396922999986],
              [10.750068665000128, 6.969591140000091],
              [10.751277924000078, 6.968914509000115],
              [10.757150649999971, 6.966217517000075],
              [10.759582519000048, 6.96497249600003],
              [10.760779380000031, 6.96435976000015],
              [10.76097965200006, 6.964140415000031],
              [10.762255669000069, 6.96273994400002],
              [10.7636680600001, 6.958714008000186],
              [10.765485763000129, 6.957441331000155],
              [10.76618003800013, 6.957232952000027],
              [10.767986297000107, 6.956690311000159],
              [10.769249916000092, 6.959623336000107],
              [10.769751548000045, 6.960678578],
              [10.770685196000045, 6.962643625000169],
              [10.773244859000158, 6.964900971000077],
              [10.773864745000139, 6.965363502000059],
              [10.775807381000106, 6.966814518000035],
              [10.777448653000022, 6.967322349000028],
              [10.778380394000067, 6.967610835000073],
              [10.779807091000123, 6.96775054900013],
              [10.78224754300004, 6.967989445000171],
              [10.782994271, 6.967439652000053],
              [10.783286094000118, 6.967225075000158],
              [10.786571503000062, 6.965278148000095],
              [10.788997650000169, 6.963237285000048],
              [10.790395737000097, 6.960843563000083],
              [10.791862488000049, 6.960340977000044],
              [10.798375129000078, 6.96297693300005],
              [10.800700188000121, 6.962654113000156],
              [10.801090241000168, 6.962421417000087],
              [10.802688599000078, 6.961468220000143],
              [10.805997849000164, 6.956857204000187],
              [10.811895370000059, 6.95132350900019],
              [10.814841270000102, 6.948857309000061],
              [10.819152832000157, 6.947520256000189],
              [10.824250221000113, 6.946130753000091],
              [10.826480866, 6.945522785000037],
              [10.832959175000042, 6.942399979000129],
              [10.837882995000086, 6.939950944000088],
              [10.839362144000177, 6.93980837],
              [10.841154098000118, 6.939635754000108],
              [10.841724395000142, 6.936686516000179],
              [10.841784478000079, 6.936375619000103],
              [10.843460084000128, 6.931577682000068],
              [10.844586372000094, 6.930642128000102],
              [10.844092369000123, 6.928145408000091],
              [10.843297004000135, 6.920747757000072],
              [10.841485024000121, 6.917102813000099],
              [10.840593336999973, 6.915309429000047],
              [10.840477944000156, 6.915063380000049],
              [10.839580535000039, 6.913152218000164],
              [10.836880683000118, 6.90711259800014],
              [10.833105086000103, 6.896337033],
              [10.831964493000157, 6.893032550999976],
              [10.831260682000106, 6.890992642000072],
              [10.830042840000033, 6.889007092000099],
              [10.827869416000169, 6.885462760999985],
              [10.827630042000067, 6.883312225000054],
              [10.827432633000171, 6.882467748000067],
              [10.826791763000074, 6.879719257000147],
              [10.826303482000071, 6.877628326000092],
              [10.824783326000045, 6.874435424000012],
              [10.82421589, 6.872197629000084],
              [10.82416153000014, 6.87198352900009],
              [10.823952674999987, 6.871162415000185],
              [10.824334145000137, 6.870444775000181],
              [10.825089455000068, 6.869023800000093],
              [10.825978280000186, 6.865680219000126],
              [10.824711799999989, 6.863004686000181],
              [10.821715355000151, 6.861517906000074],
              [10.821145058000127, 6.860792637000031],
              [10.819673539000121, 6.858922005000125],
              [10.818587303000129, 6.855665207000129],
              [10.818433761000051, 6.855204583000045],
              [10.817747117000067, 6.853147029000127],
              [10.820591927000123, 6.852226258000087],
              [10.822178842000028, 6.850379944000054],
              [10.822937965000051, 6.849496843000111],
              [10.822616577000133, 6.848443031000159],
              [10.822017669000104, 6.846480847000123],
              [10.820063590000188, 6.843799591000163],
              [10.821889878000093, 6.84140920599998],
              [10.823116303000063, 6.838841916000035],
              [10.824090959000046, 6.835498810000161],
              [10.826195717000132, 6.833113669999989],
              [10.826349257000061, 6.832940102],
              [10.82634067500004, 6.832162858],
              [10.826301575, 6.828699588000177],
              [10.82630062000004, 6.828642846000093],
              [10.827279091000094, 6.827703477000114],
              [10.82846736900018, 6.826563836000048],
              [10.830028534000121, 6.825065613000163],
              [10.835853577000023, 6.817638875000114],
              [10.837317466000115, 6.816288472],
              [10.838714599000184, 6.814999581000052],
              [10.83952140700012, 6.813606263000054],
              [10.840546608000125, 6.811835288000168],
              [10.846577645000082, 6.810426235000023],
              [10.849165916000061, 6.80941724700017],
              [10.850661279000064, 6.805477143000132],
              [10.852383614000018, 6.805233956000166],
              [10.853317261000086, 6.806616783000095],
              [10.853956223000068, 6.808255195000129],
              [10.854495049000036, 6.809635162000177],
              [10.854899407000119, 6.810141564000162],
              [10.855144501000154, 6.810448646000168],
              [10.855601310000168, 6.811019897000108],
              [10.856982230000028, 6.810344220000047],
              [10.859660148000103, 6.808512687000132],
              [10.859836579000046, 6.808392049000076],
              [10.860986710000077, 6.808161736000159],
              [10.863540650000175, 6.807650089000163],
              [10.864933968000173, 6.805531025000164],
              [10.865283012000134, 6.805000782999969],
              [10.86736488400004, 6.802783966000163],
              [10.870384216000105, 6.80169248500016],
              [10.871481895000102, 6.801167011000018],
              [10.874442101000056, 6.799750329000062],
              [10.874837875000139, 6.798930646000031],
              [10.875929833000043, 6.79666900699999],
              [10.877050400000144, 6.796334744000149],
              [10.879650115, 6.79569244400011],
              [10.880411148, 6.795504093000091],
              [10.88944244400011, 6.774867536000159],
              [10.895191193000187, 6.766063691000113],
              [10.896406173000173, 6.764368534000084],
              [10.896844863000013, 6.763757230000124],
              [10.898317337000037, 6.762480260000189],
              [10.899253846000136, 6.763605595000115],
              [10.899442672000134, 6.765378951000116],
              [10.899492264000173, 6.765842438000163],
              [10.900726318000125, 6.772384644000056],
              [10.904157638000186, 6.773359300000038],
              [10.904398917000151, 6.773254395000151],
              [10.906487465000112, 6.772347450000154],
              [10.907856942000024, 6.771141053000065],
              [10.909000397, 6.770133972000167],
              [10.910736084000177, 6.768103599000142],
              [10.910823823000101, 6.768000604000065],
              [10.911475181000128, 6.767283440000028],
              [10.912993432000064, 6.765612125000018],
              [10.913741112000139, 6.763669491000087],
              [10.914575577000164, 6.761499882000067],
              [10.917121888000111, 6.755161285000042],
              [10.91699028000005, 6.752629756000147],
              [10.916957855000078, 6.751999379000097],
              [10.916826249000053, 6.749485969000148],
              [10.916730880000046, 6.747176170999978],
              [10.916637421000075, 6.744898319000185],
              [10.916628838000122, 6.744687081000109],
              [10.916540146000102, 6.742521763000184],
              [10.915534974000082, 6.739333153000132],
              [10.915217398999971, 6.736236096000141],
              [10.915212631000145, 6.735964775000127],
              [10.915175437000073, 6.733794689000035],
              [10.915102006000097, 6.729531289000022],
              [10.915632248000122, 6.72625112500009],
              [10.91562271100014, 6.726222991000043],
              [10.915714265000076, 6.72574567900017],
              [10.916121484000087, 6.723228456000186],
              [10.916643142999988, 6.720003605000045],
              [10.918212892000099, 6.717266559000109],
              [10.918219567000051, 6.71695613900016],
              [10.91835403400006, 6.710649491000083],
              [10.919232368999985, 6.708508014000074],
              [10.920373916000017, 6.707856656000161],
              [10.922830581000028, 6.706455230000017],
              [10.927697181000156, 6.70367908500009],
              [10.929220200000145, 6.702492237000115],
              [10.930207252000059, 6.701723098000173],
              [10.932117461000132, 6.699418545000185],
              [10.93411254900019, 6.697286130000066],
              [10.936161041000048, 6.695839405000186],
              [10.939646721000145, 6.693377972000178],
              [10.941471099000069, 6.691956043],
              [10.944665909000094, 6.689465999000049],
              [10.947082520000151, 6.688282491],
              [10.94814682000009, 6.694737911000175],
              [10.958251000000132, 6.691579818000093],
              [10.960472107000157, 6.690885543000093],
              [10.967283249000104, 6.688534737999987],
              [10.96793937699999, 6.688327313000173],
              [10.970731735000129, 6.687445642000114],
              [10.975862503000087, 6.690839768000103],
              [10.97878265300011, 6.691121579000082],
              [10.980781556000068, 6.688473225000166],
              [10.982401847000119, 6.687929630000042],
              [10.983282089000113, 6.687634468000113],
              [10.992201804000103, 6.687445642000114],
              [10.994278909000116, 6.687008381000112],
              [10.999905587000114, 6.690657615000077],
              [11.010106088000043, 6.698881625000183],
              [11.016545295000185, 6.703518867000184],
              [11.019262314, 6.707724572000075],
              [11.020567893000134, 6.709369182000103],
              [11.023064613000145, 6.712512494000123],
              [11.029063224000026, 6.714910984000142],
              [11.032321929000091, 6.71602630600006],
              [11.040152551000176, 6.711733818000141],
              [11.043526649000114, 6.712678432000132],
              [11.046511649000024, 6.712186814000177],
              [11.051922798000021, 6.709364891000178],
              [11.054442406000078, 6.709671498000034],
              [11.056077957000184, 6.713008880000132],
              [11.057215690000078, 6.715479851000055],
              [11.057429313000057, 6.715942860000098],
              [11.059892654000066, 6.716192246000105],
              [11.063848496000162, 6.713772773000187],
              [11.068590164000057, 6.710872650000113],
              [11.078010560000052, 6.70143556700009],
              [11.082482337000101, 6.694134713000153],
              [11.091664315000116, 6.685725211000147],
              [11.096622466999975, 6.682096004000186],
              [11.098476409000114, 6.679588796000189],
              [11.100076676000072, 6.673009873000069],
              [11.097736359000123, 6.664508342000147],
              [11.097476959000176, 6.661067486000036],
              [11.095964431000084, 6.656641961000048],
              [11.095256805000133, 6.644714357000112],
              [11.095881462000079, 6.638185024000165],
              [11.094755172000134, 6.635596752000026],
              [11.094572068000105, 6.629691601000047],
              [11.094513893000169, 6.622583867000117],
              [11.095119476999969, 6.618462087000125],
              [11.093910217000143, 6.611860752000041],
              [11.094873428000142, 6.606021881],
              [11.096977234000121, 6.600822925000102],
              [11.096393586000147, 6.595354557000064],
              [11.096254349999981, 6.594055176000154],
              [11.096229554000104, 6.593823910000026],
              [11.097852707000186, 6.591544151000107],
              [11.097962380000126, 6.584781646000067],
              [11.095907211000167, 6.576511861000142],
              [11.096974372999966, 6.54980993200013],
              [11.098027230000127, 6.539787770000146],
              [11.0973901750001, 6.533248424000135],
              [11.096861838000166, 6.527455330000066],
              [11.097289085999989, 6.524076939000054],
              [11.099142075000088, 6.521626472000094],
              [11.105403901000045, 6.519554139000093],
              [11.109821320000037, 6.518900394000127],
              [11.113680839000097, 6.516351224000061],
              [11.118597984000075, 6.517707348000158],
              [11.123882294000111, 6.523537159000171],
              [11.125535012000114, 6.525008202000095],
              [11.125598907000153, 6.525065900000072],
              [11.128493308000088, 6.527642250999975],
              [11.135469437000097, 6.529530525000098],
              [11.140037536000136, 6.53168630600004],
              [11.142426491000151, 6.534111977000066],
              [11.144949914000165, 6.533844949000184],
              [11.147502899000074, 6.52967977600008],
              [11.15415573100006, 6.529043197000078],
              [11.16010761199999, 6.530120372000113],
              [11.168112755000038, 6.524907590000112],
              [11.17075538600011, 6.524125099000116],
              [11.174016953000034, 6.524723054000106],
              [11.180834770999979, 6.525061608000101],
              [11.184275627000147, 6.524686337000048],
              [11.188087463000045, 6.528441429000054],
              [11.191351891000124, 6.528752803000032],
              [11.194292069000028, 6.529353619000176],
              [11.194352149000053, 6.529309749000106],
              [11.194415091999986, 6.529320717000132],
              [11.194408417000091, 6.529382230000181],
              [11.194556236000153, 6.529407501000037],
              [11.194545746000131, 6.529435635000027],
              [11.192101479000144, 6.535751821000076],
              [11.19173526700007, 6.538615228000083],
              [11.194831849000138, 6.538352490000023],
              [11.198236465999969, 6.536958695000067],
              [11.202372550000064, 6.535770894000109],
              [11.205741883000087, 6.534879208000064],
              [11.20803737599999, 6.534437657000012],
              [11.210634232000132, 6.534686566000119],
              [11.21284294100019, 6.536367417000179],
              [11.213812828000016, 6.537193299000137],
              [11.214583397000126, 6.537849903999984],
              [11.217345237000188, 6.53933715900007],
              [11.217862128000036, 6.539615155000149],
              [11.21999073000012, 6.539547444000107],
              [11.220049858000039, 6.539545536000162],
              [11.222219467000059, 6.539476395],
              [11.225031851999972, 6.538866997000071],
              [11.227859497000111, 6.53630828900009],
              [11.229724884000177, 6.535260677999986],
              [11.230680465999967, 6.534724235000113],
              [11.231774330000064, 6.534333707000144],
              [11.233668327000032, 6.533657550000044],
              [11.234416962000068, 6.53346538600016],
              [11.235620498000173, 6.533156395000049],
              [11.236348152000176, 6.532272338000041],
              [11.237356187000159, 6.531048297999973],
              [11.238692284000138, 6.528650283000104],
              [11.239031792000162, 6.527679920000026],
              [11.239453315000105, 6.526477814000032],
              [11.239800453999976, 6.525835991000122],
              [11.239872932000139, 6.525702000000138],
              [11.240964889000168, 6.523679733000051],
              [11.241635322000093, 6.523461820000023],
              [11.242860795000183, 6.523063659000059],
              [11.245667458000128, 6.523199083000066],
              [11.247094155000127, 6.52401208800012],
              [11.248233796000022, 6.524938107000082],
              [11.24982738600005, 6.52644062000013],
              [11.252339364000079, 6.52777767200007],
              [11.254862785000057, 6.527624608000053],
              [11.256639480000047, 6.527523041000052],
              [11.257788659000028, 6.528956890000131],
              [11.258627891000174, 6.530003071000124],
              [11.261363984000184, 6.532029629000078],
              [11.264536858000156, 6.534854889000087],
              [11.265520096000159, 6.535729885000023],
              [11.266828538000027, 6.537000657000021],
              [11.268122673000107, 6.537385465000057],
              [11.269001960000082, 6.537647247000052],
              [11.272589683000035, 6.537595750000094],
              [11.275472640000089, 6.538555145000032],
              [11.280172348000121, 6.538589954000145],
              [11.280652046, 6.53836488800016],
              [11.281481742000096, 6.537975788000097],
              [11.282817840000178, 6.537348748000113],
              [11.285998344000063, 6.53358841],
              [11.28738021800018, 6.53204918],
              [11.291347504000157, 6.531047821000016],
              [11.294618607000189, 6.530498505000082],
              [11.297611237000183, 6.52880096500013],
              [11.29934310900012, 6.527151108000112],
              [11.301077843000087, 6.525099754000053],
              [11.303033829000128, 6.523527623000064],
              [11.306255340000121, 6.522500515000161],
              [11.308849334000172, 6.520513057000073],
              [11.311798094999972, 6.516922951000026],
              [11.311712266000086, 6.513081074000183],
              [11.314363480000054, 6.510463716000174],
              [11.31558418200018, 6.508751869000037],
              [11.317497253999989, 6.507993222000039],
              [11.318416595000144, 6.507830143000149],
              [11.319487573000117, 6.507920265000109],
              [11.320494652000036, 6.508062362999965],
              [11.322465896999972, 6.508114338000098],
              [11.324480057000187, 6.507039548000023],
              [11.326314925000133, 6.506995678000124],
              [11.328965187000051, 6.505122661000087],
              [11.331945419000078, 6.50514459700014],
              [11.332308769000122, 6.505326748000016],
              [11.33662414600019, 6.507492067000044],
              [11.337495804000014, 6.507838726000045],
              [11.337599754000109, 6.50788021000011],
              [11.33962249700005, 6.505601406000039],
              [11.341758729000048, 6.503381252000168],
              [11.346145630000024, 6.501369477000083],
              [11.350896835000128, 6.500007153000183],
              [11.354041099000028, 6.50117635800018],
              [11.355750084000135, 6.502622128000098],
              [11.356715202000032, 6.503890515000137],
              [11.35716533599998, 6.505155087000105],
              [11.35674476600019, 6.50778961300017],
              [11.354841232000069, 6.50943851500017],
              [11.352938653000137, 6.511030198000128],
              [11.35143756799999, 6.512452602000053],
              [11.350846290000106, 6.514970780000056],
              [11.35157489900007, 6.517269612000177],
              [11.35188674900013, 6.517786979000107],
              [11.352892876000169, 6.519457817000159],
              [11.35354232800006, 6.520823956000129],
              [11.35423278799999, 6.522276879000174],
              [11.354886054000076, 6.523651124000082],
              [11.355226517000062, 6.524366380000117],
              [11.356500625000137, 6.52567625100005],
              [11.35772800500007, 6.525992394000184],
              [11.359018325000079, 6.526324749000025],
              [11.361596108000128, 6.526515483000026],
              [11.363239288000045, 6.527905464000185],
              [11.363305091000029, 6.52796125399999],
              [11.365673065000067, 6.52772903500005],
              [11.368584633000069, 6.526756287000069],
              [11.37234306400012, 6.524872304000041],
              [11.377272606000133, 6.522729873000174],
              [11.379810334000126, 6.52056932500011],
              [11.382740022000064, 6.524565219000124],
              [11.386538505000033, 6.52780294400003],
              [11.388143540000044, 6.527738095000132],
              [11.390759468000113, 6.527192115000048],
              [11.390815735000047, 6.527180194000039],
              [11.390975953000179, 6.527146816000141],
              [11.392573357000174, 6.528305054000043],
              [11.395174980000036, 6.529597283000101],
              [11.395695686000181, 6.529856205000044],
              [11.396160126000041, 6.529802800000141],
              [11.399443627000039, 6.529424192000079],
              [11.400250434999975, 6.52950334600007],
              [11.403644560999965, 6.529822350000188],
              [11.405708313000048, 6.529913425000132],
              [11.407847404000165, 6.529739856999981],
              [11.407929420000016, 6.529733659000044],
              [11.408308030000114, 6.529702663000137],
              [11.410719871000083, 6.530207634000078],
              [11.410797119000051, 6.530223370000101],
              [11.410867690000146, 6.530238152000095],
              [11.414590837000105, 6.530551435000177],
              [11.416295052000123, 6.530782700000032],
              [11.416824341000165, 6.530854226000088],
              [11.419690133000131, 6.530857087000072],
              [11.419855117999987, 6.530871392000108],
              [11.420037269999966, 6.530871392000108],
              [11.420146941999974, 6.530871392000108],
              [11.423880577000091, 6.530172825000136],
              [11.424676895000061, 6.53080654200005],
              [11.425989151000124, 6.531850816000144],
              [11.426663400000052, 6.533050060000051],
              [11.42607402800013, 6.533534050000128],
              [11.425285340000073, 6.534182550000139],
              [11.424855233000073, 6.534311296000169],
              [11.423044205000167, 6.534854412000129],
              [11.422072410000169, 6.535653591000084],
              [11.421315194000044, 6.536275863000071],
              [11.420106888000078, 6.537894249000033],
              [11.419867516000124, 6.538215161000096],
              [11.419575691000091, 6.53902578400016],
              [11.419225694000147, 6.539999008],
              [11.419278144000032, 6.540569782000148],
              [11.419384004000108, 6.541708947000188],
              [11.41997146600005, 6.541915895000102],
              [11.421214104000171, 6.542353153000022],
              [11.423618317000091, 6.542886258000181],
              [11.424355507000143, 6.54307174700017],
              [11.424598693000178, 6.543141842000182],
              [11.424743652000188, 6.543183327000122],
              [11.42596435500019, 6.543490411000164],
              [11.427494049000131, 6.543896674999985],
              [11.429512024000189, 6.544433118000086],
              [11.432086944000105, 6.544852735000063],
              [11.431541443000128, 6.549367904000178],
              [11.432550431000152, 6.552424907000045],
              [11.43253517200003, 6.554646969000089],
              [11.431050301000028, 6.556287766000025],
              [11.430627823000066, 6.556754590000082],
              [11.429815293000161, 6.558125019000102],
              [11.429335595000055, 6.560812473000055],
              [11.428780556000049, 6.563637257000153],
              [11.428439141000126, 6.566144467000129],
              [11.428197862000161, 6.56791830100002],
              [11.428152085000022, 6.568166733000112],
              [11.42736244300005, 6.572442055000181],
              [11.427254678000111, 6.574256898000158],
              [11.427167893000103, 6.575709342999971],
              [11.427119254000104, 6.576804162000087],
              [11.427073479000114, 6.577843666000092],
              [11.426661492000051, 6.582045078000078],
              [11.425387383000043, 6.584125043000142],
              [11.422505378000096, 6.585990430000038],
              [11.419746398000086, 6.587295532000042],
              [11.417442323000103, 6.588712693000161],
              [11.417301179000162, 6.590137005000031],
              [11.417134286000135, 6.591811658000154],
              [11.417683602000125, 6.595078946000115],
              [11.418871879999983, 6.597208976000104],
              [11.425067902000023, 6.596737386000029],
              [11.427119254000104, 6.598472118000188],
              [11.430515290000073, 6.599043369000128],
              [11.432574272000068, 6.599229336000121],
              [11.433195114000114, 6.599285603000112],
              [11.434279442000104, 6.599383830000136],
              [11.43474197400019, 6.599577427000099],
              [11.439709664000191, 6.601658822000047],
              [11.441169740000021, 6.602102757000125],
              [11.442799569000158, 6.602598191000084],
              [11.442910194000149, 6.60257721000005],
              [11.445727348000162, 6.602045536999981],
              [11.448316575000092, 6.600687981000135],
              [11.448818206000112, 6.600956441000108],
              [11.449097634000168, 6.601106166000022],
              [11.450219154000024, 6.601706982000167],
              [11.450687408000078, 6.602048875000094],
              [11.453110694000031, 6.603818892999982],
              [11.455114365000156, 6.605071544000168],
              [11.456821441000159, 6.606138707000127],
              [11.458691596000108, 6.607555390000186],
              [11.460641862000159, 6.609033108000062],
              [11.462310791000164, 6.610661031000177],
              [11.462420463000171, 6.610703468000054],
              [11.463951110000039, 6.611292838000168],
              [11.466028214000175, 6.609472753000091],
              [11.467937469000105, 6.607832909000138],
              [11.470067024, 6.606003761000125],
              [11.472064972000112, 6.604488850000109],
              [11.47306442200005, 6.603731156000094],
              [11.474512100000084, 6.603563786000109],
              [11.476335525000081, 6.603353024000114],
              [11.47720909100002, 6.603297711000039],
              [11.479604721000044, 6.603146554000091],
              [11.482315063000101, 6.600986958000078],
              [11.484276772000158, 6.599223138000184],
              [11.485693931000071, 6.598890305000055],
              [11.486172675999967, 6.598777772000062],
              [11.487491608000028, 6.598672390000047],
              [11.48774910000003, 6.598709584000119],
              [11.48915290799999, 6.598913671000048],
              [11.492129325000178, 6.599481582000067],
              [11.493855476000078, 6.599755765000111],
              [11.494192124000051, 6.599809170000128],
              [11.496593475000054, 6.600743294000154],
              [11.498364448000132, 6.601673603000165],
              [11.499730111000019, 6.60317421000002],
              [11.500642777000053, 6.604127884999969],
              [11.501209258000131, 6.604719640000042],
              [11.501608849000036, 6.60513687200006],
              [11.502402306000079, 6.605813504000082],
              [11.503618241000027, 6.60683536499999],
              [11.504002571000058, 6.60717487300002],
              [11.504215241000054, 6.60728454600013],
              [11.505601884000157, 6.607999802000165],
              [11.506529807999982, 6.608399389999988],
              [11.508688926000104, 6.609329701000149],
              [11.509788513000046, 6.611890794000033],
              [11.510269165000011, 6.613010406000171],
              [11.512252808000142, 6.616292953000027],
              [11.514029504000064, 6.616477489000033],
              [11.515181541000061, 6.616392135000069],
              [11.516152381000097, 6.616320611000049],
              [11.517006874000174, 6.616140843000096],
              [11.51747226800012, 6.616043091000165],
              [11.518110275000083, 6.61612987500007],
              [11.519248963000166, 6.616284847000088],
              [11.519909859000109, 6.616704942000126],
              [11.520161628999972, 6.616864682000028],
              [11.520825385000023, 6.617411613000058],
              [11.52107429400013, 6.617616654000074],
              [11.521306992000177, 6.617943765000064],
              [11.522037507000107, 6.618969441000161],
              [11.52195358300014, 6.619663238000101],
              [11.521674157000064, 6.621978759000115],
              [11.521340369000029, 6.622979164000185],
              [11.520737648000136, 6.62478256300011],
              [11.520339012000022, 6.626091004000102],
              [11.520267486000137, 6.626327515000071],
              [11.519906997000078, 6.628892898000117],
              [11.52226829500006, 6.631341934999966],
              [11.522789955000064, 6.631883145000188],
              [11.523040771000183, 6.632150173000071],
              [11.524307250999982, 6.63349914600002],
              [11.524740218000034, 6.634780407000108],
              [11.525130272000126, 6.63593340000017],
              [11.524998665000169, 6.636719705000132],
              [11.524534226000014, 6.639486311999974],
              [11.524626733000161, 6.642522812000038],
              [11.525755883000102, 6.645045757000162],
              [11.527583122000067, 6.645138264000082],
              [11.529711724000151, 6.64524602900002],
              [11.533261300000049, 6.645958900000153],
              [11.534131051000145, 6.64654731800016],
              [11.534631729000182, 6.64688587099999],
              [11.535231589999967, 6.64736413900016],
              [11.535429954000051, 6.647522449000121],
              [11.537302972000134, 6.649263382000186],
              [11.53411197700018, 6.652757644000076],
              [11.533935547000112, 6.653291226000022],
              [11.533666611000058, 6.654108047000023],
              [11.536908149000055, 6.657567979000135],
              [11.539359092999973, 6.659707068999978],
              [11.540260315000126, 6.659968377000155],
              [11.541590689999964, 6.660353660000112],
              [11.543040276, 6.661114693000116],
              [11.545133591000081, 6.662213327000075],
              [11.552064896000104, 6.663293838000016],
              [11.554406166000035, 6.664743900000133],
              [11.556046485000138, 6.66769170800012],
              [11.555627823000123, 6.670487404000141],
              [11.55362510700013, 6.672066211000129],
              [11.55274391100005, 6.672760964000133],
              [11.551127435000069, 6.674297810000098],
              [11.548645973000134, 6.677214145000107],
              [11.549942969000142, 6.679680348000147],
              [11.550241471000163, 6.679887295000128],
              [11.552283287000137, 6.681302071000118],
              [11.553865433000112, 6.683957576000182],
              [11.553067208000073, 6.685015203000035],
              [11.550512314000059, 6.688400746000127],
              [11.550123215000099, 6.689165592000165],
              [11.549466132000191, 6.690457821000052],
              [11.548579216000178, 6.694232940000063],
              [11.548615455000061, 6.695617200000186],
              [11.548669815000153, 6.697678089000078],
              [11.54875755400019, 6.700620652000112],
              [11.54876232100014, 6.700775146000069],
              [11.54966068300007, 6.703479290000189],
              [11.550294876, 6.704915523000068],
              [11.550903318999985, 6.706295490000116],
              [11.551336289000119, 6.707523823000088],
              [11.551574708000032, 6.708199502000127],
              [11.550296783000078, 6.711288929000034],
              [11.551466941000115, 6.715818881000189],
              [11.553858758000047, 6.718244075000143],
              [11.554779053000061, 6.719693660000075],
              [11.55529117500015, 6.72050142300003],
              [11.555662155000107, 6.721086026000023],
              [11.556260108000117, 6.722027780000133],
              [11.558006287000069, 6.72300958600016],
              [11.560011864000046, 6.724136829000088],
              [11.563216209000018, 6.725191593000147],
              [11.566312789000108, 6.725270749000117],
              [11.569811820000155, 6.725237847000074],
              [11.572681427000191, 6.724971295000046],
              [11.574518203000082, 6.72475481000015],
              [11.576042175000055, 6.724856377000151],
              [11.575190545000112, 6.726995945000056],
              [11.575014114, 6.727304458999981],
              [11.574342728000033, 6.728476524000087],
              [11.573801995000167, 6.72942066100012],
              [11.574762345000067, 6.730835438000042],
              [11.579287529, 6.731727599000067],
              [11.582112312000049, 6.732714653000187],
              [11.583807946000036, 6.733307361000186],
              [11.58418941400015, 6.733913422000171],
              [11.584714889, 6.734747411000171],
              [11.584762573000035, 6.736124038000071],
              [11.585096359000147, 6.737674713000047],
              [11.585802077000096, 6.740593434000061],
              [11.585734367000157, 6.74104356800018],
              [11.585241318000158, 6.744327069000178],
              [11.584607124000058, 6.747533798000177],
              [11.582983970000043, 6.749969006000072],
              [11.582373619000066, 6.751737117000062],
              [11.581949234000035, 6.75296449699999],
              [11.581192016000102, 6.754565239000101],
              [11.581037521000042, 6.754868031000115],
              [11.580319405000125, 6.756279470000095],
              [11.57975864399998, 6.756976605000148],
              [11.578411103000121, 6.758652688000041],
              [11.577995301000044, 6.758867264000173],
              [11.576337814000112, 6.759722711000165],
              [11.576332093000076, 6.760593892000031],
              [11.576329231000045, 6.761008264000054],
              [11.576629640000078, 6.76242828300002],
              [11.576847077000082, 6.763458729000035],
              [11.577083588000164, 6.764649392000024],
              [11.577288628000076, 6.765683175000049],
              [11.576669694000032, 6.766974449000145],
              [11.57427024899999, 6.768222332000107],
              [11.573650360000158, 6.768674373000124],
              [11.572712899000123, 6.769358158000102],
              [11.571440697000185, 6.7707829470001],
              [11.570824623000021, 6.772109032000174],
              [11.57074260700017, 6.77228450699999],
              [11.570546150000155, 6.775710583000148],
              [11.571220399000083, 6.77787971500004],
              [11.571331024000074, 6.778237344000047],
              [11.571888923000131, 6.779415609000125],
              [11.572215080000035, 6.780104161000054],
              [11.571895600000062, 6.782231808000176],
              [11.571578026000111, 6.783815860000118],
              [11.571569442999987, 6.785134792000179],
              [11.57241153700005, 6.787650109000026],
              [11.574119566999968, 6.789439679000111],
              [11.574712753000028, 6.78983402200015],
              [11.575776099999985, 6.790541173000122],
              [11.578057289000185, 6.792392254000106],
              [11.580088615000022, 6.793799878000016],
              [11.581653595000034, 6.794883729000105],
              [11.582735061000164, 6.795518398000127],
              [11.584224701000153, 6.796392917000105],
              [11.584558486000105, 6.796628953000095],
              [11.585536957000159, 6.797319890000154],
              [11.587480546000108, 6.798308372],
              [11.588180541000042, 6.798333645000071],
              [11.58943081000001, 6.798379422000039],
              [11.592181206000021, 6.79829931400019],
              [11.594464303000052, 6.798233033000088],
              [11.594882965000011, 6.798220635000121],
              [11.597166062000042, 6.799842358000149],
              [11.597187996000059, 6.800151825000057],
              [11.597304344000122, 6.801832198999989],
              [11.595823289, 6.803069592000156],
              [11.595251083000107, 6.803239823000126],
              [11.593752862000031, 6.803686141000128],
              [11.592471122000063, 6.804256916000156],
              [11.592759133000186, 6.806539535000127],
              [11.59342670400008, 6.809228898000129],
              [11.593028068000137, 6.811664105000091],
              [11.591326714000047, 6.814328195000087],
              [11.589717865000068, 6.814699172000132],
              [11.587582588000032, 6.816373826000131],
              [11.585284233000039, 6.816930772000035],
              [11.583894730000111, 6.81692123400012],
              [11.582128525000087, 6.81690836100006],
              [11.580809593000026, 6.817177296000182],
              [11.579448700000114, 6.817455292000091],
              [11.579363823000108, 6.818508626000039],
              [11.579351425000141, 6.820272446000161],
              [11.579796791000035, 6.822131158000104],
              [11.579614640000159, 6.823718548000159],
              [11.580061913000122, 6.82515192000011],
              [11.580578805000073, 6.826531409000097],
              [11.580794335000178, 6.827106953000111],
              [11.58112907300017, 6.828428268000096],
              [11.581156730000032, 6.828585625000073],
              [11.581568719000131, 6.830962659000136],
              [11.581409454000152, 6.832005023000022],
              [11.581149102000097, 6.833714486000133],
              [11.581233025000017, 6.83536100300006],
              [11.58092021900012, 6.836342812],
              [11.580608369000061, 6.83732319000012],
              [11.58063697800003, 6.83755874600007],
              [11.580876351000029, 6.839495659000022],
              [11.579494475999979, 6.840402127000061],
              [11.577882766000016, 6.84125089600002],
              [11.576269150000087, 6.84227180400012],
              [11.575220109000099, 6.84275579500013],
              [11.574311256000101, 6.843175412000107],
              [11.573812485000133, 6.843408585000134],
              [11.57310295200017, 6.843740463000074],
              [11.572003365000057, 6.845108987000117],
              [11.5714330670001, 6.846162319000086],
              [11.571014403999982, 6.84693670300004],
              [11.570319176000055, 6.847849368000141],
              [11.56980228499998, 6.848391056000025],
              [11.569395066000141, 6.848817826000129],
              [11.568339349000098, 6.849748135000141],
              [11.56820202, 6.849868775000175],
              [11.564160348000087, 6.850787163000121],
              [11.561232566000115, 6.850199223000118],
              [11.560607909000055, 6.850073815000087],
              [11.56022453300011, 6.850137233000169],
              [11.557621002000076, 6.85056829500013],
              [11.555727958000148, 6.85061216299999],
              [11.554747582, 6.851293564000173],
              [11.554425239000068, 6.85220050900017],
              [11.55433750200001, 6.852445603000035],
              [11.553934097000138, 6.855347634000054],
              [11.553570747000094, 6.856322765000186],
              [11.553078651000078, 6.857644557000128],
              [11.553371430000027, 6.859166623000135],
              [11.553761483000073, 6.860748290000174],
              [11.553826331000096, 6.861053944000048],
              [11.554482460000145, 6.864136696],
              [11.560283661000028, 6.871232034000173],
              [11.56064415100002, 6.871573925000121],
              [11.562905312000169, 6.873716830999967],
              [11.564669609000021, 6.875736713000151],
              [11.56496715600008, 6.876708031000021],
              [11.565172195000059, 6.877377510000088],
              [11.564619064000055, 6.878772735000155],
              [11.564414025000019, 6.879290581000021],
              [11.564123154000015, 6.879980564000164],
              [11.563714027, 6.880948544000148],
              [11.56300067900014, 6.881803989000161],
              [11.562995911000087, 6.885072232000141],
              [11.56429481400005, 6.887948990000098],
              [11.566291808000074, 6.88951158500015],
              [11.567773820000127, 6.890841008000109],
              [11.56845283399997, 6.891350269000043],
              [11.569086074000154, 6.891825676000053],
              [11.569807053000034, 6.892601968000179],
              [11.570908547000045, 6.893788338000093],
              [11.571856499000148, 6.895846367000104],
              [11.572207450000121, 6.896607876000132],
              [11.573135375999982, 6.897918224000023],
              [11.573547363000046, 6.898850918000107],
              [11.573813438000116, 6.899452209],
              [11.574670791000131, 6.899672984000063],
              [11.575340272000176, 6.899845600000162],
              [11.576518059000023, 6.900617123000131],
              [11.577281953000181, 6.90111732400004],
              [11.578485489000059, 6.902338029000077],
              [11.57870578699999, 6.90256118700006],
              [11.579668045000062, 6.904345990000024],
              [11.581143379000082, 6.906650544000115],
              [11.583251000000132, 6.908844949000013],
              [11.586642266000069, 6.908009052000068],
              [11.587033272000099, 6.907879353000055],
              [11.589691162000065, 6.906998635000036],
              [11.592350006000174, 6.907077789000027],
              [11.593171120000079, 6.909718515000179],
              [11.596220016000075, 6.908708095000065],
              [11.599208833000034, 6.908041476000051],
              [11.601144790999967, 6.907523155000092],
              [11.604042054000161, 6.908920288000161],
              [11.606627465000088, 6.911232471000062],
              [11.607354164000128, 6.912977220000073],
              [11.607905388000063, 6.914299965000168],
              [11.60889339400012, 6.917745113000137],
              [11.608983993000038, 6.921130657000106],
              [11.608161927000083, 6.9237613680001],
              [11.606634141000143, 6.925576688000035],
              [11.605833053000083, 6.92652750000002],
              [11.605965614000127, 6.926857948000134],
              [11.606635093000023, 6.928521633000059],
              [11.606597901000157, 6.928831099999968],
              [11.606148720000022, 6.932584286],
              [11.606589316000054, 6.935048104000146],
              [11.608810425000058, 6.937415123000164],
              [11.60902977, 6.937684060000095],
              [11.61017608700007, 6.939087868000058],
              [11.610233307000044, 6.940252781000083],
              [11.610325814000134, 6.942128659000048],
              [11.613763810000023, 6.942264080000143],
              [11.615481378000027, 6.943138123000097],
              [11.616092682000158, 6.943449020000116],
              [11.620865822000042, 6.947896004000029],
              [11.623055458000181, 6.950442315000146],
              [11.623465537000129, 6.953251840000121],
              [11.62352943500008, 6.956805229000054],
              [11.623633385000119, 6.958629132000112],
              [11.62370300300006, 6.959856509000133],
              [11.624113082000122, 6.966089726000121],
              [11.624932288000025, 6.96914863700016],
              [11.620224, 6.97410869700019],
              [11.619768144000147, 6.975039483000103],
              [11.619240761000128, 6.976115226000047],
              [11.622344017000046, 6.977238656000111],
              [11.624736786000028, 6.978683472000114],
              [11.628796577000116, 6.983060359000149],
              [11.630078317000141, 6.984170436000056],
              [11.633582116000071, 6.98720550600018],
              [11.634102821000056, 6.987432957000067],
              [11.636550903000114, 6.988502026000163],
              [11.642001153000024, 6.989190101000133],
              [11.64359760300016, 6.989295484000081],
              [11.645311356000093, 6.989408494000031],
              [11.64868545600001, 6.989886761000093],
              [11.649306298000056, 6.989912510000181],
              [11.652450562000183, 6.990043164000156],
              [11.654492377999986, 6.989144802000055],
              [11.654865266000115, 6.988980771000058],
              [11.655643462, 6.988638401000173],
              [11.658185006000167, 6.987423421000187],
              [11.660444260000077, 6.988554001000125],
              [11.658248902000139, 6.993068218000133],
              [11.656850814000109, 6.995458127000063],
              [11.656242370000029, 6.996499061000066],
              [11.658820151000043, 6.998242855000115],
              [11.65972709600004, 6.99885606700019],
              [11.665510177000101, 6.998377799000082],
              [11.669207573000108, 6.998486997000043],
              [11.670406342000092, 6.999618055000042],
              [11.671136856000146, 7.00030708200012],
              [11.672618865000118, 7.002068520000023],
              [11.673062324000114, 7.002595425000152],
              [11.675787926000055, 7.005424977000132],
              [11.677857398000185, 7.006512643000065],
              [11.678924561000144, 7.008453369000108],
              [11.67907619500005, 7.008728027000075],
              [11.679087638, 7.010204793000071],
              [11.679109574000051, 7.013141156000074],
              [11.681108474999974, 7.015167236000025],
              [11.683869362000053, 7.017859460000011],
              [11.684999466000136, 7.01900482100001],
              [11.68563366, 7.021011352000073],
              [11.685720444000083, 7.02128505800016],
              [11.687816619999978, 7.02176952300016],
              [11.688885690000177, 7.022006034000128],
              [11.689632415000062, 7.022171497000045],
              [11.691123010000183, 7.022701264000091],
              [11.692282677000151, 7.024007320000123],
              [11.693334579000123, 7.02481794300013],
              [11.693897247000109, 7.025251866000076],
              [11.695840836000116, 7.025979520000078],
              [11.69830703700012, 7.026256560999968],
              [11.699992180000038, 7.026852608000183],
              [11.701869964000139, 7.027644635000115],
              [11.703163147000112, 7.028497218000041],
              [11.705626487000188, 7.029098989000147],
              [11.70750618, 7.029695987000025],
              [11.710621834000051, 7.029977798000118],
              [11.71405983000011, 7.030650616],
              [11.717101097000125, 7.031593323000152],
              [11.716049195000153, 7.034039498000141],
              [11.715497970000115, 7.03471279200005],
              [11.715339660000154, 7.034905434000166],
              [11.714673996000158, 7.035717487000113],
              [11.71184635100002, 7.040629864000039],
              [11.710271835000128, 7.042825222000147],
              [11.709333420000064, 7.046972274000098],
              [11.708729745000085, 7.048351288000163],
              [11.708342552000147, 7.049236775000168],
              [11.708126069000059, 7.050513267000099],
              [11.707802772000036, 7.052415849000113],
              [11.708861351000166, 7.054031850000172],
              [11.709923744000037, 7.05534887400006],
              [11.711029053000118, 7.055161953000152],
              [11.712268828000106, 7.054327011000169],
              [11.713480950000189, 7.053906919000042],
              [11.714287758000182, 7.053627013000153],
              [11.716632843000127, 7.052670003000173],
              [11.71883487600013, 7.053658962000043],
              [11.722854614000028, 7.054725647000112],
              [11.729266167000048, 7.057366848000015],
              [11.732901573000106, 7.057716847000108],
              [11.736932754000122, 7.057225704000075],
              [11.747334481, 7.056000710000149],
              [11.753127098000107, 7.054483413000128],
              [11.75690555500006, 7.05288696299999],
              [11.762764932000152, 7.050980568999989],
              [11.767778397000086, 7.049327852000147],
              [11.770507812000062, 7.049216747000116],
              [11.773432732000174, 7.048912525000162],
              [11.777203561000135, 7.048419477000039],
              [11.78148460400007, 7.049422264000157],
              [11.783033372000148, 7.050860881000062],
              [11.784285545000103, 7.052617551000083],
              [11.786012649000156, 7.054770947000122],
              [11.790064811000036, 7.059549331000028],
              [11.792321205000064, 7.062643051000066],
              [11.79322624200006, 7.064360141000179],
              [11.795295716000055, 7.068284035000147],
              [11.79734516200017, 7.072246076000113],
              [11.798460007000131, 7.073811054000146],
              [11.798788070000171, 7.074270726000123],
              [11.798928261000128, 7.074516297000116],
              [11.799942969000085, 7.076290608000136],
              [11.801932335, 7.077948093000032],
              [11.803948402000117, 7.079627991000109],
              [11.806859971000051, 7.081400872000188],
              [11.808441162000065, 7.081703186000141],
              [11.809325219000129, 7.08187198700017],
              [11.809486389000142, 7.08184146800005],
              [11.811991691000117, 7.081370831000072],
              [11.814819336000028, 7.080355168000153],
              [11.815183640000157, 7.080224514000179],
              [11.818764686000179, 7.079081059000146],
              [11.821247100000051, 7.077085495000063],
              [11.823992729000111, 7.074573040000132],
              [11.824603080000031, 7.074245452000184],
              [11.826861381000128, 7.073034763000123],
              [11.827655793000133, 7.073721410000189],
              [11.829057693000152, 7.07493209900008],
              [11.829430581000054, 7.075962067000035],
              [11.829668045000176, 7.076616288000082],
              [11.829902648000143, 7.077153206000105],
              [11.830723762000048, 7.079032421000022],
              [11.832373619000066, 7.082590103000086],
              [11.834575652000069, 7.085030555],
              [11.836310386000093, 7.086553096000159],
              [11.836513519999983, 7.086731434000171],
              [11.838259697000183, 7.087846757000136],
              [11.841828347000046, 7.088520051000103],
              [11.843585014000155, 7.088723660000142],
              [11.845398904000149, 7.088933468000107],
              [11.849683762000097, 7.089481831000057],
              [11.853261948000068, 7.088792324000167],
              [11.856201173000159, 7.086475372000109],
              [11.85880947000004, 7.085000038000089],
              [11.861878394000144, 7.082814216000031],
              [11.865135194000118, 7.081602574000158],
              [11.868134499, 7.079935551000176],
              [11.871127129, 7.07943630300008],
              [11.871374131000039, 7.081255436000049],
              [11.871675491000076, 7.085399152000036],
              [11.872264863, 7.087410926000075],
              [11.872297287000038, 7.088934898000048],
              [11.871764182000049, 7.090878964000126],
              [11.871297835000121, 7.09281540000012],
              [11.872008324000035, 7.095639707000146],
              [11.872264863, 7.097381590999987],
              [11.874029160000077, 7.103624821000039],
              [11.873547553000094, 7.105741025000157],
              [11.873270035000019, 7.108400345000064],
              [11.871955871000011, 7.110598565000089],
              [11.869482040000037, 7.111230850000084],
              [11.868628502000149, 7.112523556000099],
              [11.867514610000171, 7.113944054],
              [11.866721153000128, 7.115950584000132],
              [11.865271568000026, 7.118926526000166],
              [11.863765717000035, 7.120604039000114],
              [11.861214638000092, 7.123183250000125],
              [11.85631561299999, 7.126914502000091],
              [11.853633880000075, 7.129506589000073],
              [11.85367488899999, 7.131861209000022],
              [11.853585243000055, 7.136567117000027],
              [11.852987290000044, 7.138575554000056],
              [11.851155281000104, 7.140380383000036],
              [11.848807334000071, 7.141662598000039],
              [11.847556115000032, 7.144390584000121],
              [11.84787750300012, 7.14660358499998],
              [11.846746445000178, 7.148463727000092],
              [11.844723702000067, 7.149682999000049],
              [11.843540191999978, 7.151687146000086],
              [11.842943192000178, 7.153642655000112],
              [11.84305477200013, 7.156227589000139],
              [11.843573570999979, 7.158950806000121],
              [11.844902993000062, 7.161432743000034],
              [11.845536233000018, 7.163520813000162],
              [11.844941140000117, 7.165262699000039],
              [11.84259414700017, 7.166479589000176],
              [11.839329720000137, 7.168663979000087],
              [11.837233544000071, 7.171051503000115],
              [11.836239815, 7.173835278000126],
              [11.835765838000157, 7.176637649999975],
              [11.835741044000031, 7.18019294700008],
              [11.836628913000027, 7.183379651000166],
              [11.837085724000076, 7.186210633000087],
              [11.83620929600005, 7.187660694000044],
              [11.835095406000107, 7.189015865000044],
              [11.834821701000067, 7.191160678000131],
              [11.835297586000138, 7.193087578000188],
              [11.835962295000115, 7.195123196000054],
              [11.837122916000169, 7.196494103000077],
              [11.838603973000033, 7.198451997000063],
              [11.83936977400009, 7.200534345000051],
              [11.839547157000084, 7.203066826000054],
              [11.839783668000052, 7.206443787000126],
              [11.840993880000156, 7.209957124000141],
              [11.843439102000161, 7.212701320000178],
              [11.843508721000148, 7.212779521000186],
              [11.845116616000041, 7.215192319000153],
              [11.848029137000083, 7.216899872000113],
              [11.851007462000041, 7.218543529000101],
              [11.85313606200009, 7.220894814000076],
              [11.853229523000095, 7.224450588000138],
              [11.853235244000132, 7.224648475000038],
              [11.853285789000154, 7.226586818000101],
              [11.854255677000083, 7.226770402000057],
              [11.853290559000186, 7.226737500000127],
              [11.852044106000164, 7.228416444000175],
              [11.850674629000082, 7.228991031000021],
              [11.848984717000064, 7.229109288000132],
              [11.846447946000069, 7.229416371000184],
              [11.843588829, 7.229266644000177],
              [11.84014129500008, 7.229762077000032],
              [11.836567878000039, 7.229607582000142],
              [11.833647728000017, 7.228938103000075],
              [11.830204964000131, 7.228719235000085],
              [11.827016830000105, 7.229281427000046],
              [11.823570251000149, 7.229516983000167],
              [11.821232796000061, 7.229240895000089],
              [11.817468643000097, 7.228565693000178],
              [11.813702584000168, 7.228085040999986],
              [11.811027525999975, 7.229558945000122],
              [11.811295509000047, 7.233618737000086],
              [11.812343597999984, 7.236577989000182],
              [11.814518929000144, 7.241461277000099],
              [11.816764832000047, 7.245436669000014],
              [11.819549560000098, 7.246948719999978],
              [11.8231868740001, 7.247169018000079],
              [11.826819421000039, 7.248167992000163],
              [11.830062866000048, 7.249034404999975],
              [11.831151962000149, 7.249706269000171],
              [11.831360815999972, 7.249835492000102],
              [11.833040237000148, 7.250872611000034],
              [11.836668968000083, 7.25232601200014],
              [11.838660241000071, 7.255714893000118],
              [11.840071676999969, 7.25838613600007],
              [11.841812134000179, 7.26047515900018],
              [11.844006539000134, 7.262697220000064],
              [11.846390723000013, 7.265634538000086],
              [11.847627639000052, 7.266678809000098],
              [11.8478202820001, 7.266841412000133],
              [11.84910392800009, 7.267925263],
              [11.851883888000089, 7.270086766000077],
              [11.85466671099999, 7.271858692000137],
              [11.857829094000124, 7.275061132000076],
              [11.86046028200019, 7.27988243100009],
              [11.862707138000133, 7.283922195000059],
              [11.86714744600016, 7.290443898000092],
              [11.872495651000122, 7.297101975000032],
              [11.878232003000107, 7.304152013000021],
              [11.880680085000108, 7.307284354999979],
              [11.881895065000094, 7.310278892000042],
              [11.882706642000016, 7.31421470700019],
              [11.88321208900004, 7.318567753000139],
              [11.883459090000144, 7.321580887000039],
              [11.883903503000056, 7.32467794400003],
              [11.883909226000071, 7.324716569000145],
              [11.883999824999989, 7.325352668999983],
              [11.885985374000086, 7.329650402000084],
              [11.888175964000141, 7.332456589000174],
              [11.890691757000184, 7.335330486000032],
              [11.89158153599999, 7.339169503000107],
              [11.891166686000133, 7.341824533000079],
              [11.891273499000022, 7.345070840000176],
              [11.892290116000027, 7.348518372000115],
              [11.893714905000024, 7.349307061000104],
              [11.895205498000166, 7.349901676],
              [11.897291183000164, 7.349136829999964],
              [11.899503707000122, 7.348892689000138],
              [11.901708603000088, 7.349621773000024],
              [11.903062821000049, 7.351189137000063],
              [11.90447998000019, 7.353146552999988],
              [11.911731720000148, 7.366827965000141],
              [11.916111945000068, 7.37270021500018],
              [11.919023514, 7.374732495000103],
              [11.923239709000086, 7.37618970800014],
              [11.927077294000071, 7.375956535000114],
              [11.930389404000039, 7.376498700000127],
              [11.933697701000142, 7.377624988000036],
              [11.935762406000151, 7.379846097000041],
              [11.937365531000182, 7.383037567000145],
              [11.94064521700011, 7.388318062000167],
              [11.94328403600008, 7.394400597000129],
              [11.943953515000146, 7.398921966000046],
              [11.943999290000079, 7.401648521000084],
              [11.943264960000079, 7.404434682000158],
              [11.942331314000114, 7.407933713000034],
              [11.942141533000097, 7.408474446000071],
              [11.942030907, 7.40879011100003],
              [11.940540314999964, 7.413049698000123],
              [11.940254211000081, 7.41681241900011],
              [11.941168784000183, 7.419213772000148],
              [11.941979408000122, 7.421173573000033],
              [11.943200112000056, 7.423324109000134],
              [11.946033477000071, 7.427303314000142],
              [11.949068069000191, 7.430439950000164],
              [11.951401711000187, 7.431494712000188],
              [11.953871727000092, 7.431706430000133],
              [11.956993103000059, 7.431598187000134],
              [11.95914077700013, 7.43141794100012],
              [11.96134758, 7.43188762599999],
              [11.962315559999979, 7.432997703000126],
              [11.962919236, 7.434487820000015],
              [11.963338851, 7.435737134000021],
              [11.965472221000141, 7.446781159000125],
              [11.965191840000102, 7.44976568300018],
              [11.965004921, 7.467421056000035],
              [11.96534919700008, 7.474044801000161],
              [11.966917991000059, 7.476944447000108],
              [11.968306541000175, 7.479764939000063],
              [11.974037170000145, 7.487347126999964],
              [11.977846146000161, 7.491463185000043],
              [11.980889321000177, 7.493431567000187],
              [11.983158112000012, 7.494485379000139],
              [11.988737106000144, 7.496536255000024],
              [12.003657340000075, 7.502156258000071],
              [12.008715630000097, 7.504397869000172],
              [12.011238098000092, 7.50636243800011],
              [12.012373924000087, 7.507632733000094],
              [12.013703346000113, 7.509120465000137],
              [12.016607284000088, 7.510683536000045],
              [12.019979477000106, 7.512069703000066],
              [12.021101952000151, 7.512432575000048],
              [12.025107383000034, 7.513727666000023],
              [12.029410361999965, 7.516789914000071],
              [12.031819344000155, 7.518665313999975],
              [12.0320119860001, 7.518815041000039],
              [12.034343720000152, 7.521106719000102],
              [12.03436756200017, 7.521787166000024],
              [12.034410476000176, 7.523008823000112],
              [12.033298491999972, 7.523910045000093],
              [12.032056808000107, 7.524745942000038],
              [12.030653000000143, 7.526220321000096],
              [12.030091286000186, 7.526809693000189],
              [12.029500008000127, 7.52739238800018],
              [12.027799606000087, 7.529066562000139],
              [12.026631354000074, 7.530544759000122],
              [12.025961875, 7.531391144000111],
              [12.024270058000127, 7.534072398999967],
              [12.022740364000185, 7.536497593000092],
              [12.020506859000022, 7.539728164000167],
              [12.020100593000166, 7.542062759000146],
              [12.020164489000138, 7.543473720000065],
              [12.020213127999966, 7.544529914000066],
              [12.020352365000065, 7.547733784000116],
              [12.02038192800012, 7.548426629000176],
              [12.020970344000091, 7.557518960000095],
              [12.021080971000117, 7.560376167000129],
              [12.0206289300001, 7.562244892000138],
              [12.020343780000132, 7.563422204],
              [12.018957137000086, 7.56639862100019],
              [12.017977714000097, 7.56782341100012],
              [12.017446518000042, 7.568595887000072],
              [12.016246794999972, 7.570855140000049],
              [12.015798569000026, 7.571700572],
              [12.015395165000029, 7.573580265000032],
              [12.015522003000058, 7.57475471500004],
              [12.015640259000065, 7.575853824000092],
              [12.016286849000096, 7.577521800000113],
              [12.016598700999964, 7.578327655000066],
              [12.016861915999982, 7.579226495],
              [12.017361642000139, 7.580929280000134],
              [12.018444061000082, 7.584377289000031],
              [12.018963815000063, 7.586575985000081],
              [12.01901245200014, 7.586783409000191],
              [12.01905727400009, 7.589704991000133],
              [12.01852130900005, 7.591973305000067],
              [12.017063141000051, 7.59585857400009],
              [12.016966819000061, 7.596397877000072],
              [12.016657829000053, 7.598127843000043],
              [12.017286301000013, 7.601313115000153],
              [12.017457962000094, 7.604690076000054],
              [12.017909050000128, 7.609815598000125],
              [12.018000602000086, 7.610860824999975],
              [12.017994882000096, 7.6111249920001],
              [12.017908095000109, 7.614884853000035],
              [12.017896652000161, 7.616415978000021],
              [12.017887115000178, 7.617805958000076],
              [12.018354416000022, 7.620056153000178],
              [12.018832206000127, 7.622356891000095],
              [12.01871204300005, 7.623498440000162],
              [12.018552780000107, 7.62501669],
              [12.018188476000148, 7.625928403000046],
              [12.017957688000081, 7.626505375000022],
              [12.016575813000031, 7.628767967000158],
              [12.016486167999972, 7.632273198000178],
              [12.016492844000027, 7.632726192000177],
              [12.01652908300008, 7.635389329000191],
              [12.015919685000085, 7.63703489400018],
              [12.015402793000135, 7.638432502000171],
              [12.012499809000133, 7.644190312000148],
              [12.009919166000032, 7.650322437000114],
              [12.008794785000134, 7.652993202],
              [12.008381843999985, 7.654300213000056],
              [12.007730483000046, 7.65636158],
              [12.007058144000098, 7.659472943000139],
              [12.007757187000095, 7.66187954000003],
              [12.008701324000128, 7.662541389000182],
              [12.009286881000037, 7.66295147000011],
              [12.011564255000167, 7.663569927000026],
              [12.012776375000101, 7.66364193000004],
              [12.014111520000142, 7.663721084000031],
              [12.023042677999968, 7.66378307399998],
              [12.026052475000029, 7.663804055000014],
              [12.026330948000066, 7.6642351160001],
              [12.026992798000151, 7.665258884000082],
              [12.026158332000023, 7.67297840100008],
              [12.026137351000159, 7.675964356000122],
              [12.026772498000014, 7.678240777000099],
              [12.029343606000111, 7.685918332000028],
              [12.030150414000104, 7.688326360000133],
              [12.030598640000051, 7.689643859],
              [12.031234742000095, 7.691514969000082],
              [12.03119659500004, 7.691954137000039],
              [12.031092642999965, 7.693137170000114],
              [12.030087471000115, 7.697349548000148],
              [12.029414177000035, 7.700591087000021],
              [12.029507636, 7.7019252770001],
              [12.029651643000136, 7.703968048000093],
              [12.031183242000111, 7.726894855000182],
              [12.031542779000119, 7.728616238000086],
              [12.031875610000043, 7.730210782000029],
              [12.032776831999968, 7.731580257000076],
              [12.033868790000099, 7.733127594000109],
              [12.034066200000098, 7.733407020000129],
              [12.03639221200018, 7.735825063000107],
              [12.03810596400001, 7.736995698000101],
              [12.039176941999983, 7.737727165000138],
              [12.040212630999974, 7.738554478000083],
              [12.041636467000046, 7.739691734000019],
              [12.043895721000069, 7.742303849000052],
              [12.044618607000018, 7.743948936000038],
              [12.046329496999988, 7.74783897399999],
              [12.047569275000114, 7.749160768000138],
              [12.048720359000129, 7.750387193000108],
              [12.053050995000149, 7.754637242000115],
              [12.055311203000088, 7.757184506000044],
              [12.05555248200011, 7.758782386000064],
              [12.05568409000017, 7.759654045000161],
              [12.056095122000045, 7.762461186000166],
              [12.055405617000133, 7.765133381],
              [12.053702355000041, 7.768802643000015],
              [12.053177833000177, 7.770436765000056],
              [12.053016663000164, 7.770939826000017],
              [12.052888870000174, 7.771317481000096],
              [12.052470207000056, 7.772550584000101],
              [12.054339410000068, 7.775160314000175],
              [12.055426598000167, 7.775671960000068],
              [12.05699920700016, 7.77641248700013],
              [12.059852601000159, 7.777925491000076],
              [12.062314034000167, 7.779565335000029],
              [12.062778473000151, 7.78029203400007],
              [12.063022613999976, 7.780673982000053],
              [12.063186644999973, 7.781051635000097],
              [12.063740730000063, 7.782324792],
              [12.062979697000117, 7.785665989000165],
              [12.061923980000074, 7.788911343000109],
              [12.062099456000169, 7.791703701000017],
              [12.063314438000191, 7.794828415000097],
              [12.066157341000121, 7.797834396000042],
              [12.067495346000044, 7.798777580000092],
              [12.071273804000043, 7.801440715000126],
              [12.073585509000111, 7.802818300000126],
              [12.074580193000145, 7.803411007000079],
              [12.07645606900013, 7.805111885000088],
              [12.077388762999988, 7.806726456000035],
              [12.077999116000171, 7.80778455799998],
              [12.080906867000124, 7.8165020940001],
              [12.084120750000068, 7.826134205000074],
              [12.085423469000091, 7.829760075000081],
              [12.086483001000033, 7.832707405],
              [12.088475227000174, 7.836421967000092],
              [12.092735291000054, 7.841645242000141],
              [12.095456124, 7.844470501000046],
              [12.099909782999987, 7.849095346000183],
              [12.108067513000151, 7.855514049000021],
              [12.116755485000112, 7.860637664000024],
              [12.122982979000028, 7.863861560000146],
              [12.126748084999974, 7.865445614000123],
              [12.128774643000156, 7.867161750000037],
              [12.129530906000127, 7.867802143000176],
              [12.130026817000044, 7.868254661000094],
              [12.132184028000097, 7.870222091000187],
              [12.138491631000022, 7.878162861000135],
              [12.141155243000128, 7.88151550300006],
              [12.153691293000122, 7.895299911000166],
              [12.155300141000055, 7.897471905000089],
              [12.155491830000187, 7.898878574000037],
              [12.155953407000084, 7.900153638000063],
              [12.156044006, 7.903626441000085],
              [12.157528878000164, 7.905454159000101],
              [12.158432007000158, 7.906693935000021],
              [12.15900898000001, 7.910092831000043],
              [12.15918254799999, 7.911113739000143],
              [12.159176827000181, 7.911964417000036],
              [12.159163475000128, 7.913970471000084],
              [12.160019875000103, 7.915501595000137],
              [12.160256386000015, 7.91592550300004],
              [12.160330772000179, 7.916004181000119],
              [12.161614419000045, 7.917363167000133],
              [12.162405014000171, 7.917970181000101],
              [12.1634922020001, 7.918804645000023],
              [12.164454460000115, 7.920158386000026],
              [12.164976119000187, 7.920892238000022],
              [12.16600322700009, 7.92304134300008],
              [12.166653633000067, 7.924295902000097],
              [12.167287827000166, 7.925517559000184],
              [12.169999122000036, 7.928977014000168],
              [12.170969963000175, 7.930023671000129],
              [12.171938896000142, 7.93106794400012],
              [12.174735069000121, 7.931606292000083],
              [12.175917625000068, 7.932675840000115],
              [12.175964356000122, 7.932718277000163],
              [12.177191734000075, 7.934219837000057],
              [12.178409576000149, 7.937019825999983],
              [12.179440499000066, 7.938650132000078],
              [12.180734635000022, 7.939827442000137],
              [12.183789252999986, 7.94062709800005],
              [12.18470191900019, 7.941050530000098],
              [12.186060905000033, 7.941681385000152],
              [12.186856270000021, 7.942231655],
              [12.18729305300019, 7.942533969000181],
              [12.187655449000147, 7.942934036000168],
              [12.188003541000171, 7.94331789099999],
              [12.188185692000047, 7.943704129000025],
              [12.188710214000139, 7.944815636000044],
              [12.188047408000159, 7.946499348000089],
              [12.188359260000027, 7.94851446199999],
              [12.188481331000105, 7.949683667000045],
              [12.188608169000133, 7.949998379000135],
              [12.189057350000041, 7.951116086000184],
              [12.189270020000095, 7.951990127000101],
              [12.189691543000038, 7.953717230999985],
              [12.189029694000055, 7.955206395000118],
              [12.188242912000021, 7.956044674999987],
              [12.187713622000103, 7.957210064000094],
              [12.187638284000059, 7.95876789],
              [12.18825340300009, 7.959474563000129],
              [12.189059258000043, 7.960400580999988],
              [12.190788269, 7.961555481000175],
              [12.192495346000101, 7.9626960760001],
              [12.195672988000126, 7.964665414000024],
              [12.198602677000167, 7.966104984000083],
              [12.19982624000005, 7.966706277000185],
              [12.203267098000026, 7.968287946000032],
              [12.203777313000046, 7.968640805],
              [12.204499243999976, 7.969140053000103],
              [12.205726624000135, 7.970576763000167],
              [12.20650005300007, 7.971750736000047],
              [12.206765175000157, 7.972037317000059],
              [12.207089425000163, 7.97238731300007],
              [12.207459450000158, 7.974289417000023],
              [12.207277299000054, 7.97464704600003],
              [12.206667900000184, 7.975842475000093],
              [12.205718041000011, 7.976662159000057],
              [12.20631694900004, 7.979540826000118],
              [12.208063126000184, 7.98137044900011],
              [12.209657668000148, 7.982429504000038],
              [12.210137368, 7.982748031000028],
              [12.210319519000109, 7.982870579000064],
              [12.211499214000071, 7.983665944000052],
              [12.2118902200001, 7.98430204400006],
              [12.211910248000152, 7.9843344680001],
              [12.212224961000175, 7.987415790000171],
              [12.212950707000175, 7.990363598000158],
              [12.214560508000091, 7.991764069000112],
              [12.215282439000191, 7.992392063000011],
              [12.216711044000022, 7.993568898000149],
              [12.217030526000144, 7.993832111000188],
              [12.216809273000081, 7.997596264000151],
              [12.217312812000046, 8.000196455999969],
              [12.221256256, 8.002602577],
              [12.222370147999982, 8.006693841000129],
              [12.222748756000044, 8.010243417000027],
              [12.223188400000026, 8.014731407000113],
              [12.222686768000074, 8.019413949000125],
              [12.221929550000141, 8.022086144000127],
              [12.22016811300017, 8.024283410000066],
              [12.218401908000089, 8.027350426000169],
              [12.216496468, 8.031019211000057],
              [12.216273308000041, 8.03423023300013],
              [12.217056275000061, 8.037515640000095],
              [12.217844009000032, 8.040265084000055],
              [12.217822075000186, 8.043478012000151],
              [12.217803955000136, 8.046088219000012],
              [12.218967437000117, 8.052789688000189],
              [12.21838665000007, 8.06898403200006],
              [12.217702865000092, 8.070652963000043],
              [12.214659690000076, 8.073778152000045],
              [12.212285040999973, 8.077176093999981],
              [12.21207523400011, 8.078379632000122],
              [12.211293220000073, 8.084666252000147],
              [12.210533142000088, 8.087873460000139],
              [12.209214210000027, 8.094022751000068],
              [12.209528922000118, 8.097104072000036],
              [12.210450173000083, 8.09985446900015],
              [12.210697174000131, 8.103002549],
              [12.211000443000103, 8.10485744499999],
              [12.211277009000071, 8.106554032000133],
              [12.212210655000035, 8.108277321000173],
              [12.212405204000106, 8.1086368550001],
              [12.213511466000114, 8.109619141],
              [12.214608192000128, 8.110592842000187],
              [12.216338158000042, 8.112947465000048],
              [12.217189789000088, 8.116099357000053],
              [12.218110084000102, 8.119050981000044],
              [12.219528198000035, 8.121262550000154],
              [12.219704628999978, 8.121538162000036],
              [12.219985009000084, 8.121779441],
              [12.222375869000018, 8.123831750000079],
              [12.225057602000163, 8.124520302000065],
              [12.228407859000129, 8.12581443800002],
              [12.228321075000053, 8.128558159000079],
              [12.227426528000024, 8.131765365999968],
              [12.22629165699999, 8.134333612000035],
              [12.226129532000186, 8.134701729000028],
              [12.226455687999987, 8.135392189000129],
              [12.226858139000171, 8.136245729000052],
              [12.23019981300007, 8.138745308000011],
              [12.234013557000139, 8.140913010000134],
              [12.23695659700013, 8.142807961000187],
              [12.23888874000005, 8.145096779000028],
              [12.24127483400008, 8.149338722000096],
              [12.241292000000101, 8.149369239],
              [12.241609573000176, 8.14993476799998],
              [12.241615296000191, 8.15032768399999],
              [12.241655350000144, 8.15301418200005],
              [12.241902351000078, 8.156162262000123],
              [12.242177964000064, 8.158079147000024],
              [12.242287637000118, 8.158842087999972],
              [12.242198943000062, 8.161987305000082],
              [12.242247582000118, 8.164665223000156],
              [12.242562295000141, 8.165207863000092],
              [12.243575097000075, 8.16695022499999],
              [12.246520043000032, 8.168643952000082],
              [12.248725891000106, 8.170198440000092],
              [12.250724792000028, 8.172621726000045],
              [12.251927376000083, 8.173285485000179],
              [12.255142212000123, 8.175061225000036],
              [12.255695343000184, 8.175407409000059],
              [12.256371497000146, 8.175818443000139],
              [12.256503106000139, 8.175911903000042],
              [12.257415771000069, 8.176482202000102],
              [12.257373809000114, 8.17783165000003],
              [12.257330894000063, 8.179212570000061],
              [12.257222175000038, 8.182202340000174],
              [12.257087707999972, 8.185924530000079],
              [12.257086753000181, 8.185970307000048],
              [12.256169320000026, 8.211348533000034],
              [12.255413055000076, 8.213887215000113],
              [12.255240441000183, 8.214715004000141],
              [12.254787445000034, 8.216895103000127],
              [12.255241394000166, 8.219307899000114],
              [12.256564141000126, 8.222329140000056],
              [12.257280350000144, 8.225474358000042],
              [12.256791114000123, 8.228407860000118],
              [12.256067275000021, 8.230831146000071],
              [12.25582885700004, 8.231628418000128],
              [12.253525734000164, 8.245906830000081],
              [12.23974704799997, 8.331320763000122],
              [12.238751411000123, 8.339077949000171],
              [12.239547729000094, 8.340556145000107],
              [12.247343064000177, 8.350249289999965],
              [12.249881744999982, 8.352542877000133],
              [12.251745224999979, 8.355099679000091],
              [12.251121521000073, 8.357839584999965],
              [12.250835418000122, 8.360180855000067],
              [12.251500128999965, 8.361323356000185],
              [12.253238678, 8.362540245000048],
              [12.254580498000053, 8.362884522000059],
              [12.256937026000116, 8.36236477],
              [12.259423255000058, 8.36244964600013],
              [12.260891914000183, 8.363864900000181],
              [12.263222695000024, 8.366960525000025],
              [12.264679909000165, 8.370116233000033],
              [12.264056205000031, 8.372722625000051],
              [12.262697221000053, 8.374855041999979],
              [12.261270524000054, 8.377054215000158],
              [12.259836197000141, 8.380257606000043],
              [12.258010863999971, 8.381784440000104],
              [12.255866051000055, 8.380899430000056],
              [12.253191948000051, 8.378872871000169],
              [12.24785232500011, 8.382985116000157],
              [12.247361182000077, 8.385927201000129],
              [12.24899864200006, 8.392096520000166],
              [12.250497817000166, 8.398667336000074],
              [12.252136231, 8.404769897000051],
              [12.253459931000123, 8.407657623999967],
              [12.25350380000009, 8.411004066000146],
              [12.25476074200003, 8.413825036000105],
              [12.257496834999984, 8.416655541000125],
              [12.260439873000166, 8.418750762000173],
              [12.26082420299997, 8.421430588000021],
              [12.259864808000088, 8.424101830000097],
              [12.258631706000017, 8.427568435000126],
              [12.259213448000025, 8.430709840000077],
              [12.259661673999972, 8.433792114000028],
              [12.260375977000024, 8.437411309000083],
              [12.261698722000119, 8.440433503000179],
              [12.263762473000099, 8.443392754000172],
              [12.265366554000025, 8.444675447000179],
              [12.26885127900016, 8.446306227999969],
              [12.270976066000173, 8.446424484000033],
              [12.271606446000135, 8.446459770000104],
              [12.272702217000074, 8.446370126000147],
              [12.274633408000113, 8.446212769000169],
              [12.278134347000105, 8.44543361699999],
              [12.283644675000062, 8.445807457],
              [12.286405564000177, 8.445157052000127],
              [12.290449142000057, 8.443845750000151],
              [12.293603897000025, 8.444470405000061],
              [12.299525261000042, 8.443775177000077],
              [12.302827834000027, 8.442592622000063],
              [12.305873871000074, 8.439534188000096],
              [12.307044984000072, 8.435525895000012],
              [12.308423996000158, 8.43051529000013],
              [12.310387611000181, 8.428453446000049],
              [12.313431741000159, 8.425796509000065],
              [12.315920830000096, 8.425545693000061],
              [12.319005012000048, 8.426705360000028],
              [12.32484722099997, 8.427748681000082],
              [12.331962586000145, 8.429269790000092],
              [12.335584641000082, 8.430500030000133],
              [12.33913040200008, 8.432209015000012],
              [12.337977409000132, 8.434465408000108],
              [12.33593845300004, 8.437731744000189],
              [12.334774018000019, 8.440816879000124],
              [12.335625648000189, 8.444169998000177],
              [12.337159157000144, 8.446055412000021],
              [12.339426041000081, 8.448747635000132],
              [12.343966484000191, 8.453330995000101],
              [12.346508981000113, 8.455155373000025],
              [12.350060462000101, 8.456920623000087],
              [12.351587296000105, 8.459809302999986],
              [12.353991509000025, 8.46216869400007],
              [12.35707664600011, 8.463327408000055],
              [12.358616828000152, 8.464140892000046],
              [12.362632750999978, 8.46691226900009],
              [12.365306853999982, 8.469073294999987],
              [12.374684334000165, 8.477860452000073],
              [12.375534058000142, 8.478656769000168],
              [12.376531600000078, 8.479591370000151],
              [12.379707336000081, 8.481506348000153],
              [12.38034915899999, 8.48189354100009],
              [12.381417274, 8.482093810000038],
              [12.381826401000012, 8.482171059000166],
              [12.383311273000118, 8.48131084400012],
              [12.388036727000156, 8.478530883000076],
              [12.391617775000157, 8.476078033000135],
              [12.394652367000049, 8.474692346000154],
              [12.396724701000096, 8.475373268000169],
              [12.394558907000146, 8.478627205],
              [12.394205092999982, 8.481309891000137],
              [12.394518853000022, 8.483994484000107],
              [12.394713402000036, 8.485657692000075],
              [12.397460938000165, 8.48701477100019],
              [12.399131774000068, 8.487813950000145],
              [12.399873734000153, 8.488168717000065],
              [12.400015831000076, 8.488265037000076],
              [12.403153420000081, 8.490400314000056],
              [12.403965951000089, 8.490102768000156],
              [12.405310631000134, 8.489610672000083],
              [12.407035827000072, 8.489108086000101],
              [12.408005715, 8.48882579799999],
              [12.409972192000112, 8.489258766000148],
              [12.41082573, 8.48944664000004],
              [12.41175556100012, 8.489549637000096],
              [12.414051056000062, 8.489803314000085],
              [12.414736748000166, 8.489688874000024],
              [12.416744232000156, 8.489352226000051],
              [12.420381545000112, 8.488371849000032],
              [12.422738075000041, 8.487918853],
              [12.424414634000129, 8.488599777000047],
              [12.424738884000135, 8.490489961000094],
              [12.423249244000147, 8.492020608000189],
              [12.421287537000126, 8.49467659000004],
              [12.425759315000107, 8.498784065000166],
              [12.429162979000182, 8.502689363000172],
              [12.433767319000083, 8.508008957000129],
              [12.436905862000174, 8.511242866000089],
              [12.439385413000082, 8.512664795000092],
              [12.444476127000087, 8.515710831000035],
              [12.447826386000088, 8.517540931000099],
              [12.451251029000048, 8.518233299000031],
              [12.456285477000051, 8.519738197000038],
              [12.460916519000023, 8.521107673000188],
              [12.463530540000079, 8.52239704100009],
              [12.465737343000114, 8.524367333000043],
              [12.465453147000062, 8.526574135000033],
              [12.463958740000123, 8.528841018000094],
              [12.461030960000187, 8.534244537000177],
              [12.459320069000114, 8.538785934000146],
              [12.459161759000153, 8.542400361000148],
              [12.459211348999986, 8.54501152099999],
              [12.458518981999987, 8.548019409],
              [12.455799102000014, 8.552486419000161],
              [12.454767226000115, 8.556028367000124],
              [12.453740120000191, 8.558766365000167],
              [12.448673247999977, 8.56208038300008],
              [12.446306229000186, 8.56407356200009],
              [12.4461145410001, 8.565490722999982],
              [12.445881845000031, 8.56721687400011],
              [12.446198464000076, 8.570097924000095],
              [12.446109771000067, 8.570459365999966],
              [12.445575713000153, 8.572637558000054],
              [12.445161819000191, 8.574030876000052],
              [12.444682121000085, 8.575644494000187],
              [12.444624901000168, 8.577354431000174],
              [12.444597245000182, 8.578187943000046],
              [12.443837166000094, 8.581261634999976],
              [12.444872855000085, 8.587293625000086],
              [12.446105003000071, 8.594130515000188],
              [12.446082114000035, 8.597477912000159],
              [12.445813179000083, 8.6000356670001],
              [12.445723533000091, 8.60089015900013],
              [12.441456795000022, 8.604193687000077],
              [12.439638137000031, 8.605602265000073],
              [12.439539910000121, 8.605735779000099],
              [12.436990738000077, 8.609200479000094],
              [12.434833527000023, 8.61255836600003],
              [12.43352317800003, 8.614599229000021],
              [12.43195152200002, 8.618402482000022],
              [12.432199479000019, 8.621337890000064],
              [12.435151100000098, 8.622630120000054],
              [12.439208030000032, 8.619510652000031],
              [12.442771912000069, 8.61966800700003],
              [12.443838119000077, 8.619489671],
              [12.44661045100014, 8.619024277000051],
              [12.449027062000141, 8.619776727000158],
              [12.453125954000029, 8.621562958000027],
              [12.455265045000147, 8.622495652000112],
              [12.45755386400009, 8.622242927000059],
              [12.459087371000066, 8.621971130000077],
              [12.461191177000046, 8.62159824500003],
              [12.464281082000184, 8.622154236000142],
              [12.466489793000051, 8.623842240000158],
              [12.467267037000056, 8.625081063000096],
              [12.467755317000126, 8.625859262000063],
              [12.468878744999984, 8.628878593000025],
              [12.474973678000083, 8.632802010000034],
              [12.475685119000048, 8.63342475900015],
              [12.477515220000043, 8.635027885999989],
              [12.477952003000041, 8.635991097000044],
              [12.478642463000142, 8.637512208000032],
              [12.480037690000017, 8.640199661000111],
              [12.482574462000116, 8.64316177500001],
              [12.485801698000159, 8.643450736000034],
              [12.487335205000136, 8.642964363000033],
              [12.488903046000075, 8.642467500000066],
              [12.49200439399999, 8.641349793000018],
              [12.500519753000162, 8.641536714000154],
              [12.504986762000044, 8.641634941000177],
              [12.507076263000158, 8.640978813000061],
              [12.507846833000144, 8.640419959000042],
              [12.508631705000084, 8.639851570000133],
              [12.511269569000035, 8.637592316999985],
              [12.513284683000109, 8.63608074299998],
              [12.516137122000146, 8.6339416510001],
              [12.517066002999968, 8.633508683000173],
              [12.521805763000145, 8.631299972000136],
              [12.523550987000078, 8.630859376000046],
              [12.524905205000039, 8.630517005000058],
              [12.528483391000179, 8.63031768799999],
              [12.530289650000157, 8.630216599000107],
              [12.533581734000052, 8.630840303000184],
              [12.535932542000069, 8.630855561000033],
              [12.537685394000107, 8.630867003999981],
              [12.543688773000099, 8.62842846],
              [12.546252251000112, 8.626441002000092],
              [12.544527055000174, 8.62362671000011],
              [12.550526619000095, 8.621724128000039],
              [12.553215027000135, 8.622076035000134],
              [12.553602218000094, 8.622957231000044],
              [12.554276467000022, 8.624492645000089],
              [12.556687355000179, 8.626114846000064],
              [12.558077812000022, 8.626123429000188],
              [12.559916496000142, 8.626135827000155],
              [12.561469079000062, 8.62527561200011],
              [12.56233215400016, 8.624620439000182],
              [12.564443588000074, 8.623017311000012],
              [12.567419052000048, 8.620626449000099],
              [12.572356223999975, 8.616506576000177],
              [12.574411393000105, 8.614763260000188],
              [12.575332641000159, 8.61398124800013],
              [12.576080322999985, 8.614644051000028],
              [12.577005387000156, 8.615464210000084],
              [12.579218864999973, 8.616549493000093],
              [12.585471153000015, 8.61941528300008],
              [12.586328506000029, 8.619808198000158],
              [12.586604119000185, 8.619822502000091],
              [12.589221001000169, 8.619960785000103],
              [12.592718125000147, 8.620049477000123],
              [12.59754085600008, 8.62336063500004],
              [12.602725982000152, 8.623689652000167],
              [12.606888772000104, 8.623953820000168],
              [12.607188226000062, 8.624237061000031],
              [12.607958794000012, 8.624965668000016],
              [12.608148576000076, 8.626695633000111],
              [12.606722832000059, 8.629027368000038],
              [12.605761529, 8.632115365000175],
              [12.608241081000187, 8.633604050000145],
              [12.609104156000114, 8.634091378000164],
              [12.610520362000045, 8.634890557000119],
              [12.611178398000163, 8.635997772000167],
              [12.612318039, 8.63791465700001],
              [12.615338325000153, 8.639071466000075],
              [12.616314889000137, 8.638769149000041],
              [12.618304251999973, 8.638153076000151],
              [12.620461463000026, 8.636923790000026],
              [12.622490883000069, 8.63576793600015],
              [12.624420166000107, 8.635466577000159],
              [12.626194954000027, 8.635189057000105],
              [12.627822876000096, 8.635198594000087],
              [12.629693031000045, 8.635210037999968],
              [12.630757333000133, 8.635516167000162],
              [12.632311820999973, 8.635963440000126],
              [12.633782387000167, 8.637512208000032],
              [12.634704590000183, 8.64073181100008],
              [12.634718896000095, 8.640927315000056],
              [12.634890557, 8.643343926000114],
              [12.635601997000094, 8.64490413700014],
              [12.636084557000061, 8.645962716000042],
              [12.637830734000033, 8.646509172000151],
              [12.63951683099998, 8.645916939000074],
              [12.640747071000021, 8.645684243],
              [12.642951965000179, 8.645268441000098],
              [12.645492553000054, 8.646095275000107],
              [12.646308899000132, 8.646360398000127],
              [12.646477700000105, 8.646411895000028],
              [12.649196625000116, 8.647248269000102],
              [12.652476311000044, 8.650080682000066],
              [12.65536499100017, 8.650835037000036],
              [12.657973289000154, 8.651010513000188],
              [12.658659935000117, 8.651056290000156],
              [12.660731315000078, 8.653412818000106],
              [12.663771629999985, 8.651422500000081],
              [12.669105530000081, 8.648509026000056],
              [12.670841216000156, 8.647495271000139],
              [12.671603202000142, 8.647050857000124],
              [12.67335510200013, 8.646726609000098],
              [12.674234390000095, 8.647130966000077],
              [12.674831391000168, 8.647405624000101],
              [12.6771726610001, 8.649561882000114],
              [12.680594443000075, 8.651189804000182],
              [12.681986808999966, 8.651247024000099],
              [12.683193207000159, 8.651296616000138],
              [12.684428215000025, 8.65134716100016],
              [12.686839103000182, 8.653236390000075],
              [12.687105178000081, 8.654807091000066],
              [12.687294007000162, 8.655917167999974],
              [12.68794727300002, 8.659068108000099],
              [12.690293312999984, 8.66062259600011],
              [12.69324398100008, 8.662247657000023],
              [12.69396686499999, 8.664997101000154],
              [12.694826125000077, 8.66768074100014],
              [12.696095467000077, 8.669027329000187],
              [12.69805145399999, 8.668370246999984],
              [12.701418877000037, 8.667921066000019],
              [12.702076912, 8.669724465000115],
              [12.698886871000127, 8.674788475000071],
              [12.698543549000135, 8.675871850000135],
              [12.700216292999983, 8.679092407000042],
              [12.70067214900007, 8.679969788000051],
              [12.702675820000024, 8.682458877000045],
              [12.703935622000131, 8.685479163000025],
              [12.704566002000092, 8.691194534000147],
              [12.704637527000045, 8.691844941000056],
              [12.706278802000043, 8.693319321000161],
              [12.707114219000175, 8.694069863000095],
              [12.708168983000064, 8.69762420699999],
              [12.708831787000179, 8.699302673000148],
              [12.710567475000062, 8.701589585000022],
              [12.710217475999968, 8.703864097000121],
              [12.708988189000138, 8.705674172000045],
              [12.70817947300003, 8.706865310000183],
              [12.706298828000115, 8.714057922000052],
              [12.705837249000012, 8.71582317300016],
              [12.706427574000088, 8.718371392000051],
              [12.708094597000127, 8.720925331999979],
              [12.708145142000092, 8.723671913000146],
              [12.708810806000145, 8.724880219000113],
              [12.710252763000142, 8.727794648000099],
              [12.71073818300016, 8.728775979000034],
              [12.711061477999976, 8.730306626000129],
              [12.711261750000062, 8.731256486000177],
              [12.711306572000012, 8.732742310000049],
              [12.711318016000064, 8.73313140900018],
              [12.710423469000034, 8.734733581],
              [12.709343909999973, 8.736668587000054],
              [12.709381104000045, 8.737550736000173],
              [12.709403038000119, 8.738075257000105],
              [12.710743904000026, 8.738953591000154],
              [12.713834763000023, 8.739708902000189],
              [12.714001656000107, 8.740310670000042],
              [12.714691161000019, 8.742794037000124],
              [12.713867187000062, 8.745400429000085],
              [12.714367866000032, 8.746127130000104],
              [12.715535163000027, 8.747820854000111],
              [12.715568542000085, 8.748030662000019],
              [12.715989112000045, 8.750636101000055],
              [12.714958191000107, 8.754179],
              [12.715520860000026, 8.754578591000097],
              [12.718376159000115, 8.756609916000059],
              [12.720302582000045, 8.760772706000068],
              [12.722312928000122, 8.762124062000112],
              [12.723914146000084, 8.76209354299999],
              [12.724938393000059, 8.762073517000147],
              [12.728175163000174, 8.761021615000118],
              [12.728498458000161, 8.760869981000042],
              [12.731280326000103, 8.759567260000154],
              [12.732258797000156, 8.759108543000139],
              [12.734385491000069, 8.758111954000185],
              [12.737696647000121, 8.755922317000113],
              [12.739769936000187, 8.75593471500008],
              [12.740657806000115, 8.75594043699999],
              [12.741488457000173, 8.757899284000189],
              [12.742052078000029, 8.759229661],
              [12.742137909000064, 8.75923442900006],
              [12.744945526000151, 8.759381294000036],
              [12.748205184000028, 8.759365083000034],
              [12.751204490000191, 8.759350777000066],
              [12.753896713000131, 8.759433746000127],
              [12.758550644000138, 8.76035118100009],
              [12.758804321000071, 8.760400772000025],
              [12.759853362000115, 8.757480622000173],
              [12.760652542000173, 8.755255700000078],
              [12.763103484999988, 8.750582695999981],
              [12.765479088000177, 8.747181892000185],
              [12.767702102999976, 8.74686050400004],
              [12.772271157000034, 8.748226166000052],
              [12.774561882000114, 8.747904778000134],
              [12.777396202000148, 8.746515273000171],
              [12.780437469000162, 8.744457244000159],
              [12.783074378000094, 8.742530824000085],
              [12.785830497000177, 8.743083000000127],
              [12.787688254000159, 8.747513772000104],
              [12.789218903000062, 8.750468254000111],
              [12.790075301, 8.753621101000135],
              [12.792346, 8.756647110000131],
              [12.792600632000131, 8.759126664000121],
              [12.792856216000189, 8.762274742000159],
              [12.792980194000108, 8.7637949],
              [12.793416977000106, 8.769175530000041],
              [12.79349517899999, 8.770363808000127],
              [12.793601989000138, 8.77198886900004],
              [12.793384551000088, 8.774666787000115],
              [12.793713569000147, 8.77594089500019],
              [12.794046402000049, 8.776579857000115],
              [12.795043945999964, 8.778492927000116],
              [12.795866967000165, 8.78037452699999],
              [12.796306609999988, 8.781379700000116],
              [12.797951698000077, 8.787750245000098],
              [12.799397468000109, 8.789530754000055],
              [12.799756050000155, 8.789971352000123],
              [12.801497459000075, 8.79054641700003],
              [12.802644730000054, 8.790925027000071],
              [12.805064201000107, 8.79167556699997],
              [12.807339668, 8.793831825000041],
              [12.808523178000087, 8.794777870000075],
              [12.809617997000146, 8.79565334300014],
              [12.810746192000124, 8.798338889000092],
              [12.811811447000082, 8.800353051000172],
              [12.811395645000175, 8.802427293000164],
              [12.810624124000185, 8.80771255600007],
              [12.810195924000084, 8.811794282000108],
              [12.8101758950001, 8.815142632],
              [12.810968400000036, 8.817826272000048],
              [12.814394951000168, 8.81898403200006],
              [12.814446450000048, 8.82159614599999],
              [12.815377236000188, 8.823677064000037],
              [12.818917275000047, 8.828385352000055],
              [12.819844246000116, 8.831068992999974],
              [12.819495201999985, 8.833143235000023],
              [12.818005562000053, 8.834608079000077],
              [12.81672573000003, 8.834733962000087],
              [12.813295364000055, 8.834177971000031],
              [12.81107139500017, 8.834633827000062],
              [12.809379578000062, 8.836097718000133],
              [12.807612420000055, 8.838966370000151],
              [12.801888467000083, 8.850517274000026],
              [12.80086135900001, 8.853323936000095],
              [12.801248549999968, 8.856272699000101],
              [12.802443504999985, 8.85909175900008],
              [12.804514885000174, 8.861783027],
              [12.805992126999968, 8.862461091000171],
              [12.808413505000146, 8.862743377000072],
              [12.813535690000037, 8.861902238000027],
              [12.81581974, 8.862853050000183],
              [12.816213608000112, 8.864462853000077],
              [12.815460205000022, 8.866600990000109],
              [12.811133385000119, 8.869655609000176],
              [12.808897018000096, 8.87205410100006],
              [12.807467461000044, 8.874791146000121],
              [12.806979180000042, 8.87753296000011],
              [12.807497978000129, 8.88101863900016],
              [12.807415008000021, 8.883553505000066],
              [12.807533264000028, 8.886294366000129],
              [12.81041049900017, 8.889391898999975],
              [12.812689781000131, 8.891078950000065],
              [12.815851211000052, 8.891700744000161],
              [12.817656516000113, 8.893786430999967],
              [12.818768501000022, 8.899023057000022],
              [12.818356514000129, 8.90055370400006],
              [12.814766883, 8.904149055000119],
              [12.812806129000137, 8.905476570000076],
              [12.810452461000068, 8.904927254000086],
              [12.809725762000085, 8.902579309000089],
              [12.806494713000177, 8.902426719000061],
              [12.803806304000034, 8.912803650000058],
              [12.803986550000047, 8.916487693000136],
              [12.806061745000079, 8.918642999000156],
              [12.808680535, 8.919796943000051],
              [12.81149482699999, 8.922157288000051],
              [12.816841125000053, 8.929018975000133],
              [12.820909501000074, 8.935738563000086],
              [12.822357178, 8.941371919000119],
              [12.822917938000046, 8.949076653000077],
              [12.8274688730001, 8.953857422000112],
              [12.827931405000186, 8.955333711000151],
              [12.827120782000179, 8.962435723000169],
              [12.826296806000187, 8.969654084000069],
              [12.826280595000185, 8.972344398000132],
              [12.82627964, 8.972585679000076],
              [12.82814216700001, 8.976560592999988],
              [12.831533431000139, 8.983799934999979],
              [12.835059166000065, 8.991253854000092],
              [12.837115288000064, 9.000750542000162],
              [12.838541985000063, 9.007330895000052],
              [12.842202186000122, 9.012992860000054],
              [12.845215797000151, 9.017654419000166],
              [12.847557067000082, 9.022265435000122],
              [12.850210189000052, 9.027490617000069],
              [12.857115745000101, 9.039489747000118],
              [12.86554908800008, 9.053650856000047],
              [12.868550301000141, 9.058691024000154],
              [12.869717597999966, 9.060395241000038],
              [12.873321534000127, 9.065654754999969],
              [12.876753808000046, 9.073943139000107],
              [12.877591132000077, 9.075963974000047],
              [12.883554459000095, 9.084848405000173],
              [12.88764, 9.087735177000127],
              [12.888084411000079, 9.092290879000132],
              [12.888735772000075, 9.09604549400018],
              [12.886968614000068, 9.098713875000044],
              [12.886906623000129, 9.099637985000129],
              [12.886820794000073, 9.100922585000035],
              [12.888139724000155, 9.106291772000077],
              [12.890252113000031, 9.110437394000087],
              [12.89255619100004, 9.111000060000094],
              [12.894306183000083, 9.111345290999964],
              [12.895583153000075, 9.111822129000188],
              [12.897060394000164, 9.112700462000134],
              [12.897856712000134, 9.114781380000181],
              [12.898114204000137, 9.116925240000114],
              [12.897027015000162, 9.118526458000076],
              [12.895195962000059, 9.120457649000059],
              [12.892486572000053, 9.122920036000153],
              [12.89186954600018, 9.12465763],
              [12.89085006800002, 9.126628875000165],
              [12.891180991000169, 9.129328729000065],
              [12.893185615000107, 9.13220977900005],
              [12.893813132000048, 9.133534431000044],
              [12.89403534000013, 9.134615899000039],
              [12.893149376000054, 9.136302948000093],
              [12.891658783000082, 9.137700081000105],
              [12.890829087000157, 9.141177177000031],
              [12.889946938000094, 9.142244339000058],
              [12.887045860000057, 9.14496135700017],
              [12.884822846000134, 9.146099090000064],
              [12.883801460000029, 9.146621704000154],
              [12.882692336000048, 9.149849891000145],
              [12.884085656000082, 9.153473855000129],
              [12.883557319000147, 9.158617019000076],
              [12.884569169000031, 9.163330078000058],
              [12.887184144000116, 9.165010453000093],
              [12.888712883000039, 9.165822984000101],
              [12.889225007000107, 9.167203904000132],
              [12.889157296000121, 9.168809891000024],
              [12.888799667000114, 9.170817376000116],
              [12.889135360000068, 9.172598838999988],
              [12.889245033000179, 9.173632622000184],
              [12.889013290000094, 9.17486477000017],
              [12.887451172000169, 9.176864624000075],
              [12.88602829000007, 9.180330277000053],
              [12.889709472000163, 9.182502746000125],
              [12.890743256000064, 9.183917046999966],
              [12.890151978000176, 9.186209679000115],
              [12.889442444999986, 9.189018249000185],
              [12.891620637000131, 9.191958429000067],
              [12.89375877399999, 9.192973137000024],
              [12.895931244000167, 9.195600510000077],
              [12.896716118000086, 9.199623108000026],
              [12.897685051000053, 9.20180988300001],
              [12.898651124000139, 9.204570770000089],
              [12.897267341000145, 9.208344459000159],
              [12.897930145000089, 9.21814251100011],
              [12.902072907000047, 9.222941398000103],
              [12.906376838000085, 9.224573135000185],
              [12.907505036000146, 9.227287292000142],
              [12.908697129000132, 9.229207992000056],
              [12.909276962000035, 9.230397225000161],
              [12.909408570000096, 9.230667115000074],
              [12.909974099000067, 9.231840133000162],
              [12.909617425000022, 9.235679627000025],
              [12.910748481000041, 9.239701271000058],
              [12.911247254000159, 9.243263245000094],
              [12.911863328000152, 9.246653557000116],
              [12.91225337899999, 9.249067307000189],
              [12.913624763000144, 9.25161457199999],
              [12.911118508000186, 9.255273818000092],
              [12.909591674000126, 9.259512901999983],
              [12.909906386000046, 9.264910698000051],
              [12.91454696600016, 9.271654129000126],
              [12.916018485000166, 9.276828766000108],
              [12.913043023000171, 9.281633378000095],
              [12.908691407000163, 9.284650802000158],
              [12.908589362000157, 9.292112350000082],
              [12.914378166000063, 9.300067901999967],
              [12.91348266600005, 9.305000306000068],
              [12.916028023000024, 9.314256667000052],
              [12.912659645000076, 9.317049981000082],
              [12.91200351700013, 9.320605277000084],
              [12.915275573000145, 9.324183465000033],
              [12.916951179000023, 9.328646659000185],
              [12.916559220000011, 9.337885857000117],
              [12.914043427000138, 9.341486931000134],
              [12.911263465000161, 9.344149590000086],
              [12.908204078999972, 9.348350525000171],
              [12.904894829000114, 9.34978199],
              [12.901178360000188, 9.352630615000066],
              [12.895460129000185, 9.354173660000129],
              [12.892437935000032, 9.354989051000189],
              [12.884777069000165, 9.350752830000033],
              [12.877400398000077, 9.347379684000032],
              [12.871991157000082, 9.349999427000171],
              [12.866780280000114, 9.352523803000111],
              [12.865715028000125, 9.3587903990001],
              [12.864347459000044, 9.35917091400006],
              [12.859462738000047, 9.360531808000019],
              [12.853751182000167, 9.359004974000186],
              [12.849277495000138, 9.362823486000139],
              [12.848336221000068, 9.365572929000166],
              [12.848431588000039, 9.368903160000116],
              [12.847949028000073, 9.369397164000134],
              [12.845755577000034, 9.371642114000053],
              [12.843784333000031, 9.372720719000029],
              [12.843959808000079, 9.373016358000086],
              [12.845328330000143, 9.375313758000061],
              [12.848039627000048, 9.376191139000127],
              [12.850256921000152, 9.374362946000133],
              [12.852566719000151, 9.372459412000183],
              [12.853716851000115, 9.372059823000086],
              [12.85578251000004, 9.371342660000153],
              [12.858825683000191, 9.370286942000178],
              [12.864787102000093, 9.369921685000065],
              [12.867774009000016, 9.371832848],
              [12.865544320000026, 9.377272605000087],
              [12.86603736800015, 9.381810188000088],
              [12.869993211000121, 9.386942863000115],
              [12.871006966000095, 9.391368866000107],
              [12.876081466000016, 9.39329433600011],
              [12.882894516000079, 9.394425392000073],
              [12.884215355000038, 9.395150185000091],
              [12.890095710000139, 9.398371696000083],
              [12.894651414000123, 9.399949075000166],
              [12.899953842000116, 9.40256405000008],
              [12.903928756000028, 9.404768944000068],
              [12.906695366000122, 9.406047821000072],
              [12.910503387000176, 9.407218933000024],
              [12.913206101000128, 9.409530639000138],
              [12.915446281000072, 9.411783218999972],
              [12.917519570000081, 9.413058282000065],
              [12.923587800000178, 9.413208962000113],
              [12.926409722000074, 9.415061951000041],
              [12.933040618000177, 9.417798996000101],
              [12.936788558000103, 9.419313430000159],
              [12.939598082999964, 9.423233986000184],
              [12.942189216000031, 9.424913407000133],
              [12.941417695000098, 9.428238868000051],
              [12.942171098000131, 9.429681778000088],
              [12.943189621000158, 9.431635857],
              [12.945940017000169, 9.435785294000084],
              [12.949964523000062, 9.43953991],
              [12.954811096000071, 9.441061019000017],
              [12.959252358000185, 9.442693711000118],
              [12.961954117000175, 9.445349693000139],
              [12.964365957999973, 9.448061942000095],
              [12.965515137000125, 9.44940948500016],
              [12.965761184000144, 9.449696541000094],
              [12.967122077000056, 9.451292991],
              [12.96929454900004, 9.45543861499999],
              [12.97020339900007, 9.458199502000127],
              [12.97018528100017, 9.461356163000062],
              [12.970745087000125, 9.46440219800013],
              [12.970840454000097, 9.464950561000137],
              [12.970915795000053, 9.46537971600003],
              [12.97141647300009, 9.468252183000061],
              [12.97232437100007, 9.471127510000031],
              [12.974394798000048, 9.47297668499999],
              [12.977852822000159, 9.471185683000158],
              [12.981411934000107, 9.469343187000106],
              [12.984173774, 9.471540452000113],
              [12.984277726000073, 9.471734047000041],
              [12.98634719800009, 9.475571634000062],
              [12.991021157000034, 9.47709083500007],
              [13.004919053000094, 9.483370782000179],
              [13.005091667000158, 9.483617783000113],
              [13.007499693999989, 9.487058640000157],
              [13.00903987900017, 9.490684509],
              [13.009592057000191, 9.491151809000144],
              [13.010342598000022, 9.491786004000119],
              [13.01065635700013, 9.492051126000035],
              [13.012663841000176, 9.493747710999969],
              [13.019639968000149, 9.497231484000054],
              [13.023838043000126, 9.501216888000101],
              [13.029078483000092, 9.5050354],
              [13.031601906000105, 9.508608819000187],
              [13.034303665000095, 9.511322022000059],
              [13.039406777000067, 9.508881570000085],
              [13.04676055900012, 9.506971359999966],
              [13.05044937200006, 9.509058953000135],
              [13.052121163000095, 9.509888649000061],
              [13.054428101000042, 9.511033058000123],
              [13.056089401000179, 9.511475563999966],
              [13.057950021000124, 9.511970520000091],
              [13.058562279000171, 9.51297759900018],
              [13.059840202000089, 9.51508140500016],
              [13.063468932000148, 9.517684937000126],
              [13.067400931000122, 9.517649651000056],
              [13.06850624100008, 9.517196655000021],
              [13.07099437700009, 9.516176224000049],
              [13.073780060000104, 9.514411926000093],
              [13.076359749000119, 9.514647485000126],
              [13.077132225000071, 9.514718056000049],
              [13.078504562000035, 9.517480850000027],
              [13.079019545999984, 9.51817608000016],
              [13.080168724000032, 9.519729615000188],
              [13.084984780000127, 9.520353317000115],
              [13.085253715000079, 9.520388604000061],
              [13.085699081000143, 9.520816804000162],
              [13.087957382000013, 9.522986413000183],
              [13.088158607000082, 9.523169517000042],
              [13.088335037000093, 9.523328781999965],
              [13.09025859799999, 9.525066376000041],
              [13.092560768000055, 9.526973725000062],
              [13.095451356000126, 9.527218819000041],
              [13.099099159000048, 9.526377677000085],
              [13.101334571000109, 9.525461197000141],
              [13.102692605000016, 9.524904251000066],
              [13.103812217000097, 9.52486419600001],
              [13.105468750000114, 9.524805068000092],
              [13.108869552000158, 9.526718140000128],
              [13.112674713000104, 9.528920174000064],
              [13.116373061000047, 9.529284478000022],
              [13.116589546000114, 9.529258729000105],
              [13.121060371000112, 9.528735160000053],
              [13.123741149000068, 9.528590203000022],
              [13.125862121000011, 9.528474808000169],
              [13.129724502000158, 9.530619621000085],
              [13.133470535000185, 9.532936097000061],
              [13.135996818000081, 9.534601212000098],
              [13.139002798999968, 9.536581993000084],
              [13.142971040000134, 9.537418367000043],
              [13.14431667299999, 9.537701607000031],
              [13.14844608400017, 9.539120674000117],
              [13.148549079000077, 9.53915596100012],
              [13.149916647999987, 9.539626123000119],
              [13.153028489000178, 9.541651725000179],
              [13.154339790000051, 9.542194366000047],
              [13.156259537000153, 9.542989731000034],
              [13.15979862100005, 9.540826797000136],
              [13.161712646000069, 9.539917946000173],
              [13.163825035000173, 9.540867806000051],
              [13.164942742000164, 9.54137039200009],
              [13.165541649999966, 9.541851044000055],
              [13.165806770000074, 9.542063714000051],
              [13.166669845000172, 9.54298687000005],
              [13.168130874000155, 9.543637276000084],
              [13.169150352000145, 9.544091225000045],
              [13.170487404000085, 9.544188499000086],
              [13.171693801000174, 9.54427623800018],
              [13.174058914, 9.545436860000109],
              [13.177512169000011, 9.548612594000133],
              [13.179372788000023, 9.549421312000106],
              [13.180454253999983, 9.549891471000024],
              [13.182771682000066, 9.551399232000165],
              [13.183567047000054, 9.551917076000166],
              [13.186503410000114, 9.554458618000126],
              [13.187600136000071, 9.556194306000066],
              [13.189198493999982, 9.558721543000161],
              [13.192313194000121, 9.560287476000156],
              [13.193317414000092, 9.560080529000175],
              [13.195454597000094, 9.559639931000106],
              [13.195845605000102, 9.559559822000153],
              [13.199729920000038, 9.556781769000054],
              [13.200548173000186, 9.556196212000032],
              [13.204248429000074, 9.556445121000081],
              [13.207711219000089, 9.557841301000167],
              [13.209954261000064, 9.56020641399999],
              [13.211271286000056, 9.562739373000056],
              [13.212532044000056, 9.564482690000148],
              [13.213854788999981, 9.566312791000144],
              [13.215450286000134, 9.569130897999969],
              [13.215517044000137, 9.569249153000101],
              [13.216830253000182, 9.572586060000049],
              [13.218588828000122, 9.575234413999965],
              [13.21889591300004, 9.575696946000051],
              [13.22007083799997, 9.578444482],
              [13.220404626000061, 9.579224586000066],
              [13.22049617800019, 9.579437256000119],
              [13.22192573500007, 9.582544327000051],
              [13.22468948400001, 9.584912300000156],
              [13.224765778000119, 9.585958481000091],
              [13.224905967000097, 9.587899208000181],
              [13.224715233000097, 9.591171265000128],
              [13.224634171000162, 9.592672348000065],
              [13.224522590999982, 9.594729424999969],
              [13.224546432000125, 9.596246719000135],
              [13.224566461000052, 9.59754276200016],
              [13.224815368000122, 9.598659516000168],
              [13.224848747000181, 9.598807336000164],
              [13.224621773000024, 9.599450111000124],
              [13.223663331000125, 9.602158546000169],
              [13.224248886000169, 9.603807450000147],
              [13.224845886000026, 9.605488777000062],
              [13.227044105000061, 9.608198166000022],
              [13.229492187000119, 9.611409187000049],
              [13.231188774000088, 9.613363266000135],
              [13.233260156000028, 9.616133690000027],
              [13.234271050000018, 9.617271424000023],
              [13.23583698200008, 9.619032861000051],
              [13.236079216000064, 9.621041298000023],
              [13.235993386000075, 9.621459960000038],
              [13.23577785399999, 9.622517587000061],
              [13.235462189000089, 9.624069215000191],
              [13.235301019000076, 9.624863624999989],
              [13.230986594000058, 9.627915383000072],
              [13.230453491999981, 9.630839347000062],
              [13.229946136000137, 9.633618355000181],
              [13.22992324900008, 9.637990952],
              [13.22992038700005, 9.638489722999964],
              [13.22991657200015, 9.639202119000117],
              [13.231098175000113, 9.642846107000025],
              [13.231292724000184, 9.645042420000095],
              [13.231337548000113, 9.645545006000134],
              [13.229044913000052, 9.648858071000063],
              [13.22890090900006, 9.652181625000083],
              [13.233870507, 9.656786918000023],
              [13.234571457000129, 9.65810108200003],
              [13.236687661000076, 9.662071228000059],
              [13.235080719000166, 9.667143823000117],
              [13.234669685000085, 9.667812347000165],
              [13.232849121000072, 9.670770646000051],
              [13.229797363000159, 9.674079896000137],
              [13.229761124000106, 9.676107406000028],
              [13.229708672000186, 9.679098129000124],
              [13.230558394000127, 9.683653832000061],
              [13.230691910000189, 9.684371948000148],
              [13.231072426000026, 9.684940337000171],
              [13.233138085000178, 9.688023567000187],
              [13.237668990000145, 9.688534738000101],
              [13.238382339000111, 9.688614845000075],
              [13.238821983999969, 9.688710214000082],
              [13.24135017499998, 9.689257622000071],
              [13.24380397699997, 9.691465378000146],
              [13.246838569999966, 9.691606522000086],
              [13.24743175500015, 9.694055557000127],
              [13.247766495000121, 9.695438384000056],
              [13.250726699000097, 9.697649003000095],
              [13.25260925300006, 9.700293541000065],
              [13.252050398999984, 9.702522278000174],
              [13.251382828000089, 9.70518016799997],
              [13.249208451000072, 9.707664490000184],
              [13.247503281000036, 9.709613801000103],
              [13.248055459000057, 9.712879181000119],
              [13.251828193000108, 9.716787339000177],
              [13.250737190000166, 9.718734741000162],
              [13.249340057000097, 9.721228600000188],
              [13.250786781999977, 9.723103523000134],
              [13.252353668000126, 9.725133896000045],
              [13.253848076000168, 9.72821998500018],
              [13.25460624599998, 9.729786872999966],
              [13.256285667000157, 9.735128404000079],
              [13.256394387000057, 9.738515855000117],
              [13.25694465600003, 9.739873886000169],
              [13.258204460000172, 9.742979049000098],
              [13.257363320000024, 9.746738433000132],
              [13.255759240000032, 9.750103951000085],
              [13.255573274000085, 9.750494004000075],
              [13.255244256000026, 9.752635002000147],
              [13.254919053000037, 9.754755974000148],
              [13.255230903000097, 9.756563186000108],
              [13.255839349000155, 9.760092736000104],
              [13.255755425000132, 9.764107704000082],
              [13.259089471000152, 9.76751232099997],
              [13.258830070999977, 9.770587921000072],
              [13.258819581000182, 9.770709991000047],
              [13.257916451000085, 9.77421856],
              [13.258910180000157, 9.777610779000099],
              [13.259734154000114, 9.77844333600018],
              [13.266521453999985, 9.78530311500009],
              [13.271920205000015, 9.792984010000168],
              [13.276330949000112, 9.796080590000031],
              [13.280747414000018, 9.798172951000026],
              [13.281289101000027, 9.79873561900007],
              [13.284144402000095, 9.801702499000044],
              [13.285235404000105, 9.803359986000146],
              [13.286465644000145, 9.805228234000026],
              [13.287147521000122, 9.807829858000162],
              [13.287272454000117, 9.808305740000094],
              [13.287701606999974, 9.811005592000186],
              [13.288002014000085, 9.814018250000061],
              [13.291017532000183, 9.817985534000059],
              [13.294288634999987, 9.821452141000066],
              [13.297430993000148, 9.825231553000151],
              [13.299126625000099, 9.82768726400019],
              [13.298542977000125, 9.830444336000085],
              [13.296885490000022, 9.832945825000024],
              [13.294847489000063, 9.835821152000165],
              [13.29047489200002, 9.83730411499999],
              [13.2878685, 9.839988708000021],
              [13.283609390000038, 9.844170571000063],
              [13.28043937700005, 9.845659256000033],
              [13.274607658000093, 9.84807682100012],
              [13.272946358000127, 9.851204873000143],
              [13.269099235000056, 9.852761269000155],
              [13.264409065000109, 9.851035118000027],
              [13.259392738000088, 9.854773523000176],
              [13.256021500000031, 9.858268739000039],
              [13.252048493000018, 9.856115341000191],
              [13.248886108000136, 9.856162071000142],
              [13.247089386000141, 9.860857010000018],
              [13.242075919, 9.864030839000179],
              [13.242311477000158, 9.867356300000097],
              [13.244320869000148, 9.870126725000091],
              [13.244734763999986, 9.87552452],
              [13.24345397899998, 9.878465653000092],
              [13.24071598, 9.881964684000138],
              [13.237727165000081, 9.884897232000128],
              [13.237960816000168, 9.888536453000086],
              [13.237052918000188, 9.892797470000119],
              [13.237095833000069, 9.896498681000026],
              [13.237741471000049, 9.905974387000128],
              [13.236640929000089, 9.910674095000161],
              [13.236178398000106, 9.914435386000037],
              [13.236519814000133, 9.917415618000064],
              [13.236782073000029, 9.919707298000162],
              [13.238079071000072, 9.921390534000182],
              [13.241054536000149, 9.925250053000184],
              [13.249256133000074, 9.929558755000187],
              [13.252885818000095, 9.9314060210001],
              [13.253988267000125, 9.931967735],
              [13.260881425000093, 9.932505608000042],
              [13.26328945099999, 9.932279587000039],
              [13.265312195000035, 9.932088853000096],
              [13.269418717000178, 9.933176994000178],
              [13.269593238000141, 9.936063767000178],
              [13.271158219000029, 9.939208032000181],
              [13.275388717000112, 9.940797806000091],
              [13.27987671000011, 9.941511155000057],
              [13.280310631000077, 9.943207742000027],
              [13.277894975000038, 9.945390702000168],
              [13.277565002000188, 9.948148728000092],
              [13.280960083000139, 9.952180862000091],
              [13.281705856000144, 9.954756738000071],
              [13.281758308, 9.956765175000101],
              [13.285991669000055, 9.957978249000064],
              [13.284895898000116, 9.961736680000115],
              [13.285006523000106, 9.964748384000075],
              [13.28428459200012, 9.969763756000077],
              [13.28659629800012, 9.97542095099999],
              [13.287774085000137, 9.98019504500013],
              [13.285924911000052, 9.982757568000125],
              [13.277591706000067, 9.991183280000087],
              [13.272195815000089, 9.994417191000025],
              [13.267315864000182, 9.995897293000098],
              [13.261431694000066, 9.99586677700006],
              [13.25344657900007, 9.998083116000032],
              [13.247620583000128, 10.000475883000036],
              [13.247130393000077, 10.003755569000191],
              [13.248058319000108, 10.00710868900012],
              [13.24810504900006, 10.010926247000157],
              [13.249704361000056, 10.015020372000095],
              [13.248589516000095, 10.017382622000127],
              [13.248064042000124, 10.018494607000036],
              [13.247705460000134, 10.022377014000028],
              [13.248022080000055, 10.026330947000019],
              [13.25003528700006, 10.028886794000016],
              [13.246499061000065, 10.030977249999978],
              [13.24644279600011, 10.031010628999979],
              [13.247768403000123, 10.035773277000089],
              [13.251553535000085, 10.035658837000028],
              [13.252220154000099, 10.042205811000088],
              [13.259179115000109, 10.045398713000111],
              [13.264021873000047, 10.047227859000145],
              [13.265987397000117, 10.04794311500018],
              [13.269470215000183, 10.049209594000047],
              [13.274462699000082, 10.05149078400018],
              [13.274680137000132, 10.051411630000189],
              [13.274989129000119, 10.051298142],
              [13.27502727500007, 10.051340104000133],
              [13.276809692000029, 10.052758218000065],
              [13.279600142000163, 10.054977417000089],
              [13.282915115000094, 10.058902741000111],
              [13.287746429000151, 10.062986374000047],
              [13.289836884000181, 10.069310187999974],
              [13.291170120000174, 10.07532978100005],
              [13.291106224000032, 10.076334],
              [13.290871620000189, 10.080028533000132],
              [13.290673256000105, 10.083143234000033],
              [13.294435500000191, 10.08872413600011],
              [13.295471191000161, 10.089674950000074],
              [13.298058510000089, 10.092049599],
              [13.300613404000103, 10.092934608000178],
              [13.302902222000114, 10.093728065000164],
              [13.303020477000018, 10.09377098200008],
              [13.306687354000132, 10.095100402000128],
              [13.30955982100005, 10.096768380000128],
              [13.31696987100014, 10.101015091000022],
              [13.325882911000065, 10.107975005000185],
              [13.33221817000009, 10.114621162000049],
              [13.33424663500017, 10.116166114000123],
              [13.34294605299999, 10.122792244000038],
              [13.345782281000027, 10.115468979000127],
              [13.346518516000117, 10.113567352000075],
              [13.350732803000028, 10.11275959],
              [13.357240678000153, 10.112760544000082],
              [13.362504959000034, 10.114394188000063],
              [13.364343643000154, 10.118363380000062],
              [13.364796639000133, 10.119342804999974],
              [13.368962286999988, 10.120569230000115],
              [13.371765136000022, 10.12092018200002],
              [13.372783661000028, 10.121047973000032],
              [13.374333381000156, 10.121241569000063],
              [13.376142501000118, 10.121466637000026],
              [13.380082131000108, 10.12148571099999],
              [13.382518768000182, 10.120865822000155],
              [13.385764122000126, 10.120594979000032],
              [13.388016700000151, 10.122156142000165],
              [13.389118194000162, 10.122954369000013],
              [13.391768456000079, 10.124873162000085],
              [13.396805764000135, 10.125701904000096],
              [13.401963234000164, 10.125439644000039],
              [13.403121948000148, 10.125546456],
              [13.407233238000117, 10.125925064000057],
              [13.412094116000162, 10.127211571000032],
              [13.416666030000044, 10.128324509000095],
              [13.424302101000137, 10.130829811000069],
              [13.430779457000142, 10.133330346000093],
              [13.434075356000051, 10.134838103999982],
              [13.435496330000035, 10.136458397000013],
              [13.435596467000096, 10.136571885000023],
              [13.4361495980001, 10.137202262000017],
              [13.436660767000035, 10.139271737000115],
              [13.437050820000081, 10.142489433000037],
              [13.437324523000029, 10.145820618000073],
              [13.438929558000098, 10.149388313000145],
              [13.443427086000042, 10.153945922000048],
              [13.447705269000096, 10.15597534200009],
              [13.455744744000128, 10.159286500000121],
              [13.459332467000081, 10.160336494000148],
              [13.462573051000106, 10.161270142000092],
              [13.464000703000124, 10.162189483000077],
              [13.464059831000043, 10.162227630000132],
              [13.464999200000079, 10.162832261000119],
              [13.466618538000091, 10.163471223000045],
              [13.467485428999964, 10.163991928000087],
              [13.467802049000056, 10.164669992000029],
              [13.466973305000067, 10.168282509000051],
              [13.466417314000125, 10.171495436999976],
              [13.46654033700014, 10.174040794000177],
              [13.46591186500018, 10.17643261],
              [13.465743065000026, 10.17707538600007],
              [13.466768265000155, 10.180754661000151],
              [13.469264030000147, 10.184235574000127],
              [13.46980381000003, 10.18834877099999],
              [13.469382285000052, 10.191504479000173],
              [13.469137191000016, 10.194316864000086],
              [13.472830772, 10.197549820000063],
              [13.477928160999966, 10.198089600000117],
              [13.48076629600007, 10.198619843000017],
              [13.4828481670001, 10.199433326000076],
              [13.483187675000124, 10.201330185000131],
              [13.482832907999978, 10.202763557000026],
              [13.482186318000174, 10.204655648000028],
              [13.481418609000116, 10.207695008000144],
              [13.480360032000021, 10.210847856000044],
              [13.478829383000118, 10.215893745000187],
              [13.477014541000074, 10.219732285000021],
              [13.475318909000123, 10.222710609000046],
              [13.475302694999982, 10.22626972300003],
              [13.474764825000079, 10.229540824000026],
              [13.47491932, 10.231819153000174],
              [13.471597672000144, 10.233612060000098],
              [13.468611717000101, 10.235808373],
              [13.466630936000058, 10.239750863000097],
              [13.466482162000034, 10.24263000500008],
              [13.46564293, 10.246206283000049],
              [13.465962409999975, 10.247426031999964],
              [13.466440200000079, 10.249253273000079],
              [13.466702462000114, 10.250546456000052],
              [13.467176436999978, 10.252882003000138],
              [13.467089653000073, 10.255004883000083],
              [13.467023850000089, 10.256631851000066],
              [13.467557907000128, 10.258108140000104],
              [13.467596054000182, 10.258412362000058],
              [13.468003273000022, 10.261662484],
              [13.46862697500012, 10.26465129900015],
              [13.469110490000048, 10.267765998000186],
              [13.469188691000056, 10.268271447000188],
              [13.471149445000094, 10.270584107000047],
              [13.471249580000119, 10.272066117000065],
              [13.471402168000111, 10.274335861000111],
              [13.472877502000188, 10.276954651000096],
              [13.473405839000065, 10.278007508000087],
              [13.473833083999978, 10.278858185000047],
              [13.475735665000116, 10.281048775000102],
              [13.477694512000085, 10.283584594000047],
              [13.478437424000106, 10.284896851000155],
              [13.478672028000176, 10.285311700000079],
              [13.478589057000136, 10.28691959299999],
              [13.478544235000186, 10.287779808000039],
              [13.480151177000096, 10.291174890000093],
              [13.481901169000082, 10.292321206000111],
              [13.483329774000083, 10.29325771300006],
              [13.484560014000067, 10.293706893999968],
              [13.485876083000164, 10.29418754600016],
              [13.487435341000037, 10.29540062000018],
              [13.488045692000185, 10.300714492000168],
              [13.489439964999974, 10.312865258000102],
              [13.489423752000164, 10.316195487000073],
              [13.489415169000097, 10.31791782300013],
              [13.487826347000066, 10.322848319],
              [13.487112999000033, 10.326462746000061],
              [13.484838485000125, 10.329322815000182],
              [13.483474731000058, 10.33031749700001],
              [13.482569695000166, 10.330977440000026],
              [13.481402396000135, 10.332579613000178],
              [13.482142448000047, 10.335396767000191],
              [13.484451294000166, 10.337532044],
              [13.485052109000037, 10.337697982000066],
              [13.48740482300019, 10.338349343000061],
              [13.491929053999968, 10.337911605000102],
              [13.495060921, 10.337811470000076],
              [13.495237350000139, 10.337896347000083],
              [13.49639129600007, 10.338449478000086],
              [13.497092246000136, 10.338844299000129],
              [13.497316360000013, 10.338970184000175],
              [13.498349190000056, 10.339501382000094],
              [13.49793243300013, 10.34074783300008],
              [13.497320175000084, 10.342577934000076],
              [13.497346878000087, 10.343676567000102],
              [13.497376442000018, 10.344855308000035],
              [13.497066498000152, 10.347620964999976],
              [13.497023583000043, 10.348002435000126],
              [13.496831894000081, 10.348696708000091],
              [13.495715142000051, 10.352751733000161],
              [13.49522685900007, 10.355831147],
              [13.495534896000095, 10.357108117000166],
              [13.495824814000059, 10.358311653000101],
              [13.496815681000101, 10.360007285000052],
              [13.497434617000124, 10.361065865000057],
              [13.498368263000145, 10.363950730000113],
              [13.498351098000057, 10.367567062000035],
              [13.498299598000017, 10.367798805000064],
              [13.49772834800001, 10.370377541000096],
              [13.495800018000182, 10.377535819000173],
              [13.49558925600013, 10.378227234000065],
              [13.495044708000023, 10.380010605000109],
              [13.49455356500016, 10.381786346000013],
              [13.494286537000107, 10.382753373000014],
              [13.493415832000096, 10.386759759000029],
              [13.494111061000126, 10.391013145999977],
              [13.496912956000074, 10.3939065940001],
              [13.497670173000074, 10.394688606000159],
              [13.503267288000188, 10.396131516000025],
              [13.508985519000191, 10.397102356000062],
              [13.512382508000144, 10.398753166],
              [13.5129108430001, 10.399009705000026],
              [13.51707267800009, 10.401390077000144],
              [13.522192002000168, 10.402948380000055],
              [13.522706986000173, 10.403243066000073],
              [13.523856163000119, 10.403900147000172],
              [13.525753022000117, 10.406387329000097],
              [13.526452065000115, 10.409696579000183],
              [13.526788711000108, 10.414183617000162],
              [13.528200148999986, 10.418321609999964],
              [13.531648636000114, 10.420344353000075],
              [13.534029007000186, 10.421299935000093],
              [13.536246301000119, 10.423672676000081],
              [13.535909652999976, 10.427210807000165],
              [13.534818649000158, 10.430983544000128],
              [13.535511971000119, 10.435589790000051],
              [13.535735130000035, 10.439014435000161],
              [13.536554336000108, 10.442205429000012],
              [13.537495612000043, 10.44504261000003],
              [13.538314820000153, 10.448233604999984],
              [13.538297652000153, 10.451893806000044],
              [13.536736489000191, 10.454247474000113],
              [13.535051345000056, 10.457544326000061],
              [13.532907487000102, 10.460146904999988],
              [13.530749321000087, 10.462792397000044],
              [13.529276847000062, 10.464733125000066],
              [13.528139114, 10.466233252999984],
              [13.527770996000129, 10.468445779000092],
              [13.527579307000167, 10.470770835999986],
              [13.527296067000179, 10.474198340999976],
              [13.527234078000106, 10.47434425299997],
              [13.527202607000049, 10.474418641000113],
              [13.52662372500015, 10.475779533000093],
              [13.526526451000052, 10.476008416000127],
              [13.527542113000095, 10.479068757000107],
              [13.530669212000078, 10.479702949000171],
              [13.534340859000054, 10.478834153000037],
              [13.536128043000133, 10.479107856000041],
              [13.538351059000036, 10.479948043000036],
              [13.54009532900011, 10.483305931000018],
              [13.54325771399999, 10.487272264000012],
              [13.545640946000049, 10.490401268000085],
              [13.549291610000125, 10.495193482000161],
              [13.549695969000084, 10.496518135000031],
              [13.550218583000174, 10.498228072000018],
              [13.550318718000028, 10.498555183],
              [13.550547599000026, 10.499301910000099],
              [13.550701141000104, 10.499803543000098],
              [13.55193805600004, 10.503085137000028],
              [13.552907944000026, 10.506099701000039],
              [13.553640366000025, 10.508366585000033],
              [13.554004668000175, 10.50949192000013],
              [13.554054259999987, 10.50964641500002],
              [13.555828094000049, 10.512929916000019],
              [13.560545922000131, 10.517997741000045],
              [13.564107894000131, 10.521378517000187],
              [13.566156387000092, 10.523335456000154],
              [13.569179534, 10.527333261000081],
              [13.571936608000044, 10.530976297000052],
              [13.5736255650001, 10.533373834000145],
              [13.573973656000021, 10.535323143000028],
              [13.573521615, 10.536383630000103],
              [13.573246955000059, 10.537798882000118],
              [13.570180892999986, 10.543097497000076],
              [13.568197251000186, 10.546363830000018],
              [13.565948486000025, 10.549098969000113],
              [13.563518524000074, 10.552098274000059],
              [13.560514451000074, 10.555427552000026],
              [13.560303688000147, 10.555661201000078],
              [13.559381485000131, 10.556682586000079],
              [13.558300019, 10.558448792000036],
              [13.559071541000094, 10.56571197500017],
              [13.560029983000163, 10.571117400000048],
              [13.561712264000164, 10.575020791000156],
              [13.563758850000056, 10.577420235000091],
              [13.565358162000052, 10.579906463999976],
              [13.566246032000151, 10.581327439000063],
              [13.566771507000169, 10.583720208000045],
              [13.565506934999974, 10.586281776000078],
              [13.564065932000062, 10.588399888000026],
              [13.560116767000068, 10.590949058999968],
              [13.557664872000146, 10.591027261000079],
              [13.554911613000172, 10.594201089000137],
              [13.554624557000011, 10.597465515000067],
              [13.554614067000045, 10.597585678000144],
              [13.554250717000173, 10.601723672000048],
              [13.553516388000105, 10.605527878000032],
              [13.55135345399998, 10.60879325799999],
              [13.548743249000097, 10.612056733000088],
              [13.546401024000033, 10.615410806000057],
              [13.545849800999974, 10.61833000200005],
              [13.547357559000034, 10.621170043000063],
              [13.553342820000125, 10.623411177999969],
              [13.560036658000115, 10.627160072000038],
              [13.565034867, 10.630016328000067],
              [13.567351341, 10.632240295000145],
              [13.570919036000078, 10.634735109000189],
              [13.573463440000069, 10.645813943000064],
              [13.572715760000051, 10.652627945],
              [13.574301719000061, 10.657858850000025],
              [13.578853607000099, 10.660623549999968],
              [13.581529618000104, 10.662494660000107],
              [13.582773209000095, 10.664537430000166],
              [13.583840371000065, 10.666135788000076],
              [13.584637641000143, 10.667820931000165],
              [13.585251808000066, 10.670392037000056],
              [13.585781097000051, 10.672164918000135],
              [13.585951804999979, 10.673935891000042],
              [13.586832046000097, 10.67721557700014],
              [13.586909295000169, 10.679872514000124],
              [13.585287094000137, 10.682167054000104],
              [13.583312989000149, 10.683308602000068],
              [13.580073357000174, 10.687101365000103],
              [13.579355241000087, 10.689084053000158],
              [13.578440666000176, 10.691609384000117],
              [13.579733849000149, 10.695012093],
              [13.579855919000124, 10.695334436000167],
              [13.582437514999981, 10.698267936000036],
              [13.58510875800016, 10.701378822000038],
              [13.585398674000146, 10.701917649000109],
              [13.586972236000122, 10.70483970600003],
              [13.590194702000133, 10.704854012000169],
              [13.596648216000119, 10.702936172000079],
              [13.603895188000024, 10.703676224000048],
              [13.606571197000051, 10.705813407000051],
              [13.60825538500012, 10.709362031000012],
              [13.611194611000144, 10.712739944000191],
              [13.614945411000122, 10.714704514],
              [13.618782996000164, 10.717111589000126],
              [13.621281623000186, 10.718982697000058],
              [13.622164727000097, 10.72164249400015],
              [13.622685432000083, 10.725275039000053],
              [13.625529290000031, 10.729714394000098],
              [13.627746581000054, 10.734327316000019],
              [13.628279686000042, 10.737370491000036],
              [13.628444671000125, 10.738314630000048],
              [13.629049302000112, 10.743275642000015],
              [13.63109016500016, 10.747357369000156],
              [13.633400916000085, 10.750997543999972],
              [13.63589382300006, 10.754107475000183],
              [13.637672424000016, 10.756771087000118],
              [13.639720917000147, 10.759259224],
              [13.641799927000022, 10.763485909000053],
              [13.64137458700003, 10.769890785000143],
              [13.642624856000168, 10.773657799000034],
              [13.642691612000021, 10.773859023000171],
              [13.643405915000073, 10.776008606000119],
              [13.64876556400003, 10.778865815000188],
              [13.651712417000056, 10.780649186000062],
              [13.655187607000187, 10.784294129000102],
              [13.653999329000101, 10.789778709000018],
              [13.650948524, 10.791358948000152],
              [13.64655971500008, 10.79160499700015],
              [13.640740395000137, 10.791491509000025],
              [13.636975289000191, 10.792360305000102],
              [13.637856483000064, 10.79388046400004],
              [13.637912750000055, 10.793978691000063],
              [13.638930320000043, 10.795733453000139],
              [13.640172958000164, 10.798217774000079],
              [13.642304421000063, 10.801945687000057],
              [13.644616127000177, 10.80567455400012],
              [13.647015572000043, 10.809668541000121],
              [13.648609160999968, 10.813748360000091],
              [13.650651932000187, 10.817653655000186],
              [13.651891708000051, 10.820580482000139],
              [13.658527374000016, 10.81857299800015],
              [13.662828445, 10.818059922000145],
              [13.666858674000139, 10.817900657],
              [13.669097901000043, 10.817821503],
              [13.671244622000131, 10.818273544000022],
              [13.67240142899999, 10.819960594000179],
              [13.673826218000045, 10.821825981000075],
              [13.674447059000158, 10.823246002000076],
              [13.674797058000081, 10.825106622000021],
              [13.674966811000161, 10.827232361000085],
              [13.675220490000129, 10.830509185000153],
              [13.676194191000093, 10.833169937000036],
              [13.676630974000091, 10.835739134999983],
              [13.677961349000157, 10.838665962000107],
              [13.680281638, 10.840535165000176],
              [13.683253287000127, 10.842506410000112],
              [13.686357498, 10.843660354000178],
              [13.689216614000145, 10.845088958000076],
              [13.693688393000059, 10.846613885000068],
              [13.697272301000112, 10.846185685000137],
              [13.699599266000178, 10.84646129500004],
              [13.701300620000097, 10.846556664000047],
              [13.703354836000017, 10.847893715000055],
              [13.705405235000114, 10.850204468000015],
              [13.707185746000107, 10.852602958000091],
              [13.708969117000152, 10.854380608999975],
              [13.709590911000078, 10.855711936000091],
              [13.71076965400016, 10.857292175],
              [13.713875770000129, 10.859918595000067],
              [13.714115143000129, 10.860454559],
              [13.716000556999973, 10.864681244000053],
              [13.716707230000111, 10.866897583000025],
              [13.717225074000169, 10.869109154000114],
              [13.717754364000086, 10.873541832000058],
              [13.718751908000172, 10.876417161000063],
              [13.718889237000099, 10.880275727000082],
              [13.719467164000037, 10.883654595000053],
              [13.719857215000047, 10.887407304000135],
              [13.719838142000185, 10.891828536000048],
              [13.720022202000109, 10.896517753000126],
              [13.719970703000058, 10.899670601000082],
              [13.720494270000074, 10.903125763000162],
              [13.721377373000053, 10.906050682000114],
              [13.723860740000134, 10.911727906000181],
              [13.725547791000167, 10.915365219000137],
              [13.727048874000104, 10.920417786000144],
              [13.727931975999979, 10.923343658000078],
              [13.727416039000047, 10.926826478000123],
              [13.726105690000054, 10.93148136200017],
              [13.724213599000109, 10.93404102400001],
              [13.720885278000139, 10.937214853000171],
              [13.717468263000114, 10.94012260400018],
              [13.712702752000041, 10.94417476600006],
              [13.711171149999984, 10.946292878000179],
              [13.709904671000118, 10.949121476000073],
              [13.709353448000059, 10.952218056000163],
              [13.708972930000186, 10.957440376000079],
              [13.708863257000075, 10.961955070000045],
              [13.707938194, 10.968679427000154],
              [13.708013535000134, 10.97204494500005],
              [13.710601806000113, 10.974357606000012],
              [13.718112946000076, 10.977842331000147],
              [13.721418381000092, 10.979981422000094],
              [13.724404335000031, 10.981472016000168],
              [13.724458695000123, 10.981498718000068],
              [13.726331711, 10.983454705000156],
              [13.728320121000024, 10.986740113000053],
              [13.729178429000171, 10.988159180000139],
              [13.73050880400001, 10.991263390000086],
              [13.730878829000176, 10.993593217000068],
              [13.730945587, 10.994009972000185],
              [13.730129241000157, 10.996308327000179],
              [13.727789879000056, 10.998512268000184],
              [13.728044511000178, 11.001877785000033],
              [13.728472709000073, 11.00245475700018],
              [13.72982597400005, 11.004276275000052],
              [13.731299401000172, 11.006175994000102],
              [13.73134136300007, 11.006230354000024],
              [13.73140335100004, 11.006333352000183],
              [13.732675552999979, 11.00844955600013],
              [13.734724044000131, 11.011379243000135],
              [13.737960816000054, 11.011229516000128],
              [13.738220215000126, 11.011217118000161],
              [13.738350869000101, 11.011218071000144],
              [13.742700577999983, 11.011235237000108],
              [13.744602204000103, 11.011191369000073],
              [13.745925904000046, 11.011160850000124],
              [13.747109414000079, 11.010931969000069],
              [13.749513627000169, 11.010466575000123],
              [13.752384186000086, 11.009770393000053],
              [13.755523681000057, 11.00898647300005],
              [13.758124351000106, 11.008554458000106],
              [13.758297921000064, 11.008517266000069],
              [13.760545731000093, 11.008032799000034],
              [13.763378144000058, 11.007485390000113],
              [13.763684273000081, 11.007426263000127],
              [13.764782905, 11.007401467000022],
              [13.767000199000165, 11.007350922000171],
              [13.769060135000075, 11.007624626999984],
              [13.771657943999969, 11.007901192000077],
              [13.77230358200012, 11.00794601500013],
              [13.775689126000088, 11.008183479000024],
              [13.777960777000033, 11.007611274000055],
              [13.778110504000097, 11.007573127000171],
              [13.778275490000055, 11.007561684000052],
              [13.780530930000168, 11.007405282000036],
              [13.781766891000188, 11.007411001999969],
              [13.782502175000104, 11.00741386500016],
              [13.784920693000174, 11.00760078400009],
              [13.78698158300017, 11.007785797],
              [13.787410736000083, 11.007893562000106],
              [13.789129258000173, 11.008325577000107],
              [13.789532662000113, 11.008411407000096],
              [13.790830613000139, 11.008686067000042],
              [13.791751860999966, 11.009218217000068],
              [13.792529104999971, 11.009667397999976],
              [13.793596268000101, 11.011708261000024],
              [13.794397355, 11.013039589000016],
              [13.795156478000024, 11.01391124700001],
              [13.795557022000139, 11.01437187300013],
              [13.797340393000184, 11.016592024999966],
              [13.797372817, 11.017913819000114],
              [13.797416687000066, 11.01969146800019],
              [13.795348166999986, 11.021542549000117],
              [13.79480171199998, 11.023754120000092],
              [13.794703484000024, 11.025878907000106],
              [13.7962188730001, 11.027832984000042],
              [13.797914505000051, 11.029610635000154],
              [13.798669815000039, 11.030137062000108],
              [13.800006866000103, 11.031070709000176],
              [13.798973084000181, 11.033864022000103],
              [13.800043106999965, 11.035197258999972],
              [13.801112175000128, 11.036529541999982],
              [13.802018165000163, 11.03752136300011],
              [13.802897453000071, 11.038483620000022],
              [13.803733825000165, 11.040153503999989],
              [13.803963661000182, 11.04061317500009],
              [13.804224968000028, 11.040966034000064],
              [13.805212021999978, 11.042300225000076],
              [13.805995941000106, 11.044047356000135],
              [13.806365966000101, 11.044872285000054],
              [13.808512687000132, 11.046120644000098],
              [13.808660506000024, 11.047490120000077],
              [13.808771134000153, 11.048512458000062],
              [13.809749602000068, 11.050375938000059],
              [13.810912132000169, 11.05317211199997],
              [13.811430931000018, 11.056667328000174],
              [13.812047005000011, 11.058530808000171],
              [13.812486648000061, 11.060657502000083],
              [13.813733101000082, 11.062698365000131],
              [13.813156127000127, 11.063725471000168],
              [13.81274032499999, 11.064465523000138],
              [13.812033654000061, 11.066532135000045],
              [13.811833382000032, 11.067117692000124],
              [13.811993600000108, 11.067862510000111],
              [13.812273025000081, 11.069156648000046],
              [13.814145088000146, 11.071642877000158],
              [13.81515884300012, 11.072148323000022],
              [13.816471100000058, 11.072802544000069],
              [13.818288801999984, 11.074140549000049],
              [13.818526268000085, 11.074316024999973],
              [13.819976806000057, 11.073419570000169],
              [13.821941376000041, 11.072204591000059],
              [13.824185371000169, 11.071328162000157],
              [13.824804305000043, 11.071330070000101],
              [13.825618743000121, 11.071332932000189],
              [13.825968742000043, 11.072737693000136],
              [13.826060294000172, 11.073105813000041],
              [13.827038765, 11.075057984000182],
              [13.827749252999979, 11.076743126000167],
              [13.828409194000187, 11.078208924000137],
              [13.828548431000058, 11.078516961000105],
              [13.828492165, 11.079282760000183],
              [13.828385353000044, 11.080753326000149],
              [13.828363419000027, 11.08254146500002],
              [13.828348158999972, 11.083827973000098],
              [13.829505920000145, 11.085781097000051],
              [13.831562042000087, 11.087028503000056],
              [13.834432601000174, 11.086420059000147],
              [13.836399078000113, 11.087755204000018],
              [13.83682823099997, 11.088324546000081],
              [13.837737084000139, 11.089530945000149],
              [13.839253425000095, 11.091485024000121],
              [13.840334893000033, 11.091801643999986],
              [13.841402053000081, 11.092113496000024],
              [13.843320848000076, 11.09157943800011],
              [13.84391403300009, 11.091414451000048],
              [13.845437050000044, 11.091597557000057],
              [13.846004486000083, 11.091752052000174],
              [13.846422196000049, 11.091866494000044],
              [13.847228051000172, 11.092224121000072],
              [13.847577095000076, 11.094615935999968],
              [13.848017693000145, 11.095086097999967],
              [13.848826407999979, 11.095949173000065],
              [13.850795746000074, 11.09657669],
              [13.851777077000179, 11.09781932900006],
              [13.852185248000069, 11.098251344000062],
              [13.853205681000077, 11.099329949000037],
              [13.8542776110001, 11.100397110000131],
              [13.854672431999973, 11.100496292000173],
              [13.855351449000125, 11.100666047000061],
              [13.856255531000158, 11.101721764000104],
              [13.857846261000134, 11.103880883000159],
              [13.857728005000126, 11.104452134000098],
              [13.857300758000065, 11.106517791000044],
              [13.858727455000064, 11.108559609000054],
              [13.860602379999989, 11.110603333000029],
              [13.860537529000055, 11.112106323000035],
              [13.860503198000174, 11.112904548000074],
              [13.859179496000081, 11.114497185000118],
              [13.858522415000152, 11.115287782000109],
              [13.859390258000076, 11.117337228000054],
              [13.859498978000033, 11.117593765000038],
              [13.862994195000113, 11.117960930000095],
              [13.864307404000158, 11.119035720000056],
              [13.865495682000073, 11.120007516000157],
              [13.867635727000106, 11.123025895000069],
              [13.867813110000156, 11.123363495000092],
              [13.868615151000142, 11.124888420000048],
              [13.868422509000027, 11.126317979000135],
              [13.868245125999977, 11.127632141000163],
              [13.869487762000119, 11.129694940000036],
              [13.869849206000026, 11.130294800000115],
              [13.871721268000044, 11.132957457999964],
              [13.873218536000081, 11.133492470000022],
              [13.874228478000191, 11.13385295900008],
              [13.875909805000106, 11.134191513000019],
              [13.876019478000046, 11.134213449000072],
              [13.87614822400019, 11.134373664000066],
              [13.876733781000041, 11.135101318000068],
              [13.876729011, 11.136252404000118],
              [13.876804352000136, 11.136698723000166],
              [13.87714004500009, 11.138691902000176],
              [13.877905846000147, 11.14123153800017],
              [13.87791347600006, 11.14128017500019],
              [13.878025054000034, 11.142019272000141],
              [13.878037452, 11.142097473000092],
              [13.878108025000074, 11.142564773000061],
              [13.878283501000055, 11.143720627000107],
              [13.878373145000182, 11.144254685000078],
              [13.878751755000053, 11.146531106000054],
              [13.879364967000186, 11.148541450000096],
              [13.879725456000074, 11.149722100000133],
              [13.879739761999986, 11.14974784899999],
              [13.879943849000085, 11.150126457000056],
              [13.87997055000011, 11.150177002000078],
              [13.881684303000043, 11.153359414000136],
              [13.883826256000077, 11.155935287000034],
              [13.886417390000076, 11.158158303000107],
              [13.889277459000027, 11.16055965400011],
              [13.891514778000101, 11.16163063099998],
              [13.894110680000153, 11.16270256100006],
              [13.89634704499997, 11.164216042000135],
              [13.898853302000134, 11.165199281000014],
              [13.90033817200009, 11.165909767000187],
              [13.90109062199997, 11.166270256000075],
              [13.90193748500019, 11.166791916000079],
              [13.902969359999986, 11.167428018000123],
              [13.904582978000121, 11.167522430000133],
              [13.906194687000152, 11.168236733000185],
              [13.907431602000088, 11.170537949000163],
              [13.907529831000147, 11.170721055],
              [13.907701492000058, 11.170936585000106],
              [13.908868789000053, 11.172408104000056],
              [13.910208702000034, 11.173741340999982],
              [13.911006927000074, 11.17604637200003],
              [13.911806107000132, 11.178085327000076],
              [13.912547112000027, 11.181217194000112],
              [13.912681579999969, 11.184136391000152],
              [13.912379264000037, 11.187197685000115],
              [13.912451745000055, 11.18757629400011],
              [13.912838935000138, 11.189599992000012],
              [13.913350104000074, 11.192522049000161],
              [13.913629532000073, 11.194118501000048],
              [13.915322303999972, 11.197046281000041],
              [13.916454315000124, 11.198012350999988],
              [13.917199135000089, 11.198647499000174],
              [13.918216705000077, 11.19907283800012],
              [13.919975280000017, 11.199808120000171],
              [13.923200608000116, 11.200528144000089],
              [13.923994065000102, 11.200946808000083],
              [13.924674988000049, 11.201306343000056],
              [13.924721717000068, 11.20133113900016],
              [13.92615413700014, 11.20195579600005],
              [13.927248955000096, 11.202563287000146],
              [13.928201675000082, 11.206036567000126],
              [13.92819500000013, 11.20789527900007],
              [13.92755699200012, 11.210638046999975],
              [13.929522515000144, 11.212681771000177],
              [13.930953026000111, 11.21392631700013],
              [13.931757927000149, 11.214371682000092],
              [13.933099746999972, 11.215173722000031],
              [13.933791160000055, 11.216032027999972],
              [13.933931351000069, 11.217372894000107],
              [13.934845924000115, 11.218955040000083],
              [13.935497284, 11.221734048000144],
              [13.936298370000031, 11.223419189000026],
              [13.938718796000103, 11.223605155000143],
              [13.940255165000167, 11.224381447000098],
              [13.941218375000062, 11.226890564000144],
              [13.942377090000093, 11.228754044000141],
              [13.943177222000088, 11.230793],
              [13.945049285000152, 11.233810426000161],
              [13.946384430000023, 11.236648559000059],
              [13.947450637000088, 11.239219667000157],
              [13.949235916000077, 11.241705894000063],
              [13.950757027000066, 11.242685319000032],
              [13.951828002000127, 11.244016647000024],
              [13.953167915000051, 11.245719909000115],
              [13.953722954000057, 11.249416351999969],
              [13.953735352000024, 11.249502182000128],
              [13.954988480000168, 11.251468659000068],
              [13.95604419700004, 11.254438400000026],
              [13.95618152600008, 11.255127907000144],
              [13.956473350000067, 11.256593704000181],
              [13.957068443000026, 11.258378982000124],
              [13.957315445000063, 11.259927749999974],
              [13.957506181000042, 11.261125566000146],
              [13.958322524000152, 11.26270675700016],
              [13.959110261000035, 11.264230729000133],
              [13.96076679100014, 11.266178130000071],
              [13.961074828000164, 11.266539575000024],
              [13.962231635000023, 11.269111633000023],
              [13.963747024000099, 11.27177333800006],
              [13.965705872000171, 11.275675773000103],
              [13.965832711000076, 11.275808333999976],
              [13.967404365000107, 11.277452469000025],
              [13.968027114, 11.278871537000043],
              [13.96855926600017, 11.280378341000187],
              [13.968453407000027, 11.28185176900007],
              [13.968348504000119, 11.284052848000044],
              [13.969113349999986, 11.286878585000125],
              [13.969782829000053, 11.289656640000032],
              [13.970790863000161, 11.292434693000132],
              [13.970881463000012, 11.294157982000172],
              [13.970986364999987, 11.296149255000159],
              [13.97101783700009, 11.296742440000116],
              [13.971251488000178, 11.29923152900011],
              [13.971756936000077, 11.300575257000105],
              [13.971971511000163, 11.301147461000028],
              [13.972118378000118, 11.301799774000074],
              [13.973219872000129, 11.306692124000051],
              [13.973709107000047, 11.307543754000164],
              [13.975003243000174, 11.309797286999981],
              [13.975359917000048, 11.310508728000173],
              [13.976428032, 11.312636375000068],
              [13.977802277000137, 11.314783096000156],
              [13.978302003000067, 11.31556415700004],
              [13.980623244000014, 11.318759919000115],
              [13.985551835000081, 11.320370675000163],
              [13.989415169000154, 11.31861305200016],
              [13.989880563000099, 11.318086625000035],
              [13.991756439000085, 11.315964698000016],
              [13.994904518000055, 11.313673021000056],
              [13.996395111000027, 11.312990189000061],
              [13.997779846000071, 11.312355042000149],
              [13.998793603000024, 11.311924934000103],
              [14.000474929000063, 11.311212540000156],
              [14.003259659999969, 11.310159684000041],
              [14.007029533999969, 11.309464456000114],
              [14.009022712000046, 11.308853150000118],
              [14.010172844000181, 11.308500290999973],
              [14.011883736000186, 11.306646347000083],
              [14.012907982000172, 11.305564881000123],
              [14.013053894000166, 11.305411339000045],
              [14.014944077000166, 11.303734780000127],
              [14.017097472999978, 11.303565025000012],
              [14.017694474000109, 11.303239822000023],
              [14.018714904000149, 11.302684784000121],
              [14.020421028999976, 11.302070617000027],
              [14.023206710000181, 11.300662994000163],
              [14.029765129000054, 11.297674179000069],
              [14.031209945000057, 11.297019005000038],
              [14.033449172000132, 11.296004295000103],
              [14.03511047500001, 11.29559993700019],
              [14.037039758000049, 11.295129777],
              [14.038497924000069, 11.294949531000043],
              [14.039821625000116, 11.294785501000149],
              [14.04242611, 11.294085503000133],
              [14.044588089000115, 11.292617799000027],
              [14.044763565000096, 11.292498589000104],
              [14.047998429000017, 11.290649414000143],
              [14.050694465000163, 11.289241791000109],
              [14.05338954900003, 11.288099290000162],
              [14.05545425299999, 11.28748607600005],
              [14.057788850000179, 11.286785126000154],
              [14.060841561000075, 11.28599739100008],
              [14.06435585000014, 11.283844948000024],
              [14.064436912000076, 11.283795356000041],
              [14.067760467000028, 11.282212257000026],
              [14.069985389000067, 11.281554223000114],
              [14.070723534000138, 11.281335831000035],
              [14.071030616000144, 11.28142929],
              [14.07278442300003, 11.281962395000164],
              [14.076010704000055, 11.282946586000151],
              [14.077320098000087, 11.282905579000044],
              [14.07861232800002, 11.282865525000091],
              [14.080309867999972, 11.282774925000069],
              [14.08175277800018, 11.282698632000063],
              [14.085107803000028, 11.281982423000045],
              [14.085433006000017, 11.281912805000161],
              [14.088573455000073, 11.281391143000121],
              [14.089293479000162, 11.281267167000181],
              [14.090984345000095, 11.280974389000107],
              [14.091086388000065, 11.280956268000125],
              [14.093421935000151, 11.279900551000082],
              [14.096115113000053, 11.279289245000143],
              [14.098985673000072, 11.279297830000075],
              [14.099526406000109, 11.279139520000115],
              [14.100781440000048, 11.278772355000172],
              [14.101833344000056, 11.278300286000103],
              [14.102936745000136, 11.277804375000017],
              [14.104647636000038, 11.275773050000112],
              [14.105679512000165, 11.274592400000074],
              [14.107348442000045, 11.272682190000126],
              [14.108217240000158, 11.271828652000067],
              [14.110136985999986, 11.269945144000189],
              [14.109789849000151, 11.266137123000135],
              [14.109706878000111, 11.263922692000108],
              [14.110697747000131, 11.262774467999975],
              [14.112493516000143, 11.262160302000154],
              [14.115364075000059, 11.261991501000125],
              [14.117025376000072, 11.261411667000118],
              [14.117877961000033, 11.26111412099999],
              [14.118827820000035, 11.26073742],
              [14.12120056200007, 11.259795189000101],
              [14.123678208000058, 11.258697510000161],
              [14.1249732980001, 11.258124353000085],
              [14.127576828000031, 11.257423400000107],
              [14.131035805000181, 11.25759601600015],
              [14.133226395000065, 11.257705688000158],
              [14.139328956000043, 11.25648403100007],
              [14.143366813000114, 11.255619050000178],
              [14.14381694900004, 11.255522728000017],
              [14.146419526000159, 11.254821778000121],
              [14.149291993000077, 11.254033089000131],
              [14.153153418999977, 11.252627373],
              [14.155309677000048, 11.251482009000085],
              [14.157464028000049, 11.250602722999986],
              [14.16024494100003, 11.250433922000127],
              [14.163926124000056, 11.249381066000069],
              [14.165816307000057, 11.246818543000074],
              [14.167164803000048, 11.245759964000115],
              [14.168959617000041, 11.245588303000034],
              [14.170571327000175, 11.246478082000067],
              [14.172005652000109, 11.246659278000095],
              [14.175499916000035, 11.248173714000188],
              [14.177108764000081, 11.250038147000168],
              [14.17880725800012, 11.251902581000081],
              [14.180508613000143, 11.253234864000092],
              [14.182656289000022, 11.25474643699999],
              [14.186152457999981, 11.255464553000081],
              [14.186188697000091, 11.255466461000083],
              [14.186394691000089, 11.255479814000012],
              [14.189111708999974, 11.255649567000148],
              [14.191719056000181, 11.253708839000126],
              [14.194681167, 11.252654076000169],
              [14.19665527300009, 11.252482413999985],
              [14.198806763000107, 11.252754211000024],
              [14.200060844000063, 11.253465651999988],
              [14.201046943000051, 11.25373363500006],
              [14.202120781000076, 11.254711151000095],
              [14.202920913000185, 11.257192611999983],
              [14.204616548000047, 11.260295866999968],
              [14.20864772900012, 11.262343408000049],
              [14.210798263000015, 11.262969016999989],
              [14.212232590000156, 11.263415336000094],
              [14.213616372000047, 11.264275551000082],
              [14.214374542000087, 11.267406463000157],
              [14.216430664000029, 11.269890784000097],
              [14.218757630000027, 11.271756172000096],
              [14.220637321000027, 11.273355484000092],
              [14.224391937000178, 11.27823543500017],
              [14.226897239000152, 11.280366898000068],
              [14.229314805000172, 11.282233238000117],
              [14.229969978000099, 11.282789230999981],
              [14.229998589000104, 11.282814980000069],
              [14.231193543000188, 11.283831597000074],
              [14.233250617000067, 11.286493302000167],
              [14.235692979000135, 11.288714408999965],
              [14.239702224000155, 11.288901330000101],
              [14.241136551000068, 11.289525031999972],
              [14.243374824000057, 11.29103565299999],
              [14.245254517000092, 11.292634010000029],
              [14.249468804000173, 11.293619155999977],
              [14.252876282000045, 11.293805122000094],
              [14.256549836000033, 11.29576206199999],
              [14.261920928000166, 11.300380708000091],
              [14.266039848999981, 11.303135872000155],
              [14.268369675000031, 11.304292679000184],
              [14.272402764000049, 11.3059844980001],
              [14.279480934000048, 11.309455871000182],
              [14.288619042000107, 11.314790726000069],
              [14.306000709000045, 11.32430744200002],
              [14.316573144000074, 11.330176354000173],
              [14.319889069000055, 11.331954957000164],
              [14.322309493000148, 11.332756997000047],
              [14.329746246000013, 11.337202073000128],
              [14.335300446000019, 11.340667726000106],
              [14.342646598000158, 11.345909119000055],
              [14.346139907000122, 11.34830760900013],
              [14.349453927000013, 11.350882530000092],
              [14.357070922000105, 11.35559272800009],
              [14.375636101000111, 11.37074852000012],
              [14.375776291000136, 11.370862961000114],
              [14.378388405000067, 11.37299442300008],
              [14.387079238000183, 11.37912273500018],
              [14.390075683000077, 11.381263733000026],
              [14.393802643000072, 11.385099411000112],
              [14.395206451000035, 11.386202812000022],
              [14.398545265000052, 11.389064789000145],
              [14.401840210000103, 11.391624450999984],
              [14.404888153000115, 11.39507579800005],
              [14.40669345900011, 11.397759438000037],
              [14.412073136000174, 11.400338172000033],
              [14.41413879300012, 11.401794433000191],
              [14.415008544000045, 11.403518676000147],
              [14.417183876000081, 11.40677738100004],
              [14.417850495000096, 11.407679558000154],
              [14.41810703200008, 11.40802669499999],
              [14.418635369000185, 11.408741951000025],
              [14.42001152099999, 11.410536767000053],
              [14.421537400000034, 11.412528038],
              [14.42228794000016, 11.413818359000118],
              [14.423181534000037, 11.415354728000125],
              [14.424306870000066, 11.416378976000146],
              [14.424972534000062, 11.416985512000053],
              [14.426619530000096, 11.418457986000021],
              [14.428277016000095, 11.41993999499999],
              [14.431326866000177, 11.420654297000112],
              [14.432649613000137, 11.420179367000117],
              [14.434021950000044, 11.419686318000061],
              [14.434199333000095, 11.419635774000142],
              [14.438062667000167, 11.418542862000152],
              [14.441025733, 11.418105125000068],
              [14.443897248000042, 11.418288231000133],
              [14.446319578999976, 11.418558121000046],
              [14.448292730999981, 11.41918182500018],
              [14.450983047000022, 11.42042636799999],
              [14.453852654000059, 11.421760558000074],
              [14.455735207000089, 11.42282676800005],
              [14.458335877000025, 11.423893928000041],
              [14.460759163000148, 11.423986435000131],
              [14.464169503000051, 11.42417049400018],
              [14.464477540000019, 11.424295425000139],
              [14.466142655000056, 11.424970626000118],
              [14.467979431000174, 11.427020072000062],
              [14.471172333000027, 11.431668282000089],
              [14.471400261000042, 11.432003976000146],
              [14.471446036000032, 11.43207073200017],
              [14.47426891300006, 11.436220169000023],
              [14.477463723000085, 11.439527511000108],
              [14.479837417, 11.441493988000047],
              [14.485168457000043, 11.444231033999984],
              [14.489724160000151, 11.447014809000052],
              [14.492097854000065, 11.448789597000143],
              [14.494082452000157, 11.451376916000072],
              [14.496889114, 11.455114364000053],
              [14.498873710999987, 11.457558633000076],
              [14.501198768000108, 11.459907531000056],
              [14.505220414000121, 11.462785720999989],
              [14.507740974000114, 11.464177131000042],
              [14.509387017000165, 11.465854645000093],
              [14.5101604460001, 11.467818261000048],
              [14.5115156170001, 11.469256401000166],
              [14.513598441999989, 11.471269608000057],
              [14.516143798000144, 11.472787857000185],
              [14.519823073000168, 11.473148346000073],
              [14.523682594000036, 11.47386264700009],
              [14.528168679000032, 11.47475528700005],
              [14.531220437000115, 11.474937440000133],
              [14.533733368000128, 11.475562097000022],
              [14.537950515000091, 11.477073670000095],
              [14.54261493600012, 11.479029657000069],
              [14.54674339200011, 11.479921341000079],
              [14.548986435000131, 11.480545045000042],
              [14.551499367000076, 11.480813980000164],
              [14.554869653000026, 11.481959344000074],
              [14.557486533000031, 11.483925819000149],
              [14.559278487000142, 11.485746384000038],
              [14.561749458, 11.488334655000074],
              [14.563591004000159, 11.489869117000012],
              [14.566497803000061, 11.492218017000027],
              [14.569601058000046, 11.493992806000051],
              [14.574111938999977, 11.49476528200006],
              [14.577362060000041, 11.495488168000179],
              [14.57964038900019, 11.49692726100011],
              [14.582742691000021, 11.498510362000104],
              [14.589195251000092, 11.49918937700005],
              [14.595454217000167, 11.499820709000062],
              [14.596617699000149, 11.500349046],
              [14.597925186000055, 11.502264976000106],
              [14.599669457000061, 11.504180907999967],
              [14.601800919000027, 11.506098748000056],
              [14.604758263000122, 11.508064270000148],
              [14.606941223000092, 11.50888061600017],
              [14.609608650000041, 11.509937287000128],
              [14.613100052000107, 11.511377335000077],
              [14.614504815000089, 11.512958526999967],
              [14.614571571000113, 11.51330852600006],
              [14.615035057000057, 11.515735627000083],
              [14.615068436000058, 11.515870095000025],
              [14.615566254000044, 11.517889023000066],
              [14.615902900000037, 11.520091056000126],
              [14.61634159099998, 11.522270203],
              [14.616673469000091, 11.523921013000177],
              [14.617360116000157, 11.526606559000186],
              [14.617590904000053, 11.527510644000074],
              [14.617767333000188, 11.5280427940001],
              [14.618556976999969, 11.530431748000012],
              [14.619161605999977, 11.533349037000107],
              [14.619280816000071, 11.533926009000027],
              [14.619441031000122, 11.534499169000014],
              [14.620004655000059, 11.536510468000074],
              [14.620954514, 11.538829804000159],
              [14.622181892000128, 11.541825294000034],
              [14.623363495000149, 11.544082642000092],
              [14.62426281, 11.545800208000117],
              [14.626214028000163, 11.550024033000113],
              [14.626585961000103, 11.550827981000168],
              [14.627408027000115, 11.552982330000191],
              [14.62784385700013, 11.555172920000075],
              [14.628179551000187, 11.556859971000108],
              [14.629293443000165, 11.55896663700014],
              [14.630697251000129, 11.56160068600002],
              [14.632344246000059, 11.563755989000128],
              [14.635010718000046, 11.565960885000095],
              [14.638338089000115, 11.568616867000117],
              [14.639035224000168, 11.569172859000162],
              [14.640246391000119, 11.570705415000134],
              [14.64131164600002, 11.572669030000156],
              [14.642617225000151, 11.576020242000141],
              [14.643438338000124, 11.579706192000117],
              [14.64391994600004, 11.58272171100009],
              [14.643721581000023, 11.585496903000035],
              [14.643377305000172, 11.589085579000141],
              [14.643264769999973, 11.589627267000026],
              [14.642987252000125, 11.590965272000176],
              [14.642791748000093, 11.591908456000056],
              [14.642642976000047, 11.594540597000105],
              [14.642753601000038, 11.596302032000096],
              [14.642832755000029, 11.597555161000173],
              [14.643233299000144, 11.598288535000108],
              [14.643801690000032, 11.599327089000042],
              [14.645060539000156, 11.601864815000056],
              [14.645907402000148, 11.603006364000066],
              [14.646768569000074, 11.604167938000103],
              [14.647192002000054, 11.604739190000089],
              [14.649276734000068, 11.607230187000084],
              [14.650196075000167, 11.609241486000144],
              [14.649902344000111, 11.611346245000107],
              [14.648830413000155, 11.614264489000163],
              [14.647127151000063, 11.61804294600006],
              [14.645619393000061, 11.620242119000011],
              [14.643521309000164, 11.621170045000042],
              [14.642704964000018, 11.621530534000158],
              [14.63925647700006, 11.623296736999976],
              [14.639050483000062, 11.623728752000147],
              [14.638526916000046, 11.62482738600005],
              [14.637938500000189, 11.626560212000072],
              [14.63779544900018, 11.626979827000071],
              [14.63730812100016, 11.628415107000023],
              [14.636916160000169, 11.630950928000118],
              [14.637139320000131, 11.63183403],
              [14.637448310000138, 11.633056642000099],
              [14.638125419000119, 11.634972572000152],
              [14.638186454999982, 11.635201455000185],
              [14.638270378000129, 11.635518075000107],
              [14.638381004000053, 11.637125014999981],
              [14.637938500000189, 11.63842964100013],
              [14.637780190000058, 11.638895988000058],
              [14.637081145000025, 11.640118600000164],
              [14.636222840000187, 11.641621591000103],
              [14.636515617000157, 11.642026902000111],
              [14.639712334000023, 11.646459580000055],
              [14.640486717000101, 11.648469924000153],
              [14.640677451000045, 11.650767327000096],
              [14.639752387999977, 11.653015137000068],
              [14.637516021000124, 11.655166625999982],
              [14.634306908000099, 11.658799171000112],
              [14.632850647000112, 11.659228326000175],
              [14.629837991000102, 11.660659790000125],
              [14.627360345000056, 11.662187577000168],
              [14.626048088000118, 11.663526535000074],
              [14.625511170000095, 11.665487289000112],
              [14.624049187000026, 11.669026375000044],
              [14.623026847000176, 11.671274185000016],
              [14.620887756000172, 11.673665047000156],
              [14.618845941000075, 11.675671579000095],
              [14.616610526000159, 11.676960944000086],
              [14.613988876000121, 11.677148818000035],
              [14.611463547000142, 11.677432061000104],
              [14.609617233000165, 11.678291321000131],
              [14.608158112000126, 11.680251121000083],
              [14.607813836000048, 11.683073998000168],
              [14.607566833000135, 11.685897828000066],
              [14.606837273000167, 11.687092781000047],
              [14.605669976000115, 11.68847942400015],
              [14.60387039200009, 11.690295219000177],
              [14.602847099000087, 11.693212509000148],
              [14.602190018000158, 11.695034981000106],
              [14.601984023000057, 11.695606233000149],
              [14.601725578000071, 11.696321486999977],
              [14.600896835000185, 11.698904992000166],
              [14.599097253000139, 11.700576782999974],
              [14.596716881000191, 11.701387406000038],
              [14.594094276000021, 11.702005387999975],
              [14.591035843000157, 11.701714516999971],
              [14.589191436000078, 11.701041222000129],
              [14.585309029000086, 11.699839591000114],
              [14.582978248000074, 11.699549675000128],
              [14.579773903000046, 11.699735641000018],
              [14.577538490000109, 11.700689315999966],
              [14.57340812700005, 11.703076363000037],
              [14.569859505000068, 11.705655099000069],
              [14.566115380000156, 11.708999633000019],
              [14.564220429000102, 11.709570885000062],
              [14.56218147300018, 11.70942401800005],
              [14.559608460000163, 11.709133149000081],
              [14.55679225800003, 11.708937645000049],
              [14.554462434000186, 11.710022926000079],
              [14.553924560999974, 11.710272790000147],
              [14.553395271000056, 11.710727692000091],
              [14.553147316999969, 11.710941314000024],
              [14.552991867000117, 11.711677551000093],
              [14.552803994000101, 11.71256828300011],
              [14.552702904000057, 11.715008737000062],
              [14.551632881000103, 11.716442109000127],
              [14.55150318200009, 11.71666813000013],
              [14.550123215000042, 11.719072342000061],
              [14.548564909999982, 11.721940994000136],
              [14.548480034000079, 11.722838402000093],
              [14.548465728000167, 11.722993851000126],
              [14.549193382000112, 11.723568915999977],
              [14.55084323900013, 11.724385262000055],
              [14.55292797200002, 11.725466729000061],
              [14.553802490000123, 11.72592067800008],
              [14.555840493000062, 11.727025033000018],
              [14.561032294000029, 11.729760171000066],
              [14.565544128000113, 11.732398987000067],
              [14.567436219000115, 11.7335510260001],
              [14.570349693000082, 11.734033584000031],
              [14.571852684000191, 11.735327722000022],
              [14.573161125000183, 11.737100602000169],
              [14.57398509899997, 11.73820209600018],
              [14.574274061999972, 11.73992538499999],
              [14.574318887000061, 11.742509843000164],
              [14.574362754000049, 11.745285034000176],
              [14.574990273000026, 11.747822762],
              [14.575571060000186, 11.749019622999981],
              [14.5767345430001, 11.750361444000077],
              [14.578676224000105, 11.751034738000044],
              [14.579791068000134, 11.750718118000123],
              [14.580522536999979, 11.750510216000123],
              [14.581758498, 11.750334740000028],
              [14.582600593000166, 11.750215530000105],
              [14.582854272000077, 11.750179290000119],
              [14.584067344000118, 11.750468254000054],
              [14.584697723000147, 11.751425744000073],
              [14.585520744000121, 11.753389358000163],
              [14.586215972000048, 11.754159927000046],
              [14.587120057000163, 11.755162239000015],
              [14.589402199000176, 11.75578785],
              [14.592460631999984, 11.756270408000148],
              [14.594208717000129, 11.756799698000066],
              [14.595059394000089, 11.757300377000035],
              [14.596246719000021, 11.75799846700005],
              [14.59881782500014, 11.759724617000074],
              [14.60061168600015, 11.762215614000183],
              [14.601482392000037, 11.764322281000148],
              [14.60303211199999, 11.767722130000152],
              [14.605553628000109, 11.770501137000167],
              [14.607542038000133, 11.772514344],
              [14.609297753000021, 11.774786949000031],
              [14.609354973000109, 11.774862289000055],
              [14.610353470000064, 11.776155472000028],
              [14.612243652000132, 11.779315948000033],
              [14.612629890000107, 11.780608178000023],
              [14.611997605000113, 11.781564713000023],
              [14.610830308000061, 11.782664300000135],
              [14.60927391000007, 11.784145355000021],
              [14.60872459400008, 11.784913063000147],
              [14.608008385000062, 11.785914422000133],
              [14.606351853000149, 11.789357186000018],
              [14.605180740000151, 11.793184280000162],
              [14.604938507000099, 11.794174194000163],
              [14.604595185000107, 11.795576095000058],
              [14.604924201000188, 11.797132493000049],
              [14.60522174800019, 11.798543931000154],
              [14.605548858000077, 11.799446106000119],
              [14.605899811000086, 11.800411224000186],
              [14.605944634000139, 11.802756309000131],
              [14.605455398000174, 11.805291175000093],
              [14.605621338000049, 11.806114196000067],
              [14.605840684000043, 11.807206155000074],
              [14.606662750000055, 11.809839248000117],
              [14.607096673000171, 11.812136651],
              [14.60750579900008, 11.813215255999978],
              [14.608404159000145, 11.815584183000169],
              [14.608670234000044, 11.816739082000083],
              [14.608934402000045, 11.817882538000163],
              [14.608783722, 11.819260597000039],
              [14.608773231000157, 11.81935882700003],
              [14.608761788000152, 11.819460869000125],
              [14.608688355000027, 11.820131303000153],
              [14.608629227000108, 11.820940018000044],
              [14.608540535000088, 11.822140695000144],
              [14.608374594999987, 11.823055268000076],
              [14.608245849000184, 11.823767663000126],
              [14.608005523000031, 11.824372292000135],
              [14.60746669800011, 11.825728418000097],
              [14.605957032000106, 11.827879906000135],
              [14.604981423000083, 11.830749513000114],
              [14.604799270000058, 11.832482338999966],
              [14.604735375000189, 11.833093643000097],
              [14.605072021000126, 11.835726739],
              [14.60535907700006, 11.838741303000063],
              [14.606424331000142, 11.841135980000047],
              [14.607928277000042, 11.842382431000033],
              [14.61016082899999, 11.843868255000075],
              [14.612392425999985, 11.846360206999975],
              [14.613603592, 11.848276139000063],
              [14.61377811400007, 11.84880447400019],
              [14.614281654000024, 11.850334168000074],
              [14.614035606000073, 11.852105141000152],
              [14.61356639800016, 11.852987290000044],
              [14.61345100300008, 11.853204728000094],
              [14.611998558000096, 11.854224206000083],
              [14.611409187000106, 11.854637145000027],
              [14.609220505, 11.855831146000185],
              [14.607177734, 11.857550621000087],
              [14.60677433, 11.858185769000045],
              [14.605960847000119, 11.859463691000087],
              [14.604709626000101, 11.862191201000144],
              [14.604644775000111, 11.862332343000105],
              [14.603865624000036, 11.863479614000084],
              [14.602794646000063, 11.864961624000159],
              [14.603374481, 11.867355346000124],
              [14.60407447900019, 11.868458748000137],
              [14.604925156000149, 11.869798660000129],
              [14.607254028000114, 11.872002601000133],
              [14.608222961000081, 11.873918534000154],
              [14.609046936000141, 11.875546455000119],
              [14.610357285000077, 11.876792908000141],
              [14.611863136000068, 11.876794816000086],
              [14.612980843000116, 11.87670040099999],
              [14.613854409000112, 11.877371788000062],
              [14.615086555000062, 11.878361702000063],
              [14.616718291000097, 11.879672051000057],
              [14.61778545400017, 11.880679130000146],
              [14.617827416000125, 11.880818367000018],
              [14.618317603999969, 11.882450103000053],
              [14.619432449000101, 11.884509087000026],
              [14.620514869000147, 11.885369300000093],
              [14.621421814000144, 11.886091232000126],
              [14.623070716000143, 11.888390542000138],
              [14.624303817000111, 11.890781404000052],
              [14.624379158000067, 11.890928268000152],
              [14.624505043000056, 11.891211509000016],
              [14.625445365000076, 11.893322945000136],
              [14.626171112000179, 11.895716667000102],
              [14.626492499000051, 11.896972656],
              [14.626992225000151, 11.898923875000094],
              [14.62755489400007, 11.900034904999984],
              [14.628204347000064, 11.901317597000059],
              [14.629644394000138, 11.902622223000037],
              [14.629951476000144, 11.902899742000159],
              [14.632476807000103, 11.903668405000076],
              [14.634758949000116, 11.904724122000118],
              [14.637235642000121, 11.906450273000019],
              [14.63932323500012, 11.907840728000053],
              [14.641472816000032, 11.909659386000101],
              [14.642041205000112, 11.910140991000048],
              [14.641388892, 11.911917687000141],
              [14.640724181, 11.913728714000172],
              [14.640045167000153, 11.915540696000164],
              [14.639505386000167, 11.916980744000114],
              [14.638287544000093, 11.919467926000038],
              [14.637022018000039, 11.92090129800016],
              [14.634879112000021, 11.924392701000102],
              [14.633465766000143, 11.927357673000074],
              [14.633316994000097, 11.929606438000064],
              [14.633703232000073, 11.931569100000104],
              [14.634867669000016, 11.932766914000126],
              [14.635948182000163, 11.933699607000108],
              [14.637197495000066, 11.934779168000148],
              [14.637635231000047, 11.935197830000163],
              [14.638653754000018, 11.93616962499999],
              [14.639594078000016, 11.936952592000068],
              [14.640838623999969, 11.937990188000072],
              [14.643033027000115, 11.93976688399999],
              [14.643265725000163, 11.939955711000096],
              [14.644088746000136, 11.942397117000155],
              [14.644434929999989, 11.942797661000043],
              [14.645495415000084, 11.944025994000185],
              [14.646383286000116, 11.945234298000116],
              [14.646903038000119, 11.945941926000046],
              [14.647296906000179, 11.946840286000167],
              [14.648016930000097, 11.94847965200006],
              [14.649375916000054, 11.949916840000128],
              [14.651221275000069, 11.951307296999971],
              [14.651728629000161, 11.951653481000051],
              [14.652483940000025, 11.952169417999983],
              [14.653356553000037, 11.953893661000109],
              [14.653419494000161, 11.954479218000188],
              [14.653692246000162, 11.957004547000111],
              [14.65371704000006, 11.958136560000071],
              [14.653738976000113, 11.95915794300015],
              [14.653056144000118, 11.960975648000158],
              [14.651838302000044, 11.963079452000159],
              [14.649975777000066, 11.964274407000175],
              [14.647850990000052, 11.964319230000058],
              [14.64697170300019, 11.965014457000109],
              [14.645855904000143, 11.965895653000189],
              [14.64497375500008, 11.966368676000172],
              [14.64371585900011, 11.967041970000082],
              [14.64230442000013, 11.968857765000109],
              [14.641571998000074, 11.971585275000052],
              [14.640548705000072, 11.97349739200007],
              [14.64040088600018, 11.975076676000185],
              [14.64005756500012, 11.977420806000168],
              [14.639327048000155, 11.97852039300011],
              [14.6381101610001, 11.979188918999967],
              [14.636893272, 11.980726243000163],
              [14.636747360000015, 11.980910302000041],
              [14.636740684000188, 11.98096943000013],
              [14.636599540000077, 11.982153892999975],
              [14.636536597000088, 11.983331681000095],
              [14.636500358000035, 11.984020233000024],
              [14.636157990000129, 11.985550880000119],
              [14.635865211000123, 11.98660373700011],
              [14.634307861000082, 11.987941742000032],
              [14.633450508000124, 11.989064217000134],
              [14.633138656000085, 11.989471436000144],
              [14.632994651000161, 11.990149498000108],
              [14.632894516000135, 11.990619660000107],
              [14.632857322000064, 11.99105358200012],
              [14.632745742000054, 11.992341995000061],
              [14.632501603000037, 11.993442537000021],
              [14.632181168000045, 11.993966104000094],
              [14.631771087000175, 11.99463749],
              [14.631185532000131, 11.996407508000118],
              [14.631376266000075, 11.999135971000157],
              [14.631481170000029, 12.000677108000048],
              [14.631816864000143, 12.001938820000021],
              [14.631814956000142, 12.003334045000088],
              [14.632015228, 12.004662515],
              [14.632147789000044, 12.006522180000161],
              [14.631875039000022, 12.008248330000129],
              [14.631266593000134, 12.00970840399998],
              [14.630724907000115, 12.010704041000054],
              [14.629914284000108, 12.011898994000035],
              [14.629642487000069, 12.013359070000092],
              [14.628897667000103, 12.014885902000117],
              [14.628560066000148, 12.015549660000147],
              [14.628288268, 12.01667881100002],
              [14.62700271600005, 12.019798279000042],
              [14.626190185000041, 12.021457672000111],
              [14.62551403100008, 12.022850990000109],
              [14.623420715000066, 12.024375916000167],
              [14.62085533200019, 12.025568009000096],
              [14.620718003000093, 12.027360916000021],
              [14.620618821000051, 12.02797317500017],
              [14.620309829000064, 12.029884339000034],
              [14.620241166000142, 12.030680657000175],
              [14.619966505999969, 12.033867836000184],
              [14.619559289000108, 12.035660745000143],
              [14.619443894000085, 12.036729813000136],
              [14.619422913000051, 12.036922454000148],
              [14.619688035000138, 12.040309906000118],
              [14.620292664000146, 12.042303086],
              [14.621099472000139, 12.043964387000074],
              [14.622378349000144, 12.04642391200008],
              [14.624092102000077, 12.048545837000063],
              [14.624277115000041, 12.049339294000106],
              [14.625153541000088, 12.05013561300018],
              [14.6258249280001, 12.050745010000071],
              [14.626752854000188, 12.051661492000051],
              [14.62763214000006, 12.052607537000029],
              [14.628237724000087, 12.053736687000026],
              [14.628977776000056, 12.055397988000095],
              [14.62992572800016, 12.056412697000098],
              [14.63027954099999, 12.05679130499999],
              [14.631642341000145, 12.058074950999981],
              [14.63288688599999, 12.058193206000112],
              [14.633777618000181, 12.058588982000174],
              [14.634235382000043, 12.058792114000084],
              [14.635584830000141, 12.058727264000026],
              [14.637136460000079, 12.059127807000039],
              [14.638634681000156, 12.059387207000043],
              [14.638687133000019, 12.05939579000011],
              [14.640036584000029, 12.059796334000055],
              [14.641116142000044, 12.060063361],
              [14.643138886000031, 12.060597421000182],
              [14.64396858300006, 12.060523988000057],
              [14.644623756000158, 12.060465813000121],
              [14.646047593000105, 12.060575485000129],
              [14.646377563000044, 12.060601234000046],
              [14.647929191, 12.060869217000061],
              [14.648715973000094, 12.060985565000124],
              [14.649278642000013, 12.061069488000044],
              [14.650249482000049, 12.061071396000045],
              [14.65116787, 12.061072351],
              [14.651762963000124, 12.06134414600001],
              [14.652044296000042, 12.061471940000104],
              [14.653055191000135, 12.062335968000014],
              [14.653457642000092, 12.063533784000185],
              [14.654013634000137, 12.064998626999966],
              [14.654012681000097, 12.066157342],
              [14.653328896000119, 12.067010879],
              [14.652336120000029, 12.067925454000147],
              [14.651256562000071, 12.068156243000146],
              [14.64960575200007, 12.068508149000081],
              [14.648841859000015, 12.068820954000159],
              [14.64798545900004, 12.069170953000082],
              [14.644948006000163, 12.070229530000177],
              [14.641503334999982, 12.073146819000044],
              [14.639003753000111, 12.07533550200003],
              [14.638729095000144, 12.075941085000125],
              [14.63819122200016, 12.077127456000142],
              [14.637918472000138, 12.079252242999985],
              [14.637365341000077, 12.080398559000173],
              [14.637310028000059, 12.080513002000146],
              [14.636430740000151, 12.081707955000184],
              [14.634537696000166, 12.08449459100018],
              [14.633980750000092, 12.085383414999967],
              [14.633456230000036, 12.086219787000061],
              [14.633588791000079, 12.087814331],
              [14.633855819000132, 12.089740754000161],
              [14.633931160000088, 12.090909958999987],
              [14.63398838, 12.091799735999984],
              [14.634405135000122, 12.092283249000104],
              [14.635133744000086, 12.093129158000067],
              [14.635564804000069, 12.093611717999977],
              [14.63614368399999, 12.094259263000026],
              [14.636726378999981, 12.094850541000085],
              [14.637233735000052, 12.095364570000072],
              [14.638409615000171, 12.096769334000157],
              [14.639958382000145, 12.09786987300015],
              [14.640624999000181, 12.098384857000156],
              [14.641383172000076, 12.098970414000178],
              [14.641578674000129, 12.099145890000159],
              [14.642745971000124, 12.100192069000116],
              [14.643526077000047, 12.100927353000031],
              [14.644107819, 12.101475717000085],
              [14.64534568800002, 12.103002548],
              [14.64619636499998, 12.103973389000146],
              [14.646522523000158, 12.104346276000172],
              [14.647229196000069, 12.10555458000016],
              [14.647450446000107, 12.105934143000013],
              [14.64747238200016, 12.106007576000138],
              [14.647759438000094, 12.106971742000155],
              [14.64842891700016, 12.107556344000045],
              [14.649369239000123, 12.108377457000017],
              [14.650139809000109, 12.109473229000116],
              [14.650429726000027, 12.10988521600018],
              [14.651169778000167, 12.111213685000052],
              [14.651640892000103, 12.112211227000159],
              [14.651841164000132, 12.113672255999973],
              [14.653085708000049, 12.115250587000105],
              [14.653256415999977, 12.115467072000172],
              [14.653996468000116, 12.117460251000125],
              [14.654141427000127, 12.118473053000116],
              [14.654196739999975, 12.118855476000022],
              [14.654151917000092, 12.119206428000098],
              [14.653992653000046, 12.120449066000106],
              [14.654057503000104, 12.122308731000032],
              [14.653448105000109, 12.124339103000182],
              [14.653397560000144, 12.124505043000056],
              [14.653379441000141, 12.12456607900009],
              [14.653395652000142, 12.125149726000188],
              [14.653422356000021, 12.126113891000102],
              [14.653444290000095, 12.126890183000057],
              [14.653577806000101, 12.128218650000065],
              [14.653034210000101, 12.129208566000102],
              [14.652629852000189, 12.129944801000022],
              [14.65120983200012, 12.132134438000094],
              [14.649790763000055, 12.133925437000187],
              [14.649739266000097, 12.134207726],
              [14.64945030199999, 12.13578510200017],
              [14.650527953000108, 12.137645722000059],
              [14.651471137000158, 12.138975144000142],
              [14.653698921000114, 12.138314248000142],
              [14.655718804000173, 12.137979507000068],
              [14.656171800000152, 12.138649941000153],
              [14.656584739000152, 12.139262200000076],
              [14.65807056400007, 12.141277313999979],
              [14.659434318000081, 12.142255783000053],
              [14.661231995000037, 12.142807006000112],
              [14.662284851000152, 12.143967628000041],
              [14.662649156000157, 12.144866943000068],
              [14.663025856000047, 12.145798684000169],
              [14.663288116000103, 12.146648408000146],
              [14.663458824000031, 12.147202492000133],
              [14.664875985000094, 12.147614479000026],
              [14.665566445000024, 12.147815705000028],
              [14.665950775000056, 12.148014070000158],
              [14.666868211000121, 12.148488045000022],
              [14.667098045000103, 12.149445534000108],
              [14.66717624600011, 12.149769782000135],
              [14.667360306000035, 12.151478767000015],
              [14.667400361000091, 12.151525497000023],
              [14.668041228999982, 12.152273179000019],
              [14.669168472000081, 12.15354919399999],
              [14.669338227000026, 12.153740883000182],
              [14.669528960999969, 12.153851509000049],
              [14.670822144000169, 12.154605866000054],
              [14.672306061000086, 12.155338288000053],
              [14.672635079000145, 12.155563354000037],
              [14.673182487000133, 12.155937196000082],
              [14.673463822000031, 12.15644931800017],
              [14.673973084000068, 12.157373429000131],
              [14.674007416000109, 12.157437324],
              [14.674340249000181, 12.158041955000044],
              [14.674462319000156, 12.158263207000061],
              [14.676218987000027, 12.159849167000175],
              [14.676299095000047, 12.160502435000069],
              [14.67641544300011, 12.161453247000054],
              [14.676143646000071, 12.162914276000038],
              [14.676076890000047, 12.163416862000076],
              [14.675939561000177, 12.164441110000098],
              [14.675956726999971, 12.164908409000077],
              [14.676004409000029, 12.166168213000049],
              [14.675124168000139, 12.16862392500002],
              [14.674359322000043, 12.169919969000148],
              [14.673636436000095, 12.171146394000118],
              [14.673393249000185, 12.172242165000114],
              [14.67329692800007, 12.17267322500004],
              [14.673010826000052, 12.173209191000183],
              [14.67255306200019, 12.174066543000038],
              [14.670999527000163, 12.174994469000069],
              [14.670354842000052, 12.175217629000088],
              [14.66985130300003, 12.175392151000153],
              [14.668600082000125, 12.176276207000114],
              [14.668162347000077, 12.176585199000101],
              [14.666069984000046, 12.17664909400014],
              [14.664724350000085, 12.177144050000038],
              [14.664448738000033, 12.177245140000082],
              [14.662625312000159, 12.178238869000154],
              [14.662285804000135, 12.180031778000057],
              [14.662755013000151, 12.182688712000186],
              [14.663380623000023, 12.184286117000056],
              [14.663561821000087, 12.184748648999971],
              [14.663921356000117, 12.185269356000163],
              [14.66457271600018, 12.186210632000041],
              [14.664889336000044, 12.186742782000067],
              [14.664947509000172, 12.186840056999984],
              [14.665246010000089, 12.187340738000159],
              [14.66521930600004, 12.187923432000048],
              [14.665176392000035, 12.188868522000064],
              [14.664702415000136, 12.190129281],
              [14.663823128000161, 12.191788674000122],
              [14.662904739999988, 12.193027497],
              [14.661725045000026, 12.193819046000044],
              [14.66104126, 12.19485569],
              [14.660730361000105, 12.196075440000072],
              [14.659425735000184, 12.19711113000011],
              [14.657813072000067, 12.197353364000094],
              [14.656138421000151, 12.197351457000025],
              [14.653905868999971, 12.197104454999987],
              [14.652231214999972, 12.19637012600009],
              [14.651178361000063, 12.195270539000148],
              [14.650252342000101, 12.193631172000153],
              [14.648972512000114, 12.191703797000116],
              [14.647556306000183, 12.190506936000077],
              [14.645394325000041, 12.191965104000133],
              [14.643907548000072, 12.193424225000172],
              [14.642961501000059, 12.194352151000032],
              [14.641880990000061, 12.194085121000114],
              [14.641545295000071, 12.19308853199999],
              [14.641480446000173, 12.191163064000023],
              [14.641144753000162, 12.18956852000008],
              [14.639458657000091, 12.188570023000125],
              [14.636960982000119, 12.188964844000054],
              [14.635067941000102, 12.19088840400002],
              [14.634054184000149, 12.191884041000094],
              [14.633041381000112, 12.192214013000012],
              [14.632028579000121, 12.192213059000153],
              [14.631017686000064, 12.19108295500007],
              [14.629871368000067, 12.189752578000025],
              [14.628860473000032, 12.188954353000156],
              [14.628052712000056, 12.187425614000063],
              [14.629046441000128, 12.186393738000106],
              [14.629855157000065, 12.184930802000054],
              [14.631096840000055, 12.182648658000062],
              [14.631302834000053, 12.180257798000127],
              [14.629009247000056, 12.179191589000084],
              [14.626376151000159, 12.179121970000097],
              [14.623337746000061, 12.180247308000105],
              [14.623072624000145, 12.180406570000173],
              [14.621243476000132, 12.181506157000115],
              [14.61941909799998, 12.182555199000035],
              [14.61928367600018, 12.18263244700006],
              [14.617731094000135, 12.182962418000045],
              [14.616656303000127, 12.18281936600016],
              [14.615706444000125, 12.182693482000047],
              [14.615018844000076, 12.182777404000035],
              [14.613546372000087, 12.18295669500003],
              [14.612730027000111, 12.184494972000039],
              [14.612666131000026, 12.184615135000115],
              [14.612982751000061, 12.186066628000049],
              [14.613556863000156, 12.186221123000109],
              [14.614755631000037, 12.186544418000153],
              [14.614941598000087, 12.186854363000123],
              [14.615631103, 12.188006401000052],
              [14.61522483900012, 12.189002038000126],
              [14.613335610000036, 12.188601494000181],
              [14.610433579000187, 12.188065529000141],
              [14.608474732000047, 12.188793183000143],
              [14.606853486000034, 12.189588548000131],
              [14.605030061000036, 12.190249444000131],
              [14.603746413000067, 12.190978051000059],
              [14.602464676000125, 12.191163064000023],
              [14.601922988000069, 12.191241265000031],
              [14.601140021000049, 12.191029548000188],
              [14.599697113000047, 12.190640450000103],
              [14.597469329000091, 12.190304757000149],
              [14.597018243000093, 12.192999840000141],
              [14.596924781000155, 12.193558693999989],
              [14.596962928000039, 12.193998337000039],
              [14.597057342000028, 12.195086478000121],
              [14.598136903000068, 12.195353507999982],
              [14.598975182000061, 12.195560455000134],
              [14.599486351, 12.195687294000095],
              [14.599945068000011, 12.195705415000077],
              [14.601242066000054, 12.195756912000149],
              [14.60359096600007, 12.195952416000182],
              [14.603671075000022, 12.19595909200018],
              [14.604482650000136, 12.195750237000027],
              [14.606237412000041, 12.195299148000117],
              [14.607315063000101, 12.19702720700019],
              [14.606772423000166, 12.198753357000044],
              [14.606614113000035, 12.199398995000138],
              [14.606365204000099, 12.200412750000112],
              [14.605755805000058, 12.201872826000113],
              [14.605110169000113, 12.202603340000167],
              [14.604876519000129, 12.202867508000168],
              [14.603994370000066, 12.205921173000149],
              [14.602573396000082, 12.207978250000053],
              [14.601490020000142, 12.209969521000062],
              [14.600610734000043, 12.211296083000036],
              [14.59966564199999, 12.211493492000159],
              [14.59874248599999, 12.211631776000047],
              [14.59790992700016, 12.211756707000177],
              [14.597393036000085, 12.211807251999971],
              [14.596559524000043, 12.211888313000031],
              [14.594367981000175, 12.212281227000062],
              [14.593993186000148, 12.212348937000115],
              [14.592437743000175, 12.214073181000174],
              [14.590477944000099, 12.215863229000092],
              [14.590812682000092, 12.2177896500001],
              [14.591981887000088, 12.21873664899999],
              [14.592215538000175, 12.218925476000095],
              [14.593951224000079, 12.219904899000028],
              [14.593971253, 12.221186639000052],
              [14.593978883000148, 12.221646310000153],
              [14.593437195000092, 12.223040581000134],
              [14.592963218000023, 12.224036217000105],
              [14.592082977000132, 12.22569465700019],
              [14.59113598, 12.226490975000161],
              [14.589381218000142, 12.226687432000176],
              [14.586276055000155, 12.226416589000053],
              [14.583642959000088, 12.226479532000042],
              [14.581549645000052, 12.226409913000055],
              [14.58013248400016, 12.226075173000027],
              [14.578715325000019, 12.225741387000141],
              [14.576758384000016, 12.2251405720001],
              [14.574531553999975, 12.224340439000059],
              [14.571899413999972, 12.224137307000092],
              [14.569710731000157, 12.226402283000084],
              [14.569464684000138, 12.226656915000035],
              [14.569029809000142, 12.227435112000023],
              [14.568313598000145, 12.228713989000084],
              [14.567029, 12.229907035],
              [14.564462661999983, 12.230367661000116],
              [14.560611725000115, 12.231955528000128],
              [14.557976723000081, 12.233146667000028],
              [14.555950166000173, 12.233874320999973],
              [14.553048135000154, 12.233736039000121],
              [14.550751685000023, 12.234330177000061],
              [14.550556183000026, 12.234422684000151],
              [14.548656464000089, 12.23532295300015],
              [14.547845841000083, 12.236465454999973],
              [14.547574043000168, 12.236848829999985],
              [14.547435759999985, 12.238774300000159],
              [14.547856331999981, 12.240547181000011],
              [14.547971725000025, 12.241033554000012],
              [14.548317909000104, 12.241330148000031],
              [14.549522400000171, 12.242363931000057],
              [14.551854134, 12.242964744000119],
              [14.552491189000079, 12.243681908000156],
              [14.553568840000139, 12.244894982000119],
              [14.554278373000159, 12.246294975000069],
              [14.55437660199999, 12.246489525000186],
              [14.554779053000175, 12.248217583000155],
              [14.55464077, 12.250208854000164],
              [14.555515288000095, 12.252136231],
              [14.557065011000134, 12.253998756000044],
              [14.55814170900004, 12.255926132000184],
              [14.559017182000105, 12.257255554000039],
              [14.559769630000176, 12.258232117000091],
              [14.559705734000033, 12.259269716000063],
              [14.558773994000035, 12.260122298000056],
              [14.557409287000041, 12.25987625200014],
              [14.55697727200004, 12.258471488999987],
              [14.555924415000106, 12.257310867000058],
              [14.554226875000154, 12.255521775000034],
              [14.552674293000109, 12.255054473000087],
              [14.551187515000038, 12.255152703000022],
              [14.550716401000102, 12.25518321900006],
              [14.548148155000092, 12.256441116000133],
              [14.546997071000021, 12.258099556000047],
              [14.546655655000052, 12.260356904000105],
              [14.546180726000102, 12.261751175000143],
              [14.544489861000045, 12.263341904000185],
              [14.543596267000055, 12.265034676000084],
              [14.54347419700008, 12.26526641900017],
              [14.543306351000012, 12.265618324000059],
              [14.542525292000164, 12.267256737000025],
              [14.54215526500019, 12.26874828300015],
              [14.54198169700004, 12.269447328000183],
              [14.541842460000112, 12.270140648000165],
              [14.54143619600012, 12.272170068000037],
              [14.541891097000189, 12.273158074000037],
              [14.542446135999967, 12.27436256500016],
              [14.54336452400014, 12.275052070000072],
              [14.544131280000158, 12.275628090000168],
              [14.545013428000118, 12.27654171000006],
              [14.545479775000103, 12.277024270000027],
              [14.545815468000058, 12.27828693400005],
              [14.545340539000108, 12.279547691000175],
              [14.545232774000169, 12.279800415000125],
              [14.54500103, 12.280344011000182],
              [14.544253350000133, 12.280790329000126],
              [14.543446540000161, 12.281271934000131],
              [14.542652129999965, 12.281412126000191],
              [14.541960716000176, 12.28153419400013],
              [14.541773797000076, 12.281599045000121],
              [14.539663315000041, 12.282327651000173],
              [14.538310052000099, 12.283720017000064],
              [14.537127494000117, 12.284879686000011],
              [14.536213874, 12.285775185000148],
              [14.534928321000109, 12.286902428000076],
              [14.533779145000096, 12.287830354000107],
              [14.532629966000115, 12.288160325000149],
              [14.531010627000171, 12.287825584000075],
              [14.529093743000033, 12.287756921000152],
              [14.529051781000078, 12.287755013000151],
              [14.52761840800008, 12.288476944000138],
              [14.526686668000082, 12.288947106000137],
              [14.52514743800009, 12.289851189000046],
              [14.524996758000043, 12.28993988000019],
              [14.523777961000178, 12.291863441000089],
              [14.523619651000047, 12.292119027000126],
              [14.522927284000048, 12.293237687000101],
              [14.522422790000064, 12.294053078000161],
              [14.52120590300018, 12.295113563000086],
              [14.520394324999984, 12.295908928000074],
              [14.519448281000052, 12.295973778000132],
              [14.517760277000036, 12.295904160000021],
              [14.516546250000033, 12.295039177000149],
              [14.51472282400016, 12.295035362000078],
              [14.512629509000021, 12.29516506300007],
              [14.511007310000025, 12.296224595000183],
              [14.510464667000178, 12.297485352000137],
              [14.51127147700015, 12.299412727000174],
              [14.513023377000138, 12.301474571000028],
              [14.513898849999975, 12.302803993000111],
              [14.514368057000183, 12.304597855],
              [14.514298439000129, 12.304771422999977],
              [14.513757706000035, 12.306124687000022],
              [14.513079642000093, 12.307584762000147],
              [14.512167930000146, 12.308790206000083],
              [14.511725426000112, 12.30937480900019],
              [14.510321618000148, 12.310750008000014],
              [14.510101318000011, 12.310966491000102],
              [14.509355545000062, 12.312624931000187],
              [14.509573936000095, 12.314104080000106],
              [14.509689331000118, 12.314883232000057],
              [14.510649681000189, 12.31555175800014],
              [14.511022568000044, 12.315811158000088],
              [14.51189613400004, 12.316185951000136],
              [14.512448310000082, 12.316423416000134],
              [14.51406192800016, 12.316365241000028],
              [14.515260697000144, 12.315862654999989],
              [14.515800477000028, 12.315636635000146],
              [14.517045021000172, 12.314173699000094],
              [14.517801286000122, 12.31383800499998],
              [14.519094467000116, 12.313262941],
              [14.520698547000109, 12.31404018500001],
              [14.520965577000027, 12.315633774000162],
              [14.521100998000122, 12.316473961000156],
              [14.521233559000166, 12.317295075000061],
              [14.520825385000137, 12.318887712000105],
              [14.520695687000057, 12.32038784000008],
              [14.52068710400016, 12.320481301000086],
              [14.520817757000032, 12.323005677000026],
              [14.520476341000062, 12.324731826000118],
              [14.519596100000115, 12.326058388000092],
              [14.518136979000076, 12.327660561000073],
              [14.516336442000068, 12.328268051000066],
              [14.513791085000037, 12.328507423000133],
              [14.511308669000186, 12.328563690000124],
              [14.508578301000057, 12.328558923000173],
              [14.506157875000156, 12.32861614300009],
              [14.503489495000167, 12.328793526000084],
              [14.501193047000072, 12.328972817000135],
              [14.499936104000085, 12.329808236000019],
              [14.498718262000182, 12.330868721000115],
              [14.497703552000075, 12.331995964000043],
              [14.496620178000171, 12.333520890000102],
              [14.495671272000152, 12.335312844000043],
              [14.494249345000185, 12.336771012000099],
              [14.492154122000102, 12.337829590000069],
              [14.489519118000032, 12.338024139000083],
              [14.48780346, 12.338489533000086],
              [14.487087249000183, 12.338683129000117],
              [14.48532772100009, 12.340074540000046],
              [14.484539985000083, 12.34072494500009],
              [14.484043121000184, 12.341135025000085],
              [14.484108925000157, 12.341865538999969],
              [14.484376907000126, 12.342994689000136],
              [14.485456468000166, 12.343461991000083],
              [14.485537529000055, 12.343523979000054],
              [14.486670495000169, 12.344393729999979],
              [14.487447739000174, 12.345082284000114],
              [14.487694740000109, 12.345300674999976],
              [14.488037109000061, 12.346237183000028],
              [14.488392830000123, 12.347208024000167],
              [14.488554955000097, 12.34765148200006],
              [14.488348961000099, 12.349710464000054],
              [14.487196922000123, 12.351500510000051],
              [14.484899520999988, 12.352160454000114],
              [14.482778550000091, 12.351758957000186],
              [14.482606887000031, 12.350296974000116],
              [14.481800079000038, 12.348369598000147],
              [14.481329917000039, 12.346641540000178],
              [14.480316162000122, 12.345211983000127],
              [14.479915618000177, 12.344646454000099],
              [14.478642464000188, 12.344566345000146],
              [14.47775459200011, 12.344510078000042],
              [14.475997925000115, 12.344904899000085],
              [14.475777627000014, 12.345055581000111],
              [14.474473952999972, 12.345946312000024],
              [14.472856522000029, 12.34765148200006],
              [14.472373962000063, 12.348785400000111],
              [14.471921921000046, 12.349846840000168],
              [14.47167396500015, 12.350690842],
              [14.471421242000076, 12.351553918000036],
              [14.471319198000174, 12.351845741000091],
              [14.470485686000131, 12.354236603],
              [14.469178200000101, 12.356125832000089],
              [14.467746735000105, 12.358136176000187],
              [14.467488288000084, 12.358345031000169],
              [14.467454909000082, 12.358372689000134],
              [14.466317176000189, 12.359292985000081],
              [14.465012550000097, 12.359473229000059],
              [14.4634618770001, 12.3593482980001],
              [14.462036133000083, 12.358247757000072],
              [14.459431648000134, 12.357448579000049],
              [14.45744609799999, 12.357078552000075],
              [14.455771447000075, 12.356343269999968],
              [14.454363823000165, 12.354956627000092],
              [14.452813148000189, 12.353559494000024],
              [14.45166969200011, 12.351631164000025],
              [14.451334954000117, 12.349969864000059],
              [14.449785233000057, 12.348107339000023],
              [14.449323655000057, 12.347786904000088],
              [14.447964668000111, 12.346841812000036],
              [14.447676658000091, 12.34700679700012],
              [14.446003914000073, 12.34796714700019],
              [14.444739342000048, 12.349426270000038],
              [14.444039344999965, 12.350486755000077],
              [14.443631173000142, 12.351814270000091],
              [14.443684578000045, 12.352956770999981],
              [14.443696021000164, 12.353208543000051],
              [14.443734170000027, 12.353937149000103],
              [14.443825722000156, 12.355666160000112],
              [14.44402408600007, 12.357758523000086],
              [14.444038392000152, 12.357906343000082],
              [14.444067184000062, 12.358980789000157],
              [14.444095612000126, 12.360041618000082],
              [14.443594933000156, 12.362237931000038],
              [14.44325572200006, 12.362639514000136],
              [14.44241200100015, 12.363638001000027],
              [14.440110999000183, 12.365829999000027],
              [14.438880355000151, 12.366553037000187],
              [14.437625884, 12.367289543000027],
              [14.436639882000122, 12.367741165999973],
              [14.435637, 12.368200000000115],
              [14.433463000000188, 12.369172],
              [14.433031710000023, 12.369209691000094],
              [14.431963921000033, 12.369302749000155],
              [14.431264878000036, 12.369160653000108],
              [14.430531938000115, 12.368918240000028],
              [14.430038999000146, 12.368754999000089],
              [14.429614029000106, 12.368595116000108],
              [14.428608894000035, 12.368216516000075],
              [14.428060002000052, 12.36770327000005],
              [14.427317, 12.36700799900018],
              [14.426090541000121, 12.365478724000184],
              [14.42602539000012, 12.36539745400006],
              [14.425076486000137, 12.363452912000071],
              [14.422900199000082, 12.361438751000151],
              [14.419970512000077, 12.361030579000101],
              [14.416903384000136, 12.361291931000096],
              [14.414447785000107, 12.362291336000055],
              [14.410286903000099, 12.364225389000126],
              [14.406566868000027, 12.366010703000086],
              [14.406535149000149, 12.366025926000191],
              [14.403195381000046, 12.366219520000016],
              [14.399924277000139, 12.366211892000081],
              [14.396791458000166, 12.365468980000117],
              [14.393181801000026, 12.364790916000118],
              [14.392912206000176, 12.364727922000043],
              [14.389709, 12.363979],
              [14.387803, 12.362701001000119],
              [14.386105001000146, 12.360755000000154],
              [14.383707, 12.357994001000066],
              [14.382742141000051, 12.357693351000023],
              [14.381344794000029, 12.35725784300007],
              [14.378778396000143, 12.356987017000051],
              [14.378027099000064, 12.357201157000191],
              [14.376008034000051, 12.35777664200009],
              [14.375096718000123, 12.35928055200003],
              [14.373837097000091, 12.361358841000083],
              [14.37266961500012, 12.361979293000104],
              [14.371381, 12.362663999000119],
              [14.367017000999965, 12.36386],
              [14.363949, 12.364655999000092],
              [14.360813000000121, 12.364783001000092],
              [14.358297864, 12.364271745000053],
              [14.357816854000191, 12.364173921000145],
              [14.357278618000066, 12.363883714000053],
              [14.35557079400013, 12.36296272200002],
              [14.35347326800013, 12.362957014000074],
              [14.353118001000155, 12.362956],
              [14.35100600100003, 12.362951000000123],
              [14.349299, 12.363818001000027],
              [14.346773999000163, 12.365487],
              [14.343498001000171, 12.367622],
              [14.342048893000026, 12.367975419000118],
              [14.341861724000069, 12.368021012000042],
              [14.341077644000052, 12.36857202300007],
              [14.339677000000108, 12.369556],
              [14.337608891000173, 12.370691571000123],
              [14.334695816000078, 12.372290611999972],
              [14.332965744000148, 12.372966194000128],
              [14.331626000000142, 12.373488999000131],
              [14.328887000000179, 12.374664999000061],
              [14.325782000000117, 12.374125999000057],
              [14.324062082000069, 12.373115314000188],
              [14.322837830000026, 12.372395517000086],
              [14.32066249799999, 12.370447158000047],
              [14.319373130000088, 12.368501664],
              [14.319182094000098, 12.367390670000134],
              [14.318969738000078, 12.366155689000152],
              [14.316930771000045, 12.364140510000141],
              [14.314239795000162, 12.361482019000164],
              [14.314007808000042, 12.361252832000105],
              [14.311424249000083, 12.359103202000085],
              [14.310834527000168, 12.358968927999967],
              [14.309041023000077, 12.358560563000026],
              [14.307341307000115, 12.358769548],
              [14.305838000000165, 12.358953999000107],
              [14.30337, 12.359144749000052],
              [14.303315147000092, 12.359148985000047],
              [14.300338746000079, 12.360196113000029],
              [14.300104142000066, 12.360278129000051],
              [14.299812316000043, 12.360381126000107],
              [14.299317360000146, 12.361165048000146],
              [14.298388482, 12.362635612000133],
              [14.298952103000033, 12.363409043000104],
              [14.299111517000085, 12.363628090000191],
              [14.299599648000026, 12.364298820000101],
              [14.300069808000046, 12.36542892500006],
              [14.300043577999986, 12.366029446000084],
              [14.299997, 12.367088000000138],
              [14.299527463, 12.368140745000119],
              [14.299269676000108, 12.368718147000095],
              [14.296966889000089, 12.370771269000159],
              [14.29694747700006, 12.370788576000052],
              [14.294039726000108, 12.372186662000104],
              [14.291404758000056, 12.372511861000078],
              [14.291236745000049, 12.372414576000153],
              [14.28864006200007, 12.370910666000043],
              [14.289119951999965, 12.368322263000039],
              [14.291358519000028, 12.367253871000059],
              [14.292119025999966, 12.366890907000084],
              [14.293276787000138, 12.366170883000166],
              [14.293433386, 12.366073714000038],
              [14.293621062999989, 12.365957260000187],
              [14.295055388000094, 12.364889145000177],
              [14.29538352600008, 12.364301089000037],
              [14.295876504000034, 12.363417626],
              [14.29600188500018, 12.362176917000113],
              [14.296086311000067, 12.361341476000177],
              [14.295616150000114, 12.358794212000078],
              [14.295463433, 12.358547544000089],
              [14.295251847000088, 12.358205795000174],
              [14.295215633000112, 12.358147417000112],
              [14.295013429000107, 12.357821464000097],
              [14.294839903000138, 12.357541266000169],
              [14.294327736000128, 12.356714249000049],
              [14.292967796000028, 12.355639459000088],
              [14.290649414000086, 12.356101991000173],
              [14.29026152400013, 12.356194442000117],
              [14.288460732000146, 12.356623650000074],
              [14.28759670300019, 12.356829642999969],
              [14.287089953000134, 12.357478219000029],
              [14.286990165000077, 12.35760593399999],
              [14.285835266000163, 12.359083176000013],
              [14.285497852000162, 12.359555976000081],
              [14.285067558000094, 12.360158921000107],
              [14.284305578000101, 12.361227160000112],
              [14.284275055000137, 12.361269951000054],
              [14.28430674100008, 12.361568285000033],
              [14.284488678000116, 12.363281250000114],
              [14.28467082899999, 12.36499023499999],
              [14.284483179000119, 12.36574744100011],
              [14.283939001000022, 12.367941998999981],
              [14.283095179000043, 12.369165664000093],
              [14.282223702000124, 12.370429040000147],
              [14.279517173000102, 12.372148515000049],
              [14.276408195000101, 12.373136521000106],
              [14.273164750000035, 12.373459817000025],
              [14.27218512800016, 12.373372679000113],
              [14.269316000000117, 12.37311700000015],
              [14.26707380300013, 12.372390041000131],
              [14.265806198000121, 12.371978760000104],
              [14.263515473000041, 12.369848252000054],
              [14.261315198000148, 12.368082043000015],
              [14.260970890000067, 12.368073189000029],
              [14.258385658000066, 12.368006706000131],
              [14.256120259000113, 12.368000681000069],
              [14.254082681000114, 12.367995263000125],
              [14.252683320000017, 12.368015234000097],
              [14.250140191000014, 12.36805152800008],
              [14.246998016000191, 12.367897189000189],
              [14.246567999000035, 12.367876000000138],
              [14.240900999000132, 12.365403000000128],
              [14.238727950000168, 12.364437642000041],
              [14.236524583000062, 12.363458633000107],
              [14.231962204000126, 12.362507821000122],
              [14.228215218000059, 12.362094879000097],
              [14.224431039000024, 12.361770629000091],
              [14.221886226000038, 12.362151514000061],
              [14.220512390000067, 12.362357139000096],
              [14.216666937000127, 12.365551752000044],
              [14.216449737000119, 12.365732192000053],
              [14.216259221000143, 12.366505213000153],
              [14.21590232900013, 12.367953301000057],
              [14.215697289000047, 12.368784905000098],
              [14.215976509000029, 12.370422376000136],
              [14.216161837000072, 12.371508639000069],
              [14.216481209000051, 12.371627808000028],
              [14.218860724000024, 12.372512876000087],
              [14.218922566000174, 12.372549538000101],
              [14.219872, 12.373113],
              [14.219767270000091, 12.373754096000141],
              [14.219665528000178, 12.374374390000128],
              [14.219007439000166, 12.375084092],
              [14.218157999000027, 12.376000001000023],
              [14.217012398, 12.376582592000034],
              [14.214881896000122, 12.377665519000175],
              [14.212425231, 12.378797532000135],
              [14.211204291000058, 12.379136545000051],
              [14.21003818500003, 12.379460335000033],
              [14.207016155000076, 12.38079259599999],
              [14.205056191000097, 12.381656646000124],
              [14.201301575000116, 12.383922577000078],
              [14.197999955000171, 12.385849952000115],
              [14.19774623100011, 12.385997833000033],
              [14.197175948000108, 12.386752933000139],
              [14.19678401900012, 12.387271881000117],
              [14.194693564999966, 12.390039444000024],
              [14.193557824000038, 12.392157532000112],
              [14.191636, 12.395741000000157],
              [14.188856001000147, 12.399185999000167],
              [14.188553808000108, 12.39980628700016],
              [14.188038826000138, 12.400862693000022],
              [14.186884880000036, 12.403231621999964],
              [14.186472893000143, 12.405222892999973],
              [14.185627954000154, 12.40598200400018],
              [14.185546373000022, 12.40598845400018],
              [14.183174133000023, 12.406175614],
              [14.181332589000078, 12.406438827000045],
              [14.179217338000115, 12.407302857000104],
              [14.176759093000044, 12.40735791700007],
              [14.175725936999982, 12.407381059000045],
              [14.17514444800014, 12.407490429],
              [14.17329216000013, 12.407838822000144],
              [14.173273381000115, 12.407857159000173],
              [14.17304897299999, 12.408076286000096],
              [14.171632801999976, 12.409459503000051],
              [14.171532813000169, 12.40955716600007],
              [14.170649529000059, 12.410420418000058],
              [14.170170784000163, 12.412278176000143],
              [14.172377587000085, 12.414520263000099],
              [14.172661781000158, 12.414809227000035],
              [14.173799514000052, 12.415308000000152],
              [14.174494976, 12.415612324000108],
              [14.175562859000024, 12.416080476000104],
              [14.176638604000118, 12.417743684000072],
              [14.17655559200017, 12.418579515000033],
              [14.176499368000123, 12.419137955000053],
              [14.176023483000108, 12.419999123000082],
              [14.17453384300012, 12.421257019000052],
              [14.173445702000095, 12.423444749000055],
              [14.173394202000054, 12.423757554000133],
              [14.172963142000128, 12.426364898000031],
              [14.173135758000171, 12.427668572000073],
              [14.173359871000173, 12.429354668000144],
              [14.175301552000121, 12.42978477600019],
              [14.175789832000021, 12.429893494000112],
              [14.177889823000157, 12.428173065000124],
              [14.179379463000146, 12.427181244000053],
              [14.181135178000034, 12.427186967000068],
              [14.181896210000104, 12.427762986000062],
              [14.182281494000165, 12.42805385500003],
              [14.182533263000153, 12.428489685000045],
              [14.183087349000175, 12.429450988000099],
              [14.182991027000185, 12.429778098000156],
              [14.182678222000163, 12.430844308000076],
              [14.182735443000183, 12.4316368100001],
              [14.182806968000136, 12.432637215],
              [14.18316841, 12.433822633000034],
              [14.183476447000032, 12.434830665000163],
              [14.184182168000063, 12.435203552000189],
              [14.185499192000123, 12.435899735000135],
              [14.187395096000159, 12.434576988],
              [14.18840026800018, 12.433647157000109],
              [14.188682556000117, 12.433385850000036],
              [14.191406249000124, 12.432795525000131],
              [14.192737579000152, 12.433132173000104],
              [14.193046570000035, 12.433949471000062],
              [14.193139075000147, 12.434195518000081],
              [14.193339347, 12.434926987000097],
              [14.192998886000169, 12.435921668999981],
              [14.191577912000014, 12.436714173000041],
              [14.189412118000064, 12.437836648000086],
              [14.187787057000151, 12.438961029000097],
              [14.186161041000105, 12.440483093000125],
              [14.185979842000108, 12.44271278400015],
              [14.185950278000178, 12.443072319000123],
              [14.186367035000103, 12.443839074000039],
              [14.187360764000175, 12.445667267999966],
              [14.189430236000135, 12.445963859000074],
              [14.191143036000085, 12.446208953000109],
              [14.192504883000083, 12.446715355000094],
              [14.19287586300004, 12.446853638000107],
              [14.19330215500014, 12.447012901000051],
              [14.194379806000029, 12.448144914000181],
              [14.193971634000036, 12.448940276000087],
              [14.193698884000185, 12.449935913000104],
              [14.192684174000078, 12.450330734000033],
              [14.191145897000069, 12.450679780000087],
              [14.188763618000053, 12.449802399000077],
              [14.185865402000047, 12.448650360000045],
              [14.184452057999977, 12.446853638000107],
              [14.183254242000032, 12.445967674000144],
              [14.18053436200006, 12.446442604000026],
              [14.179688454000029, 12.447269440000014],
              [14.179617881000127, 12.447338104000039],
              [14.178705216000026, 12.448230743000067],
              [14.1789493550001, 12.449379922000048],
              [14.17896175300001, 12.449439049000034],
              [14.179170608000163, 12.450423241000124],
              [14.180518151, 12.451755523000031],
              [14.180785179000111, 12.45288562799999],
              [14.180984498000157, 12.453948021000087],
              [14.18043995000005, 12.455075264000186],
              [14.179623603999971, 12.456799507000085],
              [14.179468154000119, 12.457345963000023],
              [14.179077148999966, 12.458724022000069],
              [14.17893600400015, 12.460649490000094],
              [14.178880692000178, 12.461478234000083],
              [14.178793908000102, 12.462773324000125],
              [14.178315162999979, 12.464830398000117],
              [14.177160264000122, 12.466686250000066],
              [14.176069260000077, 12.467906952000135],
              [14.175257682000051, 12.468815805000133],
              [14.17467498700006, 12.471076011000093],
              [14.174524308000116, 12.471659661000047],
              [14.17335891800019, 12.473932267000123],
              [14.173494339000115, 12.476921082000104],
              [14.173757554000133, 12.478170396000053],
              [14.174064636000139, 12.479626656000107],
              [14.175799370000107, 12.480485917000067],
              [14.177351952000151, 12.480180740000151],
              [14.178852081, 12.480252266000036],
              [14.180421829000068, 12.480257034000033],
              [14.180556298000056, 12.480257034000033],
              [14.182312965000051, 12.481216431000121],
              [14.183611871000039, 12.48181724599999],
              [14.184481622000135, 12.482219696000072],
              [14.184879303000116, 12.482711791000042],
              [14.18563556600003, 12.483645440000089],
              [14.186557769000046, 12.485210418000122],
              [14.186643601000071, 12.485356330000116],
              [14.186346054000012, 12.487774849000118],
              [14.186614989000191, 12.489387512],
              [14.186773299000151, 12.49033737100001],
              [14.186838150000085, 12.492310524000118],
              [14.186904908000088, 12.494321823000178],
              [14.186753273000079, 12.496598244000154],
              [14.187904357000093, 12.498878479000098],
              [14.190281868000056, 12.500734330000057],
              [14.19064903300017, 12.501020432000132],
              [14.191764831000114, 12.501494408999974],
              [14.19267368300018, 12.501880647000121],
              [14.193346024000107, 12.501805306000051],
              [14.195136070999979, 12.501603126000191],
              [14.19628715500005, 12.502607346000161],
              [14.196346283000139, 12.502712251000048],
              [14.197015761999978, 12.503890038000065],
              [14.196833611000102, 12.50459861700017],
              [14.196686744000147, 12.505169869000156],
              [14.196316719000151, 12.506070138000155],
              [14.196029663000047, 12.506768226000133],
              [14.195617676000154, 12.508208276000119],
              [14.194962501000191, 12.50940704400017],
              [14.194814681000025, 12.50981140100015],
              [14.194552421000026, 12.510525704000031],
              [14.194216728000072, 12.51372623400016],
              [14.193720817000155, 12.514361382000118],
              [14.19290733400004, 12.51540374800004],
              [14.19176197000013, 12.517240524000158],
              [14.190698623, 12.518518449000112],
              [14.189634323000064, 12.520195960000081],
              [14.188329697000142, 12.520832062000125],
              [14.187510491000182, 12.522191048000138],
              [14.186775207000096, 12.522829056000148],
              [14.186244964000025, 12.523377419000155],
              [14.185385704000169, 12.524265289000027],
              [14.185150146000012, 12.525768281000069],
              [14.185047151000106, 12.526425362000168],
              [14.18442821400015, 12.527757645000179],
              [14.183742522000102, 12.529234886000097],
              [14.183364867000023, 12.531986237000126],
              [14.18040561600003, 12.553544044999967],
              [14.177335739000171, 12.571484566000152],
              [14.172032355999988, 12.598820687000057],
              [14.170878410000114, 12.607235909000167],
              [14.170809746000089, 12.607603073000178],
              [14.168726920999973, 12.618683815000054],
              [14.166633606000062, 12.630608559000109],
              [14.166625977000081, 12.630653381000059],
              [14.156418800000097, 12.688815117000104],
              [14.155383111000106, 12.694270135000068],
              [14.152434350000078, 12.710652352000068],
              [14.150378226000157, 12.722187043000133],
              [14.146705627000131, 12.738855361000105],
              [14.146624564999968, 12.739428521000093],
              [14.144958497000175, 12.751174926000033],
              [14.134868622000113, 12.808415413000148],
              [14.132944107000128, 12.817609787000038],
              [14.131814956000085, 12.823007583999981],
              [14.127912521000042, 12.846171380000101],
              [14.125732421000123, 12.860772134000058],
              [14.124582290000092, 12.868474960000015],
              [14.12291431400007, 12.879114151000067],
              [14.117680549000056, 12.902472496000144],
              [14.115953446000049, 12.913640977000057],
              [14.114687919000062, 12.92182541],
              [14.108446121000156, 12.95661926299999],
              [14.104162216000191, 12.97858715000018],
              [14.102033616000085, 12.992613792000043],
              [14.098948479, 13.010850907000076],
              [14.09645271200003, 13.023967744000117],
              [14.085618972000134, 13.076781274000098],
              [14.085494041, 13.077390670000057],
              [14.085529327000131, 13.077386857000022],
              [14.088843345000043, 13.07706737500007],
              [14.112348557000132, 13.077149391000091],
              [14.179862023000112, 13.076367379000033],
              [14.187103272000172, 13.076926231000073],
              [14.22639465300017, 13.07684612200012],
              [14.289539337000122, 13.075084687000128],
              [14.298352242000021, 13.075644493000084],
              [14.301484107000078, 13.075601577999976],
              [14.301960944000029, 13.075594903000081],
              [14.318511010000179, 13.075364113000148],
              [14.324869155000158, 13.074174882000079],
              [14.357872009000062, 13.075062751000075],
              [14.364157676000104, 13.075613976000113],
              [14.370447159000094, 13.074155808000114],
              [14.4003782260001, 13.074092865000125],
              [14.44206142400003, 13.074119569000061],
              [14.45347499900015, 13.073340415000075],
              [14.459829330000048, 13.073822022],
              [14.464970588000142, 13.066263199000161],
              [14.484708785, 13.038034438000125],
              [14.487723351000056, 13.033886909000046],
              [14.488501548000045, 13.032907486000113],
              [14.507394791000024, 13.009139062000088],
              [14.513846398000112, 12.999338150000085],
              [14.51456260700013, 12.998250009],
              [14.516798019000134, 12.994895934000056],
              [14.525444983999989, 12.98191738100013],
              [14.533405304000155, 12.970511436999971],
              [14.532904626000118, 12.966545106000183],
              [14.529241562000095, 12.961004258],
              [14.531744957000171, 12.955089569000108],
              [14.53806972600006, 12.940687179],
              [14.539650917000074, 12.936957359000132],
              [14.54319381800002, 12.936449051000181],
              [14.548442842000043, 12.935814858000015],
              [14.551593780000132, 12.934275628000023],
              [14.551994323000145, 12.933549882000023],
              [14.552735328000097, 12.932205201000045],
              [14.552737236000098, 12.930981637000059],
              [14.552741051000112, 12.929260254000042],
              [14.552529336000077, 12.928354264],
              [14.552421570999968, 12.92789363899999],
              [14.552745819000165, 12.926211357999989],
              [14.553530693000084, 12.925091743000166],
              [14.553658486000074, 12.924908637000101],
              [14.553960800000084, 12.924458504000086],
              [14.555109977000029, 12.922745706000114],
              [14.557579995000083, 12.920226098],
              [14.558281898000189, 12.919396401000029],
              [14.55962085800013, 12.917811394000069],
              [14.560588836000079, 12.916339875000062],
              [14.56155490899999, 12.914870262000079],
              [14.562215805000164, 12.913364411000089],
              [14.562523842000132, 12.912663461000022],
              [14.563277244000119, 12.910667420000152],
              [14.564139367000166, 12.907724382000026],
              [14.564389229000028, 12.902954101000091],
              [14.564403535000167, 12.89935875000009],
              [14.564410210000119, 12.8954524990001],
              [14.564017296000088, 12.892521859000055],
              [14.564220429000102, 12.889884949000191],
              [14.567310332999966, 12.887448312000117],
              [14.569303512000147, 12.885889054000074],
              [14.570831298000087, 12.8845253],
              [14.571597099000087, 12.883842468000012],
              [14.571551322000118, 12.883054733000108],
              [14.571499825000046, 12.882182122000131],
              [14.570462226000075, 12.880648613000176],
              [14.570110322000119, 12.880128861000173],
              [14.568233488999965, 12.880125999000086],
              [14.567323684000087, 12.88012409300012],
              [14.564336777000165, 12.88050937700001],
              [14.56154918600015, 12.880602836000037],
              [14.559956551000084, 12.880599976000155],
              [14.55893039800003, 12.879956246000177],
              [14.558863640000027, 12.879914284000051],
              [14.557720184000061, 12.878749847000108],
              [14.558114052000178, 12.876454354000145],
              [14.557884217000037, 12.874003411000103],
              [14.557584763000136, 12.870830536000085],
              [14.556993485000078, 12.867606164000108],
              [14.556100846000049, 12.865651130000174],
              [14.556030273000147, 12.863128662000179],
              [14.556448937000141, 12.859254838000084],
              [14.557896615, 12.856554984000184],
              [14.559474946000137, 12.853597641000022],
              [14.560661317000154, 12.850253106000139],
              [14.562768936, 12.844722747000048],
              [14.56551170400013, 12.840176582000026],
              [14.565985679000164, 12.836655617000133],
              [14.56552505500008, 12.832674027],
              [14.564766884000107, 12.82670879300008],
              [14.563765525000122, 12.824125290000097],
              [14.563070296999967, 12.822330475],
              [14.562409401000025, 12.820840836000116],
              [14.561241149000182, 12.81820964800005],
              [14.558494568000185, 12.814086913999972],
              [14.557335853000154, 12.812644004000163],
              [14.556009294000091, 12.810993194000162],
              [14.555159569000011, 12.810000420000108],
              [14.55286788900014, 12.807320595000135],
              [14.549468995000097, 12.801780700000165],
              [14.547410966000086, 12.795970918000137],
              [14.547575951000169, 12.790994644000136],
              [14.548284532000082, 12.787243844000159],
              [14.54977226200009, 12.783954620000145],
              [14.551807404000044, 12.780053139000188],
              [14.554075241000191, 12.776764870000136],
              [14.556861877000188, 12.772707940000032],
              [14.559749604000103, 12.770911216000059],
              [14.562892914000145, 12.772975923000104],
              [14.564723015000141, 12.775810243000137],
              [14.565295219000063, 12.776590347000024],
              [14.566045762000044, 12.777613640000027],
              [14.56642436900006, 12.778129577000186],
              [14.569699288000038, 12.779293061000146],
              [14.569753648000074, 12.779297829000029],
              [14.572584153000037, 12.779555321000032],
              [14.573778151999988, 12.778803826000114],
              [14.574420929000155, 12.778399467000099],
              [14.576915741000164, 12.776215553999975],
              [14.578025818000071, 12.775043487000062],
              [14.579023362000044, 12.770170211000107],
              [14.578376769999977, 12.764763833000075],
              [14.578048706, 12.761109352000119],
              [14.577998162000085, 12.756140709000135],
              [14.579314231000012, 12.752797127000065],
              [14.581679344000065, 12.749969482000154],
              [14.584174156000074, 12.747528075000048],
              [14.586142541000129, 12.746115684000131],
              [14.588504791000162, 12.7447032930001],
              [14.59296226500004, 12.744195939],
              [14.595191002000149, 12.743941307000114],
              [14.598335267000152, 12.745491027000071],
              [14.601346971000112, 12.7478122710001],
              [14.603309631000172, 12.750260353000158],
              [14.60605812099999, 12.753095626000174],
              [14.607626915000139, 12.756057740000131],
              [14.609719275999964, 12.759407044000113],
              [14.610507965000124, 12.760749817000146],
              [14.612595558000123, 12.764302254000143],
              [14.614819527000179, 12.767651558000182],
              [14.617046356000117, 12.768813133000094],
              [14.619450569000037, 12.769996643000127],
              [14.619928359000141, 12.770232201000056],
              [14.621764183000153, 12.77036380800007],
              [14.622945785000013, 12.769464493000157],
              [14.623242379000033, 12.76927852700004],
              [14.624682426999982, 12.768376350000096],
              [14.625574111000049, 12.764706613000101],
              [14.625577926000119, 12.762085915000171],
              [14.625580789000082, 12.760588647000134],
              [14.624539375000097, 12.755567552000059],
              [14.623501777, 12.752970695000045],
              [14.622840881000059, 12.751317977999975],
              [14.62088108100005, 12.746939660000123],
              [14.62066459600004, 12.746738433000075],
              [14.618393896999976, 12.744619369000077],
              [14.617084503000171, 12.743459702000109],
              [14.616827011, 12.740499496000155],
              [14.617618561000143, 12.737411499000189],
              [14.621552468000118, 12.736515999000176],
              [14.625615120000191, 12.73716545200017],
              [14.628498078000121, 12.737941740999986],
              [14.631773949000035, 12.738461495000024],
              [14.634264945999973, 12.738721848000125],
              [14.637412071000085, 12.737696647000121],
              [14.641085625000073, 12.735384941000177],
              [14.644235611000113, 12.732686997000087],
              [14.646467210000083, 12.730759622000051],
              [14.648569106000139, 12.727673530000118],
              [14.650275231000137, 12.725873948000071],
              [14.653163909000057, 12.722402573000181],
              [14.65631198900013, 12.720604896000054],
              [14.65959072000004, 12.719450952000102],
              [14.663000107000187, 12.718554497000127],
              [14.667457581, 12.718045234000158],
              [14.672045708000155, 12.718050957],
              [14.675977708000062, 12.717798232000177],
              [14.680040359000031, 12.718446732000189],
              [14.685676575000059, 12.719354630000169],
              [14.690000535000081, 12.720775604000153],
              [14.69419288600011, 12.722453118000033],
              [14.697207450000121, 12.722971917000052],
              [14.70100784300007, 12.723619462000045],
              [14.703629493999983, 12.723879815000032],
              [14.706513404000077, 12.723367691000078],
              [14.709901810000133, 12.722514153000191],
              [14.710577964000095, 12.722343445000092],
              [14.712319374000117, 12.721568109000088],
              [14.713463784000112, 12.721058845000016],
              [14.715212822000069, 12.720174789000055],
              [14.717529297000169, 12.719004631000075],
              [14.719891548000135, 12.716818809000017],
              [14.721991539000044, 12.714247703000126],
              [14.72283363400004, 12.712871551000092],
              [14.723052979000158, 12.71251392400012],
              [14.72369766200012, 12.711461068],
              [14.723960876000035, 12.711031915000149],
              [14.724231719000159, 12.710519791000024],
              [14.725931168000159, 12.707302093000067],
              [14.725935936000042, 12.703183175000163],
              [14.723975182000117, 12.697904587000039],
              [14.72341537599999, 12.697159767000073],
              [14.721750260000078, 12.694942474000186],
              [14.719263076000175, 12.691850661000046],
              [14.715334892000044, 12.688371658000051],
              [14.715247153000178, 12.688301087000184],
              [14.714301109000019, 12.687533379000058],
              [14.71438222300003, 12.687501495000106],
              [14.714463606999971, 12.687469505000138],
              [14.714382599000032, 12.687486626],
              [14.714271956, 12.687510009999983],
              [14.713109016000089, 12.686567305999972],
              [14.711453505000179, 12.685141871000098],
              [14.710795559000076, 12.684575364000011],
              [14.710371017000114, 12.68420982300006],
              [14.710302138000031, 12.684150529000135],
              [14.710261345000106, 12.68411541100005],
              [14.709966660000134, 12.683861732000139],
              [14.709797638, 12.683602945000132],
              [14.709685669000066, 12.68343151300013],
              [14.709629060000111, 12.683344840000188],
              [14.707611083000188, 12.680254936000154],
              [14.706912040000191, 12.6790857310001],
              [14.706750353000018, 12.678814817000102],
              [14.70654733900011, 12.678474656999981],
              [14.706303596000112, 12.678066253000111],
              [14.706264746000102, 12.677939409000146],
              [14.70624731099997, 12.677882482000143],
              [14.706224441000018, 12.677807808000182],
              [14.705843461000143, 12.676555577000045],
              [14.705655103000026, 12.67593646500012],
              [14.705519676999984, 12.675491332000149],
              [14.705131531, 12.671372414000075],
              [14.705287247000115, 12.669860812000024],
              [14.705399317000172, 12.668772902000114],
              [14.705529213000091, 12.6675119410001],
              [14.706004143000087, 12.664278031000094],
              [14.706379256000162, 12.662995810000098],
              [14.706650089000107, 12.662070045000178],
              [14.706977843, 12.660949708000089],
              [14.708767962000081, 12.658915010000158],
              [14.708884553000075, 12.658782490000021],
              [14.710258484000121, 12.657220841000026],
              [14.714586257000178, 12.654909135000082],
              [14.715420795000114, 12.654558972000132],
              [14.717435673000068, 12.653713551000124],
              [14.718256951000171, 12.653368950000129],
              [14.722843168999987, 12.653888702000131],
              [14.72492118200006, 12.655176531],
              [14.726373043000024, 12.656076308000138],
              [14.73070049200004, 12.65943050400017],
              [14.733053207000125, 12.665224076000072],
              [14.733304643000167, 12.667154369],
              [14.733432857000082, 12.668138674000033],
              [14.733572959000128, 12.669214248],
              [14.733604777000096, 12.669673778],
              [14.73368087099999, 12.670772781000096],
              [14.733831406000149, 12.672946930000137],
              [14.7341403960001, 12.675806999000088],
              [14.734157958000139, 12.675970076000169],
              [14.734176586000103, 12.676143056000058],
              [14.734221, 12.676551],
              [14.734588711000185, 12.678246139000123],
              [14.73474560000011, 12.678968477000183],
              [14.735003471000027, 12.680155753000179],
              [14.735765231000187, 12.680999031000169],
              [14.736351883000054, 12.681648462000169],
              [14.737095353000086, 12.682471069000087],
              [14.738336349000122, 12.683159937000084],
              [14.739193914999987, 12.683634759000086],
              [14.739680710000016, 12.683863959000064],
              [14.740259113000036, 12.684136292000119],
              [14.741238000000124, 12.684597],
              [14.742207000000178, 12.685053],
              [14.742332652000073, 12.685100663000071],
              [14.743909836, 12.685698510000066],
              [14.747318268000129, 12.685443878000115],
              [14.749940871000149, 12.684288025000114],
              [14.750355248000062, 12.684017299000118],
              [14.750976902000161, 12.683611153000072],
              [14.75229586200004, 12.682749357000034],
              [14.75326200100011, 12.682054999000115],
              [14.754174, 12.6814],
              [14.754733999000052, 12.680499],
              [14.755137578000131, 12.679849567000076],
              [14.755974769000034, 12.678502083000126],
              [14.756780624000044, 12.676851273000125],
              [14.757419587000072, 12.675543785000173],
              [14.75769924399998, 12.674358959000187],
              [14.75790000000012, 12.673507],
              [14.758209, 12.672198000000151],
              [14.758355002000087, 12.67142549],
              [14.758500998999978, 12.67065299900014],
              [14.758604596000112, 12.670104316000106],
              [14.758942603000094, 12.668312073000095],
              [14.759657609000101, 12.66608541100004],
              [14.760195235000026, 12.664411143000052],
              [14.760663, 12.662954],
              [14.762021000000175, 12.659846],
              [14.762081, 12.659126],
              [14.762551001000077, 12.653541000000132],
              [14.762905205999971, 12.648959456000114],
              [14.762943470000153, 12.648464131000082],
              [14.762948974000039, 12.648392782000144],
              [14.762927269000102, 12.648206831000095],
              [14.762834878000035, 12.647416384000167],
              [14.76276874500013, 12.646850585000152],
              [14.762628555999981, 12.645652771000186],
              [14.763070606000156, 12.643532222000147],
              [14.76320605799998, 12.642882445000112],
              [14.763479233000112, 12.641571998000131],
              [14.765972138000052, 12.637970924000058],
              [14.767632485000036, 12.635972978000154],
              [14.768083050000143, 12.63543131900019],
              [14.768273577000173, 12.635202271000026],
              [14.768858910000176, 12.634498596000185],
              [14.770657540000173, 12.633282662000113],
              [14.771673509000095, 12.632595516000094],
              [14.77223009800008, 12.632219070000019],
              [14.772661209000091, 12.63192749100017],
              [14.776856106000025, 12.629615004000073],
              [14.778053003000025, 12.629654890000154],
              [14.780786173000024, 12.62974597200008],
              [14.784391941000138, 12.628766447000089],
              [14.785185145000185, 12.62855078900003],
              [14.785505295000064, 12.628463746000023],
              [14.790353775000085, 12.628338815000063],
              [14.795594215999984, 12.62924385000008],
              [14.800966262000031, 12.630148888000178],
              [14.803586960000189, 12.63027954100005],
              [14.809614182000075, 12.631570816000078],
              [14.810229863000131, 12.631613005000077],
              [14.811519510000153, 12.631701378],
              [14.813413620000176, 12.631831169000179],
              [14.816204932000062, 12.63295434500003],
              [14.816293551000172, 12.632990004000078],
              [14.820226670000068, 12.633379935999983],
              [14.82389545400008, 12.633510590000128],
              [14.825108347000139, 12.633135249000077],
              [14.825403000000108, 12.633044],
              [14.826380381000035, 12.632741430000124],
              [14.828705000000127, 12.631602999000165],
              [14.828904982000154, 12.631504996000103],
              [14.829007, 12.631455],
              [14.829324000000156, 12.631144999000071],
              [14.830109763000053, 12.630374466000035],
              [14.830507241000078, 12.629984566000076],
              [14.830974579000042, 12.629526138000188],
              [14.831403731000023, 12.628562928000065],
              [14.831840021000062, 12.627585742],
              [14.831878024000105, 12.627500624999982],
              [14.831892966999987, 12.627467157000069],
              [14.833206176000033, 12.623350144000028],
              [14.833346918000132, 12.623046592000151],
              [14.833610584000098, 12.62247791599998],
              [14.834221840000055, 12.621159554000144],
              [14.83437303300002, 12.62083334800019],
              [14.834394, 12.620788000000118],
              [14.834518, 12.62052000000017],
              [14.835856999000043, 12.617686000000162],
              [14.835859367000069, 12.61767305800015],
              [14.836625391000041, 12.613485631000174],
              [14.836727983, 12.612924498000041],
              [14.836750984000048, 12.612798692000069],
              [14.836800255000128, 12.612516191000054],
              [14.836933, 12.611754000000133],
              [14.837671000000171, 12.607522000000131],
              [14.837113938000073, 12.603675570000178],
              [14.836944598000059, 12.602504946000124],
              [14.836757660000046, 12.601215362],
              [14.836898723000104, 12.60060435500003],
              [14.836967605000098, 12.600305996000088],
              [14.837091445000056, 12.599769592000143],
              [14.837363112000048, 12.598591485999975],
              [14.837658668000188, 12.597309785000107],
              [14.837677977000112, 12.597226079999984],
              [14.83768817300006, 12.597194665000131],
              [14.838054934000127, 12.59606469],
              [14.839515686000027, 12.591564179000045],
              [14.840247882000028, 12.588708228000087],
              [14.840379607000159, 12.58819443100009],
              [14.840944290999971, 12.585991861000139],
              [14.841031946000044, 12.585717860000102],
              [14.8411825660001, 12.585247033000144],
              [14.841595649000055, 12.583955765000042],
              [14.842193604000045, 12.582087518000094],
              [14.844066620000149, 12.578184128000089],
              [14.844992220000165, 12.57654267800018],
              [14.846027386, 12.574706926000147],
              [14.846095000000105, 12.574587000000179],
              [14.848825999000042, 12.569918],
              [14.851714000000129, 12.563412000000142],
              [14.851824283000155, 12.562961929000096],
              [14.852116791000185, 12.561767498000165],
              [14.852651597000033, 12.559583663000012],
              [14.852752224000085, 12.558282230999964],
              [14.852827328000103, 12.557310898000026],
              [14.852965354000105, 12.555525780000096],
              [14.853386242000113, 12.554073594000045],
              [14.85352104899999, 12.553608471000189],
              [14.853924751000022, 12.552215576000094],
              [14.854002493000053, 12.551947275000032],
              [14.854049, 12.551786000000106],
              [14.854081, 12.551676],
              [14.854016363000142, 12.548361311000178],
              [14.853952999000057, 12.545111999000085],
              [14.853816996999967, 12.541982943000164],
              [14.853815026000063, 12.541937813000175],
              [14.853783180000107, 12.541209591000154],
              [14.853694916000109, 12.539191245000097],
              [14.853657974000043, 12.538977585],
              [14.853623054000025, 12.538775622000117],
              [14.85356712399999, 12.538452148000147],
              [14.853375400000118, 12.537341305000155],
              [14.85302606, 12.535317245000044],
              [14.852650642000015, 12.533142089000023],
              [14.852128030000131, 12.53043937699999],
              [14.852122104000159, 12.530403262000164],
              [14.852063300999987, 12.530044888000134],
              [14.851734161000138, 12.528038978000097],
              [14.85160732300011, 12.524389267000174],
              [14.851904932000139, 12.522567423000055],
              [14.852132797000081, 12.521172523000132],
              [14.85177289700016, 12.519602323000072],
              [14.851466515000027, 12.518265615000132],
              [14.851218223999979, 12.517182351000031],
              [14.851221085000134, 12.513449774000037],
              [14.85122511000003, 12.513439056000038],
              [14.85131037900004, 12.513213085000075],
              [14.852532386000178, 12.509975434000125],
              [14.853866002000018, 12.508404955000117],
              [14.854023841000071, 12.508219082000096],
              [14.854159355000093, 12.508059501000162],
              [14.854498863000117, 12.507659913000111],
              [14.855171205000147, 12.506637573000091],
              [14.855798722000088, 12.505683900000122],
              [14.855907277000085, 12.505490800000132],
              [14.856003237000039, 12.505320104000077],
              [14.856303214999969, 12.504786491000061],
              [14.85667920100019, 12.504118393],
              [14.856932722000124, 12.503667905000043],
              [14.857047067000053, 12.503464724000025],
              [14.857066800000041, 12.503382568000177],
              [14.857984542000111, 12.499560356000075],
              [14.858224293000148, 12.498797908000085],
              [14.858418360000144, 12.498180746000173],
              [14.858609, 12.497574],
              [14.858956998999986, 12.496465999000122],
              [14.8592060040001, 12.492580969000016],
              [14.859221000000105, 12.492347000000109],
              [14.859223999000051, 12.487842999000065],
              [14.859223020000059, 12.487813448000168],
              [14.859168000000125, 12.48615500000011],
              [14.859159948000183, 12.485911876000046],
              [14.859095574000037, 12.4839811330001],
              [14.858200728000043, 12.48007702600006],
              [14.858181, 12.479991],
              [14.856085707000091, 12.478871765000122],
              [14.854258693000133, 12.477895836000073],
              [14.854169174000106, 12.477847957000108],
              [14.853832040999976, 12.477667697000129],
              [14.853598594000118, 12.477542877000133],
              [14.852598439000076, 12.476796466000053],
              [14.851994231000162, 12.476345548999973],
              [14.849802018000048, 12.474709511000128],
              [14.847577093999973, 12.473549844000047],
              [14.846006393000152, 12.47174740000014],
              [14.845687369000018, 12.471119238000028],
              [14.845066607000035, 12.469896954000149],
              [14.844960206, 12.469687404000013],
              [14.844569206000187, 12.466856003000146],
              [14.844455099000072, 12.465503592000061],
              [14.844404180000026, 12.464900088000036],
              [14.844291687, 12.463566781000111],
              [14.845096588000104, 12.460550308000052],
              [14.84614658400011, 12.456689834000031],
              [14.847588539000128, 12.453215599000032],
              [14.849335095000072, 12.450387056000068],
              [14.849910954, 12.449454453000044],
              [14.850210146000052, 12.448969913000099],
              [14.850276274, 12.44893059400016],
              [14.850505542000178, 12.448794348999968],
              [14.855187416000149, 12.446012497000027],
              [14.859247575999973, 12.446901026000091],
              [14.859967545000131, 12.447058585000093],
              [14.86107921700011, 12.447301864000053],
              [14.861523011000031, 12.447787104000042],
              [14.86235380100004, 12.448695481000129],
              [14.864612580000141, 12.451165200000105],
              [14.86461162500018, 12.45247554700012],
              [14.864609717000178, 12.456828118000089],
              [14.8650992580001, 12.458225682000091],
              [14.865315184, 12.458842117000188],
              [14.86591720600012, 12.460560798000188],
              [14.866587442000139, 12.460969031000104],
              [14.866824000000179, 12.461113],
              [14.868667, 12.462235],
              [14.86999588700013, 12.462370988000032],
              [14.871325001000173, 12.46250700000013],
              [14.871607524000183, 12.462535502000151],
              [14.871889999000189, 12.462564],
              [14.872190270999965, 12.462595387000022],
              [14.872316039999987, 12.462608340000145],
              [14.872464180000179, 12.462623597000061],
              [14.873960657999987, 12.462109880000128],
              [14.875084, 12.461724000000174],
              [14.877047999000126, 12.460438000000181],
              [14.878883000000144, 12.457864999000151],
              [14.879932, 12.453876],
              [14.879544000000124, 12.442935],
              [14.878312000000165, 12.438721999000052],
              [14.87836773000015, 12.438376345000052],
              [14.878658, 12.436576],
              [14.878648, 12.43542900000017],
              [14.878644485000052, 12.43501638500004],
              [14.878632546, 12.433668138000144],
              [14.879287720000036, 12.431094170000165],
              [14.880730630000073, 12.426590919000148],
              [14.883481979000067, 12.422472954000057],
              [14.884695666000027, 12.420684926999968],
              [14.88531597500014, 12.419771035000053],
              [14.887543679000089, 12.415396692000058],
              [14.889543112000013, 12.41259124600009],
              [14.889601372000072, 12.412509500000056],
              [14.890295028000082, 12.411536216],
              [14.890716797000039, 12.410995887000183],
              [14.891090061000057, 12.410517699000025],
              [14.893307687, 12.40767669700017],
              [14.8947404970001, 12.405474429000037],
              [14.895823122000081, 12.403810405000172],
              [14.896321296000053, 12.403044701000056],
              [14.896334176000039, 12.403030461000128],
              [14.896494878000055, 12.402852790000054],
              [14.898416519000136, 12.400728227000059],
              [14.899989128000129, 12.398412706000045],
              [14.901167870000165, 12.396096230000069],
              [14.902346611000041, 12.39352226200009],
              [14.903788566000117, 12.39069175700007],
              [14.904440621000106, 12.389125452000087],
              [14.9046020400001, 12.388737708000122],
              [14.904967308000096, 12.387860299000124],
              [14.907195090000016, 12.382713318000128],
              [14.908327102999976, 12.380171776000168],
              [14.908418421000079, 12.379872330000069],
              [14.909039157000109, 12.377836837000132],
              [14.909553528000117, 12.376150130000099],
              [14.909423827000126, 12.37254619600003],
              [14.909291367000037, 12.371763279000163],
              [14.90914, 12.37087],
              [14.908901000000128, 12.369457],
              [14.907724, 12.365338000000122],
              [14.906678, 12.361992],
              [14.905163260000165, 12.358371022000085],
              [14.904901682000173, 12.357745822000027],
              [14.90484714500019, 12.357615472000077],
              [14.904699540000138, 12.357351192000067],
              [14.904392320000113, 12.35680113],
              [14.903415155, 12.355052021000176],
              [14.903408999000021, 12.355041001000131],
              [14.902493, 12.352467],
              [14.901539000000128, 12.35038],
              [14.901478814000143, 12.350248291000071],
              [14.901315690000104, 12.349891662000175],
              [14.900924684000074, 12.346931458000029],
              [14.900987624000152, 12.344309808000162],
              [14.900926589000164, 12.340882302000068],
              [14.901304984000149, 12.339933103000135],
              [14.901502988000175, 12.339436412000168],
              [14.902106284000183, 12.337923049000096],
              [14.903698169000165, 12.335464823999985],
              [14.903826355000092, 12.335266876000162],
              [14.903939247000039, 12.335092544000133],
              [14.904938034000168, 12.334466018000114],
              [14.906643183000142, 12.333396399000037],
              [14.90805497600013, 12.332510641999988],
              [14.909560339000052, 12.330764563000059],
              [14.909696580000059, 12.330606461000116],
              [14.909766485000091, 12.330525242000022],
              [14.910486, 12.329689000000144],
              [14.910445860999971, 12.328206997000166],
              [14.91035652100004, 12.324926377000168],
              [14.908706890000076, 12.321475983000141],
              [14.908263999000098, 12.32055],
              [14.907946613000149, 12.320207895000181],
              [14.907300950000092, 12.319512368000119],
              [14.905516624000086, 12.317588806000117],
              [14.903550146999976, 12.315207482000062],
              [14.902405738000084, 12.313820839000186],
              [14.90211486700008, 12.313468933000024],
              [14.899629593000157, 12.311408997000115],
              [14.898185616000148, 12.310225170000137],
              [14.897548499999971, 12.309702836999975],
              [14.897274018000189, 12.309477806000132],
              [14.895834923000052, 12.307932854000171],
              [14.893708228000037, 12.305850029000055],
              [14.89324848800004, 12.302599517000033],
              [14.893219948000137, 12.302397729000063],
              [14.894398690000173, 12.2986650470001],
              [14.895355588000086, 12.296279768000034],
              [14.895778968000116, 12.295224400999984],
              [14.896101953000141, 12.294419288000086],
              [14.897189788000162, 12.292685943000095],
              [14.89795271600002, 12.291470303000096],
              [14.900947570000028, 12.286698341000147],
              [14.903434754000102, 12.283996582000157],
              [14.904524954000067, 12.282210032000137],
              [14.905004999000084, 12.281422999000085],
              [14.905010264000111, 12.28140806600004],
              [14.905521, 12.27995900000019],
              [14.905668617000117, 12.279540399000098],
              [14.906007767000119, 12.278577805000054],
              [14.906494719000079, 12.275078906000033],
              [14.906578715000137, 12.274475054000163],
              [14.906579, 12.273460000000171],
              [14.906580677000079, 12.269993146000047],
              [14.906580924000025, 12.269325257000105],
              [14.906308704000082, 12.267924858000129],
              [14.905595342000026, 12.264255060999972],
              [14.905405012000074, 12.263276064000081],
              [14.904096603000085, 12.261731148000138],
              [14.903506673000152, 12.26071828500011],
              [14.903053217000092, 12.25993973500016],
              [14.902857, 12.259603],
              [14.901896204000025, 12.258615230000089],
              [14.900042535000011, 12.25671005300012],
              [14.898839311000131, 12.253599667000117],
              [14.898668118999979, 12.253157129000101],
              [14.897951127000169, 12.251303673000052],
              [14.897855355000047, 12.250885830000016],
              [14.89760655399999, 12.249800342000128],
              [14.897036552000088, 12.247313500000075],
              [14.896514892000084, 12.243065834000163],
              [14.896639672000106, 12.241479871000081],
              [14.896738804000165, 12.240219887000137],
              [14.896778107000102, 12.239720345000023],
              [14.899395942000069, 12.235216141000024],
              [14.901884079000183, 12.230455398000174],
              [14.903098106000186, 12.22786426600004],
              [14.903337426000064, 12.226802677000023],
              [14.903592450000019, 12.225671437000017],
              [14.903848648000064, 12.224534988000073],
              [14.903718947000129, 12.220288277000122],
              [14.902281762000143, 12.215268136000191],
              [14.899580001000118, 12.212182044000087],
              [14.899497525000072, 12.21208778700003],
              [14.899350862000063, 12.211920171000031],
              [14.899306298000113, 12.211869240000169],
              [14.89901277700011, 12.211534741000094],
              [14.898778549999975, 12.211178357000108],
              [14.897569348000104, 12.209339699],
              [14.896103915000083, 12.207355200000052],
              [14.895482064000078, 12.206513406],
              [14.895210715000076, 12.205845266000154],
              [14.895028910000065, 12.20539761200007],
              [14.894698142000038, 12.204583168],
              [14.893914223000138, 12.202394486000117],
              [14.893053055000109, 12.200386047000109],
              [14.893086822000157, 12.200250376000156],
              [14.893153817000098, 12.19998120299999],
              [14.893908500000066, 12.196949005000135],
              [14.895307541000136, 12.193818094000164],
              [14.896232388000158, 12.192658313000038],
              [14.896463347000179, 12.192368685000019],
              [14.897560119000161, 12.190993309000078],
              [14.901054381999984, 12.189619065000102],
              [14.901710287000185, 12.189377349000154],
              [14.902520019, 12.189078945000176],
              [14.903538704000027, 12.188703536999981],
              [14.905740590000107, 12.187826582000184],
              [14.906255114000089, 12.187621661000037],
              [14.906412124000099, 12.187559128000089],
              [14.907258988000024, 12.187269212000103],
              [14.907276401000104, 12.187263200000132],
              [14.907286000000113, 12.18726],
              [14.907339, 12.18724200000014],
              [14.908197, 12.186948000000143],
              [14.908455, 12.186800000000176],
              [14.910962937000136, 12.185362235000127],
              [14.910993, 12.185345],
              [14.911316174000149, 12.184103491000087],
              [14.911656, 12.182798000000162],
              [14.911847134000027, 12.182059460000119],
              [14.91183937500017, 12.182045354000024],
              [14.911762237000175, 12.181904793000058],
              [14.911060334000126, 12.180624963000014],
              [14.911046553000119, 12.180599974000188],
              [14.911024000000111, 12.180558],
              [14.910989, 12.180495],
              [14.910767, 12.180091000000175],
              [14.91029600000013, 12.179235],
              [14.910037000000159, 12.178941],
              [14.908201000000133, 12.176866000000132],
              [14.906839, 12.175275000000113],
              [14.906106, 12.17442],
              [14.903989165999974, 12.173771796000096],
              [14.903192520000175, 12.173527718000173],
              [14.903153658000122, 12.173515829000166],
              [14.903054, 12.173485],
              [14.902613, 12.17335],
              [14.901736, 12.173158000000114],
              [14.901158, 12.173032],
              [14.900119, 12.172804000000156],
              [14.899818, 12.172738],
              [14.899784486999977, 12.172736550000081],
              [14.899401969000166, 12.172719659000165],
              [14.896325111000124, 12.172583581000083],
              [14.894187343000169, 12.172026649000031],
              [14.893830754000078, 12.17193394800006],
              [14.893784436000146, 12.171931981],
              [14.893686295000066, 12.171893119000174],
              [14.893238068000016, 12.171761513000092],
              [14.890891076000173, 12.171053887000028],
              [14.889389991000087, 12.169961929000067],
              [14.889105797000184, 12.169754980999983],
              [14.887011527000084, 12.167615891000139],
              [14.886157988999969, 12.165858269000182],
              [14.885326385000099, 12.164100646000122],
              [14.884995459999971, 12.160739899000191],
              [14.886548995, 12.157837868000115],
              [14.888724327000034, 12.154707909000081],
              [14.891442299000062, 12.153104783000117],
              [14.894547462000048, 12.151730537000105],
              [14.897109985000043, 12.149974824000026],
              [14.901612281000041, 12.148066520000043],
              [14.905183791000184, 12.146005631000151],
              [14.907746315999987, 12.143333436000148],
              [14.91108513, 12.14035511100019],
              [14.913570404000154, 12.137454033000097],
              [14.915512085000103, 12.134780885000112],
              [14.9173755650001, 12.132642747000091],
              [14.91970539100015, 12.128595352000161],
              [14.922888756000191, 12.125082016000079],
              [14.925839424000117, 12.121493339999972],
              [14.929409981000049, 12.118362428000125],
              [14.931901933000177, 12.115912438000066],
              [14.933680534000132, 12.114162446000023],
              [14.934066772000108, 12.113828660000138],
              [14.936862945000087, 12.11141300200012],
              [14.940433503000122, 12.108664513000178],
              [14.943150520000131, 12.106755257000088],
              [14.946255683000118, 12.104998589000047],
              [14.950315476000014, 12.103241922000109],
              [14.952931403000036, 12.099729538000133],
              [14.955648423000127, 12.095529556000031],
              [14.959839822000106, 12.092856408000046],
              [14.964108467000017, 12.091634751000129],
              [14.967756271000042, 12.090184212000111],
              [14.973499297000046, 12.08766365200006],
              [14.981803894, 12.084685325000123],
              [14.988554955000041, 12.083846093000147],
              [14.991737366000166, 12.088810921000061],
              [14.991659164999987, 12.096448899],
              [14.991038322000065, 12.105004312000119],
              [14.991659164999987, 12.10714244899998],
              [14.992341042000191, 12.108799934999979],
              [14.992822646999969, 12.109969140000032],
              [14.994607926000185, 12.112565994000136],
              [14.995190621000177, 12.112924576000125],
              [14.99709129300004, 12.114093781000122],
              [15.000041008000096, 12.115621567000062],
              [15.000154496000107, 12.115690232000134],
              [15.00205898300004, 12.116843223999979],
              [15.003556251000077, 12.116973878000124],
              [15.004697799000041, 12.117073060000166],
              [15.006018638000171, 12.116961479999986],
              [15.007414819000132, 12.116843223999979],
              [15.00951004, 12.116079331000094],
              [15.012148858000046, 12.115086555000062],
              [15.012403488000189, 12.115011216000084],
              [15.015486717000101, 12.114093781000122],
              [15.019289018, 12.112794877000113],
              [15.019805907000091, 12.112566949000097],
              [15.022936821000144, 12.111189842000101],
              [15.02681732100001, 12.108821869000053],
              [15.029276848000052, 12.107043267000108],
              [15.030337333000148, 12.106276512000022],
              [15.030620574000011, 12.10607147200011],
              [15.032793045000119, 12.10454368600017],
              [15.034888268000032, 12.102710724000019],
              [15.035936355000104, 12.100928306000185],
              [15.036725997000076, 12.098671913000089],
              [15.037371635000056, 12.096828461000143],
              [15.037137983999969, 12.093926430000124],
              [15.036982537000029, 12.091176034000114],
              [15.036206245000074, 12.087281227000176],
              [15.036018372000058, 12.085550308],
              [15.035998343000131, 12.085359574000051],
              [15.035817147000046, 12.083690643000068],
              [15.03558921900003, 12.080654144000107],
              [15.035738946000038, 12.077426910000042],
              [15.036757469, 12.074348450000059],
              [15.036825180000051, 12.074142456000061],
              [15.038609505000124, 12.07238578800002],
              [15.041170121000107, 12.069864273000064],
              [15.042887688000178, 12.06888580399999],
              [15.044118881000031, 12.068183899000076],
              [15.047765732000016, 12.066808700000081],
              [15.050637244000143, 12.065967560000104],
              [15.052266121000173, 12.064363480000111],
              [15.052499771000157, 12.064002038000012],
              [15.0538949970001, 12.061841965000099],
              [15.054521561000058, 12.06045436900007],
              [15.054631234, 12.060211182999979],
              [15.05503273, 12.059322356999985],
              [15.055446625000059, 12.058404923000126],
              [15.056998252000085, 12.05595970100012],
              [15.058317183999975, 12.053591728000015],
              [15.05870437700014, 12.051300048000144],
              [15.05877971600006, 12.050496103000171],
              [15.058879853000064, 12.049421310000128],
              [15.059246062999989, 12.046792984000092],
              [15.058702468999968, 12.043890953000016],
              [15.056994437000071, 12.036710739000114],
              [15.056449889000135, 12.03419017900012],
              [15.055829048000192, 12.030906677000075],
              [15.053578377, 12.028233529000033],
              [15.050009726999974, 12.029379846000097],
              [15.044655799, 12.030755998000132],
              [15.042638779000129, 12.029915811000137],
              [15.04124164600006, 12.029304505000027],
              [15.040232658999969, 12.028617860000111],
              [15.039760590000071, 12.027956962000133],
              [15.038758278000103, 12.026555061000067],
              [15.039183617000049, 12.02451801300009],
              [15.039300918000094, 12.023958207000135],
              [15.043102264000026, 12.021207810000021],
              [15.046127319999982, 12.016929627000025],
              [15.050005912000131, 12.012193680000109],
              [15.052875519000111, 12.006769181000095],
              [15.055590629000051, 12.003254891000097],
              [15.059391022, 11.999664306000113],
              [15.063657762000105, 11.998594283000102],
              [15.068158150000102, 11.998439789000145],
              [15.073743820000061, 11.99859142300005],
              [15.07738971800012, 11.999125481000021],
              [15.081113815000151, 11.998665809000158],
              [15.083499909000011, 11.998664856000175],
              [15.085458756000037, 11.998664856000175],
              [15.086524011000165, 11.998385429000052],
              [15.086870194000085, 11.99829483100001],
              [15.086969376000127, 11.998136522000152],
              [15.088405609000063, 11.995837211999969],
              [15.089258194000138, 11.993621825000105],
              [15.088965415000189, 11.991936684],
              [15.088521957000069, 11.989386559000138],
              [15.088248252000085, 11.98781681100013],
              [15.087421418000076, 11.985000611000146],
              [15.087082862000102, 11.983845712000061],
              [15.086383819000105, 11.981019975000152],
              [15.084124566000128, 11.97853469800009],
              [15.082426070999986, 11.976666450999971],
              [15.081184388000054, 11.975701333000075],
              [15.077615738000191, 11.972925186000168],
              [15.075443267000082, 11.971550941000032],
              [15.072728157000142, 11.971780777000049],
              [15.070598602000075, 11.973778725000159],
              [15.06862545000007, 11.975253105000093],
              [15.066058159000022, 11.976671219000025],
              [15.062256813999966, 11.977283477000071],
              [15.06047248800013, 11.977283477000071],
              [15.057834626000044, 11.976444245000096],
              [15.055429459000038, 11.97453498800013],
              [15.054110526999978, 11.972625734000076],
              [15.05209255200009, 11.969722748000038],
              [15.049764633000109, 11.964376449999975],
              [15.048911096000097, 11.962162018000072],
              [15.048522950000176, 11.959946632000083],
              [15.048820496000076, 11.958039283000119],
              [15.050385475000155, 11.956498146000058],
              [15.05235862699999, 11.954421043000082],
              [15.054263114000094, 11.952209472000106],
              [15.055623055000069, 11.949997901000188],
              [15.057392121000078, 11.94798851000013],
              [15.059228897000025, 11.945240975000047],
              [15.061609268000041, 11.941153527000097],
              [15.063309670000081, 11.937870025],
              [15.064574242000049, 11.935652734000143],
              [15.064587593, 11.935370446000036],
              [15.064728737000166, 11.932368280000162],
              [15.064106941000034, 11.928397179000115],
              [15.062166214000115, 11.922515868000119],
              [15.059993743000064, 11.918773651000038],
              [15.05879878900015, 11.917168618],
              [15.058287620000044, 11.916481973000145],
              [15.057669639000153, 11.916069984000046],
              [15.055882455000074, 11.914878845000032],
              [15.053012848000037, 11.914649963999977],
              [15.050143241, 11.914115907000109],
              [15.049289703999989, 11.913919448000115],
              [15.048466682000139, 11.913730622000116],
              [15.047816277000038, 11.913580895000109],
              [15.045256614999971, 11.912206649999973],
              [15.043473244000154, 11.910908699000174],
              [15.041456222000079, 11.908693313000185],
              [15.040369988000066, 11.906707765000021],
              [15.03990459400012, 11.905103684999972],
              [15.039826393000112, 11.902888298000107],
              [15.040213586000107, 11.899909974000025],
              [15.041053772000168, 11.89506340000014],
              [15.042413711000165, 11.889636040000141],
              [15.043501855000102, 11.88608551100009],
              [15.044794083000113, 11.882735252000089],
              [15.046697617, 11.879652977000035],
              [15.048193932000117, 11.877039910000121],
              [15.049282072999972, 11.87456131100015],
              [15.049757958000043, 11.8720149990001],
              [15.05044555700016, 11.86927795400004],
              [15.049436570000069, 11.866909980000059],
              [15.048893928000098, 11.865000726000176],
              [15.048117638, 11.863549233000072],
              [15.048117638, 11.860952378000036],
              [15.048599244000059, 11.858347894000019],
              [15.050230980000094, 11.854193687000134],
              [15.052269936000016, 11.850240707000069],
              [15.05417442300012, 11.847694397000055],
              [15.056758881000121, 11.845281602],
              [15.058934211000121, 11.843204499000024],
              [15.06185913000013, 11.841327666999973],
              [15.063853264000102, 11.83979034400005],
              [15.066024780000191, 11.839331628000139],
              [15.068040847000077, 11.840476989000138],
              [15.068995477000044, 11.841460228000017],
              [15.069746972000132, 11.842233659000158],
              [15.071220399000083, 11.843531609000081],
              [15.073081971000136, 11.845593453000163],
              [15.075951576000136, 11.848953246000065],
              [15.078589438999984, 11.851550103000079],
              [15.079442979000078, 11.854604721000044],
              [15.07998561900007, 11.856285095000146],
              [15.080606461000059, 11.857048988000031],
              [15.081614494000064, 11.856972694000092],
              [15.082157136000035, 11.855291366000074],
              [15.083015442000033, 11.853382111000144],
              [15.084239006000189, 11.85197448800011],
              [15.086652757000138, 11.850020408999967],
              [15.089676856000153, 11.849102021000022],
              [15.093475342000033, 11.847115517000134],
              [15.095490456000107, 11.845968246000155],
              [15.096275330000083, 11.84506988600009],
              [15.097498893000136, 11.842689515000018],
              [15.097662925, 11.842370988000084],
              [15.097738266000135, 11.842225076000091],
              [15.097194671000182, 11.839780809000047],
              [15.09707069500007, 11.83900833199999],
              [15.09696102200013, 11.83832931600017],
              [15.097270965000121, 11.836877823000066],
              [15.098019600000157, 11.835804940000173],
              [15.098976135000157, 11.834433556000022],
              [15.098509788, 11.830920219000063],
              [15.097931863000099, 11.828220367000142],
              [15.097887992999972, 11.828017235],
              [15.097887038000181, 11.825267791000101],
              [15.098350525000058, 11.820378304000087],
              [15.098038673000019, 11.815948487000128],
              [15.098347665, 11.81304550100009],
              [15.099354744000095, 11.810372353000048],
              [15.100671769000087, 11.808767318000037],
              [15.104083061000097, 11.806812286000081],
              [15.106462478000083, 11.80446624700005],
              [15.108229638000068, 11.801651955000068],
              [15.109860420000189, 11.799239159000138],
              [15.111627579000128, 11.796759605000148],
              [15.113191605000054, 11.79548549600014],
              [15.115241051000169, 11.793867112000157],
              [15.117567063000024, 11.793483734000063],
              [15.119111061000069, 11.793313981000153],
              [15.119659424000076, 11.793253899000149],
              [15.12136554599999, 11.792565346000117],
              [15.122369766000133, 11.79159641300015],
              [15.122508049000146, 11.791250230000173],
              [15.123117448000187, 11.789719583000078],
              [15.124695777000113, 11.78637695399999],
              [15.124494552999977, 11.786002159000191],
              [15.122600556000066, 11.782482147000053],
              [15.122028352000143, 11.781250001000103],
              [15.121359826000059, 11.779808999000068],
              [15.119037629000047, 11.778636933000087],
              [15.118335724000133, 11.778283120000026],
              [15.117099761000077, 11.77756977100006],
              [15.116086959000143, 11.77698516900017],
              [15.115147590000163, 11.776410102000114],
              [15.112597465000079, 11.774847984000189],
              [15.109573364000084, 11.773092269000131],
              [15.105464936000146, 11.772330283000144],
              [15.102758407000124, 11.77244949400017],
              [15.101976395000065, 11.772483825000052],
              [15.098876000000132, 11.772713661000068],
              [15.095697403000088, 11.771645546000059],
              [15.092596053000022, 11.770042419000049],
              [15.091193200000191, 11.769203187000073],
              [15.088642120000145, 11.767676353000184],
              [15.08725357000003, 11.766711235000116],
              [15.086006164000025, 11.765843392000136],
              [15.084274292999964, 11.764138222000099],
              [15.084145547000162, 11.764011383000025],
              [15.082749367000133, 11.761261940000168],
              [15.082647324000106, 11.761122703000069],
              [15.081741334000128, 11.759886742000049],
              [15.081973075000178, 11.758130073000075],
              [15.082293510000113, 11.75707721800012],
              [15.082437515000038, 11.756602288000067],
              [15.083460807000165, 11.75429248800009],
              [15.085364342000048, 11.752348900000072],
              [15.086996078000027, 11.751477241000146],
              [15.089443207000102, 11.749869346000082],
              [15.091754913000045, 11.748528480000118],
              [15.094133377000048, 11.747120857000084],
              [15.096445083000162, 11.74557876700004],
              [15.098075867000091, 11.744037628000171],
              [15.099367142000062, 11.742160797000054],
              [15.101269722000097, 11.73753738500011],
              [15.103307725000093, 11.732779503000131],
              [15.103436471000066, 11.732569695000166],
              [15.105073930000117, 11.729898452000043],
              [15.105350495000152, 11.729207992999989],
              [15.106067656999983, 11.727415086000065],
              [15.106229782000185, 11.726103782000109],
              [15.106454850000148, 11.724283219000029],
              [15.105568886000015, 11.724145890000102],
              [15.103508949, 11.723825455000167],
              [15.102292060000138, 11.724133491999964],
              [15.100486755000077, 11.724591256],
              [15.098247528000172, 11.725521087000118],
              [15.097541810000052, 11.725813866000124],
              [15.096555711000065, 11.726145744],
              [15.094132423000133, 11.726961137000103],
              [15.092599868000036, 11.727565766000112],
              [15.091032027000097, 11.728183747000173],
              [15.088782311000102, 11.728923797000107],
              [15.086382867, 11.729713441000058],
              [15.080647468, 11.730860710000059],
              [15.07847690599999, 11.730174065000028],
              [15.07731437699999, 11.72918128900011],
              [15.07630634200018, 11.727806092000094],
              [15.074445725000146, 11.72566795400013],
              [15.073437690000162, 11.723529816000109],
              [15.071897508000063, 11.721608162000109],
              [15.069948196999974, 11.719176292000157],
              [15.068701743000076, 11.717504502000054],
              [15.066305161, 11.714288711000165],
              [15.06565761600018, 11.712558747000173],
              [15.065218925000011, 11.711386680000089],
              [15.064520835000167, 11.709476471000073],
              [15.064908028000161, 11.707643509000093],
              [15.065450668000096, 11.705886842000155],
              [15.066379547000111, 11.70390033699999],
              [15.067386627000133, 11.702372550000177],
              [15.067581175999976, 11.702148437000176],
              [15.068897247000109, 11.700631141000031],
              [15.069314002, 11.700083733000042],
              [15.069916725000041, 11.699291230000142],
              [15.071958541000072, 11.697788238000101],
              [15.07264232600005, 11.695666313000117],
              [15.073041916000079, 11.694426537000027],
              [15.073893547000125, 11.691829681000115],
              [15.074513435000085, 11.690224648000083],
              [15.075443267000082, 11.688773154999978],
              [15.07652759500013, 11.687474252000072],
              [15.078464508000025, 11.686557769000046],
              [15.079708099000186, 11.68650341100016],
              [15.079974174000085, 11.686491966000176],
              [15.080766678000089, 11.685637473999975],
              [15.08133125300003, 11.685029030000123],
              [15.08194637400004, 11.683347701000173],
              [15.08233833200012, 11.68227863300018],
              [15.081640244000084, 11.679452896000043],
              [15.080476760000124, 11.677008629000056],
              [15.078461647999973, 11.674411775000124],
              [15.078086853000173, 11.672857285000134],
              [15.077762603000167, 11.671509744000105],
              [15.07737445700019, 11.669065477000061],
              [15.076754570000162, 11.666926384000078],
              [15.076831817000027, 11.665322304000085],
              [15.076976776000038, 11.663846970000066],
              [15.077993393000042, 11.662496567000119],
              [15.080162047999977, 11.660967827000093],
              [15.082098961000099, 11.660279275000164],
              [15.083648681000113, 11.659744263000107],
              [15.085120200000063, 11.658521653000037],
              [15.086437225000054, 11.656306267000048],
              [15.086863517000154, 11.65543937800004],
              [15.087676048000162, 11.653785706000122],
              [15.087814331000118, 11.653427124000132],
              [15.088527680000084, 11.651569366999979],
              [15.088526725000122, 11.648819923000076],
              [15.0872974400001, 11.648214340000152],
              [15.086201668, 11.647674562000077],
              [15.08387756400009, 11.646987915000182],
              [15.083246232000079, 11.646366120000152],
              [15.082559585000013, 11.645689964000155],
              [15.082012177000024, 11.64515018600008],
              [15.081552505000047, 11.644697190000102],
              [15.080434798000169, 11.643077851000157],
              [15.080234528000119, 11.642787933000136],
              [15.080001830000072, 11.641947746000142],
              [15.079691885999978, 11.640648843000065],
              [15.079846383000074, 11.639808656000071],
              [15.080543518000127, 11.638280869000084],
              [15.082845688000191, 11.637035371000024],
              [15.08294487, 11.636981964000142],
              [15.085346223000045, 11.63537693100011],
              [15.085996628000089, 11.634657860000061],
              [15.087230682000097, 11.633293153000011],
              [15.088908195000045, 11.630716325000151],
              [15.089269637000143, 11.628396988000191],
              [15.089372634000028, 11.62773704500006],
              [15.088984489000154, 11.624987602000033],
              [15.088922501000184, 11.624215125000148],
              [15.088750840000102, 11.622084618000144],
              [15.087821007000173, 11.620786667000118],
              [15.087566375000051, 11.619826317000104],
              [15.088827133999985, 11.617731094000021],
              [15.091073036000012, 11.616583825000021],
              [15.093319892000125, 11.615132332000087],
              [15.095875739000121, 11.613832474000048],
              [15.09843158700005, 11.612074851000159],
              [15.100203513999986, 11.610844613000154],
              [15.101917267000147, 11.608789445000127],
              [15.102637291, 11.607203482999978],
              [15.102923394000186, 11.606574059000138],
              [15.103465080000035, 11.604052543000023],
              [15.10313510899999, 11.602351188000057],
              [15.102843285, 11.600844382999981],
              [15.10284233100009, 11.59712505300007],
              [15.10284233100009, 11.596872329000121],
              [15.102066039000022, 11.592213631000163],
              [15.102136612000095, 11.589704513000015],
              [15.102218629000049, 11.586790084000029],
              [15.102140427000109, 11.584269523000103],
              [15.102449417000116, 11.582435607999969],
              [15.102762222000138, 11.580784797999968],
              [15.102816582, 11.580501557000105],
              [15.102843285, 11.580359460000125],
              [15.104307174000098, 11.578310014000067],
              [15.106549264000137, 11.577203749999967],
              [15.106785775000105, 11.577087402000132],
              [15.10957431800017, 11.576475143000039],
              [15.111897468000166, 11.575481414000137],
              [15.113756180000109, 11.574792861999981],
              [15.116931916000169, 11.573721886000044],
              [15.120572091000156, 11.572574615000065],
              [15.123747825, 11.571122170000024],
              [15.127620697, 11.570662498000047],
              [15.128608703000054, 11.570209504000047],
              [15.129336357000057, 11.569875717],
              [15.129789353000035, 11.569667817000038],
              [15.13054943100002, 11.569303512000033],
              [15.132344246000116, 11.568445207000138],
              [15.13505458800006, 11.566228868000167],
              [15.138985634000051, 11.56413078200012],
              [15.142245292000098, 11.561583520000056],
              [15.143011093000155, 11.560783385000036],
              [15.14455413800016, 11.559169769999983],
              [15.146513939000045, 11.557056428000124],
              [15.146821976000069, 11.554306031000181],
              [15.14480686200011, 11.551557541000136],
              [15.143566131000057, 11.549036980000039],
              [15.14310073900009, 11.547891616000129],
              [15.14310073900009, 11.547053337000136],
              [15.14310073900009, 11.546287536000079],
              [15.143013, 11.545383454000103],
              [15.142944337000131, 11.544683456000087],
              [15.143407821000096, 11.541704179000021],
              [15.143174171000112, 11.539183618000095],
              [15.142320633, 11.53666305500019],
              [15.141822815000069, 11.534648896000078],
              [15.141622542999983, 11.533837318000053],
              [15.14157295300015, 11.53180885300003],
              [15.14154338899999, 11.530629157000135],
              [15.140844346000165, 11.527344703000153],
              [15.139483452000036, 11.525691033000101],
              [15.138520240000105, 11.524519920999978],
              [15.138019561000135, 11.523903846999985],
              [15.136659623000071, 11.52222919400009],
              [15.13433551900016, 11.520778656000175],
              [15.134000778000086, 11.519746780000048],
              [15.133715630000097, 11.518869401000188],
              [15.132707595000113, 11.517265320999968],
              [15.132772446000047, 11.516468049000082],
              [15.132862091999982, 11.515356064],
              [15.133265496000149, 11.513691903000108],
              [15.133325577000051, 11.513445855000157],
              [15.133711815000026, 11.511612891000027],
              [15.13487243600008, 11.509550095000066],
              [15.134171486000128, 11.508059502000094],
              [15.133399962999988, 11.506418227999973],
              [15.130854606000185, 11.505261421000114],
              [15.128364564000037, 11.504129410000132],
              [15.126870156000166, 11.503502846000174],
              [15.124724388000118, 11.502602577000062],
              [15.120928765000031, 11.501476289000095],
              [15.11907005300003, 11.50092506500016],
              [15.115662574000112, 11.499704361000056],
              [15.113415718, 11.498101235000092],
              [15.111324310000157, 11.497108459],
              [15.110943795000026, 11.496961594000027],
              [15.109542847000114, 11.496421814000144],
              [15.108953477, 11.495375632999981],
              [15.10876846300016, 11.495047570000111],
              [15.108613014000184, 11.494053841000039],
              [15.108551978000094, 11.492860795000126],
              [15.10853481200013, 11.492526054000052],
              [15.108551978000094, 11.492086411000173],
              [15.108589172000165, 11.491123199000072],
              [15.108602523000116, 11.490926742000056],
              [15.108643532000031, 11.490315438000096],
              [15.108997343999988, 11.487866401000076],
              [15.108904839000104, 11.486044883000147],
              [15.108841897000048, 11.484810830000072],
              [15.105356216000189, 11.483513832000085],
              [15.103139877000046, 11.484341621000112],
              [15.102491379000014, 11.484583855000039],
              [15.10094261200004, 11.48481369000018],
              [15.099936485, 11.484050752000087],
              [15.09861946, 11.483057976],
              [15.09792137200003, 11.481683731000089],
              [15.098540306000075, 11.479620934000025],
              [15.098758697000108, 11.479267120000088],
              [15.100089074000152, 11.47711563200005],
              [15.100853920000191, 11.476232527999969],
              [15.101558685000157, 11.475418090000119],
              [15.101665497999988, 11.473521233999975],
              [15.101713180000047, 11.47266864900007],
              [15.098924637000152, 11.470606804999989],
              [15.094897270999979, 11.468010903000163],
              [15.094799996, 11.467920304000017],
              [15.094391821999977, 11.467537881000112],
              [15.091876029000105, 11.465186118000133],
              [15.090249061000179, 11.461825371000145],
              [15.090020180000124, 11.461260796000033],
              [15.089915275000067, 11.461002350000172],
              [15.08988952600015, 11.460938454000029],
              [15.089439392000031, 11.459830285000066],
              [15.089186668000082, 11.459208488000058],
              [15.088133811000091, 11.456733704000101],
              [15.087983131999977, 11.456314087000123],
              [15.087243080000064, 11.454257966000057],
              [15.086109161000081, 11.451942445000043],
              [15.084165573000064, 11.449069023000163],
              [15.082465171000024, 11.446992874999978],
              [15.081070900000043, 11.446030618000066],
              [15.079272270999979, 11.444789887000127],
              [15.07924175200003, 11.444734574999984],
              [15.078880310000159, 11.444667816999981],
              [15.078597069000068, 11.444616317000168],
              [15.078633309000054, 11.44457340200006],
              [15.076150895000012, 11.443800926000108],
              [15.074612618, 11.443561555000144],
              [15.074208259000045, 11.442603112000143],
              [15.074207306000062, 11.441645623000056],
              [15.075259208000034, 11.440127374000099],
              [15.077120780000087, 11.438849449000145],
              [15.077767373000029, 11.437397956000041],
              [15.077792167000098, 11.437342644000069],
              [15.07787799800019, 11.437150002000124],
              [15.078010559000063, 11.436853408000104],
              [15.078035355000168, 11.436162948000174],
              [15.078090668000016, 11.43461704300006],
              [15.077037811000025, 11.432461739000075],
              [15.075015069000187, 11.432541848000085],
              [15.074961662000135, 11.432541848000085],
              [15.074298858000191, 11.432541848000085],
              [15.073314667000034, 11.432541848000085],
              [15.071615220000183, 11.431903839000142],
              [15.071372033000046, 11.430466651000017],
              [15.071775437000042, 11.427112578999981],
              [15.071451187000036, 11.424158096000042],
              [15.070155144000182, 11.420006751000017],
              [15.069608688000073, 11.418774606000113],
              [15.069022178000012, 11.417450906000056],
              [15.066350937999971, 11.416014671000141],
              [15.06626892200012, 11.415968895000049],
              [15.065055847000053, 11.415296555000054],
              [15.063275337000164, 11.414258957000186],
              [15.062946319000105, 11.413954735000061],
              [15.061980248, 11.413061143000164],
              [15.061574937000159, 11.411224365000066],
              [15.061413764000065, 11.410588265],
              [15.06116962499999, 11.409627915000158],
              [15.06116962499999, 11.408682823000106],
              [15.06116962499999, 11.407630921000134],
              [15.06143283800003, 11.406656265000095],
              [15.061816216000125, 11.405235291000167],
              [15.063110350999978, 11.402919770000153],
              [15.063050271000179, 11.401209830000028],
              [15.063029290000145, 11.400604249000139],
              [15.062739372000124, 11.400149346000035],
              [15.061653138000111, 11.398447991000069],
              [15.059719085000097, 11.397788048000109],
              [15.059548377000169, 11.397729874000049],
              [15.058982850000177, 11.397079469000175],
              [15.058577537000133, 11.396612167000058],
              [15.058465004000141, 11.395672798000021],
              [15.058415414000137, 11.395255089000045],
              [15.058981894999988, 11.39397716600007],
              [15.059871674000021, 11.392299653000123],
              [15.062136650000184, 11.389743805000023],
              [15.06343078600014, 11.388226509000049],
              [15.064401627000109, 11.386389733000158],
              [15.064816476000033, 11.385317803000078],
              [15.065453529000081, 11.383674621000011],
              [15.06674766600014, 11.381039620000024],
              [15.067307472999971, 11.378438951000078],
              [15.067555427000059, 11.377285957000083],
              [15.068312645000049, 11.375604630000112],
              [15.068526268000028, 11.375129700000116],
              [15.069241524000063, 11.373465538000062],
              [15.069445610000059, 11.372989655000026],
              [15.069522857000152, 11.372809410000116],
              [15.069658280000056, 11.372494697000093],
              [15.070223809000083, 11.369540213999983],
              [15.070473671000116, 11.368157387000053],
              [15.070628166000063, 11.367303849000166],
              [15.070660590000045, 11.366405487000065],
              [15.070708275000186, 11.365068437000104],
              [15.069180487999972, 11.363615991000188],
              [15.068523407000043, 11.362992287000111],
              [15.067695618000073, 11.362569810000025],
              [15.066176415000029, 11.361795426000128],
              [15.064679145000127, 11.36089038800003],
              [15.063667297000052, 11.360278130000154],
              [15.061159135000025, 11.35796356100002],
              [15.060782432000053, 11.357547759000113],
              [15.058406831000013, 11.354929924000146],
              [15.055332184000122, 11.352135658],
              [15.054825783000013, 11.351327896000157],
              [15.053631782000082, 11.349420548000069],
              [15.052832604000059, 11.347981453000102],
              [15.052429198000084, 11.347256660000085],
              [15.052256585000066, 11.346945763000065],
              [15.050799369000117, 11.344310760999974],
              [15.050637244000143, 11.342952728000171],
              [15.051972389000184, 11.342123986000161],
              [15.054484367000043, 11.341118812000104],
              [15.056792260000122, 11.340113640000084],
              [15.05923652700011, 11.338371278000182],
              [15.062087058000145, 11.336427689],
              [15.062435150000169, 11.336190224000177],
              [15.064228058000026, 11.334964753000122],
              [15.066007614000171, 11.333208083999978],
              [15.067162514000188, 11.33200836200001],
              [15.067544937000093, 11.33161067900005],
              [15.068170548000069, 11.330881120000186],
              [15.06843471600007, 11.330572128000028],
              [15.068743704999974, 11.33029556200006],
              [15.070504188000086, 11.328721046000169],
              [15.072558404000063, 11.327247620000094],
              [15.073045732000026, 11.326897621],
              [15.073429107000038, 11.32647323599997],
              [15.075067519000072, 11.324661256000184],
              [15.075876237000045, 11.322505952000029],
              [15.075568200000021, 11.321252823000179],
              [15.075227737000034, 11.319870949000062],
              [15.074092866000171, 11.31688404099998],
              [15.074013710000031, 11.316677094],
              [15.071100235000131, 11.314441682000165],
              [15.07009124800004, 11.313229560000025],
              [15.069239616000061, 11.312206269],
              [15.069127083000069, 11.312160492000032],
              [15.066892624000047, 11.311248780000085],
              [15.064950943000042, 11.309332848000054],
              [15.063979149000147, 11.307735443000126],
              [15.063412666000033, 11.305500030000189],
              [15.063412666000033, 11.304983139000115],
              [15.063412666000033, 11.303263665000145],
              [15.062893868000117, 11.302083970000126],
              [15.062359811000078, 11.300868988000161],
              [15.060174941000128, 11.298314094000148],
              [15.058833123000113, 11.29719352800015],
              [15.05807209000011, 11.296557426000106],
              [15.05718135800015, 11.293283463000023],
              [15.056938172000116, 11.291367531000105],
              [15.056870461000074, 11.2910203940001],
              [15.056451797000079, 11.288891792000186],
              [15.056073189000017, 11.286462783000047],
              [15.055966377000061, 11.285778046000132],
              [15.055851936000124, 11.285458566000159],
              [15.054994583000166, 11.283062933999986],
              [15.053133964000097, 11.281227113000057],
              [15.051571846000172, 11.28091812100007],
              [15.051516533000154, 11.280907631000105],
              [15.050683021000111, 11.280181884],
              [15.050140381000119, 11.279709817000139],
              [15.050221442000179, 11.278592110000091],
              [15.050302506000151, 11.277474403999975],
              [15.051111220999985, 11.276196480000124],
              [15.052162171000077, 11.274918556999978],
              [15.05379295400013, 11.273248672000136],
              [15.055171012000073, 11.272363662999965],
              [15.056507112000133, 11.271505355000045],
              [15.058882713000173, 11.269964218000155],
              [15.06037426, 11.268178939000109],
              [15.060815811000055, 11.267650604000039],
              [15.062271119, 11.265254021999965],
              [15.062675475999981, 11.263177872000142],
              [15.062674523000169, 11.259824753000089],
              [15.062997818000042, 11.257827759000065],
              [15.063321114000132, 11.256390572000043],
              [15.064695358000165, 11.253834724000171],
              [15.065746307000154, 11.251519203000157],
              [15.067201615000101, 11.248804093000047],
              [15.067373276000012, 11.247616768000114],
              [15.067605972000081, 11.246008873000051],
              [15.067215919000034, 11.243573189000131],
              [15.06711959900008, 11.242974281000158],
              [15.067280769000092, 11.240578650999964],
              [15.066956519000087, 11.238023758000054],
              [15.0655002580001, 11.235788346000049],
              [15.064157485000123, 11.234841347000099],
              [15.063235282000107, 11.234190941000122],
              [15.060647011000128, 11.231876373000091],
              [15.060280801000033, 11.231234550000181],
              [15.059918404000143, 11.230598450000116],
              [15.060160638000127, 11.228363038000111],
              [15.060553551, 11.227996825999981],
              [15.061615944000096, 11.22700500500008],
              [15.061864853000145, 11.226094247000049],
              [15.062182425000117, 11.224928857000066],
              [15.06231403400011, 11.222316742000032],
              [15.062325477000059, 11.222079277000034],
              [15.062342643000079, 11.221734048000144],
              [15.062872887000083, 11.220069885000044],
              [15.063232422000056, 11.218939782000064],
              [15.063533783000025, 11.217152596000176],
              [15.063635826000052, 11.216544152000097],
              [15.063847541000086, 11.2158679960001],
              [15.064686775000041, 11.21319008],
              [15.065656663000027, 11.211273193000125],
              [15.066384315000164, 11.209356308000054],
              [15.066949843999964, 11.207679749000022],
              [15.066995621000103, 11.207274437000024],
              [15.067111016000183, 11.206241607000038],
              [15.067413330000136, 11.20560169200013],
              [15.067677498000137, 11.205043793000016],
              [15.068728447000126, 11.203686714000128],
              [15.069617271000141, 11.20184993800018],
              [15.069859505000124, 11.199533462000034],
              [15.070182800000111, 11.198096275000012],
              [15.070841789000042, 11.197376251000151],
              [15.071718217000068, 11.196418762000064],
              [15.07285022800005, 11.194262505000097],
              [15.073173523000037, 11.192266464000056],
              [15.073944091000044, 11.191089629000089],
              [15.074062347000051, 11.190908433],
              [15.074230195000098, 11.190501214000165],
              [15.07511329600004, 11.188352586],
              [15.076244354000039, 11.185717583000041],
              [15.076972008000041, 11.183880806000047],
              [15.077570916000184, 11.182755471000121],
              [15.077779771000166, 11.182363510000073],
              [15.078235627000026, 11.181868554000175],
              [15.079397202000109, 11.180605888000173],
              [15.082226754000146, 11.178369523000129],
              [15.083196640000097, 11.176293373000135],
              [15.084442138000099, 11.174523353000041],
              [15.084489823000069, 11.174455642000055],
              [15.085378647000027, 11.173018455000033],
              [15.085727691000159, 11.172529222000151],
              [15.086348533000034, 11.171660424000038],
              [15.086988450000092, 11.171133042000122],
              [15.087803841000152, 11.170462608000037],
              [15.089420319000169, 11.169743539000137],
              [15.09111881299998, 11.169583321000061],
              [15.09184074500007, 11.169448853000119],
              [15.092411994000145, 11.169342995000022],
              [15.093317032000073, 11.169091226000091],
              [15.095000268000092, 11.168623924000144],
              [15.096858978000057, 11.165987968000024],
              [15.096799850000139, 11.164159774999973],
              [15.09669494700006, 11.160877228000118],
              [15.096618652000018, 11.159152029999973],
              [15.096613884000021, 11.15904045200017],
              [15.096649170000092, 11.158843041000068],
              [15.096856117000073, 11.157683374000158],
              [15.097987175000014, 11.155207635000011],
              [15.09854221400002, 11.154255868000064],
              [15.099199295000119, 11.153130531000102],
              [15.100001335000059, 11.151875497000049],
              [15.100169183000105, 11.151613237000163],
              [15.100734712000076, 11.14937687000014],
              [15.100266457000146, 11.147149085000137],
              [15.100248337000096, 11.147061348000022],
              [15.099924087999966, 11.144665719000159],
              [15.100239753999972, 11.142894746000081],
              [15.100408554000069, 11.141950607000069],
              [15.100233078000144, 11.141113282000106],
              [15.099841117000153, 11.139236450000112],
              [15.099516869000126, 11.137000083999965],
              [15.09926891300006, 11.135775567000167],
              [15.099193572000104, 11.13540363200002],
              [15.099031449000108, 11.133486748000053],
              [15.098707199000103, 11.131969452000135],
              [15.098707199000103, 11.131708146000165],
              [15.098706244000084, 11.130754472000149],
              [15.098706244000084, 11.130532264000067],
              [15.099319458000025, 11.128814698000042],
              [15.09967613200007, 11.127817154000127],
              [15.099517822000109, 11.127161026000181],
              [15.099270821, 11.126140595000038],
              [15.099229813000022, 11.125059127999975],
              [15.099189759000069, 11.123984337000138],
              [15.098988533000124, 11.123229982000169],
              [15.098784448000174, 11.12246704099999],
              [15.098784448000174, 11.121677399000021],
              [15.098784448000174, 11.120710374000055],
              [15.099121094000168, 11.119710922000138],
              [15.099430083000072, 11.118793488999984],
              [15.09926796000002, 11.117116928000087],
              [15.09925556200011, 11.116878509000173],
              [15.099186896999981, 11.115599632000169],
              [15.099469185000089, 11.114439965000031],
              [15.099751472999969, 11.113284111000155],
              [15.099993705000145, 11.111526489000028],
              [15.098215104000133, 11.109930039000062],
              [15.098299027000053, 11.109013558000186],
              [15.098375321000162, 11.108173371000021],
              [15.098557472000039, 11.106910705000189],
              [15.098698616000036, 11.105937959000187],
              [15.099426269999981, 11.10443401399999],
              [15.09958744, 11.104100228000107],
              [15.100233078000144, 11.102104187000066],
              [15.100347519000081, 11.100502967000068],
              [15.100500106000027, 11.098365784000066],
              [15.100900650000142, 11.098063470000113],
              [15.101653100000021, 11.097494126000072],
              [15.103687287000014, 11.095617295000125],
              [15.106129647000159, 11.094343186000174],
              [15.107485771000086, 11.093873979000136],
              [15.108146667000085, 11.093463897000163],
              [15.109284400000149, 11.092758178999986],
              [15.111128806000181, 11.089036942000064],
              [15.111303329000123, 11.088685036000072],
              [15.112029076000056, 11.084611892],
              [15.111862182999971, 11.084560395000096],
              [15.109684944000037, 11.083893776000139],
              [15.109280587000114, 11.082456589000117],
              [15.108794212000078, 11.079742431999989],
              [15.107731820000083, 11.079031945000111],
              [15.10628795700012, 11.078065873000128],
              [15.104589461999979, 11.076708794000183],
              [15.104249954000124, 11.076275826000028],
              [15.103214263000154, 11.074953080000057],
              [15.103214263000154, 11.073641778000081],
              [15.103214263000154, 11.073196412000186],
              [15.103733062000174, 11.07194232900008],
              [15.103940964000174, 11.071438790000059],
              [15.103858948000095, 11.068564414999969],
              [15.102702141000066, 11.065809251000132],
              [15.1024837490001, 11.065290452000056],
              [15.098845481000183, 11.062336921000053],
              [15.096420287000058, 11.06090068900005],
              [15.096343041000068, 11.060035706000178],
              [15.096257210000147, 11.059063912000056],
              [15.097016334999978, 11.057517052000151],
              [15.097550392000016, 11.05642890900009],
              [15.097901344000149, 11.055039406000105],
              [15.098114967000129, 11.054192544000045],
              [15.097580909000158, 11.051560401000131],
              [15.097467423000182, 11.050998687000174],
              [15.097335815000122, 11.049619675000145],
              [15.097224235000169, 11.048443795000026],
              [15.097669601000064, 11.046920776000036],
              [15.098112106000144, 11.045408250000094],
              [15.096489906000045, 11.044296265000014],
              [15.095201492, 11.043413162000036],
              [15.0949020380001, 11.043422700000121],
              [15.092696190000026, 11.043494225000074],
              [15.088412284000128, 11.042696953000018],
              [15.085790634000148, 11.042652131000068],
              [15.083805084000176, 11.042617797000048],
              [15.082754134000083, 11.041739463000056],
              [15.082865715000139, 11.041380882000112],
              [15.083399772000178, 11.039663316000144],
              [15.083560944, 11.03798675500002],
              [15.083385469000177, 11.036879540000029],
              [15.083156586000143, 11.035430908000023],
              [15.083883285000127, 11.033354760000066],
              [15.084044457000175, 11.031278611000118],
              [15.080891609000048, 11.029762269000059],
              [15.080538750000073, 11.02954578399999],
              [15.078547477000086, 11.028326035000077],
              [15.077818870000101, 11.027750015000038],
              [15.076930999000069, 11.027048112000102],
              [15.075114251000059, 11.024885178000034],
              [15.074181557000145, 11.023775101000126],
              [15.070948601000168, 11.023456574000136],
              [15.06884765500007, 11.023696900000118],
              [15.065776826000103, 11.024176598000054],
              [15.06367778800012, 11.024335860000065],
              [15.06157398199997, 11.02449607799997],
              [15.058752060000074, 11.024378777000152],
              [15.05777549600009, 11.024337768000066],
              [15.054945946000089, 11.023220063],
              [15.05066204000002, 11.021144868000022],
              [15.048561097, 11.019468307000125],
              [15.047994613000071, 11.016912460000128],
              [15.047670365000045, 11.014916421000066],
              [15.047347068000022, 11.013559342000121],
              [15.048155785000063, 11.012600898000073],
              [15.049610138000048, 11.011403084000051],
              [15.0501251230001, 11.011248589000161],
              [15.052286149000054, 11.01060008900015],
              [15.054201126000123, 11.008766175000119],
              [15.05470085200011, 11.008287431000099],
              [15.054819108000117, 11.007670402000144],
              [15.055266381000081, 11.005332947000113],
              [15.051466941000115, 11.002458573000126],
              [15.051064492000137, 11.002265931000181],
              [15.04863834300005, 11.001101494000181],
              [15.047668457000043, 10.999185562000093],
              [15.048188209000102, 10.997756957000149],
              [15.048393249000185, 10.99778652100008],
              [15.048202515000014, 10.99771881099997],
              [15.04839515700013, 10.99718856800007],
              [15.050496102000182, 10.9943132410001],
              [15.052758216000086, 10.992636680000032],
              [15.054779053000061, 10.991278649000037],
              [15.057525635000104, 10.989122390000091],
              [15.059189797000158, 10.987829209000097],
              [15.059432029000106, 10.987641336000081],
              [15.059500694, 10.987587928000096],
              [15.059787750000169, 10.987364770000113],
              [15.062777518000075, 10.984009743000058],
              [15.064877509000041, 10.980096817000174],
              [15.065280915000187, 10.976822854000091],
              [15.06439113600004, 10.972590446000027],
              [15.06437015500012, 10.971672058000024],
              [15.064310075000151, 10.968997002000037],
              [15.064616204000174, 10.968239784000104],
              [15.065116883000087, 10.967000961000167],
              [15.065682412000115, 10.965244294000058],
              [15.067055701000129, 10.963886260000152],
              [15.069398881000041, 10.961969375000081],
              [15.071434021000186, 10.960536958000091],
              [15.072754860000146, 10.95960712499999],
              [15.074378967000143, 10.95829773000014],
              [15.07533073400009, 10.957530022000185],
              [15.077215195000178, 10.95605945599999],
              [15.07998180400017, 10.953901292000126],
              [15.083778382000048, 10.949987411000052],
              [15.084989549, 10.945834159000128],
              [15.085311889000025, 10.942560196000045],
              [15.085794449000161, 10.93569278699999],
              [15.086069107000185, 10.933621407000032],
              [15.086440087000142, 10.930821418000107],
              [15.086600304000171, 10.926988601000119],
              [15.086661340000035, 10.926321983000037],
              [15.086841583000137, 10.92435264500017],
              [15.087002753000149, 10.922915458000034],
              [15.087787628000171, 10.91981125],
              [15.087890625000057, 10.919402123],
              [15.088374138000177, 10.915568351000047],
              [15.089261054000019, 10.908940315],
              [15.089421272000152, 10.905666352000139],
              [15.089648247000184, 10.903327942000089],
              [15.089824676000148, 10.901514053000028],
              [15.090873717000136, 10.897521020000113],
              [15.092052460000048, 10.894539833000124],
              [15.092326165000088, 10.893847466000125],
              [15.092588425000088, 10.893342019000102],
              [15.093941688000086, 10.89073181200007],
              [15.09620094200011, 10.882666589000053],
              [15.096748352000134, 10.879458429000124],
              [15.097167969000111, 10.876996041000154],
              [15.097004890000051, 10.872124673000144],
              [15.096834184000102, 10.870776177000153],
              [15.09643840800004, 10.867653847999975],
              [15.096070290000171, 10.864394188000119],
              [15.096033096000099, 10.864060402],
              [15.093446732000132, 10.860627174000172],
              [15.092671394000092, 10.859661103000064],
              [15.091588973000171, 10.858311653000158],
              [15.087952613000027, 10.855677604999983],
              [15.087817192000102, 10.855581285000028],
              [15.083589552999968, 10.852564811000036],
              [15.081562996000116, 10.850443839000036],
              [15.080842972000028, 10.849690437000049],
              [15.078499795000027, 10.845858573999976],
              [15.077367782000067, 10.842424393000158],
              [15.075901986000133, 10.839621545000057],
              [15.074862480000093, 10.837634087000083],
              [15.074219704000029, 10.834551812000086],
              [15.073730469000111, 10.832203865000054],
              [15.073406219000105, 10.826055527000108],
              [15.072769164000078, 10.82252693099997],
              [15.072354317000134, 10.820226669000078],
              [15.069972993000079, 10.817006112000172],
              [15.069284438000068, 10.81607437100007],
              [15.069375992000175, 10.815575600000102],
              [15.069767953, 10.813439370000083],
              [15.07154464700011, 10.81104278600003],
              [15.073885918000087, 10.807847976],
              [15.074485779000099, 10.8060388560001],
              [15.075406075000046, 10.803264618000071],
              [15.075890540000046, 10.801759720000064],
              [15.076737405000074, 10.799132347000182],
              [15.077467919000128, 10.796857833000104],
              [15.077759743000115, 10.795948983000017],
              [15.078770638000151, 10.792475700000182],
              [15.080502511000191, 10.786525727000026],
              [15.080880164000064, 10.785965920000137],
              [15.082763672000112, 10.783170700000142],
              [15.084942817000069, 10.779736519000096],
              [15.086295128000131, 10.777818681000042],
              [15.086328507000133, 10.777770044000022],
              [15.08720111800011, 10.776534081000023],
              [15.087364198000103, 10.776302338000107],
              [15.089044571000159, 10.77363491],
              [15.090527533999989, 10.771279335000088],
              [15.09057045000003, 10.771211624000046],
              [15.090835571000184, 10.770791054000085],
              [15.092232704000082, 10.7681722640001],
              [15.09309482599997, 10.766557693000038],
              [15.093888283000183, 10.764844895000067],
              [15.095758438000075, 10.760807990999979],
              [15.097856522000143, 10.756654739000112],
              [15.099066734000075, 10.753220558000066],
              [15.099351882000065, 10.751050949000046],
              [15.099549294000042, 10.749547004000078],
              [15.100032806000115, 10.746512414000165],
              [15.10132408200019, 10.743876457999988],
              [15.101566315000071, 10.743262292000168],
              [15.102087020000056, 10.741941452000106],
              [15.102615357000161, 10.740601540000114],
              [15.10422897300009, 10.738365173000091],
              [15.10770034799998, 10.735170365000045],
              [15.111816406, 10.730776786999968],
              [15.114156723000121, 10.727741242000093],
              [15.117061614000079, 10.723986626000112],
              [15.119320870000138, 10.720232963000115],
              [15.12263012, 10.715919495000094],
              [15.124243736000096, 10.714161872999966],
              [15.125858307000044, 10.712244988000066],
              [15.126092910000182, 10.712087631000145],
              [15.127876281, 10.710886002000109],
              [15.130926132000013, 10.709202766000089],
              [15.132800102000033, 10.7081689850001],
              [15.13312721200009, 10.707976341000119],
              [15.136189461000072, 10.706171036000057],
              [15.138448714000049, 10.702816009],
              [15.138850213000126, 10.698743821000051],
              [15.138632775000076, 10.695960998000089],
              [15.13860702400018, 10.695629120000035],
              [15.13755607700017, 10.691476823000073],
              [15.137748719000115, 10.689043046000052],
              [15.137796401000116, 10.688442230000078],
              [15.138232231000131, 10.686453820000054],
              [15.138602256000127, 10.68476867600009],
              [15.13894653300008, 10.682822227000031],
              [15.139407159000029, 10.680216790000031],
              [15.140615463000131, 10.674067497000067],
              [15.141339302000119, 10.667757989000108],
              [15.142226219, 10.665522576000171],
              [15.143435478000185, 10.663206100000139],
              [15.144403458000113, 10.660889627000074],
              [15.145693779000055, 10.658653260000051],
              [15.148921012000187, 10.65497780000004],
              [15.149700163999967, 10.654043198000181],
              [15.151583672000015, 10.651782990000186],
              [15.154568672000096, 10.648427009999978],
              [15.156746864000013, 10.645950317000143],
              [15.157511712000087, 10.642310144000135],
              [15.157954216000121, 10.64020061500014],
              [15.157791138000107, 10.637085914000068],
              [15.158191681000119, 10.631176949000121],
              [15.158350944000063, 10.627023697000027],
              [15.158104897000044, 10.619277954000097],
              [15.157719612000051, 10.616377831000079],
              [15.157214166000131, 10.612570763000178],
              [15.155842781000104, 10.60956001400001],
              [15.155032158000097, 10.607780456000057],
              [15.152527809000105, 10.602990151000085],
              [15.149134635000166, 10.595884323000121],
              [15.147519112000168, 10.592211724000094],
              [15.147089959000084, 10.590224266000178],
              [15.146709441000041, 10.588459015000012],
              [15.145806314000026, 10.582961083000043],
              [15.145738601, 10.582550049000133],
              [15.14518737700007, 10.579285623000033],
              [15.14500045800014, 10.578249932000062],
              [15.144952774000103, 10.577984810000146],
              [15.14471817000009, 10.576745034000055],
              [15.144201279000185, 10.57400608100005],
              [15.144201279000185, 10.573645591000059],
              [15.144199371000013, 10.570971489000101],
              [15.144577981000111, 10.568233490000125],
              [15.14476299200004, 10.566899300000046],
              [15.145115852000117, 10.563668250999967],
              [15.145164489000138, 10.563225746000057],
              [15.14521789600019, 10.562698365000017],
              [15.145727158000057, 10.557635308000044],
              [15.145870209000066, 10.554554939000184],
              [15.145977974, 10.552232743000047],
              [15.146015166000097, 10.551438333000078],
              [15.14604663800003, 10.550766944000031],
              [15.146044730000028, 10.54711914100011],
              [15.146044730000028, 10.546534538000117],
              [15.145768165000163, 10.543275833000052],
              [15.14563846599998, 10.541743279000116],
              [15.146121026000117, 10.538069723999968],
              [15.146604539000066, 10.536393165999982],
              [15.147974968000085, 10.53391647400008],
              [15.15007209800001, 10.531280518000131],
              [15.152249336000068, 10.529682159000117],
              [15.155556678000153, 10.526965141],
              [15.15651226000017, 10.526202203000139],
              [15.158057213000177, 10.524968148000085],
              [15.161283494000031, 10.523209573000145],
              [15.162123681000025, 10.522641180999983],
              [15.164590836000116, 10.52097129800012],
              [15.165842055000041, 10.520112992000179],
              [15.168785094999976, 10.518095016000188],
              [15.172172547000116, 10.515458107000029],
              [15.172598839000045, 10.515051842999981],
              [15.174349784000071, 10.513380050000137],
              [15.177252770000109, 10.511062621000178],
              [15.17983341300004, 10.509384155000021],
              [15.182737350000025, 10.507625580000081],
              [15.184592246000022, 10.506426811000097],
              [15.187415123000108, 10.504828454000119],
              [15.192336082000054, 10.503626823000047],
              [15.196531296000046, 10.503066064000109],
              [15.201372147000086, 10.502264024],
              [15.203872680000131, 10.501304626000035],
              [15.206292153000049, 10.500185014000124],
              [15.20814705000015, 10.498906136000187],
              [15.209839822000049, 10.49722766800005],
              [15.210424424000109, 10.496759416000032],
              [15.210658073000161, 10.49657154200014],
              [15.213227273000086, 10.494510651000041],
              [15.216372490000026, 10.492831230000093],
              [15.219598769000072, 10.491151810000019],
              [15.222662925000066, 10.488834382000164],
              [15.225564956000142, 10.485877038000069],
              [15.228466987000161, 10.483320236000054],
              [15.23201465600016, 10.479563713],
              [15.234997748000069, 10.477166175000093],
              [15.235474586000066, 10.476872444000037],
              [15.237336158000119, 10.475727081],
              [15.238183975000027, 10.475177765000012],
              [15.239647866000041, 10.474227905000134],
              [15.240075111000181, 10.473734857000011],
              [15.241271972000163, 10.47235107500012],
              [15.244510651000155, 10.468375206000019],
              [15.246524811000029, 10.464619637000055],
              [15.246521951000148, 10.461106300000097],
              [15.246864319000053, 10.45768261000012],
              [15.247001648000094, 10.456315041000039],
              [15.247643471, 10.452161789000115],
              [15.24820709200003, 10.450324060000014],
              [15.248456001000079, 10.449500084000078],
              [15.249414444000081, 10.44633102500012],
              [15.250701904000039, 10.442496300000187],
              [15.251189232000058, 10.441044808000186],
              [15.251667022000106, 10.439620971000068],
              [15.252262115000065, 10.438322068000161],
              [15.25230693900005, 10.438223838],
              [15.252875328000073, 10.436985015000118],
              [15.253446580000116, 10.436135292000017],
              [15.254163741000013, 10.435067177000064],
              [15.256052017000115, 10.433662414000082],
              [15.25642109, 10.433388711000077],
              [15.25667095099999, 10.433141710000143],
              [15.260128976000033, 10.429712296000105],
              [15.263619421999977, 10.426403046000189],
              [15.26560974, 10.424516678000089],
              [15.26794624200005, 10.4218797690001],
              [15.268694877000087, 10.421137810000118],
              [15.269495010000128, 10.420344353000075],
              [15.269792556000084, 10.420048714000075],
              [15.269881248000104, 10.419960974999981],
              [15.27157306700002, 10.418043137000097],
              [15.273507118000111, 10.416364671000167],
              [15.275215148000029, 10.415122985999972],
              [15.275925636000181, 10.414606094000021],
              [15.278102875000116, 10.413645744000178],
              [15.279569627000171, 10.412189483000191],
              [15.281124115000182, 10.411236763000034],
              [15.281407356000045, 10.411063195000111],
              [15.281537056000161, 10.410890580000114],
              [15.282974244000059, 10.408973695000043],
              [15.283155442000123, 10.40809536099999],
              [15.283404351, 10.406885147000082],
              [15.283402444000103, 10.404689789000145],
              [15.283606529000053, 10.404006957000149],
              [15.283834457000069, 10.403244019000056],
              [15.284697532000166, 10.400940896],
              [15.284771918000104, 10.400551795000069],
              [15.284776688000136, 10.400524139000083],
              [15.284890174000111, 10.399924278000128],
              [15.284961700000167, 10.399550438000063],
              [15.284967422000136, 10.399522782000133],
              [15.285074234999968, 10.39895916],
              [15.285504340000102, 10.397197723000033],
              [15.285509110000191, 10.39714050300006],
              [15.285612107000077, 10.395639420000123],
              [15.285015107000049, 10.393497467000088],
              [15.284256934000098, 10.392159462000166],
              [15.283716201000061, 10.391038894000189],
              [15.283172608000086, 10.389911651999967],
              [15.283171653000124, 10.388677596000036],
              [15.283171653000124, 10.388091088000181],
              [15.283819198000117, 10.386323929000071],
              [15.284250259000032, 10.384449958000175],
              [15.283924103, 10.383057595000139],
              [15.283878326000035, 10.382826805000036],
              [15.283597946000157, 10.381398200000092],
              [15.283488273000046, 10.3798990250001],
              [15.283659936000106, 10.378903388000083],
              [15.283811568000033, 10.378025055000137],
              [15.284080506000066, 10.376364709000086],
              [15.283988952000129, 10.375436783000055],
              [15.28391647300009, 10.374705313999982],
              [15.28304958300015, 10.372939111000164],
              [15.282958983000128, 10.372487068000112],
              [15.282662392000191, 10.370998383000142],
              [15.283142088999966, 10.369449615000065],
              [15.28330516900013, 10.368921280000166],
              [15.283142088999966, 10.367005348000077],
              [15.283195496000076, 10.366936685000155],
              [15.283947945000023, 10.365966797000056],
              [15.284993171, 10.365332605000162],
              [15.285398483000165, 10.365086556000108],
              [15.286230087000035, 10.36454773000014],
              [15.287746430000027, 10.363564492000137],
              [15.290237427000136, 10.362036705000094],
              [15.290454864000083, 10.361903191000067],
              [15.29071903200014, 10.361741066000093],
              [15.291602135000062, 10.360752105000017],
              [15.292393685000036, 10.359865189],
              [15.293449402000078, 10.358701706000147],
              [15.294824599000094, 10.357186318000174],
              [15.296551705000184, 10.35343647000002],
              [15.298007966000114, 10.349366189000136],
              [15.29724693300011, 10.347388269000078],
              [15.296977997000113, 10.346689224000102],
              [15.294650078000132, 10.343853951000028],
              [15.294755936000172, 10.341765403000068],
              [15.295076371000107, 10.337321282000175],
              [15.295993804000091, 10.335499764000076],
              [15.297143936000055, 10.334564208000131],
              [15.299289703000056, 10.33281993900016],
              [15.300035476, 10.332449914000165],
              [15.300436974000036, 10.332250595000119],
              [15.302424430000144, 10.331263543000148],
              [15.304100991000041, 10.330726623000146],
              [15.30615425000002, 10.329546928000127],
              [15.306501389000061, 10.329264642000055],
              [15.30853176100004, 10.327616692000106],
              [15.310314178000169, 10.326169967000055],
              [15.31060028100012, 10.325772286000131],
              [15.311124801000176, 10.325044632000129],
              [15.311509133000186, 10.323930740000151],
              [15.311771393000186, 10.323169709000126],
              [15.311963082000148, 10.321610451000083],
              [15.312147141000025, 10.320117951000043],
              [15.312126160000162, 10.317307472000152],
              [15.312187195000149, 10.31675148100004],
              [15.312466621000169, 10.314229012000169],
              [15.312071800000069, 10.312515260000112],
              [15.311786652000137, 10.311281204000124],
              [15.311881066000126, 10.309731484000167],
              [15.311986923000063, 10.307998658000145],
              [15.312192918000164, 10.307045936],
              [15.312205314000096, 10.306989671000053],
              [15.314216612999985, 10.305450439000083],
              [15.317784310000036, 10.305237771000066],
              [15.320572853000101, 10.305339814000092],
              [15.32137107799997, 10.30536842500004],
              [15.325650216000042, 10.305326463000142],
              [15.327273369000125, 10.305793762000178],
              [15.329287529000169, 10.304513932000134],
              [15.33035850400006, 10.303849221000121],
              [15.331866264999974, 10.302913665000176],
              [15.335978508000153, 10.302669526000102],
              [15.339363098999968, 10.30098915200017],
              [15.339944839000168, 10.299254417999975],
              [15.3402471550001, 10.298353196000051],
              [15.344437599000116, 10.296113014000127],
              [15.345574380000073, 10.295944213000098],
              [15.348226547000081, 10.295549392000055],
              [15.350950242000124, 10.294528007000167],
              [15.35249805400008, 10.293948175000139],
              [15.356204986000023, 10.292746544000124],
              [15.357028961000083, 10.291821480000124],
              [15.358057975000122, 10.290667534000022],
              [15.358564376, 10.287975312000071],
              [15.358490945000085, 10.286869047999971],
              [15.358426094000151, 10.285898210000141],
              [15.359016419000056, 10.283240318000139],
              [15.360305785000151, 10.282120705000125],
              [15.36207771300019, 10.280920982000055],
              [15.362393380000128, 10.280847551000136],
              [15.364035607000176, 10.280465126000024],
              [15.364809991000129, 10.279843331000166],
              [15.365166665000174, 10.279556275000061],
              [15.36525249400006, 10.27948761000016],
              [15.365454674000091, 10.279324532000146],
              [15.366873740000074, 10.277513505000115],
              [15.367209434000131, 10.275503158000163],
              [15.36774635400019, 10.271883966000189],
              [15.368677140000102, 10.27003574400004],
              [15.368963241000074, 10.269467355000188],
              [15.369096757000079, 10.269203184999981],
              [15.369367600000032, 10.269016266000051],
              [15.371327399000108, 10.267659188000039],
              [15.373422622000135, 10.265848160000132],
              [15.375384331000191, 10.26441574099999],
              [15.375720977000185, 10.264169693999975],
              [15.375741006000112, 10.264147758000149],
              [15.377612114000044, 10.262157440000124],
              [15.380326271000172, 10.25905323000012],
              [15.380720139000061, 10.258603096],
              [15.384235382000043, 10.256119727000168],
              [15.384335518000171, 10.255829811000126],
              [15.384549140999979, 10.255208015000051],
              [15.385111810000126, 10.253572465000047],
              [15.386713982000174, 10.251904487000104],
              [15.388453484000024, 10.250729562000117],
              [15.388494491000131, 10.250701904000152],
              [15.388727189000178, 10.250544548000107],
              [15.392756463000126, 10.249341965000156],
              [15.394643784000095, 10.248669625000105],
              [15.396199227000068, 10.248131753000052],
              [15.396518707000041, 10.247964859999968],
              [15.398768425000014, 10.246788980000019],
              [15.400774002000162, 10.245188713000061],
              [15.403227806000132, 10.243231773000161],
              [15.404347419000146, 10.24135875800016],
              [15.404597283000044, 10.240942001000064],
              [15.405527114000108, 10.238649369000086],
              [15.406605720000016, 10.235988617000032],
              [15.408132554000076, 10.232393265000042],
              [15.409433365000154, 10.229354858000136],
              [15.40936279400006, 10.226808549],
              [15.408682822000117, 10.224129678000168],
              [15.409627914000168, 10.222185135000075],
              [15.41165351900014, 10.219703675000119],
              [15.415100098000153, 10.21748828900013],
              [15.418750764000038, 10.21600914000004],
              [15.422111511000026, 10.214571],
              [15.4224290840001, 10.214434623999978],
              [15.423618317000148, 10.213926315000151],
              [15.42483329900017, 10.211914063000052],
              [15.425016403000029, 10.210674284999982],
              [15.425375939000105, 10.208241463000149],
              [15.425436974000093, 10.207826614],
              [15.427530288000071, 10.205411911000169],
              [15.429274559000078, 10.204765321000139],
              [15.429833413000154, 10.204558373000054],
              [15.430977822000045, 10.204133988000024],
              [15.432029724000074, 10.20395279000013],
              [15.432939529000123, 10.203796388000171],
              [15.4349765770001, 10.202724456999988],
              [15.437535286000127, 10.201377869000169],
              [15.440981865000083, 10.199295997000036],
              [15.443570137000052, 10.197459221000088],
              [15.445239067000102, 10.196275711000055],
              [15.448143959000106, 10.192554474000076],
              [15.45036983500006, 10.189702035000039],
              [15.452595710000139, 10.185544968000102],
              [15.454145432000075, 10.181187631000057],
              [15.454620362000128, 10.178053856000076],
              [15.45472908000005, 10.17733574000016],
              [15.454815864000125, 10.17676448900005],
              [15.456430435000073, 10.17126846400015],
              [15.45831585000019, 10.165435790000174],
              [15.461215973000094, 10.159468651],
              [15.464387895000129, 10.155242920000035],
              [15.467561721000038, 10.152022362000025],
              [15.470871926000086, 10.149538992999965],
              [15.474790572000131, 10.146718977999967],
              [15.475012780000043, 10.146620751000114],
              [15.479047774000151, 10.144836425000108],
              [15.480601310000054, 10.143561363000117],
              [15.482490540000072, 10.140878676999989],
              [15.483100892000095, 10.139736176000099],
              [15.484244347000129, 10.137593270000025],
              [15.4843082430001, 10.135248183000101],
              [15.484167099000103, 10.134293556000046],
              [15.483761788000095, 10.131563188000086],
              [15.483900069000072, 10.130702973000041],
              [15.484096528000066, 10.129486083000074],
              [15.484360696000067, 10.128636361000133],
              [15.485240937000185, 10.125799180000115],
              [15.48713111800015, 10.123719216000154],
              [15.487690926000084, 10.123579979000112],
              [15.489023209000095, 10.123247145999983],
              [15.489109038000038, 10.123254776000124],
              [15.489323616000036, 10.123274801999969],
              [15.489360810000051, 10.123203279],
              [15.489354134000052, 10.12327766500016],
              [15.491931915000066, 10.123511315000087],
              [15.49484062200014, 10.124243737000143],
              [15.495980263000035, 10.123542785000041],
              [15.496259688000123, 10.123370171000147],
              [15.498850822000122, 10.119410516000187],
              [15.499464989000046, 10.11847305200007],
              [15.499530792000087, 10.118372917000045],
              [15.501392364000083, 10.115528107000046],
              [15.501791, 10.114919663000137],
              [15.502038956000149, 10.114647865],
              [15.503748894000012, 10.112772942000049],
              [15.505327225000144, 10.111837386000104],
              [15.506923675000053, 10.110891342000173],
              [15.507820130000084, 10.109806061000143],
              [15.509083747000091, 10.108275414000047],
              [15.509814263000123, 10.105410575000064],
              [15.510024071000089, 10.104588509000109],
              [15.510218620000103, 10.099362374000123],
              [15.510821342999975, 10.095609666000144],
              [15.512508392000029, 10.093596459000139],
              [15.51473903700014, 10.093257903000165],
              [15.51697254100003, 10.09446048699999],
              [15.519477844000107, 10.096667290000084],
              [15.521359444000154, 10.098393440999985],
              [15.522321702, 10.099276543000087],
              [15.523820877000162, 10.100117684000111],
              [15.525773049000065, 10.101213455999982],
              [15.528883934000135, 10.101476670000125],
              [15.530472756000165, 10.101148605000049],
              [15.532804488000181, 10.100667000000101],
              [15.536723137000138, 10.098784447000014],
              [15.538950921000037, 10.096502305],
              [15.542527199, 10.09227562000018],
              [15.545226098000114, 10.088988305000044],
              [15.546991348000176, 10.087095262000162],
              [15.548667907000038, 10.085296630000016],
              [15.551187514999981, 10.083758355000043],
              [15.552517892000026, 10.082945825000138],
              [15.556977271000108, 10.081062317000089],
              [15.558006287000183, 10.079398156000138],
              [15.558594704000086, 10.078446388999964],
              [15.559737205000033, 10.074423790000139],
              [15.561631202000171, 10.070431709000161],
              [15.562027930000113, 10.069596292000028],
              [15.563985824000156, 10.067851067000163],
              [15.564863205000165, 10.067707061000135],
              [15.567703246000065, 10.067241669000168],
              [15.572165490000145, 10.067300797000087],
              [15.574735641000075, 10.068033219000085],
              [15.577374459000112, 10.069301605000078],
              [15.578025816999968, 10.069251061000159],
              [15.580010415000061, 10.069096566000098],
              [15.583254815000089, 10.06815242700003],
              [15.585956574000079, 10.066740990000028],
              [15.590682030000096, 10.063315392000106],
              [15.591155052000147, 10.062853813],
              [15.594057083, 10.060026169000025],
              [15.594692231000181, 10.058010100999979],
              [15.594796181000049, 10.057679176000079],
              [15.594924927000022, 10.053994178000153],
              [15.595652581000024, 10.052000045000113],
              [15.596000672000173, 10.051044465000075],
              [15.596509933000107, 10.05016136100005],
              [15.59697914100019, 10.049347878000106],
              [15.598090172000184, 10.047422409999967],
              [15.599845887000072, 10.046279907000041],
              [15.601015092000068, 10.046173097000064],
              [15.601332664000097, 10.046143533000134],
              [15.607352257000116, 10.047539711000184],
              [15.611545563000163, 10.048537255000099],
              [15.613843917999986, 10.048465729000043],
              [15.618505479000135, 10.046849251000083],
              [15.623637200000076, 10.04362297],
              [15.625214576000076, 10.042437554000173],
              [15.629981994000161, 10.038853646000121],
              [15.635451316000058, 10.035359383000127],
              [15.63652801600017, 10.034747124000148],
              [15.643486022000161, 10.030788421000068],
              [15.652139663000014, 10.025986671000169],
              [15.656451225000069, 10.023593903000062],
              [15.659893989000182, 10.02110767400012],
              [15.66177844900011, 10.017889024000112],
              [15.665479660000017, 10.015965461],
              [15.671032906000164, 10.013558388000092],
              [15.673525810000172, 10.011477471000148],
              [15.674538612000163, 10.010029793000058],
              [15.676657677000037, 10.006999016],
              [15.677940369000112, 10.003882408000095],
              [15.678389550000077, 10.003620148000039],
              [15.687675476000152, 9.99819278800004],
              [15.68952465000018, 9.996432304000052],
              [15.68734550500011, 9.994759560000034],
              [15.681156158000022, 9.993293762000064],
              [15.655916214000115, 9.987316131000114],
              [15.652609825000013, 9.985246659000154],
              [15.594977379000113, 9.971782684000175],
              [15.529255867000074, 9.955812455000114],
              [15.527767182000105, 9.9546756740001],
              [15.505593300000044, 9.95048904500004],
              [15.465228081000021, 9.943689347000145],
              [15.424784, 9.93687100000011],
              [15.423096386000054, 9.936586177000038],
              [15.420114967000188, 9.936083324000094],
              [15.41331958700016, 9.934937477000062],
              [15.399168014000111, 9.942726135000044],
              [15.386082650000162, 9.949928284000066],
              [15.38582325100009, 9.950071336000178],
              [15.385699944000066, 9.950139154000112],
              [15.385628000000168, 9.950179],
              [15.385575, 9.950208001000021],
              [15.37866, 9.954013],
              [15.369114, 9.959266000000127],
              [15.364056588000039, 9.962049108000031],
              [15.3623796010001, 9.962482280000131],
              [15.361106873000153, 9.96281147000002],
              [15.36106301000018, 9.962822996999989],
              [15.361053, 9.96282558300004],
              [15.353761502000168, 9.964709317000143],
              [15.346087, 9.966692000000137],
              [15.345390001000055, 9.966872],
              [15.345370262000074, 9.966877098000168],
              [15.318743000000154, 9.973755],
              [15.304518377000136, 9.977428731000032],
              [15.304467, 9.977442000000167],
              [15.303976497000065, 9.977564704000031],
              [15.269837, 9.98610500000018],
              [15.265683056000057, 9.987144069000067],
              [15.264488220000146, 9.987442970000018],
              [15.264346929000055, 9.987478308000107],
              [15.263621000000114, 9.98766],
              [15.253762000000108, 9.990126],
              [15.250791455000069, 9.990868775000081],
              [15.248415, 9.991463001000057],
              [15.243966418000184, 9.991151080000066],
              [15.243023872000151, 9.991085053],
              [15.242679596000073, 9.991061212000091],
              [15.24258174199997, 9.991054104000057],
              [15.242509, 9.991049000000146],
              [15.240520000000117, 9.99091],
              [15.235810299000093, 9.990371794000055],
              [15.231638001000022, 9.98989500100015],
              [15.231609001000095, 9.989892001000101],
              [15.229860600000109, 9.98969202800015],
              [15.21482181500005, 9.987972260000163],
              [15.214027009000176, 9.988831048000122],
              [15.212872597000057, 9.990078436000147],
              [15.209349, 9.993886],
              [15.194231000000116, 9.991095],
              [15.180023017000053, 9.988472003000027],
              [15.177608000000191, 9.988793],
              [15.152478000000144, 9.997031],
              [15.152459620000059, 9.997029895000026],
              [15.150730822000185, 9.996926089000169],
              [15.14855285200008, 9.996795310999971],
              [15.148531, 9.996794000000136],
              [15.148515169000063, 9.99678973000016],
              [15.14462, 9.995739],
              [15.140393000000131, 9.994599],
              [15.12693977400005, 9.990972520000071],
              [15.12647577000007, 9.990832932000046],
              [15.124022288999981, 9.990097386000116],
              [15.12107899900019, 9.989215000000115],
              [15.120737530000099, 9.989112399000078],
              [15.120653153000148, 9.989087104000021],
              [15.112907850000113, 9.98676474500013],
              [15.112841999000068, 9.986745],
              [15.111481850000075, 9.984455020999974],
              [15.11123, 9.984031000000186],
              [15.109858999000153, 9.980916],
              [15.109859204000088, 9.980767485000058],
              [15.109858512000017, 9.978120803000081],
              [15.109807014000012, 9.977559089000181],
              [15.109454155000037, 9.973729134000109],
              [15.109089851000135, 9.972745896000106],
              [15.108003617000122, 9.969816209000101],
              [15.106564522000156, 9.967169762000026],
              [15.106486320999977, 9.967025758000034],
              [15.106310845000053, 9.966702461000182],
              [15.10535717099998, 9.966317177000121],
              [15.099491119000106, 9.963943481000172],
              [15.089938163, 9.960079193000183],
              [15.089737892000073, 9.959998131000077],
              [15.080297469000072, 9.956178665000039],
              [15.061924933000171, 9.948745729],
              [15.036314963, 9.968873977000101],
              [15.028369903000055, 9.968954086000053],
              [15.004337310000153, 9.969195367000054],
              [15.004293442000062, 9.969201088000091],
              [15.004260063000061, 9.969204903000104],
              [15.000712395000164, 9.969674110000142],
              [14.961483002000136, 9.98492526900003],
              [14.959541321000188, 9.984649659000127],
              [14.956972123000014, 9.984285354000065],
              [14.956213952000098, 9.984015464000152],
              [14.953830718000063, 9.983167649000052],
              [14.951492309000116, 9.982430458000067],
              [14.918785096000136, 9.972126007000043],
              [14.897772789000157, 9.965505599000096],
              [14.897115708000058, 9.965300560000117],
              [14.838981629000159, 9.947114945000067],
              [14.828269004000106, 9.943763733000083],
              [14.828228950000153, 9.943750382000133],
              [14.804341316000034, 9.936276437000174],
              [14.804109574000051, 9.936203956000156],
              [14.802422523000189, 9.93567562100003],
              [14.779223441000056, 9.941011429000071],
              [14.761282920000042, 9.945055961000094],
              [14.738375664000046, 9.950221063000072],
              [14.738059997000164, 9.950291634000109],
              [14.708170891000179, 9.9570274350001],
              [14.708061219000115, 9.957052231000034],
              [14.691657065000186, 9.960747719000096],
              [14.676296235, 9.963871955000116],
              [14.676226617000111, 9.963885308000045],
              [14.662374495999984, 9.966702461000182],
              [14.662290574000167, 9.966719628000078],
              [14.634538651000184, 9.97235965800013],
              [14.615443229000107, 9.97624015700012],
              [14.575659752000092, 9.984316826],
              [14.562267304000045, 9.987035751000178],
              [14.557470322000029, 9.98806476600015],
              [14.54233169500003, 9.99131202600006],
              [14.54162216200001, 9.991464616000087],
              [14.541494369000191, 9.991492272000073],
              [14.535660743000165, 9.992743493000091],
              [14.529714584000146, 9.994018555000082],
              [14.514128684000184, 9.997288704000027],
              [14.499094962000072, 10.00044250600007],
              [14.494604111000058, 10.001325608000116],
              [14.489023208000049, 10.002423287000056],
              [14.47991848099997, 10.003367424000089],
              [14.47629261100019, 10.004240037000102],
              [14.472504616000037, 10.005192757000088],
              [14.466724395000028, 10.006472589000111],
              [14.466057776000071, 10.006619453000155],
              [14.461546897000119, 10.00653266900008],
              [14.458566665000092, 10.006608010000036],
              [14.419820785000013, 10.006302834000167],
              [14.394368172000043, 10.005219460000035],
              [14.380412103000026, 10.004954338000118],
              [14.380325317000143, 10.004953384999965],
              [14.352401733000022, 10.004421235000166],
              [14.300445556000057, 10.004157066000062],
              [14.267339707000076, 10.003925324000079],
              [14.262909889000014, 10.003676415000029],
              [14.252035140000032, 10.003811837],
              [14.243186950000165, 10.004038810000054],
              [14.24305152900007, 10.004042625000125],
              [14.240596771000185, 10.004105568000057],
              [14.235363961000132, 10.002975465000077],
              [14.232464790000108, 10.002729416000079],
              [14.228758812000081, 10.002960205000079],
              [14.225131989000147, 10.003670693000061],
              [14.220541001000072, 10.003659249000179],
              [14.215785026000106, 10.005005837000056],
              [14.212801934000026, 10.00635623900007],
              [14.208049774000074, 10.006105424000168],
              [14.208051680999972, 10.006039619000148],
              [14.208221436000088, 10.001634597000191],
              [14.206537248000188, 9.998596192000036],
              [14.198104858000079, 9.973009111000124],
              [14.193105698000124, 9.957839012000022],
              [14.191571236000186, 9.953439714000183],
              [14.186012268000127, 9.937501907000126],
              [14.184965133999981, 9.93449878600012],
              [14.183349609000174, 9.929866791999984],
              [14.177914619000092, 9.913404465000099],
              [14.173274038999978, 9.901175500000022],
              [14.16486168, 9.88430595400007],
              [14.162859916000116, 9.879844665000178],
              [14.162280082000109, 9.878552437000167],
              [14.159464837000144, 9.872896195000067],
              [14.157028198000035, 9.868000985000037],
              [14.154192925000018, 9.86230373300009],
              [14.154131890000031, 9.862180710000132],
              [14.142132760000038, 9.838064195000186],
              [14.135066031, 9.823863029000052],
              [14.133089066000139, 9.822712898000191],
              [14.130243300000075, 9.821056366000107],
              [14.127245902000027, 9.818525314000112],
              [14.120895385000097, 9.813162803000068],
              [14.120725632000187, 9.813019754000038],
              [14.109279633000028, 9.803354263000131],
              [14.083863259000111, 9.782676697000056],
              [14.083498955000152, 9.78232193000008],
              [14.074104309000177, 9.773157121000054],
              [14.068643569000074, 9.768589974000065],
              [14.064227104000167, 9.764904977000072],
              [14.048249245000022, 9.751047135000135],
              [14.03556346900001, 9.740470887000072],
              [14.026252747000058, 9.731581688000063],
              [14.021101951000048, 9.721286773000145],
              [14.020867348000081, 9.720817566000108],
              [14.016083718000061, 9.711254120000035],
              [14.005130768000072, 9.689359664000165],
              [13.992045402000088, 9.663564683000061],
              [13.991772652000066, 9.663026810000076],
              [13.982252121000101, 9.644258499000159],
              [13.982441902000062, 9.64243126000008],
              [13.982501030000151, 9.641862869000022],
              [13.987630845000126, 9.637736320000101],
              [13.987672807000081, 9.6377029410001],
              [14.002179147000049, 9.626030922000041],
              [14.00790119200019, 9.622694015000093],
              [14.023275375000082, 9.620182038000166],
              [14.03487300900008, 9.615503311],
              [14.043807983000079, 9.614011766000147],
              [14.045905113, 9.611941339000168],
              [14.046608924000111, 9.601524353000173],
              [14.046660424000095, 9.600764275000131],
              [14.051905632000114, 9.595268250000061],
              [14.054930686000091, 9.59092903200002],
              [14.055104256999982, 9.590680123000141],
              [14.059740067000064, 9.584031106000055],
              [14.072169304000056, 9.568892479000056],
              [14.074267387000191, 9.566423415999964],
              [14.07886028300004, 9.563560487000132],
              [14.083786964000069, 9.556386948000181],
              [14.092156409999973, 9.55489158600011],
              [14.099730491000059, 9.549880981],
              [14.107240677000107, 9.537296296000136],
              [14.107486724000125, 9.536884309000072],
              [14.108308792000059, 9.52993965100012],
              [14.11091423, 9.517808915000046],
              [14.117359161000081, 9.513672829000143],
              [14.123966216000099, 9.509217263000096],
              [14.134997368000143, 9.504214286000092],
              [14.141034126000079, 9.502552032999972],
              [14.145605088000082, 9.503611565000085],
              [14.148348809000026, 9.50424766500015],
              [14.153019905000122, 9.501783372000091],
              [14.15463542800012, 9.501977921000105],
              [14.156396866000023, 9.502190591000101],
              [14.163020134000078, 9.490468025000041],
              [14.175088882000125, 9.482062340000084],
              [14.177136420000068, 9.480636596000068],
              [14.183165550000126, 9.473826408999969],
              [14.185915947000069, 9.467205047000107],
              [14.189069747000076, 9.45978546200007],
              [14.193824767000024, 9.455484390000152],
              [14.19585228, 9.447902680000084],
              [14.196987153, 9.444072722999977],
              [14.201334954000174, 9.441846848000125],
              [14.211860656999988, 9.445463180000047],
              [14.215737344000047, 9.438205720000156],
              [14.221539498000141, 9.432787896000036],
              [14.239017487000069, 9.419650078000132],
              [14.237999917000081, 9.406950951000056],
              [14.2430038440001, 9.398576736],
              [14.250350953000066, 9.390883445000043],
              [14.256576538000104, 9.379993440000021],
              [14.257439613000031, 9.378483771999981],
              [14.267110824000042, 9.367323876000171],
              [14.270676613000148, 9.362983703000168],
              [14.270814895000058, 9.362814903000015],
              [14.274445535000041, 9.358395576000021],
              [14.279194831999973, 9.355850219000047],
              [14.284176826000078, 9.356738090000022],
              [14.291230202000179, 9.359361648000061],
              [14.291288377000058, 9.359352113000057],
              [14.291471480000041, 9.360050201000092],
              [14.292461394000043, 9.360419273000048],
              [14.298636436000095, 9.362715720999972],
              [14.306890488000022, 9.366602898],
              [14.311408042000096, 9.368730545000119],
              [14.313468932999967, 9.36669158899997],
              [14.315355302000171, 9.364825249000091],
              [14.323117256000103, 9.35029602100019],
              [14.323134422000066, 9.350264549000087],
              [14.327459333999968, 9.342168808000167],
              [14.330285072000038, 9.335865022000121],
              [14.334321023000086, 9.327008247000151],
              [14.340211869000086, 9.315280915000074],
              [14.347546578000049, 9.304832458000021],
              [14.373689651000177, 9.295136451000076],
              [14.380206108000095, 9.292113304000168],
              [14.382310867000115, 9.283412934000069],
              [14.38457870600007, 9.27359390200013],
              [14.384911536000061, 9.267045975000031],
              [14.381392479000112, 9.256978036000021],
              [14.37770652800009, 9.250423431000115],
              [14.370802880000156, 9.24609851800011],
              [14.36807823200013, 9.241222382000103],
              [14.356928825000182, 9.229304314000046],
              [14.357666015000063, 9.221159935],
              [14.355582237000135, 9.218201638000153],
              [14.350841522, 9.217473984000151],
              [14.348359108000125, 9.21291732800006],
              [14.348530769000035, 9.206849099000067],
              [14.351511001000063, 9.203340530000105],
              [14.355208397000126, 9.203027725000084],
              [14.358101845000078, 9.20247364100004],
              [14.359472274000041, 9.200240135000172],
              [14.357793808000054, 9.195206643000063],
              [14.361262321000083, 9.188104631000101],
              [14.364164352000103, 9.182919503000051],
              [14.368506432, 9.181329728000037],
              [14.373326301000134, 9.181977271000051],
              [14.381696701000067, 9.174405099000069],
              [14.389365195000039, 9.170554161000098],
              [14.392287254000166, 9.165132522000135],
              [14.397388458000023, 9.162246704000097],
              [14.403778076000037, 9.151041984000131],
              [14.417625427000132, 9.142019271000095],
              [14.426185608000083, 9.13805294000008],
              [14.433701515000109, 9.129534721000084],
              [14.446198463000087, 9.125178337000023],
              [14.452347756000052, 9.120812417000082],
              [14.457366944000057, 9.118988037000122],
              [14.458531379000021, 9.118945122000071],
              [14.462690353000028, 9.118792533000146],
              [14.466822625000191, 9.117238998000119],
              [14.467811584, 9.116867065000179],
              [14.470270156000083, 9.113254548000157],
              [14.470787048000034, 9.112495423000098],
              [14.471211433000065, 9.102625848000173],
              [14.483726502000025, 9.084124566000071],
              [14.486652374000187, 9.079146385],
              [14.488447190000159, 9.07609272000002],
              [14.489381789000163, 9.07256793900018],
              [14.490093230000127, 9.069887161000054],
              [14.492976188000114, 9.05768108400008],
              [14.496048927000061, 9.054711342000189],
              [14.497076035000134, 9.0537185660001],
              [14.51909828200013, 9.042554855000049],
              [14.533029557000077, 9.033617974000038],
              [14.540404320000164, 9.029252052000061],
              [14.551568986000063, 9.022244454000088],
              [14.561708451000129, 9.016354560000025],
              [14.58608341300004, 9.002035142000011],
              [14.587565422000182, 9.000232697],
              [14.590342522000071, 8.996854782000071],
              [14.611342429000103, 8.980278970000086],
              [14.618288995, 8.974796295000033],
              [14.647451401000069, 8.953454972000031],
              [14.703260421000095, 8.912613869000097],
              [14.728277206000087, 8.894321442000091],
              [14.729217530000085, 8.893633842000043],
              [14.768077851000157, 8.865210533000095],
              [14.789328575000184, 8.849666597000066],
              [14.800995826000189, 8.841052056000081],
              [14.836006164000082, 8.81520366699999],
              [14.838401794000049, 8.814819336000085],
              [14.840311051000185, 8.814513207000061],
              [14.841311454999982, 8.814772607000066],
              [14.842735290000121, 8.815142632],
              [14.843993186000091, 8.81514358600009],
              [14.845475197999974, 8.815144539000073],
              [14.8488483430001, 8.815460206000182],
              [14.850851059999968, 8.815251351000029],
              [14.853697776000161, 8.813155174000087],
              [14.853843690000133, 8.815137864000178],
              [14.853945732000057, 8.816522599000052],
              [14.854116440000155, 8.819656372999987],
              [14.854575157, 8.819910050000146],
              [14.855864525000129, 8.82062339800018],
              [14.857910156000173, 8.821754456000122],
              [14.861495019000017, 8.820497512999964],
              [14.863603592000175, 8.818506242000126],
              [14.864552497000091, 8.817353248000131],
              [14.865606309000043, 8.818087577000085],
              [14.866870880000135, 8.819031716000097],
              [14.867129325000121, 8.819388390000142],
              [14.868240357000047, 8.82091999000005],
              [14.869320869999967, 8.82208442699999],
              [14.870769501000041, 8.823646545000088],
              [14.872616767000125, 8.824610710000059],
              [14.872982979000028, 8.824801444],
              [14.875828743000113, 8.824592590000123],
              [14.876701356000126, 8.823826791000101],
              [14.877620697000054, 8.82301998100013],
              [14.87804317400014, 8.81997966800003],
              [14.878992081000035, 8.819455146000166],
              [14.879120827, 8.819602966000105],
              [14.880362510000168, 8.821028710000121],
              [14.88100528800004, 8.821775437000042],
              [14.882258414000034, 8.823231697999972],
              [14.884245872000122, 8.822015763000024],
              [14.88500023, 8.821554184000092],
              [14.886476517000062, 8.819667816000163],
              [14.88868999500005, 8.818514824000147],
              [14.891430854000077, 8.818096160000152],
              [14.892714501000171, 8.817266464000056],
              [14.893539428000111, 8.816733361000104],
              [14.895542144000103, 8.816314697000109],
              [14.895912171000077, 8.816114427000059],
              [14.897861480000131, 8.815056800000036],
              [14.901445388000184, 8.813485146],
              [14.902721404999966, 8.810373306000088],
              [14.903059006000149, 8.807559014000162],
              [14.904715539000165, 8.801637649000043],
              [14.905962945000169, 8.800220490000129],
              [14.906191826000168, 8.799960137000142],
              [14.906311989000073, 8.799867631000154],
              [14.907140732000187, 8.799225808000074],
              [14.907979012000055, 8.798245430000122],
              [14.907760619000044, 8.79570102800011],
              [14.909737587000166, 8.7953081120001],
              [14.911400795000134, 8.794067384000073],
              [14.91163444599999, 8.793892861000131],
              [14.911911011000086, 8.79290294700013],
              [14.912030219000144, 8.79247760700008],
              [14.911952017999965, 8.790904046000037],
              [14.91227340800009, 8.790696144000037],
              [14.913033486000131, 8.790205955999966],
              [14.914244653000083, 8.788702965000084],
              [14.915984154, 8.787287712000136],
              [14.918004989000167, 8.785613060000117],
              [14.918829918000085, 8.784929276000014],
              [14.921439170000156, 8.78351402200019],
              [14.923890115000177, 8.781155586000068],
              [14.925708770000142, 8.778560640000137],
              [14.925564766000093, 8.777915954999969],
              [14.92539215000005, 8.777145386000086],
              [14.923970223000083, 8.775178909000033],
              [14.923331261000158, 8.774570465000124],
              [14.922152519000122, 8.773447990000079],
              [14.921057702000041, 8.772323608000079],
              [14.919702529000119, 8.770931245000099],
              [14.91535568200004, 8.768413544000055],
              [14.912905693000141, 8.766919137000116],
              [14.912590028000011, 8.764874459000055],
              [14.914092063000055, 8.763459205],
              [14.916147232000185, 8.762594224000111],
              [14.916739465000092, 8.761662483000066],
              [14.91709613900008, 8.761100769000109],
              [14.918174744000055, 8.759758949000116],
              [14.918361662999985, 8.75952720600003],
              [14.919278144000089, 8.758746147000181],
              [14.920575142000075, 8.757640839000032],
              [14.921251296000094, 8.75653267000007],
              [14.92191887000007, 8.755438804000164],
              [14.925081253000144, 8.754810334000013],
              [14.927531243000033, 8.754732133000061],
              [14.930455208000069, 8.754811287],
              [14.931226731000038, 8.754929543],
              [14.932510376000153, 8.755126954000104],
              [14.936067581000032, 8.754891396000176],
              [14.936663628000076, 8.755286217000048],
              [14.938200951000169, 8.756306649000123],
              [14.940493583000148, 8.758273124000027],
              [14.942864418999989, 8.75819492300019],
              [14.946895600000062, 8.757723808000037],
              [14.947601318000181, 8.757261277000055],
              [14.950694084000077, 8.755233764000082],
              [14.951315880000038, 8.754923819999988],
              [14.951501846000156, 8.754831315000047],
              [14.95230960900011, 8.753492355000105],
              [14.952917099999979, 8.752486228000123],
              [14.954263688000026, 8.751615525000091],
              [14.955611229000056, 8.750475884000025],
              [14.957160949000013, 8.74759483400004],
              [14.958575248000102, 8.746254921000116],
              [14.961000442, 8.745718956000076],
              [14.962280274000022, 8.744981766000024],
              [14.963896752000039, 8.743575095999972],
              [14.964303971000049, 8.74351215500019],
              [14.966052056000024, 8.743240357000104],
              [14.967267990000096, 8.743336677000059],
              [14.96941947900018, 8.743508340000119],
              [14.971776962000092, 8.743173599000045],
              [14.973244668000063, 8.742687226000044],
              [14.974404334999974, 8.742302896000069],
              [14.977973937000115, 8.740427018000105],
              [14.981409073000123, 8.738148689000127],
              [14.981642723999983, 8.737815858000033],
              [14.982352257000173, 8.736808776000032],
              [14.983333588000107, 8.736205100000177],
              [14.983887672000094, 8.73586463900017],
              [14.984335900000076, 8.735299110000028],
              [14.98531627599999, 8.734061242000109],
              [14.987403869000161, 8.732252122000148],
              [14.988414764000026, 8.729705811000031],
              [14.989674568, 8.728223801000183],
              [14.990636825000138, 8.727091790000031],
              [14.990921974, 8.723518371000068],
              [14.990368843000169, 8.721473695000157],
              [14.991175651000162, 8.718380928000158],
              [14.991175651000162, 8.715700149000099],
              [14.990368843000169, 8.713215828000159],
              [14.988314629, 8.710227966000048],
              [14.986597061, 8.707877160000066],
              [14.98641777, 8.707632066000031],
              [14.986801148000097, 8.706077575999984],
              [14.987270355000135, 8.704174996000177],
              [14.987838743999987, 8.703208923999966],
              [14.987919808000129, 8.703070640000078],
              [14.9861021050001, 8.701812744000108],
              [14.985235214000056, 8.701045990000068],
              [14.983968735000133, 8.699925422999968],
              [14.984778403000178, 8.697808266000038],
              [14.98388957899999, 8.695049286000028],
              [14.983019828000067, 8.692375184000127],
              [14.981992721999973, 8.689544678000061],
              [14.983561516000123, 8.689629555000067],
              [14.984916687000066, 8.689702034000106],
              [14.986065864000011, 8.689963341000123],
              [14.98768329699999, 8.690330505000134],
              [14.989945413000157, 8.69002437600011],
              [14.99116039200004, 8.689859391000084],
              [14.99566459600004, 8.689466477000053],
              [15.0007848730001, 8.689531326000179],
              [15.001829147000024, 8.689544678000061],
              [15.00538539900009, 8.689780235000058],
              [15.008146286000169, 8.688535691000141],
              [15.010442733000048, 8.687500000000171],
              [15.010926246000167, 8.687245369000152],
              [15.013129234000189, 8.686083794000012],
              [15.015090943000018, 8.686301232000062],
              [15.016685486000085, 8.686477662000129],
              [15.018759728000077, 8.68614864400007],
              [15.020162581000079, 8.685926437000092],
              [15.023560523000015, 8.685061456000028],
              [15.027275085000099, 8.683094979000089],
              [15.027893066000161, 8.682085992],
              [15.029250145000049, 8.679870605000133],
              [15.03029441800004, 8.679085731000043],
              [15.032234192000146, 8.677627563000158],
              [15.033677102000183, 8.676766395000129],
              [15.034193039000115, 8.676493645000107],
              [15.034657478000099, 8.676248551000128],
              [15.036850928000035, 8.675731659000121],
              [15.037055015000135, 8.675787926000112],
              [15.040199280000138, 8.676650047000123],
              [15.042831421000074, 8.677683830000092],
              [15.046929360000092, 8.678774834000137],
              [15.050046921000046, 8.678659439000114],
              [15.0519647590001, 8.679262162000157],
              [15.052240372000085, 8.679348946000062],
              [15.052721977000033, 8.679396630000099],
              [15.054549217000044, 8.67957782700006],
              [15.056090355000038, 8.679537772],
              [15.056742668000084, 8.679520607000143],
              [15.059051513999975, 8.679807663000076],
              [15.059941293000179, 8.679129601000113],
              [15.061013223000032, 8.678313255000035],
              [15.063052178000078, 8.676482201000056],
              [15.063380241000118, 8.676187515000038],
              [15.066554070000109, 8.673257828000033],
              [15.06768226600019, 8.67287254300004],
              [15.070421219000082, 8.671936036000091],
              [15.074345588000142, 8.671360968999977],
              [15.079136849000179, 8.67026805800009],
              [15.084734917000105, 8.667107582000085],
              [15.088313102000086, 8.665498733999982],
              [15.089998246000107, 8.664725303000012],
              [15.090243340000143, 8.664612770000076],
              [15.092699050000078, 8.663487435000093],
              [15.096277238000027, 8.661417961000097],
              [15.099970818000088, 8.659980773000029],
              [15.103895187000035, 8.658543586000064],
              [15.106030465000117, 8.657796861000179],
              [15.108338356000047, 8.656015397000033],
              [15.109434126999986, 8.65187931100013],
              [15.109952926, 8.64837551200003],
              [15.109927177000145, 8.645318986000063],
              [15.109926225000038, 8.642770767],
              [15.110030175000134, 8.639598846000013],
              [15.110969544000113, 8.6370506290001],
              [15.11300659200009, 8.634918213999981],
              [15.115823746000103, 8.631592751000085],
              [15.119266510000045, 8.627782822000086],
              [15.123761178000166, 8.622925759000111],
              [15.129020690000118, 8.61902046199998],
              [15.13581085300018, 8.613114357000086],
              [15.140592575000028, 8.609018325000136],
              [15.143556596000053, 8.607589721000011],
              [15.147669791000112, 8.605112077],
              [15.151494980000166, 8.602159501000131],
              [15.152066230000173, 8.597208024],
              [15.151872635000075, 8.591780663000122],
              [15.151989936000064, 8.590979577000098],
              [15.152134895000074, 8.589977265000073],
              [15.152731896000034, 8.585877418000052],
              [15.153303147000088, 8.580735207000146],
              [15.15358829600018, 8.576031685000146],
              [15.154160500000103, 8.572318078000137],
              [15.154925346000141, 8.570222855000054],
              [15.15597629500013, 8.56797504400015],
              [15.160470008000061, 8.562451363000037],
              [15.165057183000101, 8.55540371],
              [15.169357300000172, 8.545404434000091],
              [15.173942565000061, 8.532452583000065],
              [15.177763939000045, 8.525024414000143],
              [15.182543755000097, 8.519404411000096],
              [15.188375473000178, 8.515402794000067],
              [15.192008971000064, 8.513496399000132],
              [15.197649956000078, 8.512351036000155],
              [15.200230599000122, 8.50901794400005],
              [15.22458648800017, 8.456445693000035],
              [15.230792999000073, 8.441969872000016],
              [15.236809731000164, 8.430159569000125],
              [15.241393090000031, 8.419683456000087],
              [15.247218131000182, 8.407587051000121],
              [15.252851487000157, 8.396253585000125],
              [15.259916306000036, 8.381490708000115],
              [15.269175529999984, 8.360918045000119],
              [15.275283813000101, 8.347583772000064],
              [15.279430390000073, 8.33900833199999],
              [15.282919883999966, 8.331790924000018],
              [15.288024903000064, 8.321231841999975],
              [15.291937829000176, 8.313304901000038],
              [15.293306351000069, 8.310532571000181],
              [15.295683862000033, 8.305042265999987],
              [15.298586845000159, 8.298338890000139],
              [15.305659294000122, 8.283018112000093],
              [15.305801391000045, 8.282710075000068],
              [15.30599498800018, 8.282290460000127],
              [15.311749457000133, 8.271432876000063],
              [15.31608295500007, 8.262169837000045],
              [15.318999290000022, 8.255934714999967],
              [15.323884964000172, 8.245707513000014],
              [15.325081825000154, 8.243246078000027],
              [15.331844329000148, 8.229343414000084],
              [15.339092254000036, 8.213057518000142],
              [15.341054916000076, 8.208909989000063],
              [15.345236779000118, 8.200076103000129],
              [15.34913921399999, 8.191488265000089],
              [15.349491119000049, 8.190713884000047],
              [15.355635644000188, 8.178126336000048],
              [15.35604, 8.177262306000159],
              [15.359023095000055, 8.17088794700004],
              [15.361543656000151, 8.165537834000133],
              [15.36213970100016, 8.16424274600007],
              [15.370443345000126, 8.146183014000087],
              [15.374520301000132, 8.137116432000084],
              [15.374538422000114, 8.13707733300015],
              [15.37455272700015, 8.13704586099999],
              [15.377036094000175, 8.131522180000104],
              [15.380495071000098, 8.123827935000065],
              [15.381235123000067, 8.122181892000185],
              [15.396739007000178, 8.091275214],
              [15.396727562000024, 8.091211318999967],
              [15.39619255100007, 8.088199615],
              [15.395793915000127, 8.084031105000065],
              [15.394897461000028, 8.055563926000048],
              [15.39491176700011, 8.055356026000084],
              [15.39567566, 8.044395447000113],
              [15.398157119000075, 8.037278176000029],
              [15.40047550200012, 8.030628205000028],
              [15.403307915000084, 8.023154259000137],
              [15.405668258000105, 8.016860009000027],
              [15.411096573000066, 8.001755714000012],
              [15.429175377000035, 7.94777727200011],
              [15.452363969000032, 7.881149293000135],
              [15.458443641000031, 7.870651723000037],
              [15.489324571000168, 7.819888115000026],
              [15.497130394000067, 7.806834221000145],
              [15.498094558999981, 7.80477476100009],
              [15.504727363000086, 7.793642522000141],
              [15.506870270000036, 7.792190552000079],
              [15.541807175000145, 7.783589364000022],
              [15.586843490000092, 7.773245334000023],
              [15.588642120000031, 7.772483826000041],
              [15.589190483000039, 7.768479823000121],
              [15.589116097000101, 7.765374183000063],
              [15.589874267000141, 7.76309585600012],
              [15.591600418000041, 7.759987355000021],
              [15.59242630000017, 7.757225513000151],
              [15.591524125000035, 7.754949094000153],
              [15.587706566000122, 7.746879578000062],
              [15.58652591800012, 7.743775368000058],
              [15.586593629000106, 7.742463589000124],
              [15.588459968999985, 7.741219043],
              [15.590257645000065, 7.740250110000034],
              [15.590947149999977, 7.738178731000176],
              [15.590389252000023, 7.735004425000057],
              [15.589693069000077, 7.732175827000162],
              [15.589342117000172, 7.728587151000056],
              [15.588578224000116, 7.72631073000008],
              [15.588713646000087, 7.724378109000156],
              [15.589887618000091, 7.722996235000039],
              [15.591961860000083, 7.721474647999969],
              [15.592651368000134, 7.720162392000134],
              [15.592511176000073, 7.718575477000172],
              [15.590917587000149, 7.717335224000124],
              [15.590085030000068, 7.715680122000151],
              [15.589595794000104, 7.712091446000045],
              [15.589658737000036, 7.707191467000087],
              [15.590139390000104, 7.704705715000102],
              [15.590895653000075, 7.701599122],
              [15.590824127000019, 7.699252607000119],
              [15.59040260200004, 7.694697858000097],
              [15.590953828000181, 7.693109512000149],
              [15.592680931000189, 7.691244126000186],
              [15.593646049000085, 7.689240933000065],
              [15.593574523000029, 7.687584877000177],
              [15.592532157000107, 7.684134961000041],
              [15.592251779000037, 7.681029798000054],
              [15.592177391000064, 7.677648067000121],
              [15.590721130000134, 7.675165176000121],
              [15.589126587000067, 7.672751904999984],
              [15.588500023000108, 7.669853687],
              [15.58821869000019, 7.666679383000087],
              [15.587179184000149, 7.665093421000165],
              [15.584202767000022, 7.663855076000061],
              [15.58302402400011, 7.662407398000028],
              [15.582814217000021, 7.660475255000108],
              [15.581634521000126, 7.657716275000098],
              [15.579695701000105, 7.656683445000112],
              [15.576858520000144, 7.655652046000114],
              [15.577409744000079, 7.654478074000167],
              [15.578792572999987, 7.65385484699999],
              [15.580176353000127, 7.653714658000013],
              [15.581351281000138, 7.65281629600014],
              [15.582179069000063, 7.650951386000031],
              [15.582036973000015, 7.648536205000141],
              [15.581826210000088, 7.646189690000028],
              [15.582306863000156, 7.643497467000145],
              [15.583270073000108, 7.639838220000115],
              [15.582645416000048, 7.638182163000181],
              [15.579667091999966, 7.635425568000073],
              [15.579111098000169, 7.633079529000042],
              [15.579106330000116, 7.629628659000161],
              [15.579031944000178, 7.625970840000093],
              [15.577991486000087, 7.624177934000045],
              [15.574874877000013, 7.620869159000108],
              [15.57231044700012, 7.617766858000039],
              [15.571617127000138, 7.616387367000016],
              [15.570368766000172, 7.614180088000069],
              [15.568084716000158, 7.612733840000146],
              [15.565315247000058, 7.611288070000057],
              [15.564760208000052, 7.609701158000064],
              [15.564130784000042, 7.604249477999986],
              [15.564681052000083, 7.602178573000174],
              [15.566061974000149, 7.600313188000087],
              [15.568065643000125, 7.598239898000145],
              [15.570548057000167, 7.594703198000104],
              [15.570202828000106, 7.592922687000112],
              [15.56902408600007, 7.590646745000072],
              [15.565281868000056, 7.585337161000041],
              [15.563410760000181, 7.582785607000176],
              [15.560711861000073, 7.581477643000142],
              [15.558011055000065, 7.579686642000183],
              [15.556487083000093, 7.577618122000104],
              [15.555653572000097, 7.575203419000047],
              [15.554615019999972, 7.572174072000109],
              [15.553669929000023, 7.571060657000089],
              [15.55308818900005, 7.570375443000046],
              [15.55192375200005, 7.570376872999987],
              [15.550183295000011, 7.570379258000116],
              [15.548315048000063, 7.56969118100011],
              [15.545338631000106, 7.567693710000128],
              [15.542015075000108, 7.565144062000059],
              [15.54055976900014, 7.56286811800004],
              [15.540073394000103, 7.561005116000047],
              [15.539171218999968, 7.558659554000144],
              [15.536400793999974, 7.555833339000174],
              [15.530795097000066, 7.552665233000028],
              [15.52802562700009, 7.55059814599997],
              [15.526085853000154, 7.548046588999966],
              [15.524147033000133, 7.545909405999964],
              [15.52214050300006, 7.545359613000073],
              [15.519097328000043, 7.545639516000051],
              [15.517643928000041, 7.54481268],
              [15.517088889000036, 7.543433190000087],
              [15.515424729000188, 7.539984226],
              [15.51396942100007, 7.537363053000092],
              [15.513275146000069, 7.535017013000186],
              [15.512718200000165, 7.531980991000125],
              [15.507668495000075, 7.530951500000128],
              [15.505314827000177, 7.529228687000114],
              [15.503376006999986, 7.527436733],
              [15.500056266000058, 7.527026176000049],
              [15.497773171000063, 7.526890755000125],
              [15.496734618000175, 7.525787353000169],
              [15.496664047000138, 7.52406215700006],
              [15.495901109000044, 7.522544384000128],
              [15.493892670000037, 7.519855023000105],
              [15.490984916000173, 7.517028332999985],
              [15.491051674000175, 7.514957906],
              [15.491672516000051, 7.513783931000148],
              [15.493399619, 7.512263297000118],
              [15.494228363000047, 7.510744096000053],
              [15.493879319000087, 7.508466721000048],
              [15.492840768000065, 7.506811619000018],
              [15.490348816000107, 7.505157947000157],
              [15.488548279000099, 7.502675057999966],
              [15.486057282000104, 7.502194883000129],
              [15.483843804000117, 7.501299858000095],
              [15.482043267000108, 7.499231339000062],
              [15.478652001000171, 7.496681214000034],
              [15.478650093000169, 7.495231629000045],
              [15.478855133000081, 7.49288511400016],
              [15.478229523000039, 7.489572526000188],
              [15.477466583000137, 7.487847805],
              [15.475112915000068, 7.485435010000117],
              [15.471999169000185, 7.48318004700019],
              [15.470544816000029, 7.482126713000071],
              [15.46763897000011, 7.480542183000068],
              [15.46666812899997, 7.480648518000066],
              [15.46445751300007, 7.48089075199999],
              [15.462382316, 7.47992658700008],
              [15.461620330000017, 7.478684903000044],
              [15.462102889999983, 7.477097034],
              [15.463483810000014, 7.475232124000058],
              [15.463618279000059, 7.471504689000142],
              [15.461956023000027, 7.468883992000087],
              [15.459671021000133, 7.466056347000176],
              [15.458147050000093, 7.463573456000177],
              [15.457866669000055, 7.460398674000032],
              [15.457101821000037, 7.455774785000187],
              [15.455785752000111, 7.454119682999988],
              [15.453176499000108, 7.453254700000116],
              [15.453087808000134, 7.453225136000128],
              [15.453057289000014, 7.453226090000044],
              [15.450045585000055, 7.4532976160001],
              [15.447970390000023, 7.452678680000076],
              [15.442090988000132, 7.451027870000075],
              [15.440724373000137, 7.44943237300015],
              [15.44042778, 7.447992803000034],
              [15.439870834000089, 7.443506718000037],
              [15.438759804000028, 7.438676357000134],
              [15.437719345000176, 7.43605470599999],
              [15.434949874999973, 7.432123184000147],
              [15.434003831000041, 7.43129539500012],
              [15.433061599000098, 7.430469989000073],
              [15.432665826000118, 7.430123806000097],
              [15.43173885300007, 7.429778098999975],
              [15.429346085000134, 7.428884982999989],
              [15.426094055000021, 7.427093506000176],
              [15.424984931000097, 7.424540519000061],
              [15.425258636000081, 7.421158312999978],
              [15.426155091000112, 7.418603898000129],
              [15.42622280099999, 7.416947366000045],
              [15.419787407000115, 7.41177654300003],
              [15.415151595000054, 7.408606052000152],
              [15.407608031999985, 7.400882721000187],
              [15.404701233000083, 7.398193359000061],
              [15.402418136000108, 7.396746159000031],
              [15.400205612000036, 7.396610260000045],
              [15.397509576000061, 7.396681308999973],
              [15.394674301, 7.396408081000118],
              [15.392184257000054, 7.395236969000052],
              [15.390799522000123, 7.393236637000086],
              [15.390589714000043, 7.390682697000159],
              [15.388790130000132, 7.388889791000111],
              [15.387198448000106, 7.386751653000147],
              [15.384982109000134, 7.382336139000017],
              [15.381520272000103, 7.377093316000128],
              [15.379652977000092, 7.375576497000111],
              [15.376126289000126, 7.37551069400007],
              [15.373566627000059, 7.374063016000036],
              [15.371628761000125, 7.37109708700001],
              [15.370327950000103, 7.369975567000154],
              [15.369230271000106, 7.36902952299999],
              [15.369069098000182, 7.368890286000124],
              [15.365748405000147, 7.367374420000147],
              [15.362150192000058, 7.363374233000172],
              [15.359036446000175, 7.35964965800008],
              [15.353710174000184, 7.356202602000167],
              [15.348383904000059, 7.35275602300004],
              [15.342020988000058, 7.349999906000164],
              [15.339807510000071, 7.348206997000034],
              [15.339182853000182, 7.34517049699997],
              [15.337384224000118, 7.343170166000107],
              [15.335239410000099, 7.340962886999989],
              [15.331573486000025, 7.33937835800009],
              [15.328806876999977, 7.337654590000113],
              [15.324656487000141, 7.334068775000162],
              [15.320298195000134, 7.330068589000121],
              [15.317393302000028, 7.327240467000081],
              [15.314143182000066, 7.326276779000068],
              [15.312414169000078, 7.32510471400019],
              [15.306257247000133, 7.319863319000035],
              [15.299409866000133, 7.314484119000099],
              [15.29678249300008, 7.313243389000093],
              [15.293948173000047, 7.313452244000075],
              [15.293947220000064, 7.311726570000133],
              [15.293462753000028, 7.31041574599999],
              [15.291732789000037, 7.308484078000049],
              [15.289450645000045, 7.307174207000116],
              [15.285923957000136, 7.305588722000152],
              [15.284056664000047, 7.303933620000123],
              [15.282464979999986, 7.30144977600014],
              [15.281700134000118, 7.300334454000051],
              [15.28066635100015, 7.298828126000103],
              [15.27672386100005, 7.296138764000034],
              [15.275064467999982, 7.294827938000083],
              [15.274302483000099, 7.293102741999974],
              [15.269598962000032, 7.287652970000067],
              [15.267455102000099, 7.286204813999973],
              [15.263583183000094, 7.285240651000038],
              [15.262890815000048, 7.283515931000181],
              [15.263096810000093, 7.281099797000081],
              [15.261229515000082, 7.279098988000044],
              [15.254730225000174, 7.275237560000107],
              [15.254070282000043, 7.275014877000047],
              [15.252104760000122, 7.274350644000094],
              [15.250650407000137, 7.273859501],
              [15.246918679000089, 7.274344444000121],
              [15.245120048000047, 7.273379326000168],
              [15.244843482000078, 7.27151584600017],
              [15.245464326000103, 7.269375801000138],
              [15.245532037000089, 7.267167092000079],
              [15.243041038000172, 7.263095856000177],
              [15.240344048000111, 7.260336399000039],
              [15.239928245999977, 7.258680344000084],
              [15.238958359000151, 7.254953384999965],
              [15.237644195000087, 7.252745629000117],
              [15.235638618000166, 7.250813961000176],
              [15.233979225000098, 7.249158383000122],
              [15.232525825000096, 7.246674062000182],
              [15.230452538000065, 7.24577808500004],
              [15.228033067000126, 7.245710374000055],
              [15.224645614999986, 7.245711804000166],
              [15.222709655000074, 7.244401455000173],
              [15.221395493000045, 7.242538453000066],
              [15.220391274000178, 7.24005937700008],
              [15.22007942200014, 7.239281178000113],
              [15.219734193000079, 7.236188888000015],
              [15.22146129600003, 7.235014915000136],
              [15.221944808999979, 7.233496189000107],
              [15.220699311000146, 7.230597973000101],
              [15.215652467000041, 7.229150773000072],
              [15.21392345300012, 7.227495193000038],
              [15.21369171200007, 7.226799488000154],
              [15.21302413899997, 7.224803447000113],
              [15.213344574000132, 7.224533559000122],
              [15.214336395000032, 7.22369861500016],
              [15.216755866000085, 7.223145009000177],
              [15.218898774000138, 7.22335147900003],
              [15.219525338000096, 7.223559856000122],
              [15.222008705000121, 7.224385262000055],
              [15.22243499700005, 7.224172115000101],
              [15.223667145000036, 7.223556041000109],
              [15.225186348000079, 7.22093248300007],
              [15.226983070000074, 7.219551086000081],
              [15.229332923000072, 7.21954965600014],
              [15.230783462999966, 7.218306542000164],
              [15.232409477000033, 7.21791601200016],
              [15.232511519000127, 7.217891693000183],
              [15.232939719000058, 7.217442037000126],
              [15.233893394000177, 7.216441632000056],
              [15.234099389000107, 7.21506118800005],
              [15.232094765000113, 7.212991238000029],
              [15.228636742000106, 7.209680081000045],
              [15.22607898800004, 7.20802450299999],
              [15.224349977000031, 7.205195428000138],
              [15.224279404000185, 7.203331948000141],
              [15.224968911000133, 7.200570584000104],
              [15.224830627000017, 7.19877624500009],
              [15.22372341199997, 7.196774961000017],
              [15.222201348000169, 7.194360256000152],
              [15.218399047999981, 7.190841675000115],
              [15.216601371000024, 7.188840867000124],
              [15.215839386000141, 7.186494352000182],
              [15.214386940000054, 7.184079171000121],
              [15.21224403399998, 7.182354928000166],
              [15.210515976000011, 7.180837154000187],
              [15.208509445000175, 7.176489353000079],
              [15.208508492000021, 7.174764157000141],
              [15.209198952000122, 7.17303800600007],
              [15.210025788000166, 7.167515755000125],
              [15.210713387000112, 7.159646989000066],
              [15.211123467000107, 7.150535584000181],
              [15.211744309000153, 7.146739006000132],
              [15.213332176000165, 7.143908501000112],
              [15.215404510000042, 7.142112732000157],
              [15.217684747000135, 7.141421795000099],
              [15.218185425000172, 7.141406536000147],
              [15.218925475000106, 7.141384603000176],
              [15.220033645000171, 7.141351224000175],
              [15.221760750000158, 7.140177249000146],
              [15.222243308000088, 7.138037206000092],
              [15.221343994000108, 7.136312008000175],
              [15.220859528000176, 7.134241580999969],
              [15.221756935000087, 7.131756307000046],
              [15.222127915000044, 7.131163120999986],
              [15.223137855000118, 7.129547119000051],
              [15.223482131000026, 7.126717091000046],
              [15.222986221000042, 7.126184463000186],
              [15.221684457000151, 7.124784947000023],
              [15.219472886, 7.123543739000184],
              [15.218094826000026, 7.123220443999969],
              [15.215948105000166, 7.122716902999969],
              [15.213391303000151, 7.122580052000103],
              [15.212353707000091, 7.12147617300019],
              [15.211247445000083, 7.11961317000015],
              [15.192645073000108, 7.085937977000128],
              [15.191745759000128, 7.083246230999976],
              [15.192296982000187, 7.078345776000163],
              [15.193539620000138, 7.076136113000018],
              [15.194022178000068, 7.072960854000144],
              [15.19138431500005, 7.069655895000153],
              [15.19077301100009, 7.068890096000132],
              [15.190593720000095, 7.068754197000146],
              [15.188768387000152, 7.067372322000097],
              [15.184899331000111, 7.067995072000144],
              [15.180547714000056, 7.070826531000023],
              [15.179403305000108, 7.070894242000179],
              [15.178198814000041, 7.070965768000065],
              [15.176471711000033, 7.072484970000176],
              [15.175158501000112, 7.070828914000117],
              [15.172532081000043, 7.06944942500013],
              [15.171358109000096, 7.070278168000186],
              [15.16956329400017, 7.07400608099999],
              [15.16866493200007, 7.073523045000172],
              [15.168317794000131, 7.070831299000076],
              [15.168316841000149, 7.067725181000071],
              [15.166313172000173, 7.066207410000175],
              [15.164100646000065, 7.063999654000099],
              [15.161750793000067, 7.061308383000096],
              [15.160161019000157, 7.058685780000076],
              [15.160505295000064, 7.055648803000111],
              [15.158984184000076, 7.053716660000021],
              [15.158224106000034, 7.051232338000148],
              [15.159535407000078, 7.048954009],
              [15.159811020000063, 7.045778751],
              [15.158152579999978, 7.043639660000054],
              [15.155664443000092, 7.042811871000026],
              [15.153799056000025, 7.041225434000126],
              [15.15441990000005, 7.03880929900015],
              [15.152622223000094, 7.034737588000155],
              [15.15165424300011, 7.032114505000095],
              [15.14909744300013, 7.030804157000034],
              [15.144467354000085, 7.027078152],
              [15.144190788000117, 7.025076866000177],
              [15.143705368000099, 7.020245076000094],
              [15.14225387499999, 7.017967702000135],
              [15.141976357000146, 7.016173363000064],
              [15.142251015000113, 7.007959366000137],
              [15.141696929000091, 7.005543710000097],
              [15.142663956000092, 7.002920627000094],
              [15.141004561000045, 7.000850200000116],
              [15.136375427000189, 6.998021603000098],
              [15.13644409200009, 6.995329857000115],
              [15.136994361000063, 6.987737178000089],
              [15.133259773000134, 6.97296714800018],
              [15.132983208000041, 6.970620156000109],
              [15.137880324999969, 6.945770263999975],
              [15.138984679000032, 6.943491935000054],
              [15.128622055999983, 6.938318253000091],
              [15.114456177000022, 6.914991857000075],
              [15.107059478000053, 6.890213491000168],
              [15.105815887000119, 6.887176991000104],
              [15.103950500000053, 6.884209157000043],
              [15.096075058, 6.873788356000148],
              [15.092550278000033, 6.862193108000156],
              [15.091238022000027, 6.859363080000094],
              [15.081288338000093, 6.83638000600007],
              [15.075209618000031, 6.824922561000051],
              [15.070719718000134, 6.818780423000078],
              [15.068095207000169, 6.814915180000071],
              [15.067265511000073, 6.809048175000044],
              [15.066574096000181, 6.804285527000161],
              [15.065607071000159, 6.797452449000161],
              [15.064777374000187, 6.790688037000052],
              [15.064224243000126, 6.787374974000102],
              [15.063464165000141, 6.784061909000172],
              [15.060701371000164, 6.77840232900013],
              [15.055176735000089, 6.770304204000013],
              [15.050111770000171, 6.764046191000148],
              [15.045140267000136, 6.76192998900018],
              [15.044145585000081, 6.761855126000114],
              [15.040260315000126, 6.761562347000165],
              [15.039434432000121, 6.761768817000188],
              [15.037683487000095, 6.762207032000106],
              [15.034105302000057, 6.763995649000037],
              [15.033264160000101, 6.764415741000164],
              [15.027740479000045, 6.765520573000117],
              [15.018901825000057, 6.768097878000106],
              [15.016115189000061, 6.7693734180001],
              [15.009879112000021, 6.76358890500012],
              [14.99929142100018, 6.760827541000083],
              [14.964859962000162, 6.751347066000051],
              [14.961637496000151, 6.749874115000125],
              [14.949471474000177, 6.720267297000021],
              [14.949457168000038, 6.720231533000117],
              [14.946359635000022, 6.712691785000118],
              [14.943025589, 6.704936981000117],
              [14.939620972000114, 6.697015285000134],
              [14.936965943000132, 6.690837861000034],
              [14.933204651000096, 6.682088376000081],
              [14.9331912990001, 6.682057857000132],
              [14.92942619400003, 6.673299314000133],
              [14.902744294, 6.613564967000116],
              [14.886278152000045, 6.576564313000063],
              [14.872665405000021, 6.544073106000155],
              [14.857584, 6.508318425000027],
              [14.850962638000169, 6.492620468000155],
              [14.84259414800016, 6.475959779000164],
              [14.82981300400013, 6.4515666960001],
              [14.806182862000185, 6.408302784000114],
              [14.801402093000149, 6.401122093000083],
              [14.812823295000101, 6.364129066000032],
              [14.790207863000091, 6.328779220000115],
              [14.752495766000038, 6.270310880000181],
              [14.743775367000183, 6.256798267000079],
              [14.741016388000048, 6.252739907000034],
              [14.740602494000086, 6.252130508000107],
              [14.739759444000185, 6.250890255000115],
              [14.73549175200003, 6.247935294000115],
              [14.729298592000077, 6.245419026000093],
              [14.69699096700009, 6.23434353000016],
              [14.692785263000076, 6.232864858000028],
              [14.644535065000014, 6.215902806000031],
              [14.630760194000061, 6.211060524000175],
              [14.604598046000092, 6.201863290000176],
              [14.59234619200015, 6.197498321000069],
              [14.586903572000097, 6.195715427000152],
              [14.586573601000055, 6.195607185000085],
              [14.576145172000111, 6.192191124000146],
              [14.572061539000174, 6.191383839000025],
              [14.570588112, 6.192052841000134],
              [14.567841531000056, 6.193390846000114],
              [14.566302299000085, 6.191647054000043],
              [14.565746308000143, 6.191735745000187],
              [14.564628600000049, 6.191913604000092],
              [14.563021660000118, 6.19110822600004],
              [14.551779748000058, 6.182118893000109],
              [14.547831536000047, 6.178898812000057],
              [14.545289039000124, 6.176014901000087],
              [14.543416976, 6.172997474999988],
              [14.542765618000146, 6.172224044000075],
              [14.540875435000146, 6.169979573000092],
              [14.539936066000166, 6.169800758000122],
              [14.538397789000157, 6.169508457000177],
              [14.537747383000124, 6.169359685000131],
              [14.536925317000168, 6.169171811000183],
              [14.534858703000054, 6.16798782300009],
              [14.534113883000089, 6.167561055000135],
              [14.531436919999976, 6.165883542000188],
              [14.529831887000171, 6.164206506000141],
              [14.529297829000029, 6.162396908000176],
              [14.528028488000075, 6.15871000300001],
              [14.525621415000103, 6.155356884000128],
              [14.522341729, 6.153410435000126],
              [14.521807671000033, 6.15186882],
              [14.518262863000018, 6.148112774000083],
              [14.514781952000078, 6.146099090000121],
              [14.512708665000048, 6.143550394999977],
              [14.510972023000022, 6.139930248000042],
              [14.510170936000122, 6.137047768000116],
              [14.510172844000067, 6.13423299800013],
              [14.508501053000032, 6.132555962000083],
              [14.508636474000127, 6.130545616000177],
              [14.508507728000154, 6.130040645000065],
              [14.508036613000172, 6.128199578000078],
              [14.50642681100004, 6.126585960000114],
              [14.505895615000099, 6.126053333000186],
              [14.50281715400007, 6.124911309000026],
              [14.499671936000027, 6.122964857999989],
              [14.495658874000185, 6.118671894000102],
              [14.492648125000017, 6.116390705000072],
              [14.489837646000126, 6.114712716000042],
              [14.486289024000143, 6.114843368000038],
              [14.485980987000119, 6.113495827000179],
              [14.485355378000122, 6.110754491000023],
              [14.485329627000056, 6.110710621000123],
              [14.484085083000139, 6.108608723000089],
              [14.482838630000117, 6.107928276000052],
              [14.480271340000115, 6.106527328000141],
              [14.475455284000077, 6.102032662],
              [14.473314286000061, 6.100087166000151],
              [14.472781181000073, 6.097473144000048],
              [14.472586632000059, 6.09701204400011],
              [14.472046852000176, 6.095729827000127],
              [14.470600129000104, 6.09452915300011],
              [14.470106126000189, 6.094119549000141],
              [14.467965126000138, 6.093045711000116],
              [14.464150429000085, 6.091701507999972],
              [14.462276459000066, 6.091096402],
              [14.459398269000133, 6.090691566000089],
              [14.457147599000109, 6.090729237000119],
              [14.455648423000014, 6.090754509000078],
              [14.45495414800007, 6.090398312000161],
              [14.452637672000037, 6.089210034000075],
              [14.450863839000135, 6.089037418000032],
              [14.449156761999973, 6.088871480000137],
              [14.447148323000022, 6.088802815000065],
              [14.445692062000035, 6.08608341200005],
              [14.444274903000121, 6.08343839600019],
              [14.442807196999979, 6.083362102000081],
              [14.440324783000108, 6.083233356000108],
              [14.438014030000147, 6.081389427000033],
              [14.437381745000152, 6.080884456000092],
              [14.43376731800015, 6.080478667000023],
              [14.429348946000118, 6.079803943000115],
              [14.426385880000112, 6.080518246000167],
              [14.425197602000026, 6.080804824999973],
              [14.423191070000087, 6.079328538000141],
              [14.424597741000014, 6.077788354000063],
              [14.425871848000043, 6.075980664000099],
              [14.424336434000168, 6.071690083000078],
              [14.420998573000134, 6.063577653000152],
              [14.420266151000135, 6.059823990000155],
              [14.419198989999984, 6.055869102000145],
              [14.41973877099997, 6.052183629000126],
              [14.420143127000074, 6.04970455300014],
              [14.418004990000156, 6.045346261000134],
              [14.417901992000168, 6.043962955999973],
              [14.417740822000155, 6.041794300000163],
              [14.41848278000009, 6.037223815000061],
              [14.418822288000115, 6.03246641200019],
              [14.42103290600005, 6.030591965000099],
              [14.422510148000072, 6.026907922000021],
              [14.425455094000029, 6.026710033000029],
              [14.428000450000127, 6.025707246000138],
              [14.42825794200013, 6.025166035000041],
              [14.42927456000001, 6.023028375000081],
              [14.428341866000096, 6.019207478000055],
              [14.428747178000094, 6.015655995000088],
              [14.430008887000099, 6.011567116000094],
              [14.432970046000094, 6.009829999000147],
              [14.434177400000124, 6.008223057000066],
              [14.433911323000075, 6.005877019000081],
              [14.433647155000074, 6.002861023000151],
              [14.434786796000139, 6.000986100000148],
              [14.434321404000173, 5.998103620000052],
              [14.436732292000158, 5.996498106999979],
              [14.438541412000063, 5.99549436500007],
              [14.440751075000037, 5.994491578000179],
              [14.442894936000073, 5.992483139000171],
              [14.446446420000086, 5.98886823700019],
              [14.451940537000041, 5.983579159000101],
              [14.457300186, 5.978491306000024],
              [14.462123872000177, 5.974475385000176],
              [14.463999748000106, 5.972265721000156],
              [14.465341568000099, 5.969452382000156],
              [14.465678216000072, 5.96677207900018],
              [14.466216085999974, 5.964426994],
              [14.46682167, 5.961613178000107],
              [14.467761039000038, 5.958732127000189],
              [14.468968392000022, 5.956856727000115],
              [14.470777512000097, 5.954513072000054],
              [14.471582413000021, 5.952570439000169],
              [14.472389221000128, 5.949287415000129],
              [14.473461151000038, 5.947479248000036],
              [14.474936485000057, 5.944933891000176],
              [14.477214814000035, 5.941987515000108],
              [14.47888851200014, 5.941385747000084],
              [14.480696677000083, 5.939980031000118],
              [14.483308792000116, 5.938106061000156],
              [14.483913421000125, 5.93596220000012],
              [14.485523223000087, 5.932746887000064],
              [14.48519039100006, 5.930535316000146],
              [14.484189033000177, 5.927786350000076],
              [14.484592437000174, 5.925977707000072],
              [14.486334801000112, 5.923499584000126],
              [14.487206459999982, 5.921087743000157],
              [14.487677574000088, 5.918943883000054],
              [14.488483429000041, 5.915995597000176],
              [14.489622116000021, 5.914522648000059],
              [14.494845390000023, 5.911712647000172],
              [14.49698829600004, 5.91064214700009],
              [14.498395919000075, 5.908298016000174],
              [14.498599053000191, 5.905349256000079],
              [14.500675201000035, 5.903876782000111],
              [14.502684593000026, 5.902404308000143],
              [14.50455856300016, 5.901467801000024],
              [14.508040428000015, 5.8999967580001],
              [14.51038456100008, 5.898524284000075],
              [14.512059212, 5.896649361000129],
              [14.513466835000031, 5.894170762000158],
              [14.517012596000086, 5.894978047000052],
              [14.521295547000136, 5.895987034000143],
              [14.523504258, 5.896122933000072],
              [14.526581765000174, 5.896595001000094],
              [14.530998229000033, 5.897737979000169],
              [14.536016464, 5.89914941800015],
              [14.539428712000074, 5.900425435000102],
              [14.541838645000098, 5.899556160000032],
              [14.543645859000037, 5.899490834000119],
              [14.545452119000117, 5.900832654000112],
              [14.548462868000058, 5.901974202000076],
              [14.553215028000182, 5.901643277000176],
              [14.557298659000082, 5.900372983000011],
              [14.559507370000176, 5.90037489000008],
              [14.560777664999989, 5.902118207000171],
              [14.563386917000059, 5.902589321000107],
              [14.565332413000078, 5.903217316000109],
              [14.567133903000126, 5.90379858100016],
              [14.568094253000027, 5.904595853000046],
              [14.56907367600013, 5.905408383000122],
              [14.571479796000062, 5.910101414000167],
              [14.573631287000183, 5.911720277000086],
              [14.575224877000039, 5.912919044999967],
              [14.578888893000169, 5.917062760000079],
              [14.580976486000168, 5.919424058000061],
              [14.584655761000022, 5.921571256000107],
              [14.586179733000165, 5.922198773000048],
              [14.587265014000025, 5.922645569000054],
              [14.588594436000051, 5.922985077000078],
              [14.591213225999979, 5.923653603000162],
              [14.595417023000095, 5.923432351000145],
              [14.596233369000174, 5.923389436000093],
              [14.599580764999985, 5.922855854000147],
              [14.602393150000069, 5.920847417000175],
              [14.603921891000027, 5.919228554000085],
              [14.604670524000028, 5.918436051000128],
              [14.607014656000047, 5.915287972000158],
              [14.611437798000054, 5.907450199000095],
              [14.614921569000103, 5.901890279000156],
              [14.615392684000085, 5.898941993000108],
              [14.616799355000012, 5.896798134999983],
              [14.618943215000115, 5.892979621000052],
              [14.620351791000132, 5.889361859000076],
              [14.620622634000085, 5.884067536000146],
              [14.620359421999979, 5.878370762000031],
              [14.621029853000152, 5.875891685],
              [14.620161057000189, 5.873746872000083],
              [14.618490219000137, 5.871332647000088],
              [14.617093086000068, 5.858263492000106],
              [14.616559981000137, 5.855649471999982],
              [14.615489959000058, 5.85410738000013],
              [14.614821435000181, 5.85283374800008],
              [14.616294861000029, 5.85142755600009],
              [14.616166115000055, 5.843653202000041],
              [14.616637230000038, 5.840771675000099],
              [14.618110656000113, 5.838896274000092],
              [14.617844580999986, 5.836081504999982],
              [14.617979050000031, 5.834808350000117],
              [14.619656564000081, 5.829045773000132],
              [14.621131898000158, 5.823551655000131],
              [14.622739791000015, 5.820670605000146],
              [14.622875214000146, 5.818124294000029],
              [14.622274399000048, 5.815979003000109],
              [14.620871543000021, 5.81302976700016],
              [14.621474266000064, 5.811689377000107],
              [14.622279167000102, 5.809344769000063],
              [14.623217583000155, 5.806865693000134],
              [14.624089241000149, 5.804185390000157],
              [14.624960900000019, 5.801170349000131],
              [14.625297546000184, 5.79822158800016],
              [14.624363899000116, 5.793261528000073],
              [14.622961044000135, 5.790647031000049],
              [14.621758460000081, 5.787496566000073],
              [14.621960639000065, 5.785620213000129],
              [14.62330055200016, 5.782739163000144],
              [14.624105453000027, 5.779925346000141],
              [14.623103143000094, 5.777176857000029],
              [14.623105050000163, 5.774228096000172],
              [14.623641968000186, 5.77235174100008],
              [14.623644828000067, 5.768599033000101],
              [14.622777940000105, 5.764040947000069],
              [14.622778893000088, 5.762365818000149],
              [14.624386788000152, 5.759082794000108],
              [14.624588967000079, 5.756536485000027],
              [14.624592780000114, 5.751376153000081],
              [14.623925209000049, 5.748694897000064],
              [14.623190879000049, 5.745678426000154],
              [14.623059273000024, 5.742729662999977],
              [14.623127937000163, 5.739914894000094],
              [14.623932839000133, 5.737369062000084],
              [14.625205039000093, 5.734889983000073],
              [14.625073433000011, 5.732879638999975],
              [14.624874116000171, 5.729997634000029],
              [14.625277520000111, 5.726446152000165],
              [14.626216889000148, 5.722559452999974],
              [14.627022744000101, 5.717667579000079],
              [14.628096580000147, 5.713111400000116],
              [14.627830505000077, 5.71049738],
              [14.627564430000177, 5.708017826],
              [14.626362801000141, 5.703727723000043],
              [14.623558999000124, 5.695214749],
              [14.621889114000055, 5.690522194000153],
              [14.62001800600018, 5.687237263000043],
              [14.617944718000047, 5.68609666899999],
              [14.617612838000127, 5.683013440000082],
              [14.614139558000147, 5.67449998799998],
              [14.610599517000082, 5.665182114000174],
              [14.607660294000141, 5.659081459000106],
              [14.607060433000186, 5.655998231000126],
              [14.607061386000169, 5.653853893000132],
              [14.60786628600016, 5.651374817000033],
              [14.608201980000047, 5.649364471000126],
              [14.607399940000107, 5.647487640000179],
              [14.606933593000178, 5.645677568000167],
              [14.60700225800008, 5.642862796000145],
              [14.606267929000126, 5.640717983000059],
              [14.605533600000058, 5.638103485000102],
              [14.603260993000049, 5.635086537000063],
              [14.602860452000016, 5.633410453000067],
              [14.600922584000102, 5.630527496000013],
              [14.599386215999971, 5.627041340000062],
              [14.598452568000027, 5.623086929000181],
              [14.598588944000085, 5.619132996000133],
              [14.597789764000026, 5.614709377000168],
              [14.595985412000175, 5.611423969999976],
              [14.594114304000072, 5.608876228000042],
              [14.591908456000169, 5.606126785000186],
              [14.591776847000176, 5.603713989000028],
              [14.592313767, 5.601436137000064],
              [14.592449189000035, 5.598755359000109],
              [14.591581345000179, 5.595604896000111],
              [14.591516495000121, 5.593661308000094],
              [14.591518403000123, 5.59077978300013],
              [14.591990469999985, 5.584949494000114],
              [14.592393876000187, 5.581263542000102],
              [14.594000817000165, 5.579388142000028],
              [14.596008300999983, 5.578250408000031],
              [14.59855079700003, 5.576978684000153],
              [14.599956513, 5.575639248000186],
              [14.601048469000091, 5.573964120000142],
              [14.601965904999986, 5.572557926000116],
              [14.604176521000113, 5.567466260000174],
              [14.605318069000077, 5.565900327],
              [14.605983733000073, 5.564987659],
              [14.606310844000063, 5.563560487000075],
              [14.606376649000083, 5.563271046000182],
              [14.606520653000075, 5.562642574000051],
              [14.607927322000023, 5.559292317000029],
              [14.607929229000092, 5.556410790000086],
              [14.607196807999969, 5.551651954000022],
              [14.606462477999969, 5.54930591700014],
              [14.608938217000116, 5.547430992000159],
              [14.609675408000101, 5.545889854],
              [14.609542847000057, 5.543343545000084],
              [14.610280990000092, 5.540126802000145],
              [14.61034965499999, 5.53771448100008],
              [14.611889840000174, 5.535168647000148],
              [14.612292289000152, 5.532957553000131],
              [14.61436653100003, 5.531417371000089],
              [14.616106987000137, 5.529072761000066],
              [14.619453429000089, 5.526193141000022],
              [14.623267173000158, 5.52378273100004],
              [14.625409126000022, 5.521170616],
              [14.626268386000049, 5.519386292000036],
              [14.627150535000112, 5.51755285400003],
              [14.629961967000042, 5.513533593000091],
              [14.630038261000152, 5.513173104000032],
              [14.630498887000044, 5.510987281000098],
              [14.630392073999985, 5.510505199000136],
              [14.6300983430001, 5.509177209000029],
              [14.627157210000064, 5.506494998000107],
              [14.624015808000024, 5.503208637000057],
              [14.623548508000056, 5.501667022000106],
              [14.622880935000182, 5.499588967000022],
              [14.620741845000111, 5.497845650000102],
              [14.618603707000091, 5.495096207000074],
              [14.618404388000044, 5.492817402000128],
              [14.618941308000046, 5.489935875000185],
              [14.618917464000049, 5.489903451000146],
              [14.617337227000121, 5.487790584000038],
              [14.614863395999976, 5.486582755000029],
              [14.615667344000087, 5.48477363600017],
              [14.617074013000035, 5.482429028000126],
              [14.617349625000088, 5.481944085000066],
              [14.61874771100014, 5.479481220000139],
              [14.61975288300016, 5.476398945000085],
              [14.618781090000141, 5.475424290000035],
              [14.617814065000175, 5.474454404000085],
              [14.615072251000129, 5.474184514000115],
              [14.614271164, 5.471771241],
              [14.613842011000145, 5.470934392000117],
              [14.613136290999989, 5.469558715000119],
              [14.612486839999974, 5.468024731000185],
              [14.612000465000165, 5.466877462000184],
              [14.610397340000134, 5.464396953000062],
              [14.608391761000178, 5.462787152000146],
              [14.60611915700008, 5.461110114000121],
              [14.603376388000072, 5.462046623000106],
              [14.602508545000148, 5.460370542000078],
              [14.599987984000052, 5.458249570000078],
              [14.602911949000088, 5.457355023000048],
              [14.604183196000065, 5.455412387000081],
              [14.604653358000064, 5.452597618000141],
              [14.605189323000047, 5.451526165000018],
              [14.605525017000161, 5.450185777000172],
              [14.604722977000051, 5.448643685000093],
              [14.603854179000109, 5.447302819000186],
              [14.603589058000068, 5.443884848999971],
              [14.60278892600013, 5.441136361000133],
              [14.600517272000104, 5.43785095200019],
              [14.600251197000034, 5.435773372000028],
              [14.600253105000036, 5.432891847000121],
              [14.598983765000185, 5.430008889000135],
              [14.59905147600017, 5.428467752000074],
              [14.599120139000092, 5.4258542070001],
              [14.599456787000065, 5.423307420000128],
              [14.600527763, 5.421967983000059],
              [14.601198196000155, 5.419153691000076],
              [14.600597381000114, 5.416606426000101],
              [14.599529266000104, 5.414796354000089],
              [14.596857071000045, 5.410170079000181],
              [14.596565247000058, 5.409735679000107],
              [14.595327376000057, 5.407890320000149],
              [14.59516143799999, 5.407643319000044],
              [14.59465313000004, 5.406884671000114],
              [14.593250274000127, 5.403935432000083],
              [14.59117794000008, 5.403800011000158],
              [14.590441705000046, 5.404335499000069],
              [14.589570999000102, 5.406479359000173],
              [14.587097168000128, 5.40614271100003],
              [14.586428642000101, 5.405137061000119],
              [14.585159302000022, 5.404130936000115],
              [14.58301925700016, 5.403928280000059],
              [14.580544471000053, 5.40426159000009],
              [14.579008101999989, 5.402451038000038],
              [14.577404976000082, 5.400372505000064],
              [14.574596405000136, 5.399566173000153],
              [14.573058128000127, 5.399967194000055],
              [14.573527337000144, 5.398694039000134],
              [14.573728562000156, 5.397554874000093],
              [14.573997497000107, 5.395075797000061],
              [14.574199676000035, 5.392998220000038],
              [14.572796821000054, 5.39158964100011],
              [14.57146072300003, 5.389846325000121],
              [14.571863173999986, 5.388305187000128],
              [14.573334693000163, 5.386831761000053],
              [14.573335648000182, 5.385223388000156],
              [14.572870254, 5.382006170000011],
              [14.572271346000036, 5.378252984000028],
              [14.571068764000188, 5.376710892000119],
              [14.56839466200006, 5.375502587000085],
              [14.56578731500008, 5.374428272000102],
              [14.564652444000046, 5.372818947000098],
              [14.564653396000153, 5.371478559000025],
              [14.563717842000187, 5.370606900000098],
              [14.563919067000029, 5.369467735000058],
              [14.562248231000183, 5.368662357000062],
              [14.561446191000073, 5.367321492000031],
              [14.560579300000029, 5.364371776000041],
              [14.560513496999988, 5.362629414000139],
              [14.560982704000025, 5.361423493000075],
              [14.561986924000166, 5.359279632000096],
              [14.562122345000091, 5.35659885400014],
              [14.562324525000122, 5.354856490000032],
              [14.562125206000019, 5.352577686000132],
              [14.56099033400011, 5.350633622000032],
              [14.561191559000122, 5.349159241000166],
              [14.560858726000049, 5.347550870000077],
              [14.558985711000048, 5.347951412000157],
              [14.557917596000038, 5.346275330000026],
              [14.557518005000134, 5.344532489000187],
              [14.557452202000093, 5.342990876000044],
              [14.556181907000052, 5.341984749000062],
              [14.556517600000063, 5.340577603000156],
              [14.556385041000169, 5.339371204000088],
              [14.555182457000171, 5.33756113000004],
              [14.553444861000116, 5.336688519000063],
              [14.553512574000081, 5.335549355000126],
              [14.552577018000136, 5.334275246000118],
              [14.551441192000141, 5.332799912000098],
              [14.552578926000137, 5.331460476000132],
              [14.552714347000062, 5.329516888000057],
              [14.552381516000139, 5.327707291000024],
              [14.552382469000122, 5.326165676000073],
              [14.553253174000133, 5.324692249000123],
              [14.552919389000124, 5.323418617000073],
              [14.551047326000059, 5.322881222000092],
              [14.549845694999988, 5.321070672000019],
              [14.548442842000043, 5.318992137000123],
              [14.548644066000179, 5.317718982000031],
              [14.547909737000055, 5.316578865999986],
              [14.545569420000106, 5.316309452000098],
              [14.544098853000037, 5.316241265000031],
              [14.546707153000057, 5.314701558000081],
              [14.549048423000102, 5.313429834000033],
              [14.548982620000118, 5.311888694000061],
              [14.547646522000036, 5.310346127000059],
              [14.54798221499999, 5.308737754000106],
              [14.549185754, 5.308202744000084],
              [14.549721717000068, 5.307130814000061],
              [14.549457549000067, 5.30310964500012],
              [14.548188210000092, 5.301701069000103],
              [14.546116828000152, 5.300292493000086],
              [14.543709755000179, 5.299553394000157],
              [14.540836335000108, 5.297741891000101],
              [14.538297654000132, 5.295662404000098],
              [14.537096023000061, 5.292980671000123],
              [14.533153533000188, 5.289761067000029],
              [14.532152176000011, 5.288151742000025],
              [14.527144431000011, 5.28379488000013],
              [14.527543068000057, 5.282533169000089],
              [14.528680801000121, 5.28085851700007],
              [14.528281212000024, 5.279517650000059],
              [14.527813912000056, 5.277708055000176],
              [14.528684616000191, 5.275362968000025],
              [14.529488564000076, 5.273755075000111],
              [14.530826569000055, 5.272348881000141],
              [14.533770561999972, 5.269067288000144],
              [14.534621239000103, 5.26831340699999],
              [14.53611278600016, 5.266991138000151],
              [14.53758335100008, 5.266724110000041],
              [14.538259507000078, 5.266182900000047],
              [14.538921356, 5.265653133000171],
              [14.543068885000082, 5.262573243000077],
              [14.544440269000063, 5.261468886999978],
              [14.546145439000099, 5.260095597000088],
              [14.547327995000046, 5.258123397000134],
              [14.547551155000065, 5.257751465000069],
              [14.548029900000131, 5.257400036000036],
              [14.548554420000187, 5.257014753000078],
              [14.550359726000181, 5.256948947000126],
              [14.551171302000171, 5.256656646000181],
              [14.552031517000046, 5.256347179000045],
              [14.552835465000101, 5.254605293000168],
              [14.554774284000018, 5.253601551000088],
              [14.555169105000118, 5.252929689000098],
              [14.556113244000187, 5.251323701000104],
              [14.556395532000067, 5.250908852000123],
              [14.557116508000036, 5.249850274000153],
              [14.558144569000092, 5.2487721440001],
              [14.558522224000171, 5.248376370000017],
              [14.559638024000151, 5.248190879000049],
              [14.560527801000092, 5.248043060000157],
              [14.562601090000157, 5.247374058000048],
              [14.56440735000001, 5.245297909000158],
              [14.565333366000118, 5.244324208000137],
              [14.565745353000182, 5.243891240000039],
              [14.568621635000113, 5.24127960300018],
              [14.568830490000096, 5.240728379000075],
              [14.56935882700003, 5.23933649200012],
              [14.569265365000092, 5.237545490000059],
              [14.569226266000157, 5.236789703000113],
              [14.571566583000049, 5.236322403000031],
              [14.571745873000168, 5.236322403000031],
              [14.572569847000125, 5.236323356000185],
              [14.573414802000116, 5.235799313000086],
              [14.573973656000021, 5.235452652000106],
              [14.574843406000014, 5.234113217000186],
              [14.575230599000179, 5.233798505000095],
              [14.575914383999987, 5.233242511000071],
              [14.57654762400017, 5.233529092000083],
              [14.577250480999965, 5.233846665000158],
              [14.579122544000086, 5.233780861000071],
              [14.582064630000104, 5.233246804000032],
              [14.591626168000062, 5.230907440000067],
              [14.592819214000031, 5.229926109000132],
              [14.59349823000008, 5.229367257000092],
              [14.594304086000136, 5.227351665000128],
              [14.594570161000036, 5.226686954000115],
              [14.595774650000124, 5.225146294000183],
              [14.597111702000063, 5.22494602300003],
              [14.600052833000177, 5.22467994800013],
              [14.603195190000179, 5.224681855000029],
              [14.605232238000156, 5.224391461000153],
              [14.606002807000039, 5.224281789000145],
              [14.607047081000132, 5.223585129000071],
              [14.607407570000191, 5.223344325000028],
              [14.609748840000123, 5.221335411000041],
              [14.610952378000036, 5.221135139000182],
              [14.612823486000138, 5.221471310000027],
              [14.615096093000147, 5.221740722000106],
              [14.616744042000164, 5.222605228000077],
              [14.618037223000158, 5.223283767000169],
              [14.620777131000182, 5.224357605000023],
              [14.622448922000046, 5.223621368000124],
              [14.623273849000157, 5.223047256000029],
              [14.623987197000019, 5.222550393000063],
              [14.625592232000031, 5.221612931000095],
              [14.626304626000035, 5.221756458000186],
              [14.627263070000083, 5.221949100000131],
              [14.629369736000172, 5.221884251000176],
              [14.63154220600012, 5.22181749400005],
              [14.632253647000084, 5.221254825000131],
              [14.632812500000057, 5.220812799000157],
              [14.634307861000082, 5.218848229000173],
              [14.634953498000129, 5.217999459000112],
              [14.636893272, 5.216660022000042],
              [14.639366150000171, 5.216125489000092],
              [14.641104699000039, 5.216327667000144],
              [14.643950462000078, 5.216673375000141],
              [14.64498138499999, 5.216798783000058],
              [14.647923469000091, 5.216867447000027],
              [14.647970200000145, 5.21685504900006],
              [14.649661065000146, 5.216399192000097],
              [14.652135848000171, 5.214256287000183],
              [14.65334034000017, 5.212112426000147],
              [14.653542519000098, 5.209297657000036],
              [14.654212950999977, 5.205276489000084],
              [14.655217170000014, 5.203803062000077],
              [14.657958984000061, 5.20186090600015],
              [14.658354760000122, 5.201723577000053],
              [14.659620284000027, 5.201283931000091],
              [14.661234855000146, 5.200723172000153],
              [14.663040162000186, 5.199718953000115],
              [14.663614273000178, 5.19867038700005],
              [14.6641950610001, 5.197608471000137],
              [14.664737701000035, 5.195766925000157],
              [14.680366516000106, 5.142711639000083],
              [14.692684173000089, 5.102640152000049],
              [14.693488121000144, 5.099825382000176],
              [14.693221093000091, 5.09794902900012],
              [14.690414429000043, 5.098550797000144],
              [14.690148354000144, 5.095668792000026],
              [14.689949035000041, 5.093054772000073],
              [14.689014434000114, 5.091177942000059],
              [14.687210082000036, 5.08923339800009],
              [14.684336662000135, 5.087623596000128],
              [14.681730270000116, 5.08695220900006],
              [14.678589820000184, 5.086146356000086],
              [14.675715446000027, 5.086345673000153],
              [14.674379348000116, 5.085474015000159],
              [14.674380303000135, 5.083932400000037],
              [14.674983023999971, 5.081184864000079],
              [14.67498397900016, 5.078973293000161],
              [14.674250602000143, 5.076359272000161],
              [14.674250602000143, 5.074817658000143],
              [14.6746530530001, 5.073343754000064],
              [14.675789834000113, 5.070998669000062],
              [14.677863120999973, 5.06845283500013],
              [14.679401398000152, 5.06644296800016],
              [14.681674957000098, 5.064232349000122],
              [14.681913376000182, 5.063579559000118],
              [14.682595252999988, 5.061714173000155],
              [14.682679177000011, 5.061485291000054],
              [14.683080673, 5.058938504000082],
              [14.682881356000166, 5.056659699000079],
              [14.681613922000111, 5.052504062000082],
              [14.681281088999981, 5.05015802500003],
              [14.681549072000053, 5.047879696000109],
              [14.682085037000093, 5.045333386000095],
              [14.68389129600007, 5.042988301000094],
              [14.684961319000024, 5.040576458000146],
              [14.68502903000001, 5.039034843000025],
              [14.684495927000057, 5.036085605000096],
              [14.684430122000037, 5.033338069000138],
              [14.684965134000095, 5.032198906000076],
              [14.686703683000133, 5.030256272000145],
              [14.687640190000081, 5.028513908000036],
              [14.68790817200005, 5.026905537000062],
              [14.688349723000101, 5.024918556999978],
              [14.688459395999985, 5.024424076],
              [14.688712120000105, 5.023286820000067],
              [14.688646317000121, 5.020941258000164],
              [14.687845229000061, 5.02006960000017],
              [14.68263244700006, 5.018994809000162],
              [14.678689957000188, 5.018456937000053],
              [14.678757668, 5.017116546000068],
              [14.68022823199999, 5.015508651000175],
              [14.682467953000128, 5.013840543000185],
              [14.682834847000095, 5.013566858000161],
              [14.683304786000178, 5.011354924000102],
              [14.683238982999967, 5.009411335000152],
              [14.682744151000065, 5.00897321500014],
              [14.682103, 5.008406],
              [14.678816, 5.009393000000159],
              [14.676756000000125, 5.010011000000134],
              [14.677655, 5.008769],
              [14.678202057000135, 5.008013315000142],
              [14.678695678000054, 5.007331371000021],
              [14.679765701, 5.004852294000159],
              [14.680536152000059, 5.002950311000063],
              [14.680725, 5.002484000000152],
              [14.680769001999977, 5.002375461000099],
              [14.680755441000031, 5.002133378000053],
              [14.680746, 5.001978000000179],
              [14.680636000000163, 5.000162001000149],
              [14.680621661000146, 5.000133851000101],
              [14.677786, 4.994567000000188],
              [14.677698379000049, 4.994394576000161],
              [14.677509930000156, 4.993963143000087],
              [14.676036266999972, 4.990589346000093],
              [14.67603, 4.990575000000149],
              [14.67607, 4.989897000000156],
              [14.676098, 4.989436],
              [14.676629000000105, 4.98916],
              [14.677267415000074, 4.988828506000061],
              [14.677902221000011, 4.988498688000107],
              [14.680576220000034, 4.987829712000178],
              [14.681103839000059, 4.987138676000029],
              [14.681296349000036, 4.986886502000118],
              [14.681325000000186, 4.986848000000123],
              [14.681445000000167, 4.986691000000121],
              [14.681644, 4.986116000000152],
              [14.681648482000128, 4.986103056000104],
              [14.681885058000148, 4.985419680000064],
              [14.682047845, 4.984948636000183],
              [14.682048608000173, 4.984937444000138],
              [14.682050000000118, 4.984911],
              [14.68205598600008, 4.984823212000094],
              [14.682152492000057, 4.983478615000138],
              [14.682249, 4.982134],
              [14.682298938000088, 4.981988026000181],
              [14.683109952000052, 4.979617374000156],
              [14.68312, 4.979588],
              [14.68387157300009, 4.978358262000029],
              [14.684389247000126, 4.97751123300003],
              [14.684556013000076, 4.97737452199999],
              [14.686195, 4.97603700000019],
              [14.68663124200009, 4.975846714000056],
              [14.687150999000039, 4.975620000000163],
              [14.688802000000123, 4.974899000000164],
              [14.690835999000114, 4.974325000000135],
              [14.691267743000083, 4.974202723000019],
              [14.691407998999978, 4.9741629990001],
              [14.691585999000154, 4.97294999900015],
              [14.691710970000088, 4.972106852000081],
              [14.691743852000172, 4.971884252000166],
              [14.691756927000085, 4.971708127000056],
              [14.691782000000103, 4.971367000000157],
              [14.691839, 4.970600000000104],
              [14.691878, 4.970075000000179],
              [14.692073600000072, 4.96940437000012],
              [14.692345798000019, 4.968471126000168],
              [14.692982590000156, 4.966849864000153],
              [14.693215999000188, 4.966255999000111],
              [14.694328, 4.964028],
              [14.694419112000162, 4.963846755000077],
              [14.69529056500005, 4.961297036000076],
              [14.695491790000119, 4.959755421000125],
              [14.695237884000051, 4.959373474000074],
              [14.695131000000117, 4.959213000000148],
              [14.694820493000066, 4.958745240000155],
              [14.694190830000139, 4.95755040500012],
              [14.69362163500017, 4.956470966999973],
              [14.69255352, 4.954526901000065],
              [14.691791534, 4.953100898000116],
              [14.690687, 4.951035000000104],
              [14.689616000000115, 4.949030000000107],
              [14.688857612999982, 4.947363395000139],
              [14.688548, 4.946683],
              [14.689818000000173, 4.944472],
              [14.690501, 4.943059],
              [14.690534400000104, 4.942989911000097],
              [14.690821648000053, 4.942395210000029],
              [14.691486631000089, 4.940942926000105],
              [14.69155787700015, 4.940787270000044],
              [14.691357613000093, 4.939446927000063],
              [14.689353942000139, 4.937636375000182],
              [14.685346604000188, 4.934283257000061],
              [14.682941, 4.932271],
              [14.682942, 4.930261],
              [14.68321, 4.929189000000179],
              [14.684162426000114, 4.928677917000073],
              [14.685081, 4.928185],
              [14.686364121000111, 4.92739152300004],
              [14.688223000000164, 4.926241999000126],
              [14.688725908000038, 4.925542193000126],
              [14.689426, 4.924568],
              [14.689767398000072, 4.92359907700012],
              [14.690228999000112, 4.922288999000102],
              [14.690955761000055, 4.920678026000132],
              [14.691165925, 4.920211792999965],
              [14.692771912000126, 4.916727542000103],
              [14.693087069000171, 4.915870647000133],
              [14.693701599000065, 4.914198420999981],
              [14.693708001000061, 4.914180999000166],
              [14.694819797000036, 4.913108625000064],
              [14.695512771000153, 4.912439824000046],
              [14.697718619000057, 4.910161972000083],
              [14.698125838000067, 4.908684730000061],
              [14.698254584999972, 4.908218861000137],
              [14.698369628000023, 4.907377821000182],
              [14.698388000000136, 4.907242999000061],
              [14.698657, 4.905270000000144],
              [14.69992800000017, 4.900177],
              [14.700465000000179, 4.89662599899998],
              [14.701267999000095, 4.89273799900019],
              [14.70126819, 4.892726886000048],
              [14.701309, 4.890336000000104],
              [14.701337, 4.888717],
              [14.704478, 4.886775000000171],
              [14.704952, 4.886134],
              [14.705815, 4.884966],
              [14.705815, 4.883864000000187],
              [14.705816, 4.88235200000014],
              [14.706021601000145, 4.881155634000095],
              [14.706218647000071, 4.880009057000166],
              [14.707489000000123, 4.878064000000109],
              [14.70870600000012, 4.876612],
              [14.708799517000102, 4.876500479000129],
              [14.708893000000103, 4.876388999000142],
              [14.709001000000171, 4.87618299900015],
              [14.709981528000128, 4.87430545300009],
              [14.710363, 4.87357500000013],
              [14.71085257500016, 4.873020064],
              [14.712272644000166, 4.87140989400001],
              [14.71277359000004, 4.870841978000101],
              [14.712903999000105, 4.870693999000025],
              [14.713284, 4.870269000000121],
              [14.713395642000023, 4.870144651000032],
              [14.713406561999989, 4.870132446000184],
              [14.714641572000062, 4.868751049000025],
              [14.7141965510001, 4.867490064000037],
              [14.714134000000172, 4.867313000000138],
              [14.713908000000117, 4.866673000000162],
              [14.7133070000001, 4.864461],
              [14.71237, 4.86126],
              [14.71230697600015, 4.861042976000078],
              [14.712277777000168, 4.860963395000169],
              [14.711371999000164, 4.858496000000173],
              [14.710901625000133, 4.85840889300016],
              [14.708834, 4.858026],
              [14.707398499000021, 4.857931203000078],
              [14.705756635000057, 4.857817745000091],
              [14.705541, 4.857481],
              [14.705159, 4.856884],
              [14.705690000000118, 4.855903],
              [14.706025457000123, 4.855283543000041],
              [14.707833008000023, 4.852529972000184],
              [14.708095782000044, 4.851609869000185],
              [14.708502770000166, 4.850183964000053],
              [14.708236695000096, 4.84783840200015],
              [14.707595813000182, 4.844918390000146],
              [14.70723571700006, 4.843279662999976],
              [14.70702, 4.843023000000187],
              [14.706249, 4.842105],
              [14.708717000000149, 4.84056900000013],
              [14.708768206000116, 4.840537217000076],
              [14.708867074000125, 4.840467931000035],
              [14.709095707000131, 4.84030694300003],
              [14.711244585000088, 4.8387937010001],
              [14.712139697999987, 4.837547562999987],
              [14.712784768000063, 4.836647987000163],
              [14.71280471700004, 4.836431109000159],
              [14.71284, 4.836043000000132],
              [14.712918000000116, 4.835196],
              [14.71291907599999, 4.835184381000033],
              [14.713224719000152, 4.831881249000105],
              [14.713254929000186, 4.831554413000106],
              [14.713302750000139, 4.830917333000116],
              [14.713455272000147, 4.828883714000028],
              [14.713456208000025, 4.828872722000142],
              [14.713544581000178, 4.828327529000092],
              [14.713858, 4.826393999000175],
              [14.714344222000136, 4.825651506999975],
              [14.71526199900012, 4.824249999000017],
              [14.715441623000061, 4.823100411000155],
              [14.715596927000149, 4.822104013000114],
              [14.715561530000173, 4.821151260000079],
              [14.715465000000165, 4.818553000000179],
              [14.715740461000109, 4.818466073000025],
              [14.716665546, 4.81817361300017],
              [14.717831612000055, 4.817804814000112],
              [14.717895564000173, 4.817784674000052],
              [14.718003999000189, 4.81775],
              [14.718565000000126, 4.816682000000128],
              [14.718645631000129, 4.816528950000077],
              [14.718672753000078, 4.816477299000155],
              [14.71834278100016, 4.815691470000047],
              [14.718138694000061, 4.815203667000105],
              [14.718056319000084, 4.814694591999967],
              [14.717959000000121, 4.814095000000123],
              [14.717672000000164, 4.812322000000165],
              [14.720883, 4.802270000000135],
              [14.720788, 4.800745],
              [14.720749999000191, 4.800125],
              [14.720351419000053, 4.799924714000042],
              [14.719148, 4.799320000000137],
              [14.716609000000119, 4.799051],
              [14.717284, 4.797265],
              [14.718202232000124, 4.794836450000048],
              [14.719952584, 4.790205956000079],
              [14.720688820000191, 4.787659169000108],
              [14.720488549000038, 4.786184788000071],
              [14.71833064000009, 4.784189786000013],
              [14.716281999000159, 4.782296],
              [14.716081612000039, 4.781893558000093],
              [14.715681, 4.781089000000179],
              [14.715680006000184, 4.781074083000078],
              [14.715665576000049, 4.780863679000049],
              [14.71554756200004, 4.779145718000109],
              [14.715415001, 4.777068138000175],
              [14.714681626000186, 4.774118900000076],
              [14.71424677400006, 4.773180086000025],
              [14.71397, 4.772583000000168],
              [14.713346000000172, 4.771236000000101],
              [14.712590000000148, 4.770424],
              [14.710475, 4.768152000000157],
              [14.70813800000019, 4.766677000000186],
              [14.704464000000144, 4.765603000000112],
              [14.701992, 4.76473],
              [14.701992975000053, 4.764711319000128],
              [14.702004657000145, 4.764480577000086],
              [14.702059849000079, 4.763392985000166],
              [14.702511000000186, 4.762259],
              [14.702996, 4.761045000000138],
              [14.704667, 4.759504000000163],
              [14.704678886000124, 4.759499306000123],
              [14.705143000000191, 4.759316],
              [14.70518968000016, 4.759297571000047],
              [14.705603599000028, 4.759133815000155],
              [14.706310135000024, 4.758854356000029],
              [14.707162, 4.758517],
              [14.707205849000047, 4.758500058000038],
              [14.709745408000117, 4.757160665000129],
              [14.711014747000092, 4.756423950000055],
              [14.711195057999987, 4.755731827000034],
              [14.711246000000131, 4.75553600000012],
              [14.711416000000156, 4.754883],
              [14.711187, 4.754265],
              [14.710348, 4.752000000000123],
              [14.710348500000123, 4.751062080000111],
              [14.710349000000178, 4.750124000000142],
              [14.71041296300001, 4.75002150500012],
              [14.711016256000107, 4.74905479600011],
              [14.712889000000189, 4.748517],
              [14.713578569000106, 4.74829293200014],
              [14.714119155999981, 4.748117275000084],
              [14.715160000000139, 4.74778],
              [14.716127530000108, 4.746981107000067],
              [14.716296001000103, 4.746841999000083],
              [14.71783353100011, 4.745368449000011],
              [14.718635559, 4.74335813600004],
              [14.718639901000017, 4.742989985000065],
              [14.718641980000143, 4.74279397700019],
              [14.718704000000116, 4.737795000000176],
              [14.718751, 4.735133000000189],
              [14.718784343999971, 4.733259894000128],
              [14.718841552000129, 4.730021476000047],
              [14.718711796000036, 4.72765972000002],
              [14.71870900000016, 4.727608890999989],
              [14.719844818000183, 4.726201533999983],
              [14.720981597000161, 4.724660397000093],
              [14.720914841000138, 4.722784042000058],
              [14.720381735999979, 4.720438004000073],
              [14.72029997900006, 4.718784648000053],
              [14.720249, 4.71775700000012],
              [14.719916000000183, 4.715746],
              [14.719985, 4.710787],
              [14.719888818000072, 4.709526858],
              [14.719718933000138, 4.707301616000109],
              [14.719711511000071, 4.707254311000042],
              [14.719319, 4.704755],
              [14.721791, 4.703079999000124],
              [14.72218, 4.700548],
              [14.722461, 4.698724],
              [14.722863602000189, 4.696707984000057],
              [14.723129999000093, 4.695374000000186],
              [14.72339, 4.694424],
              [14.72381573600012, 4.692871981000167],
              [14.724066734000075, 4.691956044000165],
              [14.723733901000173, 4.690548421000131],
              [14.722131728000022, 4.685990335000099],
              [14.721064568000031, 4.682102680000071],
              [14.720731735000072, 4.679823876000171],
              [14.720932981000033, 4.678014145000077],
              [14.721602, 4.674462],
              [14.722205, 4.669972],
              [14.722573540000155, 4.667734039000038],
              [14.723142623000115, 4.664276123000093],
              [14.723411561000148, 4.660924911000109],
              [14.722811699999966, 4.657104492000087],
              [14.721912751000104, 4.652204618000042],
              [14.721545000000162, 4.650201000000152],
              [14.721229000000108, 4.648756000000162],
              [14.720345000000179, 4.644705000000158],
              [14.720813, 4.643365000000131],
              [14.721346101000108, 4.642759009000031],
              [14.721815000000106, 4.642226],
              [14.723496687000079, 4.641650784000092],
              [14.724072000000149, 4.641454000000124],
              [14.724237001000063, 4.641397516000097],
              [14.724554001000172, 4.641288999000039],
              [14.724764001000153, 4.641168999000058],
              [14.725120655000126, 4.640964514000132],
              [14.725957000000164, 4.640485],
              [14.726826000000131, 4.639145],
              [14.72774, 4.637312],
              [14.727785809000068, 4.637220382000123],
              [14.727829928000062, 4.637131894000106],
              [14.727962000000161, 4.636867],
              [14.727995, 4.636444],
              [14.728021, 4.636114],
              [14.728095150000172, 4.635149018000163],
              [14.728164000000163, 4.634253000000115],
              [14.728546097000049, 4.63377403800007],
              [14.729233000000136, 4.632913],
              [14.730302000000108, 4.631774000000178],
              [14.73111, 4.630579],
              [14.731706000000145, 4.629697],
              [14.731706000000145, 4.629449],
              [14.731707, 4.626010001000111],
              [14.731885253000144, 4.625206864000177],
              [14.732509999000115, 4.622392001000094],
              [14.732619739000143, 4.620141540000134],
              [14.732643996000093, 4.619644086000164],
              [14.732311, 4.616427000000101],
              [14.731356000000119, 4.615063],
              [14.731044, 4.614617],
              [14.730443, 4.613343000000157],
              [14.73296010100006, 4.613277542999981],
              [14.732981000000109, 4.613276999000107],
              [14.733084, 4.613270000000171],
              [14.733116432000145, 4.613267930000177],
              [14.733178138000085, 4.613263607000079],
              [14.735243798000113, 4.613119603000087],
              [14.735669687000097, 4.613089943000091],
              [14.735853, 4.613077],
              [14.736794211000017, 4.61283282800008],
              [14.737656593000168, 4.612608909000073],
              [14.738244712000039, 4.611747067000181],
              [14.738525000000152, 4.611336],
              [14.738455846000136, 4.611020543000109],
              [14.738451999000063, 4.611003],
              [14.738315, 4.610385001000111],
              [14.738232868000125, 4.610014054999965],
              [14.738223988000129, 4.60997394900005],
              [14.737991, 4.608923],
              [14.740129, 4.607783999000048],
              [14.741604747000167, 4.606836034000025],
              [14.742734909000035, 4.606109619000051],
              [14.742798408000056, 4.606037179999987],
              [14.742863000000114, 4.605963],
              [14.742900923000093, 4.605920087000186],
              [14.743838, 4.604852000000165],
              [14.744205, 4.604434],
              [14.744427, 4.60423],
              [14.745642, 4.603117000000168],
              [14.745690178000075, 4.603072756000017],
              [14.745739387000128, 4.603027564000058],
              [14.745764689000112, 4.603004369000132],
              [14.745811999000125, 4.602960999000118],
              [14.746052920000068, 4.602739998000061],
              [14.746066092000092, 4.602727891000029],
              [14.746542930000089, 4.60229063],
              [14.746916772000134, 4.601067065999985],
              [14.747141838000118, 4.60032749100003],
              [14.747148, 4.600307000000157],
              [14.747279, 4.599878],
              [14.749467, 4.595715],
              [14.750554, 4.593647000000146],
              [14.753895, 4.587683000000141],
              [14.754965, 4.586008000000106],
              [14.755766000000108, 4.584668],
              [14.755949645000101, 4.584677255000088],
              [14.756143, 4.584686999000155],
              [14.763847000000112, 4.585073],
              [14.765569000000141, 4.584685],
              [14.76712, 4.584336000000178],
              [14.768589943000052, 4.582594068000105],
              [14.767602287000045, 4.579681234000134],
              [14.76759, 4.579645000000141],
              [14.767632000000106, 4.579617000000155],
              [14.767684000000145, 4.579583000000184],
              [14.769526000000155, 4.578372000000172],
              [14.771464, 4.576697000000138],
              [14.77148164700003, 4.576685392000059],
              [14.772551000000135, 4.575982000000124],
              [14.772629642000084, 4.575930295000035],
              [14.77280521300014, 4.575814725000043],
              [14.773022041000104, 4.575672008000083],
              [14.773145, 4.575591],
              [14.773217377000094, 4.575543409000147],
              [14.773234839000168, 4.575531949000094],
              [14.773601532000043, 4.575290680000137],
              [14.774870872000065, 4.573615552000092],
              [14.775598932999969, 4.57264217400018],
              [14.775842000000125, 4.572317000000112],
              [14.776675000000125, 4.571203000000139],
              [14.777321793000056, 4.57017471100005],
              [14.778188705000048, 4.568795681000154],
              [14.778435146999982, 4.568403867000086],
              [14.778565000000185, 4.568197000000112],
              [14.778613, 4.56812100000019],
              [14.7798820000001, 4.565909000000147],
              [14.780954000000122, 4.565079],
              [14.781352, 4.564771000000121],
              [14.783645, 4.563088000000164],
              [14.784317736000105, 4.562593668000091],
              [14.785093000000131, 4.562024],
              [14.785752000000116, 4.561528],
              [14.786963000000128, 4.56061700000015],
              [14.787365799000156, 4.56001167400018],
              [14.788032531000056, 4.559008599000038],
              [14.788229755000032, 4.557961890000058],
              [14.788266000000135, 4.557769],
              [14.788349, 4.557328000000155],
              [14.788465, 4.55671400000017],
              [14.788702000000114, 4.555457000000104],
              [14.788922, 4.554961],
              [14.789504, 4.553648000000123],
              [14.78970085200018, 4.553600300000085],
              [14.790317000000186, 4.553451],
              [14.79046770500014, 4.55341453],
              [14.791438772999982, 4.553179540000087],
              [14.792710000000113, 4.551638],
              [14.793817614000091, 4.550130453000179],
              [14.794236182000077, 4.549560547000056],
              [14.794273440000154, 4.549509821000129],
              [14.794345, 4.549412000000132],
              [14.79458000000011, 4.549092000000144],
              [14.795314890000157, 4.548565582000094],
              [14.795981658000073, 4.548087962000182],
              [14.796369, 4.547946500000023],
              [14.796755000000132, 4.547805999000047],
              [14.797228702000041, 4.547632944000043],
              [14.797453000000132, 4.547551000000169],
              [14.799256000000128, 4.546345],
              [14.799601, 4.54648600000013],
              [14.800045112000021, 4.546667895000041],
              [14.800645827999972, 4.54691362300008],
              [14.800866152000083, 4.547003733000111],
              [14.801041, 4.547075],
              [14.801059748000114, 4.547082894000141],
              [14.801308114000108, 4.54702065000015],
              [14.801445999, 4.546986000000118],
              [14.801742357000023, 4.546911694000187],
              [14.80212783900015, 4.546814918],
              [14.803797722000184, 4.545341014000087],
              [14.804056097000171, 4.544478242000139],
              [14.804116, 4.544278],
              [14.804444, 4.543182000000172],
              [14.804801, 4.541990000000112],
              [14.805284, 4.539760000000172],
              [14.805361, 4.539405000000102],
              [14.80546995200018, 4.538908217000142],
              [14.805837306, 4.536999010000159],
              [14.806205, 4.535088000000144],
              [14.808677000000159, 4.532809],
              [14.809946, 4.52966],
              [14.811149, 4.528186000000176],
              [14.812885000000165, 4.528655],
              [14.81326700000011, 4.528817000000174],
              [14.816358, 4.53013],
              [14.817741362000163, 4.530130740000118],
              [14.818227768999975, 4.530130864000171],
              [14.818666781000047, 4.529945520000069],
              [14.819496, 4.529594999000153],
              [14.82041499900015, 4.528972000000181],
              [14.821768, 4.528053999000179],
              [14.822037000000137, 4.52791],
              [14.822217183000191, 4.52781372600009],
              [14.822444916000109, 4.527691842000138],
              [14.823475061000181, 4.527140687000099],
              [14.824773, 4.526446],
              [14.826413000000173, 4.52528500000011],
              [14.826908896000191, 4.524933998000051],
              [14.828179360000149, 4.524034501000131],
              [14.831185341000094, 4.523029804000032],
              [14.833722959000056, 4.521823934000111],
              [14.833332128000166, 4.521175432000121],
              [14.832187653000062, 4.519277096000053],
              [14.830466767000132, 4.517088293000029],
              [14.830203901000061, 4.516753995000101],
              [14.830185, 4.516730000000166],
              [14.830995, 4.516471],
              [14.833056149000129, 4.515813229000059],
              [14.833123, 4.515792],
              [14.833995000000129, 4.515632],
              [14.835038188999988, 4.51544069900018],
              [14.837131, 4.515056],
              [14.838368573000025, 4.515812563],
              [14.838667000000157, 4.515995],
              [14.840488000000164, 4.516751000000113],
              [14.840602839000155, 4.516798933000075],
              [14.842604172000108, 4.517200433000085],
              [14.842847898, 4.516579095],
              [14.843238000000156, 4.515572],
              [14.843542, 4.514789000000121],
              [14.84367351100002, 4.514621986000179],
              [14.843805, 4.514454999000122],
              [14.844543000000101, 4.513516000000152],
              [14.844618493000041, 4.513510593000149],
              [14.846413999000106, 4.513382],
              [14.849686, 4.514321000000166],
              [14.852690828000163, 4.515125953000052],
              [14.85335556299998, 4.513744988000099],
              [14.853625684000065, 4.513183658000173],
              [14.853732309000065, 4.512437981000062],
              [14.85375, 4.512314000000174],
              [14.853961, 4.510837],
              [14.854175976000136, 4.50949915200016],
              [14.854391000000135, 4.508161],
              [14.85443400000014, 4.507894],
              [14.85463, 4.506682000000126],
              [14.855039, 4.504399],
              [14.855231000000174, 4.503331000000173],
              [14.855246557000157, 4.503315385000178],
              [14.857557399000029, 4.500995894000027],
              [14.85763500000013, 4.500918000000183],
              [14.859049080000091, 4.500224714000183],
              [14.859895318000099, 4.499809825000057],
              [14.860328001000141, 4.499597427000026],
              [14.860775, 4.499378],
              [14.862488, 4.49909700000012],
              [14.864047, 4.498842],
              [14.864955, 4.498647000000176],
              [14.866852000000165, 4.49824],
              [14.867327242000044, 4.498165528000129],
              [14.867470617000095, 4.498143061000178],
              [14.868684, 4.497954],
              [14.869960328000161, 4.497755863000066],
              [14.870725631000141, 4.497636796000109],
              [14.872796025000184, 4.495760950000147],
              [14.872910000000104, 4.495532],
              [14.874383000000137, 4.492577000000154],
              [14.875936, 4.489461],
              [14.876639000000125, 4.488116000000161],
              [14.877338, 4.486781000000178],
              [14.878262519000032, 4.486112594000133],
              [14.880210876000035, 4.484703541000158],
              [14.88034719500007, 4.484580338000171],
              [14.880374, 4.484556000000112],
              [14.880513000000178, 4.48443],
              [14.880563, 4.484385],
              [14.8806018840001, 4.48434972900003],
              [14.880969887000049, 4.484017510000172],
              [14.88181800000018, 4.483251000000166],
              [14.882882000000109, 4.482291000000146],
              [14.884002, 4.481392],
              [14.884318616000144, 4.481138032000104],
              [14.885219354000014, 4.480415518000029],
              [14.884068000000184, 4.478908001000093],
              [14.883017, 4.477533000000108],
              [14.884070000000179, 4.477004],
              [14.885124000000133, 4.476475000000107],
              [14.885433567000064, 4.47632021600009],
              [14.885554102000015, 4.476259816999971],
              [14.887491, 4.472775],
              [14.888428000000147, 4.471696000000122],
              [14.889035, 4.470996000000127],
              [14.889295000000118, 4.470697],
              [14.889765, 4.470579],
              [14.890382075000105, 4.47042523000016],
              [14.892233, 4.469961],
              [14.894440371000144, 4.46982257500008],
              [14.897575, 4.469626],
              [14.901628734000099, 4.469397754000056],
              [14.902317047000111, 4.469358921000094],
              [14.902419370000132, 4.469329315000152],
              [14.902886, 4.469194],
              [14.905789, 4.468354],
              [14.908594000000164, 4.467416000000185],
              [14.910262000000159, 4.466664000000151],
              [14.911573370000099, 4.466073185000028],
              [14.912081179000097, 4.465844265999976],
              [14.913202, 4.465339000000142],
              [14.915286866000031, 4.464641671000038],
              [14.917004942000119, 4.46406702400003],
              [14.920238690000133, 4.462347783000155],
              [14.922551000000169, 4.461118000000113],
              [14.925156, 4.459308],
              [14.927894, 4.457164000000148],
              [14.930565000000115, 4.45595800000018],
              [14.93092200000018, 4.455887000000189],
              [14.931595, 4.455754000000184],
              [14.933033, 4.455470000000162],
              [14.933326609000062, 4.455412035000052],
              [14.934972764000122, 4.455086708000067],
              [14.935311684999988, 4.454817521000052],
              [14.935618246000047, 4.454573602000039],
              [14.936048, 4.454232],
              [14.936357913000052, 4.45398665200014],
              [14.936575889000153, 4.453813553000146],
              [14.936850193000112, 4.453593333000015],
              [14.937119, 4.45337700000016],
              [14.93751885100005, 4.453056120000099],
              [14.938912, 4.451937],
              [14.938932731000136, 4.45192244500015],
              [14.940248000000167, 4.450999],
              [14.941603, 4.45131],
              [14.942586000000176, 4.451535000000149],
              [14.943403519000015, 4.451407788000097],
              [14.943852425000046, 4.451337814],
              [14.944192423000118, 4.451284753000152],
              [14.945591, 4.45106599899998],
              [14.945825735000085, 4.450595124000188],
              [14.946258544999978, 4.449726104000092],
              [14.94599460000012, 4.448703992000048],
              [14.945791, 4.447917000000132],
              [14.945540575000109, 4.447702106000122],
              [14.945495000000165, 4.447663000999967],
              [14.943988000000161, 4.446375000000103],
              [14.943927560000134, 4.446022550000066],
              [14.943815999000037, 4.445372001000123],
              [14.943713000000116, 4.444771001000163],
              [14.943663652000168, 4.44448171900018],
              [14.943655000000149, 4.444431000000179],
              [14.943855000000156, 4.441281000000117],
              [14.945417, 4.441622000000109],
              [14.945879975000139, 4.441723169000056],
              [14.946496964000119, 4.441857815000162],
              [14.946927070000129, 4.441951752000023],
              [14.949454307999986, 4.440961838000021],
              [14.949475134000124, 4.44095368700016],
              [14.949664558000165, 4.440879174000088],
              [14.951735, 4.439807],
              [14.952061, 4.439664000000107],
              [14.952164, 4.439619000000107],
              [14.953872000000104, 4.438869],
              [14.954473000000121, 4.439941],
              [14.954993000000172, 4.440274000000102],
              [14.955190455000036, 4.440400220000072],
              [14.955941200000098, 4.44087982200017],
              [14.957181117000118, 4.44126090300017],
              [14.959213, 4.441885000000184],
              [14.960811263000096, 4.44200291400017],
              [14.961951256000191, 4.44208669599999],
              [14.964965820000032, 4.441582680000067],
              [14.967961312000057, 4.44108152400014],
              [14.969247818000099, 4.440744878000032],
              [14.971033096000042, 4.440277576000085],
              [14.973917518000121, 4.439045717],
              [14.974172, 4.43893700000018],
              [14.974638, 4.438694000000112],
              [14.97559600000011, 4.438195],
              [14.975974000000122, 4.437999],
              [14.976648, 4.438122],
              [14.977442799000016, 4.438266781000039],
              [14.981089624000049, 4.436778763],
              [14.98138300700009, 4.43665898900008],
              [14.981487, 4.436500000000137],
              [14.981989668000097, 4.435729817000095],
              [14.982651711000074, 4.434714795000161],
              [14.983221055000115, 4.432524681000132],
              [14.983453751000184, 4.431632041000171],
              [14.984094619000075, 4.43037176200005],
              [14.985055973000044, 4.428482038000027],
              [14.986652, 4.428482],
              [14.98906200000016, 4.428482],
              [14.989859286000012, 4.428367761000061],
              [14.991399766000143, 4.428146840000181],
              [14.991801413000019, 4.427780553000162],
              [14.992261000000155, 4.427360999000086],
              [14.992869, 4.426806000000113],
              [14.994672000000151, 4.425935000000152],
              [14.99761, 4.426404],
              [14.998398803000043, 4.426389774000086],
              [14.998607635999974, 4.426385880000169],
              [15.001149178000162, 4.426337720000049],
              [15.002249717000154, 4.425370693000104],
              [15.002539993000028, 4.425115737000112],
              [15.002750312000103, 4.424930605999975],
              [15.003402, 4.42444],
              [15.003690339000173, 4.42422343100003],
              [15.003859520000049, 4.424096108000072],
              [15.004053044000045, 4.423950420000097],
              [15.004405, 4.423685000000148],
              [15.004622, 4.423522000000162],
              [15.006691, 4.423522000000162],
              [15.009872000000144, 4.424232],
              [15.010152479000169, 4.424294695000071],
              [15.010296821000054, 4.424326896000139],
              [15.013101578000033, 4.424728870000024],
              [15.013401985000087, 4.424359798000182],
              [15.013907357000164, 4.423738022000066],
              [15.014663, 4.422808],
              [15.015172, 4.422182000000134],
              [15.015172, 4.420439239000075],
              [15.013522954000052, 4.419139175000169],
              [15.013439, 4.419073],
              [15.013302, 4.418965],
              [15.016240000000153, 4.41822800000017],
              [15.017776, 4.415413000000115],
              [15.018401608000147, 4.412647007000146],
              [15.018443, 4.412464],
              [15.017841, 4.410436000000118],
              [15.017793, 4.410273000000132],
              [15.017749000000151, 4.410123000000112],
              [15.017509000000189, 4.409314000000165],
              [15.017976, 4.406633],
              [15.018271015000096, 4.40462581900016],
              [15.018576986000085, 4.40254409400012],
              [15.019182, 4.402010000000132],
              [15.019949629000052, 4.401333160000092],
              [15.020779610000091, 4.400600910000094],
              [15.021412638000072, 4.399589616000071],
              [15.022141790000148, 4.398423937000189],
              [15.022543000000155, 4.397782],
              [15.022579695000047, 4.397724206000078],
              [15.022916764000172, 4.39597669200009],
              [15.022861000000148, 4.395904000000144],
              [15.022698000000162, 4.395689000000118],
              [15.022066871999982, 4.394856415000049],
              [15.021849, 4.394569000000161],
              [15.018777000000114, 4.392625],
              [15.018892774000108, 4.392433324000024],
              [15.019409589000134, 4.391577318999964],
              [15.020112038999969, 4.390413762000094],
              [15.022339821000116, 4.388831615000186],
              [15.022850037000069, 4.388469696000186],
              [15.024650574000077, 4.386722089000102],
              [15.024852753, 4.386526107],
              [15.025154531000112, 4.385752396000044],
              [15.025562, 4.384707],
              [15.026054, 4.383443000000113],
              [15.026922, 4.380494000000169],
              [15.028138000000126, 4.37888],
              [15.02899200000013, 4.377746000000172],
              [15.029259, 4.37406],
              [15.030862000000127, 4.369905000000131],
              [15.031028000000163, 4.369791000000134],
              [15.031047000000171, 4.36977800000011],
              [15.032531000000176, 4.36876500000011],
              [15.0327190000001, 4.369212000000175],
              [15.032767138, 4.369326573000137],
              [15.032990455000117, 4.369856834000075],
              [15.033265113000084, 4.37050771700018],
              [15.03426647200007, 4.372250081000118],
              [15.034933334000186, 4.372592695000037],
              [15.037851, 4.374091],
              [15.039871668000046, 4.37512977300014],
              [15.039884538000024, 4.375121747000037],
              [15.041627910000045, 4.373028067000178],
              [15.042224885000167, 4.372311116000105],
              [15.043280602000038, 4.371043205000092],
              [15.043755337000107, 4.370437533000029],
              [15.044868999000073, 4.369016001000034],
              [15.047219999000106, 4.366016],
              [15.048530422000056, 4.364430811999966],
              [15.049823709000066, 4.36286635100015],
              [15.04961561500005, 4.362668613000096],
              [15.049464, 4.362525],
              [15.049028, 4.362111],
              [15.047286000000156, 4.360454000000118],
              [15.047198862000016, 4.360282661000042],
              [15.045684000000108, 4.357304],
              [15.046485000000132, 4.355628000000138],
              [15.046673, 4.355460000000107],
              [15.048888, 4.353483],
              [15.050675, 4.352337000000148],
              [15.050800192000111, 4.352256723000153],
              [15.05129146500019, 4.351941587],
              [15.052436829000101, 4.351089002000094],
              [15.053361892000169, 4.350399972000105],
              [15.055097581000155, 4.347115993000102],
              [15.056900025000061, 4.346579553000083],
              [15.057580947000076, 4.34566784000009],
              [15.058101654000097, 4.344970703000058],
              [15.058206559000155, 4.344619751000153],
              [15.058827772000143, 4.342540850000148],
              [15.059063, 4.341753000000153],
              [15.059303, 4.34095],
              [15.06110572700004, 4.340011120000099],
              [15.061973573000103, 4.338536739000062],
              [15.063575745000151, 4.334850311000025],
              [15.06384005100017, 4.334186849000048],
              [15.065017000000125, 4.331231000000173],
              [15.065445000000182, 4.330158],
              [15.066980000000171, 4.328684000000123],
              [15.067255690000081, 4.328474732000188],
              [15.068055153000046, 4.327867509000157],
              [15.068590585000095, 4.327460535000057],
              [15.068820000000187, 4.327286],
              [15.069447027000081, 4.326809261000165],
              [15.071453, 4.324997],
              [15.072498066000037, 4.32330681000019],
              [15.072654724000017, 4.323053360000131],
              [15.072725145000106, 4.322915789000035],
              [15.072860000000162, 4.322652000000176],
              [15.074081, 4.320266000000117],
              [15.074698585000135, 4.31906041000002],
              [15.075123989000133, 4.318229975000122],
              [15.076126, 4.315413],
              [15.076319000000183, 4.315371],
              [15.076554, 4.315320000000156],
              [15.076887000000113, 4.315249000000108],
              [15.077058328000078, 4.315212568000106],
              [15.077337, 4.315369],
              [15.078041437000138, 4.315769406000186],
              [15.07906300000019, 4.316350000000114],
              [15.081066, 4.316618],
              [15.082201000000111, 4.315412],
              [15.082401000000175, 4.312865],
              [15.082935, 4.311190000000181],
              [15.085605621000184, 4.30897760400012],
              [15.086683232000098, 4.30727148800014],
              [15.087210000000141, 4.306437],
              [15.087341000000151, 4.306230000000141],
              [15.087407, 4.305964000000131],
              [15.087712000000124, 4.304740000000152],
              [15.088073918000021, 4.303288349000184],
              [15.090678999000033, 4.300800001000027],
              [15.09084516900009, 4.300147991000074],
              [15.090902, 4.299925],
              [15.091235810000171, 4.298617581000087],
              [15.091345631000138, 4.298187450000114],
              [15.091349027000035, 4.298175223000101],
              [15.091637027000047, 4.297149565000154],
              [15.09208, 4.295572],
              [15.092689921000158, 4.294505729000036],
              [15.093755722000139, 4.292641640000113],
              [15.094149590000029, 4.291953088000128],
              [15.094683648000171, 4.290076255000031],
              [15.094577430000072, 4.289512377000051],
              [15.094497, 4.289087000000166],
              [15.094277, 4.28792],
              [15.094015, 4.286524000000156],
              [15.094048522000037, 4.285417757000062],
              [15.094081878000111, 4.284312725000063],
              [15.09494972300007, 4.281497478000119],
              [15.094730916000174, 4.279415710000023],
              [15.094551360000082, 4.277709906999974],
              [15.094548000000145, 4.277678],
              [15.094861879000064, 4.276574827000047],
              [15.095264999000051, 4.275157999000044],
              [15.095616, 4.273924],
              [15.095458000000122, 4.273518],
              [15.095436096000071, 4.273460389000093],
              [15.094680941000036, 4.27151195499999],
              [15.09217800000016, 4.269579],
              [15.092135355999972, 4.269546067000078],
              [15.092077000000131, 4.269501000000162],
              [15.094129259000056, 4.267905790000157],
              [15.094146999000088, 4.267891998999971],
              [15.094153701000096, 4.26788446400019],
              [15.094436, 4.267567],
              [15.095668000000103, 4.266182000000185],
              [15.095816, 4.266016],
              [15.097087, 4.265460000000132],
              [15.097351, 4.265345],
              [15.097908007000058, 4.265163911000059],
              [15.099621000000127, 4.264606999000137],
              [15.099726636000184, 4.264419459000067],
              [15.100488663000078, 4.263065815000175],
              [15.100222588000179, 4.262045384000032],
              [15.10011181700014, 4.261619070000108],
              [15.100086999000041, 4.261524],
              [15.099858725000161, 4.261183961000086],
              [15.098018, 4.258442000000116],
              [15.097808888000088, 4.257037076000074],
              [15.097549000000129, 4.25529100000017],
              [15.096548, 4.251941000000102],
              [15.097416000000123, 4.249126000000103],
              [15.098883999000179, 4.245506],
              [15.098883999000179, 4.242623999000102],
              [15.098218584999984, 4.24227433100009],
              [15.096078999000042, 4.241150001000165],
              [15.092875000000106, 4.240614000000107],
              [15.093342000000177, 4.237666000000104],
              [15.094217000000128, 4.235381],
              [15.094343012000024, 4.235051998000188],
              [15.096735, 4.234634],
              [15.096989888000053, 4.234589020000044],
              [15.097414000000128, 4.234515000000158],
              [15.098782626000059, 4.234346985],
              [15.10015099899999, 4.234178999000164],
              [15.100223051000114, 4.233932709000044],
              [15.100303649000011, 4.233656407000069],
              [15.100483895000025, 4.233039855000072],
              [15.100327879000076, 4.232277553000017],
              [15.099839, 4.229890000000125],
              [15.099699, 4.229203],
              [15.099483, 4.228148],
              [15.099563000000103, 4.227999000000125],
              [15.10016000000013, 4.226884],
              [15.100416679000148, 4.226405598000099],
              [15.100744000000134, 4.226186],
              [15.100936719000174, 4.226057111000159],
              [15.101371764000021, 4.225765704000082],
              [15.102250923000042, 4.225176670000053],
              [15.102324870000189, 4.225127088000022],
              [15.102619, 4.22493],
              [15.10422099900012, 4.223254000000111],
              [15.104838000000143, 4.221896000000186],
              [15.105813000000126, 4.21975],
              [15.105935256000123, 4.219481715000029],
              [15.105957031000059, 4.219433785000149],
              [15.106296540000187, 4.217983247000177],
              [15.106365519000121, 4.217688132000035],
              [15.106442000000129, 4.217359000000158],
              [15.10649, 4.217155000000162],
              [15.106000000000165, 4.216395000000148],
              [15.105814155000132, 4.216106331000162],
              [15.105755866000038, 4.216015908000031],
              [15.10275200000018, 4.213939000000153],
              [15.100148, 4.21186100000017],
              [15.099346, 4.210253],
              [15.09968, 4.208644000000106],
              [15.099903734000065, 4.207849331000148],
              [15.100547790000064, 4.205561162000095],
              [15.100841985000159, 4.204484001000083],
              [15.100890360000164, 4.204306833000146],
              [15.101481, 4.202143],
              [15.10151900000011, 4.202119],
              [15.103231, 4.20105],
              [15.103591020000181, 4.200825487000031],
              [15.103950999000176, 4.200601],
              [15.103960808000011, 4.199762381000028],
              [15.103968621000035, 4.199061870000037],
              [15.10399755800006, 4.196388774000013],
              [15.104016957000113, 4.194573087000151],
              [15.103550000000155, 4.193363000000147],
              [15.103420372000073, 4.191768630000013],
              [15.103283, 4.190079],
              [15.103301000000158, 4.189416],
              [15.103415000000155, 4.185253],
              [15.103549, 4.18284],
              [15.105284, 4.181499000000144],
              [15.106215000000191, 4.18162800000016],
              [15.106457423999984, 4.181661966000092],
              [15.106578828000124, 4.181678773000158],
              [15.106693111000027, 4.181694589000074],
              [15.106762000000174, 4.181704],
              [15.107216308000091, 4.181766492000065],
              [15.108354532000135, 4.18163299600019],
              [15.109489, 4.181499000000144],
              [15.110611041000027, 4.180877705000171],
              [15.111425103000045, 4.180426762000025],
              [15.111583708000126, 4.180059264000022],
              [15.111916543000063, 4.179286956000055],
              [15.112046109000119, 4.178986554000062],
              [15.112183000000186, 4.178668000000187],
              [15.112291907000042, 4.178416215000027],
              [15.11200240900007, 4.177349165000067],
              [15.111713000000179, 4.176283001000115],
              [15.111691000000121, 4.176204],
              [15.109797949999972, 4.175101284000107],
              [15.109267234000129, 4.174792291000074],
              [15.109030671000028, 4.174654624000141],
              [15.108818, 4.174531],
              [15.108353886999964, 4.174260828000058],
              [15.108592987000065, 4.173595905000184],
              [15.108606000000123, 4.173561999000071],
              [15.109033835000105, 4.172382306000031],
              [15.109421729000019, 4.171311855000056],
              [15.109845361999987, 4.169530832000021],
              [15.110117000000116, 4.168388000000164],
              [15.110154954000052, 4.168229193000059],
              [15.110108, 4.167951],
              [15.110077308000029, 4.167768743000124],
              [15.109961509000129, 4.167084217000024],
              [15.109956741000076, 4.167057038000166],
              [15.10988942400013, 4.166658882000093],
              [15.109782000000109, 4.166026000000102],
              [15.109554, 4.164677],
              [15.109637000000191, 4.163250000000119],
              [15.109702105000167, 4.162141539000118],
              [15.109753609000165, 4.161258221000082],
              [15.11142158500013, 4.157705785000132],
              [15.111457746000042, 4.156544261000136],
              [15.111487999000133, 4.155560999000102],
              [15.111411892000149, 4.155267374000061],
              [15.111332893000053, 4.154963494000128],
              [15.111258588000055, 4.154677562000131],
              [15.111138, 4.154215],
              [15.110687, 4.152478],
              [15.110093, 4.151743000000124],
              [15.109118825000053, 4.150536352000131],
              [15.108684563000168, 4.149998694000033],
              [15.106013000000189, 4.148123000000112],
              [15.104478, 4.14577700000018],
              [15.104726178000135, 4.139961622000044],
              [15.104744000000153, 4.139544000000115],
              [15.104986477000068, 4.138827463000041],
              [15.105946000000131, 4.135992],
              [15.107329311000115, 4.134277551000025],
              [15.107947000000138, 4.133512000000167],
              [15.109433104000175, 4.132809538000117],
              [15.110217, 4.132439],
              [15.11069700000013, 4.131767999000033],
              [15.111834, 4.130176],
              [15.112085, 4.129825],
              [15.112952, 4.12566900000013],
              [15.112685000000113, 4.12272],
              [15.114501000000132, 4.122008000000108],
              [15.115422, 4.121647],
              [15.117898725000146, 4.121430790000147],
              [15.118492126000092, 4.121378898000103],
              [15.122229576000109, 4.12084198000008],
              [15.12342652000018, 4.119511499000055],
              [15.123515999, 4.119412001000171],
              [15.124098820000029, 4.118764200000101],
              [15.124367714000073, 4.117530823000095],
              [15.124698640000076, 4.116016389000038],
              [15.124903679000113, 4.114546300000029],
              [15.124985695000134, 4.113955975000124],
              [15.125165979000144, 4.112665115000027],
              [15.125439697000047, 4.112624196000127],
              [15.125601596000024, 4.112599994000107],
              [15.126667022000049, 4.112440586000048],
              [15.127467302000014, 4.112320661000126],
              [15.128303, 4.112194999000053],
              [15.129637939000133, 4.111860015000048],
              [15.130087, 4.111168999000086],
              [15.130637999000101, 4.110319000000175],
              [15.132506998999986, 4.109045],
              [15.133640406000097, 4.108240407000039],
              [15.135237, 4.107107000000156],
              [15.135978, 4.10658],
              [15.136378, 4.106296],
              [15.136605, 4.10591],
              [15.136615574000075, 4.105892023000081],
              [15.137232, 4.104844000000128],
              [15.137243359000024, 4.104824776000157],
              [15.137712, 4.10328],
              [15.137048004000121, 4.100867448000088],
              [15.136316135000072, 4.098208288000024],
              [15.13631, 4.098186000000112],
              [15.136443517000089, 4.09728138600002],
              [15.136577, 4.096377000000189],
              [15.138111000000151, 4.094567000000154],
              [15.138138, 4.094530000000134],
              [15.139195000000143, 4.093115000000125],
              [15.139641, 4.092518],
              [15.139914, 4.092154000000164],
              [15.14180800000014, 4.089965],
              [15.142582990000165, 4.089070012000093],
              [15.14344999900004, 4.086054001000036],
              [15.143845999000121, 4.085059001000104],
              [15.144316000000174, 4.083877000000143],
              [15.144650000000127, 4.083038000000158],
              [15.145517, 4.080223000000103],
              [15.145772811000029, 4.078097810000145],
              [15.145984650000059, 4.076335430000142],
              [15.14576490800016, 4.074417050000079],
              [15.145516, 4.072248001000105],
              [15.146116, 4.070505],
              [15.147318, 4.069097],
              [15.147810000000106, 4.069356],
              [15.148144457000114, 4.069532633000108],
              [15.148219109000081, 4.069571973],
              [15.149046389000148, 4.070007911],
              [15.149988000000178, 4.070504000000142],
              [15.15058659500005, 4.071023591000028],
              [15.15245819099999, 4.072648049000179],
              [15.158799172000045, 4.07532787299999],
              [15.159333715000116, 4.075327757000025],
              [15.161000999, 4.075327],
              [15.164674381000054, 4.075084784000069],
              [15.169144630000176, 4.074789525000085],
              [15.171346664999987, 4.074051857000143],
              [15.17761993400012, 4.071838856000056],
              [15.177672990000076, 4.071797757000127],
              [15.177823, 4.071681000000183],
              [15.178894000000184, 4.070850000000178],
              [15.179033843000127, 4.070741712000086],
              [15.179088593000017, 4.070699215000047],
              [15.179104649000124, 4.070211088000065],
              [15.179117000000133, 4.069826000000148],
              [15.179136000000142, 4.069250000000125],
              [15.179154, 4.068688001000055],
              [15.178987890000144, 4.066954797000165],
              [15.17882, 4.065203000000111],
              [15.179694, 4.063838000000146],
              [15.179757671000118, 4.063738307000165],
              [15.180021000000181, 4.063326],
              [15.181624000000113, 4.061716],
              [15.182028110000147, 4.061310375000176],
              [15.182423592000191, 4.060913087000131],
              [15.184758848000115, 4.058298912000112],
              [15.18208, 4.056736000000115],
              [15.181989000000101, 4.056683],
              [15.181889000000183, 4.056624000999989],
              [15.178283999000087, 4.055485],
              [15.178159003000019, 4.055433614000037],
              [15.176698, 4.054833],
              [15.174212, 4.053811],
              [15.170675000000131, 4.052066],
              [15.170404821999966, 4.051932595000153],
              [15.170273780000173, 4.05186796300012],
              [15.169919086000107, 4.051754067000104],
              [15.165268, 4.050261001000024],
              [15.159507254000175, 4.048815227000148],
              [15.157793045000176, 4.048385143000076],
              [15.155824720000112, 4.048199188000069],
              [15.154262971000151, 4.04805175100006],
              [15.154248079000126, 4.048048456000061],
              [15.153552648000073, 4.047793008000042],
              [15.151519, 4.047046],
              [15.147914000000185, 4.046041],
              [15.14665, 4.045101],
              [15.146125757999982, 4.044711229000029],
              [15.14611200000013, 4.044701],
              [15.142907999000101, 4.042020999000044],
              [15.141328935000047, 4.0402433860001],
              [15.140705109000123, 4.039541245],
              [15.139904022000167, 4.038134099000104],
              [15.138635635000128, 4.037866116999965],
              [15.135766029000024, 4.037531376000061],
              [15.132696152000108, 4.036929131000079],
              [15.129825592000088, 4.035789967000142],
              [15.128356934000067, 4.034584046000134],
              [15.126955985999984, 4.034651279000059],
              [15.125994893000154, 4.034984109999982],
              [15.12502, 4.035322],
              [15.123352, 4.034987],
              [15.120749001000036, 4.033915001000082],
              [15.119284937000089, 4.033317181000029],
              [15.116805407000072, 4.032306925000114],
              [15.114495375000104, 4.032276418000151],
              [15.111733600000093, 4.032239385000025],
              [15.109213208000085, 4.031887108999968],
              [15.108950034000031, 4.031850307000127],
              [15.108868840000014, 4.03183895300009],
              [15.106132, 4.031035],
              [15.102812, 4.029876000000115],
              [15.101526023000076, 4.029427008000084],
              [15.098589896000078, 4.028891086000158],
              [15.098505914000043, 4.028906912000082],
              [15.098051, 4.028993],
              [15.095387, 4.029495],
              [15.093851, 4.029026000000158],
              [15.093840919000172, 4.029021474000103],
              [15.091646253000135, 4.028036429000053],
              [15.090714455000125, 4.027618409000127],
              [15.085975648000158, 4.02507209800018],
              [15.083505630000047, 4.023798943000088],
              [15.07910060800009, 4.022526265000124],
              [15.077595673000189, 4.022089416000142],
              [15.076383602000021, 4.021737875000099],
              [15.076349000000164, 4.021727000000169],
              [15.077032, 4.018120000000181],
              [15.078087710000148, 4.015694241000119],
              [15.0781, 4.015665],
              [15.078199, 4.015439000000185],
              [15.083204, 4.006809],
              [15.083864075000122, 4.005760381000073],
              [15.084523999000055, 4.004712],
              [15.086789999000132, 4.001112000000148],
              [15.087280769000074, 4.000698654000075],
              [15.088754653000137, 3.999457122000024],
              [15.088847231000159, 3.999379133000048],
              [15.088877, 3.999354000000153],
              [15.089376000000129, 3.998933000000136],
              [15.091598000000147, 3.996702000000141],
              [15.091626480000173, 3.996673520000059],
              [15.092796000000135, 3.99332],
              [15.093076847000191, 3.992205518000048],
              [15.09342670400008, 3.990815877000102],
              [15.093871166999975, 3.989048771000057],
              [15.09418, 3.987820000000113],
              [15.094628029000091, 3.986038836000148],
              [15.095798000000173, 3.982009000000176],
              [15.096424991999982, 3.979929869000159],
              [15.099133, 3.97095000000013],
              [15.101468000000182, 3.962907],
              [15.102635, 3.958969999000033],
              [15.104293523000081, 3.954293406000033],
              [15.104804040000147, 3.952853681000022],
              [15.104831992000186, 3.95276805900005],
              [15.104975, 3.952329],
              [15.10596697200009, 3.949290273999964],
              [15.105973920000167, 3.949268987000096],
              [15.106466000000182, 3.947761000000128],
              [15.106472000000167, 3.947743],
              [15.106713636000052, 3.947274626000024],
              [15.108467000000189, 3.943876],
              [15.108616609000023, 3.943586463000145],
              [15.108805756000152, 3.943220409000048],
              [15.110323565000044, 3.94085990800005],
              [15.112814999000136, 3.936985999000058],
              [15.118180736000113, 3.928641124000137],
              [15.120982999000034, 3.924283000000173],
              [15.125351, 3.917012],
              [15.127464, 3.913495000000182],
              [15.134751900000026, 3.9013653290001],
              [15.134826000000146, 3.901242000000138],
              [15.134839606000185, 3.901220122999973],
              [15.137751, 3.896539000000189],
              [15.142056, 3.889618],
              [15.143470894000075, 3.887344559000155],
              [15.143999099000155, 3.886495591000085],
              [15.157136807000029, 3.864271862000066],
              [15.177141916000153, 3.830430882000144],
              [15.177185000000179, 3.83035800000016],
              [15.177688173000149, 3.829511952000132],
              [15.179037000000108, 3.827244],
              [15.182111, 3.822075000000154],
              [15.198112, 3.795167],
              [15.22395500000016, 3.751898],
              [15.24889694899997, 3.710672085000056],
              [15.250055000000145, 3.709790000000169],
              [15.251702820000048, 3.708535341000186],
              [15.253298759000074, 3.707319975000132],
              [15.269213624000145, 3.690741699000171],
              [15.27783, 3.681766],
              [15.281478968000158, 3.677965076000021],
              [15.28457736900009, 3.674737691000075],
              [15.299651206000021, 3.658569906000025],
              [15.303950096999984, 3.653959159000181],
              [15.311755608000055, 3.645587639000155],
              [15.329457282000078, 3.626600505000113],
              [15.350062369999989, 3.60561490200007],
              [15.37855720499999, 3.57530903900016],
              [15.378607750000185, 3.575255394000067],
              [15.378672601000119, 3.575186968000082],
              [15.384998322000115, 3.568458318000069],
              [15.391668320000065, 3.56136369800015],
              [15.415240287000074, 3.536711455000102],
              [15.446805000999973, 3.503700493999986],
              [15.494337083000119, 3.457700253000041],
              [15.501468658000022, 3.448581696000076],
              [15.534954071000129, 3.409054994000087],
              [15.549921989999973, 3.391386748000116],
              [15.563184739, 3.376567602000023],
              [15.564505576000158, 3.37502908700003],
              [15.565717698000071, 3.373617173000071],
              [15.575647353000022, 3.361815930000034],
              [15.623160362000078, 3.306562663000079],
              [15.647681235999983, 3.277393579000147],
              [15.67266750400006, 3.248759985000106],
              [15.697852135000062, 3.219589711000083],
              [15.714097023000022, 3.201035261000186],
              [15.723567962000175, 3.190217734000157],
              [15.746217727999976, 3.163327217000187],
              [15.774244309000153, 3.130683184000077],
              [15.778459549000047, 3.125773669000012],
              [15.792780876000108, 3.108941556000104],
              [15.794083594000028, 3.107485295000117],
              [15.800041198000031, 3.100826978000157],
              [15.802372932000083, 3.098747731000174],
              [15.804039001000149, 3.097808124000153],
              [15.804653166999969, 3.098264456000038],
              [15.805840492000073, 3.099147082000059],
              [15.807024956000021, 3.100891351000087],
              [15.80784225400015, 3.102094412000042],
              [15.809512139000049, 3.104811430000154],
              [15.81031227200009, 3.106113433000132],
              [15.811912537000069, 3.106179238000152],
              [15.813912391000144, 3.105775595000068],
              [15.816445350000038, 3.105237485000146],
              [15.816766739000059, 3.105132342000047],
              [15.818910600000038, 3.104431392000151],
              [15.821909905000155, 3.103423835000172],
              [15.822229385000128, 3.103432655000177],
              [15.824243545000172, 3.103488923000043],
              [15.827311516000066, 3.104357958000094],
              [15.82901573100014, 3.105393172000106],
              [15.829845428000112, 3.105897188000085],
              [15.83364772800013, 3.109111070999973],
              [15.837182999000163, 3.111319780000031],
              [15.838517189000072, 3.111452818000032],
              [15.841449738000165, 3.110579252000036],
              [15.84409141500015, 3.110011816000167],
              [15.844582558000184, 3.109906436000188],
              [15.847062111000071, 3.110166788000015],
              [15.847115517000077, 3.110172511000087],
              [15.84817409600015, 3.110560179000174],
              [15.850584029000174, 3.111443043],
              [15.851840973000094, 3.111699820000069],
              [15.853850365000085, 3.11211061400013],
              [15.854917527000055, 3.111908675],
              [15.855977059000168, 3.111097574000155],
              [15.856582642000092, 3.110634088000154],
              [15.858715058000143, 3.109224796000092],
              [15.860647201000063, 3.108083963000127],
              [15.86271381500012, 3.10734510400016],
              [15.863578796000013, 3.105669022000029],
              [15.863576888000068, 3.102854253000089],
              [15.865441323000027, 3.100440265000032],
              [15.866774558000145, 3.099970103000032],
              [15.869121551000092, 3.099968195000088],
              [15.869307519000188, 3.099967957000047],
              [15.872574806999978, 3.100367309000035],
              [15.87564182300008, 3.100699902000144],
              [15.880174636000049, 3.100763083000118],
              [15.881363869000154, 3.100936413000056],
              [15.883375168000043, 3.101229667000155],
              [15.884774209000057, 3.100290298000175],
              [15.884998322, 3.09985733000002],
              [15.885573385999976, 3.098748208000131],
              [15.88801288600007, 3.09635305400019],
              [15.8882379530001, 3.096132278000027],
              [15.888736725000172, 3.095724105999977],
              [15.889937401000054, 3.094741583000143],
              [15.890369416000169, 3.094388009000056],
              [15.891100883000036, 3.092645169000093],
              [15.891499519000149, 3.090768337000043],
              [15.892898559000059, 3.089225769000109],
              [15.894629478000013, 3.086677790000067],
              [15.896826744000123, 3.083056928000133],
              [15.900818825000101, 3.074274541000079],
              [15.902615547000096, 3.070721150000168],
              [15.904613496000138, 3.067569732000038],
              [15.906944275000114, 3.065021277000085],
              [15.908941268999968, 3.061936856000045],
              [15.911404609000044, 3.057980775000033],
              [15.914134025000124, 3.053957702000162],
              [15.91639709500015, 3.049063682000053],
              [15.91779422799999, 3.045577526000045],
              [15.919390678000127, 3.041823388000125],
              [15.92078971900014, 3.040549041000134],
              [15.922854423000103, 3.039139747000036],
              [15.925786973000072, 3.038198948000115],
              [15.928520203000119, 3.037861585000144],
              [15.929188728000099, 3.037628174000133],
              [15.930252075000055, 3.037256957000068],
              [15.931783676, 3.03504419300009],
              [15.932238578000181, 3.034234047000041],
              [15.933116914000038, 3.032668352000087],
              [15.933514595000133, 3.031960011000024],
              [15.93564415000003, 3.027937175000034],
              [15.937641144000111, 3.024517775000106],
              [15.940037728000163, 3.02116489500014],
              [15.942901612000128, 3.01848196900005],
              [15.945765494000113, 3.01579880800017],
              [15.94869613700007, 3.013115645000084],
              [15.951360702000159, 3.010499715000037],
              [15.953423500000099, 3.00674510000016],
              [15.955953599000111, 3.003392220000023],
              [15.958218575000103, 3.000910759000135],
              [15.958418847000189, 3.000789643000076],
              [15.960883140000078, 2.999300003000087],
              [15.963282585000059, 2.999297858000148],
              [15.965948105000166, 2.998558282999966],
              [15.967613220000032, 2.99668049800016],
              [15.969477653000183, 2.994266272000061],
              [15.969761849000065, 2.99483680700007],
              [15.970679284000028, 2.996677638000108],
              [15.971488952000072, 2.998737812000172],
              [15.971548080000161, 2.998888493000152],
              [15.972615242000188, 2.999758720000102],
              [15.973291397000082, 3.000324011000089],
              [15.974617004000038, 3.00143218],
              [15.977084159000185, 3.002770186000078],
              [15.978691102000028, 3.003124237000122],
              [15.980417251000119, 3.003504515000145],
              [15.981070519000127, 3.003503799999976],
              [15.982884406000039, 3.003502130000015],
              [15.984387397000148, 3.003297330000123],
              [15.986350059000188, 3.003030063000153],
              [15.988883019000127, 3.003429889000188],
              [15.991683961000092, 3.004901410000173],
              [15.993347169, 3.005343438000182],
              [15.994951249000053, 3.005769729000178],
              [15.998350143000096, 3.005565644000058],
              [15.999773980000043, 3.005295039000146],
              [16.006147384000144, 3.004084110000065],
              [16.011077880000073, 3.001533032000168],
              [16.011423111000113, 2.999894619000088],
              [16.011543274000189, 2.999321222000162],
              [16.010910034, 2.993754626000168],
              [16.010871888000054, 2.993424655000126],
              [16.010335922000138, 2.990945579000027],
              [16.011133195000127, 2.989336491000074],
              [16.012466430000188, 2.988598109000122],
              [16.014999389000081, 2.989198922000185],
              [16.015380859, 2.989198447000035],
              [16.017265320000092, 2.989196777000075],
              [16.019798278000053, 2.988256216000139],
              [16.020179749000079, 2.988121034000187],
              [16.022264481000093, 2.987382649999972],
              [16.025062560000038, 2.985503912000183],
              [16.026576996000131, 2.984692574000178],
              [16.027193070000123, 2.984362603000136],
              [16.02899169900013, 2.9846360680001],
              [16.030260086000055, 2.984828710000045],
              [16.032058715000062, 2.983955861000084],
              [16.032922746000054, 2.981609584000182],
              [16.033519744000102, 2.978459359],
              [16.034841538000023, 2.976281644000039],
              [16.034984588000157, 2.97604560800005],
              [16.034868240000094, 2.974298239000177],
              [16.034782410000105, 2.973030329000039],
              [16.034778595000034, 2.970617771000093],
              [16.034910202000049, 2.96726703700017],
              [16.035430908000137, 2.966630459000044],
              [16.036272048000058, 2.96560096900015],
              [16.036773681000113, 2.964986802000055],
              [16.038503647000084, 2.961902618000124],
              [16.039012909000121, 2.961123704000158],
              [16.040168763000167, 2.959354639000082],
              [16.041236878000177, 2.95725107200019],
              [16.041564941000047, 2.95660567200008],
              [16.042095183000072, 2.953388452000183],
              [16.044960022000055, 2.952715875000024],
              [16.046154023000156, 2.952114582000093],
              [16.046426774000054, 2.951977253],
              [16.047426223000059, 2.950770139000156],
              [16.04802322400019, 2.947820903000036],
              [16.049007415000176, 2.946517945000039],
              [16.049087524000129, 2.946412564000127],
              [16.050285339000027, 2.944468021000091],
              [16.050558090000152, 2.943980218000149],
              [16.051750183000081, 2.941853286000026],
              [16.053945541000189, 2.937964439000041],
              [16.055208205000042, 2.933942556000034],
              [16.056072236000034, 2.931462289000081],
              [16.056129456000122, 2.930579425000076],
              [16.056268692000117, 2.92837953500009],
              [16.057332992000056, 2.925631046000149],
              [16.057859422000092, 2.924405336000177],
              [16.058397292, 2.923150540000108],
              [16.058214188000136, 2.922533988000055],
              [16.057661057000132, 2.92067170100006],
              [16.058393479000131, 2.91946482700007],
              [16.05999374400011, 2.919262170000081],
              [16.061859132000109, 2.919595480000112],
              [16.062858581000114, 2.919326543000125],
              [16.063386916000013, 2.918638468000154],
              [16.063991547000057, 2.917851209000105],
              [16.064105987000119, 2.916975498000056],
              [16.064123153000139, 2.916845799000043],
              [16.065187455000057, 2.915169716000037],
              [16.067186355999979, 2.914095403000033],
              [16.069414138000127, 2.912562848000164],
              [16.069917679000127, 2.912216425000167],
              [16.071649551000064, 2.910472631000118],
              [16.071817398000178, 2.909011842000155],
              [16.071912764999979, 2.908193826000172],
              [16.072340011000165, 2.906592369000066],
              [16.072378159000152, 2.906450987000085],
              [16.072450638000191, 2.906201601000078],
              [16.073440552000022, 2.902764320000131],
              [16.074171066000076, 2.900150299000131],
              [16.075435638000044, 2.897602558000131],
              [16.07530021700012, 2.895056248000117],
              [16.075422287000094, 2.894248963000166],
              [16.075696945000118, 2.892442466000034],
              [16.075828552000075, 2.89150762700001],
              [16.076068878000058, 2.889821291000146],
              [16.076095581000061, 2.889627456000142],
              [16.07682609599999, 2.887013436000075],
              [16.076955794000071, 2.88386368800002],
              [16.078018189000147, 2.880780219000087],
              [16.08107948300011, 2.87467908800005],
              [16.082010269000023, 2.871796608000068],
              [16.082807541000079, 2.869316577000177],
              [16.084938049000186, 2.868242264000173],
              [16.085803986000144, 2.86716938100011],
              [16.085868835000099, 2.865158797000049],
              [16.085399627000186, 2.862009764000163],
              [16.086652756000035, 2.861294269000041],
              [16.087398528999984, 2.860868693000157],
              [16.087661743000126, 2.85758471500003],
              [16.087297439000167, 2.857016086000158],
              [16.08646011400009, 2.855709552000064],
              [16.087142944000107, 2.854696512000089],
              [16.087724686000058, 2.853832006000175],
              [16.088788986000054, 2.851351500000078],
              [16.088584899000125, 2.847197056000084],
              [16.08823585499999, 2.845231771000101],
              [16.087818146000188, 2.84288811600004],
              [16.088727951000067, 2.842704297000012],
              [16.090379716000029, 2.842370510000023],
              [16.091911315000175, 2.840760946000103],
              [16.092109679999965, 2.839018345000056],
              [16.092306136000047, 2.835600615000089],
              [16.092378617000122, 2.833694458000025],
              [16.092435837000039, 2.832182647000082],
              [16.093032837000067, 2.829769849000115],
              [16.092031478000081, 2.827894449000155],
              [16.091030121000074, 2.825684069999966],
              [16.090360642000064, 2.822468041999969],
              [16.086490631, 2.818249941000147],
              [16.083686829000044, 2.815494775000047],
              [16.083356857000069, 2.815170288000104],
              [16.08128738500011, 2.81188869600004],
              [16.080352783000023, 2.809879064000029],
              [16.07895279000013, 2.80887532200012],
              [16.077085495000063, 2.807938814000067],
              [16.076816558000075, 2.804923535000057],
              [16.075584412000126, 2.803525924000155],
              [16.075281143000154, 2.803182603000096],
              [16.075103760000161, 2.803104639000026],
              [16.073326111000029, 2.802324295000119],
              [16.073148728000035, 2.802246332000152],
              [16.072624206000171, 2.801939487000084],
              [16.070747376000099, 2.800841331000186],
              [16.070432663000076, 2.80072045300011],
              [16.067613602000165, 2.799638032000189],
              [16.063747405000129, 2.79856944200003],
              [16.063613951999969, 2.798463262000155],
              [16.063684463000072, 2.798370362000071],
              [16.064004898000178, 2.797958373000029],
              [16.064116264000177, 2.797814843000026],
              [16.064311617999977, 2.797563068999978],
              [16.063716535000026, 2.797669636000023],
              [16.063680649000105, 2.797676087000127],
              [16.062812790000123, 2.797832038000138],
              [16.062547684000037, 2.797623158000079],
              [16.060947419000058, 2.796360493000179],
              [16.059898729000167, 2.795583004000037],
              [16.058926, 2.794862],
              [16.057878, 2.794085000000109],
              [16.05754317800006, 2.794040828000163],
              [16.057498869000028, 2.794034983000131],
              [16.057245774000137, 2.794001716000139],
              [16.056297617000098, 2.79387721300003],
              [16.055345534000139, 2.793752194000092],
              [16.052013350000095, 2.793554021000091],
              [16.051013974, 2.792616871],
              [16.051012834000119, 2.791691945000082],
              [16.051012189000119, 2.791154493000022],
              [16.051012039000113, 2.791029216000027],
              [16.051012039000113, 2.79094147700016],
              [16.051039194000168, 2.790796099000033],
              [16.051070848000109, 2.790626638000106],
              [16.051145555000176, 2.790226698000083],
              [16.051475525000114, 2.788461686000062],
              [16.050340652000102, 2.785916091000161],
              [16.052270890000102, 2.783635855000171],
              [16.052258393000045, 2.783544311000071],
              [16.052205437999987, 2.783156410000174],
              [16.051868439000089, 2.780687809000142],
              [16.054733276000093, 2.779345036000166],
              [16.054260253000109, 2.772309064000069],
              [16.054592134000131, 2.769762277000154],
              [16.055057099000123, 2.769043381000188],
              [16.055318631000091, 2.768639019000091],
              [16.055589676000068, 2.768219947000091],
              [16.057254791000105, 2.766744138000092],
              [16.057926178000116, 2.76617169400015],
              [16.059201965000057, 2.765084748999982],
              [16.059851264000088, 2.76453148000013],
              [16.05975375, 2.763014235000128],
              [16.059717010000156, 2.762453148000134],
              [16.059114407000095, 2.761848492000183],
              [16.059087754000132, 2.761821747000056],
              [16.057916760000069, 2.76064574500009],
              [16.05664828099998, 2.759239389000072],
              [16.05716897200017, 2.75824837100015],
              [16.057223530000044, 2.758144532000131],
              [16.057247018000055, 2.75809983],
              [16.057296892000124, 2.757618453000077],
              [16.057378769000024, 2.756826401000069],
              [16.057396643000118, 2.756775303000097],
              [16.057466441000031, 2.7565757590001],
              [16.057777185000134, 2.755687387000137],
              [16.05857849099999, 2.757361413000126],
              [16.059513092000145, 2.758164644000033],
              [16.060329438, 2.758092643000055],
              [16.060781508000105, 2.758052665000093],
              [16.061046902000044, 2.75802900799999],
              [16.06112285100005, 2.757974963000095],
              [16.061265855000158, 2.757873204000077],
              [16.061878204999971, 2.757437466999988],
              [16.062115689000109, 2.757268240000144],
              [16.062177659000099, 2.75722408200005],
              [16.062454187000071, 2.756862863000094],
              [16.062864347000072, 2.756327086000056],
              [16.063971946000152, 2.754879683000127],
              [16.064639963000047, 2.752463862000184],
              [16.064581679000014, 2.75224738300011],
              [16.064170838000052, 2.750722170000131],
              [16.06263351500013, 2.745496512000102],
              [16.062500008000029, 2.743620145000023],
              [16.062616375000118, 2.743424745000027],
              [16.062792756000078, 2.74312857700005],
              [16.063098907000096, 2.742614507000099],
              [16.06355857900013, 2.741657257000156],
              [16.063746775000084, 2.741265014000135],
              [16.064096, 2.740536],
              [16.064760000000149, 2.738257],
              [16.065182905000029, 2.737120916000038],
              [16.065757752000081, 2.735575675000177],
              [16.06581688, 2.734763862000079],
              [16.065839547, 2.734455409000077],
              [16.0658681970001, 2.73406555400004],
              [16.065953962000151, 2.732895522000035],
              [16.065750000000151, 2.731611000000157],
              [16.065687, 2.731220000000121],
              [16.064219999000159, 2.729814000000147],
              [16.064210963000107, 2.729807865999987],
              [16.062625897000032, 2.728731811000046],
              [16.062261173000081, 2.728484264000088],
              [16.061952591000136, 2.728274823000106],
              [16.061818441000014, 2.728202336000038],
              [16.061574104000158, 2.72807031100001],
              [16.060516357000097, 2.727498769000022],
              [16.059352979000153, 2.72686987899999],
              [16.061082839999983, 2.725461006000046],
              [16.062438964000137, 2.724426747],
              [16.063282013000162, 2.723783732000186],
              [16.063304967000079, 2.723740619000068],
              [16.063353792000157, 2.723648395000112],
              [16.063828701000091, 2.722743635000029],
              [16.063880921000134, 2.72264409100012],
              [16.063344956000094, 2.720969201000116],
              [16.061677933000112, 2.719429493000064],
              [16.059530715999983, 2.717591311000035],
              [16.059410095000089, 2.7174880500001],
              [16.058208466000053, 2.714741706000098],
              [16.058207487000061, 2.713813248000179],
              [16.058207, 2.713351464000141],
              [16.058206558000052, 2.712932349000027],
              [16.058432991000018, 2.712719580999988],
              [16.058937577000052, 2.712245449000022],
              [16.059206010000139, 2.711993218000032],
              [16.05998092800013, 2.711992495000061],
              [16.060511297000062, 2.711992000000123],
              [16.060739517000115, 2.711991786999988],
              [16.06197357200017, 2.71155595700003],
              [16.062070847000143, 2.711521624999989],
              [16.063535794000074, 2.709912088000124],
              [16.063628743000038, 2.709934897000153],
              [16.063937377, 2.710010632000149],
              [16.064414979000105, 2.710127830000033],
              [16.064740430000143, 2.710207696999987],
              [16.065149721000125, 2.710308139000119],
              [16.066801, 2.710713000000169],
              [16.067855509000026, 2.710311323000042],
              [16.067979612999977, 2.710264001000041],
              [16.06804847699999, 2.710237743000164],
              [16.06926704, 2.709772913999984],
              [16.069989109000176, 2.708265181000058],
              [16.070152201000155, 2.707924542000171],
              [16.070198060000109, 2.707828760000098],
              [16.070634843000107, 2.707639933000166],
              [16.070705307000082, 2.707609472000058],
              [16.070863288000112, 2.707541178000099],
              [16.071596146000161, 2.707224369000073],
              [16.072141646000034, 2.70758032800012],
              [16.072929383000087, 2.708094360000189],
              [16.074466705000077, 2.708203315000048],
              [16.074563659000148, 2.708210180000037],
              [16.074642427000072, 2.708215758000108],
              [16.074796676000119, 2.708226681000042],
              [16.078516007000133, 2.70732760400017],
              [16.079185245000076, 2.707165819000011],
              [16.079527, 2.707082999000079],
              [16.082657001000086, 2.705605999000113],
              [16.086586000000182, 2.702788000000112],
              [16.086786, 2.701515],
              [16.085179, 2.699345000000164],
              [16.084233457000153, 2.698067462000154],
              [16.083116531000087, 2.696559192000052],
              [16.081647874000168, 2.693879844000037],
              [16.08155784600018, 2.692879235000135],
              [16.08131, 2.690128000000129],
              [16.079027, 2.687071000000117],
              [16.078416659000084, 2.686253541000099],
              [16.077308656000127, 2.684770107000077],
              [16.075866032000135, 2.682839315000024],
              [16.075386947000027, 2.682198113000084],
              [16.074306488000047, 2.68075203899997],
              [16.07352104600011, 2.679206186000101],
              [16.07306786900017, 2.678314274000172],
              [16.072570801000097, 2.677335978000031],
              [16.071767805999968, 2.674186946000077],
              [16.071029664000037, 2.667888641000047],
              [16.070809930000109, 2.663551664000124],
              [16.070690000000184, 2.661188001000028],
              [16.070650903000057, 2.660891627000069],
              [16.070354, 2.658641000000102],
              [16.06956700000012, 2.657149],
              [16.069468215000029, 2.656961691000106],
              [16.069152831000054, 2.656363963000047],
              [16.068920149000178, 2.655715361000034],
              [16.068248381000046, 2.653842809000025],
              [16.067951202000017, 2.653014421000023],
              [16.066947938000169, 2.64939665899999],
              [16.066926550000176, 2.64932209300008],
              [16.066819718000033, 2.648949637000158],
              [16.06541061300004, 2.644037008],
              [16.065141678000089, 2.641021490000071],
              [16.065671920999989, 2.636933328000055],
              [16.066534043000104, 2.632174730000145],
              [16.06772995, 2.627750875000118],
              [16.068525784000144, 2.625114538000048],
              [16.069126, 2.623125999000081],
              [16.069922999000028, 2.621182],
              [16.070392560000073, 2.619761269000037],
              [16.071052550000161, 2.617763041999979],
              [16.07155619200006, 2.616917563000129],
              [16.072141454000189, 2.61593506700018],
              [16.073648000000105, 2.613405],
              [16.075904309, 2.607873787000017],
              [16.076071552000087, 2.607463703000065],
              [16.076108932000182, 2.607372045000091],
              [16.076120540999966, 2.60734979700004],
              [16.076214456, 2.607169817000056],
              [16.077438354000037, 2.604824305000136],
              [16.077914420000184, 2.60399074500009],
              [16.078429626000172, 2.603088654000146],
              [16.078969956000094, 2.602142572000048],
              [16.081098556000029, 2.598656177000123],
              [16.081482887000107, 2.597297632999982],
              [16.081762, 2.59631],
              [16.082203343, 2.594408182000109],
              [16.082396890000155, 2.593573604000142],
              [16.082492828999989, 2.593159914000012],
              [16.082591234000063, 2.592430223000122],
              [16.082859469000141, 2.590441213000077],
              [16.083288001000085, 2.58726200000018],
              [16.083361334000188, 2.58581506600018],
              [16.083422840000139, 2.584598151000137],
              [16.083484650000059, 2.583375216000093],
              [16.083268598000132, 2.581894246000161],
              [16.0830154410001, 2.580158950000111],
              [16.082794284000158, 2.578520445000152],
              [16.082594617999973, 2.577041165000139],
              [16.082210541000109, 2.574195623000094],
              [16.081674574999965, 2.570979595000097],
              [16.080369812000129, 2.566759210000043],
              [16.080202971000062, 2.56621971900006],
              [16.07998110200009, 2.565511853000089],
              [16.079416204000097, 2.563709869000036],
              [16.078334809000069, 2.560260295000091],
              [16.076375922000068, 2.55580482400012],
              [16.076364517000115, 2.555623055000069],
              [16.076354572000071, 2.555446371000016],
              [16.076348999000061, 2.555352000000141],
              [16.076258751000069, 2.555351069000153],
              [16.076154784000039, 2.555349998000168],
              [16.075281400999984, 2.55359705900014],
              [16.074329001000081, 2.551685999000142],
              [16.072247710000056, 2.548772572000189],
              [16.072128280000129, 2.548605406000036],
              [16.070726395000065, 2.545456886000125],
              [16.068256379000161, 2.540701151000178],
              [16.06665420600018, 2.536681652000084],
              [16.065517426000099, 2.532259703000023],
              [16.064633283000035, 2.528148258000101],
              [16.064580917000171, 2.527904749000129],
              [16.064579204000097, 2.524655061000033],
              [16.06457900900017, 2.524286032000134],
              [16.064617628000065, 2.524190354000041],
              [16.065309524000156, 2.522476197],
              [16.066440582000098, 2.520263673000102],
              [16.066915527000049, 2.518998085000021],
              [16.067371368000011, 2.51778340400017],
              [16.06856727600001, 2.513426780000089],
              [16.069366456000068, 2.512018682000132],
              [16.069648131, 2.511749803000157],
              [16.070631028000093, 2.510811568000065],
              [16.072631497000145, 2.508945613000151],
              [16.074226379000095, 2.507457971000179],
              [16.076206079000144, 2.506045252000035],
              [16.077423096000189, 2.505176784000128],
              [16.080417634000185, 2.502359867000052],
              [16.081189822999988, 2.500599583000053],
              [16.081681999000068, 2.499477000000184],
              [16.082479, 2.496260001000167],
              [16.083141001000172, 2.492105001000141],
              [16.083471001000134, 2.487615000000119],
              [16.0835340000001, 2.483258999000043],
              [16.083416254000099, 2.482293135000077],
              [16.083198547000109, 2.48051142800017],
              [16.082545475000074, 2.478955132000181],
              [16.08213, 2.477966],
              [16.081448069999965, 2.476368207000121],
              [16.080928803000063, 2.475152254000136],
              [16.077594757000099, 2.470799208000017],
              [16.074859619000108, 2.467719078000073],
              [16.073239652999973, 2.465651368000067],
              [16.072393418000047, 2.464571238000076],
              [16.072350896000103, 2.463284951000105],
              [16.072324752000043, 2.462494136000146],
              [16.072988511000176, 2.460818053000082],
              [16.074189014000126, 2.459335868000153],
              [16.075052262000156, 2.458270073000165],
              [16.076118401000144, 2.457499137000127],
              [16.077650069000072, 2.456391573000133],
              [16.079381942999987, 2.455451966000112],
              [16.079901832000132, 2.455113444000176],
              [16.080595017000178, 2.45466208400012],
              [16.081342697000025, 2.454175234000161],
              [16.081572741000059, 2.454025501000103],
              [16.08257675200008, 2.453372001000048],
              [16.083908080000128, 2.451829672000088],
              [16.083916025000178, 2.4517989470001],
              [16.084705001000088, 2.448746],
              [16.084551583000177, 2.447365234000188],
              [16.08436965900006, 2.445731163000062],
              [16.083833694000191, 2.441643714000179],
              [16.084697823, 2.438761370000066],
              [16.085554989000059, 2.437853502999985],
              [16.087293624000097, 2.436012030000143],
              [16.089824677000024, 2.434401512000136],
              [16.093421936000027, 2.434130669000183],
              [16.095821787000091, 2.433117608000089],
              [16.096284930000138, 2.432922029000167],
              [16.097749711000063, 2.430709601999979],
              [16.098940336999988, 2.428427850000048],
              [16.09907913100011, 2.428161860000046],
              [16.100543976999973, 2.425614358000132],
              [16.101178655000126, 2.424534878000145],
              [16.102672577000078, 2.421993971000177],
              [16.105733871000041, 2.416563511000049],
              [16.10678085699999, 2.414139263000038],
              [16.106862999000043, 2.413949],
              [16.107256056000153, 2.412759826000013],
              [16.107727051000154, 2.411334754000052],
              [16.10892486500012, 2.408318282000096],
              [16.109853744000134, 2.404497861000095],
              [16.110599365000098, 2.402279854000142],
              [16.111183165999989, 2.400543213000049],
              [16.111857536000059, 2.398957397000118],
              [16.112779618000104, 2.396789075000129],
              [16.114676844000087, 2.393571250999969],
              [16.115507126000011, 2.392163038000092],
              [16.116076277000104, 2.391221127000051],
              [16.117370606, 2.389079094000181],
              [16.119100571000047, 2.386464119000095],
              [16.119902766999985, 2.385713446000068],
              [16.120964000000129, 2.384720000000129],
              [16.124297999000078, 2.381114],
              [16.125490000000184, 2.37982499899999],
              [16.126246334000029, 2.378961484000058],
              [16.127487182000039, 2.377544641000156],
              [16.12831794900012, 2.376578973000051],
              [16.130082998999967, 2.374527000000171],
              [16.131672910000191, 2.372154080000087],
              [16.131879806000029, 2.371845246000135],
              [16.132644654000103, 2.369808913000156],
              [16.132810592000169, 2.369364976999975],
              [16.133340836000173, 2.366885186000047],
              [16.133458880000148, 2.366719539000144],
              [16.13520431600017, 2.364270211000132],
              [16.137201310000023, 2.362258197000131],
              [16.139110309000102, 2.36087676600016],
              [16.139797211000086, 2.360379696000052],
              [16.141927719000023, 2.358501672999978],
              [16.142780270000117, 2.357044391000045],
              [16.143457000000126, 2.355887001000042],
              [16.144388000999982, 2.353742001000171],
              [16.146452000000124, 2.350188001000106],
              [16.148113, 2.346837001000154],
              [16.148977000000173, 2.343619],
              [16.149275329000091, 2.341507554000145],
              [16.149440766000168, 2.340335369000059],
              [16.148904801000128, 2.336516379999978],
              [16.147304535999979, 2.333502054000178],
              [16.147026634000099, 2.331795674000034],
              [16.146835326000087, 2.330621004000022],
              [16.146800981000183, 2.329581943999983],
              [16.146766663000164, 2.328543664000051],
              [16.147403107000059, 2.32644136100015],
              [16.147497163000025, 2.326130674000069],
              [16.148494721000134, 2.324253559000113],
              [16.149692535000156, 2.32177329200016],
              [16.15087722200019, 2.319426830000168],
              [16.151554107000152, 2.318086146999974],
              [16.153882981000095, 2.313125372000116],
              [16.155104530000187, 2.310802674000172],
              [16.15620994600016, 2.308700800000167],
              [16.157939911000028, 2.305013657000188],
              [16.159603119000167, 2.30179572100019],
              [16.161397934000092, 2.298242807],
              [16.165189744000088, 2.29046654699999],
              [16.166585921999967, 2.288053037000168],
              [16.16670245500012, 2.287768493000044],
              [16.167481999000131, 2.28586400100005],
              [16.169113001000085, 2.281886000000156],
              [16.169433021000089, 2.280771873000106],
              [16.169651031, 2.28001260800005],
              [16.169827330000146, 2.279399343000023],
              [16.170307, 2.27773],
              [16.170636644000126, 2.275135540000122],
              [16.170639000000165, 2.275116993000097],
              [16.170369999000172, 2.271967000000132],
              [16.170345581000106, 2.271860300000185],
              [16.169633865000037, 2.268751383000108],
              [16.169267690000027, 2.267255201000126],
              [16.168699, 2.264933001000145],
              [16.167078650000064, 2.26272831100016],
              [16.16549873299999, 2.260579347000032],
              [16.164517907000061, 2.258769903000029],
              [16.163429, 2.25676200099997],
              [16.162037037000118, 2.255136808000088],
              [16.161363602000108, 2.254350900000077],
              [16.160429020000038, 2.252542166000183],
              [16.16047211800003, 2.252324787000021],
              [16.160827637000068, 2.250531674000058],
              [16.161205973999984, 2.24930914],
              [16.161491393000063, 2.248386860999972],
              [16.163022995000176, 2.246375084000079],
              [16.164485473000184, 2.245364016000053],
              [16.165351869000119, 2.244765044000076],
              [16.168746948000091, 2.24248385400017],
              [16.169776916000046, 2.241406679000136],
              [16.170322360000057, 2.240836781000041],
              [16.170478821000131, 2.240673304000097],
              [16.170547139000121, 2.240604236000081],
              [16.170587539000053, 2.24056339200007],
              [16.17196215700011, 2.239178331000119],
              [16.172941207000122, 2.238191843000038],
              [16.173160943000028, 2.237970510000139],
              [16.17507171699998, 2.23604583700012],
              [16.177333833000148, 2.234301806000133],
              [16.178274995000038, 2.233685620000131],
              [16.179998398000066, 2.232557297000142],
              [16.180259705000083, 2.232400179000081],
              [16.182014465000179, 2.231344939000167],
              [16.183396746000028, 2.230513642000062],
              [16.183792113000152, 2.230275870000128],
              [16.184249290000082, 2.22993055000012],
              [16.185357999000018, 2.229093],
              [16.185934982000106, 2.228657168000098],
              [16.186988830000075, 2.22786092900003],
              [16.18743403700006, 2.227321960000097],
              [16.187969209000073, 2.226674081000056],
              [16.188653415000033, 2.225846632000071],
              [16.188983916000097, 2.225446941000143],
              [16.189256826000133, 2.224806177000062],
              [16.189491308000186, 2.224254213000052],
              [16.189981001000092, 2.223100999],
              [16.190855537000175, 2.220458503000032],
              [16.191045761000112, 2.219883441999969],
              [16.190841676000161, 2.215929985000173],
              [16.190566572000137, 2.214227290000054],
              [16.190441, 2.213450999000031],
              [16.188839001000133, 2.210437001000059],
              [16.187038, 2.20816],
              [16.184877956000037, 2.205989151000097],
              [16.18483924200001, 2.205950245999986],
              [16.183990385000016, 2.204683985000145],
              [16.182504653000024, 2.202467679000051],
              [16.181996735000098, 2.201805628000102],
              [16.180038000000138, 2.199253000999988],
              [16.177839, 2.197848],
              [16.174974000000191, 2.197381001],
              [16.173858394999968, 2.197032699000147],
              [16.171976089, 2.196445227000027],
              [16.166179658000146, 2.195243358000141],
              [16.162248611000052, 2.194643497000186],
              [16.160894283000061, 2.193491647000087],
              [16.16051674, 2.193170548000069],
              [16.158916473000033, 2.191362619000131],
              [16.155849456, 2.18734431300004],
              [16.155331751000062, 2.186724377000076],
              [16.154115677000163, 2.185268162999989],
              [16.153559918000099, 2.183338326000126],
              [16.153112, 2.181783999000061],
              [16.152855926000143, 2.181459706000112],
              [16.151313781000169, 2.179507493000131],
              [16.148580551000123, 2.177499293999972],
              [16.145715714000175, 2.176563263000105],
              [16.144363126000144, 2.176534750000144],
              [16.142651, 2.176499],
              [16.140609999000048, 2.177110999000092],
              [16.140185999000153, 2.177238001000148],
              [16.139256001000092, 2.178273],
              [16.138257999000132, 2.179384000000141],
              [16.138132652000138, 2.179868112000065],
              [16.137859344999981, 2.180924892000121],
              [16.137678840000092, 2.182632425000065],
              [16.13746261700004, 2.18467784000012],
              [16.137065888000166, 2.189301967000176],
              [16.136268616000109, 2.193189382000014],
              [16.13540458599999, 2.195200206000152],
              [16.133939742000166, 2.196541549000187],
              [16.132087904000059, 2.197121619000029],
              [16.132009506000145, 2.19714617700015],
              [16.128412248000018, 2.197751998000058],
              [16.124216079, 2.198157310000056],
              [16.117622375000167, 2.199301481000134],
              [16.111694337000131, 2.199908972000173],
              [16.105966569000145, 2.200650454000083],
              [16.099304199000073, 2.200990440000169],
              [16.093776703000174, 2.20092749700018],
              [16.090218553000113, 2.20067446000013],
              [16.08911323600006, 2.200595856000177],
              [16.087246036000067, 2.199368089000132],
              [16.087179178000042, 2.199324122000121],
              [16.085512160000178, 2.196979999000064],
              [16.084736432999989, 2.195537017000049],
              [16.083711623000113, 2.193630695000024],
              [16.083433221000178, 2.191451965000056],
              [16.083309174000135, 2.190481187000046],
              [16.08337402300009, 2.187934876000099],
              [16.083572387000174, 2.184047938000049],
              [16.083712278000064, 2.181041655000058],
              [16.083768844000133, 2.179826021000054],
              [16.082963943000095, 2.172656060000179],
              [16.082761764000111, 2.169774772000153],
              [16.082826614000169, 2.166022063000071],
              [16.082290649000129, 2.163274765000153],
              [16.081513716000075, 2.160657173000118],
              [16.081356047999975, 2.160125970000138],
              [16.081210930000054, 2.158910839000043],
              [16.080619811000076, 2.153961181000113],
              [16.080148696000094, 2.148600578000071],
              [16.079677582000045, 2.141028405000157],
              [16.079601007000178, 2.135868949000098],
              [16.079538345000174, 2.131646873000022],
              [16.079935074000048, 2.128429890000177],
              [16.080003877000138, 2.128158450000115],
              [16.080665588000045, 2.125547887000039],
              [16.080939507000096, 2.124617590000071],
              [16.081226, 2.12364299900014],
              [16.08173, 2.121928001000185],
              [16.082297743000026, 2.119804876000103],
              [16.082859039000084, 2.117705822000175],
              [16.084712983000031, 2.112318992000155],
              [16.084747365, 2.112218954000014],
              [16.085118999000031, 2.111137001000145],
              [16.086515001000066, 2.108187],
              [16.08764600000012, 2.104635000000144],
              [16.088907, 2.100210999000126],
              [16.089304516000027, 2.09814514600015],
              [16.089744568000071, 2.095856428000161],
              [16.089760793000039, 2.095773996000048],
              [16.089771001000031, 2.095721001000015],
              [16.089727902999982, 2.095377004000056],
              [16.089715957000067, 2.095282316000066],
              [16.089435577000131, 2.093040466000048],
              [16.088482782000085, 2.090487570000164],
              [16.087834999000052, 2.088752999000121],
              [16.086433000000113, 2.085671000000104],
              [16.08544808, 2.083646071000146],
              [16.084966660000134, 2.082656622000059],
              [16.084435851000023, 2.081767882000065],
              [16.083366, 2.079976999000166],
              [16.0807, 2.077701],
              [16.07736799900016, 2.07656399900003],
              [16.072970999000177, 2.075428000999978],
              [16.070062039000163, 2.074815774000058],
              [16.068841934000034, 2.074559213000043],
              [16.066318189000185, 2.073426831000177],
              [16.065710067000168, 2.073153972000057],
              [16.063709259000177, 2.07067585100009],
              [16.063442231000067, 2.06920170900014],
              [16.063774109000178, 2.067325354000047],
              [16.064905167000177, 2.064509869000062],
              [16.065929413000163, 2.063052654000046],
              [16.066665096, 2.062006127000018],
              [16.066892623000115, 2.061682462000022],
              [16.067011360000095, 2.061513532000049],
              [16.067167259000087, 2.061291729000061],
              [16.067468642000051, 2.06098794899998],
              [16.068657702000053, 2.059790479000014],
              [16.07003021200012, 2.058408261000068],
              [16.070875407000131, 2.057846299000119],
              [16.071954727000104, 2.057128668000132],
              [16.073158264000085, 2.056328774000065],
              [16.073997188000078, 2.055964719000031],
              [16.075410999000042, 2.055351000000144],
              [16.077953001000083, 2.054248],
              [16.079924000000176, 2.053278],
              [16.080179227000087, 2.053152389000047],
              [16.080816176000098, 2.052838850000114],
              [16.080850663000035, 2.052809095000043],
              [16.081325531000061, 2.052398920000144],
              [16.082214006000129, 2.051631991000022],
              [16.082471968000164, 2.051177345000042],
              [16.083278656000118, 2.049754620000044],
              [16.083608628000036, 2.045666694000033],
              [16.082926235000173, 2.044003535000172],
              [16.082674000000168, 2.043389],
              [16.080738, 2.036822999000037],
              [16.079538, 2.033339000000183],
              [16.077938000000188, 2.031531000000143],
              [16.075137999000049, 2.028383001000179],
              [16.071003565000069, 2.024025348000123],
              [16.06967386000008, 2.022623879000093],
              [16.067206999000177, 2.020481001000064],
              [16.058325040000057, 2.012863890000176],
              [16.05674362200017, 2.011507751000124],
              [16.053611755000077, 2.008360148],
              [16.053077698000038, 2.005948068000123],
              [16.053017191000094, 2.002936643000055],
              [16.05300903400007, 2.002530695000075],
              [16.053122830000063, 2.001955192000139],
              [16.053539277000141, 1.999849557000118],
              [16.053759543000069, 1.99914220800008],
              [16.054269791000024, 1.997503639000172],
              [16.055133819000105, 1.994420410000032],
              [16.055221605000099, 1.994175992000066],
              [16.056154, 1.991579000000115],
              [16.057004287000154, 1.989210930000127],
              [16.057395935000045, 1.988119722000079],
              [16.057605743000181, 1.98705685200008],
              [16.05779266400009, 1.98610901800015],
              [16.057857514000148, 1.982959390000133],
              [16.05725669900005, 1.980815292000045],
              [16.055406843000128, 1.979528150000078],
              [16.054658889000052, 1.97900772100013],
              [16.053753057000051, 1.978279438000186],
              [16.051660539000125, 1.976597070000082],
              [16.050792693000062, 1.974587321000115],
              [16.050988097000072, 1.973634548000064],
              [16.051191330000108, 1.972643614000162],
              [16.051843242000075, 1.971702542000116],
              [16.05249023500005, 1.970768572000111],
              [16.053507766000109, 1.97014218400011],
              [16.054531, 1.969511999000133],
              [16.057512000000145, 1.967944999000167],
              [16.057594001000098, 1.967902],
              [16.05779599900012, 1.967678001000081],
              [16.059656, 1.965621999000121],
              [16.060278470000071, 1.96461434600019],
              [16.061187745000041, 1.963141799000084],
              [16.062316896000141, 1.960594534999984],
              [16.063518970000132, 1.959432733000142],
              [16.063982008999972, 1.958985210000151],
              [16.066444397000168, 1.957643271000052],
              [16.068165340000178, 1.956572485000095],
              [16.068708420000178, 1.956234575000053],
              [16.069052907000128, 1.955972382000084],
              [16.070853999000121, 1.954601000000139],
              [16.071704999000019, 1.953953999000021],
              [16.071748605000096, 1.953894471000069],
              [16.072050094000076, 1.953482629000064],
              [16.073766708000164, 1.951138259000061],
              [16.074025763, 1.950354754000159],
              [16.074697494000077, 1.948323130000119],
              [16.075471828000047, 1.946274979],
              [16.075761796000052, 1.945508003000157],
              [16.076222459000064, 1.943991301999972],
              [16.076759338000159, 1.942223669000043],
              [16.077198254000052, 1.941001507000181],
              [16.078155518000017, 1.938336015000061],
              [16.080017090000069, 1.932437658000083],
              [16.080308389000095, 1.931556032000117],
              [16.081213, 1.928818],
              [16.082941, 1.923121000000151],
              [16.083017423000058, 1.922854723000057],
              [16.083671568999989, 1.9205740700001],
              [16.08426857000012, 1.916351914000188],
              [16.084506259000136, 1.914143575000139],
              [16.084664999000154, 1.912665999000126],
              [16.084663, 1.908712],
              [16.084804452000014, 1.904381555000157],
              [16.084859847000075, 1.902680874],
              [16.084893171000033, 1.90188338500019],
              [16.084991456000068, 1.899531246000038],
              [16.085721970000122, 1.896381141000063],
              [16.086851120000119, 1.893364907000091],
              [16.087004523000076, 1.893080285000167],
              [16.088115692000088, 1.89101863000019],
              [16.089845658000058, 1.88927519300006],
              [16.089983103000179, 1.889180262000139],
              [16.092176437000091, 1.88766539],
              [16.092887535000102, 1.88746603200002],
              [16.093374251000057, 1.887329579000038],
              [16.094571008000059, 1.887288682000133],
              [16.095373155000061, 1.887261271000057],
              [16.096074360000102, 1.887387458000092],
              [16.097475051000117, 1.887639524000178],
              [16.097970961999977, 1.887728811000045],
              [16.098088001000178, 1.887791107000055],
              [16.098262787000067, 1.887884139999983],
              [16.098970201000157, 1.888260552000077],
              [16.100234984999986, 1.888933540000153],
              [16.102933884000095, 1.890205622000053],
              [16.103500366000105, 1.890472651000039],
              [16.105853154999977, 1.891365143000144],
              [16.10649899900011, 1.89161],
              [16.108096999, 1.891743001000179],
              [16.110294000000181, 1.891004],
              [16.110934999000165, 1.889470000000131],
              [16.111103399000058, 1.889067670000031],
              [16.111358642000141, 1.888457299000095],
              [16.111356736000175, 1.886849047000055],
              [16.111354828000174, 1.884034515000053],
              [16.111330414000122, 1.883958553000184],
              [16.110687, 1.881958000000111],
              [16.109967335000022, 1.880642378000175],
              [16.109220504000177, 1.879278064000062],
              [16.10826849100016, 1.877924876000179],
              [16.107288361000087, 1.876531721000106],
              [16.106287002999977, 1.874320985000168],
              [16.106220246000078, 1.872444628999972],
              [16.106291577000036, 1.871697572000073],
              [16.106418610000162, 1.870367170000122],
              [16.106709339000133, 1.869368983000072],
              [16.107082365999986, 1.868088245000138],
              [16.108069129, 1.865369935000103],
              [16.108079953000015, 1.865340126000035],
              [16.109676001000082, 1.861518999000168],
              [16.110672001000069, 1.857096001000116],
              [16.110803605000115, 1.853946328000063],
              [16.110734940000043, 1.85106492],
              [16.111012920000121, 1.84984975399999],
              [16.1115322120001, 1.847579717000031],
              [16.111764515000061, 1.846892939000043],
              [16.112529754000036, 1.844630599000084],
              [16.113725662000036, 1.842150330000152],
              [16.114861158000053, 1.83990262400016],
              [16.115656, 1.838328999000055],
              [16.117851001000076, 1.834374000000139],
              [16.118558598999982, 1.832591169000125],
              [16.119180680000056, 1.831022858000154],
              [16.120365691000131, 1.828053441],
              [16.121374001000049, 1.825526],
              [16.122570000000167, 1.820969001000037],
              [16.123701001000029, 1.818756999000186],
              [16.125765, 1.817348001000141],
              [16.129090999000141, 1.812588001000108],
              [16.130997680000064, 1.810104430000024],
              [16.131355205000091, 1.809638605000146],
              [16.131496783000046, 1.809366751000027],
              [16.134147644000109, 1.804275752000081],
              [16.135131873000091, 1.802403025999979],
              [16.135944001000155, 1.800857001000054],
              [16.137805999000022, 1.797371],
              [16.138538001000029, 1.79469],
              [16.138668037000116, 1.790870804000065],
              [16.139598847000059, 1.788323522000155],
              [16.139560602000188, 1.787574935000123],
              [16.139465331, 1.785710215000051],
              [16.138996124000187, 1.781756760000064],
              [16.139059067000119, 1.777266979000103],
              [16.139135958, 1.77672405200002],
              [16.139457703000062, 1.774452210000163],
              [16.140489693, 1.772162714999979],
              [16.140786999000113, 1.77150299900012],
              [16.140937400000041, 1.771058580000045],
              [16.141784667000081, 1.768553734000079],
              [16.142581939000138, 1.765001655000106],
              [16.142539678999981, 1.764444907000041],
              [16.142246001000046, 1.760579000000178],
              [16.141424282000173, 1.758637824000118],
              [16.140913009000087, 1.757430315000136],
              [16.138511658000084, 1.753143072000057],
              [16.135935781000171, 1.74754486900008],
              [16.135644912000032, 1.746912717000157],
              [16.134950918000129, 1.745148082000071],
              [16.134511949000171, 1.744031907000021],
              [16.134908677000112, 1.740681172000166],
              [16.135277317000032, 1.739327331000141],
              [16.135839, 1.737263],
              [16.136503000000118, 1.733509999000034],
              [16.13701860599997, 1.730751819000034],
              [16.137166976000174, 1.729957819999981],
              [16.139226912000083, 1.724662662000128],
              [16.140565515000105, 1.722431218000054],
              [16.141157150000083, 1.72144496400017],
              [16.142995936000034, 1.718736219000107],
              [16.143751144000134, 1.717623712000147],
              [16.1460819240001, 1.714606763],
              [16.146899136, 1.713123366000104],
              [16.147411, 1.712194001000057],
              [16.147677234000071, 1.711098369000183],
              [16.148208618000183, 1.708909511000059],
              [16.148738861000083, 1.70542454800011],
              [16.149135589000082, 1.69925940100012],
              [16.149131774000182, 1.694233536000183],
              [16.14833068900009, 1.691151381000168],
              [16.14707356200006, 1.689300557000138],
              [16.146465001000081, 1.688405],
              [16.145337905000076, 1.687492612000028],
              [16.144065857000157, 1.686463117000017],
              [16.14146804700016, 1.684521318000066],
              [16.140605133000179, 1.684323795000125],
              [16.139219283999978, 1.684006572000101],
              [16.138260540999966, 1.683787056000028],
              [16.137672424000073, 1.683652400000085],
              [16.134876251000094, 1.684793234000154],
              [16.133935471000029, 1.685940385000151],
              [16.133611679000069, 1.686335206000024],
              [16.133340836000173, 1.686660171000028],
              [16.131547927000042, 1.688815952000141],
              [16.129880904000061, 1.691015600000014],
              [16.128753662, 1.692503215000102],
              [16.126956940000071, 1.694715619000021],
              [16.125288769000122, 1.696560960000056],
              [16.124229432000163, 1.697732807000079],
              [16.121831894000024, 1.698873401000128],
              [16.118701935000104, 1.69941127400017],
              [16.115173340000126, 1.699480295000058],
              [16.114560525000115, 1.69926885100017],
              [16.113040925000178, 1.698744535000117],
              [16.11025357599999, 1.697559701000159],
              [16.109577178, 1.697272181000187],
              [16.105287837000105, 1.695670272000029],
              [16.104382, 1.695331999000018],
              [16.102344667000125, 1.694713855000032],
              [16.097988129999976, 1.693392157000119],
              [16.096353578000105, 1.692895409000073],
              [16.092926025000111, 1.691853762000108],
              [16.091888163000021, 1.69151335600003],
              [16.08966255199999, 1.690783382000177],
              [16.085998535000158, 1.689512252000156],
              [16.083934784, 1.687905074000071],
              [16.083455960000038, 1.68725234700014],
              [16.082067489000167, 1.685359598000105],
              [16.078468323000095, 1.680000544000109],
              [16.077530959000171, 1.677467648000118],
              [16.077402115000154, 1.677119494000124],
              [16.075269700000035, 1.674239159000081],
              [16.071937561000084, 1.669147968000118],
              [16.070470810000131, 1.666736365000133],
              [16.068204879000177, 1.663721919000125],
              [16.065471649000131, 1.660372734000021],
              [16.062072754000042, 1.655348659000083],
              [16.061861228000112, 1.654938393000066],
              [16.061140000000137, 1.653540001000181],
              [16.060727139000164, 1.653013901000179],
              [16.060140633000117, 1.652267129000052],
              [16.059005999000021, 1.652665001000116],
              [16.058646948000046, 1.652790714000105],
              [16.058609009000065, 1.652804017000051],
              [16.057411193000064, 1.654345870000157],
              [16.055347442000084, 1.655754328000171],
              [16.053371203000097, 1.657214329000112],
              [16.05335044800006, 1.65722966200002],
              [16.052303901000073, 1.657590696000057],
              [16.05082130500017, 1.65810215499999],
              [16.050074736000113, 1.658142100000021],
              [16.04829, 1.65823799900005],
              [16.046129765000103, 1.658394068000121],
              [16.045494079999969, 1.658440113999973],
              [16.044745557000169, 1.658377727000072],
              [16.04229699900003, 1.658173999000098],
              [16.040777662000039, 1.65814262400005],
              [16.039100647999987, 1.658108353000159],
              [16.037475092000136, 1.658109241000091],
              [16.035172, 1.658111000000133],
              [16.032309000000112, 1.658178999000143],
              [16.031075000000158, 1.658366001000161],
              [16.030595280000057, 1.658438410000031],
              [16.029644012000063, 1.658582448000061],
              [16.028179169000055, 1.659655453000084],
              [16.027766963999966, 1.660090755000056],
              [16.027571000000137, 1.660298],
              [16.026783, 1.661130999000136],
              [16.02528267800011, 1.663572276000082],
              [16.025053025000034, 1.663946032000126],
              [16.023256303000096, 1.667632699000023],
              [16.022823333000133, 1.668614150000167],
              [16.021924973000068, 1.670649171000036],
              [16.021783629000083, 1.671035951000079],
              [16.020528999000192, 1.674470000999975],
              [16.019665, 1.67788800000011],
              [16.019333, 1.680367999000055],
              [16.019460543999969, 1.682567813000162],
              [16.019535064000081, 1.683852197000022],
              [16.020002365000153, 1.687135578000152],
              [16.020652111000061, 1.689613821000137],
              [16.021004000000119, 1.690954999000098],
              [16.021269, 1.691900001000136],
              [16.021467174000122, 1.692605222000111],
              [16.022472381000057, 1.696181297000123],
              [16.023340225000084, 1.700603724000018],
              [16.023941040000125, 1.703820110000095],
              [16.023916531000168, 1.70415133400013],
              [16.023742676000097, 1.706500768000183],
              [16.023612977000084, 1.707167864000155],
              [16.023579586000096, 1.707340904000034],
              [16.023275376000129, 1.708917379000184],
              [16.02324591199999, 1.709067894000157],
              [16.023210526000128, 1.709248662000164],
              [16.021986008000056, 1.710737261000134],
              [16.021280288000071, 1.711595177000049],
              [16.020232952000129, 1.712359401000072],
              [16.01935005200005, 1.713003636000167],
              [16.016887665000183, 1.713809013000116],
              [16.016388135000057, 1.714186016000099],
              [16.016180039000062, 1.714343071000087],
              [16.015821457000072, 1.714613796000151],
              [16.015201568, 1.715199471000062],
              [16.015122804999976, 1.715273924000087],
              [16.01475711400019, 1.715619893],
              [16.014492035000046, 1.717630148000183],
              [16.014492751999967, 1.71871353099999],
              [16.014493999000024, 1.720511954000017],
              [16.014891657000021, 1.721617226000035],
              [16.015627, 1.723661000000163],
              [16.016661752000061, 1.726156325000147],
              [16.01729393100004, 1.727680803000169],
              [16.017370224000047, 1.727895856000089],
              [16.018012630999976, 1.72971578500011],
              [16.018761, 1.731835],
              [16.02069500000016, 1.736726],
              [16.021238786000026, 1.737866778000182],
              [16.022228240000175, 1.739941834999968],
              [16.026960374000055, 1.746774555000116],
              [16.028959275000147, 1.749387145000071],
              [16.031358728000157, 1.753071571000078],
              [16.032159805000106, 1.755483508999987],
              [16.032092120000073, 1.756827712000131],
              [16.032028199000024, 1.758097171000088],
              [16.031894196000053, 1.758975806000024],
              [16.031629563000081, 1.760710956000082],
              [16.02989959700011, 1.763660550000054],
              [16.029619601000036, 1.763929646000122],
              [16.029565811000055, 1.763981342000136],
              [16.027621354000189, 1.765847419000068],
              [16.02710533100003, 1.766342640000119],
              [16.02517318800011, 1.766879915000061],
              [16.022310256000026, 1.767283559000077],
              [16.020443606000129, 1.767134353000131],
              [16.018980025000076, 1.767017364000139],
              [16.016840805000015, 1.766884021000067],
              [16.01471710300018, 1.766751647000092],
              [16.011054993000016, 1.766485572000022],
              [16.007049032000111, 1.766101224000181],
              [16.006193161000112, 1.766019107000091],
              [16.005191849000028, 1.765962887000057],
              [16.001464999000063, 1.765754001000175],
              [15.993687968000074, 1.765706681000154],
              [15.99127483400008, 1.765692115000036],
              [15.985147477000169, 1.765695333999986],
              [15.980936015000054, 1.766133954000054],
              [15.980619430000047, 1.766166927000029],
              [15.978754043000151, 1.766167879000136],
              [15.977231965000158, 1.766000833000135],
              [15.973892212000123, 1.765634299],
              [15.969762801000172, 1.765234351000174],
              [15.967955603000178, 1.765356575000112],
              [15.965767001000131, 1.765504999000143],
              [15.961438084000122, 1.766042990000187],
              [15.961235929, 1.766131781000127],
              [15.958841, 1.7671840000001],
              [15.953848999000058, 1.770403000000158],
              [15.947657000000106, 1.774293000000114],
              [15.94619714200013, 1.77509274800002],
              [15.943861962000028, 1.77637243300012],
              [15.937735558000099, 1.779592396],
              [15.936611329000073, 1.779935849000083],
              [15.93553829199999, 1.780263662000152],
              [15.93327426899998, 1.780465842000183],
              [15.929345130000058, 1.780467867000084],
              [15.925349235000112, 1.780335783000169],
              [15.920154572000058, 1.780673504000106],
              [15.91785301200008, 1.781034885000111],
              [15.917157174000067, 1.781144143000063],
              [15.915496885000096, 1.781901027000174],
              [15.914361000000156, 1.782418999000015],
              [15.913562999000135, 1.784497000000101],
              [15.913063898000132, 1.785713559000158],
              [15.913019180000163, 1.785822630000041],
              [15.912298203000091, 1.787580253000044],
              [15.91234016300001, 1.78784167800012],
              [15.912514179000027, 1.7889151440001],
              [15.912699700000189, 1.790059566000082],
              [15.913300513000081, 1.793678166000177],
              [15.913346163000142, 1.795123873000023],
              [15.913368110000079, 1.795822696000187],
              [15.912028274000079, 1.796677687000113],
              [15.911371122000048, 1.797096980000106],
              [15.908241271000179, 1.797433615000159],
              [15.904244423000023, 1.797435522000058],
              [15.901524066000093, 1.797436870000126],
              [15.901114301000064, 1.797437037000066],
              [15.897784233000039, 1.796701670000061],
              [15.894985710000014, 1.795717663000119],
              [15.893787384000177, 1.795296312000119],
              [15.893017402000055, 1.794875428000125],
              [15.88999, 1.79322100100012],
              [15.888256999000077, 1.791144001000021],
              [15.885391998999978, 1.787929],
              [15.884234933000187, 1.787527739000154],
              [15.883460045000049, 1.787259340000048],
              [15.88312721200009, 1.787695170000063],
              [15.882794381, 1.788130761000105],
              [15.882795333999979, 1.789301396000099],
              [15.882795333999979, 1.789404153000135],
              [15.882863044000089, 1.791481615000066],
              [15.882863977000113, 1.793251863000023],
              [15.882863999000108, 1.79329105100004],
              [15.882848477000039, 1.793455882000103],
              [15.882598877000191, 1.796105981999972],
              [15.88249416900004, 1.796831257000065],
              [15.882134438000037, 1.799322963000179],
              [15.88053703300011, 1.802339434000089],
              [15.879282038000099, 1.804831170000171],
              [15.878274998999984, 1.806831000000102],
              [15.876677999000037, 1.809847],
              [15.876262229000076, 1.811453291000021],
              [15.87588024199999, 1.812930465000022],
              [15.874908303000154, 1.815623822000021],
              [15.874017714999979, 1.818091750000122],
              [15.872635349, 1.821840101000021],
              [15.871224404000088, 1.825665950000087],
              [15.870373838000035, 1.828012558000125],
              [15.869693757000164, 1.829888820000122],
              [15.868401410999979, 1.83119094000017],
              [15.86789703300019, 1.831699133000029],
              [15.867338211000117, 1.831942563000098],
              [15.865432740000131, 1.832772613000032],
              [15.863168716000018, 1.832907797000019],
              [15.859039308000149, 1.833043813000131],
              [15.855678012, 1.833093040000165],
              [15.854310036000186, 1.833113074000039],
              [15.851712227000064, 1.83264529700017],
              [15.849976057000106, 1.832576188000076],
              [15.848382000000129, 1.832512999000073],
              [15.847011019000092, 1.832901585000059],
              [15.846251488000064, 1.833117009000148],
              [15.845078616000023, 1.833695136000074],
              [15.84312200100004, 1.834660001000088],
              [15.842190001, 1.836670999000035],
              [15.840526001000171, 1.838815999000019],
              [15.836199, 1.843711000000155],
              [15.833138001000123, 1.847464999000124],
              [15.830074532000083, 1.850730474000045],
              [15.829741434000027, 1.851085565000062],
              [15.828252298000109, 1.851791555000034],
              [15.827477330000136, 1.852158904000135],
              [15.825613022000027, 1.852159739000115],
              [15.823215484000116, 1.851892829000065],
              [15.822436427000014, 1.851953477000109],
              [15.819751739000139, 1.852162481000164],
              [15.818706868000106, 1.852230832000032],
              [15.817687, 1.852298001000179],
              [15.815272705000041, 1.853146038000091],
              [15.814823150999985, 1.853304030000174],
              [15.8116543000001, 1.854964022000047],
              [15.811494000000152, 1.855048001000171],
              [15.805901999000127, 1.859005],
              [15.802917378000188, 1.861580756000024],
              [15.802639007000153, 1.861821056000053],
              [15.796913147000112, 1.866783024000085],
              [15.793983459, 1.870135308000158],
              [15.792930396000031, 1.871549802000118],
              [15.792187001000059, 1.872549],
              [15.791492001000108, 1.874182000000133],
              [15.791291882000166, 1.874651354000037],
              [15.791188241000043, 1.874894857000072],
              [15.790266379000116, 1.877507414000093],
              [15.790123939000068, 1.877911091000044],
              [15.789762260999964, 1.878862471000048],
              [15.789033890000042, 1.880778432000057],
              [15.788975071000038, 1.880932961000156],
              [15.788926125000103, 1.881061553000109],
              [15.788744612000073, 1.881529183000055],
              [15.787729264000063, 1.884145022000041],
              [15.786862310000117, 1.88639674600006],
              [15.786464692000095, 1.887429476000023],
              [15.785067559000026, 1.889775754000027],
              [15.781937600000163, 1.892323851000072],
              [15.778475760000049, 1.894738078999978],
              [15.776411056000086, 1.896615505000057],
              [15.774651289000076, 1.898153815000171],
              [15.772882461000108, 1.899700046000135],
              [15.768955231000064, 1.904259085000149],
              [15.763895035000075, 1.910292983000034],
              [15.763861899000176, 1.910315427000114],
              [15.760033998999972, 1.912908999000138],
              [15.757589105000022, 1.914682912999979],
              [15.75716972400005, 1.914987325000141],
              [15.754533314000071, 1.916417439000156],
              [15.752841949000185, 1.917334915000026],
              [15.748046874000181, 1.919481636000114],
              [15.745781898000189, 1.919281602000069],
              [15.743050575000041, 1.918679596],
              [15.739393699000175, 1.91693554800014],
              [15.739119660000142, 1.916804853000087],
              [15.732991219000155, 1.914327742000182],
              [15.730654581000067, 1.913345753000044],
              [15.729648590000068, 1.91292297900003],
              [15.729497812000034, 1.912859613000137],
              [15.729327, 1.912787999000045],
              [15.729120925000188, 1.912787999000045],
              [15.72817516300006, 1.912788392000039],
              [15.728006188000052, 1.912788445000103],
              [15.727794999000082, 1.912788999000156],
              [15.726758199000074, 1.913689921000071],
              [15.726329804000102, 1.914062500000114],
              [15.726260883000123, 1.914168630000177],
              [15.726060867000115, 1.914476634000096],
              [15.725289661999966, 1.915665327000113],
              [15.725198746000103, 1.915805459000183],
              [15.72436491000002, 1.916854613999988],
              [15.72412700100017, 1.917154],
              [15.72405390300014, 1.917245962000038],
              [15.723600389000126, 1.917816758000015],
              [15.722509075000062, 1.91944728600015],
              [15.72172260300016, 1.920622349000098],
              [15.721536637000042, 1.920900583000048],
              [15.719782136000106, 1.922668102000159],
              [15.719673102000172, 1.922777922000023],
              [15.718140603000109, 1.923649789000137],
              [15.716076851000025, 1.924320818000126],
              [15.714973200000145, 1.92445724300012],
              [15.712812423000116, 1.924724341000058],
              [15.711304557000062, 1.924792406000051],
              [15.709815, 1.92486000100007],
              [15.708737999000164, 1.925211999000055],
              [15.707351999000082, 1.925664999000048],
              [15.703955000000121, 1.927208001000167],
              [15.700559000000112, 1.92841600100013],
              [15.696198089000177, 1.930280734000121],
              [15.695696831000078, 1.930495142000098],
              [15.690169335000178, 1.933111192000013],
              [15.686506271000098, 1.93425202300017],
              [15.683241845, 1.934387445000027],
              [15.680444717000057, 1.93365132800011],
              [15.678804692000142, 1.932497369000146],
              [15.67777919700012, 1.931775809000101],
              [15.67645031100011, 1.930831036000029],
              [15.675046920999989, 1.929833293000172],
              [15.674077646, 1.929109309000125],
              [15.672715, 1.928092],
              [15.670251000000121, 1.927825000000155],
              [15.669191723000154, 1.927825000000155],
              [15.668931962000045, 1.927825094000184],
              [15.668557513000053, 1.927825232000032],
              [15.66732, 1.927826],
              [15.664980999000079, 1.928268001000163],
              [15.664678602000038, 1.928325097000084],
              [15.664122582, 1.92843020000015],
              [15.664032362, 1.928464059000021],
              [15.663306000000148, 1.928736999000023],
              [15.66072699900019, 1.929704999000023],
              [15.658130799000105, 1.930774545000077],
              [15.656331062000163, 1.931516170000066],
              [15.654746055000032, 1.932314157000064],
              [15.654498101000115, 1.932438970000021],
              [15.654066086000171, 1.932656408000071],
              [15.650147272000083, 1.933784698000181],
              [15.648938180000073, 1.934132815000112],
              [15.644275664000133, 1.93534100100004],
              [15.640345573000104, 1.936079741000071],
              [15.638061272000186, 1.936254056],
              [15.636815072000104, 1.936349154000084],
              [15.636036873000137, 1.93645215100014],
              [15.633479375999968, 1.936790681000105],
              [15.632752419000155, 1.936886907000087],
              [15.63195637900003, 1.93693526300018],
              [15.629422188000035, 1.937089206],
              [15.629092260999982, 1.937164486000029],
              [15.628460883000173, 1.937308551000115],
              [15.625891685000056, 1.937894701000118],
              [15.624098778000075, 1.938262939000026],
              [15.623719235000067, 1.938340889000131],
              [15.622295379000036, 1.938633323000147],
              [15.620479584000122, 1.939065336000112],
              [15.617499352000095, 1.939774394000153],
              [15.616108893999979, 1.940241456000081],
              [15.614103318000161, 1.940915108000183],
              [15.611306190000164, 1.942189455000062],
              [15.608884811000109, 1.943249465000179],
              [15.608713315000102, 1.943324566000058],
              [15.608600617000036, 1.943373918000077],
              [15.608242035000046, 1.943530918000079],
              [15.608194960000162, 1.943542163000075],
              [15.60800647700006, 1.943587183000034],
              [15.605710982000119, 1.944135071000062],
              [15.605564558000083, 1.944182160000139],
              [15.604767001000084, 1.944439000000102],
              [15.602581000000157, 1.9451419990001],
              [15.600117001000115, 1.945813000999976],
              [15.598385, 1.946952999000189],
              [15.598220001000186, 1.947152],
              [15.596387000000163, 1.949365999000179],
              [15.595376999000166, 1.950510999000073],
              [15.59479000000016, 1.951175998999986],
              [15.594499508000126, 1.951307113000041],
              [15.592858315000115, 1.952048182000112],
              [15.589061539000056, 1.953366884000161],
              [15.588994981000042, 1.953390002000162],
              [15.585997581000186, 1.953525066000111],
              [15.584331513000166, 1.952922463000107],
              [15.582667450000088, 1.951972949000037],
              [15.581865999000115, 1.951516001000073],
              [15.57727, 1.949373001000026],
              [15.576864498000077, 1.949240239000062],
              [15.57440471700005, 1.948435425000071],
              [15.571806908000099, 1.948101282000039],
              [15.568543433, 1.947901250000029],
              [15.566202383000132, 1.948046985000076],
              [15.564214, 1.948171001000048],
              [15.562148999000101, 1.948909000000128],
              [15.560609390000025, 1.949822899000139],
              [15.560370445000046, 1.949964762000036],
              [15.559551239000143, 1.950451135000094],
              [15.558395931000121, 1.951153944000055],
              [15.556354999000177, 1.952396000000135],
              [15.555600999000148, 1.953235001],
              [15.554294546000108, 1.95468849299999],
              [15.553824425000016, 1.955211640000073],
              [15.553212167000027, 1.956046939000146],
              [15.552624718000175, 1.956847974000084],
              [15.552021981000109, 1.95766985500012],
              [15.551759719000074, 1.958027125000058],
              [15.549762725000051, 1.96010553900004],
              [15.547231674000159, 1.961781860000087],
              [15.544967716000087, 1.963123046000078],
              [15.54383287800016, 1.963155176000043],
              [15.542569161000131, 1.963190914000165],
              [15.541876123000122, 1.962958721000064],
              [15.539771080000151, 1.962253452000027],
              [15.538926518000096, 1.961168545000078],
              [15.538571001000037, 1.960712001000161],
              [15.536572447000083, 1.958769434000089],
              [15.53590551800005, 1.958299910000108],
              [15.533908000000167, 1.956894000000148],
              [15.531909001000031, 1.955486999000186],
              [15.530509999000117, 1.956359000000134],
              [15.529069492000019, 1.957567007000137],
              [15.527313233000086, 1.95904040400012],
              [15.524050713000179, 1.961789371000123],
              [15.519588471000077, 1.965208889000053],
              [15.514992714000073, 1.968092322000189],
              [15.513633179000124, 1.968874583000115],
              [15.510331153000095, 1.970774532000064],
              [15.505668639000135, 1.973389864000069],
              [15.503537179000034, 1.973993778000136],
              [15.501805306000165, 1.974798561],
              [15.499224849000086, 1.976097702000118],
              [15.493280411000114, 1.97909045200015],
              [15.489749908000135, 1.980767130000118],
              [15.489125939000076, 1.981081287000109],
              [15.486153662999982, 1.982577771000081],
              [15.485920431000068, 1.982358003000059],
              [15.48508739500005, 1.98157274800019],
              [15.483878375000018, 1.979965821000178],
              [15.483222007000109, 1.979093432000184],
              [15.481222152999976, 1.976748347000068],
              [15.478290559000072, 1.97393429300007],
              [15.475758553000048, 1.972125293000147],
              [15.474158582000086, 1.971481951000044],
              [15.473426999000026, 1.971188000000154],
              [15.472292123999978, 1.970939837000117],
              [15.47036266200007, 1.970518349999963],
              [15.467497908000155, 1.970049992000156],
              [15.464701, 1.969782999000188],
              [15.460144087, 1.969474277000188],
              [15.459771156000158, 1.969449043000054],
              [15.459694381000133, 1.969432725000161],
              [15.456306458000029, 1.968712688000153],
              [15.45434545300003, 1.968165225000064],
              [15.453907965999974, 1.968043089000105],
              [15.452810747000058, 1.967660440000145],
              [15.450643538000065, 1.966904641000042],
              [15.449176956000031, 1.966341646000103],
              [15.446979522000106, 1.965498090000097],
              [15.44519341400013, 1.964696407000133],
              [15.443249001000083, 1.963824000000159],
              [15.439385001000119, 1.96221599900008],
              [15.435187999000163, 1.961212],
              [15.433588563000171, 1.960729432000051],
              [15.431857107999974, 1.960207344000025],
              [15.429100926000046, 1.958943285000146],
              [15.428059577999989, 1.958465695000086],
              [15.426542506999965, 1.957475045000024],
              [15.422929764000116, 1.955115916000125],
              [15.420597076000035, 1.952569484000094],
              [15.419331551000028, 1.949888945999987],
              [15.418494806000183, 1.948346828000126],
              [15.417711258000168, 1.946902751000096],
              [15.417530372000101, 1.946569386000135],
              [15.417331999000169, 1.946202999000093],
              [15.416546249000135, 1.945277706000013],
              [15.416126999000085, 1.944783999000151],
              [15.415000000000191, 1.943455999000037],
              [15.413199, 1.942550999000105],
              [15.41195588000005, 1.941925378000121],
              [15.411669001000178, 1.941781000000106],
              [15.409536000000173, 1.940910000000145],
              [15.405606029000182, 1.940375004000089],
              [15.405568561000109, 1.940361661000168],
              [15.402408999000158, 1.939235999000118],
              [15.398012001000154, 1.938969001000032],
              [15.396651176000091, 1.938445273000014],
              [15.39488124799999, 1.937763690000054],
              [15.390967723000131, 1.935947443000146],
              [15.388819, 1.934949999000025],
              [15.377963455999975, 1.929852147000133],
              [15.376693726000099, 1.929255844000068],
              [15.376482875000136, 1.929148020000014],
              [15.372763999000085, 1.927246],
              [15.369832, 1.925437000000102],
              [15.369012748000159, 1.924695467000106],
              [15.366501001000074, 1.922422],
              [15.365576, 1.921675],
              [15.362103, 1.91887000000014],
              [15.361661795000089, 1.918492724000032],
              [15.359439001000169, 1.916592001000026],
              [15.358135640000057, 1.915985628000044],
              [15.35789966499999, 1.915875792],
              [15.357704505000186, 1.915784947000077],
              [15.355841000000112, 1.914917000000116],
              [15.353529001000027, 1.913486001000081],
              [15.352376999000114, 1.912773000000186],
              [15.349579001000052, 1.911634],
              [15.348405001000117, 1.911681001000147],
              [15.346182000000113, 1.911769001000153],
              [15.343916953000189, 1.912976071000173],
              [15.342252001000077, 1.915389001000165],
              [15.34185976700013, 1.916113085],
              [15.340654001000019, 1.918338999000184],
              [15.339553180000166, 1.92013587200006],
              [15.338190079000071, 1.922360541000046],
              [15.335647582000149, 1.926117659000056],
              [15.335081635000108, 1.926954061000117],
              [15.33506, 1.926986],
              [15.335057589000087, 1.927090873000111],
              [15.33505, 1.927421001000027],
              [15.335019829000146, 1.928703245000065],
              [15.334994, 1.929801001000158],
              [15.335224000999972, 1.931073001000073],
              [15.335527001, 1.932749999000066],
              [15.335827272000074, 1.933794609000188],
              [15.335952759000065, 1.934231877000116],
              [15.336527824000143, 1.936234831000093],
              [15.337127686000031, 1.939988017000133],
              [15.335729599000103, 1.941596865],
              [15.335077254, 1.941616887000066],
              [15.333531086000164, 1.941663997999967],
              [15.332198143000085, 1.940123081000138],
              [15.331594310000128, 1.939052842000137],
              [15.330799, 1.937643001000083],
              [15.330007091000027, 1.93671016400009],
              [15.328467001000092, 1.934896001000027],
              [15.326982616000123, 1.933795829000076],
              [15.326543001000061, 1.93347],
              [15.326428847000045, 1.933385511999973],
              [15.325935001000062, 1.93301999900001],
              [15.325152001000106, 1.93283],
              [15.324517001000117, 1.932676],
              [15.322728082000026, 1.93224139199998],
              [15.322071999000173, 1.93208200000015],
              [15.321030796000059, 1.931911337000145],
              [15.318808, 1.931546999000034],
              [15.317471831000034, 1.931954108000014],
              [15.316608999000039, 1.932217001000026],
              [15.313212001000181, 1.933558],
              [15.310481, 1.934228999000084],
              [15.30755, 1.935972000000106],
              [15.306180653000183, 1.937075497000024],
              [15.304887001000054, 1.938117999000156],
              [15.304681476000042, 1.93897863300009],
              [15.304486999, 1.939793],
              [15.304838000000188, 1.941557999],
              [15.304884795000078, 1.941792379000049],
              [15.304954, 1.942139001000101],
              [15.305486999000152, 1.944016],
              [15.307087001000014, 1.951387999000133],
              [15.308166999000036, 1.956144000000109],
              [15.308487000000127, 1.957554],
              [15.308586438000191, 1.958045473000141],
              [15.309286999000165, 1.961508],
              [15.309406903000138, 1.962350187000141],
              [15.309620857000084, 1.963853835],
              [15.309087753000028, 1.966065646000175],
              [15.307823181000174, 1.967808605000073],
              [15.305891038000084, 1.970288754000023],
              [15.301495552000063, 1.975182296000071],
              [15.301051602000086, 1.975852431000021],
              [15.300163000000111, 1.97719300000017],
              [15.297566000000131, 1.980478000000176],
              [15.296433999000044, 1.982221000000152],
              [15.295568001000106, 1.984366],
              [15.29423599900008, 1.986980001000177],
              [15.292496044000075, 1.989093898000022],
              [15.29230499300013, 1.989326001000165],
              [15.291948834000095, 1.989636056000109],
              [15.289841001000013, 1.99147100100015],
              [15.287975, 1.992342999000186],
              [15.285178000000144, 1.994220001000087],
              [15.283860954000033, 1.994662056000152],
              [15.282580375000066, 1.995091796000168],
              [15.281959510000092, 1.995236072000182],
              [15.279983000000186, 1.995695001000058],
              [15.276384999000072, 1.996231999000088],
              [15.273721, 1.99717100100014],
              [15.272757332000083, 1.997575106000113],
              [15.271322001000044, 1.998177000000169],
              [15.268791001000068, 1.999785999000153],
              [15.267021049000164, 2.001013214000182],
              [15.265794, 2.001864],
              [15.262796, 2.00414300000017],
              [15.258933001000116, 2.007227],
              [15.256868340999972, 2.008232834000125],
              [15.256088577000128, 2.008298331000162],
              [15.254470001000129, 2.008434],
              [15.249940001000141, 2.009038000000146],
              [15.246929478000027, 2.009286901000053],
              [15.245876, 2.009374],
              [15.243685000000141, 2.009566001000053],
              [15.243391655000096, 2.009591537000063],
              [15.242812, 2.009642],
              [15.241453, 2.010342999000045],
              [15.240081987, 2.011050183000123],
              [15.23998, 2.012388],
              [15.239965228000017, 2.012574962000088],
              [15.239948, 2.012793001000091],
              [15.240405999000188, 2.013458001],
              [15.24046624499999, 2.013545398000076],
              [15.240548133000061, 2.013664247000065],
              [15.240756987999987, 2.013769389000061],
              [15.242280007000147, 2.014535428000102],
              [15.244011878000038, 2.015347958000177],
              [15.245278358000178, 2.015942336000137],
              [15.247380255999985, 2.016949178000118],
              [15.248076187000095, 2.017282366000188],
              [15.248116000000152, 2.01736],
              [15.248536847000025, 2.018170684000154],
              [15.248875618000113, 2.018823861000101],
              [15.249075890000142, 2.020700454000178],
              [15.249075890000142, 2.02137708600003],
              [15.249076843000125, 2.022644282000101],
              [15.248831833000111, 2.024716499000022],
              [15.248744, 2.025459001000172],
              [15.248627187000068, 2.026489244000061],
              [15.248477937000132, 2.027805066000042],
              [15.248318845000142, 2.027972802000079],
              [15.247078895000016, 2.029279948000067],
              [15.245480537000105, 2.030151607000164],
              [15.243126107000023, 2.030990719000101],
              [15.242283001000033, 2.031291000000181],
              [15.238485001000015, 2.032431],
              [15.234887999000136, 2.03283400000015],
              [15.232090001000074, 2.03236500100013],
              [15.22980593900013, 2.032204388000082],
              [15.228293001000168, 2.03209800000019],
              [15.227057223000031, 2.032123366000121],
              [15.225028999000131, 2.032165],
              [15.222849029000031, 2.032657897000036],
              [15.221765000000119, 2.032902999000044],
              [15.219481936000079, 2.034028099000068],
              [15.218500000000176, 2.034512],
              [15.217054147000169, 2.035094249000167],
              [15.215170001000047, 2.035852999000099],
              [15.212815280000029, 2.037128926000037],
              [15.211706001000152, 2.037730001000114],
              [15.207175999000185, 2.038937000999965],
              [15.204445, 2.038937000999965],
              [15.202777470000115, 2.03866917400012],
              [15.201113701000054, 2.038401604000171],
              [15.198049545000174, 2.037329673000045],
              [15.197497138000188, 2.036989582000047],
              [15.19478400100013, 2.035319001000119],
              [15.194417724000118, 2.034874778000074],
              [15.192518999000129, 2.032571999000027],
              [15.191495341000177, 2.031350784000154],
              [15.190721000000167, 2.030426999000042],
              [15.18865499899999, 2.027947],
              [15.187136684000052, 2.025382450000166],
              [15.186989, 2.025133000000153],
              [15.184923001000129, 2.022184],
              [15.184264001000145, 2.020673],
              [15.183724000000154, 2.019436],
              [15.181192, 2.016487],
              [15.180707527000038, 2.016075545000092],
              [15.180356000000188, 2.01577700100006],
              [15.179816145000188, 2.015319208000108],
              [15.17906, 2.014678000000174],
              [15.178662, 2.014678000000174],
              [15.177571000000114, 2.014678000000174],
              [15.177328001000035, 2.014678000000174],
              [15.175897000000191, 2.014998001000095],
              [15.175389188000111, 2.015111549000039],
              [15.174930919000076, 2.015214073000038],
              [15.17412399900013, 2.015936000000181],
              [15.173730999000099, 2.016286999000158],
              [15.171966157000099, 2.018291998000052],
              [15.171666, 2.018633],
              [15.171621366000068, 2.018703378000168],
              [15.171424867000098, 2.019012690000182],
              [15.170134544000177, 2.021045923000088],
              [15.168003082000041, 2.025067569000157],
              [15.166737557000033, 2.027413605999982],
              [15.165834329000063, 2.028659983000182],
              [15.165719001000127, 2.028819000000112],
              [15.165410516000122, 2.02924472300009],
              [15.164406, 2.030631],
              [15.164036464000105, 2.03226727800012],
              [15.16374, 2.03358],
              [15.163645888000133, 2.034136993],
              [15.163642000000152, 2.03416],
              [15.16362095400018, 2.034284887000183],
              [15.16314100000011, 2.037133001000143],
              [15.163408000000175, 2.040417001000037],
              [15.163286228, 2.040546287000097],
              [15.162208876000079, 2.041690012000117],
              [15.161169537000148, 2.041797628000154],
              [15.159610748000034, 2.041958570000134],
              [15.156279564000101, 2.041355609999982],
              [15.152749062, 2.040618658000142],
              [15.150816960000043, 2.039010560000122],
              [15.150640001000056, 2.037049],
              [15.150550001000056, 2.036061000000132],
              [15.150558077000028, 2.035747044000118],
              [15.15056599900015, 2.0354390010001],
              [15.150577594000083, 2.034986184000047],
              [15.150617000000182, 2.033446999000148],
              [15.150210793000042, 2.030702616000156],
              [15.149883270000032, 2.028487682000048],
              [15.149303807000138, 2.024568759999966],
              [15.149149999000031, 2.023528001000102],
              [15.149127861000125, 2.023387784000136],
              [15.149036408000143, 2.022806405000097],
              [15.148635192000143, 2.020251573999985],
              [15.14835, 2.018434001000173],
              [15.148220999000102, 2.016930000000173],
              [15.148016975000075, 2.014546930000165],
              [15.147575742000072, 2.013310733000083],
              [15.147543, 2.013219001000152],
              [15.147536989000173, 2.013202192000108],
              [15.14708300000018, 2.011933],
              [15.146164269000053, 2.010768490000146],
              [15.145285, 2.009654001000058],
              [15.143285898000045, 2.008380958000146],
              [15.140803001000052, 2.007382000000121],
              [15.139955999000108, 2.007041000000129],
              [15.136757999, 2.006237],
              [15.134819000000107, 2.006861000000129],
              [15.133791436000138, 2.007191710000143],
              [15.133426902, 2.007309079000038],
              [15.133010461000083, 2.007633791000046],
              [15.130762001000051, 2.009387001000164],
              [15.130185999000162, 2.009624001000077],
              [15.127830999000025, 2.010594001000186],
              [15.127007000000106, 2.010817],
              [15.126383041000111, 2.010986225000124],
              [15.124366759000054, 2.01153254500008],
              [15.121235847000037, 2.010728597000139],
              [15.119236946000115, 2.008382797000024],
              [15.116438999000138, 2.004964992000112],
              [15.116335065000044, 2.004303187000176],
              [15.116038999000068, 2.002418001000137],
              [15.115868079000165, 2.001803010000174],
              [15.115620999000157, 2.000914001000069],
              [15.115106999000091, 1.999066998999979],
              [15.114439999000126, 1.994508999000175],
              [15.114428857000064, 1.99436903000003],
              [15.114350999000067, 1.993391000000145],
              [15.114106000000106, 1.990286],
              [15.113505999000097, 1.986130999000125],
              [15.112973001000057, 1.983048],
              [15.111565, 1.982134],
              [15.110909000000106, 1.981708],
              [15.109902959000067, 1.981342346000133],
              [15.109382001000142, 1.981152999000187],
              [15.108510001000127, 1.980836000000124],
              [15.107149103000097, 1.980836443999976],
              [15.105446, 1.980837],
              [15.101782001000117, 1.981239],
              [15.101047086000165, 1.981250372000147],
              [15.097452, 1.981305999000028],
              [15.094521, 1.981105000000184],
              [15.090922999000156, 1.980635999000185],
              [15.08925799900004, 1.980837],
              [15.084861999000111, 1.982580001000088],
              [15.083192232000044, 1.982729773000074],
              [15.081863, 1.982849001000147],
              [15.079398999000034, 1.982648000000154],
              [15.0774, 1.982514001000141],
              [15.077063124000119, 1.982707574000074],
              [15.075068001000147, 1.983854],
              [15.074070001000166, 1.985731043000044],
              [15.07406454900007, 1.985949131000155],
              [15.074002999000129, 1.988412],
              [15.074397952000027, 1.98935244300003],
              [15.074671745000046, 1.990004658000089],
              [15.07526874600012, 1.991428137000185],
              [15.075612867000075, 1.992401264000137],
              [15.07599399899999, 1.99348],
              [15.076261033000094, 1.994234895000034],
              [15.076334944000052, 1.994444102000045],
              [15.076130493000107, 1.994804255000076],
              [15.07473659600015, 1.997259378000081],
              [15.072338104000096, 2.000342608000096],
              [15.07083416, 2.000577926000062],
              [15.069340706000105, 2.000811815000134],
              [15.066209794000031, 2.000074625000082],
              [15.065860484000098, 1.999762437000186],
              [15.065179, 1.999152998999989],
              [15.064505938000082, 1.998551709000026],
              [15.064410210000176, 1.998466135],
              [15.063542127, 1.997446965000052],
              [15.062412, 1.996120001000065],
              [15.060347000999968, 1.993171],
              [15.059484955000073, 1.992065459000116],
              [15.058414459000119, 1.990691543000139],
              [15.056615829000179, 1.988010645000031],
              [15.055923499000073, 1.986720693000052],
              [15.05481702100019, 1.984659040000111],
              [15.053351001000067, 1.981375000000128],
              [15.05181600100002, 1.979627000000107],
              [15.050820113000043, 1.978493126999979],
              [15.050551560000144, 1.978325256000062],
              [15.049687, 1.977785001000029],
              [15.048355000000129, 1.976952],
              [15.047664000000168, 1.977075000000184],
              [15.046315583000023, 1.977314014000058],
              [15.046090000000106, 1.977353999000059],
              [15.043292, 1.978359000000182],
              [15.040227995000123, 1.979968010000107],
              [15.038896000000136, 1.982179999000039],
              [15.0380602460001, 1.983580902000028],
              [15.036696999000185, 1.985866001000034],
              [15.036305037000091, 1.987999691000027],
              [15.036031722000132, 1.98948574100001],
              [15.035539269000139, 1.992060462000154],
              [15.035365000000183, 1.992970999000079],
              [15.035026629000185, 1.994227582000065],
              [15.034299851000071, 1.996925592000025],
              [15.03304386200017, 1.999738932000071],
              [15.032961488000126, 1.999780342000122],
              [15.032557, 1.999983],
              [15.029226983000115, 2.001830104000078],
              [15.029119491000074, 2.001889705000167],
              [15.027894974000105, 2.002568722000092],
              [15.026890035000179, 2.003094142000066],
              [15.025744000000145, 2.003693],
              [15.015808001000039, 2.008888000000184],
              [15.008195000000114, 2.012431001000152],
              [15.004006999000069, 2.013676],
              [15.003132376999986, 2.01429621900013],
              [15.001957893000167, 2.0151288510001],
              [15.000086755999973, 2.016455597000061],
              [14.99982097000003, 2.016644039000028],
              [14.997702, 2.019236999000157],
              [14.996299001000011, 2.020953000000134],
              [14.995973788000015, 2.022015642000099],
              [14.995529, 2.023469001000024],
              [14.99515700000012, 2.024687],
              [14.99496670799999, 2.024983387000077],
              [14.994652747999965, 2.025471688000096],
              [14.993412972000044, 2.027398825000091],
              [14.993062974000054, 2.027942658000086],
              [14.989993062000053, 2.031275274000109],
              [14.989447001000087, 2.031868000000145],
              [14.986402001000044, 2.03407],
              [14.983546000000104, 2.03531499900015],
              [14.980406001000063, 2.034165999000095],
              [14.979315769000095, 2.032685829000059],
              [14.97850227400005, 2.031580926000174],
              [14.977809189000141, 2.030535049000093],
              [14.976598999000032, 2.028707999000062],
              [14.976130419000185, 2.026928267000073],
              [14.975742, 2.025452999000038],
              [14.974898392999989, 2.022792709000044],
              [14.974315001000036, 2.020953000000134],
              [14.974315001000036, 2.018176],
              [14.973576907000108, 2.015205531000049],
              [14.973173000000145, 2.01358000099998],
              [14.971649999000022, 2.010132999000064],
              [14.971167449000063, 2.009917155000153],
              [14.968224698000029, 2.008600973000171],
              [14.964323044000139, 2.008313656000098],
              [14.960991859000103, 2.006781579000062],
              [14.957775944000048, 2.005126031000032],
              [14.956900000000189, 2.004674999000031],
              [14.953949999000088, 2.003717001000155],
              [14.951114017000123, 2.003296439000167],
              [14.948144000000127, 2.002856],
              [14.943528863000097, 2.003124501000173],
              [14.941578, 2.003238],
              [14.940077824000127, 2.003124906000096],
              [14.937770843000067, 2.002950670000075],
              [14.934345244999975, 2.001705884000103],
              [14.932604329000185, 2.001630750000118],
              [14.927682999000126, 2.001417999000182],
              [14.923210000000154, 2.001035001000105],
              [14.92070361500015, 2.001264377000098],
              [14.917976001000056, 2.001514001000146],
              [14.913979, 2.004003000000125],
              [14.911714862999986, 2.004966678000073],
              [14.911505000000147, 2.005056],
              [14.910910385000022, 2.005238758000019],
              [14.909439088000056, 2.005690813000058],
              [14.90844660800002, 2.005995789000053],
              [14.90807799900017, 2.006109],
              [14.906455999000059, 2.007741000000124],
              [14.906378275000066, 2.007819578000067],
              [14.906365394000147, 2.007832528000051],
              [14.906098366000094, 2.008011579000026],
              [14.903754574000118, 2.009583186000157],
              [14.902939000000117, 2.010129999000185],
              [14.899457999000163, 2.013023],
              [14.898561001000189, 2.013768],
              [14.897965906000081, 2.014151160000097],
              [14.897566796000092, 2.014407634000179],
              [14.896250941, 2.015253265000069],
              [14.895135000000153, 2.015970000000152],
              [14.894410120000032, 2.01696210200015],
              [14.892756000000134, 2.019226],
              [14.892305699000076, 2.020532642000148],
              [14.892227001000151, 2.020761],
              [14.891899, 2.021714999000039],
              [14.891919231000088, 2.021910823000042],
              [14.892054557999984, 2.023225070000024],
              [14.892137370000114, 2.024034011000083],
              [14.892184, 2.024492001000112],
              [14.892797001000076, 2.025237999000183],
              [14.894468000000188, 2.027269001000036],
              [14.896888758000102, 2.028458383000043],
              [14.898560523000128, 2.029279948000067],
              [14.899566014000129, 2.029873028000111],
              [14.901320000000112, 2.030907999000021],
              [14.90338400100012, 2.032583000000159],
              [14.90427000000011, 2.033302001000038],
              [14.904861246000053, 2.033916216000137],
              [14.904965401000027, 2.034024478000049],
              [14.905287439, 2.034359276000032],
              [14.907125001, 2.03627],
              [14.909078431000182, 2.038235705000034],
              [14.909193994000077, 2.038352013000065],
              [14.909227728000189, 2.038385958000163],
              [14.910075001000052, 2.039239001000112],
              [14.91025906200008, 2.039597050000168],
              [14.910476684000116, 2.040021182000089],
              [14.911223384000095, 2.041476482000064],
              [14.911598, 2.042207],
              [14.911540550000041, 2.043017357999986],
              [14.911503000000152, 2.043546999000057],
              [14.911879157000101, 2.04535658899999],
              [14.912359, 2.047665000000166],
              [14.913557805999972, 2.050006303000146],
              [14.913977, 2.050825001000021],
              [14.915017952000028, 2.052368582000099],
              [14.91578481800002, 2.05350573000004],
              [14.915598869, 2.054252149000092],
              [14.915508032000048, 2.054617498000027],
              [14.914926999000045, 2.056952],
              [14.914216, 2.060019001000114],
              [14.913595, 2.062696999000025],
              [14.912643134000177, 2.064037810000173],
              [14.911753482999984, 2.064067599000055],
              [14.909788000000162, 2.064133],
              [14.908664639000051, 2.063805131000038],
              [14.906838000000164, 2.063271999000165],
              [14.902745999000047, 2.063175999],
              [14.899083000000132, 2.063039000000174],
              [14.897607, 2.062984],
              [14.895853770000087, 2.063390608000134],
              [14.895132, 2.063558000000114],
              [14.89504920000013, 2.063673920000099],
              [14.894927000000166, 2.063844999000139],
              [14.89446490600011, 2.064491350000083],
              [14.893815998999969, 2.065399000000184],
              [14.892942062000088, 2.066621914000109],
              [14.890923372000032, 2.067354338000143],
              [14.887137, 2.068727999000032],
              [14.886713000000157, 2.068953],
              [14.881902890999982, 2.071504154000138],
              [14.881755931999976, 2.071712351000031],
              [14.879808000000139, 2.074472],
              [14.879808000000139, 2.076196],
              [14.879319545000101, 2.079425968000123],
              [14.879142, 2.080600000000118],
              [14.879113615000108, 2.081760779000149],
              [14.879046001000063, 2.084525999000107],
              [14.878570816000092, 2.08671213800011],
              [14.878379999000174, 2.08759],
              [14.878094, 2.093622],
              [14.87837899900012, 2.097643999000184],
              [14.878462227000057, 2.098860209000065],
              [14.878759, 2.10319699900009],
              [14.877711999000155, 2.106931],
              [14.877247929000134, 2.107533791000094],
              [14.876878739000119, 2.108012915000131],
              [14.874761581000087, 2.110760927000115],
              [14.87349692399999, 2.111117135000086],
              [14.872382001000062, 2.111430999000106],
              [14.869051, 2.110952001000101],
              [14.867627354000035, 2.110524775000101],
              [14.863626480999983, 2.109323740000093],
              [14.859438999000076, 2.106833995999978],
              [14.858964001000061, 2.103387001000158],
              [14.859441486000037, 2.10198965699999],
              [14.860011101, 2.100322962000064],
              [14.861241135000171, 2.098123330000135],
              [14.863438000000144, 2.094195000000184],
              [14.863988194000171, 2.093183881000073],
              [14.865626001000066, 2.090174001000094],
              [14.86657898600015, 2.086344059999988],
              [14.864676, 2.083566999000027],
              [14.86256722600001, 2.081357632000049],
              [14.86239120800002, 2.081173218000117],
              [14.859440999000071, 2.081938999000101],
              [14.857196253000154, 2.082917882],
              [14.856586456000059, 2.083183765000115],
              [14.85192203500003, 2.084619522000139],
              [14.849105531000077, 2.085060031000069],
              [14.84764, 2.085289001000035],
              [14.846177741000133, 2.085324975000162],
              [14.84373760200009, 2.085384608000027],
              [14.839645384999983, 2.083469153000067],
              [14.838783119000084, 2.082267665000131],
              [14.837172, 2.080022],
              [14.834697000000119, 2.075617],
              [14.831557, 2.070445999000071],
              [14.830262351999977, 2.069143310000186],
              [14.829463999000097, 2.068340001000081],
              [14.825180999000111, 2.06585],
              [14.822135999000068, 2.064701000000184],
              [14.818329000000119, 2.063647000000174],
              [14.815949999000168, 2.062305999000159],
              [14.812903999000014, 2.060774001000084],
              [14.809098000000176, 2.059816000000126],
              [14.808461790000081, 2.059886895000034],
              [14.807384, 2.060007],
              [14.805267874000151, 2.062633070000118],
              [14.80452918900005, 2.063549519000048],
              [14.80369108300016, 2.06450820900011],
              [14.801686999000083, 2.066799999000011],
              [14.799675001000026, 2.069102000000157],
              [14.795867999, 2.073889001],
              [14.794810722000022, 2.074060614000075],
              [14.792917252, 2.074367524000081],
              [14.790537834000133, 2.073409796000078],
              [14.790120387000172, 2.073114144000101],
              [14.787968999000157, 2.071589999000025],
              [14.785209000000123, 2.069482999000172],
              [14.783211001000097, 2.068621000000178],
              [14.781065195, 2.066527799000085],
              [14.78007000100007, 2.065556999000023],
              [14.776358999000024, 2.063642000000129],
              [14.775251142000059, 2.063410795000095],
              [14.773599000000161, 2.063066001000152],
              [14.773546127000145, 2.063075240000103],
              [14.773496001000069, 2.063083999000071],
              [14.771586, 2.063404000000162],
              [14.770172999000124, 2.063641001000121],
              [14.766747001000169, 2.067087],
              [14.766018732000191, 2.067731005000155],
              [14.765658001000133, 2.068050000000142],
              [14.764366, 2.069193],
              [14.764366, 2.071666],
              [14.764366, 2.072449000000177],
              [14.764412328000162, 2.072500918000173],
              [14.764482001000147, 2.072579],
              [14.765888, 2.074172],
              [14.766126880000115, 2.074325967000107],
              [14.767078001000186, 2.07493899900004],
              [14.768268000000148, 2.075705],
              [14.768978372000106, 2.076269211000124],
              [14.770076000000188, 2.077140999000164],
              [14.77061129700013, 2.077525996000134],
              [14.771407999000132, 2.078099000000123],
              [14.772228999000049, 2.079447],
              [14.773215, 2.081067001000122],
              [14.773664243000155, 2.082310093000103],
              [14.774356843000078, 2.084227323000107],
              [14.774394734000111, 2.084780651000074],
              [14.774470000000122, 2.085884999000143],
              [14.774505923000163, 2.086400372000071],
              [14.774547577000021, 2.087003946000095],
              [14.773785591000035, 2.090355158000079],
              [14.772654702000182, 2.091693132000103],
              [14.772166999000092, 2.09227],
              [14.772166999000092, 2.094855],
              [14.770928999000148, 2.096769999000117],
              [14.770027926000125, 2.097409804000051],
              [14.767693766000036, 2.099067036000065],
              [14.764647, 2.099544999000045],
              [14.761697, 2.099449],
              [14.759343615999967, 2.098125722000134],
              [14.758462, 2.097630000000152],
              [14.756563359999973, 2.096734287],
              [14.755416, 2.096193],
              [14.753030057000103, 2.094632044000093],
              [14.751609802000132, 2.093702793000034],
              [14.750828503000037, 2.09304402000015],
              [14.748089000000107, 2.090734000000168],
              [14.746002493000049, 2.089764943000148],
              [14.744377014000122, 2.089010008000116],
              [14.744229367000059, 2.089028005000102],
              [14.743434000999969, 2.089125],
              [14.741237, 2.089393001000133],
              [14.739748701000053, 2.090406245000111],
              [14.738360999000179, 2.091351001000135],
              [14.738308346000167, 2.091387505000114],
              [14.73828600000013, 2.091402998999968],
              [14.738189354000099, 2.091652655000019],
              [14.737619000000166, 2.093125999000108],
              [14.737794001000168, 2.093976001000044],
              [14.737812092000127, 2.09406381600013],
              [14.738191, 2.095903001000181],
              [14.738954000000149, 2.096466000000135],
              [14.739617000000123, 2.096955999000102],
              [14.742281968000157, 2.098392982000064],
              [14.742443818000027, 2.098393],
              [14.743357000000117, 2.098393],
              [14.744376, 2.098393],
              [14.745052477000172, 2.098193355000149],
              [14.745135000000118, 2.098169001000088],
              [14.745994001000042, 2.097914999000182],
              [14.746891571000106, 2.097534916000086],
              [14.747306823000145, 2.097359181000059],
              [14.747802734000061, 2.097149134000176],
              [14.748740195000096, 2.09714937200016],
              [14.749211715, 2.097149487000081],
              [14.74970600100005, 2.09715],
              [14.751704, 2.098299000000111],
              [14.752750001000038, 2.100213999000175],
              [14.753225999000165, 2.102894999000114],
              [14.753285999000184, 2.105029],
              [14.753304698000022, 2.105679107000071],
              [14.753320695000184, 2.10624623300015],
              [14.753020726000159, 2.1070175320001],
              [14.752464, 2.108448001000056],
              [14.750435450000111, 2.109311404000152],
              [14.749989509000102, 2.109501123000143],
              [14.746838694000076, 2.110509297000078],
              [14.745801970000059, 2.110841016000052],
              [14.741708999000082, 2.112947001000123],
              [14.736379001000159, 2.116776001000062],
              [14.733965001000058, 2.118274999000107],
              [14.733322555000029, 2.11867389300005],
              [14.733141999, 2.118785999000124],
              [14.733121191000066, 2.118803770000056],
              [14.732593536000024, 2.119254112000022],
              [14.731903047000174, 2.119843427000035],
              [14.730001, 2.121466],
              [14.728031749000138, 2.124132246000045],
              [14.727525711, 2.124817133000136],
              [14.72717332100018, 2.125005380000175],
              [14.72448, 2.126443999000117],
              [14.722718820000125, 2.125971472000174],
              [14.72162532700014, 2.125677823000103],
              [14.72078966800018, 2.124836813000172],
              [14.719913001, 2.123954001000129],
              [14.71984176400008, 2.122248770000169],
              [14.719816999000102, 2.121656001000076],
              [14.719274439000117, 2.120811554999989],
              [14.718771000000118, 2.120028001000037],
              [14.718713495000088, 2.119724476000044],
              [14.718390465000027, 2.118017434000024],
              [14.720293999000091, 2.11552834500003],
              [14.722178661999976, 2.113633011000047],
              [14.722484, 2.11332599900004],
              [14.724673001000042, 2.110742001000119],
              [14.726768000000106, 2.108253000000161],
              [14.726767770000095, 2.105572],
              [14.723901947000058, 2.105571059000056],
              [14.72372200000018, 2.105571000000111],
              [14.720962822000104, 2.105666078000184],
              [14.720588169000109, 2.105829834000019],
              [14.716583999000079, 2.10758],
              [14.71192000100001, 2.110356],
              [14.705352937000043, 2.110738017000188],
              [14.702402, 2.111504],
              [14.701702, 2.111694000000114],
              [14.700631856000143, 2.11198458199999],
              [14.698881, 2.112460001000159],
              [14.69866757300008, 2.113288387000125],
              [14.698215000000175, 2.11504499900002],
              [14.698639860000185, 2.116521850000083],
              [14.698674999000161, 2.116643999000132],
              [14.699069932000043, 2.118013763000135],
              [14.698850307999976, 2.118696917000023],
              [14.698425000000157, 2.120019],
              [14.698169570000175, 2.120813246000068],
              [14.69802284299999, 2.121268989000043],
              [14.696213721000049, 2.12347078300013],
              [14.693167686000038, 2.126246930000036],
              [14.691906320000101, 2.127548782000133],
              [14.689550000000168, 2.129980001000092],
              [14.686459832000139, 2.133728117999965],
              [14.686313952000148, 2.133905020000043],
              [14.683172999000021, 2.135245],
              [14.678795, 2.135244000000171],
              [14.67611518800004, 2.134434740000188],
              [14.671181678000039, 2.132944583999972],
              [14.668990719000021, 2.132113031000188],
              [14.665377, 2.130741000000114],
              [14.663174010999967, 2.130480090000162],
              [14.660522, 2.130166001000021],
              [14.652908000000139, 2.129877],
              [14.652416060000064, 2.130041779000067],
              [14.649830001000055, 2.130907999000158],
              [14.648054000000116, 2.131503],
              [14.646088893000183, 2.133850303000031],
              [14.645693779000169, 2.134322166000118],
              [14.645493191000071, 2.134561742000017],
              [14.645008000000189, 2.135141001000079],
              [14.644385509000074, 2.136110204999966],
              [14.644147874000055, 2.136480094000149],
              [14.643934023000043, 2.136813033000067],
              [14.642056, 2.139736],
              [14.641769999000019, 2.142321],
              [14.642583999000067, 2.143707001000053],
              [14.643006999000079, 2.144428001000051],
              [14.647099, 2.146344],
              [14.647429405000025, 2.147141997000063],
              [14.647440999000139, 2.147170001000063],
              [14.647495365000168, 2.147301403000142],
              [14.648050001000058, 2.148641999000176],
              [14.647728197999982, 2.149936512000124],
              [14.647344589000113, 2.151477814000089],
              [14.647097587000133, 2.152472019000186],
              [14.64509868600004, 2.155631067000115],
              [14.641386032000128, 2.158406974000172],
              [14.637007714000106, 2.158501626000032],
              [14.634158684000056, 2.158292451000023],
              [14.631773, 2.158117001000051],
              [14.629495361000124, 2.158562424000081],
              [14.628347119000182, 2.158786977000091],
              [14.62596699900007, 2.161467001000119],
              [14.625823954000055, 2.161663318000137],
              [14.625438, 2.162193001000162],
              [14.622825, 2.1657750010001],
              [14.621878238000079, 2.167431832000091],
              [14.621333001000153, 2.168386],
              [14.620735654000043, 2.169432187000155],
              [14.620255, 2.170274001000166],
              [14.619031999000015, 2.174310001000151],
              [14.61825386300012, 2.176880152000081],
              [14.618164895000064, 2.176978191000103],
              [14.616978998999969, 2.178284999000084],
              [14.616254999000091, 2.179082],
              [14.613970001000041, 2.181667001000108],
              [14.613535, 2.182733000000155],
              [14.612563686000158, 2.185111782000149],
              [14.612445988000161, 2.18540006600017],
              [14.612157999000033, 2.186975001000178],
              [14.612063043000035, 2.187494262000087],
              [14.611588001000143, 2.190092000000107],
              [14.610926433000145, 2.191627991000075],
              [14.610350607999976, 2.192964076000067],
              [14.607110023000189, 2.196307660000116],
              [14.606981784000141, 2.196439946000055],
              [14.606732001000182, 2.196697],
              [14.606582376000119, 2.196804728000188],
              [14.60653209600008, 2.196840763000068],
              [14.597784042000114, 2.203109742000152],
              [14.595618247000061, 2.20345783300013],
              [14.595409263000022, 2.203563830000064],
              [14.595404625000128, 2.203477859000145],
              [14.593787194000186, 2.202711583000166],
              [14.593442918000108, 2.200889825000104],
              [14.593303681000066, 2.200153113000056],
              [14.593026161000182, 2.198689937000154],
              [14.591409683, 2.19332766600013],
              [14.587032318000126, 2.189688205000152],
              [14.583702087000177, 2.186336041000061],
              [14.58254528000009, 2.179340125000181],
              [14.582277297000076, 2.177718401999982],
              [14.581365585000128, 2.176466943000094],
              [14.580183982000108, 2.17484545700006],
              [14.577518464000036, 2.176185132000171],
              [14.573901177000153, 2.178482055000018],
              [14.57190227600006, 2.179055929000072],
              [14.569237709000106, 2.178672314000039],
              [14.565378190000047, 2.177731515000119],
              [14.563337327000056, 2.177234174000091],
              [14.562850952000019, 2.176942348000068],
              [14.558388710000145, 2.174264670000071],
              [14.554391861000113, 2.172923087000186],
              [14.547445297000024, 2.17024016400012],
              [14.543923377999988, 2.169951915000183],
              [14.54040241199999, 2.170238019000067],
              [14.540331841000125, 2.17017960600009],
              [14.537737845000095, 2.168035031000045],
              [14.535197258000096, 2.166755915000067],
              [14.534692764999988, 2.16650199899999],
              [14.532407760000012, 2.168607712000039],
              [14.529648781000105, 2.166117668000084],
              [14.529961586000127, 2.164231777000111],
              [14.530220985000028, 2.16267085200019],
              [14.527079583000159, 2.163914680000062],
              [14.523463249000088, 2.164583683999979],
              [14.520227432000127, 2.164965629000051],
              [14.518325806000178, 2.164344550000124],
              [14.516705512000044, 2.16381549800002],
              [14.510995865000041, 2.163239241000042],
              [14.509052276000091, 2.163320065000164],
              [14.504142761000026, 2.163524152000093],
              [14.497766495000064, 2.162851810000063],
              [14.493960381000079, 2.160648347000063],
              [14.490715981000051, 2.159219265000161],
              [14.487870216000033, 2.157965661000162],
              [14.483397483999966, 2.156144858000062],
              [14.482503892000125, 2.155166625000049],
              [14.481209754000133, 2.153750419000119],
              [14.481019974999981, 2.152026893000141],
              [14.481401443000095, 2.149346113000036],
              [14.481973647000018, 2.145612478000089],
              [14.480356216000075, 2.143792630000121],
              [14.479930879000108, 2.143825293000077],
              [14.47788143299999, 2.14398312600008],
              [14.473883628000067, 2.145992518000071],
              [14.471161843, 2.147165060000134],
              [14.469220162000056, 2.148001432000058],
              [14.46768856000017, 2.148000957000079],
              [14.465888976000087, 2.148000479000075],
              [14.465086936000034, 2.147246839000047],
              [14.464461326000105, 2.146659374000024],
              [14.46351051400012, 2.143882514000097],
              [14.464082719000146, 2.141010284000174],
              [14.46469783800012, 2.138813734000109],
              [14.465798378999978, 2.134883165000076],
              [14.466095925000104, 2.132254839000041],
              [14.46627521400012, 2.130670547000023],
              [14.464943886000071, 2.127797842000121],
              [14.462755204000189, 2.126073599000165],
              [14.458949088, 2.125210524000067],
              [14.454476356000157, 2.125400543000069],
              [14.449431420000167, 2.127505064000047],
              [14.44524288100007, 2.130280256000162],
              [14.444604874000163, 2.131348608999986],
              [14.443528175000154, 2.133152008000081],
              [14.443331719000071, 2.135452031000057],
              [14.443242071999975, 2.136502743000165],
              [14.444229126000153, 2.138926506000018],
              [14.444763183000191, 2.140237570000181],
              [14.446855544000186, 2.143301964000045],
              [14.446855544000186, 2.144557715000133],
              [14.446855544000186, 2.145504237000125],
              [14.445856095000181, 2.148229122000032],
              [14.445521354000107, 2.149142028000085],
              [14.44475841600007, 2.15287590000014],
              [14.443979264000063, 2.154731990000016],
              [14.442949296000108, 2.157183647000124],
              [14.440651893, 2.159400701000038],
              [14.440568924000161, 2.159480810000048],
              [14.436664581000116, 2.164553641999987],
              [14.433999062000112, 2.166850567000097],
              [14.430287359999966, 2.166849137000156],
              [14.427241325000125, 2.167135238000071],
              [14.424481393000178, 2.168666125000186],
              [14.423528671000042, 2.171250821000172],
              [14.421718598000098, 2.174696923],
              [14.418672561000051, 2.175844669000128],
              [14.415816307000171, 2.178811788000076],
              [14.412199020000116, 2.181299687000092],
              [14.410105704999978, 2.181011675999969],
              [14.405728339, 2.178807734999964],
              [14.404065131000095, 2.178498746000059],
              [14.402112007000142, 2.178136111000185],
              [14.398209572000098, 2.178900480000095],
              [14.396446227000126, 2.179919482000059],
              [14.394401551000044, 2.181101083000044],
              [14.392900468000107, 2.181799173000059],
              [14.388024330000064, 2.184066534000181],
              [14.383455276, 2.185596466000106],
              [14.379050254000049, 2.184117556000103],
              [14.37781238600013, 2.183701992000181],
              [14.377174376000085, 2.183487654000032],
              [14.37642955900003, 2.183277370000042],
              [14.372416496000085, 2.182145119000097],
              [14.369370461000187, 2.183005572000127],
              [14.366990089000069, 2.185781240000097],
              [14.362896920000026, 2.187694312000133],
              [14.358899117000135, 2.187213897000049],
              [14.355854987000157, 2.18539357200018],
              [14.354713439000022, 2.183190823000075],
              [14.350623130000031, 2.178784847000031],
              [14.346151352000049, 2.175623416],
              [14.342630387000156, 2.174185754000121],
              [14.338633538000124, 2.173513890000095],
              [14.335173608000048, 2.173408270000095],
              [14.33225727100006, 2.173319339999978],
              [14.32873630600011, 2.172743321000041],
              [14.324645041999986, 2.170826674000182],
              [14.317032812999969, 2.167185068000151],
              [14.312950134000118, 2.165429116000155],
              [14.308564186000126, 2.163542985000163],
              [14.308059693000075, 2.163439275000087],
              [14.303901673000098, 2.16258335200007],
              [14.3036556240001, 2.162682295000138],
              [14.301997186000165, 2.163348436000092],
              [14.300434113000051, 2.165705444000082],
              [14.299712181000018, 2.166794062000122],
              [14.298792838000054, 2.167218925000157],
              [14.294952393000187, 2.168993950000072],
              [14.293600082000125, 2.170126438000125],
              [14.291524888000026, 2.171864748000076],
              [14.288743020000084, 2.171936988000027],
              [14.287908553000022, 2.171958685000107],
              [14.266205789000082, 2.172303200000101],
              [14.252790450000191, 2.172516108000139],
              [14.204634666000118, 2.172587155999963],
              [14.175990104000107, 2.171135663000086],
              [14.166567802000145, 2.171513319000042],
              [14.158002853000141, 2.172370196000145],
              [14.11241722, 2.1723439700001],
              [14.020202636000135, 2.172286510000163],
              [14.002978323999969, 2.171892404000062],
              [13.987941742000032, 2.172648192000054],
              [13.927894593000076, 2.172798156000056],
              [13.891162872000109, 2.172005414000182],
              [13.868771553000101, 2.172010422000028],
              [13.855558396000106, 2.172013522000157],
              [13.794958114000167, 2.172027109999988],
              [13.693237305000025, 2.171846389000052],
              [13.680087040000046, 2.171692134000182],
              [13.645108222000147, 2.171281338000142],
              [13.631484986000032, 2.171121360000029],
              [13.629652977999967, 2.171132326000077],
              [13.576487542, 2.17145323800014],
              [13.49691391000016, 2.171301126000174],
              [13.495936222000068, 2.171299266000176],
              [13.494658470000047, 2.171296836000181],
              [13.487190648000137, 2.171282567000162],
              [13.473444938000057, 2.171256304000167],
              [13.422501078000039, 2.171626975000038],
              [13.40437126099999, 2.171758890000035],
              [13.355565072000047, 2.171227693000049],
              [13.299013137000145, 2.170983553999974],
              [13.295819282000082, 2.17096972500002],
              [13.29315498200009, 2.1713498740001],
              [13.293986117000031, 2.17244559400018],
              [13.29438972500003, 2.172977687000071],
              [13.295624733000068, 2.174606323000148],
              [13.296325683000134, 2.175683976000073],
              [13.297618866000107, 2.177671194000027],
              [13.297549873000037, 2.178390930000035],
              [13.297426223000116, 2.179680824000172],
              [13.296111106000069, 2.181331158000148],
              [13.295902251000143, 2.181593418000148],
              [13.292936596000118, 2.183402171000182],
              [13.291047096000113, 2.184554577000142],
              [13.289757284000189, 2.18459641100003],
              [13.288680077000095, 2.184631348000153],
              [13.28819275, 2.184647084000062],
              [13.28763294099997, 2.184734106000121],
              [13.284125262000089, 2.185278726000092],
              [13.283910999000057, 2.185311999000078],
              [13.283831, 2.186340999000151],
              [13.283749120000095, 2.187398188000145],
              [13.283718110000166, 2.187800407000111],
              [13.284190177000085, 2.191054822000183],
              [13.285138129000131, 2.193927049000024],
              [13.287419319000094, 2.19555664000012],
              [13.291033877000189, 2.196613919000129],
              [13.293769999000062, 2.200136000000157],
              [13.295498001000055, 2.202361001000099],
              [13.299108999000055, 2.206672001000129],
              [13.300959272000114, 2.207150745000149],
              [13.303197701000158, 2.20772972900005],
              [13.30101022499997, 2.209806649000143],
              [13.299674033000088, 2.211075307000101],
              [13.295292855000127, 2.215281487000141],
              [13.294560433000129, 2.217118025000048],
              [13.294148444000029, 2.218151331000115],
              [13.294562341000074, 2.219967604000033],
              [13.295000076000179, 2.221884966000062],
              [13.295005777000029, 2.222107755000081],
              [13.295028687000126, 2.223002911000094],
              [13.29509162800008, 2.225521804000039],
              [13.291855812000051, 2.226283790000025],
              [13.289901733000136, 2.228375196000059],
              [13.28899765, 2.229343176000043],
              [13.286995889000025, 2.232881784000085],
              [13.284608842000125, 2.240439891000051],
              [13.284531829000059, 2.241543860000036],
              [13.284415245000048, 2.243215084000099],
              [13.284029007000072, 2.248191357000167],
              [13.282460125000114, 2.246513564000168],
              [13.280892999000116, 2.244837998999969],
              [13.278399000999968, 2.242800001000092],
              [13.277154744000143, 2.241783246000125],
              [13.275380135000148, 2.240333318000012],
              [13.271671296000136, 2.238893271000165],
              [13.270467097000051, 2.240343371000051],
              [13.269765854000013, 2.241187811000145],
              [13.268906594000157, 2.243962288000091],
              [13.270614624000075, 2.248079776000054],
              [13.266714096000101, 2.247596503000125],
              [13.264809609, 2.248742820000018],
              [13.263665709000065, 2.249583274000145],
              [13.261765479000019, 2.250979425000082],
              [13.259505501000092, 2.252639786000088],
              [13.259478, 2.252660001000038],
              [13.259438000000102, 2.253308000000118],
              [13.259309735000045, 2.255385049000097],
              [13.259283085000163, 2.25581805000013],
              [13.260312947000102, 2.256429342999979],
              [13.261832237000078, 2.257330657000125],
              [13.262320543000158, 2.257620269000142],
              [13.262515977000135, 2.257736181000155],
              [13.261276246000136, 2.260510206000049],
              [13.259909630000038, 2.261523486000044],
              [13.259719459000053, 2.261664517999975],
              [13.259085656000082, 2.262134551999964],
              [13.255373955000039, 2.262895823000179],
              [13.254826525, 2.26392820600006],
              [13.253882407000049, 2.26570868500005],
              [13.253482017000181, 2.266464076000091],
              [13.252990722999982, 2.26739096600005],
              [13.250482560000023, 2.269225837000079],
              [13.250118309000186, 2.269662461000166],
              [13.250046042000179, 2.269749090000119],
              [13.249765397000147, 2.269785404000118],
              [13.249748231000126, 2.269763232000059],
              [13.248851776000095, 2.270419360000176],
              [13.24848079700007, 2.270690441000113],
              [13.24841976099998, 2.270735025000079],
              [13.241860389000124, 2.266420366000034],
              [13.235201837000034, 2.265072345000135],
              [13.233822822000093, 2.262124300000039],
              [13.233589171000176, 2.261625051000181],
              [13.231308937000165, 2.259325504000117],
              [13.229869842000028, 2.259257793000074],
              [13.227122306000069, 2.25912880900006],
              [13.224555015000021, 2.257977248000088],
              [13.222764969000025, 2.255720377000159],
              [13.222275734000164, 2.255103350000184],
              [13.220562936000022, 2.255292654000186],
              [13.219689370000026, 2.256043912000166],
              [13.21589660699999, 2.259306430000095],
              [13.21285152400003, 2.259972573000084],
              [13.210000038000032, 2.25757646600016],
              [13.206956863000187, 2.256807090000109],
              [13.203755378000039, 2.257965327000079],
              [13.203530312000112, 2.258046865999972],
              [13.202900886000066, 2.259413242000051],
              [13.201812744999984, 2.261777162999977],
              [13.202663422000114, 2.265906812000026],
              [13.202758789000143, 2.266372204000163],
              [13.200382233000141, 2.264646530000164],
              [13.199397087000136, 2.263700246000042],
              [13.198387147000062, 2.262729883000134],
              [13.195540428000186, 2.260916473000179],
              [13.194774627000129, 2.260428667000156],
              [13.191935539000042, 2.260771274000149],
              [13.191634178000072, 2.260807515000067],
              [13.190394401999981, 2.263581275000036],
              [13.190332412000032, 2.264137984],
              [13.189913750000187, 2.267887354000095],
              [13.186202048000041, 2.268743992000111],
              [13.185806275000061, 2.269292355000061],
              [13.184200287000067, 2.271516800000029],
              [13.184863090000079, 2.274292947000106],
              [13.188475610000069, 2.27640295100008],
              [13.192661286000146, 2.276599645000033],
              [13.192276955000068, 2.279470205000052],
              [13.18761539400009, 2.278892278000058],
              [13.184477805000085, 2.27850318000003],
              [13.181911469000056, 2.276585817000125],
              [13.178203583000027, 2.274667262000094],
              [13.176231384000175, 2.275575637000031],
              [13.174680711000065, 2.27628970100011],
              [13.1722641, 2.277832508000131],
              [13.171539306000113, 2.278295280000066],
              [13.166019440000071, 2.279723882000155],
              [13.160308837000173, 2.281343460000016],
              [13.155840873000159, 2.278849603000026],
              [13.149560929000131, 2.279415607000146],
              [13.14737796899999, 2.278709649000177],
              [13.147184372000083, 2.278646946000038],
              [13.140527725000027, 2.276437283000064],
              [13.13811016000011, 2.275346994000188],
              [13.131589889, 2.272406339000156],
              [13.124078750000024, 2.268951417000096],
              [13.118069648000187, 2.267590761000179],
              [13.11685085300013, 2.267314910000152],
              [13.117545128000131, 2.266077280000047],
              [13.118567466000172, 2.264255047],
              [13.117905618000123, 2.261000394000121],
              [13.117376328000034, 2.260280610000052],
              [13.11572170300019, 2.258030892000079],
              [13.115730286000087, 2.258003234000114],
              [13.116306305000023, 2.25605368700019],
              [13.116485596000018, 2.255448104000095],
              [13.117727280000111, 2.251334666999981],
              [13.114876746000164, 2.248842717000059],
              [13.111451148000071, 2.249508143000014],
              [13.109164238000176, 2.252376318000131],
              [13.107695579000108, 2.251588821000041],
              [13.106122971000161, 2.250745296000162],
              [13.10165119100003, 2.250835180000138],
              [13.101599694000129, 2.250189065000086],
              [13.101370811000095, 2.247293949000152],
              [13.099471092000044, 2.244899272000168],
              [13.09671402000015, 2.243555784000023],
              [13.095617294000022, 2.242579699000032],
              [13.093482971000071, 2.240680695000094],
              [13.089202880000073, 2.237707615000147],
              [13.087685584000099, 2.236653806000049],
              [13.085878372000138, 2.236651421000147],
              [13.082449914000165, 2.239709139000183],
              [13.080201149000175, 2.241212845000064],
              [13.075593948000062, 2.244293451000033],
              [13.074348449000126, 2.248036147000107],
              [13.071674347000055, 2.245602370000086],
              [13.068650245000129, 2.243969679000088],
              [13.065341950000061, 2.244107008000185],
              [13.064686774, 2.24282670000008],
              [13.064218521999976, 2.241910457000074],
              [13.061476708000157, 2.239145280000173],
              [13.058100700000068, 2.237299919000122],
              [13.054088592000085, 2.237152814000126],
              [13.050359725000021, 2.235873462000143],
              [13.046488761000035, 2.235655784000073],
              [13.042899131000013, 2.23621726000016],
              [13.041383742000164, 2.23577976200005],
              [13.04043579000006, 2.235506057000066],
              [13.0362854010001, 2.23380112699999],
              [13.034494399000039, 2.235229492000144],
              [13.033537864000039, 2.235992432000046],
              [13.033602714999972, 2.240099192],
              [13.033350945000109, 2.24105572700006],
              [13.032541275000085, 2.244133711000131],
              [13.030363083000168, 2.241369247000023],
              [13.027054786000122, 2.241364717000181],
              [13.023533821000171, 2.242776155000115],
              [13.021930695000037, 2.243948938000074],
              [13.020152092000046, 2.245249749000152],
              [13.019021034000104, 2.249142409000172],
              [13.017123223, 2.247157336000157],
              [13.014942168000118, 2.246375561000036],
              [13.010226250000187, 2.246015072000148],
              [13.004948617000025, 2.24508738600008],
              [13.00170898500005, 2.246569873000112],
              [12.997978211000088, 2.247414350000099],
              [12.995656967000059, 2.246065855000154],
              [12.995205878000149, 2.24628210200018],
              [12.994036674000029, 2.24684238399999],
              [12.991634368, 2.24852919500006],
              [12.9912185660001, 2.248821021000026],
              [12.98928260800011, 2.247223139000141],
              [12.98735237000011, 2.245629549000114],
              [12.986657143000059, 2.245354891000147],
              [12.98573398700006, 2.244990111000163],
              [12.985135079000088, 2.247267961000148],
              [12.98502635900013, 2.24767971000017],
              [12.983212471000172, 2.246421335999969],
              [12.982776641000157, 2.246118783999975],
              [12.982502938000152, 2.246108771000138],
              [12.978764533, 2.245971680000082],
              [12.975598335000029, 2.2454009060001],
              [12.974403381000116, 2.244266272],
              [12.971061707000047, 2.24194812900015],
              [12.971028328000045, 2.241925001000027],
              [12.969195367000168, 2.241267920000098],
              [12.968847275000144, 2.241143226000077],
              [12.967578888000105, 2.24248671600003],
              [12.965113640000027, 2.243545294000057],
              [12.963126183000156, 2.243742228000031],
              [12.962544440000102, 2.243799926000179],
              [12.959482194000032, 2.244103670000072],
              [12.956316948000165, 2.242754222000144],
              [12.951670646000082, 2.243526458000076],
              [12.950261117000082, 2.242927075000125],
              [12.948997498000097, 2.242389680000031],
              [12.946816442999989, 2.241961957000058],
              [12.946665764000045, 2.24288988100011],
              [12.946528435000175, 2.243732930000135],
              [12.946390151000116, 2.244580985000027],
              [12.943997384000113, 2.244294404000016],
              [12.941183089000049, 2.24386572899999],
              [12.937940597000136, 2.247684240000012],
              [12.935530662000133, 2.25058436400019],
              [12.934414864000189, 2.251927377000186],
              [12.932090758000072, 2.253056765],
              [12.930256845000144, 2.252612829000043],
              [12.928853989000061, 2.252273321000018],
              [12.923577309000109, 2.25099158300003],
              [12.919606208000062, 2.252476455000135],
              [12.918295859000125, 2.252966405000166],
              [12.91639804800019, 2.251264333000052],
              [12.917808534000187, 2.249425412000051],
              [12.919499398000141, 2.248082639000018],
              [12.920721053000022, 2.245891809000113],
              [12.920840263000116, 2.245677470999965],
              [12.92001152000006, 2.244482995000112],
              [12.919857025000113, 2.244260072000031],
              [12.914370538000128, 2.241915703000132],
              [12.911064148000094, 2.241061450000075],
              [12.909166336000112, 2.239288807000037],
              [12.903888702000074, 2.238148451000029],
              [12.900654793000115, 2.236232043000086],
              [12.898897170000055, 2.235461950000058],
              [12.896926880000137, 2.234598399000106],
              [12.895458222000116, 2.235179662000121],
              [12.894095420000156, 2.235719205000066],
              [12.89389801100009, 2.235797406000074],
              [12.891447066000069, 2.236204862000022],
              [12.891364097, 2.236218691000033],
              [12.889325142000132, 2.234658242000137],
              [12.88743114500005, 2.236320735000106],
              [12.887070656000162, 2.236637115000178],
              [12.886916161000045, 2.237370967000175],
              [12.886503220000066, 2.239326716000051],
              [12.886675835000062, 2.242525578000084],
              [12.886709214000064, 2.243150235000144],
              [12.884106637000116, 2.241942883999968],
              [12.882416725000098, 2.242435932000092],
              [12.881330490000039, 2.243681908000156],
              [12.880443573000093, 2.244698763000031],
              [12.877909660000114, 2.244411945000081],
              [12.877138138000078, 2.242499113000065],
              [12.875877381000123, 2.238532544000066],
              [12.875461579000159, 2.234142543000189],
              [12.874269486, 2.231025697000064],
              [12.874413490000052, 2.230091096000081],
              [12.874695779000035, 2.228265047999969],
              [12.872375488000159, 2.226987363000035],
              [12.869981765000091, 2.227550269000119],
              [12.868147850000128, 2.230167152000149],
              [12.866529464000166, 2.230164767000076],
              [12.864911080000013, 2.229383468000151],
              [12.863156318000165, 2.227553845000045],
              [12.86280345900019, 2.227185727000119],
              [12.861865043000137, 2.227084875000116],
              [12.86012935600013, 2.226898671000186],
              [12.855551719, 2.229157447000034],
              [12.855043411000054, 2.229775191000158],
              [12.853224755000042, 2.231986047000135],
              [12.852726937000057, 2.235454322000123],
              [12.850586891000091, 2.233752728000184],
              [12.850407601000029, 2.233610153000029],
              [12.847873687000174, 2.233818770000141],
              [12.84750557000018, 2.234412431000123],
              [12.846251488000121, 2.236435891000042],
              [12.844347954, 2.238769532000163],
              [12.840054513000155, 2.239117146000126],
              [12.836042405000171, 2.23946523800015],
              [12.831255913000064, 2.240024329000164],
              [12.828614234000099, 2.239983082000038],
              [12.827773095000055, 2.239969969000072],
              [12.826258659000132, 2.239946128000156],
              [12.823722840000016, 2.241429090000111],
              [12.823394775000168, 2.241998196000111],
              [12.822947501000101, 2.242773055000043],
              [12.823095321000096, 2.243906498000115],
              [12.823225022000031, 2.244897365000099],
              [12.824132919000135, 2.249712945000113],
              [12.824338914, 2.253252984000028],
              [12.821523667000065, 2.25374436400017],
              [12.819620133000114, 2.255794525000056],
              [12.819518088000109, 2.254726173000108],
              [12.81934261300006, 2.2528915420001],
              [12.818735122000021, 2.252138854000179],
              [12.817797660000053, 2.250977517000138],
              [12.816831590000049, 2.250392675000057],
              [12.815688134000141, 2.249700070000188],
              [12.814043044000073, 2.248040199000059],
              [12.813860893000026, 2.247856618000071],
              [12.813117981, 2.247202635000065],
              [12.810557366000182, 2.244949102000021],
              [12.808025360000102, 2.244166613000061],
              [12.804574966000075, 2.245364904000155],
              [12.802297591000183, 2.246887208000146],
              [12.80182743099999, 2.247201443000165],
              [12.799149514000021, 2.249462844000107],
              [12.796897888000103, 2.249459265999974],
              [12.796773910000127, 2.249138594000101],
              [12.796266556000091, 2.247830152],
              [12.796268464000036, 2.246870042000182],
              [12.796270371000105, 2.245777131000068],
              [12.795030593000035, 2.244298934000085],
              [12.794724464000012, 2.243934155000147],
              [12.795577049000144, 2.238767147000033],
              [12.794844627000089, 2.23799324100014],
              [12.794100762000085, 2.237207652000052],
              [12.791948319000085, 2.238154411000153],
              [12.791213989000084, 2.238477469000031],
              [12.790246964000119, 2.237226248000184],
              [12.78837585399998, 2.234804392000171],
              [12.787982940000177, 2.234295845000077],
              [12.786687850000135, 2.233764173000168],
              [12.785732270000096, 2.2333719720001],
              [12.784086227999978, 2.233866215000091],
              [12.782916068000191, 2.234217407000187],
              [12.78151226, 2.231454135000149],
              [12.779277802000024, 2.22977709900016],
              [12.778771400999972, 2.229397058000018],
              [12.776865005000161, 2.229344846000174],
              [12.776026725000065, 2.229321957000138],
              [12.77515697400014, 2.229506254],
              [12.773703576000173, 2.229814053000155],
              [12.771881103000112, 2.228718759000117],
              [12.770751000000132, 2.228039742000021],
              [12.768855094000116, 2.227991343000156],
              [12.767794609000191, 2.227964401000065],
              [12.764767647000042, 2.229092599000182],
              [12.762656211000092, 2.228735209999968],
              [12.761040688000094, 2.227033853000023],
              [12.75794601500013, 2.225542308000115],
              [12.75604343399999, 2.227309228000138],
              [12.754286766000121, 2.225607633000095],
              [12.752036094000061, 2.22482538200012],
              [12.75027942600002, 2.223123789000113],
              [12.747674942, 2.223615408000057],
              [12.746656419000033, 2.222561360000043],
              [12.744935036000129, 2.22077941900011],
              [12.743388176000053, 2.219856739000022],
              [12.742075919000115, 2.221729041000174],
              [12.741554260000044, 2.222473144000048],
              [12.740666390000115, 2.222471954000127],
              [12.739442826000129, 2.222470046000126],
              [12.737145423000186, 2.223536731000024],
              [12.736556053000072, 2.223810434000029],
              [12.73532772100009, 2.225510836000183],
              [12.735074996000037, 2.225861073000147],
              [12.734970094000062, 2.226004124000156],
              [12.733100891000049, 2.228548289000059],
              [12.73234748800013, 2.228420735000157],
              [12.730567932000156, 2.228119611000125],
              [12.728720666000072, 2.228505374000179],
              [12.727540970000177, 2.228751898000155],
              [12.725483894000149, 2.229625941000052],
              [12.725216866000096, 2.229739427000027],
              [12.724872587999982, 2.229583025000068],
              [12.72240448000008, 2.22846078900011],
              [12.71965885100019, 2.229093789000103],
              [12.717988013000138, 2.228832484000066],
              [12.715085984000098, 2.228378535000047],
              [12.711762428999975, 2.226913692000096],
              [12.711217881000039, 2.226673603000052],
              [12.709457398000154, 2.227237225000124],
              [12.706617355000105, 2.230023623000079],
              [12.70621585900011, 2.23041772900001],
              [12.705251693999969, 2.230842591000169],
              [12.70093440900007, 2.232745410000177],
              [12.700095177000094, 2.23346328700012],
              [12.698538779000103, 2.234794616000045],
              [12.695369721000077, 2.23641777000006],
              [12.691212653000036, 2.239809036000168],
              [12.690426826000134, 2.240052463000154],
              [12.683256150000091, 2.242274047000137],
              [12.677205087000118, 2.242122890000132],
              [12.674280167000063, 2.242869140000039],
              [12.673895837000032, 2.242967129000078],
              [12.671858787000076, 2.240769386000068],
              [12.668833732000166, 2.240693808],
              [12.667567253000072, 2.240196229000162],
              [12.664542198000163, 2.239554167000165],
              [12.661092759000155, 2.24046898000006],
              [12.655111312000031, 2.240530015000047],
              [12.653963088000069, 2.239586591000148],
              [12.652090073000068, 2.238047601000176],
              [12.651295663000042, 2.238373994000085],
              [12.649343491000025, 2.239175797000087],
              [12.647681236000039, 2.240675687000021],
              [12.647229195000023, 2.24108362100003],
              [12.642165185000067, 2.239588976000107],
              [12.641227721000178, 2.239501714000141],
              [12.639069557000084, 2.239300728000046],
              [12.634772301000169, 2.242054462000169],
              [12.632738113000073, 2.242343665000021],
              [12.630831719000071, 2.242614508000145],
              [12.626196862000029, 2.242948056000046],
              [12.622175216000187, 2.243237257000033],
              [12.61992073000016, 2.24479103200008],
              [12.618043900000089, 2.248262645000182],
              [12.617168426000148, 2.249882936000063],
              [12.616480827000032, 2.249937058000114],
              [12.615408896000076, 2.250021697000079],
              [12.614304542000184, 2.249959230000172],
              [12.611538887000052, 2.24980306700013],
              [12.610198022000191, 2.252065898000183],
              [12.609812736000094, 2.255071164000185],
              [12.609699249000073, 2.255958318000182],
              [12.6032266630001, 2.255522967000161],
              [12.602618216999986, 2.253613473000087],
              [12.602526665000084, 2.253327370000136],
              [12.601535796000064, 2.253325702000154],
              [12.600626946000034, 2.253324270000064],
              [12.59703731500008, 2.25345993100018],
              [12.590799330999971, 2.253072023000016],
              [12.586823464000076, 2.255292417000078],
              [12.58507061, 2.256271363000053],
              [12.583472252000092, 2.25834489000016],
              [12.582798958000183, 2.259736300000157],
              [12.581966399000123, 2.261456966000083],
              [12.581608772000095, 2.264360667000062],
              [12.581490517000191, 2.26532578500013],
              [12.576028823, 2.267146825],
              [12.573708535000037, 2.26792049400018],
              [12.573323249000168, 2.268049002000169],
              [12.558181762000117, 2.259352447000083],
              [12.553546905000076, 2.256690265000088],
              [12.545574189000149, 2.255638600000054],
              [12.544769286000076, 2.255495548999988],
              [12.534318924000104, 2.253637552000043],
              [12.53246307400002, 2.253593682000144],
              [12.530003547000035, 2.253535747000058],
              [12.527360916000134, 2.253871918000073],
              [12.5263443, 2.254001379000044],
              [12.524115563000123, 2.254709244000082],
              [12.518364906000102, 2.25653576799999],
              [12.517853737000166, 2.25696516],
              [12.514888763000158, 2.25945544199999],
              [12.511885643000028, 2.260394096000027],
              [12.492743493000148, 2.262248515000067],
              [12.483838081000158, 2.258363723000173],
              [12.474303246000034, 2.254126787000075],
              [12.472964286000092, 2.253531694000174],
              [12.470244407, 2.253338098000143],
              [12.466847420000079, 2.253256321000038],
              [12.457579613000178, 2.253032922999978],
              [12.45392227200017, 2.252460242000154],
              [12.453569412000093, 2.252556324000068],
              [12.449792863000027, 2.25358533900004],
              [12.424925805000044, 2.257787944000086],
              [12.401559830000053, 2.261803865000047],
              [12.388916970000082, 2.263532163000093],
              [12.385890006000068, 2.263945817000035],
              [12.378664969999988, 2.265253783000048],
              [12.368132591000119, 2.268022776000123],
              [12.325662614000066, 2.279188872000134],
              [12.320090293000021, 2.280144691000032],
              [12.315093041000125, 2.281439782000177],
              [12.309952735000081, 2.281816722000087],
              [12.307167052000068, 2.282197715000109],
              [12.299921035000125, 2.279092312000103],
              [12.290753365000114, 2.275934697000082],
              [12.289605142000028, 2.273951769000121],
              [12.28960800200008, 2.271970988000135],
              [12.288893699000027, 2.268877746000158],
              [12.287219047000065, 2.265444518000095],
              [12.283241271000065, 2.260944129000166],
              [12.278394698999989, 2.258906126000056],
              [12.271279335000145, 2.256185531000085],
              [12.270140647000062, 2.255750179000131],
              [12.26773452700013, 2.255140305000111],
              [12.26591587199999, 2.254679441000178],
              [12.263898850000089, 2.254917144000046],
              [12.263431550000178, 2.255299330000014],
              [12.262071608999975, 2.256411314000047],
              [12.259858131000158, 2.258387804000108],
              [12.258657456000037, 2.25848221900003],
              [12.257702827000173, 2.255291939000017],
              [12.256987571000138, 2.254424811000035],
              [12.255786895000085, 2.252969503000088],
              [12.254696846000058, 2.252099515000055],
              [12.253724098000021, 2.25132298599999],
              [12.252322197000126, 2.251408577000063],
              [12.251419067000029, 2.251463652000098],
              [12.248058320000041, 2.250925779999989],
              [12.245902061000038, 2.248409749000132],
              [12.245083808000118, 2.24845147100018],
              [12.244076728000095, 2.248502731000144],
              [12.241259574000082, 2.249141456000189],
              [12.240906715000108, 2.249221325000121],
              [12.237590790000127, 2.250422716000117],
              [12.23547458600018, 2.252109528000062],
              [12.233068465000088, 2.254713535000121],
              [12.230040551000059, 2.255915641000115],
              [12.229002952000087, 2.255973816000051],
              [12.227542876000086, 2.256055593000156],
              [12.224804877999986, 2.256581784000105],
              [12.223822594000069, 2.25723123500012],
              [12.222400665000123, 2.258171319000098],
              [12.21984958600018, 2.260968448000142],
              [12.218355177999968, 2.263960600000075],
              [12.218125343000054, 2.265727758000082],
              [12.21796608000011, 2.266954898000051],
              [12.21671295100009, 2.269222975000048],
              [12.21573925000007, 2.270233392000023],
              [12.214017867, 2.272019625000155],
              [12.212478638000107, 2.275417805000132],
              [12.212378503000082, 2.275639535000153],
              [12.208822251000186, 2.27698516800001],
              [12.205365182000037, 2.27825403300011],
              [12.205025673000137, 2.278378726000028],
              [12.202339173000041, 2.277165889000173],
              [12.200914383000111, 2.276544570000169],
              [12.199556350000137, 2.275952578000045],
              [12.197058678000019, 2.275995970000054],
              [12.196274758000186, 2.276411056000143],
              [12.193694115000085, 2.277776958000175],
              [12.189755440000113, 2.27781748700005],
              [12.18711471600011, 2.277570725999965],
              [12.183082580000075, 2.27707982000004],
              [12.181013108000059, 2.27764606400018],
              [12.18010330300001, 2.277894974000162],
              [12.177931786000045, 2.278402567000171],
              [12.177028656000118, 2.278613567000036],
              [12.173185349000164, 2.279185772],
              [12.168523788000186, 2.28125357600004],
              [12.166702270000087, 2.27955937400003],
              [12.166271210000161, 2.279010535000168],
              [12.164498329000082, 2.276753187000111],
              [12.159605025000076, 2.274424791000172],
              [12.153656959000159, 2.271031619000098],
              [12.150512696000135, 2.271541118000073],
              [12.149237632000165, 2.271747590000075],
              [12.14491271900016, 2.273477794000087],
              [12.142497063000121, 2.275585890000059],
              [12.141930580000064, 2.276080371000035],
              [12.141808509000157, 2.276180028],
              [12.140102386000137, 2.277574062999975],
              [12.138034820000144, 2.278922559000023],
              [12.134238244000073, 2.280557393000095],
              [12.133531571000105, 2.280713797999965],
              [12.130779267000094, 2.281323194000152],
              [12.128376007000043, 2.282477618000087],
              [12.12679863, 2.2842071070001],
              [12.125825882000186, 2.285274267000091],
              [12.124835014000098, 2.286713839000186],
              [12.123369217, 2.28884387100004],
              [12.116366386000152, 2.285062074000052],
              [12.115024566000159, 2.284440040000106],
              [12.113951683000096, 2.283942699000079],
              [12.112912177000055, 2.283460856999966],
              [12.112701417000039, 2.282886028000121],
              [12.111527442000181, 2.279690505000133],
              [12.11023521400017, 2.277369261000104],
              [12.109754562000035, 2.277276277000055],
              [12.108219147000057, 2.276978970000073],
              [12.105673790000083, 2.277263641000047],
              [12.104133606000175, 2.276965142000165],
              [12.102649689000032, 2.276677609000046],
              [12.10039329500006, 2.276673079000034],
              [12.096551895000175, 2.276617051000017],
              [12.092660903000137, 2.277092218000178],
              [12.090352058000121, 2.279116153000018],
              [12.088759423000056, 2.279290914],
              [12.088191031000122, 2.279353380000032],
              [12.085551262000081, 2.278961658000128],
              [12.082431794000115, 2.277844191000156],
              [12.081417084000179, 2.277996778000045],
              [12.080846787000155, 2.27808261000007],
              [12.07642555200016, 2.280150414000104],
              [12.074789048000127, 2.281982660000153],
              [12.070992470000022, 2.283472300000142],
              [12.067849160000037, 2.283755780000149],
              [12.065757752000025, 2.283944369000039],
              [12.060956002000069, 2.284031153000115],
              [12.058041573000082, 2.283932209000113],
              [12.054905891000146, 2.283825636000074],
              [12.052265168000076, 2.283723594000151],
              [12.051372528000115, 2.284246446000054],
              [12.049717903000044, 2.28521561600013],
              [12.049784661000103, 2.283811331000038],
              [12.049867630000108, 2.28207635900003],
              [12.048290252000129, 2.280801297000039],
              [12.047950745000037, 2.280526877000057],
              [12.046462058000031, 2.280523777000155],
              [12.041707038000084, 2.28133487700012],
              [12.037722589, 2.280940294000061],
              [12.03334999100008, 2.282428503000176],
              [12.031825065000021, 2.28320264700011],
              [12.03003311100008, 2.28411221500005],
              [12.029417992000049, 2.284377575000178],
              [12.027246475000084, 2.285313846000122],
              [12.023404121000112, 2.28549909700007],
              [12.019562721000057, 2.285539389000178],
              [12.017449379000027, 2.286114454000085],
              [12.015743256000178, 2.288030148000132],
              [12.015684128000089, 2.287947655000153],
              [12.013633728000059, 2.286566497000081],
              [12.013093947000073, 2.285648107000156],
              [12.012553213999979, 2.284728289000043],
              [12.010560989000112, 2.283051015000183],
              [12.009820939000178, 2.282427788000064],
              [12.008773805000033, 2.281769515],
              [12.007257461000165, 2.2808160780001],
              [12.00593090100017, 2.281719445000135],
              [12.004402160000041, 2.282760621000136],
              [12.003461838000078, 2.283311130000129],
              [12.001375198000062, 2.284532785000067],
              [11.99943828500011, 2.283610821000139],
              [11.99884414700017, 2.283155918000091],
              [11.997559547000094, 2.282172679000155],
              [11.997100830000079, 2.282038450000187],
              [11.994994164000047, 2.281421424000087],
              [11.993866921000119, 2.281439305000049],
              [11.991800309000041, 2.281471968000176],
              [11.990537642999982, 2.281521321000071],
              [11.989006043000074, 2.281580926000117],
              [11.987783431000025, 2.281996013000139],
              [11.98615169499999, 2.282550097000126],
              [11.984094621000168, 2.284553766000101],
              [11.98072433499999, 2.287242889000083],
              [11.979241371000057, 2.287239791000047],
              [11.978347778999989, 2.287045240000168],
              [11.977644921000149, 2.286892175000048],
              [11.975595475000034, 2.28528165900002],
              [11.973487854000155, 2.28395772000016],
              [11.971605301000068, 2.28441262400014],
              [11.971206665000125, 2.285150766000129],
              [11.970459936999987, 2.28653287800006],
              [11.969201088000034, 2.288480759000038],
              [11.968770980000158, 2.28774094500011],
              [11.968065262000039, 2.286527633000048],
              [11.965160371000081, 2.284628391000126],
              [11.964920997000036, 2.284437418000039],
              [11.962996482000051, 2.282902718000059],
              [11.960259437000161, 2.28261017900013],
              [11.957177162000107, 2.284037591000129],
              [11.955525397000144, 2.283116341000095],
              [11.953072547000033, 2.283397914000034],
              [11.950673102, 2.285515308000072],
              [11.94828033499999, 2.284305574000143],
              [11.946649551000064, 2.283481120000147],
              [11.945317269000157, 2.282807589000129],
              [11.943964005000112, 2.281718730000023],
              [11.942243577000056, 2.280334235000112],
              [11.938935280000123, 2.280269862000182],
              [11.937005997000085, 2.278950691000034],
              [11.936867713000026, 2.278913259000149],
              [11.935718536000081, 2.278601169000069],
              [11.934841155000072, 2.278346062000026],
              [11.934324264000168, 2.27819562000019],
              [11.932152749000181, 2.278706312000168],
              [11.929411888000175, 2.280306816000064],
              [11.928790092000042, 2.28090715500008],
              [11.92621135600001, 2.283397674000185],
              [11.924870491000036, 2.284716845000162],
              [11.923011781000071, 2.286545992000072],
              [11.921755790000134, 2.28717422599999],
              [11.921079635000069, 2.283272028000113],
              [11.918005944000015, 2.280454398000188],
              [11.916548728000066, 2.279098750000117],
              [11.915843010000117, 2.278441907000058],
              [11.913286210000138, 2.27782011],
              [11.911796570000149, 2.27745795300018],
              [11.909813880000058, 2.27825760800016],
              [11.908542633000138, 2.278770209000186],
              [11.90574932200002, 2.278477431000113],
              [11.90198421499997, 2.278813362000108],
              [11.900952340000117, 2.279879808999965],
              [11.900156020000168, 2.280702590000146],
              [11.897362709000049, 2.280180216000076],
              [11.896605491000116, 2.280864478000183],
              [11.895019532000049, 2.282297612000093],
              [11.893825531000118, 2.280803680000133],
              [11.891149521000045, 2.278617860000111],
              [11.890134811000109, 2.277791501000024],
              [11.889101029000187, 2.27694988200011],
              [11.886363984000127, 2.276829243000179],
              [11.883686066000053, 2.277198316000067],
              [11.881856918000096, 2.277450323000039],
              [11.880311013000153, 2.280257941000173],
              [11.878087043000164, 2.28059721000011],
              [11.871242523000149, 2.281041146000064],
              [11.869020461000162, 2.280118465000044],
              [11.868762016000176, 2.278801681000118],
              [11.868513107000069, 2.277535916000147],
              [11.86857795800006, 2.276255847000186],
              [11.868691445000081, 2.274037123000085],
              [11.868575095000097, 2.27277517400006],
              [11.868357658000093, 2.270422698000061],
              [11.866478920000077, 2.268984318000094],
              [11.863740921000101, 2.269265174000054],
              [11.861569404000136, 2.271268368000051],
              [11.860534668000128, 2.270777465000037],
              [11.859746934000157, 2.270403862000137],
              [11.857009888000164, 2.270397902000184],
              [11.855674743000122, 2.270778179999979],
              [11.85541152899998, 2.270853281000086],
              [11.85409641200016, 2.272513866000111],
              [11.853794098000037, 2.273547412000141],
              [11.853292464000106, 2.275265455000124],
              [11.853801728000121, 2.277159691000179],
              [11.853628159000039, 2.27830648500003],
              [11.853284836000171, 2.278119326000081],
              [11.850780486000076, 2.276751280000042],
              [11.849545479000142, 2.276944637000156],
              [11.848612785000057, 2.277090788000066],
              [11.846742630000108, 2.275735140000165],
              [11.845993042000089, 2.275192022000169],
              [11.844400405000044, 2.273352861000149],
              [11.84340000100002, 2.27287077800014],
              [11.842008590000091, 2.272200584000075],
              [11.841396332000045, 2.272345543000085],
              [11.84080982200004, 2.272484540000164],
              [11.839340210000103, 2.273809672000027],
              [11.839096069000107, 2.274029732000088],
              [11.837893485000052, 2.276551008000183],
              [11.836631776000047, 2.278066397000032],
              [11.835891724000078, 2.278955698000175],
              [11.834915161000026, 2.279531718000044],
              [11.832695007000154, 2.280841589000147],
              [11.829042435000133, 2.282095433000166],
              [11.826134681000042, 2.281802177000088],
              [11.824827195000012, 2.279791595000177],
              [11.825561524000136, 2.277400254999975],
              [11.825689316000023, 2.276982785000087],
              [11.826095582000107, 2.273714064999979],
              [11.825563430000102, 2.273074865000069],
              [11.823990821000109, 2.271185637000031],
              [11.822452546000136, 2.270895481000025],
              [11.820738793000146, 2.272210837000159],
              [11.820062636999978, 2.273248433000049],
              [11.819022178000125, 2.274845601000038],
              [11.816056252000067, 2.275068521000151],
              [11.814159393000068, 2.274532556000111],
              [11.811554908000119, 2.273796559000061],
              [11.808077813000125, 2.273272514000155],
              [11.80701637300001, 2.274392604000127],
              [11.805962563000037, 2.275504829],
              [11.803627967000182, 2.27389359600005],
              [11.803380013000094, 2.273828268000102],
              [11.800551415000143, 2.273083688000156],
              [11.79820919000008, 2.274856806000116],
              [11.796833038000045, 2.27466487900017],
              [11.795701028, 2.274506807000023],
              [11.794608117000109, 2.278691767999987],
              [11.793982505000031, 2.278059244000076],
              [11.794244767000066, 2.277186155000038],
              [11.794844628000021, 2.27519345200011],
              [11.794676780000032, 2.273644209000111],
              [11.793426514000146, 2.271806003000052],
              [11.791340828000045, 2.273134470000116],
              [11.790913581000098, 2.273406506000072],
              [11.790590286000111, 2.273597240000186],
              [11.787716866000039, 2.275292159000173],
              [11.788121223000189, 2.272769213000174],
              [11.788085938, 2.272597313000176],
              [11.787500381000143, 2.269727708000119],
              [11.784538270000098, 2.268344403000185],
              [11.782720565000091, 2.268857718000106],
              [11.781515120999984, 2.269198179000114],
              [11.778355598000132, 2.269692659000157],
              [11.777179718000014, 2.269876718000035],
              [11.775428772999987, 2.270061494000061],
              [11.769195556000057, 2.270719290000102],
              [11.766571998000018, 2.270828010000059],
              [11.764010429999985, 2.268872023000142],
              [11.763771056999985, 2.268655538000076],
              [11.762928962000046, 2.267894507000051],
              [11.758881570000028, 2.267483950000099],
              [11.758351325000092, 2.267562151999982],
              [11.756200790000094, 2.267879249000032],
              [11.753132820000076, 2.269011020000164],
              [11.752491951000081, 2.269247533000112],
              [11.752043724000032, 2.269505263000099],
              [11.750207901000067, 2.270561457000099],
              [11.749520301000018, 2.272108793000029],
              [11.750792503000127, 2.273345233000043],
              [11.752478600000131, 2.274983407000036],
              [11.752985001000184, 2.278196335000132],
              [11.752976418000117, 2.282096625000065],
              [11.752293586000064, 2.281292200000053],
              [11.752243996000061, 2.278079987000126],
              [11.750596999000152, 2.275323153000102],
              [11.750336647000097, 2.275263070000165],
              [11.748088837000068, 2.274743796000053],
              [11.747561455000152, 2.275206328000081],
              [11.747175216000073, 2.275544881000144],
              [11.746632576000138, 2.276320935000058],
              [11.745973587000037, 2.277262689000168],
              [11.74584770199999, 2.278815032000068],
              [11.745680808999964, 2.280875683000147],
              [11.743737220000185, 2.283165455000074],
              [11.742525101000183, 2.286009789000048],
              [11.742418289000057, 2.28625965100008],
              [11.742942809999988, 2.287368775000061],
              [11.744972228000051, 2.291656971000123],
              [11.74656391100001, 2.29355335200006],
              [11.74633312200001, 2.294757366000056],
              [11.744953155000189, 2.294218541000134],
              [11.740864753000096, 2.292622566000148],
              [11.734368324, 2.291059018000112],
              [11.731175421999978, 2.290879727000117],
              [11.728833199000064, 2.292996646000177],
              [11.728143692000117, 2.295346499],
              [11.727567673000181, 2.296053887000085],
              [11.726884843000164, 2.296892166000077],
              [11.723572731000047, 2.299178838000103],
              [11.722990990000142, 2.299350739000033],
              [11.722032546000037, 2.299634219000041],
              [11.719538689000046, 2.299628257000109],
              [11.718497275, 2.299625874000185],
              [11.714221955000141, 2.299157142000126],
              [11.712240219000137, 2.299267530000179],
              [11.711256981000133, 2.299322367000173],
              [11.709752083000126, 2.300059557000054],
              [11.708459855000172, 2.300692319],
              [11.707073212000068, 2.301922799000067],
              [11.70594596899997, 2.302923441000075],
              [11.705826759000047, 2.303100347000111],
              [11.704172134000032, 2.305557490000126],
              [11.702910422000059, 2.308766365000167],
              [11.702532767000037, 2.310386181000069],
              [11.702460289000044, 2.310696125000163],
              [11.70243930800018, 2.310787677000064],
              [11.70240688399997, 2.310926914000163],
              [11.702161789000058, 2.311976433000041],
              [11.701069831000098, 2.315644502000055],
              [11.682029724000074, 2.314395429000172],
              [11.68039226500008, 2.314436198000067],
              [11.679920197000058, 2.314447879000056],
              [11.672985077000021, 2.31300902400011],
              [11.67097282300017, 2.312591552000185],
              [11.667032241000072, 2.312418939000054],
              [11.666192054000078, 2.313529016000132],
              [11.665208816000074, 2.314828634000037],
              [11.65905380200013, 2.313896418000184],
              [11.656586646999983, 2.313279390999981],
              [11.655349731000172, 2.312970162000113],
              [11.654328346000113, 2.310730934999981],
              [11.651489258000026, 2.305734635000135],
              [11.649854661000063, 2.303688527000134],
              [11.649102211000127, 2.302746773000024],
              [11.646194457000092, 2.302797318000046],
              [11.645704269000021, 2.302680015000021],
              [11.643288613000152, 2.302102090000062],
              [11.640835761000062, 2.301161051000065],
              [11.639528275000089, 2.300659418000066],
              [11.638068199000031, 2.300656080000124],
              [11.636392593000153, 2.300652027000069],
              [11.636175155000103, 2.300772190000146],
              [11.635994912, 2.30087161],
              [11.635796547000041, 2.300974845000155],
              [11.63552475, 2.301116229000115],
              [11.633595467000191, 2.302179576000071],
              [11.630799293000109, 2.303262710000183],
              [11.627725602000055, 2.301190853000037],
              [11.625124932000176, 2.300096274000168],
              [11.62334060600017, 2.299345256000038],
              [11.620379447, 2.297674895000114],
              [11.619168280999986, 2.29705619900011],
              [11.614627838000104, 2.294736384999965],
              [11.612940789000049, 2.293675661000179],
              [11.612522124000122, 2.29341244800014],
              [11.610299110000085, 2.293292523000048],
              [11.608368874000064, 2.293849469000122],
              [11.608130456000026, 2.293918132000044],
              [11.607178689000079, 2.294755936000115],
              [11.606245995000165, 2.29557657200013],
              [11.604601860000116, 2.297643901000015],
              [11.604015351000157, 2.298381568000082],
              [11.600644112000055, 2.301584958999968],
              [11.598247528000172, 2.302726030000144],
              [11.595394134000117, 2.30369424800017],
              [11.594370841000114, 2.304164172000128],
              [11.593282700000032, 2.304663898000115],
              [11.592000008000127, 2.304785729000116],
              [11.591514587000063, 2.304831743000023],
              [11.589867592000132, 2.304802657000096],
              [11.587752341000169, 2.30476522500004],
              [11.585469246000173, 2.305792093000093],
              [11.582673073000024, 2.307046890000095],
              [11.582418441000073, 2.307019711000066],
              [11.57993698199999, 2.306753636000167],
              [11.573666572999969, 2.306509019000089],
              [11.569903374000091, 2.30672931700002],
              [11.566656111999976, 2.305746556000145],
              [11.563751221000075, 2.304764510000098],
              [11.560218811000027, 2.30401063000005],
              [11.557826996000074, 2.302743198000144],
              [11.557038307000141, 2.301145553000026],
              [11.556352615000037, 2.299757481000142],
              [11.554784774000098, 2.29780721700007],
              [11.554135323000082, 2.296999454000115],
              [11.553640364999978, 2.296626329000048],
              [11.551006317000031, 2.294640780000179],
              [11.548668862000113, 2.294405700000084],
              [11.546952247000149, 2.294894695000096],
              [11.545075415999975, 2.295429230000025],
              [11.541962623000131, 2.296606540000084],
              [11.541309357000046, 2.296853542000122],
              [11.541066171000182, 2.296970130000148],
              [11.539882660000046, 2.297538281000186],
              [11.539104461000079, 2.296864272000107],
              [11.538687707, 2.296503305000158],
              [11.537883759000124, 2.295860530000027],
              [11.536241532000076, 2.294547559000023],
              [11.533733367000082, 2.294369460000098],
              [11.531908034000139, 2.29499578500014],
              [11.527285575000064, 2.296934128000032],
              [11.526160240000081, 2.297938108000153],
              [11.524657248999972, 2.299278736000019],
              [11.523341178000067, 2.301225186000124],
              [11.522706986, 2.30102920500002],
              [11.522031784000092, 2.300820590000058],
              [11.518731117000129, 2.298346759000083],
              [11.518161773000088, 2.298207760000082],
              [11.515882493000163, 2.29765152900012],
              [11.514422417000105, 2.297892571000034],
              [11.51314544700017, 2.29810357100007],
              [11.511276245000033, 2.298943997000151],
              [11.510862350000025, 2.299130201000082],
              [11.507381440000131, 2.300555230000157],
              [11.504245758000025, 2.30060482000016],
              [11.501852988999985, 2.300082923000048],
              [11.500691414000073, 2.299392938000096],
              [11.499235153000143, 2.2985279560001],
              [11.494949340000176, 2.296015500000067],
              [11.494225501000074, 2.295591355000056],
              [11.493323326000109, 2.295074941000109],
              [11.489899636000132, 2.293114901000138],
              [11.487792015000139, 2.29242157900012],
              [11.485682488000123, 2.292473794000102],
              [11.483111382000175, 2.294875622000063],
              [11.481298447000029, 2.296190738000178],
              [11.47917175300006, 2.297733068000071],
              [11.475630760000115, 2.300419331000171],
              [11.474943160000066, 2.30030894399999],
              [11.472724914000025, 2.299953222000056],
              [11.469882965000124, 2.299755573000141],
              [11.467595100000096, 2.299596548000068],
              [11.463434220000124, 2.299643517000106],
              [11.461377144000096, 2.300007582000148],
              [11.460240364000185, 2.300208807000161],
              [11.45641803699999, 2.301346065000132],
              [11.456259727000031, 2.300702810000075],
              [11.456083298000124, 2.299982309000086],
              [11.457220077000102, 2.299639465000155],
              [11.458532333000164, 2.299243927000077],
              [11.45933818900005, 2.298115731000166],
              [11.459962845, 2.297240735000059],
              [11.459797860000151, 2.294717312000103],
              [11.459317208000186, 2.294110061000026],
              [11.457979202000104, 2.292419196000026],
              [11.455078124000067, 2.289946318000034],
              [11.453393935000065, 2.289708377000181],
              [11.45177364400007, 2.289479494000148],
              [11.446291923999979, 2.28989338800011],
              [11.445159912000122, 2.289978982000093],
              [11.44407177100004, 2.292097807000175],
              [11.444105148000062, 2.29229903300012],
              [11.44440937100012, 2.294105530000081],
              [11.442864419000159, 2.296337843000174],
              [11.439952850000054, 2.298050643000124],
              [11.437046051000152, 2.297928810000087],
              [11.434657097000184, 2.295858623000129],
              [11.432039262000046, 2.294005156000139],
              [11.429536820000123, 2.29223346800012],
              [11.427599906000069, 2.291769744000135],
              [11.42514896400013, 2.29159164500004],
              [11.420134544, 2.290890932000025],
              [11.416659356000082, 2.291314602000114],
              [11.414604187000123, 2.291565179000145],
              [11.414426804000129, 2.29168677399997],
              [11.414018630000101, 2.291966438000031],
              [11.412607194000032, 2.292305469000098],
              [11.409697533000042, 2.293502092000097],
              [11.409360886000172, 2.293620825000062],
              [11.405019761000062, 2.295153142000117],
              [11.403315544, 2.296376229000146],
              [11.40239238800001, 2.297038555000029],
              [11.402281760000108, 2.297042131000183],
              [11.401776314000017, 2.297059059000162],
              [11.401095390000023, 2.297067642000059],
              [11.400091172000032, 2.297101259000101],
              [11.399200440000072, 2.297131062000176],
              [11.396113396000089, 2.300678016000177],
              [11.390993118000097, 2.296880723000129],
              [11.388546944000041, 2.29498243400019],
              [11.385635375000049, 2.296981813000173],
              [11.383923530000118, 2.297608137000111],
              [11.380274773999986, 2.298000098000102],
              [11.376516343000162, 2.296901227000035],
              [11.376440049000053, 2.296852827000123],
              [11.373614312000143, 2.295058728000129],
              [11.370086668999988, 2.292756320000024],
              [11.368889808000176, 2.291154862000042],
              [11.368155479000109, 2.290171624000038],
              [11.367847442000084, 2.289893151000172],
              [11.365482331000067, 2.287756681000133],
              [11.367991448000112, 2.287361622000049],
              [11.370615006000094, 2.286508322000145],
              [11.371896744000139, 2.285695554000085],
              [11.372956276000025, 2.28502369000006],
              [11.374797822000176, 2.284673691000137],
              [11.375636101000168, 2.284514428000023],
              [11.378173828000058, 2.284346103000075],
              [11.379797936000159, 2.28423833800008],
              [11.379521371000124, 2.280854940000097],
              [11.378443718000028, 2.278366566000102],
              [11.378104210000174, 2.27758336100004],
              [11.380259513000055, 2.27791619400017],
              [11.381123543000172, 2.278049468000177],
              [11.382894516000022, 2.276391268000111],
              [11.382959367000183, 2.273410083000101],
              [11.382625579000091, 2.270255805000033],
              [11.380519868000022, 2.268874408000045],
              [11.379490852000174, 2.26796698600009],
              [11.377846718, 2.266517163000117],
              [11.377337455999964, 2.265082360000179],
              [11.380701066000086, 2.264689445000101],
              [11.383665085000132, 2.264754295000103],
              [11.384241105000172, 2.262404920000165],
              [11.383301735000089, 2.261129380000114],
              [11.382422447000181, 2.259935142000074],
              [11.379737854000155, 2.257794619000151],
              [11.379465103000086, 2.25757694399999],
              [11.377362252000069, 2.25527834900015],
              [11.377654076000056, 2.252354861000015],
              [11.379995346000157, 2.250411510999982],
              [11.381101608000051, 2.248769046000064],
              [11.381769181000095, 2.247778415000084],
              [11.381929399000057, 2.246693374000131],
              [11.382174493000093, 2.245027303000029],
              [11.381380081000088, 2.243649484000173],
              [11.380312920000165, 2.242255211000156],
              [11.378653525000061, 2.240087747000075],
              [11.376434326000037, 2.238534212000047],
              [11.372961044000078, 2.237092257000029],
              [11.370228768000118, 2.235709429000167],
              [11.369140625000057, 2.236464977000139],
              [11.367431640000177, 2.237651825000114],
              [11.366779327000074, 2.238777876000142],
              [11.366171838000184, 2.23982739500002],
              [11.364851951000162, 2.242064476999985],
              [11.364111901, 2.243319511000095],
              [11.363655090000123, 2.24293494300008],
              [11.363429069000176, 2.242744684000058],
              [11.363492965000148, 2.239992620000066],
              [11.362876891000155, 2.235576391000166],
              [11.362792969000111, 2.23514652300014],
              [11.361864091000029, 2.230413914000167],
              [11.360617638000178, 2.227429629000085],
              [11.357578278000062, 2.228327036000167],
              [11.357537271000126, 2.228339196000093],
              [11.355093001000057, 2.225638390000086],
              [11.35636329600004, 2.22506618500006],
              [11.358003614999973, 2.224327088000109],
              [11.359201431000145, 2.223806381000145],
              [11.360627175000161, 2.223186732000102],
              [11.362965583, 2.222504616000151],
              [11.364983559, 2.220132112000158],
              [11.36525249500005, 2.219815492000066],
              [11.364761352000187, 2.21913266200005],
              [11.363888741000039, 2.217920303000028],
              [11.362511634000043, 2.216983557000106],
              [11.362199784000154, 2.216771364000067],
              [11.359049797000011, 2.2159590710001],
              [11.360309600999983, 2.213496685999985],
              [11.362473487000159, 2.211684228000138],
              [11.363107682000134, 2.211153031000151],
              [11.362653733000172, 2.210173845000156],
              [11.361804008000092, 2.208340407000151],
              [11.359018325000079, 2.205466987000079],
              [11.358909607000157, 2.203402520000054],
              [11.359026908000033, 2.201912165000124],
              [11.359871865, 2.20191431100011],
              [11.360793113000057, 2.201916696000069],
              [11.362008095000022, 2.20182299600009],
              [11.362959861999968, 2.201749802999984],
              [11.363935471000161, 2.198885679000171],
              [11.363826751000033, 2.196706772000084],
              [11.36256790200008, 2.195660115000067],
              [11.357741355000144, 2.191646337000179],
              [11.356624603000114, 2.18189692500016],
              [11.355341911000039, 2.180869102000088],
              [11.357473373000175, 2.177727222000044],
              [11.359898567000073, 2.174151897000058],
              [11.356253623000157, 2.172592639000186],
              [11.353187562000016, 2.171280860000081],
              [11.346166611000058, 2.175209999],
              [11.343955994000055, 2.174952745000041],
              [11.341704369000183, 2.174065590000112],
              [11.341431618000058, 2.173964739000041],
              [11.337243080000064, 2.172417403000054],
              [11.3344106670001, 2.172394752000059],
              [11.278497697000034, 2.171949387000041],
              [11.249957085000062, 2.171721936000154],
              [11.138594627000032, 2.171789646000093],
              [11.13856029499999, 2.171789884000077],
              [11.138483048000126, 2.171790124000097],
              [11.113533020000034, 2.171804190000046],
              [11.061976433000041, 2.171750307000139],
              [10.97883796799999, 2.171663523000063],
              [10.884975434000182, 2.172010660000069],
              [10.845335007000131, 2.172157288000108],
              [10.710043907000113, 2.172312261000059],
              [10.704288482999971, 2.172323227000106],
              [10.670223236000083, 2.172387362000166],
              [10.628711701000043, 2.172465563000117],
              [10.62867164700009, 2.172480344],
              [10.628547668000067, 2.172526361],
              [10.628523827000151, 2.172494412000106],
              [10.5969553, 2.172544002000166],
              [10.575309754000159, 2.172577858000125],
              [10.438577652000163, 2.172810794000043],
              [10.404314996000153, 2.172622203000117],
              [10.399523734000184, 2.172595739000087],
              [10.305386543000111, 2.172078133000071],
              [10.23423385600006, 2.171867370000143],
              [10.197627068000031, 2.171759130000055],
              [10.192523957000162, 2.171743869000125],
              [10.190143584999987, 2.171736956000189],
              [10.190899848000129, 2.174328088000152],
              [10.188292503000127, 2.172401905000186],
              [10.18808078800015, 2.171730755999988],
              [10.187685012000088, 2.17195296400007],
              [10.185846329000071, 2.172985792000134],
              [10.184048652000115, 2.172815562000039],
              [10.181765557000119, 2.17099881199999],
              [10.182151795000095, 2.169465065000054],
              [10.181960106000133, 2.167723179000177],
              [10.181886673000179, 2.167052032000129],
              [10.179331779, 2.16512489400003],
              [10.178215026000089, 2.16248130800011],
              [10.177870750000181, 2.161666394],
              [10.175440789000106, 2.159306289000085],
              [10.175209045000145, 2.159080983000081],
              [10.174072265000063, 2.158264638000105],
              [10.172218323000095, 2.156933308000077],
              [10.169650077000085, 2.154333353000027],
              [10.166368484000088, 2.151010753000094],
              [10.166236877000131, 2.150877237000032],
              [10.166080475000115, 2.150718689000087],
              [10.163356780000129, 2.150271416000123],
              [10.162077905000046, 2.150248767000107],
              [10.159597396000095, 2.150204658000064],
              [10.157523154000046, 2.151239634000092],
              [10.155660629000067, 2.152108192000185],
              [10.153320312000119, 2.153199673000188],
              [10.150807382000039, 2.153140545000099],
              [10.147926332000054, 2.153072834000056],
              [10.147113801000046, 2.153151988000047],
              [10.145745277000174, 2.153285027000152],
              [10.145524024000053, 2.154435636000017],
              [10.145663261000095, 2.154738188000181],
              [10.146282197000176, 2.156082630000071],
              [10.141542435000019, 2.155628919000094],
              [10.136584283000161, 2.155503273000022],
              [10.135412216000077, 2.154643058000147],
              [10.134408951000125, 2.15390658400014],
              [10.129416467, 2.147311927000089],
              [10.125683785000092, 2.143863918000022],
              [10.123223303999964, 2.141590834000056],
              [10.120064736000131, 2.141032458000041],
              [10.119371414000113, 2.141875505000087],
              [10.118805885000143, 2.142563344000052],
              [10.117919921, 2.143803120000143],
              [10.116617202000157, 2.145626308000146],
              [10.113235474000135, 2.14654731800016],
              [10.112945557000046, 2.147243261000085],
              [10.112027167000065, 2.14944887200005],
              [10.11032676700006, 2.152896881000174],
              [10.112765312000136, 2.154021264000051],
              [10.113918303000048, 2.15455293600013],
              [10.114781379000078, 2.157351732000109],
              [10.113358497000092, 2.159210920000078],
              [10.112394332000179, 2.159857750000128],
              [10.109699248000084, 2.161665917000107],
              [10.107990265000183, 2.161660194000035],
              [10.106430055000089, 2.161655189000101],
              [10.104192735000026, 2.162744285000144],
              [10.103799819000017, 2.162743092000142],
              [10.102775574000134, 2.162739755000132],
              [10.098492623000027, 2.16014409100012],
              [10.097500801000024, 2.159543038000038],
              [10.094558716000051, 2.159478665000108],
              [10.092814445000045, 2.159472942000093],
              [10.087471962000052, 2.160387517000174],
              [10.08022212899999, 2.161131144000137],
              [10.075717926000095, 2.160924435000027],
              [10.073793411000111, 2.160836221000182],
              [10.0712966910001, 2.160340310000095],
              [10.070145607000086, 2.160111667000081],
              [10.064256669000088, 2.161407948000147],
              [10.062309265000067, 2.161770822000165],
              [10.05471610900014, 2.163185596000119],
              [10.050028802000156, 2.164423944000134],
              [10.047953605000146, 2.164972067000065],
              [10.047386169999982, 2.162376403000053],
              [10.046826362000047, 2.15981602800008],
              [10.046007156000144, 2.159897327000124],
              [10.044156074000057, 2.160081148000131],
              [10.042031287000043, 2.159725189000085],
              [10.041487694000125, 2.159634114000085],
              [10.04110050100013, 2.161331892000078],
              [10.041042328, 2.164147138000146],
              [10.041035653000108, 2.164456130000133],
              [10.038147925000089, 2.164391757000033],
              [10.035533904999966, 2.164163829000188],
              [10.035332681, 2.163933278000172],
              [10.034354210000174, 2.162811518000126],
              [10.032656670000051, 2.160865545000092],
              [10.031902313000046, 2.160685778000072],
              [10.031023026000071, 2.160476446000189],
              [10.029165267000053, 2.162169456000072],
              [10.025499345000185, 2.164336920000039],
              [10.025016785000048, 2.164622308000162],
              [10.022545814000125, 2.165745258000186],
              [10.020706178000125, 2.166581392000069],
              [10.019189834000088, 2.167242766000129],
              [10.017213822000031, 2.168104411000059],
              [10.012522697000122, 2.169897556000024],
              [10.011940002000131, 2.169942617000118],
              [10.010998726000025, 2.170015096000157],
              [10.009798049000096, 2.17010784199999],
              [10.00722885100015, 2.170099260000029],
              [10.006801606000067, 2.170097827000177],
              [10.006052971000031, 2.170061828000144],
              [10.00434970900011, 2.169980049000174],
              [10.002223014000094, 2.170575857000131],
              [10.000694275000171, 2.171612025000059],
              [9.998303413000087, 2.169685840000113],
              [9.996398926000154, 2.168912172000034],
              [9.994442940000113, 2.168782711000063],
              [9.993784904000052, 2.168739081000183],
              [9.992387770999983, 2.168015480000065],
              [9.991228104000072, 2.167415142000152],
              [9.990142823000042, 2.167360784000096],
              [9.988885879000122, 2.167297601000087],
              [9.987649917, 2.168146373000184],
              [9.98713874800012, 2.168497562000027],
              [9.987515449999989, 2.169532538],
              [9.987678528000174, 2.169979334000061],
              [9.987887383000157, 2.172610760000168],
              [9.987447739000174, 2.173815012000148],
              [9.986300468000025, 2.174742937000076],
              [9.985267640000188, 2.174081802000046],
              [9.982765198000095, 2.173032045000127],
              [9.980799675000071, 2.174176456000055],
              [9.979452132000063, 2.175002814000038],
              [9.977688790000173, 2.176084280000168],
              [9.974927903000094, 2.178193331000102],
              [9.974903107000159, 2.178212405000124],
              [9.97444915799997, 2.178339721000157],
              [9.974372864000088, 2.17836117700017],
              [9.974282265000113, 2.178386688000046],
              [9.97419834100009, 2.17841005400004],
              [9.974110604000032, 2.178434610000124],
              [9.974022865000165, 2.178459406],
              [9.973935126000072, 2.17848396300019],
              [9.973846435000098, 2.178508759000124],
              [9.97375869800004, 2.178533555000058],
              [9.973670006000191, 2.178558349000127],
              [9.973580360000028, 2.178583385000081],
              [9.973491668000179, 2.178608418000124],
              [9.973410607000119, 2.178661586000033],
              [9.973321915000042, 2.17868948000006],
              [9.973233223000023, 2.178717376000066],
              [9.973144532000049, 2.178745270000036],
              [9.973054885000181, 2.178773166000042],
              [9.972965241000054, 2.178801537000027],
              [9.972875594000186, 2.178829194000059],
              [9.972784995000041, 2.178858280000156],
              [9.972695351000084, 2.178886414000033],
              [9.972604751000063, 2.178915023000172],
              [9.972514152000088, 2.17894363400012],
              [9.972423553000169, 2.17897272100015],
              [9.972332954000024, 2.179001332000098],
              [9.972241402000122, 2.179030181000087],
              [9.972149850000164, 2.179059267000184],
              [9.972058296000057, 2.179088354000044],
              [9.971965789000137, 2.179117680000161],
              [9.971874237000179, 2.179147006000051],
              [9.971781730000089, 2.179176808000079],
              [9.971689224000045, 2.179206133999969],
              [9.971596717000125, 2.179235935000065],
              [9.971504212000014, 2.179265977000057],
              [9.971410751, 2.179295541000045],
              [9.971317291000105, 2.179325820000145],
              [9.971223832000078, 2.179355621000184],
              [9.971130372000175, 2.179385900000114],
              [9.971035958000186, 2.179416179000043],
              [9.970942498000113, 2.179446935000101],
              [9.970847129000106, 2.179477214000031],
              [9.970752717000096, 2.179507971000021],
              [9.970658303000107, 2.179538965000063],
              [9.970562936000135, 2.179569721000121],
              [9.970467566000082, 2.17960024000007],
              [9.970372199000053, 2.179631711000127],
              [9.970276832000081, 2.179662943000039],
              [9.970180511000024, 2.179694177000158],
              [9.970085143000119, 2.179725408000138],
              [9.969988824000041, 2.179756642000086],
              [9.969892502000107, 2.179788351000127],
              [9.969795227000134, 2.179820060000168],
              [9.969697953000036, 2.179851771000017],
              [9.96960067800012, 2.179883958000119],
              [9.969503403000147, 2.179915667000159],
              [9.969406129000049, 2.179947854000091],
              [9.969307899000114, 2.179980040000089],
              [9.969209670000055, 2.180012464000072],
              [9.969111443000031, 2.180044891000023],
              [9.969013213000096, 2.18007779200002],
              [9.968914031000054, 2.180109979000065],
              [9.968815803999974, 2.180143118000046],
              [9.968716622000159, 2.180175782000106],
              [9.968617439000184, 2.180208446000165],
              [9.968517303000056, 2.180241823000131],
              [9.96841716800003, 2.180274964000148],
              [9.968317033, 2.18030858100002],
              [9.968216896000172, 2.180341720000172],
              [9.968116761000147, 2.180375339000022],
              [9.96801567100016, 2.180408955000132],
              [9.967914582000049, 2.180442572000175],
              [9.967813492, 2.18047618899999],
              [9.967712402000188, 2.18051028300016],
              [9.967610360000094, 2.180544614000098],
              [9.967509270000051, 2.180578708000041],
              [9.967406273000165, 2.180612802000042],
              [9.967304229999968, 2.180646897000088],
              [9.967202186000066, 2.180681468000046],
              [9.967099191000159, 2.180716277000158],
              [9.966996193000171, 2.180750847000184],
              [9.966893196000058, 2.180785895000042],
              [9.96678924600019, 2.180820465000068],
              [9.966685294000115, 2.180855514000029],
              [9.966582299000038, 2.18089056000008],
              [9.966477394000151, 2.180925607000063],
              [9.966373444000055, 2.180961371000024],
              [9.966268539000168, 2.180996895000078],
              [9.966163635000044, 2.181032420000122],
              [9.966063500000189, 2.181066274000045],
              [9.964591981000183, 2.182397843000047],
              [9.962354661000177, 2.183595897000032],
              [9.959951401000069, 2.18539643400004],
              [9.955582619000097, 2.188615322000089],
              [9.95389270700008, 2.188828707000084],
              [9.952206611, 2.188055754000175],
              [9.949367523000149, 2.187021733000165],
              [9.948560715000156, 2.186728001000176],
              [9.946599960000185, 2.186721326000054],
              [9.943489075000116, 2.188409805000049],
              [9.941730500000176, 2.189820289000068],
              [9.939610480000113, 2.191520453000067],
              [9.938411712, 2.191571236000073],
              [9.938413619000073, 2.191026450000152],
              [9.938414574000092, 2.190749168000082],
              [9.938263892000066, 2.190354586000126],
              [9.93776607600006, 2.189047813000116],
              [9.937821389000135, 2.186740875000169],
              [9.937829972000031, 2.186362744000064],
              [9.93652534600011, 2.185590983000054],
              [9.936351775000048, 2.184705257000132],
              [9.936310768000112, 2.184494257000097],
              [9.936279297000056, 2.18409180600014],
              [9.936154366000096, 2.182520867000051],
              [9.935907364000116, 2.182290316000092],
              [9.935447693000185, 2.18186068600005],
              [9.933703423000111, 2.182238341000129],
              [9.932502747000058, 2.182946682000022],
              [9.932430267000086, 2.183069945000057],
              [9.931954384000051, 2.183876515000179],
              [9.931925773000103, 2.184906959000159],
              [9.931893349000063, 2.186068535000175],
              [9.931400298000028, 2.186888934000081],
              [9.929655076000074, 2.187431097000115],
              [9.926271438000072, 2.189282894000087],
              [9.922177316000045, 2.191625356000145],
              [9.919990539000025, 2.193919421000146],
              [9.920040131000064, 2.195399285000178],
              [9.91998863300006, 2.195705652000015],
              [9.919761658000027, 2.197042465000095],
              [9.919108390000133, 2.197316647000037],
              [9.918725967000057, 2.197477340000091],
              [9.917201042999977, 2.197307825000166],
              [9.914643288000036, 2.196476936000067],
              [9.914073944000165, 2.196452617000091],
              [9.911865235000107, 2.19635772800018],
              [9.909907341000121, 2.1954195510001],
              [9.908001899, 2.195138931000145],
              [9.905765534000182, 2.195843696000111],
              [9.902820587000122, 2.197039127000153],
              [9.902379036000127, 2.198626757000056],
              [9.902236939000147, 2.198945284000047],
              [9.901720047000026, 2.200104236000186],
              [9.898774146999983, 2.201354504000051],
              [9.897463798000047, 2.202226878000147],
              [9.897341727000139, 2.202538490000165],
              [9.896969795000075, 2.203485489000059],
              [9.896626473000083, 2.204141378000088],
              [9.896610260000102, 2.204173089000108],
              [9.896311759000184, 2.20474362300007],
              [9.896402358000103, 2.205326318000061],
              [9.896409036000136, 2.20537090300013],
              [9.89645957900018, 2.205694915000095],
              [9.896524429000181, 2.206114292000052],
              [9.896841049000102, 2.209019899000055],
              [9.896632194000119, 2.209645033000072],
              [9.896456718000025, 2.210169315000144],
              [9.894764901000087, 2.210568429000091],
              [9.894166946000098, 2.210709573000088],
              [9.891550063000182, 2.211084128000039],
              [9.890185357000064, 2.212010861000067],
              [9.889851571000179, 2.213927746000138],
              [9.889721870000187, 2.215240955000184],
              [9.889593123999987, 2.216548681],
              [9.889570236000054, 2.21677637200014],
              [9.888528824000048, 2.218635797000047],
              [9.888325692000137, 2.218802692000111],
              [9.888243676000116, 2.218869925000035],
              [9.888064385000064, 2.21901750699999],
              [9.887599945000034, 2.219399930000122],
              [9.886617660000013, 2.218542100000036],
              [9.886024474000124, 2.218024253000067],
              [9.885411263000151, 2.217825651000169],
              [9.884827613000141, 2.21763658500015],
              [9.884081841000068, 2.217555046000086],
              [9.882758141000181, 2.217410087000076],
              [9.881831170000112, 2.217735767000022],
              [9.880578994000075, 2.217457534000175],
              [9.878835678000087, 2.21756100600004],
              [9.878487586000063, 2.217747927000119],
              [9.878126143999964, 2.217941999],
              [9.877738953000176, 2.219639541000163],
              [9.877947808000158, 2.222160817000031],
              [9.878148078000038, 2.222310066000034],
              [9.878627778000123, 2.222668171000066],
              [9.878980637000097, 2.222931623000022],
              [9.879193307000094, 2.224521637000123],
              [9.879295349000188, 2.226439952000135],
              [9.878528595000148, 2.227752447000114],
              [9.878330231000064, 2.228970528000104],
              [9.878305435000186, 2.229121685000109],
              [9.878182412, 2.229487658000096],
              [9.877590179000151, 2.231256248000022],
              [9.874862671000074, 2.23228788400013],
              [9.87224674100014, 2.232497931000182],
              [9.871316910000019, 2.232702256000152],
              [9.867831229000046, 2.233468772000151],
              [9.864340783000046, 2.234607219999987],
              [9.862894058000165, 2.236534358000142],
              [9.862699509000152, 2.236793280000029],
              [9.862145423000129, 2.237875461000158],
              [9.861328125, 2.239473581000027],
              [9.860850335000123, 2.23981451899999],
              [9.859416962000069, 2.240836859000069],
              [9.857625008000127, 2.241114855000149],
              [9.85685634500004, 2.241234065000072],
              [9.856309892000013, 2.241318942000078],
              [9.853040696000051, 2.241636037000092],
              [9.852053642000101, 2.24197983800002],
              [9.850859642000103, 2.242395640000154],
              [9.8489847190001, 2.243016719000082],
              [9.848404884000047, 2.243208886000105],
              [9.846112251000136, 2.244625330000076],
              [9.845705985000109, 2.245477677000167],
              [9.845069885000044, 2.246813537000037],
              [9.844777107000141, 2.247884512000098],
              [9.844758033000176, 2.247952700000042],
              [9.844020844, 2.25064563699999],
              [9.84336948300006, 2.252477170000134],
              [9.842756272000031, 2.254202842999973],
              [9.842200278, 2.257378817000074],
              [9.841364861000102, 2.259647846000121],
              [9.840769768000143, 2.261264324000081],
              [9.840338706000182, 2.26271677],
              [9.839502334000088, 2.265533924000067],
              [9.838476180999976, 2.269058942000015],
              [9.837350846000049, 2.272923470000023],
              [9.835042953000141, 2.278613807000056],
              [9.834218979000184, 2.280473947000132],
              [9.83246898699997, 2.282495022000092],
              [9.832081794000033, 2.284082651000119],
              [9.830044746000056, 2.290157556000111],
              [9.82992839799999, 2.29218459100008],
              [9.830093383000076, 2.292824268000118],
              [9.830466271000034, 2.294268608000095],
              [9.831247330000053, 2.29592442600017],
              [9.831305503000181, 2.29604864200013],
              [9.831708909000156, 2.296903372000145],
              [9.833005905000164, 2.299757481000142],
              [9.833599090000121, 2.301622630000168],
              [9.833586691999983, 2.305074692000176],
              [9.833197594000126, 2.307534217000182],
              [9.833084106000172, 2.308250904000033],
              [9.833664893000162, 2.313349009999968],
              [9.834000587000048, 2.315939426000114],
              [9.834566116000019, 2.320311308000157],
              [9.835419655000067, 2.325246095000182],
              [9.835247993000053, 2.32749200000012],
              [9.833985328000097, 2.330172301000118],
              [9.830590249000125, 2.335036516000116],
              [9.828726769000127, 2.338152885000113],
              [9.826698304000104, 2.34159755800016],
              [9.825551033000124, 2.342524767000043],
              [9.825216293000096, 2.34281802300012],
              [9.823309898000048, 2.344488859000023],
              [9.821337700000129, 2.347550154999965],
              [9.821264267000174, 2.347920180000131],
              [9.821166993000077, 2.348406553000132],
              [9.820706368000117, 2.349050760000068],
              [9.820130349000124, 2.351098776000072],
              [9.819551467, 2.35362362900014],
              [9.81959152100012, 2.355721473000187],
              [9.819390296999984, 2.358819961000052],
              [9.818814277000115, 2.360867977000055],
              [9.818666458000052, 2.362393141000098],
              [9.81899261600006, 2.363919974000055],
              [9.819977760000029, 2.366689205000114],
              [9.821391106000078, 2.369030714000132],
              [9.822759627000096, 2.370465994000028],
              [9.822659493000117, 2.371991395000066],
              [9.822649955000031, 2.374423027000148],
              [9.822638512000083, 2.377474547000077],
              [9.822250366000105, 2.37999987600017],
              [9.821478845000172, 2.38342976600012],
              [9.821135520999974, 2.386527777000026],
              [9.821593285, 2.3908205030001],
              [9.821482658000036, 2.395111084000121],
              [9.821382523000182, 2.39654111800013],
              [9.820324898000138, 2.400542021000149],
              [9.820128441000122, 2.40235304800018],
              [9.820024491000083, 2.404593706000071],
              [9.819543839000119, 2.406451225000069],
              [9.819252015000132, 2.408262015000105],
              [9.81933975200019, 2.41021704700006],
              [9.818996429000094, 2.413362503999963],
              [9.818514823000044, 2.415315390000103],
              [9.817418099000065, 2.417075395000154],
              [9.817172050000067, 2.419363022000141],
              [9.8176298140001, 2.423560382000176],
              [9.818051339000078, 2.425040006000188],
              [9.819609642000103, 2.426524163000181],
              [9.82136249500013, 2.426673890000018],
              [9.822924615000034, 2.427347421999968],
              [9.824055671, 2.429116012000122],
              [9.824617387000103, 2.430834532],
              [9.825078964000056, 2.434173822000105],
              [9.825346946000025, 2.438418388000116],
              [9.825091362000023, 2.443233014000043],
              [9.824563027000067, 2.445185662000142],
              [9.824175834000073, 2.447282076000022],
              [9.824071884000034, 2.449665547000166],
              [9.823734283000078, 2.450999260000117],
              [9.823110581000151, 2.453142405000051],
              [9.822910309000122, 2.455906868000113],
              [9.822713852000106, 2.457574845000181],
              [9.822187423000173, 2.458860159000096],
              [9.820946693000167, 2.461000919000128],
              [9.821082116000071, 2.46262240499999],
              [9.821410179000168, 2.463815688000068],
              [9.822063447, 2.466392756000118],
              [9.822715760000108, 2.469399215000124],
              [9.822844506000081, 2.472737313000096],
              [9.822693824000055, 2.474977494000086],
              [9.821972848000087, 2.477406263000034],
              [9.821015358000068, 2.480024815000149],
              [9.820246697000187, 2.482548713000085],
              [9.820334434000074, 2.484408618000032],
              [9.819372176, 2.487837554000066],
              [9.818319321000047, 2.49055123300019],
              [9.81778430900016, 2.494077206000156],
              [9.8171596520001, 2.496124744999975],
              [9.816582680000124, 2.498172760000102],
              [9.815906524000127, 2.501316785000086],
              [9.815279007000186, 2.504222632000051],
              [9.815080642, 2.506319762000032],
              [9.815643311000144, 2.50803828300019],
              [9.815930367000078, 2.50830721799997],
              [9.816870689000098, 2.509187699000108],
              [9.818955421000055, 2.509672642000169],
              [9.820229531000166, 2.511012792000031],
              [9.821455956000136, 2.512591124000039],
              [9.822440147000123, 2.515455723],
              [9.822807312, 2.518556356000147],
              [9.822604180000099, 2.521988392000083],
              [9.822305679000181, 2.525467635000098],
              [9.821490288000121, 2.527800561000049],
              [9.821390153000095, 2.529230596000161],
              [9.821241379000014, 2.530851127000176],
              [9.821182251000096, 2.533663989000047],
              [9.821265220000157, 2.536763429000189],
              [9.822059632000162, 2.539722682000161],
              [9.822099686000115, 2.541582347000087],
              [9.821844102000057, 2.546158314000081],
              [9.821112633000041, 2.551209211000128],
              [9.821007728000154, 2.553640605],
              [9.821088791000022, 2.557121278000182],
              [9.821399688999975, 2.562414886000113],
              [9.821900367000012, 2.567518712000151],
              [9.822599412000045, 2.570382119000044],
              [9.823732375000077, 2.571769477000032],
              [9.824964523000062, 2.571679115000052],
              [9.825719833000051, 2.5725405230001],
              [9.827275276000023, 2.57493066700016],
              [9.828361512000015, 2.575888872000121],
              [9.829349518000072, 2.577609300000177],
              [9.829815865, 2.579566002000036],
              [9.830042839000157, 2.582189322000033],
              [9.829936028000077, 2.585192681000137],
              [9.830249786000138, 2.589580535999971],
              [9.830997468000021, 2.592110635000154],
              [9.831512452000141, 2.593829155000094],
              [9.83269024000009, 2.595645666000166],
              [9.83372402100008, 2.597795486000052],
              [9.833956719000128, 2.598940850000133],
              [9.834139823000044, 2.600658178000117],
              [9.835079192000023, 2.602807521000102],
              [9.835970879000172, 2.605004549000114],
              [9.83619976, 2.606912613000077],
              [9.836573600000065, 2.60829687100005],
              [9.836708068000178, 2.610252380000134],
              [9.836935997000126, 2.612446548000094],
              [9.837308884000152, 2.614069224000104],
              [9.837591172000032, 2.614547013999982],
              [9.838816641999983, 2.616268635000097],
              [9.839522363000071, 2.617654324000057],
              [9.839610099000026, 2.619323492000149],
              [9.839568138, 2.61990237200007],
              [9.839509965000104, 2.620705843000053],
              [9.839264870000136, 2.622611999000185],
              [9.838595390000023, 2.623991966000062],
              [9.838705062000031, 2.625793458000089],
              [9.838666915000147, 2.625797749000185],
              [9.838726043000065, 2.626767636000068],
              [9.839145660000042, 2.628485679000164],
              [9.839470863000031, 2.630203725000058],
              [9.839321136000024, 2.631871938000131],
            ],
          ],
        },
      },
    ],
  },
  30: {
    type: "FeatureCollection",
    name: "benin_country",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: { GID_0: "BEN", COUNTRY: "Benin" },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [1.786445079552151, 6.33282916912198],
                [1.7827, 6.3391],
                [1.7878, 6.3439],
                [1.7894, 6.3499],
                [1.7819, 6.374],
                [1.7724, 6.3803],
                [1.7708, 6.3917],
                [1.7743, 6.3942],
                [1.7692, 6.3992],
                [1.7695, 6.4115],
                [1.7753, 6.4128],
                [1.7756, 6.4176],
                [1.7775, 6.4176],
                [1.7806, 6.4213],
                [1.7808, 6.4268],
                [1.7764, 6.4294],
                [1.7728, 6.426],
                [1.7727, 6.4259],
                [1.7648, 6.4272],
                [1.7577, 6.4336],
                [1.7539, 6.4452],
                [1.7531, 6.4505],
                [1.7544, 6.4551],
                [1.753, 6.4597],
                [1.7508, 6.4641],
                [1.7407, 6.4748],
                [1.7363, 6.4744],
                [1.7339, 6.4764],
                [1.7376, 6.481],
                [1.7352, 6.486],
                [1.7303, 6.4876],
                [1.729, 6.4961],
                [1.7259, 6.5018],
                [1.7226, 6.5016],
                [1.7195, 6.4954],
                [1.7125, 6.5003],
                [1.7059, 6.4976],
                [1.6956, 6.5004],
                [1.6942, 6.5097],
                [1.6969, 6.513],
                [1.7024, 6.513],
                [1.7019, 6.5363],
                [1.6957, 6.5428],
                [1.6985, 6.5481],
                [1.6963, 6.5534],
                [1.6877, 6.5536],
                [1.684, 6.5606],
                [1.6708, 6.556],
                [1.6684, 6.5579],
                [1.6664, 6.5634],
                [1.6742, 6.573],
                [1.6757, 6.5796],
                [1.6742, 6.5831],
                [1.6702, 6.5855],
                [1.6627, 6.5842],
                [1.6562, 6.5778],
                [1.6436, 6.5808],
                [1.641, 6.5831],
                [1.6366, 6.6005],
                [1.6331, 6.6027],
                [1.627, 6.6032],
                [1.6218, 6.6013],
                [1.6124, 6.6109],
                [1.613, 6.6166],
                [1.6102, 6.6249],
                [1.603, 6.6303],
                [1.603, 6.6369],
                [1.6082, 6.6389],
                [1.6115, 6.6416],
                [1.6106, 6.6446],
                [1.6038, 6.6663],
                [1.5936, 6.6754],
                [1.5902, 6.6842],
                [1.5777, 6.6857],
                [1.5738, 6.6889],
                [1.5765, 6.6938],
                [1.5812, 6.6933],
                [1.5847, 6.7015],
                [1.5897, 6.7029],
                [1.5922, 6.7062],
                [1.591, 6.7172],
                [1.5929, 6.726],
                [1.6001, 6.726],
                [1.6195, 6.7415],
                [1.6209, 6.7456],
                [1.6181, 6.7508],
                [1.6206, 6.7555],
                [1.6259, 6.7598],
                [1.6221, 6.7646],
                [1.6083, 6.7704],
                [1.6086, 6.7776],
                [1.6125, 6.7894],
                [1.6134, 6.7914],
                [1.6113, 6.7982],
                [1.6089, 6.8],
                [1.5986, 6.8006],
                [1.5969, 6.8064],
                [1.5989, 6.8126],
                [1.615, 6.8254],
                [1.6133, 6.8288],
                [1.6037, 6.8271],
                [1.603298601398601, 6.82756993006993],
                [1.6032, 6.8325],
                [1.6024, 6.8413],
                [1.603295177956372, 6.827573938002296],
                [1.5996, 6.8319],
                [1.6019, 6.8528],
                [1.5946, 6.8676],
                [1.5963, 6.8734],
                [1.6066, 6.8834],
                [1.6039, 6.8906],
                [1.6056, 6.8971],
                [1.6052, 6.906],
                [1.5928, 6.9101],
                [1.5825, 6.9159],
                [1.5767, 6.9221],
                [1.5757, 6.9465],
                [1.5677, 6.9606],
                [1.5591, 6.9711],
                [1.5596, 6.9847],
                [1.5576, 6.998],
                [1.5874, 6.9976],
                [1.5874, 6.9948],
                [1.591, 6.994],
                [1.6074, 6.9956],
                [1.6416, 6.994],
                [1.6375, 7.1255],
                [1.6376, 7.2058],
                [1.6361, 7.206],
                [1.6376, 7.2059],
                [1.6357, 7.4166],
                [1.644, 7.4857],
                [1.6482, 7.4924],
                [1.6543, 7.4944],
                [1.6755, 7.4944],
                [1.6774, 7.4976],
                [1.6755, 7.5066],
                [1.6704, 7.513],
                [1.6688, 7.5181],
                [1.6593, 7.5213],
                [1.6495, 7.5482],
                [1.6508, 7.6134],
                [1.6369, 7.6414],
                [1.6315, 7.6613],
                [1.6309, 7.6766],
                [1.6359, 7.7965],
                [1.6353, 7.8103],
                [1.6296, 7.8266],
                [1.6322, 7.8862],
                [1.634, 7.8969],
                [1.6327, 7.9173],
                [1.634, 7.921],
                [1.636, 7.9915],
                [1.6413, 8.3004],
                [1.6369, 8.3397],
                [1.6304, 8.3437],
                [1.6253, 8.3516],
                [1.6268, 8.3799],
                [1.6332, 8.404],
                [1.6373, 8.4104],
                [1.6437, 8.4132],
                [1.6461, 8.4172],
                [1.6453, 8.4241],
                [1.6397, 8.4325],
                [1.6464, 8.4698],
                [1.6505, 8.4783],
                [1.6492, 8.4908],
                [1.6375, 8.5089],
                [1.6327, 8.5923],
                [1.6286, 8.6507],
                [1.6317, 8.6795],
                [1.6336, 8.7961],
                [1.6314, 8.8209],
                [1.629, 8.8305],
                [1.6327, 8.8603],
                [1.6221, 8.9552],
                [1.6259, 8.9723],
                [1.6238, 8.9747],
                [1.63, 8.999],
                [1.6245, 9.0421],
                [1.6156, 9.0627],
                [1.6177, 9.0716],
                [1.6046, 9.0993],
                [1.5981, 9.111],
                [1.579, 9.1319],
                [1.5594, 9.1699],
                [1.5509, 9.1767],
                [1.5263, 9.1904],
                [1.496, 9.2209],
                [1.4852, 9.2343],
                [1.4754, 9.2408],
                [1.462, 9.2547],
                [1.4599, 9.2621],
                [1.4502, 9.2789],
                [1.4445, 9.2842],
                [1.437, 9.293],
                [1.4311, 9.2965],
                [1.4214, 9.3069],
                [1.4079, 9.335],
                [1.4031, 9.355],
                [1.4027, 9.3815],
                [1.3999, 9.3951],
                [1.4019, 9.4055],
                [1.3999, 9.4223],
                [1.4003, 9.4443],
                [1.3983, 9.4499],
                [1.3948, 9.48],
                [1.3898, 9.4972],
                [1.3654, 9.4856],
                [1.3642, 9.4858],
                [1.3607, 9.487],
                [1.3585, 9.4902],
                [1.3593, 9.5078],
                [1.3477, 9.5366],
                [1.3425, 9.5458],
                [1.3666, 9.6115],
                [1.3666, 9.6499],
                [1.3698, 9.6616],
                [1.3649, 9.6886],
                [1.3633, 9.6972],
                [1.3668, 9.7508],
                [1.3664, 9.8232],
                [1.3639, 9.8858],
                [1.3669, 9.9741],
                [1.3661, 10.0023],
                [1.3641, 10.0121],
                [1.0, 10.22],
                [0.9968, 10.2152],
                [0.953, 10.252],
                [0.8597, 10.3209],
                [0.8235, 10.351],
                [0.7743, 10.3851],
                [0.7758, 10.3984],
                [0.7747, 10.412],
                [0.7824, 10.4836],
                [0.7824, 10.5026],
                [0.7835, 10.5126],
                [0.7874, 10.5195],
                [0.7917, 10.5356],
                [0.8014, 10.5589],
                [0.8065, 10.5812],
                [0.8082, 10.6153],
                [0.8127, 10.6914],
                [0.815, 10.7046],
                [0.8197, 10.7108],
                [0.8235, 10.7193],
                [0.8254, 10.7274],
                [0.8368, 10.7548],
                [0.868, 10.795],
                [0.8737, 10.8064],
                [0.885, 10.8615],
                [0.9008, 10.8869],
                [0.8911, 10.913],
                [0.8902, 10.9202],
                [0.8996, 10.9448],
                [0.9002, 10.9541],
                [0.9065, 10.9729],
                [0.9122, 10.9965],
                [0.9216, 11.0175],
                [0.9489, 11.0358],
                [0.9593, 11.045],
                [0.9578, 11.0445],
                [0.963, 11.0495],
                [0.9627, 11.0518],
                [0.9651, 11.052],
                [0.9699, 11.058],
                [0.9713, 11.0644],
                [0.9701, 11.0745],
                [0.9719, 11.0822],
                [0.9786, 11.0905],
                [0.9817, 11.0907],
                [0.9842, 11.0889],
                [0.9892, 11.0804],
                [0.9992, 11.0857],
                [1.0092, 11.0857],
                [1.0131, 11.0834],
                [1.0164, 11.0793],
                [1.0221, 11.0632],
                [1.0298, 11.0527],
                [1.0351, 11.0495],
                [1.0431, 11.0491],
                [1.0649, 11.0588],
                [1.0696, 11.059],
                [1.0745, 11.0538],
                [1.0847, 11.0517],
                [1.091, 11.0487],
                [1.1001, 11.0416],
                [1.1102, 11.0304],
                [1.112912230215827, 11.034187769784173],
                [1.116, 11.0385],
                [1.1153, 11.0412],
                [1.0996, 11.0566],
                [1.0998, 11.0626],
                [1.0935, 11.069],
                [1.0965, 11.0784],
                [1.093, 11.0858],
                [1.0865, 11.0899],
                [1.0902, 11.0998],
                [1.0846, 11.1051],
                [1.0866, 11.1133],
                [1.0831, 11.1177],
                [1.0782, 11.1188],
                [1.0738, 11.1259],
                [1.0672, 11.1289],
                [1.0682, 11.1234],
                [1.0647, 11.1188],
                [1.06, 11.1197],
                [1.0509, 11.1277],
                [1.0546, 11.1319],
                [1.0528, 11.1394],
                [1.0555, 11.1431],
                [1.0604, 11.1457],
                [1.0914, 11.1453],
                [1.097, 11.1474],
                [1.1118, 11.1663],
                [1.1183, 11.1716],
                [1.1412, 11.1737],
                [1.147, 11.1639],
                [1.1505, 11.1614],
                [1.1559, 11.1611],
                [1.1601, 11.1644],
                [1.1505, 11.1756],
                [1.1525, 11.1814],
                [1.1497, 11.1889],
                [1.1525, 11.1984],
                [1.1485, 11.2032],
                [1.1515, 11.2076],
                [1.1494, 11.2108],
                [1.1557, 11.2191],
                [1.1564, 11.2239],
                [1.1536, 11.2276],
                [1.1433, 11.2312],
                [1.1363, 11.237],
                [1.129, 11.2517],
                [1.1292, 11.2565],
                [1.1364, 11.2659],
                [1.142, 11.2703],
                [1.1478, 11.2708],
                [1.1595, 11.2612],
                [1.1686, 11.2607],
                [1.1768, 11.2624],
                [1.1812, 11.2617],
                [1.1852, 11.2587],
                [1.191, 11.2585],
                [1.2061, 11.2608],
                [1.212, 11.2583],
                [1.2229, 11.2602],
                [1.228, 11.2593],
                [1.2483, 11.2456],
                [1.2521, 11.2453],
                [1.2604, 11.2543],
                [1.2739, 11.2599],
                [1.2763, 11.2642],
                [1.2734, 11.2737],
                [1.2741, 11.2833],
                [1.2708, 11.2895],
                [1.2659, 11.29],
                [1.2636, 11.2884],
                [1.2638, 11.2858],
                [1.2695, 11.2801],
                [1.2667, 11.2764],
                [1.2618, 11.2771],
                [1.2583, 11.281],
                [1.2548, 11.2899],
                [1.2526, 11.312],
                [1.2556, 11.3182],
                [1.2591, 11.321],
                [1.2658, 11.3212],
                [1.2747, 11.3171],
                [1.2829, 11.3102],
                [1.2904, 11.2932],
                [1.3016, 11.2919],
                [1.3037, 11.3004],
                [1.3081, 11.3011],
                [1.3116, 11.2993],
                [1.3146, 11.2926],
                [1.3251, 11.2899],
                [1.3314, 11.3012],
                [1.3409, 11.3081],
                [1.3409, 11.3113],
                [1.3318, 11.3235],
                [1.332, 11.3315],
                [1.3264, 11.3347],
                [1.3205, 11.349],
                [1.3217, 11.3531],
                [1.318, 11.3591],
                [1.32, 11.3628],
                [1.3233, 11.3637],
                [1.3352, 11.3623],
                [1.3406, 11.364],
                [1.3443, 11.367],
                [1.3436, 11.3718],
                [1.3519, 11.3814],
                [1.351, 11.3872],
                [1.3538, 11.3904],
                [1.3568, 11.3902],
                [1.3594, 11.3851],
                [1.362, 11.3847],
                [1.3727, 11.3902],
                [1.3752, 11.3974],
                [1.3883, 11.3995],
                [1.3906, 11.4027],
                [1.3899, 11.4066],
                [1.3836, 11.4096],
                [1.3798, 11.4234],
                [1.3844, 11.4422],
                [1.4077, 11.4542],
                [1.4143, 11.4515],
                [1.4192, 11.4524],
                [1.4222, 11.4559],
                [1.4257, 11.4662],
                [1.431, 11.4662],
                [1.4345, 11.4722],
                [1.4401, 11.4738],
                [1.4431, 11.4722],
                [1.4462, 11.4649],
                [1.4515, 11.4626],
                [1.4602, 11.4635],
                [1.4679, 11.4665],
                [1.4758, 11.4541],
                [1.4802, 11.4546],
                [1.4947, 11.4659],
                [1.5012, 11.4663],
                [1.5094, 11.4565],
                [1.5185, 11.4544],
                [1.5239, 11.4498],
                [1.5318, 11.4473],
                [1.5372, 11.4478],
                [1.5413, 11.4506],
                [1.5506, 11.4706],
                [1.5544, 11.4715],
                [1.5567, 11.4699],
                [1.56, 11.4623],
                [1.5679, 11.4572],
                [1.5684, 11.4469],
                [1.5724, 11.4444],
                [1.5789, 11.4435],
                [1.5803, 11.4393],
                [1.5745, 11.4299],
                [1.5831, 11.4278],
                [1.5899, 11.4212],
                [1.5897, 11.4032],
                [1.593, 11.3982],
                [1.5997, 11.3947],
                [1.6137, 11.3941],
                [1.6205, 11.3904],
                [1.6251, 11.3909],
                [1.6375, 11.4015],
                [1.6428, 11.4024],
                [1.6489, 11.3966],
                [1.6631, 11.3964],
                [1.6703, 11.4006],
                [1.6834, 11.4142],
                [1.6876, 11.4144],
                [1.693, 11.4093],
                [1.6997, 11.408],
                [1.7074, 11.4153],
                [1.7141, 11.4291],
                [1.7174, 11.4298],
                [1.7221, 11.4232],
                [1.7293, 11.4239],
                [1.7337, 11.4218],
                [1.7365, 11.4174],
                [1.7398, 11.4165],
                [1.748, 11.4202],
                [1.7524, 11.4167],
                [1.7557, 11.4172],
                [1.7585, 11.4223],
                [1.7638, 11.4225],
                [1.7736, 11.4193],
                [1.7773, 11.4221],
                [1.7832, 11.4218],
                [1.7874, 11.4248],
                [1.7953, 11.4264],
                [1.7978, 11.4317],
                [1.8025, 11.4359],
                [1.8072, 11.4365],
                [1.8216, 11.4329],
                [1.8282, 11.4343],
                [1.8356, 11.4382],
                [1.8417, 11.4444],
                [1.8456, 11.4446],
                [1.8477, 11.4384],
                [1.8673, 11.4453],
                [1.8727, 11.4458],
                [1.8842, 11.438],
                [1.8924, 11.4396],
                [1.9125, 11.4307],
                [1.9563, 11.4202],
                [1.9589, 11.4216],
                [1.982, 11.42],
                [2.0104, 11.4233],
                [2.0192, 11.4277],
                [2.0732, 11.4837],
                [2.0907, 11.4989],
                [2.1447, 11.5542],
                [2.1573, 11.5696],
                [2.1663, 11.577],
                [2.1854, 11.5972],
                [2.1931, 11.6007],
                [2.2062, 11.601],
                [2.2188, 11.6097],
                [2.2575, 11.6479],
                [2.3057, 11.6744],
                [2.3096, 11.6808],
                [2.3048, 11.6829],
                [2.3038, 11.6859],
                [2.3067, 11.6969],
                [2.3008, 11.7084],
                [2.2992, 11.7208],
                [2.3025, 11.7254],
                [2.3037, 11.7353],
                [2.314, 11.7461],
                [2.3191, 11.7562],
                [2.3289, 11.7612],
                [2.3383, 11.7702],
                [2.3404, 11.7854],
                [2.35, 11.7893],
                [2.3724, 11.7931],
                [2.3721, 11.798],
                [2.3682, 11.8021],
                [2.3701, 11.8069],
                [2.3677, 11.8134],
                [2.3701, 11.8182],
                [2.3811, 11.8237],
                [2.3832, 11.8276],
                [2.3825, 11.8299],
                [2.378, 11.8308],
                [2.3755, 11.8334],
                [2.3743, 11.8371],
                [2.3792, 11.8541],
                [2.3891, 11.8628],
                [2.4003, 11.8671],
                [2.4026, 11.8706],
                [2.4024, 11.8745],
                [2.3945, 11.8892],
                [2.3971, 11.8955],
                [2.4024, 11.8961],
                [2.4048, 11.898],
                [2.4054, 11.9016],
                [2.4114, 11.9063],
                [2.3997, 11.9134],
                [2.4023, 11.9197],
                [2.3918, 11.9308],
                [2.4027, 11.9402],
                [2.4067, 11.9505],
                [2.4125, 11.9494],
                [2.42, 11.9552],
                [2.4312, 11.9554],
                [2.4336, 11.9588],
                [2.431, 11.9701],
                [2.4327, 11.9752],
                [2.4371, 11.9765],
                [2.4427, 11.9823],
                [2.4465, 11.982],
                [2.4551, 11.9758],
                [2.4588, 11.9763],
                [2.4657, 11.9846],
                [2.4684, 11.9946],
                [2.4637, 12.0238],
                [2.46, 12.0321],
                [2.445, 12.0516],
                [2.4373, 12.0668],
                [2.4228, 12.0896],
                [2.412, 12.1112],
                [2.3998, 12.1427],
                [2.3925, 12.1827],
                [2.3855, 12.2355],
                [2.3873, 12.2422],
                [2.4037, 12.2494],
                [2.4255, 12.2514],
                [2.4306, 12.2554],
                [2.4346, 12.2632],
                [2.4383, 12.2628],
                [2.4467, 12.2564],
                [2.4525, 12.2541],
                [2.4571, 12.2557],
                [2.4597, 12.253],
                [2.464, 12.2533],
                [2.4699, 12.2724],
                [2.4742, 12.2734],
                [2.4946, 12.2689],
                [2.4996, 12.2758],
                [2.5057, 12.2774],
                [2.508, 12.2887],
                [2.5111, 12.2937],
                [2.5143, 12.2944],
                [2.5225, 12.2854],
                [2.5302, 12.2817],
                [2.5361, 12.2746],
                [2.5417, 12.2772],
                [2.5416, 12.2743],
                [2.5464, 12.2741],
                [2.5511, 12.2758],
                [2.554, 12.2797],
                [2.5583, 12.2783],
                [2.5652, 12.2791],
                [2.5672, 12.2811],
                [2.5757, 12.2815],
                [2.5795, 12.2775],
                [2.5926, 12.2983],
                [2.596, 12.2972],
                [2.6018, 12.2999],
                [2.6031, 12.2984],
                [2.6144, 12.3008],
                [2.6294, 12.2951],
                [2.633, 12.2973],
                [2.6405, 12.297],
                [2.6634, 12.3061],
                [2.6727, 12.3046],
                [2.6781, 12.3005],
                [2.6796, 12.2974],
                [2.6851, 12.2944],
                [2.686, 12.2843],
                [2.6841, 12.2801],
                [2.6862, 12.2807],
                [2.7053, 12.3181],
                [2.7159, 12.3493],
                [2.7234, 12.3551],
                [2.7288, 12.3626],
                [2.7329, 12.3622],
                [2.7752, 12.411],
                [2.7946, 12.4184],
                [2.8058, 12.4174],
                [2.8192, 12.4137],
                [2.8325, 12.4075],
                [2.8389, 12.4067],
                [2.8465, 12.4004],
                [2.8725, 12.3878],
                [2.8811, 12.3781],
                [2.8844, 12.3772],
                [2.8869, 12.3707],
                [2.9069, 12.3494],
                [2.9219, 12.3367],
                [2.9272, 12.335],
                [2.9414, 12.3183],
                [2.9444, 12.3167],
                [2.9585, 12.2945],
                [2.9814, 12.2859],
                [2.9936, 12.2766],
                [3.0136, 12.2664],
                [3.0194, 12.2572],
                [3.0253, 12.2408],
                [3.0297, 12.2336],
                [3.0592, 12.2086],
                [3.06, 12.2061],
                [3.0581, 12.2017],
                [3.0597, 12.1961],
                [3.0719, 12.1836],
                [3.0822, 12.1767],
                [3.1008, 12.1725],
                [3.1139, 12.1628],
                [3.125, 12.1575],
                [3.1278, 12.1544],
                [3.1272, 12.1403],
                [3.1686, 12.1136],
                [3.1764, 12.1058],
                [3.1889, 12.0836],
                [3.2061, 12.0692],
                [3.2203, 12.0608],
                [3.2303, 12.0503],
                [3.2325, 12.0475],
                [3.2347, 12.0383],
                [3.2386, 12.0335],
                [3.2618, 12.0162],
                [3.2606, 12.0116],
                [3.2486, 11.9942],
                [3.2625, 11.9824],
                [3.2688, 11.9729],
                [3.2725, 11.9626],
                [3.2757, 11.9407],
                [3.2831, 11.9342],
                [3.2835, 11.9341],
                [3.2836, 11.9341],
                [3.2856, 11.9341],
                [3.291, 11.9314],
                [3.3021, 11.8941],
                [3.3076, 11.8877],
                [3.3219, 11.8857],
                [3.3254, 11.8878],
                [3.3394, 11.8852],
                [3.3709, 11.891],
                [3.3796, 11.8867],
                [3.3936, 11.8831],
                [3.403, 11.8775],
                [3.4122, 11.8769],
                [3.4183, 11.8786],
                [3.4261, 11.8747],
                [3.4411, 11.8769],
                [3.4472, 11.875],
                [3.4614, 11.8653],
                [3.4722, 11.8647],
                [3.4814, 11.8614],
                [3.4867, 11.8569],
                [3.4939, 11.8433],
                [3.5027, 11.8324],
                [3.5147, 11.8228],
                [3.5211, 11.8147],
                [3.5194, 11.8003],
                [3.5203, 11.7928],
                [3.5228, 11.7878],
                [3.535, 11.7794],
                [3.5419, 11.7781],
                [3.5531, 11.7794],
                [3.5586, 11.7766],
                [3.5606, 11.7702],
                [3.5595, 11.7651],
                [3.5503, 11.7513],
                [3.5484, 11.7438],
                [3.5498, 11.738],
                [3.5551, 11.7304],
                [3.5683, 11.719],
                [3.5711, 11.7197],
                [3.5883, 11.7108],
                [3.6051, 11.697],
                [3.6002, 11.692],
                [3.5916, 11.6746],
                [3.5864, 11.6567],
                [3.5783, 11.6437],
                [3.566, 11.617],
                [3.5613, 11.6016],
                [3.5356, 11.4764],
                [3.5008, 11.3207],
                [3.496, 11.2921],
                [3.551, 11.2472],
                [3.6919, 11.1282],
                [3.7175, 11.1278],
                [3.7259, 11.1241],
                [3.7275, 11.1177],
                [3.7192, 11.0867],
                [3.7238, 11.0556],
                [3.7215, 11.0425],
                [3.7173, 11.0352],
                [3.7169, 11.0243],
                [3.7342, 11.0198],
                [3.7515, 11.0114],
                [3.7595, 10.9972],
                [3.7712, 10.9308],
                [3.7691, 10.8981],
                [3.7492, 10.8375],
                [3.7495, 10.8247],
                [3.7522, 10.8159],
                [3.7722, 10.7852],
                [3.7852, 10.7535],
                [3.7975, 10.7352],
                [3.8127, 10.7232],
                [3.8331, 10.7123],
                [3.841, 10.7049],
                [3.844, 10.6929],
                [3.8459, 10.6711],
                [3.848, 10.6677],
                [3.8517, 10.6058],
                [3.8484, 10.5888],
                [3.8318, 10.5686],
                [3.8062, 10.503],
                [3.801, 10.4853],
                [3.8022, 10.4552],
                [3.786, 10.4054],
                [3.7756, 10.4107],
                [3.7693, 10.4158],
                [3.7607, 10.4186],
                [3.6951, 10.4538],
                [3.6877, 10.4564],
                [3.6654, 10.4425],
                [3.6461, 10.4351],
                [3.6377, 10.4276],
                [3.6316, 10.4168],
                [3.6347, 10.3949],
                [3.6339, 10.3899],
                [3.6263, 10.3736],
                [3.6144, 10.3557],
                [3.5842, 10.2784],
                [3.5846, 10.2751],
                [3.5977, 10.2418],
                [3.6125, 10.2217],
                [3.6232, 10.2141],
                [3.6463, 10.1894],
                [3.6553, 10.1841],
                [3.6725, 10.1838],
                [3.6781, 10.1783],
                [3.679, 10.1698],
                [3.6759, 10.156],
                [3.6761, 10.1493],
                [3.6628, 10.1069],
                [3.6583, 10.1032],
                [3.6465, 10.1017],
                [3.6292, 10.0932],
                [3.6236, 10.0879],
                [3.6156, 10.0756],
                [3.6116, 10.0624],
                [3.6114, 9.996],
                [3.6023, 9.9574],
                [3.5958, 9.9457],
                [3.5711, 9.9254],
                [3.5642, 9.916],
                [3.5642, 9.8996],
                [3.5623, 9.8951],
                [3.551, 9.882],
                [3.548, 9.8808],
                [3.5387, 9.8688],
                [3.5296, 9.8621],
                [3.5111, 9.8573],
                [3.4988, 9.8583],
                [3.4748, 9.8632],
                [3.4564, 9.8612],
                [3.3566, 9.8257],
                [3.3469, 9.8175],
                [3.3295, 9.7984],
                [3.3234, 9.7769],
                [3.3243, 9.7613],
                [3.3286, 9.7459],
                [3.3401, 9.7209],
                [3.3504, 9.705],
                [3.3521, 9.6974],
                [3.3486, 9.6854],
                [3.3149, 9.6577],
                [3.3096, 9.6575],
                [3.2923, 9.6621],
                [3.2804, 9.6617],
                [3.2716, 9.659],
                [3.2636, 9.6523],
                [3.2559, 9.6397],
                [3.2556, 9.6344],
                [3.2534, 9.6099],
                [3.2498, 9.6025],
                [3.2323, 9.5759],
                [3.2266, 9.5708],
                [3.2161, 9.5538],
                [3.2079, 9.5445],
                [3.1948, 9.5151],
                [3.1861, 9.5069],
                [3.1548, 9.4931],
                [3.1362, 9.4629],
                [3.1301, 9.4326],
                [3.1317, 9.4361],
                [3.1334, 9.4261],
                [3.1518, 9.3757],
                [3.1536, 9.3647],
                [3.1515, 9.3394],
                [3.1515, 9.3392],
                [3.151625984251968, 9.333625196850395],
                [3.1515, 9.3352],
                [3.1515, 9.3351],
                [3.151627131782946, 9.333574418604652],
                [3.1519, 9.3215],
                [3.1519, 9.3214],
                [3.1543, 9.2952],
                [3.1568, 9.2818],
                [3.15, 9.2726],
                [3.1317, 9.2591],
                [3.1264, 9.2522],
                [3.1222, 9.2384],
                [3.1237, 9.1922],
                [3.1013, 9.1522],
                [3.0921, 9.1298],
                [3.0882, 9.1016],
                [3.0835, 9.0979],
                [3.0562, 9.0897],
                [3.0426, 9.0835],
                [3.0351, 9.0828],
                [3.0081, 9.0739],
                [2.9841, 9.0723],
                [2.9641, 9.0795],
                [2.9564, 9.0924],
                [2.9534, 9.0926],
                [2.9126, 9.0874],
                [2.8961, 9.0837],
                [2.8819, 9.073],
                [2.8792, 9.0647],
                [2.8676, 9.0621],
                [2.8544, 9.0645],
                [2.7809, 9.0651],
                [2.7783, 9.054],
                [2.7862, 9.0449],
                [2.7828, 9.0373],
                [2.7824, 9.0317],
                [2.7743, 9.0262],
                [2.7755, 9.0238],
                [2.7832, 9.0219],
                [2.784, 9.0194],
                [2.7826, 9.016],
                [2.7719, 9.0107],
                [2.7721, 9.0074],
                [2.7811, 9.0054],
                [2.7824, 9.0031],
                [2.7778, 8.9949],
                [2.7798, 8.9916],
                [2.7868, 8.9906],
                [2.7886, 8.9877],
                [2.7874, 8.9854],
                [2.7821, 8.9826],
                [2.783, 8.9783],
                [2.7873, 8.9742],
                [2.7888, 8.9703],
                [2.7858, 8.9641],
                [2.7711, 8.9529],
                [2.7712, 8.9528],
                [2.776242372881356, 8.951215254237288],
                [2.7782, 8.9506],
                [2.7818, 8.9452],
                [2.7706, 8.9188],
                [2.7623, 8.9146],
                [2.7581, 8.9079],
                [2.7583, 8.9039],
                [2.7617, 8.9028],
                [2.7704, 8.9047],
                [2.7736, 8.8947],
                [2.7682, 8.8672],
                [2.758, 8.8649],
                [2.757, 8.8635],
                [2.7569, 8.8596],
                [2.7611, 8.8515],
                [2.7581, 8.8301],
                [2.7588, 8.8197],
                [2.7634, 8.8098],
                [2.7611, 8.8054],
                [2.7557, 8.8025],
                [2.7449, 8.7999],
                [2.7403, 8.7952],
                [2.7376, 8.7871],
                [2.7327, 8.7812],
                [2.7291, 8.7748],
                [2.7296, 8.7833],
                [2.7285, 8.7813],
                [2.7284, 8.7727],
                [2.7339, 8.7575],
                [2.7441, 8.744],
                [2.7461, 8.7352],
                [2.7462, 8.7098],
                [2.7504, 8.7003],
                [2.7414, 8.678],
                [2.7462, 8.6666],
                [2.7394, 8.6608],
                [2.7376, 8.6549],
                [2.739, 8.6506],
                [2.7465, 8.6461],
                [2.7491, 8.6404],
                [2.7499, 8.6051],
                [2.75, 8.5779],
                [2.7587, 8.5616],
                [2.7543, 8.5469],
                [2.7497, 8.538],
                [2.7495, 8.5331],
                [2.7619, 8.5225],
                [2.7618, 8.5053],
                [2.7507, 8.4934],
                [2.75, 8.4897],
                [2.7569, 8.4826],
                [2.7491, 8.4728],
                [2.7442, 8.462],
                [2.7407, 8.445],
                [2.7345, 8.4366],
                [2.7345, 8.4361],
                [2.7261, 8.432],
                [2.724914285714286, 8.43194844720497],
                [2.7146, 8.4315],
                [2.7069, 8.4203],
                [2.7113, 8.4055],
                [2.714, 8.3872],
                [2.7087, 8.3696],
                [2.6971, 8.3573],
                [2.6957, 8.3247],
                [2.6982, 8.3072],
                [2.7112, 8.2762],
                [2.7146, 8.2605],
                [2.7185, 8.2536],
                [2.7268, 8.2465],
                [2.733795039224733, 8.23520430702969],
                [2.7209, 8.2464],
                [2.7283, 8.2395],
                [2.733795553759093, 8.235203476151982],
                [2.7403, 8.2247],
                [2.7541, 8.2113],
                [2.7513, 8.2076],
                [2.7502, 8.2008],
                [2.751, 8.19],
                [2.7482, 8.1647],
                [2.744, 8.156],
                [2.7366, 8.1476],
                [2.7322, 8.1389],
                [2.7299, 8.1283],
                [2.7325, 8.1168],
                [2.7305, 8.1049],
                [2.7251, 8.0926],
                [2.724, 8.085],
                [2.7291, 8.0705],
                [2.7288, 8.0668],
                [2.7233, 8.0611],
                [2.7073, 8.0],
                [2.7045, 7.9681],
                [2.6995, 7.9527],
                [2.6955, 7.9308],
                [2.6906, 7.9251],
                [2.6878, 7.9134],
                [2.6758, 7.8932],
                [2.6684, 7.8898],
                [2.6958, 7.8548],
                [2.7063, 7.8327],
                [2.7093, 7.8171],
                [2.7113, 7.8132],
                [2.7203, 7.8109],
                [2.7247, 7.8076],
                [2.7295, 7.7922],
                [2.7324, 7.7722],
                [2.728, 7.7449],
                [2.7301, 7.7048],
                [2.7304, 7.6992],
                [2.7254, 7.648],
                [2.729, 7.6229],
                [2.7281, 7.61],
                [2.7303, 7.5802],
                [2.7362, 7.5652],
                [2.7461, 7.5558],
                [2.7477, 7.547],
                [2.7461, 7.5403],
                [2.7496, 7.5273],
                [2.7548, 7.5213],
                [2.7621, 7.5189],
                [2.7752, 7.5107],
                [2.786, 7.4987],
                [2.7929, 7.4945],
                [2.7982, 7.4826],
                [2.7992, 7.4265],
                [2.7957, 7.4233],
                [2.7858, 7.4187],
                [2.7628, 7.4192],
                [2.757, 7.4172],
                [2.7476, 7.4174],
                [2.7474, 7.366],
                [2.7501, 7.311],
                [2.7495, 7.2612],
                [2.7506, 7.2575],
                [2.7566, 7.251],
                [2.7621, 7.2482],
                [2.7743, 7.2344],
                [2.7836, 7.1824],
                [2.7849, 7.1592],
                [2.7753, 7.1282],
                [2.7673, 7.117],
                [2.7507, 7.1058],
                [2.7451, 7.0991],
                [2.7451, 7.0927],
                [2.7529, 7.0773],
                [2.763, 7.0666],
                [2.7671, 7.0653],
                [2.7818, 7.0537],
                [2.7926, 7.0413],
                [2.7855, 7.0365],
                [2.7751, 7.0335],
                [2.7666, 7.0264],
                [2.7433, 7.0125],
                [2.7236, 6.9905],
                [2.7206, 6.9865],
                [2.7183, 6.9411],
                [2.72, 6.9348],
                [2.7269, 6.9304],
                [2.7325, 6.9299],
                [2.7479, 6.9283],
                [2.7515, 6.9124],
                [2.7505, 6.8851],
                [2.7438, 6.8566],
                [2.7447, 6.8471],
                [2.7492, 6.8393],
                [2.7298, 6.8085],
                [2.728, 6.8017],
                [2.7293, 6.7902],
                [2.7326, 6.7792],
                [2.7392, 6.7653],
                [2.7518, 6.7591],
                [2.7629, 6.7577],
                [2.7787, 6.7592],
                [2.7863, 6.7546],
                [2.7895, 6.7481],
                [2.7897, 6.7389],
                [2.786, 6.7233],
                [2.7866, 6.6948],
                [2.77, 6.6799],
                [2.7608, 6.6742],
                [2.7525, 6.6657],
                [2.7314, 6.6308],
                [2.736, 6.6087],
                [2.7446, 6.5838],
                [2.7531, 6.5705],
                [2.7506, 6.5574],
                [2.7429, 6.5454],
                [2.7293, 6.5344],
                [2.7247, 6.5264],
                [2.7203, 6.5078],
                [2.7174, 6.4707],
                [2.7107, 6.4526],
                [2.7063, 6.4256],
                [2.7083, 6.4094],
                [2.7093, 6.3754],
                [2.6535, 6.3679],
                [2.6488, 6.3685],
                [2.6182, 6.3629],
                [2.6079, 6.3624],
                [2.6068, 6.3637],
                [2.6038, 6.3621],
                [2.5676, 6.3593],
                [2.5526, 6.3593],
                [2.5471, 6.3612],
                [2.5393, 6.3587],
                [2.5363, 6.3599],
                [2.5099, 6.3579],
                [2.5007, 6.3582],
                [2.4985, 6.3596],
                [2.4929, 6.3585],
                [2.491, 6.3604],
                [2.4907, 6.3593],
                [2.4737, 6.3604],
                [2.4649, 6.359],
                [2.464, 6.3563],
                [2.461, 6.3543],
                [2.4574, 6.3554],
                [2.446, 6.3546],
                [2.444, 6.3532],
                [2.444, 6.3499],
                [2.4349, 6.3513],
                [2.4318, 6.3482],
                [2.4226, 6.3482],
                [2.4196, 6.3468],
                [2.4226, 6.3451],
                [2.4265, 6.346],
                [2.4282, 6.3435],
                [2.4232, 6.3449],
                [2.4129, 6.3432],
                [2.404, 6.3457],
                [2.3974, 6.3451],
                [2.3935, 6.3479],
                [2.3899, 6.3474],
                [2.3885, 6.3499],
                [2.3876, 6.3476],
                [2.3637, 6.3496],
                [2.334, 6.3499],
                [2.3285, 6.3482],
                [2.2754, 6.3446],
                [2.246, 6.3435],
                [2.2393, 6.3415],
                [2.2126, 6.3387],
                [2.2115, 6.3399],
                [2.1937, 6.3362],
                [2.1487, 6.3324],
                [2.131, 6.329],
                [2.1288, 6.3299],
                [2.0496, 6.3157],
                [2.0443, 6.3135],
                [2.034, 6.3129],
                [1.9746, 6.3024],
                [1.9582, 6.3015],
                [1.9615, 6.3026],
                [1.9643, 6.3074],
                [1.9907, 6.3071],
                [1.9935, 6.3096],
                [2.0007, 6.311],
                [2.0029, 6.3096],
                [2.0032, 6.3118],
                [1.9932, 6.3107],
                [1.9921, 6.3093],
                [1.9832, 6.309],
                [1.9813, 6.3107],
                [1.9865, 6.3104],
                [1.9935, 6.3146],
                [1.9946, 6.3168],
                [1.9982, 6.3174],
                [1.9979, 6.3185],
                [1.991, 6.316],
                [1.9918, 6.3146],
                [1.9876, 6.3132],
                [1.9871, 6.3115],
                [1.9812, 6.3127],
                [1.9782, 6.3082],
                [1.9776, 6.3107],
                [1.974, 6.3099],
                [1.9721, 6.3118],
                [1.9685, 6.3085],
                [1.9643, 6.311],
                [1.9607, 6.311],
                [1.9571, 6.3096],
                [1.9532, 6.3104],
                [1.9479, 6.3076],
                [1.9401, 6.3129],
                [1.9346, 6.3126],
                [1.9288, 6.3112],
                [1.9257, 6.304],
                [1.9179, 6.3051],
                [1.9157, 6.3026],
                [1.9157, 6.2999],
                [1.9051, 6.2963],
                [1.9021, 6.2979],
                [1.8957, 6.2943],
                [1.8879, 6.2951],
                [1.8804, 6.2924],
                [1.8726, 6.2926],
                [1.8663, 6.2901],
                [1.8557, 6.291],
                [1.8493, 6.2885],
                [1.8499, 6.286],
                [1.8438, 6.2837],
                [1.8393, 6.2849],
                [1.8351, 6.2882],
                [1.8299, 6.2876],
                [1.8354, 6.2862],
                [1.8399, 6.2824],
                [1.8526, 6.2835],
                [1.8507, 6.2821],
                [1.8343, 6.2801],
                [1.7723, 6.2642],
                [1.752, 6.2616],
                [1.6334, 6.2355],
                [1.6303, 6.2349],
                [1.6294, 6.2364],
                [1.6397, 6.2394],
                [1.6567, 6.25],
                [1.6675, 6.2483],
                [1.6725, 6.2503],
                [1.6789, 6.2575],
                [1.6844, 6.2589],
                [1.6939, 6.2558],
                [1.7031, 6.2606],
                [1.7083, 6.2589],
                [1.7147, 6.2594],
                [1.7225, 6.2653],
                [1.7394, 6.2642],
                [1.7472, 6.2703],
                [1.7653, 6.2711],
                [1.7786, 6.2789],
                [1.7972, 6.2789],
                [1.8025, 6.2803],
                [1.8075, 6.2825],
                [1.8075, 6.2864],
                [1.8055, 6.291],
                [1.7956, 6.2936],
                [1.7897, 6.3028],
                [1.7979, 6.3068],
                [1.7984, 6.3135],
                [1.7924, 6.3288],
                [1.787, 6.3319],
                [1.786457586837294, 6.332808226691042],
                [1.7871, 6.3329],
                [1.7881, 6.3339],
                [1.786445079552151, 6.33282916912198],
              ],
              [
                [1.6327, 8.5923],
                [1.6468, 8.5901],
                [1.6572, 8.5896],
                [1.6327, 8.5923],
              ],
              [
                [1.6572, 8.5896],
                [1.6686, 8.5951],
                [1.6734, 8.5979],
                [1.6572, 8.5896],
              ],
              [
                [1.6734, 8.5979],
                [1.6833, 8.6006],
                [1.6751, 8.5989],
                [1.6734, 8.5979],
              ],
              [
                [1.6833, 8.6006],
                [1.6975, 8.6044],
                [1.7029, 8.6061],
                [1.6833, 8.6006],
              ],
              [
                [1.7029, 8.6061],
                [1.7084, 8.605],
                [1.7259, 8.6045],
                [1.7029, 8.6061],
              ],
              [
                [3.1515, 9.3352],
                [3.053, 9.3409],
                [3.0305, 9.3422],
                [3.1515, 9.3352],
              ],
              [
                [2.7327, 8.7812],
                [2.7314, 8.7816],
                [2.7326, 8.7812],
                [2.7327, 8.7812],
              ],
              [
                [2.7209, 8.2464],
                [2.712, 8.2568],
                [2.7152, 8.2518],
                [2.7209, 8.2464],
              ],
              [
                [2.7301, 7.7048],
                [2.7281, 7.6978],
                [2.7264, 7.6902],
                [2.7301, 7.7048],
              ],
              [
                [1.6934, 6.625],
                [1.6925, 6.6254],
                [1.6873, 6.627],
                [1.6934, 6.625],
              ],
              [
                [1.6934, 6.625],
                [1.7169, 6.6152],
                [1.7061, 6.6202],
                [1.6934, 6.625],
              ],
              [
                [1.7564, 6.5514],
                [1.7594, 6.5483],
                [1.7559, 6.5524],
                [1.7564, 6.5514],
              ],
              [
                [1.7594, 6.5483],
                [1.7604, 6.5383],
                [1.7603, 6.5418],
                [1.7598, 6.5461],
                [1.7594, 6.5483],
              ],
              [
                [2.6261, 6.8385],
                [2.6278, 6.8314],
                [2.6261, 6.8451],
                [2.6261, 6.8385],
              ],
              [
                [2.6278, 6.8314],
                [2.6294, 6.8287],
                [2.6343, 6.8211],
                [2.6278, 6.8314],
              ],
              [
                [2.3418, 8.7035],
                [2.3418, 8.7093],
                [2.3414, 8.6991],
                [2.3418, 8.7035],
              ],
              [
                [2.3414, 8.6991],
                [2.3399, 8.6893],
                [2.3385, 8.6737],
                [2.3414, 8.6991],
              ],
              [
                [2.6125, 7.5374],
                [2.6037, 7.5326],
                [2.6206, 7.5415],
                [2.6125, 7.5374],
              ],
              [
                [2.6037, 7.5326],
                [2.6029, 7.5214],
                [2.6029, 7.5061],
                [2.6037, 7.5326],
              ],
              [
                [2.677, 7.4802],
                [2.6705, 7.4842],
                [2.6685, 7.4854],
                [2.677, 7.4802],
              ],
              [
                [2.677, 7.4802],
                [2.6818, 7.477],
                [2.6794, 7.4794],
                [2.677, 7.4802],
              ],
              [
                [2.786485714285759, 9.622914285714174],
                [2.7888, 9.617],
                [2.7894, 9.6155],
                [2.786485714285759, 9.622914285714174],
              ],
              [
                [2.786485714285759, 9.622914285714174],
                [2.7834, 9.6308],
                [2.7826, 9.6328],
                [2.786485714285759, 9.622914285714174],
              ],
              [
                [2.6712, 9.2735],
                [2.6608, 9.2682],
                [2.6546, 9.265],
                [2.6712, 9.2735],
              ],
              [
                [2.6712, 9.2735],
                [2.6913, 9.2838],
                [2.6887, 9.2825],
                [2.6762, 9.2761],
                [2.6712, 9.2735],
              ],
              [
                [1.5451, 9.5996],
                [1.5484, 9.5987],
                [1.5611, 9.5957],
                [1.5451, 9.5996],
              ],
              [
                [1.5451, 9.5996],
                [1.5435, 9.5967],
                [1.5417, 9.5899],
                [1.5451, 9.5996],
              ],
              [
                [1.532, 10.7202],
                [1.5269, 10.7163],
                [1.4995, 10.6952],
                [1.5405, 10.7267],
                [1.532, 10.7202],
              ],
              [
                [1.4995, 10.6952],
                [1.4593, 10.6618],
                [1.4492, 10.6534],
                [1.4995, 10.6952],
              ],
              [
                [1.6975, 10.9461],
                [1.7026, 10.9613],
                [1.7059, 10.975],
                [1.6975, 10.9461],
              ],
              [
                [1.7059, 10.975],
                [1.7165, 10.9896],
                [1.7256, 11.0032],
                [1.7059, 10.975],
              ],
              [
                [1.7256, 11.0032],
                [1.726058117647058, 11.004512235294115],
                [1.7259, 11.0041],
                [1.7256, 11.0032],
              ],
              [
                [1.726058117647058, 11.004512235294115],
                [1.7287, 11.0114],
                [1.7315, 11.0201],
                [1.726058117647058, 11.004512235294115],
              ],
              [
                [1.7571, 11.0539],
                [1.766, 11.0708],
                [1.7504, 11.0441],
                [1.7571, 11.0539],
              ],
              [
                [1.766, 11.0708],
                [1.7677, 11.0811],
                [1.7694, 11.0926],
                [1.7695, 11.1013],
                [1.766, 11.0708],
              ],
              [
                [1.7546, 11.1085],
                [1.7645, 11.1068],
                [1.7518, 11.109],
                [1.7546, 11.1085],
              ],
              [
                [1.7518, 11.109],
                [1.753, 11.1254],
                [1.7524, 11.1194],
                [1.7518, 11.109],
              ],
              [
                [1.8836, 11.2836],
                [1.8847, 11.2869],
                [1.887, 11.3022],
                [1.887, 11.3076],
                [1.8836, 11.2836],
              ],
              [
                [1.887, 11.3076],
                [1.8904, 11.3207],
                [1.8881, 11.3147],
                [1.887, 11.3076],
              ],
              [
                [1.8977, 11.3387],
                [1.896, 11.3321],
                [1.9004, 11.3436],
                [1.8977, 11.3387],
              ],
              [
                [1.9004, 11.3436],
                [1.9088, 11.3534],
                [1.9155, 11.3626],
                [1.9004, 11.3436],
              ],
              [
                [2.0088, 11.4156],
                [2.0184, 11.422],
                [2.0247, 11.4277],
                [2.0088, 11.4156],
              ],
              [
                [2.0247, 11.4277],
                [2.0403, 11.4417],
                [2.0469, 11.4491],
                [2.0247, 11.4277],
              ],
              [
                [0.962, 11.0471],
                [0.9616, 11.0467],
                [0.9606, 11.0459],
                [0.9627, 11.0475],
                [0.962, 11.0471],
              ],
              [
                [0.9627, 11.0475],
                [0.9632, 11.0477],
                [0.9634, 11.0478],
                [0.9627, 11.0475],
              ],
              [
                [0.9654, 11.05],
                [0.9652, 11.0499],
                [0.9651, 11.0498],
                [0.965, 11.0497],
                [0.9654, 11.05],
              ],
              [
                [0.9654, 11.05],
                [0.9655, 11.0502],
                [0.9655, 11.0503],
                [0.9654, 11.05],
              ],
              [
                [1.0768, 10.8511],
                [1.0847, 10.8512],
                [1.0697, 10.8511],
                [1.0768, 10.8511],
              ],
              [
                [1.0847, 10.8512],
                [1.1061, 10.8558],
                [1.0957, 10.8545],
                [1.0847, 10.8512],
              ],
              [
                [3.6341, 10.0981],
                [3.6289, 10.0951],
                [3.6269, 10.093],
                [3.6341, 10.0981],
              ],
              [
                [3.6341, 10.0981],
                [3.6485, 10.1043],
                [3.645, 10.1031],
                [3.6395, 10.1007],
                [3.6341, 10.0981],
              ],
              [
                [3.409316666666678, 11.876716666666661],
                [3.4181, 11.8719],
                [3.4139, 11.8747],
                [3.409316666666678, 11.876716666666661],
              ],
              [
                [3.409316666666678, 11.876716666666661],
                [3.4088, 11.877],
                [3.4089, 11.8769],
                [3.409316666666678, 11.876716666666661],
              ],
              [
                [1.5927, 6.7076],
                [1.592, 6.7081],
                [1.593, 6.7071],
                [1.5927, 6.7076],
              ],
              [
                [3.7738, 10.4178],
                [3.7719, 10.4137],
                [3.7781, 10.4266],
                [3.7738, 10.4178],
              ],
              [
                [3.6772, 10.1683],
                [3.6782, 10.1718],
                [3.6762, 10.165],
                [3.6772, 10.1683],
              ],
              [
                [2.6984, 8.4233],
                [2.6937, 8.4233],
                [2.7049, 8.422],
                [2.6984, 8.4233],
              ],
              [
                [2.6974, 8.3073],
                [2.6923, 8.314],
                [2.6994, 8.3043],
                [2.6974, 8.3073],
              ],
              [
                [2.7156, 6.9477],
                [2.7173, 6.9456],
                [2.7184, 6.9443],
                [2.7156, 6.9477],
              ],
              [
                [2.459, 9.1275],
                [2.4598, 9.1348],
                [2.4538, 9.082],
                [2.459, 9.1275],
              ],
              [
                [2.474, 9.4841],
                [2.4724, 9.4778],
                [2.4763, 9.491],
                [2.474, 9.4841],
              ],
              [
                [2.2524, 11.344],
                [2.232, 11.3509],
                [2.2119, 11.3572],
                [2.2524, 11.344],
              ],
              [
                [2.1698, 11.3868],
                [2.1424, 11.4074],
                [2.1048, 11.4343],
                [2.1698, 11.3868],
              ],
              [
                [2.3508, 11.3134],
                [2.3615, 11.3003],
                [2.3643, 11.2969],
                [2.3508, 11.3134],
              ],
              [
                [1.7882, 6.5184],
                [1.7837, 6.5243],
                [1.7931, 6.5115],
                [1.7882, 6.5184],
              ],
              [
                [1.8307, 6.3976],
                [1.8312, 6.3944],
                [1.8292, 6.4083],
                [1.8307, 6.3976],
              ],
              [
                [1.7885, 6.3385],
                [1.7897, 6.3428],
                [1.7893, 6.3414],
                [1.7885, 6.3385],
              ],
              [
                [1.809, 6.359],
                [1.8074, 6.357],
                [1.8117, 6.3621],
                [1.809, 6.359],
              ],
              [
                [1.8133, 6.371],
                [1.8136, 6.3744],
                [1.8136, 6.3761],
                [1.8133, 6.371],
              ],
              [
                [1.7165, 6.5931],
                [1.7163, 6.5868],
                [1.718, 6.6043],
                [1.7165, 6.5931],
              ],
              [
                [1.7678, 6.5303],
                [1.7638, 6.5303],
                [1.7618, 6.5319],
                [1.7638, 6.5302],
                [1.7678, 6.5303],
              ],
              [
                [1.737, 6.5686],
                [1.7392, 6.5684],
                [1.729, 6.5696],
                [1.737, 6.5686],
              ],
              [
                [1.7492, 6.5608],
                [1.745, 6.5653],
                [1.7499, 6.5599],
                [1.7492, 6.5608],
              ],
              [
                [1.6817, 6.6604],
                [1.6807, 6.661],
                [1.6817, 6.6576],
                [1.6817, 6.6604],
              ],
              [
                [1.6613, 6.9836],
                [1.6617, 6.9794],
                [1.6637, 6.9732],
                [1.6618, 6.9794],
                [1.6613, 6.9836],
              ],
              [
                [1.6243, 6.8735],
                [1.6339, 6.8808],
                [1.6114, 6.8652],
                [1.6243, 6.8735],
              ],
              [
                [1.6612, 6.9045],
                [1.6804, 6.9227],
                [1.6703, 6.9139],
                [1.6612, 6.9045],
              ],
              [
                [2.6981, 6.7351],
                [2.7062, 6.7348],
                [2.6932, 6.7362],
                [2.6981, 6.7351],
              ],
              [
                [2.6819, 6.4867],
                [2.6825, 6.485],
                [2.6814, 6.4885],
                [2.6819, 6.4867],
              ],
              [
                [2.3444, 8.6435],
                [2.3468, 8.6415],
                [2.3412, 8.6462],
                [2.3444, 8.6435],
              ],
              [
                [2.3587, 8.6182],
                [2.3641, 8.6069],
                [2.3524, 8.6329],
                [2.3587, 8.6182],
              ],
              [
                [2.6363, 8.1771],
                [2.6564, 8.1676],
                [2.6511, 8.1708],
                [2.6363, 8.1771],
              ],
              [
                [2.7071, 8.1224],
                [2.6891, 8.1192],
                [2.6944, 8.1193],
                [2.7071, 8.1224],
              ],
              [
                [2.7266, 7.0026],
                [2.7271, 7.0058],
                [2.7256, 7.0003],
                [2.7266, 7.0026],
              ],
              [
                [2.7164, 7.0103],
                [2.7216, 7.0095],
                [2.7287, 7.0085],
                [2.7164, 7.0103],
              ],
              [
                [2.7453, 7.1022],
                [2.7462, 7.1103],
                [2.7461, 7.1098],
                [2.7453, 7.1022],
              ],
              [
                [2.614, 7.6282],
                [2.6189, 7.6226],
                [2.6124, 7.6314],
                [2.614, 7.6282],
              ],
              [
                [2.7929, 7.4645],
                [2.793, 7.4641],
                [2.7929, 7.4648],
                [2.7928, 7.4652],
                [2.7929, 7.4645],
              ],
              [
                [2.7945, 7.4806],
                [2.7948, 7.4809],
                [2.7943, 7.4805],
                [2.7945, 7.4806],
              ],
              [
                [2.7922, 7.4831],
                [2.7924, 7.4827],
                [2.792, 7.4839],
                [2.7922, 7.4831],
              ],
              [
                [2.631, 7.2809],
                [2.6336, 7.2914],
                [2.6358, 7.3007],
                [2.631, 7.2809],
              ],
              [
                [2.4803, 7.7692],
                [2.4837, 7.7721],
                [2.4858, 7.7763],
                [2.4803, 7.7692],
              ],
              [
                [2.5391, 7.9015],
                [2.5458, 7.8994],
                [2.5416, 7.9015],
                [2.5391, 7.9015],
              ],
              [
                [2.6142, 8.0595],
                [2.6055, 8.038],
                [2.6134, 8.0574],
                [2.6142, 8.0595],
              ],
              [
                [2.6762, 7.4481],
                [2.6762, 7.44],
                [2.6786, 7.4593],
                [2.6762, 7.4481],
              ],
              [
                [2.6996, 7.3927],
                [2.6947, 7.3975],
                [2.7116, 7.3806],
                [2.6996, 7.3927],
              ],
              [
                [2.9938, 9.3458],
                [2.9828, 9.3469],
                [3.008, 9.3424],
                [2.9938, 9.3458],
              ],
              [
                [2.7428, 9.4142],
                [2.7416, 9.4057],
                [2.7428, 9.4141],
                [2.7428, 9.4142],
              ],
              [
                [2.7844, 9.4657],
                [2.7883, 9.4798],
                [2.7794, 9.4521],
                [2.7844, 9.4657],
              ],
              [
                [2.7578, 9.4282],
                [2.7412, 9.4191],
                [2.7451, 9.4212],
                [2.7578, 9.4282],
              ],
              [
                [2.8865, 9.6217],
                [2.8897, 9.62],
                [2.8791, 9.6257],
                [2.8865, 9.6217],
              ],
              [
                [2.5444, 9.2984],
                [2.5358, 9.3072],
                [2.5513, 9.2895],
                [2.5444, 9.2984],
              ],
              [
                [2.4146, 8.769],
                [2.4038, 8.7689],
                [2.4062, 8.7689],
                [2.4146, 8.769],
              ],
              [
                [2.6832, 8.767],
                [2.7098, 8.7655],
                [2.6502, 8.769],
                [2.6716, 8.7673],
                [2.6832, 8.767],
              ],
              [
                [2.687, 8.4274],
                [2.6839, 8.4294],
                [2.6824, 8.4303],
                [2.6743, 8.4332],
                [2.687, 8.4274],
              ],
              [
                [2.5828, 9.255],
                [2.5845, 9.253],
                [2.5944, 9.2452],
                [2.5828, 9.255],
              ],
              [
                [1.5865, 9.3981],
                [1.5916, 9.4019],
                [1.5831, 9.3956],
                [1.5865, 9.3981],
              ],
              [
                [1.5419, 9.4562],
                [1.5396, 9.4615],
                [1.536, 9.4698],
                [1.5448, 9.4491],
                [1.5419, 9.4562],
              ],
              [
                [1.529, 9.6082],
                [1.5157, 9.615],
                [1.5401, 9.6008],
                [1.529, 9.6082],
              ],
              [
                [1.4414, 9.6375],
                [1.4487, 9.634],
                [1.4415, 9.6375],
                [1.4433, 9.6434],
                [1.4433, 9.6501],
                [1.4414, 9.6375],
              ],
              [
                [1.4029, 9.4193],
                [1.4005, 9.4614],
                [1.4026, 9.4236],
                [1.4029, 9.4193],
              ],
              [
                [1.4273, 9.5827],
                [1.4331, 9.5807],
                [1.4196, 9.5854],
                [1.4273, 9.5827],
              ],
              [
                [1.5646, 10.8271],
                [1.5673, 10.8221],
                [1.5723, 10.8167],
                [1.5646, 10.8271],
              ],
              [
                [1.5807, 10.8516],
                [1.5868, 10.8625],
                [1.5896, 10.8684],
                [1.5807, 10.8516],
              ],
              [
                [1.6122, 10.8722],
                [1.6326, 10.8737],
                [1.6001, 10.8717],
                [1.6122, 10.8722],
              ],
              [
                [1.6509, 10.8846],
                [1.6592, 10.8916],
                [1.6711, 10.9039],
                [1.6354, 10.8705],
                [1.6509, 10.8846],
              ],
              [
                [1.7659, 11.1652],
                [1.7654, 11.1766],
                [1.7647, 11.1603],
                [1.7659, 11.1652],
              ],
              [
                [1.7886, 11.1858],
                [1.7997, 11.1863],
                [1.7819, 11.1868],
                [1.7886, 11.1858],
              ],
              [
                [1.837, 11.2495],
                [1.8342, 11.244],
                [1.8325, 11.2396],
                [1.837, 11.2495],
              ],
              [
                [1.8592, 11.2668],
                [1.8714, 11.2717],
                [1.8542, 11.2657],
                [1.8592, 11.2668],
              ],
              [
                [1.8605, 11.4399],
                [1.8653, 11.4395],
                [1.8579, 11.4402],
                [1.8605, 11.4399],
              ],
              [
                [1.3891, 10.6707],
                [1.3793, 10.6803],
                [1.3977, 10.6608],
                [1.3891, 10.6707],
              ],
              [
                [0.9649, 11.0492],
                [0.965, 11.0494],
                [0.965, 11.0495],
                [0.965, 11.0496],
                [0.9649, 11.0492],
              ],
              [
                [0.9656, 11.0505],
                [0.9656, 11.0506],
                [0.9655, 11.0507],
                [0.9654, 11.0507],
                [0.9656, 11.0505],
              ],
              [
                [0.9677, 11.0535],
                [0.9669, 11.0531],
                [0.9691, 11.0539],
                [0.9684, 11.0538],
                [0.9677, 11.0535],
              ],
              [
                [0.9714, 11.0538],
                [0.9725, 11.0546],
                [0.9725, 11.0547],
                [0.9711, 11.0537],
                [0.9714, 11.0538],
              ],
              [
                [1.0148, 10.7897],
                [1.0323, 10.7975],
                [1.0206, 10.7923],
                [1.0148, 10.7897],
              ],
              [
                [1.1602, 10.8937],
                [1.1551, 10.8973],
                [1.1662, 10.8877],
                [1.1602, 10.8937],
              ],
              [
                [0.9245, 10.7075],
                [0.9057, 10.7179],
                [0.9171, 10.7115],
                [0.9245, 10.7075],
              ],
              [
                [1.1133, 11.034],
                [1.1132, 11.034],
                [1.1135, 11.0339],
                [1.1133, 11.034],
              ],
              [
                [1.1174, 11.0248],
                [1.1196, 11.0209],
                [1.1169, 11.0261],
                [1.1174, 11.0248],
              ],
              [
                [1.125, 10.9849],
                [1.1251, 10.9839],
                [1.1249, 10.9859],
                [1.125, 10.9849],
              ],
              [
                [1.1123, 10.9593],
                [1.1114, 10.952],
                [1.1133, 10.9617],
                [1.1123, 10.9593],
              ],
              [
                [2.3813, 11.6733],
                [2.3756, 11.6669],
                [2.387, 11.6776],
                [2.3813, 11.6733],
              ],
              [
                [2.7597, 11.9879],
                [2.7797, 12.0029],
                [2.7315, 11.9693],
                [2.7597, 11.9879],
              ],
              [
                [2.9643, 12.1235],
                [2.9587, 12.1216],
                [2.9948, 12.1316],
                [2.9643, 12.1235],
              ],
              [
                [3.1755, 12.086],
                [3.1677, 12.0964],
                [3.1733, 12.0889],
                [3.1755, 12.086],
              ],
              [
                [3.683, 11.0859],
                [3.6748, 11.0815],
                [3.6684, 11.0779],
                [3.683, 11.0859],
              ],
              [
                [3.3543, 10.131],
                [3.3599, 10.1402],
                [3.3683, 10.1467],
                [3.3599, 10.1403],
                [3.3543, 10.131],
              ],
              [
                [3.6725, 10.1527],
                [3.6709, 10.1459],
                [3.6667, 10.1257],
                [3.6747, 10.1601],
                [3.6725, 10.1527],
              ],
              [
                [3.3442, 10.028],
                [3.3462, 10.0384],
                [3.3501, 10.0591],
                [3.3442, 10.028],
              ],
              [
                [3.2637, 9.9142],
                [3.2605, 9.9003],
                [3.2591, 9.8941],
                [3.2637, 9.9142],
              ],
              [
                [3.5633, 10.871],
                [3.5805, 10.8868],
                [3.5613, 10.87],
                [3.5633, 10.871],
              ],
              [
                [3.2948, 11.781],
                [3.262, 11.7827],
                [3.262, 11.7826],
                [3.2948, 11.781],
              ],
              [
                [3.3846, 11.8775],
                [3.3951, 11.874],
                [3.3992, 11.8729],
                [3.3846, 11.8775],
              ],
              [
                [3.3944, 11.3917],
                [3.3888, 11.3861],
                [3.3965, 11.3923],
                [3.3944, 11.3917],
              ],
              [
                [3.5921, 11.6993],
                [3.5883, 11.7022],
                [3.5872, 11.703],
                [3.5921, 11.6993],
              ],
              [
                [3.5917, 11.7042],
                [3.5847, 11.7111],
                [3.5881, 11.7075],
                [3.5917, 11.7042],
              ],
              [
                [3.3588, 11.7246],
                [3.3629, 11.727],
                [3.3663, 11.7293],
                [3.3588, 11.7246],
              ],
              [
                [3.3956, 11.7571],
                [3.4038, 11.7661],
                [3.3855, 11.7472],
                [3.3956, 11.7571],
              ],
              [
                [3.0144, 11.8337],
                [3.0144, 11.8391],
                [3.0143, 11.837],
                [3.0144, 11.8337],
              ],
              [
                [3.02, 11.9375],
                [3.0191, 11.9327],
                [3.0184, 11.9244],
                [3.02, 11.9375],
              ],
              [
                [3.0197, 11.9185],
                [3.0146, 11.9075],
                [3.0131, 11.9019],
                [3.0197, 11.9185],
              ],
              [
                [3.0105, 11.8887],
                [3.0128, 11.8839],
                [3.0158, 11.8783],
                [3.0105, 11.8887],
              ],
            ],
            [
              [
                [2.7349, 8.4363],
                [2.7345, 8.4366],
                [2.7357, 8.4375],
                [2.7349, 8.4363],
              ],
            ],
            [
              [
                [3.1572, 9.2824],
                [3.1543, 9.2952],
                [3.153, 9.3143],
                [3.1583, 9.291],
                [3.1572, 9.2824],
              ],
            ],
            [
              [
                [1.786457586837294, 6.332808226691042],
                [1.7864, 6.3328],
                [1.786445079552151, 6.33282916912198],
                [1.786457586837294, 6.332808226691042],
              ],
            ],
            [
              [
                [1.603298601398601, 6.82756993006993],
                [1.6033, 6.8275],
                [1.603295177956372, 6.827573938002296],
                [1.603298601398601, 6.82756993006993],
              ],
            ],
            [
              [
                [3.151625984251968, 9.333625196850395],
                [3.1517, 9.3327],
                [3.151627131782946, 9.333574418604652],
                [3.151625984251968, 9.333625196850395],
              ],
            ],
            [
              [
                [2.733795039224733, 8.23520430702969],
                [2.7338, 8.2352],
                [2.733795553759093, 8.235203476151982],
                [2.733795039224733, 8.23520430702969],
              ],
            ],
            [
              [
                [1.8765, 6.291],
                [1.8729, 6.2907],
                [1.8729, 6.2915],
                [1.8782, 6.2918],
                [1.8765, 6.291],
              ],
            ],
            [
              [
                [1.8582, 6.2888],
                [1.8546, 6.2865],
                [1.851, 6.2862],
                [1.8549, 6.2901],
                [1.861, 6.2901],
                [1.8582, 6.2888],
              ],
            ],
            [
              [
                [1.8732, 6.2896],
                [1.8704, 6.2879],
                [1.869, 6.2882],
                [1.8693, 6.2893],
                [1.8732, 6.2896],
              ],
            ],
            [
              [
                [1.8582, 6.2851],
                [1.8568, 6.2846],
                [1.8585, 6.2871],
                [1.8726, 6.2874],
                [1.8757, 6.2896],
                [1.8896, 6.2932],
                [1.8926, 6.2918],
                [1.899, 6.2929],
                [1.9021, 6.2912],
                [1.9049, 6.2929],
                [1.9085, 6.2924],
                [1.904, 6.2899],
                [1.891, 6.2876],
                [1.8582, 6.2851],
              ],
            ],
            [
              [
                [1.9037, 6.294],
                [1.9024, 6.2957],
                [1.9057, 6.2951],
                [1.9051, 6.294],
                [1.9037, 6.294],
              ],
            ],
            [
              [
                [1.9296, 6.294],
                [1.929, 6.2949],
                [1.9326, 6.2943],
                [1.9324, 6.2938],
                [1.9296, 6.294],
              ],
            ],
            [
              [
                [1.9263, 6.3024],
                [1.9279, 6.2999],
                [1.924, 6.2988],
                [1.9174, 6.3007],
                [1.9185, 6.304],
                [1.9263, 6.3024],
              ],
            ],
            [
              [
                [1.9418, 6.299],
                [1.9415, 6.3004],
                [1.9457, 6.2999],
                [1.9457, 6.2988],
                [1.9418, 6.299],
              ],
            ],
            [
              [
                [1.9479, 6.3001],
                [1.9488, 6.2996],
                [1.9463, 6.2996],
                [1.9465, 6.3001],
                [1.9479, 6.3001],
              ],
            ],
            [
              [
                [1.9515, 6.3013],
                [1.9546, 6.3015],
                [1.9546, 6.3007],
                [1.9488, 6.2999],
                [1.9515, 6.3013],
              ],
            ],
            [
              [
                [1.9349, 6.2965],
                [1.9326, 6.2974],
                [1.9332, 6.2985],
                [1.9365, 6.2979],
                [1.9349, 6.2965],
              ],
            ],
            [
              [
                [1.9335, 6.2946],
                [1.9326, 6.2951],
                [1.9399, 6.2971],
                [1.9399, 6.2954],
                [1.9379, 6.2946],
                [1.9335, 6.2946],
              ],
            ],
            [
              [
                [1.9146, 6.2968],
                [1.9124, 6.2976],
                [1.9171, 6.2985],
                [1.9168, 6.2974],
                [1.9146, 6.2968],
              ],
            ],
            [
              [
                [1.9418, 6.311],
                [1.9457, 6.3071],
                [1.9476, 6.3068],
                [1.9499, 6.3068],
                [1.9532, 6.3096],
                [1.9601, 6.3087],
                [1.9585, 6.3085],
                [1.9593, 6.3054],
                [1.9579, 6.3029],
                [1.9382, 6.3015],
                [1.9374, 6.3001],
                [1.9274, 6.3015],
                [1.9285, 6.3087],
                [1.9307, 6.311],
                [1.9382, 6.3124],
                [1.9418, 6.311],
              ],
            ],
          ],
        },
      },
    ],
  },
  40: {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: {
          GID_0: "COD",
          COUNTRY: "Democratic Republic of the Congo",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [27.249130690000186, -11.794621327999835],
                [27.247798192000062, -11.793669542999965],
                [27.246941586000048, -11.79309847299993],
                [27.244562125000073, -11.792241866999973],
                [27.243039270000111, -11.791385260999959],
                [27.242087486000059, -11.790528654999946],
                [27.239898382000092, -11.789386512999954],
                [27.238089991000152, -11.788149193999971],
                [27.235996065999984, -11.7872925879999],
                [27.233902140000112, -11.786531159999981],
                [27.232569641999987, -11.784913126999925],
                [27.231998571000076, -11.783961341999941],
                [27.23123714400009, -11.78262884399993],
                [27.23123714400009, -11.781486702999928],
                [27.23161785700006, -11.780249382999955],
                [27.232474463000017, -11.777869921999979],
                [27.232284106000179, -11.774157962999823],
                [27.231903393000039, -11.77244475099991],
                [27.231998571000076, -11.770255646999885],
                [27.23161785700006, -11.766734044999907],
                [27.230761251000047, -11.763878690999832],
                [27.230475716000115, -11.762260657999889],
                [27.229999824000174, -11.760832980999965],
                [27.229238396000085, -11.759119768999881],
                [27.228952861000153, -11.757596913999919],
                [27.22765968900012, -11.755010570999957],
                [27.227525184000058, -11.754741560999946],
                [27.226192686000104, -11.753409062999879],
                [27.224289117000126, -11.752362099999971],
                [27.222671084000069, -11.750744066999914],
                [27.22190965600015, -11.749221211999952],
                [27.220672337000167, -11.747793534999971],
                [27.220577158000026, -11.745794787999955],
                [27.220767515000034, -11.74389121899992],
                [27.221053050000137, -11.742749077999918],
                [27.221719300000188, -11.741892471999961],
                [27.222100013000158, -11.740750329999855],
                [27.222575906000031, -11.739608188999853],
                [27.223051798000142, -11.739037117999942],
                [27.223337333000018, -11.73799015599991],
                [27.22362286800012, -11.736657656999967],
                [27.22362286800012, -11.734373374999961],
                [27.224765010000112, -11.731803556999978],
                [27.224098761000164, -11.72951927399987],
                [27.225050545000045, -11.727139812999837],
                [27.225907151000058, -11.726092850999976],
                [27.226383043000169, -11.725236244999962],
                [27.226763757000015, -11.724379638999892],
                [27.227209373000164, -11.723544108999931],
                [27.227525184000058, -11.722951961999968],
                [27.228191433000063, -11.722095355999897],
                [27.228857683000115, -11.721238749999884],
                [27.229523932000063, -11.720572500999936],
                [27.229904645000033, -11.720096608999881],
                [27.230570893999982, -11.719144823999841],
                [27.231522679000136, -11.717431612999917],
                [27.232474463000017, -11.715528043999882],
                [27.232474463000017, -11.712958225999898],
                [27.233045534000098, -11.711625727999945],
                [27.232284106000179, -11.708294481999928],
                [27.231903393000039, -11.706581269999958],
                [27.23123714400009, -11.704582522999942],
                [27.230713662000085, -11.703154846999951],
                [27.229785672000048, -11.702012704999959],
                [27.228857683000115, -11.700370876999898],
                [27.228286612000034, -11.699157351999929],
                [27.227715541000123, -11.697015836999924],
                [27.227073087000065, -11.695159857999954],
                [27.227287238000088, -11.693518028999904],
                [27.228215228000124, -11.692304503999935],
                [27.229000450000115, -11.691305130999865],
                [27.229500137000116, -11.690305756999976],
                [27.229642905000048, -11.689163615999973],
                [27.230213975000026, -11.688521160999926],
                [27.23107058100004, -11.687307635999957],
                [27.231784420000054, -11.68652241399991],
                [27.232569641999987, -11.685951342999829],
                [27.233925935000059, -11.685737191999976],
                [27.235781914000029, -11.685237504999918],
                [27.23635298500011, -11.683595676999971],
                [27.23635298500011, -11.6820966169999],
                [27.236709904000065, -11.68038340499993],
                [27.236281601000087, -11.679312646999961],
                [27.236138832999984, -11.678099121999935],
                [27.235282227000027, -11.676171758999942],
                [27.234140086000082, -11.674672697999938],
                [27.233497632000024, -11.673245021999946],
                [27.232997945000193, -11.671531809999919],
                [27.232641026000067, -11.670246900999928],
                [27.232569641999987, -11.669318910999948],
                [27.232069955000156, -11.667891233999967],
                [27.231498884000075, -11.666749092999964],
                [27.23092781400004, -11.665678335999928],
                [27.230428126999982, -11.663822355999912],
                [27.229500137000116, -11.661966375999953],
                [27.228929066000035, -11.66075285099987],
                [27.228357996000057, -11.659111022999923],
                [27.228357996000057, -11.657897497999897],
                [27.228357996000057, -11.656184285999871],
                [27.228215228000124, -11.654256922999934],
                [27.227929693000078, -11.652757861999874],
                [27.228001077000101, -11.651116033999926],
                [27.227572774000123, -11.649831124999935],
                [27.226858935000109, -11.648117912999965],
                [27.226858935000109, -11.646190549999972],
                [27.227001702999985, -11.644334569999899],
                [27.227287238000088, -11.643477963999885],
                [27.227644157000043, -11.641693368999938],
                [27.227715541000123, -11.64019430799982],
                [27.227715541000123, -11.638695247999976],
                [27.228286612000034, -11.636982035999949],
                [27.227572774000123, -11.635411591999969],
                [27.227001702999985, -11.634269449999977],
                [27.226573400000177, -11.633341460999929],
                [27.225645410000141, -11.632056551999938],
                [27.225145723000082, -11.630843026999969],
                [27.223575278999988, -11.629486733999897],
                [27.222790057000054, -11.628344591999905],
                [27.222438976000035, -11.627837474999978],
                [27.222147603, -11.627416602999972],
                [27.221505148000062, -11.62613169399998],
                [27.221148229000107, -11.623990178999975],
                [27.221334143000036, -11.623172156999942],
                [27.221505148000062, -11.62241973499988],
                [27.221933451000041, -11.620777905999944],
                [27.22229037, -11.619707148999964],
                [27.222218986000144, -11.618636391999928],
                [27.222218986000144, -11.617565633999959],
                [27.222218986000144, -11.616851795999878],
                [27.222004835000121, -11.616066573999944],
                [27.221505148000062, -11.614638896999907],
                [27.22121961300013, -11.613353987999915],
                [27.220934077000152, -11.612140462999889],
                [27.220291623000094, -11.610784169999931],
                [27.219720552000183, -11.609784796999918],
                [27.219149482000034, -11.608214351999948],
                [27.218863946, -11.607500513999923],
                [27.218007340000042, -11.605358998999918],
                [27.217793189000133, -11.603717170999971],
                [27.217935957000066, -11.601932574999921],
                [27.217935957000066, -11.600719049999896],
                [27.217579037000178, -11.599648292999859],
                [27.217293502000132, -11.59843476799989],
                [27.21672243099999, -11.596864322999977],
                [27.216436896000118, -11.594722807999972],
                [27.21657966399999, -11.593223747999957],
                [27.215151987000127, -11.591296384999907],
                [27.214723684000091, -11.590297010999905],
                [27.214366765000136, -11.588369647999968],
                [27.214152614000113, -11.587298889999886],
                [27.213938462000158, -11.585657061999939],
                [27.213795694000055, -11.584800455999869],
                [27.213867078000135, -11.583515546999934],
                [27.2132960080001, -11.582730324999829],
                [27.212796321000042, -11.581302647999905],
                [27.212225250000131, -11.579518052999958],
                [27.212011099000108, -11.578518678999956],
                [27.21151141200005, -11.577162385999941],
                [27.211083109000072, -11.576020244999938],
                [27.211083109000072, -11.57480672],
                [27.210726190000116, -11.573379042999875],
                [27.210083735000183, -11.571879982999917],
                [27.20979820000008, -11.570666457999948],
                [27.209584048000124, -11.569524315999956],
                [27.209084362000169, -11.567882487999839],
                [27.209012978000089, -11.566026508999926],
                [27.208370523000156, -11.564670215999968],
                [27.207513917000142, -11.562957003999884],
                [27.207085614000164, -11.561101023999981],
                [27.205783860000111, -11.560341667999921],
                [27.205372401999966, -11.560101650999968],
                [27.20437302900018, -11.560744104999912],
                [27.203373655000064, -11.561672094999892],
                [27.202374282000051, -11.563385306999976],
                [27.202088746000072, -11.564099144999886],
                [27.201660443000094, -11.565169902999912],
                [27.201446292000014, -11.56581235699997],
                [27.20102290799997, -11.56799984],
                [27.201017989000036, -11.568025255999942],
                [27.20080383700008, -11.569381548999957],
                [27.20008999900017, -11.57038092199997],
                [27.199733080000044, -11.571665830999962],
                [27.199233393000156, -11.572736588999931],
                [27.198590939000098, -11.574235648999945],
                [27.19816263600012, -11.575163638999925],
                [27.197448797000106, -11.57637716399995],
                [27.19637804000007, -11.577590688999919],
                [27.195093131000078, -11.578590062999922],
                [27.194522060000168, -11.579946355999937],
                [27.193522687000154, -11.580660193999961],
                [27.192237778000163, -11.581017112999916],
                [27.191238404000103, -11.581445415999895],
                [27.190239030000157, -11.581445415999895],
                [27.188811354000165, -11.581159880999905],
                [27.187455061000151, -11.58094572899995],
                [27.187351790000037, -11.580937466999899],
                [27.185670465000101, -11.58080296199995],
                [27.183600334000118, -11.580588809999938],
                [27.18138743500009, -11.581231264999872],
                [27.179031769000062, -11.582159253999919],
                [27.178103779000025, -11.582801708999966],
                [27.176390567000169, -11.5835869309999],
                [27.175391193999985, -11.584158001999981],
                [27.173892133000038, -11.584586304999902],
                [27.173106911000104, -11.585442910999916],
                [27.171964769999988, -11.586013980999894],
                [27.171250932000078, -11.586513667999952],
                [27.170679861, -11.587156121999897],
                [27.169109417000129, -11.588512414999968],
                [27.167824508000081, -11.58965455699996],
                [27.166896518000044, -11.590582545999894],
                [27.165825761000178, -11.59151053599993],
                [27.165040538000142, -11.592652676999933],
                [27.163827013, -11.593723434999902],
                [27.163327326000172, -11.594508656999949],
                [27.162756256000137, -11.595508030999952],
                [27.162256569000135, -11.596721555999977],
                [27.161185812000042, -11.597720928999877],
                [27.160333498000057, -11.598516421999818],
                [27.16011505400013, -11.59872030299988],
                [27.159187064000093, -11.599362756999938],
                [27.158540927000161, -11.599313054999982],
                [27.158259075000103, -11.599291373999904],
                [27.157331085000067, -11.59843476799989],
                [27.155617873000097, -11.59786369699998],
                [27.154261580000025, -11.597078474999932],
                [27.1521914490001, -11.596935706999943],
                [27.150121318000117, -11.596864322999977],
                [27.148193954000192, -11.597221242999922],
                [27.146195207000062, -11.597292625999899],
                [27.14491029800007, -11.597435393999888],
                [27.143696773000102, -11.597007090999966],
                [27.142554632000042, -11.596792939999943],
                [27.14126972300005, -11.596150484999896],
                [27.139770662999979, -11.595864949999907],
                [27.138557137000134, -11.595365262999962],
                [27.137486380000098, -11.594794191999938],
                [27.136772542000188, -11.59422312199996],
                [27.134488259000079, -11.593723434999902],
                [27.132489512000177, -11.593223747999957],
                [27.130562148000024, -11.592224373999954],
                [27.129634159000091, -11.591225000999941],
                [27.127706795000165, -11.590368394999928],
                [27.125850816000138, -11.589511788999971],
                [27.12463729100017, -11.588726566999981],
                [27.12285269500012, -11.587584424999932],
                [27.119640422000032, -11.587655808999955],
                [27.118426897000063, -11.588583798999821],
                [27.117713059000152, -11.58965455699996],
                [27.116142615000058, -11.590582545999894],
                [27.115000473000066, -11.591296384999907],
                [27.113786948000097, -11.591796070999862],
                [27.11214512000015, -11.591938838999965],
                [27.110788827000135, -11.592295757999977],
                [27.10950391900019, -11.592509909999933],
                [27.108219010000028, -11.592581293999899],
                [27.107148252000059, -11.592652676999933],
                [27.10593472700009, -11.592938212999968],
                [27.10479258600003, -11.593009596999821],
                [27.103650444000039, -11.59315236399982],
                [27.10243691900007, -11.593437899999969],
                [27.101009243000078, -11.593794818999925],
                [27.099367415000131, -11.594437272999926],
                [27.098439425000095, -11.595436646999815],
                [27.097725587000184, -11.596650171999954],
                [27.096940364000147, -11.597720928999877],
                [27.096012375000157, -11.599077221999949],
                [27.095441304000076, -11.599791059999859],
                [27.094156395000084, -11.600861817999885],
                [27.093085638000048, -11.601432888999966],
                [27.091586577000101, -11.601932574999921],
                [27.090587204000087, -11.602860564999901],
                [27.089659214000051, -11.603431635999982],
                [27.088374305000059, -11.60385993899996],
                [27.086589709000179, -11.604645160999951],
                [27.084805113000129, -11.605144847999895],
                [27.083734356000093, -11.605501766999851],
                [27.083665821000181, -11.605515092999951],
                [27.08116453800011, -11.606001453999966],
                [27.079023023000104, -11.605573150999874],
                [27.078188738000051, -11.605016960999876],
                [27.077952266000068, -11.604859311999974],
                [27.076524589000144, -11.604502392999962],
                [27.075596599000107, -11.604074089999926],
                [27.074383074000139, -11.603360251999959],
                [27.072812630000044, -11.602503645999946],
                [27.07202740800011, -11.601647039999932],
                [27.070385580000163, -11.601147352999874],
                [27.069457590000127, -11.60050489899993],
                [27.06760161, -11.599791059999859],
                [27.066602237000041, -11.599219989999938],
                [27.06424657000008, -11.598077847999946],
                [27.06231920700003, -11.596864322999977],
                [27.061391217000164, -11.59593633399993],
                [27.060891530000106, -11.594865575999904],
                [27.059178318000136, -11.593794818999925],
                [27.058321712000122, -11.593223747999957],
                [27.05703680300013, -11.592581293999899],
                [27.055537743000059, -11.592010222999818],
                [27.054252834000067, -11.590868081999929],
                [27.052325471000188, -11.590011475999972],
                [27.05082641000007, -11.590011475999972],
                [27.049398734000079, -11.590011475999972],
                [27.048042441, -11.590297010999905],
                [27.046900299000015, -11.590725313999883],
                [27.045829542000149, -11.591439151999907],
                [27.044401865000054, -11.592081606999955],
                [27.042974189000063, -11.592795444999865],
                [27.041832048000174, -11.593652050999935],
                [27.040261602999976, -11.59422312199996],
                [27.039119462000087, -11.595436646999815],
                [27.038477007000154, -11.597149858999899],
                [27.038334240000154, -11.59857753499989],
                [27.037763169000016, -11.599933827999962],
                [27.037120715000185, -11.600576282999896],
                [27.036549644000104, -11.601218736999954],
                [27.035336119000135, -11.601647039999932],
                [27.033979826000063, -11.602218110999956],
                [27.032480766000049, -11.601932574999921],
                [27.030838938000102, -11.602003958999887],
                [27.02948264500003, -11.602646413999935],
                [27.028626039000073, -11.603503019999948],
                [27.028411887000118, -11.604431008999882],
                [27.027483898000128, -11.605002079999963],
                [27.026413139999988, -11.60564453399985],
                [27.025199615000076, -11.606358372999921],
                [27.023629171000152, -11.607286362999957],
                [27.021773191000079, -11.608142968999971],
                [27.020773818000066, -11.60914234199987],
                [27.020202747000155, -11.610355866999953],
                [27.019631676000074, -11.611283856999876],
                [27.018846454000084, -11.612140462999889],
                [27.018275384000106, -11.613139836999892],
                [27.017276009999989, -11.614139209999962],
                [27.016490788000056, -11.614781664999953],
                [27.0162766360001, -11.615638270999909],
                [27.016704939000078, -11.616923179999844],
                [27.016776323000158, -11.617922552999914],
                [27.017061858000034, -11.618850542999951],
                [27.017276009999989, -11.61992130099992],
                [27.017632929000172, -11.621134825999945],
                [27.018560919000038, -11.622633885999903],
                [27.019203373000039, -11.623990178999975],
                [27.019988595000029, -11.624775400999908],
                [27.021273504000192, -11.626345844999832],
                [27.02298671599999, -11.627273834999869],
                [27.024842696000121, -11.628487359999895],
                [27.024995804000127, -11.628567241999974],
                [27.026484524000068, -11.629343965999908],
                [27.028126352000186, -11.6306288749999],
                [27.029197110000155, -11.631271329999947],
                [27.029982332000088, -11.63241347099995],
                [27.030767554000022, -11.633412843999963],
                [27.030910321000022, -11.636268197999868],
                [27.030553402000066, -11.637124803999939],
                [27.029768180000133, -11.63840971299993],
                [27.028911574000119, -11.639409085999944],
                [27.02769804899998, -11.640265691999957],
                [27.026341756000136, -11.641050913999891],
                [27.024995804000127, -11.64158929599995],
                [27.024557161000189, -11.641764752999904],
                [27.023771938000152, -11.642335822999883],
                [27.02298671599999, -11.643549347999965],
                [27.022344262000161, -11.644477337999888],
                [27.02284394899999, -11.646333317999904],
                [27.023129484000094, -11.648117912999965],
                [27.023986090000108, -11.649045902999887],
                [27.024271624999983, -11.650188044999879],
                [27.024995804000127, -11.650803596999936],
                [27.025699302000078, -11.651401569999962],
                [27.027126978000069, -11.652400942999918],
                [27.02776943300006, -11.653757235999876],
                [27.027912201000163, -11.655399063999937],
                [27.028269120000118, -11.657183659999873],
                [27.02876880700012, -11.658468568999979],
                [27.029197110000155, -11.659396558999958],
                [27.029910948000065, -11.660395931999915],
                [27.030129820000013, -11.661149823999949],
                [27.030553402000066, -11.662608830999886],
                [27.030981705000102, -11.663822355999912],
                [27.03141000800008, -11.66517864899987],
                [27.03141000800008, -11.667177395999886],
                [27.031838311000115, -11.668248153999912],
                [27.032694917000072, -11.669818597999949],
                [27.033694291000188, -11.670389667999927],
                [27.03569303800009, -11.670889354999872],
                [27.036906563000059, -11.670461051999951],
                [27.037549018000163, -11.670389667999927],
                [27.03933361300011, -11.671531809999919],
                [27.03940499700019, -11.6731022539999],
                [27.040689906000182, -11.673816091999925],
                [27.041832048000174, -11.675243768999906],
                [27.041974815000174, -11.676885596999966],
                [27.041760664000151, -11.679384030999927],
                [27.041617896000048, -11.680526171999929],
                [27.041189593000013, -11.682382151999832],
                [27.041046825000137, -11.683238757999902],
                [27.041046825000137, -11.684380898999905],
                [27.041046825000137, -11.685594423999873],
                [27.04111820900016, -11.686593797999933],
                [27.041546511999968, -11.687307635999957],
                [27.042046199000026, -11.688021474999971],
                [27.042545886000084, -11.688949463999904],
                [27.043188340000142, -11.690091605999896],
                [27.044044946000099, -11.691876200999957],
                [27.04468740100009, -11.693232493999972],
                [27.045829542000149, -11.694731554999862],
                [27.045972310000082, -11.695516776999909],
                [27.046329229000037, -11.696587533999946],
                [27.046471996000037, -11.698586280999962],
                [27.046828916000095, -11.699585654999964],
                [27.04711445099997, -11.701227482999968],
                [27.047257219000073, -11.703154846999951],
                [27.047614138000029, -11.704154219999964],
                [27.047828289000051, -11.705653280999968],
                [27.047756905000028, -11.707009572999937],
                [27.047614138000029, -11.708151714999929],
                [27.047573408000062, -11.708255802999929],
                [27.046971683000095, -11.709793542999876],
                [27.046186461000104, -11.711221218999981],
                [27.045577620000131, -11.711830059999897],
                [27.045329855000148, -11.712077824999881],
                [27.044044946000099, -11.713219966999873],
                [27.042688653000084, -11.713862420999931],
                [27.041832048000174, -11.714433491999955],
                [27.041260977000093, -11.714933177999967],
                [27.040904058000137, -11.715290097999912],
                [27.040190219000124, -11.715718400999947],
                [27.03940499700019, -11.716432238999914],
                [27.038833927000155, -11.71678915799987],
                [27.037977320999971, -11.717360228999951],
                [27.037263482000185, -11.718145450999941],
                [27.036621028000127, -11.718930672999875],
                [27.036264109000172, -11.719573126999933],
                [27.035550270000158, -11.72021558199998],
                [27.035243722000018, -11.720752041999958],
                [27.03497920000018, -11.721214955999869],
                [27.034051210000143, -11.722071561999883],
                [27.033765675000041, -11.723427853999908],
                [27.032552150000072, -11.724355843999945],
                [27.031909695000138, -11.725283833999924],
                [27.03126724100008, -11.727068429999974],
                [27.030910321000022, -11.728353338999966],
                [27.031053089000125, -11.729709630999935],
                [27.03148139200016, -11.731208691999939],
                [27.031981079000161, -11.732208064999952],
                [27.03219523000007, -11.732993287999932],
                [27.032409382000026, -11.73442096399998],
                [27.032409382000026, -11.735420337999926],
                [27.031838311000115, -11.735991407999904],
                [27.031053089000125, -11.736419710999826],
                [27.029625413000133, -11.736134175999894],
                [27.028126352000186, -11.735491721999949],
                [27.026789965000034, -11.73543826599996],
                [27.026341756000136, -11.735420337999926],
                [27.025842070000181, -11.736062791999871],
                [27.025270999000099, -11.736990781999907],
                [27.025556534000032, -11.737918771999944],
                [27.025556534000032, -11.738419867999937],
                [27.025556534000032, -11.73977475099997],
                [27.024699928000189, -11.740845508999826],
                [27.024568838000164, -11.740807449999977],
                [27.022487030000036, -11.740203053999949],
                [27.021416272000124, -11.739703367999823],
                [27.01984582800003, -11.74048858999987],
                [27.019488909000074, -11.74148796299994],
                [27.018846454000084, -11.742701487999909],
                [27.019346141000142, -11.744414699999822],
                [27.019131990000119, -11.745770992999951],
                [27.018703687000084, -11.746698982999817],
                [27.017418778000092, -11.746413447999885],
                [27.016062485000077, -11.746342063999862],
                [27.015134495000041, -11.745842376999974],
                [27.01377820200014, -11.745770992999951],
                [27.01263606100008, -11.746199295999929],
                [27.010994233000133, -11.74605652799994],
                [27.009852092000187, -11.74591376099994],
                [27.008852718000128, -11.745842376999974],
                [27.007996112000114, -11.745199921999927],
                [27.007496425000056, -11.744271932999823],
                [27.006354284000167, -11.744057780999867],
                [27.005354910000051, -11.744771618999948],
                [27.00464107200014, -11.745770992999951],
                [27.004355537000095, -11.746770366999954],
                [27.004284153000185, -11.747983891999979],
                [27.004498304000094, -11.749197416999948],
                [27.004498304000094, -11.750054022999961],
                [27.004212769000162, -11.751481699999943],
                [27.004768222, -11.752876281999875],
                [27.005148935000193, -11.754113601999904],
                [27.005434471000171, -11.755255742999907],
                [27.005910363000112, -11.756397884999842],
                [27.006195898000044, -11.757730382999966],
                [27.006481434000023, -11.759253237999928],
                [27.006671791000031, -11.760585735999939],
                [27.006671791000031, -11.761823055999912],
                [27.006671791000031, -11.763631445999977],
                [27.006671791000031, -11.765154300999939],
                [27.006671791000031, -11.766486798999892],
                [27.006291077000185, -11.768104832999938],
                [27.005910363000112, -11.769818044999909],
                [27.005910363000112, -11.771911969999962],
                [27.005244114000163, -11.773625181999876],
                [27.004482686000188, -11.774957680999933],
                [27.004292330000055, -11.776480535999951],
                [27.003721258999974, -11.777622676999954],
                [27.003721258999974, -11.779335888999981],
                [27.003721258999974, -11.780953921999981],
                [27.00362608, -11.782096063999973],
                [27.003816437000069, -11.784094810999818],
                [27.004197151000085, -11.7859983799999],
                [27.004673043000025, -11.787521234999815],
                [27.005244114000163, -11.789329624999937],
                [27.005529649000039, -11.79056694499991],
                [27.00562482800018, -11.792565691999926],
                [27.005339292000031, -11.794183724999868],
                [27.004292330000055, -11.795801758999971],
                [27.004006794000077, -11.797419791999914],
                [27.003435724000099, -11.799323360999949],
                [27.003340545000128, -11.800941394999882],
                [27.003340545000128, -11.802178713999922],
                [27.003371076000064, -11.802575608999916],
                [27.003435724000099, -11.803416033999895],
                [27.004006794000077, -11.804843710999933],
                [27.004292330000055, -11.805890673999954],
                [27.005529649000039, -11.807699063999905],
                [27.005910363000112, -11.808841204999908],
                [27.006481434000023, -11.810268881999889],
                [27.007242861000066, -11.811411022999891],
                [27.008004289000155, -11.812838699999929],
                [27.008004289000155, -11.813695305999886],
                [27.007433218000074, -11.81464708999988],
                [27.006862147000163, -11.815313338999943],
                [27.00562482800018, -11.815979587999891],
                [27.00438750800015, -11.816836193999904],
                [27.002959831000055, -11.816836193999904],
                [27.001817690000166, -11.817597621999937],
                [27.000770727000088, -11.817216907999978],
                [26.999343051000096, -11.816931372999875],
                [26.998818274000143, -11.816960526999878],
                [26.997629839000126, -11.817026550999913],
                [26.995821448000129, -11.81845422799995],
                [26.994584128999975, -11.820262617999902],
                [26.993632344000048, -11.822356543999945],
                [26.993632344000048, -11.823879398999907],
                [26.993727523000189, -11.825782967999942],
                [26.994203415000129, -11.826734751999936],
                [26.996297341000172, -11.827496179999969],
                [26.996963590000121, -11.827876892999939],
                [26.997439482000061, -11.828447963999906],
                [26.998296088000075, -11.829019034999931],
                [26.999438229000191, -11.830351532999941],
                [27.000294834999977, -11.831684030999895],
                [27.001151441000161, -11.832921350999925],
                [27.002959831000055, -11.833777956999938],
                [27.00438750800015, -11.834920097999941],
                [27.005529649000039, -11.835967060999963],
                [27.006195898000044, -11.836918844999957],
                [27.007147682999971, -11.83815616499993],
                [27.00819464599999, -11.839679019999949],
                [27.00819464599999, -11.841392231999976],
                [27.006957326000133, -11.843486157999962],
                [27.00610072000012, -11.844723476999889],
                [27.004482686000188, -11.846341510999935],
                [27.003721258999974, -11.847578829999975],
                [27.002769474000047, -11.848435435999818],
                [27.002008047000174, -11.849767934999932],
                [27.000770727000088, -11.850529361999975],
                [26.999343051000096, -11.850624540999945],
                [26.997344303000091, -11.850624540999945],
                [26.995535913000083, -11.850719718999926],
                [26.993822701000056, -11.851005253999972],
                [26.991823954000154, -11.851385967999875],
                [26.990396277000059, -11.852432930999896],
                [26.988968601000067, -11.853194357999939],
                [26.988016816000084, -11.854336499999931],
                [26.987350567000135, -11.854907569999909],
                [26.9867794970001, -11.855668997999942],
                [26.986303604000113, -11.856620781999936],
                [26.985922891000143, -11.857762923999928],
                [26.98554217700007, -11.859380956999871],
                [26.984875928000122, -11.861094168999955],
                [26.984875928000122, -11.862617023999917],
                [26.984114500000146, -11.864235056999973],
                [26.984019322000108, -11.866043447999914],
                [26.983353073000103, -11.867851837999979],
                [26.982115753000073, -11.869184335999933],
                [26.980212184000038, -11.870612012999914],
                [26.979260400000157, -11.871373439999957],
                [26.977927902000033, -11.872134867999875],
                [26.975929154000028, -11.872134867999875],
                [26.97412076400019, -11.873277008999878],
                [26.972312374000126, -11.874133614999948],
                [26.970979875000069, -11.875275756999883],
                [26.96983773400018, -11.876798611999959],
                [26.968885950000129, -11.878797358999918],
                [26.968029344000115, -11.880510570999945],
                [26.967743808000137, -11.88279485299995],
                [26.966887203, -11.884698421999872],
                [26.966696846000161, -11.887173061999817],
                [26.966696846000161, -11.889266986999871],
                [26.966030597000042, -11.891836804999969],
                [26.965972804000103, -11.893339411999932],
                [26.965935418000072, -11.894311444999971],
                [26.965935418000072, -11.896024656999884],
                [26.964983634000191, -11.89830893899989],
                [26.964127028000178, -11.900117328999954],
                [26.96441256300011, -11.901640183999916],
                [26.963746314000105, -11.90344857499997],
                [26.963365600000031, -11.904781072999981],
                [26.962889708000091, -11.906113570999935],
                [26.962223459000143, -11.907731604999981],
                [26.961652388000061, -11.909349637999981],
                [26.96060542500004, -11.910967671999856],
                [26.960034355, -11.912204990999896],
                [26.958987391999983, -11.913727845999972],
                [26.958225964000064, -11.915726593999977],
                [26.957179002000146, -11.917439805999948],
                [26.954894719000038, -11.918391589999942],
                [26.953752578000149, -11.919819266999923],
                [26.952172062999978, -11.920609523999929],
                [26.951468295000041, -11.920961407999926],
                [26.949469548000138, -11.921818013999939],
                [26.947756336000111, -11.922769797999877],
                [26.946138303000112, -11.924387831999923],
                [26.94509134000009, -11.925910686999885],
                [26.944425091000085, -11.927243184999838],
                [26.943568485000128, -11.928290147999974],
                [26.943545091000146, -11.928343620999954],
                [26.942902236000123, -11.929813002999936],
                [26.942807057000152, -11.931526214999906],
                [26.941950450999968, -11.933144247999962],
                [26.941189024000153, -11.934286388999965],
                [26.940142061000131, -11.936189957999886],
                [26.939190276000147, -11.9366658499999],
                [26.937286708000045, -11.936570671999959],
                [26.93538313900018, -11.936475493999978],
                [26.933669927000153, -11.935999600999935],
                [26.933608273000061, -11.935995747999982],
                [26.932147072000191, -11.935904422999954],
                [26.930529038000088, -11.935999600999935],
                [26.928244756000083, -11.936380314999894],
                [26.925770116000137, -11.936475493999978],
                [26.924437618000013, -11.936951385999919],
                [26.922819585000184, -11.936475493999978],
                [26.921106372999986, -11.935809244999916],
                [26.919202804000179, -11.935238173999835],
                [26.917299235000144, -11.934667102999924],
                [26.914919774000168, -11.934762281999895],
                [26.913777633000052, -11.936570671999959],
                [26.913206562000141, -11.93837906199991],
                [26.912445135000155, -11.939806738999891],
                [26.912445135000155, -11.942186199999981],
                [26.912921027000095, -11.944375303999891],
                [26.91273067000003, -11.946564407999915],
                [26.911969242000112, -11.948372798999912],
                [26.910731923000128, -11.949705296999923],
                [26.909113889000025, -11.950657080999974],
                [26.907781391000071, -11.95132333],
                [26.907305499000131, -11.951989578999928],
                [26.905401930000096, -11.953702790999955],
                [26.904069431999972, -11.954749753999977],
                [26.902451398000039, -11.95598707399995],
                [26.901689971, -11.957129214999952],
                [26.900071937000064, -11.957509928999968],
                [26.898168369000132, -11.957129214999952],
                [26.894932301999972, -11.957605106999949],
                [26.893980517000045, -11.958366534999982],
                [26.89340944700001, -11.959889389999944],
                [26.893028733000165, -11.962173671999949],
                [26.891791413000078, -11.963886883999976],
                [26.890649272000189, -11.965314560999957],
                [26.889602309000168, -11.967694021999932],
                [26.889221595000095, -11.968455449999965],
                [26.888840882000125, -11.969597590999967],
                [26.888555346000146, -11.970644553999875],
                [26.888460168000051, -11.971405980999918],
                [26.888269811000043, -11.973214371999973],
                [26.887984276000111, -11.974070977999872],
                [26.887222848000192, -11.975879367999937],
                [26.886746956000081, -11.976831151999932],
                [26.885224101000119, -11.977878114999953],
                [26.884748209000179, -11.978068471999961],
                [26.884081960000174, -11.978544363999902],
                [26.883130175000019, -11.979591326999923],
                [26.881702499000028, -11.980257575999929],
                [26.880560357000036, -11.980828646999896],
                [26.879132681000044, -11.98130453899995],
                [26.877038755000171, -11.982256322999888],
                [26.874468937000188, -11.982446679999953],
                [26.872660547000123, -11.98216114499985],
                [26.870947335000096, -11.981494895999958],
                [26.869138944000156, -11.980638289999945],
                [26.867711268000164, -11.979876861999855],
                [26.865617342000064, -11.979305791999934],
                [26.862857167000072, -11.978829899999823],
                [26.861143955000045, -11.978734720999853],
                [26.860192171000165, -11.978734720999853],
                [26.858669316000032, -11.978829899999823],
                [26.856289855000057, -11.979020255999956],
                [26.855052535000027, -11.979020255999956],
                [26.852482716999987, -11.979686504999904],
                [26.85124539800006, -11.980733467999926],
                [26.849341829000025, -11.982256322999888],
                [26.849162643999989, -11.982366590999959],
                [26.845437939000135, -11.982876286999954],
                [26.840637939000146, -11.982076286999927],
                [26.83603793900005, -11.980176286999949],
                [26.811985145000165, -11.96580918199993],
                [26.806237939000141, -11.962376286999927],
                [26.795237939, -11.959576286999891],
                [26.765537939000069, -11.964376286999936],
                [26.759637939000186, -11.967276286999891],
                [26.754737939000165, -11.968476286999874],
                [26.745437939000169, -11.975776286999917],
                [26.729437939000093, -11.991476286999898],
                [26.728786162000063, -11.992128063999928],
                [26.727337939000051, -11.993576286999939],
                [26.722937939000133, -11.995276286999911],
                [26.717037939000136, -11.999776286999918],
                [26.711437939000064, -12.001076286999933],
                [26.708614152000052, -11.998509208999963],
                [26.699337939000031, -11.990076286999965],
                [26.682837939000137, -11.980876286999944],
                [26.675837939000019, -11.98367628699998],
                [26.662737939000067, -11.983776286999898],
                [26.658837939000136, -11.983476286999974],
                [26.612837939000087, -11.967576286999929],
                [26.604837939000049, -11.967076286999884],
                [26.598637939000071, -11.966876286999934],
                [26.58903793900015, -11.968876286999944],
                [26.584037939000154, -11.971476286999973],
                [26.571437939000191, -11.974276286999896],
                [26.563437939000153, -11.974276286999896],
                [26.55783793900008, -11.97127628699991],
                [26.546437939000043, -11.95767628699997],
                [26.533637939000073, -11.950876286999915],
                [26.515737939000132, -11.940376286999879],
                [26.511837939000031, -11.938076286999888],
                [26.499637939000081, -11.930476286999919],
                [26.493337939000185, -11.92787628699989],
                [26.488237939000101, -11.92587628699988],
                [26.479737939000074, -11.920776286999967],
                [26.473337939000089, -11.916376286999878],
                [26.469737939000083, -11.915676286999883],
                [26.457337939000126, -11.913576286999955],
                [26.449537939000095, -11.914576286999932],
                [26.441937939000127, -11.915076286999977],
                [26.441909695000106, -11.915082932999894],
                [26.430037939000158, -11.917876286999842],
                [26.414137939000113, -11.928076286999953],
                [26.406737939000152, -11.929376286999855],
                [26.38613793900015, -11.935576286999833],
                [26.379537939000102, -11.936976286999936],
                [26.366892392000182, -11.942947794999839],
                [26.350737939000169, -11.950576286999876],
                [26.330037939000192, -11.958576286999914],
                [26.322937938999985, -11.958576286999914],
                [26.31653793900017, -11.955976286999942],
                [26.308137939000062, -11.951476286999934],
                [26.293837939000127, -11.94227628699997],
                [26.288137939000137, -11.93987628699989],
                [26.279437939000047, -11.938276286999951],
                [26.266937939000172, -11.935676286999922],
                [26.262537939000083, -11.934476286999939],
                [26.237137939000093, -11.925776286999962],
                [26.223837939000077, -11.923776286999953],
                [26.194537939000156, -11.927376286999902],
                [26.18603793900013, -11.928576286999828],
                [26.171837939000113, -11.930576286999951],
                [26.152837939000108, -11.936076286999878],
                [26.14303793900018, -11.93597628699996],
                [26.124437939000074, -11.937476286999981],
                [26.106137939000064, -11.936976286999936],
                [26.100237939000067, -11.93657628699998],
                [26.088537939000105, -11.935376286999883],
                [26.082537939000133, -11.935076286999958],
                [26.070237939000094, -11.932976286999974],
                [26.065837939000062, -11.931776286999934],
                [26.056937939000136, -11.931076286999826],
                [26.040437939000014, -11.929776286999981],
                [26.036337939000077, -11.930176286999938],
                [26.02693793899999, -11.931576286999871],
                [26.018537939000112, -11.932576286999961],
                [26.01185306800005, -11.933690432999924],
                [26.011141768000186, -11.935485617999973],
                [26.009154180000053, -11.936382168999899],
                [26.006727130000115, -11.937381542999958],
                [26.005085301000065, -11.938023996999959],
                [26.00134449400008, -11.939922316999969],
                [26.000302585000099, -11.940451046999954],
                [25.998089686000071, -11.94116488599991],
                [25.992236212000023, -11.942307026999913],
                [25.989880546000109, -11.943235016999893],
                [25.987239344000102, -11.943806086999871],
                [25.986446380000189, -11.944055307999975],
                [25.984740910000141, -11.944591308999918],
                [25.981385870000167, -11.941950107999958],
                [25.981528637000167, -11.940308279999954],
                [25.982171092000158, -11.939023370999848],
                [25.983027698000114, -11.937952612999879],
                [25.983066324000163, -11.937919136999881],
                [25.98409845499998, -11.937024622999957],
                [25.984383991000186, -11.935525562999885],
                [25.984241223000083, -11.93345543199996],
                [25.984241223000083, -11.931670835999967],
                [25.98345600100015, -11.928458563999925],
                [25.984241223000083, -11.925888745999941],
                [25.987596263000057, -11.924104149999891],
                [25.990308849000144, -11.922105402999819],
                [25.992807283000104, -11.921248796999976],
                [25.993878040000141, -11.920820493999884],
                [25.995234333000155, -11.919892503999961],
                [25.996233707000101, -11.918179291999934],
                [25.997018929000035, -11.916180544999975],
                [25.997233080000058, -11.912611353999978],
                [25.996519241999977, -11.909541848999936],
                [25.995948171000066, -11.907257565999942],
                [25.994734646000154, -11.905330202999949],
                [25.993806656000061, -11.902831768999931],
                [25.993021434000127, -11.900261950999948],
                [25.991236838000077, -11.897763516999873],
                [25.990094697000188, -11.89519369899989],
                [25.988024566000036, -11.893409102999954],
                [25.985811667000178, -11.891410355999938],
                [25.98395568799998, -11.889126072999943],
                [25.980957567000189, -11.887912547999974],
                [25.978459133000058, -11.887055941999961],
                [25.975389628000187, -11.884985810999922],
                [25.974390254000127, -11.882630144999894],
                [25.972962578000079, -11.880774164999877],
                [25.970963831000176, -11.877990195999928],
                [25.967894326000135, -11.875705912999933],
                [25.96211223600011, -11.873778549999884],
                [25.957757822000076, -11.87299332799995],
                [25.955259388000115, -11.874849306999863],
                [25.954260014000056, -11.875848680999923],
                [25.952261267000097, -11.87813296399986],
                [25.951699572000109, -11.878413810999916],
                [25.950405288000127, -11.879060952999964],
                [25.948477923999974, -11.879917558999978],
                [25.946550561000151, -11.880488629999888],
                [25.94367284100008, -11.880206504999933],
                [25.942909984999972, -11.880131710999933],
                [25.93976909700018, -11.88177353899988],
                [25.936628208000116, -11.882630144999894],
                [25.93370147100012, -11.883843669999919],
                [25.930703350000158, -11.883629518999896],
                [25.926920007000035, -11.882130457999949],
                [25.925420947000134, -11.881345235999902],
                [25.922851129000151, -11.87813296399986],
                [25.920424079000043, -11.876419751999947],
                [25.918710867000073, -11.874920690999886],
                [25.915498595000031, -11.872707792999961],
                [25.912214938000034, -11.869852438999942],
                [25.909645121000096, -11.867282620999958],
                [25.907503606000091, -11.864284500999872],
                [25.907503606000091, -11.862571288999959],
                [25.907461910000052, -11.862479557999905],
                [25.906075929000053, -11.859430399999951],
                [25.905362091000143, -11.857217500999923],
                [25.904148566000174, -11.855861208999954],
                [25.903006424000182, -11.854362147999836],
                [25.902435354000147, -11.852506168999923],
                [25.901864283000066, -11.850364653999918],
                [25.900293838999971, -11.849793582999837],
                [25.899638101999983, -11.849654486999952],
                [25.897938172000011, -11.849293895999949],
                [25.895296971000107, -11.848936976999937],
                [25.893298223000102, -11.848865592999971],
                [25.890942557000017, -11.848437289999879],
                [25.889514880000149, -11.846438542999977],
                [25.888943810000114, -11.844439795999961],
                [25.889800416000128, -11.842512431999921],
                [25.89008595100006, -11.840656452999951],
                [25.891013941000097, -11.838015250999888],
                [25.891513628000155, -11.835945119999849],
                [25.890657022000141, -11.832304544999886],
                [25.891299475999972, -11.829948877999925],
                [25.89187054700011, -11.826451070999894],
                [25.891513628000155, -11.824024019999968],
                [25.890871173000164, -11.82138281899995],
                [25.890442870000186, -11.819526838999934],
                [25.889157961000024, -11.816600101999825],
                [25.888515507000136, -11.813387829999954],
                [25.888372739000033, -11.810461092999901],
                [25.887920976000032, -11.807298745999901],
                [25.887730284999975, -11.805963910999878],
                [25.886445375999983, -11.80475038599991],
                [25.886203401000159, -11.804836805999969],
                [25.885446002000094, -11.805107304999979],
                [25.883732790000067, -11.805035921999831],
                [25.881234356000164, -11.804821769999876],
                [25.8785931540001, -11.805892526999912],
                [25.875880569000117, -11.805892526999912],
                [25.87302521600003, -11.805892526999912],
                [25.87052678200007, -11.805035921999831],
                [25.867742812000131, -11.804250698999965],
                [25.86702897399999, -11.804322082999931],
                [25.866600671000185, -11.804393466999954],
                [25.864958843000068, -11.805392840999957],
                [25.862246257000152, -11.806249446999971],
                [25.859533672000168, -11.807177435999904],
                [25.856913815000041, -11.808097925999959],
                [25.856892470000105, -11.808105425999941],
                [25.85546479300001, -11.809318950999909],
                [25.853394662000085, -11.811603233999904],
                [25.851324531000103, -11.813316445999931],
                [25.849754087, -11.814173051999887],
                [25.848183643000141, -11.815457960999822],
                [25.846399047000091, -11.816528717999972],
                [25.843971997000153, -11.818741616999887],
                [25.843017761000112, -11.819487113999969],
                [25.841687714000102, -11.820526212999937],
                [25.84026003800011, -11.822096656999861],
                [25.83783298700007, -11.823881252999968],
                [25.835263169000086, -11.826236918999939],
                [25.833050271000161, -11.827450443999965],
                [25.829623847000164, -11.827735978999897],
                [25.82798201899999, -11.828235665999955],
                [25.82455559499999, -11.828164281999875],
                [25.822199929000135, -11.828021514999875],
                [25.822084115000166, -11.828000067999881],
                [25.81834520200016, -11.827307676999965],
                [25.81463324300006, -11.825308928999902],
                [25.811635122000041, -11.824024019999968],
                [25.810707132, -11.823524333999956],
                [25.807137940000132, -11.822096656999861],
                [25.804068435999966, -11.821168666999881],
                [25.800285093000014, -11.821097282999972],
                [25.796930053000096, -11.820740363999903],
                [25.793503629000099, -11.821025898999892],
                [25.791933185000175, -11.821097282999972],
                [25.789006448000066, -11.82152558599995],
                [25.787221852000187, -11.824024019999968],
                [25.786530639000091, -11.824542429999894],
                [25.784937569000078, -11.825737231999881],
                [25.783295741000131, -11.826379686999928],
                [25.780797307000171, -11.827164908999976],
                [25.778584408000143, -11.826379686999928],
                [25.775300752000078, -11.825380312999926],
                [25.773658924000074, -11.824523706999969],
                [25.770660803000112, -11.823952635999945],
                [25.768376521000107, -11.823238797999863],
                [25.766591925000057, -11.823024646999897],
                [25.764236258000096, -11.82152558599995],
                [25.763451036000163, -11.820740363999903],
                [25.763381118000041, -11.820656461999874],
                [25.762023360000171, -11.819027151999819],
                [25.760595683000076, -11.817313939999906],
                [25.758668320000027, -11.816457333999892],
                [25.756598189000044, -11.81538657699997],
                [25.755598815000155, -11.815814879999948],
                [25.753171765000047, -11.815029657999901],
                [25.751030250000099, -11.813030909999895],
                [25.749459806000175, -11.811460465999915],
                [25.747461058000169, -11.810603859999901],
                [25.74553369500012, -11.809461718999898],
                [25.742678342000033, -11.809247566999943],
                [25.740179908000073, -11.809247566999943],
                [25.739109150000104, -11.809747253999831],
                [25.737039019000179, -11.809675869999978],
                [25.735183039999981, -11.81003279],
                [25.733112908000123, -11.809318950999909],
                [25.730971394000051, -11.80903341599992],
                [25.729472333000103, -11.808533728999976],
                [25.727616354000077, -11.80710605299987],
                [25.725189303000093, -11.806963284999938],
                [25.721405960000141, -11.808462344999896],
                [25.718693375000157, -11.80903341599992],
                [25.717622617000188, -11.809461718999898],
                [25.716337708000026, -11.809818637999967],
                [25.714196193000191, -11.809176183999909],
                [25.710555618000171, -11.806035294999901],
                [25.706430567000154, -11.80452932399993],
                [25.706058437000138, -11.804393466999954],
                [25.705273215000148, -11.803394092999952],
                [25.703702770000177, -11.801038426999924],
                [25.702917548000187, -11.799111063999874],
                [25.702933929000039, -11.799048815999981],
                [25.703274467000142, -11.797754770999916],
                [25.702917548000187, -11.794970800999977],
                [25.702120429000047, -11.792615134999949],
                [25.70093069800015, -11.791187457999911],
                [25.699800454000069, -11.789997727999889],
                [25.696826128000168, -11.789164916999937],
                [25.693732829000112, -11.788391591999869],
                [25.692424125000173, -11.787558779999813],
                [25.691888747000121, -11.787558779999813],
                [25.690282610000168, -11.786785455999961],
                [25.689330826000116, -11.784881886999926],
                [25.688141095000162, -11.782978317999891],
                [25.686891878000097, -11.780479883999931],
                [25.685642661000031, -11.77905220699995],
                [25.683739093000099, -11.77702966499993],
                [25.68177603700002, -11.775364042999968],
                [25.679872467999985, -11.774412257999813],
                [25.677790440000024, -11.773638933999962],
                [25.675351493000107, -11.771854337999912],
                [25.672436653000034, -11.770188714999961],
                [25.670947463000061, -11.769324241999982],
                [25.670337968000183, -11.768970430999957],
                [25.66908161300006, -11.766571933999899],
                [25.668167900000014, -11.764801614999897],
                [25.667768150000029, -11.763031295999951],
                [25.667197080000165, -11.76160362],
                [25.667146421000155, -11.760666424999954],
                [25.667082866000158, -11.759490657999947],
                [25.667082866000158, -11.758005874999924],
                [25.666797330000122, -11.755892912999968],
                [25.666740223000147, -11.752809131999982],
                [25.666683116000115, -11.750239313999828],
                [25.666112045000034, -11.747612388999812],
                [25.664969904000088, -11.745670748999828],
                [25.664170405000107, -11.743957536999915],
                [25.662914049999983, -11.741330611999899],
                [25.661143731000152, -11.738303937999945],
                [25.660001590000093, -11.734934620999979],
                [25.657203343000106, -11.73185083899989],
                [25.654576419000023, -11.728995485999974],
                [25.652577671000188, -11.727853344999971],
                [25.648465963000149, -11.72791045199989],
                [25.646010359000172, -11.728595736999864],
                [25.645942225000056, -11.728633210999931],
                [25.645215322000013, -11.729033006999884],
                [25.644868218000113, -11.729223913999874],
                [25.641841543000055, -11.729852091999874],
                [25.63995701000016, -11.73122266199988],
                [25.636073730000192, -11.730651590999969],
                [25.633218377000105, -11.730708697999887],
                [25.628764026000056, -11.729166806999956],
                [25.626708171000075, -11.727739130999964],
                [25.625394709000091, -11.727453594999929],
                [25.623510176000025, -11.727739130999964],
                [25.621511428000019, -11.727853344999971],
                [25.617799469000147, -11.727624916999901],
                [25.61471568800016, -11.728195986999935],
                [25.613059583000165, -11.728709950999871],
                [25.610261337000111, -11.729395235999846],
                [25.608776552999984, -11.7304802699999],
                [25.606949127000121, -11.731279768999968],
                [25.604721952000148, -11.731051339999908],
                [25.604093774000035, -11.731108447999929],
                [25.603065846999982, -11.731336875999887],
                [25.600724457000069, -11.73122266199988],
                [25.599410995000142, -11.73173662499994],
                [25.595641928000077, -11.731907945999978],
                [25.593757395000068, -11.733107194999945],
                [25.592729468000186, -11.733792479999977],
                [25.59158732700007, -11.734991727999954],
                [25.589188830000126, -11.735848333999968],
                [25.588960402000055, -11.737104688999921],
                [25.588446438000176, -11.739960041999836],
                [25.588846188000048, -11.74230143199992],
                [25.588731973999984, -11.744871249999846],
                [25.588275117000137, -11.746298926999941],
                [25.587475618000155, -11.748925851999957],
                [25.585876620000192, -11.749839564999945],
                [25.585020014000179, -11.751724097999841],
                [25.583306801999981, -11.753665737999825],
                [25.581993340000054, -11.754522343999895],
                [25.579480629000045, -11.756920840999953],
                [25.578338488000156, -11.757549017999963],
                [25.576910811000062, -11.758291409999913],
                [25.573598602000175, -11.759319336999965],
                [25.569886642000029, -11.759205122999958],
                [25.566688646999978, -11.759376443999884],
                [25.565089649000015, -11.759205122999958],
                [25.563205116000177, -11.759376443999884],
                [25.559892906000186, -11.758748266999874],
                [25.558465230000024, -11.759033801999976],
                [25.556923339000093, -11.759719086999837],
                [25.554867485000159, -11.760518585999819],
                [25.553325594000057, -11.761375191999889],
                [25.551783703000069, -11.762460225999973],
                [25.55029891900017, -11.763773687999958],
                [25.549042564000047, -11.764630293999915],
                [25.547843316000126, -11.765372685999978],
                [25.546472746000063, -11.766857469999934],
                [25.545273498000086, -11.767828289999954],
                [25.544245571000033, -11.768913323999925],
                [25.542418145, -11.77045521499997],
                [25.541276003, -11.77193999799988],
                [25.541084393000176, -11.772189924999964],
                [25.539962541000079, -11.773653209999964],
                [25.538763292000056, -11.775937492999958],
                [25.537735365000174, -11.777479383999946],
                [25.53625058200015, -11.779535237999937],
                [25.534594477000155, -11.780334736999919],
                [25.531910445000165, -11.781591091999871],
                [25.528255593000097, -11.780391843999951],
                [25.526656595000134, -11.779820772999926],
                [25.524657848, -11.779649451999887],
                [25.522374957000068, -11.778375279999977],
                [25.522202244000084, -11.778278881999881],
                [25.52151695900011, -11.777764918999935],
                [25.517919214000074, -11.777365168999893],
                [25.515977573999976, -11.778050453999924],
                [25.514664111000116, -11.779249702999948],
                [25.513065114000085, -11.78102002199995],
                [25.512490393, -11.782213672999944],
                [25.512322722000079, -11.782561911999892],
                [25.51140900900009, -11.784617766999929],
                [25.509638690000088, -11.78621676399996],
                [25.508839191000106, -11.787073369999973],
                [25.507019134000018, -11.785404984999957],
                [25.506783337000172, -11.785188836999907],
                [25.505755409000187, -11.783646946999966],
                [25.504042197000047, -11.782219269999871],
                [25.502386093000155, -11.781305556999939],
                [25.500273131000085, -11.780848699999979],
                [25.497703313000102, -11.78067737899994],
                [25.49627563700011, -11.780211004999899],
                [25.494610014000045, -11.779639933999874],
                [25.493943765000097, -11.778688149999937],
                [25.493372694000186, -11.77697493799991],
                [25.492135375000032, -11.775499671999967],
                [25.490898055000116, -11.774690654999915],
                [25.489898681000057, -11.774071994999815],
                [25.488851718000035, -11.773215388999972],
                [25.487995112000192, -11.77193048099997],
                [25.487614399000051, -11.77083592799994],
                [25.487376453000081, -11.769360662999929],
                [25.487281274000111, -11.767266736999943],
                [25.487233685000149, -11.765458346999878],
                [25.487852345000192, -11.763887901999965],
                [25.488423415, -11.762365046999889],
                [25.488899308000043, -11.761318083999981],
                [25.489280021000184, -11.7603663],
                [25.489993860000027, -11.759319336999965],
                [25.490802876000146, -11.758510319999914],
                [25.491278769000189, -11.758082016999936],
                [25.49246849900004, -11.757510946999901],
                [25.494086533000143, -11.756606751999982],
                [25.494990728000118, -11.755464609999876],
                [25.495514209000135, -11.754370057999836],
                [25.496465994000118, -11.753465862999974],
                [25.497560546000102, -11.752752024999893],
                [25.498464741000021, -11.751895418999879],
                [25.498655098000086, -11.750705687999869],
                [25.498274384000183, -11.748326226999893],
                [25.498655098000086, -11.74661301499998],
                [25.49741777800017, -11.744709446999934],
                [25.497322600000132, -11.74290105599988],
                [25.496323226000015, -11.741187843999967],
                [25.495990101000075, -11.739712578999956],
                [25.495894923000037, -11.738380079999956],
                [25.496370815000148, -11.736857224999881],
                [25.496061485000155, -11.735477137999908],
                [25.496097177000081, -11.734763299999884],
                [25.496525480000059, -11.734013768999887],
                [25.496811015000162, -11.733014395999874],
                [25.496596864000139, -11.731515334999926],
                [25.496739632000015, -11.730551653999953],
                [25.496739632000015, -11.729266744999961],
                [25.496794094999984, -11.727850686999943],
                [25.496811015000162, -11.727410764999945],
                [25.496500388000129, -11.7266120099999],
                [25.496311329000036, -11.726125855999953],
                [25.495668874000103, -11.724769562999938],
                [25.494847960000186, -11.723877265999874],
                [25.495169187000045, -11.723127734999878],
                [25.495311955000147, -11.722199745999831],
                [25.49563318200012, -11.72166436699996],
                [25.495660565000037, -11.720952419999833],
                [25.495668874000103, -11.72073637699998],
                [25.49563318200012, -11.71984407899987],
                [25.495481724000058, -11.719789708999883],
                [25.494241198000054, -11.719344391999925],
                [25.493206132000068, -11.71895178099993],
                [25.492063991000123, -11.718630553999901],
                [25.490814774000057, -11.718416402999878],
                [25.489351405000036, -11.717702563999978],
                [25.487638193000066, -11.717345644999909],
                [25.486353284000074, -11.716988725999954],
                [25.486067749000142, -11.7170601099999],
                [25.485282527000038, -11.716988725999954],
                [25.484247461000166, -11.716381962999947],
                [25.482962552000174, -11.715168437999978],
                [25.480713962000095, -11.714347523999947],
                [25.479286285, -11.71352660999986],
                [25.478037068000162, -11.7127770799999],
                [25.477251846000172, -11.712206008999942],
                [25.475859861, -11.711206635999929],
                [25.475038947000144, -11.710457104999819],
                [25.47400023299997, -11.710341692999862],
                [25.476391704000093, -11.695769607999864],
                [25.484377988000062, -11.696632989999955],
                [25.486541454000189, -11.69686687799998],
                [25.486457042000097, -11.696692581999912],
                [25.477537939000115, -11.678276286999903],
                [25.458537939000166, -11.673876286999928],
                [25.442537939000033, -11.668476286999976],
                [25.426237939000146, -11.666376286999935],
                [25.416537939000136, -11.668476286999976],
                [25.400237939000078, -11.654376286999934],
                [25.38613793900015, -11.644376286999886],
                [25.367837938999969, -11.639276286999973],
                [25.366578889000095, -11.638770197999918],
                [25.357637938999972, -11.635176286999922],
                [25.348937939000109, -11.631576286999973],
                [25.339437939000106, -11.626476286999946],
                [25.33133793900015, -11.620076286999961],
                [25.328737939000121, -11.614276286999939],
                [25.328437938999969, -11.608476286999917],
                [25.328637939000032, -11.601376286999937],
                [25.326937939000061, -11.59407628699995],
                [25.326437939000073, -11.586276286999919],
                [25.324737939000102, -11.579076286999907],
                [25.317937939000046, -11.571776286999977],
                [25.314537939000104, -11.566376286999912],
                [25.310437939000167, -11.563176286999976],
                [25.303837939000118, -11.551476286999957],
                [25.301737939000077, -11.543476286999976],
                [25.290737939000167, -11.521576286999959],
                [25.287337939, -11.51107628699998],
                [25.290437939000185, -11.506976286999873],
                [25.299037939000129, -11.501676286999952],
                [25.306037939000078, -11.501876286999959],
                [25.31163793900015, -11.496776286999932],
                [25.311837939000156, -11.488476286999969],
                [25.302437939000072, -11.47047628699994],
                [25.29583793900008, -11.458076286999869],
                [25.294837939000104, -11.451976286999979],
                [25.29463793900004, -11.448376286999917],
                [25.298237939000046, -11.432276286999979],
                [25.297037939000063, -11.426976286999945],
                [25.294337939000116, -11.418876286999819],
                [25.293337939000139, -11.409476286999904],
                [25.291237939000098, -11.401176286999828],
                [25.287837938999985, -11.396076286999914],
                [25.277137939, -11.368076286999894],
                [25.276837939000075, -11.355976286999976],
                [25.280337939000106, -11.348376286999894],
                [25.286137939000014, -11.346476286999973],
                [25.295337939000092, -11.341776286999959],
                [25.300437939000176, -11.337676286999908],
                [25.303837939000118, -11.32967628699987],
                [25.29193793900015, -11.306276286999889],
                [25.297037939000063, -11.291476286999966],
                [25.306737939000072, -11.287376286999972],
                [25.316037939000012, -11.278876286999946],
                [25.323016412000015, -11.265814016999911],
                [25.323837939000043, -11.264276286999973],
                [25.320837939000171, -11.257676286999924],
                [25.320875136000154, -11.25470052799983],
                [25.320937939000089, -11.249676286999943],
                [25.327237938999986, -11.238876286999869],
                [25.330337939000174, -11.231676286999914],
                [25.331111458000123, -11.230668912999874],
                [25.343237939000119, -11.214876286999981],
                [25.344737939000026, -11.203176286999962],
                [25.343237939000119, -11.196076286999926],
                [25.338037939000117, -11.190376286999935],
                [25.314937939000174, -11.188276286999894],
                [25.291237939000098, -11.189576286999909],
                [25.28323793900006, -11.190276286999904],
                [25.273637939000139, -11.194776286999911],
                [25.268437939000137, -11.196276286999876],
                [25.261137939000093, -11.200276286999895],
                [25.25603793900018, -11.203876286999957],
                [25.251737939000179, -11.207176286999982],
                [25.246037939000189, -11.21137628699995],
                [25.233137939000073, -11.218576286999962],
                [25.223437939000064, -11.222176286999854],
                [25.219137939000063, -11.223076286999969],
                [25.206637939000188, -11.224776286999941],
                [25.195837939000114, -11.227976286999933],
                [25.177937939000174, -11.228876286999878],
                [25.158137939000085, -11.230776286999969],
                [25.145937939000135, -11.233876286999873],
                [25.141537939000045, -11.233776286999955],
                [25.12333793900018, -11.239076286999818],
                [25.118937939000091, -11.24137628699998],
                [25.105637939000133, -11.243376286999876],
                [25.093337939000094, -11.244576286999973],
                [25.071137938999982, -11.249976286999924],
                [25.062537939000038, -11.24887628699986],
                [25.05803793900003, -11.246576286999868],
                [25.04053793900016, -11.243876286999978],
                [25.031037939000157, -11.24137628699998],
                [25.017437939000047, -11.24077628699996],
                [24.999437939000188, -11.244276286999934],
                [24.992937939000058, -11.246576286999868],
                [24.988037939000037, -11.2466762869999],
                [24.976237939000157, -11.250076286999956],
                [24.968637939000132, -11.253076286999885],
                [24.962837939000053, -11.252976286999967],
                [24.949337939000031, -11.25237628699989],
                [24.941437939000082, -11.254976286999977],
                [24.937137939000081, -11.25637628699991],
                [24.930437939000115, -11.258776286999819],
                [24.925537939000094, -11.261276286999816],
                [24.91433793900012, -11.266476286999932],
                [24.905437939000024, -11.267776286999833],
                [24.901337939000086, -11.26837628699991],
                [24.898437939000132, -11.268076286999872],
                [24.894737939000038, -11.268876286999898],
                [24.890337939000119, -11.268276286999878],
                [24.885837939000112, -11.266476286999932],
                [24.881837939000093, -11.265376286999924],
                [24.874037939000061, -11.265876286999912],
                [24.870637939000119, -11.268676286999948],
                [24.867837939000083, -11.272676286999967],
                [24.860237939000115, -11.277576286999874],
                [24.856237939000096, -11.281376286999944],
                [24.846437939000111, -11.287276286999884],
                [24.833037939, -11.290976286999978],
                [24.820337939000126, -11.293876286999932],
                [24.804737939000063, -11.297876286999951],
                [24.794537939000065, -11.299676286999954],
                [24.774037939000152, -11.296476286999848],
                [24.768537939000169, -11.295576286999903],
                [24.757237939000106, -11.297176286999957],
                [24.752037939000047, -11.297976286999869],
                [24.746637939000038, -11.299276286999884],
                [24.739937939000072, -11.302976286999979],
                [24.735337939000146, -11.306576286999928],
                [24.733037939000099, -11.309076286999925],
                [24.731237938999982, -11.314076286999978],
                [24.727337939000108, -11.318476286999896],
                [24.720237939000071, -11.321476286999882],
                [24.716837939000129, -11.322276286999966],
                [24.712937939000028, -11.323876286999962],
                [24.697537939000028, -11.326276286999928],
                [24.677437939000129, -11.341076286999964],
                [24.672137938999981, -11.344376286999875],
                [24.663037939000048, -11.352676286999952],
                [24.657737939000128, -11.357176286999959],
                [24.650337939000167, -11.365476286999979],
                [24.645137939000165, -11.370776286999842],
                [24.643837939000036, -11.374376286999905],
                [24.641537939000159, -11.382176286999936],
                [24.63713793900007, -11.387376286999938],
                [24.632037939000156, -11.391476286999875],
                [24.628537939000125, -11.393776286999923],
                [24.617037939000056, -11.398576286999912],
                [24.610237939000172, -11.398776286999919],
                [24.601737939000088, -11.402676286999963],
                [24.595737939000173, -11.40617628699988],
                [24.595658107000133, -11.406490897999902],
                [24.590583939000055, -11.408182286999875],
                [24.588083939000114, -11.411782286999937],
                [24.588383939000096, -11.416882286999964],
                [24.58558393900006, -11.424582286999964],
                [24.579283939000163, -11.435882286999913],
                [24.568583939000177, -11.441382286999897],
                [24.562383939000028, -11.442282286999955],
                [24.55838393900018, -11.442182286999923],
                [24.558358157000157, -11.442176792999931],
                [24.546183939000059, -11.439582286999894],
                [24.54617049400008, -11.439583224999978],
                [24.541883939000058, -11.439882286999875],
                [24.538083939000103, -11.442682286999911],
                [24.532883939000044, -11.444082286999901],
                [24.525683939000146, -11.447982286999945],
                [24.514283939000109, -11.451982286999964],
                [24.506884238000112, -11.455036131999975],
                [24.501683939000145, -11.457182286999966],
                [24.489883939000094, -11.457182286999966],
                [24.489847189000045, -11.457176286999925],
                [24.484983939000188, -11.45638228699994],
                [24.473083939000048, -11.454182286999981],
                [24.473043277000045, -11.45417727399996],
                [24.467225221000092, -11.453459978999945],
                [24.465783939000175, -11.453282286999979],
                [24.459183939000127, -11.453882286999942],
                [24.45248393899999, -11.453582286999904],
                [24.450156986000138, -11.453897212999948],
                [24.439183939000031, -11.455382286999907],
                [24.439147920000153, -11.455374936999931],
                [24.434283939000125, -11.45438228699993],
                [24.429283939000129, -11.445082286999877],
                [24.421083939000084, -11.433682286999897],
                [24.409397689000173, -11.425950030999957],
                [24.407783939000069, -11.424882286999889],
                [24.401368272000127, -11.420294112999954],
                [24.391283939000175, -11.413082286999952],
                [24.376283939000189, -11.404382286999976],
                [24.37088393900018, -11.401982286999896],
                [24.367451520000088, -11.399876029999973],
                [24.366483939000091, -11.399282286999949],
                [24.358583939000141, -11.396282286999906],
                [24.358550236000099, -11.396280956999874],
                [24.350983939000116, -11.395982286999867],
                [24.346883939000179, -11.397382286999971],
                [24.342783939000071, -11.39958228699993],
                [24.332845003000159, -11.402159047999874],
                [24.331983939000111, -11.402382286999966],
                [24.321583939000107, -11.402382286999966],
                [24.321550439000191, -11.402376286999981],
                [24.31488393900014, -11.40118228699987],
                [24.314871439000171, -11.40118228699987],
                [24.309683939000138, -11.40118228699987],
                [24.309648539000023, -11.401176286999828],
                [24.297883939000087, -11.399182286999974],
                [24.281583939000029, -11.392882286999964],
                [24.278383939000094, -11.390682286999834],
                [24.274883939000063, -11.384082286999956],
                [24.275883939000039, -11.378182286999959],
                [24.278283939000175, -11.374782286999903],
                [24.283283939000171, -11.370282286999895],
                [24.286283939000043, -11.368382286999918],
                [24.299983939000128, -11.3652822869999],
                [24.315883939000116, -11.363182286999972],
                [24.337583939000012, -11.354082286999869],
                [24.343283939, -11.347882286999891],
                [24.349383939000063, -11.339082286999883],
                [24.353683939000064, -11.334882286999914],
                [24.356183939000118, -11.328782286999967],
                [24.359583939000061, -11.321782286999962],
                [24.363783939000143, -11.314682286999869],
                [24.37048393900011, -11.302482286999918],
                [24.375683939000112, -11.28818228699987],
                [24.383083939000073, -11.277482286999884],
                [24.387783939000144, -11.260082286999875],
                [24.392483939000158, -11.242782286999955],
                [24.393983939000123, -11.233582286999876],
                [24.394283939000047, -11.227382286999898],
                [24.3915839390001, -11.220682286999875],
                [24.391883939000081, -11.208782286999963],
                [24.388583939000057, -11.19788228699997],
                [24.385383939000121, -11.191082286999915],
                [24.381783939000172, -11.184382286999949],
                [24.37658393900017, -11.176482286999828],
                [24.375283939000042, -11.170782286999895],
                [24.375683939000112, -11.167282286999978],
                [24.378383939000059, -11.154482286999951],
                [24.379983939000113, -11.14508228699998],
                [24.381183939000096, -11.141482286999974],
                [24.383283939000137, -11.131982286999914],
                [24.38448393900012, -11.125382286999979],
                [24.384883939000133, -11.118682286999899],
                [24.384283939000056, -11.114382286999955],
                [24.384183939000138, -11.106882286999962],
                [24.379183939000143, -11.096682286999965],
                [24.374083939000059, -11.088782286999901],
                [24.360083939000049, -11.080582286999913],
                [24.349383939000063, -11.072882286999914],
                [24.331483939000179, -11.058982286999935],
                [24.325783939000132, -11.055882286999918],
                [24.312164555000038, -11.050499775999981],
                [24.309083939000061, -11.049282286999812],
                [24.300983939000048, -11.046082286999876],
                [24.293083939000098, -11.043782286999942],
                [24.293054090000055, -11.043780989999902],
                [24.288483939000173, -11.043582286999879],
                [24.285631070000136, -11.04324485099994],
                [24.279052733000185, -11.042466767999883],
                [24.269883939000067, -11.041382286999863],
                [24.253483939000091, -11.041482286999951],
                [24.244483939000077, -11.041382286999863],
                [24.24183999700017, -11.041511259999936],
                [24.232183939000038, -11.041982286999939],
                [24.232164116000092, -11.041975010999977],
                [24.224283939000088, -11.039082286999871],
                [24.218954287000031, -11.036874287999979],
                [24.217283939000026, -11.036182286999917],
                [24.211783939000043, -11.033882286999869],
                [24.208483939000189, -11.031082286999947],
                [24.203483939000023, -11.028682286999924],
                [24.198283939000191, -11.026982286999953],
                [24.193483939000032, -11.023682286999872],
                [24.19048393900016, -11.021282286999963],
                [24.181783939000127, -11.017582286999982],
                [24.181743206000192, -11.017578527999945],
                [24.175283939000167, -11.016982286999905],
                [24.173933414000089, -11.017559073999962],
                [24.165683939000075, -11.021082286999956],
                [24.16028393900001, -11.02378228699996],
                [24.156083939000155, -11.027582286999973],
                [24.150783939, -11.029482286999951],
                [24.150751853000031, -11.029471298999965],
                [24.136183939000148, -11.024482286999898],
                [24.132374997000113, -11.019876124999882],
                [24.131883939000147, -11.019282286999953],
                [24.128883939000104, -11.014182286999869],
                [24.127983939000046, -11.009282286999905],
                [24.129783939000106, -10.987882286999934],
                [24.126483939000082, -10.968182286999934],
                [24.119383939000102, -10.960782286999972],
                [24.117083939000054, -10.957182286999966],
                [24.113483939000048, -10.951582286999894],
                [24.113883939000118, -10.946282286999974],
                [24.115683939000178, -10.938782286999981],
                [24.11728393900006, -10.932582286999946],
                [24.117483939000067, -10.927482286999918],
                [24.117783939000049, -10.921082286999933],
                [24.115883939000014, -10.913782286999947],
                [24.106539695000151, -10.905038131999959],
                [24.104983939000192, -10.903582286999949],
                [24.104941801999985, -10.903579901999933],
                [24.099683939000101, -10.903282286999911],
                [24.099669491000157, -10.90327807299991],
                [24.094883939000113, -10.901882286999978],
                [24.090683939000087, -10.898882286999935],
                [24.079083939000043, -10.895782286999918],
                [24.077777580000031, -10.895338617999926],
                [24.068483939000146, -10.892182286999969],
                [24.059783939000113, -10.890182286999959],
                [24.059758840000086, -10.890181091999921],
                [24.051383939000175, -10.889782286999889],
                [24.046283939000091, -10.889582286999939],
                [24.035483939000187, -10.888882286999944],
                [24.029883939000115, -10.888682286999938],
                [24.02358393899999, -10.889582286999939],
                [24.014883939000129, -10.889582286999939],
                [24.006483939000191, -10.891082286999904],
                [23.995683939000116, -10.894682286999966],
                [23.99224701900016, -10.896182913999951],
                [23.993637084000113, -10.894323348999933],
                [23.994805368000186, -10.892763075999881],
                [23.994904200000178, -10.892630894999968],
                [23.988660813000081, -10.892125128999965],
                [23.984001160000105, -10.894142150999926],
                [23.979999542000087, -10.897000311999818],
                [23.975830077000182, -10.901765822999948],
                [23.97299957199999, -10.904999731999908],
                [23.96999931400012, -10.920999526999822],
                [23.95999908499999, -10.937999725999816],
                [23.949810028000115, -10.949547766999956],
                [23.944999695000092, -10.954999922999889],
                [23.926000595000176, -10.967000006999967],
                [23.916999818000193, -10.972999572999981],
                [23.911378860000184, -10.97567653699997],
                [23.910442352000132, -10.976122378999889],
                [23.896097184000155, -10.98319387399988],
                [23.896064759000069, -10.983219146999943],
                [23.891483307000044, -10.986763953999969],
                [23.890542984000149, -10.987491130999899],
                [23.887992859000121, -10.9932193759999],
                [23.885383606000119, -11.001319885999919],
                [23.885000229000127, -11.00179672299987],
                [23.88466072000017, -11.002218245999927],
                [23.88269805800013, -11.004654883999933],
                [23.871999741000025, -11.017000197999948],
                [23.862998962000063, -11.022999763999962],
                [23.855834962000074, -11.026256561999958],
                [23.854153460000191, -11.02689632299996],
                [23.852914810000186, -11.027367590999972],
                [23.85203170800014, -11.027704238999945],
                [23.830369949000101, -11.029966352999963],
                [23.815473556000086, -11.027855871999975],
                [23.811679840000068, -11.02593994199998],
                [23.803306580000026, -11.021707533999972],
                [23.802652360000081, -11.021355627999924],
                [23.801998139000034, -11.021003722999922],
                [23.780830382000033, -11.006158827999911],
                [23.763383865000037, -10.997372627999937],
                [23.744798660000185, -10.992323874999897],
                [23.736856460000126, -10.993348836999871],
                [23.730550766000079, -10.997751235999885],
                [23.719816208000111, -11.003591536999977],
                [23.718408585000077, -11.004299163999974],
                [23.71700096100011, -11.005006790999971],
                [23.70793914900014, -11.007818222999902],
                [23.702127457000131, -11.006632804999981],
                [23.693048476000115, -11.004787445999966],
                [23.688913345000174, -11.005511283999908],
                [23.687029838, -11.005840777999879],
                [23.682604328000139, -11.006616390999966],
                [23.682374954000181, -11.006656645999897],
                [23.681887626000162, -11.006741999999861],
                [23.681400297999971, -11.006827353999881],
                [23.677642822000109, -11.007372856999837],
                [23.671998977000101, -11.008000373999948],
                [23.663999557000068, -11.006999968999878],
                [23.65099906800009, -11.003000258999975],
                [23.646888733000083, -11.000534056999925],
                [23.635999680000168, -10.994000434999975],
                [23.628999710000073, -10.991000174999954],
                [23.610298156000113, -10.992935179999961],
                [23.610191345000032, -10.992946146999941],
                [23.600000381000086, -10.994000434999975],
                [23.585000991000186, -10.993000030999895],
                [23.584369659000174, -10.992789745999971],
                [23.583738327000162, -10.992579460999821],
                [23.576206206000165, -10.989500998999915],
                [23.57596492700003, -10.989270686999873],
                [23.575723648, -10.989040374999831],
                [23.571404452000081, -10.984913952999932],
                [23.559389114000112, -10.973435400999904],
                [23.540447235000045, -10.95948886799988],
                [23.535997392000013, -10.958333015999813],
                [23.535081864000119, -10.958095073999914],
                [23.534166336000055, -10.957857131999958],
                [23.52495575, -10.954124450999927],
                [23.505569458000025, -10.946266173999959],
                [23.48671341000005, -10.943127630999982],
                [23.458999634000122, -10.939000129999897],
                [23.442935944000055, -10.938301084999978],
                [23.435529709000036, -10.938199997999959],
                [23.429000855000027, -10.940481184999953],
                [23.419161797000015, -10.944471358999976],
                [23.406332016000135, -10.952206611999884],
                [23.384000777000097, -10.965999602999887],
                [23.360000610000043, -10.982999801999881],
                [23.347000123000043, -10.994000434999975],
                [23.337999344000025, -11.003000258999975],
                [23.33640766200017, -11.003397940999946],
                [23.332000733000143, -11.006999968999878],
                [23.319999695000149, -11.012999534999892],
                [23.303541184000039, -11.022556305999956],
                [23.303532919000133, -11.022529284999962],
                [23.28897762299999, -11.031018256999971],
                [23.271999359000176, -11.045999526999879],
                [23.252000809000151, -11.05900001599997],
                [23.225000381000086, -11.08699989299987],
                [23.215999602000068, -11.093999862999965],
                [23.202999115000068, -11.102000235999867],
                [23.19700050400013, -11.104000091999978],
                [23.183000565000043, -11.102000235999867],
                [23.166620254, -11.096539972999892],
                [23.159517288000131, -11.094735145999948],
                [23.152202605000184, -11.093229292999979],
                [23.141942977000042, -11.095480917999851],
                [23.132099151000034, -11.101600646999941],
                [23.126520157000073, -11.105069159999971],
                [23.111337662000039, -11.113770484999918],
                [23.101999283000168, -11.118999479999957],
                [23.090677262000156, -11.122144699999978],
                [23.083999634000122, -11.123999594999873],
                [23.072999954000181, -11.123000144999935],
                [23.057788849000076, -11.117806433999931],
                [23.031999588000133, -11.109000205999962],
                [23.014282227000081, -11.10014152399998],
                [23.002171517000079, -11.09923887199983],
                [22.989374160000068, -11.099929808999889],
                [22.976068497000028, -11.100649832999977],
                [22.966775894000023, -11.099371909999888],
                [22.958999634, -11.097999572999981],
                [22.94814682100008, -11.094201087999977],
                [22.938999177000142, -11.090999602999943],
                [22.92099952600006, -11.08699989299987],
                [22.919209480000063, -11.086368082999911],
                [22.903999329000044, -11.08100032699997],
                [22.892810822000058, -11.074735640999961],
                [22.879369735000182, -11.06653976399997],
                [22.867282867000029, -11.06187534299994],
                [22.859134675000178, -11.06159687],
                [22.848513604000061, -11.069367884999906],
                [22.847480774000189, -11.070520400999953],
                [22.840999603000171, -11.07699966399997],
                [22.833003998000038, -11.081022262999909],
                [22.832697869000185, -11.081061839999961],
                [22.820999145000144, -11.083000182999967],
                [22.812261582000076, -11.083080767999945],
                [22.805908204000048, -11.093129634999968],
                [22.805832852000037, -11.093286998999929],
                [22.800878524999973, -11.105656624999881],
                [22.799352646000102, -11.106820106999976],
                [22.79782676700006, -11.107983588999957],
                [22.792999268000074, -11.111000059999867],
                [22.787000657000135, -11.112999915999978],
                [22.765596389000166, -11.113522528999965],
                [22.746000291000087, -11.114000320999935],
                [22.722999573000152, -11.109999655999957],
                [22.722125054, -11.109663008999973],
                [22.70956993100009, -11.105340002999981],
                [22.700885772000049, -11.102721214999917],
                [22.694000244000165, -11.100999831999957],
                [22.693416596000191, -11.099832533999916],
                [22.692790704000117, -11.098955111999885],
                [22.692697526000188, -11.098911761999943],
                [22.67123794500003, -11.089459418999979],
                [22.659154891000185, -11.084819792999951],
                [22.653579712000123, -11.082678794999936],
                [22.642763138000078, -11.076104164999947],
                [22.610000609999986, -11.055999755999949],
                [22.600826263000044, -11.048660278999932],
                [22.584999084000117, -11.036000250999962],
                [22.572999954000124, -11.031000136999978],
                [22.562000274000184, -11.031000136999978],
                [22.554208756000151, -11.033291815999974],
                [22.545131683000079, -11.036418914999956],
                [22.53368186900002, -11.041171072999873],
                [22.517339706000143, -11.042017935999979],
                [22.508333206000145, -11.043439387999967],
                [22.506515503000116, -11.04448890599997],
                [22.502670288000161, -11.046709059999955],
                [22.497512817000029, -11.051505087999942],
                [22.49112892200003, -11.060766219999891],
                [22.489031792000048, -11.068432807999898],
                [22.4890346520001, -11.068466186999899],
                [22.489999772000033, -11.081999778999943],
                [22.497999192000066, -11.097999572999981],
                [22.497860909000053, -11.100076197999897],
                [22.497367859000121, -11.111415861999888],
                [22.497310638000101, -11.112920759999895],
                [22.495794296000042, -11.117742538999948],
                [22.495362281999974, -11.119117259999939],
                [22.494930268000132, -11.120491981999976],
                [22.487358093000068, -11.131872175999945],
                [22.487292608000018, -11.131971357999873],
                [22.487161637000156, -11.132169721999958],
                [22.483352661000083, -11.134301184999913],
                [22.467279435000137, -11.143301008999913],
                [22.45580482500003, -11.15717983199994],
                [22.451999664000027, -11.161999701999946],
                [22.437000274000127, -11.175000190999924],
                [22.426000596000051, -11.178999900999941],
                [22.370000839000113, -11.175999640999862],
                [22.350999831000081, -11.177000044999943],
                [22.347000122000168, -11.178999900999941],
                [22.34399032500005, -11.186901091999971],
                [22.339000702000135, -11.199999807999916],
                [22.333999634000065, -11.206999778999943],
                [22.317720414000121, -11.223278998999945],
                [22.316465378000032, -11.224484442999938],
                [22.299690247000171, -11.243968963999976],
                [22.292526246000136, -11.249231337999902],
                [22.291262627000094, -11.250125406999928],
                [22.289999008000109, -11.251019476999886],
                [22.27799987800006, -11.24999999899984],
                [22.272432327000104, -11.246288775999972],
                [22.271999359000176, -11.246000288999937],
                [22.268598557000189, -11.241981505999945],
                [22.260999679000065, -11.232999801999938],
                [22.260839462000035, -11.232520101999967],
                [22.260919180000087, -11.232615161999888],
                [22.259162870000068, -11.227435450999963],
                [22.259035110000184, -11.227100372999871],
                [22.259042269000133, -11.227079771999854],
                [22.258871079000187, -11.22657489799991],
                [22.269999503000065, -11.195106029999863],
                [22.268947601000093, -11.182319639999889],
                [22.263719559000037, -11.16594886799993],
                [22.259742737000181, -11.158337592999885],
                [22.254306793000183, -11.147933005999846],
                [22.246248245000061, -11.135680198999978],
                [22.241300583000168, -11.130713461999903],
                [22.237932205000163, -11.127331732999949],
                [22.232519149000098, -11.122017858999925],
                [22.226999283000112, -11.116999625999938],
                [22.224527360000138, -11.111643790999949],
                [22.221000672000173, -11.104000091999978],
                [22.217000962000157, -11.088000296999951],
                [22.217000962000157, -11.086134909999885],
                [22.217000962000157, -11.084269522999932],
                [22.217000962000157, -11.072999953999954],
                [22.217651368000134, -11.068763732999912],
                [22.219638824000128, -11.055849075999959],
                [22.221000672000173, -11.046999930999959],
                [22.22003364699998, -11.035404204999963],
                [22.216854095000031, -11.025494575999971],
                [22.209985733000167, -11.013007162999884],
                [22.207000733000086, -10.998000144999878],
                [22.208000183000024, -10.953999517999819],
                [22.206697464000115, -10.948142050999934],
                [22.206348419000108, -10.946570871999938],
                [22.198999404000176, -10.930000303999975],
                [22.195513726000058, -10.925351141999897],
                [22.194725036000023, -10.924299238999879],
                [22.187000274000184, -10.914000510999813],
                [22.185969352000143, -10.912557600999889],
                [22.181964875000176, -10.907446861999972],
                [22.181829452000102, -10.895488738999973],
                [22.179069520000155, -10.891320228999973],
                [22.178979873000117, -10.89100551599995],
                [22.178931278000107, -10.890836021999974],
                [22.178743362000034, -10.890180587999964],
                [22.174316407000106, -10.874724387999947],
                [22.176017762000072, -10.864003180999873],
                [22.176031112000146, -10.863924979999979],
                [22.17677307200006, -10.862080573999947],
                [22.180999755000073, -10.852000235999981],
                [22.191999436000117, -10.8420000079999],
                [22.200080872000115, -10.837285993999956],
                [22.203725814999984, -10.835160254999948],
                [22.203740351000135, -10.835151814999961],
                [22.203837077000173, -10.83509540499989],
                [22.214834213000017, -10.826486587999909],
                [22.232000350000078, -10.81299972599993],
                [22.240497588000039, -10.807051657999921],
                [22.241249083000184, -10.806525706999935],
                [22.242000579000148, -10.805999755999949],
                [22.245574951000151, -10.804570197999965],
                [22.266641616000186, -10.795639991999963],
                [22.267000198000176, -10.795587061999981],
                [22.282693863000077, -10.775596617999952],
                [22.297540664000053, -10.78375148799995],
                [22.310878754000157, -10.782940386999883],
                [22.310949325000081, -10.782870291999927],
                [22.317178726000066, -10.779001235999942],
                [22.31893158000014, -10.777913092999938],
                [22.327861786000085, -10.772228240999937],
                [22.334419251000043, -10.755910872999891],
                [22.334619521000093, -10.747871398999962],
                [22.334228516000167, -10.742601870999863],
                [22.331558228000063, -10.737092016999952],
                [22.329000473000065, -10.732999801999938],
                [22.328889848000074, -10.729935645999888],
                [22.32884311700019, -10.728618143999938],
                [22.328161239000053, -10.70952415499994],
                [22.327585125000041, -10.7037542569999],
                [22.327108384000098, -10.702324866999959],
                [22.324222564000081, -10.690052031999926],
                [22.31597709700003, -10.678723333999812],
                [22.314941407000163, -10.677299499999947],
                [22.31411743100017, -10.67616653399989],
                [22.316329957000107, -10.666683196999941],
                [22.316485406000083, -10.666020869999954],
                [22.316640854000184, -10.665358542999968],
                [22.318899155000054, -10.65573978399982],
                [22.318996429000094, -10.644966601999897],
                [22.316613515000142, -10.608319599999959],
                [22.316589356000179, -10.607959747999928],
                [22.316156388000024, -10.601248740999949],
                [22.31611824099997, -10.600654601999906],
                [22.316080093000039, -10.600060462999977],
                [22.316421509000065, -10.593688011999916],
                [22.316886903000182, -10.586699484999883],
                [22.316999436000174, -10.585000036999872],
                [22.317314148000094, -10.582494734999898],
                [22.318349839000064, -10.57662200999988],
                [22.315811157000155, -10.561678886999914],
                [22.314790725000137, -10.555669784999964],
                [22.315886021000154, -10.542122601999949],
                [22.310214996000127, -10.535464762999879],
                [22.288412095000126, -10.520732878999922],
                [22.287824631000035, -10.520465850999926],
                [22.28345489600008, -10.518479347999971],
                [22.279331208000087, -10.516605376999905],
                [22.278665543000159, -10.516302584999892],
                [22.27799987800006, -10.515999793999981],
                [22.27799987800006, -10.508452414999908],
                [22.283800125000084, -10.505289076999929],
                [22.288510005000091, -10.504122097999925],
                [22.292699814000059, -10.497540473999948],
                [22.294912338000188, -10.4814605709999],
                [22.295949937000159, -10.466979026999923],
                [22.30900573800011, -10.450914381999894],
                [22.309166909, -10.450716494999881],
                [22.310358046999966, -10.436731338999948],
                [22.316482544000053, -10.426658629999906],
                [22.322821617000045, -10.420215128999928],
                [22.32283782900015, -10.420162200999926],
                [22.324651719000144, -10.414278029999934],
                [22.329242707000049, -10.399392126999942],
                [22.328080177000118, -10.386781374999941],
                [22.311559678000037, -10.368402479999929],
                [22.309770930000184, -10.352549809999914],
                [22.309757233000028, -10.35243034399997],
                [22.309670305000111, -10.35165929599998],
                [22.309566497000048, -10.350738524999883],
                [22.31412124600007, -10.350279807999868],
                [22.312498092000055, -10.340161323999951],
                [22.306699117000107, -10.327610650999929],
                [22.304090500000029, -10.323660850999886],
                [22.297983170000066, -10.315367221999963],
                [22.291000367000095, -10.310000418999948],
                [22.284999848000155, -10.307000159999916],
                [22.255477905000077, -10.306337356999961],
                [22.246404648000123, -10.299803732999919],
                [22.240633012000103, -10.290001868999923],
                [22.261251451000135, -10.263864516999945],
                [22.248054505000141, -10.240992544999926],
                [22.24475669800006, -10.230372428999885],
                [22.244921685000122, -10.221223115999976],
                [22.242377176000161, -10.218547666999939],
                [22.238994598000033, -10.215006826999968],
                [22.23897743200007, -10.214972971999941],
                [22.238960266000049, -10.214939116999972],
                [22.236717806000115, -10.210439503999908],
                [22.236097336000114, -10.209194658999934],
                [22.236000060000094, -10.208999632999962],
                [22.23718643300009, -10.205047607999973],
                [22.238599777000161, -10.198849677999931],
                [22.235142835000147, -10.181862024999816],
                [22.234976375000031, -10.181400891999942],
                [22.233207702000129, -10.176504134999959],
                [22.225439072000029, -10.163394927999946],
                [22.222999573000095, -10.159000396999943],
                [22.222999573000095, -10.144538879999971],
                [22.223013878000131, -10.143924235999975],
                [22.223028182000064, -10.143309592999969],
                [22.224239349000186, -10.137108801999887],
                [22.220125199000108, -10.129250525999964],
                [22.224835714000051, -10.120401222999874],
                [22.225582711000129, -10.111416116999919],
                [22.225055695000037, -10.109412193999958],
                [22.221000672000173, -10.093999861999976],
                [22.22080239200011, -10.092425553999931],
                [22.220794678000175, -10.092364309999937],
                [22.220611572000109, -10.090917586999922],
                [22.219440459000111, -10.081648825999878],
                [22.221302508000122, -10.082340336999891],
                [22.221387863000132, -10.080619095999964],
                [22.224480629000084, -10.069569108999872],
                [22.221311569000022, -10.061454771999877],
                [22.219320298000071, -10.056359290999978],
                [22.213163374999965, -10.042519568999921],
                [22.212614059000032, -10.037405966999927],
                [22.212566376000041, -10.036959170999921],
                [22.212518692, -10.036512374999973],
                [22.2140827180001, -10.03186893499992],
                [22.214725495000096, -10.029959677999955],
                [22.215368271000159, -10.028050421999978],
                [22.21796226500004, -10.023362158999873],
                [22.21818351800016, -10.022962569999947],
                [22.218268712000054, -10.022808708999889],
                [22.217506410000055, -10.01707363099996],
                [22.211535454000057, -10.013996599999871],
                [22.209913253000025, -10.013982771999906],
                [22.201389313000107, -10.006098747999943],
                [22.201283456000169, -10.005861282999945],
                [22.201177598000129, -10.00562381799989],
                [22.198669433000134, -9.999998091999942],
                [22.19843483, -9.999470709999969],
                [22.198200226000154, -9.998943327999939],
                [22.203359603000081, -9.992087363999872],
                [22.204203605000089, -9.99126386599994],
                [22.206363679000106, -9.986447333999877],
                [22.203856469000186, -9.978428840999982],
                [22.203802109000094, -9.978260040999942],
                [22.20197868300005, -9.972415923999961],
                [22.201292992000049, -9.961367129999871],
                [22.201286316000051, -9.961354255999879],
                [22.190599442000064, -9.940387725999869],
                [22.191890717000092, -9.936081884999965],
                [22.191716055000029, -9.933066399999859],
                [22.191545487000099, -9.930129050999938],
                [22.191452980000179, -9.92852449399993],
                [22.184971491999988, -9.916891097999894],
                [22.184938360000103, -9.91687010499993],
                [22.183721543000047, -9.91611194599983],
                [22.167179108000084, -9.905803680999838],
                [22.166774750000059, -9.905361651999954],
                [22.159987482000133, -9.89796064899997],
                [22.157938004000016, -9.895726202999924],
                [22.157024384000124, -9.894729613999971],
                [22.153501012999982, -9.89215014399997],
                [22.152690888000052, -9.891658782999912],
                [22.151082993000102, -9.890379904999975],
                [22.143911362000154, -9.888170241999944],
                [22.136024475000113, -9.890685081999891],
                [22.135410309000122, -9.890553473999944],
                [22.134796143000074, -9.890421866999873],
                [22.129533768000158, -9.889292716999933],
                [22.129503250000141, -9.889266967999902],
                [22.126482010000075, -9.886638639999944],
                [22.124433517000114, -9.880947112999934],
                [22.12388420100018, -9.879422186999875],
                [22.123870790000183, -9.879384947999938],
                [22.117042541000046, -9.874095916999977],
                [22.111248017000037, -9.872934340999905],
                [22.095928192000144, -9.881407736999961],
                [22.093840598999975, -9.881633757999907],
                [22.091753006000033, -9.881859778999967],
                [22.084756851000179, -9.880007743999954],
                [22.083673477000048, -9.879205703999901],
                [22.083334477000051, -9.878954891999968],
                [22.081676006000066, -9.878532011999937],
                [22.080269302000033, -9.878075106999972],
                [22.080213547000142, -9.878071783999872],
                [22.079330444000163, -9.877782821999972],
                [22.075534821000076, -9.876537321999933],
                [22.071575165000183, -9.872596739999949],
                [22.071240426000088, -9.872263430999908],
                [22.070905686000117, -9.87193012299997],
                [22.069571454000027, -9.869330550999962],
                [22.065033913000036, -9.861356734999959],
                [22.064241409000033, -9.860679625999978],
                [22.05537796100009, -9.853108405999876],
                [22.05496692700018, -9.852751253999884],
                [22.054555893000099, -9.852394102999938],
                [22.051097870000092, -9.846940039999936],
                [22.050852775000124, -9.846501826999941],
                [22.04324831200006, -9.835335866999913],
                [22.034896851000155, -9.837099075999959],
                [22.029205323000099, -9.835946559999911],
                [22.028759004000051, -9.835856437999837],
                [22.024482726000087, -9.833795546999909],
                [22.018821717000037, -9.825368880999918],
                [22.017665864000037, -9.823648452999976],
                [22.01236152600012, -9.81575107599997],
                [22.009935379000183, -9.801706312999954],
                [22.007698059000177, -9.792259214999945],
                [22.003789901000118, -9.789007186999982],
                [22.002199172000189, -9.784626959999912],
                [22.002931596000053, -9.780636787999981],
                [22.003176690000089, -9.77930307399987],
                [22.003421784000125, -9.777969359999929],
                [22.003614394000067, -9.777177909999978],
                [22.006416004000016, -9.763575393999929],
                [22.006153107000046, -9.757304191999879],
                [22.001355236000165, -9.752988045999928],
                [21.999790191000102, -9.753917692999949],
                [21.999439240000072, -9.753540037999926],
                [21.999074935000067, -9.753149031999897],
                [21.995597840000073, -9.75360012099992],
                [21.985837937000156, -9.764149664999934],
                [21.983752507000077, -9.764029461999826],
                [21.981666565000126, -9.763909338999895],
                [21.977504730000135, -9.759991645999946],
                [21.972261429000184, -9.742123602999982],
                [21.971035004000044, -9.7379436499999],
                [21.968999863000136, -9.731006621999882],
                [21.959218978000081, -9.725913047999882],
                [21.95180893, -9.715177058999927],
                [21.951263428000175, -9.715021132999937],
                [21.944250106000027, -9.713014601999817],
                [21.943008423000038, -9.711775778999879],
                [21.942321280000158, -9.711090277999915],
                [21.941013337000186, -9.709785460999967],
                [21.93963623000019, -9.704956053999979],
                [21.939350129000047, -9.697985649999964],
                [21.938346863000163, -9.691609858999868],
                [21.934242248000089, -9.68677711499987],
                [21.930460502000187, -9.686252153999874],
                [21.929025650000142, -9.686053275999939],
                [21.928918838000186, -9.68603801699993],
                [21.926324844000135, -9.685153960999969],
                [21.92525005400006, -9.684787749999941],
                [21.917139053000142, -9.682024954999974],
                [21.912084579000066, -9.677151678999906],
                [21.903835297000171, -9.671677589999945],
                [21.898523331000149, -9.668151854999906],
                [21.898953438000092, -9.666382311999939],
                [21.898959161000107, -9.66048049799997],
                [21.894813537000118, -9.656793594999897],
                [21.885553361000063, -9.654469490999873],
                [21.880233764000025, -9.650776862999976],
                [21.879795075000061, -9.648801325999955],
                [21.878097534, -9.641161916999977],
                [21.87436199300015, -9.628436565999948],
                [21.873294831000123, -9.627716064999902],
                [21.870986938000044, -9.626155853999876],
                [21.871110916000134, -9.622985839999956],
                [21.871242523000149, -9.622735975999888],
                [21.871955871000182, -9.621392250999975],
                [21.875352860000135, -9.614978789999952],
                [21.872120858000073, -9.605681895999965],
                [21.86957550000011, -9.601834296999925],
                [21.865500450000127, -9.594184874999939],
                [21.86464500400001, -9.592579840999918],
                [21.867429630000061, -9.583368858999961],
                [21.867733955000062, -9.581662653999899],
                [21.868088723000142, -9.566115855999897],
                [21.864152908000165, -9.554529189999926],
                [21.846040727000172, -9.537822721999874],
                [21.844135285000107, -9.531070708999891],
                [21.835739136000029, -9.51645660399987],
                [21.835571290000189, -9.51590585699995],
                [21.835403443000075, -9.515355109999973],
                [21.832351685000162, -9.498613356999954],
                [21.830301284000029, -9.489045143999874],
                [21.830383610000126, -9.487350966999941],
                [21.83038902200019, -9.487239599999896],
                [21.83044624300004, -9.486062048999884],
                [21.830432893000022, -9.485266684999886],
                [21.830448151000041, -9.485238075999916],
                [21.833072662, -9.480343818999927],
                [21.835008622000146, -9.471396446999961],
                [21.834838867000087, -9.46495723799984],
                [21.829359055000168, -9.457092283999941],
                [21.822608948000038, -9.452607154999839],
                [21.821987152000133, -9.452194214999963],
                [21.808202744000027, -9.43910789399996],
                [21.797969819000059, -9.430346488999874],
                [21.797645569000053, -9.429562567999938],
                [21.797109865000095, -9.428268107999941],
                [21.794691086000114, -9.422428129999901],
                [21.794378281000036, -9.421667574999958],
                [21.794065476000185, -9.420907020999948],
                [21.797290802000077, -9.41168022099987],
                [21.797862053000188, -9.411209105999888],
                [21.802671432000125, -9.406919477999963],
                [21.803104400000052, -9.399634361999915],
                [21.804509162000102, -9.391229628999952],
                [21.804666517999976, -9.390286445999891],
                [21.805374146000077, -9.387198448999925],
                [21.805561066000109, -9.386678695999819],
                [21.805747986000142, -9.386158942999884],
                [21.808334350000052, -9.378976820999981],
                [21.811885834000122, -9.363872050999873],
                [21.811824798000032, -9.363675116999957],
                [21.809030533000055, -9.354619979999882],
                [21.809067726000023, -9.354516982999883],
                [21.814165982000134, -9.339746574999936],
                [21.814170838000166, -9.339454649999937],
                [21.814899445000151, -9.337304115999814],
                [21.818410874000165, -9.329294203999893],
                [21.826765061000117, -9.321276664999914],
                [21.82845306500019, -9.298072814999898],
                [21.831456500000058, -9.295107163999944],
                [21.831567764000056, -9.294995307999898],
                [21.83164397000013, -9.294920737999973],
                [21.835392953000053, -9.291066645999933],
                [21.835603715000104, -9.290704726999934],
                [21.837518692000174, -9.287424086999977],
                [21.838764191000109, -9.286003111999946],
                [21.846219861000066, -9.262934173999895],
                [21.847848892000115, -9.257255554999972],
                [21.847949981000056, -9.257112502999974],
                [21.848239349000039, -9.256699646999891],
                [21.857532502000026, -9.242488859999867],
                [21.858971596000117, -9.237196444999938],
                [21.863083362000111, -9.202956437999944],
                [21.861156463000157, -9.187644004999925],
                [21.861076354000147, -9.180143356999906],
                [21.860740980000116, -9.17076269699993],
                [21.856250763000105, -9.160131453999952],
                [21.849014281, -9.144375800999967],
                [21.848899841000161, -9.144027708999943],
                [21.848396246000107, -9.142497520999939],
                [21.847835541000165, -9.140793799999926],
                [21.84584751400007, -9.13051911999986],
                [21.843722184000114, -9.120530605999932],
                [21.843684602, -9.119328148999841],
                [21.843650817000139, -9.1191530239999],
                [21.843595505, -9.116597175999914],
                [21.843588830000101, -9.116263866999873],
                [21.842966080000053, -9.086951254999974],
                [21.842844009000032, -9.081231116999902],
                [21.843429565000122, -9.068137167999907],
                [21.851476670000068, -9.057382583999925],
                [21.851623536000147, -9.057186126999852],
                [21.857707978000178, -9.055635451999876],
                [21.858143808000023, -9.055524348999938],
                [21.862079621000134, -9.051538467999933],
                [21.859149933000026, -9.046348571999943],
                [21.852302550000047, -9.034212110999931],
                [21.850987339000028, -9.025710832999891],
                [21.8509693150001, -9.025671005999868],
                [21.850789639000027, -9.024518022999871],
                [21.849427223000077, -9.018222808999894],
                [21.849384307999969, -9.018045425999901],
                [21.85365295400004, -9.006356237999967],
                [21.853530883000133, -9.001366614999938],
                [21.856742858000075, -8.979570387999956],
                [21.857606889000067, -8.961194991999946],
                [21.863363266000192, -8.938282965999918],
                [21.863602639000192, -8.937330721999899],
                [21.863962928999968, -8.935897281999871],
                [21.865472793000038, -8.929888724999955],
                [21.865938673000016, -8.928035181999974],
                [21.864837647000058, -8.921614646999956],
                [21.863365174000023, -8.916192054999897],
                [21.858940124000185, -8.908261298999889],
                [21.863348008000173, -8.898904800999958],
                [21.866115570000034, -8.893029211999931],
                [21.866239548000181, -8.892765997999959],
                [21.870632172000171, -8.88344097099997],
                [21.868629455000075, -8.869750021999892],
                [21.868519783000067, -8.868996618999972],
                [21.868410110000184, -8.868243216999929],
                [21.871742249000079, -8.857479094999974],
                [21.872428895000041, -8.856621741999959],
                [21.873115540000128, -8.855764388999944],
                [21.877584457000125, -8.85018062499995],
                [21.878046035000125, -8.85018539399988],
                [21.883209228, -8.841272354999887],
                [21.88099289000013, -8.831604003999928],
                [21.885829926000156, -8.821949958999937],
                [21.89211463800001, -8.809410094999976],
                [21.894438745000059, -8.803858279999872],
                [21.898571014000083, -8.780134200999896],
                [21.898680960000092, -8.779733490999888],
                [21.897871018000046, -8.77881813099998],
                [21.900819779000074, -8.771938323999905],
                [21.902984620000041, -8.766888618999872],
                [21.898582459000068, -8.742754935999926],
                [21.898408891000088, -8.741807936999919],
                [21.898204804000159, -8.740690231999906],
                [21.898366929000133, -8.739877700999898],
                [21.898927688000128, -8.737071036999964],
                [21.899039268000081, -8.736511706999863],
                [21.899033646000191, -8.731892240999912],
                [21.898660660000019, -8.718971251999903],
                [21.899016381000081, -8.71770715599996],
                [21.89937210100004, -8.716443060999893],
                [21.90176010000016, -8.707971572999952],
                [21.905023575000087, -8.702280996999889],
                [21.907394409000119, -8.698146820999966],
                [21.910509110000191, -8.685770987999888],
                [21.915964237000082, -8.671742282999901],
                [21.915733338000052, -8.670897483999909],
                [21.91631049800003, -8.669909331999861],
                [21.916727066000078, -8.669196128999886],
                [21.917327880000187, -8.668164252999873],
                [21.913919450000094, -8.652564048999977],
                [21.913505364000116, -8.650671356999965],
                [21.913299561000031, -8.64973068199987],
                [21.912883759000124, -8.647831915999973],
                [21.911853791000169, -8.643115996999939],
                [21.91218566900011, -8.641593454999907],
                [21.91251754699999, -8.640070913999978],
                [21.913255691000131, -8.636689185999956],
                [21.918457030000184, -8.628425597999922],
                [21.918636332000119, -8.628140848999976],
                [21.919377826000186, -8.626963284999931],
                [21.919691086000171, -8.62646579699998],
                [21.920904160000191, -8.624537468999904],
                [21.927966110000057, -8.621271285999967],
                [21.932922669000106, -8.617986260999942],
                [21.933628081, -8.612858771999981],
                [21.935987472000079, -8.608873366999887],
                [21.933410644000048, -8.604665754999928],
                [21.92260169900004, -8.59997272499993],
                [21.92323309000011, -8.598241842999812],
                [21.923707961000048, -8.596940040999925],
                [21.924020768000105, -8.596081732999949],
                [21.927557722000188, -8.593740018999938],
                [21.93072032900011, -8.590447902999927],
                [21.932737350000139, -8.57686710299987],
                [21.945072175000121, -8.565263748999939],
                [21.947214127000052, -8.557933807999973],
                [21.946851731000095, -8.547595977999947],
                [21.942380906000096, -8.530559539999956],
                [21.944992066000111, -8.523003577999873],
                [21.951534270000025, -8.514622687999975],
                [21.95553478800008, -8.509427674999927],
                [21.955585480000025, -8.509276389999911],
                [21.957710267000039, -8.503007888999946],
                [21.953079223000032, -8.493953704999967],
                [21.948280335000163, -8.47580146699994],
                [21.951148987000181, -8.460103034999918],
                [21.95072174000012, -8.450712203999899],
                [21.942859649000184, -8.426527977999967],
                [21.933115959000077, -8.405822752999939],
                [21.925561434000031, -8.398759251999934],
                [21.924943924000104, -8.398280143999955],
                [21.924503326000092, -8.397820472999968],
                [21.924139024000112, -8.397438049999948],
                [21.923839570000041, -8.396991728999979],
                [21.92456054600018, -8.385069847999944],
                [21.925004959000091, -8.377692221999951],
                [21.924558639000111, -8.372295378999979],
                [21.924110413000164, -8.366898535999951],
                [21.923114776000091, -8.362636565999935],
                [21.920911790000105, -8.35320091199992],
                [21.921622277000154, -8.351766585999883],
                [21.922332764000089, -8.350332259999959],
                [21.925207139000122, -8.347052573999974],
                [21.925948144000074, -8.346393107999916],
                [21.926689148000094, -8.345733641999971],
                [21.931650161000164, -8.341320037999935],
                [21.934797286000048, -8.335473060999959],
                [21.935316086000171, -8.324187118999873],
                [21.930049261000022, -8.314517338999963],
                [21.929599762000066, -8.31413745899988],
                [21.922712327000113, -8.308314322999934],
                [21.91489982600001, -8.305487632999871],
                [21.909912110000164, -8.302398680999943],
                [21.900226593000184, -8.296399114999929],
                [21.900121689000059, -8.296262740999907],
                [21.899579978000077, -8.295558874999927],
                [21.898391723000088, -8.294014930999936],
                [21.896877289000031, -8.289450644999931],
                [21.897418977000086, -8.259836196999913],
                [21.895598730000188, -8.253289221999978],
                [21.895151252000119, -8.252275202999954],
                [21.893257142000039, -8.248421668999981],
                [21.892825127000094, -8.247004030999904],
                [21.889679054000112, -8.236976942999888],
                [21.887020112000016, -8.228542327999946],
                [21.886749268000187, -8.227639197999906],
                [21.886478424000131, -8.22673606799998],
                [21.8853282930001, -8.213888168999915],
                [21.883527756000092, -8.209136008999906],
                [21.879938125000024, -8.199654578999969],
                [21.87954425800018, -8.198613165999916],
                [21.879150391, -8.19757175399991],
                [21.881006239999977, -8.192755698999974],
                [21.886700154000039, -8.188082217999977],
                [21.889291763000131, -8.181729316999963],
                [21.887338639000177, -8.176902770999959],
                [21.886962891000167, -8.175973414999874],
                [21.87266159100011, -8.160910605999959],
                [21.866951943000061, -8.157385348999981],
                [21.866243363000024, -8.156948089999901],
                [21.854850769000109, -8.143988607999972],
                [21.854572296000072, -8.143452642999932],
                [21.848731995000094, -8.132221221999942],
                [21.845941544000084, -8.121783254999968],
                [21.843652726000016, -8.114154814999893],
                [21.833826065000096, -8.10017204299993],
                [21.827106477000143, -8.079906462999929],
                [21.819160462000184, -8.067435263999926],
                [21.816841126000156, -8.062387466999894],
                [21.810670852000101, -8.056427002999953],
                [21.810237884000173, -8.054973601999961],
                [21.809938840000143, -8.053969319999965],
                [21.796375275000116, -8.041049003999945],
                [21.789667130000055, -8.033124922999889],
                [21.78880977599999, -8.032111643999883],
                [21.78795242200016, -8.031098365999981],
                [21.786390304, -8.025115965999873],
                [21.781448365000131, -8.011753081999927],
                [21.774154663000104, -8.004339216999881],
                [21.771226883000111, -8.000438688999907],
                [21.760833739000134, -7.967982290999942],
                [21.760291098999971, -7.966285704999905],
                [21.759748458000104, -7.964589118999982],
                [21.759748458000104, -7.960807321999937],
                [21.759748458000104, -7.960355041999947],
                [21.747847558000103, -7.943184851999888],
                [21.747558594000168, -7.942783831999918],
                [21.745450973000118, -7.938799857999982],
                [21.744749070000012, -7.93294000599991],
                [21.744787217000066, -7.932678698999951],
                [21.745016354000143, -7.931122746999904],
                [21.745258332000049, -7.929479598999933],
                [21.759641648000184, -7.915382860999955],
                [21.765480042000092, -7.909659862999888],
                [21.765842439000153, -7.909313678999865],
                [21.766471053000089, -7.908786110999927],
                [21.769428254000104, -7.900227070999961],
                [21.770662308000055, -7.893671034999841],
                [21.770929337000041, -7.878870009999901],
                [21.762060166000083, -7.845355986999948],
                [21.764144897000165, -7.831388949999962],
                [21.764819335000027, -7.8307954899999],
                [21.765150705999986, -7.829046805999894],
                [21.765689849000125, -7.823444842999948],
                [21.763866424000128, -7.817929743999969],
                [21.765266419000056, -7.812196254999947],
                [21.769651412000087, -7.809456824999927],
                [21.771968724000146, -7.803923089999955],
                [21.770704269000078, -7.79713034599996],
                [21.769420624000134, -7.795627116999981],
                [21.769449233000103, -7.782350061999978],
                [21.768039704000103, -7.777423858999953],
                [21.780651093000074, -7.764410018999911],
                [21.783609391000084, -7.760000229999889],
                [21.785839081000177, -7.754673002999937],
                [21.784360886000172, -7.7454800599999],
                [21.786039353000035, -7.740512846999934],
                [21.792039872000089, -7.736014842999964],
                [21.795654297000112, -7.729125022999938],
                [21.79633903500013, -7.718548774999874],
                [21.796600342000147, -7.713862894999977],
                [21.807329178000032, -7.692550181999934],
                [21.811040879000075, -7.682251928999961],
                [21.812343598000155, -7.672507762999942],
                [21.81032943800011, -7.661837099999957],
                [21.812919617000091, -7.656507014999932],
                [21.823810578000177, -7.653029917999845],
                [21.82596397399999, -7.64690112999989],
                [21.829366683000103, -7.637219905999928],
                [21.828210831000035, -7.622169970999892],
                [21.836769105000087, -7.61754989699989],
                [21.84453010600015, -7.608734130999949],
                [21.847669601000121, -7.604880332999926],
                [21.848421096000038, -7.599909782999816],
                [21.842607498000064, -7.583052157999873],
                [21.845319748000122, -7.577109812999936],
                [21.851594924000096, -7.572095154999943],
                [21.852169037000067, -7.561840056999927],
                [21.853217601000154, -7.555323957999974],
                [21.848726273000182, -7.547598838999932],
                [21.848682403000112, -7.547527790999936],
                [21.838930130999984, -7.531720160999953],
                [21.836360931000058, -7.519999980999899],
                [21.840259551000031, -7.506090163999886],
                [21.848390579000124, -7.495800016999908],
                [21.851909637000119, -7.486425875999942],
                [21.856901170000015, -7.474777936999942],
                [21.856729508000058, -7.470243929999981],
                [21.851570129000095, -7.466010093999955],
                [21.846410752000168, -7.465261935999934],
                [21.845038414000101, -7.464146374999928],
                [21.84366607600009, -7.463030813999978],
                [21.843029023000042, -7.459079741999915],
                [21.843159676000084, -7.457844733999878],
                [21.84329032800008, -7.456609725999954],
                [21.84696960500014, -7.454040049999946],
                [21.848448754, -7.452831744999969],
                [21.85326004, -7.448900221999963],
                [21.855670928000052, -7.44246387499993],
                [21.852920531999985, -7.429214953999917],
                [21.853199959000108, -7.427837370999953],
                [21.851405145000115, -7.423689364999973],
                [21.849807596000176, -7.423246514999903],
                [21.846233368000071, -7.42260265199991],
                [21.846109390000095, -7.422580240999935],
                [21.844074250000119, -7.42095136699993],
                [21.839660645000038, -7.417419909999921],
                [21.829730988000108, -7.405080318999978],
                [21.828809739000178, -7.402870177999887],
                [21.828439712000034, -7.39993],
                [21.830209732000128, -7.396009920999916],
                [21.835556030000134, -7.390969275999964],
                [21.837957383000173, -7.383994578999932],
                [21.834754943000064, -7.372528075999981],
                [21.829229354000176, -7.365540027999941],
                [21.827949524000132, -7.358349798999882],
                [21.827499391000117, -7.348978041999942],
                [21.826393128000177, -7.344667909999942],
                [21.825130462000118, -7.339747904999967],
                [21.820322991000182, -7.328869580999822],
                [21.814990997000109, -7.324678897999888],
                [21.809456825000154, -7.321331261999944],
                [21.808479309000063, -7.320740221999813],
                [21.80285453800019, -7.313461779999955],
                [21.798574448000068, -7.311078070999884],
                [21.784332275000168, -7.292661665999958],
                [21.784339905000081, -7.287649153999894],
                [21.781297683000105, -7.284602164999967],
                [21.781190245000118, -7.278907437999976],
                [21.780939101000115, -7.278200147999883],
                [21.780905508000046, -7.278104886999927],
                [21.779689788000042, -7.274680137999951],
                [21.681056976000036, -7.277457712999933],
                [21.651288986000111, -7.278483390999952],
                [21.634904861000109, -7.279047964999961],
                [21.611391067000056, -7.280714987999943],
                [21.570602417000089, -7.283607005999954],
                [21.487123488000123, -7.287230013999874],
                [21.415372849000107, -7.281318186999954],
                [21.403638839000166, -7.280580996999959],
                [21.402594566, -7.27989363599994],
                [21.377521515000183, -7.279064177999942],
                [21.311569214000144, -7.278675079999914],
                [21.304449081000143, -7.278633117999902],
                [21.293207169000084, -7.278563974999941],
                [21.203820228000097, -7.278755425999975],
                [21.205167771000163, -7.284243583999967],
                [21.190391541000054, -7.284457683999904],
                [20.837554931999989, -7.292330264999976],
                [20.82167833799997, -7.293334229999914],
                [20.821346015000131, -7.292692128999931],
                [20.8210659020001, -7.292698382999959],
                [20.81498527499997, -7.280402183999911],
                [20.798599242000023, -7.280467033999969],
                [20.763420106000183, -7.280601977999936],
                [20.67206192000009, -7.280978678999929],
                [20.671649933000026, -7.28098010999986],
                [20.671237946000133, -7.28098154099996],
                [20.545526505000112, -7.281459807999909],
                [20.544616698000027, -7.281452655999942],
                [20.543514251000033, -7.281459807999909],
                [20.54351043600019, -7.281423091999898],
                [20.543366648000188, -7.279945493999946],
                [20.543029785000101, -7.279942776999917],
                [20.541885375000106, -7.264675616999966],
                [20.541749954000181, -7.244919775999961],
                [20.538808823000124, -7.234567640999956],
                [20.538837432000093, -7.228595255999892],
                [20.542356492000067, -7.213595865999935],
                [20.544004440000037, -7.206568718999961],
                [20.54315948499999, -7.198506830999975],
                [20.542463302000044, -7.191858766999928],
                [20.545143128000063, -7.160629748999838],
                [20.544609070000149, -7.140962599999966],
                [20.548749924000106, -7.128025053999977],
                [20.551584244000139, -7.112648009999873],
                [20.553760529000158, -7.108451843999944],
                [20.556692124000165, -7.102795123999897],
                [20.567342758000166, -7.088835716999881],
                [20.575254441000084, -7.069577216999903],
                [20.575372697000091, -7.059926986999926],
                [20.577207565000037, -7.050463198999978],
                [20.580080032000126, -7.035645006999971],
                [20.583717345000082, -7.024209975999895],
                [20.585617065000065, -7.018235205999929],
                [20.585889816000133, -7.017378329999815],
                [20.589508057000103, -7.005996227999958],
                [20.595478059000072, -6.997817991999909],
                [20.595903398000189, -6.997616528999913],
                [20.60993862100014, -6.990087031999906],
                [20.612335206000125, -6.984210491999875],
                [20.619869232000156, -6.975370882999812],
                [20.619112014000052, -6.970098017999874],
                [20.619143486000155, -6.969831942999974],
                [20.620511055000065, -6.957997083999885],
                [20.621191024000098, -6.936539172999915],
                [20.621345521000023, -6.931686400999979],
                [20.610385895000036, -6.926500319999946],
                [20.570360184000037, -6.921496390999891],
                [20.546524047000048, -6.918932914999971],
                [20.534328461000143, -6.919789790999971],
                [20.522115708000115, -6.924092769999959],
                [20.52051448900005, -6.924367904999883],
                [20.518913269000109, -6.924643039999921],
                [20.503929139000036, -6.927216053999928],
                [20.495397567000055, -6.931538103999969],
                [20.488496781000151, -6.931272028999956],
                [20.478164672000162, -6.927541731999952],
                [20.429185867000115, -6.929393767999954],
                [20.384401321000098, -6.927193164999892],
                [20.350471496000182, -6.926233767999975],
                [20.336219788000108, -6.926407335999954],
                [20.315179824000097, -6.928036211999938],
                [20.315902711000149, -6.932531832999928],
                [20.315479278, -6.938168048999955],
                [20.314212799000131, -6.938506126999869],
                [20.311235427000156, -6.948722838999913],
                [20.312046050000163, -6.954539775999933],
                [20.308877944000074, -6.971769808999909],
                [20.301575661000072, -6.981076000999963],
                [20.303720475000091, -6.988996028999964],
                [20.303701401000126, -6.995213031999924],
                [20.302705765000155, -6.996622561999857],
                [20.301710128000082, -6.99803209199996],
                [20.295888900000023, -6.998402118999934],
                [20.284725190000017, -6.999105929999871],
                [20.16941833400017, -6.9983129499999],
                [20.123695374000135, -6.99799108499991],
                [20.096506120000072, -6.99822521099992],
                [20.042928697000036, -6.99868393],
                [19.999998093000102, -6.999046801999953],
                [19.963296890000038, -6.999359131999881],
                [19.95583725099999, -6.99942159699998],
                [19.91600036599999, -6.999999998999954],
                [19.886859894000168, -6.999999998999954],
                [19.868000030000189, -6.999999998999954],
                [19.852228165000042, -6.99907255099987],
                [19.833999634, -6.998000144999878],
                [19.828832627000168, -6.998344421999946],
                [19.819000244000108, -6.999000071999944],
                [19.789999007000119, -6.999000071999944],
                [19.725975037000069, -6.999000071999944],
                [19.705999374000044, -6.999000071999944],
                [19.695878982000067, -6.999674796999955],
                [19.688371658000108, -7.000003813999911],
                [19.676239014000089, -7.000538824999978],
                [19.669811248000087, -7.000484466999978],
                [19.663660050000033, -7.000432013999898],
                [19.543858846000091, -6.999790668999879],
                [19.543760298999985, -7.006037234999894],
                [19.543739318000121, -7.012424945999953],
                [19.545633316000192, -7.021800040999892],
                [19.547338486000058, -7.030248163999943],
                [19.551786422000191, -7.038077830999896],
                [19.557729721000157, -7.048539160999951],
                [19.563636064000093, -7.057312905999879],
                [19.562994053000125, -7.057566182999892],
                [19.562999725000111, -7.058000086999925],
                [19.559981570000048, -7.058754625999882],
                [19.559463501000039, -7.058959006999942],
                [19.55197715800017, -7.069269179999935],
                [19.543254852000189, -7.076495170999976],
                [19.537528992000034, -7.081659792999972],
                [19.533220292000181, -7.088127135999969],
                [19.522174835000158, -7.113002776999906],
                [19.508480073000158, -7.13698005599997],
                [19.503444672000171, -7.170030116999953],
                [19.496510289000128, -7.175954589999947],
                [19.497916222000185, -7.183875798999964],
                [19.497991561999982, -7.183974742999965],
                [19.502000808999981, -7.190000056999963],
                [19.502500534000035, -7.191499947999887],
                [19.503000259000089, -7.192999839999914],
                [19.502012252000156, -7.196459769999933],
                [19.501998902000082, -7.196498869999971],
                [19.501535415000035, -7.197908533999907],
                [19.501504898000064, -7.198111175999884],
                [19.505029679000131, -7.209044455999901],
                [19.50505066, -7.209092139999939],
                [19.504965222000067, -7.209311895999917],
                [19.503380041000071, -7.215889017999928],
                [19.503381344000104, -7.215901911999936],
                [19.503592765000064, -7.217565600999933],
                [19.503999710000073, -7.219999790999964],
                [19.503924370000107, -7.220175027999972],
                [19.50384902900015, -7.220350264999922],
                [19.503822333000073, -7.220408131999932],
                [19.500075023000022, -7.228968937999923],
                [19.502904892000117, -7.233872651999945],
                [19.503000259000089, -7.234000206999838],
                [19.501596450000022, -7.238681793999945],
                [19.500236512000129, -7.24421119799996],
                [19.500760078000098, -7.250578641999937],
                [19.500879287000089, -7.252024173999871],
                [19.499702454000158, -7.256608963999895],
                [19.498546045000126, -7.257749243999911],
                [19.493850708000025, -7.267501353999819],
                [19.493713379000155, -7.268272399999944],
                [19.492689133000113, -7.274022101999947],
                [19.494308471000181, -7.282506464999926],
                [19.495000839000113, -7.287000177999971],
                [19.492197991000182, -7.290574788999948],
                [19.492258073000187, -7.291280745999927],
                [19.493000030000189, -7.295000074999905],
                [19.491558657000041, -7.296080784999958],
                [19.491547108000077, -7.296180247999928],
                [19.488380431000166, -7.302247046999923],
                [19.492029190000153, -7.308012961999964],
                [19.48762369200017, -7.315094947999967],
                [19.490555445000155, -7.323846498999956],
                [19.490726144000178, -7.324127954999881],
                [19.491601944000138, -7.325139044999958],
                [19.496000290000097, -7.33100032699997],
                [19.495500565000043, -7.332000254999969],
                [19.499078960000077, -7.341359887999943],
                [19.500967026000183, -7.341989039999874],
                [19.500551224000048, -7.344591140999967],
                [19.501956940000184, -7.349784849999935],
                [19.503000259000089, -7.355000018999931],
                [19.500000000000114, -7.358000277999963],
                [19.497999192000123, -7.362999916999968],
                [19.495678973000054, -7.370518532999938],
                [19.496519090000049, -7.372220993999974],
                [19.513418197000192, -7.404838084999938],
                [19.514999389000081, -7.407999992999976],
                [19.515433312000027, -7.409207582999954],
                [19.517835616000184, -7.415892122999878],
                [19.520965575999981, -7.424600122999891],
                [19.524986267000031, -7.427491663999888],
                [19.529041291, -7.430408954999962],
                [19.531019211000057, -7.431010245999971],
                [19.532783509000012, -7.431892871999878],
                [19.531894683000189, -7.434524057999965],
                [19.531724930000109, -7.435054777999937],
                [19.526988983000024, -7.436106204999874],
                [19.52683576000004, -7.436140377999948],
                [19.526375770000186, -7.442701815999897],
                [19.529359817000056, -7.446960925999974],
                [19.534099105000166, -7.448287778999884],
                [19.534805299000141, -7.448451040999885],
                [19.537000657000078, -7.448999881999953],
                [19.537246704000097, -7.44924545299989],
                [19.539831162000041, -7.451829908999969],
                [19.544742585000108, -7.457032202999926],
                [19.546888350000074, -7.462670801999877],
                [19.549829483000167, -7.470392226999877],
                [19.549999236000076, -7.470839976999969],
                [19.549999236000076, -7.47200012199994],
                [19.549253464, -7.473492144999966],
                [19.549222947000089, -7.473551271999952],
                [19.549171447000106, -7.473516940999957],
                [19.542226792000065, -7.468968867999877],
                [19.54191875500004, -7.468766449999919],
                [19.536592483000049, -7.468544004999899],
                [19.532337188000156, -7.470088957999906],
                [19.521909715000049, -7.473873136999941],
                [19.516847610000184, -7.483857154999953],
                [19.512171745000103, -7.4888377179999],
                [19.512010574000158, -7.489009856999928],
                [19.501091004000102, -7.496499537999966],
                [19.496007919000078, -7.50001096699998],
                [19.49442863500019, -7.50048231999989],
                [19.485315324000112, -7.503929376999906],
                [19.492740631000117, -7.525319099999933],
                [19.493869781000114, -7.533022880999965],
                [19.492789395000102, -7.534200728999906],
                [19.484216213000025, -7.543556808999881],
                [19.482350032000056, -7.550349076999964],
                [19.482924391000097, -7.551777091999952],
                [19.484519958000192, -7.55338001299998],
                [19.484150887000112, -7.554826497999954],
                [19.483781815000157, -7.556272982999928],
                [19.47999954300019, -7.560999869999932],
                [19.47527790100014, -7.565720556999906],
                [19.473999024000136, -7.566999910999925],
                [19.46824836799999, -7.569876192999971],
                [19.468124389000138, -7.56993818199993],
                [19.46800041099999, -7.57000017199988],
                [19.461999893000041, -7.571000098999946],
                [19.381015778000062, -7.570103286999881],
                [19.381000519000111, -7.5701860879999],
                [19.381000519000111, -7.571000098999946],
                [19.381000519000111, -7.571412084999906],
                [19.381000519000111, -7.573999881999896],
                [19.380599976000099, -7.575199127999895],
                [19.380218505000073, -7.576345443999912],
                [19.379899978000083, -7.577054499999974],
                [19.378789901000175, -7.577660082999955],
                [19.377340318000051, -7.578450202999875],
                [19.370532585000149, -7.581924515999958],
                [19.36867618600013, -7.583096979999937],
                [19.372016906000113, -7.598087309999926],
                [19.374882698000079, -7.609792231999904],
                [19.377689362000126, -7.6168608669999],
                [19.384645463000084, -7.627922057999967],
                [19.387050561000137, -7.637064202999909],
                [19.387266158000102, -7.637299537999922],
                [19.395000458000027, -7.645999907999908],
                [19.396766663000051, -7.648943900999882],
                [19.397314073000075, -7.649955987999874],
                [19.39786148200011, -7.650968074999867],
                [19.398841079000078, -7.664598453999929],
                [19.398973962000184, -7.665872673999843],
                [19.398996353000143, -7.66595363599987],
                [19.399000965000027, -7.665971315999911],
                [19.401393890000179, -7.674639224999964],
                [19.406409264000047, -7.681391714999961],
                [19.406879425000113, -7.682024000999945],
                [19.407573700000114, -7.687446594999926],
                [19.407579423000129, -7.687491416999876],
                [19.407585145000098, -7.68753623899994],
                [19.409000396000181, -7.701000212999929],
                [19.408499718000144, -7.702500103999967],
                [19.40799903900006, -7.70399999599988],
                [19.402000428000122, -7.709000109999977],
                [19.401088715000071, -7.713828244999945],
                [19.401000103000058, -7.714371553999968],
                [19.400999069000136, -7.714810846999967],
                [19.401000977000081, -7.718999862999908],
                [19.400000573000113, -7.720499991999873],
                [19.398023867000177, -7.730754033999972],
                [19.397989273000121, -7.730998515999886],
                [19.397945404000154, -7.731308937999927],
                [19.39699935900012, -7.736000059999981],
                [19.396626473000026, -7.736870287999977],
                [19.396253586000171, -7.737740516999963],
                [19.39399910100002, -7.743000030999895],
                [19.390157699000156, -7.759734390999938],
                [19.391374588000019, -7.768708227999923],
                [19.389970780000056, -7.77214288599987],
                [19.389343263000114, -7.772450922999951],
                [19.388024986000175, -7.773098158999972],
                [19.38797044800009, -7.773169635999921],
                [19.38648033100003, -7.778310774999966],
                [19.386999130000049, -7.782999992999976],
                [19.385881106000056, -7.78411247899993],
                [19.385499845000083, -7.784491851999974],
                [19.385362626000187, -7.784628390999899],
                [19.385341645000096, -7.784657954999943],
                [19.38435935900003, -7.786109923999902],
                [19.383901595000168, -7.786346911999885],
                [19.380837880000058, -7.78794021199991],
                [19.380279542000039, -7.788353203999975],
                [19.380128861000117, -7.788554190999832],
                [19.373756410000169, -7.79704904599987],
                [19.369979858000136, -7.802085876999968],
                [19.368270873000085, -7.803384302999973],
                [19.368232606000163, -7.803413003999935],
                [19.364887237000062, -7.805953977999934],
                [19.361628533000101, -7.812046050999868],
                [19.36158561700006, -7.820452212999953],
                [19.361728033000134, -7.833659648999912],
                [19.364234102000125, -7.846232715999975],
                [19.36471939099999, -7.848526000999925],
                [19.364716530000067, -7.84865307799987],
                [19.364713669000082, -7.848780154999929],
                [19.364576340000156, -7.854092120999951],
                [19.364000320000116, -7.861000059999981],
                [19.358406067999965, -7.864729879999857],
                [19.356661798000061, -7.865741012999933],
                [19.355955123000172, -7.879028319999861],
                [19.347884655000144, -7.884528517999911],
                [19.346458436000148, -7.894669054999895],
                [19.343868574000112, -7.90115881],
                [19.354000092000149, -7.907999991999873],
                [19.357000352000057, -7.913000106999959],
                [19.357000352000057, -7.919839381999907],
                [19.35740852400005, -7.928155421999975],
                [19.436508178000167, -7.958249568999975],
                [19.440631866000103, -7.979626178999979],
                [19.43568420400004, -7.9985351549999],
                [19.380670546999966, -7.999841688999823],
                [19.374000549000186, -7.999999998999954],
                [19.299999236000133, -7.999999998999954],
                [19.284648895000032, -7.999999998999954],
                [19.212907790000145, -7.999999998999954],
                [19.200000763000048, -7.999999998999954],
                [19.12851715100004, -7.999999998999954],
                [19.100000382000189, -7.999999998999954],
                [19.084283828000082, -7.999999998999954],
                [19.000001908000172, -7.999999998999954],
                [18.970567704000189, -7.999999998999954],
                [18.899999618000038, -7.999999998999954],
                [18.866693497000028, -8.000267504999954],
                [18.800197601000036, -7.999999998999954],
                [18.799999237000122, -7.993402003999904],
                [18.791982651000126, -7.982308147999902],
                [18.791000367000038, -7.98099994599994],
                [18.789779663000104, -7.978063105999979],
                [18.789756774000068, -7.978013037999972],
                [18.789733885999965, -7.977962970999954],
                [18.785749436000174, -7.969167230999972],
                [18.78087806700006, -7.967228888999955],
                [18.779890059000024, -7.966835974999981],
                [18.778839112000014, -7.96525716799988],
                [18.778110504000153, -7.963710784999933],
                [18.778598786000032, -7.961834907999901],
                [18.779006958000082, -7.960262773999943],
                [18.781421661000138, -7.959394455999927],
                [18.782022544000029, -7.95917823699989],
                [18.782814026000096, -7.958338927999932],
                [18.782908440000142, -7.947160958999973],
                [18.782015978000175, -7.940209011999968],
                [18.781616210000152, -7.937771796999925],
                [18.781576156000028, -7.937125204999973],
                [18.781562805000078, -7.935659885999883],
                [18.781726838000054, -7.931839941999954],
                [18.78161049, -7.927546023999923],
                [18.777639389000171, -7.922145723999961],
                [18.558158874000071, -7.933583258999931],
                [18.555685043000096, -7.94838285499992],
                [18.544961928000077, -7.963182925999945],
                [18.533000945000083, -7.97200012199994],
                [18.53156852800015, -7.987738131999947],
                [18.53155517499999, -7.987886427999968],
                [18.531009674000074, -7.993893146999938],
                [18.527865219000091, -7.997076033999974],
                [18.527727513000059, -7.997090483999898],
                [18.527463913000076, -7.998146056999929],
                [18.527000427000075, -7.999999998999954],
                [18.499999999000011, -7.999999998999954],
                [18.427417755000022, -7.999999998999954],
                [18.399999618000152, -7.999999998999954],
                [18.391220094000062, -7.999999998999954],
                [18.391096115000039, -7.999999998999954],
                [18.398000717000059, -8.012999534999949],
                [18.389999389000025, -8.017000197999948],
                [18.387946248000105, -8.017513936999933],
                [18.376059467000061, -8.026956991999896],
                [18.376005173000181, -8.027007101999971],
                [18.375659942000141, -8.027325628999961],
                [18.364929199000187, -8.021418571999959],
                [18.359329224000192, -8.025924682999914],
                [18.35919189399999, -8.02594041899988],
                [18.359054564000189, -8.02595615499996],
                [18.354021073000183, -8.026529311999866],
                [18.334653854000123, -8.020270347999883],
                [18.334615585000051, -8.020257591999894],
                [18.334596633000046, -8.020251274999907],
                [18.332750122000164, -8.019654541999898],
                [18.330875396000124, -8.019048690999966],
                [18.330559740000126, -8.017336698999884],
                [18.326975345000108, -8.013543724999863],
                [18.319140435, -8.017858980999961],
                [18.319000245000154, -8.017999648999933],
                [18.312999726000044, -8.017999648999933],
                [18.303998947000082, -8.013999938999973],
                [18.30200004600016, -8.012000082999862],
                [18.30200004600016, -8.011736868999947],
                [18.301969529000189, -8.011708259999978],
                [18.301921004000178, -8.00698532499996],
                [18.300953574000062, -8.001647841999954],
                [18.299810410000191, -7.999999998999954],
                [18.300090789000024, -7.999779222999962],
                [18.306619364000085, -7.994653273999973],
                [18.307419778000053, -7.993701457999975],
                [18.301170349000131, -7.990170002999832],
                [18.292027474000122, -7.988092421999966],
                [18.290134430000023, -7.987999915999978],
                [18.285139083000104, -7.987755774999926],
                [18.28042221000004, -7.988457679999897],
                [18.273359299000163, -7.989854812999965],
                [18.271766662000118, -7.990437507999957],
                [18.265556335000042, -7.992709158999958],
                [18.261306764000096, -7.993906974999959],
                [18.252397537000036, -7.993739127999902],
                [18.242958069000053, -7.993127344999891],
                [18.24205970700018, -7.993069170999888],
                [18.235458375, -7.991201876999924],
                [18.226528167000083, -7.989231108999945],
                [18.216976166000165, -7.989453791999893],
                [18.210759163000091, -7.992900370999962],
                [18.20899963300019, -7.993999957999904],
                [18.186595918000023, -7.998800754999934],
                [18.18137931800004, -7.999999998999954],
                [18.174552918000131, -8.002072332999887],
                [18.152999878000116, -8.008999824999933],
                [18.139196397000092, -8.02893734],
                [18.135156632000076, -8.034774779999964],
                [18.135078430000192, -8.034887312999956],
                [18.135000228000081, -8.034999845999948],
                [18.129051209000181, -8.035566329999938],
                [18.114469528000086, -8.037457465999978],
                [18.098337174000051, -8.040159701999926],
                [18.097078180000153, -8.045367251999949],
                [18.095029830000158, -8.05485057899989],
                [18.094607353000072, -8.055587291999927],
                [18.09418487500011, -8.056324004999965],
                [18.087491989000057, -8.067993164999905],
                [18.103469848000032, -8.087327002999928],
                [18.100735664000069, -8.098308562999932],
                [18.100556890000064, -8.098315188999948],
                [18.100360869999975, -8.099055289999967],
                [18.100160598000116, -8.099055289999967],
                [18.085887909, -8.098858832999952],
                [17.999999999000124, -8.097999572999925],
                [17.982000351000124, -8.112999915999922],
                [17.973751234000133, -8.109700620999945],
                [17.972383500000149, -8.109153747999926],
                [17.972000122000054, -8.109000205999905],
                [17.965106964000086, -8.099455833999969],
                [17.958770752000191, -8.091012000999967],
                [17.949729919000106, -8.086889266999947],
                [17.939872742000148, -8.082389831999876],
                [17.923690796000074, -8.080438612999899],
                [17.915130615000123, -8.080114364999815],
                [17.891628266, -8.079219817999956],
                [17.885004043000038, -8.078968048999911],
                [17.884962081000083, -8.078966616999878],
                [17.87843132, -8.081351280999911],
                [17.878360748000034, -8.081377028999839],
                [17.878290176000064, -8.081402777999927],
                [17.847932816000025, -8.085259436999877],
                [17.838459015000183, -8.087854384999957],
                [17.83267784100002, -8.089438436999899],
                [17.825239182000132, -8.091477392999934],
                [17.817939757999966, -8.095304488999943],
                [17.812101364000057, -8.102777479999929],
                [17.806329727000104, -8.107180595999921],
                [17.8052902230001, -8.107974052999907],
                [17.803712844000017, -8.108520508999959],
                [17.796819686000106, -8.110906600999897],
                [17.790269851, -8.111848829999815],
                [17.784179688000165, -8.112723350999943],
                [17.783949853000081, -8.11275625199994],
                [17.783720017000064, -8.112789153999813],
                [17.775342942000179, -8.109189986999979],
                [17.766584396000042, -8.105427740999915],
                [17.757258415000138, -8.101421832999961],
                [17.728723525000134, -8.096468923999964],
                [17.719055176000154, -8.097124098999871],
                [17.71698379500009, -8.097392081999942],
                [17.685249328000054, -8.101495741999941],
                [17.661230087000035, -8.110943794999969],
                [17.644236898000088, -8.115101492999884],
                [17.643804551000073, -8.115353583999877],
                [17.630578996000111, -8.123050689999957],
                [17.62097358800014, -8.125764846999971],
                [17.611318588000074, -8.128493308999964],
                [17.601978303000067, -8.128681182999969],
                [17.582969665000064, -8.129064558999971],
                [17.561144829000114, -8.127507209999976],
                [17.560329437000178, -8.127449035999973],
                [17.543024063000075, -8.11539268599995],
                [17.535380554000085, -8.108044052999958],
                [17.529547692000108, -8.102051734999918],
                [17.52937698400001, -8.101882934999878],
                [17.528362274000074, -8.09337329899995],
                [17.528309823000086, -8.092931270999884],
                [17.523745536000035, -8.089197157999934],
                [17.505481719000102, -8.082833289999883],
                [17.504211427000143, -8.078189849999831],
                [17.503847123000014, -8.076856611999915],
                [17.50348281800018, -8.075523374999932],
                [17.504220962000147, -8.071560858999874],
                [17.504730224000184, -8.068836211999951],
                [17.506113053000092, -8.067227363999962],
                [17.513971329000185, -8.058089255999903],
                [17.516214372000036, -8.050064086999896],
                [17.517103196000051, -8.046883582999897],
                [17.517198563000022, -8.046541213999888],
                [17.518978119000167, -8.046160696999948],
                [17.521636963000105, -8.045592307999925],
                [17.522975922000114, -8.045305728999892],
                [17.524314881000123, -8.045019149999916],
                [17.528825760000075, -8.04753494299996],
                [17.529953003000173, -8.048162936999972],
                [17.532590867000124, -8.04393863699994],
                [17.529558182000073, -8.038335799999913],
                [17.528799058000175, -8.036932467999975],
                [17.521823881999978, -8.028759002999948],
                [17.513282777000029, -8.019458769999972],
                [17.507539750000092, -8.013199806999978],
                [17.49554538700005, -8.004235742999924],
                [17.495487213000047, -8.004220961999977],
                [17.491043090000119, -8.003078459999927],
                [17.485641479000037, -8.001690864999944],
                [17.484031675999972, -8.000240324999936],
                [17.483764649000022, -7.999999998999954],
                [17.486204148000127, -7.999549864999835],
                [17.492042542000036, -7.998475074999874],
                [17.489250183000024, -7.993437765999943],
                [17.488492965000091, -7.981278895999935],
                [17.490900040000042, -7.958541870999909],
                [17.488780498000153, -7.952291249999917],
                [17.483501465000074, -7.951175536999813],
                [17.481851577000157, -7.95232677499996],
                [17.47843933199999, -7.9547061909999],
                [17.473676681000029, -7.960240840999973],
                [17.472488403000114, -7.961620807999964],
                [17.472036362000097, -7.961398601999861],
                [17.468330382999966, -7.959577083999932],
                [17.468233108000049, -7.959070205999922],
                [17.467951429000095, -7.957615299999929],
                [17.467620850000174, -7.955907821999915],
                [17.469687051000165, -7.951863808999917],
                [17.47098117400003, -7.947575354999913],
                [17.470811293000111, -7.946859997999979],
                [17.466770172000111, -7.945353029999978],
                [17.46180534500013, -7.945838927999944],
                [17.459957124000027, -7.944930552999949],
                [17.458108902000049, -7.944022177999898],
                [17.453977585000132, -7.938142299999925],
                [17.452909470000122, -7.936622141999919],
                [17.44560051000002, -7.931231020999917],
                [17.442356109000059, -7.927345751999951],
                [17.441406249000181, -7.922301769999876],
                [17.441599846000145, -7.921456574999866],
                [17.443880082000135, -7.911498068999947],
                [17.446753502000035, -7.903928993999955],
                [17.447284699000022, -7.902529238999932],
                [17.454145431000143, -7.893311023999956],
                [17.455497742000034, -7.888254164999978],
                [17.455085755000141, -7.884176253999897],
                [17.455048562000172, -7.88380551299997],
                [17.452578545000165, -7.878697155999817],
                [17.445510864000084, -7.872694015999969],
                [17.441194533999976, -7.873284815999966],
                [17.433309555000108, -7.874363898999945],
                [17.424760819000142, -7.869586944999981],
                [17.423627853000028, -7.867558001999896],
                [17.42319873800011, -7.866790247999973],
                [17.422203063999973, -7.865008830999955],
                [17.423406601000181, -7.860407829999929],
                [17.424007416000052, -7.858111856999926],
                [17.42498016400009, -7.856424807999872],
                [17.426372010000136, -7.854011617999959],
                [17.431417465000095, -7.843956469999966],
                [17.426074028000187, -7.837577341999975],
                [17.41987323800015, -7.837328671999899],
                [17.412973404000184, -7.839196919999949],
                [17.412866592, -7.839280604999942],
                [17.408950806000064, -7.84235000599989],
                [17.408648492000054, -7.842407940999976],
                [17.408346177, -7.842465876999881],
                [17.405271530999983, -7.843056201999957],
                [17.40416908200018, -7.842404840999905],
                [17.402961730000129, -7.841691017999892],
                [17.402097702000049, -7.839855671999942],
                [17.401559829, -7.838714122999875],
                [17.401954650000107, -7.837430000999973],
                [17.402692795000121, -7.835035800999947],
                [17.403994561000047, -7.834191798999882],
                [17.405296327000087, -7.833347796999874],
                [17.409118653000178, -7.830870150999942],
                [17.411273957000162, -7.824660538999979],
                [17.404727697000112, -7.819503604999966],
                [17.397040863000029, -7.822465992999923],
                [17.396638871000164, -7.82266902899994],
                [17.396436692000066, -7.822698830999855],
                [17.392008781000072, -7.823353290999933],
                [17.391809463000072, -7.823382854999977],
                [17.389289857000165, -7.822143553999979],
                [17.38800716500009, -7.821512697999935],
                [17.386724472000083, -7.820881842999881],
                [17.385534286, -7.813541888999907],
                [17.385540008000135, -7.813526867999883],
                [17.385545730999979, -7.813511847999905],
                [17.389183044000106, -7.806866168999932],
                [17.390987396000185, -7.799969194999903],
                [17.388412476000155, -7.792405127999871],
                [17.385287285000118, -7.789322375999973],
                [17.378705978000085, -7.786026001999971],
                [17.369710922000138, -7.783775805999881],
                [17.360961914000086, -7.782903192999982],
                [17.352095604000169, -7.781474351999975],
                [17.351274490000037, -7.781342029999962],
                [17.344118117999983, -7.778164863999962],
                [17.344015121000098, -7.778055429999881],
                [17.343912125000088, -7.777945995999971],
                [17.333538055000076, -7.766898154999979],
                [17.332992554000157, -7.76631689099986],
                [17.332776231000082, -7.766017643999874],
                [17.32951545800006, -7.762619971999925],
                [17.321443558000112, -7.758532045999914],
                [17.320999146000077, -7.757350444999929],
                [17.320007325000176, -7.754706858999896],
                [17.319808961000092, -7.754179],
                [17.320243836000145, -7.749355792999893],
                [17.327536106000082, -7.741545556999938],
                [17.328672409000035, -7.732323169999972],
                [17.332069398000158, -7.721974849999981],
                [17.330311775000098, -7.716751335999959],
                [17.326257074000125, -7.717186231999961],
                [17.325832368000135, -7.717873095999948],
                [17.32484817500017, -7.717966554999975],
                [17.323299407000093, -7.718113898999889],
                [17.319841385000132, -7.71629476499993],
                [17.319087982000042, -7.714612007999961],
                [17.319042858000046, -7.714511278999964],
                [17.318199158000027, -7.712627887999929],
                [17.319549561000144, -7.706192015999875],
                [17.316063881000048, -7.700573205999945],
                [17.30735198900004, -7.702892331999976],
                [17.306411743000069, -7.703272818999949],
                [17.306307792999974, -7.703170298999964],
                [17.298824310000157, -7.696526049999932],
                [17.297489165000059, -7.695947170999887],
                [17.292310715000042, -7.693699835999951],
                [17.291736603000174, -7.692009925999912],
                [17.290910722000149, -7.689573764999977],
                [17.292329788000131, -7.686288355999977],
                [17.292501449000042, -7.685891151999897],
                [17.294513703000064, -7.684244154999874],
                [17.299839020000093, -7.679885862999981],
                [17.299753189000171, -7.673230170999943],
                [17.299699784000097, -7.672315120999883],
                [17.298793793000186, -7.656699656999876],
                [17.300354004000042, -7.647047996999959],
                [17.306287765000093, -7.636915206999902],
                [17.301343918000043, -7.630267620999973],
                [17.29608154400006, -7.62318801899994],
                [17.288242339000078, -7.621159075999913],
                [17.287062178000042, -7.619848694999973],
                [17.286754131, -7.619734643999948],
                [17.280400617000112, -7.617852819999939],
                [17.279935837000039, -7.617755888999966],
                [17.279883385000119, -7.617699622999965],
                [17.276710510999976, -7.614326953999978],
                [17.272797585000035, -7.611025094999945],
                [17.267659664000121, -7.609538077999957],
                [17.26757024300008, -7.609470847999887],
                [17.266309739000064, -7.609096050999938],
                [17.262269974000048, -7.605485916999953],
                [17.256971360000023, -7.601943174999917],
                [17.250989006000111, -7.603016645999901],
                [17.249927521000131, -7.603436945999874],
                [17.249778747999983, -7.603233813999964],
                [17.24505806000019, -7.596802234999927],
                [17.239805222000086, -7.596192835999943],
                [17.23745346200019, -7.595920085999921],
                [17.237052918000074, -7.595367430999943],
                [17.235136033, -7.592715740999836],
                [17.232586861000129, -7.585507391999897],
                [17.231681824000134, -7.584949969999968],
                [17.226968765000152, -7.582045077999965],
                [17.220554352000136, -7.578091142999938],
                [17.219160080000051, -7.576080798999954],
                [17.218696966000095, -7.575413473999959],
                [17.217536926000037, -7.573741911999946],
                [17.215411269000072, -7.560749729999941],
                [17.215387344000135, -7.560642956999971],
                [17.209783555000058, -7.5500168799999],
                [17.206096650000063, -7.543024062999962],
                [17.199857712000096, -7.538920879999921],
                [17.197984695000059, -7.532958983999947],
                [17.202760696000098, -7.521681784999885],
                [17.205244065000159, -7.51225471499987],
                [17.203062057000068, -7.504818199999932],
                [17.199882506000165, -7.499419211999964],
                [17.193870545000038, -7.493936061999875],
                [17.186941147000084, -7.489836214999912],
                [17.182060240999988, -7.489033697999957],
                [17.174730300000078, -7.487828253999965],
                [17.174609185000065, -7.487766741999963],
                [17.166650772000025, -7.48373222399988],
                [17.160261155000057, -7.478403566999816],
                [17.156246186000146, -7.475054739999905],
                [17.15365982000003, -7.464043138999898],
                [17.158117294000022, -7.456877707999979],
                [17.159820557000046, -7.454137801999934],
                [17.161139489000107, -7.453339337999978],
                [17.162458420000064, -7.452540874999897],
                [17.165754317000165, -7.451123713999948],
                [17.170614242000056, -7.449032782999893],
                [17.17073535899999, -7.44903039899998],
                [17.17770004200014, -7.448886393999942],
                [17.185121536999986, -7.448730945999955],
                [17.187754313000028, -7.444944698999962],
                [17.188512801000115, -7.437694071999886],
                [17.184321403000069, -7.431370018999871],
                [17.177488327000106, -7.426585911999894],
                [17.175962448000064, -7.426458834999949],
                [17.160823821000065, -7.425195215999963],
                [17.160318374000099, -7.424813031999918],
                [17.1529693600001, -7.419260024999915],
                [17.150243759999967, -7.412565231999963],
                [17.148303987000133, -7.407797812999945],
                [17.143237249000151, -7.405828995999855],
                [17.138872147000086, -7.409221172999935],
                [17.138021470000183, -7.415640353999947],
                [17.137319565000098, -7.420941828999901],
                [17.13655471900006, -7.421632766999949],
                [17.135650634000115, -7.422449111999924],
                [17.132968903000176, -7.424868105999963],
                [17.127910614000086, -7.426270961999933],
                [17.113634110000078, -7.425418853999872],
                [17.113252639000052, -7.4251165399999],
                [17.111806724000076, -7.423973049999972],
                [17.110170365000101, -7.422678947999941],
                [17.10839462199999, -7.417575835999969],
                [17.112797737999983, -7.390430369999933],
                [17.11204624200019, -7.378310441999872],
                [17.111709595000093, -7.376516817999971],
                [17.116680145000146, -7.364538191999884],
                [17.116399764000164, -7.357647895999833],
                [17.113180161000116, -7.352217196999902],
                [17.109630585000048, -7.346213817999967],
                [17.103630066000164, -7.343501090999951],
                [17.093620301000158, -7.344034910999937],
                [17.091899871000123, -7.344966888999977],
                [17.076461793000135, -7.342782973999931],
                [17.064838408000014, -7.327478884999948],
                [17.060220719000142, -7.325675964999903],
                [17.051929473000143, -7.325047015999928],
                [17.045230865000121, -7.321190832999946],
                [17.040571213000078, -7.314334868999879],
                [17.040537834000077, -7.314220904999957],
                [17.036672592000173, -7.301206110999942],
                [17.036556243000064, -7.301143167999953],
                [17.030548096000018, -7.29786920499987],
                [17.024389268000107, -7.298201082999981],
                [17.023581505000152, -7.298244475999923],
                [17.014051439000127, -7.298785209999949],
                [17.01296536100017, -7.298888445999921],
                [17.005510331000096, -7.300049781999917],
                [17.005297661000043, -7.299617289999958],
                [17.005084991000047, -7.299184798999931],
                [17.002021790000185, -7.292944908999971],
                [17.004901887000187, -7.270189762999962],
                [17.00394630500017, -7.263301849999891],
                [16.994888305000131, -7.246803759999978],
                [16.988595962999966, -7.23075199199991],
                [16.980268478000085, -7.222060202999899],
                [16.96945953400018, -7.215488433999894],
                [16.968864441000051, -7.215126275999978],
                [16.967572417999975, -7.214412278999873],
                [16.959995270000093, -7.210587024999938],
                [16.959047317999989, -7.209882258999869],
                [16.95853233400004, -7.209499835999907],
                [16.957113265000146, -7.208444118999978],
                [16.955801011000062, -7.207468985999924],
                [16.953710556000033, -7.203343867999934],
                [16.953692437000086, -7.203108548999921],
                [16.95367431700015, -7.202873230999899],
                [16.953218460000187, -7.19691324199988],
                [16.954788209000128, -7.188635825999882],
                [16.958446503000118, -7.18356466299997],
                [16.96853256300011, -7.175706862999959],
                [16.970350266000139, -7.170185088999972],
                [16.968933106000122, -7.163528919999976],
                [16.962688446000186, -7.157354830999964],
                [16.956689835000077, -7.154165267999929],
                [16.953691482000067, -7.152571200999887],
                [16.953052520000142, -7.150951384999928],
                [16.950429917000122, -7.14431381199995],
                [16.949460984000154, -7.134669779999967],
                [16.950215340000057, -7.124797581999872],
                [16.94824028000005, -7.120201111999904],
                [16.944120407000128, -7.116486072999919],
                [16.940380096000013, -7.113114834999976],
                [16.938013713000089, -7.10621746399994],
                [16.936632156000087, -7.099804877999929],
                [16.933601379000038, -7.091318130999923],
                [16.928045273000066, -7.084219932999872],
                [16.928590774000156, -7.07412910499994],
                [16.928890228000057, -7.068592070999955],
                [16.92995262200003, -7.067108153999925],
                [16.930640646000086, -7.066146811999943],
                [16.931859970000119, -7.064443110999946],
                [16.938581467000176, -7.06093835799993],
                [16.938974380000047, -7.060733794999976],
                [16.94116211000005, -7.061069010999972],
                [16.943349838000074, -7.061404228999948],
                [16.945178986000087, -7.065907],
                [16.948482990000059, -7.073720454999886],
                [16.95427227000016, -7.078305720999936],
                [16.961612225000067, -7.079178093999929],
                [16.968656540000154, -7.073065041999939],
                [16.969282150000083, -7.07174205799987],
                [16.970539092000138, -7.069086551999817],
                [16.970037461000118, -7.060595035999938],
                [16.969306946000131, -7.052555083999948],
                [16.970874787000128, -7.044045925999967],
                [16.971331597000074, -7.043417930999965],
                [16.975082397000108, -7.038258551999945],
                [16.980476378999981, -7.030687013999966],
                [16.982740401000115, -7.023312091999969],
                [16.981344223, -7.017978666999909],
                [16.980157853000094, -7.013443946999871],
                [16.977981568000075, -7.008832930999915],
                [16.973880768000072, -7.000144958999954],
                [16.973457336000024, -6.994153022999967],
                [16.970886856000163, -6.983726177999927],
                [16.962606123000057, -6.981583540999964],
                [16.962095261000059, -6.981585978999874],
                [16.961267472000031, -6.981589793999945],
                [16.951004029000046, -6.980792998999903],
                [16.950545311999974, -6.980757474999962],
                [16.950086594000027, -6.980721950999964],
                [16.93873977700008, -6.966066836999971],
                [16.938456536000047, -6.964646099999925],
                [16.937091827000188, -6.957801818999883],
                [16.940259933000107, -6.946527957999933],
                [16.945510863999971, -6.938690422999912],
                [16.945510863999971, -6.938230036999926],
                [16.946569443000101, -6.93287897099998],
                [16.946897022000087, -6.931220602999872],
                [16.945362092000096, -6.92568874199992],
                [16.943822860000125, -6.921462058999964],
                [16.936883927000054, -6.915060043999972],
                [16.93808555600009, -6.908437966999884],
                [16.938426972000059, -6.906555175999927],
                [16.939272594000045, -6.901902222999922],
                [16.940178871000171, -6.8969123359999],
                [16.940338134000115, -6.896032809999952],
                [16.941810608000083, -6.887921809999966],
                [16.940370560000133, -6.874828815999933],
                [16.937812806000068, -6.870013235999863],
                [16.931645870000125, -6.865985392999903],
                [16.920526504000122, -6.863198756999907],
                [16.917520523000178, -6.859766005999859],
                [16.915416717000028, -6.852880954999932],
                [16.910348892000172, -6.851294993999886],
                [16.905706406000093, -6.853389738999965],
                [16.9047166040001, -6.853835930999935],
                [16.903409959000101, -6.854424952999977],
                [16.902776392000135, -6.854710912999906],
                [16.901008606000062, -6.855508803999953],
                [16.895427705000088, -6.858026980999966],
                [16.895059587000162, -6.857839106999961],
                [16.89469146800019, -6.857651233999945],
                [16.893901099000061, -6.857170718999953],
                [16.88405418300016, -6.851489543999946],
                [16.883580424000115, -6.851375265999877],
                [16.882444380999971, -6.851134300999945],
                [16.879518509000036, -6.850513934999924],
                [16.879385948000163, -6.850363491999929],
                [16.876281738000159, -6.846850872999823],
                [16.874868394000089, -6.839945791999924],
                [16.874441146000095, -6.828621863999956],
                [16.875017166000191, -6.823185920999947],
                [16.873885155000039, -6.812776087999964],
                [16.871711731000119, -6.805274961999885],
                [16.868240356000058, -6.800922870999955],
                [16.864326477000134, -6.798485755999934],
                [16.863265990000059, -6.797825335999903],
                [16.862605676000157, -6.797414143999902],
                [16.86201095600012, -6.797043798999937],
                [16.860956192000117, -6.79631996199987],
                [16.860858917000144, -6.796253203999982],
                [16.850704194000059, -6.789279937999936],
                [16.850687981000021, -6.789259909999885],
                [16.850671768000041, -6.789239882999937],
                [16.836635590000071, -6.77143096899988],
                [16.832653046000075, -6.766376971999875],
                [16.829864502000078, -6.76041364699995],
                [16.829530715999965, -6.758627175999834],
                [16.829196928999977, -6.756840704999888],
                [16.828838867000059, -6.754921060999948],
                [16.828546524000103, -6.753355025999952],
                [16.82844924900013, -6.752834796999935],
                [16.830595017, -6.734783171999879],
                [16.830738068000073, -6.733576058999915],
                [16.829818725000109, -6.726073502999896],
                [16.8287506100001, -6.719738005999886],
                [16.825863521000031, -6.71365149799982],
                [16.822265624000181, -6.710113047999869],
                [16.816741943000125, -6.709218025999974],
                [16.811534882000103, -6.705114602999913],
                [16.806350708000025, -6.70319890899998],
                [16.801031114000011, -6.699099063999938],
                [16.797777939000071, -6.694725035999966],
                [16.796476363000011, -6.68873596099985],
                [16.793779374000053, -6.682601928999929],
                [16.792320251000035, -6.671577929999955],
                [16.793870926000181, -6.663283823999961],
                [16.79397869100012, -6.662712811999882],
                [16.791961669000045, -6.653882979999935],
                [16.783130644999972, -6.642222881999885],
                [16.780033112000126, -6.627304076999906],
                [16.77964019699999, -6.604778766999971],
                [16.77656936700015, -6.593996046999905],
                [16.769159318000106, -6.587153910999916],
                [16.763727824000171, -6.584436534999952],
                [16.757070541000076, -6.585099219999904],
                [16.751277923000032, -6.579174994999903],
                [16.750855446000173, -6.577773331999936],
                [16.749408722000055, -6.572976111999935],
                [16.749183654000035, -6.566835878999939],
                [16.749162732000116, -6.566264225999873],
                [16.749156953000067, -6.56610632],
                [16.749147414999982, -6.565851210999938],
                [16.751180648000116, -6.557796954999901],
                [16.757932662000087, -6.547106741999926],
                [16.759624481000174, -6.544429778999927],
                [16.75994682400011, -6.543926715999874],
                [16.760248899000146, -6.543455544999972],
                [16.762130738000167, -6.538300991999904],
                [16.763103961000127, -6.5326846829999],
                [16.761309624000091, -6.525905608999892],
                [16.755830766000088, -6.518698215999962],
                [16.739461899000105, -6.510262965999971],
                [16.733699799000135, -6.507528781999952],
                [16.727230073000044, -6.500891208999917],
                [16.726541519000079, -6.499228953999932],
                [16.72543334900007, -6.496551990999876],
                [16.725139618000185, -6.495841026999926],
                [16.727180481000175, -6.490776061999895],
                [16.727800369000136, -6.490342854999938],
                [16.728420257000096, -6.489909648999969],
                [16.733142853000061, -6.486612796999964],
                [16.735639572000139, -6.480497360999948],
                [16.735673904000123, -6.480412958999978],
                [16.735727918000123, -6.48028093999983],
                [16.733421326000098, -6.472473382999965],
                [16.727521897000031, -6.464338778999888],
                [16.717418671000189, -6.456329822999976],
                [16.713674545, -6.453361986999937],
                [16.71177743700008, -6.448092579999923],
                [16.711383820000094, -6.447000025999955],
                [16.711112976000095, -6.446247100999926],
                [16.713151931000141, -6.439801215999921],
                [16.714703140000154, -6.43859717499987],
                [16.714826584000036, -6.43850135799994],
                [16.716131210000185, -6.437488077999944],
                [16.718641281000089, -6.43677663699998],
                [16.720161438000048, -6.436345576999827],
                [16.721681595000121, -6.435914516999958],
                [16.726005553000164, -6.434689043999981],
                [16.729669570000169, -6.43191385199998],
                [16.73229694500003, -6.425706623999929],
                [16.729845048000129, -6.419729232999885],
                [16.724300384000173, -6.414234160999968],
                [16.71806907600012, -6.409894942999927],
                [16.713230132000092, -6.407615184999941],
                [16.70037078900009, -6.411118029999955],
                [16.698752404, -6.410909174999915],
                [16.697134018000042, -6.410700319999876],
                [16.693473816000051, -6.410227774999953],
                [16.691045760000065, -6.40622949599998],
                [16.691006295000079, -6.406163800999934],
                [16.690917380000144, -6.406016919999956],
                [16.690689086000077, -6.405642032999936],
                [16.690198898000176, -6.402155399999856],
                [16.690089226000168, -6.401371239999889],
                [16.689979553000057, -6.400587080999856],
                [16.69179153500005, -6.394371031999981],
                [16.69664955200011, -6.388087272999883],
                [16.700706483000147, -6.382839679999961],
                [16.702784653000151, -6.373588470999948],
                [16.697645187000148, -6.367218969999954],
                [16.690513610000096, -6.366100787999869],
                [16.69019699, -6.365849017999949],
                [16.688526154000158, -6.364522455999975],
                [16.688209534000066, -6.364271163999945],
                [16.687871934000043, -6.362736224999935],
                [16.687501907000069, -6.361053943999934],
                [16.690006256000061, -6.355525969999974],
                [16.697862626000131, -6.355876206999881],
                [16.699432373000036, -6.355946063999909],
                [16.706244748000188, -6.358637265999903],
                [16.713939667000091, -6.361171959999922],
                [16.719682694000028, -6.359997748999945],
                [16.722095490000186, -6.356230257999869],
                [16.722144128000139, -6.356154678999928],
                [16.724899291999975, -6.344111919999875],
                [16.724872590000075, -6.338823794999939],
                [16.718147278000174, -6.326208113999883],
                [16.718769074000136, -6.31172084699989],
                [16.716702461000068, -6.302884816999949],
                [16.71628189, -6.302319526999895],
                [16.710645676000127, -6.294741153999894],
                [16.710062980999965, -6.292788981999934],
                [16.70948028600003, -6.290836810999963],
                [16.710489274000167, -6.28507852599995],
                [16.711494446000017, -6.27933311399994],
                [16.713644027000157, -6.272835254999905],
                [16.716493607000018, -6.266666888999964],
                [16.718532563000167, -6.259529113999975],
                [16.718360900000107, -6.255464552999911],
                [16.7183160780001, -6.254393337999943],
                [16.711090087000059, -6.240706919999923],
                [16.71037483300006, -6.235652922999975],
                [16.716064453000058, -6.223442077999948],
                [16.723335266000106, -6.217148780999935],
                [16.723735809000118, -6.216401577999932],
                [16.725900651000188, -6.212364195999896],
                [16.730340481000155, -6.195494532999931],
                [16.729465485000048, -6.188204763999977],
                [16.722534180000082, -6.181335449999949],
                [16.721841813000083, -6.180648803999873],
                [16.721149445000151, -6.17996215799991],
                [16.719984054000122, -6.176289080999936],
                [16.722028732000183, -6.170533180999939],
                [16.721612930000049, -6.166589735999935],
                [16.721578598000065, -6.166262863999918],
                [16.71771812500009, -6.163482426999963],
                [16.716472625999984, -6.162683962999893],
                [16.713706970000146, -6.160908221999875],
                [16.706693649000101, -6.156130313999881],
                [16.702640533000135, -6.153368948999969],
                [16.702521324000088, -6.153188703999945],
                [16.69756412400011, -6.145876645999977],
                [16.697347640000146, -6.145721435999917],
                [16.697159352000028, -6.14557113199993],
                [16.689133644000151, -6.139513491999935],
                [16.688556671000129, -6.139401911999926],
                [16.670623778000049, -6.137867927999935],
                [16.668298722000031, -6.13605737599994],
                [16.667394639000122, -6.135353087999931],
                [16.667234422000092, -6.135017393999874],
                [16.667140960000154, -6.134824752999919],
                [16.665815352999971, -6.132052420999969],
                [16.664238389000104, -6.128746958999955],
                [16.66341114100004, -6.127065181999967],
                [16.663373948000071, -6.127039432999936],
                [16.659528733000116, -6.124348163999969],
                [16.649911880000161, -6.127222536999909],
                [16.647825242000181, -6.127846240999929],
                [16.646863937000091, -6.127657889999966],
                [16.645523072000117, -6.127395629999967],
                [16.642513276000102, -6.122580050999943],
                [16.642522613000096, -6.122480128999939],
                [16.642530442000123, -6.122381209999901],
                [16.6429500590001, -6.117290018999938],
                [16.648214340000038, -6.112424372999953],
                [16.648148537000168, -6.10183246899993],
                [16.642750740000054, -6.096712588999878],
                [16.633192061999978, -6.094107149999957],
                [16.619171142000027, -6.093703745999903],
                [16.611579894000045, -6.091666220999969],
                [16.610672950000151, -6.090552805999948],
                [16.609766006000086, -6.089439390999928],
                [16.606725694000033, -6.085706233999872],
                [16.605348587000037, -6.081337451999957],
                [16.605963707000171, -6.079969166999945],
                [16.606578827000078, -6.078600882999865],
                [16.60806274399999, -6.075354098999981],
                [16.619533539000088, -6.075305460999971],
                [16.625289916000042, -6.075281142999813],
                [16.629973093000046, -6.072983105999981],
                [16.63246854199997, -6.066873231999921],
                [16.627990723000153, -6.061244009999939],
                [16.623151779000125, -6.060891627999979],
                [16.622580528000071, -6.060849904999941],
                [16.617490768000096, -6.062745094999968],
                [16.612028122000027, -6.065085409999881],
                [16.611062050000044, -6.065499066999905],
                [16.610095977000128, -6.065912723999929],
                [16.602886199000068, -6.06551027199987],
                [16.60269260400014, -6.065499543999977],
                [16.602499008000109, -6.06548881599997],
                [16.59902572600015, -6.058826446999944],
                [16.60325050400013, -6.049940109999966],
                [16.606029511000088, -6.044093130999897],
                [16.606851579000079, -6.042363641999884],
                [16.605346679000093, -6.034203052999942],
                [16.605661392000115, -6.029119014999935],
                [16.606123923999974, -6.021682738999914],
                [16.600753590000124, -6.009804833999965],
                [16.599712372999988, -6.007780074999971],
                [16.599576951000188, -6.00720238599996],
                [16.599441528000057, -6.006624697999939],
                [16.598308564000149, -6.001808166999922],
                [16.598465920000024, -6.001194715999816],
                [16.599662780000074, -5.996513843999935],
                [16.603097916000081, -5.993968009999946],
                [16.605888128, -5.987738727999954],
                [16.603467306000084, -5.982082048999928],
                [16.601664421000066, -5.981579489999888],
                [16.596147538000025, -5.980891226999972],
                [16.593957901000124, -5.979687690999981],
                [16.591548920000037, -5.978363513999909],
                [16.591520309000032, -5.978348254999958],
                [16.591197968000131, -5.977802752999935],
                [16.589225769000109, -5.974479198999973],
                [16.589194298000052, -5.967965125999911],
                [16.589187622000054, -5.966660022999918],
                [16.591907502000026, -5.957910059999904],
                [16.595310211000083, -5.95207118899998],
                [16.596112331000029, -5.951290298999936],
                [16.596276601000113, -5.951031684999919],
                [16.599040985000045, -5.946870087999969],
                [16.600593567000089, -5.942235945999926],
                [16.597965241000054, -5.927996157999928],
                [16.599737167000114, -5.918553828999961],
                [16.594427108000161, -5.915371893999975],
                [16.59052467399999, -5.910763977999977],
                [16.569328309000127, -5.907262801999934],
                [16.562879562000035, -5.90477705],
                [16.546483992999981, -5.893159865999962],
                [16.531070709000119, -5.890272140999855],
                [16.525596619000169, -5.887869835999936],
                [16.520238876000178, -5.885516165999889],
                [16.519800186000111, -5.885153054999933],
                [16.513543823000134, -5.879977759999918],
                [16.512428283000077, -5.879055022999978],
                [16.512058258000081, -5.878748892999965],
                [16.504333496000072, -5.878035067999974],
                [16.495172501000127, -5.882006167999975],
                [16.478181838000069, -5.882808207999915],
                [16.465332067000077, -5.891253351999922],
                [16.464481353000053, -5.891812800999958],
                [16.462625504000073, -5.893032072999972],
                [16.454608918000076, -5.896305083999948],
                [16.448369981000155, -5.896220206999942],
                [16.443349838000188, -5.89615011099994],
                [16.438745852000068, -5.895517364999876],
                [16.438422179000042, -5.895472912999935],
                [16.438110352000081, -5.895430087999955],
                [16.437871381000093, -5.895397206999974],
                [16.414600373000042, -5.892199992999849],
                [16.407590865000088, -5.889539718999913],
                [16.405790059000083, -5.888858769999956],
                [16.405309678000037, -5.888677120999944],
                [16.404470444000083, -5.888360023999894],
                [16.396385193000185, -5.882431982999947],
                [16.391279221000104, -5.874189851999972],
                [16.38526153600003, -5.868448257999887],
                [16.384796143000187, -5.868004082999903],
                [16.365940093000177, -5.86468982699995],
                [16.299308776000089, -5.866039752999939],
                [16.290872573000115, -5.866216182999949],
                [16.249019622000105, -5.867060182999978],
                [16.222213746000136, -5.868618010999967],
                [16.20754623400012, -5.863409996999849],
                [16.19487953200013, -5.859861850999891],
                [16.175140380000073, -5.859578131999967],
                [16.153728484, -5.85969066499996],
                [16.144891738000183, -5.858722209999939],
                [16.139430999000183, -5.85864305399997],
                [16.141273498000146, -5.869705676999899],
                [16.139592171, -5.869740724999872],
                [16.097293855000032, -5.871044634999976],
                [16.012047768, -5.873707293999928],
                [16.010141373000124, -5.873766899999964],
                [15.913981437000132, -5.875777243999949],
                [15.856147767000095, -5.8776459689999],
                [15.781442642000059, -5.878501891999974],
                [15.781091691000029, -5.878469228999904],
                [15.77139926000001, -5.880055306999964],
                [15.765456200000187, -5.877017021999905],
                [15.708481788000029, -5.87172222099997],
                [15.692035675000056, -5.871026991999941],
                [15.676256180000166, -5.870360849999941],
                [15.67010593399999, -5.87010192799994],
                [15.660240172000158, -5.870489120999935],
                [15.55389118100004, -5.87466478399989],
                [15.53404045100018, -5.876242160999937],
                [15.52625370100003, -5.876859186999923],
                [15.526062489000026, -5.876874207999947],
                [15.525871276000089, -5.876889228999914],
                [15.416970253000045, -5.876104831999953],
                [15.40773010200013, -5.875774858999932],
                [15.351745606000179, -5.873770236999974],
                [15.350811005000025, -5.873736618999942],
                [15.293682801000102, -5.873894144999895],
                [15.293802236000147, -5.874314888999891],
                [15.294317245, -5.874602793999941],
                [15.295384408000132, -5.879888533999917],
                [15.251982689000045, -5.87469720799993],
                [15.251939455000013, -5.874269802999891],
                [15.240985871000191, -5.873382091999929],
                [15.155078888000048, -5.873122214999967],
                [15.12996864400003, -5.873042105999957],
                [15.12495994700015, -5.873025894999842],
                [15.12108230500013, -5.872039793999875],
                [15.119915486000082, -5.871742962999917],
                [15.110029220000115, -5.873101233999932],
                [15.029084207000039, -5.873249052999938],
                [14.999999046000028, -5.873301982999919],
                [14.962183952000032, -5.87342357599988],
                [14.961367606000181, -5.873482226999954],
                [14.918772766000188, -5.876490409999974],
                [14.911686421000127, -5.876991032999911],
                [14.910638809000147, -5.87706518199991],
                [14.902882576000081, -5.87659692699998],
                [14.901465447000191, -5.876511613999924],
                [14.80591011199999, -5.881174563999934],
                [14.804523469000117, -5.881244182999922],
                [14.776661873000023, -5.881192207999959],
                [14.761188507000156, -5.881161370999905],
                [14.759359360000076, -5.881157873999882],
                [14.701049804999968, -5.881042002999891],
                [14.700772285000141, -5.881041050999897],
                [14.700732574000142, -5.881040961999872],
                [14.70029799200006, -5.881040095999936],
                [14.669344972000147, -5.890632707999941],
                [14.656961442000124, -5.894629954999971],
                [14.655717849000155, -5.895030975999873],
                [14.65257740100003, -5.895661830999927],
                [14.644119263000107, -5.89736080099982],
                [14.64406442700016, -5.897372006999945],
                [14.635783195000101, -5.900434969999878],
                [14.615564346999975, -5.907935618999943],
                [14.611002903000042, -5.909627872999977],
                [14.610881329000108, -5.909672975999968],
                [14.610759734999988, -5.909718036999948],
                [14.59514141000011, -5.910245893999957],
                [14.530320168000173, -5.895277022999892],
                [14.509123802000147, -5.890380858999947],
                [14.503377914999987, -5.890175818999978],
                [14.487327575000165, -5.896914004999871],
                [14.4859571450001, -5.897243021999941],
                [14.484586715000034, -5.897572039999886],
                [14.475621224000122, -5.899724005999929],
                [14.458629608000081, -5.901175021999904],
                [14.45769119300013, -5.901123046999885],
                [14.456752778, -5.901071071999922],
                [14.40636157900019, -5.898279190999972],
                [14.405294418000096, -5.898219584999822],
                [14.400161441000023, -5.899212106999869],
                [14.400032997000039, -5.899975775999906],
                [14.399807101000022, -5.899955535999936],
                [14.397648811000124, -5.899762152999927],
                [14.378762246, -5.896950720999882],
                [14.37794841900012, -5.896777880999878],
                [14.377529621000065, -5.896730541999887],
                [14.367466927000066, -5.896122931999969],
                [14.366019249000033, -5.896042823999949],
                [14.301243783000075, -5.88347482699993],
                [14.295200348000037, -5.882301806999919],
                [14.281513214000142, -5.884113786999933],
                [14.243310928000142, -5.889175891999912],
                [14.232975483000189, -5.889218090999862],
                [14.231591224000169, -5.889224051999975],
                [14.197322942000028, -5.870567556999902],
                [14.196108341000127, -5.869932174999917],
                [14.19207573000017, -5.86815118699991],
                [14.177256582999973, -5.861607074999881],
                [14.163991929000076, -5.868791103999911],
                [14.150179862000073, -5.869071959999872],
                [14.13155078900013, -5.864085195999849],
                [14.078564643999982, -5.866389988999856],
                [14.078178406, -5.866654871999913],
                [14.061771391999969, -5.877916811999967],
                [14.061216831000024, -5.878064392999818],
                [14.042659759000117, -5.883006094999928],
                [14.038133621000043, -5.88421392399988],
                [13.987770558000079, -5.842859505999911],
                [13.977680206000059, -5.845289229999935],
                [13.968459129000166, -5.849219798999911],
                [13.952633858000013, -5.851248739999903],
                [13.932354927000176, -5.853808879999917],
                [13.921744347000129, -5.856000422999955],
                [13.920969486000047, -5.856160400999954],
                [13.920194624999965, -5.856320379999886],
                [13.906598091000149, -5.85835266],
                [13.892766000000108, -5.860874175999925],
                [13.87155532800017, -5.866609571999902],
                [13.857008934000078, -5.869379518999949],
                [13.84843635500016, -5.872600554999906],
                [13.838667870000052, -5.875585079999951],
                [13.831511497000065, -5.876603124999974],
                [13.816721916, -5.878886699999896],
                [13.806890488000136, -5.879416464999963],
                [13.805450758, -5.875271796999925],
                [13.686353683000164, -5.875829218999968],
                [13.65247726500013, -5.87600422],
                [13.59173107100014, -5.876317023999945],
                [13.587190628000087, -5.875774858999932],
                [13.572979927000119, -5.87407398199997],
                [13.559066297000186, -5.875184058999878],
                [13.548547745000121, -5.878983973999937],
                [13.53177261400009, -5.878345011999954],
                [13.52475357000003, -5.87563705499997],
                [13.538821221000035, -5.884648321999919],
                [13.551151275000052, -5.894588469999917],
                [13.542424203000053, -5.895678043999965],
                [13.531043052000143, -5.895124435999946],
                [13.523689748000095, -5.892538307999928],
                [13.516175270000076, -5.890029429999913],
                [13.510246277000078, -5.886866092999924],
                [13.506180762000042, -5.884697436999943],
                [13.499491691000173, -5.880997657999956],
                [13.493942261000029, -5.877927301999932],
                [13.488162995000039, -5.875712631999932],
                [13.481648923000023, -5.873857735999934],
                [13.469532012000116, -5.873719214999937],
                [13.456742286000065, -5.874833106999972],
                [13.449743748000117, -5.876223563999929],
                [13.443153381000059, -5.877819061999958],
                [13.438887596000143, -5.877844332999928],
                [13.43031454100003, -5.874554156999977],
                [13.429203612000038, -5.8702069819999],
                [13.42919699399999, -5.870181082999977],
                [13.429076731000123, -5.86999801199994],
                [13.429000855000027, -5.869873046999942],
                [13.428959846000112, -5.869760511999914],
                [13.426150322000183, -5.862030029999971],
                [13.424185444000159, -5.86255220799984],
                [13.423184395000021, -5.862818241999889],
                [13.422958375000121, -5.862878321999972],
                [13.422899381000036, -5.862846551999951],
                [13.418381341000156, -5.864336894999951],
                [13.4181642530001, -5.864608762999978],
                [13.417390824000165, -5.865035055999897],
                [13.415837289000137, -5.86540555799985],
                [13.413691521000089, -5.865257262999933],
                [13.413121929, -5.865241457999957],
                [13.409499168000025, -5.865602969999941],
                [13.40909866100003, -5.865648793999981],
                [13.407995224000103, -5.866146087999937],
                [13.405997979000119, -5.866003558999921],
                [13.405073167000182, -5.86610937099988],
                [13.404220069000132, -5.866222345999915],
                [13.402594566000175, -5.867479799999956],
                [13.401411057000075, -5.86799812199996],
                [13.400301933000094, -5.86851692099998],
                [13.399044037000124, -5.868813037999871],
                [13.398748398000123, -5.868442534999929],
                [13.398674012000185, -5.867553710999971],
                [13.398674012000185, -5.866928272999871],
                [13.39428512000012, -5.866697393999971],
                [13.394235611, -5.866813181999873],
                [13.393347740000024, -5.867479799999956],
                [13.392755509000096, -5.867183208999961],
                [13.39075851399997, -5.866368292999937],
                [13.387966127000141, -5.865261910999948],
                [13.38532066300013, -5.864442585999939],
                [13.378197365000119, -5.865323340999964],
                [13.377220153999986, -5.865998267999942],
                [13.37633323700004, -5.866072176999921],
                [13.37500097800006, -5.866633880999871],
                [13.372708, -5.867332000999966],
                [13.368430713000066, -5.871628059999978],
                [13.367388346999974, -5.873870210999883],
                [13.367381003000105, -5.873924971999884],
                [13.36627200099997, -5.876592],
                [13.365606500000069, -5.877702998999951],
                [13.364169669000148, -5.881769643999974],
                [13.363916337000148, -5.882725288999893],
                [13.363325118000148, -5.884956358999943],
                [13.36318665400006, -5.88553505799996],
                [13.362964, -5.886466999999868],
                [13.362803651999968, -5.886734541999886],
                [13.362602517000141, -5.887465581999891],
                [13.361769056000185, -5.891821474999972],
                [13.361515000000111, -5.895226000999912],
                [13.361399155000072, -5.895525381999903],
                [13.361094, -5.896314000999894],
                [13.360707793000017, -5.897367926999948],
                [13.360588202000031, -5.897694280999872],
                [13.36043, -5.898125999999877],
                [13.360389858000133, -5.899747104999904],
                [13.35993529000018, -5.900082436999924],
                [13.359089999000105, -5.900705998999911],
                [13.34946500000018, -5.900954000999946],
                [13.349210845000187, -5.900960287999908],
                [13.349187534000123, -5.900960864999945],
                [13.34898, -5.900966],
                [13.331321804000027, -5.893914392999932],
                [13.330308414, -5.893509706999964],
                [13.324532833000092, -5.89205960399994],
                [13.324298955000074, -5.893375017999972],
                [13.324171999000043, -5.89341400099994],
                [13.323225585999978, -5.89329533199998],
                [13.323206999000149, -5.893293000999961],
                [13.322906001000092, -5.892447000999937],
                [13.322607419000121, -5.891476452999939],
                [13.322461488000044, -5.891002102999948],
                [13.322422999000139, -5.890876998999886],
                [13.321796001000109, -5.89011],
                [13.321360247000143, -5.889935596999976],
                [13.320943999000121, -5.889769000999877],
                [13.304933547000076, -5.883372306999945],
                [13.302936999000053, -5.881332],
                [13.299799999000015, -5.878191],
                [13.29587800000013, -5.875170000999958],
                [13.293586, -5.873720000999981],
                [13.291716, -5.872511998999926],
                [13.290087, -5.872148998999933],
                [13.289303, -5.871242998999946],
                [13.28803500000015, -5.87094099899997],
                [13.282002, -5.871242998999946],
                [13.275668, -5.870698998999956],
                [13.273437, -5.870034998999927],
                [13.272893000000124, -5.868464],
                [13.270601, -5.868464],
                [13.269696000000124, -5.868222999999887],
                [13.269214, -5.867316],
                [13.268248, -5.867256],
                [13.266680000000122, -5.866531],
                [13.265232, -5.866107999999883],
                [13.263844000000176, -5.865263],
                [13.262818, -5.864355999999873],
                [13.261793, -5.864234999999894],
                [13.260948000000155, -5.86484],
                [13.259138000000178, -5.865020999999899],
                [13.257992, -5.864537],
                [13.256967, -5.863027],
                [13.255700000000104, -5.862302],
                [13.254493, -5.861638],
                [13.253166, -5.862],
                [13.25214, -5.861759],
                [13.250391, -5.861094],
                [13.24828, -5.86049],
                [13.246651, -5.859886],
                [13.244736999000111, -5.860284],
                [13.243959001, -5.859661998999968],
                [13.242768000000183, -5.859142999999847],
                [13.241110000000106, -5.859766],
                [13.239244999000107, -5.860439999999869],
                [13.238002000000165, -5.861166],
                [13.237225999000032, -5.860803],
                [13.235309, -5.860907000999873],
                [13.233236, -5.860907000999873],
                [13.232563001000187, -5.860544000999937],
                [13.231579, -5.859714],
                [13.22961, -5.859247000999915],
                [13.227849999000171, -5.858832],
                [13.226035999000146, -5.857845999999825],
                [13.224533999000073, -5.857275],
                [13.222463, -5.857172],
                [13.220079001000045, -5.856860998999935],
                [13.218370000000107, -5.856809000999931],
                [13.217075, -5.856809000999931],
                [13.216350000000148, -5.857067998999924],
                [13.215521001000013, -5.857275],
                [13.214330000000132, -5.857587000999956],
                [13.213656000000185, -5.857482999999888],
                [13.212776001000123, -5.857638998999903],
                [13.21158400000013, -5.858157998999957],
                [13.209824001000072, -5.859142999999847],
                [13.207389000000148, -5.860077],
                [13.205576, -5.861062999999888],
                [13.204332001000068, -5.861477000999969],
                [13.203866000000176, -5.86127000099998],
                [13.203193, -5.861840000999962],
                [13.202881999, -5.862359],
                [13.202209, -5.862255998999956],
                [13.201068999000086, -5.863034],
                [13.200343999000154, -5.863914998999974],
                [13.199515, -5.864277998999967],
                [13.19879, -5.86412299899996],
                [13.197909001000141, -5.863241],
                [13.196874, -5.863708000999964],
                [13.196614001000114, -5.864797000999943],
                [13.196355, -5.865886999999873],
                [13.195423, -5.866508998999905],
                [13.193350999000131, -5.867805998999927],
                [13.192677, -5.868273],
                [13.191797, -5.868064999999888],
                [13.191123001000165, -5.867702],
                [13.190450000000169, -5.86692399999987],
                [13.189414, -5.865937998999925],
                [13.188274, -5.86599],
                [13.187496999000189, -5.867182999999898],
                [13.186927999000147, -5.868375998999966],
                [13.186357000999976, -5.869672998999874],
                [13.185529000000145, -5.870398998999917],
                [13.184649000000149, -5.87076199899991],
                [13.183664, -5.87076199899991],
                [13.182680001000051, -5.870088],
                [13.181644, -5.869985000999918],
                [13.180866999000159, -5.870192000999907],
                [13.179468, -5.870503],
                [13.178328999000087, -5.871074],
                [13.177344001000165, -5.871177999999873],
                [13.175635, -5.871593],
                [13.173563, -5.872111],
                [13.172060999, -5.872163],
                [13.169678999000098, -5.872163],
                [13.168019999000137, -5.872941000999902],
                [13.166156001000104, -5.872992998999962],
                [13.16397999899999, -5.873979],
                [13.160354999000106, -5.875172000999953],
                [13.156158, -5.875949998999943],
                [13.153310001000023, -5.87761000099988],
                [13.149528999000154, -5.878647],
                [13.147249000999977, -5.880151],
                [13.146101001000034, -5.880726],
                [13.145384000000149, -5.881085000999974],
                [13.143086001000142, -5.882943998999963],
                [13.140830000000108, -5.884543998999902],
                [13.140294000000154, -5.885886],
                [13.139890001000026, -5.88689699899993],
                [13.138480000000186, -5.888402998999936],
                [13.137852, -5.888999],
                [13.13669400100008, -5.890097000999901],
                [13.133686000000182, -5.892074],
                [13.131336, -5.893579999999872],
                [13.127495001000113, -5.894705998999882],
                [13.124614, -5.896219000999849],
                [13.123603001000163, -5.897514000999877],
                [13.122765000000186, -5.89805700099987],
                [13.12208, -5.898284],
                [13.121462001000111, -5.898414998999897],
                [13.120764999000187, -5.898418000999868],
                [13.120300000000157, -5.89805700099987],
                [13.119913000000111, -5.897759000999884],
                [13.119475001000012, -5.897871998999904],
                [13.119475001000012, -5.898533998999881],
                [13.119492999000101, -5.899039],
                [13.117617, -5.899603],
                [13.115920000000131, -5.898851],
                [13.114697999000043, -5.898379999999861],
                [13.113764999000068, -5.898473998999975],
                [13.111973, -5.897063000999935],
                [13.110469000000137, -5.894333],
                [13.108306999000035, -5.89235599899996],
                [13.105675000000133, -5.889815],
                [13.103605999000081, -5.887555998999915],
                [13.102197001000036, -5.884261999999865],
                [13.101489999000023, -5.881504000999939],
                [13.101256001000024, -5.880589998999881],
                [13.100774001000104, -5.879228999999896],
                [13.100597000999983, -5.878669000999935],
                [13.100582057, -5.878640973999893],
                [13.100222587000133, -5.877672672999893],
                [13.098342896000133, -5.874566554999944],
                [13.096556663, -5.871648310999888],
                [13.094394684000122, -5.869012831999953],
                [13.093454362000102, -5.867412566999974],
                [13.09326648800004, -5.866282938999973],
                [13.092514991000087, -5.865718364999964],
                [13.090446471000178, -5.864964961999874],
                [13.089224816000069, -5.864023685999882],
                [13.086310387000083, -5.863082408999901],
                [13.083960532000106, -5.863082408999901],
                [13.083490372000085, -5.862329483999872],
                [13.083209037000188, -5.862047195999878],
                [13.081892968000091, -5.862141132999909],
                [13.081892968000091, -5.862800120999907],
                [13.081047058000081, -5.863082408999901],
                [13.079448701000103, -5.863082408999901],
                [13.078132629000095, -5.862800120999907],
                [13.076628686000049, -5.863459109999894],
                [13.074936867000133, -5.863741397999888],
                [13.073432922000165, -5.864400385999886],
                [13.071458816000074, -5.864118097999892],
                [13.06826305400017, -5.863553045999936],
                [13.065913201000114, -5.863364696999895],
                [13.064126968000039, -5.862517832999913],
                [13.063186646000077, -5.861482143999922],
                [13.061870575000114, -5.860729216999971],
                [13.060743332000015, -5.861011504999965],
                [13.059803010000053, -5.861388206999891],
                [13.058674812000106, -5.861388206999891],
                [13.055854798000041, -5.860823153999945],
                [13.053034783000044, -5.860164641999972],
                [13.05012130700004, -5.859317302999955],
                [13.048052787000131, -5.859035014999961],
                [13.045609475000106, -5.858658313999968],
                [13.043259620000072, -5.85790538699996],
                [13.041378974000111, -5.858187674999954],
                [13.040533065000034, -5.857999323999877],
                [13.038465500000086, -5.85734033499989],
                [13.036679267000181, -5.85809373799998],
                [13.03009986800015, -5.859599590999949],
                [13.02257919300007, -5.859035014999961],
                [13.017973900000129, -5.858187674999954],
                [13.012991904000046, -5.85734033499989],
                [13.009043694000013, -5.856210706999946],
                [13.005095482000058, -5.855740069999911],
                [13.002996446000111, -5.856176852999909],
                [12.995652199, -5.859009742999945],
                [12.992606164000165, -5.858955382999966],
                [12.98961448600005, -5.858682632999944],
                [12.986467361000166, -5.857790945999966],
                [12.984788005000155, -5.857142962999831],
                [12.984329, -5.860085998999978],
                [13.003761001000044, -5.864473],
                [13.00368600000013, -5.864964999999813],
                [13.002840000000106, -5.866659998999978],
                [13.0002080000001, -5.868259998999861],
                [12.997106000000144, -5.87033],
                [12.99914199900013, -5.875405998999952],
                [12.999003, -5.877411],
                [12.997278001000097, -5.879553999999871],
                [12.994655001000126, -5.882665],
                [12.9920310010001, -5.884392998999942],
                [12.990443, -5.885638000999961],
                [12.988235001000078, -5.888196000999926],
                [12.98623199900004, -5.889300999999875],
                [12.982297000000187, -5.887227998999947],
                [12.980226, -5.889300999999875],
                [12.979120999000031, -5.891514000999905],
                [12.977947001000075, -5.892758],
                [12.976498001000039, -5.894278998999937],
                [12.974358001000098, -5.894969999999887],
                [12.973185001000047, -5.894694],
                [12.972011001000055, -5.894278998999937],
                [12.971804, -5.893449000999965],
                [12.970906, -5.894141000999866],
                [12.969871000000182, -5.89455599899992],
                [12.968628001000127, -5.895661],
                [12.965866000000119, -5.897321],
                [12.963036000000102, -5.899187],
                [12.960343, -5.899948],
                [12.956754, -5.900569],
                [12.953508, -5.901606998999966],
                [12.949986999000146, -5.902229],
                [12.946122, -5.903196],
                [12.942600999000092, -5.903335000999959],
                [12.93825100000015, -5.903404],
                [12.933350001000122, -5.903127000999973],
                [12.929967001000136, -5.902782],
                [12.927757999000164, -5.903819000999931],
                [12.924443999000061, -5.904372],
                [12.920993, -5.903819000999931],
                [12.918162000000109, -5.902782],
                [12.91567700100012, -5.90133],
                [12.91257000100012, -5.902574998999967],
                [12.910430001000123, -5.903127000999973],
                [12.908635, -5.904924998999945],
                [12.906978, -5.906583998999906],
                [12.903802, -5.907758998999952],
                [12.892411, -5.909901998999885],
                [12.891445, -5.910731],
                [12.888752000000181, -5.910731],
                [12.886543001000064, -5.909971000999917],
                [12.883920001000149, -5.909625999999889],
                [12.882125, -5.910247999999854],
                [12.879640001000041, -5.9108],
                [12.876671000000158, -5.910869998999885],
                [12.873219000000176, -5.909003000999974],
                [12.869699, -5.910662000999935],
                [12.867075, -5.911423],
                [12.861413999000149, -5.91156099899996],
                [12.859343001000184, -5.91156099899996],
                [12.854509999000186, -5.912873999999874],
                [12.84891800000014, -5.915086],
                [12.845466999000166, -5.917990000999964],
                [12.843464, -5.920271],
                [12.842705000000137, -5.922967000999904],
                [12.841669001000128, -5.92428],
                [12.840496001000076, -5.92663],
                [12.839806, -5.928358998999897],
                [12.83883900100011, -5.929188],
                [12.83745800000014, -5.930847],
                [12.836145999000053, -5.932367998999951],
                [12.834835, -5.935201998999958],
                [12.834005999000055, -5.937275999999883],
                [12.832832001000099, -5.940178998999954],
                [12.832625001000054, -5.942253],
                [12.831935, -5.943496998999933],
                [12.831106999000042, -5.944257000999926],
                [12.829726001000154, -5.944603],
                [12.829449999000076, -5.946262],
                [12.827516999000181, -5.948956998999961],
                [12.824893, -5.951099998999894],
                [12.821717000000149, -5.952897999999891],
                [12.819854000000191, -5.953727000999947],
                [12.815435001000083, -5.954695000999891],
                [12.812742999000079, -5.955593998999973],
                [12.809567000000129, -5.95815099899994],
                [12.805770000000166, -5.960639],
                [12.805149001000018, -5.960225],
                [12.80425200000019, -5.960225],
                [12.802731999000059, -5.961676],
                [12.797831, -5.965201],
                [12.793689001000075, -5.967896998999947],
                [12.791135, -5.967966000999979],
                [12.790651000000139, -5.967206000999966],
                [12.788649999000029, -5.967206000999966],
                [12.787752001000172, -5.966721998999901],
                [12.786508998999977, -5.966653],
                [12.783195001000081, -5.966031000999919],
                [12.781193, -5.965616],
                [12.776430000000119, -5.964441],
                [12.769940000000133, -5.962989000999926],
                [12.765798001000178, -5.962367],
                [12.761448999000038, -5.962367],
                [12.757375999000033, -5.962505000999954],
                [12.753095999000095, -5.962782],
                [12.748608001000036, -5.962574],
                [12.745502001000091, -5.962574],
                [12.743982000000187, -5.96216],
                [12.74129, -5.962919998999951],
                [12.740209000000107, -5.963636],
                [12.73680300000018, -5.965891999999883],
                [12.732798999000124, -5.96872599999989],
                [12.732522000000188, -5.972459000999947],
                [12.731348999000033, -5.974739998999951],
                [12.729968000999975, -5.976952000999916],
                [12.729000999000107, -5.977228],
                [12.728932000000157, -5.979301000999953],
                [12.726377999000135, -5.982619000999932],
                [12.724030001000187, -5.984899998999936],
                [12.721615001000032, -5.985661000999926],
                [12.718023999000081, -5.988356],
                [12.714773001000026, -5.991331000999935],
                [12.71402100099999, -5.992018998999981],
                [12.709809000000121, -5.994024],
                [12.704424999000082, -5.997203],
                [12.702906, -5.997686998999939],
                [12.701732000000106, -5.997686998999939],
                [12.699039, -5.997617999999818],
                [12.696278999000185, -5.999207000999945],
                [12.693447999000057, -5.999828998999874],
                [12.690755, -6.00128099899996],
                [12.687510001000078, -6.002662998999881],
                [12.684610999000029, -6.003216000999885],
                [12.681918, -6.002801],
                [12.676879, -6.002939998999977],
                [12.673289000000182, -6.002871],
                [12.672254001000113, -6.002871],
                [12.67142500000017, -6.002318],
                [12.671217998999964, -6.00135],
                [12.668180001000167, -6.00176500099991],
                [12.66321, -6.001903],
                [12.656375000000139, -6.001696],
                [12.649954999000158, -6.001834],
                [12.645045999000047, -6.001714999999877],
                [12.640943999000115, -6.001481],
                [12.632609000000173, -6.00099],
                [12.626384001000133, -6.002041000999952],
                [12.621134001000087, -6.003692998999952],
                [12.61850799900003, -6.006171000999871],
                [12.615208000000109, -6.006847],
                [12.613107999000022, -6.007747999999879],
                [12.61055800000014, -6.009776],
                [12.607707999000127, -6.011352998999939],
                [12.604857, -6.012779],
                [12.602532001000156, -6.013305],
                [12.600956999000061, -6.015181998999935],
                [12.599457001000133, -6.015707],
                [12.593982000000153, -6.01473099899988],
                [12.591131001000065, -6.016533],
                [12.591131001000065, -6.018786],
                [12.589931, -6.020212998999966],
                [12.587981000000127, -6.020889000999944],
                [12.58235500100011, -6.021039000999963],
                [12.579131001000178, -6.021564],
                [12.575680998999985, -6.022990998999887],
                [12.57275499900004, -6.024117000999979],
                [12.56428, -6.025918998999941],
                [12.563004000000149, -6.026069998999901],
                [12.560453999000117, -6.028247000999897],
                [12.557904, -6.029824],
                [12.555655000000115, -6.029898998999897],
                [12.551978998999971, -6.030275],
                [12.548002999000175, -6.031176],
                [12.544554000000176, -6.032227000999967],
                [12.538918000000137, -6.033584000999895],
                [12.529280001000188, -6.037778000999879],
                [12.526043999000137, -6.040613000999883],
                [12.520583000000101, -6.041625998999962],
                [12.51554870700005, -6.041152953999927],
                [12.514111519000153, -6.041018008999913],
                [12.51188659800016, -6.041625499999839],
                [12.505819321000047, -6.041625499999839],
                [12.501976013000046, -6.042232988999899],
                [12.497527122000065, -6.043852329999879],
                [12.497324943000137, -6.046079634999956],
                [12.493279458000188, -6.048306465999929],
                [12.472246170000176, -6.054583072999833],
                [12.451009750000082, -6.057620047999933],
                [12.442313195, -6.058429717999957],
                [12.437361718000034, -6.057309627999928],
                [12.405935288000023, -6.026562689999935],
                [12.40407657600008, -6.026190756999938],
                [12.403332711000076, -6.021104811999976],
                [12.399987222000107, -6.010064123999939],
                [12.395773887000132, -6.001628398999969],
                [12.392428398000163, -5.997038840999949],
                [12.38660430900012, -5.991208075999964],
                [12.386167724000131, -5.990876791999824],
                [12.382019999000079, -5.987734],
                [12.379664999000056, -5.9845089989999],
                [12.372231, -5.97582499899994],
                [12.369380999000043, -5.97173100099991],
                [12.363185000000101, -5.964535],
                [12.361203000000103, -5.964535],
                [12.359468001000153, -5.963790998999969],
                [12.359344001000011, -5.962425999999823],
                [12.358725, -5.958952],
                [12.350546000000122, -5.947786000999884],
                [12.344971000000157, -5.942203998999958],
                [12.343359998999972, -5.939845998999829],
                [12.343731, -5.937860998999895],
                [12.342243999, -5.93451099899994],
                [12.337040000000115, -5.928432],
                [12.325269001000095, -5.917140998999969],
                [12.314734999000166, -5.907836],
                [12.302964, -5.898902000999954],
                [12.299371000000178, -5.896172999999862],
                [12.292432001000066, -5.889967998999907],
                [12.288342000000171, -5.882399998999972],
                [12.28351, -5.878800999999896],
                [12.282642000000124, -5.876444],
                [12.280165000000181, -5.873342],
                [12.271986001000187, -5.864656],
                [12.268022, -5.861801998999852],
                [12.268022, -5.860561],
                [12.268268999000099, -5.859072],
                [12.265047001000141, -5.854231998999921],
                [12.254638999000179, -5.843685000999926],
                [12.240513000000135, -5.828670000999921],
                [12.229113000000154, -5.815888],
                [12.222422, -5.806704998999919],
                [12.218580001000021, -5.799382998999931],
                [12.213623999000106, -5.790820000999929],
                [12.212941085000125, -5.789568950999978],
                [12.212917165000079, -5.789525016999903],
                [12.212639001000127, -5.789014921999979],
                [12.212347041000157, -5.78848007299996],
                [12.212191034000114, -5.788194092999902],
                [12.212082862000045, -5.788194179999948],
                [12.212082923000139, -5.787996858999918],
                [12.211274009000022, -5.786514999999895],
                [12.211251130000164, -5.786472896999953],
                [12.210971004999976, -5.785959008999896],
                [12.210679080999967, -5.785424998999929],
                [12.210220011000047, -5.784584011999925],
                [12.210139275000074, -5.784584045999907],
                [12.210139138000159, -5.784435135999956],
                [12.210068997000121, -5.784306022999942],
                [12.209860802000037, -5.784306049999941],
                [12.209860802000037, -5.78402805199994],
                [12.209583281999983, -5.78402805199994],
                [12.209583281999983, -5.783750056999907],
                [12.209304809000173, -5.783750056999907],
                [12.209304809000173, -5.783194065999908],
                [12.209029198000167, -5.783194065999908],
                [12.209029198000167, -5.782639979999829],
                [12.208748818000061, -5.782639979999829],
                [12.208748818000061, -5.782083988999943],
                [12.208473205000075, -5.782083988999943],
                [12.208473205000075, -5.781527994999919],
                [12.208194732000038, -5.781527994999919],
                [12.208194732000038, -5.78125],
                [12.20791721400019, -5.78125],
                [12.20791721400019, -5.780972003999921],
                [12.207638741000153, -5.780972003999921],
                [12.207638741000153, -5.78041505799996],
                [12.207361221000099, -5.78041505799996],
                [12.207361221000099, -5.780139922999979],
                [12.207082748000062, -5.780139922999979],
                [12.207082748000062, -5.779861926999899],
                [12.206805229000111, -5.779861926999899],
                [12.206805229000111, -5.779305933999922],
                [12.206526756000073, -5.779305933999922],
                [12.206526756000073, -5.779026984999916],
                [12.206251144000021, -5.779026984999916],
                [12.206251144000021, -5.7787489899999],
                [12.205972671000154, -5.7787489899999],
                [12.205972671000154, -5.778192995999916],
                [12.205695151999976, -5.778192995999916],
                [12.205707550000113, -5.777629851999961],
                [12.205416679000166, -5.777638912999919],
                [12.205416679000166, -5.777360914999917],
                [12.205139161000091, -5.777360914999917],
                [12.205139161000091, -5.776804923999919],
                [12.204860688000053, -5.776804923999919],
                [12.204860688000053, -5.77624893299992],
                [12.204583168, -5.77624893299992],
                [12.204583168, -5.775694846999897],
                [12.204304695000133, -5.775694846999897],
                [12.204304695000133, -5.775416850999932],
                [12.204029084000183, -5.775416850999932],
                [12.204029084000183, -5.775138855999842],
                [12.203750611000146, -5.775138855999842],
                [12.203750611000146, -5.774860857999954],
                [12.203473091000092, -5.774860857999954],
                [12.203473091000092, -5.774582861999932],
                [12.203194618000055, -5.774582861999932],
                [12.203194618000055, -5.774026870999933],
                [12.202917099999979, -5.774026870999933],
                [12.202917099999979, -5.773748874999853],
                [12.202638626000066, -5.773748874999853],
                [12.202638626000066, -5.77347278499991],
                [12.202360153000029, -5.77347278499991],
                [12.202360153000029, -5.772916793999912],
                [12.202084541000147, -5.772916793999912],
                [12.202084541000147, -5.772638797999946],
                [12.201804160000108, -5.772638797999946],
                [12.201804160000108, -5.772360800999934],
                [12.201528550000035, -5.772360800999934],
                [12.201528550000035, -5.772269527999924],
                [12.208042060000025, -5.768321413999956],
                [12.221611999000061, -5.759451998999964],
                [12.229995608000138, -5.755957978999959],
                [12.24708900000013, -5.748833998999942],
                [12.265304501000116, -5.743398],
                [12.265679001000024, -5.743284],
                [12.281070000000113, -5.742801],
                [12.314736455000116, -5.745218929999965],
                [12.391019821000043, -5.74135589499997],
                [12.508155823000152, -5.73525857899989],
                [12.517174721000117, -5.734788894999952],
                [12.535100937999971, -5.733852862999925],
                [12.537283898000112, -5.68416976799989],
                [12.535739264000028, -5.660252286999878],
                [12.520590078000168, -5.662161991999881],
                [12.515626000000111, -5.663219],
                [12.511539, -5.663777],
                [12.509188000000165, -5.664098],
                [12.508913994000068, -5.470109939999929],
                [12.516585983000084, -5.467628674999958],
                [12.530684000000122, -5.463069],
                [12.535794000000124, -5.461608999999896],
                [12.537169785000174, -5.461216060999902],
                [12.538353055000016, -5.414390183999842],
                [12.538353000000143, -5.414376999999888],
                [12.538180000000182, -5.372559999999851],
                [12.537752000000125, -5.269204],
                [12.537735000000168, -5.265057],
                [12.53810878600018, -5.246188746999906],
                [12.538110734000156, -5.246077059999834],
                [12.539179802000149, -5.19212722799989],
                [12.538951874000134, -5.18492317099998],
                [12.538616597000157, -5.174256380999964],
                [12.537907701000108, -5.15170754299993],
                [12.537561000000153, -5.140686],
                [12.537505545000101, -5.138915618999931],
                [12.534920692000185, -5.134620188999975],
                [12.528574204000165, -5.133528554999884],
                [12.527620744000103, -5.133364638999922],
                [12.527536, -5.13335],
                [12.527316000000155, -5.133312],
                [12.52722400000016, -5.133296],
                [12.526497, -5.133170999999891],
                [12.524149000000136, -5.13253],
                [12.523975000000121, -5.132497999999885],
                [12.523742, -5.132455999999877],
                [12.523404, -5.132394],
                [12.523129462000156, -5.132553576999953],
                [12.522930145000089, -5.132669924999902],
                [12.522606428000188, -5.132856253999876],
                [12.521555089000174, -5.133461297999929],
                [12.521554581000146, -5.133484321999958],
                [12.521550999000169, -5.133499000999962],
                [12.521518166000021, -5.133482093999874],
                [12.514028548000113, -5.13011693999988],
                [12.508971213999985, -5.127831935999893],
                [12.501019478000046, -5.124237536999942],
                [12.493970871000101, -5.121051787999875],
                [12.487316131000171, -5.115983962999962],
                [12.483178140000177, -5.109754085999953],
                [12.480436324000152, -5.105625152999892],
                [12.476839066000025, -5.099305390999973],
                [12.47126197900019, -5.093193052999936],
                [12.468968772999972, -5.088849233999895],
                [12.471982956999966, -5.085091351999893],
                [12.47332954400008, -5.08082580599995],
                [12.474944114000095, -5.075710772999855],
                [12.479313851000086, -5.069271563999962],
                [12.492163658000038, -5.065143107999973],
                [12.510499955000114, -5.070214270999884],
                [12.519017219000148, -5.070457934999979],
                [12.530817508000155, -5.067125319999946],
                [12.532517432000077, -5.066313742999853],
                [12.539220811000064, -5.063114165999878],
                [12.552308082000138, -5.050469875999966],
                [12.554144859000132, -5.049896478999926],
                [12.555981636000126, -5.049323081999944],
                [12.56906414000008, -5.051174163999974],
                [12.575390340000013, -5.050593613999979],
                [12.5759773260001, -5.050240992999932],
                [12.580539703000056, -5.047502040999973],
                [12.586738585000091, -5.04014301199993],
                [12.59387844500003, -5.040301113999874],
                [12.59421444000003, -5.040308474999961],
                [12.595594539999979, -5.040339046999975],
                [12.601202965000141, -5.037623880999945],
                [12.606486321000148, -5.026814936999926],
                [12.614817143000039, -5.021857022999939],
                [12.614979744000095, -5.02175998599995],
                [12.622647285000085, -5.023451804999922],
                [12.623518467, -5.0236439699999],
                [12.629529714000114, -5.020579218999956],
                [12.63449096800008, -5.009808062999923],
                [12.636330604000079, -4.999998092999931],
                [12.640468598000155, -4.991852758999926],
                [12.641273021000188, -4.984162090999973],
                [12.640472413, -4.977602958999967],
                [12.635499955000171, -4.967329979999875],
                [12.632675170000141, -4.961493013999927],
                [12.634285926000189, -4.955051898999955],
                [12.634944916000165, -4.954535721999889],
                [12.635603905000039, -4.954019545999927],
                [12.639565468, -4.950914859999955],
                [12.640075207, -4.950870751999958],
                [12.644843101000163, -4.950457096999912],
                [12.64966392600013, -4.950920105999956],
                [12.661359787000038, -4.95691108699998],
                [12.666502317000095, -4.95760345399998],
                [12.666703403000042, -4.957658406999883],
                [12.667357445000107, -4.957619189999946],
                [12.671759605000034, -4.95904016399993],
                [12.693551063000086, -4.954391002999898],
                [12.702681541000061, -4.954255579999881],
                [12.711448669000163, -4.954125880999925],
                [12.71763420100018, -4.949511049999899],
                [12.723811149000028, -4.943056106999961],
                [12.726538659000084, -4.932234762999883],
                [12.726718902000187, -4.910607813999889],
                [12.728427410000165, -4.901668785999959],
                [12.736984867000047, -4.89679494599983],
                [12.738839150000103, -4.895166872999937],
                [12.739645004000124, -4.895279882999944],
                [12.744429589000163, -4.888969897999971],
                [12.748450280000043, -4.88249301899998],
                [12.754627227000128, -4.876496790999966],
                [12.770681382000021, -4.874391079999953],
                [12.778700828000069, -4.869312761999936],
                [12.786471367000104, -4.855029104999915],
                [12.797101020000184, -4.847696780999968],
                [12.7974840440001, -4.847500835999881],
                [12.798851967000019, -4.846565722999969],
                [12.801970164000181, -4.840876578999882],
                [12.796580725000069, -4.836286519999931],
                [12.794037819000152, -4.8348169329999],
                [12.793234349000102, -4.833436488999894],
                [12.792430879000051, -4.832056045999934],
                [12.794039727000097, -4.82653284],
                [12.80092334699998, -4.819173811999917],
                [12.805154800000082, -4.808063028999868],
                [12.805449008999972, -4.807292937999932],
                [12.805743218000089, -4.806522846999883],
                [12.8070669170001, -4.804152965999947],
                [12.80744044, -4.803484598999944],
                [12.81118011500007, -4.795134067999925],
                [12.815154076000169, -4.785819053999887],
                [12.816532136000149, -4.783753394999962],
                [12.817461014000116, -4.782361984999852],
                [12.818369865000079, -4.781908035999948],
                [12.821578980000083, -4.780299185999979],
                [12.831217766000066, -4.77432250999982],
                [12.833971023, -4.770641802999933],
                [12.836726189000103, -4.764661787999955],
                [12.83649635400019, -4.759828090999918],
                [12.839479446000098, -4.755228042999931],
                [12.840830803000074, -4.754941462999909],
                [12.846315384000093, -4.753778218999969],
                [12.847051621000162, -4.753622055999927],
                [12.848435127000073, -4.753792440999973],
                [12.85603499400014, -4.753843426999936],
                [12.857311181000114, -4.750843178999844],
                [12.860590935000062, -4.741202830999953],
                [12.861851692000187, -4.740168094999945],
                [12.863113403000057, -4.739132881999922],
                [12.866862377000075, -4.739134521999972],
                [12.867599317000156, -4.738982523999937],
                [12.867606164000108, -4.738812923999944],
                [12.867937370000107, -4.738789366999924],
                [12.869396210000048, -4.738685607999969],
                [12.872750282000084, -4.738447189999931],
                [12.875187874000176, -4.735285281999893],
                [12.875919819000046, -4.734336136999957],
                [12.876651763000041, -4.733386992999954],
                [12.892020226000056, -4.731781004999959],
                [12.898449897000091, -4.734314917999882],
                [12.914278031000038, -4.734551906999968],
                [12.921159744000022, -4.732829570999911],
                [12.922307968000155, -4.732542275999947],
                [12.930087089000153, -4.728855132999968],
                [12.943188668000118, -4.722137927999938],
                [12.94926834100005, -4.720758437999905],
                [12.955353736, -4.719377517999874],
                [12.964331627000092, -4.71462774299988],
                [12.96582698800006, -4.714175938999972],
                [12.973432859000127, -4.710834661999854],
                [12.981112480000036, -4.706238269999972],
                [12.986090661000105, -4.703052043999946],
                [12.987079620000088, -4.702771662999908],
                [12.987615585000128, -4.702607154999896],
                [12.988563537000061, -4.702649116999965],
                [12.989338875999977, -4.702983854999957],
                [12.98941412500011, -4.703009832999896],
                [12.993041754000103, -4.703436255999918],
                [13.008830071000034, -4.699665069999981],
                [13.014567375000127, -4.700342178999961],
                [13.021441460000176, -4.697103976999927],
                [13.028788567000106, -4.695209978999969],
                [13.034967422000022, -4.693618773999958],
                [13.051449776000084, -4.680922030999966],
                [13.057040215000143, -4.679206370999907],
                [13.075297356000078, -4.685793876999981],
                [13.077106475999983, -4.686592577999932],
                [13.083898545000011, -4.689110754999888],
                [13.092070579999984, -4.688411234999933],
                [13.097579956000061, -4.6893181799999],
                [13.106961250000154, -4.680550098999902],
                [13.109678268000096, -4.668575762999979],
                [13.104601860000173, -4.658698081999944],
                [13.105730057000187, -4.65087080099994],
                [13.10476303199999, -4.648590086999889],
                [13.106272698000055, -4.647281646999886],
                [13.109726905000116, -4.64808130299997],
                [13.113554001000068, -4.649300096999923],
                [13.117394447000038, -4.65009403199997],
                [13.120863914000097, -4.650468826999941],
                [13.125494003000142, -4.650826453999912],
                [13.130868913000029, -4.65202283799988],
                [13.13704776700007, -4.652357577999965],
                [13.144402504000084, -4.652250765999895],
                [13.149881364000123, -4.650470733999896],
                [13.156461715000034, -4.650374889999966],
                [13.162343025000098, -4.648164271999917],
                [13.169013023000048, -4.645516871999973],
                [13.175682068000015, -4.642869472999962],
                [13.182459831000131, -4.638998984999944],
                [13.188200952000045, -4.643029212999977],
                [13.191715241000111, -4.651248455999962],
                [13.192333221000126, -4.65269517899992],
                [13.195005416000129, -4.654226302999973],
                [13.200364112000159, -4.657296179999946],
                [13.206103326000175, -4.664658068999927],
                [13.209749222000028, -4.668998717999898],
                [13.209774970000012, -4.669029236999961],
                [13.220046997000054, -4.674813269999959],
                [13.222854612999981, -4.676393031999965],
                [13.228589057000022, -4.677313804999926],
                [13.231402396000021, -4.678947925999978],
                [13.232949256000097, -4.679845808999971],
                [13.235025406000091, -4.6822738649999],
                [13.243259429000034, -4.691903590999971],
                [13.245232581999971, -4.694211481999957],
                [13.24672222200013, -4.695952890999877],
                [13.256366729000092, -4.719419955999911],
                [13.25939941300004, -4.722206590999917],
                [13.264631270999985, -4.727013110999963],
                [13.264733315000115, -4.727471827999921],
                [13.265089990000035, -4.729084015999888],
                [13.258436203000088, -4.728623866999953],
                [13.256811142000174, -4.72975301699995],
                [13.255453111000179, -4.730696200999887],
                [13.254230499000073, -4.733613012999911],
                [13.253621102000011, -4.735066890999974],
                [13.254002570000125, -4.736138820999827],
                [13.254767418000029, -4.738289831999964],
                [13.25912952300007, -4.741971014999933],
                [13.26211261800006, -4.743809700999975],
                [13.263418197000021, -4.745584486999917],
                [13.265327453000054, -4.748180865999927],
                [13.268998146000115, -4.75002288699983],
                [13.273132324000017, -4.758764743999961],
                [13.276306153000178, -4.761527538999928],
                [13.281849860000136, -4.766353606999928],
                [13.283743859000083, -4.769830226999829],
                [13.284609795000165, -4.771419046999938],
                [13.289887429000032, -4.77049875199998],
                [13.294018745000074, -4.774640082999895],
                [13.298378945000024, -4.775099754999928],
                [13.30000686599999, -4.777003288999936],
                [13.300730705000149, -4.778513906999933],
                [13.302513123000153, -4.782234191999919],
                [13.306183815000111, -4.784302710999953],
                [13.310084343000085, -4.783841131999907],
                [13.313298225999972, -4.786602973999834],
                [13.315436364000163, -4.786449908999941],
                [13.316509247, -4.786373137999931],
                [13.317419052000048, -4.78539466899997],
                [13.31972026700015, -4.782919883999909],
                [13.324080467000101, -4.783609865999893],
                [13.332342148000066, -4.789130209999939],
                [13.340372085000013, -4.784757136999872],
                [13.342207909000024, -4.784941195999977],
                [13.344962120000105, -4.785216808999962],
                [13.349088668000149, -4.786475179999911],
                [13.350239755000075, -4.786826133999909],
                [13.359421730000122, -4.797406672999955],
                [13.363784790000182, -4.799019812999916],
                [13.372733116000177, -4.798556803999929],
                [13.374973297000167, -4.80143022599998],
                [13.378474235000112, -4.805919170999971],
                [13.381462098000156, -4.816503046999912],
                [13.380236626000169, -4.818553923999957],
                [13.378933907000146, -4.820734022999886],
                [13.377336502000048, -4.82340669599995],
                [13.377751350999972, -4.826169967999874],
                [13.378718377000041, -4.832610129999921],
                [13.379727364000132, -4.833561896999868],
                [13.382620812000084, -4.836289881999903],
                [13.381913184000155, -4.838706492999961],
                [13.381475449000106, -4.840201854999862],
                [13.382007599000076, -4.841578006999953],
                [13.384232520000069, -4.84733390699995],
                [13.385551452000129, -4.849303721999945],
                [13.385084153000093, -4.849823950999905],
                [13.380727768000156, -4.851215838999963],
                [13.378462792000164, -4.8606572139999],
                [13.379172326000059, -4.866867064999894],
                [13.382416724999985, -4.871444223999902],
                [13.387470245000145, -4.878577230999895],
                [13.391604423000103, -4.879944799999976],
                [13.401269913000078, -4.888659953999877],
                [13.407943726000099, -4.896250246999955],
                [13.425683975000027, -4.916427134999879],
                [13.430974006000156, -4.919864177999955],
                [13.43464374500013, -4.919621943999914],
                [13.436050416000057, -4.917671202999884],
                [13.436470986000018, -4.917086122999876],
                [13.438269615000081, -4.905576228999962],
                [13.435012817000086, -4.892011165999975],
                [13.427568436000115, -4.882459639999979],
                [13.427866935, -4.882199287999924],
                [13.437140465000084, -4.873972892999973],
                [13.443631171000163, -4.868031501999894],
                [13.454314232000058, -4.868954181999925],
                [13.463138580000134, -4.869418143999951],
                [13.469875336000086, -4.866277216999947],
                [13.471954346000189, -4.865307806999965],
                [13.480763436000188, -4.857080935999932],
                [13.491891860000123, -4.847483158999978],
                [13.494874955000114, -4.845077990999926],
                [13.496567727000183, -4.841898916999924],
                [13.504418372000032, -4.835124491999977],
                [13.506723405000059, -4.831027029999859],
                [13.502526283000066, -4.821417807999978],
                [13.498331069000074, -4.81226634899997],
                [13.503416062000042, -4.799461363999967],
                [13.512223243000051, -4.790318964999869],
                [13.518712997000023, -4.783462046999944],
                [13.527523995000024, -4.776606559999948],
                [13.532631874000117, -4.77660989799989],
                [13.540065765000122, -4.778445243999954],
                [13.543324470000016, -4.783022402999961],
                [13.546590806000097, -4.791716097999938],
                [13.550324441000043, -4.80178260799994],
                [13.558230401000117, -4.808192729999973],
                [13.565203668000038, -4.812314510999954],
                [13.572639466000112, -4.815521715999978],
                [13.578587532000029, -4.817921638999962],
                [13.58228015800006, -4.819411277999961],
                [13.587323189000131, -4.817768096999885],
                [13.594443321000028, -4.813752173999944],
                [13.601284981000163, -4.802456855999935],
                [13.607406616000162, -4.781040190999931],
                [13.630669593000164, -4.742310047999979],
                [13.635249138000063, -4.739533900999959],
                [13.649704933000066, -4.740636824999911],
                [13.65822410700008, -4.749123096999881],
                [13.663448334000066, -4.751285075999874],
                [13.673187256000119, -4.762649058999955],
                [13.678467750000038, -4.76401376799987],
                [13.685597420000022, -4.768590926999934],
                [13.69018268700006, -4.768346785999938],
                [13.690876007000043, -4.767268179999917],
                [13.692352296000081, -4.759341715999938],
                [13.692333222000116, -4.748362063999878],
                [13.689532279000048, -4.740125655999918],
                [13.685343743000089, -4.735089779999953],
                [13.678362846000027, -4.726850032999892],
                [13.680671691000043, -4.719532012999935],
                [13.684502601000133, -4.719325065999954],
                [13.689030648000028, -4.719080446999897],
                [13.701109886000097, -4.722291944999881],
                [13.709938050000119, -4.724585532999925],
                [13.716904639000177, -4.725048063999907],
                [13.722468377000098, -4.72047710499993],
                [13.726271630000099, -4.711245059999953],
                [13.729199410000035, -4.70908308099996],
                [13.731471062000082, -4.703322885999967],
                [13.73213005000008, -4.695498941999972],
                [13.731849670000145, -4.693448065999974],
                [13.730808258000138, -4.68582391599989],
                [13.723323822000111, -4.67884397399996],
                [13.71691226900009, -4.675357340999938],
                [13.713220596000099, -4.673350333999906],
                [13.711387635000051, -4.669106959999965],
                [13.711581229999979, -4.658752917999948],
                [13.710685729999966, -4.655508517999976],
                [13.716308594000168, -4.650929450999911],
                [13.718149185000129, -4.6417799],
                [13.721380234000037, -4.630343436999965],
                [13.730151177000039, -4.600607870999909],
                [13.730172158000073, -4.60045909899992],
                [13.735427857000161, -4.593094825999913],
                [13.748176574000126, -4.567282198999976],
                [13.7502040870001, -4.55783891599998],
                [13.748344422000116, -4.550251960999958],
                [13.750593185000128, -4.53828000999988],
                [13.750109672000178, -4.532069205999903],
                [13.746183396000049, -4.524027824999905],
                [13.738581657000168, -4.515538214999936],
                [13.734912872999985, -4.505744932999903],
                [13.730469703999972, -4.4938941],
                [13.730873107999969, -4.484374045999971],
                [13.730381966000039, -4.469271183999922],
                [13.735934258000043, -4.457833289999883],
                [13.74845981499999, -4.450061797999922],
                [13.755595207000113, -4.445895670999903],
                [13.755973815000175, -4.445674417999896],
                [13.755996703000108, -4.445833205999975],
                [13.762398721000125, -4.444837093999979],
                [13.763311386000055, -4.442283153999881],
                [13.768342972000141, -4.43881416399995],
                [13.768994332000034, -4.438144682999962],
                [13.781594276000021, -4.425193785999966],
                [13.793894766999983, -4.42374420099992],
                [13.807726860000059, -4.422111988999973],
                [13.819194795000101, -4.42253398899993],
                [13.828845023999975, -4.427792071999932],
                [13.852773666000076, -4.448187827999959],
                [13.85508918700009, -4.453932761999909],
                [13.86729431100008, -4.46723699599994],
                [13.870319366000047, -4.478040216999943],
                [13.869400025000061, -4.478044031999957],
                [13.876803399000153, -4.494585989999962],
                [13.879611016000183, -4.497381210999947],
                [13.883481025000037, -4.50123500899997],
                [13.895660400000111, -4.508094787999937],
                [13.90049362200017, -4.508407114999898],
                [13.919289590000119, -4.509622096999976],
                [13.937377930000025, -4.516100405999907],
                [13.944095612000012, -4.518506050999918],
                [13.959690093000063, -4.518220901999939],
                [13.969066620000035, -4.511282919999928],
                [13.98823642800005, -4.488859176999881],
                [14.001027108000187, -4.473892212999886],
                [14.017609597000103, -4.434020996999891],
                [14.020450593000135, -4.430829046999975],
                [14.029375076, -4.426923273999819],
                [14.033081054999968, -4.42584323899996],
                [14.036613465000016, -4.426358222999966],
                [14.040645599000072, -4.42363834299988],
                [14.041907310999989, -4.423270702999957],
                [14.053511620000108, -4.420074938999903],
                [14.067902564000121, -4.417339324999887],
                [14.082293511000046, -4.414603709999938],
                [14.101334571000109, -4.41507482399993],
                [14.114803314000085, -4.415542600999913],
                [14.128270150000048, -4.415552138999885],
                [14.144054413000163, -4.412359713999877],
                [14.15565681400011, -4.408706665999887],
                [14.171432495000033, -4.400937078999903],
                [14.176611900000012, -4.39970779399988],
                [14.178458214000045, -4.399584770999923],
                [14.179905892000079, -4.39892625799996],
                [14.184894561000135, -4.397742746999938],
                [14.199278832, -4.390887737999947],
                [14.206700325000099, -4.386315821999915],
                [14.217370033000066, -4.379915713999878],
                [14.230825424000045, -4.373059272999967],
                [14.238712311000029, -4.368487833999893],
                [14.251702309000166, -4.36117362899995],
                [14.256718637000063, -4.357876776999888],
                [14.262834548000114, -4.353857992999906],
                [14.277213097000072, -4.34379815899996],
                [14.28602504700018, -4.337396143999968],
                [14.301337242000102, -4.330083368999965],
                [14.304021836000175, -4.32735824599996],
                [14.313832283000011, -4.317401409999945],
                [14.320583344000056, -4.314119338999888],
                [14.321498870000084, -4.314331053999979],
                [14.339978218, -4.312652109999817],
                [14.342460632000041, -4.312859534999916],
                [14.358326913000042, -4.314186095999901],
                [14.361747741000102, -4.310030936999908],
                [14.362246514000049, -4.302335738999943],
                [14.370016098000065, -4.301752090999969],
                [14.381699563000154, -4.298126220999905],
                [14.386783599000182, -4.299341200999891],
                [14.40113449100005, -4.304062841999951],
                [14.408434868000029, -4.307663440999875],
                [14.40716361900013, -4.313525199999845],
                [14.403081893000092, -4.323206901999868],
                [14.395779610000091, -4.331291197999974],
                [14.386633872000118, -4.337312219999944],
                [14.387339592000046, -4.341220855999893],
                [14.402237893000063, -4.36189079199994],
                [14.405949592000127, -4.367041110999935],
                [14.417105675000073, -4.382516860999942],
                [14.420811653000101, -4.390554904999931],
                [14.430981635000137, -4.40300273899993],
                [14.431962013000089, -4.403871536999873],
                [14.436467170000071, -4.409689426999933],
                [14.438798904000066, -4.412569046999977],
                [14.458127975000025, -4.427676199999951],
                [14.480509758000153, -4.436332224999831],
                [14.473540307000178, -4.442506311999978],
                [14.470228194000128, -4.445439814999929],
                [14.469653130000154, -4.445949553999924],
                [14.469598770000118, -4.445983886999954],
                [14.469551087000184, -4.446013450999885],
                [14.46613693200004, -4.451094149999904],
                [14.464226722999967, -4.458685397999886],
                [14.462950706000186, -4.463754177999874],
                [14.461336135000067, -4.467131613999925],
                [14.459979057000055, -4.469972133999931],
                [14.448375703000124, -4.480164050999974],
                [14.447383881000121, -4.481035231999954],
                [14.444983481000122, -4.481956480999941],
                [14.442570686000067, -4.482882976999974],
                [14.438459396000042, -4.492322920999868],
                [14.432956695000144, -4.493250846999899],
                [14.435261725999965, -4.498771190999946],
                [14.433192254000176, -4.511789798999928],
                [14.431859015999976, -4.520176886999934],
                [14.430218697000043, -4.518097398999942],
                [14.428410530000122, -4.515810011999974],
                [14.425888061000023, -4.516042232999894],
                [14.423819542000047, -4.51397323599997],
                [14.421297074000051, -4.514210223999839],
                [14.413968086000125, -4.519511222999938],
                [14.411879540000143, -4.513788221999903],
                [14.406012535000059, -4.516944884999873],
                [14.401415825000072, -4.514894008999931],
                [14.381735802000037, -4.52486896399995],
                [14.358882904000097, -4.543601036999974],
                [14.358208657000034, -4.546596049999891],
                [14.350681304999966, -4.556062220999934],
                [14.349337577000028, -4.564120768999942],
                [14.352369309000039, -4.574921131999872],
                [14.357221604000131, -4.5829548829999],
                [14.373764039000093, -4.591788767999958],
                [14.381592751000028, -4.595971107999958],
                [14.378009796000129, -4.615544795999881],
                [14.381068229000107, -4.632557867999879],
                [14.385449409000103, -4.637832164999907],
                [14.386097907000078, -4.637808798999856],
                [14.385013581000067, -4.640430926999898],
                [14.38038635200013, -4.64957857],
                [14.380402565000111, -4.658729553999876],
                [14.381338119000077, -4.662847994999936],
                [14.386923790000139, -4.670172214999923],
                [14.395295143000055, -4.6770415299999],
                [14.40227317800003, -4.683909415999949],
                [14.405995369000095, -4.688029764999897],
                [14.40832710300009, -4.693063735999942],
                [14.408805846000178, -4.701299666999887],
                [14.408817291000162, -4.707704543999967],
                [14.409762383000043, -4.716855524999915],
                [14.410960197000179, -4.720878123999967],
                [14.40969181000014, -4.724018095999952],
                [14.407815932000176, -4.766131876999964],
                [14.408983232000139, -4.77050113699994],
                [14.415903091000075, -4.778522967999891],
                [14.416610716999969, -4.783123015999934],
                [14.408214568000119, -4.792678832999911],
                [14.405655861000071, -4.795594214999937],
                [14.401116371000171, -4.806427001999964],
                [14.40397358000007, -4.830113887999971],
                [14.403116227000055, -4.856336593999913],
                [14.402977943000167, -4.860551833999978],
                [14.402963637000084, -4.860948084999905],
                [14.409219743000051, -4.874497889999873],
                [14.412021637000066, -4.893554687999938],
                [14.412639618000128, -4.894320010999934],
                [14.416206360000047, -4.897478103999958],
                [14.426987647999965, -4.895585535999942],
                [14.429117203000033, -4.8943562499999],
                [14.430020333000186, -4.896379947999947],
                [14.433463096000025, -4.889738558999966],
                [14.435509682000088, -4.885789870999929],
                [14.438030243000185, -4.88432359799998],
                [14.445819855000025, -4.879789827999957],
                [14.446037293000074, -4.879837989999942],
                [14.449959755000066, -4.880710125999883],
                [14.45456028000018, -4.876389980999932],
                [14.456494331000044, -4.875911235999922],
                [14.45767879400006, -4.874868869999943],
                [14.460499762999973, -4.873559950999891],
                [14.471039772000154, -4.862969874999976],
                [14.471842765000076, -4.861753939999971],
                [14.477629661000151, -4.852989673999844],
                [14.482763290999969, -4.849799154999914],
                [14.489867210000057, -4.848157882999828],
                [14.496992111000111, -4.850659847999964],
                [14.508497238000189, -4.857972144999962],
                [14.528252601000077, -4.862489221999965],
                [14.536328316000038, -4.862325191999901],
                [14.542704583000159, -4.862196920999963],
                [14.557635307000055, -4.865354060999948],
                [14.576306343, -4.88344812399987],
                [14.5867080700001, -4.899738787999866],
                [14.592001914999969, -4.903397083999948],
                [14.611986160000129, -4.907450198999925],
                [14.628550530000041, -4.916580198999952],
                [14.641272544000174, -4.921319960999881],
                [14.646249770000054, -4.923175810999965],
                [14.687930108000103, -4.917560099999946],
                [14.693710327000076, -4.914389608999954],
                [14.714339256000073, -4.89611864099993],
                [14.72523784599997, -4.88646602599988],
                [14.739412308000055, -4.875816821999933],
                [14.750391007000076, -4.870533943999931],
                [14.75711822500017, -4.867297171999951],
                [14.757143974000087, -4.867284773999927],
                [14.761617661000116, -4.865132808999931],
                [14.765091896000058, -4.862318991999928],
                [14.765858650000098, -4.861839771999883],
                [14.77177333899999, -4.858158111999899],
                [14.771879197000089, -4.858102321999979],
                [14.777739524000083, -4.855007648999901],
                [14.786757469000065, -4.850244997999937],
                [14.797918320000122, -4.844350814999928],
                [14.800744057000031, -4.842863082999941],
                [14.812905312000055, -4.843037127999878],
                [14.823941231000049, -4.837622641999872],
                [14.826639175000139, -4.836298941999871],
                [14.835417747000122, -4.830251216999955],
                [14.835446358000127, -4.830231665999975],
                [14.843790054000181, -4.824483870999927],
                [14.849720002000026, -4.817783831999918],
                [14.855180740000094, -4.80878591599992],
                [14.855356216000075, -4.807876109999938],
                [14.856213569000033, -4.803397655999959],
                [14.868539811000119, -4.787469863999945],
                [14.870590210000046, -4.783790111999963],
                [14.871973037000146, -4.771837711999922],
                [14.8727808000001, -4.768224239999881],
                [14.875370026000098, -4.756649969999955],
                [14.875559808000162, -4.755770205999909],
                [14.882149696000056, -4.74261999],
                [14.896494866000012, -4.720924853999918],
                [14.896881104000045, -4.719702720999976],
                [14.898940086000039, -4.713300228999913],
                [14.903570175000027, -4.698849676999942],
                [14.903593064000063, -4.698804854999878],
                [14.90520954200008, -4.695590018999894],
                [14.912615777000099, -4.681914805999952],
                [14.913061142000117, -4.681092738999951],
                [14.925131798000166, -4.66855716699996],
                [14.942491532000133, -4.654205798999954],
                [14.94708538000009, -4.649895667999942],
                [14.95894909000009, -4.638764380999874],
                [14.959523202000184, -4.638225077999948],
                [14.966998100000126, -4.631210803999977],
                [14.980515481000054, -4.618529319999936],
                [14.98383998900016, -4.615128040999935],
                [14.992347718000019, -4.605202673999941],
                [14.99554157200015, -4.603437422999889],
                [14.997580528000128, -4.60092592299992],
                [15.00209045400004, -4.59421920799997],
                [15.002437591000103, -4.59377431799993],
                [15.005356788000142, -4.590031622999959],
                [15.009037017000082, -4.585004806999962],
                [15.013971329000128, -4.577035903999899],
                [15.02397823300015, -4.563908100999925],
                [15.031419754000069, -4.556458948999932],
                [15.031461716000024, -4.556416511999942],
                [15.031901359000074, -4.55597686699997],
                [15.032128334000106, -4.55560874899993],
                [15.04102134600015, -4.545188903999929],
                [15.052460670000187, -4.539998054999956],
                [15.061021806000099, -4.533096789999945],
                [15.062823295000044, -4.529299735999928],
                [15.065291406000085, -4.527033327999959],
                [15.071377754000082, -4.522657870999979],
                [15.072302819000129, -4.52072858799994],
                [15.073584557000117, -4.519347667999909],
                [15.07565689099999, -4.517277240999931],
                [15.08268737800006, -4.513850212999955],
                [15.084762573000035, -4.512176988999897],
                [15.084939003999978, -4.512058258999957],
                [15.087234497000111, -4.510506628999906],
                [15.08940601300003, -4.508337496999957],
                [15.091970444000026, -4.50567483899988],
                [15.093544007000105, -4.503303050999932],
                [15.095018386000163, -4.500930784999923],
                [15.096494674999974, -4.49885654499991],
                [15.09816932699999, -4.496783255999958],
                [15.100050927000098, -4.495903013999964],
                [15.10479545700008, -4.494965552999929],
                [15.104728699000077, -4.490683078999894],
                [15.104710578000038, -4.4895296099999],
                [15.104695320000019, -4.488564967999878],
                [15.104722976, -4.487606047999861],
                [15.104739189000043, -4.487044333999904],
                [15.105809212, -4.486238955999966],
                [15.107993127000157, -4.48444938699987],
                [15.108036994000145, -4.483510015999968],
                [15.107814789000145, -4.482570646999875],
                [15.108304024, -4.481317995999973],
                [15.109418868999967, -4.480736255999887],
                [15.110532761000115, -4.479796886999964],
                [15.112266540000121, -4.478771685999959],
                [15.113340377999975, -4.478675841999916],
                [15.115009308000083, -4.477502346999927],
                [15.115247727000167, -4.476569175999885],
                [15.114737510000111, -4.475276469999869],
                [15.11407852200017, -4.473840235999944],
                [15.114952088000109, -4.473322390999897],
                [15.115342141000156, -4.472930429999906],
                [15.115413665000062, -4.47142267199996],
                [15.115842820000125, -4.470991611999978],
                [15.116214752000133, -4.469636915999899],
                [15.116772651000019, -4.468693733999942],
                [15.118037225000023, -4.468142985999918],
                [15.119062423000116, -4.467998981999926],
                [15.120159149000131, -4.466419219999921],
                [15.120850562000044, -4.465150355999924],
                [15.12161350299999, -4.464599609999937],
                [15.122471810000036, -4.464408397999932],
                [15.122686386000055, -4.463690279999923],
                [15.123473167000043, -4.463330744999951],
                [15.124284745000068, -4.462708473999953],
                [15.124785423999981, -4.462469099999964],
                [15.123711586000127, -4.46227788899995],
                [15.123234749, -4.462086199999874],
                [15.123234749, -4.461679457999878],
                [15.123544693000099, -4.460937500999876],
                [15.123812676000171, -4.460152625999967],
                [15.12428379000005, -4.45933342],
                [15.124832153000057, -4.458782671999927],
                [15.125499725000054, -4.458974360999889],
                [15.126000403000091, -4.458590982999965],
                [15.126214981000032, -4.457921026999941],
                [15.125714302000119, -4.457489966999901],
                [15.125165939000112, -4.457130909999876],
                [15.124927520000028, -4.456125734999944],
                [15.124926568000149, -4.454928873999904],
                [15.12535572000013, -4.454091071999869],
                [15.125975609000136, -4.452654837999887],
                [15.126881599000171, -4.451457976999961],
                [15.127167703, -4.450117588999945],
                [15.127549171000112, -4.449207782999963],
                [15.127930641000091, -4.447819232999905],
                [15.128455163000126, -4.446646690999955],
                [15.129003524000098, -4.445305822999956],
                [15.129480361000049, -4.444875239999931],
                [15.130243302000054, -4.445425510999939],
                [15.130697251000186, -4.445401191999963],
                [15.131484032, -4.44482660299991],
                [15.13160324099999, -4.444587229999911],
                [15.1311912540001, -4.444568155999946],
                [15.131846427000085, -4.444341182999892],
                [15.132048607000115, -4.444130897999912],
                [15.132274627000129, -4.444081782999945],
                [15.132727623000164, -4.444115637999971],
                [15.133125305000021, -4.444891928999937],
                [15.133541107000156, -4.44507789499994],
                [15.133934975000045, -4.444966314999931],
                [15.134483338, -4.445021629999815],
                [15.13478469800009, -4.44516420399998],
                [15.135193824, -4.445093155999871],
                [15.135635377000028, -4.445305822999956],
                [15.135827064000182, -4.445689678999941],
                [15.136997223000094, -4.446189401999959],
                [15.147496223000132, -4.426771164999934],
                [15.149998665000055, -4.424015044999976],
                [15.154520035000075, -4.419037819999915],
                [15.162075996000056, -4.410719394999887],
                [15.162217140000052, -4.410564422999926],
                [15.162074088999987, -4.410256862999972],
                [15.161716462000186, -4.409299372999897],
                [15.161143303000074, -4.408246516999839],
                [15.161024094000084, -4.407695770999908],
                [15.161024094000084, -4.406475065999928],
                [15.161906243000146, -4.405589103999944],
                [15.163289070000076, -4.404392240999869],
                [15.164075852000167, -4.402668474999871],
                [15.165720939000096, -4.402021884999954],
                [15.165911674000029, -4.401303768999867],
                [15.166722299000185, -4.400250434999919],
                [15.168486594000058, -4.399077414999965],
                [15.168701172000056, -4.398622512999964],
                [15.1692972190001, -4.398262976999888],
                [15.169988632000184, -4.397927760999949],
                [15.170680047000076, -4.39790391899993],
                [15.172062874, -4.397927282999888],
                [15.17315960000019, -4.397161006999909],
                [15.173995017000095, -4.396993636999923],
                [15.174089432000187, -4.39589261999987],
                [15.174184798999988, -4.394863128999873],
                [15.174613953000176, -4.394001483999887],
                [15.17540073300006, -4.393306731999871],
                [15.176330567000093, -4.392708300999914],
                [15.177188874000137, -4.392349241999966],
                [15.177188874000137, -4.391607283999974],
                [15.177784920000079, -4.391128061999893],
                [15.17881012, -4.390266418999943],
                [15.179303169000036, -4.390211103999889],
                [15.179787636000071, -4.387514589999967],
                [15.181405067000014, -4.378505229999973],
                [15.191350938000028, -4.352221965999945],
                [15.193341256000053, -4.34911584799994],
                [15.194307327000161, -4.34760522799985],
                [15.207555770000056, -4.338102816999935],
                [15.21691227000008, -4.333296774999894],
                [15.220538138000052, -4.331433294999897],
                [15.223499296999989, -4.329911707999941],
                [15.228821754000137, -4.327177047999896],
                [15.230654717000164, -4.326789854999845],
                [15.254473685999983, -4.321753023999918],
                [15.257900239000094, -4.318972110999937],
                [15.265201569000055, -4.316487788999837],
                [15.265276909000079, -4.316345690999981],
                [15.265491485000098, -4.315690993999965],
                [15.265522003000115, -4.315369128999976],
                [15.265614510000034, -4.314821718999895],
                [15.266583443, -4.31380033399995],
                [15.26689243300018, -4.313535213999955],
                [15.267363548000162, -4.313402651999923],
                [15.267577172000074, -4.313086032999934],
                [15.268471717000125, -4.312452314999973],
                [15.26891708300019, -4.312023162999822],
                [15.269220352000161, -4.311724185999935],
                [15.269393920000141, -4.311443327999882],
                [15.269882203000122, -4.31096744599995],
                [15.270185470000058, -4.310548780999966],
                [15.270705224000096, -4.309872626999947],
                [15.271257400000138, -4.30917835199989],
                [15.271976471000187, -4.308219431999873],
                [15.272290231000056, -4.307679176999955],
                [15.27284908199999, -4.306802749999974],
                [15.273312569000097, -4.306298254999888],
                [15.274021149000077, -4.305696963999878],
                [15.27428817700013, -4.305435656999919],
                [15.274466514999972, -4.304977416999918],
                [15.274818419000155, -4.304247855999961],
                [15.275060653000139, -4.303853987999958],
                [15.275331498000071, -4.303535937999868],
                [15.275787354000101, -4.303020475999915],
                [15.276417732000027, -4.302458761999901],
                [15.276834488000077, -4.302068710999947],
                [15.277294158000075, -4.301674842999887],
                [15.277818680000166, -4.301313400999902],
                [15.278175353999984, -4.30104255599997],
                [15.278715134000095, -4.300820349999981],
                [15.279514312000117, -4.30050659099993],
                [15.280945779000149, -4.298830986999974],
                [15.293946266000148, -4.286338806999936],
                [15.302176476000056, -4.281692028999942],
                [15.313165664000167, -4.278872966999927],
                [15.324582101000033, -4.280914306999875],
                [15.33985710200011, -4.283646105999878],
                [15.371925354000098, -4.289378165999949],
                [15.39720725899997, -4.299825190999968],
                [15.415301322000062, -4.296506880999971],
                [15.428569794000111, -4.291371821999974],
                [15.448913575, -4.280218124999919],
                [15.457360268000173, -4.274147987999868],
                [15.463755608000156, -4.269550799999877],
                [15.467261314000154, -4.264782905999937],
                [15.476495742000168, -4.25222301499997],
                [15.491692544000102, -4.223835944999905],
                [15.499423981, -4.212287901999844],
                [15.503940582999974, -4.200985906999961],
                [15.505717277000088, -4.190851211999927],
                [15.50541591700005, -4.178196906999972],
                [15.501071929000148, -4.1629700659999],
                [15.500499725000054, -4.160966871999904],
                [15.499973297, -4.149233816999981],
                [15.506489753000039, -4.12641477499983],
                [15.514397621000114, -4.106582163999974],
                [15.519634246000066, -4.097726820999924],
                [15.52524471400011, -4.088239193999868],
                [15.531204222999975, -4.078160761999925],
                [15.533921241000087, -4.07356595899995],
                [15.537439346000156, -4.069098471999951],
                [15.542112350000082, -4.063164233999942],
                [15.550005912000188, -4.056563376999918],
                [15.550811766999971, -4.054310321999935],
                [15.551955223000107, -4.052413939999894],
                [15.553556443000048, -4.051435946999959],
                [15.556356429000118, -4.04815959799987],
                [15.558073045000015, -4.047641753999926],
                [15.559445381000046, -4.046951293999825],
                [15.56103897100013, -4.047336101999974],
                [15.56150055, -4.046949863999885],
                [15.5794219980001, -4.038721560999932],
                [15.580547333000084, -4.038204668999981],
                [15.581847191000122, -4.037631034999947],
                [15.589779853000152, -4.034624575999885],
                [15.591698646000168, -4.032596110999918],
                [15.594728470000064, -4.030353545999901],
                [15.598274231000062, -4.029661653999938],
                [15.599989891000064, -4.029028414999971],
                [15.604221345000099, -4.02718687],
                [15.60628032600016, -4.026725768999938],
                [15.607539178000081, -4.02695464999988],
                [15.609997750000105, -4.025574206999977],
                [15.612457276000043, -4.025055407999957],
                [15.613716125000167, -4.025456428999974],
                [15.614745140000139, -4.024766444999841],
                [15.61634636000008, -4.02424812299995],
                [15.616908074000037, -4.02434158199992],
                [15.639565468000114, -4.015753744999927],
                [15.639850617000036, -4.015555857999971],
                [15.642137527000045, -4.014807222999934],
                [15.644939423000039, -4.013713836999898],
                [15.646254539000154, -4.013712881999823],
                [15.660304070000109, -4.009572982999941],
                [15.660837173000061, -4.00954437199988],
                [15.660923957000136, -4.00953960399994],
                [15.669928551000169, -4.009057997999946],
                [15.671120644000155, -4.008359907999932],
                [15.677921296000079, -4.004381178999893],
                [15.679062844000043, -4.003713130999927],
                [15.713845254000034, -3.994079111999952],
                [15.739329338000061, -3.991434334999894],
                [15.739817619000064, -3.991383789999873],
                [15.770196915000099, -3.988230942999962],
                [15.793825149000043, -3.983305931999951],
                [15.800871850000021, -3.981837987999938],
                [15.83154773699999, -3.978347538999969],
                [15.831577301000152, -3.97834420199996],
                [15.834007263000103, -3.976808309999967],
                [15.837437630000181, -3.975512265999953],
                [15.839954376000037, -3.975050209999893],
                [15.841441155000041, -3.974876403999815],
                [15.842985153000086, -3.97475981599996],
                [15.844585418000065, -3.973437071999911],
                [15.847157479000145, -3.972170829999868],
                [15.850188256000024, -3.970731496999974],
                [15.851788521000174, -3.969983099999865],
                [15.853332520000095, -3.970096349999892],
                [15.854418754000108, -3.969291209999881],
                [15.85579109300005, -3.968485594999947],
                [15.857220650000102, -3.968024492999973],
                [15.859221459000025, -3.96704602199992],
                [15.861107827000126, -3.966584442999931],
                [15.862080573000128, -3.967186688999959],
                [15.86402511599999, -3.966897247999953],
                [15.865969658000154, -3.966550588999894],
                [15.867685317999985, -3.966204165999898],
                [15.869286538000154, -3.966489790999901],
                [15.871116639000149, -3.966028211999912],
                [15.871973991, -3.96539545],
                [15.872429847999967, -3.964246272999901],
                [15.873916625000163, -3.963785171999973],
                [15.874773980000157, -3.963324545999967],
                [15.875632286000098, -3.963725803999921],
                [15.876834869000049, -3.96481585499987],
                [15.877990723000096, -3.965342282999927],
                [15.87854766900017, -3.965219020999939],
                [15.880552291000129, -3.964468001999876],
                [15.884488105000173, -3.962563514999943],
                [15.889229775000103, -3.960269211999844],
                [15.89286708800006, -3.958508966999943],
                [15.895578385000135, -3.956008909999866],
                [15.899232864000055, -3.952639341999941],
                [15.900524139000083, -3.951448678999952],
                [15.90153312700005, -3.949456690999966],
                [15.902144432999989, -3.948636291999947],
                [15.902359008000076, -3.947453736999876],
                [15.90192794800015, -3.947036026999967],
                [15.902010917000155, -3.946872949999943],
                [15.904930116, -3.941108464999957],
                [15.904969215000165, -3.940976857999885],
                [15.905913352000027, -3.937857626999914],
                [15.908486367000023, -3.936878442999898],
                [15.910429954000165, -3.93584227499997],
                [15.91117096, -3.934233187999894],
                [15.910655022000128, -3.93256807399996],
                [15.909967422000079, -3.931592225999964],
                [15.909965514000078, -3.930072784999936],
                [15.910421371000041, -3.928145169999937],
                [15.91133499100016, -3.927110432999939],
                [15.913793564000116, -3.926303385999972],
                [15.914764404000152, -3.925038813999947],
                [15.917447090000053, -3.920383215999948],
                [15.917960166000057, -3.919061420999924],
                [15.917730331000143, -3.918027639999934],
                [15.918683053000109, -3.916286945999957],
                [15.919385911000177, -3.915268658999878],
                [15.919670104000147, -3.913200616999859],
                [15.920354843000041, -3.911763906999909],
                [15.921268464000036, -3.910326718999897],
                [15.920981408000102, -3.909350633999964],
                [15.921494484000107, -3.908028841999965],
                [15.922007560999987, -3.906190395999886],
                [15.922003746000144, -3.903433083999914],
                [15.922343254000168, -3.899584293999965],
                [15.92344379299999, -3.894918441999948],
                [15.92358398500005, -3.893727301999945],
                [15.924823761000141, -3.891259192999939],
                [15.925696374000154, -3.889131545999931],
                [15.92611789599999, -3.886919259999957],
                [15.926283836000096, -3.883828400999903],
                [15.929331779000108, -3.878322839999839],
                [15.929343224000093, -3.878264426999976],
                [15.92955017200012, -3.87718534399994],
                [15.93037033000013, -3.872910022999974],
                [15.943690300000185, -3.841069936999929],
                [15.952810287000091, -3.825391053999852],
                [15.957521438000072, -3.81728911499988],
                [15.964309693000132, -3.803439855999898],
                [15.966601371000024, -3.798765181999954],
                [15.981739998000023, -3.767889974999889],
                [16.001016616000186, -3.738552092999953],
                [16.009746552000024, -3.716763256999968],
                [16.011030197000139, -3.71355891199994],
                [16.012845993000099, -3.709028243999967],
                [16.023571015000186, -3.682260036999935],
                [16.037174225000058, -3.660320043999946],
                [16.046844483000086, -3.632186888999854],
                [16.052619934, -3.620280027999911],
                [16.058149338000135, -3.608870028999888],
                [16.065559388, -3.600430010999958],
                [16.086580277000166, -3.576479910999922],
                [16.110155105000104, -3.538366079999946],
                [16.112890243000152, -3.536280154999929],
                [16.12527847299998, -3.50421500199991],
                [16.128276824000181, -3.492714880999813],
                [16.132429124000055, -3.476785898999879],
                [16.144880295000064, -3.454849958999887],
                [16.164880752000101, -3.431020021999871],
                [16.187095643000134, -3.395443914999873],
                [16.191951753000069, -3.384867906999943],
                [16.204259872000023, -3.358059882999896],
                [16.211229324000044, -3.339600085999905],
                [16.222396850000166, -3.296272039999906],
                [16.2193756100001, -3.290076969999973],
                [16.19790840200011, -3.276277065999921],
                [16.189279555000155, -3.267340181999884],
                [16.184074401000089, -3.250494957999933],
                [16.186407088000067, -3.22240710199992],
                [16.192661286000089, -3.198898076999967],
                [16.192543030000081, -3.197530983999855],
                [16.190979005000088, -3.179001807999896],
                [16.190921784000068, -3.178662298999882],
                [16.18891334500006, -3.170833586999891],
                [16.185052872000085, -3.155817985999875],
                [16.180349349000039, -3.137538909999933],
                [16.179906846000108, -3.129961013999889],
                [16.178283692000093, -3.1021189679999],
                [16.182420731000036, -3.069648027999904],
                [16.191640853000138, -3.031336067999973],
                [16.195339204000163, -3.015954016999899],
                [16.194999696000139, -3.009048460999907],
                [16.19454765200004, -2.99985408799995],
                [16.190505982000104, -2.977332114999967],
                [16.184951782000098, -2.963559149999924],
                [16.182359696000049, -2.957134008999958],
                [16.181436538000014, -2.948566436999954],
                [16.18072891200012, -2.941987036999933],
                [16.179901123000093, -2.934305905999963],
                [16.179000854000094, -2.927999972999942],
                [16.179309845000034, -2.92151164899991],
                [16.179582596000103, -2.907426118999922],
                [16.180000305000135, -2.88597726699993],
                [16.180213928000114, -2.875008104999949],
                [16.185112, -2.852663992999851],
                [16.194999696000139, -2.822999952999908],
                [16.195632935000049, -2.821352480999849],
                [16.200386048000098, -2.810457944999939],
                [16.204391479000151, -2.792947052999978],
                [16.205356598000151, -2.784723996999901],
                [16.205472945999986, -2.783736942999951],
                [16.202705384000183, -2.746254921999935],
                [16.206695556000113, -2.726442098999939],
                [16.227687835000154, -2.682826041999874],
                [16.230691911, -2.674648998999942],
                [16.23285675, -2.668759107999961],
                [16.23383903600012, -2.664618013999871],
                [16.235748290000174, -2.656546115999902],
                [16.237369536000188, -2.624788044999889],
                [16.235355377000076, -2.606897114999924],
                [16.234935760000099, -2.603177069999958],
                [16.232563018000064, -2.595156906999932],
                [16.217613220000146, -2.544624090999946],
                [16.216192246000162, -2.541363715999921],
                [16.216009140000097, -2.540996073999963],
                [16.215890885000022, -2.540729998999893],
                [16.214000701000089, -2.536000011999931],
                [16.211580277000053, -2.526804923999976],
                [16.209190368000122, -2.516613005999886],
                [16.194168092000041, -2.447127102999957],
                [16.189126968000039, -2.423801898999955],
                [16.188457488999973, -2.418153046999919],
                [16.184278488000189, -2.382886886999927],
                [16.185237885000049, -2.319850920999954],
                [16.187068939000028, -2.300691127999812],
                [16.187894820000054, -2.29205703599996],
                [16.189729691000082, -2.272898912999949],
                [16.190368651000028, -2.255334377999873],
                [16.19146919300016, -2.225044010999909],
                [16.197692872, -2.206928729999845],
                [16.19890403800008, -2.203657865999844],
                [16.201000214000146, -2.197999953999954],
                [16.206567765000102, -2.187262772999929],
                [16.215448379000179, -2.171065091999878],
                [16.233959199000026, -2.137296438999954],
                [16.242000580000024, -2.122999906999951],
                [16.267803192000088, -2.10713982599998],
                [16.28602409400014, -2.093892097999913],
                [16.290290833000086, -2.091463088999944],
                [16.301296234000063, -2.084003924999877],
                [16.309000015000038, -2.078783272999942],
                [16.323524476000159, -2.068938970999966],
                [16.337682724000103, -2.056804417999956],
                [16.359220506000099, -2.038343906999899],
                [16.366039276000151, -2.031529425999963],
                [16.37131118800005, -2.026259420999963],
                [16.396139146000166, -2.001446962999978],
                [16.401880265000102, -1.995707988999868],
                [16.405548097000178, -1.992040991999943],
                [16.426162720000093, -1.968584417999978],
                [16.428586959000029, -1.965769408999961],
                [16.432966232000069, -1.960684655999955],
                [16.437000275000173, -1.955999970999869],
                [16.438753128000144, -1.953912257999889],
                [16.440687179000065, -1.951610922999976],
                [16.457738877000111, -1.931310653999958],
                [16.463087083000119, -1.924944400999891],
                [16.467187880000097, -1.920061944999873],
                [16.474796294000043, -1.911004422999895],
                [16.47900009100016, -1.906000017999929],
                [16.48112106300016, -1.903643249999959],
                [16.487878799999976, -1.89558804],
                [16.500305175000108, -1.879081009999879],
                [16.50399971000013, -1.873999953999942],
                [16.504152297000076, -1.873969674999955],
                [16.51962471000013, -1.853417038999908],
                [16.538002014000142, -1.829001544999926],
                [16.540128708000054, -1.825197934999892],
                [16.544763565000096, -1.816922426999952],
                [16.552000047000035, -1.804000018999943],
                [16.552665711000088, -1.803002356999912],
                [16.553998948000128, -1.801000117999877],
                [16.575149537000186, -1.7671619649999],
                [16.589008331000059, -1.745197057999917],
                [16.614866258000177, -1.694398997999826],
                [16.647974014000056, -1.629353046999881],
                [16.651309968000191, -1.622794627999951],
                [16.655952453000168, -1.613667964999934],
                [16.667015076000041, -1.591007350999917],
                [16.667156219999981, -1.590718268999979],
                [16.667390824000051, -1.590237973999933],
                [16.671611787000131, -1.581593036999891],
                [16.719797135000022, -1.48286414099988],
                [16.726217270000177, -1.469710945999964],
                [16.726581573000033, -1.468837617999895],
                [16.726900099999966, -1.468070029999979],
                [16.728694917000098, -1.463760732999958],
                [16.741487504000077, -1.433048963999909],
                [16.745000839000113, -1.426999924999905],
                [16.745056153000064, -1.4269044399999],
                [16.76134681700006, -1.398882029999925],
                [16.783388137000145, -1.355347989999927],
                [16.791683196000179, -1.338966726999956],
                [16.795999528000095, -1.33099996899989],
                [16.81599998500019, -1.30100011799982],
                [16.819288254000014, -1.296957611999915],
                [16.822103501000186, -1.293494818999932],
                [16.825048447000142, -1.289860605999877],
                [16.831825257000048, -1.281495213999961],
                [16.833354951000103, -1.279607415999919],
                [16.846000671000184, -1.263999938999973],
                [16.857000351000067, -1.253999947999887],
                [16.879472732000067, -1.233323691999942],
                [16.879537583000172, -1.233265041999971],
                [16.881822587000045, -1.231162308999899],
                [16.881999970000095, -1.23099994599994],
                [16.886323928000081, -1.226100085999917],
                [16.896999359000063, -1.214000105999901],
                [16.925430298000151, -1.189877033999949],
                [16.93020248400012, -1.185958027999959],
                [16.94997596800016, -1.172482013999968],
                [16.951454163000164, -1.171316980999961],
                [16.961297989000172, -1.163560985999936],
                [16.976770401000124, -1.151370048999866],
                [16.99041175800005, -1.142997026999922],
                [17.023445129000038, -1.131258963999869],
                [17.04119873000019, -1.123083948999977],
                [17.056190491000109, -1.11194097899994],
                [17.060077668000133, -1.110120058999939],
                [17.063898087000098, -1.108329772999923],
                [17.066658021000023, -1.10703599399983],
                [17.072002411000085, -1.102711438999961],
                [17.073101043000065, -1.101770639999927],
                [17.087999344000025, -1.088999986999909],
                [17.111000061000027, -1.074000000999945],
                [17.129999161000114, -1.064000009999973],
                [17.140672684000151, -1.06023275899986],
                [17.163999557000125, -1.051999925999894],
                [17.170000076, -1.050999998999942],
                [17.179122926000048, -1.048894642999926],
                [17.182691573, -1.048246026999891],
                [17.19326591399999, -1.047605156999964],
                [17.208999634000122, -1.047000050999941],
                [17.211338043000069, -1.047668217999956],
                [17.21599960399999, -1.049000144999923],
                [17.236999511000022, -1.047000050999941],
                [17.246809005000046, -1.043730615999948],
                [17.254804612000157, -1.040802000999918],
                [17.28698539800007, -1.019824980999943],
                [17.288883209000119, -1.018588064999847],
                [17.295478821000188, -1.014289020999968],
                [17.31034088199999, -1.008664369999963],
                [17.317794799000069, -1.005843042999857],
                [17.323165893000066, -1.00298643099984],
                [17.3300743100001, -0.999312996999834],
                [17.341194153000117, -0.990492998999969],
                [17.375284195000063, -0.953119991999927],
                [17.384189605000074, -0.94335997],
                [17.385110856000153, -0.942350982999869],
                [17.3924064630001, -0.937370538999915],
                [17.39313125700005, -0.936875998999938],
                [17.396469116000048, -0.933723687999873],
                [17.42900085500014, -0.902999995999949],
                [17.45700073200004, -0.875999927999885],
                [17.46599960400016, -0.86499994899998],
                [17.467014314000096, -0.864492356999961],
                [17.468000412000151, -0.863999901999932],
                [17.468999862000032, -0.861000120999961],
                [17.474000931000035, -0.856000065999979],
                [17.481336593000037, -0.848664103999965],
                [17.487062453000021, -0.842733978999945],
                [17.492511749000073, -0.83901202699991],
                [17.493848801000183, -0.835097014999974],
                [17.530759811000109, -0.791611014999944],
                [17.534103393, -0.787670016999925],
                [17.54354858500011, -0.770132005999926],
                [17.553150177000134, -0.759778021999978],
                [17.553592681000168, -0.75930202],
                [17.556768417000058, -0.755877672999929],
                [17.558700562000126, -0.75379502699991],
                [17.560295104999966, -0.752075016999868],
                [17.574512481000056, -0.731972992999943],
                [17.609579087000157, -0.693548976999921],
                [17.63289260900018, -0.663054405999958],
                [17.633184433000167, -0.662672996999902],
                [17.633926392000149, -0.661701857999958],
                [17.638034820000087, -0.656328022999958],
                [17.654903411000021, -0.625179171999889],
                [17.65492057900002, -0.625147759999948],
                [17.656923293000034, -0.622864723999896],
                [17.67300033600003, -0.604999957999951],
                [17.683649063000075, -0.595318912999971],
                [17.700065612000117, -0.567604005999954],
                [17.702610016000165, -0.560404419999941],
                [17.703025818000071, -0.559194146999971],
                [17.703060150000113, -0.559103250999954],
                [17.703474044000075, -0.558019994999938],
                [17.709651946000179, -0.540646016999915],
                [17.718694687000038, -0.503129005999938],
                [17.723791121000033, -0.470520436999891],
                [17.728221894000114, -0.4421809899999],
                [17.729520797000191, -0.43743804199994],
                [17.729881287000183, -0.436120003999918],
                [17.732084275000034, -0.428080140999953],
                [17.740392684000085, -0.39774900599997],
                [17.741415024000105, -0.385969250999949],
                [17.741849900000034, -0.38097000099998],
                [17.739620208000133, -0.333447992999879],
                [17.738599778000093, -0.326463221999973],
                [17.736999512000182, -0.319000004999964],
                [17.736274719000164, -0.313920915999972],
                [17.735729218000074, -0.311762004999878],
                [17.734607696000182, -0.307307988999924],
                [17.722011566000162, -0.275258987999962],
                [17.72134971700018, -0.271095007999918],
                [17.715263367000034, -0.232831001999955],
                [17.714021683000112, -0.216998502999957],
                [17.71401214600013, -0.216888934999929],
                [17.715999604000103, -0.199000044999877],
                [17.715000152000187, -0.189000010999962],
                [17.711999894000087, -0.156999975999952],
                [17.714000702000078, -0.128000064999981],
                [17.725000381000086, -0.09500001299989],
                [17.730419159000064, -0.084547929999928],
                [17.739000321000105, -0.068000091999863],
                [17.746999741000081, -0.054000068999926],
                [17.770999907999965, 0.0],
                [17.762075425000091, 0.012357244000043],
                [17.761791229000039, 0.012751977000107],
                [17.757999418999987, 0.017999965000172],
                [17.758024215000091, 0.018092221000188],
                [17.758079528000167, 0.018308236],
                [17.763999938000097, 0.040999980000095],
                [17.764497756000083, 0.045975638000016],
                [17.764999390000185, 0.051000075000161],
                [17.764999390000185, 0.052000012000065],
                [17.766782761, 0.06863518900002],
                [17.768253327000025, 0.079823002000126],
                [17.775184632000048, 0.10758300100008],
                [17.782596588999979, 0.130159065000043],
                [17.787000656000146, 0.142999993000046],
                [17.790445327000157, 0.150949628000149],
                [17.799999237000179, 0.17299999300019],
                [17.800264359000096, 0.173527330000184],
                [17.802000046000046, 0.177000090000092],
                [17.812999726000157, 0.196000070000082],
                [17.82099914600019, 0.215999962000069],
                [17.828907012000059, 0.226064489000123],
                [17.835575104000043, 0.234551445000136],
                [17.843000412000151, 0.243999988000041],
                [17.844999313000073, 0.248999983000147],
                [17.854999542000144, 0.260000080000168],
                [17.869073867000168, 0.27016529600013],
                [17.872999191000076, 0.273000062000051],
                [17.874284743000032, 0.273876072000064],
                [17.894643784000095, 0.288378001000069],
                [17.912891387000116, 0.305890948000069],
                [17.923000336000143, 0.31600004400002],
                [17.924919128000113, 0.319835842000145],
                [17.926021575000107, 0.322492004000139],
                [17.942062377000184, 0.342418999000188],
                [17.954000474000054, 0.365346998],
                [17.954000474000054, 0.366000027000155],
                [17.960023881000041, 0.388999493000142],
                [17.960283280000112, 0.399549990000082],
                [17.961288453000066, 0.403434993000076],
                [17.962598801000127, 0.408499987000141],
                [17.959930419000159, 0.466194004000045],
                [17.959455489000106, 0.469089448000034],
                [17.957216262000031, 0.482749997999974],
                [17.955078124000011, 0.489937992000023],
                [17.954286574999969, 0.49178266500013],
                [17.952930451000043, 0.494942994000041],
                [17.951234817000113, 0.498334676000184],
                [17.944324493000124, 0.512148022000019],
                [17.937175750000108, 0.518158020000101],
                [17.924722671999973, 0.529893457000071],
                [17.90500068700004, 0.548999965000121],
                [17.902507781, 0.552737594000064],
                [17.900999070000125, 0.555000067000094],
                [17.885999680000054, 0.571999968000057],
                [17.878000260000078, 0.590000094000118],
                [17.875999451000155, 0.593999982000128],
                [17.872999191000076, 0.61199998800015],
                [17.873331071000166, 0.627899646000117],
                [17.873350144000085, 0.628779292000104],
                [17.874000550000062, 0.659999967000033],
                [17.880001069000116, 0.675000012000112],
                [17.880689621000101, 0.685009301000036],
                [17.883354188, 0.689032972000177],
                [17.884963989000084, 0.691466630999969],
                [17.889778137000121, 0.703854918000104],
                [17.89089584400017, 0.706731141000148],
                [17.891000747000021, 0.706999959000029],
                [17.885000228000138, 0.738999903000035],
                [17.886810303000061, 0.7455133200001],
                [17.895277024000109, 0.775074005000022],
                [17.88702964800018, 0.803054990000135],
                [17.887088776000098, 0.816480995000177],
                [17.886205674000053, 0.826789976000043],
                [17.88315391600014, 0.838200987000164],
                [17.879463196000131, 0.845710993000182],
                [17.871984483000119, 0.856203140000105],
                [17.867771148000145, 0.862115979],
                [17.865476608000165, 0.865243913000029],
                [17.861181259000148, 0.871101022000119],
                [17.847539902000051, 0.911376119000181],
                [17.844999313000073, 0.919000029000188],
                [17.844999313000073, 0.927202762000036],
                [17.845115661000079, 0.936260999000183],
                [17.845762252000043, 0.939659952000113],
                [17.849565505000044, 0.959662973000036],
                [17.851245881000182, 0.971209408000163],
                [17.85216522200011, 0.977533996000147],
                [17.853153228000167, 0.9974349740001],
                [17.850131989000033, 1.012362481000025],
                [17.850116731000185, 1.012439013000119],
                [17.853580475000058, 1.0230410100001],
                [17.859508514000026, 1.029886008000119],
                [17.860731125000029, 1.040964962000032],
                [17.861127854000074, 1.044559003000131],
                [17.862098694999986, 1.047562956000093],
                [17.864000321000162, 1.051999927000111],
                [17.881000518000121, 1.078999997000153],
                [17.88245010300011, 1.0826253890001],
                [17.88311958200012, 1.084077002000015],
                [17.915153503000056, 1.114909054000066],
                [17.919147491000103, 1.120924234000029],
                [17.928594588000067, 1.135149955000145],
                [17.929668426000092, 1.138338567000176],
                [17.930000304000032, 1.139999985000145],
                [17.934335709000038, 1.148673296000027],
                [17.941770553000026, 1.164453031000164],
                [17.943052291000015, 1.16944193900008],
                [17.94499969400016, 1.185999990000084],
                [17.944978714000172, 1.187501311000062],
                [17.949724198000069, 1.205647946000056],
                [17.953483582000104, 1.222903013000178],
                [17.954883575, 1.231634974000144],
                [17.954902649000189, 1.251230955000153],
                [17.957771301000037, 1.266523957000175],
                [17.97040176500002, 1.308196784000018],
                [17.970649719000107, 1.309018015000049],
                [17.971853255000042, 1.317551017000142],
                [17.972757340000157, 1.321789026000033],
                [17.975999832000014, 1.337999941000135],
                [17.979991912000116, 1.345983745000069],
                [17.986848831000032, 1.360262036000108],
                [17.997413634999987, 1.386434555000164],
                [17.999999999000124, 1.393000008000058],
                [18.000545502000023, 1.393545271000164],
                [18.00263214000006, 1.396227002000103],
                [18.007583617000023, 1.40638494500007],
                [18.010601043000065, 1.418141483999989],
                [18.011133193000092, 1.420212983000056],
                [18.011655808000057, 1.42295229400014],
                [18.013000488000102, 1.42900002000016],
                [18.015996934000043, 1.43649005899999],
                [18.019001007000043, 1.443999888000121],
                [18.021129609000127, 1.447830797000052],
                [18.022241592000171, 1.449834346000046],
                [18.024000169000089, 1.453000069000041],
                [18.026514054000188, 1.457021475000033],
                [18.029338837000068, 1.460834027000033],
                [18.038127898000141, 1.477002024000115],
                [18.042510986000025, 1.48233103900003],
                [18.059642791999977, 1.50316],
                [18.063192368000045, 1.508853197000178],
                [18.066415786000164, 1.51402401900009],
                [18.067535401000157, 1.519371032000095],
                [18.067958831000169, 1.521394849000103],
                [18.066450118000148, 1.539785027999983],
                [18.06951522900016, 1.550544263000177],
                [18.071571350000056, 1.557762981000053],
                [18.072776794000106, 1.580085040000029],
                [18.072544098000037, 1.584560752000186],
                [18.072000503000083, 1.59000003400007],
                [18.072759629000018, 1.591898203000028],
                [18.075597764000122, 1.599887968000189],
                [18.077764511000055, 1.609991551000121],
                [18.079351425000084, 1.617398978000097],
                [18.076045990000068, 1.633474469000134],
                [18.07613182100016, 1.634376406000058],
                [18.078388215000132, 1.65419602500009],
                [18.087221146000104, 1.665076017000047],
                [18.088039397000045, 1.677963019000174],
                [18.083271026000148, 1.703920007000079],
                [18.074125290000154, 1.734444023000037],
                [18.073587417000113, 1.737708568000016],
                [18.072687150000149, 1.743175030000089],
                [18.065908432000072, 1.762905956000168],
                [18.065177918000018, 1.772802592000119],
                [18.06438255300003, 1.783597946000157],
                [18.058282853000037, 1.803796053000042],
                [18.058153152000045, 1.821048975999986],
                [18.059989930000143, 1.84900069299999],
                [18.05999755800002, 1.849130034000154],
                [18.067701339, 1.858160020000184],
                [18.067535401000157, 1.880475045000082],
                [18.068716049000102, 1.88390696099998],
                [18.07491874700014, 1.901929021000058],
                [18.075868607000075, 1.909005045000072],
                [18.077516556000091, 1.921275972999979],
                [18.080673217000026, 1.931442976000028],
                [18.08090210000006, 1.931866289000141],
                [18.091508865000037, 1.951485991000084],
                [18.092531205000114, 1.962113976000069],
                [18.091344834000097, 1.968086958000072],
                [18.086420058999977, 1.983693005000021],
                [18.086387635000165, 1.984125495000058],
                [18.085147857000095, 2.00116896600008],
                [18.084205627000074, 2.00484299600015],
                [18.067157746000078, 2.021277903999987],
                [18.065284728000165, 2.027014017000056],
                [18.068401337000012, 2.032830477000118],
                [18.071599960000071, 2.038798094000129],
                [18.070817948000069, 2.051446915000156],
                [18.073472977000051, 2.057181121000156],
                [18.075094223000121, 2.060683965000067],
                [18.077777864000041, 2.063083410000104],
                [18.078727722999986, 2.063931942000124],
                [18.080465317000062, 2.069861890000141],
                [18.080957413000078, 2.071542024000053],
                [18.088804245000119, 2.089559078000093],
                [18.088832855000135, 2.089622736000024],
                [18.089569091000158, 2.098822355000152],
                [18.0897769930001, 2.10142517200012],
                [18.090080260000093, 2.105206966000026],
                [18.090288162000036, 2.111354112000072],
                [18.090829850000091, 2.127300977000118],
                [18.090856552000162, 2.184596062000082],
                [18.090999603, 2.192413807999969],
                [18.091180802000167, 2.202316047000124],
                [18.091999055000088, 2.210995913000147],
                [18.092527390000043, 2.215742111000054],
                [18.093381882000017, 2.22343397100002],
                [18.09423256000008, 2.231092930000045],
                [18.094459533000133, 2.233134271000097],
                [18.094535827000016, 2.23381447800017],
                [18.094999313000187, 2.237999916000035],
                [18.099000930999978, 2.251000166000097],
                [18.103017807000185, 2.260185956000044],
                [18.10602378800013, 2.266630888000066],
                [18.116479875000095, 2.275686025000084],
                [18.119688035000138, 2.277383327],
                [18.123546600999987, 2.279423953000105],
                [18.135818481, 2.290103913000053],
                [18.141965866000191, 2.296713353000143],
                [18.144214630000079, 2.299129963000098],
                [18.146575928000061, 2.300546408000116],
                [18.15378379800012, 2.304507971000078],
                [18.163293838000072, 2.317698001000053],
                [18.165502547000131, 2.321504355],
                [18.167999267000084, 2.325000048000163],
                [18.169399262000013, 2.326506376000111],
                [18.180999756000062, 2.338999987000079],
                [18.182928086000118, 2.341891290000092],
                [18.184999466000079, 2.345000028000129],
                [18.185138702000074, 2.345389366000177],
                [18.189567567000154, 2.357788562000053],
                [18.190000535000081, 2.358999969000024],
                [18.190000535000081, 2.36999988700012],
                [18.193000793000181, 2.375999928000169],
                [18.202999114000079, 2.384000063000144],
                [18.207000732000097, 2.389999867000086],
                [18.208120346000157, 2.392015458000117],
                [18.210741044000088, 2.396734715000093],
                [18.211999893000097, 2.399000168000043],
                [18.212198258000058, 2.400776624000116],
                [18.214000702000135, 2.417000055000131],
                [18.21894073600015, 2.425741196000161],
                [18.220478057999969, 2.428459884000176],
                [18.226999284000101, 2.440000057000134],
                [18.229000092000092, 2.452000142000145],
                [18.228418350000084, 2.458969356000182],
                [18.228107453, 2.463860988000079],
                [18.232002258000136, 2.474999190000062],
                [18.232141495, 2.475398063000114],
                [18.234489440000061, 2.48503232000013],
                [18.236991882000154, 2.495002509000074],
                [18.237882614000114, 2.497117519000085],
                [18.242000579000091, 2.506999969000106],
                [18.26476669300007, 2.533561469000119],
                [18.266000749000057, 2.535000086000025],
                [18.267433165999989, 2.537150860000168],
                [18.267999650000149, 2.537999869000146],
                [18.268217087000096, 2.538169145000154],
                [18.269058227000073, 2.538823365999974],
                [18.276945114000114, 2.544547081000076],
                [18.295194627000058, 2.552510024000185],
                [18.312433242000111, 2.573580981000021],
                [18.321311951000098, 2.579862118000051],
                [18.329975129000161, 2.584527017000085],
                [18.336212159000183, 2.594663382000078],
                [18.338796615000149, 2.59886288600012],
                [18.340320587000122, 2.606590033000032],
                [18.341119766000077, 2.611191511000129],
                [18.343000412000038, 2.621999980000112],
                [18.34767150800019, 2.629940987000168],
                [18.353000641000108, 2.638999939000087],
                [18.35499954200003, 2.644999981000183],
                [18.360000611000032, 2.651999951000107],
                [18.384000778000143, 2.698999881000077],
                [18.393999099000041, 2.710000039000079],
                [18.397008895000056, 2.71799898099999],
                [18.397024154000121, 2.718147516000158],
                [18.398000717000059, 2.724000215000046],
                [18.401000977000137, 2.73300004000015],
                [18.405235291000167, 2.735964776000174],
                [18.41125488300014, 2.739794014999973],
                [18.414810182000053, 2.744573354000124],
                [18.420999527000106, 2.752000094000152],
                [18.429008485000054, 2.767996074000109],
                [18.429136276000065, 2.768233060000171],
                [18.432958602000099, 2.777928115000066],
                [18.436302184000169, 2.790611029000161],
                [18.438259125000172, 2.804665090000071],
                [18.445461273000149, 2.822442055000067],
                [18.445720673000096, 2.832819939000046],
                [18.445817947000137, 2.836714029000177],
                [18.441026688000136, 2.850495100000103],
                [18.439273833000129, 2.855539084000156],
                [18.438468933000138, 2.87233710300012],
                [18.438919067000086, 2.876596928000083],
                [18.440000535000024, 2.881999969000106],
                [18.440465927000162, 2.884100436999972],
                [18.441511153000135, 2.888906955000152],
                [18.441600800000174, 2.889199256000097],
                [18.442224502000101, 2.891237974000035],
                [18.447158813000044, 2.905774118000124],
                [18.450407028000143, 2.91792345000016],
                [18.451826096000161, 2.922889233000035],
                [18.452999114000022, 2.92700004500017],
                [18.458000183000024, 2.932999849000112],
                [18.461000442, 2.940000057000077],
                [18.462554932000046, 2.941555738999966],
                [18.462999345000128, 2.941999913000132],
                [18.464191436000078, 2.94581198700007],
                [18.46606636100006, 2.951812030000099],
                [18.468000412000094, 2.957999945000097],
                [18.472158432000072, 2.965127469000038],
                [18.475000382000189, 2.970000030000051],
                [18.493999480000127, 2.986999989000026],
                [18.496999741000138, 2.990999937000083],
                [18.501546859000086, 2.993841887000031],
                [18.504903794000143, 2.996191980000049],
                [18.516916273999982, 3.014003039000158],
                [18.521642685000188, 3.025316954000061],
                [18.524826050000058, 3.040545463000171],
                [18.525999070000125, 3.047000171000093],
                [18.528142930000058, 3.057716370000094],
                [18.528999328, 3.061999798000102],
                [18.530714035000074, 3.065426827000067],
                [18.53156471300008, 3.067126751000046],
                [18.533000945000083, 3.069999933000077],
                [18.539093018000131, 3.075538398000163],
                [18.543935775000136, 3.07956910200005],
                [18.566553115000147, 3.090571641999986],
                [18.570116042000109, 3.092241763000061],
                [18.576000212999986, 3.095000030000108],
                [18.579126359000043, 3.096042394000051],
                [18.584800720000146, 3.097585916000014],
                [18.587324143000103, 3.099860668000133],
                [18.594583511000167, 3.106401921000042],
                [18.597885131000112, 3.110327243000086],
                [18.601398468000127, 3.114506006000056],
                [18.610687256000062, 3.130913973000077],
                [18.614744186000166, 3.138082028000156],
                [18.625595092000026, 3.158874035000167],
                [18.632102966000105, 3.178529262000097],
                [18.635999679000179, 3.191000223000174],
                [18.639003755000033, 3.200010300000031],
                [18.641740798000114, 3.208940029000189],
                [18.642576217000055, 3.220684052000138],
                [18.641061784000044, 3.226165534000188],
                [18.639999389000025, 3.229000092000035],
                [18.637010574000044, 3.247537135000187],
                [18.635000230000173, 3.259999991000029],
                [18.635290145999988, 3.262909651000086],
                [18.636291503000166, 3.272914649000086],
                [18.638000488000102, 3.289999962000024],
                [18.64500045799997, 3.305000068000027],
                [18.648000717000173, 3.316999913000132],
                [18.649507522000022, 3.327553987000158],
                [18.649999618000095, 3.330999851000172],
                [18.649000168000157, 3.344000101000063],
                [18.64500045799997, 3.358999968000092],
                [18.632999421000079, 3.385999918000096],
                [18.632293701000151, 3.393757582000035],
                [18.631999969000162, 3.396999836000077],
                [18.635999679000179, 3.408999921000032],
                [18.63899994000019, 3.417000056],
                [18.639999389000025, 3.427000047000092],
                [18.639999389000025, 3.443885088000172],
                [18.639865875000169, 3.448560953000026],
                [18.636718750000057, 3.463433027000065],
                [18.635099412000045, 3.471087933000035],
                [18.635000230000173, 3.471093416000031],
                [18.635000230000173, 3.471555472000034],
                [18.634660721000046, 3.473155975000054],
                [18.623008728000059, 3.50800299799999],
                [18.622987747000025, 3.508068323000032],
                [18.622730255000022, 3.509353639000153],
                [18.618434906000175, 3.533354044000078],
                [18.617229462000125, 3.542555094000022],
                [18.617610932000048, 3.550329209000097],
                [18.617828370000097, 3.554758071000094],
                [18.61601066500009, 3.588818073000027],
                [18.615989684, 3.588999033000107],
                [18.610874177000142, 3.632519960000082],
                [18.608026504000179, 3.648454904000118],
                [18.606109619000108, 3.659189939999976],
                [18.60490608200007, 3.66364336100014],
                [18.601640702000111, 3.675731897999981],
                [18.594663620000119, 3.690417051000111],
                [18.59325027400007, 3.696121931000164],
                [18.591363907000016, 3.703744888000188],
                [18.590032578000091, 3.718032122000182],
                [18.589323044000025, 3.728312492000043],
                [18.58900260900009, 3.732963800000164],
                [18.589000702000192, 3.733000041000025],
                [18.589551925000023, 3.733000041000025],
                [18.588367462000178, 3.738988639000127],
                [18.587673187, 3.74153447100008],
                [18.587446214000181, 3.742363930000067],
                [18.586999893000097, 3.743999959000178],
                [18.586999893000097, 3.747639417000187],
                [18.586999893000097, 3.750999927000066],
                [18.587083817000121, 3.75139522600017],
                [18.589874268000131, 3.764649629000019],
                [18.597778321000192, 3.803009987000166],
                [18.597999572999981, 3.807184220000124],
                [18.598388672000112, 3.814524889000097],
                [18.595228196000107, 3.840745924999965],
                [18.596277238000027, 3.854563952000092],
                [18.596471787000098, 3.855565072000161],
                [18.600482940000063, 3.876234055000168],
                [18.600187301000176, 3.885442019000152],
                [18.600652695000122, 3.888592720000133],
                [18.601999283, 3.895999909000068],
                [18.607999802000109, 3.908999921000145],
                [18.610267639000085, 3.911834956000121],
                [18.614805221000154, 3.917506694999986],
                [18.620101928999986, 3.923798084000168],
                [18.633810044000086, 3.936946868000121],
                [18.636051179000162, 3.939096689000053],
                [18.64259719800009, 3.951580048000096],
                [18.647569656000087, 3.961745025000141],
                [18.650156020000054, 3.970619678000162],
                [18.651981353, 3.977925538000136],
                [18.652000427000189, 3.977999926000109],
                [18.652000427000189, 3.99075675000006],
                [18.651601790000143, 4.009427072000165],
                [18.650331498000185, 4.028460026],
                [18.649299622000058, 4.043941974000063],
                [18.648372649, 4.05278205799999],
                [18.647829056000091, 4.057974816000126],
                [18.648849486000131, 4.076396942000088],
                [18.645532608000167, 4.091796874000124],
                [18.640829086000167, 4.10903215400009],
                [18.631959915000039, 4.13176012100007],
                [18.626619339000115, 4.14161920600003],
                [18.620759965000104, 4.159996032000151],
                [18.620555878000175, 4.160579205000033],
                [18.619661331000145, 4.163123609000024],
                [18.618419648000156, 4.166656017000094],
                [18.618148803000054, 4.166654109000092],
                [18.618000031000179, 4.166999818000022],
                [18.601537704000123, 4.193481922000046],
                [18.594999313000073, 4.204473019000034],
                [18.590961456000059, 4.212441922000096],
                [18.583610534000059, 4.226952077000135],
                [18.577569961000052, 4.238189221000141],
                [18.574680328000113, 4.24226904000011],
                [18.571079254000097, 4.247351171000048],
                [18.564327240000068, 4.262036801000136],
                [18.554100036000136, 4.282563211000081],
                [18.55100059599999, 4.289000035000129],
                [18.546188355000027, 4.301508904000116],
                [18.546167374000163, 4.301564694000149],
                [18.54599952699999, 4.302000046000103],
                [18.54599952699999, 4.308000088000028],
                [18.546119690000126, 4.308321953000132],
                [18.548999787000128, 4.31599998500019],
                [18.553462981999985, 4.320834159000128],
                [18.561113358000057, 4.328771114],
                [18.568513870000061, 4.335398197000075],
                [18.578201293000063, 4.344095231000097],
                [18.578815460000158, 4.344387531000109],
                [18.599000930000159, 4.35400009100016],
                [18.603624344000082, 4.356312274000061],
                [18.606779098000175, 4.357643127000131],
                [18.611112595000179, 4.356628894000153],
                [18.612733841000022, 4.356089116000078],
                [18.616910935000135, 4.351957798000058],
                [18.617839813000046, 4.351044177],
                [18.625890733000062, 4.347417832000076],
                [18.637359620000154, 4.346580028000062],
                [18.648349763000112, 4.348958969000137],
                [18.663930892999986, 4.35159778600007],
                [18.667509078000023, 4.352031231000183],
                [18.667419434000067, 4.352284908000115],
                [18.674489975000029, 4.352874280000037],
                [18.676076890000161, 4.353065967000134],
                [18.698888779000185, 4.370223999000132],
                [18.713857650000136, 4.375231743000086],
                [18.733100891999982, 4.386678218999975],
                [18.73333358800005, 4.383333205000156],
                [18.738027573000068, 4.373830795000174],
                [18.740465164, 4.368898391000073],
                [18.748630524000077, 4.374979974000098],
                [18.753000259000032, 4.379638672000112],
                [18.753000259000032, 4.379000187000145],
                [18.753417969000168, 4.379334450000158],
                [18.753419876000066, 4.379158020000148],
                [18.762771607000104, 4.386816501000055],
                [18.763000488000159, 4.387000084000079],
                [18.764999389000081, 4.389999867000029],
                [18.769298553000169, 4.393820762000075],
                [18.774000168000043, 4.397999763000087],
                [18.775007248000065, 4.401538849000133],
                [18.780139923000092, 4.41958522900012],
                [18.790231704000121, 4.422071458000062],
                [18.794000625000137, 4.422999859000186],
                [18.799999237000122, 4.439000129000135],
                [18.801357270000153, 4.441715718000069],
                [18.805902481000089, 4.450804710000114],
                [18.811000824000132, 4.460999965000042],
                [18.815103532000137, 4.480699063000145],
                [18.816013337000186, 4.485249043000181],
                [18.81755828900009, 4.494465828000102],
                [18.818916321000017, 4.509588719000021],
                [18.819068908000133, 4.511276246000136],
                [18.819252014000028, 4.51211595500007],
                [18.820833204999985, 4.51935005200005],
                [18.822591782000131, 4.531794072000082],
                [18.831809997000164, 4.558327198000086],
                [18.839759827000137, 4.570812225000054],
                [18.844802856000058, 4.576797008000085],
                [18.856149673000175, 4.590260029000149],
                [18.874650955000106, 4.602355958000089],
                [18.881113051999989, 4.610149384000067],
                [18.884439468000096, 4.614161969000179],
                [18.88987731800006, 4.624557019000122],
                [18.889919280000186, 4.624853610000059],
                [18.890096664000112, 4.626096249000113],
                [18.890211106000152, 4.626894474000039],
                [18.891000748000124, 4.633999824000114],
                [18.894838333000109, 4.639757156000087],
                [18.89500045800014, 4.639999867000142],
                [18.898973465000154, 4.642483234],
                [18.902591706000123, 4.64512777300007],
                [18.905958176000127, 4.655972003999978],
                [18.906003951000059, 4.655986309000014],
                [18.918319700999973, 4.659737110000151],
                [18.931020736000107, 4.66558027300016],
                [18.932043076000127, 4.666050911000184],
                [18.933219909000059, 4.667423248000148],
                [18.937740326000039, 4.672758103000035],
                [18.940458298000067, 4.679280281000047],
                [18.947452546000022, 4.696071148000101],
                [18.985300064000171, 4.733613014000014],
                [18.991937638000138, 4.737083434000112],
                [18.994218827000168, 4.738276005000103],
                [18.995838166000112, 4.739634036000098],
                [19.003347398000074, 4.74593210200004],
                [19.008996963000072, 4.752498149000189],
                [19.010000229000127, 4.752999783000121],
                [19.013999939000087, 4.768000126000061],
                [19.015663147000055, 4.774317742000164],
                [19.016899108000075, 4.778656007],
                [19.019147873000065, 4.78654909200003],
                [19.022560120000037, 4.791402817000062],
                [19.034658432000072, 4.801150799000141],
                [19.043254853, 4.820543765000139],
                [19.059400559000096, 4.843897820000052],
                [19.064329148000127, 4.861883165000052],
                [19.068130493000183, 4.877790928000081],
                [19.07121276800018, 4.884068489000129],
                [19.076999664000084, 4.894999981000126],
                [19.079547881000167, 4.897830487000022],
                [19.086000443000103, 4.905000210000026],
                [19.096000672000173, 4.918000222000046],
                [19.097999573000095, 4.921999932000063],
                [19.105899811000086, 4.928912641000125],
                [19.105998993000071, 4.928999902000157],
                [19.106069564000165, 4.928999902000157],
                [19.112773895000032, 4.932849408000152],
                [19.112819672000171, 4.932879449000041],
                [19.113000869000189, 4.933000087000096],
                [19.115800857000181, 4.933973790000096],
                [19.115879059000122, 4.933891774000074],
                [19.136125565000043, 4.940607071000159],
                [19.148284912000065, 4.941836834000185],
                [19.151348113000097, 4.941742420000026],
                [19.160678863000101, 4.94145488800001],
                [19.173997879000069, 4.940159797999968],
                [19.187540054000124, 4.939325809000025],
                [19.202163697000117, 4.94213056500007],
                [19.202442169999983, 4.942183972000123],
                [19.212039947000164, 4.949840069000061],
                [19.217468261000079, 4.962302208000097],
                [19.226490021000131, 4.988370897000095],
                [19.228242874000102, 4.99203920400015],
                [19.230998993000185, 4.997000218000096],
                [19.233999253000093, 4.999999999000067],
                [19.236871720000124, 5.00287294400016],
                [19.241292953000141, 5.006875039000079],
                [19.256160737000187, 5.01479482700006],
                [19.261487961000057, 5.015783787000032],
                [19.262582778000137, 5.015986919000113],
                [19.269899368000097, 5.020405770000082],
                [19.284553527000185, 5.026480198000172],
                [19.297807693000095, 5.036462783000047],
                [19.303461076000076, 5.040963649000105],
                [19.309459687000128, 5.045741082000063],
                [19.322744369000134, 5.054259777000141],
                [19.323999406000155, 5.055000306000011],
                [19.328651429000047, 5.056993962000149],
                [19.337831497000025, 5.060647011000128],
                [19.355537414000139, 5.071021557000051],
                [19.358869552000158, 5.072974206000026],
                [19.361745835000022, 5.075747014000115],
                [19.368001938000077, 5.081776141000091],
                [19.378000259000032, 5.098641872000144],
                [19.391918182999973, 5.112765789000036],
                [19.403354644000046, 5.121106624000106],
                [19.403867721000154, 5.121260166000184],
                [19.412973405000059, 5.123713016000124],
                [19.416728974000023, 5.12549162],
                [19.423000335000097, 5.128000260000079],
                [19.430002213000023, 5.130020142000092],
                [19.430658341000083, 5.129940511000143],
                [19.440719605000027, 5.128715038000109],
                [19.44692039600011, 5.128520966],
                [19.461862564000114, 5.124699115000055],
                [19.467046739000125, 5.123835564000103],
                [19.475189208000188, 5.12247896100007],
                [19.489650725999979, 5.122334004000038],
                [19.507543564000059, 5.125203134000117],
                [19.508834839000031, 5.125990391000187],
                [19.518981933000077, 5.132176876000187],
                [19.52665329000007, 5.138528823],
                [19.527999878000116, 5.13899994000019],
                [19.548000335000154, 5.144999981000069],
                [19.549079895000148, 5.145153999000172],
                [19.554939270000091, 5.145740032999981],
                [19.561012267000137, 5.144906997000021],
                [19.565999986000065, 5.144000054000173],
                [19.571861268000077, 5.14133596500011],
                [19.57699966399997, 5.13899994000019],
                [19.591999054000098, 5.133999826000036],
                [19.601999283000168, 5.131999970000095],
                [19.613000870000178, 5.128000260000079],
                [19.63500022900007, 5.128016473000116],
                [19.635162354000045, 5.128017426000099],
                [19.681999206000057, 5.132999897000161],
                [19.695991517000039, 5.132999897000161],
                [19.706506730000115, 5.127924919000179],
                [19.725000381000029, 5.118999958000075],
                [19.740999221000038, 5.1149997710001],
                [19.760999681000044, 5.106999873000063],
                [19.775566101000038, 5.102144241000133],
                [19.775560377999966, 5.102827073000128],
                [19.775999069000136, 5.102777005000064],
                [19.792789460000165, 5.100852967000037],
                [19.801290511000047, 5.099197865000065],
                [19.81158828700012, 5.096959115000118],
                [19.815999985000133, 5.096000195000158],
                [19.825000762000116, 5.09299993500008],
                [19.828109741000048, 5.090380669000069],
                [19.840581895000128, 5.079878329999986],
                [19.843999863000136, 5.077000142000088],
                [19.847999573000095, 5.07399988200018],
                [19.851272583000025, 5.069324017999975],
                [19.854581833000111, 5.063941956000178],
                [19.854740143000072, 5.063684941000133],
                [19.863380433000145, 5.036571980000076],
                [19.868806840000161, 5.025222301000099],
                [19.86941528400007, 5.02394819400007],
                [19.872634887000061, 5.018910885000139],
                [19.873813630000029, 5.017066956000065],
                [19.876150132000078, 5.014845372000082],
                [19.890420913000185, 5.001276969000173],
                [19.892179490000103, 5.000000954000029],
                [19.894166946000041, 4.998555184000111],
                [19.894437790999973, 4.998358727000152],
                [19.901000976000091, 4.993999958000188],
                [19.914999007, 4.980000019999977],
                [19.925340652000102, 4.976122380000163],
                [19.92717552300013, 4.975434303000156],
                [19.930999757, 4.973999978000052],
                [19.935356141000057, 4.97312879499998],
                [19.941074371000127, 4.972093105999988],
                [19.949340821000192, 4.972138881000149],
                [19.966756820000114, 4.977067947000137],
                [19.971799851000014, 4.978919984000186],
                [19.971826553000142, 4.978930474000151],
                [19.9720001230001, 4.979000093000138],
                [19.988000870000178, 4.977000237000027],
                [20.000000000000171, 4.973000049000177],
                [20.001966477000053, 4.972017288000131],
                [20.00419425900003, 4.971285821000095],
                [20.01802444499998, 4.967494011000042],
                [20.024000167000054, 4.966000081000118],
                [20.035139084000036, 4.959145547000162],
                [20.036848069000143, 4.958093166000026],
                [20.03698539800007, 4.957981110000162],
                [20.051374435000184, 4.946218014000124],
                [20.068243026000118, 4.924212933000092],
                [20.084140778000119, 4.913018226000133],
                [20.092199326000014, 4.90638589800011],
                [20.09634018, 4.905257225000071],
                [20.108085632000154, 4.897031784000092],
                [20.112379074000103, 4.895686149000028],
                [20.118200302000048, 4.893861771000104],
                [20.119447708000052, 4.89400005400006],
                [20.122999191000133, 4.89400005400006],
                [20.129062653, 4.891574861000038],
                [20.13750839100004, 4.887979030000167],
                [20.156845092000083, 4.875878811000064],
                [20.162611008000169, 4.870155812000178],
                [20.169807435000109, 4.853160858000024],
                [20.169986725000172, 4.852986335000026],
                [20.172042847000114, 4.850980281000147],
                [20.196329117000175, 4.827288151000062],
                [20.20301055800013, 4.823178769000037],
                [20.213808059000087, 4.819320203000189],
                [20.239131928000063, 4.804029941000181],
                [20.255016327000135, 4.793059827000036],
                [20.258432388000074, 4.790375234000067],
                [20.271999359000063, 4.780000210000139],
                [20.277183534000073, 4.777120590000095],
                [20.278476715000068, 4.776402474000179],
                [20.281000138000024, 4.775000096000156],
                [20.292575835000093, 4.774142743000141],
                [20.308000565000043, 4.772999765000066],
                [20.31315422, 4.770422936000159],
                [20.318000794000113, 4.768000126000061],
                [20.320257187000038, 4.766495228000053],
                [20.33600807300013, 4.756009579000136],
                [20.336421967000092, 4.755712510000137],
                [20.343000413000084, 4.750999929000045],
                [20.344900132000191, 4.749226093000175],
                [20.348487855000087, 4.745878696000091],
                [20.351640701000179, 4.742935658000079],
                [20.357860564000134, 4.736951828000088],
                [20.360860825000145, 4.732707977000189],
                [20.36386299100019, 4.728463172999966],
                [20.373130798000091, 4.709174156000131],
                [20.380289078000089, 4.699893952000025],
                [20.384450913000137, 4.694499016000066],
                [20.405012131000149, 4.677013397000053],
                [20.414113998000175, 4.666444302000173],
                [20.430320740000127, 4.647534848000021],
                [20.43989181600017, 4.639853478000021],
                [20.440876007000156, 4.639063359000147],
                [20.445850372000052, 4.634947301000068],
                [20.447000504000187, 4.633999824000114],
                [20.448989867000023, 4.631678582000063],
                [20.451986313000134, 4.628181934000111],
                [20.452999115000068, 4.626999856000055],
                [20.45800018400007, 4.614999771000043],
                [20.458459853000022, 4.613623142000108],
                [20.46100044200017, 4.605999947000043],
                [20.46100044200017, 4.601054192000163],
                [20.46100044200017, 4.598999976000016],
                [20.451999665000187, 4.573999882000067],
                [20.450719833000164, 4.567599297000129],
                [20.449756623000042, 4.564067842000043],
                [20.450000762000059, 4.555665971000053],
                [20.450311661000114, 4.544967174000078],
                [20.451316834000068, 4.538419246999979],
                [20.452856063000183, 4.530716896000172],
                [20.452508927000054, 4.530644893000158],
                [20.452999115000068, 4.527451516000156],
                [20.45338249299999, 4.524953842000059],
                [20.457340241000111, 4.512084008000159],
                [20.461120605000076, 4.504582881999966],
                [20.461969375000137, 4.502899170000148],
                [20.462894439000138, 4.501883508000105],
                [20.466350556000179, 4.498085022000055],
                [20.477882386000033, 4.485478879000027],
                [20.48379898100012, 4.483067514000084],
                [20.489601135000044, 4.480701923000027],
                [20.500610351000034, 4.480524064000122],
                [20.508668899000156, 4.476206780000155],
                [20.522512435000181, 4.457377910000105],
                [20.527824401000032, 4.448655129000031],
                [20.531093597000165, 4.444666862000133],
                [20.532899856000142, 4.44246482900013],
                [20.537628173000087, 4.440457344000038],
                [20.53933334300018, 4.439733028000148],
                [20.555168152000192, 4.437736035000171],
                [20.589696883000101, 4.411194801000079],
                [20.604055405999986, 4.404469491000157],
                [20.606241226, 4.403446198000154],
                [20.60970306300004, 4.402471065000043],
                [20.612672805000102, 4.401634216000161],
                [20.618219374999967, 4.40199995],
                [20.624000550000062, 4.40199995],
                [20.631000518000121, 4.405000210000139],
                [20.631231309000157, 4.405404567000062],
                [20.637485505000143, 4.416366101000051],
                [20.645259856999985, 4.418617249000135],
                [20.656015396000043, 4.421731472000147],
                [20.663656234000086, 4.412417413000185],
                [20.664230346000124, 4.412459850000062],
                [20.667802810000126, 4.413630010000077],
                [20.670999528000152, 4.414999962000081],
                [20.670999528000152, 4.414000035000186],
                [20.698999405000052, 4.417000293000115],
                [20.727214814000035, 4.420891284999982],
                [20.727998734000039, 4.421010971000158],
                [20.728647232000071, 4.421010018000118],
                [20.735067367000056, 4.420608044000119],
                [20.746559143000184, 4.41513586100001],
                [20.757347107000157, 4.413341999000124],
                [20.783269881000081, 4.412536144000114],
                [20.794500350000135, 4.413967133000142],
                [20.809391022000113, 4.419094086000087],
                [20.810075760000132, 4.419330120000041],
                [20.813566208000054, 4.421140194000088],
                [20.820373535000101, 4.42467022000011],
                [20.825122833000137, 4.428435803000184],
                [20.825990678000096, 4.429017544000089],
                [20.849882125000079, 4.440925599000138],
                [20.85000801100017, 4.440988064000067],
                [20.856983185000161, 4.445547103000081],
                [20.865467071000126, 4.445813178000151],
                [20.884769440000184, 4.437611104000041],
                [20.896602632000111, 4.438000202000126],
                [20.900821687000075, 4.438138961000107],
                [20.906242371000076, 4.4385242460001],
                [20.910999299000025, 4.439000129000135],
                [20.923999785999968, 4.434999944000026],
                [20.928289414000176, 4.432851315000164],
                [20.934110642000064, 4.430225850000056],
                [20.946737289000055, 4.428206921000083],
                [20.949163437000038, 4.428720952000106],
                [20.953382492000173, 4.429615021000075],
                [20.968206405000046, 4.428211213000111],
                [20.969776153000112, 4.428026200000147],
                [20.969999314000177, 4.427999973],
                [20.971309662000067, 4.42734527500005],
                [20.978399277000165, 4.424195767000015],
                [20.98313522400008, 4.422973155000136],
                [20.988048553999988, 4.421704769000144],
                [21.008516311000051, 4.407977105000157],
                [21.016639709000174, 4.404944420000106],
                [21.024370194000028, 4.40205812500011],
                [21.038129806000029, 4.401195050000069],
                [21.041376113000126, 4.401374818000022],
                [21.047000885000159, 4.40199995],
                [21.04757118100008, 4.401918411000111],
                [21.053998947000082, 4.401000023000165],
                [21.053998947000082, 4.40059947900005],
                [21.053998947000082, 4.400000096000099],
                [21.054189681000025, 4.399925708000183],
                [21.054191589000027, 4.399647237000124],
                [21.056560517000094, 4.398762703000159],
                [21.071882248000122, 4.393046380000101],
                [21.072004318000097, 4.393022062000057],
                [21.075000764000038, 4.392000199000165],
                [21.081161500000121, 4.38768720700017],
                [21.084999085000163, 4.384999753000045],
                [21.085815430000082, 4.383367539000176],
                [21.087291717000141, 4.381368161000125],
                [21.101095199000042, 4.370374203000154],
                [21.107330322000166, 4.358201027000121],
                [21.111276626000176, 4.348836421000158],
                [21.114000321000049, 4.342999935000023],
                [21.118892670000093, 4.33614921700007],
                [21.123031617000152, 4.334185600000183],
                [21.13899993800004, 4.327000141000156],
                [21.152000427000189, 4.321000099000173],
                [21.156446458000119, 4.317765713000085],
                [21.16300010700013, 4.313000202000069],
                [21.166412353000169, 4.310346603000141],
                [21.170642853000118, 4.307054996000034],
                [21.172000884000113, 4.305999756000119],
                [21.174999237000122, 4.302000046000103],
                [21.178159713000127, 4.301548481000111],
                [21.18199920800015, 4.301000119000037],
                [21.185974121000129, 4.30258989400005],
                [21.186519622000048, 4.302765847000103],
                [21.187648773000149, 4.303129674000104],
                [21.19150352500003, 4.303900718000136],
                [21.191999436000117, 4.303999900000179],
                [21.196727753000118, 4.300216675000058],
                [21.201999665000187, 4.296000003000074],
                [21.205999375000033, 4.289999962000024],
                [21.209030152000082, 4.288181782000038],
                [21.211269379000157, 4.287000179000074],
                [21.218639375000123, 4.287000179000074],
                [21.218999862000032, 4.287000179000074],
                [21.224685668000063, 4.288895607000029],
                [21.224685668000063, 4.288419723000061],
                [21.228225709000185, 4.289619924000022],
                [21.232822419000172, 4.292678832000149],
                [21.236919404000162, 4.295405865000078],
                [21.241241453999976, 4.309411049000175],
                [21.241899490000094, 4.311540126000011],
                [21.243537904000107, 4.314905166000074],
                [21.250999451000098, 4.32899999700004],
                [21.256998062000036, 4.332005978000154],
                [21.261386871000184, 4.33233738000007],
                [21.269569396000122, 4.332825184000058],
                [21.26988029600011, 4.332990646000098],
                [21.272260665000147, 4.334256173000085],
                [21.278999328, 4.337999821000039],
                [21.281999589000179, 4.337999821000039],
                [21.282169342000088, 4.337936878000051],
                [21.289060592000112, 4.334508895000056],
                [21.300121308000087, 4.320512772000086],
                [21.306329727000104, 4.315701961000059],
                [21.31124305700007, 4.3130030640001],
                [21.312982557999987, 4.312029362000033],
                [21.313014984, 4.312010766000071],
                [21.31504821700014, 4.309271812000134],
                [21.315195084000095, 4.309506416000147],
                [21.318000793000181, 4.305999756000119],
                [21.324092866000171, 4.300583840000172],
                [21.327493668000045, 4.297826766000128],
                [21.334390641000027, 4.293938161000142],
                [21.335998534000112, 4.293529987000113],
                [21.351369857000122, 4.289626122000186],
                [21.377330781000182, 4.277523995000081],
                [21.399131775000114, 4.272540092999975],
                [21.417970658, 4.264091015000076],
                [21.424850463000041, 4.262505056000066],
                [21.427839280000114, 4.260674],
                [21.434259414000053, 4.260927200000083],
                [21.45097732500011, 4.265074254000183],
                [21.451000213000043, 4.264999868000075],
                [21.459999085000163, 4.269000053000184],
                [21.466169357000183, 4.269000053000184],
                [21.468631744000049, 4.268878937000125],
                [21.478149415000189, 4.264777185000185],
                [21.48240852400005, 4.262941837000028],
                [21.495008468000037, 4.258025169000177],
                [21.504680633000191, 4.255916594999974],
                [21.540929794000135, 4.248187065000138],
                [21.551940918000128, 4.247075080000059],
                [21.564785005000147, 4.247347833000106],
                [21.57691574100005, 4.254066945000091],
                [21.583549499000185, 4.259153844000082],
                [21.596807480000166, 4.272321224000109],
                [21.602542877000189, 4.274216652000064],
                [21.607000351000011, 4.276000023000108],
                [21.62400055, 4.284999847000108],
                [21.629999161000114, 4.289999962000024],
                [21.633644104000155, 4.29394912700019],
                [21.642000198, 4.302999973000112],
                [21.651000977000024, 4.309000015000095],
                [21.659999847000108, 4.309000015000095],
                [21.664360046000184, 4.306819915000176],
                [21.66779136700012, 4.304789067],
                [21.676767350000034, 4.29561090499999],
                [21.682960510000157, 4.29563379300015],
                [21.692350387000033, 4.299809934000052],
                [21.70404625000009, 4.297544003000098],
                [21.721006393000096, 4.302980423000122],
                [21.721086502000105, 4.303006171000106],
                [21.727575303000094, 4.303469657999983],
                [21.73452949599999, 4.303860188000158],
                [21.736381531000063, 4.303089142000147],
                [21.744186401000093, 4.299837113000081],
                [21.744400024000129, 4.299747944000103],
                [21.751991272000112, 4.293097019000186],
                [21.759136200000114, 4.28184080200009],
                [21.76098442100016, 4.277242184000102],
                [21.771780014000171, 4.270139218000054],
                [21.794025422000061, 4.27113294500009],
                [21.803152085000022, 4.270167827000023],
                [21.80458068900009, 4.270038129000113],
                [21.816755295000064, 4.261767864000149],
                [21.830064773000117, 4.258817196000109],
                [21.848911286000089, 4.245065213000089],
                [21.849008561000062, 4.245017529000052],
                [21.859243393000099, 4.240032195000026],
                [21.871860503999983, 4.240070820000142],
                [21.874610901000096, 4.241001128000164],
                [21.874862670000084, 4.24095392300012],
                [21.874999999000181, 4.240999698000053],
                [21.912000655000043, 4.233979226000031],
                [21.912361145000034, 4.23395919799998],
                [21.935400009000091, 4.232670785000039],
                [21.954683303000081, 4.223979950000114],
                [21.95949935900012, 4.223763943000108],
                [21.963582992000056, 4.224801540999977],
                [21.96501731900014, 4.225052356000049],
                [21.97239494300004, 4.230243206000182],
                [21.982162475000109, 4.236952781000127],
                [21.984233857000106, 4.237609387000134],
                [21.993000031000122, 4.240000249000048],
                [22.003192901000091, 4.239271642000063],
                [22.006999969000105, 4.238999844000148],
                [22.010267257000123, 4.237366200000167],
                [22.011049271000161, 4.237038135000091],
                [22.017950057, 4.230381966000152],
                [22.027179717000024, 4.224350930000128],
                [22.031276702000184, 4.221673965000036],
                [22.035570145000065, 4.221634866000102],
                [22.043865204000099, 4.221557616999974],
                [22.049999237000065, 4.221000196000148],
                [22.055683136000027, 4.220368386000075],
                [22.058799744000169, 4.220022678000078],
                [22.06890106200018, 4.214875221000057],
                [22.072000503000027, 4.214791298000137],
                [22.083999635000055, 4.214000225000177],
                [22.086681366000164, 4.213512420000029],
                [22.091949462000116, 4.212554455000088],
                [22.09499931300013, 4.211999894000087],
                [22.11872673, 4.196162224000091],
                [22.118991852000079, 4.195984841000097],
                [22.127000810000027, 4.191999912000142],
                [22.149000167999986, 4.168000222000046],
                [22.153480529999968, 4.164266110000142],
                [22.155000687000097, 4.163000108],
                [22.161983489000022, 4.156016351000119],
                [22.163967132000096, 4.154032229999984],
                [22.16400527900015, 4.154011727000182],
                [22.173400879000042, 4.149098874],
                [22.18325805600017, 4.144603729000153],
                [22.187000274000184, 4.143000126000061],
                [22.19030761700003, 4.140594960000044],
                [22.198205949000169, 4.135124207000104],
                [22.219089508000138, 4.126432897000029],
                [22.240188599000192, 4.124415875000125],
                [22.266351700000143, 4.11435890100006],
                [22.281259536000107, 4.112094880000029],
                [22.306015016000117, 4.115151883000124],
                [22.30987930200007, 4.114927768000143],
                [22.316341400000056, 4.114550113000064],
                [22.329000473000065, 4.113999844000091],
                [22.340000153000176, 4.113999844000091],
                [22.358999251000057, 4.118000032000111],
                [22.359218597000108, 4.118000032000111],
                [22.35942840600012, 4.118048192],
                [22.387401582000052, 4.118349076000072],
                [22.388931274000129, 4.119158269000138],
                [22.392000198000062, 4.12100029000004],
                [22.394809722000161, 4.12100029000004],
                [22.398170472000061, 4.121139049000078],
                [22.399158478000118, 4.121526717000165],
                [22.399890900000116, 4.121813773000099],
                [22.409393310000155, 4.125539780000111],
                [22.419635773000039, 4.125440121000111],
                [22.423433302999968, 4.125240326000039],
                [22.427408219000085, 4.125030994000099],
                [22.427999497000144, 4.125],
                [22.444999696000139, 4.124000073000161],
                [22.444896699000026, 4.124407769000129],
                [22.445240020000142, 4.124346733999971],
                [22.455881119000139, 4.127968312000121],
                [22.469882965000124, 4.143411160000142],
                [22.485218048000149, 4.152884961000041],
                [22.493690490000063, 4.159579755000095],
                [22.501464844000111, 4.17041778600003],
                [22.504219056000068, 4.171110153000029],
                [22.507429124000112, 4.176635742000087],
                [22.5074729910001, 4.176710128000025],
                [22.520450592000145, 4.189569951000124],
                [22.531677245000083, 4.195580005000181],
                [22.531679153000084, 4.196499825000046],
                [22.540603638000164, 4.203426838000098],
                [22.550441742000032, 4.21541976899999],
                [22.550590514000078, 4.217591764000019],
                [22.550930023000035, 4.218080044000146],
                [22.551313400000026, 4.219314098999973],
                [22.556098937000172, 4.234690190000094],
                [22.554689408000115, 4.23858976300005],
                [22.546140671000046, 4.24819994],
                [22.545200349000083, 4.251420021000115],
                [22.544450759000028, 4.261539936000133],
                [22.546209336000175, 4.270759105000082],
                [22.557973862000097, 4.294488430000115],
                [22.56218910199999, 4.298549175000119],
                [22.564920425000139, 4.305922033000172],
                [22.567714691000049, 4.316636561999985],
                [22.567729950000171, 4.316694260000134],
                [22.56925010599997, 4.320869923000089],
                [22.5701179510001, 4.322549344000038],
                [22.571187974000111, 4.324621677000152],
                [22.574960708000162, 4.328730107000069],
                [22.58069038400015, 4.330820084000095],
                [22.58503913800007, 4.334509850000188],
                [22.598329544000137, 4.341446877000124],
                [22.608629226000062, 4.348139763000063],
                [22.619409561, 4.350470066000014],
                [22.621919632000129, 4.358069897000121],
                [22.623500823000143, 4.372117042000184],
                [22.622987747000138, 4.372932435000053],
                [22.622951507000153, 4.37299060800018],
                [22.622770309000089, 4.373277187000156],
                [22.620019912000146, 4.377645969000184],
                [22.614826202000131, 4.379425527000137],
                [22.60652160699999, 4.38226986],
                [22.60242080800009, 4.382794857000022],
                [22.601680756000121, 4.382890225000097],
                [22.58996963400017, 4.389308930000141],
                [22.588479997000093, 4.392909051000061],
                [22.585590362000175, 4.399888993000047],
                [22.58588409500004, 4.401491166000028],
                [22.586727142000086, 4.406105995000019],
                [22.593265534000182, 4.416512967000131],
                [22.595415115000151, 4.419936180000093],
                [22.597927094000056, 4.424543859000039],
                [22.598344802000156, 4.445724011000152],
                [22.597345352000048, 4.456489563000048],
                [22.597167969000054, 4.458399773000167],
                [22.596700667000107, 4.46344709400006],
                [22.598144532000106, 4.468219280000028],
                [22.599210740000046, 4.47173977000017],
                [22.602640153000038, 4.474967957000047],
                [22.609750748000124, 4.475440026000115],
                [22.614330291000044, 4.480519772000093],
                [22.623262405000105, 4.484450818000141],
                [22.627159119000112, 4.484691143000021],
                [22.627458573000013, 4.484638692000033],
                [22.627920150000136, 4.484558106000122],
                [22.648040771000183, 4.481052877000082],
                [22.648038865000046, 4.481570244000181],
                [22.648040771000183, 4.481974125000079],
                [22.651010512000141, 4.481701375000114],
                [22.662729264000063, 4.48062515200013],
                [22.6691608430001, 4.472812176000105],
                [22.672061921000136, 4.467020989000105],
                [22.672157288000164, 4.466831208000144],
                [22.680194855000025, 4.461784839000131],
                [22.681030274000136, 4.461738587000184],
                [22.688219071000105, 4.461340905000157],
                [22.692119597999977, 4.464803218000043],
                [22.698980332000133, 4.471720219000076],
                [22.707199097000057, 4.491306782000038],
                [22.706787108000185, 4.505039693000015],
                [22.706468583000174, 4.515705108000077],
                [22.707252503, 4.517241002000162],
                [22.710580826000182, 4.523770809000155],
                [22.718404770000063, 4.534279822999963],
                [22.721563338000067, 4.538524150000058],
                [22.727970123000034, 4.544980050000049],
                [22.730266571000129, 4.546138764000034],
                [22.730751038000164, 4.547539712000116],
                [22.730760574000101, 4.547567844000128],
                [22.73286438000008, 4.553661347000116],
                [22.733688354000037, 4.556044101000055],
                [22.734104156000171, 4.577683924999974],
                [22.734077454000044, 4.59172392],
                [22.734272004000161, 4.594782353000028],
                [22.735689163000131, 4.596829892000073],
                [22.738979339000025, 4.619180202000166],
                [22.741144181000095, 4.624708653000084],
                [22.742595673000096, 4.62843275099999],
                [22.758520127000168, 4.646790028000055],
                [22.762409210000158, 4.650710107000123],
                [22.769039153000108, 4.66821813700011],
                [22.777700424000045, 4.681429864000165],
                [22.778421402000049, 4.682507992000183],
                [22.781379699000126, 4.69033908900002],
                [22.782037735000188, 4.69486379600005],
                [22.783353806000093, 4.704448223000043],
                [22.790510177000101, 4.714529992000109],
                [22.801969528000086, 4.722605227999964],
                [22.809080124000104, 4.725150108000037],
                [22.821929931000057, 4.724255086000085],
                [22.825780869000027, 4.722531795],
                [22.828590393000127, 4.721275806000108],
                [22.835945130000027, 4.714153767000141],
                [22.845605851000073, 4.701744081000072],
                [22.857536315000061, 4.701303960000189],
                [22.865789414000176, 4.707769871000039],
                [22.870134353000083, 4.713068962000023],
                [22.870887756000172, 4.714706421000017],
                [22.874029160000191, 4.721540928000025],
                [22.874477386000137, 4.722514152000088],
                [22.874256133000074, 4.724379539000154],
                [22.875053406, 4.734401226000045],
                [22.875150680000104, 4.735620976000064],
                [22.876560211000083, 4.739140511000073],
                [22.888200759000085, 4.746009826999966],
                [22.900810242000034, 4.753407954000068],
                [22.902639388000068, 4.757092952000164],
                [22.902301787999988, 4.758601190000036],
                [22.901250838000124, 4.763306141000044],
                [22.888612748000185, 4.77502107700019],
                [22.887678147000031, 4.784455777000119],
                [22.892696380000189, 4.802189827000177],
                [22.895212173000061, 4.807028772000024],
                [22.897815705000028, 4.809491634999972],
                [22.906669617000034, 4.8178658490001],
                [22.911481858000172, 4.819255830000088],
                [22.922500611000146, 4.81812620200003],
                [22.935359954000035, 4.814469815000109],
                [22.941320419000192, 4.815398216000119],
                [22.946237564000114, 4.820174218000034],
                [22.946275711000169, 4.820210934000045],
                [22.954610824000156, 4.82636022500003],
                [22.963411331000088, 4.836809635000066],
                [22.967210769000076, 4.837769985000136],
                [22.969606399000043, 4.840680599000052],
                [22.974784851000038, 4.846968651000054],
                [22.979272842000057, 4.842777252000076],
                [22.982362746999968, 4.837180615000023],
                [22.988187790000097, 4.824585437000053],
                [22.996431351000183, 4.810245037000129],
                [23.003664017000119, 4.800805568000101],
                [23.014696122000146, 4.788222790000134],
                [23.03226471, 4.765848160000132],
                [23.04887390100015, 4.757132531000138],
                [23.063077926000062, 4.752264500000138],
                [23.073085785000103, 4.742481232000159],
                [23.079982758000085, 4.735141753000107],
                [23.091724396000131, 4.725012303000085],
                [23.106237411, 4.713838577000161],
                [23.113290787000096, 4.707959652000056],
                [23.113351822000084, 4.707963944000028],
                [23.120201111000029, 4.713217258000043],
                [23.120225907000133, 4.713235854000118],
                [23.122976303000144, 4.715345860000127],
                [23.125249862000089, 4.716372014000115],
                [23.142925263000166, 4.724352837000083],
                [23.142925263000166, 4.723432064000178],
                [23.151409150000177, 4.72942972300001],
                [23.159429551000073, 4.73612022400016],
                [23.17159080600004, 4.738210202000062],
                [23.174499512000011, 4.736639023],
                [23.183067321000067, 4.732010841999966],
                [23.195472718000019, 4.720058919000053],
                [23.206520080000189, 4.696598052000184],
                [23.208360672000026, 4.692226887000118],
                [23.220529557000134, 4.684187890000089],
                [23.225351333000162, 4.681663037000021],
                [23.238220216000059, 4.668332100000043],
                [23.249000549000073, 4.667425155000046],
                [23.258178711000085, 4.666748048000045],
                [23.263000489000035, 4.663762093000173],
                [23.267597198000146, 4.658013821000111],
                [23.271047592000173, 4.650652886000103],
                [23.275201798000182, 4.630400179999981],
                [23.280490874000122, 4.625576019000107],
                [23.288740158000167, 4.628348827000025],
                [23.294933318000119, 4.627435208000179],
                [23.305725097000163, 4.620084762000033],
                [23.319278716999975, 4.606751919000089],
                [23.331216811000104, 4.60216379200017],
                [23.342096328000082, 4.606973171999982],
                [23.34656715300008, 4.608949185000142],
                [23.360322952000047, 4.607923984000138],
                [23.369979858000079, 4.603364946000056],
                [23.371360779000042, 4.602447033000033],
                [23.377107621000164, 4.590943814000127],
                [23.382617950000167, 4.588879109000118],
                [23.393859864000092, 4.589816092999968],
                [23.40807914800007, 4.590062142000022],
                [23.420932770000093, 4.588926792000052],
                [23.427116395000041, 4.591926097000112],
                [23.428268434000074, 4.596950530000129],
                [23.429174424000053, 4.600905895999972],
                [23.425010681000117, 4.632205962],
                [23.425914764000026, 4.644638062000126],
                [23.430955887000152, 4.650166989000127],
                [23.433876037000175, 4.651944637000099],
                [23.449529648000123, 4.661469936000174],
                [23.45731926000019, 4.669529916000045],
                [23.464889527000082, 4.67346000800012],
                [23.48800277800018, 4.672810078000168],
                [23.496549606000144, 4.672569751000083],
                [23.500680924000051, 4.673269749000099],
                [23.526359558000024, 4.691249847000108],
                [23.530229569000028, 4.692636967000112],
                [23.53775215200011, 4.695331098000168],
                [23.53989028899997, 4.696096896000142],
                [23.551355362000152, 4.703475952000133],
                [23.561210633000144, 4.714765071000102],
                [23.57564926200007, 4.728590011000165],
                [23.582069396000179, 4.732971192000036],
                [23.590101242000173, 4.735511780000081],
                [23.608686448000128, 4.73690891300015],
                [23.619928360000188, 4.740602969000065],
                [23.666948318000095, 4.759290220000025],
                [23.671670914, 4.761354447000031],
                [23.677501678000169, 4.763903141000071],
                [23.690340042000173, 4.771510125000134],
                [23.70915031300018, 4.782345773000145],
                [23.720663070000171, 4.784555436000062],
                [23.723604203000093, 4.785120010000071],
                [23.728883743000097, 4.784893990000057],
                [23.743200301000172, 4.781996727000035],
                [23.745866776000128, 4.781456948000027],
                [23.758720398000094, 4.778704167000115],
                [23.770650863000014, 4.779633998],
                [23.780282974000045, 4.783554077000076],
                [23.795436859, 4.799583435000159],
                [23.803543091000165, 4.808157445000063],
                [23.806419373000097, 4.811200142000018],
                [23.815830230000131, 4.819029809000142],
                [23.824661256000184, 4.824214935000157],
                [23.833490372000085, 4.82940006299998],
                [23.834070207000025, 4.829229355000109],
                [23.837390900000059, 4.828249931000073],
                [23.842670440000063, 4.823650838000106],
                [23.84946823100006, 4.813349723000158],
                [23.851171493000095, 4.810767174000091],
                [23.856218337000087, 4.810770989000162],
                [23.870214463000082, 4.816533090000064],
                [23.873050689000138, 4.821218014000067],
                [23.873420716000112, 4.821829796000088],
                [23.883850097000163, 4.830379963000098],
                [23.88466072000017, 4.831044197000153],
                [23.892230987000119, 4.832660197999985],
                [23.907150269000169, 4.828755855000168],
                [23.916107178000061, 4.817022800000075],
                [23.930107117000148, 4.811967851000077],
                [23.949209212000142, 4.809527874000082],
                [23.949842452000155, 4.80944681200009],
                [23.951679230000082, 4.810828210000182],
                [23.955350875000192, 4.822569847000125],
                [23.953731537000124, 4.837528228999986],
                [23.949136734000035, 4.852258206000101],
                [23.950736999000185, 4.866759778000017],
                [23.953487395000025, 4.871825218000083],
                [23.959909439000057, 4.877120971000124],
                [23.964765549000049, 4.87680006100004],
                [23.96537971500004, 4.876759529000083],
                [23.966796874000181, 4.876666070000056],
                [23.96978187600007, 4.871373177000123],
                [23.971160888000099, 4.858025074000125],
                [23.976209640000036, 4.856184960000121],
                [23.984931946000188, 4.861023902000113],
                [23.992046357000049, 4.859877111000174],
                [24.008268357000077, 4.869492054000148],
                [24.009462355999972, 4.86984205300007],
                [24.009819030000187, 4.869946480000067],
                [24.010616302000074, 4.870180131000154],
                [24.013694764000036, 4.871600628000181],
                [24.018169404000105, 4.873670102000176],
                [24.031629562000035, 4.874966623000034],
                [24.038856506000059, 4.877161981000143],
                [24.048721313000158, 4.882689000000141],
                [24.065010071000131, 4.897658826000111],
                [24.074647903000027, 4.903416156000048],
                [24.090251922000107, 4.910327912000128],
                [24.09169006400009, 4.910523892000185],
                [24.092590331000054, 4.91084194199999],
                [24.095981597000161, 4.912039280000158],
                [24.100681305000023, 4.912752628000021],
                [24.105381013, 4.913465977000158],
                [24.116626740000129, 4.912600994000059],
                [24.131374358000187, 4.900017262000176],
                [24.136205672000074, 4.897509099000047],
                [24.15961074900008, 4.896243095000102],
                [24.160337448000121, 4.89642858600007],
                [24.163192749000189, 4.897159100000124],
                [24.168050767000125, 4.901147842000114],
                [24.170806884, 4.906672954000044],
                [24.191089629000032, 4.930727006000041],
                [24.192831040000101, 4.931836128000157],
                [24.196819305000133, 4.935322286000144],
                [24.209001541000191, 4.943323613000189],
                [24.215076446000069, 4.94731426300018],
                [24.218366622000133, 4.949475289000077],
                [24.229295730000104, 4.956654073000038],
                [24.23265266400017, 4.957866670000101],
                [24.235050201000035, 4.958734513000081],
                [24.236320496000076, 4.959544181000069],
                [24.248050690000071, 4.958812237000075],
                [24.253391266000165, 4.95199537200017],
                [24.253229141000133, 4.951351167000041],
                [24.250476837000122, 4.940402031000076],
                [24.252452850000111, 4.93492317200014],
                [24.25280380300012, 4.93490266900011],
                [24.258710860000122, 4.934559823000029],
                [24.275110244000018, 4.936875344000043],
                [24.275798798000153, 4.937203884000041],
                [24.280069351000122, 4.93923902500012],
                [24.281339646000163, 4.941599845000042],
                [24.28243637200012, 4.943645478000121],
                [24.283670424999968, 4.949955940000166],
                [24.282346725000082, 4.958325864000074],
                [24.279848098000173, 4.962171554000122],
                [24.277408599000069, 4.965920925000091],
                [24.274061202000098, 4.971075534000022],
                [24.273275375000026, 4.972286225000119],
                [24.273353575999977, 4.97462511000009],
                [24.27336120699999, 4.974830627000131],
                [24.273475647000055, 4.978270054000063],
                [24.274152756000035, 4.980575084000179],
                [24.27450561500018, 4.980790139000078],
                [24.274879455000018, 4.981017590000022],
                [24.275039673000151, 4.981114864000062],
                [24.275617600000089, 4.981663705000074],
                [24.283130645000085, 4.986013889000105],
                [24.295682908000117, 5.000000954000029],
                [24.298320771000135, 5.002933979000147],
                [24.307840347000024, 5.004815102000123],
                [24.318538666000109, 5.006918431000145],
                [24.318599701000096, 5.006930352000154],
                [24.318632126000011, 5.006935119000104],
                [24.319229126000039, 5.007027149000066],
                [24.322441101000152, 5.005261898000128],
                [24.325679778000165, 5.003839017000075],
                [24.329622268000037, 5.003496648000066],
                [24.334287644000028, 5.00309181200015],
                [24.349121094000111, 5.00981998400016],
                [24.350103378000028, 5.009824752000156],
                [24.354984284000068, 5.009847163000188],
                [24.358509064000032, 5.008685111000091],
                [24.362035752000168, 5.007524014000069],
                [24.373939515000188, 5.008277417000159],
                [24.375324250000119, 5.008365154000046],
                [24.380790711000031, 5.010739803000149],
                [24.380964279000011, 5.010941982000077],
                [24.38165664600001, 5.011754989999986],
                [24.381689073000132, 5.011792660000083],
                [24.381711959000086, 5.011819840000044],
                [24.391880035000099, 5.022600173],
                [24.405197144000169, 5.026999950000175],
                [24.406141281000032, 5.027311802000043],
                [24.41082000800003, 5.031252861000098],
                [24.410959243999969, 5.031713010000033],
                [24.412359238000192, 5.03635597300007],
                [24.409200669000029, 5.044571878000113],
                [24.402532577000045, 5.050422192000156],
                [24.399078370000154, 5.052917481000065],
                [24.398220062, 5.053537846000154],
                [24.397712708000142, 5.053488256000151],
                [24.396793366000111, 5.05339574900006],
                [24.396713257000158, 5.053387641000086],
                [24.391714095000054, 5.053212643000165],
                [24.390146256000094, 5.053157807000105],
                [24.384347915000035, 5.050513744000057],
                [24.374343871000065, 5.045949935000181],
                [24.368850709000128, 5.046181678000096],
                [24.36845016500007, 5.046368599],
                [24.363086700999986, 5.049348832000135],
                [24.362199782000062, 5.050464631000182],
                [24.361871719000192, 5.050878048000186],
                [24.35962295600001, 5.053706169000179],
                [24.357299805000082, 5.059909821000076],
                [24.35784721300007, 5.06388425800003],
                [24.357965469000135, 5.064744950000033],
                [24.358680725000113, 5.065518857000029],
                [24.359252929000036, 5.066149712000083],
                [24.360933303000024, 5.06798124300019],
                [24.361188890000108, 5.068055631000107],
                [24.36958313000008, 5.070475579000174],
                [24.370378495000068, 5.07070493700013],
                [24.37217140199999, 5.071221352000123],
                [24.376050949000103, 5.073804855000105],
                [24.37615585400016, 5.073997022000128],
                [24.376777648000086, 5.075150491000045],
                [24.379732131000026, 5.081677914000068],
                [24.382017136000172, 5.096581935000131],
                [24.382164002000081, 5.09683227700009],
                [24.382709503000171, 5.097759723000081],
                [24.388635636000174, 5.107219219000115],
                [24.389656066000043, 5.10884905000006],
                [24.389698028000112, 5.108874798000045],
                [24.395818710000128, 5.112595081000109],
                [24.400215149000189, 5.112730027000055],
                [24.401416778000055, 5.112767221000127],
                [24.401508332000162, 5.112770081000178],
                [24.402948379000179, 5.112823964000143],
                [24.405004501000121, 5.111751556],
                [24.40729904300008, 5.110554695000189],
                [24.429859162000071, 5.063469886],
                [24.434171677000109, 5.059178830000064],
                [24.435953141000027, 5.059400558000107],
                [24.443178177000107, 5.060301305000166],
                [24.44393921000011, 5.060396194000077],
                [24.445505143000105, 5.062162877000162],
                [24.447431564000055, 5.064334869000106],
                [24.447454452000159, 5.064425468000025],
                [24.447458267000059, 5.06453323300002],
                [24.447742463000111, 5.072860241000115],
                [24.447799683000028, 5.074522019000085],
                [24.437570572000027, 5.09015703200015],
                [24.439113616000157, 5.097218037],
                [24.452816009000117, 5.103793145000111],
                [24.454391480000027, 5.104545116000111],
                [24.461534500000027, 5.104578017000051],
                [24.467243194000048, 5.104602814000089],
                [24.475070954000046, 5.098885060000043],
                [24.478305818000024, 5.099040985000045],
                [24.483718872000054, 5.099302292000061],
                [24.485626221000075, 5.09939384400019],
                [24.494356155000105, 5.092774869000095],
                [24.534362792000138, 5.080509187000075],
                [24.53919982900004, 5.07593012000018],
                [24.543844224000168, 5.064443112000106],
                [24.545030594000082, 5.055472851000161],
                [24.548271179000096, 5.049040795000167],
                [24.565311432000044, 5.036230087000149],
                [24.591949463000049, 5.031515121000098],
                [24.603298187000064, 5.031846047000101],
                [24.610536576000072, 5.032056809000153],
                [24.613767623000172, 5.030137062000051],
                [24.617677689, 5.02495288900019],
                [24.62254333400017, 5.014156818000117],
                [24.625791549000098, 5.000000954000029],
                [24.629205704000071, 4.985127925000029],
                [24.629266739000059, 4.984843731000126],
                [24.62988090500005, 4.982205868000108],
                [24.632814408, 4.975076199000171],
                [24.632968903000119, 4.975071431000174],
                [24.632999419000157, 4.974999905000118],
                [24.651998519000074, 4.973999978000052],
                [24.659677506000094, 4.970820904000107],
                [24.672306061000086, 4.968574047000061],
                [24.67390823400018, 4.969501973000092],
                [24.67599868700006, 4.960000038000032],
                [24.67404174700016, 4.953476429000034],
                [24.672998428000085, 4.949999809000133],
                [24.66898536799999, 4.939563275000126],
                [24.667999267000027, 4.937000275000116],
                [24.667905807000125, 4.93691635100015],
                [24.666381836000085, 4.935545444000127],
                [24.658025742000063, 4.928465842000037],
                [24.657485962000123, 4.926971435000098],
                [24.656440735000103, 4.924085140000102],
                [24.658065796000187, 4.919487954000033],
                [24.660194396000065, 4.917901992000111],
                [24.661998748000144, 4.916999817000146],
                [24.664747239000121, 4.916542053000114],
                [24.668170930000144, 4.916310788000089],
                [24.676420211000107, 4.919338227000026],
                [24.693162918000098, 4.921020031000069],
                [24.704647064000142, 4.923417568000104],
                [24.705539703, 4.923604012000112],
                [24.720909119000169, 4.923901081000054],
                [24.723760605000052, 4.922328950000122],
                [24.725051880000024, 4.921617031000096],
                [24.727380752000158, 4.915623188000041],
                [24.729692460000138, 4.909667969000111],
                [24.734752655000023, 4.906237126000065],
                [24.748960495000176, 4.909519197000066],
                [24.759750366000048, 4.908874034000064],
                [24.764099122000175, 4.911654949000024],
                [24.767862321000052, 4.916878224000129],
                [24.772081374000152, 4.92273521400017],
                [24.775520325000059, 4.923048973000107],
                [24.777357101000121, 4.923216819000118],
                [24.78057670700008, 4.921158790000106],
                [24.781051637000132, 4.917249204000086],
                [24.779012681000154, 4.91102504700018],
                [24.779947280000101, 4.908038140000031],
                [24.784543991000078, 4.905525207000039],
                [24.786960601000146, 4.906004905000089],
                [24.788921356000117, 4.906394482999986],
                [24.791652679000094, 4.906937123000148],
                [24.79751777600012, 4.910179138000046],
                [24.799200057000178, 4.911109924000186],
                [24.804460526000128, 4.916423797999983],
                [24.811283111000023, 4.930262089000053],
                [24.812707900000191, 4.931654454000068],
                [24.818140030000052, 4.93696498800017],
                [24.820545197000058, 4.938126087000057],
                [24.82294845500013, 4.939287187000161],
                [24.842222213000184, 4.939135075000024],
                [24.873207093000076, 4.936500074000037],
                [24.878480912000043, 4.937671184000124],
                [24.880002976000071, 4.938954831000046],
                [24.883968354000046, 4.942297935000056],
                [24.88622856, 4.950131417000023],
                [24.888490676000117, 4.957964897000124],
                [24.889028549000159, 4.958834171000149],
                [24.890773773000149, 4.961657048000177],
                [24.907520293000175, 4.962185860000091],
                [24.912183762000154, 4.962662696999985],
                [24.916620254, 4.963116646000174],
                [24.919218064000063, 4.963382244000115],
                [24.928403854000123, 4.960657121000168],
                [24.929822921000039, 4.96078634200012],
                [24.933681489000094, 4.961138248000054],
                [24.939403534000064, 4.964612961000057],
                [24.944423675000053, 4.971076966000112],
                [24.946117401000038, 4.975446224000166],
                [24.946924209000144, 4.97753095600018],
                [24.947530746000155, 4.978814126000088],
                [24.949428559000069, 4.982834816999969],
                [24.95096397399999, 4.984205724000162],
                [24.953313826999988, 4.986302853000041],
                [24.969125747000191, 4.989878178000026],
                [24.983350754000071, 4.993094920999965],
                [24.999999999000181, 4.993159771000023],
                [25.000326157000131, 4.993161201000135],
                [24.999998094000091, 4.998000145000106],
                [24.999998094000091, 5.000000954000029],
                [25.083299637000152, 5.000000954000029],
                [25.136428832000036, 5.000000954000029],
                [25.140216827000188, 5.003892422000149],
                [25.147567749000189, 5.011443138000118],
                [25.149133682000183, 5.022265910000158],
                [25.150905610000052, 5.024885178000147],
                [25.152099609000174, 5.026648998000098],
                [25.154159545000084, 5.028267861000188],
                [25.157421113000112, 5.028279782000027],
                [25.16058921900003, 5.028292180000165],
                [25.163570404000041, 5.026922226000124],
                [25.16656875700005, 5.022790910000083],
                [25.167285920000154, 5.01565790300009],
                [25.179946899000129, 5.004887103000101],
                [25.183851242000173, 5.003292084],
                [25.191190720000066, 5.003779889000157],
                [25.193653106000056, 5.005377769],
                [25.19440078800011, 5.005863189000024],
                [25.196937560000038, 5.012110710000115],
                [25.197582245000035, 5.013699054000085],
                [25.198537827000052, 5.014340879000031],
                [25.201698303000057, 5.017395019000105],
                [25.204294205000053, 5.017481328000031],
                [25.209499359000176, 5.017654896000011],
                [25.223510742000144, 5.01402187400015],
                [25.231311798000092, 5.014282226000034],
                [25.237585067000168, 5.018301487000144],
                [25.247566222000103, 5.024696827000128],
                [25.253717423000182, 5.026172161000147],
                [25.257413864000057, 5.027059078000093],
                [25.259256363000191, 5.027501106000045],
                [25.28078651400017, 5.029345037000155],
                [25.284490585000128, 5.029662132000169],
                [25.298791885000014, 5.032464505000121],
                [25.302545548000182, 5.033200265000062],
                [25.317970276000153, 5.036222933999966],
                [25.324228287000039, 5.04085016300013],
                [25.32735443100006, 5.043160917000023],
                [25.328950881000139, 5.047539234000169],
                [25.329282761000059, 5.048455715000046],
                [25.33053970300017, 5.051917077000098],
                [25.329217911000057, 5.062322140000106],
                [25.327703475000135, 5.074230195000098],
                [25.328182220000087, 5.076241017000029],
                [25.329107285000134, 5.080130101000123],
                [25.329397202000052, 5.081350804000181],
                [25.329511642000114, 5.081831932000171],
                [25.335899354000162, 5.092441081000175],
                [25.348699569000075, 5.106984138000087],
                [25.351200104000156, 5.114126205000105],
                [25.35175705000006, 5.123489856000106],
                [25.352283477000185, 5.132311822000133],
                [25.354953767000097, 5.147659779000037],
                [25.355207444000087, 5.149122239000064],
                [25.354507446000071, 5.152571201000171],
                [25.353208542000061, 5.153379441000084],
                [25.352666855000109, 5.153716087000021],
                [25.341920853000033, 5.150442124000108],
                [25.341199875000029, 5.150222778999989],
                [25.337068559000159, 5.151131154000098],
                [25.334724426000093, 5.153688908000163],
                [25.332231522000086, 5.156406881000066],
                [25.328836441000078, 5.164635182000097],
                [25.324361801000066, 5.17548084200007],
                [25.324106216000132, 5.182613849000063],
                [25.322582245000092, 5.184740066000018],
                [25.320650102000172, 5.187435150000113],
                [25.320779800000082, 5.188163757000098],
                [25.321996690000049, 5.19503402800018],
                [25.328172684000151, 5.201038837000112],
                [25.330480575000081, 5.202554226000132],
                [25.332750321000105, 5.204044818],
                [25.334680557000127, 5.207946301000163],
                [25.33594322100015, 5.21049976300003],
                [25.336051941000107, 5.211165429000062],
                [25.338399886000104, 5.225666999000168],
                [25.343006134, 5.254154206000067],
                [25.343961717000127, 5.260058403000187],
                [25.344028473000151, 5.260465145000069],
                [25.346929551000187, 5.267374516000075],
                [25.347898484000154, 5.269683839000095],
                [25.357250214000146, 5.28628778500007],
                [25.359672546000184, 5.286751271000014],
                [25.360921859000086, 5.286990165000077],
                [25.361242294000078, 5.28693294500016],
                [25.371026993000044, 5.285183430000075],
                [25.374692917000175, 5.287036897000064],
                [25.380411147000075, 5.293038846000059],
                [25.381397248000042, 5.294795513],
                [25.384290694000185, 5.299955845000113],
                [25.384710311000163, 5.304154874000176],
                [25.384727477000183, 5.304331780000098],
                [25.381509781000034, 5.308002949000183],
                [25.373489380000137, 5.304522991000169],
                [25.369581222000079, 5.304969788000108],
                [25.366807937000033, 5.310253143000182],
                [25.367643356000144, 5.315165042999979],
                [25.367708207000078, 5.31554794300007],
                [25.373212815000102, 5.318790913000157],
                [25.376182557000163, 5.320870877000118],
                [25.39179039000004, 5.320693969000047],
                [25.393934251000076, 5.321775913000067],
                [25.395000458000084, 5.322313785000119],
                [25.398197173000142, 5.326698781000061],
                [25.398399353000173, 5.335213185000043],
                [25.40022277800017, 5.340510845000154],
                [25.404109955000024, 5.342597961000138],
                [25.408487320000063, 5.33962011400007],
                [25.409900664000133, 5.327427865000175],
                [25.410621643000184, 5.326515675000167],
                [25.414976120000119, 5.321003914000187],
                [25.41980361800006, 5.318947792000074],
                [25.422857284000145, 5.319075108000106],
                [25.42576980500013, 5.319196224000052],
                [25.426733018000164, 5.319587230000025],
                [25.429208756000037, 5.320590972000161],
                [25.43018531700011, 5.321857929000089],
                [25.431343079000158, 5.323359012000026],
                [25.432147979000149, 5.32440328600012],
                [25.432409286000166, 5.324740887000075],
                [25.435495376000063, 5.328382969000131],
                [25.445899963000045, 5.340665818000048],
                [25.460329055000045, 5.349000931000035],
                [25.464229585000169, 5.349242210000057],
                [25.471820831000173, 5.346507074000044],
                [25.476409913000055, 5.347671986000137],
                [25.478748323000104, 5.350035667000043],
                [25.480520248000062, 5.351827145000129],
                [25.482585906999986, 5.36171579400002],
                [25.482780457000104, 5.362649918000045],
                [25.483486175000053, 5.363203526],
                [25.486347199000193, 5.36545038200012],
                [25.486900330000026, 5.365883827],
                [25.488893508000103, 5.365968228000156],
                [25.489286422000077, 5.365984917000048],
                [25.492868424000164, 5.366135121000013],
                [25.497467040000174, 5.364079000000174],
                [25.502077103000147, 5.358801842000162],
                [25.506708145000175, 5.347311974000036],
                [25.51131248400003, 5.343875408000088],
                [25.516725540000039, 5.34679699000003],
                [25.52803993200007, 5.352901936000137],
                [25.54754447900018, 5.354577065000115],
                [25.550289155000087, 5.358266830000105],
                [25.548555374000046, 5.361024379000128],
                [25.546825408000075, 5.363779068000042],
                [25.536699296000165, 5.37180089900005],
                [25.536544799000069, 5.372341157000165],
                [25.53531074599999, 5.376629830000184],
                [25.536893845000179, 5.382847787000117],
                [25.537330628000177, 5.383185386999969],
                [25.540779114000031, 5.385851860000059],
                [25.543485641000075, 5.385977746000037],
                [25.543634416000032, 5.385984421000103],
                [25.546062470000038, 5.386097909000114],
                [25.555046081000171, 5.376462937000156],
                [25.56032943800011, 5.375329017000126],
                [25.571043015000043, 5.376474381000037],
                [25.573640824000108, 5.376751901000034],
                [25.581451416000164, 5.375165939000112],
                [25.587900162000096, 5.369895935000159],
                [25.587402344000111, 5.365810871],
                [25.58655357400005, 5.35884380400006],
                [25.587715149000132, 5.354705811000088],
                [25.597156524000184, 5.345530988000121],
                [25.601060867, 5.345313073000114],
                [25.602321626000162, 5.346123218000059],
                [25.603580475000115, 5.346932889000186],
                [25.611621857000046, 5.346268178000173],
                [25.618499756000062, 5.348130227000127],
                [25.620803832000092, 5.347751617000085],
                [25.622631073000036, 5.347452163000185],
                [25.626779557000077, 5.341482163000023],
                [25.624500275000173, 5.337793828000145],
                [25.612346648000084, 5.334074021000106],
                [25.607997894000164, 5.330378056000086],
                [25.60181999200006, 5.322762966000084],
                [25.603437423, 5.320238113000187],
                [25.623859405000076, 5.321453095000152],
                [25.626972198000146, 5.317940713000155],
                [25.627317429000186, 5.317552090000049],
                [25.627489090000097, 5.315010071000131],
                [25.627519607000067, 5.314558029000068],
                [25.628040314000032, 5.306969166000158],
                [25.630577087000063, 5.303524972000105],
                [25.634485245000121, 5.301696777000132],
                [25.634899140000186, 5.301784516000168],
                [25.639989853000145, 5.302864074000127],
                [25.641651153999987, 5.306411743000126],
                [25.642040252000072, 5.307241917000113],
                [25.639616013000136, 5.31046295200008],
                [25.63720131000008, 5.313670158000036],
                [25.637191772000165, 5.316892146000157],
                [25.639476777000141, 5.320891381000024],
                [25.640617370000086, 5.322885990000145],
                [25.644960404000074, 5.327041149000138],
                [25.658731461000059, 5.328004837000094],
                [25.661720277000086, 5.32594204000003],
                [25.662889481000036, 5.3215727810001],
                [25.660867692000181, 5.306149005000066],
                [25.663692474000129, 5.306315898000094],
                [25.663871765000124, 5.30632638899999],
                [25.664999008000052, 5.306393147000051],
                [25.669803620000039, 5.311240196000085],
                [25.673488618000079, 5.311990261000062],
                [25.674390793000043, 5.312173843000153],
                [25.679691314000138, 5.305286885000157],
                [25.686349868000036, 5.304846764000047],
                [25.691410065000127, 5.301411153000117],
                [25.704271316000131, 5.299378873000023],
                [25.70886993400012, 5.297091008000166],
                [25.712556839000115, 5.292039872000032],
                [25.713973998000029, 5.27961778700012],
                [25.719036102000189, 5.274799824000183],
                [25.718133925000018, 5.27019500800003],
                [25.720209122000085, 5.266980172000103],
                [25.728479385000185, 5.263553142000035],
                [25.735820771000078, 5.265875816000062],
                [25.739953995000121, 5.264967918000082],
                [25.740201949000038, 5.260597228000108],
                [25.745967865000182, 5.251408100000049],
                [25.749221802000193, 5.236693383000159],
                [25.749679566000054, 5.23784017700018],
                [25.760444641000163, 5.239836216000072],
                [25.764820098000087, 5.240647793000164],
                [25.766778945000056, 5.24110794000012],
                [25.770782470000142, 5.242047787000161],
                [25.773525238000047, 5.245367527000042],
                [25.775356292000026, 5.247583866000184],
                [25.780736924000053, 5.250223160000076],
                [25.783378600000162, 5.2515192030001],
                [25.785888672000169, 5.256359100000054],
                [25.784961699000121, 5.259118081000167],
                [25.78373527399998, 5.259932042000116],
                [25.782199860000162, 5.260951042000045],
                [25.773990630000185, 5.259804249000126],
                [25.772108077000098, 5.259540081000125],
                [25.770029067000053, 5.263677120000182],
                [25.779783249000104, 5.269051075000107],
                [25.787670136000145, 5.27339506300018],
                [25.79249954200003, 5.272717953000097],
                [25.798954010000102, 5.26260996000002],
                [25.800815582000155, 5.253180981000128],
                [25.798992157000157, 5.248573303000057],
                [25.790525437000042, 5.240263940000091],
                [25.790075301000115, 5.237730979000048],
                [25.791458131000127, 5.236124039000117],
                [25.792869567000025, 5.236330033000115],
                [25.796276092000085, 5.236827850000168],
                [25.799915315000192, 5.238760948000106],
                [25.806503296000187, 5.242254734000142],
                [25.809797288000084, 5.244001866000133],
                [25.817361832000131, 5.246325017000061],
                [25.820117950999986, 5.245872975000111],
                [25.820817947000023, 5.242884157999981],
                [25.817850113000134, 5.238273144000061],
                [25.816953658000102, 5.229755878000162],
                [25.808492660000127, 5.219375134000131],
                [25.808961869000143, 5.215233803000047],
                [25.811489105000135, 5.213631153000165],
                [25.815423966000026, 5.215756894000094],
                [25.817909241000052, 5.217100143000096],
                [25.820659637000063, 5.217109203000177],
                [25.821838380000145, 5.206296921000103],
                [25.824850083000058, 5.199631214000078],
                [25.831281662000094, 5.19550705000006],
                [25.832670212000039, 5.193439961000024],
                [25.83199119600016, 5.189525128000184],
                [25.835897446000104, 5.186776161000182],
                [25.839099884000177, 5.192997933000186],
                [25.841228486000091, 5.194263935000151],
                [25.842990876000101, 5.195311069000127],
                [25.845720291000077, 5.202452183000162],
                [25.845018386000163, 5.208434106000084],
                [25.847072602000139, 5.211892128000045],
                [25.854520798000181, 5.215825080000059],
                [25.861968995000154, 5.219758034000051],
                [25.867475511000123, 5.219774723000057],
                [25.877037049000023, 5.219125747000021],
                [25.883775712000102, 5.218669891000161],
                [25.887231827000164, 5.213615894000043],
                [25.880140304, 5.206003190000047],
                [25.884967803000052, 5.203945161000036],
                [25.890031814999986, 5.199124812999969],
                [25.895999908000078, 5.197991847000026],
                [25.898534774000041, 5.194548131000033],
                [25.899698258000171, 5.187417031000109],
                [25.903169631000083, 5.180292130000055],
                [25.902730943, 5.170626164000055],
                [25.905040741000164, 5.165108203999978],
                [25.908487320000177, 5.164886951000085],
                [25.916049957000155, 5.17020178000007],
                [25.917409896000152, 5.174808026000164],
                [25.916698455000187, 5.183321],
                [25.909559250000029, 5.191586972000096],
                [25.908628464000117, 5.195957184000179],
                [25.909130097000116, 5.198360920000141],
                [25.909530639000081, 5.20032978100005],
                [25.912059785000054, 5.200799941000014],
                [25.91780090400016, 5.1978230470001],
                [25.926527023000176, 5.197154997999974],
                [25.937749864000182, 5.20547199300006],
                [25.942337036000083, 5.206636907000188],
                [25.950145722000116, 5.204585076000114],
                [25.959131241000136, 5.192183017000104],
                [25.963951111000142, 5.191736221000099],
                [25.966241836000052, 5.194501876],
                [25.962202072000082, 5.200013162000062],
                [25.961971284000185, 5.200327397000024],
                [25.961862564000057, 5.200475216000086],
                [25.961170196000126, 5.203924180000172],
                [25.966619492000177, 5.223042011000075],
                [25.970451356000183, 5.227493762000051],
                [25.975343705000057, 5.233174801000189],
                [25.977144242000065, 5.235267163000117],
                [25.985166550000031, 5.239661217000048],
                [25.987979890000076, 5.228960513000175],
                [25.989339829000073, 5.223792076000052],
                [25.992565155000136, 5.220119954000154],
                [25.994077683000057, 5.221238614000129],
                [25.994421005000049, 5.221492768000189],
                [25.995998383000142, 5.222659111000155],
                [25.99820327700013, 5.225790500000187],
                [26.001029969000115, 5.229805948000035],
                [26.006538392000152, 5.230282783000178],
                [26.009296417000144, 5.228447914000128],
                [26.011301040000035, 5.22306585400014],
                [26.01160812400019, 5.222241878000034],
                [26.011440277000077, 5.221706868000183],
                [26.008651734000182, 5.212797166000144],
                [26.01095771700011, 5.208200933000114],
                [26.016473770000118, 5.205684185000052],
                [26.023357390000172, 5.20547199300006],
                [26.025676727000132, 5.206213475000141],
                [26.027713775000109, 5.206864833000054],
                [26.029136658000141, 5.206249237000066],
                [26.038284302000079, 5.202290058000131],
                [26.041498185000023, 5.202989101000185],
                [26.04652977100011, 5.210825921000037],
                [26.050189972, 5.213368893000109],
                [26.053632736000111, 5.213377953000133],
                [26.053634644000113, 5.213313103000132],
                [26.053888321000045, 5.202101230000153],
                [26.058950423, 5.19781541899999],
                [26.063102723000043, 5.194300174000034],
                [26.067914963000135, 5.194083214000045],
                [26.070430756000178, 5.198463917000026],
                [26.070896148000145, 5.204761982000036],
                [26.070955276000063, 5.20555591599998],
                [26.071094512, 5.207438947000128],
                [26.076818466000077, 5.213206768000134],
                [26.077596664000112, 5.213270188000024],
                [26.08645629900019, 5.213462829999969],
                [26.092670441000053, 5.206573009000067],
                [26.098178864000147, 5.205897332000177],
                [26.099214555000117, 5.207633019000184],
                [26.099327088000109, 5.207822323000187],
                [26.09955215500014, 5.208202363000055],
                [26.097482681000145, 5.21401596100003],
                [26.101350785000136, 5.22224521600009],
                [26.100883483000189, 5.22523403100007],
                [26.097200393000094, 5.227786063000167],
                [26.09260749800012, 5.230968952000183],
                [26.090532303000089, 5.233493806000183],
                [26.090757371000052, 5.234979153000097],
                [26.090986252000107, 5.236485958000117],
                [26.092819213000155, 5.237872123000159],
                [26.103147507000074, 5.238820075000092],
                [26.103836059000059, 5.238545895000129],
                [26.107740402000047, 5.236989975000029],
                [26.112117767000143, 5.231246948000091],
                [26.116708755000047, 5.229878904000032],
                [26.117271423000034, 5.230218887000035],
                [26.120145798000124, 5.231956959000115],
                [26.122650146000183, 5.240249157000051],
                [26.122953415000154, 5.240425110000103],
                [26.128610610000067, 5.243715762000079],
                [26.129634858000088, 5.246940136000035],
                [26.130659103000028, 5.250165939000056],
                [26.132501602000161, 5.250433922000127],
                [26.133871079000187, 5.250633238999967],
                [26.135005952000029, 5.255008221000139],
                [26.134300232000101, 5.262139798000021],
                [26.134439468000096, 5.262264729000151],
                [26.136360168000181, 5.263986112000168],
                [26.140951156000085, 5.263076782000098],
                [26.146703720000176, 5.257798195000021],
                [26.147386550000192, 5.25783300400019],
                [26.160932542000069, 5.258523941000078],
                [26.163463593000188, 5.255999088000124],
                [26.157773971000154, 5.23757505399999],
                [26.160079957000164, 5.232058048000056],
                [26.164220811000121, 5.228847028000132],
                [26.164466858000139, 5.228859426000099],
                [26.169042587000092, 5.229087829000036],
                [26.169178008000188, 5.229213238000057],
                [26.174310684000147, 5.23393392600002],
                [26.176918031000127, 5.234777928000028],
                [26.180042267000033, 5.235788823000121],
                [26.181697845000031, 5.237872123000159],
                [26.184621812000103, 5.241552831000092],
                [26.186910629000067, 5.241559983000116],
                [26.187791825000147, 5.234006405000059],
                [26.187850953000066, 5.233508111000049],
                [26.19153976399997, 5.227070809000168],
                [26.184440613000163, 5.219921112000179],
                [26.186742782000124, 5.217854978000105],
                [26.190650940000182, 5.217636108000136],
                [26.196830749000185, 5.22179317500013],
                [26.199001313000167, 5.22970914800004],
                [26.200239181000086, 5.234228135000137],
                [26.202991485000098, 5.237226963000069],
                [26.208040237000034, 5.237008095000078],
                [26.211265563000097, 5.231953144000102],
                [26.210590362000062, 5.22665691300017],
                [26.211748123000064, 5.221828937000055],
                [26.218425751000098, 5.214249134000056],
                [26.2097148900001, 5.208476066],
                [26.215925216000073, 5.204117774],
                [26.21800613400012, 5.197448731000065],
                [26.221227645000113, 5.194007873000089],
                [26.221302033000086, 5.194460869000125],
                [26.223264695000069, 5.206896782000115],
                [26.229692459000091, 5.20668220499999],
                [26.234119416000169, 5.207739354000182],
                [26.23748970000014, 5.208543777000045],
                [26.239564896000104, 5.20555591599998],
                [26.232236863000139, 5.198174000000108],
                [26.236608505000163, 5.19266081000012],
                [26.238233567000123, 5.185300827000162],
                [26.243288041000028, 5.18347310900009],
                [26.244476319000114, 5.189275741000074],
                [26.245784760000106, 5.195675373000029],
                [26.249450685000113, 5.198444843000061],
                [26.253808974000037, 5.197995187000117],
                [26.254051208000192, 5.192702771000143],
                [26.25428390400009, 5.191092014000162],
                [26.260494231999985, 5.185813904000099],
                [26.25386619600016, 5.174982071000102],
                [26.254562377000127, 5.171531677000075],
                [26.257089615000154, 5.169926644000043],
                [26.282560349000107, 5.170907020000186],
                [26.285549164000031, 5.169303895000155],
                [26.287397385000133, 5.165392876999988],
                [26.282131196000023, 5.159860134999974],
                [26.283060075000094, 5.155950070000017],
                [26.291540147000035, 5.16033983300008],
                [26.292819977000079, 5.160503865000123],
                [26.295211792000032, 5.160809995000079],
                [26.297292709000146, 5.154370785000026],
                [26.301198960000136, 5.15138816800004],
                [26.301681518000123, 5.141724110000155],
                [26.303287505000185, 5.140808105000133],
                [26.306503296000074, 5.140355586000112],
                [26.309719086000086, 5.141973019000091],
                [26.311550140000065, 5.144279003000065],
                [26.311201096000104, 5.14588880600013],
                [26.310850144000028, 5.147500038000032],
                [26.312669755000059, 5.15256595600016],
                [26.314504623000175, 5.152964592000103],
                [26.315881730000172, 5.153264046],
                [26.317962646000183, 5.151659011000163],
                [26.318889619000061, 5.142683983000097],
                [26.324415207000186, 5.136023046000105],
                [26.327169418999972, 5.136259079000183],
                [26.325771331000112, 5.14523220000018],
                [26.328287124000042, 5.148458958000163],
                [26.331016540000064, 5.149087430000122],
                [26.333337783000047, 5.149621963000016],
                [26.342060089000142, 5.146880150000129],
                [26.348630905000107, 5.153003216000116],
                [26.351219176000086, 5.15541505900012],
                [26.35375023000006, 5.154038907000086],
                [26.361112595000066, 5.14461994100003],
                [26.366397857000038, 5.142790795000053],
                [26.373510361000172, 5.143497944000046],
                [26.379930496000156, 5.148115158000167],
                [26.3859806050001, 5.148621083000023],
                [26.388420104000033, 5.148824215000104],
                [26.390939712000147, 5.147447109000041],
                [26.393718720000038, 5.136638165000079],
                [26.390747071000135, 5.132258892000039],
                [26.393276214000025, 5.129961967000156],
                [26.397182465000185, 5.129049779000184],
                [26.399494171000185, 5.12123346300001],
                [26.400518416000068, 5.121850967000171],
                [26.401786803000164, 5.122615815000188],
                [26.402717590000123, 5.12692403900013],
                [26.403377533000139, 5.129984855000089],
                [26.40543556200015, 5.132750988000055],
                [26.414154053000061, 5.132999897000161],
                [26.41484642000006, 5.130470753000168],
                [26.411878586000171, 5.123559952000051],
                [26.413030624999976, 5.121261119],
                [26.41807937700014, 5.12081289300005],
                [26.422439575999988, 5.12243223199999],
                [26.421768189000147, 5.11506509899999],
                [26.424064637000072, 5.112999917000025],
                [26.433710099000052, 5.110949516000119],
                [26.439834594000104, 5.113459111000168],
                [26.440052032000153, 5.113548280000032],
                [26.441049576000069, 5.113957882000136],
                [26.442901612000185, 5.10567522100007],
                [26.438791274999971, 5.095541001000186],
                [26.440870285000074, 5.091173172000026],
                [26.444309236000095, 5.090491772000121],
                [26.45095062200005, 5.096258165000165],
                [26.457151413000133, 5.09696197500017],
                [26.45899009600015, 5.095122814000149],
                [26.45693016000007, 5.090285778000123],
                [26.456943513000169, 5.086372852000181],
                [26.461778642000127, 5.077178001000107],
                [26.460868834000166, 5.075565815000118],
                [26.454902649000076, 5.074862005000171],
                [26.452838898000095, 5.073016643000074],
                [26.455839156000025, 5.065658092999968],
                [26.459970474999977, 5.064056873000027],
                [26.463657380000143, 5.05854129800008],
                [26.464553832000036, 5.05862426900012],
                [26.466178893000119, 5.058775901000047],
                [26.47052955700002, 5.06315994400012],
                [26.47857284600002, 5.058573724000098],
                [26.48476028400006, 5.061575889999972],
                [26.485097885000187, 5.060769082000036],
                [26.485336303000167, 5.060197353000092],
                [26.485912323000036, 5.058817863000172],
                [26.484876632000066, 5.056368828000132],
                [26.483173370000031, 5.052368163000097],
                [26.488008499000102, 5.046394826000153],
                [26.49075889500017, 5.046400071000164],
                [26.492824554000094, 5.048476219000122],
                [26.493478775000142, 5.050679684000102],
                [26.494235993000075, 5.053236961000039],
                [26.494873047, 5.055384159000084],
                [26.499689103000094, 5.055853844000126],
                [26.502904892000117, 5.053790092000099],
                [26.50383567800003, 5.047808170000053],
                [26.509122848000175, 5.043907642000079],
                [26.515306473000123, 5.05036306400001],
                [26.518989563000048, 5.045308113000033],
                [26.526790618000064, 5.044173717000149],
                [26.533422469000186, 5.056844235000085],
                [26.531803131000117, 5.0630540840001],
                [26.533859252000184, 5.06720113800003],
                [26.541660310000111, 5.067907811000168],
                [26.541757584000152, 5.067670823000071],
                [26.543731690000072, 5.062848092000081],
                [26.54374504000009, 5.057324886000174],
                [26.538484574000051, 5.049948215000086],
                [26.540327072000082, 5.046503544000075],
                [26.544219970000142, 5.049040795000167],
                [26.545217514000058, 5.050797462000105],
                [26.548795700000028, 5.05710506500003],
                [26.55166244600008, 5.057800770000085],
                [26.554531098000098, 5.058496953000088],
                [26.550390244000141, 5.064013004],
                [26.551843642000165, 5.065478325000015],
                [26.554277421000165, 5.067933083000128],
                [26.55563926700006, 5.075070857000071],
                [26.556070328000089, 5.075442314000156],
                [26.557247163000056, 5.076454162000175],
                [26.559766769000134, 5.076458932000037],
                [26.560934066000186, 5.06679391900019],
                [26.564386367999987, 5.063580036000076],
                [26.57448005800012, 5.063370229000043],
                [26.575628282000082, 5.064523220000126],
                [26.575012208000089, 5.066210747000071],
                [26.574705124000047, 5.067052841000077],
                [26.573835373000122, 5.06755972000019],
                [26.57194709700002, 5.068659782000111],
                [26.571710586000108, 5.073030949000042],
                [26.572322845000031, 5.074430467000184],
                [26.575138091000099, 5.08086204600005],
                [26.578344344000072, 5.081327915000145],
                [26.583402634000095, 5.077657223000188],
                [26.584520341000143, 5.07845926300007],
                [26.585010529000158, 5.078811170000165],
                [26.585229874000106, 5.082032203000154],
                [26.587409974000025, 5.082266807000167],
                [26.589588166000112, 5.082500935000041],
                [26.592357636000145, 5.074681758999986],
                [26.596490859000085, 5.073770524],
                [26.598957062000125, 5.075107099000036],
                [26.599470139000175, 5.075386047],
                [26.599824906000151, 5.07626152000006],
                [26.600324631000035, 5.077492238000104],
                [26.601383209, 5.080112458000031],
                [26.601985932000048, 5.081604958000071],
                [26.603929520000122, 5.082327367000062],
                [26.612459183000055, 5.085497856000075],
                [26.619413376000182, 5.08808279200008],
                [26.621019364000176, 5.086934090000113],
                [26.614604951000103, 5.08024788],
                [26.62149620100007, 5.078879833000087],
                [26.621961593000037, 5.073817731000077],
                [26.620365143000129, 5.070131778000018],
                [26.621284484000057, 5.067833900000153],
                [26.625190734000171, 5.067380904000174],
                [26.628870011, 5.063476086000037],
                [26.632308960000103, 5.065093040000022],
                [26.633449554000151, 5.069005966000134],
                [26.630455016000155, 5.074637414000165],
                [26.627460480000025, 5.080272198000102],
                [26.630897522000168, 5.08281278700008],
                [26.638240813000152, 5.081444741000041],
                [26.640317918000164, 5.086847782000064],
                [26.64098358200016, 5.088583945999972],
                [26.645580292000034, 5.084911346000069],
                [26.648332595999989, 5.085309982000183],
                [26.650398255000141, 5.085609913000042],
                [26.652935029000048, 5.078711033000161],
                [26.655464173000041, 5.076182842000151],
                [26.662115097000026, 5.076425076000078],
                [26.66969108699999, 5.077360154000189],
                [26.676105500000062, 5.082204819000026],
                [26.681152344, 5.084055901000056],
                [26.681915282000091, 5.085499286000015],
                [26.682979584, 5.087511063000136],
                [26.681318284000042, 5.090828418000058],
                [26.679985047000173, 5.093489170000112],
                [26.681087494000167, 5.095706464000102],
                [26.681360244000189, 5.096255780000092],
                [26.683340072000192, 5.09643936100008],
                [26.688930511000081, 5.096958160000099],
                [26.689086913000097, 5.095936775000041],
                [26.689390183000114, 5.093966960000046],
                [26.687789917000032, 5.090972900000111],
                [26.690553665000095, 5.087525368000172],
                [26.697206497000082, 5.08822584100011],
                [26.705656052000109, 5.091923237000174],
                [26.709360123000067, 5.093544007000162],
                [26.710948945000098, 5.102520943],
                [26.717840195000065, 5.099542141000143],
                [26.721525193000105, 5.091724873000089],
                [26.72313117900012, 5.09218692900015],
                [26.725181580000083, 5.100014211000087],
                [26.726558684000111, 5.100248814000054],
                [26.732534409000095, 5.094736576000116],
                [26.74078369099999, 5.101882936000095],
                [26.74995613100009, 5.103510380000103],
                [26.753871917000083, 5.097306252000124],
                [26.75627136200012, 5.099293709000165],
                [26.757768631999966, 5.100533963000089],
                [26.766492843000037, 5.098708152000143],
                [26.772453307000092, 5.101709843000037],
                [26.773374558000171, 5.100330831000179],
                [26.771320344, 5.093653203000145],
                [26.772239685000159, 5.090662957000177],
                [26.770641327000078, 5.088128089],
                [26.771331787000179, 5.085597993000135],
                [26.774190904000022, 5.085017205000042],
                [26.781433106000122, 5.083544732000121],
                [26.785230636000051, 5.085454941000023],
                [26.786479950000057, 5.086083888000076],
                [26.789012908000188, 5.081484795000165],
                [26.78374099600012, 5.075492859000121],
                [26.793153762000145, 5.07251691800019],
                [26.792245865000098, 5.067223072000047],
                [26.793859483000176, 5.063082695000048],
                [26.797769546000154, 5.058485985000061],
                [26.803735733000053, 5.058725834000086],
                [26.806274415000132, 5.050446033000071],
                [26.80811119100008, 5.049067973999968],
                [26.821199417000116, 5.047709942000097],
                [26.823545456000147, 5.048774242000036],
                [26.825782775999983, 5.049788952000142],
                [26.828739167000094, 5.057533265000075],
                [26.829210282000076, 5.058769226000095],
                [26.831499099000041, 5.059233190000157],
                [26.835178374000066, 5.056707859000028],
                [26.845964432000073, 5.054654123000091],
                [26.850109101000101, 5.051208974000019],
                [26.848276138000188, 5.044991970000069],
                [26.854480744000057, 5.042240144000118],
                [26.856317520000118, 5.039021015000117],
                [26.872159957000065, 5.033754826000063],
                [26.875595093000072, 5.039973260000124],
                [26.878807068000185, 5.040668010000047],
                [26.880872726000064, 5.044354915000042],
                [26.882928849000052, 5.044933320000041],
                [26.884990693000134, 5.045513154000048],
                [26.884927749999974, 5.046211720000088],
                [26.884292603000119, 5.053336144000184],
                [26.889791488000071, 5.059098244000154],
                [26.89139175300005, 5.063012124000181],
                [26.889493942000172, 5.066487312000106],
                [26.888629914000092, 5.068071842000109],
                [26.891138076000118, 5.073600768000176],
                [26.894811631000039, 5.074525834000156],
                [26.901012421000075, 5.072234631000015],
                [26.905370713000082, 5.073390961000143],
                [26.910573960000136, 5.078450680000174],
                [26.912244798000188, 5.080076219000148],
                [26.912239075000116, 5.088375568000174],
                [26.925109863000159, 5.113733768999964],
                [26.926288606000071, 5.115708829000141],
                [26.927560806000145, 5.117842674000031],
                [26.934900284000094, 5.119463920000101],
                [26.935005187000172, 5.119632720000027],
                [26.936048508000056, 5.121307851000154],
                [26.933246612000062, 5.125917912000091],
                [26.932134628000085, 5.127745628000184],
                [26.935108184000057, 5.13419437500005],
                [26.947952271000077, 5.138586044000078],
                [26.955057144000079, 5.144810200000052],
                [26.966754912000169, 5.148739814000123],
                [26.970191956000178, 5.152197361000162],
                [26.979589461000103, 5.157503127000041],
                [26.985330582000074, 5.15889597000006],
                [26.997947694000061, 5.165335655000092],
                [27.001152039000033, 5.166971206000142],
                [27.00130653400015, 5.167096614],
                [27.01880073600006, 5.181035042000076],
                [27.029123307000134, 5.187030793000133],
                [27.050916671000152, 5.193278788999976],
                [27.057222366000076, 5.196312904000138],
                [27.066740035000066, 5.200892925000119],
                [27.078672409000035, 5.204131127000153],
                [27.085052490000066, 5.204621793000058],
                [27.112178802000074, 5.206707001000098],
                [27.142936706000057, 5.204216004000159],
                [27.157398223000143, 5.199631691000036],
                [27.158041001000186, 5.199243068000101],
                [27.173437119000084, 5.189949034999984],
                [27.181743621000066, 5.184932710000169],
                [27.195999145000087, 5.172063827000045],
                [27.231821060000073, 5.15231323200004],
                [27.257532120000064, 5.143138885999974],
                [27.268554687000176, 5.141079903000048],
                [27.290588379000155, 5.135120869000161],
                [27.30620765600014, 5.12846517600002],
                [27.318143845000179, 5.12640381000017],
                [27.324337006000178, 5.122960091000095],
                [27.333066940000037, 5.114683628000137],
                [27.340650558000107, 5.110777855000038],
                [27.360845566000023, 5.105277062000084],
                [27.373472213000184, 5.100226879000104],
                [27.385427476000018, 5.097842217000164],
                [27.394130706000169, 5.096104623000088],
                [27.414569854000035, 5.087839127999985],
                [27.431554795000011, 5.077728749000073],
                [27.438222885000187, 5.070831776000034],
                [27.442590714000175, 5.063469886],
                [27.446266174000186, 5.052886963000049],
                [27.446506500000169, 5.047594070000059],
                [27.442150116000164, 5.03769397799999],
                [27.441083909000156, 5.021951198000068],
                [27.44474983300006, 5.017531871000074],
                [27.452085495, 5.01520299900011],
                [27.463420868000071, 5.016152858999988],
                [27.470191955000132, 5.008231163000062],
                [27.474037171000191, 5.006124974000102],
                [27.484159470000066, 5.00058221900008],
                [27.484540940000159, 5.000275135000095],
                [27.484880446000034, 5.000000954000029],
                [27.491256715000077, 4.994801998000128],
                [27.491485596000132, 4.994417190000036],
                [27.497190475000082, 4.984883786000125],
                [27.50130271800009, 4.980954170000075],
                [27.514600754000071, 4.977450848000103],
                [27.516668320000065, 4.976195812000185],
                [27.519180298000094, 4.974670887000059],
                [27.521459580000055, 4.970750809000037],
                [27.522073746000046, 4.969131948000154],
                [27.526004791000162, 4.958765030000166],
                [27.52552795400004, 4.958765984000081],
                [27.525085450000176, 4.958766937000064],
                [27.525417328000117, 4.949807167000188],
                [27.525707244000102, 4.941963197000064],
                [27.532760620000033, 4.926054002000115],
                [27.538700103, 4.918896199000187],
                [27.542251587000067, 4.917764188000092],
                [27.550390243000038, 4.915168762000064],
                [27.558404922000136, 4.910535812000092],
                [27.564781188000154, 4.900240897000117],
                [27.567981720000091, 4.89507579800005],
                [27.573925019000058, 4.88929891700019],
                [27.574932097999977, 4.888679028000183],
                [27.580337524000129, 4.885361194999973],
                [27.591089248000117, 4.890881061000187],
                [27.594133378000095, 4.89244413400013],
                [27.601480485000081, 4.893334866000089],
                [27.64462089599999, 4.893396854000059],
                [27.645805359000065, 4.893200875000105],
                [27.653186797999979, 4.891979218000188],
                [27.657468796000103, 4.891273022],
                [27.661151886000027, 4.888552189000052],
                [27.663417817000152, 4.886876106000045],
                [27.676631927000187, 4.874993324000059],
                [27.683990479000101, 4.868381024000087],
                [27.690160751000064, 4.861221791000048],
                [27.693090438000127, 4.85559988000017],
                [27.694488526000157, 4.852917194999975],
                [27.700599670000031, 4.831486225000106],
                [27.702251434000118, 4.827880859000061],
                [27.70044136000007, 4.822696686000029],
                [27.702739715000121, 4.81441164],
                [27.704376221000132, 4.805517196000039],
                [27.705390929000089, 4.800007820000189],
                [27.705410003000054, 4.79502487200017],
                [27.710676194000143, 4.789212227000178],
                [27.713716507000072, 4.785858155000142],
                [27.718097687000181, 4.785769940000023],
                [27.728172302000019, 4.785569191000036],
                [27.742649078000056, 4.79103613000018],
                [27.756420135000042, 4.790978910000092],
                [27.758712768000123, 4.790740013000118],
                [27.760541916000079, 4.789408206000132],
                [27.766029358000083, 4.785416127000133],
                [27.771718980000117, 4.775648594000131],
                [27.775194168000098, 4.775662899000167],
                [27.781772612999987, 4.769682407000062],
                [27.788982391000047, 4.754093171000022],
                [27.789022446000104, 4.743279934000157],
                [27.789154052000129, 4.709037305000095],
                [27.791002273, 4.693424226000161],
                [27.791032792000124, 4.685013293999987],
                [27.776855468000065, 4.66623163200012],
                [27.774919510000075, 4.661081791000129],
                [27.775959016000115, 4.653315067000165],
                [27.776029586999982, 4.652788161999979],
                [27.776256562000071, 4.652289867999969],
                [27.77876091000013, 4.64679193600017],
                [27.783966064000026, 4.633730889000162],
                [27.786020280000173, 4.628577232000168],
                [27.78664588900017, 4.621772766000106],
                [27.787120819000052, 4.616602898000053],
                [27.782037736000177, 4.608565807000048],
                [27.781867980000186, 4.607501507000109],
                [27.781436920000033, 4.604803086000061],
                [27.780860901000096, 4.601206780000041],
                [27.78347015300011, 4.597548961000143],
                [27.783824920000086, 4.597052097000073],
                [27.784374238000055, 4.596826554000131],
                [27.787252425000077, 4.595642089000137],
                [27.793441773000097, 4.593099117],
                [27.806318283, 4.600872039000137],
                [27.808614731000148, 4.600401879000117],
                [27.810909271000071, 4.599930763000032],
                [27.81408119200006, 4.590939046000074],
                [27.81727409400014, 4.58724498800018],
                [27.832237243000122, 4.584867954000117],
                [27.832857132000186, 4.57736683000013],
                [27.833072662000063, 4.5747900020001],
                [27.836919785000134, 4.574195862000124],
                [27.843954087000043, 4.573112011000092],
                [27.846181869000191, 4.569853783000156],
                [27.854539871000043, 4.557622910000077],
                [27.861482621000164, 4.551185131000011],
                [27.862768174000053, 4.549993038000082],
                [27.868270874000075, 4.547897816000102],
                [27.878129959000034, 4.547348976000137],
                [27.886150360000045, 4.546902180000132],
                [27.893512727000029, 4.547133923000047],
                [27.905424118000155, 4.547510148000185],
                [27.905429840000068, 4.548430919000055],
                [27.913396836000061, 4.5480041510001],
                [27.919420243000104, 4.547680854000078],
                [27.922458648000031, 4.546043872000155],
                [27.9267425540001, 4.543735982000158],
                [27.930183410000041, 4.543951034000031],
                [27.93057823100014, 4.544663905000107],
                [27.93272972200009, 4.548542977000068],
                [27.933414459000176, 4.551552773000083],
                [27.944169998, 4.556960584000137],
                [27.953535080000051, 4.551627636000148],
                [27.964120865000098, 4.547991753000133],
                [27.980867385000124, 4.544545650000032],
                [27.987977981000142, 4.548916817000134],
                [27.99348640400018, 4.546619416000169],
                [28.000921250000033, 4.547194481000076],
                [28.008384705000026, 4.547772408000185],
                [28.014574050000078, 4.54616260500012],
                [28.026273727999978, 4.538108826000155],
                [28.028337479000129, 4.534656524000127],
                [28.027193069000134, 4.52821159400014],
                [28.032503126999984, 4.487388134000128],
                [28.032390595000095, 4.485364437000158],
                [28.033855438000103, 4.476883889000135],
                [28.038904190000039, 4.466987610000103],
                [28.066617966000138, 4.44788026800012],
                [28.076784135000082, 4.43800544700008],
                [28.085741042, 4.43503761300002],
                [28.102716445000112, 4.43690919800008],
                [28.10572624100007, 4.438213825000105],
                [28.110673905000169, 4.440358162000166],
                [28.112077714000065, 4.440020084000082],
                [28.113094329000091, 4.438747882000143],
                [28.118000031000065, 4.432625770000072],
                [28.12103652900015, 4.431766987000117],
                [28.122129440000037, 4.43145704300008],
                [28.128635407000047, 4.434388160000026],
                [28.131240844000047, 4.435564040000145],
                [28.132240296000134, 4.436015129000111],
                [28.133367539000062, 4.435207844000161],
                [28.140010833000133, 4.430453778000128],
                [28.142509459999985, 4.425609111000085],
                [28.142719269000054, 4.420774937000147],
                [28.139701843000125, 4.412961961000121],
                [28.138942718000123, 4.412072182000145],
                [28.133249283000055, 4.405395031000182],
                [28.131242752000048, 4.401023864000081],
                [28.130926132000127, 4.400340079999978],
                [28.13170623799999, 4.395836831000167],
                [28.132043839000119, 4.393889905000037],
                [28.132820130000141, 4.393142223000154],
                [28.136608123000144, 4.38949584900007],
                [28.152420044000053, 4.385048866000091],
                [28.167528152000102, 4.379685879000022],
                [28.175306319000185, 4.375276090000114],
                [28.183359146000043, 4.367472649000035],
                [28.183437347000051, 4.367397309000012],
                [28.20011329600004, 4.34615659799999],
                [28.218912125000145, 4.337409020999985],
                [28.231296540000187, 4.333955764],
                [28.238630294000131, 4.329581739000048],
                [28.255374908000078, 4.327970506000042],
                [28.25893783500004, 4.328301907000025],
                [28.265230178000138, 4.328889369000137],
                [28.272567749000189, 4.327509404000068],
                [28.278142928000079, 4.329268932000161],
                [28.28931045600018, 4.332799912000098],
                [28.301223754000034, 4.333488465000187],
                [28.309026718000098, 4.331647396000165],
                [28.309608460000106, 4.331699848000028],
                [28.316593169999976, 4.332335948000093],
                [28.321081162000098, 4.336195946000089],
                [28.321409225000139, 4.336478234000026],
                [28.331487655000046, 4.336017608000077],
                [28.333822250000026, 4.337017060000164],
                [28.33791542099999, 4.338776112000062],
                [28.351892471000099, 4.338083745000063],
                [28.353532791000077, 4.337664605000043],
                [28.353780746999973, 4.336451053000133],
                [28.361141205000081, 4.330410480000126],
                [28.363372802000072, 4.328578949000189],
                [28.366104127000028, 4.324421882000081],
                [28.367631911000103, 4.31647300700007],
                [28.385356904000048, 4.299515725000163],
                [28.414470673000096, 4.282824516000119],
                [28.437862397000174, 4.283800601000166],
                [28.453636169000049, 4.287772180000047],
                [28.464916230000142, 4.298638344000096],
                [28.465261459000033, 4.29874038600002],
                [28.480390548000116, 4.303209781000078],
                [28.488105774000076, 4.311356068000066],
                [28.494916916000136, 4.3219032290001],
                [28.50229454100014, 4.339966298000149],
                [28.511747360000015, 4.361342907000051],
                [28.523921967000092, 4.371610641000132],
                [28.539995193000038, 4.375282288000051],
                [28.558763505000059, 4.375659466],
                [28.561712266000029, 4.375972747999981],
                [28.58228874200006, 4.378159523000022],
                [28.58948516900017, 4.387498378000089],
                [28.590631486000063, 4.394454956000118],
                [28.592180251000059, 4.403865815000131],
                [28.592786789000172, 4.40490198100008],
                [28.595907211000053, 4.410224915000129],
                [28.599651336000136, 4.412946225000042],
                [28.602100373000155, 4.41440391600014],
                [28.605535507000184, 4.413235189000147],
                [28.61793708800019, 4.416394234000165],
                [28.62227249100016, 4.412228107000146],
                [28.626399994000167, 4.411056042000041],
                [28.636510848000057, 4.41468715700006],
                [28.64430999800004, 4.415337085000147],
                [28.653032302000156, 4.416902066000091],
                [28.658071518000042, 4.418392181000172],
                [28.662218092999979, 4.419617176000031],
                [28.66540908800016, 4.428567887000099],
                [28.679185868000161, 4.436854363000066],
                [28.690155029000096, 4.451022625000121],
                [28.702587127000072, 4.471807481000042],
                [28.704324723000127, 4.484735489000116],
                [28.704267501000174, 4.499759198000049],
                [28.707384109000145, 4.509412288000078],
                [28.712093353000057, 4.524003982000181],
                [28.713396073000183, 4.528040886000042],
                [28.716585160000193, 4.534021854000059],
                [28.721330643000158, 4.536246776000098],
                [28.725229263000131, 4.536715030000096],
                [28.726842881000096, 4.536908149],
                [28.730121612000175, 4.536921025000027],
                [28.751619339000172, 4.537007809000102],
                [28.751981734000026, 4.53714609300016],
                [28.757589340000038, 4.539278030000105],
                [28.759378433000165, 4.542285919000165],
                [28.759910583000135, 4.5431780830001],
                [28.767280578000054, 4.548202991000039],
                [28.768209457000069, 4.550961018000066],
                [28.769096374000185, 4.551595212000109],
                [28.778110504000097, 4.558042049000164],
                [28.78288078200012, 4.558146954000051],
                [28.78660011400018, 4.558228971000176],
                [28.787969590000159, 4.556840895999983],
                [28.795473099000105, 4.544600010000067],
                [28.795654298000045, 4.544117927000059],
                [28.797729493000077, 4.538603783000156],
                [28.804504395000038, 4.532542229000114],
                [28.805496217000041, 4.53165483500004],
                [28.809188842000083, 4.515793800000154],
                [28.809305190000146, 4.515289784000174],
                [28.809499742000071, 4.508615018000171],
                [28.805717467000193, 4.501128197000071],
                [28.803468704000011, 4.496676922000063],
                [28.804605485000025, 4.494275094000102],
                [28.806417465000038, 4.490446092000013],
                [28.821107864000055, 4.478607177000072],
                [28.82194709800001, 4.477931023000053],
                [28.822826386000145, 4.477470874000119],
                [28.829952240000182, 4.473743917000036],
                [28.8331604010001, 4.473034859000165],
                [28.845090867000124, 4.473432065000168],
                [28.857269288000168, 4.476819038000144],
                [28.866035461000081, 4.486669065000058],
                [28.867256165000185, 4.487172126000019],
                [28.868795395000177, 4.48780584300016],
                [28.882528304, 4.482666970000139],
                [28.890113830000075, 4.47743988000002],
                [28.89532470600011, 4.473848819000182],
                [28.902652740000121, 4.471045971000081],
                [28.908245086000136, 4.474744796000152],
                [28.912319183000136, 4.477438927000037],
                [28.913644790000149, 4.477067948000126],
                [28.919878006000033, 4.475323201000094],
                [28.92490959200012, 4.472993852000059],
                [28.929231645000073, 4.473378182000033],
                [28.932256699000106, 4.473646165000105],
                [28.941677093000067, 4.476984979000122],
                [28.946964262999984, 4.478857994000123],
                [28.965066909000029, 4.475766182000086],
                [28.975389480000104, 4.475247860000025],
                [28.978155135000065, 4.476297856000031],
                [28.981363297000144, 4.47751617400013],
                [28.988706589000174, 4.478166104000081],
                [28.995380402000023, 4.481579781000164],
                [28.995798111000056, 4.481905937000136],
                [29.000162124000099, 4.48098516400006],
                [29.009096145000058, 4.484024048000151],
                [29.021007538000163, 4.488207817000046],
                [29.028129577000129, 4.490709782000181],
                [29.034681321000107, 4.488935948000119],
                [29.040672302000075, 4.480546475000097],
                [29.047256470000036, 4.4727606780001],
                [29.054435729000147, 4.464977264000027],
                [29.06100654700009, 4.460797310000032],
                [29.068765641000084, 4.457222938000029],
                [29.079498291000107, 4.454862595000066],
                [29.088436127000136, 4.454899311000077],
                [29.093818665000185, 4.449512004000098],
                [29.09324455300009, 4.444100858000013],
                [29.088527679000094, 4.43085956499999],
                [29.091987609000171, 4.428089620000094],
                [29.092096329000128, 4.428085805000023],
                [29.094951630000025, 4.426838874000168],
                [29.095298767000031, 4.426686764000181],
                [29.104892731000177, 4.428526878000184],
                [29.110450744000048, 4.429593086000125],
                [29.11727142400008, 4.428665637000051],
                [29.124883653000097, 4.430404663000047],
                [29.139186858000073, 4.427459718000023],
                [29.141588211000055, 4.422661305000077],
                [29.150606156000038, 4.401662349000048],
                [29.180513382000129, 4.371130943000026],
                [29.199041366000131, 4.356179715000053],
                [29.214555740000037, 4.35263919900018],
                [29.240753175000179, 4.359956742000179],
                [29.251384736000091, 4.363905430000045],
                [29.253843308000114, 4.364818097000125],
                [29.256774903000121, 4.377452850000054],
                [29.26792335600004, 4.39081287300013],
                [29.277629853000121, 4.386028767000084],
                [29.286348344000032, 4.386666774000048],
                [29.290351867000084, 4.384531021000157],
                [29.29114913900014, 4.384106159000169],
                [29.295700073000091, 4.379014015],
                [29.300130845000069, 4.378691197000137],
                [29.30602073600005, 4.378262043000177],
                [29.313467025000023, 4.380581856000163],
                [29.315441132000046, 4.381196974999966],
                [29.31572532600012, 4.381359100000168],
                [29.321428298000171, 4.384613990000162],
                [29.33246994000018, 4.395564079000167],
                [29.338270187000035, 4.401318073000027],
                [29.342153548999988, 4.410258769999985],
                [29.342458726000132, 4.410961152000027],
                [29.347801209000124, 4.43693685500017],
                [29.368467332000023, 4.462050914000031],
                [29.374967574000152, 4.469037534000051],
                [29.387319565000155, 4.470771790000015],
                [29.399280548000092, 4.475990772000046],
                [29.408729554000161, 4.483300210000152],
                [29.427677154000037, 4.509672165000154],
                [29.437885285000107, 4.523867131000145],
                [29.43914794900013, 4.531662940999979],
                [29.440750122000111, 4.541573048000089],
                [29.440120697000168, 4.54843664200007],
                [29.450300217000063, 4.564544679000164],
                [29.460538864000171, 4.581301688999986],
                [29.463918685000124, 4.586833001000059],
                [29.469116211000085, 4.596576690000063],
                [29.471748352000191, 4.595562935000146],
                [29.471645355000135, 4.601320267000062],
                [29.475160599000162, 4.60790920400018],
                [29.468488694000143, 4.638525486000106],
                [29.475528717000088, 4.66739368399999],
                [29.475772858000141, 4.66761827400012],
                [29.477785110000184, 4.667489053000168],
                [29.480312348000041, 4.668954849000158],
                [29.484460830000046, 4.671360970000023],
                [29.487392425000053, 4.675944805000086],
                [29.491092681000168, 4.681729793000045],
                [29.494527817000176, 4.684894561000021],
                [29.502616883000144, 4.685307026000146],
                [29.520910263000076, 4.666532041000039],
                [29.522401810000133, 4.661563873000034],
                [29.524509428999977, 4.654539109000041],
                [29.527090073000181, 4.653966904000185],
                [29.53092384200005, 4.653117180000038],
                [29.549360276000129, 4.666351795000026],
                [29.551965713000129, 4.666182995000099],
                [29.553260804000104, 4.666099073000112],
                [29.553514481000036, 4.665494920000128],
                [29.555971146000161, 4.659635067000124],
                [29.558950423000056, 4.658926010000187],
                [29.568607330000134, 4.662549971999965],
                [29.571533203000172, 4.662396907000186],
                [29.573650360000102, 4.662285803999964],
                [29.583711623000113, 4.656237126000065],
                [29.584201814000096, 4.65566778200008],
                [29.590089797000076, 4.648831844000029],
                [29.595361709000031, 4.648108960000116],
                [29.601568222000083, 4.651061058000096],
                [29.611038209000185, 4.660898209000038],
                [29.614715576000037, 4.660874843000045],
                [29.62284278900006, 4.65306186700019],
                [29.623153687000013, 4.65276289000002],
                [29.632535934000146, 4.649480819000189],
                [29.640106201000094, 4.649433137000131],
                [29.647890091000193, 4.64685392399997],
                [29.660202027000139, 4.634803772],
                [29.672571182000127, 4.631731033000051],
                [29.680328368000175, 4.624775887000112],
                [29.692689895000058, 4.621013165000022],
                [29.699798583000074, 4.620505809000122],
                [29.708030701000155, 4.616309167],
                [29.709402085000079, 4.614389897000024],
                [29.713489532000153, 4.608679772000187],
                [29.715732575000061, 4.601297856000087],
                [29.719253540000125, 4.596612930000049],
                [29.724599838000188, 4.589498996000145],
                [29.733095167999977, 4.574696065000069],
                [29.733219147000057, 4.574481965000075],
                [29.739160538000192, 4.570759773000134],
                [29.74181175199999, 4.570742129999985],
                [29.744895936000091, 4.570721149000121],
                [29.748037338000131, 4.572274209000057],
                [29.749956131000033, 4.573221206000142],
                [29.756099701000153, 4.578084946000047],
                [29.762643813000182, 4.583265780999966],
                [29.76667594900016, 4.582915783000146],
                [29.768371581000167, 4.58276891800017],
                [29.771846772000174, 4.579238893000024],
                [29.775667191000025, 4.57535505300001],
                [29.776763917000153, 4.57378721200007],
                [29.784069062000185, 4.563330175000033],
                [29.788087845000064, 4.562392236000164],
                [29.7911796570001, 4.561670780000156],
                [29.807470321000096, 4.562944889000107],
                [29.811555861999977, 4.562211037000168],
                [29.814115525000147, 4.561750888000177],
                [29.818119050000178, 4.559782981000183],
                [29.820289612000181, 4.558716774000118],
                [29.823314665999987, 4.553924084000073],
                [29.824375153000119, 4.552244187999975],
                [29.824445724000157, 4.551725864000105],
                [29.824813843000129, 4.549018859],
                [29.821308137000187, 4.538696766000157],
                [29.820835114000033, 4.537306785000169],
                [29.815254211000024, 4.532086848000176],
                [29.808368682999969, 4.525647164000077],
                [29.797077179000098, 4.517664910000065],
                [29.79352378800013, 4.509838105000029],
                [29.793117523000035, 4.508943081000041],
                [29.794229508000114, 4.503871918000129],
                [29.801525115000061, 4.495872021000025],
                [29.805845260000183, 4.49113607400011],
                [29.806018830000141, 4.482847214000117],
                [29.805770874000075, 4.481626034000158],
                [29.80316925000011, 4.468822956000054],
                [29.801097870000149, 4.466255188000048],
                [29.800399780000134, 4.465388775000065],
                [29.798967362000099, 4.457341193000161],
                [29.798959732000128, 4.456093787000157],
                [29.798889161000091, 4.445830822000175],
                [29.795618058000059, 4.437107087000186],
                [29.796789170000125, 4.431801796000059],
                [29.798517227000048, 4.423964977000139],
                [29.795721053000136, 4.414391041000044],
                [29.795642852000185, 4.412508965000086],
                [29.795419693999975, 4.407179833000043],
                [29.798553466000101, 4.395191193000017],
                [29.796447754000155, 4.388989925000146],
                [29.795684814000083, 4.384298801000114],
                [29.795251846000156, 4.381629943000121],
                [29.795686721000152, 4.379777909000154],
                [29.796293260000141, 4.377170086000149],
                [29.798370362000071, 4.368258954000169],
                [29.80161857700017, 4.36559915600003],
                [29.806350708000082, 4.361723423000058],
                [29.806589127000166, 4.361528874000044],
                [29.814620971000011, 4.35878086200006],
                [29.816194535000193, 4.35824203500016],
                [29.81851387100005, 4.356776238000123],
                [29.822818755000128, 4.354054927999982],
                [29.831230163999976, 4.342719077000083],
                [29.835109711000086, 4.339942932000156],
                [29.83967018200002, 4.33667898200008],
                [29.850458145000118, 4.337296963000142],
                [29.852197647000139, 4.337751867000122],
                [29.85988044700008, 4.339766979000046],
                [29.859872819000145, 4.338941098000021],
                [29.859870910999973, 4.338843823000048],
                [29.87684249900019, 4.338961124000093],
                [29.879785536999975, 4.34064483700007],
                [29.885591507000072, 4.343967915000178],
                [29.893178940000155, 4.346220016000132],
                [29.899366379000071, 4.344466209000018],
                [29.902332306000062, 4.34362602200008],
                [29.916652680000141, 4.333174230000168],
                [29.941810607999969, 4.314813137000044],
                [29.946262360000105, 4.310162067000135],
                [29.950237274000187, 4.30600881700002],
                [29.961132049000184, 4.289591790000145],
                [29.963033676000066, 4.280067921000068],
                [29.963571548000175, 4.277373790000183],
                [29.961481095000124, 4.273474217000171],
                [29.952440262000039, 4.25995397600002],
                [29.951744079000036, 4.259957791000034],
                [29.951526640999987, 4.259959220999974],
                [29.950719834000154, 4.25514078100008],
                [29.950332641000159, 4.252831935000188],
                [29.946567536000089, 4.246084214000121],
                [29.944063187000097, 4.241593838000028],
                [29.945842743000071, 4.238390923000111],
                [29.947914124000135, 4.234660148999978],
                [29.952470780000056, 4.233304025000052],
                [29.956611632000033, 4.232070922000105],
                [29.965253830000108, 4.231482030000052],
                [29.971265794000146, 4.231072426000083],
                [29.975404740000101, 4.230790139000078],
                [29.980966568000042, 4.228816032000054],
                [29.987302780000107, 4.226566792000142],
                [29.998212814000055, 4.217203140000038],
                [29.998853684000153, 4.216652870000189],
                [30.000001908000115, 4.215668201000142],
                [30.00688743600017, 4.21081209100015],
                [30.026731491000078, 4.196822166000175],
                [30.029937743000175, 4.192512036000096],
                [30.038740158000053, 4.157141209000145],
                [30.048995971000181, 4.147275923999985],
                [30.057218552000052, 4.139366151000047],
                [30.057249070000125, 4.139356137000107],
                [30.092432022000082, 4.127754212000184],
                [30.108470916000044, 4.117775917000131],
                [30.123249053, 4.113232613000093],
                [30.12855148400007, 4.111602784000183],
                [30.13150215200011, 4.111048221000033],
                [30.148050308000109, 4.103503228000079],
                [30.151317596000069, 4.100715160000107],
                [30.155679703000146, 4.096993923000127],
                [30.156337739000037, 4.095612050000113],
                [30.156444549000184, 4.09538698200015],
                [30.15699005200014, 4.094252109000138],
                [30.157730102000073, 4.092710972000077],
                [30.158586502000048, 4.083014965000132],
                [30.158847809000065, 4.080070972000158],
                [30.158489227000075, 4.0777311330001],
                [30.15741538900005, 4.070696830000088],
                [30.158557893000079, 4.055020809000041],
                [30.162784576000149, 4.050202847000037],
                [30.166706085000158, 4.045731068000123],
                [30.170997620000037, 4.045263768000041],
                [30.171379089000084, 4.044976235000149],
                [30.173856735000072, 4.043099403999975],
                [30.173332215000187, 4.030351161999988],
                [30.186376570999983, 4.018109799000115],
                [30.190895080000018, 4.015497684000081],
                [30.195718764000162, 3.988914728000111],
                [30.197490692000088, 3.97143340100007],
                [30.201496125000119, 3.967421771000147],
                [30.201950072999978, 3.963819027000113],
                [30.202899933000083, 3.960769891999973],
                [30.20454216000013, 3.95549392700002],
                [30.205535889000032, 3.954187871000102],
                [30.209129333000192, 3.949462892000042],
                [30.210796356000174, 3.947974920000036],
                [30.222600936000049, 3.937439919000099],
                [30.244010925000055, 3.944571973000109],
                [30.245220184, 3.944974899000044],
                [30.252521516000172, 3.94936204000004],
                [30.264030457000047, 3.95627498600004],
                [30.264749526000116, 3.956281901000182],
                [30.266813278000143, 3.956300020000185],
                [30.273269653000114, 3.956357002000118],
                [30.273445129000095, 3.956358910000063],
                [30.273609161000138, 3.95636010200019],
                [30.279930115000184, 3.953886987000089],
                [30.281219483000086, 3.952886105000061],
                [30.284019469000157, 3.950710058000027],
                [30.284757614, 3.950133086000108],
                [30.296707153999989, 3.934426785000028],
                [30.29928779700009, 3.931034088000104],
                [30.303329467000026, 3.930309057000045],
                [30.305524827000113, 3.930604936000123],
                [30.315410615000076, 3.931934118000129],
                [30.315685273000042, 3.931911945000138],
                [30.317226410000103, 3.931787968000094],
                [30.320940017000169, 3.931488992000084],
                [30.335840225000027, 3.919202804000122],
                [30.35189628500018, 3.912453413000037],
                [30.37410163800007, 3.897838115000127],
                [30.379037857000185, 3.895164728000168],
                [30.403829574000042, 3.866200685000081],
                [30.403865815000131, 3.866201640000043],
                [30.415464401, 3.870601891999968],
                [30.425342559000057, 3.871070861000135],
                [30.430278778000172, 3.870982647000119],
                [30.44559288, 3.838390827000183],
                [30.452981949000048, 3.831148625000083],
                [30.471563340000159, 3.826940776000186],
                [30.49885749900011, 3.84132170700002],
                [30.516202927000165, 3.845537424000042],
                [30.526088715000071, 3.848584175000156],
                [30.553178787000149, 3.829169990000082],
                [30.555677414000172, 3.827379704000123],
                [30.571466446000045, 3.765041113000109],
                [30.573999405000109, 3.745802879000109],
                [30.584920884000041, 3.68023800900005],
                [30.584020615000099, 3.676553011000181],
                [30.581752778000123, 3.667308093000145],
                [30.576799393000158, 3.656893015000094],
                [30.575389863000055, 3.6451909540001],
                [30.574907303000089, 3.641191959000025],
                [30.573396682000066, 3.637726069000166],
                [30.568424224000069, 3.626319884999987],
                [30.567489625000064, 3.62417793200018],
                [30.556827545000033, 3.607223035000175],
                [30.55629158, 3.60637307200011],
                [30.556859969000072, 3.598788022000122],
                [30.557907104000094, 3.597158908000154],
                [30.558639525000046, 3.59601903000015],
                [30.55871009800012, 3.595982551000077],
                [30.558961868000154, 3.595997811000075],
                [30.562959670000168, 3.593998910000153],
                [30.570304872000065, 3.592530251000028],
                [30.573289872000146, 3.592094898000028],
                [30.591920852000158, 3.5942759510001],
                [30.595558167000092, 3.595778466000127],
                [30.59636688300003, 3.596112967000181],
                [30.602859497000054, 3.598794700000099],
                [30.622560501000123, 3.606931926000186],
                [30.623735428000089, 3.60762810600005],
                [30.625587463000102, 3.608725548000052],
                [30.631999970000038, 3.612999916000035],
                [30.638599395000085, 3.617949963000058],
                [30.64399910000003, 3.621999980000055],
                [30.666999818000079, 3.63600015600008],
                [30.673381805000133, 3.636911631000089],
                [30.673971177000055, 3.63699579200005],
                [30.674129487000187, 3.636959315000127],
                [30.68685340799999, 3.634023190000107],
                [30.69534873900011, 3.628854036000178],
                [30.697492599000043, 3.627547025000126],
                [30.700330735000193, 3.626632929000152],
                [30.708620071000041, 3.623965979000104],
                [30.715330125000094, 3.621809961000054],
                [30.71896743800005, 3.620640994000098],
                [30.727525711000055, 3.621722937000129],
                [30.727769851000176, 3.621813059000147],
                [30.729246139999987, 3.622356176000039],
                [30.734561921000079, 3.624310018000074],
                [30.741065979000041, 3.630435945000045],
                [30.743860245000121, 3.634577514000171],
                [30.746046067000123, 3.637815953000143],
                [30.746397018000152, 3.638920070000097],
                [30.754989624000189, 3.666003704000161],
                [30.758598328000176, 3.669598103000112],
                [30.760999680000054, 3.671999932000119],
                [30.761394501000154, 3.672118426000168],
                [30.77132987900012, 3.674677373000066],
                [30.777641297000059, 3.66926932400014],
                [30.777956010000082, 3.668998957000099],
                [30.783954621000191, 3.656998872000088],
                [30.785049438999977, 3.652074576000132],
                [30.785955429000182, 3.647998810000047],
                [30.785955429000182, 3.646999121000022],
                [30.785955429000182, 3.640330076000055],
                [30.785955429000182, 3.620998860000043],
                [30.79026412900015, 3.605487108000148],
                [30.790893554000093, 3.603365899000096],
                [30.790922165000097, 3.603269100000148],
                [30.794328689000054, 3.59807801400018],
                [30.799339294000106, 3.590437888000054],
                [30.807212829999969, 3.583417892000057],
                [30.813934326000151, 3.580107927000029],
                [30.814283372000034, 3.579914808000126],
                [30.856952667000087, 3.568999051000162],
                [30.859952927, 3.565999031000047],
                [30.861351013000046, 3.56390381000017],
                [30.862041473000147, 3.563117981000062],
                [30.862070084000152, 3.55975294100017],
                [30.862119674000155, 3.554009914000119],
                [30.860158919000185, 3.546658039000135],
                [30.856760025000142, 3.539012909000121],
                [30.850000381000029, 3.523804903000155],
                [30.846090317000176, 3.5083410740001],
                [30.846750259000089, 3.503015042000129],
                [30.847436905000052, 3.497472047000088],
                [30.852668763000054, 3.488016367],
                [30.853569030000187, 3.486391068000103],
                [30.853799819000187, 3.486241818000167],
                [30.860021590000088, 3.48220682200008],
                [30.861953736000089, 3.480998993000071],
                [30.864782333000107, 3.480999470000029],
                [30.867717743000128, 3.481445074000135],
                [30.870231629000159, 3.482681989000071],
                [30.870365143000186, 3.482576132000133],
                [30.871315002000131, 3.482381105000059],
                [30.872436524000022, 3.482573034000041],
                [30.873313903000053, 3.482837916000108],
                [30.874231339000119, 3.483174087000123],
                [30.875230788000124, 3.483763934000137],
                [30.875860215000102, 3.484426021000161],
                [30.876380920000088, 3.485014916000125],
                [30.876848221000103, 3.485214950000113],
                [30.877584458, 3.484519958000021],
                [30.878360747000045, 3.484178066000141],
                [30.878665924000131, 3.485119105000081],
                [30.878843307000182, 3.487045051000109],
                [30.879053117000126, 3.488437891000103],
                [30.879104614000028, 3.488772154000117],
                [30.896999358000073, 3.501999854000189],
                [30.901193619000082, 3.503048181000111],
                [30.903099059000169, 3.503524781000124],
                [30.903495788000043, 3.503701926000133],
                [30.904661179000129, 3.504373073000124],
                [30.905828477000057, 3.50504493700015],
                [30.90694618200007, 3.50571703900016],
                [30.907707215000073, 3.506242990000033],
                [30.908468246000041, 3.506696939000051],
                [30.909490586000118, 3.507323026000051],
                [30.90982437100007, 3.507575988000042],
                [30.910490035000123, 3.50809407200012],
                [30.911632539000152, 3.508738042000061],
                [30.912656784000035, 3.508812904000081],
                [30.913583755000104, 3.508697033000146],
                [30.914869308000164, 3.508527995],
                [30.916131972000187, 3.508531093000102],
                [30.917608261000055, 3.508435011000131],
                [30.918561936000174, 3.508464097000058],
                [30.919775009999967, 3.508512019000136],
                [30.920988082000179, 3.50855994200009],
                [30.921916961000193, 3.508733987000028],
                [30.922250749000114, 3.508934021000073],
                [30.922750472000132, 3.508835077000015],
                [30.923276902000168, 3.508619069000076],
                [30.92370414700008, 3.50850296100009],
                [30.924205780000136, 3.508450031000166],
                [30.924728393000123, 3.508505105999973],
                [30.925466537000091, 3.508641958000112],
                [30.926013947000058, 3.508671046000075],
                [30.926322937000066, 3.508717061000027],
                [30.927358628000036, 3.508800030000089],
                [30.928001403000053, 3.508757116000083],
                [30.928192138999975, 3.508729935000019],
                [30.928298950000055, 3.508495093000136],
                [30.928821564000145, 3.508215905000157],
                [30.929941177000103, 3.508045912],
                [30.930940628000144, 3.508166075000077],
                [30.931798936000064, 3.508620024000095],
                [30.932868957000096, 3.509174108000082],
                [30.93386840800008, 3.509421110000119],
                [30.934797287000094, 3.509557962999963],
                [30.935865402000104, 3.509505988000058],
                [30.935951233000026, 3.509506941000041],
                [30.93688011200004, 3.509102107000103],
                [30.93742942800003, 3.508234979000122],
                [30.937835694000057, 3.507258893000028],
                [30.938289643000076, 3.506346941000061],
                [30.938789369000176, 3.505172968000181],
                [30.939243317000091, 3.504215003000013],
                [30.939638138000134, 3.50342989100011],
                [30.939958573000126, 3.502463103000025],
                [30.93998909000004, 3.501857042000097],
                [30.940000533000045, 3.501621961000069],
                [30.938886643000046, 3.500353097000073],
                [30.938358306000111, 3.499701024000046],
                [30.938007355000138, 3.499113084000044],
                [30.937715531000151, 3.49835205100004],
                [30.937423707000164, 3.497112990000119],
                [30.937423707000164, 3.495929002000025],
                [30.937658311000064, 3.495223999000075],
                [30.937953949000189, 3.494518996000068],
                [30.938110352000024, 3.494241953000142],
                [30.938423157000102, 3.493688106999969],
                [30.938835144000166, 3.492984057000172],
                [30.939128875000051, 3.492099048000057],
                [30.939422608000086, 3.491040946000055],
                [30.939924240000039, 3.48941493000018],
                [30.940277099000184, 3.488358020000135],
                [30.940368653000121, 3.487860919000127],
                [30.940454484000043, 3.487409115000048],
                [30.940689088000056, 3.486288070000114],
                [30.9410438540001, 3.485230923000131],
                [30.941337586000088, 3.484164953],
                [30.941572189000055, 3.482809067000119],
                [30.941633225000146, 3.481806040000151],
                [30.941713333000166, 3.481583595000132],
                [30.941726684000116, 3.481458664],
                [30.941867828000056, 3.480196953000188],
                [30.941835404000074, 3.477195024000025],
                [30.94192123300013, 3.474555016000068],
                [30.942003250000084, 3.473351956000045],
                [30.942565918000128, 3.471518041000081],
                [30.942804337000041, 3.470676899000125],
                [30.942888261000064, 3.469593048000036],
                [30.942987443000106, 3.468743086000075],
                [30.942455291000101, 3.466986896000037],
                [30.941303254000104, 3.465547086000129],
                [30.940328598000065, 3.465003013000114],
                [30.938735961000191, 3.46443891600012],
                [30.937423707000164, 3.46339607300007],
                [30.936666489000061, 3.46275210500005],
                [30.936668397, 3.461992979000115],
                [30.93683052, 3.460628032999978],
                [30.937273025000138, 3.459191083000121],
                [30.937236786000085, 3.457988024000144],
                [30.937099457000159, 3.456929921999972],
                [30.936862946000019, 3.455446958000039],
                [30.936838150000142, 3.455338002000076],
                [30.936389923999968, 3.453239917000133],
                [30.936164856000175, 3.451838016000011],
                [30.936086655000054, 3.45143103700002],
                [30.936086655000054, 3.450544118000096],
                [30.936086655000054, 3.449722052000084],
                [30.935913086000141, 3.448311091000164],
                [30.935562135000168, 3.446600913000054],
                [30.935092926000152, 3.445242882000059],
                [30.934976578000089, 3.444356918000096],
                [30.93515396100014, 3.443236113000182],
                [30.935251236000113, 3.4421970840001],
                [30.935331344000133, 3.441346884000097],
                [30.935743331000026, 3.439810992000048],
                [30.935920715000179, 3.438571930000023],
                [30.936157226000091, 3.437098979999973],
                [30.936275481, 3.43580603700002],
                [30.93633461100012, 3.434856893000188],
                [30.936445236000111, 3.434305907000066],
                [30.936630250000178, 3.433383940999988],
                [30.936807633000171, 3.431737899000041],
                [30.93704414400014, 3.430147887000146],
                [30.937221528000066, 3.428909064000038],
                [30.937404632000096, 3.428630114000043],
                [30.937456131000033, 3.427906037000071],
                [30.937456131000033, 3.426956893000067],
                [30.937574387000041, 3.426134109000032],
                [30.93757629400011, 3.42531204300002],
                [30.937635422000028, 3.42430806100009],
                [30.93757629400011, 3.423656941000047],
                [30.937578202000054, 3.423006058000112],
                [30.937696457000186, 3.421885015000157],
                [30.937749863000136, 3.420700074000081],
                [30.937755585000104, 3.420528889000082],
                [30.937873841000112, 3.419706106000092],
                [30.937934876000099, 3.41876602100001],
                [30.937757493000049, 3.41776204199999],
                [30.937759400000118, 3.416811942000095],
                [30.937759400000118, 3.415755033000153],
                [30.937820434000059, 3.414869071000169],
                [30.937879563000024, 3.41369295100003],
                [30.937879563000024, 3.41313290700009],
                [30.937881469000047, 3.412689924000119],
                [30.937971115000153, 3.410592079000139],
                [30.937971115000153, 3.409533979000173],
                [30.937913895000065, 3.408593892000113],
                [30.937973023000154, 3.407473087000028],
                [30.938032151000073, 3.406703949000132],
                [30.938032151000073, 3.405582906],
                [30.937931061000029, 3.4051671040001],
                [30.937799455000174, 3.404642105000107],
                [30.937271118000069, 3.404007912000111],
                [30.93715477, 3.403872013000125],
                [30.936609269000087, 3.403021098000181],
                [30.936510087000045, 3.402867079000146],
                [30.935934067000176, 3.402465582000048],
                [30.935174942000174, 3.401869059000148],
                [30.934516906000113, 3.40133500200011],
                [30.93375396800019, 3.400862932000109],
                [30.93305015500016, 3.400209904000064],
                [30.932523728000035, 3.399441003000163],
                [30.931701659000112, 3.398850918000107],
                [30.930646897000088, 3.397972107000101],
                [30.929649353000116, 3.3971381180001],
                [30.928535460999967, 3.396311999000091],
                [30.9275398260001, 3.395370007000111],
                [30.926601410000103, 3.394364118000112],
                [30.925897597000017, 3.393539907000047],
                [30.925823211000079, 3.393450022000138],
                [30.925018311000144, 3.392479897000101],
                [30.924079895000091, 3.391654968000125],
                [30.923200608000116, 3.391237021000109],
                [30.922321320000151, 3.391063928000051],
                [30.92108726400005, 3.390645028000051],
                [30.92026710600004, 3.390290977000177],
                [30.919387817000029, 3.389584064000189],
                [30.918684006000149, 3.388695955000173],
                [30.918039322000084, 3.387691020000091],
                [30.917631150000034, 3.386696101000098],
                [30.917337418000102, 3.385808945000065],
                [30.916986465000093, 3.385039091000124],
                [30.916986465000093, 3.384217025000112],
                [30.916988372000162, 3.382859946],
                [30.917047500000081, 3.38167595800013],
                [30.917108535000068, 3.380501033000144],
                [30.917375565000157, 3.380203009000184],
                [30.917436600000144, 3.379780054000094],
                [30.917560577000188, 3.378920079000068],
                [30.918203354000127, 3.37743806900005],
                [30.918308259000014, 3.376661063000029],
                [30.918388368000024, 3.376055002000101],
                [30.918298721000156, 3.374490977000107],
                [30.918022156000063, 3.373106957000118],
                [30.91729927200015, 3.372327090000169],
                [30.917230606000146, 3.372354031000043],
                [30.916599274000134, 3.371973038000022],
                [30.916053771000179, 3.371546984000133],
                [30.915550231999987, 3.371084929000176],
                [30.915088653000112, 3.370784998000147],
                [30.914710998000032, 3.370486020999977],
                [30.914165497000113, 3.370151043000021],
                [30.913450241000078, 3.369895936000091],
                [30.912946700000077, 3.369388103000119],
                [30.912401200000033, 3.369225025000105],
                [30.912258148000092, 3.369131803000016],
                [30.912191390000089, 3.369088888000135],
                [30.911939621000158, 3.368925096000055],
                [30.911729813000193, 3.368752958000073],
                [30.911352158000113, 3.368208885000058],
                [30.910974503000034, 3.367403983999964],
                [30.910722733000171, 3.366517068000121],
                [30.910472869000102, 3.365631104000045],
                [30.910453796000013, 3.365383387000065],
                [30.907674788000122, 3.361950159],
                [30.893953323000176, 3.344999075000089],
                [30.889951705000044, 3.339998960000173],
                [30.889087677000134, 3.33712124800013],
                [30.886951447000115, 3.329998969000144],
                [30.886951447000115, 3.321784734000175],
                [30.886951447000115, 3.316556454000136],
                [30.886951447000115, 3.315999031000104],
                [30.884952546000193, 3.31099891700012],
                [30.882207870000116, 3.30500722000005],
                [30.88151168700017, 3.305331946000081],
                [30.881032945000129, 3.305330991000119],
                [30.880147933000103, 3.305881024000087],
                [30.87966918900014, 3.306015015000071],
                [30.879259109000088, 3.306222915000035],
                [30.878236771000104, 3.30690789200014],
                [30.877828597000075, 3.307041884000171],
                [30.877418518000127, 3.307177067000112],
                [30.876873017000037, 3.307384014999968],
                [30.876403808000191, 3.307600022000031],
                [30.876123428000085, 3.307725906000144],
                [30.876041413000166, 3.307601930000146],
                [30.875715256000092, 3.307110072000171],
                [30.875032426000075, 3.306421995000164],
                [30.874624252000046, 3.305805921000172],
                [30.874284744000192, 3.305118084000185],
                [30.873945235000065, 3.304023027000085],
                [30.8736724850001, 3.303128005000076],
                [30.873537064000175, 3.302241086000151],
                [30.873674393000044, 3.301346064000029],
                [30.873674393000044, 3.300523996000038],
                [30.873743058000173, 3.299638034000054],
                [30.873949050000135, 3.298743010000123],
                [30.874290466000161, 3.298055886999975],
                [30.874633790000132, 3.297171116000072],
                [30.874904633000085, 3.296483994000084],
                [30.875179291000052, 3.295734882999966],
                [30.875223160000019, 3.295382022000183],
                [30.875247956000123, 3.295182943000157],
                [30.874975204000123, 3.294838906],
                [30.874610901000096, 3.294431925000026],
                [30.874429703000033, 3.294223070000044],
                [30.87381744400011, 3.293534993000037],
                [30.872930527000165, 3.292989970000178],
                [30.871704102000024, 3.292093038000189],
                [30.870819091000044, 3.291548015000103],
                [30.869865417000028, 3.290796042000181],
                [30.869321823000178, 3.290314912000156],
                [30.868707657000186, 3.289971114000139],
                [30.868299483000158, 3.289210081000135],
                [30.867549896000185, 3.288187027000049],
                [30.866937637000092, 3.28722691500019],
                [30.866460800000141, 3.286330938000049],
                [30.866870881000068, 3.285173894000025],
                [30.867553711000085, 3.284343958000136],
                [30.868440627000098, 3.283459901000072],
                [30.868755340000121, 3.283062935000089],
                [30.86898613, 3.282773971000154],
                [30.869327546000193, 3.282295942000133],
                [30.870077134000041, 3.28174591100003],
                [30.870555879000165, 3.281266928000093],
                [30.870706558000109, 3.280780078000134],
                [30.869562150000093, 3.279746057000068],
                [30.869337082000129, 3.279595375000042],
                [30.868032456000151, 3.278721094000105],
                [30.867084504000047, 3.277832985000089],
                [30.866283416000044, 3.277098895000108],
                [30.865188600000067, 3.276446105000105],
                [30.863876343000072, 3.276298046000022],
                [30.861614227000132, 3.276077031999989],
                [30.85920715200001, 3.275701047000098],
                [30.858259200000134, 3.275481940000134],
                [30.856290818000161, 3.275187969000058],
                [30.854394912000146, 3.274379015000136],
                [30.852733611000076, 3.273679973000185],
                [30.852645875000121, 3.273643016000051],
                [30.852207184000122, 3.273425103000079],
                [30.851625442000113, 3.272908927000174],
                [30.850093842000035, 3.272326947000124],
                [30.848707200000092, 3.27136492700015],
                [30.847030639000025, 3.270484925000119],
                [30.845937728000081, 3.269975901000123],
                [30.844331742000065, 3.270117046000166],
                [30.842872619000048, 3.270339966000165],
                [30.841997145999983, 3.270040035000079],
                [30.841777801000035, 3.26976799900018],
                [30.841342925000163, 3.269233942000085],
                [30.839813233000029, 3.266518117000032],
                [30.838283539000145, 3.263585090000106],
                [30.837921142000084, 3.259696960000099],
                [30.837852479000162, 3.258620977000135],
                [30.837196351000046, 3.256032943000037],
                [30.836879731000181, 3.254568100000086],
                [30.836179733000165, 3.251338005999969],
                [30.83702278200019, 3.25010204300014],
                [30.836839676000125, 3.250101090000158],
                [30.837001801000156, 3.249712945000113],
                [30.837345123000091, 3.248809100000187],
                [30.837575913000023, 3.248259069000028],
                [30.837945938000189, 3.247337103000177],
                [30.838151933000063, 3.247083902000099],
                [30.838346482000077, 3.246931077000113],
                [30.838399887000151, 3.246823072000154],
                [30.838703156000122, 3.24619007199999],
                [30.839345932000185, 3.245404958000052],
                [30.839965820000145, 3.244601965000129],
                [30.840515136000135, 3.24393391600006],
                [30.84090805000011, 3.243608951000056],
                [30.841400147000058, 3.243058920000124],
                [30.841476441000168, 3.242073059000177],
                [30.841400147000058, 3.240780116000053],
                [30.841098786000089, 3.239404917],
                [30.840114593000123, 3.23803806300009],
                [30.839206695000144, 3.237132073000112],
                [30.838146210000048, 3.236749887000087],
                [30.837163924000151, 3.236593962000086],
                [30.835945130000027, 3.236057997000046],
                [30.835548401000153, 3.235614061000092],
                [30.835197449000077, 3.235234021000053],
                [30.834213256000055, 3.234164954000164],
                [30.83360862800015, 3.233331919000079],
                [30.83270073000017, 3.23241710700006],
                [30.831905365000182, 3.231167078000112],
                [30.831073761000084, 3.229790926000078],
                [30.830240250000145, 3.228504896000118],
                [30.829711915000019, 3.227365017000182],
                [30.829559325000048, 3.226452112000061],
                [30.829256058000055, 3.225311994000037],
                [30.828954697000086, 3.224172115000158],
                [30.828727722000053, 3.223031997000135],
                [30.828424454000185, 3.222045898000033],
                [30.827894210000181, 3.221131088000163],
                [30.827365875000055, 3.22006392500009],
                [30.826986312000031, 3.219302892000087],
                [30.826852798000175, 3.218733073000067],
                [30.826684951000061, 3.218019009000159],
                [30.826745986000049, 3.217576026000188],
                [30.826835633000087, 3.216952085000173],
                [30.827136994000057, 3.215506077000043],
                [30.827136994000057, 3.214963914000066],
                [30.827136994000057, 3.214592934000109],
                [30.827514649000136, 3.213228940000022],
                [30.827817916000072, 3.21247005500004],
                [30.828044892000037, 3.211402894000116],
                [30.828346253000177, 3.210336924000046],
                [30.828725814000052, 3.209044934000076],
                [30.828952789000084, 3.207834005000166],
                [30.829404831000033, 3.206696034000061],
                [30.829858779000119, 3.205394983000133],
                [30.829706192000174, 3.204489946000138],
                [30.829027175000022, 3.203576088000034],
                [30.828042984000035, 3.202742101000183],
                [30.827610016000108, 3.20223403000017],
                [30.827474595000183, 3.20208096500005],
                [30.827058793000049, 3.201601028000027],
                [30.826152801000092, 3.200531960000035],
                [30.825395585000138, 3.199390889000028],
                [30.824562073000095, 3.198259116000088],
                [30.82410812500018, 3.197263002000113],
                [30.823654174000183, 3.19582510000015],
                [30.823125839000113, 3.194376946000034],
                [30.823070526000038, 3.193734884000037],
                [30.823049545000174, 3.193464041000084],
                [30.822820664000176, 3.192874909000182],
                [30.822671890000095, 3.192477942000096],
                [30.822368621000123, 3.191265106000117],
                [30.821989060000078, 3.18997192500018],
                [30.821460723000143, 3.188831091999987],
                [30.820854187000066, 3.187916994000034],
                [30.820173263000015, 3.186929940000084],
                [30.819492341000171, 3.185563088000151],
                [30.819448471000101, 3.184983969000086],
                [30.819227219000084, 3.185065030000146],
                [30.818925857000011, 3.184458972000073],
                [30.818395615000043, 3.183471918000123],
                [30.817865372000142, 3.182332040000176],
                [30.817411423000124, 3.181571961000032],
                [30.81673049900013, 3.180277109000031],
                [30.816125871000168, 3.1789920330001],
                [30.815292359000125, 3.177850962000093],
                [30.81476402200019, 3.17693710400016],
                [30.814611435000131, 3.17602300700014],
                [30.814233780000052, 3.174810887000035],
                [30.814180375000149, 3.174024105000115],
                [30.814081192, 3.174051047000035],
                [30.813703537000151, 3.173064948000047],
                [30.813360214000056, 3.172024012000065],
                [30.813325882000072, 3.171925067000188],
                [30.81309890700004, 3.170631886000024],
                [30.812341691000086, 3.169337033999966],
                [30.811811446000149, 3.167663097000116],
                [30.811130524000134, 3.165844917000129],
                [30.810449600000084, 3.163790941],
                [30.810226440000122, 3.163183927999967],
                [30.810043336000035, 3.162687064000068],
                [30.809692382000151, 3.161736965000102],
                [30.809389115000045, 3.160597086000166],
                [30.809011460000136, 3.158850909000023],
                [30.808784484000171, 3.157937049000111],
                [30.808784484000171, 3.156725885000071],
                [30.80886077800011, 3.155577898000047],
                [30.808826446000069, 3.155314923000049],
                [30.808708191000164, 3.154366017000029],
                [30.808633805000056, 3.153227091000133],
                [30.808557511000117, 3.15208697300011],
                [30.808481217000065, 3.150794029000053],
                [30.808404922000193, 3.149121046000118],
                [30.80832862800014, 3.147981883000114],
                [30.80832862800014, 3.146616936000044],
                [30.808479310000166, 3.145550013000161],
                [30.80870628299999, 3.144565105000027],
                [30.809080123000058, 3.143870115000027],
                [30.809007645000065, 3.143192053000064],
                [30.809083938000072, 3.142657996000025],
                [30.808479310000166, 3.142050982000058],
                [30.807647706000068, 3.141443969000022],
                [30.80673980600011, 3.140609980000022],
                [30.805831909000062, 3.139694929000086],
                [30.805074693000108, 3.139014960000054],
                [30.804092407000155, 3.138253927000051],
                [30.803409576999968, 3.137419940000086],
                [30.802503585000181, 3.136658907000083],
                [30.802125930000102, 3.135673046000136],
                [30.801897049000047, 3.134758950000162],
                [30.801897049000047, 3.133918046000019],
                [30.801897049000047, 3.132860899000036],
                [30.802047729000094, 3.131567956000083],
                [30.802047729000094, 3.129894972000045],
                [30.802122117000067, 3.128150941000115],
                [30.802227020000089, 3.12704801600006],
                [30.802108764000081, 3.127038001000187],
                [30.801958084000034, 3.126739980000139],
                [30.801805497000146, 3.126313925000147],
                [30.801542282000128, 3.125933887000144],
                [30.801362992, 3.125401020000027],
                [30.801277160000041, 3.125408887000049],
                [30.801050187000158, 3.125065089000032],
                [30.800861358000077, 3.124613047000082],
                [30.800519942000108, 3.123888969000063],
                [30.800142289000178, 3.123236895000105],
                [30.799991607000152, 3.122901917000093],
                [30.799915314000145, 3.122332096000093],
                [30.799839020000036, 3.121953010000027],
                [30.799839020000036, 3.121490956000173],
                [30.799951553000028, 3.120660067000074],
                [30.799951553000028, 3.119664908000061],
                [30.799989700000083, 3.118870021000078],
                [30.800027847000138, 3.118145942000126],
                [30.799951553000028, 3.117125034000026],
                [30.800027847000138, 3.11613011500009],
                [30.800027847000138, 3.115416049000146],
                [30.800102235000054, 3.1145389080001],
                [30.800140382000109, 3.113744021000173],
                [30.800184249000097, 3.113508939999974],
                [30.800146102000042, 3.113390922000178],
                [30.800102235000054, 3.113245964999976],
                [30.799951553000028, 3.112559080000096],
                [30.799686433000147, 3.112106084000118],
                [30.799497604000067, 3.111572981000109],
                [30.79923248200015, 3.110739947000127],
                [30.799194335000095, 3.109791041000108],
                [30.799230576000184, 3.109220981000021],
                [30.799194335000095, 3.108606100000031],
                [30.799230576000184, 3.10796499200012],
                [30.799230576000184, 3.107430935000082],
                [30.79934310900012, 3.106672049000167],
                [30.799306870000066, 3.10632801100013],
                [30.799306870000066, 3.105649949000167],
                [30.799381256000174, 3.104773045000059],
                [30.799449920000029, 3.104489087000047],
                [30.799163818000125, 3.103777886000046],
                [30.798973084000181, 3.103362084000139],
                [30.798671723000041, 3.102900028000079],
                [30.79836845400007, 3.102529050000101],
                [30.798141480000083, 3.102066994000097],
                [30.797952652000106, 3.101650954000093],
                [30.797752380000077, 3.101351977000149],
                [30.797651290000033, 3.101197958000114],
                [30.797309876000043, 3.100663900000143],
                [30.797044754000126, 3.100130081000145],
                [30.796855926000148, 3.09971404099997],
                [30.796413422000114, 3.099198104000038],
                [30.795986175000053, 3.098834991999979],
                [30.795532226000034, 3.098345994000056],
                [30.79534339900016, 3.098083019000057],
                [30.795003891000135, 3.097657919000028],
                [30.794662475000109, 3.097321986000054],
                [30.794321059000083, 3.096824885000046],
                [30.793811798000149, 3.095855952000079],
                [30.793546678000098, 3.095439911000028],
                [30.793508529, 3.09494209200011],
                [30.79347038200018, 3.094563007000147],
                [30.793432235000125, 3.093883992000144],
                [30.79339408800007, 3.093314886000144],
                [30.793432235000125, 3.092664003000039],
                [30.793506623000042, 3.092165947000012],
                [30.793508529, 3.091677904000051],
                [30.79347038200018, 3.091181041000084],
                [30.793468474000179, 3.090457917000094],
                [30.793506623000042, 3.090158940000151],
                [30.793468474000179, 3.089545012000144],
                [30.793506623000042, 3.088784934000159],
                [30.793544770000096, 3.088296891000027],
                [30.793582917000151, 3.08726692200014],
                [30.793582917000151, 3.086281061000022],
                [30.793619156000034, 3.085485936000055],
                [30.793657303000089, 3.084952116000125],
                [30.793695450000143, 3.084002972000064],
                [30.793657303000089, 3.083278895000149],
                [30.793712616000107, 3.082936049000182],
                [30.793733596999971, 3.082818983999971],
                [30.793733596999971, 3.082438945999968],
                [30.793754578000062, 3.082158088000028],
                [30.793676376000121, 3.082122089000166],
                [30.793676376000121, 3.081851006000022],
                [30.793600083000115, 3.081244945000037],
                [30.793561936000117, 3.080748082000071],
                [30.793561936000117, 3.080332040000087],
                [30.793523789000062, 3.079843045000075],
                [30.793485642000178, 3.079345941000156],
                [30.793409347000193, 3.078695059000097],
                [30.793409347000193, 3.078207016000135],
                [30.793258667000146, 3.077708960000166],
                [30.793258667000146, 3.077316046000135],
                [30.793258667000146, 3.076687097000047],
                [30.793220520000091, 3.076153994000094],
                [30.793144226000152, 3.075583935000111],
                [30.793220520000091, 3.07512402600014],
                [30.793409347000193, 3.074481964000142],
                [30.793432235000125, 3.074399947000188],
                [30.793560028000115, 3.073985100000073],
                [30.793733596999971, 3.073642016000065],
                [30.793787002000045, 3.073153974000036],
                [30.794013977000077, 3.072729111000115],
                [30.794164657000124, 3.072205067000141],
                [30.794277192000095, 3.071897985000135],
                [30.794353485000102, 3.071517945000096],
                [30.794429779000041, 3.071103095000069],
                [30.794467926000095, 3.070723057000066],
                [30.794429779000041, 3.070297957000037],
                [30.794391632000156, 3.069845916000077],
                [30.794391632000156, 3.069394112000168],
                [30.794126510000069, 3.068932056000108],
                [30.79393768400007, 3.068552018000105],
                [30.793596268000101, 3.067981959000122],
                [30.793294906000028, 3.067564964000155],
                [30.793029786000091, 3.067030907000117],
                [30.792650223000066, 3.066505910000103],
                [30.792310715000042, 3.06589102800001],
                [30.792007446000071, 3.065402031000133],
                [30.791704179000135, 3.064985036000053],
                [30.791326524000056, 3.06460499800005],
                [30.791025162000153, 3.064188004000187],
                [30.790760040000066, 3.063889027000187],
                [30.79045677300013, 3.063662052000154],
                [30.790155411000057, 3.063580990000048],
                [30.789701462000039, 3.063580037000065],
                [30.789360047000173, 3.06357908300015],
                [30.788982391000161, 3.063694953000152],
                [30.788528443000075, 3.063730955000096],
                [30.788387299000135, 3.063750028000186],
                [30.788188934000175, 3.063775062000104],
                [30.787809372000027, 3.063847065000118],
                [30.787395477000132, 3.063999892000027],
                [30.786979675, 3.064080001000036],
                [30.786563873000091, 3.064116],
                [30.786222457000065, 3.064078093000035],
                [30.785844802000156, 3.063997030000166],
                [30.78524017400008, 3.063958883000112],
                [30.784898758000054, 3.063885927000115],
                [30.784370423000155, 3.063694953000152],
                [30.783840180000027, 3.063585998000065],
                [30.783235550000086, 3.06331300800008],
                [30.782857895000063, 3.06301403100008],
                [30.782478332000153, 3.062751056000082],
                [30.782100677000074, 3.062406063000083],
                [30.781721116000028, 3.062098026000058],
                [30.781192779000094, 3.061871052000129],
                [30.780927659000042, 3.061717033000093],
                [30.780588150000085, 3.061644077000096],
                [30.780096055000115, 3.061299086000076],
                [30.77960395700012, 3.06107211200009],
                [30.779188155000156, 3.06088209100011],
                [30.778810502000113, 3.060735942000179],
                [30.778318404000061, 3.060653925000054],
                [30.777902602000097, 3.060616971000172],
                [30.777561189000039, 3.06061601600004],
                [30.777145387000076, 3.06065988600011],
                [30.776617050000141, 3.060731889000124],
                [30.776239394000186, 3.060730934000105],
                [30.775859834000187, 3.060539960000142],
                [30.775482179000107, 3.060276985000144],
                [30.775217056000145, 3.060086966000142],
                [30.774953842, 3.059977054000115],
                [30.774574279000149, 3.059597016000112],
                [30.77430915900004, 3.05940699700011],
                [30.774082183000132, 3.059026004000089],
                [30.773666381000169, 3.058681965000119],
                [30.773439408000115, 3.058337927000082],
                [30.773212433000083, 3.057921887000134],
                [30.773126603000094, 3.057542085000137],
                [30.772872924000126, 3.056972028000132],
                [30.772846223000158, 3.056854010000166],
                [30.772758484000121, 3.056473970000127],
                [30.772493361000102, 3.055948973000113],
                [30.772342682000158, 3.055416107000099],
                [30.772115708, 3.055108071000177],
                [30.771812439000087, 3.054881095000042],
                [30.771472931000062, 3.054727078000042],
                [30.771207809000146, 3.054428101000042],
                [30.770792007000182, 3.054236890000141],
                [30.770187378000173, 3.053855897000176],
                [30.769657135999978, 3.053556920000176],
                [30.769317627000078, 3.053437948000124],
                [30.7689380650001, 3.053283929999964],
                [30.768674850000082, 3.053020954999965],
                [30.768220901000063, 3.052567959000157],
                [30.76803016700012, 3.052187919000176],
                [30.768106461000059, 3.051845073000038],
                [30.768220901000063, 3.051311015000067],
                [30.768409729999973, 3.050741911000046],
                [30.768598556000143, 3.050209046000134],
                [30.768711089000135, 3.049485922000145],
                [30.768863679000162, 3.048917055000061],
                [30.768785477000108, 3.048274993000064],
                [30.76867294200008, 3.047434091000127],
                [30.768785477000108, 3.046792031000109],
                [30.7689380650001, 3.046104909000121],
                [30.769163132000131, 3.045500040000036],
                [30.769693375000088, 3.045003892000011],
                [30.770185471000104, 3.044543981000061],
                [30.770486832000074, 3.044163943000058],
                [30.771055221000097, 3.043822049000028],
                [30.771696091000024, 3.043443919000026],
                [30.772226334000095, 3.043065071000115],
                [30.772907258000146, 3.042758942000091],
                [30.773511886000108, 3.042381049000028],
                [30.774005890000126, 3.042083025000011],
                [30.774078368000062, 3.042038918],
                [30.774452210000106, 3.041696072000093],
                [30.774400711000169, 3.041640997000059],
                [30.774665833000086, 3.04145193100004],
                [30.774967194000055, 3.041028023000138],
                [30.775346755000101, 3.040575982000121],
                [30.775648117000173, 3.040234089000023],
                [30.77591323900009, 3.03973698600015],
                [30.776327133000052, 3.039284944000087],
                [30.776668549000078, 3.03871607800005],
                [30.777046204000101, 3.03821992900015],
                [30.777198792000149, 3.037995101000035],
                [30.777500153000119, 3.037533999000061],
                [30.777652741000111, 3.037190914000178],
                [30.777954102000081, 3.03684806800004],
                [30.778104782000128, 3.036468030000037],
                [30.778369904000044, 3.035710097000163],
                [30.778255464000154, 3.035176039000021],
                [30.778293610000105, 3.034533976999967],
                [30.778417586999979, 3.034190893000186],
                [30.778348923000181, 3.033956052000065],
                [30.77833175700016, 3.033883096000068],
                [30.777990341000191, 3.033584117000089],
                [30.777839662000076, 3.033168078000017],
                [30.777612686000111, 3.032824039000104],
                [30.777612686000111, 3.03236293700013],
                [30.777612686000111, 3.031982899000127],
                [30.777460099000166, 3.031269074000136],
                [30.77749824600005, 3.030843974000106],
                [30.777536392000172, 3.029894114000058],
                [30.777574539000057, 3.028908968000053],
                [30.777725219000104, 3.027770042000157],
                [30.777725219000104, 3.02746296000015],
                [30.777761458000157, 3.026928902000179],
                [30.778064727000128, 3.026478052000186],
                [30.778556823000145, 3.025791884000114],
                [30.778896331000169, 3.025485040000092],
                [30.779275894000023, 3.02510595200016],
                [30.779500962000043, 3.024763108000059],
                [30.779766082000094, 3.024465084999974],
                [30.780105591000051, 3.023660899000049],
                [30.780370713000138, 3.023327112000061],
                [30.780748368000047, 3.022938968000119],
                [30.781127931000071, 3.022641897000142],
                [30.781618119000143, 3.022299051000175],
                [30.782222747000048, 3.021956920000037],
                [30.782449723000184, 3.021686077000084],
                [30.782865525000147, 3.021343946000116],
                [30.783243180000056, 3.021001099999978],
                [30.783660889000032, 3.020812036000166],
                [30.784303665000095, 3.02046895],
                [30.784793853000167, 3.020397902000184],
                [30.785360337000157, 3.020282031000079],
                [30.785776139000063, 3.020092965000117],
                [30.786193848000096, 3.020056963000116],
                [30.786495210000169, 3.020057916000098],
                [30.787063599000192, 3.01994204500005],
                [30.787477493000154, 3.019752979000032],
                [30.788152695000065, 3.01958203300012],
                [30.788448334000122, 3.01934790700011],
                [30.788749695000092, 3.019267083000159],
                [30.789201737000042, 3.01888799600016],
                [30.78957748400012, 3.018662930000176],
                [30.789878846000192, 3.018438102000062],
                [30.790481567000029, 3.018140078000045],
                [30.791082382000127, 3.017906905000075],
                [30.79145813000008, 3.01775407800011],
                [30.791759491000107, 3.017610073000185],
                [30.792135239000061, 3.017148972000143],
                [30.792436601000134, 3.016697883],
                [30.792737960000125, 3.016165018000095],
                [30.793113708000135, 3.015487909000115],
                [30.793415069000105, 3.015189887000133],
                [30.793640137000068, 3.014955044000146],
                [30.793941499000141, 3.014575959000126],
                [30.794393540000158, 3.014053107000052],
                [30.795070649000138, 3.013674021000099],
                [30.795522690000155, 3.013367892000076],
                [30.796123504000093, 3.013071061000119],
                [30.796651841000028, 3.012609959000031],
                [30.796798705000128, 3.012494086000118],
                [30.797479630000055, 3.011934042000121],
                [30.797800064000114, 3.011718035000115],
                [30.798080445000096, 3.011104106],
                [30.798532486000113, 3.010272980000025],
                [30.799285889000032, 3.009893894000072],
                [30.799886703000027, 3.009280921000084],
                [30.800338746000193, 3.008532048000177],
                [30.800790787000039, 3.008152962999986],
                [30.80146789600019, 3.007548094000129],
                [30.802219391999984, 3.007088900000156],
                [30.803197860000125, 3.006937028000039],
                [30.803800583000168, 3.006711960000075],
                [30.803876877000107, 3.006639957000061],
                [30.804477692000148, 3.006560088000128],
                [30.804853440000159, 3.006633044000125],
                [30.805606843000078, 3.006561996000073],
                [30.806209564000085, 3.006110907000164],
                [30.807262421000075, 3.00580597000004],
                [30.807865144000118, 3.005654096000114],
                [30.808616639000036, 3.005573987000162],
                [30.809370042000126, 3.005656958000145],
                [30.809747697000034, 3.005883932000131],
                [30.810424803999979, 3.006028891000142],
                [30.810695649000138, 3.006192923000185],
                [30.810800551999989, 3.006256104000158],
                [30.811252595000042, 3.006483077000041],
                [30.811248780000142, 3.006582976000061],
                [30.811853408000104, 3.006638050000163],
                [30.812353134000034, 3.006803990000037],
                [30.81275749299999, 3.006937981000021],
                [30.81358528200019, 3.007318973999986],
                [30.814338684000177, 3.007620096000039],
                [30.814865112000064, 3.007919074000085],
                [30.81531715300008, 3.008074046000161],
                [30.815769196000076, 3.008074999000144],
                [30.815919876000123, 3.008229017000076],
                [30.816116333000082, 3.008274079000103],
                [30.816492081000092, 3.008375883000156],
                [30.816959380000128, 3.008502007000118],
                [30.817123413000104, 3.00822210400014],
                [30.818252563000101, 3.007391929999983],
                [30.819080352000128, 3.006706953000105],
                [30.81983375499999, 3.006184100999974],
                [30.820960998000089, 3.005580902000077],
                [30.821638107000069, 3.004894973000091],
                [30.822618485000191, 3.004380941000022],
                [30.822616577000076, 3.004219056000011],
                [30.822887419999972, 3.003974915000185],
                [30.823219298000083, 3.00368595100008],
                [30.824047089000089, 3.003226995000091],
                [30.824724196000091, 3.00247788400003],
                [30.825099944000101, 3.001873016000047],
                [30.825250626000127, 3.001338959],
                [30.825326920000066, 3.000734090000151],
                [30.825435639000091, 3.000247001999981],
                [30.82547759900018, 3.000056028000188],
                [30.825702667000144, 2.998908997000058],
                [30.825473786000089, 2.997967958000061],
                [30.825225830000193, 2.997126103000142],
                [30.825162887000033, 2.996701001000133],
                [30.824857712000096, 2.995714903000078],
                [30.824506759000144, 2.994366884000044],
                [30.82428169300016, 2.993489981000039],
                [30.824033737000093, 2.992187022000167],
                [30.823986054000102, 2.991617918000145],
                [30.824165345000097, 2.990984916000173],
                [30.824375152000187, 2.990505934000112],
                [30.824796677000109, 2.989873886000055],
                [30.82550811800013, 2.988954068000169],
                [30.825895309000089, 2.988528967999969],
                [30.826929092000114, 2.987572908000118],
                [30.827880859000061, 2.986733914000126],
                [30.828882218000047, 2.985976934000064],
                [30.829753877000144, 2.985471965000102],
                [30.830415726000069, 2.985065938000162],
                [30.830947876000096, 2.984832048000158],
                [30.831609726000124, 2.984553099000095],
                [30.831752778000066, 2.984527111000091],
                [30.831993102000069, 2.984472991000018],
                [30.832092284000055, 2.984309913000061],
                [30.832511901000089, 2.984021902000109],
                [30.83286857600018, 2.983470916000158],
                [30.833150864000118, 2.983037949000163],
                [30.833490372000142, 2.98231506400009],
                [30.833904266000104, 2.981158018000087],
                [30.83409881599999, 2.980770112000187],
                [30.834632873000032, 2.979486942000051],
                [30.835117341000171, 2.978646993000098],
                [30.835390091000193, 2.978195906000167],
                [30.835554124000168, 2.977916003000189],
                [30.836023330999978, 2.977039100000184],
                [30.836605073000158, 2.976037025000153],
                [30.83683204600004, 2.975621939000064],
                [30.837648392000062, 2.974538088000031],
                [30.838293075000024, 2.973897935000082],
                [30.839424133000193, 2.97298693700003],
                [30.840780259000098, 2.972067117000108],
                [30.842313766000075, 2.970921993000047],
                [30.843362807000119, 2.97024607700007],
                [30.84407234299999, 2.969741106000129],
                [30.844718933000024, 2.969434976000059],
                [30.845085143000176, 2.969244957000058],
                [30.845064164000121, 2.968847991000075],
                [30.845355988000108, 2.96858596900006],
                [30.845678330000112, 2.968405963000066],
                [30.846437454000068, 2.968072892000123],
                [30.847179413000049, 2.967612982999981],
                [30.848512649000043, 2.966820001000144],
                [30.848583222000116, 2.966774941000097],
                [30.849277497000116, 2.966361046999964],
                [30.850034715000049, 2.965936900000145],
                [30.851083756000094, 2.965287924000108],
                [30.85203552300004, 2.964576006000186],
                [30.853132248000122, 2.963963032000095],
                [30.854099273000088, 2.963512898000147],
                [30.854440689000114, 2.96315193200013],
                [30.85548400800019, 2.962548018000064],
                [30.856418609000173, 2.962089062000075],
                [30.856721878000087, 2.96205306000013],
                [30.857418061000089, 2.961963893000132],
                [30.858041763000131, 2.961937905000127],
                [30.857757569000114, 2.960618020000084],
                [30.857477188000075, 2.959767103000104],
                [30.857370376000119, 2.958800078000081],
                [30.857372284000121, 2.958131075999972],
                [30.857303620000096, 2.957506895000108],
                [30.857007981000038, 2.956828118000033],
                [30.856792449000181, 2.956077100000073],
                [30.856662750000169, 2.95582294500008],
                [30.856376647000047, 2.955252886000039],
                [30.856069565000041, 2.95427608600005],
                [30.855543136000108, 2.953588009000043],
                [30.855110168000181, 2.953089953000074],
                [30.854520798000067, 2.95264601700012],
                [30.853807449000101, 2.951983928000118],
                [30.853437424000106, 2.951576949000128],
                [30.853406904999986, 2.95095300700018],
                [30.853565215000117, 2.950392009000154],
                [30.853786468000067, 2.949733020000053],
                [30.853860854000175, 2.949381114000062],
                [30.853975297000147, 2.948828936000041],
                [30.853979110000182, 2.948015928000075],
                [30.854114533000086, 2.947220087000062],
                [30.854137420000143, 2.947084904000121],
                [30.85423278900015, 2.946640968000168],
                [30.854576110000039, 2.946145057000081],
                [30.854827882000052, 2.945674897000117],
                [30.854953765000118, 2.945178032000115],
                [30.854986192000183, 2.944490910000127],
                [30.854959488000134, 2.943830966],
                [30.854619980000109, 2.943079949000151],
                [30.854436874000044, 2.942239047000044],
                [30.854469300000062, 2.941606045000071],
                [30.854532243000051, 2.941207886000086],
                [30.854846955000141, 2.940576076000184],
                [30.855035782000016, 2.94014191600013],
                [30.855155945000149, 2.93986296800017],
                [30.855707168000151, 2.939003945000081],
                [30.856208802000083, 2.938508034000165],
                [30.856784821000076, 2.937803984000141],
                [30.857503890000146, 2.937118054000052],
                [30.858221054000182, 2.936368942000058],
                [30.858846665000158, 2.935782909000182],
                [30.859468459000084, 2.935313940000185],
                [30.860311509000041, 2.934719087000076],
                [30.86062431300013, 2.934284926000146],
                [30.860656738000046, 2.933914899000172],
                [30.860315324000055, 2.933343887000149],
                [30.859790802000191, 2.932810068000151],
                [30.859325410000054, 2.932312012000125],
                [30.85873794500003, 2.93164992300018],
                [30.858055115000184, 2.93089890499999],
                [30.857561112000099, 2.930119991000026],
                [30.85750198400018, 2.930005551000022],
                [30.858215333000146, 2.927939177000098],
                [30.858472825000092, 2.927834034000171],
                [30.85856437700005, 2.927807093000183],
                [30.858907700000145, 2.927681922000033],
                [30.859622954000145, 2.927529097000104],
                [30.860370636000027, 2.927439928000069],
                [30.860992431000057, 2.927187919000119],
                [30.861585616000184, 2.926846028000171],
                [30.862333298000067, 2.926476003000175],
                [30.862800597999978, 2.926259995000066],
                [30.862886429000071, 2.926197052000077],
                [30.863267898000117, 2.925728083000081],
                [30.863689422000164, 2.925430059000121],
                [30.86409568800002, 2.924779891000185],
                [30.864408493000099, 2.924338103000025],
                [30.864690781000149, 2.923877001000051],
                [30.865034103000141, 2.923497916000031],
                [30.865470886000139, 2.923191071000133],
                [30.865905761000135, 2.923002005000171],
                [30.86631202699999, 2.922631980000176],
                [30.866872787000034, 2.922353030000181],
                [30.866781235000133, 2.92210006800002],
                [30.866409302000136, 2.921411991000014],
                [30.8661327370001, 2.920941114000073],
                [30.865667342000052, 2.920315982000034],
                [30.865358353000147, 2.919909000000132],
                [30.865173339000137, 2.919374943000094],
                [30.865020752000191, 2.918931962000102],
                [30.864927292000118, 2.918623925000077],
                [30.864931108000064, 2.917838096000025],
                [30.865119934000063, 2.917088033000027],
                [30.865154266000047, 2.916310072000044],
                [30.865343094000025, 2.915649891000044],
                [30.865440369000169, 2.914836883000135],
                [30.865659714000117, 2.914402962000168],
                [30.865819932000079, 2.913435937000031],
                [30.866224289000058, 2.912875891000056],
                [30.866491317999987, 2.912045002000127],
                [30.866991044000144, 2.911612034000029],
                [30.86712265, 2.911530018000121],
                [30.86733818099998, 2.911396026000091],
                [30.867893219000109, 2.911206960000072],
                [30.868455888000028, 2.911117078000075],
                [30.868921280000166, 2.911082029000113],
                [30.869449615000065, 2.910993100000155],
                [30.869855881000149, 2.910712957000158],
                [30.869857789000093, 2.91027903600002],
                [30.86979675300006, 2.909773112999972],
                [30.869674683000085, 2.909184933000176],
                [30.869522095000036, 2.908497095000087],
                [30.869586944000162, 2.907963991000031],
                [30.869527816000073, 2.907304049000175],
                [30.869403840000132, 2.906707049000147],
                [30.869272231000139, 2.905792474000066],
                [30.869161606000148, 2.905087948000016],
                [30.869174957000098, 2.905016900000021],
                [30.869287492000069, 2.904428005000057],
                [30.869321823000178, 2.904112100000134],
                [30.869642258000113, 2.903850078000119],
                [30.870006560999968, 2.903742075000139],
                [30.87066078100014, 2.903527022000048],
                [30.871433257999968, 2.903260708000175],
                [30.871454239000059, 2.903235436000045],
                [30.87458038300008, 2.89956426700013],
                [30.87436294500003, 2.899049043000161],
                [30.874208450000083, 2.898613930000124],
                [30.874179841000114, 2.898180962000026],
                [30.874118806000126, 2.897800922000158],
                [30.874307632000125, 2.897114039000087],
                [30.874370575000114, 2.896707058000061],
                [30.874465942000086, 2.89617395300013],
                [30.874469757000156, 2.895486116000143],
                [30.874410629000067, 2.89442896900016],
                [30.874412537000012, 2.894048929000178],
                [30.874290466000161, 2.893080950000069],
                [30.874292374000106, 2.89270210300009],
                [30.874246597000138, 2.892349004000096],
                [30.874187469000049, 2.891716004000102],
                [30.87434577900018, 2.891218901000116],
                [30.874843597000165, 2.890849114000162],
                [30.875404358000139, 2.890470028000038],
                [30.876308441000049, 2.889883996000094],
                [30.876995086000079, 2.889451981000093],
                [30.87719154500013, 2.889307021000036],
                [30.877836228000092, 2.888855934999981],
                [30.878322601000093, 2.888550043000123],
                [30.878677367000137, 2.888324023000052],
                [30.879457474000105, 2.888082029000088],
                [30.880016327000078, 2.887830019000035],
                [30.880857467, 2.887459994000039],
                [30.881544114000064, 2.886991978000083],
                [30.882354737000071, 2.88634205000011],
                [30.882947922000085, 2.886063099000012],
                [30.883476257000154, 2.885747910000134],
                [30.883975983000141, 2.885314942000036],
                [30.884445190000122, 2.884628057000157],
                [30.88466453500007, 2.884094954000148],
                [30.884820937000029, 2.883851051000136],
                [30.885131837000188, 2.883534908000172],
                [30.88554000900001, 2.882884980000085],
                [30.886226653000165, 2.882163049000099],
                [30.886882782000043, 2.881541015000153],
                [30.887289048000071, 2.880790950000176],
                [30.887727738000137, 2.879580021000095],
                [30.887950897000053, 2.878667115000042],
                [30.888015747000054, 2.877609968000058],
                [30.887958527000137, 2.876514912000062],
                [30.887950897000053, 2.876370907000137],
                [30.887861252000164, 2.87451696300019],
                [30.887857437000093, 2.874434949000147],
                [30.887735366000072, 2.873558045999971],
                [30.887428284000066, 2.872554064000042],
                [30.887340545000143, 2.871368886000028],
                [30.887685776000012, 2.870620013000178],
                [30.887968063000187, 2.869679929000029],
                [30.88815689099999, 2.86893010100016],
                [30.888471603000141, 2.867999078000139],
                [30.888755799000023, 2.867149115000075],
                [30.888975144000142, 2.8661189070001],
                [30.889024734000145, 2.865811348000136],
                [30.889093400000149, 2.865386963000105],
                [30.889156341000103, 2.864998102000129],
                [30.889276504000179, 2.864660024000045],
                [30.889511109000182, 2.86405801799998],
                [30.889671325000108, 2.863028050000025],
                [30.889955520000058, 2.861998082000071],
                [30.890115738000191, 2.860841035000021],
                [30.890018463000047, 2.860470057000043],
                [30.890180588000021, 2.859529973000065],
                [30.890184403000092, 2.858525991000136],
                [30.890029907000098, 2.857577087000152],
                [30.889667512000017, 2.856734991999986],
                [30.88925171000011, 2.855938912000056],
                [30.888572693000128, 2.855097056000034],
                [30.88799858100009, 2.854562043000044],
                [30.887525558999982, 2.854127885000025],
                [30.887424469000166, 2.854036093000048],
                [30.887165070000094, 2.853194952000024],
                [30.887315749000038, 2.852868081000054],
                [30.887428284000066, 2.852617025000086],
                [30.88769340500005, 2.851876020000134],
                [30.887905122000063, 2.851188898000146],
                [30.887855530000024, 2.850511074000167],
                [30.887336730000129, 2.849397899000167],
                [30.886880874000042, 2.848419905000071],
                [30.886842727000158, 2.847995996000066],
                [30.886770249000051, 2.846919060000175],
                [30.886302947000104, 2.845870018000028],
                [30.886148452000043, 2.845128059000047],
                [30.885913849000076, 2.844445943999972],
                [30.885784150000063, 2.844069004000062],
                [30.885629652999967, 2.843544960000031],
                [30.885318756000117, 2.842756987000144],
                [30.885007859000041, 2.841861963000156],
                [30.884853364000151, 2.841065883000169],
                [30.88454246399999, 2.839962006000064],
                [30.884153366000135, 2.838984966000055],
                [30.883947372000137, 2.838144064000119],
                [30.884107589000166, 2.837456942000074],
                [30.884160996000048, 2.836823940000102],
                [30.884426116000157, 2.835983038000165],
                [30.884475709000071, 2.835799932000157],
                [30.884639739000136, 2.835187913000027],
                [30.884798049000096, 2.834511041000155],
                [30.885011674000111, 2.833561897000152],
                [30.885118484000088, 2.833036900000138],
                [30.88527679399999, 2.832983017],
                [30.885656357000073, 2.832866907000039],
                [30.885709762000147, 2.83170890700012],
                [30.885589601000049, 2.82982802399999],
                [30.885416030000158, 2.828398942000092],
                [30.884880065000118, 2.826083899000139],
                [30.88463211100003, 2.825124979000179],
                [30.884656907000135, 2.824934960000178],
                [30.88469505400019, 2.824619055000085],
                [30.884626388000186, 2.823144913000135],
                [30.88471222000004, 2.820730925000078],
                [30.884946823000178, 2.818624974000159],
                [30.885051728000064, 2.81744909400004],
                [30.884485244000075, 2.815432072000135],
                [30.884183883000105, 2.814409017000116],
                [30.883447648000185, 2.813575983000135],
                [30.882883072000027, 2.812834025000029],
                [30.881399155000054, 2.811022998000169],
                [30.880184173000089, 2.809601069000053],
                [30.88010215800017, 2.809514045000128],
                [30.87764930600008, 2.806893110000033],
                [30.876024245000167, 2.804639100999964],
                [30.874399184000083, 2.802122116000021],
                [30.873771667000142, 2.800991058000022],
                [30.873142243000075, 2.799912930000176],
                [30.872253418000184, 2.799062015000061],
                [30.871501923000096, 2.798346996000134],
                [30.869388579000031, 2.79616403700004],
                [30.867551803000083, 2.79434299400009],
                [30.866943360000107, 2.79258704200015],
                [30.866857528000139, 2.789974929000096],
                [30.866804123000065, 2.789797068000041],
                [30.8665943150001, 2.789078952000125],
                [30.866022109000028, 2.788228035000145],
                [30.865682603000153, 2.787998914000127],
                [30.865240097000139, 2.78770208300017],
                [30.863145829000075, 2.786043882000172],
                [30.861911774000191, 2.78497505200005],
                [30.861400605000085, 2.783934116000069],
                [30.860864640000045, 2.781989098000054],
                [30.860446930000137, 2.780468941000152],
                [30.860391617000062, 2.779529094999987],
                [30.860273362000157, 2.77840709700007],
                [30.85988426099999, 2.777339935000043],
                [30.859796525000036, 2.776544095000133],
                [30.859745025000052, 2.776299001000098],
                [30.859430313000132, 2.774753093000072],
                [30.859247207000067, 2.773857116000102],
                [30.858343124000157, 2.773005962000184],
                [30.857801436000102, 2.772425889999965],
                [30.856353761000094, 2.77232408499998],
                [30.855657578000148, 2.771949052000139],
                [30.855327605000127, 2.771770954000147],
                [30.85418128900011, 2.771071911000149],
                [30.852853775000085, 2.770762921000141],
                [30.852066040000182, 2.771276951000061],
                [30.851249694000103, 2.77114892000003],
                [30.849742889000083, 2.77060294100005],
                [30.849178315000074, 2.770693063000067],
                [30.848834991000103, 2.770745993000162],
                [30.848157882000123, 2.770401955000125],
                [30.847236634000069, 2.769921064000016],
                [30.846696853000083, 2.768852949000063],
                [30.846641541000167, 2.767487050000113],
                [30.846342087000039, 2.766211986000087],
                [30.845710755000027, 2.765902996000136],
                [30.844202041000074, 2.765232087000129],
                [30.843217850000087, 2.765816927000174],
                [30.842607499000167, 2.76733493800009],
                [30.841426849000129, 2.768273115],
                [30.840215682000064, 2.76854300500014],
                [30.839778899000066, 2.768457413000022],
                [30.839279176000161, 2.768359899000075],
                [30.83735084500006, 2.767288924000013],
                [30.836294174000102, 2.766464950000056],
                [30.835784912000065, 2.765424014000075],
                [30.835712433000026, 2.764110327000026],
                [30.828952789000084, 2.75599908900017],
                [30.826768875000084, 2.750722408000115],
                [30.822858811, 2.741273880000108],
                [30.822940825000046, 2.740916013000117],
                [30.822826384999985, 2.740192891000106],
                [30.822597504000157, 2.739351987000021],
                [30.822257996000133, 2.73847389299999],
                [30.822105408000141, 2.737642050000034],
                [30.822408675000077, 2.736918926000044],
                [30.822483064000096, 2.736766101000114],
                [30.822788238000157, 2.736386061000076],
                [30.823053360000017, 2.735843897000166],
                [30.823431016000029, 2.73523902900007],
                [30.823659896000152, 2.734778880000079],
                [30.823961258, 2.734137058000101],
                [30.824188233000086, 2.733603955000149],
                [30.824529647000077, 2.733216046999985],
                [30.824945448999983, 2.732836961999965],
                [30.825401306000174, 2.732530117000067],
                [30.826044083000113, 2.731654884000079],
                [30.826309203000051, 2.730931997000027],
                [30.826574325000138, 2.730135918000144],
                [30.826574325000138, 2.729449988000056],
                [30.826005936000115, 2.728760958000066],
                [30.825361251000118, 2.728379965000102],
                [30.824529647000077, 2.728188991000081],
                [30.823808671000108, 2.727889062000031],
                [30.82335472200009, 2.727355005000163],
                [30.823165893, 2.726784945000077],
                [30.82289886600006, 2.726170064000087],
                [30.822710037000149, 2.725636007000048],
                [30.82263374300004, 2.724839927000119],
                [30.822444917000041, 2.72411704100017],
                [30.822027207000133, 2.723238945000162],
                [30.821685791000107, 2.722405910000077],
                [30.821895599000072, 2.72165608500012],
                [30.822008134000043, 2.720968963000075],
                [30.82208442700005, 2.72036409500015],
                [30.821893691000128, 2.720056058000125],
                [30.821666718000074, 2.719830037000179],
                [30.82125091600011, 2.719793081000148],
                [30.820833206000032, 2.719791890000124],
                [30.820493698000178, 2.719754934000093],
                [30.819925309000155, 2.719681978000097],
                [30.819583893000129, 2.719681025000114],
                [30.819053650000058, 2.719490051000093],
                [30.818939210000167, 2.719218969000053],
                [30.818939210000167, 2.718612910000104],
                [30.818901061000133, 2.718116045000102],
                [30.818870544000163, 2.717825176000133],
                [30.81876776199999, 2.71764094100007],
                [30.818696465000073, 2.717513143000133],
                [30.81867408800008, 2.717473029000189],
                [30.818750381000086, 2.717201949000128],
                [30.81868543500002, 2.717089011000041],
                [30.818657633000043, 2.717040665000127],
                [30.818599702000142, 2.716939927000169],
                [30.818370907000144, 2.716821954000068],
                [30.818252511000082, 2.716805437],
                [30.818091794000054, 2.716783016000022],
                [30.817838910999967, 2.716747126000087],
                [30.817386775000045, 2.716557997000166],
                [30.817247359000078, 2.716557567000109],
                [30.816742, 2.716556],
                [30.816487319000089, 2.716537365000079],
                [30.81625, 2.716519999000013],
                [30.815758262000031, 2.716446904000122],
                [30.815509797000175, 2.716409922000082],
                [30.81517543200016, 2.716339541000025],
                [30.81477400000017, 2.716255],
                [30.814433000000179, 2.716048000000171],
                [30.814290626000115, 2.715961826000125],
                [30.814205171000083, 2.715909958000111],
                [30.813948643000174, 2.715733595000188],
                [30.81359877400007, 2.71549268300015],
                [30.813434763000089, 2.715263393000157],
                [30.813345298000058, 2.71513805799998],
                [30.813295363000123, 2.715068102000089],
                [30.813310727000157, 2.714900553000177],
                [30.813312000000167, 2.714887],
                [30.813334, 2.714652000000115],
                [30.813599, 2.714191000000142],
                [30.813807654000129, 2.713979884000082],
                [30.813891823000063, 2.71389488799997],
                [30.813938141000165, 2.71384811400003],
                [30.813932835000116, 2.71382429],
                [30.81391556300008, 2.713746729999969],
                [30.813863755000057, 2.713514089],
                [30.813219070000116, 2.713395118000051],
                [30.812612533999982, 2.71304988900016],
                [30.812499828999989, 2.712994159000175],
                [30.812157402000025, 2.712824837000028],
                [30.812007903000165, 2.712750913000093],
                [30.811401367000087, 2.712251902000105],
                [30.811350652000044, 2.712227589000179],
                [30.811268989000155, 2.712188439000101],
                [30.811239, 2.712173999000186],
                [30.810998021000103, 2.712059010000132],
                [30.810757000000137, 2.711944],
                [30.810622000000137, 2.711827000000142],
                [30.810569973000042, 2.711782053000036],
                [30.810453415000154, 2.711680890000139],
                [30.810357193000186, 2.711312042000145],
                [30.810265001000175, 2.710957000000121],
                [30.810246098000164, 2.71066378400019],
                [30.810226440000122, 2.710351943000035],
                [30.810164901000064, 2.710148533000108],
                [30.810122635000141, 2.710008830000163],
                [30.810075760000075, 2.709853887000065],
                [30.809885026000131, 2.709664106000048],
                [30.809592406000036, 2.709663448000185],
                [30.809393062000083, 2.709663000000148],
                [30.809354796000093, 2.709662914000148],
                [30.808896894000043, 2.709469549000062],
                [30.808834128, 2.709432052000068],
                [30.808521272000064, 2.709244966000028],
                [30.808256150000147, 2.708937884000022],
                [30.808249850000152, 2.708865593000155],
                [30.808233605000169, 2.708679202000099],
                [30.808220001000109, 2.708522015000142],
                [30.808710098000063, 2.708142997000039],
                [30.80876980700009, 2.708101595000187],
                [30.808826896000141, 2.70806201],
                [30.80915593400016, 2.707833862000143],
                [30.808888686999978, 2.707276585000159],
                [30.808721656000102, 2.706928701000152],
                [30.808633816000167, 2.706745889000104],
                [30.808595525999976, 2.706726690000039],
                [30.808369138999979, 2.706612800000187],
                [30.807954008000024, 2.706345149000185],
                [30.807823119000147, 2.706260671999985],
                [30.807611465000036, 2.706124067000189],
                [30.807354411000063, 2.705760555000097],
                [30.807234000000165, 2.705590000000143],
                [30.807161105000034, 2.705224091000105],
                [30.807081222000136, 2.704822064000155],
                [30.807096292000097, 2.704717236000022],
                [30.807127586000036, 2.704499545000033],
                [30.807195663000073, 2.704025984000055],
                [30.807304382000098, 2.703974724000034],
                [30.807469953, 2.703895646999968],
                [30.807673329000181, 2.703798513000152],
                [30.80776214600013, 2.703756094000084],
                [30.80829238900003, 2.703449012000078],
                [30.808421274000125, 2.703206995000016],
                [30.808502684000132, 2.703054124000062],
                [30.808633805000056, 2.702807904000167],
                [30.808672558000126, 2.702422419000129],
                [30.80868507800011, 2.702297881000106],
                [30.808710098000063, 2.702049017000149],
                [30.808704695000074, 2.702018230000022],
                [30.808684566000068, 2.701903537000021],
                [30.808596000000193, 2.7013970000001],
                [30.808596000000193, 2.700828000000115],
                [30.808685440000147, 2.700345491000064],
                [30.808710098000063, 2.70021295600003],
                [30.808937074000028, 2.699798108000152],
                [30.809694290000152, 2.699563980000164],
                [30.809750246000135, 2.699541823000175],
                [30.809868608000158, 2.699494955000034],
                [30.810262680999983, 2.699338911999973],
                [30.810451507000153, 2.698879003000059],
                [30.810429580000061, 2.698551319000103],
                [30.810420692000037, 2.698418502000038],
                [30.81041336100003, 2.698308944000019],
                [30.81041336100003, 2.697549105000121],
                [30.810422142000164, 2.697426395000093],
                [30.810429684000042, 2.697320989000104],
                [30.810451926000098, 2.697017030000097],
                [30.810556481000162, 2.696562549000078],
                [30.810602187000029, 2.696365118000131],
                [30.810678483000117, 2.695832015000178],
                [30.810700037000117, 2.695617280000079],
                [30.810702529000139, 2.695592451999971],
                [30.810716630000172, 2.695451975000026],
                [30.810716630000172, 2.695072890000176],
                [30.809995651000122, 2.694428922000157],
                [30.809907912000028, 2.694348098000035],
                [30.809579879000182, 2.69404890900006],
                [30.809498363000159, 2.69399175600006],
                [30.809087999000155, 2.693704],
                [30.808861999000101, 2.693591991000119],
                [30.808631999000056, 2.693477999000095],
                [30.80827803, 2.693139945000041],
                [30.807987212000114, 2.692862034000029],
                [30.807533263000096, 2.692137002000095],
                [30.807344333000117, 2.691714030000071],
                [30.807194001000141, 2.691377001000149],
                [30.807097081999984, 2.691074130000061],
                [30.806890488000136, 2.690427065000108],
                [30.806623458000047, 2.689703941000118],
                [30.806524372000069, 2.689275303000159],
                [30.806473, 2.689052001000164],
                [30.806567508000057, 2.688672468000107],
                [30.806662, 2.688292999000055],
                [30.806758886000068, 2.688097684000184],
                [30.806796845000122, 2.68802147800011],
                [30.806850434000182, 2.687913894000076],
                [30.807266236000146, 2.687607050000054],
                [30.807571410000151, 2.687191963000089],
                [30.807796478000114, 2.68680310200017],
                [30.807798386000115, 2.686233998000148],
                [30.807758331000116, 2.685319900000025],
                [30.807531356000027, 2.684370995000109],
                [30.807228089000091, 2.683682918000102],
                [30.807116236000184, 2.683456069000101],
                [30.807035057000121, 2.683291435000115],
                [30.806964874000073, 2.683149099000104],
                [30.806661605000102, 2.682887077999965],
                [30.80601692200014, 2.682506086000103],
                [30.805495553000071, 2.682355286000018],
                [30.805143887000156, 2.68225357200015],
                [30.805068970000036, 2.682231903000059],
                [30.804349979000165, 2.681860889000063],
                [30.804331596000168, 2.68176833400014],
                [30.804282471000079, 2.681519844000036],
                [30.804235459000097, 2.681282043000124],
                [30.804244809000181, 2.681171158000097],
                [30.804251, 2.681101000000183],
                [30.804274000000191, 2.680794001000095],
                [30.804577, 2.680261000000144],
                [30.804719091000152, 2.680188783000119],
                [30.804795653000099, 2.680149960000165],
                [30.804880142000059, 2.680107115999988],
                [30.80518341100003, 2.679836988000034],
                [30.805334090000144, 2.679420949000189],
                [30.805410386000062, 2.678924083000084],
                [30.805329224000104, 2.67874526200012],
                [30.80530919000006, 2.678701122],
                [30.805221557000152, 2.678508043000136],
                [30.804954530000032, 2.678118945000108],
                [30.804183366000132, 2.677561341000114],
                [30.804140092000125, 2.677530050000144],
                [30.804105578000133, 2.677492749000123],
                [30.804066896000108, 2.677450943000054],
                [30.80361175500019, 2.676959038000121],
                [30.803308820000098, 2.676656814000182],
                [30.803003311000111, 2.676352025000085],
                [30.802803463000089, 2.676185979000024],
                [30.802747355000065, 2.676139361000082],
                [30.802360999000086, 2.675818001000152],
                [30.801906998999982, 2.675555000000145],
                [30.801451, 2.675138000000118],
                [30.800959, 2.674486000000172],
                [30.800804000000142, 2.674195],
                [30.800745972000129, 2.674086344000102],
                [30.800655366000115, 2.673916101000145],
                [30.800387371000056, 2.673711525000101],
                [30.800126428000056, 2.673512331000154],
                [30.800048828000172, 2.673453094000138],
                [30.799632561000124, 2.673216010000033],
                [30.799622070000055, 2.673210035000182],
                [30.799253463000184, 2.673000098000102],
                [30.798362468000107, 2.672505983000178],
                [30.798157000000174, 2.672392],
                [30.797931679000044, 2.672113596000145],
                [30.797359466000046, 2.671406031000117],
                [30.797056197000131, 2.670527935000109],
                [30.797040172000095, 2.670471162000126],
                [30.797011711000152, 2.670370334000154],
                [30.796906001000139, 2.669994001000021],
                [30.796911589000047, 2.669967068000176],
                [30.797011000000168, 2.669488000000172],
                [30.797015515000112, 2.669466228000147],
                [30.797056197000131, 2.669270993000055],
                [30.797277118000181, 2.668946295000183],
                [30.79738888300011, 2.668782029000113],
                [30.797473999000033, 2.66865700000011],
                [30.797701, 2.668205999],
                [30.797944047000044, 2.667865239999969],
                [30.798191070000144, 2.667519093000067],
                [30.798281147000012, 2.667373341000086],
                [30.798415759000136, 2.667155527000148],
                [30.798570633000168, 2.666904927000189],
                [30.798654962000114, 2.666751813000133],
                [30.798677712000085, 2.666710506000186],
                [30.798759460000099, 2.666562081000109],
                [30.798830384000098, 2.665842206000093],
                [30.798847878000117, 2.665664647000085],
                [30.798854829000049, 2.665594101000124],
                [30.798173905000056, 2.664906026000097],
                [30.798038991000055, 2.664762997000082],
                [30.798021916000152, 2.664744895000183],
                [30.797567368000045, 2.664263011000116],
                [30.797344711000164, 2.663935124000091],
                [30.797327, 2.663909],
                [30.797155944999986, 2.663656766000145],
                [30.797150001000034, 2.663648000000137],
                [30.796971661000043, 2.662994351000123],
                [30.796923, 2.662816001000124],
                [30.797073133000083, 2.662469424000108],
                [30.797338486000115, 2.661858083000084],
                [30.797754288000021, 2.661252975000082],
                [30.79779243400003, 2.660638094000092],
                [30.797541357999989, 2.660357045000069],
                [30.79747770299997, 2.660285791000035],
                [30.797452926000176, 2.660258056000089],
                [30.797185916000103, 2.66008995500016],
                [30.797167649000073, 2.660078453999972],
                [30.79703521600004, 2.659995079000112],
                [30.796619414000133, 2.659578086000181],
                [30.796089172000109, 2.659234048000144],
                [30.79586029100011, 2.659007072000179],
                [30.795701026000131, 2.658762846000059],
                [30.795483, 2.658428],
                [30.795359666000138, 2.658196340000075],
                [30.795293818000061, 2.658072658000151],
                [30.795262, 2.658013000000153],
                [30.795246803000168, 2.65798462000015],
                [30.79517936700006, 2.65785789600011],
                [30.795187428000133, 2.65767259200004],
                [30.79519564300017, 2.657483731000184],
                [30.795217514000115, 2.656980992000058],
                [30.795118819000038, 2.656395400000065],
                [30.79510679399999, 2.656324052000059],
                [30.795103073000178, 2.656301975000076],
                [30.794914245000143, 2.655913115000033],
                [30.794709071000057, 2.655631644000152],
                [30.794610999000099, 2.655497],
                [30.79432700000018, 2.655211500000178],
                [30.794043001000034, 2.654926],
                [30.793509894000067, 2.654258519000109],
                [30.793436050000139, 2.654165983000098],
                [30.793368717000192, 2.654063382000061],
                [30.793296902000179, 2.653953950000073],
                [30.792930619000174, 2.65339556400005],
                [30.792925001000071, 2.653387],
                [30.792660001000172, 2.652664],
                [30.79269590500013, 2.652048896000167],
                [30.792698, 2.652013],
                [30.792714247000049, 2.651913766000178],
                [30.792772294000144, 2.651561023000056],
                [30.792806714000108, 2.6513868400001],
                [30.792830836000178, 2.651264773000037],
                [30.792886733999978, 2.650981903000059],
                [30.792697906000171, 2.65037608200015],
                [30.792621612000119, 2.649878979000107],
                [30.792430878000118, 2.649425983000128],
                [30.79226987100003, 2.648871],
                [30.79224575700016, 2.648787880000157],
                [30.792242049000038, 2.648775100000023],
                [30.792236219000074, 2.648753905000092],
                [30.792128000000105, 2.648359],
                [30.791901001000042, 2.647824999000022],
                [30.791569, 2.647427],
                [30.790691376000098, 2.647217035000153],
                [30.788633347000086, 2.646861076000107],
                [30.788074494000114, 2.646718979000127],
                [30.787200928000118, 2.646496058000082],
                [30.786666870000147, 2.645682097000133],
                [30.786666870000147, 2.644787072999975],
                [30.787054061000106, 2.643461943000148],
                [30.787117004000095, 2.643250942000179],
                [30.787101746000076, 2.642066003000082],
                [30.786602020000146, 2.641197920000138],
                [30.7867469790001, 2.640266896000185],
                [30.786748887000101, 2.638891935000174],
                [30.78710937600016, 2.637671947000172],
                [30.787397385000133, 2.636950016000185],
                [30.787683488000084, 2.636661053000182],
                [30.789474487, 2.636446953000018],
                [30.790622710999969, 2.636231900000098],
                [30.790908814000147, 2.635807992000025],
                [30.790910722000092, 2.63515710900009],
                [30.790653230000146, 2.634785889999989],
                [30.79026794400005, 2.634215118000043],
                [30.78976631099999, 2.633500099000116],
                [30.78819465700019, 2.632194996000067],
                [30.787767410000072, 2.629961014000116],
                [30.787054061000106, 2.628595114000063],
                [30.786233902000049, 2.627806902000032],
                [30.785480500000062, 2.627082109000014],
                [30.784481048000146, 2.625778913000033],
                [30.783590316000129, 2.62285709300005],
                [30.783163071000047, 2.621707916999981],
                [30.782520294000108, 2.620621921],
                [30.782306671000072, 2.619617939000079],
                [30.782257081000068, 2.619563104000065],
                [30.781234741000048, 2.618386031000114],
                [30.780662537000126, 2.618249892000165],
                [30.780019759000027, 2.617525101000126],
                [30.779661179000016, 2.617017985000075],
                [30.78045272900016, 2.615509987],
                [30.780525208000029, 2.614713907000066],
                [30.779882430000157, 2.614062071000092],
                [30.77852249100016, 2.613562108000053],
                [30.778524399000105, 2.611681939000164],
                [30.778511047999984, 2.611500979000141],
                [30.778385162000063, 2.609592914000075],
                [30.77867317099998, 2.608300924000105],
                [30.779285432000108, 2.607189894000044],
                [30.779214859000035, 2.606322051000063],
                [30.778215409000154, 2.604737997000086],
                [30.778001785000072, 2.603941917000157],
                [30.777215958000113, 2.602999925000177],
                [30.776500702000135, 2.60271811500013],
                [30.774278640000091, 2.60306692100005],
                [30.773061753000036, 2.603137017000165],
                [30.772785187000068, 2.602979899000104],
                [30.77206039400005, 2.602566004999972],
                [30.771631241000193, 2.601552010000034],
                [30.771713257000044, 2.601010084000109],
                [30.771848679000072, 2.600115060000121],
                [30.771921157000179, 2.599319935000153],
                [30.771421433000057, 2.598957063000171],
                [30.77077674800006, 2.598884104999968],
                [30.769844055000078, 2.598954916000082],
                [30.767696381, 2.599240064000071],
                [30.766622544000086, 2.599165916000118],
                [30.764617920000148, 2.598582983000142],
                [30.763473511000029, 2.598228932000041],
                [30.761898040000176, 2.597647905000031],
                [30.761245727000073, 2.597289563000061],
                [30.761148452000157, 2.597235918000138],
                [30.760740280000107, 2.597012042000074],
                [30.760038375000192, 2.596632004000071],
                [30.758966445000169, 2.595626117000052],
                [30.758472442000027, 2.594515085000182],
                [30.756956101000071, 2.592999219000149],
                [30.756422043000157, 2.591664314000127],
                [30.756256103000055, 2.591619969000078],
                [30.756000518000178, 2.591552019000119],
                [30.754783631000123, 2.591758013000117],
                [30.754272459999981, 2.591757059000031],
                [30.753995895000116, 2.591757059000031],
                [30.753063201000032, 2.591330051000057],
                [30.75263595600012, 2.590750933000095],
                [30.752063751000151, 2.589808942000047],
                [30.751565933000165, 2.588949919000129],
                [30.750991821000071, 2.588587045000111],
                [30.750494003000085, 2.587357045000147],
                [30.750127793000161, 2.586514950000037],
                [30.750158310000131, 2.585592985],
                [30.750459671000101, 2.584717036000086],
                [30.750543595000124, 2.58447289500009],
                [30.751190186000031, 2.583235026000068],
                [30.752542496000046, 2.581737041],
                [30.753482818, 2.58026504500009],
                [30.75386619600016, 2.579452039000159],
                [30.753984451000065, 2.57919907500019],
                [30.753999710000187, 2.578150033000043],
                [30.753885269000193, 2.577264071000116],
                [30.753622055000108, 2.576323033000051],
                [30.753183364000108, 2.57517409400009],
                [30.752834320000034, 2.57398891500003],
                [30.75277710000006, 2.573492050000027],
                [30.752866744000016, 2.572606086000064],
                [30.753307342000085, 2.571865081000169],
                [30.754293441000073, 2.570257903000027],
                [30.75526237400004, 2.568938970999966],
                [30.755941391000135, 2.568171978],
                [30.756174087000034, 2.567902088000096],
                [30.756792068000095, 2.566988945000105],
                [30.756910324000103, 2.566818],
                [30.75763511700012, 2.565145969000014],
                [30.757484436000027, 2.563536883000097],
                [30.757333756000151, 2.561872960000187],
                [30.756872177000048, 2.560425998000028],
                [30.756050111000093, 2.558868885000095],
                [30.75584411599999, 2.558037043000184],
                [30.755847931000062, 2.557159902000137],
                [30.757034302000079, 2.555246115000159],
                [30.757392884000069, 2.554722070000082],
                [30.758430480000129, 2.55322289500009],
                [30.758596420000174, 2.552891970000189],
                [30.75876045300015, 2.550033808000137],
                [30.75859260500016, 2.54936289900013],
                [30.758565903000033, 2.549253941000131],
                [30.758104324000158, 2.548321963000149],
                [30.757591248000153, 2.546667100000093],
                [30.756874084000117, 2.543654919000176],
                [30.756467820000125, 2.540544033000174],
                [30.756368638000083, 2.538472891000026],
                [30.75683021500015, 2.536032915000135],
                [30.757947921999971, 2.536115886000175],
                [30.759548187000178, 2.536386729000071],
                [30.760831832000065, 2.514135838000129],
                [30.760955811000088, 2.511999131000039],
                [30.75895500199999, 2.499999286000048],
                [30.757339478000119, 2.497440576000088],
                [30.756885528, 2.497419120000075],
                [30.755165099000067, 2.497805118000031],
                [30.753604889000087, 2.497920036000153],
                [30.752340317000119, 2.497673035000048],
                [30.75120925900012, 2.497164966000184],
                [30.750268937000101, 2.496475936000024],
                [30.749069214000087, 2.495596885],
                [30.748207093000076, 2.494736910000029],
                [30.74723625300004, 2.49334311400014],
                [30.746398926000097, 2.491550923000091],
                [30.746099472000026, 2.49035811400006],
                [30.746137619000081, 2.48886609200008],
                [30.746187211000063, 2.487193108000042],
                [30.746208192000154, 2.483649014999969],
                [30.746192931000053, 2.482374908000168],
                [30.746135711000079, 2.481415988000038],
                [30.74583816500018, 2.480457068000135],
                [30.745134354000129, 2.478666067000177],
                [30.74478340100012, 2.477616072000046],
                [30.744659424000076, 2.476025105000133],
                [30.744171141999971, 2.474514962000171],
                [30.743604660000187, 2.473202943000047],
                [30.743440628000144, 2.472342968000021],
                [30.743600845000117, 2.471149922000109],
                [30.744014739000079, 2.469821930000023],
                [30.744388579000145, 2.46889090600007],
                [30.744628906, 2.468033076000154],
                [30.744493484000031, 2.46719193399997],
                [30.744302750000031, 2.46595311100009],
                [30.744380951000039, 2.464632989000108],
                [30.744310378000137, 2.46314096399999],
                [30.744468688000097, 2.461468936000074],
                [30.744276047000028, 2.460175037],
                [30.744060516000047, 2.459677934000183],
                [30.743305206000116, 2.459007978000159],
                [30.74241828900017, 2.458426953000128],
                [30.741689683000118, 2.457567930000039],
                [30.740840913000056, 2.456760883000129],
                [30.740409850000162, 2.455929041000047],
                [30.740110397000024, 2.454417945000103],
                [30.740242005000027, 2.453063012000143],
                [30.740909576000149, 2.45040607500016],
                [30.741388321000045, 2.448878050000133],
                [30.741844177000075, 2.448191881000071],
                [30.742946624000126, 2.447190999000043],
                [30.744153976000177, 2.446378946000095],
                [30.745227814000032, 2.445450068000184],
                [30.745939255000167, 2.444818020000071],
                [30.74642181400003, 2.444149971000172],
                [30.746904372000188, 2.443247079000059],
                [30.747493744999986, 2.44255089700016],
                [30.748083115000099, 2.441910029000098],
                [30.748540879000132, 2.441287040000134],
                [30.748607635000155, 2.440844058999971],
                [30.749031067000033, 2.438150883000105],
                [30.749187469000162, 2.435971976000189],
                [30.749145507000094, 2.43503189200004],
                [30.749061584000174, 2.433765888000096],
                [30.749275208000086, 2.43266391800006],
                [30.749889374000077, 2.430567027],
                [30.750074386000108, 2.429157019000058],
                [30.750139236000166, 2.428307057000097],
                [30.75011062699997, 2.426724910000189],
                [30.750213622000103, 2.425124885000059],
                [30.750614166000162, 2.424077034000106],
                [30.751039506000041, 2.421762943000033],
                [30.751468659000068, 2.420299054000168],
                [30.751682281000171, 2.419919968000045],
                [30.752031327000111, 2.420109988000149],
                [30.752597809000065, 2.420427085000029],
                [30.753877639000109, 2.421777011000131],
                [30.75471305800005, 2.423171044000128],
                [30.755256653000174, 2.424889089000089],
                [30.755201341000031, 2.425178050000056],
                [30.755014420000123, 2.425775051000187],
                [30.755233765000071, 2.426779032000013],
                [30.755502700000193, 2.426969051000015],
                [30.756362915000011, 2.4269979],
                [30.757249832000127, 2.426899911000021],
                [30.757921219000025, 2.42711806300008],
                [30.758676529000127, 2.427814961000024],
                [30.75945854299999, 2.428222895000033],
                [30.759927750000031, 2.428116083000077],
                [30.760843278000095, 2.428406001000099],
                [30.76151466400006, 2.428380013000094],
                [30.762052537000045, 2.428435088000072],
                [30.762645722, 2.428716898000118],
                [30.763803482000071, 2.429296971000099],
                [30.764421463000133, 2.429778098000043],
                [30.764503479000155, 2.430329084000107],
                [30.76474762100014, 2.431423903000052],
                [30.764909744000136, 2.431495906000066],
                [30.765554427999973, 2.431334973000162],
                [30.766521454000099, 2.431046963000085],
                [30.768295287, 2.430886985000029],
                [30.769210815000122, 2.430726052000125],
                [30.770393371000068, 2.431152105000081],
                [30.770893096000123, 2.431596041000091],
                [30.77121925400013, 2.433296918],
                [30.771785736000083, 2.433967114000041],
                [30.77253913800007, 2.434348107000062],
                [30.773803710000095, 2.43504595800016],
                [30.774908067000069, 2.435717106000084],
                [30.776363373000038, 2.436532975000148],
                [30.776578903000086, 2.436677934000159],
                [30.777347564000024, 2.438008071000184],
                [30.777698517000033, 2.438822985000115],
                [30.777967453000031, 2.438921929000117],
                [30.778640747000168, 2.438968896000176],
                [30.779069900000081, 2.438997030000053],
                [30.779392242000085, 2.438735008000037],
                [30.779445647000159, 2.438446044000102],
                [30.779605865000065, 2.438083887000062],
                [30.779794693000099, 2.437705040000083],
                [30.780061721000152, 2.437416076000147],
                [30.780168533000108, 2.437227012000108],
                [30.780195237000157, 2.43701005000014],
                [30.780221939000057, 2.436675071000025],
                [30.780435561000161, 2.43651294599999],
                [30.780731202000027, 2.436467886],
                [30.781215668000129, 2.436486960000025],
                [30.781806945000142, 2.436397076000048],
                [30.782102584000143, 2.436253071000124],
                [30.782264709000174, 2.435964108000064],
                [30.782236100000034, 2.435774089000063],
                [30.782155991000025, 2.435512067000047],
                [30.782154084000183, 2.435295104000147],
                [30.782449723000184, 2.435106040000164],
                [30.782745362000071, 2.43488907800014],
                [30.783498764000058, 2.434510947000035],
                [30.78451919500003, 2.434350015],
                [30.785406114000125, 2.434451104000118],
                [30.786455155000112, 2.434525013000098],
                [30.78715515100015, 2.434734106000121],
                [30.78799057000009, 2.434978962000116],
                [30.788473130000057, 2.435152055000117],
                [30.78882408100003, 2.435359955000081],
                [30.788932799000122, 2.435432911000078],
                [30.78953552199999, 2.43497204900018],
                [30.790100099000085, 2.435587645000112],
                [30.802953721000108, 2.429999351000106],
                [30.829183578000084, 2.42661500000014],
                [30.833953856000107, 2.425999403000105],
                [30.834095001000151, 2.425780295999971],
                [30.834070206000149, 2.425712110000063],
                [30.833814622000148, 2.424999952000064],
                [30.833812714000146, 2.423923969000157],
                [30.834211349000157, 2.422089100000108],
                [30.834465028000125, 2.420949937000103],
                [30.834571838000045, 2.42052602699999],
                [30.834220885999969, 2.419883012000184],
                [30.833278657000108, 2.419040918000178],
                [30.832712173000118, 2.418514968000181],
                [30.83241462699999, 2.41798091000004],
                [30.832521439000175, 2.417438985000047],
                [30.833164214000135, 2.416553974000067],
                [30.833808899000076, 2.41612100600014],
                [30.834669112000142, 2.415915013000074],
                [30.835557937000033, 2.416033984000023],
                [30.836122513000191, 2.41606211800007],
                [30.837007522000135, 2.415801048000162],
                [30.837638853999977, 2.415069104000168],
                [30.83806800900004, 2.414139033000026],
                [30.838735580000161, 2.412657023000122],
                [30.839244842000028, 2.411564112000065],
                [30.839643478000141, 2.409746885000061],
                [30.839574814000116, 2.40825510000019],
                [30.839464187000146, 2.407341957000028],
                [30.83876228400004, 2.405911922000087],
                [30.83824920700016, 2.405051947000061],
                [30.837924957000155, 2.404211045000125],
                [30.83808517500006, 2.404038907000086],
                [30.838380814000118, 2.403922081000019],
                [30.839376450000032, 2.404313087000048],
                [30.840072633000034, 2.40452289699999],
                [30.84015846200009, 2.404548883000189],
                [30.840749739999978, 2.40445995400006],
                [30.841045379000036, 2.40436101],
                [30.84146118100017, 2.403918029000067],
                [30.841581344000076, 2.403791905000105],
                [30.841915132000167, 2.40284299800004],
                [30.842048645000091, 2.401894094],
                [30.842208863, 2.401415109000084],
                [30.842582703000062, 2.401036024000064],
                [30.843147277000014, 2.400602103000153],
                [30.843603134000034, 2.400459052000144],
                [30.844409943000073, 2.399672985000166],
                [30.84507942200014, 2.398453950000146],
                [30.845748900999979, 2.397505046000106],
                [30.845949173000065, 2.396754981000129],
                [30.845409393000182, 2.395724058000042],
                [30.845111847000055, 2.395199060000095],
                [30.844894409000176, 2.394330025000045],
                [30.844839096000157, 2.393832921000126],
                [30.845188140000062, 2.393137931000183],
                [30.845939636000082, 2.391999960000078],
                [30.846286774000191, 2.391330957000093],
                [30.846538544000055, 2.389405966000027],
                [30.846939087000067, 2.388447046000124],
                [30.847448349000103, 2.387490034000166],
                [30.847824096000181, 2.386993886000141],
                [30.847795487000042, 2.385989904000041],
                [30.84790039000012, 2.38515806200013],
                [30.848476409000057, 2.384326936000093],
                [30.849416733000055, 2.383595944000035],
                [30.85000801100017, 2.383352995000053],
                [30.850706101000185, 2.382946969000045],
                [30.851484298000173, 2.38264107700013],
                [30.852397918000122, 2.382452965000027],
                [30.853231430000164, 2.382308961000035],
                [30.853796006000152, 2.381975890000092],
                [30.854091645000153, 2.381496906000052],
                [30.854492188000165, 2.380502939000166],
                [30.855281830000138, 2.378479004000099],
                [30.85554885800002, 2.377691985000126],
                [30.85575294500012, 2.37749409800017],
                [30.856569291000028, 2.377269030000036],
                [30.85664749200015, 2.377250909],
                [30.857805252000048, 2.377080918000104],
                [30.859230041000046, 2.376749038000128],
                [30.859983444000136, 2.376579045000028],
                [30.860788346000106, 2.376461982000024],
                [30.861783980999974, 2.376652956000044],
                [30.862886429000071, 2.376781940000058],
                [30.863048554000102, 2.37677311900012],
                [30.86363983200016, 2.376738072000137],
                [30.864473344000032, 2.376404047000108],
                [30.864995956000087, 2.375911951000091],
                [30.865447997000103, 2.375009059000149],
                [30.86548805200016, 2.374037028000089],
                [30.864948273000152, 2.372364045000154],
                [30.86490631200013, 2.371469021000166],
                [30.86490631200013, 2.371279002000165],
                [30.865280151999968, 2.370899916000099],
                [30.866193772000088, 2.370177985000112],
                [30.866973876000145, 2.36982607900012],
                [30.867940904000022, 2.369781972000112],
                [30.868068695000034, 2.369766474000073],
                [30.875951767, 2.35399937700015],
                [30.880970002000026, 2.345998286999986],
                [30.885951995000028, 2.34399914800008],
                [30.912046433000114, 2.339432716999966],
                [30.925951005, 2.336999178000156],
                [30.926830291000101, 2.336823225000103],
                [30.930965424000021, 2.336006879000024],
                [30.930999756000062, 2.335999966000088],
                [30.934999466000022, 2.338000060000184],
                [30.936044693000099, 2.339044094000087],
                [30.937000275000116, 2.339999914000089],
                [30.940999985000133, 2.352999925000063],
                [30.937999724000122, 2.375000001000103],
                [30.944000243000062, 2.393000127000164],
                [30.947999955000057, 2.39800000200006],
                [30.957658768000101, 2.403942585000152],
                [30.957708358000161, 2.403862954000033],
                [30.957853317000115, 2.403510095000058],
                [30.957773208000162, 2.402894973000116],
                [30.957689285000072, 2.401963950000095],
                [30.957420349000188, 2.401484012000026],
                [30.957069398000044, 2.400651931000027],
                [30.956745148000039, 2.400118113000076],
                [30.956554414000038, 2.399739028000056],
                [30.956878662000065, 2.399430991000031],
                [30.957279205000077, 2.398880005000137],
                [30.957843781000065, 2.397949934000167],
                [30.958595277000086, 2.397615911000173],
                [30.959589004000122, 2.39735603400004],
                [30.9597778320001, 2.397572994000086],
                [30.960155487000179, 2.397744894000084],
                [30.960613251000041, 2.39790892700006],
                [30.961071015000073, 2.398488044000089],
                [30.961530685000128, 2.399175882000179],
                [30.962121964000119, 2.399375916000054],
                [30.962579726000115, 2.399637938000012],
                [30.962902069000052, 2.400235891000023],
                [30.963390351000157, 2.40095091000012],
                [30.963579178000032, 2.401474952000172],
                [30.964200974000164, 2.402815104000183],
                [30.965061187, 2.403439999000113],
                [30.965909957000065, 2.403767109000171],
                [30.966447830000106, 2.403840065000168],
                [30.966690064000034, 2.403677940000023],
                [30.967039108000165, 2.403650999000149],
                [30.967227936000143, 2.403769017000172],
                [30.96763038500012, 2.403678895000155],
                [30.967765809000184, 2.403489114000024],
                [30.967765809000184, 2.403271914000129],
                [30.967926025999986, 2.403009891000068],
                [30.968168258000162, 2.402865887000019],
                [30.968624114000193, 2.402894021000066],
                [30.968893052000055, 2.403029920000051],
                [30.969137191000129, 2.403247118000081],
                [30.96932602000004, 2.403582096000037],
                [30.96989059399999, 2.403918029000067],
                [30.970537186000058, 2.403872967000041],
                [30.971073151000098, 2.403801918000113],
                [30.971315383000046, 2.403856039000061],
                [30.971586228000149, 2.40426397300007],
                [30.972043992000181, 2.404545068000175],
                [30.9727973950001, 2.40474510199999],
                [30.974060059000124, 2.404575110000167],
                [30.975002287999985, 2.404766083000084],
                [30.975254060000054, 2.405056000000172],
                [30.97532653799999, 2.40522790000017],
                [30.975328446000162, 2.405607938000173],
                [30.975488663000192, 2.405780076000042],
                [30.975866318000101, 2.405843018999974],
                [30.977050782000049, 2.406089068000028],
                [30.977386475000174, 2.406224967000128],
                [30.977977753000062, 2.406244040000047],
                [30.978408813000044, 2.406299115000081],
                [30.97843551700015, 2.406634093000093],
                [30.978813172, 2.406941891000145],
                [30.979541778000112, 2.407124042000021],
                [30.979673385000069, 2.40716004300009],
                [30.979915619, 2.407206058000043],
                [30.980508804000181, 2.407469035000077],
                [30.980749130000163, 2.407406092000088],
                [30.981046676000062, 2.407233954000049],
                [30.981369019, 2.407335044000092],
                [30.981798172000083, 2.407236099000158],
                [30.982093811000141, 2.406883955000126],
                [30.982202530000166, 2.406712055000128],
                [30.982635498000093, 2.406713010000146],
                [30.983007431000033, 2.406713010000146],
                [30.983249665000187, 2.406523943000025],
                [30.983545303000142, 2.406143903000043],
                [30.983840941999972, 2.405854939999983],
                [30.98413658100003, 2.405828953000082],
                [30.984727859000088, 2.405857087000072],
                [30.985614776000034, 2.405668021000054],
                [30.986448286999973, 2.405579089000185],
                [30.986986160000185, 2.405551910000156],
                [30.987928391000082, 2.405961037000168],
                [30.98853492700016, 2.406096936000154],
                [30.988964080000073, 2.405908107000073],
                [30.989152909000154, 2.405925990000185],
                [30.989271164000058, 2.405991078000056],
                [30.989501953000058, 2.406116962000169],
                [30.989799499000185, 2.406407118000061],
                [30.98994255100007, 2.40636992400016],
                [30.99030876099999, 2.406290055000056],
                [30.990846633000103, 2.406172992000052],
                [30.991035462000184, 2.406055927000182],
                [30.991302490000066, 2.405694007000079],
                [30.991409302000022, 2.4054319870001],
                [30.991678236999974, 2.405432940000083],
                [30.991706848000149, 2.405721903000085],
                [30.991895675000023, 2.406012059000148],
                [30.992324830000143, 2.405985118000103],
                [30.992620469000144, 2.405885936000061],
                [30.992755890000069, 2.405724049000071],
                [30.992942809000169, 2.405579089000185],
                [30.993185043000153, 2.405625106000173],
                [30.993507385000157, 2.405580043999976],
                [30.993616103000136, 2.405481100000145],
                [30.994070054000076, 2.405029058000082],
                [30.994340897000029, 2.404676914000049],
                [30.994743348000043, 2.404532910000057],
                [30.995038987000044, 2.404633045000082],
                [30.995441436000078, 2.404706001000079],
                [30.99589920000011, 2.404508114000123],
                [30.996408463000023, 2.403820992000135],
                [30.996730804000151, 2.403315068000154],
                [30.997079850000091, 2.402935982999964],
                [30.997562408000022, 2.402339935000043],
                [30.997991563000141, 2.402033091000078],
                [30.998447419000172, 2.401789904000111],
                [30.999172211000086, 2.401221037000028],
                [30.999843597000051, 2.400697948000186],
                [30.999977111000078, 2.400671006000096],
                [31.000398636000057, 2.400372983000182],
                [31.000682831000063, 2.40013790200004],
                [31.001039506000154, 2.399542094000083],
                [31.001228332000153, 2.399324894000074],
                [31.002250672000173, 2.398992063000151],
                [31.002725602000055, 2.398821115000033],
                [31.003345489000083, 2.399012089000053],
                [31.004060745000118, 2.399420023000062],
                [31.004154204000088, 2.39947891300011],
                [31.00429153499999, 2.399564982000015],
                [31.004537582000012, 2.399709939000047],
                [31.004966735000096, 2.400262118000171],
                [31.005538941, 2.400768996000181],
                [31.00620460500005, 2.401123048000159],
                [31.006420135000099, 2.401386023000157],
                [31.006704331000151, 2.401412963999974],
                [31.007026672000109, 2.40147709900009],
                [31.007690430000139, 2.401071073000026],
                [31.008094787000118, 2.40078210900009],
                [31.008428574000106, 2.400376083000083],
                [31.008720398000094, 2.400114060000021],
                [31.009258271000078, 2.399635076000152],
                [31.010089875000176, 2.399013042000036],
                [31.01042175300006, 2.398416043000111],
                [31.010410310000111, 2.397917985000106],
                [31.010387421000075, 2.3976109030001],
                [31.010238647000051, 2.395720960000119],
                [31.010332108000057, 2.39489007100002],
                [31.010614395000061, 2.393786907000049],
                [31.011230468000122, 2.392566919000046],
                [31.011657716000116, 2.391681910000045],
                [31.011703491000105, 2.391084910000075],
                [31.011798858000077, 2.390460968000127],
                [31.012248993000128, 2.390100002000111],
                [31.012914657000124, 2.389910937000025],
                [31.013795853000033, 2.389867068000172],
                [31.014364243000159, 2.389624119000075],
                [31.014816284000176, 2.389100075000044],
                [31.015041145, 2.388847344],
                [31.015405141000144, 2.388916861000155],
                [31.015802383000107, 2.388993025000104],
                [31.016304859000115, 2.389085282000167],
                [31.016445160000103, 2.389111041999968],
                [31.016698098000063, 2.389067049000175],
                [31.017113001000041, 2.388995001000069],
                [31.018326000000116, 2.388707000000125],
                [31.018880072000115, 2.388615627000092],
                [31.019466399000066, 2.388519050000014],
                [31.02007633400018, 2.388364751000154],
                [31.020107179000149, 2.388356948000137],
                [31.020178009000119, 2.388332863000187],
                [31.020715713000072, 2.388149977000126],
                [31.020927479000136, 2.387866943000176],
                [31.021236420000037, 2.38745403199999],
                [31.021282197, 2.38697505000016],
                [31.021139145000063, 2.386641027000167],
                [31.021061036000106, 2.386430368000049],
                [31.020900999000105, 2.385998000000143],
                [31.020862927000167, 2.385724717000187],
                [31.020828248000043, 2.385473967000053],
                [31.020871406000026, 2.385340086000099],
                [31.020922000000155, 2.385184000000152],
                [31.021207946000175, 2.384995036000134],
                [31.021707534000143, 2.385185003000117],
                [31.021802901000115, 2.385546923000049],
                [31.021815940000067, 2.385610437000139],
                [31.021875382000189, 2.385900022000044],
                [31.022237773000029, 2.386077759000102],
                [31.022375108000119, 2.38614511600008],
                [31.022899785000106, 2.386497967000025],
                [31.023047315000042, 2.38649096600011],
                [31.02325630200005, 2.386481047000075],
                [31.023407671000029, 2.386381195000183],
                [31.023448943000062, 2.386353971000062],
                [31.023826599000074, 2.386120082000161],
                [31.023803710000038, 2.38597488500011],
                [31.023635865000074, 2.385595083000112],
                [31.023468021000042, 2.385260079000148],
                [31.023472700000184, 2.385231703999978],
                [31.023516, 2.384971000000121],
                [31.023534753000035, 2.3848918220001],
                [31.023561461000156, 2.38478095500011],
                [31.02401399900009, 2.384736998999983],
                [31.024965000000179, 2.384666],
                [31.02546695500007, 2.3848829800001],
                [31.025657654000099, 2.385164023000186],
                [31.025725601000147, 2.385504433000108],
                [31.025825001000044, 2.386004999000136],
                [31.025796268000022, 2.386432156000183],
                [31.025779725000177, 2.386673928000107],
                [31.025812214000098, 2.386753614000156],
                [31.025850296000044, 2.386847019000129],
                [31.025935007000044, 2.387019403000011],
                [31.026027679000094, 2.387207986000078],
                [31.02608398600006, 2.387222275000113],
                [31.026279337000119, 2.387271854000062],
                [31.026611329000048, 2.387010097000143],
                [31.026968003000093, 2.386414051000031],
                [31.0270862590001, 2.386051894000161],
                [31.027083092000112, 2.386016270000084],
                [31.027078628000083, 2.385966063000069],
                [31.027036667000061, 2.385499954000181],
                [31.026702881000176, 2.384596109000086],
                [31.026708808000024, 2.384564248000174],
                [31.026718139000025, 2.38451409400011],
                [31.026773452000043, 2.384233952000045],
                [31.026855468000122, 2.384152413000152],
                [31.027153015000124, 2.383855104000134],
                [31.027729388000125, 2.383650410000087],
                [31.028293610000105, 2.383450032000042],
                [31.028405160999966, 2.383307246000129],
                [31.028484344000049, 2.383205891000159],
                [31.028428240000039, 2.383037947000105],
                [31.028411866000113, 2.382988930000067],
                [31.028299910000044, 2.382900771000152],
                [31.028055192000068, 2.382708073000174],
                [31.027721406000182, 2.382417917000169],
                [31.027658102000089, 2.38212534600018],
                [31.027649, 2.382082999000147],
                [31.027649, 2.381649],
                [31.027663474000178, 2.381552032000172],
                [31.027742387000046, 2.381026029000111],
                [31.027740479000045, 2.380744935000109],
                [31.027908325000112, 2.380645991000108],
                [31.028046184000061, 2.380577200000175],
                [31.028144836000024, 2.380527973000085],
                [31.02933311400011, 2.379472971000041],
                [31.031694412000093, 2.377288103000069],
                [31.033666610000182, 2.375473022999984],
                [31.036373139000034, 2.372989891999964],
                [31.039068223000129, 2.370850087000122],
                [31.042133331000059, 2.368674995000106],
                [31.047822953000093, 2.364506007000102],
                [31.051481247000083, 2.361907005000035],
                [31.056457520000151, 2.358305931000132],
                [31.059379577000072, 2.356203080000171],
                [31.062437058000171, 2.353974104000088],
                [31.063516616000129, 2.353187562000016],
                [31.068946838000045, 2.342999219000035],
                [31.06961250400002, 2.340671540000074],
                [31.072946548000061, 2.328999281000051],
                [31.073379516000159, 2.325973272000056],
                [31.07343101500004, 2.325612307000085],
                [31.073696136000081, 2.323757648000026],
                [31.073947907000047, 2.321999073000086],
                [31.07594680800014, 2.31699919800019],
                [31.07594680800014, 2.30799913400017],
                [31.07594680800014, 2.304121255000041],
                [31.075399398000116, 2.30412006500012],
                [31.074636459000146, 2.304291010000156],
                [31.074209214000064, 2.304553033000047],
                [31.073949815000162, 2.304860115000054],
                [31.073617933999969, 2.305600882000022],
                [31.073547363000102, 2.305845023000018],
                [31.073429107999971, 2.306514025000126],
                [31.073406219000162, 2.30658602800014],
                [31.073381423000058, 2.306658984000137],
                [31.073263168000096, 2.306677102000037],
                [31.072525025000061, 2.306629897000164],
                [31.071716308000191, 2.306628942000145],
                [31.070692062000035, 2.306310892000113],
                [31.070026397999982, 2.305931092000151],
                [31.069715499000154, 2.305767060000051],
                [31.069358825000108, 2.305668115000117],
                [31.069288254000014, 2.305550099000129],
                [31.069311142000174, 2.305476904000045],
                [31.069858551000038, 2.305088998000088],
                [31.070831299000076, 2.304639102000181],
                [31.071521759000177, 2.304214954000088],
                [31.072578430000135, 2.303772926000079],
                [31.073314667000034, 2.303366900000071],
                [31.074218750000114, 2.30286192900013],
                [31.074670791000131, 2.302599906000012],
                [31.075239182000189, 2.302194119000092],
                [31.075452805, 2.301860094000119],
                [31.075475694000033, 2.301642894000054],
                [31.075546265000128, 2.300928114999977],
                [31.075473786000089, 2.300421954000115],
                [31.075138093000078, 2.299607038000033],
                [31.075162887000033, 2.299201012000026],
                [31.075279237000018, 2.298964978000186],
                [31.075803757000074, 2.298559904000058],
                [31.076492309000059, 2.298388959000022],
                [31.076993942000058, 2.298489094000047],
                [31.077564239000083, 2.298680067000134],
                [31.077730179000127, 2.298665047000156],
                [31.078062057000068, 2.29863500600004],
                [31.078420639000058, 2.298490048000133],
                [31.07860946600016, 2.29829192200009],
                [31.078798294000137, 2.297985077000021],
                [31.079084396000042, 2.297840118000181],
                [31.079750061000141, 2.297389029999977],
                [31.08029556200006, 2.297028065000063],
                [31.080486298000039, 2.297055006000051],
                [31.080915451000067, 2.297173977000057],
                [31.08117675800014, 2.297318936000067],
                [31.081512452000027, 2.297888995000051],
                [31.081678390000093, 2.297961951000048],
                [31.082035064000081, 2.297961951000048],
                [31.082284928000149, 2.297925950000035],
                [31.082309724000083, 2.298142910000024],
                [31.08240318300011, 2.29845261700018],
                [31.091945648000149, 2.29299926900012],
                [31.103946685000039, 2.282999278000091],
                [31.103977204000159, 2.282978059000186],
                [31.104204178000089, 2.282819272000097],
                [31.104257583000162, 2.282708884000044],
                [31.10451889, 2.282428981000066],
                [31.105255127000135, 2.281760932000111],
                [31.105373382000039, 2.281372071000021],
                [31.105396271000075, 2.281110049000176],
                [31.10537147500014, 2.280585052000163],
                [31.105226518000165, 2.280133009000167],
                [31.105226518000165, 2.279598951000025],
                [31.105224610000164, 2.279074908000098],
                [31.105199814000059, 2.278740883000069],
                [31.105463029000077, 2.278768062000097],
                [31.105699540000046, 2.278669120000075],
                [31.105936051000128, 2.278506040000082],
                [31.106079101000091, 2.278307915000141],
                [31.106199264000168, 2.278028012000164],
                [31.10626983700007, 2.277900934000115],
                [31.106412888000079, 2.277828933000137],
                [31.106769562000068, 2.277856112000166],
                [31.107126237000045, 2.278002024000159],
                [31.107412338000188, 2.278173924000157],
                [31.107721330000174, 2.278218984000148],
                [31.108053208000058, 2.278029920000165],
                [31.108219146000124, 2.277713060000053],
                [31.108409882000103, 2.277550935000022],
                [31.108707428, 2.277487994000069],
                [31.109277725000027, 2.277036906000035],
                [31.109346391000031, 2.276629924000133],
                [31.109632492000173, 2.275888920000114],
                [31.109750747000135, 2.275698901000112],
                [31.110059739000121, 2.275382996000133],
                [31.110366821000127, 2.275120974000174],
                [31.11074829100005, 2.27495908800006],
                [31.110984803000065, 2.274760009000033],
                [31.11129379200014, 2.274497987000189],
                [31.111436844000082, 2.274163961000113],
                [31.11167335500005, 2.274000883000099],
                [31.11188697800003, 2.27387499800011],
                [31.112171172999979, 2.273930073000088],
                [31.112600326000063, 2.273740054000086],
                [31.112623215000099, 2.273541927000167],
                [31.11280250499999, 2.27312588699999],
                [31.113063813000167, 2.273053883999978],
                [31.113466264000124, 2.273009062000028],
                [31.11399078400018, 2.272891999000024],
                [31.114538192000168, 2.272794009000108],
                [31.114728928000147, 2.272892952000177],
                [31.11501312200005, 2.273102046000076],
                [31.115324021000106, 2.273463966000179],
                [31.115547179000089, 2.27357888099999],
                [31.11565780699999, 2.273636104000048],
                [31.116062163000095, 2.273420096000109],
                [31.116275788000053, 2.273175955000056],
                [31.116441726000119, 2.272823096000082],
                [31.116678237000087, 2.272561074000066],
                [31.116916656000171, 2.272461891000148],
                [31.117177963000188, 2.272490025000138],
                [31.11751174900013, 2.27282500300015],
                [31.11797142000006, 2.273181438000052],
                [31.122997283000132, 2.269662858000118],
                [31.123081207000155, 2.269206047000068],
                [31.123104096000191, 2.268626929000106],
                [31.123294830000134, 2.268599988000062],
                [31.123746871000151, 2.268699885000103],
                [31.124246597000081, 2.268366099000161],
                [31.124292374000049, 2.268076896000139],
                [31.124601363000124, 2.267507077000175],
                [31.124744415000066, 2.267291069000066],
                [31.124717711000187, 2.266766072000053],
                [31.124860763000129, 2.266576053000051],
                [31.125265122000087, 2.266449929000089],
                [31.125478744000191, 2.2664780610001],
                [31.125860215000046, 2.26643300100011],
                [31.126216889000034, 2.266189098000098],
                [31.126810074000048, 2.26611804900017],
                [31.127119064000169, 2.266171932000077],
                [31.127428056000156, 2.266191005000167],
                [31.127714157000128, 2.265974045000121],
                [31.127761841000165, 2.265810967000164],
                [31.128093719000105, 2.265640021000024],
                [31.128404617000058, 2.265640021000024],
                [31.128665924000074, 2.265784979000159],
                [31.129261017000033, 2.265830993],
                [31.129570006000108, 2.265696049000042],
                [31.129926681000029, 2.265569926000183],
                [31.13037681600008, 2.265263081000114],
                [31.130472183000052, 2.265045881000106],
                [31.130638123000097, 2.264712096000096],
                [31.130994798000074, 2.26481199300008],
                [31.131280898999989, 2.264975071000094],
                [31.131828307000035, 2.265048027000091],
                [31.132232666000164, 2.265192986000102],
                [31.132375717000173, 2.2653648860001],
                [31.132566452000049, 2.2654559610001],
                [31.132757188000028, 2.265645982000081],
                [31.132806778000031, 2.265908004000096],
                [31.132806778000031, 2.266079902000115],
                [31.132354737000071, 2.26622390700004],
                [31.132282257999975, 2.266297102000124],
                [31.132307052000101, 2.266386987000033],
                [31.132450104000043, 2.266966104000062],
                [31.1328086850001, 2.267636060000086],
                [31.132810593000102, 2.268043041999988],
                [31.132692337000094, 2.268449068000052],
                [31.132358552000085, 2.268594027],
                [31.132217408000145, 2.268711091000114],
                [31.132312775000116, 2.269073010000113],
                [31.132633210000108, 2.269272089000026],
                [31.132919311000023, 2.269535064000024],
                [31.1330394740001, 2.269706965000125],
                [31.133041381, 2.270277024000109],
                [31.13318443300011, 2.270565987000111],
                [31.13318443300011, 2.270883083000058],
                [31.13304328900017, 2.271071911000036],
                [31.13304328900017, 2.271406889000048],
                [31.133281708000084, 2.271497966000027],
                [31.133495330000187, 2.271642923000059],
                [31.133758545000035, 2.271642923000059],
                [31.1337337490001, 2.271362067000098],
                [31.133661270000061, 2.271190882000042],
                [31.133684159000097, 2.271090985000058],
                [31.134351730000162, 2.271047115000158],
                [31.134826660000044, 2.270829917000128],
                [31.1351604460001, 2.270641089000094],
                [31.135349274000077, 2.270641089000094],
                [31.135469436999983, 2.270759106000185],
                [31.135398864000138, 2.27104807000012],
                [31.135185242000034, 2.271553994000044],
                [31.13525772, 2.271790027000122],
                [31.135614395000118, 2.272106887000064],
                [31.135686875000033, 2.272315025000069],
                [31.135662079000156, 2.272459031000096],
                [31.135520934999988, 2.272747995000032],
                [31.135450364000121, 2.273037911000188],
                [31.135568619000026, 2.273109914000031],
                [31.135713577000161, 2.273299933000033],
                [31.13599777200011, 2.273400068000058],
                [31.136236191000023, 2.27322888499998],
                [31.136449814000059, 2.27322888499998],
                [31.136331558, 2.273608922999983],
                [31.136285781000026, 2.274115086000052],
                [31.13640594400016, 2.274549007000189],
                [31.136573792000149, 2.274883984999974],
                [31.136573792000149, 2.275192022000169],
                [31.136383058000035, 2.275362970000117],
                [31.136335373000065, 2.275552989000119],
                [31.136432648000039, 2.275841952000121],
                [31.136587143000099, 2.27606892600005],
                [31.136611939000034, 2.276213885000061],
                [31.136802673000034, 2.276213885000061],
                [31.136968613000079, 2.276258947000088],
                [31.136968613000079, 2.276331903000084],
                [31.136968613000079, 2.276422024000169],
                [31.136802673000034, 2.27676510800012],
                [31.136802673000034, 2.276954889000081],
                [31.13692283600011, 2.277235985000061],
                [31.13775634700005, 2.277789116000122],
                [31.138042450000171, 2.278033018000031],
                [31.138092041000107, 2.278630018000058],
                [31.138116837000041, 2.278892040000073],
                [31.138235092000116, 2.279154062000089],
                [31.138498307000134, 2.279326916000173],
                [31.138616562000095, 2.279472112000121],
                [31.138879775000078, 2.279851913000186],
                [31.138856889000181, 2.280041934000167],
                [31.13861847000004, 2.280421019000187],
                [31.138620377000109, 2.280637980000108],
                [31.138738633000116, 2.28082799900011],
                [31.139072419000058, 2.280883074000144],
                [31.139429093000047, 2.281028033000155],
                [31.139787675000093, 2.281553030000168],
                [31.140430450000053, 2.282124042000135],
                [31.140644073000033, 2.282250882000142],
                [31.140977860000078, 2.282223941000154],
                [31.141286850000029, 2.282033920000117],
                [31.141548157000102, 2.281934978000152],
                [31.141691208999987, 2.282269956000107],
                [31.141645432000018, 2.282368898000129],
                [31.141073227000049, 2.282531023000161],
                [31.140954972000088, 2.282658099000173],
                [31.140920638000125, 2.282812119000141],
                [31.141063690000067, 2.283073901000137],
                [31.141088486000172, 2.283191919000103],
                [31.141088486000172, 2.283409119000169],
                [31.141111374000104, 2.283580065000081],
                [31.141349793000188, 2.283581020000099],
                [31.141683579000073, 2.283453941000118],
                [31.142087935000177, 2.283454894000101],
                [31.14232635400009, 2.283555031000162],
                [31.142826080000191, 2.28377294600017],
                [31.143205643000101, 2.283791065000116],
                [31.143419264999977, 2.283845902000167],
                [31.14357566800004, 2.283821106000062],
                [31.14375305100009, 2.283792020000135],
                [31.143896103000031, 2.283628940000142],
                [31.144323350000093, 2.283512116000054],
                [31.144847870000149, 2.283513069000037],
                [31.145109177, 2.283485890000179],
                [31.145250321000162, 2.283314943000164],
                [31.145584106000172, 2.283225061],
                [31.145845414000121, 2.283035039000083],
                [31.146322251000186, 2.28310799500008],
                [31.146345139000118, 2.283252955000023],
                [31.146797181000068, 2.283271075000073],
                [31.147155762000182, 2.28334403100007],
                [31.147558212000035, 2.283488988000102],
                [31.14777374200014, 2.283516884000107],
                [31.148094177000075, 2.283453941000118],
                [31.148616791000165, 2.283147097000153],
                [31.148950577000051, 2.2829029560001],
                [31.149139403000049, 2.282948970000177],
                [31.149282455000161, 2.282975912000097],
                [31.14923668, 2.283148050000136],
                [31.149188995000088, 2.283292056000164],
                [31.149427414000172, 2.283428908000076],
                [31.149784088000047, 2.283310890000109],
                [31.149974822000161, 2.283122063],
                [31.149948121000193, 2.282903911000119],
                [31.149759292000113, 2.28281402600004],
                [31.149591447000148, 2.282686948000162],
                [31.149663925000141, 2.282380104000026],
                [31.149684906000175, 2.282017947000156],
                [31.149993896000126, 2.281538964000049],
                [31.150207520000038, 2.281303883000021],
                [31.150396347000139, 2.281322957000043],
                [31.150468826000179, 2.281522036000069],
                [31.150611877000017, 2.281729936000033],
                [31.150825500000053, 2.281685114000084],
                [31.150779725000064, 2.281567098000039],
                [31.151182174000041, 2.281306028000131],
                [31.151515960000154, 2.281469106000145],
                [31.151729584000066, 2.281279087000144],
                [31.151634217000094, 2.281017065000128],
                [31.151775359, 2.280899049000141],
                [31.152204514000118, 2.28099107800017],
                [31.152383805000113, 2.280955077000158],
                [31.15252685500019, 2.280765058000156],
                [31.152572632000158, 2.280575037000119],
                [31.152643205000061, 2.280358076000027],
                [31.152952194000136, 2.280484915000159],
                [31.153263091000042, 2.280720949000113],
                [31.153739929000039, 2.280622006000044],
                [31.154214859000092, 2.28055000300003],
                [31.154476166000109, 2.280721903000028],
                [31.154596329000185, 2.280795097000066],
                [31.155048370000031, 2.280651093000017],
                [31.155380248000142, 2.280913115000033],
                [31.155548096000189, 2.281130075000078],
                [31.155548096000189, 2.281419993000043],
                [31.155668259000095, 2.281682016000161],
                [31.155858993000038, 2.281913997000061],
                [31.155906678000179, 2.281971932000147],
                [31.156238556000119, 2.281846046000055],
                [31.156452178000166, 2.281367064000051],
                [31.156475067000031, 2.281059027000026],
                [31.156808853000143, 2.280987024000012],
                [31.157356263000111, 2.280942916000129],
                [31.157545090000042, 2.280699015000096],
                [31.157545090000042, 2.280491115000132],
                [31.157806397000058, 2.280147076000162],
                [31.158044815000039, 2.280201912000109],
                [31.158021927000107, 2.280509949000077],
                [31.158258438000075, 2.280680894000113],
                [31.158260345000144, 2.280899049000141],
                [31.158426286000122, 2.281133890000092],
                [31.158664704000103, 2.281179905000101],
                [31.159093857000187, 2.281234980000136],
                [31.159521102000099, 2.281253101000118],
                [31.159973145000095, 2.280874013000187],
                [31.160066605, 2.280611038000188],
                [31.160305024000138, 2.280323030000147],
                [31.160327912000071, 2.28003311100008],
                [31.160421372000144, 2.279743910000036],
                [31.160827636000192, 2.279726982000057],
                [31.161111832000074, 2.279483080000091],
                [31.161134720000177, 2.27927494100004],
                [31.161325454000178, 2.279103040000109],
                [31.161468506000119, 2.27924799900012],
                [31.161682128, 2.279175996000106],
                [31.161657332000118, 2.279031039000131],
                [31.161466598000118, 2.278769017000116],
                [31.161657332000118, 2.27848005300018],
                [31.161869049000131, 2.27833509400017],
                [31.16223907400007, 2.27822709100019],
                [31.162452697000106, 2.277992010000048],
                [31.162595749000047, 2.278228044000173],
                [31.162763596000161, 2.278543949000152],
                [31.162954330000105, 2.278733968000154],
                [31.163499831000024, 2.278354883000134],
                [31.163976669000078, 2.278228999000021],
                [31.164094924000153, 2.278563977000033],
                [31.164310456000067, 2.278635978000182],
                [31.164714813000046, 2.278664112000058],
                [31.165237427000079, 2.278593064000177],
                [31.165689469000029, 2.278593064000177],
                [31.165809632000105, 2.278836965000039],
                [31.166046143000074, 2.279046060000042],
                [31.166332243999989, 2.279027939],
                [31.166690826000035, 2.278911114000039],
                [31.167594910000048, 2.278884887000118],
                [31.168069840000044, 2.279175043000123],
                [31.16838073700012, 2.279582025000025],
                [31.168975830000079, 2.279628039000102],
                [31.169078827000135, 2.279691935000074],
                [31.16928482000003, 2.279819011000086],
                [31.169570923000038, 2.280180931000018],
                [31.169809342000121, 2.280397893000043],
                [31.170095443000093, 2.280443908000052],
                [31.170309067000176, 2.28056192400004],
                [31.170501709000121, 2.280951024000046],
                [31.1707153320001, 2.280896902000052],
                [31.171119690000182, 2.280924083000059],
                [31.171333313000162, 2.281023980000043],
                [31.171571732000075, 2.281141996000088],
                [31.171833039000148, 2.281069995000053],
                [31.172048569000026, 2.281142951000049],
                [31.172309876000043, 2.28154993000004],
                [31.17228698800011, 2.281785011000068],
                [31.17214584400017, 2.282263995000108],
                [31.172075271000097, 2.282788038000035],
                [31.172170640000104, 2.283051015000183],
                [31.172445298000071, 2.283068896000145],
                [31.172801972000116, 2.283332111000163],
                [31.173183442000038, 2.283550024000022],
                [31.173255921000077, 2.283766031000027],
                [31.173349380000104, 2.284002064999982],
                [31.173564910000152, 2.283901930000184],
                [31.173826217, 2.283668041000055],
                [31.173969269000111, 2.283740997000052],
                [31.173921585000073, 2.283902885000146],
                [31.173898696000094, 2.284219979999989],
                [31.174112321000052, 2.284508943000049],
                [31.174352647000035, 2.284699917000182],
                [31.174875259000146, 2.285269976],
                [31.175043107000192, 2.28537011100002],
                [31.175304412, 2.285197973000038],
                [31.175565719000076, 2.285080910000033],
                [31.175827026000093, 2.284746885000175],
                [31.1759452820001, 2.28451204300012],
                [31.175849915000128, 2.284249068000122],
                [31.175991059000069, 2.284076928000047],
                [31.176181793000069, 2.283960105000062],
                [31.176324844000078, 2.284050942000079],
                [31.176563263000162, 2.284385920000034],
                [31.176801682000075, 2.284341098000084],
                [31.177061081000147, 2.284296036000058],
                [31.177347183000052, 2.28426909500007],
                [31.177776337000182, 2.284080028000176],
                [31.178039550000051, 2.284388065000144],
                [31.178300857000067, 2.284441948000051],
                [31.17844390900018, 2.284631967000053],
                [31.178396224000039, 2.284894944000087],
                [31.178516387000116, 2.285202026000093],
                [31.178850173000058, 2.285474061000116],
                [31.179086686000176, 2.285347938000086],
                [31.179206847000046, 2.285185100000092],
                [31.179372787000091, 2.285113097000078],
                [31.179397583000025, 2.28527498200009],
                [31.179681777000098, 2.285275937000051],
                [31.179943084000115, 2.285186053000075],
                [31.180086136000057, 2.285348893000048],
                [31.180395125000132, 2.285331012000086],
                [31.180753707000179, 2.28504204800015],
                [31.180942536000032, 2.284996986000181],
                [31.181013107000126, 2.285187005000182],
                [31.180944443000101, 2.28552198400007],
                [31.181158066000137, 2.28561210700002],
                [31.181634903000031, 2.285739899000078],
                [31.181802749000099, 2.286075115000131],
                [31.182016373000181, 2.286427976000084],
                [31.182277679000151, 2.286554099000114],
                [31.182535171000097, 2.286555052000097],
                [31.182895659999986, 2.286382914000114],
                [31.183038712000098, 2.286384106000185],
                [31.183063508000032, 2.286500932000081],
                [31.183277130000135, 2.286645889000113],
                [31.183872223000094, 2.286647081000183],
                [31.184062957000037, 2.286556959],
                [31.184156419000146, 2.286293984],
                [31.18434715300009, 2.286096095000062],
                [31.184560775000193, 2.286123037000152],
                [31.184610368000108, 2.286484955999981],
                [31.184919357000012, 2.286648035999974],
                [31.185205460000191, 2.286530971000161],
                [31.185300827000162, 2.28662991499999],
                [31.18541908300017, 2.286820889000182],
                [31.18541908300017, 2.287055969999983],
                [31.185729980000019, 2.287301064000189],
                [31.185541154000077, 2.287707091000129],
                [31.185541154000077, 2.287796975000106],
                [31.185850143000152, 2.287941932000138],
                [31.186124801000119, 2.288268090000088],
                [31.186363220000032, 2.288413049000099],
                [31.18667221200019, 2.288431882000168],
                [31.186815261000049, 2.288558961000092],
                [31.18676948600006, 2.288839101000178],
                [31.186792373000117, 2.289011002000109],
                [31.187078476000067, 2.289110899000093],
                [31.187292099000047, 2.289057017000118],
                [31.187387466000075, 2.288985013000172],
                [31.187482835000026, 2.289057017000118],
                [31.187934876000043, 2.289346933000104],
                [31.188673020000181, 2.289493084000014],
                [31.189386369000147, 2.289655923999987],
                [31.189483643000187, 2.289783001000103],
                [31.189863204000062, 2.2898559570001],
                [31.190292359000125, 2.28997397400002],
                [31.190910340000016, 2.290002108000067],
                [31.191314697000166, 2.290066003999982],
                [31.192005157000096, 2.290093899999988],
                [31.192623138000158, 2.290122032],
                [31.192886353000176, 2.290194035000013],
                [31.193195343000184, 2.29024004900009],
                [31.193624497000144, 2.290241004000109],
                [31.193767547000107, 2.290339947],
                [31.194004058000019, 2.29043102300011],
                [31.194576262000112, 2.290431976000093],
                [31.194789887000127, 2.29045891700008],
                [31.194837570000061, 2.290386916000102],
                [31.195123673000012, 2.290431976000093],
                [31.195766448000029, 2.290477991000103],
                [31.196027755000046, 2.290388107000126],
                [31.196170807000158, 2.290316104999988],
                [31.196359634000089, 2.290361882000127],
                [31.196859359000143, 2.290462016999982],
                [31.197217941000133, 2.290553094000188],
                [31.197265626000046, 2.290750981000087],
                [31.197288514000036, 2.291013956000086],
                [31.197456360000047, 2.291086912000083],
                [31.197622300000091, 2.291013956000086],
                [31.197835922000024, 2.290843010000174],
                [31.198097230000144, 2.29077100700016],
                [31.198312760000192, 2.290843010000174],
                [31.19905090400016, 2.291060925000181],
                [31.199155807000068, 2.291107178000175],
                [31.199384690000102, 2.291207074999988],
                [31.199455261000139, 2.291351079000037],
                [31.199432372000103, 2.29173111900019],
                [31.199789047000024, 2.291920901000083],
                [31.199541092000061, 2.292201996000188],
                [31.199399948000121, 2.292553903000055],
                [31.199541092000061, 2.292752982000081],
                [31.199661255000137, 2.292960882000102],
                [31.200088501000153, 2.292870998000126],
                [31.200849534000156, 2.292727948000163],
                [31.2012310020001, 2.292754890000083],
                [31.201255797999977, 2.2932069310001],
                [31.20146942000008, 2.29323506500009],
                [31.201801301000103, 2.292918920000147],
                [31.201944350000133, 2.292946100999984],
                [31.202112198000179, 2.293209075999982],
                [31.202064513000039, 2.293397903000084],
                [31.202445984000065, 2.293642999000099],
                [31.202590943000075, 2.293924094000033],
                [31.202638625000077, 2.294068099000128],
                [31.202875136000046, 2.294403077000141],
                [31.202995299000122, 2.294476033000137],
                [31.20304107600009, 2.294358015000114],
                [31.20295715300017, 2.293698072000154],
                [31.202970505000053, 2.290010929000175],
                [31.202978134000034, 2.287467002000085],
                [31.203025818000071, 2.281064034000167],
                [31.203044892000037, 2.274255038000092],
                [31.203042984000092, 2.268276930000184],
                [31.203098297000111, 2.261267902000043],
                [31.20303726100002, 2.260843039000179],
                [31.20308494600016, 2.260128021000128],
                [31.203083037999988, 2.259432078000032],
                [31.203079223000145, 2.25832891400006],
                [31.203063965000126, 2.25669193300007],
                [31.203109742000095, 2.25568795300012],
                [31.203138351000064, 2.254204988000083],
                [31.20315170399999, 2.253509046000033],
                [31.203149796000048, 2.252522945000067],
                [31.203195570999981, 2.251836062000166],
                [31.203170777000082, 2.250967979000052],
                [31.203168870000184, 2.249872923],
                [31.203163147000168, 2.249728919000177],
                [31.203145981000148, 2.249557018000076],
                [31.203128815000184, 2.248163938000118],
                [31.203121185000043, 2.24728703400001],
                [31.20313453599999, 2.243072987000119],
                [31.203149796000048, 2.237519979000069],
                [31.203117370000029, 2.227869987000076],
                [31.203159332000098, 2.226252079000176],
                [31.203220367000085, 2.223817349000115],
                [31.203231811000137, 2.223376036000104],
                [31.203046799000106, 2.221494913000186],
                [31.203107834000093, 2.221060991000172],
                [31.203498840000123, 2.220853091000038],
                [31.204309463000129, 2.220447064000098],
                [31.206237794000117, 2.219654084000183],
                [31.207712173000118, 2.219012976999977],
                [31.209203720000176, 2.21831011900008],
                [31.211549760000139, 2.21718192100019],
                [31.213146210000048, 2.216423988000088],
                [31.21555328300019, 2.215423108000039],
                [31.217569352000112, 2.214349986000116],
                [31.217870711999979, 2.214221955000085],
                [31.219648362000157, 2.213465929000051],
                [31.221874237000065, 2.212482929000089],
                [31.223876953, 2.211445092000076],
                [31.226465224000037, 2.210208894000118],
                [31.228782653, 2.209162950000064],
                [31.230529785000158, 2.208323956000129],
                [31.231721878000144, 2.207809925000106],
                [31.233509064000032, 2.207034111000041],
                [31.236593246000098, 2.205473900000186],
                [31.238700867000148, 2.204472065000175],
                [31.241380691000188, 2.203282117000128],
                [31.243637086000092, 2.202208042000166],
                [31.245382308000103, 2.201414109000098],
                [31.247550965000187, 2.200367928000162],
                [31.248392104000061, 2.19987106300016],
                [31.249206542000138, 2.19924807600006],
                [31.24989891000007, 2.198796987000094],
                [31.250768661000166, 2.19801092199998],
                [31.251188278000143, 2.197714091000023],
                [31.252199173000065, 2.19699096700009],
                [31.255147933000103, 2.194823981000184],
                [31.257102967000037, 2.193298102000142],
                [31.25911903400015, 2.191870928000185],
                [31.261465072000135, 2.190109968000172],
                [31.262475967000171, 2.189352037000049],
                [31.26421737600009, 2.188041926000096],
                [31.265691757000184, 2.186904907000098],
                [31.267587661000164, 2.185513974000173],
                [31.270175935000111, 2.183609008000133],
                [31.272310257000129, 2.182009935000053],
                [31.274297715000102, 2.18048501100003],
                [31.274972915000149, 2.17997908500007],
                [31.277379990000099, 2.178163052],
                [31.280929566000168, 2.175662041000123],
                [31.290481567000143, 2.168401958000118],
                [31.291925431000038, 2.167309999000054],
                [31.298604965000095, 2.162252902000091],
                [31.303569794000111, 2.158631087000174],
                [31.305704117, 2.157105923000074],
                [31.305702210000106, 2.155152082000143],
                [31.305686951000041, 2.152918100000079],
                [31.305660248000038, 2.148576021000054],
                [31.305669785000191, 2.14564704899999],
                [31.305679321000127, 2.142400027000122],
                [31.305688858999986, 2.139025926000102],
                [31.305677413000126, 2.136521101000085],
                [31.305664063000108, 2.133392095000147],
                [31.305723190000094, 2.132730961000164],
                [31.305656432999967, 2.12925005],
                [31.305627823000123, 2.120604038000181],
                [31.305624008000052, 2.119744063000155],
                [31.305622101000154, 2.118459940000037],
                [31.305587769000169, 2.116533995000111],
                [31.300802230000045, 2.109981060000166],
                [31.295740128000091, 2.102979899000047],
                [31.29263305700016, 2.098681451],
                [31.291072846000077, 2.096520902000066],
                [31.28242492600009, 2.084556104000058],
                [31.278137208000032, 2.078799009000022],
                [31.272066116000076, 2.070426941999983],
                [31.26312255900001, 2.057918073],
                [31.262960434000036, 2.057692050000071],
                [31.256031037000184, 2.048288107000076],
                [31.255817414000035, 2.04799795100007],
                [31.248949050000135, 2.038531065000143],
                [31.242019653000114, 2.028865099000086],
                [31.235601425000027, 2.019941090999964],
                [31.234212875000082, 2.018013001000156],
                [31.229742050000084, 2.011976003000143],
                [31.226240158000053, 2.007169962000148],
                [31.224397660000193, 2.004725933000145],
                [31.224229811999976, 2.004426956000032],
                [31.220907211000167, 1.999866010000119],
                [31.21795845000014, 1.995494008000037],
                [31.199596405000079, 1.96716797300013],
                [31.166503907000106, 1.926090956000053],
                [31.163713456000039, 1.922628283000165],
                [31.160943984000028, 1.919999360000134],
                [31.149944306000123, 1.908999325000139],
                [31.143945695000014, 1.900999308000166],
                [31.10619163500013, 1.852891208000131],
                [31.099330902000133, 1.854786992000129],
                [31.064357758000142, 1.803176999000186],
                [31.060579301000018, 1.797592997000038],
                [31.037313461000053, 1.763260008000145],
                [31.034685135000188, 1.759374261000062],
                [31.034088134000058, 1.758491040000081],
                [31.033359528000176, 1.757411478999984],
                [31.025947571000017, 1.74999940399999],
                [30.998949050000192, 1.727999448000162],
                [30.972949982000046, 1.707999468000025],
                [30.951950073000035, 1.692999483000165],
                [30.898950577000107, 1.649999500000092],
                [30.854951858000049, 1.614999533000059],
                [30.845952988000079, 1.60899949200001],
                [30.818952561000117, 1.584999562000064],
                [30.812953950000178, 1.582999469000015],
                [30.79995346100003, 1.572999478000156],
                [30.70095634400019, 1.495999575000155],
                [30.699956895000184, 1.493999601000041],
                [30.65795707699999, 1.444999575000111],
                [30.632497787999966, 1.411027550000085],
                [30.620824814000173, 1.390836597000032],
                [30.605213165000123, 1.357738613000095],
                [30.60001182600007, 1.348378062000052],
                [30.578741074000163, 1.310091615000033],
                [30.561962128000062, 1.282208801000138],
                [30.549726485000122, 1.266250968000065],
                [30.526918411000167, 1.266309976000173],
                [30.519769669000027, 1.266159057000039],
                [30.515497208000113, 1.261083007000138],
                [30.511634827000023, 1.256271005999963],
                [30.503778457000124, 1.246888996000166],
                [30.500343323000095, 1.24261999099997],
                [30.500228881000055, 1.242637992000141],
                [30.499921799000049, 1.242529035000075],
                [30.499538421000125, 1.241958974999989],
                [30.499153138000111, 1.241279961000146],
                [30.498365403000037, 1.240584016000184],
                [30.497655868000038, 1.23961603600003],
                [30.496997834000126, 1.238656998000067],
                [30.495962143000156, 1.237418055000148],
                [30.495334626000044, 1.236667037000188],
                [30.494079590000126, 1.235209942000154],
                [30.493368149000105, 1.234133959000019],
                [30.490261078000174, 1.230605006000189],
                [30.490005494000172, 1.230222940000033],
                [30.489664078000146, 1.229715943999963],
                [30.489440918000184, 1.229385019000063],
                [30.48832130500017, 1.227982045000147],
                [30.487941742000089, 1.227602005000108],
                [30.486059189000059, 1.225386023999988],
                [30.48513221800016, 1.224290967000115],
                [30.483112336000033, 1.221758009000098],
                [30.482435227000053, 1.220971942000176],
                [30.477874756000119, 1.215417027],
                [30.47711181700015, 1.214503051000065],
                [30.476457596999978, 1.213742971000045],
                [30.47591209400008, 1.213083030000064],
                [30.4755306240001, 1.212666035000154],
                [30.475074767000137, 1.212810994000165],
                [30.474594116999981, 1.213289977000045],
                [30.473445893000189, 1.213912011000048],
                [30.472293853999986, 1.214472055000158],
                [30.471349717000123, 1.214262962000021],
                [30.47061920100009, 1.214063048000185],
                [30.469810485000153, 1.213855028000182],
                [30.469469070000059, 1.213330031000169],
                [30.469514846000095, 1.212625026000012],
                [30.469476700000143, 1.211467981000112],
                [30.469282150000026, 1.210824729000024],
                [30.468540191000045, 1.210622787000034],
                [30.460584640000036, 1.206076384000141],
                [30.458211899000048, 1.204719783000087],
                [30.449630737000064, 1.203158737000081],
                [30.449508667000089, 1.203269005000152],
                [30.448841095000091, 1.203647972000113],
                [30.447767257000066, 1.203899979000141],
                [30.446664811000176, 1.204007029000081],
                [30.445615769000028, 1.204059959],
                [30.443559647000086, 1.204401970000163],
                [30.442647935000139, 1.204627038000126],
                [30.441484451000179, 1.204182983000067],
                [30.44112777600003, 1.203431965000107],
                [30.440582276000043, 1.20282602400016],
                [30.439363479000178, 1.202219963],
                [30.438663482999971, 1.20218300900018],
                [30.437713624000025, 1.201431989000184],
                [30.437543867999977, 1.200608970000019],
                [30.43624687200014, 1.20024597600019],
                [30.435201646000166, 1.200533987000142],
                [30.434661866000113, 1.200525046000053],
                [30.433601379000038, 1.200777053000024],
                [30.432336807000183, 1.200747967000098],
                [30.431442260000154, 1.200070025000116],
                [30.430435180000131, 1.19913804600003],
                [30.430042267000033, 1.198919058],
                [30.429378510000106, 1.198549033000063],
                [30.428916931, 1.198160053000038],
                [30.428026200000147, 1.197878957],
                [30.427188873000034, 1.197633981000024],
                [30.426408767000169, 1.19756996700005],
                [30.425130844000023, 1.196465968999974],
                [30.424049378000063, 1.196076990000051],
                [30.423587799000188, 1.195543050000083],
                [30.42336845400007, 1.195235014000048],
                [30.422700883000118, 1.194520950000083],
                [30.422527312000057, 1.194306134999977],
                [30.419395448000103, 1.19439303900009],
                [30.418817521000165, 1.194409012000051],
                [30.418169021000153, 1.194600106000053],
                [30.417940140000155, 1.194877982000151],
                [30.417737960000125, 1.196015955000064],
                [30.417802811000058, 1.196917057000178],
                [30.417833327000096, 1.197335959000043],
                [30.417436599000155, 1.198050023000121],
                [30.416961669000102, 1.19885397000013],
                [30.416400910000164, 1.199143052000068],
                [30.41501426700006, 1.200389027000028],
                [30.414398194000171, 1.200605034999967],
                [30.413026809000144, 1.200811982000118],
                [30.412029265, 1.200729013000114],
                [30.410318375000031, 1.200474979000035],
                [30.408752442000036, 1.199921966000034],
                [30.407482147, 1.199622036000108],
                [30.406780242000139, 1.199349999000049],
                [30.406478882000044, 1.198716999000112],
                [30.406057358000169, 1.19720804800005],
                [30.405597686000135, 1.196336149999979],
                [30.404300690000127, 1.195759655000188],
                [30.402978896000036, 1.195172548000187],
                [30.402919769000164, 1.195145846000116],
                [30.398864746000129, 1.193345785000133],
                [30.397151948000158, 1.192585469000107],
                [30.39687347500012, 1.192960024000058],
                [30.396156311000141, 1.193771960000106],
                [30.395376205000048, 1.193781018000152],
                [30.394701003000137, 1.193644046000031],
                [30.393320083000106, 1.192829966000147],
                [30.392532349000135, 1.192204952000111],
                [30.391851425000084, 1.191426992000061],
                [30.391662598000039, 1.191259862000095],
                [30.389198303000114, 1.19087803400015],
                [30.388633729000162, 1.191233993000026],
                [30.387886047000109, 1.191838979000124],
                [30.386848450000116, 1.193076014999974],
                [30.386186599000155, 1.194007038000052],
                [30.385360718000129, 1.194792985000106],
                [30.384477614000048, 1.195253014000116],
                [30.383781434000184, 1.195559026000183],
                [30.38241005000009, 1.195739032000176],
                [30.381334304000063, 1.195711018000168],
                [30.38044738800005, 1.195719004000125],
                [30.378787995000152, 1.195364952000091],
                [30.377710342000057, 1.195327043000077],
                [30.376691819000087, 1.195623994000073],
                [30.376131058000112, 1.196030975000042],
                [30.37565612800006, 1.196961046000183],
                [30.375110625000161, 1.197618962000092],
                [30.374727250000149, 1.19808101700005],
                [30.373842238000123, 1.198459982000031],
                [30.373037338000131, 1.198730945000023],
                [30.372663498000122, 1.19887495099999],
                [30.372219084999983, 1.200131058000125],
                [30.372070313000108, 1.201315046000048],
                [30.371484756000086, 1.201892018000137],
                [30.370925904000046, 1.202343941000152],
                [30.369678498000042, 1.202893972000084],
                [30.369005204000075, 1.202847958],
                [30.36822891300011, 1.203137041000048],
                [30.367534638000109, 1.203570008999975],
                [30.366626740000129, 1.204264999000145],
                [30.365770340000154, 1.204653025000084],
                [30.365230560000043, 1.204661012000088],
                [30.363672257000189, 1.20488596000007],
                [30.362707139000065, 1.205227972000103],
                [30.361740111000188, 1.20529103199999],
                [30.360717773000147, 1.205317021000099],
                [30.360015870000097, 1.205207943000175],
                [30.358938217000173, 1.203842044000055],
                [30.358493804000091, 1.202494026000068],
                [30.357727051000154, 1.201418043000103],
                [30.357044221000137, 1.200431944000115],
                [30.356006622000166, 1.199048042000015],
                [30.355058670000062, 1.197366000000102],
                [30.354696274000105, 1.196262956000169],
                [30.354042053000057, 1.19536805199999],
                [30.353471756000033, 1.195042014000023],
                [30.352310182000053, 1.194579960000169],
                [30.35117340100004, 1.194044948000169],
                [30.35081672699999, 1.193439961000081],
                [30.350379944000053, 1.192806005000023],
                [30.349868775000118, 1.191550016000122],
                [30.349216462000015, 1.190933942000129],
                [30.348592758000109, 1.190472960000022],
                [30.34802055300014, 1.190001965000022],
                [30.347208023000064, 1.189396025000178],
                [30.346763612000132, 1.18826496700018],
                [30.347053528000117, 1.187579037000091],
                [30.347181321000164, 1.18696498800017],
                [30.347238541000081, 1.186151028000154],
                [30.34625816300013, 1.18509304600002],
                [30.345607758000085, 1.184514046000061],
                [30.344762803000037, 1.183689952000123],
                [30.344053268000039, 1.182849050000186],
                [30.34367179899999, 1.182216047999987],
                [30.343017579000048, 1.181609988000162],
                [30.342203141000027, 1.180904032000171],
                [30.341316224000082, 1.179746032000082],
                [30.340707778000024, 1.178354025000147],
                [30.340152740000065, 1.176753045000169],
                [30.339979172000085, 1.175668954000116],
                [30.33995056200007, 1.175040365000029],
                [30.339931489000151, 1.174939513000027],
                [30.339374543000076, 1.173344970000187],
                [30.338987350000139, 1.172315002000062],
                [30.339162826000063, 1.171085954000148],
                [30.339193345000183, 1.171050668000078],
                [30.336256027000161, 1.155584813000189],
                [30.334592819000022, 1.15387594800012],
                [30.332639695000069, 1.151867866000032],
                [30.329473496000048, 1.151377321000041],
                [30.323474883000131, 1.156822086000148],
                [30.320049284999982, 1.157013893000112],
                [30.320014953000168, 1.15701377500011],
                [30.314357758000085, 1.151895762000038],
                [30.309055328000113, 1.148997904000112],
                [30.306634904000077, 1.14767372700004],
                [30.301006318000077, 1.139797926000028],
                [30.290317535000042, 1.135083675000146],
                [30.284688950000032, 1.127667785000142],
                [30.271076203000177, 1.119479656000067],
                [30.26403236499999, 1.104921936999972],
                [30.263391495000121, 1.105515004000154],
                [30.263307571000098, 1.10558796000015],
                [30.263120652000168, 1.105759025000168],
                [30.261066436000192, 1.106173040000044],
                [30.259233475000144, 1.105926991000047],
                [30.255899429000181, 1.106150031000027],
                [30.255794524000066, 1.106340050000028],
                [30.250555039000119, 1.108296037000116],
                [30.248979568000038, 1.109423995000157],
                [30.248188018000064, 1.110870005000095],
                [30.248086930000056, 1.1113669880001],
                [30.247171402000163, 1.111356973000056],
                [30.244131088000131, 1.111407995000036],
                [30.2420578, 1.111333967000121],
                [30.240472794000141, 1.111513018000096],
                [30.240121842000065, 1.111332059000119],
                [30.240268707000041, 1.110031008000021],
                [30.240095138000186, 1.107843041000081],
                [30.239351273000182, 1.106179953000151],
                [30.238851548000127, 1.104743003000124],
                [30.238740920000055, 1.103196978000028],
                [30.23945236100019, 1.101914048000083],
                [30.239866258000063, 1.100414992000026],
                [30.239587784000094, 1.099519968000038],
                [30.238111496000158, 1.098723054],
                [30.237560272000053, 1.097501994000083],
                [30.23783874500009, 1.095867037000062],
                [30.237659454000095, 1.094537974000161],
                [30.237604142000123, 1.094122053000092],
                [30.236913682000193, 1.093714953000188],
                [30.235733032000041, 1.09403002300013],
                [30.235034944000176, 1.094228030000068],
                [30.234262467000121, 1.092511057000024],
                [30.233287812000015, 1.091814042000181],
                [30.232204436000075, 1.09135198600012],
                [30.231525422000061, 1.090836049000188],
                [30.23139, 1.089434981000068],
                [30.231544495000094, 1.088767052000151],
                [30.231662751000158, 1.087329984000064],
                [30.230983734000176, 1.086696030000041],
                [30.230712891000053, 1.086534024000116],
                [30.23004531800018, 1.086966992000043],
                [30.229570388000127, 1.087914944000147],
                [30.229282378999983, 1.088601947000086],
                [30.22858238200007, 1.088592053000127],
                [30.228038788000049, 1.088094950000141],
                [30.227897644000109, 1.087479949000112],
                [30.228342054999985, 1.086295963000055],
                [30.229003906000116, 1.085320950000096],
                [30.228965759000118, 1.084354043000133],
                [30.22875976500012, 1.083873987],
                [30.227672576000145, 1.082842947000131],
                [30.227067948000183, 1.081712961000108],
                [30.226896285000123, 1.080818057000101],
                [30.227178573000174, 1.079607963000171],
                [30.227479935000076, 1.078912020000075],
                [30.228038788000049, 1.078333973000099],
                [30.229322434000039, 1.07752204000019],
                [30.22995567300012, 1.076455950000081],
                [30.229787826000177, 1.075796009000101],
                [30.229297638000105, 1.075343968000084],
                [30.228488922000167, 1.075144053000145],
                [30.227170945000069, 1.075160979000145],
                [30.226011276000179, 1.075024963000089],
                [30.225524903000121, 1.074753045000136],
                [30.225196838000102, 1.074399949000053],
                [30.225498199000072, 1.073696018000135],
                [30.225629806000029, 1.073243977000118],
                [30.226566315000184, 1.073027969],
                [30.227884294000035, 1.072775960000058],
                [30.228580474000125, 1.072532058000149],
                [30.228826523000123, 1.072293997000031],
                [30.228952409000044, 1.072170974000073],
                [30.22907447700004, 1.071059941999977],
                [30.22866630499999, 1.070588947000033],
                [30.228464126000063, 1.070422054000176],
                [30.227769851000062, 1.069846988000165],
                [30.226396561000172, 1.069620014000066],
                [30.225179672000081, 1.069185972000184],
                [30.224376679000159, 1.068235994000133],
                [30.224359511000159, 1.066817046000153],
                [30.224666595000031, 1.065397979000068],
                [30.224945068000068, 1.063835026000106],
                [30.224943161000169, 1.06231701500019],
                [30.225231170000086, 1.06169295300009],
                [30.22621727000012, 1.060763955000141],
                [30.226554871000076, 1.060544014000129],
                [30.227285386000062, 1.060068012000045],
                [30.227760315000182, 1.059191943000087],
                [30.227266312000097, 1.058297039000138],
                [30.226276398000039, 1.057914973000152],
                [30.226184844000102, 1.057880044000171],
                [30.224218369000027, 1.057688951000102],
                [30.223007203000179, 1.057679058000076],
                [30.2220859520001, 1.057044983000083],
                [30.221712112000091, 1.056051016000026],
                [30.222024917000113, 1.055148006000081],
                [30.222930909000127, 1.054262995000102],
                [30.223428725000076, 1.053169966000041],
                [30.223226548000127, 1.051921963000041],
                [30.222408295000037, 1.050891042000103],
                [30.221277237000038, 1.049615980000112],
                [30.221105576000127, 1.04857695100003],
                [30.221099853000112, 1.048033953000072],
                [30.22230720499999, 1.0478550200001],
                [30.223897934000092, 1.047983051000131],
                [30.225269318000073, 1.047948004000148],
                [30.226797103000081, 1.047577977000174],
                [30.228054047000171, 1.04675698300008],
                [30.228553772, 1.045763969000177],
                [30.228078843000048, 1.044108987000186],
                [30.227289201000133, 1.043295026000067],
                [30.226945877000105, 1.042626024000128],
                [30.226783752000131, 1.04264402300015],
                [30.226219178000122, 1.042680025000095],
                [30.224817275000021, 1.042497993000154],
                [30.222167968000122, 1.041319966000117],
                [30.221052169000075, 1.040153981000174],
                [30.220445633000168, 1.038743020000027],
                [30.220857620000061, 1.037179947000141],
                [30.221437454000068, 1.03601503499999],
                [30.222377778000066, 1.035933971000077],
                [30.223007203000179, 1.03699195300004],
                [30.223463059000096, 1.039134979000039],
                [30.224468231000117, 1.040092946000186],
                [30.225143433000028, 1.04030203800005],
                [30.226364135000154, 1.040068030000043],
                [30.226728440000159, 1.03885698300013],
                [30.226501464000023, 1.037672997000072],
                [30.225868224000067, 1.036525011000094],
                [30.225662232000047, 1.0349429850001],
                [30.226276398000039, 1.033154011000079],
                [30.226610184000151, 1.031780959000173],
                [30.226541519000079, 1.030506014000082],
                [30.226051330000075, 1.029991030000133],
                [30.225616455000079, 1.029620052000155],
                [30.225080490000039, 1.029764057000079],
                [30.224441528000114, 1.030387044000179],
                [30.223913193000044, 1.031190991000187],
                [30.222972871000024, 1.031316042000128],
                [30.222837447000188, 1.031370997000181],
                [30.222263336000026, 1.03042996],
                [30.222497939000164, 1.029672027000117],
                [30.222892762000072, 1.029003024000076],
                [30.223690032000093, 1.028072953000105],
                [30.224124908000022, 1.027233006000188],
                [30.223924636000163, 1.026121021000108],
                [30.223106385000051, 1.025117039000179],
                [30.222122191000153, 1.023779034000029],
                [30.221826553000028, 1.02258598800006],
                [30.221467971000038, 1.021762967000086],
                [30.220537185000126, 1.020378947000154],
                [30.219854355000109, 1.019376040000054],
                [30.219520569, 1.018499017000067],
                [30.219703675000062, 1.017884015000163],
                [30.219942094000146, 1.017668010000136],
                [30.220426559000032, 1.017686009000101],
                [30.220994949000158, 1.017894031000083],
                [30.221403122000083, 1.018481971000085],
                [30.222166061000053, 1.019206047000125],
                [30.22298049900013, 1.019912005000094],
                [30.22349357600001, 1.019974948000026],
                [30.223882676000073, 1.019777059000091],
                [30.224323272000106, 1.019559979000064],
                [30.224550247000138, 1.018313050000018],
                [30.224115372000142, 1.016649962000088],
                [30.22456550600009, 1.01602697300018],
                [30.224906922000059, 1.015757681000082],
                [30.22267913800016, 1.010477901000058],
                [30.22298049900013, 1.009579896000048],
                [30.224380492000023, 1.005420566000169],
                [30.225404740000045, 1.002373696000177],
                [30.226221086000123, 0.999947787000053],
                [30.22451782100012, 0.995066761000146],
                [30.224449158000027, 0.994872809000071],
                [30.222332001000098, 0.992889225000113],
                [30.222269058000165, 0.99296999000012],
                [30.221246720000124, 0.993448020000073],
                [30.220497130000069, 0.993140995000147],
                [30.219953537000151, 0.992588997000155],
                [30.219612121000125, 0.99170297500018],
                [30.219598770000175, 0.990329147000011],
                [30.217657089, 0.98850965600019],
                [30.217485429000192, 0.98899001000018],
                [30.217210769000019, 0.98963999700004],
                [30.216529847000174, 0.990426005000074],
                [30.21591568000008, 0.990733028000136],
                [30.214790345000154, 0.990669012000183],
                [30.214075089000119, 0.990180015000135],
                [30.213905334000174, 0.989121974000057],
                [30.214792253000098, 0.987957001000041],
                [30.215032577000102, 0.986990989000049],
                [30.214965821000078, 0.986411989000089],
                [30.214284897000084, 0.985996008000086],
                [30.211626052000099, 0.984863996000172],
                [30.211114883000164, 0.984655023000016],
                [30.209991455000079, 0.983905017000154],
                [30.20961761500007, 0.983180999000126],
                [30.20961761500007, 0.982150972000056],
                [30.209770202000186, 0.981707991000121],
                [30.210964203000117, 0.98132997800019],
                [30.211996079000073, 0.981140197000173],
                [30.211137771999972, 0.97955989899998],
                [30.208477020000089, 0.977142990000118],
                [30.207294464000142, 0.976069808000148],
                [30.201364516000126, 0.97314196799999],
                [30.197294235000072, 0.971132875000137],
                [30.196958542000061, 0.970730127000024],
                [30.19458961600003, 0.967885911000053],
                [30.194025040000042, 0.956850827000096],
                [30.191341401000102, 0.951766789000033],
                [30.18862152100013, 0.949667931000135],
                [30.183345794000161, 0.94934392100015],
                [30.182540894000169, 0.948662818000173],
                [30.180395126000064, 0.946844579000128],
                [30.179740905000017, 0.947197019000157],
                [30.17861557000009, 0.947919012000114],
                [30.17745590300018, 0.948849022000161],
                [30.176568984000028, 0.94932699300017],
                [30.175069809000092, 0.949497997000094],
                [30.174285889000089, 0.949154020000151],
                [30.173910142000125, 0.948674023000137],
                [30.173742294000135, 0.948185981000051],
                [30.173742294000135, 0.947643996000068],
                [30.174287795000055, 0.947364987000185],
                [30.175617217000081, 0.947338998000077],
                [30.176639557000101, 0.947194994000085],
                [30.176776886000027, 0.946716011000149],
                [30.176776886000027, 0.946164012],
                [30.176334381000117, 0.945622028000116],
                [30.175312043000076, 0.945168973000023],
                [30.174459458000115, 0.944455027000117],
                [30.174018860000103, 0.943252027000028],
                [30.174530029000039, 0.941914977000067],
                [30.17531585800009, 0.94109398099999],
                [30.175281524000127, 0.940777003000051],
                [30.174770355000192, 0.940541983000173],
                [30.17373085100013, 0.940261006000128],
                [30.173288345000117, 0.939583005000031],
                [30.173255921000134, 0.938822985000058],
                [30.173255921000134, 0.937622011000144],
                [30.172643662000155, 0.936183988000096],
                [30.172166825000033, 0.93498200200014],
                [30.172441483000057, 0.933780015000082],
                [30.172506332000182, 0.93256127900014],
                [30.172281266000027, 0.931489288000137],
                [30.171718597000108, 0.928799868],
                [30.172187806000125, 0.926321267000048],
                [30.172536850000029, 0.924477338000145],
                [30.173414231000038, 0.91985887300018],
                [30.172830582000188, 0.91849511900017],
                [30.171634673000085, 0.915702881000016],
                [30.170534134000036, 0.915032447000158],
                [30.168525695000085, 0.913808466000035],
                [30.166641236000032, 0.912660896000148],
                [30.166030883000076, 0.910878063000155],
                [30.165557860999968, 0.912023008000119],
                [30.164499283000168, 0.912844002000043],
                [30.163375854000037, 0.913321973000052],
                [30.161603928000147, 0.913112998000088],
                [30.161296844000105, 0.912742019000177],
                [30.161228180000137, 0.91184800800005],
                [30.162048339000023, 0.910781979000035],
                [30.163377761000106, 0.909689009000033],
                [30.164026261000117, 0.908931017000157],
                [30.164503099000115, 0.907530010000187],
                [30.164062500000171, 0.906120002000023],
                [30.163955688000044, 0.905820906000145],
                [30.161258697000108, 0.903639912000187],
                [30.16060447600006, 0.903504909000105],
                [30.160537720000036, 0.903651],
                [30.159820557000103, 0.904743015000065],
                [30.159069062000185, 0.90543001900005],
                [30.158525466000128, 0.90477901700018],
                [30.15883255000017, 0.903405012000121],
                [30.158786773000031, 0.903129935000152],
                [30.156206130000101, 0.902597428000092],
                [30.156175614000063, 0.902591048999966],
                [30.154455185000074, 0.902235807000068],
                [30.154193878000058, 0.90218186400017],
                [30.153137206, 0.89962542100011],
                [30.153076171, 0.899595975000125],
                [30.15208816500018, 0.899730980000015],
                [30.150844574000018, 0.900072991000172],
                [30.149105072000168, 0.900207996000063],
                [30.148288727000022, 0.899999024000181],
                [30.148458482000137, 0.898932993000187],
                [30.149414062000176, 0.89845401000008],
                [30.150913239000147, 0.898627997000176],
                [30.152412415000185, 0.898393989000169],
                [30.152559281000094, 0.898228705000179],
                [30.14885330300001, 0.889255822000166],
                [30.148792267000147, 0.889200389000109],
                [30.148080827000058, 0.889262974000076],
                [30.14712715200011, 0.889796018000141],
                [30.145799637000152, 0.890489997000088],
                [30.144847869999978, 0.89045298100001],
                [30.14451980500013, 0.889946997000095],
                [30.144281386000046, 0.889106989000027],
                [30.144222259, 0.887786984000172],
                [30.143806457000096, 0.886883021000074],
                [30.142942428000083, 0.88670098800003],
                [30.142316819000087, 0.88727098600009],
                [30.141899109000178, 0.888083994000056],
                [30.141719819000116, 0.889077008000129],
                [30.141241074000163, 0.89003497300007],
                [30.140497208999989, 0.890359997000132],
                [30.139661790000048, 0.890358986000138],
                [30.138799667000058, 0.889878989000124],
                [30.138782501000037, 0.889700235000078],
                [30.136522293000098, 0.891413988000068],
                [30.133577347000141, 0.891857982000033],
                [30.133598328, 0.892162025000061],
                [30.132852553000021, 0.893508018000091],
                [30.131837845000121, 0.894102991000068],
                [30.130437850000192, 0.894254983],
                [30.129722594000157, 0.893830001000026],
                [30.129432678000171, 0.893473983000035],
                [30.12883186199997, 0.892731727000125],
                [30.125650406000034, 0.894060792],
                [30.123590469000192, 0.894921661000126],
                [30.122714996000127, 0.895287097000164],
                [30.121337891000167, 0.895588995000082],
                [30.119010925000168, 0.895702184000129],
                [30.118976593000184, 0.89570397100016],
                [30.118860245000121, 0.895709635000117],
                [30.118553161000136, 0.896055998000065],
                [30.118324280000138, 0.896315992000098],
                [30.117101670000011, 0.897037030000092],
                [30.115821837999988, 0.897270977000176],
                [30.114479065000182, 0.897062005000123],
                [30.113885879000122, 0.896311999000034],
                [30.113914491, 0.895083010000064],
                [30.114473342000167, 0.894393027000092],
                [30.114721298000063, 0.894088984000064],
                [30.114803315000188, 0.893953085000078],
                [30.11297225900006, 0.891851961000157],
                [30.112009049000108, 0.892623068000091],
                [30.11123085000014, 0.893249512000068],
                [30.111249923000173, 0.893310011000153],
                [30.110950469000102, 0.894447982000088],
                [30.110742569000138, 0.895559011999978],
                [30.110382080000079, 0.896851003000052],
                [30.109430313000075, 0.897058011000127],
                [30.10874557599999, 0.896633029000157],
                [30.108240127000045, 0.895042003000128],
                [30.10755539000013, 0.894119978000106],
                [30.106035232000124, 0.894895971000096],
                [30.10433578400017, 0.895554007000044],
                [30.103084563000152, 0.895642997000095],
                [30.102012635000051, 0.894864977000054],
                [30.102130891000115, 0.894294024000146],
                [30.102340698000148, 0.893275023000115],
                [30.102622986000029, 0.892616033000138],
                [30.100576401000069, 0.891811549000181],
                [30.099809647000029, 0.892524004000052],
                [30.098840714000062, 0.892848016000016],
                [30.098157882000066, 0.892816008000068],
                [30.097679138000103, 0.892793],
                [30.09690475400015, 0.892131984000116],
                [30.096071242000107, 0.89054197199999],
                [30.09613227799997, 0.890116990000024],
                [30.096130370000026, 0.890080750000038],
                [30.090736390000131, 0.888216257000067],
                [30.090286256000184, 0.889256],
                [30.090084076000153, 0.889723003000029],
                [30.089099883000131, 0.890228986000068],
                [30.088043213000105, 0.889901996000162],
                [30.087804795000125, 0.889478027000166],
                [30.088020325000173, 0.888113022000141],
                [30.088146211000094, 0.887320877000036],
                [30.077323914000033, 0.883580386000062],
                [30.072637559000157, 0.88196081000018],
                [30.067661286000089, 0.877538980000111],
                [30.063396453999985, 0.870831431000113],
                [30.060152054000127, 0.87121707200015],
                [30.058197022000058, 0.871449471000119],
                [30.054157258000089, 0.871929526000145],
                [30.051097870000092, 0.872293115000105],
                [30.049043656000094, 0.87203037800009],
                [30.044500351000181, 0.871448876000159],
                [30.043626785000185, 0.870988727000167],
                [30.041490555000166, 0.869863331000147],
                [30.040924073000042, 0.870003999000062],
                [30.039897920000101, 0.869024635000187],
                [30.039852143000132, 0.869000972000094],
                [30.036386490000041, 0.867174745000057],
                [30.036327362000122, 0.867143930000111],
                [30.03258323700004, 0.866301716000066],
                [30.032886506000182, 0.865706026000169],
                [30.03298759400019, 0.865505994000102],
                [30.033048629000177, 0.865145026999983],
                [30.03334617600018, 0.863943995000113],
                [30.032960892000119, 0.86328399100006],
                [30.032693862000031, 0.862686993000182],
                [30.030988693000097, 0.862043978000031],
                [30.030324936000113, 0.862586021000027],
                [30.029495240000074, 0.863698304000138],
                [30.029153824000048, 0.864157021000153],
                [30.028711318000035, 0.86464101100006],
                [30.027435303000061, 0.866043984000044],
                [30.026811599000155, 0.867453993000083],
                [30.024782181000091, 0.86808401400009],
                [30.022834778000174, 0.867133976000162],
                [30.022523881000097, 0.865805984000076],
                [30.022602082000105, 0.864938021000114],
                [30.02431869500009, 0.862743973000136],
                [30.025854112000047, 0.861714005000181],
                [30.02666092000004, 0.86117297300018],
                [30.026889800000106, 0.860549391000063],
                [30.024990081000055, 0.860835015000077],
                [30.019895553000083, 0.86160171],
                [30.019866944000114, 0.861601830000041],
                [30.018674850000025, 0.859399796000105],
                [30.018650054000148, 0.859354854000117],
                [30.015617370000029, 0.853745282000148],
                [30.013452530000166, 0.853937745000167],
                [30.01293754600016, 0.853983581000023],
                [30.010999680000054, 0.854156077000084],
                [30.010538102000055, 0.854196966000188],
                [30.010599137000042, 0.854330003000086],
                [30.010911942000064, 0.856055975000061],
                [30.010286332000192, 0.85692298500004],
                [30.009271622000085, 0.857156991000068],
                [30.009080886000106, 0.856962979000173],
                [30.008804321000014, 0.85668599700017],
                [30.007089615000098, 0.856133997000143],
                [30.005218505000187, 0.856449008000027],
                [30.004360199000018, 0.856212974000186],
                [30.003580093000153, 0.855192006000095],
                [30.003582001000154, 0.853772998000125],
                [30.002880095000137, 0.852985978000163],
                [30.001789094, 0.852913022000166],
                [30.000463485000012, 0.853066028000057],
                [29.999605179000071, 0.852441014000021],
                [30.000034332000155, 0.851809978000063],
                [30.00065994300013, 0.850708009000073],
                [30.0007305150001, 0.849080264000122],
                [30.000738145000184, 0.84890997499997],
                [30.000619889000177, 0.84877598300011],
                [29.999959946000047, 0.848040998000101],
                [29.999759674000131, 0.848027885000022],
                [29.998712540000042, 0.84795898200008],
                [29.995981217000065, 0.848508001000141],
                [29.994771958000115, 0.849165976000108],
                [29.993095397000047, 0.85007798700002],
                [29.991146088000107, 0.850392998000018],
                [29.989898682000103, 0.849840999000094],
                [29.988962173000175, 0.849289001000102],
                [29.987482071000102, 0.84920698500008],
                [29.986545562000174, 0.850389004000078],
                [29.98545265100006, 0.850624024000126],
                [29.984830857000134, 0.849756002000106],
                [29.985454559000061, 0.848581016000026],
                [29.986625671000127, 0.847479999000143],
                [29.987100601000179, 0.845713973000045],
                [29.987251283000035, 0.844651997000142],
                [29.987440109000033, 0.843750000000171],
                [29.987564087000123, 0.843161999000131],
                [29.988422393000121, 0.842456996000124],
                [29.988536835000161, 0.842303097000126],
                [29.98814010699999, 0.841996967000171],
                [29.988473893000105, 0.839146375000041],
                [29.986631394000142, 0.839555979000181],
                [29.985071183000116, 0.840964019000126],
                [29.984222411000076, 0.840900003000172],
                [29.983978272000172, 0.840882003000104],
                [29.983901978000119, 0.84002399600007],
                [29.983932495000033, 0.83987599500017],
                [29.984136582000133, 0.838921011000025],
                [29.983695984000121, 0.839030982000168],
                [29.983200073000035, 0.839155972000015],
                [29.982967377000136, 0.83900201299997],
                [29.983278273999986, 0.83853197100018],
                [29.982421874000181, 0.83853197100018],
                [29.983278273999986, 0.83758300500017],
                [29.98265648000006, 0.837041021000118],
                [29.982793778000087, 0.83662097000007],
                [29.982823974000098, 0.836529791999965],
                [29.982864000000177, 0.836408],
                [29.982891000000109, 0.836327],
                [29.982801000000109, 0.836281],
                [29.982424, 0.836092000000178],
                [29.982035000000167, 0.835387000000139],
                [29.982150000000104, 0.835347000000183],
                [29.982267, 0.835306000000173],
                [29.982304381000176, 0.835010741000133],
                [29.982345880000139, 0.834682942000143],
                [29.981800041000156, 0.834528012000078],
                [29.981956001000128, 0.833814998999969],
                [29.982147, 0.833529],
                [29.982269000000144, 0.833345],
                [29.982122, 0.833109],
                [29.981953000000146, 0.832838],
                [29.981907, 0.832764000000168],
                [29.981880000000103, 0.832721000000163],
                [29.981676997000136, 0.832515762000071],
                [29.981335000000172, 0.83217],
                [29.980555, 0.832016],
                [29.980150000000151, 0.83212900000018],
                [29.980007, 0.832169000000135],
                [29.978994302000103, 0.832402930000114],
                [29.978690130000189, 0.832058614000061],
                [29.978664000000151, 0.832029],
                [29.97837996800007, 0.8317071620001],
                [29.978371001000141, 0.831697],
                [29.978215487000057, 0.831344970000146],
                [29.978058503000057, 0.830986437999968],
                [29.97788, 0.830204],
                [29.977826826000125, 0.829970881000065],
                [29.97777, 0.829932000000156],
                [29.977242, 0.829573],
                [29.977154582000139, 0.82942742900002],
                [29.97700900000018, 0.829185000000166],
                [29.97719, 0.829002000000116],
                [29.977554000000112, 0.828634],
                [29.977554000000112, 0.827848000000188],
                [29.976776, 0.827061],
                [29.975763, 0.826590000000181],
                [29.975685001000102, 0.82588599900015],
                [29.976141, 0.825278],
                [29.976388967000105, 0.824946999000076],
                [29.975919995000027, 0.824945986000046],
                [29.975708231000112, 0.82437158700003],
                [29.975687001000097, 0.824313999000083],
                [29.975624866000032, 0.824251866000168],
                [29.975594000000115, 0.824221000000136],
                [29.97544593300006, 0.824072933000025],
                [29.97529800000018, 0.823924999000042],
                [29.975098666000179, 0.823807571000089],
                [29.974361419000047, 0.823373020000133],
                [29.974402409000049, 0.82271466200018],
                [29.974439896000035, 0.822118635000152],
                [29.974399, 0.821738],
                [29.974363, 0.821412],
                [29.973878, 0.820919000000117],
                [29.973428398000181, 0.820461875000035],
                [29.973121258000162, 0.819886972000177],
                [29.972883225000089, 0.819441020000056],
                [29.972908425000071, 0.819289719000039],
                [29.972943000000157, 0.819084000000146],
                [29.97303890500001, 0.818502989000137],
                [29.972259848000135, 0.819205923000027],
                [29.971774376000099, 0.818959297000049],
                [29.971636, 0.81888899900008],
                [29.97163171700015, 0.818869769000116],
                [29.971587113000169, 0.818669471000078],
                [29.971479, 0.818184000000144],
                [29.971108, 0.818023000000153],
                [29.970936, 0.817949],
                [29.970467001000031, 0.817398],
                [29.970615000000123, 0.817064000000187],
                [29.970778614000153, 0.816693874000123],
                [29.97063533, 0.816366268000024],
                [29.970469001000026, 0.815988000000175],
                [29.969684411000117, 0.815859049000039],
                [29.969532021000134, 0.815833988000179],
                [29.96995464600019, 0.815565964000029],
                [29.970157544000074, 0.815435337000167],
                [29.969934366000132, 0.814619544999971],
                [29.969923019000134, 0.814578],
                [29.970029118000127, 0.814578194000035],
                [29.97046771, 0.814578997000183],
                [29.970624561000079, 0.814344661000177],
                [29.970236, 0.813630000000103],
                [29.969851404000167, 0.813144941000189],
                [29.969612121000182, 0.812843026000166],
                [29.969379425000113, 0.811433017000127],
                [29.969421207000039, 0.811013076000108],
                [29.969458000000145, 0.810647000000188],
                [29.969515338000065, 0.810544892999985],
                [29.969603821000192, 0.810387319000085],
                [29.968189000000109, 0.80755100000016],
                [29.961635000000172, 0.790443],
                [29.960291000000154, 0.786934000000144],
                [29.961306, 0.781746],
                [29.961482449000073, 0.780841825000152],
                [29.961740000000134, 0.779525000000149],
                [29.961664001000145, 0.779363001000149],
                [29.961675098000114, 0.779335547000187],
                [29.961682377000102, 0.779314566000153],
                [29.961389380000071, 0.778638790000173],
                [29.961138, 0.778058999000109],
                [29.961269781000055, 0.777728496000179],
                [29.961450859000081, 0.777274355000031],
                [29.961839677000114, 0.777743996000027],
                [29.962619897000025, 0.778376974000025],
                [29.962651470000083, 0.778360971000041],
                [29.962834, 0.778268],
                [29.963242790000152, 0.778060522000146],
                [29.963076249000096, 0.77768177400003],
                [29.962933013000168, 0.777356029000032],
                [29.962963204000118, 0.77735607000011],
                [29.963364, 0.777357000000166],
                [29.963634005000074, 0.77735699599998],
                [29.963642200000152, 0.777350204000129],
                [29.963967, 0.777081],
                [29.964102, 0.776969],
                [29.963791000000185, 0.776417],
                [29.963727, 0.776116000000172],
                [29.963660020000191, 0.775802249000037],
                [29.963556290000156, 0.775314987000058],
                [29.963702078000097, 0.775170156000058],
                [29.96379300000018, 0.775080001000163],
                [29.963863692000189, 0.775023392000037],
                [29.964571, 0.774457],
                [29.963941193000096, 0.774259913000151],
                [29.963558, 0.774139999],
                [29.964027, 0.773354],
                [29.963746814000103, 0.773165218000088],
                [29.963558, 0.773038],
                [29.963363489000074, 0.772644478000188],
                [29.963169001000153, 0.772251000000153],
                [29.963247000000138, 0.771465],
                [29.963389000000177, 0.771233000000109],
                [29.963484, 0.771077000000162],
                [29.963093000000185, 0.770679],
                [29.963168241000119, 0.770225627000173],
                [29.963171005000163, 0.770209015000034],
                [29.963289714000098, 0.77017935300006],
                [29.96410582600015, 0.769975542000168],
                [29.964296411000191, 0.76945687400007],
                [29.964420318000066, 0.769116998000186],
                [29.964809144000071, 0.768882142999985],
                [29.965294921000179, 0.769365251000181],
                [29.965354782000077, 0.769424782000044],
                [29.965357877000088, 0.769398658],
                [29.965387432000114, 0.76913186600018],
                [29.965433001000065, 0.76872],
                [29.965280351000047, 0.768361189000075],
                [29.964968, 0.767627001000051],
                [29.965279, 0.7672290000001],
                [29.966059, 0.767076],
                [29.966137000000174, 0.766679],
                [29.966087011000184, 0.766393865000168],
                [29.965909130000114, 0.765379268000174],
                [29.965904, 0.76535],
                [29.965690000000109, 0.765241000000117],
                [29.965281, 0.765034000000185],
                [29.964972, 0.764878],
                [29.964880553, 0.764831989000072],
                [29.96481323200004, 0.764797985000143],
                [29.964772857000185, 0.764595081000039],
                [29.964722000000108, 0.76433800000018],
                [29.964657000000102, 0.764012],
                [29.96458100000018, 0.763461],
                [29.96458100000018, 0.762603000000126],
                [29.964753, 0.762543000000164],
                [29.964813, 0.762522],
                [29.965735411000026, 0.762161214000173],
                [29.965828001000091, 0.762125],
                [29.96590800700011, 0.762032143000056],
                [29.966295138000078, 0.761583091000034],
                [29.966764000000182, 0.761890000000108],
                [29.96740717299997, 0.761890485000094],
                [29.968088593000061, 0.761890998000013],
                [29.968090001000178, 0.761422000000152],
                [29.967350004000139, 0.761103994000109],
                [29.967663001, 0.760482001000071],
                [29.967980480999984, 0.760162479000087],
                [29.96813, 0.760012000000188],
                [29.968214, 0.759905],
                [29.96867749200004, 0.759308654000051],
                [29.968631, 0.75893],
                [29.968619708000062, 0.758840016000022],
                [29.968599329000028, 0.75867506000003],
                [29.969066, 0.758759],
                [29.969456675000117, 0.758828763000054],
                [29.970160000000135, 0.757809000000123],
                [29.970038000000159, 0.757203000000118],
                [29.970010942000044, 0.757070805000069],
                [29.970003127000041, 0.757031978000157],
                [29.970020007000187, 0.757009244000074],
                [29.970074000000125, 0.756937000000107],
                [29.97023765400013, 0.756716467000103],
                [29.971331000000191, 0.7564],
                [29.971409000000108, 0.755695],
                [29.97098700000015, 0.755519],
                [29.970313607000151, 0.75523891600011],
                [29.970083236000164, 0.755142987000113],
                [29.969772339000087, 0.754356981000058],
                [29.97086334200003, 0.753418028999988],
                [29.97086334200003, 0.753021002000139],
                [29.970756397000173, 0.752532976000055],
                [29.970709001000159, 0.752316],
                [29.971724, 0.751530001000106],
                [29.972114845000135, 0.750744464000036],
                [29.972000312000148, 0.750348339000027],
                [29.971958163000124, 0.75020200900002],
                [29.972244950000174, 0.750217123000027],
                [29.973361969000109, 0.750276029000077],
                [29.974142074000099, 0.749888003000137],
                [29.974697112000172, 0.748956978000138],
                [29.974905014000171, 0.748605012000155],
                [29.974888761000159, 0.748579209000013],
                [29.974871000000178, 0.748551],
                [29.974495000000161, 0.747954000000107],
                [29.975100000000111, 0.746916000000169],
                [29.975166, 0.745895],
                [29.975171985000145, 0.745795251000061],
                [29.975113000000135, 0.745595000000151],
                [29.974888000000192, 0.744828],
                [29.974888000000192, 0.744151],
                [29.974752, 0.74398],
                [29.974687387000188, 0.743898824000098],
                [29.974319457000092, 0.743435980000186],
                [29.974450073000071, 0.743010381000033],
                [29.974497000000156, 0.742858000000126],
                [29.974407000000156, 0.742603],
                [29.974347643000158, 0.742435461000071],
                [29.974283218000039, 0.742253006000169],
                [29.974285125000108, 0.741494000000102],
                [29.97456932100016, 0.740779996000185],
                [29.974668707000035, 0.740663322000103],
                [29.974710000000186, 0.740615],
                [29.974785000000168, 0.740527],
                [29.974695894000092, 0.740505715000097],
                [29.974178315000131, 0.740382016000126],
                [29.973289489000138, 0.73963201100014],
                [29.973306813000193, 0.739152537000052],
                [29.973313, 0.738986],
                [29.973326, 0.738629000000174],
                [29.973258000000101, 0.738181],
                [29.973235838000051, 0.738035364000098],
                [29.973146439000061, 0.737444997000182],
                [29.973115778000079, 0.737151849000099],
                [29.973096, 0.736960000000124],
                [29.973042000000191, 0.736442],
                [29.973175449000109, 0.736170816000026],
                [29.973291396000036, 0.735935987],
                [29.973398209000095, 0.735294997000096],
                [29.972507477000136, 0.735005022000166],
                [29.972473145000095, 0.734498978000033],
                [29.972616195000057, 0.733605027000067],
                [29.972118379000051, 0.732891024000082],
                [29.972069018000184, 0.732556903000045],
                [29.972012008000036, 0.732168054000113],
                [29.972936755000092, 0.732240981000189],
                [29.973139, 0.732],
                [29.973293000000183, 0.731816],
                [29.973024000000123, 0.731750000000147],
                [29.972773000000132, 0.731689],
                [29.972205, 0.731555000000185],
                [29.972082000000171, 0.731525998999984],
                [29.97187, 0.731092],
                [29.971317000000113, 0.730487],
                [29.971067, 0.730486],
                [29.970926, 0.730911],
                [29.970819235000022, 0.731054684000185],
                [29.970392228000151, 0.73044997500017],
                [29.970428467000033, 0.729301989000021],
                [29.970642090000069, 0.728335977000029],
                [29.970678329000123, 0.727684974000056],
                [29.970278752000183, 0.727622644000064],
                [29.970216926000035, 0.727613295000083],
                [29.970181577000062, 0.727753235000137],
                [29.970144, 0.727902],
                [29.969610156000101, 0.728226905000042],
                [29.969488143000035, 0.727982998000073],
                [29.969324113000141, 0.727648020000061],
                [29.96932602000004, 0.727222979000146],
                [29.969175507000102, 0.727199597000151],
                [29.968859888, 0.727150664000078],
                [29.968511, 0.727113],
                [29.968251822000184, 0.727085174000024],
                [29.968185425000058, 0.727078020000135],
                [29.968044280000015, 0.726571977999981],
                [29.967985976000193, 0.72637312500018],
                [29.967913000000181, 0.726123000000143],
                [29.967867000000126, 0.725967],
                [29.967973965000169, 0.725533140000039],
                [29.968399047000162, 0.725750030000029],
                [29.968556773000103, 0.725670387000093],
                [29.968942, 0.725476],
                [29.969328000000189, 0.725280999000063],
                [29.969292991000088, 0.725223785000082],
                [29.969112396000128, 0.724928022000029],
                [29.968918662000078, 0.724339863000125],
                [29.968874000000142, 0.724204],
                [29.968794, 0.723961000000145],
                [29.968814000000123, 0.723236],
                [29.96883, 0.722633],
                [29.968749971000079, 0.72239011500011],
                [29.968687002000081, 0.722199006000039],
                [29.968153000000143, 0.722199],
                [29.96812957700007, 0.722222922000071],
                [29.968011856000032, 0.722343028000068],
                [29.967793584000106, 0.722437792000051],
                [29.967512123000176, 0.72255994700015],
                [29.967369079000036, 0.721836984000106],
                [29.967428, 0.721788000000117],
                [29.967833000000155, 0.721449000000121],
                [29.967709000000184, 0.720726000000184],
                [29.967764727000088, 0.720551611000076],
                [29.967995001000133, 0.719831001000102],
                [29.968294, 0.719301],
                [29.968458000000169, 0.719010000000139],
                [29.968268693000027, 0.71888607599999],
                [29.967960000000176, 0.718684000000167],
                [29.967716, 0.71879],
                [29.967620125000053, 0.718831734000105],
                [29.967460633000144, 0.718900979000068],
                [29.967184006000082, 0.71891797800015],
                [29.966902796000113, 0.71893521700008],
                [29.966890326000112, 0.718935980000026],
                [29.966749192000179, 0.718249976000152],
                [29.966999054000041, 0.717860998000106],
                [29.966905860000168, 0.717742774999977],
                [29.966572, 0.717319],
                [29.966373000000146, 0.717102000000182],
                [29.966297099000144, 0.717019374000074],
                [29.966215149000107, 0.716929985999968],
                [29.966227263000064, 0.716928822000057],
                [29.966850000000193, 0.716869],
                [29.966964917000155, 0.716858008000088],
                [29.967364321000161, 0.716800267000167],
                [29.967463, 0.716786000000127],
                [29.967380328000161, 0.716618673000085],
                [29.967213001000118, 0.71628],
                [29.967269754000085, 0.71627237600012],
                [29.96774900000014, 0.716208000000108],
                [29.967732000000126, 0.715890000000115],
                [29.967712, 0.715531],
                [29.96766, 0.715298000000132],
                [29.967606045000082, 0.715061199000161],
                [29.968389511000112, 0.715385974000128],
                [29.968709991000139, 0.715711996000096],
                [29.969067000000109, 0.715387],
                [29.969067000000109, 0.714808000000119],
                [29.969601, 0.714637000000153],
                [29.969599852000158, 0.714093367000032],
                [29.969511, 0.713967000000139],
                [29.969497990000036, 0.713948520000031],
                [29.969423295000183, 0.713841974000104],
                [29.969316482000124, 0.713380993000044],
                [29.969524384000124, 0.713290989000086],
                [29.969568035000066, 0.713271475000056],
                [29.969736, 0.713197],
                [29.969887, 0.713129],
                [29.97003, 0.712659],
                [29.969874212000093, 0.71250388500016],
                [29.969566344000043, 0.712197005000178],
                [29.969721897, 0.711935919000041],
                [29.969732, 0.711919000000137],
                [29.969889, 0.711656],
                [29.970144230000074, 0.711441186000059],
                [29.97035016600006, 0.711268093000058],
                [29.970603528000026, 0.71140990300006],
                [29.971044790000121, 0.711656883000046],
                [29.971067438000091, 0.711522696000031],
                [29.971114430000057, 0.71124427699999],
                [29.97115291800003, 0.711015479000082],
                [29.970724, 0.710581000000104],
                [29.970663342000023, 0.710493065000037],
                [29.970406001000129, 0.710120001000064],
                [29.970797001000165, 0.709722999000064],
                [29.970692, 0.709108],
                [29.970333998000115, 0.708894904000033],
                [29.969978333000086, 0.708683014000087],
                [29.970121022000114, 0.708444003000125],
                [29.970130000000154, 0.708429],
                [29.970191179000153, 0.708326716000101],
                [29.970835000000193, 0.70825],
                [29.970822000000169, 0.707921000000113],
                [29.970818839000174, 0.707841700000131],
                [29.970798492000029, 0.707319022000036],
                [29.970323855000117, 0.707291910000095],
                [29.970287000000155, 0.707289],
                [29.970192981000082, 0.707282703000146],
                [29.969885, 0.707193],
                [29.969710574000089, 0.707142773000101],
                [29.969694138000136, 0.707138003000068],
                [29.969482423000102, 0.706242978000034],
                [29.969055175000108, 0.705772995000132],
                [29.968555450000053, 0.705384017000085],
                [29.967987060000098, 0.704479993000064],
                [29.96813011200004, 0.703911007000045],
                [29.968700409000064, 0.70312601400002],
                [29.969018213000084, 0.702752876000091],
                [29.969371887000193, 0.702338166000061],
                [29.970091, 0.701762000000144],
                [29.970217760000082, 0.701605560000189],
                [29.970501001000173, 0.701256001000104],
                [29.970501001000173, 0.700507],
                [29.970771, 0.700432],
                [29.970891683000161, 0.700398089000032],
                [29.970914422000192, 0.700352781000163],
                [29.971071001000098, 0.700037],
                [29.971046156000057, 0.699515256000041],
                [29.971036894, 0.699322350999978],
                [29.971002345000102, 0.699107763000029],
                [29.970928193000191, 0.698646010000061],
                [29.970758438000075, 0.698067010000159],
                [29.970430375000035, 0.697633028000098],
                [29.970039369000176, 0.697099985000136],
                [29.970396043000051, 0.696385980000116],
                [29.970716476000177, 0.695663990000071],
                [29.970539006000024, 0.695447007000155],
                [29.970524200000057, 0.69544580000013],
                [29.970057957000108, 0.695407588000023],
                [29.969648361000168, 0.695374013000162],
                [29.969740123000122, 0.694990154000095],
                [29.969827584000143, 0.694625731000087],
                [29.969871000000182, 0.694124],
                [29.969897941, 0.693802700000106],
                [29.969870256000149, 0.693701727000189],
                [29.969828000000177, 0.693549],
                [29.969437, 0.693512],
                [29.968965, 0.693259],
                [29.96885391700016, 0.693199756000126],
                [29.968830109000123, 0.693186998000158],
                [29.968824607000045, 0.693095809000113],
                [29.968819, 0.693001],
                [29.968796, 0.692618000000152],
                [29.96883, 0.692184000000111],
                [29.96886792000015, 0.69168804899999],
                [29.968494000000135, 0.690683],
                [29.968373, 0.69049],
                [29.968245000000138, 0.690286],
                [29.968062, 0.690332000000183],
                [29.967959999000072, 0.690357999000071],
                [29.96728320800014, 0.690610922000133],
                [29.967033386000082, 0.690105022000012],
                [29.967059405000157, 0.689874559000089],
                [29.96708800000016, 0.689625],
                [29.967105995000111, 0.689463044000036],
                [29.966572, 0.688993],
                [29.966601, 0.688389000000143],
                [29.966607253000063, 0.688258588000053],
                [29.966608001000111, 0.68824300000017],
                [29.966707679000024, 0.688066644000116],
                [29.966751000000158, 0.687990000000184],
                [29.966798469000082, 0.687927792000039],
                [29.966998269000101, 0.687664316000053],
                [29.966782, 0.687462],
                [29.966467, 0.68716799900011],
                [29.966467, 0.68640900000014],
                [29.96621900100007, 0.685767000000112],
                [29.966164155000172, 0.685672519000036],
                [29.965862000000129, 0.685152000000187],
                [29.965862000000129, 0.684836073999975],
                [29.96543500100006, 0.685115999000175],
                [29.964971999000113, 0.685188000000153],
                [29.96458100000018, 0.685188000000153],
                [29.964080962000025, 0.685512974000062],
                [29.963547, 0.685151000000133],
                [29.963138387000129, 0.685061565000069],
                [29.963049001000172, 0.68504200000018],
                [29.96303036900008, 0.684766154000101],
                [29.963013000000103, 0.684509000000105],
                [29.963130178000029, 0.684094371000185],
                [29.963156, 0.684003000000189],
                [29.963032016999989, 0.683682543000032],
                [29.962908000000141, 0.683362],
                [29.962871590000134, 0.682963955999981],
                [29.962418000000127, 0.682915],
                [29.962246931000095, 0.682896671000037],
                [29.96219444400009, 0.682891011000095],
                [29.961376190000067, 0.682430029000159],
                [29.961519242000179, 0.681743026000049],
                [29.961359024000046, 0.681526005000137],
                [29.96140729200016, 0.681381315000067],
                [29.961609000000124, 0.680777001000138],
                [29.961626862000173, 0.680687858000169],
                [29.961716, 0.680243000000132],
                [29.961468, 0.679195000000107],
                [29.962145, 0.678374],
                [29.962252, 0.677985000000149],
                [29.962645, 0.677371000000164],
                [29.962645, 0.676901000000157],
                [29.962645, 0.676693000000171],
                [29.962395000000129, 0.676259000000186],
                [29.962431, 0.675365000000113],
                [29.961861, 0.674857999000153],
                [29.961498481000149, 0.674298529000055],
                [29.961399, 0.674145],
                [29.961131, 0.673887],
                [29.960581001000094, 0.673358001000054],
                [29.960638564000135, 0.67300777700018],
                [29.960688000000118, 0.672707],
                [29.960628000000156, 0.672287000000154],
                [29.960617000000127, 0.672211],
                [29.960742154000172, 0.672017350000033],
                [29.961061, 0.671524],
                [29.961264224000047, 0.67089155900004],
                [29.961418000000151, 0.670413000000167],
                [29.96140471699999, 0.670003675000146],
                [29.961390971000128, 0.669579829000043],
                [29.96138800000017, 0.669458],
                [29.961385180000093, 0.66937888800004],
                [29.961260155000161, 0.668750859000056],
                [29.961170196000069, 0.668298006000157],
                [29.960281372000054, 0.667222976000176],
                [29.959854126000039, 0.666146993000041],
                [29.959463120000066, 0.665405989000021],
                [29.959249496000155, 0.664999009000098],
                [29.959105934000149, 0.664270046000127],
                [29.959102, 0.66425],
                [29.959041574000139, 0.663942207000048],
                [29.959038, 0.663924],
                [29.95875400000017, 0.662813],
                [29.958696000000145, 0.662237000000118],
                [29.95868040900001, 0.662088649000168],
                [29.958415000000173, 0.661482],
                [29.958273000000133, 0.661159000000112],
                [29.958487000000105, 0.660626],
                [29.958352310000123, 0.660333992000062],
                [29.958235867000042, 0.660082649000117],
                [29.957946000000106, 0.659737],
                [29.957829700000048, 0.659598528000117],
                [29.957668304000038, 0.659406005999983],
                [29.957170486000052, 0.658791005000182],
                [29.957106282000098, 0.658209730000124],
                [29.957064000000116, 0.657823999000129],
                [29.95683700100011, 0.657508000000178],
                [29.956772997000144, 0.657417495000061],
                [29.956709, 0.657327000000123],
                [29.956032, 0.656143001000089],
                [29.956566001000056, 0.65556500100007],
                [29.956695232000186, 0.655274994000138],
                [29.957029, 0.654526],
                [29.956994, 0.65401],
                [29.956960668000136, 0.653521685000101],
                [29.956843000000163, 0.653055000000165],
                [29.956808, 0.65291800000017],
                [29.956779841000184, 0.652796253000133],
                [29.956447, 0.651720000000182],
                [29.956338342000151, 0.651370715000155],
                [29.956172, 0.651046000000179],
                [29.956048328000122, 0.650804840000148],
                [29.955732346000104, 0.650188029000162],
                [29.955341340000075, 0.649149],
                [29.955057144000193, 0.647893012000054],
                [29.95484811700004, 0.646730164000132],
                [29.954773000000102, 0.646312],
                [29.954596, 0.645634],
                [29.954575, 0.6452010000001],
                [29.954559000000188, 0.644875000000127],
                [29.954438, 0.644281],
                [29.954384, 0.644017],
                [29.954449314000158, 0.643646445000172],
                [29.954580307000072, 0.642904997000073],
                [29.954935074000048, 0.642228007000028],
                [29.954863953000086, 0.641863691000083],
                [29.954794, 0.641504999000063],
                [29.954648456000086, 0.641267128000038],
                [29.954567004000126, 0.641134006000186],
                [29.95451, 0.641044],
                [29.954633435, 0.640666025000144],
                [29.954686720000097, 0.640500945000156],
                [29.954567463000046, 0.640050135000138],
                [29.954546001000097, 0.639968999000189],
                [29.954617000000155, 0.639463001000081],
                [29.954369, 0.638785],
                [29.954435, 0.638663],
                [29.95449600000012, 0.638550000000123],
                [29.954832000000181, 0.637918],
                [29.954975000000104, 0.637096],
                [29.955011, 0.636057],
                [29.954908000000103, 0.635836],
                [29.954762, 0.635523],
                [29.955225, 0.634909000000107],
                [29.955251683000029, 0.63480967900017],
                [29.955351000000178, 0.63444],
                [29.955866941000124, 0.633974553000144],
                [29.956383, 0.633509000000117],
                [29.95656200000019, 0.633241000000112],
                [29.956882000000178, 0.632760000000133],
                [29.957273934999989, 0.632313074000081],
                [29.957666000000188, 0.631866],
                [29.957767, 0.631344000000183],
                [29.957846000000188, 0.630936],
                [29.957937949000041, 0.630788499000175],
                [29.958038329000033, 0.630627989000175],
                [29.958151238000028, 0.630450654000185],
                [29.958227, 0.630332000000124],
                [29.958487000000105, 0.62992400000013],
                [29.95849777400008, 0.629907384000148],
                [29.958912844000167, 0.629267300000151],
                [29.958950042000026, 0.62920999500011],
                [29.958986283000115, 0.628497004000167],
                [29.958986283000115, 0.62770199900001],
                [29.959054703000106, 0.627660662000096],
                [29.959222000000182, 0.62756],
                [29.95945, 0.627422],
                [29.959719, 0.626997],
                [29.960162267000044, 0.626292297000077],
                [29.960287094000023, 0.626093984000136],
                [29.96060943700013, 0.624729992000084],
                [29.960966111000118, 0.623618008000051],
                [29.961158399000055, 0.623382118999984],
                [29.961466, 0.623005000000148],
                [29.961589336000088, 0.622729810000124],
                [29.961786270000061, 0.622291029000166],
                [29.961902089000148, 0.622106568000049],
                [29.962158000000102, 0.6217],
                [29.962393000000191, 0.621325],
                [29.962643, 0.620611000000167],
                [29.962143001000186, 0.619779],
                [29.962063375000184, 0.61963522800005],
                [29.96178817700013, 0.619138003000046],
                [29.961766297000111, 0.618924995000157],
                [29.961718, 0.618451000000107],
                [29.9619016850001, 0.618354686000146],
                [29.962252, 0.618171],
                [29.963463, 0.617991],
                [29.963820000000112, 0.617485000000158],
                [29.964123000000143, 0.617205],
                [29.964303, 0.61689500000017],
                [29.964584, 0.616411],
                [29.964541, 0.615816],
                [29.964514000000179, 0.615444],
                [29.964805735000027, 0.615020387000072],
                [29.965041773000053, 0.614682454000047],
                [29.965084, 0.614622],
                [29.965213, 0.614373],
                [29.96558253000012, 0.613657844000045],
                [29.966227000000117, 0.612788],
                [29.966368001000149, 0.612073999000131],
                [29.966518816000132, 0.611754966000149],
                [29.966654000000119, 0.611469000000113],
                [29.966681, 0.611266000000171],
                [29.966685573000177, 0.611231553000096],
                [29.966726303000144, 0.610926986000038],
                [29.967024233000132, 0.610642185000188],
                [29.967215000000181, 0.610460000000103],
                [29.967330375000017, 0.610349598000028],
                [29.967372000000182, 0.610227000000179],
                [29.967387328000029, 0.610181349000072],
                [29.967510222999977, 0.609816015000035],
                [29.967538480000087, 0.609778406000032],
                [29.967585, 0.609717000000103],
                [29.967843, 0.609372000000121],
                [29.967891082000165, 0.609307652000155],
                [29.968044280000015, 0.609103024000092],
                [29.968578338000157, 0.608308017000127],
                [29.968813466000086, 0.608165961000111],
                [29.968888, 0.608121],
                [29.969042000000172, 0.608028000000161],
                [29.969135344000051, 0.607471363000172],
                [29.969151000000124, 0.607378001000086],
                [29.969595001000187, 0.606944000000169],
                [29.969561001000045, 0.605941000000144],
                [29.969561001000045, 0.605192000000159],
                [29.969782486000156, 0.604950461000158],
                [29.970058, 0.60465],
                [29.970558, 0.604008],
                [29.970951000000184, 0.603331],
                [29.971028420000039, 0.602983263999988],
                [29.971128, 0.6025360000001],
                [29.971733, 0.602003],
                [29.97177500000015, 0.601831],
                [29.971800944000165, 0.601719840000101],
                [29.971912000000145, 0.601244000000179],
                [29.971878000000174, 0.600025000000187],
                [29.971487000000138, 0.599094],
                [29.971096000000102, 0.598344],
                [29.971273, 0.597729000000129],
                [29.970972000000188, 0.596482000000151],
                [29.97094200000015, 0.59635800000018],
                [29.970794999000191, 0.595758999000111],
                [29.970467948000191, 0.595514856000079],
                [29.970081329000095, 0.595225991000063],
                [29.969904624000151, 0.59508472300007],
                [29.96988, 0.595065000000147],
                [29.969584, 0.594828],
                [29.969305, 0.594238000000132],
                [29.969263001000058, 0.594150000000127],
                [29.969692000000123, 0.593391],
                [29.96966835500001, 0.592643432000159],
                [29.969658001000084, 0.592316],
                [29.969658001000084, 0.591277001000151],
                [29.969515, 0.590057],
                [29.970406, 0.589452],
                [29.970728, 0.589091],
                [29.970675, 0.588549000000114],
                [29.970675, 0.587618],
                [29.970563, 0.587298],
                [29.970528264000052, 0.587200445000178],
                [29.970516204000091, 0.587166011000022],
                [29.97050381300005, 0.587130548000175],
                [29.970483, 0.587070000000153],
                [29.970424883000078, 0.586903574000075],
                [29.970301403000178, 0.586453411000036],
                [29.970140456000081, 0.585865021000188],
                [29.969856263000111, 0.585106016000168],
                [29.969524384000124, 0.584978998000167],
                [29.969036102000018, 0.584788979000166],
                [29.968683243000044, 0.584382057000084],
                [29.971420288000104, 0.578194678000159],
                [29.976039887000184, 0.567401409000183],
                [29.97618410400014, 0.567023115000154],
                [29.97730300000012, 0.564089],
                [29.97737895500012, 0.563887879000049],
                [29.977316000000144, 0.563718000000165],
                [29.977096786000118, 0.563132791000101],
                [29.976725428000123, 0.562150016000089],
                [29.976637, 0.561916],
                [29.976798, 0.56134800000018],
                [29.976879218000079, 0.561060765000093],
                [29.976921081000057, 0.560913026000037],
                [29.977583176000053, 0.560546439000177],
                [29.977704899000116, 0.56047905600002],
                [29.978162980000093, 0.560424001000172],
                [29.978595907000113, 0.560372012000073],
                [29.978703157999973, 0.560409704000165],
                [29.979184, 0.559153],
                [29.979668885000081, 0.557880926000166],
                [29.980514934000155, 0.555662778000112],
                [29.981682, 0.552603],
                [29.988661, 0.54304100000013],
                [29.989325034000103, 0.540041450000103],
                [29.990437982000174, 0.53501409100005],
                [29.990167, 0.53501400000016],
                [29.990122000000156, 0.53501400000016],
                [29.989067, 0.53501400000016],
                [29.988776903000144, 0.534910266000111],
                [29.988256, 0.534724000000153],
                [29.987879998000096, 0.534640099000057],
                [29.987771999000131, 0.534616],
                [29.987512983000158, 0.534638624000024],
                [29.986547392000148, 0.534722966000061],
                [29.986193357000047, 0.534640911999986],
                [29.985806, 0.534551],
                [29.984951001000127, 0.534406000000104],
                [29.984409000000142, 0.534153000000117],
                [29.984036057000083, 0.534066316000064],
                [29.983669000000191, 0.533981],
                [29.982786000000146, 0.533981],
                [29.982044, 0.534098],
                [29.981888, 0.53414000000015],
                [29.981646000000126, 0.534206],
                [29.981557246000079, 0.534365131000186],
                [29.981419000000187, 0.534613],
                [29.98119, 0.534893],
                [29.980895718000056, 0.534892509000144],
                [29.980591, 0.534892000000184],
                [29.980249000000185, 0.53492800000015],
                [29.980036148000181, 0.534964840000157],
                [29.979936629000179, 0.534982021000076],
                [29.979674217000138, 0.534997007000072],
                [29.97914900000012, 0.535026999000081],
                [29.97921, 0.534562],
                [29.979266999, 0.534124],
                [29.978376, 0.533581],
                [29.978151976000049, 0.533371978000162],
                [29.977924338000037, 0.53315958799999],
                [29.977239072000032, 0.532520880000106],
                [29.976882903000103, 0.532188723000047],
                [29.976843220000092, 0.532074865000084],
                [29.976669312000126, 0.531575024000176],
                [29.976580471000148, 0.531314033000172],
                [29.976439001000131, 0.530896999000106],
                [29.976437001000193, 0.530391001000169],
                [29.976495, 0.529674000000114],
                [29.976509000000192, 0.529496000000108],
                [29.976499358000069, 0.529222045000097],
                [29.976475, 0.528530000000103],
                [29.976332, 0.52749],
                [29.976368, 0.526623],
                [29.976645, 0.526014000000146],
                [29.97668883700004, 0.525917010000114],
                [29.976725000000101, 0.525837],
                [29.977010953000047, 0.524977755000123],
                [29.976998000000151, 0.524632000000167],
                [29.97697346800004, 0.524008866000145],
                [29.976918034000107, 0.523681585000134],
                [29.97687000000019, 0.523398],
                [29.977186398000072, 0.523187892000067],
                [29.9775104040001, 0.522973032000039],
                [29.977868635000164, 0.523001272000101],
                [29.978435790000105, 0.523045984000078],
                [29.978691, 0.522839000000147],
                [29.978776461000109, 0.522769616000119],
                [29.978792, 0.522757000000126],
                [29.979094873000179, 0.521861005000176],
                [29.979039, 0.521425],
                [29.979025000000149, 0.52132],
                [29.978720000000123, 0.519694000000186],
                [29.977878, 0.518855],
                [29.977452357000175, 0.518431091000025],
                [29.97689628500018, 0.517876983],
                [29.972219466000183, 0.512606978000179],
                [29.971141228000135, 0.511349749000146],
                [29.969817000000148, 0.509805000000142],
                [29.96754500100019, 0.50715600000018],
                [29.966270402000191, 0.505614581000088],
                [29.966217042000039, 0.505550028000187],
                [29.966162155000177, 0.505483622000156],
                [29.964757000000191, 0.503783000000169],
                [29.961052, 0.499301],
                [29.960084, 0.498218000000122],
                [29.957951899000136, 0.495833864000019],
                [29.957157135000102, 0.494944990000022],
                [29.956958162000092, 0.494701543000076],
                [29.955829000000108, 0.493319000000156],
                [29.95528800000011, 0.492657999000073],
                [29.955253824000124, 0.492614039000159],
                [29.953849, 0.490807000000132],
                [29.951002000000187, 0.487145],
                [29.948349, 0.483746000000167],
                [29.945068, 0.47994100000011],
                [29.942974000000163, 0.477329000000168],
                [29.939163000000121, 0.472231],
                [29.936651, 0.469086],
                [29.933313000000169, 0.464693],
                [29.93107529800011, 0.46206912700012],
                [29.930746, 0.461683],
                [29.926935000000185, 0.456848],
                [29.924142148000044, 0.453420153000025],
                [29.923974356000031, 0.453214252000123],
                [29.923797259000025, 0.453008026000077],
                [29.92086219700019, 0.449589996999975],
                [29.920505609000145, 0.449159215000066],
                [29.919535, 0.447986],
                [29.918461, 0.446689],
                [29.917236, 0.445108000000118],
                [29.915457264000167, 0.44281434800007],
                [29.914621352000097, 0.441736012000035],
                [29.912544250000167, 0.438821524000105],
                [29.912807465000185, 0.437290013000165],
                [29.913019180000049, 0.436423005000165],
                [29.913467408000031, 0.434091985],
                [29.913670326000101, 0.433454806000157],
                [29.913721, 0.433296000000155],
                [29.914, 0.432421],
                [29.914324000000192, 0.430927000000111],
                [29.914372625000055, 0.430702579000069],
                [29.914480210000136, 0.430207015000121],
                [29.914686203000031, 0.42873498900002],
                [29.914766311000108, 0.427985012000022],
                [29.914907455000048, 0.427053988000068],
                [29.915388108000116, 0.425826013000176],
                [29.915454428000089, 0.425631802000169],
                [29.915623, 0.425139001000161],
                [29.91588600099999, 0.423965001000056],
                [29.916122816000154, 0.423761454000157],
                [29.917032242000062, 0.422980010000174],
                [29.917167250000091, 0.422897970000122],
                [29.917647000000159, 0.422607],
                [29.918652000000179, 0.421996],
                [29.918968605000146, 0.421996168000135],
                [29.920515981000108, 0.421996993999983],
                [29.920707, 0.421991],
                [29.922107240000059, 0.421943265000095],
                [29.922118712000099, 0.421942079000189],
                [29.923244000000125, 0.421663],
                [29.923710000000142, 0.421547],
                [29.924595950000082, 0.421533501000113],
                [29.925482, 0.421520000000157],
                [29.926531000000182, 0.421348000000137],
                [29.92733743000008, 0.421215895000103],
                [29.927349000000163, 0.421214],
                [29.927559156000143, 0.421111446000111],
                [29.928015000000187, 0.420889001000091],
                [29.928387, 0.42078],
                [29.930443, 0.419688],
                [29.930563000000177, 0.419573000000184],
                [29.930622, 0.419516],
                [29.930911, 0.419097],
                [29.931742, 0.417891000000111],
                [29.932297235000021, 0.416547344000094],
                [29.932623000999968, 0.415758999000161],
                [29.933182000000158, 0.413925001000109],
                [29.933222001000161, 0.413816],
                [29.933466001000113, 0.412335],
                [29.934195000000102, 0.411368],
                [29.934629825000115, 0.411107210000125],
                [29.935022353000079, 0.41087201300013],
                [29.935884476000126, 0.410600989000045],
                [29.937740325000107, 0.410140992000095],
                [29.937874556999986, 0.410115119000181],
                [29.938967, 0.409905000000151],
                [29.940776920000076, 0.409555787000102],
                [29.942816001000153, 0.408643001000087],
                [29.942930944000125, 0.408544062000146],
                [29.942974091000053, 0.408506990000092],
                [29.943901062000123, 0.407640011000012],
                [29.944182739000041, 0.407446304000075],
                [29.944285, 0.407376000000113],
                [29.945162000000153, 0.406773000000157],
                [29.945407246000059, 0.406623964000062],
                [29.945993424000051, 0.406268000000125],
                [29.946735382000156, 0.405641883999976],
                [29.939407348000088, 0.405117005000022],
                [29.928985595000142, 0.403393834000155],
                [29.918247222000048, 0.400060505000113],
                [29.898660659000143, 0.395824730000072],
                [29.887580872000058, 0.391282588000138],
                [29.886158734000162, 0.389508401000114],
                [29.885272000000157, 0.388402000000156],
                [29.883203731000037, 0.385821665000094],
                [29.883162694000077, 0.38577060800003],
                [29.883032000000128, 0.385608000000104],
                [29.881452000000138, 0.381409000000133],
                [29.880904150999982, 0.37995319900017],
                [29.878782273000127, 0.374313593000011],
                [29.876381152000135, 0.366807021000113],
                [29.873252648000118, 0.357024803000058],
                [29.873238000000129, 0.356979],
                [29.873238123000021, 0.356968735],
                [29.873296481000068, 0.352122734000091],
                [29.87331, 0.351000011000167],
                [29.873031, 0.351074],
                [29.872639935000109, 0.351178339],
                [29.872554778000108, 0.351200999000127],
                [29.872275513000147, 0.351249106000125],
                [29.870905000000164, 0.351485],
                [29.869623000000161, 0.351706],
                [29.866965233000087, 0.352211190000105],
                [29.865881000000172, 0.352479],
                [29.864815, 0.352743],
                [29.864225, 0.35291],
                [29.863859166000054, 0.353013953000186],
                [29.863832140000113, 0.352893128000062],
                [29.863823, 0.352851999000109],
                [29.86321630600014, 0.350407166000082],
                [29.86147117600018, 0.343374013000187],
                [29.861189067000169, 0.342073896000159],
                [29.860699001000114, 0.339814],
                [29.859351385000025, 0.333945],
                [29.858004000000165, 0.328077],
                [29.856215786000178, 0.320470814999965],
                [29.855795000000171, 0.318680999000037],
                [29.855747353000027, 0.318585705000146],
                [29.855623245000061, 0.318336994000106],
                [29.85567308800006, 0.318160169000123],
                [29.85572000000019, 0.317994414000168],
                [29.855737793000173, 0.317930148000187],
                [29.854702000000145, 0.313675],
                [29.854336416000081, 0.312175785000022],
                [29.8542461400001, 0.311805009000068],
                [29.850433349000184, 0.295468987000049],
                [29.849108013000148, 0.289473757000167],
                [29.847448871000154, 0.281966540000099],
                [29.84742400000016, 0.281854],
                [29.846964000000128, 0.280119000000184],
                [29.846621000000141, 0.27908],
                [29.843609000000185, 0.265700000000152],
                [29.842949, 0.263007],
                [29.840052000000185, 0.250014999000086],
                [29.840028767000149, 0.249896821999982],
                [29.839419, 0.246816],
                [29.838991000000135, 0.244658000000129],
                [29.83856, 0.242858],
                [29.837521, 0.238522999000111],
                [29.837353949000146, 0.237759267000172],
                [29.837187, 0.236996],
                [29.836052000000166, 0.231837000000155],
                [29.835986, 0.231587000000104],
                [29.835818481000103, 0.23095725100012],
                [29.83571624699999, 0.230572001000041],
                [29.835553923000077, 0.229863997000109],
                [29.835268000000156, 0.228615],
                [29.834414, 0.224890000000187],
                [29.833111, 0.21899],
                [29.833043, 0.218727000000115],
                [29.833018454000182, 0.218632223000157],
                [29.832870484000182, 0.218059005000157],
                [29.831817305000186, 0.213321476000147],
                [29.831545000000119, 0.212096000000145],
                [29.831139000000121, 0.210308000000168],
                [29.830513933000134, 0.20748173100003],
                [29.830002001000082, 0.205167],
                [29.829911038000091, 0.204707006000035],
                [29.829811697000139, 0.204204645000118],
                [29.829809000000182, 0.204191000000151],
                [29.82939000000016, 0.202519000000166],
                [29.828343742000072, 0.19834793300015],
                [29.828341001000126, 0.198337001000084],
                [29.828173397000114, 0.19739466700014],
                [29.828100205000112, 0.19698199700008],
                [29.827011109000068, 0.191823007000096],
                [29.826677323000126, 0.190585003000137],
                [29.826591590000078, 0.19020065400008],
                [29.826507000000163, 0.189820000000168],
                [29.826321001000167, 0.188986000999989],
                [29.825682443000062, 0.18586796400001],
                [29.825517594000189, 0.185063362000051],
                [29.825042355000051, 0.183147261000101],
                [29.825016000000119, 0.183041],
                [29.824705000000108, 0.181849],
                [29.824251, 0.180349],
                [29.823879000000147, 0.178262],
                [29.82387647000013, 0.178252017000034],
                [29.823767000000146, 0.177820000000111],
                [29.823643000000175, 0.177331000000152],
                [29.823357000000158, 0.176518],
                [29.823355000000163, 0.175570000000107],
                [29.822926000000166, 0.17475700000017],
                [29.822877899000048, 0.174701870000035],
                [29.822737000000188, 0.174522],
                [29.822453928000186, 0.174263730000177],
                [29.82221221899999, 0.174043],
                [29.821737289000168, 0.173636005000049],
                [29.82163801900009, 0.173249837000071],
                [29.821524001000114, 0.172805],
                [29.821283299000072, 0.172263740000119],
                [29.821142197000142, 0.171946005000166],
                [29.820560455000134, 0.171629996000036],
                [29.82013130200005, 0.17084400400006],
                [29.81997196399999, 0.170492870000146],
                [29.819771324000101, 0.170050787000037],
                [29.819431000000179, 0.169383000000153],
                [29.819227000000183, 0.168983],
                [29.818940048000059, 0.168801093000013],
                [29.818443000000173, 0.168486000000144],
                [29.817661000000101, 0.168097000000103],
                [29.817336, 0.167850000000101],
                [29.817257, 0.167790000000139],
                [29.817241, 0.167789],
                [29.81683, 0.167772],
                [29.816600043000165, 0.167677540000057],
                [29.816236497000091, 0.167528004000133],
                [29.816038493000065, 0.167394637000029],
                [29.815808000000118, 0.167239],
                [29.815714000000128, 0.167175999000051],
                [29.815563112000063, 0.167030017000059],
                [29.815191268000035, 0.16666999500012],
                [29.815047763000166, 0.166422553000075],
                [29.814835, 0.166055],
                [29.814683000000116, 0.165903000000128],
                [29.814627818000133, 0.165847818000032],
                [29.814573, 0.165793000000122],
                [29.814358, 0.165808],
                [29.814194000000157, 0.16582],
                [29.813886738000122, 0.165865336000024],
                [29.813739000000169, 0.166055],
                [29.813647999000182, 0.166173],
                [29.813520660000052, 0.166261159000044],
                [29.813271000000157, 0.166434],
                [29.8128, 0.166769],
                [29.812724791000164, 0.166823113000135],
                [29.812635688000171, 0.166870742000071],
                [29.812015534000182, 0.167201996000131],
                [29.810960770000122, 0.16735599899999],
                [29.810485844000084, 0.167401],
                [29.809917001000031, 0.167401],
                [29.809347, 0.167473],
                [29.80895001100015, 0.167539500000032],
                [29.808755941000072, 0.167571995000117],
                [29.808163001000025, 0.167527],
                [29.807995, 0.167237999000179],
                [29.807859143000087, 0.167137896000156],
                [29.80761377000016, 0.166957653000111],
                [29.807490760000064, 0.16692290400016],
                [29.807424681000043, 0.166904258000045],
                [29.807260732000088, 0.166858057000184],
                [29.806834, 0.166949000000159],
                [29.806713, 0.166975],
                [29.806358000000159, 0.167102],
                [29.806351653000092, 0.167110316000048],
                [29.806112954000128, 0.167423011000039],
                [29.805957795000154, 0.167625994000048],
                [29.80589877500006, 0.167690358000073],
                [29.805808000000127, 0.167789],
                [29.805767000000117, 0.167834],
                [29.805682933000185, 0.167849985000032],
                [29.805482956000048, 0.167887982000082],
                [29.805256131000192, 0.16780079900002],
                [29.805175853000151, 0.167769153999984],
                [29.805149254000128, 0.167615415000171],
                [29.80512589500006, 0.167480862000104],
                [29.805039, 0.167367000000127],
                [29.80489, 0.167173999000056],
                [29.804741782000178, 0.166925599000081],
                [29.804674, 0.166812],
                [29.804531000000168, 0.167057],
                [29.803989, 0.167409],
                [29.803696854000066, 0.167424871000037],
                [29.80348483400013, 0.167440839000108],
                [29.803260794000039, 0.167592118000073],
                [29.80306400000012, 0.167725001000179],
                [29.802840636000099, 0.168129470000167],
                [29.802804947000084, 0.168193996000184],
                [29.802461050000147, 0.168436575000044],
                [29.80233, 0.168529000000149],
                [29.802306000000101, 0.168536],
                [29.80192605100018, 0.168655731000058],
                [29.801293000000101, 0.168893],
                [29.801133999, 0.168952999000169],
                [29.800706999000056, 0.168952999000169],
                [29.800691636000124, 0.16894942000016],
                [29.800397896000106, 0.168881052000188],
                [29.800183000000118, 0.168989],
                [29.799876675000064, 0.16914320300009],
                [29.799776282000039, 0.169205515000101],
                [29.799499512000068, 0.169376999000065],
                [29.79922627500008, 0.169411535000108],
                [29.799072082, 0.169430990000023],
                [29.79874, 0.1693590000001],
                [29.798607000000118, 0.169415000000129],
                [29.798527, 0.1694490000001],
                [29.798491547000026, 0.169529139000076],
                [29.798431000000107, 0.16966600000012],
                [29.798382000000117, 0.169948000000147],
                [29.798367641000084, 0.17003278500016],
                [29.798360824000156, 0.170072005],
                [29.798196792000113, 0.170479],
                [29.798196792000113, 0.170741007000117],
                [29.797931941000115, 0.170829091000144],
                [29.797860000000128, 0.170853],
                [29.797842019000143, 0.170858993000138],
                [29.797795806000124, 0.170850858000051],
                [29.797581001000026, 0.170813000000123],
                [29.797463169000025, 0.17052481200011],
                [29.797414770000046, 0.170406673000173],
                [29.797313000000145, 0.170262],
                [29.797199000000148, 0.170099],
                [29.797010000000114, 0.170172],
                [29.796394182000142, 0.170460915000035],
                [29.79591941700005, 0.170217007000133],
                [29.795882131000155, 0.170211895000079],
                [29.795594, 0.170172],
                [29.795255832000123, 0.170126011000036],
                [29.794580811000117, 0.170170098000085],
                [29.794567108000138, 0.170170993000056],
                [29.794069290000152, 0.170126005000043],
                [29.794010859000025, 0.170117355000059],
                [29.793726, 0.170075000000168],
                [29.793097, 0.169982000000118],
                [29.792858000000138, 0.170173000000148],
                [29.792646000000161, 0.170343000000173],
                [29.792339, 0.170695000000137],
                [29.792149681000069, 0.170826569000099],
                [29.791961744000048, 0.170956947000036],
                [29.791651971000078, 0.170893050000188],
                [29.791363, 0.170907000000113],
                [29.791272810000066, 0.170911681000064],
                [29.791249, 0.170997000000114],
                [29.791179999000121, 0.171245999000178],
                [29.791132000000118, 0.171579999000073],
                [29.791104018000112, 0.171594811999967],
                [29.790586287, 0.171868847000042],
                [29.790343172000064, 0.171674840000037],
                [29.790199394000069, 0.171559991000038],
                [29.790111822000085, 0.171490039000105],
                [29.789782000000173, 0.171562000000108],
                [29.789307000000122, 0.171896],
                [29.788951999000176, 0.172104],
                [29.788667955000108, 0.172229953000056],
                [29.78850200100004, 0.172058999000114],
                [29.788167909000094, 0.171551818000182],
                [29.78781100100008, 0.171010001000184],
                [29.786552, 0.169727],
                [29.786077, 0.169727],
                [29.785686, 0.169693],
                [29.785557, 0.16968199900009],
                [29.785382184000127, 0.16966759100012],
                [29.785011291000046, 0.16963699400003],
                [29.784582138000189, 0.169084997000084],
                [29.784561157000098, 0.16883692100015],
                [29.78450276500007, 0.16822352500003],
                [29.784219000000121, 0.168048000000169],
                [29.78413, 0.167993],
                [29.783821021000165, 0.16827298100003],
                [29.783466339000142, 0.168182001000162],
                [29.78341410600018, 0.168078211000079],
                [29.783379, 0.168008],
                [29.783275848000073, 0.167803129000049],
                [29.783022507000169, 0.168017815000042],
                [29.78285, 0.168164000000161],
                [29.782890000000123, 0.168293000000176],
                [29.782916889000148, 0.168378470000164],
                [29.782945632000121, 0.168470993000085],
                [29.782987181000124, 0.168994379000026],
                [29.783001, 0.16917],
                [29.783015628000044, 0.169386627000165],
                [29.782765608000147, 0.169663212000046],
                [29.782283782999968, 0.170195997000178],
                [29.781526565000036, 0.17057600600009],
                [29.781302268000047, 0.170647755000061],
                [29.781076117000055, 0.170719963000181],
                [29.780174255000077, 0.170620993000171],
                [29.779891908000081, 0.170590981000146],
                [29.779488001000118, 0.170548],
                [29.779131000000177, 0.170386000000178],
                [29.779013000000191, 0.170006000000171],
                [29.779297, 0.169762],
                [29.779225498000187, 0.169608496000137],
                [29.779154001, 0.169455],
                [29.778915435000158, 0.169423540000082],
                [29.778608, 0.169383000000153],
                [29.778352, 0.169498],
                [29.778186442000163, 0.169573107000133],
                [29.778146744000082, 0.169590995000021],
                [29.777030945000035, 0.169635996000125],
                [29.776495736000072, 0.16965390900009],
                [29.776224133000085, 0.169662996],
                [29.776184957000055, 0.169627506000097],
                [29.776136000000179, 0.169583000000159],
                [29.775965, 0.169428],
                [29.775655451000091, 0.168919966000033],
                [29.775519, 0.168414],
                [29.77546280200005, 0.168207654000071],
                [29.775314392000041, 0.167947935000143],
                [29.775179, 0.167711000000168],
                [29.775016361000155, 0.167662792999977],
                [29.774845, 0.167612000000133],
                [29.774536000000126, 0.167639],
                [29.774386799000126, 0.167688173000158],
                [29.774181246000069, 0.167755918000125],
                [29.77401421400009, 0.16763255900014],
                [29.773827, 0.167494000000147],
                [29.77347, 0.167087],
                [29.772757000000183, 0.166663000000142],
                [29.772757000000183, 0.166446000000121],
                [29.772882, 0.166152000000125],
                [29.772968, 0.165949],
                [29.772802000000183, 0.165615000000116],
                [29.772541, 0.165443],
                [29.772476, 0.165476000000183],
                [29.772116, 0.165660000000116],
                [29.771760999000094, 0.166021000000114],
                [29.771454000000176, 0.166210999000157],
                [29.770838, 0.166661999000155],
                [29.770534505000057, 0.166787924000175],
                [29.770315, 0.166879],
                [29.769840196000075, 0.166923981000139],
                [29.769399229999976, 0.166736867000111],
                [29.76927000000012, 0.166682],
                [29.76865400000014, 0.166165000000149],
                [29.768344987999967, 0.165613897000128],
                [29.768298457000128, 0.165213045000144],
                [29.768236160000015, 0.164674997000077],
                [29.768248899000127, 0.16451607200014],
                [29.768264, 0.164330000000177],
                [29.768329649000066, 0.163513369000043],
                [29.768377000000157, 0.163239000000146],
                [29.768393070000059, 0.163143980000143],
                [29.768400193000048, 0.163102998999989],
                [29.768430748000128, 0.16306895800011],
                [29.768484000000115, 0.16301],
                [29.768659003000096, 0.162815110000111],
                [29.768743, 0.162452],
                [29.76875296500009, 0.162407159000168],
                [29.769037, 0.162055],
                [29.769012, 0.161341000000164],
                [29.769058, 0.160619000000111],
                [29.769081001000018, 0.160004001000118],
                [29.769182955000133, 0.159914386000082],
                [29.769460200000083, 0.159670702000142],
                [29.769556000000193, 0.159382],
                [29.769672000000185, 0.159029],
                [29.769860845000096, 0.158639863000019],
                [29.769476000000111, 0.158298],
                [29.769434, 0.158261],
                [29.769066000000123, 0.157818],
                [29.768961000000161, 0.157726],
                [29.768860765000113, 0.15763843000002],
                [29.768684386000189, 0.157483996000053],
                [29.768398285000046, 0.156959997000115],
                [29.768541337000158, 0.156625003000102],
                [29.768562944000053, 0.156539575000181],
                [29.768597, 0.156407000000115],
                [29.768612, 0.156346],
                [29.768750097000179, 0.156148093000127],
                [29.768895104000023, 0.15594028300012],
                [29.768824500000051, 0.155713],
                [29.768753001000107, 0.155487],
                [29.76871502500012, 0.155304089000083],
                [29.768633001000126, 0.154909001000078],
                [29.768655463000187, 0.154817455000114],
                [29.768726, 0.15453],
                [29.768603883000026, 0.154439558000036],
                [29.768489607000163, 0.154454857000133],
                [29.768062157000145, 0.154509952000183],
                [29.767998, 0.154354],
                [29.767944, 0.154223],
                [29.767775201000177, 0.153815824000105],
                [29.767339, 0.15372],
                [29.767113, 0.153671],
                [29.766992025000036, 0.153466946000037],
                [29.76694700000013, 0.153390999000067],
                [29.767088, 0.152913],
                [29.767135991000032, 0.15246090100004],
                [29.76709, 0.151974000000166],
                [29.767086, 0.151937],
                [29.767084000000125, 0.15153],
                [29.766842000000167, 0.151242000000138],
                [29.766573582000149, 0.150924357000065],
                [29.76628600000015, 0.150794],
                [29.765576135000117, 0.150472633000163],
                [29.765282380000087, 0.150347677000127],
                [29.765175, 0.150302000000181],
                [29.765112, 0.150294],
                [29.764828197000043, 0.150259979000168],
                [29.764795304000074, 0.15025599300003],
                [29.764532089000056, 0.149967001000107],
                [29.764534517000072, 0.14994398400006],
                [29.764546, 0.149839],
                [29.76458, 0.149515],
                [29.764546, 0.149285],
                [29.764484, 0.148874000000149],
                [29.764185158000032, 0.148697841000057],
                [29.764008567000076, 0.148593589000143],
                [29.763687287000153, 0.148287566000135],
                [29.763630000000148, 0.148233],
                [29.763537, 0.148145],
                [29.763273, 0.147898],
                [29.763016076000156, 0.14779570200011],
                [29.762766758000112, 0.147696476000078],
                [29.762728, 0.147681],
                [29.762579359000142, 0.147718907000069],
                [29.762228779000111, 0.147808176000069],
                [29.762091000000169, 0.147828000000118],
                [29.761730171000067, 0.14787997500008],
                [29.761232377000056, 0.147302001000014],
                [29.761206246000143, 0.147086771000147],
                [29.761176000000148, 0.146834000000126],
                [29.761159932000055, 0.146704780999983],
                [29.761085, 0.146465000000148],
                [29.760969, 0.146091000000126],
                [29.760305000000187, 0.145784000000106],
                [29.75997400000017, 0.145477],
                [29.75966300000016, 0.145188000000189],
                [29.759388000000172, 0.144954],
                [29.759069, 0.144682],
                [29.758745699000031, 0.144617667000148],
                [29.758476, 0.144564],
                [29.757883000000163, 0.144519],
                [29.757609678999984, 0.144102356000076],
                [29.757441, 0.143764000000147],
                [29.75725236, 0.14338848500006],
                [29.756924875000038, 0.143085500000097],
                [29.75670623700006, 0.142883002000076],
                [29.756090164000113, 0.142720997000083],
                [29.75530624400011, 0.14236900200018],
                [29.755100140000138, 0.142122914000083],
                [29.754784, 0.141745],
                [29.754632000000129, 0.14132],
                [29.754545000000178, 0.141077],
                [29.753949405000185, 0.14033546800016],
                [29.753569, 0.139995],
                [29.753050000000144, 0.139532],
                [29.752213000000154, 0.138475],
                [29.752145404000089, 0.138383488000102],
                [29.751892034000036, 0.13816560399999],
                [29.75143199900009, 0.137769999000056],
                [29.751089491000187, 0.13754703800015],
                [29.75070845800019, 0.137299581000036],
                [29.750378985, 0.137209724000172],
                [29.750116348000063, 0.137137993000181],
                [29.750162125000031, 0.136540995000132],
                [29.750053323000088, 0.136383188000138],
                [29.749901001000069, 0.136162000000127],
                [29.749780791000092, 0.135970721000035],
                [29.749724, 0.135623000000123],
                [29.749710000000164, 0.135539000000108],
                [29.749256000000116, 0.134211],
                [29.749068000000193, 0.134127000000149],
                [29.748962418000133, 0.13408402500005],
                [29.74555800100012, 0.134092999000131],
                [29.744901565000134, 0.134031186000072],
                [29.744358, 0.13398],
                [29.744017, 0.133948],
                [29.743588884000133, 0.133674582000026],
                [29.743422001000113, 0.133568],
                [29.743042, 0.133278999000083],
                [29.743113, 0.132945],
                [29.743315489000167, 0.132826023000064],
                [29.743466512000168, 0.132737286000122],
                [29.743382000000111, 0.132453],
                [29.743372000000136, 0.132421],
                [29.742944900000168, 0.132257622000054],
                [29.742900000000191, 0.132087000000126],
                [29.742826, 0.131807],
                [29.742228613000123, 0.13162667000006],
                [29.74207512400011, 0.131335169000181],
                [29.741995, 0.131183000000135],
                [29.741978, 0.130805000000123],
                [29.741970001000027, 0.130641],
                [29.742023926000172, 0.130284438000047],
                [29.742086000000143, 0.129874],
                [29.742112340000176, 0.129000873000109],
                [29.742112999000142, 0.128979034],
                [29.742092000000127, 0.128989000000104],
                [29.742059243000142, 0.129005070000062],
                [29.742038727000079, 0.129014998000173],
                [29.741787854000165, 0.129130641000017],
                [29.741470000000163, 0.129277000000172],
                [29.741327, 0.129828],
                [29.740877076000118, 0.130044962000113],
                [29.740570069000057, 0.129899994000027],
                [29.740534189000073, 0.129854576000184],
                [29.740421, 0.129711],
                [29.739857, 0.128997],
                [29.738924475000147, 0.128165841000168],
                [29.737958818999971, 0.128138016000037],
                [29.737915279000106, 0.12814175599999],
                [29.736795043000143, 0.128237996000166],
                [29.73584747200016, 0.128209994000144],
                [29.735254287000032, 0.127803996000125],
                [29.734825134000118, 0.127090008000039],
                [29.734847373000093, 0.126902195000014],
                [29.734895985000037, 0.126494122000111],
                [29.735180598000056, 0.12602107400005],
                [29.735369001000095, 0.125707999000156],
                [29.735545176000016, 0.12502642700008],
                [29.735628127000041, 0.124706000000117],
                [29.735655582000106, 0.124663875000124],
                [29.735683, 0.124622000000102],
                [29.735888000000159, 0.124308],
                [29.736067, 0.124216000000104],
                [29.736172377000173, 0.124161231000073],
                [29.736219374000029, 0.124137017000066],
                [29.73650440199998, 0.124149492000072],
                [29.736812972000109, 0.124162999000077],
                [29.737286, 0.124164],
                [29.737594933000082, 0.124208990000056],
                [29.737849746000109, 0.124184378000166],
                [29.737863999000126, 0.124183000000187],
                [29.737772651000057, 0.124046690000114],
                [29.737736, 0.123991999000111],
                [29.737511001000087, 0.123577001000058],
                [29.737391141999979, 0.123403161000056],
                [29.73724937500009, 0.123196996000161],
                [29.737152006000088, 0.123012711000115],
                [29.737059001000148, 0.12283600000012],
                [29.737342953, 0.122646032000034],
                [29.737817765000045, 0.122791001000053],
                [29.738599911999984, 0.123124995000126],
                [29.739218000000164, 0.123053000000141],
                [29.739239000000111, 0.122719],
                [29.739013, 0.122460000000103],
                [29.73888755400003, 0.122317447000114],
                [29.738859176000119, 0.122285],
                [29.738659853000058, 0.122004520000075],
                [29.738455000000101, 0.121716],
                [29.738171, 0.121336],
                [29.737673, 0.121689],
                [29.737435, 0.121722000000148],
                [29.73696131500003, 0.121787956000105],
                [29.73687693300019, 0.121708478000073],
                [29.736813000000154, 0.121648],
                [29.736746, 0.121585],
                [29.736559, 0.121409],
                [29.736295765000079, 0.120784198000024],
                [29.736195422000037, 0.120440979000136],
                [29.736032001000069, 0.119882000000132],
                [29.735906298000089, 0.119600826000124],
                [29.735842000000105, 0.119457000000125],
                [29.735956416000136, 0.119133141000077],
                [29.736078, 0.118789000000106],
                [29.736124000000132, 0.118252],
                [29.736172, 0.117686000000162],
                [29.73656580100004, 0.116957617000025],
                [29.736704, 0.116702],
                [29.736773, 0.115745000000175],
                [29.736606816000119, 0.115438032000156],
                [29.736291573000074, 0.115492689000121],
                [29.736036000000126, 0.115537000000188],
                [29.735894495000082, 0.115612549000105],
                [29.73568040400005, 0.115725611000073],
                [29.735390579000125, 0.115654764000169],
                [29.735277176000068, 0.115626998000153],
                [29.735088348999966, 0.11520200200016],
                [29.734823227000049, 0.113937996],
                [29.734062194000103, 0.112845004000178],
                [29.733964615000104, 0.11275932],
                [29.733854, 0.112662000000171],
                [29.733517, 0.112366000000179],
                [29.73337400000014, 0.112059000000158],
                [29.733517, 0.11174300000016],
                [29.733683000000156, 0.111670000000117],
                [29.733742887000062, 0.11148344500009],
                [29.733822, 0.111237000000187],
                [29.733842000000152, 0.111168],
                [29.733857964000038, 0.111110131000089],
                [29.733857000000171, 0.110802965000062],
                [29.733856, 0.110496000000182],
                [29.733889313000077, 0.110182111000029],
                [29.733927000000108, 0.109827001000156],
                [29.733748753000157, 0.109578206000094],
                [29.733500000000106, 0.109231000000136],
                [29.733143, 0.10887],
                [29.733355000000188, 0.108562000000177],
                [29.733212, 0.107967],
                [29.733021, 0.107226000000139],
                [29.733122, 0.106878000000108],
                [29.733138842000074, 0.10681954800009],
                [29.733209506000037, 0.106534476000149],
                [29.73328, 0.10625],
                [29.733265000000188, 0.106045000000108],
                [29.733231, 0.105582],
                [29.733314000000178, 0.105181],
                [29.733323057000121, 0.105134808000116],
                [29.733989812000118, 0.10513],
                [29.734356, 0.104950000000144],
                [29.734389037000028, 0.104856348000169],
                [29.734592, 0.104281000000185],
                [29.734163001000184, 0.103739000000132],
                [29.733972569000059, 0.103351432000181],
                [29.733879, 0.103161],
                [29.733852, 0.102357000000154],
                [29.733730654000169, 0.10135249200016],
                [29.733341353000014, 0.100915782000129],
                [29.733305000000144, 0.100875],
                [29.73321, 0.100351000000103],
                [29.733388824000144, 0.10027733000004],
                [29.733493999000075, 0.100234001000103],
                [29.733906866000041, 0.100013245000127],
                [29.734085082000036, 0.099918000000173],
                [29.733562470000095, 0.099257998000098],
                [29.733611386000064, 0.098818346000087],
                [29.73364400000014, 0.098528],
                [29.733656, 0.098418],
                [29.733725438000079, 0.098008767000181],
                [29.733797074000165, 0.097586996000189],
                [29.733606338000186, 0.096874000000184],
                [29.733343124999976, 0.096493997000096],
                [29.733412281000028, 0.096409178999977],
                [29.733557001000122, 0.096232000000157],
                [29.733340392000059, 0.095512791999965],
                [29.733198166000193, 0.095040001000029],
                [29.733007432000193, 0.094299004000106],
                [29.733221046000097, 0.093938014000059],
                [29.733380185000044, 0.093984591000094],
                [29.733467, 0.09401],
                [29.733479000000159, 0.094037],
                [29.733517, 0.094118],
                [29.733621722000066, 0.09426008700018],
                [29.733729999000047, 0.094406999000057],
                [29.734181999000157, 0.094948999000167],
                [29.734703, 0.095094000000131],
                [29.734741810000173, 0.09510314500011],
                [29.734894000000168, 0.095139001000064],
                [29.734896000000163, 0.095428],
                [29.735027654000191, 0.095453104],
                [29.735132000000135, 0.095473001000187],
                [29.735345841000139, 0.095473001000187],
                [29.735369135000099, 0.095544307000182],
                [29.735419, 0.095698],
                [29.73546395700015, 0.095834869999976],
                [29.73564, 0.095969],
                [29.735904088000041, 0.096168696000063],
                [29.735963820000052, 0.09621399800011],
                [29.736627579000128, 0.096711002000177],
                [29.736987599000088, 0.097090577000074],
                [29.737021430000141, 0.097126288000084],
                [29.73715, 0.097262000000114],
                [29.737280511000165, 0.097379510000053],
                [29.737410999000133, 0.097497],
                [29.737886000000117, 0.097614001000011],
                [29.738180792000151, 0.097634380000045],
                [29.738290787000096, 0.097641997000039],
                [29.738478132000068, 0.097682322000082],
                [29.73883253200006, 0.097758683000109],
                [29.738961674000109, 0.097898766000071],
                [29.739097595000089, 0.098048002000155],
                [29.73917579600004, 0.098210999000059],
                [29.739263535000134, 0.09840100200006],
                [29.739025071000128, 0.098910022000041],
                [29.738896000000182, 0.099185],
                [29.738755914000137, 0.099484360000133],
                [29.738719926000158, 0.099634891000107],
                [29.738663, 0.099873000000173],
                [29.738700727000094, 0.100015994999978],
                [29.738806000000181, 0.100415],
                [29.738771000000156, 0.100555],
                [29.73870999900015, 0.100802999000109],
                [29.738426000000175, 0.101156],
                [29.73842800000017, 0.101589],
                [29.738447000000122, 0.101621],
                [29.738498919000108, 0.101706865000097],
                [29.738905992000127, 0.101747519000071],
                [29.738950999, 0.101752],
                [29.739148806000117, 0.101814004000175],
                [29.739464604000148, 0.101912994000031],
                [29.739994, 0.102131],
                [29.74009400000017, 0.102203],
                [29.740826, 0.102728000000127],
                [29.742226000000187, 0.103848],
                [29.74286804500008, 0.104255140000021],
                [29.743643047000091, 0.104746590000161],
                [29.74432905000009, 0.105136237000181],
                [29.744623000999979, 0.105303001000095],
                [29.744873000000155, 0.105646000000149],
                [29.744802000000163, 0.10617],
                [29.744662000000119, 0.106362],
                [29.744591000000128, 0.106459],
                [29.744757000000163, 0.106856000000107],
                [29.744925, 0.107435],
                [29.744938717000025, 0.107718490000025],
                [29.744948999000144, 0.107931],
                [29.745069636000096, 0.108084228000109],
                [29.745233535000068, 0.108292996000159],
                [29.745496750000086, 0.109006003000047],
                [29.745782853000037, 0.109503002000054],
                [29.745794435000107, 0.109596387000067],
                [29.745801, 0.109650000000158],
                [29.745852392000188, 0.110067069000138],
                [29.746067, 0.1105060000001],
                [29.746208000000138, 0.110669],
                [29.746544, 0.111057],
                [29.747044576000064, 0.111570190000066],
                [29.747178999000084, 0.111708],
                [29.747221930000023, 0.111751467000033],
                [29.74766, 0.112195],
                [29.748337000000106, 0.11271],
                [29.748625902000185, 0.112820223000028],
                [29.748835, 0.112900001000014],
                [29.74912, 0.113017500000069],
                [29.749405, 0.113135],
                [29.749571, 0.113280000000145],
                [29.749585621000108, 0.113276345000088],
                [29.749718627999982, 0.113243277000038],
                [29.749973132000093, 0.113180038],
                [29.750558000000183, 0.113347000000147],
                [29.750630538000109, 0.113367596999979],
                [29.750970841000139, 0.113776997000116],
                [29.751248646000136, 0.114228904000129],
                [29.751398, 0.114472001000081],
                [29.751399862000028, 0.114758292000033],
                [29.751401999000109, 0.115087],
                [29.751318181000102, 0.115716080000084],
                [29.751307000000168, 0.1158],
                [29.751143, 0.116424],
                [29.751238000000114, 0.116993],
                [29.751427831000115, 0.117216250000183],
                [29.75152199900009, 0.117326999000056],
                [29.75167255700012, 0.11733917100014],
                [29.751855925000029, 0.117353994000041],
                [29.751856, 0.117038],
                [29.752020000000186, 0.116686000000186],
                [29.752538914000127, 0.116017112000122],
                [29.753125934000025, 0.115253917000132],
                [29.751768453000068, 0.113541780000105],
                [29.751017001000037, 0.112593999000126],
                [29.747987998000156, 0.106835867000086],
                [29.747944, 0.103188000000159],
                [29.747916839000027, 0.100968368],
                [29.747894287000122, 0.099102297000059],
                [29.748888016000024, 0.088066943000058],
                [29.748886273000153, 0.088049774000126],
                [29.748846, 0.087648000000115],
                [29.748439880000092, 0.083651057000054],
                [29.748404000000107, 0.083668000000159],
                [29.747765000000186, 0.083903],
                [29.747619, 0.084134000000176],
                [29.74748, 0.084354],
                [29.747123000000158, 0.084704],
                [29.746937, 0.084887],
                [29.746906, 0.085100000000125],
                [29.746889000000181, 0.085221],
                [29.746752, 0.085510000000113],
                [29.746725475000062, 0.085565970000175],
                [29.746524810000039, 0.085989000000154],
                [29.746431550000182, 0.086064565000129],
                [29.746316, 0.086158000000125],
                [29.746168, 0.086278000000107],
                [29.74607349200005, 0.086328004000165],
                [29.745979, 0.086378],
                [29.745836000000111, 0.086537],
                [29.745743, 0.08664],
                [29.745634589000019, 0.087000601000113],
                [29.745602, 0.087109000000112],
                [29.745644, 0.087432],
                [29.745671934000029, 0.087641501000178],
                [29.745528642000068, 0.087785358000133],
                [29.745384007000098, 0.087929993000103],
                [29.745246888, 0.088067004000152],
                [29.744632721000073, 0.088445999000157],
                [29.744389684000168, 0.088613760000101],
                [29.744253, 0.088707999000178],
                [29.743944, 0.089142],
                [29.743855000000167, 0.089332000000184],
                [29.743764151000164, 0.089527215999965],
                [29.743686677000142, 0.089693002000047],
                [29.743630668000037, 0.089752236000152],
                [29.74359, 0.089795],
                [29.743576000000132, 0.089810000000114],
                [29.743472999000062, 0.0899280000001],
                [29.743394723000165, 0.090067188000091],
                [29.743188858999986, 0.090433001000065],
                [29.743163412000115, 0.090450728000178],
                [29.743036, 0.090539],
                [29.742643, 0.090813000000139],
                [29.742277000000115, 0.091255000000103],
                [29.741922000000102, 0.091418],
                [29.741391005000082, 0.091451656000061],
                [29.741069936000088, 0.091471990000116],
                [29.740853008999977, 0.091441914000143],
                [29.740356446000021, 0.091372997],
                [29.739290238000081, 0.090893998000126],
                [29.738777160000097, 0.09073100300003],
                [29.73859350399999, 0.090694705000033],
                [29.738425136000103, 0.090661414000067],
                [29.738413, 0.090659000000187],
                [29.738288000000125, 0.090715],
                [29.738032254000132, 0.090831647000186],
                [29.737932055999977, 0.091052742000102],
                [29.737820000000113, 0.091299999000057],
                [29.737607932000174, 0.09187815700011],
                [29.737582000000145, 0.091938000000141],
                [29.737394038000048, 0.092374910000046],
                [29.737253188000125, 0.092330001000164],
                [29.73694419900005, 0.091688],
                [29.73688780800012, 0.09156206900002],
                [29.73672, 0.091186000000107],
                [29.736539658000083, 0.090784705000033],
                [29.736285218000148, 0.090564544000188],
                [29.736206, 0.090496],
                [29.735752652000144, 0.090347615000098],
                [29.735708, 0.090333],
                [29.735628000000133, 0.090411000000131],
                [29.735495000000128, 0.090541],
                [29.735496931000057, 0.091119192000065],
                [29.735420000000147, 0.091334],
                [29.735377267999979, 0.091453141999978],
                [29.735254000000111, 0.091477],
                [29.735166783000068, 0.091494132000037],
                [29.73514175400004, 0.091499],
                [29.734991538000031, 0.091525749000084],
                [29.734737034000148, 0.091570994000165],
                [29.734388351000177, 0.091562004000139],
                [29.73393249500009, 0.091544003000138],
                [29.733362198000066, 0.091046996000159],
                [29.732744, 0.090758],
                [29.732128487000068, 0.090827354000112],
                [29.732105251000064, 0.090829971000062],
                [29.732061385000179, 0.090483003000088],
                [29.732043298000178, 0.090342647000057],
                [29.732033, 0.090261000000112],
                [29.731723892000048, 0.089519639000173],
                [29.731609000000162, 0.089137],
                [29.73155529200011, 0.088959350999971],
                [29.731531142999984, 0.088878996000176],
                [29.731649398000116, 0.088454000000183],
                [29.732004165000035, 0.088165001000164],
                [29.732450486000118, 0.087793998000109],
                [29.732502494000187, 0.087751052000101],
                [29.732526534000044, 0.087728109000068],
                [29.732441189000156, 0.087198035000085],
                [29.732415814000092, 0.087053235000042],
                [29.732298305000086, 0.087012052999967],
                [29.73197000000016, 0.086896999000032],
                [29.731852462, 0.086855517000174],
                [29.731800080000141, 0.086837001000049],
                [29.731113433000075, 0.086484999000049],
                [29.730929213000138, 0.086210700000038],
                [29.73078, 0.0859880000001],
                [29.730612, 0.085338],
                [29.730466362000072, 0.085202253000148],
                [29.730185, 0.08494],
                [29.729567, 0.084416],
                [29.729392000000189, 0.083906000000127],
                [29.729328, 0.083721],
                [29.729255504000093, 0.083174531000054],
                [29.729183000000148, 0.082628],
                [29.728567000000169, 0.082104],
                [29.727890000000116, 0.08176],
                [29.727642419000119, 0.081637533000105],
                [29.727142000000185, 0.081390000000113],
                [29.726708, 0.081345000000113],
                [29.726170001000128, 0.081289999000091],
                [29.724701, 0.080983],
                [29.723466940000037, 0.080866002000107],
                [29.723039627000162, 0.080893004000018],
                [29.722732989000178, 0.080964893000044],
                [29.722712112000124, 0.080766841000184],
                [29.722694396000065, 0.080595001000177],
                [29.722731106000083, 0.080428021000046],
                [29.722760000000164, 0.080298],
                [29.722836, 0.079953],
                [29.722836, 0.079475000000116],
                [29.722502776000056, 0.079094264000048],
                [29.722137867000129, 0.078874622],
                [29.721977315000174, 0.07877833900011],
                [29.720755, 0.078299000000186],
                [29.720295000000135, 0.078119000000186],
                [29.72010692200007, 0.078105711999967],
                [29.719276, 0.078047],
                [29.71836885700003, 0.078330421000032],
                [29.718350999000165, 0.078335999000103],
                [29.717831033000095, 0.078397656000163],
                [29.717592171999968, 0.07842598000002],
                [29.717260361000172, 0.078308999],
                [29.716976166000165, 0.077928998000118],
                [29.71697425799999, 0.077170998000099],
                [29.7168912140001, 0.077019429000075],
                [29.716838000000109, 0.076922],
                [29.716618, 0.07652],
                [29.716475000000116, 0.076258001000156],
                [29.716691, 0.076135],
                [29.71671015700008, 0.076123506000101],
                [29.716757484000027, 0.076109979000137],
                [29.717068000000154, 0.076023001000067],
                [29.717684000000133, 0.075807001000157],
                [29.717825, 0.075238001000116],
                [29.717693511000107, 0.074976022000044],
                [29.717561981000074, 0.074713830000178],
                [29.71752, 0.074332],
                [29.717514038000104, 0.074280002000023],
                [29.717632294000111, 0.074000001000172],
                [29.717987060000155, 0.073738002000084],
                [29.717971378000072, 0.073474479000026],
                [29.717949003000115, 0.073097046000157],
                [29.717972717000123, 0.073109714000168],
                [29.718046, 0.073149],
                [29.718151, 0.073205],
                [29.718536157000187, 0.073242678000156],
                [29.718887000000166, 0.073277001000065],
                [29.718937, 0.073353],
                [29.718982951000044, 0.07342192700014],
                [29.719187084000168, 0.073538312000096],
                [29.719362, 0.073638001000063],
                [29.719389834000026, 0.073650128000111],
                [29.719860012000026, 0.073854997000069],
                [29.72047600000019, 0.073684],
                [29.720605, 0.073580000000163],
                [29.720856, 0.073377],
                [29.721187763000046, 0.073322039000061],
                [29.721780778000152, 0.073467002000029],
                [29.722717333000105, 0.073872833000166],
                [29.72284, 0.073926],
                [29.72299, 0.073991],
                [29.723032000000103, 0.073997],
                [29.723641194000095, 0.07408092300011],
                [29.723844528000029, 0.07410900300016],
                [29.724414826000157, 0.074371002000078],
                [29.725078583000084, 0.074560002000055],
                [29.725533769000094, 0.074756093000019],
                [29.725623999000106, 0.074795000000165],
                [29.72597435300014, 0.07492046700014],
                [29.726430999000058, 0.075084001000164],
                [29.726847000000134, 0.075211000000138],
                [29.727746994000142, 0.075283],
                [29.727802694000161, 0.075271207000014],
                [29.728172000000143, 0.075193],
                [29.728580000000136, 0.075059000000124],
                [29.728695, 0.075021],
                [29.728977000000157, 0.074874000000136],
                [29.729144699000074, 0.074786157000062],
                [29.730068000000188, 0.07447],
                [29.730296378000048, 0.074322767000069],
                [29.730614, 0.074118000999988],
                [29.730674, 0.074094],
                [29.730701111000087, 0.074083051000116],
                [29.730726242000117, 0.074073002000091],
                [29.730869219000112, 0.074017397000034],
                [29.731151, 0.073908000000131],
                [29.731536172000176, 0.073758099999964],
                [29.731747384000016, 0.073590446000139],
                [29.732082366000043, 0.073322998000037],
                [29.732549061000043, 0.073074383000176],
                [29.732793999000023, 0.072944001000053],
                [29.73302457, 0.072935219000044],
                [29.733503000000155, 0.072917001000121],
                [29.73375322600009, 0.072818005000101],
                [29.734095563999972, 0.072682568000175],
                [29.734547, 0.072348],
                [29.734547, 0.072041000000183],
                [29.734743692000166, 0.07193717600012],
                [29.734924000000149, 0.071842],
                [29.735104000000149, 0.071732],
                [29.735352, 0.07158],
                [29.735368000000108, 0.071572000000117],
                [29.735391626000137, 0.071560189000138],
                [29.73432, 0.070785000000171],
                [29.734260560000166, 0.070742203000123],
                [29.734245300000111, 0.070731163000062],
                [29.733443733000172, 0.069329527000093],
                [29.731356957000173, 0.065679603000092],
                [29.731009, 0.065071000000103],
                [29.731010028000128, 0.065058559000022],
                [29.731292, 0.061646],
                [29.731304415000068, 0.061495703000105],
                [29.731386186000179, 0.060507552000161],
                [29.731227358000183, 0.060499187000119],
                [29.731167000000141, 0.060496000000114],
                [29.730101, 0.060162000000162],
                [29.729739271000085, 0.060090832000128],
                [29.729364396000051, 0.060017002000109],
                [29.72906112700008, 0.05985400100019],
                [29.728991298000096, 0.059815982000146],
                [29.728962000000138, 0.0598],
                [29.72839, 0.059068],
                [29.727892, 0.05899600000015],
                [29.727862894000168, 0.058988946000113],
                [29.727335, 0.058861],
                [29.726848552000092, 0.058861],
                [29.726362228000141, 0.058860999000103],
                [29.725864409999986, 0.058842],
                [29.725587188000134, 0.058664518000114],
                [29.725485, 0.058599000000186],
                [29.725153, 0.058626000000118],
                [29.725138000000129, 0.05863],
                [29.724916280000059, 0.05869791399999],
                [29.72446441600016, 0.058481],
                [29.724058152000111, 0.058409003000179],
                [29.723682404000101, 0.058345999000096],
                [29.723589382000057, 0.058269948000145],
                [29.723436000000163, 0.058144],
                [29.723185000000171, 0.057939],
                [29.722069, 0.057108],
                [29.722059647000037, 0.057103293000125],
                [29.721453, 0.056798000000185],
                [29.721309573000156, 0.056725915000186],
                [29.721260071000131, 0.056701002000182],
                [29.721043229000145, 0.05656105200012],
                [29.719931, 0.055843],
                [29.719124, 0.055581],
                [29.718628, 0.05568],
                [29.718338, 0.0557060000001],
                [29.717821, 0.055753],
                [29.717592429000092, 0.055765392000183],
                [29.717489213000079, 0.05577098800012],
                [29.717014312000117, 0.055562999000074],
                [29.717034121999973, 0.055492248000178],
                [29.717045, 0.055454001000101],
                [29.71714833700014, 0.055452026000012],
                [29.717454549000081, 0.055446179000171],
                [29.717451, 0.055243000000132],
                [29.717440001000114, 0.055084011000019],
                [29.717510427000036, 0.054831071000081],
                [29.717532986000037, 0.054750050000052],
                [29.717481196000051, 0.054708513000037],
                [29.71733336900013, 0.054590253000072],
                [29.717296858000054, 0.054561045000128],
                [29.71723100000014, 0.054582000000153],
                [29.717155109000146, 0.054605966],
                [29.716772684000034, 0.05456390500018],
                [29.716492001000177, 0.054533],
                [29.716562508000038, 0.05437948299999],
                [29.716633, 0.054226],
                [29.716714985000124, 0.054145991000041],
                [29.716799, 0.054064],
                [29.716799, 0.053847],
                [29.716654000000176, 0.053539],
                [29.716679000000113, 0.053278],
                [29.716791551000085, 0.053249195000092],
                [29.716890067000065, 0.05322406900018],
                [29.717057999000133, 0.053394999000091],
                [29.717175999000119, 0.05370200100009],
                [29.717542942000193, 0.053728072000013],
                [29.717554102, 0.053728854000099],
                [29.717531204000068, 0.05344],
                [29.717508316000135, 0.053133001000106],
                [29.717558467000117, 0.053049030000182],
                [29.717626858000187, 0.052935235000064],
                [29.718065, 0.052796000000171],
                [29.718111, 0.052781],
                [29.718218, 0.052561],
                [29.718443, 0.052095],
                [29.718482, 0.052048000000127],
                [29.718678477000083, 0.051804340000047],
                [29.718568449000031, 0.051665356000058],
                [29.718507775000148, 0.051588714000104],
                [29.71846, 0.051528],
                [29.718393000000162, 0.051444],
                [29.718260497000131, 0.051393730000143],
                [29.717966001000093, 0.051281999000082],
                [29.717980000000125, 0.051201],
                [29.718059615000186, 0.050732266000125],
                [29.718272346000106, 0.050128951000033],
                [29.718413569000177, 0.049729216000117],
                [29.718623844000092, 0.049112684000079],
                [29.718570985000042, 0.049005623000028],
                [29.71838800099999, 0.048635001000093],
                [29.718478028000106, 0.048280152000132],
                [29.718576, 0.047894000000156],
                [29.718906000000175, 0.047135],
                [29.718917203000103, 0.046924087999969],
                [29.718929000000117, 0.046702],
                [29.718854000000135, 0.046366],
                [29.718834, 0.046277],
                [29.71875, 0.046109],
                [29.718666, 0.04594299900009],
                [29.718554412, 0.04580076700006],
                [29.718382000000133, 0.045581],
                [29.718498371000067, 0.04526819900002],
                [29.718523025000025, 0.045201999000085],
                [29.718550171000118, 0.045152128000097],
                [29.71875386500011, 0.044778252000071],
                [29.718712, 0.044588000000147],
                [29.718983000000151, 0.043955],
                [29.71904429600005, 0.043860092000159],
                [29.719075968000027, 0.043811049000055],
                [29.718744, 0.043043],
                [29.718431000000123, 0.042363],
                [29.71834, 0.042167],
                [29.718294337000088, 0.041968557000018],
                [29.718126297000026, 0.041237002000173],
                [29.718115, 0.04118700000015],
                [29.718100000000106, 0.041119],
                [29.717882047000103, 0.040619015000118],
                [29.717600000000118, 0.039972000000148],
                [29.716839, 0.039186000000143],
                [29.715794001000177, 0.0384],
                [29.715320941000073, 0.03816477],
                [29.715213000000119, 0.037747],
                [29.715164001000062, 0.037559999000166],
                [29.715293202, 0.037151886000061],
                [29.715353000000164, 0.036963],
                [29.715553961000182, 0.036769038000159],
                [29.715755, 0.036575],
                [29.715944, 0.036169],
                [29.71610119900015, 0.035974162000116],
                [29.716228000000115, 0.035817],
                [29.716749, 0.035482],
                [29.716867, 0.035120999000128],
                [29.716793001000042, 0.034769001000143],
                [29.716819922000013, 0.034736345000113],
                [29.717009, 0.034507],
                [29.717246000000102, 0.034399000000178],
                [29.717320828000084, 0.034364793000066],
                [29.717386245000114, 0.034334999000123],
                [29.718050003000144, 0.034173000000123],
                [29.718595839000159, 0.034235981999984],
                [29.718784, 0.034195000000182],
                [29.718926759000169, 0.034163054000032],
                [29.718927000000122, 0.033856000000185],
                [29.718709, 0.033376],
                [29.71868900100003, 0.033333],
                [29.718700137999974, 0.03316205800013],
                [29.718712, 0.03298000000018],
                [29.718844000000161, 0.032909000000188],
                [29.719007918000045, 0.032820363000042],
                [29.719114188000162, 0.032763063000061],
                [29.719366840000077, 0.032847043000118],
                [29.719468999000128, 0.032881000000145],
                [29.719514213000139, 0.033192361999966],
                [29.719566, 0.033549000000164],
                [29.719724706000022, 0.033848043000148],
                [29.71978200000018, 0.033956000000103],
                [29.720197331000179, 0.034239347000039],
                [29.72023086900009, 0.034262225000134],
                [29.720720000000142, 0.033811000000185],
                [29.720964, 0.033521999000186],
                [29.720953297000165, 0.033267067000054],
                [29.720942, 0.032998],
                [29.720732114999976, 0.032488835000152],
                [29.720726014000093, 0.032474001000139],
                [29.720729000000176, 0.03242],
                [29.720771791000061, 0.031593046000182],
                [29.720772000000181, 0.031047000000115],
                [29.72075081100013, 0.031014234000054],
                [29.720675000000142, 0.030897000000152],
                [29.720556000000101, 0.030713],
                [29.720363, 0.030647],
                [29.720236, 0.030604],
                [29.719755, 0.030362],
                [29.719642945000089, 0.030305666000061],
                [29.719572067000115, 0.030270001000019],
                [29.719500380000113, 0.030222560000084],
                [29.719027001000143, 0.029909001000021],
                [29.719052180000176, 0.029721547000122],
                [29.719071887000098, 0.029573832999972],
                [29.718984, 0.029444000000183],
                [29.718882000000121, 0.029295000000104],
                [29.718998000000113, 0.028888000000165],
                [29.719021584000131, 0.028805084000055],
                [29.719093322000049, 0.028554001000145],
                [29.719174220000127, 0.028482049000047],
                [29.719277000000147, 0.028391000000113],
                [29.719306999000025, 0.028364001000057],
                [29.719662, 0.028382],
                [29.71978200000018, 0.028527001000043],
                [29.71978200000018, 0.028861],
                [29.71995, 0.029150000000186],
                [29.720061166000164, 0.029174108000063],
                [29.720281813000042, 0.029221960000086],
                [29.720423, 0.028789000000131],
                [29.72052200000013, 0.028418],
                [29.720657, 0.027912],
                [29.720721, 0.027430000000152],
                [29.720797000000118, 0.026856],
                [29.720865227000161, 0.025819849000072],
                [29.720888139000124, 0.025474001000077],
                [29.720533372000148, 0.025338],
                [29.720406264000133, 0.02509643000019],
                [29.720320001000175, 0.024932001000138],
                [29.720394167000109, 0.024814248000098],
                [29.72053100100004, 0.024596999000153],
                [29.720474280000076, 0.024289901000145],
                [29.720461, 0.024218000000133],
                [29.720618787000035, 0.024122439000109],
                [29.720745046000104, 0.024046025000189],
                [29.720841287000155, 0.024097244000075],
                [29.721097690000136, 0.024233705000086],
                [29.721238, 0.024262],
                [29.721479, 0.024308000000133],
                [29.721626331999971, 0.024149296000132],
                [29.721764001000111, 0.024001001000045],
                [29.722026009000103, 0.023901451000143],
                [29.722143000000131, 0.023857000000135],
                [29.72216929700005, 0.023634032000132],
                [29.722191001000112, 0.023449999000093],
                [29.722239, 0.023217000000102],
                [29.722287248000157, 0.022978598000066],
                [29.722307205000106, 0.022880999000108],
                [29.722637176000148, 0.022285],
                [29.722899000000155, 0.022264000000121],
                [29.723514720000082, 0.022213023000063],
                [29.723636512000041, 0.022229536999987],
                [29.723684, 0.022236],
                [29.723846000000151, 0.022258000000136],
                [29.723962143000108, 0.022213410000177],
                [29.72407, 0.022172000000126],
                [29.723962000000142, 0.022017000000119],
                [29.723930740000071, 0.021972265000045],
                [29.723846435000098, 0.021850999000151],
                [29.723980131000133, 0.021677456000077],
                [29.724057000000187, 0.021578],
                [29.724083, 0.021544],
                [29.724118148000116, 0.021518351000168],
                [29.724342000000149, 0.021355],
                [29.72439725400011, 0.021262601000103],
                [29.724460924000027, 0.02115612900019],
                [29.724616839000078, 0.021219078000172],
                [29.724671, 0.021241],
                [29.724817, 0.02130000000011],
                [29.724983, 0.021590000000117],
                [29.725088, 0.021454],
                [29.725220000000149, 0.021282],
                [29.725403, 0.021121],
                [29.725599, 0.020948],
                [29.725647000000151, 0.020625],
                [29.725669972000105, 0.020469187],
                [29.726025000000163, 0.020325],
                [29.726379000000122, 0.020325],
                [29.726814, 0.020095],
                [29.726924763000056, 0.02003612600015],
                [29.727045696000062, 0.020015268],
                [29.727350234000028, 0.019963000000132],
                [29.727432251000153, 0.019394000000148],
                [29.727125168000043, 0.019304000000147],
                [29.726801180000052, 0.018894648000014],
                [29.72604600000011, 0.017940000000124],
                [29.725935010000171, 0.017855436000048],
                [29.725919728000122, 0.017843793000054],
                [29.725675000000138, 0.017658],
                [29.725570753000113, 0.017579078000097],
                [29.725498200000061, 0.017524000000151],
                [29.725239189000035, 0.01728595700007],
                [29.725174000999971, 0.017226000000164],
                [29.724993001000087, 0.016793001000167],
                [29.724811799000065, 0.016430510000077],
                [29.724711000000127, 0.016185000000178],
                [29.72462968200017, 0.015988711000034],
                [29.724368, 0.015751],
                [29.724269953000146, 0.01566299000018],
                [29.724191, 0.015647000000115],
                [29.723969542000077, 0.015603332000012],
                [29.723907470000086, 0.015591],
                [29.723583222000059, 0.015447],
                [29.723293304000094, 0.015266002000033],
                [29.723003388000052, 0.015049],
                [29.722822189000112, 0.014824001000136],
                [29.72274547700016, 0.014746765000098],
                [29.722607000000153, 0.014607000000183],
                [29.722534501999974, 0.014426004000143],
                [29.722462, 0.014245],
                [29.722317000000146, 0.01406500000013],
                [29.722317000000146, 0.013767000000144],
                [29.722498, 0.013441000000171],
                [29.722765793000065, 0.013341270000069],
                [29.722787968000034, 0.013333012000146],
                [29.723184804000141, 0.013368994000075],
                [29.723315755000044, 0.013360283000168],
                [29.723455001000048, 0.013351001000103],
                [29.723564, 0.013026001000014],
                [29.723745149000081, 0.012773033000087],
                [29.723879, 0.012803000000133],
                [29.724072, 0.012845],
                [29.724147000000187, 0.012653000000171],
                [29.724216, 0.012475000000165],
                [29.724240196999972, 0.012300093000022],
                [29.724251, 0.012222],
                [29.724328, 0.012167],
                [29.724504, 0.012041000000181],
                [29.724687000000131, 0.011890000000108],
                [29.724721712000132, 0.011861238000051],
                [29.724778492000041, 0.011769792000109],
                [29.724901123000166, 0.011572123000178],
                [29.72494709200015, 0.01161783800012],
                [29.7250820000001, 0.011752],
                [29.725321000000179, 0.011919],
                [29.725445, 0.012005],
                [29.725626, 0.011897000000147],
                [29.725554000000102, 0.011535],
                [29.725571393000166, 0.011463012000092],
                [29.72562600100008, 0.011237],
                [29.725699262000035, 0.011205849000078],
                [29.725879961000146, 0.01112901800002],
                [29.726167856000075, 0.011164982000139],
                [29.726302000000146, 0.011015000000157],
                [29.726389293000182, 0.01091648300013],
                [29.726442337000151, 0.010857],
                [29.726673127000026, 0.010623],
                [29.726687420000076, 0.010615219000044],
                [29.726812, 0.010548],
                [29.72707176300014, 0.01040612900016],
                [29.727506772000027, 0.010334038000167],
                [29.727543106000155, 0.010343929000157],
                [29.727707, 0.010389],
                [29.727903, 0.010442000000182],
                [29.728048535000141, 0.010250627000062],
                [29.728156975000047, 0.010107902000073],
                [29.72815038300007, 0.010081438000043],
                [29.728085, 0.009818999000061],
                [29.727652, 0.00971],
                [29.727495, 0.009606000000133],
                [29.727325, 0.009494],
                [29.727180000000146, 0.009385000000123],
                [29.727028000000189, 0.00929],
                [29.726891001000126, 0.009205000000179],
                [29.726947793000193, 0.009102381000105],
                [29.727036000000112, 0.008943000000158],
                [29.727362, 0.008761999000171],
                [29.727346407000141, 0.008655378000185],
                [29.727325, 0.008509000000117],
                [29.727002299999981, 0.008217673000104],
                [29.726965000000121, 0.008184],
                [29.726965000000121, 0.007597001000079],
                [29.72674585499999, 0.007488033000129],
                [29.726577782000049, 0.007525785000041],
                [29.726421198000082, 0.00756095600002],
                [29.726377209000077, 0.007548297000142],
                [29.72629, 0.007523000000106],
                [29.72616800100019, 0.007488],
                [29.72616800100019, 0.007272000000114],
                [29.726204001000099, 0.00701900100006],
                [29.726458, 0.006838001000176],
                [29.726458, 0.006585],
                [29.726313000000175, 0.006432],
                [29.726231000000155, 0.006334],
                [29.726200260000098, 0.00629741900002],
                [29.726131439000142, 0.006215],
                [29.725950241000078, 0.005925999],
                [29.7259891490001, 0.005854781000153],
                [29.726044000000115, 0.005755],
                [29.726167633000159, 0.005528673000185],
                [29.726458, 0.005194000000131],
                [29.72675513900009, 0.004955462999988],
                [29.72681789100011, 0.004905087000111],
                [29.726912, 0.00482],
                [29.727216967000118, 0.004543614000113],
                [29.727203397000039, 0.004381137000109],
                [29.727180000000146, 0.004101000000105],
                [29.727000363000116, 0.003819121000106],
                [29.726927001000092, 0.003704001000187],
                [29.72684048900004, 0.003485001],
                [29.726784, 0.003342],
                [29.726494, 0.002791],
                [29.726431000000161, 0.002728000000161],
                [29.726365887000043, 0.002663307000091],
                [29.726276399000085, 0.002574],
                [29.726115146000154, 0.002357782000104],
                [29.726061000000129, 0.002285000000143],
                [29.726045, 0.002254],
                [29.725986, 0.002141000000108],
                [29.725894000000153, 0.002182000000118],
                [29.725884012000051, 0.002186459000029],
                [29.725837708000086, 0.002207],
                [29.72581535, 0.002216938000174],
                [29.72574, 0.00225],
                [29.725662000000113, 0.002285000000143],
                [29.725430163000055, 0.002391812000099],
                [29.725191039000151, 0.002501981000023],
                [29.724901199000158, 0.002430001000107],
                [29.724779099000159, 0.002257704000158],
                [29.724722001000089, 0.002177],
                [29.724736, 0.002122000000156],
                [29.724751399000183, 0.002059006000081],
                [29.724758148000149, 0.002032000000156],
                [29.724939346000042, 0.001888001000054],
                [29.725090309000166, 0.00185401300007],
                [29.725263997000127, 0.001815002000114],
                [29.72555375900015, 0.001816],
                [29.725579625000137, 0.001806789000057],
                [29.725771479000059, 0.001738473000103],
                [29.725986001000024, 0.001662],
                [29.726053001000082, 0.001572000000124],
                [29.7260950000001, 0.001452000000143],
                [29.7260950000001, 0.001228001000129],
                [29.726075, 0.001033],
                [29.726061000000129, 0.000903000000164],
                [29.72606087500003, 0.000532946000078],
                [29.725978000000168, 0.000497000000166],
                [29.725805, 0.000424000000123],
                [29.725712, 0.000451],
                [29.725554000000102, 0.000497000000166],
                [29.72544478899999, 0.000568552],
                [29.725264, 0.000687],
                [29.725121000000115, 0.000976000000151],
                [29.72501754100017, 0.001078321000023],
                [29.724939087000109, 0.001155913000048],
                [29.724886658000116, 0.001138553000033],
                [29.724768, 0.001099],
                [29.724722000000156, 0.001084],
                [29.724541001000034, 0.000830999000186],
                [29.724540910000087, 0.000577270000065],
                [29.724527, 0.000464],
                [29.72452132899997, 0.000418631000173],
                [29.724504470999989, 0.000280000000146],
                [29.724611283000172, 0.00000900200007],
                [29.724609042000054, -0.000118305999877],
                [29.724606001000041, -0.000316000999874],
                [29.724724001000084, -0.000848998999913],
                [29.724734965999971, -0.000967015999947],
                [29.724745, -0.001075],
                [29.724879, -0.001143999999897],
                [29.724898058000178, -0.001153817999921],
                [29.725044251000099, -0.001229],
                [29.72552108799999, -0.001336998999875],
                [29.725973041000032, -0.001372990999926],
                [29.726143649000107, -0.001337876999969],
                [29.726453782000078, -0.00127399899992],
                [29.726768785000161, -0.001122505999831],
                [29.726951417000123, -0.001034641999922],
                [29.726961000000131, -0.00103],
                [29.727388000000133, -0.001056999999889],
                [29.727391521000129, -0.001097487999971],
                [29.727432000000192, -0.001562999999862],
                [29.727474000000143, -0.001758999999822],
                [29.727502997000158, -0.001896985999963],
                [29.72761799500006, -0.001888764999876],
                [29.727879999000038, -0.00187],
                [29.728299000000163, -0.001863999999898],
                [29.728501322000056, -0.001861122999912],
                [29.728758000000141, -0.002114],
                [29.728795, -0.002292],
                [29.728824826000164, -0.002437016999977],
                [29.728853225000137, -0.002573998999935],
                [29.728918240000098, -0.002743091999889],
                [29.728951000000109, -0.002828],
                [29.729047670000114, -0.003079142999979],
                [29.729192652000052, -0.003144289999966],
                [29.729273001000024, -0.003180000999976],
                [29.729321016000029, -0.003229957999849],
                [29.729443905000153, -0.003357820999952],
                [29.729392264000069, -0.003538951999928],
                [29.729340001000082, -0.003712998999958],
                [29.729235000000187, -0.004065],
                [29.729481, -0.004426],
                [29.729502, -0.004779],
                [29.729443000000174, -0.005366],
                [29.729492393000044, -0.005665178999891],
                [29.729513168000096, -0.00579],
                [29.729631424000104, -0.006250998999974],
                [29.729780160000189, -0.006431953999936],
                [29.729934190000108, -0.006387685999925],
                [29.730033875000117, -0.006359],
                [29.73005115300009, -0.006346714999893],
                [29.730108, -0.006305999999881],
                [29.73031200000014, -0.006161],
                [29.730620960000124, -0.006296834999944],
                [29.730595526000116, -0.006188735999956],
                [29.73058891300019, -0.006160998999974],
                [29.730697631000112, -0.006142999999895],
                [29.730798689000153, -0.005945472999827],
                [29.730881000000124, -0.005784],
                [29.730915, -0.005717999999831],
                [29.731043, -0.005566],
                [29.731618025999978, -0.004883899999925],
                [29.731875000000116, -0.004654],
                [29.732265, -0.004309],
                [29.732637000000125, -0.003766999999868],
                [29.732643, -0.003343],
                [29.732866, -0.002548],
                [29.733385000000169, -0.002195],
                [29.733700834000047, -0.001980555999921],
                [29.734089000000154, -0.001716999999815],
                [29.734227220000037, -0.001648522999915],
                [29.734635999000034, -0.001446],
                [29.734822010000073, -0.001142983999898],
                [29.735008000000164, -0.000839999999812],
                [29.73531300000019, -0.000633],
                [29.73563189500004, -0.000596983999969],
                [29.735799790000158, -0.000577998999972],
                [29.735838629000057, -0.000545272999943],
                [29.735872, -0.000516999999888],
                [29.736012091000191, -0.000398766999979],
                [29.736291000000108, -0.00013],
                [29.736332000000118, -0.000091],
                [29.736388000000147, -0.000085],
                [29.736513000000173, -0.000073],
                [29.736582492000082, -0.000057745999925],
                [29.736595000000193, -0.000055],
                [29.736671000000115, -0.000045],
                [29.73670861700009, -0.00001678599989],
                [29.736770616000172, 0.000030271000185],
                [29.736635000000149, 0.000126],
                [29.736822000000188, 0.000341],
                [29.736919473000171, 0.000452901000131],
                [29.737098694000167, 0.000659001000145],
                [29.736932755000169, 0.001138001000186],
                [29.736967743000037, 0.001472504000049],
                [29.737019000000146, 0.001966],
                [29.737030000000118, 0.002068000000122],
                [29.737176167000086, 0.00265044300005],
                [29.737293, 0.003116000000148],
                [29.737745000000132, 0.003595000000132],
                [29.73813, 0.003834],
                [29.738268, 0.00392],
                [29.738482, 0.004209000000174],
                [29.738773, 0.004347],
                [29.73897875900019, 0.00444388700015],
                [29.739024818000189, 0.004790445000026],
                [29.739076813000111, 0.005183582000143],
                [29.73938486100019, 0.005528923000043],
                [29.739538, 0.0057],
                [29.739833, 0.005851000000121],
                [29.740227000000175, 0.006052],
                [29.741051534000178, 0.006248051],
                [29.741556000000173, 0.006368],
                [29.742317, 0.006629],
                [29.746067046, 0.002576084000054],
                [29.756917954000073, -0.013269902999923],
                [29.757387162000157, -0.013844859999892],
                [29.756969452000078, -0.014263999999855],
                [29.755285263000076, -0.015673999999876],
                [29.754089355000076, -0.016757999999868],
                [29.749577462000047, -0.020527490999939],
                [29.748683618000143, -0.021274270999925],
                [29.748516000000166, -0.021393],
                [29.747942148999982, -0.021797709999873],
                [29.747583390000102, -0.02205099899993],
                [29.74717549900015, -0.022375718999911],
                [29.746756000000175, -0.02271],
                [29.746241, -0.022791999999868],
                [29.746223472000167, -0.023011307999923],
                [29.746202, -0.02328],
                [29.745875613000067, -0.023867561999964],
                [29.745628000000124, -0.024227],
                [29.745415, -0.024535],
                [29.744617000000119, -0.025159],
                [29.744210979, -0.02547612099994],
                [29.744005202000153, -0.025637000999836],
                [29.743965150000179, -0.026342000999875],
                [29.743654251000123, -0.027009997999926],
                [29.743576007000058, -0.027118996999889],
                [29.743144511000082, -0.027044219999937],
                [29.743074, -0.027032000999952],
                [29.742792069000018, -0.026983011999903],
                [29.742780607000157, -0.02698524099992],
                [29.742731, -0.026995],
                [29.742674000000136, -0.027006],
                [29.742586000000131, -0.02701599999989],
                [29.742292000000134, -0.027048],
                [29.742065, -0.02692],
                [29.742097000000172, -0.026667],
                [29.74209959, -0.026646578999873],
                [29.742122651000045, -0.02646200099997],
                [29.74213166200019, -0.026388771999962],
                [29.742134000000192, -0.0263690029999],
                [29.742168658000082, -0.026325733999954],
                [29.742286682000042, -0.026177998999913],
                [29.7423515320001, -0.026097998999944],
                [29.742004947999988, -0.02601614699995],
                [29.741928078000058, -0.025998018999928],
                [29.741856054000152, -0.026060463999954],
                [29.74167000000017, -0.026222],
                [29.741438000000187, -0.026422999999852],
                [29.741300762000037, -0.026701477999893],
                [29.741161058000159, -0.026982476999933],
                [29.741125001000114, -0.027054998999915],
                [29.740981323000142, -0.027289054999926],
                [29.740753000000154, -0.027661],
                [29.740595, -0.02780399999989],
                [29.74039059, -0.027987365999877],
                [29.740260884000122, -0.028103419999979],
                [29.740049362000036, -0.028293000999952],
                [29.739869260000035, -0.028614378999976],
                [29.739832001000082, -0.02868099899996],
                [29.740489929000148, -0.029142045999947],
                [29.740761000000134, -0.029332],
                [29.740958985000077, -0.02940029399997],
                [29.741334999000117, -0.02953],
                [29.74114800000018, -0.029855],
                [29.741096000000141, -0.029866],
                [29.740588642000091, -0.029969207999898],
                [29.740570069000057, -0.029973],
                [29.740452210000171, -0.030080491999968],
                [29.740204, -0.030307],
                [29.740047000000175, -0.030699],
                [29.739982323000106, -0.030860283999914],
                [29.739889144000074, -0.031092998999895],
                [29.73955345100012, -0.031228],
                [29.739372253000056, -0.031300999999871],
                [29.739202979000083, -0.031571233999955],
                [29.73903300000012, -0.031842999999867],
                [29.738994330000139, -0.031941283999913],
                [29.738937377000127, -0.032086997999954],
                [29.738882065000155, -0.032501],
                [29.738880397000059, -0.032515715999921],
                [29.738871, -0.032602],
                [29.738728000000151, -0.032875999999874],
                [29.738705753000147, -0.032919988999879],
                [29.738684, -0.032962999999882],
                [29.738501000000156, -0.033052999999882],
                [29.738334, -0.032794999999851],
                [29.738262000000134, -0.032683],
                [29.738167, -0.032605],
                [29.738139969000031, -0.032582786999967],
                [29.737964629000089, -0.032439],
                [29.737569999000129, -0.032430004999924],
                [29.737540914000135, -0.032558465999898],
                [29.737474441000074, -0.03285399899994],
                [29.737530271000082, -0.033048164999911],
                [29.737565000000188, -0.033167999999876],
                [29.737589, -0.033251999999891],
                [29.738102, -0.033378],
                [29.738121, -0.033405],
                [29.738278003000119, -0.033629573999974],
                [29.738232, -0.033743],
                [29.73819, -0.033840999999882],
                [29.73812467700003, -0.033993093999925],
                [29.73802883500008, -0.034021344999871],
                [29.73776245199997, -0.0341],
                [29.737703324000051, -0.034177998999951],
                [29.737633374000154, -0.03436774499994],
                [29.737571930000058, -0.03453537199988],
                [29.737534, -0.034737],
                [29.737474000000134, -0.035049999999899],
                [29.737345000000118, -0.035010999999884],
                [29.737263, -0.034986],
                [29.736813000000154, -0.034733],
                [29.736589, -0.034503999999856],
                [29.736513030000026, -0.034426029999963],
                [29.736055000000135, -0.034759999999892],
                [29.736064802000044, -0.034967969999968],
                [29.736077997000166, -0.035247927999933],
                [29.735950000000116, -0.035182],
                [29.735776992000012, -0.03504507599996],
                [29.735599518000072, -0.03490500099997],
                [29.735416412000063, -0.03490500099997],
                [29.735346563, -0.034922159999894],
                [29.735242, -0.034948],
                [29.735083000000145, -0.034987],
                [29.734810000000152, -0.035039999999867],
                [29.734762, -0.03503899999987],
                [29.734098000000188, -0.035023],
                [29.73385250400014, -0.035002992999921],
                [29.73364400000014, -0.034986],
                [29.733195570000134, -0.034615551999934],
                [29.733029992000127, -0.034443391999901],
                [29.7327175530001, -0.034119039999894],
                [29.732596000000115, -0.034129999999891],
                [29.732526372, -0.03413601699998],
                [29.732515336000176, -0.034137],
                [29.732497827000032, -0.03413856599991],
                [29.732208000000128, -0.034165],
                [29.732113, -0.034172999999896],
                [29.731868967000082, -0.034226977999936],
                [29.731689007000114, -0.034105342999908],
                [29.731508146000124, -0.033983097999908],
                [29.731453460000068, -0.034032780999951],
                [29.731359, -0.034119],
                [29.73123, -0.034236],
                [29.731109665000133, -0.034345213999927],
                [29.730933042000174, -0.03445831099998],
                [29.73074340800008, -0.03458],
                [29.730705261000082, -0.034744998999884],
                [29.730669042000159, -0.034900955999944],
                [29.730616, -0.035130999999865],
                [29.730558, -0.035312],
                [29.73052, -0.035429],
                [29.730396916000018, -0.035654181999973],
                [29.730303001000152, -0.035825999999815],
                [29.730391025000188, -0.036103020999974],
                [29.730507000000102, -0.036468],
                [29.730486, -0.036524],
                [29.730468356000074, -0.036569873999895],
                [29.730461120000029, -0.036589000999868],
                [29.730438005000167, -0.036652759999924],
                [29.730417000000102, -0.036710999999855],
                [29.730285996000077, -0.037073055999883],
                [29.730269000000135, -0.037284],
                [29.730249, -0.037524],
                [29.730160489000127, -0.037641013999973],
                [29.730072000000177, -0.037757999999883],
                [29.730013810000116, -0.037834580999913],
                [29.729877584000064, -0.038012093999953],
                [29.729819000000191, -0.038016],
                [29.729778730000021, -0.038018585999964],
                [29.729455993000101, -0.038038998999866],
                [29.728883795000172, -0.037696030999825],
                [29.728392675, -0.037769461999858],
                [29.728155137000101, -0.037804997999956],
                [29.728140308000036, -0.037810718999879],
                [29.728006000000107, -0.037863],
                [29.727851641000029, -0.037921904999962],
                [29.727712158000145, -0.037885228999926],
                [29.727369300000191, -0.0377950799999],
                [29.727333068000178, -0.038192998999818],
                [29.727531254000098, -0.038374589999933],
                [29.728142000000162, -0.038934],
                [29.727833001000079, -0.039357998999947],
                [29.728496835000101, -0.039364811999974],
                [29.728708863000065, -0.039366988999973],
                [29.728760467000086, -0.039454322999973],
                [29.728888001000144, -0.039674999999875],
                [29.728959507000013, -0.039978527999949],
                [29.729031000000191, -0.040282],
                [29.729049, -0.040361],
                [29.729053281000176, -0.040380262999975],
                [29.729059219000135, -0.040406],
                [29.728931428000124, -0.040831],
                [29.728995877000045, -0.041050907999931],
                [29.729008, -0.041091999999878],
                [29.729047852000122, -0.041227496999966],
                [29.729223159000185, -0.041309912999964],
                [29.729586746000166, -0.041479946999914],
                [29.729504, -0.041898],
                [29.72945995300006, -0.042115960999865],
                [29.729455, -0.042426],
                [29.72945314899999, -0.042538107999974],
                [29.729446412000073, -0.042971],
                [29.729631424000104, -0.043929],
                [29.729589462000035, -0.044751],
                [29.729701219000106, -0.045298191999905],
                [29.729760873000089, -0.045757601999981],
                [29.729813, -0.04616099999987],
                [29.729785, -0.046269],
                [29.72977456100017, -0.046308667999938],
                [29.72975540200008, -0.046383000999867],
                [29.729698232000146, -0.0466057599999],
                [29.729676, -0.046693],
                [29.729588, -0.047036999999875],
                [29.72945, -0.047865],
                [29.729431793, -0.047885275999931],
                [29.729406358000119, -0.047913997999956],
                [29.729143351000118, -0.048216865999905],
                [29.729085231000113, -0.048283187999971],
                [29.728963224000097, -0.048313142999973],
                [29.728752137000129, -0.048364999999819],
                [29.728441238000073, -0.048879001999978],
                [29.728418349000037, -0.049042998999937],
                [29.728342056000031, -0.049548],
                [29.728355408000027, -0.050829998999916],
                [29.728317262000076, -0.051254997999933],
                [29.728604221000126, -0.051725656999963],
                [29.728674001000059, -0.05184],
                [29.728867, -0.052159],
                [29.728962000000138, -0.052618999999879],
                [29.729035762000024, -0.052978838999934],
                [29.729077, -0.053269000999933],
                [29.729118, -0.053558],
                [29.729080000000124, -0.054137],
                [29.728822, -0.054408],
                [29.728755996000132, -0.054477003999978],
                [29.728701160000071, -0.054534124999975],
                [29.728588000000116, -0.054652],
                [29.728272, -0.054573999999832],
                [29.72807693499999, -0.054524998999966],
                [29.727672577000135, -0.054496998999923],
                [29.727561999000045, -0.054489005999812],
                [29.727521740000157, -0.054676188999849],
                [29.727465000000109, -0.05494],
                [29.727403011000035, -0.055048480999915],
                [29.727340599, -0.055157195999925],
                [29.72713562500013, -0.055257432999952],
                [29.727022, -0.055313],
                [29.726913, -0.055364999999881],
                [29.726814, -0.055378000999895],
                [29.7260950000001, -0.055474],
                [29.726060000000189, -0.055500999999879],
                [29.725985, -0.055558],
                [29.725989161000086, -0.055574551999939],
                [29.726076997000121, -0.055923882999878],
                [29.726116, -0.056078999999897],
                [29.726377000000127, -0.056973],
                [29.72634491600013, -0.057241112999975],
                [29.726316475000033, -0.057280163999906],
                [29.726285852000046, -0.057322128999942],
                [29.726214241999969, -0.057385215999886],
                [29.725698472000147, -0.057840001999978],
                [29.725543426000115, -0.058063124999933],
                [29.725468, -0.058172],
                [29.725422000000151, -0.058238],
                [29.725402007000184, -0.058248213999889],
                [29.725237515000117, -0.058332255999915],
                [29.724964142000147, -0.058472],
                [29.72498130800011, -0.05872299899994],
                [29.724997140000028, -0.058932356999946],
                [29.725015820000124, -0.059174675999884],
                [29.725017325000181, -0.059222510999973],
                [29.725019455000165, -0.059279999999831],
                [29.725025960000096, -0.05944634399998],
                [29.725029000000177, -0.059519],
                [29.7250370000001, -0.059719],
                [29.724783001000162, -0.061715000999925],
                [29.724665001000176, -0.062424],
                [29.724658274000035, -0.06246099699996],
                [29.724651337000068, -0.062500997999962],
                [29.724529267000094, -0.062877],
                [29.724365234000118, -0.063387],
                [29.72432009400012, -0.064049667999939],
                [29.724291, -0.064479],
                [29.724257, -0.065037],
                [29.724251, -0.065148004999969],
                [29.724247000000105, -0.06547],
                [29.724244056000032, -0.065658428999825],
                [29.724243164000143, -0.065725996999902],
                [29.724264168000104, -0.065752289999864],
                [29.724305000000129, -0.065803],
                [29.724396000000183, -0.065916999999899],
                [29.724613, -0.066187],
                [29.72462779000017, -0.066205404999948],
                [29.724838256000169, -0.066467000999921],
                [29.725075058000073, -0.066696557999933],
                [29.725405693000141, -0.06701673699996],
                [29.725570910000044, -0.067260623999971],
                [29.725761000000148, -0.067541999999889],
                [29.725727000000177, -0.068063999999822],
                [29.72571900100013, -0.0681640009999],
                [29.725574409000103, -0.068832334999968],
                [29.725449001000186, -0.069411999999886],
                [29.725431067000102, -0.069665366999971],
                [29.725410461000024, -0.069963],
                [29.725466443000187, -0.070116008999946],
                [29.725493, -0.070188],
                [29.72552700000017, -0.070280999999852],
                [29.725536, -0.070306],
                [29.725540629000136, -0.070319157999961],
                [29.725555420000035, -0.070359996999969],
                [29.725664138000127, -0.070632002999957],
                [29.725761414000033, -0.070874996999976],
                [29.725868225000056, -0.071065997999938],
                [29.725969028000122, -0.071243500999969],
                [29.726012000000139, -0.071318999999846],
                [29.726145000000145, -0.071553],
                [29.72612328299999, -0.071653647999824],
                [29.726093000000105, -0.071794],
                [29.726076, -0.071874],
                [29.726048000999981, -0.072005],
                [29.726187, -0.072952998999938],
                [29.726463459000115, -0.073260479999817],
                [29.726490021000188, -0.073289996999961],
                [29.726543426000035, -0.073349996999923],
                [29.726526260000071, -0.073462998999958],
                [29.72644424399999, -0.07399199799994],
                [29.726131439000142, -0.074660001999973],
                [29.726547241000105, -0.075301997999873],
                [29.727268218000177, -0.075617998999917],
                [29.72769165, -0.075806997999905],
                [29.727966308000191, -0.076647995999963],
                [29.727760316, -0.077546998999935],
                [29.727708816000188, -0.077767997999899],
                [29.727594376000127, -0.078587003999928],
                [29.727540970000177, -0.078960000999928],
                [29.72728919900004, -0.079655996999975],
                [29.727146149000077, -0.0811730019999],
                [29.726984024000103, -0.081959001999905],
                [29.726734160000035, -0.08247399999982],
                [29.726596832000041, -0.083047001999887],
                [29.728336335000165, -0.083076997999967],
                [29.730035782000186, -0.083084000999918],
                [29.72955322200005, -0.090830995999909],
                [29.710865021000188, -0.181003002999887],
                [29.708511352000016, -0.192357003999916],
                [29.741806031000181, -0.18328650299992],
                [29.736381530000131, -0.206464051999887],
                [29.705829621000078, -0.205289006999919],
                [29.702989578000029, -0.218988],
                [29.69891357400013, -0.238655],
                [29.685234070000149, -0.304663985999923],
                [29.712369918999968, -0.309075951999944],
                [29.708553313000039, -0.325385926999957],
                [29.681753158000106, -0.321451990999947],
                [29.677320480000162, -0.342844991999868],
                [29.703498841000169, -0.346987307999882],
                [29.687271118000126, -0.41632714799988],
                [29.662454606000097, -0.416301011999963],
                [29.659166336000169, -0.416296988999875],
                [29.657918930000164, -0.422890990999974],
                [29.656694413000025, -0.429466008999952],
                [29.655662537000069, -0.434953002999919],
                [29.655469894000191, -0.435986994999951],
                [29.654172898000184, -0.442589998999949],
                [29.653585434999968, -0.445785998999895],
                [29.653043747000083, -0.448731004999956],
                [29.651746749000097, -0.455278992999922],
                [29.650890351000101, -0.459975987999883],
                [29.650724411000056, -0.460525988999848],
                [29.650772096000026, -0.46096],
                [29.651031495000041, -0.461122989999865],
                [29.651283265000131, -0.461167006999972],
                [29.651647567000055, -0.461196004999863],
                [29.651813507000099, -0.461340008999969],
                [29.65199089000015, -0.461710988999926],
                [29.651777268000046, -0.461926995999931],
                [29.651731491000078, -0.46214398799998],
                [29.651763762000144, -0.462214039999878],
                [29.651825, -0.462352],
                [29.651991000000123, -0.462595],
                [29.652067, -0.462629],
                [29.652252, -0.462713],
                [29.652393000000188, -0.462947999999869],
                [29.652393000000188, -0.463191999999879],
                [29.652445, -0.463461],
                [29.652489000000116, -0.463688999999874],
                [29.652570476000108, -0.463881998999966],
                [29.65263, -0.464022998999894],
                [29.652690912000139, -0.46409220399994],
                [29.652821, -0.46424],
                [29.652940760000035, -0.464270648999957],
                [29.653032244000144, -0.464293970999961],
                [29.653117, -0.464284],
                [29.653268696000168, -0.464267033999931],
                [29.653347961000065, -0.464315514999953],
                [29.653505000000166, -0.464411998999935],
                [29.653545000000122, -0.464621],
                [29.653554913000107, -0.464673538999932],
                [29.653553187000171, -0.464820850999956],
                [29.65355300900012, -0.464836000999924],
                [29.653576746000056, -0.46499937599998],
                [29.653862, -0.46527],
                [29.653863461000071, -0.465292997999939],
                [29.653885001000106, -0.465632000999904],
                [29.653885001000106, -0.465802998999948],
                [29.653885001000106, -0.465893998999888],
                [29.653933, -0.466227998999898],
                [29.653881000000183, -0.466328],
                [29.653864745000192, -0.466358388999879],
                [29.653858185000161, -0.466371],
                [29.653812408000192, -0.46646299899993],
                [29.653869255000188, -0.46674613599987],
                [29.653884987000083, -0.466823934999923],
                [29.654144005000035, -0.46682399599996],
                [29.654263180000044, -0.466720614999929],
                [29.654310001000056, -0.46667999899995],
                [29.654435757000158, -0.466541903999939],
                [29.654523758000096, -0.46644526699987],
                [29.654715000000124, -0.466155999999899],
                [29.654762, -0.466110999999898],
                [29.654819, -0.466057],
                [29.654941997000151, -0.465941879999946],
                [29.655021667000142, -0.465867012999979],
                [29.65520134799999, -0.465601713999888],
                [29.655214000000171, -0.465583],
                [29.65526, -0.465515],
                [29.655419000000109, -0.465505],
                [29.655668754000146, -0.465489037999873],
                [29.655685004000077, -0.465488002999962],
                [29.655899, -0.465632],
                [29.655928455000037, -0.465694212999892],
                [29.656112233000158, -0.466082383999947],
                [29.656279, -0.46617],
                [29.656445001000179, -0.466255998999941],
                [29.656646, -0.466321999999877],
                [29.656798646000027, -0.466372881999916],
                [29.656846, -0.466431],
                [29.656894740000155, -0.466490680999812],
                [29.657368, -0.466663],
                [29.657629000000156, -0.466708],
                [29.657961000000114, -0.466735],
                [29.658055508000075, -0.466771502999961],
                [29.65815, -0.466808],
                [29.658293000000185, -0.467015],
                [29.658459000000107, -0.467142],
                [29.658766, -0.467332],
                [29.659098, -0.467546],
                [29.659154349000175, -0.467582646999915],
                [29.659241000000122, -0.467638998999973],
                [29.659307021000188, -0.467666036999958],
                [29.659346, -0.467682],
                [29.659452000000158, -0.467725],
                [29.659595, -0.467783999999881],
                [29.659779, -0.46790499999986],
                [29.659857000000102, -0.467956],
                [29.659895, -0.468033999999875],
                [29.659986000000117, -0.468222],
                [29.660071, -0.468409999999892],
                [29.660151, -0.468588],
                [29.660234, -0.46877899999987],
                [29.660243963000084, -0.468801963999908],
                [29.660316468000133, -0.468968002999873],
                [29.66038633200003, -0.469073494999975],
                [29.66046000100016, -0.469183999999814],
                [29.660769, -0.469347],
                [29.660953784000071, -0.469461503999923],
                [29.661003088000086, -0.46949197399988],
                [29.661312, -0.469401999999889],
                [29.661361, -0.469361999999876],
                [29.661525793000067, -0.469230165999875],
                [29.661690401000044, -0.469302296999899],
                [29.661716946000183, -0.469371601999967],
                [29.661724091000167, -0.469390986999883],
                [29.66176223799999, -0.469491988999891],
                [29.661806108000064, -0.469568997999943],
                [29.66192817600006, -0.469781010999895],
                [29.662021376000155, -0.469824632999973],
                [29.662141879000046, -0.469880943999897],
                [29.662222652000082, -0.46982473099996],
                [29.662356557000066, -0.469731310999975],
                [29.662591106000036, -0.469568544999902],
                [29.662686999000073, -0.469502],
                [29.66288681900005, -0.469331034999811],
                [29.66296386700003, -0.469265012999927],
                [29.662981616000025, -0.469243356999925],
                [29.663034, -0.469179],
                [29.66316200000017, -0.469022999999879],
                [29.66328, -0.46868899999987],
                [29.663325000000157, -0.468662],
                [29.663360892000128, -0.468641062999893],
                [29.663565000000119, -0.468517],
                [29.663991997000039, -0.468427000999952],
                [29.664312307000046, -0.468444365999972],
                [29.664490000000114, -0.468453999999895],
                [29.664604925000106, -0.468612750999966],
                [29.664695625000149, -0.468758393999963],
                [29.66493993500012, -0.468806985999947],
                [29.665063858000167, -0.468751012999974],
                [29.665101, -0.46873399999987],
                [29.665199000000143, -0.468689999999867],
                [29.66529673000008, -0.468774776999965],
                [29.665365, -0.468834],
                [29.665398076000088, -0.468958724999823],
                [29.665460580000115, -0.469194418999962],
                [29.665544396000143, -0.469365481999887],
                [29.665554046000068, -0.469384997999896],
                [29.665581000000145, -0.469385],
                [29.665648999000155, -0.469386],
                [29.665911549000043, -0.469338676999882],
                [29.66605389800003, -0.46931301799998],
                [29.666066676000185, -0.469338866999919],
                [29.66612836000013, -0.469463642999926],
                [29.666170120000061, -0.469547986999885],
                [29.666177414000117, -0.469560768999884],
                [29.666188, -0.469578999999897],
                [29.666243, -0.469675],
                [29.666241, -0.470099],
                [29.666320348000056, -0.470208415999934],
                [29.666431427000077, -0.470360994999851],
                [29.666675366000106, -0.470511159999944],
                [29.666859, -0.470624],
                [29.667189000000121, -0.470840998999961],
                [29.667364203000147, -0.470898580999915],
                [29.667545001000178, -0.47095799899995],
                [29.667709000000173, -0.471156999999891],
                [29.667762, -0.471326],
                [29.667805000000101, -0.471464],
                [29.66804100000013, -0.471595],
                [29.668062694000128, -0.471607148999908],
                [29.668182, -0.471543],
                [29.668360246000077, -0.471440672999904],
                [29.668397996000181, -0.471419001999891],
                [29.66873, -0.471419],
                [29.669085006000159, -0.471537004999902],
                [29.669188410000174, -0.471631609999974],
                [29.669273377000025, -0.471709012999895],
                [29.669429196000067, -0.47181487499995],
                [29.669511679000038, -0.471870783999975],
                [29.669597040000099, -0.471890890999873],
                [29.669807168000034, -0.471939946999953],
                [29.66986077200005, -0.47192306099987],
                [29.67008, -0.471853999999837],
                [29.670189, -0.47184],
                [29.670294000000183, -0.471827],
                [29.670297758000117, -0.471843829999955],
                [29.670317470000157, -0.471929933999888],
                [29.67036400000012, -0.472133998999823],
                [29.670357249000176, -0.472145992999913],
                [29.670278871999983, -0.472285238999973],
                [29.670184176000134, -0.472453473999963],
                [29.670176, -0.472468],
                [29.669985, -0.472729999999842],
                [29.669911501000058, -0.472851997999896],
                [29.669838001000187, -0.472974],
                [29.669822265000164, -0.47299974799995],
                [29.66979408200001, -0.473046004999958],
                [29.669772418000036, -0.473085756999978],
                [29.66973688700017, -0.473151194999957],
                [29.669700487, -0.473214720999977],
                [29.669580460000134, -0.473425],
                [29.669548487000043, -0.473618772999941],
                [29.66953300099999, -0.473714000999905],
                [29.669592, -0.473814999999888],
                [29.669627735000063, -0.47387654399995],
                [29.669649274000108, -0.473931074999939],
                [29.669724, -0.474121],
                [29.669819000000132, -0.474309999999832],
                [29.669776998000032, -0.474554013999864],
                [29.669735000000117, -0.474798],
                [29.66964, -0.475078],
                [29.669758, -0.475295],
                [29.669832722000024, -0.475363705999882],
                [29.669994, -0.475511998999878],
                [29.670048120999979, -0.475649555999894],
                [29.670090000000187, -0.475756],
                [29.670065, -0.476018],
                [29.670135571000117, -0.476054501999954],
                [29.670325314000138, -0.476152645999946],
                [29.67063300000018, -0.476225999999826],
                [29.670824000000152, -0.476425],
                [29.670835000000181, -0.47647899999987],
                [29.670869984000035, -0.476659917999939],
                [29.670842, -0.476729],
                [29.670799, -0.476831],
                [29.670762, -0.476914],
                [29.670691, -0.477071],
                [29.670671000000141, -0.477115],
                [29.670660340000097, -0.477137334999895],
                [29.670650482000042, -0.477158993999922],
                [29.670574189000035, -0.477328984999872],
                [29.670578068000054, -0.477481591999947],
                [29.67058589700008, -0.477775183999938],
                [29.670479, -0.477938999999878],
                [29.670380333000139, -0.47808377299998],
                [29.670372, -0.478096],
                [29.670347000000106, -0.478619],
                [29.670346773, -0.47907203099993],
                [29.670304287000079, -0.479265736999878],
                [29.670254, -0.479495],
                [29.670284152000079, -0.479600099999914],
                [29.670324325000138, -0.479739010999879],
                [29.670391556000084, -0.479813484999909],
                [29.670480000000111, -0.479911],
                [29.670560804000047, -0.480000782999866],
                [29.670868, -0.480362999999898],
                [29.671177, -0.480570999999884],
                [29.671225000000106, -0.480769999999893],
                [29.671177, -0.480859999999893],
                [29.671034000000134, -0.480976999999882],
                [29.671141, -0.481229999999869],
                [29.671295, -0.481307],
                [29.671377181000025, -0.481348007999941],
                [29.67149133700002, -0.481391101999975],
                [29.671568, -0.48142],
                [29.67159700000019, -0.481485],
                [29.671684, -0.481682],
                [29.671566, -0.481854999999882],
                [29.67151100000018, -0.481938999999898],
                [29.67142500000017, -0.482071],
                [29.671614000000147, -0.482277999999894],
                [29.671694297000158, -0.482326630999921],
                [29.671898, -0.482449998999925],
                [29.672253001000172, -0.482567998999912],
                [29.672455018000107, -0.48261181099997],
                [29.672585, -0.482639999999833],
                [29.67274, -0.482765],
                [29.672797650000064, -0.482811716999834],
                [29.673058999000091, -0.482928998999853],
                [29.673225, -0.483047],
                [29.6733890000001, -0.483264],
                [29.6733890000001, -0.483381],
                [29.673465737000186, -0.483623391999913],
                [29.673578000000134, -0.483978],
                [29.673454000000163, -0.48426499999988],
                [29.673412, -0.484356999999875],
                [29.673603, -0.484572999999898],
                [29.673591500000157, -0.48463400199995],
                [29.673580001, -0.484695],
                [29.673566129000108, -0.484770582999886],
                [29.673531000000139, -0.484962000999928],
                [29.673531000000139, -0.485313998999914],
                [29.673649000000125, -0.485576],
                [29.673815, -0.485866],
                [29.673778000000141, -0.486117],
                [29.673758231000193, -0.486248988999932],
                [29.673744202000023, -0.486344008999879],
                [29.673885346000134, -0.486768990999963],
                [29.674074173000065, -0.486939994999886],
                [29.674171271000091, -0.487046806999956],
                [29.674288, -0.487175],
                [29.67417198000004, -0.487317043999838],
                [29.674074173000065, -0.487437009999894],
                [29.673931121000123, -0.487726002999921],
                [29.674074173000065, -0.488061010999843],
                [29.673993154000186, -0.488289832999953],
                [29.673955868000121, -0.488395038999954],
                [29.673909000000151, -0.488409],
                [29.673656055000095, -0.488482182999974],
                [29.673553466000044, -0.488512008999919],
                [29.673494440000127, -0.488564702999952],
                [29.673482999000157, -0.488575000999901],
                [29.673363000999984, -0.488684],
                [29.673396, -0.488814999999875],
                [29.673407295000118, -0.488860179999961],
                [29.673433303000138, -0.488963006999938],
                [29.673517755000034, -0.488997003999941],
                [29.673600000000192, -0.48903],
                [29.673861000000102, -0.489135],
                [29.674028123000141, -0.489280917999963],
                [29.674191139000129, -0.489423248999913],
                [29.674263345000099, -0.489614922999976],
                [29.674334001000034, -0.489803998999889],
                [29.674563515000045, -0.489969044999953],
                [29.674601, -0.489996],
                [29.674693946000104, -0.490062159999923],
                [29.674682322000024, -0.490096902999881],
                [29.674428, -0.490856999999892],
                [29.67418692300015, -0.491577278999898],
                [29.674183, -0.491589],
                [29.67409501100019, -0.491593000999956],
                [29.674004938999985, -0.491759015999946],
                [29.673929, -0.491898999999876],
                [29.673763000000179, -0.492187999999885],
                [29.67374, -0.49245],
                [29.673409000000163, -0.49245],
                [29.673334319000105, -0.492487340999958],
                [29.673265457000127, -0.492522000999884],
                [29.673454284000172, -0.492929011999934],
                [29.673480556000072, -0.493021017999979],
                [29.673497000000168, -0.493078],
                [29.67355, -0.493262999999843],
                [29.673311, -0.493262999999843],
                [29.673220000000185, -0.493207],
                [29.673128291000126, -0.493149922999976],
                [29.673076763000154, -0.493118069999923],
                [29.672804, -0.493199],
                [29.672724000000187, -0.49328],
                [29.672649, -0.493357999999887],
                [29.67263603300006, -0.493371008999873],
                [29.672653000000139, -0.493384999999876],
                [29.672826708000116, -0.493533749999926],
                [29.672934041000019, -0.493566655999928],
                [29.67306300100006, -0.493605998999954],
                [29.673110000000122, -0.493701],
                [29.673134000000175, -0.493751],
                [29.67285, -0.494112],
                [29.672808001000078, -0.494139999999845],
                [29.672786999000152, -0.494154000999913],
                [29.672704295000017, -0.494209136999871],
                [29.672565459999987, -0.494302004999838],
                [29.672367095000027, -0.494478998999909],
                [29.672304152000038, -0.494536010999923],
                [29.672278034000044, -0.494552227999918],
                [29.672232000000122, -0.494581000999915],
                [29.672078436000049, -0.494674952999958],
                [29.671995, -0.494726],
                [29.671639, -0.494804999999872],
                [29.671547000000146, -0.494825],
                [29.671509000000185, -0.49484399999983],
                [29.67116500100002, -0.495014],
                [29.670571764000101, -0.495276117999879],
                [29.670436, -0.495343999999875],
                [29.670336000000134, -0.495393000999968],
                [29.670170001000145, -0.495681999999874],
                [29.670139290000179, -0.495744207999962],
                [29.670053000000166, -0.495919],
                [29.670013, -0.495999],
                [29.670004000000176, -0.496016999999881],
                [29.669954000000132, -0.496060999999884],
                [29.669859064000093, -0.496143751999966],
                [29.669767381000042, -0.496223985999961],
                [29.669637309000109, -0.496399966999945],
                [29.669553839000116, -0.49651301199998],
                [29.669472, -0.496518999999864],
                [29.66916983200008, -0.49653876799988],
                [29.66915130700005, -0.496540010999979],
                [29.669092179000131, -0.496603994999873],
                [29.669074772000045, -0.496623041999953],
                [29.669054, -0.496645999999885],
                [29.668961001000127, -0.496748000999958],
                [29.668934001000025, -0.496936999999889],
                [29.668912990000138, -0.497082069999976],
                [29.668896000000132, -0.497202],
                [29.668865, -0.497416],
                [29.668798, -0.497545],
                [29.668652, -0.497822],
                [29.668529045000071, -0.497988045999819],
                [29.668438, -0.49811100099987],
                [29.668415097000093, -0.498134208999943],
                [29.668289000000186, -0.498262000999944],
                [29.66822400000018, -0.498328],
                [29.668142184000146, -0.498367368999936],
                [29.66809100100005, -0.498392],
                [29.668003236000175, -0.49843326599995],
                [29.667940000000101, -0.498463],
                [29.667695, -0.49851],
                [29.667655994000029, -0.498517002999961],
                [29.667644309000082, -0.498523364999869],
                [29.66756630000009, -0.498566001999961],
                [29.667442322000113, -0.498634993999872],
                [29.667465210000103, -0.498851000999878],
                [29.667600746000119, -0.498905633999868],
                [29.667868577000149, -0.499013428999945],
                [29.668320000000108, -0.499403],
                [29.668488000000139, -0.499596999999881],
                [29.668755406000059, -0.499908307999874],
                [29.669065000000103, -0.500224999999887],
                [29.669136, -0.500577],
                [29.669181, -0.500619],
                [29.669325374000152, -0.500754473999905],
                [29.66942781099999, -0.500990509999895],
                [29.669468, -0.501084],
                [29.669531095000025, -0.501210523999816],
                [29.669657, -0.501463],
                [29.669823000000122, -0.501850999999874],
                [29.670129978000091, -0.501923993999981],
                [29.670416000000159, -0.501942],
                [29.67058400000019, -0.50193],
                [29.670675, -0.501924],
                [29.670720171000028, -0.50205078099998],
                [29.670793704000118, -0.502257171999929],
                [29.671032000000139, -0.502376],
                [29.671268000000111, -0.502331],
                [29.671273670000062, -0.502343936999978],
                [29.671338953000031, -0.5024932419999],
                [29.671333171000128, -0.502523767999946],
                [29.671267, -0.502873000999955],
                [29.671196001000112, -0.503234000999896],
                [29.671237956000141, -0.503349060999938],
                [29.667459488000134, -0.507750808999958],
                [29.66954803499999, -0.513249874999929],
                [29.668659211000033, -0.516930817999935],
                [29.670471278000093, -0.52087744499994],
                [29.670966276000058, -0.521955359999936],
                [29.670973, -0.521969999999897],
                [29.670917, -0.522209],
                [29.670856014000151, -0.522466589999965],
                [29.670774460000132, -0.522813437999957],
                [29.670324325000138, -0.524724423999885],
                [29.669063409000046, -0.530091769999899],
                [29.668972010000061, -0.530480956999952],
                [29.669250000000147, -0.53828899999985],
                [29.669218000000171, -0.538368999999875],
                [29.669085001000099, -0.538434],
                [29.668930209000052, -0.538569440999936],
                [29.668909073000066, -0.538587985999868],
                [29.668876699000066, -0.538616539999964],
                [29.668763000000183, -0.538717],
                [29.668705000000159, -0.538768],
                [29.668648, -0.538836999999887],
                [29.66859, -0.538908999999876],
                [29.668530442000133, -0.538981792999948],
                [29.66849136400009, -0.53903001499998],
                [29.66844414600007, -0.539257851999878],
                [29.668418, -0.539385],
                [29.668398, -0.539481000999956],
                [29.668301001000032, -0.539978],
                [29.668255, -0.540365999999835],
                [29.667968879000114, -0.540646087999903],
                [29.667876000000149, -0.540714],
                [29.667707000000178, -0.540835999999899],
                [29.667568923000033, -0.540811800999961],
                [29.667416404000107, -0.540785125999946],
                [29.667329789000178, -0.540769992999969],
                [29.666972856000029, -0.540744974999939],
                [29.666939, -0.540739],
                [29.666666029000112, -0.540690004999931],
                [29.66635704100014, -0.540835022999829],
                [29.66630935600017, -0.541168986999935],
                [29.666452408000112, -0.541575013999932],
                [29.666543925000155, -0.541682884999886],
                [29.666596, -0.541743999999881],
                [29.666737, -0.54191],
                [29.666970383999967, -0.542088907999926],
                [29.667113345000189, -0.542198498999937],
                [29.667448, -0.54279],
                [29.667058089000079, -0.543763678999937],
                [29.66638218300011, -0.545451866999827],
                [29.665655136000055, -0.547267913999974],
                [29.665451049000126, -0.551174818999925],
                [29.66808319200004, -0.554993807999892],
                [29.668577195000182, -0.555187939999882],
                [29.674421311000174, -0.55558389499987],
                [29.679224014000113, -0.563265800999886],
                [29.679227126000171, -0.563796563999972],
                [29.679247001000135, -0.567170000999909],
                [29.679129824000142, -0.567369421999899],
                [29.67709, -0.570841],
                [29.677052000000174, -0.570821],
                [29.676815028000078, -0.570577029999924],
                [29.676791, -0.570593],
                [29.676736733999974, -0.57062917199994],
                [29.676700090000168, -0.5706531699999],
                [29.676624297000046, -0.570703029999947],
                [29.676340104000019, -0.571299015999955],
                [29.67631721500004, -0.57156097799998],
                [29.676330831000087, -0.571588662999943],
                [29.676356000000112, -0.571639],
                [29.676410932000067, -0.571750861999931],
                [29.676453000000151, -0.571925],
                [29.676199000000111, -0.572356999999897],
                [29.6759199440001, -0.57283096599997],
                [29.675891573000172, -0.572813942999971],
                [29.675865000000158, -0.572797998999818],
                [29.675722040000039, -0.57258106099988],
                [29.675510407000047, -0.572753011999907],
                [29.675388337000072, -0.572901009999896],
                [29.675358330000165, -0.572938361999888],
                [29.675334000000134, -0.572969],
                [29.67529700100016, -0.573015000999931],
                [29.675179329000173, -0.573023439999929],
                [29.675060272, -0.573032020999904],
                [29.674988276000079, -0.573113917999933],
                [29.674870000000112, -0.573249],
                [29.674988, -0.573539],
                [29.675047677000123, -0.573590151999895],
                [29.675177, -0.573701],
                [29.675293, -0.5739],
                [29.675104422000118, -0.574220737999951],
                [29.674805, -0.574729999999875],
                [29.674360775000139, -0.575483360999954],
                [29.674341202000051, -0.575515448999965],
                [29.67421898900011, -0.575723106999817],
                [29.674093, -0.575937999999894],
                [29.673958000000141, -0.576167],
                [29.672619000000168, -0.578446000999975],
                [29.672415000000171, -0.578795],
                [29.672263276000081, -0.578856700999893],
                [29.672174454000185, -0.578893004999884],
                [29.671995620000132, -0.579037660999973],
                [29.671846000000187, -0.579159],
                [29.671795, -0.5792],
                [29.671499011000151, -0.579434996999964],
                [29.671465, -0.579462],
                [29.671244000000115, -0.579721],
                [29.671179, -0.579796],
                [29.671061, -0.580175],
                [29.670994377000113, -0.580356824999967],
                [29.670965195, -0.580437004999908],
                [29.670940398000027, -0.580726026999969],
                [29.67100005900005, -0.580946841999946],
                [29.671035650000078, -0.581077717999904],
                [29.671061743000053, -0.581095832999893],
                [29.670813000000123, -0.581518999999844],
                [29.670443, -0.58214999899991],
                [29.670288075000144, -0.582099024999934],
                [29.670074463000049, -0.582189023999945],
                [29.670017298, -0.582576202999917],
                [29.670004000000176, -0.582667],
                [29.670033000000103, -0.582846],
                [29.668568, -0.585338],
                [29.666759, -0.588416],
                [29.666615880000165, -0.588659595999843],
                [29.666269, -0.58925],
                [29.666260501000124, -0.589263998999911],
                [29.666252, -0.589278],
                [29.666065, -0.589478999999869],
                [29.665934, -0.58962],
                [29.665602, -0.589665000999958],
                [29.665447509000046, -0.589737495999941],
                [29.665293000000133, -0.589809999999886],
                [29.664843000000189, -0.589926999999875],
                [29.664744601000109, -0.58987932499997],
                [29.664488000000119, -0.589755000999958],
                [29.664037927000095, -0.589592016999973],
                [29.663847, -0.589636999999868],
                [29.663712, -0.58974],
                [29.663659695000092, -0.589779775999887],
                [29.663633346000097, -0.5898],
                [29.663620804000118, -0.589886589999935],
                [29.663613, -0.589941999999894],
                [29.663588, -0.590116],
                [29.663686, -0.590314],
                [29.663776, -0.590495],
                [29.66378791000011, -0.590668468999979],
                [29.663799000000154, -0.59083],
                [29.663799000000154, -0.591091],
                [29.663732, -0.591133],
                [29.663609, -0.591209],
                [29.66327860500013, -0.59118987599993],
                [29.663058792000186, -0.591121490999967],
                [29.662874000000159, -0.591064],
                [29.662767, -0.591011],
                [29.662638000000186, -0.590946],
                [29.662457087000064, -0.590847090999887],
                [29.662424034000082, -0.590829018999841],
                [29.662115096999969, -0.590846001999978],
                [29.66178512700003, -0.590973019999922],
                [29.661558151000065, -0.591175019999866],
                [29.6615182540001, -0.591202821999957],
                [29.661458, -0.591245],
                [29.661240001000067, -0.591397],
                [29.661192, -0.59168600199996],
                [29.661193462000028, -0.591741177999893],
                [29.661203384000146, -0.592100976999916],
                [29.661235748000138, -0.592152981999959],
                [29.661266000000182, -0.592201],
                [29.661322, -0.592291],
                [29.661583000000121, -0.592409],
                [29.66167749499999, -0.592480995999836],
                [29.661772, -0.592552999999839],
                [29.661851204000072, -0.592662794999967],
                [29.661961000000133, -0.592815],
                [29.661961000000133, -0.593032],
                [29.661907780000149, -0.593117398999823],
                [29.661875001, -0.59317],
                [29.661638684000081, -0.59318746699995],
                [29.661415, -0.593204],
                [29.661219435000135, -0.59303007799997],
                [29.661102536000158, -0.592995881999968],
                [29.660890580000171, -0.592934011999944],
                [29.660873415000083, -0.592930279999962],
                [29.660787000000141, -0.592912],
                [29.660757006000154, -0.592905000999906],
                [29.660418070000162, -0.592859598999894],
                [29.660166000000117, -0.592751],
                [29.660042000000146, -0.592697],
                [29.659758, -0.592408],
                [29.659569, -0.592173],
                [29.659416, -0.591883999999823],
                [29.659378000000117, -0.591810999999893],
                [29.659211999000149, -0.591476999999884],
                [29.658857247000071, -0.591305119999959],
                [29.658842600000185, -0.591320338999878],
                [29.658811, -0.591354],
                [29.658751000000109, -0.591416],
                [29.658718146000012, -0.59144940099992],
                [29.658691407000049, -0.591476976999957],
                [29.658714295000152, -0.591765998999904],
                [29.658880233000048, -0.592100024999922],
                [29.659046173000092, -0.592451989999915],
                [29.659123487000102, -0.592555626999967],
                [29.65928006900009, -0.592765079999879],
                [29.659305, -0.592957],
                [29.65933, -0.593175],
                [29.659376000000123, -0.59341],
                [29.659325000000138, -0.593466],
                [29.659245027, -0.593554365999978],
                [29.659163, -0.593644999999867],
                [29.658808000000192, -0.593572],
                [29.65866, -0.593428999999844],
                [29.65859380400002, -0.593364494999946],
                [29.658452974000113, -0.593002964999926],
                [29.658446601000037, -0.592994946999966],
                [29.658050537000122, -0.592496990999905],
                [29.657870865000177, -0.592285925999931],
                [29.657789, -0.59219],
                [29.65760026900017, -0.592099129999951],
                [29.65746741200013, -0.592217269999935],
                [29.657387001000131, -0.592289000999926],
                [29.657402, -0.592456],
                [29.657434, -0.592813],
                [29.65737700000011, -0.592906],
                [29.657334002000084, -0.592976996999937],
                [29.657291001000146, -0.593048],
                [29.657145289000027, -0.593162894999921],
                [29.656959571000073, -0.593310502999884],
                [29.65689, -0.59355399999987],
                [29.656864, -0.59364399999987],
                [29.656852501000117, -0.59377449099992],
                [29.656841000000156, -0.593905],
                [29.656555, -0.594003999999813],
                [29.656177120000109, -0.594048662999967],
                [29.655930056000102, -0.593954439999891],
                [29.655727000000184, -0.593877],
                [29.655607, -0.593715],
                [29.655634000000134, -0.593572],
                [29.655656906000047, -0.59345348599993],
                [29.65570700000012, -0.593374],
                [29.655713673000037, -0.593363513999918],
                [29.655722690000175, -0.593349320999891],
                [29.655868530000134, -0.593119024999964],
                [29.655880677000027, -0.593034756999884],
                [29.655883000000131, -0.593017999999859],
                [29.655893, -0.592947000999914],
                [29.655779911000025, -0.592846120999923],
                [29.655679703000089, -0.592756985999927],
                [29.655648384000074, -0.59275157299993],
                [29.655313, -0.592694],
                [29.655159, -0.592667],
                [29.655011, -0.592664],
                [29.654739812, -0.59265771399987],
                [29.654708869000046, -0.592657029999941],
                [29.65395, -0.592829000999927],
                [29.653311001000191, -0.592991],
                [29.653025, -0.593280000999926],
                [29.652656914000147, -0.593523367999921],
                [29.652479, -0.59364100099998],
                [29.652313009000068, -0.593744493999907],
                [29.652147, -0.593848],
                [29.651883, -0.593972999999892],
                [29.651745, -0.594038],
                [29.651272000000176, -0.593803],
                [29.650760699000045, -0.593693517999952],
                [29.650706059000129, -0.593606275999946],
                [29.650642, -0.593504],
                [29.650525000000187, -0.593359],
                [29.650485620000097, -0.593309984999962],
                [29.65043068000017, -0.5932419899999],
                [29.650381622, -0.593221119999953],
                [29.650256000000127, -0.593168],
                [29.650026189000187, -0.593070080999894],
                [29.649939454000048, -0.593142428999954],
                [29.649878528000158, -0.593188472999941],
                [29.649528972000155, -0.593213971999887],
                [29.649340000000166, -0.593024999999898],
                [29.649006475000192, -0.59276206699991],
                [29.648758498000177, -0.592773143999921],
                [29.648605347000057, -0.592779993999955],
                [29.648526522000168, -0.593018883999946],
                [29.648514, -0.593056999999874],
                [29.64851, -0.593069],
                [29.648628097000142, -0.5932594399999],
                [29.64870100100012, -0.593377],
                [29.648887000000173, -0.593550999999877],
                [29.648960000000159, -0.59362],
                [29.649026000000106, -0.593727999999885],
                [29.64917400000013, -0.593972999999892],
                [29.649173912000094, -0.594307197999854],
                [29.64913526700019, -0.594394955999917],
                [29.649126053000145, -0.594416020999972],
                [29.649098817000038, -0.594480499999975],
                [29.649007084000175, -0.594696215999875],
                [29.648753000000113, -0.594756],
                [29.648640613000111, -0.594782073999966],
                [29.64862823500016, -0.594784975999971],
                [29.648617377000164, -0.594887907999919],
                [29.648612000000128, -0.594939999999895],
                [29.648581000999968, -0.595236998999951],
                [29.648607056000117, -0.59544660399996],
                [29.648626328000091, -0.595598994999875],
                [29.648958206000032, -0.595715998999879],
                [29.64899, -0.595715999999868],
                [29.649356339000178, -0.595716986999946],
                [29.649622, -0.595744],
                [29.649767000000168, -0.595807999999863],
                [29.650215000000117, -0.596005999999818],
                [29.650461000000178, -0.596042],
                [29.650854, -0.596267999999895],
                [29.65097192799999, -0.59665713499993],
                [29.650916000000166, -0.596761999999842],
                [29.650900001000025, -0.596791],
                [29.650883310000154, -0.596821087999842],
                [29.650829000000101, -0.596919],
                [29.650451, -0.596958],
                [29.650401656000099, -0.596963114999937],
                [29.650387000000137, -0.596967999999833],
                [29.650166000000127, -0.597035],
                [29.650166000000127, -0.59737],
                [29.650185, -0.597534],
                [29.649820627000054, -0.597651986999949],
                [29.649765086000173, -0.597669971999892],
                [29.650206932000117, -0.598822579999876],
                [29.650237000000118, -0.598900999999898],
                [29.651768000000175, -0.602893999999878],
                [29.651890455000057, -0.603215380999927],
                [29.652021409000099, -0.603558181999915],
                [29.654067993000126, -0.608899890999965],
                [29.654122602000086, -0.60904234599991],
                [29.65426900000017, -0.609423999999876],
                [29.655509382000105, -0.612661395999965],
                [29.655485, -0.612677],
                [29.655474, -0.612683999999888],
                [29.655427, -0.612858],
                [29.655410213000152, -0.61291885199995],
                [29.655403138, -0.612945019999813],
                [29.655426026000157, -0.613161980999905],
                [29.655466391000175, -0.613186740999936],
                [29.655616642000098, -0.613278781999895],
                [29.655723675000161, -0.613221175999911],
                [29.656834000000117, -0.616118],
                [29.656808136999985, -0.616173128999833],
                [29.656796000000156, -0.616199000999927],
                [29.656704000000161, -0.616487],
                [29.65676000000019, -0.616590999999858],
                [29.656845000000146, -0.616749],
                [29.657154000000105, -0.616952],
                [29.657158, -0.616961999999887],
                [29.657166, -0.616984],
                [29.657348000000184, -0.61746],
                [29.657384695000076, -0.617556198999978],
                [29.657415001000118, -0.617638998999951],
                [29.657457003000047, -0.617749007999919],
                [29.657499, -0.617859],
                [29.659513, -0.62311],
                [29.659252000000151, -0.626219],
                [29.659208, -0.626271],
                [29.659199, -0.626389],
                [29.659184, -0.626586999999859],
                [29.65921800000018, -0.626636],
                [29.659142, -0.627533],
                [29.658211000000165, -0.628130999999883],
                [29.658020001000068, -0.628271],
                [29.658012, -0.628277],
                [29.6578540000001, -0.628393],
                [29.657747000000143, -0.628467999999884],
                [29.657545000000141, -0.628609],
                [29.657443205000163, -0.62862524399992],
                [29.65726300100016, -0.628654000999973],
                [29.657000000000153, -0.628667999999891],
                [29.656847975000119, -0.628676338999981],
                [29.656763077000164, -0.62868100299994],
                [29.656658172000107, -0.628786979999973],
                [29.656610489000172, -0.629095971999959],
                [29.65666800200006, -0.629191737999975],
                [29.656693000000132, -0.629233],
                [29.656751731000156, -0.629330551999942],
                [29.656797216000086, -0.629442102999917],
                [29.656821942000192, -0.629502857999967],
                [29.656853, -0.629602],
                [29.656918000000132, -0.62981],
                [29.657036000000119, -0.630071999999814],
                [29.657036000000119, -0.630288999999891],
                [29.656918000000132, -0.630596],
                [29.656703988000174, -0.630858],
                [29.656420001000015, -0.630858000999922],
                [29.656321940000169, -0.630828047999842],
                [29.656181301000174, -0.63078509199994],
                [29.656141282000135, -0.630900024999846],
                [29.656124352000177, -0.63094661599996],
                [29.656111000000124, -0.630984],
                [29.656134, -0.631318],
                [29.655993000000137, -0.631454],
                [29.655691, -0.631437],
                [29.655660945000136, -0.631435054999884],
                [29.655630000000144, -0.631466],
                [29.65561388500015, -0.631481470999972],
                [29.655605317000152, -0.631489992999889],
                [29.655568396000035, -0.631527866999932],
                [29.655518, -0.631580000999975],
                [29.655505492000145, -0.631680562999861],
                [29.655493000000149, -0.631780999999819],
                [29.65547, -0.631958999999881],
                [29.655422000000158, -0.632056],
                [29.655230962000132, -0.632439147999833],
                [29.655010134000179, -0.632671972999901],
                [29.654736, -0.632961],
                [29.654664509000099, -0.63311448099995],
                [29.654593000000148, -0.633268],
                [29.654402000000118, -0.633575],
                [29.654425001000163, -0.633891],
                [29.654466000000127, -0.634129],
                [29.654495, -0.634297999999887],
                [29.654472, -0.634631999999897],
                [29.65439, -0.634742],
                [29.654329000000132, -0.634821999999815],
                [29.654210666000097, -0.634839819999968],
                [29.65373796900019, -0.634910997999896],
                [29.653696181999976, -0.634908355999926],
                [29.653310985000189, -0.634884012999976],
                [29.653085432000069, -0.635078171999965],
                [29.653038025000171, -0.635119019999877],
                [29.653032695000093, -0.635129609999865],
                [29.653023, -0.635149],
                [29.652920001000155, -0.635354],
                [29.652920001000155, -0.635588000999974],
                [29.653028886000016, -0.635721799999942],
                [29.653153448000012, -0.635874864999948],
                [29.653200281000068, -0.635968423999884],
                [29.653273992000038, -0.636118942999929],
                [29.653332, -0.636408],
                [29.653344854000068, -0.636473256999921],
                [29.653361404000179, -0.636563538999951],
                [29.653392923000069, -0.63673558399995],
                [29.653367826000192, -0.636998023999979],
                [29.653345883000043, -0.637001159999897],
                [29.653179, -0.637025],
                [29.65287, -0.636853],
                [29.652586000000156, -0.63688],
                [29.652206000000149, -0.63688],
                [29.652041000000168, -0.63688],
                [29.651841404000038, -0.636879058999909],
                [29.651828942000066, -0.636879026999907],
                [29.651740474000178, -0.636919994999914],
                [29.651679999000066, -0.636948000999951],
                [29.65152, -0.637024],
                [29.651306148000117, -0.637041987999908],
                [29.651286203000154, -0.63705904699998],
                [29.651234001000091, -0.637104],
                [29.651174120000064, -0.637156234999964],
                [29.65114021300019, -0.63718599099991],
                [29.650997161000078, -0.637565969999912],
                [29.650831223000182, -0.637880980999967],
                [29.650640487000032, -0.638089000999855],
                [29.650476457000138, -0.63836497099993],
                [29.650455476000104, -0.638440013999855],
                [29.650522232000128, -0.639145969999902],
                [29.650640487000032, -0.639931976999947],
                [29.650497436000023, -0.640482007999879],
                [29.650484085000073, -0.640942989999928],
                [29.650483818000112, -0.64140772899998],
                [29.650483, -0.64142],
                [29.650475001000189, -0.641503000999876],
                [29.650414001000115, -0.641855998999972],
                [29.650473309000063, -0.642192371999897],
                [29.650483992000147, -0.642252955999936],
                [29.650293000000147, -0.642451999999878],
                [29.65018229500015, -0.642595158999939],
                [29.649985595000032, -0.642849476999913],
                [29.649935128000038, -0.642909120999946],
                [29.649876001, -0.642979000999958],
                [29.649582000000123, -0.643326999999886],
                [29.649441, -0.643660999999895],
                [29.649408265000034, -0.643802699999981],
                [29.649368285000094, -0.64397698699986],
                [29.649408092000101, -0.64414560299997],
                [29.649441, -0.644284],
                [29.649513, -0.64432399999987],
                [29.649635, -0.644391],
                [29.649389000000156, -0.644814],
                [29.649149937000175, -0.645233386999962],
                [29.649109, -0.645595],
                [29.649034, -0.645620999999892],
                [29.648851, -0.645847],
                [29.648251701000106, -0.64602724699995],
                [29.64816500000012, -0.646099],
                [29.64808679600003, -0.646163675999958],
                [29.648023410000121, -0.64637467499989],
                [29.648014069000169, -0.646405995999942],
                [29.648134232000075, -0.646695970999929],
                [29.648150460000181, -0.646695929999851],
                [29.64837, -0.646695],
                [29.648513926000192, -0.646695],
                [29.648801068000182, -0.646602426999948],
                [29.648821, -0.646596],
                [29.648821, -0.646836000999883],
                [29.648748962000127, -0.647004272999936],
                [29.648714067000185, -0.647083996999811],
                [29.648689270000148, -0.647274017999962],
                [29.648733050000033, -0.647302939999975],
                [29.648861000000124, -0.647387],
                [29.648879973000192, -0.647399981999911],
                [29.648892, -0.647405332999938],
                [29.648907, -0.647411999999861],
                [29.648901379000051, -0.647462378999933],
                [29.64793978900002, -0.656079182999974],
                [29.647865295000088, -0.656143009999823],
                [29.64757919300007, -0.656333028999825],
                [29.647601131000101, -0.656354799999917],
                [29.647646000000179, -0.656399],
                [29.647878997000191, -0.656629996999868],
                [29.647872, -0.656691],
                [29.647867001000066, -0.656729992999885],
                [29.647844059000136, -0.656936068999869],
                [29.647638, -0.658787],
                [29.647087000000113, -0.659601],
                [29.64526442499999, -0.662296557999866],
                [29.643127476000018, -0.665457067999967],
                [29.643114055000069, -0.665476917999911],
                [29.642879089000189, -0.666324913999915],
                [29.641335561000176, -0.671896317999938],
                [29.641329, -0.67192],
                [29.64054322100003, -0.672721128999967],
                [29.639742, -0.673538],
                [29.63654136800011, -0.672867076999978],
                [29.63654758000007, -0.67383341999988],
                [29.636549000000173, -0.674042],
                [29.636559000000148, -0.675537],
                [29.63656, -0.675745],
                [29.63656, -0.675939999999855],
                [29.636464947999968, -0.676092104999896],
                [29.636454000000128, -0.676114],
                [29.636412, -0.676196999999888],
                [29.636324, -0.676303],
                [29.63632, -0.67635],
                [29.636293000000137, -0.67663],
                [29.636251976000153, -0.676757174999977],
                [29.636242899000024, -0.676785312999868],
                [29.636223, -0.676847],
                [29.63617104399998, -0.676829785999928],
                [29.636056958000154, -0.676791983999976],
                [29.635989, -0.67676699999987],
                [29.635818438000115, -0.676702166999917],
                [29.635759474000054, -0.67689848099991],
                [29.635748, -0.676937000999942],
                [29.63570630900017, -0.677055007999911],
                [29.635630000000162, -0.677270999999848],
                [29.635796, -0.677461],
                [29.635888444000045, -0.677509679999957],
                [29.635984000000178, -0.67756],
                [29.635984000000178, -0.677715],
                [29.635983, -0.677893998999878],
                [29.635998, -0.678071],
                [29.636002458000064, -0.678126478999843],
                [29.636007309000036, -0.678183019999892],
                [29.636022512000125, -0.678194712999868],
                [29.636056, -0.67822],
                [29.636125886000173, -0.678273911999952],
                [29.63624400100008, -0.678463],
                [29.636216458000035, -0.67856169099997],
                [29.636196137000013, -0.678634999999815],
                [29.636005401000091, -0.678870021999899],
                [29.636005401000091, -0.679113984999901],
                [29.63588714600013, -0.679275989999894],
                [29.635968435000052, -0.67937608099993],
                [29.636101, -0.679539],
                [29.636381286000187, -0.679690583999957],
                [29.636314393000077, -0.679827986999953],
                [29.636005401000091, -0.67997199399997],
                [29.63588714600013, -0.680324017999965],
                [29.635910034000062, -0.680594979999967],
                [29.635949, -0.680643],
                [29.636206, -0.680965],
                [29.636016943000129, -0.681083],
                [29.635707998000044, -0.681082003999961],
                [29.635686000000192, -0.681135999999867],
                [29.635612, -0.681317],
                [29.635517, -0.681552],
                [29.635451000000103, -0.681527],
                [29.635281, -0.681462],
                [29.63521900000012, -0.681387],
                [29.635139, -0.68129],
                [29.634855256000037, -0.681244041999946],
                [29.634818341000084, -0.681319936999898],
                [29.634813, -0.681331],
                [29.634782934999976, -0.681393127999854],
                [29.634749159000023, -0.681460206999873],
                [29.634712, -0.681534],
                [29.63461901400018, -0.681569460999867],
                [29.634492072000171, -0.681617818999882],
                [29.634473304000039, -0.681623351999974],
                [29.634271695000166, -0.681575073999966],
                [29.634093686000085, -0.68153372699993],
                [29.634061, -0.681544],
                [29.63385979300017, -0.681605584999943],
                [29.633826000000113, -0.681701],
                [29.633819543000016, -0.681719079999823],
                [29.633806228000083, -0.681756973999939],
                [29.633829863000187, -0.681794154999977],
                [29.633885, -0.68188],
                [29.634026000000176, -0.682101999999873],
                [29.634138000000178, -0.682144999999878],
                [29.6342140000001, -0.682174],
                [29.634275000000173, -0.682266],
                [29.634309614000188, -0.68231841599993],
                [29.634322144000123, -0.682433574999948],
                [29.63433300000014, -0.682536],
                [29.634142000000168, -0.682626],
                [29.634047000000123, -0.682619],
                [29.633905408000146, -0.682608031999905],
                [29.633886337000035, -0.682775973999981],
                [29.634071351000102, -0.683131991999915],
                [29.634212495000043, -0.683367013999941],
                [29.63420464100011, -0.683450396999888],
                [29.634200000000192, -0.683503],
                [29.63419000100015, -0.68361],
                [29.634047000000123, -0.68399000099987],
                [29.633983989000171, -0.684090125999887],
                [29.633974076000129, -0.684105990999967],
                [29.633928299000161, -0.684180021999964],
                [29.633951187000093, -0.684467971999879],
                [29.634187699000108, -0.684440969999969],
                [29.63426674599998, -0.68435375599995],
                [29.634327, -0.684287],
                [29.634424, -0.68418],
                [29.634567000000175, -0.684224998999923],
                [29.634727086000055, -0.684299805999899],
                [29.634780770000077, -0.684324893999928],
                [29.63481693600005, -0.68433705699988],
                [29.633861, -0.685188999999866],
                [29.632971000000111, -0.685983],
                [29.632963858000039, -0.686059787999966],
                [29.632412, -0.691993],
                [29.632202118000066, -0.691946038999902],
                [29.632146256000055, -0.691967262999924],
                [29.632104509000044, -0.691983187999881],
                [29.631985, -0.692029],
                [29.631915392000167, -0.692055480999898],
                [29.631893158000082, -0.692063987999916],
                [29.631752014000142, -0.692225992999909],
                [29.631681441000069, -0.692470013999923],
                [29.631758464000143, -0.692841663999957],
                [29.631774962000065, -0.692920821999905],
                [29.631896772, -0.693013444999963],
                [29.631966, -0.693066],
                [29.632011, -0.693140999999855],
                [29.632035884000175, -0.693182804999935],
                [29.632034, -0.693445],
                [29.631702, -0.69359],
                [29.631559000000152, -0.693668],
                [29.63160507200007, -0.693735979999872],
                [29.631702, -0.693878998999878],
                [29.631892999000172, -0.693968999999868],
                [29.632011, -0.694185999999888],
                [29.631927, -0.694719],
                [29.631950000000188, -0.694981],
                [29.63197300000013, -0.695125],
                [29.631666000000109, -0.695179000999815],
                [29.631523000000186, -0.695282],
                [29.631523000000186, -0.695865998999977],
                [29.631664000000114, -0.696010999999885],
                [29.63191, -0.696196998999937],
                [29.631974365000133, -0.696283696999899],
                [29.632007957000098, -0.696328942999912],
                [29.631960000000106, -0.696838],
                [29.63176, -0.696914],
                [29.631594000000177, -0.696968],
                [29.631522507000057, -0.697107485999936],
                [29.631451001000187, -0.697247],
                [29.631462000000113, -0.697385999999881],
                [29.631475000000137, -0.697563999999886],
                [29.631189, -0.69751],
                [29.631120511000177, -0.697303062999879],
                [29.631095886000026, -0.697229027999867],
                [29.631089990000078, -0.697217312999953],
                [29.631078000000116, -0.697194],
                [29.631023, -0.697085],
                [29.630777, -0.697016],
                [29.630764008000085, -0.697012006999955],
                [29.630678177000163, -0.697086990999935],
                [29.63059806800004, -0.697157024999967],
                [29.630578981000156, -0.697209253999915],
                [29.630503000000147, -0.697418],
                [29.630461, -0.69757],
                [29.630441995000183, -0.697637438999834],
                [29.63043022200003, -0.697679996999966],
                [29.630241394, -0.697825014999978],
                [29.630231259000141, -0.697909134999918],
                [29.630219000000125, -0.698014999999884],
                [29.630305000000135, -0.698198],
                [29.630384000000106, -0.698367],
                [29.630384000000106, -0.698510999999883],
                [29.63007, -0.698535],
                [29.630038741000192, -0.69853739499996],
                [29.629822000000104, -0.698553999999888],
                [29.629723000000126, -0.698634],
                [29.62960100000015, -0.698773],
                [29.629659000000117, -0.698975],
                [29.629670767000164, -0.699016184999948],
                [29.629652, -0.699074],
                [29.629578, -0.699296],
                [29.629498000000126, -0.699349999999868],
                [29.629442000000154, -0.699387999999885],
                [29.629364, -0.699441],
                [29.629103000000157, -0.699349999999868],
                [29.628962, -0.699079],
                [29.628895754000041, -0.698848519999956],
                [29.628866, -0.698745],
                [29.628723025000056, -0.698655015999918],
                [29.628554213000143, -0.698665174999974],
                [29.628441, -0.698671999999874],
                [29.628377000000114, -0.69871],
                [29.628225000000157, -0.698798999999838],
                [29.628180525000118, -0.698774034999815],
                [29.628012991000105, -0.698680306999961],
                [29.627965927000105, -0.698653995999905],
                [29.627902352000092, -0.698636348999969],
                [29.627608999000074, -0.698555000999875],
                [29.627489992999983, -0.698536166999929],
                [29.627311706000057, -0.698508023999921],
                [29.627294064000068, -0.69850658099989],
                [29.627135, -0.698494],
                [29.626989278000053, -0.698483162999821],
                [29.626945, -0.698497],
                [29.626757001000101, -0.698553999999888],
                [29.626828520000061, -0.698662530999911],
                [29.626900000000148, -0.698771],
                [29.626958000000116, -0.698917],
                [29.626992937000068, -0.699005840999973],
                [29.627020238000114, -0.699185311999827],
                [29.627040890000046, -0.699321284999883],
                [29.626936174000093, -0.699441927999942],
                [29.626922913000158, -0.699457222999854],
                [29.626864000000182, -0.699607999999898],
                [29.62684087700012, -0.699666119999904],
                [29.626827241000058, -0.699701010999945],
                [29.626850127000182, -0.699890970999945],
                [29.626869189000161, -0.699947778999956],
                [29.626923000999966, -0.700107],
                [29.626933000000179, -0.70022299999988],
                [29.626945, -0.70037],
                [29.626905000000193, -0.70054],
                [29.62688, -0.700656],
                [29.626875, -0.700676999999871],
                [29.626856, -0.700751],
                [29.626815000000192, -0.700906],
                [29.626794144000087, -0.700983004999955],
                [29.626789092000024, -0.701002000999949],
                [29.626884460999975, -0.701191007999967],
                [29.626908681000032, -0.701220236999973],
                [29.626949, -0.701268],
                [29.627005000000111, -0.701336],
                [29.627028, -0.701552999999876],
                [29.626954000000126, -0.701678999999899],
                [29.626930180000159, -0.70171750899982],
                [29.626856365000151, -0.701778737999916],
                [29.626715000000104, -0.701896],
                [29.626634000000138, -0.701906],
                [29.626411000000189, -0.701931999999886],
                [29.626268, -0.701859],
                [29.625982457000134, -0.701825095999936],
                [29.62588896800014, -0.701814012999876],
                [29.625462000000141, -0.701975999999888],
                [29.625379215000123, -0.702027296999972],
                [29.625299454000015, -0.702076971999873],
                [29.625297327000112, -0.70224705499993],
                [29.625296001000038, -0.702381998999897],
                [29.625273000000163, -0.70259900099984],
                [29.625268408000125, -0.702608067999961],
                [29.625232224000172, -0.702679532999923],
                [29.625154495, -0.702834008999901],
                [29.625154495, -0.703024029999938],
                [29.625235376000035, -0.703148357999908],
                [29.625296001000038, -0.703241],
                [29.625362835000033, -0.703250598999944],
                [29.625484, -0.703268],
                [29.625681000000156, -0.703364],
                [29.625723000000107, -0.703384999999855],
                [29.625762000000122, -0.703439],
                [29.625838866000095, -0.703547809999918],
                [29.625850060000118, -0.703686966999953],
                [29.625862, -0.703837],
                [29.625806499000134, -0.703953002999867],
                [29.625750477000111, -0.704069034999975],
                [29.625485442000127, -0.704087441999889],
                [29.625318364000179, -0.70409875799993],
                [29.625138386, -0.704030229999944],
                [29.625080088000175, -0.70400803299998],
                [29.625059000000192, -0.704054999999869],
                [29.624987, -0.704216],
                [29.624987, -0.70455],
                [29.624939, -0.704838999999879],
                [29.624951479000117, -0.704845985999953],
                [29.625198365000131, -0.704984007999883],
                [29.62525687700014, -0.7050037299999],
                [29.625386000000105, -0.705047],
                [29.625412, -0.705056],
                [29.625461448000124, -0.705169521999892],
                [29.625483000000145, -0.705218999999886],
                [29.62537701500014, -0.705340482999929],
                [29.625271000000168, -0.705462000999944],
                [29.625247391000073, -0.70560956099996],
                [29.625223161, -0.705762027999867],
                [29.625249025000016, -0.705824018999976],
                [29.625313228000095, -0.705977013999927],
                [29.625483000000145, -0.706294],
                [29.625766319000036, -0.706585207999979],
                [29.625736498000151, -0.706681863999961],
                [29.62571716400015, -0.706745026999954],
                [29.626003265000065, -0.706943988999967],
                [29.626062945000058, -0.706973842999957],
                [29.626234676000138, -0.707059341999923],
                [29.626244, -0.707106],
                [29.626268, -0.707250999999872],
                [29.62586, -0.707324],
                [29.625587, -0.707405],
                [29.625576, -0.707503],
                [29.625565000000165, -0.707593999999858],
                [29.625659982, -0.707811178999975],
                [29.625655000000165, -0.707862],
                [29.625643714000091, -0.707970239999895],
                [29.625633240000127, -0.708073018999869],
                [29.625467300000082, -0.708307980999962],
                [29.625587463000159, -0.708451986999876],
                [29.625808814000095, -0.70857086299992],
                [29.625824, -0.708579],
                [29.625871661000019, -0.708814231999895],
                [29.625727, -0.708913],
                [29.625647724000089, -0.708966131999944],
                [29.625633240000127, -0.708975969999869],
                [29.625751496000134, -0.70921999199993],
                [29.625965118000067, -0.709293006999928],
                [29.625940322000133, -0.709528028999898],
                [29.625896098, -0.709555072999933],
                [29.62582, -0.709602],
                [29.625746001000095, -0.709646998999972],
                [29.625835849999987, -0.709820065999907],
                [29.625895000000128, -0.709934],
                [29.626026097000192, -0.70997873399989],
                [29.626105909000046, -0.710005968999951],
                [29.626106000000163, -0.710413],
                [29.626079384000093, -0.710627798999951],
                [29.626049001000126, -0.710872999999822],
                [29.626047404000076, -0.710886387999949],
                [29.626036, -0.710982000999934],
                [29.626054000000124, -0.711224999999899],
                [29.626081, -0.711586999999838],
                [29.625989, -0.711658],
                [29.625952, -0.711687],
                [29.625909998000111, -0.711718501999883],
                [29.625867800000151, -0.711750059999929],
                [29.625775000000147, -0.711778],
                [29.625697466000076, -0.711801152999954],
                [29.625631333000058, -0.711821018999899],
                [29.625621, -0.711942],
                [29.625607, -0.712111],
                [29.625607, -0.712357],
                [29.625689000000136, -0.712422999999887],
                [29.625706700000137, -0.712437287999933],
                [29.625772170000062, -0.712489776999917],
                [29.625845698000091, -0.712392759999886],
                [29.625916, -0.712299998999924],
                [29.62602305200005, -0.712243627999896],
                [29.626087018000192, -0.712210014999869],
                [29.626104000000169, -0.712201],
                [29.626158, -0.712215999999898],
                [29.626199937000024, -0.712227981999945],
                [29.6262, -0.712462999999843],
                [29.626177000000155, -0.712707],
                [29.626341, -0.712825],
                [29.626404, -0.712844],
                [29.626577, -0.712897],
                [29.626770000000192, -0.712987],
                [29.62679088800013, -0.712996946999965],
                [29.626886, -0.713186],
                [29.626884, -0.713493],
                [29.626931728000102, -0.713728407999895],
                [29.626872000000105, -0.713818],
                [29.626795000000129, -0.713933],
                [29.626749, -0.714000999999882],
                [29.626682000000187, -0.714099],
                [29.626398000000165, -0.714198],
                [29.626381099000071, -0.71423234699995],
                [29.626305000000116, -0.714387000999977],
                [29.626316500000144, -0.714504502999887],
                [29.626328, -0.714622],
                [29.626244, -0.714726],
                [29.625969798000085, -0.714813076999974],
                [29.625948, -0.71482],
                [29.625878582000155, -0.714730380999924],
                [29.625759, -0.714576],
                [29.625616, -0.714387000999977],
                [29.625450132000083, -0.714387000999977],
                [29.625423171000079, -0.714414116999876],
                [29.625356810000142, -0.714481189999958],
                [29.625308000000189, -0.71453],
                [29.62527881200009, -0.714558566999926],
                [29.625261308000063, -0.714576005999959],
                [29.625178000000119, -0.714659],
                [29.625071000000162, -0.714766],
                [29.625013035000052, -0.714829415999873],
                [29.6249961850001, -0.714847980999821],
                [29.624834060000069, -0.715027988999964],
                [29.62484143100005, -0.715140652999878],
                [29.624858997000103, -0.715407050999886],
                [29.624841317000062, -0.71568143099995],
                [29.624830283000051, -0.715797000999828],
                [29.624743, -0.71584399999989],
                [29.624679, -0.715879],
                [29.624668, -0.715885],
                [29.62447699900008, -0.715794999999844],
                [29.62433607700018, -0.715596108999819],
                [29.62422368599999, -0.715656440999908],
                [29.624100000000112, -0.715723],
                [29.624036000000103, -0.715846999999883],
                [29.624016142000073, -0.715885335999928],
                [29.623964310000076, -0.715986012999906],
                [29.623962732000109, -0.716024897999944],
                [29.623961000000122, -0.716077999999811],
                [29.623957000000132, -0.716174],
                [29.624185000000182, -0.716340999999886],
                [29.624216000000104, -0.716364],
                [29.624477000000184, -0.716463],
                [29.624500000000126, -0.716599],
                [29.624477000000184, -0.716797999999869],
                [29.624168, -0.716841999999872],
                [29.624087080000095, -0.717093464999891],
                [29.624075000000175, -0.717130998999892],
                [29.624263984000152, -0.717365979999954],
                [29.624500007000051, -0.717366991999882],
                [29.624563, -0.717293999999868],
                [29.6246145, -0.717235499999902],
                [29.624666, -0.717176999999879],
                [29.624755000000107, -0.717136],
                [29.624879974000123, -0.717078011999945],
                [29.6249, -0.717087999999876],
                [29.62499, -0.717133999999874],
                [29.624994105000098, -0.717290500999923],
                [29.624998001000051, -0.717439000999889],
                [29.62494848700004, -0.717518053999925],
                [29.624760000000151, -0.717819],
                [29.62464000000017, -0.717979],
                [29.624545340000168, -0.71810406599991],
                [29.624475479000182, -0.718196988999978],
                [29.624168396000073, -0.71822398899991],
                [29.624025345000064, -0.718486010999925],
                [29.624071122000032, -0.718675970999925],
                [29.624214171, -0.718819974999917],
                [29.624380112000154, -0.71893799299994],
                [29.624396969000031, -0.719014710999943],
                [29.624427793000109, -0.71915406599993],
                [29.624399291000088, -0.719275735999872],
                [29.624355316000049, -0.719461976999924],
                [29.624023437000062, -0.719605980999859],
                [29.623905182000158, -0.719923018999964],
                [29.623886195000068, -0.720037771999955],
                [29.623837, -0.720337999999856],
                [29.623827, -0.720397],
                [29.623823070000071, -0.720417305999945],
                [29.623821258000135, -0.720427990999838],
                [29.623844146000067, -0.720627008999941],
                [29.623938311000131, -0.720693660999871],
                [29.624035, -0.720762],
                [29.624113697000041, -0.720798679999973],
                [29.624152884000182, -0.720816945999957],
                [29.624153, -0.720960999999875],
                [29.624092622000035, -0.72104150499996],
                [29.624084, -0.721053],
                [29.624010000000112, -0.721151],
                [29.623971121000181, -0.721213272999876],
                [29.623892000000126, -0.721339999999884],
                [29.623974000000146, -0.72149],
                [29.624004651000121, -0.721547044999966],
                [29.624010087000045, -0.72155702099991],
                [29.624176025000054, -0.721602976999975],
                [29.624238000000162, -0.721625999999844],
                [29.624367000000177, -0.721674999999891],
                [29.624415, -0.72183299999989],
                [29.624432239000157, -0.721890079999923],
                [29.62446022000006, -0.721982000999901],
                [29.624483109000096, -0.72227102599993],
                [29.624504973000114, -0.722300703999963],
                [29.624537, -0.722344],
                [29.624696679000181, -0.722560564999981],
                [29.624886000000117, -0.722749999999849],
                [29.62510401500009, -0.722925520999979],
                [29.625122071000192, -0.722940026999936],
                [29.625431060999972, -0.723084985999947],
                [29.62571716400015, -0.723116993999838],
                [29.625759835, -0.72312176],
                [29.625833999000122, -0.723129998999923],
                [29.626001, -0.723173],
                [29.626047, -0.723185],
                [29.626069002000122, -0.723265881999964],
                [29.626118, -0.723446],
                [29.626073133000034, -0.723666590999926],
                [29.626070023000125, -0.723681986999964],
                [29.626092110000059, -0.723699666999892],
                [29.626283989000058, -0.723852990999887],
                [29.626532290000057, -0.723853617999964],
                [29.62668562500005, -0.723854],
                [29.626716258000044, -0.723856851999926],
                [29.626877, -0.723872],
                [29.627018000000135, -0.724042999999881],
                [29.627279, -0.724016],
                [29.627397, -0.724045999999873],
                [29.627493000000186, -0.724071],
                [29.627659, -0.72440500099998],
                [29.627562001000115, -0.724739],
                [29.627709889000073, -0.724782818999927],
                [29.627832001000058, -0.724818998999979],
                [29.627938489000144, -0.724895315999959],
                [29.62801139600009, -0.724947567999948],
                [29.627981155000157, -0.725009688999933],
                [29.627970804000086, -0.725030390999962],
                [29.627939223000055, -0.725094020999904],
                [29.627892028000133, -0.725188954999965],
                [29.627846, -0.725282],
                [29.627823, -0.725669999999866],
                [29.627883000000111, -0.725689],
                [29.628034097000068, -0.725737323999908],
                [29.628105028000164, -0.725759939999932],
                [29.628121, -0.725726],
                [29.628156947000093, -0.725654105999979],
                [29.628204497000127, -0.725535006999962],
                [29.628252, -0.725416000999815],
                [29.628256942000121, -0.725429865999956],
                [29.628530000000183, -0.726196],
                [29.628726964000123, -0.726749071999961],
                [29.628707, -0.726788999999883],
                [29.628687000000184, -0.726827],
                [29.628733, -0.726939],
                [29.62880500000017, -0.727116],
                [29.628808345000039, -0.727135634999968],
                [29.628828000000112, -0.727250999999853],
                [29.628809442000033, -0.727304446999938],
                [29.628803000000175, -0.727323],
                [29.62877200000014, -0.727412],
                [29.628733001000171, -0.727522],
                [29.62868434000012, -0.72765480299995],
                [29.628637314000173, -0.727783976999888],
                [29.62882614200015, -0.728064],
                [29.628882431000079, -0.728121610999949],
                [29.629065001000129, -0.728308],
                [29.629219049000085, -0.728534016999959],
                [29.629324000000111, -0.728688],
                [29.629352000000154, -0.728726],
                [29.62948, -0.728903],
                [29.629654, -0.729142],
                [29.629699778000145, -0.729205690999947],
                [29.629762262000156, -0.72922549599997],
                [29.629799000000162, -0.729237],
                [29.629912747000105, -0.729272919999971],
                [29.630011731000081, -0.729273],
                [29.630037307000066, -0.729272901999877],
                [29.63036346399997, -0.729271769999968],
                [29.63041647700004, -0.729490071999919],
                [29.630439986999988, -0.729585948999954],
                [29.630341969000085, -0.729594056999929],
                [29.630075456000156, -0.729616163999879],
                [29.629976273000182, -0.729660451999905],
                [29.629982194000036, -0.729671768999935],
                [29.630049, -0.729799],
                [29.630047117000174, -0.729818234999811],
                [29.630026000000157, -0.730033999999876],
                [29.629864, -0.730145],
                [29.629848085000049, -0.730155900999932],
                [29.629791260000047, -0.730194866999966],
                [29.629644393000092, -0.730296015999897],
                [29.629646301000093, -0.730530976999887],
                [29.62968635499999, -0.730822978999811],
                [29.62968934, -0.730871044999958],
                [29.629670000000147, -0.730914999999868],
                [29.629599000000155, -0.731070999999815],
                [29.629635000000121, -0.73117],
                [29.629640933000132, -0.731186412999932],
                [29.629665374000126, -0.731252907999931],
                [29.630010796000136, -0.731371854999907],
                [29.630025609000029, -0.731395948999875],
                [29.630072069000107, -0.731471518999911],
                [29.630281448000119, -0.731811761999893],
                [29.63040348100003, -0.731957667999893],
                [29.63052400000015, -0.732101],
                [29.63057, -0.732341],
                [29.630578976000038, -0.732386877999886],
                [29.630481774000032, -0.732746240999973],
                [29.630452, -0.732909999999833],
                [29.630443517000117, -0.73295638399992],
                [29.630405426000095, -0.733167348999871],
                [29.630224228000031, -0.733456968999974],
                [29.63014221200018, -0.733748495999919],
                [29.630178451000063, -0.734071134999965],
                [29.630264282000155, -0.734387098999832],
                [29.630318585000111, -0.734581518999846],
                [29.630358, -0.734722],
                [29.630455, -0.735075999999879],
                [29.630493, -0.735217],
                [29.630533000000185, -0.735500999999886],
                [29.63056, -0.735695],
                [29.630535000000123, -0.735784],
                [29.630446638000137, -0.736094612999977],
                [29.630326382000135, -0.736299250999934],
                [29.63026600000012, -0.736402],
                [29.630041000000119, -0.736269],
                [29.629839000000118, -0.736062],
                [29.62963711000009, -0.73585382899995],
                [29.629613904000166, -0.735829978999845],
                [29.62950765800008, -0.735806465999929],
                [29.629003905000047, -0.735695087999943],
                [29.62887193500012, -0.735817193999935],
                [29.628723145000095, -0.735955357999899],
                [29.628809367000088, -0.736301502999936],
                [29.629141000000118, -0.737631998999973],
                [29.629149481000184, -0.73766552099994],
                [29.629183000000182, -0.737798],
                [29.629342, -0.737879],
                [29.629415465000136, -0.737916690999839],
                [29.62945734300007, -0.737938037999925],
                [29.629673001000128, -0.737961998999936],
                [29.629748999000014, -0.737967998999977],
                [29.630066, -0.737887],
                [29.630255, -0.738075],
                [29.630167, -0.738229],
                [29.630158965000192, -0.738243060999935],
                [29.63012700000013, -0.738299],
                [29.62998, -0.738328999999851],
                [29.629871318000141, -0.738350935999961],
                [29.629865000000109, -0.738375],
                [29.629862, -0.738386],
                [29.629838411000151, -0.738469072999976],
                [29.629791260000047, -0.738636135999968],
                [29.629729961000066, -0.738907399999903],
                [29.629679000000181, -0.739134],
                [29.629794000000118, -0.739679],
                [29.629874710000024, -0.740063616999919],
                [29.62979016800017, -0.740012116999935],
                [29.629720687000031, -0.739969013999939],
                [29.629388809000091, -0.739852011999915],
                [29.629127700000083, -0.739734082999917],
                [29.628933000000131, -0.739787999999862],
                [29.628866196000104, -0.739805994999927],
                [29.628841400000169, -0.740086017999829],
                [29.628984452000111, -0.74034899499992],
                [29.628984452000111, -0.740619002999949],
                [29.628841400000169, -0.740899025999965],
                [29.629173278999986, -0.741162000999964],
                [29.629393807000156, -0.741282845999933],
                [29.629467481000177, -0.741323169999873],
                [29.629683000000171, -0.741659],
                [29.629824, -0.741865999999845],
                [29.629931000000113, -0.74188],
                [29.630037000000129, -0.741894],
                [29.630098584000109, -0.741975393999837],
                [29.630180000000109, -0.742083],
                [29.630169, -0.742213],
                [29.630156, -0.742371998999943],
                [29.630231440000102, -0.742488403999914],
                [29.630342000000155, -0.74265899999989],
                [29.630329000000131, -0.742791999999895],
                [29.630318000000159, -0.742896],
                [29.63009000500017, -0.742976324999972],
                [29.630037307000066, -0.742995022999935],
                [29.629791354000076, -0.74317382299995],
                [29.629775968000047, -0.743185250999943],
                [29.629767608000066, -0.743251260999955],
                [29.629726475000155, -0.743565689999969],
                [29.629478, -0.743841],
                [29.629467, -0.743853000999934],
                [29.629459998000186, -0.743864669999937],
                [29.629299164000031, -0.744132995999905],
                [29.629331833000151, -0.744165541999962],
                [29.629412000000116, -0.744244999999864],
                [29.629489810000166, -0.744322809999971],
                [29.629681000000176, -0.744522],
                [29.629627000000141, -0.744639],
                [29.62956000000014, -0.744783999999868],
                [29.629453000000126, -0.744958999999881],
                [29.629372, -0.74509000099988],
                [29.62944621000014, -0.74527751699992],
                [29.629465102000097, -0.745325028999957],
                [29.62951211900014, -0.745356802999936],
                [29.629868, -0.745596998999929],
                [29.630017000000123, -0.745843],
                [29.630081, -0.745948999999882],
                [29.630079, -0.746527],
                [29.62989, -0.746906],
                [29.629772, -0.747141],
                [29.629574442000091, -0.747190734999947],
                [29.629486, -0.747213],
                [29.629251001000057, -0.747023000999945],
                [29.629050872000107, -0.74696314199997],
                [29.628919887000166, -0.746924067999942],
                [29.628754, -0.747023],
                [29.628774, -0.747188],
                [29.62879877, -0.747402564999902],
                [29.628746145000036, -0.747531872999957],
                [29.628729000000135, -0.747573999999872],
                [29.628647953000041, -0.747590789999947],
                [29.628338, -0.747655000999941],
                [29.628278993000094, -0.747772512999973],
                [29.628220000999988, -0.747889998999938],
                [29.628195001000051, -0.748179],
                [29.628208255000118, -0.74828587899998],
                [29.628241859000013, -0.748556872999927],
                [29.628157, -0.748633],
                [29.628077000000189, -0.748702999999864],
                [29.62788600000016, -0.748919],
                [29.627837849000173, -0.74907406299991],
                [29.627768001000106, -0.749299],
                [29.627953001000094, -0.749678],
                [29.628097, -0.749934],
                [29.628165694000018, -0.750056454999935],
                [29.628247030000182, -0.750409630999911],
                [29.628328000000124, -0.750762],
                [29.628323708000153, -0.750808511999878],
                [29.628273, -0.751357999999868],
                [29.628283000000181, -0.751397],
                [29.62838, -0.751792],
                [29.628298000000143, -0.751936],
                [29.628271368000071, -0.751982704999875],
                [29.628164291000189, -0.752170979999903],
                [29.62832641599999, -0.752550005999922],
                [29.628702164, -0.752605019999862],
                [29.628777271000104, -0.752718723999919],
                [29.629025, -0.753093],
                [29.628649, -0.753147],
                [29.628452, -0.753213],
                [29.628266007000093, -0.753274997999938],
                [29.628164291000189, -0.753309010999942],
                [29.627893448000066, -0.753959000999885],
                [29.627706105000129, -0.75428845499988],
                [29.627678001000106, -0.754337998999972],
                [29.627678001000106, -0.754934],
                [29.628109000000165, -0.754935],
                [29.628185, -0.754889],
                [29.628378004000183, -0.754773007999916],
                [29.628409635000082, -0.754828777999933],
                [29.628416213999969, -0.754840363999961],
                [29.628593, -0.755151998999906],
                [29.628641001, -0.755221000999939],
                [29.628805006000164, -0.755458007999948],
                [29.628969, -0.755694999999889],
                [29.629013000000157, -0.755893],
                [29.629075000000114, -0.756186],
                [29.629021190000117, -0.756400007999957],
                [29.628630000000101, -0.756617999999889],
                [29.628557477000015, -0.756658202999915],
                [29.628538131000028, -0.756668984999919],
                [29.628267289000178, -0.757157027999824],
                [29.628536224000129, -0.757373987999927],
                [29.628525051, -0.757453288999898],
                [29.628507, -0.757584],
                [29.628483000000188, -0.757753],
                [29.6284, -0.757898999999838],
                [29.628342988000156, -0.757998878999842],
                [29.628267289000178, -0.758131979999916],
                [29.628698091000103, -0.758348871999942],
                [29.628967000000102, -0.75797],
                [29.629236000000162, -0.758187],
                [29.628967000000102, -0.758403999999871],
                [29.628853, -0.758548],
                [29.628752, -0.758675],
                [29.628732000000184, -0.758792],
                [29.628722418000109, -0.758847361999869],
                [29.628696441000159, -0.75900000199988],
                [29.628737839000053, -0.759006896999949],
                [29.628878, -0.759029999999882],
                [29.629020922000109, -0.759053986999902],
                [29.629285585000048, -0.759000885999967],
                [29.62929, -0.759487999999863],
                [29.629017629000089, -0.759651301999952],
                [29.62882808300003, -0.759693119999952],
                [29.628534316000184, -0.759757995999905],
                [29.628265381, -0.760462998999969],
                [29.62828600500012, -0.760651447999919],
                [29.628300000000138, -0.760776999999848],
                [29.628319, -0.760951],
                [29.628515989000164, -0.76111679],
                [29.628641056000106, -0.761221943999885],
                [29.629072, -0.760789],
                [29.629356000000143, -0.761116999999899],
                [29.629447791000189, -0.761221761999877],
                [29.629494000000193, -0.761222],
                [29.629879, -0.761223],
                [29.629946, -0.761514],
                [29.630002000000104, -0.761762],
                [29.630023521, -0.761858552999968],
                [29.630039214000135, -0.76192802199995],
                [29.630077534000122, -0.762014601999908],
                [29.630136000000164, -0.762146],
                [29.630255, -0.762415],
                [29.630139059000157, -0.762531478999961],
                [29.630039000000124, -0.762632],
                [29.629847, -0.762794],
                [29.629717000000142, -0.762902999999881],
                [29.629492, -0.763218],
                [29.629446029000178, -0.76328199999989],
                [29.629452, -0.763317999999856],
                [29.62951500000014, -0.763786],
                [29.629528000000164, -0.763886],
                [29.629532293000068, -0.763977691999969],
                [29.629552997000189, -0.764419932999886],
                [29.629553000000101, -0.764691],
                [29.629929000000118, -0.765125],
                [29.629892, -0.76535],
                [29.629874784000037, -0.765450215999977],
                [29.629799199000104, -0.765526152999939],
                [29.62966, -0.765666],
                [29.629474727000058, -0.76580570699997],
                [29.629229, -0.765991000999975],
                [29.629391302000045, -0.766195574999927],
                [29.629443934000108, -0.766261917999884],
                [29.629873, -0.766479999999888],
                [29.629173, -0.767247],
                [29.629307000000153, -0.767403999999885],
                [29.629495936000126, -0.767625924999948],
                [29.629819869000016, -0.767562984999813],
                [29.629867399000091, -0.767523171999869],
                [29.629964000000143, -0.767442],
                [29.630142000000149, -0.767293],
                [29.630226000000164, -0.76746],
                [29.630251000000101, -0.76751],
                [29.63025800000014, -0.767561999999884],
                [29.630290848000186, -0.767806117999953],
                [29.630302, -0.767889],
                [29.630033, -0.768115],
                [29.630033, -0.768656],
                [29.630302, -0.768873],
                [29.630247000000111, -0.769415],
                [29.630128, -0.769676],
                [29.629925000000128, -0.77012],
                [29.629762807000134, -0.770499042999916],
                [29.629419, -0.770575999999892],
                [29.629289655000036, -0.770604437999907],
                [29.629278183000167, -0.77060699499998],
                [29.629255390000139, -0.770641366999882],
                [29.629203, -0.770721],
                [29.629063, -0.770932],
                [29.628914475999977, -0.771080523999956],
                [29.628847123000185, -0.771148025999878],
                [29.628416061000053, -0.771310983999967],
                [29.628416061000053, -0.771636007999916],
                [29.628446000000167, -0.771646],
                [29.628895603000103, -0.771797184999855],
                [29.628954019000162, -0.771853012999941],
                [29.629061, -0.771962],
                [29.628954, -0.772394999999847],
                [29.628929131000064, -0.7725063339999],
                [29.628845216000116, -0.772882997999886],
                [29.629007339000111, -0.773154018999946],
                [29.629274368000097, -0.773316978999901],
                [29.629114151000067, -0.7738040079999],
                [29.628965379000022, -0.773858010999902],
                [29.628843308000171, -0.773913024999956],
                [29.62889927599997, -0.773978437999858],
                [29.628972000000147, -0.774062999999899],
                [29.629168, -0.774292],
                [29.629759001000139, -0.774528],
                [29.629889000000162, -0.774555],
                [29.630226000000164, -0.774633],
                [29.630212182000093, -0.774669848999963],
                [29.630205154, -0.774688899999944],
                [29.630255389000183, -0.774897859999896],
                [29.630273926000029, -0.774974691999944],
                [29.630286973000125, -0.774995269999977],
                [29.630338, -0.775075],
                [29.630475987000125, -0.775293184999839],
                [29.63047049100004, -0.77537273899992],
                [29.630457788000172, -0.7755561799999],
                [29.630456969000193, -0.775568006999947],
                [29.630219417000035, -0.775621472999887],
                [29.630106000000183, -0.775646999999879],
                [29.629675, -0.775687999999889],
                [29.629468982000049, -0.775707501999932],
                [29.629263, -0.77572700099995],
                [29.628915555000162, -0.77600549899995],
                [29.628879954000183, -0.77603408899995],
                [29.628738000000169, -0.77631],
                [29.628658, -0.776464],
                [29.628628155, -0.77652189399987],
                [29.628525001000185, -0.776722000999939],
                [29.628472277000071, -0.776921429999902],
                [29.628456115000176, -0.77698296199992],
                [29.628470205000099, -0.776998496999965],
                [29.628522, -0.777055],
                [29.628756, -0.777312999999879],
                [29.628747, -0.777505],
                [29.628738000000169, -0.777694999999881],
                [29.628689045999977, -0.777712426999813],
                [29.628429000000153, -0.77780499999983],
                [29.628238000000181, -0.77777],
                [29.628121279000027, -0.777748431999953],
                [29.627962112000091, -0.777719257999934],
                [29.627969962000179, -0.777757832999839],
                [29.628011, -0.777958],
                [29.628342918000158, -0.779588683999975],
                [29.628439, -0.780058999999881],
                [29.628174, -0.780244],
                [29.62776, -0.780305999999882],
                [29.62752, -0.780415],
                [29.627451, -0.780966],
                [29.627403774000072, -0.781019772999969],
                [29.627350001000138, -0.781081],
                [29.627312390000156, -0.781123470999944],
                [29.627172470000119, -0.781282007999948],
                [29.627124045000073, -0.781625449999922],
                [29.627103945000044, -0.781769668999971],
                [29.62709400000017, -0.78189],
                [29.627068999000187, -0.782185000999959],
                [29.626939419999985, -0.7822873],
                [29.626898001000029, -0.78232],
                [29.626958146000163, -0.782846948999918],
                [29.626965000000155, -0.782907],
                [29.627163209000116, -0.783093691999966],
                [29.627378356000065, -0.78329588899993],
                [29.627567, -0.783237],
                [29.627723693000178, -0.783187983999881],
                [29.627803608000079, -0.78304596199996],
                [29.6279139990001, -0.782849000999818],
                [29.627998, -0.7827],
                [29.628344, -0.7827],
                [29.628653, -0.782980999999893],
                [29.628697000000102, -0.783359],
                [29.628721000000155, -0.783567999999889],
                [29.628733378000106, -0.783801046999884],
                [29.628755942000055, -0.784225928999945],
                [29.628756, -0.784434],
                [29.628756, -0.785031],
                [29.628632, -0.785206],
                [29.628582001000098, -0.785274999999899],
                [29.628563, -0.785317],
                [29.628543688000036, -0.785359674999881],
                [29.628507, -0.785438999999883],
                [29.628408, -0.785654],
                [29.62816800700017, -0.785861993999958],
                [29.627960048000091, -0.785726030999911],
                [29.627754, -0.785753],
                [29.627647000000138, -0.785725],
                [29.627438430999973, -0.785669381999924],
                [29.627376557000048, -0.785652994999907],
                [29.627065679000054, -0.785517999999854],
                [29.626757, -0.785516999999857],
                [29.62644568799999, -0.78565377599989],
                [29.626399, -0.78577],
                [29.626384329000189, -0.785806010999977],
                [29.626377105000131, -0.785824000999924],
                [29.626514434, -0.786032020999926],
                [29.627084148000108, -0.786436746999925],
                [29.627099968000152, -0.786447977999956],
                [29.6271344970001, -0.78656998799994],
                [29.627168499000049, -0.786693299999911],
                [29.62703, -0.787053],
                [29.626995000000193, -0.787143],
                [29.62692600000014, -0.787496],
                [29.626814145000026, -0.787544710999953],
                [29.626802000000168, -0.78755],
                [29.626616, -0.787631],
                [29.626444, -0.787631],
                [29.626299000000131, -0.787669],
                [29.626186708999967, -0.787697923999929],
                [29.626167298000098, -0.787702976999981],
                [29.626145245000089, -0.787790018999885],
                [29.626137, -0.787823],
                [29.626099000000124, -0.787973999999849],
                [29.626168494000183, -0.78818148199997],
                [29.626238, -0.788388999999881],
                [29.626444, -0.788913],
                [29.626236, -0.789157],
                [29.625994000000105, -0.789157],
                [29.625865, -0.789014999999893],
                [29.625650188000122, -0.788777207999942],
                [29.625513000000126, -0.788912],
                [29.625202121000086, -0.788668094999878],
                [29.625160218000076, -0.788752018999901],
                [29.625099183000088, -0.788875996999934],
                [29.625099183000088, -0.78926402299993],
                [29.625134504000073, -0.789296250999826],
                [29.62535000000014, -0.789492],
                [29.625495833, -0.789625846999911],
                [29.625151000000187, -0.789797],
                [29.62505792200011, -0.78984909299993],
                [29.624908446000177, -0.789933025999915],
                [29.624957092000102, -0.790019444999871],
                [29.625000000000114, -0.790094999999837],
                [29.625046, -0.790177],
                [29.625141, -0.790213999999821],
                [29.625240276000113, -0.790252941999938],
                [29.625322274000155, -0.790284963999909],
                [29.625494349000064, -0.790262599999949],
                [29.625598801000024, -0.790249025999969],
                [29.62560617600019, -0.790269289999969],
                [29.625628144000075, -0.790331035999827],
                [29.625734330000114, -0.790629027999955],
                [29.625734330000114, -0.791043996999917],
                [29.625698091000061, -0.791360019999956],
                [29.625940322000133, -0.79160398199997],
                [29.626006357000165, -0.791621536999912],
                [29.62607964700004, -0.79164090699993],
                [29.62616669300013, -0.791552749999937],
                [29.626246858000059, -0.791471144999832],
                [29.626301605000094, -0.791415646999951],
                [29.626319992000106, -0.791397008999979],
                [29.626385128000152, -0.791443675999972],
                [29.626560001000144, -0.791569],
                [29.626538374000063, -0.791712434999965],
                [29.626492, -0.79202],
                [29.62673198400006, -0.792327979999925],
                [29.626746487000105, -0.792325332999894],
                [29.626933000000179, -0.792291],
                [29.627077, -0.792264999999873],
                [29.627317000000176, -0.791984999999897],
                [29.62750696500018, -0.791870801999949],
                [29.627662051000186, -0.791777571999944],
                [29.627813000000174, -0.791807999999889],
                [29.631151000000159, -0.796086],
                [29.631138000000135, -0.796116],
                [29.631242, -0.796264999999892],
                [29.631378, -0.79646],
                [29.631308000000161, -0.79666],
                [29.631241000000102, -0.796847999999898],
                [29.631035000000111, -0.797091],
                [29.631085315000121, -0.797192000999871],
                [29.631170272000134, -0.797362029999817],
                [29.631221548000099, -0.797398414999975],
                [29.631279, -0.797439],
                [29.631516000000147, -0.797607],
                [29.631653, -0.798093999999878],
                [29.631711000000109, -0.798123],
                [29.631868000000111, -0.798201],
                [29.631994, -0.798422],
                [29.632064764000063, -0.798546583999951],
                [29.632008000000155, -0.798655],
                [29.631871, -0.798913],
                [29.631787288, -0.799070505999964],
                [29.631501000000128, -0.799274],
                [29.631203, -0.799485],
                [29.631079528999976, -0.799733739999851],
                [29.630997001000082, -0.7999],
                [29.631100000000174, -0.800387998999895],
                [29.631003000000135, -0.800526],
                [29.630912545000058, -0.800653008999973],
                [29.630857467999988, -0.800731002999953],
                [29.630828745000031, -0.80084814199995],
                [29.630809, -0.80093],
                [29.630789001000096, -0.801011],
                [29.63075300100013, -0.801499],
                [29.630718497000146, -0.801720018999958],
                [29.630684, -0.801941],
                [29.63033905899999, -0.802293182999961],
                [29.630012132000047, -0.802324155999941],
                [29.629961013000184, -0.802329004999933],
                [29.629547119000051, -0.802465020999875],
                [29.629526666, -0.802488760999893],
                [29.629458, -0.802569],
                [29.629307001000086, -0.802743998999972],
                [29.629289896000159, -0.802924067999925],
                [29.629271000000188, -0.803123],
                [29.629407976000152, -0.803403949999961],
                [29.62951693600013, -0.80337558299982],
                [29.629664301000162, -0.803337189999922],
                [29.629684, -0.803332],
                [29.629856004000089, -0.80333197099992],
                [29.62989, -0.803124],
                [29.629970863000096, -0.803003859999876],
                [29.630029566000076, -0.802916646999961],
                [29.630236, -0.803025],
                [29.630372228000169, -0.803263151999943],
                [29.630508479000184, -0.803501343999926],
                [29.630716, -0.803892],
                [29.630630375000123, -0.804030995999938],
                [29.630493, -0.804254000999947],
                [29.630476200000089, -0.804441277999956],
                [29.630459, -0.804632999999853],
                [29.630562, -0.804949],
                [29.630657, -0.805026],
                [29.63083221200003, -0.80516952499994],
                [29.630939484000066, -0.805257021999978],
                [29.631156415000135, -0.805432941999868],
                [29.631285, -0.805537],
                [29.631375, -0.805927999999881],
                [29.631390559000124, -0.805995768999878],
                [29.631420136000031, -0.80612397099992],
                [29.631660462000184, -0.806684016999895],
                [29.631834031000096, -0.806783973999927],
                [29.632350971000051, -0.806747991999941],
                [29.632832000000121, -0.806956],
                [29.632907365000051, -0.807161605999909],
                [29.632935000000145, -0.80723699899994],
                [29.633228, -0.807641],
                [29.633314, -0.80776],
                [29.633726, -0.808068],
                [29.633809000000156, -0.808184],
                [29.634003, -0.808456],
                [29.634003, -0.8087],
                [29.633907, -0.808783],
                [29.633759381000061, -0.808908412999926],
                [29.633610027000088, -0.809008142999915],
                [29.63345, -0.809115000999952],
                [29.633381000999975, -0.809323000999939],
                [29.633587, -0.809594999999888],
                [29.633741, -0.809687],
                [29.633931524000161, -0.809801712999956],
                [29.633924403000037, -0.809855730999971],
                [29.633863001000066, -0.810290000999942],
                [29.633762441000101, -0.810391042999925],
                [29.633656, -0.810498000999928],
                [29.633632684000133, -0.810577681999916],
                [29.63355300000012, -0.81085],
                [29.633599000000174, -0.811056999999892],
                [29.633607896, -0.811097435999955],
                [29.633621217000155, -0.81115698699989],
                [29.633644466000192, -0.811164369999972],
                [29.633742, -0.811195],
                [29.633965000000103, -0.811266],
                [29.634550000000161, -0.811402],
                [29.634630949000041, -0.811465516999931],
                [29.635032289000037, -0.811780442999975],
                [29.635121, -0.812031],
                [29.635203927000191, -0.812268790999951],
                [29.635213932000056, -0.812336000999949],
                [29.635271000000159, -0.812720999999897],
                [29.635169088000055, -0.812945561999925],
                [29.635099000000139, -0.8131],
                [29.63499400000012, -0.813211],
                [29.634928000000173, -0.813281],
                [29.63499398200014, -0.813385566999898],
                [29.635099411000056, -0.813552021999897],
                [29.63540819900004, -0.813587999999868],
                [29.635754000000134, -0.813587999999868],
                [29.635786318000157, -0.813590312999963],
                [29.636271, -0.813624999999831],
                [29.636496, -0.813687],
                [29.636534, -0.813698],
                [29.636612, -0.813719999999876],
                [29.636858000000188, -0.814245],
                [29.637086586000066, -0.814735111999937],
                [29.637231292000138, -0.815037081999947],
                [29.638126373000034, -0.816903769999954],
                [29.63841175500005, -0.816846009999949],
                [29.638534375000063, -0.816821126999969],
                [29.638792000000137, -0.816878999999858],
                [29.638869942000042, -0.816896986999836],
                [29.63896946400007, -0.816974555999934],
                [29.639097000000163, -0.817074],
                [29.639222412000038, -0.817194602999962],
                [29.639409961000013, -0.81737496199986],
                [29.639336, -0.817626999999845],
                [29.639296000000115, -0.817652],
                [29.639023, -0.817819],
                [29.638313422000181, -0.817623116999869],
                [29.638746262000097, -0.818565011999908],
                [29.63896942000008, -0.818603990999918],
                [29.639016026000036, -0.818699898999967],
                [29.639070631000038, -0.818811249999953],
                [29.63913, -0.818933],
                [29.639140981000139, -0.818956958999934],
                [29.639149318000023, -0.819094699999937],
                [29.639167983000107, -0.81940371099995],
                [29.639245745000096, -0.819333231999963],
                [29.639549000000102, -0.819332000999964],
                [29.639528001000031, -0.819756000999973],
                [29.639599503000056, -0.819978508999895],
                [29.639661645000047, -0.820203783999887],
                [29.639381828000126, -0.820287089999852],
                [29.639147, -0.820357000999934],
                [29.639105001000189, -0.820691000999943],
                [29.638795999000024, -0.820934000999898],
                [29.638753898000175, -0.820965440999942],
                [29.638563156000032, -0.821108399999957],
                [29.638555156999985, -0.821229124999888],
                [29.63855400000017, -0.821247],
                [29.638548018000051, -0.821338722999883],
                [29.638853072000074, -0.821363925999947],
                [29.639167785000041, -0.821199953999951],
                [29.639402390000043, -0.821151791999966],
                [29.639690399000131, -0.821256933999962],
                [29.639796292000085, -0.821315751999975],
                [29.639926681000134, -0.821387824999874],
                [29.640025397000159, -0.821523350999883],
                [29.640148163000163, -0.821691691999888],
                [29.640388488999974, -0.821801841999957],
                [29.64060466, -0.821925803999875],
                [29.640682, -0.82197],
                [29.64077, -0.822309],
                [29.640814, -0.822481],
                [29.640771071000188, -0.822747903999868],
                [29.640768052000055, -0.822766780999928],
                [29.640705109000066, -0.823153315999946],
                [29.640600204000179, -0.823407469999836],
                [29.640630008000187, -0.823537779999867],
                [29.640648193000175, -0.823617162999938],
                [29.640667, -0.823699],
                [29.640509000000122, -0.823884],
                [29.64044, -0.823963999999876],
                [29.64012, -0.823956999999893],
                [29.640017522000107, -0.823954326999967],
                [29.64000511100005, -0.823954045999926],
                [29.639969235000137, -0.824067882999941],
                [29.63991178200007, -0.824250506999931],
                [29.639853000000187, -0.824438],
                [29.639766476000148, -0.824428891999958],
                [29.639473001000113, -0.824398000999906],
                [29.639462701000127, -0.824405465999973],
                [29.639244057000155, -0.824563958999931],
                [29.63921737700008, -0.824870936999957],
                [29.639622, -0.824808],
                [29.639619469000138, -0.824877167999944],
                [29.639616194000155, -0.824971713999958],
                [29.639612198000179, -0.825089929999933],
                [29.639524459000086, -0.825403390999952],
                [29.639646437000067, -0.825643699999944],
                [29.639700000000119, -0.825749],
                [29.639648008000108, -0.825886001999834],
                [29.639563001000056, -0.826109998999868],
                [29.639528890000179, -0.826211004999891],
                [29.639460000000156, -0.826414999999884],
                [29.639523, -0.827015],
                [29.639357000000189, -0.82732],
                [29.639349, -0.827436],
                [29.639345, -0.827492999999834],
                [29.639205, -0.8276],
                [29.639162432000091, -0.827629742999932],
                [29.638822735000133, -0.82773600299987],
                [29.638513962000047, -0.827736018999872],
                [29.6381, -0.827943],
                [29.637480001000029, -0.82811400099996],
                [29.637478001000034, -0.828285998999888],
                [29.637537749000046, -0.828294925999842],
                [29.63796043300016, -0.828357993999816],
                [29.638144001000114, -0.828377676999935],
                [29.638306, -0.828395],
                [29.638512, -0.828702],
                [29.638291085000162, -0.829004262999888],
                [29.638235001000169, -0.829081],
                [29.638170209000123, -0.829135019999967],
                [29.63785600000017, -0.829397],
                [29.637910267000109, -0.829594764999911],
                [29.63796043300016, -0.829775988999813],
                [29.637924194000107, -0.830165027999954],
                [29.63802719099999, -0.830543994999857],
                [29.638130188000105, -0.83099597599994],
                [29.63819031200012, -0.83102892699992],
                [29.638315, -0.831097],
                [29.63851000000011, -0.831203999999843],
                [29.638819, -0.831475],
                [29.638716000000102, -0.831863999999825],
                [29.638457550000169, -0.831998973999873],
                [29.6381990000001, -0.832134],
                [29.637750842000173, -0.83237808399997],
                [29.63772351300014, -0.832392551999931],
                [29.637683, -0.832413999999858],
                [29.63763200000011, -0.832441],
                [29.637198999000077, -0.832341],
                [29.636953, -0.831970000999888],
                [29.636644, -0.831817000999934],
                [29.636591864000025, -0.831820493999942],
                [29.636449814000173, -0.831830023999942],
                [29.636425110000062, -0.831831696999927],
                [29.636406, -0.831833],
                [29.636244, -0.831844],
                [29.636056, -0.831975],
                [29.635942742000111, -0.832053695999889],
                [29.63584327600006, -0.832122980999941],
                [29.635853179000094, -0.832316404999972],
                [29.635861, -0.832465],
                [29.63587385000011, -0.832719542999826],
                [29.635839304000115, -0.832845972999962],
                [29.635780000000182, -0.833063],
                [29.635612, -0.833272],
                [29.635504, -0.833405],
                [29.634947000000182, -0.833955999999887],
                [29.634329, -0.834424999999897],
                [29.633897946000047, -0.835048162999954],
                [29.633816, -0.835294],
                [29.633735379000029, -0.835533490999865],
                [29.633680345000073, -0.835698006999962],
                [29.633702101000097, -0.836161399999924],
                [29.633711001000108, -0.836348],
                [29.633734000000175, -0.836465],
                [29.633801767000193, -0.836816790999819],
                [29.633718000000101, -0.837325999999848],
                [29.633709, -0.837377999999887],
                [29.633215, -0.838064],
                [29.632969000000116, -0.838407],
                [29.632866148000119, -0.838496969999937],
                [29.632442474000072, -0.838868020999882],
                [29.63201141400009, -0.839617012999952],
                [29.632067888000165, -0.839825813999937],
                [29.632096000000161, -0.839929],
                [29.632195, -0.840294],
                [29.632255241000109, -0.84049333199988],
                [29.6323175550001, -0.840699531999974],
                [29.632040000000131, -0.841134],
                [29.631636777000097, -0.841411696999955],
                [29.631359001000192, -0.841603],
                [29.631315548000089, -0.841634461999888],
                [29.631021638999982, -0.841847182999913],
                [29.630835441000045, -0.841942038999946],
                [29.630651473000114, -0.842036008999912],
                [29.63055510800001, -0.842357184999912],
                [29.630465, -0.842658999999856],
                [29.630276939, -0.842943688999981],
                [29.630013365000082, -0.843347526999878],
                [29.629938125000081, -0.843463002999954],
                [29.629568100000085, -0.844021975999908],
                [29.629259110000078, -0.844618021999963],
                [29.629259110000078, -0.845296024999925],
                [29.629444122000109, -0.845793007999816],
                [29.629135132000158, -0.846695005999948],
                [29.628824235000081, -0.847409008999932],
                [29.628796025000042, -0.847517833999973],
                [29.628751000000136, -0.847692999999879],
                [29.62867, -0.848005],
                [29.62819100400003, -0.84910599199992],
                [29.627907680000192, -0.849590681999871],
                [29.62779, -0.849792],
                [29.627357, -0.850379],
                [29.627147149000052, -0.850651026999969],
                [29.626925001000131, -0.850939000999972],
                [29.626770024000052, -0.851559528999815],
                [29.626676580000094, -0.851933545999941],
                [29.626445297000089, -0.852787388999957],
                [29.626398086000165, -0.852962015999935],
                [29.626274108000189, -0.853421985999887],
                [29.626026154000101, -0.854389011999956],
                [29.625963211000169, -0.855102002999899],
                [29.625896996999984, -0.855539158999932],
                [29.625809001000164, -0.856121998999924],
                [29.625700951000113, -0.856538338999826],
                [29.625591278, -0.856962024999973],
                [29.625534180000102, -0.857342630999938],
                [29.625498000000107, -0.857585],
                [29.625374000000136, -0.858552],
                [29.625380000000121, -0.858687],
                [29.625393183000085, -0.859019872999966],
                [29.625404357000093, -0.859292982999932],
                [29.625581114000056, -0.859812828999964],
                [29.625727, -0.860241],
                [29.625788000000114, -0.861118],
                [29.626057493000133, -0.861354577999975],
                [29.626311999000052, -0.861577998999962],
                [29.626497, -0.862074999999891],
                [29.626443000000165, -0.862364],
                [29.626404, -0.862571999999886],
                [29.626229307000131, -0.862813611999968],
                [29.626156, -0.862915000999919],
                [29.626085144, -0.863048181999943],
                [29.625940322000133, -0.863321005999978],
                [29.626125336000143, -0.86381798899987],
                [29.626493453000137, -0.864405989999852],
                [29.626802445000123, -0.865001976999963],
                [29.626861482000152, -0.865299412999946],
                [29.626925001000131, -0.86561699899994],
                [29.626925001000131, -0.866555998999843],
                [29.626923000999966, -0.86736],
                [29.626923000999966, -0.868136],
                [29.626830504000054, -0.868321491999893],
                [29.626736790000109, -0.86850865699995],
                [29.626503229000093, -0.868828950999955],
                [29.626396179000096, -0.86897599699995],
                [29.626148224000076, -0.869535981999945],
                [29.626211167000065, -0.869906008999976],
                [29.626386452000077, -0.870166898999912],
                [29.626672819000078, -0.870592731999977],
                [29.626795000000129, -0.870937],
                [29.626839251000149, -0.872056747999977],
                [29.626841, -0.872100998999883],
                [29.626909000000182, -0.872558],
                [29.626932000000124, -0.872716],
                [29.627363, -0.873683],
                [29.627731000000153, -0.87483],
                [29.627980669000124, -0.87531179399997],
                [29.628101000000186, -0.875544],
                [29.628263000000118, -0.875782],
                [29.628656, -0.876356999999871],
                [29.628675000000101, -0.876394999999889],
                [29.628881013000182, -0.876796524999975],
                [29.629087, -0.877198],
                [29.628963000000113, -0.878002],
                [29.628973437000184, -0.878104403999942],
                [29.628979001000118, -0.878159],
                [29.629030000000171, -0.878688999999895],
                [29.629045932000054, -0.87885732899997],
                [29.62905311600008, -0.878931997999928],
                [29.629142434000016, -0.879016330999889],
                [29.629456001000108, -0.879311998999924],
                [29.629455002000157, -0.87961851599988],
                [29.629454, -0.879926],
                [29.629158, -0.880324],
                [29.628989025000067, -0.880549799999926],
                [29.628699306000158, -0.880788045999964],
                [29.628157, -0.881233999999893],
                [29.628152682, -0.881245730999979],
                [29.62799793500011, -0.88166627399994],
                [29.627941131, -0.881820976999961],
                [29.627632141000049, -0.882010995999963],
                [29.627603186000044, -0.882117271999959],
                [29.627539, -0.882353998999918],
                [29.627637428000014, -0.882509949999928],
                [29.62769317700014, -0.882597982999869],
                [29.627722636000158, -0.882604339999943],
                [29.62789400000014, -0.882641],
                [29.628157, -0.882698],
                [29.628649, -0.882815999999877],
                [29.628808132000074, -0.882917861999942],
                [29.628988, -0.883033],
                [29.629341000000124, -0.883890999999892],
                [29.629401, -0.884038],
                [29.629534, -0.884366],
                [29.629762168000127, -0.884926334999932],
                [29.629894295000156, -0.885250812999971],
                [29.630039000000124, -0.885703],
                [29.630169, -0.886113],
                [29.630182220999984, -0.88615538199997],
                [29.63020273300009, -0.88622114799989],
                [29.630315, -0.886637999999891],
                [29.630419000000131, -0.887027],
                [29.630764000000113, -0.887463],
                [29.630835049000041, -0.887552512999889],
                [29.630941391000135, -0.88768601299995],
                [29.63101573900002, -0.887710322999908],
                [29.631279, -0.887796],
                [29.631489, -0.887865],
                [29.631491224000058, -0.888124015999949],
                [29.631496, -0.888679999999852],
                [29.631496, -0.889061],
                [29.631495, -0.889484],
                [29.631619000000114, -0.890234],
                [29.631827000000101, -0.890398999999888],
                [29.631917358000123, -0.89047116099988],
                [29.631971360000136, -0.890514015999884],
                [29.63232612600018, -0.89079499199994],
                [29.632368088000135, -0.89091163799992],
                [29.632583618000183, -0.890687823999883],
                [29.63289476500006, -0.890318177999859],
                [29.633299, -0.890301],
                [29.633627, -0.890452],
                [29.633686, -0.890479],
                [29.633989679000081, -0.890619388999824],
                [29.634287, -0.890756],
                [29.634304, -0.890763999999876],
                [29.634682, -0.890937999999892],
                [29.634850000000142, -0.891211],
                [29.63495300000011, -0.89138],
                [29.635022898000045, -0.8914958299999],
                [29.63528338899999, -0.89192165399993],
                [29.635294000000101, -0.891939],
                [29.635148916000162, -0.892239169999925],
                [29.635115312000039, -0.892308189999937],
                [29.635019303000036, -0.892506121999872],
                [29.635003043000097, -0.892937016999895],
                [29.63499908600005, -0.892953760999944],
                [29.634897232000128, -0.893384873999935],
                [29.634630204000075, -0.893587231999959],
                [29.634382248000122, -0.893695354999977],
                [29.63399314899999, -0.893911241999945],
                [29.633834839000087, -0.894243418999906],
                [29.63348960799999, -0.89426934699992],
                [29.633205415000191, -0.894453882999926],
                [29.633087157000034, -0.894702017999919],
                [29.632877350000172, -0.895140647999881],
                [29.632694062999974, -0.895525168999939],
                [29.632677000000115, -0.895561],
                [29.632437000000152, -0.895888],
                [29.632404, -0.895933999999897],
                [29.632181000000116, -0.896244],
                [29.63199, -0.896643],
                [29.631707000000119, -0.896886999999879],
                [29.631634000000133, -0.89695],
                [29.63127500700017, -0.897138995999967],
                [29.631124, -0.897453],
                [29.631106051000074, -0.897469153999907],
                [29.631053947000169, -0.897515999999882],
                [29.629700383999989, -0.897513058999891],
                [29.629213333000109, -0.897512017999929],
                [29.629172294, -0.897511953999924],
                [29.628332000000114, -0.897510999999895],
                [29.627918000000136, -0.897509999999897],
                [29.62443, -0.897497],
                [29.615505, -0.897463],
                [29.609419, -0.897421],
                [29.606756000000132, -0.8974],
                [29.600538001000189, -0.897395],
                [29.600493356000129, -0.897394748999886],
                [29.592705, -0.897351],
                [29.591864, -0.89735],
                [29.590995654000153, -0.897331399999871],
                [29.590977, -0.897331],
                [29.590212000000179, -0.897331],
                [29.589885, -0.897349],
                [29.589397, -0.897375],
                [29.58840899900008, -0.897329],
                [29.587845348000087, -0.89732841499989],
                [29.587446213000192, -0.897328019999975],
                [29.587380990000042, -0.897354568999958],
                [29.587216, -0.897421999999835],
                [29.586779983000156, -0.897599599999978],
                [29.586332197000047, -0.897864143999925],
                [29.586065293000161, -0.898021995999898],
                [29.585323334000122, -0.898617982999951],
                [29.584558488000084, -0.899213014999873],
                [29.584160423000128, -0.89949229299998],
                [29.583954680000033, -0.899636733999955],
                [29.583915805000117, -0.89966382699987],
                [29.583413826000083, -0.89922019699992],
                [29.583374005000053, -0.899185004999936],
                [29.582485198000143, -0.899365006999915],
                [29.581745148000039, -0.899986981999973],
                [29.581542060000118, -0.900377779999872],
                [29.581409000000122, -0.900633999999855],
                [29.581398, -0.900655],
                [29.581180885000151, -0.901025198999889],
                [29.581077000000164, -0.901206],
                [29.581028805000187, -0.901289245999976],
                [29.580978393000066, -0.901377020999973],
                [29.580854684000087, -0.901767403999941],
                [29.580767, -0.902044999999873],
                [29.580681, -0.902316],
                [29.580694000000108, -0.902501],
                [29.580712439000138, -0.902776555999935],
                [29.580730438999979, -0.90303897799987],
                [29.580791382000143, -0.903920208999978],
                [29.580803, -0.904086999999834],
                [29.580850990000101, -0.904855107999879],
                [29.580802428000027, -0.905369214999951],
                [29.580776216000174, -0.905649005999919],
                [29.580738067000084, -0.906678974999977],
                [29.58074828600013, -0.906903582999973],
                [29.580786001000149, -0.907727000999898],
                [29.580798129000073, -0.908134548999953],
                [29.580811, -0.908567],
                [29.580822214000023, -0.908962593999831],
                [29.580832831000123, -0.909343820999936],
                [29.580700197000169, -0.909987827999942],
                [29.580660000000137, -0.910183],
                [29.580462, -0.910959999999875],
                [29.580288, -0.911782],
                [29.58050400000019, -0.912434],
                [29.580566013000066, -0.912621262999949],
                [29.580656052000165, -0.912892997999961],
                [29.580699417000119, -0.913003009999898],
                [29.580952000000138, -0.913643],
                [29.581032250000192, -0.914083668999979],
                [29.581123, -0.914581999999882],
                [29.581166, -0.915335],
                [29.581173, -0.915457999999887],
                [29.581205121000039, -0.915565359999903],
                [29.581430436000119, -0.916316984999924],
                [29.581848144000048, -0.917183994999903],
                [29.581959052000116, -0.917350952999925],
                [29.582293001000153, -0.917852998999933],
                [29.582951962000038, -0.918405952999933],
                [29.58298301700006, -0.918431996999971],
                [29.58394432100016, -0.918748973999868],
                [29.584696343000076, -0.918814700999917],
                [29.584882950000122, -0.91883099599994],
                [29.584925836000139, -0.918835075999823],
                [29.585377000000165, -0.918877999999893],
                [29.585648, -0.918904],
                [29.586030763000167, -0.919003710999903],
                [29.586481, -0.919121],
                [29.586697000000129, -0.919201999999871],
                [29.587345736000145, -0.919445773999882],
                [29.587669932000153, -0.919723371999964],
                [29.587990000000161, -0.919998999999848],
                [29.588509000000101, -0.920568],
                [29.588820000000112, -0.921040999999889],
                [29.589046884000027, -0.92138625799987],
                [29.589325117000044, -0.921739927999909],
                [29.589542000000165, -0.922015],
                [29.58976, -0.922517],
                [29.589862807000145, -0.922755552999945],
                [29.589940463000175, -0.923098654999876],
                [29.590059281000038, -0.923623025999916],
                [29.590218347000075, -0.924057853999955],
                [29.59038, -0.924498999999855],
                [29.590489000000161, -0.924721],
                [29.590673, -0.925096],
                [29.59103000000016, -0.926153],
                [29.591078285000151, -0.926393593999933],
                [29.591204000000175, -0.92701999899981],
                [29.591326000000151, -0.927913999999873],
                [29.59157340400003, -0.928487427999812],
                [29.591669001000184, -0.928708998999923],
                [29.59203900000017, -0.929531999999824],
                [29.592036105000034, -0.930356070999949],
                [29.591891, -0.930692],
                [29.591717000000187, -0.931094],
                [29.591096865000111, -0.931873031999885],
                [29.590690000000109, -0.932450999999844],
                [29.590578, -0.93261],
                [29.589344, -0.933856],
                [29.588548028000162, -0.934582597999906],
                [29.588471999000149, -0.934652000999961],
                [29.588205168, -0.934896257999924],
                [29.588011000000108, -0.935074000999975],
                [29.587740894000149, -0.935311817999946],
                [29.587233, -0.935759],
                [29.586739, -0.936472],
                [29.586363470000038, -0.936986351999849],
                [29.586317063000024, -0.937049983999941],
                [29.585798264000175, -0.937942980999935],
                [29.585747487000162, -0.938621513999976],
                [29.585724, -0.938937],
                [29.585747, -0.939658999999892],
                [29.585954000000129, -0.940335999999888],
                [29.585965089000069, -0.940372436999951],
                [29.585968017000084, -0.940382002999968],
                [29.586189270000034, -0.94125002699991],
                [29.586708069000053, -0.941801010999939],
                [29.586760961000152, -0.941857538999898],
                [29.587004, -0.942116999999882],
                [29.587234000000137, -0.942308],
                [29.587469396000074, -0.942504253999971],
                [29.587667466000141, -0.942668973999901],
                [29.588161991999982, -0.942755993999924],
                [29.588235974999975, -0.942768994999938],
                [29.588606000000141, -0.942769],
                [29.58868, -0.942769],
                [29.589078707999988, -0.942937120999886],
                [29.589197000000183, -0.942987],
                [29.588999488000184, -0.943226014999823],
                [29.588802, -0.943465],
                [29.588603562000117, -0.944259551999892],
                [29.588554000000101, -0.944458000999873],
                [29.588542468000071, -0.944983219999926],
                [29.588530000000105, -0.945551],
                [29.588652990000128, -0.945970963999912],
                [29.588776000000166, -0.946391],
                [29.588818005000178, -0.946861055999932],
                [29.58886, -0.947331],
                [29.588975131000041, -0.947914739999874],
                [29.589031220000152, -0.948198019999893],
                [29.589220047000083, -0.949137985999926],
                [29.589228924000167, -0.949162779999938],
                [29.58928883100009, -0.949329119999959],
                [29.589314000000115, -0.949399],
                [29.589541, -0.949941],
                [29.589580653000041, -0.950036050999927],
                [29.58960914700009, -0.950103997999918],
                [29.589855194000108, -0.950700998999935],
                [29.590225219000104, -0.951395988999877],
                [29.590337666999972, -0.951605490999896],
                [29.590353000000107, -0.951634],
                [29.590546, -0.951993],
                [29.590570000000127, -0.952293],
                [29.590581539000084, -0.952441503999978],
                [29.590593338000076, -0.952588975999845],
                [29.590610201000175, -0.95276920099991],
                [29.590635000000191, -0.953029],
                [29.590668, -0.953384],
                [29.590696194000088, -0.954013367999949],
                [29.590702056000055, -0.954142987999887],
                [29.59074100600003, -0.95432965599997],
                [29.590849319000029, -0.954847960999871],
                [29.591393, -0.955605999999875],
                [29.591672000000187, -0.956071999999892],
                [29.59181, -0.956302],
                [29.591932, -0.95735],
                [29.591955000000155, -0.958163],
                [29.59193, -0.959183999999823],
                [29.591978, -0.960006],
                [29.591908984000042, -0.960985266999899],
                [29.591906161999987, -0.961034360999975],
                [29.59190400000017, -0.961071999999888],
                [29.592004, -0.961747],
                [29.592018743000153, -0.961846831999878],
                [29.592039108000165, -0.961983978999967],
                [29.592175212000086, -0.962157687999934],
                [29.592606001000092, -0.962707],
                [29.593222, -0.963376],
                [29.593592, -0.963972],
                [29.593613191000088, -0.964139265999904],
                [29.593639, -0.964343],
                [29.593473000000131, -0.96458],
                [29.59344300000015, -0.964622],
                [29.593209166000179, -0.964871332999905],
                [29.593096, -0.964992000999871],
                [29.592972421000013, -0.965172941999924],
                [29.592775345000064, -0.965462028999866],
                [29.592699052000057, -0.965957998999954],
                [29.59269352400014, -0.965997547999962],
                [29.592681, -0.966088],
                [29.592602, -0.966653999999892],
                [29.592527, -0.967028],
                [29.592494849000104, -0.967187800999909],
                [29.592426299000124, -0.967530011999884],
                [29.59236830500015, -0.968163870999945],
                [29.592352000000119, -0.968343],
                [29.5923, -0.968478999999888],
                [29.59220692700012, -0.968721139999957],
                [29.592054367000117, -0.96911901299984],
                [29.592044870000109, -0.969347247999963],
                [29.59204300000016, -0.969394],
                [29.592030000000136, -0.969715],
                [29.592105000000117, -0.970041],
                [29.592119080000032, -0.970102548999932],
                [29.592140197999981, -0.970193981999955],
                [29.592256904000067, -0.970450748999895],
                [29.592456043000141, -0.970888113999933],
                [29.592461, -0.970898999999861],
                [29.592628, -0.971303],
                [29.592754425000123, -0.97160918599991],
                [29.59298, -0.972275],
                [29.593052000000114, -0.972489],
                [29.593346000000111, -0.973301999999819],
                [29.593719, -0.973708],
                [29.593786709000085, -0.9737817379999],
                [29.594011306000084, -0.974026022999965],
                [29.594190413000092, -0.974132442999974],
                [29.594279, -0.974185],
                [29.594721354000058, -0.974447647999909],
                [29.595207515000027, -0.974623004999955],
                [29.595688000000109, -0.974795],
                [29.596188527000038, -0.974907040999938],
                [29.596576999000035, -0.974993998999935],
                [29.59663, -0.975002],
                [29.597146008000095, -0.975075500999935],
                [29.597662000000184, -0.975148999999874],
                [29.597724000000142, -0.975176],
                [29.598574000000156, -0.975548],
                [29.599083294000025, -0.97587498099989],
                [29.599263999000129, -0.975990998999976],
                [29.599418386000025, -0.976137299999948],
                [29.599676351000141, -0.976381752999885],
                [29.599684000000138, -0.97638899999987],
                [29.600245, -0.977139],
                [29.600306000000103, -0.977491],
                [29.600243, -0.978475999999887],
                [29.599974000000145, -0.979269999999872],
                [29.599667000000181, -0.979927],
                [29.599651451000057, -0.979959448999978],
                [29.59964378300009, -0.979975481999873],
                [29.599484207000046, -0.980330274999915],
                [29.599344253000027, -0.980642019999891],
                [29.59912300100018, -0.981193005999899],
                [29.598665237000148, -0.981896995999875],
                [29.598449048000134, -0.982206972999961],
                [29.598162000000173, -0.982619],
                [29.597706, -0.98343199999988],
                [29.597170000000176, -0.984271],
                [29.596368924000103, -0.984423991999904],
                [29.595806282000126, -0.98436408099991],
                [29.595502083999975, -0.984331449999956],
                [29.59499589, -0.984236011999883],
                [29.594545, -0.984151],
                [29.593789496000056, -0.984041869999885],
                [29.593646615000011, -0.984005183999955],
                [29.592976, -0.983833],
                [29.592391917000157, -0.983187657999906],
                [29.592145880000146, -0.982674995999957],
                [29.591664340000079, -0.981671824999921],
                [29.591313000000127, -0.981046],
                [29.591200634000074, -0.980844747999868],
                [29.591178893000119, -0.980805991999944],
                [29.590503692000141, -0.979287980999857],
                [29.590066909000143, -0.97845601899985],
                [29.58994827600003, -0.978323455999885],
                [29.5895, -0.977823],
                [29.589161, -0.977612],
                [29.58902725400003, -0.977528546999849],
                [29.588918685000181, -0.977460979999933],
                [29.588893673000086, -0.977458587999934],
                [29.588877, -0.977457],
                [29.588636227000052, -0.977434021999954],
                [29.588211059000059, -0.977540015999978],
                [29.58788108900012, -0.977622984999925],
                [29.587017058000129, -0.978065012999934],
                [29.586372375000167, -0.978362023999978],
                [29.585720062000121, -0.978685974999962],
                [29.585706389000109, -0.978692842999976],
                [29.585539, -0.978777],
                [29.584721, -0.97913700099997],
                [29.584823688000029, -0.979339607999862],
                [29.585129, -0.97994199999988],
                [29.585217, -0.980127],
                [29.585498, -0.980721],
                [29.585535, -0.980799999999817],
                [29.58602100000013, -0.981668],
                [29.586414000000161, -0.982372],
                [29.586638, -0.982989],
                [29.586712, -0.983194],
                [29.586998183000105, -0.983783899999878],
                [29.587120001000017, -0.984035],
                [29.587450063000176, -0.984611032999908],
                [29.587591, -0.984857],
                [29.587714, -0.985108],
                [29.588015, -0.985724999999889],
                [29.588197186000059, -0.986254882999958],
                [29.588309829000082, -0.986582501999976],
                [29.588347, -0.987226],
                [29.588357955000163, -0.987422194999908],
                [29.588354, -0.987537],
                [29.588325115000089, -0.988304944999925],
                [29.588320864000025, -0.988316432999909],
                [29.587681, -0.989621],
                [29.587151, -0.990701],
                [29.586891491000131, -0.991147515999955],
                [29.586632, -0.99159399999985],
                [29.586207552000133, -0.992307473999972],
                [29.585955000000183, -0.992732000999865],
                [29.585792229000106, -0.99297924799987],
                [29.585718155000052, -0.99309200099998],
                [29.5854492200001, -0.993869004999965],
                [29.584875108000176, -0.99550300899989],
                [29.584684372000027, -0.996424019999893],
                [29.584571127000174, -0.997025545999918],
                [29.584511, -0.997346000999869],
                [29.584400506000179, -0.997810473999948],
                [29.58429, -0.998275],
                [29.584186, -0.998744],
                [29.584131001000173, -0.998989],
                [29.584096640999974, -0.999195701999895],
                [29.584068, -0.999368],
                [29.584146, -0.999522000999889],
                [29.584048051000082, -1.000167761999933],
                [29.583979001000046, -1.000623],
                [29.583820302000106, -1.001061817999926],
                [29.583698273000095, -1.001399993999939],
                [29.583471297000131, -1.002845048999973],
                [29.583360884000115, -1.003453074999925],
                [29.583245000000147, -1.004092999999898],
                [29.583191000000113, -1.00438899999989],
                [29.583113, -1.004967],
                [29.58349800100018, -1.005472999999881],
                [29.583833999000092, -1.006014995999976],
                [29.584250133000069, -1.006082303999847],
                [29.584341, -1.006096999999897],
                [29.584575, -1.006154],
                [29.585045617000162, -1.006269659999873],
                [29.585699000000147, -1.006664],
                [29.58572, -1.006677],
                [29.585876, -1.006789],
                [29.586424, -1.007183],
                [29.587128, -1.007726],
                [29.587325848000091, -1.00786661199993],
                [29.587688001000117, -1.008124],
                [29.587897139, -1.008484720999945],
                [29.5880550010001, -1.008756999999889],
                [29.588362, -1.009154],
                [29.588472, -1.009179],
                [29.588669282000069, -1.009224031999906],
                [29.588840290000178, -1.009262993999926],
                [29.589513, -1.009249999999838],
                [29.589745, -1.009246],
                [29.589993000000163, -1.009335],
                [29.590221, -1.009417999999869],
                [29.590418469000099, -1.009571975999904],
                [29.590616000000182, -1.009726],
                [29.590830701000129, -1.010225336999895],
                [29.590923, -1.01044],
                [29.591005, -1.01081],
                [29.591261088000124, -1.010993846999952],
                [29.591597, -1.011235],
                [29.591680607000058, -1.011303544999919],
                [29.59186935300005, -1.011457562999965],
                [29.592613220000089, -1.040359734999925],
                [29.593246139000144, -1.046101547999911],
                [29.594207000000154, -1.054815],
                [29.595009, -1.062093],
                [29.594784, -1.066353999999876],
                [29.594453, -1.072655],
                [29.594408, -1.073505],
                [29.594345, -1.074683999999877],
                [29.593753215000163, -1.080092629999854],
                [29.592889986000046, -1.087982127999908],
                [29.592660011000135, -1.090084167999919],
                [29.592109642000139, -1.095091940999851],
                [29.590385, -1.099170999999842],
                [29.58907900100013, -1.102258998999901],
                [29.58834417800017, -1.112852723999879],
                [29.587500000000148, -1.125023],
                [29.589310000000125, -1.13459],
                [29.591448000000128, -1.142102],
                [29.593252650000011, -1.1484434899999],
                [29.593273000000124, -1.148515],
                [29.593376, -1.149279999999862],
                [29.593402107000031, -1.149473661999878],
                [29.593442879000065, -1.149776722999945],
                [29.593373605000181, -1.149618870999916],
                [29.593320482000138, -1.149498072999961],
                [29.593278000000169, -1.149422],
                [29.593185020000135, -1.149255037999922],
                [29.592847824000103, -1.149255037999922],
                [29.592599870000186, -1.149255037999922],
                [29.592558156000109, -1.149243861999878],
                [29.592461, -1.149218],
                [29.592327039000111, -1.149182010999937],
                [29.592033386000026, -1.14936196799988],
                [29.591907308000089, -1.149446258999944],
                [29.591762955000149, -1.149543034999965],
                [29.591681914000105, -1.14960645899987],
                [29.591648000000134, -1.149633],
                [29.591422067000167, -1.149604164999971],
                [29.591266430000189, -1.149560743999928],
                [29.591187914000102, -1.149663153999939],
                [29.591129303000116, -1.149739979999822],
                [29.591116441000111, -1.149812843999882],
                [29.591099, -1.149914],
                [29.591061, -1.150129],
                [29.591109346000167, -1.150251767999976],
                [29.591149943000062, -1.150354856999968],
                [29.591194, -1.150671],
                [29.59114999500008, -1.150709136999979],
                [29.591058999000154, -1.15078800099991],
                [29.590805474000035, -1.150905351999938],
                [29.590448000000151, -1.150940999999875],
                [29.590334000000155, -1.150952],
                [29.590154999000163, -1.15096800099991],
                [29.589860755000132, -1.150985133999882],
                [29.589742000000172, -1.15105],
                [29.589631484999984, -1.151109897999902],
                [29.589611053000112, -1.151121019999948],
                [29.589475124000103, -1.151246005999894],
                [29.589386, -1.151328000999854],
                [29.589228001000038, -1.151435999999819],
                [29.589138449000075, -1.151471427999979],
                [29.589046478000171, -1.15150797299998],
                [29.588842391000071, -1.151661992999948],
                [29.588481972000181, -1.151797060999911],
                [29.588466225000047, -1.151832493999848],
                [29.588390350000054, -1.152004003999934],
                [29.588457108000114, -1.15221202399988],
                [29.588662992000081, -1.152349580999896],
                [29.588728000000117, -1.152393],
                [29.588745040000106, -1.152414756999974],
                [29.588905000000182, -1.152618998999913],
                [29.588971866000065, -1.152917223999964],
                [29.588936000000103, -1.152977],
                [29.588836860000072, -1.153143119999925],
                [29.588768, -1.153202],
                [29.588678, -1.153277999999887],
                [29.588318000000129, -1.153277999999887],
                [29.58809100000019, -1.153186999999832],
                [29.588063000000147, -1.153143],
                [29.587978, -1.153006],
                [29.587888507, -1.152878150999925],
                [29.587844849000135, -1.152816056999939],
                [29.587801777000152, -1.15276436299996],
                [29.58773200000013, -1.152681],
                [29.587653000000159, -1.152562999999873],
                [29.587632713000175, -1.152532569999949],
                [29.587598817000014, -1.15248205599994],
                [29.587524000000144, -1.152505],
                [29.587369871000078, -1.152551726999946],
                [29.587303162000183, -1.152572035999924],
                [29.587206423000055, -1.152640765999934],
                [29.586987000000136, -1.152797],
                [29.586757075000037, -1.152882040999828],
                [29.586694802000125, -1.152905140999962],
                [29.586504000000161, -1.153041],
                [29.58639807100019, -1.153115481999976],
                [29.586376191000113, -1.1531310069999],
                [29.586082459000181, -1.15331101299995],
                [29.585855484000149, -1.153445958999953],
                [29.585827309000024, -1.153585067999927],
                [29.58581, -1.153672],
                [29.58580400000011, -1.153801],
                [29.585798000000182, -1.153916],
                [29.585591100000102, -1.154005193999922],
                [29.5855216490001, -1.153986461999978],
                [29.585257000000183, -1.153915],
                [29.585009, -1.153635],
                [29.584921, -1.153363999999897],
                [29.584896951000133, -1.153315902999907],
                [29.584808000000123, -1.153138000999945],
                [29.584705535000126, -1.153023879999921],
                [29.584596866000084, -1.152903217999892],
                [29.584548270000141, -1.152982043999941],
                [29.584425, -1.153182],
                [29.584332006000182, -1.153315015999965],
                [29.584267000000125, -1.153408000999889],
                [29.584084, -1.153813999999841],
                [29.583833921000121, -1.154157140999814],
                [29.583771000000127, -1.154268999999829],
                [29.583696000000145, -1.154400999999893],
                [29.583515, -1.154789],
                [29.583333114000141, -1.155043363999937],
                [29.583176, -1.155285],
                [29.582993, -1.155556],
                [29.5829, -1.155805999999814],
                [29.582855, -1.155926],
                [29.58262800100016, -1.156287],
                [29.582401000000118, -1.156603000999951],
                [29.582335668000042, -1.156741668999871],
                [29.582197000000122, -1.157035999999891],
                [29.582039343000133, -1.157259347999968],
                [29.581993000000125, -1.157325],
                [29.58176600000013, -1.15753199999989],
                [29.581744179000054, -1.157591317999902],
                [29.581663001000095, -1.157812000999968],
                [29.581627386000036, -1.157874479999975],
                [29.581606, -1.157911999999897],
                [29.581565, -1.157982999999888],
                [29.581412728000032, -1.158246313999939],
                [29.581309165000107, -1.158366249999972],
                [29.581141000000116, -1.158561],
                [29.581014, -1.158653],
                [29.580955265000114, -1.158695122999916],
                [29.580915452000113, -1.158723948999921],
                [29.580796134000025, -1.158861483999942],
                [29.580666001000168, -1.159011998999972],
                [29.580621019000034, -1.159049510999864],
                [29.580395, -1.159238],
                [29.580168, -1.159418],
                [29.579897, -1.159581],
                [29.579693, -1.159850999999833],
                [29.579465706000065, -1.160005222999871],
                [29.579355112000144, -1.160089290999906],
                [29.579170228000123, -1.160230039999931],
                [29.578966141000024, -1.160464047999938],
                [29.578876494000156, -1.160645008999893],
                [29.57884704300011, -1.160802009999827],
                [29.57883100000015, -1.16088900099993],
                [29.578646553000056, -1.161144798999942],
                [29.578568, -1.161351],
                [29.57851, -1.161503],
                [29.578425, -1.161673],
                [29.578375, -1.161772999999869],
                [29.578296, -1.161832],
                [29.578216663000035, -1.161891249999883],
                [29.578120844000182, -1.16196232599998],
                [29.578035355000111, -1.162026047999916],
                [29.577875137000035, -1.162178992999884],
                [29.577806472000077, -1.162405013999944],
                [29.577778336000108, -1.162577129999875],
                [29.577761, -1.16268499999984],
                [29.577826178000123, -1.163016739999932],
                [29.577839000000154, -1.163082],
                [29.577842775000192, -1.163122324999961],
                [29.577883, -1.163551999999811],
                [29.577862, -1.163805],
                [29.577860593000025, -1.163821885999937],
                [29.577856064000116, -1.163877009999965],
                [29.577858825000078, -1.163922847999857],
                [29.577869000000135, -1.164088],
                [29.577878997000141, -1.164256954999871],
                [29.5779, -1.164527],
                [29.577899000000116, -1.164827],
                [29.577898026000014, -1.164942979999978],
                [29.577906192000171, -1.164965210999924],
                [29.577988, -1.165186999999889],
                [29.577965, -1.165467],
                [29.57793, -1.165677],
                [29.577894, -1.165890999999874],
                [29.577779000000135, -1.166075],
                [29.577736000000129, -1.166144],
                [29.577555, -1.16637],
                [29.577305, -1.166595],
                [29.577101, -1.166821],
                [29.576920000000143, -1.167046],
                [29.576851120000129, -1.167115548999959],
                [29.576707628000122, -1.167259061999914],
                [29.576393, -1.167509],
                [29.576374054000041, -1.167523978999896],
                [29.57630538900014, -1.167705059999889],
                [29.576080322999985, -1.167858003999925],
                [29.57607841500004, -1.168228028999977],
                [29.576175338000155, -1.168421961999968],
                [29.576214000000164, -1.168499],
                [29.576529000000107, -1.168708],
                [29.576754, -1.168952],
                [29.576976999000124, -1.169204998999931],
                [29.577247999000178, -1.169294998999817],
                [29.577475106000065, -1.169447976999948],
                [29.577584221000109, -1.169521475999886],
                [29.57770351400012, -1.169656457999963],
                [29.577808, -1.169774998999912],
                [29.577895462000185, -1.169905232999952],
                [29.57799000000017, -1.170045998999967],
                [29.578109, -1.170295],
                [29.578201, -1.170489],
                [29.578281191000031, -1.170775656999922],
                [29.578334812000094, -1.170967332999965],
                [29.57839, -1.171072],
                [29.578467875, -1.171220761999905],
                [29.578541000000143, -1.17139],
                [29.578604, -1.171537],
                [29.578669, -1.171898999999883],
                [29.578871, -1.172152],
                [29.578911278000191, -1.172267706999946],
                [29.578980936000107, -1.17246781699987],
                [29.579062042000089, -1.172706723999909],
                [29.579072973000166, -1.172738919999915],
                [29.579205000000172, -1.173173],
                [29.579298339000161, -1.173345508999944],
                [29.579317, -1.17338],
                [29.579341256000077, -1.173543633999884],
                [29.579384, -1.173832],
                [29.579448853000031, -1.174149819999968],
                [29.579385467000122, -1.174506250999968],
                [29.57938, -1.174537],
                [29.579376000000138, -1.17455],
                [29.579266, -1.174880000999963],
                [29.579182038000056, -1.175127318999898],
                [29.579173886000092, -1.175151325999934],
                [29.579114000000118, -1.175323],
                [29.578991, -1.175674],
                [29.578808, -1.176126],
                [29.57865, -1.176603999999884],
                [29.578564428000107, -1.176815369999872],
                [29.578533000000164, -1.176892999999893],
                [29.578466017000039, -1.177009013999907],
                [29.578283000999988, -1.177326000999926],
                [29.578125004000185, -1.177623991999894],
                [29.577820000000145, -1.177930999999887],
                [29.577718, -1.177998499999887],
                [29.577616000000148, -1.178065999999888],
                [29.577389, -1.178174],
                [29.577185, -1.178426999999829],
                [29.577113000000111, -1.178515999999888],
                [29.577003, -1.178652],
                [29.576886761000139, -1.178739748999931],
                [29.576799, -1.178805999999895],
                [29.576695861000132, -1.178999873999885],
                [29.576641000000109, -1.179103000999874],
                [29.576662000000113, -1.179447],
                [29.576683, -1.179736],
                [29.576681, -1.180061],
                [29.576631513000166, -1.180235998999933],
                [29.576611833000129, -1.180305442999895],
                [29.576516041000161, -1.180559463999941],
                [29.576500001000113, -1.180601998999919],
                [29.57634, -1.180801],
                [29.576180000000193, -1.181171],
                [29.576079000000163, -1.18133],
                [29.576065, -1.18135199999989],
                [29.576037, -1.181377],
                [29.575908549000189, -1.181487353999899],
                [29.575830000000167, -1.181549],
                [29.575771, -1.181594999999845],
                [29.57559, -1.181713],
                [29.575342, -1.181775],
                [29.575245330000087, -1.181796427999814],
                [29.574935938000067, -1.181865010999957],
                [29.574795000000165, -1.181890999999894],
                [29.574687999000105, -1.181910000999949],
                [29.574535984000022, -1.181909483999846],
                [29.574393407000059, -1.181908921999877],
                [29.574235, -1.181887999999844],
                [29.574056999000106, -1.181864],
                [29.573844559000122, -1.181815076999953],
                [29.5737400000001, -1.181791],
                [29.573492000000158, -1.181701],
                [29.573413000000187, -1.18169],
                [29.573394686000086, -1.18168725299995],
                [29.573383988000103, -1.181685806999894],
                [29.573257, -1.181669],
                [29.57315427900005, -1.181655037999917],
                [29.572748184000091, -1.181627034999906],
                [29.57268120700013, -1.181663116999971],
                [29.572613000999979, -1.181700000999911],
                [29.572432001000152, -1.181925],
                [29.57220500000011, -1.182151000999909],
                [29.572128914000189, -1.182278928999949],
                [29.572000772000081, -1.182494624999947],
                [29.571895464000079, -1.182783996999888],
                [29.571885939000083, -1.182810077999932],
                [29.571861, -1.18284199999988],
                [29.571705000000122, -1.183035],
                [29.571679, -1.183098999999857],
                [29.571669792000023, -1.183121600999925],
                [29.571613311000078, -1.183261035999919],
                [29.571535085000164, -1.183476976999941],
                [29.571499001, -1.183577],
                [29.571589001000177, -1.18396499999983],
                [29.571674, -1.18423],
                [29.571690231000048, -1.184281288999955],
                [29.571699143000046, -1.184309004999875],
                [29.571720124000137, -1.184533952999914],
                [29.571857174000058, -1.184673507999833],
                [29.572013766000055, -1.184832762999974],
                [29.572063, -1.18491],
                [29.572168, -1.185077],
                [29.57225, -1.185207],
                [29.572327, -1.18533],
                [29.572416000000146, -1.185499],
                [29.572459869000113, -1.185582749999924],
                [29.572481, -1.185808999999892],
                [29.572481, -1.186008],
                [29.572439454000119, -1.18619823399996],
                [29.572423944000093, -1.186269142999947],
                [29.572413364999989, -1.186296559999903],
                [29.572309494000024, -1.186566948999825],
                [29.572172165000097, -1.186956046999967],
                [29.572142, -1.187050999999883],
                [29.572081000000139, -1.187244000999954],
                [29.572091, -1.187335],
                [29.572104, -1.187452],
                [29.572141259000034, -1.187610349999886],
                [29.572168350000084, -1.187723992999963],
                [29.572212786000023, -1.187841247999927],
                [29.57225700000015, -1.187957],
                [29.572280905000071, -1.188020747999929],
                [29.572326695000129, -1.188051764999841],
                [29.572483062000174, -1.188156962999926],
                [29.57272539000013, -1.188194924999948],
                [29.572777000000144, -1.188202998999941],
                [29.573069001000079, -1.188337999999874],
                [29.573234328000183, -1.188429974999963],
                [29.573362351000185, -1.188500998999871],
                [29.573587417000169, -1.188547013999937],
                [29.573902131000068, -1.188683032999848],
                [29.574108123000087, -1.188807010999881],
                [29.574171066000019, -1.188845992999916],
                [29.574295149000079, -1.188909988999967],
                [29.574396840000077, -1.188962402999948],
                [29.574506, -1.18901499999987],
                [29.574610168000163, -1.189065747999962],
                [29.574623108000139, -1.189072012999873],
                [29.574696975000109, -1.189103938999949],
                [29.574935303000132, -1.189206835999926],
                [29.575253, -1.189416],
                [29.575383468000155, -1.189510516999917],
                [29.575478001000079, -1.189579],
                [29.575602285000059, -1.189670602999911],
                [29.575747937000017, -1.189777953999851],
                [29.575854, -1.189896],
                [29.575974, -1.190031],
                [29.57622, -1.190212],
                [29.576468, -1.190493],
                [29.576607226000192, -1.190666512999826],
                [29.576735959000075, -1.19082694899987],
                [29.576848921000192, -1.190967734999958],
                [29.576939, -1.19108],
                [29.577082000000189, -1.191353],
                [29.577091500999984, -1.191371271999969],
                [29.577126438000164, -1.191405809999878],
                [29.577432001000091, -1.191676117999975],
                [29.5776350000001, -1.192038],
                [29.577704157000085, -1.192107156999896],
                [29.577773346000185, -1.19217634499995],
                [29.577835083000082, -1.19223797199993],
                [29.578014374000077, -1.192517041999906],
                [29.57823944200004, -1.192788957999937],
                [29.578279320000149, -1.192833799999903],
                [29.578347, -1.192909],
                [29.57846485400006, -1.193041834999974],
                [29.5786, -1.193196],
                [29.578669, -1.193273],
                [29.578799865000121, -1.193421846999968],
                [29.578850166000109, -1.193513898999868],
                [29.578913001000103, -1.193628998999884],
                [29.579066108000063, -1.193801840999811],
                [29.579138000000114, -1.193883],
                [29.579247561000045, -1.193992560999959],
                [29.579418, -1.194163],
                [29.579597, -1.194398],
                [29.579711000000145, -1.194535],
                [29.579822342000114, -1.19466920699989],
                [29.580048, -1.194867998999825],
                [29.580273000999966, -1.195031],
                [29.580542001000026, -1.195257998999921],
                [29.58079, -1.195437998999978],
                [29.580902510000101, -1.195519506999972],
                [29.581015, -1.195600999999897],
                [29.581153, -1.195679999999868],
                [29.581329, -1.195782],
                [29.581575000000157, -1.195945],
                [29.581846731000155, -1.19618361299996],
                [29.581935414000156, -1.196261485999912],
                [29.582055, -1.196453],
                [29.582138, -1.196587],
                [29.582317000000103, -1.196876],
                [29.582406000000162, -1.197095999999874],
                [29.582473676000063, -1.197264193999956],
                [29.582541000000163, -1.197563],
                [29.582565407000118, -1.197727036999936],
                [29.582584381000117, -1.197852014999967],
                [29.582626343000186, -1.198176979999971],
                [29.582624435000014, -1.198647021999932],
                [29.582645416000105, -1.199107050999828],
                [29.582666397000139, -1.199396966999927],
                [29.582759132000092, -1.199679691999961],
                [29.582779001000063, -1.19974],
                [29.582949651000149, -1.200177122999889],
                [29.582965996000155, -1.200218989999883],
                [29.583022572, -1.200201071999913],
                [29.583249999000088, -1.20012899999989],
                [29.583362530000102, -1.200166786999944],
                [29.583520921000115, -1.200219972999889],
                [29.583791732000066, -1.200085043999934],
                [29.583951950000028, -1.199975966999944],
                [29.583977828, -1.199958521999918],
                [29.584085000000186, -1.199885999999879],
                [29.584173000000192, -1.199841999999876],
                [29.584267000000125, -1.199795999999878],
                [29.58453800000018, -1.19986],
                [29.584988000000124, -1.199978],
                [29.585267000000101, -1.200083],
                [29.585340961000099, -1.20011130599994],
                [29.585358132000181, -1.200117487999933],
                [29.585409000000141, -1.200134999999875],
                [29.585686386000191, -1.200231438999936],
                [29.585913001000165, -1.20029499899988],
                [29.586030694000044, -1.200310730999945],
                [29.586114965000036, -1.200321994999968],
                [29.58623, -1.200271],
                [29.586318665000192, -1.200232146999838],
                [29.586454001000163, -1.200141998999925],
                [29.586658000000114, -1.200052],
                [29.586989773000084, -1.20013062299995],
                [29.587042, -1.200142998999922],
                [29.5873570010001, -1.200441],
                [29.587505139000086, -1.20055655799996],
                [29.587648393000052, -1.20066797699991],
                [29.587873276000096, -1.200802930999885],
                [29.587982, -1.200776],
                [29.588055, -1.200757999999894],
                [29.588118730000133, -1.200747674999946],
                [29.588233952999985, -1.200729005999904],
                [29.588210282000091, -1.200912566999932],
                [29.588138123000078, -1.201472114999945],
                [29.587837237000031, -1.203806146999966],
                [29.58791100000019, -1.203852],
                [29.587963426000101, -1.2038848279999],
                [29.588018418000047, -1.20391905199989],
                [29.588333130000137, -1.204100012999959],
                [29.588737489000096, -1.204262972999914],
                [29.588992189000066, -1.204349752999974],
                [29.589189784000155, -1.204416926999954],
                [29.589457332000052, -1.204626832999907],
                [29.589548, -1.204697998999961],
                [29.589657053000167, -1.204836953999973],
                [29.589796, -1.205013999999892],
                [29.590064999000163, -1.205330998999898],
                [29.59010000000012, -1.205359],
                [29.59038, -1.205583999999874],
                [29.590628, -1.20572],
                [29.590807, -1.205946],
                [29.591055, -1.206154],
                [29.59114425700011, -1.206222046999926],
                [29.591257, -1.206308],
                [29.591482, -1.206516],
                [29.591751000000158, -1.206678999999895],
                [29.591976000000102, -1.206787],
                [29.591990996999982, -1.206801160999873],
                [29.592245000000162, -1.207040999999833],
                [29.592347020000091, -1.207145019999928],
                [29.592449000000158, -1.207248999999877],
                [29.592628, -1.207546999999863],
                [29.592638331000103, -1.207803725999952],
                [29.592648000000111, -1.208043998999926],
                [29.592687445000081, -1.208302785999933],
                [29.592714310000076, -1.208477019999975],
                [29.592779160000134, -1.209018945999958],
                [29.592826279000178, -1.20917847599992],
                [29.592846001000055, -1.209245],
                [29.592887000000133, -1.209266],
                [29.593250000000182, -1.209453999999823],
                [29.593382439000038, -1.209556825999869],
                [29.593656054000178, -1.209769267999945],
                [29.593902000000128, -1.209936],
                [29.593988055000068, -1.209994325999958],
                [29.594488000000126, -1.210187],
                [29.594616, -1.210295999999858],
                [29.594804181000086, -1.210457297999881],
                [29.594928038000091, -1.210528921999924],
                [29.595119, -1.210638998999968],
                [29.595478000000128, -1.210892],
                [29.595582, -1.210924],
                [29.595771692000085, -1.210982903999934],
                [29.595959000000164, -1.211090999999897],
                [29.59609577000009, -1.211170847999938],
                [29.596132278000084, -1.211192011999913],
                [29.596401215000071, -1.211344956999938],
                [29.596603393000066, -1.21144497399996],
                [29.59685134900019, -1.211490033999951],
                [29.59714534200009, -1.211658062999845],
                [29.597167996000167, -1.211670996999942],
                [29.597484919000181, -1.211671998999918],
                [29.597501383000122, -1.211675468999943],
                [29.597792439000045, -1.211736799999869],
                [29.597911975000159, -1.211761993999915],
                [29.59818300000012, -1.211853],
                [29.598366411000086, -1.211983129999908],
                [29.598541261000037, -1.212106942999924],
                [29.598882146000051, -1.212341876999915],
                [29.59910600000012, -1.212495999999874],
                [29.599150229000145, -1.212565167999912],
                [29.599239349000015, -1.212703942999951],
                [29.599416542000029, -1.212832019999894],
                [29.599576, -1.212947],
                [29.599634, -1.213031],
                [29.599698900000135, -1.213125612999932],
                [29.599800091000077, -1.213273022999886],
                [29.60016300000018, -1.213364],
                [29.600286, -1.213370999999881],
                [29.600476544000173, -1.21338297099993],
                [29.600675, -1.213411],
                [29.600794000000178, -1.213428],
                [29.600910925000164, -1.213493920999952],
                [29.601127674000054, -1.21361612499993],
                [29.601353944000095, -1.213745619999884],
                [29.601559, -1.213863],
                [29.60173200000014, -1.214044999999885],
                [29.601752, -1.214065],
                [29.601906000000156, -1.214216],
                [29.602016021999987, -1.214287820999914],
                [29.6022660000001, -1.214450999999826],
                [29.602355000000159, -1.214548],
                [29.602445899000145, -1.214648887999942],
                [29.602562920000139, -1.214757085999906],
                [29.602671, -1.214857],
                [29.602836, -1.214908],
                [29.602936700000157, -1.21493946899983],
                [29.602964401000065, -1.214948057999834],
                [29.603256226000155, -1.215083956999877],
                [29.603483200000085, -1.215065955999819],
                [29.603666117000046, -1.215105548999873],
                [29.60382060500018, -1.215138915999887],
                [29.604206418000047, -1.215268744999889],
                [29.604225159000123, -1.215275048999956],
                [29.604383469000027, -1.215500949999921],
                [29.604675293000014, -1.215682029999812],
                [29.604877472000169, -1.216115950999892],
                [29.604965209000056, -1.216369032999978],
                [29.604942321000124, -1.21668505699995],
                [29.605009079000126, -1.216910957999971],
                [29.60506248400003, -1.217100023999876],
                [29.605185050000046, -1.217257921999817],
                [29.605309000000148, -1.217416999999898],
                [29.605425, -1.217549],
                [29.605498847000149, -1.217633824999894],
                [29.605561892000139, -1.217705593999881],
                [29.605657953000161, -1.217814946999965],
                [29.605782058000045, -1.217896537999877],
                [29.605906000000175, -1.217978],
                [29.605982963000031, -1.218014941999911],
                [29.606131000000119, -1.218086],
                [29.606333000000177, -1.218294],
                [29.606438363000052, -1.218471983999905],
                [29.606510001000117, -1.218593],
                [29.606549, -1.218691],
                [29.606645734000097, -1.218935327999873],
                [29.606683000000146, -1.219045999999878],
                [29.606712, -1.219135],
                [29.60669200000018, -1.219256],
                [29.606667, -1.219405999999822],
                [29.606417000000192, -1.219749],
                [29.606168873000172, -1.219792989999974],
                [29.605839000000117, -1.219767],
                [29.605829, -1.219766],
                [29.605759, -1.219752],
                [29.605582450000043, -1.219715821999955],
                [29.605514527000025, -1.219702005999977],
                [29.605220957000085, -1.219702007999956],
                [29.60478, -1.219782],
                [29.604717, -1.219793999999865],
                [29.604317000000151, -1.219917999999893],
                [29.604177090000121, -1.219983242999888],
                [29.603931426000031, -1.220098017999874],
                [29.603727341000138, -1.220376967999869],
                [29.603391275000035, -1.220666972999879],
                [29.603184, -1.220846],
                [29.603016458000184, -1.221046696999963],
                [29.602709001000051, -1.221415000999969],
                [29.602255001000174, -1.221702999999877],
                [29.602182984000137, -1.221785182999952],
                [29.601915359000145, -1.222090958999843],
                [29.601957321000043, -1.222543001999952],
                [29.602062226000101, -1.222759961999941],
                [29.602084134000165, -1.222804267999891],
                [29.602116000000137, -1.222867999999835],
                [29.602195, -1.22314],
                [29.602216653000028, -1.223214951999921],
                [29.602247239000064, -1.223320005999938],
                [29.602270268999973, -1.223369970999954],
                [29.602308, -1.223450999999841],
                [29.602427, -1.223708999999872],
                [29.602468000000101, -1.223831],
                [29.602493, -1.223906999999826],
                [29.602567000000136, -1.2243],
                [29.602583, -1.224386],
                [29.602601483000058, -1.224429446999977],
                [29.602737001000094, -1.224747999999863],
                [29.602855000000147, -1.225246999999854],
                [29.602871, -1.225317],
                [29.603127, -1.226148],
                [29.603191468000091, -1.226344301999973],
                [29.603216172000032, -1.22641897099993],
                [29.603262077000181, -1.226487968999948],
                [29.603373001000136, -1.226653998999893],
                [29.603598000000147, -1.226834998999948],
                [29.603770290000057, -1.226910723999936],
                [29.603846, -1.226944],
                [29.604048, -1.227062],
                [29.604227000000151, -1.227179],
                [29.604362000000151, -1.22727],
                [29.60447, -1.227404],
                [29.604544, -1.22749599999986],
                [29.60462600000011, -1.227661999999896],
                [29.604676953000023, -1.227766902999974],
                [29.604726, -1.227903],
                [29.604789826000172, -1.228082510999968],
                [29.604844435000189, -1.228114798999968],
                [29.604992, -1.228200999999842],
                [29.605027001000167, -1.22822468499993],
                [29.605124900000192, -1.228290932999926],
                [29.605215417000124, -1.228409281999916],
                [29.605283739000129, -1.228498659999957],
                [29.605354000000148, -1.228604],
                [29.605417, -1.228698999999836],
                [29.605479471000137, -1.228905150999935],
                [29.605506934000175, -1.228995786999974],
                [29.605536, -1.22907],
                [29.605639897000117, -1.229339732999904],
                [29.60575035300019, -1.229625370999941],
                [29.605797, -1.229745999999864],
                [29.605885, -1.230108],
                [29.605883, -1.230568],
                [29.60576643800016, -1.23097066899993],
                [29.605619430000104, -1.231480002999888],
                [29.605547052000134, -1.231894297999872],
                [29.605528000000163, -1.232004],
                [29.605417, -1.232215],
                [29.605410371000119, -1.232227599999931],
                [29.605276108999988, -1.232483029999912],
                [29.60504913300008, -1.232707977999894],
                [29.604755402000023, -1.23290705699992],
                [29.604372024000099, -1.233085988999903],
                [29.604143143000044, -1.233520030999955],
                [29.604074478000143, -1.233726977999936],
                [29.604120255000112, -1.233908056999951],
                [29.604136831000062, -1.233930090999934],
                [29.604184000000146, -1.233991999999887],
                [29.604276844000026, -1.234115791999955],
                [29.604324237000128, -1.234221619999971],
                [29.604366001000187, -1.234314998999878],
                [29.604433000000142, -1.234495998999933],
                [29.604447, -1.234535999999878],
                [29.604479, -1.234631],
                [29.604544, -1.234794],
                [29.604504132000159, -1.235013271999947],
                [29.604497876000039, -1.235047549999933],
                [29.604479, -1.235131],
                [29.604406, -1.235452999999893],
                [29.604246001000035, -1.235931],
                [29.604221454000083, -1.236021362999964],
                [29.604155001000152, -1.236266],
                [29.604059259000053, -1.236384362999957],
                [29.603973388000156, -1.236490964999973],
                [29.604058008000038, -1.236633847999826],
                [29.604070000000149, -1.236653999999874],
                [29.604086, -1.236681],
                [29.604135234000012, -1.236702935999858],
                [29.604288, -1.236771],
                [29.604320469000186, -1.236814857999946],
                [29.604421823000052, -1.236951761999933],
                [29.604441000000122, -1.237058],
                [29.604465000000118, -1.237195999999869],
                [29.604284, -1.237701999999899],
                [29.604207357000064, -1.237823015999879],
                [29.60417, -1.237882],
                [29.604139000000146, -1.237955999999883],
                [29.604096725000147, -1.238055143999929],
                [29.604055404000178, -1.238152980999928],
                [29.604053497000109, -1.238423943999976],
                [29.604045087000145, -1.238540053999884],
                [29.604041, -1.2386],
                [29.604031000000134, -1.238739999999893],
                [29.604020000000105, -1.238861001999965],
                [29.604008001000068, -1.238993000999869],
                [29.603960037000036, -1.239140990999943],
                [29.603891374000114, -1.23935401499989],
                [29.603830000000187, -1.239596],
                [29.603803797000069, -1.239699937999944],
                [29.6038, -1.239715000999922],
                [29.60376129399998, -1.23984592599993],
                [29.603685379000183, -1.240103959999885],
                [29.603685379000183, -1.240419983999914],
                [29.603639517000147, -1.240669400999934],
                [29.603625, -1.240749],
                [29.603558, -1.241115],
                [29.603512000000137, -1.241594],
                [29.603306000000146, -1.241928],
                [29.603125, -1.242135],
                [29.602898000000152, -1.242379],
                [29.602694000000156, -1.242514],
                [29.602423000000101, -1.242604],
                [29.6023, -1.242596],
                [29.602170561000094, -1.242587574999959],
                [29.602131, -1.242585],
                [29.601790431999973, -1.242494725999961],
                [29.601542, -1.242375],
                [29.601468644000192, -1.242339154999968],
                [29.601453782000192, -1.242331981999939],
                [29.60118293700009, -1.242241024999942],
                [29.600915236000048, -1.242281984999977],
                [29.600889102000053, -1.242285984999967],
                [29.60064100000011, -1.242466],
                [29.600561, -1.242679],
                [29.600616000000173, -1.24299],
                [29.600458, -1.243213000999901],
                [29.600419464000083, -1.243269790999875],
                [29.600401000000147, -1.243297],
                [29.600344985000049, -1.243377954999971],
                [29.600263865000102, -1.243495134999876],
                [29.600176, -1.243583],
                [29.600140336000095, -1.243617920999839],
                [29.60012817300003, -1.243630051999958],
                [29.600098181000021, -1.243704058999924],
                [29.600037, -1.243855999999823],
                [29.600035, -1.244155],
                [29.600079000000164, -1.244452],
                [29.600079000000164, -1.244543],
                [29.600115354000138, -1.244692273999931],
                [29.600145000999987, -1.244814000999952],
                [29.599737000000118, -1.244994000999895],
                [29.599604000999989, -1.245012],
                [29.599399853000193, -1.245147083999882],
                [29.599297, -1.245206],
                [29.599250255000072, -1.245231875999878],
                [29.599241256000084, -1.245236992999935],
                [29.599206808000076, -1.245260129999906],
                [29.599173000000121, -1.245283],
                [29.59910600000012, -1.245328000999848],
                [29.598946000000183, -1.245462],
                [29.598865, -1.245709],
                [29.598854, -1.245741999999893],
                [29.598831, -1.245985999999846],
                [29.598783, -1.246329999999887],
                [29.598558, -1.246582],
                [29.598168943000132, -1.246612124999956],
                [29.59810625200015, -1.246616979999828],
                [29.597925187000044, -1.246644973999935],
                [29.597891427000093, -1.246673776999899],
                [29.597820000000127, -1.246734999999887],
                [29.597767001000079, -1.246779999999887],
                [29.597687998000026, -1.246856501999901],
                [29.597608371000092, -1.246933664999915],
                [29.597504401000037, -1.247043750999978],
                [29.597473145000095, -1.247076988999936],
                [29.597408514000165, -1.247157607999952],
                [29.597337, -1.247247],
                [29.597292, -1.247303],
                [29.597132, -1.247501],
                [29.596950458000094, -1.247619150999981],
                [29.59686500000015, -1.247642999999812],
                [29.596792000000164, -1.247663],
                [29.596567000000164, -1.247663],
                [29.596361697000134, -1.24759730299985],
                [29.596339730000182, -1.247589504999894],
                [29.596301, -1.247564],
                [29.596277202000181, -1.247548306999875],
                [29.596162797000034, -1.247473],
                [29.595914840000034, -1.247338055999876],
                [29.59581351300011, -1.247337252999898],
                [29.595779000000164, -1.247337],
                [29.595552425000164, -1.247337],
                [29.595304489000057, -1.247544050999977],
                [29.595216762000177, -1.24761390599997],
                [29.595153, -1.247665],
                [29.595055000000116, -1.247743],
                [29.594979791000014, -1.247875431999944],
                [29.594963073000088, -1.247905014999901],
                [29.594873429000131, -1.248193978999836],
                [29.594846415000177, -1.248335613999927],
                [29.594821000000138, -1.248471],
                [29.594802762000029, -1.24856434599991],
                [29.594768147000082, -1.248614998999926],
                [29.594667000000186, -1.248763000999929],
                [29.594564001000094, -1.249006000999941],
                [29.594406, -1.249006000999941],
                [29.594455131000132, -1.249201690999882],
                [29.594465000000184, -1.249240998999937],
                [29.594497009000065, -1.249356657999954],
                [29.594540001000041, -1.249511998999822],
                [29.594847000000129, -1.249946],
                [29.594988001000161, -1.250398],
                [29.595098443999973, -1.250497952999865],
                [29.59538841300008, -1.250759958999936],
                [29.595503306000069, -1.250811057999897],
                [29.595659000000182, -1.25088],
                [29.595816, -1.250949999999875],
                [29.596071624000047, -1.251005549999945],
                [29.596359253000116, -1.251067995999961],
                [29.596417162000023, -1.251078763999942],
                [29.596667, -1.251125],
                [29.597048, -1.251195999999879],
                [29.597758999000121, -1.25114199899997],
                [29.597774302000062, -1.251134387999855],
                [29.597956000000181, -1.251044],
                [29.598139, -1.250953],
                [29.598614, -1.250575],
                [29.598948000000178, -1.250196],
                [29.59925690200015, -1.250079036999921],
                [29.599564000000157, -1.250124],
                [29.599613000000147, -1.250456],
                [29.599628389000088, -1.250563720999878],
                [29.599634170000115, -1.250602959999924],
                [29.59975242500002, -1.250820039999951],
                [29.599988961000065, -1.250864990999958],
                [29.600151665000055, -1.251104097999928],
                [29.600159672000075, -1.251150336999842],
                [29.600201000000141, -1.25151599999981],
                [29.600291, -1.251585],
                [29.600364422000155, -1.251642038999933],
                [29.600412368000036, -1.251678942999945],
                [29.600826475000076, -1.251803849999931],
                [29.600982839000039, -1.251850951999813],
                [29.601199, -1.251881],
                [29.601491075000183, -1.251922312999852],
                [29.601503372000025, -1.251924038999903],
                [29.602474158000064, -1.252097],
                [29.602710308000098, -1.252105972999971],
                [29.603248873000155, -1.252130568999917],
                [29.604132579000066, -1.25217093599997],
                [29.604454206000128, -1.25208384299998],
                [29.604979, -1.251940999999874],
                [29.605901337000034, -1.251692040999899],
                [29.605438000000163, -1.253119],
                [29.604404966000118, -1.256297027999949],
                [29.604400402000124, -1.25630853499996],
                [29.601589, -1.262978],
                [29.601181, -1.263945],
                [29.600573000000168, -1.268082],
                [29.600077000000169, -1.276684999999873],
                [29.600401361000081, -1.280247069999973],
                [29.600736982000058, -1.283932791999973],
                [29.599243, -1.283776],
                [29.598997000000168, -1.28375],
                [29.598704019000138, -1.283713896999871],
                [29.596035, -1.283384999999839],
                [29.596009921000189, -1.28338169299991],
                [29.594488000000126, -1.283181],
                [29.594299319000015, -1.283156041999973],
                [29.594463349000137, -1.283923982999909],
                [29.595186234000153, -1.287546992999921],
                [29.595457076000173, -1.288902043999883],
                [29.595488930000158, -1.289035179999871],
                [29.595609000000138, -1.289536],
                [29.596413, -1.292896],
                [29.597281026000076, -1.296979059999899],
                [29.597740173000147, -1.299137948999942],
                [29.597904206000123, -1.299716947999968],
                [29.59822845400015, -1.299916027999927],
                [29.598487615000181, -1.30008198299987],
                [29.598533000000145, -1.300111],
                [29.598780000000147, -1.300269],
                [29.599600000000123, -1.300569],
                [29.599821000000134, -1.30065],
                [29.600066455999979, -1.300847842999929],
                [29.600650999000152, -1.301318998999932],
                [29.600800033000041, -1.301651529999958],
                [29.600971, -1.302033],
                [29.601074, -1.302258999999879],
                [29.601163000000156, -1.302921999999853],
                [29.601248, -1.30356],
                [29.601269394000042, -1.303702582999961],
                [29.601412001000028, -1.304653],
                [29.60237700100015, -1.307092999999895],
                [29.602957, -1.308336999999881],
                [29.603043001000174, -1.309277],
                [29.604152190000036, -1.313976624999953],
                [29.605022430000076, -1.317662358999826],
                [29.604704217000119, -1.319221622999976],
                [29.604523000000142, -1.320111],
                [29.604255132000162, -1.321018052999932],
                [29.603986801000076, -1.321926675999976],
                [29.6037970000001, -1.322569999999814],
                [29.603687628000102, -1.322939713999915],
                [29.603563309000037, -1.323361038999963],
                [29.603453425, -1.323943300999929],
                [29.603337, -1.324562],
                [29.603082668000184, -1.325909427999875],
                [29.602615000000185, -1.32792099999989],
                [29.602037000000166, -1.330403999999874],
                [29.601797, -1.331391],
                [29.601641, -1.332030000999964],
                [29.601414001000023, -1.33296000099989],
                [29.600645328000098, -1.335985457999925],
                [29.599962235000191, -1.338675020999972],
                [29.597885132000158, -1.348083019999933],
                [29.597786155000051, -1.348487520999925],
                [29.597539000000154, -1.349499],
                [29.596415, -1.354093],
                [29.595858000000135, -1.356372],
                [29.594719, -1.361238999999898],
                [29.594269120000092, -1.36316044299997],
                [29.593660355000111, -1.365761994999957],
                [29.59184837399999, -1.373455047999926],
                [29.591415405000191, -1.375151990999882],
                [29.590307119000101, -1.379892824999956],
                [29.589567677000048, -1.383057025999904],
                [29.589555297000118, -1.383109655999931],
                [29.589390183000091, -1.383734431999926],
                [29.589172364000092, -1.384559988999911],
                [29.58905325, -1.38511968499995],
                [29.588921050000124, -1.385742765999908],
                [29.589300770000079, -1.385530968999944],
                [29.589727, -1.38529299999982],
                [29.590648323000096, -1.384923805999904],
                [29.591389, -1.384627],
                [29.592766, -1.384295],
                [29.593030965000082, -1.384269002999929],
                [29.59303630300019, -1.38441436599993],
                [29.593101446000162, -1.386188491999917],
                [29.593131993999975, -1.387020822999943],
                [29.592808680000076, -1.386969155999964],
                [29.592788965000125, -1.386966005999966],
                [29.592281931000116, -1.387050640999917],
                [29.592136295000103, -1.387074950999931],
                [29.591157912000085, -1.388452410999946],
                [29.590906849000191, -1.388796399999876],
                [29.590754, -1.389005999999881],
                [29.590376001000152, -1.389524],
                [29.59037055500005, -1.389535683999952],
                [29.589723747000164, -1.390923287999897],
                [29.589027405000138, -1.391645073999939],
                [29.589726959000075, -1.39510879799991],
                [29.589412, -1.395107],
                [29.589346178000085, -1.395106155999883],
                [29.589334487000144, -1.395106077999969],
                [29.583993085000145, -1.396080051999888],
                [29.575161861000026, -1.397690647999923],
                [29.575138, -1.397695],
                [29.566806986000188, -1.406399013999874],
                [29.56512, -1.408161999999834],
                [29.564967456000034, -1.408321104999857],
                [29.564933776000089, -1.408356308999942],
                [29.564669274999972, -1.408647523999946],
                [29.56339300000019, -1.410052999999891],
                [29.555294000000117, -1.41897],
                [29.554874000000154, -1.418482],
                [29.554883000000189, -1.4183],
                [29.554883942000117, -1.418281478999916],
                [29.554885865000188, -1.418241024999929],
                [29.554803849000109, -1.41810047599995],
                [29.554584816000045, -1.417808771999887],
                [29.554445, -1.417622999999878],
                [29.554317, -1.417321],
                [29.55422, -1.417071],
                [29.554199875000052, -1.417018977999931],
                [29.554166794000082, -1.416933893999897],
                [29.554128996000145, -1.416858360999925],
                [29.554064, -1.416729],
                [29.553951, -1.416503],
                [29.553920000000119, -1.416410999999869],
                [29.553895103000059, -1.416336307999927],
                [29.553842544000076, -1.416180132999955],
                [29.553756714000087, -1.415964840999891],
                [29.553643631000057, -1.415754779999929],
                [29.553605999000126, -1.415685],
                [29.553411, -1.415426],
                [29.553341089000071, -1.415276366999876],
                [29.553239823000069, -1.415060400999948],
                [29.553014755000049, -1.414748190999944],
                [29.553100586000141, -1.414618967999957],
                [29.553014755000049, -1.414382098999965],
                [29.552954131000035, -1.414299365999966],
                [29.55285, -1.414157999999873],
                [29.552841006000165, -1.414145008999981],
                [29.552784839000083, -1.414016971999956],
                [29.552722931000062, -1.413876056999925],
                [29.552642943000023, -1.413702757999886],
                [29.552629, -1.413674],
                [29.552572000000112, -1.413553],
                [29.552562, -1.413536],
                [29.552551965000134, -1.413517936999938],
                [29.552541733, -1.413500189999979],
                [29.552421592000087, -1.413294710999878],
                [29.552398, -1.413278],
                [29.552271, -1.413187],
                [29.552229000000182, -1.412929],
                [29.552142192000076, -1.412784765999959],
                [29.552099, -1.412712999999883],
                [29.552034, -1.412433],
                [29.551895, -1.412120999999843],
                [29.551902816000165, -1.412109906999888],
                [29.551925659, -1.412076710999884],
                [29.551784515000065, -1.411890386999858],
                [29.551756000000125, -1.411855],
                [29.551565001000029, -1.411855],
                [29.550064, -1.409598],
                [29.54998, -1.409471000999872],
                [29.547690242000158, -1.406490069999961],
                [29.547333, -1.405981],
                [29.546344630000192, -1.404571878999946],
                [29.546336999000175, -1.404561],
                [29.545595708000064, -1.403443023999898],
                [29.544679642000119, -1.402061938999964],
                [29.544645348000074, -1.402010265999934],
                [29.544609, -1.401955999999871],
                [29.544591205000131, -1.401930423999886],
                [29.544217000000174, -1.402129999999886],
                [29.543676000000175, -1.402417999999841],
                [29.542633, -1.402943],
                [29.541393000000141, -1.403751999999884],
                [29.541351000000191, -1.403782],
                [29.541012001000126, -1.404022],
                [29.540847402000111, -1.404138363999891],
                [29.540739148, -1.404215153999928],
                [29.541152333000184, -1.40464610499987],
                [29.542646, -1.406203998999899],
                [29.5439, -1.407592],
                [29.544758000000172, -1.408542999999895],
                [29.546341, -1.41064],
                [29.546784000000116, -1.411233],
                [29.548334765000106, -1.413311981999868],
                [29.548429001000102, -1.413371999999868],
                [29.548441502000117, -1.413398003999873],
                [29.548454000000106, -1.413424],
                [29.548479, -1.41354699999988],
                [29.548497197000017, -1.413743464999868],
                [29.548524125000142, -1.414034993999849],
                [29.548547774000042, -1.414292164999893],
                [29.548515000000179, -1.414316],
                [29.548290352000038, -1.414477746999978],
                [29.548787309000033, -1.41529905699997],
                [29.549290000000155, -1.416128998999909],
                [29.550032000000101, -1.417541998999923],
                [29.550385001000109, -1.419026000999963],
                [29.55066, -1.420058],
                [29.550710030000062, -1.420246129999953],
                [29.550781250000114, -1.420513033999896],
                [29.550874609000118, -1.420625227999949],
                [29.550962000000141, -1.42073],
                [29.551532247000182, -1.421414693999907],
                [29.551576242000181, -1.421529100999976],
                [29.551584243000036, -1.421550391999858],
                [29.546346665000101, -1.420974134999938],
                [29.540886000000114, -1.420938],
                [29.540950057000146, -1.420908533999864],
                [29.541036330999987, -1.420868648999942],
                [29.541234814000177, -1.420776725999872],
                [29.541178000000116, -1.420693],
                [29.541054000000145, -1.42051],
                [29.540991, -1.420327000999976],
                [29.540930747000175, -1.420123871999977],
                [29.540859000000182, -1.419882],
                [29.540838999000187, -1.419840997999927],
                [29.54081894900014, -1.419799858999966],
                [29.540810000000192, -1.419775],
                [29.540789, -1.419715],
                [29.540780345000144, -1.419680001999893],
                [29.540766000000133, -1.41962199999989],
                [29.540739, -1.419575999999836],
                [29.540718, -1.41954],
                [29.540672000000143, -1.419446999999821],
                [29.540632, -1.419363],
                [29.540615969000044, -1.419333029999962],
                [29.540586000000133, -1.419277],
                [29.540572, -1.419224],
                [29.540566740000145, -1.419202433999942],
                [29.540561676000095, -1.419183373999942],
                [29.540533425000149, -1.419142518999934],
                [29.540514, -1.419115],
                [29.540506000000107, -1.419103],
                [29.540461999000115, -1.419019998999943],
                [29.540443556000071, -1.418962692999969],
                [29.540433883000048, -1.418933391999929],
                [29.54040908900015, -1.418843387999971],
                [29.540394801000048, -1.418817381999929],
                [29.540363000000184, -1.418760000999896],
                [29.540328583000075, -1.418705213999886],
                [29.540314, -1.418682],
                [29.54030500000016, -1.418660999999872],
                [29.540276999000071, -1.418592],
                [29.540239, -1.418502999999873],
                [29.540229, -1.418488],
                [29.540185999000187, -1.418423000999894],
                [29.540177955000047, -1.418399139999963],
                [29.540156999000089, -1.418337000999884],
                [29.540135828000189, -1.418275599999959],
                [29.540126801000042, -1.418249964999973],
                [29.540065766000055, -1.418179988999953],
                [29.540023804000157, -1.41809332299988],
                [29.539978027000188, -1.418003320999901],
                [29.539945602000103, -1.417913316999943],
                [29.539918486000147, -1.417867682999884],
                [29.539912000000129, -1.417857],
                [29.539894, -1.417826999999875],
                [29.539867000000129, -1.417737999999815],
                [29.5398459970001, -1.417694994999977],
                [29.539824624, -1.417651269999908],
                [29.539724, -1.417456],
                [29.539692, -1.417394],
                [29.539682000000141, -1.417373999999882],
                [29.539640765000172, -1.417381740999929],
                [29.539421, -1.417423],
                [29.537602271000083, -1.414415041999916],
                [29.536872665000089, -1.413089876999891],
                [29.536363602000165, -1.412165879999918],
                [29.535163261999969, -1.410058486999901],
                [29.534087619000047, -1.408170537999922],
                [29.534048, -1.408101],
                [29.533571, -1.408246],
                [29.533481009000127, -1.40827325399988],
                [29.533395768000105, -1.408299206999971],
                [29.532548904, -1.408277152999858],
                [29.531723022000108, -1.408102034999956],
                [29.531005859000174, -1.408036231999972],
                [29.530671122000115, -1.408164102999933],
                [29.530376, -1.408277],
                [29.529921, -1.408582999999851],
                [29.529768000000161, -1.409041999999886],
                [29.529692, -1.409723],
                [29.529599712999982, -1.40993812399995],
                [29.52954700000015, -1.410061],
                [29.529408, -1.41023],
                [29.529341000000159, -1.410367],
                [29.529308001, -1.410433000999888],
                [29.529248271000029, -1.410592538999936],
                [29.529232, -1.410635999999897],
                [29.528942, -1.410790999999847],
                [29.527943000000164, -1.410244],
                [29.527700066000136, -1.410081216999913],
                [29.526964000000135, -1.409588000999975],
                [29.526618879000125, -1.40943217399996],
                [29.526335000000131, -1.409304],
                [29.526087, -1.409337999999877],
                [29.525948355000025, -1.409356605999903],
                [29.525856017000024, -1.409369228999879],
                [29.525835861000132, -1.409396257999902],
                [29.525757000000112, -1.409502999999859],
                [29.525530000000174, -1.409807],
                [29.525456, -1.410145],
                [29.52541332100003, -1.410337056999936],
                [29.525398254000095, -1.410405634999847],
                [29.525435559000073, -1.410454356999878],
                [29.525478000000135, -1.410509],
                [29.525508909999985, -1.410549879999962],
                [29.525485999000182, -1.411250005999875],
                [29.525483000000179, -1.41128],
                [29.525377000000105, -1.412256],
                [29.525305000000174, -1.412538999999867],
                [29.525204001000077, -1.41293400099994],
                [29.525105952000047, -1.413362390999964],
                [29.525073895000162, -1.41350222799997],
                [29.524956345000192, -1.413759369999866],
                [29.524834000000112, -1.414027],
                [29.524443, -1.414574],
                [29.524288957000067, -1.414771323999844],
                [29.52416085100009, -1.414811168999961],
                [29.524036407000096, -1.414850115999968],
                [29.524000687000182, -1.414981397999895],
                [29.523993000000189, -1.41501],
                [29.523949, -1.415173],
                [29.523838000000126, -1.415438000999927],
                [29.523811390000049, -1.415500302999931],
                [29.523756000000105, -1.41563],
                [29.523558, -1.415968],
                [29.523493, -1.416274],
                [29.523350000000107, -1.41642],
                [29.523335, -1.416585999999882],
                [29.523291, -1.416892],
                [29.523182, -1.417307],
                [29.522985, -1.417657],
                [29.522724347000121, -1.417906792999929],
                [29.522697382000104, -1.41793263399984],
                [29.522676000000104, -1.417972],
                [29.522635, -1.418047999999885],
                [29.522603000000117, -1.418109],
                [29.522575147000168, -1.418160335999914],
                [29.52255249000018, -1.418203114999926],
                [29.52249531800004, -1.418329352999876],
                [29.522456, -1.418416999999863],
                [29.522425000000112, -1.418485],
                [29.522395000000131, -1.418523],
                [29.522380435000059, -1.418541416999915],
                [29.522274017000143, -1.418676374999905],
                [29.522263190000047, -1.418696309999973],
                [29.522241000000179, -1.418738],
                [29.52215800100015, -1.418891],
                [29.522105835000104, -1.419006426999943],
                [29.522063619000164, -1.419099738999933],
                [29.521976241000175, -1.419269978999978],
                [29.521948001000169, -1.419324998999912],
                [29.521797001000095, -1.419561998999939],
                [29.521698292, -1.419689025999958],
                [29.521552999000164, -1.419876000999977],
                [29.521350822000045, -1.420044480999934],
                [29.521271000000183, -1.420111000999953],
                [29.52118543000006, -1.420155806999844],
                [29.521017075000032, -1.420244097999955],
                [29.520906701000172, -1.420375820999936],
                [29.520853, -1.42044],
                [29.520796000000132, -1.420508],
                [29.520813000000146, -1.420784],
                [29.520809000000156, -1.420828999999856],
                [29.520703690000175, -1.421922941999867],
                [29.520658, -1.422208999999896],
                [29.520595635000177, -1.422599653999896],
                [29.520437000000129, -1.422884],
                [29.52029, -1.423146],
                [29.519958, -1.423490999999842],
                [29.519570617000113, -1.423893667999948],
                [29.519465000000139, -1.424077999999895],
                [29.519409000000167, -1.424175],
                [29.519402142000104, -1.424184484999955],
                [29.519362000000172, -1.424239999999884],
                [29.519182000000171, -1.42448899999988],
                [29.51894, -1.424645],
                [29.518726000000129, -1.424823999999887],
                [29.518448, -1.425039],
                [29.518408, -1.42507],
                [29.518764, -1.425233],
                [29.519747, -1.425683],
                [29.520351000000119, -1.426001],
                [29.520702, -1.426186],
                [29.520825043000173, -1.426263805999952],
                [29.520838001000186, -1.426272],
                [29.520853760000136, -1.426282243999935],
                [29.520878, -1.426298],
                [29.52142, -1.426645],
                [29.521193000000153, -1.427035999999873],
                [29.521192855000038, -1.42724023999989],
                [29.521125, -1.427353],
                [29.52106999900019, -1.427443000999972],
                [29.521019712000168, -1.427487464999842],
                [29.52088, -1.427611],
                [29.520718000000102, -1.427869999999871],
                [29.52065089700011, -1.427999411999906],
                [29.520578, -1.42814],
                [29.520462, -1.428343],
                [29.520310947000098, -1.428546060999963],
                [29.520291258000157, -1.428569135999965],
                [29.520247, -1.42862100099984],
                [29.520201495000094, -1.428674977999947],
                [29.520102001, -1.42879300099986],
                [29.520135486000015, -1.428827501999876],
                [29.520168303000162, -1.428861021999978],
                [29.520179185000188, -1.428872184999932],
                [29.520190000000127, -1.428883],
                [29.520246795000105, -1.428940791999935],
                [29.520153428000071, -1.429075893999823],
                [29.520050001000129, -1.429225000999963],
                [29.519740758000069, -1.429452362999882],
                [29.519626469000116, -1.429536919999862],
                [29.519552, -1.429665999999884],
                [29.519527921000133, -1.429707590999954],
                [29.519464493000044, -1.429817794999906],
                [29.519094468000048, -1.429972051999869],
                [29.518442155000173, -1.430012463999958],
                [29.517768861000036, -1.430340288999901],
                [29.51696395800019, -1.430624484999953],
                [29.516513824000072, -1.43071520399991],
                [29.516500840000049, -1.430717769999944],
                [29.516476000000125, -1.430723],
                [29.51642006899999, -1.43073398599995],
                [29.516048, -1.431216],
                [29.515743, -1.431354],
                [29.515620000000183, -1.431393],
                [29.515345000000138, -1.43148],
                [29.515022, -1.431561],
                [29.514589114000159, -1.43161198699994],
                [29.514375687000154, -1.431711315999905],
                [29.514184952999983, -1.431805372999975],
                [29.514032139000165, -1.431875706999904],
                [29.513958000000116, -1.43191],
                [29.513897, -1.431938],
                [29.513643000000172, -1.432036999999866],
                [29.513407, -1.432118999999887],
                [29.513177340000027, -1.432166347999896],
                [29.512888, -1.432226],
                [29.512473537000176, -1.432222578999927],
                [29.512161151000043, -1.432220001999895],
                [29.511444092000147, -1.432350992999886],
                [29.511381862000178, -1.432366113999933],
                [29.51081500000015, -1.432504],
                [29.510337471000184, -1.432680904999927],
                [29.509989000000189, -1.43281],
                [29.509466, -1.433007],
                [29.508837, -1.433159999999873],
                [29.508511000000112, -1.433291000999873],
                [29.508207112000036, -1.433291000999873],
                [29.508031845000176, -1.433526277999874],
                [29.507991790000119, -1.433533190999924],
                [29.507501602000104, -1.433633445999874],
                [29.507387392000055, -1.433642965999979],
                [29.507093430000054, -1.433673262999889],
                [29.506811142000174, -1.433743475999904],
                [29.50642394900018, -1.433863401999815],
                [29.506335974000081, -1.433908573999929],
                [29.506283000000167, -1.433936],
                [29.506176001000085, -1.433990999999878],
                [29.506165903000124, -1.43399696899985],
                [29.505989023999973, -1.434101530999953],
                [29.505950927000129, -1.434124111999893],
                [29.50574874800003, -1.43422937399987],
                [29.50567632800005, -1.434278087999928],
                [29.505484001000013, -1.434408000999952],
                [29.50538421400006, -1.434524782999915],
                [29.505302001000075, -1.434621000999869],
                [29.505112887000053, -1.434774165999954],
                [29.505060001000118, -1.434817],
                [29.504864000000168, -1.43503],
                [29.504555, -1.436045],
                [29.504469, -1.43692],
                [29.504250001000059, -1.439413000999878],
                [29.504338207000103, -1.439928778999843],
                [29.504380961999971, -1.440178781999919],
                [29.504045, -1.440243999999893],
                [29.503831626000192, -1.440284845999884],
                [29.503694671000119, -1.44031128599994],
                [29.503461670000149, -1.440513211999871],
                [29.503297, -1.440619],
                [29.503220001000102, -1.440668],
                [29.503089436000039, -1.440707081999847],
                [29.502926, -1.440756],
                [29.502678000000117, -1.440798],
                [29.502374819000181, -1.440839909999909],
                [29.502338, -1.440845],
                [29.502321306999988, -1.440846652999937],
                [29.502136, -1.44086499999986],
                [29.501945992000117, -1.440874447999931],
                [29.501774000000125, -1.440883],
                [29.501312, -1.440877],
                [29.50118466400005, -1.440890145999958],
                [29.50097274899997, -1.440912246999858],
                [29.500920191000091, -1.440921499999888],
                [29.500784, -1.440945999999883],
                [29.500702, -1.44096],
                [29.500393169000176, -1.441022349999912],
                [29.500383073000023, -1.441024641999832],
                [29.500367, -1.441029999999898],
                [29.500177000000122, -1.44109],
                [29.500124482000047, -1.441101471999957],
                [29.499884, -1.44115399999987],
                [29.499692000000152, -1.441189],
                [29.499565000000132, -1.441212],
                [29.499468, -1.441223],
                [29.499289, -1.441242999999872],
                [29.49912318, -1.441262368999958],
                [29.498931287000175, -1.441285376999929],
                [29.49881511600006, -1.441309204999925],
                [29.498655320000182, -1.441342471999917],
                [29.498453141000084, -1.441402314999891],
                [29.498355839000169, -1.441427393999959],
                [29.498277000000144, -1.441447999999866],
                [29.498222, -1.441462000999934],
                [29.498110962000055, -1.44150605699997],
                [29.497849, -1.44161],
                [29.497769, -1.441629],
                [29.497641001000034, -1.441659],
                [29.497340935000068, -1.441746027999898],
                [29.497302000000104, -1.441763],
                [29.497150000000147, -1.441828],
                [29.497121078000077, -1.441840096999954],
                [29.496942000000161, -1.441915],
                [29.49683200100003, -1.441961],
                [29.496690994000176, -1.442050003999952],
                [29.496549825000159, -1.442139128999941],
                [29.496452326000167, -1.442211171999929],
                [29.496347001000061, -1.442289],
                [29.496191335000162, -1.442399811999906],
                [29.496111000000155, -1.442457000999866],
                [29.495897246000084, -1.44281516399991],
                [29.49577522300018, -1.443019865999872],
                [29.495405198000185, -1.444463133999932],
                [29.495477289000064, -1.444959106999932],
                [29.495557705000124, -1.445510980999927],
                [29.495458000000156, -1.445721],
                [29.495317000000171, -1.446015999999815],
                [29.494545, -1.445911],
                [29.494343, -1.446072999999899],
                [29.494066, -1.446115],
                [29.493987000000118, -1.446132],
                [29.493945969000151, -1.446140739999919],
                [29.49381828300011, -1.446168302999979],
                [29.493716046000088, -1.446322994999889],
                [29.493645, -1.446431],
                [29.493499062000069, -1.446655034999935],
                [29.493275001000086, -1.446999],
                [29.492493000000138, -1.449274000999822],
                [29.490801826000165, -1.451220233999891],
                [29.490232468000158, -1.45187568599988],
                [29.49018430100017, -1.451932684999974],
                [29.490163, -1.451958],
                [29.489943000000153, -1.452219],
                [29.489536217000193, -1.452699743999915],
                [29.488811494000174, -1.453558921999957],
                [29.488785902000188, -1.4535893599999],
                [29.488749001000031, -1.453634],
                [29.487429000000191, -1.455199],
                [29.486862350000024, -1.455955796999945],
                [29.486082076000116, -1.456998347999956],
                [29.485953965000078, -1.457170285999894],
                [29.48586500100015, -1.457289998999954],
                [29.490699958000107, -1.457949993999932],
                [29.49247, -1.457864998999924],
                [29.493361000000164, -1.457972999999868],
                [29.494225, -1.458078],
                [29.49754510300005, -1.458476612999903],
                [29.50017121000019, -1.458791904999941],
                [29.500219175000154, -1.458796043999939],
                [29.501664950000134, -1.458919993999928],
                [29.488270000000114, -1.473957],
                [29.487198844000147, -1.47515941599994],
                [29.487151274000098, -1.475212817999818],
                [29.48685, -1.47555199999988],
                [29.485939000000144, -1.476574],
                [29.473057, -1.474652],
                [29.472864000000129, -1.474944],
                [29.469452000000103, -1.479339000999971],
                [29.467915354000127, -1.481510532999948],
                [29.466930000000161, -1.482903000999897],
                [29.465864, -1.484258000999944],
                [29.464884777000123, -1.485527438999952],
                [29.4644330160001, -1.486113089999947],
                [29.464426190000097, -1.486122716999944],
                [29.463416918000064, -1.487637387999939],
                [29.463104249000025, -1.488106727999934],
                [29.461582183000075, -1.490140198999882],
                [29.461016661000144, -1.491034068999966],
                [29.460999, -1.491062],
                [29.460213000000181, -1.492304999999874],
                [29.458873818000029, -1.493913217999875],
                [29.458544715000073, -1.494307682999931],
                [29.458300000000179, -1.494601000999865],
                [29.458064884000066, -1.494933910999976],
                [29.45749700000016, -1.495738],
                [29.456770188000064, -1.496564785999965],
                [29.458040494000045, -1.4967775369999],
                [29.458383476000165, -1.49683491299993],
                [29.458508487000131, -1.496855747999973],
                [29.458533935000105, -1.496859989999962],
                [29.458698, -1.496887],
                [29.463016629000094, -1.49761009599996],
                [29.463028001000112, -1.497611998999957],
                [29.46837869400008, -1.498419200999933],
                [29.46908, -1.498525],
                [29.472664000000123, -1.498761999999886],
                [29.474246901000072, -1.498794187999977],
                [29.472002, -1.503062],
                [29.466736, -1.513072],
                [29.461712608000084, -1.510970506999968],
                [29.452503001000082, -1.507118000999981],
                [29.451831951000145, -1.506837126999926],
                [29.450031877000072, -1.50608511199988],
                [29.449923000000183, -1.506089],
                [29.449851742000192, -1.506091064999851],
                [29.449785233000057, -1.506093381999961],
                [29.447773999000106, -1.506162517999883],
                [29.446574, -1.506204],
                [29.444712000000152, -1.506268],
                [29.432455565000112, -1.506689268999935],
                [29.426469899000097, -1.506895002999841],
                [29.425919, -1.506913999999881],
                [29.400247965000119, -1.507796258999974],
                [29.396502034000036, -1.507925014999955],
                [29.396637023000153, -1.507985550999933],
                [29.396695905, -1.508011957999884],
                [29.396677298000156, -1.50801158399986],
                [29.396470533000013, -1.50800741199987],
                [29.395614624000189, -1.50799024],
                [29.394843407000053, -1.507998414999918],
                [29.394423000000188, -1.50800299999986],
                [29.394129572000054, -1.507999011999971],
                [29.393908005000128, -1.507996088999903],
                [29.394003, -1.509807],
                [29.394051000000104, -1.510709],
                [29.394082499000149, -1.511861464999811],
                [29.394114001000105, -1.513014],
                [29.394128059000025, -1.513513086999865],
                [29.394137831000137, -1.513860035999869],
                [29.394173398000135, -1.515132821999941],
                [29.39419, -1.515727],
                [29.394193000000143, -1.515825999999834],
                [29.394195733000117, -1.515931666999961],
                [29.39419937100007, -1.516058087999852],
                [29.394227988000182, -1.516326883999966],
                [29.394228, -1.516413999999884],
                [29.394226287000151, -1.516681274999883],
                [29.394226074000017, -1.51672577699992],
                [29.394222259000173, -1.517611503999945],
                [29.394242451000082, -1.517993431999912],
                [29.394265, -1.518413999999893],
                [29.394292990000054, -1.51894688699997],
                [29.394289422000156, -1.519316521999883],
                [29.39427947900009, -1.520395873999917],
                [29.394288222000057, -1.52049375799993],
                [29.394293, -1.520545],
                [29.394325039000137, -1.520896473999869],
                [29.39436000000012, -1.521279999999877],
                [29.394365000000164, -1.521784999999852],
                [29.394367000000159, -1.522051],
                [29.394371000000149, -1.522551000999897],
                [29.394372, -1.522641],
                [29.394373000000144, -1.522813],
                [29.397890000000132, -1.522780999999839],
                [29.397928, -1.524038],
                [29.398033, -1.526835999999889],
                [29.398043189000134, -1.527414221999948],
                [29.398068998000042, -1.528878803999874],
                [29.395593306000023, -1.528758006999908],
                [29.39461113200008, -1.528710082999965],
                [29.394548417000181, -1.52870702599995],
                [29.394489161000024, -1.528704138999899],
                [29.390940274000116, -1.528531519999945],
                [29.390909000000192, -1.528529999999876],
                [29.387532170000043, -1.528365162999933],
                [29.387283, -1.528353],
                [29.387283, -1.528312],
                [29.387264998000148, -1.526926881999941],
                [29.387247, -1.525542000999849],
                [29.38724494700017, -1.525365172999955],
                [29.387235644000043, -1.524593978999917],
                [29.387123055000018, -1.524587276999966],
                [29.386984000000155, -1.524579000999836],
                [29.386354, -1.524643],
                [29.386035000000163, -1.524616],
                [29.385933, -1.524607000999936],
                [29.38524800100015, -1.524670999999898],
                [29.384137777000149, -1.52467026599993],
                [29.383733360000178, -1.5246700269999],
                [29.383635538000078, -1.524673813999868],
                [29.38364196200007, -1.523480671999892],
                [29.383624000000168, -1.523325999999884],
                [29.383593, -1.523049],
                [29.38355, -1.522368999999856],
                [29.383541, -1.522224],
                [29.383541, -1.52041],
                [29.383453, -1.517756998999971],
                [29.383453, -1.516564],
                [29.383418999000185, -1.515857973999971],
                [29.383414000000187, -1.515748999999858],
                [29.383401999000171, -1.515473],
                [29.383392501000117, -1.515278016999957],
                [29.383383, -1.515082999999891],
                [29.383398000000113, -1.514306],
                [29.383415000000127, -1.51343],
                [29.383312000000103, -1.511983],
                [29.383249, -1.509648],
                [29.383248655000102, -1.50962734899997],
                [29.383228, -1.508392],
                [29.382531000000142, -1.508382],
                [29.38239027000003, -1.508379530999946],
                [29.382360458000051, -1.508379100999889],
                [29.381711996000035, -1.508396256999959],
                [29.380445, -1.50843],
                [29.379835000000185, -1.508468],
                [29.379752, -1.508468],
                [29.379638753000052, -1.508467390999897],
                [29.379566192000141, -1.508467197999892],
                [29.379571237000107, -1.508537704999867],
                [29.37958, -1.508656],
                [29.379595, -1.508867],
                [29.379673, -1.50925199999989],
                [29.379673005000029, -1.510112284999934],
                [29.379673005000029, -1.510601639999948],
                [29.379673005000029, -1.512621759999831],
                [29.379690119000031, -1.51275552899989],
                [29.379738000000145, -1.513128999999879],
                [29.37974000000014, -1.513471],
                [29.379745000000185, -1.514628],
                [29.379727327000069, -1.515054188999898],
                [29.379719, -1.5152550009999],
                [29.379731349000053, -1.515545612999972],
                [29.379734001000088, -1.515608],
                [29.379774000000111, -1.516562],
                [29.379829, -1.517954],
                [29.379911000000106, -1.518389],
                [29.379935000000103, -1.519843],
                [29.379944000000137, -1.520381],
                [29.379950000000122, -1.520737999999824],
                [29.37995029700005, -1.520754702999966],
                [29.379967, -1.521695],
                [29.379979, -1.522428],
                [29.379981625000141, -1.522589917999937],
                [29.379995347000033, -1.523414967999884],
                [29.380029363000176, -1.523846481999954],
                [29.380074, -1.524406999999883],
                [29.380074, -1.524535998999909],
                [29.380074, -1.524602],
                [29.380073808000077, -1.52470623399995],
                [29.379975044000048, -1.524711246999857],
                [29.379116, -1.524754000999906],
                [29.377915999000152, -1.524814000999925],
                [29.376968999000098, -1.524861492999946],
                [29.376918955000065, -1.524863997999944],
                [29.376795000000186, -1.524858],
                [29.376433997000049, -1.524839912999937],
                [29.376415000000179, -1.524227],
                [29.376413000000184, -1.524144],
                [29.37645300000014, -1.523407999999847],
                [29.376419731000112, -1.52260821099992],
                [29.376403809000067, -1.522230029999889],
                [29.376365662000183, -1.521337507999931],
                [29.37629890400018, -1.519775747999972],
                [29.376295887000026, -1.519702313999915],
                [29.37628100000012, -1.51935],
                [29.376253000000133, -1.518661999999893],
                [29.376252762000092, -1.518641478999882],
                [29.37623200000013, -1.516848],
                [29.37623200000013, -1.516745999999898],
                [29.376230000000135, -1.516604],
                [29.376224998999987, -1.51611899999989],
                [29.376214536000134, -1.515191140999946],
                [29.376213000000178, -1.515055],
                [29.376210000000128, -1.514818],
                [29.376204399000073, -1.51429938299998],
                [29.376199722000138, -1.513891816999944],
                [29.376157790000036, -1.513475176999918],
                [29.376136, -1.51326],
                [29.376133000000152, -1.513229],
                [29.376049, -1.512404],
                [29.376061, -1.512384],
                [29.376090220000151, -1.512335705999874],
                [29.376132917, -1.512262666999959],
                [29.376132, -1.512237],
                [29.376127000000167, -1.512012],
                [29.376115610000113, -1.511919243999955],
                [29.376098995000063, -1.511783952999963],
                [29.376081616000135, -1.511622844999977],
                [29.376062000000104, -1.511441],
                [29.37607, -1.510940998999956],
                [29.376062000000104, -1.510242993999896],
                [29.376057633000187, -1.5101444399999],
                [29.376054951000128, -1.5100797529999],
                [29.376049897000087, -1.509548806999931],
                [29.376041003000068, -1.508614301999899],
                [29.374320984000121, -1.508614301999899],
                [29.37403639900009, -1.508621668999979],
                [29.373653000000104, -1.508632],
                [29.373220000000174, -1.508643],
                [29.373015, -1.508636],
                [29.372889730000168, -1.508631546999879],
                [29.372592926000152, -1.508621454999968],
                [29.372430801000121, -1.508632062999936],
                [29.371065139000109, -1.508694052999942],
                [29.371038873000089, -1.508695333999924],
                [29.370830000000126, -1.508705999999847],
                [29.370188, -1.508737],
                [29.369976353000084, -1.508748388999891],
                [29.369957557000134, -1.50874939299996],
                [29.369795000000124, -1.508757999999887],
                [29.368723, -1.508800999999892],
                [29.368459378000068, -1.508807868999838],
                [29.368384, -1.508791999999858],
                [29.368360662999976, -1.50878699499998],
                [29.367910000000109, -1.50878],
                [29.367432000000179, -1.508737],
                [29.36671100000018, -1.509308],
                [29.365775533000146, -1.510007474999952],
                [29.364839067000105, -1.510707657999888],
                [29.364406, -1.511013],
                [29.36409, -1.511235],
                [29.363491, -1.51170599999989],
                [29.362724488000026, -1.512245194999935],
                [29.362648, -1.51229900099986],
                [29.362564341, -1.512302385999874],
                [29.361954537000031, -1.512326763999852],
                [29.361504249000063, -1.512254347999885],
                [29.361471, -1.512248999999883],
                [29.361049809000122, -1.512184833999925],
                [29.360721948000105, -1.511828215999969],
                [29.360647470000117, -1.512133893999874],
                [29.360559, -1.512496999999883],
                [29.360479222000095, -1.512743103999924],
                [29.360394, -1.513006],
                [29.360413315000017, -1.513147640999932],
                [29.36043, -1.51327],
                [29.36043, -1.513441],
                [29.360386000000176, -1.51357],
                [29.360144, -1.514382999999896],
                [29.360036852000064, -1.514633477999837],
                [29.360002718000089, -1.514743169999917],
                [29.359973907000096, -1.514748453999914],
                [29.359803496000097, -1.515061172999879],
                [29.359631, -1.515378000999874],
                [29.359545, -1.51563],
                [29.359464180000089, -1.515782694999871],
                [29.359451, -1.515825999999834],
                [29.359426000000155, -1.515908],
                [29.359378000000106, -1.516021],
                [29.359373005000123, -1.516031988999885],
                [29.359359664000181, -1.51604800299998],
                [29.35929300000015, -1.516128],
                [29.359267000000159, -1.516242],
                [29.359251023000127, -1.516309974999899],
                [29.35925200000014, -1.516324999999881],
                [29.35925698900013, -1.516471669999874],
                [29.359269500000153, -1.516567500999884],
                [29.359282000000121, -1.51666299999988],
                [29.359280000000126, -1.516693],
                [29.359261000000174, -1.516876],
                [29.359251, -1.516958999999872],
                [29.359220903000164, -1.517252448999898],
                [29.35920700000014, -1.517388],
                [29.359009000000128, -1.517841],
                [29.359005648000164, -1.517853055999865],
                [29.358899953000048, -1.518233313999872],
                [29.358753204000095, -1.518761991999838],
                [29.358507156000144, -1.519554376999963],
                [29.358407280000051, -1.519961487999979],
                [29.358368, -1.520122],
                [29.358361000000116, -1.520165],
                [29.358341, -1.520277],
                [29.358093000000167, -1.521187],
                [29.358027996000089, -1.521381343999849],
                [29.357996000000128, -1.521477],
                [29.357890227000155, -1.521866245999888],
                [29.357746001000123, -1.522396998999966],
                [29.357479001000058, -1.523328998999887],
                [29.357389001000115, -1.52363],
                [29.357343082000114, -1.5237641499999],
                [29.357338, -1.523778999999877],
                [29.357202768000036, -1.524107223999977],
                [29.357151068, -1.524157371999934],
                [29.357153, -1.524168],
                [29.357158960000163, -1.524205590999941],
                [29.357166290000066, -1.524250149999887],
                [29.357084273999988, -1.524525522999852],
                [29.357236160000127, -1.524562021999941],
                [29.357329, -1.524583999999891],
                [29.357441, -1.524610999999823],
                [29.357465909000041, -1.524741523999978],
                [29.357517935000089, -1.524739638999961],
                [29.357554000000164, -1.524738],
                [29.357634000000189, -1.524735],
                [29.357713999000168, -1.524735],
                [29.357744860000139, -1.524725855999975],
                [29.357849, -1.524695],
                [29.357897500000149, -1.524679],
                [29.357946, -1.524662999999862],
                [29.358103, -1.524662999999862],
                [29.358227, -1.524691],
                [29.358353000000193, -1.524715],
                [29.358568, -1.524711],
                [29.358702000000108, -1.524735],
                [29.359112, -1.524859999999819],
                [29.359137810000107, -1.524868295999966],
                [29.359155023000028, -1.524840878999896],
                [29.359178542000109, -1.524795532999974],
                [29.359248725000157, -1.524627663999866],
                [29.359319, -1.52459],
                [29.359375000000171, -1.524561],
                [29.359690000000114, -1.524661],
                [29.359752005000189, -1.524678000999927],
                [29.359814, -1.524695],
                [29.359878638000112, -1.524735697999915],
                [29.359949001000189, -1.524779998999861],
                [29.360048427000038, -1.524857567999959],
                [29.360089957000127, -1.524889966999979],
                [29.360196, -1.52493],
                [29.360241956000152, -1.524947570999871],
                [29.360297000000116, -1.524972],
                [29.36026800000019, -1.52502900099995],
                [29.360199000000136, -1.525165999999899],
                [29.3599140070001, -1.525784633999933],
                [29.359892001000048, -1.525843033999877],
                [29.359863, -1.52592],
                [29.359842000000128, -1.525975],
                [29.35983515900017, -1.525992672999905],
                [29.359818, -1.52603699999986],
                [29.359834207000063, -1.526057526999978],
                [29.359847997000145, -1.526074996999967],
                [29.359849000000168, -1.526088999999899],
                [29.359854, -1.526159999999891],
                [29.359854, -1.52661],
                [29.359888425000122, -1.526718681999967],
                [29.359924000000149, -1.526830998999913],
                [29.35997, -1.527011],
                [29.359997000000135, -1.527117],
                [29.360104, -1.527316999999869],
                [29.360115681000082, -1.527352635999932],
                [29.360163001000103, -1.527496998999936],
                [29.360194, -1.527589],
                [29.360239, -1.527724],
                [29.36026100000015, -1.527806],
                [29.360309905000179, -1.527994634999914],
                [29.360411000000113, -1.52812299999988],
                [29.360554, -1.528251999999895],
                [29.360568424000178, -1.528268024999875],
                [29.360689, -1.528401998999868],
                [29.36076700100017, -1.528530000999979],
                [29.360895, -1.52878],
                [29.360919, -1.528848],
                [29.360944884000048, -1.528922665999971],
                [29.360989000000131, -1.529108],
                [29.361109000000113, -1.529286999999897],
                [29.361210000000142, -1.529458],
                [29.36127617000011, -1.529588131999958],
                [29.361329743000169, -1.529693495999879],
                [29.361336962000166, -1.529708994999908],
                [29.361343000000147, -1.529721998999946],
                [29.361372114000062, -1.529784594999967],
                [29.361402952000105, -1.529850898999939],
                [29.361421000000178, -1.529900999999825],
                [29.361444473000063, -1.529964922999966],
                [29.361491152000156, -1.530079984999873],
                [29.361501, -1.530104],
                [29.361523, -1.530157999999858],
                [29.361584003000075, -1.530290007999952],
                [29.361645, -1.530421998999941],
                [29.36173100100018, -1.530656998999916],
                [29.361784, -1.530764],
                [29.361836894000135, -1.530871783999942],
                [29.361917000000176, -1.53106],
                [29.361931, -1.531093],
                [29.36193400000019, -1.531106],
                [29.361937136000108, -1.531122201999949],
                [29.361940383999979, -1.531137346999969],
                [29.361881500000095, -1.531164487999888],
                [29.361857, -1.531176],
                [29.361818, -1.531194],
                [29.361691000000121, -1.531208],
                [29.361626004000072, -1.531204300999832],
                [29.361568, -1.531200999999896],
                [29.361454, -1.531144],
                [29.361368, -1.531137],
                [29.361333000000172, -1.531128],
                [29.361310938000145, -1.531121969999958],
                [29.36128, -1.531107],
                [29.361269005000111, -1.53110100299989],
                [29.361234, -1.531111999999837],
                [29.361194622000085, -1.531123541999875],
                [29.361173465000036, -1.531130749999875],
                [29.361134, -1.531157999999891],
                [29.361048, -1.531215],
                [29.360947, -1.531294],
                [29.360897198000032, -1.531316604999972],
                [29.360805424000034, -1.531359016999943],
                [29.360669000000144, -1.5316],
                [29.360653, -1.531627999999898],
                [29.360441, -1.531944999999837],
                [29.360245284000086, -1.531932473999973],
                [29.360191, -1.531929],
                [29.35999900000013, -1.531972],
                [29.359724, -1.531986],
                [29.359497001000079, -1.531964000999892],
                [29.35939865500012, -1.531968001999871],
                [29.359325028000114, -1.531970998999952],
                [29.359142, -1.532043],
                [29.359043, -1.53206],
                [29.358926808000035, -1.532079019999912],
                [29.358849958000064, -1.53208736199997],
                [29.358669, -1.532106999999826],
                [29.358385000000169, -1.532],
                [29.358355300000028, -1.53199469599997],
                [29.358189, -1.531965],
                [29.358094508000136, -1.53193250299995],
                [29.358000000000118, -1.5319],
                [29.357848774000047, -1.531835942999976],
                [29.357821196000032, -1.531829170999913],
                [29.357735, -1.531808],
                [29.357582133000108, -1.531771351999964],
                [29.357463837000068, -1.531743287999916],
                [29.357324197000082, -1.531723446999877],
                [29.357218853000063, -1.531708595999817],
                [29.357178000000147, -1.53170299999988],
                [29.357173, -1.53165399999989],
                [29.357162, -1.531543],
                [29.357156379, -1.531457191999948],
                [29.357147010000062, -1.531314150999947],
                [29.355815887000119, -1.531425355999829],
                [29.355880733000163, -1.531025316999944],
                [29.355872184000077, -1.531035483999972],
                [29.355770000000177, -1.531156999999894],
                [29.355646, -1.531151],
                [29.355626326000163, -1.531149899999946],
                [29.355553000000157, -1.531139],
                [29.355420095000056, -1.531118938999953],
                [29.355340957000124, -1.531107067999869],
                [29.355289027000083, -1.531112087999929],
                [29.355198, -1.531121000999974],
                [29.355161195000164, -1.531248298999913],
                [29.355089188000136, -1.53149819399988],
                [29.354996137000171, -1.531825429999969],
                [29.354954000000134, -1.531974],
                [29.354929833000028, -1.532058584999902],
                [29.354902267000057, -1.532155512999907],
                [29.354889411000045, -1.532200448999902],
                [29.354852564999987, -1.532330521999938],
                [29.354813, -1.532469],
                [29.354755000000182, -1.532671],
                [29.354738, -1.532909],
                [29.354701914000088, -1.532974152999884],
                [29.354618073000154, -1.533123254999964],
                [29.354589703000102, -1.533217913999977],
                [29.354522, -1.533445],
                [29.354460001000064, -1.533651000999953],
                [29.354422243000101, -1.533780613999909],
                [29.354393, -1.533881],
                [29.354381812000042, -1.533918095999923],
                [29.354374000000121, -1.533944],
                [29.354356770000095, -1.534000612999932],
                [29.354332, -1.534081999999899],
                [29.354296, -1.534206],
                [29.354249482000114, -1.534359195999912],
                [29.354221344000109, -1.534452675999944],
                [29.354139328000088, -1.534734248999939],
                [29.354076385000099, -1.534943817999874],
                [29.353981056000123, -1.535252210999829],
                [29.353988319999985, -1.535279680999963],
                [29.354036001000111, -1.535460000999876],
                [29.354044000000158, -1.535549],
                [29.354052000000138, -1.535643],
                [29.353986230000032, -1.53576442099984],
                [29.353935, -1.535859],
                [29.35368300000016, -1.536071999999876],
                [29.353560967000078, -1.536176048999948],
                [29.353539446000184, -1.536208988999931],
                [29.353512, -1.536251],
                [29.353514000000189, -1.536307],
                [29.353514408000024, -1.536318740999946],
                [29.353519439000024, -1.53645062399994],
                [29.353626252000083, -1.536723971999834],
                [29.353216172000089, -1.537032008999915],
                [29.353141824000147, -1.537080882999874],
                [29.352911, -1.537233],
                [29.352579000000105, -1.537451000999965],
                [29.351788, -1.537936000999935],
                [29.351459490000025, -1.538160006999874],
                [29.35113100000018, -1.538384],
                [29.350884024000038, -1.538521986999911],
                [29.350636706000159, -1.538660186999891],
                [29.350425, -1.538795],
                [29.349925984000151, -1.539110703999938],
                [29.34944534300007, -1.539415477999967],
                [29.349308000000178, -1.539542],
                [29.349294786000144, -1.539554147999979],
                [29.348670959000117, -1.539984226999934],
                [29.348646332000158, -1.540001266999923],
                [29.34859100000017, -1.54004],
                [29.348555, -1.540110999999854],
                [29.348497, -1.540223],
                [29.348255000000108, -1.540273],
                [29.348123, -1.540372],
                [29.348051000000112, -1.540426],
                [29.347939465000024, -1.540509771999894],
                [29.347822000000122, -1.540598],
                [29.347633411000061, -1.540711699999974],
                [29.347132001000148, -1.541014000999951],
                [29.34702531400012, -1.541081838999958],
                [29.346774595000056, -1.541241260999925],
                [29.346764000000178, -1.541248],
                [29.346434, -1.541518],
                [29.346306000000141, -1.541622],
                [29.34621506600007, -1.541685137999878],
                [29.346041, -1.541806],
                [29.345798000000173, -1.541905999999869],
                [29.345461, -1.542061999999874],
                [29.34538687700018, -1.542096210999887],
                [29.34533119300005, -1.542122006999875],
                [29.345329655000057, -1.542145541999958],
                [29.345329000000106, -1.542157],
                [29.345323787000098, -1.542239109999969],
                [29.345104068000126, -1.542353458999855],
                [29.344957351000176, -1.54243016199996],
                [29.344860762999986, -1.542491951999921],
                [29.344797000000142, -1.542532999999878],
                [29.344658001000084, -1.542622],
                [29.344515894000153, -1.542702210999948],
                [29.34443283100012, -1.542749285999832],
                [29.344249725000054, -1.542866466999953],
                [29.343982588000074, -1.543023561999974],
                [29.343768, -1.54315],
                [29.343117, -1.543533],
                [29.342608491000192, -1.543918506999944],
                [29.342099702000098, -1.544304125999872],
                [29.341791000000114, -1.544434],
                [29.341375000000141, -1.544607999999869],
                [29.34106, -1.544724],
                [29.340805, -1.54481799999985],
                [29.340395, -1.545074999999883],
                [29.340092, -1.545074999999883],
                [29.339844000000141, -1.545132],
                [29.339434000000153, -1.545646],
                [29.339310547000139, -1.545669573999874],
                [29.33923503, -1.545683994999934],
                [29.338987001000191, -1.545931],
                [29.33879227500006, -1.546038326999906],
                [29.338606, -1.546141000999967],
                [29.338275709000129, -1.546262200999877],
                [29.338140488000079, -1.546311973999934],
                [29.338063420000083, -1.54635158799988],
                [29.338022, -1.546373],
                [29.337826000000121, -1.546473999999819],
                [29.337764183000104, -1.546522937999953],
                [29.337730407000151, -1.546549916999879],
                [29.336702346000095, -1.547196984999971],
                [29.336669922000056, -1.547244429999978],
                [29.336599349000153, -1.54736757299986],
                [29.336391449000189, -1.547758340999963],
                [29.336267471000042, -1.548047422999957],
                [29.336063386000149, -1.548390150999978],
                [29.335821152000165, -1.548930285999916],
                [29.335810371999969, -1.548966357999973],
                [29.335802000000172, -1.548995],
                [29.33577200000019, -1.549096000999953],
                [29.335583001000089, -1.549508998999954],
                [29.33555429300003, -1.549626810999826],
                [29.335506, -1.549824999999885],
                [29.335438, -1.550088],
                [29.335432000000139, -1.550109],
                [29.335352, -1.550307],
                [29.33532, -1.550553],
                [29.335338213000114, -1.550691705999896],
                [29.335345774000075, -1.550751660999879],
                [29.335278000000187, -1.550949999999887],
                [29.335190998000087, -1.551204838999922],
                [29.335080000000119, -1.55163],
                [29.335052364000148, -1.551735477999955],
                [29.335020065000037, -1.551859378999893],
                [29.334999084000174, -1.552127003999942],
                [29.335001, -1.55214599999988],
                [29.335008965000043, -1.552233609999973],
                [29.335020499000109, -1.552327992999949],
                [29.335032, -1.552422],
                [29.335089000000153, -1.552625],
                [29.335093625000127, -1.552668941999912],
                [29.335133, -1.553043],
                [29.335167352999974, -1.553400998999905],
                [29.335171, -1.5534390099999],
                [29.335175226000047, -1.55361222099998],
                [29.335180282000067, -1.55380809199994],
                [29.335213225000075, -1.553967201999967],
                [29.335227983000038, -1.554037918999939],
                [29.335227436000082, -1.55406521499998],
                [29.335224001000086, -1.554231],
                [29.335203165000109, -1.554361519999873],
                [29.335171, -1.554563],
                [29.335111, -1.554661],
                [29.335092720000148, -1.554690770999969],
                [29.335041193000052, -1.554775115999917],
                [29.335238, -1.554892999999879],
                [29.335356, -1.554964],
                [29.335426000000155, -1.555014],
                [29.335489572000085, -1.555060289999972],
                [29.335529326000028, -1.555088876999946],
                [29.335947036000107, -1.555238841999937],
                [29.336039004999975, -1.555289761999973],
                [29.336092, -1.555318999999884],
                [29.336338000000126, -1.555455],
                [29.336505000000102, -1.555582],
                [29.33661300000017, -1.555664],
                [29.336727000000167, -1.555769999999882],
                [29.336863, -1.555897],
                [29.336908, -1.555967],
                [29.336915857000065, -1.555979570999909],
                [29.33695125100013, -1.556032613999946],
                [29.337032329000124, -1.556153680999955],
                [29.337043953000034, -1.556171038999935],
                [29.336148489000152, -1.556905447999952],
                [29.33579300100007, -1.557196998999928],
                [29.335186915000179, -1.558605476999958],
                [29.334663, -1.559823],
                [29.334631813000101, -1.559811429999968],
                [29.334496952000052, -1.559744981999813],
                [29.334436838000045, -1.559723388999885],
                [29.334394455000165, -1.559708475999969],
                [29.334295102000056, -1.559676393999894],
                [29.334197768000138, -1.559645246999821],
                [29.333963, -1.559566],
                [29.333906788000036, -1.559546969999929],
                [29.3337709810001, -1.55950124799989],
                [29.333766202000163, -1.559564986999931],
                [29.333765031000155, -1.559581397999864],
                [29.333761216000084, -1.559635042999958],
                [29.333717346000185, -1.559768795999901],
                [29.333717346000185, -1.559913395999956],
                [29.333716070000094, -1.559951594999916],
                [29.333715000000154, -1.559996],
                [29.333711864000065, -1.560085928999968],
                [29.333694, -1.560208],
                [29.333675, -1.560336],
                [29.333644000000163, -1.560456],
                [29.333626001000027, -1.560524000999976],
                [29.333521457000074, -1.560654993999947],
                [29.33346, -1.560732000999963],
                [29.333181, -1.560952],
                [29.333038161000047, -1.561053892999951],
                [29.33302100100002, -1.561133999999811],
                [29.332958001000179, -1.561289],
                [29.332817489000092, -1.561355201999902],
                [29.332802000000129, -1.561429],
                [29.332802000000129, -1.561552],
                [29.332695000000172, -1.561690999999883],
                [29.332639419000088, -1.561746580999966],
                [29.332588196000188, -1.561797976999856],
                [29.332585911000081, -1.56182683399993],
                [29.332583000000113, -1.561867],
                [29.332577001000061, -1.56194199],
                [29.332621, -1.562173],
                [29.332566000000156, -1.562381],
                [29.332549006000136, -1.56244462899997],
                [29.332523345000084, -1.562542080999947],
                [29.332466544000056, -1.56276076599994],
                [29.332427000000166, -1.562914],
                [29.332390001000078, -1.56305600099995],
                [29.332386384000188, -1.563109328999929],
                [29.332377865000126, -1.563233500999957],
                [29.332347361000075, -1.563347731999897],
                [29.332330905000163, -1.563409189999959],
                [29.332315000000165, -1.563440999999898],
                [29.332233815000109, -1.5636024099999],
                [29.332131, -1.563638],
                [29.332064000000173, -1.5636610009999],
                [29.331966967000028, -1.563768007999954],
                [29.331957, -1.563778999999897],
                [29.331907000000172, -1.563829],
                [29.331799493000062, -1.563934470999868],
                [29.3317, -1.564026999999896],
                [29.331661001000157, -1.564062999999862],
                [29.331583998000042, -1.564140501999873],
                [29.331507000000101, -1.564217999999869],
                [29.331498980000163, -1.564249783999969],
                [29.331480017000104, -1.564324941999928],
                [29.331495000000189, -1.564453],
                [29.331493, -1.564502],
                [29.33149, -1.564571],
                [29.331433000000175, -1.564713],
                [29.331421000999967, -1.5647420009999],
                [29.331287, -1.565112],
                [29.331174953000072, -1.565347072999941],
                [29.33113239700009, -1.565412880999816],
                [29.331089100000156, -1.565479732999904],
                [29.331077868000136, -1.565497075999929],
                [29.331045, -1.565515999999889],
                [29.33093478100011, -1.565578117999962],
                [29.3307382160001, -1.565684369999872],
                [29.330636992000052, -1.565739082999869],
                [29.3305, -1.565812999999878],
                [29.33041990300012, -1.565856026999882],
                [29.330402407000065, -1.565861118999976],
                [29.330200196000135, -1.565920351999978],
                [29.330039979000105, -1.566134451999915],
                [29.32970237800015, -1.566562771999941],
                [29.329678456000124, -1.566624474999969],
                [29.329666, -1.566657],
                [29.329631910000046, -1.566745073999925],
                [29.329572139000163, -1.566794432999927],
                [29.329477310000186, -1.566873311999927],
                [29.329422054000077, -1.566959457999872],
                [29.329409, -1.566980000999877],
                [29.329285, -1.567145999999866],
                [29.329161001000045, -1.56735],
                [29.329139998000187, -1.567425005999951],
                [29.329119, -1.5675],
                [29.329099816000053, -1.567630892999944],
                [29.328900186000055, -1.567473217999918],
                [29.328790999000148, -1.567386999999883],
                [29.32852350800016, -1.567163392999873],
                [29.328407001000187, -1.567066000999887],
                [29.328331040000023, -1.567017529999873],
                [29.328197000000159, -1.566931999999895],
                [29.328121, -1.566879],
                [29.328006164000158, -1.566798032999941],
                [29.327903747000107, -1.566726207999921],
                [29.327828522, -1.566673340999898],
                [29.327732, -1.566606],
                [29.32762, -1.566504],
                [29.327608108000106, -1.566493153999943],
                [29.327383042000122, -1.5667431359999],
                [29.327323883000076, -1.566825269999868],
                [29.327143000000149, -1.567077],
                [29.327024026000061, -1.567217416999824],
                [29.326971000000128, -1.567280000999915],
                [29.326763000000142, -1.567526],
                [29.326655999000081, -1.567660000999922],
                [29.326589000000126, -1.567727999999875],
                [29.326544000000126, -1.567772999999875],
                [29.326393052000071, -1.568013916999973],
                [29.326330185000131, -1.568222402999936],
                [29.326303482000128, -1.568913101999954],
                [29.326286316000164, -1.569127201999834],
                [29.326185226000121, -1.569710849999979],
                [29.32620048400014, -1.569768069999896],
                [29.326324462000116, -1.57021939699996],
                [29.326452256000039, -1.57060491999988],
                [29.32653312900004, -1.570862703999978],
                [29.326537, -1.570875],
                [29.326563068000098, -1.570957859999965],
                [29.326560390000054, -1.571078882999927],
                [29.32655300000016, -1.571262999999874],
                [29.326452000000188, -1.571911],
                [29.326413873000149, -1.57196922199995],
                [29.326378, -1.572023999999885],
                [29.326363172000129, -1.572035066999888],
                [29.325553658000103, -1.572639295999977],
                [29.325195312000119, -1.572906969999906],
                [29.325574875000143, -1.573019504999934],
                [29.325880051000183, -1.57315862199988],
                [29.326021132000051, -1.573218349999934],
                [29.326182, -1.573286],
                [29.326360608000073, -1.573361832999979],
                [29.326616461000185, -1.573475726999902],
                [29.326662000000113, -1.573496],
                [29.326781, -1.57355299999989],
                [29.326949799000033, -1.573634902999856],
                [29.327072000000157, -1.573677],
                [29.327109000000178, -1.573692],
                [29.327679000000103, -1.573919],
                [29.32799451700015, -1.574084008999876],
                [29.32831, -1.574249],
                [29.328435223000042, -1.574295707999909],
                [29.327875664000032, -1.575597306999953],
                [29.326764178000076, -1.578179270999954],
                [29.326004, -1.579945000999885],
                [29.325065959000142, -1.58078846199993],
                [29.324913, -1.580926],
                [29.324802, -1.580623],
                [29.32476800000012, -1.5805],
                [29.324757150000096, -1.580460215999949],
                [29.324737548000087, -1.580389737999894],
                [29.324695586000132, -1.580323099999816],
                [29.324344635000159, -1.580114959999889],
                [29.324228287000096, -1.579998373999956],
                [29.323995591000028, -1.579806804999976],
                [29.323906768000086, -1.579719912999906],
                [29.323788, -1.579604],
                [29.323776234000093, -1.579592233999904],
                [29.323709, -1.579622],
                [29.323467076000099, -1.579379075999952],
                [29.323350263000123, -1.579297425999926],
                [29.322803, -1.578915000999928],
                [29.322783620000052, -1.578905050999879],
                [29.32220500000011, -1.578608],
                [29.321964, -1.578465999999878],
                [29.321819080000182, -1.578380047999872],
                [29.321794512999986, -1.578399509999883],
                [29.321696000000145, -1.578478],
                [29.321297000000129, -1.578794],
                [29.321118, -1.579004999999825],
                [29.321082186000126, -1.579047180999851],
                [29.320848466000143, -1.579323172999921],
                [29.320177079000132, -1.580114482999875],
                [29.319883805000188, -1.580739165999944],
                [29.319746, -1.581033],
                [29.319584000000191, -1.581378],
                [29.319342, -1.581828],
                [29.318871000000172, -1.582192],
                [29.318686000000127, -1.582898],
                [29.318745, -1.583078],
                [29.318800000000124, -1.583241],
                [29.319127926000078, -1.583705243999873],
                [29.31910500000015, -1.583781],
                [29.319027, -1.584041],
                [29.318901989000153, -1.584073002999844],
                [29.318777000000182, -1.584105000999898],
                [29.318749, -1.58416],
                [29.318658471000049, -1.584336690999976],
                [29.318521499000099, -1.584604500999944],
                [29.318472212000188, -1.585014432999913],
                [29.31847, -1.585032995999939],
                [29.318464, -1.585065],
                [29.31839900000017, -1.585383],
                [29.318251, -1.585647],
                [29.31824302800004, -1.585660949999976],
                [29.318248, -1.585683],
                [29.318293, -1.58589099999989],
                [29.318372098000054, -1.585899834999907],
                [29.318857, -1.585954],
                [29.318998000000192, -1.585947],
                [29.319135283999969, -1.586014844999909],
                [29.319169824000028, -1.586031913999932],
                [29.31923600000016, -1.586002],
                [29.319306248000089, -1.585970338999971],
                [29.317286, -1.587788999999816],
                [29.317208, -1.587927999999863],
                [29.317200627000034, -1.587941069999943],
                [29.317186356000036, -1.587966679999965],
                [29.317128893000131, -1.588106095999933],
                [29.317046000000119, -1.588308],
                [29.316807, -1.588887999999884],
                [29.316544000000192, -1.589172999999846],
                [29.316514000000154, -1.589334],
                [29.316486000000168, -1.58948],
                [29.316387000000134, -1.589737],
                [29.316320000000132, -1.58988399999987],
                [29.316249897000091, -1.590037587999973],
                [29.316206, -1.590287],
                [29.316093000000137, -1.590922],
                [29.316017180000074, -1.591007025999886],
                [29.315390652000133, -1.591710003999879],
                [29.31515700000017, -1.592009],
                [29.31497400000012, -1.592243],
                [29.314946125, -1.592302587999882],
                [29.314479998000081, -1.593299020999893],
                [29.314448061, -1.593610742999942],
                [29.314410202000033, -1.593980258999977],
                [29.314409, -1.593992],
                [29.314024618000076, -1.594876939999892],
                [29.313873001000047, -1.595225998999979],
                [29.313621294000086, -1.595838668999875],
                [29.313509001000114, -1.596112],
                [29.313502082000184, -1.596147651999957],
                [29.313495991000138, -1.596179040999971],
                [29.313489125999979, -1.59621122599998],
                [29.313480378000179, -1.596254348999935],
                [29.313667297000109, -1.596853971999963],
                [29.313688278000143, -1.597410797999942],
                [29.313772310000161, -1.59747518699993],
                [29.313931001000071, -1.597595999999896],
                [29.314009, -1.597725],
                [29.314238000000159, -1.59791],
                [29.314237603000038, -1.597923511999909],
                [29.314230007000106, -1.598181780999937],
                [29.314241468000091, -1.598197984999956],
                [29.314263, -1.598228],
                [29.314402, -1.598424],
                [29.314431000000127, -1.598521],
                [29.314466, -1.598639],
                [29.314526135999984, -1.598718714999961],
                [29.314552, -1.598752999999874],
                [29.314539, -1.598983],
                [29.314523888000167, -1.599240454999972],
                [29.314475492000099, -1.5994392099999],
                [29.314449, -1.59954800099996],
                [29.31440698099999, -1.599785106999946],
                [29.31436500000018, -1.600022],
                [29.314308, -1.600156],
                [29.314192, -1.600306],
                [29.31405100000012, -1.600381],
                [29.313865468000074, -1.600472338999964],
                [29.313789248000148, -1.600521076999883],
                [29.313657761000172, -1.600605367999947],
                [29.313508987000148, -1.600780247999921],
                [29.313188551999986, -1.600851416999944],
                [29.312595367000029, -1.601129889999925],
                [29.312189102000104, -1.60130119299987],
                [29.311647415000095, -1.601451039999915],
                [29.311332162000042, -1.601680472999874],
                [29.31130400100011, -1.601700999999821],
                [29.310783381000022, -1.601886863999937],
                [29.310780656000077, -1.6020628039999],
                [29.310779000000139, -1.602187],
                [29.310776, -1.602378994999867],
                [29.310785, -1.60326],
                [29.310786042000188, -1.603363403999879],
                [29.310789109000154, -1.603656887999932],
                [29.310804367000173, -1.60374963299995],
                [29.31084106600008, -1.604007585999966],
                [29.310883001000093, -1.604299],
                [29.31071900000012, -1.605912999999873],
                [29.310776, -1.606398],
                [29.310854001, -1.607889998999951],
                [29.310854001, -1.607932766999852],
                [29.310961, -1.608682],
                [29.310982230000036, -1.608954102999974],
                [29.311010360000068, -1.609310387999869],
                [29.311141322000083, -1.609533705999979],
                [29.311354000000108, -1.609896],
                [29.311386974000129, -1.60998759599994],
                [29.311426162000032, -1.610095619999925],
                [29.311710358000084, -1.611237763999839],
                [29.311853409000094, -1.612208603999875],
                [29.312009030000127, -1.612618070999929],
                [29.312046000000123, -1.612714998999934],
                [29.312065475000054, -1.612823632999948],
                [29.312174, -1.613429],
                [29.312140000000113, -1.614007],
                [29.312197112000035, -1.614249553999969],
                [29.31222534200009, -1.614368199999944],
                [29.31207731700016, -1.614366980999932],
                [29.311935, -1.614366],
                [29.311297665000154, -1.614360660999921],
                [29.311218, -1.61428],
                [29.311169, -1.61423],
                [29.311089683000148, -1.614107609999962],
                [29.311011880000024, -1.613992194999923],
                [29.310869218000107, -1.61388039499991],
                [29.310824373000059, -1.613850672999945],
                [29.31076400000012, -1.613811],
                [29.310603999000136, -1.613704999999868],
                [29.310226, -1.613455999999871],
                [29.309943797000187, -1.613296493999826],
                [29.309835435000139, -1.613235473999907],
                [29.309635759000116, -1.613220708999961],
                [29.309605000000147, -1.613219],
                [29.309425, -1.613207000999978],
                [29.309329005000166, -1.613203499999941],
                [29.30923300000012, -1.613199999999836],
                [29.308969478000165, -1.613121158999888],
                [29.308708159000105, -1.613220020999961],
                [29.308661, -1.613207999999872],
                [29.30849799900011, -1.61316399999987],
                [29.308408573000179, -1.613157005999938],
                [29.308318963000033, -1.613150024999868],
                [29.308275, -1.61318],
                [29.308191, -1.613235],
                [29.308097, -1.61322],
                [29.307969814000046, -1.613200068999845],
                [29.307908104000091, -1.613223293999965],
                [29.307876588000113, -1.613235353999926],
                [29.307514191000109, -1.613394259999836],
                [29.307407379000153, -1.613376378999931],
                [29.306981063000023, -1.613528348999978],
                [29.306871817000058, -1.6135669],
                [29.306555, -1.613539999999887],
                [29.30638352700015, -1.61352521099991],
                [29.306125641000108, -1.613503336999884],
                [29.305816700000037, -1.613520659999892],
                [29.305513000000133, -1.613537999999892],
                [29.30499499900003, -1.613567000999979],
                [29.304777955000191, -1.613556360999837],
                [29.304485321000129, -1.613542318999919],
                [29.303761605000091, -1.613546487999884],
                [29.303518000000111, -1.613548],
                [29.302023, -1.613557],
                [29.301572835000172, -1.613892119999889],
                [29.301522471000112, -1.613928962999921],
                [29.301126, -1.614219],
                [29.301111559000049, -1.614208601999962],
                [29.301101000000131, -1.614201],
                [29.30102, -1.614143],
                [29.300982, -1.614115],
                [29.300885, -1.614044],
                [29.300703, -1.61391],
                [29.300561, -1.613788999999883],
                [29.300499977000072, -1.613736415999881],
                [29.300466538000023, -1.613707898999849],
                [29.300359726000067, -1.613657951999869],
                [29.300123, -1.613552],
                [29.300105000000144, -1.613519],
                [29.30006477, -1.613443246999964],
                [29.300010682000163, -1.61334240399998],
                [29.299880981000172, -1.613192439999921],
                [29.299846674000094, -1.613128250999921],
                [29.29979, -1.613023],
                [29.299732, -1.612913999999876],
                [29.299519000000146, -1.612679],
                [29.29944, -1.612522],
                [29.29940400900017, -1.612450017999947],
                [29.299196000000109, -1.612307],
                [29.299164, -1.612277],
                [29.299011, -1.612129],
                [29.298847197999976, -1.611893296999881],
                [29.298625947000062, -1.61175048299998],
                [29.29838943600015, -1.611693381999942],
                [29.29816818300003, -1.611966371999927],
                [29.298053742000093, -1.612107396999932],
                [29.298020272000144, -1.61213099899993],
                [29.297950000000185, -1.612181],
                [29.297791000000188, -1.612293],
                [29.297641, -1.612372999999877],
                [29.297568630000058, -1.612411278999957],
                [29.297519685000054, -1.61243736699987],
                [29.297050476000038, -1.612526178999929],
                [29.296740346000092, -1.612774772999899],
                [29.29646, -1.612999999999886],
                [29.29641700000019, -1.613034],
                [29.296395000000132, -1.613053],
                [29.296324280000135, -1.613112669999964],
                [29.296299102000035, -1.613134040999967],
                [29.296373125, -1.613163537999924],
                [29.296431954000184, -1.613186981999888],
                [29.296463, -1.613543999999877],
                [29.296541000000161, -1.613886],
                [29.296546, -1.613972],
                [29.296555, -1.614151],
                [29.296555, -1.614371999999889],
                [29.296545, -1.614393],
                [29.296537399000101, -1.61440801699996],
                [29.296976090000101, -1.614755748999926],
                [29.297112541000104, -1.614918531999933],
                [29.297370000000171, -1.615225],
                [29.297454883000171, -1.615326859999868],
                [29.297473672000024, -1.615339521999943],
                [29.298380487000031, -1.615947644999892],
                [29.29877, -1.616209],
                [29.299129286000095, -1.616451178999967],
                [29.29922089199999, -1.61651292699986],
                [29.292228004000151, -1.628806676999886],
                [29.292126, -1.628985999999884],
                [29.292113, -1.628972],
                [29.292082000000164, -1.628938],
                [29.291992000000164, -1.628675],
                [29.291931, -1.628495000999976],
                [29.291918460000147, -1.628493918999936],
                [29.291525, -1.62846],
                [29.291620000000137, -1.628292],
                [29.291844695000123, -1.62789653599998],
                [29.291877652000096, -1.627722290999884],
                [29.291895000000125, -1.62763],
                [29.292017000000101, -1.627274999999827],
                [29.292009000000178, -1.627232999999819],
                [29.291780005000135, -1.625990025999897],
                [29.291769635000151, -1.625989874999902],
                [29.291697000000113, -1.62598899999989],
                [29.291288000000179, -1.625983],
                [29.290817000000118, -1.626069],
                [29.290346, -1.626125999999886],
                [29.289623176000077, -1.626113002999944],
                [29.289589184000135, -1.626134356999955],
                [29.289547, -1.626161],
                [29.289322869000046, -1.626012077999974],
                [29.289097, -1.62586199999987],
                [29.288686, -1.625502],
                [29.288597725000102, -1.625424109999869],
                [29.288582722000172, -1.62541145199998],
                [29.288094, -1.625201999999888],
                [29.288033813000141, -1.625175905999868],
                [29.288020040000163, -1.62516945699997],
                [29.287698745000114, -1.625019191999968],
                [29.287555856000097, -1.625046365999879],
                [29.287223, -1.62511],
                [29.286985000000129, -1.625154999999836],
                [29.28584871400011, -1.624621346999902],
                [29.285799026000063, -1.624598026999934],
                [29.285371781000151, -1.624298214999897],
                [29.28501129100016, -1.624036430999922],
                [29.284793854000043, -1.62387704799994],
                [29.284366608000028, -1.623421311999891],
                [29.284223557000189, -1.623297213999933],
                [29.284033089000047, -1.623197083999912],
                [29.283903, -1.623128999999892],
                [29.283751, -1.623049],
                [29.283327000000156, -1.623033999999848],
                [29.282923000000153, -1.62302],
                [29.282626547, -1.622674054999891],
                [29.282329498000024, -1.622326761999943],
                [29.282132705000038, -1.621842237999829],
                [29.2820660000001, -1.621678000999907],
                [29.281842103000145, -1.621241025999893],
                [29.281797120000078, -1.621153233999905],
                [29.281452180000088, -1.621292948999951],
                [29.280767440000147, -1.621835469999951],
                [29.279679958000031, -1.622534444999815],
                [29.279662000000144, -1.622546],
                [29.27939, -1.622721],
                [29.277905, -1.623399000999939],
                [29.277302865000081, -1.623723775999963],
                [29.27697716800003, -1.623899482999946],
                [29.276204132000032, -1.624348462999876],
                [29.276155472000085, -1.624376773999927],
                [29.275904999000034, -1.624534805999929],
                [29.275713, -1.624655999999845],
                [29.275170043000173, -1.62499829199993],
                [29.274781, -1.625116999999875],
                [29.274554094000109, -1.625185893999969],
                [29.274421716000177, -1.625226250999958],
                [29.274408493000067, -1.625237990999949],
                [29.273771287000102, -1.625804423999909],
                [29.27278709400008, -1.62631130199992],
                [29.271894454000119, -1.627546189999976],
                [29.270751953, -1.628520130999902],
                [29.270742512000027, -1.628504604999932],
                [29.27069900000015, -1.628434],
                [29.270624000000169, -1.62831],
                [29.270421671000065, -1.627822128999981],
                [29.270394210000177, -1.627833000999885],
                [29.270374298000149, -1.627841113999864],
                [29.270313726999973, -1.62791399799994],
                [29.270284000000117, -1.627949999999885],
                [29.270163, -1.628096],
                [29.270096, -1.628171],
                [29.270021001000089, -1.628254000999903],
                [29.269848743000068, -1.628321286999892],
                [29.269829000000129, -1.628328999999894],
                [29.26966300100014, -1.628421],
                [29.2696173490001, -1.628475345999846],
                [29.269579, -1.628521],
                [29.269505000000152, -1.628604],
                [29.269424000000186, -1.628631],
                [29.269371760000126, -1.628648046999899],
                [29.269329071000072, -1.628662227999939],
                [29.269146470000123, -1.628734071999929],
                [29.269096, -1.628754],
                [29.269081, -1.628759],
                [29.269064861000061, -1.628764176999937],
                [29.269027710000103, -1.628776429999959],
                [29.268956954000089, -1.628800104999868],
                [29.268916000000104, -1.628814],
                [29.268870999000171, -1.628829000999872],
                [29.268841492000149, -1.628841908999959],
                [29.268662329000165, -1.62892022799997],
                [29.26854800000018, -1.628958999999895],
                [29.268488001000094, -1.628979000999948],
                [29.268412844000181, -1.628977373999817],
                [29.268302918000131, -1.628975391999916],
                [29.268285310000124, -1.628985241999942],
                [29.268265, -1.628997],
                [29.267651, -1.63081],
                [29.267585369000187, -1.631184294999969],
                [29.267557144000079, -1.631346225999948],
                [29.267536090000135, -1.631466774999979],
                [29.2674990000001, -1.631681],
                [29.26740100000012, -1.632242],
                [29.267157, -1.632463],
                [29.266867, -1.633257],
                [29.266716000000145, -1.633747],
                [29.266659468000057, -1.634019727999942],
                [29.266569, -1.634299],
                [29.266469214000153, -1.634606909999945],
                [29.266464, -1.634623],
                [29.266121000000112, -1.634992999999895],
                [29.265997000000141, -1.635129],
                [29.265930191000052, -1.635201791999862],
                [29.266069412000093, -1.636033295999937],
                [29.266056267000181, -1.636251597999944],
                [29.266045001000123, -1.636445],
                [29.26605536600016, -1.63649232299997],
                [29.266068000000132, -1.63655],
                [29.266078183000047, -1.63671802499988],
                [29.266087997000113, -1.636879945999965],
                [29.265837, -1.637134],
                [29.265614008000057, -1.637359991999972],
                [29.265312111000071, -1.637661888999844],
                [29.265305838000131, -1.637676259999978],
                [29.265277, -1.637742999999887],
                [29.265251117000162, -1.637801786999944],
                [29.265205000000151, -1.637996000999863],
                [29.265163227000187, -1.638162084999919],
                [29.265039000000115, -1.638656],
                [29.264875, -1.639277999999877],
                [29.264341, -1.640915],
                [29.264164000000164, -1.641308],
                [29.263624000000107, -1.642511],
                [29.263564428000109, -1.642642493999915],
                [29.263542001000133, -1.642691998999908],
                [29.26303048599999, -1.644076421999898],
                [29.262806, -1.64468399999987],
                [29.262082815000042, -1.646499519999907],
                [29.262034053999969, -1.646637329999976],
                [29.261715, -1.647539],
                [29.261219, -1.648711],
                [29.260420000000181, -1.650598],
                [29.260014000000183, -1.651320999999882],
                [29.259571000000108, -1.652121],
                [29.259253000000115, -1.652628],
                [29.259065000000192, -1.653048],
                [29.259053282000082, -1.653074173999812],
                [29.258821487000034, -1.653592824999919],
                [29.257133485000168, -1.656600473999902],
                [29.256418229000133, -1.657938003999902],
                [29.256210327000133, -1.658507345999851],
                [29.256109777000177, -1.658782409999958],
                [29.25608700000015, -1.658844999999872],
                [29.256037000000106, -1.658981999999867],
                [29.256012604000034, -1.6590039539999],
                [29.252370893000091, -1.662280946999886],
                [29.252343000000167, -1.662409],
                [29.252323001000036, -1.662499],
                [29.252218000000141, -1.662966000999973],
                [29.252199437000115, -1.663049721999812],
                [29.252169001000084, -1.66318699999988],
                [29.252166538000154, -1.663198066999826],
                [29.251532, -1.666049999999871],
                [29.251244047000114, -1.667343266999978],
                [29.250732422000112, -1.669643162999932],
                [29.250719113000173, -1.669641359999957],
                [29.250677000000167, -1.669636],
                [29.250425000000121, -1.669601999999884],
                [29.250403279000182, -1.669599018999975],
                [29.250373886000148, -1.669594984999947],
                [29.249686818000157, -1.669501876999959],
                [29.249304, -1.66945],
                [29.249280882000107, -1.669446763999929],
                [29.249254, -1.669443],
                [29.248285000000124, -1.669311999999877],
                [29.2482300530001, -1.669304202999854],
                [29.247788999000022, -1.669245],
                [29.247751, -1.669234000999893],
                [29.247395999000048, -1.669134999999869],
                [29.247164993000126, -1.669122164999976],
                [29.247, -1.669113000999914],
                [29.246840853000094, -1.669029701999875],
                [29.246765000000153, -1.66899],
                [29.246728, -1.668970999999885],
                [29.246674999000049, -1.668942999999899],
                [29.246654828000089, -1.668932304999885],
                [29.246591567999985, -1.668899057999909],
                [29.246259690000102, -1.668813465999904],
                [29.246104650000063, -1.668809572999976],
                [29.245988000000182, -1.668807],
                [29.245832005000125, -1.668803],
                [29.245308000000193, -1.66879],
                [29.244911, -1.66891],
                [29.244429000000139, -1.668953],
                [29.243850507000161, -1.668877935999944],
                [29.243665000000135, -1.668853999999897],
                [29.243559889000096, -1.668839984999977],
                [29.243500042000164, -1.668832219999956],
                [29.243518504000122, -1.668929563999939],
                [29.243544, -1.669063999999878],
                [29.243568, -1.669198],
                [29.243574276000118, -1.669235657999934],
                [29.243576050000058, -1.669245600999886],
                [29.243614224000055, -1.669451076999906],
                [29.24363900100002, -1.669584],
                [29.243701225000052, -1.66992210699982],
                [29.243752, -1.670198],
                [29.243868, -1.670826],
                [29.243875066000101, -1.670894638999926],
                [29.24389648500005, -1.671097755999938],
                [29.243984222000108, -1.671347259999948],
                [29.244106292000083, -1.672069668999939],
                [29.244113793000054, -1.672113833999958],
                [29.244122000000118, -1.67216],
                [29.244143, -1.672283],
                [29.244233278000081, -1.672833433999927],
                [29.244246, -1.672910998999953],
                [29.244391470000039, -1.67362470899991],
                [29.244438170000024, -1.673853157999929],
                [29.244463144000122, -1.673936085999912],
                [29.244530000000111, -1.674157],
                [29.244630690000122, -1.674491969999906],
                [29.244644000000164, -1.674536],
                [29.244656716000122, -1.674578773999826],
                [29.244699478000143, -1.674720644999923],
                [29.244381002000068, -1.676758070999938],
                [29.244390000000124, -1.67709],
                [29.244406167000079, -1.677712136999901],
                [29.244417191000139, -1.678129077999927],
                [29.244417191000139, -1.678220151999938],
                [29.244413376000068, -1.679241656999977],
                [29.244427266000173, -1.679502500999888],
                [29.244436000000178, -1.679662],
                [29.244446, -1.679851],
                [29.244479386000023, -1.680445787999872],
                [29.244511001000092, -1.681008999999847],
                [29.24454314500008, -1.681565863999822],
                [29.24454500000013, -1.681597999999895],
                [29.244581, -1.68162],
                [29.244724001000122, -1.681646],
                [29.247223009000095, -1.682103],
                [29.247412, -1.682111],
                [29.247441607999974, -1.682112741999958],
                [29.247562409000125, -1.682117819999917],
                [29.247059292000131, -1.683237928999972],
                [29.246605, -1.68425],
                [29.246351000000175, -1.684213999999884],
                [29.246391000000131, -1.684001],
                [29.246397, -1.683973],
                [29.246402479000096, -1.683951084999933],
                [29.246378323000044, -1.683937604999926],
                [29.24635, -1.683934],
                [29.246325000000127, -1.68393],
                [29.246225, -1.683917],
                [29.246139991, -1.68390648299993],
                [29.246128, -1.683905],
                [29.246108004000121, -1.6839004549999],
                [29.246039900000028, -1.683884964999947],
                [29.24600316100009, -1.68387229699988],
                [29.24595260600006, -1.68385505599997],
                [29.245866774999968, -1.683813333999979],
                [29.245784759000117, -1.683768391999877],
                [29.245738623000022, -1.683754666999903],
                [29.245705, -1.683745],
                [29.245688908000091, -1.683739962999937],
                [29.245641, -1.683720999999878],
                [29.24560200000019, -1.683705],
                [29.245574, -1.683693],
                [29.245514000000185, -1.683667],
                [29.245469766999975, -1.683649910999861],
                [29.245426179000106, -1.683633327999928],
                [29.245361089000085, -1.683610389999956],
                [29.24533300000013, -1.683599999999899],
                [29.245290167000064, -1.683576493999908],
                [29.245250700999975, -1.683555006999825],
                [29.245238129000029, -1.683552854999903],
                [29.245173, -1.683541999999818],
                [29.245162916000083, -1.683539958999916],
                [29.245145916000126, -1.68353177399996],
                [29.245136000000173, -1.683527],
                [29.24504299900002, -1.68352],
                [29.244829184000025, -1.683491024999967],
                [29.244846344, -1.683588145999977],
                [29.244842197000139, -1.683937237999942],
                [29.244839001000059, -1.684230984999886],
                [29.244911, -1.684332999999867],
                [29.245035, -1.684508999999878],
                [29.245039000000133, -1.684814],
                [29.245039000000133, -1.684843999999885],
                [29.245043000000123, -1.685158],
                [29.245049000000108, -1.685528],
                [29.245049000000108, -1.685621],
                [29.245051000000103, -1.685742],
                [29.245051996000143, -1.685829666999894],
                [29.245057916000121, -1.686241155999937],
                [29.24505930100014, -1.686393281999926],
                [29.245060000000137, -1.686472],
                [29.245063934000086, -1.686828411999954],
                [29.245051000000103, -1.686909],
                [29.245034995000083, -1.687002028999928],
                [29.245020000000181, -1.687090999999896],
                [29.244996103000062, -1.687218741999914],
                [29.244965001000139, -1.687384998999903],
                [29.244875000999969, -1.687871000999962],
                [29.244913, -1.688016998999956],
                [29.244925000000137, -1.688064],
                [29.244939589000182, -1.688152393999872],
                [29.244942, -1.688167],
                [29.244883, -1.688519],
                [29.244793, -1.689076001999922],
                [29.244795, -1.689145],
                [29.244799544000045, -1.689347974999976],
                [29.244804382000098, -1.689547062999907],
                [29.244812325000112, -1.689856578999923],
                [29.244820000000175, -1.690142],
                [29.244822483000064, -1.690245293999965],
                [29.244825363000132, -1.690350292999938],
                [29.244825363000132, -1.690378666999891],
                [29.244764328000144, -1.690709114999947],
                [29.24476089400008, -1.690729500999964],
                [29.244759000000101, -1.690742],
                [29.244755000999987, -1.690766000999872],
                [29.244760000000156, -1.690841999999861],
                [29.244765998000105, -1.690938962999951],
                [29.244768000000136, -1.690975001999959],
                [29.24477, -1.69101099999989],
                [29.244771000000185, -1.691024],
                [29.244776, -1.691096],
                [29.244782, -1.691188],
                [29.244787, -1.691274991999876],
                [29.24479119900019, -1.69134078899998],
                [29.244798886000183, -1.691461257999947],
                [29.244803000000161, -1.691534],
                [29.244804363000185, -1.691562162999958],
                [29.244806290000099, -1.691595553999946],
                [29.244812013000114, -1.691689967999935],
                [29.244848252000168, -1.692265032999899],
                [29.244853098000021, -1.692335519999972],
                [29.244854000000146, -1.692348],
                [29.244883, -1.692824],
                [29.244915, -1.693314],
                [29.244925000000137, -1.693492],
                [29.244856, -1.693657],
                [29.244817995000062, -1.693749040999933],
                [29.244813064000084, -1.693792494999912],
                [29.244802000000107, -1.693889999999897],
                [29.244789, -1.694013],
                [29.244662737, -1.694127264999963],
                [29.244568001000175, -1.694212999999877],
                [29.244425000000149, -1.694462999999871],
                [29.244355268000049, -1.694586217999927],
                [29.244146347000083, -1.694955943999901],
                [29.244277955000143, -1.69499063499984],
                [29.244337900000062, -1.694992870999954],
                [29.244406, -1.694995],
                [29.244474000000139, -1.695014],
                [29.244512559000043, -1.695024609999962],
                [29.244542122000098, -1.695029596999973],
                [29.24457000100017, -1.695034],
                [29.244638173000112, -1.695044994999876],
                [29.244662379000147, -1.69504890099995],
                [29.244669000000101, -1.695068],
                [29.24467297800004, -1.695082915999933],
                [29.244745, -1.695087999999885],
                [29.244894, -1.695098],
                [29.24519276400008, -1.695054170999924],
                [29.245182, -1.695062],
                [29.245026001000042, -1.695165],
                [29.244940000000156, -1.695322000999965],
                [29.244956975000036, -1.695608242999867],
                [29.24495124900011, -1.695667026999956],
                [29.244949249000115, -1.69569780899991],
                [29.244946, -1.695755],
                [29.244919000000152, -1.696297999999899],
                [29.244883, -1.69654],
                [29.244796995000115, -1.696669074999875],
                [29.244778235000183, -1.696969244999934],
                [29.244768143000044, -1.697132945999897],
                [29.244771216, -1.697174256999858],
                [29.244774190000101, -1.697214057999929],
                [29.244779600000186, -1.69728703099986],
                [29.244354154000177, -1.697458937999954],
                [29.244318000000192, -1.697794000999977],
                [29.244270001000189, -1.698840998999913],
                [29.244060363000074, -1.699124964999953],
                [29.243469000000118, -1.699925998999959],
                [29.242481176000069, -1.701265760999888],
                [29.241724015000045, -1.702291249999917],
                [29.232349396000018, -1.715283155999941],
                [29.230634689000169, -1.71776855],
                [29.230232239000088, -1.718352674999949],
                [29.22233200200003, -1.729321956999854],
                [29.218999863000136, -1.733842968999909],
                [29.213979721000044, -1.740909934999934],
                [29.211505890000126, -1.74436008899994],
                [29.205675126000131, -1.752307533999897],
                [29.205245971000068, -1.752563237999937],
                [29.203313828000148, -1.755079268999907],
                [29.202629089000084, -1.756055593999975],
                [29.201904297000112, -1.757041095999966],
                [29.201154709000093, -1.7580506799999],
                [29.200433731000146, -1.759021042999905],
                [29.199701309000091, -1.760033607999958],
                [29.19909095800017, -1.760806679999973],
                [29.198968887000092, -1.760967969999967],
                [29.198596955000028, -1.761461972999939],
                [29.197856903000115, -1.762453316999881],
                [29.19711113000011, -1.763450980999892],
                [29.196378708000111, -1.764439344999971],
                [29.195631028000037, -1.765448926999909],
                [29.194892884000069, -1.766424654999923],
                [29.194145203000119, -1.767410753999968],
                [29.194116592000171, -1.767449735999946],
                [29.193874358000187, -1.767821668999943],
                [29.193628312000101, -1.768111466999869],
                [29.191946030000167, -1.770394205999935],
                [29.190855027000055, -1.771828173999893],
                [29.190475464000144, -1.772356390999903],
                [29.194919587000072, -1.772419810999907],
                [29.171150208000086, -1.805135248999932],
                [29.142019271000038, -1.843788265999933],
                [29.125940323000123, -1.889606117999961],
                [29.129510880000055, -1.93124675699994],
                [29.141407012000059, -1.98954355799998],
                [29.159219931000166, -2.031434285999922],
                [29.159946152000146, -2.033142593999969],
                [29.160291065000138, -2.033953939999947],
                [29.161882401000184, -2.03769659999989],
                [29.169480288000102, -2.066215564999936],
                [29.171756081000183, -2.074757845999955],
                [29.17313384900018, -2.079929350999976],
                [29.173130755000102, -2.082832222999912],
                [29.17312792600012, -2.085485306999885],
                [29.173124313000073, -2.088873624999906],
                [29.173147355000026, -2.090758926999968],
                [29.173181902000124, -2.093547166999883],
                [29.173200998000141, -2.093716980999943],
                [29.173220001000175, -2.093879],
                [29.173280333000037, -2.094455414999914],
                [29.173294067000086, -2.094585894999966],
                [29.173295601000063, -2.09459881],
                [29.173299789000055, -2.094634054999972],
                [29.17331936100004, -2.094824632999917],
                [29.173339191000082, -2.095017719999817],
                [29.173410415000149, -2.095711230999882],
                [29.173456192000117, -2.096109151999883],
                [29.17346835900014, -2.096200149999959],
                [29.17349559600018, -2.096403849999945],
                [29.173614502000021, -2.097293136999952],
                [29.173650741000131, -2.097590207999929],
                [29.173814773000174, -2.098709582999902],
                [29.17384828400003, -2.099242240999956],
                [29.173926493000181, -2.100485384999956],
                [29.173933021000039, -2.100589146999937],
                [29.173955917000114, -2.100820779999879],
                [29.173958169000173, -2.100845013999958],
                [29.173969268000064, -2.100964546999876],
                [29.174116135000077, -2.102502345999938],
                [29.174135208000166, -2.102737186999946],
                [29.174139326000102, -2.102786741999921],
                [29.174148971000136, -2.102897681999878],
                [29.174199825000073, -2.103450533999876],
                [29.174208521000082, -2.103544282999906],
                [29.174219132000133, -2.103658675999952],
                [29.174362182000095, -2.104919909999978],
                [29.174362182000095, -2.105566261999968],
                [29.174363571000129, -2.105590343999893],
                [29.174365769000133, -2.105628470999875],
                [29.174376000000166, -2.105798],
                [29.174389675000157, -2.10600197499997],
                [29.174394875000019, -2.106077072999938],
                [29.174400331000129, -2.106155871999818],
                [29.174425125000084, -2.10651779199992],
                [29.174442290000172, -2.106753109999943],
                [29.174448005000158, -2.106909046999874],
                [29.174456878000115, -2.10700287599991],
                [29.174480437, -2.107251643999973],
                [29.17448949400017, -2.107734867999966],
                [29.174494000000152, -2.107964],
                [29.17450176400007, -2.108147938999878],
                [29.174507748000053, -2.108285939999917],
                [29.174520492000056, -2.108579873999929],
                [29.17454147300009, -2.108824014999868],
                [29.174549103000174, -2.109230993999972],
                [29.174564265000186, -2.109438037999951],
                [29.174596865000069, -2.109880188999966],
                [29.174599000000171, -2.109953],
                [29.17465, -2.110471941999947],
                [29.174696001000086, -2.11094000099996],
                [29.174707397000134, -2.111576771999978],
                [29.174718999000049, -2.112225],
                [29.174707501000171, -2.112807933999875],
                [29.174696001000086, -2.113391],
                [29.174707397000134, -2.114015872999914],
                [29.174719000000152, -2.114652],
                [29.174719000000152, -2.116578999999888],
                [29.174744, -2.117696999999851],
                [29.174788325000065, -2.118525885999929],
                [29.174814225000091, -2.119006156999888],
                [29.174772593000057, -2.119504792999862],
                [29.174749000000133, -2.119789],
                [29.174719000000152, -2.120148],
                [29.174600745000191, -2.121437316999959],
                [29.174543231000087, -2.12241268899993],
                [29.17452812200014, -2.122670650999964],
                [29.17452812200014, -2.123812674999954],
                [29.174219132000133, -2.12569260499987],
                [29.174202705000141, -2.125804788999915],
                [29.174132611000175, -2.126283489999935],
                [29.173957825000116, -2.127477168999917],
                [29.173528672000032, -2.129309414999909],
                [29.173515243999987, -2.12937425299998],
                [29.173490386000083, -2.12949428099995],
                [29.173410415000149, -2.129880428999911],
                [29.173101425000141, -2.13104629399993],
                [29.172815324000169, -2.132212160999927],
                [29.172709559000054, -2.132611797999971],
                [29.17264687300019, -2.132848657999944],
                [29.172481536000078, -2.13347339499984],
                [29.171981812000126, -2.135281801999895],
                [29.171747785000036, -2.136117530999968],
                [29.17170016700004, -2.136287580999976],
                [29.171482086000026, -2.13706636399985],
                [29.170888901000012, -2.138803481999958],
                [29.170221330000118, -2.140492915999857],
                [29.169437408000135, -2.1428723329999],
                [29.169388133000041, -2.142958456999907],
                [29.169345524000164, -2.143032929999947],
                [29.169327375000137, -2.143066713999929],
                [29.169248, -2.143297],
                [29.169230128000038, -2.143348797999977],
                [29.169130326000129, -2.143638848999899],
                [29.169029868000166, -2.143900648999818],
                [29.168899632000034, -2.144240052999919],
                [29.168689728000061, -2.144787072999975],
                [29.168218613000079, -2.145962476999955],
                [29.168158766000033, -2.146118842999954],
                [29.16806539900017, -2.146362788999966],
                [29.167789366000193, -2.147083976999966],
                [29.167301178000116, -2.148222923999867],
                [29.166349411000112, -2.150510310999948],
                [29.165880204000132, -2.151559114999941],
                [29.165634155000134, -2.152083634999826],
                [29.165334701, -2.152698277999946],
                [29.164833070000157, -2.153817415999924],
                [29.164075852000053, -2.155510186999834],
                [29.163076400000136, -2.157797574999904],
                [29.162517547000164, -2.158909796999922],
                [29.161970138000072, -2.159958362999817],
                [29.16188812200005, -2.160130500999912],
                [29.16090011599999, -2.162227869999924],
                [29.160354615000074, -2.163288591999844],
                [29.160335542000041, -2.163342474999865],
                [29.159950256000116, -2.164045094999892],
                [29.158905030000142, -2.166124342999979],
                [29.158071518000099, -2.167788027999961],
                [29.157798768000077, -2.168313502999922],
                [29.157135009000172, -2.169712542999946],
                [29.156539916000042, -2.170854806999955],
                [29.155183793000162, -2.173757552999973],
                [29.15513038600011, -2.17387437799988],
                [29.155082703000176, -2.1739811899999],
                [29.154565811, -2.175111293999976],
                [29.153722761999973, -2.17692995099992],
                [29.15325737, -2.177932022999869],
                [29.152723312000091, -2.1790423399999],
                [29.15102005000017, -2.182656764999933],
                [29.15018653900006, -2.184251068999856],
                [29.148593902000187, -2.187414884999953],
                [29.148077012000044, -2.188472269999977],
                [29.147268295000174, -2.190128326999968],
                [29.146490098000186, -2.19179177299992],
                [29.145677567000178, -2.193446397999935],
                [29.144880295000121, -2.195146082999884],
                [29.144355773000086, -2.196239948999903],
                [29.14384078900008, -2.197379111999908],
                [29.143548965000093, -2.197978019999823],
                [29.142734527000073, -2.199740885999916],
                [29.142623902000025, -2.199985503999926],
                [29.14090728900004, -2.203881739999872],
                [29.138217925000106, -2.209330558999966],
                [29.137836457000162, -2.210401296999919],
                [29.136100769000052, -2.213613747999887],
                [29.135101319000171, -2.215898036999874],
                [29.134197235000102, -2.217634915999895],
                [29.133388519000164, -2.2193720329999],
                [29.132577896000157, -2.221013783999979],
                [29.132102966000105, -2.222108362999904],
                [29.131841659000088, -2.222631692999869],
                [29.131341933000158, -2.22370266799993],
                [29.130817413000102, -2.224844693999955],
                [29.129795075000061, -2.226938724999911],
                [29.128961564000065, -2.228723287999969],
                [29.12810516400009, -2.230412721999926],
                [29.127271652000047, -2.232125757999938],
                [29.126510619000044, -2.233767746999888],
                [29.125679015000173, -2.235385655999892],
                [29.125488281000059, -2.235933065999973],
                [29.124963759000138, -2.237027644999955],
                [29.124393463000047, -2.238217352999925],
                [29.123846054000126, -2.239311933999943],
                [29.123371125000176, -2.240382671999896],
                [29.122823716000084, -2.241453409999963],
                [29.12229919400005, -2.242666958999905],
                [29.121751786000061, -2.243690013999924],
                [29.121229172000142, -2.244832277999933],
                [29.120656967000173, -2.245974301999922],
                [29.120109559000127, -2.247068882999884],
                [29.119586945000094, -2.248210906999873],
                [29.119421005000049, -2.248805761999961],
                [29.118825912000091, -2.249900340999943],
                [29.118549346000123, -2.250406741999939],
                [29.118385316000058, -2.250816820999944],
                [29.118127824000055, -2.251255511999887],
                [29.117753982000011, -2.252161025999953],
                [29.117206573000146, -2.253255367999884],
                [29.116540909000094, -2.254373787999896],
                [29.115993499000126, -2.255397080999899],
                [29.115398406000168, -2.256372450999891],
                [29.114755631000037, -2.257371902999978],
                [29.114067078000176, -2.25846648199996],
                [29.113471986000093, -2.259418248999907],
                [29.112710953000146, -2.260512827999889],
                [29.112163544000055, -2.26146459499995],
                [29.111734390000038, -2.262035606999859],
                [29.111043930000108, -2.263035058999947],
                [29.110355377000076, -2.264010666999923],
                [29.109521866000136, -2.265009878999933],
                [29.108474732000161, -2.266366241999947],
                [29.107688905000089, -2.267103909999889],
                [29.106809616000078, -2.267936705999887],
                [29.10561943, -2.26895999899989],
                [29.104690552000079, -2.269649980999873],
                [29.103786469000113, -2.270601747999933],
                [29.102930069000138, -2.271386861999872],
                [29.10178756800002, -2.27217221199993],
                [29.100860594999972, -2.272886037999854],
                [29.099956512000063, -2.273576019999837],
                [29.09957504200014, -2.273837804999914],
                [29.098386764000054, -2.274503945999868],
                [29.097244263000107, -2.274956226999905],
                [29.096553803000177, -2.275194167999871],
                [29.0955314630001, -2.275622367999972],
                [29.094411850000142, -2.276026964999858],
                [29.093341827000131, -2.276407717999916],
                [29.092485427000156, -2.276526688999979],
                [29.091651916000046, -2.27678823499997],
                [29.090890885000192, -2.276907205999919],
                [29.090248108000026, -2.27707385899987],
                [29.090143202999968, -2.277090786999963],
                [29.089509963000182, -2.277192829999933],
                [29.088987351000071, -2.277264117999891],
                [29.088069915000062, -2.277345418999971],
                [29.086702346000152, -2.27770233099983],
                [29.085512161000167, -2.277761935999877],
                [29.083513259000142, -2.278140305999898],
                [29.083442688000048, -2.278131245999873],
                [29.082504272000051, -2.279235839999956],
                [29.079404832000137, -2.27882075299982],
                [29.078620910000097, -2.278958796999973],
                [29.078031540000154, -2.27905392699995],
                [29.076850891999982, -2.279244422999966],
                [29.075593948000119, -2.279396532999954],
                [29.074413300000117, -2.27958702899997],
                [29.073291778000055, -2.279758214999959],
                [29.071977616000027, -2.280024766999873],
                [29.070720672000107, -2.280215263999935],
                [29.07013130200005, -2.280386446999955],
                [29.069616318000044, -2.280386446999955],
                [29.06904602100019, -2.280557869999939],
                [29.068284988000187, -2.280614851999928],
                [29.067770004000067, -2.280709981999905],
                [29.067066193000187, -2.280843257999834],
                [29.066553114999977, -2.280938624999976],
                [29.065923690000034, -2.281033753999964],
                [29.065372467000032, -2.281071663999967],
                [29.064172745000064, -2.281395433999933],
                [29.063068389000136, -2.281547545999842],
                [29.061775207999972, -2.281757115999938],
                [29.060480118000157, -2.281985521999843],
                [29.059871674000078, -2.282023428999935],
                [29.058729171000095, -2.282252073999871],
                [29.05798721299999, -2.282404183999915],
                [29.057415009000124, -2.282480477999911],
                [29.056825639000181, -2.282556532999934],
                [29.05558776800018, -2.282804011999872],
                [29.053817748000085, -2.283108471999924],
                [29.052579880000167, -2.283279894999907],
                [29.051380158000029, -2.283489226999905],
                [29.049552918000188, -2.283850908999909],
                [29.048316955000132, -2.284041404999925],
                [29.047079087000043, -2.284231661999968],
                [29.04595565700015, -2.284403084999951],
                [29.045289993000154, -2.284536360999937],
                [29.044586183000035, -2.284612416999948],
                [29.044071198000097, -2.284688472999903],
                [29.043462753000142, -2.28480267599997],
                [29.042890548000116, -2.284840822999911],
                [29.042186738000169, -2.285031079999953],
                [29.041597365000143, -2.28508829999987],
                [29.041006088000188, -2.285202502999937],
                [29.040397644000109, -2.285335778999922],
                [29.039882660000103, -2.285430907999967],
                [29.03915977600019, -2.285469054999965],
                [29.038665771000069, -2.285621404999915],
                [29.037979126000039, -2.285735606999879],
                [29.037351609000041, -2.285773515999892],
                [29.036724090000064, -2.285868643999947],
                [29.036094665000121, -2.2859828469999],
                [29.035562515000152, -2.286078213999872],
                [29.03493499800004, -2.286192415999949],
                [29.034381866999979, -2.286268471999904],
                [29.033754348000059, -2.286363601999881],
                [29.033182144000136, -2.286515950999956],
                [29.032592773000147, -2.286630153999909],
                [29.031984330000114, -2.286725281999964],
                [29.031297683000105, -2.286782503999973],
                [29.030708313000162, -2.286896704999947],
                [29.030193328000109, -2.286991833999821],
                [29.02947044400014, -2.287086962999979],
                [29.028861999000185, -2.287220238999964],
                [29.028196335000132, -2.287277459999928],
                [29.027719497000135, -2.287429571999951],
                [29.027091980000023, -2.287486791999925],
                [29.026500703000181, -2.287639140999886],
                [29.025968553000041, -2.287734270999977],
                [29.025283813000044, -2.287829397999929],
                [29.024654389000034, -2.287943600999938],
                [29.024045945000125, -2.287981747999936],
                [29.023492812000086, -2.28807687699998],
                [29.022991181000066, -2.288190364999934],
                [29.022903442000029, -2.288210152999966],
                [29.022275925000031, -2.288267135999888],
                [29.021646500000088, -2.288419484999906],
                [29.021038055000133, -2.288495778999902],
                [29.02037239100008, -2.288666961999866],
                [29.01987648, -2.288762091999899],
                [29.019134522000115, -2.288876294999966],
                [29.018621444000075, -2.288971661999938],
                [29.017992019000133, -2.289047717999836],
                [29.017364502000191, -2.28916192],
                [29.016754151000043, -2.289257049999947],
                [29.016202927999984, -2.289371252999956],
                [29.015651702000071, -2.289466379999908],
                [29.014965057000154, -2.28954267599994],
                [29.014471054000069, -2.289656875999981],
                [29.01393699700003, -2.289790152999956],
                [29.012605667000173, -2.290132760999882],
                [29.011539459000062, -2.290456293999853],
                [29.01035881100006, -2.290817975999914],
                [29.010139466000112, -2.29087829599996],
                [29.009918213000049, -2.290095806999943],
                [28.998575212000048, -2.297537564999914],
                [28.994197846000077, -2.31417488999989],
                [28.980949401000146, -2.342366695999886],
                [28.977052688000072, -2.345324754999922],
                [28.974374770000168, -2.345441339999979],
                [28.969375610999975, -2.34763956099988],
                [28.964368821000164, -2.349923847999889],
                [28.960542679000127, -2.353769302999922],
                [28.961732864999988, -2.355702637999912],
                [28.961988450000092, -2.356759785999884],
                [28.961595536000118, -2.357057571999917],
                [28.966045380000025, -2.36397528599997],
                [28.966100693000101, -2.364733695999917],
                [28.965869904000101, -2.367832183999951],
                [28.965011597000057, -2.370187281999961],
                [28.963823318000038, -2.371382712999946],
                [28.962242127000025, -2.372973442999921],
                [28.962127686000088, -2.37308955099985],
                [28.961799621000011, -2.373299597999903],
                [28.960828780999975, -2.373889682999959],
                [28.959743500000172, -2.374346731999935],
                [28.95919227600001, -2.374563694999949],
                [28.958486558000061, -2.374841450999952],
                [28.957458495000026, -2.375127075999956],
                [28.956830978000085, -2.375298498999939],
                [28.956373214, -2.375564813999915],
                [28.955612183000028, -2.375583886999891],
                [28.955041885000071, -2.375717163999923],
                [28.954565047000074, -2.375945566999974],
                [28.95397567700013, -2.375964640999882],
                [28.953403473000037, -2.376078842999903],
                [28.952623368000104, -2.37623119299991],
                [28.952053069000044, -2.376269339999965],
                [28.95149993800004, -2.376383542999975],
                [28.950910568000097, -2.376383542999975],
                [28.949577331000057, -2.376421451999931],
                [28.948493957000096, -2.376364468999839],
                [28.947122573000115, -2.376269339999965],
                [28.946018219000052, -2.376154898999971],
                [28.944818498000188, -2.375945566999974],
                [28.943525315000045, -2.375698089999958],
                [28.942153930000188, -2.375298498999939],
                [28.940973282000186, -2.374898672999962],
                [28.939813613000069, -2.374498843999902],
                [28.938825607000012, -2.374144077999972],
                [28.938310623000064, -2.374308347999886],
                [28.937412263000169, -2.374595403999933],
                [28.934791565000012, -2.372620343999927],
                [28.933893203000139, -2.372385977999898],
                [28.932750702000192, -2.372195480999949],
                [28.932193756000117, -2.372075080999935],
                [28.931608201000074, -2.371948241999917],
                [28.930389404000039, -2.371776818999933],
                [28.929704667000124, -2.371776818999933],
                [28.929077147999976, -2.371681689999889],
                [28.927877426, -2.371643542999948],
                [28.926887512000178, -2.371586559999912],
                [28.925422669000056, -2.371624469999972],
                [28.924261092000108, -2.37160539599995],
                [28.923004151000157, -2.371643542999948],
                [28.921766280000099, -2.37154841399996],
                [28.920625687000154, -2.371586559999912],
                [28.919311523000147, -2.371415136999929],
                [28.919181824000134, -2.371405838999976],
                [28.917446137000184, -2.371281860999943],
                [28.916873931000055, -2.371205806999967],
                [28.915676117000089, -2.371205806999967],
                [28.915222168000071, -2.371226787999888],
                [28.914438247000135, -2.371262787999967],
                [28.913276672000052, -2.371262787999967],
                [28.912040710000099, -2.371529339999881],
                [28.910821915000099, -2.371814965999818],
                [28.909679413000049, -2.372214554999914],
                [28.908479690000149, -2.372671603999891],
                [28.907356262000121, -2.373223541999835],
                [28.90642356900014, -2.373794555999893],
                [28.90587806800005, -2.374099730999944],
                [28.905776978000176, -2.374156235999976],
                [28.905357361000029, -2.374365567999917],
                [28.904767991000085, -2.374765396999976],
                [28.904216765000172, -2.374993799999913],
                [28.90354919400005, -2.375412701999949],
                [28.903074264000054, -2.375698089999958],
                [28.902502060000131, -2.376097916999811],
                [28.902046204000044, -2.376402377999966],
                [28.901037216000077, -2.377068756999847],
                [28.899990082000102, -2.377753971999937],
                [28.898962021000045, -2.378553628999953],
                [28.897991181000179, -2.379238843999872],
                [28.896905899999979, -2.37996220499997],
                [28.895992280000087, -2.380742548999876],
                [28.895574570000178, -2.381104230999938],
                [28.894660950000059, -2.381884812999886],
                [28.893671036000057, -2.382684229999939],
                [28.892738342000143, -2.383464811999943],
                [28.891937255000016, -2.384359358999973],
                [28.890985488000069, -2.385273218999942],
                [28.890110016000108, -2.386110782999936],
                [28.889196395999988, -2.386967180999932],
                [28.888416290000123, -2.387804745999972],
                [28.888105393000046, -2.388122081999939],
                [28.887540816000183, -2.388699531999919],
                [28.886863709000181, -2.389424085999906],
                [28.886722565000071, -2.389575242999911],
                [28.885961532000067, -2.390488862999916],
                [28.885503768000035, -2.390907762999916],
                [28.885086059000173, -2.391440629999977],
                [28.884685517000094, -2.391840456999944],
                [28.884361267000088, -2.392240285999947],
                [28.883905411000171, -2.392697094999903],
                [28.883523941000078, -2.393039702999943],
                [28.883163451000087, -2.393553733999909],
                [28.88240051299999, -2.394467352999925],
                [28.881677627000045, -2.395495175999884],
                [28.880992889000083, -2.396370886999875],
                [28.880270003000135, -2.39741778299998],
                [28.879945755000108, -2.397855758999981],
                [28.879583358000048, -2.39836954999987],
                [28.879261016000044, -2.398902654999915],
                [28.878936768000017, -2.399492739999971],
                [28.878271102000042, -2.400444507999964],
                [28.877641677000099, -2.401510476999931],
                [28.876995087000068, -2.402443408999943],
                [28.87638664300016, -2.403509378999956],
                [28.87583351000012, -2.404422998999962],
                [28.875263214000029, -2.405488966999883],
                [28.875185012000145, -2.405629157999954],
                [28.874998093000045, -2.408684490999974],
                [28.873056412000039, -2.409815548999916],
                [28.872692108000081, -2.410571574999949],
                [28.872198105000166, -2.411618470999883],
                [28.871685028000059, -2.412817716999825],
                [28.871284485000047, -2.413959980999834],
                [28.870656968000105, -2.415520905999927],
                [28.870065690000047, -2.417196034999961],
                [28.869247437000126, -2.419404028999963],
                [28.869094849000078, -2.420089482999913],
                [28.868619919000025, -2.42176461199989],
                [28.868124009000041, -2.423573015999921],
                [28.867744446000188, -2.425457476999895],
                [28.867382049000128, -2.427284954999948],
                [28.867095948000156, -2.429759500999978],
                [28.866981506000116, -2.430977820999942],
                [28.866868973000123, -2.432101010999872],
                [28.866792679000014, -2.433300256999928],
                [28.866735459000097, -2.434499500999948],
                [28.866735459000097, -2.435736655999961],
                [28.866811752000103, -2.436993122999922],
                [28.866849899000158, -2.43828749599993],
                [28.866920470000025, -2.439182995999943],
                [28.866943359000061, -2.439486741999929],
                [28.867076875000123, -2.440666914999952],
                [28.867172242000095, -2.441219090999823],
                [28.867458343000067, -2.442456245999949],
                [28.867687226000044, -2.443617582999934],
                [28.868066789000125, -2.444911955999942],
                [28.868391037000094, -2.446111201999941],
                [28.868827819000046, -2.447310447999939],
                [28.869247437000126, -2.448395489999939],
                [28.869609833000084, -2.448928356999886],
                [28.869817733000048, -2.449480532999928],
                [28.870485305000045, -2.450584649999883],
                [28.870714188000022, -2.451155661999962],
                [28.871074677000138, -2.451726674999975],
                [28.871437072000163, -2.452259777999927],
                [28.871646881000061, -2.452773569999977],
                [28.872007371, -2.453306674999965],
                [28.872787475000109, -2.454239367999946],
                [28.873048782000126, -2.454610107999883],
                [28.874086381000097, -2.459827899999937],
                [28.886785508000173, -2.472425937999958],
                [28.890302658000053, -2.478832005999948],
                [28.891605377000133, -2.483466147999934],
                [28.892040252000129, -2.485013007999953],
                [28.892728805000161, -2.488199471999963],
                [28.892866136000066, -2.489579438999954],
                [28.89287185600017, -2.489642619999927],
                [28.892818451000096, -2.490462064999974],
                [28.892679215000157, -2.490877627999964],
                [28.892856598000151, -2.490928409999981],
                [28.892799378000063, -2.491136311999981],
                [28.892675398999984, -2.491593837999972],
                [28.89249420100009, -2.491851805999943],
                [28.892368316999978, -2.492031336999958],
                [28.892181396000069, -2.492271899999935],
                [28.892175674000157, -2.492308854999976],
                [28.892147065000188, -2.492485522999971],
                [28.89211845300008, -2.492659330999857],
                [28.892095565000147, -2.49280524299985],
                [28.892089844000111, -2.492841004999946],
                [28.892074584000113, -2.492934226999864],
                [28.892051697000113, -2.492959499999927],
                [28.892040252000129, -2.492997646999868],
                [28.892028809000124, -2.493036030999917],
                [28.891628265000065, -2.49428272199998],
                [28.891164779000064, -2.495496271999912],
                [28.890499115000068, -2.499493598999891],
                [28.889406204000124, -2.503386496999951],
                [28.888879776000124, -2.504918812999904],
                [28.88838005100007, -2.505656479999914],
                [28.887334824000163, -2.506608246999974],
                [28.885692597000116, -2.507096052999941],
                [28.884338379000155, -2.507293938999965],
                [28.883169174000102, -2.507464884999877],
                [28.880943297000044, -2.508053064999956],
                [28.880842210000139, -2.508080243999928],
                [28.880468368000095, -2.508178710999971],
                [28.88010597300007, -2.508407115999944],
                [28.879606247000083, -2.508721350999963],
                [28.879219056000125, -2.508963822999931],
                [28.876281739000035, -2.509261368999944],
                [28.874448775000076, -2.509428023999874],
                [28.87415122900012, -2.509689568999932],
                [28.87359237700008, -2.510605811999881],
                [28.872415543000045, -2.51158142],
                [28.870998383000028, -2.513306378999971],
                [28.869417190000036, -2.514555691999931],
                [28.86821556100017, -2.515611408999973],
                [28.865489960000161, -2.518005848999962],
                [28.864740373000018, -2.519088506999935],
                [28.863418579000097, -2.520742654999935],
                [28.862943649000044, -2.521337031999963],
                [28.862194061000025, -2.523121833999937],
                [28.861766816000113, -2.524466036999968],
                [28.861806870000066, -2.525610922999931],
                [28.861850738000157, -2.526821851999955],
                [28.861707688000024, -2.529320238999958],
                [28.861968993000062, -2.531092881999939],
                [28.862255096000183, -2.532413481999924],
                [28.863111495999988, -2.533579348999922],
                [28.86412239100008, -2.534307479999882],
                [28.864221573000123, -2.534325837999973],
                [28.864238739000086, -2.534391403999905],
                [28.865179061000106, -2.535069225999962],
                [28.865398406000054, -2.535227297999938],
                [28.866157532000159, -2.535911320999958],
                [28.866279602000134, -2.536049841999898],
                [28.867500305000135, -2.53744602199987],
                [28.867952346000152, -2.537909983999953],
                [28.868455887000152, -2.538038014999927],
                [28.868595123000148, -2.538073538999925],
                [28.868644714000084, -2.538104295999972],
                [28.868713379000155, -2.538150787999825],
                [28.869264601999987, -2.53852057499995],
                [28.869468689000087, -2.538665293999941],
                [28.869796752000184, -2.538897514999974],
                [28.869785309000179, -2.539195059999884],
                [28.869916916000136, -2.540253876999941],
                [28.870573044000082, -2.541367768999976],
                [28.870660783000176, -2.541429280999921],
                [28.870773316000168, -2.541542291999974],
                [28.870864868000069, -2.541646241999956],
                [28.871007919000078, -2.541795253999908],
                [28.871042251000119, -2.541862963999904],
                [28.871604920000038, -2.542097807999824],
                [28.871818542000142, -2.542215585999884],
                [28.871982575000118, -2.54225635499995],
                [28.872018814000171, -2.542325972999947],
                [28.872358322000025, -2.542511701999956],
                [28.87252426200007, -2.542603015999873],
                [28.872615814000028, -2.542653320999932],
                [28.872795105000023, -2.54275226599998],
                [28.873472214000174, -2.543528079999931],
                [28.873552323000126, -2.543621301999906],
                [28.873594285000024, -2.543686626999829],
                [28.873628617000065, -2.543757915999947],
                [28.873693467000066, -2.543891666999912],
                [28.873754500000075, -2.544017552999947],
                [28.873817443000064, -2.544141292999882],
                [28.873916625000049, -2.544349907999902],
                [28.873970033000035, -2.54445981899994],
                [28.874000549000073, -2.54452204699993],
                [28.874246598000127, -2.545036552999875],
                [28.875080110000169, -2.546250104999899],
                [28.875246048, -2.546579122999958],
                [28.875291824999977, -2.546670674999973],
                [28.875782013000048, -2.547691344999976],
                [28.875801086000138, -2.547732113999871],
                [28.875856399000156, -2.547781466999879],
                [28.877042770000173, -2.549854993999929],
                [28.878314972000112, -2.550937651999902],
                [28.8788394930001, -2.551188945999911],
                [28.879920959, -2.5517075059999],
                [28.879949570000178, -2.551720856999964],
                [28.880077362000065, -2.551782368999966],
                [28.882812500000114, -2.553555011999947],
                [28.884475708000025, -2.554441690999909],
                [28.884965896000097, -2.554702758999952],
                [28.885046005000049, -2.554745912999977],
                [28.885414124000079, -2.554942369999878],
                [28.885667801000181, -2.555077075999918],
                [28.885869980000109, -2.555184840999971],
                [28.886512756000172, -2.555608510999946],
                [28.886697769000136, -2.555729388999964],
                [28.887369156000148, -2.556172369999956],
                [28.888948440000092, -2.556913851999923],
                [28.889081956000155, -2.556976317999954],
                [28.88949966500013, -2.557171821999873],
                [28.890378952000162, -2.557528733999902],
                [28.89077186500009, -2.557778596999981],
                [28.89089584300018, -2.557965755999931],
                [28.890968322000049, -2.558077811999965],
                [28.891046523000057, -2.55819487499997],
                [28.892127991000166, -2.559670208999819],
                [28.893152237000038, -2.560633896999889],
                [28.893142701000102, -2.562210082999911],
                [28.893205642000112, -2.562404631999925],
                [28.893218995000041, -2.562482594999892],
                [28.893280030000028, -2.56296777599988],
                [28.894090653000035, -2.564286469999956],
                [28.89413261500016, -2.564571618999821],
                [28.894275665000066, -2.564842938999959],
                [28.894294738000156, -2.565666198999963],
                [28.894325257000105, -2.565867183999899],
                [28.894382477000192, -2.566250323999896],
                [28.894392013000129, -2.566317319999939],
                [28.894472121000149, -2.566856145999907],
                [28.894718169999976, -2.567247628999894],
                [28.89496421900003, -2.567783116999976],
                [28.895084382000107, -2.56857204399995],
                [28.895111084000177, -2.569290399999886],
                [28.895109176, -2.5694141379999],
                [28.895339967000041, -2.571746109999936],
                [28.895282746000191, -2.572832822999885],
                [28.895257950000087, -2.573316335999948],
                [28.895240784000066, -2.573531865999939],
                [28.895219803000032, -2.573571442999821],
                [28.895156861000146, -2.574623106999979],
                [28.895154952999974, -2.574661730999878],
                [28.895139695000125, -2.574843406999889],
                [28.895202638000114, -2.575456140999961],
                [28.895511627000189, -2.57576704],
                [28.895654679000131, -2.5761015409999],
                [28.896059036000111, -2.576603649999868],
                [28.896497726000177, -2.577024221999977],
                [28.896629334000011, -2.577543735999939],
                [28.896633147999978, -2.577576159999978],
                [28.896701813000107, -2.577985763999948],
                [28.896816253000111, -2.57865834099988],
                [28.897327423000149, -2.57961010799994],
                [28.897863388000189, -2.579871892999961],
                [28.898359298000173, -2.580535888999975],
                [28.898635864000141, -2.580906868999932],
                [28.898874282000179, -2.582013367999934],
                [28.898862839000174, -2.58360767299996],
                [28.899374008000109, -2.584146497999939],
                [28.899507522000135, -2.584306000999902],
                [28.899980544000186, -2.584874151999941],
                [28.899599076000072, -2.5854718679999],
                [28.899599076000072, -2.585997581999891],
                [28.899671555000111, -2.586451768999893],
                [28.899719239000149, -2.586834191999913],
                [28.89976692200014, -2.587575196999978],
                [28.899719239000149, -2.587639331999924],
                [28.899742126000035, -2.587735890999909],
                [28.899789810000073, -2.588200092999955],
                [28.899631500000112, -2.588409422999973],
                [28.899492264000116, -2.588592528999925],
                [28.899118424000108, -2.588919638999926],
                [28.899196625000116, -2.589105127999972],
                [28.899267196000153, -2.590395925999928],
                [28.899267196000153, -2.591160296999931],
                [28.899267196000153, -2.591328144999977],
                [28.898715973000094, -2.591449259999934],
                [28.89830970800017, -2.591538428999968],
                [28.898017881999976, -2.592417955999906],
                [28.89800834700003, -2.592472075999922],
                [28.898006439000028, -2.592614172999959],
                [28.897649764999983, -2.593643425999971],
                [28.8976135260001, -2.593755960999943],
                [28.897554398000182, -2.594476461999875],
                [28.897516251000127, -2.594943046999845],
                [28.897500993000108, -2.595122097999933],
                [28.897487640000179, -2.595282554999869],
                [28.897460938000108, -2.595647811999868],
                [28.897460938000108, -2.595745563999969],
                [28.897460938000108, -2.596223591999944],
                [28.897459031000039, -2.597146749999922],
                [28.898113251000154, -2.598348378999958],
                [28.898237228000028, -2.598530529999948],
                [28.898624421000193, -2.599097965999874],
                [28.898683548000179, -2.599419115999979],
                [28.898420334000093, -2.600061653999944],
                [28.898338317000139, -2.600263833999975],
                [28.898374557000125, -2.600620744999958],
                [28.898519516000135, -2.600872516999971],
                [28.898885728000039, -2.601513146999878],
                [28.900135041000112, -2.602226972999972],
                [28.900129318999973, -2.602433204999898],
                [28.900094987000159, -2.6034564979999],
                [28.900087357000075, -2.603713989999903],
                [28.900325775000056, -2.604487418999952],
                [28.90164947500017, -2.606642244999875],
                [28.901678086000175, -2.606668948999868],
                [28.901988983000024, -2.607367275999934],
                [28.901969910000162, -2.607888220999939],
                [28.901969910000162, -2.608199119999938],
                [28.901992798000094, -2.608509779999963],
                [28.902017592000163, -2.608868358999871],
                [28.901590347000081, -2.60915517799998],
                [28.901590347000081, -2.609346388999825],
                [28.901531219000162, -2.609574556999974],
                [28.901361466000083, -2.610614537999936],
                [28.901411056000086, -2.611354112999948],
                [28.901721954000038, -2.612927674999924],
                [28.901721954000038, -2.613426447999814],
                [28.901342393000164, -2.614189385999964],
                [28.901273727000159, -2.614325046999909],
                [28.901102067000181, -2.614672660999872],
                [28.90097618100009, -2.615919589999862],
                [28.900974273000088, -2.617166517999976],
                [28.901098252000111, -2.619161605999977],
                [28.90096283000014, -2.619656324999937],
                [28.900924683000085, -2.619798658999912],
                [28.900835037000093, -2.620128868999871],
                [28.900724411, -2.620532750999814],
                [28.900600433000079, -2.621405601999925],
                [28.900604248000093, -2.621457338999846],
                [28.900609971000165, -2.621533632999956],
                [28.900356292000026, -2.623038530999907],
                [28.901281357000073, -2.62384796099991],
                [28.90134048499999, -2.623899935999873],
                [28.902034760000163, -2.623859403999973],
                [28.902616500000192, -2.623871326999904],
                [28.902891158000159, -2.624858855999889],
                [28.903104783000174, -2.625501155999928],
                [28.903236389000028, -2.625802516999897],
                [28.903520585000081, -2.626453160999915],
                [28.903818131000037, -2.627071618999935],
                [28.903818131000037, -2.627892255999882],
                [28.903295517000117, -2.629034756999943],
                [28.903236389000028, -2.630010366999898],
                [28.903364182000018, -2.630332469999928],
                [28.903629303000059, -2.631004570999949],
                [28.903640747999987, -2.631033182999886],
                [28.903949738000165, -2.631818771999974],
                [28.904048920000037, -2.63192033699994],
                [28.904073716000141, -2.631946802999892],
                [28.904371262000041, -2.632364749999852],
                [28.904439926000066, -2.632512806999841],
                [28.903772354000068, -2.634083032999911],
                [28.903814316000137, -2.634881734999908],
                [28.903871536000111, -2.63599705799993],
                [28.903902053000024, -2.636994121999976],
                [28.904687882000133, -2.639016626999933],
                [28.904663086000028, -2.639446973999895],
                [28.904661179000129, -2.639478682999936],
                [28.904657363000183, -2.639623642999936],
                [28.905019760000073, -2.640285013999971],
                [28.905155182000101, -2.640529154999911],
                [28.905357361000029, -2.640892266999913],
                [28.905424119000031, -2.641015290999974],
                [28.905412674000047, -2.641326664999895],
                [28.905454636000172, -2.641558645999908],
                [28.905395508000083, -2.64180612499996],
                [28.905376434000061, -2.642300367999894],
                [28.905429840000068, -2.642667054999947],
                [28.905794144000026, -2.645128487999955],
                [28.905603408000047, -2.646500109999863],
                [28.90534210300018, -2.647547006999957],
                [28.905290603000026, -2.647708653999928],
                [28.905241013000136, -2.647865533999948],
                [28.90512466500013, -2.648231267999904],
                [28.905094146000181, -2.648256777999904],
                [28.904924393000101, -2.648435115999916],
                [28.903980254000032, -2.649685381999973],
                [28.903697968000131, -2.649767637999958],
                [28.903348922000191, -2.649987458999931],
                [28.903074264000054, -2.650071619999949],
                [28.902770995000083, -2.650164365999899],
                [28.902414321000037, -2.650307177999821],
                [28.90232658400015, -2.650640725999949],
                [28.902107239000031, -2.650751113999945],
                [28.901296616000025, -2.650570392999839],
                [28.900806428000124, -2.650693178999973],
                [28.900642395000148, -2.650652169999944],
                [28.900541305000161, -2.650759219999941],
                [28.899604797000109, -2.65120005499989],
                [28.89941597, -2.651223181999853],
                [28.898513793000063, -2.651590823999868],
                [28.898334502000068, -2.651663541999938],
                [28.896965028000125, -2.652044056999898],
                [28.896324158000027, -2.65217828599998],
                [28.896018981000054, -2.652338503999943],
                [28.895795823000071, -2.652499435999914],
                [28.895423888000153, -2.652768850999905],
                [28.895210266000049, -2.653772592999928],
                [28.895092010000042, -2.654155015999947],
                [28.894971847000136, -2.654657124999915],
                [28.895114899000077, -2.655445813999904],
                [28.895305633000191, -2.655684947999873],
                [28.89535522500006, -2.655732631999911],
                [28.895662307000066, -2.656019448999928],
                [28.895828247000111, -2.656593082999962],
                [28.895923614000083, -2.657357930999922],
                [28.896184921000099, -2.658648728999879],
                [28.896551131000024, -2.659169673999827],
                [28.897192001000121, -2.660467386999926],
                [28.898429871000076, -2.661371706999944],
                [28.898691176000114, -2.661535261999973],
                [28.898763657000131, -2.661581038999941],
                [28.899606704000178, -2.662109373999954],
                [28.90127182000009, -2.661895035999976],
                [28.901927948000036, -2.662085532999925],
                [28.902973175000113, -2.662085532999925],
                [28.903354645000036, -2.6623353949999],
                [28.903997422000032, -2.663537025999972],
                [28.905080796000163, -2.664595842999972],
                [28.90562629600015, -2.664905069999975],
                [28.905889511000169, -2.664798019999921],
                [28.906614303000083, -2.664000987999884],
                [28.907066346000136, -2.663786887999947],
                [28.90741539000004, -2.66384506299994],
                [28.907779694000169, -2.663953302999857],
                [28.908803940000155, -2.664334057999895],
                [28.909458161000032, -2.664560078999955],
                [28.910041808000074, -2.664976595999974],
                [28.910446167000089, -2.665809391999972],
                [28.910255433000088, -2.667474984999899],
                [28.910005569000191, -2.66841483199994],
                [28.911254883000026, -2.669521330999942],
                [28.911491394000109, -2.669787168999903],
                [28.911836625000035, -2.670175551999876],
                [28.911600114000066, -2.670746803999975],
                [28.911706924000043, -2.671032189999892],
                [28.912134171000105, -2.671067953999909],
                [28.91283607400004, -2.671864985999946],
                [28.913764953000054, -2.673233269999855],
                [28.913974762000066, -2.673867224999981],
                [28.914007187000038, -2.673966646999872],
                [28.914335252000058, -2.674958465999964],
                [28.915348052000184, -2.675993441999879],
                [28.915430068000035, -2.676255226999956],
                [28.915393829000152, -2.677013157999966],
                [28.915382386000033, -2.677242755999941],
                [28.915454864000139, -2.677980184999967],
                [28.91583442700005, -2.678539513999965],
                [28.916572571000188, -2.678646563999905],
                [28.917785644000105, -2.680395363999935],
                [28.918607712000096, -2.680550097999969],
                [28.919237137000039, -2.680859326999951],
                [28.920177460000104, -2.680909871999972],
                [28.920665741000107, -2.681002138999872],
                [28.921747207000067, -2.681537626999955],
                [28.922782898000037, -2.681870697999955],
                [28.923627853000028, -2.682072876999939],
                [28.924888611000085, -2.681763649999823],
                [28.926649094000027, -2.681620835999979],
                [28.92825317400019, -2.681361435999918],
                [28.928312302000109, -2.681351900999971],
                [28.928583145000061, -2.681307792999917],
                [28.929481507000162, -2.681466102999877],
                [28.930252075000112, -2.681378363999897],
                [28.930349350000085, -2.681367396999974],
                [28.930944443000044, -2.681299685999875],
                [28.931444168000098, -2.681382894999956],
                [28.932991028000174, -2.682251452999935],
                [28.933094026000163, -2.682416915999966],
                [28.933465958000056, -2.68301272399998],
                [28.933990478000112, -2.683227060999911],
                [28.9343471520001, -2.682191847999889],
                [28.93456840500005, -2.681338786999845],
                [28.934633255000051, -2.681085585999938],
                [28.935085297000171, -2.680907009999885],
                [28.935775757000101, -2.681418655999948],
                [28.936916352000082, -2.6817755699999],
                [28.9375, -2.681882619999897],
                [28.937656402000187, -2.681960821999951],
                [28.937761307000073, -2.682013511999969],
                [28.938249588000076, -2.68272733699996],
                [28.939439773000061, -2.683548210999959],
                [28.940498353000066, -2.68452382199996],
                [28.940664291000132, -2.684928415999934],
                [28.940620423000041, -2.685384988999942],
                [28.940511703000084, -2.686493396999936],
                [28.940473557000132, -2.686879396999871],
                [28.940771103000088, -2.687438725999868],
                [28.940914154999973, -2.68858075199995],
                [28.940891266000165, -2.689877509999974],
                [28.942960738000124, -2.692614077999906],
                [28.943889616999968, -2.693078039999932],
                [28.944150924000041, -2.692875622999964],
                [28.94447708199999, -2.692388057999949],
                [28.94432449400017, -2.69214892399998],
                [28.94432449400017, -2.691814183999895],
                [28.94432449400017, -2.691551208999897],
                [28.944847107000157, -2.69135999699995],
                [28.945274354000048, -2.69135999699995],
                [28.945661545000064, -2.690960167999947],
                [28.946374893000097, -2.690757988999906],
                [28.947481155000162, -2.690757988999906],
                [28.947874069000136, -2.690912723999929],
                [28.949207306000062, -2.691459893999877],
                [28.949939728000061, -2.69136500299993],
                [28.950218201000098, -2.691329002999964],
                [28.95171737600009, -2.691709755999966],
                [28.952430725000056, -2.69237613699994],
                [28.953227997000113, -2.69272112799996],
                [28.954252242000052, -2.693030357999874],
                [28.955001830000072, -2.693030357999874],
                [28.955608368000128, -2.69331598399998],
                [28.956655502000103, -2.693649053999934],
                [28.957225799000128, -2.693565844999966],
                [28.957666396999969, -2.693518160999929],
                [28.958332061000192, -2.693601369999954],
                [28.959070206000035, -2.693601369999954],
                [28.959688187000097, -2.694053650999933],
                [28.960723878000124, -2.695064782999907],
                [28.961318971000026, -2.696052311999893],
                [28.962293624000154, -2.69800353099987],
                [28.963090897000086, -2.699431179999976],
                [28.964468003000036, -2.700692176999951],
                [28.964633941000045, -2.700851201999967],
                [28.965463638000074, -2.701646088999894],
                [28.965839385000152, -2.70269107799993],
                [28.966081619000136, -2.702932833999967],
                [28.966695785000127, -2.703547477999848],
                [28.966981889000181, -2.703511953999907],
                [28.967094422000173, -2.7035162439999],
                [28.967599868000036, -2.703535794999937],
                [28.968540192000034, -2.703619003999904],
                [28.969015122000087, -2.703214406999905],
                [28.96953964100004, -2.702119825999944],
                [28.969642639000085, -2.701662301999932],
                [28.970003128000144, -2.700049876999913],
                [28.970615387000066, -2.699124812999912],
                [28.971300126000131, -2.698086737999915],
                [28.972335815000122, -2.697301387999971],
                [28.973918914000137, -2.696092128999908],
                [28.974905015000104, -2.695338487999891],
                [28.975381852, -2.694933891999938],
                [28.975845337000067, -2.694898127999977],
                [28.976179122000076, -2.694981573999939],
                [28.976987838000184, -2.694719791999944],
                [28.977855683000143, -2.694588898999882],
                [28.978652953000051, -2.69461274199989],
                [28.980093002999979, -2.69406533199998],
                [28.981737138000028, -2.693934917999968],
                [28.981853486000091, -2.693925856999897],
                [28.982044220000034, -2.693910836999976],
                [28.983221055000172, -2.693922756999939],
                [28.983602523000116, -2.694398641999953],
                [28.983694077000052, -2.694456339999931],
                [28.983783721000179, -2.694512129999907],
                [28.984708787000159, -2.695088623999936],
                [28.984935760000042, -2.69556522399995],
                [28.984973907000096, -2.695591687999979],
                [28.985038757000098, -2.69563841799993],
                [28.985420228000123, -2.696403265999891],
                [28.985534668000184, -2.696849346999954],
                [28.985694886000147, -2.697057483999856],
                [28.985790253000118, -2.697182416999965],
                [28.985862731000054, -2.6971900449999],
                [28.986322403000088, -2.697550534999948],
                [28.987226485000122, -2.697956800999918],
                [28.987575531000061, -2.698443650999877],
                [28.987985611000056, -2.699284075999969],
                [28.988046646000043, -2.69940853199995],
                [28.988611222000031, -2.699609518999978],
                [28.98923301700006, -2.699656725999887],
                [28.989824294000016, -2.699621438999884],
                [28.990621567000176, -2.698621987999957],
                [28.991394043000128, -2.698193787999969],
                [28.991918565000049, -2.698324680999974],
                [28.992536544000075, -2.698550700999931],
                [28.993892670000037, -2.698669671999937],
                [28.99496460000006, -2.698990821999928],
                [28.995725631000084, -2.69937157499993],
                [28.995899201000043, -2.699667930999908],
                [28.996004104000122, -2.699845074999928],
                [28.996902466, -2.701370475999909],
                [28.997438431000035, -2.702274560999967],
                [28.997915268000156, -2.70297646399996],
                [28.998758315000032, -2.703678368999931],
                [29.000473023000154, -2.704416035999941],
                [29.001543046000108, -2.70477294799997],
                [29.002185821000069, -2.704677820999848],
                [29.003643037000074, -2.704283714999917],
                [29.003946304000181, -2.704201458999933],
                [29.00477981500012, -2.705141542999911],
                [29.005636215000095, -2.706176518999939],
                [29.005699158000084, -2.706221580999966],
                [29.006456376000017, -2.70677137399997],
                [29.006874085000049, -2.707045076999975],
                [29.00725936900011, -2.707380532999935],
                [29.007293700000048, -2.707410573999823],
                [29.007415771000126, -2.707518099999902],
                [29.00815773100004, -2.70816373699995],
                [29.008895872999972, -2.708865641999978],
                [29.009954452000102, -2.710329055999978],
                [29.010597229999973, -2.711471079999967],
                [29.011644364000119, -2.712613342999873],
                [29.012369156000034, -2.71345805999988],
                [29.01302909900005, -2.713853120999886],
                [29.013856888000021, -2.714350223999873],
                [29.014535905000173, -2.715016602999981],
                [29.015296935000094, -2.715825557999949],
                [29.015748976999987, -2.716027974999975],
                [29.016046523000114, -2.716741798999976],
                [29.016557694000028, -2.717158078999944],
                [29.018104554000161, -2.717848062999849],
                [29.018369674000041, -2.718010185999958],
                [29.018747330000053, -2.718240736999917],
                [29.019794464000142, -2.718514441999901],
                [29.020841598000118, -2.719216346999929],
                [29.021411897000121, -2.719846963999942],
                [29.022327422000103, -2.72140550499995],
                [29.022344588000124, -2.72142720099987],
                [29.022397995000176, -2.72149801199987],
                [29.02282714800009, -2.722059725999884],
                [29.023338317000025, -2.722035884999968],
                [29.023838043000126, -2.722393035999914],
                [29.024219513000105, -2.723023413999954],
                [29.024374008000166, -2.723630188999891],
                [29.024469374999967, -2.725212573999897],
                [29.025278091000132, -2.725569484999937],
                [29.026586532000124, -2.727377891999936],
                [29.028312683000024, -2.728781700999889],
                [29.029144287000065, -2.72900772099996],
                [29.029382705000103, -2.729400396999836],
                [29.02981185800013, -2.730078696999897],
                [29.030286788000183, -2.730613946999881],
                [29.030750274000184, -2.730887651999979],
                [29.030960082000149, -2.731176852999909],
                [29.031240463000131, -2.731987713999956],
                [29.031360626000037, -2.732247352999934],
                [29.031797409000035, -2.732842920999929],
                [29.032583238000143, -2.733508109999889],
                [29.033704758, -2.733527182999978],
                [29.034406661000105, -2.733527182999978],
                [29.034675598000035, -2.733574867999892],
                [29.035081863000187, -2.734171150999941],
                [29.03539085400007, -2.735480069999881],
                [29.03549766500015, -2.735789298999976],
                [29.035415650000175, -2.736764906999952],
                [29.03549766500015, -2.73745489199996],
                [29.036212921000185, -2.738846779999903],
                [29.037033081000175, -2.740096090999884],
                [29.037866593000047, -2.741238354999894],
                [29.038829803000169, -2.742011545999958],
                [29.039638519000107, -2.7426896099999],
                [29.04017448400009, -2.743100166999966],
                [29.040115356, -2.744207858999857],
                [29.040086747000032, -2.744733095999948],
                [29.040182114000061, -2.745899914999882],
                [29.04011726300007, -2.747034072999952],
                [29.039390564000087, -2.748363017999907],
                [29.039249420000147, -2.748622892999947],
                [29.03879737900013, -2.750860929999931],
                [29.038700104000156, -2.751770019999924],
                [29.038528441999972, -2.752444981999815],
                [29.038078308000024, -2.755321026999923],
                [29.038000107000073, -2.757025956999939],
                [29.0381336210001, -2.75769090599988],
                [29.03835868800013, -2.757615088999842],
                [29.03835868800013, -2.757931946999861],
                [29.038572311000166, -2.758409976999872],
                [29.03881073000008, -2.758792399999891],
                [29.038881303000153, -2.75924658799994],
                [29.038619996000079, -2.760417699999891],
                [29.038192748000085, -2.761039017999963],
                [29.037240981000139, -2.760608911999839],
                [29.036052703000053, -2.7600829599999],
                [29.035173417000124, -2.760107040999969],
                [29.034437180000054, -2.760107040999969],
                [29.033962250000172, -2.759772299999895],
                [29.033723831000088, -2.75958108899988],
                [29.03334617600018, -2.759394882999914],
                [29.032464982000135, -2.758959769999876],
                [29.031942368000045, -2.75924658799994],
                [29.030776978000063, -2.759963511999899],
                [29.029706955000108, -2.760107040999969],
                [29.028234482000187, -2.760107040999969],
                [29.026973725000062, -2.76044154199991],
                [29.026617051000073, -2.760823964999872],
                [29.024976731000038, -2.76209068199995],
                [29.024658204000048, -2.762356280999825],
                [29.023599624000042, -2.76323795299993],
                [29.021602631000121, -2.765699624999968],
                [29.02072334200011, -2.766775130999974],
                [29.019962311000086, -2.768113373999938],
                [29.019605637000097, -2.768663166999943],
                [29.019083023000178, -2.769571303999953],
                [29.018037796000101, -2.770527361999882],
                [29.017467499000077, -2.77071857399983],
                [29.017038345000117, -2.771268128999907],
                [29.016298295000183, -2.771559952999894],
                [29.015827179999974, -2.771746158999918],
                [29.015470506000156, -2.77208089699991],
                [29.014543533000108, -2.772391556999878],
                [29.014020920000121, -2.772797822999905],
                [29.013236999000185, -2.773060797999904],
                [29.011928558000193, -2.773562668999944],
                [29.011548997000148, -2.774279594999939],
                [29.010812760000078, -2.774805306999838],
                [29.010194779000187, -2.775044440999977],
                [29.009456634000117, -2.775689600999897],
                [29.009220122999977, -2.77626323599992],
                [29.008911133000026, -2.776836871999876],
                [29.008815766000055, -2.777219294999895],
                [29.008434296000075, -2.778079747999868],
                [29.008268356000031, -2.77846193399995],
                [29.007698059000177, -2.779155015999947],
                [29.006366729000149, -2.779752491999886],
                [29.005250930000102, -2.78023052199984],
                [29.004297257000189, -2.780811308999944],
                [29.00425148000005, -2.780361889999938],
                [29.004144165000127, -2.780167372999927],
                [29.004065966000155, -2.780211170999905],
                [29.003284455000028, -2.780648945999928],
                [29.002516143000094, -2.781241070999954],
                [29.002489084000047, -2.781261935999908],
                [29.001562119000027, -2.783498048999832],
                [29.000715255000102, -2.785708902999886],
                [29.000566483000057, -2.787123916999974],
                [29.000500461000115, -2.787749196999926],
                [29.000477, -2.787973],
                [29.000422, -2.788842],
                [29.000383915000043, -2.789439492999975],
                [29.000345230000107, -2.790050028999872],
                [29.001007081000068, -2.7916209699999],
                [29.000976391000052, -2.79233205],
                [29.000929, -2.793433],
                [29.000844, -2.793789999999888],
                [29.000701671000058, -2.794382427999949],
                [29.000610352000024, -2.79476404199994],
                [29.00051911200012, -2.796020716999976],
                [29.000477, -2.796603],
                [29.000504, -2.798971],
                [29.0006711260001, -2.799753328999941],
                [29.000874001000113, -2.800702998999896],
                [29.002066, -2.803445998999905],
                [29.002079, -2.803692999999839],
                [29.002092, -2.80395099999987],
                [29.002119000000164, -2.804910999999891],
                [29.002008, -2.805689000999962],
                [29.001932001000057, -2.806216],
                [29.001851354000053, -2.806358663999959],
                [29.001616000000126, -2.806775000999949],
                [29.001475891000155, -2.807139493999898],
                [29.001350402000185, -2.807466982999927],
                [29.001457161000189, -2.808106763999888],
                [29.001484, -2.808267],
                [29.001550000000179, -2.808393],
                [29.001719526000159, -2.808719087999918],
                [29.001749001000178, -2.810212000999911],
                [29.001893109000036, -2.810474554999871],
                [29.002333, -2.81127599999985],
                [29.002891119000026, -2.811701722999885],
                [29.003308997000147, -2.812020473999894],
                [29.004396, -2.814126],
                [29.004741067000111, -2.814587715999949],
                [29.005030190000184, -2.814974580999888],
                [29.00527000000011, -2.815299],
                [29.005589, -2.81607],
                [29.005852000000118, -2.816467],
                [29.005916598000113, -2.816565405999882],
                [29.005959, -2.81663],
                [29.007946000000175, -2.817908],
                [29.008375066000156, -2.818201554999973],
                [29.01059341500013, -2.819719076999945],
                [29.012630463000107, -2.820837021999921],
                [29.013543016000028, -2.821017516999916],
                [29.014379999000084, -2.821182998999859],
                [29.014869728000178, -2.821294698999964],
                [29.015783, -2.821503],
                [29.016122, -2.821696],
                [29.016392, -2.82185],
                [29.016529000000162, -2.821941999999865],
                [29.016543605000038, -2.821951811999952],
                [29.016788483000028, -2.822115896999947],
                [29.017194197000038, -2.822071695999853],
                [29.017769000000101, -2.822009],
                [29.01826, -2.821745],
                [29.019697849000067, -2.82097369],
                [29.020313000000101, -2.820923],
                [29.020414, -2.820979],
                [29.020470000000103, -2.820982],
                [29.020501695000121, -2.820983788999968],
                [29.020841997000048, -2.821003],
                [29.021175, -2.821241998999938],
                [29.021389000000113, -2.821409],
                [29.022077806000084, -2.821409],
                [29.022332000000176, -2.82147899999984],
                [29.022571610000057, -2.821545961999959],
                [29.022628784000176, -2.82156181299996],
                [29.022665023000059, -2.821571825999968],
                [29.023277282000038, -2.822649001999935],
                [29.023349523000036, -2.822671779999951],
                [29.02374500000019, -2.822796],
                [29.02385097500013, -2.822829718999969],
                [29.023942948000013, -2.822858571999916],
                [29.024217606000036, -2.822651623999946],
                [29.024692536000089, -2.822603939999908],
                [29.02507209800001, -2.822603939999908],
                [29.025125588000037, -2.822665185999938],
                [29.025206, -2.822757],
                [29.025405228000068, -2.822985115999927],
                [29.025641425000174, -2.82342203199994],
                [29.025689816000181, -2.823511660999884],
                [29.025715889000082, -2.823589666999965],
                [29.025881, -2.824085],
                [29.026035474000139, -2.824335957999949],
                [29.026190000000156, -2.824587],
                [29.026642, -2.825424],
                [29.026705200000151, -2.825482525999973],
                [29.026926040000149, -2.825686693999899],
                [29.027157030000069, -2.825853903999871],
                [29.027189000000192, -2.825877],
                [29.027352453000162, -2.825995158999945],
                [29.027853000000107, -2.82630799899988],
                [29.028198019000115, -2.826295998999967],
                [29.028543000000127, -2.826284],
                [29.028856608000183, -2.82625869799989],
                [29.029422760000102, -2.826212643999895],
                [29.030658723000158, -2.826092957999947],
                [29.031616210000038, -2.826052903999937],
                [29.031634102000112, -2.826052157999925],
                [29.031659, -2.826051],
                [29.031799, -2.826045],
                [29.032357968000156, -2.825949504999869],
                [29.032917, -2.825853998999889],
                [29.033154520000153, -2.825602978999882],
                [29.033392000000106, -2.825351999999839],
                [29.033409, -2.825319],
                [29.033432000000175, -2.825277],
                [29.033748054000057, -2.824691758999904],
                [29.033922196000049, -2.824369332999879],
                [29.033985000000143, -2.824253],
                [29.034485, -2.824253],
                [29.034960000000126, -2.824253],
                [29.035187000000121, -2.824306],
                [29.035577809000188, -2.824395955999933],
                [29.036268000000177, -2.824396],
                [29.036453422000079, -2.824438542999928],
                [29.036790848000123, -2.824515818999942],
                [29.037195206000149, -2.824467896999977],
                [29.037365596000029, -2.824453001999871],
                [29.037741, -2.82442],
                [29.038077514000179, -2.824357622999969],
                [29.038383484000065, -2.824300765999965],
                [29.039167152000061, -2.824037873999885],
                [29.039832999, -2.823487999999884],
                [29.040153512000131, -2.8232489909999],
                [29.040474000000188, -2.82301],
                [29.040774830000032, -2.822949665999943],
                [29.041187286000081, -2.822866676999979],
                [29.041900635000047, -2.822436571999845],
                [29.04242061900004, -2.822135596999885],
                [29.042763, -2.821936999999878],
                [29.042850000000158, -2.821887],
                [29.043601007, -2.82100968799989],
                [29.043729246000055, -2.820859882999969],
                [29.044847000000175, -2.819999],
                [29.045289742000023, -2.819712520999872],
                [29.045918000000142, -2.819305999999813],
                [29.046297, -2.819305999999813],
                [29.046915, -2.819354],
                [29.047449, -2.819418],
                [29.047465000000102, -2.819423],
                [29.047506000000112, -2.819435999999882],
                [29.04773706800006, -2.819509822999976],
                [29.047819137000147, -2.819535970999937],
                [29.049259187000132, -2.819969890999914],
                [29.049298263000139, -2.81997198799985],
                [29.049417000000119, -2.819977999999878],
                [29.049636968000186, -2.819989997999926],
                [29.049921, -2.820005],
                [29.050066496000056, -2.820013082999935],
                [29.050119400000142, -2.820015905999924],
                [29.05016517700011, -2.820244788999958],
                [29.050332075000085, -2.82031183],
                [29.050504655000168, -2.820380861999922],
                [29.050776, -2.820581],
                [29.050861, -2.820644],
                [29.051003, -2.820917],
                [29.051145734000158, -2.821193484999924],
                [29.051146000000188, -2.822102],
                [29.051146000000188, -2.823177],
                [29.05116, -2.823493],
                [29.051163798000061, -2.823581874999945],
                [29.051170349000131, -2.823727129999952],
                [29.0512632760001, -2.823880086999907],
                [29.051432, -2.824157],
                [29.051764000000162, -2.824586998999962],
                [29.051895942000044, -2.824701623999943],
                [29.05192387500017, -2.82472589199989],
                [29.051923510000051, -2.824895629999901],
                [29.051889149000147, -2.825080506999939],
                [29.051853181000183, -2.825275419999969],
                [29.051864645000023, -2.8253763539999],
                [29.051880000000153, -2.82551],
                [29.051924, -2.825896999999884],
                [29.051924, -2.826207],
                [29.051949, -2.826422],
                [29.052296000000126, -2.826691999999866],
                [29.052396969000029, -2.826771043999941],
                [29.052471160000039, -2.826828717999888],
                [29.052574157000151, -2.827088594999907],
                [29.052589416000046, -2.8271296019999],
                [29.052920913000037, -2.827378500999885],
                [29.053161585000055, -2.827558689999876],
                [29.053238247000024, -2.827573829999892],
                [29.053707000000145, -2.827665],
                [29.054111, -2.827832],
                [29.05423584100015, -2.827919549999933],
                [29.054418564000059, -2.828047514999923],
                [29.054752349000069, -2.828166960999965],
                [29.054918203000113, -2.828250602999958],
                [29.055083668, -2.828333833999977],
                [29.055321014000128, -2.828501957999947],
                [29.055489001000069, -2.828621],
                [29.05572652699999, -2.828692507999904],
                [29.055964, -2.828764],
                [29.056345000000135, -2.82886],
                [29.056396, -2.828895999999872],
                [29.056749000000138, -2.829146999999864],
                [29.056982475000041, -2.829408721999926],
                [29.057153788000051, -2.829600762999917],
                [29.057380000000137, -2.829859999999883],
                [29.057507256000179, -2.83000614599996],
                [29.057842000000164, -2.830509],
                [29.057556000000147, -2.8307],
                [29.057389664000027, -2.83096625099995],
                [29.057374000000152, -2.831118],
                [29.057343000000117, -2.831417],
                [29.057491277, -2.831816580999941],
                [29.057556153000064, -2.831990718999975],
                [29.058032990000186, -2.832444905999978],
                [29.058317184000032, -2.832683801999849],
                [29.05844455499999, -2.832811553999932],
                [29.058500285000036, -2.832867284999907],
                [29.058556, -2.832923],
                [29.058699, -2.833162],
                [29.058838000000151, -2.833411],
                [29.058878162000099, -2.833483182999885],
                [29.058912277000161, -2.833544254999879],
                [29.059126027000104, -2.833889874999898],
                [29.059148811, -2.833926694999832],
                [29.059316677000083, -2.834055662999845],
                [29.059552999000061, -2.834236998999927],
                [29.060057459000063, -2.83456012399995],
                [29.060075884000071, -2.834571923999931],
                [29.06041, -2.835791],
                [29.060636, -2.836361999999895],
                [29.060645978000025, -2.836387942999977],
                [29.060652000000118, -2.836437999999873],
                [29.060718936000171, -2.837008449999928],
                [29.061103000000173, -2.837632999999869],
                [29.061145904000171, -2.837702843999978],
                [29.061525344000131, -2.838562964999937],
                [29.061716080000053, -2.838825701999895],
                [29.061716080000053, -2.838888167999926],
                [29.061243938000075, -2.83887526299992],
                [29.061123, -2.838872],
                [29.06114, -2.839047],
                [29.06121400000012, -2.839829],
                [29.060749000000158, -2.841399],
                [29.060522044000038, -2.842538039999909],
                [29.061293, -2.843265999999858],
                [29.061819, -2.844168],
                [29.061961178000104, -2.844412148999936],
                [29.062063217000116, -2.844587086999979],
                [29.062611925000056, -2.845016059999978],
                [29.062869, -2.845216999999877],
                [29.06328208900004, -2.845539941999959],
                [29.064281121000135, -2.846043891999841],
                [29.064672, -2.845695998999929],
                [29.065275728000074, -2.845160550999935],
                [29.065344, -2.845099999999889],
                [29.065514491000044, -2.845081500999868],
                [29.065685001000134, -2.845062998999936],
                [29.066184999000086, -2.845062998999936],
                [29.066238267000074, -2.845085598999958],
                [29.066317001000186, -2.845119],
                [29.066508, -2.845275999999899],
                [29.066998000000126, -2.84568],
                [29.067677000000174, -2.847136],
                [29.068764, -2.849504],
                [29.068673482000179, -2.84980055799997],
                [29.06858300000016, -2.850096999999892],
                [29.068416021000132, -2.850449955999977],
                [29.068249, -2.850802999999871],
                [29.06725242400006, -2.851091165999833],
                [29.066896, -2.851194],
                [29.066552032000175, -2.851292949999902],
                [29.066385269000136, -2.851341008999896],
                [29.065706253000087, -2.852751016999946],
                [29.065660478000098, -2.854208945999972],
                [29.066837311000086, -2.855437992999953],
                [29.067924499000128, -2.85567903499998],
                [29.068018318000156, -2.85569834699993],
                [29.068080000000123, -2.855710999999872],
                [29.069084271000179, -2.855917329999897],
                [29.069107, -2.855922],
                [29.069733, -2.856857],
                [29.070323565000024, -2.857718488999979],
                [29.070188686000165, -2.858488027999897],
                [29.069553375000055, -2.858488082999884],
                [29.069171151000091, -2.858769329999973],
                [29.06893, -2.858947],
                [29.068377000000169, -2.859354],
                [29.06815131500008, -2.859852699999919],
                [29.067924499000128, -2.86035490099988],
                [29.068010215000129, -2.860581395999816],
                [29.068055000000186, -2.860699],
                [29.068171000000177, -2.861005],
                [29.068287, -2.861312],
                [29.068295000000148, -2.861342999999863],
                [29.068308175000084, -2.861395699999946],
                [29.068336486000192, -2.861507177999954],
                [29.068428040000128, -2.861865519999924],
                [29.068647385000077, -2.862723111999912],
                [29.06937217400008, -2.864273068999978],
                [29.070366901000057, -2.864665785999875],
                [29.070640798000056, -2.864773919999948],
                [29.070757713000091, -2.864739819999898],
                [29.071198842000058, -2.864610632999927],
                [29.071686214000067, -2.864468105999947],
                [29.071795999000187, -2.864436],
                [29.071879, -2.864373999999827],
                [29.071913000000166, -2.864348999999891],
                [29.072245000000123, -2.86411],
                [29.072399493000034, -2.864002504999974],
                [29.072554, -2.863895],
                [29.072862, -2.863670999999897],
                [29.072983, -2.863583999999833],
                [29.073066669000184, -2.863558827999952],
                [29.073218832000066, -2.863513050999927],
                [29.073241900000028, -2.863511952999886],
                [29.073744000000147, -2.863489],
                [29.074005898000166, -2.863511131999928],
                [29.074027999000123, -2.863512999999898],
                [29.074176121000107, -2.863496986999905],
                [29.074694, -2.863441],
                [29.074721443000101, -2.863443640999947],
                [29.075193000000127, -2.863488998999969],
                [29.075220221000166, -2.863503993999871],
                [29.075783000000115, -2.863814],
                [29.07593, -2.863895],
                [29.07609709500008, -2.864133874999936],
                [29.076214, -2.864300999999898],
                [29.076220879000175, -2.864349009999955],
                [29.076262000000156, -2.864636],
                [29.076296280000122, -2.864750782999977],
                [29.076327974000094, -2.864856912999812],
                [29.076194, -2.864920999999868],
                [29.076139904000172, -2.86494658599986],
                [29.076120376000119, -2.864955901999906],
                [29.076559066000186, -2.866089104999958],
                [29.076586000000134, -2.866158999999811],
                [29.077000000000112, -2.86722599899997],
                [29.077045326000132, -2.867342672999939],
                [29.077162000000158, -2.867643],
                [29.077336296, -2.867774690999909],
                [29.077522249000026, -2.867914893999966],
                [29.077588, -2.867886999999882],
                [29.077860699000155, -2.867772832999947],
                [29.078048706000175, -2.867693899999892],
                [29.078222044000142, -2.867620649999935],
                [29.078345189000117, -2.867568530999904],
                [29.078384, -2.867551999999876],
                [29.079681, -2.868224],
                [29.079877726000177, -2.868325857999935],
                [29.07990812800017, -2.868332868999914],
                [29.080321, -2.868426999999883],
                [29.081869, -2.868781],
                [29.0832410000001, -2.869435],
                [29.083262, -2.869445],
                [29.083289828000034, -2.86945846499998],
                [29.083354950000114, -2.869489668999961],
                [29.08343162400007, -2.869526057999963],
                [29.083491000000151, -2.869554],
                [29.083590000000186, -2.869601],
                [29.083779197000126, -2.86992167499983],
                [29.083944320000171, -2.870200871999941],
                [29.084647822000136, -2.871391779999954],
                [29.084658000000104, -2.871409],
                [29.084955849000153, -2.87191293199993],
                [29.085041001000093, -2.872056998999938],
                [29.085084422000193, -2.872130979999952],
                [29.08517646700011, -2.872287034999943],
                [29.085303147000104, -2.872460480999905],
                [29.085412000000133, -2.872609],
                [29.085666999000182, -2.87295799899988],
                [29.086010243000146, -2.872989419999897],
                [29.086388, -2.873024],
                [29.086577495000029, -2.873083497999971],
                [29.086767000000123, -2.873142998999981],
                [29.08729, -2.873142998999981],
                [29.087932781000063, -2.872952064999936],
                [29.088407516000188, -2.872569798999905],
                [29.088495595000097, -2.872290757999906],
                [29.088572840000154, -2.872044512999878],
                [29.088801563000175, -2.871563918999925],
                [29.088811989000135, -2.87154202399995],
                [29.08881892800008, -2.871528893999937],
                [29.08918947, -2.870827894999934],
                [29.089668000000188, -2.870825],
                [29.090000000000146, -2.870825],
                [29.090093, -2.870889],
                [29.090107868000189, -2.870899239999972],
                [29.090244292000079, -2.87099289899993],
                [29.090496150000149, -2.871165618999896],
                [29.090522906000047, -2.871183937999888],
                [29.090623000000164, -2.871284],
                [29.090831502000185, -2.871494497999947],
                [29.090854666000155, -2.871568467999907],
                [29.090890131000151, -2.871683182999902],
                [29.090950001000124, -2.871877000999973],
                [29.091045001000168, -2.872331000999964],
                [29.091379000000188, -2.873072],
                [29.091410440000118, -2.87336824599987],
                [29.091450000000179, -2.873740998999892],
                [29.091569000000106, -2.874047],
                [29.091662981000127, -2.874290059999964],
                [29.091637, -2.874370999999883],
                [29.091616001000148, -2.874433998999962],
                [29.091822117000163, -2.874640783999951],
                [29.091925, -2.874744],
                [29.092306000000121, -2.875055],
                [29.09238626500013, -2.875136112999883],
                [29.092590332000043, -2.875341890999948],
                [29.092762205000156, -2.875514861999875],
                [29.092852001000097, -2.875605],
                [29.09316100000018, -2.876107],
                [29.09316100000018, -2.876536999999871],
                [29.093112997000048, -2.877110001999938],
                [29.092782000000113, -2.877356],
                [29.092663000000186, -2.877445],
                [29.092663000000186, -2.877684],
                [29.092663000000186, -2.877922999999896],
                [29.092659, -2.878049],
                [29.092656770000019, -2.878116115999887],
                [29.092649460000132, -2.87834978199993],
                [29.09284109399999, -2.878438065999887],
                [29.092998, -2.878509999999892],
                [29.093019, -2.87852],
                [29.093111949000104, -2.878579600999956],
                [29.093281000000161, -2.878688],
                [29.093612834999988, -2.878926880999927],
                [29.093613000000119, -2.879405],
                [29.093613000000119, -2.88036],
                [29.093653, -2.880608],
                [29.093668414999968, -2.880708240999979],
                [29.093685151000102, -2.880814551999947],
                [29.093719392000025, -2.880902076999917],
                [29.09377883500008, -2.881052583999917],
                [29.094046, -2.88118],
                [29.094183, -2.881245],
                [29.094254498000055, -2.881591491999927],
                [29.094326000000137, -2.881938],
                [29.094444000000124, -2.882392],
                [29.094444000000124, -2.882822],
                [29.094111476000137, -2.883181349999973],
                [29.09396646100015, -2.883555803999911],
                [29.093899000000135, -2.88373],
                [29.093574000000103, -2.884178],
                [29.093516925000074, -2.884256080999876],
                [29.09350512300017, -2.884269438999922],
                [29.09256744400011, -2.885331153999914],
                [29.092531340000164, -2.885581149999894],
                [29.0925, -2.885801],
                [29.092495000000156, -2.885832999999877],
                [29.092211, -2.886406],
                [29.092100738000056, -2.886565588999929],
                [29.091831001000173, -2.886956000999874],
                [29.091306785000143, -2.887386292999963],
                [29.091211304000183, -2.887517124999874],
                [29.091045380000139, -2.887744664999957],
                [29.091010602000097, -2.887863533999848],
                [29.090971, -2.888],
                [29.090857000000142, -2.88839],
                [29.090857000000142, -2.88898799999987],
                [29.090666000000169, -2.889537],
                [29.090214, -2.890038999999888],
                [29.090091, -2.890439],
                [29.090078789000188, -2.890478685999938],
                [29.090023042000098, -2.890660285999957],
                [29.09011840900007, -2.891735553999922],
                [29.09017201100005, -2.892142331999935],
                [29.090183, -2.892225],
                [29.09019097200013, -2.892285786999878],
                [29.090231132000042, -2.892382248999979],
                [29.090419215000111, -2.892833320999898],
                [29.089968000000169, -2.893842],
                [29.08969700900019, -2.895663939999906],
                [29.089783275000059, -2.895639796999944],
                [29.089914435000082, -2.895603052999945],
                [29.090000000000146, -2.895578999999884],
                [29.090000000000146, -2.896133],
                [29.089790249000089, -2.896606037999959],
                [29.089714026000081, -2.896777944999883],
                [29.08969100000013, -2.89728],
                [29.089676000000168, -2.897313],
                [29.089477750000071, -2.897734875999902],
                [29.089396, -2.898021],
                [29.08936657400011, -2.898123040999963],
                [29.089334487000031, -2.898235558999943],
                [29.089334487000031, -2.898976563999895],
                [29.089359283000135, -2.899478196999837],
                [29.089380867000159, -2.899561095999957],
                [29.089394, -2.899610999999879],
                [29.089477400000078, -2.899929705999966],
                [29.089549502000182, -2.900266511999973],
                [29.089621000000193, -2.900601],
                [29.089651, -2.900948999999855],
                [29.089668000000188, -2.901151],
                [29.090118000000132, -2.901629],
                [29.090286000000162, -2.903039],
                [29.090332, -2.903852],
                [29.090338, -2.904016],
                [29.090356998000175, -2.904544957999917],
                [29.090595000000178, -2.904999],
                [29.090648000000101, -2.905146999999829],
                [29.090713755000138, -2.905332309999949],
                [29.090726183000072, -2.905650857999945],
                [29.090736390000131, -2.905906677999894],
                [29.091125489000092, -2.906928777999895],
                [29.091567994000172, -2.907555579999894],
                [29.091622046000055, -2.907670979999921],
                [29.09167, -2.907773],
                [29.091735794000044, -2.907913558999951],
                [29.091751, -2.907926],
                [29.091778000000147, -2.907949],
                [29.09189, -2.908301],
                [29.092074192000155, -2.908882604999917],
                [29.09223938100007, -2.909403084999894],
                [29.09309005800003, -2.910952806999944],
                [29.093311440000036, -2.911357371999941],
                [29.093480284000123, -2.911665693999964],
                [29.093689, -2.912046],
                [29.094072, -2.912538],
                [29.094160006000095, -2.9129080099999],
                [29.094373917000098, -2.913314841999977],
                [29.094403176000128, -2.913350659999935],
                [29.094419000000187, -2.91337],
                [29.094706000000144, -2.913721],
                [29.094935, -2.913982],
                [29.095062, -2.914127],
                [29.095171315000186, -2.914285184999926],
                [29.095232001000056, -2.914373000999888],
                [29.095265, -2.914421],
                [29.095276, -2.914438],
                [29.095353, -2.914696999999876],
                [29.095395972000176, -2.914843902999962],
                [29.095491, -2.91554],
                [29.095541000000139, -2.915906999999891],
                [29.095606000000146, -2.916225999999824],
                [29.095658386000082, -2.916488453999932],
                [29.095705033000115, -2.91672158199998],
                [29.09578658300012, -2.916825886999959],
                [29.095844000000113, -2.916898999999887],
                [29.095919000000151, -2.916995],
                [29.095858, -2.917282999999884],
                [29.096126306000031, -2.918004547999885],
                [29.096314602000177, -2.918510932999936],
                [29.096279, -2.918733999999859],
                [29.096228501000041, -2.919054490999884],
                [29.096178, -2.919375],
                [29.096292, -2.919632],
                [29.096721556000091, -2.920604994999962],
                [29.097728819000054, -2.921114906999947],
                [29.098360062000154, -2.921022891999883],
                [29.09869559100008, -2.920894023999949],
                [29.099674000000107, -2.920517998999912],
                [29.09988609100003, -2.920618153999953],
                [29.099998000000141, -2.920671],
                [29.100041000000147, -2.920728999999881],
                [29.100052000000119, -2.920744],
                [29.100052000000119, -2.921424],
                [29.100159000000133, -2.921719],
                [29.100299419000123, -2.922106913999869],
                [29.10059738200016, -2.922929047999958],
                [29.100683212000149, -2.923052548999863],
                [29.101106263000077, -2.923685189999958],
                [29.101308004000146, -2.923986513999864],
                [29.102234, -2.92441],
                [29.10229482200009, -2.924437757999897],
                [29.102382661000149, -2.924477815999921],
                [29.102680207000105, -2.924613],
                [29.104581832000122, -2.925293921999923],
                [29.105517224999971, -2.925081577999947],
                [29.105577001000142, -2.925067998999907],
                [29.106185564000157, -2.924524985999938],
                [29.106393814000114, -2.924339056999884],
                [29.106438813000011, -2.924167705999935],
                [29.106545596000103, -2.923759547999907],
                [29.106754, -2.922973],
                [29.107082, -2.922800022999923],
                [29.107704000000183, -2.922471998999868],
                [29.107986033000145, -2.922436518999973],
                [29.108791311000175, -2.92233521299994],
                [29.109506, -2.922536],
                [29.109917000000166, -2.922652],
                [29.109943000000158, -2.922683999999833],
                [29.109953250000103, -2.922696947999839],
                [29.109962439000071, -2.922708240999953],
                [29.109982000000173, -2.922719],
                [29.110536681000156, -2.923040814999865],
                [29.11086003600019, -2.923833633999891],
                [29.111139, -2.924184],
                [29.111233000000141, -2.924302],
                [29.111748, -2.924689999999885],
                [29.111961129000065, -2.92497610199996],
                [29.11200016600003, -2.925007106999942],
                [29.112413406000087, -2.925334690999932],
                [29.112865447000104, -2.925693034999938],
                [29.112978448000149, -2.925767709999889],
                [29.113152000000127, -2.925881998999955],
                [29.114041766000184, -2.926156741999932],
                [29.11449524700015, -2.926296767999872],
                [29.114514998000061, -2.9263089989999],
                [29.114534001000152, -2.926321],
                [29.115014000000144, -2.926623],
                [29.115433, -2.926888],
                [29.116537497000024, -2.927586728999927],
                [29.116744994000044, -2.927717922999875],
                [29.117452622000144, -2.928046702999893],
                [29.118347169000174, -2.928462981999871],
                [29.118980409000187, -2.928962945999956],
                [29.119373352000082, -2.929206461999911],
                [29.119434000000126, -2.929244],
                [29.119568000000186, -2.929327],
                [29.119965351000133, -2.929473586999905],
                [29.120430002000091, -2.92964499999988],
                [29.12052505000014, -2.929711987999838],
                [29.120535, -2.929719],
                [29.120563000000118, -2.929738],
                [29.120719791, -2.929849314999899],
                [29.120794296000042, -2.929901837999978],
                [29.121578216000103, -2.930454730999941],
                [29.122240033000026, -2.930922006999936],
                [29.122483173000091, -2.930971846999967],
                [29.124006260000044, -2.931283947999873],
                [29.125139000000161, -2.931056999999839],
                [29.125934, -2.930741],
                [29.127312000000188, -2.930192],
                [29.127647506000073, -2.930152998999915],
                [29.127983000000143, -2.930114],
                [29.128491, -2.930133],
                [29.128548000000137, -2.930135],
                [29.128632, -2.930212],
                [29.128729225000143, -2.930354788999978],
                [29.128897, -2.930601999999851],
                [29.129433001000109, -2.931335],
                [29.129477760000157, -2.931395252999948],
                [29.129511, -2.931439999999896],
                [29.129523499000129, -2.93145049899988],
                [29.129536, -2.931460998999967],
                [29.129846999000051, -2.931700998999929],
                [29.130457001000082, -2.932174],
                [29.130559006000112, -2.932253003999961],
                [29.130661, -2.932332],
                [29.130675364000126, -2.932360904999882],
                [29.131069, -2.933152998999958],
                [29.131214000000114, -2.933574],
                [29.131344, -2.933952],
                [29.131428, -2.934199],
                [29.131454763000079, -2.934277849999944],
                [29.131521224000153, -2.934473036999862],
                [29.131716993000055, -2.934949797999877],
                [29.132069097000056, -2.935806372999934],
                [29.132112000000177, -2.935911],
                [29.132131500000185, -2.935959306999905],
                [29.132156371000178, -2.936019896999937],
                [29.13333511400009, -2.936886071999936],
                [29.133347137000158, -2.936894690999964],
                [29.133652001000144, -2.937113],
                [29.133770858000105, -2.937098706999962],
                [29.13478290400019, -2.936976971999911],
                [29.135754738000117, -2.937745965999966],
                [29.135775, -2.937761998999918],
                [29.135955009999975, -2.937904007999975],
                [29.136135000000138, -2.938045999999815],
                [29.136247732000072, -2.938159729999882],
                [29.136865350000164, -2.938317962999918],
                [29.137435492000122, -2.938463847999913],
                [29.138752000000125, -2.939193],
                [29.13884800000011, -2.939281],
                [29.138975631000051, -2.939398125999958],
                [29.139091099000041, -2.939504025999838],
                [29.139254000000108, -2.939547],
                [29.139365585000178, -2.939576888999909],
                [29.139936, -2.940341999999873],
                [29.140318, -2.94082],
                [29.14036485600019, -2.941047529999935],
                [29.140436000000193, -2.941392999999891],
                [29.140436000000193, -2.942397],
                [29.140495000000101, -2.94276699999989],
                [29.140500909000139, -2.942804421999881],
                [29.14097045900013, -2.943852391999826],
                [29.141821, -2.94575],
                [29.14259, -2.946887999999888],
                [29.145947, -2.947737],
                [29.1461240000001, -2.947782],
                [29.146266000000139, -2.94782],
                [29.146691574000158, -2.947935288999872],
                [29.147163392000095, -2.948062657999913],
                [29.147674396000127, -2.948200755999892],
                [29.147704935999968, -2.948208982999972],
                [29.147771000000148, -2.948241],
                [29.148618261000138, -2.94865516099992],
                [29.149471283000025, -2.949071882999931],
                [29.15060422900018, -2.949255937999965],
                [29.151464000000146, -2.949027],
                [29.151735, -2.948708],
                [29.152132742000049, -2.947543287999963],
                [29.15227889900018, -2.94711494399985],
                [29.152028993999977, -2.946848005999868],
                [29.152058, -2.946817],
                [29.152209481999989, -2.946785374999934],
                [29.152536822000116, -2.946717034999949],
                [29.152627945000063, -2.946697949999873],
                [29.152654, -2.94669],
                [29.152866000000131, -2.946626],
                [29.153130898000029, -2.946626],
                [29.152793000000145, -2.946848999999872],
                [29.152733000000183, -2.946887999999888],
                [29.152692999000124, -2.946980001999975],
                [29.152653001000033, -2.947072],
                [29.152738365000175, -2.947388475999901],
                [29.152816999000095, -2.947679995999977],
                [29.153312789999973, -2.947679998999888],
                [29.153429878999987, -2.947836699999868],
                [29.15376525400012, -2.948285535999958],
                [29.154266357000097, -2.948956012999929],
                [29.154525037000042, -2.949106981999933],
                [29.154791000000102, -2.949261999999862],
                [29.155124031000184, -2.949456433999956],
                [29.154852007000102, -2.95198495499983],
                [29.156906001000038, -2.952559999999892],
                [29.157495, -2.953492],
                [29.158079222000026, -2.954416853999874],
                [29.158084995000081, -2.954425989999834],
                [29.160182952000184, -2.953438997999967],
                [29.160335834000023, -2.953287649999879],
                [29.160481034000156, -2.953143895999972],
                [29.160492, -2.953133],
                [29.160662, -2.953771],
                [29.160682994000183, -2.954112715999941],
                [29.160708861999979, -2.95453374799996],
                [29.160706354000126, -2.955055467999955],
                [29.160704001000056, -2.955518],
                [29.160995, -2.956023],
                [29.161183772000015, -2.956351603999906],
                [29.16130800000019, -2.956739],
                [29.161356, -2.956891],
                [29.16197065700004, -2.957337628999937],
                [29.163623917000052, -2.958538936999958],
                [29.163831883, -2.958665927999903],
                [29.163990001000116, -2.958693998999934],
                [29.164394, -2.958932998999956],
                [29.164701, -2.959092999999882],
                [29.164988, -2.959243],
                [29.16518786000006, -2.959323615999949],
                [29.165464402000168, -2.959434508999891],
                [29.166034699000193, -2.959625719999906],
                [29.16655721300009, -2.959768982999947],
                [29.166811386000063, -2.95974924199993],
                [29.167175000000157, -2.959720999999888],
                [29.16767300000015, -2.959683],
                [29.167745794000098, -2.959677016999876],
                [29.167700001000128, -2.960224],
                [29.166508422000049, -2.962585967999871],
                [29.166069030000131, -2.963457106999954],
                [29.166069080000113, -2.963484479999977],
                [29.16607, -2.963800999999876],
                [29.16607099700019, -2.964502954999944],
                [29.166342001000032, -2.966006999999877],
                [29.166462001000014, -2.966193],
                [29.167063390000123, -2.96711790199987],
                [29.167200061000187, -2.96732802799994],
                [29.167357214000106, -2.967401934999941],
                [29.167953400000044, -2.967682307999951],
                [29.16827268000003, -2.967832956999871],
                [29.168418948000067, -2.967901974999904],
                [29.168649785000071, -2.968010896999942],
                [29.168570000000102, -2.968192],
                [29.168468000000189, -2.968420999999864],
                [29.168133000000182, -2.968855],
                [29.167835, -2.969241],
                [29.167095001000177, -2.96939],
                [29.167042527000092, -2.969400430999883],
                [29.166929246000052, -2.969423054999822],
                [29.166445421999981, -2.969531121999978],
                [29.166115, -2.969605],
                [29.165965375000155, -2.969948617999876],
                [29.16579818800011, -2.970333099999891],
                [29.165776520000065, -2.970516251999925],
                [29.165766, -2.970606],
                [29.165707000000168, -2.971107],
                [29.165992519000099, -2.971500764999973],
                [29.166071000000102, -2.971609],
                [29.16683600000016, -2.973398],
                [29.167347000000177, -2.974298],
                [29.167357159, -2.974316092999914],
                [29.167379379000067, -2.974354982999955],
                [29.168271144000073, -2.975450997999928],
                [29.168968103999987, -2.976307214999906],
                [29.168985000000134, -2.976330999999846],
                [29.169004000000143, -2.97636],
                [29.169155, -2.977659998999911],
                [29.169228277000116, -2.977921497999887],
                [29.169563, -2.979116],
                [29.170370000000162, -2.980438],
                [29.170786000000135, -2.98112],
                [29.17100100000016, -2.981485],
                [29.171375000000182, -2.982120999999893],
                [29.171192000000133, -2.984079999999892],
                [29.170845, -2.984444999999823],
                [29.170197, -2.985126],
                [29.168522, -2.98703799999987],
                [29.168205482000133, -2.987721038999894],
                [29.167889, -2.988404000999935],
                [29.167692178000152, -2.989712325999903],
                [29.167629178000084, -2.990122381999925],
                [29.167561, -2.990248999999892],
                [29.167418041000076, -2.990311363999979],
                [29.167114, -2.990444],
                [29.166575515000034, -2.99068116199993],
                [29.166340274000163, -2.990760070999897],
                [29.165625000000148, -2.991000000999975],
                [29.16551148200017, -2.991250539999896],
                [29.165398, -2.991501],
                [29.165508083000134, -2.992205534999869],
                [29.165533065000091, -2.99236488199989],
                [29.165634214000193, -2.992567799999904],
                [29.165806, -2.992911999999876],
                [29.165902910000057, -2.992995754999868],
                [29.166244196000036, -2.993290714999944],
                [29.166784049000057, -2.993755886999963],
                [29.167072000000132, -2.994004],
                [29.167316, -2.994816],
                [29.167797000000121, -2.996416998999962],
                [29.167984000000104, -2.996539998999936],
                [29.168707216000087, -2.997014610999941],
                [29.168975939, -2.997190958999966],
                [29.169005384000059, -2.997192793999943],
                [29.169369000000131, -2.997215],
                [29.169698285000095, -2.997235953999962],
                [29.170287999000038, -2.997235998999884],
                [29.171042999000178, -2.996589],
                [29.171075, -2.996506],
                [29.171096430000091, -2.996450475999893],
                [29.17134701399999, -2.99619798599997],
                [29.171597, -2.995946999999887],
                [29.172185502000104, -2.99551162399996],
                [29.172307999000054, -2.995420998999975],
                [29.173117000000104, -2.994870999999876],
                [29.173344993000057, -2.994682510999894],
                [29.173521042000118, -2.994536875999927],
                [29.17357, -2.994483],
                [29.173830000000123, -2.994201999999859],
                [29.174613983000029, -2.993848007999873],
                [29.174620000000118, -2.993813999999816],
                [29.174755, -2.993669999999895],
                [29.174781917000075, -2.993642085999966],
                [29.175041000000135, -2.993366],
                [29.175493, -2.993175],
                [29.175605, -2.993114999999875],
                [29.17598, -2.992915],
                [29.176291712000022, -2.992772778999893],
                [29.176562999, -2.992648998999869],
                [29.176977634000139, -2.992441010999812],
                [29.17718124400011, -2.992338657999937],
                [29.177225645000135, -2.992310502999942],
                [29.177286, -2.992272],
                [29.17729454900018, -2.99231645499998],
                [29.17732100000012, -2.992453999999896],
                [29.177420000000154, -2.992969],
                [29.177971, -2.993718],
                [29.178326000000141, -2.994197999999869],
                [29.178826427000047, -2.994569076999937],
                [29.179653167000083, -2.995181560999924],
                [29.179891586000167, -2.995277642999952],
                [29.179933442000106, -2.995300368999949],
                [29.180068, -2.995373],
                [29.180508, -2.995611999999881],
                [29.18070500000016, -2.995673],
                [29.180816829000094, -2.995707946999914],
                [29.180945000000122, -2.995756],
                [29.181198000000109, -2.995851],
                [29.181549000000189, -2.996081999999888],
                [29.182072000000119, -2.996295],
                [29.182130000000143, -2.996307],
                [29.18245700000017, -2.996377],
                [29.182766000000186, -2.996879],
                [29.182835000000182, -2.996991],
                [29.183064000000115, -2.997364999999888],
                [29.18342, -2.99787],
                [29.183548311000152, -2.998052937999887],
                [29.183622360000129, -2.998157977999881],
                [29.18452040599999, -2.999580073999937],
                [29.185116950000179, -3.000524338999924],
                [29.185169525000163, -3.000701455999831],
                [29.185345, -3.001299],
                [29.184891000000107, -3.002027999999882],
                [29.184336, -3.002447],
                [29.183846000000187, -3.003214],
                [29.183671823000054, -3.003486251999959],
                [29.183521271000018, -3.003721951999978],
                [29.183565140000042, -3.004678010999953],
                [29.183823453000173, -3.005266239999969],
                [29.184065, -3.005815999999868],
                [29.184153000000151, -3.006327999999883],
                [29.184223328000144, -3.00674146599988],
                [29.184244156999966, -3.006863116999909],
                [29.184698106000155, -3.008456945999967],
                [29.18473451300008, -3.008524907999913],
                [29.184805, -3.008656],
                [29.185014196, -3.009046498999908],
                [29.185693956000136, -3.009366978999879],
                [29.185703997000132, -3.009366544999921],
                [29.185847998999975, -3.009359998999912],
                [29.186917589000188, -3.009313019999922],
                [29.187897000000135, -3.00927],
                [29.18848147, -3.009244615999876],
                [29.188817979000078, -3.009229896999898],
                [29.190759660000026, -3.00909996099989],
                [29.190820693000035, -3.008991955999932],
                [29.190885626000181, -3.00893299299986],
                [29.191051702000038, -3.00878198199996],
                [29.191204, -3.008777999999893],
                [29.192297, -3.008777999999893],
                [29.193392, -3.00873],
                [29.19408847800014, -3.009278740999946],
                [29.194150980000131, -3.009327982999935],
                [29.194365000000118, -3.00983],
                [29.194365000000118, -3.010402999999883],
                [29.194365000000118, -3.010952998999926],
                [29.194365000000118, -3.011717],
                [29.194379, -3.012032],
                [29.194383217999984, -3.012131134999947],
                [29.194389343000068, -3.01226687399992],
                [29.194412224000075, -3.012601274999952],
                [29.194443000000149, -3.012639],
                [29.194602789000157, -3.012840733999951],
                [29.194705477000127, -3.013028285999951],
                [29.194864000000166, -3.013317999999856],
                [29.19526900000011, -3.013771999999847],
                [29.195466, -3.014404],
                [29.195498801000042, -3.014510401999928],
                [29.195507050000117, -3.014536857999872],
                [29.195555783000145, -3.014620569999977],
                [29.195744001000094, -3.014943000999836],
                [29.196005, -3.015469],
                [29.196005, -3.015971],
                [29.196009217000153, -3.015980455999966],
                [29.196313858000053, -3.016663550999908],
                [29.196334766000177, -3.016708529999903],
                [29.196411000000126, -3.016872],
                [29.196541, -3.017151999999896],
                [29.196875000000148, -3.017541],
                [29.19730135500015, -3.018037537999817],
                [29.197360992000029, -3.01810693799996],
                [29.197429686000191, -3.018033161999881],
                [29.197441, -3.018021],
                [29.197548, -3.017906],
                [29.197584280000171, -3.017932929999972],
                [29.197645001000126, -3.017977998999868],
                [29.198072001000128, -3.018264999999872],
                [29.198949158000119, -3.019122808999896],
                [29.199023572999977, -3.019195584999977],
                [29.199286000000143, -3.019507],
                [29.199570000000165, -3.019841],
                [29.200047, -3.02010399999989],
                [29.200331, -3.020391],
                [29.200548909000076, -3.020694238999965],
                [29.20064, -3.020820999999899],
                [29.200839638000048, -3.021040524999933],
                [29.201162338000131, -3.0213947299999],
                [29.201336244000117, -3.021514507999825],
                [29.201544, -3.021656998999958],
                [29.20176901300016, -3.021812508999972],
                [29.201994000000184, -3.021968],
                [29.202148485000066, -3.022146981999924],
                [29.202303, -3.022325998999975],
                [29.202611999000055, -3.02256499899994],
                [29.202803001000063, -3.022709],
                [29.203230000000133, -3.022899998999947],
                [29.203281000000118, -3.022932],
                [29.203289467000161, -3.022937330999866],
                [29.203308105000133, -3.022948979999853],
                [29.203506971000024, -3.022959927999978],
                [29.203836, -3.022977999999853],
                [29.204288, -3.02265899899993],
                [29.20438, -3.02247599999987],
                [29.204396141000075, -3.022444419999943],
                [29.204402924000192, -3.022430895999946],
                [29.204456023000034, -3.022324617999971],
                [29.204561000000126, -3.022113998999942],
                [29.204562990000113, -3.02209298199989],
                [29.204586, -3.02185],
                [29.204590535000193, -3.021802105999882],
                [29.204610956000181, -3.021586473999889],
                [29.204651000000126, -3.021157],
                [29.204691, -3.021135],
                [29.204909000000157, -3.021012999999869],
                [29.205059000000119, -3.020929],
                [29.205139705000136, -3.02099076199994],
                [29.205566406000173, -3.021317003999911],
                [29.206503436000105, -3.022027894999951],
                [29.206915, -3.02234],
                [29.207639703000041, -3.02252292299994],
                [29.21009635900009, -3.022672891999832],
                [29.212223053, -3.022353886999895],
                [29.213808753000137, -3.021838038999931],
                [29.213898001000075, -3.021808998999916],
                [29.213950423000142, -3.021806078999873],
                [29.214975, -3.021749],
                [29.215085000000101, -3.021765],
                [29.215609000000143, -3.02184],
                [29.216301, -3.022378],
                [29.21649069100016, -3.022525745999928],
                [29.216604233000169, -3.022614001999955],
                [29.218143455000131, -3.023159975999874],
                [29.218225332000088, -3.023155864999978],
                [29.219955333000144, -3.023068910999825],
                [29.219996432000073, -3.023057944999891],
                [29.220356000000152, -3.022962],
                [29.220597, -3.022797],
                [29.221142000000157, -3.022425],
                [29.221581, -3.022281],
                [29.22171, -3.022239],
                [29.222507000000121, -3.021978999999874],
                [29.223600000000147, -3.021100999999874],
                [29.223837, -3.020911],
                [29.224335704999987, -3.020760684999971],
                [29.224828939000133, -3.020612017999952],
                [29.225620005000053, -3.020806003999894],
                [29.226797104000184, -3.021717071999888],
                [29.227022171000044, -3.022717951999937],
                [29.227206163000176, -3.023424970999884],
                [29.227295, -3.023766],
                [29.227974, -3.025267999999869],
                [29.228833, -3.026218],
                [29.22886497900015, -3.026253380999947],
                [29.228879929000129, -3.026269912999908],
                [29.229349478000131, -3.026426819999927],
                [29.229831352000076, -3.026587783999901],
                [29.233409881000114, -3.025995969999883],
                [29.234359876000099, -3.025541040999826],
                [29.23439292900008, -3.02553820299994],
                [29.234877754000138, -3.025496573999874],
                [29.235944847000098, -3.025405009999929],
                [29.235893000000146, -3.027086],
                [29.235853000000191, -3.028363999999897],
                [29.235751000000107, -3.028567999999893],
                [29.235535, -3.029002],
                [29.234541, -3.030094],
                [29.233702194000045, -3.030316379999874],
                [29.233678256000189, -3.030322630999933],
                [29.233568000000162, -3.03034799999989],
                [29.232125201000031, -3.03067929499997],
                [29.232096, -3.030686000999879],
                [29.231733541000153, -3.031117950999942],
                [29.231370998000102, -3.031550036999875],
                [29.231355412000028, -3.031905152999968],
                [29.231325150000032, -3.032598017999931],
                [29.233770370000173, -3.035693884999944],
                [29.2351284020001, -3.03605794899994],
                [29.235944747000076, -3.036011934999863],
                [29.236522126000125, -3.035975704999885],
                [29.237392001000103, -3.03592099899987],
                [29.237952000000121, -3.035979999999881],
                [29.238215175000164, -3.036007978999976],
                [29.238252640000155, -3.036011934999863],
                [29.240472887000067, -3.036057982999921],
                [29.241151780000052, -3.03651681599996],
                [29.241114141000025, -3.037000392999914],
                [29.241106034000154, -3.037105082999972],
                [29.24051001600003, -3.037754168999925],
                [29.240017763000083, -3.038290245999974],
                [29.239875, -3.038433999999882],
                [29.23972445600009, -3.038585441999885],
                [29.239204407000102, -3.039108991999967],
                [29.239067078000062, -3.040292024999872],
                [29.23919234499999, -3.040588818999936],
                [29.239566959000115, -3.041475898999977],
                [29.241874842000186, -3.043250877999981],
                [29.242158891000145, -3.042943714999979],
                [29.242258073000187, -3.043018817999894],
                [29.24297142100005, -3.043520688999934],
                [29.243280411000057, -3.043711900999938],
                [29.244016648000127, -3.043711900999938],
                [29.244105913000112, -3.0437287069999],
                [29.244778001000157, -3.043854998999962],
                [29.244981185000086, -3.044162639999968],
                [29.245062, -3.044285],
                [29.245258431000082, -3.044513360999929],
                [29.245547954000187, -3.044849946999875],
                [29.245453000000111, -3.044935999999893],
                [29.246403, -3.046394],
                [29.247763000000134, -3.04744],
                [29.24940502600009, -3.048506711999949],
                [29.249935001000154, -3.048851],
                [29.250209000000154, -3.049050999999849],
                [29.250930038000149, -3.049579294999944],
                [29.252064000000189, -3.050853998999969],
                [29.252483299000119, -3.051125584999966],
                [29.253328611000086, -3.051673100999892],
                [29.253435, -3.051763999999878],
                [29.253548, -3.051861],
                [29.253649454000083, -3.052020515999971],
                [29.253714000000116, -3.052121998999837],
                [29.253872858000079, -3.052313612999967],
                [29.253907788000163, -3.052356492999877],
                [29.253837307000083, -3.052521062999972],
                [29.253693000000112, -3.052858],
                [29.252472000000182, -3.053266999999892],
                [29.252383055, -3.053336541999954],
                [29.251656001000072, -3.053905000999919],
                [29.251656001000072, -3.055178999999896],
                [29.251831529000015, -3.055777497999941],
                [29.251883000000134, -3.055953],
                [29.25204098200004, -3.056065657999909],
                [29.252126794000048, -3.056126853999899],
                [29.252120998000123, -3.056710076999934],
                [29.252115000000117, -3.056968999999867],
                [29.25209800000016, -3.05769],
                [29.25209800000016, -3.058238998999968],
                [29.25209800000016, -3.058564],
                [29.252097547, -3.058670369999959],
                [29.252055000000155, -3.058799],
                [29.252003000000116, -3.058956],
                [29.251913000000116, -3.059117],
                [29.251789000000144, -3.059337999999855],
                [29.251255958000115, -3.059625328999914],
                [29.251123014000143, -3.059696992999875],
                [29.250219346000165, -3.060389756999939],
                [29.25020633000014, -3.060401655999954],
                [29.250169, -3.060436],
                [29.249958, -3.060628999999892],
                [29.249918463000029, -3.060678995999979],
                [29.249769000000185, -3.06086800099996],
                [29.249671262000106, -3.060949333999929],
                [29.249483109000039, -3.061106442999972],
                [29.24946022000006, -3.06141710299994],
                [29.24946022000006, -3.061756133999893],
                [29.249727248000113, -3.062644004999925],
                [29.249982287000137, -3.062926845999868],
                [29.250079, -3.063034],
                [29.250309, -3.063288999999827],
                [29.250446555, -3.063378636999971],
                [29.250553, -3.063448],
                [29.250957, -3.063544],
                [29.251190000000179, -3.063672999999881],
                [29.251384808000125, -3.063781892999941],
                [29.251659665000034, -3.063980552999908],
                [29.251717001000145, -3.064022],
                [29.252455, -3.064404],
                [29.252573, -3.064497],
                [29.253214000000128, -3.064643],
                [29.253415, -3.064726],
                [29.253500000000145, -3.064762],
                [29.254356, -3.064762],
                [29.254411474000051, -3.064766966999969],
                [29.254556957000148, -3.064779995999913],
                [29.254698000000133, -3.064908],
                [29.254990476000046, -3.065174576999937],
                [29.255161285000042, -3.065330028999938],
                [29.254980087000149, -3.06624102599983],
                [29.254934312000159, -3.067514895999977],
                [29.255388249000134, -3.068061100999955],
                [29.255425000000116, -3.068076],
                [29.255983696000158, -3.068308404999925],
                [29.256151148000129, -3.068377949999956],
                [29.256271650000144, -3.068337479999912],
                [29.256681, -3.0682],
                [29.256808610000178, -3.068162650999909],
                [29.256926999000086, -3.068127999999888],
                [29.256868000000111, -3.068209999999851],
                [29.256804000000159, -3.068298],
                [29.25674180600015, -3.068737736999879],
                [29.256732939000017, -3.068801925999878],
                [29.256726, -3.069021],
                [29.256725542000027, -3.069035436999911],
                [29.256708145000118, -3.069588660999955],
                [29.256823032000113, -3.069922803999816],
                [29.256922000000145, -3.070209998999928],
                [29.257106, -3.07043],
                [29.257152698000027, -3.070485879999865],
                [29.257284165000158, -3.070642947999886],
                [29.256561279000039, -3.07164597499991],
                [29.255613327000106, -3.072796105999942],
                [29.255161285000042, -3.074024915999928],
                [29.255262375000029, -3.075205325999889],
                [29.255166664000058, -3.075416100999917],
                [29.255136000000164, -3.075484],
                [29.255068001000154, -3.075634],
                [29.255068001000154, -3.075939998999957],
                [29.255068001000154, -3.076135],
                [29.255068001000154, -3.076875998999924],
                [29.255402000000117, -3.07749699999988],
                [29.255854, -3.077856],
                [29.255978, -3.078064],
                [29.256067, -3.078213999999889],
                [29.256351000000166, -3.078907],
                [29.256351000000166, -3.079433],
                [29.256340000000137, -3.07977],
                [29.256328192000012, -3.080079070999943],
                [29.256089000000145, -3.080396],
                [29.25594264700004, -3.080589218999933],
                [29.255733490000011, -3.080866336999975],
                [29.255203247000111, -3.081435917999841],
                [29.255292632000135, -3.081557347999933],
                [29.255398000000184, -3.081699999999842],
                [29.255478, -3.081809],
                [29.257515000000183, -3.083586],
                [29.257517000000178, -3.084541988999888],
                [29.257459389000132, -3.085115276999886],
                [29.257456000000104, -3.085149],
                [29.257396007000182, -3.085733433999963],
                [29.257336000000123, -3.086318],
                [29.256701000000135, -3.087956],
                [29.256134016000146, -3.088207435999948],
                [29.255568151000148, -3.088457923999954],
                [29.255180152000094, -3.088423429999978],
                [29.254028000000119, -3.088320999999837],
                [29.252949750000084, -3.088176492999935],
                [29.252670288000047, -3.088139056999978],
                [29.252591932000144, -3.088203657999884],
                [29.252507, -3.088274],
                [29.252410619000159, -3.088353312999914],
                [29.252321000000109, -3.088427],
                [29.25226073500005, -3.08847634499989],
                [29.252172470000119, -3.088548898999932],
                [29.251932970000155, -3.089030537999918],
                [29.251897, -3.089103],
                [29.251675000000148, -3.089550000999907],
                [29.25231, -3.091098],
                [29.253572, -3.093681],
                [29.253622000000121, -3.093783999999857],
                [29.253622000000121, -3.094602],
                [29.251329000000112, -3.0944],
                [29.249002162000124, -3.094194014999914],
                [29.248529434000091, -3.09432005899987],
                [29.247463227000082, -3.09460306099993],
                [29.246803712000144, -3.095211866999932],
                [29.24677, -3.095242999999869],
                [29.246378000999982, -3.095604998999875],
                [29.246391397000025, -3.095704334999937],
                [29.246513367000034, -3.096606016999942],
                [29.247014999000157, -3.097229957999957],
                [29.247282009, -3.097563002999891],
                [29.247592745000134, -3.097692662999975],
                [29.250225, -3.098790999999892],
                [29.250839, -3.099191],
                [29.251892304000023, -3.099877678999917],
                [29.251902, -3.099884],
                [29.252609000000177, -3.100563],
                [29.252794420000043, -3.100741337999978],
                [29.252897216000179, -3.100840047999952],
                [29.253527, -3.100980999999877],
                [29.253714000000116, -3.10114],
                [29.254011001000094, -3.101361998999892],
                [29.254259, -3.101546],
                [29.254324000000111, -3.101972],
                [29.254396031000113, -3.102442650999933],
                [29.254426968000132, -3.102645128999939],
                [29.254236000000105, -3.103409999999883],
                [29.25374247200017, -3.103832146999821],
                [29.253593, -3.10396],
                [29.253208000000143, -3.103972],
                [29.253040786000156, -3.103976917999887],
                [29.25283432100008, -3.103983401999813],
                [29.252519162000169, -3.104033373999926],
                [29.252409000000114, -3.104051],
                [29.251981999000066, -3.104119000999901],
                [29.251930001000176, -3.104127],
                [29.25159834100009, -3.10434178],
                [29.251337051000178, -3.104843377999885],
                [29.251158186, -3.10511224399994],
                [29.250813001000154, -3.105632],
                [29.250750000000153, -3.106186998999874],
                [29.250723569, -3.106200859999944],
                [29.250667813, -3.106230348999929],
                [29.25047700000016, -3.106587],
                [29.250373384000113, -3.106779512999879],
                [29.25030136, -3.106914041999971],
                [29.250372051000056, -3.107265653999946],
                [29.250439, -3.107597],
                [29.250762000000122, -3.10795],
                [29.251009273000022, -3.108221274999892],
                [29.251205445000096, -3.108435868999948],
                [29.251480103000119, -3.108735084999921],
                [29.251490622000176, -3.108746272999895],
                [29.251578000000109, -3.108839],
                [29.252539, -3.109861],
                [29.252781, -3.110117999999886],
                [29.25302100000016, -3.110373],
                [29.255102000000193, -3.111193],
                [29.255396000000189, -3.112156],
                [29.255602000000181, -3.112833],
                [29.256371, -3.113651999999888],
                [29.256955555000161, -3.113978748999955],
                [29.257184982000069, -3.114106893999974],
                [29.257323911000071, -3.114102766999963],
                [29.257907000000102, -3.114085],
                [29.258724173000132, -3.114061053999819],
                [29.258741313000144, -3.114063377999912],
                [29.260407, -3.114322],
                [29.260489128000074, -3.114334862999897],
                [29.260578480000163, -3.114902425999958],
                [29.260582000000113, -3.114925],
                [29.260396000000185, -3.114832],
                [29.260197793000032, -3.114732674999971],
                [29.259948730000133, -3.114608049999958],
                [29.259176254000124, -3.114608049999958],
                [29.258708986000045, -3.114770266999869],
                [29.258350000000178, -3.114894999999876],
                [29.257872059000135, -3.115060896999921],
                [29.257860664000191, -3.115066577999869],
                [29.257250000000113, -3.115475999999831],
                [29.25673073300004, -3.115823882999905],
                [29.256235123000067, -3.116156099999955],
                [29.256028404000119, -3.116473407999933],
                [29.255465000000186, -3.117339],
                [29.254803000000152, -3.118559999999889],
                [29.254401262000101, -3.119300940999949],
                [29.254379, -3.11934200099995],
                [29.25456, -3.122346999999877],
                [29.254070360000071, -3.123147734999975],
                [29.253834970000185, -3.123531806999949],
                [29.253105321000078, -3.12410445699993],
                [29.252386000000172, -3.124669],
                [29.251434, -3.124623],
                [29.250528, -3.124303999999825],
                [29.249126000000103, -3.122711],
                [29.248039184000049, -3.122302068999829],
                [29.247667312000033, -3.122390031999885],
                [29.247488769000086, -3.122432305999951],
                [29.247271, -3.122484000999975],
                [29.246546000000137, -3.123395],
                [29.246574481000096, -3.123860939999929],
                [29.246635438000055, -3.124850987999935],
                [29.247087479000072, -3.126079082999979],
                [29.249172209999983, -3.128401993999944],
                [29.249209912000083, -3.128668159999961],
                [29.249221000000148, -3.128746],
                [29.249307764000037, -3.129357944999924],
                [29.248824357000103, -3.129228363999857],
                [29.24862654400016, -3.12917379299995],
                [29.248284, -3.128866],
                [29.247768000000178, -3.128402],
                [29.247024, -3.127926],
                [29.246828189000155, -3.127800399999899],
                [29.246772054000189, -3.127765081999939],
                [29.24642930400006, -3.127795202999948],
                [29.245873120000056, -3.127844075999974],
                [29.245571923999989, -3.12787034899992],
                [29.245232001000034, -3.127899999999897],
                [29.245178249000048, -3.12800800499997],
                [29.245125000000144, -3.128115],
                [29.244929545000048, -3.128508909999937],
                [29.244734000000165, -3.128903000999912],
                [29.244688370000119, -3.129345871999874],
                [29.244598001000156, -3.130222998999898],
                [29.244510182999989, -3.130353489999948],
                [29.244349, -3.130593],
                [29.243828000000121, -3.131361],
                [29.243601000000183, -3.132180999999889],
                [29.243376, -3.13637],
                [29.243572, -3.136829],
                [29.243918596000185, -3.13764305199993],
                [29.243921137000143, -3.137683731999914],
                [29.243962999000189, -3.138462],
                [29.243227000000161, -3.13855599999988],
                [29.24291789200015, -3.138595855999881],
                [29.242878, -3.138600999999881],
                [29.241382939000061, -3.138099024999917],
                [29.240251780000165, -3.138554414999931],
                [29.240037255000118, -3.138960388999976],
                [29.239843368000152, -3.139328002999889],
                [29.239842491000161, -3.139353937999942],
                [29.239842, -3.139379],
                [29.23984, -3.139441],
                [29.239800000000116, -3.140421],
                [29.240183493000131, -3.14109821999989],
                [29.2405210820001, -3.141694380999923],
                [29.240232000000162, -3.14223],
                [29.240031, -3.1426],
                [29.239753815000086, -3.143108643999881],
                [29.239696, -3.143623],
                [29.23969001200004, -3.143676186999869],
                [29.239662171000134, -3.143924950999917],
                [29.239777058000186, -3.14430060299992],
                [29.240025, -3.14511],
                [29.239573000000178, -3.145611000999907],
                [29.239437455000029, -3.146043644999907],
                [29.239302000000123, -3.146475999999893],
                [29.239417, -3.147779],
                [29.239494535000119, -3.148677489999955],
                [29.239139681000097, -3.148796429999948],
                [29.238953, -3.148859],
                [29.237324, -3.149359],
                [29.235966000000133, -3.149996],
                [29.235308951000036, -3.15049703699998],
                [29.234652001000029, -3.150998],
                [29.234578659000192, -3.151308356999948],
                [29.234488, -3.151692],
                [29.234321, -3.153036],
                [29.234291, -3.153274000999886],
                [29.234144000000185, -3.153509000999975],
                [29.234081000000117, -3.15473],
                [29.2337950000001, -3.15516],
                [29.233646000000192, -3.155857],
                [29.233635000000163, -3.15591],
                [29.232988569000099, -3.15627349499988],
                [29.232977000000119, -3.15628],
                [29.232893, -3.156357],
                [29.232443505999981, -3.156766658999857],
                [29.231979369000044, -3.157190082999875],
                [29.231751959000064, -3.15760624099994],
                [29.231727, -3.157652],
                [29.231487255000047, -3.158091369999966],
                [29.231481077000069, -3.158101285999976],
                [29.231056000000137, -3.158233],
                [29.230894, -3.158283],
                [29.230768000000126, -3.158254999999883],
                [29.23047350700017, -3.158189272999948],
                [29.230077743000095, -3.158101080999927],
                [29.229127984000115, -3.158010015999878],
                [29.228448897000135, -3.158692249999945],
                [29.228341239000144, -3.158953463999978],
                [29.22813034100011, -3.159466026999894],
                [29.228082998000161, -3.160211151999931],
                [29.228045890000146, -3.160799451999935],
                [29.228041, -3.160877],
                [29.228149000000144, -3.161542999999881],
                [29.228233336000187, -3.162067016999913],
                [29.228313447000119, -3.162561892999918],
                [29.229534133000072, -3.16374705599992],
                [29.229553000000124, -3.163754999999867],
                [29.22981886000008, -3.163869341999828],
                [29.230497360000072, -3.164160726999967],
                [29.230621338000049, -3.164213656999891],
                [29.231798173000186, -3.16466879799998],
                [29.232416152000042, -3.165289877999953],
                [29.232677459000115, -3.165743826999915],
                [29.232677459000115, -3.167177200999959],
                [29.232677459000115, -3.167732],
                [29.232677459000115, -3.168133020999903],
                [29.23283324700003, -3.168401696999922],
                [29.232940852000127, -3.16858674599996],
                [29.232820780000111, -3.169160071999954],
                [29.232453784000143, -3.169280214999958],
                [29.231869698000139, -3.169471484999974],
                [29.23151700000011, -3.169603],
                [29.231431193000162, -3.1696349529999],
                [29.231039047000081, -3.169781445999945],
                [29.230785, -3.169929],
                [29.230442039000025, -3.169481050999877],
                [29.229488372000105, -3.170665978999978],
                [29.229494095000121, -3.170833586999891],
                [29.229496285000153, -3.170917850999842],
                [29.229504000000134, -3.171200999999826],
                [29.229506000000129, -3.171263],
                [29.229374, -3.172122999999886],
                [29.229374, -3.172513],
                [29.229218000000117, -3.172715],
                [29.229174, -3.17367],
                [29.228947, -3.174399],
                [29.227588905000061, -3.175287061999939],
                [29.226231, -3.176175],
                [29.225506000000109, -3.176722],
                [29.225202, -3.177116999999839],
                [29.224950348000164, -3.177443554999968],
                [29.224735260000102, -3.177722929999959],
                [29.224237441000071, -3.179043054999966],
                [29.224283218000039, -3.180363893999925],
                [29.224596023000061, -3.181150912999897],
                [29.224638421000179, -3.181257389999928],
                [29.224721560000035, -3.181465899999978],
                [29.224803641000165, -3.18167071299996],
                [29.224827, -3.181728998999972],
                [29.224934613000187, -3.181821990999936],
                [29.225379, -3.182205999999894],
                [29.22538769900018, -3.182213517999912],
                [29.225460039000154, -3.182275998999842],
                [29.22612322700013, -3.18225872499994],
                [29.227225999000041, -3.18223],
                [29.227905000000192, -3.181910998999911],
                [29.228031070000156, -3.181898504999936],
                [29.228359, -3.181865999999843],
                [29.229031, -3.182192999999813],
                [29.22985300900018, -3.182594007999967],
                [29.230197865000093, -3.182891685999891],
                [29.230679, -3.183307],
                [29.230969, -3.183557],
                [29.231118715999969, -3.18368675399995],
                [29.231157000000167, -3.183876999999882],
                [29.231255, -3.184370999999885],
                [29.230397349999976, -3.1855189],
                [29.229488, -3.186735999999826],
                [29.228584, -3.187146999999811],
                [29.228090920000113, -3.186986110999897],
                [29.227210603, -3.186699044999955],
                [29.226909735000163, -3.186600954999904],
                [29.226815321000174, -3.186585126999944],
                [29.226569537000103, -3.186543922999931],
                [29.225549736000175, -3.186373351999919],
                [29.225087490000135, -3.186991096999975],
                [29.225006104000158, -3.18709993199991],
                [29.225002148000158, -3.187136064999947],
                [29.224995, -3.187202999999897],
                [29.224936000000184, -3.187744999999893],
                [29.224903497000071, -3.188038529999858],
                [29.224870816000134, -3.188334574999942],
                [29.224797421000176, -3.189364705999878],
                [29.224758148000035, -3.189918278999869],
                [29.224689485000169, -3.190880058999937],
                [29.224327088000109, -3.191699981999932],
                [29.223583297000118, -3.192978965999885],
                [29.224068000000159, -3.192924],
                [29.224367579000159, -3.192890383999952],
                [29.224363914000094, -3.19290566899997],
                [29.224300298000173, -3.193047212999943],
                [29.223955, -3.193815],
                [29.22365636100011, -3.194391319999966],
                [29.223384730000078, -3.194915728999888],
                [29.223117000000173, -3.195638],
                [29.222973225000089, -3.196024783999917],
                [29.222862244000055, -3.196324108999932],
                [29.222763068000177, -3.197125512999946],
                [29.222744, -3.19728],
                [29.222668019000025, -3.197478143999831],
                [29.222387314000173, -3.198211430999947],
                [29.222366333000139, -3.198413849999895],
                [29.222353252, -3.198537063999936],
                [29.22234, -3.198664999999892],
                [29.22234, -3.199125],
                [29.222406315000057, -3.199289019999981],
                [29.222489837000069, -3.199495598999931],
                [29.222696000000155, -3.199717],
                [29.222926297000072, -3.200542077999955],
                [29.222950001000129, -3.200627],
                [29.223487061000128, -3.200957037999899],
                [29.224024, -3.201287],
                [29.224266000000171, -3.201377],
                [29.224283099000104, -3.201383361999831],
                [29.224567414000092, -3.201488733999952],
                [29.225158977000092, -3.201707494999937],
                [29.225350000000162, -3.201777999999877],
                [29.225439, -3.201811],
                [29.226028000000156, -3.201765],
                [29.226671651000174, -3.201391593999972],
                [29.227044999000157, -3.201175],
                [29.227352102999987, -3.200955103999945],
                [29.227781, -3.200647999999831],
                [29.22808, -3.200764],
                [29.228519000000176, -3.200935],
                [29.228829923000092, -3.201112100999922],
                [29.229065, -3.201246],
                [29.229136641000025, -3.201795284999889],
                [29.228977, -3.201922],
                [29.228472, -3.20232099999987],
                [29.227542438000057, -3.202863470999887],
                [29.227449417000059, -3.202917813999875],
                [29.227449417000059, -3.203132867999898],
                [29.227432251000039, -3.203562258999966],
                [29.22755582800005, -3.203838074999965],
                [29.227615000000185, -3.20397],
                [29.227745164000112, -3.204260440999917],
                [29.228914132, -3.20596936499993],
                [29.229029, -3.206137],
                [29.229153, -3.206317999999897],
                [29.230082500000037, -3.20685658899987],
                [29.23033, -3.207],
                [29.231417000000135, -3.20782],
                [29.232367, -3.209004],
                [29.232511000000102, -3.209131],
                [29.232554316000119, -3.209169502999941],
                [29.232574462000173, -3.209187269999916],
                [29.232975006000117, -3.209525584999938],
                [29.233033247000037, -3.209575002999941],
                [29.233100394000189, -3.209631491999971],
                [29.233635000000163, -3.211053],
                [29.233860002000142, -3.211731651999912],
                [29.234314001000143, -3.213101000999927],
                [29.233952001000034, -3.213693998999929],
                [29.234077085000081, -3.213686565999865],
                [29.234523799999977, -3.213660011999878],
                [29.234343000000138, -3.214122],
                [29.233677000000114, -3.214647999999897],
                [29.233184000000165, -3.214714],
                [29.233018307000066, -3.214736110999922],
                [29.232606888000191, -3.214791296999977],
                [29.23198890700013, -3.215030192999905],
                [29.231180191000192, -3.215818404999936],
                [29.23114031800003, -3.216011740999932],
                [29.231095000000153, -3.216232999999875],
                [29.231062001000168, -3.216392000999974],
                [29.231025368000076, -3.21660762099998],
                [29.230974197000023, -3.216909646999909],
                [29.231054305999976, -3.217744110999945],
                [29.231405257000176, -3.218868255999951],
                [29.231594164000057, -3.219470155999943],
                [29.231124653000052, -3.220050625999932],
                [29.230731165000179, -3.220523618999948],
                [29.229168, -3.220893999999873],
                [29.228601857, -3.221027884999955],
                [29.228427815000032, -3.221069153999963],
                [29.22841, -3.221084],
                [29.22756811000005, -3.221751911999888],
                [29.227385417000107, -3.222040461999882],
                [29.227180481000175, -3.222364901999924],
                [29.226858139000171, -3.222901344999968],
                [29.226807092000115, -3.222986452999976],
                [29.226774, -3.223042],
                [29.226723000000106, -3.223127],
                [29.226595000000145, -3.224085],
                [29.226572, -3.224254998999868],
                [29.226657121000073, -3.224577960999966],
                [29.226977267000052, -3.225809473999959],
                [29.226754910000068, -3.226336405999973],
                [29.226741958000048, -3.226367096999923],
                [29.22673624700019, -3.226380610999968],
                [29.226480484000092, -3.226986883999871],
                [29.225891113999978, -3.228444098999944],
                [29.225851059000149, -3.229788541999881],
                [29.225847244000079, -3.229945896999936],
                [29.226074219000168, -3.23107409499994],
                [29.226037978000079, -3.231293439999945],
                [29.226030350000144, -3.231343984999967],
                [29.225893021000047, -3.232167005999941],
                [29.225847244000079, -3.233167885999876],
                [29.226125312000192, -3.23361896099982],
                [29.226435506000087, -3.234121581999943],
                [29.227476, -3.234670999999821],
                [29.22761344900016, -3.235171079999873],
                [29.22761344900016, -3.237628936999954],
                [29.228153229000043, -3.238280534999944],
                [29.228164931000094, -3.238294486999962],
                [29.228176, -3.238307],
                [29.228518000000122, -3.238720999999885],
                [29.228624, -3.23887],
                [29.228706477000173, -3.238987263999889],
                [29.228771209000172, -3.239078520999897],
                [29.229168986000161, -3.239476985999943],
                [29.229185, -3.239493],
                [29.229191000000185, -3.239502],
                [29.229422000000113, -3.239850999999874],
                [29.23004, -3.241094],
                [29.230349000000103, -3.242025],
                [29.230591, -3.242665999999815],
                [29.230682000000115, -3.242909],
                [29.231157000000167, -3.243626],
                [29.231157000000167, -3.24460599899993],
                [29.231155000000172, -3.244624999999815],
                [29.231151965000151, -3.24465332199992],
                [29.231087001000105, -3.245226999999886],
                [29.231045098000095, -3.245935847999931],
                [29.231039047000081, -3.246038913999826],
                [29.231039047000081, -3.246444939999947],
                [29.230682373000036, -3.246970414999964],
                [29.230568493000192, -3.247640889999957],
                [29.230492, -3.248092999999869],
                [29.230443580000099, -3.248834747999979],
                [29.230272000000127, -3.249139999999898],
                [29.230171438000127, -3.249318367999933],
                [29.230135000000132, -3.249383147999936],
                [29.230135000000132, -3.250266999999894],
                [29.230135000000132, -3.250698596999882],
                [29.230438231999983, -3.250945568999896],
                [29.230572371000164, -3.251054746999955],
                [29.230653000000189, -3.25112],
                [29.230691990000025, -3.251151991999848],
                [29.231145997000056, -3.251832995999962],
                [29.230573744000026, -3.252405255999975],
                [29.230492, -3.252487000999906],
                [29.230673, -3.25313599999987],
                [29.230923000000132, -3.25393],
                [29.231625000000122, -3.255160999999816],
                [29.231794330000128, -3.2566253599999],
                [29.231820989000084, -3.256855906999874],
                [29.23178341900018, -3.256976676999955],
                [29.231461, -3.258011999999894],
                [29.231522022000149, -3.25852263299987],
                [29.231586456000173, -3.259057998999936],
                [29.231989418000069, -3.259574538999971],
                [29.232177948000071, -3.259815931999924],
                [29.232683572000155, -3.260969042999875],
                [29.232716001000085, -3.261042998999926],
                [29.233049968999978, -3.261608592999949],
                [29.233398438000165, -3.262197970999921],
                [29.234024047000105, -3.262883900999952],
                [29.234121905000052, -3.262923504999947],
                [29.234537, -3.263091],
                [29.235228000000177, -3.263370999999836],
                [29.235632148000036, -3.263685774999942],
                [29.235947000000181, -3.263931],
                [29.236305200000118, -3.264256928999942],
                [29.237057000000163, -3.264941],
                [29.237633822000134, -3.265849080999885],
                [29.237858000000188, -3.266202],
                [29.238331, -3.266946],
                [29.238606289000188, -3.267589742999917],
                [29.238724, -3.267865],
                [29.239183, -3.268334999999865],
                [29.2397, -3.268865],
                [29.237930000000119, -3.269288999999844],
                [29.23455, -3.26871199999988],
                [29.234287000000108, -3.269524],
                [29.207888, -3.290202],
                [29.20382700000016, -3.297186],
                [29.200918000000172, -3.308666],
                [29.202711000000193, -3.323494],
                [29.203541, -3.346255999999869],
                [29.203632790000086, -3.348771754999916],
                [29.203688891000184, -3.350305750999951],
                [29.203796386000079, -3.353245019999861],
                [29.203892711000151, -3.35585976099992],
                [29.203986000000157, -3.358381999999892],
                [29.204119000000162, -3.361995],
                [29.210154000000159, -3.393887],
                [29.214799989000085, -3.409826960999965],
                [29.219360352000024, -3.425461999999811],
                [29.219506911000167, -3.425965260999874],
                [29.219554902000141, -3.42613005599992],
                [29.226422393000178, -3.46755084199998],
                [29.22758102500012, -3.474539040999957],
                [29.228502273000117, -3.476982115999874],
                [29.230368073000136, -3.481934392999904],
                [29.232734679000032, -3.488215921999881],
                [29.234809167000094, -3.500646237999945],
                [29.2382144930001, -3.521050930999934],
                [29.239183426000068, -3.533488988999977],
                [29.240511990000016, -3.550524359999884],
                [29.241317749000189, -3.560856103999868],
                [29.24233126300004, -3.580660506999891],
                [29.243427278000183, -3.602077007999981],
                [29.243464367000172, -3.60280873399995],
                [29.243524552000054, -3.603996037999934],
                [29.245477676, -3.642174003999912],
                [29.243133544000045, -3.65384101799998],
                [29.242203727000117, -3.697072063999883],
                [29.241844176000143, -3.713788998999974],
                [29.241743089000011, -3.71847891699997],
                [29.240400314000055, -3.728193044999841],
                [29.23180198600005, -3.790510892999976],
                [29.239946365000037, -3.828964948999953],
                [29.240240096000093, -3.843240975999891],
                [29.241939545000093, -3.853099107999924],
                [29.244543075000081, -3.868196009999849],
                [29.244596480000155, -3.875334024999972],
                [29.253709792000109, -3.918102025999929],
                [29.259054185000082, -3.931963920999976],
                [29.26418685800013, -3.942631960999961],
                [29.282567977000156, -3.970712898999921],
                [29.284254074000103, -3.973290919999897],
                [29.305898664999972, -3.98947095799997],
                [29.327030183000147, -4.012041090999958],
                [29.34385681100008, -4.03370380299998],
                [29.347841263000078, -4.041376112999956],
                [29.356370925000078, -4.069332122999924],
                [29.36128044100019, -4.085422038999923],
                [29.367755889000023, -4.100027083999976],
                [29.368650436000053, -4.119369982999956],
                [29.378816605000054, -4.175841808999905],
                [29.379934312000046, -4.187809944999913],
                [29.380392074000099, -4.19271278399998],
                [29.382076263000101, -4.210761069999876],
                [29.383380891000058, -4.242745398999944],
                [29.385301591000143, -4.289831160999938],
                [29.385469436000108, -4.292115687999967],
                [29.387447357000042, -4.31899499899987],
                [29.388851165, -4.338087081999959],
                [29.387878419000174, -4.357821940999884],
                [29.390363693000097, -4.388988971999936],
                [29.39008522000006, -4.432705877999979],
                [29.391475678, -4.445250032999979],
                [29.392889024000056, -4.475230217999922],
                [29.393903732000183, -4.496782779999933],
                [29.394308090000095, -4.505403041999955],
                [29.386379242000032, -4.54074811799984],
                [29.372173309000175, -4.567213057999936],
                [29.371910096000136, -4.567702770999972],
                [29.371786118000159, -4.567989826999963],
                [29.355129242000089, -4.606417177999958],
                [29.335624694999979, -4.651411055999972],
                [29.330560684000147, -4.679856776999941],
                [29.329544068000018, -4.685544012999912],
                [29.327167512000017, -4.698911189999876],
                [29.327566148000187, -4.72087717099987],
                [29.328538894000189, -4.774072168999965],
                [29.33092880300012, -4.796138762999931],
                [29.332603454000036, -4.811597824999979],
                [29.337154388000158, -4.853596209999921],
                [29.353580475000115, -4.936216831999957],
                [29.357580185000074, -4.956338881999955],
                [29.371011734000035, -4.999998092999931],
                [29.371129989000167, -5.000383853999892],
                [29.371183395000116, -5.00055885199987],
                [29.371288299000128, -5.000898837999955],
                [29.372076034000031, -5.007911681999929],
                [29.372213363000071, -5.009128570999906],
                [29.373088836000136, -5.016916275999904],
                [29.388353349000056, -5.056379794999941],
                [29.388978958000052, -5.057999133999942],
                [29.38977432300004, -5.060913562999872],
                [29.392120360999968, -5.068615913999906],
                [29.401159287000041, -5.091632841999967],
                [29.410163879000038, -5.12353897],
                [29.426250457000037, -5.16827392599987],
                [29.429468155000166, -5.177218912999877],
                [29.435302734000061, -5.193439482999963],
                [29.438980102000187, -5.202999114999898],
                [29.465978622000137, -5.27099943199994],
                [29.467296600999987, -5.274160384999959],
                [29.467784881000057, -5.275249958999893],
                [29.476446152000051, -5.294565200999955],
                [29.489095687000145, -5.322755812999901],
                [29.495340347000081, -5.336671828999897],
                [29.500413895000122, -5.343339919999949],
                [29.500978469000131, -5.343998907999946],
                [29.501035689000048, -5.344148159999975],
                [29.522111892000112, -5.399155139999948],
                [29.522907257000156, -5.400815962999957],
                [29.523994445000028, -5.403081893999968],
                [29.527284622000025, -5.409951210999907],
                [29.528614044000051, -5.412727832999963],
                [29.529333115000156, -5.414227008999944],
                [29.529745102000049, -5.41508102399996],
                [29.543468475000168, -5.455468653999958],
                [29.549978254999985, -5.474999426999943],
                [29.554727555000056, -5.491025924999917],
                [29.557977677, -5.501998901999912],
                [29.558664322000084, -5.503373622999959],
                [29.560977936000029, -5.507999419999919],
                [29.56354141300011, -5.513978480999981],
                [29.563978196000107, -5.514999388999911],
                [29.566902160999973, -5.529614925999965],
                [29.56697845399998, -5.52999925599994],
                [29.568250656000089, -5.536397933999922],
                [29.570365906000177, -5.541972159999943],
                [29.57071685700015, -5.542848110999955],
                [29.5759773260001, -5.555999278999934],
                [29.587978363000161, -5.618999003999932],
                [29.595977783000023, -5.651998995999918],
                [29.598978043000102, -5.6639990799999],
                [29.604972840000073, -5.750999449999938],
                [29.605022430000076, -5.751426696999943],
                [29.606977463000078, -5.767999170999929],
                [29.606977463000078, -5.77483892399988],
                [29.606798172000083, -5.778161049999937],
                [29.605932235000125, -5.794182300999978],
                [29.604976655000087, -5.808999061999941],
                [29.599977492999983, -5.855998991999968],
                [29.588977813000099, -5.902998924999849],
                [29.585395812000115, -5.920459747999928],
                [29.580520629000034, -5.941857814999935],
                [29.579660416000024, -5.9495821],
                [29.578830719000052, -5.95702505099996],
                [29.578180314000122, -5.962873934999948],
                [29.578119279000134, -6.017464160999907],
                [29.578105926000035, -6.028380869999978],
                [29.578020095999989, -6.101694107999947],
                [29.579317092000053, -6.146804808999946],
                [29.580322265000177, -6.154717921999918],
                [29.584625244000108, -6.188560007999968],
                [29.588977813000099, -6.219999313999949],
                [29.594978333000086, -6.251998900999979],
                [29.597404480000023, -6.260190009999917],
                [29.603321075000053, -6.278677940999899],
                [29.60700607300015, -6.290191650999873],
                [29.611225128000115, -6.301909922999926],
                [29.611978530000101, -6.303998947999958],
                [29.61767387500015, -6.316023348999977],
                [29.620977402000165, -6.322999000999914],
                [29.623977660000094, -6.337999343999911],
                [29.644268036000085, -6.387053011999967],
                [29.64809227000012, -6.396416185999897],
                [29.654878616000133, -6.413033008999946],
                [29.654977798000175, -6.41299915299993],
                [29.663976669000192, -6.425999164999951],
                [29.688943862000144, -6.46574306399998],
                [29.707540511000104, -6.495346068999936],
                [29.713459016000115, -6.504161834999877],
                [29.742794038000113, -6.547847746999935],
                [29.746604919000049, -6.553523062999943],
                [29.746919631000139, -6.553915500999892],
                [29.746982574000128, -6.553994177999925],
                [29.773975372000109, -6.586998938999955],
                [29.776979447000031, -6.59067010799987],
                [29.789297104000184, -6.605726718999904],
                [29.791975022000088, -6.608998774999918],
                [29.826974870000186, -6.642999172999907],
                [29.839044571000045, -6.652913092999881],
                [29.905973434000089, -6.70799875199998],
                [29.979894638000189, -6.760399341999914],
                [29.981891632000043, -6.761530874999835],
                [29.984510421999971, -6.763504504999958],
                [29.984943390000126, -6.763831137999887],
                [29.99616432300013, -6.77229499699996],
                [30.000001908000115, -6.775185106999913],
                [30.002347945000167, -6.776956079999877],
                [30.004693985000131, -6.778725147999978],
                [30.023107527999969, -6.792612075999955],
                [30.023294449000105, -6.79274415999987],
                [30.026821137000013, -6.79521703599994],
                [30.05441284200009, -6.814572809999959],
                [30.083877563000044, -6.835243223999953],
                [30.085060119000048, -6.836074827999937],
                [30.08515930100009, -6.836193083999945],
                [30.08695030199999, -6.838324070999875],
                [30.087177278000127, -6.83859300599994],
                [30.089775085000042, -6.841685770999959],
                [30.094970703000058, -6.84786701299987],
                [30.107601165000062, -6.859458923999853],
                [30.124204635000012, -6.874697207999816],
                [30.140808106000065, -6.889933108999912],
                [30.174350737999987, -6.929299830999923],
                [30.183631897000112, -6.940192221999951],
                [30.18374824600005, -6.940333842999849],
                [30.183944702000133, -6.940614223999944],
                [30.195718764000162, -6.957791803999953],
                [30.220687866000048, -6.994201182999973],
                [30.220808030000057, -6.994377135999969],
                [30.221193313000015, -6.994946002999939],
                [30.221387864000064, -6.99522876799989],
                [30.221515655000076, -6.995474814999909],
                [30.22283935500019, -6.99803209199996],
                [30.223302841000134, -6.998920915999975],
                [30.223762513000167, -6.99981021799988],
                [30.224018097000169, -7.000307083999871],
                [30.224863051999989, -7.001932144999955],
                [30.226539611000078, -7.005167960999927],
                [30.226760864000028, -7.00512313899992],
                [30.227020263000043, -7.006095884999866],
                [30.232309341000189, -7.016294955999967],
                [30.233051301000103, -7.017201899999975],
                [30.234056473000123, -7.018039701999896],
                [30.236118317000034, -7.019759177999958],
                [30.237846375000174, -7.021200179999937],
                [30.238563537000175, -7.021797180999954],
                [30.238851548000127, -7.022273062999886],
                [30.241943360000164, -7.027376174999972],
                [30.245414733000018, -7.03310108099987],
                [30.245515823000062, -7.03326988099991],
                [30.245851516000016, -7.033822058999931],
                [30.246887207000043, -7.035532951999926],
                [30.248443604000101, -7.038093088999915],
                [30.248960494000073, -7.038949966999951],
                [30.249996185000043, -7.04066419499992],
                [30.251033783000082, -7.042370794999954],
                [30.251550675000033, -7.043224811999892],
                [30.253107071000045, -7.045795917999897],
                [30.25465965300009, -7.048356054999886],
                [30.254848480000135, -7.048672199999942],
                [30.26472473199999, -7.065214157999947],
                [30.270456314000114, -7.074813842999959],
                [30.270538330000193, -7.074957846999951],
                [30.270673751000118, -7.07520580299996],
                [30.302831651000076, -7.135453223999946],
                [30.303068162000159, -7.1358971599999],
                [30.303188325000065, -7.136117934999959],
                [30.303287507000107, -7.136418818999971],
                [30.305744172000061, -7.143918037999981],
                [30.309299469000166, -7.154766081999981],
                [30.349306105999972, -7.229845046999913],
                [30.358261108000193, -7.246652126999948],
                [30.358890533000135, -7.247829914999954],
                [30.359018326000125, -7.248260974999937],
                [30.359281541000144, -7.249125002999904],
                [30.359933854000019, -7.251277923999965],
                [30.361637116000111, -7.256892203999882],
                [30.361684799000045, -7.257036208999978],
                [30.36923027000006, -7.281920909999883],
                [30.369304658000033, -7.282167910999817],
                [30.369354248000036, -7.282262800999945],
                [30.37203216600011, -7.287207126999931],
                [30.373607635000155, -7.290111063999973],
                [30.374965668000129, -7.291998386999978],
                [30.398965836000173, -7.36099862999987],
                [30.422966003000056, -7.417998313999931],
                [30.435089111000025, -7.441853046999938],
                [30.453964233000136, -7.478998659999945],
                [30.478965759000062, -7.533998489999874],
                [30.488969802999975, -7.553722858999947],
                [30.513963699000158, -7.602998255999978],
                [30.51525306700006, -7.606055258999902],
                [30.522249222000085, -7.619986056999949],
                [30.522291184000039, -7.620086192999963],
                [30.539739609000151, -7.660348892999934],
                [30.54017830000015, -7.661358832999952],
                [30.540264129000036, -7.661888121999937],
                [30.540647507000131, -7.664168833999895],
                [30.541135788000133, -7.666984080999953],
                [30.541343690000076, -7.667531013999906],
                [30.560907364000172, -7.715709208999954],
                [30.56203651400017, -7.718488216999958],
                [30.562446594000164, -7.719497203999936],
                [30.57514953600014, -7.750775812999962],
                [30.57521057200006, -7.750930784999923],
                [30.57533645500007, -7.75127983099992],
                [30.605329513000129, -7.835650919999921],
                [30.606138229000067, -7.837933063999913],
                [30.606359481000084, -7.838550090999888],
                [30.607362748000071, -7.841373919999967],
                [30.608032227000137, -7.843258856999967],
                [30.627141953000148, -7.896992206999926],
                [30.627218246000155, -7.897229194999966],
                [30.63911628600016, -7.9332618709999],
                [30.645433425000135, -7.957212923999919],
                [30.645584107000161, -7.957785129999934],
                [30.649358748999987, -7.972093105999932],
                [30.651588441000115, -7.980543136999927],
                [30.651622772, -7.9806752199999],
                [30.65173721300016, -7.980757236999921],
                [30.66118812600007, -7.987238882999975],
                [30.685205458999974, -8.017992973999924],
                [30.703706741000133, -8.048899649999896],
                [30.709987640000122, -8.062541006999936],
                [30.710840225000084, -8.064392088999966],
                [30.711257934000059, -8.065300940999919],
                [30.712207794000165, -8.066953658999978],
                [30.71320533800008, -8.068683623999959],
                [30.714202880000187, -8.070416450999971],
                [30.715204239000172, -8.072152136999932],
                [30.716701507000096, -8.074752806999925],
                [30.718194962000098, -8.077346800999976],
                [30.719196319000105, -8.079086303999929],
                [30.720193863000191, -8.080815314999882],
                [30.721691131000114, -8.083415984999874],
                [30.723186493000185, -8.086015701999941],
                [30.724185943000123, -8.087748527999963],
                [30.725681305000023, -8.090349196999966],
                [30.726678849000109, -8.092081070999882],
                [30.728176117000032, -8.094683647999943],
                [30.728311538000128, -8.094921111999952],
                [30.732818603999988, -8.102743147999945],
                [30.733005523000088, -8.103071212999964],
                [30.738393783000049, -8.109502791999944],
                [30.738660813000138, -8.10981655199987],
                [30.73875427300004, -8.110127448999947],
                [30.748552322000137, -8.141271590999963],
                [30.748748779000096, -8.141888617999939],
                [30.748853683000107, -8.142381667999928],
                [30.749980926000035, -8.147648811999943],
                [30.756517410000129, -8.17817783299995],
                [30.756689071000039, -8.178977965999877],
                [30.756589889000168, -8.180730819999951],
                [30.756568908000133, -8.18111324299997],
                [30.756410598000173, -8.18393993299992],
                [30.756366730000082, -8.18471431699993],
                [30.756721497000058, -8.18549823799998],
                [30.768503189000171, -8.211624144999973],
                [30.768791198000088, -8.212262152999927],
                [30.769010543000036, -8.212750434999919],
                [30.76961517400008, -8.214090347999957],
                [30.770437240000035, -8.215910910999924],
                [30.771259308000026, -8.217737196999963],
                [30.772081375000141, -8.219557761999965],
                [30.773315429999968, -8.222294807999958],
                [30.774246216000108, -8.224360466999883],
                [30.774526596000044, -8.22503852899996],
                [30.775291442000082, -8.226887702999932],
                [30.776439666000044, -8.229659079999976],
                [30.777584075000107, -8.2324256899999],
                [30.778350831000182, -8.234278678999942],
                [30.779497147000143, -8.237046240999973],
                [30.78064346300016, -8.239816665999967],
                [30.78169250500008, -8.242347716999916],
                [30.781795502000136, -8.242594718999953],
                [30.782558441000106, -8.244443892999925],
                [30.783704757000123, -8.24721527],
                [30.784467697000025, -8.249056816999939],
                [30.785234451, -8.250909805999925],
                [30.786001204000172, -8.252759932999823],
                [30.786762237000119, -8.254604338999911],
                [30.787914276000151, -8.257377623999957],
                [30.788690568000106, -8.25925064199987],
                [30.78884124700005, -8.259617803999959],
                [30.78900909500004, -8.260085105999906],
                [30.789720536000061, -8.262039184999935],
                [30.790006636999976, -8.262825011999951],
                [30.792936324000038, -8.270876883999847],
                [30.795076198000174, -8.276758080999912],
                [30.723137939000082, -8.282876286999965],
                [30.716237939000109, -8.283476286999871],
                [30.698337939000169, -8.285076286999981],
                [30.579437939000172, -8.295676286999878],
                [30.574737939000102, -8.295876286999828],
                [30.568937939000193, -8.296976286999893],
                [30.564037939000116, -8.2971762869999],
                [30.558436658000062, -8.29652686299994],
                [30.557137939000143, -8.296376286999873],
                [30.55373793900003, -8.294176286999857],
                [30.550437939000176, -8.292076286999929],
                [30.547537939000051, -8.288376286999949],
                [30.543937939000102, -8.283076286999972],
                [30.540237939000178, -8.280476286999885],
                [30.537037939000072, -8.278976286999864],
                [30.533337939000035, -8.280276286999936],
                [30.531237939000164, -8.282376286999977],
                [30.529037939000034, -8.284676286999968],
                [30.525837939000098, -8.287676286999897],
                [30.522337939000067, -8.28747628699989],
                [30.519537939000031, -8.286076286999958],
                [30.515837939000164, -8.285276286999931],
                [30.513237939000135, -8.285876286999951],
                [30.510237939000092, -8.288476286999924],
                [30.507637939000062, -8.290376286999958],
                [30.505537939000021, -8.292276286999936],
                [30.501937939000072, -8.293576286999951],
                [30.498937939000029, -8.293276286999969],
                [30.495537939000087, -8.291576286999941],
                [30.492737939000051, -8.2894762869999],
                [30.48843793900005, -8.285976286999926],
                [30.485437939000178, -8.284076286999948],
                [30.481837939000172, -8.280976286999874],
                [30.478537938999978, -8.278276286999926],
                [30.475337939000099, -8.276276286999917],
                [30.472337939, -8.274376286999939],
                [30.469437939000045, -8.272976286999892],
                [30.464437939000049, -8.271976286999973],
                [30.457837939000058, -8.27347628699988],
                [30.453537939000057, -8.274876286999927],
                [30.450037939000026, -8.276376286999948],
                [30.447337939000079, -8.27787628699997],
                [30.444237939000061, -8.279876286999979],
                [30.440137939000124, -8.281476286999919],
                [30.43583793900018, -8.279976286999897],
                [30.433037939000144, -8.276576286999955],
                [30.429537939000056, -8.273276286999931],
                [30.212983029000156, -8.301781259999927],
                [29.697937939000155, -8.369576286999916],
                [29.695237939000037, -8.371276286999887],
                [29.692337939000083, -8.373776286999942],
                [29.689337939000154, -8.378176286999974],
                [29.6868379390001, -8.383376286999976],
                [29.684337938999988, -8.387576286999945],
                [29.682668906, -8.389714734999927],
                [29.681137939000109, -8.391676286999882],
                [29.67713793900009, -8.395376286999976],
                [29.673437939000166, -8.397976286999949],
                [29.670537939000042, -8.39987628699987],
                [29.666437939000105, -8.401476286999923],
                [29.662437939000029, -8.402776286999938],
                [29.658737939000162, -8.403176286999894],
                [29.654737939000142, -8.403176286999894],
                [29.650637938999978, -8.403276286999926],
                [29.647837939000169, -8.403776286999971],
                [29.644537939000145, -8.404676286999916],
                [29.641537939000045, -8.406476286999919],
                [29.638137939000103, -8.407676286999902],
                [29.635037939000142, -8.410376286999906],
                [29.631137939000041, -8.413476286999924],
                [29.626937939000129, -8.416476286999966],
                [29.623037939000028, -8.418176286999938],
                [29.619237939000072, -8.418976286999964],
                [29.614437939000084, -8.419176286999914],
                [29.611137939000059, -8.418676286999926],
                [29.607737939000117, -8.416976286999955],
                [29.605937939000057, -8.414776286999938],
                [29.604537939000068, -8.411476286999971],
                [29.601823052000043, -8.407579110999961],
                [29.592137939000111, -8.393676286999892],
                [29.589037939000093, -8.393276286999935],
                [29.585637939000151, -8.393076286999929],
                [29.581837939000138, -8.392776286999947],
                [29.577737939000031, -8.392076286999952],
                [29.576473121000163, -8.391927484999883],
                [29.572637939000117, -8.391476286999932],
                [29.569437939000181, -8.389976286999911],
                [29.56593793900015, -8.387576286999945],
                [29.56243793900012, -8.384176286999889],
                [29.560284731000138, -8.380457109999952],
                [29.56023793900016, -8.380376286999933],
                [29.551747698000156, -8.381578670999943],
                [29.351259107000033, -8.409971768999924],
                [29.338925710000183, -8.411718417999964],
                [29.258599970000091, -8.423094110999898],
                [29.252244814000051, -8.423994124999922],
                [29.00671713700001, -8.458765636999942],
                [28.994637939000143, -8.460476286999892],
                [28.994313117000104, -8.460566130999894],
                [28.989937939000129, -8.461776286999907],
                [28.985737939000046, -8.468876286999944],
                [28.985637939000128, -8.472076286999936],
                [28.985337938999976, -8.476176286999873],
                [28.984637939000152, -8.481376286999932],
                [28.98333793900008, -8.483976286999905],
                [28.981637939000109, -8.487276286999929],
                [28.970137938999983, -8.496376286999976],
                [28.966137939000134, -8.498876286999973],
                [28.961537939000038, -8.500276286999963],
                [28.95573793900013, -8.504076286999975],
                [28.951937939000118, -8.507776286999956],
                [28.950637939000046, -8.510376286999872],
                [28.949137939000082, -8.514676286999929],
                [28.947937939000099, -8.519776286999957],
                [28.937328664000063, -8.546827797999981],
                [28.799337939000111, -8.898676286999887],
                [28.797737939000058, -8.902176286999918],
                [28.796037939000087, -8.905476286999942],
                [28.794337939000116, -8.908276286999978],
                [28.792637939000144, -8.910676286999944],
                [28.790337939000096, -8.916076286999896],
                [28.788337938999973, -8.919676286999959],
                [28.786637939, -8.922676286999945],
                [28.782437939000147, -8.92967628699995],
                [28.777737939000133, -8.935176286999933],
                [28.771537939000154, -8.940476286999967],
                [28.763837939000041, -8.94697628699987],
                [28.759037939000052, -8.951676286999941],
                [28.752037939000161, -8.959176286999877],
                [28.746637939000095, -8.965076286999931],
                [28.739737939000122, -8.97167628699998],
                [28.735337939000033, -8.975176286999897],
                [28.728437939000059, -8.982376286999966],
                [28.720637939000028, -8.991476286999955],
                [28.714537938999968, -8.998776286999942],
                [28.708637939000141, -9.005076286999838],
                [28.703437939000139, -9.009576286999845],
                [28.696037939000178, -9.016276286999869],
                [28.681737939000186, -9.029376286999934],
                [28.660837939000032, -9.045076286999915],
                [28.654237939000154, -9.051276286999894],
                [28.64953793900014, -9.055376286999945],
                [28.638737939000066, -9.064876286999947],
                [28.632137939000188, -9.070476286999906],
                [28.624337939000156, -9.075876286999971],
                [28.616137939000112, -9.08207628699995],
                [28.610337939000146, -9.085376286999974],
                [28.390637939000101, -9.225676286999942],
                [28.385937939000087, -9.229476286999955],
                [28.38063793900011, -9.23367628699998],
                [28.374637938999967, -9.238876286999926],
                [28.370337939000024, -9.247576286999959],
                [28.370737939000094, -9.252776286999904],
                [28.371337939000171, -9.261576286999969],
                [28.372537939000154, -9.268076286999929],
                [28.373037939000142, -9.272776286999942],
                [28.374837939000031, -9.277176286999918],
                [28.375406454000085, -9.279490954999972],
                [28.376237939000021, -9.282876286999965],
                [28.376437939000084, -9.286076286999958],
                [28.377537939000149, -9.289776286999938],
                [28.37843793900015, -9.292776286999981],
                [28.380437939000103, -9.295976286999974],
                [28.382337939000081, -9.298876286999871],
                [28.394837939000126, -9.310876286999928],
                [28.399237939000045, -9.313776286999882],
                [28.408137939000142, -9.322476286999972],
                [28.415037939000115, -9.324676286999932],
                [28.418237939000051, -9.326476286999821],
                [28.423137939000128, -9.328376286999969],
                [28.42793793900006, -9.330576286999929],
                [28.441237939000075, -9.336476286999869],
                [28.448137939000048, -9.339476286999968],
                [28.451637939000079, -9.340976286999876],
                [28.459737939000092, -9.343676286999937],
                [28.46383793900003, -9.3442762869999],
                [28.468037939000055, -9.345076286999927],
                [28.473637939000128, -9.346476286999973],
                [28.479337939000175, -9.347076286999936],
                [28.48353793900003, -9.348076286999969],
                [28.502282852000121, -9.357217894999906],
                [28.503837939000107, -9.357976286999872],
                [28.51113793900015, -9.363676286999976],
                [28.515537939000069, -9.374476286999936],
                [28.51643793900007, -9.378976286999944],
                [28.515760181000132, -9.386687665999943],
                [28.511937939000063, -9.430176286999938],
                [28.510437939000155, -9.433776286999887],
                [28.509037939000109, -9.437776286999906],
                [28.509137939000027, -9.441576286999919],
                [28.512337939000133, -9.447176286999877],
                [28.515737939000076, -9.44937628699995],
                [28.51863793900003, -9.45097628699989],
                [28.521837939000136, -9.452676286999974],
                [28.524637939000172, -9.455776286999935],
                [28.525461439000082, -9.458295227999827],
                [28.526337939000143, -9.46097628699988],
                [28.526837939000131, -9.466576286999953],
                [28.524937939000154, -9.472576286999981],
                [28.524896001000059, -9.473163413999885],
                [28.524537939000083, -9.478176286999883],
                [28.526837939000131, -9.481676286999914],
                [28.531337939000139, -9.48247628699994],
                [28.53913793900017, -9.480876286999887],
                [28.54623793899998, -9.480776286999969],
                [28.552037939000115, -9.482876286999897],
                [28.556137939000052, -9.485876286999883],
                [28.559837939000147, -9.489576286999977],
                [28.562137939000024, -9.494076286999871],
                [28.562200841000049, -9.494403374999934],
                [28.563137939000114, -9.499276286999816],
                [28.563004198000158, -9.504157824999936],
                [28.562937939000108, -9.506576286999859],
                [28.56285141800015, -9.507287678999944],
                [28.562377533000131, -9.511184065999885],
                [28.562229951000177, -9.512397522999947],
                [28.562037939000049, -9.513976286999935],
                [28.561862931000178, -9.517184764999911],
                [28.561737939000125, -9.519476286999975],
                [28.560837939000066, -9.526776286999905],
                [28.559537939000165, -9.533676286999935],
                [28.559237939000013, -9.542976286999874],
                [28.560906488000171, -9.552460672999928],
                [28.561137939000048, -9.553776286999948],
                [28.564537939000161, -9.560576286999947],
                [28.569437939000068, -9.564276286999927],
                [28.576737939000111, -9.564876286999947],
                [28.583537939000166, -9.563376286999869],
                [28.593037939000169, -9.554676286999893],
                [28.599837939000054, -9.548976286999903],
                [28.603237939000167, -9.548776286999896],
                [28.606637939000109, -9.552376286999959],
                [28.609437939000145, -9.557676286999879],
                [28.610537939000039, -9.562776286999963],
                [28.609537939000063, -9.566576286999975],
                [28.604137939000168, -9.568576286999928],
                [28.593837939000082, -9.569076286999973],
                [28.583337939000103, -9.569976286999918],
                [28.577637939000113, -9.573276286999942],
                [28.572637939000117, -9.578776286999926],
                [28.574337939000088, -9.582976286999951],
                [28.578437939000082, -9.583776286999978],
                [28.585237939000137, -9.584376286999884],
                [28.588937939000175, -9.586876286999882],
                [28.596437939000111, -9.59347628699993],
                [28.600937939000119, -9.599376286999927],
                [28.602837939000096, -9.604276286999948],
                [28.602437939000026, -9.60907628699988],
                [28.600637939000137, -9.61677628699988],
                [28.598337939000089, -9.621076286999937],
                [28.597537939000176, -9.625576286999944],
                [28.597437939000031, -9.628676286999962],
                [28.598437939000178, -9.631476286999885],
                [28.603137939000021, -9.637776286999895],
                [28.60793793900018, -9.645476286999895],
                [28.60973793900007, -9.65227628699995],
                [28.613137939000183, -9.66387628699988],
                [28.615337939000142, -9.674676286999898],
                [28.61543793900006, -9.683376286999874],
                [28.617737939000108, -9.695176286999924],
                [28.620037938999985, -9.701276286999871],
                [28.618737939000084, -9.706376286999898],
                [28.61623793900003, -9.710676286999956],
                [28.610537939000039, -9.717276286999891],
                [28.608637939000175, -9.721976286999961],
                [28.609237939000081, -9.725476286999879],
                [28.613937939000152, -9.731776286999889],
                [28.619437939000079, -9.738276286999906],
                [28.625537939000139, -9.748176286999978],
                [28.629937939000058, -9.751876286999959],
                [28.635737938999966, -9.755076286999952],
                [28.64523793900014, -9.75517628699987],
                [28.652537939000183, -9.754376286999957],
                [28.657500237000079, -9.754674024999872],
                [28.657470703000115, -9.754699706999872],
                [28.663513184000124, -9.760681151999961],
                [28.666503906000116, -9.767395019999924],
                [28.667480469000054, -9.773803710999971],
                [28.664672851999967, -9.780578612999875],
                [28.664672851999967, -9.783874511999898],
                [28.671325684000124, -9.789489745999902],
                [28.677673340000069, -9.794799804999968],
                [28.681274414000143, -9.798095702999945],
                [28.687072754000098, -9.80438232399996],
                [28.689270020000151, -9.808898925999927],
                [28.689270020000151, -9.813293456999929],
                [28.68728355200011, -9.814304748999916],
                [28.685913086000085, -9.815002440999933],
                [28.682678223000039, -9.815185546999885],
                [28.680228146000047, -9.815072116999886],
                [28.67608642600004, -9.814880370999958],
                [28.670471191000047, -9.815185546999885],
                [28.667480469000054, -9.818481444999918],
                [28.667907715000013, -9.823303222999925],
                [28.668518066000161, -9.834106444999975],
                [28.667480469000054, -9.839111327999945],
                [28.666687012000182, -9.843200683999896],
                [28.665527344000168, -9.847412108999833],
                [28.6629028320001, -9.852600097999925],
                [28.656921387000182, -9.85968017599987],
                [28.651123047, -9.8663940429999],
                [28.649475097999982, -9.872985839999899],
                [28.652099609000174, -9.881896972999925],
                [28.65069580100004, -9.890686034999931],
                [28.6472778320001, -9.89678955099987],
                [28.642517090000183, -9.903198241999974],
                [28.637878418000071, -9.90850830099987],
                [28.628723145000095, -9.924804687999824],
                [28.623718262000125, -9.935913085999914],
                [28.619689941000161, -9.948486327999945],
                [28.617919922000169, -9.957702636999898],
                [28.617309570000145, -9.967102050999927],
                [28.618896484000174, -9.972106933999953],
                [28.620300293000071, -9.976501464999956],
                [28.6218872070001, -9.982910155999946],
                [28.618896484000174, -9.988891601999967],
                [28.614929199000073, -9.996582030999889],
                [28.616516113000102, -10.00170898399989],
                [28.619689941000161, -10.008178710999914],
                [28.624511719000168, -10.012512206999929],
                [28.629272461000085, -10.018798827999944],
                [28.633483887000182, -10.023986815999933],
                [28.634205908000126, -10.026513891999969],
                [28.635314941000161, -10.03039550799997],
                [28.63507080100004, -10.037292479999962],
                [28.632324219000168, -10.048095702999944],
                [28.631871295000167, -10.049145159999853],
                [28.627319336000028, -10.05969238299997],
                [28.624877929999968, -10.067993163999972],
                [28.624651540000116, -10.072155255999974],
                [28.624518490000071, -10.074601333999965],
                [28.624084473000153, -10.082580565999876],
                [28.624084473000153, -10.086486815999876],
                [28.620300293000071, -10.109008788999972],
                [28.618896484000174, -10.119384765999882],
                [28.617675781000116, -10.128906249999943],
                [28.618896484000174, -10.14367675799997],
                [28.618286133000026, -10.155212401999961],
                [28.61631957899999, -10.164866397999901],
                [28.616271973000153, -10.165100097999925],
                [28.611328125, -10.174377440999933],
                [28.607774941, -10.178922211999918],
                [28.606079102000137, -10.181091308999896],
                [28.600524902000132, -10.185791015999826],
                [28.596313477000081, -10.190002440999933],
                [28.591308594000168, -10.195800780999889],
                [28.585083008000026, -10.203308104999962],
                [28.580078125000057, -10.210998534999874],
                [28.575073242000087, -10.219787597999925],
                [28.571472168000071, -10.225402831999929],
                [28.571105957000043, -10.233886718999884],
                [28.573303223000096, -10.23889160199991],
                [28.576721191000104, -10.243408202999888],
                [28.582519531000059, -10.246887206999872],
                [28.587524414000029, -10.251586913999972],
                [28.593078613000159, -10.258605956999872],
                [28.596923828000058, -10.26538085899989],
                [28.602111816000161, -10.273010253999928],
                [28.604675293000071, -10.279785155999946],
                [28.604492188000108, -10.282775878999871],
                [28.606079102000137, -10.286987304999968],
                [28.608276367000144, -10.289978026999904],
                [28.614318848000096, -10.292785644999924],
                [28.619323730000133, -10.294982909999931],
                [28.624328613000102, -10.297607421999942],
                [28.627075195000145, -10.301574706999929],
                [28.629516602000081, -10.306884765999882],
                [28.629882813000108, -10.315307616999917],
                [28.627319336000028, -10.335693358999833],
                [28.624328613000102, -10.346984862999932],
                [28.618713379000042, -10.357788085999914],
                [28.616271973000153, -10.367797851999967],
                [28.618896484000174, -10.377502440999933],
                [28.619689941000161, -10.388000487999932],
                [28.618713379000042, -10.394104003999928],
                [28.618713379000042, -10.402404784999874],
                [28.622497559000124, -10.407104491999974],
                [28.626708984000174, -10.411804198999903],
                [28.632873535000101, -10.417602538999972],
                [28.637084961000085, -10.424377440999876],
                [28.639709473000153, -10.432800292999957],
                [28.641283653000187, -10.43941992099991],
                [28.642089844000168, -10.442810058999953],
                [28.642129437000165, -10.444472989999952],
                [28.64227294900013, -10.450500487999932],
                [28.642102688000023, -10.452694959999974],
                [28.64172363299997, -10.457580565999933],
                [28.640030703000093, -10.459474352999848],
                [28.638122559000124, -10.461608886999954],
                [28.634887695000145, -10.465087890999882],
                [28.632873535000101, -10.47039794899996],
                [28.631286621000072, -10.474182128999871],
                [28.631286621000072, -10.478393554999968],
                [28.632873535000101, -10.484191894999867],
                [28.636718750000057, -10.490112304999968],
                [28.637878418000071, -10.494995116999974],
                [28.638026479000132, -10.495669616999976],
                [28.639526367000087, -10.502502440999933],
                [28.64031982400013, -10.508178710999971],
                [28.635681152000188, -10.516906737999875],
                [28.634182768000187, -10.518830799999932],
                [28.630310059000124, -10.523803710999971],
                [28.631286621000072, -10.528686522999976],
                [28.636474609000174, -10.531799315999933],
                [28.643026926000118, -10.535129181999935],
                [28.643920898000147, -10.535583495999958],
                [28.653320313000108, -10.541503905999889],
                [28.65664014400005, -10.544621307999819],
                [28.658325195000145, -10.546203612999818],
                [28.661071777000188, -10.550781249999943],
                [28.664306641000167, -10.553710936999948],
                [28.668942178000066, -10.557075439999892],
                [28.671875000000114, -10.559204101999967],
                [28.675476074000187, -10.566284179999968],
                [28.675292969, -10.572387694999918],
                [28.673095703000115, -10.577392577999888],
                [28.671081543000071, -10.581481933999953],
                [28.668090819999975, -10.588806151999961],
                [28.665283203000115, -10.597106933999839],
                [28.664577263000183, -10.600546129999827],
                [28.6629028320001, -10.608703612999932],
                [28.661071777000188, -10.618103026999961],
                [28.659912109000174, -10.625488280999946],
                [28.659912109000174, -10.629394530999946],
                [28.664123535000101, -10.631896972999868],
                [28.671875000000114, -10.633483886999898],
                [28.678527832000043, -10.635192870999902],
                [28.682678223000039, -10.637084960999971],
                [28.68572998000019, -10.638793944999975],
                [28.688476562000062, -10.641113280999946],
                [28.690307617000144, -10.643981933999953],
                [28.692687988000159, -10.64819335899989],
                [28.694688795000104, -10.653028641999924],
                [28.694885254000098, -10.653503417999957],
                [28.696289062000062, -10.659301757999913],
                [28.698303222999982, -10.668395995999902],
                [28.696899414000086, -10.673583983999947],
                [28.689880371000129, -10.678588866999974],
                [28.689835099000106, -10.678609993999942],
                [28.683471680000025, -10.681579589999956],
                [28.672119141000167, -10.686706542999957],
                [28.668781768000144, -10.692976756999883],
                [28.668090819999975, -10.694274901999847],
                [28.66773324400009, -10.695743517999858],
                [28.664672851999967, -10.708312987999875],
                [28.659729004000042, -10.71789550799997],
                [28.658214036000061, -10.71906746399992],
                [28.656494141000167, -10.72039794899996],
                [28.651489258000026, -10.721984862999932],
                [28.644470215000069, -10.722595214999956],
                [28.638305664000086, -10.723205565999933],
                [28.630676270000151, -10.724487304999855],
                [28.622314453000058, -10.727111815999933],
                [28.616516113000102, -10.731994628999928],
                [28.612121582000043, -10.739501952999944],
                [28.610473633000026, -10.75109863299997],
                [28.608276367000144, -10.765075683999953],
                [28.606079102000137, -10.775512694999918],
                [28.601928711000028, -10.780090331999872],
                [28.594299316000161, -10.786804198999903],
                [28.585876465000183, -10.793395995999902],
                [28.582519531000059, -10.799804687999938],
                [28.581481934000124, -10.807800292999957],
                [28.580078125000057, -10.815002440999876],
                [28.578125000000171, -10.817810058999953],
                [28.573730469000111, -10.821289061999948],
                [28.566528320000089, -10.826293944999975],
                [28.560913085999971, -10.831298827999944],
                [28.558105469000111, -10.838684081999929],
                [28.555114746000015, -10.845886229999962],
                [28.54992675800014, -10.853881835999971],
                [28.546875, -10.863586425999927],
                [28.546081543000184, -10.87078857399996],
                [28.546508789000029, -10.877807616999974],
                [28.546875, -10.886474608999947],
                [28.547912598000096, -10.896606444999918],
                [28.549499512000125, -10.910583495999958],
                [28.548522949000073, -10.925109862999932],
                [28.548706055000082, -10.932189940999876],
                [28.548882537000168, -10.93235556299993],
                [28.548577659000159, -10.932557382999903],
                [28.548865719000162, -10.933709621999924],
                [28.548637939000173, -10.933576286999937],
                [28.547537939000108, -10.940276286999961],
                [28.546137939000062, -10.94857628699998],
                [28.538237939000112, -10.956876286999886],
                [28.525337939000167, -10.967276286999947],
                [28.51943793900017, -10.974176286999921],
                [28.514637939000181, -10.978976286999966],
                [28.512137939000127, -10.985276286999976],
                [28.512537938999969, -10.990876286999935],
                [28.513037939000128, -10.998976286999834],
                [28.515037939000081, -11.005876286999978],
                [28.516737939000052, -11.012376286999938],
                [28.518037939000124, -11.018776286999923],
                [28.513837939000098, -11.032376286999977],
                [28.510037939000085, -11.038576286999955],
                [28.506937939000125, -11.045076286999972],
                [28.503837939000107, -11.049676286999897],
                [28.502337938999972, -11.053176286999928],
                [28.501537939000059, -11.060876286999871],
                [28.500937939000153, -11.070676286999969],
                [28.500237939000158, -11.080076286999883],
                [28.498037939000028, -11.085876286999962],
                [28.494637939000086, -11.091376286999946],
                [28.487937939000119, -11.098176286999944],
                [28.483637939000118, -11.104476286999898],
                [28.482737939000117, -11.111176286999978],
                [28.484637939000095, -11.117376286999956],
                [28.489037939000184, -11.125276286999963],
                [28.493637939000109, -11.133176286999912],
                [28.500737939000146, -11.140776286999937],
                [28.50323793900003, -11.146176286999889],
                [28.50503793900009, -11.155576286999974],
                [28.50503793900009, -11.162376286999915],
                [28.504237939000177, -11.172776286999976],
                [28.501737939000066, -11.180576286999894],
                [28.497737939000046, -11.188676286999964],
                [28.492737939000051, -11.200176286999977],
                [28.48963793900009, -11.208676286999832],
                [28.489037939000184, -11.21907628699995],
                [28.490737939000155, -11.227676286999895],
                [28.495237939000162, -11.236376286999871],
                [28.494037939000179, -11.245976286999962],
                [28.490737939000155, -11.2543762869999],
                [28.486737939000136, -11.259776286999966],
                [28.482537939000053, -11.265576286999874],
                [28.479837939000106, -11.275176286999965],
                [28.480437939000012, -11.287076286999934],
                [28.478437939000116, -11.291476286999966],
                [28.474237939000034, -11.293876286999932],
                [28.466537939000148, -11.297476286999881],
                [28.461537939000152, -11.303476286999853],
                [28.457537939000133, -11.313476286999901],
                [28.45633793900015, -11.319776286999911],
                [28.456037939000169, -11.32577628699994],
                [28.457937938999976, -11.336076286999969],
                [28.46383793900003, -11.348376286999894],
                [28.465037939000183, -11.357876286999897],
                [28.464037939000036, -11.368176286999926],
                [28.460037939000188, -11.376076286999876],
                [28.451137939000091, -11.382476286999974],
                [28.440237939000099, -11.386076286999923],
                [28.436937939000074, -11.390676286999962],
                [28.43583793900018, -11.396276286999921],
                [28.437337939000145, -11.404976286999897],
                [28.439437939000186, -11.411476286999914],
                [28.440037939000092, -11.419776286999934],
                [28.437937939000051, -11.424876286999961],
                [28.43403793900012, -11.431276286999946],
                [28.431537939000066, -11.435676286999922],
                [28.431037939000078, -11.442776286999958],
                [28.429437939000024, -11.449876286999881],
                [28.428537939000137, -11.453976286999932],
                [28.428251028000147, -11.454525161999868],
                [28.426237939000089, -11.458376286999908],
                [28.421937939000145, -11.463176286999953],
                [28.414837939000108, -11.468376286999899],
                [28.407737939000071, -11.475876286999949],
                [28.404637939000111, -11.485276286999863],
                [28.404137939000123, -11.495076286999961],
                [28.403937939000116, -11.50357628699993],
                [28.401737939000157, -11.515876286999969],
                [28.399337939000134, -11.525476286999947],
                [28.398137939000151, -11.534076286999948],
                [28.397619010000142, -11.536084318999883],
                [28.395837939000103, -11.542976286999931],
                [28.393937939000125, -11.551376286999925],
                [28.393937939000125, -11.560476286999972],
                [28.392537939000078, -11.56887628699991],
                [28.391637939000077, -11.579676286999927],
                [28.391837939000084, -11.587176286999977],
                [28.393537939000055, -11.593276286999924],
                [28.395837939000103, -11.597676286999956],
                [28.404637939000111, -11.610376286999951],
                [28.407137939000165, -11.614076286999932],
                [28.412737939000067, -11.619476286999941],
                [28.416437939000161, -11.62427628699993],
                [28.422937939000064, -11.628076286999942],
                [28.429437939000024, -11.632276286999968],
                [28.433537939000132, -11.637176286999932],
                [28.436337939000168, -11.641776286999971],
                [28.439837939000029, -11.649576286999888],
                [28.440437939000105, -11.656776286999957],
                [28.437137939000138, -11.663876286999937],
                [28.432537938999985, -11.670376286999954],
                [28.42873793900003, -11.677476286999877],
                [28.428937939000036, -11.682876286999942],
                [28.433137939000062, -11.688276286999894],
                [28.437937939000051, -11.692076286999963],
                [28.441037939000012, -11.694376286999955],
                [28.445037939000088, -11.697276286999909],
                [28.446937939000065, -11.699976286999913],
                [28.448737939000125, -11.703476286999887],
                [28.447537939000142, -11.706476286999873],
                [28.446237939000071, -11.711276286999919],
                [28.442137939000077, -11.715576286999976],
                [28.437337939000145, -11.718276286999924],
                [28.429537939000113, -11.723676286999876],
                [28.429837939000095, -11.728076286999965],
                [28.43403793900012, -11.731476286999964],
                [28.440437939000105, -11.735376286999895],
                [28.443337939000116, -11.74037628699989],
                [28.449637939000183, -11.747876286999883],
                [28.456137939000087, -11.756676286999948],
                [28.459637939000174, -11.760376286999929],
                [28.462337939000065, -11.766676286999939],
                [28.463537939000048, -11.772076286999891],
                [28.465037939000183, -11.777276286999893],
                [28.464037939000036, -11.782676286999958],
                [28.461037939000164, -11.786676286999977],
                [28.457137939000063, -11.791676286999973],
                [28.45333793900005, -11.79777628699992],
                [28.450037939000026, -11.810676286999978],
                [28.448337939000055, -11.813776286999939],
                [28.447937939000042, -11.819576286999961],
                [28.450837939000166, -11.824976286999913],
                [28.454037939000102, -11.826676286999884],
                [28.456937939000056, -11.829776286999959],
                [28.456955031, -11.829812125999979],
                [28.460037939000188, -11.836276286999976],
                [28.462937938999971, -11.840076286999874],
                [28.467537939000124, -11.841276286999971],
                [28.470937939000066, -11.840476286999944],
                [28.474237939000034, -11.83917628699993],
                [28.480437939000012, -11.832576286999824],
                [28.487727319999976, -11.832322005999913],
                [28.489037939000184, -11.832276286999956],
                [28.493437939000103, -11.833376286999908],
                [28.497337938999976, -11.837076286999832],
                [28.499237939000182, -11.841676286999927],
                [28.50323793900003, -11.847576286999981],
                [28.505737939000085, -11.85337628699989],
                [28.504437939000184, -11.861076286999889],
                [28.504134208000039, -11.865024791999929],
                [28.504037939000114, -11.866276286999891],
                [28.504437939000184, -11.872976286999972],
                [28.505131465000034, -11.873927407999872],
                [28.507937939000044, -11.877776286999904],
                [28.512537938999969, -11.880476286999965],
                [28.523237939000182, -11.881976286999873],
                [28.533237939000117, -11.888376286999971],
                [28.541537939000136, -11.894476286999861],
                [28.548637939000173, -11.898976286999869],
                [28.556218508000029, -11.900567270999943],
                [28.556737939000129, -11.90067628699984],
                [28.56543223000017, -11.90196067099987],
                [28.565537939000137, -11.901976286999911],
                [28.573037939000187, -11.905076286999929],
                [28.579437939, -11.908776286999966],
                [28.585571806000132, -11.911891901999923],
                [28.585737939000126, -11.911976286999902],
                [28.591537939000034, -11.912376286999972],
                [28.599537939000072, -11.910876286999951],
                [28.60162376500017, -11.91038959399998],
                [28.605537939000044, -11.909476286999904],
                [28.609537939000063, -11.910476286999938],
                [28.615737939000041, -11.911076286999958],
                [28.62113793900005, -11.910276286999931],
                [28.627837939000017, -11.909676286999854],
                [28.631637939000029, -11.912176286999909],
                [28.634637939000072, -11.916576286999941],
                [28.635737938999966, -11.923676286999921],
                [28.636637939000025, -11.927376286999902],
                [28.639337939000143, -11.934676286999945],
                [28.647637939000163, -11.943476286999953],
                [28.655537939000112, -11.950276286999951],
                [28.666437939000105, -11.956176286999948],
                [28.675937939000107, -11.96197628699997],
                [28.685837939000123, -11.967176286999972],
                [28.696037939000178, -11.97557628699991],
                [28.696625882000035, -11.976141322999922],
                [28.703737939000064, -11.982976286999872],
                [28.709937939000042, -11.986576286999934],
                [28.711038764000079, -11.987126699999919],
                [28.714937939000038, -11.989076286999932],
                [28.717090488000053, -11.990107716999944],
                [28.719737939000026, -11.99137628699998],
                [28.724737939000192, -11.992576286999963],
                [28.733937939000043, -11.991176286999973],
                [28.74203793900017, -11.987976286999981],
                [28.747237939, -11.984876286999963],
                [28.749086147000185, -11.984058227999981],
                [28.753337939000062, -11.982176286999959],
                [28.760637939000105, -11.981576286999939],
                [28.766837939000084, -11.982576286999915],
                [28.771437939, -11.989476286999889],
                [28.775837939000098, -11.998876286999973],
                [28.777337939000063, -12.010976286999892],
                [28.778937939000116, -12.019876286999875],
                [28.784137939000118, -12.025776286999928],
                [28.786437939000166, -12.033876286999885],
                [28.786437939000166, -12.043476286999976],
                [28.788337938999973, -12.048276286999965],
                [28.791837939000061, -12.05267628699994],
                [28.79543793900018, -12.055576286999894],
                [28.808737939000025, -12.062676286999931],
                [28.813937939000027, -12.064976286999979],
                [28.818137939000053, -12.068876286999966],
                [28.824137939000025, -12.076576286999909],
                [28.830437939000092, -12.085576286999924],
                [28.840237939000076, -12.092876286999967],
                [28.847537939000119, -12.100176286999897],
                [28.85023793900001, -12.105976286999919],
                [28.853837939000186, -12.110776286999965],
                [28.858337939000023, -12.120776286999956],
                [28.868137939000121, -12.137276286999963],
                [28.874037939000175, -12.144776286999956],
                [28.882537939000031, -12.152876286999913],
                [28.8940379390001, -12.158976286999973],
                [28.900437939000142, -12.161876286999927],
                [28.910537939000051, -12.163776286999905],
                [28.921737938999968, -12.169376286999977],
                [28.933037939000087, -12.181476286999896],
                [28.947337939000192, -12.193976286999884],
                [28.955937939000137, -12.20037628699987],
                [28.964237939000157, -12.209376286999884],
                [28.968837939000082, -12.218376286999899],
                [28.972437939000088, -12.226676286999918],
                [28.982137939000097, -12.23977628699987],
                [28.990137939000135, -12.256076286999928],
                [28.997237939000172, -12.264876286999936],
                [29.002237939000167, -12.270476286999838],
                [29.009337939000147, -12.27607628699991],
                [29.015137939000056, -12.281276286999912],
                [29.020137939000051, -12.290476286999876],
                [29.021737939000104, -12.301576286999932],
                [29.022237939000092, -12.309676286999888],
                [29.02533793900011, -12.320876286999862],
                [29.030937939000182, -12.331376286999898],
                [29.036637939000173, -12.3365762869999],
                [29.043037939000158, -12.344476286999964],
                [29.047037939000177, -12.349876286999915],
                [29.047437939000076, -12.357576286999915],
                [29.045937939000112, -12.362976286999981],
                [29.045137939000028, -12.367376286999956],
                [29.047037939000177, -12.372776286999908],
                [29.050937939000107, -12.378176286999974],
                [29.066437939000082, -12.383476286999894],
                [29.07143793900002, -12.384276286999921],
                [29.072400557000094, -12.38441546099989],
                [29.07973793900004, -12.385476286999904],
                [29.08803793900006, -12.385576286999935],
                [29.09553793900011, -12.382576286999949],
                [29.096792029000142, -12.381740226999966],
                [29.103337939000141, -12.377376286999947],
                [29.112637939000138, -12.374076286999923],
                [29.120337939000024, -12.374076286999923],
                [29.125637939000171, -12.377576286999954],
                [29.130437939000103, -12.385476286999904],
                [29.133537939000121, -12.392976286999897],
                [29.137073185000077, -12.392033554999955],
                [29.139537939000036, -12.391376286999957],
                [29.141056697000124, -12.390346620999935],
                [29.14543793900009, -12.387376286999938],
                [29.152037939000138, -12.383276286999944],
                [29.164937939000083, -12.378176286999974],
                [29.173467935000076, -12.375226661999875],
                [29.175637939000069, -12.374476286999879],
                [29.187809316000028, -12.371597920999932],
                [29.190437939000162, -12.370976286999849],
                [29.195837939, -12.370676286999981],
                [29.205237939000085, -12.371176286999969],
                [29.212537939000129, -12.371376286999975],
                [29.220837939000148, -12.371576286999925],
                [29.225860724000142, -12.373709249999933],
                [29.228137939000192, -12.374676286999943],
                [29.236537939000129, -12.378176286999974],
                [29.245637939000062, -12.377776286999904],
                [29.252737939000099, -12.37427628699993],
                [29.264437939, -12.370076286999904],
                [29.272186146000138, -12.367899823999949],
                [29.273337939000101, -12.367576286999906],
                [29.281737939000038, -12.367976286999976],
                [29.288337939000087, -12.37087628699993],
                [29.292937939000183, -12.374076286999923],
                [29.305937939000046, -12.379676286999825],
                [29.311137939000048, -12.382776286999899],
                [29.315237939000042, -12.387176286999875],
                [29.315637939000055, -12.390776286999937],
                [29.314837939000142, -12.394876286999818],
                [29.314237939000066, -12.398276286999931],
                [29.313637939000159, -12.402676286999906],
                [29.316137939000043, -12.404876286999979],
                [29.321337939000045, -12.405576286999974],
                [29.326737939000111, -12.406776286999957],
                [29.330937938999966, -12.410076286999981],
                [29.336137939000025, -12.415076286999977],
                [29.338676615000111, -12.416451402999883],
                [29.340937939000128, -12.417676286999892],
                [29.345937939000123, -12.419276286999946],
                [29.350537939000048, -12.419476286999952],
                [29.355337939000037, -12.418376286999887],
                [29.362337939000099, -12.414476286999957],
                [29.365294457000118, -12.412936433999846],
                [29.367137939000088, -12.411976286999959],
                [29.373237939000148, -12.408276286999978],
                [29.379637939000133, -12.406776286999957],
                [29.384937939000054, -12.40657628699995],
                [29.391537939000102, -12.409676286999911],
                [29.398437939000075, -12.41387628699988],
                [29.405737939000119, -12.42197628699995],
                [29.418037939000158, -12.430076286999849],
                [29.427437939000072, -12.434076286999982],
                [29.434937939000122, -12.437176286999943],
                [29.442637939000065, -12.439976286999979],
                [29.449737939000102, -12.440176286999929],
                [29.45593793900008, -12.440376286999935],
                [29.460937939000019, -12.440976286999955],
                [29.466237939000166, -12.444076286999916],
                [29.474537939000186, -12.448276286999885],
                [29.479337939000118, -12.45137628699996],
                [29.48533793900009, -12.452276286999847],
                [29.489137939000102, -12.452276286999847],
                [29.494337939000104, -12.45137628699996],
                [29.499937939000176, -12.447476286999972],
                [29.503737939000189, -12.445176286999981],
                [29.507237939000049, -12.441576286999862],
                [29.510837939000169, -12.438276286999951],
                [29.515337939000176, -12.43657628699998],
                [29.520637939000153, -12.434976286999927],
                [29.526837939000131, -12.430576286999894],
                [29.531437939000057, -12.425976286999969],
                [29.536437939000052, -12.422676286999945],
                [29.537342194000132, -12.420961320999936],
                [29.539337939000177, -12.417176286999904],
                [29.540637939000078, -12.411976286999959],
                [29.54013793900009, -12.40617628699988],
                [29.5387379390001, -12.397376286999872],
                [29.536237939000159, -12.390276286999949],
                [29.532437938999976, -12.388076286999933],
                [29.527237939000145, -12.387976286999901],
                [29.522237938999979, -12.390076286999943],
                [29.514337939000029, -12.39217628699987],
                [29.509537939000097, -12.393076286999928],
                [29.504537939000102, -12.392976286999897],
                [29.499937939000176, -12.390076286999943],
                [29.494737939000174, -12.386176286999842],
                [29.49183793899999, -12.382376286999943],
                [29.489737939000179, -12.376676286999952],
                [29.485937939000166, -12.366176286999973],
                [29.480337939000094, -12.353776286999846],
                [29.473137939000139, -12.334276286999966],
                [29.467437939000149, -12.319776286999968],
                [29.467837939000049, -12.31117628699991],
                [29.468337939000037, -12.302176286999895],
                [29.473937939000109, -12.293176286999881],
                [29.477737939000065, -12.285176286999956],
                [29.479937939000024, -12.279976286999897],
                [29.484137939000107, -12.27017628699997],
                [29.487737939000056, -12.26247628699997],
                [29.488537938999968, -12.256576286999916],
                [29.490037939000104, -12.250376286999938],
                [29.494037939000123, -12.244976286999929],
                [29.499537939000106, -12.239376286999914],
                [29.507537939000144, -12.233476286999974],
                [29.518837939000036, -12.228076286999965],
                [29.528737939000109, -12.225276286999929],
                [29.536737939000147, -12.222176286999911],
                [29.54623793900015, -12.219276286999957],
                [29.552537939000047, -12.213176286999897],
                [29.555637939000064, -12.208176286999901],
                [29.556211739999981, -12.20641074599996],
                [29.556937939000136, -12.204176286999882],
                [29.558937939000089, -12.199276286999918],
                [29.562337939000031, -12.196476286999882],
                [29.567237939000051, -12.196476286999882],
                [29.569859155000074, -12.196266589999937],
                [29.572237939000047, -12.196076286999926],
                [29.576837938999972, -12.194176286999891],
                [29.578398368000023, -12.192541551999909],
                [29.581037939000055, -12.189776286999916],
                [29.585337939, -12.18497628699987],
                [29.588637939000023, -12.18477628699992],
                [29.591137939000134, -12.191376286999969],
                [29.595037939000065, -12.19247628699992],
                [29.606037939000146, -12.19047628699991],
                [29.610637939000071, -12.192576286999952],
                [29.612337939000042, -12.195076286999949],
                [29.615637939000067, -12.198876286999962],
                [29.616537954000023, -12.200539950999939],
                [29.618937939000091, -12.204976286999909],
                [29.623437939000098, -12.213876286999891],
                [29.627537939000035, -12.215876286999844],
                [29.631837939000036, -12.214976286999956],
                [29.634737939000161, -12.212676286999908],
                [29.638537939000173, -12.211876286999882],
                [29.642837939000174, -12.21437628699988],
                [29.645537939000064, -12.216876286999877],
                [29.647537939000188, -12.220176286999902],
                [29.650337939000053, -12.223876286999882],
                [29.656737939000038, -12.226476286999969],
                [29.660737939000057, -12.227176286999907],
                [29.664737939000133, -12.227576286999977],
                [29.669437939000147, -12.227776286999926],
                [29.675737939000044, -12.227976286999933],
                [29.679637939000145, -12.227576286999977],
                [29.684037939000063, -12.226876286999868],
                [29.689537939000047, -12.225776286999974],
                [29.693837939000161, -12.224176286999921],
                [29.698537939000062, -12.221776286999955],
                [29.702137939000181, -12.22007628699987],
                [29.705837939000105, -12.217776286999936],
                [29.709937939000042, -12.215976286999933],
                [29.714237938999986, -12.214476286999911],
                [29.719037939000145, -12.213576286999967],
                [29.725337939000042, -12.212576286999933],
                [29.727337939000165, -12.209776286999954],
                [29.72803793900016, -12.205476286999897],
                [29.730137939000031, -12.202476286999911],
                [29.73453793900012, -12.20137628699996],
                [29.738537939000139, -12.198376286999974],
                [29.740337939000028, -12.194876286999943],
                [29.741337939000175, -12.189776286999916],
                [29.742237939000177, -12.181476286999896],
                [29.745137939000131, -12.174076286999934],
                [29.748637939000048, -12.168776286999901],
                [29.748669042000188, -12.168735741999853],
                [29.75423793900012, -12.161476286999971],
                [29.760637939000105, -12.156476286999975],
                [29.76393793900013, -12.153676286999939],
                [29.770937939000191, -12.148676286999944],
                [29.776337939000086, -12.146676286999934],
                [29.782737939000071, -12.146276286999978],
                [29.789037938999968, -12.146876286999941],
                [29.795837939000023, -12.147876286999917],
                [29.80153793900007, -12.148076286999867],
                [29.806637939000154, -12.149176286999932],
                [29.810637939000173, -12.149376286999939],
                [29.813449603000095, -12.149473240999896],
                [29.81347210600012, -12.149484240999925],
                [29.815178920999983, -12.14923033499997],
                [29.817134837000026, -12.14840228199995],
                [29.817598757000042, -12.148055875999944],
                [29.819968496000058, -12.147467973999881],
                [29.822251273000063, -12.146665870999982],
                [29.822337939000136, -12.147576286999879],
                [29.825737939000078, -12.495376286999829],
                [29.818409656000085, -12.982015295999929],
                [29.811437939000086, -13.444976286999974],
                [29.80919975300003, -13.444501519999847],
                [29.80153793900007, -13.442876286999933],
                [29.79723793900007, -13.441476286999887],
                [29.79463793900004, -13.440276286999904],
                [29.776537939000093, -13.432776286999854],
                [29.771237939000173, -13.432576286999904],
                [29.766237939000177, -13.435076286999902],
                [29.76173793900017, -13.438476286999958],
                [29.761246858000106, -13.438928081999904],
                [29.759237939000059, -13.440776286999892],
                [29.753537939000068, -13.443476286999839],
                [29.749437939000131, -13.445376286999931],
                [29.744037939000066, -13.447976286999847],
                [29.739629056000069, -13.449828017999948],
                [29.739037939000127, -13.450076286999945],
                [29.736037939000028, -13.450576286999933],
                [29.730637939000189, -13.450276286999895],
                [29.726037939000037, -13.451476286999878],
                [29.720937939000123, -13.452476286999854],
                [29.707437939000101, -13.450376286999926],
                [29.704237939000052, -13.448876286999962],
                [29.701037939000116, -13.447276286999909],
                [29.696937939000179, -13.444376286999955],
                [29.694437939000068, -13.441876286999957],
                [29.691237939000189, -13.438776286999882],
                [29.689037939000059, -13.436576286999923],
                [29.686937939000188, -13.434476286999825],
                [29.684137939000152, -13.432176286999947],
                [29.680737939000039, -13.430976286999964],
                [29.679937847000076, -13.430976286999964],
                [29.67713793900009, -13.430976286999964],
                [29.673937939000155, -13.430976286999964],
                [29.670337938999978, -13.431476286999953],
                [29.666937939000036, -13.432376286999954],
                [29.662690059000113, -13.433022703999882],
                [29.662337939000111, -13.433076286999892],
                [29.658537939000155, -13.433776286999887],
                [29.65483793900006, -13.43497628699987],
                [29.65053793900006, -13.435476286999972],
                [29.646237939000116, -13.435776286999896],
                [29.643454808000115, -13.434689540999955],
                [29.635737939000137, -13.431676286999959],
                [29.633537939000178, -13.429376286999911],
                [29.631637939000029, -13.426276286999894],
                [29.628437939000094, -13.422976286999813],
                [29.625637939000057, -13.41957628699987],
                [29.617737939000108, -13.41347628699998],
                [29.617504606000068, -13.413253891999886],
                [29.611337939000123, -13.40737628699992],
                [29.612237939000124, -13.40187628699988],
                [29.613637939000171, -13.398576286999969],
                [29.615637939000067, -13.387776286999951],
                [29.615637939000067, -13.384076286999971],
                [29.613771379000184, -13.381260891999887],
                [29.608376843000087, -13.373124133999909],
                [29.604990005000161, -13.368015652999929],
                [29.60363793900018, -13.36597628699991],
                [29.603737939000098, -13.361076286999946],
                [29.60753793900011, -13.358976286999905],
                [29.610937939000053, -13.358976286999905],
                [29.613164701000073, -13.359303751999846],
                [29.614337939000166, -13.359476286999893],
                [29.619937939000067, -13.36007628699997],
                [29.624837939000145, -13.360676286999876],
                [29.627937939000105, -13.360676286999876],
                [29.630937938999978, -13.360576286999958],
                [29.633937939000077, -13.359976286999881],
                [29.63733793900019, -13.359476286999893],
                [29.640937939000139, -13.357476286999884],
                [29.643237939000016, -13.355576286999963],
                [29.643544482000095, -13.35525697199995],
                [29.645637939000039, -13.353076286999965],
                [29.648637939000082, -13.346576286999948],
                [29.650437939000142, -13.342576286999929],
                [29.652237939000031, -13.33837628699996],
                [29.656537939000032, -13.328376286999912],
                [29.656737939000038, -13.325476286999958],
                [29.656037939000043, -13.321776286999977],
                [29.654937939000149, -13.318476286999953],
                [29.653937939, -13.315576286999885],
                [29.653237939000178, -13.312576286999899],
                [29.651137938999966, -13.306176286999914],
                [29.649837939000065, -13.301376286999869],
                [29.649837939000065, -13.297776286999976],
                [29.650037939000072, -13.294776286999934],
                [29.653637939000077, -13.288176286999885],
                [29.655837939000037, -13.285776286999976],
                [29.657224076000034, -13.284687178999945],
                [29.660037939000063, -13.282476286999895],
                [29.662637939000092, -13.280776286999924],
                [29.663287068000045, -13.279546358999937],
                [29.66453793900007, -13.277176286999861],
                [29.667237939000188, -13.274376286999825],
                [29.669437939000147, -13.270776286999933],
                [29.673037939000096, -13.266176286999837],
                [29.675737939000044, -13.262976286999901],
                [29.677637939000022, -13.257576286999949],
                [29.67713793900009, -13.255476286999851],
                [29.67704563500007, -13.255055791999951],
                [29.676237939000032, -13.251376286999971],
                [29.676137939000114, -13.246576286999925],
                [29.675362054000118, -13.243472747999931],
                [29.675137939000138, -13.242576286999906],
                [29.673837939000066, -13.23957628699992],
                [29.672337939000101, -13.237176286999954],
                [29.670137939000142, -13.232576286999915],
                [29.665237939000065, -13.231776286999889],
                [29.662037939000186, -13.231676286999971],
                [29.653237939000178, -13.237176286999954],
                [29.65093793900013, -13.23977628699987],
                [29.647337939000181, -13.242676286999938],
                [29.644537939000145, -13.243776286999889],
                [29.640037939000138, -13.244576286999916],
                [29.636637939000025, -13.244976286999872],
                [29.632937939000101, -13.244576286999916],
                [29.628937939000082, -13.243976286999839],
                [29.624137939000093, -13.240976286999967],
                [29.621637939000038, -13.238876286999925],
                [29.617937939000115, -13.236476286999959],
                [29.613837939000177, -13.233176286999935],
                [29.610737939000046, -13.230576286999906],
                [29.607337939000104, -13.228076286999908],
                [29.604537939000068, -13.225676286999942],
                [29.600937939000062, -13.22187628699993],
                [29.598457944000131, -13.219458291999899],
                [29.596937939000043, -13.217976286999942],
                [29.592537939000124, -13.215176286999963],
                [29.590293682000151, -13.214470948999974],
                [29.589037939000093, -13.214076286999898],
                [29.587799121000103, -13.213672950999921],
                [29.584737939000092, -13.212676286999908],
                [29.57913793900002, -13.211576286999843],
                [29.574537939000095, -13.212476286999959],
                [29.571737939000059, -13.214476286999968],
                [29.569737939000163, -13.215976286999933],
                [29.565637939, -13.219476286999964],
                [29.563537939000184, -13.221576286999891],
                [29.55943793900002, -13.223976286999971],
                [29.554937939000013, -13.225776286999974],
                [29.551779184000054, -13.226700800999879],
                [29.550837939000075, -13.226976286999957],
                [29.545237939000174, -13.230776286999969],
                [29.542037939000124, -13.231376286999932],
                [29.534037939000029, -13.232376286999909],
                [29.531337939000139, -13.233476286999974],
                [29.525937939000073, -13.234976286999824],
                [29.523037939000119, -13.236476286999959],
                [29.516637939000077, -13.241676286999962],
                [29.513937939000186, -13.243476286999964],
                [29.508837939000102, -13.246076286999937],
                [29.506137939000155, -13.248976286999891],
                [29.503637939000043, -13.251776286999927],
                [29.501437939000084, -13.254376286999957],
                [29.497837939000135, -13.258676286999957],
                [29.496772682000028, -13.260066536999943],
                [29.487792103000118, -13.271786954999982],
                [29.486037939000084, -13.274076286999957],
                [29.482837938999978, -13.278076286999976],
                [29.480837939000082, -13.280776286999924],
                [29.476137939000012, -13.285176286999899],
                [29.472637939000151, -13.288476286999924],
                [29.468837938999968, -13.29087628699989],
                [29.466137939000077, -13.292876286999899],
                [29.462237939000147, -13.296176286999923],
                [29.459337939000193, -13.298076286999958],
                [29.456137939000087, -13.299676286999897],
                [29.455728011000076, -13.299987832999932],
                [29.453637938999975, -13.301576286999875],
                [29.450537939000185, -13.304476286999943],
                [29.446737939000172, -13.308676286999912],
                [29.44373793900013, -13.310776286999953],
                [29.425537939000094, -13.315576286999885],
                [29.414437939000038, -13.317976286999965],
                [29.411950856000033, -13.319190908999929],
                [29.410137939000037, -13.320076286999893],
                [29.405337939000106, -13.32237628699994],
                [29.393737939000061, -13.326776286999973],
                [29.386037939000119, -13.329576286999895],
                [29.382037939000099, -13.329976286999965],
                [29.377437939000174, -13.329176286999939],
                [29.374237939000068, -13.328876286999957],
                [29.371037939000189, -13.327976286999956],
                [29.365737939000041, -13.328276286999881],
                [29.361537939000186, -13.330776286999878],
                [29.358037939000155, -13.335676286999956],
                [29.356537939000191, -13.339576286999943],
                [29.355637939000133, -13.345476286999883],
                [29.354837939000049, -13.348876286999939],
                [29.352737939000178, -13.3519762869999],
                [29.350937939000119, -13.352876286999958],
                [29.341537939000034, -13.351276286999905],
                [29.33333793900016, -13.34627628699991],
                [29.329137939000077, -13.343576286999905],
                [29.325437939000039, -13.343476286999874],
                [29.321837939000034, -13.343476286999874],
                [29.317837939000185, -13.343676286999937],
                [29.313037939000083, -13.344476286999964],
                [29.310845512000128, -13.347200210999972],
                [29.309737939000058, -13.348576286999958],
                [29.308337939000012, -13.351676286999975],
                [29.307337939000092, -13.355176286999949],
                [29.306337939000116, -13.359376286999975],
                [29.304237939000075, -13.362076286999979],
                [29.298337939000078, -13.370576286999949],
                [29.29483793899999, -13.372776286999965],
                [29.291937939000036, -13.37407628699998],
                [29.287937939000187, -13.376276286999939],
                [29.280337939000049, -13.37857628699993],
                [29.276437939000118, -13.379576286999963],
                [29.273437939000019, -13.380676286999915],
                [29.26803793900018, -13.383276286999944],
                [29.265337939000062, -13.384776286999966],
                [29.263037939000185, -13.386576286999968],
                [29.262708758000031, -13.386831136999888],
                [29.259937939, -13.388976286999934],
                [29.256537939000054, -13.391076286999976],
                [29.253137939000112, -13.39377628699998],
                [29.238637939000171, -13.405576286999974],
                [29.235437939000064, -13.408376286999896],
                [29.230637939000076, -13.411076286999901],
                [29.227937939000185, -13.412976286999935],
                [29.221837939000125, -13.415776286999971],
                [29.218237939000119, -13.416576286999884],
                [29.214137939000182, -13.418376286999944],
                [29.20983793900001, -13.419876286999909],
                [29.207485050000116, -13.421143226999845],
                [29.207237939000038, -13.421276286999841],
                [29.204337939000084, -13.423576286999889],
                [29.201137939000148, -13.426676286999964],
                [29.198637939000037, -13.429376286999911],
                [29.195037939000088, -13.432176286999947],
                [29.191437939000139, -13.435076286999902],
                [29.188437939000039, -13.438676286999964],
                [29.182635030000142, -13.442757453999945],
                [29.179337939000106, -13.445076286999949],
                [29.175137939000081, -13.446276286999876],
                [29.172337939000045, -13.444476286999873],
                [29.170337939000092, -13.441976286999875],
                [29.169037939000191, -13.437876286999938],
                [29.168437939000114, -13.433876286999919],
                [29.167737939000119, -13.430076286999906],
                [29.167337939000049, -13.425776286999849],
                [29.166537939000079, -13.421476286999962],
                [29.164337939000177, -13.416976286999954],
                [29.161837939000065, -13.411376286999882],
                [29.159737939000024, -13.406576286999893],
                [29.15723793900014, -13.402876286999913],
                [29.154937939000092, -13.400976286999935],
                [29.151737939000157, -13.398276286999931],
                [29.141937939000059, -13.384776286999966],
                [29.139437939000118, -13.382776286999956],
                [29.134737939000104, -13.380676286999915],
                [29.120337939000024, -13.376276286999939],
                [29.116137939000168, -13.376276286999939],
                [29.112337939000156, -13.377476286999922],
                [29.108037938999985, -13.377776286999961],
                [29.104137939000111, -13.378776286999937],
                [29.098237939000057, -13.381276286999935],
                [29.086037939000107, -13.386376286999848],
                [29.08233793900007, -13.38827628699994],
                [29.07833793900005, -13.390576286999817],
                [29.073537939000062, -13.392976286999954],
                [29.071237939000184, -13.394476286999918],
                [29.060637939000117, -13.39967628699992],
                [29.045337939000035, -13.409976286999949],
                [29.042237939000074, -13.411476286999971],
                [29.038637939000068, -13.41367628699993],
                [29.034037939000143, -13.415976286999978],
                [29.030237939000131, -13.416776286999834],
                [29.026537939000093, -13.419076286999882],
                [29.019137939000132, -13.422376286999906],
                [29.014737939000042, -13.42337628699994],
                [29.012037939000095, -13.424576286999923],
                [29.007737939000094, -13.425676286999931],
                [28.995937939000044, -13.426676286999964],
                [28.992337939000095, -13.425276286999974],
                [28.990037939000047, -13.42337628699994],
                [28.987737939000169, -13.420776286999967],
                [28.985237939000058, -13.418676286999926],
                [28.979737939000131, -13.407076286999882],
                [28.980337939000037, -13.403876286999889],
                [28.982837939000092, -13.39967628699992],
                [28.986537939000186, -13.396376286999896],
                [28.991155134, -13.392230233999953],
                [28.991437939000036, -13.39197628699992],
                [28.995337939000137, -13.388776286999928],
                [28.996147847000032, -13.387512829999935],
                [28.997837939000078, -13.384876286999884],
                [28.997837939000078, -13.379476286999932],
                [28.992037939000113, -13.37797628699991],
                [28.988537939000082, -13.37797628699991],
                [28.985437939000121, -13.37997628699992],
                [28.973737939000159, -13.386476286999937],
                [28.969237939000152, -13.38787628699987],
                [28.959137939000072, -13.382476286999918],
                [28.958637939000084, -13.377876286999879],
                [28.958437939000078, -13.374676286999886],
                [28.958437939000078, -13.371776286999932],
                [28.958837939000091, -13.367676286999881],
                [28.959037939000154, -13.363776286999951],
                [28.957637939000108, -13.359876286999963],
                [28.95613793900003, -13.355476286999874],
                [28.95533793900006, -13.351376286999937],
                [28.954237939000166, -13.348076286999913],
                [28.952237939000099, -13.345076286999927],
                [28.948037939000187, -13.33267628699997],
                [28.946237939000127, -13.327776286999892],
                [28.944637939000074, -13.321776286999977],
                [28.940637939000055, -13.309776286999977],
                [28.939037939000173, -13.305176286999938],
                [28.936737939000125, -13.296276286999955],
                [28.935837939000123, -13.290076286999977],
                [28.933537939000018, -13.286476286999914],
                [28.932537939000099, -13.279576286999941],
                [28.931237938999971, -13.274676286999977],
                [28.930037939000158, -13.271276286999921],
                [28.927037939000115, -13.263076286999933],
                [28.925437939000062, -13.259776286999909],
                [28.922937939000178, -13.25517628699987],
                [28.920937939000055, -13.251276286999882],
                [28.919737939000072, -13.248176286999978],
                [28.918137939000189, -13.244176286999959],
                [28.918137939000189, -13.237476286999822],
                [28.918337939000025, -13.23387628699993],
                [28.918337939000025, -13.230376286999956],
                [28.918337939000025, -13.225876286999949],
                [28.918437939000171, -13.222276286999829],
                [28.916437939000048, -13.212876286999915],
                [28.915937939000059, -13.208576286999971],
                [28.91453793900007, -13.204576286999838],
                [28.912737939000181, -13.199776286999963],
                [28.910637939000139, -13.194376286999898],
                [28.908737939000105, -13.189176286999896],
                [28.906337939000139, -13.184876286999838],
                [28.903737939000166, -13.181576286999928],
                [28.896637939000129, -13.174076286999934],
                [28.892937939000035, -13.170976286999917],
                [28.889237939000168, -13.169076286999939],
                [28.884737939000161, -13.168576286999951],
                [28.88083793900006, -13.169476286999895],
                [28.877837939000187, -13.170276286999922],
                [28.874437939000018, -13.17117628699998],
                [28.869137939000098, -13.169976286999884],
                [28.866237939000143, -13.167576286999974],
                [28.864437939000084, -13.164776286999938],
                [28.862537939000049, -13.161276286999907],
                [28.858437939000112, -13.161076286999958],
                [28.850337939000156, -13.161476286999857],
                [28.847037939000131, -13.160276286999931],
                [28.84233793900006, -13.158376286999953],
                [28.839937939000095, -13.154976286999897],
                [28.838337939000041, -13.150376286999972],
                [28.838037939000117, -13.146476286999871],
                [28.838337939000041, -13.143576286999917],
                [28.838337939000041, -13.140476286999956],
                [28.838137939000035, -13.136576286999912],
                [28.839437939000106, -13.132476286999974],
                [28.839737939000088, -13.129076286999918],
                [28.839737939000088, -13.125076286999899],
                [28.83963793900017, -13.121776286999875],
                [28.838737939000112, -13.115976286999967],
                [28.83703793900014, -13.111076286999889],
                [28.833737939000116, -13.107676286999947],
                [28.828937939000184, -13.103076286999965],
                [28.826137939000148, -13.099676286999909],
                [28.820537939000076, -13.093776286999969],
                [28.820437939000158, -13.090276286999938],
                [28.822937939000042, -13.088776286999973],
                [28.8310143920001, -13.08403913699982],
                [28.833337939000046, -13.082676286999856],
                [28.833383545000117, -13.08240916799997],
                [28.834037939000098, -13.078576286999976],
                [28.831937939000056, -13.075376286999813],
                [28.828137939000044, -13.070076286999949],
                [28.826337939000155, -13.067176286999882],
                [28.824937939000165, -13.064376286999959],
                [28.82343793900003, -13.058276286999899],
                [28.82303793900013, -13.049976286999879],
                [28.823337939000112, -13.044276286999889],
                [28.8238379390001, -13.038576286999898],
                [28.824137939000025, -13.031976286999964],
                [28.822537939000142, -13.027376286999925],
                [28.822037938999983, -13.023176286999956],
                [28.820837939000171, -13.020276286999945],
                [28.819537939000099, -13.016776286999971],
                [28.817437939000058, -13.01287628699987],
                [28.814837939000085, -13.007876286999874],
                [28.813037938999969, -13.003776286999937],
                [28.81123793900008, -13.000676286999919],
                [28.80943793900019, -12.99847628699996],
                [28.802537939000047, -12.992076286999975],
                [28.798737939000034, -12.989076286999932],
                [28.795937939000169, -12.985476286999926],
                [28.794137939000109, -12.98307628699996],
                [28.791537939000079, -12.979176286999973],
                [28.789937939000026, -12.976576286999943],
                [28.787437939000142, -12.973976286999914],
                [28.78363793900013, -12.968976286999862],
                [28.78223793900014, -12.965876286999958],
                [28.781337939000082, -12.962876286999972],
                [28.779737939000029, -12.959976286999847],
                [28.777237939000145, -12.956576286999905],
                [28.773237939000126, -12.954476286999977],
                [28.767737939000142, -12.952776286999892],
                [28.762937938999983, -12.950376286999926],
                [28.758937939000134, -12.949076286999912],
                [28.755137939000178, -12.947776286999897],
                [28.748637939000048, -12.94427628699998],
                [28.746837939000159, -12.940776286999892],
                [28.74553793900003, -12.936876286999905],
                [28.743237938999982, -12.932776286999967],
                [28.740337939000028, -12.92767628699994],
                [28.737437939000074, -12.922276286999818],
                [28.736137939000173, -12.919276286999946],
                [28.73453793900012, -12.915476286999933],
                [28.732437939000079, -12.91187628699987],
                [28.7262379390001, -12.905376286999854],
                [28.723337939000146, -12.900976286999935],
                [28.720337939000103, -12.896476286999928],
                [28.718437939000069, -12.891576286999964],
                [28.71483793900012, -12.887576286999945],
                [28.700937939000028, -12.875476286999913],
                [28.696137939000096, -12.872776286999908],
                [28.691637939000088, -12.870776286999899],
                [28.687737939000158, -12.868876286999978],
                [28.683137939000062, -12.866976286999886],
                [28.678837939000061, -12.863576286999944],
                [28.675837939000189, -12.859776286999875],
                [28.674237939000136, -12.857176286999959],
                [28.672237939000183, -12.854876286999968],
                [28.670337939000035, -12.852276286999881],
                [28.667737939000176, -12.849476286999959],
                [28.66533793900004, -12.846276286999853],
                [28.66313793900008, -12.843976286999975],
                [28.661037939000039, -12.841376286999946],
                [28.658737939000162, -12.83857628699991],
                [28.652737939000076, -12.833076286999869],
                [28.649837939000065, -12.830376286999922],
                [28.647337939000181, -12.828676286999951],
                [28.640237938999974, -12.825976286999946],
                [28.636937939000177, -12.825876286999915],
                [28.634037939, -12.825776286999826],
                [28.628437939000094, -12.826176286999953],
                [28.622737939000103, -12.827876286999924],
                [28.61543793900006, -12.834876286999929],
                [28.609444235000126, -12.837679793999826],
                [28.609237939000081, -12.837776286999883],
                [28.60578208600009, -12.8414206409999],
                [28.60467444700015, -12.842588696999826],
                [28.603737939000155, -12.843576286999905],
                [28.601337939000132, -12.848576286999901],
                [28.600995147000049, -12.849359811999875],
                [28.599937939000142, -12.851776286999893],
                [28.599192221000067, -12.85359397499991],
                [28.598337939000089, -12.855676286999937],
                [28.595437939000135, -12.861276286999896],
                [28.593737939000164, -12.866176286999973],
                [28.592337939000117, -12.871476286999894],
                [28.590337939000051, -12.876276286999939],
                [28.589137939000068, -12.880076286999952],
                [28.588337939000098, -12.882476286999918],
                [28.587431712000182, -12.885557459999973],
                [28.585337939000055, -12.892676286999915],
                [28.583237939000185, -12.897076286999948],
                [28.580837939000048, -12.90027628699994],
                [28.577637939000113, -12.903676286999882],
                [28.573237939000023, -12.906776286999957],
                [28.569837939000081, -12.908076286999972],
                [28.564537939000161, -12.909476286999961],
                [28.56103793900013, -12.909476286999961],
                [28.556237939000141, -12.908076286999972],
                [28.55093793900005, -12.903876286999889],
                [28.548937939000098, -12.900676286999897],
                [28.548337939000191, -12.896576286999959],
                [28.547737939000115, -12.894076286999962],
                [28.545837939000137, -12.888476286999889],
                [28.543337939000025, -12.883776286999932],
                [28.54133793900013, -12.880476286999908],
                [28.536737938999977, -12.875576286999944],
                [28.533237939000117, -12.872676286999877],
                [28.530637939000144, -12.869076286999928],
                [28.529637939000168, -12.865776286999903],
                [28.528337939000096, -12.861376286999928],
                [28.527937939000026, -12.857576286999915],
                [28.528037939000114, -12.85057628699991],
                [28.529137939000179, -12.839976286999956],
                [28.529537939000079, -12.837376286999927],
                [28.529037939000091, -12.833076286999869],
                [28.528137939000032, -12.830976286999942],
                [28.524937939000154, -12.826976286999979],
                [28.522037938999972, -12.822276286999966],
                [28.520337939, -12.819876286999886],
                [28.518137939000042, -12.816276286999937],
                [28.511937939000063, -12.806576286999871],
                [28.508337939000114, -12.80327628699996],
                [28.506637939000143, -12.80187628699997],
                [28.50463793900002, -12.800376286999892],
                [28.502537939000035, -12.798076286999958],
                [28.499137939000093, -12.795676286999878],
                [28.492437939000126, -12.791476286999909],
                [28.488737939000032, -12.788476286999924],
                [28.486237939000148, -12.785576286999969],
                [28.482937939000124, -12.780976286999874],
                [28.480437939000012, -12.776976286999911],
                [28.478437939000116, -12.772676286999967],
                [28.476737939000145, -12.769676286999982],
                [28.474537939000186, -12.766476286999875],
                [28.47173793900015, -12.764276286999916],
                [28.46953793900019, -12.762276286999906],
                [28.466137939000077, -12.757876286999931],
                [28.462537939000129, -12.752076286999909],
                [28.461337939000146, -12.750076286999899],
                [28.460237939000081, -12.748176286999978],
                [28.458337939000046, -12.742576286999906],
                [28.457837939000058, -12.737676286999942],
                [28.457937938999976, -12.731376286999932],
                [28.457837939000058, -12.727676286999952],
                [28.457837939000058, -12.723076286999969],
                [28.458179470000175, -12.719387749999953],
                [28.458337939000046, -12.717676286999904],
                [28.460537939000176, -12.712676286999908],
                [28.462937938999971, -12.710276286999942],
                [28.465937939000071, -12.707976286999951],
                [28.470637939000085, -12.70647628699993],
                [28.478837939000186, -12.70507628699994],
                [28.486437939000155, -12.704776286999959],
                [28.491337939000061, -12.704076286999964],
                [28.498137939000117, -12.70077628699994],
                [28.500837939000064, -12.698276286999942],
                [28.502537939000035, -12.695476286999906],
                [28.50503793900009, -12.691676286999893],
                [28.506237939000073, -12.686876286999961],
                [28.506437939000136, -12.682276286999922],
                [28.506318460000102, -12.680205310999895],
                [28.506137939000155, -12.677076286999977],
                [28.50463793900002, -12.673476286999858],
                [28.504437939000184, -12.668276286999912],
                [28.505337939000071, -12.665376286999958],
                [28.507537939000031, -12.661876286999927],
                [28.510137939000174, -12.659476286999961],
                [28.513537939000116, -12.654776286999891],
                [28.514537939000093, -12.65107628699991],
                [28.512137939000127, -12.645076286999881],
                [28.509237939000172, -12.641076286999919],
                [28.505137939, -12.636876286999893],
                [28.501437939000141, -12.633176286999969],
                [28.498737939000023, -12.629976286999977],
                [28.494437939000022, -12.625176286999931],
                [28.491937939000138, -12.621776286999932],
                [28.488237939000044, -12.618176286999926],
                [28.484337939000113, -12.614676286999895],
                [28.474837939000167, -12.607276286999934],
                [28.471837939000068, -12.60437628699998],
                [28.46913793900012, -12.602176286999907],
                [28.467137939000054, -12.600476286999935],
                [28.463537939000048, -12.598176286999887],
                [28.458437939000135, -12.594176286999982],
                [28.454137939000191, -12.591176286999882],
                [28.450537939000014, -12.588676286999885],
                [28.446237939000071, -12.586276286999919],
                [28.441537939, -12.583076286999926],
                [28.432937939000055, -12.574576286999957],
                [28.429237939000188, -12.56807628699994],
                [28.428937939000036, -12.563676286999964],
                [28.430737939000096, -12.558076286999892],
                [28.432537938999985, -12.553376286999935],
                [28.433337939000126, -12.549576286999923],
                [28.431937939000079, -12.543276286999969],
                [28.429537939000113, -12.540076286999863],
                [28.426937939000084, -12.539076286999887],
                [28.421337939000011, -12.537276286999827],
                [28.417637939000144, -12.535676286999944],
                [28.413737939000043, -12.533076286999858],
                [28.410537939000108, -12.531476286999975],
                [28.408437939000123, -12.53037628699991],
                [28.405537939000112, -12.529576286999884],
                [28.399337939000134, -12.527976286999944],
                [28.394037939000043, -12.524976286999959],
                [28.388837939000041, -12.521576286999959],
                [28.386237939000182, -12.519976286999963],
                [28.383937939000134, -12.517676286999915],
                [28.382637939000062, -12.515276286999949],
                [28.381537939000168, -12.51267628699992],
                [28.380037939000033, -12.510076286999947],
                [28.37313793900006, -12.496276286999944],
                [28.369737939000117, -12.485776286999908],
                [28.362737939, -12.477476286999888],
                [28.356237939000096, -12.47167628699998],
                [28.344537939000134, -12.464476286999968],
                [28.33543793900003, -12.459276286999909],
                [28.332137939000177, -12.454076286999907],
                [28.330437939000035, -12.447076286999902],
                [28.328237939000076, -12.435276286999965],
                [28.323037939000073, -12.424176286999909],
                [28.316515233000189, -12.419827815999895],
                [28.315237939000042, -12.418976286999964],
                [28.314635103000057, -12.419008746999907],
                [28.302237939000179, -12.419676286999902],
                [28.289237939000145, -12.422276286999931],
                [28.279537939000136, -12.422276286999931],
                [28.271737939000104, -12.422876286999895],
                [28.265237939000144, -12.419676286999902],
                [28.259937939000054, -12.417376286999854],
                [28.255060771999979, -12.415066049999837],
                [28.250437939000051, -12.412876286999847],
                [28.243137939000178, -12.411176286999876],
                [28.237937939000176, -12.409276286999955],
                [28.232737939000117, -12.407276286999945],
                [28.224937939000142, -12.412476286999947],
                [28.223511179000184, -12.413903046999962],
                [28.217137939000111, -12.420276286999979],
                [28.206137939000143, -12.426776286999939],
                [28.196337939000045, -12.426176286999919],
                [28.187937939000108, -12.419676286999902],
                [28.179337939000163, -12.419376286999864],
                [28.169937939000079, -12.421376286999873],
                [28.157937939000192, -12.413976286999912],
                [28.154537939000022, -12.410676286999887],
                [28.146937939000054, -12.40797628699994],
                [28.146616055000038, -12.408042654999917],
                [28.137237939000045, -12.409976286999949],
                [28.129837939000083, -12.406676286999868],
                [28.129137939000032, -12.397976286999892],
                [28.131137939000155, -12.392676286999972],
                [28.133037939000133, -12.387876286999813],
                [28.142537939000135, -12.369676286999947],
                [28.145037939000076, -12.361476286999959],
                [28.138437939000028, -12.35887628699993],
                [28.132537938999974, -12.360576286999901],
                [28.125137939000183, -12.365876286999935],
                [28.121437939000145, -12.375476286999856],
                [28.115837939000073, -12.389276286999916],
                [28.111137939, -12.395276286999945],
                [28.105328494000105, -12.397546644999977],
                [28.10243793900014, -12.398676286999887],
                [28.099137939000116, -12.400376286999972],
                [28.097337939000056, -12.404176286999871],
                [28.097337939000056, -12.408476286999928],
                [28.103537939000034, -12.418876286999875],
                [28.108837939000125, -12.425676286999931],
                [28.108837939000125, -12.429276286999936],
                [28.106437939000159, -12.431576286999871],
                [28.099037939000027, -12.435676286999922],
                [28.097337939000056, -12.440676286999974],
                [28.094737939000026, -12.442976286999965],
                [28.091137939000077, -12.444076286999916],
                [28.088537939000048, -12.440676286999974],
                [28.085337939000169, -12.43597628699996],
                [28.079437939000115, -12.428976286999955],
                [28.071537939000166, -12.421876286999861],
                [28.065937939000094, -12.41427628699995],
                [28.064437939000129, -12.410476286999938],
                [28.062737938999987, -12.406976286999907],
                [28.059537939000109, -12.405576286999974],
                [28.055637939000178, -12.403976286999921],
                [28.054637939000031, -12.400776286999928],
                [28.058337939000126, -12.395576286999926],
                [28.058337939000126, -12.39257628699994],
                [28.057637939000074, -12.385976286999892],
                [28.055637939000178, -12.375876286999926],
                [28.049037939000129, -12.369176286999959],
                [28.046337939000182, -12.358076286999903],
                [28.044577047000189, -12.350856629999953],
                [28.044337939000116, -12.349876286999915],
                [28.039637939000045, -12.341176286999939],
                [28.030937939000182, -12.334476286999973],
                [28.020937939000191, -12.335776286999817],
                [28.016237939000177, -12.332476286999963],
                [28.009837939000136, -12.330176286999915],
                [28.006737939000175, -12.328176286999906],
                [27.998437939000155, -12.32497628699997],
                [27.983937938999986, -12.31747628699992],
                [27.955137939000053, -12.2971762869999],
                [27.946437939000191, -12.281976286999907],
                [27.943237939000085, -12.276176286999942],
                [27.942337939000026, -12.272676286999967],
                [27.934337939000159, -12.260276286999897],
                [27.929037939000068, -12.257776286999899],
                [27.927636813000106, -12.25756182899994],
                [27.919237939000084, -12.256276286999878],
                [27.904137939000066, -12.252476286999979],
                [27.869737939, -12.248976286999948],
                [27.865637939000067, -12.24807628699989],
                [27.853037939000103, -12.243576286999883],
                [27.840437939000083, -12.242076286999861],
                [27.83213793900012, -12.2423762869999],
                [27.824937939000165, -12.244576286999859],
                [27.820337939000069, -12.250976286999958],
                [27.81952960600006, -12.252448607999895],
                [27.814893603000087, -12.260892755999919],
                [27.814737939000167, -12.261176286999955],
                [27.810737939000148, -12.263576286999921],
                [27.803137939000123, -12.266876286999945],
                [27.797237939000127, -12.271576286999903],
                [27.790737939000167, -12.276676286999816],
                [27.787137939000047, -12.279776286999891],
                [27.781237939000164, -12.285476286999881],
                [27.778437939000128, -12.287576286999979],
                [27.776337939000143, -12.28927628699995],
                [27.763837939000041, -12.294476286999895],
                [27.758537939000121, -12.294276286999946],
                [27.753037939000137, -12.293576286999951],
                [27.743537939000134, -12.291676286999973],
                [27.743439224000042, -12.291735431999939],
                [27.724855295000111, -12.290036899999848],
                [27.669155295000053, -12.287236899999982],
                [27.662513005000108, -12.2854593859999],
                [27.662055295000073, -12.285336899999891],
                [27.659098286000074, -12.283658597999931],
                [27.657742726000038, -12.28529222099985],
                [27.654648022000117, -12.28529222099985],
                [27.650922915000024, -12.284833746999936],
                [27.647828211000103, -12.282942538999919],
                [27.643530011000109, -12.27858702899988],
                [27.639862214000118, -12.27618003799995],
                [27.637627150000071, -12.271939146999898],
                [27.635048230000052, -12.270277175999979],
                [27.632068145000119, -12.270391794999966],
                [27.628495466000174, -12.270824846999972],
                [27.62828572900014, -12.270850269999869],
                [27.625191025000049, -12.270162557999981],
                [27.622382868000159, -12.26815673099992],
                [27.622370081999975, -12.265999759999886],
                [27.622598202000063, -12.264631040999973],
                [27.623431013000129, -12.263203364999981],
                [27.625394068999981, -12.261359281999887],
                [27.62609954200019, -12.26050044599998],
                [27.626762259000031, -12.259693659999925],
                [27.62842788099999, -12.2579685499999],
                [27.628903774000037, -12.256064981999884],
                [27.627892503000112, -12.254161412999963],
                [27.626940718000185, -12.252436303999957],
                [27.625394068999981, -12.251900924999973],
                [27.624382798000056, -12.250473247999878],
                [27.623305924000135, -12.249611749999929],
                [27.623193067000159, -12.24952146399994],
                [27.622062823000078, -12.248212759999888],
                [27.621170525000139, -12.247379948999935],
                [27.619207470000163, -12.24458408199996],
                [27.618136713000126, -12.243513324999924],
                [27.617006469000046, -12.241550268999958],
                [27.615221873000166, -12.240182078999965],
                [27.614329575000056, -12.239289781999958],
                [27.61462700800007, -12.237624158999893],
                [27.615221873000166, -12.236791346999837],
                [27.615578792000122, -12.235661102999927],
                [27.616352117000076, -12.234709318999876],
                [27.617065955000157, -12.233579074999966],
                [27.617065955000157, -12.23250831799993],
                [27.617244415000187, -12.230664234999949],
                [27.617229581000174, -12.230337891999909],
                [27.617184928000142, -12.229355531999943],
                [27.617065955000157, -12.227927854999905],
                [27.617065955000157, -12.22691658399998],
                [27.616972567000062, -12.225562460999981],
                [27.616953869000099, -12.225291342999981],
                [27.616946982000172, -12.225191474999974],
                [27.616590063000046, -12.224061230999894],
                [27.616385500000092, -12.223319691999848],
                [27.616600451000181, -12.223738689999948],
                [27.61646814400018, -12.223259078999888],
                [27.616196815000023, -12.222275508999871],
                [27.615842323000152, -12.222039180999957],
                [27.615669465000053, -12.221923942999979],
                [27.615759679000064, -12.222099793999973],
                [27.614686494000182, -12.221384336999961],
                [27.614508034000153, -12.220373066999912],
                [27.61436247, -12.220185911999977],
                [27.614532860000111, -12.220238114999972],
                [27.614445114000091, -12.2201253],
                [27.614174273000174, -12.219777074999911],
                [27.612925056000108, -12.218468370999972],
                [27.61209224400011, -12.217873505999876],
                [27.610962001000132, -12.217397613999935],
                [27.608820486000127, -12.21632685599991],
                [27.607868701000143, -12.216207882999925],
                [27.606441025000152, -12.2154940449999],
                [27.605191808000086, -12.215196611999829],
                [27.603704644000175, -12.215137125999945],
                [27.602276968000126, -12.21501815299996],
                [27.600492372000133, -12.214661233999948],
                [27.598648290000142, -12.214423287999978],
                [27.597815478000143, -12.213590475999979],
                [27.596387802000152, -12.212936123999953],
                [27.594960125000057, -12.211508447999847],
                [27.594186800000102, -12.210021284999982],
                [27.593175529000177, -12.208296175999976],
                [27.592580664000081, -12.207582336999963],
                [27.59139093400006, -12.206273633999956],
                [27.589784798000039, -12.205738254999972],
                [27.587702769000146, -12.206154660999971],
                [27.587464823000175, -12.206214146999912],
                [27.586037146000081, -12.206749525999896],
                [27.584728443000074, -12.206690038999966],
                [27.583241280000038, -12.207046957999978],
                [27.582348982000099, -12.207344390999936],
                [27.580326440000078, -12.206214146999912],
                [27.57866081700007, -12.206154660999971],
                [27.576935708000065, -12.20698747199998],
                [27.576043410000125, -12.207284904999881],
                [27.574615734000133, -12.207344390999936],
                [27.573247544000083, -12.20740387799998],
                [27.570987056000092, -12.206927984999936],
                [27.569618866000042, -12.205857227999957],
                [27.567893756000103, -12.20502441699989],
                [27.567060945000037, -12.204251091999936],
                [27.565335836000031, -12.2027639289999],
                [27.564169900000024, -12.201825627999824],
                [27.563670213000137, -12.201540092999892],
                [27.562670839000077, -12.200897637999958],
                [27.561814233000064, -12.199541344999886],
                [27.56031517299999, -12.199684112999932],
                [27.558887496000125, -12.198898890999942],
                [27.557673971000156, -12.197399829999824],
                [27.556817365000143, -12.195615234999934],
                [27.556103527000062, -12.194401709999909],
                [27.555532456000151, -12.193616486999872],
                [27.554961386000173, -12.192474345999869],
                [27.554604467000047, -12.191974658999982],
                [27.554604467000047, -12.191332204999981],
                [27.553676477000181, -12.190047295999875],
                [27.552748487000088, -12.189047921999872],
                [27.551392194000073, -12.187763012999881],
                [27.550250053000184, -12.186906406999981],
                [27.549536215000046, -12.185335962999943],
                [27.547823003000076, -12.184907659999965],
                [27.547109164000062, -12.182908912999949],
                [27.54582425500007, -12.182052306999879],
                [27.544467963000102, -12.181267084999945],
                [27.543896892000191, -12.181124316999842],
                [27.543111670000087, -12.180767397999887],
                [27.542397832000177, -12.180624629999954],
                [27.540756004000059, -12.180196326999976],
                [27.539185559000032, -12.180624629999954],
                [27.537615115000108, -12.179625256999884],
                [27.536544358000071, -12.179268337999929],
                [27.535473600000103, -12.178411731999915],
                [27.534831146000101, -12.177840660999948],
                [27.534045923000065, -12.176841286999945],
                [27.533189318000154, -12.176056064999955],
                [27.532189944000038, -12.175128074999918],
                [27.532047176000106, -12.174342852999871],
                [27.530905035000046, -12.172701024999924],
                [27.530548116000091, -12.170916428999874],
                [27.529334591000122, -12.168417994999913],
                [27.528263832999983, -12.167846924999935],
                [27.527478611000049, -12.166562015999943],
                [27.526622005000036, -12.165348490999975],
                [27.52555124800017, -12.164134965999949],
                [27.524766026000066, -12.162921439999877],
                [27.524266339000178, -12.161922066999978],
                [27.523695268000097, -12.161350995999896],
                [27.523338349000142, -12.16028023899986],
                [27.522196208000025, -12.158281490999968],
                [27.521910672000047, -12.156782430999897],
                [27.521767905000047, -12.155711673999861],
                [27.521553753000092, -12.154069844999981],
                [27.521125450000113, -12.153284622999934],
                [27.520625763000055, -12.151571410999964],
                [27.5202688440001, -12.149786815999903],
                [27.519769157000042, -12.148144987999956],
                [27.51834148100005, -12.146931461999827],
                [27.517413491000184, -12.144647179999936],
                [27.516842420000103, -12.143219502999898],
                [27.516271350000125, -12.141934593999906],
                [27.515414744000111, -12.140221381999936],
                [27.51484367300003, -12.138508170999899],
                [27.515057825000156, -12.137722947999976],
                [27.513558764000038, -12.135652816999936],
                [27.513201845000083, -12.134653443999923],
                [27.513273229000106, -12.133725453999944],
                [27.512844926000128, -12.133154382999976],
                [27.512702158000025, -12.132226392999939],
                [27.512559391000025, -12.131012867999971],
                [27.512202471000137, -12.130227645999923],
                [27.511916936000034, -12.128585817999976],
                [27.511560017000079, -12.127586443999974],
                [27.511131714000101, -12.125873232999879],
                [27.510846179, -12.124374171999932],
                [27.51041787600019, -12.123017878999974],
                [27.510203724000064, -12.121947121999938],
                [27.510346492000167, -12.120233909999911],
                [27.51041787600019, -12.118734849999896],
                [27.510632027000042, -12.118163778999872],
                [27.51056064300019, -12.116664717999925],
                [27.510632027000042, -12.114380435999919],
                [27.511203098000124, -12.112809991999939],
                [27.511488633000056, -12.111239546999855],
                [27.5116908870001, -12.109312183999975],
                [27.511750374000144, -12.10830091299988],
                [27.511750374000144, -12.106873235999899],
                [27.511274482000033, -12.105445559999964],
                [27.51056064300019, -12.103898909999828],
                [27.510144238000123, -12.102649692999933],
                [27.509906292000153, -12.101876367999978],
                [27.509549372000095, -12.100627150999912],
                [27.509608859000139, -12.099615879999931],
                [27.509192453000139, -12.098664095999936],
                [27.509370913000168, -12.097236418999955],
                [27.50966834500008, -12.096403607999946],
                [27.509965778000094, -12.095451823999895],
                [27.510382184000093, -12.09444055299997],
                [27.51079858900016, -12.093607740999914],
                [27.51079858900016, -12.092239550999864],
                [27.511333968000145, -12.090871360999813],
                [27.511036536000063, -12.089146251999978],
                [27.51056064300019, -12.088551386999882],
                [27.509965778000094, -12.087242682999943],
                [27.509013994000043, -12.087302169999816],
                [27.508538102000102, -12.086766790999945],
                [27.507824263000089, -12.086231411999961],
                [27.506991452000193, -12.084803735999969],
                [27.506099154000083, -12.084149383999886],
                [27.505325829000128, -12.083019139999976],
                [27.504790450000144, -12.08236478799995],
                [27.503779179000048, -12.081115570999884],
                [27.503005855000026, -12.079866353999819],
                [27.502292016000013, -12.078855082999894],
                [27.501935097000057, -12.077724838999927],
                [27.501875611000116, -12.076297162999822],
                [27.501697151000087, -12.075642810999966],
                [27.501280745000088, -12.074036674999945],
                [27.500388448000081, -12.073322835999932],
                [27.499734096000111, -12.072965916999976],
                [27.499027321000028, -12.072814465999954],
                [27.498901284000169, -12.072787457999937],
                [27.498603852000031, -12.072727970999892],
                [27.497533094000119, -12.072014132999982],
                [27.496640796000179, -12.071419266999897],
                [27.49562952600013, -12.070943374999956],
                [27.495332093000172, -12.070526969999946],
                [27.494915687000173, -12.069158779999896],
                [27.495272606000128, -12.06808802199987],
                [27.495213120000187, -12.066660344999946],
                [27.495213120000187, -12.065232668999954],
                [27.495273463000103, -12.064890726999977],
                [27.495570039000143, -12.063210126999934],
                [27.49610541800007, -12.061544503999869],
                [27.495986445000085, -12.059997854999892],
                [27.495986445000085, -12.058094285999971],
                [27.496045931000083, -12.056547635999891],
                [27.495272606000128, -12.056190716999936],
                [27.493309551000152, -12.05464406699997],
                [27.492655199000183, -12.054108688999975],
                [27.492179307000072, -12.053632795999931],
                [27.491168036000147, -12.052859471999909],
                [27.490275738000037, -12.052324092999925],
                [27.489086008000186, -12.051729227999942],
                [27.48807473700009, -12.051193848999958],
                [27.487122952000107, -12.050123090999875],
                [27.48646860100007, -12.049230793999982],
                [27.485159897000131, -12.048338495999928],
                [27.483851194000124, -12.048100549999958],
                [27.48331581500014, -12.048279008999884],
                [27.482126084000186, -12.049587712999937],
                [27.481233786000075, -12.050480010999934],
                [27.480282002000024, -12.051074875999973],
                [27.479092272000173, -12.051372307999941],
                [27.477426649000108, -12.052562038999895],
                [27.476534351, -12.052978444999894],
                [27.475936276000027, -12.053290483999945],
                [27.475166161000118, -12.053692282999975],
                [27.474333350000052, -12.054049201999931],
                [27.47260824000017, -12.053751768999916],
                [27.472013375000074, -12.053513822999946],
                [27.471061590999966, -12.052740498999924],
                [27.469693401000143, -12.051372307999941],
                [27.468503670000189, -12.050301550999905],
                [27.468087264000189, -12.049111820999883],
                [27.468503670000189, -12.047803116999944],
                [27.468751134000058, -12.045702052999957],
                [27.469036669000161, -12.044845446999943],
                [27.46863692, -12.044217268999944],
                [27.467437672000074, -12.043817519999891],
                [27.466352637000114, -12.04313223499986],
                [27.465210496, -12.042389842999967],
                [27.465438924000068, -12.041133487999957],
                [27.466352637000114, -12.040276881999944],
                [27.465324710000061, -12.038906311999881],
                [27.46486785400009, -12.038163919999931],
                [27.465039175000129, -12.037250206999886],
                [27.465667353000072, -12.036793350999915],
                [27.465895781000143, -12.036507815999926],
                [27.466866601000163, -12.036108065999883],
                [27.46732472900004, -12.035846278999884],
                [27.467666100000145, -12.035651209999912],
                [27.467437672000074, -12.034908817999963],
                [27.467323457000134, -12.034052211999949],
                [27.466980815000056, -12.033309819999829],
                [27.467152136000095, -12.032339],
                [27.467152136000095, -12.031596607999916],
                [27.466382820000035, -12.031094879999955],
                [27.465838674000111, -12.030740001999902],
                [27.46486785400009, -12.029255217999889],
                [27.464924961000122, -12.027998862999937],
                [27.463611498000034, -12.026913828999966],
                [27.463383070000134, -12.025600365999935],
                [27.463154642000063, -12.024401117999901],
                [27.462469357000145, -12.022745012999906],
                [27.462298036000107, -12.021945513999924],
                [27.462298036000107, -12.020860479999953],
                [27.462298036000107, -12.020403622999822],
                [27.462240929000075, -12.019090160999951],
                [27.462355143000082, -12.017890912999917],
                [27.462469357000145, -12.016406128999904],
                [27.462240929000075, -12.015492415999972],
                [27.462183822000043, -12.014236060999963],
                [27.462183822000043, -12.012865490999957],
                [27.461726965000025, -12.011323599999912],
                [27.461841179000032, -12.009381959999871],
                [27.4615556440001, -12.007897175999915],
                [27.461726965000025, -12.006412392999835],
                [27.461726965000025, -12.005384465999953],
                [27.461955393000096, -12.003956788999972],
                [27.462240929000075, -12.002814647999969],
                [27.462583570999982, -12.001329863999956],
                [27.463154642000063, -11.99995929499994],
                [27.463325963000159, -11.998931366999898],
                [27.464068355000109, -11.998017653999966],
                [27.464424322000184, -11.997065869999915],
                [27.465090571000133, -11.995923728999912],
                [27.465566463000073, -11.995067122999842],
                [27.466423069000086, -11.992878018999875],
                [27.466708605000065, -11.990879270999812],
                [27.467006213000161, -11.990432858999895],
                [27.467279675000043, -11.990022664999969],
                [27.467850746000181, -11.988785345999929],
                [27.467341025000167, -11.987256181999953],
                [27.467184497000176, -11.986786597999924],
                [27.466803783000103, -11.98469267299987],
                [27.466613426000094, -11.983169817999908],
                [27.46632789099999, -11.981551783999976],
                [27.46632789099999, -11.979743393999911],
                [27.467089318000035, -11.977649467999925],
                [27.467374854000013, -11.976316969999914],
                [27.467755568000086, -11.974508579999963],
                [27.468136281000056, -11.972890545999917],
                [27.468421817000092, -11.971082155999966],
                [27.468802530000062, -11.96946412199992],
                [27.469468779000181, -11.967941266999958],
                [27.469754314999989, -11.966608768999834],
                [27.470420564000165, -11.964705199999969],
                [27.470991635000075, -11.963467880999929],
                [27.471562705000053, -11.961564311999894],
                [27.471764705000055, -11.961236061999898],
                [27.472324133000029, -11.960326991999921],
                [27.472609668000075, -11.95889931499994],
                [27.47280002500014, -11.957566816999815],
                [27.473561452000126, -11.956329497999945],
                [27.473656631000097, -11.954140392999818],
                [27.474798772000042, -11.952903073999948],
                [27.475179486000116, -11.951189861999922],
                [27.475655378000056, -11.950713969999981],
                [27.476131270000167, -11.949476649999951],
                [27.476702341000077, -11.948620043999938],
                [27.476797519000115, -11.946716474999903],
                [27.476416806000145, -11.944622548999916],
                [27.475750557000026, -11.942814158999965],
                [27.474608414999977, -11.9409105899999],
                [27.473466274000089, -11.939387734999968],
                [27.472419311000067, -11.937769701999969],
                [27.471277170000178, -11.936151667999923],
                [27.470610921000173, -11.93500952699992],
                [27.470610921000173, -11.933677028999966],
                [27.469373601000143, -11.932058994999863],
                [27.468802530000062, -11.930631318999929],
                [27.467279675000043, -11.929298820999918],
                [27.466232713000124, -11.927871143999937],
                [27.465566463000073, -11.927204894999932],
                [27.463853251000046, -11.925586860999942],
                [27.462330396000084, -11.9244447199999],
                [27.46061718499999, -11.923683292999897],
                [27.459760579000033, -11.922636329999875],
                [27.458332902000109, -11.92158936699991],
                [27.45766665300016, -11.920827938999878],
                [27.456619690000082, -11.91987615499994],
                [27.454811300000074, -11.918734013999938],
                [27.454036713000164, -11.918365162999976],
                [27.452812552000069, -11.917782228999954],
                [27.451384876000077, -11.916544909999971],
                [27.450242735000131, -11.91606901699987],
                [27.449386129000118, -11.915878659999919],
                [27.447387381000169, -11.914831697999887],
                [27.445674169000142, -11.91397509199993],
                [27.445198277000031, -11.913784734999979],
                [27.443580243999975, -11.912547414999892],
                [27.442057389000013, -11.911500451999871],
                [27.440344177000043, -11.910739024999941],
                [27.43834543000014, -11.909787239999957],
                [27.437108110000054, -11.908264384999825],
                [27.436346682000135, -11.906455994999931],
                [27.434252757000138, -11.904552425999896],
                [27.434237984000106, -11.904536421999921],
                [27.433110615000146, -11.903315105999923],
                [27.432254009000133, -11.901792250999961],
                [27.431397403000176, -11.90093564499989],
                [27.429303478000179, -11.899127254999939],
                [27.428922764000106, -11.898270648999926],
                [27.427209552000136, -11.896367079999891],
                [27.42606741100002, -11.895129759999918],
                [27.424544556000058, -11.894368332999875],
                [27.422069916000112, -11.891988871999843],
                [27.421213310000155, -11.891132265999943],
                [27.420261526000104, -11.89075155199987],
                [27.41892902800015, -11.890275659999929],
                [27.416835102000107, -11.889228696999965],
                [27.415692961000161, -11.888847982999948],
                [27.414074927000058, -11.888657626999816],
                [27.412837608000075, -11.888562447999846],
                [27.410553325000024, -11.888752804999967],
                [27.409316005000107, -11.888847982999948],
                [27.407412436000072, -11.888657626999816],
                [27.405794403000073, -11.888086555999905],
                [27.404461905000119, -11.887610663999965],
                [27.403224585000089, -11.887134770999921],
                [27.402650476000076, -11.88673731199998],
                [27.401987265000173, -11.886278165999954],
                [27.401035481000122, -11.885992629999919],
                [27.400083697000071, -11.88532638099997],
                [27.39951262600016, -11.884755309999946],
                [27.398941555000079, -11.883993882999846],
                [27.398751198000014, -11.882851741999957],
                [27.398751198000014, -11.882185492999952],
                [27.398751198000014, -11.880948172999979],
                [27.398656020000146, -11.879234960999895],
                [27.398674392000089, -11.879182905999869],
                [27.399227091000057, -11.877616926999963],
                [27.398941555000079, -11.875332644999958],
                [27.398465663000138, -11.874476038999887],
                [27.398084949000065, -11.872762826999974],
                [27.396942808000176, -11.871239971999898],
                [27.395039239000141, -11.870288187999961],
                [27.392659778000166, -11.869146045999969],
                [27.391136923000033, -11.868384618999926],
                [27.388376748000155, -11.867908726999872],
                [27.386853893000023, -11.868384618999926],
                [27.383903362000069, -11.867432833999942],
                [27.382380507000107, -11.866861763999964],
                [27.381238365000058, -11.86638587199991],
                [27.38019140300014, -11.865624443999877],
                [27.37885890400014, -11.864387123999904],
                [27.378573369000037, -11.863625696999975],
                [27.377145692000113, -11.86172212799994],
                [27.376764979000143, -11.86000891599997],
                [27.375527659000113, -11.857248740999921],
                [27.375337302000048, -11.855630707999978],
                [27.374861410000108, -11.854964458999916],
                [27.373433733000013, -11.854203030999884],
                [27.371434986000111, -11.853631960999905],
                [27.369721774000141, -11.853060889999881],
                [27.368389276000187, -11.851918748999879],
                [27.367342313000165, -11.851062142999979],
                [27.364677317000087, -11.850395893999917],
                [27.361917142000095, -11.849634465999884],
                [27.360394287000076, -11.849729643999979],
                [27.359061789000179, -11.849444108999876],
                [27.357634112000085, -11.848873038999955],
                [27.354588402000161, -11.847255004999852],
                [27.352684833000126, -11.846493576999819],
                [27.351161978000164, -11.845732149999947],
                [27.349448766000137, -11.844399651999936],
                [27.348116268000183, -11.843733402999874],
                [27.346783770000059, -11.842496082999901],
                [27.34564162900017, -11.841449119999879],
                [27.344118774000037, -11.84068769299995],
                [27.342024848000165, -11.838879301999953],
                [27.340026101000035, -11.837641982999912],
                [27.339264673000116, -11.836309483999969],
                [27.339930922000065, -11.834596271999885],
                [27.340311636000138, -11.832121632999929],
                [27.34059717100007, -11.830789134999918],
                [27.340882707000048, -11.829075922999834],
                [27.340406815000108, -11.827743424999881],
                [27.337741818000154, -11.827553067999929],
                [27.335933428000089, -11.827838602999975],
                [27.333934681000187, -11.827553067999929],
                [27.331079327000168, -11.82764824599991],
                [27.329175759000066, -11.827743424999881],
                [27.327272190000031, -11.827553067999929],
                [27.325939692000077, -11.826886818999981],
                [27.324892729000055, -11.82593503399994],
                [27.323369874000093, -11.824983249999946],
                [27.322132554000063, -11.824507357999948],
                [27.320895234000147, -11.823745929999973],
                [27.318420595000134, -11.822508610999932],
                [27.316707383000164, -11.82098575599997],
                [27.313661673000183, -11.819462899999905],
                [27.311472569000102, -11.818130401999952],
                [27.310140071000149, -11.8171786179999],
                [27.308902751000062, -11.816322011999887],
                [27.306332933000078, -11.815275048999979],
                [27.304619721000108, -11.813657015999922],
                [27.302145082000038, -11.811848624999982],
                [27.30024151300006, -11.81080166199996],
                [27.298052409000093, -11.809373985999855],
                [27.296244018000152, -11.80775595199998],
                [27.294340449000117, -11.804995777999977],
                [27.292341701999987, -11.802997029999972],
                [27.289771884000174, -11.802045245999921],
                [27.287392423000028, -11.801188639999907],
                [27.285584033000191, -11.800522390999959],
                [27.284156356000096, -11.800046498999905],
                [27.281301003000181, -11.799570605999975],
                [27.279111899000043, -11.798809178999932],
                [27.276922795000132, -11.79785739499988],
                [27.274924048, -11.796810431999972],
                [27.272163873000181, -11.796239360999948],
                [27.270926553000095, -11.795192397999926],
                [27.268927806000193, -11.794050256999924],
                [27.267867936000073, -11.793589443999963],
                [27.266738702000112, -11.79309847299993],
                [27.264073705000101, -11.79319365099991],
                [27.26359781299999, -11.795097219999946],
                [27.263407456000152, -11.797000788999981],
                [27.262947611000016, -11.798456966999936],
                [27.262836386000174, -11.798809178999932],
                [27.262552217000177, -11.798797811999975],
                [27.260456925000028, -11.798714000999894],
                [27.257411215000047, -11.799475427999937],
                [27.254270326000153, -11.798047750999899],
                [27.252366757000118, -11.797667037999929],
                [27.250177653000037, -11.795763468999837],
                [27.249130690000186, -11.794621327999835],
              ],
            ],
            [
              [
                [29.216036, -3.321529995999981],
                [29.216125219000105, -3.320891508999978],
                [29.216156524000155, -3.320667479999827],
                [29.216211385000122, -3.320243921999975],
                [29.215696133000165, -3.319519843999899],
                [29.215534000000162, -3.319292],
                [29.215391285000123, -3.318889619999879],
                [29.215246, -3.31848],
                [29.215204928000105, -3.318202858999939],
                [29.215139000000136, -3.317758],
                [29.214853257000016, -3.3174277599999],
                [29.214606000000117, -3.317142000999979],
                [29.214542586000164, -3.317068439999957],
                [29.214530989000025, -3.317054986999892],
                [29.225007986000094, -3.317397942999946],
                [29.224909001000185, -3.317674998999962],
                [29.224413, -3.318980000999829],
                [29.224599259000115, -3.319782977999921],
                [29.22463800100013, -3.31995],
                [29.225205999000082, -3.321572000999879],
                [29.225199687000099, -3.322633169999847],
                [29.225196892000099, -3.323085182999932],
                [29.224237192000089, -3.324541865999947],
                [29.217020008000191, -3.324146999999869],
                [29.217015000000117, -3.32407399899995],
                [29.216996288000189, -3.323785909999913],
                [29.21698311200015, -3.323585574999868],
                [29.216966628000023, -3.323334930999977],
                [29.216688662000138, -3.323096977999853],
                [29.216248999000072, -3.322721000999934],
                [29.216094569000063, -3.321857486999875],
                [29.216036, -3.321529995999981],
              ],
            ],
            [
              [
                [29.239753798000038, -3.27180220799994],
                [29.239317002000121, -3.27215299799991],
                [29.239138, -3.272288999999887],
                [29.238957, -3.272666],
                [29.238928000000101, -3.272726],
                [29.238920212000153, -3.272741794999888],
                [29.239002228000174, -3.273357868999881],
                [29.239020948000132, -3.273421158999952],
                [29.239035, -3.273468],
                [29.239101000000176, -3.27369],
                [29.239200922000123, -3.274028734999945],
                [29.239312353000173, -3.274211186999878],
                [29.23956108200008, -3.27461767099993],
                [29.23984080800011, -3.274997500999916],
                [29.239895001000036, -3.275071000999958],
                [29.239984356000093, -3.275179020999929],
                [29.240345001000151, -3.275615000999949],
                [29.240442932000178, -3.275773754999932],
                [29.240476537000063, -3.275828229999888],
                [29.240725, -3.27623],
                [29.241005000000143, -3.277282],
                [29.241004758000145, -3.277591327999971],
                [29.240816424000172, -3.277847118999887],
                [29.240522000000169, -3.278247],
                [29.239648000000159, -3.279072999999869],
                [29.239335888000085, -3.279367060999959],
                [29.238947852000138, -3.279578168999933],
                [29.238805599000159, -3.279655559999924],
                [29.238369373000126, -3.279892495999945],
                [29.237849441000094, -3.280051131999926],
                [29.237686000000167, -3.280101],
                [29.237028, -3.280254999999897],
                [29.23615, -3.28043],
                [29.234915968000109, -3.280675826999811],
                [29.234890001, -3.280681000999948],
                [29.233920461000082, -3.28121097199994],
                [29.23364623700013, -3.281360869999901],
                [29.233503985000084, -3.281584116999909],
                [29.233231001000036, -3.282013000999939],
                [29.233076488000165, -3.282316872999957],
                [29.23305100000016, -3.282366999999852],
                [29.232572927000149, -3.283073053999829],
                [29.232207912000035, -3.283338143999913],
                [29.231825000000185, -3.283539],
                [29.231554000000131, -3.283681],
                [29.230473, -3.28411599999987],
                [29.229776, -3.284387],
                [29.229652000000158, -3.284435],
                [29.229633331000116, -3.284441947999937],
                [29.229458306000083, -3.284613099999888],
                [29.229390000000137, -3.28468],
                [29.229300000000137, -3.284768],
                [29.229281000000185, -3.284794],
                [29.228996482000071, -3.285179658999937],
                [29.228980248000141, -3.285201676999918],
                [29.228965992000042, -3.285221022999906],
                [29.228777, -3.285756],
                [29.228714000000139, -3.28717],
                [29.228689104000068, -3.287439357999972],
                [29.228642, -3.287948999999856],
                [29.228659, -3.289566],
                [29.228659, -3.289580999999885],
                [29.228689077000126, -3.289692984999931],
                [29.228771001000155, -3.289998000999901],
                [29.228873970000052, -3.29038260699997],
                [29.228955948000078, -3.290688801999977],
                [29.226630480000097, -3.290592975999971],
                [29.226448001000165, -3.290585],
                [29.226516000000174, -3.290466],
                [29.226828, -3.289916999999889],
                [29.226878000000113, -3.289551],
                [29.226934000000142, -3.289141],
                [29.226806, -3.288392],
                [29.226685000000145, -3.287679],
                [29.226990000000171, -3.287048],
                [29.226883, -3.286308],
                [29.226946, -3.286157999999887],
                [29.227207000000192, -3.285531999999876],
                [29.227323000000183, -3.28536],
                [29.227549, -3.285026],
                [29.227711000000113, -3.284214],
                [29.228428000000122, -3.283834999999897],
                [29.228773, -3.283522],
                [29.229164, -3.283168],
                [29.229871000000117, -3.28294],
                [29.23011600000018, -3.282861],
                [29.23042, -3.282699],
                [29.230995, -3.282392],
                [29.231571, -3.282013],
                [29.231944, -3.281007],
                [29.231966000000114, -3.280948],
                [29.232487, -3.280081999999879],
                [29.233189, -3.279847],
                [29.234032000000184, -3.280172],
                [29.234116, -3.280142],
                [29.234785000000102, -3.279901],
                [29.235844000000156, -3.279828999999893],
                [29.2362940000001, -3.279486],
                [29.237658000000181, -3.278457999999887],
                [29.238682000000153, -3.277303],
                [29.239077000000123, -3.276544999999885],
                [29.239057000000116, -3.27604],
                [29.239042, -3.275679],
                [29.238628000000119, -3.275011],
                [29.237787, -3.274036999999851],
                [29.236944, -3.273134],
                [29.236818, -3.272575],
                [29.236843, -3.27228799999989],
                [29.236874000000114, -3.271943],
                [29.237096000000179, -3.271584],
                [29.237411000000122, -3.271076999999877],
                [29.238256, -3.270661999999845],
                [29.239225000000147, -3.270482],
                [29.23958800000014, -3.2703],
                [29.239800000000116, -3.270193],
                [29.239979000000176, -3.26985],
                [29.240266926000174, -3.26925394899996],
                [29.241024018000132, -3.268977640999935],
                [29.241210937000062, -3.26909160699995],
                [29.24175834700003, -3.269258974999843],
                [29.241436005000025, -3.270775079999964],
                [29.240734099, -3.271255254999971],
                [29.240282058000048, -3.271500109999977],
                [29.239768982000044, -3.271790027999941],
                [29.239753798000038, -3.27180220799994],
              ],
            ],
            [
              [
                [29.247940180000057, -1.674974762999966],
                [29.248039598000105, -1.67484746599996],
                [29.248104, -1.674764999999866],
                [29.248122999000145, -1.674443998999948],
                [29.248163, -1.673826],
                [29.248183999000048, -1.673497],
                [29.248236076000182, -1.673312728999974],
                [29.248378754999976, -1.672806977999926],
                [29.248417021000137, -1.672806270999956],
                [29.248901000000103, -1.672797000999935],
                [29.24893, -1.67279900099993],
                [29.249372, -1.672831],
                [29.249401, -1.672837],
                [29.249905001000172, -1.672934999999882],
                [29.249994070000128, -1.67295523599995],
                [29.24948501700004, -1.675242184999888],
                [29.249339277000047, -1.675217712999938],
                [29.249041000000148, -1.675167998999882],
                [29.248550001000126, -1.675086000999954],
                [29.248380263000115, -1.675057369999877],
                [29.248300999000151, -1.675044],
                [29.248213182000086, -1.675027214999886],
                [29.247940180000057, -1.674974762999966],
              ],
            ],
            [
              [
                [29.598500049000165, -1.387612384999954],
                [29.599155427000028, -1.387575864999974],
                [29.599373012000171, -1.387563771999965],
                [29.599672, -1.387547000999973],
                [29.599758, -1.387542000999929],
                [29.600525569000069, -1.387499877999971],
                [29.600578291000147, -1.387496950999832],
                [29.60123062200006, -1.387384533999921],
                [29.601398202000041, -1.387355552999964],
                [29.601565876000052, -1.387326341999938],
                [29.601090267000075, -1.38862481599989],
                [29.600899001000073, -1.389147],
                [29.600914000000159, -1.390278],
                [29.600924999000085, -1.39115899899997],
                [29.599550100000101, -1.392230920999907],
                [29.598521578000089, -1.390685426999937],
                [29.596576999000035, -1.387762999999893],
                [29.596460966000109, -1.387587630999974],
                [29.59640114900003, -1.387496998999836],
                [29.597343001000183, -1.387497],
                [29.598267392000025, -1.38760564699993],
                [29.598381000000188, -1.387619],
                [29.598500049000165, -1.387612384999954],
              ],
            ],
            [
              [
                [29.962077333000082, 0.409763490000159],
                [29.959631942000158, 0.407469099000082],
                [29.959505033000028, 0.407350030000089],
                [29.95829025400019, 0.407121201999985],
                [29.958337895000113, 0.407157937000136],
                [29.958408001000066, 0.407212],
                [29.961413999000058, 0.409300999000152],
                [29.961723153999969, 0.409516569000061],
                [29.962077333000082, 0.409763490000159],
              ],
            ],
          ],
        },
      },
    ],
  },
  50: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [11.861804963000168, -4.829861162999919],
                [11.864305000000172, -4.832639],
                [11.865693, -4.833748999999898],
                [11.866806000000167, -4.835416999999893],
                [11.86986, -4.838193],
                [11.869862, -4.838749999999891],
                [11.871528, -4.840693999999814],
                [11.87486, -4.844028],
                [11.876804000000163, -4.846249999999884],
                [11.876804000000163, -4.847081999999887],
                [11.877916000000141, -4.848472],
                [11.878751000000136, -4.848749999999882],
                [11.880417000000136, -4.850415999999882],
                [11.881251, -4.851807],
                [11.885139, -4.855695],
                [11.887361, -4.858749],
                [11.891805, -4.863196],
                [11.892084000000182, -4.864306],
                [11.894306, -4.86875],
                [11.894584, -4.870972],
                [11.895416000000182, -4.871806],
                [11.895416000000182, -4.874027999999896],
                [11.894028000000105, -4.875693999999896],
                [11.894028000000105, -4.877082],
                [11.89514, -4.879304999999874],
                [11.895972, -4.879860999999892],
                [11.896806, -4.881804999999872],
                [11.89764, -4.882917],
                [11.898472, -4.884860999999887],
                [11.899306, -4.884860999999887],
                [11.900138, -4.886526999999887],
                [11.90125, -4.887917],
                [11.902916, -4.889305],
                [11.904028, -4.891249],
                [11.905973, -4.892084],
                [11.906807000000128, -4.893472],
                [11.906807000000128, -4.894862],
                [11.907639, -4.894862],
                [11.908195, -4.895972],
                [11.909583000000168, -4.897362],
                [11.910139000000129, -4.897362],
                [11.911527, -4.89875],
                [11.911527, -4.899306],
                [11.912917000000164, -4.900416],
                [11.916249000000164, -4.905417],
                [11.917361000000142, -4.906251],
                [11.919027000000142, -4.908195],
                [11.92125, -4.908195],
                [11.924862000000132, -4.904582],
                [11.929028000000187, -4.900972],
                [11.927916, -4.899306],
                [11.927916, -4.894306],
                [11.929584, -4.895972],
                [11.930694000000187, -4.898471999999856],
                [11.930694000000187, -4.901806],
                [11.929028000000187, -4.905139],
                [11.927082, -4.905695],
                [11.925694, -4.905695],
                [11.925138, -4.907083],
                [11.921806, -4.908750999999825],
                [11.92097200000012, -4.910139],
                [11.921528000000137, -4.911250999999879],
                [11.922362, -4.911250999999879],
                [11.922916, -4.912361],
                [11.925138, -4.915138999999897],
                [11.92625, -4.915693],
                [11.927082, -4.917082999999877],
                [11.929028000000187, -4.919028],
                [11.92930600000011, -4.92014],
                [11.93097200000011, -4.922362],
                [11.932360000000187, -4.923472],
                [11.932363, -4.925138],
                [11.933751, -4.926806],
                [11.935139000000106, -4.926806],
                [11.935139000000106, -4.927915999999868],
                [11.937917, -4.930416],
                [11.937917, -4.930973],
                [11.940139000000102, -4.932916999999861],
                [11.940695, -4.934305],
                [11.941527000000178, -4.934861],
                [11.941527000000178, -4.935973],
                [11.943195000000173, -4.937083],
                [11.943471000000102, -4.938195],
                [11.945418, -4.939583],
                [11.946806000000151, -4.942083],
                [11.950140000000147, -4.945415],
                [11.951806000000147, -4.947361999999885],
                [11.952084, -4.94875],
                [11.955416, -4.952084],
                [11.956250000000125, -4.95402799999988],
                [11.957638, -4.954859999999883],
                [11.958472000000143, -4.956249999999898],
                [11.960973000000138, -4.959028999999873],
                [11.96208300000012, -4.959860999999876],
                [11.962085000000116, -4.960694999999873],
                [11.962917, -4.962083],
                [11.965139, -4.964305],
                [11.967083000000116, -4.967083],
                [11.967639, -4.967360999999869],
                [11.968473000000188, -4.969583],
                [11.969305, -4.969860999999867],
                [11.970417000000111, -4.971249],
                [11.971249, -4.973472],
                [11.972640000000183, -4.974862],
                [11.972640000000183, -4.975694],
                [11.974028, -4.976528],
                [11.974306000000183, -4.977638],
                [11.975694, -4.979028],
                [11.975972000000183, -4.980416],
                [11.977084000000161, -4.981805999999835],
                [11.977084000000161, -4.982916],
                [11.978472, -4.98486],
                [11.979306000000179, -4.98486],
                [11.981528, -4.987361],
                [11.981806, -4.988751],
                [11.982640000000174, -4.990417],
                [11.984582, -4.992361],
                [11.985138, -4.994027],
                [11.986251000000152, -4.994861],
                [11.986526, -4.995970999999884],
                [11.987639, -4.996527],
                [11.988751, -4.997918],
                [11.990139000000113, -5.001805999999874],
                [11.992083, -5.00375],
                [11.992917000000148, -5.005137999999874],
                [11.992917000000148, -5.005971999999872],
                [11.99402700000013, -5.007637999999872],
                [11.995695000000126, -5.009306],
                [11.995973, -5.010416],
                [11.998471000000109, -5.014583],
                [11.999862000000121, -5.016250999999841],
                [12.000418, -5.016250999999841],
                [12.001806, -5.019027],
                [12.002916, -5.019861],
                [12.003196000000116, -5.020973],
                [12.004862000000116, -5.023748999999896],
                [12.005972, -5.024582999999893],
                [12.006806, -5.026528],
                [12.008194000000117, -5.02764],
                [12.009028000000171, -5.029862],
                [12.009860000000117, -5.030663999999888],
                [12.047459000000117, -4.999998],
                [12.066187075000073, -4.984729103999939],
                [12.125337, -4.936504],
                [12.163148, -4.907920999999874],
                [12.173229000000106, -4.898013999999876],
                [12.179617, -4.877757],
                [12.18710517900007, -4.845418929999937],
                [12.194844142000136, -4.811994954999875],
                [12.20216, -4.780398999999875],
                [12.210160256000165, -4.767732143999979],
                [12.213150024000072, -4.769566057999896],
                [12.243970871000158, -4.808638095999925],
                [12.253846169000099, -4.817364214999941],
                [12.290783882000028, -4.815695761999905],
                [12.30156040200012, -4.813611028999901],
                [12.323779106000188, -4.794707775999939],
                [12.35126113900003, -4.766590117999954],
                [12.35858154400006, -4.757144927999889],
                [12.381694794000055, -4.721255778999932],
                [12.413470267999969, -4.671916961999955],
                [12.419400215999985, -4.659711837999964],
                [12.442951202000131, -4.620787143999962],
                [12.454258920000143, -4.603983878999884],
                [12.457808494, -4.599683045999939],
                [12.462395669000045, -4.596130847999916],
                [12.505045890000133, -4.589149950999911],
                [12.553646087000061, -4.57709312399993],
                [12.564431190000107, -4.576151846999949],
                [12.565814555000088, -4.57515488699994],
                [12.60027027200016, -4.567446231999952],
                [12.616116524000176, -4.564342020999959],
                [12.621034622000138, -4.557864189999918],
                [12.620687485000076, -4.556138992999877],
                [12.623529434000147, -4.548643112999969],
                [12.629248620000112, -4.538289069999962],
                [12.646397591000152, -4.515474795999921],
                [12.658267022000189, -4.491751193999903],
                [12.662843704000124, -4.487370966999947],
                [12.685997964000137, -4.483181952999928],
                [12.703428268000096, -4.483605860999887],
                [12.736162010999976, -4.490350681999928],
                [12.755499841000074, -4.488098143999878],
                [12.760083198000132, -4.486017225999945],
                [12.753868102000126, -4.47705507299986],
                [12.745828628000027, -4.471549032999917],
                [12.737548827000182, -4.462133883999911],
                [12.735439301000042, -4.45778989799993],
                [12.725980282000023, -4.437558888999888],
                [12.723011016000157, -4.426958083999921],
                [12.723678589000087, -4.417292117999978],
                [12.727991104000125, -4.399795055999903],
                [12.734380721000036, -4.385973929999977],
                [12.743300439000166, -4.377670763999959],
                [12.751319885000044, -4.372591017999923],
                [12.765771865000147, -4.374630928999977],
                [12.779208183000037, -4.382354973999952],
                [12.785923004000097, -4.388937949999956],
                [12.813120841000057, -4.41940021399995],
                [12.867001533000064, -4.41490602399989],
                [12.893887520000021, -4.40979909899994],
                [12.90299034200018, -4.40930080399994],
                [12.90612792900015, -4.467510223999966],
                [12.950503349000087, -4.508823871999937],
                [12.963321686000086, -4.518799066999975],
                [12.98614215900011, -4.541413783999929],
                [13.00344944000011, -4.57910585299993],
                [13.013349533000053, -4.592199801999925],
                [13.052180290000138, -4.615342140999928],
                [13.061475277000113, -4.619742273999918],
                [13.09929180099999, -4.646515846999876],
                [13.103878975000157, -4.64650392599998],
                [13.10476303199999, -4.648590086999889],
                [13.106272698000055, -4.647281646999886],
                [13.109726905000116, -4.64808130299997],
                [13.113554001000068, -4.649300096999923],
                [13.117394447000038, -4.65009403199997],
                [13.125494003000142, -4.650826453999912],
                [13.130868913000029, -4.65202283799988],
                [13.13704776700007, -4.652357577999965],
                [13.144402504000084, -4.652250765999895],
                [13.149881364000123, -4.650470733999896],
                [13.156461715000034, -4.650374889999966],
                [13.162343025000098, -4.648164271999917],
                [13.175682068000015, -4.642869472999962],
                [13.18245983100013, -4.638998984999944],
                [13.188200952000045, -4.643029212999977],
                [13.192333221000126, -4.65269517899992],
                [13.200364112000159, -4.657296179999946],
                [13.206103326000175, -4.664658068999927],
                [13.209774970000012, -4.669029236999961],
                [13.222854612999981, -4.676393031999965],
                [13.228589057000022, -4.677313804999926],
                [13.232949256000097, -4.679845808999971],
                [13.24672222200013, -4.695952890999877],
                [13.256366729000092, -4.719419955999911],
                [13.264631270999985, -4.727013110999963],
                [13.265089990000035, -4.729084015999888],
                [13.258436203000088, -4.728623866999953],
                [13.255453111000179, -4.730696200999887],
                [13.253621102000011, -4.735066890999974],
                [13.254767418000029, -4.738289831999964],
                [13.25912952300007, -4.741971014999933],
                [13.26211261800006, -4.743809700999975],
                [13.265327453000054, -4.748180865999927],
                [13.268998146000115, -4.75002288699983],
                [13.273132324000017, -4.758764743999961],
                [13.281849860000136, -4.766353606999928],
                [13.284609795000165, -4.771419046999938],
                [13.289887429000032, -4.77049875199998],
                [13.294018745000074, -4.774640082999895],
                [13.298378945000024, -4.775099754999928],
                [13.30000686599999, -4.777003288999936],
                [13.302513123000153, -4.782234191999919],
                [13.30618381500011, -4.784302710999953],
                [13.310084343000085, -4.783841131999907],
                [13.313298225999972, -4.786602973999834],
                [13.316509247, -4.786373137999931],
                [13.31972026700015, -4.782919883999909],
                [13.324080467000101, -4.783609865999893],
                [13.332342148000066, -4.789130209999939],
                [13.340372085000013, -4.784757136999872],
                [13.344962120000105, -4.785216808999962],
                [13.350239755000075, -4.786826133999909],
                [13.359421730000122, -4.797406672999955],
                [13.363784790000182, -4.799019812999916],
                [13.372733116000177, -4.798556803999929],
                [13.378474235000112, -4.805919170999971],
                [13.381462098000156, -4.816503046999912],
                [13.377336502000048, -4.82340669599995],
                [13.378718377000041, -4.832610129999921],
                [13.382620812000084, -4.836289881999903],
                [13.381475449000106, -4.840201854999862],
                [13.384232520000069, -4.84733390699995],
                [13.38555145200013, -4.849303721999945],
                [13.385084153000093, -4.849823950999905],
                [13.380727768000156, -4.851215838999963],
                [13.378462792000164, -4.8606572139999],
                [13.37917232600006, -4.866867064999894],
                [13.387470245000145, -4.878577230999895],
                [13.391604423000103, -4.879944799999976],
                [13.401269913000078, -4.888659953999877],
                [13.425683975000027, -4.916427134999879],
                [13.430974006000156, -4.919864177999955],
                [13.43464374500013, -4.919621943999914],
                [13.436470986000018, -4.917086122999876],
                [13.438269615000081, -4.905576228999962],
                [13.435012817000086, -4.892011165999975],
                [13.427568436000115, -4.882459639999979],
                [13.437140465000084, -4.873972892999973],
                [13.443631171000163, -4.868031501999894],
                [13.454314232000058, -4.868954181999925],
                [13.463138580000134, -4.869418143999951],
                [13.471954346000189, -4.865307806999965],
                [13.480763436000188, -4.857080935999932],
                [13.491891860000123, -4.847483158999978],
                [13.494874955000114, -4.845077990999926],
                [13.496567727000183, -4.841898916999924],
                [13.504418372000032, -4.835124491999977],
                [13.50672340500006, -4.831027029999859],
                [13.502526283000066, -4.821417807999978],
                [13.498331069000074, -4.81226634899997],
                [13.503416062000042, -4.799461363999967],
                [13.51222324300005, -4.790318964999869],
                [13.518712997000023, -4.783462046999944],
                [13.527523995000024, -4.776606559999948],
                [13.532631874000117, -4.77660989799989],
                [13.540065765000122, -4.778445243999954],
                [13.543324470000016, -4.783022402999961],
                [13.550324441000043, -4.80178260799994],
                [13.558230401000117, -4.808192729999973],
                [13.565203668000038, -4.812314510999954],
                [13.572639466000112, -4.815521715999978],
                [13.58228015800006, -4.819411277999961],
                [13.58732318900013, -4.817768096999885],
                [13.594443321000028, -4.813752173999944],
                [13.601284981000163, -4.802456855999935],
                [13.607406616000162, -4.781040190999931],
                [13.630669593000164, -4.742310047999979],
                [13.635249138000063, -4.739533900999959],
                [13.649704933000066, -4.740636824999911],
                [13.65822410700008, -4.749123096999881],
                [13.663448334000066, -4.751285075999874],
                [13.67318725600012, -4.762649058999955],
                [13.678467750000038, -4.76401376799987],
                [13.685597420000022, -4.768590926999934],
                [13.69018268700006, -4.768346785999938],
                [13.690876007000043, -4.767268179999917],
                [13.692352296000081, -4.759341715999938],
                [13.692333222000116, -4.748362063999878],
                [13.689532279000048, -4.740125655999918],
                [13.678362846000027, -4.726850032999892],
                [13.680671691000043, -4.719532012999935],
                [13.689030648000028, -4.719080446999897],
                [13.701109886000097, -4.722291944999881],
                [13.709938050000119, -4.724585532999925],
                [13.716904639000177, -4.725048063999907],
                [13.722468377000098, -4.72047710499993],
                [13.726271630000099, -4.711245059999953],
                [13.729199410000035, -4.70908308099996],
                [13.731471062000082, -4.703322885999967],
                [13.73213005000008, -4.695498941999972],
                [13.730808258000138, -4.68582391599989],
                [13.723323822000111, -4.67884397399996],
                [13.713220596000099, -4.673350333999906],
                [13.711387635000051, -4.669106959999965],
                [13.711581229999979, -4.658752917999948],
                [13.710685729999966, -4.655508517999976],
                [13.716308594000168, -4.650929450999911],
                [13.71814918500013, -4.6417799],
                [13.721380234000037, -4.630343436999965],
                [13.730151177000039, -4.600607870999909],
                [13.735427857000161, -4.593094825999913],
                [13.748176574000126, -4.567282198999976],
                [13.7502040870001, -4.55783891599998],
                [13.748344422000116, -4.550251960999958],
                [13.750593185000128, -4.53828000999988],
                [13.750109672000178, -4.532069205999903],
                [13.746183396000049, -4.524027824999905],
                [13.738581657000168, -4.515538214999936],
                [13.730469703999972, -4.4938941],
                [13.730873107999969, -4.484374045999971],
                [13.730381966000039, -4.469271183999922],
                [13.735934258000043, -4.457833289999883],
                [13.74845981499999, -4.450061797999922],
                [13.755996703000108, -4.445833205999975],
                [13.762398721000125, -4.444837093999979],
                [13.763311386000055, -4.442283153999881],
                [13.768342972000141, -4.43881416399995],
                [13.781594276000021, -4.425193785999966],
                [13.80772686000006, -4.422111988999973],
                [13.8191947950001, -4.42253398899993],
                [13.828845023999975, -4.427792071999932],
                [13.852773666000076, -4.448187827999959],
                [13.85508918700009, -4.453932761999909],
                [13.86729431100008, -4.46723699599994],
                [13.870319366000047, -4.478040216999943],
                [13.869400025000061, -4.478044031999957],
                [13.876803399000153, -4.494585989999962],
                [13.883481025000037, -4.50123500899997],
                [13.89566040000011, -4.508094787999937],
                [13.919289590000119, -4.509622096999976],
                [13.944095612000012, -4.518506050999918],
                [13.959690093000063, -4.518220901999939],
                [13.969066620000035, -4.511282919999928],
                [14.001027108000187, -4.473892212999886],
                [14.017609597000103, -4.434020996999891],
                [14.020450593000135, -4.430829046999975],
                [14.029375076, -4.426923273999819],
                [14.033081054999968, -4.42584323899996],
                [14.036613465000016, -4.426358222999966],
                [14.040645599000072, -4.42363834299988],
                [14.053511620000108, -4.420074938999903],
                [14.082293511000046, -4.414603709999938],
                [14.101334571000109, -4.41507482399993],
                [14.114803314000085, -4.415542600999913],
                [14.128270150000048, -4.415552138999885],
                [14.144054413000163, -4.412359713999877],
                [14.15565681400011, -4.408706665999887],
                [14.171432495000033, -4.400937078999903],
                [14.176611900000012, -4.39970779399988],
                [14.178458214000045, -4.399584770999923],
                [14.179905892000079, -4.39892625799996],
                [14.184894561000135, -4.397742746999938],
                [14.199278832, -4.390887737999947],
                [14.2067003250001, -4.386315821999915],
                [14.217370033000066, -4.379915713999878],
                [14.230825424000045, -4.373059272999967],
                [14.238712311000029, -4.368487833999893],
                [14.251702309000166, -4.36117362899995],
                [14.262834548000114, -4.353857992999906],
                [14.277213097000072, -4.34379815899996],
                [14.28602504700018, -4.337396143999968],
                [14.301337242000102, -4.330083368999965],
                [14.31383228300001, -4.317401409999945],
                [14.320583344000056, -4.314119338999888],
                [14.321498870000084, -4.314331053999979],
                [14.339978218, -4.312652109999817],
                [14.358326913000042, -4.314186095999901],
                [14.361747741000102, -4.310030936999908],
                [14.362246514000049, -4.302335738999943],
                [14.370016098000065, -4.301752090999969],
                [14.381699563000154, -4.298126220999905],
                [14.386783599000182, -4.299341200999891],
                [14.40113449100005, -4.304062841999951],
                [14.408434868000029, -4.307663440999875],
                [14.40716361900013, -4.313525199999845],
                [14.403081893000092, -4.323206901999868],
                [14.39577961000009, -4.331291197999974],
                [14.386633872000118, -4.337312219999944],
                [14.387339592000046, -4.341220855999893],
                [14.417105675000073, -4.382516860999942],
                [14.4208116530001, -4.390554904999931],
                [14.430981635000137, -4.40300273899993],
                [14.431962013000089, -4.403871536999873],
                [14.438798904000066, -4.412569046999977],
                [14.458127975000025, -4.427676199999951],
                [14.480509758000153, -4.436332224999831],
                [14.469551087000184, -4.446013450999885],
                [14.46613693200004, -4.451094149999904],
                [14.462950706000186, -4.463754177999874],
                [14.459979057000055, -4.469972133999931],
                [14.447383881000121, -4.481035231999954],
                [14.442570686000067, -4.482882976999974],
                [14.438459396000042, -4.492322920999868],
                [14.432956695000144, -4.493250846999899],
                [14.435261725999965, -4.498771190999946],
                [14.431859015999976, -4.520176886999934],
                [14.428410530000122, -4.515810011999974],
                [14.425888061000023, -4.516042232999894],
                [14.423819542000047, -4.51397323599997],
                [14.421297074000051, -4.514210223999839],
                [14.413968086000125, -4.519511222999938],
                [14.411879540000143, -4.513788221999903],
                [14.406012535000059, -4.516944884999873],
                [14.401415825000072, -4.514894008999931],
                [14.381735802000037, -4.52486896399995],
                [14.358882904000097, -4.543601036999974],
                [14.358208657000034, -4.546596049999891],
                [14.350681304999966, -4.556062220999934],
                [14.349337577000028, -4.564120768999942],
                [14.35236930900004, -4.574921131999872],
                [14.35722160400013, -4.5829548829999],
                [14.381592751000028, -4.595971107999958],
                [14.378009796000129, -4.615544795999881],
                [14.381068229000107, -4.632557867999879],
                [14.385449409000103, -4.637832164999907],
                [14.386097907000078, -4.637808798999856],
                [14.385013581000067, -4.640430926999898],
                [14.38038635200013, -4.64957857],
                [14.38040256500011, -4.658729553999876],
                [14.381338119000077, -4.662847994999936],
                [14.38692379000014, -4.670172214999923],
                [14.395295143000055, -4.6770415299999],
                [14.40227317800003, -4.683909415999949],
                [14.405995369000095, -4.688029764999897],
                [14.40832710300009, -4.693063735999942],
                [14.408805846000178, -4.701299666999887],
                [14.408817291000162, -4.707704543999967],
                [14.409762383000043, -4.716855524999915],
                [14.410960197000179, -4.720878123999967],
                [14.40969181000014, -4.724018095999952],
                [14.407815932000176, -4.766131876999964],
                [14.40898323200014, -4.77050113699994],
                [14.415903091000075, -4.778522967999891],
                [14.416610716999969, -4.783123015999934],
                [14.40565586100007, -4.795594214999937],
                [14.401116371000171, -4.806427001999964],
                [14.40397358000007, -4.830113887999971],
                [14.402963637000084, -4.860948084999905],
                [14.409219743000051, -4.874497889999873],
                [14.412021637000066, -4.893554687999938],
                [14.412639618000128, -4.894320010999934],
                [14.416206360000047, -4.897478103999958],
                [14.426987647999965, -4.895585535999942],
                [14.429117203000033, -4.8943562499999],
                [14.430020333000186, -4.896379947999947],
                [14.435509682000088, -4.885789870999929],
                [14.445819855000025, -4.879789827999957],
                [14.449959755000066, -4.880710125999883],
                [14.45456028000018, -4.876389980999932],
                [14.456494331000044, -4.875911235999922],
                [14.45767879400006, -4.874868869999943],
                [14.460499762999973, -4.873559950999891],
                [14.471039772000154, -4.862969874999976],
                [14.477629661000151, -4.852989673999844],
                [14.48276329099997, -4.849799154999914],
                [14.489867210000057, -4.848157882999828],
                [14.496992111000111, -4.850659847999964],
                [14.508497238000189, -4.857972144999962],
                [14.528252601000077, -4.862489221999965],
                [14.542704583000159, -4.862196920999963],
                [14.557635307000055, -4.865354060999948],
                [14.576306343, -4.88344812399987],
                [14.5867080700001, -4.899738787999866],
                [14.592001914999969, -4.903397083999948],
                [14.611986160000129, -4.907450198999925],
                [14.628550530000041, -4.916580198999952],
                [14.646249770000054, -4.923175810999965],
                [14.687930108000103, -4.917560099999946],
                [14.693710327000076, -4.914389608999954],
                [14.72523784599997, -4.88646602599988],
                [14.739412308000055, -4.875816821999933],
                [14.761617661000116, -4.865132808999931],
                [14.765091896000058, -4.862318991999928],
                [14.77177333899999, -4.858158111999899],
                [14.800744057000031, -4.842863082999941],
                [14.812905312000055, -4.843037127999878],
                [14.826639175000139, -4.836298941999871],
                [14.84379005400018, -4.824483870999927],
                [14.849720002000026, -4.817783831999918],
                [14.855180740000094, -4.80878591599992],
                [14.856213569000033, -4.803397655999959],
                [14.868539811000119, -4.787469863999945],
                [14.870590210000046, -4.783790111999963],
                [14.871973037000146, -4.771837711999922],
                [14.875559808000162, -4.755770205999909],
                [14.882149696000056, -4.74261999],
                [14.896494866000012, -4.720924853999918],
                [14.903570175000027, -4.698849676999942],
                [14.90520954200008, -4.695590018999894],
                [14.913061142000117, -4.681092738999951],
                [14.925131798000166, -4.66855716699996],
                [14.942491532000133, -4.654205798999954],
                [14.980515481000054, -4.618529319999936],
                [14.98383998900016, -4.615128040999935],
                [14.992347718000019, -4.605202673999941],
                [14.99554157200015, -4.603437422999889],
                [14.997580528000128, -4.60092592299992],
                [15.00209045400004, -4.59421920799997],
                [15.005356788000142, -4.590031622999959],
                [15.009037017000082, -4.585004806999962],
                [15.013971329000128, -4.577035903999899],
                [15.02397823300015, -4.563908100999925],
                [15.031901359000074, -4.55597686699997],
                [15.032128334000106, -4.55560874899993],
                [15.04102134600015, -4.545188903999929],
                [15.052460670000187, -4.539998054999956],
                [15.061021806000099, -4.533096789999945],
                [15.062823295000044, -4.529299735999928],
                [15.065291406000085, -4.527033327999959],
                [15.071377754000082, -4.522657870999979],
                [15.072302819000129, -4.52072858799994],
                [15.07565689099999, -4.517277240999931],
                [15.08268737800006, -4.513850212999955],
                [15.084762573000035, -4.512176988999897],
                [15.08723449700011, -4.510506628999906],
                [15.091970444000026, -4.50567483899988],
                [15.095018386000163, -4.500930784999923],
                [15.09816932699999, -4.496783255999958],
                [15.100050927000098, -4.495903013999964],
                [15.10479545700008, -4.494965552999929],
                [15.104739189000043, -4.487044333999904],
                [15.107993127000157, -4.48444938699987],
                [15.107814789000145, -4.482570646999875],
                [15.108304024, -4.481317995999973],
                [15.112266540000121, -4.478771685999959],
                [15.113340377999975, -4.478675841999916],
                [15.115009308000083, -4.477502346999927],
                [15.115247727000167, -4.476569175999885],
                [15.11407852200017, -4.473840235999944],
                [15.115342141000156, -4.472930429999906],
                [15.115413665000062, -4.47142267199996],
                [15.11677265100002, -4.468693733999942],
                [15.119062423000116, -4.467998981999926],
                [15.120850562000044, -4.465150355999924],
                [15.122471810000036, -4.464408397999932],
                [15.122686386000055, -4.463690279999923],
                [15.124284745000068, -4.462708473999953],
                [15.123234749, -4.462086199999874],
                [15.12381267600017, -4.460152625999967],
                [15.124832153000057, -4.458782671999927],
                [15.126214981000032, -4.457921026999941],
                [15.125165939000112, -4.457130909999876],
                [15.124926568000149, -4.454928873999904],
                [15.125975609000136, -4.452654837999887],
                [15.126881599000171, -4.451457976999961],
                [15.127930641000091, -4.447819232999905],
                [15.129003524000098, -4.445305822999956],
                [15.130697251000186, -4.445401191999963],
                [15.132048607000115, -4.444130897999912],
                [15.133125305000021, -4.444891928999937],
                [15.135635377000028, -4.445305822999956],
                [15.136997223000094, -4.446189401999959],
                [15.147496223000132, -4.426771164999934],
                [15.162217140000052, -4.410564422999926],
                [15.161143303000074, -4.408246516999839],
                [15.161024094000084, -4.406475065999928],
                [15.163289070000076, -4.404392240999869],
                [15.164075852000167, -4.402668474999871],
                [15.165720939000096, -4.402021884999954],
                [15.166722299000185, -4.400250434999919],
                [15.169988632000184, -4.397927760999949],
                [15.172062874, -4.397927282999888],
                [15.173995017000095, -4.396993636999923],
                [15.174184798999988, -4.394863128999873],
                [15.174613953000176, -4.394001483999887],
                [15.176330567000093, -4.392708300999914],
                [15.177188874000137, -4.391607283999974],
                [15.179303169000036, -4.390211103999889],
                [15.181405067000014, -4.378505229999973],
                [15.191350938000028, -4.352221965999945],
                [15.19430732700016, -4.34760522799985],
                [15.207555770000056, -4.338102816999935],
                [15.228821754000137, -4.327177047999896],
                [15.254473685999983, -4.321753023999918],
                [15.257900239000094, -4.318972110999937],
                [15.265201569000055, -4.316487788999837],
                [15.265614510000034, -4.314821718999895],
                [15.268471717000125, -4.312452314999973],
                [15.269882203000122, -4.31096744599995],
                [15.27284908199999, -4.306802749999974],
                [15.27428817700013, -4.305435656999919],
                [15.275787354000101, -4.303020475999915],
                [15.278175353999984, -4.30104255599997],
                [15.279514312000117, -4.30050659099993],
                [15.280945779000149, -4.298830986999974],
                [15.293946266000148, -4.286338806999936],
                [15.302176476000056, -4.281692028999942],
                [15.313165664000167, -4.278872966999927],
                [15.371925354000098, -4.289378165999949],
                [15.39720725899997, -4.299825190999968],
                [15.415301322000062, -4.296506880999971],
                [15.428569794000111, -4.291371821999974],
                [15.448913575, -4.280218124999919],
                [15.463755608000156, -4.269550799999877],
                [15.476495742000168, -4.25222301499997],
                [15.491692544000102, -4.223835944999905],
                [15.499423981, -4.212287901999844],
                [15.503940582999974, -4.200985906999961],
                [15.505717277000088, -4.190851211999927],
                [15.50541591700005, -4.178196906999972],
                [15.500499725000054, -4.160966871999904],
                [15.499973297, -4.149233816999981],
                [15.50648975300004, -4.12641477499983],
                [15.514397621000114, -4.106582163999974],
                [15.533921241000087, -4.07356595899995],
                [15.542112350000082, -4.063164233999942],
                [15.550005912000188, -4.056563376999918],
                [15.55081176699997, -4.054310321999935],
                [15.551955223000107, -4.052413939999894],
                [15.553556443000048, -4.051435946999959],
                [15.556356429000118, -4.04815959799987],
                [15.559445381000046, -4.046951293999825],
                [15.56103897100013, -4.047336101999974],
                [15.56150055, -4.046949863999885],
                [15.581847191000122, -4.037631034999947],
                [15.589779853000152, -4.034624575999885],
                [15.591698646000168, -4.032596110999918],
                [15.594728470000064, -4.030353545999901],
                [15.598274231000062, -4.029661653999938],
                [15.604221345000099, -4.02718687],
                [15.60628032600016, -4.026725768999938],
                [15.607539178000081, -4.02695464999988],
                [15.609997750000105, -4.025574206999977],
                [15.612457276000043, -4.025055407999957],
                [15.613716125000167, -4.025456428999974],
                [15.614745140000139, -4.024766444999841],
                [15.616908074000037, -4.02434158199992],
                [15.64493942300004, -4.013713836999898],
                [15.646254539000154, -4.013712881999823],
                [15.660304070000109, -4.009572982999941],
                [15.669928551000169, -4.009057997999946],
                [15.679062844000043, -4.003713130999927],
                [15.713845254000034, -3.994079111999952],
                [15.7701969150001, -3.988230942999962],
                [15.800871850000021, -3.981837987999938],
                [15.831577301000152, -3.97834420199996],
                [15.834007263000103, -3.976808309999967],
                [15.837437630000181, -3.975512265999953],
                [15.839954376000037, -3.975050209999893],
                [15.842985153000086, -3.97475981599996],
                [15.844585418000065, -3.973437071999911],
                [15.851788521000174, -3.969983099999865],
                [15.853332520000095, -3.970096349999892],
                [15.85579109300005, -3.968485594999947],
                [15.859221459000025, -3.96704602199992],
                [15.861107827000126, -3.966584442999931],
                [15.862080573000128, -3.967186688999959],
                [15.867685317999985, -3.966204165999898],
                [15.869286538000154, -3.966489790999901],
                [15.87111663900015, -3.966028211999912],
                [15.871973991, -3.96539545],
                [15.872429847999967, -3.964246272999901],
                [15.874773980000157, -3.963324545999967],
                [15.877990723000096, -3.965342282999927],
                [15.880552291000129, -3.964468001999876],
                [15.89286708800006, -3.958508966999943],
                [15.900524139000083, -3.951448678999952],
                [15.90214443299999, -3.948636291999947],
                [15.90192794800015, -3.947036026999967],
                [15.904930116, -3.941108464999957],
                [15.905913352000027, -3.937857626999914],
                [15.908486367000023, -3.936878442999898],
                [15.910429954000165, -3.93584227499997],
                [15.91117096, -3.934233187999894],
                [15.90996742200008, -3.931592225999964],
                [15.909965514000078, -3.930072784999936],
                [15.91042137100004, -3.928145169999937],
                [15.91133499100016, -3.927110432999939],
                [15.913793564000116, -3.926303385999972],
                [15.914764404000152, -3.925038813999947],
                [15.917447090000053, -3.920383215999948],
                [15.917960166000057, -3.919061420999924],
                [15.917730331000143, -3.918027639999934],
                [15.919385911000177, -3.915268658999878],
                [15.919670104000147, -3.913200616999859],
                [15.921268464000036, -3.910326718999897],
                [15.920981408000102, -3.909350633999964],
                [15.922007560999987, -3.906190395999886],
                [15.922003746000144, -3.903433083999914],
                [15.922343254000168, -3.899584293999965],
                [15.92344379299999, -3.894918441999948],
                [15.92358398500005, -3.893727301999945],
                [15.925696374000154, -3.889131545999931],
                [15.92611789599999, -3.886919259999957],
                [15.926283836000096, -3.883828400999903],
                [15.929331779000108, -3.878322839999839],
                [15.93037033000013, -3.872910022999974],
                [15.943690300000185, -3.841069936999929],
                [15.957521438000072, -3.81728911499988],
                [15.981739998000023, -3.767889974999889],
                [16.001016616000186, -3.738552092999953],
                [16.023571015000186, -3.682260036999935],
                [16.037174225000058, -3.660320043999946],
                [16.046844483000086, -3.632186888999854],
                [16.058149338000135, -3.608870028999888],
                [16.086580277000166, -3.576479910999922],
                [16.110155105000104, -3.538366079999946],
                [16.11289024300015, -3.536280154999929],
                [16.12527847299998, -3.50421500199991],
                [16.132429124000055, -3.476785898999879],
                [16.144880295000064, -3.454849958999887],
                [16.1648807520001, -3.431020021999871],
                [16.187095643000134, -3.395443914999873],
                [16.204259872000023, -3.358059882999896],
                [16.211229324000044, -3.339600085999905],
                [16.222396850000166, -3.296272039999906],
                [16.2193756100001, -3.290076969999973],
                [16.19790840200011, -3.276277065999921],
                [16.189279555000155, -3.267340181999884],
                [16.18407440100009, -3.250494957999933],
                [16.186407088000067, -3.22240710199992],
                [16.19266128600009, -3.198898076999967],
                [16.190921784000068, -3.178662298999882],
                [16.18034934900004, -3.137538909999933],
                [16.178283692000093, -3.1021189679999],
                [16.182420731000036, -3.069648027999904],
                [16.195339204000163, -3.015954016999899],
                [16.19454765200004, -2.99985408799995],
                [16.190505982000104, -2.977332114999967],
                [16.18235969600005, -2.957134008999958],
                [16.179901123000093, -2.934305905999963],
                [16.179000854000094, -2.927999972999942],
                [16.179309845000034, -2.92151164899991],
                [16.180213928000114, -2.875008104999949],
                [16.185112, -2.852663992999851],
                [16.19499969600014, -2.822999952999908],
                [16.19563293500005, -2.821352480999849],
                [16.200386048000098, -2.810457944999939],
                [16.20439147900015, -2.792947052999978],
                [16.205472945999986, -2.783736942999951],
                [16.202705384000183, -2.746254921999935],
                [16.206695556000113, -2.726442098999939],
                [16.227687835000154, -2.682826041999874],
                [16.23285675, -2.668759107999961],
                [16.235748290000174, -2.656546115999902],
                [16.237369536000188, -2.624788044999889],
                [16.2349357600001, -2.603177069999958],
                [16.217613220000146, -2.544624090999946],
                [16.21589088500002, -2.540729998999893],
                [16.21400070100009, -2.536000011999931],
                [16.211580277000053, -2.526804923999976],
                [16.209190368000122, -2.516613005999886],
                [16.19416809200004, -2.447127102999957],
                [16.18912696800004, -2.423801898999955],
                [16.18427848800019, -2.382886886999927],
                [16.18523788500005, -2.319850920999954],
                [16.189729691000082, -2.272898912999949],
                [16.19146919300016, -2.225044010999909],
                [16.197692872, -2.206928729999845],
                [16.201000214000146, -2.197999953999954],
                [16.206567765000102, -2.187262772999929],
                [16.233959199000026, -2.137296438999954],
                [16.242000580000024, -2.122999906999951],
                [16.267803192000088, -2.10713982599998],
                [16.28602409400014, -2.093892097999913],
                [16.290290833000086, -2.091463088999944],
                [16.32352447600016, -2.068938970999966],
                [16.3592205060001, -2.038343906999899],
                [16.405548097000178, -1.992040991999943],
                [16.426162720000093, -1.968584417999978],
                [16.437000275000173, -1.955999970999869],
                [16.47900009100016, -1.906000017999929],
                [16.48112106300016, -1.903643249999959],
                [16.487878799999976, -1.89558804],
                [16.504152297000076, -1.873969674999955],
                [16.538002014000142, -1.829001544999926],
                [16.552000047000035, -1.804000018999943],
                [16.553998948000128, -1.801000117999877],
                [16.575149537000186, -1.7671619649999],
                [16.58900833100006, -1.745197057999917],
                [16.614866258000177, -1.694398997999826],
                [16.647974014000056, -1.629353046999881],
                [16.655952453000168, -1.613667964999934],
                [16.67161178700013, -1.581593036999891],
                [16.71979713500002, -1.48286414099988],
                [16.726217270000177, -1.469710945999964],
                [16.741487504000077, -1.433048963999909],
                [16.76134681700006, -1.398882029999925],
                [16.79168319600018, -1.338966726999956],
                [16.795999528000095, -1.33099996899989],
                [16.81599998500019, -1.30100011799982],
                [16.822103501000186, -1.293494818999932],
                [16.846000671000184, -1.263999938999973],
                [16.857000351000067, -1.253999947999887],
                [16.881999970000095, -1.23099994599994],
                [16.896999359000063, -1.214000105999901],
                [16.92543029800015, -1.189877033999949],
                [16.93020248400012, -1.185958027999959],
                [16.94997596800016, -1.172482013999968],
                [16.976770401000124, -1.151370048999866],
                [16.99041175800005, -1.142997026999922],
                [17.023445129000038, -1.131258963999869],
                [17.04119873000019, -1.123083948999977],
                [17.05619049100011, -1.11194097899994],
                [17.066658021000023, -1.10703599399983],
                [17.072002411000085, -1.102711438999961],
                [17.087999344000025, -1.088999986999909],
                [17.111000061000027, -1.074000000999945],
                [17.129999161000114, -1.064000009999973],
                [17.163999557000125, -1.051999925999894],
                [17.170000076, -1.050999998999942],
                [17.179122926000048, -1.048894642999926],
                [17.182691573, -1.048246026999891],
                [17.19326591399999, -1.047605156999964],
                [17.208999634000122, -1.047000050999941],
                [17.21599960399999, -1.049000144999923],
                [17.236999511000022, -1.047000050999941],
                [17.246809005000046, -1.043730615999948],
                [17.254804612000157, -1.040802000999918],
                [17.295478821000188, -1.014289020999968],
                [17.31779479900007, -1.005843042999857],
                [17.3300743100001, -0.999312996999834],
                [17.341194153000117, -0.990492998999969],
                [17.385110856000153, -0.942350982999869],
                [17.39313125700005, -0.936875998999938],
                [17.42900085500014, -0.902999995999949],
                [17.45700073200004, -0.875999927999885],
                [17.46599960400016, -0.86499994899998],
                [17.46800041200015, -0.863999901999932],
                [17.468999862000032, -0.861000120999961],
                [17.481336593000037, -0.848664103999965],
                [17.48706245300002, -0.842733978999945],
                [17.492511749000073, -0.83901202699991],
                [17.493848801000183, -0.835097014999974],
                [17.534103393, -0.787670016999925],
                [17.54354858500011, -0.770132005999926],
                [17.560295104999966, -0.752075016999868],
                [17.574512481000056, -0.731972992999943],
                [17.609579087000157, -0.693548976999921],
                [17.638034820000087, -0.656328022999958],
                [17.65492057900002, -0.625147759999948],
                [17.656923293000034, -0.622864723999896],
                [17.67300033600003, -0.604999957999951],
                [17.683649063000075, -0.595318912999971],
                [17.700065612000117, -0.567604005999954],
                [17.70965194600018, -0.540646016999915],
                [17.71869468700004, -0.503129005999938],
                [17.728221894000114, -0.4421809899999],
                [17.740392684000085, -0.39774900599997],
                [17.741849900000034, -0.38097000099998],
                [17.739620208000133, -0.333447992999879],
                [17.738599778000093, -0.326463221999973],
                [17.73699951200018, -0.319000004999964],
                [17.736274719000164, -0.313920915999972],
                [17.734607696000182, -0.307307988999924],
                [17.722011566000162, -0.275258987999962],
                [17.715263367000034, -0.232831001999955],
                [17.71401214600013, -0.216888934999929],
                [17.715999604000103, -0.199000044999877],
                [17.715000152000187, -0.189000010999962],
                [17.711999894000087, -0.156999975999952],
                [17.714000702000078, -0.128000064999981],
                [17.725000381000086, -0.09500001299989],
                [17.739000321000105, -0.068000091999863],
                [17.74699974100008, -0.054000068999926],
                [17.770999907999965, 0],
                [17.757999418999987, 0.017999965000172],
                [17.763999938000097, 0.040999980000095],
                [17.764999390000185, 0.051000075000161],
                [17.764999390000185, 0.052000012000065],
                [17.766782761, 0.06863518900002],
                [17.768253327000025, 0.079823002000126],
                [17.775184632000048, 0.10758300100008],
                [17.78259658899998, 0.130159065000043],
                [17.787000656000146, 0.142999993000046],
                [17.79999923700018, 0.17299999300019],
                [17.802000046000046, 0.177000090000092],
                [17.812999726000157, 0.196000070000082],
                [17.82099914600019, 0.215999962000069],
                [17.84300041200015, 0.243999988000041],
                [17.844999313000073, 0.248999983000147],
                [17.854999542000144, 0.260000080000168],
                [17.872999191000076, 0.273000062000051],
                [17.874284743000032, 0.273876072000064],
                [17.894643784000095, 0.288378001000069],
                [17.912891387000116, 0.305890948000069],
                [17.923000336000143, 0.31600004400002],
                [17.924919128000113, 0.319835842000145],
                [17.926021575000107, 0.322492004000139],
                [17.942062377000184, 0.342418999000188],
                [17.954000474000054, 0.365346998],
                [17.954000474000054, 0.366000027000155],
                [17.96002388100004, 0.388999493000142],
                [17.960283280000112, 0.399549990000082],
                [17.962598801000127, 0.408499987000141],
                [17.95993041900016, 0.466194004000045],
                [17.95721626200003, 0.482749997999974],
                [17.95507812400001, 0.489937992000023],
                [17.952930451000043, 0.494942994000041],
                [17.944324493000124, 0.512148022000019],
                [17.937175750000108, 0.518158020000101],
                [17.924722671999973, 0.529893457000071],
                [17.90500068700004, 0.548999965000121],
                [17.900999070000125, 0.555000067000094],
                [17.885999680000054, 0.571999968000057],
                [17.87800026000008, 0.590000094000118],
                [17.875999451000155, 0.593999982000128],
                [17.872999191000076, 0.61199998800015],
                [17.874000550000062, 0.659999967000033],
                [17.880001069000116, 0.675000012000112],
                [17.8806896210001, 0.685009301000036],
                [17.884963989000084, 0.691466630999969],
                [17.89100074700002, 0.706999959000029],
                [17.885000228000138, 0.738999903000035],
                [17.88681030300006, 0.7455133200001],
                [17.89527702400011, 0.775074005000022],
                [17.88702964800018, 0.803054990000135],
                [17.8870887760001, 0.816480995000177],
                [17.886205674000053, 0.826789976000043],
                [17.88315391600014, 0.838200987000164],
                [17.87946319600013, 0.845710993000182],
                [17.867771148000145, 0.862115979],
                [17.861181259000148, 0.871101022000119],
                [17.84753990200005, 0.911376119000181],
                [17.844999313000073, 0.919000029000188],
                [17.844999313000073, 0.927202762000036],
                [17.84511566100008, 0.936260999000183],
                [17.849565505000044, 0.959662973000036],
                [17.85216522200011, 0.977533996000147],
                [17.853153228000167, 0.9974349740001],
                [17.850116731000185, 1.012439013000119],
                [17.853580475000058, 1.0230410100001],
                [17.859508514000026, 1.029886008000119],
                [17.861127854000074, 1.044559003000131],
                [17.862098694999986, 1.047562956000093],
                [17.864000321000162, 1.051999927000111],
                [17.88100051800012, 1.078999997000153],
                [17.88311958200012, 1.084077002000015],
                [17.915153503000056, 1.114909054000066],
                [17.928594588000067, 1.135149955000145],
                [17.92966842600009, 1.138338567000176],
                [17.930000304000032, 1.139999985000145],
                [17.934335709000038, 1.148673296000027],
                [17.941770553000026, 1.164453031000164],
                [17.943052291000015, 1.16944193900008],
                [17.94499969400016, 1.185999990000084],
                [17.94497871400017, 1.187501311000062],
                [17.94972419800007, 1.205647946000056],
                [17.953483582000104, 1.222903013000178],
                [17.954883575, 1.231634974000144],
                [17.95490264900019, 1.251230955000153],
                [17.957771301000037, 1.266523957000175],
                [17.970649719000107, 1.309018015000049],
                [17.971853255000042, 1.317551017000142],
                [17.972757340000157, 1.321789026000033],
                [17.975999832000014, 1.337999941000135],
                [17.979991912000116, 1.345983745000069],
                [17.98684883100003, 1.360262036000108],
                [17.997413634999987, 1.386434555000164],
                [17.999999999000124, 1.393000008000058],
                [18.00263214000006, 1.396227002000103],
                [18.007583617000023, 1.40638494500007],
                [18.01113319300009, 1.420212983000056],
                [18.013000488000102, 1.42900002000016],
                [18.019001007000043, 1.443999888000121],
                [18.02400016900009, 1.453000069000041],
                [18.026514054000188, 1.457021475000033],
                [18.02933883700007, 1.460834027000033],
                [18.03812789800014, 1.477002024000115],
                [18.059642791999977, 1.50316],
                [18.066415786000164, 1.51402401900009],
                [18.06795883100017, 1.521394849000103],
                [18.066450118000148, 1.539785027999983],
                [18.071571350000056, 1.557762981000053],
                [18.072776794000106, 1.580085040000029],
                [18.072544098000037, 1.584560752000186],
                [18.072000503000083, 1.59000003400007],
                [18.072759629000018, 1.591898203000028],
                [18.075597764000122, 1.599887968000189],
                [18.079351425000084, 1.617398978000097],
                [18.07604599000007, 1.633474469000134],
                [18.078388215000132, 1.65419602500009],
                [18.087221146000104, 1.665076017000047],
                [18.088039397000045, 1.677963019000174],
                [18.083271026000148, 1.703920007000079],
                [18.074125290000154, 1.734444023000037],
                [18.07268715000015, 1.743175030000089],
                [18.06590843200007, 1.762905956000168],
                [18.06438255300003, 1.783597946000157],
                [18.058282853000037, 1.803796053000042],
                [18.058153152000045, 1.821048975999986],
                [18.05999755800002, 1.849130034000154],
                [18.067701339, 1.858160020000184],
                [18.067535401000157, 1.880475045000082],
                [18.07491874700014, 1.901929021000058],
                [18.07751655600009, 1.921275972999979],
                [18.080673217000026, 1.931442976000028],
                [18.091508865000037, 1.951485991000084],
                [18.092531205000114, 1.962113976000069],
                [18.091344834000097, 1.968086958000072],
                [18.086420058999977, 1.983693005000021],
                [18.085147857000095, 2.00116896600008],
                [18.084205627000074, 2.00484299600015],
                [18.067157746000078, 2.021277903999987],
                [18.065284728000165, 2.027014017000056],
                [18.07159996000007, 2.038798094000129],
                [18.07081794800007, 2.051446915000156],
                [18.07509422300012, 2.060683965000067],
                [18.078727722999986, 2.063931942000124],
                [18.08095741300008, 2.071542024000053],
                [18.088832855000135, 2.089622736000024],
                [18.090080260000093, 2.105206966000026],
                [18.09082985000009, 2.127300977000118],
                [18.09085655200016, 2.184596062000082],
                [18.091180802000167, 2.202316047000124],
                [18.091999055000088, 2.210995913000147],
                [18.094999313000187, 2.237999916000035],
                [18.099000930999978, 2.251000166000097],
                [18.103017807000185, 2.260185956000044],
                [18.10602378800013, 2.266630888000066],
                [18.116479875000095, 2.275686025000084],
                [18.123546600999987, 2.279423953000105],
                [18.135818481, 2.290103913000053],
                [18.14421463000008, 2.299129963000098],
                [18.14657592800006, 2.300546408000116],
                [18.15378379800012, 2.304507971000078],
                [18.163293838000072, 2.317698001000053],
                [18.16550254700013, 2.321504355],
                [18.167999267000084, 2.325000048000163],
                [18.180999756000062, 2.338999987000079],
                [18.18499946600008, 2.345000028000129],
                [18.19000053500008, 2.358999969000024],
                [18.19000053500008, 2.36999988700012],
                [18.19300079300018, 2.375999928000169],
                [18.20299911400008, 2.384000063000144],
                [18.207000732000097, 2.389999867000086],
                [18.211999893000097, 2.399000168000043],
                [18.214000702000135, 2.417000055000131],
                [18.2269992840001, 2.440000057000134],
                [18.229000092000092, 2.452000142000145],
                [18.228418350000084, 2.458969356000182],
                [18.228107453, 2.463860988000079],
                [18.232141495, 2.475398063000114],
                [18.23448944000006, 2.48503232000013],
                [18.236991882000154, 2.495002509000074],
                [18.24200057900009, 2.506999969000106],
                [18.266000749000057, 2.535000086000025],
                [18.26799965000015, 2.537999869000146],
                [18.276945114000114, 2.544547081000076],
                [18.295194627000058, 2.552510024000185],
                [18.31243324200011, 2.573580981000021],
                [18.321311951000098, 2.579862118000051],
                [18.32997512900016, 2.584527017000085],
                [18.33879661500015, 2.59886288600012],
                [18.34032058700012, 2.606590033000032],
                [18.343000412000038, 2.621999980000112],
                [18.353000641000108, 2.638999939000087],
                [18.35499954200003, 2.644999981000183],
                [18.360000611000032, 2.651999951000107],
                [18.384000778000143, 2.698999881000077],
                [18.39399909900004, 2.710000039000079],
                [18.397008895000056, 2.71799898099999],
                [18.39800071700006, 2.724000215000046],
                [18.401000977000137, 2.73300004000015],
                [18.405235291000167, 2.735964776000174],
                [18.41125488300014, 2.739794014999973],
                [18.414810182000053, 2.744573354000124],
                [18.420999527000106, 2.752000094000152],
                [18.429136276000065, 2.768233060000171],
                [18.4329586020001, 2.777928115000066],
                [18.43630218400017, 2.790611029000161],
                [18.438259125000172, 2.804665090000071],
                [18.44546127300015, 2.822442055000067],
                [18.445817947000137, 2.836714029000177],
                [18.43927383300013, 2.855539084000156],
                [18.438468933000138, 2.87233710300012],
                [18.438919067000086, 2.876596928000083],
                [18.440000535000024, 2.881999969000106],
                [18.441511153000135, 2.888906955000152],
                [18.4422245020001, 2.891237974000035],
                [18.447158813000044, 2.905774118000124],
                [18.450407028000143, 2.91792345000016],
                [18.452999114000022, 2.92700004500017],
                [18.458000183000024, 2.932999849000112],
                [18.461000442, 2.940000057000077],
                [18.462999345000128, 2.941999913000132],
                [18.468000412000094, 2.957999945000097],
                [18.47500038200019, 2.970000030000051],
                [18.493999480000127, 2.986999989000026],
                [18.49699974100014, 2.990999937000083],
                [18.501546859000086, 2.993841887000031],
                [18.504903794000143, 2.996191980000049],
                [18.516916273999982, 3.014003039000158],
                [18.521642685000188, 3.025316954000061],
                [18.52482605000006, 3.040545463000171],
                [18.525999070000125, 3.047000171000093],
                [18.528999328, 3.061999798000102],
                [18.533000945000083, 3.069999933000077],
                [18.53909301800013, 3.075538398000163],
                [18.543935775000136, 3.07956910200005],
                [18.566553115000147, 3.090571641999986],
                [18.576000212999986, 3.095000030000108],
                [18.579126359000043, 3.096042394000051],
                [18.584800720000146, 3.097585916000014],
                [18.594583511000167, 3.106401921000042],
                [18.601398468000127, 3.114506006000056],
                [18.614744186000166, 3.138082028000156],
                [18.625595092000026, 3.158874035000167],
                [18.632102966000105, 3.178529262000097],
                [18.63599967900018, 3.191000223000174],
                [18.639003755000033, 3.200010300000031],
                [18.641740798000114, 3.208940029000189],
                [18.642576217000055, 3.220684052000138],
                [18.641061784000044, 3.226165534000188],
                [18.639999389000025, 3.229000092000035],
                [18.635000230000173, 3.259999991000029],
                [18.638000488000102, 3.289999962000024],
                [18.64500045799997, 3.305000068000027],
                [18.648000717000173, 3.316999913000132],
                [18.649999618000095, 3.330999851000172],
                [18.649000168000157, 3.344000101000063],
                [18.64500045799997, 3.358999968000092],
                [18.63299942100008, 3.385999918000096],
                [18.631999969000162, 3.396999836000077],
                [18.63599967900018, 3.408999921000032],
                [18.63899994000019, 3.417000056],
                [18.639999389000025, 3.427000047000092],
                [18.639999389000025, 3.443885088000172],
                [18.63986587500017, 3.448560953000026],
                [18.635099412000045, 3.471087933000035],
                [18.624130248000142, 3.471702099000027],
                [18.60372924700016, 3.472707034000109],
                [18.599599838000188, 3.473828077000064],
                [18.594999313000073, 3.477019071000143],
                [18.590112686000168, 3.487343072000158],
                [18.591030122000063, 3.487349988000062],
                [18.587972641000135, 3.498836994000101],
                [18.57538032600013, 3.530746936000185],
                [18.567754746000162, 3.540129901000171],
                [18.558300018000182, 3.549038888000041],
                [18.55132102900012, 3.563493967999989],
                [18.547548294000023, 3.578428983000038],
                [18.544219971000018, 3.596131087000117],
                [18.535640716000103, 3.61011290499999],
                [18.513999940000133, 3.624691011000039],
                [18.500133515000073, 3.64162492700018],
                [18.49089813200004, 3.651686906],
                [18.48675918600003, 3.653269053000088],
                [18.481721877000098, 3.65323209800016],
                [18.47534942600015, 3.646739006000075],
                [18.467895507000037, 3.631721973000026],
                [18.461288452000076, 3.626148939000075],
                [18.45511817800002, 3.623572111000044],
                [18.453859329000068, 3.607678891000035],
                [18.448633194000138, 3.601195097000073],
                [18.443609239000182, 3.598627090000093],
                [18.43718719500015, 3.599730016000081],
                [18.425907134, 3.607244016000038],
                [18.419706344000133, 3.609041930000103],
                [18.414014817000066, 3.603935003000061],
                [18.412933349000127, 3.594717979000052],
                [18.413446426, 3.586895942000069],
                [18.41053581200009, 3.577896118000069],
                [18.40595054700003, 3.577861071000086],
                [18.396970749000104, 3.584012985000186],
                [18.391210555999976, 3.588804007000135],
                [18.37327957100007, 3.596267939000029],
                [18.359514236000052, 3.59915995700004],
                [18.345935822000115, 3.607577086000049],
                [18.339298248000148, 3.606375933000095],
                [18.33336067300013, 3.60356998400016],
                [18.3256187450001, 3.596834898000168],
                [18.31376075700007, 3.588920117000157],
                [18.308731079000097, 3.587042094000083],
                [18.300247193000132, 3.588129043000038],
                [18.287157058000048, 3.591943979000064],
                [18.274547577000078, 3.593231917000026],
                [18.267684937000126, 3.591567994000116],
                [18.264730454000016, 3.58809208800011],
                [18.26262092500002, 3.564367056000151],
                [18.259746551000035, 3.549381016999973],
                [18.24992370600006, 3.514084100000105],
                [18.24629974300018, 3.508304119000172],
                [18.242889404000152, 3.504595996000035],
                [18.24155044600002, 3.50044107400015],
                [18.231540679000034, 3.490698099000042],
                [18.2246704100001, 3.489495038000086],
                [18.214862822999976, 3.485045910000167],
                [18.188995361000025, 3.481167078000055],
                [18.18049812200013, 3.484095097000136],
                [18.176109314000087, 3.488204955000185],
                [18.169830322000166, 3.500817061000078],
                [18.16625213700013, 3.520126104000156],
                [18.163448333000133, 3.527472020000118],
                [18.152549743000066, 3.544884921000119],
                [18.144697190000045, 3.553342105000183],
                [18.133409501000187, 3.561542989000145],
                [18.12352180500011, 3.566071034000061],
                [18.105150224000113, 3.571455001000118],
                [18.087049483000044, 3.571543933000044],
                [18.070749282000065, 3.57533192599999],
                [18.058399201000043, 3.573163986000054],
                [18.048807143999966, 3.56825494900005],
                [18.037565232000077, 3.570470095000189],
                [18.024017334, 3.574048043000119],
                [18.015060425000115, 3.577430964000143],
                [18.00909805300006, 3.577384949000134],
                [18.000001907000126, 3.578872919000162],
                [17.99872779899999, 3.553910017000078],
                [17.995740890000036, 3.551158905000023],
                [17.984981538000113, 3.553497077000031],
                [17.969430924000108, 3.560456037999984],
                [17.957550050000123, 3.57131290500007],
                [17.949340820000145, 3.583077908000178],
                [17.947990417000028, 3.58975601100002],
                [17.943878174000076, 3.599781990999986],
                [17.938640594000162, 3.601753951000092],
                [17.937719346000108, 3.602446079000174],
                [17.93267059400017, 3.599011898000128],
                [17.92989158600011, 3.589814902000171],
                [17.919300080000028, 3.57351493800013],
                [17.902301789000035, 3.55930209200011],
                [17.89037704500015, 3.557040931000131],
                [17.881416320000085, 3.549937010000065],
                [17.866701126000066, 3.533188105000079],
                [17.8591403960001, 3.532983066000099],
                [17.85369491600005, 3.548880101000123],
                [17.8479881290001, 3.556952954000053],
                [17.84823036200015, 3.559482098000046],
                [17.84548950200002, 3.563174963000165],
                [17.84462928800008, 3.578824997000083],
                [17.840303421000044, 3.587124110000104],
                [17.840133668000078, 3.605304002000139],
                [17.833318711000118, 3.623277902000098],
                [17.828977585000132, 3.626744032000033],
                [17.81660080000006, 3.62678503900014],
                [17.809507370000063, 3.62910795300013],
                [17.79575729400011, 3.629153013000121],
                [17.789590835000126, 3.631706],
                [17.787305832000186, 3.634936094000182],
                [17.75890541100017, 3.63893795100006],
                [17.74517059300007, 3.643124104000151],
                [17.744489669000075, 3.646116972000129],
                [17.738319397000055, 3.650279045000161],
                [17.7330493930001, 3.651215076000085],
                [17.720657349000078, 3.645271063000052],
                [17.7050495150001, 3.634505033000096],
                [17.700923920000093, 3.634288073000107],
                [17.694749833, 3.639141083000027],
                [17.687660218000076, 3.641463042000169],
                [17.653280257, 3.639038086000085],
                [17.606319427000074, 3.641251087000171],
                [17.597618104000162, 3.643578053000113],
                [17.59260940600018, 3.653487920000032],
                [17.587583541000072, 3.658334970000112],
                [17.57821083200008, 3.663656949000028],
                [17.5576000210001, 3.669240951],
                [17.549390794000033, 3.681000947000143],
                [17.543670653999982, 3.685851097000182],
                [17.533367158000146, 3.689104079],
                [17.506570815000146, 3.69194102199998],
                [17.491699218000065, 3.702111006],
                [17.474060059000124, 3.703082084000073],
                [17.461910247000105, 3.699204922000092],
                [17.451122284, 3.693711995000058],
                [17.441007615000046, 3.680392981000125],
                [17.405231476000154, 3.669445991000089],
                [17.394212722000077, 3.661652088000153],
                [17.388000489000035, 3.652921916000082],
                [17.365892410000015, 3.610179901000038],
                [17.36267662000006, 3.606966018000151],
                [17.354650498000126, 3.604456902000038],
                [17.348693847000163, 3.604933023000058],
                [17.31574058500013, 3.619288922000123],
                [17.30454063300016, 3.628982067000038],
                [17.298809052000138, 3.630148889000111],
                [17.257989884000096, 3.614608051000175],
                [17.25248146100006, 3.610707998000123],
                [17.24765968300005, 3.605427981000162],
                [17.24945259100008, 3.588623048000159],
                [17.24828910700012, 3.583333970000069],
                [17.239347458000054, 3.57921505000013],
                [17.223529816999985, 3.575113058],
                [17.199235916000134, 3.572181940000121],
                [17.176767348000055, 3.565565110000136],
                [17.17218780600001, 3.565344095000057],
                [17.163457870000173, 3.572552920000135],
                [17.157085418000122, 3.575105905000044],
                [17.153648377000025, 3.573674918000052],
                [17.14723014900011, 3.572772026000109],
                [17.13209343000011, 3.565442084999972],
                [17.11329269400011, 3.559274913000081],
                [17.098175047999973, 3.560693027000184],
                [17.07937240700005, 3.552911997000138],
                [17.058057785000074, 3.548130036000146],
                [17.05252075100003, 3.530422926000028],
                [17.038963318000185, 3.515034915000172],
                [17.035299301000123, 3.513432980000061],
                [17.029569626000068, 3.513675929000158],
                [17.010818482000047, 3.529138088000082],
                [17.00923919800016, 3.539036035000095],
                [17.004444123000155, 3.546642066000118],
                [16.997800827000106, 3.546888113000136],
                [16.990692139000032, 3.543910981000181],
                [16.987710952000043, 3.540925980000054],
                [16.981748582000023, 3.539098025000044],
                [16.976024627000072, 3.540493013000173],
                [16.960710526000128, 3.55824709],
                [16.95727920500019, 3.559864998000137],
                [16.94605064400008, 3.55804896300009],
                [16.932750700999975, 3.552093029000048],
                [16.910066605000168, 3.550071954000032],
                [16.896099091, 3.55194306400017],
                [16.88900947600007, 3.558402062000084],
                [16.87846946700006, 3.559576035000134],
                [16.87365913499997, 3.557516098000122],
                [16.86975288400015, 3.553379059],
                [16.86951065, 3.549007892000134],
                [16.874298095000086, 3.536341906000189],
                [16.873359681000068, 3.527137042000106],
                [16.860729217000028, 3.511516095000161],
                [16.851802825999982, 3.510616065000136],
                [16.83349037200003, 3.520318032000034],
                [16.820663452000133, 3.521493911999983],
                [16.802608490000182, 3.543391944000121],
                [16.795049668000047, 3.546169996000117],
                [16.77444267300001, 3.549662113000181],
                [16.76346015900009, 3.554976941000064],
                [16.7545318600001, 3.557066917000157],
                [16.745130539000172, 3.556164027000079],
                [16.72610282800008, 3.549067020000052],
                [16.71304321299999, 3.547940969000024],
                [16.687828064000144, 3.542464018000089],
                [16.672023774000024, 3.541802884000106],
                [16.662851334000152, 3.537679911000112],
                [16.64748954700019, 3.529192926000178],
                [16.61055183300016, 3.49727106000006],
                [16.604812622000054, 3.490149021000093],
                [16.596540451000124, 3.477276088000167],
                [16.593320846000097, 3.46669697700014],
                [16.582920074000185, 3.416088104000039],
                [16.580825805000188, 3.396989108000071],
                [16.58008956800012, 3.368916988000137],
                [16.5770702370001, 3.342689037000071],
                [16.572921752000127, 3.329579115],
                [16.559379578, 3.313952923000102],
                [16.554559707000124, 3.305677892000119],
                [16.55017280600015, 3.282675029000131],
                [16.556549073000042, 3.266324043000111],
                [16.558359145000054, 3.253436089000104],
                [16.55422973600008, 3.244927883000116],
                [16.546884537000096, 3.236886979000076],
                [16.53884887600003, 3.22447204700012],
                [16.53240585399999, 3.20584511900006],
                [16.508989333999978, 3.171135903000049],
                [16.505769730000054, 3.159868002000053],
                [16.50712013200007, 3.147667885000033],
                [16.51099586500004, 3.13592600800007],
                [16.512344361000032, 3.120507003000171],
                [16.521930695000094, 3.099550963000183],
                [16.53883552500008, 3.07950305900016],
                [16.54431343200008, 3.070288897000182],
                [16.544540405000134, 3.065686941000081],
                [16.531459809000182, 3.049602985000149],
                [16.503248215000042, 3.021347046000187],
                [16.4933795930001, 3.004334926000183],
                [16.49117279100011, 2.956832886000029],
                [16.48958587700008, 2.92288589500015],
                [16.490249633000133, 2.909308911000153],
                [16.501680374000102, 2.899167061000128],
                [16.513799667, 2.893393993000132],
                [16.520889282000155, 2.887860061000026],
                [16.524770736000107, 2.880489111000145],
                [16.52012825100013, 2.842302084000039],
                [16.500379563000024, 2.803450108000106],
                [16.49648094300005, 2.798624992000043],
                [16.49119949300001, 2.786437988000103],
                [16.475820542000065, 2.759772061999968],
                [16.474441528, 2.754255058000013],
                [16.463190078000025, 2.73264408],
                [16.460666657000047, 2.73034691800018],
                [16.435880661000056, 2.685290099000156],
                [16.424858094000115, 2.661839962000101],
                [16.417522430000133, 2.650809050000078],
                [16.404113770000038, 2.625000001000046],
                [16.349819183000022, 2.520462990000169],
                [16.342250823000143, 2.503676891000112],
                [16.33192825200007, 2.487359046000108],
                [16.29911995000009, 2.422991038000134],
                [16.297969818000126, 2.417928935000077],
                [16.290401459, 2.402527094000163],
                [16.286960601999965, 2.399080992000165],
                [16.27457428000008, 2.37402391500018],
                [16.270908355000074, 2.36988806700009],
                [16.265403747000107, 2.356782913000075],
                [16.229776383000114, 2.289272069000106],
                [16.213119507000044, 2.257705927000131],
                [16.20875930800014, 2.246668100000136],
                [16.195230484000035, 2.223453045000042],
                [16.191989899000077, 2.220515012000021],
                [16.190820695000127, 2.223486901000172],
                [16.189981001000092, 2.223100999],
                [16.19104576100011, 2.219883441999969],
                [16.19084167600016, 2.215929985000173],
                [16.190441, 2.213450999000031],
                [16.188839001000133, 2.210437001000059],
                [16.187038, 2.20816],
                [16.18483924200001, 2.205950245999986],
                [16.182504653000024, 2.202467679000051],
                [16.18003800000014, 2.199253000999988],
                [16.177839, 2.197848],
                [16.17497400000019, 2.197381001],
                [16.171976089, 2.196445227000027],
                [16.166179658000146, 2.195243358000141],
                [16.162248611000052, 2.194643497000186],
                [16.16051674, 2.193170548000069],
                [16.158916473000033, 2.191362619000131],
                [16.154115677000163, 2.185268162999989],
                [16.153112, 2.181783999000061],
                [16.15131378100017, 2.179507493000131],
                [16.148580551000123, 2.177499293999972],
                [16.145715714000175, 2.176563263000105],
                [16.142651, 2.176499],
                [16.140185999000153, 2.177238001000148],
                [16.138257999000132, 2.179384000000141],
                [16.13785934499998, 2.180924892000121],
                [16.137065888000166, 2.189301967000176],
                [16.13626861600011, 2.193189382000014],
                [16.13540458599999, 2.195200206000152],
                [16.133939742000166, 2.196541549000187],
                [16.132009506000145, 2.19714617700015],
                [16.128412248000018, 2.197751998000058],
                [16.124216079, 2.198157310000056],
                [16.117622375000167, 2.199301481000134],
                [16.11169433700013, 2.199908972000173],
                [16.105966569000145, 2.200650454000083],
                [16.099304199000073, 2.200990440000169],
                [16.093776703000174, 2.20092749700018],
                [16.08911323600006, 2.200595856000177],
                [16.087179178000042, 2.199324122000121],
                [16.085512160000178, 2.196979999000064],
                [16.083711623000113, 2.193630695000024],
                [16.083309174000135, 2.190481187000046],
                [16.083768844000133, 2.179826021000054],
                [16.082963943000095, 2.172656060000179],
                [16.08276176400011, 2.169774772000153],
                [16.08282661400017, 2.166022063000071],
                [16.08229064900013, 2.163274765000153],
                [16.081356047999975, 2.160125970000138],
                [16.080619811000076, 2.153961181000113],
                [16.080148696000094, 2.148600578000071],
                [16.079677582000045, 2.141028405000157],
                [16.079538345000174, 2.131646873000022],
                [16.079935074000048, 2.128429890000177],
                [16.082859039000084, 2.117705822000175],
                [16.08511899900003, 2.111137001000145],
                [16.086515001000066, 2.108187],
                [16.088907, 2.100210999000126],
                [16.08977100100003, 2.095721001000015],
                [16.08943557700013, 2.093040466000048],
                [16.08783499900005, 2.088752999000121],
                [16.084966660000134, 2.082656622000059],
                [16.083366, 2.079976999000166],
                [16.0807, 2.077701],
                [16.07736799900016, 2.07656399900003],
                [16.072970999000177, 2.075428000999978],
                [16.068841934000034, 2.074559213000043],
                [16.06571006700017, 2.073153972000057],
                [16.063709259000177, 2.07067585100009],
                [16.063442231000067, 2.06920170900014],
                [16.063774109000178, 2.067325354000047],
                [16.064905167000177, 2.064509869000062],
                [16.067167259000087, 2.061291729000061],
                [16.07003021200012, 2.058408261000068],
                [16.073158264000085, 2.056328774000065],
                [16.077953001000083, 2.054248],
                [16.0808161760001, 2.052838850000114],
                [16.08221400600013, 2.051631991000022],
                [16.083278656000118, 2.049754620000044],
                [16.083608628000036, 2.045666694000033],
                [16.082674000000168, 2.043389],
                [16.080738, 2.036822999000037],
                [16.079538, 2.033339000000183],
                [16.07513799900005, 2.028383001000179],
                [16.06967386000008, 2.022623879000093],
                [16.05674362200017, 2.011507751000124],
                [16.053611755000077, 2.008360148],
                [16.05307769800004, 2.005948068000123],
                [16.05300903400007, 2.002530695000075],
                [16.05353927700014, 1.999849557000118],
                [16.055133819000105, 1.994420410000032],
                [16.057395935000045, 1.988119722000079],
                [16.05779266400009, 1.98610901800015],
                [16.057857514000148, 1.982959390000133],
                [16.05725669900005, 1.980815292000045],
                [16.054658889000052, 1.97900772100013],
                [16.051660539000125, 1.976597070000082],
                [16.050792693000062, 1.974587321000115],
                [16.05119133000011, 1.972643614000162],
                [16.05249023500005, 1.970768572000111],
                [16.057594001000098, 1.967902],
                [16.059656, 1.965621999000121],
                [16.06118774500004, 1.963141799000084],
                [16.06231689600014, 1.960594534999984],
                [16.06398200899997, 1.958985210000151],
                [16.068708420000178, 1.956234575000053],
                [16.07170499900002, 1.953953999000021],
                [16.073766708000164, 1.951138259000061],
                [16.07576179600005, 1.945508003000157],
                [16.07675933800016, 1.942223669000043],
                [16.078155518000017, 1.938336015000061],
                [16.081213, 1.928818],
                [16.08367156899999, 1.9205740700001],
                [16.084664999000154, 1.912665999000126],
                [16.084663, 1.908712],
                [16.08499145600007, 1.899531246000038],
                [16.085721970000122, 1.896381141000063],
                [16.08685112000012, 1.893364907000091],
                [16.088115692000088, 1.89101863000019],
                [16.08984565800006, 1.88927519300006],
                [16.09217643700009, 1.88766539],
                [16.09537315500006, 1.887261271000057],
                [16.097970961999977, 1.887728811000045],
                [16.103500366000105, 1.890472651000039],
                [16.10649899900011, 1.89161],
                [16.108096999, 1.891743001000179],
                [16.11029400000018, 1.891004],
                [16.11135864200014, 1.888457299000095],
                [16.111354828000174, 1.884034515000053],
                [16.110687, 1.881958000000111],
                [16.109220504000177, 1.879278064000062],
                [16.107288361000087, 1.876531721000106],
                [16.106287002999977, 1.874320985000168],
                [16.106418610000162, 1.870367170000122],
                [16.108079953000015, 1.865340126000035],
                [16.109676001000082, 1.861518999000168],
                [16.11067200100007, 1.857096001000116],
                [16.110734940000043, 1.85106492],
                [16.1115322120001, 1.847579717000031],
                [16.112529754000036, 1.844630599000084],
                [16.115656, 1.838328999000055],
                [16.117851001000076, 1.834374000000139],
                [16.12137400100005, 1.825526],
                [16.122570000000167, 1.820969001000037],
                [16.12370100100003, 1.818756999000186],
                [16.125765, 1.817348001000141],
                [16.12909099900014, 1.812588001000108],
                [16.13135520500009, 1.809638605000146],
                [16.137805999000022, 1.797371],
                [16.13853800100003, 1.79469],
                [16.138668037000116, 1.790870804000065],
                [16.13959884700006, 1.788323522000155],
                [16.139465331, 1.785710215000051],
                [16.138996124000187, 1.781756760000064],
                [16.13905906700012, 1.777266979000103],
                [16.139457703000062, 1.774452210000163],
                [16.140786999000113, 1.77150299900012],
                [16.14178466700008, 1.768553734000079],
                [16.142581939000138, 1.765001655000106],
                [16.142246001000046, 1.760579000000178],
                [16.140913009000087, 1.757430315000136],
                [16.138511658000084, 1.753143072000057],
                [16.135644912000032, 1.746912717000157],
                [16.13451194900017, 1.744031907000021],
                [16.134908677000112, 1.740681172000166],
                [16.135839, 1.737263],
                [16.137166976000174, 1.729957819999981],
                [16.139226912000083, 1.724662662000128],
                [16.141157150000083, 1.72144496400017],
                [16.143751144000134, 1.717623712000147],
                [16.1460819240001, 1.714606763],
                [16.147411, 1.712194001000057],
                [16.148208618000183, 1.708909511000059],
                [16.148738861000083, 1.70542454800011],
                [16.149135589000082, 1.69925940100012],
                [16.149131774000182, 1.694233536000183],
                [16.14833068900009, 1.691151381000168],
                [16.14646500100008, 1.688405],
                [16.14146804700016, 1.684521318000066],
                [16.137672424000073, 1.683652400000085],
                [16.134876251000094, 1.684793234000154],
                [16.131547927000042, 1.688815952000141],
                [16.12695694000007, 1.694715619000021],
                [16.124229432000163, 1.697732807000079],
                [16.121831894000024, 1.698873401000128],
                [16.118701935000104, 1.69941127400017],
                [16.115173340000126, 1.699480295000058],
                [16.104382, 1.695331999000018],
                [16.09292602500011, 1.691853762000108],
                [16.085998535000158, 1.689512252000156],
                [16.083934784, 1.687905074000071],
                [16.082067489000167, 1.685359598000105],
                [16.078468323000095, 1.680000544000109],
                [16.077402115000154, 1.677119494000124],
                [16.075269700000035, 1.674239159000081],
                [16.07047081000013, 1.666736365000133],
                [16.068204879000177, 1.663721919000125],
                [16.06547164900013, 1.660372734000021],
                [16.06207275400004, 1.655348659000083],
                [16.060140633000117, 1.652267129000052],
                [16.058609009000065, 1.652804017000051],
                [16.057411193000064, 1.654345870000157],
                [16.05335044800006, 1.65722966200002],
                [16.05082130500017, 1.65810215499999],
                [16.04549407999997, 1.658440113999973],
                [16.039100647999987, 1.658108353000159],
                [16.03230900000011, 1.658178999000143],
                [16.029644012000063, 1.658582448000061],
                [16.028179169000055, 1.659655453000084],
                [16.026783, 1.661130999000136],
                [16.025053025000034, 1.663946032000126],
                [16.02192497300007, 1.670649171000036],
                [16.02052899900019, 1.674470000999975],
                [16.019665, 1.67788800000011],
                [16.019333, 1.680367999000055],
                [16.01953506400008, 1.683852197000022],
                [16.020002365000153, 1.687135578000152],
                [16.022472381000057, 1.696181297000123],
                [16.023941040000125, 1.703820110000095],
                [16.023742676000097, 1.706500768000183],
                [16.023210526000128, 1.709248662000164],
                [16.02128028800007, 1.711595177000049],
                [16.01935005200005, 1.713003636000167],
                [16.016887665000183, 1.713809013000116],
                [16.01475711400019, 1.715619893],
                [16.014492035000046, 1.717630148000183],
                [16.014493999000024, 1.720511954000017],
                [16.015627, 1.723661000000163],
                [16.01729393100004, 1.727680803000169],
                [16.018761, 1.731835],
                [16.02069500000016, 1.736726],
                [16.022228240000175, 1.739941834999968],
                [16.026960374000055, 1.746774555000116],
                [16.028959275000147, 1.749387145000071],
                [16.031358728000157, 1.753071571000078],
                [16.032159805000106, 1.755483508999987],
                [16.032028199000024, 1.758097171000088],
                [16.03162956300008, 1.760710956000082],
                [16.02989959700011, 1.763660550000054],
                [16.02710533100003, 1.766342640000119],
                [16.02517318800011, 1.766879915000061],
                [16.022310256000026, 1.767283559000077],
                [16.011054993000016, 1.766485572000022],
                [16.006193161000112, 1.766019107000091],
                [16.001464999000063, 1.765754001000175],
                [15.985147477000169, 1.765695333999986],
                [15.980619430000047, 1.766166927000029],
                [15.978754043000151, 1.766167879000136],
                [15.969762801000172, 1.765234351000174],
                [15.965767001000131, 1.765504999000143],
                [15.961438084000122, 1.766042990000187],
                [15.958841, 1.7671840000001],
                [15.947657000000106, 1.774293000000114],
                [15.9377355580001, 1.779592396],
                [15.93553829199999, 1.780263662000152],
                [15.93327426899998, 1.780465842000183],
                [15.925349235000112, 1.780335783000169],
                [15.920154572000058, 1.780673504000106],
                [15.917157174000067, 1.781144143000063],
                [15.914361000000156, 1.782418999000015],
                [15.912298203000091, 1.787580253000044],
                [15.913300513000081, 1.793678166000177],
                [15.913368110000079, 1.795822696000187],
                [15.911371122000048, 1.797096980000106],
                [15.908241271000179, 1.797433615000159],
                [15.901114301000064, 1.797437037000066],
                [15.897784233000039, 1.796701670000061],
                [15.893787384000177, 1.795296312000119],
                [15.88999, 1.79322100100012],
                [15.885391998999978, 1.787929],
                [15.88346004500005, 1.787259340000048],
                [15.882794381, 1.788130761000105],
                [15.882863999000108, 1.79329105100004],
                [15.882134438000037, 1.799322963000179],
                [15.876677999000037, 1.809847],
                [15.87588024199999, 1.812930465000022],
                [15.869693757000164, 1.829888820000122],
                [15.86789703300019, 1.831699133000029],
                [15.86543274000013, 1.832772613000032],
                [15.859039308000149, 1.833043813000131],
                [15.854310036000186, 1.833113074000039],
                [15.851712227000064, 1.83264529700017],
                [15.848382000000129, 1.832512999000073],
                [15.846251488000064, 1.833117009000148],
                [15.84312200100004, 1.834660001000088],
                [15.842190001, 1.836670999000035],
                [15.840526001000171, 1.838815999000019],
                [15.836199, 1.843711000000155],
                [15.833138001000123, 1.847464999000124],
                [15.829741434000027, 1.851085565000062],
                [15.827477330000136, 1.852158904000135],
                [15.823215484000116, 1.851892829000065],
                [15.817687, 1.852298001000179],
                [15.814823150999985, 1.853304030000174],
                [15.811494000000152, 1.855048001000171],
                [15.805901999000127, 1.859005],
                [15.796913147000112, 1.866783024000085],
                [15.793983459, 1.870135308000158],
                [15.792187001000059, 1.872549],
                [15.786464692000095, 1.887429476000023],
                [15.785067559000026, 1.889775754000027],
                [15.781937600000163, 1.892323851000072],
                [15.778475760000049, 1.894738078999978],
                [15.772882461000108, 1.899700046000135],
                [15.763895035000075, 1.910292983000034],
                [15.75716972400005, 1.914987325000141],
                [15.752841949000185, 1.917334915000026],
                [15.748046874000181, 1.919481636000114],
                [15.745781898000189, 1.919281602000069],
                [15.74305057500004, 1.918679596],
                [15.739119660000142, 1.916804853000087],
                [15.729327, 1.912787999000045],
                [15.727794999000082, 1.912788999000156],
                [15.726329804000102, 1.914062500000114],
                [15.723600389000126, 1.917816758000015],
                [15.721536637000042, 1.920900583000048],
                [15.719673102000172, 1.922777922000023],
                [15.718140603000109, 1.923649789000137],
                [15.716076851000025, 1.924320818000126],
                [15.712812423000116, 1.924724341000058],
                [15.709815, 1.92486000100007],
                [15.707351999000082, 1.925664999000048],
                [15.703955000000121, 1.927208001000167],
                [15.700559000000112, 1.92841600100013],
                [15.695696831000078, 1.930495142000098],
                [15.690169335000178, 1.933111192000013],
                [15.686506271000098, 1.93425202300017],
                [15.683241845, 1.934387445000027],
                [15.680444717000057, 1.93365132800011],
                [15.672715, 1.928092],
                [15.670251000000121, 1.927825000000155],
                [15.66732, 1.927826],
                [15.664122582, 1.92843020000015],
                [15.656331062000163, 1.931516170000066],
                [15.65406608600017, 1.932656408000071],
                [15.648938180000073, 1.934132815000112],
                [15.644275664000133, 1.93534100100004],
                [15.640345573000104, 1.936079741000071],
                [15.636815072000104, 1.936349154000084],
                [15.632752419000155, 1.936886907000087],
                [15.629422188000035, 1.937089206],
                [15.622295379000036, 1.938633323000147],
                [15.617499352000095, 1.939774394000153],
                [15.614103318000161, 1.940915108000183],
                [15.608242035000046, 1.943530918000079],
                [15.605710982000119, 1.944135071000062],
                [15.600117001000115, 1.945813000999976],
                [15.598385, 1.946952999000189],
                [15.59479000000016, 1.951175998999986],
                [15.592858315000115, 1.952048182000112],
                [15.588994981000042, 1.953390002000162],
                [15.585997581000186, 1.953525066000111],
                [15.584331513000166, 1.952922463000107],
                [15.581865999000115, 1.951516001000073],
                [15.57727, 1.949373001000026],
                [15.57440471700005, 1.948435425000071],
                [15.568543433, 1.947901250000029],
                [15.564214, 1.948171001000048],
                [15.562148999000101, 1.948909000000128],
                [15.556354999000177, 1.952396000000135],
                [15.553824425000016, 1.955211640000073],
                [15.551759719000074, 1.958027125000058],
                [15.54976272500005, 1.96010553900004],
                [15.544967716000087, 1.963123046000078],
                [15.54256916100013, 1.963190914000165],
                [15.53977108000015, 1.962253452000027],
                [15.538571001000037, 1.960712001000161],
                [15.536572447000083, 1.958769434000089],
                [15.531909001000031, 1.955486999000186],
                [15.530509999000117, 1.956359000000134],
                [15.524050713000179, 1.961789371000123],
                [15.519588471000077, 1.965208889000053],
                [15.514992714000073, 1.968092322000189],
                [15.505668639000135, 1.973389864000069],
                [15.503537179000034, 1.973993778000136],
                [15.486153662999982, 1.982577771000081],
                [15.48508739500005, 1.98157274800019],
                [15.481222152999976, 1.976748347000068],
                [15.478290559000072, 1.97393429300007],
                [15.475758553000048, 1.972125293000147],
                [15.473426999000026, 1.971188000000154],
                [15.467497908000155, 1.970049992000156],
                [15.459771156000158, 1.969449043000054],
                [15.453907965999974, 1.968043089000105],
                [15.446979522000106, 1.965498090000097],
                [15.439385001000119, 1.96221599900008],
                [15.435187999000163, 1.961212],
                [15.431857107999974, 1.960207344000025],
                [15.428059577999989, 1.958465695000086],
                [15.422929764000116, 1.955115916000125],
                [15.420597076000035, 1.952569484000094],
                [15.419331551000028, 1.949888945999987],
                [15.417331999000169, 1.946202999000093],
                [15.415000000000191, 1.943455999000037],
                [15.411669001000178, 1.941781000000106],
                [15.409536000000173, 1.940910000000145],
                [15.405606029000182, 1.940375004000089],
                [15.402408999000158, 1.939235999000118],
                [15.398012001000154, 1.938969001000032],
                [15.39488124799999, 1.937763690000054],
                [15.376693726000099, 1.929255844000068],
                [15.372763999000085, 1.927246],
                [15.369832, 1.925437000000102],
                [15.366501001000074, 1.922422],
                [15.359439001000169, 1.916592001000026],
                [15.355841000000112, 1.914917000000116],
                [15.352376999000114, 1.912773000000186],
                [15.349579001000052, 1.911634],
                [15.346182000000113, 1.911769001000153],
                [15.343916953000189, 1.912976071000173],
                [15.342252001000077, 1.915389001000165],
                [15.340654001000019, 1.918338999000184],
                [15.338190079000071, 1.922360541000046],
                [15.33506, 1.926986],
                [15.334994, 1.929801001000158],
                [15.335527001, 1.932749999000066],
                [15.336527824000143, 1.936234831000093],
                [15.33712768600003, 1.939988017000133],
                [15.335729599000103, 1.941596865],
                [15.333531086000164, 1.941663997999967],
                [15.332198143000085, 1.940123081000138],
                [15.330799, 1.937643001000083],
                [15.328467001000092, 1.934896001000027],
                [15.325935001000062, 1.93301999900001],
                [15.322071999000173, 1.93208200000015],
                [15.318808, 1.931546999000034],
                [15.316608999000039, 1.932217001000026],
                [15.313212001000181, 1.933558],
                [15.310481, 1.934228999000084],
                [15.30755, 1.935972000000106],
                [15.304887001000054, 1.938117999000156],
                [15.304486999, 1.939793],
                [15.305486999000152, 1.944016],
                [15.308487000000127, 1.957554],
                [15.309620857000084, 1.963853835],
                [15.309087753000028, 1.966065646000175],
                [15.305891038000084, 1.970288754000023],
                [15.301495552000063, 1.975182296000071],
                [15.300163000000111, 1.97719300000017],
                [15.297566000000131, 1.980478000000176],
                [15.296433999000044, 1.982221000000152],
                [15.29423599900008, 1.986980001000177],
                [15.29230499300013, 1.989326001000165],
                [15.289841001000013, 1.99147100100015],
                [15.287975, 1.992342999000186],
                [15.285178000000144, 1.994220001000087],
                [15.282580375000066, 1.995091796000168],
                [15.279983000000186, 1.995695001000058],
                [15.276384999000072, 1.996231999000088],
                [15.271322001000044, 1.998177000000169],
                [15.265794, 2.001864],
                [15.258933001000116, 2.007227],
                [15.256868340999972, 2.008232834000125],
                [15.249940001000141, 2.009038000000146],
                [15.242812, 2.009642],
                [15.240081987, 2.011050183000123],
                [15.239948, 2.012793001000091],
                [15.240548133000061, 2.013664247000065],
                [15.248076187000095, 2.017282366000188],
                [15.248875618000113, 2.018823861000101],
                [15.249076843000125, 2.022644282000101],
                [15.248477937000132, 2.027805066000042],
                [15.247078895000016, 2.029279948000067],
                [15.245480537000105, 2.030151607000164],
                [15.242283001000033, 2.031291000000181],
                [15.238485001000015, 2.032431],
                [15.234887999000136, 2.03283400000015],
                [15.232090001000074, 2.03236500100013],
                [15.228293001000168, 2.03209800000019],
                [15.22502899900013, 2.032165],
                [15.221765000000119, 2.032902999000044],
                [15.218500000000176, 2.034512],
                [15.215170001000047, 2.035852999000099],
                [15.211706001000152, 2.037730001000114],
                [15.207175999000185, 2.038937000999965],
                [15.204445, 2.038937000999965],
                [15.201113701000054, 2.038401604000171],
                [15.198049545000174, 2.037329673000045],
                [15.19478400100013, 2.035319001000119],
                [15.18865499899999, 2.027947],
                [15.186989, 2.025133000000153],
                [15.18492300100013, 2.022184],
                [15.183724000000154, 2.019436],
                [15.181192, 2.016487],
                [15.17906, 2.014678000000174],
                [15.177328001000035, 2.014678000000174],
                [15.174930919000076, 2.015214073000038],
                [15.171666, 2.018633],
                [15.170134544000177, 2.021045923000088],
                [15.166737557000033, 2.027413605999982],
                [15.164406, 2.030631],
                [15.16314100000011, 2.037133001000143],
                [15.163408000000175, 2.040417001000037],
                [15.16220887600008, 2.041690012000117],
                [15.159610748000034, 2.041958570000134],
                [15.152749062, 2.040618658000142],
                [15.150816960000043, 2.039010560000122],
                [15.150550001000056, 2.036061000000132],
                [15.150617000000182, 2.033446999000148],
                [15.14835, 2.018434001000173],
                [15.148016975000075, 2.014546930000165],
                [15.14708300000018, 2.011933],
                [15.145285, 2.009654001000058],
                [15.143285898000045, 2.008380958000146],
                [15.139955999000108, 2.007041000000129],
                [15.136757999, 2.006237],
                [15.133426902, 2.007309079000038],
                [15.13076200100005, 2.009387001000164],
                [15.127830999000025, 2.010594001000186],
                [15.124366759000054, 2.01153254500008],
                [15.121235847000037, 2.010728597000139],
                [15.116438999000138, 2.004964992000112],
                [15.116038999000068, 2.002418001000137],
                [15.11510699900009, 1.999066998999979],
                [15.114439999000126, 1.994508999000175],
                [15.114106000000106, 1.990286],
                [15.112973001000057, 1.983048],
                [15.110909000000106, 1.981708],
                [15.108510001000127, 1.980836000000124],
                [15.105446, 1.980837],
                [15.101782001000117, 1.981239],
                [15.097452, 1.981305999000028],
                [15.094521, 1.981105000000184],
                [15.090922999000156, 1.980635999000185],
                [15.08925799900004, 1.980837],
                [15.084861999000111, 1.982580001000088],
                [15.081863, 1.982849001000147],
                [15.0774, 1.982514001000141],
                [15.075068001000147, 1.983854],
                [15.074070001000166, 1.985731043000044],
                [15.074002999000129, 1.988412],
                [15.076334944000052, 1.994444102000045],
                [15.07473659600015, 1.997259378000081],
                [15.072338104000096, 2.000342608000096],
                [15.069340706000105, 2.000811815000134],
                [15.06620979400003, 2.000074625000082],
                [15.064410210000176, 1.998466135],
                [15.062412, 1.996120001000065],
                [15.058414459000119, 1.990691543000139],
                [15.05661582900018, 1.988010645000031],
                [15.05481702100019, 1.984659040000111],
                [15.053351001000067, 1.981375000000128],
                [15.050820113000043, 1.978493126999979],
                [15.048355000000129, 1.976952],
                [15.046090000000106, 1.977353999000059],
                [15.043292, 1.978359000000182],
                [15.040227995000123, 1.979968010000107],
                [15.036696999000185, 1.985866001000034],
                [15.035365000000183, 1.992970999000079],
                [15.034299851000071, 1.996925592000025],
                [15.03304386200017, 1.999738932000071],
                [15.027894974000105, 2.002568722000092],
                [15.015808001000039, 2.008888000000184],
                [15.008195000000114, 2.012431001000152],
                [15.004006999000069, 2.013676],
                [14.99982097000003, 2.016644039000028],
                [14.996299001000011, 2.020953000000134],
                [14.99515700000012, 2.024687],
                [14.993062974000054, 2.027942658000086],
                [14.989447001000087, 2.031868000000145],
                [14.986402001000044, 2.03407],
                [14.983546000000104, 2.03531499900015],
                [14.980406001000063, 2.034165999000095],
                [14.976598999000032, 2.028707999000062],
                [14.975742, 2.025452999000038],
                [14.974315001000036, 2.020953000000134],
                [14.974315001000036, 2.018176],
                [14.973173000000145, 2.01358000099998],
                [14.971649999000022, 2.010132999000064],
                [14.968224698000029, 2.008600973000171],
                [14.964323044000139, 2.008313656000098],
                [14.960991859000103, 2.006781579000062],
                [14.95690000000019, 2.004674999000031],
                [14.953949999000088, 2.003717001000155],
                [14.948144000000127, 2.002856],
                [14.941578, 2.003238],
                [14.937770843000067, 2.002950670000075],
                [14.934345244999975, 2.001705884000103],
                [14.927682999000126, 2.001417999000182],
                [14.923210000000154, 2.001035001000105],
                [14.917976001000056, 2.001514001000146],
                [14.913979, 2.004003000000125],
                [14.911505000000147, 2.005056],
                [14.90807799900017, 2.006109],
                [14.906365394000147, 2.007832528000051],
                [14.902939000000117, 2.010129999000185],
                [14.89856100100019, 2.013768],
                [14.895135000000153, 2.015970000000152],
                [14.892756000000134, 2.019226],
                [14.891899, 2.021714999000039],
                [14.892184, 2.024492001000112],
                [14.894468000000188, 2.027269001000036],
                [14.898560523000128, 2.029279948000067],
                [14.901320000000112, 2.030907999000021],
                [14.90427000000011, 2.033302001000038],
                [14.910075001000052, 2.039239001000112],
                [14.911598, 2.042207],
                [14.911503000000152, 2.043546999000057],
                [14.912359, 2.047665000000166],
                [14.913977, 2.050825001000021],
                [14.91578481800002, 2.05350573000004],
                [14.913595, 2.062696999000025],
                [14.912643134000177, 2.064037810000173],
                [14.909788000000162, 2.064133],
                [14.906838000000164, 2.063271999000165],
                [14.897607, 2.062984],
                [14.895132, 2.063558000000114],
                [14.892942062000088, 2.066621914000109],
                [14.887137, 2.068727999000032],
                [14.881902890999982, 2.071504154000138],
                [14.87980800000014, 2.074472],
                [14.87980800000014, 2.076196],
                [14.879142, 2.080600000000118],
                [14.879046001000063, 2.084525999000107],
                [14.878379999000174, 2.08759],
                [14.878094, 2.093622],
                [14.878759, 2.10319699900009],
                [14.877711999000155, 2.106931],
                [14.874761581000087, 2.110760927000115],
                [14.872382001000062, 2.111430999000106],
                [14.869051, 2.110952001000101],
                [14.863626480999983, 2.109323740000093],
                [14.859438999000076, 2.106833995999978],
                [14.85896400100006, 2.103387001000158],
                [14.860011101, 2.100322962000064],
                [14.865626001000066, 2.090174001000094],
                [14.86657898600015, 2.086344059999988],
                [14.864676, 2.083566999000027],
                [14.86239120800002, 2.081173218000117],
                [14.859440999000071, 2.081938999000101],
                [14.85658645600006, 2.083183765000115],
                [14.85192203500003, 2.084619522000139],
                [14.84764, 2.085289001000035],
                [14.84373760200009, 2.085384608000027],
                [14.839645384999983, 2.083469153000067],
                [14.837172, 2.080022],
                [14.83469700000012, 2.075617],
                [14.831557, 2.070445999000071],
                [14.829463999000097, 2.068340001000081],
                [14.82518099900011, 2.06585],
                [14.822135999000068, 2.064701000000184],
                [14.81832900000012, 2.063647000000174],
                [14.812903999000014, 2.060774001000084],
                [14.809098000000176, 2.059816000000126],
                [14.807384, 2.060007],
                [14.80452918900005, 2.063549519000048],
                [14.799675001000026, 2.069102000000157],
                [14.795867999, 2.073889001],
                [14.792917252, 2.074367524000081],
                [14.790537834000133, 2.073409796000078],
                [14.785209000000123, 2.069482999000172],
                [14.783211001000097, 2.068621000000178],
                [14.78007000100007, 2.065556999000023],
                [14.776358999000024, 2.063642000000129],
                [14.77359900000016, 2.063066001000152],
                [14.770172999000124, 2.063641001000121],
                [14.76674700100017, 2.067087],
                [14.764366, 2.069193],
                [14.764366, 2.072449000000177],
                [14.765888, 2.074172],
                [14.768268000000148, 2.075705],
                [14.771407999000132, 2.078099000000123],
                [14.773215, 2.081067001000122],
                [14.774356843000078, 2.084227323000107],
                [14.774547577000021, 2.087003946000095],
                [14.773785591000035, 2.090355158000079],
                [14.772166999000092, 2.09227],
                [14.772166999000092, 2.094855],
                [14.770928999000148, 2.096769999000117],
                [14.767693766000036, 2.099067036000065],
                [14.764647, 2.099544999000045],
                [14.761697, 2.099449],
                [14.758462, 2.097630000000152],
                [14.755416, 2.096193],
                [14.751609802000132, 2.093702793000034],
                [14.748089000000107, 2.090734000000168],
                [14.744377014000122, 2.089010008000116],
                [14.741237, 2.089393001000133],
                [14.73828600000013, 2.091402998999968],
                [14.737619000000166, 2.093125999000108],
                [14.738191, 2.095903001000181],
                [14.739617000000123, 2.096955999000102],
                [14.742281968000157, 2.098392982000064],
                [14.744376, 2.098393],
                [14.74780273400006, 2.097149134000176],
                [14.74970600100005, 2.09715],
                [14.751704, 2.098299000000111],
                [14.752750001000038, 2.100213999000175],
                [14.753225999000165, 2.102894999000114],
                [14.753320695000184, 2.10624623300015],
                [14.752464, 2.108448001000056],
                [14.749989509000102, 2.109501123000143],
                [14.745801970000059, 2.110841016000052],
                [14.741708999000082, 2.112947001000123],
                [14.73637900100016, 2.116776001000062],
                [14.733141999, 2.118785999000124],
                [14.730001, 2.121466],
                [14.727525711, 2.124817133000136],
                [14.72448, 2.126443999000117],
                [14.72162532700014, 2.125677823000103],
                [14.719913001, 2.123954001000129],
                [14.719816999000102, 2.121656001000076],
                [14.718771000000118, 2.120028001000037],
                [14.718390465000027, 2.118017434000024],
                [14.720293999000091, 2.11552834500003],
                [14.722484, 2.11332599900004],
                [14.726768000000106, 2.108253000000161],
                [14.726767770000095, 2.105572],
                [14.720962822000104, 2.105666078000184],
                [14.716583999000079, 2.10758],
                [14.71192000100001, 2.110356],
                [14.705352937000043, 2.110738017000188],
                [14.698881, 2.112460001000159],
                [14.698215000000175, 2.11504499900002],
                [14.699069932000043, 2.118013763000135],
                [14.69802284299999, 2.121268989000043],
                [14.69621372100005, 2.12347078300013],
                [14.693167686000038, 2.126246930000036],
                [14.689550000000168, 2.129980001000092],
                [14.686313952000148, 2.133905020000043],
                [14.683172999000021, 2.135245],
                [14.678795, 2.135244000000171],
                [14.671181678000039, 2.132944583999972],
                [14.665377, 2.130741000000114],
                [14.660522, 2.130166001000021],
                [14.652908000000139, 2.129877],
                [14.648054000000116, 2.131503],
                [14.645008000000189, 2.135141001000079],
                [14.642056, 2.139736],
                [14.641769999000019, 2.142321],
                [14.643006999000079, 2.144428001000051],
                [14.647099, 2.146344],
                [14.648050001000058, 2.148641999000176],
                [14.647097587000133, 2.152472019000186],
                [14.64509868600004, 2.155631067000115],
                [14.641386032000128, 2.158406974000172],
                [14.637007714000106, 2.158501626000032],
                [14.631773, 2.158117001000051],
                [14.628347119000182, 2.158786977000091],
                [14.62596699900007, 2.161467001000119],
                [14.622825, 2.1657750010001],
                [14.620255, 2.170274001000166],
                [14.61825386300012, 2.176880152000081],
                [14.613970001000041, 2.181667001000108],
                [14.612445988000161, 2.18540006600017],
                [14.611588001000143, 2.190092000000107],
                [14.610350607999976, 2.192964076000067],
                [14.606732001000182, 2.196697],
                [14.597784042000114, 2.203109742000152],
                [14.595404625000128, 2.203477859000145],
                [14.593787194000186, 2.202711583000166],
                [14.593026161000182, 2.198689937000154],
                [14.591409683, 2.19332766600013],
                [14.587032318000126, 2.189688205000152],
                [14.583702087000177, 2.186336041000061],
                [14.582277297000076, 2.177718401999982],
                [14.580183982000108, 2.17484545700006],
                [14.577518464000036, 2.176185132000171],
                [14.573901177000153, 2.178482055000018],
                [14.57190227600006, 2.179055929000072],
                [14.569237709000106, 2.178672314000039],
                [14.563337327000056, 2.177234174000091],
                [14.558388710000145, 2.174264670000071],
                [14.554391861000113, 2.172923087000186],
                [14.547445297000024, 2.17024016400012],
                [14.543923377999988, 2.169951915000183],
                [14.54040241199999, 2.170238019000067],
                [14.537737845000095, 2.168035031000045],
                [14.534692764999988, 2.16650199899999],
                [14.532407760000012, 2.168607712000039],
                [14.529648781000105, 2.166117668000084],
                [14.530220985000028, 2.16267085200019],
                [14.527079583000159, 2.163914680000062],
                [14.523463249000088, 2.164583683999979],
                [14.520227432000127, 2.164965629000051],
                [14.516705512000044, 2.16381549800002],
                [14.51099586500004, 2.163239241000042],
                [14.504142761000026, 2.163524152000093],
                [14.497766495000064, 2.162851810000063],
                [14.493960381000079, 2.160648347000063],
                [14.487870216000033, 2.157965661000162],
                [14.483397483999966, 2.156144858000062],
                [14.481209754000133, 2.153750419000119],
                [14.481019974999981, 2.152026893000141],
                [14.481973647000018, 2.145612478000089],
                [14.480356216000075, 2.143792630000121],
                [14.47788143299999, 2.14398312600008],
                [14.473883628000067, 2.145992518000071],
                [14.469220162000056, 2.148001432000058],
                [14.465888976000087, 2.148000479000075],
                [14.464461326000105, 2.146659374000024],
                [14.46351051400012, 2.143882514000097],
                [14.464082719000146, 2.141010284000174],
                [14.465798378999978, 2.134883165000076],
                [14.46627521400012, 2.130670547000023],
                [14.464943886000071, 2.127797842000121],
                [14.462755204000189, 2.126073599000165],
                [14.458949088, 2.125210524000067],
                [14.454476356000157, 2.125400543000069],
                [14.449431420000167, 2.127505064000047],
                [14.44524288100007, 2.130280256000162],
                [14.443528175000154, 2.133152008000081],
                [14.443242071999975, 2.136502743000165],
                [14.444763183000191, 2.140237570000181],
                [14.446855544000186, 2.143301964000045],
                [14.446855544000186, 2.145504237000125],
                [14.445521354000107, 2.149142028000085],
                [14.44475841600007, 2.15287590000014],
                [14.442949296000108, 2.157183647000124],
                [14.44056892400016, 2.159480810000048],
                [14.436664581000116, 2.164553641999987],
                [14.433999062000112, 2.166850567000097],
                [14.430287359999966, 2.166849137000156],
                [14.427241325000125, 2.167135238000071],
                [14.424481393000178, 2.168666125000186],
                [14.423528671000042, 2.171250821000172],
                [14.421718598000098, 2.174696923],
                [14.41867256100005, 2.175844669000128],
                [14.41581630700017, 2.178811788000076],
                [14.412199020000116, 2.181299687000092],
                [14.410105704999978, 2.181011675999969],
                [14.405728339, 2.178807734999964],
                [14.402112007000142, 2.178136111000185],
                [14.398209572000098, 2.178900480000095],
                [14.394401551000044, 2.181101083000044],
                [14.388024330000064, 2.184066534000181],
                [14.383455276, 2.185596466000106],
                [14.377174376000085, 2.183487654000032],
                [14.372416496000085, 2.182145119000097],
                [14.369370461000187, 2.183005572000127],
                [14.366990089000069, 2.185781240000097],
                [14.362896920000026, 2.187694312000133],
                [14.358899117000135, 2.187213897000049],
                [14.355854987000157, 2.18539357200018],
                [14.354713439000022, 2.183190823000075],
                [14.35062313000003, 2.178784847000031],
                [14.34615135200005, 2.175623416],
                [14.342630387000156, 2.174185754000121],
                [14.338633538000124, 2.173513890000095],
                [14.33225727100006, 2.173319339999978],
                [14.32873630600011, 2.172743321000041],
                [14.317032812999969, 2.167185068000151],
                [14.308564186000126, 2.163542985000163],
                [14.303901673000098, 2.16258335200007],
                [14.301997186000165, 2.163348436000092],
                [14.299712181000018, 2.166794062000122],
                [14.294952393000187, 2.168993950000072],
                [14.291524888000026, 2.171864748000076],
                [14.287908553000022, 2.171958685000107],
                [14.252790450000191, 2.172516108000139],
                [14.204634666000118, 2.172587155999963],
                [14.175990104000107, 2.171135663000086],
                [14.166567802000145, 2.171513319000042],
                [14.158002853000141, 2.172370196000145],
                [14.11241722, 2.1723439700001],
                [14.020202636000135, 2.172286510000163],
                [14.002978323999969, 2.171892404000062],
                [13.987941742000032, 2.172648192000054],
                [13.927894593000076, 2.172798156000056],
                [13.891162872000109, 2.172005414000182],
                [13.855558396000106, 2.172013522000157],
                [13.794958114000167, 2.172027109999988],
                [13.693237305000025, 2.171846389000052],
                [13.631484986000032, 2.171121360000029],
                [13.576487542, 2.17145323800014],
                [13.49691391000016, 2.171301126000174],
                [13.473444938000057, 2.171256304000167],
                [13.40437126099999, 2.171758890000035],
                [13.355565072000047, 2.171227693000049],
                [13.299013137000145, 2.170983553999974],
                [13.29809379600016, 2.161767958999974],
                [13.296032905000118, 2.159471034000092],
                [13.291687011000022, 2.15901494000002],
                [13.29122924699999, 2.156485082000131],
                [13.293972968000105, 2.154182911000021],
                [13.298995971000124, 2.142910958000186],
                [13.29716396300006, 2.139235021000104],
                [13.291904449000072, 2.139008998000122],
                [13.287326812000174, 2.133263111000133],
                [13.294412614000066, 2.128890038000066],
                [13.29486942300008, 2.125669003000098],
                [13.291436195000074, 2.121993065000083],
                [13.290975570000057, 2.118314028000043],
                [13.293714524000052, 2.108195066000178],
                [13.298290253000175, 2.108190059000037],
                [13.29895687200019, 2.104372978000129],
                [13.297826766000128, 2.100832939000043],
                [13.299427031999983, 2.098073007000153],
                [13.295994758000063, 2.093936921000022],
                [13.297364235000089, 2.091634988000067],
                [13.29438972500003, 2.088649035],
                [13.293472290000068, 2.084052085000167],
                [13.295294762000026, 2.075081110000156],
                [13.29346179900017, 2.068185092000135],
                [13.295517921000112, 2.064733982000064],
                [13.294371605000151, 2.061285973000167],
                [13.290025711000055, 2.05715108000004],
                [13.289793014999987, 2.050251961000072],
                [13.286819458000082, 2.050945044000116],
                [13.28316307100016, 2.05462789600017],
                [13.281332969000061, 2.054169893000051],
                [13.28110313500008, 2.050950051000086],
                [13.288643836000062, 2.043355943000051],
                [13.286583900000153, 2.03921890200013],
                [13.277891160000138, 2.035089016000029],
                [13.273768424000082, 2.02497506200001],
                [13.268965720999972, 2.02567005200018],
                [13.263019563000057, 2.023375034000139],
                [13.2541017530001, 2.024763107000183],
                [13.250897407000025, 2.019476891000124],
                [13.246322633000034, 2.020400047000123],
                [13.237400055000023, 2.010981083000104],
                [13.239912033000053, 2.007299901000124],
                [13.236025810000115, 2.005002976000071],
                [13.235107422000112, 1.998335957999984],
                [13.232818603, 1.996498942000073],
                [13.229617120000057, 1.996271014000058],
                [13.224813462000157, 1.991215945000022],
                [13.218638420000104, 1.987771988000077],
                [13.217720032000159, 1.982944965000115],
                [13.209712982000042, 1.981801034000057],
                [13.2071952820001, 1.975134016000141],
                [13.208789826000043, 1.965477944000043],
                [13.216786383999988, 1.949836016000063],
                [13.216558456000143, 1.946388007000167],
                [13.207405089000133, 1.938807011000108],
                [13.204204559, 1.938120962000141],
                [13.201228142000048, 1.93375396700003],
                [13.193911552000031, 1.931920051000134],
                [13.190937995000127, 1.928933979000021],
                [13.187732697000115, 1.921810030000017],
                [13.183842660000039, 1.91790294700013],
                [13.182927132000145, 1.914685011000188],
                [13.18475532499997, 1.910545945000024],
                [13.181322097000134, 1.907099963000178],
                [13.172632217, 1.900897980000138],
                [13.167601586000046, 1.902742029000024],
                [13.161886216000028, 1.901365995000049],
                [13.158223152000176, 1.893319965000103],
                [13.160735130000035, 1.8866519930001],
                [13.157532692000075, 1.881826997000189],
                [13.159585953000033, 1.875617981000175],
                [13.16324424700008, 1.872856021000132],
                [13.17170238400007, 1.869400979000034],
                [13.172158240000158, 1.865491986000166],
                [13.170781136000073, 1.861354947000109],
                [13.172839165000084, 1.858365059000164],
                [13.187243462000083, 1.857892990000096],
                [13.19204330399998, 1.853752018000137],
                [13.193870545000095, 1.849153043000172],
                [13.193634034000183, 1.834898950000024],
                [13.195006371000147, 1.832597017000069],
                [13.195918083000038, 1.829836965000141],
                [13.190426826000191, 1.823174953000148],
                [13.192267417000153, 1.817103981000173],
                [13.195454597000094, 1.816504003000091],
                [13.197081566000122, 1.812005042000067],
                [13.200360299000067, 1.809453010000141],
                [13.201395035000075, 1.806843043000129],
                [13.199106216000132, 1.801787972999989],
                [13.198645591000115, 1.796270013000139],
                [13.195672035000143, 1.791445016000182],
                [13.188352583999972, 1.788462044000085],
                [13.186298370000031, 1.793290973000069],
                [13.183097840000073, 1.79398405500018],
                [13.181726456000092, 1.791226029000086],
                [13.182407380000143, 1.783409000000177],
                [13.179203033000135, 1.773524046000091],
                [13.182399750000059, 1.769155027000181],
                [13.18148231500004, 1.762027980000141],
                [13.182395935000159, 1.756971001000181],
                [13.180335046000096, 1.753983021000181],
                [13.175074577000146, 1.751000046000058],
                [13.171417236000138, 1.745713949000106],
                [13.159069062000185, 1.741814971000053],
                [13.156550406000179, 1.732851028000084],
                [13.158834458000115, 1.725723982000147],
                [13.157690047000017, 1.723196983000094],
                [13.152660369000102, 1.721359969000105],
                [13.145111085, 1.70849204100017],
                [13.137106895000159, 1.701830030000053],
                [13.138705253000069, 1.697229981000078],
                [13.136416435000058, 1.691946984000026],
                [13.13984203300015, 1.684128047000115],
                [13.139382362000049, 1.677000999000143],
                [13.143036843000175, 1.67263197900013],
                [13.14006137900003, 1.663437963000092],
                [13.142344475000073, 1.658149958000138],
                [13.140057563000028, 1.65286195300007],
                [13.145310402000064, 1.640676021000047],
                [13.150337220000154, 1.64113402300012],
                [13.150793076000014, 1.634925962000068],
                [13.147134780000044, 1.633779048],
                [13.14621925400013, 1.63125205],
                [13.149190903999965, 1.628950000000145],
                [13.151017189000186, 1.624811053000087],
                [13.149184226000159, 1.617455961000132],
                [13.145070076000081, 1.61584901800012],
                [13.143239975000085, 1.610566020000135],
                [13.1455240250001, 1.606884956000044],
                [13.14209079800014, 1.59815204099999],
                [13.14277458100014, 1.595394015000068],
                [13.145975113000077, 1.59240400900012],
                [13.145515441999976, 1.58941602699997],
                [13.141403199000024, 1.591027022000162],
                [13.136601449000068, 1.589650988000187],
                [13.132259368000064, 1.591034055000137],
                [13.130888939000101, 1.586897016000023],
                [13.133171081000114, 1.583217024000021],
                [13.138198854000166, 1.581604958000014],
                [13.140709877, 1.577466012000059],
                [13.141163825000092, 1.570109010000067],
                [13.139561653000044, 1.56344497200007],
                [13.137275696000188, 1.561378003000016],
                [13.139102936000029, 1.559077978000062],
                [13.144589423000014, 1.559991956000033],
                [13.145273208000049, 1.555166960000122],
                [13.148241044000088, 1.551025988000163],
                [13.153725624, 1.551481962000025],
                [13.15441226900009, 1.549643040000149],
                [13.152124404000062, 1.543439030000172],
                [13.159202576000041, 1.530331969999963],
                [13.16377067500008, 1.525500059000137],
                [13.169259072000102, 1.529863954000177],
                [13.172917366000092, 1.529631973000051],
                [13.172917366000092, 1.532158971000058],
                [13.175433159000136, 1.535146951],
                [13.178632736000111, 1.535833001000128],
                [13.183885575000147, 1.527552963000176],
                [13.190055846000064, 1.524099946000035],
                [13.191649436000091, 1.515367032000086],
                [13.194621085000051, 1.515364052000166],
                [13.198277474000122, 1.512141943000131],
                [13.195302010000148, 1.508927942000014],
                [13.192784309000103, 1.498355032000063],
                [13.198269844000038, 1.500419974000067],
                [13.19918441700014, 1.498119951000092],
                [13.198497772000053, 1.495133042000134],
                [13.193921090000117, 1.489621998000132],
                [13.194834710000066, 1.487552046000076],
                [13.200093269000035, 1.487316965000105],
                [13.20603466000017, 1.490071059000059],
                [13.208087921000185, 1.481104016000188],
                [13.213802337000118, 1.482020974000022],
                [13.217913627000087, 1.479257942000061],
                [13.220652580000149, 1.473971010000128],
                [13.220194816000117, 1.470752954000147],
                [13.21630668600011, 1.46570003000005],
                [13.21790504400019, 1.462939978000122],
                [13.222703934000037, 1.462017060000107],
                [13.223159790000125, 1.460176946000104],
                [13.221101761000114, 1.456501008000089],
                [13.223383903000126, 1.451444029000072],
                [13.221325874000058, 1.447998047000056],
                [13.221550942000079, 1.443858981000062],
                [13.22657680500015, 1.439257026000064],
                [13.228864670000178, 1.443393947000118],
                [13.234118461000094, 1.438333988000068],
                [13.238910675000113, 1.42775702400013],
                [13.236622811000188, 1.422013045000142],
                [13.237535476000119, 1.418565036000075],
                [13.241876602000104, 1.415802956000164],
                [13.243701935000104, 1.41120302600001],
                [13.248957634000021, 1.411198973000126],
                [13.252611160000129, 1.405451059000086],
                [13.249178887000085, 1.396489024000118],
                [13.249400139000102, 1.391664028000037],
                [13.25625801100017, 1.392348052000159],
                [13.257171631000062, 1.388900041000056],
                [13.246418952999989, 1.367532969000138],
                [13.247330665000106, 1.364544987000159],
                [13.253955841000106, 1.358795047000115],
                [13.254181861000177, 1.356495022000161],
                [13.24481010400001, 1.348919035000108],
                [13.243893624000066, 1.344782949000035],
                [13.245489121000162, 1.340185046000158],
                [13.250287056000047, 1.336735011000087],
                [13.254172326000173, 1.338109015999976],
                [13.257599831000164, 1.341783048000025],
                [13.261713982000117, 1.342010976000097],
                [13.26308441100008, 1.34063005400003],
                [13.26399612400013, 1.336493015000144],
                [13.25735855, 1.316045047000046],
                [13.23998737300002, 1.305027963000157],
                [13.231304169000111, 1.303655982],
                [13.229018212000085, 1.300439953000137],
                [13.229697227000031, 1.290099026000121],
                [13.223980903000097, 1.285045982000156],
                [13.22032737700016, 1.285279034999974],
                [13.21393203800011, 1.291262031000144],
                [13.197707177000098, 1.286906004000059],
                [13.194512368, 1.289206029000184],
                [13.192914010000095, 1.293805002000113],
                [13.188799858000039, 1.292428018000067],
                [13.184911728000031, 1.286914944000102],
                [13.18285369900019, 1.280022979000137],
                [13.183991432000084, 1.271980047000056],
                [13.18307495200014, 1.268074036000087],
                [13.178275109000083, 1.2641689780001],
                [13.177130698000155, 1.259804964000182],
                [13.180554391000044, 1.25106895000016],
                [13.178954124000029, 1.247393012000089],
                [13.175525665000123, 1.24693703600002],
                [13.171187400000122, 1.251306058000068],
                [13.167986870000163, 1.250617981000062],
                [13.156331063000039, 1.239596010000184],
                [13.15632915600014, 1.234310984000047],
                [13.167752266000093, 1.233152985000061],
                [13.181687354000076, 1.228546977000178],
                [13.189452171000084, 1.224632978000045],
                [13.195616722000068, 1.2179650060001],
                [13.21823978399999, 1.226909995000085],
                [13.226463316999968, 1.225293994000026],
                [13.23651599800013, 1.225975991000041],
                [13.242911338000113, 1.223901987000033],
                [13.255480767000165, 1.228256941000154],
                [13.25798988400004, 1.223889946000043],
                [13.260959626000044, 1.223196984000083],
                [13.265303612000139, 1.227789999000038],
                [13.271243096000035, 1.227555038000048],
                [13.274668694000127, 1.224794984000141],
                [13.278097152000157, 1.224791051000068],
                [13.287466049000045, 1.229609014000175],
                [13.29317760399999, 1.227535009000121],
                [13.299574853000081, 1.229827047000185],
                [13.305740357000047, 1.221318961],
                [13.309393883000155, 1.221086980000109],
                [13.313282013000162, 1.225219011000149],
                [13.31466102500002, 1.23946595100017],
                [13.323568345000012, 1.235780956000156],
                [13.324939727000185, 1.238077998000165],
                [13.325402261000079, 1.243132950000074],
                [13.321976661000122, 1.249111056000174],
                [13.321986198000104, 1.262209059000043],
                [13.335008621000156, 1.262886048000041],
                [13.340033532000177, 1.258056999000075],
                [13.343002318000117, 1.257593990000032],
                [13.346659660000057, 1.258507968000174],
                [13.351920128000074, 1.266088963000073],
                [13.3724823, 1.26537704500015],
                [13.37454032900007, 1.267675043000054],
                [13.373170852000158, 1.270434975000114],
                [13.366319656000144, 1.275727033000123],
                [13.36609363499997, 1.279173017000176],
                [13.37112426800013, 1.285601973000041],
                [13.375012398000138, 1.290886045000093],
                [13.377754212000127, 1.291113019000079],
                [13.384148599000127, 1.286739946000182],
                [13.397625923000021, 1.282359004000057],
                [13.40013980800012, 1.283735990000139],
                [13.40220165200003, 1.290168047],
                [13.409742356000095, 1.291082025000151],
                [13.413860321000186, 1.297510981000073],
                [13.422310829000025, 1.295202018000055],
                [13.43145465800012, 1.299559952000038],
                [13.436711312000057, 1.300243974000182],
                [13.438531875000137, 1.29288697200019],
                [13.442647934999968, 1.294721008000067],
                [13.446301461000132, 1.294028045],
                [13.457719804000135, 1.285972],
                [13.464800835000062, 1.284355999000184],
                [13.47005367200012, 1.280673027000091],
                [13.475079537, 1.279978037000149],
                [13.47735881900013, 1.274459958000023],
                [13.482156754000187, 1.27376496800008],
                [13.48855209400017, 1.270081998000137],
                [13.494722365000086, 1.269155980000107],
                [13.496095657000012, 1.275357963000147],
                [13.500892639000085, 1.275123000000178],
                [13.51025390600006, 1.266610026000137],
                [13.514365196000085, 1.265915035999967],
                [13.51528167600003, 1.267292022000049],
                [13.511628150000092, 1.271662950000064],
                [13.513003349000087, 1.2737300390001],
                [13.524428367000041, 1.276013971000054],
                [13.541112899000098, 1.284039020000137],
                [13.547511101000111, 1.282881023000186],
                [13.552076340000099, 1.278280020000068],
                [13.55664634700014, 1.278733016000047],
                [13.573335647000079, 1.289283038000065],
                [13.576078414999984, 1.290429949000043],
                [13.577684403000035, 1.298470021000185],
                [13.58843135899997, 1.304432988000031],
                [13.59048938799998, 1.30741799000009],
                [13.59072589900012, 1.316843034000101],
                [13.604210853000154, 1.320500971000172],
                [13.608331681000038, 1.329457998000066],
                [13.629355431000135, 1.330350995000174],
                [13.63438034000012, 1.329195976000051],
                [13.63256359200011, 1.341146945999981],
                [13.633481980000056, 1.344135046000133],
                [13.639196395000113, 1.3450469980001],
                [13.64765357900012, 1.349401950000186],
                [13.654734611000038, 1.345484019000139],
                [13.665022850000128, 1.352594972000077],
                [13.678736686000093, 1.353955984000038],
                [13.683301927000059, 1.349352003000035],
                [13.687641143000064, 1.348428964000163],
                [13.689933777000078, 1.354861021000033],
                [13.69404125300008, 1.349799035999979],
                [13.701807976000111, 1.34978699800007],
                [13.70775222800006, 1.350926996000055],
                [13.70981311900016, 1.354603051000026],
                [13.725356102000035, 1.361704946000089],
                [13.727423667000096, 1.368366956000102],
                [13.731309891000137, 1.36996901100008],
                [13.739079476000029, 1.370417952000025],
                [13.74433231400019, 1.368113994000169],
                [13.745242119000068, 1.366271973000096],
                [13.741811751000057, 1.362370968000164],
                [13.742495536000092, 1.360530972000163],
                [13.750948907000065, 1.360980035000068],
                [13.754373550000025, 1.359133959000133],
                [13.759166717000028, 1.352923990000079],
                [13.762365342000123, 1.352458953000109],
                [13.765343666000149, 1.358659984000042],
                [13.76808834000002, 1.360725046000027],
                [13.778598785000042, 1.361397027000123],
                [13.785460472000182, 1.366212965000159],
                [13.788893700000187, 1.371036054000172],
                [13.789133071000037, 1.379997016000175],
                [13.783663750000187, 1.391037941000036],
                [13.785725594000098, 1.397011041000042],
                [13.790980340000033, 1.394243956000139],
                [13.794178963000093, 1.395619036000085],
                [13.797154427000066, 1.398831964000181],
                [13.79670238600005, 1.404119969000078],
                [13.799678803, 1.408022047000031],
                [13.807217598000022, 1.407549977000031],
                [13.810422896000034, 1.411911964000069],
                [13.807687760000022, 1.417204022000078],
                [13.808382988000176, 1.423637986000017],
                [13.802436829000158, 1.420428991000165],
                [13.800839425000163, 1.422500968000122],
                [13.801992416000076, 1.430312039000057],
                [13.805885316000115, 1.43697094900017],
                [13.810227394000037, 1.437654973000122],
                [13.812738419000027, 1.43558299599999],
                [13.813871383000162, 1.428683997000178],
                [13.817070960000137, 1.427760959000011],
                [13.824614524000083, 1.430737972000031],
                [13.834210396000174, 1.427736043000095],
                [13.839923859000123, 1.428645968000183],
                [13.850669860000096, 1.433915020000029],
                [13.856384277000132, 1.43367600400012],
                [13.861858369000117, 1.425850987000104],
                [13.866428375000055, 1.425385953000159],
                [13.875802040000167, 1.429507016000173],
                [13.892031670000051, 1.432700038000064],
                [13.896828652000124, 1.432230949000029],
                [13.926289558000065, 1.417243004000113],
                [13.948914528000103, 1.417665004000185],
                [13.966515541000092, 1.422232033000171],
                [13.97200012200011, 1.421993017000091],
                [13.977706910000165, 1.417845012000157],
                [13.983418465000113, 1.416226984000048],
                [13.99598789200013, 1.418272972000068],
                [13.999420165, 1.420106053000097],
                [14.004464149000114, 1.432047963000173],
                [14.009265899000184, 1.434108972000104],
                [14.011556626000129, 1.438241005000123],
                [14.017722130000095, 1.433863045000066],
                [14.02457523400011, 1.431324006000068],
                [14.02845192, 1.427638054000056],
                [14.030270576000078, 1.421429992000128],
                [14.028661727999975, 1.413619042000107],
                [14.030255317000126, 1.410397053000054],
                [14.042577743000038, 1.398651958000187],
                [14.04988670300014, 1.397492051000029],
                [14.058551788000045, 1.385064006000164],
                [14.066542625000125, 1.380682946000036],
                [14.074538230000087, 1.378828049000163],
                [14.083450318000132, 1.378811956000163],
                [14.103097916000081, 1.375558018000049],
                [14.109274864999975, 1.380143046000171],
                [14.118889808000176, 1.391847014000064],
                [14.125289918000021, 1.392524003000062],
                [14.129633904000059, 1.394814015000179],
                [14.144489288000159, 1.39501500199998],
                [14.160021783000047, 1.391536952000024],
                [14.170305252000105, 1.391288043000088],
                [14.17533779100006, 1.394955039000081],
                [14.18722057299999, 1.395620943000154],
                [14.19110202700017, 1.393314005000036],
                [14.197939873000166, 1.382498026000121],
                [14.205465317000062, 1.37466895599999],
                [14.216883660000065, 1.370049],
                [14.22533988900011, 1.370952010000167],
                [14.230134964000115, 1.369564057000162],
                [14.237207413000021, 1.362195015000054],
                [14.247260094000012, 1.361484051000048],
                [14.251597404000051, 1.359174968000048],
                [14.258894920999978, 1.351119041000061],
                [14.271682740000188, 1.346264004999966],
                [14.278985978000094, 1.341192960000058],
                [14.287641526000073, 1.326694966000105],
                [14.296743392000167, 1.305302978000043],
                [14.303814889000023, 1.299541951000094],
                [14.305415154000173, 1.299769997000112],
                [14.30677509300017, 1.294250011000088],
                [14.303557396000144, 1.283225059000017],
                [14.30558776900017, 1.269662023000137],
                [14.303972243999965, 1.260704994000037],
                [14.297554015000173, 1.249227047999966],
                [14.295025825000039, 1.241189004000148],
                [14.296156883000037, 1.233603954000159],
                [14.300945281999986, 1.228767992000087],
                [14.309164047000138, 1.226451994000115],
                [14.310290337000083, 1.217257978000021],
                [14.323985099000026, 1.21033394300008],
                [14.329001426000048, 1.204118967000113],
                [14.335367202000043, 1.188248991000137],
                [14.343799590000174, 1.177430034000167],
                [14.344896316000131, 1.153759002000186],
                [14.346710206000125, 1.145710945000189],
                [14.37178993200007, 1.120839000000103],
                [14.38728428000013, 1.099436044000015],
                [14.396410943000035, 1.093672037000147],
                [14.39868640899999, 1.089761019000036],
                [14.398672104000184, 1.084015012000066],
                [14.401846885000054, 1.071830988000045],
                [14.399086953000108, 1.061728],
                [14.399765968000054, 1.057361007000111],
                [14.41228294400014, 1.035274030000096],
                [14.41369915000007, 1.026447059000077],
                [14.420242309000116, 1.016415954000081],
                [14.424094200000013, 1.001471996000134],
                [14.43229198400013, 0.989275991000113],
                [14.437965393000127, 0.969964982000079],
                [14.445253372000138, 0.960527004000028],
                [14.451645850999967, 0.959365011000102],
                [14.467617989000075, 0.951057017000153],
                [14.477182388000188, 0.937708021000162],
                [14.485577583000122, 0.933382988000176],
                [14.493420601000025, 0.932552993000115],
                [14.497989655000026, 0.92864900800015],
                [14.502346039000088, 0.905901014000051],
                [14.500068665000128, 0.898545981000154],
                [14.493225097000163, 0.8904960160001],
                [14.4916343700001, 0.879922986000167],
                [14.48775482300016, 0.876704991000111],
                [14.465377806999982, 0.874387026000079],
                [14.465838433000101, 0.870711028000187],
                [14.47109413200019, 0.864740014000176],
                [14.477033615000039, 0.862906992000035],
                [14.483668327000089, 0.848664999000164],
                [14.485505105000186, 0.835339010000041],
                [14.488712310000096, 0.825231016000032],
                [14.485060692000104, 0.819943011000078],
                [14.472737312000106, 0.812807024000051],
                [14.464080810999974, 0.789362013000073],
                [14.461113930000067, 0.786831976000087],
                [14.438515662999976, 0.780380011000091],
                [14.43098640500017, 0.775318026000036],
                [14.414565086000096, 0.752554001000135],
                [14.415262223000127, 0.742215990000147],
                [14.402029991000063, 0.730255006999982],
                [14.39335727700012, 0.727259994000065],
                [14.392674445000125, 0.724272012000142],
                [14.38811111400014, 0.721282006000024],
                [14.37853813200013, 0.70312100700005],
                [14.368041038000058, 0.698514999000167],
                [14.359619141, 0.672545017000061],
                [14.37058925500014, 0.661297024000135],
                [14.38453483600017, 0.637416005000148],
                [14.365594864000172, 0.629127026000106],
                [14.358302117000108, 0.617173016999971],
                [14.349862099000063, 0.610732020000057],
                [14.338907241000129, 0.610029995000161],
                [14.32248783100016, 0.593014000999972],
                [14.310846328000139, 0.591624021000086],
                [14.295107841000117, 0.580350995000117],
                [14.29351329800005, 0.57690298600005],
                [14.29374790200012, 0.570011021000084],
                [14.28349208899999, 0.555756987000052],
                [14.289212227000064, 0.543816984000046],
                [14.280994415000123, 0.543577016000086],
                [14.257932664000066, 0.548376978000022],
                [14.234893799000133, 0.536634983000056],
                [14.222796440000081, 0.536391020000053],
                [14.215486527000166, 0.540288984000142],
                [14.209541321000131, 0.549471021000045],
                [14.198813438000059, 0.55083602600007],
                [14.183071136000137, 0.543237985000189],
                [14.178732872000182, 0.543003024000029],
                [14.169598578999967, 0.548735024000166],
                [14.152012825000156, 0.555145979000088],
                [14.136945724000043, 0.557653010000138],
                [14.119593621000035, 0.558323026000153],
                [14.10659408500004, 0.549805999000057],
                [14.082666398000129, 0.521287024000173],
                [14.07083225200006, 0.493936002000055],
                [14.072207451000054, 0.489573001999986],
                [14.081117631000097, 0.481775999000149],
                [14.085684776999983, 0.479943990000152],
                [14.09254074100005, 0.474440009000148],
                [14.093004228000154, 0.469386995000036],
                [14.091638566000142, 0.46662700100012],
                [14.085008620999986, 0.461542011000176],
                [14.07454490800012, 0.44731101400015],
                [14.073198318999971, 0.429850997000131],
                [14.066365242000131, 0.419735997000089],
                [14.0602073660001, 0.416281014000106],
                [14.05131816900007, 0.407081991000041],
                [14.038085937, 0.404536009000083],
                [14.022584915000152, 0.390964001000157],
                [14.010264396000139, 0.388191015000189],
                [13.989515304000179, 0.376217992000136],
                [13.982682228000044, 0.365182995000055],
                [13.977667808000092, 0.362190008000141],
                [13.97129344900003, 0.350926995000179],
                [13.967434884000056, 0.336450011000125],
                [13.961502076000158, 0.336670994000031],
                [13.957404136000036, 0.329315990000055],
                [13.947825432000059, 0.325397998000085],
                [13.941678047000096, 0.315741002000038],
                [13.942140579000181, 0.310690999000087],
                [13.949221610000109, 0.307256013000142],
                [13.937829019000105, 0.296443998000029],
                [13.94491005000009, 0.2897930150001],
                [13.93487262800005, 0.288630992000151],
                [13.933735847000037, 0.285872995000148],
                [13.93579578400005, 0.282662004000088],
                [13.936027527000135, 0.278070003000039],
                [13.941976548000184, 0.267973006000091],
                [13.933995246999984, 0.26428499900004],
                [13.926009178000129, 0.26427400200015],
                [13.921234131000176, 0.253702999000041],
                [13.91257381500003, 0.24610899400011],
                [13.897754669000108, 0.238049999000054],
                [13.895934106000027, 0.235290006000071],
                [13.896401405000063, 0.228403003000153],
                [13.893674850000139, 0.22036199199999],
                [13.894366265000087, 0.215538994000099],
                [13.900766371999964, 0.210036993000074],
                [13.888682366000069, 0.202671007000049],
                [13.89736652300013, 0.192579],
                [13.906060218000107, 0.178127007000171],
                [13.905389786000057, 0.168248996000102],
                [13.912245750000181, 0.161831008000036],
                [13.9170484550001, 0.154489995000063],
                [13.926435470000058, 0.131311],
                [13.927821160000121, 0.120752],
                [13.923282622000102, 0.10283599800016],
                [13.914633752000043, 0.090651996000133],
                [13.914871216000165, 0.084224001000052],
                [13.921730995000075, 0.072984002000055],
                [13.921747208000113, 0.061732002000042],
                [13.923818588000074, 0.05048599800017],
                [13.928844453000124, 0.044752002000052],
                [13.934316634000027, 0.047975001000168],
                [13.941159248000076, 0.048675000000117],
                [13.953937531000179, 0.045706999000117],
                [13.95850658500018, 0.041352],
                [13.958520889000113, 0.03079],
                [13.953517913999974, 0.021369999000115],
                [13.949209211999971, 0.004375],
                [13.93354034500004, 0.000001000000168],
                [13.933109283000078, -0.00241],
                [13.934823036000182, -0.032248000999971],
                [13.930843354000046, -0.064632996999876],
                [13.926809310000067, -0.083476996999934],
                [13.915300368000146, -0.116117998999812],
                [13.909185408999974, -0.12716299199991],
                [13.893280984000057, -0.144438995999906],
                [13.88763141700008, -0.158464997999943],
                [13.885629652999967, -0.172021000999962],
                [13.886833191999983, -0.188088996999909],
                [13.895365714000093, -0.212170003999859],
                [13.904769897000051, -0.224537],
                [13.926512718000026, -0.242598995999913],
                [13.93955040000003, -0.25013199499989],
                [13.94615936200006, -0.24826900599993],
                [13.953620911000087, -0.230561002999821],
                [13.96089363200008, -0.22318500199998],
                [13.971152305000032, -0.221312001999877],
                [13.984849930000166, -0.22309899399994],
                [14.01866722200009, -0.235604001999889],
                [14.022788048, -0.23880399799998],
                [14.044504165000092, -0.248827992999907],
                [14.049769402000095, -0.253170012999817],
                [14.10098266599999, -0.278237998999941],
                [14.119737625000084, -0.289189009999916],
                [14.150184632000105, -0.312498001999927],
                [14.162807464000025, -0.329901009999958],
                [14.16811466200005, -0.344121008999934],
                [14.171389581000028, -0.363400011999943],
                [14.17230415400013, -0.363395004999973],
                [14.173010827000041, -0.42310899499995],
                [14.17673015600002, -0.439633010999898],
                [14.18290424300011, -0.442133008999974],
                [14.19202995300003, -0.441637991999869],
                [14.228965759000062, -0.432765989999893],
                [14.252927779000117, -0.432900010999958],
                [14.265050887000086, -0.439054012999975],
                [14.314429283000038, -0.45768800299993],
                [14.326305390000073, -0.459477006999919],
                [14.353691101000152, -0.459134995999932],
                [14.36488247, -0.460927007999885],
                [14.386141776999978, -0.46864798699994],
                [14.400787353000112, -0.477086006999912],
                [14.412258148000035, -0.49058899399995],
                [14.42964840000002, -0.518217026999878],
                [14.456320763000065, -0.543237983999973],
                [14.463206290000187, -0.551707028999942],
                [14.482548714000075, -0.588837981999859],
                [14.48510169900004, -0.598016976999929],
                [14.492943764000074, -0.615900992999968],
                [14.497133255000051, -0.633114992999879],
                [14.499524116999964, -0.657226024999886],
                [14.49872016900008, -0.680893003999927],
                [14.486551284000143, -0.716095983999878],
                [14.470913886, -0.741208015999916],
                [14.464839934000167, -0.761220990999902],
                [14.45862960800008, -0.801229],
                [14.440822601000093, -0.852544009999974],
                [14.430406571000162, -0.872577011999908],
                [14.415636063000022, -0.888037025999949],
                [14.410247803000061, -0.908969997999975],
                [14.419535636000148, -0.942941008999867],
                [14.422990798000058, -1.000607966999951],
                [14.43144226100003, -1.037559030999944],
                [14.447578430000135, -1.108101008999938],
                [14.465164184000173, -1.184985041999937],
                [14.470749856000111, -1.207947015999935],
                [14.480708123000113, -1.237324951999881],
                [14.485608100000093, -1.260061979999932],
                [14.482973099000105, -1.283519028999933],
                [14.472783089000075, -1.303562045999968],
                [14.459623337000096, -1.323616027999947],
                [14.453075408000018, -1.340885042999958],
                [14.452271462000112, -1.365028023999969],
                [14.457348823000018, -1.376037954999845],
                [14.467678069999977, -1.386569975999976],
                [14.495647430000133, -1.406911015999867],
                [14.500937461000035, -1.414703963999955],
                [14.501670838000052, -1.425276993999887],
                [14.498508454000046, -1.432878017999883],
                [14.49328231700008, -1.439337015999911],
                [14.47186660799997, -1.453681944999914],
                [14.466179847000092, -1.459455012999854],
                [14.462570190000122, -1.469812988999877],
                [14.464203834000102, -1.477162955999972],
                [14.48216629100017, -1.507665037999971],
                [14.485652924000078, -1.520526049999944],
                [14.485257148000187, -1.533630966999965],
                [14.480051040000035, -1.544690012999979],
                [14.47368812600007, -1.552535056999943],
                [14.450209618000088, -1.566200017999904],
                [14.410760880000112, -1.585677980999947],
                [14.3843002320001, -1.597054958999877],
                [14.377006531000177, -1.601683972999922],
                [14.374528885000132, -1.609971045999942],
                [14.376862526000025, -1.620769977999942],
                [14.381245612000157, -1.629948019999915],
                [14.391825675000064, -1.644621014999927],
                [14.402382850000038, -1.654692053999895],
                [14.413838387000112, -1.660853027999963],
                [14.421390533000078, -1.662889955999901],
                [14.438812257000052, -1.674085020999826],
                [14.44753932899999, -1.68324494299992],
                [14.451237679000144, -1.692425964999927],
                [14.448752403000015, -1.698644994999938],
                [14.441952704000073, -1.707285998999907],
                [14.426700592000032, -1.719686029999878],
                [14.420770644000186, -1.728654979999931],
                [14.416766167000048, -1.7532780169999],
                [14.41379165700016, -1.803879975999962],
                [14.404986382000175, -1.828060983999933],
                [14.401856423000083, -1.843711971999937],
                [14.403063774000088, -1.858654021999882],
                [14.400611877000188, -1.872002959999918],
                [14.398568154000088, -1.87569105599988],
                [14.386947632000158, -1.885167956999965],
                [14.352877618000093, -1.906270025999959],
                [14.313302993000093, -1.930775046999884],
                [14.304415702000085, -1.937940954999931],
                [14.302371978999986, -1.941167950999954],
                [14.294162751000044, -1.94603204699996],
                [14.257282258000089, -1.983204960999899],
                [14.25048255900009, -1.997262],
                [14.24737358100009, -2.018662929999891],
                [14.246977805000029, -2.033384084999966],
                [14.25544452700018, -2.08785796199993],
                [14.253233909000016, -2.105577944999936],
                [14.244582176000165, -2.114581106999879],
                [14.233171463000076, -2.120377063999911],
                [14.225195885000062, -2.127079962999915],
                [14.215912819000096, -2.149425982999844],
                [14.210917473000109, -2.157496927999887],
                [14.197225571000047, -2.165369033999923],
                [14.174155235000057, -2.1716709129999],
                [14.164114952000034, -2.17699909199996],
                [14.157983780000052, -2.187603950999971],
                [14.15640926399999, -2.194279908999818],
                [14.164477349000094, -2.208969114999889],
                [14.216944695000052, -2.284900902999937],
                [14.221342087000096, -2.296612976999882],
                [14.220227242000135, -2.30374693899995],
                [14.21363830600012, -2.314126014999943],
                [14.178985597000064, -2.342092990999959],
                [14.166023254000038, -2.360086916999933],
                [14.149820327999976, -2.391139983999949],
                [14.119722367000065, -2.4488298899999],
                [14.102894784000114, -2.473965882999892],
                [14.083021165000048, -2.481632946999866],
                [14.069264413000099, -2.474093913999923],
                [14.056163789000095, -2.459650991999979],
                [14.05019855500018, -2.455533026999945],
                [14.044479368999987, -2.455553054999939],
                [14.028527259000157, -2.470796108999934],
                [14.024641037000094, -2.47172999299994],
                [14.018448829000079, -2.468302964999964],
                [13.998000146000038, -2.446522951999896],
                [13.986778260000051, -2.443115949999935],
                [13.977628709000157, -2.443608044999962],
                [13.969632149000063, -2.446398018999958],
                [13.947727203000056, -2.461200951999956],
                [13.93721580499999, -2.464457988999868],
                [13.922572136000156, -2.464971065999976],
                [13.891647340000077, -2.454037903999961],
                [13.885454177000042, -2.45015191899995],
                [13.871903419999967, -2.436167000999944],
                [13.868673326000078, -2.428817033999962],
                [13.868412971000112, -2.420536040999934],
                [13.870670319, -2.412477015999968],
                [13.874527930000056, -2.404871939999907],
                [13.883179665000114, -2.393569945999957],
                [13.904601097000125, -2.372560977999967],
                [13.916443824000055, -2.358716964999928],
                [13.918901444000028, -2.343755005999981],
                [13.916576385000099, -2.333872079999935],
                [13.913357735000034, -2.329745054999876],
                [13.89224815300014, -2.313257931999885],
                [13.886726379000152, -2.304534910999962],
                [13.880380630000104, -2.260624885999903],
                [13.849785805000067, -2.215728996999928],
                [13.83691120200018, -2.196831940999857],
                [13.813878060000093, -2.153439997999953],
                [13.79047107600013, -2.132359980999979],
                [13.77097988100013, -2.118168114999946],
                [13.75815486900018, -2.11337995599996],
                [13.753583908000053, -2.113856075999934],
                [13.74880409200017, -2.120312927999919],
                [13.729271889000074, -2.156259059999968],
                [13.724718093000092, -2.162252902999967],
                [13.691445351000084, -2.1952550399999],
                [13.67482566700005, -2.216932057999941],
                [13.644985199000189, -2.250611066999966],
                [13.638158799000053, -2.261214017999976],
                [13.628816604000065, -2.270673988999874],
                [13.591691971000103, -2.316566943999931],
                [13.583941459000073, -2.324412106999887],
                [13.571192741000061, -2.342164038999897],
                [13.53610801700006, -2.38414096799994],
                [13.506067277000113, -2.429549931999929],
                [13.50309658000009, -2.431169031999957],
                [13.492809296000132, -2.43350005099984],
                [13.484335899000087, -2.430535077999934],
                [13.470807074999982, -2.421144961999971],
                [13.453857423000045, -2.414987086999929],
                [13.442415237000091, -2.414099931999942],
                [13.430522919000055, -2.415285109999957],
                [13.39990329699998, -2.426188945999854],
                [13.391440391, -2.426671981999959],
                [13.384799004, -2.424621104999972],
                [13.374033927000085, -2.420743940999898],
                [13.356147767000039, -2.407222032999925],
                [13.336440086000039, -2.396927117999894],
                [13.332077979000132, -2.392338989999871],
                [13.316273687999967, -2.385943889999908],
                [13.263179780000087, -2.379193066999903],
                [13.250124931000016, -2.374166965999905],
                [13.239811898000028, -2.367753982999886],
                [13.231780052000147, -2.359266042999877],
                [13.22326278700001, -2.34203600799998],
                [13.215930939000032, -2.337915896999959],
                [13.209986687000082, -2.339313029999914],
                [13.199728012000094, -2.351300954999942],
                [13.189031601000124, -2.369271993999973],
                [13.18196010700018, -2.376421928999946],
                [13.17556476499999, -2.379430054999887],
                [13.166412353000169, -2.378763913999933],
                [13.15541839600013, -2.373732090999965],
                [13.130442620000053, -2.360225914999887],
                [13.121731757000077, -2.353807924999899],
                [13.088988305000157, -2.342852115999904],
                [13.072291375000134, -2.343122958999913],
                [13.05717754400007, -2.337412118999907],
                [13.047794342000145, -2.336515903999953],
                [13.040926933000094, -2.333771942999931],
                [13.031063081000184, -2.324595927999894],
                [13.026918411999986, -2.314255953999975],
                [13.034790038000097, -2.266851901999928],
                [13.033831596000027, -2.251672983999811],
                [13.025535584000181, -2.229840992999868],
                [13.007162094000137, -2.202286004999905],
                [12.998681069000156, -2.195175885999959],
                [12.966623306000088, -2.180994986999906],
                [12.930646895999985, -2.155087947999959],
                [12.913217544000133, -2.137190103999956],
                [12.9033451090001, -2.122034071999963],
                [12.885210037000093, -2.099488019999853],
                [12.881966592000083, -2.080452917999878],
                [12.875733375000095, -2.058388948999948],
                [12.873844146000181, -2.035393953999972],
                [12.86944294000017, -2.013556002999849],
                [12.864144324000165, -1.997928022999872],
                [12.856788635000044, -1.983224986999971],
                [12.847337722000134, -1.953119993999962],
                [12.841585160000022, -1.939563988999851],
                [12.82897472400009, -1.92602300599998],
                [12.779511451000133, -1.893707036999899],
                [12.763475417000052, -1.88132500699993],
                [12.74332714000019, -1.868949054999916],
                [12.73325824799997, -1.86483097099989],
                [12.722053528000174, -1.864395020999893],
                [12.68549823799998, -1.874359965999815],
                [12.683674813000039, -1.876664040999856],
                [12.650080681000077, -1.882019996999873],
                [12.626068116000056, -1.879080056999896],
                [12.614400864000118, -1.875424027999941],
                [12.590827942000146, -1.864429949999931],
                [12.571838379000042, -1.858260035999876],
                [12.561779976000139, -1.859199047999937],
                [12.556307792000155, -1.866106985999977],
                [12.550407410000105, -1.884976027999869],
                [12.543574333000038, -1.89648794999988],
                [12.535122872000045, -1.900872945999879],
                [12.520722389000184, -1.901360987999965],
                [12.485028267000018, -1.888546942999938],
                [12.471508026000038, -1.87431395099992],
                [12.462126731000183, -1.869961021999927],
                [12.445437431000073, -1.869990943999881],
                [12.444073677, -1.874595045999968],
                [12.467311859, -1.943542956999977],
                [12.47102737300014, -1.97067403899996],
                [12.470615386000077, -1.991832970999951],
                [12.47524642899998, -2.018961905999959],
                [12.482375145000049, -2.038499116999958],
                [12.494083404000094, -2.059406996999883],
                [12.519983292000063, -2.086726903999931],
                [12.523661614000105, -2.095231055999875],
                [12.522105217000046, -2.115931987999829],
                [12.510764123000115, -2.158734083999946],
                [12.51260566700006, -2.164942979999864],
                [12.527290344000164, -2.186074971999972],
                [12.532154083000137, -2.213896035999881],
                [12.528741837000098, -2.221952915999964],
                [12.504795075000061, -2.253969906999942],
                [12.497048378000102, -2.268013953999969],
                [12.497986794000099, -2.27951288099996],
                [12.511749269000063, -2.296741007999913],
                [12.52666568800015, -2.318794011999898],
                [12.529197694000061, -2.326380966999977],
                [12.52077865700005, -2.346637963999967],
                [12.506184577999989, -2.368058919999896],
                [12.501635552000039, -2.379796981999959],
                [12.506054879000033, -2.413145064999981],
                [12.502870560000076, -2.422353028999964],
                [12.49761295400009, -2.423970936999922],
                [12.468323707000081, -2.421263932999921],
                [12.351439477000042, -2.420353887999966],
                [12.299260139000125, -2.419941900999959],
                [12.284621238000113, -2.421345948999885],
                [12.225689887000158, -2.421308994999947],
                [12.176639556000055, -2.421277045999943],
                [12.14805603100018, -2.427989005999905],
                [12.110090254999989, -2.434252023999875],
                [12.09591293300008, -2.43933296199998],
                [12.092252731000087, -2.439337014999978],
                [12.077589035000074, -2.425324915999966],
                [12.069555283000057, -2.410382985999888],
                [12.064732552000066, -2.397737980999921],
                [12.057619094000188, -2.386017083999946],
                [12.048460007000074, -2.379585027999951],
                [12.022570611000106, -2.35454606899998],
                [12.00539493600013, -2.342375993999951],
                [11.996698379000122, -2.339857100999893],
                [11.974740982000128, -2.341264008999929],
                [11.940225600000133, -2.358325003999937],
                [11.914856911000129, -2.374685047999947],
                [11.904110908000177, -2.378609894999897],
                [11.891985892000037, -2.378163098999949],
                [11.867042541000103, -2.371516941999914],
                [11.858119965000071, -2.371527909999941],
                [11.84760761299998, -2.378669021999826],
                [11.831138610999972, -2.3807559],
                [11.820854187000123, -2.387437104999833],
                [11.806702613000027, -2.410913942999969],
                [11.794379234000075, -2.435539959999971],
                [11.775189399000112, -2.456491946999961],
                [11.771301269000105, -2.456957100999944],
                [11.76191520700013, -2.453516006999962],
                [11.740162850000047, -2.437661885999944],
                [11.729626656000107, -2.426861047999864],
                [11.710824012999979, -2.391912937999962],
                [11.69776153600003, -2.372829913999965],
                [11.678981781000061, -2.353524923999942],
                [11.666390419000095, -2.344563960999949],
                [11.648542404000125, -2.339287996999872],
                [11.622213363000071, -2.320446013999913],
                [11.613064767000026, -2.319992064999894],
                [11.608720779000123, -2.322757004999971],
                [11.607123374000025, -2.325057029999869],
                [11.609674452999968, -2.356797932999939],
                [11.61380100200006, -2.367377995999959],
                [11.632357596000134, -2.391056059999926],
                [11.650920868000071, -2.421405076999974],
                [11.653219224000168, -2.430376052999975],
                [11.653006554000171, -2.444638012999974],
                [11.641609192000033, -2.480072020999955],
                [11.637057304000166, -2.504000901999973],
                [11.636161804000153, -2.520796059999896],
                [11.65336704300006, -2.560580013999868],
                [11.661868096000092, -2.591169119999961],
                [11.668976784000108, -2.605427025999973],
                [11.685250282000084, -2.627727984999979],
                [11.696257591, -2.647274015999926],
                [11.702683449000062, -2.662913083999854],
                [11.703841209000075, -2.673032997999883],
                [11.701567649000083, -2.687067985999931],
                [11.696537971000112, -2.691215992999958],
                [11.686241151000104, -2.692373989999851],
                [11.668389320000188, -2.689167021999879],
                [11.656028748000097, -2.688947915999961],
                [11.648024558000088, -2.691945074999978],
                [11.641394615000138, -2.698163032999844],
                [11.637987137000096, -2.721169948999886],
                [11.632288933000098, -2.742572068999948],
                [11.620648385000095, -2.769037006999952],
                [11.616085053000177, -2.784224985999913],
                [11.617465973000037, -2.789746999999863],
                [11.622739793000108, -2.798945903999879],
                [11.630071639000107, -2.805843113999913],
                [11.642436028000077, -2.810893057999977],
                [11.655950547000145, -2.820775031999858],
                [11.6728992460001, -2.829735040999935],
                [11.688469886000064, -2.835704087999886],
                [11.711589814000035, -2.837754964999931],
                [11.760593415000187, -2.853126049999958],
                [11.796538352000027, -2.861373900999922],
                [11.866622924000126, -2.892132997999966],
                [11.894560813000055, -2.900156020999873],
                [11.938308715000062, -2.919662952999886],
                [12.011150361000034, -2.948796987999913],
                [12.043212891000053, -2.956347941999979],
                [12.056954384999983, -2.961621998999874],
                [12.066580773000169, -2.969432114999961],
                [12.068427085000167, -2.977482079999959],
                [12.065691947000175, -2.986459969999942],
                [12.05062484800004, -3.011096954999914],
                [12.030320167000013, -3.055761097999948],
                [12.024854661000063, -3.074404953999931],
                [12.018236161000118, -3.087987899999973],
                [12.013502121000101, -3.135162115999833],
                [12.01076698300011, -3.142299889999947],
                [12.002311707000047, -3.153124092999917],
                [11.991331100000025, -3.15957999199992],
                [11.971418381999968, -3.16627693099997],
                [11.947383881000064, -3.171828030999905],
                [11.939379693000035, -3.178281068999979],
                [11.935043335000103, -3.187948941999821],
                [11.936654090000047, -3.193928956999969],
                [11.943539620000081, -3.204273938999904],
                [11.965310096000167, -3.214373111999976],
                [12.001762391000057, -3.24194192899995],
                [12.022211074000097, -3.284483909999949],
                [12.043340682000064, -3.323575972999947],
                [12.048404694000055, -3.339445113999886],
                [12.045451164000099, -3.352566002999879],
                [12.027865409000128, -3.383651017999966],
                [12.007069587000046, -3.414971112999979],
                [11.958836555000175, -3.478311060999886],
                [11.943277358000046, -3.48937392199997],
                [11.919697761000123, -3.498833894999905],
                [11.89818000800011, -3.510824918999958],
                [11.887432099000137, -3.522802114999934],
                [11.880576133000034, -3.535695074999921],
                [11.876952170000152, -3.56492209399994],
                [11.87788677200001, -3.576885936999929],
                [11.889360427000156, -3.59091091199997],
                [11.90449333100014, -3.599407909999854],
                [11.9404859550001, -3.615475892999882],
                [11.946452142000169, -3.620990990999871],
                [11.94783306200003, -3.626283883999918],
                [11.914035798000157, -3.702949046999947],
                [11.895748138000158, -3.734263896999948],
                [11.88431358300005, -3.747391938999954],
                [11.876068116, -3.750394104999941],
                [11.862773896000135, -3.747415065999974],
                [11.829981804000113, -3.729270934999931],
                [11.7928390510001, -3.712049960999877],
                [11.786647796000068, -3.707683085999975],
                [11.713526725000179, -3.684278011999936],
                [11.702751160000048, -3.677154062999875],
                [11.696326256000134, -3.670717953999883],
                [11.680949211000154, -3.646797895999953],
                [11.677510262000055, -3.644270896999956],
                [11.669245720000163, -3.632309913999904],
                [11.646289826000043, -3.590678930999957],
                [11.63160324099999, -3.570209978999912],
                [11.625873565000063, -3.566764115999945],
                [11.621282577000159, -3.560555934999968],
                [11.609590530000048, -3.551820039999882],
                [11.585523606000038, -3.538721083999917],
                [11.564666748999969, -3.52953004799997],
                [11.53739261600009, -3.513901947999841],
                [11.529373168, -3.512758016999953],
                [11.519759178000186, -3.518515109999953],
                [11.508086205000097, -3.529798982999864],
                [11.48680782300005, -3.556732892999946],
                [11.458426476, -3.583903073999863],
                [11.442164, -3.589665],
                [11.428418, -3.590132],
                [11.407796861000179, -3.587151050999921],
                [11.393135071000131, -3.586930035999956],
                [11.361526, -3.595228],
                [11.345494271000064, -3.603519915999925],
                [11.334276198999987, -3.61387801099994],
                [11.302922249000119, -3.657380103999969],
                [11.26995, -3.688458],
                [11.256214143000136, -3.705950974999894],
                [11.249577000000102, -3.718149],
                [11.24501037500005, -3.742311953999888],
                [11.245481000000154, -3.764861999999823],
                [11.251470566000137, -3.811805009999887],
                [11.249189000000172, -3.826533],
                [11.227897644000109, -3.858985900999926],
                [11.22424221, -3.878774880999913],
                [11.22723484, -3.89879488899993],
                [11.227008819000048, -3.907537935999926],
                [11.223579, -3.917664],
                [11.21350288400015, -3.932163952999929],
                [11.212369918000036, -3.955177068999944],
                [11.205005799000105, -3.978806860999953],
                [11.204929, -3.978802],
                [11.200858, -3.990695],
                [11.202083589000097, -3.991528986999981],
                [11.20374965700006, -3.993195056999923],
                [11.20541572500008, -3.993751048999911],
                [11.206528664000075, -3.995138883999971],
                [11.20819473299997, -3.995695114999933],
                [11.211251259000107, -3.997916935999967],
                [11.212917329000106, -3.999583004999977],
                [11.21597099200011, -4.002082823999956],
                [11.221528053000043, -4.004583835999938],
                [11.221805573000097, -4.005417822999959],
                [11.223471641000117, -4.005971908999868],
                [11.224862099000063, -4.007361888999981],
                [11.226528167000083, -4.007915972999967],
                [11.226528167000083, -4.008749961999968],
                [11.227640152000163, -4.009305952999966],
                [11.227640152000163, -4.010138033999965],
                [11.22875022900007, -4.012084007999874],
                [11.230137826000032, -4.012916086999951],
                [11.232082367000089, -4.014582156999893],
                [11.232915879000132, -4.015694141999973],
                [11.234304429000076, -4.016529082999966],
                [11.23652744300017, -4.01708221399997],
                [11.23930454300006, -4.019305228999883],
                [11.239860534000172, -4.019305228999883],
                [11.241805077000038, -4.021526812999923],
                [11.244027137000046, -4.022916792999922],
                [11.244583130000137, -4.023748873999921],
                [11.245973588000027, -4.024304865999909],
                [11.246806145000107, -4.025138854999909],
                [11.24902820599999, -4.026526926999907],
                [11.250140191000071, -4.026526926999907],
                [11.253193856000053, -4.02986192599991],
                [11.254305839000153, -4.030139923999911],
                [11.255971909000152, -4.031250000999933],
                [11.256527900000037, -4.032083987999897],
                [11.25847244300013, -4.033750057999896],
                [11.25986003900016, -4.034306048999895],
                [11.262916565000126, -4.037362097999903],
                [11.266806602000031, -4.039857863999885],
                [11.268195152000146, -4.0412502289999],
                [11.27013874, -4.042637824999872],
                [11.271248817000128, -4.042915819999962],
                [11.272360802000037, -4.044303892999892],
                [11.272917748000111, -4.044303892999892],
                [11.27402687, -4.045694827999967],
                [11.275415420000172, -4.046528816999967],
                [11.278750419000119, -4.049582957999974],
                [11.280415535000088, -4.051527022999949],
                [11.282637595999972, -4.05236101],
                [11.284028054000089, -4.053749084999879],
                [11.284028054000089, -4.054305075999878],
                [11.289029121000112, -4.057918070999961],
                [11.290695191000111, -4.058750151999959],
                [11.292917252, -4.060416221999958],
                [11.294029235000096, -4.061806202999946],
                [11.295970916000044, -4.063471792999906],
                [11.296805382, -4.064861773999894],
                [11.300417899000024, -4.067915915999834],
                [11.301527976000159, -4.067915915999834],
                [11.303471565000109, -4.065415858999927],
                [11.304862022000123, -4.065415858999927],
                [11.305137635000108, -4.067083834999892],
                [11.302915574, -4.067083834999892],
                [11.302083970000126, -4.067915915999834],
                [11.301806449000026, -4.069306848999872],
                [11.303471565000109, -4.071250914999894],
                [11.304306031000067, -4.071528910999973],
                [11.307915688000037, -4.07541704099998],
                [11.30930614500005, -4.07625103],
                [11.311249732000022, -4.078473090999978],
                [11.312915802000191, -4.079861163999908],
                [11.314304351999965, -4.080417154999907],
                [11.318195342000024, -4.083470820999878],
                [11.320417404000068, -4.085971832999974],
                [11.322360992000085, -4.08874988499997],
                [11.323748588000171, -4.089861869999936],
                [11.324583054000072, -4.091249941999934],
                [11.326526642000147, -4.092083930999934],
                [11.328195571000151, -4.094305990999942],
                [11.331250191000095, -4.096529006999958],
                [11.33291626, -4.098473070999944],
                [11.333521843000085, -4.098693846999822],
                [11.335971833000144, -4.10152721399993],
                [11.338193894000028, -4.103748797999913],
                [11.339859962000048, -4.104582786999913],
                [11.341528893000032, -4.106248854999933],
                [11.342917442999976, -4.108194828999899],
                [11.344306946000131, -4.109026907999976],
                [11.347916603000101, -4.112915992999888],
                [11.349305153000046, -4.112361906999979],
                [11.35124874100012, -4.112361906999979],
                [11.35124874100012, -4.114027976999978],
                [11.348195076000138, -4.113749981999888],
                [11.348472596000022, -4.114861963999886],
                [11.3495826730001, -4.115694044999884],
                [11.35124874100012, -4.118194102999894],
                [11.352083205000042, -4.118194102999894],
                [11.35319328200012, -4.120138168999972],
                [11.356528281000124, -4.1234722129999],
                [11.356528281000124, -4.124029158999974],
                [11.357917786000087, -4.125417232999894],
                [11.359583854000107, -4.128750799999921],
                [11.36041641300011, -4.129026889999864],
                [11.362084389000131, -4.130694865999942],
                [11.362360002000116, -4.131526946999941],
                [11.365415573000064, -4.134027003999961],
                [11.366250039000022, -4.135694979999926],
                [11.368194580000022, -4.137362002999907],
                [11.36847305300006, -4.138471124999967],
                [11.369860648000042, -4.139306068999929],
                [11.370972633000122, -4.140972136999949],
                [11.37263870300012, -4.142638205999958],
                [11.374029159000088, -4.144584178999935],
                [11.374583244000178, -4.145971773999975],
                [11.374860763000129, -4.147915839999882],
                [11.375970840000036, -4.149305819999881],
                [11.376251219999972, -4.151528834999965],
                [11.376251219999972, -4.154028891999872],
                [11.375695229000087, -4.155138968999893],
                [11.375695229000087, -4.157083034999971],
                [11.374860763000129, -4.15847301499997],
                [11.373473167999975, -4.159861087999957],
                [11.374029159000088, -4.161249159999898],
                [11.373473167999975, -4.163749216999975],
                [11.375139236000166, -4.165972231999888],
                [11.375695229000087, -4.167362213999979],
                [11.375695229000087, -4.175415991999955],
                [11.37541675600005, -4.176249980999955],
                [11.373194694000063, -4.178472994999936],
                [11.373194694000063, -4.179304122999952],
                [11.374583244000178, -4.180139064999935],
                [11.376805306000165, -4.182639121999841],
                [11.376805306000165, -4.18347310899992],
                [11.377917291000074, -4.184305190999851],
                [11.37819480900015, -4.185139178999918],
                [11.379304886000057, -4.18597316599994],
                [11.379304886000057, -4.187082767999868],
                [11.380417823000016, -4.188195227999927],
                [11.381249427000114, -4.190138815999887],
                [11.381806373000188, -4.190417766999872],
                [11.382914543000027, -4.192639827999869],
                [11.384305955000059, -4.193749904999891],
                [11.38458442600006, -4.194861887999878],
                [11.385693549999985, -4.196527957999876],
                [11.385693549999985, -4.198194025999953],
                [11.386249541000097, -4.198750019999977],
                [11.387084008000102, -4.201250076999884],
                [11.387084008000102, -4.203472135999959],
                [11.388471603000085, -4.204860210999868],
                [11.388471603000085, -4.205695152999965],
                [11.38986015300003, -4.207361220999871],
                [11.394584657000166, -4.212082861999932],
                [11.395973205000132, -4.21374893199993],
                [11.398751259000107, -4.214582918999952],
                [11.399861336000015, -4.215416907999838],
                [11.402360916000077, -4.217917917999898],
                [11.404026984000097, -4.2181959159999],
                [11.404026984000097, -4.219583987999897],
                [11.406249046000084, -4.222084045999907],
                [11.407917977000068, -4.222362040999826],
                [11.409861565000142, -4.224306106999904],
                [11.411527633000105, -4.225416183999926],
                [11.41374969500015, -4.227637767999966],
                [11.414305686000034, -4.227637767999966],
                [11.415971756000033, -4.229581831999894],
                [11.41819381800002, -4.23097181199995],
                [11.420138359000077, -4.231803893999881],
                [11.422638894000102, -4.234304905999977],
                [11.423194885000044, -4.235416887999975],
                [11.424027444000103, -4.235416887999975],
                [11.425139426000044, -4.236804962999884],
                [11.42791652600016, -4.238472938999962],
                [11.429026603000068, -4.23986101099996],
                [11.431527138000149, -4.240971087999981],
                [11.432360649000088, -4.24236106799998],
                [11.434862137000096, -4.243749140999967],
                [11.436249734000057, -4.245971202999954],
                [11.437084198000036, -4.246528148999971],
                [11.438750267000103, -4.248472214999879],
                [11.439583778000042, -4.248472214999879],
                [11.440416337000102, -4.249584196999933],
                [11.442640304000179, -4.250693798999919],
                [11.44319439000003, -4.25152778599994],
                [11.44680595400007, -4.254583835999824],
                [11.448193549000052, -4.255137919999925],
                [11.44930362600013, -4.256249903999901],
                [11.452082634000021, -4.257915972999911],
                [11.45291710000015, -4.259028911999906],
                [11.45458316800017, -4.259582042999909],
                [11.456251144000134, -4.261250972999903],
                [11.456251144000134, -4.262083052999913],
                [11.457917214000133, -4.263195036999889],
                [11.459027291000098, -4.263473032999912],
                [11.464028358000064, -4.267083166999896],
                [11.464584350000052, -4.268195151999976],
                [11.46569442700013, -4.268470763999972],
                [11.467639923000036, -4.269861221999918],
                [11.470693588000188, -4.273192882999979],
                [11.47263813000012, -4.274583815999961],
                [11.47347164100006, -4.275693892999868],
                [11.475137711000059, -4.27624988599996],
                [11.475973128000021, -4.277361868999947],
                [11.477639199000123, -4.278193949999945],
                [11.48041725100012, -4.280972002999874],
                [11.481804849000184, -4.281527995999966],
                [11.485138893000169, -4.284028052999929],
                [11.487360954000053, -4.286529062999932],
                [11.488196373000164, -4.286529062999932],
                [11.489862442000117, -4.288195133999864],
                [11.490971566000042, -4.288473128999954],
                [11.492637634, -4.289861201999941],
                [11.493193627000096, -4.291526794999925],
                [11.494028092000121, -4.291526794999925],
                [11.495694160000141, -4.2931947699999],
                [11.497083665000105, -4.293748855999922],
                [11.497916221000082, -4.294860838999909],
                [11.49930572400018, -4.295416831999887],
                [11.500415801000088, -4.297082899999907],
                [11.50180435100009, -4.297638893999931],
                [11.502917290000028, -4.29930591699997],
                [11.50541687000009, -4.300971984999933],
                [11.50736141200008, -4.301805973999819],
                [11.509583473000134, -4.304028032999895],
                [11.510695458000043, -4.304584026999976],
                [11.514582635000068, -4.307362078999972],
                [11.515971185000012, -4.30930614499988],
                [11.517640113000084, -4.3109722129999],
                [11.520972252000035, -4.313195227999813],
                [11.521528243000091, -4.314303874999951],
                [11.522915840000053, -4.315416811999967],
                [11.523750305000078, -4.316804886999933],
                [11.524581909000176, -4.316804886999933],
                [11.526249886000073, -4.318748949999872],
                [11.526805878000062, -4.318748949999872],
                [11.529028892000156, -4.320694923999952],
                [11.52958202300016, -4.321805000999973],
                [11.530694962000155, -4.321805000999973],
                [11.53125095300004, -4.322916982999857],
                [11.533473015000027, -4.324027059999935],
                [11.53597259500009, -4.326527117999945],
                [11.537361145000034, -4.328194140999926],
                [11.53819465600003, -4.328472135999959],
                [11.538748742, -4.329584120999925],
                [11.540139198000134, -4.330694197999947],
                [11.542082786000037, -4.331250190999924],
                [11.543471336000152, -4.33291578199993],
                [11.547639846000095, -4.337083816999893],
                [11.54986, -4.339028835999955],
                [11.550415994000105, -4.339028835999955],
                [11.552362443000106, -4.340416907999895],
                [11.553194046000101, -4.341528892999975],
                [11.55430603100018, -4.341804980999882],
                [11.555137635000051, -4.342916964999972],
                [11.557639122000182, -4.34486103099988],
                [11.557639122000182, -4.345973013999981],
                [11.558751107000091, -4.346527099999889],
                [11.55986118400017, -4.348195074999978],
                [11.561529160000191, -4.349305151999886],
                [11.562916755000117, -4.350971222999931],
                [11.56347084100014, -4.350971222999931],
                [11.565417289000095, -4.352918148999947],
                [11.567639351000082, -4.35374879799997],
                [11.569862365000176, -4.355418205999968],
                [11.569862365000176, -4.357083796999916],
                [11.572361947000047, -4.358749866999858],
                [11.572361947000047, -4.359305857999914],
                [11.57374954200003, -4.360415934999935],
                [11.57486152700011, -4.360693930999958],
                [11.57680606800011, -4.362915992999945],
                [11.57736206100003, -4.362915992999945],
                [11.58041572500008, -4.365416049999965],
                [11.580694198000117, -4.366250990999845],
                [11.581804275000081, -4.366528986999924],
                [11.582916259000058, -4.368751048999911],
                [11.586528779000162, -4.371805190999965],
                [11.588748933000034, -4.37402677599988],
                [11.59152698500003, -4.376248836999821],
                [11.593193054000096, -4.377916812999956],
                [11.596527100000117, -4.38013982699988],
                [11.597914695000043, -4.381805896999879],
                [11.60264015100006, -4.386250018999874],
                [11.604027749000124, -4.386806011999965],
                [11.60458374000001, -4.387916088999873],
                [11.605693817000144, -4.388194083999963],
                [11.606249810000065, -4.389306068999872],
                [11.608194351000066, -4.390138147999892],
                [11.608471870000017, -4.391250132999971],
                [11.610137940000072, -4.39208221399997],
                [11.611525535, -4.393194198999879],
                [11.612916945000052, -4.395139216999951],
                [11.614028930000131, -4.395695208999882],
                [11.615416527000093, -4.397360801999923],
                [11.616526604000171, -4.397638796999956],
                [11.621249198000157, -4.401248930999941],
                [11.624584197000104, -4.404860972999927],
                [11.625694274000011, -4.40513896899995],
                [11.629859924000073, -4.409028052999929],
                [11.631806373000131, -4.409862041999929],
                [11.633193970000093, -4.411806105999915],
                [11.635693550000155, -4.413472175999914],
                [11.636251449000042, -4.414584158999901],
                [11.638472558000046, -4.415694235999922],
                [11.638751031000083, -4.416806219999899],
                [11.6406946190001, -4.417915819999962],
                [11.64097309000016, -4.418471812999883],
                [11.642917, -4.41986],
                [11.643473, -4.41986],
                [11.64597300000014, -4.421529],
                [11.647361, -4.423472999999888],
                [11.648471, -4.423750999999868],
                [11.64930500000014, -4.424861],
                [11.65097100000014, -4.426251],
                [11.651806000000136, -4.426251],
                [11.65264, -4.427361],
                [11.654306, -4.428195],
                [11.657362, -4.430970999999886],
                [11.659582000000114, -4.431805],
                [11.660972000000186, -4.433471],
                [11.662638000000186, -4.434306],
                [11.66458200000011, -4.436528],
                [11.667083000000105, -4.438194],
                [11.668195, -4.438472],
                [11.6720830000001, -4.441528],
                [11.673195, -4.443194],
                [11.67708400000015, -4.445416],
                [11.679862000000128, -4.446807],
                [11.680418000000145, -4.448473],
                [11.682916, -4.449583],
                [11.683194000000128, -4.450139],
                [11.684862000000123, -4.450695],
                [11.685972000000163, -4.452083],
                [11.686806, -4.452361],
                [11.687916, -4.454305],
                [11.689304000000163, -4.455973],
                [11.689304000000163, -4.457639],
                [11.690695000000119, -4.457916999999895],
                [11.69375100000019, -4.460692999999878],
                [11.694305, -4.462083999999891],
                [11.695417000000191, -4.463193999999874],
                [11.697083000000191, -4.464027999999871],
                [11.698751000000186, -4.465415999999891],
                [11.699583000000132, -4.465415999999891],
                [11.701249000000132, -4.466806],
                [11.702918000000182, -4.467084],
                [11.703471, -4.467916],
                [11.704584000000182, -4.468193999999869],
                [11.705972, -4.469306],
                [11.707638, -4.469859999999869],
                [11.708472, -4.470972],
                [11.710637, -4.472282999999891],
                [11.710972, -4.473472],
                [11.712084, -4.473751],
                [11.714028000000155, -4.473472],
                [11.71652900000015, -4.473472],
                [11.717082, -4.475139],
                [11.716250000000173, -4.475973],
                [11.715972, -4.477083],
                [11.714584000000173, -4.478473],
                [11.712916000000178, -4.478473],
                [11.712362000000155, -4.477639],
                [11.711250000000177, -4.47736099999986],
                [11.71014, -4.478195],
                [11.7115280000001, -4.478751],
                [11.712084, -4.479861],
                [11.713472, -4.480417],
                [11.714584000000173, -4.481805],
                [11.71652900000015, -4.482639],
                [11.717917, -4.484305],
                [11.718751000000168, -4.484583],
                [11.720139, -4.485971],
                [11.720695, -4.485971],
                [11.722639000000129, -4.487915],
                [11.723195000000146, -4.487915],
                [11.724861000000146, -4.489862],
                [11.730696000000137, -4.494584],
                [11.731249, -4.494584],
                [11.73375, -4.497082],
                [11.736528, -4.499027999999896],
                [11.739306, -4.502082],
                [11.742638, -4.504861],
                [11.744307000000106, -4.505972999999869],
                [11.745417, -4.507361],
                [11.749305000000106, -4.511249],
                [11.749861, -4.511249],
                [11.756249, -4.517638],
                [11.757640000000151, -4.51875],
                [11.757640000000151, -4.519306],
                [11.759862000000112, -4.521528],
                [11.76125, -4.523472],
                [11.764028, -4.525972],
                [11.764306000000147, -4.526528],
                [11.768750000000125, -4.530973],
                [11.770138, -4.532916999999884],
                [11.776251000000116, -4.539027],
                [11.777083, -4.540139],
                [11.777639, -4.541806],
                [11.781249000000116, -4.54541799999987],
                [11.781805, -4.546249999999873],
                [11.784305000000188, -4.547915999999873],
                [11.786806000000183, -4.550694],
                [11.787084, -4.552084],
                [11.790416, -4.555417],
                [11.793194, -4.559027],
                [11.795138000000179, -4.560139],
                [11.795138000000179, -4.561251],
                [11.796250000000157, -4.562360999999839],
                [11.797082, -4.562360999999839],
                [11.798473, -4.56402699999984],
                [11.798751000000152, -4.565139],
                [11.801251, -4.567360999999892],
                [11.80152900000013, -4.568749],
                [11.803751000000148, -4.570972],
                [11.803751000000148, -4.571806],
                [11.805139, -4.573193999999887],
                [11.806249, -4.575693999999885],
                [11.806249, -4.576527999999883],
                [11.807639000000108, -4.577638],
                [11.808473, -4.579582],
                [11.809861000000126, -4.58069399999988],
                [11.809861000000126, -4.581528999999875],
                [11.810971000000109, -4.583194999999876],
                [11.81264, -4.584860999999876],
                [11.81375, -4.586805],
                [11.814306, -4.588473],
                [11.814862000000176, -4.588749],
                [11.817084, -4.591805],
                [11.819028000000117, -4.595971],
                [11.8201380000001, -4.599028],
                [11.82125, -4.600971999999899],
                [11.822084000000189, -4.601805999999897],
                [11.822084000000189, -4.602916],
                [11.824029000000166, -4.60625],
                [11.824860000000172, -4.609861],
                [11.825417, -4.61013899999989],
                [11.826251000000184, -4.612082999999814],
                [11.825695000000167, -4.616248999999868],
                [11.825695000000167, -4.62264],
                [11.825417, -4.625694],
                [11.824582, -4.62875],
                [11.823194000000171, -4.63125],
                [11.822360000000117, -4.634307],
                [11.81875, -4.636804],
                [11.81764, -4.638195],
                [11.816806, -4.640417],
                [11.814584, -4.644305],
                [11.814028000000121, -4.644582999999898],
                [11.812918, -4.647082999999896],
                [11.812918, -4.648193],
                [11.812084, -4.648748999999896],
                [11.811249, -4.651249999999891],
                [11.809305000000109, -4.652915999999891],
                [11.80819300000013, -4.653193999999871],
                [11.805973000000108, -4.655972],
                [11.805973000000108, -4.656528],
                [11.801805, -4.659584],
                [11.800417000000152, -4.660416],
                [11.798751000000152, -4.660416],
                [11.797916000000157, -4.65986],
                [11.79569400000014, -4.65986],
                [11.793472000000179, -4.659306],
                [11.792916000000162, -4.65875],
                [11.790416, -4.658194],
                [11.788472000000183, -4.658194],
                [11.788194, -4.65875],
                [11.786250000000166, -4.65875],
                [11.785140000000183, -4.659306],
                [11.781527, -4.659306],
                [11.781249000000116, -4.660138],
                [11.781527, -4.661807],
                [11.781249000000116, -4.665139],
                [11.781249000000116, -4.667917],
                [11.78208300000017, -4.668473],
                [11.78208300000017, -4.669583],
                [11.782917000000111, -4.670139],
                [11.783471, -4.672361],
                [11.784584000000166, -4.674027],
                [11.784862, -4.675971],
                [11.784862, -4.67875],
                [11.785140000000183, -4.680139999999824],
                [11.786250000000166, -4.682084],
                [11.787638, -4.68375],
                [11.787638, -4.684584],
                [11.78875, -4.685693999999899],
                [11.789306, -4.687916],
                [11.78986, -4.687916],
                [11.790694, -4.689582],
                [11.792638, -4.691804999999874],
                [11.79375, -4.693472999999869],
                [11.795138000000179, -4.694583],
                [11.796528, -4.69513899999987],
                [11.796528, -4.695970999999872],
                [11.797638, -4.696527],
                [11.797916000000157, -4.697639],
                [11.798751000000152, -4.69847099999987],
                [11.800139, -4.698749],
                [11.801251, -4.700695],
                [11.802917, -4.701527],
                [11.806805, -4.704028],
                [11.809305000000109, -4.706528],
                [11.81069600000012, -4.707362],
                [11.811527000000126, -4.70875],
                [11.81375, -4.709862],
                [11.81514, -4.71125],
                [11.81764, -4.713194],
                [11.81764, -4.71375],
                [11.821528000000171, -4.716529],
                [11.823194000000171, -4.717916999999829],
                [11.825138, -4.719029],
                [11.826805, -4.721250999999882],
                [11.828195, -4.722639],
                [11.829029000000162, -4.722639],
                [11.832083, -4.725416999999879],
                [11.83347300000014, -4.72708299999988],
                [11.833749, -4.728193],
                [11.835417, -4.729304999999897],
                [11.835971, -4.730417999999872],
                [11.839862000000153, -4.733749999999873],
                [11.842084000000114, -4.73625],
                [11.842362, -4.73736],
                [11.844862, -4.73986],
                [11.845138, -4.740972],
                [11.84625, -4.741807],
                [11.846806, -4.743195],
                [11.849028, -4.745695],
                [11.849027633999981, -4.747639177999929],
                [11.849583625000093, -4.748195170999964],
                [11.849860191000062, -4.750139234999949],
                [11.850693702000171, -4.751804827999933],
                [11.851529121000112, -4.752360820999911],
                [11.851529121000112, -4.754304884999897],
                [11.852082252000116, -4.754582880999976],
                [11.852359773000103, -4.757361888999981],
                [11.852916716999971, -4.757915018999938],
                [11.853472710000062, -4.760139940999977],
                [11.853472710000062, -4.769581793999976],
                [11.85319519, -4.769581793999976],
                [11.85319519, -4.773750781999922],
                [11.85263919800002, -4.776248930999941],
                [11.851806640000063, -4.776804923999976],
                [11.850137711000116, -4.778748987999961],
                [11.850416184000153, -4.779860972999927],
                [11.850416184000153, -4.782361029999947],
                [11.849860191000062, -4.782639979999942],
                [11.850137711000116, -4.786250114999859],
                [11.848750114000097, -4.787916182999936],
                [11.847361564000153, -4.788194177999969],
                [11.847361564000153, -4.789306162999935],
                [11.846249581000109, -4.790972232999934],
                [11.841806411000164, -4.792915821999884],
                [11.839861870000107, -4.792915821999884],
                [11.839028358000064, -4.792084217999957],
                [11.83680534399997, -4.791806219999955],
                [11.837361335000082, -4.787640094999915],
                [11.83652687100016, -4.786806105999915],
                [11.835416794000025, -4.786806105999915],
                [11.834029199000042, -4.785972118999951],
                [11.832361221000099, -4.783471106999969],
                [11.831251143999964, -4.782917975999908],
                [11.830139159000055, -4.784028052999929],
                [11.830139159000055, -4.785972118999951],
                [11.82958316800017, -4.786806105999915],
                [11.828473091000092, -4.786806105999915],
                [11.825416565000126, -4.784306048999952],
                [11.825416565000126, -4.778194904999964],
                [11.826528550000035, -4.7784729],
                [11.82958316800017, -4.7784729],
                [11.830416680000042, -4.778194904999964],
                [11.830416680000042, -4.777082919999941],
                [11.829029082000147, -4.775694846999954],
                [11.828194618000055, -4.775694846999954],
                [11.82680511500007, -4.774860858999944],
                [11.824028968000164, -4.774582862999978],
                [11.823472024000068, -4.775416851999921],
                [11.823472024000068, -4.781805038999949],
                [11.823193551000031, -4.784584043999928],
                [11.823749542000144, -4.788472175999971],
                [11.825416565000126, -4.789306162999935],
                [11.826251029000048, -4.791250228999957],
                [11.829029082000147, -4.794027803999882],
                [11.830416680000042, -4.794581889999904],
                [11.83152675700012, -4.796250820999887],
                [11.837082862000045, -4.801805018999971],
                [11.83763980800012, -4.803750991999891],
                [11.839028358000064, -4.804305075999878],
                [11.840140343000144, -4.805971145999934],
                [11.842915535000088, -4.808471202999897],
                [11.84402847300015, -4.810140131999958],
                [11.845137597000075, -4.81041812899997],
                [11.845693588000188, -4.811528205999878],
                [11.847082138000133, -4.812362192999956],
                [11.847082138000133, -4.813749790999964],
                [11.846249581000109, -4.815415858999927],
                [11.845137597000075, -4.816249847999927],
                [11.843471525999973, -4.81819391199997],
                [11.842639922000103, -4.818471908999925],
                [11.840417861000049, -4.820415972999967],
                [11.839861870000107, -4.82180595299991],
                [11.837915421000105, -4.823194025999953],
                [11.839028358000064, -4.824304102999975],
                [11.840417861000049, -4.823194025999953],
                [11.840694428000063, -4.822084903999837],
                [11.842362404000085, -4.820693968999933],
                [11.842362404000085, -4.820137977999934],
                [11.84374999900001, -4.818749904999947],
                [11.844305993000035, -4.818749904999947],
                [11.845972061000055, -4.817359924999948],
                [11.845972061000055, -4.81652784299996],
                [11.847915650000175, -4.815137862999961],
                [11.850972175000038, -4.816249847999927],
                [11.85263919800002, -4.817915915999947],
                [11.854305267000143, -4.820971965999945],
                [11.85541725100012, -4.822084903999837],
                [11.855694771000174, -4.823194025999953],
                [11.85736084000007, -4.823750971999914],
                [11.857639313000107, -4.824861048999935],
                [11.858470916999977, -4.825695037999935],
                [11.85902690800009, -4.827083110999922],
                [11.860138892000066, -4.827639101999921],
                [11.86069488600009, -4.82930517199992],
                [11.861804963000168, -4.829861162999919],
              ],
            ],
            [
              [
                [11.697639, -4.467637998999976],
                [11.697916999000029, -4.468193999999869],
                [11.699861, -4.468193999999869],
                [11.699861, -4.467084],
                [11.697639, -4.467637998999976],
              ],
            ],
            [
              [
                [11.697639, -4.467637998999976],
                [11.697083000000191, -4.466806],
                [11.694305, -4.465693999999871],
                [11.694861, -4.467084],
                [11.697639, -4.467637998999976],
              ],
            ],
          ],
        },
        properties: { GID_0: "COG", NAME_1: "Congo" },
      },
    ],
  },
  60: {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: { GID_0: "NER", COUNTRY: "Niger" },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [3.272485971000151, 11.962559700000099],
              [3.268795966000084, 11.972929955000097],
              [3.262531042000092, 11.982391357000097],
              [3.248569011000143, 11.994180679000124],
              [3.252764940000077, 12.000272752000171],
              [3.258857012000021, 12.009118081000111],
              [3.26056599500015, 12.011599540000134],
              [3.261753083000087, 12.016189575000055],
              [3.255230903000097, 12.019901276000098],
              [3.240094901000077, 12.032313348000173],
              [3.238595008000061, 12.033543586000178],
              [3.234720944000173, 12.038331985000184],
              [3.232501030000151, 12.047498703000144],
              [3.230278969000096, 12.050278663000086],
              [3.224720001000037, 12.055560113000126],
              [3.220279932000096, 12.060832024000092],
              [3.211941004000039, 12.066109657000084],
              [3.20611095400011, 12.069170953000082],
              [3.201109887000143, 12.07332992500011],
              [3.198329926000099, 12.075831413000117],
              [3.194169999000053, 12.078888893000169],
              [3.188889027000073, 12.083608627000046],
              [3.185281992000171, 12.090000154000165],
              [3.182780980000189, 12.095280647000152],
              [3.176388026000041, 12.105831146000128],
              [3.175328971000056, 12.106890678000013],
              [3.175224066000169, 12.106995583000071],
              [3.168610096000066, 12.113610268],
              [3.154170990000125, 12.12250137400008],
              [3.144720077000045, 12.130001068000126],
              [3.133059978000119, 12.136109352000176],
              [3.12721800900016, 12.140277862000062],
              [3.127779008000118, 12.15443992600018],
              [3.124998093000158, 12.157501222000178],
              [3.113889932000063, 12.162779809000028],
              [3.106940984000119, 12.168060302000015],
              [3.100831032000144, 12.17249965700006],
              [3.09694004000005, 12.173890115],
              [3.08357095700012, 12.176416398000072],
              [3.082221031000188, 12.176671028000158],
              [3.081646919000093, 12.177058221000152],
              [3.0719399460001, 12.183610916000077],
              [3.059719086000087, 12.196106911000072],
              [3.058059931000173, 12.201668739000183],
              [3.059999942000047, 12.206110955000042],
              [3.059170008000081, 12.208608627000103],
              [3.046098948000065, 12.21976852500012],
              [3.039999962000081, 12.226110459000097],
              [3.036390066000024, 12.228060723],
              [3.034441947000175, 12.228610039000159],
              [3.029721021, 12.233611106000183],
              [3.025279045000048, 12.240831375000084],
              [3.021672011000021, 12.250280380000049],
              [3.01943993600014, 12.25722026800014],
              [3.01360988600004, 12.266388894000045],
              [3.009720088000108, 12.269450188000064],
              [3.000926971000069, 12.27335357600009],
              [2.9944698810001, 12.276221275000125],
              [2.993587970000078, 12.276613236000117],
              [2.993556023000167, 12.27690124500009],
              [2.990191936000088, 12.279400826000028],
              [2.988950015000114, 12.280325890000029],
              [2.984623909999982, 12.283543586000121],
              [2.983968018999974, 12.284031869000103],
              [2.981401921000156, 12.285940171000107],
              [2.97487211300006, 12.287983893999979],
              [2.971812964000151, 12.288941384000168],
              [2.958488940000109, 12.294480323000073],
              [2.954252004000011, 12.299329758000113],
              [2.953059911000082, 12.303890229000103],
              [2.944441079000171, 12.316671371000155],
              [2.941390991000048, 12.318327903000068],
              [2.935559033000118, 12.324721338000131],
              [2.933609962000048, 12.328060149000066],
              [2.927220107000096, 12.335000039000022],
              [2.923609018000036, 12.335829736000164],
              [2.921941042000185, 12.336670875000038],
              [2.914449929000057, 12.343331337000109],
              [2.906925916000034, 12.349427223000191],
              [2.906620980000071, 12.349760056000093],
              [2.89049100900013, 12.36721992400004],
              [2.886861086000067, 12.370651245000147],
              [2.884439945, 12.377221108000072],
              [2.881109952000088, 12.378060342000083],
              [2.877780913000095, 12.382219315000043],
              [2.872499943000094, 12.387781143000154],
              [2.868608951000056, 12.390277862000062],
              [2.862840891000076, 12.392110825000088],
              [2.857290983000098, 12.395529748000058],
              [2.84651494100001, 12.400429726000141],
              [2.845279933000143, 12.401670456000147],
              [2.845153094000068, 12.401769638000189],
              [2.844314098000098, 12.402425766000079],
              [2.842992068000115, 12.403461457000105],
              [2.838888882999981, 12.406669618000024],
              [2.835560082000029, 12.406941413000084],
              [2.832467080000072, 12.407520294999983],
              [2.819160939000028, 12.413730621000127],
              [2.805824041000165, 12.417421342000068],
              [2.794594050000114, 12.418351173000133],
              [2.792251110000052, 12.416508674000056],
              [2.775166988000137, 12.411000251000189],
              [2.755970000000161, 12.390091896000115],
              [2.732929945000137, 12.36217022],
              [2.728781939000101, 12.362639427000033],
              [2.723381043000188, 12.355070115000046],
              [2.715878009000051, 12.349341394000078],
              [2.7052710050001, 12.318100930000014],
              [2.695821047000152, 12.298629760000154],
              [2.688103915000056, 12.282731057000149],
              [2.686228037000092, 12.28067016600005],
              [2.684140922000154, 12.280104637000079],
              [2.6860098840001, 12.284320832000162],
              [2.68449902600014, 12.291440963000127],
              [2.68508005100017, 12.294390679000117],
              [2.680213928000171, 12.297061919000157],
              [2.679579972999989, 12.297410965000097],
              [2.679311038000037, 12.297968864000154],
              [2.678080083000054, 12.300521851000099],
              [2.67274999600005, 12.304600715000049],
              [2.663429976000032, 12.306071281000072],
              [2.658632994000129, 12.303792],
              [2.656862021000052, 12.302950860000067],
              [2.64046096800007, 12.297030450000136],
              [2.633001088000128, 12.297281265000038],
              [2.630575895000106, 12.296546935000038],
              [2.62944102299997, 12.295120240000188],
              [2.62786007, 12.295723916000043],
              [2.614411117000032, 12.300841331000072],
              [2.610130071000015, 12.299905777000106],
              [2.603090049000173, 12.298370361000082],
              [2.601810932000149, 12.299938202000021],
              [2.595983981000188, 12.297221184000136],
              [2.592647075000173, 12.298255920000088],
              [2.589469910000105, 12.291798592000134],
              [2.579530955000109, 12.277549745000101],
              [2.575731039000118, 12.281470299000091],
              [2.57249999000004, 12.281634331000191],
              [2.569986105000112, 12.280489922000072],
              [2.567198037000026, 12.281126022000137],
              [2.565249921000088, 12.279079437000178],
              [2.55834198000008, 12.278329849000158],
              [2.554008961000136, 12.279720307000105],
              [2.551139117000162, 12.27575874300004],
              [2.546448947000044, 12.274061204000191],
              [2.541630984000108, 12.274338722000039],
              [2.541722058, 12.277249336000182],
              [2.538069010000129, 12.275528908000126],
              [2.536061048000079, 12.27458000300004],
              [2.530399085000113, 12.281509400000061],
              [2.530225992000055, 12.281721115000096],
              [2.522516013000143, 12.285420419000104],
              [2.517396926000117, 12.291049005000048],
              [2.514347076000036, 12.294400215000053],
              [2.511070012000118, 12.29372120000005],
              [2.508019924, 12.288669586000083],
              [2.505661964000126, 12.277401925000106],
              [2.499578952999968, 12.275811196],
              [2.49460101000011, 12.268941879000181],
              [2.491440057000148, 12.270199776000084],
              [2.474210024000172, 12.273441315000127],
              [2.46988105700018, 12.27237033900019],
              [2.469810963000043, 12.271964073000106],
              [2.46855998, 12.264671326000041],
              [2.464040041000089, 12.253258704000075],
              [2.459650039999985, 12.252961160000154],
              [2.457139015000166, 12.2556839],
              [2.456037998000113, 12.25450992500015],
              [2.452529906000109, 12.254050256000028],
              [2.45194697400018, 12.254280090000066],
              [2.44668698300012, 12.25636100700018],
              [2.439213037000059, 12.262104034000117],
              [2.438278913000033, 12.262820245000114],
              [2.434552909000104, 12.263230324],
              [2.430598021000094, 12.255351067000106],
              [2.42759204000015, 12.253063202000078],
              [2.425457, 12.251440049000166],
              [2.405632973000024, 12.249544145000129],
              [2.403708936000101, 12.249361992000104],
              [2.392801047000035, 12.244601251000063],
              [2.387346982000054, 12.242220879000115],
              [2.385479927000176, 12.235549927000079],
              [2.386394024000083, 12.229552268000077],
              [2.387983084000098, 12.219103814000107],
              [2.388070105999987, 12.218529702000012],
              [2.38884902000018, 12.212280274000022],
              [2.391177894000123, 12.193626405000032],
              [2.392488956000079, 12.183112145000109],
              [2.392544031000057, 12.182669639000096],
              [2.399827957000014, 12.142659187000049],
              [2.4120121, 12.111168861000067],
              [2.422780990000149, 12.089559556000097],
              [2.437288047000095, 12.0668096550001],
              [2.445012092000184, 12.051630973999977],
              [2.449923039000169, 12.045661926000093],
              [2.455538987000125, 12.036460877000081],
              [2.459980965000113, 12.032098771000108],
              [2.463725091000129, 12.023820878000038],
              [2.466773034000141, 12.010951042000158],
              [2.468417883000086, 11.994621277000022],
              [2.466087104000053, 11.988170625000066],
              [2.465708016000122, 11.984610558000099],
              [2.46452498400015, 11.983337403],
              [2.463788986000168, 11.982541084000161],
              [2.460942983000166, 11.979479790000141],
              [2.459244966000085, 11.976893426],
              [2.458837032000076, 11.976268769000114],
              [2.455096959000116, 11.975811005000082],
              [2.447407006000049, 11.981360436000159],
              [2.446465969000087, 11.982040405000191],
              [2.445266961000186, 11.98211669900013],
              [2.442725897000116, 11.982280731000174],
              [2.43710708600014, 11.976539612000067],
              [2.432667971000114, 11.975158692000036],
              [2.431024074000106, 11.970110894000186],
              [2.43357706099999, 11.958840370000075],
              [2.432143926000038, 11.956729889000144],
              [2.431236028000058, 11.95539188399999],
              [2.420022965000158, 11.955180169000187],
              [2.419038057000023, 11.954425811000078],
              [2.412538052000173, 11.949440957000093],
              [2.407866001000059, 11.949911117000113],
              [2.406951905000085, 11.95034408500004],
              [2.406678915000043, 11.950473786000032],
              [2.406420946000139, 11.949812890000089],
              [2.402669907000188, 11.940221787999974],
              [2.399211883000021, 11.937225342000033],
              [2.391768932000161, 11.930770875000064],
              [2.395417928000086, 11.926906585999973],
              [2.402259111000092, 11.919659614000068],
              [2.39974999500015, 11.91339016],
              [2.400604963000035, 11.912760734000187],
              [2.404002905000141, 11.910738946000038],
              [2.404690982000147, 11.910330772],
              [2.411439896000047, 11.906319619000044],
              [2.40693903000016, 11.902812005000101],
              [2.405400038000039, 11.901610374000029],
              [2.395828009000184, 11.91202926700015],
              [2.38469147700016, 11.92798709900012],
              [2.382030010000108, 11.93180084300019],
              [2.379994392000072, 11.933707238000125],
              [2.377107382000133, 11.936411857000166],
              [2.373437165000155, 11.939849853000055],
              [2.371742010000105, 11.941438675000029],
              [2.370421886000145, 11.943289757000116],
              [2.365839004000179, 11.949703217000149],
              [2.362384082000176, 11.954540254000051],
              [2.351279974000136, 11.9703407290001],
              [2.346709967000095, 11.97684097400014],
              [2.318339110000068, 12.014706611000122],
              [2.315751792000185, 12.018159867000179],
              [2.296766042000172, 12.043499947000043],
              [2.262786627000025, 12.088851929999976],
              [2.254523993000078, 12.099880219000056],
              [2.245739936000121, 12.111603736000063],
              [2.232304574000068, 12.129535674000181],
              [2.21605277000009, 12.151226998000084],
              [2.214387894000026, 12.153449058999968],
              [2.196365118000074, 12.177504539000097],
              [2.193201780000152, 12.181726455000046],
              [2.184197187000052, 12.193744660000107],
              [2.139065027000072, 12.253981590000024],
              [2.138096094000105, 12.255274773],
              [2.123003960000119, 12.275419235000186],
              [2.092032909000068, 12.316756249000036],
              [2.085982085000183, 12.324832916000162],
              [2.069282055000031, 12.347121240000092],
              [2.066610097000137, 12.350687027000163],
              [2.066420078000135, 12.350940704000095],
              [2.064908980000155, 12.355548860000169],
              [2.064690113000097, 12.356209756000112],
              [2.0644919880001, 12.361060143000088],
              [2.072540998000136, 12.369680405000111],
              [2.072443963000183, 12.372568130000047],
              [2.072429895000084, 12.372988702000043],
              [2.071411372000114, 12.373899460000075],
              [2.069350958000143, 12.375741959000038],
              [2.072160960000133, 12.382269859000132],
              [2.076910973000111, 12.387840271000073],
              [2.078151465000076, 12.389198304000047],
              [2.082118034000132, 12.393540383000072],
              [2.087986946000058, 12.395649909000156],
              [2.09005999600015, 12.394631386000185],
              [2.091730117000054, 12.393811225000093],
              [2.095161915000119, 12.389925003000087],
              [2.104125978000127, 12.379777908000108],
              [2.107400894000023, 12.379090310000038],
              [2.110450029000162, 12.380318643000123],
              [2.111377955000023, 12.380689621000101],
              [2.111998082000071, 12.38243580000011],
              [2.113254070000039, 12.385979653000106],
              [2.117465973000151, 12.391730308000149],
              [2.126128911000137, 12.398619653000026],
              [2.135019063000129, 12.401600838000036],
              [2.143414021000183, 12.408931732000156],
              [2.143448115000126, 12.40896129500004],
              [2.15406966300003, 12.41158199299997],
              [2.152112961000171, 12.418609620000154],
              [2.155155897000043, 12.419980049000117],
              [2.16451096500009, 12.419289589000186],
              [2.169685125000115, 12.42201232900004],
              [2.174108982000121, 12.424340248000021],
              [2.174341918000039, 12.424332618000108],
              [2.1816000930001, 12.424100877000171],
              [2.186510086000169, 12.421570777999989],
              [2.188768624000034, 12.421696662000102],
              [2.189909935, 12.421759605000091],
              [2.193239451000181, 12.421952249000014],
              [2.202189923000162, 12.422471046000055],
              [2.203461170000139, 12.421679496000138],
              [2.215521098000181, 12.414180755000075],
              [2.217164040000057, 12.415834427000107],
              [2.218234777000077, 12.416913986000168],
              [2.223953963000099, 12.422680855000124],
              [2.235960008, 12.425951004000069],
              [2.248290061000148, 12.420130731000143],
              [2.250828742000124, 12.417628289000049],
              [2.253200053000171, 12.41528987900017],
              [2.253602742000169, 12.41543102300011],
              [2.254553079000175, 12.415765762000035],
              [2.257179022000116, 12.41667079900003],
              [2.257245062999971, 12.416801453000176],
              [2.261610270000119, 12.418420791000074],
              [2.262228727000036, 12.418649674000051],
              [2.261274099000104, 12.423857688000055],
              [2.261159896000152, 12.424481392000132],
              [2.26668644, 12.425361634000126],
              [2.272630929000115, 12.426308633000076],
              [2.272807122000188, 12.426530838000076],
              [2.274271966000072, 12.428380965000088],
              [2.266880989000072, 12.436161995000191],
              [2.267587185000025, 12.444714546],
              [2.26842904200015, 12.454910278],
              [2.271229983000183, 12.467830659000128],
              [2.27055001400015, 12.469242095000027],
              [2.269935130000079, 12.470518112000036],
              [2.268137693000085, 12.474249840000027],
              [2.267811060000156, 12.47492790199999],
              [2.263967513000011, 12.479482650000136],
              [2.262650966000081, 12.481042863000141],
              [2.256092071000182, 12.488815307000039],
              [2.254901886000027, 12.490223885000034],
              [2.254038335000075, 12.491246224000179],
              [2.249933480000152, 12.496109962000048],
              [2.248319387000095, 12.498023034000084],
              [2.248271942000145, 12.498079301000018],
              [2.245519876000174, 12.501339912000105],
              [2.228260995000085, 12.521788597000125],
              [2.226059437, 12.578138353000099],
              [2.22586989500013, 12.582990647000145],
              [2.224298716000078, 12.585153579000064],
              [2.216406107000125, 12.596018791],
              [2.196288824000135, 12.623713492999968],
              [2.196029902000021, 12.624070168000117],
              [2.180948973000056, 12.632540702000028],
              [2.174320222, 12.636263847000123],
              [2.161312580000072, 12.643568994000134],
              [2.156773330000021, 12.646118163000153],
              [2.151500463000104, 12.649081230000093],
              [2.151806592000128, 12.649714469000173],
              [2.157819986000106, 12.662159920000079],
              [2.159883738000133, 12.68110084500006],
              [2.160629988000096, 12.687950134000175],
              [2.128628969000033, 12.697271348000129],
              [2.115689992000171, 12.705690385000139],
              [2.115172148000113, 12.705815316000098],
              [2.11359834700005, 12.706196786000021],
              [2.110779047000165, 12.706879616000037],
              [2.106234074000099, 12.723367691000078],
              [2.105032919999985, 12.727725030000101],
              [2.104160070000091, 12.730891228000075],
              [2.091569900000025, 12.727470398000037],
              [2.082761049000112, 12.721140861000038],
              [2.071001052999975, 12.72422027600004],
              [2.062959909000085, 12.726731301000029],
              [2.062210799000127, 12.726963997000098],
              [2.061389923000036, 12.727218628000116],
              [2.039418936000175, 12.727749826000036],
              [2.038508178000143, 12.72777271200016],
              [2.033684015, 12.727890968000168],
              [2.022510051000097, 12.728156090000084],
              [2.013134002000129, 12.728379250000046],
              [2.005170106000037, 12.730449677000024],
              [1.983605265000051, 12.735689163000131],
              [1.977509975000032, 12.73717022],
              [1.961493848999965, 12.71196079300006],
              [1.960080028000164, 12.710849762000066],
              [1.948868990000165, 12.697499275999974],
              [1.93105304300002, 12.703661920000059],
              [1.924723982999979, 12.704568862000144],
              [1.922387003999972, 12.70180034700013],
              [1.910964012000079, 12.681309699999986],
              [1.873898982000185, 12.61821937600007],
              [1.870151996000118, 12.618209838000155],
              [1.863834023000038, 12.616359711000143],
              [1.857979059, 12.616799353999966],
              [1.846029996000141, 12.619071960999975],
              [1.827996969000139, 12.619018556000128],
              [1.820919990000164, 12.619295121000164],
              [1.80598104000012, 12.619880676000037],
              [1.794500947000131, 12.6216878890001],
              [1.776232958000037, 12.622089387000074],
              [1.757457971000122, 12.623019218000024],
              [1.752312184000061, 12.623273850000146],
              [1.74554896300009, 12.6236095430001],
              [1.738844035999989, 12.623632432000136],
              [1.710186005000026, 12.623729706000177],
              [1.700111986000024, 12.624849319000191],
              [1.689568997000151, 12.625970841000083],
              [1.683251023000025, 12.624570846000154],
              [1.664980055000115, 12.62635135700009],
              [1.625218034000113, 12.628424644000177],
              [1.611876131000088, 12.629703522000113],
              [1.576050043000066, 12.633138657000188],
              [1.433308006000061, 12.743431091000161],
              [1.427297950000025, 12.748675346000027],
              [1.410490990000085, 12.763340951000089],
              [1.405089974000134, 12.766071319000048],
              [1.394858717000147, 12.774089813000046],
              [1.388159991000123, 12.779339791000098],
              [1.372159004000025, 12.79374885600015],
              [1.360704422000083, 12.802835464],
              [1.34016203900012, 12.819131851000066],
              [1.327211977000161, 12.831950189000167],
              [1.324917434999975, 12.833453179000173],
              [1.317996740000183, 12.837987899000041],
              [1.317810059000067, 12.838109970000119],
              [1.317751169000189, 12.838166237000053],
              [1.309193611000183, 12.846352578000165],
              [1.302736043000039, 12.852529526000183],
              [1.292623996000089, 12.85892105],
              [1.277674556000079, 12.871600152000156],
              [1.274029970000129, 12.874691009000173],
              [1.273900389000175, 12.874794006000059],
              [1.273862124000118, 12.874824525],
              [1.273832559000084, 12.874848366000151],
              [1.226475954000136, 12.913319589000139],
              [1.210227966000105, 12.926800728000103],
              [1.201972961000024, 12.935957910000127],
              [1.190678000000105, 12.943720818000145],
              [1.190682054000092, 12.942799568000112],
              [1.174661994000076, 12.957201957999985],
              [1.166411995000033, 12.96475124400007],
              [1.123366833000091, 12.999508858000183],
              [1.118485569000143, 12.999514580000096],
              [0.997500003000027, 12.999650002000124],
              [0.993848801000127, 13.045829773000037],
              [0.992857038000182, 13.058373450999966],
              [0.992789984000183, 13.059221268000044],
              [0.992500007000046, 13.066801071000043],
              [0.992100418000121, 13.083982469000034],
              [0.991876006000041, 13.093819619000044],
              [0.991550029000166, 13.107951163999985],
              [0.992097019000084, 13.133469582000032],
              [0.99212032600002, 13.163213729],
              [0.992142856999976, 13.192040444000099],
              [0.992142916000091, 13.192094801999986],
              [0.99215102200003, 13.202427864000185],
              [0.991541028000029, 13.26218891200017],
              [0.991699517000086, 13.313515664000022],
              [0.991699994000044, 13.313679695000019],
              [0.990700006000054, 13.32355880800003],
              [0.990737974000183, 13.325164795000148],
              [0.991819978000024, 13.370920182000077],
              [0.997288763000142, 13.372741700000176],
              [0.998849988000075, 13.373261452000179],
              [1.018820046000087, 13.372220993000155],
              [1.023609638000039, 13.369689942000093],
              [1.028715968000142, 13.366991043000155],
              [1.053089022000052, 13.368611336000185],
              [1.055194736000033, 13.367694856000014],
              [1.055342793000136, 13.36763000500008],
              [1.064098596000065, 13.36381721399999],
              [1.064388991000044, 13.363690376000022],
              [1.065001965000135, 13.363350867000065],
              [1.067166804000124, 13.36215019299999],
              [1.070988656000168, 13.360029221000048],
              [1.073209882000072, 13.358797074000108],
              [1.075178027000106, 13.357705117000137],
              [1.076570152000102, 13.356933594000111],
              [1.078183651000131, 13.356038094000098],
              [1.082569481000064, 13.353605271000163],
              [1.083621978000053, 13.353021621000039],
              [1.090667010000118, 13.34911346500013],
              [1.108443261000048, 13.339253426000084],
              [1.119680047000031, 13.333020211000132],
              [1.120067953000159, 13.333146095000075],
              [1.123520970000129, 13.33426857000012],
              [1.126829982000174, 13.332367898000086],
              [1.128826976000028, 13.334032058000105],
              [1.132310987000096, 13.33693790500007],
              [1.136315942000067, 13.334510803000171],
              [1.143651962999968, 13.330061912000076],
              [1.143759966000118, 13.329999924000049],
              [1.143884062000097, 13.330083848000072],
              [1.14492595300004, 13.330788612000163],
              [1.148681999000132, 13.322210312000038],
              [1.176628948000143, 13.320219994000183],
              [1.178766966000069, 13.318968773000108],
              [1.182508946000098, 13.316789628000038],
              [1.18649995300018, 13.317031861000089],
              [1.192935943000123, 13.324747086000116],
              [1.193989992000013, 13.326009750000139],
              [1.201539993000097, 13.335450172000037],
              [1.201205015000085, 13.34649372199999],
              [1.204370976000121, 13.349450111000124],
              [1.203306198000121, 13.36198902100017],
              [1.202298999000163, 13.373850822000122],
              [1.208402992000117, 13.373865127000158],
              [1.210752010000135, 13.373870850000174],
              [1.21686696900008, 13.370671273000028],
              [1.220653057000163, 13.360789300000135],
              [1.220916986000077, 13.350448608000022],
              [1.231297015000166, 13.333699228000114],
              [1.236462950000089, 13.333250047000149],
              [1.252470017000178, 13.339500427000189],
              [1.25382399599999, 13.340027809000162],
              [1.2545599930001, 13.340315818000079],
              [1.25852799400019, 13.342006684000182],
              [1.258756042000073, 13.341952324000147],
              [1.279152035000038, 13.349910737000187],
              [1.279885053999976, 13.350350380000066],
              [1.282130600000187, 13.351696969000045],
              [1.28250908800004, 13.351923942000042],
              [1.282607675000122, 13.351983070000131],
              [1.282955051000101, 13.352255822000188],
              [1.281260014000054, 13.357689857000082],
              [1.280969978000087, 13.358619690000012],
              [1.24092996100012, 13.391369820000136],
              [1.227550984000061, 13.38854789800007],
              [1.221647023000173, 13.387302399000134],
              [1.214548945000161, 13.390091895000182],
              [1.203062892000048, 13.39281559],
              [1.191542030000164, 13.395547866000129],
              [1.177498222000111, 13.400300026000025],
              [1.159821033000071, 13.40628147100017],
              [1.157957316000136, 13.409525871000028],
              [1.156128048000085, 13.412710190000155],
              [1.136669041000062, 13.414020539000092],
              [1.100467920000142, 13.448722840000187],
              [1.087890863000041, 13.460779190000096],
              [1.079923034000046, 13.461081504000106],
              [1.067780018000065, 13.461541176000082],
              [1.044893026000125, 13.479879380000057],
              [1.043662070000096, 13.480864524000026],
              [1.040809036000098, 13.48314857600019],
              [1.024283290000028, 13.485766411000156],
              [1.014089942, 13.487380982000104],
              [1.010066986000027, 13.506508828000165],
              [1.007359982000082, 13.519379615999981],
              [1.014811038000175, 13.531020163999983],
              [1.010004043000095, 13.548691750000046],
              [1.006079554000166, 13.558012008999981],
              [1.00594902000006, 13.558321953000075],
              [1.002649188000021, 13.561608315],
              [0.998149990000115, 13.566088676999982],
              [0.993657470000187, 13.571744920000185],
              [0.989960015000065, 13.576399803000129],
              [0.989652992000117, 13.576389313000163],
              [0.988570512000081, 13.57635212100007],
              [0.973646999000152, 13.575841904000072],
              [0.961559295000086, 13.579643250000174],
              [0.958580016000042, 13.580579759000102],
              [0.950994730000048, 13.586595536000061],
              [0.938090027000158, 13.596830368000099],
              [0.929379284000163, 13.602294922000169],
              [0.913479389000088, 13.612269401000049],
              [0.913388192000127, 13.612326622000069],
              [0.912775994000071, 13.6127109520001],
              [0.90866971000014, 13.617005348000134],
              [0.907096028000126, 13.618651390000082],
              [0.870922029000155, 13.621721267000169],
              [0.863835633, 13.622322082000039],
              [0.857409059000133, 13.622867585000165],
              [0.852757991000033, 13.623262406000038],
              [0.846417009000163, 13.623801232000062],
              [0.838268996000181, 13.621618272000092],
              [0.827189983000039, 13.618651390000082],
              [0.790709019000133, 13.637911798000118],
              [0.781639993000113, 13.642700195000089],
              [0.78162104, 13.642827988000136],
              [0.778438986000026, 13.664280891000089],
              [0.782345057000157, 13.67023754000013],
              [0.784489988000075, 13.673508644000037],
              [0.784898818000158, 13.673633576999976],
              [0.796920002000036, 13.67726993500014],
              [0.796877026000061, 13.677296639000019],
              [0.78374302300017, 13.685278893000032],
              [0.772896111000136, 13.691869736000115],
              [0.772599995000121, 13.692049980000093],
              [0.772598028000061, 13.692971230000126],
              [0.768679977000147, 13.692090989],
              [0.76837402700005, 13.69209289700018],
              [0.750001192000184, 13.692277908000108],
              [0.71845001, 13.692595483000048],
              [0.684092046000103, 13.692940712000109],
              [0.683849991000045, 13.69294357400014],
              [0.66772800800004, 13.693650245000072],
              [0.655973970000048, 13.693340303000184],
              [0.635519028000147, 13.693430902000159],
              [0.619018376000156, 13.699061392999965],
              [0.60530001, 13.709758759000124],
              [0.604676365000046, 13.710899353000173],
              [0.60029, 13.718918801000029],
              [0.600282133000064, 13.719081879000044],
              [0.599734009000031, 13.730409622000025],
              [0.614229916999989, 13.747600555000133],
              [0.621178985000142, 13.75584125600011],
              [0.62558001300016, 13.765290259999972],
              [0.625297010000111, 13.771259309000129],
              [0.620430291000048, 13.776514053000085],
              [0.619741558000158, 13.777257920000068],
              [0.619434953999985, 13.777589798000179],
              [0.619368970000039, 13.777661324000064],
              [0.620995999000115, 13.779970170000126],
              [0.62047702100017, 13.786629677000178],
              [0.61365801200003, 13.786349295999969],
              [0.613508939000042, 13.783832551000046],
              [0.61333882800011, 13.780996323000011],
              [0.613210023000022, 13.77884960200015],
              [0.6099230640001, 13.779484749000176],
              [0.602168978000122, 13.780982017000099],
              [0.589420974000177, 13.787560463000091],
              [0.584882081000046, 13.794538498000065],
              [0.584659993000116, 13.794879913000159],
              [0.584649503000151, 13.794936181000025],
              [0.581965982000099, 13.809338571000126],
              [0.5781739950001, 13.812990189000061],
              [0.564991772000042, 13.822445870000024],
              [0.564220010000099, 13.822999954000068],
              [0.555006027000047, 13.827758789000086],
              [0.551217973000178, 13.831400872000074],
              [0.54036998700002, 13.834090233000097],
              [0.530439973000171, 13.840682030000096],
              [0.528938114000141, 13.843366623000065],
              [0.527409196000121, 13.846099853999988],
              [0.527052581000021, 13.846736908000139],
              [0.52686101200004, 13.847079277000148],
              [0.526560963000179, 13.856389046000118],
              [0.526238978000094, 13.866378784000119],
              [0.52381640700014, 13.86972522800005],
              [0.520771026000091, 13.873931885000047],
              [0.517690301000016, 13.876324655000133],
              [0.513352990000101, 13.879693985000017],
              [0.5046690100001, 13.886440277000077],
              [0.497748465000086, 13.889655114000107],
              [0.497350992000179, 13.889840126000138],
              [0.491901993000056, 13.894159318000106],
              [0.488398522000182, 13.897838593000131],
              [0.476476014000127, 13.910359382000024],
              [0.474177450000184, 13.915769578000038],
              [0.473360985000056, 13.917691231000163],
              [0.471614986000077, 13.929862023000169],
              [0.472942413000055, 13.935218812000073],
              [0.473437994999983, 13.937218665999978],
              [0.474089383000091, 13.937714577000065],
              [0.480453998000087, 13.942561148999971],
              [0.482212306000065, 13.948146820000034],
              [0.482468099000187, 13.948959351000042],
              [0.483209013000021, 13.951311111000109],
              [0.481444000000124, 13.960731507000048],
              [0.480500013000096, 13.965769768000143],
              [0.473371386999986, 13.959323884000071],
              [0.47255900600004, 13.958589555000117],
              [0.469556987000033, 13.956373216000145],
              [0.463434995000114, 13.951850891000163],
              [0.45521, 13.949961663000181],
              [0.450450986000078, 13.956580163000126],
              [0.45340499200006, 13.96969890500003],
              [0.453289002000076, 13.970000267000103],
              [0.450408637000066, 13.977389336000158],
              [0.450280011000075, 13.977719306000097],
              [0.446550936000165, 13.981053352000117],
              [0.443399011000054, 13.983871460000046],
              [0.442552239000179, 13.984494209000161],
              [0.442027211000038, 13.984880447000137],
              [0.422089011000082, 13.999560357000064],
              [0.416277468000146, 14.005257607000033],
              [0.410461008000141, 14.010959626000101],
              [0.407790095, 14.014828682000143],
              [0.398788006000075, 14.027869224000142],
              [0.387459994000039, 14.059029579000082],
              [0.387413173000141, 14.059187889000043],
              [0.38406899600011, 14.070501329000138],
              [0.39506098600009, 14.086340904],
              [0.384321988000067, 14.095211029000041],
              [0.384328991000018, 14.095771790000185],
              [0.381915003000131, 14.102649688000099],
              [0.36334899100018, 14.126171112000065],
              [0.357924998000044, 14.137957572000062],
              [0.357038021000108, 14.148412705000169],
              [0.37312001000015, 14.172960282000076],
              [0.391000003000102, 14.190159798000082],
              [0.386889993000182, 14.221960068000158],
              [0.403759987000171, 14.249659539000049],
              [0.401769996000098, 14.254010200000039],
              [0.385939986000039, 14.288669587000129],
              [0.370050014000128, 14.308171273000028],
              [0.358679861000041, 14.317204476000086],
              [0.353500992000079, 14.321318627000039],
              [0.347433418000037, 14.330261231000065],
              [0.338788986000168, 14.34300136600001],
              [0.328774988000077, 14.355005265000159],
              [0.322930991000135, 14.36201095600012],
              [0.300810010000134, 14.366079332000083],
              [0.296665162000181, 14.366839410000125],
              [0.296499998, 14.366869926000163],
              [0.275406987000167, 14.378391266000051],
              [0.266562997000051, 14.390504837000037],
              [0.248162002000186, 14.411588669000139],
              [0.231129870000132, 14.431103706000044],
              [0.224178000000109, 14.439068795000139],
              [0.214640007000071, 14.44944953900017],
              [0.212288007000154, 14.446822168000097],
              [0.209736004000092, 14.443966866000096],
              [0.192010998000114, 14.460640908000016],
              [0.176770002000069, 14.492421149999984],
              [0.173283324000067, 14.504717826000103],
              [0.167999998000084, 14.523349762],
              [0.166250006000098, 14.533438682000053],
              [0.177590996000163, 14.582681657000137],
              [0.212449998000125, 14.668258667000032],
              [0.231241002000047, 14.721678735000125],
              [0.233023212000091, 14.73187732800011],
              [0.234491975000026, 14.740282059000151],
              [0.235316619000059, 14.744998933000147],
              [0.236101002, 14.749480248000111],
              [0.22487247, 14.779095650000045],
              [0.221963241000083, 14.786768913000117],
              [0.221249610000086, 14.788651466000033],
              [0.219620004999967, 14.792951585000083],
              [0.20950152000006, 14.819640160000063],
              [0.204038307000019, 14.834050178000041],
              [0.203644633000124, 14.83508872900012],
              [0.202004999, 14.839417457000138],
              [0.204009994000103, 14.842869759000166],
              [0.22809399700003, 14.869251252000083],
              [0.235739888000182, 14.886071206000167],
              [0.235711277000178, 14.886302949000083],
              [0.235220701000117, 14.890259742000183],
              [0.234945505000042, 14.892478943000015],
              [0.234471485000086, 14.896298408000121],
              [0.234459997000045, 14.89639091500004],
              [0.234396502000038, 14.897340774000043],
              [0.233837501000039, 14.905703546000098],
              [0.233182371, 14.915505410000037],
              [0.229002995999963, 14.978083610000056],
              [0.229347408000081, 14.98958396900008],
              [0.229350001000171, 14.989670753000155],
              [0.23149700499999, 14.989812852000114],
              [0.242449999000144, 14.989767075000145],
              [0.268592001000059, 14.989660263000189],
              [0.284435988000155, 14.987951280000061],
              [0.295322, 14.985975265000093],
              [0.305249990000164, 14.985365868000031],
              [0.319454998000026, 14.982041360000096],
              [0.328179985000133, 14.980734824000024],
              [0.336959005000153, 14.979421615000149],
              [0.341719001000172, 14.978270531000135],
              [0.352584989000036, 14.975643158000082],
              [0.365359009000031, 14.973678589000144],
              [0.378749997000114, 14.972179413000106],
              [0.382865012000138, 14.971717834],
              [0.401775002000136, 14.969598770000175],
              [0.418518008000092, 14.973402978000024],
              [0.421739996000042, 14.974397660000079],
              [0.427466988000162, 14.976166725000155],
              [0.441374988000177, 14.980463982000174],
              [0.444644005000043, 14.98147964399999],
              [0.449827999000149, 14.983091355000056],
              [0.45674699599999, 14.985241890000054],
              [0.470124007000095, 14.989399910000031],
              [0.476819009000167, 14.991067886],
              [0.479781985000159, 14.991805078000084],
              [0.499601006000148, 14.996741294000117],
              [0.504302024000083, 15.000000000000114],
              [0.505425990000049, 15.000000000000114],
              [0.510527015000093, 15.001091958000018],
              [0.512082994000139, 15.001424789000112],
              [0.513870002000033, 15.000000000000114],
              [0.522343994000039, 14.993245125000101],
              [0.537761986000135, 14.980951309000091],
              [0.541112005000116, 14.981284142000163],
              [0.560190976000172, 14.98318004600003],
              [0.561154008000074, 14.982905388],
              [0.562388002000034, 14.982550622000133],
              [0.566465974000153, 14.981384277000188],
              [0.572740019000094, 14.979591370000094],
              [0.588582992999989, 14.977640152000106],
              [0.594040989000064, 14.97445964800005],
              [0.609430016000147, 14.970211983000013],
              [0.619831979000082, 14.969141006000143],
              [0.627891004000105, 14.965749742000014],
              [0.642567993000114, 14.961489677000088],
              [0.659833013000082, 14.95885086100003],
              [0.665050029000099, 14.956131936000077],
              [0.672149003000186, 14.954339982000135],
              [0.689189971000076, 14.948947907000104],
              [0.694172977999983, 14.947373391000042],
              [0.700611697999989, 14.946264991000191],
              [0.700811634000047, 14.946230503000152],
              [0.701940769000089, 14.946035386000119],
              [0.703157008000119, 14.945824622999965],
              [0.703759014000184, 14.946035386000119],
              [0.704720000000123, 14.946368],
              [0.705499534000126, 14.946637782000039],
              [0.706838012000048, 14.947100640000144],
              [0.707531703000143, 14.947341618000166],
              [0.707872988000076, 14.947460175000117],
              [0.711997986000142, 14.951697351000064],
              [0.716218961000038, 14.956031497000026],
              [0.717239977000133, 14.95707988800018],
              [0.719126999000139, 14.959018707000098],
              [0.720808029000125, 14.959186554000041],
              [0.733099996000135, 14.96041584000011],
              [0.73635723700005, 14.960741698000106],
              [0.745731, 14.961679],
              [0.748635000000149, 14.961971],
              [0.755861912000171, 14.96225468300014],
              [0.762570022999967, 14.962517738000145],
              [0.785706997000148, 14.964269638000133],
              [0.806963027000108, 14.965094566000175],
              [0.809029997000096, 14.965359689000024],
              [0.814516009000101, 14.966061592000074],
              [0.823586510000041, 14.966570130000036],
              [0.832938015000082, 14.967094422000116],
              [0.847810982999988, 14.968560219000153],
              [0.87369208400014, 14.969835535000129],
              [0.88512498200015, 14.970398902000113],
              [0.886472317000027, 14.970872928000119],
              [0.891185998000026, 14.972531319999973],
              [0.89243197400009, 14.97297001000004],
              [0.893207014000097, 14.972929001000125],
              [0.904246986000146, 14.972350121000034],
              [0.915401265000128, 14.972281484000121],
              [0.923619985000187, 14.972230911000111],
              [0.924868555000103, 14.972453384000119],
              [0.931643, 14.973660000000109],
              [0.941511000000162, 14.974075],
              [0.950445068000022, 14.974452014000121],
              [0.956914007000137, 14.974724770000137],
              [0.973188995999976, 14.978949548000116],
              [0.97454661800009, 14.980096678000166],
              [0.975654, 14.981032],
              [0.983242, 14.98744],
              [0.998114000000157, 15.000000000000114],
              [0.998902581000095, 15.000665930000139],
              [1.029837012000144, 15.026788712000041],
              [1.036589026000172, 15.032082558000013],
              [1.038065741000025, 15.03324050000009],
              [1.038777, 15.033798],
              [1.048416000000145, 15.041355],
              [1.058524000000148, 15.050823],
              [1.073014835000038, 15.062473360000126],
              [1.07922100900015, 15.067462921000072],
              [1.092952966999974, 15.07901954700003],
              [1.095391989000177, 15.081073761000027],
              [1.126968979000026, 15.107654572000115],
              [1.151203037000187, 15.127519607000181],
              [1.170231524000144, 15.143556154],
              [1.17159, 15.144701],
              [1.171901415000036, 15.144963453],
              [1.176144003000047, 15.148538590000157],
              [1.18065212900018, 15.151681613000051],
              [1.185038, 15.154739000000177],
              [1.186224789000164, 15.155566523000175],
              [1.187095999000121, 15.156173705000128],
              [1.195325386000036, 15.161910542000044],
              [1.201344014000085, 15.166106225000021],
              [1.201897921000182, 15.16670232000007],
              [1.202963948000047, 15.167849540000134],
              [1.208274220000135, 15.173565489999987],
              [1.210983, 15.176481],
              [1.240983, 15.201227],
              [1.244787213000052, 15.204364601000066],
              [1.246530056000097, 15.20580196300017],
              [1.263962031000119, 15.218935966000174],
              [1.264695581000069, 15.219488720000072],
              [1.265843987000039, 15.220354080000106],
              [1.266517768000142, 15.221013887000083],
              [1.268901945000152, 15.223348618000045],
              [1.269119025000123, 15.223554612000044],
              [1.274983717999987, 15.229146362000165],
              [1.277851000000112, 15.23188],
              [1.28454546100005, 15.238262097000074],
              [1.28584504100013, 15.239501000000189],
              [1.304947019000167, 15.25471687300012],
              [1.311050057000159, 15.259579658000064],
              [1.313447427000085, 15.260607187000062],
              [1.31348204599999, 15.260622025000089],
              [1.322458982000057, 15.264472007000109],
              [1.322546959000135, 15.264510154000163],
              [1.324030041000128, 15.265146256000037],
              [1.344169809999983, 15.266361047000089],
              [1.347682954000049, 15.266572953000036],
              [1.351959787000169, 15.266831109000066],
              [1.367053000000112, 15.267742],
              [1.370808067000041, 15.267967808000094],
              [1.380606054000111, 15.26855659600011],
              [1.381480507000049, 15.268609910000066],
              [1.385658026000101, 15.269981385000165],
              [1.387584432000153, 15.270613917000105],
              [1.389276981000137, 15.27116966300008],
              [1.40654397000003, 15.27054977500012],
              [1.412282972000014, 15.27141485400017],
              [1.423797, 15.27315],
              [1.43562100000014, 15.2732],
              [1.438648616000023, 15.273436451],
              [1.475813985000116, 15.276338578000093],
              [1.490207918000124, 15.277348012000118],
              [1.501134992000061, 15.278114319],
              [1.515308023000102, 15.279108048000069],
              [1.517197012000167, 15.27923965400015],
              [1.517428041000187, 15.279255868000064],
              [1.522189021000088, 15.279342652000139],
              [1.549829006000039, 15.279840470000124],
              [1.557625054000141, 15.28170204200012],
              [1.584076046, 15.283214568],
              [1.600690007000026, 15.284165383000072],
              [1.604681014000107, 15.284393311000144],
              [1.652624011000057, 15.287781715000165],
              [1.689805985000078, 15.290410043000065],
              [1.708485961, 15.292080879000082],
              [1.741827011000169, 15.294711114],
              [1.757434965000186, 15.29543972099998],
              [1.771149040000069, 15.296629907000067],
              [1.849850058000016, 15.30232238800005],
              [1.860178947000122, 15.303069116000017],
              [1.87330603700002, 15.30401897500019],
              [1.890810012000145, 15.30475330500019],
              [1.912564039000188, 15.306181907000109],
              [1.918948055000101, 15.308262825000156],
              [1.939290046000053, 15.308311462000177],
              [1.958650946000034, 15.309981347000075],
              [1.969331979000174, 15.310902596000176],
              [1.972468973000161, 15.311173438000026],
              [1.975738049000142, 15.311454774000026],
              [1.985692978000031, 15.312313079000091],
              [2.004796027999987, 15.313961028999984],
              [2.026551008000013, 15.316529274000118],
              [2.049967050000021, 15.317951203000121],
              [2.063210963000074, 15.319351196000014],
              [2.143385887000136, 15.326609612000027],
              [2.156867027000033, 15.327321052000059],
              [2.164382934000116, 15.32807636300015],
              [2.18505191800017, 15.330154420000042],
              [2.189270020000095, 15.330577850000054],
              [2.203461885000081, 15.331509591000156],
              [2.216047048000178, 15.332875253000168],
              [2.231369973000142, 15.334536553000135],
              [2.246273994000035, 15.33522129000005],
              [2.246747016000086, 15.335243226000102],
              [2.296166897000035, 15.339114190000089],
              [2.300204038000061, 15.33942985499999],
              [2.345618008999963, 15.34453105900019],
              [2.346726893999971, 15.344584466000072],
              [2.365015983000035, 15.345462800000121],
              [2.382507085000043, 15.346877099000153],
              [2.396476985000049, 15.34800624900015],
              [2.456089973000076, 15.35355091100007],
              [2.466559886000084, 15.354233741000087],
              [2.477380038000092, 15.354939461000015],
              [2.492994070000066, 15.357238769000048],
              [2.499145985000041, 15.35792732200008],
              [2.512156963000109, 15.358161927000026],
              [2.529580117000023, 15.360181810000086],
              [2.537942886000167, 15.36115169500016],
              [2.548825979000071, 15.361379623000175],
              [2.572510004000151, 15.363299371000039],
              [2.58573198300013, 15.364370346000101],
              [2.603652, 15.36551380200018],
              [2.60815691900018, 15.365801811000154],
              [2.628789901000118, 15.367120742999987],
              [2.63626503900008, 15.366510391000133],
              [2.651266099000168, 15.365282059000094],
              [2.691248895000058, 15.363205909000101],
              [2.694499970000038, 15.362978936000047],
              [2.708092927000166, 15.362031937000154],
              [2.724370002, 15.360897064000142],
              [2.749551057000133, 15.359189033000121],
              [2.775470019000068, 15.357430458000181],
              [2.797235966000187, 15.356949805999989],
              [2.799268962000042, 15.356821060000186],
              [2.830353976000026, 15.354860306000148],
              [2.841237068000055, 15.353011131000187],
              [2.851881027000104, 15.353235244000132],
              [2.874420881000049, 15.35205554900017],
              [2.896593095000185, 15.350897790000033],
              [2.918121099000075, 15.351100922000114],
              [2.956913949000125, 15.348527909000154],
              [2.989089012000136, 15.346650123000188],
              [3.006592989000069, 15.345938682000053],
              [3.014784098000064, 15.34559726700013],
              [3.020201922000126, 15.345369338000182],
              [3.057923079000034, 15.343791962000068],
              [3.097902060000081, 15.341658592000101],
              [3.131494045000125, 15.341140747000168],
              [3.15420293800014, 15.341551782000181],
              [3.221621990000131, 15.341182709000066],
              [3.221621990000131, 15.34026432100012],
              [3.235110045000113, 15.341151238000066],
              [3.261131049000142, 15.341551782000181],
              [3.307497979000175, 15.341670037000085],
              [3.376574039000104, 15.34080028600016],
              [3.382488013000057, 15.341469765000056],
              [3.403074026000127, 15.342101097000068],
              [3.409929036999984, 15.340471268000101],
              [3.435481072000073, 15.341321946000164],
              [3.527507066000169, 15.341489791000072],
              [3.527453899000079, 15.342038155000182],
              [3.525902986000119, 15.357790948000172],
              [3.525722980000126, 15.374306679000028],
              [3.531407117000185, 15.393700600000045],
              [3.53170299500016, 15.394713403000083],
              [3.531886101000168, 15.395530700999984],
              [3.534033060000127, 15.405126571000096],
              [3.536988973000177, 15.41833114700006],
              [3.537739038000154, 15.430031778000114],
              [3.537586926000188, 15.430985450000151],
              [3.536115885000015, 15.440217973000131],
              [3.534955978000085, 15.447479248000093],
              [3.534142017000136, 15.453579904000037],
              [3.531493903000069, 15.473420143000169],
              [3.53268599400019, 15.479520797000077],
              [3.533420086000035, 15.483282089999989],
              [3.538172006000082, 15.488309861000062],
              [3.565924884000083, 15.505428314000085],
              [3.57105398200008, 15.507037164000167],
              [3.580940008000027, 15.510136604000081],
              [3.592701911000063, 15.513825417000021],
              [3.594183921000081, 15.515047074000108],
              [3.60766005500011, 15.526162147999969],
              [3.617177963000131, 15.539428712000074],
              [3.640963078000027, 15.568715096000119],
              [3.674010039000109, 15.604148864000024],
              [3.6851758950001, 15.613290788000086],
              [3.706119061000095, 15.639360427000156],
              [3.72154903400002, 15.648245811000095],
              [3.729337931000089, 15.651718141000117],
              [3.731750012000077, 15.652792931000079],
              [3.734370947000173, 15.653601647000187],
              [3.738481998000054, 15.654870986000162],
              [3.739814043000024, 15.655282020000072],
              [3.741816999000037, 15.655628204000095],
              [3.754398108000146, 15.657810211000083],
              [3.756643056000087, 15.658199310000043],
              [3.764914989000147, 15.658862113000055],
              [3.791945935000115, 15.661029815000177],
              [3.811291932000188, 15.662558556000135],
              [3.822273016000167, 15.663427352000042],
              [3.837933063000151, 15.669550896000089],
              [3.84358596800007, 15.673469543000067],
              [3.850761891000047, 15.678443908000133],
              [3.854795933000048, 15.679800987000078],
              [3.865250110000034, 15.686639785000125],
              [3.868108034000045, 15.690296174000082],
              [3.872142076000046, 15.691880226000023],
              [3.877125024000065, 15.697593688000097],
              [3.883097887000133, 15.704448700000114],
              [3.889081002000069, 15.717041969000149],
              [3.893897057000174, 15.733991624000055],
              [3.895734071000106, 15.740229606000128],
              [3.905695915000138, 15.774079322000091],
              [3.905725002000168, 15.780271530000164],
              [3.906481982000059, 15.789670945000069],
              [3.910092116000158, 15.813715935000062],
              [3.911128998000152, 15.820618630000183],
              [3.911720038000055, 15.844927787000074],
              [3.915858984000181, 15.86806869600008],
              [3.925674914000012, 15.887981415000013],
              [3.929572104999977, 15.909519196000133],
              [3.942024946000174, 15.935374260000117],
              [3.947273969000037, 15.941540719000045],
              [3.964421987000094, 15.957053184000074],
              [3.974168062000103, 15.961823464000076],
              [3.980881929000077, 15.966294289000018],
              [3.985497952000173, 15.969367981000175],
              [3.997845888000086, 15.97759056100017],
              [3.998435974000074, 15.97798442900006],
              [3.998795986000175, 15.978466987000047],
              [4.004405021000025, 15.985981942000024],
              [4.004453183000123, 15.995611191000137],
              [4.003334046000134, 16.000001908000058],
              [4.00154304400013, 16.013799668000047],
              [3.995771884000078, 16.033721924000133],
              [3.990446092000127, 16.049640655000132],
              [3.987740040000062, 16.060646058000088],
              [3.987938882000151, 16.061349868999969],
              [3.989248991000125, 16.065992356000152],
              [3.992095947000109, 16.074214936000146],
              [3.996642112000131, 16.08797454900008],
              [4.00995492900006, 16.124870300000055],
              [4.023042202000113, 16.164436341000055],
              [4.031980038000143, 16.189859390000095],
              [4.037669182000116, 16.207445144000133],
              [4.045658112000126, 16.230384827000023],
              [4.050795077000032, 16.245103836000112],
              [4.055172920000132, 16.257339477000073],
              [4.057452202000036, 16.264219285000138],
              [4.065062046000151, 16.286399842000151],
              [4.069468022000024, 16.296728135000023],
              [4.075168132000044, 16.314119338000182],
              [4.076075077000041, 16.318523407000157],
              [4.076467038000033, 16.320419311000023],
              [4.076927184000112, 16.331090928000037],
              [4.077136994000057, 16.345748902000139],
              [4.077315330000033, 16.349882126000182],
              [4.077900887000112, 16.363464355000133],
              [4.077674867000042, 16.397350311000082],
              [4.077805043000183, 16.418680190000032],
              [4.078137874000106, 16.439252854000131],
              [4.078900814000122, 16.457153321000192],
              [4.078526974000113, 16.471239090000097],
              [4.07936811400009, 16.483057022000025],
              [4.079049111000074, 16.492763519000107],
              [4.079099179000139, 16.504570006999984],
              [4.079482079000172, 16.537141801000075],
              [4.079286098000068, 16.552751540000145],
              [4.078956127000026, 16.57921981800007],
              [4.07891512000009, 16.598260880000112],
              [4.079659940000056, 16.609779358000139],
              [4.079741954000099, 16.611028670999985],
              [4.079617022999969, 16.613328933999981],
              [4.07922697000015, 16.62054061900011],
              [4.078784944000176, 16.64015579300019],
              [4.078557015000058, 16.674242020000065],
              [4.077935219000096, 16.723754883000083],
              [4.077823161000083, 16.732700348000094],
              [4.077457904000141, 16.761648179000133],
              [4.076927184000112, 16.788728715000047],
              [4.076918126000066, 16.789260865000074],
              [4.076538086000028, 16.80373191800004],
              [4.075839996000184, 16.874961854000048],
              [4.076756001000035, 16.91230964600004],
              [4.090919018000136, 16.919710160000079],
              [4.096468925000067, 16.922153473000151],
              [4.099572183000134, 16.923519134000117],
              [4.122035980000135, 16.933412553000039],
              [4.166740895000032, 16.953350067000144],
              [4.199020863000101, 16.967535019000024],
              [4.223031043000049, 16.978086471000154],
              [4.22900295300019, 16.980710984000154],
              [4.240818024000134, 16.986934663],
              [4.24120283200017, 16.987697601000093],
              [4.241638183000021, 17.018760682000163],
              [4.241024971000115, 17.054759979000039],
              [4.240426063000143, 17.072282792000124],
              [4.240365983000118, 17.094566343999986],
              [4.240989207000155, 17.109239579000018],
              [4.239858151000021, 17.155719756999986],
              [4.239582061000078, 17.179529190000096],
              [4.23962307000005, 17.210399627000015],
              [4.239388942000176, 17.230590821000192],
              [4.240067959000157, 17.240507126000068],
              [4.239147186000082, 17.285839082000052],
              [4.239253998000038, 17.328149795000115],
              [4.239170074000185, 17.369691849000048],
              [4.238905907000117, 17.392549515000042],
              [4.239705087000175, 17.409328461000086],
              [4.239332199000046, 17.424379349000048],
              [4.239490987000181, 17.444959641000025],
              [4.239243030000182, 17.466299058000061],
              [4.239103794000073, 17.49545669500003],
              [4.239390850000177, 17.539289474000043],
              [4.239758014000188, 17.559114457000192],
              [4.239224912000111, 17.570924759000036],
              [4.239511968000045, 17.614761353000119],
              [4.239483834000055, 17.651351930000089],
              [4.239780903000053, 17.677280427000028],
              [4.240989207000155, 17.693300248000071],
              [4.24076080399999, 17.712928773000101],
              [4.240336894000109, 17.766670228000066],
              [4.240860939000015, 17.807277679000038],
              [4.240815162000047, 17.835979461000022],
              [4.241038799000023, 17.865779878000012],
              [4.240590096000119, 17.929178238000077],
              [4.24098205700011, 17.975282669000137],
              [4.241073131000178, 17.986034393000125],
              [4.240263938000112, 18.006521225000029],
              [4.24040079200006, 18.011844635000159],
              [4.240869999000097, 18.030059816000119],
              [4.240549086999977, 18.08060073900009],
              [4.241652011000099, 18.13336563200005],
              [4.241687775000059, 18.228761674000054],
              [4.241823196000155, 18.241891862000045],
              [4.241609096000047, 18.260200500000053],
              [4.241706848000149, 18.325805664000029],
              [4.241538048, 18.340290070000151],
              [4.24162721700003, 18.357240677000107],
              [4.242344856000159, 18.369281769000054],
              [4.241541863000066, 18.389226914000176],
              [4.242215156999976, 18.429960252000171],
              [4.24253606700006, 18.525650024000186],
              [4.243239880000147, 18.563657760000126],
              [4.242062092000026, 18.590984345000095],
              [4.243094921000136, 18.625440597000136],
              [4.243430137000189, 18.670829773999969],
              [4.243441104999988, 18.672210694],
              [4.243471146000104, 18.67605972399997],
              [4.243710042000146, 18.707954407000102],
              [4.243881225000166, 18.73070907500005],
              [4.243717193000123, 18.794139862],
              [4.244967938000116, 18.810005188000162],
              [4.244258880000075, 18.82148361100019],
              [4.244338989000028, 18.913621904000081],
              [4.243980885000099, 18.94424057100008],
              [4.244226932000117, 18.972410201000116],
              [4.243288040000039, 19.00383949400009],
              [4.243566036000118, 19.056297302000132],
              [4.243737221000174, 19.088600158000133],
              [4.242959977000169, 19.13043022100004],
              [4.242887021000172, 19.136716843000102],
              [4.281672954000101, 19.144981385000165],
              [4.33615207600019, 19.156589509000185],
              [4.447794915000088, 19.17839813300003],
              [4.506813049000016, 19.190214157000128],
              [4.562339783000141, 19.201330186000177],
              [4.667045594000172, 19.222156525000059],
              [4.672811985000067, 19.222980500000062],
              [4.724978924000084, 19.233169555000188],
              [4.824817180000139, 19.252300262000063],
              [4.96497011200006, 19.277500152000187],
              [4.999999999000067, 19.284048081000094],
              [5.081787109000118, 19.299335480000138],
              [5.098989963000065, 19.302549363000026],
              [5.182458878000148, 19.319040299000051],
              [5.288577079000106, 19.33832550000011],
              [5.391017914000145, 19.357530595000128],
              [5.477021217000072, 19.373500824000132],
              [5.510766030000127, 19.379869462000045],
              [5.5502791400001, 19.387424469000166],
              [5.675837040000147, 19.410839081000177],
              [5.80098390500018, 19.434648514000116],
              [5.81708192900004, 19.437709809000182],
              [5.82116699300019, 19.441322327000137],
              [5.826950074000024, 19.446437835999973],
              [5.878996848000156, 19.49246978799999],
              [5.880341530000067, 19.49365806600008],
              [5.943864823000183, 19.550394058000052],
              [6.022905349000041, 19.621822357000042],
              [6.030826091999984, 19.628980637000097],
              [6.077151776000164, 19.669225693000044],
              [6.120347977000108, 19.706750869000132],
              [6.197978020000051, 19.774709701000063],
              [6.23020506, 19.804769517000125],
              [6.289320944999986, 19.858240128000034],
              [6.369583129000034, 19.92963981600019],
              [6.449069976000089, 20.000000001000103],
              [6.452402115000041, 20.002948760999971],
              [6.502542972000072, 20.046989440000175],
              [6.541513920000114, 20.080890656000122],
              [6.568471909000152, 20.104263306000064],
              [6.61162185600017, 20.141647338000098],
              [6.64606905, 20.17007255499999],
              [6.690505982000047, 20.210170747000063],
              [6.751576901000135, 20.261531831000127],
              [6.805210114000147, 20.307815552000079],
              [6.82933187499998, 20.328420640000161],
              [6.838064193999969, 20.334640504000049],
              [6.865951061000146, 20.360700608000116],
              [6.893846035000138, 20.386358260000122],
              [6.941730022, 20.427551270000151],
              [6.964231969000139, 20.448539734000178],
              [6.994236947000104, 20.475349427000026],
              [7.07011795000011, 20.540979386000174],
              [7.129776953999965, 20.591428757000017],
              [7.147322178000025, 20.606569291000085],
              [7.15107107100016, 20.61045074599997],
              [7.160675049000076, 20.619380952000085],
              [7.17068290800006, 20.625955582000131],
              [7.184031963000109, 20.635250093000138],
              [7.189047813000173, 20.640300752000144],
              [7.191978931000051, 20.644189835000134],
              [7.218289851000122, 20.665901184000177],
              [7.238305092000132, 20.681779861000109],
              [7.273006915, 20.709306718000107],
              [7.335688115000039, 20.757713319000061],
              [7.375401973000066, 20.78828811700015],
              [7.413042069000085, 20.816930771000045],
              [7.445679189000089, 20.842470168000091],
              [7.479530810000142, 20.863307952000071],
              [7.493299008000122, 20.871564866000085],
              [7.511550903000114, 20.882993697000074],
              [7.535346986000036, 20.897720337000123],
              [7.559145928000078, 20.912754059000065],
              [7.603167056000132, 20.939493180000113],
              [7.640018940000175, 20.961711883000021],
              [7.677537919000031, 20.984222413000055],
              [7.706913948000135, 21.002534866000076],
              [7.745104790000084, 21.025632859000154],
              [7.757174968000129, 21.031917573000044],
              [7.776441098000078, 21.043609619000051],
              [7.784926891000112, 21.048990250000145],
              [7.831328868000128, 21.07840919400013],
              [7.888179779000097, 21.11226272600004],
              [7.945059777000097, 21.146392822000109],
              [8.002943992000098, 21.181100846000049],
              [8.031082153000114, 21.198160171000154],
              [8.051372529000105, 21.210729599000103],
              [8.058896064000066, 21.214908600000058],
              [8.069043159000046, 21.221187592000035],
              [8.119462014000021, 21.251989365000099],
              [8.181689263000067, 21.289638520000096],
              [8.252461434000111, 21.332029343000045],
              [8.334099770000137, 21.381269456000041],
              [8.376092910000068, 21.406919480000113],
              [8.390866280000125, 21.416475296000101],
              [8.408271790000185, 21.42781829900008],
              [8.448300361000065, 21.451051713000084],
              [8.501500129000021, 21.483833312000172],
              [8.558655740000177, 21.518373490000101],
              [8.601714135000066, 21.544870377000166],
              [8.652661323000189, 21.575199128000179],
              [8.674028395999983, 21.587688446000072],
              [8.696730615000149, 21.601970673000096],
              [8.739822387000061, 21.627826692000042],
              [8.790178299000104, 21.658428193000077],
              [8.866551400000105, 21.70384788399997],
              [8.920259477000116, 21.736799241000142],
              [8.970678329, 21.767051696000067],
              [9.005285263000076, 21.787496567000176],
              [9.03002452800007, 21.802919389000124],
              [9.071902275000184, 21.827499391000117],
              [9.1260242460001, 21.860687256000119],
              [9.186760902000174, 21.897096635000025],
              [9.256769180000163, 21.93878936800013],
              [9.320872307000172, 21.977226257000098],
              [9.334396363000053, 21.983980179000071],
              [9.342660905000116, 21.989011764000111],
              [9.36480522100004, 22.002000808000105],
              [9.382691383000065, 22.01415062000018],
              [9.398896218000175, 22.023899079000046],
              [9.425038338000093, 22.04015731800007],
              [9.461750031000179, 22.061693192000121],
              [9.516030312000055, 22.094451905000142],
              [9.575286866000056, 22.129539489000024],
              [9.627928733000147, 22.159860610000123],
              [9.66372203800006, 22.181669235000072],
              [9.672983169000077, 22.186359407000168],
              [9.682584763000136, 22.191650390000177],
              [9.731968880000125, 22.220785140000089],
              [9.746888159999969, 22.229614259000073],
              [9.765111923000177, 22.239896774999977],
              [9.800270081000178, 22.260782242000118],
              [9.85600566800008, 22.293710710000084],
              [9.896143913000174, 22.316608430000144],
              [9.988437653000176, 22.370330810000155],
              [10.0, 22.377695083000106],
              [10.005065918000128, 22.380922319000149],
              [10.015721322000161, 22.387992860000111],
              [10.051883697000051, 22.407287597000106],
              [10.07116127, 22.418731690000072],
              [10.139639854000109, 22.458595276000096],
              [10.211179733000051, 22.501398087000155],
              [10.240151883000067, 22.518238068000016],
              [10.24443912400011, 22.520418168000106],
              [10.274544715000161, 22.538398743000073],
              [10.30770778699997, 22.558204651000096],
              [10.354658127000107, 22.585699082000133],
              [10.386320115000103, 22.604429245000063],
              [10.422285080000165, 22.624866485000155],
              [10.438303948000055, 22.635587692],
              [10.45168590600008, 22.644544602000053],
              [10.467811584000117, 22.653554917000122],
              [10.48207664500012, 22.66152572600015],
              [10.482537747000094, 22.661783218000096],
              [10.497888178000153, 22.670788562000041],
              [10.536694527000179, 22.693588258000034],
              [10.538018704000024, 22.69433117],
              [10.577700616000072, 22.716611862000036],
              [10.597756386000015, 22.729499818000022],
              [10.633802414000172, 22.750501632000123],
              [10.645649910000088, 22.757192611000107],
              [10.679180146000078, 22.776130676000037],
              [10.704189300000053, 22.791306496000175],
              [10.717613219999976, 22.79948806800013],
              [10.734312057000125, 22.809101104999968],
              [10.765062333000117, 22.82747459400008],
              [10.771781920000024, 22.831378937000125],
              [10.824214935000043, 22.86183357200008],
              [10.880449295000062, 22.894830703000139],
              [10.913509369000053, 22.914230347000114],
              [10.972068786000023, 22.948560715000042],
              [10.996536255000024, 22.96272277900016],
              [11.020269394000024, 22.976457595000056],
              [11.059441567000135, 22.999113082000065],
              [11.071662903, 23.006254196000157],
              [11.080836297000189, 23.011617660000184],
              [11.08686256500016, 23.01513862600018],
              [11.120017051000161, 23.034516335000092],
              [11.12007617900008, 23.034551620000059],
              [11.199159621999968, 23.080089569000165],
              [11.272588730000052, 23.122417450000171],
              [11.280472755000176, 23.126945497000122],
              [11.308235168000181, 23.142887116000111],
              [11.341692924000029, 23.162096024000164],
              [11.389019013000109, 23.189609528000062],
              [11.398098946000061, 23.195125580000081],
              [11.43201732599999, 23.214811325000085],
              [11.505211831000111, 23.256132126000125],
              [11.559631347999982, 23.286739350000175],
              [11.62348079800006, 23.32282066300013],
              [11.669220924000058, 23.348789215000124],
              [11.687973022000165, 23.360120773000119],
              [11.699870110000063, 23.367309570000089],
              [11.701369763000059, 23.367929458000049],
              [11.706262589000062, 23.369955064000123],
              [11.737019538000027, 23.38728713900008],
              [11.765930175000108, 23.40388679400013],
              [11.777318001000026, 23.410425186000055],
              [11.814710141000148, 23.431244850000155],
              [11.851794244000132, 23.452629091000119],
              [11.859919547000118, 23.45731926000019],
              [11.892094612000164, 23.475299836000033],
              [11.901041031000091, 23.480571748000102],
              [11.916379452000115, 23.489306451000118],
              [11.925707816999989, 23.495204926000099],
              [11.934144973000173, 23.499820710000108],
              [11.946760179000023, 23.506719590000159],
              [11.958166123000126, 23.512447357000042],
              [11.958850860000041, 23.512790680000137],
              [11.960250854000037, 23.513618469000164],
              [11.979550363000044, 23.525030136000112],
              [11.983137131000149, 23.522220612000183],
              [12.000000954000086, 23.515167236000138],
              [12.000671387000011, 23.515300750000165],
              [12.03606128600012, 23.507932661999973],
              [12.078689576000102, 23.499059677000105],
              [12.107163430000014, 23.490917205000187],
              [12.109628678000092, 23.490209579000066],
              [12.121850013000085, 23.48946189899999],
              [12.153021812000077, 23.481069566000087],
              [12.158479690000092, 23.481473923000067],
              [12.230681418000074, 23.466821671000105],
              [12.240373612000155, 23.464714050000055],
              [12.373358726000106, 23.435779571000069],
              [12.377118110000083, 23.434965133000048],
              [12.384825706000129, 23.434974671000134],
              [12.415505409000048, 23.42705154500004],
              [12.434783934999984, 23.422071457000072],
              [12.506172180000021, 23.407272338000155],
              [12.567798615000072, 23.394470215000013],
              [12.576028823, 23.393802644000118],
              [12.584360124000057, 23.390380859000061],
              [12.614371300000187, 23.385309219000192],
              [12.62269497, 23.381887436000113],
              [12.638849258999983, 23.379219056000181],
              [12.646199226000022, 23.378000259000146],
              [12.728610993000132, 23.359779358000026],
              [12.745081902000038, 23.357736587000034],
              [12.754295349000074, 23.354408264000085],
              [12.755649568000081, 23.353918075000081],
              [12.763572694000175, 23.35245323200013],
              [12.76640987400009, 23.351930619000143],
              [12.769512177000024, 23.348354340000071],
              [12.789687158000163, 23.347101211000052],
              [12.903376580000156, 23.322504043000038],
              [13.006940841000016, 23.300027848000127],
              [13.018659592, 23.298749925000152],
              [13.02947044400014, 23.294910430000186],
              [13.036111832000017, 23.293800353000108],
              [13.04944896700016, 23.291559220000181],
              [13.168952942000146, 23.264938356000073],
              [13.18805027000019, 23.260679246000109],
              [13.198989868000126, 23.260280610000166],
              [13.222792625000011, 23.253572463000125],
              [13.241484641000113, 23.251543046],
              [13.278661728000145, 23.242235184000094],
              [13.30096721600006, 23.23665046800005],
              [13.301483155000028, 23.236520767000059],
              [13.301871301000176, 23.236423492000085],
              [13.310310364000031, 23.234310151000102],
              [13.375002860000052, 23.221759797000175],
              [13.383319855000138, 23.217840194000132],
              [13.397974969000131, 23.217527389000111],
              [13.407778741000072, 23.21342277500014],
              [13.411610603000042, 23.210077285000068],
              [13.43141937200005, 23.203205108000134],
              [13.480590820000145, 23.186132431000033],
              [13.523599626000134, 23.166795732000139],
              [13.539990424000052, 23.158029556000145],
              [13.544738769000105, 23.15699958800019],
              [13.550518990000057, 23.15357971200018],
              [13.555033684000023, 23.150909425000123],
              [13.631830215000093, 23.105470658000058],
              [13.668129921000059, 23.080629349000048],
              [13.68039989600004, 23.072240829000179],
              [13.693651199000158, 23.05970954899999],
              [13.72025585099999, 23.037893296000163],
              [13.743389130000139, 23.01892471300016],
              [13.748769760000187, 23.012628555000049],
              [13.769789695000043, 22.99704933300012],
              [13.776358605000155, 22.992176057000165],
              [13.870711326000105, 22.912410737000016],
              [13.879598618000045, 22.904893874999971],
              [13.917370795000124, 22.872722626999973],
              [13.922232628000131, 22.866870880000135],
              [13.93990993400007, 22.853969573000029],
              [13.971019743999989, 22.827430725000113],
              [14.001720428000169, 22.80121994100017],
              [14.025086402999989, 22.783546448000095],
              [14.02647113800009, 22.78249931400012],
              [14.028036118000102, 22.781095506000156],
              [14.060930252000048, 22.751600266000025],
              [14.07163715400003, 22.742641449000132],
              [14.12717533100016, 22.696147920000044],
              [14.133440018000044, 22.692840575000048],
              [14.146340370000189, 22.681882859000041],
              [14.151688575000151, 22.675569534000033],
              [14.161459923, 22.670730592000041],
              [14.172386170000152, 22.658988954000051],
              [14.175690650000035, 22.65543937700005],
              [14.188081741000019, 22.645479202999979],
              [14.189322471000025, 22.644483567000066],
              [14.291270256000075, 22.689462662000096],
              [14.292721749, 22.690101624000192],
              [14.295063019000111, 22.691133500000092],
              [14.339232446000096, 22.710584641000025],
              [14.377098083000078, 22.727262498000186],
              [14.381965638000054, 22.7308006290001],
              [14.411372184000072, 22.743314743000099],
              [14.412240029000031, 22.74405670200008],
              [14.41318988900008, 22.744869233000088],
              [14.413950920000104, 22.745519639000065],
              [14.415498734000096, 22.746839523000062],
              [14.449919700000066, 22.759275436000053],
              [14.451089860000081, 22.7596988680001],
              [14.452486993000093, 22.760343551000062],
              [14.517478943000185, 22.79035186700014],
              [14.520876885000121, 22.793394089000117],
              [14.535630226000023, 22.798772812000095],
              [14.543470383999988, 22.801630020000061],
              [14.589310647000048, 22.82222938600006],
              [14.605528832000175, 22.828035355000054],
              [14.605607033000183, 22.828086853000059],
              [14.612363815000037, 22.832521438000072],
              [14.651260377000142, 22.85027885500017],
              [14.65810585000014, 22.85340499900019],
              [14.685688018, 22.865989686000091],
              [14.698716162999972, 22.87058448900018],
              [14.698893547000125, 22.870721818000106],
              [14.703349113000172, 22.874109269000144],
              [14.769882203, 22.902725220000036],
              [14.780890464000038, 22.907459259000177],
              [14.811870576000047, 22.920780181000111],
              [14.843812943000046, 22.935819626000068],
              [14.861441611000089, 22.944118500000116],
              [14.862633705000121, 22.944200515999967],
              [14.865897178000012, 22.944425584000157],
              [14.877690315000166, 22.949670792000177],
              [14.88263988500006, 22.949760437],
              [14.89763546000006, 22.956451415000117],
              [14.901503563000119, 22.96063232400013],
              [14.902730941000073, 22.961160661000065],
              [14.926481246000094, 22.971509933000164],
              [14.961211204000051, 22.986640930000192],
              [14.961313248000124, 22.986698150000166],
              [14.972490310000182, 22.993017196000039],
              [14.995869636000123, 23.001890182000182],
              [14.996606827000107, 23.000905991000025],
              [14.997902871000065, 22.999176025000054],
              [14.999999046000028, 22.990980148000062],
              [15.002903938000031, 22.979627609000147],
              [15.006489753000153, 22.965610504000097],
              [15.005870819000108, 22.9628639230001],
              [15.005349159000104, 22.960550308000109],
              [15.008365631000117, 22.946193695000147],
              [15.022500991000129, 22.878940583000031],
              [15.029125214000146, 22.847381591999977],
              [15.029642105000164, 22.844917297000109],
              [15.028969764000067, 22.841009141000029],
              [15.04547977500016, 22.754850388000023],
              [15.047501565000118, 22.7443046570001],
              [15.050438880000058, 22.728982926000072],
              [15.069854737000071, 22.630752563000101],
              [15.094344140000146, 22.506664276000038],
              [15.114425658000073, 22.404750823000143],
              [15.117038726000089, 22.393686295],
              [15.119628907000106, 22.382720948000042],
              [15.122200013000054, 22.371839523000062],
              [15.122328759000027, 22.370653153000092],
              [15.12368965200011, 22.358100892000095],
              [15.132206917000076, 22.316654205000134],
              [15.138070106000157, 22.288120270000093],
              [15.142329216000121, 22.258390427],
              [15.143128396000179, 22.25281143300009],
              [15.143741606999981, 22.248516082000037],
              [15.14520073, 22.244899750000172],
              [15.147878646000095, 22.238260269000136],
              [15.148667335000084, 22.230281830000024],
              [15.150301934000026, 22.21373748700006],
              [15.164472581000041, 22.137456895000128],
              [15.165065766000168, 22.134265901000106],
              [15.168086053000081, 22.117979050000145],
              [15.188131332000125, 22.00989914000013],
              [15.189792632999968, 22.00092888000006],
              [15.191865922000034, 21.989744186000109],
              [15.191369056000156, 21.985443115000123],
              [15.190589905000081, 21.978708267000172],
              [15.191742896000164, 21.933506013000056],
              [15.193003655000098, 21.884065629000077],
              [15.194000244000051, 21.845190048000063],
              [15.192951203, 21.780679702000043],
              [15.193329811000069, 21.777702332000047],
              [15.194379807000132, 21.769447326000034],
              [15.194520951000072, 21.757925033000163],
              [15.19602107899999, 21.631689073000132],
              [15.196873664000123, 21.559627534000185],
              [15.197198867000111, 21.532150269000169],
              [15.194820403000165, 21.527980805000141],
              [15.195880890000069, 21.522678375],
              [15.197521210000048, 21.514480591000108],
              [15.197851179999986, 21.506229401000041],
              [15.198430062000114, 21.49130630500008],
              [15.288578987000051, 21.432319641000049],
              [15.289652824000029, 21.430580138999971],
              [15.293050766000135, 21.425073624000106],
              [15.299270629999967, 21.414987564000114],
              [15.30120563600002, 21.411851883999987],
              [15.321539879, 21.385297775000026],
              [15.330129623, 21.370960235000155],
              [15.36273574900008, 21.326080322000166],
              [15.367288590000157, 21.319812776000106],
              [15.368051528000024, 21.31876182700006],
              [15.44305038499999, 21.215408326000045],
              [15.455120086000079, 21.198759079000126],
              [15.472922325000184, 21.173759460000156],
              [15.473711014000173, 21.17032814099997],
              [15.484181405000072, 21.159450532000164],
              [15.499497413000086, 21.13624954200003],
              [15.517934799000045, 21.11098289500012],
              [15.593903541000088, 21.006767272000161],
              [15.59471988700011, 21.005645752000078],
              [15.600661278000132, 20.99748420700007],
              [15.602482796, 20.994981765000148],
              [15.625590324000029, 20.963239670000064],
              [15.623555183000178, 20.961189271000137],
              [15.620049476000077, 20.957656861000089],
              [15.615242959, 20.954107285000191],
              [15.576030731000117, 20.925149918000102],
              [15.573370934000025, 20.922454834000064],
              [15.566367149000087, 20.915359496000065],
              [15.561623574000066, 20.910551071000043],
              [15.56110000699999, 20.910020829000018],
              [15.557492255000113, 20.904493332000186],
              [15.552020073000108, 20.896110534000115],
              [15.550577164, 20.892248152999969],
              [15.54615783700001, 20.880418777000159],
              [15.548810958000104, 20.866910934000146],
              [15.551974297000072, 20.858787538000172],
              [15.553015707999975, 20.856113435000168],
              [15.556460381000022, 20.847269058000165],
              [15.564335823000022, 20.830230712000116],
              [15.578259468000056, 20.800109863000046],
              [15.590099335000104, 20.774469377],
              [15.617959976, 20.74269676300014],
              [15.64050007000003, 20.722789765000073],
              [15.645521165000048, 20.718988419000141],
              [15.663744926000049, 20.705190658000106],
              [15.667367936000119, 20.702445984000178],
              [15.667563438000172, 20.702297210000154],
              [15.670441628000106, 20.700117112000044],
              [15.696828842000059, 20.680131913000025],
              [15.727060318000099, 20.650209427000107],
              [15.736433030000057, 20.639804841000057],
              [15.745671272999971, 20.629550934000122],
              [15.746827125000038, 20.627977372000089],
              [15.756528854000123, 20.614780426000095],
              [15.790983201000131, 20.567882539000152],
              [15.81439113600004, 20.534643174000109],
              [15.816164016000187, 20.530073167000126],
              [15.829277038000157, 20.513320922000048],
              [15.831610680000153, 20.510339736999981],
              [15.832262993000029, 20.509506226000042],
              [15.836279869000066, 20.504371643000013],
              [15.865159989000063, 20.462459564000085],
              [15.88787460400016, 20.441837309999983],
              [15.919719696000186, 20.41663169899999],
              [15.923611641000036, 20.413551332000111],
              [15.982689857000025, 20.360174178000079],
              [15.995641709000154, 20.348470687000088],
              [15.991128922000087, 20.340917587999968],
              [15.982233048000126, 20.326028823000058],
              [15.981666563999966, 20.325080871000182],
              [15.981349944000101, 20.324550629000157],
              [15.955596925000123, 20.281425476000038],
              [15.915615081999988, 20.21447181800005],
              [15.913059234000116, 20.210189820000096],
              [15.911258697000051, 20.207174301000123],
              [15.898123742000166, 20.18497467100002],
              [15.893946648000053, 20.177915573000064],
              [15.837269783000067, 20.082040786999983],
              [15.831007957000111, 20.07201385500008],
              [15.82837009300016, 20.067789077000157],
              [15.82231712399999, 20.058082580000189],
              [15.788438797000083, 20.003749847],
              [15.786100387000033, 20.000000001000103],
              [15.78116607600009, 19.992086410000184],
              [15.760490417000085, 19.958929061000049],
              [15.753550530000098, 19.947784424000133],
              [15.750152588000162, 19.92541503800004],
              [15.749775886000066, 19.922931672000118],
              [15.747899055000119, 19.917133332000162],
              [15.745819092000033, 19.910709382000107],
              [15.746220589000131, 19.907444001000044],
              [15.746919632000186, 19.901765823000062],
              [15.745732308000129, 19.891481400000089],
              [15.740221978000022, 19.84379959100005],
              [15.739118576000067, 19.836402892000081],
              [15.739011764000111, 19.835681916000112],
              [15.738125802000127, 19.829746247000116],
              [15.731367110000122, 19.784433365000041],
              [15.72863197200013, 19.771995544000106],
              [15.727267266000183, 19.769166947000087],
              [15.724618912000096, 19.763679503999981],
              [15.727030754000168, 19.759275436000109],
              [15.727620125000158, 19.758199692000119],
              [15.722061157000098, 19.715412140000126],
              [15.72161483800005, 19.711980819000189],
              [15.721559524999975, 19.711559297000122],
              [15.719100953000122, 19.692630768000072],
              [15.717432021999969, 19.68904686000019],
              [15.71533584600013, 19.684547424000073],
              [15.716898917000037, 19.677219391000108],
              [15.716345785999977, 19.672220231000153],
              [15.715829850000148, 19.667560578000177],
              [15.713508606000062, 19.660087585000156],
              [15.710960389000036, 19.651880265000159],
              [15.711696626000105, 19.651050567000084],
              [15.71317958800006, 19.64937973100001],
              [15.70873832699999, 19.621822357000042],
              [15.708061218000012, 19.617620468000098],
              [15.704940797000063, 19.588689804000182],
              [15.70357513499999, 19.57604408200018],
              [15.700588226000036, 19.548385621000079],
              [15.698060035000026, 19.538709639999979],
              [15.698040009000181, 19.538066865000189],
              [15.697401046000152, 19.517799378000063],
              [15.689970970000161, 19.479110717000026],
              [15.688198090000014, 19.463590622000083],
              [15.679240227000037, 19.385036469000113],
              [15.677991867000117, 19.379169464000029],
              [15.677644730000111, 19.377540588000102],
              [15.676299095000047, 19.371219634000056],
              [15.676258088000111, 19.363784791000114],
              [15.676253318000079, 19.363006592000147],
              [15.676230431000079, 19.35903930600017],
              [15.676160812999967, 19.358514786000114],
              [15.674095154000042, 19.342960356999981],
              [15.673459053999977, 19.338157654999975],
              [15.66480731900009, 19.272909165000044],
              [15.658379555000124, 19.224449159000187],
              [15.656428336000033, 19.218019485000013],
              [15.654958725000029, 19.213180543000078],
              [15.654547691000118, 19.211822510000047],
              [15.65445995400006, 19.21153068600006],
              [15.654252052000118, 19.206768035999971],
              [15.653554916000132, 19.190851212000041],
              [15.654491425000117, 19.187149048000094],
              [15.655867577000095, 19.181695938000132],
              [15.650348664000092, 19.162559509000118],
              [15.643874168000139, 19.11859130900018],
              [15.640250206000132, 19.093982698000161],
              [15.639884948000088, 19.08857727000003],
              [15.638669967, 19.0705394740001],
              [15.634280205999971, 19.040847778000057],
              [15.631274223000048, 19.031623841000112],
              [15.630179406000138, 19.020519258000149],
              [15.625193594999985, 18.969989777000023],
              [15.624799729000074, 18.966878892000125],
              [15.624349593000147, 18.963327408000112],
              [15.615269660000024, 18.891309738000075],
              [15.613834380000071, 18.886003495000068],
              [15.611840249000011, 18.878633499000102],
              [15.611990929000058, 18.868759156000124],
              [15.607883454000103, 18.838178636000123],
              [15.602450372000021, 18.797670364999988],
              [15.602347374000033, 18.796140671000103],
              [15.600871085000165, 18.774219514000094],
              [15.598340989000064, 18.766149520999988],
              [15.596392631000185, 18.745332718000043],
              [15.596059800000091, 18.741769791000081],
              [15.595900535000112, 18.741073608000079],
              [15.593798637000134, 18.731866837000098],
              [15.593214034000141, 18.718677521000018],
              [15.592960357000038, 18.71293068000017],
              [15.591972350999981, 18.690671921000103],
              [15.589671135, 18.638801576000105],
              [15.586992265000049, 18.623069764000149],
              [15.585907936000126, 18.616706848000035],
              [15.587670327000012, 18.612140656000065],
              [15.587670327000012, 18.596290588999977],
              [15.587257385000157, 18.587276459000066],
              [15.583879471000103, 18.512880325000083],
              [15.582406998000181, 18.485065460000044],
              [15.581137658000159, 18.461099624000042],
              [15.576588630000174, 18.375204086000053],
              [15.574458123, 18.334959031000039],
              [15.573610305000045, 18.318929672000081],
              [15.572858810000128, 18.298950196000021],
              [15.569683074000068, 18.214405059000114],
              [15.569290160999969, 18.20875930699998],
              [15.566184045, 18.164052964000177],
              [15.564955712000085, 18.146375656000032],
              [15.562891960000059, 18.094099044000075],
              [15.562439919000042, 18.082649230000186],
              [15.562046051000152, 18.072668075000081],
              [15.559425353, 18.006246567000062],
              [15.559190750000027, 18.001882553000087],
              [15.558959007000169, 17.997552872000085],
              [15.558889388000182, 17.996244431000093],
              [15.558789253000157, 17.994390487000146],
              [15.556753158000163, 17.956491471000163],
              [15.556111335000082, 17.944543839000175],
              [15.55513095900011, 17.92629051300014],
              [15.554376602000161, 17.921195984000065],
              [15.553394318000073, 17.91455841100003],
              [15.55338955000019, 17.884912492000183],
              [15.55338955000019, 17.883800507000103],
              [15.553216935000023, 17.88164329600005],
              [15.551178932000084, 17.856220244999975],
              [15.551636696000116, 17.852893829000095],
              [15.552179336000052, 17.848958969000137],
              [15.553070068000068, 17.842470170000126],
              [15.551060676000077, 17.830730438000103],
              [15.550430297000048, 17.827049255000077],
              [15.547313691000113, 17.771047593000105],
              [15.544421195000041, 17.71907234300005],
              [15.545078278000176, 17.707605363000027],
              [15.544878959000073, 17.704690934000041],
              [15.542385102000082, 17.667993545000058],
              [15.538440705000141, 17.609958650000181],
              [15.539319991000013, 17.599870682000017],
              [15.538847924000152, 17.590520859000094],
              [15.538249016000179, 17.578689575000169],
              [15.535799981000139, 17.530269623000095],
              [15.531869889000177, 17.452592851000134],
              [15.525501251000094, 17.326503754999976],
              [15.524669647000053, 17.310016631999986],
              [15.524950028000092, 17.292119979000063],
              [15.520241737000163, 17.241336822000164],
              [15.521283151000148, 17.23531913700009],
              [15.521550178000155, 17.233779907999974],
              [15.519831658000044, 17.20064544700017],
              [15.519520760000091, 17.194662095000126],
              [15.518429757000149, 17.173620224000047],
              [15.519483566000019, 17.171985627000083],
              [15.5206289300001, 17.170209884000144],
              [15.51838016500011, 17.16122055000011],
              [15.517190934000041, 17.144857405999971],
              [15.514056205000145, 17.101732255000059],
              [15.513876914000093, 17.093183517000057],
              [15.513522147000174, 17.075519562000068],
              [15.513347626000041, 17.068002702],
              [15.513342856000179, 17.067779541000107],
              [15.512878417000024, 17.043090820000089],
              [15.509887695000032, 17.009521484000061],
              [15.510681152000188, 16.986877441000104],
              [15.508300782000049, 16.973693848000039],
              [15.509277343000122, 16.966674804000149],
              [15.508669852000025, 16.956125259000089],
              [15.508326530999966, 16.949724197000023],
              [15.507878303000041, 16.941356660000167],
              [15.505545616000177, 16.897871017000057],
              [15.473140717000149, 16.864059447999978],
              [15.453224182000099, 16.843276979000052],
              [15.438458442000126, 16.827848434000089],
              [15.3644104, 16.750480653000182],
              [15.330434800000148, 16.714956284000095],
              [15.328828812000097, 16.711029052000072],
              [15.27554512100005, 16.658206939000024],
              [15.26805687000018, 16.650779724000188],
              [15.259960175, 16.642749787000071],
              [15.258351325000149, 16.639280320000125],
              [15.219064711999977, 16.598060608000026],
              [15.195078850000129, 16.573183060000076],
              [15.194866180000133, 16.572963715000185],
              [15.187390327000173, 16.565210342000171],
              [15.182994842000028, 16.560646057999975],
              [15.171659470000066, 16.548873901000036],
              [15.113504410000075, 16.48848533600011],
              [15.098360061000164, 16.47275924700017],
              [15.009430886000075, 16.380283356000064],
              [14.999999999000181, 16.37046432600016],
              [14.956649780000077, 16.325326921000169],
              [14.922219277000124, 16.286380768000129],
              [14.913618088000021, 16.276639939],
              [14.83400344800009, 16.193380355000158],
              [14.817249298000092, 16.175840378000089],
              [14.810552596999969, 16.166091919000053],
              [14.799110413000051, 16.156482696000182],
              [14.746358871000041, 16.100242616],
              [14.715449333000038, 16.067258835000018],
              [14.693863869000154, 16.044225693000101],
              [14.675205232000053, 16.026737214000036],
              [14.646678925000117, 16.000001908000058],
              [14.638401032000104, 15.991372109000054],
              [14.636494636000066, 15.987915040000075],
              [14.63460636200017, 15.984489442000154],
              [14.626154901000177, 15.976317406000078],
              [14.622268676000033, 15.972559930000045],
              [14.621318817000031, 15.969348906999983],
              [14.610408783000082, 15.956041336],
              [14.594276427000068, 15.942279816],
              [14.55537891400013, 15.90373039300016],
              [14.542579652000029, 15.890419961000191],
              [14.524797439000167, 15.870454788000188],
              [14.514840127000184, 15.862190246000068],
              [14.504410744000097, 15.851403236000181],
              [14.45345783300013, 15.800441742000146],
              [14.446187973000121, 15.793281555000021],
              [14.438769340000135, 15.785976409999989],
              [14.421829224000021, 15.768527032000065],
              [14.416259765000063, 15.762790681000183],
              [14.414485932000161, 15.760914803000048],
              [14.392581941000117, 15.73775863700007],
              [14.384760857000117, 15.731551170000103],
              [14.357069016000139, 15.693664551000154],
              [14.356731416000059, 15.693240166000123],
              [14.328194619000158, 15.657376289000069],
              [14.325307846000157, 15.65281677199999],
              [14.324411392000059, 15.651399613000081],
              [14.322971344000109, 15.649612427000022],
              [14.318494797000142, 15.644055367000078],
              [14.315656661000162, 15.639966965000042],
              [14.311636925000073, 15.634177209000086],
              [14.30984401600017, 15.629100799000184],
              [14.308798790000026, 15.626140595000038],
              [14.30747413500012, 15.625318527000047],
              [14.306200981000075, 15.624528885000075],
              [14.306324006000068, 15.623446464000153],
              [14.306435584000042, 15.62246418],
              [14.299569130000179, 15.618557930000122],
              [14.279230117000168, 15.591910363000011],
              [14.277869225000188, 15.590109826000173],
              [14.275919914000099, 15.58823204100014],
              [14.241394044000174, 15.541132927000092],
              [14.235718728000052, 15.53538799200004],
              [14.228869439000107, 15.523670196000069],
              [14.215633392000143, 15.504140853000024],
              [14.186100005000185, 15.462551117000032],
              [14.177577018000136, 15.450702667000087],
              [14.159641265000175, 15.425767898000117],
              [14.125638962000096, 15.37704563200009],
              [14.123719215000165, 15.37585163100016],
              [14.123041153000031, 15.375431062000075],
              [14.119735718000186, 15.368540763999988],
              [14.116184234000116, 15.364331246000063],
              [14.114304543000117, 15.362103462000107],
              [14.105570794000016, 15.349687576000065],
              [14.105260849000047, 15.348782540000173],
              [14.103448867000054, 15.343482017000099],
              [14.096365928000125, 15.336355210000079],
              [14.092119216000128, 15.33014869699997],
              [14.06901454900003, 15.297546387000068],
              [14.051060677000123, 15.272211075000087],
              [14.047518731000139, 15.270130158000086],
              [14.047050477000141, 15.267148973000076],
              [14.046265602000119, 15.266038896000168],
              [14.045866966000176, 15.265474319000077],
              [14.043245316000139, 15.26177215600012],
              [14.037609100000111, 15.253811836000125],
              [14.035731316000124, 15.248526573000106],
              [14.030197144, 15.242621420999967],
              [14.028181075000077, 15.240469933000156],
              [14.012850762000085, 15.218399047999981],
              [14.012634277000188, 15.21811294500003],
              [14.00813102799998, 15.212189675000047],
              [14.007850647000168, 15.211180688000127],
              [14.007429123000122, 15.209659576000035],
              [13.970181465, 15.156531334000135],
              [13.954849244000172, 15.139734267000051],
              [13.944827079000049, 15.12676334400004],
              [13.944005013000094, 15.1256990440001],
              [13.933150292000164, 15.116258622000032],
              [13.931030272999976, 15.112580300000161],
              [13.917599678000101, 15.096241952000128],
              [13.918541908000122, 15.096243859000026],
              [13.908916474000023, 15.08684253600012],
              [13.869506835000038, 15.042839050000055],
              [13.864991188000033, 15.035722733000057],
              [13.86220932000009, 15.031338693],
              [13.862215043000106, 15.029932022000082],
              [13.862219811000159, 15.028352737999967],
              [13.858053208000115, 15.020998001000123],
              [13.851399421000167, 15.009256363000077],
              [13.84996128099999, 15.004137993000086],
              [13.84879875199999, 15.000000000000114],
              [13.848367692000068, 14.998456002000069],
              [13.847661971000036, 14.995921136000106],
              [13.845178605000058, 14.993199348000132],
              [13.843885422000085, 14.99178028200015],
              [13.842312813000092, 14.988006592000033],
              [13.839189529000123, 14.980508804000124],
              [13.830043793000129, 14.956594468000162],
              [13.82417011300015, 14.945779801000185],
              [13.810228347000191, 14.914607048000107],
              [13.796705246000101, 14.884370804000184],
              [13.796624184000166, 14.881845475000034],
              [13.796490669000036, 14.877706529000079],
              [13.79226017000002, 14.868510246000085],
              [13.789679527000089, 14.863208772000178],
              [13.790656090000027, 14.85425663],
              [13.789730072000111, 14.846670152000115],
              [13.791009904000134, 14.834118844000045],
              [13.791440009000098, 14.829901695999979],
              [13.790995598000052, 14.820712090000086],
              [13.791562080000176, 14.812314034000167],
              [13.792235374000086, 14.802335739000114],
              [13.794140816000038, 14.797283173000039],
              [13.794433594000111, 14.796274185000016],
              [13.794603347000191, 14.795689583000183],
              [13.799153328000159, 14.780063629000097],
              [13.803149223000105, 14.766740799000161],
              [13.807751656000164, 14.75139522500001],
              [13.809412956000131, 14.745852470000159],
              [13.80987739599999, 14.741892815000028],
              [13.811594009000032, 14.727245331000063],
              [13.810227394000037, 14.722628594000128],
              [13.809960365000052, 14.721729280000147],
              [13.805835724000076, 14.718813896000142],
              [13.797228813000174, 14.712731361000181],
              [13.796069146000036, 14.710575105000146],
              [13.795123099000023, 14.708818435000069],
              [13.788749695000092, 14.706500054],
              [13.78861522700015, 14.704982758000085],
              [13.788524628000062, 14.703974723000044],
              [13.783100128000115, 14.702729225000041],
              [13.781451226000115, 14.702349664000167],
              [13.77036857700017, 14.695652008000025],
              [13.768728257000134, 14.691972733000171],
              [13.761685372000159, 14.694302560000096],
              [13.752183915000103, 14.697444917000155],
              [13.748382569, 14.697961808],
              [13.74556923, 14.698344231000135],
              [13.706494330999988, 14.663304329000084],
              [13.702265739999973, 14.656856537000067],
              [13.694020272000046, 14.651320458000043],
              [13.690439224000045, 14.64815807500014],
              [13.679320335000114, 14.638338090000047],
              [13.677308083000071, 14.636560441000142],
              [13.676999092000187, 14.633228303000124],
              [13.676860810000107, 14.631731033000051],
              [13.678758621000043, 14.62705040100019],
              [13.67900085499997, 14.626452445000098],
              [13.682936668000139, 14.62251186300017],
              [13.685067177000121, 14.620380401000034],
              [13.69250488299997, 14.612936974000093],
              [13.691821099000094, 14.60696125000004],
              [13.691609383000127, 14.59374332499999],
              [13.691429138000046, 14.582599640000183],
              [13.693353653000031, 14.569404602000134],
              [13.69602203300002, 14.55111789800003],
              [13.675544738000099, 14.536902429000065],
              [13.673421859000086, 14.535428047000096],
              [13.665888785999982, 14.529890060000071],
              [13.662818909000123, 14.52942085300009],
              [13.659058571, 14.525270462000151],
              [13.657550813000171, 14.524309159000097],
              [13.644233704000044, 14.515819549000014],
              [13.640939712000034, 14.513719560000084],
              [13.636702538000065, 14.511956215000112],
              [13.635100365000142, 14.511289596000097],
              [13.633160592000138, 14.510482788000161],
              [13.609814643000163, 14.50458908000013],
              [13.600868224000067, 14.502330781000069],
              [13.597108841000136, 14.502534866000133],
              [13.592843056000049, 14.502765657000168],
              [13.588639259000104, 14.503811836000125],
              [13.586465836000173, 14.504351616000179],
              [13.578207017000068, 14.505116462000046],
              [13.572069167000109, 14.505682946000036],
              [13.566214561000095, 14.503368378000175],
              [13.559089660999973, 14.500550270000076],
              [13.544508933000088, 14.494784355000036],
              [13.533491133999973, 14.490855216000114],
              [13.519768714000065, 14.485961914000086],
              [13.499749183000176, 14.47784805399999],
              [13.499333381000042, 14.477636337000149],
              [13.493391038000141, 14.474610330000189],
              [13.47883129100012, 14.460068703000047],
              [13.475949288000152, 14.453869820000023],
              [13.475087165000105, 14.452013969000063],
              [13.473475457000177, 14.442815780000103],
              [13.473756790000095, 14.439234735000184],
              [13.476220131000048, 14.407888413000023],
              [13.477793694000127, 14.394884108999975],
              [13.479640961000143, 14.379631042000142],
              [13.480257988000119, 14.37766170499998],
              [13.481079101000091, 14.375041009000086],
              [13.485630036000146, 14.358268738000106],
              [13.485660553000116, 14.358104706],
              [13.485833168999989, 14.357191085000181],
              [13.488040924000131, 14.345409393000068],
              [13.493801117000089, 14.321519851000176],
              [13.496373177000066, 14.313673019000191],
              [13.499294282000108, 14.30476379400011],
              [13.500995637000074, 14.292586328000084],
              [13.517487527000014, 14.220174790000044],
              [13.518520355000021, 14.215640067999971],
              [13.519277573000181, 14.212462426000172],
              [13.528590202000032, 14.173380852000037],
              [13.529519081000046, 14.16961574600009],
              [13.53267097600019, 14.156841277000126],
              [13.535080909000158, 14.142131805000076],
              [13.539087296000105, 14.12488937500018],
              [13.546038626999973, 14.094964980000043],
              [13.549402237000095, 14.080496787000072],
              [13.549639701000046, 14.079476357000033],
              [13.551148414000068, 14.072986603000061],
              [13.554524421999986, 14.059117318000119],
              [13.557128907000106, 14.048411369000121],
              [13.559882164000101, 14.035763741000096],
              [13.560729981000179, 14.031870841000057],
              [13.563631057000066, 14.014169694000145],
              [13.566752433000033, 14.002867699000149],
              [13.567689895000115, 13.999470710000026],
              [13.570876120999969, 13.985623359000101],
              [13.57448577800011, 13.969927788000064],
              [13.576870917, 13.959561347999966],
              [13.578471184000023, 13.952603339000063],
              [13.581237792000138, 13.940778733000116],
              [13.58218574600005, 13.936726570000133],
              [13.585891725000181, 13.920895576000134],
              [13.58672237400009, 13.91762638],
              [13.586906433000081, 13.91690254100007],
              [13.587630271000137, 13.914047241999981],
              [13.590668678999975, 13.902058601000078],
              [13.594529152000121, 13.879541398000129],
              [13.597422600000073, 13.868672371000059],
              [13.601929664000124, 13.851741790000176],
              [13.607596397000123, 13.826785088000065],
              [13.614608765000185, 13.795901299000093],
              [13.61635399000005, 13.787551880000024],
              [13.618451118000053, 13.777520180000067],
              [13.620821952000085, 13.7713212970001],
              [13.623000146000038, 13.755910875000097],
              [13.626094819000173, 13.746035577000157],
              [13.628008843000089, 13.73707199200004],
              [13.631423951000045, 13.722515107000049],
              [13.633506776000161, 13.713636399000109],
              [13.634547235000184, 13.710688591000121],
              [13.630026817999976, 13.710663795000016],
              [13.616581918000122, 13.710591316000148],
              [13.61282157900007, 13.708740234000118],
              [13.611417771000106, 13.709020615000156],
              [13.607173920000093, 13.709871292000116],
              [13.592114449000064, 13.710741043000041],
              [13.58694457900009, 13.709343910000143],
              [13.581528663000142, 13.710021019000123],
              [13.561297417000048, 13.710176469000032],
              [13.554941177000103, 13.711303712000131],
              [13.542947770000126, 13.710342407000098],
              [13.474961282000095, 13.71056080000011],
              [13.465309142000137, 13.710289955000178],
              [13.465320587000065, 13.709368706000078],
              [13.456839562000141, 13.711178779000022],
              [13.443199158000027, 13.710429191000173],
              [13.430970193000121, 13.709466934000034],
              [13.416151046000095, 13.709181785000169],
              [13.408852577000062, 13.710298538000075],
              [13.392376899000055, 13.711841582000034],
              [13.376152038999976, 13.710396767000134],
              [13.370492936000119, 13.713591577000159],
              [13.363177299000085, 13.711878777000038],
              [13.362502097000174, 13.711721421000163],
              [13.359028817000024, 13.712803840000106],
              [13.353783608000072, 13.714445114000171],
              [13.349098206, 13.710055351000108],
              [13.341100692000168, 13.710021019000123],
              [13.338765145000139, 13.706563949999975],
              [13.33574009, 13.694701194000061],
              [13.33479213600009, 13.693293571000083],
              [13.33125972800002, 13.691464425000049],
              [13.317849159000048, 13.691040040000019],
              [13.314787864000152, 13.689437867000095],
              [13.313598633000083, 13.682999611000071],
              [13.308650971000134, 13.681176185000027],
              [13.306280135000122, 13.674741746000109],
              [13.299459458, 13.674300193000079],
              [13.297808648, 13.672691345000032],
              [13.298743247000175, 13.670621873000016],
              [13.300374986000065, 13.664178849],
              [13.298480987000119, 13.657970428000112],
              [13.293310165000037, 13.659370423000041],
              [13.283169746999988, 13.651570320000133],
              [13.274458886000048, 13.64745426200011],
              [13.271157264000067, 13.643092156000137],
              [13.275144576000116, 13.639632226],
              [13.275378227000147, 13.638251304000164],
              [13.269962311000029, 13.635734558000081],
              [13.265011788000152, 13.63114738600018],
              [13.261461257000065, 13.622408867000047],
              [13.257220268000083, 13.617824554000038],
              [13.249919891000104, 13.61508083300015],
              [13.248271942000088, 13.613018989000068],
              [13.252260208000052, 13.609560967000164],
              [13.25414085400007, 13.60633182600003],
              [13.254431725000018, 13.602264405000028],
              [13.254819871, 13.596900941000172],
              [13.262114525000129, 13.598722459000044],
              [13.26515960800009, 13.594116211000141],
              [13.264221192000093, 13.592511178000109],
              [13.256910324000046, 13.587921143000017],
              [13.257142067000132, 13.584474564000061],
              [13.254782677000151, 13.581488609000189],
              [13.251720428000169, 13.580579759000102],
              [13.243667603000063, 13.584467888000063],
              [13.239749908000022, 13.586361884999974],
              [13.238585473000057, 13.58592033400015],
              [13.235510826000109, 13.58475971200005],
              [13.235227585000018, 13.583726884000043],
              [13.234036445000072, 13.579369545000191],
              [13.232430457000078, 13.573489188999986],
              [13.233126640000023, 13.571652413000095],
              [13.238539696000089, 13.572098732000143],
              [13.24135017499998, 13.570481300999973],
              [13.242052078000086, 13.567029954000134],
              [13.241099358000099, 13.563579560000107],
              [13.237564087000067, 13.558300971999984],
              [13.232149124000159, 13.555317880000075],
              [13.229651452000098, 13.554727555],
              [13.221563339999989, 13.552818298000034],
              [13.221167564000154, 13.551092147000134],
              [13.220140456000081, 13.546611787000131],
              [13.218493461000094, 13.546155931000101],
              [13.215684889999977, 13.551451684000142],
              [13.214902878000146, 13.55081653700006],
              [13.214268684000047, 13.550298690000147],
              [13.209296226000049, 13.534214974],
              [13.208338738000066, 13.526625633000094],
              [13.200099945000034, 13.522500992000175],
              [13.198684693000189, 13.51882743800013],
              [13.199849128000153, 13.515138627],
              [13.199921608000125, 13.513957977000018],
              [13.200077056, 13.511463165],
              [13.19926357300011, 13.510802269000067],
              [13.198663712000155, 13.510315896000066],
              [13.194904328000121, 13.510786056000029],
              [13.191623687, 13.514931680000018],
              [13.186479569000028, 13.53011989700002],
              [13.183899880000183, 13.532200814000191],
              [13.179200173000083, 13.532440186000088],
              [13.165320397000073, 13.528330803000131],
              [13.162729263000074, 13.526266099],
              [13.162959099000091, 13.521889686],
              [13.161779404000072, 13.52143573800015],
              [13.15800857500011, 13.517760278000139],
              [13.159413338000093, 13.514081001000079],
              [13.158001899000112, 13.512932777000117],
              [13.155179976999989, 13.512708664000115],
              [13.149541855000166, 13.515482903000077],
              [13.143190383000103, 13.514801979000026],
              [13.142023086000052, 13.518027305000089],
              [13.145339013000068, 13.529752732000134],
              [13.144413947999965, 13.536654473000169],
              [13.149840354000105, 13.544919968000045],
              [13.148201942000071, 13.547690393000039],
              [13.145609855999965, 13.547231674000045],
              [13.139012337000054, 13.540114403000075],
              [13.133131981000076, 13.539207458000078],
              [13.129838944000028, 13.539211273000149],
              [13.124197960000117, 13.539683342000046],
              [13.122546195000098, 13.536236763000034],
              [13.128640176000147, 13.528401374000055],
              [13.12652492400008, 13.527261734999968],
              [13.125349044000131, 13.524729729000114],
              [13.127454758000113, 13.521507263000103],
              [13.127448082000058, 13.518981935000056],
              [13.122981071000027, 13.519680978000054],
              [13.105621337000059, 13.53534984700002],
              [13.104188920000126, 13.534024240000065],
              [13.100669860000096, 13.530757904000154],
              [13.093588829000112, 13.516281128000116],
              [13.090769769000133, 13.514676093000105],
              [13.086780549000082, 13.520433426000125],
              [13.083250999000086, 13.51951980500013],
              [13.079731941000091, 13.521595955000123],
              [13.071975708000025, 13.523221016000036],
              [13.067050935000111, 13.527138710000031],
              [13.06752300200003, 13.531049728000141],
              [13.07152462, 13.534952163000185],
              [13.075287818000163, 13.534944535000079],
              [13.081886292000036, 13.543674469000109],
              [13.08142089800009, 13.545741081000074],
              [13.079771041000129, 13.54667091500005],
              [13.075540542999988, 13.547370909999984],
              [13.064958572000137, 13.547148704999984],
              [13.057662965000191, 13.540266037000038],
              [13.054839135000122, 13.539353372000107],
              [13.049202919000038, 13.542579651000153],
              [13.047554969000146, 13.541433335000136],
              [13.044952392000027, 13.53200721800016],
              [13.045989036000151, 13.529009820000113],
              [13.046707154000046, 13.524408342000072],
              [13.041764259999979, 13.525529862000099],
              [13.03020572600002, 13.532140732000187],
              [13.02621841399997, 13.53096771200012],
              [13.011980057000073, 13.513870240000131],
              [13.007761002000109, 13.511089325000171],
              [13.004469872000129, 13.511071205000121],
              [13.001162530000045, 13.514042854000024],
              [12.995051384000135, 13.513780594000025],
              [12.986139298000126, 13.509821893000094],
              [12.975798606000183, 13.509530067000128],
              [12.974127770000166, 13.513896942000031],
              [12.970590592000065, 13.515482903000077],
              [12.966397286000188, 13.509482385000069],
              [12.961561204000077, 13.498127938000039],
              [12.961175919000084, 13.49721813300016],
              [12.960798263000129, 13.498127938000039],
              [12.958202362000179, 13.504377366000028],
              [12.954172135000022, 13.509643555000082],
              [12.946928978000187, 13.50293445599999],
              [12.93777561200011, 13.500122071000078],
              [12.931804657000157, 13.494589807000125],
              [12.93053341000001, 13.493412019],
              [12.924010277000036, 13.483248711000044],
              [12.906162263000169, 13.481081964000055],
              [12.901200295000137, 13.485191346000079],
              [12.892500878000021, 13.485601426000073],
              [12.891510009, 13.493020058000184],
              [12.87705993700007, 13.497050285000114],
              [12.87376022300009, 13.494979857999965],
              [12.872578622000105, 13.488080979000188],
              [12.873519897999984, 13.483942033],
              [12.875682830000073, 13.48156261400004],
              [12.879388809000034, 13.477490426000088],
              [12.879854202999979, 13.473350524000068],
              [12.878440857000101, 13.470132828000089],
              [12.874449730000038, 13.470370292000041],
              [12.865753174000076, 13.477047920000075],
              [12.863209725000047, 13.478096009000012],
              [12.861909867000122, 13.478630067000154],
              [12.858470916000044, 13.480045318000123],
              [12.856117248000032, 13.475906372000111],
              [12.852119446000188, 13.474068643000066],
              [12.851512910000054, 13.473278046000075],
              [12.849294663000137, 13.47039318100002],
              [12.851173401000153, 13.468550682000114],
              [12.853759765000063, 13.468318939000028],
              [12.858222962000184, 13.468041420000077],
              [12.861281395000162, 13.467848779],
              [12.862455368000042, 13.465550424000185],
              [12.861040116000027, 13.46325016100019],
              [12.860280991000025, 13.461680412000078],
              [12.857040406000181, 13.454970360000175],
              [12.855387687000132, 13.449454307000053],
              [12.856327058000147, 13.446694375000106],
              [12.86172771400004, 13.441169739000088],
              [12.862199783000108, 13.438870431000055],
              [12.86124897100018, 13.43657016800006],
              [12.854438782000045, 13.43841362],
              [12.848648071000071, 13.439024926000172],
              [12.847860336, 13.439108849000093],
              [12.845079421000037, 13.441207886000086],
              [12.841749192000123, 13.443719863000013],
              [12.837760925000055, 13.442568779000169],
              [12.835401535000074, 13.43797397600008],
              [12.838685989000055, 13.431760788000076],
              [12.837980271000106, 13.429000854000151],
              [12.834918975000107, 13.429003716000182],
              [12.83445358299997, 13.429003716000182],
              [12.832579613000178, 13.430471420000117],
              [12.832105636000165, 13.430845260000126],
              [12.82975578300011, 13.430617332000111],
              [12.827638626, 13.426250457000037],
              [12.816826820000188, 13.425566673000105],
              [12.815178872000104, 13.423269272000027],
              [12.816120149000085, 13.421199798000032],
              [12.820952415000022, 13.42068004600003],
              [12.829037667000023, 13.419810295000048],
              [12.83162117, 13.417270661000089],
              [12.833966255000178, 13.40439128800017],
              [12.831887245000075, 13.398709297000153],
              [12.831611633000023, 13.397953034000182],
              [12.827851296, 13.396801950000167],
              [12.826211929000181, 13.399801255000057],
              [12.826497078000045, 13.401287080000031],
              [12.826913834000095, 13.403474808],
              [12.825881959000071, 13.405788421000011],
              [12.82526874500013, 13.407157898000094],
              [12.824962616000107, 13.406993867000097],
              [12.823698044000139, 13.406331062999982],
              [12.822216987000047, 13.405550957000116],
              [12.819157601000029, 13.400724411000056],
              [12.821036339000045, 13.396580697000047],
              [12.819691659000171, 13.393301011000119],
              [12.819621086000097, 13.39313221000009],
              [12.819358826000098, 13.392968178000046],
              [12.81748581, 13.391807556000117],
              [12.805518150000125, 13.384403229000043],
              [12.800346375000061, 13.380954742000085],
              [12.797529220000115, 13.380728722000015],
              [12.797060013000078, 13.379811286000177],
              [12.794231416000059, 13.374980927000081],
              [12.795410156000116, 13.371761322000054],
              [12.794231416000059, 13.368770599000129],
              [12.787409783000101, 13.361183166000046],
              [12.78200435500014, 13.358195306000084],
              [12.78059101100007, 13.357740402000104],
              [12.772370339000076, 13.35406112700008],
              [12.762970924000115, 13.351771355000153],
              [12.754750251000019, 13.352009773000134],
              [12.753478051000116, 13.353459359000169],
              [12.753338814000017, 13.35361862100001],
              [12.744852067000124, 13.344862938000063],
              [12.740409851000095, 13.340278626000043],
              [12.737587929000028, 13.334992408000176],
              [12.738527298000065, 13.331770897000126],
              [12.74298954000011, 13.328779220000172],
              [12.742051124999989, 13.32509994500009],
              [12.739411354000026, 13.322518349],
              [12.738289832000135, 13.321418762000064],
              [12.730533601000047, 13.318894387],
              [12.714556695000113, 13.316830635000144],
              [12.714228629000104, 13.315755844000137],
              [12.713852882000026, 13.314532280000151],
              [12.720665931000042, 13.309006691000093],
              [12.721599580000088, 13.306710243000168],
              [12.718988418000038, 13.305354119000015],
              [12.717609405000076, 13.304637909000121],
              [12.714318275999972, 13.304180145000089],
              [12.709390640000152, 13.308320999000046],
              [12.70374965600007, 13.313389779000033],
              [12.698108674000139, 13.312238694000087],
              [12.692943572000161, 13.313161850000085],
              [12.691944123000155, 13.31130027800009],
              [12.690594674000124, 13.308793068000114],
              [12.69388294099997, 13.30050754500013],
              [12.689179421, 13.290390968],
              [12.684142112000075, 13.283997536000186],
              [12.678897859000188, 13.277347565000014],
              [12.676488876000121, 13.274291039000104],
              [12.67367362900012, 13.272912027000189],
              [12.669680595000159, 13.273142815000085],
              [12.668272971000022, 13.273833275000015],
              [12.667098999000075, 13.278657914000178],
              [12.66380977700004, 13.284649849000061],
              [12.659818650000147, 13.28557109799999],
              [12.657489775999977, 13.284543037000105],
              [12.655119897000134, 13.283498765000047],
              [12.650892259000102, 13.283732414000099],
              [12.648309708, 13.286028862000023],
              [12.648770332000083, 13.287070275000133],
              [12.648042678000138, 13.290103912000063],
              [12.647089958000151, 13.294080734000147],
              [12.647040366000169, 13.300521851000099],
              [12.645538330000022, 13.300410270000157],
              [12.645116806000146, 13.300377846000174],
              [12.643206596000027, 13.299947739000174],
              [12.641394616000184, 13.298149109000065],
              [12.640257836000103, 13.297019004000049],
              [12.638268470000185, 13.29168128900011],
              [12.633810043000153, 13.279720306000172],
              [12.626111030000061, 13.272311211000044],
              [12.61812973100001, 13.271800042000109],
              [12.609399795000172, 13.276570321],
              [12.59766960200011, 13.275799752000125],
              [12.589188575000151, 13.277810096],
              [12.588740348999977, 13.275971413000036],
              [12.592329980000102, 13.26748085000014],
              [12.591418267000051, 13.263333321000061],
              [12.581031800000176, 13.270161628000096],
              [12.578932762000022, 13.268305779000116],
              [12.578473091000092, 13.265163421000182],
              [12.577151298000047, 13.256098747000124],
              [12.5761137, 13.253562928000179],
              [12.574608802, 13.24987507000003],
              [12.56628608699998, 13.240485192000108],
              [12.56599044800015, 13.240150452000137],
              [12.566249848000098, 13.237394333000054],
              [12.568964005000055, 13.234672547000116],
              [12.572401046000152, 13.231231688999969],
              [12.568209647000174, 13.226140023000085],
              [12.557440757000165, 13.222610475000067],
              [12.554310798000131, 13.219867706000059],
              [12.553473472000121, 13.219134332000124],
              [12.552898407000043, 13.204023361000111],
              [12.552886964000095, 13.203718185000071],
              [12.550089836000097, 13.200942039000097],
              [12.547036170000013, 13.201145172000111],
              [12.540604590999976, 13.21282958900008],
              [12.53773784700013, 13.212988854000059],
              [12.536549568000112, 13.213055612000062],
              [12.536058426000181, 13.212424278000015],
              [12.537470817000042, 13.207366943000181],
              [12.537068368000064, 13.205337525000118],
              [12.536750793000181, 13.203736305000177],
              [12.540289879000056, 13.195861816000104],
              [12.543574333000038, 13.192411422000077],
              [12.548272133000069, 13.190571786000135],
              [12.55320358400013, 13.191032410000048],
              [12.558209418999979, 13.196329118000051],
              [12.559073449000095, 13.197242735999964],
              [12.56003856699999, 13.197624206000114],
              [12.561417579000079, 13.198165894000169],
              [12.561997413000086, 13.197385788000076],
              [12.565177917000142, 13.19310379000018],
              [12.555554389000065, 13.181368827000085],
              [12.554226874000108, 13.178050042000052],
              [12.553441046999978, 13.176078797000059],
              [12.552968978000081, 13.167800904000046],
              [12.553713798000103, 13.163735390999989],
              [12.554149628000118, 13.161359787000094],
              [12.558849336000094, 13.157670022000048],
              [12.553213119000134, 13.15422153500009],
              [12.5499258050001, 13.153301238999973],
              [12.547275543000012, 13.150379180000073],
              [12.546170234000158, 13.149162293000188],
              [12.544533730000126, 13.145352364000189],
              [12.542416572999969, 13.140419006],
              [12.539600372000109, 13.13927078200004],
              [12.53795623700006, 13.140419006],
              [12.537019730000111, 13.146169663000023],
              [12.532080651000115, 13.146860123000124],
              [12.526221275000069, 13.146169663000023],
              [12.518699647000176, 13.149850845000174],
              [12.512128829000062, 13.143170357000031],
              [12.504386901000032, 13.139031411000076],
              [12.497982979000085, 13.132291795000185],
              [12.497566224000138, 13.131852150000157],
              [12.494764328000088, 13.128905296000028],
              [12.493593216000022, 13.126540185000181],
              [12.492450713999972, 13.124233245000084],
              [12.491838456000153, 13.122995377000166],
              [12.491194726000174, 13.121696473000156],
              [12.490777969000078, 13.120853424000131],
              [12.490777969000078, 13.120532035999986],
              [12.490777969000078, 13.119704247000186],
              [12.490962981999985, 13.119364739000162],
              [12.491256714000144, 13.11882686600012],
              [12.492017747000148, 13.117429733999984],
              [12.492606164000051, 13.116352081000116],
              [12.49339199100018, 13.114909173000058],
              [12.494052887000123, 13.113692283000091],
              [12.49446964200007, 13.112925529999984],
              [12.494545937000055, 13.11280822700013],
              [12.495046616000025, 13.112001419000023],
              [12.495282172000145, 13.111626624000166],
              [12.495528220999972, 13.111232758000085],
              [12.496418953000159, 13.109810829000139],
              [12.494605064999973, 13.106701851000082],
              [12.493811608000158, 13.105337144000089],
              [12.492735863000064, 13.103490830000055],
              [12.491725921000182, 13.101760864000141],
              [12.492430686000091, 13.098310470000115],
              [12.494483947000106, 13.096193313000128],
              [12.496891022000057, 13.09371090000019],
              [12.49055862400013, 13.090021134000096],
              [12.491732598000112, 13.088186263000068],
              [12.496431351000126, 13.087039947000051],
              [12.496899605000124, 13.083819389000041],
              [12.495961189000127, 13.082896233000042],
              [12.487811089000047, 13.08369159800003],
              [12.484223366000151, 13.084040642000161],
              [12.48093891200017, 13.083580017000145],
              [12.479295731000036, 13.081049919000066],
              [12.482588768000085, 13.070700646000034],
              [12.481884957000034, 13.068701745000112],
              [12.480481148000138, 13.064720154000099],
              [12.476720810000018, 13.062409400000035],
              [12.470858573000044, 13.063329696000153],
              [12.461229324000101, 13.069540977000088],
              [12.452780723000103, 13.0718402870001],
              [12.442690850000076, 13.071600914000101],
              [12.433530808000171, 13.071141242000067],
              [12.41804027500018, 13.075961113000176],
              [12.409818650000034, 13.078721047000101],
              [12.407009126000048, 13.078950883000118],
              [12.404899597000053, 13.077561378000155],
              [12.400440216000106, 13.074110031000146],
              [12.402078629000073, 13.077330590000088],
              [12.396449089000043, 13.077791214000172],
              [12.386404990000074, 13.076771736000182],
              [12.378141403000143, 13.075936317000071],
              [12.37038993800013, 13.076622009000118],
              [12.368750573000113, 13.078460693000068],
              [12.369447708000166, 13.084899902000188],
              [12.368740082000045, 13.086971284000128],
              [12.365690232000134, 13.089729310000109],
              [12.362399101000051, 13.090191841999967],
              [12.361699105000184, 13.088120460000027],
              [12.361708641000121, 13.08076095500013],
              [12.355369568000071, 13.07845020200017],
              [12.352086067000073, 13.079367638],
              [12.347499847000051, 13.083128929000111],
              [12.343393325000079, 13.086492539000062],
              [12.340815544000066, 13.084189416000186],
              [12.339651107000066, 13.073150636000094],
              [12.336835860000122, 13.071764945999973],
              [12.334016800000086, 13.074290275000124],
              [12.332840920000137, 13.077738760999978],
              [12.334710121000171, 13.083040237000034],
              [12.32978153200014, 13.08487033800003],
              [12.323208809000164, 13.085785866000151],
              [12.32227039300011, 13.086469650000026],
              [12.316630363000115, 13.088999749000038],
              [12.311227798000118, 13.093827248000025],
              [12.307236671000055, 13.095664024000143],
              [12.297377586000096, 13.096113205000108],
              [12.294327736000184, 13.095419885000126],
              [12.287055014999964, 13.091962815000045],
              [12.28048896700011, 13.086202623000077],
              [12.282372475000159, 13.083676339000135],
              [12.288009644000169, 13.082299232000139],
              [12.288475036000136, 13.081152916000121],
              [12.287069321000104, 13.079999924000106],
              [12.277681351000126, 13.080908775000069],
              [12.27486038100011, 13.083210945000133],
              [12.272509576000061, 13.088730811000175],
              [12.272970200000145, 13.095399856000142],
              [12.270430566000186, 13.103046416999973],
              [12.270374298000149, 13.103219033000073],
              [12.268148421999967, 13.105991363000101],
              [12.265198708000185, 13.109648704000108],
              [12.260197639000182, 13.112588882000011],
              [12.256979943000033, 13.114480973000184],
              [12.241249084000117, 13.114692688000048],
              [12.239351272000079, 13.113823892000084],
              [12.238200188000064, 13.113300323000033],
              [12.23375129700014, 13.107316972000092],
              [12.230230332000019, 13.10777092100011],
              [12.218482971000185, 13.113969804000078],
              [12.210970879000172, 13.114878654000108],
              [12.207205773, 13.120396615000061],
              [12.202742577000038, 13.122692108000024],
              [12.194762230000094, 13.122222901000043],
              [12.18842601800003, 13.120369911000182],
              [12.1839656840001, 13.121055603000059],
              [12.183665275000067, 13.121286392000059],
              [12.178559302999986, 13.12518978200012],
              [12.176924706000023, 13.120359421000046],
              [12.177047730000083, 13.120092393000107],
              [12.179280282000036, 13.115299226000104],
              [12.187509537000153, 13.106570245000057],
              [12.180238724000105, 13.103797912000061],
              [12.177431105999972, 13.098271371000124],
              [12.173200607000183, 13.100566864000086],
              [12.170379638000099, 13.103781701000059],
              [12.163576126000066, 13.102162361000012],
              [12.161931039000137, 13.103310584999974],
              [12.157695771000022, 13.108136178000052],
              [12.150888444000145, 13.108585359000188],
              [12.149015426000062, 13.105589866],
              [12.151141166000059, 13.098463060000086],
              [12.149270058000184, 13.096852302000059],
              [12.142218590000084, 13.099140167000087],
              [12.139402389000054, 13.09936523500005],
              [12.136936188000163, 13.101918221000062],
              [12.135226250000073, 13.103691102000141],
              [12.134724617000074, 13.104210854000144],
              [12.132017135000069, 13.101551057000052],
              [12.131420135000042, 13.100962639000045],
              [12.129846573000066, 13.091151237000076],
              [12.123030663000065, 13.091552734000174],
              [12.118101120000176, 13.092200279000167],
              [12.114126206000094, 13.090326309000034],
              [12.104241371000114, 13.093460083000139],
              [12.073551178, 13.111829758000113],
              [12.047353745, 13.126548768000077],
              [12.045447350000131, 13.129753113000106],
              [12.040974616000085, 13.130863190000014],
              [12.027029038000137, 13.140862464000122],
              [12.014717103000066, 13.151331902000038],
              [12.013334274000158, 13.154356004000135],
              [12.011548995000112, 13.158253669000146],
              [11.994985581000151, 13.173199653999973],
              [11.994669914000042, 13.17345619200006],
              [11.994068146000188, 13.173940659000095],
              [11.991628646000095, 13.175321579000126],
              [11.991033554000069, 13.175686836000068],
              [11.989112854000155, 13.176866531000087],
              [11.985837937000156, 13.179351806999989],
              [11.985655784000073, 13.179491044000088],
              [11.98128890900017, 13.182913780000092],
              [11.975223540000059, 13.187401771999987],
              [11.969485284000086, 13.191755294000131],
              [11.957325936000132, 13.200978279000083],
              [11.950197220000064, 13.206507683000154],
              [11.934343337000087, 13.218552590000115],
              [11.928756713000041, 13.222748756000044],
              [11.928533555000058, 13.222914696000089],
              [11.917762757000048, 13.228652000000181],
              [11.915191650000054, 13.230018617000155],
              [11.913200379000045, 13.23108100900015],
              [11.911989212000094, 13.231723787000021],
              [11.910582542999975, 13.232474327000148],
              [11.909906386999978, 13.232850075000158],
              [11.904631615000028, 13.235449791000121],
              [11.889108657000122, 13.243110658000091],
              [11.884585380000033, 13.245344162000151],
              [11.884561539000117, 13.246546745999979],
              [11.882786751000026, 13.247151375000044],
              [11.875433922000127, 13.24965763199998],
              [11.86941146900017, 13.250917436000123],
              [11.820720673000096, 13.261093140000071],
              [11.816979408000066, 13.262180329000103],
              [11.810582160000081, 13.26387119300017],
              [11.810226441000054, 13.263971328000025],
              [11.80874538400019, 13.26426506100006],
              [11.786145210000029, 13.268507003000082],
              [11.777282715000126, 13.268495560000076],
              [11.771877289000145, 13.271888734000186],
              [11.76457500399999, 13.27254295400013],
              [11.764306069000042, 13.272567748000029],
              [11.762016297000116, 13.273049356000115],
              [11.751152993000119, 13.275319100000161],
              [11.743858338000109, 13.275574684000162],
              [11.738942146000113, 13.277788163000082],
              [11.728327751, 13.280014037],
              [11.721629142000097, 13.281419755000172],
              [11.695807457000114, 13.287004472000149],
              [11.695612908000044, 13.287063600000067],
              [11.685855866000111, 13.290093422000098],
              [11.676262856000051, 13.293132782000043],
              [11.671777726000073, 13.294554710999989],
              [11.671479225000155, 13.294651031000171],
              [11.665639878000036, 13.295871735000105],
              [11.661141395000072, 13.299375533999978],
              [11.651965142000165, 13.30170345300013],
              [11.637608527000054, 13.305277824000029],
              [11.635674477000066, 13.306108475000087],
              [11.625568390000126, 13.310444833000076],
              [11.609742164000181, 13.317123413000047],
              [11.601497650000113, 13.319116592],
              [11.600921631000176, 13.319496155000081],
              [11.600440978000108, 13.319939612999974],
              [11.595231056000159, 13.322687149000103],
              [11.590636254000117, 13.325262070000122],
              [11.539630891000115, 13.347957611000083],
              [11.539232255000172, 13.348052978000055],
              [11.522156715000051, 13.352137567000057],
              [11.522038459000044, 13.353051187000176],
              [11.517484666000087, 13.352795600000036],
              [11.517256736999968, 13.352783204000104],
              [11.485487939000052, 13.356804848000138],
              [11.48277568899999, 13.356429100000128],
              [11.480093001000114, 13.356060028000172],
              [11.473759652000183, 13.357019425000033],
              [11.46870041000011, 13.357914925000046],
              [11.466429711000046, 13.358963967000022],
              [11.46430587899999, 13.36002635900013],
              [11.464036941000131, 13.360063553000032],
              [11.461457253000049, 13.360433578000027],
              [11.450890540000159, 13.361950874000115],
              [11.437014580000096, 13.363693238000053],
              [11.434178352000117, 13.364077569000131],
              [11.420533180000177, 13.366200448000029],
              [11.413884163000034, 13.367232324000156],
              [11.395575523000048, 13.370079040000121],
              [11.376161576000015, 13.371458054000186],
              [11.367590904, 13.372065545000112],
              [11.366031646000124, 13.372110368000165],
              [11.363137246000122, 13.372186661000171],
              [11.326454162, 13.373196601000075],
              [11.325529098000061, 13.373216629000126],
              [11.302426338000032, 13.373399735000021],
              [11.302249908000022, 13.373394966000035],
              [11.302261353000176, 13.372473717000105],
              [11.296408654000061, 13.373675346000141],
              [11.295895577000124, 13.373709680000161],
              [11.293822288000115, 13.373846054000069],
              [11.290134430000137, 13.374137878000056],
              [11.283917426000187, 13.374160766000159],
              [11.275139809, 13.374199868],
              [11.269502640000155, 13.37426090299999],
              [11.261501313000053, 13.374347687000068],
              [11.258426666000162, 13.374382019000109],
              [11.258019447000095, 13.374385834000122],
              [11.212706566000179, 13.374882698000022],
              [11.212706566000179, 13.374997140000119],
              [11.212712287000045, 13.375856401000021],
              [11.205649376000167, 13.375288964000106],
              [11.205089569999984, 13.375274658000137],
              [11.180986405000112, 13.374736786000028],
              [11.177650451000147, 13.375418663000062],
              [11.175332070000081, 13.37589454700003],
              [11.174625397000113, 13.376041412000177],
              [11.173216819000118, 13.375856401000021],
              [11.172890663000146, 13.375812531000122],
              [11.167639732000055, 13.375126839000075],
              [11.166880607000053, 13.375046730000122],
              [11.166744233000145, 13.375034332000155],
              [11.166383743000154, 13.375049591000106],
              [11.157256126000163, 13.375574112000038],
              [11.154461860000083, 13.375733374999982],
              [11.145486832000131, 13.376064301000042],
              [11.145135880000055, 13.376049995000074],
              [11.143399239000189, 13.375980377000189],
              [11.138731003000089, 13.375487328000133],
              [11.136190414000112, 13.375407220000113],
              [11.134129524000116, 13.375218391000033],
              [11.13257789700009, 13.37507534100007],
              [11.096813201000145, 13.374509812000099],
              [11.096575737000023, 13.37450504300017],
              [11.068675042000166, 13.373996736000095],
              [11.066247940999972, 13.375136375000181],
              [11.065131187000134, 13.375631333000058],
              [11.059260369000015, 13.374012947000097],
              [11.05884742700016, 13.373891831000037],
              [11.033654214000137, 13.373290063000184],
              [11.024991035000028, 13.372373580000101],
              [11.024979592000022, 13.37329483100001],
              [11.024808884000095, 13.37329483100001],
              [11.013456345000066, 13.373417853999968],
              [10.985500335000154, 13.373766900000078],
              [10.985363961000019, 13.373739244000092],
              [10.977293015000157, 13.372235298000021],
              [10.976684569999975, 13.372202873999981],
              [10.97650909400005, 13.372192384000186],
              [10.929299354000079, 13.369570731000067],
              [10.923527718000059, 13.369203569000092],
              [10.921826363000037, 13.369057655000063],
              [10.912481308000167, 13.36825561500018],
              [10.911554337000098, 13.368246079000073],
              [10.907458305000148, 13.368195534000051],
              [10.900190353000085, 13.368160247999981],
              [10.881507873999965, 13.366443635000167],
              [10.881033897000123, 13.366450310000118],
              [10.860376359000099, 13.366640091000079],
              [10.84906864200002, 13.365164757000059],
              [10.839904785000044, 13.36494159700004],
              [10.828058242000168, 13.364485740000077],
              [10.821853637000061, 13.364185334000069],
              [10.793890954, 13.36280822700013],
              [10.791563987000131, 13.36265277900003],
              [10.777914047000138, 13.361707688000081],
              [10.755667687000141, 13.360174179000126],
              [10.755174637000039, 13.360161782999967],
              [10.753245354000171, 13.360112190000052],
              [10.734041214000172, 13.359634400000118],
              [10.709832192000135, 13.358282090000159],
              [10.70542430800009, 13.358136178000166],
              [10.704218863999984, 13.358097076000149],
              [10.669219972000178, 13.356932641000128],
              [10.666468620000103, 13.356398582000111],
              [10.654708862000177, 13.354116440000098],
              [10.635457039000016, 13.347290040000189],
              [10.616800308000109, 13.34046363900012],
              [10.60887527400007, 13.337243081000111],
              [10.608716011000126, 13.337175370000125],
              [10.600489616000061, 13.333635330000106],
              [10.586689949, 13.329300881000052],
              [10.572211266000124, 13.323807717000136],
              [10.569966315999977, 13.323056221000115],
              [10.56202602400009, 13.320487023000169],
              [10.561452864999978, 13.320211411000116],
              [10.546547891000102, 13.313050270000076],
              [10.546249389000081, 13.312964438999984],
              [10.53584289500003, 13.309929849000071],
              [10.528266907000102, 13.307209014000136],
              [10.484379768000167, 13.290810585000031],
              [10.484094619000075, 13.290682791999984],
              [10.474166869000044, 13.286087989000066],
              [10.466402053000081, 13.282537460000185],
              [10.451866149000182, 13.281817437000029],
              [10.450825691000034, 13.281766892],
              [10.438491822000174, 13.280299188000072],
              [10.438294411000072, 13.280307770999968],
              [10.43295574300015, 13.280648232000033],
              [10.432317733000104, 13.280599595000183],
              [10.431921005000163, 13.280570031000025],
              [10.431342125000015, 13.280504227000108],
              [10.417193412000074, 13.278912545000026],
              [10.413623810000104, 13.278541566000115],
              [10.403306961000112, 13.277473450000059],
              [10.402795792, 13.277453422000178],
              [10.39249897000019, 13.277014732000112],
              [10.385470390000023, 13.276681901000188],
              [10.378727912000102, 13.27633762500011],
              [10.345953941000062, 13.274662971000112],
              [10.330186845000071, 13.274057388000188],
              [10.323702811000032, 13.273805619000086],
              [10.306903839000086, 13.272844314],
              [10.300733567000066, 13.272488594000095],
              [10.296335221000106, 13.272255898000026],
              [10.288064002999988, 13.271752358000072],
              [10.269202232000168, 13.270630837000112],
              [10.257756233, 13.271367074000182],
              [10.250432968000098, 13.26986789700004],
              [10.245609284000125, 13.269635201000142],
              [10.242876053000032, 13.269507408000152],
              [10.24247169500012, 13.269487380999976],
              [10.235905647000095, 13.26917743700011],
              [10.207091332000118, 13.267767906000074],
              [10.206983567000179, 13.267729759000076],
              [10.195066452999981, 13.263225555000076],
              [10.194769859000132, 13.26311683800003],
              [10.161364556000137, 13.248705865000034],
              [10.145495415000084, 13.241146088000107],
              [10.145262717000037, 13.241047859000048],
              [10.135023117000117, 13.236748695000131],
              [10.125456809000127, 13.232733727000095],
              [10.122914315000116, 13.230393409999976],
              [10.118316650000111, 13.228468894000116],
              [10.100683213000082, 13.221092224000131],
              [10.100063324000075, 13.220817566000107],
              [10.085250855000083, 13.214236260000064],
              [10.061150551000139, 13.203574179999976],
              [10.060874940000133, 13.203451157000018],
              [10.049489022000103, 13.196138382000072],
              [10.039518357000134, 13.189730644000122],
              [10.033288955000046, 13.183422088000043],
              [10.01630210899998, 13.175068855000177],
              [10.01599884, 13.174888611000029],
              [10.011457444000143, 13.17218589700002],
              [10.0, 13.165362357000163],
              [9.996533393000163, 13.16327476399999],
              [9.991482734000158, 13.156521798000085],
              [9.985449791000065, 13.152284621999968],
              [9.983854294000139, 13.14949703100018],
              [9.98190784500008, 13.149603844000069],
              [9.980107308000072, 13.145750046000046],
              [9.979028702000164, 13.143437385000084],
              [9.941013335999969, 13.101639748000025],
              [9.912678718, 13.070570945000043],
              [9.901128769000138, 13.060029983999982],
              [9.88653755100006, 13.048746110000025],
              [9.87768077800007, 13.040509224000061],
              [9.872682572000088, 13.035861969000052],
              [9.869398118000106, 13.035806656000034],
              [9.846767425000166, 13.014950752000118],
              [9.832783699000061, 12.996084213000074],
              [9.816585541000109, 12.97004318200004],
              [9.811966896000115, 12.966053963000093],
              [9.803277969000021, 12.953254700000059],
              [9.794327735000024, 12.94205856300016],
              [9.790371895000135, 12.940150260999985],
              [9.776783943000112, 12.926116944000171],
              [9.769898415000057, 12.917490005000047],
              [9.762963295000191, 12.911851883],
              [9.710214615999973, 12.858248711000044],
              [9.695457459000124, 12.844426156000111],
              [9.688586235000059, 12.835330010000121],
              [9.675053596000055, 12.823257446000071],
              [9.665971755000101, 12.815155029000152],
              [9.650238991000094, 12.80360698800007],
              [9.637016296000184, 12.804368019000094],
              [9.618089676000068, 12.805457115000138],
              [9.617136955000035, 12.805512427999986],
              [9.613964081, 12.805584908000128],
              [9.606210708000049, 12.805650711000169],
              [9.590266228000075, 12.805865289000167],
              [9.587555886000189, 12.806448936000038],
              [9.580950736000091, 12.807870864999984],
              [9.580745697000111, 12.807915688000094],
              [9.57277965600008, 12.807347298000138],
              [9.572016715000132, 12.807339668],
              [9.55379295400013, 12.807228088000045],
              [9.553663253000138, 12.807226180000043],
              [9.546731948000172, 12.80844688500008],
              [9.546625138000024, 12.808467866000115],
              [9.5452919, 12.808561325000142],
              [9.536968230000184, 12.80915355799999],
              [9.53174686300008, 12.80952835100004],
              [9.531233787000076, 12.80956363700011],
              [9.529829978, 12.809518815000104],
              [9.511301040000092, 12.809194565000098],
              [9.504069329000117, 12.809671402000049],
              [9.488226891000068, 12.810814858000128],
              [9.460939408000115, 12.815196038000067],
              [9.435133933999964, 12.819338798000047],
              [9.429696083000124, 12.821540833000086],
              [9.421527863000165, 12.821673394000129],
              [9.420486450000055, 12.821690560000093],
              [9.420155524000052, 12.821704864000026],
              [9.414442061999978, 12.821704864000026],
              [9.411308289000147, 12.822096825000074],
              [9.411068916000147, 12.822109223000041],
              [9.402046203000111, 12.822501183000099],
              [9.39095783300013, 12.82305431400016],
              [9.39026546600013, 12.823088646000144],
              [9.390162468000142, 12.823088646000144],
              [9.389982223000061, 12.823024750000172],
              [9.382119179000142, 12.820233345000076],
              [9.381889343000125, 12.820151328000122],
              [9.370923997000034, 12.81833744100004],
              [9.365283013000123, 12.817373276000126],
              [9.353313447000119, 12.817112922999968],
              [9.346109389000162, 12.814487458000087],
              [9.340645791000043, 12.813915253000062],
              [9.32738590200006, 12.812528611000118],
              [9.324976921000143, 12.812376976000053],
              [9.323504448000051, 12.812280655],
              [9.318917275000047, 12.811988831],
              [9.305597306000095, 12.811153412000067],
              [9.291956901999981, 12.813043595000067],
              [9.288004874000023, 12.812058449000119],
              [9.280432702000041, 12.815107347000151],
              [9.258314133, 12.819786071000181],
              [9.251390458000117, 12.823723793],
              [9.251280785000176, 12.823787688999971],
              [9.243671417000144, 12.824956894000024],
              [9.243417739999984, 12.824995995000108],
              [9.242914198999984, 12.824977875000059],
              [9.236467362000099, 12.824735641000075],
              [9.229843139000138, 12.824489594000056],
              [9.226023675000135, 12.826970100000153],
              [9.220487595000179, 12.828437806000068],
              [9.220363617000032, 12.828437806000068],
              [9.201081276000025, 12.828193665000015],
              [9.191289902000108, 12.828070642000057],
              [9.178863525, 12.827914237000186],
              [9.169425965000016, 12.830725671000096],
              [9.166825295000137, 12.832145691000164],
              [9.166563034000035, 12.832221031000188],
              [9.165237426000147, 12.832588196000074],
              [9.160223006000024, 12.833733559999985],
              [9.159982682000191, 12.833790780000129],
              [9.147079468000186, 12.833977699000059],
              [9.146819115000028, 12.833875656000032],
              [9.143337250000172, 12.832576753000126],
              [9.138260842000079, 12.834221840000112],
              [9.138160705000018, 12.834256172000096],
              [9.129264831000114, 12.834566116000133],
              [9.10741710700006, 12.835297585000035],
              [9.101774215000148, 12.83584880900014],
              [9.101318359000118, 12.835894586000109],
              [9.095538140000144, 12.83625698100019],
              [9.093297958000051, 12.836402893000184],
              [9.081213951000052, 12.835007667000184],
              [9.074934960000178, 12.836915969000131],
              [9.07336902600008, 12.83738994600003],
              [9.061342240000101, 12.836938858],
              [9.061057092000169, 12.836945533000119],
              [9.060476302000041, 12.836963654000101],
              [9.054718972000103, 12.837143897000033],
              [9.032582283000011, 12.838055610000026],
              [9.02339935200007, 12.838434220000124],
              [9.014701842000022, 12.838791847000095],
              [9.014396668000188, 12.838806152000132],
              [9.01190090099999, 12.839090348000013],
              [8.997537613000134, 12.840733528000101],
              [8.994213105000029, 12.840334892000158],
              [8.991832733000081, 12.840049743000122],
              [8.983735084000045, 12.839078903000086],
              [8.982596398000169, 12.838301659000081],
              [8.981775284000094, 12.837740898000106],
              [8.976986885000088, 12.834470749000161],
              [8.975410461000024, 12.833395004000067],
              [8.969788550000033, 12.833052635000058],
              [8.956508636000081, 12.839921952000111],
              [8.948965071999965, 12.841840745000127],
              [8.939524650000124, 12.844647408000128],
              [8.934303285, 12.847530366000058],
              [8.92251777700011, 12.850290298000175],
              [8.882370949000119, 12.863061904000119],
              [8.880823136000061, 12.864038467000057],
              [8.878830910000033, 12.865298270999972],
              [8.870726586000046, 12.87041664100019],
              [8.860618592000037, 12.870453835000092],
              [8.852830887000039, 12.873272895000071],
              [8.852469445000168, 12.873407364000116],
              [8.830341338000096, 12.881114960000104],
              [8.826704978000123, 12.880889892000141],
              [8.824522018000153, 12.880760193000185],
              [8.812313081000127, 12.886532784000053],
              [8.809591293000153, 12.887818337000112],
              [8.8064775470001, 12.888580322000166],
              [8.787896157000091, 12.893125535000081],
              [8.783985138000048, 12.895212173000061],
              [8.781248092999988, 12.896670341000117],
              [8.777347565000014, 12.896696090000034],
              [8.772568703000047, 12.896724700999982],
              [8.76031589500019, 12.899236679000182],
              [8.74187755600002, 12.906345367000029],
              [8.731980324000062, 12.910162926000169],
              [8.72575283000009, 12.912563324000189],
              [8.714939118000132, 12.914414406000049],
              [8.709796905000189, 12.913616181000123],
              [8.703640938000035, 12.916243554],
              [8.700064659000134, 12.91650772100013],
              [8.699226379000038, 12.9165697090001],
              [8.696354867000139, 12.916782379000097],
              [8.692424773000028, 12.91883278],
              [8.690884591000156, 12.919636727000011],
              [8.690479278999987, 12.919844626999975],
              [8.688899993000064, 12.920551300000113],
              [8.68595981500016, 12.921863557000052],
              [8.683180810000181, 12.923106193000024],
              [8.680771827000058, 12.924181938000117],
              [8.678098679000073, 12.925375939000048],
              [8.678118705000145, 12.924454689000072],
              [8.675571441000045, 12.926223756000127],
              [8.67181301200003, 12.928835869000181],
              [8.671685218999983, 12.928922653000086],
              [8.667426109000019, 12.930472375000022],
              [8.661516190000157, 12.932623864000163],
              [8.648481369000024, 12.93855857900013],
              [8.646060943000123, 12.943494798000074],
              [8.644794464000029, 12.946078301000057],
              [8.644727708, 12.949088097000015],
              [8.644694329000174, 12.950609207000127],
              [8.644575119000081, 12.955739022000103],
              [8.640003204000095, 12.961165429000118],
              [8.639039039000181, 12.963002205],
              [8.637764932000152, 12.965446472000053],
              [8.636941911000179, 12.967016220000062],
              [8.63580322200005, 12.969195367000168],
              [8.633773805000089, 12.973073959000089],
              [8.633063316000175, 12.974431993],
              [8.631668091000108, 12.977099418000023],
              [8.631171226000106, 12.977727890000153],
              [8.628231048000032, 12.981445314000041],
              [8.625608445000182, 12.984761239000022],
              [8.623209952000082, 12.987794876000123],
              [8.621287345000042, 12.990223885000148],
              [8.617485046000127, 12.994190216000106],
              [8.614821434000021, 12.996971131000066],
              [8.613291740000136, 12.998565674000133],
              [8.610325813000088, 13.001659394000114],
              [8.609136581000144, 13.002899169000102],
              [8.604654312999969, 13.007575988000099],
              [8.601824760000056, 13.010524749000126],
              [8.598752976000128, 13.011610032000078],
              [8.596196174000113, 13.014205934000131],
              [8.594000816, 13.016434669000034],
              [8.593059539000024, 13.017389298000069],
              [8.592984199, 13.017466545000161],
              [8.592562676000057, 13.017851830000154],
              [8.589791299000183, 13.020373345000166],
              [8.585560798000188, 13.024214745000052],
              [8.584760666000022, 13.024756431000071],
              [8.583269118000089, 13.025768281000126],
              [8.581137658000102, 13.027215004000027],
              [8.579115867999974, 13.028585435000025],
              [8.577459336000061, 13.029707908000034],
              [8.574691773000154, 13.031583786],
              [8.572500229000184, 13.033067703000143],
              [8.570581436000111, 13.034369469000012],
              [8.56822586100003, 13.03596687400011],
              [8.564826011000093, 13.038270951000072],
              [8.563592911, 13.038958549000142],
              [8.55867672100004, 13.041709900000114],
              [8.555401802000063, 13.043542861000162],
              [8.552935599000023, 13.044923783000058],
              [8.550511361000133, 13.046279907000155],
              [8.544618607000189, 13.049574852000035],
              [8.542202949000171, 13.05083847100002],
              [8.538967132000039, 13.052529335000145],
              [8.537350654000022, 13.05337238400017],
              [8.531378746000087, 13.05649471300012],
              [8.531079293000119, 13.056650163000029],
              [8.530863762000138, 13.056738854000173],
              [8.527170182000077, 13.058226587000092],
              [8.524576188000026, 13.059274673000118],
              [8.515917777000084, 13.062768937000044],
              [8.503179550000141, 13.066561700000079],
              [8.499368667000056, 13.067689895000058],
              [8.499138831000039, 13.067742348000024],
              [8.486369133000039, 13.069729806000169],
              [8.474385261000066, 13.069154739000112],
              [8.468829155000037, 13.068888664000042],
              [8.464567184000146, 13.067735672000026],
              [8.460904122000102, 13.066745758000025],
              [8.457395555000119, 13.065797806000091],
              [8.451980591000108, 13.064336777999983],
              [8.44661235699999, 13.062883377000105],
              [8.441999436000174, 13.061636924000084],
              [8.440343856000141, 13.061112404000028],
              [8.437054634000106, 13.060070991000089],
              [8.43395328600019, 13.059088707000171],
              [8.43048000400006, 13.057985306000091],
              [8.425911904000088, 13.056539535000127],
              [8.421723366000094, 13.055213928000114],
              [8.415629387000081, 13.054848671],
              [8.413873673000126, 13.056653976000064],
              [8.411749841000074, 13.058837891000053],
              [8.40966034000013, 13.060984611000038],
              [8.408750533000102, 13.061919212000191],
              [8.406079293000062, 13.064665794000064],
              [8.403648376000092, 13.067164421000086],
              [8.400720596000156, 13.0701723090001],
              [8.400515557000119, 13.070324899000127],
              [8.399269104000098, 13.071249962000024],
              [8.394972802000098, 13.074436189000096],
              [8.391515733000119, 13.077002526000115],
              [8.38941669400009, 13.07855892200007],
              [8.388055801, 13.079568861999974],
              [8.388019561000078, 13.080715180000141],
              [8.381132126000125, 13.083807946000093],
              [8.376632690000179, 13.088981628000056],
              [8.36580944100001, 13.097707749000051],
              [8.356260300000145, 13.114417077000041],
              [8.351867675000051, 13.117357255000115],
              [8.347662925000122, 13.120064735000142],
              [8.327900887000169, 13.132773400000133],
              [8.326815606000139, 13.133988380000119],
              [8.318327903000125, 13.143550873000095],
              [8.31823921199998, 13.143651010000099],
              [8.315830231000064, 13.14651584600017],
              [8.313649177000116, 13.150303841000152],
              [8.311225891000163, 13.154511451000133],
              [8.310686112000155, 13.15544796000006],
              [8.309908866000171, 13.156800271000122],
              [8.309439659000134, 13.157651901000065],
              [8.307639122000126, 13.161064148000037],
              [8.307524682000064, 13.161113738000097],
              [8.294010161000188, 13.167046546000165],
              [8.293767930000115, 13.167151451000052],
              [8.287554741, 13.171705247000034],
              [8.28743171800005, 13.171798706000061],
              [8.27279567700009, 13.18883323700004],
              [8.271492004000152, 13.190348625000013],
              [8.263125419000062, 13.199956893000092],
              [8.262405395000144, 13.200786590000121],
              [8.262241363000101, 13.20098972400001],
              [8.261570931000051, 13.201684952000164],
              [8.255915641000172, 13.207526208000161],
              [8.248926163000078, 13.209377289000088],
              [8.244052888000169, 13.210641861000113],
              [8.234971047000045, 13.21300125199997],
              [8.232316018, 13.213688851000143],
              [8.211999893000098, 13.218962669000064],
              [8.21174907700015, 13.219029427000066],
              [8.201210022000055, 13.224998474000188],
              [8.199627876000079, 13.22589397400003],
              [8.196649551000121, 13.229207994000149],
              [8.196536064000043, 13.229332925000108],
              [8.191206932, 13.232566834000068],
              [8.190773964000073, 13.232961655000111],
              [8.184503555000049, 13.238650322000183],
              [8.181244850000155, 13.241656304],
              [8.180149078000056, 13.242679595000027],
              [8.174409867000122, 13.24824142600005],
              [8.164506913000025, 13.258653641000137],
              [8.158667564000098, 13.260832786000037],
              [8.158299447000104, 13.260970115000134],
              [8.15810680300018, 13.261039733000018],
              [8.156489372000181, 13.263464929000122],
              [8.156425476000038, 13.263559341000132],
              [8.145627021, 13.271163940000065],
              [8.145402908000051, 13.271322250000026],
              [8.136913300000117, 13.275148392000062],
              [8.127744675000145, 13.279259682000031],
              [8.126401902000112, 13.279862405000074],
              [8.124863625000103, 13.280602455000064],
              [8.12047863000015, 13.282714844000111],
              [8.119883537000021, 13.2829999920001],
              [8.106680870000162, 13.289319993000106],
              [8.103883744000029, 13.29019260400014],
              [8.097561836000068, 13.292163849000076],
              [8.095669747000102, 13.292755127000135],
              [8.087237357000163, 13.295385360000068],
              [8.079633713000021, 13.297755241000118],
              [8.078508378000095, 13.298106195000059],
              [8.07424354599999, 13.299805642000081],
              [8.067596435999974, 13.302456856000049],
              [8.063987731999987, 13.302696228000116],
              [8.062419891000047, 13.302832604000059],
              [8.062190056000134, 13.302852630000075],
              [8.060647011000071, 13.303215982000154],
              [8.058684349000032, 13.303675651000049],
              [8.051162719000161, 13.305439949000061],
              [8.046381950000068, 13.306595803000107],
              [8.04260253800004, 13.305807113000014],
              [8.042408944000158, 13.305825234000054],
              [8.036952973000041, 13.306353569000123],
              [8.03355693900005, 13.308793068000114],
              [8.025898933000065, 13.309329033000097],
              [8.022953987000108, 13.31106090500009],
              [8.022868156000186, 13.311111450000055],
              [8.010334968000052, 13.313282966000145],
              [8.006688118000113, 13.313914298000157],
              [8.000249863000022, 13.314990044000183],
              [7.98655414600006, 13.317228318000105],
              [7.984991073000174, 13.317485810000107],
              [7.975068093000175, 13.319104194000033],
              [7.956436157999974, 13.322139741000115],
              [7.947474002999968, 13.323597909000171],
              [7.947050095000066, 13.323638916000107],
              [7.934158800999967, 13.324863434000179],
              [7.916384221000158, 13.326549530000023],
              [7.910878181000044, 13.327707292000071],
              [7.910708905000035, 13.327743531000181],
              [7.905403137000178, 13.328044893000026],
              [7.904127121000101, 13.32811546400012],
              [7.904002190000142, 13.328124999000124],
              [7.891622066000082, 13.330088616000126],
              [7.881730080000125, 13.329632760000095],
              [7.87487697600011, 13.332472801000108],
              [7.874546051000039, 13.332489014000146],
              [7.848525046000077, 13.333687782000027],
              [7.840466023000033, 13.336488724000162],
              [7.832929135000086, 13.337229728000182],
              [7.824882031000016, 13.339568138],
              [7.815518856999972, 13.338615418000074],
              [7.804233073000034, 13.334292413000071],
              [7.804107190000025, 13.334225655000012],
              [7.80128622, 13.332702636000079],
              [7.791484833000027, 13.32742500400019],
              [7.783136845000172, 13.322925568000073],
              [7.780751228000042, 13.321640015000185],
              [7.780458927000097, 13.321472167000138],
              [7.780203820000054, 13.321310044000143],
              [7.779579163000108, 13.320908546000112],
              [7.777915, 13.319844246000173],
              [7.771722794000141, 13.315890313000125],
              [7.770761012000151, 13.315234185000065],
              [7.768022061000124, 13.31336879800017],
              [7.75725316900008, 13.305987359000028],
              [7.752943040000105, 13.30303573600014],
              [7.748991012000147, 13.302084923999985],
              [7.748429776000023, 13.298875809000151],
              [7.746173858000077, 13.299056054],
              [7.742185116000087, 13.296710967000081],
              [7.741620064000074, 13.296376229000089],
              [7.72224998400003, 13.28497600500009],
              [7.719626904000052, 13.283428191999974],
              [7.710117818000185, 13.277042390000076],
              [7.708732128000122, 13.276189805000172],
              [7.697471142999973, 13.269261361000133],
              [7.693799018000163, 13.267000199999984],
              [7.661355973000184, 13.24703693400005],
              [7.66040515899999, 13.246308328000168],
              [7.65329504000016, 13.240859032000117],
              [7.65046596500008, 13.241248131000077],
              [7.649586201000147, 13.238925934000065],
              [7.645799159000148, 13.237791061000053],
              [7.64430999700005, 13.236849785000118],
              [7.640079976000038, 13.234089852000125],
              [7.638565063000044, 13.232876778000104],
              [7.631783962000043, 13.227457047000144],
              [7.631683827000188, 13.227418900000089],
              [7.622954846000141, 13.223978996000028],
              [7.621614932999989, 13.221443177000083],
              [7.618752002000178, 13.22138977000003],
              [7.613691807000123, 13.217825890000029],
              [7.613521099000025, 13.217720986000074],
              [7.608500004000121, 13.214685440999972],
              [7.602150916000085, 13.210856439000054],
              [7.594281197000043, 13.2071638110001],
              [7.594132900000147, 13.207052231000091],
              [7.584588051000083, 13.200051308000184],
              [7.579014777000111, 13.198247910000191],
              [7.570848942000055, 13.193460464],
              [7.560246945000131, 13.187275887000169],
              [7.549098968000124, 13.180781365000144],
              [7.539124013000162, 13.174968720000152],
              [7.514442921000125, 13.160733223000136],
              [7.505855084000018, 13.155782701000135],
              [7.504558086000031, 13.154483795000147],
              [7.503007888000184, 13.153014183000039],
              [7.502202035000039, 13.15224647500014],
              [7.501675128000045, 13.151746749000154],
              [7.499348164000082, 13.151074410000035],
              [7.493482114000187, 13.148660660000189],
              [7.484504223000101, 13.142397881000079],
              [7.484398841000086, 13.142356872000107],
              [7.474141120000013, 13.138195039000152],
              [7.471948147000035, 13.136797906000083],
              [7.45780897200018, 13.126724244000059],
              [7.454470158000106, 13.124961854000048],
              [7.453186036000091, 13.12428760500012],
              [7.448993207, 13.123031616000048],
              [7.446607113000141, 13.121617317000187],
              [7.426324845000067, 13.110475540000152],
              [7.426124094999977, 13.110407829000167],
              [7.423405170000024, 13.109484674000043],
              [7.400763989000097, 13.101721764999979],
              [7.39528703799999, 13.100855827000089],
              [7.389463902000102, 13.100009918000183],
              [7.387196064000079, 13.099650383000039],
              [7.386065960000167, 13.099470139],
              [7.383418084000084, 13.09905147500001],
              [7.382677079000189, 13.099019051000028],
              [7.381447792000131, 13.09883880699999],
              [7.377521038000168, 13.098795891],
              [7.368330002000164, 13.098686218000068],
              [7.367572784, 13.098743438000042],
              [7.343245984000021, 13.100281715000051],
              [7.331467152000073, 13.101132393000057],
              [7.310253143000125, 13.10431289700017],
              [7.309020043000089, 13.104430199000092],
              [7.27211618400014, 13.107951163999985],
              [7.270101071000113, 13.108144761000119],
              [7.244554042000118, 13.11085033500018],
              [7.244061946000045, 13.110901833000185],
              [7.227884769000127, 13.110942840000064],
              [7.221970081000109, 13.104128838000122],
              [7.221743108000112, 13.103863716000035],
              [7.219336033000161, 13.102603914000042],
              [7.217574120000109, 13.101685523000185],
              [7.212327004000088, 13.096026421000033],
              [7.212172032000069, 13.094914436000124],
              [7.211935043000096, 13.093253136000158],
              [7.210031987000036, 13.092623711000044],
              [7.208909034000101, 13.092254639000032],
              [7.198657036000043, 13.080941201000087],
              [7.193107129000111, 13.077804566],
              [7.187590123000177, 13.071658134000018],
              [7.187220098000012, 13.071179391000101],
              [7.179277896000144, 13.060884476000183],
              [7.173296928000184, 13.05470085200011],
              [7.165819168000155, 13.046968459000084],
              [7.163825034000183, 13.044907570000191],
              [7.158076762000121, 13.038961411000173],
              [7.151636123, 13.032301902000086],
              [7.142549037000094, 13.026063920000183],
              [7.136393071000043, 13.023158074000094],
              [7.135479928000052, 13.022973060000083],
              [7.130545140000152, 13.021965980000061],
              [7.124723912000036, 13.020778657000108],
              [7.118741989000057, 13.01862907400016],
              [7.102136136000013, 13.012653350999983],
              [7.100553989000105, 13.012084007000169],
              [7.100406170000042, 13.012044908],
              [7.097227096000097, 13.011201858000106],
              [7.091608047000136, 13.009711266000011],
              [7.089470863000088, 13.00914573700004],
              [7.086109162000184, 13.008252145000029],
              [7.084232807000092, 13.007659912000122],
              [7.079349041000114, 13.00611496],
              [7.072960854000144, 13.004093170000033],
              [7.06689596200016, 13.002176285000189],
              [7.066729069000076, 13.002123833000098],
              [7.066177846000073, 13.001948357000117],
              [7.065340996000089, 13.001684190000049],
              [7.063951016000033, 13.00124359199998],
              [7.06318283100012, 13.00100040500007],
              [7.063070775000085, 13.000976562000119],
              [7.06290722000017, 13.000959396000098],
              [7.061176777000071, 13.000756264000188],
              [7.060133935000124, 13.000633240000127],
              [7.058218956000189, 13.000410080000165],
              [7.056304931, 13.000188827000045],
              [7.054374218000078, 12.99996089900003],
              [7.052863122000076, 12.999785423000048],
              [7.05114221600013, 12.999585152000122],
              [7.050206185000093, 12.999474526000029],
              [7.049204826000107, 12.999306679000085],
              [7.047687054000107, 12.99904918600015],
              [7.046493053000177, 12.998848915],
              [7.044787884000186, 12.998561859000063],
              [7.042910098000107, 12.998242378000043],
              [7.041151046000039, 12.99794578500007],
              [7.039474964000078, 12.997664452000151],
              [7.039052010000091, 12.997592926000095],
              [7.03854322400008, 12.997486115000072],
              [7.037767887000143, 12.997323990000041],
              [7.036842824000075, 12.997127534000128],
              [7.034287929000129, 12.996589661000144],
              [7.034179211000037, 12.99656677300004],
              [7.033500195000158, 12.996423721000099],
              [7.031688212000063, 12.996043205000035],
              [7.022407055000144, 12.994093894000116],
              [7.016811848000032, 12.992918014],
              [7.01005601900016, 12.99149608700003],
              [7.004016876000094, 12.99118423499999],
              [7.00029277800013, 12.99099254600003],
              [6.999999047000074, 12.990987777999976],
              [6.999607086000083, 12.990979195000079],
              [6.988942147000046, 12.991065025000069],
              [6.979025842000169, 12.991145134000078],
              [6.977886201000104, 12.991153717000145],
              [6.976297854000052, 12.991168022000181],
              [6.946559906000118, 12.991404533000093],
              [6.934085846000073, 12.988611222000031],
              [6.92800807899999, 12.989852905000134],
              [6.913483143000121, 13.00571346300012],
              [6.904776095000159, 13.018719673000135],
              [6.891612053000074, 13.031800271000066],
              [6.88886976200007, 13.036437988000046],
              [6.851266860000123, 13.08008384600015],
              [6.840025901000047, 13.093132020000098],
              [6.839902878000089, 13.09327602400009],
              [6.838016034000077, 13.095465660000059],
              [6.837540150000166, 13.096019744999978],
              [6.834168911000177, 13.099930764000021],
              [6.830811977000053, 13.103828431000011],
              [6.827501774000041, 13.107670784000049],
              [6.823763847000066, 13.11201000200009],
              [6.821173190000081, 13.115015983000035],
              [6.818690775999983, 13.11789703300002],
              [6.818611145000091, 13.117987632000165],
              [6.815075874000058, 13.123636247000093],
              [6.811584949000121, 13.129267693000088],
              [6.808132172000171, 13.134844780000151],
              [6.807320118000121, 13.136040688000151],
              [6.795858859000134, 13.152938843000015],
              [6.794745922000175, 13.154570579000051],
              [6.793266773000084, 13.156738281000173],
              [6.791993140000102, 13.158604623000031],
              [6.79171085400003, 13.159311294000133],
              [6.791204929000173, 13.160582543000089],
              [6.790752887000053, 13.161712646000069],
              [6.790202141000179, 13.163095474000102],
              [6.790133, 13.163266182000029],
              [6.790009975000089, 13.163581849000082],
              [6.789722919000099, 13.164296150000155],
              [6.789236068999969, 13.165523530000087],
              [6.789134979000153, 13.165790557000037],
              [6.787875175000181, 13.167923927000174],
              [6.785559177000039, 13.171841621000169],
              [6.784751891000042, 13.17320919000008],
              [6.784565925000095, 13.173527717000013],
              [6.783823013000131, 13.174765588000071],
              [6.782637119000015, 13.176772117000098],
              [6.781456947000095, 13.178764343000068],
              [6.774363994000055, 13.190747262000059],
              [6.773306847000072, 13.192534448000117],
              [6.770423889000085, 13.197404861000166],
              [6.769743920000053, 13.198553085000128],
              [6.769556999000145, 13.198871612000119],
              [6.769473075000121, 13.199013709000042],
              [6.76457500500004, 13.207291604000091],
              [6.750847817000022, 13.230713844000036],
              [6.745060921000118, 13.24120712399997],
              [6.737399102000097, 13.255101205000187],
              [6.736916065000173, 13.255978584000047],
              [6.729095935999965, 13.266900063000151],
              [6.71745586500009, 13.288909912000065],
              [6.714724064000109, 13.293549539000139],
              [6.70967102000003, 13.302128790999973],
              [6.699370860000045, 13.319748879000031],
              [6.68513584100009, 13.341334343000085],
              [6.680653095000082, 13.350002289000145],
              [6.677817822000066, 13.355448722],
              [6.677712918000054, 13.355571748000102],
              [6.677255154000022, 13.355986595000047],
              [6.676315785000043, 13.356835365000109],
              [6.670657157000051, 13.361948967000046],
              [6.66439390100004, 13.367612839000117],
              [6.658707142000139, 13.373914720000073],
              [6.655148983000174, 13.37786007],
              [6.636146068000187, 13.394914626000173],
              [6.5933160780001, 13.438405037000109],
              [6.579208852000079, 13.452729225000098],
              [6.571774006000055, 13.458356857000183],
              [6.564826965000179, 13.465349197000137],
              [6.499003886000025, 13.528334618000031],
              [6.451249123000025, 13.574505807000094],
              [6.433632850000038, 13.591984748000073],
              [6.427620888000149, 13.596660615000189],
              [6.421308040000042, 13.60157108300001],
              [6.419184208000047, 13.601976394000076],
              [6.414743900000133, 13.602810860000034],
              [6.41142988200005, 13.604611397000042],
              [6.344282151000016, 13.640996933000054],
              [6.326931000000116, 13.649498941000047],
              [6.32592392000015, 13.649991036000188],
              [6.314389227999982, 13.65564346400015],
              [6.309699058000092, 13.656394959000068],
              [6.309116841000105, 13.656739235000146],
              [6.304565907000153, 13.65945244],
              [6.303895951000129, 13.65966415500003],
              [6.302619934000177, 13.660449982000102],
              [6.300384044000111, 13.660782815000061],
              [6.299038886000176, 13.661301614000081],
              [6.283351898000035, 13.666745186000185],
              [6.275824070000169, 13.666716575],
              [6.26892709700013, 13.666583061000154],
              [6.268517018000068, 13.66668796600004],
              [6.26112318100013, 13.668600083000058],
              [6.242693901000109, 13.66770744400003],
              [6.238153935000184, 13.66743755400006],
              [6.224427222000145, 13.666443824999988],
              [6.205805779000116, 13.661931038000091],
              [6.205616951000138, 13.661931038000091],
              [6.202595234000171, 13.660523415000057],
              [6.201101779000112, 13.659708977000037],
              [6.200358867000091, 13.659337997000023],
              [6.198166847000096, 13.658459663000031],
              [6.196673869000051, 13.65781307300017],
              [6.195889950000094, 13.657472610000184],
              [6.194434166000065, 13.656744003000028],
              [6.192581176999966, 13.656374931000187],
              [6.177262782000071, 13.652288437000095],
              [6.175910949000183, 13.651911736000102],
              [6.174053192000031, 13.651186943000084],
              [6.160933017000104, 13.646132470000168],
              [6.160710811, 13.646045684000057],
              [6.156960011000024, 13.644577980000122],
              [6.149414062000119, 13.642833711000151],
              [6.143682003000038, 13.645727157000067],
              [6.138203144000158, 13.648490906000063],
              [6.123973847000116, 13.657176971000126],
              [6.101395130000185, 13.670295715000066],
              [6.084767818000046, 13.679957391000073],
              [6.076723098000059, 13.68274879400002],
              [6.042863845999989, 13.694493294000097],
              [6.027389049000078, 13.700383187999989],
              [6.026017190000175, 13.700906755000062],
              [6.007514953000111, 13.707103730000085],
              [5.990903854000123, 13.713741304],
              [5.982697010000038, 13.715678215000139],
              [5.917356968000092, 13.737601280000149],
              [5.885731221000015, 13.747855187000084],
              [5.882452012000044, 13.749044418000153],
              [5.844749928000056, 13.762579916999982],
              [5.800002099000153, 13.777339936000089],
              [5.753159046000064, 13.794417382000177],
              [5.741479874000049, 13.799609185000122],
              [5.736386775000085, 13.801881791000028],
              [5.735783100000162, 13.802152633999981],
              [5.733028887999979, 13.803380967000123],
              [5.627215862000128, 13.849121095000157],
              [5.606129169000042, 13.856926918],
              [5.583426953000128, 13.867970466000031],
              [5.545146942000031, 13.884140016000117],
              [5.540298939000081, 13.886184691999972],
              [5.536296844000162, 13.887875556000097],
              [5.526512146000016, 13.892009735000102],
              [5.504712104000021, 13.886097908000124],
              [5.504438877000041, 13.886025429000028],
              [5.494655133000038, 13.883372307000116],
              [5.486633777000179, 13.881616593000103],
              [5.45925998700011, 13.873851776000095],
              [5.454796790000046, 13.874590874000148],
              [5.449135780000063, 13.873269082000036],
              [5.444396973000096, 13.869871140000043],
              [5.411601066, 13.86100864500014],
              [5.394473076, 13.85610485],
              [5.394362926000156, 13.856073380999987],
              [5.388009071000113, 13.854251863000115],
              [5.35534715700004, 13.844900132000021],
              [5.354873180000027, 13.844426155000178],
              [5.353752136000139, 13.843463898000039],
              [5.349932194000076, 13.83929824900008],
              [5.344092845000034, 13.832935334000013],
              [5.339735031000089, 13.82779312100007],
              [5.317918776, 13.802055359],
              [5.307075023000152, 13.789259910000112],
              [5.289195060000168, 13.768163681000146],
              [5.274472238000044, 13.750795365000158],
              [5.270699978000039, 13.746343614000125],
              [5.270487786000047, 13.746100425000179],
              [5.261931895000146, 13.745860099000026],
              [5.233791829000097, 13.745070457000054],
              [5.202507973000138, 13.744441032000111],
              [5.190929890000064, 13.743960380000146],
              [5.184391022000114, 13.743687630000124],
              [5.179935933000024, 13.745107651000126],
              [5.171714783000141, 13.746102333000181],
              [5.16464710200006, 13.744561195000188],
              [5.147967815000129, 13.746548653000161],
              [5.144689084000163, 13.747571946000164],
              [5.135067940000113, 13.750571251999986],
              [5.128849029000094, 13.749547959000154],
              [5.128716945000178, 13.749525071000051],
              [5.105984210000145, 13.752470016000075],
              [5.101348878000124, 13.752053261000128],
              [5.090611935000027, 13.75108432799999],
              [5.089456081000151, 13.750926971000013],
              [5.085886956000138, 13.750454904000151],
              [5.067279816000166, 13.748003007000023],
              [5.056070804000115, 13.746523857000057],
              [5.054674148000061, 13.746438981000153],
              [5.052924156000188, 13.746361732000025],
              [5.049321175000046, 13.745965004000084],
              [5.046996116000173, 13.745710374000168],
              [5.044404030000123, 13.745671272000095],
              [5.030366897000135, 13.744793892000189],
              [5.030237198000123, 13.744772911000155],
              [5.026253224000186, 13.744144441000174],
              [5.014957905000074, 13.742362022000066],
              [5.011289120000185, 13.741781235000019],
              [5.009866238000029, 13.741684913000086],
              [5.00187396900003, 13.741153718000078],
              [4.999999999000067, 13.741391182000029],
              [4.990418910000187, 13.742483138000125],
              [4.987545013000158, 13.742809296000132],
              [4.987074853000138, 13.742860793000034],
              [4.983846186999983, 13.743080139000028],
              [4.977175237000154, 13.743523597000149],
              [4.974384784000108, 13.743700028000092],
              [4.944069863000152, 13.746484756000086],
              [4.909521104000135, 13.748608590000174],
              [4.898282050000148, 13.754447936000133],
              [4.897879124000042, 13.754826546],
              [4.87680387600011, 13.774662018000015],
              [4.87611103100005, 13.775311471],
              [4.87198114500012, 13.780842781000104],
              [4.870727061000082, 13.782451631000185],
              [4.870658875000174, 13.782539368000073],
              [4.861890792000054, 13.780854226000088],
              [4.861795903000086, 13.780649186000176],
              [4.853316783000025, 13.778994561000104],
              [4.843842984000162, 13.777187347000165],
              [4.812983035000059, 13.771298408000064],
              [4.7789030080001, 13.76509189699999],
              [4.772865773000035, 13.763993264000135],
              [4.753115177000097, 13.760328294000146],
              [4.751962186000185, 13.760107041000026],
              [4.750165939000169, 13.759729386000117],
              [4.749617099000034, 13.759551048000105],
              [4.742348193000055, 13.757081032000031],
              [4.740829944000154, 13.756912232000104],
              [4.728352070000142, 13.755077363000055],
              [4.727083205000042, 13.754831314000057],
              [4.69881010000006, 13.749352455000121],
              [4.688226222000083, 13.747303009000063],
              [4.682260990000032, 13.746297836999986],
              [4.672433854000133, 13.74448585499999],
              [4.645439147000104, 13.740110397000024],
              [4.62600994100012, 13.736848832000078],
              [4.616550923000148, 13.73427772600013],
              [4.615218163000179, 13.734121324000171],
              [4.597130776000086, 13.730218888000024],
              [4.595286847000011, 13.729805947000045],
              [4.594713210000066, 13.729679108000141],
              [4.589408874000128, 13.728498460000139],
              [4.584853173000056, 13.727839470000163],
              [4.583885193000072, 13.727698325000119],
              [4.583024979000129, 13.727522850000071],
              [4.567842006000035, 13.724536897000178],
              [4.55907583200019, 13.723771096000178],
              [4.554759980000142, 13.723393441000098],
              [4.550212860000158, 13.720771790000128],
              [4.549301147000108, 13.720619201000034],
              [4.522315980000144, 13.715770721000183],
              [4.506813049000016, 13.712856291999969],
              [4.503322125000011, 13.712200164000024],
              [4.480165958000157, 13.707846642000106],
              [4.480062962000147, 13.707694053000182],
              [4.478208065000047, 13.704909325000131],
              [4.478141785000105, 13.70481300299997],
              [4.464822768000033, 13.703294754000069],
              [4.461080075000098, 13.699898720000078],
              [4.459292889000153, 13.698176384000021],
              [4.456397056000128, 13.695651054000166],
              [4.450323104000063, 13.690141678000145],
              [4.441301823000174, 13.682491302000074],
              [4.434699059000081, 13.676738740000133],
              [4.420488834000025, 13.665225030000158],
              [4.41023016000014, 13.656758309000111],
              [4.410044194000022, 13.656573295000101],
              [4.402544975000069, 13.649135589000025],
              [4.399751187000049, 13.646800042000109],
              [4.399641991000067, 13.646709443000191],
              [4.36584901800012, 13.618317605000072],
              [4.35100317000007, 13.606021881000061],
              [4.35017299600014, 13.605328561000078],
              [4.349415778000036, 13.60460567500013],
              [4.344398023000053, 13.599810600000126],
              [4.324340820000089, 13.580179215000044],
              [4.324203969000052, 13.580043794000119],
              [4.321258068000134, 13.579256057],
              [4.320735931000172, 13.579116821000071],
              [4.304669856000032, 13.564838409000117],
              [4.293169022000029, 13.555307389],
              [4.249516964000122, 13.51765346500008],
              [4.24826097499999, 13.516531945],
              [4.213416100000075, 13.485302924999985],
              [4.213201045000119, 13.485134124000183],
              [4.208937168000034, 13.481757163000054],
              [4.204660892000049, 13.478371620000189],
              [4.197412015000055, 13.472976684],
              [4.197249890000023, 13.474287988000185],
              [4.181959151000115, 13.471667290000028],
              [4.173294067000086, 13.473261833000095],
              [4.173042773000077, 13.473307610000063],
              [4.167059898000048, 13.473214150000103],
              [4.146325111000124, 13.472810746000164],
              [4.141425132000165, 13.472810746000164],
              [4.130453110000019, 13.472638131000167],
              [4.130186080000158, 13.4705085760001],
              [4.129652976999978, 13.466285706000122],
              [4.128029824000066, 13.453392982000025],
              [4.127645016000031, 13.450345039000183],
              [4.127675057000147, 13.450054170000044],
              [4.128717899000094, 13.437857628000188],
              [4.128960133000078, 13.435041428999966],
              [4.129076003000023, 13.426776887000074],
              [4.129514218000168, 13.395744325000123],
              [4.129587174000164, 13.39055919700013],
              [4.129865170000073, 13.375352860000021],
              [4.129464149000171, 13.364056588000096],
              [4.129449845000067, 13.363545419000161],
              [4.128913880000027, 13.343998909999982],
              [4.128781796000112, 13.339100838000093],
              [4.128708838000136, 13.336406707000037],
              [4.129126073000123, 13.32139873599999],
              [4.128958225000076, 13.212648393],
              [4.129068852000103, 13.212652206000087],
              [4.129065037000032, 13.209913255],
              [4.128501891000155, 13.193790437000075],
              [4.128249169000185, 13.186516761000064],
              [4.128266812000163, 13.186011314999973],
              [4.129387855000118, 13.154849052000088],
              [4.129622936000089, 13.148301124999989],
              [4.129570961000127, 13.148085595000111],
              [4.126984120000088, 13.136309623000102],
              [4.124411107000071, 13.122154235000039],
              [4.122550965000187, 13.110307695000017],
              [4.120608806000178, 13.103244781000058],
              [4.11592817200011, 13.085164071000122],
              [4.113469124000062, 13.074687003000065],
              [4.105442048000043, 13.036232949],
              [4.101710796000077, 13.01811409000004],
              [4.10152816700014, 13.017221450000079],
              [4.096078872000021, 12.995821953000018],
              [4.070148944000039, 12.958869934000177],
              [4.062263012000187, 12.946923256000048],
              [4.054471017000026, 12.934837342000151],
              [4.051560878000032, 12.930323601000168],
              [4.049683095000034, 12.929414749000102],
              [4.030659199000183, 12.904097557000057],
              [4.027080059000184, 12.897896766000088],
              [4.024643897000033, 12.893449783000108],
              [4.020471096000165, 12.88644886000003],
              [4.019256114000029, 12.884406089000038],
              [4.018648148000182, 12.88338470400015],
              [4.018463135000047, 12.883161546000167],
              [4.006547929000078, 12.868692398000064],
              [4.003610134000098, 12.86435222700004],
              [4.003045082000028, 12.863443374999974],
              [4.000322819000132, 12.858986855000012],
              [3.996835946000033, 12.853273393000165],
              [3.990195035000056, 12.842388153000115],
              [3.988408089000188, 12.83944130000009],
              [3.967415095000149, 12.804821015000073],
              [3.964878081000165, 12.800175667000133],
              [3.942208051000137, 12.764188766000188],
              [3.939151048000042, 12.761571884000034],
              [3.932523965000144, 12.755900383000153],
              [3.932373048000045, 12.755697251000072],
              [3.927601099000185, 12.749321937000104],
              [3.927437067000085, 12.749198913999976],
              [3.907047034000129, 12.734174729000074],
              [3.880251883000028, 12.714430808999964],
              [3.876559020000116, 12.711711884000181],
              [3.876110077000021, 12.711411476000023],
              [3.873518943000022, 12.709694862000106],
              [3.870798110000067, 12.708122254000159],
              [3.866492033000043, 12.70545768900007],
              [3.863785029000042, 12.702494622000131],
              [3.863181113000167, 12.701818466000134],
              [3.862735987000121, 12.701322555000047],
              [3.861546994000037, 12.700251580000156],
              [3.840188981000097, 12.683972359000109],
              [3.837446928000134, 12.681863786],
              [3.83582901900013, 12.68059539900014],
              [3.83385992000018, 12.679052354000078],
              [3.833369971000025, 12.678668975999983],
              [3.823015929000064, 12.671975135000082],
              [3.817852974, 12.668638229000067],
              [3.817203999000071, 12.668216706000067],
              [3.793442010000149, 12.648476601000027],
              [3.772850036000079, 12.631472587000189],
              [3.771095038000169, 12.629970551000099],
              [3.75095892000013, 12.612724304000153],
              [3.734755993000022, 12.599426270000151],
              [3.72517204400009, 12.59204578399999],
              [3.722278118000133, 12.589814187],
              [3.720046996000065, 12.58766078900004],
              [3.710606099000074, 12.577835083000082],
              [3.70994305500011, 12.577198981000038],
              [3.702383041000076, 12.569750787000146],
              [3.699492931000179, 12.568146707000096],
              [3.696311951000098, 12.566379547000111],
              [3.695856095000181, 12.565950394000026],
              [3.688796997, 12.559314728000061],
              [3.687839985000039, 12.558506013000056],
              [3.67441392, 12.547133444999986],
              [3.672688007000147, 12.545669556000121],
              [3.64255595200018, 12.520148278000022],
              [3.641381980000062, 12.503825188000178],
              [3.642792940999982, 12.493918419000011],
              [3.643328906000022, 12.49016380300003],
              [3.643345117000024, 12.489794731000075],
              [3.644359112000188, 12.466089250000039],
              [3.644428968000113, 12.465560913000104],
              [3.647192002000111, 12.444946289000086],
              [3.647330999000133, 12.434146881000061],
              [3.647340059000158, 12.430687906000117],
              [3.647640943, 12.425863267000182],
              [3.649795053999981, 12.389754295000159],
              [3.649780988999964, 12.385533333000126],
              [3.649705887000152, 12.365841865000107],
              [3.64977908100019, 12.365262985000186],
              [3.653551102000108, 12.334483148000118],
              [3.654289007000102, 12.319842339000161],
              [3.654309033000175, 12.319032669000137],
              [3.655203105000112, 12.28165722],
              [3.657454968000138, 12.268477440000026],
              [3.649295091000113, 12.243273736000106],
              [3.648821116000079, 12.241625785999986],
              [3.641977073000021, 12.217720986000074],
              [3.639791013000149, 12.210091591000037],
              [3.636992932000169, 12.200319291000085],
              [3.636949062000099, 12.200163841],
              [3.63734102300009, 12.19674778100017],
              [3.63806390700006, 12.190443993000144],
              [3.637270927000145, 12.183600425000179],
              [3.637079001000075, 12.181946755000126],
              [3.63538098399999, 12.167258262000132],
              [3.635175944000082, 12.165391922000026],
              [3.634938955000109, 12.163240433000112],
              [3.634401083000057, 12.145099641000172],
              [3.634274007000158, 12.140788079000117],
              [3.634229898000172, 12.140482901999974],
              [3.630786896000018, 12.117162704000123],
              [3.642509938000103, 12.076824189000035],
              [3.644664049000085, 12.069403649000151],
              [3.650237083000093, 12.050227165000081],
              [3.650779008000029, 12.049595833000069],
              [3.651941062000105, 12.045199395000111],
              [3.657124043000067, 12.025744438000061],
              [3.657493115000023, 12.024580001000061],
              [3.661926030000075, 12.010608674000025],
              [3.665693998999984, 11.998598099000048],
              [3.667872906000071, 11.991641998000148],
              [3.667881966000095, 11.991516114000035],
              [3.668823003000057, 11.977862358999971],
              [3.667172909000158, 11.974341392000099],
              [3.666676045000088, 11.973280907000174],
              [3.661520959000029, 11.967995644000098],
              [3.647274018000132, 11.953304291000052],
              [3.645329952000054, 11.951210023000101],
              [3.635600089000093, 11.940713883000114],
              [3.633543968000083, 11.938494682000112],
              [3.626132965000181, 11.93049907700015],
              [3.625382900000034, 11.929780961000063],
              [3.625088929000128, 11.929567336000048],
              [3.624387026000022, 11.929059030000076],
              [3.619335889000183, 11.925389290000055],
              [3.619183064000083, 11.924657820999983],
              [3.617655993000085, 11.917307854000171],
              [3.617655993000085, 11.916888237000023],
              [3.617675066000174, 11.916646958],
              [3.618721008000023, 11.902500153000062],
              [3.620157003000088, 11.883120537000082],
              [3.621253014000104, 11.868589401000179],
              [3.621398926000097, 11.866810798000188],
              [3.622550010000111, 11.85278987900017],
              [3.622654915000169, 11.852428437000071],
              [3.626751899000055, 11.839382172000057],
              [3.627190113000097, 11.838905335000106],
              [3.634444952000024, 11.831112862000111],
              [3.642759085000023, 11.825773240000046],
              [3.650022030000116, 11.821127892000106],
              [3.650392055000111, 11.82087993600004],
              [3.661808014000087, 11.813197136000099],
              [3.665853024000114, 11.810473443000092],
              [3.671180009000068, 11.799414635000062],
              [3.671396017000177, 11.794589996000127],
              [3.679033994000179, 11.765109062000136],
              [3.679430008000111, 11.763580323000042],
              [3.679157018000069, 11.758503915000119],
              [3.669974090000039, 11.751293182000097],
              [3.666764974000046, 11.748746873000186],
              [3.653328895000072, 11.738092424000115],
              [3.644440889000123, 11.730183601000078],
              [3.632544041000017, 11.719598770000118],
              [3.631222009000055, 11.718568802000163],
              [3.631007909000118, 11.718402863000051],
              [3.621630907000167, 11.711103439000055],
              [3.611143112999969, 11.702939034000167],
              [3.610160112000074, 11.702112198000123],
              [3.609617949000096, 11.701566697000032],
              [3.60893011200011, 11.700874330000033],
              [3.606271982000067, 11.698180199000149],
              [3.605076074000067, 11.696969986999989],
              [3.601439953000067, 11.69961071099999],
              [3.599153995999984, 11.701271058000145],
              [3.588330984000152, 11.710828781000032],
              [3.582778931000064, 11.714440346000174],
              [3.577219963000175, 11.716110229000037],
              [3.571110010000098, 11.719722748000095],
              [3.568609, 11.718891144],
              [3.5682559010001, 11.719004632000178],
              [3.555144071000029, 11.730400086000145],
              [3.550048113000116, 11.737646103000088],
              [3.549797058000024, 11.73800087],
              [3.548418999000148, 11.743761062000033],
              [3.550312995000184, 11.751340867000067],
              [3.559461116000136, 11.765099527000132],
              [3.560646056999985, 11.770159721000141],
              [3.558568002000129, 11.776600838000093],
              [3.553149938000161, 11.779356003000032],
              [3.547499896000034, 11.778330804000063],
              [3.541949988000056, 11.778061867000076],
              [3.535001040000054, 11.77943992600018],
              [3.530167103000053, 11.78256130300008],
              [3.526391029000081, 11.784998894000012],
              [3.525310038999976, 11.785831451000092],
              [3.522780895000153, 11.78777980699999],
              [3.52027988499998, 11.792779924000115],
              [3.519448041000089, 11.800278663000142],
              [3.519958973000087, 11.803154945000074],
              [3.520829916000139, 11.808050157000139],
              [3.521110057000101, 11.81471920000007],
              [3.514924049000058, 11.822521210000104],
              [3.514719010000079, 11.822780610000052],
              [3.509438992000185, 11.827501298000072],
              [3.502701044000105, 11.832368851000183],
              [3.493890046000104, 11.843331338000098],
              [3.492479085000127, 11.845708847000083],
              [3.489439010000069, 11.85083198600006],
              [3.486670018000098, 11.856940270000109],
              [3.481389045000014, 11.861391067000056],
              [3.472219943000084, 11.864721298000177],
              [3.461390018000088, 11.865280151000093],
              [3.447220087000119, 11.874999999000181],
              [3.441109895000125, 11.876939773000117],
              [3.431941033000157, 11.874999999000181],
              [3.426110983000058, 11.874719621000054],
              [3.418329953000182, 11.87860965800013],
              [3.415230035000036, 11.87776470100016],
              [3.41222000200014, 11.876939773000117],
              [3.403048039000169, 11.877499581000052],
              [3.393610955000042, 11.883061409000163],
              [3.389697074000139, 11.884363175000033],
              [3.383610010999973, 11.886388778000025],
              [3.379570962, 11.886721611000155],
              [3.370939970000165, 11.891029358000139],
              [3.352031946000125, 11.887539864000075],
              [3.340434075000019, 11.885403634000056],
              [3.339380979000111, 11.885210990000132],
              [3.336388111000133, 11.885764121000136],
              [3.325381995000043, 11.887800217000063],
              [3.321867943000086, 11.885747910000134],
              [3.317239046000111, 11.886370659000079],
              [3.307631970000159, 11.887660979999964],
              [3.302057982000122, 11.89412880000009],
              [3.29181790399997, 11.928750993000108],
              [3.291023970000026, 11.931428909000147],
              [3.285594940000067, 11.934057236000115],
              [3.283091068000033, 11.934160234000103],
              [3.282560111000066, 11.935549736000155],
              [3.27750897400017, 11.938161851000189],
              [3.275651932000187, 11.940700531000061],
              [3.272485971000151, 11.962559700000099],
            ],
          ],
        },
      },
    ],
  },
  70: {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: { GID_0: "BFA", COUNTRY: "Burkina Faso" },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-4.17767381699997, 9.7780971520001],
              [-4.180419920999896, 9.775327683000057],
              [-4.187161444999902, 9.772187233000068],
              [-4.209064005999892, 9.761985780000032],
              [-4.220201967999969, 9.762400626999977],
              [-4.227609157999837, 9.761990548000085],
              [-4.227707861999932, 9.761916161000045],
              [-4.231705188999968, 9.758899688000156],
              [-4.235301017999973, 9.754444122000052],
              [-4.245934008999939, 9.753264426000158],
              [-4.249040603999902, 9.753355980000094],
              [-4.252425192999908, 9.753455163000069],
              [-4.254347800999938, 9.754259110000021],
              [-4.258786200999964, 9.756114960000105],
              [-4.263636112999905, 9.754612922000035],
              [-4.264574526999923, 9.755257607000146],
              [-4.268815993999851, 9.758172989000116],
              [-4.271103857999947, 9.756972313000119],
              [-4.275542258999963, 9.745200158000102],
              [-4.277658939999924, 9.73958683],
              [-4.277453422999884, 9.739109040000074],
              [-4.27667903899993, 9.737310409000031],
              [-4.26717710399987, 9.737292290000084],
              [-4.265808104999849, 9.73618221300012],
              [-4.265522956999973, 9.735951425000053],
              [-4.265703199999962, 9.733647346999987],
              [-4.267314432999967, 9.731162071000085],
              [-4.271297931999925, 9.725017548000153],
              [-4.270020007999904, 9.719760894000103],
              [-4.27104091599989, 9.713760375000163],
              [-4.274694441999941, 9.709588052000186],
              [-4.275557994999929, 9.708601951000048],
              [-4.27550506699987, 9.707598686000097],
              [-4.275290488999872, 9.703430176000154],
              [-4.275143146999938, 9.700569152000014],
              [-4.279508113999896, 9.698859214000152],
              [-4.283329485999957, 9.692882539000038],
              [-4.284428119999916, 9.691164016000073],
              [-4.281517981999912, 9.68629551000015],
              [-4.280090808999944, 9.683908464000126],
              [-4.283846854999979, 9.676006318000191],
              [-4.2865781779999, 9.672715187000108],
              [-4.289483069999847, 9.66921424800006],
              [-4.287951944999975, 9.664680480000129],
              [-4.287248134999913, 9.662596703000133],
              [-4.28962898199984, 9.655418396000073],
              [-4.288808822999897, 9.64992141800019],
              [-4.289499759999956, 9.645862579000038],
              [-4.290851115999885, 9.637924194000163],
              [-4.295083046999935, 9.630935669000053],
              [-4.300446032999901, 9.622078896000062],
              [-4.303822993999972, 9.617918969000073],
              [-4.305072784999936, 9.616378784000062],
              [-4.311369894999928, 9.608619691000172],
              [-4.318694115999961, 9.599603653000031],
              [-4.336551189999966, 9.614276886000084],
              [-4.349087237999925, 9.624579429000107],
              [-4.349657535999938, 9.625048638000123],
              [-4.350810050999883, 9.625996590000057],
              [-4.361241816999893, 9.62621784300012],
              [-4.362301348999893, 9.625602722000167],
              [-4.36741399899995, 9.622632980000105],
              [-4.370226382999874, 9.619514466000169],
              [-4.370800971999813, 9.618877411000142],
              [-4.373189926999942, 9.612159729000155],
              [-4.372740745999977, 9.609863282000106],
              [-4.37163686699995, 9.604220390000023],
              [-4.371494769999913, 9.60349369100004],
              [-4.371354102999931, 9.602774620000162],
              [-4.374915122999937, 9.596488952000186],
              [-4.376157283999874, 9.595918656000038],
              [-4.380186079999874, 9.594067574],
              [-4.38946199399993, 9.594547271000181],
              [-4.392977236999968, 9.596272470000031],
              [-4.394382952999933, 9.596962929000029],
              [-4.394643306999967, 9.597209931000066],
              [-4.404334068999958, 9.606389046000061],
              [-4.406957148999936, 9.612028123000073],
              [-4.41067981599997, 9.620030403000158],
              [-4.417085170999826, 9.64632034300007],
              [-4.420235156999866, 9.652222634000168],
              [-4.422798156999932, 9.654574394000065],
              [-4.424633979999953, 9.656258583000067],
              [-4.426403045999962, 9.657882691000168],
              [-4.427813053999898, 9.659176827000124],
              [-4.433446882999931, 9.66226482400009],
              [-4.436139583999875, 9.662350654000079],
              [-4.437623023999947, 9.662397386000066],
              [-4.440286158999925, 9.661417007000011],
              [-4.450943947999974, 9.657494546000123],
              [-4.459262369999919, 9.655035017999978],
              [-4.466132164999976, 9.653004646000056],
              [-4.474501608999958, 9.652017594000085],
              [-4.475834846999874, 9.651860237000108],
              [-4.493433951999975, 9.651454926000099],
              [-4.500072954999951, 9.648081780000041],
              [-4.502515316999961, 9.647666931000117],
              [-4.507348060999959, 9.646845816999985],
              [-4.507919787999924, 9.646747590000132],
              [-4.510520934999931, 9.648988725000038],
              [-4.514164924999932, 9.656026839999981],
              [-4.518874168999901, 9.659137725999983],
              [-4.519351958999891, 9.66947078700008],
              [-4.52028036199988, 9.671206474000087],
              [-4.521780014999933, 9.67401027700015],
              [-4.521245956999962, 9.68091869400007],
              [-4.522197246999951, 9.684347152000044],
              [-4.523272991999875, 9.688225746000114],
              [-4.522516726999925, 9.698832513000127],
              [-4.5220580099999, 9.705265044999976],
              [-4.509787082999878, 9.71543502899999],
              [-4.509194374999936, 9.716506959000071],
              [-4.50734090799989, 9.719859123000106],
              [-4.506581306999919, 9.725051879000091],
              [-4.505350111999917, 9.733469964000165],
              [-4.50677585699998, 9.739757538000106],
              [-4.509500026999945, 9.742335319000119],
              [-4.519320964999906, 9.746013641000047],
              [-4.522587299999827, 9.745960236000144],
              [-4.522826193999947, 9.745956421000074],
              [-4.525832176999813, 9.745906829000091],
              [-4.552451133999966, 9.745469093000111],
              [-4.559287071999961, 9.74048233000002],
              [-4.560475825999902, 9.7379732120001],
              [-4.560608863999903, 9.737692833999972],
              [-4.558378696999967, 9.732851029000017],
              [-4.554742811999915, 9.724957467000081],
              [-4.547039985999959, 9.722840310000152],
              [-4.546245575999933, 9.721348763000094],
              [-4.545345783999949, 9.719659806000038],
              [-4.546975134999912, 9.715144157000111],
              [-4.547520160999966, 9.713633536999964],
              [-4.556240081999931, 9.700330734000033],
              [-4.56481409099996, 9.690702439000177],
              [-4.570991037999875, 9.687339782000038],
              [-4.580502033999892, 9.687805176000154],
              [-4.583024501999887, 9.68942737499998],
              [-4.584588049999923, 9.690432549000036],
              [-4.590090275999899, 9.693971635000139],
              [-4.590154170999938, 9.694012642000018],
              [-4.590538978999916, 9.696010591000061],
              [-4.590718744999947, 9.696942330000127],
              [-4.591249464999976, 9.699695588000054],
              [-4.591742037999893, 9.702252388000034],
              [-4.591829299999915, 9.70238590200006],
              [-4.592422961999944, 9.703292847000057],
              [-4.593667983999978, 9.705195425999989],
              [-4.598510742999963, 9.708816528000114],
              [-4.599329948999866, 9.709428787000093],
              [-4.600512503999937, 9.711236],
              [-4.603180883999926, 9.715313913000102],
              [-4.609457970999927, 9.716083527000023],
              [-4.611959932999923, 9.717770576000078],
              [-4.612757205999969, 9.71830749600008],
              [-4.613211153999885, 9.719207763000043],
              [-4.614705085999958, 9.722167016000014],
              [-4.61772489599997, 9.722555160000184],
              [-4.619750975999921, 9.719979286000012],
              [-4.619050024999922, 9.713210107000123],
              [-4.622980593999955, 9.71001815700015],
              [-4.625840185999948, 9.70374012000002],
              [-4.628077505999897, 9.701841354000123],
              [-4.628343105999932, 9.701616287999968],
              [-4.629906653999967, 9.701817513000037],
              [-4.631364821999853, 9.702004432000138],
              [-4.631610393999893, 9.70224857300019],
              [-4.635878085999877, 9.706494332000034],
              [-4.642764568999894, 9.707251550000137],
              [-4.644474983999942, 9.707439424000029],
              [-4.648579120999955, 9.714238167000076],
              [-4.652750014999924, 9.714139938000074],
              [-4.652832507999904, 9.714083673000118],
              [-4.657309055999917, 9.711043358000154],
              [-4.658541678999939, 9.708566667000127],
              [-4.659739971999898, 9.706158639000023],
              [-4.659356116999959, 9.701293946000135],
              [-4.659070014999884, 9.697670936000066],
              [-4.665802955999823, 9.68877315400016],
              [-4.668804168999884, 9.688241959000152],
              [-4.674654960999931, 9.690630914],
              [-4.67619085299998, 9.687602997000056],
              [-4.678194998999913, 9.68365192400006],
              [-4.682251453999925, 9.679189683000061],
              [-4.683126925999943, 9.678817750000121],
              [-4.685697077999976, 9.67772579199999],
              [-4.692399979999891, 9.677105905000133],
              [-4.694512843999917, 9.678202629000111],
              [-4.694956301999866, 9.680498124000053],
              [-4.696104048999871, 9.686440468000058],
              [-4.699590206999915, 9.68712043700009],
              [-4.701744555999937, 9.687542916000155],
              [-4.70239210099993, 9.687669755000059],
              [-4.702723025999887, 9.687688828000148],
              [-4.707960128999957, 9.68799019],
              [-4.712289809999902, 9.694330215000093],
              [-4.720468044999961, 9.715970039000013],
              [-4.724789142999896, 9.722071647000064],
              [-4.727530002999913, 9.729820251999968],
              [-4.73599147799996, 9.734990120000191],
              [-4.736941814999909, 9.735570907000181],
              [-4.738680838999926, 9.740357399000118],
              [-4.738450527999873, 9.741910934000089],
              [-4.737690448999956, 9.747047425000119],
              [-4.7410101899999, 9.75018692000009],
              [-4.745699881999883, 9.751611709000088],
              [-4.749420166999869, 9.752739907000034],
              [-4.758725165999977, 9.753195763000065],
              [-4.771463869999877, 9.753817559000026],
              [-4.772451877999913, 9.749845506000099],
              [-4.77272891899986, 9.748730659000103],
              [-4.771770476999961, 9.741950989000145],
              [-4.771160125999927, 9.737640381000176],
              [-4.771039961999975, 9.736820221000187],
              [-4.776823997999941, 9.736215592000121],
              [-4.781280039999956, 9.738406182],
              [-4.784513950999894, 9.741839409000136],
              [-4.785090922999871, 9.742451667000182],
              [-4.786324023999953, 9.754836082000168],
              [-4.789670467999883, 9.756999969000049],
              [-4.790092944999913, 9.757272721000106],
              [-4.795464038999967, 9.756168365000178],
              [-4.800240040999938, 9.755187034000073],
              [-4.800981044999958, 9.75700759900019],
              [-4.800748824999914, 9.757943153000156],
              [-4.799089908999974, 9.764639854000109],
              [-4.800455092999926, 9.768789291000132],
              [-4.800590991999911, 9.769202232999987],
              [-4.803852081999878, 9.769812583999965],
              [-4.806358814999896, 9.768141746000083],
              [-4.807920455999977, 9.768103599000085],
              [-4.809833049999952, 9.768057824000096],
              [-4.818130015999884, 9.784640312000079],
              [-4.81694841399991, 9.785867691000135],
              [-4.816321848999962, 9.786520957000164],
              [-4.809319973999948, 9.784620285000131],
              [-4.79729795499992, 9.783723831000032],
              [-4.79074096599993, 9.783233643000131],
              [-4.788701056999969, 9.785123824000095],
              [-4.788716792999935, 9.785773277000089],
              [-4.788766860999886, 9.787878991000071],
              [-4.793059826999922, 9.792599679000034],
              [-4.796176909999929, 9.800338745000033],
              [-4.796269893999977, 9.800569535000079],
              [-4.7930698399999, 9.811679841000114],
              [-4.793651581999939, 9.814341546000151],
              [-4.794373035999968, 9.817628860000184],
              [-4.793580055999883, 9.822935104000123],
              [-4.786810873999968, 9.830222130000152],
              [-4.788348675999941, 9.832755089000045],
              [-4.78873920399991, 9.833395959000143],
              [-4.790215968999974, 9.833874703000106],
              [-4.795986175999929, 9.835748674000172],
              [-4.804328917999897, 9.844971655999984],
              [-4.808039188999942, 9.844882965000011],
              [-4.813311100999954, 9.842454911000061],
              [-4.818177223999896, 9.842334748000155],
              [-4.820495129999927, 9.842277527000078],
              [-4.82466840799998, 9.846735001000127],
              [-4.829549788999941, 9.851940155000079],
              [-4.829272745999901, 9.858180047000133],
              [-4.829102992999935, 9.862070085000141],
              [-4.831142425999815, 9.863603592000061],
              [-4.831470011999898, 9.863849641000115],
              [-4.831639766999842, 9.863902092999979],
              [-4.845954894999977, 9.868324280000081],
              [-4.847544669999877, 9.867390632000081],
              [-4.851668834999884, 9.864967346000128],
              [-4.856349467999905, 9.86465835600012],
              [-4.85722494099997, 9.864600181000014],
              [-4.864612101999967, 9.872465134000038],
              [-4.879186152999921, 9.870829583000159],
              [-4.880340098999909, 9.870699882000167],
              [-4.881936548999931, 9.87397194000016],
              [-4.88278102899983, 9.875697136000099],
              [-4.884197711999889, 9.876938819000088],
              [-4.886099815999955, 9.878604889000087],
              [-4.894209861999968, 9.878399850000051],
              [-4.906919001999938, 9.888209342000096],
              [-4.913843153999949, 9.893553735000069],
              [-4.919602870999938, 9.892030716000079],
              [-4.923400878999928, 9.889347076000092],
              [-4.928030968999963, 9.886075974000164],
              [-4.930736064999962, 9.883020401000181],
              [-4.93115424999985, 9.877514839000128],
              [-4.931260109999926, 9.876110078000124],
              [-4.934440136999854, 9.873499869999989],
              [-4.935470579999958, 9.87356853599999],
              [-4.936995028999945, 9.873667718000036],
              [-4.939370154999892, 9.875908851000133],
              [-4.940854071999922, 9.879550934000122],
              [-4.956011771999897, 9.882850646000065],
              [-4.959430217999966, 9.885620118000077],
              [-4.967852114999914, 9.892443658000161],
              [-4.968733787999952, 9.89525318200009],
              [-4.971519946999877, 9.904120445000046],
              [-4.972274779999907, 9.906531333000032],
              [-4.972650051999949, 9.907730102000187],
              [-4.97178983699996, 9.91028022900008],
              [-4.964910506999956, 9.913669585000037],
              [-4.964543341999956, 9.914548874000047],
              [-4.96142005899992, 9.92203044900009],
              [-4.961030004999884, 9.924530029000152],
              [-4.960219859999938, 9.929650307000145],
              [-4.961323260999905, 9.931817055000181],
              [-4.964613914999973, 9.938273431000027],
              [-4.962775706999935, 9.941301345000113],
              [-4.961500166999883, 9.943409919000146],
              [-4.958300114999872, 9.945560455000077],
              [-4.949759959999881, 9.947150230999966],
              [-4.948092936999899, 9.950252532000093],
              [-4.94688177199987, 9.952507973000081],
              [-4.947607515999948, 9.953531266000084],
              [-4.948804854999935, 9.955220222000037],
              [-4.959114074999889, 9.959101677000149],
              [-4.961536406999869, 9.961116791000052],
              [-4.963140963999933, 9.96245193600015],
              [-4.963196276999895, 9.964635849000104],
              [-4.9633297929999, 9.96979999500013],
              [-4.96503829999989, 9.971870422000109],
              [-4.966681002999906, 9.973856926],
              [-4.972979544999873, 9.976435661000096],
              [-4.983089923999842, 9.980569840000158],
              [-4.987089155999968, 9.983776093000131],
              [-4.98783016099992, 9.984370231000071],
              [-4.988783835999868, 9.996693611000126],
              [-4.989040852999892, 10.00000095300004],
              [-4.989122390999967, 10.00104999500013],
              [-4.989560126999947, 10.006620408000174],
              [-4.988269805999948, 10.010560036000129],
              [-4.986239908999949, 10.011690141000145],
              [-4.975658415999931, 10.007973672000048],
              [-4.964433191999831, 10.004031182000119],
              [-4.964083195999933, 10.00837040000016],
              [-4.964079857999877, 10.008410455000046],
              [-4.964807509999957, 10.009323120000147],
              [-4.96839523299991, 10.013818741000023],
              [-4.96208381699995, 10.021100999000055],
              [-4.961452006999878, 10.023649216000138],
              [-4.969731807999835, 10.029879571000038],
              [-4.971022128999948, 10.034898758000111],
              [-4.970470904999957, 10.040670396000166],
              [-4.971673965999969, 10.042248725000093],
              [-4.98038005899997, 10.044453621000116],
              [-4.982419967999931, 10.044969558000048],
              [-4.986144541999977, 10.048161507000088],
              [-4.990244863999976, 10.051671029000033],
              [-4.99268960899991, 10.058064461000072],
              [-4.993729591999966, 10.060778619000075],
              [-4.996969698999919, 10.062247277000097],
              [-4.999999045999914, 10.063620567000044],
              [-5.004395961999933, 10.065560340000047],
              [-5.005777836999869, 10.065193176000037],
              [-5.010260104999929, 10.064000130000124],
              [-5.012056828999903, 10.063525200000072],
              [-5.012339591999933, 10.063449859000116],
              [-5.017640112999914, 10.06494998900007],
              [-5.019138335999969, 10.067197799999974],
              [-5.021224975999871, 10.070321083000067],
              [-5.021670818999837, 10.07201767],
              [-5.022079943999927, 10.07355976000008],
              [-5.020956515999956, 10.076277733000154],
              [-5.020103930999937, 10.07835102199999],
              [-5.014894008999931, 10.082831382000165],
              [-5.009222983999905, 10.096719743000051],
              [-5.003714084999899, 10.103949547000184],
              [-5.010413168999889, 10.105259895000074],
              [-5.013403891999815, 10.103171347999989],
              [-5.016788959999929, 10.100811005000025],
              [-5.023056983999936, 10.100502015000018],
              [-5.027285099999915, 10.098527908000051],
              [-5.032959937999976, 10.094059943000104],
              [-5.036697863999905, 10.09300136600018],
              [-5.041448115999913, 10.088508606000062],
              [-5.042793273999848, 10.085749626000052],
              [-5.045100211999966, 10.081009864000066],
              [-5.047943114999953, 10.081372261000126],
              [-5.04949998799998, 10.081568718000142],
              [-5.051148414999886, 10.082757951000019],
              [-5.052467822999972, 10.083708763],
              [-5.058990955999946, 10.081860542000129],
              [-5.062983034999945, 10.080730439000149],
              [-5.063800810999965, 10.08236408300013],
              [-5.065199851999978, 10.085149766000143],
              [-5.063992499999927, 10.08794498400016],
              [-5.063231943999938, 10.089710237000077],
              [-5.050393579999934, 10.101123809000114],
              [-5.047349929999939, 10.103830337999966],
              [-5.047637462999887, 10.105450630000121],
              [-5.047760963999906, 10.106140137000068],
              [-5.047998427999914, 10.106201172000056],
              [-5.051681040999881, 10.107151984000041],
              [-5.058930872999952, 10.104790688000037],
              [-5.059775351999917, 10.105153083000118],
              [-5.06628608699998, 10.107950211000059],
              [-5.067491530999973, 10.107789039000068],
              [-5.069736003999935, 10.107488632000184],
              [-5.076529979999975, 10.106579780000118],
              [-5.079954147999956, 10.108959199000083],
              [-5.081654071999822, 10.115669251000156],
              [-5.078672884999889, 10.123881341000185],
              [-5.073860167999953, 10.121239662000164],
              [-5.06637001099989, 10.124050141000112],
              [-5.063138007999896, 10.12329101600011],
              [-5.060229778999883, 10.118620872],
              [-5.057240008999941, 10.117401124000025],
              [-5.054420948999962, 10.118940354000188],
              [-5.053171634999956, 10.118910790000029],
              [-5.047924041999977, 10.118789672000162],
              [-5.044092177999914, 10.12376022300009],
              [-5.049242018999962, 10.131701470000166],
              [-5.068210124999894, 10.134679794000022],
              [-5.074615477999885, 10.13887500800007],
              [-5.079864978999922, 10.142310142000042],
              [-5.081592081999929, 10.14787101700017],
              [-5.080300331999979, 10.153360367000175],
              [-5.080667495999876, 10.154978753000137],
              [-5.081140995999931, 10.157060623000064],
              [-5.080605982999941, 10.157949447000078],
              [-5.077033995999898, 10.163859367000043],
              [-5.075507163999873, 10.171799661000136],
              [-5.075200557999892, 10.173392295999975],
              [-5.07413005799998, 10.178970337000123],
              [-5.073595046999969, 10.181730271000049],
              [-5.07472801299997, 10.182901383000114],
              [-5.095466136999903, 10.179700851000177],
              [-5.099188326999922, 10.178097726000146],
              [-5.105080127999941, 10.17555999700005],
              [-5.110403059999953, 10.176368713000159],
              [-5.11359119399998, 10.178970337000123],
              [-5.113902090999943, 10.179624558000171],
              [-5.114699840999947, 10.181300164000049],
              [-5.11358737899991, 10.182591439000078],
              [-5.111595152999939, 10.184911728000088],
              [-5.098529815999882, 10.187829972000145],
              [-5.09289073899987, 10.198031425000181],
              [-5.091250896999895, 10.201000214000089],
              [-5.090363025999977, 10.20399761200008],
              [-5.088479994999887, 10.210359573000062],
              [-5.08863973699988, 10.212709428000096],
              [-5.089075087999902, 10.219030381000039],
              [-5.094820976999927, 10.226840973000037],
              [-5.096938610999871, 10.224342346000128],
              [-5.102465152999912, 10.217820168000173],
              [-5.102567672999953, 10.217778206000048],
              [-5.122930048999876, 10.20899009599998],
              [-5.12274551299987, 10.209447860000012],
              [-5.117050170999903, 10.223580359999971],
              [-5.108819960999824, 10.228960037000036],
              [-5.107240198999932, 10.237950326000032],
              [-5.113820074999978, 10.2381095880001],
              [-5.119619846999967, 10.242179871000133],
              [-5.120870112999967, 10.24973011000003],
              [-5.118760108999823, 10.256420136000031],
              [-5.113920211999925, 10.25914955200011],
              [-5.106709956999964, 10.261670112000104],
              [-5.100249766999923, 10.268569946000071],
              [-5.099020003999954, 10.272100449000106],
              [-5.10406017299988, 10.275639534000049],
              [-5.120500087999972, 10.278770446000124],
              [-5.120882032999873, 10.282369615000107],
              [-5.121175288999893, 10.282479287000115],
              [-5.118939876999946, 10.284799576000182],
              [-5.117819786999974, 10.285960198000112],
              [-5.11757993599997, 10.286379815000089],
              [-5.1146402359999, 10.291480064000154],
              [-5.111060142999975, 10.304679870000029],
              [-5.114729881999949, 10.304120064000074],
              [-5.124259948999963, 10.298489570000129],
              [-5.128709792999871, 10.307089806000079],
              [-5.131440162999922, 10.307900429000085],
              [-5.13249015699995, 10.301059723000037],
              [-5.13685989399994, 10.300290107000137],
              [-5.144181251999953, 10.292186737000065],
              [-5.147999763999962, 10.287960052000017],
              [-5.157509803999915, 10.283929825000087],
              [-5.163020132999975, 10.284910201],
              [-5.170899866999889, 10.285599709000053],
              [-5.174950121999927, 10.291390419999971],
              [-5.187560081999948, 10.284790038000097],
              [-5.191160201999935, 10.290650368000058],
              [-5.187210083999901, 10.298970223000026],
              [-5.192392826999935, 10.302061080000044],
              [-5.196112155999913, 10.304848671],
              [-5.206015109999953, 10.308529853000152],
              [-5.207471847999955, 10.310379982000029],
              [-5.208045959999936, 10.311109544000146],
              [-5.206422805999921, 10.314434052000081],
              [-5.205605982999941, 10.31611061100017],
              [-5.206855773999962, 10.322349548000034],
              [-5.210111141999903, 10.32219123800013],
              [-5.222489356999972, 10.30936050400004],
              [-5.232100009999954, 10.31556034],
              [-5.240429877999929, 10.315010072000121],
              [-5.246215820999964, 10.314626694000026],
              [-5.250242232999938, 10.314359666000144],
              [-5.259325980999961, 10.31319046100009],
              [-5.266370772999949, 10.30989933],
              [-5.267453670999885, 10.311287880000179],
              [-5.270209787999932, 10.314820290000057],
              [-5.270999907999965, 10.314903259000062],
              [-5.27725124299991, 10.315555572000164],
              [-5.278551101999938, 10.315690993999965],
              [-5.280673025999874, 10.314131738000128],
              [-5.281252860999928, 10.30909061400007],
              [-5.285970686999974, 10.309410095000146],
              [-5.286124228999825, 10.309420585000112],
              [-5.290067672999953, 10.313409806000038],
              [-5.29543495199988, 10.318840026000089],
              [-5.300286769999957, 10.31986141200008],
              [-5.304030417999968, 10.318660736000027],
              [-5.304386614999885, 10.318546295000033],
              [-5.304965971999934, 10.318360329000143],
              [-5.30641555699998, 10.316988945000162],
              [-5.308050155999979, 10.31544017900012],
              [-5.304333686999939, 10.313344956000037],
              [-5.30209016699996, 10.312081337],
              [-5.301476955999931, 10.308390617000157],
              [-5.302590369999962, 10.306711198000016],
              [-5.303880215999925, 10.304759979000153],
              [-5.306169032999947, 10.305303574000106],
              [-5.307119844999932, 10.305529595000053],
              [-5.307659625999975, 10.306153297000151],
              [-5.309153079999931, 10.307870865000154],
              [-5.313941001999922, 10.301540375000059],
              [-5.32068109599993, 10.301460267000152],
              [-5.328269958999897, 10.294652940000105],
              [-5.329689979999955, 10.293379783000034],
              [-5.334569453999961, 10.293259620000129],
              [-5.346030235999876, 10.29994964600013],
              [-5.351627825999969, 10.301582337000127],
              [-5.352028369999971, 10.301699638000173],
              [-5.352920055999846, 10.303560258000118],
              [-5.351022719999889, 10.306020738000086],
              [-5.350999831999957, 10.306050302000074],
              [-5.352950572999873, 10.306455613000139],
              [-5.354701041999931, 10.306819916000165],
              [-5.359172821999948, 10.304161071000181],
              [-5.365120887999922, 10.287500382000189],
              [-5.368192196999928, 10.285269738000181],
              [-5.371526717999871, 10.285596848000068],
              [-5.374217033999969, 10.285861016000069],
              [-5.38215112599994, 10.294551850000119],
              [-5.390524863999815, 10.292519569999968],
              [-5.391724109999927, 10.292228699000191],
              [-5.39676284799998, 10.295331002000069],
              [-5.407209872999943, 10.295559883000124],
              [-5.413080692999927, 10.301777839000124],
              [-5.417191027999934, 10.306130410000094],
              [-5.417452334999894, 10.306226730000105],
              [-5.423182010999938, 10.308340071999964],
              [-5.423902987999895, 10.317781449],
              [-5.425284384999884, 10.31943988900008],
              [-5.426058768999951, 10.320369720000031],
              [-5.42689943299996, 10.321380615000066],
              [-5.428198813999927, 10.322942734000094],
              [-5.432850836999876, 10.322580339000069],
              [-5.433795928999928, 10.321970939999972],
              [-5.438035964999926, 10.319238662000032],
              [-5.440309523999929, 10.319854736000025],
              [-5.440806864999956, 10.31998920500007],
              [-5.444407940999895, 10.325131416000033],
              [-5.444709777999947, 10.332500459000187],
              [-5.445661066999946, 10.33507824000003],
              [-5.446682929999952, 10.33784198699999],
              [-5.451479911999911, 10.34138965600016],
              [-5.461451053999895, 10.342068672000039],
              [-5.468103885999938, 10.345900537000091],
              [-5.47132444499988, 10.350411416000043],
              [-5.47260999699995, 10.352211952000175],
              [-5.467190265999932, 10.355772019000085],
              [-5.46725177899998, 10.356118203000165],
              [-5.4676032079999, 10.358079910000185],
              [-5.465087414999971, 10.360679627000081],
              [-5.464990137999962, 10.360779762000107],
              [-5.467116832999977, 10.364613534000057],
              [-5.469902037999873, 10.369630814000061],
              [-5.468788145999895, 10.372902870000075],
              [-5.468659876999936, 10.373279572000172],
              [-5.472764969999957, 10.376818658000047],
              [-5.478808879999974, 10.376488685000027],
              [-5.480215073999887, 10.37547397700007],
              [-5.482824802999915, 10.373590469000021],
              [-5.48807382699988, 10.377849578999985],
              [-5.489007472999958, 10.380003929999987],
              [-5.489180088999945, 10.38039970400007],
              [-5.48652982699997, 10.384710313000141],
              [-5.499718189999953, 10.40822982800006],
              [-5.502954006999971, 10.408991813000114],
              [-5.508325100999969, 10.415322305000075],
              [-5.509521006999876, 10.416731836000054],
              [-5.50910091499992, 10.425230027000055],
              [-5.511141777999967, 10.424274444000105],
              [-5.51331567699998, 10.423255921000134],
              [-5.513797759999875, 10.423030853000171],
              [-5.517509936999886, 10.423339843000122],
              [-5.516627311999969, 10.425589561000095],
              [-5.515540598999849, 10.428360938000026],
              [-5.518918036999878, 10.433259965000104],
              [-5.518749713999966, 10.434888839000052],
              [-5.518324851999978, 10.43900108400004],
              [-5.516574859999878, 10.443100929000025],
              [-5.513036251999949, 10.451396943000077],
              [-5.51219892499995, 10.453359605000117],
              [-5.512629507999918, 10.454429627000025],
              [-5.514624118999961, 10.459389687000055],
              [-5.514450549999879, 10.461136819000046],
              [-5.514327049999963, 10.462380410000151],
              [-5.513011932999973, 10.463804244000187],
              [-5.510993003999886, 10.465991021000036],
              [-5.50944566599992, 10.469973563000053],
              [-5.508778094999968, 10.471691131000057],
              [-5.508157252999922, 10.472007751000092],
              [-5.506188868999971, 10.473010063000117],
              [-5.503254890999926, 10.479621888000111],
              [-5.502234935999979, 10.480284691000179],
              [-5.49948596899992, 10.482069970000168],
              [-5.497933863999947, 10.489171983000119],
              [-5.495088577999979, 10.491866111999968],
              [-5.494607925999901, 10.492321015000073],
              [-5.494947433999926, 10.493404388999977],
              [-5.495695113999886, 10.495789528000046],
              [-5.491111754999906, 10.505349159000104],
              [-5.48973131199989, 10.508229256000106],
              [-5.485219955999924, 10.517640114000187],
              [-5.482089518999942, 10.529141426000024],
              [-5.477375982999945, 10.546460152],
              [-5.472806453999908, 10.551652908000165],
              [-5.470221995999964, 10.554590226000187],
              [-5.468870639999977, 10.556563378000021],
              [-5.465415, 10.561609267000165],
              [-5.46977949199993, 10.572850227000117],
              [-5.472893237999926, 10.580869675000031],
              [-5.465896129999976, 10.592451095000115],
              [-5.4652061459999, 10.596560478000015],
              [-5.465088844999968, 10.597260475000155],
              [-5.465870380999888, 10.599843979000184],
              [-5.467013834999932, 10.603623391000099],
              [-5.467473028999962, 10.605138779000072],
              [-5.470302103999927, 10.607256889000155],
              [-5.471563815999957, 10.608201981000036],
              [-5.475976942999978, 10.609487533000163],
              [-5.477511406999952, 10.609933854000076],
              [-5.477600098999972, 10.609959603000163],
              [-5.477683067999919, 10.610323907000065],
              [-5.478445052999916, 10.613659859000052],
              [-5.480550766999954, 10.614851952000038],
              [-5.480969906999917, 10.615089416000103],
              [-5.482879638999918, 10.623417854000024],
              [-5.482114314999933, 10.624756814000136],
              [-5.479894160999891, 10.62864112799997],
              [-5.48026323299996, 10.645180703000051],
              [-5.479444979999869, 10.660578727000086],
              [-5.476414202999933, 10.717638969000063],
              [-5.476381778999894, 10.718250275000059],
              [-5.475461482999947, 10.720707893000167],
              [-5.474539279999931, 10.72317028000009],
              [-5.46997022599993, 10.735369682000112],
              [-5.473899840999877, 10.758449553999981],
              [-5.471331119999888, 10.760042191000082],
              [-5.470990180999934, 10.760253906000059],
              [-5.466351986999939, 10.76313114300001],
              [-5.459127425999952, 10.764539719000027],
              [-5.452565192999828, 10.765819549000071],
              [-5.450298308999948, 10.769029617000115],
              [-5.450050829999952, 10.769379616000037],
              [-5.449203014999966, 10.770581245000074],
              [-5.44870233499995, 10.774927139999988],
              [-5.44777393399994, 10.78298187300004],
              [-5.445012091999956, 10.790630341000167],
              [-5.444561004999969, 10.791879655000116],
              [-5.444441794999932, 10.79471492800019],
              [-5.444087027999899, 10.803140640000095],
              [-5.441435336999973, 10.807144165000125],
              [-5.438074111999981, 10.812218665999978],
              [-5.436480044999939, 10.813020706000088],
              [-5.435012817999962, 10.813758850000056],
              [-5.43516779, 10.81481266000003],
              [-5.435420989999898, 10.816531182000119],
              [-5.432678698999951, 10.819516182000029],
              [-5.428989887999876, 10.823530198000128],
              [-5.424050806999958, 10.836529732000145],
              [-5.422309874999883, 10.852600098000039],
              [-5.422415255999908, 10.852801322000118],
              [-5.424993990999951, 10.857719422000116],
              [-5.426710128999901, 10.859411240000099],
              [-5.427362919999951, 10.860054970000078],
              [-5.431293010999923, 10.863931656000034],
              [-5.433637618999967, 10.866244317000167],
              [-5.435481071999902, 10.868062020000025],
              [-5.44396209699994, 10.870804786000122],
              [-5.44664716799997, 10.871672631000081],
              [-5.448430061999886, 10.872249604000103],
              [-5.449038982999923, 10.873478889000125],
              [-5.464950083999952, 10.905500412000038],
              [-5.464338779999935, 10.93400859799999],
              [-5.469266890999904, 10.943091393000032],
              [-5.468637943999909, 10.961711883000078],
              [-5.469803332999959, 10.963171006000096],
              [-5.471815109999966, 10.965688705000161],
              [-5.479153155999882, 10.96797943200005],
              [-5.481463909999945, 10.968700408000188],
              [-5.485953807999977, 10.97010040400005],
              [-5.486852169999963, 10.970380784000156],
              [-5.487610339999947, 10.971162795999987],
              [-5.48875284199994, 10.972340584000108],
              [-5.489586829999951, 10.973200799000153],
              [-5.489358901999935, 10.97601699900008],
              [-5.489271162999955, 10.977100372000109],
              [-5.487223146999952, 10.980093002000103],
              [-5.486629961999881, 10.980959893000147],
              [-5.486600875999955, 10.987646103000031],
              [-5.486575603999938, 10.993476867000027],
              [-5.486569881999969, 10.994759559000101],
              [-5.486965654999949, 10.996201515000052],
              [-5.493598460999976, 11.020380020000118],
              [-5.49456977899996, 11.023920058000101],
              [-5.495800971999927, 11.04850959800001],
              [-5.49627208599992, 11.057925224000144],
              [-5.496310232999974, 11.058682442000077],
              [-5.496531962999939, 11.063069343000052],
              [-5.492228506999936, 11.069656372],
              [-5.490749834999974, 11.071919442000024],
              [-5.484133719999932, 11.073820114000057],
              [-5.480226993999906, 11.074942589000159],
              [-5.470592974999931, 11.077711106000152],
              [-5.466103076999957, 11.080599784000071],
              [-5.465427397999918, 11.080605507000087],
              [-5.459117889999959, 11.080661774000021],
              [-5.458413124999879, 11.080671310000128],
              [-5.412088393999966, 11.092189790000134],
              [-5.408987998999976, 11.092960358000141],
              [-5.407163142999877, 11.093575477000115],
              [-5.405186175999972, 11.094241143000147],
              [-5.39420318599997, 11.097940444000074],
              [-5.363715171999957, 11.117759705000083],
              [-5.361474989999977, 11.119532586000105],
              [-5.359870909999927, 11.120803833000082],
              [-5.349012850999941, 11.129409791000171],
              [-5.345095156999889, 11.133111955000061],
              [-5.341981410999892, 11.136051178000173],
              [-5.341400146999945, 11.136599541000123],
              [-5.340880394999942, 11.138178825000068],
              [-5.33837556799989, 11.145789146000141],
              [-5.336087702999976, 11.152740478999988],
              [-5.3359098439999, 11.153280258000166],
              [-5.335709571999928, 11.154349327000091],
              [-5.332220076999874, 11.172989846000064],
              [-5.332749365999973, 11.178064345999985],
              [-5.332990169999903, 11.180370332000052],
              [-5.332215785999949, 11.183136939000065],
              [-5.328189849999944, 11.197520256000075],
              [-5.323060511999813, 11.20087528300013],
              [-5.317272662999926, 11.204660415000092],
              [-5.314230918999954, 11.206649781000067],
              [-5.285943984999904, 11.220991135000077],
              [-5.282248974999902, 11.2246027],
              [-5.278232096999886, 11.228529931000139],
              [-5.274714468999889, 11.231965065000168],
              [-5.272128104999979, 11.234490395000023],
              [-5.26941108799997, 11.242726326000138],
              [-5.26738119099997, 11.248880387000156],
              [-5.264623165999922, 11.266080855999974],
              [-5.264351843999862, 11.27343082499999],
              [-5.265079974999878, 11.286437035],
              [-5.26577711099992, 11.298892021000086],
              [-5.26780700799992, 11.335170746000017],
              [-5.267551420999894, 11.346697808000044],
              [-5.267480849999913, 11.349888803],
              [-5.265651226999978, 11.358820916000184],
              [-5.26365947599993, 11.36184787799999],
              [-5.259379862999879, 11.368350030000101],
              [-5.246335028999965, 11.377959251000163],
              [-5.234505176999903, 11.382915497999988],
              [-5.233850001999883, 11.383190156000182],
              [-5.23173999799991, 11.384080887000039],
              [-5.2195520399999, 11.393264770000087],
              [-5.216320037999935, 11.39570045500011],
              [-5.210968016999971, 11.405480384999976],
              [-5.207437038999899, 11.417371750000029],
              [-5.205644607999943, 11.419604302000039],
              [-5.20191097299994, 11.424253465000049],
              [-5.200932978999902, 11.425470352000104],
              [-5.20060682399992, 11.427098274000116],
              [-5.200510501999929, 11.427580834000082],
              [-5.200261115999922, 11.428830146999985],
              [-5.199525832999939, 11.432510377000028],
              [-5.201097488999949, 11.435491562000038],
              [-5.201982975999954, 11.437170982999987],
              [-5.202390669999886, 11.439800263000109],
              [-5.202948093999851, 11.443396569000129],
              [-5.203199862999895, 11.445019723000144],
              [-5.205696104999959, 11.447970391000069],
              [-5.21091699699997, 11.454171181999982],
              [-5.212309836999964, 11.458049774000131],
              [-5.212357521999877, 11.458170892000169],
              [-5.213602065999908, 11.46133041500002],
              [-5.214361190999966, 11.466670037000029],
              [-5.213718890999928, 11.469361306000053],
              [-5.21048402699995, 11.482919694000145],
              [-5.210760592999918, 11.486714364000022],
              [-5.210954188999949, 11.489370346000044],
              [-5.215003965999927, 11.512451173000102],
              [-5.212403773999881, 11.516369819000147],
              [-5.209452150999937, 11.520820618000073],
              [-5.20389318499997, 11.529199600000027],
              [-5.204226493999954, 11.531102182000097],
              [-5.204774856999904, 11.53423118600017],
              [-5.212463379999974, 11.545044900000164],
              [-5.214572905999944, 11.548011779000035],
              [-5.217639446999954, 11.554466247000107],
              [-5.219389914999908, 11.55815029199999],
              [-5.222654819999946, 11.57118034500013],
              [-5.222859858999868, 11.575990678000153],
              [-5.223245144999964, 11.585301399000059],
              [-5.227647303999959, 11.590289116000179],
              [-5.228672026999902, 11.591450692000024],
              [-5.23041439099984, 11.592365265000069],
              [-5.234686850999935, 11.594609260000027],
              [-5.239925861999893, 11.602790833000086],
              [-5.240999698999929, 11.604471207000074],
              [-5.241261958999928, 11.604881287000069],
              [-5.248699187999875, 11.60620117100018],
              [-5.256031990999873, 11.605519293999976],
              [-5.26371097599997, 11.604805946000113],
              [-5.278639792999968, 11.603420258000028],
              [-5.284440039999936, 11.604701043000034],
              [-5.287268637999944, 11.606937408000078],
              [-5.293193817999963, 11.611620903000073],
              [-5.299092768999913, 11.619290352000064],
              [-5.299204348999922, 11.621980668000106],
              [-5.299405098999898, 11.626811027000144],
              [-5.294144153999923, 11.637414933000173],
              [-5.29074192, 11.644271850000052],
              [-5.290210722999973, 11.646127702000172],
              [-5.289000033999912, 11.650360108000029],
              [-5.275588034999885, 11.67409038500017],
              [-5.27160596899995, 11.68587112400013],
              [-5.27153921099989, 11.688781738000102],
              [-5.271473884999921, 11.691620826000189],
              [-5.272943973999873, 11.697240830000112],
              [-5.273551939999891, 11.699561118000076],
              [-5.276199818999885, 11.709670067000104],
              [-5.273509024999896, 11.714900017000105],
              [-5.270447731999923, 11.721199035000097],
              [-5.27007722899998, 11.721961021000084],
              [-5.270818233999933, 11.730010987000185],
              [-5.271010875999878, 11.732101441000111],
              [-5.267542362999961, 11.738838196000017],
              [-5.265388964999943, 11.743020057000081],
              [-5.265224456999931, 11.744740486000069],
              [-5.265080927999975, 11.74623966300004],
              [-5.266026496999928, 11.748577118000071],
              [-5.268839835999927, 11.755530358000044],
              [-5.263979910999979, 11.763697624000088],
              [-5.268661022999879, 11.765667916000041],
              [-5.269077777999883, 11.765843392000136],
              [-5.269308090999971, 11.765939714000126],
              [-5.269762039999932, 11.766130448000069],
              [-5.273983001999966, 11.765310286999977],
              [-5.278725146999932, 11.76138687100007],
              [-5.280728340999929, 11.759729385000071],
              [-5.281837462999874, 11.758811950000108],
              [-5.28532648099997, 11.759531976000176],
              [-5.285559176999868, 11.759579658000064],
              [-5.287772655999959, 11.761691094000128],
              [-5.297514914999908, 11.770982743000047],
              [-5.293642997999939, 11.775980949000029],
              [-5.296154975999968, 11.779780387000187],
              [-5.297420025999884, 11.781699180000032],
              [-5.297691345999965, 11.782232285000191],
              [-5.299439907999954, 11.785659790000182],
              [-5.300822734999883, 11.785655022000128],
              [-5.308310030999962, 11.785630226000023],
              [-5.310326099999884, 11.786390305000168],
              [-5.311553000999879, 11.786861419000047],
              [-5.313060283999903, 11.789172172000178],
              [-5.315917014999911, 11.793551445000048],
              [-5.323669910999968, 11.794309616000021],
              [-5.333985805999873, 11.798858644000177],
              [-5.334207534999905, 11.798967361],
              [-5.334642886999973, 11.79918003099999],
              [-5.336346626999898, 11.800529480000023],
              [-5.337840079999921, 11.801712037000073],
              [-5.347630023999841, 11.802390099000036],
              [-5.34864902399994, 11.803242684000168],
              [-5.351298807999967, 11.805460931000141],
              [-5.354156971999942, 11.802990913000031],
              [-5.354906081999957, 11.799061776000087],
              [-5.355626105999932, 11.798222542000133],
              [-5.355770109999924, 11.798054696000065],
              [-5.357283115999962, 11.796291351000093],
              [-5.358542919999934, 11.796859742000152],
              [-5.361265181999954, 11.798090935000118],
              [-5.364119052999968, 11.801259994000077],
              [-5.365180968999937, 11.802438736000056],
              [-5.36768341099986, 11.802810669000053],
              [-5.369766235999975, 11.80312061300009],
              [-5.373664855999948, 11.809988975000124],
              [-5.374169826999889, 11.8108787540001],
              [-5.381215094999902, 11.812582017000125],
              [-5.383319855999901, 11.813090325000076],
              [-5.386515140999961, 11.81663799200004],
              [-5.387238024999931, 11.81744003200015],
              [-5.39038801199996, 11.818090440000162],
              [-5.391184329999931, 11.817401885000095],
              [-5.393449305999923, 11.815439223000055],
              [-5.394209860999979, 11.814780235000057],
              [-5.396226882999883, 11.814830780000079],
              [-5.397700786999963, 11.816466331000129],
              [-5.400145053999893, 11.819178581000017],
              [-5.408094881999944, 11.823349954000093],
              [-5.409285067999861, 11.825845718000039],
              [-5.409802913999897, 11.826931954000031],
              [-5.410006998999904, 11.827359199000114],
              [-5.409700869999881, 11.829355240000154],
              [-5.409314155999937, 11.831878663000111],
              [-5.408860206999975, 11.832473754000091],
              [-5.407959937999976, 11.833652497000173],
              [-5.406334876999893, 11.835781097000051],
              [-5.404223917999957, 11.836053849000052],
              [-5.397999763999962, 11.836858749000044],
              [-5.389757156999963, 11.839900970000144],
              [-5.388309001999971, 11.841940879999981],
              [-5.381989000999909, 11.842490195999972],
              [-5.381464003999895, 11.841343880000181],
              [-5.381329536999886, 11.841050148000022],
              [-5.379541873999926, 11.837149620000048],
              [-5.376039504999881, 11.836688042000048],
              [-5.373252868999884, 11.836320877000162],
              [-5.367987631999881, 11.833166124000172],
              [-5.367261885999881, 11.832731246000037],
              [-5.360682009999891, 11.824297906000027],
              [-5.359477995999953, 11.824398995000138],
              [-5.3541269299999, 11.824850082000069],
              [-5.349366186999873, 11.827260972000033],
              [-5.336259840999901, 11.83559322300016],
              [-5.33347988099996, 11.837360382999975],
              [-5.331571100999895, 11.840354921000142],
              [-5.328680037999902, 11.844889641000179],
              [-5.328325270999926, 11.84495162900015],
              [-5.327238082999941, 11.845141410000167],
              [-5.324478148999958, 11.842511177],
              [-5.323627948999956, 11.841791153000145],
              [-5.323109626999951, 11.840989113000035],
              [-5.322646139999961, 11.840275765000172],
              [-5.322480201999952, 11.840020180000067],
              [-5.321825981999893, 11.83994579400013],
              [-5.315889358999925, 11.83927250000005],
              [-5.30829095799993, 11.838411332000021],
              [-5.307343005999883, 11.838311195000131],
              [-5.304174899999964, 11.84654045100018],
              [-5.301090239999894, 11.848814010000069],
              [-5.298504828999967, 11.85072040600005],
              [-5.292974949999973, 11.853631020000023],
              [-5.290539265999882, 11.853824617000157],
              [-5.286190031999922, 11.854169846000048],
              [-5.284110068999951, 11.85297012400008],
              [-5.284007549999899, 11.852403640000091],
              [-5.283309936999956, 11.848549842000068],
              [-5.283193588999893, 11.847228050000126],
              [-5.28293609699989, 11.844300271000066],
              [-5.281242369999916, 11.842362405000131],
              [-5.280182837999917, 11.841150283000047],
              [-5.278799057999891, 11.840803147000088],
              [-5.278052805999892, 11.840616226000179],
              [-5.275360106999926, 11.839940071000115],
              [-5.274106025999913, 11.83979034400005],
              [-5.271602152999947, 11.837077142000112],
              [-5.270921230999932, 11.836338997000041],
              [-5.270266055999969, 11.835630417000061],
              [-5.267697810999948, 11.835581779999984],
              [-5.263349056999914, 11.841920854000136],
              [-5.256222724999873, 11.842415810000034],
              [-5.253286837999951, 11.842619897000134],
              [-5.254001617999961, 11.844735146000119],
              [-5.254381178999893, 11.845858574000147],
              [-5.253931044999945, 11.846941947000175],
              [-5.252347945999816, 11.850749970000038],
              [-5.25247716999985, 11.853450775000113],
              [-5.25492143699995, 11.854048730000102],
              [-5.255051134999917, 11.854080201000158],
              [-5.250859260999903, 11.862572670000191],
              [-5.250490188999947, 11.863320352000073],
              [-5.243430137999951, 11.869099618000064],
              [-5.241443157999925, 11.869421960000068],
              [-5.240458965999949, 11.869582176000165],
              [-5.235912800999927, 11.870321275000151],
              [-5.224471091999874, 11.878529549000177],
              [-5.222683905999872, 11.88236141200008],
              [-5.220809935999967, 11.886380195000129],
              [-5.216474054999878, 11.887121200000081],
              [-5.210773944999971, 11.895760537000172],
              [-5.208673, 11.896233559000052],
              [-5.207666874999916, 11.896460533000038],
              [-5.20728540399989, 11.896546364000073],
              [-5.206715107999969, 11.896676065000065],
              [-5.197215079999978, 11.898821831000191],
              [-5.19470501, 11.901941299000157],
              [-5.194579123999972, 11.902094841000064],
              [-5.19299984, 11.904060364000088],
              [-5.192957877999959, 11.904067994000172],
              [-5.188639162999948, 11.904750823000086],
              [-5.18556022599995, 11.90814972000004],
              [-5.184345721999875, 11.911217689000125],
              [-5.180401801999892, 11.921182633000058],
              [-5.180319785999927, 11.921389580000039],
              [-5.180328845999895, 11.921815873000071],
              [-5.180438995999964, 11.927009584000189],
              [-5.178995608999969, 11.929459571000109],
              [-5.178730010999857, 11.929909707000036],
              [-5.177746772999967, 11.930343628000173],
              [-5.174627303999955, 11.931718827000168],
              [-5.169164180999928, 11.934129715000154],
              [-5.163401602999898, 11.932391166000116],
              [-5.162443637999957, 11.932102203000113],
              [-5.161737917999972, 11.931889535000096],
              [-5.157968997999888, 11.933179855000105],
              [-5.157662391999963, 11.936281204000068],
              [-5.156668184999944, 11.946330071000091],
              [-5.156524180999895, 11.947791100000131],
              [-5.155508995999924, 11.948639870000022],
              [-5.154551982999976, 11.949440957000093],
              [-5.152468203999945, 11.949301721000154],
              [-5.149417399999948, 11.949098587000037],
              [-5.146986960999925, 11.947750090999989],
              [-5.145959853999955, 11.947179793999965],
              [-5.145771026999967, 11.947256088000074],
              [-5.1434850699999, 11.948181153000178],
              [-5.140799998999967, 11.949269295000136],
              [-5.140060901999846, 11.949568748000104],
              [-5.129117011999938, 11.95801067400015],
              [-5.128174304999902, 11.958738328000152],
              [-5.126732826999898, 11.959850312000128],
              [-5.114132881999922, 11.962711334000062],
              [-5.111536024999907, 11.963300705000051],
              [-5.103445053999963, 11.96638202600019],
              [-5.101560114999927, 11.967100143000039],
              [-5.101506232999952, 11.967121124000073],
              [-5.101390837999929, 11.967166901000041],
              [-5.093877792999933, 11.970155716000136],
              [-5.091177940999899, 11.971229553000114],
              [-5.088577746999874, 11.97367286799999],
              [-5.08807182299995, 11.974148750000154],
              [-5.087710856999934, 11.975732804000074],
              [-5.087289808999913, 11.97758197800016],
              [-5.085619925999936, 11.977813722000121],
              [-5.083068848999972, 11.978170396000166],
              [-5.08293437999987, 11.97859573400018],
              [-5.082562922999955, 11.979769706000184],
              [-5.079626559999952, 11.98105049100019],
              [-5.078549861999932, 11.98151969800017],
              [-5.075912952999886, 11.979290963000096],
              [-5.073511123999936, 11.977259636000156],
              [-5.061820982999905, 11.977449417000116],
              [-5.060884951999981, 11.977804184000092],
              [-5.049791811999967, 11.982011796000052],
              [-5.034790038999972, 11.983961105000105],
              [-5.021777628999871, 11.980891229000122],
              [-5.018364428999973, 11.982913971000187],
              [-5.017117022999969, 11.983653068000137],
              [-5.012060165999912, 11.986650466000128],
              [-5.006220817999918, 11.986281395999981],
              [-5.002813337999896, 11.985217094000063],
              [-4.999999045999914, 11.984338760000185],
              [-4.995957373999943, 11.983286858000042],
              [-4.994987963999904, 11.983035087000076],
              [-4.994894026999873, 11.98301124600016],
              [-4.991117, 11.983823776000065],
              [-4.990900039999815, 11.983870506000187],
              [-4.990547655999876, 11.983945847000086],
              [-4.988852023999925, 11.984310151000045],
              [-4.985818386999881, 11.98695755000017],
              [-4.980169771999897, 11.991889954000044],
              [-4.977084636999962, 11.989832878000016],
              [-4.97683715799991, 11.989667892000057],
              [-4.973810195999931, 11.989979744000095],
              [-4.968996047999951, 11.993780135000065],
              [-4.966528892999918, 11.994376183000156],
              [-4.959474086999933, 11.996081353000022],
              [-4.953433989999951, 12.000944138000136],
              [-4.953404425999906, 12.000967980000155],
              [-4.953289984999856, 12.00105953100018],
              [-4.953205108999953, 12.001058579000073],
              [-4.94108533799988, 12.000855447000163],
              [-4.924075125999934, 12.00057125100011],
              [-4.921642779999957, 12.000528336000173],
              [-4.920087813999942, 12.000500680000187],
              [-4.918599604999884, 12.001005174],
              [-4.906186102999868, 12.005211831000167],
              [-4.901943683999946, 12.003407479000089],
              [-4.900043009999877, 12.002599716000134],
              [-4.89282989499992, 12.003931046000162],
              [-4.887608051999962, 12.000840188000041],
              [-4.881601810999939, 12.001198768000052],
              [-4.881080150999935, 12.001230239000051],
              [-4.868120193999971, 12.006369590000133],
              [-4.862867832999939, 12.01132869700001],
              [-4.857707500999936, 12.013932229000147],
              [-4.856637000999911, 12.014472007000052],
              [-4.84746313099987, 12.01349544600015],
              [-4.846088886999951, 12.013349532000177],
              [-4.84081506699988, 12.017391204000091],
              [-4.838782309999886, 12.017129899000054],
              [-4.837860107999973, 12.017011644000149],
              [-4.837769984999909, 12.017000198000062],
              [-4.837576389999981, 12.016656874999967],
              [-4.837115287999893, 12.015842439000096],
              [-4.83507680799994, 12.012240410000061],
              [-4.83173799399998, 12.009559632000105],
              [-4.826078891999885, 12.007620811000038],
              [-4.814896106999925, 12.00817108200016],
              [-4.807405948999872, 12.008539199000154],
              [-4.807063102999905, 12.009639741000058],
              [-4.806970118999971, 12.0099296570001],
              [-4.804506777999961, 12.009634973000061],
              [-4.791716575999885, 12.008105279000119],
              [-4.787749767999969, 12.007630349000067],
              [-4.763433932999931, 12.007291793000149],
              [-4.760493277999899, 12.005525588000125],
              [-4.75452375399982, 12.001940727999965],
              [-4.752758980999886, 12.000881195000147],
              [-4.747824191999882, 11.999851227000022],
              [-4.746449947999963, 12.001031875000024],
              [-4.74571609599991, 11.999670983000044],
              [-4.734347819999869, 11.996789933000059],
              [-4.732497215999956, 11.997775077000028],
              [-4.731626510999945, 11.998238564000076],
              [-4.731379985999979, 11.998370170000157],
              [-4.731196402999956, 11.998599052999964],
              [-4.725093363999918, 12.006214142999966],
              [-4.72459983899995, 12.006830216000083],
              [-4.717539787999954, 12.030929566000111],
              [-4.717569351999941, 12.032020569000053],
              [-4.717625141999918, 12.034090042000116],
              [-4.717639922999979, 12.034609796000098],
              [-4.717666625999868, 12.035615921000158],
              [-4.717687129999888, 12.036399842000094],
              [-4.717719078999949, 12.037599564000061],
              [-4.71743965099995, 12.037934304000032],
              [-4.717250823999962, 12.038160324999978],
              [-4.714054106999868, 12.041986466000139],
              [-4.713240145999919, 12.042960167000103],
              [-4.711860178999871, 12.044610977000104],
              [-4.710289000999978, 12.046489715000121],
              [-4.707304000999955, 12.050060272000053],
              [-4.701482772999952, 12.05346012200016],
              [-4.700687885999969, 12.053924561000144],
              [-4.69740819999987, 12.055839538000043],
              [-4.68885278599987, 12.056755066000107],
              [-4.683187960999931, 12.057360649000032],
              [-4.669993876999911, 12.053561211000101],
              [-4.669288158999962, 12.053352357000051],
              [-4.658310889999939, 12.053871156000071],
              [-4.65105295199993, 12.060958863000053],
              [-4.650180816999978, 12.061810493000166],
              [-4.646699905999981, 12.065210342000171],
              [-4.645154475999959, 12.067502975000082],
              [-4.64443778999987, 12.068567277],
              [-4.643644809999898, 12.069746017000057],
              [-4.641974926999922, 12.072230339000043],
              [-4.64067506799995, 12.078871727000148],
              [-4.640442847999907, 12.080060958000047],
              [-4.637453079999887, 12.095349312000053],
              [-4.633012771999972, 12.104209900000114],
              [-4.628025055999899, 12.110485076999964],
              [-4.625549792999948, 12.113599777000104],
              [-4.614540098999896, 12.121470451000107],
              [-4.613251685999956, 12.12204170300015],
              [-4.611411571999952, 12.122858048000126],
              [-4.607369899999924, 12.124651910000011],
              [-4.600594997999906, 12.124820710000108],
              [-4.593223571999886, 12.128091812000093],
              [-4.590709209999943, 12.129207611000083],
              [-4.589038848999962, 12.129948616000036],
              [-4.585137365999913, 12.130048753000096],
              [-4.581573963999972, 12.130139352000185],
              [-4.581097126999907, 12.130151748000173],
              [-4.574930189999918, 12.132430077000095],
              [-4.569054126999959, 12.134599686000115],
              [-4.561563968999963, 12.149622918000034],
              [-4.561504840999874, 12.149741173000166],
              [-4.561573028999931, 12.152271272000178],
              [-4.564253806999943, 12.154604911000035],
              [-4.564305304999948, 12.154649736000124],
              [-4.566445827999928, 12.156514168],
              [-4.572104930999956, 12.161441804000049],
              [-4.575894832999893, 12.163877487999969],
              [-4.578292847999876, 12.165418625000029],
              [-4.582884787999944, 12.167124748000049],
              [-4.583489893999911, 12.16734886100005],
              [-4.583950042999902, 12.16787147500014],
              [-4.586864946999924, 12.171180725],
              [-4.590899943999943, 12.18210983300014],
              [-4.590898035999942, 12.182843209000055],
              [-4.590896605999944, 12.183340073000124],
              [-4.590882300999965, 12.188923837000118],
              [-4.590879917999871, 12.189940452000144],
              [-4.587009428999977, 12.195873260000042],
              [-4.586606979999942, 12.196490289000167],
              [-4.572215557999925, 12.208034515],
              [-4.547213076999981, 12.22809124000014],
              [-4.543195247999961, 12.230718613000022],
              [-4.542786121999882, 12.230986595000161],
              [-4.541749000999971, 12.231665612000143],
              [-4.541078090999974, 12.232105255000022],
              [-4.541007040999944, 12.232151985000144],
              [-4.538828848999913, 12.234230995000189],
              [-4.523664950999887, 12.248701096000104],
              [-4.516536235999979, 12.252971648000141],
              [-4.514451980999922, 12.254220962000147],
              [-4.514118194999924, 12.254624368000123],
              [-4.506671904999962, 12.263595581000118],
              [-4.50334215099997, 12.267608644000063],
              [-4.503229140999963, 12.267767906000131],
              [-4.497147082999959, 12.2763834000001],
              [-4.495900153999912, 12.278149605000124],
              [-4.495758532999901, 12.27844238400013],
              [-4.487912177999874, 12.294679642000119],
              [-4.48750829599993, 12.301520348],
              [-4.486619949999977, 12.316570283000146],
              [-4.484439849999887, 12.322830199000066],
              [-4.476830005999887, 12.326939584],
              [-4.466969966999955, 12.326029777000144],
              [-4.448122977999901, 12.320989608000104],
              [-4.4366068839999, 12.314134599000056],
              [-4.429994105999981, 12.310197830000163],
              [-4.429582118999974, 12.309952736000128],
              [-4.428728103999958, 12.309774400000151],
              [-4.427540300999965, 12.309525491000045],
              [-4.424201011999969, 12.308825493000029],
              [-4.423699855999928, 12.308720587999971],
              [-4.421090125999967, 12.314205170000093],
              [-4.42104005899995, 12.314310075000151],
              [-4.421255111999869, 12.31492710100008],
              [-4.423820018999891, 12.32229042099999],
              [-4.426556587999926, 12.325586319000024],
              [-4.426841258999957, 12.325929642000119],
              [-4.428098677999969, 12.327444077999985],
              [-4.429615973999944, 12.329271316000018],
              [-4.430552959999943, 12.329250335000154],
              [-4.437891960999934, 12.341488837999975],
              [-4.438966273999881, 12.345623017000037],
              [-4.439223288999926, 12.346611023000037],
              [-4.439858435999895, 12.349055291000127],
              [-4.44127607299987, 12.354510308000044],
              [-4.45098495499991, 12.376811028000077],
              [-4.451407431999883, 12.388463020000131],
              [-4.451835154999912, 12.400250435000146],
              [-4.452449320999961, 12.408433915000103],
              [-4.452919959999974, 12.414710045000163],
              [-4.450889109999878, 12.426719665000121],
              [-4.44828176599998, 12.432238579000057],
              [-4.444101809999893, 12.441085816000168],
              [-4.443302154999913, 12.442778587000134],
              [-4.438024044999906, 12.453950883000118],
              [-4.429433822999954, 12.483609200000103],
              [-4.425108909999949, 12.490139962000114],
              [-4.424479007999878, 12.491090774000099],
              [-4.423684119999962, 12.491882324000017],
              [-4.412383078999881, 12.503129960000024],
              [-4.407453536999924, 12.509172441000032],
              [-4.400129793999895, 12.51815032899998],
              [-4.393457888999933, 12.531889916000068],
              [-4.396749018999969, 12.541241646],
              [-4.4061908729999, 12.55319118599999],
              [-4.410409927999979, 12.562279702000069],
              [-4.413074015999939, 12.57464027400016],
              [-4.414555072999917, 12.576022148999982],
              [-4.415955065999924, 12.577328682000029],
              [-4.418360233999977, 12.585584641000025],
              [-4.419813156999965, 12.590571403000126],
              [-4.425112724999849, 12.597956657000111],
              [-4.428080081999951, 12.602091790000088],
              [-4.429687976999958, 12.603711129000033],
              [-4.433138845999906, 12.607187271000043],
              [-4.434094905999871, 12.608149529000059],
              [-4.437881945999891, 12.611051560000135],
              [-4.439412116999904, 12.61222267200003],
              [-4.444849967999971, 12.616389275000074],
              [-4.448462963999873, 12.61787414500003],
              [-4.46332216299993, 12.623978616000159],
              [-4.46522188199998, 12.626331329000038],
              [-4.466229915999975, 12.627579689000129],
              [-4.470793247999893, 12.630078316000038],
              [-4.472033977999899, 12.630758286000173],
              [-4.474468229999957, 12.632090568000081],
              [-4.475843906999899, 12.63284397100017],
              [-4.477878092999902, 12.633958816000131],
              [-4.487475871999948, 12.651660919999983],
              [-4.489897250999889, 12.660216331000072],
              [-4.491353033999928, 12.665361405000169],
              [-4.489559649999933, 12.674512864],
              [-4.48763990499998, 12.684309960000064],
              [-4.489498138999977, 12.710478784000088],
              [-4.487117767999962, 12.714008332000105],
              [-4.484762191999948, 12.717500686000051],
              [-4.481645106999963, 12.719920159000139],
              [-4.480960368999945, 12.720451356000126],
              [-4.480245590999971, 12.721006393000152],
              [-4.479249001999904, 12.721780776000173],
              [-4.476916789999962, 12.72204113100014],
              [-4.473879814999862, 12.722379684000146],
              [-4.470162868999978, 12.720833779000031],
              [-4.468664168999965, 12.720211030000144],
              [-4.458933829999921, 12.720662116000142],
              [-4.447128771999871, 12.72120857200008],
              [-4.439459799999952, 12.723699571000054],
              [-4.426559924999935, 12.72332954400008],
              [-4.414179802999968, 12.724789620000081],
              [-4.409239768999953, 12.725672721000024],
              [-4.400362014999928, 12.727258683000173],
              [-4.393186091999951, 12.728541375000077],
              [-4.387384891999886, 12.729687692000141],
              [-4.383323191999921, 12.730490684000131],
              [-4.380817889999946, 12.730986595000047],
              [-4.379889965999894, 12.731169701000113],
              [-4.369589805999965, 12.731419563000031],
              [-4.369023798999933, 12.731213571000183],
              [-4.368457794999927, 12.731007576000138],
              [-4.364815711999938, 12.729684829000178],
              [-4.357758998999941, 12.727121354000076],
              [-4.352508067999963, 12.723340034000046],
              [-4.334216117999858, 12.704701423000188],
              [-4.327044009999895, 12.702033996000011],
              [-4.32262182199986, 12.700388909000083],
              [-4.318204879999883, 12.699332237000021],
              [-4.311305999999888, 12.69768142700002],
              [-4.294475077999948, 12.699239730000102],
              [-4.287691115999905, 12.702478409000094],
              [-4.276189803999955, 12.707969666000167],
              [-4.275306701999966, 12.70860862699999],
              [-4.271296501999927, 12.711511612000095],
              [-4.267288206999922, 12.714412689000085],
              [-4.264679909999927, 12.716301919000102],
              [-4.258026122999979, 12.720197676999987],
              [-4.257106779999958, 12.720735550000029],
              [-4.249429702999976, 12.725230218000092],
              [-4.248288153999908, 12.725898742000027],
              [-4.24780130399995, 12.726668358000097],
              [-4.247777937999899, 12.726705552000169],
              [-4.246206283999868, 12.729189873000109],
              [-4.246165751999911, 12.729253769000024],
              [-4.245996951999871, 12.729520799000113],
              [-4.241209029999936, 12.737093926000114],
              [-4.239275931999941, 12.740151405000063],
              [-4.233339785999874, 12.755470275000107],
              [-4.228289126999869, 12.778129577000186],
              [-4.227965353999821, 12.785386087000177],
              [-4.227241992999893, 12.801609994000046],
              [-4.228181838999944, 12.80707931600017],
              [-4.231100080999909, 12.824060440999972],
              [-4.236279010999965, 12.843480111000076],
              [-4.238499164999894, 12.856990815000074],
              [-4.236436843999968, 12.868081094000104],
              [-4.236595630999887, 12.874377252000045],
              [-4.236691950999955, 12.878190995000068],
              [-4.233638762999931, 12.896142007000094],
              [-4.232409, 12.903370858000017],
              [-4.233329772999923, 12.911849975000052],
              [-4.232976913999949, 12.914728165000156],
              [-4.232434748999935, 12.919153215000165],
              [-4.230515003999926, 12.93482112900017],
              [-4.230224132999922, 12.937218666000035],
              [-4.230970382999942, 12.94268036],
              [-4.230975150999882, 12.94271659900005],
              [-4.231389521999972, 12.945740700999977],
              [-4.231948852999892, 12.949831010000139],
              [-4.234537601999932, 12.954804421000119],
              [-4.237621783999941, 12.960729598],
              [-4.23855686099995, 12.961647988000152],
              [-4.241723060999846, 12.96475505900014],
              [-4.256149769999979, 12.978910447000032],
              [-4.273543834999884, 12.999735833000045],
              [-4.274338244999967, 13.000686646000133],
              [-4.281499862999851, 13.009261132000063],
              [-4.281557082999939, 13.009329796000088],
              [-4.288339137999969, 13.02888965700015],
              [-4.289579391999894, 13.032467842000131],
              [-4.291437148999876, 13.037831306000044],
              [-4.296955584999864, 13.05055618300014],
              [-4.300912856999901, 13.059680940000078],
              [-4.305850028999942, 13.066911699000173],
              [-4.313107013999968, 13.077540397000121],
              [-4.323289870999872, 13.090169906000142],
              [-4.333408832999851, 13.100500108000062],
              [-4.351186275999851, 13.114647866000041],
              [-4.353299139999876, 13.116329194000059],
              [-4.353531837999924, 13.116729735999968],
              [-4.356985092999935, 13.122678757000074],
              [-4.356496809999953, 13.12614822400019],
              [-4.356197832999897, 13.1282815940001],
              [-4.35605955199992, 13.129271508000159],
              [-4.355400085999975, 13.133990287000017],
              [-4.351119994999976, 13.140989305000062],
              [-4.345856188999903, 13.146181106000029],
              [-4.343831538999893, 13.147134781000148],
              [-4.342009067999925, 13.147994041000175],
              [-4.336578845999952, 13.150552750000031],
              [-4.33215284399995, 13.153593063000187],
              [-4.323439597999936, 13.159578324000051],
              [-4.320001124999919, 13.161940574000084],
              [-4.303599833999897, 13.173210145000041],
              [-4.303143501999898, 13.173420907000093],
              [-4.295575619999966, 13.176925659000176],
              [-4.28736209799996, 13.180730820000122],
              [-4.279737472999955, 13.181076051000048],
              [-4.276340007999977, 13.18122959100009],
              [-4.272521019999886, 13.180628777000152],
              [-4.262434959999894, 13.179041863000123],
              [-4.253738403999932, 13.179444314000079],
              [-4.252119063999885, 13.179519653000057],
              [-4.249449730999913, 13.180969238000159],
              [-4.249172687999931, 13.181119918000036],
              [-4.246327876999885, 13.182649612000091],
              [-4.242722987999969, 13.187437057000182],
              [-4.241559981999956, 13.188981056000102],
              [-4.241821288999972, 13.190035821000095],
              [-4.243651866999926, 13.1974296570001],
              [-4.246273516999963, 13.201010705000101],
              [-4.247788905999926, 13.203080177000061],
              [-4.251079081999933, 13.205615997999985],
              [-4.254733085999931, 13.208431245000156],
              [-4.257940291999887, 13.214709282000115],
              [-4.260417937999819, 13.219559669000034],
              [-4.260787009999888, 13.224841119000075],
              [-4.258329866999873, 13.229729653000049],
              [-4.254056929999933, 13.233139038000161],
              [-4.252327918999868, 13.234519005000038],
              [-4.251513957999919, 13.235168458000032],
              [-4.250174999999899, 13.236236573000042],
              [-4.246065139999928, 13.239515304000179],
              [-4.243204115999902, 13.241798401000153],
              [-4.2388148299999, 13.245301248000146],
              [-4.237325190999854, 13.246489526000062],
              [-4.22266101799994, 13.258189202000153],
              [-4.222083568999949, 13.258502007],
              [-4.220224856999835, 13.259509086000151],
              [-4.216919898999947, 13.261297225000021],
              [-4.213319777999971, 13.263245584],
              [-4.206214904999968, 13.267091752000113],
              [-4.205425737999974, 13.267396926000117],
              [-4.203376769999977, 13.26819038300016],
              [-4.198390483999958, 13.270118712999988],
              [-4.196691035999947, 13.270775794000087],
              [-4.195525646999897, 13.271226883000054],
              [-4.192265033999945, 13.272488594000095],
              [-4.190937518999874, 13.272897721000106],
              [-4.178516864999892, 13.276729583000076],
              [-4.163715838999963, 13.285821914000167],
              [-4.160728930999937, 13.287563324000189],
              [-4.156311033999884, 13.290140152000049],
              [-4.153138160999902, 13.292997360000186],
              [-4.147126198999956, 13.298411369000064],
              [-4.145175456999937, 13.302686692000066],
              [-4.144899844999941, 13.303291321000074],
              [-4.139568328999872, 13.307744979000176],
              [-4.137539863999905, 13.30943965900002],
              [-4.136400698999978, 13.310095787000137],
              [-4.133371828999941, 13.311840058000143],
              [-4.1226921089999, 13.326121330000149],
              [-4.115362167999876, 13.342849732000104],
              [-4.11273050199992, 13.352416040000094],
              [-4.110839842999894, 13.359290124000097],
              [-4.108606815999963, 13.373358726000049],
              [-4.103104113999962, 13.38482856800016],
              [-4.102580546999889, 13.385919572000034],
              [-4.092476844999965, 13.395360947000029],
              [-4.088462829999912, 13.39638710000014],
              [-4.084540843999946, 13.397390365000092],
              [-4.077091216999975, 13.400382042000103],
              [-4.068724154999927, 13.403741837000155],
              [-4.060806750999859, 13.409714700000109],
              [-4.05834722599991, 13.411570549000089],
              [-4.05333995899997, 13.418129922000048],
              [-4.053295134999871, 13.41815185500019],
              [-4.050467966999918, 13.419562341000187],
              [-4.050342560999923, 13.419624329000158],
              [-4.036398887999951, 13.426581383000041],
              [-4.036236762999977, 13.426623345000166],
              [-4.036070824999911, 13.426666259000172],
              [-4.021489143999929, 13.430494309000153],
              [-4.020058154999902, 13.43087005600006],
              [-4.016526221999868, 13.431521416000123],
              [-4.005793093999898, 13.433500290000097],
              [-3.999384880999855, 13.439052582000102],
              [-3.998430013999894, 13.439880371000129],
              [-3.984590052999977, 13.45975017700016],
              [-3.984467268999936, 13.461043358000154],
              [-3.984205721999899, 13.463801384000078],
              [-3.983278990999963, 13.473571777000132],
              [-3.98470449499996, 13.48129749400016],
              [-3.986531972999899, 13.491201401000069],
              [-3.984632968999904, 13.495609285000114],
              [-3.984533071999977, 13.495841981000183],
              [-3.981441974999939, 13.497159958000054],
              [-3.97197198899994, 13.501199722000024],
              [-3.966474055999868, 13.500148772999978],
              [-3.963649033999957, 13.499608994000027],
              [-3.962290047999886, 13.499349595000126],
              [-3.960095404999947, 13.49775028300013],
              [-3.958637713999963, 13.496686935000071],
              [-3.958141565999938, 13.496325493000029],
              [-3.956789016999949, 13.495340347000081],
              [-3.951818941999932, 13.47842693400014],
              [-3.951644897999927, 13.477835655000149],
              [-3.949609995999936, 13.470910072000095],
              [-3.950206993999871, 13.466531754000016],
              [-3.946659802999932, 13.459979057999988],
              [-3.946270941999899, 13.459260941000025],
              [-3.940190076999897, 13.45506668000013],
              [-3.939817904999927, 13.454810143000145],
              [-3.933136938999951, 13.452854156000058],
              [-3.921442985999875, 13.449431419000121],
              [-3.920175075999964, 13.449060441000142],
              [-3.915837049999936, 13.444561004999969],
              [-3.915721892999898, 13.439832688000024],
              [-3.915695905999883, 13.43876647899998],
              [-3.915641068999889, 13.436519623000095],
              [-3.919750928999974, 13.431599618000064],
              [-3.9251189229999, 13.430174828000077],
              [-3.930838108999978, 13.428656578000073],
              [-3.93149495199998, 13.42848205700011],
              [-3.936520814999881, 13.427147866000098],
              [-3.947848557999976, 13.424140931000125],
              [-3.948230028999888, 13.424039841000081],
              [-3.948225497999943, 13.423866273000101],
              [-3.948205947999952, 13.423118591000048],
              [-3.957331179999926, 13.421660424000095],
              [-3.958272933999979, 13.421509743000172],
              [-3.964040516999944, 13.417778970000143],
              [-3.96492910399985, 13.417203903000086],
              [-3.970067023999945, 13.413879395000151],
              [-3.972389935999956, 13.410805703000051],
              [-3.9726285939999, 13.410490036000112],
              [-3.974843977999967, 13.407559396000124],
              [-3.974749088999943, 13.406536103000064],
              [-3.974536418999946, 13.404238701000054],
              [-3.974205970999947, 13.400671959000022],
              [-3.973971366999876, 13.39666462100007],
              [-3.973803043999908, 13.393791199000191],
              [-3.972781896999891, 13.392690659000095],
              [-3.967640400999926, 13.387148857],
              [-3.96753001299993, 13.387029649000112],
              [-3.965601444999948, 13.38581562000013],
              [-3.960848092999868, 13.382822037000153],
              [-3.959265947999882, 13.38214397500019],
              [-3.950396537999836, 13.378345490000015],
              [-3.946603059999916, 13.376720429000102],
              [-3.93545508499983, 13.37812137700007],
              [-3.930912016999969, 13.378691674000095],
              [-3.912365912999974, 13.379121781000038],
              [-3.908441066999956, 13.378161430999967],
              [-3.906061887999897, 13.377578735999975],
              [-3.895339012999898, 13.374953271000095],
              [-3.891738891999921, 13.374071122000032],
              [-3.883156061999955, 13.371970176000104],
              [-3.877156017999937, 13.3705005650001],
              [-3.868124961999911, 13.36753654600011],
              [-3.863697051999907, 13.366083145000175],
              [-3.857635020999908, 13.364093780000132],
              [-3.857299089999913, 13.363983154000039],
              [-3.852294920999952, 13.362340927000162],
              [-3.850813625999933, 13.362279892000174],
              [-3.836713075999967, 13.361702920000027],
              [-3.834189891999927, 13.361599923000142],
              [-3.83315801599997, 13.361670494],
              [-3.825242995999929, 13.36221027400012],
              [-3.82033968099995, 13.362544060000175],
              [-3.819339036999963, 13.362611770000115],
              [-3.818755863999911, 13.362651825000114],
              [-3.813786983999933, 13.362991332999968],
              [-3.797707079999952, 13.358530044000076],
              [-3.786205530999894, 13.351266861000113],
              [-3.776962995999895, 13.345430375000035],
              [-3.771168468999974, 13.33980369600016],
              [-3.764698980999924, 13.333521842000152],
              [-3.735137938999969, 13.295100212000079],
              [-3.722645997999962, 13.283200264000129],
              [-3.708817719999956, 13.278569222000101],
              [-3.706551073999833, 13.277810096],
              [-3.686424017999912, 13.27066993700015],
              [-3.671438932999934, 13.262498856000036],
              [-3.660680056999979, 13.25422954600009],
              [-3.649823187999971, 13.243788720000055],
              [-3.626097918999903, 13.220971108000015],
              [-3.60698699999989, 13.206681251000134],
              [-3.599839925999959, 13.202240945000085],
              [-3.590393065999933, 13.199690818000022],
              [-3.586119174999965, 13.197965622000083],
              [-3.582808017999923, 13.196629525000105],
              [-3.579210996999962, 13.193261147000158],
              [-3.550776958999904, 13.181698800000106],
              [-3.54795312899995, 13.180219651000016],
              [-3.532244920999915, 13.171990395000023],
              [-3.504686831999948, 13.166447640000172],
              [-3.499233960999902, 13.165350913999987],
              [-3.498663424999961, 13.165228843000136],
              [-3.485552072999951, 13.162428857000066],
              [-3.481732606999913, 13.161075591000042],
              [-3.47821807899993, 13.159831048000171],
              [-3.468832968999834, 13.16003132000003],
              [-3.468606948999877, 13.159919739000145],
              [-3.464798927999937, 13.158041954000112],
              [-3.448837994999963, 13.157930374000102],
              [-3.438778875999958, 13.161106110000162],
              [-3.434432029999925, 13.162478447000126],
              [-3.433223962999875, 13.162859917000048],
              [-3.430000066999924, 13.166807175000145],
              [-3.428639888999953, 13.168473243000165],
              [-3.427527903999931, 13.169835092000142],
              [-3.42271399599997, 13.175729753000155],
              [-3.422327994999932, 13.17793369400016],
              [-3.422281027999873, 13.178195954000046],
              [-3.419594049999944, 13.19350147300014],
              [-3.419884680999928, 13.198046684000076],
              [-3.420314072999929, 13.204758643000048],
              [-3.423463343999913, 13.221701622000069],
              [-3.425122975999898, 13.230629921000116],
              [-3.434221982999929, 13.249058724000179],
              [-3.434806107999975, 13.25626754700005],
              [-3.434874057999878, 13.257102013000178],
              [-3.432343959999969, 13.261800766000022],
              [-3.431493996999905, 13.263381005000099],
              [-3.424770116999923, 13.26720142400012],
              [-3.411710977999974, 13.269908906000126],
              [-3.404778957999895, 13.271342278000077],
              [-3.403733968999973, 13.271558761000165],
              [-3.397931813999946, 13.272011757000143],
              [-3.387562990999925, 13.272821427000167],
              [-3.370234966999874, 13.275030136000055],
              [-3.368762014999959, 13.274999619000084],
              [-3.353790043999936, 13.274689675000047],
              [-3.341397047999919, 13.277018547000182],
              [-3.321776390999901, 13.27819442700013],
              [-3.305988073999913, 13.279141426000024],
              [-3.305362938999963, 13.27919960100013],
              [-3.302569865999885, 13.279457093000133],
              [-3.300708530999884, 13.279629707000026],
              [-3.274565695999968, 13.282014846000095],
              [-3.273415089999901, 13.282119750999982],
              [-3.269648790999952, 13.28249836100008],
              [-3.256079911999905, 13.283860207000089],
              [-3.243998050999892, 13.289857864000112],
              [-3.242848157999958, 13.292769432000171],
              [-3.237770079999905, 13.305629730000021],
              [-3.238117932999899, 13.311031341000103],
              [-3.238191603999951, 13.312172889000067],
              [-3.238480089999939, 13.316650391000167],
              [-3.241909980999878, 13.324626923000039],
              [-3.243669985999929, 13.328720092000026],
              [-3.244257210999933, 13.337321282000119],
              [-3.244264125999962, 13.337424278000128],
              [-3.244360922999874, 13.338830948000123],
              [-3.259598969999843, 13.358969689000105],
              [-3.260047435999979, 13.362092973000074],
              [-3.260982036999963, 13.368599891000031],
              [-3.260996102999968, 13.370262146000186],
              [-3.261184691999972, 13.394045830000039],
              [-3.261249064999959, 13.402159691000179],
              [-3.261497973999951, 13.405420304000017],
              [-3.262895106999963, 13.423729897000044],
              [-3.266177892999906, 13.449217796000084],
              [-3.266196965999882, 13.449365615000147],
              [-3.268341064999902, 13.466007233000084],
              [-3.269689082999889, 13.476470948000099],
              [-3.273593902999949, 13.496012688000178],
              [-3.276551008999945, 13.510810852000134],
              [-3.282001018999949, 13.534139633000109],
              [-3.278964996999832, 13.545700074000138],
              [-3.271279571999969, 13.558551789000092],
              [-3.27122545199984, 13.55864238800018],
              [-3.271028994999938, 13.558971406000069],
              [-3.269010305999871, 13.559916496000085],
              [-3.268469094999944, 13.560170175000053],
              [-3.266998290999936, 13.563074111000162],
              [-3.260584115999905, 13.575738907000186],
              [-3.259036063999872, 13.590941429],
              [-3.257804392999958, 13.601254463000146],
              [-3.257246971999905, 13.605921746000035],
              [-3.256497859999854, 13.624823570000046],
              [-3.256489991999899, 13.625020981000148],
              [-3.258270024999945, 13.631190300000185],
              [-3.255120039999895, 13.648490906000063],
              [-3.256294011999955, 13.669851302000154],
              [-3.261893986999951, 13.678462028000069],
              [-3.26774215699993, 13.68400859799999],
              [-3.274719237999875, 13.690625191000095],
              [-3.28026437799997, 13.695882797000081],
              [-3.280630112999916, 13.696229936000123],
              [-3.283787012999937, 13.701000214000089],
              [-3.284315585999877, 13.703633308000178],
              [-3.284842967999964, 13.706259728000077],
              [-3.279644011999949, 13.715789795000148],
              [-3.272641895999868, 13.71823978499998],
              [-3.272350549999942, 13.718246460000103],
              [-3.272063016999937, 13.718252183000118],
              [-3.271749496999917, 13.71825885800007],
              [-3.270556925999927, 13.718283654000174],
              [-3.261593102999882, 13.718469620000064],
              [-3.257843969999954, 13.717461586000184],
              [-3.254357576999951, 13.716524124000046],
              [-3.239356040999951, 13.712491990000046],
              [-3.228925942999979, 13.708799362000036],
              [-3.209845065999957, 13.696551323000108],
              [-3.185616015999869, 13.685331345000122],
              [-3.180054902999927, 13.685520172],
              [-3.179496048999965, 13.685539245000086],
              [-3.170714616999817, 13.685838699000158],
              [-3.168930053999929, 13.685899734000145],
              [-3.159557341999914, 13.688392639000085],
              [-3.157511948999968, 13.688936234000039],
              [-3.155696153999941, 13.689418794000176],
              [-3.146482943999956, 13.691869736000115],
              [-3.135020016999874, 13.695363045000022],
              [-3.134511947999954, 13.695516587000157],
              [-3.134335039999883, 13.695570945000043],
              [-3.12974, 13.695837975000074],
              [-3.124657630999934, 13.696132661000149],
              [-3.122119902999941, 13.696279526000126],
              [-3.107290029999945, 13.695429803000081],
              [-3.105738877999841, 13.69479465600017],
              [-3.103015899999946, 13.693679809000059],
              [-3.09194993899996, 13.684414864000018],
              [-3.082904099999894, 13.676840782000056],
              [-3.068739890999893, 13.663109779000024],
              [-3.059927940999899, 13.63570117900008],
              [-3.051450013999897, 13.62391090400007],
              [-3.046158075999927, 13.623604775000047],
              [-3.045567989999938, 13.623571396000045],
              [-3.039088011999979, 13.628531456000076],
              [-3.023638009999843, 13.654121400000179],
              [-3.022768974999963, 13.65759182000005],
              [-3.021962403999908, 13.658413886000062],
              [-3.019966840999928, 13.660449029000119],
              [-3.017250060999857, 13.66322040600005],
              [-3.013700961999916, 13.664658546000169],
              [-3.013604402999931, 13.664697648000015],
              [-3.013570070999947, 13.664711952000175],
              [-3.008050917999981, 13.66695117900008],
              [-3.006520985999941, 13.667572023000105],
              [-3.001329897999938, 13.666751862000183],
              [-2.997220992999871, 13.664689065000118],
              [-2.995151042999964, 13.663649559000078],
              [-2.982642649999889, 13.652067184000032],
              [-2.979278086999955, 13.648951530000147],
              [-2.978982924999968, 13.648677825000163],
              [-2.960093020999921, 13.631185533000064],
              [-2.95942807199998, 13.630569459000071],
              [-2.953710078999905, 13.626779556000088],
              [-2.953393935999884, 13.626837731000023],
              [-2.950138090999872, 13.627445220000084],
              [-2.947709082999893, 13.627898216000119],
              [-2.945560693999937, 13.628297805000045],
              [-2.944103002999952, 13.628568650000147],
              [-2.943856001999961, 13.628787040000077],
              [-2.941508769999871, 13.630862235000109],
              [-2.937180041999909, 13.634690285000147],
              [-2.929440975999967, 13.635760307],
              [-2.92865705499986, 13.636271476000104],
              [-2.87196993799995, 13.65532970400011],
              [-2.903039931999842, 13.724439620000055],
              [-2.901738880999915, 13.742897988000152],
              [-2.898446558999922, 13.789600373000155],
              [-2.897709846999874, 13.80004978300019],
              [-2.896686553999871, 13.814564706000056],
              [-2.896677732999933, 13.814690589000065],
              [-2.895910024999921, 13.825579644000186],
              [-2.892778395999869, 13.834078790000149],
              [-2.88079285699996, 13.866606712000134],
              [-2.854647636999914, 13.937562942000056],
              [-2.832429885999886, 13.997859955000081],
              [-2.848078488999875, 14.00133323700004],
              [-2.847950457999957, 14.001436235000028],
              [-2.8513500699999, 14.002220154000156],
              [-2.844770193999977, 14.029058457000019],
              [-2.837029933999929, 14.060629844000061],
              [-2.817423104999875, 14.070602416000042],
              [-2.817469596999899, 14.069372178000037],
              [-2.804038284999876, 14.075374604000046],
              [-2.672329902999877, 14.134240151000085],
              [-2.661683321999874, 14.143661499000132],
              [-2.572330951999959, 14.222729682000022],
              [-2.57232999799993, 14.224466324000048],
              [-2.576282024999898, 14.229168891000029],
              [-2.476469992999967, 14.297860145000072],
              [-2.398800610999899, 14.274559976000035],
              [-2.398224115999824, 14.276098251],
              [-2.393911361999926, 14.277436256000158],
              [-2.291980027999898, 14.252220154000099],
              [-2.25943994599993, 14.23439979599999],
              [-2.239140033999945, 14.223279953000031],
              [-2.132249592999926, 14.164752008000164],
              [-2.10581993999989, 14.150279999000134],
              [-2.103057144999923, 14.151401520000093],
              [-2.101938009999969, 14.151855469000054],
              [-2.089202403999934, 14.157024383000191],
              [-1.993669986999976, 14.195759773000077],
              [-1.987390756999957, 14.293556213000159],
              [-1.986235618999956, 14.311547279000138],
              [-1.985269070999891, 14.326600074000112],
              [-1.979154108999921, 14.421899795000115],
              [-1.975370048999935, 14.480879784000024],
              [-1.79890525299993, 14.484927178000078],
              [-1.763905882999893, 14.485730171000171],
              [-1.746019959999956, 14.485759735000102],
              [-1.674909949999972, 14.506890298000087],
              [-1.560505866999961, 14.578938484000105],
              [-1.409432649999872, 14.674080849],
              [-1.314939974999902, 14.733590126000081],
              [-1.246889947999932, 14.749420167000039],
              [-1.155337213999928, 14.772399902000075],
              [-1.15557944699998, 14.77163887100005],
              [-1.112591147999922, 14.783370971000181],
              [-1.087859988999924, 14.790120125000158],
              [-1.047916769999915, 14.822986602000185],
              [-1.047830104999946, 14.822898865000127],
              [-1.047557949999884, 14.822624207000104],
              [-1.046555517999934, 14.823356629000159],
              [-1.031826017999947, 14.834111214000131],
              [-1.018440960999953, 14.839330673000063],
              [-1.012073992999888, 14.84265995100003],
              [-1.001791000999958, 14.848038674000179],
              [-0.983559011999944, 14.860563278000143],
              [-0.982370972999945, 14.861378671000182],
              [-0.951748012999872, 14.884949683],
              [-0.95020401599993, 14.886704445000078],
              [-0.946632027999897, 14.890761376000114],
              [-0.935957014999929, 14.900715827000113],
              [-0.93216800599987, 14.904249192000179],
              [-0.931765019999943, 14.904697419000058],
              [-0.92569112699988, 14.91144180200007],
              [-0.93365997199993, 14.914329530000089],
              [-0.884148776999837, 14.952738761999967],
              [-0.883675276999895, 14.953226090000157],
              [-0.877850711999827, 14.95919704500011],
              [-0.870780468999953, 14.966444017000185],
              [-0.856779991999929, 14.980797767000183],
              [-0.856175005999944, 14.981418609],
              [-0.851073025999881, 14.985688209000159],
              [-0.84905385899998, 14.987377168000023],
              [-0.843086003999929, 14.992369652000093],
              [-0.841354964999937, 14.995137214000067],
              [-0.839613019999888, 14.997921944000097],
              [-0.837463020999905, 15.000000000000114],
              [-0.83429098199997, 15.003067971000178],
              [-0.828555403999871, 15.008612633000098],
              [-0.827973306999979, 15.009175302000074],
              [-0.8237500199999, 15.013259888000164],
              [-0.819030582999972, 15.016466141000137],
              [-0.81365126299994, 15.020120620000057],
              [-0.812740027999951, 15.020739556000137],
              [-0.805491089999975, 15.026287079000042],
              [-0.801201402999936, 15.029570580000041],
              [-0.796589016999974, 15.033100128],
              [-0.794729470999869, 15.034384728000077],
              [-0.787455022999893, 15.03940868400008],
              [-0.774576543999956, 15.047079086000053],
              [-0.763060987999893, 15.053937912000038],
              [-0.762736558999961, 15.054167748000054],
              [-0.762579977999962, 15.054278373000045],
              [-0.749731002999908, 15.06331825400008],
              [-0.746056020999958, 15.065903665],
              [-0.742715000999908, 15.068254470000113],
              [-0.739169001999869, 15.070749282000122],
              [-0.720198928999878, 15.079908371000045],
              [-0.719368637999935, 15.08030891500016],
              [-0.719201027999929, 15.080389976000049],
              [-0.715420006999977, 15.080439569000134],
              [-0.710844754999869, 15.080794335000178],
              [-0.701488018999839, 15.081521034000161],
              [-0.697728991999952, 15.081453324000051],
              [-0.678321002999951, 15.081101417000184],
              [-0.637435018999895, 15.081342698000185],
              [-0.60907501, 15.081009865000055],
              [-0.576599657999964, 15.080629350000095],
              [-0.567228973999931, 15.080519676999984],
              [-0.549721537999972, 15.080279351],
              [-0.530115007999939, 15.080010414000071],
              [-0.507739007999874, 15.08181858099999],
              [-0.506973028999937, 15.081880569000191],
              [-0.500465988999963, 15.081998824000095],
              [-0.499058007999963, 15.08202457499999],
              [-0.476725994999867, 15.082431794000058],
              [-0.462076008999929, 15.082592010000155],
              [-0.458662002999972, 15.081646920000082],
              [-0.455916106999894, 15.080887795000081],
              [-0.455673993999937, 15.080821037000078],
              [-0.451279729999953, 15.076723099000105],
              [-0.442056000999912, 15.068121911000048],
              [-0.419981002999975, 15.039201736000052],
              [-0.419807850999916, 15.038856507000162],
              [-0.41562700299994, 15.030520440000089],
              [-0.403618991999906, 15.013428688999966],
              [-0.397659986999884, 15.008899689000089],
              [-0.394276291999972, 15.00841426900007],
              [-0.388666987999954, 15.007610321000129],
              [-0.384025425999937, 15.009050369000079],
              [-0.376424014999941, 15.011409759000117],
              [-0.372619985999961, 15.013640404],
              [-0.3508488249999, 15.026406289000136],
              [-0.331588118999946, 15.037700653000115],
              [-0.324810086999889, 15.04167556699997],
              [-0.317027002999964, 15.046239854000078],
              [-0.312507033999964, 15.048322679000023],
              [-0.30254501099995, 15.052912712000079],
              [-0.301744013999837, 15.053281784000092],
              [-0.298287002999871, 15.055254936000097],
              [-0.269804, 15.071511268000108],
              [-0.253791003999936, 15.076951028000053],
              [-0.248295992999942, 15.075986863000139],
              [-0.247631996999871, 15.075871467000184],
              [-0.246464267999897, 15.075545312000088],
              [-0.245261995999897, 15.075209618000031],
              [-0.231750993999867, 15.071439744000031],
              [-0.220266237999965, 15.06705760900013],
              [-0.179545, 15.051520348],
              [-0.167949004999912, 15.049801826000078],
              [-0.163657991999969, 15.046171188000073],
              [-0.158455236999941, 15.044061661000057],
              [-0.148706003999905, 15.040108681000049],
              [-0.148699997999927, 15.039607048000164],
              [-0.148695005999969, 15.039190293000047],
              [-0.143044650999911, 15.037975311000082],
              [-0.135433003999935, 15.03633880700005],
              [-0.132372780999958, 15.034255982000104],
              [-0.132100998999931, 15.03407096900014],
              [-0.109807997999951, 15.026251792000039],
              [-0.098650000999896, 15.021080017000145],
              [-0.098115265999979, 15.02096176200007],
              [-0.093679144999953, 15.019979478000153],
              [-0.077912143999924, 15.01376915000003],
              [-0.042954998999903, 15.000000000000114],
              [-0.036742000999936, 14.99755001200009],
              [-0.027673438999841, 14.993475914000101],
              [-0.009209998999836, 14.98518180800005],
              [0.0, 14.984150887000112],
              [0.0020164660001, 14.983923910999977],
              [0.002143000000103, 14.983909608000147],
              [0.013249999000095, 14.983090402000073],
              [0.013637084000038, 14.983040810000034],
              [0.028394001000095, 14.981161117],
              [0.035539932000063, 14.980728149000072],
              [0.038322002000029, 14.980559349000146],
              [0.045255419000057, 14.979829789000178],
              [0.050383003000093, 14.979290009000124],
              [0.059836998000094, 14.978093148000085],
              [0.062212002000138, 14.97779274100003],
              [0.070229560000087, 14.977862359000085],
              [0.071326002000035, 14.977871895000021],
              [0.07939299899999, 14.97794437400006],
              [0.085480005000079, 14.977998733000049],
              [0.094575004000035, 14.978079797000134],
              [0.102679529000113, 14.978778840000189],
              [0.105925999000135, 14.979059220000124],
              [0.121250005000093, 14.980381965000049],
              [0.132315561000098, 14.980229378000104],
              [0.133415998000146, 14.980214120000085],
              [0.141809999000031, 14.980099679000148],
              [0.144693002000054, 14.980776786000092],
              [0.145176143000185, 14.980890274000103],
              [0.148880004000148, 14.98176098],
              [0.150151998000013, 14.981677055999967],
              [0.162365004000151, 14.980874061000065],
              [0.163529574000052, 14.980796813999973],
              [0.164240003000145, 14.980750085000125],
              [0.182879998999965, 14.983888627000113],
              [0.19224000100013, 14.98652076799999],
              [0.196337999000093, 14.987673759000131],
              [0.200554997000154, 14.988860130000148],
              [0.211664006000092, 14.988499641000033],
              [0.219667628000138, 14.989029885000036],
              [0.229350001000171, 14.989670753000155],
              [0.229347408000081, 14.98958396900008],
              [0.229002995999963, 14.978083610000056],
              [0.233182371, 14.915505410000037],
              [0.233837501000039, 14.905703546000098],
              [0.234396502000038, 14.897340774000043],
              [0.234459997000045, 14.89639091500004],
              [0.234471485000086, 14.896298408000121],
              [0.234945505000042, 14.892478943000015],
              [0.235220701000117, 14.890259742000183],
              [0.235711277000178, 14.886302949000083],
              [0.235739888000182, 14.886071206000167],
              [0.22809399700003, 14.869251252000083],
              [0.204009994000103, 14.842869759000166],
              [0.202004999, 14.839417457000138],
              [0.203644633000124, 14.83508872900012],
              [0.204038307000019, 14.834050178000041],
              [0.20950152000006, 14.819640160000063],
              [0.219620004999967, 14.792951585000083],
              [0.221249610000086, 14.788651466000033],
              [0.221963241000083, 14.786768913000117],
              [0.22487247, 14.779095650000045],
              [0.236101002, 14.749480248000111],
              [0.235316619000059, 14.744998933000147],
              [0.234491975000026, 14.740282059000151],
              [0.233023212000091, 14.73187732800011],
              [0.231241002000047, 14.721678735000125],
              [0.212449998000125, 14.668258667000032],
              [0.177590996000163, 14.582681657000137],
              [0.166250006000098, 14.533438682000053],
              [0.167999998000084, 14.523349762],
              [0.173283324000067, 14.504717826000103],
              [0.176770002000069, 14.492421149999984],
              [0.192010998000114, 14.460640908000016],
              [0.209736004000092, 14.443966866000096],
              [0.212288007000154, 14.446822168000097],
              [0.214640007000071, 14.44944953900017],
              [0.224178000000109, 14.439068795000139],
              [0.231129870000132, 14.431103706000044],
              [0.248162002000186, 14.411588669000139],
              [0.266562997000051, 14.390504837000037],
              [0.275406987000167, 14.378391266000051],
              [0.296499998, 14.366869926000163],
              [0.296665162000181, 14.366839410000125],
              [0.300810010000134, 14.366079332000083],
              [0.322930991000135, 14.36201095600012],
              [0.328774988000077, 14.355005265000159],
              [0.338788986000168, 14.34300136600001],
              [0.347433418000037, 14.330261231000065],
              [0.353500992000079, 14.321318627000039],
              [0.358679861000041, 14.317204476000086],
              [0.370050014000128, 14.308171273000028],
              [0.385939986000039, 14.288669587000129],
              [0.401769996000098, 14.254010200000039],
              [0.403759987000171, 14.249659539000049],
              [0.386889993000182, 14.221960068000158],
              [0.391000003000102, 14.190159798000082],
              [0.37312001000015, 14.172960282000076],
              [0.357038021000108, 14.148412705000169],
              [0.357924998000044, 14.137957572000062],
              [0.36334899100018, 14.126171112000065],
              [0.381915003000131, 14.102649688000099],
              [0.384328991000018, 14.095771790000185],
              [0.384321988000067, 14.095211029000041],
              [0.39506098600009, 14.086340904],
              [0.38406899600011, 14.070501329000138],
              [0.387413173000141, 14.059187889000043],
              [0.387459994000039, 14.059029579000082],
              [0.398788006000075, 14.027869224000142],
              [0.407790095, 14.014828682000143],
              [0.410461008000141, 14.010959626000101],
              [0.416277468000146, 14.005257607000033],
              [0.422089011000082, 13.999560357000064],
              [0.442027211000038, 13.984880447000137],
              [0.442552239000179, 13.984494209000161],
              [0.443399011000054, 13.983871460000046],
              [0.446550936000165, 13.981053352000117],
              [0.450280011000075, 13.977719306000097],
              [0.450408637000066, 13.977389336000158],
              [0.453289002000076, 13.970000267000103],
              [0.45340499200006, 13.96969890500003],
              [0.450450986000078, 13.956580163000126],
              [0.45521, 13.949961663000181],
              [0.463434995000114, 13.951850891000163],
              [0.469556987000033, 13.956373216000145],
              [0.47255900600004, 13.958589555000117],
              [0.473371386999986, 13.959323884000071],
              [0.480500013000096, 13.965769768000143],
              [0.481444000000124, 13.960731507000048],
              [0.483209013000021, 13.951311111000109],
              [0.482468099000187, 13.948959351000042],
              [0.482212306000065, 13.948146820000034],
              [0.480453998000087, 13.942561148999971],
              [0.474089383000091, 13.937714577000065],
              [0.473437994999983, 13.937218665999978],
              [0.472942413000055, 13.935218812000073],
              [0.471614986000077, 13.929862023000169],
              [0.473360985000056, 13.917691231000163],
              [0.474177450000184, 13.915769578000038],
              [0.476476014000127, 13.910359382000024],
              [0.488398522000182, 13.897838593000131],
              [0.491901993000056, 13.894159318000106],
              [0.497350992000179, 13.889840126000138],
              [0.497748465000086, 13.889655114000107],
              [0.5046690100001, 13.886440277000077],
              [0.513352990000101, 13.879693985000017],
              [0.517690301000016, 13.876324655000133],
              [0.520771026000091, 13.873931885000047],
              [0.52381640700014, 13.86972522800005],
              [0.526238978000094, 13.866378784000119],
              [0.526560963000179, 13.856389046000118],
              [0.52686101200004, 13.847079277000148],
              [0.527052581000021, 13.846736908000139],
              [0.527409196000121, 13.846099853999988],
              [0.528938114000141, 13.843366623000065],
              [0.530439973000171, 13.840682030000096],
              [0.54036998700002, 13.834090233000097],
              [0.551217973000178, 13.831400872000074],
              [0.555006027000047, 13.827758789000086],
              [0.564220010000099, 13.822999954000068],
              [0.564991772000042, 13.822445870000024],
              [0.5781739950001, 13.812990189000061],
              [0.581965982000099, 13.809338571000126],
              [0.584649503000151, 13.794936181000025],
              [0.584659993000116, 13.794879913000159],
              [0.584882081000046, 13.794538498000065],
              [0.589420974000177, 13.787560463000091],
              [0.602168978000122, 13.780982017000099],
              [0.6099230640001, 13.779484749000176],
              [0.613210023000022, 13.77884960200015],
              [0.61333882800011, 13.780996323000011],
              [0.613508939000042, 13.783832551000046],
              [0.61365801200003, 13.786349295999969],
              [0.62047702100017, 13.786629677000178],
              [0.620995999000115, 13.779970170000126],
              [0.619368970000039, 13.777661324000064],
              [0.619434953999985, 13.777589798000179],
              [0.619741558000158, 13.777257920000068],
              [0.620430291000048, 13.776514053000085],
              [0.625297010000111, 13.771259309000129],
              [0.62558001300016, 13.765290259999972],
              [0.621178985000142, 13.75584125600011],
              [0.614229916999989, 13.747600555000133],
              [0.599734009000031, 13.730409622000025],
              [0.600282133000064, 13.719081879000044],
              [0.60029, 13.718918801000029],
              [0.604676365000046, 13.710899353000173],
              [0.60530001, 13.709758759000124],
              [0.619018376000156, 13.699061392999965],
              [0.635519028000147, 13.693430902000159],
              [0.655973970000048, 13.693340303000184],
              [0.66772800800004, 13.693650245000072],
              [0.683849991000045, 13.69294357400014],
              [0.684092046000103, 13.692940712000109],
              [0.71845001, 13.692595483000048],
              [0.750001192000184, 13.692277908000108],
              [0.76837402700005, 13.69209289700018],
              [0.768679977000147, 13.692090989],
              [0.772598028000061, 13.692971230000126],
              [0.772599995000121, 13.692049980000093],
              [0.772896111000136, 13.691869736000115],
              [0.78374302300017, 13.685278893000032],
              [0.796877026000061, 13.677296639000019],
              [0.796920002000036, 13.67726993500014],
              [0.784898818000158, 13.673633576999976],
              [0.784489988000075, 13.673508644000037],
              [0.782345057000157, 13.67023754000013],
              [0.778438986000026, 13.664280891000089],
              [0.78162104, 13.642827988000136],
              [0.781639993000113, 13.642700195000089],
              [0.790709019000133, 13.637911798000118],
              [0.827189983000039, 13.618651390000082],
              [0.838268996000181, 13.621618272000092],
              [0.846417009000163, 13.623801232000062],
              [0.852757991000033, 13.623262406000038],
              [0.857409059000133, 13.622867585000165],
              [0.863835633, 13.622322082000039],
              [0.870922029000155, 13.621721267000169],
              [0.907096028000126, 13.618651390000082],
              [0.90866971000014, 13.617005348000134],
              [0.912775994000071, 13.6127109520001],
              [0.913388192000127, 13.612326622000069],
              [0.913479389000088, 13.612269401000049],
              [0.929379284000163, 13.602294922000169],
              [0.938090027000158, 13.596830368000099],
              [0.950994730000048, 13.586595536000061],
              [0.958580016000042, 13.580579759000102],
              [0.961559295000086, 13.579643250000174],
              [0.973646999000152, 13.575841904000072],
              [0.988570512000081, 13.57635212100007],
              [0.989652992000117, 13.576389313000163],
              [0.989960015000065, 13.576399803000129],
              [0.993657470000187, 13.571744920000185],
              [0.998149990000115, 13.566088676999982],
              [1.002649188000021, 13.561608315],
              [1.00594902000006, 13.558321953000075],
              [1.006079554000166, 13.558012008999981],
              [1.010004043000095, 13.548691750000046],
              [1.014811038000175, 13.531020163999983],
              [1.007359982000082, 13.519379615999981],
              [1.010066986000027, 13.506508828000165],
              [1.014089942, 13.487380982000104],
              [1.024283290000028, 13.485766411000156],
              [1.040809036000098, 13.48314857600019],
              [1.043662070000096, 13.480864524000026],
              [1.044893026000125, 13.479879380000057],
              [1.067780018000065, 13.461541176000082],
              [1.079923034000046, 13.461081504000106],
              [1.087890863000041, 13.460779190000096],
              [1.100467920000142, 13.448722840000187],
              [1.136669041000062, 13.414020539000092],
              [1.156128048000085, 13.412710190000155],
              [1.157957316000136, 13.409525871000028],
              [1.159821033000071, 13.40628147100017],
              [1.177498222000111, 13.400300026000025],
              [1.191542030000164, 13.395547866000129],
              [1.203062892000048, 13.39281559],
              [1.214548945000161, 13.390091895000182],
              [1.221647023000173, 13.387302399000134],
              [1.227550984000061, 13.38854789800007],
              [1.24092996100012, 13.391369820000136],
              [1.280969978000087, 13.358619690000012],
              [1.281260014000054, 13.357689857000082],
              [1.282955051000101, 13.352255822000188],
              [1.282607675000122, 13.351983070000131],
              [1.28250908800004, 13.351923942000042],
              [1.282130600000187, 13.351696969000045],
              [1.279885053999976, 13.350350380000066],
              [1.279152035000038, 13.349910737000187],
              [1.258756042000073, 13.341952324000147],
              [1.25852799400019, 13.342006684000182],
              [1.2545599930001, 13.340315818000079],
              [1.25382399599999, 13.340027809000162],
              [1.252470017000178, 13.339500427000189],
              [1.236462950000089, 13.333250047000149],
              [1.231297015000166, 13.333699228000114],
              [1.220916986000077, 13.350448608000022],
              [1.220653057000163, 13.360789300000135],
              [1.21686696900008, 13.370671273000028],
              [1.210752010000135, 13.373870850000174],
              [1.208402992000117, 13.373865127000158],
              [1.202298999000163, 13.373850822000122],
              [1.203306198000121, 13.36198902100017],
              [1.204370976000121, 13.349450111000124],
              [1.201205015000085, 13.34649372199999],
              [1.201539993000097, 13.335450172000037],
              [1.193989992000013, 13.326009750000139],
              [1.192935943000123, 13.324747086000116],
              [1.18649995300018, 13.317031861000089],
              [1.182508946000098, 13.316789628000038],
              [1.178766966000069, 13.318968773000108],
              [1.176628948000143, 13.320219994000183],
              [1.148681999000132, 13.322210312000038],
              [1.14492595300004, 13.330788612000163],
              [1.143884062000097, 13.330083848000072],
              [1.143759966000118, 13.329999924000049],
              [1.143651962999968, 13.330061912000076],
              [1.136315942000067, 13.334510803000171],
              [1.132310987000096, 13.33693790500007],
              [1.128826976000028, 13.334032058000105],
              [1.126829982000174, 13.332367898000086],
              [1.123520970000129, 13.33426857000012],
              [1.120067953000159, 13.333146095000075],
              [1.119680047000031, 13.333020211000132],
              [1.108443261000048, 13.339253426000084],
              [1.090667010000118, 13.34911346500013],
              [1.083621978000053, 13.353021621000039],
              [1.082569481000064, 13.353605271000163],
              [1.078183651000131, 13.356038094000098],
              [1.076570152000102, 13.356933594000111],
              [1.075178027000106, 13.357705117000137],
              [1.073209882000072, 13.358797074000108],
              [1.070988656000168, 13.360029221000048],
              [1.067166804000124, 13.36215019299999],
              [1.065001965000135, 13.363350867000065],
              [1.064388991000044, 13.363690376000022],
              [1.064098596000065, 13.36381721399999],
              [1.055342793000136, 13.36763000500008],
              [1.055194736000033, 13.367694856000014],
              [1.053089022000052, 13.368611336000185],
              [1.028715968000142, 13.366991043000155],
              [1.023609638000039, 13.369689942000093],
              [1.018820046000087, 13.372220993000155],
              [0.998849988000075, 13.373261452000179],
              [0.997288763000142, 13.372741700000176],
              [0.991819978000024, 13.370920182000077],
              [0.990737974000183, 13.325164795000148],
              [0.990700006000054, 13.32355880800003],
              [0.991699994000044, 13.313679695000019],
              [0.991699517000086, 13.313515664000022],
              [0.991541028000029, 13.26218891200017],
              [0.99215102200003, 13.202427864000185],
              [0.992142916000091, 13.192094801999986],
              [0.992142856999976, 13.192040444000099],
              [0.99212032600002, 13.163213729],
              [0.992097019000084, 13.133469582000032],
              [0.991550029000166, 13.107951163999985],
              [0.991876006000041, 13.093819619000044],
              [0.992100418000121, 13.083982469000034],
              [0.992500007000046, 13.066801071000043],
              [0.992789984000183, 13.059221268000044],
              [0.992857038000182, 13.058373450999966],
              [0.993848801000127, 13.045829773000037],
              [0.997500003000027, 12.999650002000124],
              [1.118485569000143, 12.999514580000096],
              [1.123366833000091, 12.999508858000183],
              [1.166411995000033, 12.96475124400007],
              [1.174661994000076, 12.957201957999985],
              [1.190682054000092, 12.942799568000112],
              [1.190678000000105, 12.943720818000145],
              [1.201972961000024, 12.935957910000127],
              [1.210227966000105, 12.926800728000103],
              [1.226475954000136, 12.913319589000139],
              [1.273832559000084, 12.874848366000151],
              [1.273862124000118, 12.874824525],
              [1.273900389000175, 12.874794006000059],
              [1.274029970000129, 12.874691009000173],
              [1.277674556000079, 12.871600152000156],
              [1.292623996000089, 12.85892105],
              [1.302736043000039, 12.852529526000183],
              [1.309193611000183, 12.846352578000165],
              [1.317751169000189, 12.838166237000053],
              [1.317810059000067, 12.838109970000119],
              [1.317996740000183, 12.837987899000041],
              [1.324917434999975, 12.833453179000173],
              [1.327211977000161, 12.831950189000167],
              [1.34016203900012, 12.819131851000066],
              [1.360704422000083, 12.802835464],
              [1.372159004000025, 12.79374885600015],
              [1.388159991000123, 12.779339791000098],
              [1.394858717000147, 12.774089813000046],
              [1.405089974000134, 12.766071319000048],
              [1.410490990000085, 12.763340951000089],
              [1.427297950000025, 12.748675346000027],
              [1.433308006000061, 12.743431091000161],
              [1.576050043000066, 12.633138657000188],
              [1.611876131000088, 12.629703522000113],
              [1.625218034000113, 12.628424644000177],
              [1.664980055000115, 12.62635135700009],
              [1.683251023000025, 12.624570846000154],
              [1.689568997000151, 12.625970841000083],
              [1.700111986000024, 12.624849319000191],
              [1.710186005000026, 12.623729706000177],
              [1.738844035999989, 12.623632432000136],
              [1.74554896300009, 12.6236095430001],
              [1.752312184000061, 12.623273850000146],
              [1.757457971000122, 12.623019218000024],
              [1.776232958000037, 12.622089387000074],
              [1.794500947000131, 12.6216878890001],
              [1.80598104000012, 12.619880676000037],
              [1.820919990000164, 12.619295121000164],
              [1.827996969000139, 12.619018556000128],
              [1.846029996000141, 12.619071960999975],
              [1.857979059, 12.616799353999966],
              [1.863834023000038, 12.616359711000143],
              [1.870151996000118, 12.618209838000155],
              [1.873898982000185, 12.61821937600007],
              [1.910964012000079, 12.681309699999986],
              [1.922387003999972, 12.70180034700013],
              [1.924723982999979, 12.704568862000144],
              [1.93105304300002, 12.703661920000059],
              [1.948868990000165, 12.697499275999974],
              [1.960080028000164, 12.710849762000066],
              [1.961493848999965, 12.71196079300006],
              [1.977509975000032, 12.73717022],
              [1.983605265000051, 12.735689163000131],
              [2.005170106000037, 12.730449677000024],
              [2.013134002000129, 12.728379250000046],
              [2.022510051000097, 12.728156090000084],
              [2.033684015, 12.727890968000168],
              [2.038508178000143, 12.72777271200016],
              [2.039418936000175, 12.727749826000036],
              [2.061389923000036, 12.727218628000116],
              [2.062210799000127, 12.726963997000098],
              [2.062959909000085, 12.726731301000029],
              [2.071001052999975, 12.72422027600004],
              [2.082761049000112, 12.721140861000038],
              [2.091569900000025, 12.727470398000037],
              [2.104160070000091, 12.730891228000075],
              [2.105032919999985, 12.727725030000101],
              [2.106234074000099, 12.723367691000078],
              [2.110779047000165, 12.706879616000037],
              [2.11359834700005, 12.706196786000021],
              [2.115172148000113, 12.705815316000098],
              [2.115689992000171, 12.705690385000139],
              [2.128628969000033, 12.697271348000129],
              [2.160629988000096, 12.687950134000175],
              [2.159883738000133, 12.68110084500006],
              [2.157819986000106, 12.662159920000079],
              [2.151806592000128, 12.649714469000173],
              [2.151500463000104, 12.649081230000093],
              [2.156773330000021, 12.646118163000153],
              [2.161312580000072, 12.643568994000134],
              [2.174320222, 12.636263847000123],
              [2.180948973000056, 12.632540702000028],
              [2.196029902000021, 12.624070168000117],
              [2.196288824000135, 12.623713492999968],
              [2.216406107000125, 12.596018791],
              [2.224298716000078, 12.585153579000064],
              [2.22586989500013, 12.582990647000145],
              [2.226059437, 12.578138353000099],
              [2.228260995000085, 12.521788597000125],
              [2.245519876000174, 12.501339912000105],
              [2.248271942000145, 12.498079301000018],
              [2.248319387000095, 12.498023034000084],
              [2.249933480000152, 12.496109962000048],
              [2.254038335000075, 12.491246224000179],
              [2.254901886000027, 12.490223885000034],
              [2.256092071000182, 12.488815307000039],
              [2.262650966000081, 12.481042863000141],
              [2.263967513000011, 12.479482650000136],
              [2.267811060000156, 12.47492790199999],
              [2.268137693000085, 12.474249840000027],
              [2.269935130000079, 12.470518112000036],
              [2.27055001400015, 12.469242095000027],
              [2.271229983000183, 12.467830659000128],
              [2.26842904200015, 12.454910278],
              [2.267587185000025, 12.444714546],
              [2.266880989000072, 12.436161995000191],
              [2.274271966000072, 12.428380965000088],
              [2.272807122000188, 12.426530838000076],
              [2.272630929000115, 12.426308633000076],
              [2.26668644, 12.425361634000126],
              [2.261159896000152, 12.424481392000132],
              [2.261274099000104, 12.423857688000055],
              [2.262228727000036, 12.418649674000051],
              [2.261610270000119, 12.418420791000074],
              [2.257245062999971, 12.416801453000176],
              [2.257179022000116, 12.41667079900003],
              [2.254553079000175, 12.415765762000035],
              [2.253602742000169, 12.41543102300011],
              [2.253200053000171, 12.41528987900017],
              [2.250828742000124, 12.417628289000049],
              [2.248290061000148, 12.420130731000143],
              [2.235960008, 12.425951004000069],
              [2.223953963000099, 12.422680855000124],
              [2.218234777000077, 12.416913986000168],
              [2.217164040000057, 12.415834427000107],
              [2.215521098000181, 12.414180755000075],
              [2.203461170000139, 12.421679496000138],
              [2.202189923000162, 12.422471046000055],
              [2.193239451000181, 12.421952249000014],
              [2.189909935, 12.421759605000091],
              [2.188768624000034, 12.421696662000102],
              [2.186510086000169, 12.421570777999989],
              [2.1816000930001, 12.424100877000171],
              [2.174341918000039, 12.424332618000108],
              [2.174108982000121, 12.424340248000021],
              [2.169685125000115, 12.42201232900004],
              [2.16451096500009, 12.419289589000186],
              [2.155155897000043, 12.419980049000117],
              [2.152112961000171, 12.418609620000154],
              [2.15406966300003, 12.41158199299997],
              [2.143448115000126, 12.40896129500004],
              [2.143414021000183, 12.408931732000156],
              [2.135019063000129, 12.401600838000036],
              [2.126128911000137, 12.398619653000026],
              [2.117465973000151, 12.391730308000149],
              [2.113254070000039, 12.385979653000106],
              [2.111998082000071, 12.38243580000011],
              [2.111377955000023, 12.380689621000101],
              [2.110450029000162, 12.380318643000123],
              [2.107400894000023, 12.379090310000038],
              [2.104125978000127, 12.379777908000108],
              [2.095161915000119, 12.389925003000087],
              [2.091730117000054, 12.393811225000093],
              [2.09005999600015, 12.394631386000185],
              [2.087986946000058, 12.395649909000156],
              [2.082118034000132, 12.393540383000072],
              [2.078151465000076, 12.389198304000047],
              [2.076910973000111, 12.387840271000073],
              [2.072160960000133, 12.382269859000132],
              [2.069350958000143, 12.375741959000038],
              [2.071411372000114, 12.373899460000075],
              [2.072429895000084, 12.372988702000043],
              [2.072443963000183, 12.372568130000047],
              [2.072540998000136, 12.369680405000111],
              [2.0644919880001, 12.361060143000088],
              [2.064690113000097, 12.356209756000112],
              [2.064908980000155, 12.355548860000169],
              [2.066420078000135, 12.350940704000095],
              [2.066610097000137, 12.350687027000163],
              [2.069282055000031, 12.347121240000092],
              [2.085982085000183, 12.324832916000162],
              [2.092032909000068, 12.316756249000036],
              [2.123003960000119, 12.275419235000186],
              [2.138096094000105, 12.255274773],
              [2.139065027000072, 12.253981590000024],
              [2.184197187000052, 12.193744660000107],
              [2.193201780000152, 12.181726455000046],
              [2.196365118000074, 12.177504539000097],
              [2.214387894000026, 12.153449058999968],
              [2.21605277000009, 12.151226998000084],
              [2.232304574000068, 12.129535674000181],
              [2.245739936000121, 12.111603736000063],
              [2.254523993000078, 12.099880219000056],
              [2.262786627000025, 12.088851929999976],
              [2.296766042000172, 12.043499947000043],
              [2.315751792000185, 12.018159867000179],
              [2.318339110000068, 12.014706611000122],
              [2.346709967000095, 11.97684097400014],
              [2.351279974000136, 11.9703407290001],
              [2.362384082000176, 11.954540254000051],
              [2.365839004000179, 11.949703217000149],
              [2.370421886000145, 11.943289757000116],
              [2.371742010000105, 11.941438675000029],
              [2.373437165000155, 11.939849853000055],
              [2.377107382000133, 11.936411857000166],
              [2.379994392000072, 11.933707238000125],
              [2.382030010000108, 11.93180084300019],
              [2.38469147700016, 11.92798709900012],
              [2.395828009000184, 11.91202926700015],
              [2.405400038000039, 11.901610374000029],
              [2.404860497000072, 11.898568154000031],
              [2.404751062000173, 11.897951127000056],
              [2.404536962000179, 11.897782327000129],
              [2.402411938000057, 11.896108628000093],
              [2.397146941000074, 11.895468711000035],
              [2.395867109000051, 11.892494202000023],
              [2.394463063000046, 11.88923072800003],
              [2.398407935000137, 11.881888390000029],
              [2.399729967000098, 11.879427909000071],
              [2.401427031000026, 11.876273156000082],
              [2.402379989000167, 11.874501228000042],
              [2.402386666000098, 11.87438869500005],
              [2.402607917000182, 11.870590210000103],
              [2.400268555000082, 11.867139816000076],
              [2.394096136000144, 11.864747049000073],
              [2.389051914000049, 11.862791062000156],
              [2.379230976000144, 11.854070664000176],
              [2.377192736000097, 11.848827363000055],
              [2.375479936000147, 11.844421386000079],
              [2.375396489000082, 11.843899727000178],
              [2.374304056000028, 11.837061882000057],
              [2.375463962000026, 11.833380699000031],
              [2.378031970000052, 11.830848695000157],
              [2.382469893000177, 11.829919814000164],
              [2.382860899000036, 11.82862091100003],
              [2.383162976000051, 11.827618599000061],
              [2.382031918000109, 11.825519563000114],
              [2.381057024000086, 11.823710441000173],
              [2.377019643000097, 11.821687699000165],
              [2.370074035000016, 11.818208695000067],
              [2.36773300200008, 11.813389777000168],
              [2.370059729000104, 11.806941032999987],
              [2.369876146000081, 11.806468963000043],
              [2.368184089000181, 11.802120209000122],
              [2.370901108000169, 11.799280166000017],
              [2.371365548000028, 11.798791886000117],
              [2.372148037000045, 11.79796981700008],
              [2.372211933000187, 11.796609878000083],
              [2.372375010000098, 11.793140410999968],
              [2.370892048000144, 11.792889594000087],
              [2.362287760000186, 11.791401864000136],
              [2.349956035000105, 11.78927040200017],
              [2.340379001000144, 11.785370827000179],
              [2.340191125000047, 11.782235145000072],
              [2.339663030000111, 11.77342128700019],
              [2.338751792000039, 11.771330834000139],
              [2.338258982000127, 11.77020072900018],
              [2.335840941000186, 11.767881394000028],
              [2.32891011300012, 11.76124191299999],
              [2.319097996000153, 11.756201745000055],
              [2.316056014000139, 11.750224115000037],
              [2.313951970000119, 11.746089935999976],
              [2.30366897600004, 11.735288620000119],
              [2.30248999600002, 11.725399971000058],
              [2.299216985000157, 11.720811844000139],
              [2.300035953000077, 11.714539528000046],
              [2.300837993000187, 11.708391190000043],
              [2.306658983000034, 11.696891786000037],
              [2.304158926000071, 11.687081336000062],
              [2.303845882000076, 11.685850143000096],
              [2.304775953000046, 11.682860375000189],
              [2.306849717000034, 11.681978227000172],
              [2.309592007999981, 11.680810929000074],
              [2.309689998000124, 11.679710388000046],
              [2.30572891200012, 11.67442035800002],
              [2.28870606400011, 11.665671347999989],
              [2.257458925000094, 11.647920608000163],
              [2.238404750000143, 11.629091263000021],
              [2.218781948000128, 11.609700203000159],
              [2.21459698699999, 11.606790543000102],
              [2.206197023000073, 11.600950242000124],
              [2.201998949000029, 11.600020409000024],
              [2.1976203910001, 11.600356101999978],
              [2.193133116000183, 11.600699426000176],
              [2.185441018000176, 11.597240449000026],
              [2.166343928000174, 11.576979637000022],
              [2.158308982000051, 11.570461274000081],
              [2.157258988000024, 11.569609642000103],
              [2.144690036000156, 11.554180145999965],
              [2.103018046000102, 11.51294994400007],
              [2.090682983000079, 11.498899460000132],
              [2.087237596000023, 11.495897292000109],
              [2.08120322100018, 11.490649223999981],
              [2.081021070000134, 11.490489006000075],
              [2.080065013000137, 11.489647865],
              [2.075927974000081, 11.486044883000147],
              [2.073225022000088, 11.483691215000079],
              [2.06042790500004, 11.469869615000107],
              [2.034137963000092, 11.442918777000045],
              [2.02512192800009, 11.43370819099999],
              [2.019248008000091, 11.427710533999971],
              [2.010399103000054, 11.423330307000015],
              [1.998345136000069, 11.421939849000125],
              [1.981961966000029, 11.420049668000104],
              [1.98150515500015, 11.420080185000074],
              [1.969244003000142, 11.420905113000117],
              [1.958876014, 11.42160034300008],
              [1.957371711000178, 11.420790673000056],
              [1.957228542000109, 11.420713424000155],
              [1.956313729000044, 11.420222283000101],
              [1.95398104200018, 11.42136097000008],
              [1.934620022000161, 11.425230026000122],
              [1.912454964000119, 11.430688859000156],
              [1.903693795999971, 11.434581756999989],
              [1.892377973000123, 11.43960952700013],
              [1.891420961000165, 11.439418793000186],
              [1.884150028000136, 11.437970163000045],
              [1.87267995000002, 11.445770263000099],
              [1.871301533000121, 11.445652007999968],
              [1.867318989000069, 11.445309639000186],
              [1.85193300300017, 11.438872338000181],
              [1.848947048000127, 11.438542367000139],
              [1.84773695399997, 11.438408851000133],
              [1.84659588300002, 11.441767693000031],
              [1.845630170000106, 11.444609642000046],
              [1.841670037000085, 11.444377900000063],
              [1.835612058000038, 11.43817043199999],
              [1.828153968000095, 11.434261322000054],
              [1.825373293000155, 11.433672906000027],
              [1.821623565000039, 11.432880402000023],
              [1.813547374000166, 11.434929848000138],
              [1.807168006999973, 11.43654918600015],
              [1.805890798000121, 11.436360359999981],
              [1.802505016999987, 11.435860634000051],
              [1.79984819900011, 11.43350029100003],
              [1.797844053000176, 11.431719780000094],
              [1.79528498600007, 11.426429748999965],
              [1.7927593, 11.425914765000186],
              [1.787358047000112, 11.424812315999986],
              [1.784925460000011, 11.423076630000082],
              [1.783161998000139, 11.421818733],
              [1.780738114000144, 11.421916007999982],
              [1.777336002000027, 11.422051429000078],
              [1.775674344000038, 11.420817376000059],
              [1.773606419999965, 11.419281007],
              [1.769444705000183, 11.420646668000131],
              [1.766510010000104, 11.42160987900013],
              [1.763811111000166, 11.422499658000163],
              [1.762350440000034, 11.422434807],
              [1.758452057000113, 11.422261239000022],
              [1.757682443000022, 11.420867919000045],
              [1.755656959000135, 11.417201042000158],
              [1.752393961000166, 11.416741371000057],
              [1.748225928000181, 11.419982910000101],
              [1.747961043000032, 11.420188904000099],
              [1.740632535000088, 11.416876794000132],
              [1.73980402900014, 11.416501999000104],
              [1.736698508000131, 11.4173755650001],
              [1.736538053000061, 11.417420387000107],
              [1.733736992000047, 11.421792030000063],
              [1.729305982000085, 11.423851966000143],
              [1.724336863000076, 11.423375129000021],
              [1.722077013000103, 11.423158646000161],
              [1.717406989000097, 11.429821014000026],
              [1.715666175000138, 11.429451943000117],
              [1.71414494600009, 11.429129601000056],
              [1.709875583999974, 11.420398712000065],
              [1.707396985000173, 11.415329934000056],
              [1.703207969000118, 11.410031319000154],
              [1.700279354000031, 11.40829658500013],
              [1.699713350000081, 11.407961846000035],
              [1.692950010000061, 11.409339906000014],
              [1.687581419000026, 11.414388658000121],
              [1.685045362000039, 11.414251329000081],
              [1.683385967000163, 11.414160729],
              [1.670346976000189, 11.400580406000188],
              [1.664142966000043, 11.397017479],
              [1.663123130000031, 11.396431924000126],
              [1.658463955000116, 11.39573192600011],
              [1.648900031000153, 11.39663982400009],
              [1.642831684000157, 11.402388573000167],
              [1.641744494000079, 11.402198791999979],
              [1.640494942000089, 11.4019813540001],
              [1.638813734000053, 11.401690483000095],
              [1.637472988000127, 11.401458740000066],
              [1.628859997000063, 11.392939568000031],
              [1.627113343000133, 11.391970635000064],
              [1.625131010000189, 11.390871048000122],
              [1.620470047000083, 11.390399934000015],
              [1.618639946000087, 11.391389847000141],
              [1.613705992000064, 11.394080161000147],
              [1.607092022000131, 11.394396781000069],
              [1.599717021000174, 11.394749642000022],
              [1.59295165500015, 11.398189544000104],
              [1.59155774200002, 11.400340080000035],
              [1.589679003000072, 11.403239250000127],
              [1.589949131000026, 11.406715393000013],
              [1.590823055000044, 11.417960168000093],
              [1.590513110000074, 11.41902256000003],
              [1.589884043000154, 11.421178818000101],
              [1.589287043000184, 11.421771048999972],
              [1.58764624600002, 11.423384667000107],
              [1.583114981000165, 11.427840233000154],
              [1.576040149000107, 11.429529190000039],
              [1.574483991000136, 11.429901122000047],
              [1.580301047000035, 11.439332009000111],
              [1.578894018000085, 11.443470955000066],
              [1.575498104000133, 11.443943023000088],
              [1.572362185000088, 11.444379806000029],
              [1.569725871000117, 11.446056367000153],
              [1.56839704399999, 11.446901321000041],
              [1.56816101000004, 11.451948165000033],
              [1.567913056000123, 11.457249641000089],
              [1.55997800900002, 11.462301255000057],
              [1.557475329000056, 11.468088149000152],
              [1.556699991000073, 11.469881058000055],
              [1.556072234000055, 11.47031402600004],
              [1.554365992000101, 11.471489906000158],
              [1.550634979000051, 11.47056102800002],
              [1.548368811000103, 11.464241029000164],
              [1.546923040000138, 11.460208893000129],
              [1.541342974000145, 11.450551987000154],
              [1.537150025000187, 11.447780610000052],
              [1.531787038000118, 11.447311401000036],
              [1.52385604400007, 11.449831963000065],
              [1.518483997000146, 11.454421996000121],
              [1.513061761000188, 11.455648423000127],
              [1.509387969000159, 11.456480027000168],
              [1.501207948000058, 11.466348648],
              [1.494680047000031, 11.465879441000027],
              [1.480240583000068, 11.45459079699998],
              [1.479526638999971, 11.454514503000098],
              [1.475814818000117, 11.454120637000187],
              [1.467860938000115, 11.466528893000088],
              [1.460173965000138, 11.463520050000113],
              [1.45616996300015, 11.463088035000169],
              [1.451547981000147, 11.462590219000163],
              [1.449363709000068, 11.463521958000058],
              [1.446179987000107, 11.464880944000072],
              [1.443132997000021, 11.472230911000111],
              [1.440535068000145, 11.47360038700009],
              [1.440096974000085, 11.473831178000069],
              [1.434504032000064, 11.472210885000038],
              [1.431018949000134, 11.46623039200017],
              [1.42565703300005, 11.46622085600012],
              [1.422975182000073, 11.458227157000124],
              [1.422181010000088, 11.455860139000038],
              [1.421645045000105, 11.455248833000098],
              [1.421612263000043, 11.45521164000013],
              [1.419157028000029, 11.452409744000079],
              [1.414263009000024, 11.451481819000151],
              [1.412909150000132, 11.452051163000021],
              [1.407727003000105, 11.454231262000178],
              [1.404085397000074, 11.453086854000162],
              [1.403301000000113, 11.452839852000182],
              [1.400056243000108, 11.451015472],
              [1.384436011000162, 11.4422311780001],
              [1.37981605499999, 11.423361778000071],
              [1.381043912000109, 11.42030811300009],
              [1.382863998000062, 11.415781975000073],
              [1.383272408999972, 11.412222862000021],
              [1.383576989000176, 11.409568787000069],
              [1.384889602000158, 11.408951760000093],
              [1.385380030000022, 11.408720970000161],
              [1.38980424500005, 11.406625749000057],
              [1.389878989000067, 11.406590462000111],
              [1.390588045000072, 11.402689934000136],
              [1.389561534000166, 11.40126419000012],
              [1.388260245000026, 11.399460793],
              [1.375210046, 11.397371293000162],
              [1.37496590700016, 11.396686553000166],
              [1.372663975000137, 11.390230178000024],
              [1.361951232000081, 11.384690286000136],
              [1.359952689000181, 11.385048866000147],
              [1.35938894700007, 11.385149955000031],
              [1.358250976000136, 11.387379647000159],
              [1.356811047000122, 11.39020061500014],
              [1.353780032000032, 11.390421867000157],
              [1.351948739000136, 11.388306618],
              [1.350991964000116, 11.387201309000147],
              [1.351463079000098, 11.384336471999973],
              [1.351938009000151, 11.381448747000036],
              [1.34356999400012, 11.371780396000077],
              [1.344163299000059, 11.367744447000177],
              [1.344279885000162, 11.366950990000021],
              [1.340556025000069, 11.363951683999971],
              [1.335199952000153, 11.362331391000112],
              [1.323307992000139, 11.363682747000041],
              [1.32004797400009, 11.362759591000042],
              [1.317957998000168, 11.35906982500012],
              [1.319381953000118, 11.356800079000038],
              [1.321702957000127, 11.353100776000133],
              [1.320740699000112, 11.349646569000072],
              [1.320549965000112, 11.348961830000178],
              [1.326411962000123, 11.334719657000107],
              [1.3320120570001, 11.331510544000025],
              [1.331925153000043, 11.328256607000014],
              [1.33179700400018, 11.323460579000027],
              [1.334736942000063, 11.319540978000191],
              [1.34091603700017, 11.311289787000021],
              [1.340919972000052, 11.309866905000092],
              [1.340924977000043, 11.30807876599999],
              [1.339038134000134, 11.306035996000105],
              [1.338368058000128, 11.305310249000172],
              [1.33138501500008, 11.301158905000079],
              [1.327288986000042, 11.292206765000117],
              [1.326750993000189, 11.291030885000168],
              [1.326584815000103, 11.290913582000144],
              [1.325120329000072, 11.289879799000119],
              [1.317171692000102, 11.291948318000152],
              [1.314628958000071, 11.292610169000113],
              [1.313023567000187, 11.296116828000038],
              [1.311579942000037, 11.299269676000165],
              [1.308080553000082, 11.301099777000161],
              [1.307400107000092, 11.300992012000052],
              [1.303657651000094, 11.300399781000124],
              [1.302173496000137, 11.294318199000031],
              [1.30158007100016, 11.291890145000025],
              [1.299734472000125, 11.292112351000128],
              [1.290390014000025, 11.293238641000016],
              [1.282889008000041, 11.310238838000032],
              [1.27959501800018, 11.313011170000095],
              [1.274713992000045, 11.317119600000012],
              [1.268028974000117, 11.320226670000068],
              [1.265846014000147, 11.321241378000025],
              [1.260254980000127, 11.321033478000061],
              [1.259088040000165, 11.320989609000037],
              [1.255601049000063, 11.318221092000044],
              [1.254538774000025, 11.31603050200016],
              [1.252588033000166, 11.312007905000144],
              [1.252371310000058, 11.309823036000068],
              [1.252132059000076, 11.3074111950001],
              [1.253041982000127, 11.304380418000051],
              [1.253668904000165, 11.302287102000093],
              [1.254956006000043, 11.297988891000159],
              [1.254750014000081, 11.289939881000123],
              [1.258265972, 11.280982019000021],
              [1.261036039000146, 11.277897835000147],
              [1.26177096300006, 11.277079583000102],
              [1.26666605500003, 11.276398659000051],
              [1.268037082000092, 11.278214454000135],
              [1.269454003000135, 11.280091285000083],
              [1.263844968000115, 11.285820961000013],
              [1.263742923000109, 11.28688812300004],
              [1.263602972000172, 11.288351059000092],
              [1.265930056000173, 11.289971353000055],
              [1.270475746000045, 11.289552688000128],
              [1.270825982000076, 11.289520264000146],
              [1.272081851000166, 11.287146568000026],
              [1.274106026000027, 11.283320426000159],
              [1.273432970000101, 11.273658753],
              [1.276252984000053, 11.264240265000012],
              [1.276010990000088, 11.263778688000116],
              [1.27393496100018, 11.259860992000142],
              [1.266126155000166, 11.256649018000132],
              [1.260434985000188, 11.254308701000014],
              [1.25340914700007, 11.246758459999967],
              [1.252070308000043, 11.245320320000189],
              [1.248342037000043, 11.245551109000189],
              [1.24487006600009, 11.247898101000033],
              [1.242056012000148, 11.249800683000046],
              [1.231948138000121, 11.25663948100015],
              [1.228032947000088, 11.259288788000049],
              [1.222902059000091, 11.260198593000098],
              [1.215004802000124, 11.258850097000106],
              [1.211958051000181, 11.258330345000047],
              [1.206125975000077, 11.260841370000094],
              [1.198436023000113, 11.260590553000043],
              [1.190984964000108, 11.258501052999975],
              [1.185160041000131, 11.258720398000094],
              [1.183777928000097, 11.259754182],
              [1.181190015000141, 11.261689187000172],
              [1.179422737000095, 11.261960983000108],
              [1.176761032000059, 11.262371063999979],
              [1.173696041000028, 11.261759758000039],
              [1.168612958000153, 11.26074123300009],
              [1.159520029000021, 11.261170388000153],
              [1.152740002000087, 11.268050193000136],
              [1.147842527000137, 11.270798684000113],
              [1.142374516000075, 11.270349503000148],
              [1.142017961000136, 11.27031993899999],
              [1.138722777000169, 11.267731665999975],
              [1.136440993, 11.265939712000034],
              [1.134926797000162, 11.263951301000077],
              [1.129245996000066, 11.256490709000104],
              [1.129029035000144, 11.25166130000008],
              [1.136297941000066, 11.236961366000116],
              [1.143306017000157, 11.231240273000026],
              [1.153568983000184, 11.227588654000158],
              [1.156380057000092, 11.223919868000166],
              [1.155838252000137, 11.220114709000029],
              [1.155691027000103, 11.219080926000174],
              [1.154176831000029, 11.217076302000066],
              [1.149428009000076, 11.210788727000022],
              [1.150225044000024, 11.209570885000176],
              [1.151533962000144, 11.207571029000064],
              [1.148520946000076, 11.203200341000127],
              [1.152493000000106, 11.198378563000119],
              [1.150481701000047, 11.191511154000068],
              [1.149729013000069, 11.18894100100016],
              [1.152547955000102, 11.181369781000058],
              [1.150467038000159, 11.17560863600005],
              [1.152642727000114, 11.172944069000096],
              [1.155143977000137, 11.169880868000064],
              [1.160053014000141, 11.164369584000042],
              [1.159645915000112, 11.164056778000088],
              [1.159026981000068, 11.163581848000035],
              [1.155869007999968, 11.161141396000119],
              [1.151373983000156, 11.161326409000026],
              [1.150513052000065, 11.16136169400005],
              [1.14701199600006, 11.163870811000095],
              [1.144232511000041, 11.168556213000159],
              [1.141157031000148, 11.17374038600019],
              [1.125319004, 11.173240663000115],
              [1.118335962000117, 11.171610832],
              [1.111830950000126, 11.166300775000025],
              [1.110764147000054, 11.164942741000118],
              [1.096987008999975, 11.147401810000133],
              [1.094038011000066, 11.146301270000038],
              [1.09209978500013, 11.145572664000156],
              [1.091401100000098, 11.145310402000121],
              [1.062543273000074, 11.14565563299999],
              [1.06042504300018, 11.145681380999974],
              [1.055541039000161, 11.14313125700005],
              [1.052760004000049, 11.13943862900004],
              [1.05464804200011, 11.131860733999986],
              [1.050938964000181, 11.127710342000171],
              [1.053277968999964, 11.124731065000105],
              [1.060047031000124, 11.119689941000104],
              [1.064707994000059, 11.118789674000141],
              [1.068186044000072, 11.123399735000078],
              [1.067108751000035, 11.125955582000074],
              [1.066542030000107, 11.127300263000052],
              [1.066722156000083, 11.127718925000067],
              [1.067234992000067, 11.128911018],
              [1.073766946000148, 11.125939369000093],
              [1.07822001, 11.118829728000037],
              [1.081870913000046, 11.117986679000012],
              [1.083112001000018, 11.117699623000078],
              [1.085620163999977, 11.114582062000125],
              [1.086619020000057, 11.113340378000089],
              [1.084550024000066, 11.105051040000092],
              [1.087749959000064, 11.102044106000164],
              [1.090157032000036, 11.099781990000054],
              [1.086454988000128, 11.092640876000189],
              [1.086459993000119, 11.091114044000108],
              [1.086464047999982, 11.089880944000072],
              [1.091557860000137, 11.086676599000043],
              [1.092998982000154, 11.085769654000046],
              [1.095785975000126, 11.079939843000091],
              [1.096515059000012, 11.078418732000102],
              [1.093520044000115, 11.068980217000103],
              [1.097710013000153, 11.064720155000032],
              [1.099830032000114, 11.062560081000015],
              [1.09974920799999, 11.060312271000043],
              [1.099614976999987, 11.056580544000099],
              [1.10989201100017, 11.046270371000105],
              [1.115262985000129, 11.041231155000105],
              [1.1159697760001, 11.038471222000055],
              [1.115929007000148, 11.03841304700012],
              [1.115779042000099, 11.038199425000073],
              [1.113378167000121, 11.034857751000118],
              [1.110177637000163, 11.030402185000071],
              [1.102337717000069, 11.039170265000109],
              [1.100129961000164, 11.041640281000184],
              [1.091024995000169, 11.048740386000134],
              [1.086168409000152, 11.051031113000022],
              [1.084728002000077, 11.051710127999968],
              [1.074476004000189, 11.053750038000032],
              [1.070399881000185, 11.058129311000073],
              [1.069568992000086, 11.059021950000101],
              [1.065751317000149, 11.058823586000074],
              [1.064913034000142, 11.05877971700005],
              [1.046970963000035, 11.050791740000022],
              [1.043059945000096, 11.049050331000103],
              [1.035143018000042, 11.049481391000029],
              [1.030127168000036, 11.052473069000143],
              [1.029778004000093, 11.052680969000107],
              [1.02205598300003, 11.06324005100015],
              [1.017896533000112, 11.07508182600003],
              [1.016410946000065, 11.079311371000131],
              [1.014351964000014, 11.081911088000027],
              [1.013922334000029, 11.082450866000102],
              [1.013134957000148, 11.08343982700012],
              [1.009170056000073, 11.085728645000131],
              [0.999154986000121, 11.085689545000093],
              [0.993409217000135, 11.082631112000115],
              [0.989162266000051, 11.080370903000016],
              [0.984239995000166, 11.088859559000014],
              [0.981670618000123, 11.090689659000077],
              [0.980964841000059, 11.090634347000162],
              [0.978645027000141, 11.090451241000096],
              [0.977909804000149, 11.089598655999964],
              [0.977254986000162, 11.08883953000003],
              [0.971924007000041, 11.082150459000047],
              [0.970359744, 11.075670243000104],
              [0.970089021000092, 11.074548721000042],
              [0.970433236000076, 11.071658135000177],
              [0.971292972000128, 11.064438820000134],
              [0.970461786000101, 11.060546875000057],
              [0.969918012000051, 11.0580005650001],
              [0.965770721000013, 11.052887916000088],
              [0.965051532000132, 11.052001954000161],
              [0.962724986000183, 11.051769256000057],
              [0.962885798000173, 11.050241471000049],
              [0.962966980000147, 11.04946994800008],
              [0.948876978000158, 11.035828590999984],
              [0.947937908000085, 11.035420417000182],
              [0.94451099600002, 11.033930780000105],
              [0.943574548000186, 11.033259391000058],
              [0.921563984000159, 11.017471313999977],
              [0.917907656000125, 11.009318352000093],
              [0.912175531000059, 10.996536979999973],
              [0.911475973000051, 10.996499204000088],
              [0.901083002000178, 10.995937999000091],
              [0.899250359000177, 10.997103475000074],
              [0.89785559500001, 10.997990483000081],
              [0.897751001000074, 10.998057001000063],
              [0.891695, 10.996542999000042],
              [0.868075001000136, 10.995332000000133],
              [0.863756820000162, 10.995332000000133],
              [0.854448000000161, 10.995332000000133],
              [0.848089000000186, 10.997452001000113],
              [0.830221999000059, 10.996542999000042],
              [0.81229437900015, 10.99667393600015],
              [0.788736001000132, 10.996845999000072],
              [0.787524001, 10.997148999000103],
              [0.785136714000146, 10.997107815000049],
              [0.76996, 10.996845999000072],
              [0.761481, 10.996542999000042],
              [0.755169360000082, 10.997144487000071],
              [0.755122, 10.997148999000103],
              [0.741495000000157, 10.996239999000011],
              [0.731805, 10.997148999000103],
              [0.714543999000171, 10.998360001000094],
              [0.705041560999973, 10.997783749000064],
              [0.704551000000151, 10.997753999000054],
              [0.696072, 10.997451999000134],
              [0.687442507000071, 10.997452001000113],
              [0.684867999000062, 10.997452001000113],
              [0.671885022000083, 10.997452001000113],
              [0.670938000999968, 10.997451999000134],
              [0.670101026000111, 10.997496002000105],
              [0.65943, 10.998057001000063],
              [0.650043001000086, 10.998360001000094],
              [0.639141000000166, 10.999571],
              [0.626724999000032, 10.993515],
              [0.611281998999971, 10.984733000000176],
              [0.597107000000165, 10.979117001000077],
              [0.595232000000124, 10.978374],
              [0.590993001000129, 10.975951001000169],
              [0.58221099900004, 10.978979000000152],
              [0.575548998999977, 10.978677000000175],
              [0.550717, 10.967168999000023],
              [0.537696, 10.957782],
              [0.500752, 10.936584],
              [0.509231, 10.952633999],
              [0.509835999000131, 10.97776800000014],
              [0.50711100000018, 10.984733000000176],
              [0.494997999000077, 10.984733000000176],
              [0.491364, 10.986853],
              [0.497421001000021, 10.995029000000102],
              [0.502719999000021, 11.004264999000043],
              [0.5042340010001, 11.009413000000109],
              [0.503931001000069, 11.012744001000101],
              [0.444880999000077, 11.023343000000182],
              [0.436064, 11.026022000000125],
              [0.425420990000134, 11.029256711000187],
              [0.413993000000175, 11.032729999000139],
              [0.399621001000185, 11.036065000000121],
              [0.392362, 11.037539],
              [0.362, 11.044244],
              [0.359261, 11.044742001000145],
              [0.332639999000037, 11.047575001000041],
              [0.314400001000024, 11.048660001000144],
              [0.303119001000141, 11.04982999900011],
              [0.301382371000159, 11.049935234000031],
              [0.299006463000183, 11.050079207000124],
              [0.296353000000124, 11.05024],
              [0.291528, 11.050425],
              [0.291303001000188, 11.050593749000086],
              [0.291228002000082, 11.050649999000086],
              [0.286083378000058, 11.051936104000049],
              [0.266626999000039, 11.056800001000056],
              [0.266554858000177, 11.056615913000087],
              [0.266540001000124, 11.056578],
              [0.265601999000125, 11.057004999000128],
              [0.263587449000056, 11.057400237000138],
              [0.263049993000038, 11.057505682000055],
              [0.205599391000078, 11.068776979000177],
              [0.192507963000082, 11.071345401000031],
              [0.191302001000167, 11.071582001000081],
              [0.190313000000117, 11.072288000000128],
              [0.169802001000051, 11.078431],
              [0.146084, 11.084483001000081],
              [0.100766999000143, 11.092948999000157],
              [0.096686, 11.094297],
              [0.074632000000179, 11.100126],
              [0.074451000000124, 11.09958],
              [0.071539, 11.100744001000123],
              [0.04308300100007, 11.108785999000133],
              [0.042109999000047, 11.10911],
              [0.040013511000097, 11.109808512000086],
              [0.035510001000148, 11.111309],
              [0.016744999000082, 11.113276999000107],
              [0.012119, 11.113761999000076],
              [0.001746, 11.11453900000015],
              [-0.006473000999961, 11.115713],
              [-0.030737, 11.120796001000144],
              [-0.036134999999888, 11.122104],
              [-0.05465699899986, 11.12548899900014],
              [-0.07621, 11.128320000000144],
              [-0.085588, 11.129199999000036],
              [-0.085414998999966, 11.128785001000153],
              [-0.093511, 11.129290999000091],
              [-0.101726998999936, 11.131428],
              [-0.107899150999856, 11.133042961000058],
              [-0.110050998999895, 11.133605999000054],
              [-0.115321, 11.135025001000088],
              [-0.125456999999869, 11.136849],
              [-0.131712000999869, 11.138039999000114],
              [-0.132583422999971, 11.136421415000143],
              [-0.134967871999891, 11.137068990000159],
              [-0.135215911999978, 11.137943035000092],
              [-0.135857656999974, 11.138623628],
              [-0.136182073999976, 11.138785837],
              [-0.136446829999954, 11.139467029000059],
              [-0.136490790999972, 11.139848026999971],
              [-0.136922717999823, 11.139913655999976],
              [-0.1490279759999, 11.141893160000166],
              [-0.152039437999917, 11.142364239000187],
              [-0.152329470999973, 11.142505709000147],
              [-0.154052698999976, 11.143346238000163],
              [-0.161564257999942, 11.147009944000047],
              [-0.166329753999889, 11.148291168000185],
              [-0.174211549999939, 11.150305455000137],
              [-0.176761290999934, 11.151078331000122],
              [-0.1777849529999, 11.151470963000122],
              [-0.180989669999974, 11.152087264000045],
              [-0.185204399999975, 11.153118328000119],
              [-0.197765208999954, 11.156618577000074],
              [-0.21262934899994, 11.16002652800006],
              [-0.217973330999939, 11.160174774000041],
              [-0.218894912999929, 11.16085368099999],
              [-0.225934335999966, 11.163165113000105],
              [-0.239196231999927, 11.166508022000073],
              [-0.251325100999907, 11.169418410000162],
              [-0.276022037999951, 11.174841667000067],
              [-0.276731360999918, 11.17445336000003],
              [-0.278338445999907, 11.173264160000031],
              [-0.279642075999959, 11.172395073000132],
              [-0.280656009999916, 11.171670834],
              [-0.281959639999968, 11.169715390000022],
              [-0.283263268999974, 11.16754267400006],
              [-0.284059930999945, 11.165659652000045],
              [-0.284422049999932, 11.163124817000153],
              [-0.284639321999975, 11.159938167000121],
              [-0.284422049999932, 11.157113634999973],
              [-0.284422049999932, 11.154651224000077],
              [-0.284784168999977, 11.153275170000086],
              [-0.287536275999969, 11.151971540999966],
              [-0.288477786999977, 11.151319726000111],
              [-0.288647598999887, 11.150598021],
              [-0.288767481999969, 11.150088520000054],
              [-0.289491719999944, 11.149074585000108],
              [-0.289703038999903, 11.147704980000185],
              [-0.288440227999956, 11.146063326000046],
              [-0.286167166999917, 11.14395864],
              [-0.284399230999952, 11.142695828000171],
              [-0.283346887999812, 11.142064423000022],
              [-0.280189859999894, 11.14109626700008],
              [-0.27745376799993, 11.140170205000175],
              [-0.276511319999827, 11.139793735000126],
              [-0.276239079999925, 11.139716212000167],
              [-0.274392206999892, 11.13932429099998],
              [-0.273284854999929, 11.13917916000014],
              [-0.273104149999824, 11.139155066000114],
              [-0.272140987999876, 11.138031377000118],
              [-0.271739670999978, 11.137469533000171],
              [-0.271579143999929, 11.134740574999967],
              [-0.270696244999897, 11.13201161700016],
              [-0.270455454999876, 11.129282658000022],
              [-0.270328933999963, 11.128693640000165],
              [-0.270480075999956, 11.128261807000058],
              [-0.271087651999949, 11.126732812000114],
              [-0.272081300999901, 11.125841269000148],
              [-0.27225938, 11.125703663000138],
              [-0.273408527999948, 11.123537960000078],
              [-0.274071497999842, 11.1234053660001],
              [-0.274256986999887, 11.123389908999968],
              [-0.278314505999901, 11.123051782000175],
              [-0.281673554999827, 11.121902633000047],
              [-0.284275602999912, 11.121525526000028],
              [-0.285074743999871, 11.121364976000052],
              [-0.286585616999901, 11.121121315000039],
              [-0.287837817999957, 11.12075259400018],
              [-0.295848949999936, 11.11863090200012],
              [-0.299891038999931, 11.119146913000122],
              [-0.302299092999931, 11.119619924000062],
              [-0.303546120999897, 11.119404919000146],
              [-0.305395161999968, 11.118200892000061],
              [-0.308147222999935, 11.116394852000042],
              [-0.310071304999951, 11.115169869],
              [-0.320488496999928, 11.115147823000143],
              [-0.322552542999972, 11.114373805000071],
              [-0.329432694999923, 11.109557697000184],
              [-0.333904794999853, 11.108181666000121],
              [-0.336720865999951, 11.106722380000178],
              [-0.336890964999839, 11.10567910400016],
              [-0.336537637999925, 11.104287989000113],
              [-0.335538830999951, 11.100355492000062],
              [-0.33652808599993, 11.096584465000035],
              [-0.337267358999895, 11.09467790900004],
              [-0.337939873999915, 11.093216779000102],
              [-0.33842808299994, 11.09183638900015],
              [-0.338733344999866, 11.090826472000117],
              [-0.339251972999875, 11.08879239100014],
              [-0.339372578999928, 11.088585639000087],
              [-0.339636726999913, 11.088088829000014],
              [-0.33995656799982, 11.087695733000032],
              [-0.340357028999904, 11.086909017000096],
              [-0.341062451999903, 11.086251222000101],
              [-0.341645338999911, 11.085698224],
              [-0.342908696999928, 11.085254341000166],
              [-0.344692128999952, 11.084858023000038],
              [-0.346289030999912, 11.084503156000096],
              [-0.349293772999943, 11.084400722000112],
              [-0.352161933999923, 11.084810459000039],
              [-0.353391146999911, 11.08463973500011],
              [-0.35431305599991, 11.083478812000067],
              [-0.354620358999966, 11.081874007000067],
              [-0.35431305599991, 11.080815518000122],
              [-0.354159782999943, 11.07972071100005],
              [-0.354074041999866, 11.079108279000025],
              [-0.354348359999904, 11.077286169000047],
              [-0.354310350999867, 11.07656399900003],
              [-0.355341495999937, 11.072300592000147],
              [-0.356366621999939, 11.071275465000099],
              [-0.358229785999811, 11.070240374000036],
              [-0.35853747699997, 11.070069434000061],
              [-0.359913028999813, 11.06898045600002],
              [-0.361417790999951, 11.0695104670001],
              [-0.361082563999958, 11.070595798000113],
              [-0.361027963999959, 11.070893126000044],
              [-0.360873624999954, 11.071733590000122],
              [-0.360846594999941, 11.072618213],
              [-0.360819565999861, 11.073502836000102],
              [-0.360747413999945, 11.074607845000173],
              [-0.360366151999926, 11.075920845000098],
              [-0.360277939999889, 11.076224631000173],
              [-0.360225794999849, 11.077109200000109],
              [-0.360360757999899, 11.077835306000111],
              [-0.360377485999948, 11.07792530099999],
              [-0.360531445999925, 11.078182962000085],
              [-0.360640926999906, 11.078366212000162],
              [-0.360697200999937, 11.078460644000188],
              [-0.361295431999963, 11.078829440000106],
              [-0.361513648999903, 11.07896396600006],
              [-0.362611650999895, 11.079502360000049],
              [-0.363112370999943, 11.079649456000141],
              [-0.363593390999938, 11.079786893000062],
              [-0.364083013999959, 11.079940639000142],
              [-0.364901132999876, 11.08019753700006],
              [-0.365145429999927, 11.080353535000029],
              [-0.365862448999906, 11.080811393999966],
              [-0.366358152999908, 11.081258909000155],
              [-0.366594521999843, 11.081529745000182],
              [-0.366843791999941, 11.081815364000079],
              [-0.367749596999943, 11.082891422000159],
              [-0.368297362999954, 11.083557509000116],
              [-0.36882598599982, 11.084080591000031],
              [-0.36906251399995, 11.084314361],
              [-0.369603616999939, 11.08498908100006],
              [-0.370074186999886, 11.085799508000036],
              [-0.370117926999967, 11.085874837000176],
              [-0.37040981899986, 11.086385309000093],
              [-0.37063195199994, 11.086895636000179],
              [-0.371043174999954, 11.087712274000069],
              [-0.371387137999875, 11.088666572000136],
              [-0.3715562, 11.089204347000134],
              [-0.371567067999933, 11.089284564000081],
              [-0.371641358999966, 11.089832896000189],
              [-0.371902255999885, 11.090486614999975],
              [-0.372143198999936, 11.09133872600006],
              [-0.372206802999926, 11.091607570000122],
              [-0.372350463999851, 11.092245533000039],
              [-0.372284843999921, 11.09246212100004],
              [-0.37228704599994, 11.092836207000062],
              [-0.372287895999932, 11.092998149000152],
              [-0.372289492999869, 11.093320568000081],
              [-0.372264147999886, 11.093502450000017],
              [-0.372243616999867, 11.093666981000069],
              [-0.372212984999919, 11.09391246500013],
              [-0.37216265, 11.094311133000076],
              [-0.372132294999915, 11.09448743900009],
              [-0.371929296999895, 11.094730247000086],
              [-0.371873519999895, 11.094796964000011],
              [-0.371683384999812, 11.094939707000037],
              [-0.371611450999978, 11.094995957000094],
              [-0.371521659999928, 11.095101561000092],
              [-0.371463388999814, 11.095170930000052],
              [-0.37138553199992, 11.095264737000093],
              [-0.371350294999957, 11.095357077000131],
              [-0.371319886999913, 11.095444871999973],
              [-0.371215783999844, 11.095745431000068],
              [-0.371182055999896, 11.095842809000146],
              [-0.371134284999926, 11.096104554000021],
              [-0.371079455999848, 11.096403191000149],
              [-0.37105174099986, 11.096547418000114],
              [-0.371008475999929, 11.096764334000113],
              [-0.37094501099989, 11.097082521000061],
              [-0.370954262999874, 11.097735869000132],
              [-0.370974, 11.098287303000063],
              [-0.370991777999961, 11.098700398000062],
              [-0.371338121999941, 11.09936118600001],
              [-0.371441365999942, 11.099444082000161],
              [-0.372050468999873, 11.100062849000096],
              [-0.372873587999948, 11.10082093900013],
              [-0.373647332999951, 11.101759593000168],
              [-0.374406955999916, 11.102780897000116],
              [-0.374893154999825, 11.103674555000111],
              [-0.375062944999911, 11.103986636000116],
              [-0.375504877999902, 11.104811602000154],
              [-0.37587895899992, 11.106052584000167],
              [-0.375888569999915, 11.106782947000113],
              [-0.375720187999946, 11.107822023000097],
              [-0.37565105199991, 11.108248655000068],
              [-0.375319872999967, 11.109140917000161],
              [-0.37490693899997, 11.110421607000092],
              [-0.374904767999965, 11.11144132700008],
              [-0.375136958999917, 11.112482855999986],
              [-0.375135508999961, 11.113164315000176],
              [-0.374899716999835, 11.113814244000025],
              [-0.374792424999839, 11.114401052000119],
              [-0.374838608999937, 11.114990934000048],
              [-0.374943758999905, 11.115410067000028],
              [-0.375071706999961, 11.115607388000058],
              [-0.375199653999971, 11.115804708000042],
              [-0.375164018999953, 11.116813335000131],
              [-0.37505485, 11.117632541000035],
              [-0.374948488999962, 11.118430666999984],
              [-0.374922222999828, 11.118535824999981],
              [-0.37470080099996, 11.119422317000158],
              [-0.374465472999873, 11.119851764999964],
              [-0.373866203999853, 11.120474077000154],
              [-0.372851078999872, 11.121649010000112],
              [-0.372534319999943, 11.122477667000169],
              [-0.372365725999941, 11.122918603000073],
              [-0.37243246099996, 11.122960958000135],
              [-0.372557142999881, 11.123053331000051],
              [-0.372722812999939, 11.12307266900001],
              [-0.372907000999874, 11.123070297000027],
              [-0.373099532999902, 11.123081723000041],
              [-0.373442841999974, 11.123054878000062],
              [-0.373780640999939, 11.122994948999974],
              [-0.374037391999877, 11.122989972000028],
              [-0.374122343999886, 11.122926760000098],
              [-0.374308219999932, 11.122927147000041],
              [-0.374799304999954, 11.122961240000109],
              [-0.374972201999867, 11.123019475999968],
              [-0.375719921999917, 11.123103709000134],
              [-0.375847992999866, 11.123244532000115],
              [-0.375959085999909, 11.123495559000048],
              [-0.375958885999978, 11.123589263000156],
              [-0.375958686999979, 11.123682967000093],
              [-0.375989213999901, 11.123762954000028],
              [-0.376100576999875, 11.123887206000177],
              [-0.376220457999864, 11.123942575000058],
              [-0.376419010999939, 11.123747310000169],
              [-0.376514490999966, 11.12346639399999],
              [-0.376716050999903, 11.123169163],
              [-0.376992740999924, 11.122746054000118],
              [-0.376918054999919, 11.12266247600013],
              [-0.37680133899994, 11.12243072900003],
              [-0.376796596999839, 11.122025539000049],
              [-0.376850050999906, 11.12183277400004],
              [-0.377120502999901, 11.12194633199999],
              [-0.377538508999919, 11.122225553000078],
              [-0.377937854999857, 11.121971975000065],
              [-0.37812254399995, 11.121854700000085],
              [-0.378295434999927, 11.121915690000151],
              [-0.378423401999953, 11.122106118999966],
              [-0.378582240999947, 11.122213932000136],
              [-0.378677122999932, 11.122214127000063],
              [-0.378741505999926, 11.122120556000027],
              [-0.378836452999963, 11.122090436],
              [-0.378963030999955, 11.122278106000124],
              [-0.379089607999958, 11.122465777000059],
              [-0.37916761199989, 11.122529326000119],
              [-0.379259598999965, 11.12257912400014],
              [-0.379820356999915, 11.122657449000144],
              [-0.379979161999927, 11.12278179700013],
              [-0.380202792999853, 11.12290861200006],
              [-0.380550151999898, 11.123301104000177],
              [-0.380945698999938, 11.123646420000171],
              [-0.381189687999949, 11.123923240000124],
              [-0.381293699999958, 11.124041246000161],
              [-0.381374297999969, 11.124198505000095],
              [-0.381416072999968, 11.124654401000157],
              [-0.381432749999931, 11.124836406000043],
              [-0.38144249599992, 11.124942770000132],
              [-0.381515473999912, 11.125162028000148],
              [-0.381544431999941, 11.125392179000073],
              [-0.381604643999935, 11.12568115900001],
              [-0.381729119999932, 11.125793582000028],
              [-0.381926109999881, 11.125875296],
              [-0.382164350999972, 11.125833192000073],
              [-0.382280660999925, 11.125812637000081],
              [-0.382451252999886, 11.125642114000073],
              [-0.382617743999901, 11.125642456000094],
              [-0.382907449999891, 11.125742618000061],
              [-0.383347837999906, 11.126067904000138],
              [-0.383690506999926, 11.126235482000141],
              [-0.383831319999956, 11.126273316000095],
              [-0.384062624999899, 11.126265484000157],
              [-0.384202621999862, 11.126260744000092],
              [-0.384291818999941, 11.126257724000027],
              [-0.384492780999892, 11.126298336000048],
              [-0.384783435999964, 11.126554100000021],
              [-0.384879990999934, 11.126640622000082],
              [-0.384987724999974, 11.126794797000173],
              [-0.385049266999943, 11.126698858000111],
              [-0.385164480999947, 11.126652241000102],
              [-0.38525939699997, 11.1266372770001],
              [-0.385354216999929, 11.126667787000144],
              [-0.385449100999949, 11.126667981000139],
              [-0.38554398399998, 11.126668175000134],
              [-0.385736639999948, 11.126621717000035],
              [-0.385893184999929, 11.126495260000127],
              [-0.385943612999938, 11.12640165800002],
              [-0.386038559999975, 11.126371536000022],
              [-0.386064258999966, 11.126146601000187],
              [-0.386006386999895, 11.1257430980001],
              [-0.386054895999905, 11.125549118000038],
              [-0.386133430999905, 11.125416404000077],
              [-0.386198180999941, 11.125308095999969],
              [-0.386374356999966, 11.125262583000051],
              [-0.386579427999948, 11.125289525000142],
              [-0.386753770999917, 11.125262441000189],
              [-0.386945237999953, 11.125180030000024],
              [-0.387089777999904, 11.125232477000054],
              [-0.387249289999943, 11.125244039000108],
              [-0.387341118999927, 11.125144130000081],
              [-0.387401847999968, 11.125078058000156],
              [-0.387465180999868, 11.125009151000029],
              [-0.387526420999961, 11.124942523000186],
              [-0.387591095999937, 11.124872159000063],
              [-0.387645395999868, 11.124813081000127],
              [-0.387727822999864, 11.124741903000142],
              [-0.387899784999945, 11.124657428000148],
              [-0.388053844999945, 11.124643456000058],
              [-0.38818316399994, 11.124633023000172],
              [-0.388307659999953, 11.124622978000161],
              [-0.388426245999938, 11.124613411000155],
              [-0.388526934999902, 11.124619614000039],
              [-0.388646532999928, 11.124628944000108],
              [-0.388726835999933, 11.124635210000065],
              [-0.388807636999957, 11.124672600000167],
              [-0.388906733999875, 11.124718457000142],
              [-0.389043652999931, 11.124781815000063],
              [-0.389136492999967, 11.124824777000072],
              [-0.38931811499998, 11.124908822000179],
              [-0.389458287999958, 11.124951309000039],
              [-0.389557907999915, 11.124989587000186],
              [-0.389651955999966, 11.125042471000143],
              [-0.389777691999825, 11.125113174000035],
              [-0.389858088999972, 11.125156673000163],
              [-0.389980226999967, 11.125182732999974],
              [-0.390102854999896, 11.125208898000096],
              [-0.390238301999943, 11.125237798],
              [-0.390377051999963, 11.125265957000124],
              [-0.390503677999959, 11.125211155000045],
              [-0.390626985999916, 11.12520404900016],
              [-0.39070983299996, 11.125204216999975],
              [-0.390785516999813, 11.125178457000175],
              [-0.390992893999908, 11.125107874000093],
              [-0.391098393999926, 11.125071966000178],
              [-0.39129898199991, 11.125003694000043],
              [-0.39140708299982, 11.124969742000076],
              [-0.39150422799986, 11.124957248999976],
              [-0.391643833999865, 11.124939395000126],
              [-0.391779946999918, 11.124921987999983],
              [-0.39192391499995, 11.124903577000168],
              [-0.392051202999824, 11.124861668000165],
              [-0.392134483999939, 11.124834184],
              [-0.392243814999858, 11.124798102000057],
              [-0.392320061999953, 11.124772940000128],
              [-0.392412857999886, 11.124742315000162],
              [-0.392539229999954, 11.124693166999975],
              [-0.392633459999956, 11.12463560600014],
              [-0.3927180149999, 11.12458880000014],
              [-0.392821402999971, 11.124552591000111],
              [-0.39291177299998, 11.124521204000075],
              [-0.392999550999946, 11.124490718],
              [-0.393153193999979, 11.124533068000176],
              [-0.393200118999914, 11.124712883000029],
              [-0.393202717999884, 11.124864881000178],
              [-0.393282271999965, 11.124979549000045],
              [-0.393262354999933, 11.125136215000055],
              [-0.3933663699999, 11.125303292000126],
              [-0.393515209999975, 11.125414756999987],
              [-0.39378389299992, 11.125626942000054],
              [-0.393884463999882, 11.125775616000112],
              [-0.39406005, 11.125886211000136],
              [-0.394246363999969, 11.125880125000151],
              [-0.394425822999835, 11.125793243000089],
              [-0.394603050999933, 11.12563435900006],
              [-0.394741756999963, 11.125510009000095],
              [-0.394839431999969, 11.125510205000069],
              [-0.394934245999877, 11.125543468999979],
              [-0.395004277999931, 11.125616258000093],
              [-0.395120629999951, 11.125818032000097],
              [-0.395440286999929, 11.126074735000088],
              [-0.395583249999959, 11.126140074000091],
              [-0.395841432999873, 11.126206621000165],
              [-0.395965018999902, 11.126238476000026],
              [-0.396052182999938, 11.126258522000114],
              [-0.396134249999875, 11.126238993000186],
              [-0.396214147999899, 11.126214766000032],
              [-0.396299748999979, 11.126188808],
              [-0.396396202999881, 11.126159560000076],
              [-0.396485, 11.126132633000054],
              [-0.396601164999879, 11.126097408000078],
              [-0.396727336999902, 11.126059148000138],
              [-0.396840574999942, 11.126024265000183],
              [-0.396925961999841, 11.125953983000045],
              [-0.396997813999917, 11.12588288500001],
              [-0.397192497999868, 11.125761963000116],
              [-0.397335562999956, 11.12558342900013],
              [-0.397411185999943, 11.125494377000052],
              [-0.397585819999904, 11.125344847000065],
              [-0.397680751999928, 11.125321610000015],
              [-0.397773676999975, 11.12529886499999],
              [-0.397912708999968, 11.125377015000083],
              [-0.398077532999821, 11.125471612000183],
              [-0.39831813099994, 11.125609694000048],
              [-0.39846507499982, 11.125754328000141],
              [-0.398534095999935, 11.125828405000107],
              [-0.398612182999898, 11.125899340000046],
              [-0.398702152999874, 11.125960856000177],
              [-0.398901767999973, 11.126096140000186],
              [-0.399039391999906, 11.126189412000087],
              [-0.399264496999876, 11.126341972000091],
              [-0.399531315999923, 11.126522803000057],
              [-0.399627494999891, 11.126544111000044],
              [-0.400096275999942, 11.126647973000104],
              [-0.400267801999917, 11.126651050000078],
              [-0.400641933999964, 11.126773239000158],
              [-0.400747465999928, 11.126843112000131],
              [-0.401191230999871, 11.126881777000051],
              [-0.401537103999885, 11.12678820300016],
              [-0.401948741999945, 11.12665921700011],
              [-0.402079192999963, 11.126544416000115],
              [-0.402295308999896, 11.126476327000091],
              [-0.402643058999899, 11.12635484000009],
              [-0.402777473999947, 11.126248153000063],
              [-0.402872419999881, 11.126218025000014],
              [-0.402946836999831, 11.126132799000118],
              [-0.403211208999949, 11.125998156000037],
              [-0.40330273099994, 11.125951545000021],
              [-0.40342976799991, 11.125939966000033],
              [-0.403585824999936, 11.125968288000081],
              [-0.40368519499998, 11.125884437000138],
              [-0.403970121999919, 11.125748923000117],
              [-0.404159889999903, 11.125749299000177],
              [-0.404432671999928, 11.126097098000059],
              [-0.404619877999949, 11.125984471999971],
              [-0.404830495999875, 11.125639375000105],
              [-0.404972680999947, 11.125406405000035],
              [-0.405063998999935, 11.125119151000092],
              [-0.405195151999976, 11.124417431000097],
              [-0.40519533399987, 11.124327220000168],
              [-0.405195497999898, 11.124246514000049],
              [-0.405196134999926, 11.123932373000116],
              [-0.405247249999832, 11.123497022000038],
              [-0.405348946999879, 11.12328784500005],
              [-0.405547102999947, 11.122880265000106],
              [-0.405751128999952, 11.122729087000039],
              [-0.405835016999959, 11.122646572],
              [-0.405946866999898, 11.122536552000156],
              [-0.406136787999969, 11.122459758000048],
              [-0.406479995999973, 11.122188060000099],
              [-0.406709792999948, 11.122006144000181],
              [-0.407011474999877, 11.121863426000061],
              [-0.40716587299994, 11.121713963000047],
              [-0.407285371999876, 11.121660019000046],
              [-0.407886235999968, 11.121228507000012],
              [-0.408250666999891, 11.121022565000033],
              [-0.408352807999904, 11.120964846000163],
              [-0.408489138999926, 11.12116906000017],
              [-0.408743295999898, 11.121269238000139],
              [-0.408918755999935, 11.121241561000033],
              [-0.409013731999892, 11.121194895000087],
              [-0.409108708999952, 11.121148229000141],
              [-0.409427414999925, 11.120864984000036],
              [-0.409617206999826, 11.120851576],
              [-0.409798747999901, 11.120757022000021],
              [-0.409857712999894, 11.120598493000102],
              [-0.410000694999951, 11.120270807],
              [-0.410176976999935, 11.120036890000108],
              [-0.410414560999868, 11.119848567000076],
              [-0.410533352999948, 11.119754406000027],
              [-0.410652143999926, 11.119660245000148],
              [-0.410737943999891, 11.11962278600015],
              [-0.410811973999898, 11.119590466000147],
              [-0.41090591699998, 11.11958357300017],
              [-0.411009351999894, 11.119613580000077],
              [-0.411101213999928, 11.119653997000114],
              [-0.4111662699999, 11.119753777000028],
              [-0.411226128999886, 11.119850481000071],
              [-0.411276141999963, 11.11993211000015],
              [-0.411325982999813, 11.120053617000167],
              [-0.411384258999931, 11.120139422000079],
              [-0.411448980999864, 11.120225303000154],
              [-0.411534593999932, 11.120338903000118],
              [-0.411626726999828, 11.120461152000019],
              [-0.411708952999959, 11.120564903],
              [-0.411851800999955, 11.120633500999986],
              [-0.411996637999948, 11.120681781000087],
              [-0.412111501999959, 11.120720070000175],
              [-0.412217490999922, 11.120746323000105],
              [-0.412385038999957, 11.120746754000038],
              [-0.412505105999912, 11.120746988000064],
              [-0.412631758999851, 11.120747236000113],
              [-0.412734240999953, 11.120749305000174],
              [-0.4128418, 11.120823569000095],
              [-0.412913347999961, 11.12077900100013],
              [-0.413080565999962, 11.12077842799999],
              [-0.413270329999932, 11.12077879800006],
              [-0.413339957999824, 11.120847834000074],
              [-0.413877580999895, 11.120925007000153],
              [-0.414176917999953, 11.120967975000156],
              [-0.414336232999915, 11.120844264000141],
              [-0.414397973999883, 11.120670754000173],
              [-0.414367997999875, 11.120309656000131],
              [-0.414585900999953, 11.11984362100003],
              [-0.414734885999962, 11.119651678000025],
              [-0.415019777999817, 11.119528211000045],
              [-0.415321167999878, 11.1195287970001],
              [-0.415480110999965, 11.119591115999981],
              [-0.415639053999882, 11.119653436000021],
              [-0.415733935999924, 11.11965362],
              [-0.415893221999966, 11.119543687999965],
              [-0.416671777999966, 11.119561734000058],
              [-0.416778450999914, 11.119597080000119],
              [-0.416964992999908, 11.119463086000053],
              [-0.417072301999895, 11.118826651000063],
              [-0.41715078399983, 11.118653173000098],
              [-0.417374313999915, 11.118513048000125],
              [-0.417578350999861, 11.118418380000151],
              [-0.417754403999936, 11.118229911000071],
              [-0.41784965699992, 11.118042686000138],
              [-0.418025869999951, 11.117839080000181],
              [-0.418590040999902, 11.11760590800003],
              [-0.418729961999929, 11.11740912200014],
              [-0.418869883999946, 11.117212336000023],
              [-0.418978815999935, 11.117122536000011],
              [-0.419266800999935, 11.116885134000086],
              [-0.41930604099997, 11.116798395000103],
              [-0.419345280999949, 11.11671165600012],
              [-0.419284601999891, 11.116350496999985],
              [-0.419346333999954, 11.116179743000089],
              [-0.419410675999927, 11.116099942000176],
              [-0.419528000999946, 11.11603953600013],
              [-0.419645326999898, 11.115979129000038],
              [-0.420094467999945, 11.116054408000139],
              [-0.420365342999958, 11.115961224000046],
              [-0.420633240999848, 11.115961740000103],
              [-0.420809173999942, 11.11589869],
              [-0.420968542999958, 11.11574465800004],
              [-0.421063733999915, 11.11558774700012],
              [-0.421094805999928, 11.115397641000015],
              [-0.421064285999876, 11.115307929000096],
              [-0.421064788999956, 11.11505307900012],
              [-0.421077508999929, 11.114970423000045],
              [-0.421176939999953, 11.114785959000073],
              [-0.421255168999949, 11.114739257999986],
              [-0.421542600999942, 11.114739809000127],
              [-0.421715459999859, 11.114820067000039],
              [-0.421908044999896, 11.11480390100013],
              [-0.42212036899997, 11.11468304300007],
              [-0.422526150999943, 11.114252766000106],
              [-0.422658576999822, 11.114165941000067],
              [-0.42281365599996, 11.114064265000025],
              [-0.422925352999869, 11.114027272000101],
              [-0.423037049999948, 11.113990281000099],
              [-0.423503078999886, 11.113991173000045],
              [-0.423640155999863, 11.113991435000059],
              [-0.423736165999969, 11.113991447000046],
              [-0.423964137999917, 11.113989759999981],
              [-0.425062515999969, 11.113806920000115],
              [-0.425309553999966, 11.113706515999979],
              [-0.425522330999968, 11.113715328000012],
              [-0.425785980999933, 11.113825114000065],
              [-0.425930384999901, 11.113976703000048],
              [-0.42604879299995, 11.114363621000109],
              [-0.426201840999966, 11.114447976000065],
              [-0.426346672999955, 11.11438100100014],
              [-0.426483287999929, 11.114162696000108],
              [-0.426628151999978, 11.11407890900017],
              [-0.426883486999884, 11.114087802000086],
              [-0.427263375999871, 11.114244043000156],
              [-0.428111713999897, 11.114245655000104],
              [-0.429982604999964, 11.108545495000101],
              [-0.431656095999813, 11.103650554000183],
              [-0.4316907799999, 11.102587215000028],
              [-0.431596573999968, 11.100685799000132],
              [-0.43122077299995, 11.09924281799999],
              [-0.430916461999971, 11.098074341000029],
              [-0.430510139999853, 11.09690192100004],
              [-0.430390683999917, 11.096479324000143],
              [-0.430257991999952, 11.095990706000066],
              [-0.430273196999963, 11.095040399000027],
              [-0.430340731999934, 11.094670952000115],
              [-0.430474930999935, 11.094380825000144],
              [-0.43059505999986, 11.094275073000119],
              [-0.430929730999935, 11.09415729900013],
              [-0.431224216999965, 11.093907071000046],
              [-0.431304679999869, 11.093762033000132],
              [-0.431306057999961, 11.093049283000141],
              [-0.431694342999947, 11.092667242000061],
              [-0.431682253999952, 11.092007263000028],
              [-0.431749581999895, 11.091743407000081],
              [-0.431509827999946, 11.091333783000096],
              [-0.431309705999922, 11.091161817999989],
              [-0.431297081999958, 11.090779019000024],
              [-0.431337708999934, 11.090501914000129],
              [-0.431592227999943, 11.090185615000166],
              [-0.43168628199993, 11.089921810000078],
              [-0.431820222999875, 11.08976367300005],
              [-0.431727467999963, 11.089354326000034],
              [-0.432008380999889, 11.089209664000123],
              [-0.43207568299988, 11.088959008000188],
              [-0.432063465999931, 11.088365025000144],
              [-0.432345496999915, 11.08763960400006],
              [-0.432346006999978, 11.087375623000185],
              [-0.432292858999915, 11.087217134000127],
              [-0.431865587999823, 11.087031541000101],
              [-0.431772476999924, 11.086806982000098],
              [-0.431506024999919, 11.086384108000118],
              [-0.431506738999872, 11.086014534000071],
              [-0.431841789999964, 11.085513599000137],
              [-0.431855586999973, 11.085289240000122],
              [-0.432257910999965, 11.084550846000127],
              [-0.432311974999891, 11.084234168000023],
              [-0.432593263999877, 11.083891520000122],
              [-0.432647250999935, 11.083614441000179],
              [-0.432594230999882, 11.08338995600019],
              [-0.432594586999926, 11.083205168000177],
              [-0.432782382999903, 11.082835946000159],
              [-0.432796254999914, 11.082571989000087],
              [-0.433198801999822, 11.081714803000011],
              [-0.433106046999967, 11.081305456],
              [-0.433280503999924, 11.080923008000184],
              [-0.433401304999961, 11.080646054000169],
              [-0.433510214999899, 11.079603527000131],
              [-0.433390429999974, 11.079352519000167],
              [-0.433351229999914, 11.078890476000026],
              [-0.433418374999974, 11.078719014000058],
              [-0.433989348999944, 11.077208330000133],
              [-0.438313385999948, 11.068111356000088],
              [-0.439792553999951, 11.064950102000182],
              [-0.438337253999975, 11.059684054],
              [-0.437410687999886, 11.056590115000063],
              [-0.436269220999918, 11.052689822000048],
              [-0.434846547999825, 11.04783502],
              [-0.435447187999955, 11.047194669000021],
              [-0.435914056999934, 11.046850132000031],
              [-0.436247785999853, 11.046472450000067],
              [-0.43639845499996, 11.046045083000138],
              [-0.436726819999876, 11.045895944000108],
              [-0.437164524999957, 11.045964269000024],
              [-0.437580938999929, 11.045882802000108],
              [-0.437981076999904, 11.045603930000141],
              [-0.438331545999972, 11.045176932000174],
              [-0.438566336999941, 11.044289177000167],
              [-0.438334578999957, 11.043581485000061],
              [-0.434747580999897, 11.038640419000103],
              [-0.433634937999955, 11.037125126999968],
              [-0.435622131999935, 11.034053062000112],
              [-0.437707964999959, 11.030728031000024],
              [-0.437910066999905, 11.030405859000041],
              [-0.438809477999882, 11.030226598000013],
              [-0.44037480399993, 11.03008145900003],
              [-0.441606796999963, 11.030116626000165],
              [-0.442372650999914, 11.030262180000136],
              [-0.443471129999864, 11.030333878000022],
              [-0.444003910999868, 11.030334855000035],
              [-0.444786650999902, 11.030221154000117],
              [-0.445336605999955, 11.029942544000164],
              [-0.445852436999871, 11.029789961000063],
              [-0.446902142999875, 11.029682236000099],
              [-0.448094976999812, 11.030125527000109],
              [-0.448819492999974, 11.030394774000115],
              [-0.448998558999961, 11.030442661000052],
              [-0.44938140499994, 11.030492702],
              [-0.449814381999943, 11.030444144000114],
              [-0.450480818999949, 11.030198631000133],
              [-0.45134713799996, 11.029904135999971],
              [-0.451694295999971, 11.0297583790001],
              [-0.452096727999958, 11.029708115],
              [-0.453412245999914, 11.029595353000104],
              [-0.454793995999978, 11.029680081000038],
              [-0.455592683999953, 11.029944684000156],
              [-0.456092226999886, 11.029912685000056],
              [-0.458875089999935, 11.028601820000063],
              [-0.45951580499991, 11.028441473000044],
              [-0.460624354999879, 11.028012807000096],
              [-0.461107185999879, 11.028013665000174],
              [-0.461489970999935, 11.028096586000174],
              [-0.461839098999974, 11.028376822000098],
              [-0.46203802399998, 11.028854169000056],
              [-0.462486213999966, 11.029595127000107],
              [-0.462884874999872, 11.030105723000077],
              [-0.463283864999937, 11.030435390999969],
              [-0.463633086999891, 11.030666281000094],
              [-0.464482725999915, 11.030491747000042],
              [-0.464699502999906, 11.030483707000087],
              [-0.46503200199993, 11.030471376000037],
              [-0.465464770999915, 11.030537932000016],
              [-0.466644898999959, 11.031642030000057],
              [-0.468440273999931, 11.033191302000034],
              [-0.469322056999943, 11.033554705000142],
              [-0.470720129999904, 11.03383676600015],
              [-0.472168096999894, 11.034151805000022],
              [-0.472650440999928, 11.034432262],
              [-0.473215301999915, 11.035124064000058],
              [-0.473597455999936, 11.035568824999984],
              [-0.474130073999959, 11.035668439000062],
              [-0.474862812999902, 11.03558746900012],
              [-0.475679236999952, 11.035259921000034],
              [-0.476079529999936, 11.034865860000025],
              [-0.476346625999952, 11.034471566000093],
              [-0.476614535999886, 11.03361672900013],
              [-0.477046130999895, 11.0331311650001],
              [-0.477514664999944, 11.033026151000115],
              [-0.478031587999908, 11.032582943000023],
              [-0.479150271999913, 11.030792024000107],
              [-0.479671295999935, 11.028013190000024],
              [-0.479905716999895, 11.027256979000072],
              [-0.480322989999877, 11.026665563000165],
              [-0.481028673999901, 11.026242700000068],
              [-0.481339491999961, 11.026157412000146],
              [-0.481889494999905, 11.02582938900008],
              [-0.481723980999959, 11.025269871000148],
              [-0.481308412999965, 11.024890854000148],
              [-0.480825901999935, 11.02470909900012],
              [-0.480606985999941, 11.024492077000104],
              [-0.48026081099988, 11.024148896000042],
              [-0.479895452999926, 11.023621931000037],
              [-0.479929329999891, 11.023293027000022],
              [-0.480312952999952, 11.022898929000121],
              [-0.480679607999889, 11.022685731000024],
              [-0.481279407999978, 11.022440036000035],
              [-0.481729278999978, 11.022243428000138],
              [-0.482712291999974, 11.021833901999969],
              [-0.483328738999887, 11.021588230000077],
              [-0.484061754999971, 11.021326308000084],
              [-0.484711324999978, 11.021179378],
              [-0.485111187999905, 11.021015576000138],
              [-0.485761241999967, 11.020589028000188],
              [-0.485821157999965, 11.020723825000061],
              [-0.486010005999958, 11.021148685000071],
              [-0.486024713999939, 11.022267183000054],
              [-0.487106876999917, 11.02228546200007],
              [-0.488538954999967, 11.022139847000176],
              [-0.489055615999973, 11.021828203000155],
              [-0.48982308899997, 11.020891950000021],
              [-0.490506857999947, 11.02021872600011],
              [-0.491306376999944, 11.0200062400001],
              [-0.492404899999883, 11.020189010000138],
              [-0.493353723999974, 11.020289284000114],
              [-0.493819968999901, 11.020332336000081],
              [-0.494300754999927, 11.020342807000191],
              [-0.494818863999967, 11.020275277999986],
              [-0.495402111999908, 11.01996373500009],
              [-0.495818783999937, 11.019701257],
              [-0.496553100999904, 11.018666242000108],
              [-0.496864933999973, 11.01836465100007],
              [-0.497009825999953, 11.018224518000125],
              [-0.497486471999878, 11.018059207000022],
              [-0.498852041999896, 11.017847639000138],
              [-0.499301533999869, 11.017864829000132],
              [-0.49956780499997, 11.017931061000127],
              [-0.500017352999862, 11.017915353000035],
              [-0.500533745999974, 11.017751722000071],
              [-0.501117120999879, 11.017357925000113],
              [-0.50175091199992, 11.016684591000171],
              [-0.502135077999924, 11.015945053999985],
              [-0.502269290999948, 11.015336692000119],
              [-0.50153887599987, 11.014068985000108],
              [-0.501506906999907, 11.01327942000006],
              [-0.500825199999895, 11.012751959000013],
              [-0.500609598999915, 11.012258159000055],
              [-0.500593393999907, 11.011994962000188],
              [-0.500610402999882, 11.01178116400007],
              [-0.500794312999915, 11.011320917000091],
              [-0.500994786999911, 11.010910042000148],
              [-0.50116182499994, 11.01058135300002],
              [-0.501362823999898, 11.009857964000105],
              [-0.501421385999947, 11.009506243000089],
              [-0.501480552999965, 11.009150886000043],
              [-0.501481713999965, 11.008460064000133],
              [-0.501432488999967, 11.008032331000152],
              [-0.501459997999916, 11.007531909000136],
              [-0.501683705999881, 11.007144541000059],
              [-0.502330116999815, 11.006632194000019],
              [-0.502517361999935, 11.006405739000058],
              [-0.502135197999962, 11.00596101299999],
              [-0.501403035999942, 11.005745986000079],
              [-0.50102073599993, 11.005383499],
              [-0.501089237999906, 11.004248687000086],
              [-0.501257073999852, 11.003443001000051],
              [-0.501291309999942, 11.002883819000147],
              [-0.501409062999812, 11.002160293000145],
              [-0.50227533799989, 11.00181630000003],
              [-0.503958022999939, 11.00107888299999],
              [-0.504242823999959, 11.000010215000032],
              [-0.504361117999906, 10.998957724000093],
              [-0.504645228999948, 10.998300259000132],
              [-0.505262265999932, 10.997659784000177],
              [-0.505778921999877, 10.997315212000103],
              [-0.505922306999935, 10.997190767000177],
              [-0.507746191999956, 10.995607791000111],
              [-0.508129933999896, 10.995098517000088],
              [-0.508480186999918, 10.99470432600009],
              [-0.508780306999881, 10.994425192000051],
              [-0.509180473999947, 10.99404752900017],
              [-0.509530561999952, 10.993752026000152],
              [-0.510031100999925, 10.993078456000035],
              [-0.510281274999898, 10.992799239000078],
              [-0.510798105999868, 10.992339521000076],
              [-0.511182297999937, 10.991550625000173],
              [-0.511066930999903, 10.990843165000058],
              [-0.51108401099998, 10.990580021000142],
              [-0.511151574999872, 10.989987994000103],
              [-0.51118736299992, 10.989611614000069],
              [-0.511253217999865, 10.988919023000051],
              [-0.511814321999907, 10.98904193400017],
              [-0.512085284999955, 10.989101289000189],
              [-0.512900489999822, 10.989415112000131],
              [-0.51394744199996, 10.990535265000176],
              [-0.515261161999945, 10.991409116999989],
              [-0.516525322999883, 10.992052609000154],
              [-0.516760126999941, 10.990983848000099],
              [-0.517881108999973, 10.991156723000074],
              [-0.51894284399998, 10.991574951000018],
              [-0.520865116999971, 10.99169461800011],
              [-0.522062627999844, 10.991579868000031],
              [-0.522956751999914, 10.991364667000028],
              [-0.523881289999963, 10.993215585000087],
              [-0.524704173999908, 10.995366256000125],
              [-0.525276508999923, 10.996016965000024],
              [-0.525948671999856, 10.99751758400015],
              [-0.525998465999976, 10.998017518000097],
              [-0.526081402999978, 10.998884066000016],
              [-0.526603339999951, 10.999418059000106],
              [-0.527007760999822, 10.999618632000079],
              [-0.529098442999953, 10.99992179100019],
              [-0.529620228999875, 11.000555751999968],
              [-0.530077831999904, 10.999123536000127],
              [-0.530735927999899, 10.998874624000166],
              [-0.531107070999894, 10.998791887000152],
              [-0.531545853999944, 10.998592621000171],
              [-0.532203869999933, 10.998393691000047],
              [-0.532490743999972, 10.998277499000039],
              [-0.532997295999962, 10.997878391000029],
              [-0.533166355999924, 10.997612060000108],
              [-0.533487480999952, 10.997162682000067],
              [-0.533892452999964, 10.997013346000131],
              [-0.534095422999883, 10.996630433000178],
              [-0.534230518999948, 10.996514006000041],
              [-0.534365507999951, 10.996464227000104],
              [-0.535074788999907, 10.995832160000134],
              [-0.535462998999947, 10.995616149000114],
              [-0.535750128999837, 10.995333334000065],
              [-0.535986927999886, 10.994883824000169],
              [-0.536037753999892, 10.994733944000018],
              [-0.536054956999919, 10.994517365000149],
              [-0.535819954999909, 10.993817206000017],
              [-0.535871015999874, 10.993517370000063],
              [-0.535955959999967, 10.993117613000152],
              [-0.535989973999961, 10.992934383999966],
              [-0.536007124999969, 10.992751129],
              [-0.53588933899988, 10.99258433000017],
              [-0.535771708999846, 10.992317560000117],
              [-0.535788884999931, 10.992117643000029],
              [-0.535890301999871, 10.991967840000143],
              [-0.536059407999915, 10.991668184000048],
              [-0.536431216999972, 10.991152231000058],
              [-0.536667671999965, 10.990919325000164],
              [-0.536871375999965, 10.990925966000077],
              [-0.537260247999882, 10.99071328499997],
              [-0.537649417999944, 10.990308658000174],
              [-0.537919966999937, 10.989839870000083],
              [-0.53811469999988, 10.989541584000108],
              [-0.538828128999967, 10.988828202000036],
              [-0.539368440999965, 10.988391811000099],
              [-0.539768298999945, 10.98805118000007],
              [-0.539941129999931, 10.987955468000166],
              [-0.540330193999978, 10.987614821000136],
              [-0.540557317999856, 10.987305918000061],
              [-0.540881574999958, 10.986997160999977],
              [-0.54118419699995, 10.986720363000188],
              [-0.541357520999895, 10.986304742000186],
              [-0.541908584999931, 10.985889690000135],
              [-0.542221799999822, 10.985740871000132],
              [-0.542675466999867, 10.985496288000093],
              [-0.542894787999899, 10.98535233500013],
              [-0.543107529999929, 10.985262338000098],
              [-0.543237335999891, 10.985070586],
              [-0.54332382399997, 10.984974744000056],
              [-0.543593916999839, 10.984793867000121],
              [-0.543799187999923, 10.984655547000045],
              [-0.544097002999877, 10.984430957000143],
              [-0.543950676999941, 10.983808969000165],
              [-0.543919037999899, 10.983674478000125],
              [-0.543817492999949, 10.983561586000064],
              [-0.543688707999934, 10.983045562000143],
              [-0.543630290999829, 10.98279304600004],
              [-0.543632515999946, 10.982460019000087],
              [-0.543677564999939, 10.982280972000126],
              [-0.543715156999838, 10.982139903000075],
              [-0.543758754999885, 10.981976295000038],
              [-0.543890609999949, 10.981629046000023],
              [-0.543904909999981, 10.981429998000067],
              [-0.543922886999951, 10.981166426000129],
              [-0.543900542999893, 10.980938088000016],
              [-0.543817066999907, 10.980730340000093],
              [-0.543747626999959, 10.980554306999977],
              [-0.543967864999956, 10.980378934000157],
              [-0.543987148999975, 10.980188103000046],
              [-0.544005570999957, 10.979997959000173],
              [-0.544042416999901, 10.979617671000142],
              [-0.544137491999948, 10.979463471000145],
              [-0.54435532499997, 10.979292919000102],
              [-0.544411353999976, 10.979135904000145],
              [-0.544411808999939, 10.978838245000134],
              [-0.544136133999928, 10.978553423000164],
              [-0.544100084999968, 10.978391151999972],
              [-0.544100433999915, 10.978163194000103],
              [-0.544102003999967, 10.977961250000135],
              [-0.544137826999815, 10.97788067800019],
              [-0.544140102999904, 10.977760199000102],
              [-0.544074950999914, 10.977501028000063],
              [-0.544009738999875, 10.977280442000108],
              [-0.543756457999962, 10.976921767000022],
              [-0.543758716999889, 10.976683504000107],
              [-0.543989041999851, 10.976225732000046],
              [-0.543989386999954, 10.976000205000105],
              [-0.544025927999883, 10.975926138000148],
              [-0.544123686999967, 10.975731678000045],
              [-0.544163060999949, 10.975520895000102],
              [-0.544201655999927, 10.975314284000149],
              [-0.544276232999891, 10.975164824000046],
              [-0.544417915999873, 10.975094402000082],
              [-0.544679880999979, 10.975002146000065],
              [-0.544727061999879, 10.975159310000095],
              [-0.544858824999949, 10.975603731000035],
              [-0.545022012999937, 10.975765463000073],
              [-0.545423916999823, 10.976026929000056],
              [-0.545926425999937, 10.976269911000088],
              [-0.54591953399995, 10.976667411000108],
              [-0.546089131999963, 10.976748407000059],
              [-0.546371243999943, 10.977245715000151],
              [-0.546509612999955, 10.977196233000143],
              [-0.546698391999939, 10.977066081000032],
              [-0.546893654999906, 10.976805506000062],
              [-0.547032098999921, 10.976706334000028],
              [-0.547151645999975, 10.97663197899999],
              [-0.547415657999863, 10.976632370000118],
              [-0.547560112999975, 10.976713330000052],
              [-0.547748456999955, 10.976868886000148],
              [-0.547911628999941, 10.977043040000183],
              [-0.547968928999978, 10.977108989000044],
              [-0.548106154999971, 10.977266928000063],
              [-0.548156207999853, 10.977422279],
              [-0.548080567999875, 10.977558811000108],
              [-0.548023865999824, 10.977642576000051],
              [-0.548300153999946, 10.977838636000115],
              [-0.54822445699989, 10.978012434000107],
              [-0.548255679999897, 10.978149124000026],
              [-0.548563459999968, 10.978304858000115],
              [-0.548499891999938, 10.978770596000118],
              [-0.548556277999978, 10.97889490099999],
              [-0.548644178999893, 10.978963353000154],
              [-0.548952436999912, 10.978894997],
              [-0.549090534999948, 10.978932959000133],
              [-0.549210026999901, 10.978895869000098],
              [-0.549361438999938, 10.978535850000071],
              [-0.549524988999963, 10.978461559000039],
              [-0.549826604999964, 10.978536538000128],
              [-0.550222616999918, 10.978543334000165],
              [-0.550586915999872, 10.978736416000118],
              [-0.550831996999932, 10.978786467000077],
              [-0.550995292999914, 10.978879874000086],
              [-0.551051661999963, 10.979016601000126],
              [-0.551189851999936, 10.979085127000133],
              [-0.551214798999979, 10.979215596000188],
              [-0.551138791999961, 10.979289596000058],
              [-0.551176588999908, 10.979593847000103],
              [-0.551194220999946, 10.979735783000137],
              [-0.551395271999979, 10.980121740000186],
              [-0.55149738599988, 10.980317769000123],
              [-0.551541092999969, 10.980670246000102],
              [-0.551585186999944, 10.981025850000037],
              [-0.55176625699994, 10.981445416000099],
              [-0.551961049999875, 10.981754386999967],
              [-0.552039098999956, 10.981790331000127],
              [-0.5520385799999, 10.982134844000143],
              [-0.551728489999903, 10.982443073000127],
              [-0.551572170999975, 10.98251725700004],
              [-0.551001656999915, 10.98256947100009],
              [-0.550539511999943, 10.982898832999979],
              [-0.550014617999977, 10.983217767000099],
              [-0.549866336999969, 10.983509696],
              [-0.54986613199992, 10.983644745000106],
              [-0.549963527999978, 10.983799231000091],
              [-0.550233755999955, 10.984028388000127],
              [-0.550387085999944, 10.984086492000131],
              [-0.550562531999901, 10.984276923000152],
              [-0.550517166999953, 10.985176014000047],
              [-0.550510313999951, 10.985311826999975],
              [-0.550504806999925, 10.985420960000056],
              [-0.550490640999897, 10.985701726],
              [-0.55043872899995, 10.985769903000062],
              [-0.550423359999854, 10.985922115000051],
              [-0.550499454999908, 10.986073049000083],
              [-0.550569455999948, 10.986153845000047],
              [-0.550637679999909, 10.986231116000113],
              [-0.55070679499994, 10.986442112000191],
              [-0.550656750999963, 10.98679839000016],
              [-0.550636637999901, 10.986920142000088],
              [-0.550636192999946, 10.987215045000085],
              [-0.55087581499987, 10.987394546000075],
              [-0.551352573999907, 10.987552348000179],
              [-0.55142220599987, 10.987621353000122],
              [-0.551912966999907, 10.987743345000069],
              [-0.552492853999922, 10.987956419000113],
              [-0.552531792999957, 10.98803226900003],
              [-0.552570730999889, 10.988108120000163],
              [-0.552550972999938, 10.988262433000045],
              [-0.552377448999948, 10.988645277000103],
              [-0.552378100999931, 10.989160749000121],
              [-0.552384962999952, 10.989675040000122],
              [-0.55248434899994, 10.990076450000117],
              [-0.552596955999945, 10.990310792000173],
              [-0.552781858999936, 10.990539319000163],
              [-0.552935077999905, 10.990674594000041],
              [-0.553367316999811, 10.990852681000149],
              [-0.553456725999922, 10.990889518000074],
              [-0.553592989999913, 10.990945659000033],
              [-0.55391093399993, 10.990984711000067],
              [-0.554435613999829, 10.990814601000181],
              [-0.554692494999927, 10.990624589000106],
              [-0.555010837999816, 10.990389116000074],
              [-0.555164478999927, 10.990275470000086],
              [-0.555260787999885, 10.990227377999986],
              [-0.555741628999954, 10.990179849000071],
              [-0.556210389999933, 10.990373461000104],
              [-0.556494917999942, 10.990373876000035],
              [-0.5574745399999, 10.990030789000173],
              [-0.558049799999878, 10.989609940000094],
              [-0.558613462999915, 10.989483977000077],
              [-0.558864453999888, 10.989525682000021],
              [-0.559678387999895, 10.989926498000159],
              [-0.559800571999972, 10.989946800000041],
              [-0.560679376999872, 10.990112396000086],
              [-0.561137207999934, 10.990482457000041],
              [-0.561299700999939, 10.990664765000076],
              [-0.561383760999888, 10.99076087899999],
              [-0.56150899499994, 10.990991500000177],
              [-0.561859973999958, 10.991677022000033],
              [-0.561915719999945, 10.991732657000114],
              [-0.562249930999883, 10.992066196999986],
              [-0.562824153999941, 10.992348145000051],
              [-0.56326465699982, 10.992431531000022],
              [-0.563631399999963, 10.992500953000103],
              [-0.563836463999962, 10.99253977],
              [-0.564212948999852, 10.992787648000103],
              [-0.564575307999974, 10.99279714700009],
              [-0.565102718999924, 10.992665609000028],
              [-0.565402015999894, 10.992717035000055],
              [-0.565813683999977, 10.992911919000164],
              [-0.56600071399987, 10.992821234000019],
              [-0.56667684599995, 10.992089071000123],
              [-0.566795575999947, 10.992059923],
              [-0.567090160999953, 10.991995951000035],
              [-0.567441305999978, 10.991996451000091],
              [-0.567525087999968, 10.991968990000032],
              [-0.568000804999826, 10.991814368000064],
              [-0.568034872999874, 10.991512626000144],
              [-0.568808207999837, 10.991356791000101],
              [-0.568810562999943, 10.989734617000181],
              [-0.570729295999968, 10.987466282000184],
              [-0.571608353999977, 10.985088327000142],
              [-0.572977599999945, 10.984333234000133],
              [-0.572595994999972, 10.983305319000067],
              [-0.572707233999949, 10.982061805],
              [-0.573213597999938, 10.980438757],
              [-0.573476188999962, 10.980116268000074],
              [-0.574353575999908, 10.978873826000154],
              [-0.574846875999867, 10.978333788000043],
              [-0.575067166999872, 10.977360788000169],
              [-0.575287224999897, 10.976550005000149],
              [-0.575562006999974, 10.975739298000121],
              [-0.576219395999942, 10.97525355900018],
              [-0.577369686999873, 10.974498140000094],
              [-0.578410753999947, 10.973580347000109],
              [-0.579069204999826, 10.972337585000105],
              [-0.57924753199984, 10.971959451000089],
              [-0.579782755999872, 10.97082453500019],
              [-0.580332433999899, 10.969094966000171],
              [-0.579348634999917, 10.968228446000069],
              [-0.58104977499994, 10.964878279000061],
              [-0.581599433999941, 10.963148706000027],
              [-0.583631743999945, 10.962738661000117],
              [-0.584204789999944, 10.9625411130001],
              [-0.584938703999967, 10.962288106000074],
              [-0.58537746099995, 10.961585756000034],
              [-0.586089824999931, 10.960883778000039],
              [-0.586093505999884, 10.958234217000097],
              [-0.586373419999973, 10.953692481000189],
              [-0.586649197999918, 10.952124743000127],
              [-0.587570183999958, 10.948553347000086],
              [-0.587749437999946, 10.947908550000079],
              [-0.586875346999932, 10.94687998500018],
              [-0.587424710999869, 10.945312615000148],
              [-0.587316617999932, 10.944339159000094],
              [-0.587043622999886, 10.943906207000111],
              [-0.586169095999935, 10.943202076000034],
              [-0.585731721999878, 10.942931119000093],
              [-0.586827667999955, 10.941797073999965],
              [-0.587430468999912, 10.941149014000189],
              [-0.587595669999928, 10.940392217000181],
              [-0.587377843999946, 10.939634902000137],
              [-0.586394041999881, 10.938822480000056],
              [-0.586558870999909, 10.938336047000064],
              [-0.58721578299992, 10.938120644000094],
              [-0.58809126899996, 10.93812182500011],
              [-0.588967349999905, 10.93769042100007],
              [-0.589843798999937, 10.936988651000092],
              [-0.590884842999913, 10.935962662000065],
              [-0.591542338999886, 10.935314666000068],
              [-0.591926542999943, 10.934450012000127],
              [-0.592459251999969, 10.932196934000046],
              [-0.593463408999924, 10.930937320000112],
              [-0.593465911999942, 10.929098843000133],
              [-0.593795825999905, 10.927909677000116],
              [-0.593360011999891, 10.926503201000116],
              [-0.59286889699996, 10.92552923500017],
              [-0.592815654999811, 10.924447705000034],
              [-0.593199691999871, 10.923691194000071],
              [-0.593966366999894, 10.923205556000028],
              [-0.595170977999828, 10.922558277],
              [-0.595969089999869, 10.922208985000111],
              [-0.596156432999919, 10.922126994000166],
              [-0.597852886999931, 10.921912937000116],
              [-0.600315925999894, 10.921267287000148],
              [-0.601246726999932, 10.920781843000157],
              [-0.602122593999979, 10.920458546000134],
              [-0.602342963999945, 10.919323299000041],
              [-0.602763350999965, 10.918382527],
              [-0.603800899999953, 10.918125733000124],
              [-0.603901521999944, 10.918080982000049],
              [-0.604036087999873, 10.918014540000058],
              [-0.604319158999942, 10.917943891000164],
              [-0.604592268999966, 10.917872855000155],
              [-0.604805752999937, 10.917768398000135],
              [-0.605005803999973, 10.917670513000076],
              [-0.605126926999958, 10.917550776000041],
              [-0.605211881999878, 10.917532398000105],
              [-0.605313011999897, 10.91751667200009],
              [-0.605460640999979, 10.917500619000066],
              [-0.60561208199988, 10.916665825999985],
              [-0.605779928999937, 10.916337816000066],
              [-0.605819414999871, 10.916260653000165],
              [-0.606059818999881, 10.916022590000068],
              [-0.606267478999939, 10.91589112600002],
              [-0.60638802699998, 10.915849579000053],
              [-0.606669262999958, 10.915752650000115],
              [-0.606972716999906, 10.91564817],
              [-0.607250346999876, 10.915600920000031],
              [-0.608510740999975, 10.915393235000124],
              [-0.608615031999932, 10.915365375000135],
              [-0.609307977999947, 10.915170342000067],
              [-0.609384763999913, 10.915148748000092],
              [-0.609582058999933, 10.915084124000032],
              [-0.609890844999882, 10.914982982000026],
              [-0.610536336999928, 10.914766921000023],
              [-0.611193325999977, 10.914443085000016],
              [-0.611545613999908, 10.914182703000165],
              [-0.611706652999942, 10.91397422600005],
              [-0.611819108999896, 10.913411827000175],
              [-0.611869984999942, 10.913309788000163],
              [-0.611979972999961, 10.913194075999968],
              [-0.61226168099995, 10.912943354000049],
              [-0.612625576999847, 10.912619810000081],
              [-0.613044951999882, 10.912409394000065],
              [-0.61317760299994, 10.9123389720001],
              [-0.61325722, 10.912251896000043],
              [-0.613415854999971, 10.91205589600014],
              [-0.613544532999924, 10.911929046000125],
              [-0.613653679999913, 10.911824740000043],
              [-0.614099739999972, 10.911825303000171],
              [-0.614297341999873, 10.911825552000096],
              [-0.614528608999933, 10.911671499000022],
              [-0.614856413999973, 10.911675543],
              [-0.614919897999869, 10.911734612000032],
              [-0.615206399999977, 10.911998309000069],
              [-0.615324893999968, 10.912043969000138],
              [-0.615434118999872, 10.912055741000017],
              [-0.615558306999958, 10.912056092000171],
              [-0.615650387999835, 10.912091881000038],
              [-0.615725325999904, 10.912135231000036],
              [-0.615891, 10.912232756000037],
              [-0.615952877999973, 10.912409],
              [-0.616023623999979, 10.912551968000173],
              [-0.616127662999872, 10.912540252999975],
              [-0.616204228999891, 10.912531351000155],
              [-0.6162902129999, 10.912521094999988],
              [-0.616477399999951, 10.912420860000168],
              [-0.61661586699995, 10.912403930000153],
              [-0.616709840999931, 10.912366973000019],
              [-0.617003199999942, 10.912233120000053],
              [-0.617087907999917, 10.912194469000156],
              [-0.617523605999907, 10.911961987000097],
              [-0.617630218999864, 10.911119506000091],
              [-0.617652731999954, 10.911015657000121],
              [-0.61781678199992, 10.910863041000141],
              [-0.617894150999916, 10.910715422000067],
              [-0.617907551999963, 10.910636836000151],
              [-0.617934344999981, 10.91048923],
              [-0.618301398999961, 10.910127268999986],
              [-0.618546714999923, 10.910256950000132],
              [-0.618725120999954, 10.910316218000105],
              [-0.618766752999932, 10.911279980000074],
              [-0.619393827999943, 10.912419414],
              [-0.619775851999918, 10.913197331000163],
              [-0.619817257999898, 10.913780541000108],
              [-0.619268491999946, 10.914446328000054],
              [-0.618634842999938, 10.915570204000119],
              [-0.618633301999978, 10.916778176000093],
              [-0.618927648999943, 10.917320048000136],
              [-0.619390799999962, 10.917695512000137],
              [-0.620105942999885, 10.918779411000173],
              [-0.620569045999844, 10.919196527000054],
              [-0.621704995999949, 10.920822449000127],
              [-0.622126218999881, 10.921031241000151],
              [-0.621998350999888, 10.922155748000023],
              [-0.62212395399996, 10.922822371000052],
              [-0.62262884699993, 10.923531117000039],
              [-0.622501189999923, 10.924489007000091],
              [-0.622499611999956, 10.92573863199999],
              [-0.622929025999952, 10.92704189599999],
              [-0.622876851999933, 10.927405267000154],
              [-0.623507520999965, 10.928655671000058],
              [-0.623463952999828, 10.929780282000081],
              [-0.624010956999939, 10.93053073100009],
              [-0.624262127999941, 10.931905630000074],
              [-0.623795851999944, 10.933987768000065],
              [-0.624636190999979, 10.936113166000041],
              [-0.62556115599989, 10.93798874],
              [-0.626402664999944, 10.939197743000136],
              [-0.627497400999971, 10.940157127000077],
              [-0.629434758999935, 10.941450763000034],
              [-0.630277737999961, 10.941493439000169],
              [-0.631669151999972, 10.941161885999975],
              [-0.632473533999928, 10.941309770000146],
              [-0.634029329999862, 10.941414647000045],
              [-0.635546226999963, 10.941874658000188],
              [-0.636767711999937, 10.94262588800018],
              [-0.638443721999977, 10.943680768000036],
              [-0.639547462999928, 10.944503623000173],
              [-0.64054049899994, 10.944988472999967],
              [-0.641106034999893, 10.945380197000077],
              [-0.641276588999972, 10.946243329000083],
              [-0.641694713999925, 10.946588863000045],
              [-0.642790175999892, 10.947006688999977],
              [-0.648258657999918, 10.950074953000183],
              [-0.647812209999927, 10.950633996000022],
              [-0.647077498999977, 10.951217391000057],
              [-0.645851662999974, 10.952395602000081],
              [-0.645493908999867, 10.953018076999967],
              [-0.645537142999899, 10.9541894890001],
              [-0.645075841999926, 10.955101237000065],
              [-0.645674018999955, 10.956361491000052],
              [-0.646956486999898, 10.956837035000149],
              [-0.648461379999901, 10.957919181000136],
              [-0.649448111999902, 10.958874940999976],
              [-0.649851613999829, 10.959265780000123],
              [-0.650378040999954, 10.959894785000188],
              [-0.651250752999943, 10.960165885000038],
              [-0.651289249999934, 10.960637230999964],
              [-0.651541281999926, 10.960975147000056],
              [-0.651793311999882, 10.961313063000091],
              [-0.652357680999955, 10.962895734000085],
              [-0.653606261999869, 10.963762585000097],
              [-0.655444522999915, 10.963648909000142],
              [-0.655760323999971, 10.963522694000062],
              [-0.656407264999928, 10.963264135999964],
              [-0.657293198999866, 10.961754763000044],
              [-0.657356530999948, 10.961654099999976],
              [-0.65862051199997, 10.959645042000034],
              [-0.659016757999893, 10.959093648000078],
              [-0.659774298999878, 10.958039496000083],
              [-0.660291773999973, 10.956750194000165],
              [-0.661463677999961, 10.9563656360001],
              [-0.661718746999838, 10.956349207000073],
              [-0.662599212999964, 10.956292496000117],
              [-0.662765284999921, 10.956281636000085],
              [-0.663009824999904, 10.956266047000156],
              [-0.663430022999933, 10.956403641000179],
              [-0.66365851899991, 10.956480542000065],
              [-0.664160516999971, 10.956649743000071],
              [-0.664270873999953, 10.95669047199999],
              [-0.664978273999964, 10.956951550000099],
              [-0.666448190999915, 10.957494046000079],
              [-0.666549879999877, 10.957531576000122],
              [-0.667852078999886, 10.95785822199997],
              [-0.668725237999922, 10.957737901000087],
              [-0.67010859699991, 10.957822085000089],
              [-0.670819331999894, 10.95828864200007],
              [-0.671172917999968, 10.958873327000049],
              [-0.671288078999964, 10.959402097000122],
              [-0.671672450999893, 10.961166984000045],
              [-0.670839939999951, 10.962356744000033],
              [-0.670124701999896, 10.963428114000067],
              [-0.670047591999833, 10.96360302100004],
              [-0.669551457999887, 10.964728397000101],
              [-0.66950054299997, 10.965002587000015],
              [-0.669290492999892, 10.966133751000029],
              [-0.668078280999964, 10.967620754000052],
              [-0.667859524999869, 10.96806150000009],
              [-0.667640768999888, 10.968502245000025],
              [-0.667203255999937, 10.969383736000054],
              [-0.666222675999904, 10.970744201000116],
              [-0.665699484999948, 10.97160528],
              [-0.664758346999974, 10.973154224000098],
              [-0.66426239499998, 10.973897837000095],
              [-0.663766443999975, 10.974641450000036],
              [-0.663023851999981, 10.975186346000044],
              [-0.662869880999949, 10.975221297000189],
              [-0.661525568999934, 10.975526448000096],
              [-0.659991646999913, 10.975318029000164],
              [-0.659031069999969, 10.97500137500009],
              [-0.658070491999979, 10.974684721000187],
              [-0.65732996, 10.974699347000126],
              [-0.657278291999944, 10.974700368000128],
              [-0.656679578999899, 10.975146535000022],
              [-0.655413380999846, 10.976090117000126],
              [-0.654734235999968, 10.977230392000024],
              [-0.654727402999924, 10.978310793000162],
              [-0.654673671999944, 10.978941889000168],
              [-0.653215645999978, 10.980615968000052],
              [-0.65248478399991, 10.98156778200007],
              [-0.6522937299999, 10.981816595000168],
              [-0.65241719799991, 10.983575154999983],
              [-0.652880866999908, 10.985433323999985],
              [-0.653166898999871, 10.986533351000162],
              [-0.653670591999969, 10.987564722000172],
              [-0.655081431999918, 10.98812030900001],
              [-0.658955264999975, 10.988799930000027],
              [-0.660051617999898, 10.9888513410001],
              [-0.660701402999962, 10.988881810000066],
              [-0.665007670999898, 10.988521638000122],
              [-0.667547310999907, 10.988475945000118],
              [-0.668735312999956, 10.988769392000165],
              [-0.669059807999815, 10.988956950000045],
              [-0.670606768999903, 10.989851092000151],
              [-0.671171783999966, 10.990177670000151],
              [-0.67244073199987, 10.990435360000163],
              [-0.673194439999975, 10.989948333000086],
              [-0.673409850999917, 10.989386310000043],
              [-0.673684672999855, 10.988531952000073],
              [-0.67393083099995, 10.987933142999964],
              [-0.675115937999976, 10.985762163000118],
              [-0.67575826399991, 10.98460414800013],
              [-0.676998912999977, 10.98324620700015],
              [-0.677724242999943, 10.982583223000177],
              [-0.678524957999969, 10.982451774000026],
              [-0.679623941999921, 10.982793957000013],
              [-0.680079235999926, 10.982860922000157],
              [-0.68091219899992, 10.982944880000161],
              [-0.681636259999891, 10.9832576660001],
              [-0.682705221999868, 10.983942670000147],
              [-0.682975148999901, 10.984115643000166],
              [-0.684059776999959, 10.984563265000077],
              [-0.684453441999949, 10.984725729000161],
              [-0.685036247999903, 10.98496625000007],
              [-0.685144406999882, 10.985010887000158],
              [-0.685396449999871, 10.985262611],
              [-0.685743704999936, 10.985609425000121],
              [-0.685881687999881, 10.986215802000117],
              [-0.686098581999886, 10.987486896000121],
              [-0.685547428999939, 10.989018747000102],
              [-0.686915393999868, 10.990618725000161],
              [-0.686857067999938, 10.990850716000068],
              [-0.686209465999923, 10.993426515000124],
              [-0.685787049999874, 10.994553344],
              [-0.685746399999971, 10.996060915000157],
              [-0.684763561999944, 10.996914304000143],
              [-0.684500323999885, 10.997870414000033],
              [-0.684064325999884, 10.99864168300013],
              [-0.687461743999961, 10.998507712000162],
              [-0.688501239999937, 10.99846694900009],
              [-0.690173953999931, 10.998440326000093],
              [-0.694026279999889, 10.99837901300009],
              [-0.696481192999897, 10.998320838000154],
              [-0.698076004999962, 10.998291420000044],
              [-0.70200192499982, 10.99821880900015],
              [-0.703448710999965, 10.998191974000179],
              [-0.707235321999917, 10.998121916000059],
              [-0.710489443999961, 10.998074026000154],
              [-0.71327619799996, 10.998033014000157],
              [-0.713807943999939, 10.998024913999984],
              [-0.716263864999974, 10.997947034000163],
              [-0.720221393999964, 10.997821535000128],
              [-0.72398730599997, 10.997702112000127],
              [-0.724710823999885, 10.99769165500004],
              [-0.730450908999956, 10.99760869000005],
              [-0.732215542999938, 10.997586851000108],
              [-0.732883472999958, 10.997578451000095],
              [-0.733983262999971, 10.997564831000034],
              [-0.734074711999938, 10.997561978000078],
              [-0.734192803999917, 10.997558053000034],
              [-0.734299633999967, 10.997554535000063],
              [-0.734547659999976, 10.997546367000098],
              [-0.734688991999917, 10.997541710000178],
              [-0.734775418999902, 10.997538873000053],
              [-0.735683552999888, 10.997508940000102],
              [-0.735801257999867, 10.99750505500009],
              [-0.735899419999896, 10.997501818000046],
              [-0.736253395999881, 10.997490440000036],
              [-0.740689492999934, 10.997343833000059],
              [-0.74584940099993, 10.99717364899999],
              [-0.746285106999892, 10.997159279000073],
              [-0.748901409999917, 10.997205389000158],
              [-0.749850722999952, 10.997226796000064],
              [-0.750076112999977, 10.997239592000028],
              [-0.750395258999902, 10.997242160999974],
              [-0.75097941699994, 10.997246534000112],
              [-0.752450103999934, 10.997258706000025],
              [-0.753504517999943, 10.99726719500012],
              [-0.753899121999893, 10.997290883000119],
              [-0.754824633999931, 10.99734612400016],
              [-0.755137151999918, 10.997365200000161],
              [-0.755273041999885, 10.997373358000118],
              [-0.757040232999941, 10.997207400000036],
              [-0.759311172999958, 10.997348004000116],
              [-0.762445119999938, 10.997270774000128],
              [-0.765712762999954, 10.997365813000101],
              [-0.766049297999871, 10.997381840000116],
              [-0.775789037999914, 10.997686736999981],
              [-0.77685053099998, 10.997679235000078],
              [-0.778995290999887, 10.997664077000081],
              [-0.779368463999958, 10.997661439000069],
              [-0.780016682999928, 10.997656858000084],
              [-0.785690754999848, 10.997669179000127],
              [-0.787556994999875, 10.997689777000062],
              [-0.789423233999855, 10.997710373000189],
              [-0.790979798999956, 10.997769327000071],
              [-0.792101097999819, 10.997756806000041],
              [-0.794003769999961, 10.997735561000127],
              [-0.797236913999939, 10.99777356400017],
              [-0.799435125999935, 10.997786038000129],
              [-0.800058541999817, 10.997789576000059],
              [-0.801549796999836, 10.997798039000145],
              [-0.802723527999945, 10.997843321000175],
              [-0.80524458199983, 10.997946236000075],
              [-0.807527653999898, 10.998039332000133],
              [-0.807535641999948, 10.998281341000165],
              [-0.807588331999966, 10.9998775090001],
              [-0.807616056999848, 11.000717010000187],
              [-0.80766898, 11.002320447000045],
              [-0.807721902999901, 11.003923883000027],
              [-0.807567232999872, 11.00583105700008],
              [-0.809025495999947, 11.005842078000057],
              [-0.809556278999878, 11.005846090000034],
              [-0.809778367999968, 11.00586488700003],
              [-0.810038291999888, 11.005886885999985],
              [-0.810520305999887, 11.005927681000117],
              [-0.811032429999898, 11.005971025000122],
              [-0.812241286999893, 11.005966274000173],
              [-0.814288937999947, 11.005948241],
              [-0.815160725999874, 11.005937749000054],
              [-0.815790543999924, 11.006013154000129],
              [-0.816463373999909, 11.006093708000094],
              [-0.817497607999883, 11.006217531000118],
              [-0.818145484999945, 11.006295097000134],
              [-0.818744331999937, 11.006320385000095],
              [-0.81986668899998, 11.006367783000087],
              [-0.821138463999944, 11.006368529000156],
              [-0.822944039999925, 11.006369590000133],
              [-0.823344113999838, 11.006375049000155],
              [-0.824844024999948, 11.006413179000106],
              [-0.827262101999906, 11.006529179000154],
              [-0.827633475999903, 11.004042675000107],
              [-0.82769859799987, 11.003601927000034],
              [-0.827807488999895, 11.002808571000116],
              [-0.827926260999959, 11.001943224],
              [-0.828233450999903, 10.999705106000135],
              [-0.828421780999918, 10.998332972000071],
              [-0.82993859599992, 10.99841444000009],
              [-0.830555264999873, 10.998440389],
              [-0.83120702399998, 10.998433301000148],
              [-0.832160008999892, 10.998423256000081],
              [-0.833110112999975, 10.998412604000123],
              [-0.834561831999849, 10.998396816000025],
              [-0.834780933999923, 10.998394434000033],
              [-0.835817029999873, 10.998383165000064],
              [-0.836638003999951, 10.998374236000132],
              [-0.837202072999901, 10.998368347999985],
              [-0.837459708999972, 10.998371939000037],
              [-0.837534823999874, 10.998373050000055],
              [-0.837719173999972, 10.998375770999985],
              [-0.837886977999972, 10.998378246000129],
              [-0.838072482999962, 10.998380984000164],
              [-0.838944235999975, 10.99839385100006],
              [-0.843759114999841, 10.998443218000148],
              [-0.844789561999903, 10.998457249000182],
              [-0.848140317999878, 10.998502871000028],
              [-0.849320158999888, 10.998518935000106],
              [-0.851672705999931, 10.998550967000085],
              [-0.851975563999929, 10.998553205000121],
              [-0.852557267999941, 10.998508295000136],
              [-0.853080906999935, 10.998467852999966],
              [-0.85504045599987, 10.998316510000166],
              [-0.855105762999926, 10.998200581000049],
              [-0.858248460999903, 10.992629267000041],
              [-0.85889758899998, 10.991462325000157],
              [-0.859497988999919, 10.990382987000032],
              [-0.859546718999979, 10.990295384000149],
              [-0.860105799999928, 10.989368383000055],
              [-0.860797745999889, 10.988221079000027],
              [-0.862491107999972, 10.985228078000091],
              [-0.862860569999953, 10.98456403800003],
              [-0.864893327999937, 10.980168835000086],
              [-0.866134058999933, 10.977348355000117],
              [-0.866520272999878, 10.976485396000044],
              [-0.867358352999872, 10.974616351],
              [-0.867677210999886, 10.973905250000087],
              [-0.868293292999965, 10.97269723900007],
              [-0.868664631999934, 10.971969118000118],
              [-0.869341129999953, 10.970642644000122],
              [-0.870012286999895, 10.966731903000095],
              [-0.871351255999855, 10.966534025000101],
              [-0.874868843999934, 10.965975984000181],
              [-0.875110594999967, 10.965931849000185],
              [-0.875137198999937, 10.96592699200005],
              [-0.876062795999928, 10.965758010000116],
              [-0.877580277999925, 10.965480970999977],
              [-0.878354685999966, 10.965373049000107],
              [-0.881248447999951, 10.964969770000096],
              [-0.882220217999873, 10.964780614000063],
              [-0.882472288999963, 10.964731548000088],
              [-0.882658528999968, 10.964695296000116],
              [-0.882843071999957, 10.964659014000119],
              [-0.884233772999949, 10.96438867300003],
              [-0.885414645999958, 10.964158814000143],
              [-0.885736320999911, 10.964096200000085],
              [-0.885828713999899, 10.964084983000134],
              [-0.885918011999934, 10.96407460000006],
              [-0.88654389099986, 10.964001833],
              [-0.886843918999887, 10.963966950000042],
              [-0.887317968999866, 10.963911835000033],
              [-0.887396520999971, 10.963902702000041],
              [-0.887484025999925, 10.963883561000159],
              [-0.888138537999907, 10.963740395000059],
              [-0.888685486999862, 10.96362075799999],
              [-0.889202609999927, 10.96350764400006],
              [-0.889800073999879, 10.963376956000104],
              [-0.890038213999958, 10.963324867000154],
              [-0.890654246999929, 10.963205195000171],
              [-0.891110700999889, 10.96311652300011],
              [-0.891577914999971, 10.963025761999972],
              [-0.892594271999883, 10.962828285000057],
              [-0.892728043999966, 10.963323583000033],
              [-0.892534624999939, 10.963568316000135],
              [-0.892500937999898, 10.963852183000085],
              [-0.892436652999891, 10.96403959100013],
              [-0.892269341999906, 10.964334482000083],
              [-0.892121399999951, 10.964383952000105],
              [-0.891630414999895, 10.964383814000087],
              [-0.891471524999872, 10.964477518000024],
              [-0.891011138999886, 10.964570946000094],
              [-0.890629073999889, 10.964570808000076],
              [-0.890439304999916, 10.964540493000186],
              [-0.890266378999911, 10.964460431000134],
              [-0.889789429999894, 10.964396905],
              [-0.889599941999961, 10.964429977000066],
              [-0.889345886999877, 10.964600711000173],
              [-0.889105867999831, 10.964878377000048],
              [-0.888955399999816, 10.965052555000057],
              [-0.888790615999937, 10.965501780000011],
              [-0.888712012999918, 10.966868881000096],
              [-0.888736996999967, 10.96707282300008],
              [-0.888870621999899, 10.967671004000124],
              [-0.888820371999941, 10.968048571000168],
              [-0.888750471999913, 10.968224952000128],
              [-0.888580072999844, 10.969189535000055],
              [-0.888401251999881, 10.969652532999987],
              [-0.887996728999894, 10.970052007000049],
              [-0.887949286999969, 10.970225631000176],
              [-0.887993079999944, 10.971382984000115],
              [-0.88801273099989, 10.971901373000094],
              [-0.888073927999869, 10.972058460000028],
              [-0.888124177999941, 10.972447183000156],
              [-0.888567159999923, 10.973687617999985],
              [-0.888597758999879, 10.973875019000161],
              [-0.88887090299994, 10.974429092999969],
              [-0.888960733999966, 10.974812438000185],
              [-0.888909641999931, 10.975360722000062],
              [-0.888800721999871, 10.975683161000177],
              [-0.888345668999875, 10.976413332000106],
              [-0.888278856999875, 10.976694432000102],
              [-0.888249380999923, 10.977376235000122],
              [-0.888211201999979, 10.978262797000127],
              [-0.888659797999935, 10.979233134000026],
              [-0.888849286999914, 10.979767906],
              [-0.888880165999979, 10.979922234000185],
              [-0.888832442999956, 10.980346634000114],
              [-0.888865849999945, 10.980517496000061],
              [-0.888974489999953, 10.980768415000114],
              [-0.889102779999916, 10.980942033000076],
              [-0.889847258999964, 10.98149085700004],
              [-0.890195917999961, 10.981678391000116],
              [-0.890324207999925, 10.981918149000023],
              [-0.898593199999937, 10.98166089200015],
              [-0.910557917999938, 10.981050198999981],
              [-0.911285552999914, 10.981036558000028],
              [-0.911054517999901, 10.980458797000097],
              [-0.916647420999936, 10.980306955000117],
              [-0.916641812999899, 10.985899649000146],
              [-0.916635129999975, 10.990986738000117],
              [-0.916517936999924, 10.994236391000186],
              [-0.916367, 10.999324239000146],
              [-0.916548907999925, 11.002245723000101],
              [-0.92143281299991, 11.002312674000052],
              [-0.925303437999958, 11.002182350000112],
              [-0.929389918999959, 11.00246233900009],
              [-0.93375434, 11.002450922000094],
              [-0.935170895999931, 11.002447216000178],
              [-0.939074999999889, 11.002527246000056],
              [-0.943510122999953, 11.002432467000119],
              [-0.946383997999874, 11.002432988000066],
              [-0.947629892999942, 11.002466030000107],
              [-0.951101814999902, 11.002384546000144],
              [-0.953095271999814, 11.002269972000079],
              [-0.957132042999945, 11.00187666700009],
              [-0.960620626999855, 11.001351937000038],
              [-0.962298482999927, 11.000958249000178],
              [-0.967921888999911, 10.99992840800013],
              [-0.971833907999951, 10.999039013000186],
              [-0.973677838999834, 10.998825815000089],
              [-0.976418818999946, 10.998415725000086],
              [-0.977531803999852, 10.998465048000185],
              [-0.980050856999981, 10.998371501000179],
              [-0.981502028999898, 10.998317602000043],
              [-0.982889117999832, 10.998286318000169],
              [-0.986947810999936, 10.99819474200001],
              [-0.987514957999963, 10.998180726000101],
              [-0.992399508999881, 10.998059974000057],
              [-0.992605846999879, 10.998053361000132],
              [-1.000116863999892, 10.997835653999971],
              [-1.000224661999937, 11.001185024000165],
              [-1.000240100999974, 11.002651025000034],
              [-1.000265927999976, 11.004306743000086],
              [-1.000281928999868, 11.007885718000182],
              [-1.006944340999951, 11.008147361000056],
              [-1.015891560999933, 11.007801809999989],
              [-1.035390054999937, 11.006432419000134],
              [-1.049139612999852, 11.005366138000113],
              [-1.0516596679999, 11.005143485000076],
              [-1.054862442999934, 11.005080382000074],
              [-1.062752738999905, 11.005077902000039],
              [-1.065031652999892, 11.005054754000071],
              [-1.066258398999878, 11.005050255000128],
              [-1.071906290999834, 11.005029541000056],
              [-1.080154789999938, 11.004920832000039],
              [-1.08326071599987, 11.00483086100013],
              [-1.086176028999944, 11.004586438000047],
              [-1.086596833999863, 11.004663457000106],
              [-1.090341965999926, 11.004544553000073],
              [-1.114784563999933, 11.003913378000163],
              [-1.114754190999918, 11.000914304000162],
              [-1.114601531999881, 10.985840629000052],
              [-1.11908637099998, 10.985752031000175],
              [-1.121466251999891, 10.985905217000038],
              [-1.135179969999911, 10.986787931000094],
              [-1.138609569999915, 10.987133919000144],
              [-1.146876034999821, 10.98747756500012],
              [-1.153853166999966, 10.988145295000152],
              [-1.157797352999978, 10.988591996000082],
              [-1.167137910999941, 10.988840998000057],
              [-1.175656899999865, 10.989013056999966],
              [-1.181654620999893, 10.989224136000132],
              [-1.183875956999941, 10.989235606000022],
              [-1.188167377999889, 10.989298712999982],
              [-1.205763970999954, 10.989621550000152],
              [-1.231716149999897, 10.990040577000116],
              [-1.250498106999942, 10.990343833000168],
              [-1.256692271999896, 10.990210730000058],
              [-1.263700814999879, 10.990279624000095],
              [-1.266178480999827, 10.99020053400011],
              [-1.276996442999973, 10.990965116000098],
              [-1.301378964999969, 10.99034204100019],
              [-1.305768357999966, 10.990143903000103],
              [-1.31446236599993, 10.989549074000024],
              [-1.32100406599983, 10.989605842000117],
              [-1.327783538999881, 10.989288517000034],
              [-1.337504994999904, 10.988415905000124],
              [-1.35438518199993, 10.988862476000065],
              [-1.370546434999937, 10.988792342000124],
              [-1.370883864999883, 10.988913871000136],
              [-1.370973134999929, 10.988908222000134],
              [-1.371056789999898, 10.98885296900005],
              [-1.37117385199997, 10.988748112],
              [-1.371251611999924, 10.988618453000015],
              [-1.371345935999955, 10.988119522000034],
              [-1.371538230999931, 10.987926342000037],
              [-1.372004231999938, 10.988002953000091],
              [-1.372235547999821, 10.98787040000002],
              [-1.372313308999935, 10.987754520000067],
              [-1.372544905999916, 10.98759992100014],
              [-1.373010625999825, 10.987524964000045],
              [-1.373476065999967, 10.987273499000139],
              [-1.373707381999907, 10.987215352000021],
              [-1.373938978999888, 10.987234367000099],
              [-1.374405260999936, 10.987407430000076],
              [-1.374636576999876, 10.987426445000153],
              [-1.374870980999901, 10.987503331000141],
              [-1.374985515999867, 10.987577599000076],
              [-1.375219919999836, 10.987541497999985],
              [-1.375258659999929, 10.98742575599999],
              [-1.375141036999878, 10.987061993000054],
              [-1.375160406999953, 10.98683326400004],
              [-1.375798490999898, 10.986352930000066],
              [-1.376345059999949, 10.986173115000042],
              [-1.376937386999941, 10.986122822000027],
              [-1.377201266999975, 10.986100363000048],
              [-1.377530555999954, 10.986155065000105],
              [-1.377751203999935, 10.986299468000084],
              [-1.377971571999979, 10.986443871000063],
              [-1.378094247999968, 10.986402397000177],
              [-1.378306755999972, 10.986713525000141],
              [-1.378518982999935, 10.986906292000015],
              [-1.37886792199987, 10.987057446000108],
              [-1.379004915999929, 10.987288794000051],
              [-1.379122257999939, 10.987401643000055],
              [-1.379353573999822, 10.987401367000018],
              [-1.379819574999829, 10.98755514000004],
              [-1.379914459999952, 10.987516421000123],
              [-1.380109562999905, 10.987287416000129],
              [-1.380223816999944, 10.987210116000142],
              [-1.380458220999913, 10.987287003000176],
              [-1.380642094999928, 10.987176496000188],
              [-1.380733891999967, 10.986909047000154],
              [-1.380892780999886, 10.986856412000179],
              [-1.381233579999957, 10.987189447999981],
              [-1.381435700999873, 10.987256827000181],
              [-1.38163810199984, 10.987324068000135],
              [-1.381853135999961, 10.987494650000031],
              [-1.38222144599996, 10.987590689000058],
              [-1.382316329999981, 10.987667713000064],
              [-1.383084389999965, 10.988388898000153],
              [-1.383185169999933, 10.988683627000114],
              [-1.383578743999976, 10.988793444],
              [-1.383810621999828, 10.988966782000148],
              [-1.38404221899998, 10.989043668000136],
              [-1.384159279999892, 10.989117935000138],
              [-1.384390876999873, 10.989175393000096],
              [-1.384625280999899, 10.989155827000104],
              [-1.38485687799988, 10.989194132000137],
              [-1.385088193999934, 10.989116695000064],
              [-1.385205255999949, 10.989042152000025],
              [-1.385436852999931, 10.989041876000158],
              [-1.385913521999896, 10.988884108999969],
              [-1.386483109999972, 10.989269226000147],
              [-1.386717513999827, 10.989307532000112],
              [-1.386949110999979, 10.989268675000176],
              [-1.387415111999815, 10.989422447000095],
              [-1.387646708999966, 10.989422171000115],
              [-1.387995085999933, 10.989305878000152],
              [-1.388226682999971, 10.989286312000104],
              [-1.388461087999929, 10.989401778000115],
              [-1.388692964999848, 10.989610939999977],
              [-1.38881002699992, 10.989649383000085],
              [-1.388924561999829, 10.989610665000043],
              [-1.389002321999953, 10.989494785000034],
              [-1.389100013999951, 10.989456067000049],
              [-1.389331610999932, 10.989455791000012],
              [-1.389565734999962, 10.98936182000017],
              [-1.389679988999887, 10.989380972000163],
              [-1.389914673999954, 10.989493683000148],
              [-1.390146270999935, 10.98953198800001],
              [-1.390402009999946, 10.989459925],
              [-1.390475278999872, 10.989418589000138],
              [-1.390514018999966, 10.989283419000174],
              [-1.390686663999873, 10.98907370500001],
              [-1.390921067999898, 10.989034849],
              [-1.391054972999882, 10.989186278000034],
              [-1.391442370999869, 10.988841257000161],
              [-1.391790748999938, 10.988689276000116],
              [-1.392025152999963, 10.988763269000117],
              [-1.39208129799988, 10.988879011000108],
              [-1.392120598999952, 10.98910773900019],
              [-1.392467011999941, 10.989490376000163],
              [-1.392798826999922, 10.989357686000176],
              [-1.392876868999906, 10.989451244000122],
              [-1.392877710999869, 10.989911593000102],
              [-1.393053443999861, 10.989969188000146],
              [-1.393421471999943, 10.989847384000029],
              [-1.39357502799993, 10.98992991900019],
              [-1.393770691999976, 10.990158371000064],
              [-1.39388494699989, 10.990216104000126],
              [-1.394119350999858, 10.990177248000123],
              [-1.39428449199994, 10.990286605000051],
              [-1.394468289999963, 10.990408317000174],
              [-1.394758557999978, 10.990501599000027],
              [-1.394931483999926, 10.990446346],
              [-1.395397484999933, 10.990655232000051],
              [-1.395629081999971, 10.99061623800003],
              [-1.395746143999872, 10.990558230000033],
              [-1.396006093999915, 10.99052461000008],
              [-1.396123716999966, 10.990608109000163],
              [-1.396393772999943, 10.99087293600013],
              [-1.396782013999882, 10.991254193000145],
              [-1.39708491399989, 10.991547403000084],
              [-1.397388095999929, 10.99184047600005],
              [-1.398142118999886, 10.992451283999969],
              [-1.398862455999904, 10.99312009900018],
              [-1.400118412999973, 10.994502641000167],
              [-1.400488405999965, 10.994895743000029],
              [-1.401699447999874, 10.996044733000019],
              [-1.40291020799998, 10.997193855000091],
              [-1.403017724999813, 10.99731634599999],
              [-1.403125241999931, 10.997438836000185],
              [-1.404566195999962, 10.998638657000072],
              [-1.40592658199995, 10.999824696000076],
              [-1.406965539999874, 11.00078518000015],
              [-1.407856556999946, 11.001652244000127],
              [-1.408898321999914, 11.002510762999975],
              [-1.410719093999944, 11.00502981600016],
              [-1.422821365999937, 11.020262167000055],
              [-1.42260380599987, 11.017796149000105],
              [-1.422624298999892, 11.017646250999974],
              [-1.422839051999972, 11.016067349000082],
              [-1.423387585999876, 11.015153758000054],
              [-1.42468284399996, 11.014636961000065],
              [-1.42557245699993, 11.014351902000158],
              [-1.4264620699999, 11.014066705000118],
              [-1.426794049999955, 11.014004330000034],
              [-1.428070895999952, 11.013764424000044],
              [-1.429066902999978, 11.013468893000038],
              [-1.428562162999924, 11.01168729800014],
              [-1.42853380899993, 11.011067574000037],
              [-1.451125236999872, 11.010888371000021],
              [-1.544101909999938, 11.010150847000091],
              [-1.544587108999906, 11.01869131],
              [-1.545810859999904, 11.040231776999974],
              [-1.744524602999888, 11.037957536000022],
              [-1.743387474999963, 11.008107828000107],
              [-1.856336622999891, 11.006082327000115],
              [-1.880803698999955, 11.005744810000181],
              [-2.006304291999868, 11.004013560000089],
              [-2.035202604999881, 11.011243414000035],
              [-2.057961405999947, 11.017482635000135],
              [-2.082819242999904, 11.01458714099999],
              [-2.118365984999969, 11.014284837000048],
              [-2.172024330999932, 11.018645833000107],
              [-2.186628809999945, 11.019177901000035],
              [-2.220160518999876, 11.020664172000011],
              [-2.250247782999907, 11.018039236999982],
              [-2.272443179999868, 11.016769373999978],
              [-2.30013156799987, 11.011058423000065],
              [-2.315325293999877, 11.010256992000109],
              [-2.336773015999881, 11.008691354000064],
              [-2.347328835999917, 11.008304528000053],
              [-2.350477785999885, 11.008189132999973],
              [-2.360161861999927, 11.008145919000128],
              [-2.377596517999905, 11.008803321000187],
              [-2.410410047999903, 11.016453985000112],
              [-2.452309498999966, 11.02332156500006],
              [-2.497725542999888, 11.018243491000021],
              [-2.537652987999934, 11.018190982000021],
              [-2.555381384999976, 11.018100628000184],
              [-2.582891683999947, 11.021621426000024],
              [-2.617970247999892, 11.021305698000162],
              [-2.619303940999942, 11.020038763000059],
              [-2.622881358999962, 11.019437414000151],
              [-2.626823231999822, 11.019346352000071],
              [-2.632481678999909, 11.019346266000071],
              [-2.63738155599998, 11.019344659000183],
              [-2.653132491999884, 11.019341900000029],
              [-2.680308723999872, 11.019338630000107],
              [-2.683857696999894, 11.019375898000021],
              [-2.702312961999951, 11.019403823000118],
              [-2.724529098999938, 11.019437438000125],
              [-2.739603975999955, 11.019375911000111],
              [-2.750506166999969, 11.019242723000104],
              [-2.751602347999892, 11.019314384000097],
              [-2.754986502999941, 11.019191325000122],
              [-2.757509848999916, 11.019044511000061],
              [-2.759478200999979, 11.018699085000037],
              [-2.761708173999921, 11.017973275000031],
              [-2.761918460999937, 11.017374225000026],
              [-2.762058642999875, 11.017075875000046],
              [-2.76248979099995, 11.016527888000098],
              [-2.762705243999903, 11.016321391000133],
              [-2.762920695999981, 11.016114895000101],
              [-2.763339627999926, 11.015886554000133],
              [-2.763700600999925, 11.015496006000149],
              [-2.763933503999851, 11.01524946100011],
              [-2.764166405999845, 11.015002915000139],
              [-2.764667395999823, 11.014462784000102],
              [-2.765022275999968, 11.014256853],
              [-2.765377155999943, 11.01405092300007],
              [-2.766365838999946, 11.013709065000171],
              [-2.766784931999951, 11.013399447000154],
              [-2.767204025999888, 11.013089830000183],
              [-2.767855813999915, 11.012631467],
              [-2.768094946999895, 11.012196168000173],
              [-2.768334080999978, 11.011760869000113],
              [-2.768730652999921, 11.011014719000173],
              [-2.768976594999913, 11.010027010999977],
              [-2.76902976599996, 11.009591418000127],
              [-2.769082934999858, 11.009155824000118],
              [-2.769084383999882, 11.008420242],
              [-2.768876270999897, 11.007869085000152],
              [-2.768668159999891, 11.007317927000031],
              [-2.768216971999948, 11.006284690000086],
              [-2.768309261999946, 11.004800022000154],
              [-2.769351116999928, 11.004677450000145],
              [-2.771496113999945, 11.00467745100002],
              [-2.772154712999907, 11.004621556000131],
              [-2.772202154999945, 11.004617529000029],
              [-2.777470587999915, 11.004485495000154],
              [-2.78187236399998, 11.004375154000172],
              [-2.786812977999944, 11.004251300000021],
              [-2.805937390999929, 11.003770415000133],
              [-2.811765146999903, 11.003623848000188],
              [-2.81666687399985, 11.003730388000122],
              [-2.827148420999947, 11.003958207000039],
              [-2.830732183999942, 11.004036100000178],
              [-2.833997490999934, 11.004090181000095],
              [-2.834012904999952, 11.00385543800013],
              [-2.834162255999956, 11.00158087900013],
              [-2.8341788809999, 11.001091788999986],
              [-2.834294117999946, 11.000380338000014],
              [-2.834559203999959, 10.999059156000158],
              [-2.834600515999966, 10.998774340000011],
              [-2.834823958999891, 10.997233834000099],
              [-2.835053268999957, 10.995270984000058],
              [-2.835116288999927, 10.994707233000042],
              [-2.835179306999976, 10.994143482000027],
              [-2.835270863999938, 10.993167706000122],
              [-2.83533945899984, 10.992445516000032],
              [-2.83561597299996, 10.990859799000191],
              [-2.8357428569999, 10.990137257000129],
              [-2.835949451999966, 10.98953780700009],
              [-2.836032087999968, 10.989298031000146],
              [-2.836402291999946, 10.988160790999984],
              [-2.836610373999918, 10.98740194700008],
              [-2.836864162999973, 10.98633428100004],
              [-2.837013634999892, 10.985256225],
              [-2.837163252999915, 10.984244286000035],
              [-2.837312911999959, 10.983246120000047],
              [-2.837381206999908, 10.982292524],
              [-2.837463449999973, 10.981671011],
              [-2.837507439999968, 10.981338578000134],
              [-2.837645897999892, 10.980684838000059],
              [-2.837807879999957, 10.980143907000127],
              [-2.838247514999921, 10.978915337000103],
              [-2.838780143999941, 10.977628353],
              [-2.839196704999949, 10.976501852000183],
              [-2.839671237999937, 10.975240012000029],
              [-2.840076347999911, 10.973911550000082],
              [-2.840249443999937, 10.973343927000087],
              [-2.840480449999859, 10.972469239000191],
              [-2.840629762999868, 10.971184570000048],
              [-2.840650774999915, 10.969807012000103],
              [-2.840658404999942, 10.969712963000063],
              [-2.840696390999938, 10.969244744000093],
              [-2.840718896999874, 10.968784545000119],
              [-2.840635692999911, 10.967545358],
              [-2.840545580999901, 10.967241518000094],
              [-2.840227093999943, 10.966167638000059],
              [-2.84002883099987, 10.965799683000057],
              [-2.839807261999852, 10.965318919000083],
              [-2.839713390999975, 10.964699641000038],
              [-2.839362790999928, 10.963365647000103],
              [-2.838896735999981, 10.962437312000134],
              [-2.838313705999894, 10.961118490999979],
              [-2.837928356999896, 10.960593512000173],
              [-2.83782448799991, 10.960452007000129],
              [-2.836905320999904, 10.959545683000101],
              [-2.8355324179999, 10.958355579],
              [-2.834938897999905, 10.957769608000092],
              [-2.834066093999979, 10.956876768000143],
              [-2.83329828899997, 10.956198174000122],
              [-2.832751660999975, 10.95587225],
              [-2.832205032999923, 10.955546327000093],
              [-2.830786512999964, 10.954951524000023],
              [-2.829728368999895, 10.954426180000041],
              [-2.828937678999864, 10.954012178000085],
              [-2.827311409999936, 10.95221061400008],
              [-2.827145133999977, 10.952028380000058],
              [-2.825725504999923, 10.95051617900009],
              [-2.824817849999874, 10.949529846000132],
              [-2.824410564999937, 10.94909976200006],
              [-2.824003278999953, 10.948669679000091],
              [-2.823328113999878, 10.947946421000097],
              [-2.822804532999896, 10.947442648000106],
              [-2.822502109999846, 10.947224062000146],
              [-2.822292737999931, 10.946949821999965],
              [-2.822082780999949, 10.946444174000078],
              [-2.821383740999863, 10.945148034000113],
              [-2.820637960999875, 10.943772278000154],
              [-2.819986236999966, 10.942911126000126],
              [-2.819741672999953, 10.942579240000043],
              [-2.819403690999877, 10.941878754000186],
              [-2.818820746999961, 10.940502022000089],
              [-2.818378311999879, 10.939722264000068],
              [-2.818063405999851, 10.939054697000188],
              [-2.817900061999922, 10.938468874000137],
              [-2.817852134999953, 10.937551777000181],
              [-2.817816720999929, 10.937115336000033],
              [-2.817781305999972, 10.936678895000057],
              [-2.817826582999885, 10.935874193000075],
              [-2.817814417999955, 10.935478937000084],
              [-2.817802253999901, 10.935083681000037],
              [-2.817742759999874, 10.934061966000172],
              [-2.817532477999976, 10.933313882000107],
              [-2.817357289999961, 10.932741904000181],
              [-2.817134775999932, 10.93150076600017],
              [-2.817046723999908, 10.930383934000133],
              [-2.816992794999976, 10.929699902000038],
              [-2.816920827999979, 10.928044631000091],
              [-2.817000088999976, 10.926495903000045],
              [-2.817207370999881, 10.925334857000109],
              [-2.817519018999917, 10.923773729000061],
              [-2.818005028999892, 10.922371349],
              [-2.818282651999937, 10.921590062000121],
              [-2.819175335999944, 10.920143941000106],
              [-2.819604178999953, 10.91938379200019],
              [-2.820148718999974, 10.918397054000081],
              [-2.820739949999961, 10.917432077000058],
              [-2.821145851999972, 10.916889701000173],
              [-2.821482185999912, 10.916546093000136],
              [-2.821783742999912, 10.916213710000079],
              [-2.822433925999974, 10.915868232000093],
              [-2.822619561999886, 10.915729382000166],
              [-2.822805197999969, 10.915590531000134],
              [-2.82303710899987, 10.915222749000066],
              [-2.823349751999899, 10.91443023100004],
              [-2.823685380999962, 10.913557685000171],
              [-2.824068013999977, 10.913040240000043],
              [-2.824450646999935, 10.91252279400004],
              [-2.825598740999908, 10.911050347000185],
              [-2.826363936999883, 10.909913523000057],
              [-2.826572452999926, 10.909545878000131],
              [-2.82674642499984, 10.909327204000135],
              [-2.827413662999959, 10.908710153000186],
              [-2.827466016999949, 10.908661737000045],
              [-2.828173548999871, 10.908040418999974],
              [-2.828306372999919, 10.907866971000033],
              [-2.828614371999947, 10.907464770000047],
              [-2.828822737999928, 10.906941475],
              [-2.829031102999977, 10.90641817900007],
              [-2.829714367999827, 10.904970536000121],
              [-2.830055938999919, 10.904212279000149],
              [-2.830226724999932, 10.903833150000139],
              [-2.830397510999944, 10.903454022],
              [-2.8307686039999, 10.902965566000091],
              [-2.831139697999959, 10.902477112000042],
              [-2.831348420999916, 10.90212737100012],
              [-2.832756509999911, 10.899720825000145],
              [-2.832831701999908, 10.89952477800017],
              [-2.832958488999907, 10.898835299000041],
              [-2.833045268999911, 10.898600614000088],
              [-2.833132047999811, 10.898365930000011],
              [-2.833711689999973, 10.89743406700012],
              [-2.834824701999878, 10.89603068200006],
              [-2.836007875999883, 10.894742579000024],
              [-2.837074843999972, 10.893468944000062],
              [-2.838037877999909, 10.89269731800016],
              [-2.838977983999882, 10.892143462000149],
              [-2.83950049799995, 10.891856576000066],
              [-2.839976490999902, 10.891660880000188],
              [-2.840568794999967, 10.891569171000128],
              [-2.840754585999889, 10.891551527000104],
              [-2.843031598999914, 10.891471738000178],
              [-2.844390614999895, 10.891413982000188],
              [-2.845342985999935, 10.891320100000087],
              [-2.845697218999931, 10.891268381000145],
              [-2.846051451999927, 10.891216661000101],
              [-2.846632307999926, 10.891169088000026],
              [-2.84752683399995, 10.891180231000135],
              [-2.848641989999976, 10.891085357000065],
              [-2.849048500999913, 10.891015413000162],
              [-2.849455012999897, 10.890945468000155],
              [-2.849721977999877, 10.89087085599999],
              [-2.849988943999847, 10.890796242000079],
              [-2.850511548999918, 10.890611269000146],
              [-2.851289746999896, 10.890474341000186],
              [-2.851638363999882, 10.89050805],
              [-2.851986980999868, 10.89054175900003],
              [-2.852637451999954, 10.890667310000026],
              [-2.853706758999977, 10.890906033000022],
              [-2.854729565999946, 10.891202885000041],
              [-2.854978837999965, 10.89125497100008],
              [-2.855600887999913, 10.891384946000073],
              [-2.856495534999965, 10.891374024000072],
              [-2.857564072999935, 10.891312459000062],
              [-2.858260871999903, 10.891176006000023],
              [-2.85887639699996, 10.890968417000181],
              [-2.859421869999892, 10.890609736000101],
              [-2.859851056999958, 10.890243494000174],
              [-2.860802915999955, 10.889747359000069],
              [-2.861023745999887, 10.889618497000072],
              [-2.861928759999898, 10.889090386000134],
              [-2.862671440999975, 10.888573475999976],
              [-2.863147074999915, 10.888240007000036],
              [-2.863378792999924, 10.887803335000115],
              [-2.863784426999871, 10.887194806000139],
              [-2.864144075999889, 10.886804188000156],
              [-2.864375722999967, 10.886447406000116],
              [-2.864607173999843, 10.88579310199998],
              [-2.864803752999933, 10.885273998],
              [-2.865000712999972, 10.884942219000038],
              [-2.865313872999877, 10.884562903000074],
              [-2.865568734999897, 10.88413710899999],
              [-2.865695763999952, 10.883723110000119],
              [-2.865763385999856, 10.882356298000047],
              [-2.865767416999915, 10.882267448000107],
              [-2.865831020999963, 10.880865517000018],
              [-2.865817222999965, 10.879543277000039],
              [-2.865845821999926, 10.879233183000167],
              [-2.865874421999877, 10.878923089000068],
              [-2.866047628999922, 10.87831597100012],
              [-2.866359935999981, 10.877534454000056],
              [-2.866452453999955, 10.877131684000119],
              [-2.866498090999869, 10.876718181000058],
              [-2.866482152999879, 10.87388216700009],
              [-2.866446627999892, 10.873456760000124],
              [-2.86620104699989, 10.872425185000168],
              [-2.86612575099997, 10.87225478199997],
              [-2.865851401999919, 10.871633914000142],
              [-2.865548688999922, 10.87113437],
              [-2.864547227999935, 10.869509579000123],
              [-2.864068950999979, 10.868248006000101],
              [-2.863823840999942, 10.867423038000084],
              [-2.863542889999962, 10.86611343300018],
              [-2.863413938999884, 10.865276741000059],
              [-2.863401771999918, 10.864954497000042],
              [-2.863389604999895, 10.864632254000071],
              [-2.86339994399998, 10.863827775000061],
              [-2.863445175999971, 10.862990027000023],
              [-2.863408281999966, 10.861577013000158],
              [-2.863395031999914, 10.860612896000077],
              [-2.863428843999941, 10.859948773000099],
              [-2.863451321999946, 10.859304002000101],
              [-2.863461460999815, 10.858375556000055],
              [-2.863501526999926, 10.858052995999969],
              [-2.86354159299998, 10.857730436000111],
              [-2.863714882999886, 10.857087507000131],
              [-2.864050568999971, 10.856214941000076],
              [-2.864583075999974, 10.855032640000047],
              [-2.865196588999936, 10.8536983300001],
              [-2.865520678999928, 10.853021429000137],
              [-2.865671083999928, 10.852607290000094],
              [-2.865855750999856, 10.851826549000066],
              [-2.866064128999938, 10.85127431699999],
              [-2.866376873999968, 10.850699413000029],
              [-2.866561979999915, 10.850298837000139],
              [-2.867685236999876, 10.848126717000184],
              [-2.867911358999947, 10.847827823000102],
              [-2.868137479999973, 10.847528927999974],
              [-2.868844873999819, 10.846780828000078],
              [-2.869633717999875, 10.846092839000107],
              [-2.869888522999929, 10.845746937000058],
              [-2.870259569999973, 10.845229529000108],
              [-2.870584111999904, 10.844803312000067],
              [-2.87123377599994, 10.844275946000153],
              [-2.871917796999981, 10.843539004000093],
              [-2.872555518999889, 10.842744488999983],
              [-2.872879972999897, 10.842263175000141],
              [-2.872944096999959, 10.842133586000159],
              [-2.873227263999866, 10.841561334000062],
              [-2.873563236999928, 10.841090969000163],
              [-2.874316689999944, 10.84004505900009],
              [-2.874930736999943, 10.839079886000093],
              [-2.875522173999855, 10.838379313000132],
              [-2.876113330999829, 10.837675987000011],
              [-2.876161209999964, 10.837609236999981],
              [-2.876513237999973, 10.837118454000176],
              [-2.876913144999946, 10.836560920000068],
              [-2.877168873999949, 10.836013837000166],
              [-2.877202417999968, 10.835942075999981],
              [-2.877433794999945, 10.835458571000061],
              [-2.877630349999947, 10.834851311000079],
              [-2.877815422999959, 10.834274424000057],
              [-2.878035376999947, 10.83374691099999],
              [-2.878232042999969, 10.833332488999986],
              [-2.878556466999896, 10.832898003000082],
              [-2.87889262899995, 10.832460692000097],
              [-2.879089398999952, 10.831930565000164],
              [-2.879320501999871, 10.831229430000178],
              [-2.879689670999881, 10.829703759000154],
              [-2.879920681999977, 10.82871612200006],
              [-2.879937312999857, 10.82827938000014],
              [-2.879953943999965, 10.82784263800005],
              [-2.879964252999969, 10.826947255000164],
              [-2.87985888299994, 10.826447896000104],
              [-2.879753512999912, 10.825948536],
              [-2.879543708999961, 10.825420887000178],
              [-2.879461338999931, 10.824765738000053],
              [-2.879484168999909, 10.824450172000013],
              [-2.879506997999954, 10.824134604000051],
              [-2.879737581999962, 10.823169008000093],
              [-2.879893221999851, 10.822411859000056],
              [-2.880048862999956, 10.821654710000189],
              [-2.880140518999951, 10.820802909000179],
              [-2.880151589999912, 10.820476394000025],
              [-2.880162660999929, 10.820149879000098],
              [-2.880103634999955, 10.819621311000105],
              [-2.8800679899999, 10.819092601000023],
              [-2.880078879999928, 10.818646252000121],
              [-2.880078011999956, 10.818059478000066],
              [-2.879902235999964, 10.816947596000091],
              [-2.879768208999963, 10.816076987000088],
              [-2.879737840999951, 10.815879722000034],
              [-2.879690567999944, 10.815315269000166],
              [-2.8796784099999, 10.814970989000074],
              [-2.879712420999965, 10.814433590000135],
              [-2.879700033999939, 10.813926776000073],
              [-2.879641095999943, 10.813341732000026],
              [-2.879582158999938, 10.812756690000072],
              [-2.879626984999959, 10.811767431000021],
              [-2.879764643999977, 10.810653641000101],
              [-2.87990265499991, 10.809771255000101],
              [-2.880167196999935, 10.808127765000165],
              [-2.880232568999929, 10.807846427000186],
              [-2.880455660999928, 10.806886335000115],
              [-2.880603274999885, 10.805820916000187],
              [-2.880662324999889, 10.805394713000169],
              [-2.880823464999878, 10.804556263000052],
              [-2.880984933999855, 10.803728831000171],
              [-2.881355031999931, 10.80275412300017],
              [-2.88163291199993, 10.802317169000105],
              [-2.881899155999975, 10.801717750000137],
              [-2.882084259999942, 10.801281358999972],
              [-2.882263910999882, 10.801000651000038],
              [-2.882443560999946, 10.800719942000171],
              [-2.882745121999903, 10.800464661000092],
              [-2.882965330999923, 10.800212631000022],
              [-2.883104215999879, 10.799961096000118],
              [-2.883161782999935, 10.799718320000068],
              [-2.883218983999939, 10.799109155000053],
              [-2.883299375999968, 10.798546679000026],
              [-2.883507785999939, 10.798008218000177],
              [-2.883924995999905, 10.797490524000125],
              [-2.88436536599994, 10.796972689000029],
              [-2.884771043999933, 10.796477103000029],
              [-2.884932941999921, 10.796076666000033],
              [-2.885199118999935, 10.79532848600013],
              [-2.885395539999934, 10.794836930000145],
              [-2.885719759999915, 10.794237156000065],
              [-2.886136343999908, 10.793215332000102],
              [-2.886575947999972, 10.792171327000119],
              [-2.886923628999966, 10.791676092999978],
              [-2.887166801999911, 10.791170477000094],
              [-2.887386127999946, 10.790422582000133],
              [-2.887721674999909, 10.789538992000189],
              [-2.888104083999963, 10.789021508000189],
              [-2.888752269999941, 10.787700747000031],
              [-2.88898362599997, 10.787137352000059],
              [-2.889214982999931, 10.786573955999984],
              [-2.889990315999967, 10.784861234000061],
              [-2.890232970999875, 10.784058100000038],
              [-2.890452781999954, 10.783563645000072],
              [-2.890661444999978, 10.78333372100002],
              [-2.89074210699988, 10.783046727000112],
              [-2.890846168999872, 10.78257501700017],
              [-2.890984940999829, 10.78226563000004],
              [-2.8912952149999, 10.782055257000138],
              [-2.891390835999914, 10.781990424000185],
              [-2.891727194999874, 10.781644017000019],
              [-2.891865910999968, 10.781367688000159],
              [-2.892016288999969, 10.78090946899999],
              [-2.89209668899997, 10.780267106000053],
              [-2.892085539999926, 10.779537791],
              [-2.892083508999974, 10.77940492700003],
              [-2.892047958999854, 10.778843161999987],
              [-2.89196581799996, 10.778314737000073],
              [-2.891860381999948, 10.777855326000179],
              [-2.891725658999917, 10.777429220999977],
              [-2.891613644999893, 10.776312279000138],
              [-2.892051145999915, 10.775560078000126],
              [-2.892679710999971, 10.775056554000059],
              [-2.893593193999891, 10.774270942000044],
              [-2.893930427999976, 10.773862335000103],
              [-2.893944442999896, 10.773845353000127],
              [-2.894038750999926, 10.773662139000066],
              [-2.894199067999921, 10.773350685000025],
              [-2.894511626999929, 10.772673844999986],
              [-2.894649939999852, 10.772111017000157],
              [-2.894707235999874, 10.771603780000021],
              [-2.89466016199998, 10.771204620000049],
              [-2.894520120999857, 10.770731646000172],
              [-2.894322054999975, 10.77037748500004],
              [-2.894158915999981, 10.769907408000165],
              [-2.894123299999876, 10.769425532000071],
              [-2.894156472999953, 10.76854103300019],
              [-2.894201764999878, 10.767805220000128],
              [-2.89427440299994, 10.767549409000026],
              [-2.894474131999971, 10.766913768999984],
              [-2.894618411999886, 10.76651904400012],
              [-2.894784042999845, 10.766186375000018],
              [-2.894928321999942, 10.765791650000153],
              [-2.89492612, 10.765439277000041],
              [-2.894839937999848, 10.765066688000047],
              [-2.894836959999964, 10.764589948000037],
              [-2.895002330999944, 10.764215823000086],
              [-2.895503104999932, 10.763839648000101],
              [-2.896250245999909, 10.762632822000114],
              [-2.897128212999974, 10.762233612000102],
              [-2.897272739999949, 10.762137058000121],
              [-2.897754495999948, 10.761815210000123],
              [-2.898070560999884, 10.761668634000046],
              [-2.898486903999981, 10.760577935000128],
              [-2.898995333999949, 10.758968768000159],
              [-2.899342131999958, 10.758118164000109],
              [-2.899504204999857, 10.757750782000073],
              [-2.899701201999903, 10.757441037000149],
              [-2.900304162999873, 10.75692219699999],
              [-2.901022827999896, 10.75627868000015],
              [-2.901521210999931, 10.755611719000115],
              [-2.901695088999929, 10.755416439000101],
              [-2.901868966999928, 10.755221161000122],
              [-2.902321088999941, 10.754772112000182],
              [-2.903074998999898, 10.75419724700015],
              [-2.903677612999843, 10.753576475000102],
              [-2.905427527999962, 10.75090322300008],
              [-2.905670541999882, 10.750495398],
              [-2.905960125999968, 10.750069382000106],
              [-2.906608449999965, 10.749194885000122],
              [-2.907060221999927, 10.748643906000098],
              [-2.907894892999821, 10.747894985000073],
              [-2.908648481999819, 10.747273280000059],
              [-2.909308844999941, 10.74653644599999],
              [-2.909760805999838, 10.74606535200013],
              [-2.910281796999868, 10.745365186000072],
              [-2.910536517999958, 10.74506610200001],
              [-2.910745338999959, 10.744916059000161],
              [-2.910954262999894, 10.744823865000171],
              [-2.911058162999893, 10.744511935000105],
              [-2.911277932999951, 10.744157969000128],
              [-2.911567741999932, 10.743949576000034],
              [-2.911880860999929, 10.743765833000168],
              [-2.912112621999938, 10.743546778999985],
              [-2.912588065999898, 10.743177466000077],
              [-2.912796385999911, 10.742809796000017],
              [-2.912899903999971, 10.742395939000176],
              [-2.913073261999898, 10.742006447000108],
              [-2.9132240499999, 10.741889817000015],
              [-2.913491000999898, 10.741764208],
              [-2.913699454999915, 10.741556315000139],
              [-2.913849741999854, 10.741222060000098],
              [-2.913977138999826, 10.741061497000089],
              [-2.91435992299995, 10.740888343000108],
              [-2.91466159, 10.740704669000024],
              [-2.915345036999952, 10.739967684000078],
              [-2.915831530999867, 10.739405464000072],
              [-2.916074587999901, 10.738990747000173],
              [-2.916363973999978, 10.738578500000074],
              [-2.916746727999907, 10.738311681000084],
              [-2.917152514999941, 10.738080532000083],
              [-2.917430995999951, 10.737885979000055],
              [-2.917871064999929, 10.737390158000153],
              [-2.918218407999916, 10.73685083200013],
              [-2.918542418999891, 10.736366745000112],
              [-2.918889505999914, 10.735794363000025],
              [-2.924017688999868, 10.731994140000097],
              [-2.925788503999911, 10.73108374800006],
              [-2.92636824899995, 10.73071515200013],
              [-2.926577295999891, 10.730644988000165],
              [-2.926739571999917, 10.730495225000141],
              [-2.927457960999959, 10.729826876000175],
              [-2.928165311999919, 10.729354181000019],
              [-2.929012095999838, 10.72890541400011],
              [-2.92958041299994, 10.728651207000155],
              [-2.929951695999932, 10.728467090000095],
              [-2.9302528959999, 10.728261368000119],
              [-2.930623853999975, 10.727936758999988],
              [-2.931029569999964, 10.727650501000142],
              [-2.931516495999915, 10.727212226000177],
              [-2.931876034999903, 10.72706399100008],
              [-2.932107942999949, 10.726913793999984],
              [-2.932385948999922, 10.726647611000089],
              [-2.932861013999968, 10.726096462000044],
              [-2.933278281999947, 10.725738505000038],
              [-2.933602796999878, 10.725565694000011],
              [-2.934171186999947, 10.725187515000073],
              [-2.934994584999913, 10.724747144000162],
              [-2.936119670999858, 10.724172669000154],
              [-2.936470678999967, 10.723989150000136],
              [-2.93658358599987, 10.723930119000158],
              [-2.936757368999963, 10.723791299000027],
              [-2.936895916999958, 10.723529474000145],
              [-2.937000148999971, 10.723332499000094],
              [-2.937370534999957, 10.722790261000171],
              [-2.937683591999928, 10.722479779000025],
              [-2.937845443999834, 10.722217070000056],
              [-2.937969247999945, 10.720400912000059],
              [-2.938015203999953, 10.720092093],
              [-2.938164980999886, 10.719642135000072],
              [-2.938384575999919, 10.719172460000038],
              [-2.938684711999883, 10.71827529400008],
              [-2.939054554999927, 10.717471357000079],
              [-2.939227668999877, 10.717057066000166],
              [-2.93929624499998, 10.716541499000073],
              [-2.939352671999927, 10.715689928000188],
              [-2.939351251999881, 10.715070115000174],
              [-2.939315704999956, 10.714737011000125],
              [-2.939344259999928, 10.714542622000124],
              [-2.939372815999889, 10.714348234000056],
              [-2.939499548999891, 10.713909438000144],
              [-2.93982288299992, 10.712979072000053],
              [-2.940145935999908, 10.712004632000117],
              [-2.940329781999935, 10.711130228000059],
              [-2.940374302999885, 10.710336581000035],
              [-2.940210708999871, 10.709797667000032],
              [-2.939988953999887, 10.709270133000075],
              [-2.939796952999927, 10.709076105000179],
              [-2.939791175999972, 10.709070267000072],
              [-2.939593399999865, 10.708870400000137],
              [-2.938988856999913, 10.708458192000137],
              [-2.938291552999885, 10.7082283740001],
              [-2.937513356999887, 10.708103736999988],
              [-2.936828404999972, 10.70810523800003],
              [-2.936398867999969, 10.708096887000181],
              [-2.936027255999932, 10.708005533000062],
              [-2.935800546999928, 10.707878845000039],
              [-2.935573837999925, 10.70775215600014],
              [-2.935120128999927, 10.707363795000049],
              [-2.934201436999956, 10.706744164000042],
              [-2.933446175999961, 10.706412765999971],
              [-2.932806681999921, 10.706025553000188],
              [-2.932620490999966, 10.705829742000049],
              [-2.932434300999944, 10.705633930000033],
              [-2.931992270999956, 10.705198660000065],
              [-2.93145784099994, 10.70494853100007],
              [-2.93071418999989, 10.704696943000044],
              [-2.930301764999967, 10.704623739],
              [-2.929889339999932, 10.704550536000113],
              [-2.928809727999976, 10.704551705000085],
              [-2.928496291999977, 10.70453986900003],
              [-2.928043134999939, 10.704415952000147],
              [-2.927566887999888, 10.704336253000065],
              [-2.926719163999906, 10.704234055000086],
              [-2.92583638799988, 10.70408799400019],
              [-2.925221142999874, 10.704100057000062],
              [-2.924280960999965, 10.70421605100006],
              [-2.923816514999942, 10.704240955000159],
              [-2.923502695999957, 10.704127189000133],
              [-2.922352983999872, 10.703875327999981],
              [-2.921144815999924, 10.703546689000063],
              [-2.920865892999927, 10.703465763000111],
              [-2.91971556999988, 10.703054118000068],
              [-2.918984028999944, 10.703044845000136],
              [-2.917834138999922, 10.702770929],
              [-2.916892986999926, 10.702462667000077],
              [-2.915812364999965, 10.70203955900007],
              [-2.915254638999898, 10.701806071000135],
              [-2.914696912999943, 10.701572582000097],
              [-2.913558236999961, 10.701218690000132],
              [-2.912733203999949, 10.700909703000036],
              [-2.9108502979999, 10.699846868000122],
              [-2.91018738799994, 10.699363327000185],
              [-2.909977896999862, 10.699089129000015],
              [-2.908903608999879, 10.696070924000139],
              [-2.908693624999899, 10.695543285000099],
              [-2.907971569999859, 10.694500872999981],
              [-2.907260964999921, 10.693249022000089],
              [-2.906713, 10.692205540999964],
              [-2.906223516999887, 10.691208533000065],
              [-2.905954693999945, 10.690358936000052],
              [-2.905790825999873, 10.689715310000111],
              [-2.905755547999888, 10.689508915000147],
              [-2.905709061999971, 10.689382476999981],
              [-2.905462864999947, 10.688224203000061],
              [-2.905517935999967, 10.686824420000164],
              [-2.905550816999948, 10.685802184000124],
              [-2.905637020999961, 10.685354000000189],
              [-2.905723224999974, 10.684905817000129],
              [-2.906127653999874, 10.683928145999971],
              [-2.906670744999872, 10.682756791000145],
              [-2.9070291099999, 10.681986011000163],
              [-2.90786290199992, 10.68093960300007],
              [-2.908349444999942, 10.680443521000029],
              [-2.908963, 10.679456306000191],
              [-2.90953037099996, 10.678698023000038],
              [-2.909761998999954, 10.678547848000164],
              [-2.90985487099988, 10.678478410000082],
              [-2.909947741999929, 10.678408972],
              [-2.910028801999886, 10.678364400000078],
              [-2.910040237999965, 10.678237609000121],
              [-2.90987745599989, 10.678122902000155],
              [-2.909795864999978, 10.67794984700015],
              [-2.910050498999908, 10.677650772000163],
              [-2.910165524999911, 10.677156958000182],
              [-2.910292551999874, 10.676800812000181],
              [-2.910604846999945, 10.676236922000101],
              [-2.910859350999942, 10.675778068000113],
              [-2.911009383999954, 10.675363930000117],
              [-2.911008296999853, 10.674744105000116],
              [-2.91077476199996, 10.674100907000025],
              [-2.910564239999872, 10.673344621000126],
              [-2.91049359799996, 10.67282714800001],
              [-2.910504389999971, 10.672367030000146],
              [-2.910630716999947, 10.671815296000148],
              [-2.9108968099999, 10.671254444000112],
              [-2.911324598999897, 10.670356517000187],
              [-2.911671504999902, 10.669712527000172],
              [-2.912018295999928, 10.668966609999984],
              [-2.912550843999952, 10.668252614000096],
              [-2.913511565999954, 10.666940962000069],
              [-2.914252193999914, 10.66579318800018],
              [-2.915039147999949, 10.664573505000021],
              [-2.915409484999941, 10.663976201000025],
              [-2.915664136999965, 10.663550403000102],
              [-2.915768424999953, 10.663435440000114],
              [-2.916061744999979, 10.663196708999976],
              [-2.917706908999946, 10.661857710000163],
              [-2.919361218999882, 10.660511250000127],
              [-2.920056334999913, 10.659925726000154],
              [-2.920693902999915, 10.659406669000134],
              [-2.921261913999956, 10.659072608000088],
              [-2.921557799999903, 10.658957846000021],
              [-2.922524830999976, 10.65866231300015],
              [-2.922845648999953, 10.658564269000067],
              [-2.923025456999937, 10.658505314000024],
              [-2.923268753999878, 10.658310985000071],
              [-2.923553950999974, 10.657812887000034],
              [-2.923604325999861, 10.657724908000148],
              [-2.924125411999967, 10.657195542000011],
              [-2.925052305999941, 10.656275255000025],
              [-2.925863206999964, 10.655504439000026],
              [-2.926240014999962, 10.655239039000094],
              [-2.926616822999961, 10.654973639000161],
              [-2.927370493999888, 10.654583333000176],
              [-2.927616033999868, 10.654460083000117],
              [-2.928565089999893, 10.653983701000129],
              [-2.929515960999936, 10.653509533000147],
              [-2.93016473299997, 10.65295733500011],
              [-2.930558636999876, 10.652554086000066],
              [-2.930952540999897, 10.652150839000171],
              [-2.931826368999964, 10.650984913000173],
              [-2.932029056999966, 10.650714471000185],
              [-2.932298207999963, 10.650284910000039],
              [-2.93369418799989, 10.647891572000105],
              [-2.933924471999944, 10.647579946000064],
              [-2.933972706999953, 10.64749592000004],
              [-2.93477151299993, 10.64644968500005],
              [-2.935339110999848, 10.645884207],
              [-2.935813876999873, 10.645344094000166],
              [-2.936016414999926, 10.645068743000024],
              [-2.93621895299998, 10.644793393000157],
              [-2.936739414999977, 10.64386181899999],
              [-2.937433547999945, 10.642780415000061],
              [-2.937826774999962, 10.641987364999977],
              [-2.93841612499989, 10.64076886800018],
              [-2.93889023499986, 10.639906449000136],
              [-2.939179446999958, 10.639400538000075],
              [-2.93951430899989, 10.63858581],
              [-2.939883995999935, 10.63781494300008],
              [-2.940254464999953, 10.637250680000022],
              [-2.940439280999954, 10.636929983000073],
              [-2.940601201999925, 10.636642485000039],
              [-2.940762921999976, 10.636275101000024],
              [-2.940989918999946, 10.633816442000068],
              [-2.941092356999889, 10.632840621000071],
              [-2.94105683499987, 10.632507514000167],
              [-2.941021138999929, 10.631967800000098],
              [-2.941100952999875, 10.631336462999968],
              [-2.941158472999859, 10.631095065000068],
              [-2.941215992999901, 10.630853667000167],
              [-2.941249198999969, 10.630117939000058],
              [-2.941166476999968, 10.629407719000085],
              [-2.94080398199992, 10.628098690000058],
              [-2.940174472999956, 10.626791309000168],
              [-2.939801175999889, 10.625975448000077],
              [-2.939742634999959, 10.625700332],
              [-2.939684094999905, 10.6254252170001],
              [-2.939602065999964, 10.625092396000184],
              [-2.939589876999946, 10.62479495499997],
              [-2.939728290999938, 10.624402922000172],
              [-2.939843634999875, 10.624057864],
              [-2.939945698999964, 10.622842379000076],
              [-2.940035018999879, 10.621199983000054],
              [-2.940079362999938, 10.620152895000103],
              [-2.940119318999962, 10.619820698000069],
              [-2.940159275999974, 10.619488502000138],
              [-2.940228056999956, 10.619176788000118],
              [-2.940494279999882, 10.618739889000096],
              [-2.94088745199997, 10.618073561000017],
              [-2.941026018999935, 10.617706321000014],
              [-2.941083312999922, 10.617339583000046],
              [-2.941105227999969, 10.616799512000057],
              [-2.941046294999978, 10.616375641000047],
              [-2.940917351999872, 10.615709782000124],
              [-2.940572061999944, 10.61425463900008],
              [-2.9404238649999, 10.612863705000109],
              [-2.940353958999822, 10.612717444000168],
              [-2.940214146999949, 10.612424924000095],
              [-2.939667397999926, 10.611877342000128],
              [-2.939167417999954, 10.611453436000033],
              [-2.938702390999936, 10.611142258000029],
              [-2.938133388999916, 10.610950177000063],
              [-2.937285742999961, 10.61072124399999],
              [-2.936333305999938, 10.61048193400012],
              [-2.936112595999873, 10.610414423000122],
              [-2.935961480999936, 10.610316182000076],
              [-2.935810364999952, 10.610217939000052],
              [-2.9356361749999, 10.610103310000056],
              [-2.935566090999885, 10.60989713400005],
              [-2.935426447999873, 10.609658325999987],
              [-2.935158747999935, 10.609417553000071],
              [-2.935048184999914, 10.609215757000072],
              [-2.93493762199995, 10.609013961000073],
              [-2.93464580199992, 10.608258190000129],
              [-2.93428371199991, 10.607224618999965],
              [-2.933839742999965, 10.605789351000055],
              [-2.933325555999943, 10.60403495700001],
              [-2.932963333999908, 10.602888437000047],
              [-2.932776549999915, 10.602360665000049],
              [-2.932660307999868, 10.602209865000191],
              [-2.932451168999819, 10.602120242000126],
              [-2.932230662999871, 10.602118841000106],
              [-2.931801303999976, 10.60221238500003],
              [-2.931337140999915, 10.602306141000156],
              [-2.931105133999893, 10.60224695900007],
              [-2.930837725999879, 10.602099842000086],
              [-2.930727180999895, 10.601944874000026],
              [-2.930616634999979, 10.601789907000068],
              [-2.92984884699996, 10.60097644199999],
              [-2.929302752999888, 10.600610648000099],
              [-2.928489341999921, 10.600243736000152],
              [-2.927873640999962, 10.599878364000119],
              [-2.927594562999957, 10.599666576000175],
              [-2.926600228999917, 10.598690579000163],
              [-2.926396680999972, 10.598443892000091],
              [-2.92627451899989, 10.598277975000144],
              [-2.925442367999949, 10.597138447000134],
              [-2.925418634999971, 10.596954020000112],
              [-2.925232525999945, 10.596712736000029],
              [-2.92506977499994, 10.596553953000125],
              [-2.924906670999974, 10.596345585000108],
              [-2.924825130999977, 10.596128453000063],
              [-2.924694429999818, 10.596043166000015],
              [-2.924414929999955, 10.59562958700019],
              [-2.924347903999944, 10.595508786000039],
              [-2.923962865999954, 10.594442275000176],
              [-2.923694060999935, 10.593581664000112],
              [-2.923525023999957, 10.593220440000096],
              [-2.923355986999923, 10.592859215000146],
              [-2.923052295999923, 10.591894134000142],
              [-2.922964417999935, 10.591486959000065],
              [-2.922876539999948, 10.591079785000147],
              [-2.92279392599994, 10.59033373800014],
              [-2.922838891999959, 10.589597930000025],
              [-2.92286074999987, 10.588944909000077],
              [-2.922812871999838, 10.588278538999987],
              [-2.922684351999862, 10.58776141900006],
              [-2.922573531999944, 10.587515540000027],
              [-2.92246271199997, 10.58726966100005],
              [-2.92229396599987, 10.586999342000126],
              [-2.922125217999962, 10.586729023000032],
              [-2.92182218499994, 10.586133079000149],
              [-2.921496022999861, 10.585581351000144],
              [-2.92103035599996, 10.58480182400001],
              [-2.920911191999949, 10.584701999],
              [-2.920462009999937, 10.584404514000028],
              [-2.920443283999873, 10.584321933000012],
              [-2.920332865999967, 10.584241338000027],
              [-2.920112028999938, 10.584080149999977],
              [-2.919937624999875, 10.583948981000049],
              [-2.919763221999915, 10.58381781200012],
              [-2.919623737999928, 10.583663015000013],
              [-2.919484252999951, 10.583508217000031],
              [-2.919320868999932, 10.583071198000027],
              [-2.91904146, 10.5827395660001],
              [-2.91869276299991, 10.582361524000078],
              [-2.918413162999968, 10.581950004000134],
              [-2.918052499999931, 10.581445313000017],
              [-2.91786599999989, 10.581071792000159],
              [-2.917679499999906, 10.58069827200012],
              [-2.917224874999931, 10.579676246000133],
              [-2.916962548999834, 10.579085555],
              [-2.916700221999975, 10.578494864999982],
              [-2.916303797999944, 10.577519314000028],
              [-2.916047280999976, 10.576879001000066],
              [-2.915790758999947, 10.57628000800014],
              [-2.915476490999879, 10.575846656000181],
              [-2.915080859999932, 10.575306359000137],
              [-2.91482435699993, 10.574801029000071],
              [-2.914591390999931, 10.574365804000024],
              [-2.914358423999943, 10.573930578000102],
              [-2.914014714999894, 10.573213655000188],
              [-2.913671005999959, 10.572496732000104],
              [-2.913320610999961, 10.571441006000157],
              [-2.913256418999879, 10.5712334050001],
              [-2.913192225999978, 10.57102580600008],
              [-2.913017175999869, 10.570591605000118],
              [-2.912876627999879, 10.56983488100002],
              [-2.912758860999929, 10.569075263000059],
              [-2.912641429999951, 10.568271567000181],
              [-2.912419003999958, 10.567330765000179],
              [-2.912255122999966, 10.56667611000006],
              [-2.912103301999935, 10.566079233000153],
              [-2.911834978999934, 10.565461020999976],
              [-2.911730131999889, 10.565231629000039],
              [-2.9116252849999, 10.565002235000065],
              [-2.911415589999876, 10.564543449000098],
              [-2.911170613999957, 10.563922341000023],
              [-2.910937710999974, 10.563579397000069],
              [-2.910751021999943, 10.563062620000039],
              [-2.91040189499995, 10.562524785000051],
              [-2.909843468999839, 10.561858739],
              [-2.909436442999891, 10.561412163],
              [-2.909029414999964, 10.56096558500019],
              [-2.908250340999928, 10.560185166],
              [-2.907482860999835, 10.559393654000075],
              [-2.906831731999944, 10.558774992000053],
              [-2.906575778999923, 10.558396370000139],
              [-2.906191926999952, 10.557960667000089],
              [-2.905953555999929, 10.557748606000189],
              [-2.90571518399986, 10.557536544000072],
              [-2.905435836999914, 10.557067152000116],
              [-2.905324031999896, 10.556891963000112],
              [-2.905040019999944, 10.556446945000062],
              [-2.904749031999927, 10.555944564000129],
              [-2.904411567999887, 10.555439708000108],
              [-2.903946138999856, 10.554866751000077],
              [-2.903539146999947, 10.554373331000079],
              [-2.903248009999913, 10.553846154999974],
              [-2.903151948999948, 10.553685576000134],
              [-2.903103918999875, 10.553605285000117],
              [-2.9020135049999, 10.551550539000118],
              [-2.901792140999874, 10.551113851000025],
              [-2.901348384999949, 10.549761137000019],
              [-2.901010567999947, 10.548969773000124],
              [-2.900788981999938, 10.548475233000033],
              [-2.900730819999922, 10.548400513000047],
              [-2.900672656999973, 10.548325793000117],
              [-2.900556330999962, 10.548176352999974],
              [-2.90038158599998, 10.547786215000031],
              [-2.900253123999903, 10.547402687000101],
              [-2.900055686999906, 10.546893167000064],
              [-2.900007656999946, 10.5465673490001],
              [-2.899411448999899, 10.545230587000162],
              [-2.898935183999924, 10.544001479000087],
              [-2.898795035999967, 10.543531241000039],
              [-2.898713221999969, 10.543229388000043],
              [-2.898631406999925, 10.542927534000114],
              [-2.898467778999816, 10.542323826000086],
              [-2.89823355599998, 10.541085550000105],
              [-2.898163056999977, 10.540643820000184],
              [-2.898092555999938, 10.540202089000104],
              [-2.89811460899989, 10.539662007],
              [-2.898101447999977, 10.538741966000032],
              [-2.898094446999835, 10.53814558300013],
              [-2.898087444999874, 10.537549200000115],
              [-2.898156362999885, 10.537164486000052],
              [-2.898225281999885, 10.536779771000113],
              [-2.898421160999931, 10.536055448000127],
              [-2.898617039999976, 10.535331126000074],
              [-2.898819070999934, 10.534773435000034],
              [-2.899021101999949, 10.53421574500004],
              [-2.899390667999967, 10.533285144000047],
              [-2.899245643999961, 10.533331469000075],
              [-2.899806621999915, 10.532379059000164],
              [-2.899901051999905, 10.532062785000107],
              [-2.900129628999935, 10.531297222000092],
              [-2.90033682599983, 10.530447485000025],
              [-2.900382492999938, 10.530062910000083],
              [-2.900428158999944, 10.529678335000142],
              [-2.900403237999967, 10.529502830000126],
              [-2.900322237999944, 10.52893240700007],
              [-2.900053265999816, 10.527785249000033],
              [-2.899888737999959, 10.526695315000097],
              [-2.899794745999827, 10.526074661000052],
              [-2.899700752999934, 10.525454006000132],
              [-2.899595107999915, 10.524862346000191],
              [-2.899489462999952, 10.524270687000183],
              [-2.899080689999892, 10.522802046000095],
              [-2.898486726999977, 10.521769780000113],
              [-2.898317797999937, 10.52134516700005],
              [-2.898148867999964, 10.520920554000043],
              [-2.897845926999878, 10.520272222000187],
              [-2.897542986999952, 10.519623890000048],
              [-2.896669780999901, 10.518042319000187],
              [-2.896028798999964, 10.516608116999976],
              [-2.895643853999843, 10.515610405000018],
              [-2.895416595999961, 10.515070433000119],
              [-2.895189337999966, 10.514530461000049],
              [-2.894875258999946, 10.514128750999987],
              [-2.894561179999869, 10.513727040000106],
              [-2.893922076999843, 10.513097239000103],
              [-2.893468863999829, 10.512821703000043],
              [-2.893335331999879, 10.512772104000135],
              [-2.892702422999889, 10.512537015000134],
              [-2.892122112999971, 10.512410998000064],
              [-2.891669459999889, 10.51232003199999],
              [-2.891262962999917, 10.512080027000138],
              [-2.89105357699998, 10.511794769000176],
              [-2.890809492999949, 10.511575834000098],
              [-2.890663987999915, 10.511363200000062],
              [-2.890518482999937, 10.51115056500015],
              [-2.890296762999981, 10.510532040000044],
              [-2.889946424999948, 10.509269643000152],
              [-2.889780835999943, 10.50875316500003],
              [-2.889650908999954, 10.508347914000183],
              [-2.889409556999965, 10.507595126000069],
              [-2.889205374999847, 10.506998536000083],
              [-2.88900119199991, 10.506401944000118],
              [-2.888791830999935, 10.506148365000172],
              [-2.888687149999953, 10.506021574000044],
              [-2.888582468999914, 10.505894784000077],
              [-2.88841969899994, 10.505678118000048],
              [-2.888256928999965, 10.505461452000077],
              [-2.887966420999931, 10.505249677000052],
              [-2.887675913999942, 10.505037902000026],
              [-2.887449433999962, 10.504894617000161],
              [-2.887222953999867, 10.504751333000058],
              [-2.885655241999928, 10.504074683000169],
              [-2.884493606999968, 10.503434184000014],
              [-2.88371553699983, 10.503055873000108],
              [-2.88336756699988, 10.503011104],
              [-2.883175908999931, 10.502943368000103],
              [-2.882984250999812, 10.502875634000191],
              [-2.882682401999944, 10.502761718000158],
              [-2.882544495999923, 10.502716907000092],
              [-2.882312054999943, 10.502515974000062],
              [-2.882211756999936, 10.502411883000093],
              [-2.882108412999969, 10.502260977000049],
              [-2.881982911999899, 10.502085409000074],
              [-2.881740276999892, 10.501866456000187],
              [-2.881543572999874, 10.50170508400015],
              [-2.881370245999847, 10.501532553000175],
              [-2.881115868999927, 10.501313670000059],
              [-2.880965616999959, 10.501187835000053],
              [-2.880782142999863, 10.501020185000073],
              [-2.880320850999965, 10.500691642000106],
              [-2.880259778999971, 10.50076638500019],
              [-2.880126281999935, 10.500829160000137],
              [-2.879964, 10.500828743],
              [-2.880190598999946, 10.500622163000173],
              [-2.880109848999837, 10.500531730000034],
              [-2.880006111999933, 10.500358786000106],
              [-2.879902050999874, 10.500232677999975],
              [-2.879786636999825, 10.500106636000055],
              [-2.879671095999925, 10.4999668210001],
              [-2.879578733999949, 10.499840643000141],
              [-2.879405752999844, 10.499588217000053],
              [-2.879279006999923, 10.499299704000066],
              [-2.879198363999933, 10.499151417000121],
              [-2.879117720999886, 10.499003130000062],
              [-2.879002911999976, 10.498703528000135],
              [-2.878887538999948, 10.498497593000025],
              [-2.878737893999926, 10.498198196000033],
              [-2.878565064999918, 10.497876896000093],
              [-2.878403525999943, 10.497588588000042],
              [-2.878230719999976, 10.497278308000148],
              [-2.878046340999958, 10.496888204000015],
              [-2.877862207999954, 10.496495344000039],
              [-2.877643254999953, 10.496105444000079],
              [-2.877320718999897, 10.495473726000057],
              [-2.877159374999962, 10.495210210000096],
              [-2.876952078999864, 10.49476238799997],
              [-2.876732804999904, 10.494405547000042],
              [-2.876617482999961, 10.494232670000031],
              [-2.876502159999916, 10.494059793000019],
              [-2.876317723999932, 10.493796413000098],
              [-2.876121507999869, 10.493483513000115],
              [-2.875913673999833, 10.493220271000041],
              [-2.875729111999931, 10.492943116000106],
              [-2.875607906999846, 10.492782670000167],
              [-2.875486701999932, 10.492622224],
              [-2.875336995999874, 10.492311804999986],
              [-2.875198817999944, 10.492059170000175],
              [-2.875072033999913, 10.49180646800005],
              [-2.874899102999905, 10.491474146000087],
              [-2.874783739999941, 10.491312288000188],
              [-2.874633690999872, 10.491081763000182],
              [-2.874507212999902, 10.490829058000145],
              [-2.874438275999978, 10.490615960000127],
              [-2.874369337999951, 10.490402862000053],
              [-2.874243139999976, 10.490059245000168],
              [-2.874036002999901, 10.489542546000109],
              [-2.873955775999946, 10.489256510000075],
              [-2.8738642, 10.488934727000185],
              [-2.873784175999958, 10.488588083000081],
              [-2.873755728999868, 10.488439486000175],
              [-2.873727281999948, 10.488290889000041],
              [-2.873704587999839, 10.488129862000108],
              [-2.873681894999891, 10.487968834000128],
              [-2.873659166999971, 10.487820204000059],
              [-2.873636439999871, 10.487671573000114],
              [-2.873602472999892, 10.487440362000086],
              [-2.873556973999882, 10.487143102000175],
              [-2.873507122999911, 10.4869040640001],
              [-2.873328482999966, 10.486177481000141],
              [-2.873276606999866, 10.486045551000075],
              [-2.873224730999937, 10.485913622000055],
              [-2.873075375999974, 10.485559120000062],
              [-2.872948771999916, 10.485248562000095],
              [-2.872833692999961, 10.484995789000038],
              [-2.872695519999923, 10.484743152000021],
              [-2.872499485999867, 10.484408209000094],
              [-2.872314950999964, 10.484133806000045],
              [-2.87216499699997, 10.483867463000138],
              [-2.87198039599997, 10.483582040000044],
              [-2.871841923999909, 10.483373483000094],
              [-2.871623378999857, 10.482903681000153],
              [-2.871519852999882, 10.482626045000075],
              [-2.871485645999826, 10.482448556000065],
              [-2.871451437999838, 10.482271066000123],
              [-2.871371326999963, 10.481996048000099],
              [-2.87129132299998, 10.481652156000052],
              [-2.871211147999929, 10.481330304999972],
              [-2.871119449999981, 10.481030559999965],
              [-2.871050974999832, 10.480708640000159],
              [-2.871063908999929, 10.480262270000026],
              [-2.871076651999829, 10.479928853000047],
              [-2.871065954999892, 10.479675465000014],
              [-2.871055880999961, 10.479207191],
              [-2.871103520999895, 10.478840509000065],
              [-2.871197415999973, 10.478484575000095],
              [-2.871291027999973, 10.478219554000077],
              [-2.871362175999934, 10.477783862000081],
              [-2.871362805999979, 10.477519389000179],
              [-2.87136312399997, 10.477433640000072],
              [-2.871363652999946, 10.477290727000081],
              [-2.871364084999868, 10.477130940000109],
              [-2.871354015999941, 10.476706745000058],
              [-2.871366476999981, 10.476464241000087],
              [-2.871425902999931, 10.476017598000169],
              [-2.871496417999936, 10.475708635000103],
              [-2.871590288999869, 10.475399534000189],
              [-2.871765202999939, 10.475043124000024],
              [-2.871824127999958, 10.474803101000134],
              [-2.871929742999953, 10.474400266000089],
              [-2.871988625999961, 10.474102391000031],
              [-2.872059425999964, 10.473746592],
              [-2.872002553999948, 10.473631245000149],
              [-2.871679052999923, 10.472975126000108],
              [-2.871327064999946, 10.472261228000036],
              [-2.871095021999906, 10.471790601000066],
              [-2.870974615999899, 10.471546393000153],
              [-2.870722125999976, 10.471067096000183],
              [-2.870469508999861, 10.470554741000171],
              [-2.8704688709999, 10.470389451000131],
              [-2.870385609999914, 10.469783862000043],
              [-2.87031479299992, 10.46948399300004],
              [-2.870232597999973, 10.469186946000036],
              [-2.870191544999955, 10.469049442000028],
              [-2.870150492999926, 10.468911938000019],
              [-2.870091242999934, 10.46858996200001],
              [-2.87002059799994, 10.468325907000121],
              [-2.869973045999927, 10.468003862000046],
              [-2.869855496999946, 10.467569278000155],
              [-2.869749780999882, 10.467258593999986],
              [-2.86964462799989, 10.466983721000076],
              [-2.869492263999916, 10.466596174000074],
              [-2.869292615999939, 10.46593065899998],
              [-2.8690584389999, 10.465265345000034],
              [-2.868958818999943, 10.465021973000148],
              [-2.868859197999939, 10.464778601999967],
              [-2.868800819999819, 10.464635984000097],
              [-2.868648898999936, 10.464314550000154],
              [-2.868508632999976, 10.463993049000067],
              [-2.86839141299987, 10.463707226000167],
              [-2.868297678999909, 10.463443305000055],
              [-2.868157564999876, 10.463190675000135],
              [-2.868070037999928, 10.463030024999966],
              [-2.867982509999877, 10.462869377],
              [-2.867877335999879, 10.462641336000047],
              [-2.867737156999908, 10.462377687000185],
              [-2.867608610999923, 10.462102949000041],
              [-2.867491653999934, 10.461817124000106],
              [-2.867404029999875, 10.461661985000148],
              [-2.86731640499994, 10.461506846000191],
              [-2.867199730999857, 10.461311931000125],
              [-2.866931590999968, 10.460878227000137],
              [-2.866744776999951, 10.460590055000068],
              [-2.866558415999975, 10.460326675000147],
              [-2.866348507999931, 10.459994560000098],
              [-2.866161843999976, 10.459731181000052],
              [-2.866022013999952, 10.459525381000105],
              [-2.865812386999892, 10.459240098],
              [-2.865590650999934, 10.458886011000118],
              [-2.865497397999945, 10.458741924000037],
              [-2.865404143999967, 10.45859783700007],
              [-2.865252353999949, 10.458348029000035],
              [-2.865100677999976, 10.458117503000153],
              [-2.864914361999922, 10.457911975000172],
              [-2.864797863999968, 10.457745984000042],
              [-2.864681365999957, 10.457579993000081],
              [-2.863459535999937, 10.456471396000097],
              [-2.863192246999915, 10.456266339000081],
              [-2.862994710999942, 10.45614076600009],
              [-2.862773755999911, 10.456004311000072],
              [-2.862425212999938, 10.455810748000147],
              [-2.86219282299993, 10.455718438000133],
              [-2.861960628999896, 10.455615107000028],
              [-2.861774742999955, 10.455525279000085],
              [-2.861646895999968, 10.45543924600014],
              [-2.861519047999934, 10.455353213000024],
              [-2.861388210999962, 10.455231726000022],
              [-2.861309707999965, 10.455158835000134],
              [-2.861065300999883, 10.454917827999964],
              [-2.860704628999883, 10.454644441000028],
              [-2.860472304999917, 10.45451907],
              [-2.86019351799996, 10.454347136000024],
              [-2.859972390999872, 10.454188640000041],
              [-2.859682014999919, 10.453983714000117],
              [-2.859356609999907, 10.453790012000127],
              [-2.859205324999891, 10.453675187000101],
              [-2.858972788999949, 10.453514001000087],
              [-2.858705588999953, 10.453366792000054],
              [-2.858519815999955, 10.453299003000041],
              [-2.858334041999854, 10.453231212000048],
              [-2.85747426699993, 10.452762372000166],
              [-2.857334744999946, 10.452654365],
              [-2.857195221999973, 10.452546357000131],
              [-2.856950923999875, 10.452374220000024],
              [-2.856730021999908, 10.45220194500007],
              [-2.856555559999947, 10.452065214000015],
              [-2.856288235999955, 10.45186015000013],
              [-2.856282085999965, 10.451856609000117],
              [-2.85603243199995, 10.45171287200003],
              [-2.85581174399988, 10.451576410000087],
              [-2.855590859999893, 10.451450968000188],
              [-2.855474699999945, 10.451388280000174],
              [-2.855358539999941, 10.451325593000035],
              [-2.855277201999968, 10.45126821100007],
              [-2.855195863999882, 10.451210831000139],
              [-2.854951911999876, 10.451096542000187],
              [-2.854812409999965, 10.451039500000093],
              [-2.854591935999849, 10.45098292800003],
              [-2.854359746999933, 10.45087959000017],
              [-2.853822976999823, 10.450736009000082],
              [-2.854521830999886, 10.449744705000171],
              [-2.855561134999903, 10.448600499000179],
              [-2.855126457999916, 10.448426004],
              [-2.854732875999957, 10.44827677000012],
              [-2.854315723999889, 10.448149712999964],
              [-2.853956979999964, 10.448008541000036],
              [-2.853551766999942, 10.44787039300013],
              [-2.853238889999886, 10.447822621000114],
              [-2.852868047999948, 10.447753145000036],
              [-2.852763824999954, 10.447730334000028],
              [-2.852659599999925, 10.447707522000087],
              [-2.852208144999963, 10.447566886000175],
              [-2.851849352999921, 10.447417447000134],
              [-2.851617623999971, 10.447347162000085],
              [-2.851293621999957, 10.447197520000145],
              [-2.851062067999976, 10.447105195000063],
              [-2.850738114999842, 10.44691973900018],
              [-2.850460296999927, 10.446769828000129],
              [-2.850206004999961, 10.446597741000176],
              [-2.850090276999879, 10.446515764000083],
              [-2.849824265999814, 10.446332725000104],
              [-2.84962769699996, 10.446229176000145],
              [-2.849384510999926, 10.446147937000092],
              [-2.8491643499999, 10.446099624000112],
              [-2.848863310999946, 10.445971885000063],
              [-2.848562358999914, 10.44582210700014],
              [-2.848284846999889, 10.445672192000075],
              [-2.847983874999954, 10.445511393000174],
              [-2.847174017999976, 10.445050495000032],
              [-2.846919488999902, 10.444933505000165],
              [-2.8464794759999, 10.444748715000117],
              [-2.846167067999886, 10.444587980000051],
              [-2.845843053999829, 10.444435577000149],
              [-2.845530596999822, 10.444310656000027],
              [-2.845183384999871, 10.444147367000141],
              [-2.844859017999966, 10.444030778000126],
              [-2.844558177999943, 10.443892014000028],
              [-2.844303600999979, 10.443810836000068],
              [-2.844106774999943, 10.443707286],
              [-2.843898538999952, 10.44355696700012],
              [-2.84365571099994, 10.443395827000131],
              [-2.843436017999977, 10.443234554000185],
              [-2.843193075999864, 10.44309820899997],
              [-2.842961639999885, 10.442981082000188],
              [-2.842695169999956, 10.442910991000133],
              [-2.842393947999824, 10.442898955000032],
              [-2.842196895999962, 10.44289733700009],
              [-2.841895738999881, 10.442852239000047],
              [-2.841606163999927, 10.442735447000189],
              [-2.84132836699996, 10.442632361000165],
              [-2.841062208999858, 10.442526453000141],
              [-2.840819071999874, 10.442401127000039],
              [-2.840437298999916, 10.442180175999965],
              [-2.840292729999874, 10.442088718000036],
              [-2.840148160999888, 10.44199726100004],
              [-2.839824219999912, 10.441811791000134],
              [-2.839523543999974, 10.441604147000021],
              [-2.839199865999944, 10.441374595000013],
              [-2.838714179999897, 10.441038533000096],
              [-2.838541093999879, 10.44080811300006],
              [-2.83832164699993, 10.440591735000112],
              [-2.838125033999916, 10.440383489999988],
              [-2.837836465999942, 10.440060066000058],
              [-2.837559176999889, 10.439761372000021],
              [-2.837328212999978, 10.439531284],
              [-2.837062395999908, 10.439290377000077],
              [-2.836854653999978, 10.439082196000129],
              [-2.836673184999881, 10.438866994000136],
              [-2.836392758999864, 10.438575185000047],
              [-2.836161441999821, 10.438380913000117],
              [-2.835953344999894, 10.438208546000169],
              [-2.83572209099998, 10.438069372000029],
              [-2.835490609999908, 10.437988054000016],
              [-2.83521264399991, 10.437907002000088],
              [-2.835038991999852, 10.437861165000129],
              [-2.834679896999887, 10.437708950000058],
              [-2.834333003999973, 10.437501565000048],
              [-2.834043805999841, 10.437351702000171],
              [-2.833858569999961, 10.437277005000112],
              [-2.833673334999901, 10.437202306000017],
              [-2.833314253999959, 10.437096924],
              [-2.832897395999908, 10.437016666000147],
              [-2.832514917999902, 10.436955496000053],
              [-2.83212119399991, 10.436875106000173],
              [-2.831750480999972, 10.436780808000151],
              [-2.831460766999896, 10.436735634000172],
              [-2.831101948999958, 10.436630247000039],
              [-2.830082865999941, 10.436316510000097],
              [-2.829793508999956, 10.436235519000093],
              [-2.829422928999975, 10.436119177000023],
              [-2.829040629999952, 10.435991882000053],
              [-2.82862393399995, 10.435886822999976],
              [-2.828230266999867, 10.435726533000036],
              [-2.828004791999945, 10.435651403000065],
              [-2.827848230999962, 10.435599236000087],
              [-2.827523808999956, 10.435515687000191],
              [-2.827234292999947, 10.435459488000106],
              [-2.826805994999972, 10.43531867900009],
              [-2.826562527999897, 10.435284256000159],
              [-2.826261342999942, 10.43522536800009],
              [-2.826110782999933, 10.435179393999988],
              [-2.825798229999975, 10.43508751100012],
              [-2.825508904999936, 10.434959681],
              [-2.825254325999936, 10.434831651000081],
              [-2.825046019999832, 10.434763966000105],
              [-2.824721491999981, 10.434669395000185],
              [-2.824525044999973, 10.434576848000063],
              [-2.824407182999892, 10.434531394000032],
              [-2.824166036999941, 10.434438394000153],
              [-2.823784134999869, 10.434289050000075],
              [-2.823517790999972, 10.434194145000106],
              [-2.822961746999965, 10.43410089300005],
              [-2.822724990999973, 10.434069657000123],
              [-2.822521408999933, 10.434042796000085],
              [-2.822173770999939, 10.434042023000131],
              [-2.821779678999917, 10.433994680000012],
              [-2.821316190999937, 10.433933958000182],
              [-2.82079500399982, 10.433807443999967],
              [-2.820623764999937, 10.433767691000185],
              [-2.820343261999938, 10.433702573000119],
              [-2.819914819999895, 10.433633383000085],
              [-2.819555579999928, 10.43359685900009],
              [-2.819207732999928, 10.433560270000157],
              [-2.818837100999929, 10.433523809000064],
              [-2.818337195999959, 10.433459486000174],
              [-2.817944988999898, 10.433407666000107],
              [-2.817574184999955, 10.433349166000085],
              [-2.817041351999933, 10.433230976000061],
              [-2.816728485999875, 10.43317490000004],
              [-2.816358018999949, 10.433069561000138],
              [-2.815906245999884, 10.433011518000058],
              [-2.81531530299992, 10.432951511000113],
              [-2.815025443999843, 10.432925607000129],
              [-2.814700945999903, 10.432880615000158],
              [-2.814329534999899, 10.432817709000062],
              [-2.813948169999947, 10.43274989400004],
              [-2.813542524999889, 10.432727401000079],
              [-2.813252862999889, 10.432690475000015],
              [-2.812939918999859, 10.432620620000023],
              [-2.812737325999876, 10.432551517000093],
              [-2.812534734999815, 10.432482413000059],
              [-2.812326423999878, 10.432413342000132],
              [-2.812118113999873, 10.432344270999977],
              [-2.81167812599989, 10.432203506000178],
              [-2.811284265999916, 10.432098293000081],
              [-2.811029596999958, 10.432006066000156],
              [-2.810566272999836, 10.431868186000145],
              [-2.810217751999971, 10.431730199000185],
              [-2.810010588999944, 10.431648179000149],
              [-2.809628741999973, 10.431507081000177],
              [-2.809257989999935, 10.431404490000148],
              [-2.808934146999889, 10.431276838000031],
              [-2.808587632999888, 10.431184592000022],
              [-2.808418438999922, 10.431138386000157],
              [-2.808111775999976, 10.431044561000078],
              [-2.807822425999916, 10.430963547000147],
              [-2.807498100999908, 10.430857937000098],
              [-2.807185596999887, 10.430766034000044],
              [-2.806883208999921, 10.430631240000082],
              [-2.80676360699988, 10.430587554000056],
              [-2.806606761999944, 10.430535131000056],
              [-2.806418114999872, 10.430494439000029],
              [-2.806335115999957, 10.430476536000072],
              [-2.806006596999964, 10.43037879700006],
              [-2.80521678599996, 10.430231667000157],
              [-2.805054530999939, 10.430207788000075],
              [-2.80489227399994, 10.430183909000107],
              [-2.804614390999973, 10.430160683000167],
              [-2.804196970999954, 10.430124469000191],
              [-2.804089484999963, 10.430095233000088],
              [-2.803849663999927, 10.430030004000059],
              [-2.803548322999859, 10.429995889],
              [-2.803379717999917, 10.429958215000113],
              [-2.803235642999937, 10.429926022000188],
              [-2.802922764999948, 10.429867177],
              [-2.802610084999856, 10.429797310000026],
              [-2.802378678999958, 10.429726984000069],
              [-2.802233335999858, 10.429688602000056],
              [-2.801961491999919, 10.429632910000066],
              [-2.801486577999981, 10.429610792000062],
              [-2.801266268999939, 10.429587238000067],
              [-2.80094168699992, 10.429572537000183],
              [-2.800628639999957, 10.429582565000089],
              [-2.800362031999839, 10.429584067000064],
              [-2.800234757999874, 10.429571008000096],
              [-2.799817642999926, 10.42953478700008],
              [-2.799574476999965, 10.429453505000083],
              [-2.79922688299996, 10.429361791000133],
              [-2.79894915299991, 10.429266929000107],
              [-2.798613256999829, 10.42917514800007],
              [-2.798389814999894, 10.429106895000189],
              [-2.798312148999969, 10.429083171000173],
              [-2.797907154999848, 10.428931169],
              [-2.797374444999889, 10.428779882000015],
              [-2.797026970999923, 10.428663370000152],
              [-2.796644948999926, 10.428536031000021],
              [-2.796228191999944, 10.428364807000037],
              [-2.795848140999908, 10.428209755000012],
              [-2.795522084999959, 10.428073986000072],
              [-2.795128727999895, 10.42791089300016],
              [-2.794700337999814, 10.427750752000122],
              [-2.794167883999876, 10.4275526240001],
              [-2.793716324999934, 10.427378836000116],
              [-2.793376401999979, 10.427237186000127],
              [-2.793126064999854, 10.427147969000089],
              [-2.792610777999926, 10.426956433000043],
              [-2.792315578999933, 10.426846706000106],
              [-2.792205605999811, 10.426817017000076],
              [-2.79209563299986, 10.426787327999989],
              [-2.791817859999981, 10.426684194000188],
              [-2.791319842999883, 10.42656851800001],
              [-2.790914421999901, 10.42648538300017],
              [-2.790462458999912, 10.426438324000117],
              [-2.789987289999942, 10.426424453000095],
              [-2.789616301999956, 10.426423774000114],
              [-2.7892568339999, 10.426445071000046],
              [-2.788955620999843, 10.426432981000119],
              [-2.788608028999931, 10.426432170000112],
              [-2.788237159999881, 10.42640669400015],
              [-2.78785429699991, 10.426428119000036],
              [-2.787495005999972, 10.426427372999967],
              [-2.787205490999895, 10.426415216000123],
              [-2.786741756999902, 10.426401276000036],
              [-2.786405629999877, 10.426367338999967],
              [-2.785930324999924, 10.426375503000145],
              [-2.785547900999973, 10.426374883999983],
              [-2.785200201999885, 10.426363050000077],
              [-2.784875934999889, 10.426304249999987],
              [-2.784481985999889, 10.426234817000022],
              [-2.784157341999958, 10.426209078000113],
              [-2.783740370999965, 10.426197628000068],
              [-2.783369501999857, 10.426172146999988],
              [-2.78289420699997, 10.426136226000153],
              [-2.782465594999849, 10.426089024000134],
              [-2.7821410869999, 10.426041242999986],
              [-2.781723974999864, 10.426004996000131],
              [-2.781155876999947, 10.425991630000055],
              [-2.780588111999919, 10.425945201000047],
              [-2.780066561999945, 10.425942594000105],
              [-2.779637712999886, 10.42595324600012],
              [-2.779081567999981, 10.425892974000078],
              [-2.778710815999943, 10.425834426000051],
              [-2.778258852999954, 10.425787348000028],
              [-2.777807009999947, 10.425715474000128],
              [-2.777343664999876, 10.425624378000066],
              [-2.77696157299988, 10.425483239000016],
              [-2.776510594999877, 10.425218505000146],
              [-2.776175237999951, 10.424975168000174],
              [-2.775805109999965, 10.42473478],
              [-2.774903066999912, 10.42482387900003],
              [-2.774752459999888, 10.424949541000103],
              [-2.774092216999975, 10.424027973000079],
              [-2.773907349999945, 10.423819614000138],
              [-2.773734302999969, 10.423624965000101],
              [-2.773607557999924, 10.42340526400011],
              [-2.773480741999947, 10.423188319000019],
              [-2.773366072999863, 10.422935491000089],
              [-2.773274189999881, 10.422682535000092],
              [-2.773231101999954, 10.422560860999965],
              [-2.773216739999953, 10.422520305000035],
              [-2.773193837999884, 10.422407475000114],
              [-2.773183115999927, 10.422233967000125],
              [-2.773149170999943, 10.42198344600007],
              [-2.773178601999973, 10.421851040000149],
              [-2.773208031999843, 10.421718635000104],
              [-2.773359899999946, 10.421398207999971],
              [-2.773442034999903, 10.421158063000121],
              [-2.773547299999848, 10.420870955000169],
              [-2.773757257999876, 10.42050336900013],
              [-2.773862369999961, 10.420342994000066],
              [-2.774106796999945, 10.420011033000037],
              [-2.774293722999971, 10.419646330000148],
              [-2.774433638999881, 10.419381070000043],
              [-2.774597012999834, 10.419096394000121],
              [-2.774690407999913, 10.418925064000177],
              [-2.774865307999903, 10.418648589000099],
              [-2.774994526999933, 10.418168495000032],
              [-2.775077193999948, 10.417776819000096],
              [-2.775148, 10.417410006000068],
              [-2.775183959999879, 10.417079201000092],
              [-2.775220384999898, 10.416676761000019],
              [-2.775226770999893, 10.416499025000064],
              [-2.775233157999878, 10.416321290000042],
              [-2.77524603199987, 10.415998877999982],
              [-2.775247130999958, 10.415701326999965],
              [-2.775248089999877, 10.415378981000117],
              [-2.775260643999957, 10.415092387000016],
              [-2.775297009999918, 10.414725763000092],
              [-2.775367816999903, 10.41435895],
              [-2.775438489999829, 10.414061013000151],
              [-2.775508768999941, 10.413831955000035],
              [-2.775579403999927, 10.413580853000042],
              [-2.775661398999887, 10.413362750000033],
              [-2.775755087999926, 10.413097746000119],
              [-2.775813690999939, 10.412879772],
              [-2.775884187999907, 10.412650712000072],
              [-2.775989284999923, 10.412388400000054],
              [-2.776129472999969, 10.412125893000109],
              [-2.776385470999912, 10.411826928000153],
              [-2.776676688999942, 10.411450626000089],
              [-2.776920994999898, 10.411198561999981],
              [-2.777211957999839, 10.410877362000065],
              [-2.777421537999942, 10.410636510000018],
              [-2.777700638999932, 10.410340172000019],
              [-2.778003235999847, 10.410007887000177],
              [-2.77829403699991, 10.409758318000058],
              [-2.778794027999822, 10.409345041000051],
              [-2.779014640999947, 10.409186778000162],
              [-2.779282198999965, 10.408956624000155],
              [-2.779537838999886, 10.408787145000076],
              [-2.779793458999905, 10.408568077000041],
              [-2.780095779999954, 10.408387319000042],
              [-2.78043224899983, 10.408239433000062],
              [-2.780606558999864, 10.408136527000067],
              [-2.780780867999965, 10.408033622000175],
              [-2.781117514999949, 10.407863693000024],
              [-2.781349631999888, 10.407771486000115],
              [-2.781663144999868, 10.407601686000135],
              [-2.781907257999933, 10.407462576000171],
              [-2.782185910999885, 10.40732602900016],
              [-2.78232526499994, 10.407270153000127],
              [-2.782592157999943, 10.407122651000179],
              [-2.782743488999927, 10.407008852000104],
              [-2.783010636999904, 10.406814513000086],
              [-2.783184877999929, 10.406722627000136],
              [-2.783370378999905, 10.406699554000056],
              [-2.78363692399995, 10.406689805999974],
              [-2.783927025999958, 10.406655129000058],
              [-2.784240361999878, 10.406554204000145],
              [-2.78462312899984, 10.406428095000081],
              [-2.784810735999884, 10.406353680000052],
              [-2.785250099999928, 10.406179405000103],
              [-2.785517173999892, 10.406018124000184],
              [-2.785784189999959, 10.40589266000012],
              [-2.786005026999874, 10.405767452000077],
              [-2.7862838699999, 10.405573046000143],
              [-2.78656306399995, 10.40528772000016],
              [-2.786830821999899, 10.405002459000059],
              [-2.78701686699992, 10.404830609000101],
              [-2.78734243699995, 10.404534005000073],
              [-2.787551512999926, 10.40439784300014],
              [-2.787749074999908, 10.404247968999982],
              [-2.787935080999944, 10.404122955000162],
              [-2.788144275999969, 10.403961995000145],
              [-2.788353487999927, 10.403803792000133],
              [-2.788434749999908, 10.403756503000011],
              [-2.788701878999973, 10.403597975000025],
              [-2.789038731999881, 10.403428038000129],
              [-2.789282341999979, 10.403346783000075],
              [-2.789630457999976, 10.403245658000117],
              [-2.789827590999948, 10.403211496000154],
              [-2.790129051999941, 10.403179508000164],
              [-2.790372666999929, 10.403145086000166],
              [-2.790743861999943, 10.403087912000046],
              [-2.791172747999951, 10.40304418900007],
              [-2.791427889999852, 10.402987662999976],
              [-2.79171788899987, 10.402897881000058],
              [-2.792100951999942, 10.402771763000089],
              [-2.792391050999868, 10.402646164999965],
              [-2.793041564999953, 10.402281618000131],
              [-2.793355189999886, 10.402133848000119],
              [-2.79369202099997, 10.401952886000117],
              [-2.794051681999974, 10.401780062000171],
              [-2.794423493999886, 10.401587884000094],
              [-2.794748431999949, 10.401426273000141],
              [-2.794980832999841, 10.401278956000112],
              [-2.795247911999979, 10.401120424000112],
              [-2.795515179999882, 10.400994951000143],
              [-2.795828541999811, 10.400847180000085],
              [-2.796165487999872, 10.400641421000046],
              [-2.796409219999873, 10.400491284000111],
              [-2.79668837, 10.400299622999967],
              [-2.796804585999837, 10.400183261000109],
              [-2.79697912499995, 10.400000451000096],
              [-2.797153641999955, 10.399806621000039],
              [-2.797328278999885, 10.399587997000083],
              [-2.797549404999927, 10.399325030000114],
              [-2.797677459999932, 10.39914248000008],
              [-2.797794186999909, 10.398971015000029],
              [-2.797922222999944, 10.398777445000178],
              [-2.798085052999909, 10.398638782000148],
              [-2.798340600999893, 10.39850235300014],
              [-2.798619130999953, 10.398354774000097],
              [-2.798897893999936, 10.398240255000132],
              [-2.799211072999981, 10.398114522000128],
              [-2.799501602999953, 10.397966875000066],
              [-2.79983794899988, 10.397854788000132],
              [-2.800336513999923, 10.397785868000085],
              [-2.800742499999956, 10.397731243000123],
              [-2.801240944999904, 10.397687118000078],
              [-2.802099018999911, 10.397610667000095],
              [-2.802539476999868, 10.397599926000169],
              [-2.802898674999938, 10.397636477000106],
              [-2.803199688999882, 10.397717434000128],
              [-2.80348899899991, 10.397842538000191],
              [-2.803882862999899, 10.397903687000166],
              [-2.804311616999939, 10.397937090000028],
              [-2.804485555999975, 10.397938866000175],
              [-2.804717054999912, 10.397984398000176],
              [-2.804890938999904, 10.398021989000028],
              [-2.80506462999989, 10.398078867000095],
              [-2.805249952999873, 10.398124659000132],
              [-2.80545806799995, 10.398217156999976],
              [-2.8057475089999, 10.398311953000189],
              [-2.806002133999925, 10.398462043],
              [-2.806325680999976, 10.398680621000153],
              [-2.806568716999834, 10.398841798000092],
              [-2.806730280999943, 10.398967617000096],
              [-2.806984772999954, 10.399139748000039],
              [-2.807239386999925, 10.399234738000075],
              [-2.807436272999951, 10.39930250399999],
              [-2.807656220999945, 10.399372893000077],
              [-2.808038320999913, 10.3994671640001],
              [-2.808304732999943, 10.399534536000033],
              [-2.808605828999873, 10.399629263000122],
              [-2.808918709999944, 10.399641272000167],
              [-2.809208331999912, 10.399631373000148],
              [-2.809660422999912, 10.399609537000117],
              [-2.809846113999925, 10.399575429000095],
              [-2.810136144999945, 10.399485631000118],
              [-2.81042624, 10.399406853000187],
              [-2.810623321999913, 10.399372680000113],
              [-2.811794196999927, 10.399294435000115],
              [-2.812130559999957, 10.399284269000191],
              [-2.812466464999943, 10.399285125000063],
              [-2.812883946999932, 10.399241440000083],
              [-2.81319715799998, 10.399220384000159],
              [-2.813440522999883, 10.39919696700008],
              [-2.81380018599998, 10.399117793000073],
              [-2.814009033999923, 10.399050491000025],
              [-2.814368685999966, 10.398924481000051],
              [-2.81464714599997, 10.398856786000067],
              [-2.815041606999955, 10.398780168000144],
              [-2.815342844999975, 10.398756423],
              [-2.815597974999946, 10.398699878000059],
              [-2.815934611999921, 10.398631851],
              [-2.816201213999932, 10.398589017],
              [-2.816526089999911, 10.398510037000051],
              [-2.816827700999909, 10.398453228000051],
              [-2.817280007999955, 10.39832669000009],
              [-2.817523814999959, 10.398237148000135],
              [-2.817744192999896, 10.398133964000124],
              [-2.817976464999958, 10.398019692999981],
              [-2.818208772999981, 10.397919196000146],
              [-2.818371164999974, 10.397849400000041],
              [-2.81864973699993, 10.397748641000135],
              [-2.818974786999945, 10.397564968000097],
              [-2.819253543999821, 10.397406352000132],
              [-2.820020325999849, 10.396892328000092],
              [-2.820415252999908, 10.396664178000037],
              [-2.820566188999976, 10.396583427000166],
              [-2.820868095999913, 10.396435698000118],
              [-2.821181731999843, 10.396241068],
              [-2.821437450999952, 10.39604676700003],
              [-2.821727954999915, 10.395852268000169],
              [-2.821983443999898, 10.39571582300016],
              [-2.822273632999952, 10.395557140000108],
              [-2.822529281999948, 10.395395898000061],
              [-2.822715202999973, 10.395259847000091],
              [-2.822935842999925, 10.39511257900017],
              [-2.823121790999949, 10.394973774000107],
              [-2.823319571999889, 10.394768781000153],
              [-2.823540435999973, 10.394607735000136],
              [-2.823819011999831, 10.394471158000101],
              [-2.824109325999871, 10.394334513000103],
              [-2.824411100999953, 10.39416474300009],
              [-2.824759535999931, 10.393980932000034],
              [-2.82505003499989, 10.393786431000024],
              [-2.825340284999925, 10.393638765000048],
              [-2.825746770999899, 10.393388504000086],
              [-2.825990833999924, 10.393194265999966],
              [-2.826304487999948, 10.392966572000148],
              [-2.826607074999856, 10.392645272000038],
              [-2.82662193199991, 10.39263063500016],
              [-2.82686301799987, 10.392393111000047],
              [-2.827083829999936, 10.392223801000057],
              [-2.827316246999885, 10.391993814000102],
              [-2.827583526999888, 10.391777403000049],
              [-2.827804880999906, 10.391514418999975],
              [-2.828048939999974, 10.391320181000083],
              [-2.828293071999894, 10.391101147000086],
              [-2.828467466999939, 10.390943119000156],
              [-2.828769529999875, 10.39072650900016],
              [-2.82899032399996, 10.390554443000156],
              [-2.82924603299989, 10.390360137000073],
              [-2.829501709999818, 10.390107976000081],
              [-2.829745963999926, 10.389902716000108],
              [-2.830071712999882, 10.389595056000076],
              [-2.830373812999937, 10.38929579600017],
              [-2.830536798999901, 10.389135078000038],
              [-2.830734799999959, 10.388872226000103],
              [-2.830990543999974, 10.38858700500009],
              [-2.831177040999876, 10.388357278000171],
              [-2.831409828999938, 10.388050148000161],
              [-2.831666505999976, 10.387544523000145],
              [-2.831783143999928, 10.387362030000077],
              [-2.831981406999887, 10.386950408000075],
              [-2.832075238999948, 10.386674375000098],
              [-2.832215355999892, 10.386365019000095],
              [-2.832320818999904, 10.386077899000099],
              [-2.832425695999973, 10.385884453000074],
              [-2.832519036999884, 10.385666277000155],
              [-2.832635902999868, 10.385425928000075],
              [-2.832775954999931, 10.385105552000027],
              [-2.832939397999951, 10.384785041999976],
              [-2.833102612999937, 10.384522388000107],
              [-2.833312357999887, 10.384223654000095],
              [-2.833428928999922, 10.384030141000153],
              [-2.833580314999949, 10.383789594000064],
              [-2.833801678999976, 10.383432939000102],
              [-2.833941692999929, 10.383203476000062],
              [-2.834127993999971, 10.382940691000044],
              [-2.834314356999926, 10.382688924000092],
              [-2.834908112999926, 10.381958219000126],
              [-2.835036297999977, 10.381750865000015],
              [-2.835129700999971, 10.381543709000141],
              [-2.835211477999906, 10.381303559000173],
              [-2.835305304999963, 10.381027526000139],
              [-2.835375491999969, 10.380798463000076],
              [-2.835399342999892, 10.380638539000188],
              [-2.835446803999844, 10.380329711000059],
              [-2.835537684999963, 10.379194145000042],
              [-2.835556033999978, 10.378929564000089],
              [-2.835626332999937, 10.378667441000175],
              [-2.835604170999886, 10.378356256000075],
              [-2.835605346999898, 10.37808075300012],
              [-2.835594206999872, 10.377885214],
              [-2.835630328999912, 10.377540636000106],
              [-2.835701104999941, 10.377173821000042],
              [-2.835806205999916, 10.376922519000061],
              [-2.835864749999871, 10.376773417000152],
              [-2.83592329399994, 10.376624313000036],
              [-2.836040077999883, 10.37632611000015],
              [-2.836168731999976, 10.376005798000108],
              [-2.836273948999974, 10.375729701000125],
              [-2.836437692999937, 10.375365109000143],
              [-2.836624271999938, 10.374964572000124],
              [-2.83675269399987, 10.37474619599999],
              [-2.836904093999976, 10.374516669000116],
              [-2.837078868999868, 10.374231909000173],
              [-2.837207018999891, 10.374071388000118],
              [-2.837439951999897, 10.373739460000024],
              [-2.837591231999966, 10.373578807000115],
              [-2.837776987999973, 10.373417957000129],
              [-2.837951593999947, 10.37324615100016],
              [-2.838149352999892, 10.373041152000155],
              [-2.838323901999956, 10.372822511999971],
              [-2.838486974999853, 10.372581897000089],
              [-2.838626998999871, 10.372355189000132],
              [-2.838743483999849, 10.372147901000062],
              [-2.838871598999958, 10.371929525000098],
              [-2.838988502999939, 10.371645096000123],
              [-2.839163389999953, 10.371335539000142],
              [-2.839291928999842, 10.371048287000178],
              [-2.83949003299989, 10.370716558000026],
              [-2.839618211999948, 10.370509202000108],
              [-2.839862902999926, 10.37014414700019],
              [-2.839991161999876, 10.369950566000057],
              [-2.84015405699995, 10.369731992000084],
              [-2.84019494699993, 10.369657373000052],
              [-2.840235835999977, 10.36958275600017],
              [-2.840340931999947, 10.369331453000086],
              [-2.840446155999928, 10.369102189000102],
              [-2.84055105099992, 10.368905986000129],
              [-2.840726003999919, 10.368563369000128],
              [-2.840807717999951, 10.368403114000103],
              [-2.840970691999928, 10.36819831400004],
              [-2.84102726999987, 10.368120705000081],
              [-2.841121953999902, 10.367990825000163],
              [-2.841215722999948, 10.367750606000072],
              [-2.841297586999872, 10.36747464199999],
              [-2.841426724999906, 10.367052393000165],
              [-2.841497262999951, 10.366787513000077],
              [-2.841567495999925, 10.366522635000024],
              [-2.841638036999882, 10.366213676000143],
              [-2.841685555999902, 10.365915868000172],
              [-2.841781331999925, 10.365066792000107],
              [-2.841840991999902, 10.364562293000176],
              [-2.841876347999971, 10.36433342800018],
              [-2.841947407999896, 10.363919778000025],
              [-2.841983180999932, 10.363657852000017],
              [-2.842054066999936, 10.363222162000056],
              [-2.84213682099994, 10.362808444999985],
              [-2.842195665999952, 10.362499552000145],
              [-2.842231965999929, 10.362097120000044],
              [-2.842290610999953, 10.361843328000191],
              [-2.842349729999967, 10.36158126800018],
              [-2.842397106999897, 10.361214588000053],
              [-2.84249138399997, 10.360778764000031],
              [-2.842538931999911, 10.360434121000026],
              [-2.842575051999972, 10.360045466000088],
              [-2.842582977999939, 10.359958294000023],
              [-2.842599112999835, 10.359780852000085],
              [-2.842588886999977, 10.35941450100006],
              [-2.842590142999882, 10.35905634900007],
              [-2.842602705999866, 10.358780781000178],
              [-2.842592526999908, 10.358370351000133],
              [-2.842570184999943, 10.358140440000057],
              [-2.842547843999853, 10.357910528000048],
              [-2.842502705999948, 10.357566417000044],
              [-2.842446315999894, 10.357059827000057],
              [-2.842426786999965, 10.356358256000021],
              [-2.842519878999951, 10.355891303000021],
              [-2.842567397999972, 10.355593495000051],
              [-2.842614608999952, 10.355339769000068],
              [-2.842650235999884, 10.355053049000105],
              [-2.842616630999942, 10.354777745000092],
              [-2.842728419999958, 10.352575892000061],
              [-2.842752610999924, 10.352333317000159],
              [-2.842753479999885, 10.352057817000116],
              [-2.842754393999883, 10.35178231600014],
              [-2.842732289999901, 10.351473886000178],
              [-2.842721997999945, 10.351140595000061],
              [-2.842711336999969, 10.350796286000104],
              [-2.842700927999886, 10.350487790000159],
              [-2.842667538999876, 10.350107797000021],
              [-2.842623480999976, 10.349419309000098],
              [-2.842555085999834, 10.349097369000106],
              [-2.842487029999973, 10.348684515000059],
              [-2.842418994999946, 10.348282679000022],
              [-2.842362166999919, 10.347938635000162],
              [-2.84233969, 10.347707346000107],
              [-2.842294619999962, 10.347374254000101],
              [-2.842249369999934, 10.347054937000053],
              [-2.84220392799989, 10.346754906000172],
              [-2.842170325999973, 10.346479602000159],
              [-2.842136645999915, 10.346146444000112],
              [-2.842137868999942, 10.345826862000138],
              [-2.84217361799989, 10.345471267000107],
              [-2.842244512999969, 10.34512649100003],
              [-2.842291967999927, 10.344817665000051],
              [-2.842339474999847, 10.344473023000091],
              [-2.842480831999978, 10.343761434000101],
              [-2.842517016999977, 10.343383797000058],
              [-2.842634190999888, 10.343005698000127],
              [-2.84278661899998, 10.342522708000104],
              [-2.842938638999954, 10.342111350000039],
              [-2.843125486999952, 10.341663977000053],
              [-2.843347365999932, 10.341252219000069],
              [-2.843463971999881, 10.341022890000033],
              [-2.843615868999848, 10.340680407000036],
              [-2.843697856999881, 10.340426481000122],
              [-2.843814508999856, 10.340153073000067],
              [-2.843907786999921, 10.339978977000044],
              [-2.844024795999928, 10.339669752000134],
              [-2.844106798999974, 10.339418581000075],
              [-2.844177331999958, 10.33915370200009],
              [-2.84425933499989, 10.338902530999974],
              [-2.844317881999928, 10.3386845550001],
              [-2.844388319999894, 10.338455490000058],
              [-2.844505326999979, 10.338146265000148],
              [-2.844586861999972, 10.337963972000068],
              [-2.844703706999951, 10.33772362100018],
              [-2.844750927999883, 10.337516730000175],
              [-2.844809538999925, 10.337309772000083],
              [-2.844845820999922, 10.336852242000077],
              [-2.844881575999921, 10.336587561000101],
              [-2.844870632999914, 10.336381001000063],
              [-2.844848354999954, 10.336094613],
              [-2.844837999999868, 10.335750302000065],
              [-2.844873742999937, 10.335438788000033],
              [-2.844932952999955, 10.335140913000146],
              [-2.845003377999888, 10.334865014000172],
              [-2.845108462999974, 10.334613711000031],
              [-2.845225176999975, 10.334351323000021],
              [-2.845376971999883, 10.334088733000158],
              [-2.845505123999942, 10.333834544000183],
              [-2.845691454999894, 10.333582776000128],
              [-2.845889642999964, 10.333262067000135],
              [-2.846110818999932, 10.33297704000006],
              [-2.846378331999972, 10.332713788000149],
              [-2.846587612999883, 10.332483929000034],
              [-2.846866646999956, 10.332198571000049],
              [-2.847134321999931, 10.331866443000138],
              [-2.847308840999972, 10.331639538000104],
              [-2.847518249999951, 10.331431717000044],
              [-2.84769272199992, 10.33124889100003],
              [-2.84789084199997, 10.330917161000116],
              [-2.848135486999979, 10.330505272000039],
              [-2.848287512999946, 10.330149011000117],
              [-2.848497555999927, 10.329715280000016],
              [-2.848660950999943, 10.329347934],
              [-2.848848090999866, 10.328900560000136],
              [-2.84911662899998, 10.328292931000021],
              [-2.849210254999889, 10.328030674000104],
              [-2.849280786999941, 10.327721715],
              [-2.849328414999889, 10.32739911200008],
              [-2.849457031999975, 10.327031967000153],
              [-2.849527334999948, 10.326780863000181],
              [-2.849655963999908, 10.326460551000139],
              [-2.849714570999879, 10.32625359400015],
              [-2.849784989999932, 10.32597769500012],
              [-2.849867504999907, 10.325621833000184],
              [-2.849891870999898, 10.325313138000126],
              [-2.849962519999963, 10.324935305000054],
              [-2.849986804999958, 10.324612836000085],
              [-2.849999476999926, 10.324304208000115],
              [-2.849977356999943, 10.323993026000153],
              [-2.849967488999937, 10.323546780000186],
              [-2.849968468999919, 10.323238219000132],
              [-2.849969495999915, 10.322937923000154],
              [-2.849936089999915, 10.322502832000112],
              [-2.850008723999963, 10.32165389000005],
              [-2.850022145999844, 10.321138636000057],
              [-2.850081662999969, 10.320656179000025],
              [-2.850082176999933, 10.320506030000104],
              [-2.850082690999955, 10.320355883000047],
              [-2.850095320999856, 10.32004725600018],
              [-2.850119261999964, 10.319807438000055],
              [-2.850132050999946, 10.319474013999979],
              [-2.850133274999962, 10.319162698000184],
              [-2.850157348999915, 10.318900839000094],
              [-2.850169825999956, 10.318603233000147],
              [-2.850147676999939, 10.318338884000013],
              [-2.850090488999967, 10.318118815000048],
              [-2.849987323999869, 10.317797076000147],
              [-2.849907121999934, 10.317544080000175],
              [-2.849849953999978, 10.317282686000055],
              [-2.849781332999896, 10.317062683000074],
              [-2.849689746999957, 10.316765672000088],
              [-2.849609369999882, 10.316534716000149],
              [-2.849482927999816, 10.316224130000023],
              [-2.849344870999914, 10.315971465000189],
              [-2.849252521999915, 10.31583424500019],
              [-2.849090848999879, 10.315683648000061],
              [-2.848906147999969, 10.315453289000118],
              [-2.848744833999945, 10.315222796000114],
              [-2.848571990999915, 10.314923494000084],
              [-2.848433414999931, 10.314775518999966],
              [-2.848121697999886, 10.314463237000098],
              [-2.847913886999947, 10.314279844000112],
              [-2.847648064999873, 10.314107800999977],
              [-2.847335721999968, 10.313889190000111],
              [-2.847058485999924, 10.313667623000185],
              [-2.846815899999854, 10.313484427000105],
              [-2.846584551999968, 10.313312188000168],
              [-2.846330540999929, 10.313115281000137],
              [-2.846111017999874, 10.31292093400009],
              [-2.84593785099986, 10.312759380000045],
              [-2.845683700999871, 10.312493600000096],
              [-2.845522163999931, 10.312320960000022],
              [-2.845349108999926, 10.312126345000024],
              [-2.845187361999933, 10.311917892000054],
              [-2.84489900899996, 10.311608228000011],
              [-2.844737635999934, 10.311366711],
              [-2.844616436999957, 10.311193840000044],
              [-2.844495237999865, 10.311020969000026],
              [-2.844310784999948, 10.310779585000148],
              [-2.844137421999903, 10.310629052000024],
              [-2.843964421999885, 10.310398621000047],
              [-2.843849761999934, 10.310101740000107],
              [-2.84379235199998, 10.309939524000185],
              [-2.84371222599998, 10.309653464000121],
              [-2.843643660999931, 10.309389379000038],
              [-2.843540441999892, 10.30905661700001],
              [-2.843448672999955, 10.308778889000166],
              [-2.843333949999874, 10.308470986000145],
              [-2.843242191999934, 10.308240092000062],
              [-2.843092976999913, 10.307838718000085],
              [-2.842989920999912, 10.307481160000123],
              [-2.842909684999938, 10.307228160000022],
              [-2.842795015999968, 10.306884443000058],
              [-2.842668979999928, 10.306447122],
              [-2.842542629999969, 10.306150305000074],
              [-2.842462273999956, 10.305966180000041],
              [-2.84239330499986, 10.305781990000071],
              [-2.842313278999882, 10.305460115000187],
              [-2.842109584999832, 10.305265673000065],
              [-2.842040267999948, 10.305208213000128],
              [-2.841970803999971, 10.305125960000169],
              [-2.8419131039999, 10.305010579999987],
              [-2.841890421999892, 10.304839901000037],
              [-2.841878983999948, 10.304644363000023],
              [-2.841841981999892, 10.304358057000115],
              [-2.841738347999922, 10.304185085000029],
              [-2.841647655999964, 10.304045602000087],
              [-2.841588434999949, 10.303954520000048],
              [-2.84149638599996, 10.30377046100017],
              [-2.841357979999884, 10.30359768600016],
              [-2.841138881999882, 10.303287622000084],
              [-2.840954301999886, 10.303068275000157],
              [-2.840804367999908, 10.302931380000132],
              [-2.840619725999943, 10.30270101300016],
              [-2.840423421999958, 10.302528567000081],
              [-2.840250119999894, 10.302342215000124],
              [-2.840111714999978, 10.302169439000011],
              [-2.839892266999868, 10.301986104000036],
              [-2.839661191999937, 10.301813856000024],
              [-2.839395342999978, 10.301641804000155],
              [-2.839140844999974, 10.301455912000051],
              [-2.838921648999929, 10.30122574],
              [-2.838748344999885, 10.301031122000097],
              [-2.838505998999892, 10.300834142000156],
              [-2.838297807999936, 10.300672781],
              [-2.838135985999941, 10.300546972000177],
              [-2.83792803599988, 10.300374590000104],
              [-2.837708708999855, 10.300166457000046],
              [-2.83750082399996, 10.300005095000188],
              [-2.837338692999879, 10.299923366000087],
              [-2.837119311999913, 10.299751049000122],
              [-2.836957651999967, 10.299600442000042],
              [-2.836772853999946, 10.299394868000036],
              [-2.836622645999967, 10.299255217000166],
              [-2.836449598999934, 10.299104676000013],
              [-2.836218595999924, 10.298899364000079],
              [-2.836022036999907, 10.298726915000145],
              [-2.835733324999922, 10.298494379999966],
              [-2.835583000999918, 10.298379523000165],
              [-2.835409793999872, 10.29825377700007],
              [-2.835236432999921, 10.298056402000157],
              [-2.835063756999944, 10.297781883000084],
              [-2.834925489999876, 10.297587063000151],
              [-2.834752192999929, 10.297400707000179],
              [-2.834625555999935, 10.297194802000092],
              [-2.834371759999954, 10.296895947000166],
              [-2.834152238999934, 10.29669883299999],
              [-2.833932611999956, 10.296573348000038],
              [-2.833655060999831, 10.296398602000124],
              [-2.833319539999934, 10.296248979000097],
              [-2.832903145999978, 10.296019919000173],
              [-2.832649114999981, 10.295822998000119],
              [-2.832278918999919, 10.295637755000143],
              [-2.831978192999884, 10.295498953000049],
              [-2.831746690999921, 10.295439654000177],
              [-2.831503512999973, 10.29538317500004],
              [-2.831260460999943, 10.295348735000061],
              [-2.831086643999925, 10.295357983000088],
              [-2.830889640999885, 10.295356343000151],
              [-2.83056551899989, 10.295253486000036],
              [-2.830380354999932, 10.295171884000069],
              [-2.830091257999925, 10.295021994000081],
              [-2.829906361999974, 10.294896310000183],
              [-2.82975602599987, 10.294778695000105],
              [-2.829513310999971, 10.294606503000068],
              [-2.82920106399996, 10.294489803000033],
              [-2.828934796999874, 10.29438661800009],
              [-2.828645700999971, 10.294236726000122],
              [-2.828448979999905, 10.294133148000071],
              [-2.828183268999965, 10.293983124000135],
              [-2.827870753999946, 10.293819590000055],
              [-2.827500760999897, 10.293623319000119],
              [-2.827350335999881, 10.293588353000018],
              [-2.827061355999945, 10.293405400000097],
              [-2.826829980999889, 10.293277221000039],
              [-2.826552629999981, 10.293083181000156],
              [-2.826332947999902, 10.292954936000115],
              [-2.826148215999979, 10.292804454000134],
              [-2.82595196099993, 10.292585160999977],
              [-2.825743978999924, 10.292412769000123],
              [-2.825489772999958, 10.292229617999965],
              [-2.825362606999931, 10.292172480000033],
              [-2.825188917999924, 10.292112848999977],
              [-2.825003835999894, 10.292045017000078],
              [-2.824818630999914, 10.29196341100004],
              [-2.824679954999908, 10.29189256300009],
              [-2.824494928999968, 10.291788916000087],
              [-2.824309783999922, 10.291710064000142],
              [-2.824159181999903, 10.291697138000131],
              [-2.823985480999909, 10.291673321000019],
              [-2.823777133999897, 10.29158082500004],
              [-2.823592368999925, 10.291477175000182],
              [-2.823361046999935, 10.29130491300009],
              [-2.823129915999914, 10.29116571000003],
              [-2.822910370999978, 10.291015421000168],
              [-2.822656032999873, 10.290854308000064],
              [-2.82228610899989, 10.290578135000032],
              [-2.822031834999905, 10.290428041000041],
              [-2.821754125999973, 10.290322158000095],
              [-2.821557415999905, 10.290265410000188],
              [-2.82129138699986, 10.290104360000157],
              [-2.82107194199989, 10.289918255000146],
              [-2.820841018999886, 10.28972394800013],
              [-2.820586852999895, 10.289493957000047],
              [-2.820355787999972, 10.289274856000077],
              [-2.820101982999859, 10.289009047000036],
              [-2.819894302999899, 10.288789814000097],
              [-2.81965165299988, 10.28858179600013],
              [-2.819443620999891, 10.288445215000138],
              [-2.819189045999906, 10.288295120000043],
              [-2.818934731999889, 10.288145022000037],
              [-2.818726746999914, 10.288016705000189],
              [-2.818356835999964, 10.287787361000028],
              [-2.818113882999853, 10.287670260000027],
              [-2.817847857999936, 10.287509207000141],
              [-2.81748963299998, 10.287232962000076],
              [-2.817177638999908, 10.28701431200011],
              [-2.816888768999888, 10.286795531000053],
              [-2.816530430999933, 10.286552345000189],
              [-2.816322329999935, 10.286448822000068],
              [-2.816056191999962, 10.286320829000033],
              [-2.815790058999937, 10.286239670000157],
              [-2.815535735999958, 10.28612538499999],
              [-2.815327574999969, 10.286019106000026],
              [-2.814760758999967, 10.285697191000111],
              [-2.814263982999876, 10.28531702600003],
              [-2.813940482999954, 10.285084661000042],
              [-2.813339028999906, 10.28483181199999],
              [-2.812934267999935, 10.28454204500008],
              [-2.812529520999931, 10.284345949000055],
              [-2.812159743999871, 10.284138636000023],
              [-2.811916865999933, 10.283988468000018],
              [-2.811546722999935, 10.283770137000033],
              [-2.811153668999964, 10.283573973000102],
              [-2.810852819999923, 10.283410353000079],
              [-2.810448146999931, 10.283181192000029],
              [-2.810217088999877, 10.283052997000027],
              [-2.809881646999941, 10.282867530000033],
              [-2.809615691999966, 10.282717489000106],
              [-2.809280328999932, 10.282545795000146],
              [-2.809037381999815, 10.282428685000014],
              [-2.808748048999917, 10.2823256100001],
              [-2.80859782899995, 10.282279613000128],
              [-2.808354954999913, 10.282129442000041],
              [-2.807915560999959, 10.281908736999981],
              [-2.807649308999885, 10.281805530999975],
              [-2.807348583999954, 10.281655682000121],
              [-2.807025134999947, 10.281423309000161],
              [-2.80674780499993, 10.281229247999988],
              [-2.806400736999876, 10.28105486100003],
              [-2.805995996999968, 10.280858755000168],
              [-2.80570685899994, 10.280697819000181],
              [-2.80527915499988, 10.280430209000087],
              [-2.805024832999891, 10.28026907900005],
              [-2.804724410999825, 10.280072390000043],
              [-2.804493004999927, 10.279935927],
              [-2.804388867999933, 10.279895181000029],
              [-2.804284731999928, 10.279854435],
              [-2.803891805999967, 10.279633465000018],
              [-2.803579397999954, 10.279530512000122],
              [-2.803359369999953, 10.279438066000125],
              [-2.803081916999815, 10.279321143000118],
              [-2.802861951999887, 10.279239715000131],
              [-2.802572985999973, 10.279100816000096],
              [-2.802110031999973, 10.278894007000133],
              [-2.801902000999974, 10.278801492000014],
              [-2.801566388999902, 10.278638056000034],
              [-2.801207890999933, 10.278455460000032],
              [-2.80094206799987, 10.278327451000166],
              [-2.800676045999978, 10.278210462000175],
              [-2.800317233999863, 10.278071948000047],
              [-2.800132119999887, 10.278004100000146],
              [-2.799808379999945, 10.277818555000181],
              [-2.7995192919999, 10.277657612999974],
              [-2.799207305999971, 10.277483022000183],
              [-2.798941191999973, 10.277310931000102],
              [-2.798698628999944, 10.277160750000178],
              [-2.798502107999866, 10.277043374000073],
              [-2.798235979999959, 10.276907098000152],
              [-2.797808110999824, 10.276708353000117],
              [-2.797634427999924, 10.276640440000108],
              [-2.797379937999835, 10.276548179000031],
              [-2.797136954999871, 10.27643106],
              [-2.796801528999936, 10.276292412000089],
              [-2.796512203999953, 10.276189322999983],
              [-2.796188592999954, 10.27602581300016],
              [-2.795714277999934, 10.275830077000137],
              [-2.795378592999953, 10.275691430000109],
              [-2.795031596999934, 10.275528048000069],
              [-2.794592398999953, 10.275285280000048],
              [-2.794384265999952, 10.27518174000005],
              [-2.794187565999948, 10.275078136000161],
              [-2.79400242, 10.274996510000165],
              [-2.793713416999935, 10.274857601000122],
              [-2.793412644999933, 10.274743553000121],
              [-2.792938077999907, 10.274602914000127],
              [-2.792567582999936, 10.274511289999964],
              [-2.792116140999951, 10.27437052300013],
              [-2.791722792999906, 10.274265249000166],
              [-2.79132917599992, 10.274173752000138],
              [-2.790889365999874, 10.274068735000071],
              [-2.790437821999888, 10.273963781000077],
              [-2.790020954999875, 10.2738806750001],
              [-2.789534880999895, 10.273764890000052],
              [-2.788990835999925, 10.273635649000084],
              [-2.788527823999971, 10.273508715000048],
              [-2.788029741999878, 10.273437075000174],
              [-2.787509033999868, 10.273299437000162],
              [-2.787138404999951, 10.273229849000074],
              [-2.786687165999979, 10.273124887000108],
              [-2.786131096999895, 10.273075604000041],
              [-2.785795256999904, 10.273016841000072],
              [-2.785355147999837, 10.272958653000046],
              [-2.784973011999909, 10.272922185000027],
              [-2.784579184999927, 10.27288578100007],
              [-2.784196883999925, 10.272874109000156],
              [-2.783664094999892, 10.272860509000111],
              [-2.783293408999953, 10.272834997000132],
              [-2.782888008999976, 10.272765594000134],
              [-2.782436405999931, 10.272696444000076],
              [-2.781996480999851, 10.2726244750001],
              [-2.78149888299987, 10.272483945000033],
              [-2.780919990999962, 10.272332840000047],
              [-2.780422303999899, 10.272184043000095],
              [-2.779554581999946, 10.27191605400003],
              [-2.779317270999968, 10.271840213000019],
              [-2.772116203999929, 10.268802213000015],
              [-2.771780759999956, 10.268696596000098],
              [-2.771387289999893, 10.268536195000024],
              [-2.770959319999918, 10.268362207000052],
              [-2.77070494499992, 10.268234106000023],
              [-2.770415936999939, 10.268084154000064],
              [-2.769999294999934, 10.26789908100011],
              [-2.769606296999882, 10.267667043000074],
              [-2.769178492999856, 10.267459988999974],
              [-2.76872734299991, 10.267228266000075],
              [-2.768322498999908, 10.267054148000057],
              [-2.767882934999932, 10.266905013000098],
              [-2.767455117999816, 10.266695202000051],
              [-2.766923342999974, 10.266361978000191],
              [-2.766472317999956, 10.266152290999969],
              [-2.765928818999896, 10.265852189000043],
              [-2.765662989999953, 10.26568006499997],
              [-2.765247038999974, 10.265415083999983],
              [-2.765004256999873, 10.265229059000148],
              [-2.76472694399996, 10.265023935000045],
              [-2.764392108999971, 10.264744737000058],
              [-2.764091597999936, 10.26452596300004],
              [-2.76373333399988, 10.264285462000146],
              [-2.763571688999946, 10.264167871000097],
              [-2.763328860999934, 10.264020416000051],
              [-2.76297084499987, 10.263730321000139],
              [-2.762508558999969, 10.263385690000121],
              [-2.762127672999895, 10.263062657000148],
              [-2.761862337999901, 10.262774813000135],
              [-2.761596739999959, 10.262533805000089],
              [-2.76134280399998, 10.26223212300016],
              [-2.761077774999819, 10.261842338000179],
              [-2.760881981999944, 10.261554116000127],
              [-2.760617647999936, 10.260990755000137],
              [-2.76043340699988, 10.26071624500014],
              [-2.760249088999899, 10.260427960000186],
              [-2.760007222999946, 10.260013251000089],
              [-2.759857816999954, 10.259702736000065],
              [-2.759639297999968, 10.259312696000109],
              [-2.759489893999955, 10.258955344000015],
              [-2.75932938099993, 10.258553969000104],
              [-2.759179977999906, 10.258196616000134],
              [-2.759019344999956, 10.257773202000067],
              [-2.758814863999817, 10.257186104000027],
              [-2.757238886999971, 10.251592136000056],
              [-2.757168761999935, 10.251281189],
              [-2.757075649999877, 10.251041998000062],
              [-2.757028828999921, 10.250763987000028],
              [-2.756958994999934, 10.250568753000039],
              [-2.756830783999874, 10.250112100000024],
              [-2.756679737999889, 10.249754753000047],
              [-2.756633025999918, 10.249559393000027],
              [-2.75658631399989, 10.249364034000109],
              [-2.756504381999946, 10.2489291710001],
              [-2.756446106999931, 10.248607139000114],
              [-2.756364099999871, 10.248158501000091],
              [-2.756282428999953, 10.247723636000103],
              [-2.756200619999959, 10.247310813000126],
              [-2.756176644999925, 10.246792984000024],
              [-2.756152964999899, 10.246344030000159],
              [-2.756105918999936, 10.246024693000152],
              [-2.756070647999934, 10.24563366000018],
              [-2.756070005999902, 10.245264479000127],
              [-2.756081056999904, 10.244840134000071],
              [-2.756103905999964, 10.244553481000082],
              [-2.756103261999897, 10.244231138000146],
              [-2.756102832999943, 10.243900528000154],
              [-2.756114075999903, 10.243669038000178],
              [-2.756159834999949, 10.243233486],
              [-2.756176997999944, 10.243066709000175],
              [-2.756319362999932, 10.241452829000025],
              [-2.756388455999968, 10.241119089000108],
              [-2.756457593999926, 10.240730248000034],
              [-2.756549631999974, 10.240269649000027],
              [-2.75661878499983, 10.239946928000052],
              [-2.756653048999908, 10.239602356000148],
              [-2.756733400999963, 10.239039882000156],
              [-2.756767633999971, 10.238753167000141],
              [-2.756801748999976, 10.238270840000098],
              [-2.756765733999885, 10.23749134100018],
              [-2.756788534999942, 10.237133055000186],
              [-2.756834321999975, 10.236813216000087],
              [-2.756914847999894, 10.236377476000143],
              [-2.756926173999943, 10.236066088000086],
              [-2.756948562999924, 10.235743620000051],
              [-2.756994499999905, 10.235387964000154],
              [-2.757040529999927, 10.235112206000053],
              [-2.757086469999877, 10.234709714000132],
              [-2.757109114999878, 10.234480918000031],
              [-2.757201466999902, 10.234125012000106],
              [-2.757259021999971, 10.233827150000138],
              [-2.757281847999877, 10.23363141499999],
              [-2.757316350999815, 10.233377760000053],
              [-2.757362376999879, 10.233148838000147],
              [-2.757558414999949, 10.232585739000172],
              [-2.757639208999876, 10.232356629000037],
              [-2.757859184999973, 10.231942177000064],
              [-2.758020869999939, 10.231596917000104],
              [-2.758205927999882, 10.231218470000101],
              [-2.758390990999828, 10.23091991900003],
              [-2.758599091999884, 10.230527572000142],
              [-2.758772479999948, 10.230240105000064],
              [-2.759003658999973, 10.229792530000111],
              [-2.759223222999935, 10.22936705900014],
              [-2.759384987999908, 10.228941901000155],
              [-2.759546745999955, 10.228610415000048],
              [-2.759708749999959, 10.228276173000097],
              [-2.759858897999948, 10.227919954000072],
              [-2.760020489999931, 10.227621531000125],
              [-2.760275343999865, 10.227322603000118],
              [-2.760460493999972, 10.227103950000014],
              [-2.760634168999957, 10.226932195000131],
              [-2.760854054999868, 10.226644477000093],
              [-2.761039204999918, 10.226425824000046],
              [-2.761120062999964, 10.226264213000093],
              [-2.761200919999965, 10.226102603000072],
              [-2.761386144999847, 10.225897724000049],
              [-2.761594533999926, 10.225747823000177],
              [-2.761965121999879, 10.225426228000117],
              [-2.762300891999928, 10.225126862000025],
              [-2.76265981399996, 10.224849411000093],
              [-2.762972520999938, 10.224608026000112],
              [-2.763319809999871, 10.224333392000119],
              [-2.763690223999959, 10.223941542000034],
              [-2.764060638999979, 10.223549691000017],
              [-2.764292215999944, 10.223286704000031],
              [-2.764732220999917, 10.222928915000182],
              [-2.764963459999876, 10.222652153000183],
              [-2.765310775999922, 10.222272825000061],
              [-2.76563482499995, 10.221882602000051],
              [-2.76593577899996, 10.221481484000037],
              [-2.766225068999916, 10.221124509000106],
              [-2.766653351999935, 10.220722700000181],
              [-2.76702387999984, 10.220376305000173],
              [-2.76737110199997, 10.220043812000142],
              [-2.767834099999902, 10.219570181999984],
              [-2.768216147999965, 10.219248521000111],
              [-2.768459388999929, 10.219076385000108],
              [-2.76870263099994, 10.218904251000083],
              [-2.76931622799998, 10.218410516000176],
              [-2.769594398999971, 10.218260231000045],
              [-2.769906866999975, 10.218040884000118],
              [-2.770095860999902, 10.217877264000094],
              [-2.770358408999869, 10.217649965000021],
              [-2.770497450999926, 10.217551405000052],
              [-2.77063649299987, 10.21745284400015],
              [-2.770937416999971, 10.21718947700009],
              [-2.771342640999933, 10.216831870000078],
              [-2.771770910999862, 10.216430056999968],
              [-2.772199527999874, 10.216075077000085],
              [-2.77258130599995, 10.215706576000059],
              [-2.773032876999935, 10.215337695000187],
              [-2.773530759999972, 10.214935501000127],
              [-2.773855033999894, 10.214683026000102],
              [-2.774376191999863, 10.214277949000063],
              [-2.774677111999893, 10.213967743000183],
              [-2.775001503999818, 10.213784143000112],
              [-2.775279379999972, 10.213589775000173],
              [-2.775545793999925, 10.213381694000077],
              [-2.775846805999947, 10.213198220000038],
              [-2.776205823999874, 10.212989634000166],
              [-2.776587976999963, 10.212736842000027],
              [-2.77709757599996, 10.212450293000018],
              [-2.777503046999925, 10.212194617000023],
              [-2.777781042999948, 10.212022287000138],
              [-2.778649574999861, 10.211458274000108],
              [-2.778915939999934, 10.211343863000138],
              [-2.779425916999969, 10.211126186000172],
              [-2.779796375999979, 10.210975392000137],
              [-2.780132451999975, 10.210791723000114],
              [-2.780468467999981, 10.210652136000022],
              [-2.780885517999934, 10.210479046000103],
              [-2.78131399699987, 10.210305892000179],
              [-2.781673283999908, 10.210146892000125],
              [-2.782102083999973, 10.20998475600004],
              [-2.782553819999976, 10.209844535000059],
              [-2.78299414199995, 10.20970713],
              [-2.783388237999873, 10.209603039000172],
              [-2.783770381999887, 10.209452177000117],
              [-2.784187566999947, 10.209257041000171],
              [-2.784558278999896, 10.209051139000167],
              [-2.784760977999952, 10.20894120600002],
              [-2.78496367799994, 10.208831272000168],
              [-2.785612236999953, 10.208428240000046],
              [-2.786063842999852, 10.208117201000164],
              [-2.786816179999903, 10.207473908000111],
              [-2.787013008999963, 10.20731303600013],
              [-2.787279286999876, 10.207082908000132],
              [-2.787603505999869, 10.206816647000039],
              [-2.78789293299991, 10.20655333000019],
              [-2.788263495999956, 10.206220693000091],
              [-2.788634000999878, 10.205932136000115],
              [-2.789004257999977, 10.205552664000152],
              [-2.789293525999824, 10.205253532000086],
              [-2.789629201999958, 10.204907310000067],
              [-2.78993009699991, 10.204597094000121],
              [-2.790196183999967, 10.204287068000042],
              [-2.790450761999921, 10.203955065000059],
              [-2.790693634999855, 10.203620371000056],
              [-2.791006048999918, 10.203241215000105],
              [-2.791283823999834, 10.202931124000145],
              [-2.791584650999937, 10.202563051000141],
              [-2.791943129999936, 10.202067928000133],
              [-2.792139947999885, 10.201805118000038],
              [-2.792440712999962, 10.201426025000046],
              [-2.792637253999885, 10.201160459999983],
              [-2.792810815999872, 10.200919818000159],
              [-2.793088447999935, 10.20058493199997],
              [-2.793574648999936, 10.200091862000079],
              [-2.793794462999927, 10.199861985000155],
              [-2.793898748999936, 10.19976774100013],
              [-2.794130129999871, 10.199562595000032],
              [-2.794396516999882, 10.199307668000188],
              [-2.794639528999937, 10.199146539000083],
              [-2.794894427999964, 10.198974325000052],
              [-2.795091290999892, 10.198766615000068],
              [-2.795287923999922, 10.198525845000063],
              [-2.795542429999955, 10.198182819000124],
              [-2.795750655999939, 10.197917189000179],
              [-2.795924014999969, 10.197687567000173],
              [-2.796028012999955, 10.197433530000183],
              [-2.796108834999927, 10.197215437000182],
              [-2.796212460999868, 10.196848446000104],
              [-2.796351233999872, 10.196503301000064],
              [-2.796512625999924, 10.195976199000143],
              [-2.796576119999941, 10.195791261000124],
              [-2.796639612999968, 10.195606323999982],
              [-2.796685278999973, 10.195217611000089],
              [-2.796731190999878, 10.194872977000159],
              [-2.796845991999874, 10.194321336000087],
              [-2.797007874999963, 10.193874127000129],
              [-2.79719226099985, 10.193333123000116],
              [-2.797307305999937, 10.192817296000158],
              [-2.79738816299988, 10.192458695000084],
              [-2.797468389999949, 10.192033976000175],
              [-2.797583638999811, 10.191609064000033],
              [-2.797652623999909, 10.191264304000072],
              [-2.797768134999956, 10.190886226999964],
              [-2.797813908999956, 10.190563634000057],
              [-2.7979174, 10.190025831000014],
              [-2.7979168599999, 10.189634616000092],
              [-2.797927745999971, 10.189243339000143],
              [-2.797857599999929, 10.188783632000025],
              [-2.79777558, 10.188290929],
              [-2.797740340999894, 10.187957761000177],
              [-2.797646630999964, 10.187418287000128],
              [-2.797588133999909, 10.18705218600013],
              [-2.797471580999968, 10.186545898000134],
              [-2.797354438999946, 10.186113269000145],
              [-2.797331544999906, 10.186028718000159],
              [-2.796971006999911, 10.185259284999972],
              [-2.796854595999889, 10.184933451000063],
              [-2.796738185999914, 10.184607617000097],
              [-2.796610016999978, 10.184261184000093],
              [-2.796528265999882, 10.183870416000104],
              [-2.796423426999979, 10.183468754000103],
              [-2.796341741999925, 10.183182678000037],
              [-2.796271701999956, 10.182896536000158],
              [-2.796213598999941, 10.182701247000068],
              [-2.796119943999941, 10.182263707000118],
              [-2.79606171599994, 10.181999542000028],
              [-2.796026359999928, 10.181691170000079],
              [-2.795968050999875, 10.181358129000159],
              [-2.795920926999884, 10.180989210000121],
              [-2.795874266999931, 10.180749776000027],
              [-2.795862228999908, 10.180485358000169],
              [-2.795838516999936, 10.180130086000077],
              [-2.795803467999974, 10.179876812999964],
              [-2.795756791999907, 10.179634625],
              [-2.79569812099993, 10.1793374],
              [-2.795581922999929, 10.179048758000135],
              [-2.795465417999878, 10.178751850000083],
              [-2.795372355999973, 10.178520937000087],
              [-2.795291078999924, 10.178361590000122],
              [-2.795197893999955, 10.178108636000161],
              [-2.794942264999918, 10.177696779000087],
              [-2.794826048999823, 10.177478390000033],
              [-2.794104659999903, 10.175709465000125],
              [-2.794880452999962, 10.175297464000096],
              [-2.79488019799993, 10.175034377999964],
              [-2.794880793999823, 10.174895547000119],
              [-2.794885541999861, 10.174761579000062],
              [-2.794890471999906, 10.174623319000148],
              [-2.794906235999974, 10.174503585000025],
              [-2.794924130999959, 10.174382946000094],
              [-2.794938830999911, 10.174266477000117],
              [-2.794947755999942, 10.174127192000071],
              [-2.794953904999829, 10.174031241000023],
              [-2.794966504999877, 10.173905640000044],
              [-2.795005572999912, 10.17369884600015],
              [-2.795016526999859, 10.173592285000097],
              [-2.795039860999907, 10.1733652960001],
              [-2.795074088999911, 10.173067562000142],
              [-2.795096707999903, 10.172791932000052],
              [-2.795084851999832, 10.172458635000112],
              [-2.795084486999883, 10.17229195699997],
              [-2.795084119999956, 10.172125278000124],
              [-2.795037358999878, 10.171874824000042],
              [-2.794990680999945, 10.17153069799997],
              [-2.794932182999901, 10.171310614000049],
              [-2.794804235999948, 10.170909077000147],
              [-2.79473504099991, 10.170753070000103],
              [-2.794641207999973, 10.170496712000045],
              [-2.794455328999959, 10.17023324500019],
              [-2.794362333999914, 10.170060186000171],
              [-2.794269337999936, 10.169887126000049],
              [-2.79412521799992, 10.169597110000041],
              [-2.793839344999981, 10.169131841000024],
              [-2.793676582999922, 10.168868247000034],
              [-2.793479167999919, 10.168569026000057],
              [-2.7933727059999, 10.168389469000033],
              [-2.793316344999937, 10.16829441200008],
              [-2.793136610999909, 10.168054407000056],
              [-2.792921366999963, 10.16773178700015],
              [-2.792770478999898, 10.167501187000084],
              [-2.792607657999952, 10.167226573000107],
              [-2.792433333999895, 10.166985081],
              [-2.792294150999851, 10.166757172000075],
              [-2.792177980999895, 10.166573219000043],
              [-2.7921091689999, 10.166423950000024],
              [-2.792061427999954, 10.166320391000113],
              [-2.791968448999967, 10.166103249000059],
              [-2.79187533399994, 10.165908149000074],
              [-2.791747657999906, 10.165655382000068],
              [-2.791666106999912, 10.165391342000021],
              [-2.791636906999827, 10.165317114000118],
              [-2.791607707999901, 10.16524288700009],
              [-2.791503070999966, 10.165023055000063],
              [-2.79138165899991, 10.164694079000185],
              [-2.791310372999874, 10.164448259999972],
              [-2.791212294999866, 10.164242205999983],
              [-2.791142187999924, 10.163887187],
              [-2.791118007999955, 10.163756934000162],
              [-2.791027743999962, 10.163270696999973],
              [-2.791003731999922, 10.162932465999972],
              [-2.790931544999978, 10.16267059900008],
              [-2.79091486699997, 10.162528807000058],
              [-2.790895684999953, 10.16235881700004],
              [-2.790888158999962, 10.162256895000155],
              [-2.790881737999939, 10.162166671000136],
              [-2.790874595999981, 10.162066337000169],
              [-2.790872013999945, 10.161717673000112],
              [-2.790860184999929, 10.161540035000144],
              [-2.790797935999933, 10.158622762999983],
              [-2.790797741999938, 10.158513939000045],
              [-2.790797356999974, 10.15829629100017],
              [-2.790785064999966, 10.157883098000184],
              [-2.790737689999958, 10.15721663100004],
              [-2.790714138999931, 10.156927478000171],
              [-2.790667103999965, 10.156470394000166],
              [-2.790620394999905, 10.156228203000126],
              [-2.79059661499997, 10.155850888999964],
              [-2.790550010999937, 10.155666554000106],
              [-2.79049172099991, 10.155435447000059],
              [-2.790375048999977, 10.15505864000005],
              [-2.790293304999921, 10.15471194800017],
              [-2.790252631999977, 10.154574416000173],
              [-2.790211958999976, 10.154436885000052],
              [-2.790072141999929, 10.154046428000186],
              [-2.789932703999966, 10.153669746000105],
              [-2.789851116999955, 10.153405705000182],
              [-2.789815879999878, 10.153163451000012],
              [-2.789792502999887, 10.153014805000169],
              [-2.789734274999944, 10.152841554000133],
              [-2.789676122999936, 10.152635241000041],
              [-2.789594396999917, 10.152440076000119],
              [-2.78955963899989, 10.152365880000048],
              [-2.789524879999874, 10.152291682000111],
              [-2.789489952999929, 10.152210598000124],
              [-2.789455024999938, 10.152129514000023],
              [-2.789408378999895, 10.151992015000076],
              [-2.789373358999967, 10.151843433000067],
              [-2.789315208999938, 10.151590284000179],
              [-2.789291569999932, 10.15133970200003],
              [-2.789267795999933, 10.151017488000036],
              [-2.789278821999915, 10.150695085000052],
              [-2.789301629999955, 10.150397413000121],
              [-2.789289663999966, 10.150097176000088],
              [-2.789300705999949, 10.149777528000072],
              [-2.789334940999879, 10.149432958000034],
              [-2.789392446999841, 10.149077240000054],
              [-2.789391786999943, 10.148708065000164],
              [-2.789379435999933, 10.148283852000134],
              [-2.789332790999879, 10.147950743000024],
              [-2.789227152999843, 10.147204695000141],
              [-2.789203561999841, 10.146962377000136],
              [-2.789145333999898, 10.146687188000044],
              [-2.789121698999907, 10.146491707000109],
              [-2.789075036999975, 10.146296352000036],
              [-2.789040156999931, 10.146078891000059],
              [-2.789004998999928, 10.145850412000073],
              [-2.788969981999969, 10.145705962000022],
              [-2.788660863999951, 10.144444447000183],
              [-2.788631628999951, 10.144312363000097],
              [-2.788526975999901, 10.14404844600017],
              [-2.788433792999911, 10.143737631000135],
              [-2.78831749699998, 10.143473778000043],
              [-2.788236112999925, 10.143245550000188],
              [-2.788189499999874, 10.143113561000177],
              [-2.788142886999935, 10.142981571000121],
              [-2.788061132999928, 10.14262385700016],
              [-2.788002924999887, 10.142359688000056],
              [-2.787932828999942, 10.142051501000083],
              [-2.787862857999926, 10.141773620000095],
              [-2.787845218999962, 10.141624941000146],
              [-2.787827580999931, 10.141476264000175],
              [-2.787792364999973, 10.141143092000107],
              [-2.78775696699995, 10.140776860000017],
              [-2.787745263999966, 10.140523457000086],
              [-2.787733240999955, 10.140259035999975],
              [-2.787721049999902, 10.140019411000139],
              [-2.787732659999904, 10.139903634000063],
              [-2.787732400999971, 10.139708026000051],
              [-2.787763859999927, 10.139365183],
              [-2.78778931099987, 10.139087825000161],
              [-2.787812242999905, 10.138859029000059],
              [-2.787881297999945, 10.138627228000132],
              [-2.787973560999944, 10.138318157000072],
              [-2.788030929999877, 10.13798448099999],
              [-2.788123251999878, 10.13768643100002],
              [-2.788215436999963, 10.137410422000187],
              [-2.788319369999954, 10.137043432000041],
              [-2.788469580999959, 10.136709251000184],
              [-2.78861983399986, 10.13642190500002],
              [-2.788781485999891, 10.136090417000162],
              [-2.788908487999947, 10.135825239000042],
              [-2.789081870999894, 10.135400013000037],
              [-2.789243567999961, 10.135076790000028],
              [-2.789416706999873, 10.134709423000118],
              [-2.789601913999945, 10.134410867000099],
              [-2.789728809999872, 10.134134668000058],
              [-2.789826676999951, 10.133987190000084],
              [-2.790110467999909, 10.133559535000188],
              [-2.790283803999955, 10.133227983000097],
              [-2.790399506999961, 10.133084087999976],
              [-2.7905152099999, 10.13294019500006],
              [-2.790723483999898, 10.132688349000034],
              [-2.790873612999974, 10.132434065000098],
              [-2.790954528999919, 10.132261432000121],
              [-2.791035445999967, 10.132088799000144],
              [-2.791208581999911, 10.131721432000063],
              [-2.791301005999912, 10.131434401000035],
              [-2.791485967999961, 10.131100030000141],
              [-2.79160139999982, 10.130801853000094],
              [-2.79168227599996, 10.13059478200006],
              [-2.791809288999957, 10.130332359000136],
              [-2.791982744999928, 10.130124784000088],
              [-2.792156322999972, 10.129986084000166],
              [-2.792318612999907, 10.129872241000101],
              [-2.792469077999954, 10.12978050300012],
              [-2.792608021999968, 10.12971087000011],
              [-2.792874118999976, 10.129513809000059],
              [-2.792995693999899, 10.129398810000055],
              [-2.792996529999869, 10.129398019000121],
              [-2.7931172669999, 10.129283812000153],
              [-2.793290781999872, 10.128985319000037],
              [-2.793487104999883, 10.128639865000082],
              [-2.793590987999949, 10.128421648000028],
              [-2.793729745999883, 10.128021407000062],
              [-2.793810236999946, 10.127745461000131],
              [-2.793936968999958, 10.127182736],
              [-2.793959636999887, 10.126813434000042],
              [-2.793982504999917, 10.126624587000151],
              [-2.793992316999947, 10.126543557000161],
              [-2.794003844999907, 10.126448356000026],
              [-2.79399424699983, 10.126323923000143],
              [-2.793983349999905, 10.126214392],
              [-2.793971677999934, 10.126097069000025],
              [-2.793981395999936, 10.125873838000075],
              [-2.793969187999892, 10.1256314580001],
              [-2.793957483999975, 10.125424893000172],
              [-2.79393383199988, 10.125171556000055],
              [-2.79392206599988, 10.124953969000103],
              [-2.79389829299987, 10.124631757000088],
              [-2.793851310999969, 10.124276610000095],
              [-2.793827888999942, 10.124064597000029],
              [-2.793804467999848, 10.123852585000122],
              [-2.793780878999939, 10.123610267000117],
              [-2.793757227999947, 10.123356931000103],
              [-2.793745096999885, 10.123081490000175],
              [-2.793721444999903, 10.122726216000046],
              [-2.793709648999823, 10.122554088000015],
              [-2.793697854999891, 10.122381961000087],
              [-2.793720522999934, 10.122106331000055],
              [-2.793731503999936, 10.121681991000116],
              [-2.7938116549999, 10.120992787000091],
              [-2.793788003999907, 10.12063751300019],
              [-2.793833541999959, 10.120279106000112],
              [-2.793867850999959, 10.119948310999973],
              [-2.793925335999973, 10.119636675000152],
              [-2.793936621999933, 10.119314271000121],
              [-2.793924474999926, 10.119027810000148],
              [-2.793935594999937, 10.118777038000189],
              [-2.793923874999962, 10.11856771700019],
              [-2.793865364999931, 10.118201613000053],
              [-2.793841531999874, 10.117915214999982],
              [-2.79377174699988, 10.117615294000132],
              [-2.793736392999904, 10.117351],
              [-2.79367762399994, 10.116836122000166],
              [-2.793607821999899, 10.116478344000029],
              [-2.793603780999945, 10.116465492000032],
              [-2.793514418999905, 10.116181306000158],
              [-2.793444651999835, 10.115939240000046],
              [-2.793339990999868, 10.115664304000177],
              [-2.79330501499993, 10.115550159000065],
              [-2.793270038999935, 10.115436014000124],
              [-2.793188567999948, 10.115182992000143],
              [-2.793130164999923, 10.114827906000073],
              [-2.793013482999925, 10.114343649999967],
              [-2.792955156999938, 10.11415524500012],
              [-2.792896830999894, 10.113966840000046],
              [-2.792826942999966, 10.113702734000071],
              [-2.792757020999943, 10.11347168900005],
              [-2.792722068999979, 10.11328729000013],
              [-2.792628990999845, 10.113001270000098],
              [-2.792535894999958, 10.112704229000144],
              [-2.792511865999927, 10.112335180000059],
              [-2.792430397999908, 10.112082157000032],
              [-2.792395340999974, 10.11183163700008],
              [-2.792348547999893, 10.111611486000072],
              [-2.792325098999925, 10.111393963000069],
              [-2.792324899999926, 10.111256211000068],
              [-2.792289826999934, 10.111049771000012],
              [-2.792266118999919, 10.11068347600019],
              [-2.792230725999843, 10.110270408000133],
              [-2.792218627999887, 10.109992212000179],
              [-2.792218243999969, 10.109752725000021],
              [-2.792241236999928, 10.109454851000066],
              [-2.792252299999916, 10.109248160000107],
              [-2.792263450999883, 10.108947797000042],
              [-2.792251705999888, 10.108741230000078],
              [-2.792227998999977, 10.10847687200004],
              [-2.792216097999869, 10.108179389000043],
              [-2.792215535999901, 10.107881845000122],
              [-2.792226763999963, 10.107548420000114],
              [-2.792237794999892, 10.107179181000049],
              [-2.792237154999953, 10.106914698000082],
              [-2.792167190999919, 10.106628551000142],
              [-2.792085664999831, 10.106215733999989],
              [-2.792005271999926, 10.105880887000126],
              [-2.791980629999955, 10.105778249000139],
              [-2.791887478999968, 10.10542335100007],
              [-2.791765234999957, 10.105090652000172],
              [-2.791642991999879, 10.104757953000103],
              [-2.791538276999916, 10.10447199500004],
              [-2.791433484999971, 10.10417226200002],
              [-2.79135191599994, 10.103806281000061],
              [-2.791322647999948, 10.103690725999968],
              [-2.79129337899991, 10.103575173000081],
              [-2.791211930999907, 10.103278067000019],
              [-2.79118270299989, 10.103122565000149],
              [-2.791153473999884, 10.102967062000175],
              [-2.791130027999884, 10.102749539000172],
              [-2.791106572999979, 10.102554056000088],
              [-2.791083118999836, 10.102358573000174],
              [-2.791059491999931, 10.102061153000079],
              [-2.791059218999976, 10.101807688000122],
              [-2.791070326999943, 10.101554161000024],
              [-2.791070110999897, 10.10140952800009],
              [-2.791069811999876, 10.101209781000136],
              [-2.791034724999918, 10.100945484000022],
              [-2.791005465999888, 10.100807889000123],
              [-2.790964562999875, 10.100638674000038],
              [-2.790929631999916, 10.100543814000048],
              [-2.790836620999869, 10.100315649000038],
              [-2.79075523399996, 10.100131500000032],
              [-2.790627454999878, 10.099900768000111],
              [-2.790557760999889, 10.099792322000042],
              [-2.790488066999899, 10.099683875000039],
              [-2.790360241999963, 10.099546815000053],
              [-2.79015717599998, 10.099323379000055],
              [-2.789918912999951, 10.098846667000032],
              [-2.789885782999875, 10.098740998999972],
              [-2.789779091999947, 10.098543984000116],
              [-2.789698025999883, 10.098369860999981],
              [-2.78962835499982, 10.098214577000135],
              [-2.789553559999945, 10.098098425000103],
              [-2.789496431999908, 10.098009711000032],
              [-2.789446205999923, 10.097936649000019],
              [-2.789227691999884, 10.097652620000076],
              [-2.789093825999885, 10.097365367],
              [-2.789059094999971, 10.097286450000183],
              [-2.788952292999909, 10.096938807000072],
              [-2.788826230999973, 10.096552108000139],
              [-2.788733069999978, 10.096241290000023],
              [-2.788703723999959, 10.096040328000072],
              [-2.78867437699995, 10.095839367000053],
              [-2.788654346999977, 10.09576361500018],
              [-2.788604541999916, 10.095575258000167],
              [-2.788575375999926, 10.095403223000062],
              [-2.788546210999812, 10.095231189],
              [-2.788533776999941, 10.095148939000126],
              [-2.788499184999978, 10.09492012100003],
              [-2.788481649999881, 10.094816900000126],
              [-2.788464116999933, 10.094713680000098],
              [-2.788428792999866, 10.094358466000187],
              [-2.788417034999952, 10.094191848000094],
              [-2.788406017999932, 10.094035747000021],
              [-2.78836992, 10.09374971200009],
              [-2.788369498999941, 10.093438592000098],
              [-2.788357500999894, 10.09313008800018],
              [-2.788333862999934, 10.092829913000116],
              [-2.788356780999948, 10.092543261],
              [-2.788390912999944, 10.092281343000025],
              [-2.788402253999891, 10.092096691],
              [-2.788413592999916, 10.091912040000125],
              [-2.788413404999915, 10.091788874000031],
              [-2.788412944999948, 10.091487761000053],
              [-2.788423921999879, 10.091107501000067],
              [-2.788470002999873, 10.090798682000184],
              [-2.788481241999932, 10.090615409],
              [-2.788492479999889, 10.090432135000128],
              [-2.788509660999978, 10.09027087000004],
              [-2.788526842999886, 10.090109605000066],
              [-2.788607592999824, 10.089880495000159],
              [-2.788699909999934, 10.089637548000042],
              [-2.788792207999904, 10.089430417000017],
              [-2.788850020999973, 10.089275819000022],
              [-2.788907833999872, 10.089121222000131],
              [-2.788936585999977, 10.088994332000084],
              [-2.788965337999912, 10.08886744299997],
              [-2.789022763999981, 10.088591624000173],
              [-2.789092194999967, 10.088373596000054],
              [-2.789126697999961, 10.088230145000182],
              [-2.789161201999889, 10.08808669400014],
              [-2.789207350999959, 10.08793216000015],
              [-2.789230425999847, 10.087854893000042],
              [-2.789253500999962, 10.087777625000058],
              [-2.789270688999977, 10.087645288000033],
              [-2.789287877999868, 10.087512952000111],
              [-2.789368353999976, 10.08717915],
              [-2.789379661999931, 10.087012407000145],
              [-2.789390969999886, 10.086845664000123],
              [-2.789373347999856, 10.086651526000026],
              [-2.789355724999894, 10.086457389000088],
              [-2.78936711699987, 10.086278247000166],
              [-2.789378508999903, 10.086099105000073],
              [-2.789377890999958, 10.085790540000062],
              [-2.789411857999937, 10.085341277999987],
              [-2.789469316999885, 10.084996582000088],
              [-2.789526774999956, 10.084651886000131],
              [-2.789641901999971, 10.084103002000063],
              [-2.78969383399982, 10.083918129000097],
              [-2.789745766999943, 10.083733258000166],
              [-2.789826335999919, 10.083424251999986],
              [-2.789918632999957, 10.083115184],
              [-2.789970473999972, 10.082964751000077],
              [-2.790022313999884, 10.082814317999976],
              [-2.790172565999967, 10.082482895],
              [-2.79024190399997, 10.082326857000055],
              [-2.790311239999937, 10.082170819000112],
              [-2.790438045999906, 10.081825746999982],
              [-2.790541845999883, 10.081538656000134],
              [-2.790680351999924, 10.081196275000138],
              [-2.790784016999965, 10.08073561600014],
              [-2.790841595999837, 10.080495612000163],
              [-2.79088738299987, 10.080126183000061],
              [-2.790898675999927, 10.079850614000065],
              [-2.790967604999935, 10.079494836000151],
              [-2.790967168999941, 10.079219331000161],
              [-2.790966853999976, 10.079012702000057],
              [-2.790954763999878, 10.078632567000113],
              [-2.790919576999897, 10.078404086000148],
              [-2.790919249999945, 10.078092764000132],
              [-2.790860920999933, 10.077795532000039],
              [-2.790814049999938, 10.077567115000079],
              [-2.790744963999941, 10.07735115100013],
              [-2.790651261999813, 10.077083103000177],
              [-2.79061622699993, 10.07690559100007],
              [-2.790581191999877, 10.076728078000031],
              [-2.790511118999916, 10.076474991000111],
              [-2.790475996999874, 10.076108755],
              [-2.790406030999861, 10.075819852],
              [-2.790388273999895, 10.075549950000038],
              [-2.790370517999918, 10.075280049000185],
              [-2.790358381999909, 10.074993587000108],
              [-2.790322820999904, 10.074547456000062],
              [-2.790356867999947, 10.074018297000066],
              [-2.790379725999969, 10.073720624000032],
              [-2.790425211999946, 10.073249260000125],
              [-2.790453827999897, 10.073019056000078],
              [-2.790482443999963, 10.072788852000031],
              [-2.790516881999963, 10.072480097000152],
              [-2.790533842999935, 10.072255466000115],
              [-2.790550804999953, 10.072030835000135],
              [-2.790619854999875, 10.071641995000107],
              [-2.790688785999976, 10.071239381000169],
              [-2.790734512999961, 10.070858932000078],
              [-2.790757328999973, 10.070514424000123],
              [-2.790768666999952, 10.070376608000117],
              [-2.790780002999895, 10.070238793000158],
              [-2.790767868999865, 10.069952331000081],
              [-2.790755932999957, 10.069654846000105],
              [-2.790697472999966, 10.069285983000043],
              [-2.790592519999905, 10.068906350000134],
              [-2.790476142999978, 10.068471678000037],
              [-2.790336381999964, 10.068034377],
              [-2.790242934999867, 10.067519683000057],
              [-2.790126480999902, 10.067118073000074],
              [-2.790081854999926, 10.066941988000167],
              [-2.790029411999967, 10.066734263000114],
              [-2.789858525999932, 10.066221367000139],
              [-2.789672081999868, 10.065533605000041],
              [-2.789631327999871, 10.06540158200005],
              [-2.789590573999874, 10.065269558000125],
              [-2.789462737999884, 10.064969946000076],
              [-2.789352245999965, 10.064742744000057],
              [-2.789311733999909, 10.064659439000025],
              [-2.789195490999873, 10.064453437000054],
              [-2.78910254199991, 10.064178431000187],
              [-2.789009351999937, 10.063867611000035],
              [-2.788904299999956, 10.063421856000048],
              [-2.788868893999961, 10.062995009000133],
              [-2.788851275999889, 10.062800871000093],
              [-2.788833657999874, 10.062606733],
              [-2.78880943899992, 10.062042072000111],
              [-2.788808875999962, 10.061639833000129],
              [-2.788796628999933, 10.061182556],
              [-2.788773252999931, 10.060976051000068],
              [-2.788749876999873, 10.060769546000131],
              [-2.788702606999948, 10.06030970300003],
              [-2.788655657999868, 10.059860878999984],
              [-2.78862643899987, 10.059677823000129],
              [-2.788611828999819, 10.059586296000134],
              [-2.788597218999939, 10.059494767999979],
              [-2.788486716999955, 10.059218479000151],
              [-2.788376214999971, 10.058942191000028],
              [-2.78825990699994, 10.058518536000065],
              [-2.788166777999948, 10.058265572000096],
              [-2.788038601999972, 10.057910858000071],
              [-2.787980528999981, 10.0577610200001],
              [-2.787951491999877, 10.057686101],
              [-2.787922455999876, 10.05761118200013],
              [-2.787771532999841, 10.0573695490001],
              [-2.787620396999955, 10.05702598],
              [-2.787422664999838, 10.056660621000049],
              [-2.787248467999973, 10.05632544100007],
              [-2.78714400399997, 10.056159322000099],
              [-2.78703953899992, 10.055993204000174],
              [-2.786937020999972, 10.055850742000132],
              [-2.786868119999951, 10.05575490199999],
              [-2.786782336999977, 10.055633291000163],
              [-2.786721371999875, 10.055546024000137],
              [-2.786673949999965, 10.055478144],
              [-2.786628964999977, 10.055413863000126],
              [-2.786561720999941, 10.055318441000054],
              [-2.786445501999879, 10.055152838000083],
              [-2.786240215999953, 10.054814438000165],
              [-2.786187181999935, 10.054706507000049],
              [-2.786151368999981, 10.054633131000173],
              [-2.78610588499987, 10.054539443000067],
              [-2.786069620999967, 10.054462477000072],
              [-2.785947497999928, 10.054202780000026],
              [-2.785756852999953, 10.053684862000125],
              [-2.785721986999874, 10.053590023000027],
              [-2.785682307999878, 10.053450497000028],
              [-2.785655372999884, 10.05335578800009],
              [-2.785586707999812, 10.052997997000091],
              [-2.785580613999912, 10.052849256000115],
              [-2.785576042999878, 10.052737699000033],
              [-2.785609144999967, 10.05242481800019],
              [-2.785602954999888, 10.052234750000082],
              [-2.785596764999923, 10.052044682000144],
              [-2.785590800999955, 10.05189593900019],
              [-2.785584836999931, 10.051747198000044],
              [-2.785456952999823, 10.051436561],
              [-2.785387332999903, 10.051339132000066],
              [-2.785317714999849, 10.051241701000038],
              [-2.785213037999881, 10.051115531000107],
              [-2.785143412999901, 10.050989172000016],
              [-2.785073786999931, 10.050862813000151],
              [-2.784957361999943, 10.050519055000109],
              [-2.784835149999879, 10.050157419000072],
              [-2.78477404399996, 10.049976600000093],
              [-2.784712936999881, 10.04979578300015],
              [-2.784683776999884, 10.049698135000085],
              [-2.784654617999934, 10.049600486000088],
              [-2.784550016999901, 10.049234621999972],
              [-2.784479897999972, 10.048970511999983],
              [-2.784409955999877, 10.048532829000123],
              [-2.784281892999957, 10.048189132000175],
              [-2.784229555999957, 10.04799931299999],
              [-2.784177217999968, 10.047809493000102],
              [-2.783991104999927, 10.047430292000115],
              [-2.783840132999956, 10.047122536000018],
              [-2.783607684999936, 10.046649911000088],
              [-2.783445029999882, 10.046342216000141],
              [-2.783247552999967, 10.046020933000079],
              [-2.78310798799987, 10.045710358000065],
              [-2.782922195999959, 10.045389012000157],
              [-2.782794389999879, 10.045147252000049],
              [-2.782724550999944, 10.044957526000132],
              [-2.782654710999964, 10.044767800000045],
              [-2.782584913999926, 10.044506442000113],
              [-2.782537971999886, 10.0441595530001],
              [-2.782502790999899, 10.043826375000094],
              [-2.782502486999874, 10.043564643000082],
              [-2.782490305999943, 10.043275423000125],
              [-2.782495899999958, 10.043103200000189],
              [-2.782501494999963, 10.042930977000083],
              [-2.782512810999947, 10.042713263000167],
              [-2.782552987999964, 10.042482997000093],
              [-2.782604931999913, 10.042379401000062],
              [-2.782720684999902, 10.042196943000079],
              [-2.782756231999827, 10.042086179000137],
              [-2.782812800999977, 10.041909918000044],
              [-2.78283001799997, 10.041726611000058],
              [-2.782847234999963, 10.041543305000175],
              [-2.782869922999907, 10.041220837000139],
              [-2.782846060999873, 10.040876579000098],
              [-2.782851683999979, 10.040733284000055],
              [-2.782857305999926, 10.040589989000182],
              [-2.78286824199995, 10.040151870000045],
              [-2.782862181999974, 10.039985219000016],
              [-2.782856122999931, 10.039818568000044],
              [-2.782890393999935, 10.039429916000188],
              [-2.782936187999951, 10.039107324000156],
              [-2.782958826999959, 10.038625060000015],
              [-2.78295854299995, 10.038418429999979],
              [-2.78295825899994, 10.038211800000113],
              [-2.783003760999975, 10.037740434],
              [-2.783026259999872, 10.037280212000155],
              [-2.783083342999873, 10.036742662000051],
              [-2.783094680999852, 10.036552499000038],
              [-2.783106017999899, 10.036362337000128],
              [-2.783105463999959, 10.036006932000134],
              [-2.783116285999938, 10.035444836000181],
              [-2.783104439999931, 10.035260307999977],
              [-2.783091795999951, 10.034501347000173],
              [-2.783079805999932, 10.03431819799999],
              [-2.783079627999939, 10.034031669],
              [-2.78304444399987, 10.033745328000066],
              [-2.783032319999848, 10.0335139660001],
              [-2.783008898999924, 10.03319450000015],
              [-2.782985022999924, 10.032847486000037],
              [-2.782874013999958, 10.032113851000133],
              [-2.782844792999867, 10.031952834000037],
              [-2.78277505799997, 10.031757596999967],
              [-2.782635575999962, 10.031515898000123],
              [-2.78255403199995, 10.031138888000044],
              [-2.782495303999895, 10.030621245000134],
              [-2.782506061999925, 10.03015006600009],
              [-2.782482374999972, 10.029725909000092],
              [-2.782447276999847, 10.029359668999973],
              [-2.782411727999943, 10.028910779000171],
              [-2.782399949999899, 10.028738649000104],
              [-2.782381656999917, 10.027889778000144],
              [-2.78237057399997, 10.027714872000047],
              [-2.78236344499993, 10.027602367999975],
              [-2.782328449999966, 10.027378016000057],
              [-2.782310952999922, 10.027265839000108],
              [-2.782293454999888, 10.027153663000092],
              [-2.782273210999904, 10.026967747000128],
              [-2.782161361999897, 10.026793349000116],
              [-2.782037798999966, 10.026705955000182],
              [-2.781907231999924, 10.026562013000159],
              [-2.781828891999908, 10.02647564800003],
              [-2.781666366999957, 10.026236826000172],
              [-2.781612739999957, 10.02614803099999],
              [-2.781457391999936, 10.02589080600012],
              [-2.781271489999881, 10.025594252000133],
              [-2.781132012999819, 10.025352552000186],
              [-2.781045001999814, 10.025226284000041],
              [-2.78095799099998, 10.025100016000067],
              [-2.78088521799998, 10.025006824000172],
              [-2.780679468999949, 10.02474334700014],
              [-2.780424078999943, 10.02444716500014],
              [-2.780319620999933, 10.02430308300012],
              [-2.780267391999871, 10.024231041],
              [-2.78021516299998, 10.024159],
              [-2.779959953999935, 10.023895879000179],
              [-2.779669957999886, 10.023632943000109],
              [-2.779310114999873, 10.023240892000103],
              [-2.7790665469999, 10.022977707000052],
              [-2.778822778999881, 10.022725543000149],
              [-2.778579135999962, 10.022440318000122],
              [-2.778485457999977, 10.022353326000029],
              [-2.778405092999947, 10.022278698000036],
              [-2.7782309299999, 10.022095039000021],
              [-2.778074431999869, 10.021969141999989],
              [-2.777896149999947, 10.02181753300016],
              [-2.777830793999954, 10.021740395],
              [-2.777743652999959, 10.021637545000033],
              [-2.777553818999877, 10.021437438000078],
              [-2.777505845999883, 10.021355041999982],
              [-2.777441879999856, 10.021245180000165],
              [-2.777325723999923, 10.021050189000107],
              [-2.777209577999884, 10.02075325900006],
              [-2.777162989999965, 10.020592334000128],
              [-2.777139696999882, 10.020511873000146],
              [-2.777116402999923, 10.020431410000128],
              [-2.777038613999878, 10.020262095000078],
              [-2.777000235999878, 10.020178562000183],
              [-2.776837588999968, 10.019868105000171],
              [-2.776771139999937, 10.019707490000087],
              [-2.776686324999957, 10.019502485000089],
              [-2.776628092999943, 10.019272745000137],
              [-2.77660625599998, 10.019186593000086],
              [-2.776569859999825, 10.019043005000128],
              [-2.77649980699988, 10.018732052000075],
              [-2.776429674999861, 10.018159365000031],
              [-2.776382941999884, 10.017871706999983],
              [-2.776336207999918, 10.017584048000117],
              [-2.776327055999957, 10.017255133000162],
              [-2.776323790999868, 10.017137787000024],
              [-2.776315226999884, 10.016815689000055],
              [-2.776311559999954, 10.016677750000099],
              [-2.776301826999941, 10.016511060000141],
              [-2.776276021999934, 10.016069061000053],
              [-2.776286219999975, 10.015846938999971],
              [-2.77630502299985, 10.015437416000111],
              [-2.77630980399988, 10.01533326700013],
              [-2.776251672999877, 10.014853556000105],
              [-2.776227726999935, 10.014655950000133],
              [-2.776221273999909, 10.01455944200012],
              [-2.776180213999908, 10.013945386000103],
              [-2.776162218999843, 10.013795246000143],
              [-2.776144224999939, 10.013645107000059],
              [-2.776133354999956, 10.01355441100003],
              [-2.776115827999888, 10.013422259000095],
              [-2.77609830199998, 10.013290108000092],
              [-2.77601682999989, 10.01297921500003],
              [-2.775993496999831, 10.01288153400003],
              [-2.775970164999933, 10.012783852000098],
              [-2.775952643999915, 10.012680630000091],
              [-2.775935122999954, 10.012577406000105],
              [-2.775888576999932, 10.012395818000186],
              [-2.775795227999936, 10.01204917299998],
              [-2.775742327999922, 10.011866910000094],
              [-2.775700706999942, 10.011723506000124],
              [-2.775659084999973, 10.011580101999982],
              [-2.775623507999967, 10.01147170500019],
              [-2.775551084999904, 10.011338282000054],
              [-2.775453815999924, 10.01115401300018],
              [-2.77534796499998, 10.010925458000031],
              [-2.775295438999876, 10.010812043000158],
              [-2.7751252199999, 10.01043917100003],
              [-2.775074616999916, 10.010328323000067],
              [-2.774999138999874, 10.010173062000092],
              [-2.774961399999938, 10.010095432000071],
              [-2.774923660999946, 10.010017801000117],
              [-2.774737661999836, 10.009652364000033],
              [-2.774517219999893, 10.009342213000025],
              [-2.77441277399987, 10.009227055999986],
              [-2.77430832899995, 10.009111898000072],
              [-2.774051884999949, 10.008743193000043],
              [-2.773739280999905, 10.008274625000126],
              [-2.773530395999956, 10.007989207000151],
              [-2.773425954999937, 10.007850631000053],
              [-2.773321513999974, 10.007712055000127],
              [-2.773263462999978, 10.007614558000171],
              [-2.773205410999879, 10.007517060999987],
              [-2.773153223999941, 10.007431930000166],
              [-2.773101035999957, 10.00734680000005],
              [-2.772892019999972, 10.00703658600014],
              [-2.772636455999873, 10.00659988600006],
              [-2.772457082999949, 10.006298743000116],
              [-2.772404162999919, 10.00620989700019],
              [-2.772230083999943, 10.005935314000112],
              [-2.772032393999893, 10.00556718200005],
              [-2.771869901999878, 10.005274627000063],
              [-2.77170740899993, 10.004982073000065],
              [-2.771655112999952, 10.004844596000112],
              [-2.77160281599987, 10.004707119000045],
              [-2.77144011799993, 10.004432473000065],
              [-2.77125426899994, 10.004053256000191],
              [-2.771080051999888, 10.003800712999976],
              [-2.77092917199991, 10.00355906600015],
              [-2.770778252999946, 10.003342215000089],
              [-2.770697052999935, 10.003232442000126],
              [-2.770615853999857, 10.003122670000096],
              [-2.770469253999977, 10.002925425000058],
              [-2.770347637999976, 10.002768315000026],
              [-2.770272337999927, 10.002662178000037],
              [-2.770200844999977, 10.002587417000143],
              [-2.770126700999981, 10.002509882000027],
              [-2.769765271999916, 10.002131927000107],
              [-2.769658606999883, 10.002020948000109],
              [-2.76954835, 10.00191720100014],
              [-2.768651628999976, 10.001078157000165],
              [-2.768580975999953, 10.001012049],
              [-2.76848997799982, 10.000894635000066],
              [-2.768117392999898, 10.000413896000168],
              [-2.767839397999978, 9.999894357000073],
              [-2.767723286999967, 9.999749250000036],
              [-2.767608847999838, 9.999629064999965],
              [-2.76746899799997, 9.999482192000187],
              [-2.767329147999874, 9.999335320000114],
              [-2.766943693999963, 9.99893051],
              [-2.76684243099993, 9.998811794000119],
              [-2.766759029999946, 9.998713062000149],
              [-2.766457144999947, 9.998355683000114],
              [-2.766402115999824, 9.998290538000163],
              [-2.7662251779999, 9.998011653000106],
              [-2.765834185999893, 9.997395381000104],
              [-2.765369577999934, 9.996514534000141],
              [-2.765300897999907, 9.996384321999983],
              [-2.765051766999932, 9.995896609000056],
              [-2.76480263499991, 9.995408895000026],
              [-2.764735664999876, 9.995308323000074],
              [-2.764443395999933, 9.994869415000153],
              [-2.764084156999957, 9.994329936000042],
              [-2.76394467699987, 9.994117445000086],
              [-2.763330831999895, 9.993182282000078],
              [-2.763247301999911, 9.993085882000116],
              [-2.762879399999918, 9.992661297000041],
              [-2.762360169999965, 9.992062069000042],
              [-2.762206786999968, 9.991885055000125],
              [-2.762133598999981, 9.991819658000168],
              [-2.76181743199993, 9.991537153000138],
              [-2.761521972999958, 9.991273151000144],
              [-2.761432773999957, 9.991193448000047],
              [-2.761240444999942, 9.99102159500012],
              [-2.761144279999883, 9.990935669000123],
              [-2.760868475999928, 9.990692271000057],
              [-2.760688835999872, 9.990534800999967],
              [-2.760329556999977, 9.990219859000035],
              [-2.760239476999914, 9.990155694000123],
              [-2.760149396999907, 9.990091529000097],
              [-2.759669182999971, 9.989749464999989],
              [-2.759437121999952, 9.989563243000077],
              [-2.759205059999943, 9.989377023000088],
              [-2.75899723699996, 9.989210250999975],
              [-2.758932620999872, 9.989125928000021],
              [-2.758868004999954, 9.989041605000068],
              [-2.7585220199999, 9.988590099000021],
              [-2.75831335, 9.98829089000003],
              [-2.757762041999911, 9.987963929000046],
              [-2.757595028999958, 9.987864879000028],
              [-2.756506149999837, 9.987303061000091],
              [-2.756400331999942, 9.987242029000015],
              [-2.755897037999887, 9.986951746999978],
              [-2.755393744999878, 9.986661465000111],
              [-2.754988272999924, 9.986379820000025],
              [-2.754785536999918, 9.98623899800009],
              [-2.75458280099997, 9.986098176000155],
              [-2.753616486999931, 9.985332467000148],
              [-2.753447740999889, 9.985198751000098],
              [-2.753308270999924, 9.985088234999978],
              [-2.752821620999953, 9.984616909000067],
              [-2.752414645999977, 9.984222748000036],
              [-2.752334970999925, 9.984145583000156],
              [-2.751408043999902, 9.983216460000108],
              [-2.750562124999874, 9.982297928000151],
              [-2.75046707599995, 9.982164506000117],
              [-2.750313598999924, 9.981949505000159],
              [-2.750086944999907, 9.98163092100009],
              [-2.750021006999873, 9.981504629000142],
              [-2.749794294999958, 9.981046127000013],
              [-2.749760119999962, 9.98090799300013],
              [-2.749701327999901, 9.980712132],
              [-2.749519023999937, 9.980104793000123],
              [-2.749366460999909, 9.979331479999985],
              [-2.749321918999954, 9.979105708000134],
              [-2.749023527999952, 9.977529525000023],
              [-2.749008757999889, 9.977451505000147],
              [-2.748753723999926, 9.976224044000105],
              [-2.748637721999899, 9.975684642999966],
              [-2.748521719999928, 9.975145241000121],
              [-2.748162457999968, 9.974111186000073],
              [-2.748093228999892, 9.97388911199999],
              [-2.747872627999925, 9.973181463000174],
              [-2.747726543999931, 9.972508756000025],
              [-2.747675411999978, 9.97227329500015],
              [-2.747617499999876, 9.971722568000132],
              [-2.747559442999943, 9.971160820000023],
              [-2.747292952999942, 9.97043761000009],
              [-2.74712373899996, 9.970047126000111],
              [-2.746864109999876, 9.969447998000078],
              [-2.746699175999936, 9.969085602000121],
              [-2.746342462999905, 9.968301828000108],
              [-2.746029516999954, 9.967611920000024],
              [-2.745821116999935, 9.967048202000115],
              [-2.745762976999913, 9.966682071000093],
              [-2.745751351999957, 9.966210999000055],
              [-2.745727983999871, 9.965899789000048],
              [-2.745635342999947, 9.96561924700012],
              [-2.745542701999966, 9.965338706000068],
              [-2.745310866999887, 9.964499594000074],
              [-2.745067266999911, 9.963685340000041],
              [-2.744727762999958, 9.96304665100007],
              [-2.744569070999887, 9.962503224000102],
              [-2.744522651999944, 9.962283053000078],
              [-2.744450282999935, 9.962168059000021],
              [-2.74440686, 9.962099063000153],
              [-2.74415191199995, 9.961926819000098],
              [-2.743885352999882, 9.961710552000056],
              [-2.743798462999905, 9.961571871000047],
              [-2.743711571999938, 9.961433189000104],
              [-2.743413030999818, 9.960487773000068],
              [-2.743359485999918, 9.960318209000093],
              [-2.743302058999973, 9.960117120000064],
              [-2.743242183999882, 9.959631007000041],
              [-2.74317842499994, 9.959113369000022],
              [-2.743259178999949, 9.958619774000113],
              [-2.74342143299998, 9.95803483300017],
              [-2.74347914699996, 9.957323701000121],
              [-2.743442731999949, 9.956826990000138],
              [-2.743432707999943, 9.956690256000172],
              [-2.743479011999966, 9.956152757000041],
              [-2.743560065999816, 9.955871308000099],
              [-2.743653833999929, 9.95555718300011],
              [-2.743797397999913, 9.955188168000063],
              [-2.743953674999943, 9.954786477000141],
              [-2.744150158999958, 9.953958903000057],
              [-2.744208127999968, 9.953672064000045],
              [-2.744266094999944, 9.953385225000034],
              [-2.74426020299984, 9.953109739000126],
              [-2.744254311999953, 9.952834253999981],
              [-2.744254111999965, 9.952338326000188],
              [-2.744213162999927, 9.951981781000086],
              [-2.744228797999881, 9.951870221000092],
              [-2.744393045999971, 9.950698279999983],
              [-2.744477159999917, 9.950113514000122],
              [-2.744561271999942, 9.949528748000034],
              [-2.744581267999934, 9.949389742000108],
              [-2.744601262999879, 9.949250734000145],
              [-2.744856389999939, 9.948481949000041],
              [-2.744948390999923, 9.948204718000113],
              [-2.745237830999884, 9.947555745000159],
              [-2.745527270999958, 9.946906771999977],
              [-2.746233684999879, 9.946010414000114],
              [-2.74658130499995, 9.945547956000041],
              [-2.746743075999916, 9.945332741000073],
              [-2.747119544999976, 9.94510347500011],
              [-2.747496012999875, 9.944874209000147],
              [-2.747606279999957, 9.944822718000069],
              [-2.748086737999813, 9.944598363000068],
              [-2.748801196999864, 9.944453875000022],
              [-2.748951213999931, 9.944386550000047],
              [-2.74921596199988, 9.944253577000097],
              [-2.749603986999944, 9.944058688000098],
              [-2.750060502999872, 9.943683706000115],
              [-2.750680920999969, 9.943174163000151],
              [-2.751774815999966, 9.942083422000053],
              [-2.751929206999932, 9.941886046000093],
              [-2.752151753999954, 9.941601539000089],
              [-2.752204519999964, 9.94145903899999],
              [-2.752257286999907, 9.94131654],
              [-2.752296393999927, 9.941210927999975],
              [-2.752441034999947, 9.940820317000089],
              [-2.752614559999813, 9.940268378000155],
              [-2.752796688999979, 9.939745471000151],
              [-2.752973416999907, 9.939131376000034],
              [-2.753112460999887, 9.938912992000098],
              [-2.753251504999923, 9.938694607000059],
              [-2.75345981699985, 9.93847034900017],
              [-2.753668129999937, 9.938246090000177],
              [-2.754235643999891, 9.937317387000178],
              [-2.754484530999889, 9.937007505999986],
              [-2.754733417999887, 9.936697626000125],
              [-2.754993959999865, 9.936358753999969],
              [-2.7552545019999, 9.936019883000085],
              [-2.755798825999932, 9.935124362000181],
              [-2.756111331999932, 9.934433937000165],
              [-2.75633120099991, 9.93381287599999],
              [-2.756481799999961, 9.933181158000139],
              [-2.756852152999954, 9.932493184000066],
              [-2.757512039999938, 9.931481340000175],
              [-2.759320786999922, 9.928610320000132],
              [-2.759508686999936, 9.92834590699999],
              [-2.759793200999923, 9.927945538000188],
              [-2.759916196999882, 9.927578565000147],
              [-2.760001337999938, 9.927324538000164],
              [-2.760371834999944, 9.92612961500015],
              [-2.7604273419999, 9.925995540000145],
              [-2.760557017999872, 9.925682310000184],
              [-2.760649390999902, 9.92514181700011],
              [-2.760776789999966, 9.924281543000177],
              [-2.760984831999963, 9.923123291000081],
              [-2.761094771999979, 9.922709442000041],
              [-2.761204712999927, 9.922295593000172],
              [-2.761459336999963, 9.921583430000112],
              [-2.761592399999927, 9.921060631000103],
              [-2.761725462999948, 9.920537833000026],
              [-2.762153721999937, 9.919298525000158],
              [-2.762679079999884, 9.917875668000136],
              [-2.762836619999973, 9.917448992000175],
              [-2.76318668599987, 9.916362034000144],
              [-2.763288141999908, 9.916047009000067],
              [-2.763693178999858, 9.914818843000091],
              [-2.763961095999889, 9.913911473000155],
              [-2.764086711999823, 9.913486041999988],
              [-2.764173502999881, 9.913193541000055],
              [-2.764260293999882, 9.912901040000122],
              [-2.764277552999886, 9.912596506000114],
              [-2.7642948109999, 9.912291973000095],
              [-2.764268505999951, 9.912126073000025],
              [-2.764236758999971, 9.911925844000052],
              [-2.764085970999929, 9.91143071200014],
              [-2.764051220999818, 9.911322066000025],
              [-2.764009487999829, 9.911191586000086],
              [-2.76394028999988, 9.91098386800013],
              [-2.76389442899989, 9.910899691000168],
              [-2.763710745999958, 9.910572137000031],
              [-2.763587953999945, 9.91041943700003],
              [-2.763570540999922, 9.910305188999985],
              [-2.763553127999899, 9.910190943000146],
              [-2.763622521999878, 9.910030780000113],
              [-2.763630772999932, 9.909751052000047],
              [-2.763631435999969, 9.909620346000111],
              [-2.763552789999949, 9.909047492000127],
              [-2.763511077999965, 9.908743658000049],
              [-2.763504456999954, 9.908510496000076],
              [-2.763494532999857, 9.907447130000037],
              [-2.7635040399999, 9.906115194000165],
              [-2.763482556999918, 9.90572523000003],
              [-2.763452289999975, 9.905365276000111],
              [-2.763436011999886, 9.905171690000088],
              [-2.76345915099995, 9.904821666000032],
              [-2.763482289999899, 9.904471641000157],
              [-2.76348223399998, 9.904253986000185],
              [-2.7634789519999, 9.903748478000068],
              [-2.76347802, 9.903441258000044],
              [-2.763470408999979, 9.903140970000095],
              [-2.763354580999931, 9.902670449000141],
              [-2.763099547999957, 9.902002288],
              [-2.762879245999841, 9.901659051000138],
              [-2.76267908799997, 9.901323319000085],
              [-2.762242276999871, 9.900590641000179],
              [-2.762022152999975, 9.900263933000133],
              [-2.761802027999977, 9.899937224000041],
              [-2.761466086999974, 9.899580817000128],
              [-2.761432612999954, 9.899486788000047],
              [-2.761327070999926, 9.899190315000169],
              [-2.761326972999939, 9.899041538000176],
              [-2.761326874999895, 9.898892760000138],
              [-2.76132668199989, 9.898661329000049],
              [-2.761234273999889, 9.898364258000072],
              [-2.761210789999893, 9.898031007000157],
              [-2.761245530999929, 9.897869649000143],
              [-2.761280271999965, 9.897708292000175],
              [-2.761430711999878, 9.897261170000149],
              [-2.761778011999923, 9.89595341099999],
              [-2.761920260999887, 9.895348289000026],
              [-2.762356268999895, 9.893470750000063],
              [-2.762460432999887, 9.892161510000051],
              [-2.762475473999928, 9.891971988000023],
              [-2.762564335999969, 9.890852271000028],
              [-2.762552493999976, 9.890406],
              [-2.762644986999817, 9.889187741000114],
              [-2.762749101999816, 9.887983198000029],
              [-2.76280677799997, 9.886960737000095],
              [-2.762777660999973, 9.886449809000055],
              [-2.76274854299993, 9.885938882000176],
              [-2.762551456999972, 9.884881939000024],
              [-2.762319640999976, 9.88398773],
              [-2.761902481999925, 9.883066940000049],
              [-2.761427622999975, 9.882022468000116],
              [-2.760859742999969, 9.880678171],
              [-2.760234191999928, 9.879394788000127],
              [-2.759353827999973, 9.877280677000101],
              [-2.758820624999942, 9.875972009000179],
              [-2.758451949999881, 9.875112313000102],
              [-2.758414166999955, 9.875024212000028],
              [-2.758359432999953, 9.874896580000097],
              [-2.758330892999936, 9.874809624000022],
              [-2.758299667999893, 9.874712709999983],
              [-2.758270827999922, 9.87462319500014],
              [-2.758195150999939, 9.874388305000082],
              [-2.758076331999973, 9.874085858000171],
              [-2.758028328999842, 9.873964397000179],
              [-2.757876010999951, 9.873675355000046],
              [-2.757829325999978, 9.873586765000027],
              [-2.75766925399995, 9.87328300900009],
              [-2.757529123999916, 9.873017096000012],
              [-2.757341802999974, 9.872663423000176],
              [-2.75727418699995, 9.872550815000068],
              [-2.756780822999929, 9.871729160000029],
              [-2.756445757999813, 9.871171142000037],
              [-2.756028497999921, 9.870472886000187],
              [-2.755437997999934, 9.869484726000167],
              [-2.755035311999905, 9.86876931099999],
              [-2.754777741999931, 9.86831171100016],
              [-2.754256601999941, 9.867372158000137],
              [-2.753735319999976, 9.866371991999984],
              [-2.75329504799987, 9.865487119000079],
              [-2.75261571599998, 9.864160969000125],
              [-2.752484264999964, 9.863904360000106],
              [-2.751638411999977, 9.862239124000155],
              [-2.751549171999955, 9.86208653500006],
              [-2.750758076999944, 9.860733864000167],
              [-2.750185042999874, 9.859681282000111],
              [-2.750040135999939, 9.859415109],
              [-2.7499977499999, 9.859346954000159],
              [-2.749542540999926, 9.858614849000048],
              [-2.749182970999925, 9.858036459000175],
              [-2.749031903999935, 9.857714764000093],
              [-2.74871961499997, 9.857049755000048],
              [-2.748204378999958, 9.856091076000041],
              [-2.747966529999815, 9.855648519000113],
              [-2.747461997999892, 9.854795815000102],
              [-2.747225432999869, 9.85439599700004],
              [-2.746973512999887, 9.854080456000133],
              [-2.746721593999837, 9.853764915000056],
              [-2.746217753999872, 9.85313383200014],
              [-2.745634452999923, 9.85255542200008],
              [-2.745302849999973, 9.852226600000051],
              [-2.744538492999936, 9.85158308299998],
              [-2.743774135999956, 9.850939566000136],
              [-2.742905552999844, 9.850336531000039],
              [-2.74281021399986, 9.850270340000179],
              [-2.742036970999891, 9.849733497000102],
              [-2.741284343999894, 9.849205629000096],
              [-2.740803761999928, 9.848871462999966],
              [-2.740658961999941, 9.848770779000176],
              [-2.739882959999932, 9.848022612000023],
              [-2.739298179999878, 9.84741534900013],
              [-2.738713399999881, 9.84680808700017],
              [-2.738296387999924, 9.846256441000151],
              [-2.737879376999899, 9.845704794000085],
              [-2.736454988999981, 9.843786242000135],
              [-2.735747383999978, 9.843069742000182],
              [-2.735412695999912, 9.842730847000155],
              [-2.734648361999916, 9.842134140000042],
              [-2.733629429999894, 9.841144742999973],
              [-2.732506126999965, 9.840067712000121],
              [-2.732180149999977, 9.83977419900009],
              [-2.731371225999908, 9.839045838000118],
              [-2.730525809999961, 9.838080337000122],
              [-2.729888866999943, 9.837185402000046],
              [-2.728962470999932, 9.835553551000089],
              [-2.728823441999964, 9.835292712000125],
              [-2.72814948499996, 9.834023358000081],
              [-2.72770682099997, 9.832922559000053],
              [-2.727544661999957, 9.83229306800007],
              [-2.726986924999892, 9.830130525000129],
              [-2.726912278999919, 9.829778099000066],
              [-2.726796537999974, 9.828938353000069],
              [-2.726796545999946, 9.828136588000064],
              [-2.726796296999908, 9.827285232000179],
              [-2.72685422499984, 9.826469396000164],
              [-2.727108813999962, 9.825575410000056],
              [-2.727699295999969, 9.823679573000163],
              [-2.728139151999926, 9.822628973000121],
              [-2.728579007999883, 9.821578374000126],
              [-2.728816324999912, 9.820895248999989],
              [-2.729053640999837, 9.820212125000126],
              [-2.729655640999908, 9.818982987000027],
              [-2.730280836999953, 9.817967257000021],
              [-2.730906032999883, 9.816951528000118],
              [-2.731936462999954, 9.815527335000127],
              [-2.732202740999981, 9.815257342000052],
              [-2.73249578899987, 9.814967237000133],
              [-2.732576094999956, 9.814906901000143],
              [-2.732683169999916, 9.814826452000148],
              [-2.732889400999966, 9.814671506000082],
              [-2.733075455999881, 9.814605113000027],
              [-2.733441444999869, 9.814480930000116],
              [-2.733649910999816, 9.81442889200008],
              [-2.73385837599983, 9.814376855000148],
              [-2.734159470999941, 9.814367048000122],
              [-2.734610955999926, 9.814480456000126],
              [-2.735097153999902, 9.814491744000122],
              [-2.735803359999977, 9.814675480000062],
              [-2.735915187999922, 9.814675646000069],
              [-2.736220226999933, 9.814676099000053],
              [-2.736637093999946, 9.81467671900009],
              [-2.737273864999963, 9.814596309000024],
              [-2.737580766999884, 9.814418404000094],
              [-2.73773421899989, 9.814329451000106],
              [-2.737887669999964, 9.814240498000117],
              [-2.738362115999962, 9.813929483000095],
              [-2.739219043999981, 9.81343466300018],
              [-2.739902041999926, 9.813147373000049],
              [-2.740185683999926, 9.812987493000094],
              [-2.740469325999868, 9.812827614000128],
              [-2.740567592999923, 9.812735944000167],
              [-2.741036555999926, 9.812298460000022],
              [-2.741534526999885, 9.811805479000043],
              [-2.742229050999924, 9.811482308],
              [-2.743101082999942, 9.811185],
              [-2.743271077999964, 9.811127043],
              [-2.744197328999974, 9.810827474000178],
              [-2.744724176999966, 9.810620879000055],
              [-2.745251026999938, 9.810414284000103],
              [-2.745841217999896, 9.810011744],
              [-2.746835977999979, 9.809152427000072],
              [-2.747288284999911, 9.808761704000176],
              [-2.747913509999933, 9.808370001000185],
              [-2.748295632999941, 9.808186190000129],
              [-2.748758742999883, 9.808150739000155],
              [-2.749149208999882, 9.808169143000043],
              [-2.749395609999908, 9.808026227000141],
              [-2.749592863999908, 9.807969901000092],
              [-2.749716741999919, 9.807934526999986],
              [-2.749813591999953, 9.807922143000042],
              [-2.749926579999965, 9.807908468000051],
              [-2.75003583299997, 9.80789525300014],
              [-2.750187278999874, 9.807875587000126],
              [-2.75028023099992, 9.807828375000099],
              [-2.750750188999916, 9.807567388000109],
              [-2.751253791999943, 9.80719283799999],
              [-2.75175739599996, 9.806818289000034],
              [-2.752350250999882, 9.806408683000086],
              [-2.752637297999911, 9.806210361000183],
              [-2.752891838999915, 9.805751687000054],
              [-2.75318099999987, 9.805223953999985],
              [-2.753554677999944, 9.804797893],
              [-2.753736881999942, 9.804590147000056],
              [-2.753951035999876, 9.804458169000156],
              [-2.754165189999924, 9.804326191000087],
              [-2.754547316999947, 9.804029412000091],
              [-2.754755528999851, 9.803593019000175],
              [-2.75536920299993, 9.802972686000089],
              [-2.75555448199998, 9.802867074000062],
              [-2.756237442999975, 9.802477772000145],
              [-2.757175194999945, 9.801880556000128],
              [-2.757835028999921, 9.801422537000178],
              [-2.758193809999852, 9.800979852000012],
              [-2.758552590999955, 9.80053716599997],
              [-2.759050248999927, 9.799630893000085],
              [-2.759299092999925, 9.799279697000145],
              [-2.759501616999955, 9.798993875000122],
              [-2.759547936999979, 9.798928502000138],
              [-2.759686807999913, 9.798579254000174],
              [-2.75982567899996, 9.79823000600004],
              [-2.759987629999955, 9.797022405000064],
              [-2.759952687999885, 9.796025216999965],
              [-2.759906335999972, 9.795663153000021],
              [-2.759859984999935, 9.795301089000077],
              [-2.759894677999967, 9.794554261000144],
              [-2.760044963999974, 9.793933569000103],
              [-2.760392313999944, 9.793003279000061],
              [-2.761260326999889, 9.791533033000064],
              [-2.761401041999932, 9.791341707000072],
              [-2.762383150999824, 9.790006360000177],
              [-2.762637796999911, 9.789736410000046],
              [-2.762892440999963, 9.789466461000131],
              [-2.763251053999966, 9.78873172700014],
              [-2.763876326999935, 9.787882648000107],
              [-2.764825345999896, 9.787078724000025],
              [-2.765496632999941, 9.786331345000122],
              [-2.765570841999875, 9.786245312000176],
              [-2.766191307999975, 9.785525985000106],
              [-2.766353322999976, 9.785205545000054],
              [-2.766515337999863, 9.784885105000171],
              [-2.766943302999835, 9.783609998000088],
              [-2.767325169999879, 9.782219415000043],
              [-2.767421328999944, 9.781719758000179],
              [-2.767614433999938, 9.780716353000059],
              [-2.767973118999976, 9.779210173000138],
              [-2.768181331999926, 9.778590559000179],
              [-2.768285438999953, 9.778280751000125],
              [-2.768389544999934, 9.777970944000174],
              [-2.768620865999878, 9.777280953000172],
              [-2.768806037999923, 9.776580181000099],
              [-2.768898782999884, 9.776260101000105],
              [-2.768991294999978, 9.776076402000058],
              [-2.769083805999912, 9.775892703000068],
              [-2.769685617999869, 9.774881187000062],
              [-2.770055898999885, 9.774306189000072],
              [-2.770298957999842, 9.773572054000169],
              [-2.770646184999976, 9.772790540000187],
              [-2.77116704499997, 9.772205115000133],
              [-2.771687903999919, 9.771619689000033],
              [-2.772463497999979, 9.771056355000098],
              [-2.773088533999896, 9.770598500000176],
              [-2.773293948999935, 9.770354288000021],
              [-2.773499362999871, 9.770110076000094],
              [-2.77391019199996, 9.76962165100008],
              [-2.774650788999907, 9.768540529000177],
              [-2.774940165999965, 9.768036205000158],
              [-2.775229542999966, 9.767531882000071],
              [-2.775877663999836, 9.766084807000027],
              [-2.77609162899995, 9.765579499000126],
              [-2.776305592999961, 9.765074192000157],
              [-2.776525367999966, 9.763626595000176],
              [-2.776664136999898, 9.762661569999978],
              [-2.776802906999933, 9.76169654600011],
              [-2.776853460999917, 9.761498816000085],
              [-2.777207784999916, 9.760112981000077],
              [-2.77756649, 9.759274924000124],
              [-2.777925193999977, 9.758436867000114],
              [-2.778561431999947, 9.757011892000094],
              [-2.779035901999919, 9.756425326000056],
              [-2.779510370999958, 9.755838759000085],
              [-2.78000224699997, 9.755236948000118],
              [-2.780126702999951, 9.755084674000159],
              [-2.78021830299997, 9.754972598000109],
              [-2.78049412099989, 9.754635136000047],
              [-2.781617072999893, 9.75386334000018],
              [-2.782085736999932, 9.753468281999972],
              [-2.782320069999969, 9.753270753999971],
              [-2.782554401999903, 9.753073225000094],
              [-2.782785858999944, 9.752881911000031],
              [-2.782901587999959, 9.752786254000057],
              [-2.783017315999928, 9.752690596000093],
              [-2.783310806999964, 9.75262569299997],
              [-2.783583306999958, 9.752530593000131],
              [-2.784040923999896, 9.752360136000107],
              [-2.784122490999891, 9.752340423000021],
              [-2.784366231999968, 9.752215166000042],
              [-2.784499529999891, 9.752159366000058],
              [-2.784632826999939, 9.752103566000017],
              [-2.784830237999927, 9.751989572000014],
              [-2.785074120999923, 9.751842273000023],
              [-2.785294499999964, 9.751706118000186],
              [-2.785416526999939, 9.751620070000172],
              [-2.785538554999903, 9.751534021000055],
              [-2.78579399299997, 9.751364621000107],
              [-2.78603787499992, 9.751217321000183],
              [-2.786270112999944, 9.751067326999987],
              [-2.786502519999885, 9.750851210000178],
              [-2.786677529999906, 9.750646413000027],
              [-2.786817317999919, 9.750452821000124],
              [-2.787027596999906, 9.75009906299999],
              [-2.787121171999957, 9.749938775000089],
              [-2.787273076999895, 9.749673486000063],
              [-2.78744823999989, 9.74939981000017],
              [-2.787692620999962, 9.749183629000129],
              [-2.78800567199994, 9.749035966000065],
              [-2.788260850999905, 9.748924424000052],
              [-2.788899582999932, 9.748491274000116],
              [-2.789039230999833, 9.748377581000057],
              [-2.789259418999848, 9.748263466000083],
              [-2.789434067999821, 9.748105507000105],
              [-2.789620099999922, 9.747933713000066],
              [-2.78981821799988, 9.747692978000146],
              [-2.789888140999892, 9.74760169100017],
              [-2.789958062999915, 9.747510405000071],
              [-2.789999176999856, 9.747408250000035],
              [-2.790040291999958, 9.747306094000123],
              [-2.790169048999871, 9.747029906000023],
              [-2.790251507999926, 9.746811818000083],
              [-2.79032150199987, 9.746709511000063],
              [-2.790391495999813, 9.746607204000043],
              [-2.790531855999973, 9.746366771000112],
              [-2.790602337999928, 9.746148746000074],
              [-2.790627336999876, 9.745840041000065],
              [-2.790629463999949, 9.745506659000171],
              [-2.790608387999896, 9.74515135900009],
              [-2.79063426099998, 9.744737954000073],
              [-2.790635228999975, 9.744553355000164],
              [-2.79067301099991, 9.744037948000084],
              [-2.790674180999929, 9.743842328000028],
              [-2.790676196999925, 9.743544764000092],
              [-2.790689692999877, 9.743211322000036],
              [-2.790680275999875, 9.742844940000111],
              [-2.790658547999954, 9.742627399000128],
              [-2.790625060999957, 9.742442981000181],
              [-2.790585645999954, 9.742265482000164],
              [-2.790546229999904, 9.74208798300009],
              [-2.790501512999924, 9.741823725000074],
              [-2.790457312999934, 9.741501607000089],
              [-2.790355637999937, 9.741077851],
              [-2.790219365999974, 9.740684582000085],
              [-2.790082262999874, 9.740387748000046],
              [-2.789921704999927, 9.740168180000183],
              [-2.789761104999968, 9.739948611000045],
              [-2.789624120999974, 9.739673816000106],
              [-2.789499405999891, 9.739258445000132],
              [-2.789477662999957, 9.739029884000104],
              [-2.789456505999965, 9.738707644000101],
              [-2.789400462999879, 9.73841038400019],
              [-2.789275477999922, 9.738041852000094],
              [-2.789196235999953, 9.737763999000094],
              [-2.789047836999941, 9.737431406000042],
              [-2.788985314999877, 9.737241629000039],
              [-2.788922793999973, 9.737051852000036],
              [-2.788763227999823, 9.736708297000121],
              [-2.788557659999867, 9.736282328000186],
              [-2.788409533999868, 9.735902896000027],
              [-2.788272664999909, 9.735592283000187],
              [-2.788148210999907, 9.735119052000187],
              [-2.788046200999929, 9.734786215000099],
              [-2.787874975999955, 9.734395882000058],
              [-2.787726413999906, 9.734042510000109],
              [-2.787521931999947, 9.733499557000016],
              [-2.787396481999963, 9.733246741000073],
              [-2.787202803999946, 9.732762851000189],
              [-2.787128599999846, 9.732595176000075],
              [-2.787054394999927, 9.732427501000132],
              [-2.78675656199988, 9.73193313400003],
              [-2.786551000999907, 9.731507164000107],
              [-2.786368784999922, 9.731081070000016],
              [-2.78617543699994, 9.730617731000166],
              [-2.786163436999914, 9.73058897400017],
              [-2.785957547999942, 9.73020708700011],
              [-2.785739529999944, 9.729943732999971],
              [-2.78554504199991, 9.729608623000161],
              [-2.785269997999876, 9.72917199300008],
              [-2.78513230599998, 9.728999139000166],
              [-2.784994612999924, 9.728826283999979],
              [-2.784708444999978, 9.728329099000177],
              [-2.784559467999884, 9.728098445000057],
              [-2.784466276999922, 9.727955871000177],
              [-2.784410505999915, 9.727870546000133],
              [-2.784227590999876, 9.727524354000082],
              [-2.784044920999975, 9.72716714],
              [-2.783884661999935, 9.726903482000068],
              [-2.78372387099995, 9.726694931000111],
              [-2.783621151999967, 9.72648883200003],
              [-2.78355260799998, 9.726344544000028],
              [-2.783484063999936, 9.726200257000073],
              [-2.783382377999942, 9.725820577999969],
              [-2.783326858999942, 9.725407597000185],
              [-2.783294743999875, 9.725005514000031],
              [-2.783320007999919, 9.72463894800012],
              [-2.783333961999915, 9.724283463000063],
              [-2.783359943999926, 9.72379015700011],
              [-2.783455280999931, 9.723332305999975],
              [-2.783563738999931, 9.722631935000038],
              [-2.783577690999948, 9.722218591000114],
              [-2.783591799999954, 9.7217942260001],
              [-2.783651448999933, 9.721521156000051],
              [-2.783886683999924, 9.720891757000118],
              [-2.783968741999956, 9.720708113],
              [-2.784050798999829, 9.720524467000132],
              [-2.78412302199996, 9.720052961000022],
              [-2.784275793999882, 9.719641649000096],
              [-2.784463594999977, 9.719183316000056],
              [-2.784551465999925, 9.718995507999978],
              [-2.78463933799992, 9.718807701000173],
              [-2.784722212999952, 9.718520733000048],
              [-2.784852373999968, 9.718026884000153],
              [-2.784959667999885, 9.717524890000163],
              [-2.785124683999925, 9.717008818000124],
              [-2.785290056999941, 9.716412845000093],
              [-2.785361560999832, 9.716068078999967],
              [-2.785468566999896, 9.715610168000183],
              [-2.785644692999881, 9.715151896000123],
              [-2.785800528999914, 9.714832723000086],
              [-2.785867216999918, 9.714696137000146],
              [-2.786066829999925, 9.714215701],
              [-2.78613910699994, 9.713755215000106],
              [-2.786257537999973, 9.71330826500008],
              [-2.786410622999938, 9.712850113000172],
              [-2.786658535999948, 9.71200299200018],
              [-2.786799533999954, 9.711671639000031],
              [-2.786870404999888, 9.711420552000107],
              [-2.786900392999883, 9.711237180000012],
              [-2.786930378999955, 9.711053806000166],
              [-2.786978022999904, 9.710824881000121],
              [-2.787013808999916, 9.710634590000097],
              [-2.787049595999974, 9.71044429799997],
              [-2.787097943999868, 9.710135471000115],
              [-2.787099648999913, 9.709826887000133],
              [-2.787090043999854, 9.709529382000142],
              [-2.787070387999904, 9.708964681000055],
              [-2.787014470999907, 9.708631602000082],
              [-2.78694754299994, 9.708218680000016],
              [-2.786903285999927, 9.707932376000144],
              [-2.786882539999908, 9.707530234000103],
              [-2.786883521999926, 9.707375941],
              [-2.786884504999875, 9.707221648000143],
              [-2.786934085999917, 9.706670363000114],
              [-2.787064785999974, 9.706074570999988],
              [-2.787137091999909, 9.705627860999982],
              [-2.787139643999979, 9.705167740000036],
              [-2.787176047999935, 9.704905811000117],
              [-2.787259305999896, 9.704527924000047],
              [-2.787226487999931, 9.704205743000102],
              [-2.787194116999956, 9.703861520000146],
              [-2.787173228999961, 9.703481420000117],
              [-2.787163379999868, 9.703194937000149],
              [-2.787119796999889, 9.702781894000168],
              [-2.787087380999878, 9.70237981299999],
              [-2.787078365999946, 9.701988630000074],
              [-2.78704652599987, 9.701531442999965],
              [-2.787037709999936, 9.701071381000133],
              [-2.787027272999978, 9.700882708000052],
              [-2.787016836999896, 9.700694036000073],
              [-2.78700757699994, 9.70031387500012],
              [-2.787032696999972, 9.699969352000153],
              [-2.787069314999826, 9.699649565000073],
              [-2.787235488999897, 9.698984710000048],
              [-2.787283275999926, 9.698733743000162],
              [-2.787342789999968, 9.698435878000112],
              [-2.787426278999874, 9.698044213000117],
              [-2.787404572999947, 9.697779831999981],
              [-2.787372015999893, 9.697449385000084],
              [-2.787350481999908, 9.697160208000071],
              [-2.787317911999878, 9.696827006000035],
              [-2.78726179899985, 9.696562805000099],
              [-2.787149313999976, 9.696034405000091],
              [-2.787058619999925, 9.695737323],
              [-2.786945031999949, 9.695426583000085],
              [-2.786865679999949, 9.695173523999983],
              [-2.786775056999886, 9.694840625000097],
              [-2.786730558999864, 9.694565342000146],
              [-2.786651865999943, 9.694174523000015],
              [-2.786607238999977, 9.693932303000054],
              [-2.786528432999944, 9.693577300000072],
              [-2.786414628999921, 9.693266563000066],
              [-2.786265670999853, 9.693035906000034],
              [-2.786196839999946, 9.692938457000082],
              [-2.786128008999924, 9.692841008000073],
              [-2.785921288999873, 9.692607898000176],
              [-2.785795544999928, 9.692401917000098],
              [-2.785703760999979, 9.692275659000131],
              [-2.785600724999881, 9.692113642000152],
              [-2.785485958999914, 9.691940665000118],
              [-2.78533706099995, 9.691721029000064],
              [-2.785256473999937, 9.691652569000098],
              [-2.785083443999895, 9.691595612000072],
              [-2.784921868999959, 9.69154686000013],
              [-2.784783361999928, 9.69151176400004],
              [-2.784644839999942, 9.691465648000133],
              [-2.784506718999978, 9.69133962900014],
              [-2.784403827999881, 9.691155570000092],
              [-2.784379027999876, 9.691006395000102],
              [-2.78433701199998, 9.690753666000035],
              [-2.784349918999965, 9.690511146000063],
              [-2.784328461999962, 9.690235744000177],
              [-2.78428474399982, 9.689844741000059],
              [-2.784250652999845, 9.689752619999979],
              [-2.784216562999973, 9.689660501000105],
              [-2.784170899999879, 9.689534001000141],
              [-2.784079550999877, 9.689374677999979],
              [-2.783976069999937, 9.68926363300011],
              [-2.783802379999941, 9.689315506000071],
              [-2.783604879999871, 9.689435004000188],
              [-2.783442852999883, 9.68948543900018],
              [-2.783350381999981, 9.689483165000183],
              [-2.783200246999968, 9.689448128000038],
              [-2.782980764999934, 9.689377635000142],
              [-2.782773402999908, 9.689285037000104],
              [-2.782439288999967, 9.689066362000062],
              [-2.782323806999841, 9.689020124000138],
              [-2.782070494999914, 9.688845111000148],
              [-2.781863145999921, 9.688705675000165],
              [-2.781690621999928, 9.688579834000109],
              [-2.781621657999835, 9.688509935000127],
              [-2.781552691999877, 9.688440037000021],
              [-2.781368713999939, 9.688256398000021],
              [-2.781231216999856, 9.688083537000182],
              [-2.781127995999952, 9.687943560000065],
              [-2.780978194999875, 9.68786443900018],
              [-2.78076999599989, 9.687826947000076],
              [-2.780504237999935, 9.687825571000133],
              [-2.780284599999959, 9.687823955],
              [-2.78009975699996, 9.687789097000177],
              [-2.779903488999935, 9.687743278000028],
              [-2.779591940999922, 9.687590605000082],
              [-2.779407805999881, 9.687475844000062],
              [-2.779154265999978, 9.687314605000154],
              [-2.77871672099991, 9.687013806000039],
              [-2.77844007199991, 9.686896769000043],
              [-2.778261302999965, 9.686804020000125],
              [-2.778082533999907, 9.686711271000149],
              [-2.7777828319999, 9.686583331000065],
              [-2.777344638999978, 9.686373453999977],
              [-2.777114110999889, 9.686256176000086],
              [-2.776791153999909, 9.686095294000097],
              [-2.77637662199993, 9.685838455000067],
              [-2.775984493999886, 9.685675176000132],
              [-2.775592306999954, 9.685500874000127],
              [-2.775188623999952, 9.685351429000093],
              [-2.774807646999932, 9.685245948000159],
              [-2.774507367999945, 9.685173111000097],
              [-2.774195288999977, 9.68512513200011],
              [-2.773975941999936, 9.685079428000051],
              [-2.773652899999945, 9.684951605000038],
              [-2.773410961999844, 9.684834383000123],
              [-2.773168778999946, 9.684719918000155],
              [-2.772811217999958, 9.684578495999972],
              [-2.772361082999908, 9.684415511000111],
              [-2.771864887999925, 9.684263784000052],
              [-2.771299129999818, 9.684134455000105],
              [-2.770744318999903, 9.684073949000094],
              [-2.77020141099996, 9.683966543000054],
              [-2.769589504999942, 9.683823674000053],
              [-2.769058123999912, 9.683729982000102],
              [-2.76880406, 9.68368307500009],
              [-2.768549997999969, 9.683636169000067],
              [-2.768203528999834, 9.683541523000088],
              [-2.768053536999958, 9.683483059000025],
              [-2.767903542999875, 9.683424596000066],
              [-2.767661331999932, 9.683354209000015],
              [-2.767407566999964, 9.683248064000054],
              [-2.767234354999914, 9.683213137999985],
              [-2.766852815999812, 9.683165509000048],
              [-2.766552466999826, 9.683128482000086],
              [-2.766332975999944, 9.683104814999979],
              [-2.766055852999955, 9.68304562700007],
              [-2.765721216999964, 9.68293989700004],
              [-2.765555245999906, 9.682895979000136],
              [-2.765455664999934, 9.682869628000162],
              [-2.765132046999895, 9.682846495000149],
              [-2.764969902999894, 9.682879014000037],
              [-2.764807759999883, 9.682911532000105],
              [-2.764564286999814, 9.683014725000021],
              [-2.764159053999947, 9.683115993000058],
              [-2.763869593999971, 9.683136767000178],
              [-2.763464868999961, 9.683169153000165],
              [-2.76315260299998, 9.683143204999965],
              [-2.762886831999936, 9.683130794000135],
              [-2.762609477999888, 9.683093646000032],
              [-2.762332703999903, 9.683001391000175],
              [-2.76207902799996, 9.682862178999983],
              [-2.761801848999937, 9.682791967000071],
              [-2.761559464999948, 9.682754638000063],
              [-2.761166660999891, 9.682660222000095],
              [-2.760965026999884, 9.682549672000164],
              [-2.760763392999934, 9.682439121000186],
              [-2.760464295999952, 9.682209220000061],
              [-2.760130330999857, 9.681965721999973],
              [-2.75980822799994, 9.681700119000141],
              [-2.759543551999968, 9.681481062000046],
              [-2.759209442999975, 9.68125960399999],
              [-2.758875696999951, 9.681040899000095],
              [-2.75850768, 9.680706652000083],
              [-2.758266930999866, 9.680382774000123],
              [-2.758037417999958, 9.680083634000027],
              [-2.75783123399998, 9.679748557000153],
              [-2.757659569999873, 9.67946014100005],
              [-2.757464969999944, 9.679149801000165],
              [-2.757247363999966, 9.67880376200003],
              [-2.757133531999955, 9.678539846000035],
              [-2.756996445999903, 9.678276050000079],
              [-2.756905260999929, 9.67804508100005],
              [-2.756848274999811, 9.677882816000022],
              [-2.756780300999935, 9.677676525000095],
              [-2.756770845999881, 9.677343196000152],
              [-2.756795574999956, 9.677092347999974],
              [-2.756859882999947, 9.676648620000037],
              [-2.756874760999949, 9.676535934000128],
              [-2.756892304999894, 9.676403057000073],
              [-2.756952397999896, 9.67606110700001],
              [-2.756989617999977, 9.675625597000078],
              [-2.757019839999884, 9.675429825000038],
              [-2.757050060999973, 9.675234052000064],
              [-2.757168413999921, 9.674765065000088],
              [-2.757240295999907, 9.674387237000076],
              [-2.75727653499996, 9.674147351000158],
              [-2.757335987999909, 9.673882549000098],
              [-2.757373177999966, 9.67345806000003],
              [-2.757363270999974, 9.673204634],
              [-2.75735326399996, 9.672931922000032],
              [-2.757309406999866, 9.672573972000066],
              [-2.757253398999922, 9.672265678000144],
              [-2.757255368999836, 9.671943311],
              [-2.757256579999933, 9.671758708000141],
              [-2.757251559999872, 9.671644393000179],
              [-2.757246541999905, 9.671530078000046],
              [-2.757201723999913, 9.671287851000159],
              [-2.757156541999905, 9.671092464000083],
              [-2.757065577999924, 9.670853228000169],
              [-2.75702617099995, 9.670692251000048],
              [-2.756986764999965, 9.670531275000087],
              [-2.756884290999949, 9.670264545],
              [-2.756781976999832, 9.669978529000161],
              [-2.756680026999902, 9.669645672000058],
              [-2.756555434999939, 9.669230276000178],
              [-2.756476422999867, 9.668886281000027],
              [-2.75643135699994, 9.668712935000087],
              [-2.756341738999822, 9.668231234000132],
              [-2.756339485999888, 9.668017962000022],
              [-2.756334577999951, 9.66755349500005],
              [-2.756290652999951, 9.667165238],
              [-2.75625885099987, 9.666705284000159],
              [-2.756238166999935, 9.66631415300003],
              [-2.756205722999937, 9.6659478790001],
              [-2.756184703999963, 9.665592567000033],
              [-2.756164309999974, 9.665190414000051],
              [-2.756097290999946, 9.664799520000031],
              [-2.756041708999931, 9.664455405000069],
              [-2.755940770999928, 9.663948966000021],
              [-2.755861483999979, 9.66365181000009],
              [-2.755805800999951, 9.663304941000092],
              [-2.755750511999906, 9.662916741000117],
              [-2.7557061199999, 9.662605632000066],
              [-2.755649998999957, 9.662341420000018],
              [-2.755582412999956, 9.662041449000185],
              [-2.755571931999896, 9.661869303000117],
              [-2.755561450999835, 9.661697157000106],
              [-2.755517189999978, 9.661410843000056],
              [-2.755487299999913, 9.661105709000026],
              [-2.75547348899994, 9.660964725999975],
              [-2.755417382999895, 9.660686738000095],
              [-2.75530429399987, 9.660296078000101],
              [-2.755224919999876, 9.660031984999989],
              [-2.755135683999868, 9.659539261000077],
              [-2.755079447999833, 9.659286071000111],
              [-2.755012228999931, 9.658906197000135],
              [-2.754944538999951, 9.658653065000124],
              [-2.754843138999888, 9.658207241000184],
              [-2.75475207799991, 9.657998311000142],
              [-2.754626706999943, 9.657698634000099],
              [-2.754536188999907, 9.657376738000153],
              [-2.754514864999919, 9.657079286000055],
              [-2.754495075999955, 9.656575187000158],
              [-2.754509498999937, 9.656092956000123],
              [-2.754546049999931, 9.655795208000029],
              [-2.754559796999899, 9.655450740000106],
              [-2.75455035199991, 9.65508434700007],
              [-2.754507411999839, 9.654566590000115],
              [-2.754475484999944, 9.654131432000042],
              [-2.754430440999954, 9.653911246000121],
              [-2.754386490999934, 9.653567071000111],
              [-2.754329947999963, 9.653371741000115],
              [-2.754266981999933, 9.65315814500002],
              [-2.754103722999901, 9.652579398000057],
              [-2.754023914999891, 9.652348368000105],
              [-2.75393272499997, 9.652164235000043],
              [-2.753783852999902, 9.651933557000177],
              [-2.753669410999976, 9.651716480000061],
              [-2.753475535999883, 9.651323474000037],
              [-2.753326754999932, 9.651059733000125],
              [-2.753189730999964, 9.65077113500007],
              [-2.752938044999951, 9.650356382000155],
              [-2.75283511799995, 9.650184706000118],
              [-2.752732192999929, 9.650013030000139],
              [-2.752537637999922, 9.649655845000154],
              [-2.752193592999959, 9.649216764000187],
              [-2.751975736999953, 9.648917556000072],
              [-2.751699670999869, 9.648687524000081],
              [-2.751515260999895, 9.648581008000122],
              [-2.75123888499985, 9.648441897999987],
              [-2.750962160999961, 9.648302790000059],
              [-2.75066238799991, 9.648152777000121],
              [-2.75032852399994, 9.64792028200003],
              [-2.750041314999976, 9.647632445000056],
              [-2.749972476999972, 9.647568966000051],
              [-2.749882095999965, 9.647485620999987],
              [-2.749791714999901, 9.647402277000026],
              [-2.749722696999811, 9.647329741000078],
              [-2.749577838999926, 9.647177986000031],
              [-2.749336335999885, 9.646944576000124],
              [-2.749271562999979, 9.64685801100012],
              [-2.74919855099995, 9.646759601000156],
              [-2.74903099099987, 9.646502434000126],
              [-2.748893313999929, 9.646304758000099],
              [-2.74884921599994, 9.646234941000103],
              [-2.74853597699996, 9.645739014000185],
              [-2.748263867999981, 9.645269241000108],
              [-2.748202662999972, 9.645196918000067],
              [-2.748068843999931, 9.645038794000129],
              [-2.747804998999925, 9.644703998000182],
              [-2.747518105999916, 9.644358296000178],
              [-2.747357584999975, 9.644138695000038],
              [-2.747159599999918, 9.643803371000161],
              [-2.746979953999869, 9.643493136000188],
              [-2.746831441999973, 9.643229389000112],
              [-2.74669178399995, 9.642948171000114],
              [-2.74664855299983, 9.642861119000031],
              [-2.74650048399991, 9.642514713000139],
              [-2.746466650999878, 9.642389523000077],
              [-2.746432818999836, 9.642264332000082],
              [-2.746407601999977, 9.642103544000122],
              [-2.746365896999976, 9.641837615000043],
              [-2.746354861999976, 9.641642273000059],
              [-2.745235499999978, 9.641531347000125],
              [-2.745941821999963, 9.638215592000108],
              [-2.746060071999978, 9.637482444000057],
              [-2.746473945999924, 9.635779647000106],
              [-2.747077020999939, 9.634514376000141],
              [-2.747360819999869, 9.633769402000155],
              [-2.747316898999884, 9.633077430000071],
              [-2.747116507999976, 9.63270747700011],
              [-2.747070262999898, 9.632198791000121],
              [-2.747255239999845, 9.631736351000029],
              [-2.747578948999887, 9.631366398000068],
              [-2.748210950999976, 9.631104348000065],
              [-2.749120417999961, 9.63099644600004],
              [-2.749921981999933, 9.631027275000179],
              [-2.750939352999922, 9.631042690000129],
              [-2.752141699999925, 9.62996366100009],
              [-2.752943263999896, 9.628252630000191],
              [-2.75331321699997, 9.627605212999981],
              [-2.753775657999938, 9.626140816000088],
              [-2.754484733999902, 9.624553102000164],
              [-2.755363372999966, 9.623504903000026],
              [-2.756504059999941, 9.622456704000058],
              [-2.758877923999876, 9.620499038000105],
              [-2.760265246999893, 9.619219617],
              [-2.761212561999969, 9.618444544000113],
              [-2.762130425999942, 9.617693563000046],
              [-2.76317862499991, 9.616090434],
              [-2.763301942999817, 9.614841844000068],
              [-2.762896956999953, 9.613195273000031],
              [-2.762658729999885, 9.612557665],
              [-2.762462543999902, 9.610904088000041],
              [-2.763408444999925, 9.609229491000178],
              [-2.763926939999919, 9.608563857000149],
              [-2.764081087999955, 9.60737272099999],
              [-2.764060066999946, 9.607092454000167],
              [-2.76420020199987, 9.606517906000079],
              [-2.764865835999899, 9.606048458000146],
              [-2.765349296999943, 9.60577519800006],
              [-2.766302205999978, 9.605382824000117],
              [-2.767290147999916, 9.604682156000024],
              [-2.768250062999869, 9.603932441000097],
              [-2.769476232999978, 9.603028579000124],
              [-2.769875612999897, 9.602559132000067],
              [-2.771129808999945, 9.601318949000017],
              [-2.771424089999925, 9.60042209400018],
              [-2.771900544999937, 9.598565324000049],
              [-2.771788437999874, 9.597752549000177],
              [-2.771655310999961, 9.595839724000086],
              [-2.771234910999908, 9.594039007000049],
              [-2.770884555999885, 9.592777678000061],
              [-2.77068972, 9.591794281000148],
              [-2.77063973099996, 9.590494546000173],
              [-2.770739710999976, 9.589219805000084],
              [-2.77076470499992, 9.588344983000013],
              [-2.770739710999976, 9.586320394000097],
              [-2.770714716999976, 9.584995663000029],
              [-2.77103965099991, 9.582696130000102],
              [-2.770639732999882, 9.581746323000118],
              [-2.770064848999937, 9.581021470000053],
              [-2.769689925999899, 9.580121653000163],
              [-2.769639935999919, 9.579371805999983],
              [-2.769939874999977, 9.578896902000167],
              [-2.770764707999831, 9.577647156000126],
              [-2.771264605999875, 9.576847319000137],
              [-2.771064646999946, 9.575122669000052],
              [-2.769939876999956, 9.572223258000065],
              [-2.76966897199992, 9.571666648000189],
              [-2.769068575999825, 9.570347744000117],
              [-2.768438652999919, 9.569087897000088],
              [-2.767700460999947, 9.567837892000114],
              [-2.766932740999948, 9.566646941999977],
              [-2.765850059999877, 9.565357566000046],
              [-2.764590212999906, 9.563428424000165],
              [-2.763842178999937, 9.561637078000103],
              [-2.763113829999952, 9.559796519000088],
              [-2.762247685999967, 9.557572101000119],
              [-2.760663033999947, 9.555268941000122],
              [-2.759127594999939, 9.55383192700009],
              [-2.757198453999934, 9.5522177470001],
              [-2.75612596499991, 9.551208128000155],
              [-2.755405192999945, 9.549901727000133],
              [-2.753918598999974, 9.549180954000121],
              [-2.74950386699993, 9.548144843000102],
              [-2.746890935999886, 9.546387821],
              [-2.744885165999961, 9.544345397000143],
              [-2.742744215999892, 9.542037617000119],
              [-2.739213037999946, 9.538395219000051],
              [-2.736877454999956, 9.535753786000043],
              [-2.734291631999952, 9.532750893000014],
              [-2.733704045999957, 9.53211548500002],
              [-2.732497491999936, 9.530810718000168],
              [-2.731900440999937, 9.530165068000031],
              [-2.731010695999942, 9.529525563000163],
              [-2.729703880999978, 9.528969472000028],
              [-2.727896585999929, 9.528969471000153],
              [-2.72740278099991, 9.528845156000045],
              [-2.723920534999934, 9.527968506000036],
              [-2.721529342999872, 9.527495829000088],
              [-2.717469877999974, 9.526049990000104],
              [-2.713799676999884, 9.523881235000147],
              [-2.712423351999973, 9.522407593000082],
              [-2.711964561999935, 9.522004417000119],
              [-2.708481956999833, 9.516487552000058],
              [-2.707992769999919, 9.515712620999977],
              [-2.707124813999883, 9.514989324999988],
              [-2.70577310699997, 9.513862902000142],
              [-2.700516012999913, 9.51078652700005],
              [-2.696368750999966, 9.506931322000014],
              [-2.693740203999937, 9.503796535000106],
              [-2.69179313099994, 9.50050598200005],
              [-2.690138120999961, 9.497254371000054],
              [-2.688522051999883, 9.494041701000185],
              [-2.687353807999955, 9.491899921000027],
              [-2.68707471599987, 9.491439935000187],
              [-2.689924002999931, 9.488793373000078],
              [-2.691003322999961, 9.48811721900006],
              [-2.697669027999893, 9.483942986000102],
              [-2.699948549999874, 9.48175907100017],
              [-2.70721602499998, 9.474797250000108],
              [-2.724808931999974, 9.45102119600017],
              [-2.731300115999886, 9.43963623000019],
              [-2.734544753999955, 9.437453270000049],
              [-2.735869883999897, 9.436561586000153],
              [-2.739043473999857, 9.431674004000058],
              [-2.75339603499998, 9.409569740999984],
              [-2.763180017999957, 9.401107789000093],
              [-2.76587915399989, 9.401448250000101],
              [-2.771070957999939, 9.402104378000047],
              [-2.787198065999974, 9.409834861000093],
              [-2.798727988999815, 9.419262884999966],
              [-2.804984569999931, 9.425537109000061],
              [-2.812664986999948, 9.433238983000138],
              [-2.839153050999812, 9.461215019000122],
              [-2.847218990999977, 9.47093772900007],
              [-2.868674993999889, 9.490051270000151],
              [-2.878810881999925, 9.50280380200013],
              [-2.901225088999979, 9.531004906000078],
              [-2.907433032999961, 9.54100799500003],
              [-2.910804987999882, 9.546441079000147],
              [-2.922433614999875, 9.568052292000118],
              [-2.92548704099994, 9.57372665299999],
              [-2.942409038999927, 9.608551980000129],
              [-2.94334006299988, 9.608530044000077],
              [-2.951240062999943, 9.621020317000159],
              [-2.965887546999966, 9.646278381000116],
              [-2.966125964999947, 9.646689415000026],
              [-2.96789217099996, 9.650603295000053],
              [-2.969214677999901, 9.653533936000144],
              [-2.971868276999885, 9.659414291000076],
              [-2.977535009999883, 9.671972275000087],
              [-2.983682870999928, 9.691313743000137],
              [-2.984721898999908, 9.694582939000043],
              [-2.995203017999813, 9.719653129000108],
              [-3.006691933999946, 9.737351418000174],
              [-3.012835026999937, 9.74297142099999],
              [-3.017246960999955, 9.74334335400016],
              [-3.018693923999933, 9.742180824000059],
              [-3.022722959999953, 9.738945960000081],
              [-3.022878884999898, 9.738818169000126],
              [-3.042778014999954, 9.722829820000186],
              [-3.044449327999928, 9.721603393],
              [-3.048254966999878, 9.718810082000118],
              [-3.06009006599993, 9.719489098000167],
              [-3.066194056999848, 9.723270416000048],
              [-3.072566507999966, 9.733222962000184],
              [-3.077276943999891, 9.74057960499999],
              [-3.079544067999962, 9.744121553000127],
              [-3.079849958999887, 9.744599343000061],
              [-3.086812257999952, 9.756364822000023],
              [-3.087779045999923, 9.757998466000174],
              [-3.091551064999919, 9.761137963000124],
              [-3.094314097999927, 9.771194459000185],
              [-3.094835758999921, 9.787148476000141],
              [-3.094959974999881, 9.790949822000073],
              [-3.100509881999926, 9.801179886000057],
              [-3.108095884999898, 9.809299469000166],
              [-3.115108966999912, 9.822943689],
              [-3.120558023999934, 9.839612960000181],
              [-3.123770712999942, 9.842814445000101],
              [-3.125530957999899, 9.844568251999988],
              [-3.128021478999926, 9.845350266000082],
              [-3.128952264999839, 9.845643043000052],
              [-3.137202976999959, 9.848233224000012],
              [-3.144675731999882, 9.847478866000131],
              [-3.145762920999971, 9.847369194000123],
              [-3.146002053999951, 9.84730720500005],
              [-3.164541005999979, 9.842523574000097],
              [-3.165132998999923, 9.842370987000038],
              [-3.184154986999943, 9.842894554000111],
              [-3.186980008999854, 9.844900130000099],
              [-3.192945956999893, 9.852826118999985],
              [-3.193732022999939, 9.85488891600005],
              [-3.19442296099993, 9.856701851000025],
              [-3.194433926999921, 9.868195534000051],
              [-3.19388794799994, 9.877588272000082],
              [-3.193417070999885, 9.885685921000118],
              [-3.193780660999948, 9.889779091000037],
              [-3.194334028999833, 9.896011353],
              [-3.192719220999891, 9.911947250000083],
              [-3.192420006999896, 9.914899826000124],
              [-3.195469616999901, 9.927478790000123],
              [-3.198340653999878, 9.927420617],
              [-3.198429107999914, 9.927418709000051],
              [-3.198716163999961, 9.927412987000082],
              [-3.201263903999973, 9.927359582],
              [-3.204051971999832, 9.924702645000025],
              [-3.204679965999901, 9.92467022000011],
              [-3.205310582999971, 9.924637794000091],
              [-3.209424018999869, 9.92442703200004],
              [-3.209663151999962, 9.924255371000129],
              [-3.214313029999914, 9.920919418000096],
              [-3.215433359999963, 9.913539887000127],
              [-3.216167926999901, 9.908700943000156],
              [-3.225028991999977, 9.899109840000165],
              [-3.225162028999876, 9.899089814000092],
              [-3.226773023999954, 9.898853303000124],
              [-3.235666035999884, 9.897533417000034],
              [-3.23824095699996, 9.89605140700013],
              [-3.239795923999907, 9.895155908000049],
              [-3.24011421199998, 9.89474964100009],
              [-3.240766047999898, 9.89391803699999],
              [-3.243953943999884, 9.889854432000163],
              [-3.248394964999875, 9.884193421000077],
              [-3.252066611999851, 9.877380372000118],
              [-3.252650021999955, 9.87629795100014],
              [-3.262531994999961, 9.871747018000121],
              [-3.264336586999946, 9.869681358999969],
              [-3.270051001999832, 9.863141060000032],
              [-3.278863906999902, 9.853049279000175],
              [-3.279491186999849, 9.852660179000111],
              [-3.281157016999941, 9.85162735],
              [-3.288902044999929, 9.856537818000049],
              [-3.296025991999954, 9.853185654000185],
              [-3.299926041999811, 9.853349686000058],
              [-3.311110018999955, 9.853818893000096],
              [-3.311882019999871, 9.853326798000126],
              [-3.313878773999875, 9.854880333000153],
              [-3.314739942999836, 9.855549812000049],
              [-3.318526028999941, 9.859148025000138],
              [-3.319818018999968, 9.865324020000173],
              [-3.311583995999911, 9.88113021800001],
              [-3.310709714999973, 9.885397911000098],
              [-3.310117959999957, 9.888286591000167],
              [-3.310279846999947, 9.890061379000144],
              [-3.310724972999878, 9.894942283000034],
              [-3.315274000999978, 9.901510238000185],
              [-3.31669449799989, 9.901735306000148],
              [-3.320394993999912, 9.902321816000153],
              [-3.334604978999892, 9.894663811999976],
              [-3.340367078999975, 9.891958238000143],
              [-3.343327998999826, 9.890567780000026],
              [-3.345600604999902, 9.890769005000038],
              [-3.349601984999879, 9.891123771000082],
              [-3.351085661999889, 9.892672539000159],
              [-3.356722115999958, 9.898555757000054],
              [-3.366478680999876, 9.9039030080001],
              [-3.366817473999902, 9.90408897399999],
              [-3.397238016999836, 9.916537285000118],
              [-3.40465211899982, 9.916147232000128],
              [-3.413160322999886, 9.913660050000033],
              [-3.420782089999932, 9.911431313000094],
              [-3.433599948999927, 9.914140702000168],
              [-3.460470914999917, 9.923212052000054],
              [-3.463219403999972, 9.923761368000044],
              [-3.469789981999952, 9.925075532000051],
              [-3.479036092999934, 9.923727036000059],
              [-3.486780404999934, 9.923282623000148],
              [-3.492008924999936, 9.922982216000037],
              [-3.502065181999967, 9.924512863000132],
              [-3.503879068999936, 9.9247894290001],
              [-3.506968498999925, 9.924721718000114],
              [-3.523350000999869, 9.924363136000068],
              [-3.535092831999918, 9.925244331999977],
              [-3.549371003999909, 9.926316261000125],
              [-3.561217068999952, 9.925771713000188],
              [-3.568602084999952, 9.925432206000096],
              [-3.575462102999893, 9.923425675000033],
              [-3.579659937999963, 9.922198295000101],
              [-3.592833994999921, 9.920066833000135],
              [-3.596106290999956, 9.91987800600009],
              [-3.599318026999981, 9.919692993000126],
              [-3.60354590399993, 9.923803330000112],
              [-3.608331918999966, 9.928456305999987],
              [-3.619765996999945, 9.941534043000161],
              [-3.630412100999934, 9.951046944000098],
              [-3.632314919999942, 9.952747346000081],
              [-3.637530564999963, 9.955186843000035],
              [-3.637879847999955, 9.955349923000028],
              [-3.64169597699987, 9.957135202000075],
              [-3.64834642399984, 9.955660820000105],
              [-3.648617983999941, 9.955600738000101],
              [-3.649054764999903, 9.955291748000093],
              [-3.654325008999876, 9.951564789000031],
              [-3.673774957999967, 9.940089226000112],
              [-3.680947065999931, 9.939238548000049],
              [-3.688341855999965, 9.94036960599999],
              [-3.69142198499992, 9.940840721000029],
              [-3.708622932999901, 9.942521095000132],
              [-3.711579083999936, 9.939925193000079],
              [-3.716712712999936, 9.939099312000053],
              [-3.719897985999978, 9.938587188000156],
              [-3.726870060999886, 9.939118385000143],
              [-3.751976966999962, 9.931647300000066],
              [-3.758475303999944, 9.928868295000086],
              [-3.772413969999946, 9.922906876000013],
              [-3.777642011999944, 9.918418885000051],
              [-3.78861999499992, 9.91195964799999],
              [-3.806975601999909, 9.890799523000112],
              [-3.809170961999939, 9.888268470000185],
              [-3.814033030999951, 9.887927056000024],
              [-3.835463999999888, 9.89202880900018],
              [-3.842248916999949, 9.892786027000113],
              [-3.855926035999971, 9.894312858000092],
              [-3.863621233999936, 9.895552636000161],
              [-3.873388051999825, 9.897125245000154],
              [-3.879616975999966, 9.898241042000052],
              [-3.882471084999963, 9.898752213000137],
              [-3.88946557, 9.899463654000101],
              [-3.891453027999887, 9.899665832000153],
              [-3.900415182999893, 9.90057659199999],
              [-3.904810905999966, 9.892974853000112],
              [-3.917101382999931, 9.880465507],
              [-3.918652057999964, 9.87888717800007],
              [-3.926543949999882, 9.8792781840001],
              [-3.928400038999882, 9.879370690000087],
              [-3.93703794399994, 9.871373176000077],
              [-3.944943904999946, 9.872355461999973],
              [-3.952547072999948, 9.870123864000107],
              [-3.955409049999958, 9.870182037],
              [-3.958115101999965, 9.870236397000099],
              [-3.961606740999912, 9.873135566000087],
              [-3.961894035999876, 9.873373985000171],
              [-3.964248178999981, 9.873323440000149],
              [-3.964442015999964, 9.873319627000115],
              [-3.968997954999963, 9.869544983000083],
              [-3.972939013999906, 9.869461059000059],
              [-3.9744999399999, 9.866668701000094],
              [-3.974184989999969, 9.865096092000101],
              [-3.973720072999868, 9.862779618000104],
              [-3.974317073999885, 9.861365319000072],
              [-3.978127956999913, 9.852337836000174],
              [-3.976886033999904, 9.848459244000082],
              [-3.972836016999906, 9.843487739000068],
              [-3.974077939999916, 9.841006280000158],
              [-3.975279092999926, 9.838607788000161],
              [-3.978963850999946, 9.8349456790001],
              [-3.980952023999976, 9.832969666000111],
              [-3.987600087999908, 9.829612731999987],
              [-3.993159055999968, 9.82926368700015],
              [-3.998863935999907, 9.831011772000124],
              [-4.000161171999935, 9.831409455000085],
              [-4.008618830999865, 9.836516380000091],
              [-4.013161658999934, 9.83506965700002],
              [-4.017138004999879, 9.833804130000033],
              [-4.030593872999873, 9.834202765999976],
              [-4.032778262999955, 9.832655907000174],
              [-4.03297805699998, 9.832514763000063],
              [-4.035614967999948, 9.830647469999974],
              [-4.044519900999887, 9.818389893000187],
              [-4.044427870999925, 9.817435266000132],
              [-4.042901514999926, 9.80163955800009],
              [-4.048686980999889, 9.801029205000134],
              [-4.054233073999967, 9.800443650000091],
              [-4.063473223999949, 9.799074173000179],
              [-4.065288065999937, 9.800644874],
              [-4.066315173999897, 9.801533698000014],
              [-4.078907011999831, 9.804450990000021],
              [-4.083204745999979, 9.807560921000174],
              [-4.092123985999876, 9.814016343000162],
              [-4.106524467999975, 9.81986618000002],
              [-4.109220027999925, 9.820960999000135],
              [-4.123395918999961, 9.831651687000033],
              [-4.128347873999815, 9.831871032000151],
              [-4.130127906999917, 9.831949234000035],
              [-4.131472587999895, 9.831331254000077],
              [-4.135402201999966, 9.829524040000138],
              [-4.138803480999911, 9.825240136000048],
              [-4.140799044999938, 9.822727204000159],
              [-4.145171164999908, 9.812047006],
              [-4.154133319999971, 9.805992127000081],
              [-4.161460399999953, 9.801041603000101],
              [-4.164328574999956, 9.799103737000166],
              [-4.167057037999939, 9.79726028500005],
              [-4.169016836999901, 9.792582511000035],
              [-4.17065000599996, 9.788678170000026],
              [-4.173180101999947, 9.782629967000105],
              [-4.17767381699997, 9.7780971520001],
            ],
          ],
        },
      },
    ],
  },
  80: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-8.707270620999907, 7.634743214000082],
                [-8.706783295999912, 7.640019894000091],
                [-8.701015472999927, 7.646245956000087],
                [-8.687577247999968, 7.655398847000015],
                [-8.676616669999873, 7.668807029999982],
                [-8.670296668999924, 7.68792915400013],
                [-8.666124342999922, 7.693087101000174],
                [-8.659360885999888, 7.696164132000092],
                [-8.614681244999929, 7.694580078000115],
                [-8.584142683999971, 7.693497182000044],
                [-8.559730529999968, 7.69262886100006],
                [-8.55617618599996, 7.676571846000115],
                [-8.554746626999872, 7.649655820000135],
                [-8.565239905999931, 7.625895977000027],
                [-8.544582367999908, 7.605105878000131],
                [-8.487145423999948, 7.571209907000025],
                [-8.47218036699985, 7.554582120000077],
                [-8.440885543999968, 7.586531162000028],
                [-8.417427061999945, 7.608161926000037],
                [-8.405476569999962, 7.611214161000078],
                [-8.393016814999953, 7.612191200000041],
                [-8.38473892299993, 7.606978893000132],
                [-8.381673813999953, 7.597648143000129],
                [-8.379691123999976, 7.579007150000052],
                [-8.369343758999946, 7.572749139],
                [-8.356881141999963, 7.574762820999979],
                [-8.346479414999976, 7.578856944999984],
                [-8.322503088999895, 7.599445820000085],
                [-8.30277728999988, 7.601425170000084],
                [-8.298646925999833, 7.596747876000165],
                [-8.299714087999917, 7.591577054000084],
                [-8.297672272999932, 7.584838868000134],
                [-8.289409637999881, 7.577037812000015],
                [-8.257283209999969, 7.56757020900011],
                [-8.235571860999926, 7.551939964000155],
                [-8.229873657999974, 7.549842835000106],
                [-8.214912414999844, 7.533728123000117],
                [-8.205040931999918, 7.535750865000125],
                [-8.193576812999936, 7.545011997000074],
                [-8.179969786999948, 7.566683770000054],
                [-8.172627447999957, 7.581657886000187],
                [-8.171907424999972, 7.619952203000082],
                [-8.157246589999943, 7.644204140000056],
                [-8.14891719699989, 7.649341106000179],
                [-8.143695829999956, 7.655525208000086],
                [-8.139862059999928, 7.693283082000107],
                [-8.123680114999956, 7.71079778800015],
                [-8.101334571999928, 7.715865135000115],
                [-8.094035147999932, 7.722039222000149],
                [-8.091284751999979, 7.751001834000078],
                [-8.086001395999915, 7.768569947000117],
                [-8.071351051999955, 7.79022789000004],
                [-8.070261955999968, 7.79953908900012],
                [-8.071770666999896, 7.808342934],
                [-8.08312988199998, 7.819781780000028],
                [-8.114659308999933, 7.844769955000118],
                [-8.117179869999916, 7.858753204000095],
                [-8.11556625399993, 7.86909294100019],
                [-8.107178688999852, 7.884572983000112],
                [-8.094126701999926, 7.898478032000128],
                [-8.082610129999978, 7.916011811000033],
                [-8.075221060999922, 7.938736916000153],
                [-8.065770148999945, 7.958349227999975],
                [-8.06220340699997, 7.970335007000074],
                [-8.05337143, 8],
                [-8.063541411999893, 8.011078834000159],
                [-8.057643889999895, 8.022718429000122],
                [-8.047423363999883, 8.02993869800008],
                [-8.040738105999935, 8.03279685900003],
                [-8.029653548999818, 8.029681206000078],
                [-8.024313925999877, 8.033059121000065],
                [-8.00981903099995, 8.031952859],
                [-8.003287315999899, 8.038529397000048],
                [-8.000283241999853, 8.03917789500008],
                [-7.989054203999956, 8.034902573000181],
                [-7.98725795699994, 8.031206132000136],
                [-7.991803169999969, 8.018180847000053],
                [-7.990667818999896, 8.016785621000054],
                [-7.98604488299992, 8.018104553000114],
                [-7.984292982999932, 8.010965348000184],
                [-7.982227800999965, 8.010481835],
                [-7.972702979999951, 8.017013549000126],
                [-7.959630011999877, 8.012941361000117],
                [-7.953718184999957, 8.00712680800018],
                [-7.948188780999942, 8.007284166000034],
                [-7.945581913999888, 8.012989043000175],
                [-7.946928976999971, 8.015760423000131],
                [-7.946342944999969, 8.02516555900013],
                [-7.950419902999897, 8.030499459],
                [-7.946622847999947, 8.03917408],
                [-7.946579932999953, 8.042387008000105],
                [-7.963332175999881, 8.047196389000078],
                [-7.969590186999938, 8.061969758000032],
                [-7.98111295699988, 8.079339027000174],
                [-7.98707103799984, 8.081708908000053],
                [-7.993225096999936, 8.086839676000011],
                [-8.010642052999913, 8.09395027200003],
                [-8.014452933999962, 8.102265359000114],
                [-8.010151861999873, 8.11482143500001],
                [-8.014341353999953, 8.119530678000046],
                [-8.01543331199997, 8.124595642999964],
                [-8.009333610999931, 8.13370323200013],
                [-8.007619857999885, 8.142176629000176],
                [-8.009973525999953, 8.157361031000107],
                [-8.008975981999924, 8.163776399000142],
                [-7.995921134999946, 8.17624282800017],
                [-7.993298052999933, 8.183559418000186],
                [-7.993649005999828, 8.19274902400008],
                [-7.99841880799994, 8.198545456000033],
                [-8.004182814999979, 8.19861698200009],
                [-8.006985663999899, 8.19543552500005],
                [-8.014179228999865, 8.191390038000065],
                [-8.01792812399998, 8.186383248000027],
                [-8.023674010999969, 8.187602043000084],
                [-8.03477096599994, 8.204495430000122],
                [-8.039601324999978, 8.205472945999986],
                [-8.043329238999888, 8.20207500400005],
                [-8.051420210999936, 8.180358886000022],
                [-8.06451415999993, 8.164445878000038],
                [-8.068925856999897, 8.161513328000012],
                [-8.089082717999872, 8.16710186000006],
                [-8.093734740999935, 8.173064232000058],
                [-8.101742743999921, 8.17752647400016],
                [-8.103737829999886, 8.18398094100013],
                [-8.1109418879999, 8.188751222000064],
                [-8.126380920999964, 8.19617176200012],
                [-8.16671466899993, 8.196411133000083],
                [-8.179585456999973, 8.199774743000035],
                [-8.189421652999954, 8.206546783000135],
                [-8.207397459999868, 8.227416993000134],
                [-8.21908187799994, 8.233978271000183],
                [-8.22508335099991, 8.233358384000098],
                [-8.230306625999901, 8.226033211000015],
                [-8.243995666999922, 8.228629113000068],
                [-8.248055457999953, 8.236024857000018],
                [-8.248765945999935, 8.253716469000096],
                [-8.246381758999917, 8.260346412000047],
                [-8.232859610999924, 8.273270607000143],
                [-8.230238914999973, 8.280359269000087],
                [-8.231749533999846, 8.289104462000068],
                [-8.230691908999972, 8.300341606000075],
                [-8.237603187999923, 8.320175170000141],
                [-8.23797416799988, 8.327757836000103],
                [-8.228771208999888, 8.345328331000076],
                [-8.225660323999875, 8.354936599000098],
                [-8.225819587999979, 8.360679626000035],
                [-8.236800192999851, 8.395055771000102],
                [-8.238370895999935, 8.407796861000065],
                [-8.237839698999949, 8.414218902000187],
                [-8.23974990899984, 8.429167748],
                [-8.245587348999948, 8.442552567000178],
                [-8.243496893999975, 8.444723129000181],
                [-8.241583823999974, 8.450434684000186],
                [-8.24242973399987, 8.456877709000082],
                [-8.240768433999904, 8.46076107000016],
                [-8.235405921999927, 8.465749742000071],
                [-8.23379039699995, 8.465729713000144],
                [-8.23339080799991, 8.460441590000187],
                [-8.230417250999835, 8.45896339400008],
                [-8.222576140999934, 8.45824527799999],
                [-8.220483780999928, 8.459598541000162],
                [-8.223381041999858, 8.468132019000109],
                [-8.221213339999906, 8.470409393000068],
                [-8.215132712999946, 8.472677231000148],
                [-8.213568686999963, 8.47418785200017],
                [-8.20591831299987, 8.481701852000128],
                [-8.196758268999929, 8.49537182],
                [-8.198296545999938, 8.501591684000061],
                [-8.196896552999931, 8.502722740000024],
                [-8.187001228999975, 8.500994683000101],
                [-8.183788298999957, 8.499576569],
                [-8.17823982199991, 8.500430107000113],
                [-8.175254820999953, 8.499244691000115],
                [-8.169572829999936, 8.492283820000125],
                [-8.1621360769999, 8.496789932000127],
                [-8.157759666999937, 8.496274947000018],
                [-8.156406402999949, 8.493730545],
                [-8.157601356999976, 8.49053096800003],
                [-8.156959533999895, 8.486160278000057],
                [-8.153298377999931, 8.48404693700013],
                [-8.150922775999902, 8.489530564000063],
                [-8.146988868999927, 8.490399362000176],
                [-8.143239975999904, 8.495176316000027],
                [-8.139098166999872, 8.49420833500011],
                [-8.134102820999942, 8.48794460400012],
                [-8.129495619999943, 8.487201692000099],
                [-8.125316618999932, 8.489443779000055],
                [-8.120944976999965, 8.488703727000143],
                [-8.119660376999889, 8.480647087000023],
                [-8.10360336399998, 8.49193096100015],
                [-8.098377228999937, 8.504037858000117],
                [-8.095355034999955, 8.505838395000126],
                [-8.081844328999978, 8.497860909000167],
                [-8.076901434999968, 8.499307632000068],
                [-8.069592474999979, 8.503816605],
                [-8.06512546599987, 8.506608009000047],
                [-8.059845923999944, 8.50447463900008],
                [-8.055508612999972, 8.500746727000035],
                [-8.044965743999967, 8.495328903000086],
                [-8.037836074999973, 8.493631363000134],
                [-8.03111839199994, 8.495841980000023],
                [-8.022594452999897, 8.495043755000154],
                [-8.009771346999912, 8.487531662000038],
                [-8.002821921999953, 8.489738464000027],
                [-7.997981071999959, 8.48944664000004],
                [-7.990653990999931, 8.485220909000077],
                [-7.985136031999957, 8.483769416000143],
                [-7.980491161999964, 8.486237527000185],
                [-7.978094099999964, 8.49332618700015],
                [-7.970870971999943, 8.498744965000128],
                [-7.967638969999882, 8.498934747000021],
                [-7.963099002999968, 8.493362428000012],
                [-7.957314968999924, 8.494664192000073],
                [-7.950173853999957, 8.493652344000054],
                [-7.942700863999903, 8.500676156000111],
                [-7.938083172999939, 8.501074792000054],
                [-7.929213046999905, 8.491312980000089],
                [-7.915438173999974, 8.486308098000052],
                [-7.913169859999869, 8.483292579000022],
                [-7.914805888999979, 8.481706619000079],
                [-7.923367022999912, 8.479750633000094],
                [-7.91856908799997, 8.476245881000182],
                [-7.911799907999978, 8.46467018100003],
                [-7.911427020999952, 8.458005906000096],
                [-7.913281917999939, 8.45711040500015],
                [-7.917119979999939, 8.463591576000056],
                [-7.919437885999969, 8.462702751000108],
                [-7.923046112999941, 8.451498986000104],
                [-7.921254158999886, 8.447341919000166],
                [-7.911085127999968, 8.449044229000151],
                [-7.909717082999919, 8.447647096000082],
                [-7.908950804999961, 8.435694696000041],
                [-7.892614841999944, 8.432723046000035],
                [-7.88474512099998, 8.434915542000056],
                [-7.884080886999925, 8.43261241900018],
                [-7.887461185999939, 8.42140293100016],
                [-7.886598109999909, 8.41702461300008],
                [-7.876389026999959, 8.421484948000113],
                [-7.852424143999883, 8.420017244000178],
                [-7.847558020999941, 8.421788217000028],
                [-7.832880972999874, 8.432847023000079],
                [-7.830990791999966, 8.436037064000118],
                [-7.837707996999939, 8.468509674000188],
                [-7.8350029, 8.480876922999983],
                [-7.831696032999901, 8.486804963000054],
                [-7.827267169999914, 8.49018955200006],
                [-7.822759150999957, 8.48231983300019],
                [-7.820994853999821, 8.481793404000086],
                [-7.815833091999934, 8.480425835000176],
                [-7.808255195999891, 8.480058670000062],
                [-7.803682804999937, 8.477008819000048],
                [-7.799159049999958, 8.47028827700018],
                [-7.793652056999918, 8.468376160000162],
                [-7.788410186999897, 8.46371078600015],
                [-7.780377864999934, 8.443617822000135],
                [-7.764214992999939, 8.411245345000111],
                [-7.751740932999894, 8.37892246299998],
                [-7.745131968999942, 8.37331771800018],
                [-7.716979979999962, 8.374767303000112],
                [-7.695354938999913, 8.37102317800003],
                [-7.689564226999949, 8.373007774000087],
                [-7.683351038999945, 8.372002601000133],
                [-7.6768651, 8.374209405000101],
                [-7.662782191999952, 8.375390053000103],
                [-7.651725768999938, 8.374546051000095],
                [-7.641070841999976, 8.377433776000032],
                [-7.649696826999843, 8.387881280000045],
                [-7.651432037999882, 8.396403312000132],
                [-7.654603958999871, 8.400579453000091],
                [-7.666027068999938, 8.408997535000083],
                [-7.673109053999951, 8.428236009000102],
                [-7.680149078999875, 8.440652848000127],
                [-7.681429862999892, 8.448476792000179],
                [-7.679962157999967, 8.454888343000164],
                [-7.675747871999874, 8.459425926000165],
                [-7.671687126999871, 8.470165253000118],
                [-7.666257857999938, 8.479278565000072],
                [-7.660181044999888, 8.485399247000089],
                [-7.659418106999851, 8.490671157000179],
                [-7.661066054999935, 8.505851746000076],
                [-7.666007040999944, 8.516023635000181],
                [-7.678079128999968, 8.52812767000006],
                [-7.684360980999941, 8.541992187000119],
                [-7.687553882999964, 8.544791223000061],
                [-7.697017193999955, 8.580290794000064],
                [-7.699306963999959, 8.581697465000161],
                [-7.701663971999949, 8.5957393650001],
                [-7.704162120999968, 8.598988533000181],
                [-7.711536883999884, 8.617461206000144],
                [-7.712813853999933, 8.625978470000177],
                [-7.723919868999928, 8.659662246000096],
                [-7.726826190999816, 8.667281151000111],
                [-7.736093044999961, 8.682564736000188],
                [-7.738080024999874, 8.68970966400019],
                [-7.750665187999971, 8.716753961000109],
                [-7.752632139999889, 8.725507736000054],
                [-7.756485937999912, 8.731071472000167],
                [-7.762087822999888, 8.744467735000057],
                [-7.763576984999929, 8.754365921000101],
                [-7.767868041999861, 8.762003899000035],
                [-7.77265501099987, 8.767118454000183],
                [-7.781601904999945, 8.771828652000181],
                [-7.790362834999883, 8.773322107000013],
                [-7.812397955999927, 8.766023635000124],
                [-7.823948859999973, 8.765941621000081],
                [-7.845528125999977, 8.776785851000113],
                [-7.861224173999972, 8.77767086100016],
                [-7.874420166999869, 8.775312425000038],
                [-7.901103972999977, 8.76531124100012],
                [-7.915886879999903, 8.7654933930001],
                [-7.925774097999977, 8.769294738000156],
                [-7.940433024999948, 8.779353142000161],
                [-7.947044849999884, 8.78655719700015],
                [-7.961309908999965, 8.810397149000039],
                [-7.962561129999926, 8.821668625000086],
                [-7.957779882999944, 8.85113620800007],
                [-7.9548239699999, 8.869362832000036],
                [-7.94588708799995, 8.901185989000112],
                [-7.930364130999976, 8.942352295000035],
                [-7.91711807299987, 8.967002870000101],
                [-7.920782088999943, 8.988648414000124],
                [-7.918282031999922, 9.004012107000108],
                [-7.906313895999972, 9.018338203000098],
                [-7.896024226999941, 9.028087615000118],
                [-7.865118980999966, 9.04033946900006],
                [-7.853785037999955, 9.059268951000092],
                [-7.840923786999952, 9.070823669999982],
                [-7.813032149999913, 9.082187653000119],
                [-7.803812980999965, 9.079542161000063],
                [-7.801530838999895, 9.077215195000122],
                [-7.790026186999967, 9.072699548000116],
                [-7.777565955999876, 9.070468903000062],
                [-7.764904974999922, 9.066169739000145],
                [-7.754243850999956, 9.067871094000111],
                [-7.73726892499991, 9.075231552000048],
                [-7.736526012999889, 9.078054429000076],
                [-7.741600990999871, 9.08384323200005],
                [-7.755170821999911, 9.088856698000086],
                [-7.761540889999935, 9.096526147000077],
                [-7.770548819999874, 9.097109794000119],
                [-7.777646064999942, 9.102260590000185],
                [-7.788000106999903, 9.106080055000064],
                [-7.801414965999982, 9.122576713000058],
                [-7.808087825999905, 9.124964715000146],
                [-7.811964987999886, 9.129154206000123],
                [-7.813024997999946, 9.136293411000054],
                [-7.812060832999919, 9.14054584500002],
                [-7.827221869999903, 9.149012566000067],
                [-7.837841987999923, 9.150527955000143],
                [-7.839198111999963, 9.153072357000156],
                [-7.832059859999959, 9.168835640000111],
                [-7.833143234999909, 9.174825669000029],
                [-7.840034960999844, 9.178590774999975],
                [-7.853001117999838, 9.177837372000113],
                [-7.870246886999894, 9.167483330000152],
                [-7.881877898999903, 9.15766620700009],
                [-7.907806873999959, 9.172949791000065],
                [-7.9148950579999, 9.179017067000075],
                [-7.916880130999914, 9.182335853000097],
                [-7.919879911999885, 9.186896324000031],
                [-7.922934054999871, 9.200954438000053],
                [-7.926014899999814, 9.212946892000048],
                [-7.925725935999878, 9.217308999000068],
                [-7.915543078999974, 9.23556327900019],
                [-7.906465053999966, 9.257275582000148],
                [-7.900828838999928, 9.263639450000028],
                [-7.895327091999889, 9.27758789100011],
                [-7.891354082999953, 9.28052330100013],
                [-7.887444971999912, 9.290613173999986],
                [-7.884795187999885, 9.304109574000108],
                [-7.886536121999939, 9.312405586000125],
                [-7.886446952999961, 9.336542129000122],
                [-7.888453006999953, 9.34231472000016],
                [-7.887479781999957, 9.345977784000013],
                [-7.882791041999951, 9.350515366000138],
                [-7.879220008999823, 9.358053207000069],
                [-7.875637053999924, 9.366510391000077],
                [-7.866493224999829, 9.375352860000135],
                [-7.862562178999895, 9.392539978000059],
                [-7.859714984999869, 9.397559166],
                [-7.858211039999958, 9.406274796000105],
                [-7.85608005499995, 9.40992260000013],
                [-7.857664108999927, 9.41270160800002],
                [-7.85551881899994, 9.417270662000078],
                [-7.856388091999918, 9.421651840000038],
                [-7.8553957929999, 9.426465035000092],
                [-7.850316047999968, 9.425478936000161],
                [-7.848882197999899, 9.428906442000027],
                [-7.849986076999926, 9.437743186000034],
                [-7.857914923999886, 9.433017730000017],
                [-7.865322112999934, 9.433339119999971],
                [-7.872231959999908, 9.436185837999972],
                [-7.883121966999909, 9.43563175200012],
                [-7.885251044999961, 9.432210922000081],
                [-7.88696909, 9.424188614000116],
                [-7.891688823999971, 9.416893006000123],
                [-7.894011974999955, 9.416232108000145],
                [-7.915739059999908, 9.419488908000119],
                [-7.942530155999918, 9.406030655000109],
                [-7.968118189999927, 9.395999908000135],
                [-7.983246803999975, 9.394384384000034],
                [-7.987554072999899, 9.396929741000065],
                [-7.999349115999905, 9.397992134000162],
                [-8.011343955999905, 9.401583672000129],
                [-8.047015189999968, 9.40017604799999],
                [-8.06032943599996, 9.401763915],
                [-8.073525428999972, 9.419859886000097],
                [-8.081108091999965, 9.424321174999989],
                [-8.083587645999955, 9.429637909000064],
                [-8.098880767999901, 9.447069168000041],
                [-8.101552009999978, 9.45560932100011],
                [-8.106612204999976, 9.45820045500011],
                [-8.108386992999897, 9.464428902],
                [-8.11161041199989, 9.46584892300018],
                [-8.115238188999854, 9.471870423000155],
                [-8.116086005999932, 9.478316307000057],
                [-8.123171805999903, 9.48575878100013],
                [-8.12402439099992, 9.491518974000087],
                [-8.12860965799996, 9.495251656000164],
                [-8.134058952999908, 9.503824235000081],
                [-8.133332251999946, 9.506574631000092],
                [-8.137627600999963, 9.514903069000127],
                [-8.135928153999942, 9.52131557500013],
                [-8.13287353499993, 9.524957658000119],
                [-8.142521857999895, 9.531052589000183],
                [-8.149227141999972, 9.53205490099998],
                [-8.149865150999972, 9.536662102000093],
                [-8.14747714999993, 9.542608261999987],
                [-8.15194129899993, 9.55622577600019],
                [-8.15073108699994, 9.560349464000126],
                [-8.151818275999858, 9.566109657000027],
                [-8.149935721999952, 9.568616867000173],
                [-8.146450996999931, 9.56949329500003],
                [-8.144314765999923, 9.573603630000036],
                [-8.146282195999902, 9.583056450000072],
                [-8.15292358499994, 9.58911132899999],
                [-8.147597312999949, 9.608127595000099],
                [-8.143600462999814, 9.61290836400019],
                [-8.14426517399994, 9.61521434700012],
                [-8.152274131999889, 9.623129845999983],
                [-8.15178394399993, 9.625423431000115],
                [-8.146223067999927, 9.625583647999974],
                [-8.142211912999926, 9.631511688000046],
                [-8.133578300999943, 9.63669490899997],
                [-8.132756232999952, 9.647030831000052],
                [-8.12779426499992, 9.65478515699999],
                [-8.12773704499989, 9.659381867000036],
                [-8.130000113999927, 9.663778305000164],
                [-8.13668346399993, 9.666620254000065],
                [-8.135270117999937, 9.668440819000125],
                [-8.13063239999991, 9.66884231500012],
                [-8.129448889999878, 9.671128273000079],
                [-8.133279799999968, 9.679681778000031],
                [-8.133197784999936, 9.6861181270001],
                [-8.136388777999969, 9.69052600900011],
                [-8.131613730999959, 9.70196342500003],
                [-8.125121116999935, 9.702342988000055],
                [-8.123926162999965, 9.705318452000085],
                [-8.123189926999885, 9.708526612000128],
                [-8.127100944999938, 9.729496955000172],
                [-8.12424755099994, 9.735439301000156],
                [-8.129503249999914, 9.741480827000146],
                [-8.12356376699995, 9.753133775000094],
                [-8.129758834999961, 9.758266448000143],
                [-8.127864837999823, 9.761464118000049],
                [-8.115331650999963, 9.762918473000013],
                [-8.113455772999885, 9.76473522100008],
                [-8.120694159999971, 9.779076575999966],
                [-8.120546339999919, 9.791033746000096],
                [-8.109597206999922, 9.795266152000124],
                [-8.109334944999887, 9.797790528000121],
                [-8.118066786999918, 9.803878785000165],
                [-8.118724822999923, 9.806876183000043],
                [-8.116115569999977, 9.81167125799999],
                [-8.106197357999918, 9.80787181800008],
                [-8.104505538999831, 9.813366890000168],
                [-8.105089187999965, 9.822341918000063],
                [-8.101484298999935, 9.832644463000122],
                [-8.106848715999888, 9.832987785000114],
                [-8.11140728, 9.836215018000019],
                [-8.112999916999911, 9.838763238000013],
                [-8.108739851999871, 9.84560966600003],
                [-8.109390259999941, 9.849294663000023],
                [-8.107686996999973, 9.855712892000042],
                [-8.109446524999896, 9.863323211000079],
                [-8.113340377999918, 9.867280006000158],
                [-8.117491721999954, 9.868941307000057],
                [-8.123060225999836, 9.868549346],
                [-8.130285263999895, 9.86565017700002],
                [-8.137931824999896, 9.86597442600015],
                [-8.143448828999965, 9.869721413000093],
                [-8.144094465999899, 9.873867035000103],
                [-8.140703201999884, 9.885552407000091],
                [-8.138599395999904, 9.886904716000174],
                [-8.134020803999874, 9.882250786000043],
                [-8.130764006999868, 9.881077766000146],
                [-8.128684996999937, 9.882415771000069],
                [-8.124798774999874, 9.89711761500007],
                [-8.124513625999896, 9.900987626000074],
                [-8.127927780999869, 9.906088830000101],
                [-8.135073660999979, 9.909626008000032],
                [-8.132238387999848, 9.913961411000173],
                [-8.13335323299998, 9.91742324900008],
                [-8.142172813999878, 9.916839599000127],
                [-8.152935027999888, 9.909156799000186],
                [-8.156414985999959, 9.908967972000085],
                [-8.160779952999974, 9.912240028000099],
                [-8.165474890999974, 9.926783561999969],
                [-8.16309833399987, 9.931352616000027],
                [-8.155405998999981, 9.934477805000029],
                [-8.152356147999967, 9.93719864000019],
                [-8.153031349999935, 9.939047814000048],
                [-8.165946959999928, 9.944725038000115],
                [-8.166845321999915, 9.947263717000112],
                [-8.164013861999933, 9.951137543000016],
                [-8.148256300999947, 9.948757172000171],
                [-8.13008689999998, 9.956472396000095],
                [-8.123629569999935, 9.953634263000026],
                [-8.120606422999913, 9.95428562100011],
                [-8.117781637999883, 9.957699776000027],
                [-8.11738300199994, 9.971033096000156],
                [-8.121494291999966, 9.97614288300008],
                [-8.127287863999868, 9.97644424400005],
                [-8.138520239999934, 9.96853256300011],
                [-8.143604277999941, 9.969975471000112],
                [-8.150271415999953, 9.974886894000122],
                [-8.151833533999877, 9.979731561000165],
                [-8.15062904299998, 9.983396530000107],
                [-8.147809027999926, 9.98635292099999],
                [-8.137299537999922, 9.992203713000038],
                [-8.134982108999907, 10.00774097600015],
                [-8.13101005599998, 10.009700775],
                [-8.131426809999937, 10.013851167000041],
                [-8.106679915999962, 10.046900749000088],
                [-8.098949432999916, 10.053019525000082],
                [-8.0791568759999, 10.058758736000016],
                [-8.045100211999966, 10.073300362999987],
                [-8.024494171999947, 10.088688851000143],
                [-8.016502379999963, 10.097101211],
                [-8.011268614999892, 10.107851982000057],
                [-8.005990027999871, 10.122269630000119],
                [-7.988173961999962, 10.15586185400008],
                [-7.985986231999959, 10.16411018299999],
                [-7.98051881899994, 10.174618721000115],
                [-7.978549003999945, 10.175108911],
                [-7.978137016999938, 10.179651261000174],
                [-7.957448959999908, 10.20079040500002],
                [-7.952459811999916, 10.209920883],
                [-7.950551986999926, 10.233359338000128],
                [-7.947204112999941, 10.241130830000145],
                [-7.948703764999948, 10.251501083000107],
                [-7.959441185999879, 10.28544044600011],
                [-7.960949896999921, 10.29489135700004],
                [-7.968624113999965, 10.31361198400009],
                [-7.98009204899995, 10.325710296000125],
                [-7.995274066999969, 10.338311196000177],
                [-8.006831168999952, 10.343051911000146],
                [-8.019609450999894, 10.345394135000106],
                [-8.051108360999876, 10.351169586000083],
                [-8.080821036999907, 10.353360176000137],
                [-8.091686247999974, 10.3580894480001],
                [-8.108613013999957, 10.381052017000115],
                [-8.114282608999929, 10.392850877000058],
                [-8.115967750999971, 10.408049584000025],
                [-8.118845939999915, 10.420270919000075],
                [-8.120452880999949, 10.422128678000036],
                [-8.12763404799989, 10.424050331000103],
                [-8.140680313999951, 10.421220779000123],
                [-8.14817619299987, 10.416010857000174],
                [-8.16391563499991, 10.401021004000086],
                [-8.173233031999871, 10.399049760000025],
                [-8.189496993999967, 10.399238586000024],
                [-8.20202255299995, 10.401220322000029],
                [-8.22539329599988, 10.409998893000136],
                [-8.2315969469999, 10.416279792000125],
                [-8.240627289999964, 10.440291405000096],
                [-8.242721557999971, 10.461020469000175],
                [-8.244764327999974, 10.465409279000028],
                [-8.257006644999933, 10.472219468000162],
                [-8.261837005999894, 10.476868629000137],
                [-8.264067649999902, 10.48516941000014],
                [-8.262269973999935, 10.500790597000048],
                [-8.266160010999897, 10.506361008000056],
                [-8.273758886999929, 10.512879371],
                [-8.276694296999949, 10.520730972000138],
                [-8.277743338999812, 10.531091690000096],
                [-8.272542, 10.56025123600017],
                [-8.273278236999943, 10.57773018000006],
                [-8.278051375999894, 10.587910652000062],
                [-8.285019874999932, 10.588441849000048],
                [-8.289155005999874, 10.593091012],
                [-8.2903032299999, 10.615638734000129],
                [-8.295124052999938, 10.621440888000052],
                [-8.30147266299997, 10.636231422000094],
                [-8.301113128999873, 10.64795875600015],
                [-8.296880722999958, 10.673441887000024],
                [-8.296477316999926, 10.689079285000105],
                [-8.305573462999973, 10.708271980000177],
                [-8.306881903999965, 10.716340065000054],
                [-8.295570373999908, 10.750939369000037],
                [-8.297846793999952, 10.755571366000083],
                [-8.313447951999933, 10.754360199000132],
                [-8.325519561999897, 10.756790161000083],
                [-8.32895660399987, 10.76189136500011],
                [-8.329323767999881, 10.770858765000071],
                [-8.326701162999882, 10.776809694000178],
                [-8.316722869999921, 10.795800209000163],
                [-8.307519911999975, 10.807430268000076],
                [-8.306200026999932, 10.821900367000183],
                [-8.30006217999994, 10.82966137000011],
                [-8.286531447999948, 10.83273124699997],
                [-8.28206634399993, 10.836588860000063],
                [-8.2787427909999, 10.84253120400001],
                [-8.275600432999966, 10.853080750000174],
                [-8.281644821999919, 10.896391870000116],
                [-8.281693458999882, 10.913180352000097],
                [-8.278993606999961, 10.925568582000153],
                [-8.291296958999908, 10.971480371000098],
                [-8.286995887999922, 10.98154926300009],
                [-8.287079810999955, 10.995130539000058],
                [-8.293227196999908, 11.00784969300014],
                [-8.295784949999927, 11.008331300000123],
                [-8.305837630999974, 11.026161193000178],
                [-8.312975882999979, 11.033599854000045],
                [-8.317317962999937, 11.041231155000105],
                [-8.323533056999963, 11.048199654000143],
                [-8.347776412999963, 11.068021775000034],
                [-8.371001243999956, 11.074481010000056],
                [-8.381934165999951, 11.07598114000018],
                [-8.389930725999875, 11.068700791000083],
                [-8.399067878999972, 11.063511849000065],
                [-8.405605315999878, 11.062198639000144],
                [-8.415782927999885, 11.06897926400012],
                [-8.425533293999933, 11.072072030000015],
                [-8.446018218999939, 11.07367038700005],
                [-8.472857474999898, 11.068651199000044],
                [-8.482442855999977, 11.065070152000146],
                [-8.486263274999942, 11.056140900000116],
                [-8.503048896999871, 11.031469345000119],
                [-8.51366805899994, 10.995010376000153],
                [-8.514455795999822, 10.98604106800019],
                [-8.513183593999884, 10.97383880600006],
                [-8.515065191999952, 10.972020149000173],
                [-8.535978316999888, 10.976368905000072],
                [-8.564065932999938, 10.96170044000013],
                [-8.57130527499993, 10.959711075000087],
                [-8.580908774999898, 10.953821183000173],
                [-8.590490340999963, 10.950241087999984],
                [-8.598362922999968, 10.954691887000081],
                [-8.60742664299994, 10.95639038100012],
                [-8.611378669999965, 10.957868576000124],
                [-8.618282318999889, 10.963660240000081],
                [-8.622454643999902, 10.965319635000128],
                [-8.628979682999955, 10.964471817000174],
                [-8.661031721999848, 10.949159622000025],
                [-8.671962738999923, 10.950190544000066],
                [-8.677262304999942, 10.955311776000144],
                [-8.68021488099987, 10.962240220000183],
                [-8.680629729999907, 10.967069627000171],
                [-8.677721976999976, 10.978079795000042],
                [-8.682355880999921, 11.00275039600001],
                [-8.681370734999973, 11.007800103000136],
                [-8.67923831999991, 11.01144886100002],
                [-8.660475730999963, 11.024600030000045],
                [-8.650367736999897, 11.03369998900007],
                [-8.647668837999959, 11.046779632000039],
                [-8.648077009999895, 11.052311898000028],
                [-8.645161627999926, 11.063782691000142],
                [-8.624943732999952, 11.081970216000116],
                [-8.620675085999892, 11.08906078300015],
                [-8.620630263999942, 11.093429565000122],
                [-8.625123976999873, 11.108888627000056],
                [-8.625302314999885, 11.113948822000168],
                [-8.62244605899997, 11.119899751000048],
                [-8.617500304999965, 11.124911308000037],
                [-8.611413001999892, 11.127840043000106],
                [-8.602794647999929, 11.127751351000086],
                [-8.585638046999975, 11.124579430000097],
                [-8.581154823999896, 11.125909805000106],
                [-8.57691955599995, 11.129771233000042],
                [-8.570989607999934, 11.139830590000031],
                [-8.564303396999946, 11.15493869800008],
                [-8.570876121999959, 11.171809196000083],
                [-8.573287962999927, 11.185859681000068],
                [-8.570841788999871, 11.196649552000167],
                [-8.566327093999973, 11.204651832000025],
                [-8.558808326999952, 11.210550309000041],
                [-8.552501678999931, 11.212101936000067],
                [-8.529925345999914, 11.210009576000118],
                [-8.517705917999876, 11.219308854000019],
                [-8.512346266999884, 11.219479562000117],
                [-8.497705458999974, 11.216111184000113],
                [-8.492563247999954, 11.217660904000127],
                [-8.486456870999973, 11.221961975999989],
                [-8.484544753999955, 11.226309777000097],
                [-8.484736442999917, 11.229990960000123],
                [-8.491641996999931, 11.237660409000114],
                [-8.493388175999883, 11.248261453000055],
                [-8.498417854999957, 11.25706005100011],
                [-8.49928569799988, 11.262820243000135],
                [-8.497338293999974, 11.270388603000185],
                [-8.493296621999946, 11.27748108000003],
                [-8.48457908599994, 11.286122321999983],
                [-8.47918891899991, 11.288828849000026],
                [-8.467065810999884, 11.289151192000134],
                [-8.45014953499998, 11.280450821000159],
                [-8.422299384999974, 11.270480157000122],
                [-8.412516593999896, 11.269909860000098],
                [-8.406670569999903, 11.271690369000055],
                [-8.393847464999965, 11.272230149000109],
                [-8.38239192999987, 11.275550841999973],
                [-8.37698841199989, 11.279170037000029],
                [-8.371987342999944, 11.288551331000178],
                [-8.364432335999936, 11.31698989900002],
                [-8.365085601999965, 11.320900917000188],
                [-8.369470596999975, 11.32464122700003],
                [-8.401671408999903, 11.321781158000078],
                [-8.418153762999964, 11.327489852999975],
                [-8.421379089999903, 11.33096790400009],
                [-8.421792983999978, 11.335580825000136],
                [-8.421112059999928, 11.338211060000049],
                [-8.415992736999954, 11.353461265000021],
                [-8.41079044299994, 11.36007023000019],
                [-8.397447585999942, 11.364979745000028],
                [-8.383926391999921, 11.36483192500009],
                [-8.375888824999947, 11.367480277000027],
                [-8.381550787999913, 11.375248909000106],
                [-8.396868705999907, 11.382309914000189],
                [-8.409009932999936, 11.381058693000114],
                [-8.411833761999901, 11.378561019000074],
                [-8.423494338999944, 11.378449441000043],
                [-8.430682182999874, 11.382439613000145],
                [-8.437431334999928, 11.383890151000116],
                [-8.44818782799996, 11.38078117300006],
                [-8.456342697999958, 11.381560327000045],
                [-8.476317405999907, 11.389590263000116],
                [-8.491207121999935, 11.392960549000065],
                [-8.50210285199995, 11.399280548000092],
                [-8.508899687999929, 11.418910980000021],
                [-8.517228125999964, 11.425891877000083],
                [-8.526736258999904, 11.431280137000044],
                [-8.528556823999907, 11.43567180700012],
                [-8.52688694099993, 11.439332009000111],
                [-8.517751692999923, 11.443148613000062],
                [-8.525517463999961, 11.459561347000033],
                [-8.525647162999974, 11.469614982999985],
                [-8.528784751999865, 11.47339439500007],
                [-8.5309495919999, 11.47595119500005],
                [-8.53536319799997, 11.477840424000135],
                [-8.5445499409999, 11.468957902000056],
                [-8.565674780999927, 11.45897960700006],
                [-8.57187938699991, 11.445801735000032],
                [-8.576336860999959, 11.443320274000143],
                [-8.581698416999927, 11.443380356000148],
                [-8.596014975999935, 11.456640243000095],
                [-8.602772711999876, 11.456939697],
                [-8.610721586999944, 11.454961777000108],
                [-8.612629890999926, 11.452760695999984],
                [-8.620209693999868, 11.460969925000029],
                [-8.635683058999916, 11.477231980000056],
                [-8.641654968999944, 11.486949921000075],
                [-8.647912979999944, 11.491149902000018],
                [-8.654132843999946, 11.499261857000022],
                [-8.65773868499997, 11.512410164000187],
                [-8.665340423999908, 11.522830009000131],
                [-8.670388222999975, 11.556010247000131],
                [-8.673102377999896, 11.564998628000183],
                [-8.685650825999971, 11.570421219000025],
                [-8.688864708999972, 11.576198578000117],
                [-8.690624235999962, 11.587491989000114],
                [-8.682872772999872, 11.617091179000113],
                [-8.682334899999887, 11.624670982000112],
                [-8.685074805999932, 11.631138802],
                [-8.69156837499986, 11.635569572000179],
                [-8.702066421999916, 11.636141776999978],
                [-8.719633102999978, 11.629631044000178],
                [-8.733699798999908, 11.630279541999982],
                [-8.760130882999931, 11.606800078999981],
                [-8.77342796399995, 11.617940903000033],
                [-8.779954909999901, 11.618687631],
                [-8.78529453199991, 11.621728896000036],
                [-8.791595459999826, 11.622247696000159],
                [-8.795826910999892, 11.6188411710001],
                [-8.797732352999901, 11.614489555000148],
                [-8.804713249999907, 11.616850853000074],
                [-8.812827109999887, 11.622908592000101],
                [-8.822134970999969, 11.626210212999979],
                [-8.854325294999967, 11.628337861000034],
                [-8.852081298999906, 11.63822078700008],
                [-8.84523868599996, 11.668269157000111],
                [-8.823991775999957, 11.761619569000175],
                [-8.822243690999926, 11.769301414000097],
                [-8.820287704999885, 11.780366897000022],
                [-8.80158615099998, 11.886150359000112],
                [-8.79491329299998, 11.92387199400008],
                [-8.797845840999969, 11.935169221000024],
                [-8.801346778999914, 11.935889245000055],
                [-8.801725386999976, 11.945550918000038],
                [-8.814425467999968, 11.962459565000074],
                [-8.8162021629999, 11.972601890000021],
                [-8.807828902999972, 11.993921279000176],
                [-8.805390358999887, 12.00517272900015],
                [-8.81239223499989, 12.014049530000023],
                [-8.831009864999942, 12.03094100900006],
                [-8.832331656999884, 12.038999557000182],
                [-8.835360525999931, 12.039950371000145],
                [-8.842174529999909, 12.036109924000073],
                [-8.845909117999952, 12.036829948000161],
                [-8.850302695999915, 12.041481973000032],
                [-8.855904578999969, 12.041990279000174],
                [-8.8617963799999, 12.03722000099998],
                [-8.868449210999927, 12.026008606000062],
                [-8.871512412999948, 12.023281098000155],
                [-8.87524318699991, 12.02400112100014],
                [-8.883088111999939, 12.03442955000014],
                [-8.896122932999901, 12.040080070000101],
                [-8.906888962999972, 12.038110733000167],
                [-8.906626700999936, 12.041091919999985],
                [-8.912936210999874, 12.041151047000028],
                [-8.915381430999958, 12.054287911000188],
                [-8.919301031999908, 12.059848786000146],
                [-8.91619682399994, 12.066720963000023],
                [-8.914991379999947, 12.095009804000028],
                [-8.909853933999955, 12.094498635000093],
                [-8.90703678199992, 12.095850945000109],
                [-8.903248786999939, 12.100880624000126],
                [-8.901293754999926, 12.109830856000144],
                [-8.90132999499997, 12.13008117700008],
                [-8.895483970999976, 12.146591186000023],
                [-8.896680831999959, 12.151889801000095],
                [-8.90503597299994, 12.158180236000021],
                [-8.905706404999876, 12.161400795000134],
                [-8.902830124999923, 12.168740273000083],
                [-8.910034179999968, 12.17317008900011],
                [-8.914125441999886, 12.185408592000158],
                [-8.922085761999938, 12.184330941000042],
                [-8.924632071999952, 12.187111855000126],
                [-8.924835205999955, 12.190340996000032],
                [-8.922444343999928, 12.195840836000173],
                [-8.924523353999916, 12.19862079600017],
                [-8.930625914999837, 12.19591140700004],
                [-8.936445234999951, 12.198961257000178],
                [-8.93928337099993, 12.19576072700005],
                [-8.938657760999945, 12.187480927000081],
                [-8.941012381999883, 12.185660362000021],
                [-8.945693968999933, 12.185240747000023],
                [-8.952670097999885, 12.189220428000056],
                [-8.95501136799993, 12.189009666],
                [-8.958547592999935, 12.185818672000153],
                [-8.964604376999944, 12.188408853000112],
                [-8.973310469999888, 12.182510376000153],
                [-8.97859096499991, 12.192910193999978],
                [-8.977144241999952, 12.197718621000035],
                [-8.979435919999958, 12.202580452000063],
                [-8.97797107599996, 12.211811067000042],
                [-8.992900847999977, 12.221440316000155],
                [-8.99382019099994, 12.251360893000026],
                [-8.987021444999868, 12.280740737000144],
                [-8.981332778999956, 12.289428711000085],
                [-8.96743393, 12.300359726000067],
                [-8.960328101999892, 12.310651779000182],
                [-8.957666396999855, 12.320751190000124],
                [-8.960143087999938, 12.332041741000182],
                [-8.968897818999949, 12.348448754000117],
                [-8.988400459999923, 12.36701107000016],
                [-8.99235916099991, 12.369118690999983],
                [-9.002337454999974, 12.379777908000108],
                [-9.008668899999975, 12.384101868000073],
                [-9.013022422999938, 12.38953113499997],
                [-9.024998664999941, 12.412872314000083],
                [-9.03427887, 12.423070909000046],
                [-9.045703887999878, 12.428448678000109],
                [-9.085883139999964, 12.439131737000025],
                [-9.093564032999893, 12.444950104000156],
                [-9.10382938399988, 12.449401855000133],
                [-9.106671333999941, 12.454500199000108],
                [-9.112327576999974, 12.458417893000103],
                [-9.117941856999892, 12.45893096900005],
                [-9.12493419699996, 12.462209703000099],
                [-9.135875702999954, 12.468970300000024],
                [-9.146788596999954, 12.479191781000111],
                [-9.1544685369999, 12.484089851000022],
                [-9.161707877999902, 12.486221313000158],
                [-9.181363106999925, 12.48707103800001],
                [-9.182540893999942, 12.486159325000187],
                [-9.188145636999877, 12.48759079000007],
                [-9.201262473999918, 12.486551286],
                [-9.211073875999944, 12.488930703000165],
                [-9.22552108799988, 12.496641159000035],
                [-9.235364914999934, 12.495578766000165],
                [-9.24517154699987, 12.498420715000066],
                [-9.24912166699994, 12.501898765000021],
                [-9.25332927699992, 12.502631187000077],
                [-9.274454116999948, 12.495670318000123],
                [-9.280632972999967, 12.48444080400003],
                [-9.284166336999931, 12.481941223000035],
                [-9.288610457999937, 12.482210160000022],
                [-9.29421234199998, 12.484319687000038],
                [-9.312580107999963, 12.499419212000134],
                [-9.319593428999895, 12.500630377999983],
                [-9.32082557699988, 12.492818832000069],
                [-9.32535362199991, 12.482731820000026],
                [-9.33007717099997, 12.477938652000091],
                [-9.335703848999913, 12.476828575000184],
                [-9.338947295999958, 12.481001854000056],
                [-9.339552878999882, 12.492968559000133],
                [-9.343757628999981, 12.49392032600008],
                [-9.348928450999892, 12.491661073000103],
                [-9.362003326999911, 12.495670318000123],
                [-9.364145278999956, 12.491318702000115],
                [-9.36660194399991, 12.476390840000079],
                [-9.369669912999825, 12.473419190000072],
                [-9.38512897499993, 12.472161294000102],
                [-9.39173221699997, 12.465769767000154],
                [-9.401577948999886, 12.463770866000061],
                [-9.404639243999952, 12.461499215000117],
                [-9.406102179999948, 12.453921319000187],
                [-9.40360164599997, 12.444469451999964],
                [-9.399626731999888, 12.443749428000103],
                [-9.392327307999892, 12.449440002000017],
                [-9.387411116999942, 12.449628831000098],
                [-9.38438892299996, 12.447312355000122],
                [-9.376596449999965, 12.426091195000026],
                [-9.369876860999966, 12.417518616000109],
                [-9.367777824999962, 12.41658878300018],
                [-9.361191748999886, 12.42091179],
                [-9.357688903999872, 12.420189859000061],
                [-9.3558397299999, 12.417181015000153],
                [-9.35587692199988, 12.412579537000113],
                [-9.358277321999822, 12.405011178000166],
                [-9.34798908199997, 12.404007912999987],
                [-9.3407726289999, 12.399119376999977],
                [-9.337080955999909, 12.392648698000073],
                [-9.336226463999822, 12.38251972099999],
                [-9.334165571999904, 12.376991272000112],
                [-9.32858943899987, 12.371878625000079],
                [-9.324395178999907, 12.369779587000153],
                [-9.314580916999944, 12.368088722000152],
                [-9.312465667999902, 12.369448661000149],
                [-9.30035877299997, 12.36198997400004],
                [-9.29758071799995, 12.358518601000128],
                [-9.296911238999883, 12.354600906000087],
                [-9.299269676999927, 12.352318764000074],
                [-9.304659843999957, 12.350991249000117],
                [-9.307023047999962, 12.348251343000072],
                [-9.307519912999965, 12.344570160000046],
                [-9.304082869999888, 12.335570336000046],
                [-9.304347991999975, 12.331892014000175],
                [-9.308629989999872, 12.323181153000178],
                [-9.312877654999966, 12.318619729000034],
                [-9.316599845999917, 12.321180344000084],
                [-9.320118903999969, 12.319829941000023],
                [-9.322484969999891, 12.316620826000133],
                [-9.323701858999925, 12.310651779000182],
                [-9.31882858299997, 12.277029991000063],
                [-9.31979846899992, 12.272891998999967],
                [-9.329933165999932, 12.263079644000186],
                [-9.341863632999946, 12.262949943000024],
                [-9.344522476999941, 12.252161025000134],
                [-9.340349196999966, 12.247520448000103],
                [-9.340385435999963, 12.242920875000152],
                [-9.353920937999874, 12.246480942000062],
                [-9.359532355999931, 12.246751786000118],
                [-9.365627288999974, 12.24496078500016],
                [-9.387332915999934, 12.250880242000108],
                [-9.391276359999893, 12.25482273199998],
                [-9.401372908999974, 12.24960994800017],
                [-9.40557765899996, 12.250329972000031],
                [-9.41135501899987, 12.259350777000122],
                [-9.41462135199987, 12.260290146000102],
                [-9.424929617999965, 12.258069992000117],
                [-9.441588400999933, 12.251059533000102],
                [-9.461944579999965, 12.24960994800017],
                [-9.473901747999946, 12.245780946000082],
                [-9.479280471999914, 12.24582004600012],
                [-9.48796176899998, 12.24221038900015],
                [-9.490554809999935, 12.239460945000019],
                [-9.499567032999892, 12.222511291000046],
                [-9.505222319999916, 12.216799736000041],
                [-9.52916813, 12.20386123700007],
                [-9.54141044599993, 12.192680358000132],
                [-9.54678153999987, 12.193400384000029],
                [-9.553124426999943, 12.18954086299999],
                [-9.569477080999945, 12.191040993000115],
                [-9.578151702999946, 12.187879563000024],
                [-9.584235189999958, 12.187459946000047],
                [-9.588932037999882, 12.184499740000092],
                [-9.600634575999948, 12.18274021200017],
                [-9.604865073999974, 12.17978191400016],
                [-9.61188125599989, 12.179599762],
                [-9.619155883999952, 12.175971032000177],
                [-9.625705718999939, 12.175318719000074],
                [-9.630397795999954, 12.17283058300012],
                [-9.63699245499987, 12.166200637000088],
                [-9.639596938999887, 12.161389352000185],
                [-9.664050102999965, 12.140851974000157],
                [-9.677724837999904, 12.123689652000166],
                [-9.680814742999928, 12.116349221000064],
                [-9.680461883999953, 12.099550247000082],
                [-9.675691604999884, 12.07882118300006],
                [-9.67974853599992, 12.06665039000012],
                [-9.684212684999977, 12.062998771000082],
                [-9.692170142999885, 12.061209680000104],
                [-9.695935248999945, 12.057560921000118],
                [-9.698081969999919, 12.050901414],
                [-9.697719572999972, 12.035250664000046],
                [-9.699756622999928, 12.023820878000038],
                [-9.733663559999968, 12.026379585000143],
                [-9.765653610999891, 12.03232193100007],
                [-9.769631384999968, 12.03188037800004],
                [-9.790621756999883, 12.040511132000063],
                [-9.809936523999966, 12.056719780000037],
                [-9.8160114289999, 12.057682037000177],
                [-9.825385092999852, 12.052900315000102],
                [-9.86369323799994, 12.058861731999968],
                [-9.87660217299998, 12.055238725000152],
                [-9.880093573999943, 12.057331085999976],
                [-9.882902144999946, 12.057121278000068],
                [-9.888948440999911, 12.061988831000178],
                [-9.908229826999957, 12.081648827000038],
                [-9.914440155999955, 12.098481179000089],
                [-9.919802664999906, 12.100818634000177],
                [-9.936889647999976, 12.096771241000056],
                [-9.941795349999893, 12.097489357000143],
                [-9.952964782999913, 12.106069565000041],
                [-9.958578108999973, 12.105408669000042],
                [-9.97077369799996, 12.098348618000045],
                [-9.980998039999918, 12.108989716000167],
                [-9.98306274399988, 12.113801002000173],
                [-9.990297317999932, 12.118011474000184],
                [-9.995778084999927, 12.124830246000045],
                [-10.001689911999847, 12.12819194799999],
                [-10.024370193999971, 12.128780366],
                [-10.030429840999943, 12.131798745000083],
                [-10.039258956999959, 12.141740799000047],
                [-10.045101165999938, 12.14268875099998],
                [-10.052820204999875, 12.14043045000011],
                [-10.056518553999922, 12.147818566000126],
                [-10.059320449999916, 12.14966964700011],
                [-10.061900137999942, 12.148070335000114],
                [-10.063349723999977, 12.138649941000153],
                [-10.067090033999932, 12.13912868600005],
                [-10.072429656999873, 12.146519662000173],
                [-10.076629637999929, 12.147229195000136],
                [-10.078708647999974, 12.152761460000022],
                [-10.081648825999878, 12.171641350000016],
                [-10.084429740999951, 12.17649078400018],
                [-10.090958594999961, 12.179740906000177],
                [-10.099139212999944, 12.17933082500008],
                [-10.107339859999911, 12.176151276000155],
                [-10.112959860999922, 12.17572116800011],
                [-10.131408690999876, 12.178111078000143],
                [-10.143131256999936, 12.17311096100002],
                [-10.148979185999906, 12.172679901000095],
                [-10.167619704999936, 12.184271812000077],
                [-10.183740614999977, 12.187111855000126],
                [-10.186770438999929, 12.189661979000107],
                [-10.190931319999947, 12.198881149000101],
                [-10.195130348999953, 12.200740815000131],
                [-10.213600158999952, 12.201060296000037],
                [-10.223880767999958, 12.202948571000036],
                [-10.237929343999951, 12.198641777000034],
                [-10.243309973999942, 12.199359894],
                [-10.246100424999952, 12.20189857500003],
                [-10.247481346999905, 12.20628166300014],
                [-10.24627971599989, 12.213401794000106],
                [-10.24837875399993, 12.215250016000084],
                [-10.25376033799995, 12.21527957900014],
                [-10.26712131499994, 12.207058907000146],
                [-10.27644920399996, 12.212849618000064],
                [-10.282999038999947, 12.212649346000035],
                [-10.28745079, 12.209679603999973],
                [-10.288880348999953, 12.204401017000123],
                [-10.285650252999915, 12.19471931600009],
                [-10.288941381999905, 12.19129181],
                [-10.29362010899996, 12.190850256999965],
                [-10.299220085999877, 12.192938805000153],
                [-10.304751395999858, 12.211601257000098],
                [-10.309389114999874, 12.21875095400003],
                [-10.312890051999943, 12.220609664000165],
                [-10.3201398839999, 12.221330643000044],
                [-10.327399253999943, 12.219060899],
                [-10.33658885999995, 12.224949837],
                [-10.339319229999887, 12.225481033000108],
                [-10.341211318999967, 12.220420838],
                [-10.34006023499984, 12.215589524000109],
                [-10.332151411999973, 12.202449798000089],
                [-10.33195114199998, 12.194399834000137],
                [-10.335008620999929, 12.187271119000172],
                [-10.351598740999918, 12.193090440000162],
                [-10.358148574999973, 12.192190170000117],
                [-10.360959051999885, 12.189901352999982],
                [-10.360548972999823, 12.175641060000032],
                [-10.363352775999886, 12.17198085800004],
                [-10.371089933999883, 12.168771745000186],
                [-10.371099471999969, 12.167620658999965],
                [-10.381150245999947, 12.16950130400005],
                [-10.396140097999876, 12.176369666000085],
                [-10.404048918999933, 12.175790787000096],
                [-10.41071987099997, 12.172577858000068],
                [-10.414460181999914, 12.166838647000134],
                [-10.412461279999832, 12.145011902000078],
                [-10.414029120999885, 12.141091348000089],
                [-10.434099196999966, 12.133351326000025],
                [-10.439668654999878, 12.130048753000096],
                [-10.448980330999916, 12.120109558000081],
                [-10.456400871999904, 12.11653137200011],
                [-10.46747875299991, 12.117818833000172],
                [-10.476961134999954, 12.120761871000127],
                [-10.494488715999978, 12.122659684000041],
                [-10.503141403999962, 12.121770860000026],
                [-10.51062965299991, 12.118350030000158],
                [-10.516501425999934, 12.112851142000068],
                [-10.518618583999967, 12.106418611000151],
                [-10.514830589999974, 12.100350379000076],
                [-10.507229805999884, 12.090500831999975],
                [-10.505148887999951, 12.08383274],
                [-10.50702857899995, 12.080380441000102],
                [-10.51873016299993, 12.07882118300006],
                [-10.521540641999877, 12.0760717390001],
                [-10.523669243999962, 12.068941117000065],
                [-10.520448684999963, 12.055820465000124],
                [-10.521389961999944, 12.053059578000045],
                [-10.520300865999957, 12.049989701000186],
                [-10.522939681999901, 12.041231156000151],
                [-10.530981063999889, 12.033596038999974],
                [-10.532690048999939, 12.031708717000186],
                [-10.558639526999968, 12.026510239000118],
                [-10.57094955399998, 12.027271272000064],
                [-10.578290938999885, 12.021979332000114],
                [-10.57900905699995, 12.013929367000117],
                [-10.572030066999844, 12.005621910000116],
                [-10.563640593999878, 12.000530244000174],
                [-10.562950133999948, 11.997770310000078],
                [-10.564358710999954, 11.995928764000098],
                [-10.572779654999977, 11.993660927000121],
                [-10.577010155999972, 11.98608970700002],
                [-10.581918715999905, 11.984490395000023],
                [-10.58893012999988, 11.985440254000025],
                [-10.599479675999874, 11.974199295000119],
                [-10.605548857999963, 11.973990440000023],
                [-10.612790107999842, 11.976781846000051],
                [-10.620751380999877, 11.969209671000101],
                [-10.625699042999884, 11.957730293000111],
                [-10.63018894299995, 11.939570427000035],
                [-10.633478163999882, 11.934749604000046],
                [-10.64027023299991, 11.929480552000143],
                [-10.641460418999884, 11.923500062000016],
                [-10.640089988999932, 11.913150787],
                [-10.64243984199993, 11.910160064000138],
                [-10.651328085999921, 11.905820846000097],
                [-10.65950965899998, 11.903780938000068],
                [-10.662560461999817, 11.900540351000075],
                [-10.662819861999878, 11.89906120400019],
                [-10.672221184999955, 11.893879890999983],
                [-10.690210342999876, 11.893000603000075],
                [-10.697220801999947, 11.896011354000052],
                [-10.717040061999967, 11.915610314000048],
                [-10.731288909999876, 11.916560174000153],
                [-10.735489844999904, 11.91932868900011],
                [-10.74247074199991, 11.933840752000151],
                [-10.751330376999874, 11.943980217000046],
                [-10.745451926999863, 11.962828637000086],
                [-10.749871253999856, 11.97387790700003],
                [-10.756368637999913, 11.993780135000065],
                [-10.761340140999948, 11.999211312000114],
                [-10.77772903399989, 12.010951042000158],
                [-10.786008833999915, 12.024688721000189],
                [-10.792308806999927, 12.029771804000063],
                [-10.795088767999971, 12.037599564000061],
                [-10.794150352999964, 12.041508674],
                [-10.788990020999961, 12.04839038900019],
                [-10.78497028299995, 12.06587123800017],
                [-10.784480094999935, 12.073911667000061],
                [-10.787172316999829, 12.102217674000087],
                [-10.787428855999906, 12.104981423000083],
                [-10.790229796999938, 12.109358788000122],
                [-10.79629039799994, 12.113512038000067],
                [-10.815220832999955, 12.115860938000083],
                [-10.82900047399994, 12.121419906000142],
                [-10.845100402999947, 12.131581307000033],
                [-10.870301246999873, 12.153959274000044],
                [-10.876830099999893, 12.161110879000148],
                [-10.879151343999922, 12.168009759000029],
                [-10.880290031999834, 12.179061890000128],
                [-10.88634967899992, 12.187581062000106],
                [-10.890521048999915, 12.202320100000179],
                [-10.89216041599991, 12.203931808000107],
                [-10.899180411999964, 12.199121475000084],
                [-10.902919769999926, 12.200960158999976],
                [-10.907811164999828, 12.212711334000176],
                [-10.910610197999972, 12.214320182000051],
                [-10.918319701999962, 12.214341163000142],
                [-10.928601265999873, 12.222419739000088],
                [-10.93279933999986, 12.223349572000018],
                [-10.940059662999886, 12.217611314000067],
                [-10.94381046399991, 12.217390061000174],
                [-10.952919005999945, 12.221551896000165],
                [-10.967180251999878, 12.221120835000079],
                [-10.974438666999959, 12.218139649000022],
                [-10.985679625999921, 12.208741188000147],
                [-10.99223041499988, 12.207368851000183],
                [-11.007429122999952, 12.207860947000029],
                [-11.015378951999935, 12.20557022200012],
                [-11.02004909599998, 12.210181236000096],
                [-11.023089408999908, 12.211111069000026],
                [-11.033140181999954, 12.210671426000147],
                [-11.039440154999909, 12.218040466000048],
                [-11.044820784999956, 12.221038819000057],
                [-11.048331260999817, 12.217129708000186],
                [-11.050231932999964, 12.201729775000103],
                [-11.055158613999936, 12.191841125999986],
                [-11.055168151999908, 12.18517017400012],
                [-11.06056117999998, 12.17506027200011],
                [-11.061989783999877, 12.162409782000054],
                [-11.06737995199984, 12.154600144000085],
                [-11.072778701999937, 12.13527965600008],
                [-11.082139967999922, 12.129090308000059],
                [-11.09243869799991, 12.123820306000141],
                [-11.099450111999886, 12.121990203000166],
                [-11.104370116999917, 12.118538856000157],
                [-11.106480598999951, 12.113948821000065],
                [-11.103919028999826, 12.102211953000051],
                [-11.105798721999918, 12.097149849000118],
                [-11.109309195999856, 12.094861032000154],
                [-11.134320258999821, 12.095129967000105],
                [-11.139459608999971, 12.093058587000144],
                [-11.142270087999975, 12.089850427000101],
                [-11.14415931699989, 12.078808785000092],
                [-11.138111113999969, 12.059471131000066],
                [-11.14255905199991, 12.050509453000188],
                [-11.152150153999969, 12.041319848],
                [-11.172731398999872, 12.028930665000189],
                [-11.178819655999973, 12.020420074000072],
                [-11.184199332999981, 12.01744079600013],
                [-11.192021369999964, 12.01453113500014],
                [-11.212590217999946, 12.017761231000065],
                [-11.224040984999931, 12.012022018000096],
                [-11.238301275999902, 11.99592018100003],
                [-11.24695110399989, 11.992468834000022],
                [-11.257471083999917, 11.99248027900012],
                [-11.28005504499987, 12.00431060800014],
                [-11.312400817999901, 12.021247865000078],
                [-11.33962726599998, 12.04204463900004],
                [-11.355179787999816, 12.05391883800013],
                [-11.361760138999898, 12.059941293000065],
                [-11.36034870199984, 12.079039572999989],
                [-11.370948791999979, 12.099250795000046],
                [-11.375990867999974, 12.103891372000021],
                [-11.385109900999907, 12.108269692000079],
                [-11.397958754999877, 12.108269692000079],
                [-11.411050795999813, 12.111728669],
                [-11.439570426999978, 12.121870995000052],
                [-11.464111326999955, 12.133610725000096],
                [-11.469020842999953, 12.138670922000188],
                [-11.473220823999895, 12.149021149000077],
                [-11.481639860999962, 12.16067791099999],
                [-11.494501113999888, 12.178468704000068],
                [-11.495901106999952, 12.186060905000033],
                [-11.494501113999888, 12.195949554000094],
                [-11.497797012999968, 12.209525110000186],
                [-11.49239063199991, 12.21459102700004],
                [-11.482569694999825, 12.232529640000052],
                [-11.476491927999916, 12.238511086000017],
                [-11.463158607999901, 12.241720201000078],
                [-11.458709716999977, 12.249078751000184],
                [-11.45310115899997, 12.252987862000055],
                [-11.45192909199983, 12.265872002000094],
                [-11.449818609999909, 12.271162033],
                [-11.443270682999923, 12.273228645000131],
                [-11.43859004899997, 12.277830123000172],
                [-11.434848785999975, 12.286801338000032],
                [-11.433670997999911, 12.297381402000042],
                [-11.437877654999909, 12.304739952000148],
                [-11.436709402999895, 12.317388534000031],
                [-11.439749717999916, 12.334651948000044],
                [-11.450039862999915, 12.350521087000118],
                [-11.448631286999898, 12.35489273100012],
                [-11.441848753999864, 12.357647896000117],
                [-11.439970970999923, 12.36340141300019],
                [-11.439740179999944, 12.370759965000104],
                [-11.439661025999953, 12.378608703000054],
                [-11.437190055999963, 12.381971360000023],
                [-11.432129860999964, 12.383488654000132],
                [-11.425930975999904, 12.38548088000016],
                [-11.416331290999892, 12.395369531000085],
                [-11.413990973999887, 12.395131112000115],
                [-11.412818908999895, 12.391222001000074],
                [-11.41469955399998, 12.38294029300016],
                [-11.412831306999976, 12.37788009600007],
                [-11.408618926999964, 12.377650261000156],
                [-11.402528763999896, 12.383399962999988],
                [-11.399491308999927, 12.382020950000026],
                [-11.392941474999873, 12.383851051000022],
                [-11.380301474999953, 12.383381844000041],
                [-11.37374973299984, 12.389360429000078],
                [-11.375149725999961, 12.39465046000015],
                [-11.37467956599994, 12.40223884500017],
                [-11.380518912999946, 12.411218643000097],
                [-11.379562377999832, 12.413569451000114],
                [-11.37946033499992, 12.413559913000029],
                [-11.377452850999816, 12.41799545300006],
                [-11.379015921999894, 12.418216706000123],
                [-11.381397246999938, 12.41868209800009],
                [-11.383030890999919, 12.418089868000095],
                [-11.385980605999919, 12.41795635200009],
                [-11.387760161999893, 12.418107034000116],
                [-11.389394760999949, 12.418155671000136],
                [-11.394210814999951, 12.418535234000046],
                [-11.397810935999928, 12.418538094000098],
                [-11.40220165299985, 12.4204845430001],
                [-11.404388427999947, 12.423113823000051],
                [-11.405790328999956, 12.42532539399997],
                [-11.405265806999978, 12.426654817000099],
                [-11.407842635999941, 12.427672387000086],
                [-11.410180091999962, 12.429472924000152],
                [-11.411951064999869, 12.430904388999977],
                [-11.412418364999894, 12.432336808000116],
                [-11.413489341999878, 12.433299065000085],
                [-11.414326666999955, 12.435142517000031],
                [-11.416751860999966, 12.436438560000056],
                [-11.41862392299987, 12.437275887000112],
                [-11.42175102199991, 12.437605857999984],
                [-11.424511908999818, 12.438572883000177],
                [-11.426142692999917, 12.438856126000076],
                [-11.429188727999872, 12.43909454500016],
                [-11.431330680999906, 12.441027641000062],
                [-11.43320655899987, 12.441494942000077],
                [-11.435307502999876, 12.440989494000178],
                [-11.437129974999948, 12.440770149000059],
                [-11.438909530999922, 12.440260887000022],
                [-11.44035530099984, 12.439990997000052],
                [-11.44199943599989, 12.439994812000123],
                [-11.445734977999962, 12.440233231000036],
                [-11.447329520999915, 12.440516472000127],
                [-11.449989317999894, 12.441490174000023],
                [-11.45110511799993, 12.442498207000028],
                [-11.452089308999916, 12.44305229300005],
                [-11.45395660399987, 12.443564414000093],
                [-11.458261489999927, 12.443756103000055],
                [-11.460041045999901, 12.443301202000157],
                [-11.461816786999918, 12.443035127000087],
                [-11.464111326999955, 12.443219185000032],
                [-11.465227126999935, 12.443549156000074],
                [-11.468358038999895, 12.445712090000143],
                [-11.46980380899987, 12.447419167000078],
                [-11.471341132999896, 12.44857692700009],
                [-11.473443031999977, 12.450048448000075],
                [-11.477556229999948, 12.450201035000191],
                [-11.48200130499987, 12.449654580000185],
                [-11.486401556999965, 12.44897174800019],
                [-11.490000724999959, 12.449071885000023],
                [-11.492853163999882, 12.448758124999983],
                [-11.495192528999837, 12.448300361000122],
                [-11.49762248999997, 12.44858455700006],
                [-11.500942230999954, 12.44826603000007],
                [-11.504357337999977, 12.44781398800012],
                [-11.507861136999963, 12.446812628999965],
                [-11.512820244999943, 12.446912766000025],
                [-11.515720367999961, 12.446462632000078],
                [-11.519042015999958, 12.446053506000112],
                [-11.521518706999814, 12.445643424000139],
                [-11.524791717999904, 12.445468903000176],
                [-11.527270316999818, 12.44487762599999],
                [-11.531813621999959, 12.44451141400009],
                [-11.534472464999851, 12.44346237200017],
                [-11.53713893899993, 12.44397544900005],
                [-11.540646553999977, 12.444119453000098],
                [-11.543965338999953, 12.443249702000116],
                [-11.546215056999927, 12.443305969999983],
                [-11.548172950999913, 12.443168641000113],
                [-11.550230979999867, 12.443266868000137],
                [-11.55327415499994, 12.442348480000021],
                [-11.55566120199984, 12.442397117000041],
                [-11.557581901999924, 12.44171714800018],
                [-11.56080722799993, 12.441677093000123],
                [-11.563419342999964, 12.441403390000119],
                [-11.566513060999966, 12.44159603200012],
                [-11.569691656999964, 12.441555022999978],
                [-11.573011397999835, 12.44059562699999],
                [-11.574737548999906, 12.439949035000154],
                [-11.5768928519999, 12.440229416000022],
                [-11.582781790999888, 12.439509392000105],
                [-11.584978103999902, 12.439510347000123],
                [-11.58694744099995, 12.439282418000175],
                [-11.588958739999953, 12.439334869000163],
                [-11.590966223999942, 12.43823146900013],
                [-11.592982290999942, 12.437227250000092],
                [-11.595644950999883, 12.43515777500005],
                [-11.597756384999968, 12.433179855000162],
                [-11.599955557999976, 12.431527138000092],
                [-11.601918219999902, 12.43171501200004],
                [-11.603411674999847, 12.432041169000115],
                [-11.605607986999928, 12.432691575000092],
                [-11.608603477999964, 12.432602883000015],
                [-11.611692427999856, 12.431041716999971],
                [-11.614358902999925, 12.42944145199999],
                [-11.616280554999946, 12.42857170200017],
                [-11.619318962999898, 12.428798675000053],
                [-11.62076568599997, 12.42916965500018],
                [-11.622021673999882, 12.430051804000072],
                [-11.623099326999977, 12.431979179000109],
                [-11.624450682999964, 12.433369637000055],
                [-11.626223563999929, 12.434800148000022],
                [-11.628137587999845, 12.435212135000086],
                [-11.62996482899996, 12.435307502000057],
                [-11.631742476999932, 12.434986115000015],
                [-11.633984564999935, 12.43416595400015],
                [-11.636093138999968, 12.433107377],
                [-11.639128685999935, 12.432792664000033],
                [-11.641003608999881, 12.432057380000117],
                [-11.642874716999927, 12.43151187899997],
                [-11.647040366999875, 12.429766655000037],
                [-11.649325370999918, 12.430734634999965],
                [-11.65722846899996, 12.429134368000177],
                [-11.65947341899988, 12.428177833000177],
                [-11.661352156999897, 12.427813531000083],
                [-11.663222312999949, 12.426707266999983],
                [-11.666583061999916, 12.426072122000107],
                [-11.66812515199996, 12.42543125200001],
                [-11.669205664999936, 12.424281120999979],
                [-11.671362876999979, 12.422997474999988],
                [-11.672152518999951, 12.420831681000038],
                [-11.673645972999964, 12.419870376000176],
                [-11.676788330999898, 12.418955803000074],
                [-11.678144453999948, 12.418494225000074],
                [-11.679683685999919, 12.41739368500015],
                [-11.68071937499991, 12.416106225000021],
                [-11.681982040999856, 12.414361001000032],
                [-11.683433531999867, 12.412888527000064],
                [-11.684319495999887, 12.41109371300007],
                [-11.686333655999931, 12.40944767000002],
                [-11.68835162999983, 12.40751266500007],
                [-11.68909740499987, 12.40668201599999],
                [-11.692654608999874, 12.4043416990001],
                [-11.69616222399992, 12.402324676999967],
                [-11.69943523399985, 12.401362419000122],
                [-11.70111942299991, 12.401130676000037],
                [-11.702712057999975, 12.40154743300019],
                [-11.705650329999912, 12.401645660000042],
                [-11.710090635999961, 12.402114869000059],
                [-11.712893484999881, 12.402763367000034],
                [-11.71463203399992, 12.401890754000078],
                [-11.716827391999857, 12.401158332000023],
                [-11.718563080999957, 12.398994446000074],
                [-11.720151899999962, 12.397523879000119],
                [-11.724592207999876, 12.392836572000022],
                [-11.726791380999941, 12.391048431000115],
                [-11.72969436699998, 12.391092300000082],
                [-11.731654166999874, 12.390176773000121],
                [-11.73352622899995, 12.391057014000012],
                [-11.735205649999898, 12.39119529800007],
                [-11.737643241999876, 12.390916825000033],
                [-11.741566657999897, 12.39009761900013],
                [-11.743765831999951, 12.390512466000075],
                [-11.745732306999969, 12.389732362000188],
                [-11.747690200999955, 12.390423774000055],
                [-11.74984168899988, 12.390982628000074],
                [-11.751052855999887, 12.390337945000113],
                [-11.752454758999875, 12.389875413000027],
                [-11.75465393199994, 12.388132096000106],
                [-11.756480216999876, 12.387495042000182],
                [-11.758864401999972, 12.386340142],
                [-11.76031875699988, 12.386158944000101],
                [-11.762508391999972, 12.386628151000139],
                [-11.764572143999942, 12.386164665000138],
                [-11.765971183999966, 12.385937691000038],
                [-11.768028258999891, 12.388978004000137],
                [-11.770499228999881, 12.391969681000148],
                [-11.77404880599994, 12.39546966600011],
                [-11.776668547999975, 12.395339012000136],
                [-11.778632162999827, 12.396256447000098],
                [-11.781104088999882, 12.396167756000125],
                [-11.782927513999937, 12.396587373000102],
                [-11.785594938999964, 12.3970069880001],
                [-11.788489341999878, 12.397745132000068],
                [-11.79082393699997, 12.397655488000112],
                [-11.79189968199995, 12.396828652000067],
                [-11.793543815999953, 12.396875381000143],
                [-11.794007300999908, 12.40023136100018],
                [-11.794801712999913, 12.401387215000057],
                [-11.796339987999943, 12.402579307999986],
                [-11.798018455999966, 12.40359211100008],
                [-11.799378394999962, 12.405071258000135],
                [-11.80082034999998, 12.405856132000054],
                [-11.802556991999893, 12.405858040000055],
                [-11.803626060999875, 12.405582429000049],
                [-11.804847716999973, 12.40480136799999],
                [-11.805457114999967, 12.403833390000045],
                [-11.807040213999926, 12.402822495000123],
                [-11.808353422999971, 12.401393891000055],
                [-11.809807776999946, 12.399604797],
                [-11.81092739099995, 12.399744033000161],
                [-11.811204909999901, 12.401120184999968],
                [-11.811808584999937, 12.402048111],
                [-11.812372206999953, 12.402551652000056],
                [-11.814663886999938, 12.402966499000172],
                [-11.817231178999975, 12.402599335000161],
                [-11.821486472999823, 12.402514458000155],
                [-11.822798729999931, 12.403387069000132],
                [-11.825131416999966, 12.402655602000095],
                [-11.826346396999952, 12.402471543000047],
                [-11.827893255999925, 12.40297699000007],
                [-11.829991339999879, 12.404220581000175],
                [-11.833174705999966, 12.401648522000073],
                [-11.833503723999911, 12.401001930000064],
                [-11.834856986999966, 12.400683403000073],
                [-11.836771964999969, 12.401193619000026],
                [-11.838504790999878, 12.399994850000041],
                [-11.840425490999905, 12.399168014],
                [-11.841494560999934, 12.39815998099999],
                [-11.84346199099997, 12.396503449000079],
                [-11.844869613999833, 12.396366119999982],
                [-11.846690176999914, 12.396965981000164],
                [-11.846961974999942, 12.39881134000018],
                [-11.848269462999951, 12.401616096000055],
                [-11.849625586999878, 12.40272521999998],
                [-11.851405142999965, 12.402908326000045],
                [-11.85262012499993, 12.403554917000179],
                [-11.853875160999962, 12.40456199700003],
                [-11.856723784999929, 12.407602311000062],
                [-11.858413695999957, 12.409220694999988],
                [-11.861443518999977, 12.412398339000049],
                [-11.86200046499988, 12.415756226000099],
                [-11.862797736999937, 12.417509078000023],
                [-11.86298465799996, 12.4187936780001],
                [-11.865456580999933, 12.419535638000184],
                [-11.867843627999832, 12.419951440000148],
                [-11.86933612799993, 12.420130731000143],
                [-11.871256827999957, 12.420135499000025],
                [-11.873685836999869, 12.41981124900019],
                [-11.87574386599988, 12.420593263000058],
                [-11.877470016999894, 12.421471596000174],
                [-11.879055975999847, 12.422582626000064],
                [-11.881209373999923, 12.423817634000102],
                [-11.883359907999875, 12.424555779000173],
                [-11.88574504899998, 12.42557621100002],
                [-11.887753485999951, 12.425987243000122],
                [-11.888545035999925, 12.426584244000026],
                [-11.889945028999932, 12.427142144000186],
                [-11.890604972999824, 12.428016663000164],
                [-11.891859053999951, 12.429990767999982],
                [-11.892378807999933, 12.43183899000013],
                [-11.893682478999892, 12.434228897000082],
                [-11.893682478999892, 12.43653202000013],
                [-11.894470215999888, 12.439105988000108],
                [-11.895546913999965, 12.441267014000061],
                [-11.89605998999997, 12.443757058000187],
                [-11.897133827999937, 12.44688511000004],
                [-11.89806938199996, 12.449371339000095],
                [-11.899466514999915, 12.4494142530001],
                [-11.90297794199995, 12.446978569],
                [-11.905828475999897, 12.445367814000065],
                [-11.911159515999827, 12.442150116000107],
                [-11.91533279399988, 12.438159943000073],
                [-11.922482490999926, 12.433371544000124],
                [-11.926695823999921, 12.431091310000113],
                [-11.934072493999906, 12.427627565000137],
                [-11.936641691999966, 12.426473618000102],
                [-11.938984871999935, 12.424223900000129],
                [-11.943430899999953, 12.421971322000104],
                [-11.947125435999908, 12.419583321000118],
                [-11.948620795999886, 12.418200493000143],
                [-11.949928284999828, 12.41792583500012],
                [-11.959372519999931, 12.411808014000144],
                [-11.961472510999954, 12.410522461000085],
                [-11.96558761599988, 12.409006120000129],
                [-11.96975231099998, 12.408131599000114],
                [-11.972275733999936, 12.40679550100009],
                [-11.973819731999981, 12.406840326000122],
                [-11.974981308999872, 12.40725994100012],
                [-11.977695465999943, 12.408545493000076],
                [-11.978487967999968, 12.40919494600007],
                [-11.979052543999956, 12.408777237000095],
                [-11.981198309999854, 12.4083671570001],
                [-11.982974052999964, 12.40740299200013],
                [-11.984289169999954, 12.40652370600003],
                [-11.986065864999887, 12.406208991000028],
                [-11.987794876999942, 12.406065942],
                [-11.990220069999964, 12.40533352000017],
                [-11.994100569999887, 12.405105592000155],
                [-11.995645521999904, 12.405791282000052],
                [-12.000124931999892, 12.405380250000121],
                [-12.000972746999878, 12.405137063000154],
                [-12.004425048999963, 12.404551506000132],
                [-12.006556510999928, 12.403789520000146],
                [-12.007781027999897, 12.403595924000115],
                [-12.00859451399998, 12.403297425000176],
                [-12.009910582999908, 12.403113366000127],
                [-12.011178016999963, 12.403154373000064],
                [-12.01224994699993, 12.403409958000168],
                [-12.013777731999937, 12.404013634000023],
                [-12.014778135999961, 12.404873849000069],
                [-12.015809058999878, 12.406339645],
                [-12.016323088999968, 12.408006667999985],
                [-12.017794609999953, 12.410362243000122],
                [-12.018474578999928, 12.411119461000055],
                [-12.019262313999889, 12.411372186000108],
                [-12.020472525999935, 12.41150283899998],
                [-12.023619652999912, 12.412846565000166],
                [-12.024324415999956, 12.41304397600004],
                [-12.025327682999944, 12.413723946],
                [-12.02663803199988, 12.41404533400015],
                [-12.028895377999959, 12.415126800999985],
                [-12.030136107999965, 12.41589927800004],
                [-12.03140544799993, 12.417457581000122],
                [-12.031906128999935, 12.417887688000064],
                [-12.032708168999875, 12.419251442000132],
                [-12.033001899999931, 12.42013931400004],
                [-12.034272193999982, 12.421507836000103],
                [-12.03480243699994, 12.42254447900018],
                [-12.038378714999908, 12.426476479000087],
                [-12.03993511199991, 12.427812576000065],
                [-12.04096221799989, 12.42789554500007],
                [-12.042641638999953, 12.428266525000083],
                [-12.043351172999962, 12.428147317000139],
                [-12.045750617999943, 12.428038597000011],
                [-12.047145842999953, 12.428179741000179],
                [-12.049213409999936, 12.428148269000019],
                [-12.052311896999981, 12.428019523000046],
                [-12.05291557299995, 12.42830657899998],
                [-12.053623198999958, 12.428333283000086],
                [-12.054454802999942, 12.428729057000112],
                [-12.055762290999951, 12.430152893000184],
                [-12.05649948099989, 12.430720330000156],
                [-12.057168006999973, 12.431757925999989],
                [-12.058728217999942, 12.432822228000134],
                [-12.059601783999824, 12.43353652899998],
                [-12.06108665399995, 12.433949471000062],
                [-12.062714576999895, 12.432991981000043],
                [-12.064238548999867, 12.432304382000098],
                [-12.065972327999873, 12.430922509000084],
                [-12.0669384, 12.429993631000116],
                [-12.067386625999916, 12.429357529000072],
                [-12.067731856999956, 12.428507806000027],
                [-12.069622993999928, 12.42832470000019],
                [-12.072992323999927, 12.428568839000036],
                [-12.075138091999918, 12.428998947000082],
                [-12.075983046999852, 12.429026603000068],
                [-12.077646254999877, 12.428339957999981],
                [-12.079228400999966, 12.427426338000089],
                [-12.082127571999933, 12.426219941],
                [-12.082396506999885, 12.425129890000164],
                [-12.084374426999943, 12.424952507000114],
                [-12.085117339999954, 12.425114632000145],
                [-12.086155890999919, 12.425062180000054],
                [-12.088029860999882, 12.425163269000166],
                [-12.08872604399994, 12.425324440000054],
                [-12.09074687899988, 12.42381858900012],
                [-12.091954229999885, 12.422657967000191],
                [-12.093641280999918, 12.42137527500006],
                [-12.09453392099988, 12.421267509000074],
                [-12.096148490999894, 12.420715331],
                [-12.097911833999945, 12.419939995000163],
                [-12.09896755099993, 12.419237137000096],
                [-12.099918365999883, 12.418849946000137],
                [-12.101325988999918, 12.418711662000078],
                [-12.103167532999976, 12.418396950000101],
                [-12.105129241999919, 12.418688774000088],
                [-12.10594654099998, 12.418065072000047],
                [-12.107091903999958, 12.417553903000112],
                [-12.108633993999831, 12.4175977700001],
                [-12.109974861999945, 12.41695213200012],
                [-12.112150191999888, 12.41632557],
                [-12.112830162999899, 12.415655136000112],
                [-12.1148481379999, 12.414294243000029],
                [-12.115892408999969, 12.413772583000025],
                [-12.116569518999881, 12.413237573000174],
                [-12.116975784999909, 12.412149430000113],
                [-12.117362022999885, 12.41050052700001],
                [-12.117299079999952, 12.409533502000045],
                [-12.117764471999976, 12.408120156000166],
                [-12.118920325999966, 12.40584659700005],
                [-12.119626043999915, 12.404300689000081],
                [-12.120604514999968, 12.4031372070001],
                [-12.121113776999891, 12.40190506],
                [-12.122532844999967, 12.399878502000035],
                [-12.123252868999884, 12.397916795000128],
                [-12.123700141999961, 12.397335053000177],
                [-12.125297546999946, 12.395861626],
                [-12.126959799999895, 12.395038605000025],
                [-12.1277542119999, 12.39386367800006],
                [-12.128868101999956, 12.39280128500019],
                [-12.130523681999819, 12.389305114000024],
                [-12.131408690999933, 12.388220788000183],
                [-12.133265495999922, 12.38735580500014],
                [-12.136570930999937, 12.386721611000041],
                [-12.137977598999896, 12.386628151000139],
                [-12.140967368999895, 12.385431290000156],
                [-12.142235755999877, 12.385472297000035],
                [-12.143368721999934, 12.38523101800007],
                [-12.144888877999904, 12.384768485999984],
                [-12.14871025199983, 12.384148598000024],
                [-12.149417877999952, 12.382422447000124],
                [-12.150208471999974, 12.379910469000151],
                [-12.150795936999828, 12.379103661000158],
                [-12.152785300999938, 12.376992225000095],
                [-12.154322624999963, 12.375888825000061],
                [-12.156731604999948, 12.37444210000001],
                [-12.157812118999914, 12.37394046900016],
                [-12.159821509999915, 12.373635292000074],
                [-12.16158580799987, 12.372690201000125],
                [-12.16407489699992, 12.371924400000069],
                [-12.165839194999819, 12.371010780000177],
                [-12.170903206999867, 12.368872642000156],
                [-12.173244476999969, 12.367347718000133],
                [-12.17476654099994, 12.366458893000015],
                [-12.175754546999883, 12.365704536000067],
                [-12.176077841999927, 12.365056038000034],
                [-12.17614269299986, 12.364405632000057],
                [-12.176010130999884, 12.363300322999976],
                [-12.17643260899996, 12.361520767000172],
                [-12.176860808999947, 12.360915184000078],
                [-12.17780017799987, 12.360232354000061],
                [-12.181380272999945, 12.359448433000125],
                [-12.183626175999962, 12.359472276000076],
                [-12.184629439999867, 12.359907151000073],
                [-12.185199736999891, 12.360853196000107],
                [-12.185863494999921, 12.361012459000051],
                [-12.187029837999944, 12.361042977000068],
                [-12.188960075999887, 12.360918046000165],
                [-12.19066238299996, 12.359039306000113],
                [-12.193246840999905, 12.358795167000096],
                [-12.195026396999822, 12.359075547000032],
                [-12.196294783999974, 12.359069825000063],
                [-12.197762487999967, 12.35847091700009],
                [-12.200019835999967, 12.357021331000055],
                [-12.201291083999934, 12.356691361000117],
                [-12.202752113999907, 12.356688501000065],
                [-12.207385063999823, 12.355588914000123],
                [-12.208139418999963, 12.355515481000168],
                [-12.21057415099989, 12.355585099000052],
                [-12.21111965199998, 12.356069564000052],
                [-12.212180137999894, 12.35664939899999],
                [-12.214478493999934, 12.358200074000138],
                [-12.219026564999979, 12.358291626000096],
                [-12.221062659999973, 12.35784244500013],
                [-12.22349643699988, 12.3579216010001],
                [-12.224641799999972, 12.357355117000111],
                [-12.226088522999873, 12.356060029000105],
                [-12.22760677399998, 12.354124070000069],
                [-12.228241920999949, 12.353137017000165],
                [-12.22974586399988, 12.351707460000114],
                [-12.232160567999927, 12.350991249000117],
                [-12.233866691999879, 12.350402831000054],
                [-12.23537731099998, 12.350074768000013],
                [-12.238455771999952, 12.34915828800007],
                [-12.239983558999882, 12.349805833000062],
                [-12.241087912999888, 12.350485802000094],
                [-12.241906166999911, 12.351288795000187],
                [-12.243354796999938, 12.35165596000013],
                [-12.244687079999949, 12.352527618000124],
                [-12.245941162999884, 12.352982522000104],
                [-12.246922493999932, 12.353146552000169],
                [-12.248159408999925, 12.352589608000073],
                [-12.249924659999976, 12.351667405000057],
                [-12.250679969999965, 12.351422309000043],
                [-12.253246307999916, 12.350346566000155],
                [-12.25474834399995, 12.349006653],
                [-12.256180762999975, 12.348225594000155],
                [-12.25706100399998, 12.348388672000112],
                [-12.259735106999926, 12.34846973499998],
                [-12.26053619399994, 12.348351478000097],
                [-12.26131534599989, 12.347591400000056],
                [-12.2622509, 12.346010208000166],
                [-12.265151977999835, 12.34628391300015],
                [-12.265932082999882, 12.34543323600002],
                [-12.267754554999954, 12.344241142000158],
                [-12.268465040999956, 12.344031335000125],
                [-12.270579337999834, 12.343862535000028],
                [-12.273715971999934, 12.34267425600018],
                [-12.274408339999923, 12.341452599000093],
                [-12.275385855999957, 12.340233802000057],
                [-12.276017189999891, 12.33960819300006],
                [-12.27784347599993, 12.33809948000004],
                [-12.279553412999917, 12.336210251000125],
                [-12.280778885999894, 12.335834504000047],
                [-12.282034873999976, 12.336109161000138],
                [-12.284041403999936, 12.336715698000091],
                [-12.287022590999925, 12.337134362000143],
                [-12.288290977999964, 12.337164879000056],
                [-12.28996944399995, 12.337452889000076],
                [-12.292669295999872, 12.336792946000116],
                [-12.298626899999931, 12.333744048000085],
                [-12.299394607999886, 12.333264351000082],
                [-12.301958082999874, 12.332285882000065],
                [-12.304445266999892, 12.330883026000151],
                [-12.306165694999947, 12.329734803000122],
                [-12.307537078999928, 12.329548836000072],
                [-12.309118270999932, 12.328489304000186],
                [-12.310733794999976, 12.32780075200003],
                [-12.31329727199983, 12.326859475000049],
                [-12.314913749999903, 12.326035499000056],
                [-12.317032813999901, 12.324529649],
                [-12.319871902999978, 12.323654175000058],
                [-12.322210311999925, 12.322294236000062],
                [-12.323894500999927, 12.321146012999975],
                [-12.325552940999899, 12.320647240000028],
                [-12.327441214999908, 12.320196151000118],
                [-12.331199645999959, 12.318416595000144],
                [-12.332195281999816, 12.318074226000135],
                [-12.333831786999951, 12.317024232000108],
                [-12.336866378999957, 12.315915108000183],
                [-12.33882617899991, 12.314732552],
                [-12.342956541999968, 12.313282967000077],
                [-12.344420432999982, 12.313007354000092],
                [-12.34804153499988, 12.311046600000054],
                [-12.352942466999934, 12.308889389000171],
                [-12.35488891599988, 12.308113099000082],
                [-12.355745315999968, 12.307859420000113],
                [-12.356794357999888, 12.308682441000087],
                [-12.357493400999886, 12.310405732000163],
                [-12.357951163999928, 12.312397958000133],
                [-12.35868358599987, 12.314447404000077],
                [-12.35933971399993, 12.315943719000131],
                [-12.36074447599998, 12.318840026999965],
                [-12.361371039999938, 12.321348190000094],
                [-12.36209011099993, 12.323903084000108],
                [-12.362373351999906, 12.326814651000063],
                [-12.36303329499998, 12.329874992000043],
                [-12.363936424999906, 12.332341195000083],
                [-12.364337920999901, 12.334431648000134],
                [-12.364844321999897, 12.33624458300011],
                [-12.365343092999979, 12.336899758000072],
                [-12.367255210999872, 12.338662148000026],
                [-12.368702887999973, 12.339028358000178],
                [-12.370082854999907, 12.339764595000076],
                [-12.372008322999875, 12.341111183000066],
                [-12.37316989999988, 12.341654779000123],
                [-12.37336540199982, 12.344241142000158],
                [-12.374897002999887, 12.346351623999965],
                [-12.376098631999923, 12.348368646000097],
                [-12.377378464999936, 12.350901604000057],
                [-12.378726004999976, 12.353100777],
                [-12.380636214999925, 12.357817651],
                [-12.38165569299997, 12.359733581000114],
                [-12.382311820999917, 12.361230851000187],
                [-12.382646560999888, 12.362715721000086],
                [-12.383349418999956, 12.364023209000095],
                [-12.383913993999954, 12.365509987000053],
                [-12.385006904999898, 12.36651420600009],
                [-12.386143684999979, 12.367745399000057],
                [-12.387323378999895, 12.369211197000027],
                [-12.388248443999942, 12.36946392200008],
                [-12.389945983999894, 12.370718002999979],
                [-12.390205382999966, 12.371462823000172],
                [-12.390178679999963, 12.372248650000074],
                [-12.38967418599998, 12.374944686000049],
                [-12.390400886999885, 12.375801086000024],
                [-12.391551970999899, 12.376480103000176],
                [-12.393267631999947, 12.37864685000011],
                [-12.394952772999943, 12.380171776000168],
                [-12.397299765999946, 12.381766319],
                [-12.398589133999963, 12.382447243000058],
                [-12.40028381299993, 12.383882523000125],
                [-12.402737617999946, 12.384872437000183],
                [-12.404218672999889, 12.385609626000132],
                [-12.40576362499985, 12.385560989000112],
                [-12.408827780999957, 12.385147094000047],
                [-12.411803244999874, 12.384452819000103],
                [-12.416789053999935, 12.38409423800016],
                [-12.420442579999872, 12.384481431000154],
                [-12.423320769999918, 12.38525867500016],
                [-12.425934791999907, 12.38588809900017],
                [-12.429097175999914, 12.386713028000145],
                [-12.431035995999935, 12.387833595000018],
                [-12.433454512999958, 12.388643265000042],
                [-12.435322761999885, 12.39031410199999],
                [-12.436730385999908, 12.391963006000026],
                [-12.437736510999855, 12.39255046900007],
                [-12.441596983999887, 12.395288467000114],
                [-12.443670272999896, 12.396636010000122],
                [-12.444841385999894, 12.398191453000152],
                [-12.446310996999898, 12.39925384500009],
                [-12.448389052999914, 12.400094987000045],
                [-12.451001166999959, 12.400815964000117],
                [-12.45305919599997, 12.400925636000125],
                [-12.455162046999874, 12.40117073000016],
                [-12.45773124699997, 12.401619911000125],
                [-12.459741591999887, 12.401909828000043],
                [-12.462071418999926, 12.40243721000013],
                [-12.466425894999873, 12.402749062],
                [-12.471293449999905, 12.403219224],
                [-12.473939894999944, 12.404219628000021],
                [-12.476361274999931, 12.404747963000148],
                [-12.48147487499989, 12.406376839000075],
                [-12.484924316999923, 12.407122612000023],
                [-12.487645148999889, 12.40715694500011],
                [-12.49011135, 12.407821655000077],
                [-12.491927146999899, 12.408245087000125],
                [-12.494146346999912, 12.407849313000042],
                [-12.495611189999977, 12.407383919000097],
                [-12.498520850999967, 12.405857087000072],
                [-12.501888273999953, 12.404812813000149],
                [-12.506675720999851, 12.404992103999973],
                [-12.508732794999958, 12.40514659900009],
                [-12.511110305999864, 12.405493737000029],
                [-12.512197494999953, 12.40525054900013],
                [-12.51528835199997, 12.404050828000095],
                [-12.517002104999904, 12.40170765],
                [-12.520456313999887, 12.3979806910001],
                [-12.522607802999914, 12.39500045799997],
                [-12.524052619999907, 12.39384842000004],
                [-12.525650024999948, 12.392146110000056],
                [-12.526853561999928, 12.391208648000145],
                [-12.530786513999942, 12.388470650000102],
                [-12.532047271999886, 12.387307168000063],
                [-12.533566475999919, 12.385052682000094],
                [-12.53421115899988, 12.383965492000186],
                [-12.536567686999888, 12.380761146000054],
                [-12.539576529999977, 12.377435685000023],
                [-12.54048061299983, 12.377099992000012],
                [-12.542783737999969, 12.375594139000043],
                [-12.544185638999977, 12.374070168000173],
                [-12.546553611999968, 12.373496056000079],
                [-12.551293373999897, 12.371794701000113],
                [-12.555093764999924, 12.37033748600004],
                [-12.555709838999917, 12.370352746000037],
                [-12.557886122999946, 12.369541168000069],
                [-12.561004638999975, 12.368302345000131],
                [-12.56320858, 12.367527961],
                [-12.563970565999966, 12.368610382000156],
                [-12.56573390899996, 12.370740892000015],
                [-12.566994666999904, 12.37252330800004],
                [-12.567753790999916, 12.373929978000092],
                [-12.570142744999941, 12.377041817000077],
                [-12.571137, 12.379880000000185],
                [-12.57195, 12.38239],
                [-12.573394849999943, 12.384329002000072],
                [-12.574476, 12.385602],
                [-12.577259000999902, 12.386784000000148],
                [-12.57937415799995, 12.386667261000184],
                [-12.580307005999941, 12.388211250000097],
                [-12.581032752999931, 12.392238616999975],
                [-12.582514761999903, 12.39601993600013],
                [-12.582879971999944, 12.3993030850001],
                [-12.582519999999874, 12.400393000000122],
                [-12.581591, 12.401524000000109],
                [-12.581175, 12.402794],
                [-12.58114804, 12.403668724000113],
                [-12.580764, 12.405411000000186],
                [-12.580548570999952, 12.408030192000183],
                [-12.581186102999936, 12.40851398100017],
                [-12.58289, 12.408195000000148],
                [-12.58533099899995, 12.406608001],
                [-12.589944999999886, 12.404716],
                [-12.592968, 12.40383],
                [-12.594530179999936, 12.403744239000048],
                [-12.59630584599995, 12.403796196000144],
                [-12.598791, 12.405426],
                [-12.600815999999895, 12.408164],
                [-12.608366965999949, 12.412938119000103],
                [-12.608737, 12.414659],
                [-12.609725972999911, 12.416112961000067],
                [-12.610325812999918, 12.41792583500012],
                [-12.610370634999981, 12.421061517000055],
                [-12.610295, 12.422217],
                [-12.61079, 12.42439],
                [-12.611871, 12.4259],
                [-12.612842999999884, 12.426207],
                [-12.615002419999882, 12.42783693600012],
                [-12.617905615999916, 12.429486275000102],
                [-12.619831, 12.431102000000124],
                [-12.621711999999832, 12.432547000000113],
                [-12.622657, 12.433721000000105],
                [-12.623672403999933, 12.434308083000076],
                [-12.627412495999977, 12.435009408000099],
                [-12.628951072999882, 12.43514919300003],
                [-12.630070685999897, 12.435411454000132],
                [-12.630798999999854, 12.436123],
                [-12.632584977999898, 12.435940003000098],
                [-12.633370998999851, 12.43638],
                [-12.635610000999918, 12.436996],
                [-12.637546252999982, 12.436779695000098],
                [-12.639164999999878, 12.437425000000133],
                [-12.640754, 12.437655000000177],
                [-12.642297999999869, 12.437696000000187],
                [-12.64811, 12.437223000000131],
                [-12.654388, 12.435085000000129],
                [-12.659119058999977, 12.433115537000162],
                [-12.662528038999938, 12.43259811500019],
                [-12.665378742999906, 12.432621998000116],
                [-12.668533634999903, 12.43338056000016],
                [-12.672362327999963, 12.434692383000083],
                [-12.68307623499993, 12.438274206000074],
                [-12.684624, 12.43832500000019],
                [-12.686589, 12.438513000000114],
                [-12.687767999999835, 12.438324],
                [-12.690638861999957, 12.438077012000065],
                [-12.691668, 12.438204],
                [-12.694628, 12.438192000000186],
                [-12.696105999999872, 12.437455000000114],
                [-12.696999, 12.43739],
                [-12.699172043999965, 12.436984001000155],
                [-12.70366619799995, 12.43716698600008],
                [-12.705597999999895, 12.437030000000107],
                [-12.707716000999937, 12.436677001000135],
                [-12.710115, 12.436797001000116],
                [-12.712792, 12.436729],
                [-12.714542, 12.436319],
                [-12.716795999999874, 12.436248],
                [-12.719335, 12.436089],
                [-12.721783, 12.435838000000103],
                [-12.724689, 12.435881000000109],
                [-12.727949000999956, 12.43514000100015],
                [-12.729459000999952, 12.434954001000051],
                [-12.733585999999889, 12.435070000000167],
                [-12.736686, 12.434933000000171],
                [-12.73820600099998, 12.434603],
                [-12.744269, 12.434228000000132],
                [-12.748272, 12.43392800000015],
                [-12.750937, 12.434049],
                [-12.752309998999976, 12.433672000000115],
                [-12.754654, 12.433736],
                [-12.755981445999907, 12.433403968000164],
                [-12.757684707999886, 12.43312931000014],
                [-12.760335921999967, 12.43385028900002],
                [-12.763259886999947, 12.434901238],
                [-12.763676643999872, 12.436712266000143],
                [-12.762844084999927, 12.439452172000188],
                [-12.76166248199985, 12.441078185000151],
                [-12.760477065999908, 12.443074226000022],
                [-12.759050369999898, 12.44528579700011],
                [-12.757730482999932, 12.447091102],
                [-12.756684303999975, 12.448952674000054],
                [-12.755842208999923, 12.450635910000017],
                [-12.75603294299998, 12.452019693000068],
                [-12.75467204999984, 12.455163003000052],
                [-12.753765106999936, 12.456936836000182],
                [-12.753576277999969, 12.458543778000092],
                [-12.753009795999958, 12.460328102000119],
                [-12.752429961999951, 12.462473870000053],
                [-12.752300262999881, 12.463620186000185],
                [-12.751656532999903, 12.464708327999972],
                [-12.753753661999951, 12.466840745000127],
                [-12.755304336999927, 12.468217850000087],
                [-12.757288931999824, 12.469572068000048],
                [-12.75927543499995, 12.470591546000037],
                [-12.760508537999954, 12.471550941000089],
                [-12.76176357199995, 12.472094536000043],
                [-12.763098716999934, 12.473009109000145],
                [-12.764573097999971, 12.473734856000078],
                [-12.766779898999971, 12.473752976000128],
                [-12.768784522999908, 12.47270393500014],
                [-12.770919800999934, 12.470326423000074],
                [-12.772629737999921, 12.4714336400001],
                [-12.773119925999879, 12.472187996000173],
                [-12.776668547999975, 12.474539758000049],
                [-12.779110908999883, 12.475996971000086],
                [-12.781406401999845, 12.47771453900009],
                [-12.78352928199996, 12.478979111000115],
                [-12.785835266999982, 12.480245591000084],
                [-12.78807067899993, 12.48233318300015],
                [-12.790538787999878, 12.483049392000169],
                [-12.792634010999961, 12.482000350000078],
                [-12.795580863999874, 12.479502678000188],
                [-12.796625137999968, 12.478887559000157],
                [-12.797529219999888, 12.478589058000068],
                [-12.798799514999928, 12.478489876000026],
                [-12.800093649999894, 12.47769641900004],
                [-12.8022117619999, 12.477297783000097],
                [-12.806182860999854, 12.476399422999975],
                [-12.80897426599995, 12.475870133000058],
                [-12.810838698999873, 12.476202011],
                [-12.812300681999943, 12.476150514000096],
                [-12.81359195799996, 12.476974487000177],
                [-12.81550121299989, 12.477441789000068],
                [-12.817475319999915, 12.478876114],
                [-12.819142340999917, 12.479667664000146],
                [-12.82024860499996, 12.480435372000045],
                [-12.821557998999936, 12.482216835000145],
                [-12.823430060999954, 12.483840942000143],
                [-12.823768614999892, 12.48519039200005],
                [-12.823731422999913, 12.486292839000043],
                [-12.8258085249999, 12.487592697000139],
                [-12.827888487999871, 12.4884853370001],
                [-12.829224585999953, 12.489346504000025],
                [-12.830937385999903, 12.49013710100013],
                [-12.83312988299997, 12.49084186499999],
                [-12.837235450999913, 12.491552353000031],
                [-12.842250822999915, 12.49335575200007],
                [-12.843220710999958, 12.493987084000082],
                [-12.84389114299995, 12.49520397200007],
                [-12.843912123999814, 12.49603557600011],
                [-12.843543053999895, 12.497261048000098],
                [-12.842460632999973, 12.499077798000144],
                [-12.84022426599995, 12.501500129000078],
                [-12.839877128999888, 12.502274513000032],
                [-12.838560104999942, 12.503664970999978],
                [-12.836495398999887, 12.505311013000096],
                [-12.835767746999977, 12.507600785000022],
                [-12.835011481999913, 12.507847786000127],
                [-12.833865164999963, 12.508606912000062],
                [-12.83218765199996, 12.511178017000077],
                [-12.83280086499991, 12.512537956000074],
                [-12.834076880999874, 12.51400375500009],
                [-12.83416366699987, 12.515802384000153],
                [-12.836324690999959, 12.515855789000057],
                [-12.839150427999982, 12.51543617200008],
                [-12.840467451999928, 12.515374184000052],
                [-12.84195232399992, 12.515974045000064],
                [-12.843175888999895, 12.51595592600006],
                [-12.844466209999894, 12.516824722000024],
                [-12.844772338999917, 12.517568589000177],
                [-12.845426559999964, 12.517501831000175],
                [-12.84562778399993, 12.518705367999985],
                [-12.846886634999976, 12.518922806000035],
                [-12.84847450299992, 12.519333840000115],
                [-12.850065231999906, 12.519382476999965],
                [-12.853365898999982, 12.520673752000164],
                [-12.854794500999958, 12.521444321000047],
                [-12.856371879999926, 12.522089959000027],
                [-12.856975555999952, 12.522474289],
                [-12.86378955799995, 12.523865700000101],
                [-12.86541271099992, 12.524375916000054],
                [-12.866096495999955, 12.52522182500013],
                [-12.871088981999947, 12.527709962000074],
                [-12.873351097999944, 12.528931617000126],
                [-12.87562370199987, 12.52996158500008],
                [-12.87735939099997, 12.53148460400007],
                [-12.878022193999982, 12.534679414000095],
                [-12.879549980999911, 12.536932945000103],
                [-12.879787444999863, 12.538236618000099],
                [-12.88045978599996, 12.539308548000122],
                [-12.882389068999828, 12.540842057000077],
                [-12.882235526999978, 12.542819978000068],
                [-12.879401206999944, 12.544993400000124],
                [-12.878606796999975, 12.546496392000108],
                [-12.878190039999936, 12.54791164400018],
                [-12.87833595199993, 12.549205780000136],
                [-12.879579544999956, 12.550065040000163],
                [-12.886534690999895, 12.55141162800004],
                [-12.887493132999964, 12.552268983000033],
                [-12.889731407999818, 12.553117753000095],
                [-12.890715598999975, 12.55314350100008],
                [-12.892137527999864, 12.552540778000036],
                [-12.894532202999926, 12.551040650000061],
                [-12.896102904999964, 12.550212859000055],
                [-12.897293089999948, 12.54982471599999],
                [-12.899343490999854, 12.548783302000174],
                [-12.901678085999947, 12.54907226600011],
                [-12.90412425999989, 12.549145699000064],
                [-12.905622482999945, 12.54927539800002],
                [-12.907044409999912, 12.548671722000165],
                [-12.908945083999981, 12.54799080000015],
                [-12.910537720999969, 12.546692849000124],
                [-12.912725447999946, 12.545787812000128],
                [-12.913860320999959, 12.545408249000047],
                [-12.91588687899997, 12.545098305000181],
                [-12.918187141999965, 12.545070648000092],
                [-12.920542716999819, 12.544944763000103],
                [-12.922387122999908, 12.544498444],
                [-12.924777984999935, 12.544516565000038],
                [-12.925889967999922, 12.54362106300016],
                [-12.927664755999956, 12.542478562000042],
                [-12.928675650999935, 12.541446686000143],
                [-12.930590629999926, 12.5401601800001],
                [-12.932169913999871, 12.539377214000126],
                [-12.936096190999933, 12.538422583999989],
                [-12.938260077999928, 12.538212777000126],
                [-12.942407608999872, 12.53602123200011],
                [-12.94389057099994, 12.533529282000131],
                [-12.945657728999947, 12.531092643000079],
                [-12.946188925999934, 12.529026986000133],
                [-12.946259498999893, 12.528249740000149],
                [-12.94582939199995, 12.526809693000075],
                [-12.942060469999944, 12.524214746000041],
                [-12.939994810999906, 12.52245426100012],
                [-12.939970016999894, 12.518630982000047],
                [-12.939708709999934, 12.517978669],
                [-12.939964294999982, 12.517058373000054],
                [-12.940896033999877, 12.514363289000187],
                [-12.940911293999875, 12.513676644000157],
                [-12.940308570999946, 12.511909484000114],
                [-12.937940596999965, 12.507742882000173],
                [-12.938687324999933, 12.506474495000077],
                [-12.939206123999952, 12.504779815000063],
                [-12.939075469999977, 12.502663613000095],
                [-12.939124107999817, 12.501280784000187],
                [-12.940078734999929, 12.499137878000113],
                [-12.94067001299993, 12.496483804000093],
                [-12.940298079999934, 12.494718551000119],
                [-12.94132137299988, 12.492123605000188],
                [-12.941762923999931, 12.489741326000171],
                [-12.942019462999838, 12.487492562000114],
                [-12.941967010999917, 12.486055375000092],
                [-12.942028998999888, 12.484030723000103],
                [-12.94234085099987, 12.48293972000016],
                [-12.942551612999978, 12.480727196000032],
                [-12.94293498899998, 12.47871494399999],
                [-12.943133352999894, 12.476872445000083],
                [-12.944613456999946, 12.475898742000027],
                [-12.945596695999939, 12.475978851000036],
                [-12.947322844999974, 12.476299286000142],
                [-12.949677466999901, 12.47636222900013],
                [-12.951223371999845, 12.47622013199998],
                [-12.954040526999961, 12.474786760000086],
                [-12.956899642999929, 12.474997520000102],
                [-12.958352088999902, 12.475035667000157],
                [-12.961783408999963, 12.474908828000025],
                [-12.96359443699987, 12.473892213000056],
                [-12.96638107299998, 12.47037887600004],
                [-12.966647147999879, 12.469278336000116],
                [-12.967577933999962, 12.467875481000135],
                [-12.969353676999901, 12.466677665000134],
                [-12.970050811999954, 12.466836930000056],
                [-12.974657057999934, 12.46820926700019],
                [-12.977057457999877, 12.468181610000158],
                [-12.979664801999945, 12.468860627000083],
                [-12.982820509999954, 12.469914436000181],
                [-12.98520755699991, 12.470438958000045],
                [-12.988363264999975, 12.471447946000012],
                [-12.991086004999943, 12.471657753000045],
                [-12.992055892999872, 12.472243308000088],
                [-12.992449760999875, 12.474876404000042],
                [-12.993304, 12.476103],
                [-12.99484399999983, 12.47794],
                [-12.996396999999888, 12.479416000000128],
                [-12.998982999999896, 12.48033],
                [-13.00146599899989, 12.480465001000141],
                [-13.005494, 12.480586],
                [-13.008164461999911, 12.480596579000178],
                [-13.011357307999958, 12.480050087000052],
                [-13.0141146979999, 12.48042291500019],
                [-13.01538276599996, 12.480360031000146],
                [-13.016684531999942, 12.480975152000156],
                [-13.017745971999886, 12.481733323000071],
                [-13.02003, 12.482464],
                [-13.021156, 12.481976],
                [-13.023284911999895, 12.481286049],
                [-13.024362884999903, 12.481392988999971],
                [-13.025378999999873, 12.481961000000183],
                [-13.02844, 12.483357],
                [-13.029664005999962, 12.483419474000073],
                [-13.031514774999948, 12.482303490000106],
                [-13.032268, 12.482002],
                [-13.032753, 12.481084],
                [-13.033993, 12.48005400000011],
                [-13.034648895999851, 12.479697227000031],
                [-13.03695, 12.479506],
                [-13.039309998999954, 12.48115900099998],
                [-13.040464335999957, 12.481827041000031],
                [-13.043601021999962, 12.482202249000181],
                [-13.047412871999882, 12.48126411600009],
                [-13.048729895999884, 12.481101036000098],
                [-13.049988999999869, 12.481174],
                [-13.051665, 12.482125001000156],
                [-13.052967998999975, 12.482559],
                [-13.054499, 12.483275],
                [-13.05642, 12.483361001000105],
                [-13.0586682469999, 12.483633457999986],
                [-13.059681891999958, 12.484107972000174],
                [-13.062191961999872, 12.485482216000037],
                [-13.062829016999899, 12.486327171000028],
                [-13.063951, 12.48883],
                [-13.065099730999975, 12.490229672000055],
                [-13.065437, 12.49189600000011],
                [-13.065632820999895, 12.494011878000038],
                [-13.065811999999823, 12.497142],
                [-13.064072999999894, 12.502127],
                [-13.063765998999827, 12.503779000000122],
                [-13.063422, 12.506804000000102],
                [-13.063392638999915, 12.508187295000084],
                [-13.063120842999979, 12.510109902000067],
                [-13.062924, 12.51310100000012],
                [-13.062954902999934, 12.51640892000006],
                [-13.06339359299983, 12.517939567000155],
                [-13.064192999999875, 12.523106000000155],
                [-13.065011978999962, 12.526754380000057],
                [-13.065073999999868, 12.52827400000018],
                [-13.066208, 12.530451],
                [-13.066981999999882, 12.531432000000166],
                [-13.06740570099987, 12.533830644000034],
                [-13.066767691999928, 12.535571098000162],
                [-13.065708, 12.537027998999974],
                [-13.063636779999968, 12.539607047000061],
                [-13.061693999999875, 12.54104800000016],
                [-13.058756, 12.542698999000095],
                [-13.055014, 12.544742],
                [-13.053337, 12.54618500000015],
                [-13.052896, 12.547374999000112],
                [-13.05256462099993, 12.549848557000132],
                [-13.052545547999955, 12.551186562000055],
                [-13.052368109999918, 12.55270305700003],
                [-13.052349018999848, 12.554077385000085],
                [-13.052485998999884, 12.556563],
                [-13.052393912999833, 12.559093475000168],
                [-13.052730560999976, 12.56080436800005],
                [-13.052607535999982, 12.56254768500014],
                [-13.051457, 12.563768],
                [-13.049365, 12.565479],
                [-13.047355, 12.567282],
                [-13.046490999999833, 12.568378000000166],
                [-13.045628, 12.569277000000113],
                [-13.044522, 12.570686998999975],
                [-13.043611, 12.572001],
                [-13.042535998999881, 12.574189001000036],
                [-13.041932137999936, 12.576339944000097],
                [-13.041612358999942, 12.578364733],
                [-13.041641220999907, 12.579379902000085],
                [-13.041899680999904, 12.580438613000183],
                [-13.041766, 12.582273],
                [-13.042023658999938, 12.583566667000184],
                [-13.041867036999975, 12.584767713000076],
                [-13.042206226999951, 12.586244483000087],
                [-13.043519973999935, 12.588847160000057],
                [-13.044596672999944, 12.591304779999973],
                [-13.044290542999931, 12.59281158500005],
                [-13.044218, 12.593868000000157],
                [-13.043593, 12.59496700000011],
                [-13.043143106999935, 12.598546203000069],
                [-13.04319999899991, 12.600758001000088],
                [-13.04359299899994, 12.602188],
                [-13.044940948999908, 12.602903366000078],
                [-13.045808791999946, 12.603750228000138],
                [-13.04659175899991, 12.604866983000079],
                [-13.047168730999942, 12.606398582000054],
                [-13.047742842999924, 12.608391763000043],
                [-13.048106193999956, 12.609035493000022],
                [-13.049397174999967, 12.609840211000176],
                [-13.050135611999849, 12.610732078000183],
                [-13.050303, 12.611746],
                [-13.049852, 12.61293499900006],
                [-13.048896998999965, 12.61397600000015],
                [-13.047651, 12.61561200000017],
                [-13.047276, 12.61768],
                [-13.047459601999947, 12.620256424000047],
                [-13.047318, 12.623112],
                [-13.048263, 12.624871999000106],
                [-13.049425, 12.625586000000112],
                [-13.050591, 12.625974],
                [-13.052182, 12.626094],
                [-13.053500174999954, 12.625940323],
                [-13.054527, 12.62632800100016],
                [-13.055121032999978, 12.62702802500013],
                [-13.061638, 12.631884],
                [-13.062423705999947, 12.63263988400007],
                [-13.062634, 12.634024000000181],
                [-13.062135696999917, 12.635394096000027],
                [-13.060697554999933, 12.637028695000026],
                [-13.05901351599988, 12.639572712000074],
                [-13.058606999999824, 12.640811],
                [-13.058545, 12.641678000000184],
                [-13.059229, 12.642569],
                [-13.060589, 12.642868],
                [-13.065425769999933, 12.642586325000138],
                [-13.06664943599992, 12.642288209000128],
                [-13.069311, 12.643076],
                [-13.071542, 12.643987],
                [-13.073194999999885, 12.643239],
                [-13.07483, 12.641245],
                [-13.07542, 12.639785],
                [-13.075873, 12.638313000000153],
                [-13.075768153999888, 12.636426793000112],
                [-13.075967, 12.635648001000163],
                [-13.076633, 12.635201],
                [-13.07852199999985, 12.63490800000011],
                [-13.080209, 12.634531],
                [-13.081676, 12.633999],
                [-13.082917, 12.633014000000117],
                [-13.084810152999921, 12.632123987000057],
                [-13.086504998999828, 12.63197300000013],
                [-13.087341, 12.632078],
                [-13.088488, 12.631228000000135],
                [-13.089035, 12.629668],
                [-13.089114188999872, 12.627743722000105],
                [-13.089655875999938, 12.626599311],
                [-13.091493606999961, 12.625754357000119],
                [-13.092731, 12.627625001000183],
                [-13.093083131999833, 12.628594037000028],
                [-13.094158050999965, 12.62889098700009],
                [-13.095292, 12.62859200000014],
                [-13.097714, 12.627163],
                [-13.099309999999889, 12.626732],
                [-13.100656509999908, 12.627491950999968],
                [-13.100868, 12.628831],
                [-13.103091999999833, 12.633014000000117],
                [-13.104655, 12.634417],
                [-13.106473979999976, 12.635468951000064],
                [-13.108151128999907, 12.635868992000042],
                [-13.1144961359999, 12.635469312000055],
                [-13.11585, 12.63521900000012],
                [-13.11658954599983, 12.636019707000173],
                [-13.11662, 12.63689600000015],
                [-13.115438, 12.640098],
                [-13.115743, 12.641068000000189],
                [-13.117247047999967, 12.641553148000185],
                [-13.119936942999857, 12.640265464000038],
                [-13.12324142499989, 12.638949395000111],
                [-13.126647949999949, 12.637672425000176],
                [-13.128135681999936, 12.638161660000037],
                [-13.129105567999943, 12.638963700000147],
                [-13.130572318999896, 12.641062736000094],
                [-13.131301879999967, 12.64190006299998],
                [-13.131937979999918, 12.64301586300013],
                [-13.132907999999816, 12.643908000000124],
                [-13.1346, 12.644038],
                [-13.136008999999888, 12.643875999000045],
                [-13.136630458999946, 12.643336574000102],
                [-13.137072562999947, 12.641958236000107],
                [-13.138057708999952, 12.639442443000064],
                [-13.13762950899985, 12.637550354000098],
                [-13.136945724999919, 12.636433602000068],
                [-13.136872291999907, 12.635375977000024],
                [-13.137630255999909, 12.634784861000128],
                [-13.13884258399986, 12.635226250000187],
                [-13.139628410999933, 12.636063577000073],
                [-13.140676497999891, 12.637417793000054],
                [-13.141413999999884, 12.638544001000128],
                [-13.142372131999934, 12.639661788000183],
                [-13.143629000999852, 12.64033],
                [-13.146855353999968, 12.640895844000113],
                [-13.148641585999883, 12.640745164000123],
                [-13.150275229999977, 12.64141750300007],
                [-13.151671409999949, 12.641806603000077],
                [-13.152458, 12.642472000000168],
                [-13.15386599999988, 12.642634999000052],
                [-13.155031999999892, 12.642888001000017],
                [-13.156869888999836, 12.642131806000066],
                [-13.157398, 12.641547999000181],
                [-13.158078999999873, 12.640359000000103],
                [-13.158185946999936, 12.639483430000155],
                [-13.157288550999908, 12.63757801100013],
                [-13.157443045999912, 12.636666298000137],
                [-13.158017092999899, 12.636118950000139],
                [-13.159745215999976, 12.6366100300001],
                [-13.16118335799996, 12.63737964600017],
                [-13.162363999999855, 12.63726],
                [-13.164015770999981, 12.63659477200008],
                [-13.167761, 12.639286999000149],
                [-13.168892859999971, 12.639357567000161],
                [-13.170316695999873, 12.638463020000131],
                [-13.171378135999873, 12.636780738000027],
                [-13.173121452999965, 12.63362884500009],
                [-13.174458502999926, 12.631925583],
                [-13.175312995999946, 12.629667282000128],
                [-13.17616271999998, 12.629130364000105],
                [-13.1769638049999, 12.629144668000038],
                [-13.177660942999978, 12.629474641000058],
                [-13.178104400999871, 12.63058948600002],
                [-13.178587913999934, 12.632482529000072],
                [-13.179041862999895, 12.633280754000111],
                [-13.182481765999967, 12.634994507000101],
                [-13.184375762999878, 12.636598587000094],
                [-13.186417, 12.638194],
                [-13.188872, 12.639710999000044],
                [-13.191341398999896, 12.64076709800014],
                [-13.193157195999959, 12.641674042000034],
                [-13.194220543999904, 12.642341614000031],
                [-13.196176, 12.643115],
                [-13.198521650999965, 12.643383960000108],
                [-13.19974994599994, 12.642713546000095],
                [-13.200643539999874, 12.642683030000057],
                [-13.201534271999947, 12.642935754000177],
                [-13.201943397999969, 12.643632889],
                [-13.201478003999966, 12.64569091800007],
                [-13.200692176999894, 12.647565841000187],
                [-13.200705, 12.649645],
                [-13.201272, 12.651100000000156],
                [-13.201920000999962, 12.652383001000146],
                [-13.202752113999964, 12.65308380200014],
                [-13.205462455999964, 12.653825760000075],
                [-13.20691799899987, 12.653707999000176],
                [-13.208063998999933, 12.65294799899999],
                [-13.210068701999887, 12.650638580000134],
                [-13.210930823999888, 12.649731634999966],
                [-13.211868998999876, 12.649837000000105],
                [-13.212702, 12.650503000000128],
                [-13.214003999999875, 12.651307],
                [-13.215593999999896, 12.651470999000026],
                [-13.216396000999907, 12.651349999000104],
                [-13.216924667999876, 12.650892257000066],
                [-13.217700369999932, 12.64948171400016],
                [-13.218230998999957, 12.648298000000125],
                [-13.218937873999892, 12.645961760999967],
                [-13.219758032999835, 12.644502640000155],
                [-13.221951485999966, 12.642699243000038],
                [-13.223787306999952, 12.642087937000099],
                [-13.224980277999975, 12.64132790799999],
                [-13.226529120999885, 12.640985490000105],
                [-13.2279214859999, 12.641880990000118],
                [-13.228936, 12.642963000000123],
                [-13.231663703999914, 12.645215036000081],
                [-13.23266504899982, 12.646758061000128],
                [-13.233634947999974, 12.647830963000104],
                [-13.234314917999882, 12.649363517999973],
                [-13.2353754049999, 12.650346756000147],
                [-13.236646712999914, 12.650238023000156],
                [-13.238241195999933, 12.649895668000113],
                [-13.239342000999898, 12.649178999000071],
                [-13.240147590999868, 12.648407936000126],
                [-13.242041586999903, 12.647380830000088],
                [-13.243084907999958, 12.646945],
                [-13.244277953999926, 12.646129609000184],
                [-13.245626365999954, 12.64675327100008],
                [-13.247750998999948, 12.648358],
                [-13.249839000999884, 12.650052999000081],
                [-13.250857922999899, 12.651128611000104],
                [-13.252202987999908, 12.651569366000047],
                [-13.25332164799994, 12.65209198100007],
                [-13.254535674999829, 12.652208329000075],
                [-13.25645732799984, 12.652654647000077],
                [-13.257764815999906, 12.652590752000037],
                [-13.259366035999903, 12.65270996200013],
                [-13.260822728999813, 12.652502782],
                [-13.262850761999914, 12.651892661999966],
                [-13.264307022999958, 12.651739121000162],
                [-13.266715050999892, 12.650995254000179],
                [-13.267729758999849, 12.651978493000058],
                [-13.270092010999974, 12.653909683000165],
                [-13.271441459999892, 12.654442788000154],
                [-13.274346350999906, 12.654859543000043],
                [-13.275744438999936, 12.655202866000138],
                [-13.27841091199997, 12.655572891000133],
                [-13.280650998999931, 12.656445999000084],
                [-13.282541, 12.656015],
                [-13.283437999999876, 12.655478000000187],
                [-13.285574911999959, 12.653946877000067],
                [-13.2887392049999, 12.652900696000074],
                [-13.290036, 12.651589000000115],
                [-13.2901429879999, 12.650668095000185],
                [-13.289188998999919, 12.648808],
                [-13.287779807999925, 12.646340371000065],
                [-13.288295744999914, 12.645969391000108],
                [-13.29158592199991, 12.645516395000072],
                [-13.296569824999892, 12.64539051100013],
                [-13.299199102999921, 12.645637513000167],
                [-13.301137923999931, 12.646309852000115],
                [-13.302393912999946, 12.64647579200016],
                [-13.303376198999956, 12.645979882000177],
                [-13.305093764999981, 12.644742011000119],
                [-13.306381224999825, 12.644448280000063],
                [-13.30789852099997, 12.644903184000043],
                [-13.309204101999967, 12.646331788000111],
                [-13.309991835999938, 12.64659500100015],
                [-13.311060905999966, 12.646243096000092],
                [-13.313191413999903, 12.644420624000077],
                [-13.3150548939999, 12.643269540000063],
                [-13.316152572999954, 12.643047332000151],
                [-13.318453787999943, 12.641984939999986],
                [-13.320089339999925, 12.642052650000096],
                [-13.32294654799989, 12.643133163000073],
                [-13.325525283999923, 12.644340515000124],
                [-13.328639984999882, 12.643958092000048],
                [-13.329210281999906, 12.64399051600003],
                [-13.331652640999948, 12.64442920700003],
                [-13.332081793999976, 12.64504146500002],
                [-13.332262039999875, 12.648773193000068],
                [-13.330553054999882, 12.650958062000086],
                [-13.329019545999927, 12.653058053000052],
                [-13.328951834999941, 12.654550553000092],
                [-13.330063819999907, 12.656795501000033],
                [-13.331726074999949, 12.658585548000076],
                [-13.332447050999917, 12.658590318000108],
                [-13.333754539999916, 12.658311843999968],
                [-13.335200309999834, 12.6577463160001],
                [-13.336199759999943, 12.656796456],
                [-13.336871147999943, 12.655403138],
                [-13.33731174399992, 12.653607369000042],
                [-13.337786673999972, 12.652539254000033],
                [-13.338511467999979, 12.651816368000084],
                [-13.33981800099997, 12.651652337000087],
                [-13.34076786099996, 12.652519226000152],
                [-13.342450141999961, 12.654539108000108],
                [-13.344265937999921, 12.656903266000143],
                [-13.345026015999963, 12.658164978000116],
                [-13.345611572999928, 12.660493852000059],
                [-13.345945358999813, 12.663203239000154],
                [-13.345751761999963, 12.667459488000134],
                [-13.34558868399995, 12.670127868000122],
                [-13.345195770999965, 12.673598291000133],
                [-13.345216751999942, 12.677979469000036],
                [-13.344826698999896, 12.679536819000134],
                [-13.344094276999954, 12.681254387000138],
                [-13.343748092999931, 12.683538437000095],
                [-13.3444566739999, 12.685503959000016],
                [-13.345763206999948, 12.686918260000084],
                [-13.346057890999873, 12.688030242000082],
                [-13.345865249999918, 12.68925285400013],
                [-13.346014021999963, 12.690230370000052],
                [-13.348968504999903, 12.691501617000142],
                [-13.349904058999982, 12.691516876000094],
                [-13.352623938999955, 12.691256523000106],
                [-13.356046676999824, 12.683372499000086],
                [-13.357029914999885, 12.679663658000038],
                [-13.357473373999937, 12.676991463000036],
                [-13.357728957999939, 12.672924997],
                [-13.357766151999897, 12.670202255000163],
                [-13.357949255999927, 12.668866157000082],
                [-13.358877181999901, 12.66491127100005],
                [-13.36028194499994, 12.659690857000157],
                [-13.388834000999964, 12.662772179000171],
                [-13.430299758999922, 12.667242051000187],
                [-13.471767424999939, 12.671705247000148],
                [-13.498439788999974, 12.674966813000026],
                [-13.500547407999932, 12.67513275300007],
                [-13.520417213999849, 12.675008774000048],
                [-13.558756827999957, 12.67495727500011],
                [-13.561195372999919, 12.675087927999982],
                [-13.583036422999953, 12.675124167999968],
                [-13.611220358999901, 12.674956322000128],
                [-13.638714789999938, 12.674791337000045],
                [-13.681512832999886, 12.674698830000125],
                [-13.687766075999832, 12.674657823000018],
                [-13.69627475699997, 12.674518586000147],
                [-13.707084655999893, 12.67440891400014],
                [-13.708568572999923, 12.674434662000124],
                [-13.703689574999828, 12.655557633000114],
                [-13.702979088999939, 12.628430367000021],
                [-13.69971084599996, 12.603610038999989],
                [-13.696150778999936, 12.598810196000159],
                [-13.692528723999885, 12.586878777000152],
                [-13.69200992499998, 12.580451966000169],
                [-13.683658598999955, 12.553530692000038],
                [-13.683319092999966, 12.552458764000164],
                [-13.679629324999837, 12.532011031000025],
                [-13.677489279999975, 12.527890206000052],
                [-13.663329123999915, 12.515819550000174],
                [-13.656231880999883, 12.50712967000004],
                [-13.651871681999978, 12.49693966000018],
                [-13.638769149999973, 12.466328621],
                [-13.636520384999926, 12.449790956000129],
                [-13.639700888999812, 12.438261033000117],
                [-13.663048744999912, 12.405409814000109],
                [-13.66296863599996, 12.39552021000003],
                [-13.660500525999907, 12.381279946],
                [-13.656190871999968, 12.369812011000022],
                [-13.655170440999882, 12.36061954500002],
                [-13.66159916, 12.346988679000049],
                [-13.661238669999875, 12.33251094800005],
                [-13.659790039999962, 12.327920913000128],
                [-13.663189886999874, 12.315239906000102],
                [-13.666210173999843, 12.311989784000104],
                [-13.684650419999969, 12.307009698000172],
                [-13.689988134999908, 12.302591323000058],
                [-13.692038535999927, 12.295910835000143],
                [-13.69439983399991, 12.291279793000115],
                [-13.699728965999952, 12.290292740000098],
                [-13.70459079699998, 12.283808708000038],
                [-13.709730147999949, 12.270450592000032],
                [-13.713939666999977, 12.253609658000187],
                [-13.71948909799994, 12.246891021000181],
                [-13.725099562999901, 12.245700837000129],
                [-13.732081412999833, 12.249409676000141],
                [-13.744421005999925, 12.263719559000094],
                [-13.75572967599993, 12.27398109500001],
                [-13.76299953399996, 12.275309563000178],
                [-13.767410277999943, 12.27136039700008],
                [-13.773090361999891, 12.251990319000015],
                [-13.778448104999939, 12.249191284000176],
                [-13.792011260999914, 12.248850823000168],
                [-13.80442142499993, 12.250590324000086],
                [-13.808659553999917, 12.253548623000029],
                [-13.798298835999958, 12.27295971000018],
                [-13.796979903999897, 12.283551216000035],
                [-13.797730445999889, 12.28905963900013],
                [-13.80152034699995, 12.29454994200006],
                [-13.804551123999886, 12.293378829000062],
                [-13.809650419999855, 12.287819863000038],
                [-13.823919295999815, 12.260560989000055],
                [-13.829140662999976, 12.256369590000077],
                [-13.834199904999934, 12.259781837000048],
                [-13.835989951999977, 12.277711868000097],
                [-13.839988708999954, 12.280440330000033],
                [-13.846778869999923, 12.28129863800001],
                [-13.858460426999955, 12.278910636000148],
                [-13.865010261999942, 12.279541969999968],
                [-13.866198539999914, 12.273051262000081],
                [-13.864930152999932, 12.270568848000039],
                [-13.859510420999982, 12.265791894000074],
                [-13.855460166999933, 12.256621362000146],
                [-13.855179785999951, 12.252021789000139],
                [-13.857460974999924, 12.243889810000098],
                [-13.871899604999896, 12.237850190000074],
                [-13.89942932199989, 12.23686981100002],
                [-13.922401427999944, 12.23604011500015],
                [-13.929830550999895, 12.230001448999985],
                [-13.9331989289999, 12.215559960000178],
                [-13.940830229999904, 12.205060006000053],
                [-13.948980330999973, 12.201540948000172],
                [-13.95691967099998, 12.200551987000154],
                [-13.96502113299988, 12.191510201000085],
                [-13.964659690999895, 12.17805862500012],
                [-13.962761879999903, 12.17381954200016],
                [-13.956049919999941, 12.155700683000134],
                [-13.948229788999924, 12.144270897000183],
                [-13.941760062999947, 12.135919571000159],
                [-13.931908606999968, 12.132788659000084],
                [-13.904299734999938, 12.13119983700011],
                [-13.892820356999948, 12.128540040000019],
                [-13.887169837999863, 12.124448776000065],
                [-13.878339765999954, 12.106360436000045],
                [-13.87127113299988, 12.10021114500006],
                [-13.8473186499999, 12.090531349000116],
                [-13.843820570999924, 12.091018677000136],
                [-13.835378647999903, 12.088801385000181],
                [-13.82199954899994, 12.08269977700013],
                [-13.817761419999954, 12.07929039000004],
                [-13.814180374999921, 12.071502685000098],
                [-13.807668686999932, 12.050621034000073],
                [-13.797740936999958, 12.038290978000077],
                [-13.791140554999913, 12.03236961400006],
                [-13.776101112999982, 12.024668693000137],
                [-13.71378040299993, 12.008872032000056],
                [-13.705558776999851, 12.004341125000053],
                [-13.705261230999838, 11.996978760000104],
                [-13.712908743999947, 11.963560104000123],
                [-13.714868545999934, 11.92214202800011],
                [-13.719780920999938, 11.896100045000026],
                [-13.720338821999917, 11.881369590000077],
                [-13.71413993899995, 11.840709687000185],
                [-13.71154880499995, 11.813361167999972],
                [-13.713460922999957, 11.791489601000023],
                [-13.717349051999975, 11.782019615000024],
                [-13.720008849999942, 11.766361236000023],
                [-13.715209006999942, 11.752370835000022],
                [-13.713431358999912, 11.709819794000111],
                [-13.719710349999957, 11.707242013000098],
                [-13.729531287999976, 11.709229469000036],
                [-13.748311042999944, 11.69503021300011],
                [-13.759779929999922, 11.691240311000058],
                [-13.782380104999902, 11.686458587000175],
                [-13.78477954899995, 11.693569182999966],
                [-13.791509627999972, 11.689611435000074],
                [-13.7964401239999, 11.693249703000049],
                [-13.802048683999885, 11.694359780000127],
                [-13.804409981999868, 11.696409226000071],
                [-13.80560874999992, 11.701231957],
                [-13.803790092999918, 11.706070901000032],
                [-13.806851385999892, 11.709039688000075],
                [-13.800599097999964, 11.716221809000047],
                [-13.80133056699998, 11.719209671000158],
                [-13.805528641999956, 11.719629287999965],
                [-13.811790464999888, 11.713601113000095],
                [-13.819729804999895, 11.713999748000106],
                [-13.826679229999911, 11.708650589000115],
                [-13.829719542999953, 11.70862960800008],
                [-13.83658885999995, 11.720528603000048],
                [-13.840788841999938, 11.72119045200003],
                [-13.8424386989999, 11.72301101700009],
                [-13.841790198999888, 11.728079797000078],
                [-13.836688994999975, 11.732489585999986],
                [-13.833459852999965, 11.737580300000047],
                [-13.833950042999845, 11.739878655000041],
                [-13.840040206999959, 11.742131233000066],
                [-13.845471380999925, 11.7496795670001],
                [-13.8473186499999, 11.747591972000123],
                [-13.849889755999925, 11.74734115700005],
                [-13.85270881699995, 11.749850273000163],
                [-13.859217642999965, 11.746109962000048],
                [-13.863241195999933, 11.751831055000082],
                [-13.867839813999922, 11.744430543000078],
                [-13.87080955599987, 11.737270355000078],
                [-13.868398665999962, 11.727861404000066],
                [-13.865340231999937, 11.725360870000088],
                [-13.85747909599985, 11.733699798000146],
                [-13.854881286999955, 11.730039597000086],
                [-13.854128836999848, 11.724761964000095],
                [-13.857339859999968, 11.718058587000144],
                [-13.860118864999947, 11.715511323000044],
                [-13.866901397999982, 11.690381050999974],
                [-13.872288702999981, 11.692170144000158],
                [-13.879780768999979, 11.668640137000068],
                [-13.897238731999948, 11.667961120000086],
                [-13.905908584999906, 11.671330453000053],
                [-13.92049121899987, 11.682470322000142],
                [-13.931959150999887, 11.684900284000094],
                [-13.935891150999907, 11.681871414000113],
                [-13.942250250999905, 11.663410187000068],
                [-13.950599669999974, 11.658271791000175],
                [-13.977120399999933, 11.648361207000107],
                [-13.99156093699986, 11.645009995000123],
                [-13.999959944999887, 11.644932746000052],
                [-14.00596141899996, 11.648020745],
                [-14.016151428999933, 11.653289795000035],
                [-14.0283298499999, 11.656849862],
                [-14.037420272999896, 11.656070710000051],
                [-14.062080383999955, 11.648020745],
                [-14.075519560999908, 11.63798999800008],
                [-14.08897972199992, 11.63050079400017],
                [-14.095049857999982, 11.630900383000096],
                [-14.108880043999875, 11.63628959600004],
                [-14.120079, 11.647191],
                [-14.13764892799992, 11.664300928000102],
                [-14.154230116999884, 11.664819718000047],
                [-14.17683029099993, 11.660229683000125],
                [-14.206608770999935, 11.67235088300015],
                [-14.21665954699995, 11.67385959600017],
                [-14.227870998999947, 11.673740999000017],
                [-14.24833106899996, 11.666629792000094],
                [-14.258660315999919, 11.671811103000039],
                [-14.263398169999903, 11.677749634000179],
                [-14.268070220999846, 11.678389549000087],
                [-14.296078680999926, 11.63554000800002],
                [-14.31951999699993, 11.60308075],
                [-14.320310000999939, 11.60268],
                [-14.353237151999963, 11.585542678000024],
                [-14.381741049999903, 11.570705855000142],
                [-14.414073, 11.553877000000114],
                [-14.433759, 11.543630000000121],
                [-14.477031, 11.521089],
                [-14.478159, 11.51762900000017],
                [-14.480469703999916, 11.515991211000085],
                [-14.491400718999898, 11.512880326000186],
                [-14.516200065999953, 11.49880123100013],
                [-14.526460646999908, 11.498220444000083],
                [-14.548889159999874, 11.500281335000182],
                [-14.57106995199996, 11.502308996000181],
                [-14.573888998999905, 11.504121000000168],
                [-14.611051, 11.50898],
                [-14.635791, 11.509842000000106],
                [-14.641599999999869, 11.508401000000106],
                [-14.658869, 11.508422],
                [-14.665384030999917, 11.506735114000094],
                [-14.692170000999965, 11.485018998999976],
                [-14.70344, 11.472010000000182],
                [-14.721312030999968, 11.44724335],
                [-14.741273999999862, 11.419579],
                [-14.753648999999882, 11.40243],
                [-14.768069, 11.382431],
                [-14.780004672999894, 11.356216299999971],
                [-14.791661, 11.33061000000015],
                [-14.807990999999845, 11.288460000000157],
                [-14.815927, 11.267989000000114],
                [-14.82301, 11.249710000000107],
                [-14.862099999999884, 11.17399],
                [-14.864599999999882, 11.16914],
                [-14.913196, 11.0790760000001],
                [-14.91264, 11.07763800000015],
                [-14.91264, 11.072918],
                [-14.912914999999884, 11.072918],
                [-14.912918, 11.071805000000154],
                [-14.913471, 11.071249],
                [-14.913748999999882, 11.070417],
                [-14.913748999999882, 11.068751],
                [-14.914306, 11.068471000000159],
                [-14.914306, 11.066249],
                [-14.914584, 11.066249],
                [-14.914584, 11.065139000000158],
                [-14.914862, 11.065139000000158],
                [-14.914862, 11.063751],
                [-14.91514, 11.063751],
                [-14.91514, 11.062639000000104],
                [-14.915694, 11.06236100000018],
                [-14.915418, 11.061529],
                [-14.915418, 11.060416],
                [-14.915972, 11.059304],
                [-14.916528, 11.059028],
                [-14.916528, 11.058194000000185],
                [-14.917084, 11.057916],
                [-14.917084, 11.05736],
                [-14.917638, 11.057082],
                [-14.917638, 11.056528000000185],
                [-14.918472, 11.055972],
                [-14.918472, 11.055138000000113],
                [-14.919028, 11.054584],
                [-14.919028, 11.053472000000113],
                [-14.919306, 11.053472000000113],
                [-14.919306, 11.051806000000113],
                [-14.91875, 11.05152800000019],
                [-14.918472, 11.050418],
                [-14.917916, 11.050138000000118],
                [-14.917916, 11.049584],
                [-14.917362, 11.049584],
                [-14.917362, 11.049028000000135],
                [-14.916528, 11.04875],
                [-14.91625, 11.04764],
                [-14.915694, 11.047362000000135],
                [-14.915418, 11.046249],
                [-14.914862, 11.046249],
                [-14.914028, 11.0454170000001],
                [-14.914028, 11.044583],
                [-14.913471, 11.044583],
                [-14.913196, 11.043473],
                [-14.91264, 11.043473],
                [-14.91264, 11.042917],
                [-14.912082999999882, 11.042639000000122],
                [-14.912082999999882, 11.042083000000105],
                [-14.911248999999884, 11.041805],
                [-14.910416999999882, 11.040139],
                [-14.909305, 11.039029],
                [-14.909027, 11.038473],
                [-14.908471, 11.038195000000144],
                [-14.908471, 11.037639000000127],
                [-14.907916999999884, 11.037361],
                [-14.907639, 11.036251],
                [-14.906526999999869, 11.035973000000126],
                [-14.906526999999869, 11.035417000000166],
                [-14.905695, 11.035139],
                [-14.905695, 11.03458200000017],
                [-14.904860999999869, 11.034307000000126],
                [-14.904860999999869, 11.033751000000166],
                [-14.903473, 11.032360000000153],
                [-14.902916999999889, 11.032360000000153],
                [-14.902916999999889, 11.031806000000131],
                [-14.901250999999888, 11.030694000000153],
                [-14.901250999999888, 11.030138],
                [-14.89986, 11.030138],
                [-14.899584999999888, 11.030416],
                [-14.899581999999896, 11.031528],
                [-14.899028999999814, 11.031806000000131],
                [-14.899028999999814, 11.03291600000017],
                [-14.898749999999893, 11.033473],
                [-14.898194, 11.033751000000166],
                [-14.897638, 11.034861000000149],
                [-14.897638, 11.035417000000166],
                [-14.897081999999898, 11.035695],
                [-14.897081999999898, 11.036527000000149],
                [-14.896528, 11.036527000000149],
                [-14.896249999999895, 11.037083000000166],
                [-14.895415999999898, 11.037083000000166],
                [-14.895138, 11.037917],
                [-14.894583999999895, 11.037917],
                [-14.894583999999895, 11.038473],
                [-14.894028, 11.038473],
                [-14.894028, 11.039029],
                [-14.893194, 11.039305000000127],
                [-14.892916, 11.039861000000144],
                [-14.89125, 11.039861000000144],
                [-14.89125, 11.040139],
                [-14.890138, 11.040695],
                [-14.889028, 11.040695],
                [-14.888194, 11.040973000000122],
                [-14.888194, 11.041251],
                [-14.887083999999845, 11.041251],
                [-14.887083999999845, 11.041527000000144],
                [-14.885971, 11.041527000000144],
                [-14.885971, 11.041805],
                [-14.884861, 11.041805],
                [-14.884861, 11.042083000000105],
                [-14.884027, 11.042361],
                [-14.882917, 11.042361],
                [-14.882917, 11.042639000000122],
                [-14.881805, 11.042639000000122],
                [-14.881805, 11.042917],
                [-14.880695, 11.042917],
                [-14.880695, 11.04319500000014],
                [-14.879305, 11.04319500000014],
                [-14.878195, 11.043749000000105],
                [-14.877083, 11.04486100000014],
                [-14.876529, 11.04486100000014],
                [-14.875695, 11.045693],
                [-14.875695, 11.045971000000122],
                [-14.874861, 11.046805],
                [-14.874307, 11.04764],
                [-14.873751, 11.04764],
                [-14.873751, 11.048472000000118],
                [-14.873195, 11.04875],
                [-14.873195, 11.049584],
                [-14.872637999999881, 11.050138000000118],
                [-14.872637999999881, 11.050694000000135],
                [-14.871528, 11.05319400000019],
                [-14.870971999999881, 11.053472000000113],
                [-14.870971999999881, 11.054306],
                [-14.870416, 11.05486000000019],
                [-14.870416, 11.055694],
                [-14.869862, 11.055972],
                [-14.86986, 11.056806000000108],
                [-14.869305999999881, 11.057916],
                [-14.86875, 11.058194000000185],
                [-14.86875, 11.05875],
                [-14.868194, 11.059028],
                [-14.867916, 11.059860000000185],
                [-14.867916, 11.060973000000104],
                [-14.868471999999883, 11.06236100000018],
                [-14.869305999999881, 11.063195],
                [-14.870137999999884, 11.063473000000158],
                [-14.871528, 11.063473000000158],
                [-14.87236, 11.063195],
                [-14.87236, 11.062917],
                [-14.874307, 11.062917],
                [-14.874307, 11.062639000000104],
                [-14.877361, 11.062639000000104],
                [-14.877917, 11.062917],
                [-14.878195, 11.063473000000158],
                [-14.878749, 11.063473000000158],
                [-14.879583, 11.064305000000104],
                [-14.879861, 11.064861],
                [-14.880417, 11.064861],
                [-14.880695, 11.065417],
                [-14.881251, 11.065417],
                [-14.881527, 11.065973],
                [-14.882083, 11.065973],
                [-14.882361, 11.066529],
                [-14.883193, 11.066527],
                [-14.883473, 11.067083],
                [-14.884027, 11.067083],
                [-14.884583, 11.067639],
                [-14.884583, 11.068195],
                [-14.885139, 11.068195],
                [-14.885417999999845, 11.068751],
                [-14.886527, 11.069027000000176],
                [-14.887083999999845, 11.069861],
                [-14.887915, 11.070693000000176],
                [-14.888749999999845, 11.071249],
                [-14.888749999999845, 11.071527],
                [-14.889584, 11.072361000000171],
                [-14.890138, 11.072639],
                [-14.890417999999897, 11.07319600000011],
                [-14.890972, 11.07319600000011],
                [-14.89125, 11.073749],
                [-14.892083999999898, 11.074027000000171],
                [-14.892083999999898, 11.074584],
                [-14.893194, 11.07486200000011],
                [-14.895138, 11.076806],
                [-14.895694, 11.077084000000127],
                [-14.896806, 11.07819400000011],
                [-14.89736, 11.07819400000011],
                [-14.898194, 11.079028],
                [-14.898194, 11.079584],
                [-14.898749999999893, 11.079584],
                [-14.899028999999814, 11.08014],
                [-14.89986, 11.080694],
                [-14.89986, 11.081528000000105],
                [-14.900694999999814, 11.082084000000123],
                [-14.900694999999814, 11.082638000000145],
                [-14.900138, 11.082916],
                [-14.89986, 11.082084000000123],
                [-14.899028999999814, 11.081528000000105],
                [-14.898749999999893, 11.080972000000145],
                [-14.897915999999896, 11.08014],
                [-14.897915999999896, 11.07986000000011],
                [-14.897081999999898, 11.079306000000145],
                [-14.896249999999895, 11.078472],
                [-14.895694, 11.07819400000011],
                [-14.895138, 11.077362],
                [-14.894583999999895, 11.077362],
                [-14.893472, 11.07652800000011],
                [-14.893194, 11.07597200000015],
                [-14.891528, 11.07514],
                [-14.89125, 11.074584],
                [-14.890694, 11.074584],
                [-14.890417999999897, 11.074027000000171],
                [-14.889862, 11.074027000000171],
                [-14.889584, 11.073471000000154],
                [-14.889028, 11.073471000000154],
                [-14.888472, 11.072918],
                [-14.888472, 11.072361000000171],
                [-14.887915, 11.072083],
                [-14.887083999999845, 11.070973],
                [-14.886527, 11.070973],
                [-14.885696, 11.070139000000154],
                [-14.885139, 11.069861],
                [-14.884861, 11.069305],
                [-14.884305, 11.069027000000176],
                [-14.883193, 11.067917],
                [-14.882083, 11.067361000000176],
                [-14.881527, 11.067361000000176],
                [-14.880417, 11.066529],
                [-14.880139, 11.065973],
                [-14.879583, 11.065973],
                [-14.878473, 11.064861],
                [-14.877917, 11.064583],
                [-14.877639, 11.064029000000176],
                [-14.877083, 11.063751],
                [-14.874583, 11.063751],
                [-14.874583, 11.064029000000176],
                [-14.872637999999881, 11.064029000000176],
                [-14.871803999999884, 11.064305000000104],
                [-14.871803999999884, 11.064583],
                [-14.86875, 11.064583],
                [-14.868194, 11.064305000000104],
                [-14.867916, 11.064861],
                [-14.866805999999883, 11.064861],
                [-14.866528, 11.065417],
                [-14.866528, 11.066529],
                [-14.865694, 11.067639],
                [-14.865694, 11.068471000000159],
                [-14.865139999999883, 11.069027000000176],
                [-14.864862, 11.069861],
                [-14.864862, 11.071527],
                [-14.864584, 11.071527],
                [-14.864584, 11.07319600000011],
                [-14.864305999999885, 11.07319600000011],
                [-14.864305999999885, 11.07430600000015],
                [-14.864028, 11.07430600000015],
                [-14.864028, 11.077362],
                [-14.86375, 11.077362],
                [-14.86375, 11.081528000000105],
                [-14.864028, 11.081528000000105],
                [-14.864028, 11.087082000000123],
                [-14.86375, 11.087085],
                [-14.86375, 11.088473],
                [-14.863194, 11.090139],
                [-14.86263799999989, 11.090695],
                [-14.862084, 11.090695],
                [-14.86125, 11.091805],
                [-14.86097199999989, 11.092361],
                [-14.859584, 11.092361],
                [-14.859584, 11.092917000000114],
                [-14.859027, 11.093195],
                [-14.859027, 11.093751000000168],
                [-14.858470999999895, 11.093751000000168],
                [-14.858470999999895, 11.09430500000019],
                [-14.860415, 11.095695],
                [-14.86125, 11.095695],
                [-14.861805999999888, 11.096249000000114],
                [-14.86263799999989, 11.096249000000114],
                [-14.863473999999883, 11.096805],
                [-14.863473999999883, 11.099861],
                [-14.864584, 11.09958300000011],
                [-14.865416, 11.099027],
                [-14.865416, 11.098473],
                [-14.865971999999886, 11.097917000000109],
                [-14.866805999999883, 11.097639000000186],
                [-14.867916, 11.097639000000186],
                [-14.868471999999883, 11.098193],
                [-14.868471999999883, 11.098749000000169],
                [-14.869028, 11.099027],
                [-14.869305999999881, 11.09958300000011],
                [-14.869305999999881, 11.101528],
                [-14.868471999999883, 11.101806000000181],
                [-14.868194, 11.101528],
                [-14.868194, 11.100418],
                [-14.867916, 11.099861],
                [-14.865971999999886, 11.099861],
                [-14.865971999999886, 11.10014000000018],
                [-14.864862, 11.100696],
                [-14.864028, 11.100971000000186],
                [-14.86375, 11.102084],
                [-14.863194, 11.102362],
                [-14.863194, 11.10375],
                [-14.862918, 11.104028],
                [-14.862918, 11.104862],
                [-14.86263799999989, 11.105138000000181],
                [-14.860415, 11.105138000000181],
                [-14.860415, 11.105416],
                [-14.859584, 11.105694],
                [-14.858470999999895, 11.105694],
                [-14.858193, 11.105416],
                [-14.858193, 11.103472000000181],
                [-14.857638999999892, 11.103194],
                [-14.857638999999892, 11.102084],
                [-14.857083, 11.10124900000011],
                [-14.856527, 11.10124900000011],
                [-14.856249, 11.10014000000018],
                [-14.855695, 11.099861],
                [-14.855417, 11.099305000000186],
                [-14.854861, 11.099027],
                [-14.853751, 11.099305000000186],
                [-14.853470999999843, 11.100418],
                [-14.85263899999984, 11.100971000000186],
                [-14.852361, 11.101806000000181],
                [-14.852361, 11.102918000000159],
                [-14.851529, 11.103194],
                [-14.85097299999984, 11.10375],
                [-14.850695, 11.104584000000159],
                [-14.850695, 11.105694],
                [-14.850138999999842, 11.10625000000016],
                [-14.850138999999842, 11.107084],
                [-14.849583, 11.107638],
                [-14.849583, 11.108196],
                [-14.849027, 11.108472000000177],
                [-14.849027, 11.109028000000137],
                [-14.848472999999842, 11.109306],
                [-14.848472999999842, 11.109862],
                [-14.847917, 11.109862],
                [-14.847917, 11.109028000000137],
                [-14.848472999999842, 11.10791600000016],
                [-14.849027, 11.10791600000016],
                [-14.849027, 11.107084],
                [-14.849583, 11.106806000000176],
                [-14.849861, 11.105972],
                [-14.849861, 11.105138000000181],
                [-14.850417, 11.103472000000181],
                [-14.850695, 11.102918000000159],
                [-14.851249, 11.102918000000159],
                [-14.851249, 11.102362],
                [-14.851804999999842, 11.102084],
                [-14.852083, 11.101528],
                [-14.852083, 11.100696],
                [-14.852917, 11.10014000000018],
                [-14.852917, 11.09958300000011],
                [-14.853470999999843, 11.098749000000169],
                [-14.854027, 11.098749000000169],
                [-14.854583, 11.097917000000109],
                [-14.854583, 11.096805],
                [-14.855417, 11.095139],
                [-14.855972999999892, 11.095139],
                [-14.856249, 11.094583000000114],
                [-14.856249, 11.093473],
                [-14.856804999999895, 11.092083],
                [-14.857361, 11.091805],
                [-14.857638999999892, 11.091251000000113],
                [-14.858470999999895, 11.090417],
                [-14.858470999999895, 11.089861],
                [-14.859027, 11.088751],
                [-14.859304999999893, 11.087917000000118],
                [-14.859862, 11.087917000000118],
                [-14.860415, 11.086529],
                [-14.860415, 11.08125],
                [-14.860696, 11.08125],
                [-14.860696, 11.075694],
                [-14.860139999999888, 11.075415],
                [-14.859862, 11.07486200000011],
                [-14.859862, 11.073749],
                [-14.860139999999888, 11.073749],
                [-14.860139999999888, 11.072639],
                [-14.860696, 11.072361000000171],
                [-14.860696, 11.071527],
                [-14.86125, 11.070417],
                [-14.861528, 11.070417],
                [-14.861528, 11.068751],
                [-14.862362, 11.067083],
                [-14.862918, 11.066805000000159],
                [-14.862918, 11.065417],
                [-14.863194, 11.065417],
                [-14.863194, 11.059304],
                [-14.863473999999883, 11.059304],
                [-14.863473999999883, 11.058194000000185],
                [-14.86375, 11.058194000000185],
                [-14.86375, 11.056806000000108],
                [-14.864305999999885, 11.05625],
                [-14.864305999999885, 11.055416],
                [-14.864862, 11.055138000000113],
                [-14.865139999999883, 11.054584],
                [-14.865139999999883, 11.05375],
                [-14.865694, 11.053472000000113],
                [-14.865694, 11.052916],
                [-14.86625, 11.05264],
                [-14.866528, 11.05152800000019],
                [-14.867084, 11.05152800000019],
                [-14.86736, 11.050418],
                [-14.867916, 11.050138000000118],
                [-14.868471999999883, 11.049028000000135],
                [-14.868471999999883, 11.048472000000118],
                [-14.869028, 11.048196000000189],
                [-14.869305999999881, 11.047084],
                [-14.86986, 11.04652700000014],
                [-14.870416, 11.046249],
                [-14.870416, 11.045693],
                [-14.870971999999881, 11.0454170000001],
                [-14.870971999999881, 11.04486100000014],
                [-14.871528, 11.044583],
                [-14.872085, 11.043749000000105],
                [-14.872916, 11.04319500000014],
                [-14.872916, 11.042639000000122],
                [-14.873472999999876, 11.042639000000122],
                [-14.874307, 11.041805],
                [-14.874307, 11.041251],
                [-14.874861, 11.040971000000127],
                [-14.875973, 11.040695],
                [-14.876251, 11.040139],
                [-14.877361, 11.039861000000144],
                [-14.878195, 11.039029],
                [-14.878749, 11.038749000000166],
                [-14.879861, 11.038749000000166],
                [-14.879861, 11.038473],
                [-14.881527, 11.038473],
                [-14.881805, 11.037917],
                [-14.885696, 11.037917],
                [-14.885696, 11.037639000000127],
                [-14.886806, 11.037639000000127],
                [-14.887915, 11.037083000000166],
                [-14.887915, 11.036805],
                [-14.889028, 11.036805],
                [-14.889306, 11.036251],
                [-14.890417999999897, 11.035973000000126],
                [-14.890694, 11.035417000000166],
                [-14.89125, 11.035417000000166],
                [-14.891806, 11.034861000000149],
                [-14.891806, 11.034029],
                [-14.89236, 11.033473],
                [-14.89236, 11.032360000000153],
                [-14.892916, 11.032085000000166],
                [-14.892916, 11.029306],
                [-14.89264, 11.028472],
                [-14.892083999999898, 11.027916000000175],
                [-14.891806, 11.026806],
                [-14.890972, 11.026250000000175],
                [-14.890972, 11.025416],
                [-14.890417999999897, 11.025138],
                [-14.890417999999897, 11.024584000000175],
                [-14.889862, 11.024306],
                [-14.889862, 11.023472],
                [-14.889028, 11.023194000000103],
                [-14.889028, 11.022084],
                [-14.888749999999845, 11.021528000000103],
                [-14.888194, 11.02125000000018],
                [-14.887915, 11.02014],
                [-14.887362, 11.019862000000103],
                [-14.887083999999845, 11.019305],
                [-14.887083999999845, 11.018193],
                [-14.886527, 11.017917],
                [-14.885971, 11.016805],
                [-14.885696, 11.015971],
                [-14.885139, 11.015971],
                [-14.884861, 11.015417000000184],
                [-14.884861, 11.014305],
                [-14.884305, 11.014027000000112],
                [-14.884305, 11.01291700000013],
                [-14.884027, 11.012361000000112],
                [-14.883473, 11.012083000000189],
                [-14.883473, 11.010139],
                [-14.883193, 11.010139],
                [-14.883193, 11.008751000000188],
                [-14.882639, 11.008473],
                [-14.882639, 11.006529],
                [-14.882083, 11.005972],
                [-14.882083, 10.999584],
                [-14.882917, 10.99930600000016],
                [-14.883193, 10.998194000000126],
                [-14.884027, 10.997916],
                [-14.884027, 10.997084000000143],
                [-14.884583, 10.996806],
                [-14.884583, 10.995416000000148],
                [-14.884305, 10.995416000000148],
                [-14.884305, 10.993196000000125],
                [-14.884027, 10.993196000000125],
                [-14.884027, 10.984583],
                [-14.883749, 10.984583],
                [-14.883749, 10.982361],
                [-14.883473, 10.982361],
                [-14.883473, 10.980973],
                [-14.883193, 10.980138000000125],
                [-14.882361, 10.978472000000124],
                [-14.881805, 10.978194],
                [-14.881527, 10.977638000000184],
                [-14.881251, 10.976528],
                [-14.880695, 10.976250000000107],
                [-14.880695, 10.975694],
                [-14.880139, 10.975138000000129],
                [-14.879583, 10.974862],
                [-14.879583, 10.974028],
                [-14.878473, 10.973194],
                [-14.878473, 10.972640000000183],
                [-14.877917, 10.972362],
                [-14.877361, 10.971250000000111],
                [-14.877361, 10.969862],
                [-14.876804999999877, 10.969584000000111],
                [-14.876804999999877, 10.969028],
                [-14.876251, 10.96875],
                [-14.876251, 10.968194],
                [-14.875695, 10.967915],
                [-14.875695, 10.967084],
                [-14.875138999999876, 10.966806000000133],
                [-14.875138999999876, 10.966249],
                [-14.874583, 10.965971000000138],
                [-14.874583, 10.965418],
                [-14.874029, 10.965139],
                [-14.874029, 10.964583],
                [-14.871528, 10.96208300000012],
                [-14.870694, 10.960971000000143],
                [-14.869305999999881, 10.96041700000012],
                [-14.864584, 10.96041700000012],
                [-14.864305999999885, 10.96152700000016],
                [-14.86263799999989, 10.96152700000016],
                [-14.86263799999989, 10.961251],
                [-14.860696, 10.961251],
                [-14.860139999999888, 10.96152700000016],
                [-14.859584, 10.96208300000012],
                [-14.858470999999895, 10.96208300000012],
                [-14.858470999999895, 10.96152700000016],
                [-14.857917, 10.96152700000016],
                [-14.857638999999892, 10.96208300000012],
                [-14.857083, 10.962361],
                [-14.856249, 10.962361],
                [-14.855695, 10.962917],
                [-14.854861, 10.962917],
                [-14.85430499999984, 10.963473],
                [-14.853472999999894, 10.963473],
                [-14.852917, 10.964027],
                [-14.852083, 10.964027],
                [-14.851529, 10.964583],
                [-14.850695, 10.964861000000155],
                [-14.849583, 10.965418],
                [-14.849027, 10.965418],
                [-14.847917, 10.965971000000138],
                [-14.847361, 10.967362],
                [-14.846529, 10.968194],
                [-14.845416, 10.969028],
                [-14.84486, 10.969028],
                [-14.844585, 10.969584000000111],
                [-14.844028, 10.969584000000111],
                [-14.843194, 10.970694],
                [-14.842638, 10.970694],
                [-14.842362, 10.971250000000111],
                [-14.84125, 10.972084],
                [-14.840693999999871, 10.972084],
                [-14.84014, 10.972916000000112],
                [-14.840138, 10.974028],
                [-14.839584, 10.974862],
                [-14.839027999999871, 10.974862],
                [-14.838193999999874, 10.975972000000183],
                [-14.837638, 10.975972000000183],
                [-14.837084, 10.976806000000124],
                [-14.836527999999873, 10.976806000000124],
                [-14.835972, 10.97736],
                [-14.834306, 10.978194],
                [-14.83264, 10.97875],
                [-14.831805, 10.97875],
                [-14.830974, 10.979029],
                [-14.830417, 10.97986],
                [-14.829583, 10.97986],
                [-14.829305, 10.980417000000102],
                [-14.828749, 10.980417000000102],
                [-14.828471, 10.980973],
                [-14.827360999999883, 10.981251],
                [-14.826805, 10.982083000000102],
                [-14.825694999999882, 10.982639],
                [-14.825139, 10.983195],
                [-14.825139, 10.983751],
                [-14.824583, 10.984863],
                [-14.824860999999885, 10.985417],
                [-14.823473, 10.986249],
                [-14.822639, 10.986249],
                [-14.822083, 10.986805000000174],
                [-14.821251, 10.986805000000174],
                [-14.820694999999887, 10.987361],
                [-14.820139, 10.987361],
                [-14.819859999999835, 10.987917000000152],
                [-14.819304, 10.987917000000152],
                [-14.819028999999887, 10.988471000000175],
                [-14.817916, 10.988471000000175],
                [-14.817916, 10.988751],
                [-14.817084, 10.989027],
                [-14.815693999999837, 10.989027],
                [-14.815693999999837, 10.989305],
                [-14.814306, 10.989305],
                [-14.814306, 10.989583000000152],
                [-14.812916, 10.989583000000152],
                [-14.812916, 10.989861],
                [-14.81125, 10.989861],
                [-14.81125, 10.99013900000017],
                [-14.809862, 10.99013900000017],
                [-14.809306, 10.990693],
                [-14.809306, 10.99180500000017],
                [-14.80875, 10.99180500000017],
                [-14.80875, 10.993196000000125],
                [-14.808472, 10.993196000000125],
                [-14.808472, 10.995416000000148],
                [-14.808194, 10.995416000000148],
                [-14.808194, 10.997916],
                [-14.807916, 10.997916],
                [-14.807916, 11.000416000000143],
                [-14.808194, 11.000416000000143],
                [-14.808194, 11.002362],
                [-14.808472, 11.002362],
                [-14.808472, 11.003750000000139],
                [-14.80875, 11.003750000000139],
                [-14.809306, 11.004860000000122],
                [-14.809306, 11.005694],
                [-14.810694, 11.006804],
                [-14.811806, 11.007082000000139],
                [-14.81236, 11.007917000000134],
                [-14.813194, 11.008195],
                [-14.813194, 11.008751000000188],
                [-14.814027999999837, 11.009861],
                [-14.815138, 11.010695000000112],
                [-14.815416, 11.011805],
                [-14.815693999999837, 11.012361000000112],
                [-14.816806, 11.01291700000013],
                [-14.816806, 11.013473],
                [-14.817359999999837, 11.013751000000184],
                [-14.817638, 11.014305],
                [-14.818472, 11.015139],
                [-14.818751, 11.01624900000013],
                [-14.819304, 11.016527],
                [-14.819304, 11.017083000000184],
                [-14.820694999999887, 11.017639],
                [-14.820973, 11.017361000000108],
                [-14.820973, 11.019862000000103],
                [-14.821807, 11.022084],
                [-14.822362999999882, 11.022362000000157],
                [-14.822360999999887, 11.02375],
                [-14.822639, 11.02375],
                [-14.822639, 11.025138],
                [-14.822362999999882, 11.025418],
                [-14.821807, 11.025138],
                [-14.82152699999989, 11.024584000000175],
                [-14.82152699999989, 11.022362000000157],
                [-14.820973, 11.022084],
                [-14.820694999999887, 11.020971],
                [-14.820139, 11.020696000000157],
                [-14.820139, 11.019862000000103],
                [-14.819585, 11.019027000000108],
                [-14.819585, 11.018474],
                [-14.818193999999835, 11.017639],
                [-14.818193999999835, 11.016527],
                [-14.817084, 11.015695000000107],
                [-14.817084, 11.015139],
                [-14.816527999999835, 11.014861],
                [-14.816527999999835, 11.014305],
                [-14.814861999999835, 11.012639],
                [-14.814861999999835, 11.012083000000189],
                [-14.814306, 11.011805],
                [-14.814027999999837, 11.010695000000112],
                [-14.813194, 11.010417000000189],
                [-14.81236, 11.009307],
                [-14.811528, 11.009027000000117],
                [-14.811528, 11.008473],
                [-14.810138, 11.007917000000134],
                [-14.809862, 11.00736],
                [-14.809028, 11.006529],
                [-14.807916, 11.005972],
                [-14.807084, 11.005972],
                [-14.807084, 11.006529],
                [-14.806528, 11.006529],
                [-14.806528, 11.00736],
                [-14.805971, 11.007639],
                [-14.805971, 11.008473],
                [-14.804861, 11.009027000000117],
                [-14.804582999999866, 11.009583000000134],
                [-14.803748999999868, 11.010417000000189],
                [-14.803748999999868, 11.010973],
                [-14.803193, 11.010973],
                [-14.802082999999868, 11.011529],
                [-14.801805, 11.012083000000189],
                [-14.800695, 11.012639],
                [-14.79958299999987, 11.012639],
                [-14.799305, 11.013195],
                [-14.797639, 11.014027000000112],
                [-14.79791699999987, 11.01458300000013],
                [-14.79791699999987, 11.015695000000107],
                [-14.798195, 11.01624900000013],
                [-14.798748999999873, 11.016527],
                [-14.799027, 11.017083000000184],
                [-14.799027, 11.018193],
                [-14.79958299999987, 11.018749000000184],
                [-14.79958299999987, 11.019583],
                [-14.800139, 11.020696000000157],
                [-14.800695, 11.020971],
                [-14.800695, 11.021528000000103],
                [-14.801250999999866, 11.022084],
                [-14.801250999999866, 11.025694000000158],
                [-14.801527, 11.025694000000158],
                [-14.801527, 11.027916000000175],
                [-14.802361, 11.028472],
                [-14.802639, 11.029028000000153],
                [-14.802639, 11.03125000000017],
                [-14.802916999999866, 11.03125000000017],
                [-14.802916999999866, 11.032360000000153],
                [-14.803193, 11.032360000000153],
                [-14.803193, 11.035139],
                [-14.802639, 11.035417000000166],
                [-14.802639, 11.037083000000166],
                [-14.801527, 11.038195000000144],
                [-14.799861, 11.039029],
                [-14.799305, 11.039861000000144],
                [-14.798473, 11.039861000000144],
                [-14.798195, 11.040417000000105],
                [-14.797361, 11.040695],
                [-14.79625099999987, 11.040695],
                [-14.795416999999873, 11.040973000000122],
                [-14.794307, 11.041527000000144],
                [-14.794307, 11.041805],
                [-14.792637999999897, 11.041805],
                [-14.792637999999897, 11.042083000000105],
                [-14.791529, 11.042083000000105],
                [-14.791529, 11.041805],
                [-14.7901379999999, 11.041805],
                [-14.789305999999897, 11.042083000000105],
                [-14.78874999999988, 11.041805],
                [-14.785694, 11.041805],
                [-14.785694, 11.042083000000105],
                [-14.784306, 11.042083000000105],
                [-14.782917999999881, 11.042639000000122],
                [-14.782917999999881, 11.04319500000014],
                [-14.782083999999884, 11.043749000000105],
                [-14.781528, 11.043749000000105],
                [-14.781528, 11.044305000000122],
                [-14.780972, 11.04486100000014],
                [-14.780415, 11.04486100000014],
                [-14.78014, 11.0454170000001],
                [-14.779306, 11.045693],
                [-14.779306, 11.047362000000135],
                [-14.779862, 11.047915],
                [-14.78124999999983, 11.047915],
                [-14.78124999999983, 11.048196000000189],
                [-14.782362, 11.048196000000189],
                [-14.782638, 11.04875],
                [-14.782362, 11.049028000000135],
                [-14.779862, 11.049028000000135],
                [-14.779306, 11.048196000000189],
                [-14.778749, 11.047915],
                [-14.77791799999983, 11.047915],
                [-14.777361, 11.047362000000135],
                [-14.776527, 11.047362000000135],
                [-14.775973, 11.047084],
                [-14.775695, 11.04652700000014],
                [-14.775139, 11.046805],
                [-14.774305, 11.04652700000014],
                [-14.773195, 11.04652700000014],
                [-14.773195, 11.046249],
                [-14.772083, 11.046249],
                [-14.771529, 11.045971000000122],
                [-14.771529, 11.045139],
                [-14.775695, 11.045139],
                [-14.776527, 11.044305000000122],
                [-14.777359, 11.044027],
                [-14.77791799999983, 11.043473],
                [-14.779027, 11.043473],
                [-14.779306, 11.042917],
                [-14.780415, 11.042639000000122],
                [-14.780694, 11.042083000000105],
                [-14.780694, 11.041251],
                [-14.78124999999983, 11.040417000000105],
                [-14.782362, 11.040417000000105],
                [-14.782917999999881, 11.039583],
                [-14.784583999999882, 11.039583],
                [-14.784583999999882, 11.039305000000127],
                [-14.787915999999882, 11.039305000000127],
                [-14.787915999999882, 11.039583],
                [-14.789305999999897, 11.039583],
                [-14.78986, 11.040139],
                [-14.790694, 11.039861000000144],
                [-14.791804, 11.039861000000144],
                [-14.79236, 11.039583],
                [-14.794582999999875, 11.039583],
                [-14.794582999999875, 11.039029],
                [-14.795139, 11.038473],
                [-14.795973, 11.038473],
                [-14.795973, 11.037917],
                [-14.796529, 11.037917],
                [-14.796805, 11.037361],
                [-14.797361, 11.037361],
                [-14.79791699999987, 11.036805],
                [-14.798473, 11.035695],
                [-14.799027, 11.035139],
                [-14.799027, 11.03458200000017],
                [-14.800139, 11.034307000000126],
                [-14.800139, 11.033751000000166],
                [-14.800971, 11.033473],
                [-14.801527, 11.032085000000166],
                [-14.801527, 11.030138],
                [-14.801250999999866, 11.030138],
                [-14.80124899999987, 11.028194],
                [-14.800695, 11.027638],
                [-14.800695, 11.026806],
                [-14.800139, 11.026250000000175],
                [-14.800139, 11.025138],
                [-14.799861, 11.025138],
                [-14.799861, 11.024028000000158],
                [-14.799305, 11.02375],
                [-14.799305, 11.022918000000175],
                [-14.798748999999873, 11.02264],
                [-14.79791699999987, 11.020971],
                [-14.79791699999987, 11.02014],
                [-14.797361, 11.019027000000108],
                [-14.79625099999987, 11.018193],
                [-14.795695, 11.018193],
                [-14.794861, 11.017361000000108],
                [-14.794307, 11.017083000000184],
                [-14.794307, 11.016527],
                [-14.793473, 11.01624900000013],
                [-14.793195, 11.015695000000107],
                [-14.792084999999872, 11.015695000000107],
                [-14.792084999999872, 11.015417000000184],
                [-14.789581999999882, 11.015417000000184],
                [-14.789581999999882, 11.015139],
                [-14.7884719999999, 11.015139],
                [-14.788194, 11.014305],
                [-14.787638, 11.014305],
                [-14.787638, 11.013751000000184],
                [-14.786528, 11.01291700000013],
                [-14.786528, 11.010973],
                [-14.786249999999882, 11.010973],
                [-14.786249999999882, 11.009861],
                [-14.785972, 11.009861],
                [-14.785972, 11.008751000000188],
                [-14.785415999999884, 11.00736],
                [-14.78486, 11.006804],
                [-14.784028, 11.006529],
                [-14.782917999999881, 11.006529],
                [-14.781807999999899, 11.007082000000139],
                [-14.781528, 11.007639],
                [-14.780972, 11.007639],
                [-14.780972, 11.008195],
                [-14.780415, 11.008195],
                [-14.78014, 11.008751000000188],
                [-14.779027, 11.009307],
                [-14.778193, 11.009307],
                [-14.777639, 11.009861],
                [-14.776527, 11.010417000000189],
                [-14.775417, 11.010417000000189],
                [-14.775417, 11.010695000000112],
                [-14.774027, 11.010695000000112],
                [-14.774027, 11.010973],
                [-14.769861, 11.010973],
                [-14.769861, 11.011249000000134],
                [-14.767917, 11.01125100000013],
                [-14.767361, 11.011805],
                [-14.76597299999986, 11.011805],
                [-14.76597299999986, 11.012083000000189],
                [-14.764585, 11.012083000000189],
                [-14.764304, 11.012639],
                [-14.763472, 11.01291700000013],
                [-14.763472, 11.013473],
                [-14.762916, 11.013473],
                [-14.762082, 11.014861],
                [-14.762082, 11.015695000000107],
                [-14.761528, 11.015971],
                [-14.761528, 11.016805],
                [-14.760972, 11.016805],
                [-14.760972, 11.017361000000108],
                [-14.760416, 11.017361000000108],
                [-14.759584, 11.018474],
                [-14.759584, 11.019027000000108],
                [-14.75875, 11.019305],
                [-14.75875, 11.019862000000103],
                [-14.758194, 11.019862000000103],
                [-14.758194, 11.020415000000185],
                [-14.757362, 11.020971],
                [-14.75680599999987, 11.020971],
                [-14.75680599999987, 11.021528000000103],
                [-14.755971999999872, 11.022084],
                [-14.75513999999987, 11.023194000000103],
                [-14.75375, 11.02375],
                [-14.75375, 11.024306],
                [-14.752915, 11.024584000000175],
                [-14.750970999999879, 11.024584000000175],
                [-14.750692999999899, 11.025138],
                [-14.749583, 11.025138],
                [-14.749583, 11.025418],
                [-14.746804999999824, 11.025418],
                [-14.746804999999824, 11.025138],
                [-14.745972999999879, 11.024862],
                [-14.745138999999824, 11.024862],
                [-14.744304999999827, 11.024584000000175],
                [-14.744304999999827, 11.024306],
                [-14.743195, 11.024306],
                [-14.742638999999826, 11.02375],
                [-14.741806999999824, 11.02375],
                [-14.741527, 11.023194000000103],
                [-14.740417, 11.02264],
                [-14.739304, 11.02264],
                [-14.739304, 11.022362000000157],
                [-14.738194, 11.022362000000157],
                [-14.738194, 11.022084],
                [-14.73625, 11.022084],
                [-14.73625, 11.021806],
                [-14.734862, 11.021806],
                [-14.734862, 11.021528000000103],
                [-14.73375, 11.021528000000103],
                [-14.73375, 11.02125000000018],
                [-14.73264, 11.02125000000018],
                [-14.73264, 11.020971],
                [-14.731527999999855, 11.020971],
                [-14.731527999999855, 11.020696000000157],
                [-14.729584, 11.020696000000157],
                [-14.729027999999857, 11.02014],
                [-14.728194, 11.019862000000103],
                [-14.723474, 11.019862000000103],
                [-14.723474, 11.02014],
                [-14.722361, 11.02014],
                [-14.722083, 11.020696000000157],
                [-14.721527, 11.020696000000157],
                [-14.720695, 11.021806],
                [-14.719305, 11.02264],
                [-14.719305, 11.023472],
                [-14.718748999999889, 11.023472],
                [-14.718748999999889, 11.024028000000158],
                [-14.717916999999886, 11.024306],
                [-14.717916999999886, 11.024862],
                [-14.717360999999869, 11.024862],
                [-14.717360999999869, 11.025418],
                [-14.716529, 11.025974],
                [-14.715695, 11.026250000000175],
                [-14.715695, 11.026806],
                [-14.715139, 11.026806],
                [-14.715139, 11.027360000000158],
                [-14.714307, 11.027360000000158],
                [-14.713194999999871, 11.027916000000175],
                [-14.713194999999871, 11.028472],
                [-14.71235999999982, 11.02875],
                [-14.71235999999982, 11.029306],
                [-14.71125099999989, 11.029306],
                [-14.710693999999819, 11.029582000000175],
                [-14.710415999999896, 11.030138],
                [-14.709859999999821, 11.030416],
                [-14.708193999999821, 11.030416],
                [-14.707638, 11.030694000000153],
                [-14.707361999999819, 11.03125000000017],
                [-14.706249999999898, 11.031806000000131],
                [-14.705694, 11.031806000000131],
                [-14.705416, 11.032360000000153],
                [-14.704306, 11.032360000000153],
                [-14.704306, 11.032638],
                [-14.703194, 11.033194],
                [-14.702638, 11.033194],
                [-14.702362, 11.033751000000166],
                [-14.70125, 11.034307000000126],
                [-14.700694, 11.034307000000126],
                [-14.700416, 11.034861000000149],
                [-14.699306, 11.035417000000166],
                [-14.698193, 11.035417000000166],
                [-14.697918, 11.035973000000126],
                [-14.696805, 11.036805],
                [-14.695974, 11.036805],
                [-14.694861, 11.037639000000127],
                [-14.694861, 11.038473],
                [-14.694027, 11.039029],
                [-14.693471, 11.039029],
                [-14.693195, 11.039583],
                [-14.691527, 11.040417000000105],
                [-14.690695, 11.040695],
                [-14.689027, 11.040695],
                [-14.689027, 11.040971000000127],
                [-14.687917, 11.040973000000122],
                [-14.687917, 11.041251],
                [-14.686251, 11.041251],
                [-14.686251, 11.041527000000144],
                [-14.682916, 11.041527000000144],
                [-14.682916, 11.041805],
                [-14.67875, 11.041805],
                [-14.678194, 11.042083000000105],
                [-14.678194, 11.042639000000122],
                [-14.672639999999888, 11.039861000000144],
                [-14.672362, 11.039305000000127],
                [-14.671249, 11.039029],
                [-14.666804999999897, 11.036805],
                [-14.665971, 11.035417000000166],
                [-14.666251, 11.034861000000149],
                [-14.666251, 11.032638],
                [-14.666527, 11.032638],
                [-14.666527, 11.030138],
                [-14.667917, 11.030138],
                [-14.668195, 11.029582000000175],
                [-14.669861, 11.029582000000175],
                [-14.670970999999895, 11.029028000000153],
                [-14.674305999999888, 11.029028000000153],
                [-14.67513799999989, 11.02875],
                [-14.67513799999989, 11.028472],
                [-14.67625, 11.028472],
                [-14.67625, 11.028194],
                [-14.677639999999883, 11.028194],
                [-14.677639999999883, 11.027916000000175],
                [-14.679028, 11.027916000000175],
                [-14.67986, 11.027638],
                [-14.67986, 11.027360000000158],
                [-14.681805999999881, 11.027360000000158],
                [-14.681805999999881, 11.027638],
                [-14.682916, 11.027638],
                [-14.682916, 11.027916000000175],
                [-14.684029, 11.027916000000175],
                [-14.68486, 11.027638],
                [-14.68486, 11.027360000000158],
                [-14.685973, 11.027360000000158],
                [-14.685973, 11.027084],
                [-14.687083, 11.027084],
                [-14.687639, 11.026806],
                [-14.687917, 11.026250000000175],
                [-14.688751, 11.025974],
                [-14.688751, 11.02264],
                [-14.689305, 11.020971],
                [-14.689027, 11.020971],
                [-14.689027, 11.019583],
                [-14.688751, 11.019583],
                [-14.688751, 11.018474],
                [-14.688195, 11.017917],
                [-14.688195, 11.01624900000013],
                [-14.687917, 11.01624900000013],
                [-14.687917, 11.015139],
                [-14.687639, 11.015139],
                [-14.687639, 11.012361000000112],
                [-14.687361, 11.012361000000112],
                [-14.687361, 11.010417000000189],
                [-14.687639, 11.010417000000189],
                [-14.687639, 11.006529],
                [-14.687917, 11.006529],
                [-14.687917, 11.003750000000139],
                [-14.688195, 11.003750000000139],
                [-14.688195, 11.002638000000161],
                [-14.688473, 11.002638000000161],
                [-14.688473, 11.00097200000016],
                [-14.689027, 11.000416000000143],
                [-14.689027, 10.999584],
                [-14.689583, 10.999584],
                [-14.689583, 10.999028],
                [-14.690695, 10.998194000000126],
                [-14.690695, 10.997638000000165],
                [-14.691527, 10.997638000000165],
                [-14.692639, 10.996528000000126],
                [-14.69375099999985, 10.995972000000165],
                [-14.69375099999985, 10.995416000000148],
                [-14.694305, 10.995416000000148],
                [-14.694305, 10.994862000000126],
                [-14.694861, 10.994584],
                [-14.695974, 10.994584],
                [-14.695974, 10.994028],
                [-14.696805, 10.993749],
                [-14.69764, 10.993749],
                [-14.697918, 10.993196000000125],
                [-14.699584, 10.993196000000125],
                [-14.699584, 10.992918],
                [-14.70125, 10.992918],
                [-14.70125, 10.992640000000165],
                [-14.702638, 10.992640000000165],
                [-14.703472, 10.992918],
                [-14.704028, 10.99347100000017],
                [-14.706806, 10.99347100000017],
                [-14.706806, 10.993749],
                [-14.708749999999895, 10.993749],
                [-14.708749999999895, 10.99347100000017],
                [-14.710138, 10.99347100000017],
                [-14.71152899999987, 10.992918],
                [-14.711804, 10.99236100000013],
                [-14.71291699999989, 10.99236100000013],
                [-14.713750999999888, 10.991527],
                [-14.713750999999888, 10.990973],
                [-14.714582999999891, 10.990417],
                [-14.714582999999891, 10.989861],
                [-14.715139, 10.989305],
                [-14.715695, 10.989305],
                [-14.716529, 10.988195],
                [-14.717916999999886, 10.987639],
                [-14.717916999999886, 10.986805000000174],
                [-14.718748999999889, 10.986249],
                [-14.718748999999889, 10.985695],
                [-14.719305, 10.985695],
                [-14.719305, 10.984861],
                [-14.719582999999886, 10.984307],
                [-14.720417, 10.984029],
                [-14.720417, 10.983195],
                [-14.720971, 10.982917],
                [-14.721248999999887, 10.981807000000174],
                [-14.721805, 10.981807000000174],
                [-14.721805, 10.981251],
                [-14.722361, 10.980973],
                [-14.722917, 10.97986],
                [-14.722917, 10.97736],
                [-14.723193, 10.97736],
                [-14.723193, 10.975972000000183],
                [-14.723749, 10.975694],
                [-14.724027, 10.975138000000129],
                [-14.724027, 10.974028],
                [-14.724862, 10.97375],
                [-14.724862, 10.973194],
                [-14.725418, 10.972362],
                [-14.725971, 10.97236],
                [-14.725971, 10.971806000000129],
                [-14.726528, 10.971250000000111],
                [-14.72764, 10.970972],
                [-14.727916, 10.970138000000134],
                [-14.728472, 10.970138000000134],
                [-14.729027999999857, 10.969584000000111],
                [-14.729861999999855, 10.969584000000111],
                [-14.730416, 10.96875],
                [-14.730972, 10.96875],
                [-14.730972, 10.967915],
                [-14.731527999999855, 10.96764],
                [-14.731527999999855, 10.966806000000133],
                [-14.732084, 10.966806000000133],
                [-14.732084, 10.965971000000138],
                [-14.73264, 10.965693],
                [-14.733472, 10.964861000000155],
                [-14.733472, 10.964305000000138],
                [-14.734862, 10.96374900000012],
                [-14.735138, 10.963195000000155],
                [-14.735694, 10.963195000000155],
                [-14.735972, 10.962639000000138],
                [-14.737085, 10.96152700000016],
                [-14.738194, 10.960971000000143],
                [-14.739304, 10.960971000000143],
                [-14.739304, 10.96041700000012],
                [-14.739861, 10.960139],
                [-14.740695, 10.960139],
                [-14.740972999999826, 10.959583],
                [-14.741805, 10.959583],
                [-14.742361, 10.959029],
                [-14.743472999999824, 10.959027],
                [-14.743472999999824, 10.958749000000125],
                [-14.751526999999896, 10.958749000000125],
                [-14.751526999999896, 10.959027],
                [-14.752361999999891, 10.959305000000143],
                [-14.753471999999874, 10.959305000000143],
                [-14.753471999999874, 10.959583],
                [-14.754305999999872, 10.95986100000016],
                [-14.755693999999892, 10.95986100000016],
                [-14.755693999999892, 10.960139],
                [-14.75680599999987, 10.960139],
                [-14.757916, 10.960695],
                [-14.758194, 10.961251],
                [-14.766251, 10.961251],
                [-14.766529, 10.960695],
                [-14.767083, 10.960695],
                [-14.767361, 10.960139],
                [-14.767917, 10.960139],
                [-14.768195, 10.959583],
                [-14.768751, 10.959583],
                [-14.769027, 10.959029],
                [-14.769583, 10.959027],
                [-14.770695, 10.95819500000016],
                [-14.770973, 10.957639000000142],
                [-14.771527, 10.957639000000142],
                [-14.771805, 10.957083000000125],
                [-14.772917, 10.956805],
                [-14.773195, 10.956251],
                [-14.773751, 10.956251],
                [-14.774861, 10.955695],
                [-14.776527, 10.954029],
                [-14.776805, 10.953473],
                [-14.777361, 10.953195000000164],
                [-14.777361, 10.952638],
                [-14.778193, 10.952085000000125],
                [-14.778193, 10.951250000000186],
                [-14.778749, 10.950138],
                [-14.779862, 10.949028000000169],
                [-14.78014, 10.949028000000169],
                [-14.782362, 10.946806],
                [-14.782917999999881, 10.946528],
                [-14.783194, 10.945972],
                [-14.784306, 10.945694000000174],
                [-14.785972, 10.944862],
                [-14.785972, 10.94458400000019],
                [-14.787638, 10.94458400000019],
                [-14.787638, 10.944306],
                [-14.79041599999988, 10.944306],
                [-14.79041599999988, 10.944028000000174],
                [-14.793195, 10.944028000000174],
                [-14.793750999999872, 10.943752],
                [-14.794026, 10.943194000000119],
                [-14.794861, 10.943194000000119],
                [-14.795695, 10.942362000000173],
                [-14.795695, 10.941528000000119],
                [-14.795416999999873, 10.940696000000173],
                [-14.794582999999875, 10.94014],
                [-14.792916999999875, 10.94014],
                [-14.792084999999872, 10.939305],
                [-14.792084999999872, 10.938749],
                [-14.793473, 10.938749],
                [-14.794307, 10.939027000000124],
                [-14.794861, 10.939584],
                [-14.795695, 10.939584],
                [-14.79791699999987, 10.938471000000106],
                [-14.798748999999873, 10.937639],
                [-14.799027, 10.936805000000106],
                [-14.799027, 10.935971],
                [-14.79958299999987, 10.935695000000123],
                [-14.79958299999987, 10.935139000000106],
                [-14.800971, 10.933751],
                [-14.801527, 10.933751],
                [-14.802639, 10.932639],
                [-14.80514, 10.932639],
                [-14.80514, 10.932361000000128],
                [-14.809028, 10.932361000000128],
                [-14.809028, 10.932639],
                [-14.81264, 10.932639],
                [-14.813194, 10.932083],
                [-14.813194, 10.930695000000128],
                [-14.813472, 10.930417],
                [-14.814584, 10.930417],
                [-14.815416, 10.93013900000011],
                [-14.815416, 10.92958300000015],
                [-14.815972, 10.929305],
                [-14.815972, 10.924306000000115],
                [-14.815693999999837, 10.924306000000115],
                [-14.815693999999837, 10.92263800000012],
                [-14.815416, 10.92263800000012],
                [-14.815416, 10.92125],
                [-14.815138, 10.92125],
                [-14.814027999999837, 10.919028],
                [-14.814027999999837, 10.917638000000181],
                [-14.81375, 10.917638000000181],
                [-14.81375, 10.91625],
                [-14.813472, 10.91625],
                [-14.813472, 10.91514],
                [-14.813194, 10.91514],
                [-14.813194, 10.913196000000141],
                [-14.812916, 10.913196000000141],
                [-14.812916, 10.910417],
                [-14.81236, 10.909861],
                [-14.812084, 10.909305],
                [-14.812084, 10.907083000000114],
                [-14.81236, 10.907083000000114],
                [-14.81236, 10.905695],
                [-14.812916, 10.904305],
                [-14.81375, 10.903751000000113],
                [-14.81375, 10.902361],
                [-14.814027999999837, 10.902361],
                [-14.814027999999837, 10.900139],
                [-14.81375, 10.900139],
                [-14.81375, 10.898472],
                [-14.813472, 10.898472],
                [-14.813472, 10.897360000000106],
                [-14.813194, 10.897360000000106],
                [-14.813194, 10.896250000000123],
                [-14.813472, 10.896250000000123],
                [-14.813472, 10.895138000000145],
                [-14.812916, 10.894028000000105],
                [-14.81236, 10.89375],
                [-14.812084, 10.892916000000127],
                [-14.812084, 10.891806000000145],
                [-14.81264, 10.89069400000011],
                [-14.813194, 10.890418],
                [-14.813194, 10.889862],
                [-14.81375, 10.889584000000127],
                [-14.814027999999837, 10.88902800000011],
                [-14.814027999999837, 10.888194],
                [-14.813194, 10.886528],
                [-14.812916, 10.886528],
                [-14.812916, 10.884305000000154],
                [-14.81236, 10.883473],
                [-14.811806, 10.883195000000114],
                [-14.810694, 10.883195000000114],
                [-14.810694, 10.883473],
                [-14.809028, 10.883473],
                [-14.808472, 10.883749000000137],
                [-14.808194, 10.884583],
                [-14.806248999999866, 10.884583],
                [-14.806248999999866, 10.884861000000114],
                [-14.804861, 10.884861000000114],
                [-14.804861, 10.885140000000149],
                [-14.804027, 10.885418],
                [-14.801250999999866, 10.88680600000015],
                [-14.800139, 10.88680600000015],
                [-14.800139, 10.887084],
                [-14.799027, 10.887084],
                [-14.799027, 10.88736200000011],
                [-14.797639, 10.88736200000011],
                [-14.795416999999873, 10.888194],
                [-14.795139, 10.88875],
                [-14.794582999999875, 10.88875],
                [-14.794307, 10.889306],
                [-14.793750999999872, 10.889306],
                [-14.793195, 10.889862],
                [-14.793195, 10.890418],
                [-14.792637999999897, 10.89069400000011],
                [-14.79236, 10.891806000000145],
                [-14.791804, 10.892084],
                [-14.791804, 10.89264],
                [-14.791249999999877, 10.892916000000127],
                [-14.791249999999877, 10.89375],
                [-14.789581999999882, 10.895416],
                [-14.789581999999882, 10.895972],
                [-14.789028, 10.896528],
                [-14.789028, 10.897638],
                [-14.789581999999882, 10.897916000000123],
                [-14.789581999999882, 10.898472],
                [-14.790971999999897, 10.898472],
                [-14.79041599999988, 10.899304],
                [-14.789028, 10.899304],
                [-14.788194, 10.898472],
                [-14.788194, 10.897360000000106],
                [-14.787638, 10.897082],
                [-14.785972, 10.897082],
                [-14.785139999999899, 10.897360000000106],
                [-14.784583999999882, 10.897916000000123],
                [-14.783749999999884, 10.897916000000123],
                [-14.783194, 10.898194],
                [-14.782917999999881, 10.898751000000118],
                [-14.78124999999983, 10.900417000000118],
                [-14.780694, 10.900417000000118],
                [-14.78014, 10.901251000000173],
                [-14.77958399999983, 10.901527000000101],
                [-14.778749, 10.901527000000101],
                [-14.778471, 10.900695],
                [-14.779306, 10.900417000000118],
                [-14.77958399999983, 10.89986],
                [-14.78014, 10.89986],
                [-14.780415, 10.899304],
                [-14.780972, 10.899304],
                [-14.78124999999983, 10.898751000000118],
                [-14.781806, 10.898751000000118],
                [-14.782083999999884, 10.898194],
                [-14.783749999999884, 10.897360000000106],
                [-14.784583999999882, 10.897082],
                [-14.783194, 10.895694000000105],
                [-14.783194, 10.895138000000145],
                [-14.782638, 10.895138000000145],
                [-14.782083999999884, 10.894584000000123],
                [-14.782083999999884, 10.893472000000145],
                [-14.781807999999899, 10.892916000000127],
                [-14.78124999999983, 10.89264],
                [-14.780694, 10.892084],
                [-14.780694, 10.890972],
                [-14.780415, 10.89013800000015],
                [-14.779862, 10.889862],
                [-14.779862, 10.889306],
                [-14.780694, 10.889306],
                [-14.78124999999983, 10.89013800000015],
                [-14.78124999999983, 10.890972],
                [-14.782083999999884, 10.891528],
                [-14.782083999999884, 10.892084],
                [-14.782638, 10.893194],
                [-14.783472, 10.894306],
                [-14.784306, 10.895138000000145],
                [-14.785415999999884, 10.895138000000145],
                [-14.786249999999882, 10.894862],
                [-14.787362, 10.894028000000105],
                [-14.788194, 10.89236000000011],
                [-14.788194, 10.891528],
                [-14.78874999999988, 10.890418],
                [-14.789581999999882, 10.889862],
                [-14.7901379999999, 10.88875],
                [-14.7901379999999, 10.888194],
                [-14.790971999999897, 10.88764],
                [-14.792084999999872, 10.886528],
                [-14.792084999999872, 10.886249],
                [-14.793195, 10.885140000000149],
                [-14.794026, 10.885140000000149],
                [-14.794026, 10.884027],
                [-14.793473, 10.883473],
                [-14.793473, 10.882639000000154],
                [-14.794307, 10.882917],
                [-14.794307, 10.883473],
                [-14.794861, 10.884305000000154],
                [-14.795973, 10.884305000000154],
                [-14.795973, 10.884583],
                [-14.797639, 10.884583],
                [-14.79791699999987, 10.884027],
                [-14.799027, 10.883749000000137],
                [-14.799305, 10.883193000000176],
                [-14.799861, 10.883193000000176],
                [-14.800139, 10.882639000000154],
                [-14.800695, 10.882639000000154],
                [-14.801527, 10.881527000000176],
                [-14.802361, 10.881251],
                [-14.802361, 10.880695],
                [-14.802916999999866, 10.880695],
                [-14.803193, 10.879583],
                [-14.803748999999868, 10.879027],
                [-14.804027, 10.878195000000176],
                [-14.804582999999866, 10.877083],
                [-14.80514, 10.876805],
                [-14.80514, 10.876251],
                [-14.805696, 10.875973000000158],
                [-14.805696, 10.873473],
                [-14.805971, 10.873473],
                [-14.805971, 10.872360000000185],
                [-14.806248999999866, 10.872360000000185],
                [-14.806248999999866, 10.870416],
                [-14.805696, 10.870138000000168],
                [-14.805696, 10.869582],
                [-14.804582999999866, 10.868472000000168],
                [-14.804305, 10.867638000000113],
                [-14.804305, 10.866528],
                [-14.804027, 10.866528],
                [-14.804027, 10.865416],
                [-14.804305, 10.865416],
                [-14.804305, 10.863472],
                [-14.804861, 10.863194],
                [-14.804861, 10.862638000000118],
                [-14.805418, 10.86236200000019],
                [-14.805418, 10.86125],
                [-14.805696, 10.86125],
                [-14.805696, 10.86014],
                [-14.805418, 10.859584],
                [-14.804582999999866, 10.858749],
                [-14.803473, 10.858749],
                [-14.802916999999866, 10.858193],
                [-14.802916999999866, 10.85736100000014],
                [-14.802361, 10.856805000000122],
                [-14.802361, 10.855971],
                [-14.803193, 10.855417],
                [-14.803473, 10.854861],
                [-14.803473, 10.853751],
                [-14.803748999999868, 10.852917000000105],
                [-14.802916999999866, 10.852639],
                [-14.801805, 10.851529],
                [-14.801805, 10.850417],
                [-14.802639, 10.849861],
                [-14.802639, 10.846529],
                [-14.802916999999866, 10.845973],
                [-14.803473, 10.845695000000148],
                [-14.803748999999868, 10.844860000000153],
                [-14.803748999999868, 10.842638],
                [-14.803473, 10.842638],
                [-14.803473, 10.838194000000158],
                [-14.804027, 10.837638],
                [-14.804027, 10.834862000000157],
                [-14.804861, 10.833196000000157],
                [-14.805971, 10.83208400000018],
                [-14.805971, 10.831527000000108],
                [-14.806528, 10.830971],
                [-14.807084, 10.829861000000108],
                [-14.807084, 10.827639],
                [-14.806806, 10.827639],
                [-14.806806, 10.825139],
                [-14.807084, 10.824305],
                [-14.807362, 10.824305],
                [-14.807362, 10.822917000000189],
                [-14.806806, 10.822639],
                [-14.806806, 10.822083000000134],
                [-14.806248999999866, 10.821807],
                [-14.804582999999866, 10.820139],
                [-14.800971, 10.820139],
                [-14.800695, 10.820973],
                [-14.799861, 10.821527000000117],
                [-14.796805, 10.821527000000117],
                [-14.79625099999987, 10.822083000000134],
                [-14.794861, 10.822083000000134],
                [-14.793195, 10.822639],
                [-14.792637999999897, 10.822917000000189],
                [-14.792084999999872, 10.823751],
                [-14.792084999999872, 10.825139],
                [-14.791804, 10.825695],
                [-14.7901379999999, 10.825695],
                [-14.7901379999999, 10.825417],
                [-14.78708399999988, 10.825417],
                [-14.786528, 10.825973],
                [-14.785972, 10.825973],
                [-14.785694, 10.826527000000112],
                [-14.784583999999882, 10.827083],
                [-14.783749999999884, 10.827083],
                [-14.783194, 10.826527000000112],
                [-14.782083999999884, 10.826527000000112],
                [-14.782083999999884, 10.824861000000112],
                [-14.781528, 10.824583000000189],
                [-14.781528, 10.824029],
                [-14.780694, 10.823749000000134],
                [-14.780694, 10.823195000000112],
                [-14.78014, 10.823195000000112],
                [-14.779862, 10.822639],
                [-14.777361, 10.822639],
                [-14.776805, 10.822361],
                [-14.775139, 10.822361],
                [-14.774027, 10.822917000000189],
                [-14.774027, 10.823473],
                [-14.773471, 10.824029],
                [-14.770973, 10.824029],
                [-14.769861, 10.825139],
                [-14.76930499999986, 10.825139],
                [-14.76930499999986, 10.825695],
                [-14.768751, 10.826249000000189],
                [-14.768195, 10.826249000000189],
                [-14.76763899999986, 10.827083],
                [-14.767083, 10.827361],
                [-14.767083, 10.827917000000184],
                [-14.766529, 10.828195000000107],
                [-14.76597299999986, 10.829583000000184],
                [-14.76597299999986, 10.830693],
                [-14.765416, 10.830974],
                [-14.764304, 10.83208400000018],
                [-14.764304, 10.83264],
                [-14.76375, 10.83375000000018],
                [-14.763194, 10.834028000000103],
                [-14.763194, 10.83514],
                [-14.762916, 10.835972],
                [-14.762362, 10.836528000000158],
                [-14.761806, 10.838194000000158],
                [-14.761806, 10.839306],
                [-14.761528, 10.839306],
                [-14.761528, 10.840694],
                [-14.761806, 10.840694],
                [-14.761806, 10.842916],
                [-14.760416, 10.842916],
                [-14.759584, 10.842638],
                [-14.759028, 10.84375000000017],
                [-14.759028, 10.844585000000109],
                [-14.75847199999987, 10.845138],
                [-14.75875, 10.845973],
                [-14.75875, 10.847083],
                [-14.758194, 10.846804],
                [-14.757637999999872, 10.845973],
                [-14.757084, 10.845973],
                [-14.756528, 10.846529],
                [-14.755971999999872, 10.847917000000109],
                [-14.755971999999872, 10.849027000000149],
                [-14.755693999999892, 10.849027000000149],
                [-14.755693999999892, 10.850139000000127],
                [-14.755418, 10.850139000000127],
                [-14.755418, 10.85124900000011],
                [-14.75513999999987, 10.85124900000011],
                [-14.75513999999987, 10.852361000000144],
                [-14.754862, 10.853195],
                [-14.754027999999892, 10.853471000000127],
                [-14.753471999999874, 10.852917000000105],
                [-14.752639999999872, 10.852917000000105],
                [-14.752084, 10.852361000000144],
                [-14.751249, 10.852361000000144],
                [-14.750692999999899, 10.851805000000127],
                [-14.749860999999896, 10.851805000000127],
                [-14.749304999999879, 10.85124900000011],
                [-14.748470999999824, 10.85124900000011],
                [-14.747638999999879, 10.850973],
                [-14.747083, 10.850417],
                [-14.746251, 10.850417],
                [-14.745972999999879, 10.850139000000127],
                [-14.746251, 10.849305],
                [-14.746251, 10.847083],
                [-14.745972999999879, 10.847083],
                [-14.745972999999879, 10.844860000000153],
                [-14.746251, 10.844860000000153],
                [-14.746251, 10.843472],
                [-14.745694999999898, 10.842362],
                [-14.745138999999824, 10.842362],
                [-14.744861, 10.841806],
                [-14.743749, 10.841528000000153],
                [-14.743472999999824, 10.840972],
                [-14.742917, 10.840972],
                [-14.742361, 10.840416000000175],
                [-14.741251, 10.839860000000158],
                [-14.740695, 10.839860000000158],
                [-14.739861, 10.838750000000175],
                [-14.739304, 10.838474],
                [-14.738751, 10.837916],
                [-14.738194, 10.836806],
                [-14.738194, 10.832362000000103],
                [-14.737916, 10.832362000000103],
                [-14.737916, 10.830974],
                [-14.738194, 10.830971],
                [-14.738194, 10.829305],
                [-14.738473, 10.829305],
                [-14.738473, 10.827361],
                [-14.738194, 10.827361],
                [-14.738194, 10.825973],
                [-14.737638, 10.825973],
                [-14.737085, 10.824861000000112],
                [-14.737085, 10.824305],
                [-14.736528, 10.824029],
                [-14.735972, 10.823473],
                [-14.734582, 10.823473],
                [-14.734582, 10.824029],
                [-14.734028, 10.824583000000189],
                [-14.733472, 10.825695],
                [-14.733472, 10.827083],
                [-14.733193999999855, 10.827083],
                [-14.733193999999855, 10.828471],
                [-14.732916, 10.828471],
                [-14.732916, 10.829583000000184],
                [-14.73264, 10.829583000000184],
                [-14.73264, 10.830693],
                [-14.731806, 10.831805],
                [-14.73125, 10.831805],
                [-14.730693999999858, 10.832362000000103],
                [-14.730693999999858, 10.832915000000185],
                [-14.729584, 10.833472],
                [-14.729306, 10.834028000000103],
                [-14.728194, 10.834306],
                [-14.728194, 10.834862000000157],
                [-14.727084, 10.834862000000157],
                [-14.727084, 10.83514],
                [-14.723749, 10.83514],
                [-14.723474, 10.834584],
                [-14.722361, 10.834584],
                [-14.721248999999887, 10.83375000000018],
                [-14.720971, 10.833196000000157],
                [-14.720417, 10.833196000000157],
                [-14.720139, 10.832362000000103],
                [-14.719582999999886, 10.83208400000018],
                [-14.719582999999886, 10.831527000000108],
                [-14.718748999999889, 10.829861000000108],
                [-14.717639, 10.828749],
                [-14.717082999999889, 10.827083],
                [-14.717082999999889, 10.826249000000189],
                [-14.717639, 10.825973],
                [-14.717916999999886, 10.826805],
                [-14.718473, 10.827917000000184],
                [-14.719305, 10.829027],
                [-14.720417, 10.829583000000184],
                [-14.720417, 10.830693],
                [-14.720971, 10.83208400000018],
                [-14.722083, 10.83208400000018],
                [-14.722361, 10.83264],
                [-14.723474, 10.83264],
                [-14.723474, 10.832915000000185],
                [-14.725415, 10.832915000000185],
                [-14.725418, 10.83264],
                [-14.726806, 10.83264],
                [-14.726806, 10.832362000000103],
                [-14.72875, 10.832362000000103],
                [-14.730416, 10.831527000000108],
                [-14.730693999999858, 10.830693],
                [-14.730693999999858, 10.829583000000184],
                [-14.73125, 10.829027],
                [-14.73125, 10.826805],
                [-14.731527999999855, 10.826805],
                [-14.731527999999855, 10.825695],
                [-14.731806, 10.825695],
                [-14.731806, 10.824305],
                [-14.73264, 10.822639],
                [-14.73375, 10.821807],
                [-14.734028, 10.821251000000188],
                [-14.734028, 10.819582000000139],
                [-14.734306, 10.819582000000139],
                [-14.734306, 10.817082],
                [-14.734582, 10.817082],
                [-14.734582, 10.815694000000121],
                [-14.735138, 10.815138000000104],
                [-14.735416, 10.814306],
                [-14.735416, 10.812084],
                [-14.736528, 10.810972],
                [-14.737085, 10.810694000000126],
                [-14.737916, 10.810694000000126],
                [-14.738194, 10.810138000000165],
                [-14.739304, 10.810138000000165],
                [-14.739304, 10.809862],
                [-14.740695, 10.809862],
                [-14.740695, 10.809584000000143],
                [-14.741805, 10.809584000000143],
                [-14.742083, 10.809306],
                [-14.742083, 10.808472000000165],
                [-14.741806999999824, 10.808194],
                [-14.742083, 10.80764],
                [-14.742083, 10.806806000000165],
                [-14.741251, 10.806528],
                [-14.741527, 10.805696000000125],
                [-14.742361, 10.805696000000125],
                [-14.742917, 10.805140000000165],
                [-14.743195, 10.805418],
                [-14.743195, 10.806806000000165],
                [-14.743472999999824, 10.806806000000165],
                [-14.743472999999824, 10.807916000000148],
                [-14.743195, 10.80875],
                [-14.742638999999826, 10.809862],
                [-14.741806999999824, 10.810416],
                [-14.740695, 10.810416],
                [-14.740695, 10.810694000000126],
                [-14.739304, 10.810694000000126],
                [-14.738751, 10.811250000000143],
                [-14.738194, 10.811250000000143],
                [-14.737085, 10.811806000000104],
                [-14.737085, 10.81264],
                [-14.736528, 10.813194],
                [-14.736528, 10.814028000000121],
                [-14.735972, 10.814584000000139],
                [-14.735972, 10.815972],
                [-14.735416, 10.816528],
                [-14.735416, 10.818194],
                [-14.735138, 10.818194],
                [-14.735138, 10.819582000000139],
                [-14.734862, 10.820417000000134],
                [-14.735694, 10.820417000000134],
                [-14.735694, 10.820973],
                [-14.73625, 10.821527000000117],
                [-14.737085, 10.821527000000117],
                [-14.737638, 10.822083000000134],
                [-14.738473, 10.822083000000134],
                [-14.739582, 10.822639],
                [-14.739582, 10.823195000000112],
                [-14.740139, 10.823195000000112],
                [-14.740695, 10.824305],
                [-14.740695, 10.825139],
                [-14.741251, 10.825695],
                [-14.741527, 10.826527000000112],
                [-14.741527, 10.827361],
                [-14.742083, 10.828471],
                [-14.742638999999826, 10.828471],
                [-14.742638999999826, 10.829583000000184],
                [-14.742917, 10.830139],
                [-14.743472999999824, 10.830417],
                [-14.743472999999824, 10.830971],
                [-14.744028999999898, 10.831249000000184],
                [-14.744028999999898, 10.831805],
                [-14.744583, 10.83208400000018],
                [-14.744583, 10.83264],
                [-14.745694999999898, 10.833196000000157],
                [-14.746251, 10.834028000000103],
                [-14.747083, 10.834306],
                [-14.748194999999896, 10.834862000000157],
                [-14.749026999999899, 10.83514],
                [-14.750417, 10.83514],
                [-14.750970999999879, 10.834862000000157],
                [-14.751804999999877, 10.834028000000103],
                [-14.751804999999877, 10.833196000000157],
                [-14.752361999999891, 10.83264],
                [-14.752639999999872, 10.831805],
                [-14.752639999999872, 10.830417],
                [-14.752915, 10.830417],
                [-14.752915, 10.829305],
                [-14.75375, 10.829027],
                [-14.75375, 10.828471],
                [-14.753196, 10.827917000000184],
                [-14.753196, 10.825417],
                [-14.753471999999874, 10.825417],
                [-14.753471999999874, 10.823749000000134],
                [-14.75375, 10.823749000000134],
                [-14.75375, 10.822083000000134],
                [-14.754305999999872, 10.822083000000134],
                [-14.755137999999874, 10.821251000000188],
                [-14.75513999999987, 10.820695],
                [-14.755693999999892, 10.820417000000134],
                [-14.755971999999872, 10.819861000000117],
                [-14.755971999999872, 10.815972],
                [-14.755693999999892, 10.815972],
                [-14.755693999999892, 10.814584000000139],
                [-14.75513999999987, 10.814028000000121],
                [-14.755137999999874, 10.812916000000143],
                [-14.754862, 10.812916000000143],
                [-14.754862, 10.810972],
                [-14.755137999999874, 10.810972],
                [-14.75513999999987, 10.805696000000125],
                [-14.755693999999892, 10.805140000000165],
                [-14.755693999999892, 10.80430500000017],
                [-14.75625, 10.804027],
                [-14.75680599999987, 10.802917],
                [-14.75680599999987, 10.801805],
                [-14.755971999999872, 10.801527],
                [-14.755971999999872, 10.800695],
                [-14.757637999999872, 10.800695],
                [-14.75875, 10.800139],
                [-14.75875, 10.799027],
                [-14.75847199999987, 10.798195],
                [-14.759028, 10.797917],
                [-14.759028, 10.796251],
                [-14.75875, 10.795973000000174],
                [-14.757637999999872, 10.795973000000174],
                [-14.757362, 10.795417000000157],
                [-14.757362, 10.794583000000102],
                [-14.757916, 10.794029],
                [-14.758194, 10.793195],
                [-14.758194, 10.792085000000156],
                [-14.759028, 10.792085000000156],
                [-14.759306, 10.791804000000184],
                [-14.759306, 10.790972],
                [-14.760972, 10.78986],
                [-14.760694, 10.789028],
                [-14.760694, 10.787638000000129],
                [-14.760416, 10.787638000000129],
                [-14.760416, 10.784862],
                [-14.76013799999987, 10.784862],
                [-14.76013799999987, 10.782918],
                [-14.760416, 10.782362],
                [-14.760972, 10.782084000000111],
                [-14.761528, 10.780972000000133],
                [-14.761528, 10.779862],
                [-14.760972, 10.779306000000133],
                [-14.76125, 10.778193],
                [-14.761806, 10.777918],
                [-14.762082, 10.777361],
                [-14.762638, 10.777361],
                [-14.762638, 10.776805000000138],
                [-14.76375, 10.775695],
                [-14.764585, 10.775695],
                [-14.765138, 10.775139000000138],
                [-14.765138, 10.77402700000016],
                [-14.766251, 10.77291700000012],
                [-14.766807, 10.77291700000012],
                [-14.76763899999986, 10.77236100000016],
                [-14.76763899999986, 10.771805000000143],
                [-14.768473, 10.770973],
                [-14.770139, 10.770139000000142],
                [-14.770139, 10.769583000000125],
                [-14.770695, 10.769305],
                [-14.771249, 10.768195],
                [-14.771249, 10.767361000000164],
                [-14.772083, 10.765417],
                [-14.773195, 10.765417],
                [-14.774027, 10.764029000000164],
                [-14.774027, 10.763472],
                [-14.774583, 10.762363000000164],
                [-14.775417, 10.762082],
                [-14.775417, 10.760972000000152],
                [-14.775973, 10.76125],
                [-14.77791799999983, 10.76125],
                [-14.778471, 10.761528000000169],
                [-14.779306, 10.761528000000169],
                [-14.779027, 10.760972000000152],
                [-14.779306, 10.759860000000174],
                [-14.779862, 10.759584],
                [-14.780694, 10.759584],
                [-14.78124999999983, 10.759860000000174],
                [-14.781528, 10.760416],
                [-14.782362, 10.760416],
                [-14.782638, 10.760972000000152],
                [-14.784862, 10.762082],
                [-14.785694, 10.762363000000164],
                [-14.786528, 10.762363000000164],
                [-14.786805999999899, 10.762916],
                [-14.788194, 10.763472],
                [-14.789028, 10.763472],
                [-14.789581999999882, 10.762638000000152],
                [-14.789581999999882, 10.759584],
                [-14.789305999999897, 10.759584],
                [-14.789305999999897, 10.758194000000174],
                [-14.789028, 10.758194000000174],
                [-14.789028, 10.75708400000019],
                [-14.7884719999999, 10.75541800000019],
                [-14.787915999999882, 10.754862000000173],
                [-14.787638, 10.754028],
                [-14.787638, 10.75264],
                [-14.787362, 10.75264],
                [-14.787362, 10.750971000000106],
                [-14.78708399999988, 10.750971000000106],
                [-14.78708399999988, 10.749583],
                [-14.786805999999899, 10.749583],
                [-14.786805999999899, 10.748471],
                [-14.786528, 10.748471],
                [-14.786528, 10.747361000000183],
                [-14.786249999999882, 10.747361000000183],
                [-14.786249999999882, 10.746249],
                [-14.785972, 10.745417],
                [-14.785415999999884, 10.74430500000011],
                [-14.784862, 10.744029000000182],
                [-14.78486, 10.743473],
                [-14.783749999999884, 10.742361],
                [-14.783749999999884, 10.741807],
                [-14.782917999999881, 10.741251],
                [-14.782917999999881, 10.740695],
                [-14.782362, 10.740417],
                [-14.782362, 10.739583],
                [-14.782083999999884, 10.738751],
                [-14.781806, 10.738751],
                [-14.781807999999899, 10.737638],
                [-14.781528, 10.737638],
                [-14.781528, 10.735972],
                [-14.77958399999983, 10.732084],
                [-14.780694, 10.731528],
                [-14.780694, 10.730694000000142],
                [-14.779862, 10.729028000000142],
                [-14.779306, 10.72875],
                [-14.779306, 10.728194],
                [-14.778749, 10.727916000000164],
                [-14.778749, 10.727362000000142],
                [-14.778193, 10.727084],
                [-14.778193, 10.726528],
                [-14.777639, 10.726250000000164],
                [-14.777083, 10.724862],
                [-14.777083, 10.724027],
                [-14.775973, 10.723193],
                [-14.775695, 10.722639000000186],
                [-14.775695, 10.721805],
                [-14.775139, 10.720695],
                [-14.774305, 10.720139],
                [-14.773471, 10.719027],
                [-14.773195, 10.718195],
                [-14.772639, 10.718195],
                [-14.772083, 10.71763900000019],
                [-14.772083, 10.716805],
                [-14.771249, 10.716251],
                [-14.769583, 10.714583000000118],
                [-14.769583, 10.714027000000101],
                [-14.769027, 10.713751000000173],
                [-14.767917, 10.712639],
                [-14.767917, 10.7123610000001],
                [-14.767083, 10.711529],
                [-14.76597299999986, 10.711529],
                [-14.765695, 10.710973],
                [-14.76486, 10.710694],
                [-14.76486, 10.710138],
                [-14.764028, 10.709307],
                [-14.762916, 10.709028],
                [-14.762362, 10.707916],
                [-14.762362, 10.70736],
                [-14.761806, 10.70736],
                [-14.761528, 10.706806],
                [-14.75986, 10.706806],
                [-14.759584, 10.705694],
                [-14.75875, 10.704584],
                [-14.758194, 10.704584],
                [-14.757637999999872, 10.704028],
                [-14.757637999999872, 10.703472],
                [-14.757084, 10.703472],
                [-14.75680599999987, 10.702918],
                [-14.75625, 10.702918],
                [-14.755971999999872, 10.702362],
                [-14.755418, 10.702362],
                [-14.754862, 10.70152800000011],
                [-14.754305999999872, 10.70152800000011],
                [-14.753471999999874, 10.700694],
                [-14.751804999999877, 10.69986200000011],
                [-14.751249, 10.69930600000015],
                [-14.750417, 10.699028],
                [-14.749860999999896, 10.698193],
                [-14.749304999999879, 10.698193],
                [-14.748194999999896, 10.697640000000149],
                [-14.747917, 10.697083],
                [-14.747360999999898, 10.696527],
                [-14.745972999999879, 10.695974000000149],
                [-14.744861, 10.695974000000149],
                [-14.744028999999898, 10.695417],
                [-14.743195, 10.695417],
                [-14.742638999999826, 10.695974000000149],
                [-14.742361, 10.696805000000154],
                [-14.741251, 10.696805000000154],
                [-14.741251, 10.697083],
                [-14.738473, 10.697083],
                [-14.738194, 10.697640000000149],
                [-14.73736, 10.697640000000149],
                [-14.736806, 10.698193],
                [-14.736528, 10.69930600000015],
                [-14.735972, 10.69930600000015],
                [-14.735972, 10.69986200000011],
                [-14.736528, 10.700694],
                [-14.737085, 10.700694],
                [-14.73736, 10.70152800000011],
                [-14.738194, 10.701806],
                [-14.739304, 10.701806],
                [-14.739861, 10.702084000000127],
                [-14.739861, 10.703750000000127],
                [-14.740139, 10.704306],
                [-14.740972999999826, 10.704584],
                [-14.740972999999826, 10.706806],
                [-14.740417, 10.70736],
                [-14.740417, 10.711529],
                [-14.740139, 10.711529],
                [-14.740139, 10.712917000000118],
                [-14.740417, 10.712917000000118],
                [-14.740417, 10.714583000000118],
                [-14.740695, 10.714583000000118],
                [-14.740695, 10.715695],
                [-14.740972999999826, 10.715695],
                [-14.740972999999826, 10.716805],
                [-14.741251, 10.71763900000019],
                [-14.742083, 10.71763900000019],
                [-14.742638999999826, 10.717917],
                [-14.742638999999826, 10.718473],
                [-14.741806999999824, 10.71930500000019],
                [-14.740972999999826, 10.721805],
                [-14.740139, 10.723474000000124],
                [-14.740139, 10.724305000000186],
                [-14.739304, 10.724862],
                [-14.738473, 10.725696000000141],
                [-14.738194, 10.725696000000141],
                [-14.73736, 10.726528],
                [-14.73736, 10.727084],
                [-14.736806, 10.727362000000142],
                [-14.736806, 10.728472000000124],
                [-14.737085, 10.729306],
                [-14.737916, 10.729584000000159],
                [-14.738194, 10.730694000000142],
                [-14.737638, 10.730972],
                [-14.737638, 10.734028000000137],
                [-14.73736, 10.734028000000137],
                [-14.73736, 10.735694000000137],
                [-14.737085, 10.735694000000137],
                [-14.737085, 10.736807],
                [-14.736806, 10.737638],
                [-14.73625, 10.738195000000132],
                [-14.735972, 10.739029],
                [-14.735416, 10.740139],
                [-14.735138, 10.74097300000011],
                [-14.735138, 10.742083],
                [-14.735694, 10.743195000000128],
                [-14.73375, 10.743195000000128],
                [-14.73375, 10.743749],
                [-14.732916, 10.74430500000011],
                [-14.730416, 10.74430500000011],
                [-14.730416, 10.744583],
                [-14.729306, 10.744583],
                [-14.729306, 10.745139],
                [-14.728472, 10.745973000000106],
                [-14.727916, 10.746249],
                [-14.72764, 10.746805],
                [-14.726528, 10.747083],
                [-14.72625, 10.747639000000106],
                [-14.724305, 10.748749],
                [-14.724027, 10.749305000000106],
                [-14.724027, 10.750971000000106],
                [-14.724583, 10.751249],
                [-14.724583, 10.751806000000101],
                [-14.725695999999857, 10.752084],
                [-14.727084, 10.753472000000102],
                [-14.727084, 10.75375],
                [-14.728194, 10.754862000000173],
                [-14.72875, 10.754862000000173],
                [-14.729584, 10.755694],
                [-14.730138, 10.755972],
                [-14.730416, 10.756528000000174],
                [-14.730138, 10.756806],
                [-14.729027999999857, 10.75625],
                [-14.728194, 10.75541800000019],
                [-14.727361999999857, 10.75541800000019],
                [-14.727084, 10.754584],
                [-14.726528, 10.754028],
                [-14.725971, 10.754028],
                [-14.724862, 10.753472000000102],
                [-14.724305, 10.752915],
                [-14.724305, 10.752362],
                [-14.723474, 10.751806000000101],
                [-14.722639, 10.751806000000101],
                [-14.722361, 10.752084],
                [-14.722361, 10.752915],
                [-14.721248999999887, 10.753196000000173],
                [-14.720971, 10.75375],
                [-14.719861, 10.754028],
                [-14.719026999999869, 10.755694],
                [-14.718748999999889, 10.756806],
                [-14.717916999999886, 10.757362],
                [-14.716529, 10.758750000000191],
                [-14.716529, 10.759028],
                [-14.715695, 10.759860000000174],
                [-14.715139, 10.759860000000174],
                [-14.714582999999891, 10.760416],
                [-14.714307, 10.760972000000152],
                [-14.713750999999888, 10.76125],
                [-14.713473, 10.762082],
                [-14.71235999999982, 10.762638000000152],
                [-14.712081999999896, 10.76375000000013],
                [-14.71125099999989, 10.764585000000125],
                [-14.710415999999896, 10.765138],
                [-14.710415999999896, 10.765695000000164],
                [-14.709581999999898, 10.766251000000125],
                [-14.709306, 10.767361000000164],
                [-14.708749999999895, 10.767361000000164],
                [-14.708472, 10.767917000000125],
                [-14.707638, 10.767917000000125],
                [-14.706527999999821, 10.769027000000165],
                [-14.706527999999821, 10.769305],
                [-14.702638, 10.771249000000125],
                [-14.701528, 10.771249000000125],
                [-14.699862, 10.771805000000143],
                [-14.699862, 10.772083],
                [-14.698471, 10.772083],
                [-14.697918, 10.772639],
                [-14.695695, 10.772639],
                [-14.695693, 10.77291700000012],
                [-14.694861, 10.773195],
                [-14.69375099999985, 10.773195],
                [-14.692916999999852, 10.773471000000143],
                [-14.692916999999852, 10.773751],
                [-14.690695, 10.773751],
                [-14.690139, 10.774305],
                [-14.688473, 10.775139000000138],
                [-14.687917, 10.775139000000138],
                [-14.687639, 10.775695],
                [-14.686529, 10.775974000000133],
                [-14.686251, 10.776527],
                [-14.685695, 10.776805000000138],
                [-14.684585, 10.777083],
                [-14.684585, 10.777640000000133],
                [-14.68375, 10.777918],
                [-14.684303999999884, 10.779584],
                [-14.684303999999884, 10.781528],
                [-14.683471999999881, 10.781528],
                [-14.682916, 10.78125],
                [-14.682916, 10.779862],
                [-14.68236, 10.779862],
                [-14.68236, 10.779028],
                [-14.680694, 10.779028],
                [-14.680694, 10.779306000000133],
                [-14.679028, 10.780140000000188],
                [-14.678471999999886, 10.780140000000188],
                [-14.678194, 10.780694],
                [-14.677639999999883, 10.780972000000133],
                [-14.676805999999885, 10.780972000000133],
                [-14.67625, 10.781528],
                [-14.675418, 10.781806000000188],
                [-14.674305999999888, 10.782362],
                [-14.673749999999814, 10.782362],
                [-14.672915, 10.782918],
                [-14.672915, 10.783472000000188],
                [-14.672362, 10.783750000000111],
                [-14.671527, 10.783750000000111],
                [-14.670417999999813, 10.784862],
                [-14.669304999999895, 10.785416000000112],
                [-14.669304999999895, 10.78625],
                [-14.668749, 10.78736],
                [-14.668195, 10.787638000000129],
                [-14.667917, 10.788194],
                [-14.667917, 10.789028],
                [-14.667361, 10.789306],
                [-14.666804999999897, 10.790972],
                [-14.666804999999897, 10.792085000000156],
                [-14.666527, 10.792085000000156],
                [-14.665972999999894, 10.793751000000157],
                [-14.665971, 10.794861],
                [-14.665695, 10.795695],
                [-14.665417, 10.795695],
                [-14.665417, 10.796805],
                [-14.665138999999897, 10.796805],
                [-14.665138999999897, 10.798473],
                [-14.664861, 10.798473],
                [-14.664861, 10.799583],
                [-14.664583, 10.799583],
                [-14.664583, 10.800695],
                [-14.6643049999999, 10.800695],
                [-14.6643049999999, 10.801805],
                [-14.664029, 10.80263900000017],
                [-14.663749, 10.80263900000017],
                [-14.662917, 10.80430500000017],
                [-14.662917, 10.804862],
                [-14.662083, 10.805696000000125],
                [-14.661804999999845, 10.806250000000148],
                [-14.661251, 10.806528],
                [-14.661251, 10.807084],
                [-14.660417, 10.807362000000126],
                [-14.660417, 10.807916000000148],
                [-14.659029, 10.809306],
                [-14.658472999999844, 10.809584000000143],
                [-14.658195, 10.810138000000165],
                [-14.658195, 10.811806000000104],
                [-14.658751, 10.812360000000126],
                [-14.659304, 10.812360000000126],
                [-14.659583, 10.812916000000143],
                [-14.660138999999845, 10.812916000000143],
                [-14.660695, 10.813472000000104],
                [-14.660972999999899, 10.814306],
                [-14.661804999999845, 10.814306],
                [-14.662083, 10.815138000000104],
                [-14.662917, 10.815138000000104],
                [-14.663749, 10.815694000000121],
                [-14.663749, 10.816250000000139],
                [-14.664583, 10.817082],
                [-14.665138999999897, 10.817082],
                [-14.665417, 10.817638],
                [-14.666527, 10.817082],
                [-14.667361, 10.817082],
                [-14.667361, 10.815416],
                [-14.668195, 10.815694000000121],
                [-14.668195, 10.817638],
                [-14.667638999999895, 10.818751000000134],
                [-14.666527, 10.818751000000134],
                [-14.665971, 10.819029],
                [-14.665138999999897, 10.817916000000139],
                [-14.664029, 10.817638],
                [-14.663749, 10.817082],
                [-14.6626389999999, 10.816528],
                [-14.661804999999845, 10.816528],
                [-14.660972999999899, 10.815694000000121],
                [-14.660972999999899, 10.815416],
                [-14.659583, 10.815416],
                [-14.658472999999844, 10.814584000000139],
                [-14.65736, 10.813472000000104],
                [-14.656529, 10.812084],
                [-14.655972, 10.811528],
                [-14.654306, 10.812084],
                [-14.653194, 10.81264],
                [-14.65125, 10.81264],
                [-14.650138, 10.813194],
                [-14.650138, 10.81375],
                [-14.649584, 10.814028000000121],
                [-14.649584, 10.815694000000121],
                [-14.649306, 10.815694000000121],
                [-14.649306, 10.822639],
                [-14.649028, 10.822639],
                [-14.649028, 10.824305],
                [-14.64875, 10.825139],
                [-14.647918, 10.825695],
                [-14.647918, 10.828471],
                [-14.647084, 10.828471],
                [-14.647084, 10.827639],
                [-14.646527999999876, 10.827083],
                [-14.646527999999876, 10.825973],
                [-14.647084, 10.825417],
                [-14.647084, 10.824305],
                [-14.647362, 10.823749000000134],
                [-14.647918, 10.823473],
                [-14.648193999999876, 10.822917000000189],
                [-14.648193999999876, 10.821527000000117],
                [-14.648472, 10.821527000000117],
                [-14.648472, 10.819861000000117],
                [-14.648193999999876, 10.819861000000117],
                [-14.648193999999876, 10.818194],
                [-14.647918, 10.818194],
                [-14.647918, 10.815694000000121],
                [-14.647362, 10.815138000000104],
                [-14.647362, 10.814028000000121],
                [-14.645415, 10.814028000000121],
                [-14.645415, 10.81375],
                [-14.643192999999883, 10.81375],
                [-14.641526999999883, 10.813194],
                [-14.641249, 10.81264],
                [-14.640417, 10.81264],
                [-14.640417, 10.812084],
                [-14.639860999999883, 10.811806000000104],
                [-14.639026999999885, 10.811806000000104],
                [-14.638749, 10.811250000000143],
                [-14.637639, 10.810972],
                [-14.637083, 10.810694000000126],
                [-14.636528999999882, 10.809862],
                [-14.635694999999885, 10.809862],
                [-14.635417, 10.809306],
                [-14.634860999999887, 10.809306],
                [-14.634307, 10.80875],
                [-14.633473, 10.80875],
                [-14.632917, 10.808194],
                [-14.631528999999887, 10.808194],
                [-14.631528999999887, 10.807916000000148],
                [-14.629582, 10.807916000000148],
                [-14.629582, 10.80764],
                [-14.627916, 10.80764],
                [-14.627916, 10.807362000000126],
                [-14.62625, 10.807362000000126],
                [-14.62625, 10.807084],
                [-14.62402799999984, 10.807084],
                [-14.62402799999984, 10.807362000000126],
                [-14.62014, 10.807362000000126],
                [-14.619861999999841, 10.807916000000148],
                [-14.619306, 10.808194],
                [-14.618195999999841, 10.808194],
                [-14.61764, 10.808472000000165],
                [-14.617362, 10.809028000000126],
                [-14.616805, 10.809028000000126],
                [-14.616527, 10.809584000000143],
                [-14.615974, 10.809862],
                [-14.615974, 10.810416],
                [-14.615417, 10.810416],
                [-14.615417, 10.810972],
                [-14.614861, 10.810972],
                [-14.614583, 10.812084],
                [-14.614027, 10.812084],
                [-14.614027, 10.81264],
                [-14.613471, 10.813194],
                [-14.613471, 10.821251000000188],
                [-14.613751, 10.821251000000188],
                [-14.613751, 10.830417],
                [-14.614027, 10.831249000000184],
                [-14.614305, 10.831249000000184],
                [-14.614305, 10.832915000000185],
                [-14.614861, 10.833472],
                [-14.614861, 10.834306],
                [-14.615974, 10.83625],
                [-14.616527, 10.83625],
                [-14.616805, 10.837362],
                [-14.617918, 10.837638],
                [-14.618195999999841, 10.838194000000158],
                [-14.619306, 10.838194000000158],
                [-14.619584, 10.838750000000175],
                [-14.620694, 10.838750000000175],
                [-14.620694, 10.839028],
                [-14.622361999999839, 10.839028],
                [-14.622361999999839, 10.839306],
                [-14.62514, 10.839306],
                [-14.62514, 10.839584],
                [-14.627084, 10.839584],
                [-14.627084, 10.839860000000158],
                [-14.629307, 10.839860000000158],
                [-14.629307, 10.840138],
                [-14.630973, 10.840138],
                [-14.631804, 10.840416000000175],
                [-14.632917, 10.840972],
                [-14.633751, 10.840972],
                [-14.634307, 10.84125],
                [-14.634583, 10.841806],
                [-14.635139, 10.841806],
                [-14.635417, 10.842362],
                [-14.636249, 10.842916],
                [-14.636251, 10.843472],
                [-14.637083, 10.844028],
                [-14.638194999999882, 10.846251000000109],
                [-14.638194999999882, 10.847361000000149],
                [-14.638473, 10.847361000000149],
                [-14.638473, 10.848473000000126],
                [-14.638749, 10.848473000000126],
                [-14.638749, 10.849861],
                [-14.639026999999885, 10.849861],
                [-14.639026999999885, 10.855139000000122],
                [-14.638749, 10.855139000000122],
                [-14.638749, 10.85902700000014],
                [-14.638473, 10.85902700000014],
                [-14.638473, 10.860415],
                [-14.638194999999882, 10.860415],
                [-14.637917, 10.86125],
                [-14.637917, 10.86236200000019],
                [-14.637639, 10.86236200000019],
                [-14.637639, 10.863472],
                [-14.637360999999885, 10.863472],
                [-14.637360999999885, 10.864862],
                [-14.636805, 10.864862],
                [-14.636251, 10.866528],
                [-14.636249, 10.867638000000113],
                [-14.635973, 10.868472000000168],
                [-14.635417, 10.86875],
                [-14.635417, 10.869306000000108],
                [-14.634307, 10.870138000000168],
                [-14.634307, 10.870694000000185],
                [-14.633751, 10.87125],
                [-14.632639, 10.871804000000168],
                [-14.626806, 10.871804000000168],
                [-14.626806, 10.87152900000018],
                [-14.62375, 10.87152900000018],
                [-14.621527999999842, 10.872638000000109],
                [-14.61875, 10.875417],
                [-14.618195999999841, 10.875417],
                [-14.615693, 10.877917],
                [-14.615139, 10.877917],
                [-14.614861, 10.878473],
                [-14.614305, 10.878473],
                [-14.613471, 10.879585],
                [-14.612361, 10.879861000000176],
                [-14.61208299999987, 10.880417000000136],
                [-14.611527, 10.880695],
                [-14.61041699999987, 10.880695],
                [-14.61041699999987, 10.880971000000159],
                [-14.608473, 10.880971000000159],
                [-14.608473, 10.880695],
                [-14.607082999999875, 10.880695],
                [-14.606250999999872, 10.880417000000136],
                [-14.605139, 10.879861000000176],
                [-14.604584999999872, 10.879027],
                [-14.603473, 10.878473],
                [-14.60291599999988, 10.878473],
                [-14.602638, 10.877917],
                [-14.601528, 10.877917],
                [-14.60124999999988, 10.877639000000158],
                [-14.60124999999988, 10.876805],
                [-14.600694, 10.876251],
                [-14.600138, 10.876251],
                [-14.59958399999988, 10.875695],
                [-14.59958399999988, 10.875139],
                [-14.598749999999882, 10.874307000000158],
                [-14.598194, 10.872638000000109],
                [-14.59764, 10.872360000000185],
                [-14.59764, 10.871804000000168],
                [-14.596528, 10.870972000000108],
                [-14.595694, 10.869862],
                [-14.594862, 10.869582],
                [-14.591806, 10.869582],
                [-14.591248999999834, 10.869028000000185],
                [-14.590417999999886, 10.869028000000185],
                [-14.589862, 10.868194],
                [-14.588748999999837, 10.867638000000113],
                [-14.587916999999834, 10.866806000000167],
                [-14.587082999999836, 10.86625],
                [-14.587082999999836, 10.86569400000019],
                [-14.586527, 10.865416],
                [-14.586527, 10.864584],
                [-14.585971, 10.864306000000113],
                [-14.585695, 10.86375],
                [-14.585695, 10.862638000000118],
                [-14.584861, 10.861806],
                [-14.584583, 10.861806],
                [-14.584583, 10.860696000000189],
                [-14.584029, 10.860415],
                [-14.584027, 10.859862],
                [-14.583473, 10.859584],
                [-14.583195, 10.858749],
                [-14.583195, 10.857639],
                [-14.582361, 10.85736100000014],
                [-14.581527, 10.855971],
                [-14.581527, 10.854861],
                [-14.580971, 10.853751],
                [-14.580417, 10.853471000000127],
                [-14.580417, 10.852917000000105],
                [-14.579029, 10.851529],
                [-14.578473, 10.851529],
                [-14.578195, 10.852083],
                [-14.577637999999865, 10.852083],
                [-14.577637999999865, 10.854305],
                [-14.57736, 10.854305],
                [-14.57736, 10.855417],
                [-14.577085, 10.856249000000105],
                [-14.576529, 10.856527],
                [-14.576251, 10.857639],
                [-14.575694, 10.858193],
                [-14.575694, 10.858749],
                [-14.575137999999868, 10.85902700000014],
                [-14.575137999999868, 10.859584],
                [-14.574582, 10.859862],
                [-14.574582, 10.860415],
                [-14.57263799999987, 10.86236200000019],
                [-14.571805999999867, 10.862638000000118],
                [-14.57097199999987, 10.862638000000118],
                [-14.570694, 10.863194],
                [-14.569584, 10.86375],
                [-14.567362, 10.86375],
                [-14.567362, 10.863472],
                [-14.56625, 10.863472],
                [-14.56625, 10.863194],
                [-14.565415, 10.862918],
                [-14.564028, 10.862918],
                [-14.563470999999879, 10.86236200000019],
                [-14.562639999999874, 10.86236200000019],
                [-14.562360999999896, 10.861806],
                [-14.561804999999879, 10.861806],
                [-14.560694999999896, 10.86125],
                [-14.560694999999896, 10.860972000000118],
                [-14.559304999999881, 10.860972000000118],
                [-14.559304999999881, 10.86125],
                [-14.558194999999898, 10.861806],
                [-14.558194999999898, 10.86236200000019],
                [-14.557638999999881, 10.862638000000118],
                [-14.557638999999881, 10.863194],
                [-14.557083, 10.86402800000019],
                [-14.556528999999898, 10.86402800000019],
                [-14.556804999999883, 10.863194],
                [-14.556804999999883, 10.861806],
                [-14.557083, 10.861806],
                [-14.557083, 10.86014],
                [-14.556804999999883, 10.86014],
                [-14.556804999999883, 10.85902700000014],
                [-14.556528999999898, 10.858471000000122],
                [-14.55597299999988, 10.858193],
                [-14.55597299999988, 10.857639],
                [-14.554861, 10.856805000000122],
                [-14.554861, 10.856249000000105],
                [-14.553751, 10.855139000000122],
                [-14.553751, 10.854583000000105],
                [-14.553194998999913, 10.853472709000187],
                [-14.552916525999876, 10.85347080300005],
                [-14.552916525999876, 10.852083206000032],
                [-14.552639007999915, 10.850973129000124],
                [-14.552360534999877, 10.850417138000068],
                [-14.551803588999974, 10.850138665000031],
                [-14.551803588999974, 10.84958267200011],
                [-14.551251410999953, 10.84930515300016],
                [-14.551251410999953, 10.84763908500014],
                [-14.551803588999974, 10.846529008000061],
                [-14.552916525999876, 10.84597301500014],
                [-14.55375099299988, 10.845694542000103],
                [-14.55486106799998, 10.845694542000103],
                [-14.55486106799998, 10.845416069000066],
                [-14.556248664999885, 10.845416069000066],
                [-14.556248664999885, 10.84513759600003],
                [-14.558195113999886, 10.84513759600003],
                [-14.558195113999886, 10.844860077000078],
                [-14.559305190999908, 10.844860077000078],
                [-14.560971258999928, 10.84430694700012],
                [-14.562640189999968, 10.843471527000133],
                [-14.562917709999965, 10.842638015999967],
                [-14.563470840999969, 10.842362402999981],
                [-14.563470840999969, 10.840694427000187],
                [-14.562360763999948, 10.840140344000076],
                [-14.561527251999905, 10.840138436000132],
                [-14.560417174999884, 10.83958435000011],
                [-14.559861183999828, 10.839028359],
                [-14.558195113999886, 10.838472366000076],
                [-14.557360649999964, 10.838474272999974],
                [-14.556804655999883, 10.83791637500019],
                [-14.5559730519999, 10.837637902000154],
                [-14.555138587999977, 10.837637902000154],
                [-14.55347347299994, 10.837083816000131],
                [-14.552916525999876, 10.836806298000113],
                [-14.552360534999877, 10.836250304000089],
                [-14.551803588999974, 10.836250304000089],
                [-14.550695419999954, 10.835417748000111],
                [-14.55013847399988, 10.835417748000111],
                [-14.549860000999843, 10.834861756000123],
                [-14.549306869999896, 10.834861756000123],
                [-14.549029348999966, 10.834305763000032],
                [-14.54847240499987, 10.834305763000032],
                [-14.54791641199995, 10.833472252000092],
                [-14.547362327999963, 10.833195686000124],
                [-14.547362327999963, 10.832639695000069],
                [-14.546806334999928, 10.832639695000069],
                [-14.546250343999873, 10.83180522900011],
                [-14.545694350999952, 10.831249237000122],
                [-14.544859885999927, 10.831249237000122],
                [-14.544859885999927, 10.830139159999987],
                [-14.54458427399993, 10.829583169000102],
                [-14.543472288999965, 10.828471184000023],
                [-14.543193817999907, 10.828471184000023],
                [-14.542916297999966, 10.827638626000066],
                [-14.542916297999966, 10.822916984000187],
                [-14.542637824999929, 10.822916984000187],
                [-14.542637824999929, 10.818472863000125],
                [-14.542362213999866, 10.818472863000125],
                [-14.542362213999866, 10.81291580300001],
                [-14.542916297999966, 10.812359810000146],
                [-14.543472288999965, 10.811249733000011],
                [-14.543472288999965, 10.810137748000102],
                [-14.543749808999905, 10.810137748000102],
                [-14.543749808999905, 10.809027671000024],
                [-14.544028281999942, 10.809027671000024],
                [-14.544028281999942, 10.806805612000119],
                [-14.544305800999894, 10.806805612000119],
                [-14.544305800999894, 10.805695534999984],
                [-14.54458427399993, 10.805695534999984],
                [-14.54458427399993, 10.804582595000113],
                [-14.544859885999927, 10.804582595000113],
                [-14.544859885999927, 10.803193093000061],
                [-14.545140266999965, 10.803193093000061],
                [-14.545140266999965, 10.802083016000154],
                [-14.545415877999915, 10.801251412000113],
                [-14.545694350999952, 10.801248551000128],
                [-14.545694350999952, 10.800139427000033],
                [-14.545971870999836, 10.799304963000054],
                [-14.546527861999891, 10.79874897000019],
                [-14.546527861999891, 10.797917366000092],
                [-14.547081947999914, 10.797361374000104],
                [-14.547081947999914, 10.796251297000026],
                [-14.547362327999963, 10.796251297000026],
                [-14.547362327999963, 10.79513931299999],
                [-14.547637938999912, 10.79513931299999],
                [-14.547637938999912, 10.79402732800014],
                [-14.54791641199995, 10.794029236000085],
                [-14.54791641199995, 10.792914391000124],
                [-14.54847240499987, 10.791251182999986],
                [-14.549029348999966, 10.790694237000139],
                [-14.549306869999896, 10.78985977300016],
                [-14.549306869999896, 10.788749696000082],
                [-14.549581527999976, 10.788749696000082],
                [-14.549581527999976, 10.787637711000173],
                [-14.549860000999843, 10.787637711000173],
                [-14.549860000999843, 10.786527634000095],
                [-14.55013847399988, 10.785694123000098],
                [-14.550695419999954, 10.785140037000076],
                [-14.550695419999954, 10.784028054000032],
                [-14.550972937999973, 10.784028054000032],
                [-14.550972937999973, 10.782637597000189],
                [-14.550416946999917, 10.781805993000091],
                [-14.549860000999843, 10.781805993000091],
                [-14.548749923999935, 10.780694008000012],
                [-14.54847240499987, 10.780139923000092],
                [-14.54847240499987, 10.778470994000145],
                [-14.548193931999833, 10.777917863000084],
                [-14.546806334999928, 10.776527404999968],
                [-14.546250343999873, 10.776527404999968],
                [-14.545140266999965, 10.77541732800006],
                [-14.544028281999942, 10.77541732800006],
                [-14.544028281999942, 10.775138855000023],
                [-14.542362213999866, 10.775138855000023],
                [-14.542362213999866, 10.77541732800006],
                [-14.539584158999958, 10.77541732800006],
                [-14.538749694999922, 10.775694847000011],
                [-14.538749694999922, 10.775974274000134],
                [-14.5376396179999, 10.775974274000134],
                [-14.5376396179999, 10.776248932000101],
                [-14.536526680999941, 10.776248932000101],
                [-14.53597354999988, 10.776527404999968],
                [-14.535417555999913, 10.77736091700001],
                [-14.53374862599992, 10.77819347400009],
                [-14.53374862599992, 10.778749467000182],
                [-14.532917021999936, 10.779583931000104],
                [-14.53236103099988, 10.779862404000141],
                [-14.53236103099988, 10.780415534999975],
                [-14.531805038999948, 10.780694008000012],
                [-14.531526565999911, 10.78125],
                [-14.531526565999911, 10.782637597000189],
                [-14.53124904599997, 10.782637597000189],
                [-14.53124904599997, 10.783749581000166],
                [-14.530973434999908, 10.784584046000191],
                [-14.53069496199987, 10.784584046000191],
                [-14.53069496199987, 10.786806107000132],
                [-14.530973434999908, 10.787360191000118],
                [-14.528472900999873, 10.787360191000118],
                [-14.529028891999928, 10.786806107000132],
                [-14.529028891999928, 10.785971641000174],
                [-14.52958297799995, 10.784305573000154],
                [-14.529861450999931, 10.784305573000154],
                [-14.529860999999869, 10.782084000000111],
                [-14.530139, 10.78125],
                [-14.530695, 10.780972000000133],
                [-14.530695, 10.779862],
                [-14.530973, 10.779306000000133],
                [-14.532083, 10.777918],
                [-14.532639, 10.777918],
                [-14.533471, 10.777083],
                [-14.533749, 10.776527],
                [-14.534305, 10.775974000000133],
                [-14.535139, 10.775695],
                [-14.535974, 10.775695],
                [-14.536527, 10.77458300000012],
                [-14.53736199999986, 10.77458300000012],
                [-14.538196, 10.774305],
                [-14.538196, 10.77402700000016],
                [-14.54486, 10.77402700000016],
                [-14.54486, 10.774305],
                [-14.545972, 10.774305],
                [-14.546806, 10.77458300000012],
                [-14.547362, 10.775417],
                [-14.548194, 10.775417],
                [-14.548472, 10.775974000000133],
                [-14.549306999999828, 10.776527],
                [-14.549306999999828, 10.777083],
                [-14.54986, 10.777640000000133],
                [-14.550417, 10.778749],
                [-14.551529, 10.779584],
                [-14.551529, 10.780140000000188],
                [-14.552083, 10.780416000000116],
                [-14.552917, 10.780416000000116],
                [-14.553472999999883, 10.780140000000188],
                [-14.554027, 10.779584],
                [-14.554027, 10.776805000000138],
                [-14.554304999999829, 10.776805000000138],
                [-14.55430699999988, 10.775417],
                [-14.554583, 10.775417],
                [-14.554583, 10.773751],
                [-14.554861, 10.773751],
                [-14.554861, 10.77236100000016],
                [-14.555138999999883, 10.77236100000016],
                [-14.555138999999883, 10.771249000000125],
                [-14.555695, 10.769583000000125],
                [-14.55597299999988, 10.769583000000125],
                [-14.55597299999988, 10.766805000000147],
                [-14.556528999999898, 10.766251000000125],
                [-14.556804999999883, 10.765138],
                [-14.557361, 10.764585000000125],
                [-14.557361, 10.76375000000013],
                [-14.557917, 10.762638000000152],
                [-14.558472999999879, 10.762638000000152],
                [-14.558472999999879, 10.762082],
                [-14.559027, 10.761528000000169],
                [-14.559304999999881, 10.760694],
                [-14.559304999999881, 10.759860000000174],
                [-14.560138999999879, 10.757362],
                [-14.560417, 10.757362],
                [-14.560417, 10.755694],
                [-14.561249, 10.75541800000019],
                [-14.561526999999899, 10.754862000000173],
                [-14.561526999999899, 10.754028],
                [-14.562083, 10.753472000000102],
                [-14.562360999999896, 10.75264],
                [-14.562360999999896, 10.751806000000101],
                [-14.562918, 10.751249],
                [-14.562918, 10.750418],
                [-14.563749, 10.748749],
                [-14.564028, 10.748749],
                [-14.564862, 10.747083],
                [-14.564862, 10.746527000000128],
                [-14.565696, 10.745417],
                [-14.566528, 10.744583],
                [-14.566805999999872, 10.744029000000182],
                [-14.567918, 10.743195000000128],
                [-14.567918, 10.74263900000011],
                [-14.56875, 10.741807],
                [-14.56930599999987, 10.741527000000133],
                [-14.56930599999987, 10.74097300000011],
                [-14.570137999999872, 10.740417],
                [-14.570694, 10.739861000000133],
                [-14.57097199999987, 10.738751],
                [-14.571528, 10.738473],
                [-14.571528, 10.737916000000155],
                [-14.572084, 10.737638],
                [-14.572084, 10.737082],
                [-14.57263799999987, 10.736807],
                [-14.57263799999987, 10.736250000000155],
                [-14.573194, 10.735972],
                [-14.573194, 10.735416],
                [-14.57375, 10.734306],
                [-14.574028, 10.734306],
                [-14.574863, 10.73347200000012],
                [-14.574863, 10.73291600000016],
                [-14.575694, 10.732360000000142],
                [-14.575694, 10.73180600000012],
                [-14.576251, 10.731528],
                [-14.576529, 10.730416],
                [-14.577085, 10.730138000000125],
                [-14.577085, 10.729584000000159],
                [-14.577917, 10.729028000000142],
                [-14.580695, 10.726250000000164],
                [-14.581527, 10.724862],
                [-14.583195, 10.723193],
                [-14.583473, 10.722639000000186],
                [-14.584027, 10.722361],
                [-14.584029, 10.721527],
                [-14.584861, 10.720695],
                [-14.585416999999836, 10.720695],
                [-14.586250999999834, 10.719861],
                [-14.586805, 10.719583],
                [-14.587082999999836, 10.719027],
                [-14.587639, 10.719027],
                [-14.588748999999837, 10.717917],
                [-14.590417999999886, 10.717083000000173],
                [-14.590417999999886, 10.716805],
                [-14.59153, 10.716805],
                [-14.591806, 10.716527],
                [-14.591806, 10.714027000000101],
                [-14.592083999999886, 10.714027000000101],
                [-14.593749999999886, 10.7123610000001],
                [-14.594306, 10.711529],
                [-14.594862, 10.711529],
                [-14.595972, 10.710416000000123],
                [-14.596249999999884, 10.709860000000106],
                [-14.597362, 10.708750000000123],
                [-14.599028, 10.708750000000123],
                [-14.599028, 10.708472],
                [-14.600138, 10.708472],
                [-14.600972, 10.708194000000105],
                [-14.600972, 10.707916],
                [-14.602081999999882, 10.707916],
                [-14.60291599999988, 10.707638],
                [-14.605695, 10.70625],
                [-14.606527, 10.705972],
                [-14.606807, 10.705416000000127],
                [-14.607361, 10.705416000000127],
                [-14.607639, 10.70486000000011],
                [-14.607639, 10.700416000000132],
                [-14.607916999999873, 10.700416000000132],
                [-14.607916999999873, 10.69930600000015],
                [-14.608195, 10.69930600000015],
                [-14.608195, 10.698193],
                [-14.608473, 10.698193],
                [-14.608473, 10.694583000000137],
                [-14.60875099999987, 10.694583000000137],
                [-14.60875099999987, 10.692361000000119],
                [-14.609027, 10.692361000000119],
                [-14.609027, 10.691249000000141],
                [-14.609582999999873, 10.690695000000119],
                [-14.609861, 10.689861],
                [-14.609861, 10.686529],
                [-14.610139, 10.686529],
                [-14.610139, 10.684304000000168],
                [-14.61041699999987, 10.684304000000168],
                [-14.61041699999987, 10.68236],
                [-14.610695, 10.68236],
                [-14.610695, 10.680416],
                [-14.610973, 10.680416],
                [-14.610973, 10.678472000000113],
                [-14.611248999999873, 10.677638000000172],
                [-14.61041699999987, 10.677084],
                [-14.610139, 10.67652800000019],
                [-14.610139, 10.67486200000019],
                [-14.61041699999987, 10.67486200000019],
                [-14.61041699999987, 10.672084],
                [-14.610973, 10.671528],
                [-14.610695, 10.670693],
                [-14.610695, 10.669027],
                [-14.61041699999987, 10.669027],
                [-14.61041699999987, 10.667361],
                [-14.610973, 10.666805],
                [-14.610973, 10.663195000000144],
                [-14.611248999999873, 10.663195000000144],
                [-14.611248999999873, 10.661805],
                [-14.611527, 10.661805],
                [-14.611527, 10.660695],
                [-14.611805, 10.660695],
                [-14.611805, 10.659583],
                [-14.612361, 10.658473],
                [-14.612917, 10.657917],
                [-14.613195, 10.657360000000153],
                [-14.61374899999987, 10.657082],
                [-14.614027, 10.656529000000148],
                [-14.614027, 10.655694000000153],
                [-14.614583, 10.655138000000136],
                [-14.614861, 10.654306],
                [-14.614861, 10.653194],
                [-14.615139, 10.653194],
                [-14.615139, 10.651806000000136],
                [-14.615417, 10.651250000000175],
                [-14.615974, 10.650972],
                [-14.616249, 10.650416],
                [-14.617362, 10.650138],
                [-14.617362, 10.649306],
                [-14.616805, 10.649306],
                [-14.616805, 10.64875],
                [-14.616249, 10.64875],
                [-14.615974, 10.64791600000018],
                [-14.615139, 10.64791600000018],
                [-14.614583, 10.64764],
                [-14.614861, 10.647084],
                [-14.614861, 10.645696000000157],
                [-14.614583, 10.64514],
                [-14.615417, 10.644862],
                [-14.615974, 10.645415000000185],
                [-14.615974, 10.646528],
                [-14.616249, 10.646806],
                [-14.61764, 10.646806],
                [-14.61764, 10.64625000000018],
                [-14.619028, 10.645415000000185],
                [-14.619028, 10.644862],
                [-14.619861999999841, 10.644028000000162],
                [-14.620694, 10.643749000000184],
                [-14.621806, 10.643749000000184],
                [-14.622638, 10.643471],
                [-14.622638, 10.643193000000167],
                [-14.62375, 10.643193000000167],
                [-14.62375, 10.64291800000018],
                [-14.62569399999984, 10.64291800000018],
                [-14.62569399999984, 10.64264],
                [-14.627084, 10.64264],
                [-14.627361999999891, 10.641805],
                [-14.62514, 10.641805],
                [-14.62514, 10.642083000000184],
                [-14.623472, 10.642083000000184],
                [-14.623472, 10.642361000000108],
                [-14.619861999999841, 10.642361000000108],
                [-14.619028, 10.641805],
                [-14.61875, 10.641249],
                [-14.61875, 10.637917],
                [-14.619028, 10.637917],
                [-14.619028, 10.636805],
                [-14.619306, 10.635973],
                [-14.619861999999841, 10.634861],
                [-14.620416, 10.634583],
                [-14.620416, 10.634027000000117],
                [-14.621806, 10.632639],
                [-14.621806, 10.631529],
                [-14.622084, 10.631529],
                [-14.622084, 10.630417],
                [-14.622361999999839, 10.630417],
                [-14.622361999999839, 10.629307],
                [-14.621806, 10.628472],
                [-14.62125, 10.628472],
                [-14.62125, 10.627916],
                [-14.620416, 10.62736],
                [-14.620416, 10.626528000000121],
                [-14.622361999999839, 10.626528000000121],
                [-14.622916, 10.625694],
                [-14.624306, 10.624306000000104],
                [-14.624306, 10.622916],
                [-14.62375, 10.622638000000109],
                [-14.62375, 10.620972000000108],
                [-14.623193999999842, 10.620694],
                [-14.623193999999842, 10.619862000000126],
                [-14.622084, 10.619584],
                [-14.622084, 10.618750000000148],
                [-14.622916, 10.617640000000108],
                [-14.622916, 10.616527],
                [-14.622638, 10.616527],
                [-14.622638, 10.614583000000152],
                [-14.622361999999839, 10.614583000000152],
                [-14.622361999999839, 10.612361],
                [-14.622084, 10.612361],
                [-14.622084, 10.610417],
                [-14.621806, 10.610417],
                [-14.621806, 10.608473000000174],
                [-14.62125, 10.607361],
                [-14.620972, 10.607361],
                [-14.620972, 10.605139000000179],
                [-14.620416, 10.604861],
                [-14.620416, 10.604029],
                [-14.619584, 10.603751000000102],
                [-14.619584, 10.603195],
                [-14.619028, 10.602916000000107],
                [-14.619028, 10.60236],
                [-14.618471, 10.602082],
                [-14.618471, 10.601250000000107],
                [-14.61764, 10.600138],
                [-14.616527, 10.599028],
                [-14.616249, 10.598472],
                [-14.615693, 10.598194],
                [-14.615139, 10.597638000000188],
                [-14.615139, 10.597084],
                [-14.614583, 10.596528],
                [-14.613751, 10.595972000000188],
                [-14.61374899999987, 10.595418],
                [-14.612917, 10.594862],
                [-14.611527, 10.593472000000133],
                [-14.611527, 10.593194],
                [-14.61041699999987, 10.593194],
                [-14.61041699999987, 10.592916000000116],
                [-14.60875099999987, 10.592916000000116],
                [-14.60875099999987, 10.592640000000188],
                [-14.606529, 10.592640000000188],
                [-14.605695, 10.592916000000116],
                [-14.605695, 10.593194],
                [-14.604029, 10.593194],
                [-14.604029, 10.593472000000133],
                [-14.60291599999988, 10.593472000000133],
                [-14.60291599999988, 10.59375],
                [-14.601806, 10.59375],
                [-14.601806, 10.594028],
                [-14.59986, 10.594028],
                [-14.598749999999882, 10.59375],
                [-14.59764, 10.59375],
                [-14.597362, 10.594306000000188],
                [-14.596806, 10.594584000000111],
                [-14.595417999999881, 10.594584000000111],
                [-14.594583999999884, 10.594862],
                [-14.594583999999884, 10.595418],
                [-14.592083999999886, 10.595418],
                [-14.592083999999886, 10.595694],
                [-14.590971, 10.595694],
                [-14.590693, 10.596250000000111],
                [-14.59014, 10.596250000000111],
                [-14.589862, 10.59736],
                [-14.590417999999886, 10.597638000000188],
                [-14.590417999999886, 10.598194],
                [-14.590971, 10.59875],
                [-14.591806, 10.600416],
                [-14.592083999999886, 10.601528],
                [-14.59264, 10.601807000000179],
                [-14.59264, 10.60236],
                [-14.593194, 10.602638000000184],
                [-14.593194, 10.603195],
                [-14.593749999999886, 10.603473000000179],
                [-14.594583999999884, 10.605139000000179],
                [-14.594583999999884, 10.608473000000174],
                [-14.594306, 10.608473000000174],
                [-14.594306, 10.609583000000157],
                [-14.594028, 10.609583000000157],
                [-14.593472, 10.610695],
                [-14.592915999999889, 10.611249000000157],
                [-14.59264, 10.611805000000174],
                [-14.59264, 10.612917000000152],
                [-14.592362, 10.612917000000152],
                [-14.592362, 10.61513900000017],
                [-14.592083999999886, 10.61513900000017],
                [-14.592083999999886, 10.616249000000153],
                [-14.591806, 10.617084000000148],
                [-14.591248999999834, 10.617362],
                [-14.590693, 10.618472],
                [-14.590693, 10.619028],
                [-14.59014, 10.619028],
                [-14.589862, 10.619584],
                [-14.589305, 10.619862000000126],
                [-14.589305, 10.620416000000148],
                [-14.588195, 10.621528000000126],
                [-14.587916999999834, 10.622084000000143],
                [-14.587082999999836, 10.622638000000109],
                [-14.585416999999836, 10.624306000000104],
                [-14.584029, 10.62514],
                [-14.583749, 10.625694],
                [-14.583749, 10.626528000000121],
                [-14.584029, 10.627084000000139],
                [-14.584583, 10.62736],
                [-14.584583, 10.627916],
                [-14.585139, 10.628472],
                [-14.585971, 10.628472],
                [-14.586250999999834, 10.629026],
                [-14.587361, 10.629026],
                [-14.588195, 10.629307],
                [-14.588748999999837, 10.629860000000122],
                [-14.589305, 10.631251],
                [-14.589305, 10.632639],
                [-14.589582999999834, 10.632639],
                [-14.589582999999834, 10.635417000000189],
                [-14.588195, 10.635417000000189],
                [-14.587639, 10.635139],
                [-14.588471, 10.634307],
                [-14.588471, 10.633751000000188],
                [-14.589027, 10.633473],
                [-14.589027, 10.632639],
                [-14.588471, 10.632083],
                [-14.587916999999834, 10.630973],
                [-14.587082999999836, 10.630141],
                [-14.584583, 10.629307],
                [-14.584027, 10.628750000000139],
                [-14.583195, 10.628750000000139],
                [-14.582361, 10.627916],
                [-14.582361, 10.62736],
                [-14.581805, 10.626806],
                [-14.581805, 10.625972000000104],
                [-14.583195, 10.623194000000126],
                [-14.583473, 10.623194000000126],
                [-14.584305, 10.622362],
                [-14.584861, 10.622084000000143],
                [-14.584861, 10.621528000000126],
                [-14.585695, 10.62125],
                [-14.586527, 10.62014],
                [-14.587639, 10.61986000000013],
                [-14.589027, 10.618750000000148],
                [-14.589027, 10.618193],
                [-14.589582999999834, 10.617640000000108],
                [-14.589582999999834, 10.616249000000153],
                [-14.589862, 10.616249000000153],
                [-14.589862, 10.614861],
                [-14.59014, 10.614027],
                [-14.590417999999886, 10.614027],
                [-14.590693, 10.613195],
                [-14.590693, 10.611805000000174],
                [-14.590971, 10.611805000000174],
                [-14.590971, 10.608195],
                [-14.590693, 10.608195],
                [-14.590693, 10.605414],
                [-14.59014, 10.604304000000184],
                [-14.589582999999834, 10.604304000000184],
                [-14.589582999999834, 10.603751000000102],
                [-14.588748999999837, 10.603473000000179],
                [-14.588748999999837, 10.602638000000184],
                [-14.588195, 10.60236],
                [-14.588195, 10.601807000000179],
                [-14.587361, 10.601250000000107],
                [-14.587082999999836, 10.600138],
                [-14.586527, 10.599584000000107],
                [-14.586527, 10.598472],
                [-14.586250999999834, 10.597916000000112],
                [-14.585695, 10.597638000000188],
                [-14.583195, 10.597638000000188],
                [-14.583195, 10.597916000000112],
                [-14.579583, 10.597916000000112],
                [-14.579029, 10.598194],
                [-14.578751, 10.59875],
                [-14.577917, 10.59875],
                [-14.57736, 10.599028],
                [-14.576803999999868, 10.59986],
                [-14.576251, 10.59986],
                [-14.576251, 10.600416],
                [-14.575416, 10.600694],
                [-14.575137999999868, 10.601250000000107],
                [-14.574582, 10.601250000000107],
                [-14.574028, 10.602082],
                [-14.573194, 10.60236],
                [-14.573194, 10.602916000000107],
                [-14.572084, 10.603195],
                [-14.572084, 10.604029],
                [-14.571528, 10.604861],
                [-14.569028, 10.604861],
                [-14.56875, 10.604304000000184],
                [-14.568194, 10.604029],
                [-14.566805999999872, 10.604029],
                [-14.56625, 10.603473000000179],
                [-14.565139999999872, 10.603473000000179],
                [-14.564862, 10.602916000000107],
                [-14.564028, 10.602916000000107],
                [-14.563749, 10.60236],
                [-14.563192999999899, 10.60236],
                [-14.562918, 10.601807000000179],
                [-14.562360999999896, 10.601807000000179],
                [-14.561249, 10.601250000000107],
                [-14.561249, 10.600694],
                [-14.560694999999896, 10.600416],
                [-14.559583, 10.600416],
                [-14.559583, 10.600138],
                [-14.557361, 10.600138],
                [-14.557361, 10.600694],
                [-14.556249, 10.600972000000183],
                [-14.55597299999988, 10.601528],
                [-14.555138999999883, 10.601528],
                [-14.554583, 10.602082],
                [-14.55430699999988, 10.602638000000184],
                [-14.553472999999883, 10.602638000000184],
                [-14.553472999999883, 10.603195],
                [-14.551804, 10.603195],
                [-14.551804, 10.602916000000107],
                [-14.547916, 10.602916000000107],
                [-14.547916, 10.603195],
                [-14.546806, 10.603195],
                [-14.54625, 10.603473000000179],
                [-14.545972, 10.604029],
                [-14.545416, 10.604029],
                [-14.54486, 10.604861],
                [-14.544028, 10.605139000000179],
                [-14.541528, 10.605139000000179],
                [-14.541528, 10.604861],
                [-14.540416, 10.604861],
                [-14.540416, 10.604585000000156],
                [-14.539306, 10.604585000000156],
                [-14.538196, 10.604029],
                [-14.538196, 10.603473000000179],
                [-14.53764, 10.603195],
                [-14.53736199999986, 10.602638000000184],
                [-14.53736199999986, 10.601528],
                [-14.536806, 10.600972000000183],
                [-14.536806, 10.59986],
                [-14.536527, 10.59986],
                [-14.536527, 10.596528],
                [-14.535974, 10.595972000000188],
                [-14.535974, 10.595138000000134],
                [-14.535418, 10.594862],
                [-14.535418, 10.59375],
                [-14.535139, 10.59375],
                [-14.534861, 10.592916000000116],
                [-14.534861, 10.591806000000133],
                [-14.534305, 10.591806000000133],
                [-14.534027, 10.591250000000116],
                [-14.532639, 10.590693],
                [-14.531526999999869, 10.590693],
                [-14.530973, 10.591250000000116],
                [-14.530139, 10.591250000000116],
                [-14.529583, 10.591806000000133],
                [-14.528751, 10.592084],
                [-14.527639, 10.592640000000188],
                [-14.526807, 10.592640000000188],
                [-14.525694999999871, 10.593472000000133],
                [-14.524585, 10.59375],
                [-14.52402899999987, 10.594306000000188],
                [-14.521249999999895, 10.594306000000188],
                [-14.521249999999895, 10.594584000000111],
                [-14.520138, 10.594584000000111],
                [-14.520138, 10.594306000000188],
                [-14.518749999999898, 10.594306000000188],
                [-14.518749999999898, 10.59375],
                [-14.518193999999824, 10.593472000000133],
                [-14.51764, 10.592916000000116],
                [-14.51764, 10.592362],
                [-14.517083999999898, 10.592084],
                [-14.517083999999898, 10.590972],
                [-14.517359999999826, 10.590972],
                [-14.517359999999826, 10.589027],
                [-14.51764, 10.589027],
                [-14.51764, 10.587917],
                [-14.517916, 10.587917],
                [-14.517916, 10.586251],
                [-14.518472, 10.585971000000143],
                [-14.518749999999898, 10.584861000000103],
                [-14.519306, 10.584861000000103],
                [-14.519583999999895, 10.584305000000143],
                [-14.520415999999898, 10.584027],
                [-14.520415999999898, 10.583195000000103],
                [-14.520972, 10.583195000000103],
                [-14.521525999999824, 10.582639000000142],
                [-14.521527999999876, 10.579861000000164],
                [-14.521807, 10.579861000000164],
                [-14.521807, 10.578751000000125],
                [-14.520972, 10.577082],
                [-14.520693999999878, 10.576804000000152],
                [-14.521249999999895, 10.576251],
                [-14.522359999999878, 10.576251],
                [-14.522915999999896, 10.576804000000152],
                [-14.522915999999896, 10.579305000000147],
                [-14.523194999999873, 10.579305000000147],
                [-14.523194999999873, 10.580417000000125],
                [-14.523473, 10.580417000000125],
                [-14.523473, 10.582917],
                [-14.523194999999873, 10.583749000000125],
                [-14.522359999999878, 10.58541700000012],
                [-14.521249999999895, 10.586527000000103],
                [-14.520972, 10.586527000000103],
                [-14.520693999999878, 10.587361],
                [-14.520693999999878, 10.58874900000012],
                [-14.520972, 10.58874900000012],
                [-14.520972, 10.590137],
                [-14.521249999999895, 10.590137],
                [-14.521249999999895, 10.591250000000116],
                [-14.522081999999898, 10.591528],
                [-14.522359999999878, 10.592084],
                [-14.522915999999896, 10.591806000000133],
                [-14.52402899999987, 10.591806000000133],
                [-14.524860999999873, 10.591528],
                [-14.52541699999989, 10.590972],
                [-14.526251, 10.590972],
                [-14.526807, 10.590418],
                [-14.527917, 10.590418],
                [-14.527917, 10.590137],
                [-14.529029, 10.590137],
                [-14.529029, 10.589862],
                [-14.530139, 10.589862],
                [-14.530139, 10.589584000000116],
                [-14.532361, 10.589584000000116],
                [-14.532361, 10.589305000000138],
                [-14.534583, 10.589305000000138],
                [-14.534861, 10.589862],
                [-14.535693, 10.590137],
                [-14.535693, 10.590972],
                [-14.536249, 10.590972],
                [-14.536806, 10.591806000000133],
                [-14.53736199999986, 10.592362],
                [-14.53764, 10.593194],
                [-14.53764, 10.594584000000111],
                [-14.537918, 10.594584000000111],
                [-14.537918, 10.596250000000111],
                [-14.538196, 10.596250000000111],
                [-14.538196, 10.59736],
                [-14.538472, 10.598194],
                [-14.53875, 10.598194],
                [-14.53875, 10.599304000000188],
                [-14.53902799999986, 10.599304000000188],
                [-14.53902799999986, 10.600416],
                [-14.539862, 10.601807000000179],
                [-14.540416, 10.601807000000179],
                [-14.54069399999986, 10.60236],
                [-14.54375, 10.60236],
                [-14.544584, 10.602082],
                [-14.54514, 10.601528],
                [-14.545972, 10.601250000000107],
                [-14.547362, 10.601250000000107],
                [-14.547362, 10.600972000000183],
                [-14.549029, 10.600972000000183],
                [-14.549029, 10.600694],
                [-14.550138, 10.600694],
                [-14.550972999999829, 10.600416],
                [-14.551251, 10.59986],
                [-14.553472999999883, 10.59986],
                [-14.553751, 10.599028],
                [-14.554861, 10.598472],
                [-14.555417, 10.598472],
                [-14.555417, 10.597916000000112],
                [-14.556249, 10.597362],
                [-14.559583, 10.597362],
                [-14.559583, 10.597638000000188],
                [-14.561804999999879, 10.597638000000188],
                [-14.561804999999879, 10.597916000000112],
                [-14.562639999999874, 10.598194],
                [-14.564028, 10.599584000000107],
                [-14.564028, 10.600416],
                [-14.564584, 10.600972000000183],
                [-14.565139999999872, 10.600972000000183],
                [-14.565139999999872, 10.601528],
                [-14.566805999999872, 10.60236],
                [-14.568471999999872, 10.60236],
                [-14.568471999999872, 10.602638000000184],
                [-14.56930599999987, 10.602916000000107],
                [-14.570694, 10.602916000000107],
                [-14.57097199999987, 10.60236],
                [-14.571805999999867, 10.602082],
                [-14.571805999999867, 10.601528],
                [-14.57236, 10.601250000000107],
                [-14.57236, 10.600416],
                [-14.57263799999987, 10.59986],
                [-14.573194, 10.59986],
                [-14.573471999999867, 10.599304000000188],
                [-14.574028, 10.599028],
                [-14.574028, 10.598472],
                [-14.574582, 10.598472],
                [-14.574582, 10.597916000000112],
                [-14.575137999999868, 10.597362],
                [-14.576251, 10.597084],
                [-14.576251, 10.596528],
                [-14.576803999999868, 10.596528],
                [-14.57736, 10.595972000000188],
                [-14.577637999999865, 10.595138000000134],
                [-14.578473, 10.594862],
                [-14.579583, 10.594862],
                [-14.579583, 10.594584000000111],
                [-14.580695, 10.594584000000111],
                [-14.580695, 10.594306000000188],
                [-14.583473, 10.594306000000188],
                [-14.583473, 10.594028],
                [-14.584583, 10.594028],
                [-14.587082999999836, 10.593194],
                [-14.587082999999836, 10.592916000000116],
                [-14.588195, 10.592916000000116],
                [-14.588471, 10.592362],
                [-14.588471, 10.590693],
                [-14.589582999999834, 10.588471],
                [-14.589862, 10.588471],
                [-14.590693, 10.587639000000138],
                [-14.590971, 10.586805],
                [-14.590971, 10.585139],
                [-14.590417999999886, 10.584583],
                [-14.590417999999886, 10.583473],
                [-14.589862, 10.582917],
                [-14.589027, 10.580417000000125],
                [-14.589027, 10.579583],
                [-14.588471, 10.579029],
                [-14.587916999999834, 10.577917],
                [-14.587916999999834, 10.576251],
                [-14.587639, 10.576251],
                [-14.587639, 10.573194],
                [-14.587361, 10.572360000000174],
                [-14.587082999999836, 10.572360000000174],
                [-14.587082999999836, 10.571250000000134],
                [-14.586805, 10.570694000000174],
                [-14.585695, 10.570416],
                [-14.584583, 10.569582],
                [-14.583749, 10.568194],
                [-14.582917, 10.568194],
                [-14.582361, 10.56764],
                [-14.580973, 10.56764],
                [-14.579861, 10.56875],
                [-14.579583, 10.569862],
                [-14.579029, 10.570138000000156],
                [-14.578751, 10.571250000000134],
                [-14.578195, 10.571528],
                [-14.576251, 10.571528],
                [-14.576251, 10.571250000000134],
                [-14.574863, 10.571250000000134],
                [-14.574028, 10.570972],
                [-14.573194, 10.569862],
                [-14.57263799999987, 10.569862],
                [-14.571528, 10.56875],
                [-14.57125, 10.567916],
                [-14.570137999999872, 10.566806000000156],
                [-14.570137999999872, 10.56625],
                [-14.569584, 10.56625],
                [-14.569584, 10.565696000000173],
                [-14.570137999999872, 10.565696000000173],
                [-14.570416, 10.56625],
                [-14.571805999999867, 10.567638000000102],
                [-14.572916, 10.568194],
                [-14.573194, 10.56875],
                [-14.574028, 10.569582],
                [-14.574028, 10.569862],
                [-14.575137999999868, 10.570416],
                [-14.57736, 10.570416],
                [-14.577637999999865, 10.569862],
                [-14.578195, 10.569862],
                [-14.579029, 10.56875],
                [-14.579029, 10.568194],
                [-14.579583, 10.56764],
                [-14.580417, 10.567638000000102],
                [-14.580973, 10.567084],
                [-14.582083, 10.567084],
                [-14.582083, 10.567362000000173],
                [-14.582917, 10.567638000000102],
                [-14.584029, 10.56764],
                [-14.584861, 10.567916],
                [-14.585971, 10.568472000000156],
                [-14.586527, 10.569028000000174],
                [-14.586527, 10.569582],
                [-14.587082999999836, 10.569862],
                [-14.587082999999836, 10.570416],
                [-14.587639, 10.570694000000174],
                [-14.587916999999834, 10.571250000000134],
                [-14.588471, 10.572916],
                [-14.588471, 10.575973000000147],
                [-14.588748999999837, 10.575973000000147],
                [-14.589305, 10.577082],
                [-14.589582999999834, 10.577917],
                [-14.589582999999834, 10.579029],
                [-14.589862, 10.579861000000164],
                [-14.590417999999886, 10.580417000000125],
                [-14.590417999999886, 10.581527000000165],
                [-14.591248999999834, 10.583195000000103],
                [-14.591806, 10.583473],
                [-14.592083999999886, 10.584029],
                [-14.592083999999886, 10.586805],
                [-14.59153, 10.58708300000012],
                [-14.591248999999834, 10.587639000000138],
                [-14.591248999999834, 10.590137],
                [-14.591528, 10.590693],
                [-14.592083999999886, 10.590972],
                [-14.593194, 10.590418],
                [-14.594306, 10.590137],
                [-14.594583999999884, 10.589584000000116],
                [-14.595417999999881, 10.589584000000116],
                [-14.596249999999884, 10.589305000000138],
                [-14.59736, 10.58874900000012],
                [-14.597915999999884, 10.588196],
                [-14.598472, 10.588196],
                [-14.59958399999988, 10.587639000000138],
                [-14.600415999999882, 10.587361],
                [-14.601528, 10.586805],
                [-14.602081999999882, 10.586251],
                [-14.603194, 10.585695],
                [-14.603750999999875, 10.585139],
                [-14.603750999999875, 10.584583],
                [-14.604584999999872, 10.583473],
                [-14.605416999999875, 10.582917],
                [-14.605416999999875, 10.582361],
                [-14.606527, 10.581527000000165],
                [-14.607361, 10.581527000000165],
                [-14.608473, 10.580971000000147],
                [-14.609027, 10.579861000000164],
                [-14.61041699999987, 10.579861000000164],
                [-14.611527, 10.578751000000125],
                [-14.611805, 10.577917],
                [-14.612639, 10.577917],
                [-14.613195, 10.577361],
                [-14.614027, 10.577361],
                [-14.614305, 10.576529000000164],
                [-14.614305, 10.574306],
                [-14.614861, 10.572638],
                [-14.615139, 10.572360000000174],
                [-14.621806, 10.572360000000174],
                [-14.622361999999839, 10.572084],
                [-14.622638, 10.571528],
                [-14.623193999999842, 10.571528],
                [-14.62375, 10.570972],
                [-14.622916, 10.570138000000156],
                [-14.622916, 10.569028000000174],
                [-14.622638, 10.569028000000174],
                [-14.622638, 10.567084],
                [-14.622916, 10.566528],
                [-14.62402799999984, 10.566528],
                [-14.624584, 10.56625],
                [-14.624859999999842, 10.565416],
                [-14.624859999999842, 10.564028000000178],
                [-14.62514, 10.563471000000106],
                [-14.625972, 10.562918],
                [-14.627361999999891, 10.562918],
                [-14.627361999999891, 10.563193000000183],
                [-14.628472, 10.563193000000183],
                [-14.629307, 10.563471000000106],
                [-14.629307, 10.56375],
                [-14.630416, 10.56375],
                [-14.630416, 10.564028000000178],
                [-14.631251, 10.564306000000101],
                [-14.632639, 10.564306000000101],
                [-14.634307, 10.56375],
                [-14.634583, 10.563193000000183],
                [-14.635139, 10.563193000000183],
                [-14.635139, 10.561527000000183],
                [-14.634860999999887, 10.561527000000183],
                [-14.634860999999887, 10.560415],
                [-14.635417, 10.560695],
                [-14.637360999999885, 10.560695],
                [-14.638749, 10.560139000000106],
                [-14.639026999999885, 10.559583],
                [-14.639583, 10.559583],
                [-14.639860999999883, 10.559027000000128],
                [-14.64069499999988, 10.559027000000128],
                [-14.640971, 10.558195000000183],
                [-14.640971, 10.557361000000128],
                [-14.64069499999988, 10.556529000000182],
                [-14.640139, 10.555417],
                [-14.639305, 10.554861000000187],
                [-14.639305, 10.554027000000133],
                [-14.641805, 10.554861000000187],
                [-14.642639, 10.554861000000187],
                [-14.643192999999883, 10.55513900000011],
                [-14.643474, 10.555695000000128],
                [-14.64402699999988, 10.555695000000128],
                [-14.644305, 10.556249],
                [-14.644861999999875, 10.556529000000182],
                [-14.645972, 10.556529000000182],
                [-14.647918, 10.560415],
                [-14.647918, 10.560971],
                [-14.648472, 10.561249],
                [-14.64875, 10.562362000000178],
                [-14.649584, 10.562362000000178],
                [-14.649584, 10.563471000000106],
                [-14.649862, 10.56375],
                [-14.650972, 10.56375],
                [-14.651528, 10.562918],
                [-14.652084, 10.562918],
                [-14.652916, 10.562083],
                [-14.652916, 10.561527000000183],
                [-14.65375, 10.560971],
                [-14.655138, 10.560971],
                [-14.655416, 10.560415],
                [-14.655972, 10.560139000000106],
                [-14.657085, 10.557917],
                [-14.657085, 10.555973],
                [-14.65736, 10.555973],
                [-14.65736, 10.550695000000132],
                [-14.657085, 10.550695000000132],
                [-14.657085, 10.549307],
                [-14.656806999999844, 10.549307],
                [-14.656806999999844, 10.547916],
                [-14.656529, 10.54708200000016],
                [-14.655972, 10.546806],
                [-14.655416, 10.54625],
                [-14.655416, 10.54514],
                [-14.655138, 10.54514],
                [-14.654862, 10.54430600000012],
                [-14.654862, 10.543472],
                [-14.654306, 10.542638000000125],
                [-14.650694, 10.542638000000125],
                [-14.649584, 10.54125],
                [-14.649584, 10.540694],
                [-14.649028, 10.54014],
                [-14.648193999999876, 10.538472],
                [-14.647362, 10.537918],
                [-14.647362, 10.534861],
                [-14.64875, 10.53347100000019],
                [-14.649306, 10.53236100000015],
                [-14.649028, 10.53180500000019],
                [-14.649028, 10.530417],
                [-14.649584, 10.529305],
                [-14.650694, 10.53013900000019],
                [-14.651806, 10.530417],
                [-14.65236, 10.529861],
                [-14.652638, 10.529027],
                [-14.652638, 10.527917000000173],
                [-14.653472, 10.526527000000101],
                [-14.654306, 10.526251000000173],
                [-14.655416, 10.526251000000173],
                [-14.655416, 10.525973],
                [-14.657638, 10.525973],
                [-14.657916, 10.525417],
                [-14.659029, 10.525139],
                [-14.659304, 10.525695],
                [-14.660695, 10.525695],
                [-14.661251, 10.524583000000177],
                [-14.661527, 10.523751],
                [-14.661527, 10.5215290000001],
                [-14.661251, 10.520694000000105],
                [-14.660972999999899, 10.520694000000105],
                [-14.660972999999899, 10.518472],
                [-14.661251, 10.518472],
                [-14.661251, 10.516806],
                [-14.661527, 10.516528],
                [-14.663195, 10.516528],
                [-14.663472999999897, 10.515972],
                [-14.664029, 10.515972],
                [-14.6643049999999, 10.515418000000182],
                [-14.664861, 10.515138],
                [-14.665971, 10.515138],
                [-14.666527, 10.515972],
                [-14.667917, 10.515972],
                [-14.667917, 10.514862],
                [-14.667361, 10.514306],
                [-14.667361, 10.513194],
                [-14.667083, 10.51236200000011],
                [-14.666251, 10.511806],
                [-14.665971, 10.511250000000132],
                [-14.665971, 10.509584000000132],
                [-14.665695, 10.509584000000132],
                [-14.665695, 10.507361],
                [-14.664861, 10.504861000000119],
                [-14.664583, 10.504861000000119],
                [-14.664583, 10.503749000000141],
                [-14.6643049999999, 10.503749000000141],
                [-14.6643049999999, 10.502639000000158],
                [-14.664029, 10.502639000000158],
                [-14.664029, 10.501527000000124],
                [-14.663472999999897, 10.50041700000014],
                [-14.663195, 10.50041700000014],
                [-14.663195, 10.499305000000163],
                [-14.662917, 10.499305000000163],
                [-14.662917, 10.497361],
                [-14.662361, 10.496804000000168],
                [-14.661804999999845, 10.495138000000168],
                [-14.661804999999845, 10.49375],
                [-14.661251, 10.493194],
                [-14.661251, 10.491528],
                [-14.660972999999899, 10.491528],
                [-14.660972999999899, 10.490416],
                [-14.660417, 10.489306],
                [-14.660138999999845, 10.489306],
                [-14.660138999999845, 10.48736200000019],
                [-14.659861, 10.48736200000019],
                [-14.659861, 10.48625],
                [-14.659583, 10.48625],
                [-14.659583, 10.485140000000172],
                [-14.659304, 10.485140000000172],
                [-14.659304, 10.482640000000117],
                [-14.659029, 10.482640000000117],
                [-14.659029, 10.480415],
                [-14.658751, 10.480415],
                [-14.658751, 10.477917000000105],
                [-14.658472999999844, 10.477083],
                [-14.659029, 10.476529],
                [-14.659029, 10.475695],
                [-14.659583, 10.47458300000011],
                [-14.659861, 10.474305],
                [-14.662361, 10.474305],
                [-14.662917, 10.473751],
                [-14.663195, 10.473195],
                [-14.662361, 10.472083],
                [-14.662361, 10.470695000000148],
                [-14.662083, 10.470695000000148],
                [-14.662083, 10.468473000000131],
                [-14.661804999999845, 10.468473000000131],
                [-14.661804999999845, 10.467082000000119],
                [-14.662361, 10.466528000000153],
                [-14.662361, 10.464306000000136],
                [-14.662083, 10.463750000000118],
                [-14.661527, 10.464028],
                [-14.659583, 10.464028],
                [-14.658751, 10.463194000000158],
                [-14.65736, 10.46263800000014],
                [-14.656806999999844, 10.46208200000018],
                [-14.656529, 10.46097200000014],
                [-14.655972, 10.460694],
                [-14.655972, 10.46014],
                [-14.655416, 10.46014],
                [-14.654028, 10.45875000000018],
                [-14.653472, 10.457916],
                [-14.65264, 10.457916],
                [-14.652084, 10.45875000000018],
                [-14.65125, 10.45875000000018],
                [-14.650972, 10.45930600000014],
                [-14.650416, 10.45930600000014],
                [-14.649584, 10.46014],
                [-14.649584, 10.46097200000014],
                [-14.650138, 10.461528000000158],
                [-14.650138, 10.463472],
                [-14.649028, 10.464584],
                [-14.648193999999876, 10.464584],
                [-14.648193999999876, 10.46625],
                [-14.64764, 10.466806],
                [-14.647084, 10.467916],
                [-14.647084, 10.471249000000114],
                [-14.646806, 10.471249000000114],
                [-14.646806, 10.472361000000149],
                [-14.646527999999876, 10.472361000000149],
                [-14.646527999999876, 10.474305],
                [-14.64625, 10.474305],
                [-14.64625, 10.476529],
                [-14.644861999999875, 10.476529],
                [-14.64402699999988, 10.47624900000011],
                [-14.643471, 10.475695],
                [-14.642639, 10.475417],
                [-14.641526999999883, 10.475417],
                [-14.641526999999883, 10.475695],
                [-14.640417, 10.47624900000011],
                [-14.636528999999882, 10.47624900000011],
                [-14.635973, 10.475695],
                [-14.635139, 10.475695],
                [-14.634307, 10.475417],
                [-14.633751, 10.47458300000011],
                [-14.632639, 10.47458300000011],
                [-14.631525999999894, 10.474027000000149],
                [-14.631251, 10.473473000000126],
                [-14.62514, 10.473473000000126],
                [-14.62514, 10.473751],
                [-14.623472, 10.473751],
                [-14.622638, 10.474027000000149],
                [-14.622084, 10.474861],
                [-14.620972, 10.475417],
                [-14.619584, 10.476805000000127],
                [-14.619584, 10.477639],
                [-14.619028, 10.477639],
                [-14.619028, 10.478195],
                [-14.617918, 10.479027],
                [-14.617918, 10.479583000000105],
                [-14.617362, 10.479861],
                [-14.616805, 10.480971],
                [-14.616805, 10.481805000000122],
                [-14.616249, 10.482362],
                [-14.615974, 10.483193],
                [-14.615974, 10.484584],
                [-14.616249, 10.484584],
                [-14.616249, 10.48625],
                [-14.615974, 10.487084],
                [-14.615693, 10.487084],
                [-14.615693, 10.488194],
                [-14.615417, 10.48902800000019],
                [-14.615139, 10.48902800000019],
                [-14.615139, 10.490138000000172],
                [-14.614861, 10.490138000000172],
                [-14.613751, 10.49236000000019],
                [-14.613751, 10.492916],
                [-14.612639, 10.494028000000185],
                [-14.61208299999987, 10.494028000000185],
                [-14.61208299999987, 10.494582],
                [-14.611248999999873, 10.494863000000123],
                [-14.611248999999873, 10.495416],
                [-14.610695, 10.495416],
                [-14.61041699999987, 10.495973000000163],
                [-14.609305, 10.496251],
                [-14.609027, 10.496804000000168],
                [-14.607916999999873, 10.49708500000014],
                [-14.607639, 10.497639000000163],
                [-14.607082999999875, 10.497639000000163],
                [-14.606250999999872, 10.49875100000014],
                [-14.606250999999872, 10.501805],
                [-14.605695, 10.502361],
                [-14.605695, 10.503195000000119],
                [-14.605416999999875, 10.503749000000141],
                [-14.60486, 10.504027],
                [-14.60486, 10.504861000000119],
                [-14.604029, 10.505695],
                [-14.60291599999988, 10.505971000000159],
                [-14.602638, 10.50652700000012],
                [-14.60124999999988, 10.507083000000137],
                [-14.59958399999988, 10.507083000000137],
                [-14.59958399999988, 10.507361],
                [-14.598472, 10.507361],
                [-14.598472, 10.507639000000154],
                [-14.597083999999882, 10.507639000000154],
                [-14.596806, 10.508471],
                [-14.595694, 10.509584000000132],
                [-14.594862, 10.509584000000132],
                [-14.594306, 10.510137],
                [-14.592083999999886, 10.510137],
                [-14.590417999999886, 10.509584000000132],
                [-14.589862, 10.509027],
                [-14.588748999999837, 10.509027],
                [-14.587916999999834, 10.508749000000137],
                [-14.587916999999834, 10.508471],
                [-14.585971, 10.508471],
                [-14.585971, 10.508749000000137],
                [-14.581527, 10.508749000000137],
                [-14.581527, 10.508471],
                [-14.579861, 10.508471],
                [-14.578473, 10.509027],
                [-14.57736, 10.510137],
                [-14.57736, 10.510694000000115],
                [-14.576803999999868, 10.510972],
                [-14.576803999999868, 10.511806],
                [-14.576251, 10.511806],
                [-14.576251, 10.51236200000011],
                [-14.575416, 10.51264],
                [-14.574863, 10.513194],
                [-14.57097199999987, 10.513194],
                [-14.57097199999987, 10.512916000000132],
                [-14.56930599999987, 10.512916000000132],
                [-14.56875, 10.513194],
                [-14.568471999999872, 10.51375],
                [-14.568471999999872, 10.516806],
                [-14.567918, 10.516806],
                [-14.56763999999987, 10.517916000000127],
                [-14.567362, 10.518472],
                [-14.566805999999872, 10.518750000000182],
                [-14.565971999999874, 10.519584000000123],
                [-14.565971999999874, 10.520138],
                [-14.565139999999872, 10.520972],
                [-14.564584, 10.520972],
                [-14.564028, 10.5215290000001],
                [-14.563470999999879, 10.521807],
                [-14.561526999999899, 10.521807],
                [-14.560138999999879, 10.520416000000182],
                [-14.559583, 10.520416000000182],
                [-14.559304999999881, 10.51986],
                [-14.559860999999898, 10.519584000000123],
                [-14.560417, 10.520416000000182],
                [-14.561804999999879, 10.520416000000182],
                [-14.561804999999879, 10.520694000000105],
                [-14.563749, 10.520694000000105],
                [-14.564305999999874, 10.520416000000182],
                [-14.564584, 10.51986],
                [-14.565139999999872, 10.519584000000123],
                [-14.566805999999872, 10.517916000000127],
                [-14.567084, 10.51736000000011],
                [-14.567084, 10.515418000000182],
                [-14.567362, 10.515418000000182],
                [-14.567637999999874, 10.514584000000127],
                [-14.56763999999987, 10.513472],
                [-14.568194, 10.51236200000011],
                [-14.56875, 10.512084],
                [-14.574028, 10.512084],
                [-14.574863, 10.511806],
                [-14.574863, 10.511250000000132],
                [-14.575694, 10.510694000000115],
                [-14.575694, 10.510137],
                [-14.576251, 10.509862],
                [-14.577085, 10.509027],
                [-14.577085, 10.508471],
                [-14.578473, 10.507083000000137],
                [-14.579029, 10.507083000000137],
                [-14.579305, 10.50652700000012],
                [-14.581805, 10.50652700000012],
                [-14.582639, 10.506251],
                [-14.583195, 10.505695],
                [-14.584029, 10.505417000000136],
                [-14.585139, 10.505417000000136],
                [-14.586250999999834, 10.504861000000119],
                [-14.587916999999834, 10.503195000000119],
                [-14.590971, 10.503195000000119],
                [-14.591248999999834, 10.502917],
                [-14.591248999999834, 10.502083000000141],
                [-14.590693, 10.501251],
                [-14.59014, 10.501251],
                [-14.589862, 10.500695],
                [-14.589862, 10.499583],
                [-14.589582999999834, 10.49875100000014],
                [-14.589862, 10.498195000000123],
                [-14.590693, 10.497639000000163],
                [-14.592362, 10.497639000000163],
                [-14.59264, 10.498195000000123],
                [-14.593749999999886, 10.499029],
                [-14.594306, 10.499305000000163],
                [-14.594583999999884, 10.499861000000124],
                [-14.595972, 10.499861000000124],
                [-14.597083999999882, 10.49875100000014],
                [-14.598472, 10.49875100000014],
                [-14.59958399999988, 10.499305000000163],
                [-14.59958399999988, 10.500139],
                [-14.600138, 10.50041700000014],
                [-14.600138, 10.503195000000119],
                [-14.601528, 10.504583],
                [-14.60236, 10.504861000000119],
                [-14.602638, 10.504027],
                [-14.603194, 10.503473],
                [-14.603194, 10.501805],
                [-14.603473, 10.501805],
                [-14.603473, 10.499029],
                [-14.603750999999875, 10.499029],
                [-14.603750999999875, 10.497639000000163],
                [-14.604304, 10.49708500000014],
                [-14.604304, 10.496529000000123],
                [-14.60486, 10.496251],
                [-14.60486, 10.495695],
                [-14.605695, 10.495416],
                [-14.605695, 10.49486],
                [-14.606807, 10.494582],
                [-14.607082999999875, 10.494028000000185],
                [-14.608195, 10.492916],
                [-14.60875099999987, 10.492916],
                [-14.609582999999873, 10.49236000000019],
                [-14.609582999999873, 10.491806000000167],
                [-14.610139, 10.491528],
                [-14.609861, 10.49069400000019],
                [-14.609861, 10.489306],
                [-14.609582999999873, 10.489306],
                [-14.609582999999873, 10.488194],
                [-14.609305, 10.488194],
                [-14.609305, 10.487084],
                [-14.609027, 10.487084],
                [-14.609027, 10.484028],
                [-14.609305, 10.483193],
                [-14.610695, 10.480415],
                [-14.610973, 10.480415],
                [-14.611805, 10.479583000000105],
                [-14.611805, 10.479305],
                [-14.612917, 10.478195],
                [-14.613195, 10.478195],
                [-14.614027, 10.477361],
                [-14.614027, 10.476805000000127],
                [-14.614583, 10.47624900000011],
                [-14.615693, 10.475695],
                [-14.616805, 10.474861],
                [-14.617362, 10.474027000000149],
                [-14.618195999999841, 10.473473000000126],
                [-14.619584, 10.472083],
                [-14.62014, 10.471805000000131],
                [-14.620416, 10.471249000000114],
                [-14.620972, 10.470695000000148],
                [-14.621527999999842, 10.470695000000148],
                [-14.622084, 10.469861],
                [-14.622638, 10.469582],
                [-14.62375, 10.469582],
                [-14.624306, 10.469029000000148],
                [-14.625416, 10.469029000000148],
                [-14.625416, 10.468751],
                [-14.626527999999894, 10.468751],
                [-14.626527999999894, 10.468473000000131],
                [-14.627916, 10.468473000000131],
                [-14.627916, 10.468194000000153],
                [-14.629027999999892, 10.468194000000153],
                [-14.629027999999892, 10.468473000000131],
                [-14.630693999999892, 10.468473000000131],
                [-14.630693999999892, 10.468751],
                [-14.631804, 10.468751],
                [-14.632639, 10.469029000000148],
                [-14.633194999999887, 10.469582],
                [-14.63402699999989, 10.469582],
                [-14.634583, 10.470139000000131],
                [-14.635417, 10.470139000000131],
                [-14.636249, 10.470417],
                [-14.636528999999882, 10.469861],
                [-14.637083, 10.469861],
                [-14.637360999999885, 10.468751],
                [-14.636805, 10.468473000000131],
                [-14.636805, 10.467916],
                [-14.635973, 10.467363000000148],
                [-14.635417, 10.466806],
                [-14.635417, 10.46625],
                [-14.633751, 10.464584],
                [-14.633473, 10.464028],
                [-14.632917, 10.463750000000118],
                [-14.632917, 10.461806],
                [-14.63236099999989, 10.460694],
                [-14.633194999999887, 10.459584],
                [-14.633751, 10.45930600000014],
                [-14.633751, 10.45875000000018],
                [-14.632917, 10.458472],
                [-14.631804, 10.457916],
                [-14.631804, 10.457362],
                [-14.630973, 10.456806],
                [-14.630416, 10.456806],
                [-14.629859999999894, 10.45625],
                [-14.628193999999894, 10.45625],
                [-14.628193999999894, 10.456528000000162],
                [-14.627361999999891, 10.456806],
                [-14.626527999999894, 10.456806],
                [-14.625972, 10.45708400000018],
                [-14.625416, 10.457916],
                [-14.624584, 10.457916],
                [-14.623193999999842, 10.45930600000014],
                [-14.622361999999839, 10.459584],
                [-14.62125, 10.459584],
                [-14.620694, 10.46041600000018],
                [-14.62014, 10.46041600000018],
                [-14.619861999999841, 10.46097200000014],
                [-14.619861999999841, 10.461806],
                [-14.620694, 10.46208200000018],
                [-14.621806, 10.46125],
                [-14.622084, 10.460694],
                [-14.624306, 10.460694],
                [-14.624306, 10.461806],
                [-14.62375, 10.462084000000118],
                [-14.623193999999842, 10.463194000000158],
                [-14.623193999999842, 10.464306000000136],
                [-14.622638, 10.464860000000158],
                [-14.622084, 10.464860000000158],
                [-14.621806, 10.465416000000118],
                [-14.620972, 10.465416000000118],
                [-14.62014, 10.465694],
                [-14.619584, 10.46625],
                [-14.61875, 10.46625],
                [-14.618195999999841, 10.466806],
                [-14.616805, 10.466806],
                [-14.616805, 10.467082000000119],
                [-14.615417, 10.467082000000119],
                [-14.615417, 10.466806],
                [-14.613751, 10.466806],
                [-14.613751, 10.467082000000119],
                [-14.61208299999987, 10.467082000000119],
                [-14.611805, 10.466528000000153],
                [-14.609861, 10.466528000000153],
                [-14.609305, 10.465972000000136],
                [-14.608473, 10.465694],
                [-14.606807, 10.465694],
                [-14.606807, 10.465972000000136],
                [-14.605139, 10.465972000000136],
                [-14.60486, 10.465416000000118],
                [-14.604029, 10.464584],
                [-14.603194, 10.464028],
                [-14.60236, 10.462916],
                [-14.60236, 10.462362],
                [-14.601528, 10.46125],
                [-14.600694, 10.460694],
                [-14.600694, 10.459860000000162],
                [-14.600415999999882, 10.45930600000014],
                [-14.599028, 10.458472],
                [-14.598194, 10.458194000000162],
                [-14.59764, 10.45764000000014],
                [-14.596806, 10.457362],
                [-14.594028, 10.457362],
                [-14.592915999999889, 10.456806],
                [-14.593472, 10.455693000000167],
                [-14.593472, 10.454862000000162],
                [-14.593749999999886, 10.454583000000184],
                [-14.593194, 10.454027000000167],
                [-14.588195, 10.454027000000167],
                [-14.587639, 10.453749],
                [-14.587361, 10.453195],
                [-14.586250999999834, 10.452917000000184],
                [-14.585971, 10.452361000000167],
                [-14.585416999999836, 10.452083],
                [-14.584029, 10.452083],
                [-14.584027, 10.451805],
                [-14.581251, 10.451805],
                [-14.581251, 10.451527000000112],
                [-14.578473, 10.451527000000112],
                [-14.577917, 10.451805],
                [-14.577085, 10.453471],
                [-14.577085, 10.454027000000167],
                [-14.576251, 10.454305],
                [-14.576251, 10.45514],
                [-14.575416, 10.45514],
                [-14.575416, 10.454027000000167],
                [-14.575137999999868, 10.454027000000167],
                [-14.575137999999868, 10.452917000000184],
                [-14.571805999999867, 10.452917000000184],
                [-14.571805999999867, 10.451805],
                [-14.57236, 10.452083],
                [-14.574863, 10.452083],
                [-14.574863, 10.449861000000112],
                [-14.574028, 10.448195000000112],
                [-14.572916, 10.447639],
                [-14.572916, 10.447083],
                [-14.572084, 10.446527000000117],
                [-14.571528, 10.446527000000117],
                [-14.570416, 10.445417],
                [-14.56875, 10.444583],
                [-14.568471999999872, 10.444029000000171],
                [-14.567918, 10.444029000000171],
                [-14.567637999999874, 10.443473],
                [-14.567084, 10.443473],
                [-14.566805999999872, 10.442917],
                [-14.56625, 10.442917],
                [-14.565971999999874, 10.442360000000122],
                [-14.565415, 10.442082],
                [-14.563470999999879, 10.440138000000104],
                [-14.564305999999874, 10.440138000000104],
                [-14.565139999999872, 10.440416],
                [-14.565696, 10.440972],
                [-14.566805999999872, 10.440972],
                [-14.56763999999987, 10.441251],
                [-14.568194, 10.441807],
                [-14.56930599999987, 10.442360000000122],
                [-14.570137999999872, 10.442360000000122],
                [-14.57125, 10.443195000000117],
                [-14.571805999999867, 10.444029000000171],
                [-14.57236, 10.444029000000171],
                [-14.57263799999987, 10.444583],
                [-14.57375, 10.444861000000117],
                [-14.574028, 10.445417],
                [-14.574582, 10.445417],
                [-14.574863, 10.445973],
                [-14.576251, 10.445973],
                [-14.576251, 10.446251000000188],
                [-14.57736, 10.446807],
                [-14.578473, 10.446807],
                [-14.578473, 10.447083],
                [-14.579583, 10.447083],
                [-14.579861, 10.446527000000117],
                [-14.578751, 10.445973],
                [-14.577637999999865, 10.445973],
                [-14.577085, 10.445417],
                [-14.576251, 10.445139],
                [-14.575137999999868, 10.445139],
                [-14.574863, 10.444583],
                [-14.574305999999865, 10.444583],
                [-14.57263799999987, 10.443751],
                [-14.57236, 10.442917],
                [-14.571805999999867, 10.442360000000122],
                [-14.570694, 10.442082],
                [-14.570137999999872, 10.441251],
                [-14.569584, 10.441251],
                [-14.56930599999987, 10.440694000000121],
                [-14.56875, 10.440694000000121],
                [-14.568471999999872, 10.440138000000104],
                [-14.567918, 10.440138000000104],
                [-14.567637999999874, 10.439584],
                [-14.567084, 10.439584],
                [-14.566805999999872, 10.439028000000121],
                [-14.56625, 10.439028000000121],
                [-14.565971999999874, 10.438472000000104],
                [-14.565415, 10.438472000000104],
                [-14.564862, 10.437916000000143],
                [-14.563749, 10.437360000000126],
                [-14.562918, 10.437084],
                [-14.560417, 10.437084],
                [-14.559583, 10.437360000000126],
                [-14.559027, 10.437084],
                [-14.558749, 10.436528],
                [-14.557638999999881, 10.436250000000143],
                [-14.557361, 10.435694000000126],
                [-14.556804999999883, 10.435694000000126],
                [-14.556528999999898, 10.435138000000109],
                [-14.555695, 10.435138000000109],
                [-14.553472999999883, 10.434028000000126],
                [-14.553195, 10.433472000000108],
                [-14.552638999999829, 10.433472000000108],
                [-14.552361, 10.432916000000148],
                [-14.551251, 10.43264],
                [-14.551248, 10.432084],
                [-14.550695, 10.432084],
                [-14.550417, 10.431528],
                [-14.54986, 10.431528],
                [-14.549582, 10.430972],
                [-14.54875, 10.430972],
                [-14.548194, 10.43069400000013],
                [-14.548194, 10.430137],
                [-14.547362, 10.429584000000148],
                [-14.546806, 10.429584000000148],
                [-14.546528, 10.42902800000013],
                [-14.546528, 10.428193000000135],
                [-14.545694, 10.427083000000152],
                [-14.54514, 10.426805],
                [-14.54486, 10.426252000000147],
                [-14.544306, 10.426252000000147],
                [-14.54375, 10.425417000000152],
                [-14.54375, 10.424861000000135],
                [-14.542362, 10.423473],
                [-14.542084, 10.423473],
                [-14.540416, 10.421805],
                [-14.540416, 10.421527],
                [-14.53902799999986, 10.420139],
                [-14.538472, 10.419861],
                [-14.537918, 10.419305000000179],
                [-14.53736199999986, 10.418195],
                [-14.536527, 10.417639000000179],
                [-14.535139, 10.416251],
                [-14.534305, 10.415973000000179],
                [-14.533195, 10.41486],
                [-14.533195, 10.414582000000166],
                [-14.532361, 10.414029],
                [-14.532361, 10.413472000000183],
                [-14.531805, 10.413194],
                [-14.531805, 10.412638],
                [-14.531249, 10.41236],
                [-14.530417, 10.411528],
                [-14.529583, 10.410138000000188],
                [-14.529026999999871, 10.409862],
                [-14.529029, 10.409306],
                [-14.528473, 10.409028],
                [-14.527639, 10.407916],
                [-14.527639, 10.407362],
                [-14.527082999999891, 10.407084000000111],
                [-14.527082999999891, 10.406528],
                [-14.526526999999874, 10.40625],
                [-14.526526999999874, 10.405694],
                [-14.525139, 10.402918],
                [-14.524582999999893, 10.402637],
                [-14.524582999999893, 10.402084000000116],
                [-14.52402899999987, 10.401806],
                [-14.52402899999987, 10.40124900000012],
                [-14.523473, 10.400971],
                [-14.523473, 10.400415],
                [-14.522915999999896, 10.400139000000138],
                [-14.522915999999896, 10.39958300000012],
                [-14.522359999999878, 10.399305],
                [-14.522359999999878, 10.398749],
                [-14.521525999999824, 10.397639],
                [-14.520693999999878, 10.396805000000143],
                [-14.520415999999898, 10.396249000000125],
                [-14.519859999999824, 10.395973],
                [-14.519583999999895, 10.394861],
                [-14.51764, 10.392917000000125],
                [-14.517359999999826, 10.392917000000125],
                [-14.515972, 10.391529],
                [-14.515693999999826, 10.390973],
                [-14.515138, 10.390973],
                [-14.514861999999823, 10.390417],
                [-14.514306, 10.390417],
                [-14.513194, 10.38958300000013],
                [-14.512916, 10.389029000000107],
                [-14.511528, 10.389029000000107],
                [-14.51125, 10.388195],
                [-14.51125, 10.387363000000107],
                [-14.510693, 10.387082],
                [-14.509584, 10.385972000000152],
                [-14.509027, 10.385694],
                [-14.506805, 10.383472],
                [-14.506527, 10.383472],
                [-14.505695, 10.382638000000156],
                [-14.505695, 10.382082],
                [-14.504027, 10.380416],
                [-14.503748999999857, 10.380416],
                [-14.502361, 10.379028],
                [-14.502082999999857, 10.378472000000102],
                [-14.501527, 10.378474],
                [-14.501250999999854, 10.377916],
                [-14.500695, 10.377640000000156],
                [-14.500695, 10.377084],
                [-14.499305, 10.37625],
                [-14.498750999999857, 10.375418],
                [-14.497917, 10.375140000000101],
                [-14.497917, 10.374584],
                [-14.497361, 10.374305000000106],
                [-14.496251, 10.373471],
                [-14.494582, 10.371805],
                [-14.494582, 10.371249],
                [-14.494028, 10.370973000000106],
                [-14.493194, 10.370139],
                [-14.492637999999886, 10.369861],
                [-14.49236, 10.36930500000011],
                [-14.49125, 10.368195],
                [-14.490971999999886, 10.36763900000011],
                [-14.490416, 10.367361000000187],
                [-14.490416, 10.366807],
                [-14.489584, 10.36597300000011],
                [-14.489305999999885, 10.365417],
                [-14.487918, 10.364029000000187],
                [-14.48763799999989, 10.363473],
                [-14.48625, 10.362082],
                [-14.48597199999989, 10.362082],
                [-14.484862, 10.360973000000115],
                [-14.48430599999989, 10.360694000000137],
                [-14.484028, 10.36013800000012],
                [-14.483470999999895, 10.35986],
                [-14.48264, 10.35875],
                [-14.48264, 10.358194],
                [-14.481804999999895, 10.35764],
                [-14.481527, 10.357084],
                [-14.480970999999897, 10.357084],
                [-14.480416999999818, 10.356250000000102],
                [-14.479304999999897, 10.355138000000125],
                [-14.478750999999818, 10.354862],
                [-14.478750999999818, 10.354306],
                [-14.47791699999982, 10.354028000000142],
                [-14.47791699999982, 10.353194],
                [-14.477361, 10.352916000000164],
                [-14.476249, 10.351806000000124],
                [-14.475695, 10.351528],
                [-14.4751389999999, 10.350694000000146],
                [-14.474305, 10.349862],
                [-14.474027, 10.349862],
                [-14.473195, 10.349028000000146],
                [-14.473195, 10.348471],
                [-14.471805, 10.347083000000168],
                [-14.471248, 10.347083000000168],
                [-14.470973, 10.346527000000151],
                [-14.470417, 10.346527000000151],
                [-14.46986, 10.345695],
                [-14.469307, 10.345695],
                [-14.46847199999985, 10.34486100000015],
                [-14.467363, 10.344305000000134],
                [-14.467082, 10.343749000000173],
                [-14.466528, 10.343749000000173],
                [-14.465971999999852, 10.342917],
                [-14.465416, 10.342917],
                [-14.464305999999851, 10.341805],
                [-14.46375, 10.340971],
                [-14.463194, 10.340695],
                [-14.463194, 10.340139],
                [-14.462638, 10.339861000000155],
                [-14.461806, 10.339027000000101],
                [-14.461806, 10.338473],
                [-14.460694, 10.338473],
                [-14.46014, 10.338751000000173],
                [-14.459028, 10.337639],
                [-14.459028, 10.336805],
                [-14.459306, 10.336251],
                [-14.46014, 10.336251],
                [-14.461806, 10.335417000000177],
                [-14.461806, 10.334860000000106],
                [-14.462362, 10.334307],
                [-14.462362, 10.332638],
                [-14.462082, 10.332638],
                [-14.462082, 10.331528000000105],
                [-14.461806, 10.330694],
                [-14.46125, 10.330138],
                [-14.460972, 10.329306],
                [-14.460972, 10.328472],
                [-14.460416, 10.327918000000182],
                [-14.46014, 10.327084000000127],
                [-14.46014, 10.326250000000186],
                [-14.459584, 10.325694],
                [-14.459584, 10.32486200000011],
                [-14.459028, 10.324306],
                [-14.459028, 10.322637],
                [-14.45875, 10.322637],
                [-14.45875, 10.321528000000114],
                [-14.458472, 10.321528000000114],
                [-14.458472, 10.320418000000132],
                [-14.458196, 10.319583000000137],
                [-14.45764, 10.31902700000012],
                [-14.45764, 10.318471000000159],
                [-14.457084, 10.317917000000136],
                [-14.457084, 10.317083],
                [-14.456528, 10.315973],
                [-14.456249, 10.315973],
                [-14.455692999999883, 10.314861],
                [-14.455692999999883, 10.313751],
                [-14.455418, 10.313751],
                [-14.455418, 10.312639],
                [-14.45486099999988, 10.312361000000124],
                [-14.45486099999988, 10.310417],
                [-14.454305, 10.308751],
                [-14.453749, 10.308195],
                [-14.453749, 10.307363000000123],
                [-14.45319499999988, 10.30708200000015],
                [-14.452917, 10.305972000000168],
                [-14.452083, 10.305694],
                [-14.451805, 10.304584],
                [-14.452639, 10.304304000000172],
                [-14.452917, 10.303472],
                [-14.452917, 10.302362],
                [-14.452639, 10.30125],
                [-14.452639, 10.2979160000001],
                [-14.452917, 10.2979160000001],
                [-14.452917, 10.296806],
                [-14.45319499999988, 10.295972000000177],
                [-14.453749, 10.294862],
                [-14.454026999999883, 10.294862],
                [-14.45486099999988, 10.294027],
                [-14.455418, 10.292915],
                [-14.455418, 10.292083000000105],
                [-14.455692999999883, 10.291527],
                [-14.456249, 10.291249],
                [-14.456806, 10.289861],
                [-14.456806, 10.28874900000011],
                [-14.457084, 10.28874900000011],
                [-14.457084, 10.285695],
                [-14.457362, 10.285139],
                [-14.457916, 10.284861],
                [-14.457916, 10.282361],
                [-14.458196, 10.282361],
                [-14.458196, 10.280138000000136],
                [-14.458472, 10.280138000000136],
                [-14.458472, 10.278472000000136],
                [-14.45875, 10.278472000000136],
                [-14.45875, 10.276528],
                [-14.459306, 10.276250000000118],
                [-14.459584, 10.275694000000158],
                [-14.459584, 10.274582000000123],
                [-14.460416, 10.272084],
                [-14.461528, 10.270972],
                [-14.462362, 10.270416],
                [-14.462916, 10.269306],
                [-14.462916, 10.26847400000014],
                [-14.463472, 10.267918000000122],
                [-14.463472, 10.267084],
                [-14.464028, 10.265971],
                [-14.464305999999851, 10.265139],
                [-14.464028, 10.264027],
                [-14.464028, 10.263193000000172],
                [-14.46375, 10.262639],
                [-14.464584, 10.262361],
                [-14.466805999999849, 10.260139],
                [-14.467637999999852, 10.259861000000171],
                [-14.467916, 10.259027000000117],
                [-14.467916, 10.257917],
                [-14.468194, 10.257083],
                [-14.46847199999985, 10.257083],
                [-14.46847199999985, 10.254026],
                [-14.467082, 10.254029000000116],
                [-14.466805999999849, 10.253473],
                [-14.465694, 10.253194000000121],
                [-14.465416, 10.252638000000104],
                [-14.46486, 10.25236],
                [-14.46486, 10.251804],
                [-14.46375, 10.250694],
                [-14.462916, 10.249028],
                [-14.462638, 10.249028],
                [-14.46125, 10.24625],
                [-14.460972, 10.245416000000148],
                [-14.460972, 10.244306000000108],
                [-14.460694, 10.244306000000108],
                [-14.460694, 10.242918],
                [-14.46014, 10.242638000000113],
                [-14.46014, 10.24125],
                [-14.460416, 10.24125],
                [-14.460416, 10.23986200000013],
                [-14.460694, 10.239305],
                [-14.46125, 10.239027000000135],
                [-14.46125, 10.238471000000118],
                [-14.461806, 10.238195],
                [-14.461806, 10.235973],
                [-14.460694, 10.235139000000117],
                [-14.460416, 10.234583000000157],
                [-14.459584, 10.23347100000018],
                [-14.45875, 10.232639],
                [-14.45875, 10.23236100000014],
                [-14.457362, 10.23236100000014],
                [-14.456806, 10.231805000000179],
                [-14.456806, 10.231249000000162],
                [-14.457362, 10.230973],
                [-14.457916, 10.231249000000162],
                [-14.461528, 10.231249000000162],
                [-14.461806, 10.23069500000014],
                [-14.461806, 10.228473000000179],
                [-14.461528, 10.228473000000179],
                [-14.461528, 10.22736],
                [-14.460694, 10.227082000000166],
                [-14.459862, 10.226251000000161],
                [-14.459862, 10.225694],
                [-14.459028, 10.22486],
                [-14.458472, 10.224584],
                [-14.458472, 10.224028],
                [-14.45764, 10.223472],
                [-14.45764, 10.222916000000112],
                [-14.456528, 10.220694],
                [-14.456249, 10.220694],
                [-14.455974, 10.219862],
                [-14.455971, 10.219028],
                [-14.455692999999883, 10.218472],
                [-14.45514, 10.218194],
                [-14.45514, 10.215972],
                [-14.455418, 10.215972],
                [-14.455418, 10.214862],
                [-14.456249, 10.214306],
                [-14.456806, 10.213196],
                [-14.456806, 10.21208300000012],
                [-14.456528, 10.21208300000012],
                [-14.456528, 10.210971000000143],
                [-14.455692999999883, 10.21041700000012],
                [-14.454583, 10.208195000000103],
                [-14.454305, 10.207361],
                [-14.454305, 10.206527000000108],
                [-14.452917, 10.206527000000108],
                [-14.452917, 10.206805],
                [-14.451805, 10.206805],
                [-14.451805, 10.207083000000125],
                [-14.450694999999882, 10.207083000000125],
                [-14.450694999999882, 10.207361],
                [-14.449583, 10.207361],
                [-14.448751, 10.207639000000142],
                [-14.448749, 10.207917],
                [-14.447639, 10.207917],
                [-14.447639, 10.208195000000103],
                [-14.446807, 10.208473],
                [-14.445417, 10.208473],
                [-14.445417, 10.208749000000125],
                [-14.442638, 10.208749000000125],
                [-14.442359999999894, 10.209305000000143],
                [-14.441806999999812, 10.209305000000143],
                [-14.44125, 10.209861000000103],
                [-14.440972, 10.21041700000012],
                [-14.440416, 10.21041700000012],
                [-14.439584, 10.21208300000012],
                [-14.439584, 10.21264],
                [-14.439027999999894, 10.212918000000116],
                [-14.439027999999894, 10.213474],
                [-14.438193999999896, 10.214028],
                [-14.437916, 10.214584000000116],
                [-14.437916, 10.21541800000017],
                [-14.437359999999899, 10.216250000000116],
                [-14.43625, 10.21708400000017],
                [-14.435693999999899, 10.21708400000017],
                [-14.435416, 10.217640000000188],
                [-14.434861999999896, 10.217640000000188],
                [-14.434584, 10.218194],
                [-14.43375, 10.218194],
                [-14.433193999999844, 10.218472],
                [-14.43264, 10.219028],
                [-14.43264, 10.219862],
                [-14.431806, 10.221806],
                [-14.43125, 10.22208200000017],
                [-14.430972, 10.223194],
                [-14.430418, 10.223472],
                [-14.430418, 10.224028],
                [-14.429584, 10.224304000000188],
                [-14.429306, 10.22486],
                [-14.427639, 10.22486],
                [-14.427361, 10.224028],
                [-14.425417, 10.224028],
                [-14.425139, 10.22486],
                [-14.424583, 10.225138],
                [-14.424583, 10.225694],
                [-14.423749, 10.225972000000183],
                [-14.423473, 10.226529],
                [-14.422361, 10.226529],
                [-14.422361, 10.226807000000179],
                [-14.418473, 10.226807000000179],
                [-14.418473, 10.226251000000161],
                [-14.416804, 10.226251000000161],
                [-14.416804, 10.225972000000183],
                [-14.41486, 10.225972000000183],
                [-14.414307, 10.225694],
                [-14.414028, 10.225138],
                [-14.413472, 10.225138],
                [-14.413194, 10.224584],
                [-14.412638, 10.224304000000188],
                [-14.411804, 10.224304000000188],
                [-14.410972, 10.223750000000166],
                [-14.410972, 10.223194],
                [-14.410415999999884, 10.223194],
                [-14.410415999999884, 10.222638000000188],
                [-14.409583999999882, 10.222362],
                [-14.408194, 10.222362],
                [-14.407917999999881, 10.222916000000112],
                [-14.407362, 10.222916000000112],
                [-14.405972, 10.225694],
                [-14.405415999999889, 10.226251000000161],
                [-14.40514, 10.226807000000179],
                [-14.40514, 10.227639],
                [-14.40403, 10.228751],
                [-14.403472, 10.228751],
                [-14.403472, 10.229305],
                [-14.402917999999886, 10.229583000000162],
                [-14.402637, 10.23069500000014],
                [-14.402083999999888, 10.230973],
                [-14.401527, 10.232083],
                [-14.401527, 10.232639],
                [-14.400971, 10.232917000000157],
                [-14.400971, 10.23347100000018],
                [-14.400139, 10.23402700000014],
                [-14.399027, 10.235139000000117],
                [-14.398471, 10.235417],
                [-14.396805, 10.235417],
                [-14.396805, 10.235695000000135],
                [-14.395973, 10.235973],
                [-14.390139, 10.235973],
                [-14.389582, 10.236805000000118],
                [-14.387363, 10.236805000000118],
                [-14.385694, 10.236249000000157],
                [-14.38513799999987, 10.235695000000135],
                [-14.38347199999987, 10.235695000000135],
                [-14.38347199999987, 10.235973],
                [-14.381528, 10.235973],
                [-14.380971999999872, 10.235417],
                [-14.38013999999987, 10.235417],
                [-14.379584, 10.234861],
                [-14.378471999999874, 10.234861],
                [-14.378471999999874, 10.234583000000157],
                [-14.377362, 10.234583000000157],
                [-14.376805999999874, 10.23402700000014],
                [-14.375970999999879, 10.23402700000014],
                [-14.375418, 10.23347100000018],
                [-14.374304999999879, 10.23347100000018],
                [-14.370972999999879, 10.23236100000014],
                [-14.370417, 10.231805000000179],
                [-14.369583, 10.231805000000179],
                [-14.367917, 10.230973],
                [-14.367083, 10.23069500000014],
                [-14.363751, 10.22902900000014],
                [-14.363748, 10.228751],
                [-14.362638999999888, 10.228195],
                [-14.362082, 10.228195],
                [-14.360971999999833, 10.227639],
                [-14.360137999999836, 10.22736],
                [-14.358471999999836, 10.226529],
                [-14.358194, 10.225972000000183],
                [-14.357639999999833, 10.225972000000183],
                [-14.35736, 10.225416000000166],
                [-14.356805999999835, 10.225138],
                [-14.355972, 10.225138],
                [-14.355972, 10.224584],
                [-14.354862, 10.224304000000188],
                [-14.354584, 10.223750000000166],
                [-14.35375, 10.223750000000166],
                [-14.353472, 10.223194],
                [-14.352916, 10.223194],
                [-14.35236, 10.222638000000188],
                [-14.350418, 10.222638000000188],
                [-14.349584, 10.222362],
                [-14.349584, 10.22208200000017],
                [-14.348474, 10.22208200000017],
                [-14.348192999999867, 10.221528],
                [-14.34764, 10.221528],
                [-14.347360999999864, 10.220972000000188],
                [-14.346805, 10.220972000000188],
                [-14.344026999999869, 10.218194],
                [-14.344026999999869, 10.217916000000116],
                [-14.343192999999872, 10.217640000000188],
                [-14.340695, 10.217640000000188],
                [-14.340139, 10.21708400000017],
                [-14.339307, 10.21708400000017],
                [-14.338751, 10.216806],
                [-14.338751, 10.216250000000116],
                [-14.337917, 10.215972],
                [-14.337639, 10.21541800000017],
                [-14.336805, 10.21541800000017],
                [-14.335138, 10.214584000000116],
                [-14.335138, 10.214028],
                [-14.334581999999898, 10.214028],
                [-14.334581999999898, 10.213471],
                [-14.333193999999878, 10.212361],
                [-14.332638, 10.212361],
                [-14.33235999999988, 10.211805],
                [-14.331804, 10.211805],
                [-14.331249999999898, 10.211249],
                [-14.330416, 10.211249],
                [-14.32902799999988, 10.209861000000103],
                [-14.327917999999897, 10.209305000000143],
                [-14.32736199999988, 10.208473],
                [-14.326806, 10.208473],
                [-14.326527999999882, 10.207917],
                [-14.325972, 10.207917],
                [-14.325972, 10.207361],
                [-14.32514, 10.207083000000125],
                [-14.324584, 10.206251],
                [-14.324027999999828, 10.206251],
                [-14.322918, 10.205417000000125],
                [-14.322637, 10.204861000000108],
                [-14.321527, 10.204029],
                [-14.320139, 10.203195000000107],
                [-14.317083, 10.200138000000152],
                [-14.316249, 10.200138000000152],
                [-14.315695, 10.199582000000134],
                [-14.314861, 10.199582000000134],
                [-14.314305, 10.200138000000152],
                [-14.312917, 10.200138000000152],
                [-14.312917, 10.20041700000013],
                [-14.309304, 10.20041700000013],
                [-14.309029, 10.200695],
                [-14.309029, 10.201526],
                [-14.308750999999859, 10.201805],
                [-14.307082, 10.201805],
                [-14.306528, 10.202917],
                [-14.306528, 10.204029],
                [-14.30625, 10.204029],
                [-14.30625, 10.205417000000125],
                [-14.305972, 10.205417000000125],
                [-14.305972, 10.206805],
                [-14.306528, 10.207917],
                [-14.306807, 10.208749000000125],
                [-14.307916, 10.210971000000143],
                [-14.308195, 10.210971000000143],
                [-14.308750999999859, 10.21208300000012],
                [-14.309861, 10.21541800000017],
                [-14.309861, 10.216528],
                [-14.310139, 10.216528],
                [-14.310139, 10.219862],
                [-14.309861, 10.219862],
                [-14.309861, 10.221250000000111],
                [-14.309585, 10.22208200000017],
                [-14.309029, 10.223194],
                [-14.308473, 10.223750000000166],
                [-14.307916, 10.223750000000166],
                [-14.30736, 10.224584],
                [-14.30625, 10.224584],
                [-14.30625, 10.22486],
                [-14.305138, 10.22486],
                [-14.305138, 10.225138],
                [-14.303472, 10.225138],
                [-14.302916, 10.224304000000188],
                [-14.302362, 10.224304000000188],
                [-14.301806, 10.223472],
                [-14.30125, 10.223472],
                [-14.300972, 10.222916000000112],
                [-14.30041599999987, 10.222638000000188],
                [-14.300418, 10.22208200000017],
                [-14.299862, 10.221806],
                [-14.299862, 10.22041600000017],
                [-14.299584, 10.22041600000017],
                [-14.299584, 10.219028],
                [-14.299306, 10.219028],
                [-14.299306, 10.217916000000116],
                [-14.299028, 10.217916000000116],
                [-14.299028, 10.216806],
                [-14.298196, 10.21514],
                [-14.29680599999989, 10.214306],
                [-14.296249999999873, 10.213471],
                [-14.293748999999877, 10.21264],
                [-14.292361, 10.21264],
                [-14.292361, 10.212361],
                [-14.290972999999894, 10.212361],
                [-14.290971, 10.21264],
                [-14.289861, 10.21264],
                [-14.289029, 10.212915],
                [-14.287916999999823, 10.214028],
                [-14.287361, 10.214306],
                [-14.286527, 10.215137],
                [-14.286250999999822, 10.215694],
                [-14.285695, 10.216250000000116],
                [-14.284861, 10.217362],
                [-14.283749, 10.219028],
                [-14.282917, 10.219584000000111],
                [-14.282917, 10.220138],
                [-14.282361, 10.22041600000017],
                [-14.282361, 10.220972000000188],
                [-14.281807, 10.221250000000111],
                [-14.281807, 10.221806],
                [-14.286250999999822, 10.221806],
                [-14.286805, 10.221250000000111],
                [-14.288195, 10.221250000000111],
                [-14.288195, 10.220972000000188],
                [-14.290971, 10.220972000000188],
                [-14.292082999999877, 10.22208200000017],
                [-14.292638999999895, 10.22208200000017],
                [-14.292914999999823, 10.222638000000188],
                [-14.293470999999897, 10.222916000000112],
                [-14.293748999999877, 10.224028],
                [-14.294304999999895, 10.224304000000188],
                [-14.294583999999873, 10.22486],
                [-14.294583999999873, 10.226251000000161],
                [-14.294027, 10.226807000000179],
                [-14.293470999999897, 10.226807000000179],
                [-14.292914999999823, 10.22736],
                [-14.292638999999895, 10.227917000000161],
                [-14.291248999999823, 10.227917000000161],
                [-14.290695, 10.22736],
                [-14.289861, 10.22736],
                [-14.2893049999999, 10.226529],
                [-14.288748999999825, 10.226529],
                [-14.288195, 10.225972000000183],
                [-14.285695, 10.225972000000183],
                [-14.285139, 10.226251000000161],
                [-14.284861, 10.226807000000179],
                [-14.284305, 10.226807000000179],
                [-14.284305, 10.22736],
                [-14.283749, 10.227639],
                [-14.283473, 10.228473000000179],
                [-14.283473, 10.229583000000162],
                [-14.283195, 10.229583000000162],
                [-14.283195, 10.23069500000014],
                [-14.283473, 10.231249000000162],
                [-14.284583, 10.231805000000179],
                [-14.285695, 10.231805000000179],
                [-14.285695, 10.231527],
                [-14.2876389999999, 10.231529],
                [-14.2876389999999, 10.231249000000162],
                [-14.289029, 10.231249000000162],
                [-14.289861, 10.231805000000179],
                [-14.289861, 10.234305],
                [-14.289582999999823, 10.234583000000157],
                [-14.288472999999897, 10.234583000000157],
                [-14.288472999999897, 10.23402700000014],
                [-14.289029, 10.233751],
                [-14.289029, 10.23236100000014],
                [-14.288748999999825, 10.232083],
                [-14.288195, 10.23236100000014],
                [-14.286805, 10.23236100000014],
                [-14.286805, 10.232639],
                [-14.284583, 10.232639],
                [-14.283473, 10.232083],
                [-14.283473, 10.231805000000179],
                [-14.282639, 10.231249000000162],
                [-14.282361, 10.23069500000014],
                [-14.282361, 10.229583000000162],
                [-14.282639, 10.229583000000162],
                [-14.282639, 10.228473000000179],
                [-14.282917, 10.227639],
                [-14.283473, 10.22736],
                [-14.283473, 10.226807000000179],
                [-14.284029, 10.226529],
                [-14.284305, 10.225972000000183],
                [-14.284861, 10.225972000000183],
                [-14.285416999999825, 10.225138],
                [-14.288472999999897, 10.225138],
                [-14.288472999999897, 10.225416000000166],
                [-14.289582999999823, 10.225416000000166],
                [-14.289861, 10.225972000000183],
                [-14.290971, 10.226251000000161],
                [-14.291527, 10.226807000000179],
                [-14.292638999999895, 10.226807000000179],
                [-14.293196, 10.226529],
                [-14.293748999999877, 10.225972000000183],
                [-14.293748999999877, 10.22486],
                [-14.293470999999897, 10.224304000000188],
                [-14.292914999999823, 10.224028],
                [-14.292638999999895, 10.222916000000112],
                [-14.291527, 10.222638000000188],
                [-14.291248999999823, 10.22208200000017],
                [-14.287916999999823, 10.22208200000017],
                [-14.287361, 10.222916000000112],
                [-14.285139, 10.222916000000112],
                [-14.285139, 10.223194],
                [-14.283195, 10.223194],
                [-14.283195, 10.222916000000112],
                [-14.282082, 10.222916000000112],
                [-14.281807, 10.222362],
                [-14.280416, 10.222362],
                [-14.280141, 10.223194],
                [-14.279306, 10.223750000000166],
                [-14.277916, 10.226529],
                [-14.277916, 10.227639],
                [-14.27764, 10.227639],
                [-14.277637999999854, 10.228751],
                [-14.277084, 10.229305],
                [-14.277084, 10.229861],
                [-14.27625, 10.231529],
                [-14.275694, 10.231805000000179],
                [-14.275694, 10.23402700000014],
                [-14.275971999999854, 10.234583000000157],
                [-14.276528, 10.234583000000157],
                [-14.277084, 10.235417],
                [-14.280694, 10.235417],
                [-14.280694, 10.235139000000117],
                [-14.282917, 10.235139000000117],
                [-14.283473, 10.235417],
                [-14.284861, 10.236805000000118],
                [-14.284861, 10.238195],
                [-14.284583, 10.238749],
                [-14.284029, 10.239027000000135],
                [-14.284029, 10.239583000000152],
                [-14.283473, 10.24014],
                [-14.282917, 10.24014],
                [-14.282639, 10.240693000000135],
                [-14.281807, 10.240693000000135],
                [-14.281529, 10.24014],
                [-14.282639, 10.239583000000152],
                [-14.283473, 10.238471000000118],
                [-14.284029, 10.238195],
                [-14.284029, 10.237083],
                [-14.283749, 10.237083],
                [-14.282639, 10.235973],
                [-14.281529, 10.235973],
                [-14.281529, 10.236249000000157],
                [-14.280416, 10.236249000000157],
                [-14.280416, 10.236527],
                [-14.277637999999854, 10.236527],
                [-14.27625, 10.235973],
                [-14.275416, 10.235139000000117],
                [-14.274582, 10.23402700000014],
                [-14.274582, 10.23236100000014],
                [-14.274862, 10.231805000000179],
                [-14.275416, 10.231529],
                [-14.27625, 10.229861],
                [-14.27625, 10.228751],
                [-14.276528, 10.227917000000161],
                [-14.276806, 10.227917000000161],
                [-14.276806, 10.226807000000179],
                [-14.277084, 10.225972000000183],
                [-14.277637999999854, 10.225416000000166],
                [-14.277916, 10.224584],
                [-14.277916, 10.223750000000166],
                [-14.278472, 10.223472],
                [-14.278472, 10.222638000000188],
                [-14.279028, 10.22208200000017],
                [-14.279028, 10.220694],
                [-14.278472, 10.220138],
                [-14.277637999999854, 10.219862],
                [-14.277084, 10.21875000000017],
                [-14.277084, 10.217362],
                [-14.276806, 10.217362],
                [-14.276806, 10.216250000000116],
                [-14.276528, 10.21541800000017],
                [-14.275971999999854, 10.21541800000017],
                [-14.275694, 10.214862],
                [-14.274862, 10.214584000000116],
                [-14.272084, 10.214584000000116],
                [-14.271528, 10.21514],
                [-14.271528, 10.215972],
                [-14.270972, 10.216806],
                [-14.270139999999856, 10.21708400000017],
                [-14.269584, 10.217640000000188],
                [-14.26875, 10.217916000000116],
                [-14.26764, 10.217916000000116],
                [-14.266249, 10.217362],
                [-14.265971, 10.216806],
                [-14.264305, 10.216806],
                [-14.263749, 10.216250000000116],
                [-14.262917, 10.216250000000116],
                [-14.262360999999885, 10.215694],
                [-14.262083, 10.215137],
                [-14.262083, 10.21374900000012],
                [-14.263192999999887, 10.213196],
                [-14.263473, 10.21264],
                [-14.264026999999885, 10.212361],
                [-14.265417, 10.212361],
                [-14.265417, 10.21208300000012],
                [-14.266805, 10.21208300000012],
                [-14.267083, 10.211805],
                [-14.267083, 10.21041700000012],
                [-14.266249, 10.209861000000103],
                [-14.265417, 10.209861000000103],
                [-14.264305, 10.209027],
                [-14.264026999999885, 10.208473],
                [-14.262360999999885, 10.208473],
                [-14.258751, 10.21208300000012],
                [-14.257917, 10.21264],
                [-14.257917, 10.213196],
                [-14.257085, 10.21374900000012],
                [-14.257085, 10.214584000000116],
                [-14.256251, 10.214862],
                [-14.256251, 10.21541800000017],
                [-14.25569499999989, 10.215972],
                [-14.254859999999894, 10.216250000000116],
                [-14.252084, 10.216250000000116],
                [-14.251805999999817, 10.215694],
                [-14.25125, 10.215694],
                [-14.249584, 10.214862],
                [-14.248472, 10.21374900000012],
                [-14.248472, 10.21264],
                [-14.248193999999899, 10.21264],
                [-14.248193999999899, 10.211249],
                [-14.248472, 10.210695],
                [-14.247638, 10.210695],
                [-14.247084, 10.210971000000143],
                [-14.247084, 10.211805],
                [-14.24625, 10.21208300000012],
                [-14.245972, 10.210971000000143],
                [-14.245416, 10.210695],
                [-14.244584, 10.210695],
                [-14.243472, 10.209861000000103],
                [-14.243472, 10.209305000000143],
                [-14.242918, 10.209027],
                [-14.242918, 10.205973000000142],
                [-14.242084, 10.205695],
                [-14.242084, 10.205139],
                [-14.241249, 10.204861000000108],
                [-14.241249, 10.204305000000147],
                [-14.240692999999851, 10.204305000000147],
                [-14.240418, 10.20374900000013],
                [-14.23902699999985, 10.202361],
                [-14.238194999999848, 10.20208300000013],
                [-14.235417, 10.20208300000013],
                [-14.235417, 10.202361],
                [-14.234305, 10.202361],
                [-14.234027, 10.202917],
                [-14.233195, 10.203195000000107],
                [-14.233195, 10.207083000000125],
                [-14.233471, 10.207083000000125],
                [-14.233471, 10.209027],
                [-14.233195, 10.209861000000103],
                [-14.232083, 10.210695],
                [-14.231251, 10.210695],
                [-14.231251, 10.211249],
                [-14.230417, 10.211249],
                [-14.230139, 10.210971000000143],
                [-14.230417, 10.210139],
                [-14.231251, 10.210139],
                [-14.232083, 10.209861000000103],
                [-14.232639, 10.209027],
                [-14.232639, 10.207083000000125],
                [-14.232361, 10.207083000000125],
                [-14.232361, 10.203195000000107],
                [-14.233195, 10.202639000000147],
                [-14.234305, 10.201526],
                [-14.235139, 10.201251],
                [-14.238194999999848, 10.201251],
                [-14.238194999999848, 10.201526],
                [-14.239305, 10.201526],
                [-14.239860999999848, 10.20208300000013],
                [-14.239860999999848, 10.202639000000147],
                [-14.240418, 10.202639000000147],
                [-14.241249, 10.20374900000013],
                [-14.241806, 10.20374900000013],
                [-14.242918, 10.204029],
                [-14.243472, 10.203195000000107],
                [-14.243472, 10.202361],
                [-14.24375, 10.201805],
                [-14.243194, 10.201526],
                [-14.242084, 10.20041700000013],
                [-14.238752, 10.199307000000147],
                [-14.238749, 10.199029],
                [-14.237917, 10.19875],
                [-14.236805, 10.19875],
                [-14.236805, 10.198472000000152],
                [-14.235417, 10.198472000000152],
                [-14.235139, 10.197916000000134],
                [-14.234583, 10.197916000000134],
                [-14.234305, 10.197360000000174],
                [-14.232917, 10.196806000000151],
                [-14.231805, 10.196806000000151],
                [-14.230973, 10.196528],
                [-14.228195, 10.195138000000156],
                [-14.227917, 10.194582],
                [-14.22736, 10.194582],
                [-14.227081999999882, 10.194028000000174],
                [-14.22624999999988, 10.194028000000174],
                [-14.22624999999988, 10.193472000000156],
                [-14.225694, 10.193194],
                [-14.224028, 10.193194],
                [-14.224028, 10.19375],
                [-14.223194, 10.194306],
                [-14.221528, 10.194306],
                [-14.220972, 10.194862],
                [-14.219862, 10.194862],
                [-14.219306, 10.195138000000156],
                [-14.219028, 10.195694000000174],
                [-14.218472, 10.195694000000174],
                [-14.218193999999812, 10.196250000000134],
                [-14.21764, 10.196250000000134],
                [-14.217083999999886, 10.197084],
                [-14.217083999999886, 10.198472000000152],
                [-14.21764, 10.198472000000152],
                [-14.218193999999812, 10.199307000000147],
                [-14.219028, 10.200138000000152],
                [-14.219028, 10.200695],
                [-14.219583999999884, 10.201251],
                [-14.220138, 10.201251],
                [-14.220972, 10.20208300000013],
                [-14.220972, 10.202639000000147],
                [-14.221528, 10.20374900000013],
                [-14.222083999999882, 10.204029],
                [-14.222083999999882, 10.206251],
                [-14.221806, 10.206251],
                [-14.221806, 10.207361],
                [-14.221249999999884, 10.207639000000142],
                [-14.221249999999884, 10.208195000000103],
                [-14.220415999999886, 10.208473],
                [-14.220138, 10.209027],
                [-14.219583999999884, 10.209027],
                [-14.219306, 10.209583],
                [-14.218749999999886, 10.209583],
                [-14.218472, 10.210139],
                [-14.216249999999889, 10.210139],
                [-14.216249999999889, 10.209861000000103],
                [-14.215137, 10.209861000000103],
                [-14.214583999999888, 10.209305000000143],
                [-14.214583999999888, 10.208749000000125],
                [-14.213748999999893, 10.208473],
                [-14.213748999999893, 10.207917],
                [-14.213196, 10.207639000000142],
                [-14.212639, 10.206527000000108],
                [-14.210695, 10.206527000000108],
                [-14.210695, 10.206805],
                [-14.208748999999898, 10.206805],
                [-14.208748999999898, 10.207083000000125],
                [-14.207082999999898, 10.207639000000142],
                [-14.205139, 10.207639000000142],
                [-14.204305, 10.207917],
                [-14.203748999999846, 10.208473],
                [-14.203748999999846, 10.210971000000143],
                [-14.204305, 10.211249],
                [-14.204305, 10.21264],
                [-14.204029, 10.21264],
                [-14.204029, 10.214028],
                [-14.203473, 10.214584000000116],
                [-14.203473, 10.215694],
                [-14.203195, 10.216250000000116],
                [-14.202639, 10.216528],
                [-14.202639, 10.21708400000017],
                [-14.202082999999845, 10.217362],
                [-14.201529, 10.218472],
                [-14.201529, 10.224584],
                [-14.202082999999845, 10.225138],
                [-14.202082999999845, 10.226529],
                [-14.201250999999843, 10.227639],
                [-14.200416, 10.228195],
                [-14.200416, 10.228473000000179],
                [-14.199307, 10.228473000000179],
                [-14.199307, 10.228751],
                [-14.197916, 10.228751],
                [-14.197916, 10.22902900000014],
                [-14.197084, 10.229305],
                [-14.195694, 10.229305],
                [-14.195694, 10.230139000000179],
                [-14.195416, 10.23069500000014],
                [-14.194584, 10.23069500000014],
                [-14.194306, 10.231249000000162],
                [-14.19264, 10.23236100000014],
                [-14.191528, 10.23236100000014],
                [-14.191528, 10.231805000000179],
                [-14.190694, 10.231249000000162],
                [-14.190416, 10.230139000000179],
                [-14.189305999999874, 10.22902900000014],
                [-14.189305999999874, 10.228195],
                [-14.18875, 10.227917000000161],
                [-14.18875, 10.227082000000166],
                [-14.188471999999877, 10.226251000000161],
                [-14.188193, 10.226251000000161],
                [-14.187918, 10.225416000000166],
                [-14.187918, 10.223472],
                [-14.187639999999874, 10.223472],
                [-14.187639999999874, 10.222362],
                [-14.187362, 10.222362],
                [-14.187362, 10.218472],
                [-14.187639999999874, 10.218472],
                [-14.187639999999874, 10.216806],
                [-14.187918, 10.216806],
                [-14.188471999999877, 10.215694],
                [-14.188471999999877, 10.215137],
                [-14.189028, 10.215137],
                [-14.189028, 10.214584000000116],
                [-14.189584, 10.214028],
                [-14.19014, 10.214028],
                [-14.190416, 10.213196],
                [-14.190971999999874, 10.21264],
                [-14.191528, 10.21264],
                [-14.192637999999874, 10.211805],
                [-14.19264, 10.211249],
                [-14.19375, 10.210971000000143],
                [-14.194028, 10.21041700000012],
                [-14.195138, 10.210139],
                [-14.195416, 10.209583],
                [-14.195972, 10.209583],
                [-14.197638, 10.208749000000125],
                [-14.197638, 10.208195000000103],
                [-14.198472, 10.207917],
                [-14.200416, 10.205971000000147],
                [-14.200695, 10.205139],
                [-14.200695, 10.204029],
                [-14.200138, 10.203473],
                [-14.200138, 10.202639000000147],
                [-14.199307, 10.200973000000147],
                [-14.198472, 10.20041700000013],
                [-14.198472, 10.200138000000152],
                [-14.197638, 10.199307000000147],
                [-14.197084, 10.199029],
                [-14.196806, 10.198472000000152],
                [-14.19625, 10.198194],
                [-14.194584, 10.198194],
                [-14.194028, 10.19875],
                [-14.19375, 10.199307000000147],
                [-14.193194, 10.199582000000134],
                [-14.189862, 10.199582000000134],
                [-14.188193, 10.199029],
                [-14.188193, 10.19875],
                [-14.187084, 10.19875],
                [-14.185970999999881, 10.198194],
                [-14.185970999999881, 10.197638],
                [-14.185415, 10.197360000000174],
                [-14.185415, 10.196806000000151],
                [-14.184583, 10.196250000000134],
                [-14.183749, 10.196250000000134],
                [-14.183193, 10.195694000000174],
                [-14.182917, 10.195138000000156],
                [-14.182917, 10.194028000000174],
                [-14.183193, 10.194028000000174],
                [-14.183193, 10.192916],
                [-14.182638999999881, 10.19264],
                [-14.182361, 10.191528],
                [-14.181804999999883, 10.19125],
                [-14.180695, 10.190140000000156],
                [-14.179861, 10.189862],
                [-14.179861, 10.189584],
                [-14.177083, 10.189584],
                [-14.177083, 10.189862],
                [-14.175419, 10.189862],
                [-14.175419, 10.190140000000156],
                [-14.174306999999885, 10.190140000000156],
                [-14.174306999999885, 10.190418],
                [-14.170971999999892, 10.190416],
                [-14.170971999999892, 10.190694000000178],
                [-14.169584, 10.190694000000178],
                [-14.169584, 10.190974],
                [-14.168471999999838, 10.190972],
                [-14.167916, 10.191528],
                [-14.166805999999838, 10.191528],
                [-14.165416, 10.192084],
                [-14.164584, 10.192916],
                [-14.164584, 10.193472000000156],
                [-14.164028, 10.19375],
                [-14.163194, 10.195416],
                [-14.163194, 10.197360000000174],
                [-14.162638, 10.197638],
                [-14.162638, 10.198472000000152],
                [-14.162362, 10.199029],
                [-14.161806, 10.199582000000134],
                [-14.161528, 10.200138000000152],
                [-14.160971, 10.20041700000013],
                [-14.16014, 10.201526],
                [-14.16014, 10.20208300000013],
                [-14.158749, 10.203473],
                [-14.158471, 10.204305000000147],
                [-14.157917, 10.204583],
                [-14.156805, 10.205695],
                [-14.156251, 10.205973000000142],
                [-14.155973, 10.206527000000108],
                [-14.155417, 10.206251],
                [-14.155417, 10.205417000000125],
                [-14.156251, 10.205417000000125],
                [-14.157639, 10.204029],
                [-14.157917, 10.202917],
                [-14.158471, 10.202639000000147],
                [-14.158471, 10.20208300000013],
                [-14.159583, 10.201526],
                [-14.159583, 10.200973000000147],
                [-14.16014, 10.200695],
                [-14.16014, 10.200138000000152],
                [-14.160693, 10.19986],
                [-14.16125, 10.19875],
                [-14.16125, 10.197638],
                [-14.161528, 10.197638],
                [-14.161528, 10.195416],
                [-14.162362, 10.19375],
                [-14.163472, 10.19264],
                [-14.16430599999984, 10.191528],
                [-14.165139999999838, 10.190974],
                [-14.165139999999838, 10.190694000000178],
                [-14.16625, 10.190140000000156],
                [-14.16763799999984, 10.190140000000156],
                [-14.168471999999838, 10.189862],
                [-14.168471999999838, 10.189584],
                [-14.170694, 10.189584],
                [-14.170971999999892, 10.189028000000178],
                [-14.170971999999892, 10.188194],
                [-14.170416, 10.188194],
                [-14.169028, 10.18680600000016],
                [-14.16875, 10.186249],
                [-14.168194, 10.185971000000166],
                [-14.167362, 10.185971000000166],
                [-14.167084, 10.185415],
                [-14.16597199999984, 10.184305000000165],
                [-14.165416, 10.184305000000165],
                [-14.164584, 10.183473000000106],
                [-14.164028, 10.183193000000188],
                [-14.16375, 10.182639000000165],
                [-14.163194, 10.182639000000165],
                [-14.162916, 10.182083],
                [-14.162362, 10.182083],
                [-14.162084, 10.181527000000187],
                [-14.160971, 10.181249],
                [-14.160693, 10.180695],
                [-14.159583, 10.18013900000011],
                [-14.158471, 10.18013900000011],
                [-14.158471, 10.179861000000187],
                [-14.157361, 10.179861000000187],
                [-14.156805, 10.179583],
                [-14.156251, 10.179027],
                [-14.154583, 10.179027],
                [-14.154583, 10.178751],
                [-14.152083, 10.178751],
                [-14.152083, 10.17847300000011],
                [-14.150973, 10.17847300000011],
                [-14.150694999999871, 10.178751],
                [-14.150139, 10.177917],
                [-14.149305, 10.177083],
                [-14.148194999999873, 10.176805000000115],
                [-14.147639, 10.175973],
                [-14.147639, 10.175417],
                [-14.147082, 10.175417],
                [-14.146251, 10.174583],
                [-14.145972, 10.174026],
                [-14.145138, 10.173195],
                [-14.145138, 10.17263800000012],
                [-14.144584, 10.172363],
                [-14.144027999999878, 10.17097200000012],
                [-14.144027999999878, 10.169860000000142],
                [-14.14375, 10.169860000000142],
                [-14.14375, 10.167362],
                [-14.144027999999878, 10.167362],
                [-14.144584, 10.16625],
                [-14.144584, 10.165694],
                [-14.145138, 10.16514],
                [-14.145693999999878, 10.16514],
                [-14.145972, 10.164584],
                [-14.147082, 10.164028],
                [-14.147639, 10.164028],
                [-14.148751, 10.163472],
                [-14.148751, 10.163194000000146],
                [-14.149860999999873, 10.162638000000129],
                [-14.150694999999871, 10.162638000000129],
                [-14.150973, 10.162084000000107],
                [-14.152083, 10.16125],
                [-14.152639, 10.16125],
                [-14.152639, 10.160418000000107],
                [-14.152083, 10.16014],
                [-14.151805, 10.159584],
                [-14.151251, 10.159584],
                [-14.150973, 10.159027000000151],
                [-14.150417, 10.159027000000151],
                [-14.150139, 10.158471000000134],
                [-14.149583, 10.158471000000134],
                [-14.149305, 10.157917000000168],
                [-14.148194999999873, 10.156805000000134],
                [-14.147917, 10.156249000000173],
                [-14.147359999999878, 10.156249000000173],
                [-14.146807, 10.155417],
                [-14.145972, 10.155417],
                [-14.145138, 10.155693000000156],
                [-14.144304, 10.156527],
                [-14.144027999999878, 10.157083],
                [-14.142916, 10.157083],
                [-14.142916, 10.15736100000015],
                [-14.142084, 10.157639],
                [-14.13986199999988, 10.157639],
                [-14.139306, 10.157083],
                [-14.138472, 10.156805000000134],
                [-14.137361999999882, 10.155693000000156],
                [-14.136806, 10.155693000000156],
                [-14.13625, 10.155139000000133],
                [-14.134584, 10.155139000000133],
                [-14.134027999999887, 10.155417],
                [-14.133752, 10.155973],
                [-14.133195999999884, 10.155973],
                [-14.132360999999833, 10.157083],
                [-14.131249, 10.157917000000168],
                [-14.131249, 10.160693000000151],
                [-14.131805, 10.16125],
                [-14.131805, 10.162084000000107],
                [-14.132083, 10.162916],
                [-14.13264, 10.163472],
                [-14.13264, 10.164306000000124],
                [-14.132915, 10.16514],
                [-14.133752, 10.166806],
                [-14.134308, 10.166806],
                [-14.134306, 10.167362],
                [-14.135137, 10.168472],
                [-14.135693999999887, 10.168750000000102],
                [-14.135693999999887, 10.16930600000012],
                [-14.13625, 10.169584],
                [-14.13625, 10.170138],
                [-14.136806, 10.170416000000102],
                [-14.136806, 10.17097200000012],
                [-14.137361999999882, 10.17125],
                [-14.137361999999882, 10.171807000000115],
                [-14.137916, 10.172082000000103],
                [-14.137916, 10.17263800000012],
                [-14.138472, 10.172916],
                [-14.139306, 10.174583],
                [-14.139306, 10.175417],
                [-14.139584, 10.176249],
                [-14.140138, 10.176805000000115],
                [-14.140138, 10.17847300000011],
                [-14.140418, 10.17847300000011],
                [-14.140418, 10.185415],
                [-14.140138, 10.185415],
                [-14.140138, 10.18875],
                [-14.140972, 10.190418],
                [-14.142084, 10.190974],
                [-14.144027999999878, 10.190972],
                [-14.144027999999878, 10.19125],
                [-14.14485999999988, 10.191528],
                [-14.145972, 10.192084],
                [-14.146807, 10.192360000000178],
                [-14.146807, 10.192916],
                [-14.147359999999878, 10.193194],
                [-14.147359999999878, 10.194582],
                [-14.146528999999873, 10.196250000000134],
                [-14.145972, 10.196528],
                [-14.145138, 10.197360000000174],
                [-14.144584, 10.198194],
                [-14.144027999999878, 10.198472000000152],
                [-14.14319399999988, 10.200138000000152],
                [-14.14319399999988, 10.203195000000107],
                [-14.144027999999878, 10.203473],
                [-14.144304, 10.204029],
                [-14.145416, 10.204029],
                [-14.145416, 10.204305000000147],
                [-14.146807, 10.204305000000147],
                [-14.146807, 10.204029],
                [-14.147917, 10.204029],
                [-14.147917, 10.204305000000147],
                [-14.149583, 10.204305000000147],
                [-14.150139, 10.205139],
                [-14.150694999999871, 10.205139],
                [-14.150417, 10.205973000000142],
                [-14.149860999999873, 10.205695],
                [-14.14902899999987, 10.205695],
                [-14.148751, 10.205139],
                [-14.144584, 10.205139],
                [-14.144584, 10.204861000000108],
                [-14.143472, 10.204861000000108],
                [-14.14319399999988, 10.204029],
                [-14.142638, 10.20374900000013],
                [-14.142638, 10.202917],
                [-14.142084, 10.202639000000147],
                [-14.142084, 10.199582000000134],
                [-14.142359999999883, 10.199029],
                [-14.144027999999878, 10.197360000000174],
                [-14.144027999999878, 10.196806000000151],
                [-14.144584, 10.196806000000151],
                [-14.145693999999878, 10.195694000000174],
                [-14.146251, 10.194582],
                [-14.146251, 10.193472000000156],
                [-14.145693999999878, 10.19264],
                [-14.140418, 10.19264],
                [-14.13986199999988, 10.192084],
                [-14.139027999999882, 10.192084],
                [-14.138472, 10.192360000000178],
                [-14.137916, 10.192916],
                [-14.137916, 10.193472000000156],
                [-14.137084, 10.19375],
                [-14.137084, 10.194306],
                [-14.13625, 10.194306],
                [-14.135693999999887, 10.194582],
                [-14.135418, 10.195138000000156],
                [-14.133752, 10.195972],
                [-14.13264, 10.195972],
                [-14.132360999999833, 10.196528],
                [-14.130417, 10.196528],
                [-14.130417, 10.196806000000151],
                [-14.124583, 10.196806000000151],
                [-14.123749, 10.196528],
                [-14.123473, 10.195972],
                [-14.122917, 10.195972],
                [-14.122083, 10.195416],
                [-14.122083, 10.194862],
                [-14.121529, 10.194306],
                [-14.120973, 10.194306],
                [-14.120695, 10.193194],
                [-14.120138, 10.193194],
                [-14.120138, 10.19264],
                [-14.119307, 10.192084],
                [-14.119029, 10.191528],
                [-14.118472, 10.191528],
                [-14.118194, 10.190972],
                [-14.117083999999863, 10.190694000000178],
                [-14.117083999999863, 10.190140000000156],
                [-14.115972, 10.189862],
                [-14.115972, 10.189306],
                [-14.11458199999987, 10.18875],
                [-14.113472, 10.18875],
                [-14.113472, 10.18847200000016],
                [-14.112362, 10.18847200000016],
                [-14.111806, 10.187918],
                [-14.110972, 10.187918],
                [-14.110415999999873, 10.187362000000178],
                [-14.10958399999987, 10.187362000000178],
                [-14.109028, 10.186527000000183],
                [-14.108472, 10.185971000000166],
                [-14.10791799999987, 10.185696000000178],
                [-14.10791799999987, 10.185139000000106],
                [-14.106806, 10.184027],
                [-14.105970999999897, 10.183473000000106],
                [-14.105970999999897, 10.183193000000188],
                [-14.105138999999895, 10.182917],
                [-14.104027, 10.182917],
                [-14.104027, 10.183193000000188],
                [-14.102638999999897, 10.183193000000188],
                [-14.102361, 10.183749],
                [-14.1018049999999, 10.184027],
                [-14.099582999999882, 10.184027],
                [-14.099582999999882, 10.183749],
                [-14.09875099999988, 10.183473000000106],
                [-14.097916999999882, 10.183473000000106],
                [-14.097639, 10.182917],
                [-14.097082999999827, 10.182917],
                [-14.096805, 10.182361],
                [-14.09624899999983, 10.182361],
                [-14.095416999999827, 10.181527000000187],
                [-14.095416999999827, 10.181249],
                [-14.09458299999983, 10.180695],
                [-14.093750999999827, 10.179861000000187],
                [-14.093750999999827, 10.179305],
                [-14.093195, 10.178751],
                [-14.092638, 10.178751],
                [-14.092641, 10.176805000000115],
                [-14.09236, 10.176805000000115],
                [-14.091528, 10.174861],
                [-14.08986, 10.174307],
                [-14.088472, 10.174307],
                [-14.088472, 10.174583],
                [-14.08625, 10.174583],
                [-14.08625, 10.174861],
                [-14.08514, 10.174861],
                [-14.08486, 10.175417],
                [-14.08375, 10.175417],
                [-14.08375, 10.175695],
                [-14.080693, 10.175695],
                [-14.079862, 10.175417],
                [-14.079305, 10.174861],
                [-14.078471, 10.173473000000115],
                [-14.077917, 10.173473000000115],
                [-14.077361, 10.172082000000103],
                [-14.077361, 10.17097200000012],
                [-14.077639, 10.17097200000012],
                [-14.077639, 10.169028],
                [-14.077361, 10.169028],
                [-14.077361, 10.166528000000142],
                [-14.076527, 10.164860000000147],
                [-14.075973, 10.164306000000124],
                [-14.075973, 10.163750000000107],
                [-14.075417, 10.163472],
                [-14.075417, 10.162918],
                [-14.074861, 10.162638000000129],
                [-14.074583, 10.162084000000107],
                [-14.074583, 10.16125],
                [-14.074305, 10.160418000000107],
                [-14.07180499999987, 10.155417],
                [-14.071251, 10.154861],
                [-14.071251, 10.154305],
                [-14.070695, 10.154027000000156],
                [-14.070695, 10.153471],
                [-14.07013899999987, 10.153195],
                [-14.06986099999989, 10.152639],
                [-14.069585, 10.151527000000101],
                [-14.069029, 10.151251000000173],
                [-14.069029, 10.150139],
                [-14.068751, 10.149583000000177],
                [-14.06819499999989, 10.149307],
                [-14.06819499999989, 10.148751],
                [-14.067360999999892, 10.148473],
                [-14.067082, 10.147917000000177],
                [-14.066525999999897, 10.147917000000177],
                [-14.065416, 10.14736100000016],
                [-14.065416, 10.147082000000182],
                [-14.06125, 10.147082000000182],
                [-14.06125, 10.14736100000016],
                [-14.057639999999822, 10.14736100000016],
                [-14.055972, 10.145694000000105],
                [-14.055972, 10.145138],
                [-14.054584, 10.143750000000182],
                [-14.054028, 10.143472],
                [-14.054028, 10.142916],
                [-14.053193, 10.14236000000011],
                [-14.053193, 10.142082000000187],
                [-14.052362, 10.14125],
                [-14.051805, 10.140972],
                [-14.051805, 10.140416000000187],
                [-14.049861, 10.138472],
                [-14.049583, 10.137916000000132],
                [-14.048749, 10.13736200000011],
                [-14.047360999999853, 10.135972],
                [-14.047360999999853, 10.135418000000186],
                [-14.046526999999855, 10.134862],
                [-14.045417, 10.133750000000191],
                [-14.045417, 10.133196],
                [-14.044860999999855, 10.13264],
                [-14.044583, 10.132083000000137],
                [-14.044860999999855, 10.13152700000012],
                [-14.045417, 10.13152700000012],
                [-14.045694999999853, 10.130971000000102],
                [-14.045694999999853, 10.129861000000119],
                [-14.045417, 10.129861000000119],
                [-14.045417, 10.128473],
                [-14.045139, 10.128473],
                [-14.045139, 10.126805],
                [-14.044860999999855, 10.126805],
                [-14.044860999999855, 10.125695],
                [-14.045417, 10.12541700000014],
                [-14.045694999999853, 10.124861000000124],
                [-14.045694999999853, 10.123749000000146],
                [-14.045139, 10.123195000000123],
                [-14.044305, 10.123195000000123],
                [-14.043473, 10.122917],
                [-14.043473, 10.122639000000163],
                [-14.042083, 10.122639000000163],
                [-14.042083, 10.122361],
                [-14.040417, 10.122361],
                [-14.039582, 10.122083000000146],
                [-14.039307, 10.121527000000128],
                [-14.038472, 10.121527000000128],
                [-14.037916, 10.120973000000163],
                [-14.037084, 10.120973000000163],
                [-14.037084, 10.120417000000145],
                [-14.035972, 10.120139],
                [-14.035694, 10.11958200000015],
                [-14.035138, 10.119307000000163],
                [-14.034306, 10.119307000000163],
                [-14.034028, 10.118751000000145],
                [-14.032915999999886, 10.118472000000168],
                [-14.032083999999884, 10.117638],
                [-14.031806, 10.117084],
                [-14.030693999999869, 10.11625000000015],
                [-14.03014, 10.115416],
                [-14.027637, 10.115416],
                [-14.027637, 10.115138000000172],
                [-14.026527999999871, 10.114582000000155],
                [-14.025971, 10.114582000000155],
                [-14.025414999999896, 10.114028000000133],
                [-14.024582999999893, 10.114028000000133],
                [-14.020694999999819, 10.112084],
                [-14.019305, 10.111528],
                [-14.019027, 10.110972],
                [-14.018471, 10.110694],
                [-14.017639, 10.110694],
                [-14.016529, 10.110140000000172],
                [-14.014861, 10.109584000000154],
                [-14.014305, 10.109306],
                [-14.014026, 10.1087500000001],
                [-14.013195, 10.108752],
                [-14.01208199999985, 10.108196],
                [-14.011249999999848, 10.107918000000154],
                [-14.011249999999848, 10.10764],
                [-14.0104189999999, 10.107362],
                [-14.009028, 10.107362],
                [-14.007916, 10.106806000000176],
                [-14.007916, 10.106528],
                [-14.006806, 10.106528],
                [-14.00514, 10.105696],
                [-14.005138, 10.105415],
                [-14.004306, 10.105140000000176],
                [-14.003472, 10.105140000000176],
                [-14.003472, 10.104583000000105],
                [-14.002916, 10.104583000000105],
                [-14.002916, 10.104027],
                [-14.002084, 10.103749],
                [-14.002084, 10.103193],
                [-14.001528, 10.103193],
                [-14.001528, 10.102639000000181],
                [-14.000972, 10.102639000000181],
                [-14.000418, 10.101805000000127],
                [-14.000418, 10.10124900000011],
                [-13.999862, 10.10124900000011],
                [-13.999862, 10.100695],
                [-13.999027, 10.100417],
                [-13.998749, 10.09958300000011],
                [-13.998749, 10.098473000000126],
                [-13.998470999999881, 10.098473000000126],
                [-13.998470999999881, 10.097361],
                [-13.998196, 10.097361],
                [-13.998196, 10.096249000000114],
                [-13.997915, 10.096249000000114],
                [-13.997915, 10.094583000000114],
                [-13.997361, 10.094026],
                [-13.997361, 10.093195],
                [-13.995972999999879, 10.090416000000118],
                [-13.995693, 10.089584],
                [-13.995138999999881, 10.089306000000136],
                [-13.995138999999881, 10.088194000000158],
                [-13.994861, 10.088194000000158],
                [-13.994861, 10.087084000000118],
                [-13.994583, 10.087082000000123],
                [-13.994583, 10.085416000000123],
                [-13.994304999999883, 10.084584],
                [-13.994027, 10.084584],
                [-13.994027, 10.082916],
                [-13.993749, 10.082916],
                [-13.993749, 10.080694],
                [-13.994027, 10.080694],
                [-13.994027, 10.07847100000015],
                [-13.993470999999886, 10.07680500000015],
                [-13.992917, 10.076527],
                [-13.992638999999883, 10.075417],
                [-13.991251, 10.074861],
                [-13.990138999999886, 10.073751],
                [-13.989304999999888, 10.073749],
                [-13.989029, 10.073195],
                [-13.988472999999885, 10.072917000000189],
                [-13.987638999999888, 10.072917000000189],
                [-13.987363, 10.072361000000171],
                [-13.986526, 10.072083],
                [-13.986251, 10.070973],
                [-13.985416, 10.070695000000171],
                [-13.985416, 10.070139],
                [-13.98486, 10.070139],
                [-13.983471999999892, 10.069305],
                [-13.983471999999892, 10.068751],
                [-13.982637999999895, 10.067917],
                [-13.98236, 10.067917],
                [-13.980694, 10.066251],
                [-13.980694, 10.065695000000176],
                [-13.980137999999897, 10.065695000000176],
                [-13.979862, 10.06486000000018],
                [-13.979028, 10.064585],
                [-13.979028, 10.064028000000121],
                [-13.978471999999897, 10.06319400000018],
                [-13.977916, 10.06319400000018],
                [-13.977639999999894, 10.062082],
                [-13.977084, 10.061806000000104],
                [-13.977084, 10.06125],
                [-13.975694, 10.05986200000018],
                [-13.974862, 10.05986200000018],
                [-13.974584, 10.059306],
                [-13.973471999999845, 10.059028000000126],
                [-13.972918, 10.05736000000013],
                [-13.972918, 10.05625],
                [-13.972084, 10.05375],
                [-13.972918, 10.053193000000135],
                [-13.972362, 10.051527000000135],
                [-13.972362, 10.050139],
                [-13.972918, 10.049861000000135],
                [-13.973193, 10.048749000000157],
                [-13.97375, 10.048473],
                [-13.97375, 10.047917],
                [-13.974305999999842, 10.047639000000117],
                [-13.974305999999842, 10.045417000000157],
                [-13.973471999999845, 10.044583],
                [-13.972918, 10.044583],
                [-13.972639999999842, 10.044029],
                [-13.972084, 10.044027],
                [-13.971805, 10.043473],
                [-13.970971, 10.043473],
                [-13.970696, 10.042917],
                [-13.970139, 10.042917],
                [-13.969583, 10.042361],
                [-13.967639, 10.042361],
                [-13.967639, 10.042083000000162],
                [-13.964583, 10.042083000000162],
                [-13.964583, 10.042361],
                [-13.962639, 10.042361],
                [-13.962083, 10.041805],
                [-13.960417, 10.041805],
                [-13.960417, 10.04152900000014],
                [-13.958751, 10.04152900000014],
                [-13.958751, 10.041251],
                [-13.957638, 10.041251],
                [-13.957638, 10.040973000000122],
                [-13.956527999999878, 10.040973000000122],
                [-13.954859999999883, 10.040139],
                [-13.954859999999883, 10.039861000000144],
                [-13.95375, 10.039861000000144],
                [-13.952916, 10.040139],
                [-13.951806, 10.041251],
                [-13.951527999999882, 10.040695],
                [-13.95236199999988, 10.039861000000144],
                [-13.952916, 10.039029],
                [-13.952916, 10.038473],
                [-13.95236199999988, 10.038194],
                [-13.952084, 10.037085000000161],
                [-13.951527999999882, 10.037085000000161],
                [-13.951527999999882, 10.036528],
                [-13.950693999999885, 10.036250000000166],
                [-13.949306, 10.036250000000166],
                [-13.947918, 10.034862],
                [-13.947638, 10.034306],
                [-13.947918, 10.033472000000188],
                [-13.94875, 10.034306],
                [-13.94875, 10.03486],
                [-13.949306, 10.035416],
                [-13.950693999999885, 10.035416],
                [-13.950693999999885, 10.035694],
                [-13.951806, 10.035694],
                [-13.951806, 10.03458200000017],
                [-13.952084, 10.03375],
                [-13.952638, 10.033194],
                [-13.952638, 10.030416000000116],
                [-13.95236199999988, 10.02958400000017],
                [-13.95125, 10.028472],
                [-13.95125, 10.028194],
                [-13.950416, 10.027362],
                [-13.949584, 10.027084000000116],
                [-13.949306, 10.026250000000175],
                [-13.948472, 10.026250000000175],
                [-13.947084, 10.024862],
                [-13.946527999999887, 10.024027000000103],
                [-13.945971, 10.023749],
                [-13.94514, 10.023749],
                [-13.944861999999887, 10.023193],
                [-13.943749, 10.023193],
                [-13.943195999999887, 10.023471],
                [-13.942917, 10.024027000000103],
                [-13.941805, 10.02458300000012],
                [-13.941805, 10.02514],
                [-13.941249, 10.025415],
                [-13.940417, 10.026528],
                [-13.940417, 10.027638],
                [-13.939860999999837, 10.028194],
                [-13.939583, 10.029306],
                [-13.938751, 10.029862],
                [-13.938751, 10.030694],
                [-13.938194999999837, 10.031806000000188],
                [-13.937639, 10.03236],
                [-13.937083, 10.03236],
                [-13.936805, 10.033472000000188],
                [-13.935973, 10.034306],
                [-13.935417, 10.03458200000017],
                [-13.935139, 10.035694],
                [-13.934583, 10.035972],
                [-13.934583, 10.037916000000166],
                [-13.934861, 10.037916000000166],
                [-13.934861, 10.039307000000122],
                [-13.935417, 10.039582000000166],
                [-13.935417, 10.040417000000161],
                [-13.935973, 10.040417000000161],
                [-13.935973, 10.041251],
                [-13.93569499999984, 10.042083000000162],
                [-13.934861, 10.042639000000122],
                [-13.934027, 10.042639000000122],
                [-13.93236, 10.042083000000162],
                [-13.93236, 10.042361],
                [-13.931251, 10.042361],
                [-13.931251, 10.042083000000162],
                [-13.929028, 10.042083000000162],
                [-13.928472, 10.04152900000014],
                [-13.928194, 10.040695],
                [-13.928194, 10.036528],
                [-13.927638, 10.036250000000166],
                [-13.927638, 10.035138000000188],
                [-13.927362, 10.035138000000188],
                [-13.927362, 10.034028],
                [-13.92708199999987, 10.034028],
                [-13.92708199999987, 10.031806000000188],
                [-13.927362, 10.031806000000188],
                [-13.927362, 10.027362],
                [-13.92708199999987, 10.027362],
                [-13.92708199999987, 10.025971],
                [-13.927362, 10.025971],
                [-13.927362, 10.024862],
                [-13.92708199999987, 10.024862],
                [-13.92708199999987, 10.023471],
                [-13.926528, 10.023471],
                [-13.926528, 10.022639],
                [-13.926249999999868, 10.022083],
                [-13.925694, 10.021805],
                [-13.92541599999987, 10.021249000000125],
                [-13.92541599999987, 10.020417],
                [-13.924583999999868, 10.020417],
                [-13.924583999999868, 10.019861],
                [-13.92375, 10.019583000000125],
                [-13.92208399999987, 10.019583000000125],
                [-13.92208399999987, 10.019861],
                [-13.921249999999873, 10.020139],
                [-13.92014, 10.020695000000103],
                [-13.919306, 10.020695000000103],
                [-13.919028, 10.021249000000125],
                [-13.91791499999988, 10.021249000000125],
                [-13.91791499999988, 10.021527],
                [-13.916805, 10.021527],
                [-13.916805, 10.021805],
                [-13.91458299999988, 10.021805],
                [-13.914027, 10.020973],
                [-13.91291699999988, 10.020973],
                [-13.912361, 10.020695000000103],
                [-13.912082999999882, 10.020139],
                [-13.911527, 10.020139],
                [-13.911527, 10.019305],
                [-13.910972999999899, 10.019305],
                [-13.910139, 10.018195],
                [-13.910139, 10.017083],
                [-13.909582999999884, 10.016805000000147],
                [-13.909582999999884, 10.014861],
                [-13.910139, 10.01458300000013],
                [-13.910416999999882, 10.013473000000147],
                [-13.912082999999882, 10.012639],
                [-13.912082999999882, 10.01236],
                [-13.91291699999988, 10.011529],
                [-13.913473, 10.011529],
                [-13.91458299999988, 10.010973],
                [-13.914861, 10.010416000000134],
                [-13.915693, 10.010416000000134],
                [-13.915971, 10.009860000000117],
                [-13.916527, 10.009860000000117],
                [-13.916527, 10.009306000000151],
                [-13.917082999999877, 10.009028],
                [-13.918748999999877, 10.009028],
                [-13.918748999999877, 10.009306000000151],
                [-13.920972, 10.009306000000151],
                [-13.920972, 10.009028],
                [-13.92708199999987, 10.009028],
                [-13.92708199999987, 10.009306000000151],
                [-13.931251, 10.009306000000151],
                [-13.932082, 10.009028],
                [-13.932082, 10.008472],
                [-13.932639, 10.008194000000117],
                [-13.932639, 10.006806],
                [-13.932917, 10.006806],
                [-13.932917, 10.00514],
                [-13.933195, 10.005138],
                [-13.933195, 10.003472],
                [-13.933473, 10.003474],
                [-13.933473, 10.001528000000178],
                [-13.933751, 10.001528000000178],
                [-13.933751, 9.998471000000166],
                [-13.934027, 9.998471000000166],
                [-13.934027, 9.996805000000165],
                [-13.934305, 9.996805000000165],
                [-13.934305, 9.995693000000188],
                [-13.934583, 9.995693000000188],
                [-13.934583, 9.99430500000011],
                [-13.935139, 9.994027000000187],
                [-13.935139, 9.992917],
                [-13.935417, 9.992361000000187],
                [-13.936249, 9.99180500000017],
                [-13.936249, 9.991251],
                [-13.937083, 9.99097300000011],
                [-13.937083, 9.99013900000017],
                [-13.93736099999984, 9.989583],
                [-13.937917, 9.989029000000187],
                [-13.938194999999837, 9.98847300000017],
                [-13.938751, 9.988195],
                [-13.938751, 9.987083],
                [-13.938471, 9.987083],
                [-13.938194999999837, 9.986251],
                [-13.938194999999837, 9.98486],
                [-13.937639, 9.984304],
                [-13.937639, 9.98347200000012],
                [-13.937083, 9.982916000000102],
                [-13.937083, 9.981528],
                [-13.936805, 9.981528],
                [-13.936528999999837, 9.980694000000142],
                [-13.936528999999837, 9.979584000000102],
                [-13.936805, 9.979584000000102],
                [-13.936805, 9.975972],
                [-13.93736099999984, 9.974584000000107],
                [-13.938194999999837, 9.97375],
                [-13.938751, 9.97375],
                [-13.939860999999837, 9.973194],
                [-13.940139, 9.97264],
                [-13.940694999999891, 9.97264],
                [-13.941526999999837, 9.972084],
                [-13.941526999999837, 9.971249],
                [-13.942360999999892, 9.970140000000129],
                [-13.942917, 9.970140000000129],
                [-13.943749, 9.969027],
                [-13.944305, 9.968749000000173],
                [-13.94514, 9.967639000000133],
                [-13.945971, 9.967917],
                [-13.947084, 9.967917],
                [-13.947084, 9.968193000000156],
                [-13.948193999999887, 9.968193000000156],
                [-13.949027999999885, 9.967361],
                [-13.949027999999885, 9.966805],
                [-13.949861999999882, 9.966251],
                [-13.949861999999882, 9.965417000000173],
                [-13.95014, 9.964861000000155],
                [-13.949027999999885, 9.964305],
                [-13.94875, 9.963473],
                [-13.947918, 9.963195000000155],
                [-13.947918, 9.962639],
                [-13.947084, 9.962363],
                [-13.947084, 9.961807],
                [-13.945415, 9.960139],
                [-13.944861999999887, 9.960139],
                [-13.944861999999887, 9.959583],
                [-13.943749, 9.959307],
                [-13.943749, 9.958751000000177],
                [-13.943195999999887, 9.958751000000177],
                [-13.943195999999887, 9.957638],
                [-13.942639, 9.956804],
                [-13.941526999999837, 9.956804],
                [-13.940973, 9.955972],
                [-13.940417, 9.955972],
                [-13.939305, 9.95486000000011],
                [-13.939305, 9.954306],
                [-13.938194999999837, 9.954028000000164],
                [-13.937917, 9.953472],
                [-13.936805, 9.95319400000011],
                [-13.936528999999837, 9.952638],
                [-13.935973, 9.952638],
                [-13.934861, 9.952084],
                [-13.934861, 9.95152800000011],
                [-13.934305, 9.95152800000011],
                [-13.934027, 9.950972],
                [-13.933195, 9.95014],
                [-13.932639, 9.95014],
                [-13.931807, 9.949306],
                [-13.930973, 9.949028],
                [-13.930973, 9.948472],
                [-13.92986, 9.948472],
                [-13.929585, 9.947918000000186],
                [-13.929028, 9.947918000000186],
                [-13.92875, 9.946806],
                [-13.927915999999868, 9.946528000000114],
                [-13.927362, 9.945972],
                [-13.926528, 9.945972],
                [-13.926249999999868, 9.945415],
                [-13.925694, 9.945415],
                [-13.925694, 9.944862000000114],
                [-13.925138, 9.94458400000019],
                [-13.924306, 9.94458400000019],
                [-13.92375, 9.944306],
                [-13.923472, 9.943749],
                [-13.922362, 9.94291800000019],
                [-13.921528, 9.94291800000019],
                [-13.921249999999873, 9.942083],
                [-13.919862, 9.941249000000141],
                [-13.919583999999873, 9.940695000000119],
                [-13.918748999999877, 9.940695000000119],
                [-13.918471, 9.940139000000102],
                [-13.91764, 9.940139000000102],
                [-13.917362, 9.939583000000141],
                [-13.916527, 9.939583000000141],
                [-13.915693, 9.939305],
                [-13.915139, 9.938471000000106],
                [-13.914027, 9.938195],
                [-13.914027, 9.937639],
                [-13.913473, 9.937639],
                [-13.91291699999988, 9.936805000000106],
                [-13.911805, 9.936805000000106],
                [-13.911805, 9.936529],
                [-13.910695, 9.936529],
                [-13.910416999999882, 9.935973],
                [-13.909861, 9.935973],
                [-13.908748999999887, 9.935139000000106],
                [-13.908195, 9.934583000000146],
                [-13.908195, 9.934027000000128],
                [-13.907639, 9.934027000000128],
                [-13.907361, 9.933473000000106],
                [-13.906526, 9.933473000000106],
                [-13.905695, 9.933195],
                [-13.905416999999886, 9.932639],
                [-13.904581999999834, 9.932639],
                [-13.903472, 9.931529],
                [-13.902915999999834, 9.931529],
                [-13.902915999999834, 9.930973],
                [-13.902083999999832, 9.93041600000015],
                [-13.901528, 9.929860000000133],
                [-13.900972, 9.929860000000133],
                [-13.900416, 9.929307],
                [-13.899582, 9.929307],
                [-13.89875, 9.92875000000015],
                [-13.898472, 9.927638000000172],
                [-13.89736, 9.927362],
                [-13.897084, 9.926806],
                [-13.89625, 9.926806],
                [-13.895416, 9.92625],
                [-13.895416, 9.925694],
                [-13.894862, 9.925694],
                [-13.894862, 9.925138],
                [-13.894028, 9.92486],
                [-13.893472, 9.924306000000172],
                [-13.892084, 9.923750000000155],
                [-13.891806, 9.923194],
                [-13.890696, 9.9229160000001],
                [-13.890418, 9.922084000000154],
                [-13.889861, 9.922084000000154],
                [-13.889861, 9.921528],
                [-13.888749, 9.921252],
                [-13.888472999999863, 9.920694],
                [-13.887638999999865, 9.920694],
                [-13.887083, 9.920418000000154],
                [-13.887083, 9.919862],
                [-13.886527, 9.919862],
                [-13.885695, 9.919028],
                [-13.885695, 9.918472],
                [-13.885139, 9.917915],
                [-13.884583, 9.917915],
                [-13.88430499999987, 9.917362],
                [-13.883751, 9.917362],
                [-13.883473, 9.916805000000181],
                [-13.882917, 9.916805000000181],
                [-13.882361, 9.915971],
                [-13.882361, 9.915417000000105],
                [-13.881529, 9.915139000000181],
                [-13.881529, 9.914583],
                [-13.88097299999987, 9.914583],
                [-13.879861, 9.913471000000186],
                [-13.879025999999897, 9.913471000000186],
                [-13.878195, 9.912639],
                [-13.878195, 9.91208300000011],
                [-13.877085, 9.911805000000186],
                [-13.877085, 9.911251],
                [-13.875971999999877, 9.910973],
                [-13.875416, 9.910139000000186],
                [-13.874859999999899, 9.910139000000186],
                [-13.874582, 9.909029],
                [-13.874027999999896, 9.908749000000114],
                [-13.87375, 9.907639000000131],
                [-13.872637999999881, 9.907361],
                [-13.872637999999881, 9.906807000000185],
                [-13.872084, 9.906527],
                [-13.87125, 9.906527],
                [-13.87125, 9.905973000000131],
                [-13.870139999999878, 9.905695],
                [-13.870139999999878, 9.90513900000019],
                [-13.869584, 9.904861],
                [-13.869584, 9.904304],
                [-13.868471999999826, 9.904029],
                [-13.868471999999826, 9.90347300000019],
                [-13.867918, 9.90347300000019],
                [-13.867637999999829, 9.902916000000118],
                [-13.866805999999826, 9.902085000000113],
                [-13.86625, 9.901806000000136],
                [-13.865693, 9.900694000000101],
                [-13.865693, 9.899862],
                [-13.864862, 9.89875],
                [-13.864305999999829, 9.89875],
                [-13.862639, 9.897084],
                [-13.862639, 9.896528],
                [-13.862083, 9.896528],
                [-13.862083, 9.895972],
                [-13.860973, 9.895694000000162],
                [-13.860695, 9.895138000000145],
                [-13.860139, 9.894584000000123],
                [-13.859583, 9.894584000000123],
                [-13.859583, 9.894028000000162],
                [-13.858751, 9.89375],
                [-13.858471, 9.893194],
                [-13.857639, 9.892916000000127],
                [-13.857639, 9.892362000000162],
                [-13.856805, 9.891528],
                [-13.856249, 9.891528],
                [-13.856249, 9.89097100000015],
                [-13.855417, 9.890696000000162],
                [-13.855139, 9.890140000000144],
                [-13.854583, 9.890140000000144],
                [-13.854305, 9.889583],
                [-13.853751, 9.88930500000015],
                [-13.853473, 9.888749000000132],
                [-13.852639, 9.887917],
                [-13.852083, 9.88763900000015],
                [-13.852083, 9.887083000000132],
                [-13.850973, 9.886805],
                [-13.850973, 9.886251],
                [-13.849585, 9.885417000000132],
                [-13.849304, 9.884305000000154],
                [-13.848472, 9.884027],
                [-13.848472, 9.883473],
                [-13.847916, 9.883473],
                [-13.847362, 9.882917],
                [-13.84625, 9.882083],
                [-13.84625, 9.881529000000171],
                [-13.845138, 9.880695],
                [-13.844028, 9.880139],
                [-13.844028, 9.879583],
                [-13.842915999999889, 9.879583],
                [-13.842915999999889, 9.879027],
                [-13.842084, 9.878751],
                [-13.842084, 9.877917],
                [-13.841527999999869, 9.877917],
                [-13.841527999999869, 9.87736000000018],
                [-13.840972, 9.877085],
                [-13.840974, 9.875972000000104],
                [-13.840693999999871, 9.875972000000104],
                [-13.840693999999871, 9.874860000000126],
                [-13.840138, 9.874306000000104],
                [-13.840138, 9.873472],
                [-13.839583999999888, 9.87236200000018],
                [-13.839306, 9.871528000000126],
                [-13.839027999999871, 9.871528000000126],
                [-13.839027999999871, 9.870416],
                [-13.838472, 9.869306000000108],
                [-13.837914999999896, 9.869028000000185],
                [-13.837914999999896, 9.868472],
                [-13.83708399999989, 9.868472],
                [-13.836526999999876, 9.86819400000013],
                [-13.836248999999896, 9.867638000000113],
                [-13.835416999999893, 9.867638000000113],
                [-13.835139, 9.867362000000185],
                [-13.835139, 9.86625],
                [-13.834582999999895, 9.865972000000113],
                [-13.834582999999895, 9.86486200000013],
                [-13.834026999999821, 9.863749],
                [-13.833473, 9.863471000000118],
                [-13.833473, 9.861805000000118],
                [-13.833194999999876, 9.860973],
                [-13.832082999999898, 9.858751],
                [-13.831526999999824, 9.858471000000122],
                [-13.831526999999824, 9.857917000000157],
                [-13.830973, 9.857917000000157],
                [-13.830971, 9.85736100000014],
                [-13.830416999999898, 9.857083],
                [-13.829860999999823, 9.855973],
                [-13.829860999999823, 9.855417],
                [-13.829305, 9.855139000000122],
                [-13.82902899999982, 9.854027000000144],
                [-13.828749, 9.853473000000122],
                [-13.828194999999823, 9.853195],
                [-13.828194999999823, 9.852083],
                [-13.827917, 9.851529],
                [-13.827361, 9.851529],
                [-13.826527, 9.850695000000144],
                [-13.825973, 9.850417],
                [-13.825695, 9.849304000000188],
                [-13.824861, 9.849029000000144],
                [-13.824861, 9.848472],
                [-13.824307, 9.848194000000149],
                [-13.823473, 9.848194000000149],
                [-13.823194, 9.847638000000188],
                [-13.82236, 9.847362],
                [-13.82236, 9.846806],
                [-13.821806, 9.846528000000148],
                [-13.820694, 9.846528000000148],
                [-13.820138, 9.845972000000188],
                [-13.819306, 9.845972000000188],
                [-13.818749999999852, 9.84541600000017],
                [-13.818749999999852, 9.84486],
                [-13.818194, 9.84486],
                [-13.817915999999855, 9.844306000000188],
                [-13.816249999999854, 9.844306000000188],
                [-13.816249999999854, 9.844028],
                [-13.814862, 9.844028],
                [-13.814028, 9.84375000000017],
                [-13.814028, 9.843194],
                [-13.813194, 9.843194],
                [-13.812362, 9.842916],
                [-13.812084, 9.842362],
                [-13.81125, 9.842362],
                [-13.810971, 9.841806],
                [-13.810415, 9.841528],
                [-13.809583, 9.841528],
                [-13.808474, 9.840972],
                [-13.808474, 9.840694],
                [-13.805970999999886, 9.840694],
                [-13.805138999999883, 9.84041800000017],
                [-13.804861, 9.839584],
                [-13.804027, 9.839306],
                [-13.803472999999883, 9.838750000000175],
                [-13.802917, 9.838472],
                [-13.796803999999895, 9.838472],
                [-13.796529, 9.839028],
                [-13.795694, 9.838750000000175],
                [-13.795694, 9.83791500000018],
                [-13.794582, 9.83791500000018],
                [-13.794582, 9.838196],
                [-13.793749999999818, 9.838472],
                [-13.792916, 9.838472],
                [-13.792362, 9.839028],
                [-13.791528, 9.839306],
                [-13.787918, 9.839306],
                [-13.787918, 9.839584],
                [-13.786805999999899, 9.84014],
                [-13.785694, 9.840972],
                [-13.785139999999899, 9.841806],
                [-13.784584, 9.843472],
                [-13.784584, 9.84736],
                [-13.785416, 9.848472],
                [-13.786528, 9.849029000000144],
                [-13.787084, 9.849029000000144],
                [-13.787638, 9.849585000000161],
                [-13.787918, 9.850417],
                [-13.78875, 9.850417],
                [-13.789028, 9.851251000000161],
                [-13.789584, 9.851251000000161],
                [-13.790139999999894, 9.851807000000122],
                [-13.790416, 9.852917000000161],
                [-13.790694, 9.853473000000122],
                [-13.790139999999894, 9.853751],
                [-13.790139999999894, 9.854583000000162],
                [-13.78986, 9.855139000000122],
                [-13.789028, 9.855973],
                [-13.787638, 9.855973],
                [-13.787084, 9.856529],
                [-13.786528, 9.856805000000122],
                [-13.785694, 9.856805000000122],
                [-13.784306, 9.85736100000014],
                [-13.784028, 9.857917000000157],
                [-13.783196, 9.857917000000157],
                [-13.78264, 9.858195],
                [-13.782083, 9.85902700000014],
                [-13.78180499999985, 9.859861],
                [-13.78180499999985, 9.862361000000135],
                [-13.781249, 9.862083],
                [-13.780972999999847, 9.861527],
                [-13.780972999999847, 9.859583000000157],
                [-13.781249, 9.858751],
                [-13.78264, 9.85736100000014],
                [-13.784306, 9.856529],
                [-13.784862, 9.855973],
                [-13.785972, 9.855973],
                [-13.787638, 9.855417],
                [-13.787918, 9.854861],
                [-13.789028, 9.854583000000162],
                [-13.789028, 9.854027000000144],
                [-13.789584, 9.853751],
                [-13.789584, 9.852639],
                [-13.789305999999897, 9.852083],
                [-13.78875, 9.852083],
                [-13.788194, 9.851529],
                [-13.787362, 9.851251000000161],
                [-13.786805999999899, 9.850695000000144],
                [-13.786805999999899, 9.850138],
                [-13.785972, 9.849860000000149],
                [-13.784862, 9.848750000000166],
                [-13.784306, 9.848472],
                [-13.784306, 9.847916],
                [-13.783749, 9.847638000000188],
                [-13.783749, 9.846528000000148],
                [-13.78347099999985, 9.846528000000148],
                [-13.783196, 9.845694],
                [-13.783196, 9.844028],
                [-13.782918, 9.843472],
                [-13.782361, 9.843194],
                [-13.782361, 9.842640000000188],
                [-13.781527, 9.842362],
                [-13.781249, 9.841806],
                [-13.780693, 9.84208400000017],
                [-13.779861, 9.842916],
                [-13.777361, 9.842916],
                [-13.777083, 9.843472],
                [-13.776527, 9.84375000000017],
                [-13.775417, 9.84375000000017],
                [-13.774861, 9.844028],
                [-13.774029, 9.84486],
                [-13.773751, 9.84541600000017],
                [-13.772639, 9.84541600000017],
                [-13.772361, 9.845972000000188],
                [-13.771807, 9.845972000000188],
                [-13.771529, 9.846528000000148],
                [-13.770417, 9.84708200000017],
                [-13.76985999999988, 9.84708200000017],
                [-13.76875, 9.847638000000188],
                [-13.76875, 9.847916],
                [-13.767638, 9.848472],
                [-13.767082, 9.848472],
                [-13.76652799999988, 9.849029000000144],
                [-13.765693999999883, 9.849029000000144],
                [-13.765416, 9.849585000000161],
                [-13.764027999999882, 9.850138],
                [-13.763193999999885, 9.850138],
                [-13.762916, 9.850695000000144],
                [-13.761806, 9.850695000000144],
                [-13.761806, 9.850973],
                [-13.760693999999887, 9.851529],
                [-13.760693999999887, 9.852083],
                [-13.759027999999887, 9.852083],
                [-13.759027999999887, 9.852361000000144],
                [-13.757915, 9.852917000000161],
                [-13.757084, 9.852917000000161],
                [-13.756526999999892, 9.853473000000122],
                [-13.755139, 9.853473000000122],
                [-13.754583, 9.854027000000144],
                [-13.753749, 9.854305],
                [-13.752917, 9.855139000000122],
                [-13.752360999999894, 9.855139000000122],
                [-13.752083, 9.85569500000014],
                [-13.751526999999896, 9.855973],
                [-13.750417, 9.855973],
                [-13.749860999999896, 9.856529],
                [-13.749028999999894, 9.856529],
                [-13.747360999999842, 9.85736100000014],
                [-13.747083, 9.857917000000157],
                [-13.746526999999844, 9.858195],
                [-13.745139, 9.858195],
                [-13.744583, 9.858471000000122],
                [-13.744028999999841, 9.85902700000014],
                [-13.743194999999844, 9.85902700000014],
                [-13.742638, 9.859583000000157],
                [-13.741528, 9.859583000000157],
                [-13.740694, 9.859861],
                [-13.740138, 9.860417],
                [-13.739582, 9.860417],
                [-13.739306, 9.860973],
                [-13.738194, 9.861249000000157],
                [-13.737916, 9.861805000000118],
                [-13.737084, 9.861805000000118],
                [-13.736528, 9.862361000000135],
                [-13.735415999999873, 9.862361000000135],
                [-13.734862, 9.862918],
                [-13.733472, 9.862918],
                [-13.732918, 9.863471000000118],
                [-13.731528, 9.863471000000118],
                [-13.730972, 9.864028],
                [-13.73041499999988, 9.864306000000113],
                [-13.729027, 9.864306000000113],
                [-13.72874899999988, 9.86486200000013],
                [-13.728193, 9.86514],
                [-13.727361, 9.86514],
                [-13.726805, 9.865694],
                [-13.725695, 9.86625],
                [-13.725139, 9.86625],
                [-13.724582999999882, 9.866806],
                [-13.724582999999882, 9.867362000000185],
                [-13.724027, 9.867638000000113],
                [-13.724027, 9.86819400000013],
                [-13.723195, 9.86819400000013],
                [-13.722916999999882, 9.869028000000185],
                [-13.722082999999884, 9.86986000000013],
                [-13.721248999999887, 9.869862000000126],
                [-13.720973, 9.870694000000185],
                [-13.719861, 9.870694000000185],
                [-13.719582999999886, 9.87125],
                [-13.718473, 9.871806],
                [-13.718473, 9.87236200000018],
                [-13.717639, 9.87236200000018],
                [-13.717361, 9.872638000000109],
                [-13.717361, 9.873472],
                [-13.716529, 9.87402800000018],
                [-13.716529, 9.874582],
                [-13.715973, 9.874860000000126],
                [-13.715973, 9.875416],
                [-13.715138, 9.87569400000018],
                [-13.715138, 9.876251],
                [-13.714028, 9.87736000000018],
                [-13.714028, 9.878195000000176],
                [-13.713472, 9.879307000000153],
                [-13.712915999999836, 9.879583],
                [-13.712915999999836, 9.880139],
                [-13.712362, 9.880417],
                [-13.712081999999839, 9.881529000000171],
                [-13.711528, 9.881805],
                [-13.711528, 9.882917],
                [-13.710972, 9.883195000000171],
                [-13.71014, 9.884861000000171],
                [-13.71014, 9.885695],
                [-13.70986, 9.886249],
                [-13.709028, 9.886805],
                [-13.708194, 9.88930500000015],
                [-13.708194, 9.890696000000162],
                [-13.707916, 9.890696000000162],
                [-13.707362, 9.891806000000145],
                [-13.707362, 9.89264],
                [-13.706806, 9.892916000000127],
                [-13.706528, 9.893472000000145],
                [-13.706528, 9.894584000000123],
                [-13.705972, 9.894862],
                [-13.705694, 9.895416],
                [-13.705694, 9.896528],
                [-13.70514, 9.897084],
                [-13.704862, 9.897916000000123],
                [-13.704862, 9.8990280000001],
                [-13.704028, 9.899584000000118],
                [-13.704028, 9.900416],
                [-13.70375, 9.900972],
                [-13.703196, 9.901250000000118],
                [-13.703196, 9.901806000000136],
                [-13.701527, 9.90347300000019],
                [-13.700974, 9.90347300000019],
                [-13.700974, 9.904029],
                [-13.700417, 9.904029],
                [-13.700417, 9.90513900000019],
                [-13.699861, 9.906251],
                [-13.699304999999868, 9.906251],
                [-13.699304999999868, 9.907083000000114],
                [-13.699027, 9.907639000000131],
                [-13.697917, 9.907917],
                [-13.697917, 9.909029],
                [-13.697361, 9.909583],
                [-13.697361, 9.911527],
                [-13.697083, 9.911527],
                [-13.697083, 9.916805000000181],
                [-13.69680499999987, 9.916805000000181],
                [-13.69680499999987, 9.922638000000177],
                [-13.696527, 9.922638000000177],
                [-13.696527, 9.923750000000155],
                [-13.696249, 9.923750000000155],
                [-13.696249, 9.925138],
                [-13.695695, 9.925416000000155],
                [-13.695695, 9.926528000000133],
                [-13.69513899999987, 9.926806],
                [-13.69513899999987, 9.927638000000172],
                [-13.694861, 9.928194000000133],
                [-13.694304999999872, 9.928472],
                [-13.694029, 9.929585000000145],
                [-13.693195, 9.929860000000133],
                [-13.693195, 9.93041600000015],
                [-13.692638999999872, 9.930695000000128],
                [-13.692638999999872, 9.931251000000145],
                [-13.691251, 9.932639],
                [-13.690972999999872, 9.933195],
                [-13.689585, 9.934583000000146],
                [-13.68930399999988, 9.934583000000146],
                [-13.68930399999988, 9.935695000000123],
                [-13.688751, 9.935973],
                [-13.688471999999877, 9.936805000000106],
                [-13.688471999999877, 9.938195],
                [-13.688194, 9.938195],
                [-13.688194, 9.940973],
                [-13.687916, 9.941249000000141],
                [-13.68625, 9.941805000000102],
                [-13.685416, 9.941805000000102],
                [-13.684862, 9.942361000000119],
                [-13.684027999999898, 9.942639],
                [-13.682639999999878, 9.942639],
                [-13.682084, 9.941805000000102],
                [-13.681528, 9.941805000000102],
                [-13.68125, 9.941249000000141],
                [-13.680137999999886, 9.941527],
                [-13.679305999999883, 9.942083],
                [-13.679305999999883, 9.942639],
                [-13.67875, 9.942639],
                [-13.678471999999886, 9.943193],
                [-13.677362, 9.943193],
                [-13.676805999999885, 9.94291800000019],
                [-13.676805999999885, 9.942361000000119],
                [-13.676249, 9.942361000000119],
                [-13.675693, 9.941805000000102],
                [-13.675418, 9.941249000000141],
                [-13.675418, 9.940417],
                [-13.674583, 9.940417],
                [-13.673749, 9.939583000000141],
                [-13.67347299999983, 9.938471000000106],
                [-13.672917, 9.93791700000014],
                [-13.672917, 9.935139000000106],
                [-13.672638999999833, 9.935139000000106],
                [-13.672638999999833, 9.933473000000106],
                [-13.672361, 9.933473000000106],
                [-13.672361, 9.931807000000106],
                [-13.672083, 9.931807000000106],
                [-13.670971, 9.930695000000128],
                [-13.670971, 9.93041600000015],
                [-13.669305, 9.93041600000015],
                [-13.669305, 9.930138],
                [-13.668195, 9.930138],
                [-13.668195, 9.929860000000133],
                [-13.666527, 9.929860000000133],
                [-13.666527, 9.929585000000145],
                [-13.665139, 9.929585000000145],
                [-13.664583, 9.929028],
                [-13.664583, 9.928472],
                [-13.664029, 9.928472],
                [-13.663473, 9.927916],
                [-13.662917, 9.926806],
                [-13.662917, 9.925138],
                [-13.662638, 9.925138],
                [-13.662638, 9.924028],
                [-13.662917, 9.924028],
                [-13.662917, 9.9229160000001],
                [-13.663473, 9.922638000000177],
                [-13.663473, 9.922084000000154],
                [-13.664307, 9.9212500000001],
                [-13.664861, 9.920972000000177],
                [-13.665695, 9.920972000000177],
                [-13.666527, 9.920694],
                [-13.667083, 9.92014],
                [-13.668195, 9.92014],
                [-13.668473, 9.9195840000001],
                [-13.669027, 9.9195840000001],
                [-13.669583, 9.919028],
                [-13.669583, 9.917640000000176],
                [-13.669305, 9.917084000000159],
                [-13.668473, 9.916249],
                [-13.667639, 9.915974000000176],
                [-13.666527, 9.915974000000176],
                [-13.666527, 9.916249],
                [-13.662917, 9.916249],
                [-13.662085, 9.915971],
                [-13.661804, 9.915417000000105],
                [-13.66125, 9.915417000000105],
                [-13.660693999999864, 9.914583],
                [-13.660693999999864, 9.913195],
                [-13.660972, 9.912361],
                [-13.661529, 9.91208300000011],
                [-13.661529, 9.911527],
                [-13.662085, 9.911251],
                [-13.662085, 9.910417000000109],
                [-13.662359999999865, 9.909861],
                [-13.662917, 9.909583],
                [-13.663473, 9.908473000000185],
                [-13.663473, 9.907917],
                [-13.664029, 9.907639000000131],
                [-13.664029, 9.907083000000114],
                [-13.664583, 9.906807000000185],
                [-13.665417, 9.90513900000019],
                [-13.665695, 9.904304],
                [-13.665695, 9.900972],
                [-13.664861, 9.90013800000014],
                [-13.664307, 9.899862],
                [-13.663473, 9.899862],
                [-13.662917, 9.90013800000014],
                [-13.662085, 9.900972],
                [-13.662085, 9.901528],
                [-13.661529, 9.901806000000136],
                [-13.661529, 9.902360000000101],
                [-13.660693999999864, 9.902638],
                [-13.660693999999864, 9.90347300000019],
                [-13.659861999999862, 9.903751000000113],
                [-13.65875, 9.904861],
                [-13.655693999999869, 9.904861],
                [-13.655416, 9.904304],
                [-13.654306, 9.904304],
                [-13.654306, 9.903751000000113],
                [-13.653472, 9.903751000000113],
                [-13.652362, 9.902916000000118],
                [-13.652362, 9.900972],
                [-13.652916, 9.900972],
                [-13.652916, 9.899862],
                [-13.653193999999871, 9.8990280000001],
                [-13.653472, 9.8990280000001],
                [-13.653472, 9.897916000000123],
                [-13.654027999999869, 9.89764],
                [-13.654306, 9.897084],
                [-13.654306, 9.895694000000162],
                [-13.654584, 9.895694000000162],
                [-13.654584, 9.894584000000123],
                [-13.654862, 9.89375],
                [-13.655416, 9.89264],
                [-13.655693999999869, 9.89264],
                [-13.655693999999869, 9.891250000000127],
                [-13.65625, 9.891250000000127],
                [-13.65625, 9.890140000000144],
                [-13.656528, 9.890140000000144],
                [-13.656528, 9.889027],
                [-13.657084, 9.889027],
                [-13.657084, 9.887917],
                [-13.657638, 9.88763900000015],
                [-13.657638, 9.886805],
                [-13.658194, 9.885695],
                [-13.65875, 9.885417000000132],
                [-13.65875, 9.884583],
                [-13.659306, 9.884305000000154],
                [-13.659582, 9.883473],
                [-13.659582, 9.882361],
                [-13.660138, 9.882083],
                [-13.660138, 9.878473],
                [-13.65986, 9.877639],
                [-13.659306, 9.877085],
                [-13.659306, 9.876251],
                [-13.65875, 9.875972000000104],
                [-13.65875, 9.875138],
                [-13.658194, 9.874860000000126],
                [-13.657916, 9.87402800000018],
                [-13.657916, 9.872916],
                [-13.65735999999987, 9.87236200000018],
                [-13.657084, 9.87125],
                [-13.65625, 9.870972000000108],
                [-13.655693999999869, 9.870416],
                [-13.654862, 9.870416],
                [-13.654306, 9.87014],
                [-13.653193999999871, 9.869028000000185],
                [-13.652362, 9.86875],
                [-13.649306, 9.86875],
                [-13.649306, 9.868472],
                [-13.645694999999876, 9.868472],
                [-13.645139, 9.86819400000013],
                [-13.645139, 9.867638000000113],
                [-13.643473, 9.867638000000113],
                [-13.643471, 9.867362000000185],
                [-13.64236099999988, 9.867362000000185],
                [-13.641249, 9.86652800000013],
                [-13.640139, 9.865972000000113],
                [-13.639860999999826, 9.865416],
                [-13.639305, 9.865416],
                [-13.637639, 9.863749],
                [-13.637639, 9.863193],
                [-13.637083, 9.862083],
                [-13.636528999999825, 9.861527],
                [-13.636804, 9.860695000000135],
                [-13.636804, 9.85736100000014],
                [-13.637360999999828, 9.857083],
                [-13.637639, 9.856249000000162],
                [-13.637639, 9.854305],
                [-13.639305, 9.850973],
                [-13.639583, 9.850138],
                [-13.640139, 9.850138],
                [-13.640417, 9.849585000000161],
                [-13.640417, 9.848750000000166],
                [-13.640973, 9.848472],
                [-13.641249, 9.847362],
                [-13.641805, 9.84736],
                [-13.641805, 9.846806],
                [-13.642639, 9.84625],
                [-13.642639, 9.845694],
                [-13.643194999999878, 9.84541600000017],
                [-13.643194999999878, 9.84486],
                [-13.64402699999988, 9.844584],
                [-13.644305, 9.843472],
                [-13.644860999999878, 9.843194],
                [-13.646526999999878, 9.841528],
                [-13.647918, 9.840972],
                [-13.648471, 9.84041800000017],
                [-13.648748999999896, 9.839306],
                [-13.649584, 9.839028],
                [-13.650972, 9.83764],
                [-13.651527999999871, 9.836527000000103],
                [-13.651527999999871, 9.835418000000175],
                [-13.651806, 9.834861000000103],
                [-13.652362, 9.834861000000103],
                [-13.652362, 9.834027],
                [-13.652638, 9.833471],
                [-13.653193999999871, 9.83291700000018],
                [-13.653193999999871, 9.832083000000125],
                [-13.65375, 9.831805],
                [-13.653472, 9.83125100000018],
                [-13.653472, 9.829305],
                [-13.653193999999871, 9.828473],
                [-13.652362, 9.827639],
                [-13.652084, 9.82708300000013],
                [-13.651527999999871, 9.826805],
                [-13.651527999999871, 9.826251],
                [-13.650696, 9.825695],
                [-13.650696, 9.825139],
                [-13.649861999999871, 9.824861000000112],
                [-13.649584, 9.824304],
                [-13.649026999999876, 9.824029],
                [-13.648748999999896, 9.823473],
                [-13.648192999999878, 9.823473],
                [-13.647082999999895, 9.822916000000134],
                [-13.646805, 9.82208500000013],
                [-13.646248999999898, 9.821528],
                [-13.644860999999878, 9.821528],
                [-13.644305, 9.820972],
                [-13.643473, 9.820694000000117],
                [-13.642916999999898, 9.820138000000156],
                [-13.64236099999988, 9.820138000000156],
                [-13.642083, 9.819582000000139],
                [-13.641528999999878, 9.819582000000139],
                [-13.641249, 9.819028000000117],
                [-13.640417, 9.819028000000117],
                [-13.640139, 9.818472000000156],
                [-13.639026999999828, 9.818194],
                [-13.638473, 9.81764],
                [-13.637639, 9.81764],
                [-13.637360999999828, 9.816806000000156],
                [-13.636251, 9.816806000000156],
                [-13.635416, 9.816528],
                [-13.635138, 9.815694000000121],
                [-13.634307, 9.815416],
                [-13.634028, 9.814862],
                [-13.633472, 9.814862],
                [-13.632916, 9.814306],
                [-13.632082, 9.814306],
                [-13.631528, 9.814028000000121],
                [-13.630694, 9.814306],
                [-13.63014, 9.81375],
                [-13.629028, 9.812918000000138],
                [-13.628472, 9.81264],
                [-13.627362, 9.81180600000016],
                [-13.627362, 9.809862],
                [-13.627084, 9.809027],
                [-13.626806, 9.809027],
                [-13.626806, 9.807917],
                [-13.626249999999857, 9.806805],
                [-13.62541599999986, 9.806527000000187],
                [-13.624862, 9.80597100000017],
                [-13.624862, 9.805139],
                [-13.624583999999857, 9.804583],
                [-13.623196, 9.804027],
                [-13.622083999999859, 9.804027],
                [-13.622083999999859, 9.803749],
                [-13.614861, 9.803749],
                [-13.614861, 9.803473],
                [-13.613751, 9.803473],
                [-13.61374899999987, 9.803195000000187],
                [-13.612638999999888, 9.803195000000187],
                [-13.612638999999888, 9.802917],
                [-13.610695, 9.802917],
                [-13.609029, 9.802083],
                [-13.608194, 9.801805000000115],
                [-13.607915999999875, 9.801251],
                [-13.60708199999982, 9.801251],
                [-13.606528, 9.80097300000017],
                [-13.606528, 9.800417],
                [-13.605971999999895, 9.800139000000115],
                [-13.605694, 9.799583],
                [-13.604862, 9.798751],
                [-13.604862, 9.798195],
                [-13.604305999999895, 9.797639000000174],
                [-13.60375, 9.797639000000174],
                [-13.602915999999823, 9.797082000000103],
                [-13.602915999999823, 9.796529],
                [-13.601805999999897, 9.796529],
                [-13.601805999999897, 9.796251],
                [-13.599028, 9.796251],
                [-13.599028, 9.796529],
                [-13.597916, 9.796529],
                [-13.597639999999899, 9.795694],
                [-13.59875, 9.795694],
                [-13.59875, 9.795416000000102],
                [-13.60208399999982, 9.795416000000102],
                [-13.60208399999982, 9.795694],
                [-13.603471999999897, 9.795694],
                [-13.60375, 9.795973000000174],
                [-13.60375, 9.796804],
                [-13.604583999999875, 9.796804],
                [-13.604862, 9.797361],
                [-13.605694, 9.797917],
                [-13.605971999999895, 9.798751],
                [-13.60736, 9.798751],
                [-13.607637999999895, 9.79930700000017],
                [-13.609029, 9.79930700000017],
                [-13.609029, 9.799583],
                [-13.61013899999989, 9.799583],
                [-13.61013899999989, 9.799861],
                [-13.610972999999888, 9.800139000000115],
                [-13.61208299999987, 9.800139000000115],
                [-13.612917, 9.800417],
                [-13.613195, 9.799861],
                [-13.61374899999987, 9.799861],
                [-13.614029, 9.79930700000017],
                [-13.614583, 9.799027],
                [-13.615695, 9.797917],
                [-13.617361, 9.797917],
                [-13.617639, 9.797361],
                [-13.618195, 9.797361],
                [-13.618471, 9.796804],
                [-13.619027, 9.796804],
                [-13.620139, 9.795694],
                [-13.620417, 9.795138],
                [-13.620974, 9.794582],
                [-13.621249, 9.794028],
                [-13.621249, 9.792916],
                [-13.620693, 9.792362],
                [-13.620693, 9.790972000000124],
                [-13.620417, 9.790972000000124],
                [-13.620417, 9.789860000000147],
                [-13.620139, 9.789306000000124],
                [-13.619583, 9.789028],
                [-13.619583, 9.786528000000146],
                [-13.618749, 9.785972000000129],
                [-13.618749, 9.784306000000129],
                [-13.618471, 9.784306000000129],
                [-13.618471, 9.781805000000134],
                [-13.618195, 9.781805000000134],
                [-13.618195, 9.778751],
                [-13.617639, 9.778195],
                [-13.617639, 9.776805000000138],
                [-13.617917, 9.776805000000138],
                [-13.617917, 9.773195],
                [-13.616527, 9.771807000000138],
                [-13.615139, 9.771807000000138],
                [-13.615139, 9.771529],
                [-13.614029, 9.771529],
                [-13.614029, 9.771251000000177],
                [-13.612638999999888, 9.771251000000177],
                [-13.612638999999888, 9.770973],
                [-13.611251, 9.770973],
                [-13.611251, 9.77069500000016],
                [-13.609303999999895, 9.77069500000016],
                [-13.609303999999895, 9.770417],
                [-13.60736, 9.770417],
                [-13.60736, 9.770139],
                [-13.60541599999982, 9.770139],
                [-13.60541599999982, 9.769860000000165],
                [-13.603194, 9.769860000000165],
                [-13.603194, 9.769585000000177],
                [-13.60208399999982, 9.769585000000177],
                [-13.60208399999982, 9.769304],
                [-13.6009719999999, 9.769304],
                [-13.599862, 9.768194000000165],
                [-13.599583999999822, 9.767638],
                [-13.599583999999822, 9.766806],
                [-13.599305999999899, 9.76625],
                [-13.59875, 9.76625],
                [-13.598472, 9.76569400000011],
                [-13.597916, 9.76569400000011],
                [-13.597639999999899, 9.765138],
                [-13.595971, 9.765138],
                [-13.595971, 9.765416000000187],
                [-13.594305, 9.765416000000187],
                [-13.594305, 9.76569400000011],
                [-13.592917, 9.76569400000011],
                [-13.592361, 9.76625],
                [-13.590417, 9.767082000000187],
                [-13.589583, 9.767082000000187],
                [-13.589027, 9.767638],
                [-13.588195, 9.767638],
                [-13.587638999999854, 9.767916],
                [-13.587361, 9.768472],
                [-13.586805, 9.768751],
                [-13.584861, 9.768751],
                [-13.584861, 9.768472],
                [-13.583751, 9.768472],
                [-13.583473, 9.76902900000016],
                [-13.581251, 9.76902900000016],
                [-13.581251, 9.769585000000177],
                [-13.579859999999883, 9.769585000000177],
                [-13.579028, 9.769860000000165],
                [-13.579028, 9.770139],
                [-13.576806, 9.770139],
                [-13.576806, 9.770417],
                [-13.575693999999885, 9.770417],
                [-13.575693999999885, 9.77069500000016],
                [-13.574306, 9.77069500000016],
                [-13.574306, 9.770417],
                [-13.571806, 9.770417],
                [-13.571527999999887, 9.769860000000165],
                [-13.57097199999987, 9.769860000000165],
                [-13.57069399999989, 9.770417],
                [-13.567918, 9.770417],
                [-13.566804999999817, 9.77069500000016],
                [-13.565694999999891, 9.77069500000016],
                [-13.564305, 9.771251000000177],
                [-13.564305, 9.771807000000138],
                [-13.563751, 9.771807000000138],
                [-13.563751, 9.77236100000016],
                [-13.563194999999894, 9.772639],
                [-13.562917, 9.773195],
                [-13.562917, 9.774305],
                [-13.562360999999896, 9.774305],
                [-13.562360999999896, 9.775417],
                [-13.562639, 9.775417],
                [-13.563194999999894, 9.776527],
                [-13.563194999999894, 9.777083],
                [-13.564026999999896, 9.777639],
                [-13.564305, 9.778471000000138],
                [-13.565417, 9.780693000000156],
                [-13.565971, 9.781249000000116],
                [-13.565971, 9.781805000000134],
                [-13.566526999999894, 9.782083],
                [-13.566526999999894, 9.782640000000129],
                [-13.565971, 9.782918],
                [-13.565417, 9.782362],
                [-13.565417, 9.781805000000134],
                [-13.564583, 9.780974000000128],
                [-13.564026999999896, 9.779861],
                [-13.563751219999915, 9.779026986000133],
                [-13.563195228999916, 9.778751372999977],
                [-13.56291675599988, 9.777916909000055],
                [-13.56208324399995, 9.777083397000183],
                [-13.56180477099997, 9.776527404000092],
                [-13.56180477099997, 9.775694847000068],
                [-13.560694693999892, 9.773472786000127],
                [-13.56041717599993, 9.773195268000109],
                [-13.559582709999972, 9.773195268000109],
                [-13.55930519099985, 9.773751259000164],
                [-13.558751105999931, 9.773751259000164],
                [-13.558472632999951, 9.774305345000187],
                [-13.557916640999963, 9.774305345000187],
                [-13.557360647999872, 9.774861336000072],
                [-13.557360647999872, 9.775417329000163],
                [-13.556804656999816, 9.775694847000068],
                [-13.556250572999886, 9.777360916000134],
                [-13.554585457999963, 9.777360916000134],
                [-13.554585457999963, 9.777083397000183],
                [-13.552638053999942, 9.777083397000183],
                [-13.552638053999942, 9.776804924000146],
                [-13.550415992999945, 9.776804924000146],
                [-13.55013847399988, 9.776527404000092],
                [-13.55013847399988, 9.775417329000163],
                [-13.550415992999945, 9.775417329000163],
                [-13.550415992999945, 9.774305345000187],
                [-13.550694465999982, 9.773751259000164],
                [-13.55125045699998, 9.773472786000127],
                [-13.552082060999965, 9.771806718000107],
                [-13.552082060999965, 9.769860269000105],
                [-13.552362440999957, 9.769860269000105],
                [-13.552362440999957, 9.768751144000078],
                [-13.552915571999904, 9.76847171900016],
                [-13.552915571999904, 9.767638207000118],
                [-13.553471565999814, 9.767359734000081],
                [-13.553471565999814, 9.766805648000059],
                [-13.554029463999939, 9.766528129999983],
                [-13.55486011599993, 9.765693664000082],
                [-13.55486011599993, 9.76513767300014],
                [-13.555973052999946, 9.764583587000118],
                [-13.556529043999944, 9.764027596000062],
                [-13.556529043999944, 9.763471604000074],
                [-13.557639120999909, 9.763471604000074],
                [-13.557639120999909, 9.762915611000153],
                [-13.558195113999943, 9.762915611000153],
                [-13.558472632999951, 9.76236152700011],
                [-13.560138702999893, 9.76152801600017],
                [-13.56180477099997, 9.76152801600017],
                [-13.56180477099997, 9.761249543000133],
                [-13.5640268329999, 9.761249543000133],
                [-13.5640268329999, 9.760972023000079],
                [-13.566248893999898, 9.760972023000079],
                [-13.566248893999898, 9.760693550000042],
                [-13.56791782299996, 9.760693550000042],
                [-13.568193435999945, 9.76152801600017],
                [-13.569862365999938, 9.76152801600017],
                [-13.570416449999925, 9.760972023000079],
                [-13.571249960999978, 9.760693550000042],
                [-13.572084427999926, 9.760693550000042],
                [-13.572361945999944, 9.760137558000054],
                [-13.573193549999928, 9.760137558000054],
                [-13.574305533999848, 9.75958442700005],
                [-13.57486152599995, 9.759028434000129],
                [-13.575971602999914, 9.759028434000129],
                [-13.577083586999947, 9.758472443000073],
                [-13.577083586999947, 9.758193970000036],
                [-13.577916145999893, 9.757916452000131],
                [-13.578749656999946, 9.757916452000131],
                [-13.579305648999934, 9.757639886000163],
                [-13.57958221399997, 9.757083893000072],
                [-13.580694198999879, 9.757083893000072],
                [-13.580971716999954, 9.756527902000187],
                [-13.582084655999893, 9.755970955],
                [-13.583194732999914, 9.755970955],
                [-13.583473205999951, 9.755140304000122],
                [-13.584583282999972, 9.754860878000102],
                [-13.58513927499996, 9.754027367000162],
                [-13.58569526599996, 9.754027367000162],
                [-13.5859727859999, 9.75347137600005],
                [-13.587082862999921, 9.75347137600005],
                [-13.587361335999958, 9.752917290000028],
                [-13.58847332099998, 9.752361299000142],
                [-13.589583397999888, 9.752361299000142],
                [-13.589583397999888, 9.752082826000105],
                [-13.590416908999941, 9.751526832000081],
                [-13.590416908999941, 9.750970841000026],
                [-13.591528891999928, 9.750970841000026],
                [-13.591528891999928, 9.750416755000174],
                [-13.592082976999848, 9.750416755000174],
                [-13.592361449999885, 9.749860764000118],
                [-13.592917441999873, 9.749860764000118],
                [-13.593193053999926, 9.74902916000002],
                [-13.594026565999968, 9.748748780000085],
                [-13.594026565999968, 9.747917176000044],
                [-13.595417975999908, 9.747361184000056],
                [-13.595417975999908, 9.746805191000135],
                [-13.595971106999855, 9.746805191000135],
                [-13.595971106999855, 9.746251107000148],
                [-13.597361564999972, 9.74541664100019],
                [-13.597361564999972, 9.744860650000078],
                [-13.598194121999938, 9.744304657000043],
                [-13.598750114999916, 9.744304657000043],
                [-13.598750114999916, 9.74375057300017],
                [-13.599306105999915, 9.74375057300017],
                [-13.600137709999899, 9.742917061000128],
                [-13.600137709999899, 9.742637634],
                [-13.600972175999914, 9.74180698400005],
                [-13.601528167999902, 9.741804124000168],
                [-13.602084160999937, 9.740971565000109],
                [-13.602640151999935, 9.740415574],
                [-13.602640151999935, 9.739027977000035],
                [-13.602084160999937, 9.738750458000084],
                [-13.601805687999956, 9.737915992000126],
                [-13.600972175999914, 9.737084388000085],
                [-13.600972175999914, 9.73652839700003],
                [-13.600418090999824, 9.73652839700003],
                [-13.600418090999824, 9.735972406000087],
                [-13.600972175999914, 9.73569393300005],
                [-13.601805687999956, 9.73486232900018],
                [-13.602640151999935, 9.73486232900018],
                [-13.602640151999935, 9.73541641200012],
                [-13.60486221399998, 9.73541641200012],
                [-13.60541629699992, 9.736249924000163],
                [-13.606805801999883, 9.736249924000163],
                [-13.60735988599987, 9.736805915000048],
                [-13.612083434999931, 9.736805915000048],
                [-13.61291694599987, 9.73652839700003],
                [-13.61291694599987, 9.735972406000087],
                [-13.61402893099995, 9.73569393300005],
                [-13.61402893099995, 9.735137939000083],
                [-13.61458301499988, 9.73486232900018],
                [-13.61458301499988, 9.733471871000063],
                [-13.614860534999934, 9.733471871000063],
                [-13.614860534999934, 9.730972289000022],
                [-13.615139007999971, 9.730972289000022],
                [-13.615973471999894, 9.730139733000044],
                [-13.616526602999954, 9.729026795000152],
                [-13.616526602999954, 9.72763919800019],
                [-13.617082595999932, 9.727360725000153],
                [-13.61736106899997, 9.726248741000177],
                [-13.617917060999957, 9.725694657000133],
                [-13.618194580999898, 9.72486114500009],
                [-13.618194580999898, 9.72319507700007],
                [-13.618471145999877, 9.72236061100017],
                [-13.619027137999979, 9.721804619000181],
                [-13.619304657999919, 9.720973015000084],
                [-13.619304657999919, 9.718473434000146],
                [-13.619027137999979, 9.718473434000146],
                [-13.619027137999979, 9.715695381000046],
                [-13.619304657999919, 9.715695381000046],
                [-13.619304657999919, 9.713193894000085],
                [-13.619860648999918, 9.712637902000097],
                [-13.619860648999918, 9.708193779],
                [-13.620416639999917, 9.707916259000172],
                [-13.620416639999917, 9.705694199000163],
                [-13.62097358599982, 9.705140114000073],
                [-13.62097358599982, 9.703749656000127],
                [-13.621249198999976, 9.703749656000127],
                [-13.621249198999976, 9.702083588000107],
                [-13.621526716999938, 9.702083588000107],
                [-13.621526716999938, 9.69902706199997],
                [-13.621805189999975, 9.69902706199997],
                [-13.621805189999975, 9.696805001000087],
                [-13.622083662999898, 9.696805001000087],
                [-13.622083662999898, 9.694026948000158],
                [-13.621805189999975, 9.694026948000158],
                [-13.621805189999975, 9.692639351000025],
                [-13.622083662999898, 9.692360878000159],
                [-13.623195647999978, 9.692360878000159],
                [-13.623749733999944, 9.692083359000037],
                [-13.623749733999944, 9.69125080100008],
                [-13.624305724999942, 9.690694809000036],
                [-13.624305724999942, 9.689584732000128],
                [-13.62458419799998, 9.689584732000128],
                [-13.62458419799998, 9.68652820599999],
                [-13.624305724999942, 9.685972213000127],
                [-13.623749733999944, 9.68569374000009],
                [-13.623195647999978, 9.685137748999978],
                [-13.623195647999978, 9.68375015399999],
                [-13.623472212999957, 9.683471681000185],
                [-13.623472212999957, 9.679583549000142],
                [-13.623749733999944, 9.679583549000142],
                [-13.623749733999944, 9.677915573000178],
                [-13.623472212999957, 9.677915573000178],
                [-13.623472212999957, 9.675692559000083],
                [-13.62402820699998, 9.675140381000062],
                [-13.624305724999942, 9.674583435000159],
                [-13.624305724999942, 9.672082902000113],
                [-13.62458419799998, 9.672082902000113],
                [-13.62458419799998, 9.670139313000163],
                [-13.625415801999907, 9.669860840000126],
                [-13.625415801999907, 9.667917252000052],
                [-13.625971792999906, 9.667361261000167],
                [-13.625971792999906, 9.66597080300005],
                [-13.626250265999943, 9.665416717000028],
                [-13.626806258999977, 9.665416717000028],
                [-13.626806258999977, 9.664029122000045],
                [-13.627916335999885, 9.663473129000124],
                [-13.627916335999885, 9.661804200000176],
                [-13.627637862999848, 9.661804200000176],
                [-13.627637862999848, 9.660415650000061],
                [-13.62819385499995, 9.65985965700014],
                [-13.62819385499995, 9.65819358900012],
                [-13.627916335999885, 9.65819358900012],
                [-13.627916335999885, 9.648749351000106],
                [-13.627637862999848, 9.648749351000106],
                [-13.627637862999848, 9.64597129900011],
                [-13.627362250999965, 9.645417213000087],
                [-13.627362250999965, 9.644582749000165],
                [-13.627083777999928, 9.643751145000124],
                [-13.62652778599994, 9.643472672000087],
                [-13.626250265999943, 9.642639160000044],
                [-13.626250265999943, 9.640973092000024],
                [-13.625694274999944, 9.640694619000158],
                [-13.625694274999944, 9.64013862500019],
                [-13.62486171799992, 9.639861107000115],
                [-13.62486171799992, 9.63847255700017],
                [-13.625140188999865, 9.63847255700017],
                [-13.625971792999906, 9.637639046],
                [-13.626250265999943, 9.636528969000096],
                [-13.626806258999977, 9.636247635000075],
                [-13.626806258999977, 9.635695457000054],
                [-13.627362250999965, 9.635416984000017],
                [-13.627362250999965, 9.634860038000113],
                [-13.628472327999873, 9.634029389000034],
                [-13.628749847999927, 9.633472443000187],
                [-13.629859924999948, 9.633472443000187],
                [-13.63014030599993, 9.632637979000037],
                [-13.631527900999913, 9.631250380999973],
                [-13.632637977999934, 9.631250380999973],
                [-13.632916450999971, 9.63041591700005],
                [-13.63347244199997, 9.63041591700005],
                [-13.634028434999948, 9.62958431300018],
                [-13.634028434999948, 9.629028320000089],
                [-13.634585380999965, 9.628749847000051],
                [-13.634585380999965, 9.627916337000158],
                [-13.63541603099992, 9.627083779000031],
                [-13.635972976999824, 9.627083779000031],
                [-13.636528967999823, 9.626527787000043],
                [-13.637360571999977, 9.62625026700016],
                [-13.63875102899982, 9.62625026700016],
                [-13.63875102899982, 9.625694276000104],
                [-13.6393051149999, 9.624584198999969],
                [-13.639861105999898, 9.624584198999969],
                [-13.640138626999942, 9.62347221400006],
                [-13.64069461799994, 9.623195649000024],
                [-13.64069461799994, 9.622362137000152],
                [-13.641804694999962, 9.621249200000022],
                [-13.642639158999827, 9.621249200000022],
                [-13.643470762999982, 9.620973587000037],
                [-13.64347267099987, 9.620139123000058],
                [-13.64430522899994, 9.619860650000021],
                [-13.64430522899994, 9.619304657000157],
                [-13.645138740999869, 9.618748665000112],
                [-13.645138740999869, 9.617917061000071],
                [-13.64430522899994, 9.617638588000034],
                [-13.643195151999976, 9.617638588000034],
                [-13.642916678999939, 9.61736106799998],
                [-13.642916678999939, 9.616526604000057],
                [-13.643195151999976, 9.616249085999982],
                [-13.64430522899994, 9.616249085999982],
                [-13.64430522899994, 9.616526604000057],
                [-13.646527290999927, 9.616526604000057],
                [-13.646804808999946, 9.615695000000187],
                [-13.648470878999888, 9.615695000000187],
                [-13.649026870999876, 9.615139009000075],
                [-13.649026870999876, 9.614027024000166],
                [-13.648749350999935, 9.614027024000166],
                [-13.648749350999935, 9.612916947000087],
                [-13.649306296999896, 9.612083436000148],
                [-13.649862289999817, 9.612083436000148],
                [-13.650415420999934, 9.611527442000124],
                [-13.650415420999934, 9.610138894000158],
                [-13.651805877999948, 9.610138894000158],
                [-13.65291786299997, 9.609582901000067],
                [-13.653194427999892, 9.60902881700008],
                [-13.654306410999823, 9.60902881700008],
                [-13.654583930999934, 9.608751297000026],
                [-13.654583930999934, 9.607915878000085],
                [-13.655139921999933, 9.607915878000085],
                [-13.655139921999933, 9.606806755999969],
                [-13.655694007999955, 9.606249810000065],
                [-13.655694007999955, 9.605416298000023],
                [-13.655971525999917, 9.604860307000138],
                [-13.65652847199982, 9.60458374100017],
                [-13.65652847199982, 9.60375023000006],
                [-13.656805992999978, 9.603194237000139],
                [-13.657360075999975, 9.603194237000139],
                [-13.657360075999975, 9.602359772000113],
                [-13.657916069999942, 9.601805686999967],
                [-13.657916069999942, 9.600137711000173],
                [-13.658193587999904, 9.600137711000173],
                [-13.658193587999904, 9.599027634000095],
                [-13.657637596999905, 9.598471642999982],
                [-13.657637596999905, 9.596528054000032],
                [-13.657916069999942, 9.596528054000032],
                [-13.657916069999942, 9.595417977000125],
                [-13.657637596999905, 9.595417977000125],
                [-13.657637596999905, 9.593749046000141],
                [-13.658194, 9.592083000000116],
                [-13.657916, 9.591527000000156],
                [-13.657916, 9.589861000000155],
                [-13.65875, 9.589583],
                [-13.659582, 9.58874900000012],
                [-13.659861999999862, 9.587917],
                [-13.66125, 9.587917],
                [-13.661804, 9.587361],
                [-13.662085, 9.58652700000016],
                [-13.663473, 9.58652700000016],
                [-13.664029, 9.586251],
                [-13.665417, 9.58486100000016],
                [-13.666251, 9.584305000000143],
                [-13.666251, 9.58319500000016],
                [-13.666527, 9.58319500000016],
                [-13.666527, 9.581251],
                [-13.666251, 9.581251],
                [-13.666251, 9.574862],
                [-13.665695, 9.57375],
                [-13.665139, 9.573194000000115],
                [-13.664307, 9.572918000000186],
                [-13.664307, 9.572084],
                [-13.664583, 9.571806],
                [-13.665417, 9.571806],
                [-13.665695, 9.570694000000174],
                [-13.666251, 9.570416],
                [-13.666527, 9.569862000000114],
                [-13.666251, 9.569306],
                [-13.666251, 9.564861],
                [-13.665973, 9.564861],
                [-13.665973, 9.561529],
                [-13.666251, 9.560973],
                [-13.666805, 9.560695000000123],
                [-13.666805, 9.560139000000106],
                [-13.667639, 9.559583000000146],
                [-13.670695, 9.559583000000146],
                [-13.671251, 9.558751],
                [-13.671251, 9.558195],
                [-13.672361, 9.558195],
                [-13.672361, 9.557083],
                [-13.671251, 9.557083],
                [-13.671251, 9.555973],
                [-13.670971, 9.555973],
                [-13.670971, 9.554860000000133],
                [-13.671527, 9.554029000000128],
                [-13.67347299999983, 9.554029000000128],
                [-13.67347299999983, 9.553751],
                [-13.675693, 9.553751],
                [-13.675693, 9.553472],
                [-13.676528, 9.552638000000115],
                [-13.677639999999883, 9.552638000000115],
                [-13.677639999999883, 9.552082000000155],
                [-13.678471999999886, 9.551528000000133],
                [-13.678471999999886, 9.550694],
                [-13.679028, 9.550416000000155],
                [-13.679028, 9.548472],
                [-13.679584, 9.548194000000137],
                [-13.679584, 9.547362],
                [-13.679862, 9.546806],
                [-13.680418, 9.546806],
                [-13.680418, 9.54625],
                [-13.680971999999883, 9.545972000000177],
                [-13.680971999999883, 9.54514],
                [-13.681528, 9.54514],
                [-13.682639999999878, 9.544584],
                [-13.682916, 9.544028],
                [-13.683471999999881, 9.544028],
                [-13.683471999999881, 9.543196000000137],
                [-13.68375, 9.542640000000176],
                [-13.684305999999879, 9.542362],
                [-13.684305999999879, 9.541528],
                [-13.685137999999881, 9.541249000000164],
                [-13.685137999999881, 9.540693],
                [-13.685971999999879, 9.540418000000159],
                [-13.685971999999879, 9.539861],
                [-13.686805999999876, 9.539027],
                [-13.687359999999899, 9.539027],
                [-13.687359999999899, 9.538471000000186],
                [-13.687916, 9.538471000000186],
                [-13.688194, 9.537639],
                [-13.688751, 9.537639],
                [-13.689029, 9.53708300000011],
                [-13.689029, 9.536249000000169],
                [-13.68986, 9.535695],
                [-13.68986, 9.534583000000168],
                [-13.690417, 9.534583000000168],
                [-13.690417, 9.533749000000114],
                [-13.691251, 9.533473000000185],
                [-13.69180699999987, 9.532917000000168],
                [-13.69180699999987, 9.530417000000114],
                [-13.692083, 9.53013900000019],
                [-13.693195, 9.53013900000019],
                [-13.693195, 9.529029],
                [-13.692083, 9.528751000000113],
                [-13.692083, 9.528195],
                [-13.691527, 9.527917],
                [-13.691527, 9.527360000000101],
                [-13.690972999999872, 9.52680700000019],
                [-13.690972999999872, 9.525694000000101],
                [-13.690695, 9.525694000000101],
                [-13.690695, 9.5240280000001],
                [-13.691251, 9.5240280000001],
                [-13.69180699999987, 9.52347200000014],
                [-13.69180699999987, 9.522916000000123],
                [-13.695695, 9.522916000000123],
                [-13.696249, 9.522360000000106],
                [-13.697083, 9.522360000000106],
                [-13.697638999999867, 9.522084],
                [-13.697917, 9.521528],
                [-13.697917, 9.520694000000105],
                [-13.698195, 9.519862],
                [-13.698749, 9.519862],
                [-13.698749, 9.519306],
                [-13.699304999999868, 9.519306],
                [-13.699304999999868, 9.519862],
                [-13.699861, 9.520138000000145],
                [-13.70264, 9.520138000000145],
                [-13.703471, 9.520416],
                [-13.704584, 9.520972],
                [-13.704584, 9.51875],
                [-13.704862, 9.517916000000127],
                [-13.706528, 9.517916000000127],
                [-13.706528, 9.51736000000011],
                [-13.708472, 9.51736000000011],
                [-13.709028, 9.516806000000145],
                [-13.711249999999836, 9.516806000000145],
                [-13.711249999999836, 9.51736000000011],
                [-13.711806, 9.51736000000011],
                [-13.711806, 9.517916000000127],
                [-13.712362, 9.518194],
                [-13.712362, 9.521250000000123],
                [-13.714584999999886, 9.521250000000123],
                [-13.715415999999891, 9.519584000000123],
                [-13.714584999999886, 9.519028000000105],
                [-13.71486, 9.518472000000145],
                [-13.715415999999891, 9.518472000000145],
                [-13.715694, 9.519028000000105],
                [-13.716529, 9.519028000000105],
                [-13.716804, 9.518472000000145],
                [-13.717361, 9.518472000000145],
                [-13.718195, 9.51764],
                [-13.718195, 9.517084],
                [-13.719027, 9.516528],
                [-13.720139, 9.516250000000127],
                [-13.720416999999884, 9.51569400000011],
                [-13.720416999999884, 9.514584000000127],
                [-13.720695, 9.514027],
                [-13.721248999999887, 9.513749000000132],
                [-13.721248999999887, 9.513193000000172],
                [-13.722361, 9.512361],
                [-13.722361, 9.510971000000154],
                [-13.721529, 9.510971000000154],
                [-13.721529, 9.510139],
                [-13.720695, 9.510139],
                [-13.720695, 9.509583],
                [-13.720139, 9.509305000000154],
                [-13.719861, 9.508749],
                [-13.719861, 9.505973000000154],
                [-13.719307, 9.505417],
                [-13.719307, 9.504583],
                [-13.718473, 9.504861000000176],
                [-13.717639, 9.504583],
                [-13.715973, 9.504583],
                [-13.715415999999891, 9.503751],
                [-13.715415999999891, 9.502361],
                [-13.715694, 9.501529000000176],
                [-13.715415999999891, 9.500973000000158],
                [-13.71374999999989, 9.500973000000158],
                [-13.713472, 9.501251],
                [-13.713472, 9.502361],
                [-13.712915999999836, 9.502917],
                [-13.711528, 9.502917],
                [-13.710694, 9.503195000000176],
                [-13.70986, 9.502917],
                [-13.709583999999836, 9.502361],
                [-13.708472, 9.502361],
                [-13.708194, 9.502917],
                [-13.707083999999838, 9.503473],
                [-13.70625, 9.504307000000153],
                [-13.705694, 9.504583],
                [-13.70514, 9.505139],
                [-13.704306, 9.505139],
                [-13.70375, 9.505417],
                [-13.703196, 9.505973000000154],
                [-13.703196, 9.507361],
                [-13.702918, 9.508193000000176],
                [-13.70264, 9.508195000000171],
                [-13.70264, 9.509305000000154],
                [-13.701805, 9.509583],
                [-13.701805, 9.510139],
                [-13.700974, 9.510417000000132],
                [-13.700693, 9.510971000000154],
                [-13.698195, 9.510971000000154],
                [-13.697083, 9.510695],
                [-13.696249, 9.510971000000154],
                [-13.696249, 9.51263900000015],
                [-13.69680499999987, 9.513193000000172],
                [-13.69680499999987, 9.513749000000132],
                [-13.697638999999867, 9.514027],
                [-13.697638999999867, 9.514584000000127],
                [-13.697083, 9.515140000000144],
                [-13.696527, 9.515140000000144],
                [-13.695695, 9.515972],
                [-13.695695, 9.516528],
                [-13.69513899999987, 9.516806000000145],
                [-13.694583, 9.516528],
                [-13.693751, 9.516528],
                [-13.693751, 9.517084],
                [-13.693195, 9.51736000000011],
                [-13.693195, 9.518194],
                [-13.692361, 9.51875],
                [-13.69184099999984, 9.518809000000147],
                [-13.69180699999987, 9.519306],
                [-13.691251, 9.519862],
                [-13.68986, 9.519862],
                [-13.68930399999988, 9.520138000000145],
                [-13.688751, 9.520694000000105],
                [-13.688194, 9.520694000000105],
                [-13.687916, 9.521250000000123],
                [-13.687359999999899, 9.521528],
                [-13.686805999999876, 9.522084],
                [-13.68625, 9.522360000000106],
                [-13.685971999999879, 9.52347200000014],
                [-13.68375, 9.52347200000014],
                [-13.683471999999881, 9.5240280000001],
                [-13.682916, 9.524306],
                [-13.681805999999881, 9.524306],
                [-13.681528, 9.52486],
                [-13.681528, 9.525972],
                [-13.680971999999883, 9.526250000000118],
                [-13.680694, 9.52680700000019],
                [-13.680137999999886, 9.52680700000019],
                [-13.679862, 9.527360000000101],
                [-13.679862, 9.528195],
                [-13.679584, 9.528751000000113],
                [-13.678471999999886, 9.529305],
                [-13.677639999999883, 9.529305],
                [-13.676805999999885, 9.529861],
                [-13.676805999999885, 9.530417000000114],
                [-13.675970999999834, 9.530417000000114],
                [-13.675970999999834, 9.530973],
                [-13.675139999999885, 9.531527],
                [-13.674027, 9.532083000000114],
                [-13.674027, 9.532639],
                [-13.67347299999983, 9.533195],
                [-13.672361, 9.533195],
                [-13.671805, 9.533473000000185],
                [-13.671805, 9.534029],
                [-13.671251, 9.534029],
                [-13.671251, 9.534583000000168],
                [-13.670695, 9.535139000000186],
                [-13.669861, 9.535139000000186],
                [-13.669583, 9.536249000000169],
                [-13.666805, 9.536249000000169],
                [-13.666527, 9.536805000000186],
                [-13.665417, 9.536805000000186],
                [-13.665417, 9.537917000000164],
                [-13.665139, 9.538471000000186],
                [-13.664583, 9.538471000000186],
                [-13.664307, 9.539305],
                [-13.662917, 9.539861],
                [-13.662917, 9.540418000000159],
                [-13.662085, 9.540693],
                [-13.662085, 9.541249000000164],
                [-13.661529, 9.541528],
                [-13.661529, 9.542084000000159],
                [-13.660972, 9.542362],
                [-13.660972, 9.542916],
                [-13.660416, 9.542916],
                [-13.660416, 9.543472],
                [-13.659861999999862, 9.544306000000176],
                [-13.658472, 9.544306000000176],
                [-13.658472, 9.544584],
                [-13.657638, 9.544862000000137],
                [-13.655972, 9.544862000000137],
                [-13.655693999999869, 9.545694],
                [-13.65514, 9.545972000000177],
                [-13.65514, 9.546806],
                [-13.651527999999871, 9.546806],
                [-13.650972, 9.547362],
                [-13.649306, 9.547362],
                [-13.649306, 9.547916],
                [-13.647918, 9.547916],
                [-13.647918, 9.548194000000137],
                [-13.646805, 9.548194000000137],
                [-13.646248999999898, 9.547916],
                [-13.645971, 9.548194000000137],
                [-13.645971, 9.549028],
                [-13.645139, 9.549584],
                [-13.645139, 9.550416000000155],
                [-13.64402699999988, 9.550694],
                [-13.643750999999895, 9.55125],
                [-13.642916999999898, 9.55125],
                [-13.64236099999988, 9.551806],
                [-13.64236099999988, 9.552363000000128],
                [-13.641805, 9.552638000000115],
                [-13.641805, 9.553194000000133],
                [-13.641249, 9.553194000000133],
                [-13.640973, 9.554307],
                [-13.639305, 9.554307],
                [-13.639305, 9.554582],
                [-13.637917, 9.554582],
                [-13.637360999999828, 9.555417],
                [-13.636528967999823, 9.555694581000182],
                [-13.636803627999882, 9.556529045000104],
                [-13.636803627999882, 9.557639122000069],
                [-13.636528967999823, 9.557639122000069],
                [-13.636528967999823, 9.559861183000123],
                [-13.636251449999861, 9.560417175000111],
                [-13.63569545799993, 9.560417175000111],
                [-13.63541603099992, 9.560973168000032],
                [-13.634860038999932, 9.561248779000039],
                [-13.632637977999934, 9.561248779000039],
                [-13.632637977999934, 9.560694695000166],
                [-13.632081984999957, 9.560694695000166],
                [-13.632083891999912, 9.560138702000074],
                [-13.631527900999913, 9.559582710000086],
                [-13.630971908999925, 9.559305192000068],
                [-13.625694274999944, 9.559305192000068],
                [-13.625694274999944, 9.559582710000086],
                [-13.62458419799998, 9.559582710000086],
                [-13.624305724999942, 9.560138702000074],
                [-13.624305724999942, 9.561248779000039],
                [-13.623749733999944, 9.561248779000039],
                [-13.623749733999944, 9.562360763000186],
                [-13.622083662999898, 9.56319523000002],
                [-13.621526716999938, 9.56319523000002],
                [-13.62097358599982, 9.563751221000132],
                [-13.619860648999918, 9.563751221000132],
                [-13.619027137999979, 9.563470840000093],
                [-13.618748664999941, 9.564026834000117],
                [-13.618194580999898, 9.564305307000154],
                [-13.617082595999932, 9.564582825000173],
                [-13.616526602999954, 9.565138816000115],
                [-13.61569499899997, 9.565138816000115],
                [-13.615139007999971, 9.565417289000152],
                [-13.614860534999934, 9.565974235],
                [-13.61402893099995, 9.565971375000117],
                [-13.613472938999962, 9.56652736600006],
                [-13.613472938999962, 9.56847095500018],
                [-13.613195418999908, 9.56930637400012],
                [-13.61291694599987, 9.56930637400012],
                [-13.61291694599987, 9.570416451000028],
                [-13.612360954999872, 9.570693971000082],
                [-13.610694884999873, 9.570695876000173],
                [-13.610417365999922, 9.571249962000024],
                [-13.609581946999981, 9.571528435000062],
                [-13.609581946999981, 9.572361947000104],
                [-13.609028815999977, 9.572637557000121],
                [-13.609028815999977, 9.573193551000145],
                [-13.608194351999941, 9.573472024000182],
                [-13.605972290999944, 9.573472024000182],
                [-13.605972290999944, 9.573193551000145],
                [-13.604027746999975, 9.573193551000145],
                [-13.603194235999922, 9.57291793800016],
                [-13.602915764999977, 9.572361947000104],
                [-13.601805687999956, 9.572361947000104],
                [-13.600418090999824, 9.57180595300008],
                [-13.600137709999899, 9.571249962000024],
                [-13.599306105999915, 9.571249962000024],
                [-13.598750114999916, 9.57097244200014],
                [-13.598194121999938, 9.57013988500006],
                [-13.597640037999895, 9.569862367000042],
                [-13.59680557299987, 9.569862367000042],
                [-13.59680557299987, 9.56930637400012],
                [-13.596249579999892, 9.569027901000084],
                [-13.595417975999908, 9.569027901000084],
                [-13.594861984999909, 9.568750382000133],
                [-13.594861984999909, 9.567917824000176],
                [-13.594026565999968, 9.567640305000054],
                [-13.593749045999914, 9.566804886000114],
                [-13.593193053999926, 9.56652736600006],
                [-13.593193053999926, 9.565971375000117],
                [-13.59263896899995, 9.565694808999979],
                [-13.59263896899995, 9.565138816000115],
                [-13.592082976999848, 9.56486129800004],
                [-13.592082976999848, 9.564305307000154],
                [-13.591526985999849, 9.564026834000117],
                [-13.591251372999977, 9.562916756999982],
                [-13.590695379999943, 9.562639236000052],
                [-13.590695379999943, 9.56208324500011],
                [-13.590139388999944, 9.561804772000073],
                [-13.589860915999907, 9.561248779000039],
                [-13.589860915999907, 9.560417175000111],
                [-13.589027403999978, 9.560138702000074],
                [-13.588748930999941, 9.55902671900003],
                [-13.589304924999851, 9.558751106000045],
                [-13.589304924999851, 9.556529045000104],
                [-13.58847332099998, 9.555417060000025],
                [-13.58847332099998, 9.55458164300012],
                [-13.58763885399992, 9.554029465000099],
                [-13.58763885399992, 9.552082062000125],
                [-13.587082862999921, 9.551806451000175],
                [-13.58680534299998, 9.551250458000084],
                [-13.58680534299998, 9.550415993000058],
                [-13.586526869999943, 9.549584389000188],
                [-13.58569526599996, 9.549584389000188],
                [-13.58513927499996, 9.548472404999984],
                [-13.58513927499996, 9.547916414000099],
                [-13.584583282999972, 9.547637941000062],
                [-13.584583282999972, 9.546806337000021],
                [-13.58402919799994, 9.546527864000154],
                [-13.58402728999988, 9.54597187000013],
                [-13.583473205999951, 9.545694352000112],
                [-13.583473205999951, 9.54514026600009],
                [-13.582638739999936, 9.54430580200011],
                [-13.582360266999899, 9.543749809000076],
                [-13.5818042759999, 9.543749809000076],
                [-13.5818042759999, 9.542916297000033],
                [-13.581251144999953, 9.541806222000105],
                [-13.580694198999879, 9.54124927600003],
                [-13.57958221399997, 9.540417672000103],
                [-13.57958221399997, 9.539304733000165],
                [-13.57902812999987, 9.53902721500009],
                [-13.578749656999946, 9.538471221000123],
                [-13.578193663999855, 9.537917138000182],
                [-13.577916145999893, 9.536805153000103],
                [-13.577360152999972, 9.536526680000065],
                [-13.577083586999947, 9.535416603000158],
                [-13.576250075999951, 9.535139085000083],
                [-13.575694083999963, 9.533748626999966],
                [-13.575139998999873, 9.533473015000084],
                [-13.575139998999873, 9.532917023000095],
                [-13.574584006999885, 9.532638550000058],
                [-13.574584006999885, 9.532082557000138],
                [-13.574028013999964, 9.531806946000188],
                [-13.573193549999928, 9.530694961999984],
                [-13.573193549999928, 9.530138971000099],
                [-13.57263755899993, 9.529861450000112],
                [-13.57263755899993, 9.529305459000057],
                [-13.572084427999926, 9.529028894000021],
                [-13.572084427999926, 9.528472900000168],
                [-13.571249960999978, 9.528195382000149],
                [-13.571249960999978, 9.527638436000075],
                [-13.570139883999957, 9.526806831999977],
                [-13.570139883999957, 9.526249886000073],
                [-13.569583892999958, 9.526249886000073],
                [-13.569583892999958, 9.525693893000039],
                [-13.568749426999943, 9.52541637400003],
                [-13.568193435999945, 9.524581910000109],
                [-13.56791782299996, 9.523750306000068],
                [-13.567360878999978, 9.523750306000068],
                [-13.567360878999978, 9.523194313000147],
                [-13.566527366999935, 9.522640229000103],
                [-13.566527366999935, 9.522084236000069],
                [-13.565971373999957, 9.521805763000032],
                [-13.565971373999957, 9.52097225100016],
                [-13.565417289999914, 9.519862174000025],
                [-13.564861296999936, 9.51930618300014],
                [-13.564305305999937, 9.51930618300014],
                [-13.563195228999916, 9.518194199000163],
                [-13.563195228999916, 9.51764011500012],
                [-13.56097316699993, 9.515418053000133],
                [-13.560694693999892, 9.514862060000041],
                [-13.559861182999953, 9.514862060000041],
                [-13.559582709999972, 9.514026643000136],
                [-13.559026717999814, 9.513749123000082],
                [-13.558472632999951, 9.513193131000094],
                [-13.558472632999951, 9.51236057300008],
                [-13.557916640999963, 9.51208305400013],
                [-13.557083129999853, 9.51208305400013],
                [-13.556804656999816, 9.511804581000092],
                [-13.556804656999816, 9.510971070000153],
                [-13.556250572999886, 9.510695457000168],
                [-13.555694579999965, 9.50958347300002],
                [-13.55486011599993, 9.508749008000166],
                [-13.554306984999926, 9.50763893100003],
                [-13.554029463999939, 9.506805420000092],
                [-13.553194044999941, 9.506805420000092],
                [-13.552638053999942, 9.506251336000105],
                [-13.552638053999942, 9.505695343000184],
                [-13.552082060999965, 9.505416870000147],
                [-13.551806449999958, 9.504860879000034],
                [-13.550694465999982, 9.504583358000104],
                [-13.55013847399988, 9.50402736699999],
                [-13.550415992999945, 9.50319480800016],
                [-13.549305915999923, 9.50236129699999],
                [-13.548749923999935, 9.501804353000125],
                [-13.548193930999958, 9.501804353000125],
                [-13.548193930999958, 9.501251222000064],
                [-13.547637939999959, 9.501251222000064],
                [-13.547362326999973, 9.50069522900003],
                [-13.546806335999975, 9.50069522900003],
                [-13.546527862999937, 9.500138283000126],
                [-13.545694350999895, 9.500138283000126],
                [-13.545417785999916, 9.499582291000138],
                [-13.54430580099995, 9.499582291000138],
                [-13.543749809999952, 9.49902820599999],
                [-13.54263973299993, 9.49902820599999],
                [-13.54263973299993, 9.498472214],
                [-13.542083739999953, 9.498193740999966],
                [-13.541249274999814, 9.498193740999966],
                [-13.540417670999943, 9.498472214],
                [-13.540139197999963, 9.497916221000082],
                [-13.53874874099995, 9.497916221000082],
                [-13.53874874099995, 9.497637748000045],
                [-13.535416601999941, 9.497637748000045],
                [-13.535416601999941, 9.497360230000027],
                [-13.53180694599996, 9.497360230000027],
                [-13.531526564999865, 9.496804238000038],
                [-13.53041744299992, 9.496804238000038],
                [-13.53041744299992, 9.496527673000173],
                [-13.528472898999894, 9.496527673000173],
                [-13.527638434999858, 9.496250153000119],
                [-13.52735996199982, 9.49569416100013],
                [-13.52624988499997, 9.49569416100013],
                [-13.525693893999971, 9.495415688000094],
                [-13.52541637399986, 9.495971680000082],
                [-13.524027823999972, 9.495971680000082],
                [-13.52375030499985, 9.49513816800004],
                [-13.522915840999929, 9.494584084],
                [-13.522915840999929, 9.49430561100013],
                [-13.520416258999944, 9.49180603000002],
                [-13.520138, 9.49125],
                [-13.519583999999895, 9.490972000000113],
                [-13.519306, 9.490416],
                [-13.518472, 9.489862],
                [-13.516528, 9.487918],
                [-13.516528, 9.48736200000019],
                [-13.514583999999843, 9.485417],
                [-13.514305, 9.485417],
                [-13.514305, 9.48402700000014],
                [-13.514583999999843, 9.483195],
                [-13.51541799999984, 9.481527],
                [-13.515972, 9.48069500000014],
                [-13.516528, 9.48069500000014],
                [-13.516528, 9.478473000000122],
                [-13.516249999999843, 9.478471000000127],
                [-13.516249999999843, 9.477361000000144],
                [-13.515972, 9.477361000000144],
                [-13.515972, 9.475973],
                [-13.515696, 9.475973],
                [-13.515696, 9.474861],
                [-13.51541799999984, 9.474861],
                [-13.51541799999984, 9.473751],
                [-13.51514, 9.473751],
                [-13.51514, 9.472363000000144],
                [-13.514583999999843, 9.471806],
                [-13.514027, 9.471528000000148],
                [-13.514027, 9.469306000000131],
                [-13.513193, 9.469306000000131],
                [-13.512917, 9.46875000000017],
                [-13.512361, 9.468472],
                [-13.512083, 9.467638],
                [-13.512083, 9.466528000000153],
                [-13.511527, 9.465972],
                [-13.511527, 9.463472],
                [-13.511805, 9.463472],
                [-13.511805, 9.462362],
                [-13.510971, 9.462084000000175],
                [-13.510971, 9.460971],
                [-13.510417, 9.460971],
                [-13.510139, 9.460418000000175],
                [-13.510139, 9.458471],
                [-13.509861, 9.457639],
                [-13.509583, 9.457639],
                [-13.509583, 9.456527000000108],
                [-13.509027, 9.455973],
                [-13.509027, 9.454861000000108],
                [-13.509583, 9.454583000000184],
                [-13.509027, 9.45374900000013],
                [-13.509027, 9.452639],
                [-13.508473, 9.45208300000013],
                [-13.508193, 9.451251000000184],
                [-13.508195, 9.450139],
                [-13.507639, 9.449861000000112],
                [-13.507639, 9.448473],
                [-13.507361, 9.447917000000189],
                [-13.506804999999872, 9.447639],
                [-13.506804999999872, 9.447082],
                [-13.506251, 9.446807],
                [-13.505973, 9.446251000000188],
                [-13.505973, 9.445416],
                [-13.504861, 9.44486],
                [-13.504861, 9.443194],
                [-13.504027, 9.442916000000139],
                [-13.503472999999872, 9.442360000000122],
                [-13.503472999999872, 9.441806],
                [-13.502917, 9.441528],
                [-13.502638999999874, 9.440974],
                [-13.502638999999874, 9.43986],
                [-13.502361, 9.439306],
                [-13.50180399999988, 9.439028000000121],
                [-13.500416, 9.43764],
                [-13.499305999999876, 9.435972],
                [-13.49875, 9.435972],
                [-13.498471999999879, 9.435416],
                [-13.497637999999881, 9.434584000000143],
                [-13.496805999999879, 9.434028000000126],
                [-13.496528, 9.43347400000016],
                [-13.495971999999881, 9.43347400000016],
                [-13.495416, 9.432918000000143],
                [-13.494584, 9.43264],
                [-13.494028, 9.432083000000148],
                [-13.494028, 9.43152700000013],
                [-13.493471999999883, 9.43097100000017],
                [-13.492918, 9.430139],
                [-13.492084, 9.42986100000013],
                [-13.492084, 9.42930500000017],
                [-13.49125, 9.429027],
                [-13.49125, 9.428193],
                [-13.490971999999886, 9.427361],
                [-13.490139999999883, 9.426805],
                [-13.490139999999883, 9.426251],
                [-13.489305999999885, 9.426251],
                [-13.489028, 9.425417000000152],
                [-13.48847099999989, 9.425139],
                [-13.488193, 9.424583],
                [-13.487362, 9.424583],
                [-13.48680499999989, 9.424027],
                [-13.48680499999989, 9.423195],
                [-13.486249, 9.422917],
                [-13.486249, 9.422361],
                [-13.485417, 9.422361],
                [-13.48513899999989, 9.421529],
                [-13.484304999999836, 9.420973000000174],
                [-13.484304999999836, 9.420138000000179],
                [-13.483470999999838, 9.420138000000179],
                [-13.483195, 9.419307000000174],
                [-13.482638999999836, 9.419307000000174],
                [-13.482361, 9.418472000000179],
                [-13.481527, 9.418194],
                [-13.481527, 9.41736],
                [-13.481249, 9.416806000000179],
                [-13.480695, 9.416528],
                [-13.479861, 9.415694],
                [-13.478195, 9.41486],
                [-13.477361, 9.414306000000124],
                [-13.477361, 9.412638000000129],
                [-13.476251, 9.412638000000129],
                [-13.474029, 9.411528],
                [-13.473751, 9.410972000000129],
                [-13.472916, 9.410972000000129],
                [-13.471528, 9.410416000000112],
                [-13.470972, 9.409862],
                [-13.47125, 9.409306000000129],
                [-13.47125, 9.408472],
                [-13.470416, 9.407637],
                [-13.46985999999987, 9.407637],
                [-13.469306, 9.407084000000111],
                [-13.469306, 9.406249000000116],
                [-13.46875, 9.405693000000156],
                [-13.467638, 9.405693000000156],
                [-13.467361999999866, 9.405139000000133],
                [-13.466806, 9.405139000000133],
                [-13.466527999999869, 9.405693000000156],
                [-13.465138, 9.405693000000156],
                [-13.464861999999869, 9.404861],
                [-13.463195999999868, 9.404861],
                [-13.463195999999868, 9.405139000000133],
                [-13.459860999999876, 9.405139000000133],
                [-13.459860999999876, 9.405418000000111],
                [-13.457917, 9.405418000000111],
                [-13.457917, 9.405693000000156],
                [-13.455694999999878, 9.405693000000156],
                [-13.455139, 9.406527],
                [-13.453749, 9.406527],
                [-13.452917, 9.406806],
                [-13.452639, 9.407362],
                [-13.451251, 9.407362],
                [-13.450694999999882, 9.407637],
                [-13.450417, 9.408194],
                [-13.449305, 9.408194],
                [-13.448194999999885, 9.409028],
                [-13.447082, 9.409028],
                [-13.446807, 9.409584],
                [-13.445693999999833, 9.409584],
                [-13.445416, 9.410140000000183],
                [-13.444584, 9.410140000000183],
                [-13.444306, 9.410694],
                [-13.443194, 9.410694],
                [-13.443194, 9.410972000000129],
                [-13.442084, 9.410972000000129],
                [-13.442084, 9.41125],
                [-13.43875, 9.41125],
                [-13.43875, 9.411528],
                [-13.437916, 9.411806000000183],
                [-13.43514, 9.411806000000183],
                [-13.43514, 9.411528],
                [-13.433748999999864, 9.411528],
                [-13.433748999999864, 9.41125],
                [-13.432082999999864, 9.41125],
                [-13.431527, 9.410140000000183],
                [-13.429861, 9.410140000000183],
                [-13.429583, 9.409584],
                [-13.428195, 9.409584],
                [-13.428195, 9.409306000000129],
                [-13.427083, 9.409028],
                [-13.427361, 9.408472],
                [-13.428473, 9.408194],
                [-13.429027, 9.407362],
                [-13.429027, 9.406806],
                [-13.428195, 9.406527],
                [-13.428473, 9.405693000000156],
                [-13.430695, 9.405693000000156],
                [-13.430971, 9.406527],
                [-13.431805, 9.406527],
                [-13.432082999999864, 9.407084000000111],
                [-13.433193, 9.407084000000111],
                [-13.433748999999864, 9.407637],
                [-13.435696, 9.407637],
                [-13.435972, 9.408194],
                [-13.436806, 9.408472],
                [-13.43764, 9.408472],
                [-13.437916, 9.408194],
                [-13.437916, 9.407362],
                [-13.437362, 9.406806],
                [-13.439306, 9.406806],
                [-13.439862, 9.407084000000111],
                [-13.440694, 9.406527],
                [-13.440694, 9.404583000000116],
                [-13.440416, 9.404305],
                [-13.438194, 9.404305],
                [-13.43764, 9.403752000000111],
                [-13.436806, 9.403471000000138],
                [-13.431249, 9.403471000000138],
                [-13.431249, 9.403752000000111],
                [-13.429861, 9.403752000000111],
                [-13.429027, 9.404027000000156],
                [-13.429027, 9.404305],
                [-13.427917, 9.404861],
                [-13.427083, 9.404861],
                [-13.425695, 9.405418000000111],
                [-13.425417, 9.405971],
                [-13.424307, 9.405971],
                [-13.423751, 9.406527],
                [-13.423751, 9.408472],
                [-13.42458299999987, 9.409028],
                [-13.42458299999987, 9.409862],
                [-13.423473, 9.409862],
                [-13.423473, 9.410140000000183],
                [-13.422082999999873, 9.410140000000183],
                [-13.42125099999987, 9.41125],
                [-13.419026, 9.41125],
                [-13.418749999999875, 9.410416000000112],
                [-13.417915999999877, 9.410140000000183],
                [-13.416528, 9.410140000000183],
                [-13.416528, 9.410416000000112],
                [-13.414583999999877, 9.410416000000112],
                [-13.414583999999877, 9.410140000000183],
                [-13.4134719999999, 9.410140000000183],
                [-13.412917999999877, 9.409306000000129],
                [-13.412915999999825, 9.405418000000111],
                [-13.412638, 9.405418000000111],
                [-13.412638, 9.404027000000156],
                [-13.412362, 9.404027000000156],
                [-13.412362, 9.402917000000116],
                [-13.41208399999988, 9.402083],
                [-13.411805999999899, 9.402083],
                [-13.411805999999899, 9.400695000000155],
                [-13.412362, 9.400139000000138],
                [-13.412362, 9.39958300000012],
                [-13.41374999999988, 9.39958300000012],
                [-13.414028, 9.39902700000016],
                [-13.41541599999988, 9.39902700000016],
                [-13.41541599999988, 9.398751],
                [-13.416528, 9.398751],
                [-13.416805999999895, 9.398195],
                [-13.417637999999897, 9.398195],
                [-13.417915999999877, 9.397639],
                [-13.418471999999895, 9.397639],
                [-13.419581999999878, 9.397083],
                [-13.420137999999895, 9.396527],
                [-13.420695, 9.395417],
                [-13.420695, 9.394304000000147],
                [-13.421803999999895, 9.393473000000142],
                [-13.421803999999895, 9.391807000000142],
                [-13.422082999999873, 9.391807000000142],
                [-13.422361, 9.390972000000147],
                [-13.422361, 9.389304],
                [-13.42291699999987, 9.388750000000186],
                [-13.42291699999987, 9.387638],
                [-13.423195, 9.387638],
                [-13.423195, 9.385416],
                [-13.42291699999987, 9.385416],
                [-13.42291699999987, 9.383472],
                [-13.422639, 9.383472],
                [-13.422639, 9.38208400000019],
                [-13.422361, 9.38208400000019],
                [-13.422361, 9.380693000000178],
                [-13.422082999999873, 9.380693000000178],
                [-13.422082999999873, 9.378471],
                [-13.422361, 9.378474],
                [-13.422361, 9.377083],
                [-13.421803999999895, 9.376805],
                [-13.421803999999895, 9.374305000000106],
                [-13.421529, 9.374305000000106],
                [-13.421529, 9.372917],
                [-13.42125099999987, 9.372917],
                [-13.42125099999987, 9.370417],
                [-13.421529, 9.370417],
                [-13.421529, 9.36874900000015],
                [-13.421803999999895, 9.367917],
                [-13.422361, 9.36763900000011],
                [-13.422361, 9.366251],
                [-13.42291699999987, 9.36597300000011],
                [-13.42291699999987, 9.365416],
                [-13.423473, 9.365138000000115],
                [-13.423473, 9.36430700000011],
                [-13.424027, 9.364028000000133],
                [-13.424027, 9.362916000000155],
                [-13.424307, 9.362916000000155],
                [-13.424307, 9.361806000000115],
                [-13.425139, 9.361250000000155],
                [-13.425139, 9.36013800000012],
                [-13.425417, 9.36013800000012],
                [-13.425417, 9.354862],
                [-13.42458299999987, 9.354584000000159],
                [-13.42458299999987, 9.35347400000012],
                [-13.424307, 9.352918000000159],
                [-13.423751, 9.35264],
                [-13.423751, 9.351805],
                [-13.423195, 9.350139],
                [-13.42291699999987, 9.350139],
                [-13.42291699999987, 9.349027],
                [-13.422639, 9.349027],
                [-13.422639, 9.345139],
                [-13.422361, 9.345139],
                [-13.422361, 9.343195],
                [-13.422639, 9.343195],
                [-13.422639, 9.341529],
                [-13.42291699999987, 9.341529],
                [-13.42291699999987, 9.339860000000101],
                [-13.423195, 9.339860000000101],
                [-13.423195, 9.338194000000101],
                [-13.423473, 9.338194000000101],
                [-13.423473, 9.3365280000001],
                [-13.423751, 9.3365280000001],
                [-13.423751, 9.335416000000123],
                [-13.423473, 9.334584],
                [-13.423195, 9.334584],
                [-13.423195, 9.333472],
                [-13.422639, 9.331806],
                [-13.422082999999873, 9.330694],
                [-13.421803999999895, 9.330694],
                [-13.420973, 9.329028],
                [-13.420973, 9.32819400000011],
                [-13.420415999999875, 9.327637],
                [-13.420415999999875, 9.326806],
                [-13.41986, 9.326249000000132],
                [-13.419581999999878, 9.325418000000127],
                [-13.419581999999878, 9.324583000000132],
                [-13.419026, 9.322917000000132],
                [-13.417915999999877, 9.320695000000114],
                [-13.417637999999897, 9.319861],
                [-13.417915999999877, 9.319305],
                [-13.417637999999897, 9.318751],
                [-13.417083999999875, 9.318471000000159],
                [-13.416528, 9.317361000000176],
                [-13.416528, 9.316805000000159],
                [-13.415971999999897, 9.315695000000176],
                [-13.41541599999988, 9.315417],
                [-13.41486, 9.314305],
                [-13.41486, 9.313195],
                [-13.414028, 9.310694],
                [-13.41374999999988, 9.310694],
                [-13.41374999999988, 9.309584],
                [-13.412915999999825, 9.307084],
                [-13.412638, 9.307084],
                [-13.41208399999988, 9.305972000000168],
                [-13.411805999999899, 9.305138000000113],
                [-13.411805999999899, 9.304028],
                [-13.411528, 9.304028],
                [-13.411528, 9.302916],
                [-13.411249999999825, 9.302916],
                [-13.411249999999825, 9.29986200000019],
                [-13.410139999999899, 9.299027],
                [-13.409862, 9.298196000000189],
                [-13.40986, 9.2970830000001],
                [-13.409583999999825, 9.2970830000001],
                [-13.409583999999825, 9.295971000000122],
                [-13.409306, 9.295971000000122],
                [-13.409306, 9.293473],
                [-13.409028, 9.292639000000122],
                [-13.408472, 9.292083000000105],
                [-13.408749999999827, 9.291251],
                [-13.408749999999827, 9.289861000000144],
                [-13.409028, 9.289861000000144],
                [-13.409028, 9.286804000000132],
                [-13.409306, 9.286804000000132],
                [-13.409306, 9.284307000000126],
                [-13.40986, 9.28375],
                [-13.410139999999899, 9.28291600000017],
                [-13.410139999999899, 9.281806000000131],
                [-13.410415999999827, 9.280972],
                [-13.410972, 9.280416],
                [-13.41208399999988, 9.278194],
                [-13.411805999999899, 9.277916000000175],
                [-13.410694, 9.277916000000175],
                [-13.410139999999899, 9.278194],
                [-13.409862, 9.27875],
                [-13.405974, 9.27875],
                [-13.405139, 9.278472],
                [-13.405139, 9.278194],
                [-13.402083, 9.278194],
                [-13.402083, 9.277916000000175],
                [-13.399304999999856, 9.277916000000175],
                [-13.399304999999856, 9.27764],
                [-13.397638999999856, 9.277638],
                [-13.397638999999856, 9.277362000000153],
                [-13.396529, 9.277362000000153],
                [-13.395972999999856, 9.278194],
                [-13.395138999999858, 9.277916000000175],
                [-13.394029, 9.277362000000153],
                [-13.393194, 9.277084],
                [-13.391806, 9.277084],
                [-13.391528, 9.277638],
                [-13.386806, 9.277638],
                [-13.386806, 9.277362000000153],
                [-13.38513799999987, 9.277362000000153],
                [-13.38513799999987, 9.277638],
                [-13.384027999999887, 9.27764],
                [-13.38347199999987, 9.278194],
                [-13.38347199999987, 9.27875],
                [-13.380692999999894, 9.27875],
                [-13.37986199999989, 9.278472],
                [-13.379304999999874, 9.277916000000175],
                [-13.378194999999891, 9.277916000000175],
                [-13.378194999999891, 9.27764],
                [-13.375970999999822, 9.277638],
                [-13.375694999999894, 9.277084],
                [-13.374583, 9.277084],
                [-13.37347299999982, 9.276528],
                [-13.373194999999896, 9.275972],
                [-13.372638999999822, 9.275694000000158],
                [-13.371527, 9.275694000000158],
                [-13.370694999999898, 9.275416],
                [-13.370139, 9.274862],
                [-13.365416, 9.274862],
                [-13.365416, 9.27514],
                [-13.364306, 9.27514],
                [-13.36375, 9.274862],
                [-13.362638, 9.275694000000158],
                [-13.362362, 9.276250000000175],
                [-13.359582, 9.276250000000175],
                [-13.35875, 9.276528],
                [-13.358194, 9.277084],
                [-13.356806, 9.277084],
                [-13.356806, 9.277362000000153],
                [-13.355972, 9.277638],
                [-13.354862, 9.27764],
                [-13.354306, 9.277916000000175],
                [-13.354028, 9.278472],
                [-13.35264, 9.279028000000153],
                [-13.351805, 9.279028000000153],
                [-13.350139, 9.280694000000153],
                [-13.349582999999882, 9.280694000000153],
                [-13.349305, 9.28125000000017],
                [-13.348748999999884, 9.28125000000017],
                [-13.348473, 9.281806000000131],
                [-13.348473, 9.283194],
                [-13.348748999999884, 9.283194],
                [-13.348748999999884, 9.285417000000109],
                [-13.348473, 9.286251],
                [-13.348748999999884, 9.287361],
                [-13.348748999999884, 9.291251],
                [-13.349027, 9.291251],
                [-13.349027, 9.292361],
                [-13.349582999999882, 9.292917],
                [-13.349582999999882, 9.293749000000105],
                [-13.349861, 9.294305000000122],
                [-13.350417, 9.294583],
                [-13.350417, 9.295139],
                [-13.350971, 9.295695],
                [-13.350139, 9.29652700000014],
                [-13.349027, 9.29652700000014],
                [-13.348195, 9.296805],
                [-13.348473, 9.29764],
                [-13.348473, 9.299027],
                [-13.348195, 9.299027],
                [-13.348195, 9.300137],
                [-13.347916999999882, 9.300137],
                [-13.347916999999882, 9.30125],
                [-13.347639, 9.30125],
                [-13.347639, 9.304306000000167],
                [-13.347361, 9.304306000000167],
                [-13.347361, 9.30736],
                [-13.347639, 9.308194000000185],
                [-13.347361, 9.30875],
                [-13.347639, 9.309304000000168],
                [-13.348473, 9.309584],
                [-13.348473, 9.315973],
                [-13.348195, 9.315973],
                [-13.348195, 9.317641],
                [-13.347916999999882, 9.317639],
                [-13.347916999999882, 9.319861],
                [-13.347639, 9.319861],
                [-13.347639, 9.321249000000137],
                [-13.347082999999884, 9.321527],
                [-13.347082999999884, 9.322083],
                [-13.346248999999887, 9.322361000000114],
                [-13.345973, 9.323471000000154],
                [-13.345416999999884, 9.323471000000154],
                [-13.344861, 9.324583000000132],
                [-13.344861, 9.32514],
                [-13.344307, 9.32514],
                [-13.344307, 9.325693000000115],
                [-13.343473, 9.325971],
                [-13.343473, 9.326806],
                [-13.342639, 9.327362],
                [-13.342639, 9.327918],
                [-13.341529, 9.329028],
                [-13.341250999999886, 9.329584],
                [-13.340416999999889, 9.330416000000127],
                [-13.339859999999817, 9.330694],
                [-13.339581999999893, 9.33125],
                [-13.339026, 9.331806],
                [-13.33874999999989, 9.332362],
                [-13.337638, 9.333472],
                [-13.337081999999896, 9.334584],
                [-13.33736, 9.335416000000123],
                [-13.337915999999893, 9.3365280000001],
                [-13.33736, 9.336806],
                [-13.33736, 9.337363],
                [-13.336527999999817, 9.337085000000172],
                [-13.33514, 9.337082000000123],
                [-13.33486, 9.3365280000001],
                [-13.333749999999895, 9.337363],
                [-13.333194, 9.33736],
                [-13.332915999999898, 9.337916],
                [-13.332362, 9.337916],
                [-13.332083999999895, 9.338473],
                [-13.330972, 9.338473],
                [-13.330972, 9.338751000000173],
                [-13.32986, 9.338751000000173],
                [-13.329028, 9.339026],
                [-13.329028, 9.33930700000019],
                [-13.328194, 9.339582],
                [-13.326527, 9.339582],
                [-13.325693, 9.339860000000101],
                [-13.325139, 9.340417000000173],
                [-13.324027, 9.340417000000173],
                [-13.324027, 9.340695],
                [-13.320417, 9.340695],
                [-13.320417, 9.340417000000173],
                [-13.319305, 9.340417000000173],
                [-13.319305, 9.340695],
                [-13.315694808999922, 9.340695381000046],
                [-13.315694808999922, 9.34041690800018],
                [-13.31486129699988, 9.33958244400003],
                [-13.314304350999976, 9.33958244400003],
                [-13.314028739999912, 9.339026451000166],
                [-13.31291580099986, 9.338193894000085],
                [-13.3120822919999, 9.337360382000043],
                [-13.311529158999974, 9.337363243000027],
                [-13.310972214999879, 9.336806297000123],
                [-13.310693741999955, 9.336250305000135],
                [-13.310137747999931, 9.336250305000135],
                [-13.309304236999822, 9.335140228000057],
                [-13.308750152999892, 9.335140228000057],
                [-13.307638168999972, 9.334028244000024],
                [-13.307638168999972, 9.333749770999987],
                [-13.308471679999968, 9.333193779],
                [-13.308750152999892, 9.33208370200009],
                [-13.307915686999934, 9.331806182000037],
                [-13.307638168999972, 9.330971718000114],
                [-13.308750152999892, 9.330694200000039],
                [-13.309027670999853, 9.330140114000017],
                [-13.310137747999931, 9.329859733000035],
                [-13.310416220999969, 9.329305650000094],
                [-13.311529158999974, 9.329028129000108],
                [-13.311806678999915, 9.328472138000052],
                [-13.31291580099986, 9.328193665000185],
                [-13.313195228999973, 9.327636719000111],
                [-13.313751219999972, 9.327636719000111],
                [-13.31541728999997, 9.326806068000053],
                [-13.316527366999878, 9.326806068000053],
                [-13.316527366999878, 9.326527595000186],
                [-13.318472860999918, 9.326527595000186],
                [-13.318472860999918, 9.32624912200015],
                [-13.31958293799994, 9.325693131000037],
                [-13.32013893199985, 9.325693131000037],
                [-13.320139, 9.32514],
                [-13.320695, 9.32514],
                [-13.320695, 9.324583000000132],
                [-13.321527, 9.324305],
                [-13.321527, 9.323749],
                [-13.322361, 9.322917000000132],
                [-13.322916999999848, 9.322917000000132],
                [-13.322916999999848, 9.322361000000114],
                [-13.323749, 9.322083],
                [-13.323749, 9.321527],
                [-13.324305, 9.321527],
                [-13.324861, 9.320417],
                [-13.325139, 9.319583000000137],
                [-13.325139, 9.317917000000136],
                [-13.325418, 9.317917000000136],
                [-13.325693, 9.317083],
                [-13.325693, 9.312082],
                [-13.325418, 9.312082],
                [-13.325418, 9.310972000000163],
                [-13.325139, 9.310972000000163],
                [-13.325139, 9.30875],
                [-13.324582999999848, 9.30875],
                [-13.324582999999848, 9.308194000000185],
                [-13.324027, 9.307084],
                [-13.323749, 9.307084],
                [-13.323749, 9.30319400000019],
                [-13.323471, 9.302362],
                [-13.322916999999848, 9.302084],
                [-13.323195, 9.30152800000019],
                [-13.323195, 9.298471],
                [-13.323471, 9.298471],
                [-13.323471, 9.295971000000122],
                [-13.323749, 9.295139],
                [-13.323195, 9.294583],
                [-13.323195, 9.291251],
                [-13.323749, 9.290695],
                [-13.324027, 9.289861000000144],
                [-13.324027, 9.286804000000132],
                [-13.323749, 9.286804000000132],
                [-13.323749, 9.285695],
                [-13.324027, 9.285695],
                [-13.324027, 9.28375],
                [-13.324305, 9.28291600000017],
                [-13.324582999999848, 9.28291600000017],
                [-13.324582999999848, 9.281528],
                [-13.324861, 9.281528],
                [-13.324861, 9.280416],
                [-13.325418, 9.279028000000153],
                [-13.325418, 9.278472],
                [-13.324861, 9.278472],
                [-13.324305, 9.277916000000175],
                [-13.323471, 9.277916000000175],
                [-13.323195, 9.276806],
                [-13.322639, 9.276528],
                [-13.322639, 9.274584000000175],
                [-13.322361, 9.274028000000158],
                [-13.321527, 9.27375],
                [-13.321527, 9.272918000000175],
                [-13.320693, 9.27264],
                [-13.320695, 9.270139],
                [-13.320417, 9.269305],
                [-13.320139, 9.269305],
                [-13.320139, 9.268193],
                [-13.319861, 9.267361000000108],
                [-13.319583, 9.267361000000108],
                [-13.319583, 9.266249],
                [-13.319305, 9.266249],
                [-13.319305, 9.265139],
                [-13.319027, 9.265139],
                [-13.319027, 9.264027000000112],
                [-13.318195, 9.262361000000112],
                [-13.317639, 9.261805],
                [-13.317083, 9.261529],
                [-13.316527, 9.260973],
                [-13.316251, 9.260417000000189],
                [-13.315695, 9.260139],
                [-13.315695, 9.259582],
                [-13.314861, 9.258473],
                [-13.31430399999988, 9.258195],
                [-13.31430399999988, 9.2576380000001],
                [-13.313751, 9.25736],
                [-13.313751, 9.256807],
                [-13.312916, 9.255694],
                [-13.31236, 9.25514],
                [-13.311529, 9.254028],
                [-13.310416, 9.253474],
                [-13.310137999999881, 9.253194000000121],
                [-13.310137999999881, 9.251806],
                [-13.30986, 9.251806],
                [-13.30986, 9.249860000000126],
                [-13.310137999999881, 9.249860000000126],
                [-13.310137999999881, 9.248472],
                [-13.309584, 9.247362],
                [-13.309303999999884, 9.247362],
                [-13.309028, 9.246528000000126],
                [-13.309028, 9.245418000000143],
                [-13.30875, 9.244584],
                [-13.308471999999881, 9.244584],
                [-13.308471999999881, 9.24347100000017],
                [-13.308194, 9.24347100000017],
                [-13.308194, 9.240693],
                [-13.30875, 9.240417],
                [-13.308471999999881, 9.239583000000152],
                [-13.308471999999881, 9.238471000000175],
                [-13.307916, 9.237917000000152],
                [-13.307916, 9.234583000000157],
                [-13.30875, 9.232917000000157],
                [-13.308471999999881, 9.232082],
                [-13.308194, 9.232082],
                [-13.308194, 9.230973],
                [-13.307916, 9.230973],
                [-13.307916, 9.22736],
                [-13.307637999999884, 9.22736],
                [-13.307637999999884, 9.226250000000107],
                [-13.30736, 9.226250000000107],
                [-13.30736, 9.222916000000112],
                [-13.307916, 9.221528],
                [-13.308471999999881, 9.221250000000111],
                [-13.308471999999881, 9.220694],
                [-13.309303999999884, 9.219862],
                [-13.30986, 9.219584000000111],
                [-13.310137999999881, 9.21875],
                [-13.310137999999881, 9.217362],
                [-13.310416, 9.217362],
                [-13.310416, 9.215971000000138],
                [-13.310694, 9.215971000000138],
                [-13.310694, 9.214861],
                [-13.31125, 9.213473],
                [-13.311807, 9.213473],
                [-13.312637999999879, 9.212639000000138],
                [-13.313195, 9.211251],
                [-13.312916, 9.21041700000012],
                [-13.312916, 9.208749000000125],
                [-13.313195, 9.208749000000125],
                [-13.313195, 9.207361],
                [-13.313473, 9.206805],
                [-13.314029, 9.20652900000016],
                [-13.31430399999988, 9.205973000000142],
                [-13.314029, 9.205139],
                [-13.314029, 9.203194],
                [-13.313473, 9.202638],
                [-13.313473, 9.201804000000152],
                [-13.312916, 9.200416],
                [-13.313473, 9.200138000000152],
                [-13.312916, 9.19875],
                [-13.312916, 9.197916],
                [-13.31236, 9.196806000000151],
                [-13.311807, 9.196528],
                [-13.311529, 9.195696000000169],
                [-13.30986, 9.195696000000169],
                [-13.309303999999884, 9.195972],
                [-13.309028, 9.196528],
                [-13.308471999999881, 9.19625],
                [-13.308471999999881, 9.195140000000151],
                [-13.308194, 9.194306],
                [-13.30875, 9.193472],
                [-13.30875, 9.19264],
                [-13.309303999999884, 9.192084],
                [-13.309028, 9.191249],
                [-13.30986, 9.190696000000173],
                [-13.30986, 9.189861000000178],
                [-13.310137999999881, 9.189027000000124],
                [-13.31125, 9.188471000000106],
                [-13.31125, 9.187917],
                [-13.311807, 9.187639],
                [-13.312637999999879, 9.187639],
                [-13.312637999999879, 9.187083],
                [-13.313751, 9.187083],
                [-13.314861, 9.186249],
                [-13.315139, 9.185417],
                [-13.316527, 9.185417],
                [-13.317361, 9.185139000000106],
                [-13.318195, 9.186249],
                [-13.319027, 9.186249],
                [-13.319305, 9.185417],
                [-13.320417, 9.185973],
                [-13.320417, 9.185417],
                [-13.320973, 9.185139000000106],
                [-13.320973, 9.184027000000128],
                [-13.320693, 9.184027000000128],
                [-13.320693, 9.182361000000128],
                [-13.320417, 9.182361000000128],
                [-13.320417, 9.181249],
                [-13.320139, 9.181249],
                [-13.320139, 9.178195],
                [-13.320695, 9.17736],
                [-13.322083, 9.17736],
                [-13.322361, 9.176529],
                [-13.322361, 9.175138],
                [-13.322639, 9.175138],
                [-13.322639, 9.173472],
                [-13.322916999999848, 9.173472],
                [-13.322916999999848, 9.171806],
                [-13.322639, 9.17097200000012],
                [-13.322083, 9.17041600000016],
                [-13.322083, 9.16875000000016],
                [-13.321807, 9.167916],
                [-13.321249, 9.167362],
                [-13.320417, 9.164862000000142],
                [-13.320417, 9.163749],
                [-13.320139, 9.163749],
                [-13.320139, 9.162361000000146],
                [-13.319583, 9.160973],
                [-13.319027, 9.160693],
                [-13.318751, 9.160139000000186],
                [-13.319027, 9.159583000000168],
                [-13.319027, 9.158749],
                [-13.317639, 9.157361],
                [-13.317639, 9.156527],
                [-13.317083, 9.156251000000168],
                [-13.316527, 9.155695],
                [-13.316251, 9.15513900000019],
                [-13.313751, 9.15513900000019],
                [-13.313751, 9.154583000000173],
                [-13.313195, 9.153751],
                [-13.31236, 9.15347300000019],
                [-13.31125, 9.15347300000019],
                [-13.31125, 9.152917000000173],
                [-13.30986, 9.152917000000173],
                [-13.308471999999881, 9.154305],
                [-13.308471999999881, 9.155695],
                [-13.30875, 9.155695],
                [-13.30875, 9.157361],
                [-13.305971999999883, 9.157361],
                [-13.305971999999883, 9.157083],
                [-13.304305999999883, 9.157083],
                [-13.30375, 9.157361],
                [-13.303471999999886, 9.157917000000168],
                [-13.300971999999888, 9.157917000000168],
                [-13.300418, 9.157083],
                [-13.297918, 9.157083],
                [-13.297083, 9.15680500000019],
                [-13.296527, 9.155973],
                [-13.294304999999895, 9.155973],
                [-13.293472999999892, 9.155695],
                [-13.293472999999892, 9.155417],
                [-13.28847299999984, 9.155417],
                [-13.28847299999984, 9.155695],
                [-13.287638999999842, 9.155973],
                [-13.285972999999842, 9.15680500000019],
                [-13.285138, 9.15680500000019],
                [-13.28486, 9.155973],
                [-13.28236, 9.155973],
                [-13.28236, 9.156251000000168],
                [-13.28125, 9.156251000000168],
                [-13.280416, 9.156527],
                [-13.280416, 9.15680500000019],
                [-13.279306, 9.15680500000019],
                [-13.279028, 9.156251000000168],
                [-13.278472, 9.155973],
                [-13.275696, 9.155973],
                [-13.27514, 9.156251000000168],
                [-13.274861999999871, 9.15680500000019],
                [-13.274306, 9.157083],
                [-13.274306, 9.157639],
                [-13.273471, 9.157917000000168],
                [-13.273192999999878, 9.15847100000019],
                [-13.273192999999878, 9.159305],
                [-13.27264, 9.159583000000168],
                [-13.27264, 9.160139000000186],
                [-13.271805, 9.160693],
                [-13.271805, 9.161805000000186],
                [-13.271249, 9.162083],
                [-13.270971, 9.162639],
                [-13.270415, 9.162918],
                [-13.269583, 9.162918],
                [-13.268473, 9.163471000000186],
                [-13.267917, 9.164027000000146],
                [-13.267083, 9.164306000000124],
                [-13.265417, 9.16514],
                [-13.264583, 9.16514],
                [-13.263473, 9.165694],
                [-13.262917, 9.16625],
                [-13.262083, 9.166528000000142],
                [-13.25986, 9.16764000000012],
                [-13.259028999999884, 9.16764000000012],
                [-13.258539199999973, 9.16818905000008],
                [-13.257916450999971, 9.168193818000134],
                [-13.257637977999934, 9.166806220000069],
                [-13.257084846999874, 9.165694238000071],
                [-13.255694388999927, 9.165694238000071],
                [-13.25541591599989, 9.166250229000184],
                [-13.254859924999835, 9.166250229000184],
                [-13.254584311999963, 9.166806220000069],
                [-13.253193854999893, 9.166806220000069],
                [-13.252916336999931, 9.167639732000112],
                [-13.252362250999852, 9.167639732000112],
                [-13.252083777999815, 9.168193818000134],
                [-13.25152778699993, 9.168193818000134],
                [-13.25152778699993, 9.168749809000019],
                [-13.250140188999922, 9.169584275000147],
                [-13.250140188999922, 9.170140266999965],
                [-13.249305724999886, 9.170415879000018],
                [-13.249305724999886, 9.17097187000013],
                [-13.248749733999887, 9.17097187000013],
                [-13.248472213999946, 9.171527864000097],
                [-13.247083663999888, 9.172081947000038],
                [-13.246249198999976, 9.172081947000038],
                [-13.246249198999976, 9.172637939000026],
                [-13.245693205999942, 9.172916412000063],
                [-13.2448616019999, 9.17374992300006],
                [-13.243748664999941, 9.17374992300006],
                [-13.243748664999941, 9.174028396000097],
                [-13.242638587999977, 9.174028396000097],
                [-13.242638587999977, 9.174305917000027],
                [-13.240973471999894, 9.174305917000027],
                [-13.240973471999894, 9.174028396000097],
                [-13.238751410999896, 9.174028396000097],
                [-13.238195418999965, 9.173193932000117],
                [-13.23569488499993, 9.173193932000117],
                [-13.23569488499993, 9.173472405000155],
                [-13.234582899999964, 9.173472405000155],
                [-13.23375129599998, 9.17374992300006],
                [-13.233195304999981, 9.173193932000117],
                [-13.233195304999981, 9.172637939000026],
                [-13.232638358999907, 9.172362328000077],
                [-13.232638358999907, 9.171806335000156],
                [-13.232082367999908, 9.171527864000097],
                [-13.232082367999908, 9.17097187000013],
                [-13.23152637499993, 9.170696258000078],
                [-13.23152637499993, 9.170140266999965],
                [-13.230972290999887, 9.169859886000154],
                [-13.230137824999872, 9.168749809000019],
                [-13.230137824999872, 9.168193818000134],
                [-13.229583740999942, 9.167916296999977],
                [-13.229583740999942, 9.167362214000093],
                [-13.229027747999965, 9.167083741000056],
                [-13.229027747999965, 9.165971756000147],
                [-13.229583740999942, 9.165694238000071],
                [-13.23041629799991, 9.164861679000012],
                [-13.230972290999887, 9.164861679000012],
                [-13.232082367999908, 9.164305688000127],
                [-13.23235988599987, 9.163195611000049],
                [-13.232916831999944, 9.162918091000165],
                [-13.234028816999967, 9.16291523000018],
                [-13.234582899999964, 9.16236114500009],
                [-13.23569488499993, 9.16236114500009],
                [-13.235973357999967, 9.161805153000103],
                [-13.236527443999933, 9.161526680000065],
                [-13.237639425999816, 9.161249160000182],
                [-13.237916945999928, 9.16069316900007],
                [-13.23902893099995, 9.16069316900007],
                [-13.239583014999937, 9.160416603000101],
                [-13.239860534999934, 9.159304619000125],
                [-13.240416525999933, 9.159304619000125],
                [-13.240416525999933, 9.158471107000082],
                [-13.241249084999879, 9.157917023999971],
                [-13.241526602999897, 9.156805039000062],
                [-13.240973471999894, 9.156526566000025],
                [-13.240973471999894, 9.152639389000058],
                [-13.241249084999879, 9.151806832000148],
                [-13.242638587999977, 9.150973321000038],
                [-13.242638587999977, 9.150416375000134],
                [-13.243194580999898, 9.14986038100011],
                [-13.244027137999979, 9.149581910000109],
                [-13.244027137999979, 9.148750304000032],
                [-13.245140074999938, 9.148471832999974],
                [-13.245140074999938, 9.147638322000034],
                [-13.246249198999976, 9.147084236000012],
                [-13.248472213999946, 9.147084236000012],
                [-13.249028204999945, 9.146805762999975],
                [-13.249028204999945, 9.145416260000047],
                [-13.249859810999908, 9.14513778700018],
                [-13.250415801999907, 9.145693779000169],
                [-13.250415801999907, 9.14624977200009],
                [-13.250971792999906, 9.146528245000127],
                [-13.250971792999906, 9.147084236000012],
                [-13.251806259999967, 9.147084236000012],
                [-13.252637863999894, 9.146805762999975],
                [-13.252916336999931, 9.14624977200009],
                [-13.25347232799993, 9.14624977200009],
                [-13.25347232799993, 9.145693779000169],
                [-13.254305838999926, 9.145693779000169],
                [-13.254584311999963, 9.144862175000128],
                [-13.255694388999927, 9.14458370200009],
                [-13.255694388999927, 9.144027710000103],
                [-13.256250380999859, 9.14347171899999],
                [-13.257360457999937, 9.143194199000106],
                [-13.257637977999934, 9.142640113000141],
                [-13.258193968999933, 9.142640113000141],
                [-13.258473396999932, 9.142084122000028],
                [-13.260139464999952, 9.141249658000106],
                [-13.26097297699988, 9.141249658000106],
                [-13.261804580999978, 9.14097213700012],
                [-13.262083053999902, 9.140418054],
                [-13.263195037999878, 9.139862060000041],
                [-13.263472556999943, 9.1393060690001],
                [-13.263472556999943, 9.13819313200014],
                [-13.26375103, 9.13819313200014],
                [-13.26375103, 9.136527061000038],
                [-13.263472556999943, 9.136527061000038],
                [-13.263472556999943, 9.135139466000055],
                [-13.264026641999976, 9.135139466000055],
                [-13.2643051149999, 9.13458347300002],
                [-13.264861106999888, 9.13458347300002],
                [-13.264861106999888, 9.134026527000117],
                [-13.26541709999998, 9.133473396000056],
                [-13.26569461799994, 9.132361410999977],
                [-13.266248703999963, 9.13208293800011],
                [-13.266529081999977, 9.13125133400007],
                [-13.267916679999928, 9.13125133400007],
                [-13.267916679999928, 9.12902736699999],
                [-13.268470762999982, 9.128748894000125],
                [-13.268472670999927, 9.128194809000036],
                [-13.26902675699995, 9.127638817000047],
                [-13.270695686999943, 9.126805306000108],
                [-13.271248817999947, 9.126251220000086],
                [-13.272640228999876, 9.125416756000106],
                [-13.272640228999876, 9.12486076500005],
                [-13.27319335899989, 9.124582291000081],
                [-13.273470877999955, 9.123750688000143],
                [-13.273470877999955, 9.122637748000045],
                [-13.273750305999897, 9.121804237000106],
                [-13.27458381699995, 9.120138167999983],
                [-13.275416373999974, 9.119582177000098],
                [-13.27624988499997, 9.119305612000062],
                [-13.277361869999936, 9.119305612000062],
                [-13.278471946999957, 9.118749618000038],
                [-13.278471946999957, 9.117637634000062],
                [-13.27875041999988, 9.117637634000062],
                [-13.27875041999988, 9.116527557000154],
                [-13.279582023999978, 9.115971566000042],
                [-13.280415534999918, 9.114862441000014],
                [-13.280694007999955, 9.114306450000129],
                [-13.28125, 9.114306450000129],
                [-13.282916069999942, 9.112638474000164],
                [-13.28347206099994, 9.112638474000164],
                [-13.283749578999902, 9.112084389000017],
                [-13.284306524999977, 9.112084389000017],
                [-13.284582137999962, 9.11152839700003],
                [-13.28513812899996, 9.11152839700003],
                [-13.28541564999989, 9.110971451000125],
                [-13.286528586999964, 9.110695839000073],
                [-13.286804199999949, 9.110139847000085],
                [-13.287361145999967, 9.110139847000085],
                [-13.287917136999852, 9.109305381000127],
                [-13.288473129999943, 9.109305381000127],
                [-13.289029121999931, 9.108752250000123],
                [-13.289304733999927, 9.108195306000027],
                [-13.290139198999952, 9.10791683299999],
                [-13.290139198999952, 9.105694771000174],
                [-13.290695189999951, 9.10541725100012],
                [-13.290416716999971, 9.104861260000064],
                [-13.290416716999971, 9.104026794000106],
                [-13.290139198999952, 9.103472710000062],
                [-13.288750648999894, 9.10263919800002],
                [-13.289029121999931, 9.101804734000098],
                [-13.289029121999931, 9.10069465700019],
                [-13.289304733999927, 9.10069465700019],
                [-13.289304733999927, 9.099582672999986],
                [-13.29097080199989, 9.099582672999986],
                [-13.291805266999859, 9.098195075000149],
                [-13.291805266999859, 9.097359658000187],
                [-13.29236126, 9.096250534000092],
                [-13.292917250999949, 9.095972061000055],
                [-13.292917250999949, 9.09541607000017],
                [-13.293748854999933, 9.094584466000072],
                [-13.2943048489999, 9.094584466000072],
                [-13.294583321999937, 9.09402847299998],
                [-13.294583321999937, 9.093194008000125],
                [-13.294860839999899, 9.092362404000085],
                [-13.295139312999936, 9.092362404000085],
                [-13.295139312999936, 9.09069442800012],
                [-13.295416830999898, 9.09069442800012],
                [-13.295416830999898, 9.089305878000118],
                [-13.295695303999935, 9.088749885000027],
                [-13.296526907999976, 9.088193894000142],
                [-13.296805380999956, 9.08763980800012],
                [-13.296805380999956, 9.086250305000021],
                [-13.297361373999934, 9.08569431400008],
                [-13.297918319999837, 9.08569431400008],
                [-13.298471450999955, 9.085140228000057],
                [-13.298471450999955, 9.08374881800006],
                [-13.298748969999906, 9.08374881800006],
                [-13.298748969999906, 9.082082748000062],
                [-13.298471450999955, 9.081526757000177],
                [-13.299304962999884, 9.080693245000134],
                [-13.300136566999981, 9.080693245000134],
                [-13.300136566999981, 9.081526757000177],
                [-13.305418014999873, 9.081526757000177],
                [-13.305418014999873, 9.08124923600019],
                [-13.306249618999857, 9.080416680000042],
                [-13.307359695999935, 9.08013916100009],
                [-13.309027670999853, 9.078473091000092],
                [-13.309583664999934, 9.07763862700017],
                [-13.310137747999931, 9.07763862700017],
                [-13.310693741999955, 9.076805115000127],
                [-13.311249732999954, 9.076805115000127],
                [-13.311529158999974, 9.07625103],
                [-13.3120822919999, 9.075972557000114],
                [-13.314028739999912, 9.075972557000114],
                [-13.31486129699988, 9.07569503799999],
                [-13.31486129699988, 9.075416565000126],
                [-13.315973281999959, 9.075416565000126],
                [-13.316250801999843, 9.07486057400007],
                [-13.31708335899998, 9.074028969999972],
                [-13.31736087899992, 9.074028969999972],
                [-13.31736087899992, 9.072360992000029],
                [-13.3179168699999, 9.072083474000124],
                [-13.318195342999957, 9.071525573000088],
                [-13.318751333999955, 9.070973397000046],
                [-13.318751333999955, 9.06958198700005],
                [-13.31902694699994, 9.06958198700005],
                [-13.31902694699994, 9.067359925],
                [-13.318751333999955, 9.067359925],
                [-13.318751333999955, 9.065693855],
                [-13.318472860999918, 9.065693855],
                [-13.318472860999918, 9.063750266000056],
                [-13.318195342999957, 9.063750266000056],
                [-13.318195342999957, 9.062359811000022],
                [-13.3179168699999, 9.062359811000022],
                [-13.3179168699999, 9.061249734000114],
                [-13.317639349999979, 9.060693741000023],
                [-13.316806792999841, 9.060693741000023],
                [-13.316527366999878, 9.059862137000152],
                [-13.315973281999959, 9.059583664000115],
                [-13.315973281999959, 9.059027672000127],
                [-13.31541728999997, 9.058750152000073],
                [-13.31541728999997, 9.05819416100013],
                [-13.3145847319999, 9.057640075000108],
                [-13.314028739999912, 9.057640075000108],
                [-13.313472746999935, 9.056527138000149],
                [-13.313472746999935, 9.055695534000108],
                [-13.31291580099986, 9.054582596000046],
                [-13.312359809999975, 9.054305076000162],
                [-13.312359809999975, 9.05374908500005],
                [-13.311806678999915, 9.053193092000186],
                [-13.311249732999954, 9.05291652599999],
                [-13.30986023, 9.051527023000062],
                [-13.309304236999822, 9.05013942800008],
                [-13.309304236999822, 9.049304962000178],
                [-13.308750152999892, 9.048750878000135],
                [-13.308750152999892, 9.047917367000025],
                [-13.308471679999968, 9.047361373],
                [-13.307915686999934, 9.046805382000116],
                [-13.307359695999935, 9.04680729000006],
                [-13.306805609999856, 9.046251296000094],
                [-13.306249618999857, 9.045139314000096],
                [-13.306249618999857, 9.044583321],
                [-13.305693626999926, 9.0440263750001],
                [-13.304862022999885, 9.042360306000035],
                [-13.304583549999961, 9.041250229000127],
                [-13.304027556999927, 9.04097175600009],
                [-13.303506, 9.039858],
                [-13.300681999999881, 9.0415670000001],
                [-13.291328428999918, 9.05006027200011],
                [-13.288514136999936, 9.052596093000034],
                [-13.281430244999854, 9.056521415000134],
                [-13.276390999999876, 9.06207000000012],
                [-13.267869, 9.067413],
                [-13.24758052899989, 9.075134277000075],
                [-13.236250877999964, 9.075442314000043],
                [-13.215641021999886, 9.071672440000043],
                [-13.206859, 9.072652000000119],
                [-13.179471, 9.090297],
                [-13.17417, 9.092399000000114],
                [-13.163061, 9.091551],
                [-13.151459692999936, 9.085880280000026],
                [-13.123060226999883, 9.057799340000088],
                [-13.107508657999972, 9.049626352000075],
                [-13.087618827999961, 9.048373223000056],
                [-13.069378853999979, 9.052161218000037],
                [-13.05831899999987, 9.058433],
                [-13.05085, 9.082605000000115],
                [-13.04369999999983, 9.086555],
                [-13.036081, 9.08821],
                [-13.01850891, 9.08762645700017],
                [-13.01181983899994, 9.090883255000165],
                [-13.00214958099997, 9.09737396200012],
                [-12.991608619999909, 9.112831116000109],
                [-12.980859755999973, 9.132425309000155],
                [-12.973388670999896, 9.15889453900013],
                [-12.973199843999907, 9.165101051000136],
                [-12.97023868599996, 9.171780586000068],
                [-12.956185339999877, 9.182224273000088],
                [-12.954151153999874, 9.189800263000052],
                [-12.95882987899995, 9.235273361000168],
                [-12.952439308999885, 9.249328614000035],
                [-12.943810461999874, 9.27557659200005],
                [-12.935078620999946, 9.286656379000078],
                [-12.92652130199997, 9.2864761350001],
                [-12.916541099999904, 9.282625198000062],
                [-12.900738715999921, 9.270992279999973],
                [-12.8930997839999, 9.270344734000048],
                [-12.881109236999976, 9.277993203000051],
                [-12.876358985999957, 9.296636582000133],
                [-12.87290000899992, 9.298261643000046],
                [-12.868249893999916, 9.295298576000107],
                [-12.85715103199982, 9.295821191000073],
                [-12.844599723999863, 9.286925316000065],
                [-12.840800284999887, 9.2853116980001],
                [-12.831339836999973, 9.291101457000025],
                [-12.825090408999927, 9.290900230000148],
                [-12.816509246999942, 9.285883903000126],
                [-12.812113760999921, 9.286829949000094],
                [-12.806585311999925, 9.293517113],
                [-12.794131278999942, 9.297474861000126],
                [-12.789510726999822, 9.300713540000118],
                [-12.788371085999927, 9.303936959000112],
                [-12.789569854999911, 9.312665940000159],
                [-12.787959098999977, 9.315889359000153],
                [-12.78518581399993, 9.31682872800019],
                [-12.78151989, 9.3239612590001],
                [-12.778558730999862, 9.333398819000081],
                [-12.778819083999906, 9.340063096000051],
                [-12.7769804, 9.34259986800015],
                [-12.756851196999946, 9.342228890000172],
                [-12.75337505399989, 9.345030784000187],
                [-12.750872611999966, 9.349630357000024],
                [-12.7515897749999, 9.355582238000125],
                [-12.756508827999937, 9.366234780000127],
                [-12.758170127999904, 9.37693119100004],
                [-12.757760046999977, 9.388422013000138],
                [-12.752240180999877, 9.396490098000015],
                [-12.723999023999909, 9.395232201000113],
                [-12.716798781999955, 9.390437127000041],
                [-12.712170600999912, 9.389536857000166],
                [-12.71129989699989, 9.401493072999983],
                [-12.708790778999969, 9.410928727000169],
                [-12.703489303999959, 9.415546418000076],
                [-12.698399542999937, 9.416256904000022],
                [-12.691188811999893, 9.408011436000095],
                [-12.688871383999981, 9.407332421000149],
                [-12.686799048999944, 9.409177780000164],
                [-12.686138152999888, 9.417686464000042],
                [-12.683380125999975, 9.421143531000155],
                [-12.684882163999873, 9.427975656000115],
                [-12.685466766999923, 9.430814744000031],
                [-12.679970740999863, 9.435180664000143],
                [-12.679980276999913, 9.438857078000012],
                [-12.683930396999926, 9.442979814000068],
                [-12.680259704999969, 9.450578690000157],
                [-12.67938137099992, 9.460002899000187],
                [-12.67846870399984, 9.463687897000113],
                [-12.672691344999919, 9.466926576000105],
                [-12.671319007999955, 9.469232559000034],
                [-12.672039984999856, 9.475437165000017],
                [-12.658685683999977, 9.496297837000157],
                [-12.65065193199996, 9.508853913000053],
                [-12.649289130999932, 9.516671182000152],
                [-12.651168823999853, 9.524481773000105],
                [-12.658611297999869, 9.531806946000188],
                [-12.660468101999925, 9.53202819900008],
                [-12.65748882299988, 9.539166451000085],
                [-12.648031234999962, 9.547939300000053],
                [-12.642938613999945, 9.54956817600015],
                [-12.634369849999871, 9.548224447999985],
                [-12.632769584999892, 9.55259704600013],
                [-12.63650989499996, 9.561086655999986],
                [-12.636288641999954, 9.565456391000168],
                [-12.630760192999901, 9.572142601000053],
                [-12.623769759999902, 9.593263626000123],
                [-12.621150969999974, 9.601144791000024],
                [-12.614919662999966, 9.606227876000048],
                [-12.600079535999896, 9.600767135000012],
                [-12.591738701999873, 9.599879266000016],
                [-12.590628624999965, 9.612526894000041],
                [-12.585369110999977, 9.628866197000093],
                [-12.585618972999896, 9.635302544000126],
                [-12.587968825999894, 9.642191887000081],
                [-12.59148979299988, 9.65413284300007],
                [-12.589659689999905, 9.660575868000137],
                [-12.5870885839999, 9.663116455000136],
                [-12.583189963999928, 9.663586617000135],
                [-12.578531265999914, 9.658086776000061],
                [-12.57553005199992, 9.659937858000092],
                [-12.574179648999916, 9.670059205000086],
                [-12.569569587999979, 9.677432060000058],
                [-12.567970275999869, 9.68387317700018],
                [-12.568928717999938, 9.692377090999969],
                [-12.566658973999836, 9.7027311330001],
                [-12.556698799999936, 9.70345783200014],
                [-12.547448158999885, 9.709008217000076],
                [-12.53614139699988, 9.720541955000158],
                [-12.533591269999931, 9.720092774000022],
                [-12.528921127999979, 9.709074021000049],
                [-12.52565956099994, 9.705866813000057],
                [-12.522380828999871, 9.705435753000074],
                [-12.519410132999951, 9.707265852999967],
                [-12.519201277999969, 9.714164734000121],
                [-12.528088568999976, 9.73643493700007],
                [-12.525559425999973, 9.741728783000042],
                [-12.523019789999978, 9.743350029000055],
                [-12.509111404999942, 9.742937088000133],
                [-12.507268905999979, 9.745931624999969],
                [-12.50844097099997, 9.749376297000083],
                [-12.518421173999911, 9.753940583000087],
                [-12.518660544999932, 9.756467819000079],
                [-12.512429237999925, 9.763387681000154],
                [-12.511319160999903, 9.775115968000023],
                [-12.501398085999881, 9.789174079000134],
                [-12.50030898999995, 9.808029175],
                [-12.497309684999948, 9.81033992800019],
                [-12.49127864899998, 9.81127929700017],
                [-12.490182875999892, 9.82599830700019],
                [-12.492277145999822, 9.82897853899999],
                [-12.496681212999874, 9.829883576000043],
                [-12.49946975599994, 9.832174301000123],
                [-12.50063896099988, 9.83469677],
                [-12.498870849999946, 9.839332582000054],
                [-12.499010086999931, 9.843647004000161],
                [-12.480091094999977, 9.855468750000057],
                [-12.456899642999929, 9.85464191400007],
                [-12.452510832999963, 9.857188226000062],
                [-12.443498610999939, 9.866188050000062],
                [-12.437520980999977, 9.877476692000073],
                [-12.431738853999832, 9.88163757400008],
                [-12.39373016399992, 9.887758256000097],
                [-12.371251104999942, 9.892668724000089],
                [-12.365928650999933, 9.89567565900012],
                [-12.35317897799996, 9.896183968000173],
                [-12.289219855999931, 9.908817291000162],
                [-12.287368773999958, 9.91089058],
                [-12.266040801999907, 9.915764808000063],
                [-12.227108955999938, 9.924650192000058],
                [-12.222701071999893, 9.92443370900014],
                [-12.215259552999953, 9.918710709000038],
                [-12.212929725999913, 9.914115906000063],
                [-12.205019950999883, 9.907013893000169],
                [-12.177379607999967, 9.897441865000076],
                [-12.156921386999954, 9.882096291000096],
                [-12.141818998999952, 9.874321939000083],
                [-12.12650966699988, 9.871375083000146],
                [-12.120018959999982, 9.871853828000042],
                [-12.082480430999908, 9.883447647000025],
                [-12.079480170999943, 9.885523796000086],
                [-12.061400412999944, 9.88924789400005],
                [-12.047498701999928, 9.895260810000025],
                [-12.029191016999903, 9.898755074000121],
                [-11.972249030999933, 9.916084291000118],
                [-11.937872886999969, 9.926546096000038],
                [-11.925591468999869, 9.928871155000138],
                [-11.918639182999982, 9.93256282800013],
                [-11.906579970999815, 9.935804367],
                [-11.898249625999938, 9.945707321000157],
                [-11.892669676999958, 9.978631974000052],
                [-11.891372680999837, 9.986221315000137],
                [-11.885848998999961, 9.997462272],
                [-11.828680037999959, 9.997452736000128],
                [-11.780532836999953, 9.997433663000038],
                [-11.768678663999879, 9.997429848000024],
                [-11.734808921999957, 10.00000095300004],
                [-11.7255296699999, 9.997946739000042],
                [-11.70145988399986, 9.998359681000125],
                [-11.676822661999893, 9.998779296000123],
                [-11.653370857999903, 9.99917984000001],
                [-11.614391326999964, 9.998067855000102],
                [-11.584679603999973, 9.999510765000139],
                [-11.573990820999938, 9.99957466100011],
                [-11.563579557999958, 9.99856853600005],
                [-11.531443594999871, 9.99930858600004],
                [-11.501398086999927, 10.00000095300004],
                [-11.498389243999895, 10.00000095300004],
                [-11.481100082999887, 9.999740601000155],
                [-11.446520804999977, 9.999223709000034],
                [-11.390905379999822, 9.998879433000127],
                [-11.318968772999881, 9.998436927000114],
                [-11.31393909499991, 9.998405456000057],
                [-11.288710592999905, 9.99888420100018],
                [-11.26044845599995, 9.999417306000112],
                [-11.242879867999932, 9.999749184000052],
                [-11.20674896199995, 10.000431061000086],
                [-11.205948829999954, 10.00000095300004],
                [-11.194689749999952, 9.991091728000129],
                [-11.174968718999935, 9.975811959000055],
                [-11.171260833999895, 9.966153145000021],
                [-11.167100906999963, 9.94476795200012],
                [-11.164560317999872, 9.912577629000054],
                [-11.160164831999907, 9.89052295800002],
                [-11.154370307999898, 9.87923431400003],
                [-11.142768858999943, 9.869110107000154],
                [-11.12200546199989, 9.859423638000067],
                [-11.097338675999879, 9.847919464000029],
                [-11.096179008999968, 9.845617294000135],
                [-11.069759367999893, 9.82811737100019],
                [-11.048759460999975, 9.80757141099997],
                [-11.038710592999962, 9.797739030000116],
                [-11.01255512299997, 9.75931835200015],
                [-11.003978727999936, 9.750795365000045],
                [-10.983838081999977, 9.725023270000122],
                [-10.977360725999972, 9.713975907000076],
                [-10.970890044999976, 9.69788074600018],
                [-10.96790885799993, 9.673735619000183],
                [-10.962820051999813, 9.664073944000052],
                [-10.954709051999885, 9.657856941000148],
                [-10.944060325999885, 9.656231880000064],
                [-10.93292903899993, 9.656443597000077],
                [-10.926218986999913, 9.65459346800003],
                [-10.921366691999935, 9.649993897000058],
                [-10.92090034499995, 9.641711236000162],
                [-10.91489028899997, 9.63296890200013],
                [-10.91098976099994, 9.60192871200013],
                [-10.904049872999906, 9.594332696000095],
                [-10.895481108999888, 9.593858719000082],
                [-10.878129958999978, 9.57382965100004],
                [-10.859399795999934, 9.558164597000086],
                [-10.858939171999907, 9.55264663800017],
                [-10.87007141099997, 9.543244362000053],
                [-10.873789787999954, 9.537505151000119],
                [-10.857186316999957, 9.52464580600008],
                [-10.839940070999944, 9.52200412700006],
                [-10.830961227999978, 9.520646096000064],
                [-10.827488897999899, 9.517882347000125],
                [-10.826340674999926, 9.514201164000099],
                [-10.8305206299999, 9.507312776000163],
                [-10.844429969999965, 9.50090408300008],
                [-10.84836864399989, 9.495395659999986],
                [-10.848609924999892, 9.491028786000186],
                [-10.840761184999906, 9.476532936000183],
                [-10.840769767999916, 9.471017838000023],
                [-10.84887122999993, 9.461631775000114],
                [-10.848899840999877, 9.45471477600006],
                [-10.843358993999914, 9.442751886000053],
                [-10.835500715999956, 9.438140870000097],
                [-10.82715892799996, 9.435364722000088],
                [-10.8160686499999, 9.425689697000053],
                [-10.806349754999928, 9.420153617000153],
                [-10.801959036999904, 9.414402008000025],
                [-10.804519653999932, 9.407281876000127],
                [-10.812398910999946, 9.40132045700011],
                [-10.816591262999907, 9.391906738000046],
                [-10.814740180999877, 9.386614800000075],
                [-10.809659957999884, 9.380860327999983],
                [-10.804570198999954, 9.380850793000036],
                [-10.799229621999928, 9.39072227500003],
                [-10.78973960899998, 9.39001274200018],
                [-10.780019760999892, 9.383325576000061],
                [-10.770071029999883, 9.381695747000094],
                [-10.760108947999981, 9.386042596000152],
                [-10.748310089999904, 9.384866716000033],
                [-10.740921020999963, 9.37542629300009],
                [-10.738370894999889, 9.374043464000124],
                [-10.730978965999896, 9.380189897000037],
                [-10.72498988999996, 9.371672630000091],
                [-10.71111011499994, 9.348468780000076],
                [-10.710209846999874, 9.337894440000184],
                [-10.711600302999841, 9.334679604000087],
                [-10.707449912999891, 9.330074311000033],
                [-10.689410209999949, 9.324515343000144],
                [-10.66885280699995, 9.309306145000107],
                [-10.66331005, 9.300550460000125],
                [-10.661481856999899, 9.289972306000095],
                [-10.67101955399994, 9.265866280000068],
                [-10.675219534999883, 9.250248910000153],
                [-10.67455005599993, 9.239448547000109],
                [-10.663469314999872, 9.226551057000052],
                [-10.66439914599988, 9.226552963000017],
                [-10.663740157999882, 9.210006714000087],
                [-10.66792011299998, 9.203353883000034],
                [-10.679031371999884, 9.198324204000187],
                [-10.68272972199992, 9.1985626230001],
                [-10.695228575999977, 9.193998337000096],
                [-10.708420753999917, 9.191961289000119],
                [-10.71304035199995, 9.198406219000162],
                [-10.718359946999954, 9.197499276000144],
                [-10.723467825999876, 9.183528901000045],
                [-10.72206306499993, 9.180413246000057],
                [-10.714268684999979, 9.16347789700012],
                [-10.714539527999932, 9.143947602000026],
                [-10.72612857799993, 9.131108285000039],
                [-10.726159094999957, 9.11663055400004],
                [-10.73014068599997, 9.09136390700013],
                [-10.733390808999957, 9.084248544000047],
                [-10.72996425599996, 9.079233169000076],
                [-10.708198547999928, 9.075457572000062],
                [-10.675120352999954, 9.081352234000178],
                [-10.660319326999911, 9.08154773800004],
                [-10.633068084999877, 9.069524764000164],
                [-10.622658729999955, 9.067659379000133],
                [-10.621971129999906, 9.069496155000024],
                [-10.61295890799994, 9.066715240000065],
                [-10.59401893599994, 9.054945946000089],
                [-10.584836004999886, 9.04324531500015],
                [-10.583589553999957, 9.036373138000044],
                [-10.58544063599993, 9.031568528000093],
                [-10.58619022399995, 9.016175270000076],
                [-10.587590216999956, 9.012275696000188],
                [-10.591760634999957, 9.009301185000027],
                [-10.59432029699991, 9.003336907000175],
                [-10.598349570999972, 8.973712922],
                [-10.597230910999883, 8.963368417000027],
                [-10.598170279999977, 8.959006310000177],
                [-10.594999313999892, 8.937854767000033],
                [-10.592020988999934, 8.932562829000062],
                [-10.593649862999882, 8.926363946000095],
                [-10.593021392999901, 8.908671379],
                [-10.596091269999931, 8.88961029100011],
                [-10.593290328999956, 8.87470722300003],
                [-10.586778640999967, 8.839953422000121],
                [-10.587731360999896, 8.834673882000118],
                [-10.583379744999945, 8.821564675000161],
                [-10.585240363999958, 8.817894936000187],
                [-10.586898803999873, 8.804346085000134],
                [-10.583451269999898, 8.801576616000034],
                [-10.57997989699993, 8.80179500600019],
                [-10.576298712999971, 8.798105241000144],
                [-10.57052135399988, 8.801530839000065],
                [-10.567049978999876, 8.801058769000122],
                [-10.562689781999893, 8.793692589000102],
                [-10.535751342999902, 8.772460937000176],
                [-10.527488707999964, 8.757040024000105],
                [-10.52546978099997, 8.74095153800016],
                [-10.523179053999968, 8.737496376000081],
                [-10.511401175999879, 8.738829613000178],
                [-10.510258674999875, 8.735381127000153],
                [-10.51764774399993, 8.729904175000115],
                [-10.506870268999933, 8.715381622000109],
                [-10.51035022699989, 8.709424020000142],
                [-10.493989944999896, 8.702010154999982],
                [-10.484571456999902, 8.689339639000025],
                [-10.482761381999978, 8.68014431100005],
                [-10.475139616999968, 8.68080520699999],
                [-10.469388962999972, 8.674578667000162],
                [-10.478678702999957, 8.659684182000092],
                [-10.481710434999968, 8.652345658000058],
                [-10.47850131999985, 8.646363258000179],
                [-10.479208945999972, 8.640401841000141],
                [-10.481760977999897, 8.637874603000114],
                [-10.498399734999907, 8.631278991000045],
                [-10.50212192399988, 8.624630929000148],
                [-10.515761375999887, 8.618021965000082],
                [-10.522939681999901, 8.610239030000116],
                [-10.52616882299992, 8.60978984899998],
                [-10.53100967499995, 8.613023758000168],
                [-10.544170379999969, 8.61169529000017],
                [-10.551569938999933, 8.605291366000017],
                [-10.559431076999942, 8.601872445000026],
                [-10.56637954799993, 8.595694543000093],
                [-10.575830459999963, 8.597107887000163],
                [-10.57947063399996, 8.595765114000187],
                [-10.582308768999951, 8.591387749000091],
                [-10.581211089999897, 8.575076102000025],
                [-10.583069801999955, 8.571637154000086],
                [-10.59022903499988, 8.56936741000004],
                [-10.594860076999908, 8.56455993700007],
                [-10.602298735999966, 8.549656868000056],
                [-10.607849120999958, 8.545998574000066],
                [-10.618109703999892, 8.543325424000045],
                [-10.622389792999911, 8.541684150000151],
                [-10.62403964999993, 8.532044411000015],
                [-10.631118773999958, 8.512278557000116],
                [-10.63289928499995, 8.504283905000136],
                [-10.629468918999976, 8.494627],
                [-10.63062858599983, 8.490724564000061],
                [-10.638230323999949, 8.49373626800002],
                [-10.639619826999933, 8.49259185700015],
                [-10.639880179999977, 8.487055778000126],
                [-10.63941955499996, 8.483174323000014],
                [-10.642430305999937, 8.479740142000026],
                [-10.647741317999873, 8.478379249000113],
                [-10.649588583999957, 8.4763193120001],
                [-10.65032958899991, 8.461393357000134],
                [-10.651960372999838, 8.455888747000131],
                [-10.651761055999941, 8.445092201000023],
                [-10.65713119499992, 8.42421341000005],
                [-10.65347099399986, 8.416160583000021],
                [-10.652139663999947, 8.400312423],
                [-10.652859687999978, 8.389749528000095],
                [-10.653100966999887, 8.387915612000086],
                [-10.65655899099994, 8.38517189099997],
                [-10.66438102699982, 8.382220268000083],
                [-10.66927909899988, 8.375106812000013],
                [-10.675279617999877, 8.37099075399999],
                [-10.691909789999897, 8.363927841000134],
                [-10.696069716999943, 8.359575272000143],
                [-10.699787138999852, 8.34741783200019],
                [-10.701910972999883, 8.33295726900019],
                [-10.716290474999937, 8.304757118000055],
                [-10.712199210999927, 8.282470703000172],
                [-10.717048644999977, 8.278122902000064],
                [-10.718920707999871, 8.271010398999977],
                [-10.714079855999898, 8.270538330000079],
                [-10.70069885199996, 8.273251534000053],
                [-10.700179099999957, 8.29345703100006],
                [-10.697620391999976, 8.30332279300012],
                [-10.69137859299991, 8.30789470700006],
                [-10.687878608999881, 8.322580338000023],
                [-10.673990249999974, 8.340906143999973],
                [-10.656680106999886, 8.340904236000029],
                [-10.633279799999968, 8.334553719000098],
                [-10.59710121199987, 8.332259179000175],
                [-10.5864591589999, 8.325986863000026],
                [-10.582341192999877, 8.311438561000159],
                [-10.579581260999873, 8.307986259000131],
                [-10.567600249999941, 8.307254792000094],
                [-10.56091880699995, 8.305163384000082],
                [-10.553540228999964, 8.307664871000043],
                [-10.521180151999886, 8.330966951000164],
                [-10.512168884999937, 8.335983275999979],
                [-10.490429878999976, 8.355422020000049],
                [-10.484160422999935, 8.36756801600012],
                [-10.471441268999854, 8.376702309000166],
                [-10.464710235999974, 8.38838958700012],
                [-10.452690124999947, 8.395229340000185],
                [-10.445750236999913, 8.403470993000042],
                [-10.431339264999963, 8.42982196800017],
                [-10.425559043999954, 8.436000825000065],
                [-10.417240141999912, 8.440559387000121],
                [-10.40842914599989, 8.450167656000076],
                [-10.401451111999961, 8.465524674000108],
                [-10.39379978099987, 8.476058960000046],
                [-10.389598845999899, 8.487752915000158],
                [-10.385971068999936, 8.492883683000116],
                [-10.372698783999965, 8.487055778000126],
                [-10.363990783999952, 8.488141061000078],
                [-10.3534002299999, 8.491639137000163],
                [-10.346890449999933, 8.496069908000038],
                [-10.335570334999943, 8.500842094000177],
                [-10.331410406999908, 8.502890587000138],
                [-10.32172870599993, 8.498944282000025],
                [-10.301959036999904, 8.488678931000038],
                [-10.286860466999883, 8.48479938500003],
                [-10.278179167999951, 8.490479470000025],
                [-10.269399643999975, 8.491586685000073],
                [-10.262270926999975, 8.487650871000028],
                [-10.251871109999968, 8.491276741000036],
                [-10.245190619999903, 8.49147510500012],
                [-10.241510391999952, 8.487321855000175],
                [-10.232010841999909, 8.49761486000017],
                [-10.229480742999954, 8.496683121000046],
                [-10.229028701999937, 8.49254608200016],
                [-10.231140135999908, 8.48497486000008],
                [-10.229539870999929, 8.481755257000032],
                [-10.218110084999978, 8.48091983800009],
                [-10.212578773999894, 8.480468749000181],
                [-10.2032003399999, 8.49242115100003],
                [-10.19974040999989, 8.493092538000099],
                [-10.193248747999974, 8.499492644999975],
                [-10.171038626999916, 8.512704850000091],
                [-10.163640975999954, 8.514734269000087],
                [-10.161291122999955, 8.523678779000079],
                [-10.158049583999968, 8.52641868700016],
                [-10.146088600999917, 8.51923942600007],
                [-10.142378806999943, 8.522435188000145],
                [-10.138231276999875, 8.521953583000027],
                [-10.125529289999974, 8.52533626600001],
                [-10.120699882999872, 8.522784234000085],
                [-10.108939169999871, 8.521115304000034],
                [-10.10065937, 8.515331268000182],
                [-10.09373092699991, 8.518050195000171],
                [-10.086809157999937, 8.518015861000151],
                [-10.080828666999878, 8.514537811000139],
                [-10.078330992999895, 8.50740432800012],
                [-10.065419197999972, 8.505956651000133],
                [-10.05486011599993, 8.495794297000089],
                [-10.0525703429999, 8.493254661000094],
                [-10.051899909999975, 8.489577293000082],
                [-10.056639671999903, 8.465719223000121],
                [-10.06268882799992, 8.455416680000098],
                [-10.064190864999944, 8.447115899000096],
                [-10.06744098799993, 8.429261207000025],
                [-10.066780089999952, 8.423516273000018],
                [-10.062641142999894, 8.421197892000123],
                [-10.055258751999872, 8.420928956000125],
                [-10.037679672999957, 8.430479049000041],
                [-10.028451918999906, 8.431346894],
                [-10.023138998999968, 8.433614730999977],
                [-10.016400335999833, 8.441844941000056],
                [-10.012940406999974, 8.44205188900014],
                [-10.006729125999868, 8.43949317900018],
                [-9.99865722699991, 8.439449310000043],
                [-9.994957923999891, 8.441266060000032],
                [-9.988003730999935, 8.44742584300019],
                [-9.973604202999923, 8.465257644000076],
                [-9.96459484099995, 8.46796131100001],
                [-9.945593832999975, 8.483470917000034],
                [-9.940282821999915, 8.484359741000048],
                [-9.93447399199988, 8.491673468999977],
                [-9.919443129999934, 8.498016359000076],
                [-9.896132467999962, 8.500173570000129],
                [-9.888779640999928, 8.495535850000067],
                [-9.885087012999918, 8.495515824000051],
                [-9.875623701999928, 8.496607780000147],
                [-9.867535590999978, 8.500003814000081],
                [-9.858990669999969, 8.500638962000096],
                [-9.846845625999947, 8.487472535000052],
                [-9.844994543999974, 8.4881515510001],
                [-9.83986473099992, 8.49753475099999],
                [-9.837298392999969, 8.49958229200007],
                [-9.832690239999977, 8.501396180000029],
                [-9.804307937999965, 8.502596856000082],
                [-9.795275688999936, 8.508511543000168],
                [-9.7940969469999, 8.51240921100009],
                [-9.795690535999881, 8.515864373000113],
                [-9.79983425099988, 8.517498017000094],
                [-9.80002307899997, 8.524157524000032],
                [-9.803712844999893, 8.52418327300012],
                [-9.804391860999942, 8.526254654000184],
                [-9.79995727599993, 8.534494401000075],
                [-9.797410964999926, 8.536085130000117],
                [-9.794198035999955, 8.53307819400004],
                [-9.791431426999907, 8.533292772000038],
                [-9.781908987999941, 8.54287624300008],
                [-9.779047965999894, 8.547979355000109],
                [-9.772960663999982, 8.551790238000024],
                [-9.75935745199996, 8.549418450000076],
                [-9.753603933999898, 8.546167375000095],
                [-9.745841025999937, 8.530965805000164],
                [-9.735288620999881, 8.519878388000166],
                [-9.73484420799997, 8.516431808999982],
                [-9.7415933609999, 8.506594658000097],
                [-9.745122908999917, 8.493984222000108],
                [-9.742857932999925, 8.486850737999987],
                [-9.723140716999922, 8.463999748000049],
                [-9.720005034999929, 8.447674752000069],
                [-9.717484473999889, 8.444444656000144],
                [-9.712640761999978, 8.444417954000073],
                [-9.709149360999902, 8.449447633000148],
                [-9.707166670999925, 8.47401046900012],
                [-9.704588889999968, 8.480883598000105],
                [-9.691837309999869, 8.491600038000058],
                [-9.691842079999901, 8.490681648000077],
                [-9.671057700999881, 8.493541717000028],
                [-9.660473822999961, 8.488653183],
                [-9.65245437699997, 8.47895813100007],
                [-9.633611678999955, 8.465980530000024],
                [-9.628116608999903, 8.458596229000023],
                [-9.62493324199994, 8.451228141000172],
                [-9.6323556899999, 8.444383621000156],
                [-9.635384559999977, 8.439352990000032],
                [-9.643725394999876, 8.43343162500014],
                [-9.655797003999965, 8.42064857600019],
                [-9.659312247999935, 8.41125583600018],
                [-9.657738684999913, 8.40412712200009],
                [-9.654750823999905, 8.402270317000045],
                [-9.645521163999888, 8.40290260400019],
                [-9.634188650999874, 8.407652855000038],
                [-9.62288379599994, 8.408272743000168],
                [-9.60605335299988, 8.406788826000025],
                [-9.59795665799993, 8.410640716999978],
                [-9.59006500299995, 8.418626786000061],
                [-9.584690093999939, 8.429844856999978],
                [-9.579597472999978, 8.432795525000074],
                [-9.57406711599998, 8.43161392200011],
                [-9.57019138399994, 8.424470902000053],
                [-9.56818008499988, 8.41366291100013],
                [-9.561095237999893, 8.403284072000133],
                [-9.533309935999966, 8.383351326000081],
                [-9.513528823999934, 8.382335663000163],
                [-9.486963271999969, 8.380972862000078],
                [-9.480073928999957, 8.376792908000084],
                [-9.477334022999912, 8.372182847000147],
                [-9.477853774999915, 8.363229753000098],
                [-9.48020267499993, 8.356643677000136],
                [-9.486184119999962, 8.348607063000088],
                [-9.489442825999959, 8.344497680000131],
                [-9.51559829699994, 8.329996110000025],
                [-9.520170210999936, 8.326165199000059],
                [-9.514573097999914, 8.319851875000097],
                [-9.501023292999946, 8.31103420200003],
                [-9.500157355999875, 8.30230236000017],
                [-9.5041503899999, 8.291076659000112],
                [-9.503264427999909, 8.285564422999983],
                [-9.499148367999965, 8.280023576000133],
                [-9.491110800999934, 8.275145530000088],
                [-9.488163947999908, 8.267321586000037],
                [-9.489278792999869, 8.26423358900007],
                [-9.516606330999934, 8.242700577000107],
                [-9.516017913999974, 8.237877846000117],
                [-9.516024588999926, 8.23697090100012],
                [-9.509840963999977, 8.230732919000047],
                [-9.511123656999871, 8.210077286000114],
                [-9.514164923999886, 8.203211784000132],
                [-9.519730567999943, 8.19819641200013],
                [-9.521637916999964, 8.188105584000084],
                [-9.519848822999961, 8.179371835000154],
                [-9.514802931999895, 8.175894737000078],
                [-9.504178045999936, 8.17926788300008],
                [-9.500956534999943, 8.178325653000115],
                [-9.495013236999966, 8.1709404],
                [-9.48971939099988, 8.169756889000098],
                [-9.481641769999896, 8.171537400000034],
                [-9.46944141399996, 8.169389725000087],
                [-9.463472365999905, 8.165903091000075],
                [-9.461720466999964, 8.151658059000056],
                [-9.46321678199996, 8.134450912000148],
                [-9.46232795799989, 8.129392625000037],
                [-9.457286833999945, 8.125226974999975],
                [-9.459258078999824, 8.105497361],
                [-9.45588302599998, 8.09376621299998],
                [-9.449017523999942, 8.086372376999975],
                [-9.447672843999953, 8.08062362700008],
                [-9.444929121999962, 8.077619552000101],
                [-9.444740295999964, 8.071420669000133],
                [-9.450586319999957, 8.05837822],
                [-9.458029745999966, 8.047640800000124],
                [-9.460372923999955, 8.04145717700004],
                [-9.459945678999873, 8.036176682000189],
                [-9.45674705599987, 8.03248214700011],
                [-9.453525542999955, 8.03199863400016],
                [-9.4407806399999, 8.043157577000045],
                [-9.430381775999876, 8.048137665000127],
                [-9.427618980999966, 8.047886849000179],
                [-9.427910804999954, 8.038708686000064],
                [-9.431218146999981, 8.026332856000181],
                [-9.42986869899994, 8.021736146000137],
                [-9.421607016999928, 8.017086983000127],
                [-9.421175956999946, 8.012492180000038],
                [-9.421331405999979, 8],
                [-9.411774635999961, 7.986393929000087],
                [-9.409732817999952, 7.979506017000176],
                [-9.41183376399988, 7.972631932000127],
                [-9.423148153999819, 7.965106010000056],
                [-9.432428358999914, 7.94793796499999],
                [-9.428606032999937, 7.924752236000188],
                [-9.424506187999896, 7.914181233000136],
                [-9.430327415999898, 7.898145199000055],
                [-9.423958776999939, 7.872636796000052],
                [-9.423478126999953, 7.862287999000102],
                [-9.41702842699982, 7.852180005000037],
                [-9.410915373999842, 7.846611977000123],
                [-9.406638145999977, 7.835647106000181],
                [-9.395861625999942, 7.824591160000068],
                [-9.390400886999942, 7.80919790400003],
                [-9.387189863999936, 7.806200028000148],
                [-9.382818221999969, 7.805493831999968],
                [-9.378684042999964, 7.802955151000163],
                [-9.369487761999949, 7.803679943000077],
                [-9.359363554999902, 7.796105863000093],
                [-9.35475063399997, 7.780309200000033],
                [-9.356767653999952, 7.776936054000032],
                [-9.357390402999954, 7.775104046000138],
                [-9.355326652999906, 7.772801876000074],
                [-9.34910488199995, 7.77415084900008],
                [-9.348202704999949, 7.770019055000148],
                [-9.35054778999995, 7.759702207000032],
                [-9.348961829999894, 7.753501892000145],
                [-9.34391784699983, 7.748661041999981],
                [-9.35111141099992, 7.735150814],
                [-9.351869583999871, 7.718403817000024],
                [-9.362741470999879, 7.70582723699999],
                [-9.369236945999944, 7.693922997000016],
                [-9.37066840999995, 7.68199396100016],
                [-9.368204116999948, 7.665462017999971],
                [-9.372200011999894, 7.645741939],
                [-9.369236945999944, 7.638157844000034],
                [-9.36351585499989, 7.630331040000101],
                [-9.3566198339999, 7.628008843000032],
                [-9.354558944999894, 7.625703813000087],
                [-9.357146263999937, 7.612174989000039],
                [-9.376375197999948, 7.582417012000121],
                [-9.378515242999981, 7.565443992000155],
                [-9.39938068299989, 7.528804780000144],
                [-9.399651527999936, 7.518477916000109],
                [-9.405446051999945, 7.50840187100016],
                [-9.418714523999881, 7.471727848],
                [-9.429800032999879, 7.461675167000067],
                [-9.433976173999895, 7.454116822],
                [-9.443889618999947, 7.449106217000121],
                [-9.451284407999879, 7.44201994000008],
                [-9.45637989099987, 7.433775902000093],
                [-9.465892791999977, 7.402306080000074],
                [-9.47107124299987, 7.385170937000112],
                [-9.477537154999936, 7.379456997000034],
                [-9.478701591999936, 7.375785827000186],
                [-9.477837561999934, 7.362010956000063],
                [-9.46814727799989, 7.368858815000124],
                [-9.462365149999926, 7.375951767000061],
                [-9.456547736999937, 7.391768933000151],
                [-9.446619986999963, 7.400222779000103],
                [-9.415713310999877, 7.417774202000032],
                [-9.410858153999982, 7.423494815000083],
                [-9.408329009999875, 7.423025131000088],
                [-9.405579565999972, 7.420031070000107],
                [-9.400795936999941, 7.408073902000183],
                [-9.396965026999965, 7.388548852000099],
                [-9.39397239699997, 7.388764858],
                [-9.389126777999934, 7.391958236999983],
                [-9.384297370999946, 7.391479970000148],
                [-9.380607605999955, 7.393073082000115],
                [-9.372733116999939, 7.405436994000127],
                [-9.360735891999923, 7.412733078000031],
                [-9.359778404999929, 7.42168283500007],
                [-9.350083350999967, 7.429217816000119],
                [-9.345251083999926, 7.428966999000011],
                [-9.337445257999946, 7.425262928000052],
                [-9.330085753999924, 7.424085140000159],
                [-9.324554443999943, 7.425669194000136],
                [-9.320625304999965, 7.430015088000175],
                [-9.309582709999916, 7.429050923000034],
                [-9.300178526999957, 7.422126771000023],
                [-9.295265196999935, 7.422637940000129],
                [-9.290096281999922, 7.412212849000071],
                [-9.28829574499997, 7.402335166000171],
                [-9.289948462999973, 7.392701149000175],
                [-9.288586615999975, 7.388103962000059],
                [-9.274586677999821, 7.379552841000134],
                [-9.266761778999978, 7.379977226000165],
                [-9.259104729999933, 7.383443833],
                [-9.22732353299989, 7.38937807100001],
                [-9.21177768699988, 7.385179997000137],
                [-9.202098845999842, 7.380623818],
                [-9.203797340999927, 7.360659123000062],
                [-9.206121444999951, 7.355390070999988],
                [-9.206139564999944, 7.351254940000104],
                [-9.201347350999924, 7.341824055000018],
                [-9.201210021999941, 7.320703983000101],
                [-9.190242767999962, 7.303208828000038],
                [-9.18197917799995, 7.298810005000121],
                [-9.160677909999947, 7.276679993000187],
                [-9.153790474999937, 7.273437023000042],
                [-9.13400268499987, 7.274037839000016],
                [-9.120647429999906, 7.276506902000165],
                [-9.116057396999906, 7.273731232000159],
                [-9.113607406999904, 7.256711960000075],
                [-9.110642432999896, 7.252284050000185],
                [-9.104944228999955, 7.250194072000113],
                [-9.10393619599995, 7.242938996000078],
                [-9.09415244999991, 7.225819112000181],
                [-9.095643042999882, 7.222988129000157],
                [-9.096520423999834, 7.218313218000162],
                [-9.10461807299987, 7.207558156000061],
                [-9.105795860999876, 7.201136113000075],
                [-9.104451179999842, 7.193552970000155],
                [-9.092453955999815, 7.201078893000158],
                [-9.08439731599998, 7.202649116000146],
                [-9.065999983999916, 7.200960160000022],
                [-9.053302765999945, 7.210546016000023],
                [-9.040385245999914, 7.218067169000165],
                [-9.035994529999869, 7.222408772000108],
                [-9.031805990999885, 7.232721806000029],
                [-9.01680278799995, 7.243672848000188],
                [-9.003692625999918, 7.242928029000098],
                [-8.992849350999961, 7.249767781000116],
                [-8.976518630999976, 7.248313904000156],
                [-8.963851928999873, 7.251699925000082],
                [-8.958547592999935, 7.254200935000085],
                [-8.956690788999936, 7.257865906000177],
                [-8.957123756999977, 7.263608933000114],
                [-8.95318317399989, 7.269790173000104],
                [-8.948352812999929, 7.269309045000114],
                [-8.944470405999937, 7.263549805000025],
                [-8.94102382699998, 7.262615204000042],
                [-8.937789917999908, 7.265127182000072],
                [-8.937762259999943, 7.270865918000027],
                [-8.933382033999976, 7.273373128],
                [-8.937709808999955, 7.282574177000072],
                [-8.937005996999972, 7.285556794],
                [-8.928957938999929, 7.284142018000011],
                [-8.919046401999822, 7.288457870000059],
                [-8.915597915999967, 7.287981987000023],
                [-8.906464577999941, 7.272789001000092],
                [-8.90718460099987, 7.266362190000109],
                [-8.905591964999928, 7.262683869000114],
                [-8.902838707999933, 7.261063099000126],
                [-8.863510131999874, 7.258124828000121],
                [-8.848072051999907, 7.263332844999979],
                [-8.83347988099996, 7.2834668160001],
                [-8.833459853999955, 7.287600995000162],
                [-8.83824825399995, 7.296576978000019],
                [-8.837762832999942, 7.301855088000139],
                [-8.833367347999967, 7.307343007000043],
                [-8.833113670999921, 7.311933040000156],
                [-8.83768272499998, 7.318841934000147],
                [-8.837188720999961, 7.325726986000177],
                [-8.841991423999957, 7.33240699900017],
                [-8.846081732999892, 7.342755794000141],
                [-8.845830916999944, 7.347346781999988],
                [-8.83539295199995, 7.364514829000029],
                [-8.815490721999936, 7.387835979000158],
                [-8.81128597299994, 7.401360035000039],
                [-8.806677818999958, 7.402485848000026],
                [-8.796740530999898, 7.411161900000082],
                [-8.792143820999968, 7.409762859000011],
                [-8.788452147999976, 7.412040232999971],
                [-8.78247642499997, 7.410863876000065],
                [-8.779936790999898, 7.412229062000051],
                [-8.774291038999877, 7.437682152000036],
                [-8.776332854999964, 7.443429947000141],
                [-8.775389670999914, 7.44801616699999],
                [-8.77238845799991, 7.450296879000064],
                [-8.757180214999948, 7.454357148000042],
                [-8.746294021999859, 7.468764781000061],
                [-8.726860046999946, 7.489560128000051],
                [-8.715303419999941, 7.500289915999986],
                [-8.709049224999944, 7.508295059000034],
                [-8.70671653799991, 7.514249802000052],
                [-8.71239471399997, 7.529654980000146],
                [-8.710735320999959, 7.539286136000158],
                [-8.71162033, 7.546637058000158],
                [-8.719452856999965, 7.54529619200008],
                [-8.721743583999967, 7.547142982000082],
                [-8.72263908399998, 7.552197933000116],
                [-8.713739394999948, 7.583597182000062],
                [-8.71158313799998, 7.600800038000045],
                [-8.704133033999938, 7.61774778500012],
                [-8.704331397999908, 7.624173164000013],
                [-8.707270620999907, 7.634743214000082],
              ],
            ],
            [
              [
                [-13.323471, 9.181529000000182],
                [-13.322360998999955, 9.182082999000102],
                [-13.322639000999914, 9.182917],
                [-13.326248998999915, 9.182917],
                [-13.327084, 9.182361001],
                [-13.327084, 9.181529000000182],
                [-13.325693, 9.180694999000025],
                [-13.325693, 9.178473001000043],
                [-13.325418, 9.178473001000043],
                [-13.325418, 9.175972001000048],
                [-13.325139000999911, 9.175694001000124],
                [-13.324305, 9.175972001000048],
                [-13.324305, 9.179026],
                [-13.324582999999848, 9.179026],
                [-13.324582999999848, 9.180694999000025],
                [-13.323471, 9.181529000000182],
              ],
            ],
            [
              [
                [-13.43819399899985, 9.270971],
                [-13.437915998999927, 9.271249],
                [-13.437915998999927, 9.272362000000157],
                [-13.43875, 9.27375],
                [-13.440138, 9.27375],
                [-13.440138, 9.273193001000095],
                [-13.44097200099992, 9.273193001000095],
                [-13.44097200099992, 9.27375],
                [-13.44180599899994, 9.27375],
                [-13.44208399899992, 9.273193001000095],
                [-13.44264, 9.272917999000072],
                [-13.44264, 9.270971],
                [-13.443193998999902, 9.270414999000081],
                [-13.443193998999902, 9.269583],
                [-13.44236000099994, 9.269305001000077],
                [-13.439862, 9.269305001000077],
                [-13.439862, 9.269861000000162],
                [-13.440416, 9.269861000000162],
                [-13.440416, 9.270414999000081],
                [-13.439305998999941, 9.270414999000081],
                [-13.438472000999923, 9.270138998999983],
                [-13.43819399899985, 9.270971],
              ],
            ],
            [
              [
                [-13.422082999999873, 9.277084],
                [-13.422082999999873, 9.277362000000153],
                [-13.42291699999987, 9.277638],
                [-13.424307, 9.27764],
                [-13.424307, 9.277916000000175],
                [-13.427083, 9.277916000000175],
                [-13.427083, 9.278194],
                [-13.428473, 9.278194],
                [-13.428473, 9.278472],
                [-13.430139, 9.278472],
                [-13.430695, 9.278194],
                [-13.430695, 9.277084],
                [-13.431249, 9.276528],
                [-13.431249, 9.27514],
                [-13.430971, 9.274306],
                [-13.430139, 9.27375],
                [-13.429583, 9.272362000000157],
                [-13.429583, 9.271249],
                [-13.429861, 9.270415000000185],
                [-13.430695, 9.269583],
                [-13.431249, 9.269583],
                [-13.431249, 9.268749000000184],
                [-13.430416999999863, 9.267083000000184],
                [-13.430695, 9.266527],
                [-13.429861, 9.266249],
                [-13.429583, 9.266805],
                [-13.427917, 9.266805],
                [-13.427917, 9.267083000000184],
                [-13.425973, 9.267083000000184],
                [-13.424307, 9.266249],
                [-13.423751, 9.265417000000184],
                [-13.422639, 9.265139],
                [-13.422082999999873, 9.266249],
                [-13.420415999999875, 9.266249],
                [-13.420415999999875, 9.266527],
                [-13.417083999999875, 9.266527],
                [-13.416805999999895, 9.266805],
                [-13.416805999999895, 9.267639],
                [-13.416528, 9.268473],
                [-13.416805999999895, 9.269027000000108],
                [-13.41736, 9.269305],
                [-13.41736, 9.270139],
                [-13.417083999999875, 9.270696000000157],
                [-13.416249999999877, 9.270696000000157],
                [-13.41541599999988, 9.272084],
                [-13.41541599999988, 9.272918000000175],
                [-13.414583999999877, 9.27375],
                [-13.414305999999897, 9.27375],
                [-13.4134719999999, 9.274584000000175],
                [-13.413194, 9.27514],
                [-13.412638, 9.275694000000158],
                [-13.412638, 9.276806],
                [-13.4134719999999, 9.277916000000175],
                [-13.414583999999877, 9.277638],
                [-13.414583999999877, 9.277084],
                [-13.415694, 9.276250000000175],
                [-13.418749999999875, 9.276250000000175],
                [-13.418749999999875, 9.276528],
                [-13.420415999999875, 9.276528],
                [-13.422082999999873, 9.277084],
              ],
            ],
            [
              [
                [-13.444584, 9.405693000000156],
                [-13.444584, 9.405971],
                [-13.44375, 9.406527],
                [-13.444027999999832, 9.407362],
                [-13.446528999999884, 9.407362],
                [-13.446528999999884, 9.407084000000111],
                [-13.447638, 9.407084000000111],
                [-13.447917, 9.406527],
                [-13.448473, 9.406249000000116],
                [-13.450417, 9.406249000000116],
                [-13.450694999999882, 9.405418000000111],
                [-13.449583, 9.404861],
                [-13.448473, 9.404861],
                [-13.448473, 9.404583000000116],
                [-13.447082, 9.404583000000116],
                [-13.446807, 9.404861],
                [-13.446807, 9.405693000000156],
                [-13.444584, 9.405693000000156],
              ],
            ],
            [
              [
                [-13.760137999999813, 9.437640001000148],
                [-13.759584, 9.437916000000143],
                [-13.759027998999898, 9.439028001000054],
                [-13.759027998999898, 9.439584000000139],
                [-13.760137999999813, 9.439584000000139],
                [-13.760694000999877, 9.439028001000054],
                [-13.76125, 9.43875000100013],
                [-13.761527998999895, 9.438194],
                [-13.762084, 9.438194],
                [-13.762361999999882, 9.437640001000148],
                [-13.762915998999972, 9.437640001000148],
                [-13.763472, 9.437083999000151],
                [-13.763472, 9.436528],
                [-13.764584000999946, 9.435971999000117],
                [-13.764859999999885, 9.435416],
                [-13.765972000999966, 9.43514000000016],
                [-13.76625, 9.43458399900004],
                [-13.766805998999871, 9.43458399900004],
                [-13.767360000999872, 9.434028000000126],
                [-13.767916, 9.43374900100008],
                [-13.768193998999948, 9.433192999000084],
                [-13.769585000999939, 9.433192999000084],
                [-13.769585000999939, 9.432082999000045],
                [-13.76875, 9.43152700000013],
                [-13.767916, 9.43152700000013],
                [-13.767638, 9.432361001000174],
                [-13.766805998999871, 9.432918000000143],
                [-13.764584000999946, 9.432918000000143],
                [-13.764305998999873, 9.433474001000036],
                [-13.76375, 9.43374900100008],
                [-13.763194000999874, 9.434306],
                [-13.763194000999874, 9.434862001000113],
                [-13.762361999999882, 9.435416],
                [-13.761806000999968, 9.435971999000117],
                [-13.761806000999968, 9.436528],
                [-13.76125, 9.43735999900008],
                [-13.760418000999891, 9.43735999900008],
                [-13.760137999999813, 9.437640001000148],
              ],
            ],
            [
              [
                [-13.794028, 9.464584],
                [-13.794582, 9.465416000000175],
                [-13.794582, 9.467082000000175],
                [-13.794305999999892, 9.467082000000175],
                [-13.794305999999892, 9.468194000000153],
                [-13.79486, 9.468472],
                [-13.795415999999818, 9.468194000000153],
                [-13.796529, 9.468194000000153],
                [-13.796529, 9.467082000000175],
                [-13.796803999999895, 9.466806],
                [-13.799027, 9.466806],
                [-13.79958299999987, 9.466528000000153],
                [-13.800138999999888, 9.465138],
                [-13.800138999999888, 9.462084000000175],
                [-13.800417, 9.462084000000175],
                [-13.800417, 9.45958300000018],
                [-13.799863, 9.458195000000103],
                [-13.798751, 9.458195000000103],
                [-13.79791699999987, 9.458471],
                [-13.79791699999987, 9.458749],
                [-13.79625099999987, 9.458749],
                [-13.795694, 9.458195000000103],
                [-13.793749999999818, 9.458195000000103],
                [-13.792916, 9.458749],
                [-13.792916, 9.46014],
                [-13.792362, 9.460693],
                [-13.792362, 9.461528000000158],
                [-13.791528, 9.462362],
                [-13.790416, 9.462362],
                [-13.790139999999894, 9.462916],
                [-13.789584, 9.46264],
                [-13.7884719999999, 9.46264],
                [-13.788194, 9.462916],
                [-13.787362, 9.462084000000175],
                [-13.787362, 9.461806],
                [-13.785694, 9.461806],
                [-13.785694, 9.462084000000175],
                [-13.784584, 9.46264],
                [-13.784028, 9.46264],
                [-13.784028, 9.464028],
                [-13.783749, 9.464028],
                [-13.783749, 9.465972],
                [-13.783196, 9.466528000000153],
                [-13.78347099999985, 9.467638],
                [-13.784306, 9.468194000000153],
                [-13.78625, 9.468194000000153],
                [-13.786528, 9.467362],
                [-13.7884719999999, 9.466528000000153],
                [-13.789305999999897, 9.46625],
                [-13.790416, 9.465694],
                [-13.790416, 9.465416000000175],
                [-13.791805999999895, 9.465416000000175],
                [-13.792637999999897, 9.464584],
                [-13.794028, 9.464584],
              ],
            ],
            [
              [
                [-13.759861999999885, 9.505695],
                [-13.759861999999885, 9.506805],
                [-13.760418, 9.507361],
                [-13.762084, 9.507361],
                [-13.763193999999885, 9.506805],
                [-13.763472, 9.505973000000154],
                [-13.764584, 9.505973000000154],
                [-13.765972, 9.506527000000176],
                [-13.76652799999988, 9.505973000000154],
                [-13.767916, 9.505973000000154],
                [-13.768472, 9.505417],
                [-13.768472, 9.504583],
                [-13.767638, 9.504583],
                [-13.767638, 9.504027],
                [-13.76819399999988, 9.502917],
                [-13.76875, 9.502639000000158],
                [-13.769304, 9.501529000000176],
                [-13.769029, 9.500416],
                [-13.769304, 9.49986],
                [-13.76985999999988, 9.499307000000158],
                [-13.76985999999988, 9.497916],
                [-13.769304, 9.497360000000185],
                [-13.767359999999883, 9.497360000000185],
                [-13.766806, 9.496804],
                [-13.766806, 9.49625],
                [-13.765972, 9.495416],
                [-13.764859999999885, 9.495416],
                [-13.764306, 9.49486],
                [-13.764306, 9.494306000000108],
                [-13.763472, 9.493472],
                [-13.763193999999885, 9.492918],
                [-13.762084, 9.491806],
                [-13.761806, 9.49125],
                [-13.76125, 9.490972000000113],
                [-13.760418, 9.49014],
                [-13.759584, 9.489584],
                [-13.759308, 9.488472],
                [-13.758471999999813, 9.487918],
                [-13.758471999999813, 9.486527],
                [-13.758195999999884, 9.486527],
                [-13.758195999999884, 9.484861],
                [-13.758471999999813, 9.48402700000014],
                [-13.757915, 9.483471000000122],
                [-13.757361999999887, 9.48236100000014],
                [-13.757361999999887, 9.481805000000122],
                [-13.755692999999894, 9.480139000000122],
                [-13.755139, 9.479027000000144],
                [-13.754583, 9.478471000000127],
                [-13.754305, 9.477639],
                [-13.754305, 9.476805000000127],
                [-13.753749, 9.476529],
                [-13.752917, 9.474585000000104],
                [-13.751805, 9.474585000000104],
                [-13.751251, 9.474029000000144],
                [-13.750971, 9.473194000000149],
                [-13.750971, 9.471528000000148],
                [-13.750694999999894, 9.471528000000148],
                [-13.750694999999894, 9.47041600000017],
                [-13.750417, 9.469584],
                [-13.750139, 9.469584],
                [-13.750139, 9.46625],
                [-13.750417, 9.465416000000175],
                [-13.750694999999894, 9.465416000000175],
                [-13.750694999999894, 9.463196000000153],
                [-13.750971, 9.463196000000153],
                [-13.750971, 9.462084000000175],
                [-13.751526999999896, 9.461806],
                [-13.751526999999896, 9.460693],
                [-13.752083, 9.460418000000175],
                [-13.752083, 9.459305],
                [-13.752917, 9.457639],
                [-13.752639, 9.457639],
                [-13.752639, 9.456527000000108],
                [-13.751805, 9.455417],
                [-13.751805, 9.454583000000184],
                [-13.751526999999896, 9.454029],
                [-13.750694999999894, 9.453473],
                [-13.750694999999894, 9.450973],
                [-13.751526999999896, 9.45041700000013],
                [-13.751805, 9.449305],
                [-13.752639, 9.449029],
                [-13.752917, 9.448473],
                [-13.753473, 9.448473],
                [-13.753749, 9.447917000000189],
                [-13.754305, 9.447917000000189],
                [-13.754583, 9.447360000000117],
                [-13.755139, 9.447360000000117],
                [-13.755139, 9.446251000000188],
                [-13.755692999999894, 9.445972],
                [-13.755692999999894, 9.445416],
                [-13.755139, 9.4451380000001],
                [-13.755418, 9.444306],
                [-13.755418, 9.44264],
                [-13.754026999999894, 9.442638],
                [-13.753749, 9.442360000000122],
                [-13.753749, 9.440694000000121],
                [-13.752360999999894, 9.440694000000121],
                [-13.752360999999894, 9.440974],
                [-13.751251, 9.440972],
                [-13.750694999999894, 9.441528],
                [-13.750694999999894, 9.442084],
                [-13.750139, 9.442360000000122],
                [-13.750139, 9.442916000000139],
                [-13.749583, 9.44375],
                [-13.749028999999894, 9.44375],
                [-13.748749, 9.444306],
                [-13.747917, 9.444582000000139],
                [-13.747083, 9.446251000000188],
                [-13.747083, 9.447082],
                [-13.746251, 9.448748],
                [-13.745973, 9.448748],
                [-13.745417, 9.449861000000112],
                [-13.745417, 9.451805],
                [-13.745694999999841, 9.451805],
                [-13.744860999999844, 9.453473],
                [-13.744583, 9.454305],
                [-13.744583, 9.455417],
                [-13.744307, 9.456249000000184],
                [-13.743751, 9.457361],
                [-13.743194999999844, 9.457639],
                [-13.743194999999844, 9.458195000000103],
                [-13.742638, 9.458471],
                [-13.74236, 9.45958300000018],
                [-13.741528, 9.46014],
                [-13.74125, 9.460693],
                [-13.74125, 9.461806],
                [-13.74236, 9.462362],
                [-13.74236, 9.463750000000175],
                [-13.743751, 9.464306],
                [-13.743751, 9.465138],
                [-13.744583, 9.465694],
                [-13.744583, 9.466528000000153],
                [-13.744307, 9.467082000000175],
                [-13.744860999999844, 9.467362],
                [-13.744860999999844, 9.469860000000153],
                [-13.744583, 9.469860000000153],
                [-13.744583, 9.47236],
                [-13.744307, 9.473194000000149],
                [-13.744860999999844, 9.473751],
                [-13.744860999999844, 9.474585000000104],
                [-13.745417, 9.475139000000127],
                [-13.745417, 9.476529],
                [-13.745973, 9.476529],
                [-13.746251, 9.477083],
                [-13.744860999999844, 9.477083],
                [-13.744860999999844, 9.476805000000127],
                [-13.743751, 9.476805000000127],
                [-13.743194999999844, 9.475973],
                [-13.742085, 9.475973],
                [-13.74236, 9.476529],
                [-13.743194999999844, 9.477083],
                [-13.743194999999844, 9.477917000000105],
                [-13.744028999999841, 9.477917000000105],
                [-13.744583, 9.478471000000127],
                [-13.746805, 9.478473000000122],
                [-13.747083, 9.479583000000105],
                [-13.747639, 9.479861],
                [-13.748473, 9.479861],
                [-13.749028999999894, 9.480139000000122],
                [-13.749028999999894, 9.48069500000014],
                [-13.749583, 9.480973],
                [-13.750417, 9.481805000000122],
                [-13.751526999999896, 9.482639],
                [-13.751526999999896, 9.483195],
                [-13.752360999999894, 9.483471000000122],
                [-13.752917, 9.48402700000014],
                [-13.753194999999891, 9.4845830000001],
                [-13.753194999999891, 9.487084],
                [-13.753749, 9.48736200000019],
                [-13.754026999999894, 9.488193],
                [-13.754026999999894, 9.490416],
                [-13.754305, 9.490416],
                [-13.754305, 9.491806],
                [-13.755139, 9.492084],
                [-13.754583, 9.493194],
                [-13.754583, 9.495138],
                [-13.754860999999892, 9.495138],
                [-13.754860999999892, 9.496804],
                [-13.755139, 9.496804],
                [-13.755139, 9.498472000000163],
                [-13.755418, 9.498472000000163],
                [-13.755418, 9.49986],
                [-13.756249, 9.500973000000158],
                [-13.756249, 9.501804000000163],
                [-13.756526999999892, 9.502361],
                [-13.75764, 9.503473],
                [-13.75764, 9.504027],
                [-13.758471999999813, 9.504027],
                [-13.759308, 9.504861000000176],
                [-13.759306, 9.505417],
                [-13.759861999999885, 9.505695],
              ],
            ],
            [
              [
                [-13.802083, 9.513749000000132],
                [-13.802361, 9.514584000000127],
                [-13.802917, 9.514862],
                [-13.803195, 9.515418],
                [-13.803195, 9.516250000000127],
                [-13.805417, 9.516250000000127],
                [-13.805970999999886, 9.515418],
                [-13.805970999999886, 9.514584000000127],
                [-13.806804999999883, 9.513193000000172],
                [-13.807361, 9.513193000000172],
                [-13.807639, 9.51263900000015],
                [-13.809305, 9.51263900000015],
                [-13.809862, 9.511527000000171],
                [-13.81125, 9.511527000000171],
                [-13.811528, 9.510971000000154],
                [-13.812084, 9.510417000000132],
                [-13.812362, 9.509861000000171],
                [-13.813194, 9.509305000000154],
                [-13.813194, 9.508749],
                [-13.81375, 9.508195000000171],
                [-13.814583999999854, 9.507639000000154],
                [-13.814862, 9.507083],
                [-13.815972, 9.505973000000154],
                [-13.816249999999854, 9.504861000000176],
                [-13.816806, 9.503751],
                [-13.81736, 9.503195000000176],
                [-13.817083999999852, 9.502639000000158],
                [-13.817083999999852, 9.500973000000158],
                [-13.81764, 9.49986],
                [-13.818194, 9.499307000000158],
                [-13.818472, 9.49875],
                [-13.819584, 9.497638000000109],
                [-13.819584, 9.495694000000185],
                [-13.820138, 9.494584],
                [-13.820694, 9.494584],
                [-13.820694, 9.493194],
                [-13.82125, 9.492918],
                [-13.821528, 9.491806],
                [-13.822085, 9.491528],
                [-13.82236, 9.490972000000113],
                [-13.823751, 9.490972000000113],
                [-13.824029, 9.490416],
                [-13.825139, 9.490416],
                [-13.825417, 9.489862],
                [-13.825973, 9.489584],
                [-13.827083, 9.489584],
                [-13.827361, 9.48902800000019],
                [-13.827361, 9.488193],
                [-13.827639, 9.48736200000019],
                [-13.82902899999982, 9.486805000000118],
                [-13.829583, 9.4862490000001],
                [-13.830139, 9.485971],
                [-13.830416999999898, 9.485417],
                [-13.831805, 9.484861],
                [-13.832916999999895, 9.484861],
                [-13.832916999999895, 9.4845830000001],
                [-13.834026999999821, 9.4845830000001],
                [-13.834582999999895, 9.48402700000014],
                [-13.834582999999895, 9.483471000000122],
                [-13.835139, 9.48236100000014],
                [-13.835692999999821, 9.481805000000122],
                [-13.835692999999821, 9.481249000000105],
                [-13.83708399999989, 9.478471000000127],
                [-13.836526999999876, 9.477917000000105],
                [-13.83708399999989, 9.476529],
                [-13.83708399999989, 9.475695000000144],
                [-13.837914999999896, 9.474585000000104],
                [-13.837914999999896, 9.473751],
                [-13.838472, 9.472638000000131],
                [-13.839306, 9.470138],
                [-13.840693999999871, 9.46875000000017],
                [-13.840974, 9.467638],
                [-13.840418, 9.467362],
                [-13.840418, 9.466806],
                [-13.839583999999888, 9.465972],
                [-13.839027999999871, 9.465694],
                [-13.838196, 9.465694],
                [-13.838196, 9.464584],
                [-13.837914999999896, 9.464584],
                [-13.83708399999989, 9.463750000000175],
                [-13.836805, 9.462916],
                [-13.836805, 9.461528000000158],
                [-13.836526999999876, 9.461528000000158],
                [-13.836526999999876, 9.45958300000018],
                [-13.836805, 9.45958300000018],
                [-13.836805, 9.457361],
                [-13.835971, 9.455695],
                [-13.834582999999895, 9.454861000000108],
                [-13.834026999999821, 9.454029],
                [-13.833473, 9.45374900000013],
                [-13.831805, 9.45374900000013],
                [-13.831526999999824, 9.452639],
                [-13.830139, 9.451251000000184],
                [-13.830139, 9.449583000000189],
                [-13.828749, 9.449583000000189],
                [-13.828194999999823, 9.450695],
                [-13.827639, 9.450973],
                [-13.827361, 9.451805],
                [-13.827361, 9.455139],
                [-13.827639, 9.455139],
                [-13.828194999999823, 9.456805],
                [-13.828749, 9.457915000000185],
                [-13.828749, 9.458471],
                [-13.829305, 9.45958300000018],
                [-13.829583, 9.45958300000018],
                [-13.830139, 9.460693],
                [-13.830694999999821, 9.46125],
                [-13.830694999999821, 9.46264],
                [-13.831526999999824, 9.463472],
                [-13.831805, 9.464028],
                [-13.831250999999895, 9.464306],
                [-13.831250999999895, 9.465972],
                [-13.830973, 9.465972],
                [-13.830971, 9.467082000000175],
                [-13.830416999999898, 9.467638],
                [-13.830139, 9.468472],
                [-13.830139, 9.469584],
                [-13.829305, 9.470138],
                [-13.82902899999982, 9.470694],
                [-13.82902899999982, 9.47236],
                [-13.828749, 9.472363000000144],
                [-13.827361, 9.475139000000127],
                [-13.827361, 9.475695000000144],
                [-13.826805, 9.476251000000104],
                [-13.826251, 9.476251000000104],
                [-13.825695, 9.477083],
                [-13.824861, 9.477083],
                [-13.824307, 9.477361000000144],
                [-13.824307, 9.479027000000144],
                [-13.823473, 9.479027000000144],
                [-13.823473, 9.480139000000122],
                [-13.823751, 9.480139000000122],
                [-13.823751, 9.481527],
                [-13.823473, 9.481805000000122],
                [-13.820138, 9.481805000000122],
                [-13.820138, 9.482083],
                [-13.819306, 9.48236100000014],
                [-13.818472, 9.48236100000014],
                [-13.81764, 9.482639],
                [-13.817638, 9.4829170000001],
                [-13.816806, 9.483195],
                [-13.815694, 9.483751],
                [-13.815138, 9.483751],
                [-13.814028, 9.484305],
                [-13.813472, 9.485139000000117],
                [-13.813194, 9.4862490000001],
                [-13.812084, 9.487084],
                [-13.812084, 9.487637],
                [-13.811528, 9.487918],
                [-13.81125, 9.48902800000019],
                [-13.810696, 9.489306000000113],
                [-13.810415, 9.490416],
                [-13.809583, 9.49069400000019],
                [-13.808749, 9.49125],
                [-13.808749, 9.491806],
                [-13.807917, 9.492638000000113],
                [-13.807639, 9.492638000000113],
                [-13.806804999999883, 9.493472],
                [-13.806804999999883, 9.494028000000185],
                [-13.806249, 9.495138],
                [-13.805417, 9.495416],
                [-13.805417, 9.496528],
                [-13.805138999999883, 9.496528],
                [-13.805138999999883, 9.498194],
                [-13.804583, 9.498194],
                [-13.804027, 9.49875],
                [-13.804027, 9.499307000000158],
                [-13.802083, 9.501251],
                [-13.802083, 9.501804000000163],
                [-13.800972999999885, 9.501804000000163],
                [-13.800695, 9.502639000000158],
                [-13.800695, 9.504027],
                [-13.800138999999888, 9.504307000000153],
                [-13.799863, 9.504861000000176],
                [-13.799863, 9.506251],
                [-13.79958299999987, 9.506527000000176],
                [-13.798751, 9.506251],
                [-13.79736, 9.506251],
                [-13.797081999999818, 9.506527000000176],
                [-13.797081999999818, 9.507361],
                [-13.796803999999895, 9.507639000000154],
                [-13.795971999999892, 9.507361],
                [-13.795137999999895, 9.507639000000154],
                [-13.793749999999818, 9.507639000000154],
                [-13.793749999999818, 9.507917],
                [-13.792637999999897, 9.507917],
                [-13.790416, 9.508749],
                [-13.790139999999894, 9.509305000000154],
                [-13.790139999999894, 9.510417000000132],
                [-13.791805999999895, 9.511249],
                [-13.792362, 9.511251],
                [-13.792916, 9.511805],
                [-13.793749999999818, 9.511527000000171],
                [-13.795137999999895, 9.511527000000171],
                [-13.795415999999818, 9.510971000000154],
                [-13.795971999999892, 9.510695],
                [-13.796803999999895, 9.510695],
                [-13.797081999999818, 9.511527000000171],
                [-13.799027, 9.511527000000171],
                [-13.799306999999885, 9.512083000000132],
                [-13.800417, 9.512083000000132],
                [-13.800972999999885, 9.512361],
                [-13.801804999999888, 9.513193000000172],
                [-13.802083, 9.513749000000132],
              ],
            ],
            [
              [
                [-13.698470998999937, 9.732084001000146],
                [-13.698749000999953, 9.73264],
                [-13.698749000999953, 9.734306],
                [-13.698470998999937, 9.734306],
                [-13.698470998999937, 9.736250001000087],
                [-13.698749000999953, 9.736528],
                [-13.700417, 9.736528],
                [-13.700973998999928, 9.736806000000172],
                [-13.701248998999972, 9.736250001000087],
                [-13.701248998999972, 9.735416],
                [-13.702083, 9.734862001000124],
                [-13.702083, 9.73069600100007],
                [-13.700693, 9.73069600100007],
                [-13.699860998999952, 9.730415000000164],
                [-13.699583, 9.73125000000016],
                [-13.698195, 9.73125000000016],
                [-13.697917, 9.732084001000146],
                [-13.698470998999937, 9.732084001000146],
              ],
            ],
            [
              [
                [-13.65875, 9.824583],
                [-13.659306, 9.824861000000112],
                [-13.659582, 9.825695],
                [-13.660693999999864, 9.826251],
                [-13.662638, 9.826251],
                [-13.663473, 9.825973],
                [-13.663751, 9.82541700000013],
                [-13.664307, 9.82541700000013],
                [-13.664583, 9.824861000000112],
                [-13.665695, 9.824583],
                [-13.665973, 9.824029],
                [-13.666527, 9.82375100000013],
                [-13.667361, 9.82375100000013],
                [-13.669861, 9.822916000000134],
                [-13.670417, 9.822638],
                [-13.670971, 9.821807],
                [-13.670971, 9.821250000000134],
                [-13.670417, 9.820972],
                [-13.670417, 9.819862],
                [-13.670139, 9.819862],
                [-13.670139, 9.81875],
                [-13.669029, 9.817916000000139],
                [-13.668749, 9.817084],
                [-13.668749, 9.816250000000139],
                [-13.668195, 9.815416],
                [-13.667639, 9.815416],
                [-13.667083, 9.814584000000139],
                [-13.667083, 9.813194],
                [-13.666805, 9.812362000000121],
                [-13.665973, 9.81180600000016],
                [-13.665417, 9.810415],
                [-13.665417, 9.808749],
                [-13.664861, 9.808193000000188],
                [-13.664861, 9.807083],
                [-13.664307, 9.806805],
                [-13.664307, 9.804583],
                [-13.664029, 9.803749],
                [-13.664583, 9.803473],
                [-13.664861, 9.802917],
                [-13.664861, 9.80097300000017],
                [-13.665695, 9.800695],
                [-13.665695, 9.799583],
                [-13.665973, 9.799583],
                [-13.665973, 9.797082000000103],
                [-13.665139, 9.795416000000102],
                [-13.665417, 9.795138],
                [-13.665973, 9.795416000000102],
                [-13.666251, 9.796529],
                [-13.666805, 9.796804],
                [-13.666805, 9.799861],
                [-13.666527, 9.799861],
                [-13.666527, 9.801249],
                [-13.665695, 9.801529000000187],
                [-13.665695, 9.802917],
                [-13.665417, 9.802917],
                [-13.665417, 9.804027],
                [-13.665139, 9.804027],
                [-13.665139, 9.805695],
                [-13.665417, 9.805695],
                [-13.665417, 9.806805],
                [-13.665695, 9.806805],
                [-13.665695, 9.807917],
                [-13.666251, 9.80847400000016],
                [-13.666251, 9.81014000000016],
                [-13.666805, 9.811528],
                [-13.667639, 9.812084],
                [-13.668195, 9.813194],
                [-13.668195, 9.814028000000121],
                [-13.668473, 9.814862],
                [-13.669027, 9.815138000000161],
                [-13.669583, 9.816250000000139],
                [-13.669583, 9.817084],
                [-13.669861, 9.81764],
                [-13.670417, 9.818194],
                [-13.671251, 9.820138000000156],
                [-13.671805, 9.820416],
                [-13.672083, 9.821250000000134],
                [-13.672917, 9.821250000000134],
                [-13.673749, 9.821528],
                [-13.673749, 9.821807],
                [-13.676528, 9.821807],
                [-13.676528, 9.82208500000013],
                [-13.67875, 9.82208500000013],
                [-13.67875, 9.822360000000117],
                [-13.682084, 9.822360000000117],
                [-13.682084, 9.82208500000013],
                [-13.684862, 9.82208500000013],
                [-13.684862, 9.821807],
                [-13.688194, 9.821807],
                [-13.688751, 9.820972],
                [-13.689585, 9.820694000000117],
                [-13.690695, 9.820694000000117],
                [-13.691251, 9.820138000000156],
                [-13.691251, 9.819582000000139],
                [-13.692083, 9.819028000000117],
                [-13.692083, 9.818472000000156],
                [-13.692638999999872, 9.818194],
                [-13.692361, 9.817360000000122],
                [-13.692361, 9.816250000000139],
                [-13.692083, 9.816250000000139],
                [-13.692083, 9.814306],
                [-13.69180699999987, 9.814306],
                [-13.69180699999987, 9.812918000000138],
                [-13.692083, 9.812918000000138],
                [-13.692083, 9.81180600000016],
                [-13.692638999999872, 9.81014000000016],
                [-13.693751, 9.809584000000143],
                [-13.693751, 9.809305000000165],
                [-13.694861, 9.809305000000165],
                [-13.695417, 9.80847400000016],
                [-13.697638999999867, 9.80847400000016],
                [-13.697638999999867, 9.808749],
                [-13.698749, 9.808749],
                [-13.699304999999868, 9.80847400000016],
                [-13.700139, 9.807639000000165],
                [-13.700974, 9.80597100000017],
                [-13.700974, 9.80263900000017],
                [-13.700417, 9.801251],
                [-13.699861, 9.80097300000017],
                [-13.699583, 9.799861],
                [-13.699027, 9.79930700000017],
                [-13.698749, 9.798195],
                [-13.697083, 9.797361],
                [-13.69680499999987, 9.796804],
                [-13.696249, 9.796529],
                [-13.696249, 9.795694],
                [-13.69680499999987, 9.795694],
                [-13.697083, 9.796251],
                [-13.697917, 9.796251],
                [-13.698749, 9.797082000000103],
                [-13.699304999999868, 9.797361],
                [-13.699304999999868, 9.797917],
                [-13.700139, 9.798751],
                [-13.700693, 9.798751],
                [-13.701527, 9.799861],
                [-13.701527, 9.80097300000017],
                [-13.702083, 9.801529000000187],
                [-13.702083, 9.807083],
                [-13.701805, 9.807083],
                [-13.701805, 9.808193000000188],
                [-13.701527, 9.808193000000188],
                [-13.701527, 9.809584000000143],
                [-13.700974, 9.81014000000016],
                [-13.700974, 9.81264],
                [-13.701249, 9.81347200000016],
                [-13.701527, 9.81347200000016],
                [-13.701527, 9.814862],
                [-13.702083, 9.815416],
                [-13.702083, 9.816250000000139],
                [-13.702362, 9.817084],
                [-13.702918, 9.817360000000122],
                [-13.704584, 9.819028000000117],
                [-13.704862, 9.819582000000139],
                [-13.705694, 9.819582000000139],
                [-13.70625, 9.820138000000156],
                [-13.707362, 9.820138000000156],
                [-13.707362, 9.820416],
                [-13.709028, 9.820416],
                [-13.709028, 9.820138000000156],
                [-13.711249999999836, 9.820138000000156],
                [-13.711806, 9.819582000000139],
                [-13.712362, 9.819306],
                [-13.715138, 9.819306],
                [-13.715138, 9.819028000000117],
                [-13.716529, 9.819028000000117],
                [-13.718195, 9.818472000000156],
                [-13.718195, 9.818194],
                [-13.719582999999886, 9.818194],
                [-13.719582999999886, 9.817916000000139],
                [-13.722082999999884, 9.817916000000139],
                [-13.722082999999884, 9.81764],
                [-13.724582999999882, 9.81764],
                [-13.724582999999882, 9.817360000000122],
                [-13.725971, 9.817360000000122],
                [-13.725971, 9.817084],
                [-13.727916999999877, 9.817084],
                [-13.727916999999877, 9.816806000000156],
                [-13.731528, 9.816806000000156],
                [-13.731528, 9.816528],
                [-13.73264, 9.816528],
                [-13.733194, 9.815694000000121],
                [-13.734862, 9.815694000000121],
                [-13.735415999999873, 9.815416],
                [-13.735694, 9.814862],
                [-13.73625, 9.814862],
                [-13.736806, 9.814028000000121],
                [-13.738194, 9.814028000000121],
                [-13.738472, 9.813194],
                [-13.739306, 9.812918000000138],
                [-13.739862, 9.812084],
                [-13.740416, 9.811528],
                [-13.741528, 9.81069600000012],
                [-13.742085, 9.81069600000012],
                [-13.74236, 9.81014000000016],
                [-13.742916, 9.81014000000016],
                [-13.744028999999841, 9.809027],
                [-13.744028999999841, 9.80847400000016],
                [-13.744583, 9.80847400000016],
                [-13.744860999999844, 9.807917],
                [-13.745973, 9.807639000000165],
                [-13.745973, 9.807083],
                [-13.746526999999844, 9.807083],
                [-13.746526999999844, 9.80597100000017],
                [-13.747360999999842, 9.805695],
                [-13.747360999999842, 9.80263900000017],
                [-13.747639, 9.80263900000017],
                [-13.747639, 9.801529000000187],
                [-13.747917, 9.800695],
                [-13.748473, 9.800417],
                [-13.748473, 9.799027],
                [-13.749028999999894, 9.799027],
                [-13.749028999999894, 9.798195],
                [-13.748749, 9.797082000000103],
                [-13.747639, 9.796804],
                [-13.747639, 9.796251],
                [-13.746251, 9.796251],
                [-13.745973, 9.795694],
                [-13.745139, 9.795694],
                [-13.744307, 9.79486],
                [-13.744307, 9.794028],
                [-13.743751, 9.793750000000102],
                [-13.743751, 9.792638000000125],
                [-13.743194999999844, 9.792084000000102],
                [-13.743194999999844, 9.788750000000107],
                [-13.742638, 9.787638000000129],
                [-13.741807, 9.787638000000129],
                [-13.74125, 9.786528000000146],
                [-13.737916, 9.786528000000146],
                [-13.737638, 9.787084000000107],
                [-13.737084, 9.787362],
                [-13.737084, 9.787916],
                [-13.736528, 9.788472],
                [-13.736528, 9.793194],
                [-13.73625, 9.793194],
                [-13.73625, 9.794582],
                [-13.735415999999873, 9.794582],
                [-13.735415999999873, 9.793194],
                [-13.735694, 9.793194],
                [-13.735694, 9.788472],
                [-13.735415999999873, 9.787918],
                [-13.735972, 9.787638000000129],
                [-13.735972, 9.786806],
                [-13.736806, 9.785694],
                [-13.74125, 9.785694],
                [-13.74236, 9.786806],
                [-13.742916, 9.786806],
                [-13.743473, 9.787362],
                [-13.744028999999841, 9.788472],
                [-13.744307, 9.789306000000124],
                [-13.744307, 9.790416000000107],
                [-13.744028999999841, 9.790416000000107],
                [-13.744028999999841, 9.792084000000102],
                [-13.744583, 9.792362],
                [-13.744583, 9.793750000000102],
                [-13.745417, 9.79430600000012],
                [-13.745417, 9.79486],
                [-13.746526999999844, 9.795416000000102],
                [-13.747917, 9.795416000000102],
                [-13.747917, 9.795694],
                [-13.749028999999894, 9.795694],
                [-13.749028999999894, 9.795973000000174],
                [-13.750139, 9.795973000000174],
                [-13.750417, 9.795416000000102],
                [-13.750971, 9.795416000000102],
                [-13.751251, 9.794582],
                [-13.751251, 9.793194],
                [-13.752083, 9.792638000000125],
                [-13.752639, 9.790972000000124],
                [-13.752639, 9.789584],
                [-13.753473, 9.789306000000124],
                [-13.753473, 9.785416000000112],
                [-13.753749, 9.785416000000112],
                [-13.753749, 9.784306000000129],
                [-13.754026999999894, 9.784306000000129],
                [-13.754026999999894, 9.783193],
                [-13.754305, 9.782362],
                [-13.754583, 9.782362],
                [-13.754583, 9.780974000000128],
                [-13.754860999999892, 9.780974000000128],
                [-13.754860999999892, 9.779861],
                [-13.755418, 9.779305],
                [-13.755692999999894, 9.778471000000138],
                [-13.755692999999894, 9.777361000000155],
                [-13.755971, 9.777361000000155],
                [-13.755971, 9.775417],
                [-13.756526999999892, 9.775139000000138],
                [-13.756526999999892, 9.77402700000016],
                [-13.756249, 9.773751],
                [-13.755418, 9.773751],
                [-13.755418, 9.77236100000016],
                [-13.756249, 9.771251000000177],
                [-13.756805999999813, 9.770973],
                [-13.75764, 9.770139],
                [-13.757084, 9.769585000000177],
                [-13.755971, 9.769304],
                [-13.754860999999892, 9.768194000000165],
                [-13.754305, 9.767916],
                [-13.754026999999894, 9.76736000000011],
                [-13.752917, 9.766806],
                [-13.752917, 9.76625],
                [-13.752360999999894, 9.765972],
                [-13.752083, 9.765416000000187],
                [-13.751526999999896, 9.765416000000187],
                [-13.750971, 9.76486000000017],
                [-13.750971, 9.764306],
                [-13.750417, 9.76402800000011],
                [-13.750417, 9.763472],
                [-13.749305, 9.763472],
                [-13.749028999999894, 9.762916],
                [-13.748473, 9.762916],
                [-13.748194999999896, 9.76236200000011],
                [-13.747639, 9.76236200000011],
                [-13.747639, 9.761806],
                [-13.746805, 9.76125],
                [-13.745973, 9.760972],
                [-13.744860999999844, 9.760972],
                [-13.744860999999844, 9.760694000000115],
                [-13.742085, 9.760694000000115],
                [-13.741528, 9.759862000000169],
                [-13.740972, 9.759862000000169],
                [-13.740416, 9.759306],
                [-13.740416, 9.757916],
                [-13.739862, 9.75764],
                [-13.739028, 9.75764],
                [-13.737916, 9.75708400000019],
                [-13.737084, 9.756806],
                [-13.735972, 9.756249],
                [-13.735415999999873, 9.75541800000019],
                [-13.734862, 9.75541800000019],
                [-13.734584, 9.754861000000119],
                [-13.734028, 9.754305000000102],
                [-13.732083999999873, 9.754305000000102],
                [-13.731806, 9.753749000000141],
                [-13.731806, 9.752639000000102],
                [-13.731528, 9.751805],
                [-13.730696, 9.751251],
                [-13.73041499999988, 9.750695],
                [-13.729583999999875, 9.749861000000124],
                [-13.729027, 9.749583],
                [-13.727916999999877, 9.748749000000146],
                [-13.727639, 9.748195000000123],
                [-13.726527, 9.748195000000123],
                [-13.72541699999988, 9.747639000000106],
                [-13.724582999999882, 9.747361],
                [-13.723473, 9.746805],
                [-13.723195, 9.746251],
                [-13.722639, 9.746251],
                [-13.722361, 9.745695],
                [-13.721805, 9.745695],
                [-13.721529, 9.745139],
                [-13.720973, 9.745139],
                [-13.720695, 9.745695],
                [-13.719582999999886, 9.745695],
                [-13.719582999999886, 9.745417000000145],
                [-13.718473, 9.745417000000145],
                [-13.717639, 9.745139],
                [-13.717081999999891, 9.744583],
                [-13.715138, 9.744583],
                [-13.714584999999886, 9.743473],
                [-13.710972, 9.743473],
                [-13.710415999999839, 9.742638],
                [-13.707362, 9.742638],
                [-13.707362, 9.742360000000133],
                [-13.705972, 9.742360000000133],
                [-13.705972, 9.741529000000128],
                [-13.70264, 9.741529000000128],
                [-13.702362, 9.741807],
                [-13.702362, 9.742638],
                [-13.701527, 9.743195000000128],
                [-13.700417, 9.742917],
                [-13.700417, 9.741807],
                [-13.700693, 9.741807],
                [-13.700693, 9.740138000000172],
                [-13.699861, 9.739582000000155],
                [-13.699861, 9.739028000000133],
                [-13.699304999999868, 9.738472000000172],
                [-13.69847099999987, 9.737916000000155],
                [-13.69847099999987, 9.73736],
                [-13.697638999999867, 9.736528],
                [-13.696249, 9.736528],
                [-13.696249, 9.734028],
                [-13.695417, 9.73375],
                [-13.69513899999987, 9.734306],
                [-13.694304999999872, 9.734862],
                [-13.694304999999872, 9.735694],
                [-13.693751, 9.735972],
                [-13.69347299999987, 9.737084],
                [-13.692638999999872, 9.73736],
                [-13.692638999999872, 9.739306],
                [-13.693195, 9.739306],
                [-13.693195, 9.740694000000133],
                [-13.69347299999987, 9.740694000000133],
                [-13.69347299999987, 9.742638],
                [-13.69180699999987, 9.743473],
                [-13.690695, 9.743473],
                [-13.690417, 9.743751000000145],
                [-13.690417, 9.73875],
                [-13.690695, 9.738194],
                [-13.690417, 9.737916000000155],
                [-13.689585, 9.737916000000155],
                [-13.689029, 9.73736],
                [-13.688194, 9.73736],
                [-13.688194, 9.736806000000172],
                [-13.687082, 9.736806000000172],
                [-13.686805999999876, 9.73736],
                [-13.68625, 9.73736],
                [-13.68625, 9.73875],
                [-13.685416, 9.73875],
                [-13.685137999999881, 9.738194],
                [-13.683194, 9.738194],
                [-13.682916, 9.739028000000133],
                [-13.68236, 9.739306],
                [-13.68236, 9.739862],
                [-13.68125, 9.739862],
                [-13.680694, 9.739582000000155],
                [-13.680418, 9.740138000000172],
                [-13.679028, 9.740138000000172],
                [-13.679305999999883, 9.740694000000133],
                [-13.679305999999883, 9.742085000000145],
                [-13.679862, 9.742638],
                [-13.679862, 9.743473],
                [-13.680418, 9.744029],
                [-13.680418, 9.744861000000128],
                [-13.680971999999883, 9.745695],
                [-13.679862, 9.745695],
                [-13.679584, 9.745139],
                [-13.679028, 9.745139],
                [-13.67875, 9.745695],
                [-13.678194, 9.745695],
                [-13.677639999999883, 9.746527000000128],
                [-13.677639999999883, 9.747083000000146],
                [-13.675970999999834, 9.747083000000146],
                [-13.675970999999834, 9.747361],
                [-13.674861, 9.747361],
                [-13.674861, 9.747639000000106],
                [-13.674027, 9.747917],
                [-13.672361, 9.747917],
                [-13.672361, 9.748473],
                [-13.671251, 9.748473],
                [-13.670417, 9.748749000000146],
                [-13.670139, 9.749305000000106],
                [-13.669583, 9.749583],
                [-13.668749, 9.75041700000014],
                [-13.668195, 9.750695],
                [-13.667361, 9.751527000000124],
                [-13.666805, 9.751805],
                [-13.665973, 9.752639000000102],
                [-13.665695, 9.753195000000119],
                [-13.664583, 9.753749000000141],
                [-13.664029, 9.754861000000119],
                [-13.664029, 9.756249],
                [-13.663751, 9.756249],
                [-13.663751, 9.757916],
                [-13.664583, 9.759584],
                [-13.663473, 9.759584],
                [-13.663473, 9.759306],
                [-13.662359999999865, 9.759306],
                [-13.662359999999865, 9.759028000000114],
                [-13.657916, 9.759028000000114],
                [-13.65735999999987, 9.759584],
                [-13.657084, 9.760694000000115],
                [-13.655972, 9.760694000000115],
                [-13.655972, 9.760972],
                [-13.654862, 9.760972],
                [-13.654584, 9.761806],
                [-13.65375, 9.76264],
                [-13.653193999999871, 9.762916],
                [-13.653193999999871, 9.763472],
                [-13.652084, 9.764584],
                [-13.651527999999871, 9.764584],
                [-13.650696, 9.765416000000187],
                [-13.650696, 9.76569400000011],
                [-13.649584, 9.76569400000011],
                [-13.65014, 9.766528000000164],
                [-13.65014, 9.769585000000177],
                [-13.649306, 9.769585000000177],
                [-13.648471, 9.770139],
                [-13.645694999999876, 9.770139],
                [-13.645416999999895, 9.77069500000016],
                [-13.644860999999878, 9.77069500000016],
                [-13.644582999999898, 9.771251000000177],
                [-13.64402699999988, 9.771251000000177],
                [-13.64402699999988, 9.771807000000138],
                [-13.643194999999878, 9.772083],
                [-13.643194999999878, 9.772917000000177],
                [-13.642639, 9.772917000000177],
                [-13.642639, 9.773751],
                [-13.642083, 9.77402700000016],
                [-13.642083, 9.774583000000177],
                [-13.641528999999878, 9.775139000000138],
                [-13.64069499999988, 9.775417],
                [-13.64069499999988, 9.776249000000178],
                [-13.640139, 9.776249000000178],
                [-13.639860999999826, 9.777361000000155],
                [-13.639305, 9.777361000000155],
                [-13.639305, 9.778195],
                [-13.638473, 9.778471000000138],
                [-13.638194999999826, 9.779583000000116],
                [-13.637639, 9.779583000000116],
                [-13.637083, 9.780417],
                [-13.637083, 9.780974000000128],
                [-13.636528999999825, 9.781249000000116],
                [-13.636528999999825, 9.782083],
                [-13.635973, 9.782083],
                [-13.635694999999828, 9.782640000000129],
                [-13.635138, 9.782640000000129],
                [-13.635138, 9.783750000000111],
                [-13.634307, 9.784862000000146],
                [-13.63375, 9.78514],
                [-13.63375, 9.786528000000146],
                [-13.633472, 9.786528000000146],
                [-13.633472, 9.793472],
                [-13.634028, 9.793750000000102],
                [-13.634028, 9.795694],
                [-13.634307, 9.796251],
                [-13.63486, 9.796529],
                [-13.635138, 9.797082000000103],
                [-13.635138, 9.79930700000017],
                [-13.635694999999828, 9.799861],
                [-13.635694999999828, 9.800695],
                [-13.636251, 9.80097300000017],
                [-13.636251, 9.801805000000115],
                [-13.637083, 9.802083],
                [-13.637083, 9.802917],
                [-13.637917, 9.803749],
                [-13.638473, 9.803749],
                [-13.638751, 9.804861000000187],
                [-13.641249, 9.807361],
                [-13.641805, 9.808193000000188],
                [-13.64236099999988, 9.808193000000188],
                [-13.642916999999898, 9.808749],
                [-13.642916999999898, 9.809305000000165],
                [-13.643750999999895, 9.809862],
                [-13.644860999999878, 9.809862],
                [-13.644860999999878, 9.81014000000016],
                [-13.645971, 9.81014000000016],
                [-13.646248999999898, 9.810972],
                [-13.647082999999895, 9.811528],
                [-13.647918, 9.812362000000121],
                [-13.649026999999876, 9.81264],
                [-13.65014, 9.81347200000016],
                [-13.650414999999896, 9.814028000000121],
                [-13.651527999999871, 9.814584000000139],
                [-13.652084, 9.814584000000139],
                [-13.654306, 9.816806000000156],
                [-13.654306, 9.817916000000139],
                [-13.655416, 9.818472000000156],
                [-13.655416, 9.819028000000117],
                [-13.655972, 9.819306],
                [-13.65625, 9.820416],
                [-13.656806, 9.820694000000117],
                [-13.656806, 9.821528],
                [-13.65735999999987, 9.821807],
                [-13.65735999999987, 9.823195000000112],
                [-13.657916, 9.823473],
                [-13.658194, 9.824029],
                [-13.65875, 9.824583],
              ],
            ],
            [
              [
                [-13.720139, 9.857917000000157],
                [-13.720139, 9.858194999000148],
                [-13.719306998999969, 9.858751],
                [-13.719306998999969, 9.859306999000125],
                [-13.718750999999884, 9.859582999000054],
                [-13.718750999999884, 9.860139000000117],
                [-13.717916999999886, 9.860417],
                [-13.717916999999886, 9.860973001000104],
                [-13.717082000999937, 9.861249001000033],
                [-13.716803998999922, 9.862082999000108],
                [-13.716250999999886, 9.86236100100001],
                [-13.716250999999886, 9.862918],
                [-13.715693998999825, 9.863193],
                [-13.715693998999825, 9.863749001000144],
                [-13.715138, 9.864028],
                [-13.715138, 9.864583999000104],
                [-13.714585000999818, 9.864862001],
                [-13.714028, 9.86597199900001],
                [-13.714028, 9.866806],
                [-13.71374999999989, 9.867361999000082],
                [-13.715973000999895, 9.867361999000082],
                [-13.716250999999886, 9.866806],
                [-13.717361, 9.86652800000013],
                [-13.718473000999893, 9.86597199900001],
                [-13.718750999999884, 9.865416],
                [-13.719306998999969, 9.865416],
                [-13.719582998999897, 9.864862001],
                [-13.720139, 9.864862001],
                [-13.720416999999884, 9.864306000000113],
                [-13.721529, 9.864028],
                [-13.721805, 9.863470999000015],
                [-13.722361000999967, 9.863193],
                [-13.723472998999966, 9.86236100100001],
                [-13.724582999999882, 9.861249001000033],
                [-13.725139000999889, 9.861249001000033],
                [-13.725695, 9.860417],
                [-13.726248998999893, 9.860417],
                [-13.72736099899987, 9.859582999000054],
                [-13.727639000999886, 9.85902700000014],
                [-13.72874899899989, 9.858471001000055],
                [-13.729583999999875, 9.858194999000148],
                [-13.730696, 9.858194999000148],
                [-13.730696, 9.857917000000157],
                [-13.732083999999873, 9.857917000000157],
                [-13.732083999999873, 9.857639],
                [-13.733472, 9.857639],
                [-13.734027998999977, 9.857083001000149],
                [-13.735694000999956, 9.856529],
                [-13.737084, 9.856529],
                [-13.738472, 9.855139000000122],
                [-13.739028, 9.854304999000135],
                [-13.739582000999974, 9.854304999000135],
                [-13.740693998999859, 9.853195001000131],
                [-13.740693998999859, 9.852639],
                [-13.741806998999948, 9.851528999000152],
                [-13.742916, 9.850973],
                [-13.743473000999927, 9.850973],
                [-13.744028999999841, 9.850417001000153],
                [-13.744306998999946, 9.849860000000149],
                [-13.745139, 9.84902899900004],
                [-13.745694998999909, 9.848750000000166],
                [-13.746251, 9.847916001000158],
                [-13.746805, 9.847637999000085],
                [-13.747639, 9.847637999000085],
                [-13.748194999999896, 9.847362],
                [-13.74847299899983, 9.846806],
                [-13.749028999999894, 9.846806],
                [-13.750139000999923, 9.845694],
                [-13.750694999999894, 9.845694],
                [-13.750970998999946, 9.845137998999974],
                [-13.751527000999943, 9.845137998999974],
                [-13.751805, 9.844584],
                [-13.752917, 9.844306000000188],
                [-13.753473, 9.843749999000067],
                [-13.754305, 9.843749999000067],
                [-13.754860998999902, 9.843194],
                [-13.756805999999813, 9.842361999000161],
                [-13.757639998999878, 9.842361999000161],
                [-13.758195999999884, 9.841806],
                [-13.759027998999898, 9.841806],
                [-13.759584, 9.841250001000162],
                [-13.760972, 9.841250001000162],
                [-13.760972, 9.84097199900009],
                [-13.762084, 9.84097199900009],
                [-13.762084, 9.840694],
                [-13.763472, 9.840694],
                [-13.763472, 9.84041800000017],
                [-13.76652799999988, 9.84041800000017],
                [-13.76652799999988, 9.84014000100018],
                [-13.767638, 9.840138001000184],
                [-13.767638, 9.839862001000085],
                [-13.769029, 9.839862001000085],
                [-13.769585000999939, 9.839306],
                [-13.770417, 9.839306],
                [-13.770973000999959, 9.838750001000108],
                [-13.771807, 9.838750001000108],
                [-13.772361000999979, 9.838195999000106],
                [-13.773195, 9.838195999000106],
                [-13.774029, 9.83764],
                [-13.774029, 9.837083999000072],
                [-13.77486099899994, 9.836249001000112],
                [-13.775417, 9.836249001000112],
                [-13.775695, 9.835692999000116],
                [-13.77624899899996, 9.835692999000116],
                [-13.776527000999863, 9.835139],
                [-13.777083, 9.835139],
                [-13.777639000999955, 9.834305],
                [-13.778195, 9.834305],
                [-13.778750998999953, 9.833471001000134],
                [-13.779305, 9.833471001000134],
                [-13.780972999999847, 9.831804999000099],
                [-13.781527000999915, 9.831804999000099],
                [-13.781805000999952, 9.83125100000018],
                [-13.782361, 9.83125100000018],
                [-13.782639998999912, 9.830695001000095],
                [-13.78347099999985, 9.830416999000022],
                [-13.784584, 9.830416999000022],
                [-13.785415998999952, 9.830139],
                [-13.78569400099991, 9.829583001000117],
                [-13.78625, 9.829583001000117],
                [-13.78652799899993, 9.829028999000116],
                [-13.787638, 9.828473],
                [-13.788194000999908, 9.828473],
                [-13.789305998999907, 9.827639],
                [-13.790139999999894, 9.827639],
                [-13.791805998999905, 9.826805001000139],
                [-13.791805998999905, 9.826526999],
                [-13.792637999999897, 9.826251],
                [-13.793472000999884, 9.826251],
                [-13.795972000999882, 9.825417001],
                [-13.796529, 9.824304001000144],
                [-13.794305998999903, 9.824304001000144],
                [-13.793749999999818, 9.824861000000112],
                [-13.793193998999982, 9.825138999000103],
                [-13.791528, 9.825138999000103],
                [-13.79125, 9.82402899900012],
                [-13.79125, 9.822916001000067],
                [-13.791805998999905, 9.822637999000108],
                [-13.791805998999905, 9.82208500000013],
                [-13.792916, 9.82208500000013],
                [-13.793472000999884, 9.821807],
                [-13.793472000999884, 9.820416001000126],
                [-13.792084000999978, 9.820416001000126],
                [-13.79097200099983, 9.820972],
                [-13.790415998999833, 9.819582000000139],
                [-13.790693998999927, 9.819306],
                [-13.793193998999982, 9.819306],
                [-13.793472000999884, 9.81902800100005],
                [-13.794581998999888, 9.81902800100005],
                [-13.794581998999888, 9.818749999000147],
                [-13.793193998999982, 9.818194],
                [-13.792916, 9.817640001000143],
                [-13.791805998999905, 9.817638001000148],
                [-13.79125, 9.816806000000156],
                [-13.78958400099998, 9.816806000000156],
                [-13.78958400099998, 9.815971999000169],
                [-13.792637999999897, 9.815971999000169],
                [-13.793749999999818, 9.817084],
                [-13.793749999999818, 9.817640001000143],
                [-13.795415999999818, 9.817640001000143],
                [-13.794860000999904, 9.817084],
                [-13.794860000999904, 9.816250001000071],
                [-13.794581998999888, 9.815694000000121],
                [-13.793749999999818, 9.814862001000165],
                [-13.792916, 9.814583999000035],
                [-13.792916, 9.812918000000138],
                [-13.793472000999884, 9.812083999000151],
                [-13.794028, 9.81180600000016],
                [-13.795137999999895, 9.81180600000016],
                [-13.795137999999895, 9.812083999000151],
                [-13.797360000999902, 9.812083999000151],
                [-13.79791699999987, 9.81180600000016],
                [-13.79763899999989, 9.811250001000076],
                [-13.79763899999989, 9.81014000000016],
                [-13.795415999999818, 9.81014000000016],
                [-13.794860000999904, 9.80958399900004],
                [-13.794860000999904, 9.808192999000084],
                [-13.795137999999895, 9.807917],
                [-13.797081998999886, 9.807917],
                [-13.797081998999886, 9.80708300100008],
                [-13.79625099999987, 9.80708300100008],
                [-13.79569399899998, 9.806527000000187],
                [-13.79569399899998, 9.805416999000101],
                [-13.79625099999987, 9.805139],
                [-13.79625099999987, 9.804304999000067],
                [-13.794860000999904, 9.803473],
                [-13.793193998999982, 9.803473],
                [-13.792916, 9.803195001000063],
                [-13.792916, 9.802083],
                [-13.792084000999978, 9.80180500099999],
                [-13.79125, 9.80097300000017],
                [-13.790693998999927, 9.800695001000179],
                [-13.790415998999833, 9.800138999000183],
                [-13.78958400099998, 9.800138999000183],
                [-13.789028, 9.799583],
                [-13.788194000999908, 9.799307001000102],
                [-13.783749, 9.799307001000102],
                [-13.782918000999928, 9.799026999000034],
                [-13.782361, 9.798473000000115],
                [-13.780417000999932, 9.798473000000115],
                [-13.780138998999917, 9.799026999000034],
                [-13.780138998999917, 9.800695001000179],
                [-13.779860998999936, 9.800695001000179],
                [-13.779860998999936, 9.802361],
                [-13.779305, 9.80263900000017],
                [-13.779029000999913, 9.803195001000063],
                [-13.779029000999913, 9.807361],
                [-13.778750998999953, 9.807361],
                [-13.778750998999953, 9.809027],
                [-13.778195, 9.80958399900004],
                [-13.778195, 9.810695999000075],
                [-13.777917, 9.810972001000152],
                [-13.777083, 9.810972001000152],
                [-13.776527000999863, 9.811250001000076],
                [-13.775695, 9.81014000000016],
                [-13.775695, 9.808749],
                [-13.775972998999919, 9.808749],
                [-13.775972998999919, 9.807361],
                [-13.77624899899996, 9.807361],
                [-13.77624899899996, 9.805971001000103],
                [-13.776527000999863, 9.805971001000103],
                [-13.777083, 9.804861000000187],
                [-13.777083, 9.801251],
                [-13.777639000999955, 9.799861],
                [-13.777360998999939, 9.799583],
                [-13.775972998999919, 9.799583],
                [-13.775972998999919, 9.799861],
                [-13.774583, 9.801249],
                [-13.774305, 9.802083],
                [-13.773751000999937, 9.80291699900016],
                [-13.773472998999864, 9.804027],
                [-13.771807, 9.804027],
                [-13.771529, 9.803473],
                [-13.770417, 9.803195001000063],
                [-13.76986000099987, 9.803749],
                [-13.76986000099987, 9.804304999000067],
                [-13.76875, 9.804583000999969],
                [-13.76875, 9.805139],
                [-13.767916, 9.805416999000101],
                [-13.767638, 9.805971001000103],
                [-13.766805998999871, 9.806804999000178],
                [-13.76652799999988, 9.807917],
                [-13.765972000999966, 9.808192999000084],
                [-13.76569399899995, 9.808749],
                [-13.764859999999885, 9.80958399900004],
                [-13.764859999999885, 9.81014000000016],
                [-13.764305998999873, 9.810695999000075],
                [-13.76375, 9.81180600000016],
                [-13.764027999999882, 9.812362001000054],
                [-13.76375, 9.813194],
                [-13.76375, 9.815416],
                [-13.763194000999874, 9.815694000000121],
                [-13.763194000999874, 9.817638001000148],
                [-13.762915998999972, 9.817640001000143],
                [-13.762915998999972, 9.819861999000125],
                [-13.76264, 9.819861999000125],
                [-13.76264, 9.820972],
                [-13.762084, 9.821528001000104],
                [-13.762084, 9.822637999000108],
                [-13.761806000999968, 9.823195000000112],
                [-13.76125, 9.823750999000026],
                [-13.760972, 9.824583],
                [-13.760972, 9.8256950010001],
                [-13.760418000999891, 9.826251],
                [-13.760418000999891, 9.82708300000013],
                [-13.759861999999885, 9.827361],
                [-13.759584, 9.828473],
                [-13.75875199899997, 9.82874900000013],
                [-13.75819399999989, 9.830139],
                [-13.757361999999887, 9.830416999000022],
                [-13.757361999999887, 9.83125100000018],
                [-13.756805999999813, 9.831527000000108],
                [-13.756805999999813, 9.832083001],
                [-13.756248998999979, 9.83291700000018],
                [-13.755692999999894, 9.83291700000018],
                [-13.755692999999894, 9.833471001000134],
                [-13.754860998999902, 9.834305],
                [-13.754305, 9.834305],
                [-13.752639000999977, 9.83597099900004],
                [-13.751527000999943, 9.836527000000103],
                [-13.7512510009999, 9.837083999000072],
                [-13.750139000999923, 9.83764],
                [-13.749305, 9.83764],
                [-13.748749000999908, 9.838195999000106],
                [-13.747917, 9.838195999000106],
                [-13.746805, 9.838750001000108],
                [-13.746526999999844, 9.839306],
                [-13.745973000999925, 9.839306],
                [-13.745694998999909, 9.839862001000085],
                [-13.745139, 9.839862001000085],
                [-13.744582998999931, 9.840694],
                [-13.744028999999841, 9.840694],
                [-13.743751, 9.841250001000162],
                [-13.743194998999911, 9.841250001000162],
                [-13.742916, 9.841806],
                [-13.742360000999952, 9.841806],
                [-13.742085000999907, 9.842361999000161],
                [-13.741528, 9.842361999000161],
                [-13.74125, 9.842916],
                [-13.740693998999859, 9.842916],
                [-13.740415998999936, 9.843472],
                [-13.739862, 9.843472],
                [-13.739582000999974, 9.84402800099997],
                [-13.739028, 9.84402800099997],
                [-13.73875, 9.844584],
                [-13.738194000999954, 9.844584],
                [-13.737084, 9.845416001000103],
                [-13.736806000999934, 9.845972000000188],
                [-13.73625, 9.845972000000188],
                [-13.735694000999956, 9.846806],
                [-13.734862, 9.84708200000017],
                [-13.734862, 9.847637999000085],
                [-13.733749999999873, 9.847916001000158],
                [-13.733749999999873, 9.848472],
                [-13.732918000999973, 9.848472],
                [-13.732639998999957, 9.84902899900004],
                [-13.731806, 9.84902899900004],
                [-13.731528000999958, 9.849585000000161],
                [-13.730696, 9.849585000000161],
                [-13.730140000999938, 9.849860000000149],
                [-13.72986199899998, 9.850417001000153],
                [-13.72874899899989, 9.850695001000076],
                [-13.728471, 9.851251000000161],
                [-13.72708299999988, 9.851807001000054],
                [-13.726248998999893, 9.851807001000054],
                [-13.725695, 9.852361000000144],
                [-13.724582999999882, 9.852639],
                [-13.724027, 9.852916999000058],
                [-13.723472998999966, 9.853473000000122],
                [-13.722639, 9.855139000000122],
                [-13.722082998999895, 9.855695001000072],
                [-13.722082998999895, 9.856249000000162],
                [-13.720416999999884, 9.857917000000157],
                [-13.720139, 9.857917000000157],
              ],
            ],
            [
              [
                [-13.642639, 9.863193],
                [-13.642916999999898, 9.863749001000144],
                [-13.643750999999895, 9.864028],
                [-13.645139, 9.864028],
                [-13.645139, 9.864306000000113],
                [-13.645970998999815, 9.864583999000104],
                [-13.646805, 9.864306000000113],
                [-13.651249998999958, 9.864306000000113],
                [-13.651249998999958, 9.864583999000104],
                [-13.653472, 9.864583999000104],
                [-13.653472, 9.85736100000014],
                [-13.653749998999956, 9.85736100000014],
                [-13.653749998999956, 9.855695001000072],
                [-13.65513999899997, 9.854304999000135],
                [-13.65652800099997, 9.853751],
                [-13.659306000999948, 9.853751],
                [-13.660138, 9.854027000000144],
                [-13.66125, 9.855139000000122],
                [-13.662085, 9.855416999000113],
                [-13.663751, 9.855416999000113],
                [-13.664306998999848, 9.855139000000122],
                [-13.664861, 9.854583001000037],
                [-13.665416998999945, 9.854583001000037],
                [-13.665694998999925, 9.854027000000144],
                [-13.666251, 9.853751],
                [-13.666251, 9.852083],
                [-13.666527, 9.852083],
                [-13.666527, 9.850973],
                [-13.66680499899985, 9.850973],
                [-13.66680499899985, 9.845694],
                [-13.667083000999924, 9.845694],
                [-13.667083000999924, 9.844584],
                [-13.667639, 9.84402800099997],
                [-13.667639, 9.842640001000063],
                [-13.667917, 9.842640001000063],
                [-13.667917, 9.839028],
                [-13.667639, 9.838471998999978],
                [-13.66680499899985, 9.837362000999974],
                [-13.664306998999848, 9.837362000999974],
                [-13.664306998999848, 9.83764],
                [-13.663195000999963, 9.83764],
                [-13.663195000999963, 9.838471998999978],
                [-13.662638, 9.838471998999978],
                [-13.662359999999865, 9.839028],
                [-13.661528998999927, 9.839028],
                [-13.66125, 9.839862001000085],
                [-13.660694000999968, 9.84041800000017],
                [-13.659306000999948, 9.84041800000017],
                [-13.659306000999948, 9.840694],
                [-13.65735999999987, 9.840694],
                [-13.65680600099995, 9.840138001000184],
                [-13.65513999899997, 9.840138001000184],
                [-13.654861998999934, 9.840694],
                [-13.654028000999972, 9.84097199900009],
                [-13.654028000999972, 9.841528],
                [-13.652918, 9.842361999000161],
                [-13.652638000999957, 9.842916],
                [-13.651249998999958, 9.843749999000067],
                [-13.650414999999896, 9.844584],
                [-13.650140000999954, 9.845416001000103],
                [-13.649584, 9.845416001000103],
                [-13.648470998999812, 9.846528001000081],
                [-13.648192999999878, 9.84736],
                [-13.647361000999979, 9.847362],
                [-13.647082998999963, 9.847637999000085],
                [-13.647082998999963, 9.848472],
                [-13.646805, 9.849304001000064],
                [-13.645970998999815, 9.849585000000161],
                [-13.645970998999815, 9.850417001000153],
                [-13.645416999999895, 9.850973],
                [-13.644861000999981, 9.851251000000161],
                [-13.644861000999981, 9.852083],
                [-13.644582998999965, 9.852639],
                [-13.643750999999895, 9.853195001000131],
                [-13.643750999999895, 9.854027000000144],
                [-13.642916999999898, 9.854861],
                [-13.642639, 9.855416999000113],
                [-13.641528999999878, 9.856527],
                [-13.641528999999878, 9.857639],
                [-13.641249, 9.857639],
                [-13.640973000999907, 9.858471001000055],
                [-13.640973000999907, 9.862082999000108],
                [-13.64208300099989, 9.863193],
                [-13.642639, 9.863193],
              ],
            ],
            [
              [
                [-13.674304999999833, 9.937639],
                [-13.674304999999833, 9.938751],
                [-13.674861, 9.939583000000141],
                [-13.675693, 9.939583000000141],
                [-13.677084, 9.940139000000102],
                [-13.678194, 9.940139000000102],
                [-13.678471999999886, 9.939305],
                [-13.679305999999883, 9.939027000000124],
                [-13.679305999999883, 9.938471000000106],
                [-13.679862, 9.938471000000106],
                [-13.680137999999886, 9.93791700000014],
                [-13.680137999999886, 9.937083],
                [-13.680694, 9.936805000000106],
                [-13.680694, 9.935973],
                [-13.68125, 9.935695000000123],
                [-13.68125, 9.934861],
                [-13.681805999999881, 9.934305],
                [-13.681805999999881, 9.933195],
                [-13.682084, 9.933195],
                [-13.682084, 9.927082000000155],
                [-13.68236, 9.927082000000155],
                [-13.682361999999898, 9.925694],
                [-13.682916, 9.925416000000155],
                [-13.682639999999878, 9.92486],
                [-13.682639999999878, 9.923472],
                [-13.682084, 9.923194],
                [-13.682084, 9.921806],
                [-13.681528, 9.921528],
                [-13.681528, 9.920972000000177],
                [-13.680971999999883, 9.919862],
                [-13.680694, 9.919028],
                [-13.679305999999883, 9.916249],
                [-13.67875, 9.915971],
                [-13.678471999999886, 9.915139000000181],
                [-13.678471999999886, 9.913195],
                [-13.678194, 9.913195],
                [-13.678194, 9.911527],
                [-13.677916, 9.911527],
                [-13.677916, 9.909305000000131],
                [-13.678471999999886, 9.908749000000114],
                [-13.678471999999886, 9.907639000000131],
                [-13.67875, 9.906807000000185],
                [-13.679028, 9.906807000000185],
                [-13.679028, 9.905695],
                [-13.679862, 9.90513900000019],
                [-13.679862, 9.904029],
                [-13.680418, 9.903751000000113],
                [-13.680971999999883, 9.903194],
                [-13.680971999999883, 9.902085000000113],
                [-13.681805999999881, 9.901528],
                [-13.682639999999878, 9.90013800000014],
                [-13.683194, 9.90013800000014],
                [-13.683194, 9.899584000000118],
                [-13.684305999999879, 9.8990280000001],
                [-13.684305999999879, 9.898194],
                [-13.684862, 9.89764],
                [-13.685416, 9.89764],
                [-13.685416, 9.897084],
                [-13.68625, 9.896250000000123],
                [-13.686805999999876, 9.896250000000123],
                [-13.687637999999879, 9.895416],
                [-13.688194, 9.895138000000145],
                [-13.688194, 9.894584000000123],
                [-13.689029, 9.894028000000162],
                [-13.689585, 9.892916000000127],
                [-13.689585, 9.891250000000127],
                [-13.690138999999874, 9.890418],
                [-13.690972999999872, 9.890418],
                [-13.691527, 9.890140000000144],
                [-13.69180699999987, 9.889583],
                [-13.692361, 9.889583],
                [-13.692917, 9.889027],
                [-13.693195, 9.887917],
                [-13.694029, 9.88763900000015],
                [-13.694029, 9.887083000000132],
                [-13.694583, 9.887083000000132],
                [-13.694583, 9.886527000000171],
                [-13.695417, 9.885417000000132],
                [-13.696249, 9.885139],
                [-13.696249, 9.884583],
                [-13.69680499999987, 9.884027],
                [-13.698195, 9.883195000000171],
                [-13.698195, 9.882639000000154],
                [-13.699027, 9.882083],
                [-13.699304999999868, 9.880973000000154],
                [-13.699861, 9.880695],
                [-13.700139, 9.880139],
                [-13.700139, 9.879307000000153],
                [-13.700693, 9.879027],
                [-13.700693, 9.878195000000176],
                [-13.701249, 9.877639],
                [-13.701249, 9.876804],
                [-13.701805, 9.876529000000176],
                [-13.701805, 9.875416],
                [-13.702083, 9.875416],
                [-13.702083, 9.872916],
                [-13.70264, 9.87236200000018],
                [-13.702918, 9.871528000000126],
                [-13.702918, 9.870694000000185],
                [-13.703471, 9.87014],
                [-13.703471, 9.869306000000108],
                [-13.704028, 9.869306000000108],
                [-13.704584, 9.86819400000013],
                [-13.704584, 9.867638000000113],
                [-13.70514, 9.867084],
                [-13.70514, 9.86625],
                [-13.705694, 9.865694],
                [-13.705694, 9.86486200000013],
                [-13.706528, 9.863193],
                [-13.706806, 9.862361000000135],
                [-13.707638, 9.861805000000118],
                [-13.707638, 9.860973],
                [-13.708194, 9.860973],
                [-13.708194, 9.860139000000117],
                [-13.708749999999839, 9.860139000000117],
                [-13.708749999999839, 9.859583000000157],
                [-13.709583999999836, 9.859307],
                [-13.709583999999836, 9.858751],
                [-13.710415999999839, 9.858195],
                [-13.710415999999839, 9.857639],
                [-13.710972, 9.857639],
                [-13.710972, 9.857083],
                [-13.711806, 9.856805000000122],
                [-13.711806, 9.855973],
                [-13.712638, 9.85569500000014],
                [-13.712638, 9.855139000000122],
                [-13.713472, 9.855139000000122],
                [-13.713472, 9.854583000000162],
                [-13.714028, 9.854305],
                [-13.714306, 9.853195],
                [-13.71486, 9.853195],
                [-13.715138, 9.852639],
                [-13.715415999999891, 9.851529],
                [-13.715973, 9.851251000000161],
                [-13.717361, 9.849860000000149],
                [-13.717361, 9.849029000000144],
                [-13.717639, 9.848472],
                [-13.718195, 9.848472],
                [-13.718473, 9.847916],
                [-13.718473, 9.84708200000017],
                [-13.719027, 9.84708200000017],
                [-13.719027, 9.846528000000148],
                [-13.719861, 9.84625],
                [-13.720139, 9.845694],
                [-13.720695, 9.845694],
                [-13.720695, 9.845138],
                [-13.721248999999887, 9.844584],
                [-13.722361, 9.844028],
                [-13.722361, 9.843472],
                [-13.722916999999882, 9.843472],
                [-13.723195, 9.842916],
                [-13.72375099999988, 9.842362],
                [-13.724305, 9.842362],
                [-13.724305, 9.841806],
                [-13.72541699999988, 9.840694],
                [-13.725971, 9.840694],
                [-13.726527, 9.839862],
                [-13.727639, 9.839584],
                [-13.728193, 9.839028],
                [-13.728193, 9.838472],
                [-13.729027, 9.838196],
                [-13.729027, 9.83764],
                [-13.729583999999875, 9.83764],
                [-13.730696, 9.837084000000175],
                [-13.730972, 9.836527000000103],
                [-13.732083999999873, 9.835418000000175],
                [-13.73264, 9.835418000000175],
                [-13.73264, 9.834861000000103],
                [-13.733472, 9.834305],
                [-13.734306, 9.834305],
                [-13.734306, 9.833749000000125],
                [-13.735415999999873, 9.833195000000103],
                [-13.735415999999873, 9.832361],
                [-13.73625, 9.831805],
                [-13.736806, 9.83125100000018],
                [-13.736806, 9.830695],
                [-13.73736, 9.830417000000125],
                [-13.73736, 9.829861000000108],
                [-13.738472, 9.82874900000013],
                [-13.738472, 9.827639],
                [-13.73875, 9.827639],
                [-13.73875, 9.826527000000112],
                [-13.739028, 9.826527000000112],
                [-13.739028, 9.82541700000013],
                [-13.739306, 9.82541700000013],
                [-13.739306, 9.822638],
                [-13.73875, 9.82208500000013],
                [-13.735972, 9.82208500000013],
                [-13.735972, 9.821807],
                [-13.734306, 9.821807],
                [-13.734306, 9.821528],
                [-13.731249999999875, 9.821528],
                [-13.730972, 9.82208500000013],
                [-13.726248999999882, 9.82208500000013],
                [-13.726248999999882, 9.822360000000117],
                [-13.724861, 9.822360000000117],
                [-13.724861, 9.822638],
                [-13.722916999999882, 9.822638],
                [-13.722916999999882, 9.822916000000134],
                [-13.721805, 9.822916000000134],
                [-13.721805, 9.823195000000112],
                [-13.719861, 9.823195000000112],
                [-13.719861, 9.823473],
                [-13.715973, 9.823473],
                [-13.715973, 9.82375100000013],
                [-13.712081999999839, 9.82375100000013],
                [-13.712081999999839, 9.824029],
                [-13.711249999999836, 9.824304],
                [-13.710415999999839, 9.824029],
                [-13.707638, 9.824029],
                [-13.707638, 9.82375100000013],
                [-13.705972, 9.82375100000013],
                [-13.705972, 9.823473],
                [-13.704862, 9.823473],
                [-13.704862, 9.823195000000112],
                [-13.70375, 9.823195000000112],
                [-13.702918, 9.822916000000134],
                [-13.702362, 9.822360000000117],
                [-13.701527, 9.822360000000117],
                [-13.700417, 9.821250000000134],
                [-13.699861, 9.820972],
                [-13.699861, 9.820138000000156],
                [-13.699304999999868, 9.819862],
                [-13.698751, 9.81875],
                [-13.698749, 9.818194],
                [-13.698195, 9.817916000000139],
                [-13.698195, 9.816528],
                [-13.697917, 9.816528],
                [-13.697917, 9.81375],
                [-13.697638999999867, 9.81375],
                [-13.697638999999867, 9.81264],
                [-13.697917, 9.812084],
                [-13.697361, 9.81180600000016],
                [-13.697361, 9.810972],
                [-13.69680499999987, 9.810415],
                [-13.69513899999987, 9.810415],
                [-13.694304999999872, 9.811250000000143],
                [-13.694304999999872, 9.81264],
                [-13.694029, 9.81264],
                [-13.694029, 9.814306],
                [-13.694304999999872, 9.814306],
                [-13.694304999999872, 9.816528],
                [-13.694029, 9.816528],
                [-13.694029, 9.819582000000139],
                [-13.69347299999987, 9.819862],
                [-13.693195, 9.820694000000117],
                [-13.692361, 9.821528],
                [-13.69180699999987, 9.822360000000117],
                [-13.690972999999872, 9.822360000000117],
                [-13.690695, 9.822916000000134],
                [-13.68986, 9.822916000000134],
                [-13.689585, 9.823473],
                [-13.688751, 9.82375100000013],
                [-13.687637999999879, 9.82375100000013],
                [-13.687637999999879, 9.824029],
                [-13.68625, 9.824029],
                [-13.68625, 9.824304],
                [-13.682916, 9.824304],
                [-13.682916, 9.824583],
                [-13.681528, 9.824583],
                [-13.680694, 9.82541700000013],
                [-13.680418, 9.825973],
                [-13.680418, 9.826805],
                [-13.679862, 9.827361],
                [-13.679862, 9.828473],
                [-13.679584, 9.828473],
                [-13.679584, 9.833195000000103],
                [-13.679862, 9.833195000000103],
                [-13.679862, 9.834305],
                [-13.680137999999886, 9.834305],
                [-13.680137999999886, 9.835971],
                [-13.679862, 9.835971],
                [-13.679862, 9.83791500000018],
                [-13.679028, 9.838472],
                [-13.67875, 9.839028],
                [-13.678194, 9.839028],
                [-13.677639999999883, 9.839584],
                [-13.677639999999883, 9.840138],
                [-13.677084, 9.84041800000017],
                [-13.676805999999885, 9.840972],
                [-13.676805999999885, 9.843472],
                [-13.677639999999883, 9.84375000000017],
                [-13.677639999999883, 9.84541600000017],
                [-13.678194, 9.845694],
                [-13.678471999999886, 9.84625],
                [-13.678471999999886, 9.84708200000017],
                [-13.679305999999883, 9.847638000000188],
                [-13.679305999999883, 9.847916],
                [-13.680137999999886, 9.848194000000149],
                [-13.682916, 9.848194000000149],
                [-13.682916, 9.848472],
                [-13.684305999999879, 9.848472],
                [-13.685416, 9.849029000000144],
                [-13.685971999999879, 9.849585000000161],
                [-13.685971999999879, 9.851807000000122],
                [-13.684584, 9.853195],
                [-13.683471999999881, 9.853473000000122],
                [-13.682916, 9.854027000000144],
                [-13.682639999999878, 9.854583000000162],
                [-13.681805999999881, 9.854861],
                [-13.681528, 9.855973],
                [-13.680971999999883, 9.856249000000162],
                [-13.680971999999883, 9.858195],
                [-13.684305999999879, 9.858195],
                [-13.685693999999899, 9.857639],
                [-13.685971999999879, 9.857083],
                [-13.686528, 9.857083],
                [-13.686805999999876, 9.856529],
                [-13.687637999999879, 9.856529],
                [-13.687916, 9.855973],
                [-13.689585, 9.855973],
                [-13.68986, 9.856249000000162],
                [-13.689585, 9.856805000000122],
                [-13.688194, 9.856805000000122],
                [-13.687916, 9.85736100000014],
                [-13.687082, 9.85736100000014],
                [-13.686805999999876, 9.857917000000157],
                [-13.68625, 9.857917000000157],
                [-13.685971999999879, 9.858471000000122],
                [-13.685137999999881, 9.858471000000122],
                [-13.684584, 9.85902700000014],
                [-13.680694, 9.85902700000014],
                [-13.680137999999886, 9.858195],
                [-13.680137999999886, 9.857083],
                [-13.679862, 9.856249000000162],
                [-13.680694, 9.855973],
                [-13.680971999999883, 9.855417],
                [-13.680971999999883, 9.854305],
                [-13.68125, 9.854027000000144],
                [-13.682084, 9.854027000000144],
                [-13.682639999999878, 9.853751],
                [-13.682639999999878, 9.853195],
                [-13.683471999999881, 9.852917000000161],
                [-13.68375, 9.852361000000144],
                [-13.684305999999879, 9.852361000000144],
                [-13.685137999999881, 9.851529],
                [-13.685137999999881, 9.849860000000149],
                [-13.684027999999898, 9.849304000000188],
                [-13.680971999999883, 9.849304000000188],
                [-13.680137999999886, 9.849029000000144],
                [-13.680137999999886, 9.848750000000166],
                [-13.679028, 9.848750000000166],
                [-13.678194, 9.848194000000149],
                [-13.678194, 9.847638000000188],
                [-13.677639999999883, 9.84736],
                [-13.677639999999883, 9.846528000000148],
                [-13.677362, 9.845972000000188],
                [-13.676805999999885, 9.845694],
                [-13.676805999999885, 9.844028],
                [-13.675970999999834, 9.843472],
                [-13.675970999999834, 9.840694],
                [-13.676249, 9.84014],
                [-13.676805999999885, 9.839862],
                [-13.676805999999885, 9.839306],
                [-13.677639999999883, 9.838750000000175],
                [-13.67875, 9.83764],
                [-13.679028, 9.836806],
                [-13.679028, 9.835971],
                [-13.679305999999883, 9.835139],
                [-13.678471999999886, 9.834861000000103],
                [-13.677916, 9.833749000000125],
                [-13.677916, 9.832639],
                [-13.677639999999883, 9.831805],
                [-13.677362, 9.831805],
                [-13.677362, 9.830139],
                [-13.677639999999883, 9.830139],
                [-13.677916, 9.829305],
                [-13.677916, 9.828195000000107],
                [-13.678194, 9.827361],
                [-13.678471999999886, 9.827361],
                [-13.678471999999886, 9.825139],
                [-13.678194, 9.824583],
                [-13.677084, 9.82375100000013],
                [-13.675970999999834, 9.82375100000013],
                [-13.675693, 9.824304],
                [-13.67347299999983, 9.824304],
                [-13.672638999999833, 9.824029],
                [-13.672083, 9.824583],
                [-13.671251, 9.824583],
                [-13.670971, 9.825139],
                [-13.670417, 9.82541700000013],
                [-13.669305, 9.82541700000013],
                [-13.668749, 9.825973],
                [-13.667361, 9.825973],
                [-13.665695, 9.826805],
                [-13.665417, 9.827361],
                [-13.664583, 9.827361],
                [-13.663473, 9.828195000000107],
                [-13.663195, 9.82874900000013],
                [-13.661804, 9.82874900000013],
                [-13.661804, 9.829029],
                [-13.660416, 9.829029],
                [-13.659306, 9.829583],
                [-13.65875, 9.830695],
                [-13.658472, 9.831527000000108],
                [-13.658472, 9.832361],
                [-13.658194, 9.833195000000103],
                [-13.657638, 9.833471],
                [-13.657638, 9.835139],
                [-13.657084, 9.835693],
                [-13.657084, 9.836527000000103],
                [-13.65735999999987, 9.837084000000175],
                [-13.657916, 9.83764],
                [-13.659861999999862, 9.83764],
                [-13.660972, 9.837084000000175],
                [-13.660972, 9.836806],
                [-13.663195, 9.836806],
                [-13.663195, 9.836527000000103],
                [-13.664861, 9.836527000000103],
                [-13.664861, 9.83624900000018],
                [-13.665973, 9.83624900000018],
                [-13.667361, 9.836806],
                [-13.668195, 9.83764],
                [-13.669027, 9.839306],
                [-13.669027, 9.840972],
                [-13.668749, 9.840972],
                [-13.668749, 9.843472],
                [-13.668473, 9.843472],
                [-13.668473, 9.845138],
                [-13.667917, 9.845694],
                [-13.667639, 9.846528000000148],
                [-13.667639, 9.852361000000144],
                [-13.667083, 9.852639],
                [-13.667083, 9.854027000000144],
                [-13.666251, 9.854305],
                [-13.665973, 9.855139000000122],
                [-13.665139, 9.855417],
                [-13.664861, 9.855973],
                [-13.664307, 9.856249000000162],
                [-13.663751, 9.855973],
                [-13.66125, 9.855973],
                [-13.660138, 9.855417],
                [-13.658472, 9.854861],
                [-13.658472, 9.854583000000162],
                [-13.656528, 9.854583000000162],
                [-13.655972, 9.855139000000122],
                [-13.65514, 9.855139000000122],
                [-13.65514, 9.85569500000014],
                [-13.654584, 9.855973],
                [-13.654584, 9.85736100000014],
                [-13.654306, 9.85736100000014],
                [-13.654306, 9.864584],
                [-13.654862, 9.86486200000013],
                [-13.654862, 9.865694],
                [-13.65514, 9.865972000000113],
                [-13.655972, 9.865972000000113],
                [-13.656528, 9.867084],
                [-13.65735999999987, 9.867084],
                [-13.659306, 9.869028000000185],
                [-13.659306, 9.869306000000108],
                [-13.660138, 9.869862000000126],
                [-13.660138, 9.870416],
                [-13.660693999999864, 9.870694000000185],
                [-13.660693999999864, 9.87125],
                [-13.66125, 9.871528000000126],
                [-13.661529, 9.872638000000109],
                [-13.662085, 9.872916],
                [-13.662359999999865, 9.87402800000018],
                [-13.662917, 9.874306000000104],
                [-13.662917, 9.87736000000018],
                [-13.663473, 9.877639],
                [-13.663751, 9.878473],
                [-13.663751, 9.881805],
                [-13.663473, 9.881805],
                [-13.663473, 9.883195000000171],
                [-13.663195, 9.883195000000171],
                [-13.663195, 9.884305000000154],
                [-13.662917, 9.884305000000154],
                [-13.662917, 9.886249],
                [-13.662359999999865, 9.887917],
                [-13.661804, 9.889027],
                [-13.66125, 9.88930500000015],
                [-13.66125, 9.890140000000144],
                [-13.660972, 9.89097100000015],
                [-13.660416, 9.891528],
                [-13.660416, 9.892916000000127],
                [-13.659861999999862, 9.893472000000145],
                [-13.65986, 9.894584000000123],
                [-13.659306, 9.894862],
                [-13.65875, 9.895972],
                [-13.65875, 9.89680600000014],
                [-13.657916, 9.897360000000162],
                [-13.657916, 9.897916000000123],
                [-13.65735999999987, 9.898194],
                [-13.65735999999987, 9.89875],
                [-13.656806, 9.8990280000001],
                [-13.656806, 9.899862],
                [-13.65625, 9.90013800000014],
                [-13.655972, 9.900694000000101],
                [-13.655972, 9.903194],
                [-13.656806, 9.90347300000019],
                [-13.656806, 9.904029],
                [-13.65735999999987, 9.904029],
                [-13.65735999999987, 9.90347300000019],
                [-13.657916, 9.902916000000118],
                [-13.658472, 9.902916000000118],
                [-13.658472, 9.902360000000101],
                [-13.660972, 9.899862],
                [-13.661529, 9.899584000000118],
                [-13.662085, 9.8990280000001],
                [-13.664861, 9.8990280000001],
                [-13.664861, 9.899306],
                [-13.665973, 9.900416],
                [-13.666527, 9.900694000000101],
                [-13.666805, 9.901250000000118],
                [-13.666805, 9.903194],
                [-13.666527, 9.903194],
                [-13.666527, 9.904861],
                [-13.665973, 9.90513900000019],
                [-13.665973, 9.905973000000131],
                [-13.665417, 9.906527],
                [-13.665417, 9.907639000000131],
                [-13.664861, 9.908195],
                [-13.664861, 9.909029],
                [-13.664029, 9.909583],
                [-13.664029, 9.910139000000186],
                [-13.663473, 9.910417000000109],
                [-13.663473, 9.910973],
                [-13.662917, 9.910973],
                [-13.662359999999865, 9.911527],
                [-13.662085, 9.91208300000011],
                [-13.662085, 9.91374900000011],
                [-13.661804, 9.914583],
                [-13.662085, 9.914861],
                [-13.667083, 9.914861],
                [-13.667917, 9.915139000000181],
                [-13.669583, 9.915974000000176],
                [-13.669861, 9.916805000000181],
                [-13.670417, 9.916805000000181],
                [-13.670417, 9.919306000000176],
                [-13.669583, 9.920418000000154],
                [-13.669027, 9.920418000000154],
                [-13.668749, 9.920972000000177],
                [-13.667361, 9.921528],
                [-13.666251, 9.921528],
                [-13.665417, 9.921806],
                [-13.665139, 9.922362],
                [-13.664307, 9.922638000000177],
                [-13.664307, 9.923194],
                [-13.663751, 9.923472],
                [-13.663751, 9.92625],
                [-13.664307, 9.927638000000172],
                [-13.665417, 9.928194000000133],
                [-13.665973, 9.92875000000015],
                [-13.666805, 9.929028],
                [-13.668195, 9.929028],
                [-13.668195, 9.929307],
                [-13.669583, 9.929307],
                [-13.669583, 9.929585000000145],
                [-13.671251, 9.929585000000145],
                [-13.671527, 9.930138],
                [-13.672083, 9.930138],
                [-13.672917, 9.930973],
                [-13.673195, 9.931529],
                [-13.673195, 9.932639],
                [-13.67347299999983, 9.932639],
                [-13.67347299999983, 9.934305],
                [-13.673749, 9.934305],
                [-13.673749, 9.935695000000123],
                [-13.674027, 9.935695000000123],
                [-13.674027, 9.937639],
                [-13.674304999999833, 9.937639],
              ],
            ],
            [
              [
                [-13.684027999999898, 9.922362],
                [-13.684584, 9.922638000000177],
                [-13.684584, 9.923194],
                [-13.685137999999881, 9.923750000000155],
                [-13.685693999999899, 9.923750000000155],
                [-13.68625, 9.923194],
                [-13.68625, 9.922638000000177],
                [-13.686805999999876, 9.922084000000154],
                [-13.686805999999876, 9.9212500000001],
                [-13.687359999999899, 9.920694],
                [-13.687359999999899, 9.919862],
                [-13.688194, 9.918196],
                [-13.688471999999877, 9.918196],
                [-13.688471999999877, 9.917084000000159],
                [-13.689029, 9.915693],
                [-13.68986, 9.915139000000181],
                [-13.690417, 9.914027],
                [-13.690417, 9.913471000000186],
                [-13.690972999999872, 9.912917],
                [-13.690972999999872, 9.91208300000011],
                [-13.691527, 9.911527],
                [-13.691527, 9.908473000000185],
                [-13.69180699999987, 9.907639000000131],
                [-13.691251, 9.907361],
                [-13.690972999999872, 9.906251],
                [-13.690138999999874, 9.905973000000131],
                [-13.690138999999874, 9.905417000000114],
                [-13.691251, 9.904861],
                [-13.691527, 9.904304],
                [-13.692083, 9.904029],
                [-13.692361, 9.903194],
                [-13.692361, 9.902085000000113],
                [-13.692638999999872, 9.902085000000113],
                [-13.692638999999872, 9.900416],
                [-13.69347299999987, 9.89875],
                [-13.693751, 9.89875],
                [-13.693751, 9.89764],
                [-13.694029, 9.89764],
                [-13.694029, 9.895138000000145],
                [-13.693751, 9.894862],
                [-13.692638999999872, 9.894862],
                [-13.69180699999987, 9.895694000000162],
                [-13.69180699999987, 9.896250000000123],
                [-13.691251, 9.896528],
                [-13.691251, 9.897084],
                [-13.690417, 9.897360000000162],
                [-13.690417, 9.897916000000123],
                [-13.689585, 9.89847200000014],
                [-13.689029, 9.89847200000014],
                [-13.688751, 9.8990280000001],
                [-13.687916, 9.8990280000001],
                [-13.687359999999899, 9.899862],
                [-13.686805999999876, 9.90013800000014],
                [-13.686528, 9.900694000000101],
                [-13.685416, 9.900694000000101],
                [-13.684862, 9.900972],
                [-13.684027999999898, 9.901806000000136],
                [-13.684027999999898, 9.902360000000101],
                [-13.683471999999881, 9.902360000000101],
                [-13.683194, 9.902916000000118],
                [-13.682639999999878, 9.903194],
                [-13.682639999999878, 9.903751000000113],
                [-13.681805999999881, 9.904029],
                [-13.681528, 9.904582000000119],
                [-13.680971999999883, 9.904582000000119],
                [-13.680971999999883, 9.905417000000114],
                [-13.680694, 9.906251],
                [-13.680137999999886, 9.906527],
                [-13.680137999999886, 9.907083000000114],
                [-13.679584, 9.907361],
                [-13.679584, 9.908473000000185],
                [-13.679305999999883, 9.908473000000185],
                [-13.679305999999883, 9.912917],
                [-13.679584, 9.913471000000186],
                [-13.680418, 9.91374900000011],
                [-13.680418, 9.914305],
                [-13.680971999999883, 9.914583],
                [-13.680971999999883, 9.915417000000105],
                [-13.681528, 9.915693],
                [-13.681528, 9.916527],
                [-13.682084, 9.916805000000181],
                [-13.682084, 9.918196],
                [-13.682639999999878, 9.918472],
                [-13.682916, 9.9195840000001],
                [-13.683471999999881, 9.919862],
                [-13.683471999999881, 9.921528],
                [-13.684027999999898, 9.921806],
                [-13.684027999999898, 9.922362],
              ],
            ],
            [
              [
                [-13.937917, 9.99430500000011],
                [-13.93736099999984, 9.995417],
                [-13.93736099999984, 9.99597400000016],
                [-13.936805, 9.99597400000016],
                [-13.936805, 9.997362],
                [-13.937083, 9.99764000000016],
                [-13.937917, 9.99764000000016],
                [-13.938194999999837, 9.997362],
                [-13.938194999999837, 9.996249],
                [-13.938751, 9.996249],
                [-13.938751, 9.99430500000011],
                [-13.937917, 9.99430500000011],
              ],
            ],
            [
              [
                [-13.924583999999868, 10.010973],
                [-13.924583999999868, 10.01125100000013],
                [-13.923472, 10.01125100000013],
                [-13.92375, 10.012082000000134],
                [-13.924583999999868, 10.012082000000134],
                [-13.925138, 10.01236],
                [-13.925972, 10.01236],
                [-13.926528, 10.011529],
                [-13.926249999999868, 10.01125100000013],
                [-13.925138, 10.01125100000013],
                [-13.924583999999868, 10.010973],
              ],
            ],
            [
              [
                [-14.017639, 10.066807],
                [-14.017916999999898, 10.067917],
                [-14.018750999999895, 10.068195],
                [-14.019027, 10.068751],
                [-14.019861, 10.068751],
                [-14.020973, 10.067917],
                [-14.020973, 10.06652600000018],
                [-14.021527, 10.065973],
                [-14.021527, 10.06486000000018],
                [-14.021805, 10.064028000000121],
                [-14.022360999999819, 10.06375],
                [-14.022639, 10.06319400000018],
                [-14.022639, 10.062360000000126],
                [-14.024026999999819, 10.06152800000018],
                [-14.025696, 10.060972],
                [-14.025696, 10.060694000000126],
                [-14.027083999999888, 10.060694000000126],
                [-14.027917999999886, 10.060972],
                [-14.028472, 10.06152800000018],
                [-14.029306, 10.06152800000018],
                [-14.029583999999886, 10.062082],
                [-14.030415999999889, 10.062360000000126],
                [-14.030415999999889, 10.062916],
                [-14.030972, 10.063472000000104],
                [-14.031528, 10.063472000000104],
                [-14.032083999999884, 10.064304],
                [-14.032083999999884, 10.065138000000104],
                [-14.032362, 10.065695000000176],
                [-14.032915999999886, 10.065695000000176],
                [-14.033194, 10.066251],
                [-14.035138, 10.066251],
                [-14.035694, 10.065973],
                [-14.035972, 10.065416],
                [-14.036528, 10.065416],
                [-14.036804, 10.06486000000018],
                [-14.03736, 10.06486000000018],
                [-14.038472, 10.064304],
                [-14.038751, 10.06375],
                [-14.039307, 10.06375],
                [-14.040417, 10.06319400000018],
                [-14.041251, 10.062916],
                [-14.041805, 10.062360000000126],
                [-14.043749, 10.062360000000126],
                [-14.043749, 10.062082],
                [-14.045694999999853, 10.062082],
                [-14.045694999999853, 10.06152800000018],
                [-14.046526999999855, 10.06152800000018],
                [-14.047083, 10.062360000000126],
                [-14.052362, 10.062360000000126],
                [-14.052362, 10.062082],
                [-14.053471, 10.062082],
                [-14.05375, 10.061806000000104],
                [-14.05375, 10.060972],
                [-14.054028, 10.060416],
                [-14.055138, 10.059584],
                [-14.055418, 10.05875],
                [-14.055418, 10.057084],
                [-14.055972, 10.055972],
                [-14.05625, 10.055138000000113],
                [-14.057084, 10.053472000000113],
                [-14.057639999999822, 10.053193000000135],
                [-14.057639999999822, 10.05264],
                [-14.058194, 10.05236200000013],
                [-14.058194, 10.051805],
                [-14.05875, 10.051249],
                [-14.059305999999822, 10.051249],
                [-14.059584, 10.05069600000013],
                [-14.060137999999824, 10.05069600000013],
                [-14.060693999999899, 10.050139],
                [-14.060693999999899, 10.047639000000117],
                [-14.059584, 10.04652700000014],
                [-14.059305999999822, 10.045139],
                [-14.061527999999896, 10.047361],
                [-14.061527999999896, 10.047917],
                [-14.062084, 10.048473],
                [-14.062916, 10.048473],
                [-14.062916, 10.047083000000157],
                [-14.063193999999896, 10.047083000000157],
                [-14.063193999999896, 10.045139],
                [-14.063471999999877, 10.045139],
                [-14.063471999999877, 10.043473],
                [-14.063193999999896, 10.043473],
                [-14.063193999999896, 10.041805],
                [-14.062916, 10.041805],
                [-14.062916, 10.040417000000161],
                [-14.062637999999822, 10.040417000000161],
                [-14.062637999999822, 10.039029],
                [-14.062359999999899, 10.039029],
                [-14.062359999999899, 10.03736],
                [-14.062084, 10.03736],
                [-14.062084, 10.035972],
                [-14.061805999999876, 10.035972],
                [-14.061805999999876, 10.034862],
                [-14.06125, 10.034306],
                [-14.06125, 10.033194],
                [-14.060693999999899, 10.03291600000017],
                [-14.060416, 10.031806000000188],
                [-14.059861999999896, 10.031528],
                [-14.059861999999896, 10.030972],
                [-14.059305999999822, 10.030694],
                [-14.05875, 10.02958400000017],
                [-14.05875, 10.029028],
                [-14.058194, 10.029028],
                [-14.058194, 10.028472],
                [-14.057639999999822, 10.028472],
                [-14.057639999999822, 10.02791800000017],
                [-14.057084, 10.02791800000017],
                [-14.05625, 10.026806],
                [-14.05625, 10.026250000000175],
                [-14.055138, 10.025971],
                [-14.055138, 10.025415],
                [-14.054584, 10.025415],
                [-14.054584, 10.024862],
                [-14.054028, 10.024862],
                [-14.05375, 10.024305],
                [-14.053193, 10.024305],
                [-14.052918, 10.023749],
                [-14.050695, 10.023193],
                [-14.050695, 10.022639],
                [-14.049583, 10.022361000000103],
                [-14.049583, 10.021805],
                [-14.049026999999853, 10.021805],
                [-14.048195, 10.020695000000103],
                [-14.047360999999853, 10.020695000000103],
                [-14.046805, 10.019861],
                [-14.045971, 10.019861],
                [-14.045417, 10.019583000000125],
                [-14.045417, 10.019027000000108],
                [-14.044305, 10.018751],
                [-14.044027, 10.018195],
                [-14.043473, 10.018195],
                [-14.042639, 10.017083],
                [-14.042083, 10.017083],
                [-14.041805, 10.015973],
                [-14.040695, 10.015695000000107],
                [-14.040695, 10.015139000000147],
                [-14.040139, 10.015139000000147],
                [-14.039029, 10.014305],
                [-14.038751, 10.013751],
                [-14.038194, 10.013473000000147],
                [-14.03736, 10.013473000000147],
                [-14.03736, 10.01236],
                [-14.036804, 10.011807000000147],
                [-14.03625, 10.011807000000147],
                [-14.03625, 10.01125100000013],
                [-14.035694, 10.01125100000013],
                [-14.034862, 10.010416000000134],
                [-14.034306, 10.010138],
                [-14.034028, 10.009028],
                [-14.033194, 10.008750000000134],
                [-14.033194, 10.008194000000117],
                [-14.032083999999884, 10.007916],
                [-14.032083999999884, 10.007362],
                [-14.031528, 10.007362],
                [-14.031528, 10.006806],
                [-14.030693999999869, 10.006528000000117],
                [-14.030693999999869, 10.005972000000156],
                [-14.029862, 10.005696],
                [-14.029862, 10.00514],
                [-14.029306, 10.004860000000178],
                [-14.029306, 10.004306000000156],
                [-14.028749999999889, 10.004028],
                [-14.028474, 10.002918],
                [-14.027917999999886, 10.002638000000161],
                [-14.027917999999886, 10.001806],
                [-14.027362, 10.00125],
                [-14.027083999999888, 10.000418000000138],
                [-14.027083999999888, 9.999584],
                [-14.026248999999893, 9.999584],
                [-14.025696, 10.000694],
                [-14.025414999999896, 10.001806],
                [-14.024860999999817, 10.002084000000139],
                [-14.024860999999817, 10.002638000000161],
                [-14.024305, 10.002918],
                [-14.024026999999819, 10.003472],
                [-14.024026999999819, 10.004860000000178],
                [-14.024860999999817, 10.004860000000178],
                [-14.024860999999817, 10.006806],
                [-14.024582999999893, 10.006806],
                [-14.024582999999893, 10.008194000000117],
                [-14.024305, 10.008194000000117],
                [-14.024305, 10.009306000000151],
                [-14.023748999999896, 10.010694],
                [-14.023194999999816, 10.01125100000013],
                [-14.022639, 10.01125100000013],
                [-14.021527, 10.01236],
                [-14.021527, 10.01291700000013],
                [-14.020694999999819, 10.014027000000112],
                [-14.020139, 10.014027000000112],
                [-14.020139, 10.01458300000013],
                [-14.019305, 10.015417],
                [-14.018750999999895, 10.015417],
                [-14.018750999999895, 10.015973],
                [-14.017916999999898, 10.015973],
                [-14.017916999999898, 10.016529],
                [-14.017083, 10.016529],
                [-14.016805, 10.017083],
                [-14.015973, 10.017361000000108],
                [-14.014861, 10.017917000000125],
                [-14.014026, 10.018195],
                [-14.014026, 10.018751],
                [-14.013473, 10.019305],
                [-14.013473, 10.021805],
                [-14.013751, 10.022639],
                [-14.014305, 10.02291700000012],
                [-14.015139, 10.024027000000103],
                [-14.015417, 10.02458300000012],
                [-14.016805, 10.025971],
                [-14.016805, 10.026528],
                [-14.017361, 10.027084000000116],
                [-14.018195, 10.027638],
                [-14.019027, 10.028750000000116],
                [-14.019027, 10.029306],
                [-14.019582999999898, 10.02958400000017],
                [-14.020416999999895, 10.031806000000188],
                [-14.020416999999895, 10.033194],
                [-14.020694999999819, 10.033194],
                [-14.020694999999819, 10.035972],
                [-14.020416999999895, 10.035972],
                [-14.020139, 10.036804000000188],
                [-14.019582999999898, 10.037916000000166],
                [-14.019582999999898, 10.038473],
                [-14.017361, 10.040695],
                [-14.017361, 10.041251],
                [-14.016805, 10.041251],
                [-14.016805, 10.042083000000162],
                [-14.016249, 10.042361],
                [-14.016249, 10.04486100000014],
                [-14.016805, 10.045417000000157],
                [-14.016805, 10.04652700000014],
                [-14.017083, 10.04652700000014],
                [-14.017083, 10.048749000000157],
                [-14.016249, 10.050418],
                [-14.014861, 10.051249],
                [-14.012638, 10.051249],
                [-14.012638, 10.050971000000118],
                [-14.011529, 10.050971000000118],
                [-14.010972, 10.05069600000013],
                [-14.0104189999999, 10.050139],
                [-14.009583999999847, 10.050139],
                [-14.009028, 10.049861000000135],
                [-14.00875, 10.049305000000118],
                [-14.007916, 10.049305000000118],
                [-14.007638, 10.048749000000157],
                [-14.005694, 10.048749000000157],
                [-14.005416, 10.049027],
                [-14.005416, 10.050418],
                [-14.005694, 10.050971000000118],
                [-14.00625, 10.051249],
                [-14.00625, 10.051805],
                [-14.006806, 10.052084],
                [-14.006806, 10.05264],
                [-14.007638, 10.054306],
                [-14.008194, 10.054584],
                [-14.008472, 10.05569400000013],
                [-14.009028, 10.05569400000013],
                [-14.009306, 10.05625],
                [-14.010694, 10.05625],
                [-14.010972, 10.05569400000013],
                [-14.011807, 10.05569400000013],
                [-14.01208199999985, 10.055138000000113],
                [-14.012638, 10.055138000000113],
                [-14.012917, 10.054584],
                [-14.013473, 10.054306],
                [-14.014861, 10.054306],
                [-14.015417, 10.05375],
                [-14.017639, 10.05375],
                [-14.018195, 10.054306],
                [-14.018750999999895, 10.054306],
                [-14.019027, 10.054862],
                [-14.019582999999898, 10.055138000000113],
                [-14.020694999999819, 10.05736000000013],
                [-14.020694999999819, 10.058194],
                [-14.020139, 10.05875],
                [-14.020139, 10.059584],
                [-14.019582999999898, 10.060138000000109],
                [-14.019582999999898, 10.060694000000126],
                [-14.018750999999895, 10.06125],
                [-14.018473, 10.061806000000104],
                [-14.017916999999898, 10.062360000000126],
                [-14.017916999999898, 10.062916],
                [-14.017361, 10.06319400000018],
                [-14.017083, 10.064028000000121],
                [-14.017083, 10.065695000000176],
                [-14.017361, 10.065695000000176],
                [-14.017361, 10.066807],
                [-14.017639, 10.066807],
              ],
            ],
            [
              [
                [-14.026248999999893, 10.105971000000181],
                [-14.027083999999888, 10.106249000000105],
                [-14.027083999999888, 10.106528],
                [-14.029862, 10.106528],
                [-14.030693999999869, 10.106249000000105],
                [-14.031249999999886, 10.105696],
                [-14.031249999999886, 10.105140000000176],
                [-14.031806, 10.104861],
                [-14.032362, 10.103749],
                [-14.032362, 10.103193],
                [-14.032915999999886, 10.102917000000105],
                [-14.032915999999886, 10.100973000000181],
                [-14.033194, 10.100973000000181],
                [-14.033194, 10.099305000000186],
                [-14.033472, 10.099305000000186],
                [-14.033472, 10.097639000000186],
                [-14.033749999999884, 10.097639000000186],
                [-14.033749999999884, 10.096249000000114],
                [-14.034028, 10.095417],
                [-14.034581999999887, 10.094861],
                [-14.034581999999887, 10.094026],
                [-14.034862, 10.093195],
                [-14.035415999999884, 10.092638000000136],
                [-14.035415999999884, 10.091804],
                [-14.036528, 10.089584],
                [-14.037084, 10.089306000000136],
                [-14.037084, 10.088750000000118],
                [-14.037638, 10.088472],
                [-14.037638, 10.087916],
                [-14.038194, 10.08763800000014],
                [-14.038194, 10.087082000000123],
                [-14.038751, 10.086806],
                [-14.038751, 10.08597200000014],
                [-14.039307, 10.08597200000014],
                [-14.039307, 10.085416000000123],
                [-14.03986, 10.08514],
                [-14.040139, 10.084028],
                [-14.040695, 10.083750000000123],
                [-14.040695, 10.083194000000162],
                [-14.041251, 10.082916],
                [-14.041528999999855, 10.081806],
                [-14.042083, 10.081528000000162],
                [-14.042361, 10.080972000000145],
                [-14.042361, 10.08014],
                [-14.042917, 10.079862000000162],
                [-14.043194999999855, 10.079306000000145],
                [-14.043194999999855, 10.07847100000015],
                [-14.043749, 10.078196000000162],
                [-14.043749, 10.077639],
                [-14.044305, 10.077083],
                [-14.044305, 10.076249000000189],
                [-14.044860999999855, 10.076249000000189],
                [-14.044860999999855, 10.075417],
                [-14.045417, 10.074305],
                [-14.045694999999853, 10.073471],
                [-14.046251, 10.072917000000189],
                [-14.046526999999855, 10.072361000000171],
                [-14.046526999999855, 10.071527],
                [-14.047083, 10.070973],
                [-14.047083, 10.070139],
                [-14.047639, 10.069583],
                [-14.047639, 10.068195],
                [-14.046805, 10.067639],
                [-14.045971, 10.067641],
                [-14.045417, 10.067083],
                [-14.044583, 10.067085],
                [-14.044305, 10.065973],
                [-14.043749, 10.065416],
                [-14.042639, 10.065416],
                [-14.042639, 10.065695000000176],
                [-14.040973, 10.065695000000176],
                [-14.040417, 10.066251],
                [-14.039307, 10.06652600000018],
                [-14.039029, 10.067085],
                [-14.038472, 10.067083],
                [-14.038194, 10.067641],
                [-14.03736, 10.067639],
                [-14.036804, 10.068195],
                [-14.034306, 10.068195],
                [-14.034306, 10.067917],
                [-14.033194, 10.067917],
                [-14.032362, 10.067641],
                [-14.031249999999886, 10.067085],
                [-14.031249999999886, 10.066529],
                [-14.030693999999869, 10.066529],
                [-14.03014, 10.065973],
                [-14.029583999999886, 10.065695000000176],
                [-14.028749999999889, 10.06486000000018],
                [-14.028474, 10.06375],
                [-14.027637, 10.063472000000104],
                [-14.027637, 10.062916],
                [-14.027083999999888, 10.062360000000126],
                [-14.026527999999871, 10.062638],
                [-14.02514, 10.062638],
                [-14.024582999999893, 10.06319400000018],
                [-14.024026999999819, 10.064304],
                [-14.024026999999819, 10.065416],
                [-14.023748999999896, 10.065416],
                [-14.023748999999896, 10.066529],
                [-14.022916999999893, 10.068473],
                [-14.022360999999819, 10.068751],
                [-14.022082999999895, 10.069583],
                [-14.021248999999898, 10.069861],
                [-14.018473, 10.069861],
                [-14.017916999999898, 10.069305],
                [-14.017083, 10.069305],
                [-14.016529, 10.068473],
                [-14.015973, 10.067361000000176],
                [-14.015973, 10.065973],
                [-14.015695, 10.065973],
                [-14.015695, 10.064304],
                [-14.015973, 10.063472000000104],
                [-14.016529, 10.062916],
                [-14.016529, 10.062082],
                [-14.017916999999898, 10.059306],
                [-14.018471, 10.059028000000126],
                [-14.018750999999895, 10.058472000000108],
                [-14.018750999999895, 10.05736000000013],
                [-14.019027, 10.056528],
                [-14.018471, 10.055972],
                [-14.017361, 10.055138000000113],
                [-14.016805, 10.054862],
                [-14.015695, 10.054862],
                [-14.015695, 10.055138000000113],
                [-14.014583, 10.055138000000113],
                [-14.014583, 10.055418],
                [-14.013473, 10.056528],
                [-14.012638, 10.056528],
                [-14.011807, 10.056806000000108],
                [-14.011249999999848, 10.05736000000013],
                [-14.008472, 10.05736000000013],
                [-14.007362, 10.056528],
                [-14.006528, 10.05569400000013],
                [-14.00625, 10.055138000000113],
                [-14.005694, 10.054584],
                [-14.00514, 10.053472000000113],
                [-14.005138, 10.052915],
                [-14.004584, 10.05264],
                [-14.004306, 10.051805],
                [-14.004306, 10.05069600000013],
                [-14.004028, 10.050139],
                [-14.004584, 10.049027],
                [-14.005972, 10.048195000000135],
                [-14.00625, 10.047639000000117],
                [-14.009028, 10.047639000000117],
                [-14.009028, 10.047917],
                [-14.010138, 10.048473],
                [-14.010694, 10.048473],
                [-14.011249999999848, 10.049305000000118],
                [-14.01236, 10.049305000000118],
                [-14.012638, 10.050139],
                [-14.014861, 10.050139],
                [-14.015695, 10.049305000000118],
                [-14.015973, 10.048749000000157],
                [-14.015973, 10.046805],
                [-14.015695, 10.046805],
                [-14.015695, 10.045695],
                [-14.015139, 10.045417000000157],
                [-14.015139, 10.044583],
                [-14.014305, 10.044305000000122],
                [-14.014305, 10.040973000000122],
                [-14.015139, 10.040695],
                [-14.015417, 10.040139],
                [-14.015973, 10.040139],
                [-14.017083, 10.039029],
                [-14.017639, 10.037916000000166],
                [-14.017639, 10.037085000000161],
                [-14.018195, 10.036528],
                [-14.018195, 10.035416],
                [-14.018471, 10.035416],
                [-14.018473, 10.034028],
                [-14.018750999999895, 10.034028],
                [-14.018750999999895, 10.032638],
                [-14.018195, 10.032084],
                [-14.018195, 10.031528],
                [-14.017639, 10.03125000000017],
                [-14.017639, 10.030694],
                [-14.016805, 10.030140000000188],
                [-14.016805, 10.02958400000017],
                [-14.016249, 10.029306],
                [-14.015139, 10.028194],
                [-14.015139, 10.02764],
                [-14.014305, 10.027362],
                [-14.013751, 10.026250000000175],
                [-14.013195, 10.026250000000175],
                [-14.013195, 10.025696],
                [-14.012638, 10.025415],
                [-14.012638, 10.024862],
                [-14.01208199999985, 10.024862],
                [-14.011807, 10.024027000000103],
                [-14.011249999999848, 10.023749],
                [-14.011249999999848, 10.023193],
                [-14.010694, 10.02291700000012],
                [-14.010694, 10.022361000000103],
                [-14.010138, 10.022083],
                [-14.00986, 10.021249000000125],
                [-14.009863, 10.020139],
                [-14.009583999999847, 10.020139],
                [-14.009583999999847, 10.019027000000108],
                [-14.009306, 10.019027000000108],
                [-14.009306, 10.017083],
                [-14.009583999999847, 10.017083],
                [-14.009583999999847, 10.015139000000147],
                [-14.009863, 10.015139000000147],
                [-14.009863, 10.01291700000013],
                [-14.010138, 10.01291700000013],
                [-14.010138, 10.011807000000147],
                [-14.010694, 10.01125100000013],
                [-14.010694, 10.010416000000134],
                [-14.011249999999848, 10.008750000000134],
                [-14.011807, 10.008750000000134],
                [-14.012638, 10.007916],
                [-14.013195, 10.007638000000156],
                [-14.013195, 10.007082000000139],
                [-14.013751, 10.007082000000139],
                [-14.014026, 10.006528000000117],
                [-14.014583, 10.006528000000117],
                [-14.015695, 10.005972000000156],
                [-14.015973, 10.00514],
                [-14.017083, 10.004584],
                [-14.017083, 10.002362],
                [-14.016805, 10.002362],
                [-14.016249, 10.00125],
                [-14.015695, 10.000418000000138],
                [-14.014861, 10.000418000000138],
                [-14.014861, 9.99930600000016],
                [-14.014305, 9.99930600000016],
                [-14.014026, 9.998749],
                [-14.013473, 9.998749],
                [-14.01236, 9.99764000000016],
                [-14.01236, 9.997083],
                [-14.011807, 9.997083],
                [-14.011529, 9.996527],
                [-14.01041599999985, 9.996527],
                [-14.00986, 9.99597400000016],
                [-14.009583999999847, 9.995417],
                [-14.00875, 9.995417],
                [-14.008472, 9.994861],
                [-14.007916, 9.994861],
                [-14.007916, 9.99430500000011],
                [-14.006806, 9.993749],
                [-14.006806, 9.993195],
                [-14.005972, 9.992917],
                [-14.004584, 9.991527],
                [-14.004306, 9.990417],
                [-14.003472, 9.99013900000017],
                [-14.003472, 9.989583],
                [-14.00264, 9.989583],
                [-14.002084, 9.989305000000115],
                [-14.001806, 9.98847300000017],
                [-14.000694, 9.98847300000017],
                [-14.000418, 9.987639000000115],
                [-13.999584, 9.987639000000115],
                [-13.999027, 9.987361],
                [-13.998749, 9.98680700000017],
                [-13.997638999999879, 9.986526],
                [-13.996527, 9.985973000000115],
                [-13.996249, 9.985417],
                [-13.995138999999881, 9.98486],
                [-13.995138999999881, 9.984304],
                [-13.994304999999883, 9.984304],
                [-13.992361, 9.98347200000012],
                [-13.991804999999886, 9.982638],
                [-13.990695, 9.982638],
                [-13.989861, 9.982360000000142],
                [-13.989583, 9.98180600000012],
                [-13.989029, 9.98180600000012],
                [-13.988472999999885, 9.980972],
                [-13.987361, 9.980972],
                [-13.987082999999814, 9.980416],
                [-13.986526, 9.980138000000125],
                [-13.985137999999893, 9.980138000000125],
                [-13.985137999999893, 9.979584000000102],
                [-13.984029, 9.979584000000102],
                [-13.984029, 9.979306],
                [-13.982916, 9.979306],
                [-13.982916, 9.979028000000142],
                [-13.981805999999892, 9.979028000000142],
                [-13.980971999999895, 9.97875],
                [-13.980416, 9.978194],
                [-13.979305999999895, 9.978194],
                [-13.979305999999895, 9.977916000000107],
                [-13.97625, 9.977916000000107],
                [-13.974862, 9.977360000000147],
                [-13.974584, 9.976806000000124],
                [-13.97375, 9.976528],
                [-13.972639999999842, 9.976528],
                [-13.972639999999842, 9.976250000000107],
                [-13.969305, 9.976250000000107],
                [-13.969307999999842, 9.975972],
                [-13.968193, 9.975972],
                [-13.968193, 9.975418],
                [-13.966805, 9.975418],
                [-13.966251, 9.975972],
                [-13.965139, 9.976250000000107],
                [-13.964861, 9.976806000000124],
                [-13.962639, 9.976806000000124],
                [-13.962083, 9.977360000000147],
                [-13.960139, 9.977360000000147],
                [-13.960139, 9.977084],
                [-13.958473, 9.977084],
                [-13.958473, 9.976806000000124],
                [-13.952916, 9.976806000000124],
                [-13.952916, 9.976528],
                [-13.949584, 9.976528],
                [-13.949584, 9.976250000000107],
                [-13.947361999999885, 9.976250000000107],
                [-13.947361999999885, 9.975972],
                [-13.945415, 9.975972],
                [-13.945415, 9.975694000000146],
                [-13.943471, 9.975694000000146],
                [-13.943471, 9.975418],
                [-13.941249, 9.975418],
                [-13.940139, 9.975972],
                [-13.939305, 9.977360000000147],
                [-13.939305, 9.978472000000124],
                [-13.93902699999984, 9.978472000000124],
                [-13.939583, 9.980138000000125],
                [-13.939583, 9.980972],
                [-13.939860999999837, 9.981528],
                [-13.940417, 9.98180600000012],
                [-13.940417, 9.982360000000142],
                [-13.940973, 9.982638],
                [-13.940973, 9.983751],
                [-13.941526999999837, 9.984029],
                [-13.942083, 9.985417],
                [-13.942083, 9.990417],
                [-13.941805, 9.990417],
                [-13.941805, 9.99180500000017],
                [-13.941526999999837, 9.99180500000017],
                [-13.941526999999837, 9.993195],
                [-13.941249, 9.993195],
                [-13.941249, 9.994583],
                [-13.940973, 9.994583],
                [-13.940973, 9.996249],
                [-13.940694999999891, 9.997083],
                [-13.940139, 9.99764000000016],
                [-13.940139, 9.998471000000166],
                [-13.939583, 9.999028],
                [-13.939583, 10.000418000000138],
                [-13.93902699999984, 10.000694],
                [-13.938751, 10.00125],
                [-13.938194999999837, 10.001806],
                [-13.938194999999837, 10.002638000000161],
                [-13.937083, 10.004860000000178],
                [-13.936528999999837, 10.00514],
                [-13.936528999999837, 10.005696],
                [-13.935973, 10.005694],
                [-13.93569499999984, 10.006806],
                [-13.935139, 10.007082000000139],
                [-13.934861, 10.008194000000117],
                [-13.934305, 10.008194000000117],
                [-13.934305, 10.009028],
                [-13.934027, 10.00958500000013],
                [-13.933473, 10.009860000000117],
                [-13.933473, 10.010416000000134],
                [-13.932917, 10.010416000000134],
                [-13.932917, 10.010973],
                [-13.93236, 10.01125100000013],
                [-13.93236, 10.011807000000147],
                [-13.931251, 10.012082000000134],
                [-13.931251, 10.012639],
                [-13.930138, 10.013195],
                [-13.929306, 10.013473000000147],
                [-13.928194, 10.013473000000147],
                [-13.928194, 10.013751],
                [-13.92708199999987, 10.013751],
                [-13.92708199999987, 10.014027000000112],
                [-13.920694, 10.014027000000112],
                [-13.92014, 10.013473000000147],
                [-13.914027, 10.013473000000147],
                [-13.914027, 10.013751],
                [-13.912361, 10.013751],
                [-13.911805, 10.01458300000013],
                [-13.910972999999899, 10.014861],
                [-13.910972999999899, 10.016805000000147],
                [-13.91125099999988, 10.016805000000147],
                [-13.91125099999988, 10.017917000000125],
                [-13.911805, 10.018471000000147],
                [-13.91291699999988, 10.018751],
                [-13.913195, 10.019305],
                [-13.913748999999882, 10.019305],
                [-13.913748999999882, 10.019861],
                [-13.914861, 10.020139],
                [-13.915139, 10.020695000000103],
                [-13.91764, 10.020695000000103],
                [-13.918196, 10.020417],
                [-13.918471, 10.019583000000125],
                [-13.919583999999873, 10.019305],
                [-13.919862, 10.018751],
                [-13.920694, 10.018473],
                [-13.921806, 10.018471000000147],
                [-13.921806, 10.018195],
                [-13.92375, 10.018195],
                [-13.925138, 10.018751],
                [-13.925694, 10.019583000000125],
                [-13.926249999999868, 10.019861],
                [-13.92708199999987, 10.020695000000103],
                [-13.927362, 10.020695000000103],
                [-13.927915999999868, 10.021805],
                [-13.928472, 10.023471],
                [-13.928194, 10.024305],
                [-13.928472, 10.02514],
                [-13.928472, 10.032084],
                [-13.928194, 10.032084],
                [-13.928194, 10.033194],
                [-13.928472, 10.033194],
                [-13.92875, 10.034028],
                [-13.92875, 10.035138000000188],
                [-13.929028, 10.035138000000188],
                [-13.929028, 10.039029],
                [-13.929306, 10.039861000000144],
                [-13.930416, 10.040973000000122],
                [-13.931529, 10.040973000000122],
                [-13.931529, 10.041251],
                [-13.932639, 10.041251],
                [-13.932639, 10.04152900000014],
                [-13.934027, 10.04152900000014],
                [-13.934305, 10.040973000000122],
                [-13.934305, 10.039582000000166],
                [-13.934027, 10.039582000000166],
                [-13.933473, 10.038473],
                [-13.933473, 10.035694],
                [-13.933751, 10.035138000000188],
                [-13.934305, 10.03486],
                [-13.934305, 10.034028],
                [-13.934583, 10.033472000000188],
                [-13.935139, 10.033194],
                [-13.935417, 10.03236],
                [-13.936249, 10.032084],
                [-13.936249, 10.03125000000017],
                [-13.936805, 10.030972],
                [-13.936805, 10.030416000000116],
                [-13.93736099999984, 10.029862],
                [-13.93736099999984, 10.029028],
                [-13.937639, 10.028472],
                [-13.938194999999837, 10.028194],
                [-13.938471, 10.027638],
                [-13.938471, 10.026806],
                [-13.939305, 10.02514],
                [-13.939583, 10.024305],
                [-13.940139, 10.023749],
                [-13.940694999999891, 10.023749],
                [-13.940973, 10.023193],
                [-13.941805, 10.022361000000103],
                [-13.942360999999892, 10.022083],
                [-13.944305, 10.022083],
                [-13.944305, 10.021805],
                [-13.945415, 10.021805],
                [-13.945415, 10.022083],
                [-13.946527999999887, 10.022639],
                [-13.947361999999885, 10.023471],
                [-13.948472, 10.024027000000103],
                [-13.949027999999885, 10.024027000000103],
                [-13.949027999999885, 10.02458300000012],
                [-13.949584, 10.024862],
                [-13.949584, 10.025415],
                [-13.950693999999885, 10.025696],
                [-13.950693999999885, 10.026250000000175],
                [-13.951527999999882, 10.027084000000116],
                [-13.952084, 10.027362],
                [-13.952916, 10.027362],
                [-13.953472, 10.02791800000017],
                [-13.95375, 10.028750000000116],
                [-13.95375, 10.029862],
                [-13.95402799999988, 10.029862],
                [-13.95402799999988, 10.033194],
                [-13.95375, 10.033194],
                [-13.95375, 10.036804000000188],
                [-13.954582, 10.03736],
                [-13.954582, 10.037916000000166],
                [-13.955416, 10.038751000000161],
                [-13.957085, 10.039582000000166],
                [-13.958193999999878, 10.039582000000166],
                [-13.958193999999878, 10.039861000000144],
                [-13.959307, 10.039861000000144],
                [-13.959307, 10.040139],
                [-13.960973, 10.040139],
                [-13.960973, 10.040417000000161],
                [-13.962917, 10.040417000000161],
                [-13.962917, 10.040695],
                [-13.964305, 10.040695],
                [-13.964305, 10.040973000000122],
                [-13.965971, 10.040973000000122],
                [-13.965971, 10.041251],
                [-13.966805, 10.04152900000014],
                [-13.968749, 10.04152900000014],
                [-13.969583, 10.041805],
                [-13.969583, 10.042083000000162],
                [-13.970971, 10.042083000000162],
                [-13.971805, 10.042361],
                [-13.972918, 10.042917],
                [-13.973193, 10.043473],
                [-13.974305999999842, 10.044027],
                [-13.974584, 10.045139],
                [-13.975137999999845, 10.045417000000157],
                [-13.975137999999845, 10.047361],
                [-13.974862, 10.047917],
                [-13.974305999999842, 10.048195000000135],
                [-13.97375, 10.049305000000118],
                [-13.97375, 10.052084],
                [-13.974028, 10.05264],
                [-13.974862, 10.05264],
                [-13.974862, 10.053193000000135],
                [-13.975418, 10.053193000000135],
                [-13.975418, 10.05375],
                [-13.97625, 10.05402800000013],
                [-13.976528, 10.054862],
                [-13.977084, 10.054862],
                [-13.977084, 10.055418],
                [-13.977916, 10.05569400000013],
                [-13.977916, 10.05625],
                [-13.978471999999897, 10.05625],
                [-13.978471999999897, 10.056806000000108],
                [-13.979305999999895, 10.057084],
                [-13.979305999999895, 10.05764],
                [-13.979862, 10.05764],
                [-13.979862, 10.058194],
                [-13.980694, 10.05875],
                [-13.980971999999895, 10.059306],
                [-13.980971999999895, 10.060138000000109],
                [-13.981528, 10.060138000000109],
                [-13.982084, 10.06125],
                [-13.982084, 10.062082],
                [-13.982637999999895, 10.062082],
                [-13.982637999999895, 10.062638],
                [-13.98375, 10.063472000000104],
                [-13.98375, 10.064304],
                [-13.984303999999895, 10.064304],
                [-13.984582, 10.06486000000018],
                [-13.985972999999888, 10.06486000000018],
                [-13.985972999999888, 10.065138000000104],
                [-13.986806999999885, 10.065416],
                [-13.987919, 10.065416],
                [-13.987917, 10.065695000000176],
                [-13.989583, 10.065695000000176],
                [-13.989861, 10.066251],
                [-13.990417, 10.066529],
                [-13.991527, 10.066529],
                [-13.991527, 10.067085],
                [-13.992083, 10.067361000000176],
                [-13.992917, 10.068195],
                [-13.993195, 10.068751],
                [-13.993749, 10.069029000000171],
                [-13.993749, 10.069583],
                [-13.994861, 10.070417],
                [-13.994861, 10.070973],
                [-13.995417, 10.071251000000188],
                [-13.995417, 10.072083],
                [-13.995972999999879, 10.072639],
                [-13.995972999999879, 10.073471],
                [-13.996527, 10.073471],
                [-13.996527, 10.074583000000189],
                [-13.997083, 10.07513900000015],
                [-13.997361, 10.075973],
                [-13.997361, 10.077083],
                [-13.997638999999879, 10.077083],
                [-13.997638999999879, 10.078749],
                [-13.997915, 10.078749],
                [-13.997915, 10.082084000000123],
                [-13.997638999999879, 10.082084000000123],
                [-13.997638999999879, 10.08597200000014],
                [-13.997915, 10.08597200000014],
                [-13.997915, 10.087082000000123],
                [-13.998470999999881, 10.088194000000158],
                [-13.999027, 10.088750000000118],
                [-13.999027, 10.089584],
                [-13.999306, 10.090138],
                [-13.999862, 10.090416000000118],
                [-13.999862, 10.09125],
                [-14.000694, 10.091529],
                [-14.000972, 10.09236],
                [-14.000972, 10.093195],
                [-14.001528, 10.093195],
                [-14.001806, 10.094307000000185],
                [-14.002362, 10.094583000000114],
                [-14.002362, 10.095139000000131],
                [-14.003194, 10.096529],
                [-14.00375, 10.096529],
                [-14.004028, 10.097083],
                [-14.005416, 10.097639000000186],
                [-14.006528, 10.097639000000186],
                [-14.006528, 10.097917000000109],
                [-14.007362, 10.098195],
                [-14.009306, 10.098195],
                [-14.009306, 10.098473000000126],
                [-14.010138, 10.098751],
                [-14.011529, 10.098751],
                [-14.012638, 10.09958300000011],
                [-14.013751, 10.09958300000011],
                [-14.014026, 10.100139000000127],
                [-14.014583, 10.100417],
                [-14.015417, 10.100417],
                [-14.015973, 10.100695],
                [-14.016249, 10.10124900000011],
                [-14.017361, 10.101805000000127],
                [-14.018195, 10.101805000000127],
                [-14.018195, 10.102361],
                [-14.018750999999895, 10.102361],
                [-14.019027, 10.102917000000105],
                [-14.019582999999898, 10.102917000000105],
                [-14.019861, 10.103471000000127],
                [-14.020973, 10.103749],
                [-14.021248999999898, 10.104305000000181],
                [-14.021805, 10.104583000000105],
                [-14.022639, 10.104583000000105],
                [-14.024582999999893, 10.105415],
                [-14.024582999999893, 10.105696],
                [-14.025414999999896, 10.105971000000181],
                [-14.026248999999893, 10.105971000000181],
              ],
            ],
            [
              [
                [-14.121251, 10.11236],
                [-14.121251, 10.112916000000155],
                [-14.11986, 10.114306],
                [-14.118749999999864, 10.114306],
                [-14.118472, 10.11375],
                [-14.117638, 10.113472000000172],
                [-14.117638, 10.112916000000155],
                [-14.117083999999863, 10.11236],
                [-14.116528, 10.112084],
                [-14.116528, 10.111528],
                [-14.115416, 10.111250000000155],
                [-14.115138, 10.110694],
                [-14.110696, 10.110694],
                [-14.110415999999873, 10.111250000000155],
                [-14.109862, 10.111250000000155],
                [-14.109862, 10.112638],
                [-14.10958399999987, 10.112638],
                [-14.10958399999987, 10.116804000000172],
                [-14.109306, 10.117360000000133],
                [-14.108194, 10.118194],
                [-14.108194, 10.11958200000015],
                [-14.10791799999987, 10.120417000000145],
                [-14.10791799999987, 10.121251],
                [-14.109028, 10.122361],
                [-14.109028, 10.122639000000163],
                [-14.109862, 10.122917],
                [-14.110696, 10.122917],
                [-14.113472, 10.120139],
                [-14.11375, 10.11958200000015],
                [-14.116804, 10.11958200000015],
                [-14.116806, 10.11986],
                [-14.117915999999866, 10.11986],
                [-14.118749999999864, 10.120139],
                [-14.119307, 10.120695],
                [-14.120138, 10.120695],
                [-14.120695, 10.120973000000163],
                [-14.120973, 10.121527000000128],
                [-14.122083, 10.121527000000128],
                [-14.122639, 10.122083000000146],
                [-14.123195, 10.122083000000146],
                [-14.123473, 10.122639000000163],
                [-14.124029, 10.122917],
                [-14.125139, 10.122917],
                [-14.125695, 10.123195000000123],
                [-14.125971, 10.123749000000146],
                [-14.126805, 10.124029],
                [-14.127917, 10.124029],
                [-14.128473, 10.124305000000163],
                [-14.129027, 10.124861000000124],
                [-14.129860999999835, 10.124861000000124],
                [-14.130139, 10.12541700000014],
                [-14.130694999999832, 10.125695],
                [-14.131526999999835, 10.125695],
                [-14.13264, 10.126805],
                [-14.132915, 10.127917],
                [-14.133471, 10.128473],
                [-14.133471, 10.13319300000012],
                [-14.133195999999884, 10.133750000000191],
                [-14.13264, 10.134306],
                [-14.13264, 10.135972],
                [-14.132360999999833, 10.135972],
                [-14.132360999999833, 10.13902800000011],
                [-14.132915, 10.139306],
                [-14.132915, 10.143750000000182],
                [-14.132360999999833, 10.144304],
                [-14.132360999999833, 10.145138],
                [-14.131805, 10.145416000000182],
                [-14.131805, 10.146251000000177],
                [-14.131249, 10.14736100000016],
                [-14.130417, 10.147917000000177],
                [-14.129305, 10.149029000000155],
                [-14.129305, 10.149583000000177],
                [-14.128194999999835, 10.1498610000001],
                [-14.128194999999835, 10.150417],
                [-14.127639, 10.150417],
                [-14.127361, 10.150973],
                [-14.126805, 10.150973],
                [-14.126805, 10.151527000000101],
                [-14.125971, 10.152639],
                [-14.125139, 10.152917000000173],
                [-14.125139, 10.153471],
                [-14.124583, 10.153751],
                [-14.124583, 10.154861],
                [-14.124305, 10.155417],
                [-14.124861, 10.156249000000173],
                [-14.125695, 10.155139000000133],
                [-14.126251, 10.154861],
                [-14.126527, 10.153751],
                [-14.127639, 10.153195],
                [-14.127639, 10.152639],
                [-14.128194999999835, 10.152639],
                [-14.128194999999835, 10.152083],
                [-14.129860999999835, 10.150417],
                [-14.130417, 10.150417],
                [-14.130417, 10.1498610000001],
                [-14.130971, 10.149863],
                [-14.131249, 10.149029000000155],
                [-14.132083, 10.149029000000155],
                [-14.13264, 10.148751],
                [-14.132915, 10.147917000000177],
                [-14.134027999999887, 10.147917000000177],
                [-14.134027999999887, 10.14736100000016],
                [-14.134584, 10.147082000000182],
                [-14.135418, 10.147082000000182],
                [-14.135972, 10.146807],
                [-14.136806, 10.145973],
                [-14.137361999999882, 10.145694000000105],
                [-14.137916, 10.14486],
                [-14.140693999999883, 10.14486],
                [-14.14152799999988, 10.144585000000177],
                [-14.14152799999988, 10.144304],
                [-14.14319399999988, 10.144304],
                [-14.143472, 10.143750000000182],
                [-14.144027999999878, 10.143750000000182],
                [-14.144584, 10.143194],
                [-14.145693999999878, 10.143194],
                [-14.148194999999873, 10.14236000000011],
                [-14.148194999999873, 10.142082000000187],
                [-14.149583, 10.142082000000187],
                [-14.150694999999871, 10.141528],
                [-14.150973, 10.140972],
                [-14.152083, 10.140972],
                [-14.152639, 10.140138],
                [-14.153473, 10.140138],
                [-14.153473, 10.139584],
                [-14.154583, 10.139306],
                [-14.154583, 10.138750000000186],
                [-14.155139, 10.138750000000186],
                [-14.155139, 10.138194],
                [-14.155973, 10.137916000000132],
                [-14.156251, 10.136806],
                [-14.156805, 10.136806],
                [-14.156805, 10.136250000000132],
                [-14.157361, 10.136250000000132],
                [-14.157917, 10.135694000000115],
                [-14.157917, 10.135138],
                [-14.159027, 10.134862],
                [-14.159305, 10.133750000000191],
                [-14.16014, 10.133471],
                [-14.16014, 10.132915],
                [-14.16125, 10.13264],
                [-14.161528, 10.13152700000012],
                [-14.162084, 10.13152700000012],
                [-14.162362, 10.130971000000102],
                [-14.162918, 10.130417000000136],
                [-14.163472, 10.130417000000136],
                [-14.16375, 10.129305000000102],
                [-14.16430599999984, 10.129027],
                [-14.16430599999984, 10.128473],
                [-14.16486, 10.128195000000119],
                [-14.16486, 10.127083000000141],
                [-14.165416, 10.127083000000141],
                [-14.165416, 10.126527000000124],
                [-14.16625, 10.126251],
                [-14.166805999999838, 10.125695],
                [-14.166805999999838, 10.125139],
                [-14.167362, 10.124861000000124],
                [-14.167362, 10.124029],
                [-14.168194, 10.123473],
                [-14.168471999999838, 10.122361],
                [-14.169305999999892, 10.122083000000146],
                [-14.16986, 10.120973000000163],
                [-14.16986, 10.120417000000145],
                [-14.170416, 10.120139],
                [-14.170416, 10.11958200000015],
                [-14.170971999999892, 10.118751000000145],
                [-14.171528, 10.118751000000145],
                [-14.171528, 10.118194],
                [-14.172082, 10.11791600000015],
                [-14.17236, 10.117360000000133],
                [-14.172916, 10.116806000000167],
                [-14.172916, 10.115694000000133],
                [-14.172637999999893, 10.115694000000133],
                [-14.17180599999989, 10.114862],
                [-14.17125, 10.11375],
                [-14.17125, 10.113194],
                [-14.170694, 10.112638],
                [-14.169028, 10.112638],
                [-14.168194, 10.112916000000155],
                [-14.16763799999984, 10.11375],
                [-14.167084, 10.114028000000133],
                [-14.163472, 10.114028000000133],
                [-14.162084, 10.112638],
                [-14.162084, 10.104305000000181],
                [-14.161528, 10.102639000000181],
                [-14.160971, 10.102361],
                [-14.160971, 10.101805000000127],
                [-14.159862, 10.10124900000011],
                [-14.158471, 10.10124900000011],
                [-14.158471, 10.100973000000181],
                [-14.157083, 10.100973000000181],
                [-14.156251, 10.10124900000011],
                [-14.154860999999869, 10.102639000000181],
                [-14.154305, 10.102639000000181],
                [-14.154305, 10.103193],
                [-14.153751, 10.103471000000127],
                [-14.153473, 10.104305000000181],
                [-14.153473, 10.105140000000176],
                [-14.153194999999869, 10.105696],
                [-14.152639, 10.105971000000181],
                [-14.152639, 10.104583000000105],
                [-14.152917, 10.104583000000105],
                [-14.152917, 10.102917000000105],
                [-14.153194999999869, 10.102361],
                [-14.154305, 10.101805000000127],
                [-14.154583, 10.10124900000011],
                [-14.155139, 10.10124900000011],
                [-14.155692999999872, 10.100695],
                [-14.156526999999869, 10.100695],
                [-14.157083, 10.100417],
                [-14.157361, 10.099861],
                [-14.157917, 10.09958300000011],
                [-14.159583, 10.09958300000011],
                [-14.16014, 10.100417],
                [-14.160693, 10.100417],
                [-14.160971, 10.100973000000181],
                [-14.161528, 10.10124900000011],
                [-14.162084, 10.102361],
                [-14.162638, 10.102917000000105],
                [-14.162638, 10.103471000000127],
                [-14.163194, 10.103749],
                [-14.163194, 10.105415],
                [-14.163472, 10.105415],
                [-14.163472, 10.112638],
                [-14.164028, 10.112916000000155],
                [-14.165694, 10.112916000000155],
                [-14.167084, 10.11236],
                [-14.167362, 10.111806000000172],
                [-14.168471999999838, 10.111528],
                [-14.169028, 10.111250000000155],
                [-14.169305999999892, 10.110694],
                [-14.170416, 10.1104160000001],
                [-14.171528, 10.111250000000155],
                [-14.17180599999989, 10.111806000000172],
                [-14.17236, 10.111806000000172],
                [-14.17236, 10.11236],
                [-14.172916, 10.112638],
                [-14.174582, 10.114306],
                [-14.174863, 10.114028000000133],
                [-14.175695, 10.114028000000133],
                [-14.175972999999885, 10.113194],
                [-14.176529, 10.113194],
                [-14.176529, 10.111250000000155],
                [-14.177361, 10.110972],
                [-14.177917, 10.1104160000001],
                [-14.178472999999883, 10.109306],
                [-14.178472999999883, 10.107918000000154],
                [-14.178195, 10.10764],
                [-14.180138999999883, 10.10764],
                [-14.180695, 10.107362],
                [-14.180695, 10.106806000000176],
                [-14.182361, 10.105140000000176],
                [-14.182638999999881, 10.104305000000181],
                [-14.183193, 10.104027],
                [-14.183472999999879, 10.102917000000105],
                [-14.184861, 10.101527],
                [-14.185415, 10.099861],
                [-14.185415, 10.098751],
                [-14.185696, 10.098751],
                [-14.186527, 10.097917000000109],
                [-14.187084, 10.096529],
                [-14.187084, 10.095417],
                [-14.186804999999879, 10.095417],
                [-14.186527, 10.094583000000114],
                [-14.186527, 10.093473000000131],
                [-14.186249, 10.092638000000136],
                [-14.185696, 10.092082000000119],
                [-14.185415, 10.09125],
                [-14.184304999999881, 10.090138],
                [-14.183749, 10.089028],
                [-14.183749, 10.088472],
                [-14.183193, 10.088194000000158],
                [-14.182917, 10.08763800000014],
                [-14.182083, 10.086806],
                [-14.181527, 10.08597200000014],
                [-14.18097299999988, 10.08597200000014],
                [-14.18097299999988, 10.085416000000123],
                [-14.180417, 10.084860000000162],
                [-14.179583, 10.08430600000014],
                [-14.179583, 10.083750000000123],
                [-14.179027, 10.083472],
                [-14.179027, 10.082916],
                [-14.178472999999883, 10.082638000000145],
                [-14.177638999999886, 10.081806],
                [-14.176529, 10.08125],
                [-14.175972999999885, 10.080694],
                [-14.175972999999885, 10.079862000000162],
                [-14.175138999999888, 10.078749],
                [-14.174306999999885, 10.078749],
                [-14.174306999999885, 10.079584],
                [-14.173194, 10.079306000000145],
                [-14.173194, 10.07847100000015],
                [-14.173472999999888, 10.077639],
                [-14.172916, 10.077361000000167],
                [-14.172916, 10.07680500000015],
                [-14.17236, 10.076249000000189],
                [-14.171528, 10.076249000000189],
                [-14.170416, 10.074305],
                [-14.16986, 10.073749],
                [-14.169305999999892, 10.072917000000189],
                [-14.16875, 10.072917000000189],
                [-14.168471999999838, 10.072361000000171],
                [-14.167916, 10.072361000000171],
                [-14.167362, 10.071527],
                [-14.166805999999838, 10.070973],
                [-14.16625, 10.070973],
                [-14.16625, 10.070417],
                [-14.165694, 10.070417],
                [-14.165139999999838, 10.069583],
                [-14.16430599999984, 10.069583],
                [-14.16430599999984, 10.069029000000171],
                [-14.163472, 10.068751],
                [-14.162918, 10.068195],
                [-14.161806, 10.067639],
                [-14.160971, 10.066807],
                [-14.16014, 10.066529],
                [-14.157917, 10.064304],
                [-14.157361, 10.064585],
                [-14.156526999999869, 10.064585],
                [-14.156526999999869, 10.064028000000121],
                [-14.155973, 10.062916],
                [-14.155417, 10.062916],
                [-14.155139, 10.062360000000126],
                [-14.154583, 10.062360000000126],
                [-14.154305, 10.061806000000104],
                [-14.153194999999869, 10.06152800000018],
                [-14.147639, 10.05875],
                [-14.147359999999878, 10.058194],
                [-14.146807, 10.058194],
                [-14.146528999999873, 10.05764],
                [-14.145416, 10.05736000000013],
                [-14.145138, 10.056806000000108],
                [-14.144584, 10.056806000000108],
                [-14.144304, 10.05625],
                [-14.14319399999988, 10.055972],
                [-14.14125, 10.055138000000113],
                [-14.137916, 10.053472000000113],
                [-14.137361999999882, 10.052915],
                [-14.135972, 10.052915],
                [-14.135972, 10.05264],
                [-14.134861999999885, 10.05264],
                [-14.134861999999885, 10.05236200000013],
                [-14.132915, 10.05236200000013],
                [-14.132083, 10.052084],
                [-14.132083, 10.051805],
                [-14.130417, 10.051805],
                [-14.130417, 10.051527000000135],
                [-14.128749, 10.051527000000135],
                [-14.128749, 10.051249],
                [-14.122639, 10.051249],
                [-14.122639, 10.051527000000135],
                [-14.120973, 10.051527000000135],
                [-14.120973, 10.051805],
                [-14.11986, 10.051805],
                [-14.119307, 10.052084],
                [-14.119029, 10.05264],
                [-14.116806, 10.05264],
                [-14.116804, 10.052915],
                [-14.114862, 10.052915],
                [-14.114306, 10.053472000000113],
                [-14.112084, 10.053472000000113],
                [-14.112084, 10.05375],
                [-14.110972, 10.05375],
                [-14.110696, 10.054306],
                [-14.11014, 10.054584],
                [-14.109306, 10.054584],
                [-14.108749999999873, 10.054862],
                [-14.10791799999987, 10.05569400000013],
                [-14.10791799999987, 10.055972],
                [-14.106527, 10.055972],
                [-14.105970999999897, 10.05625],
                [-14.105696, 10.056806000000108],
                [-14.104582999999877, 10.056806000000108],
                [-14.104027, 10.057084],
                [-14.10374899999988, 10.05764],
                [-14.102638999999897, 10.05764],
                [-14.10208299999988, 10.057916],
                [-14.1018049999999, 10.058472000000108],
                [-14.101248999999882, 10.05875],
                [-14.099305, 10.05875],
                [-14.099305, 10.059028000000126],
                [-14.098472999999899, 10.059306],
                [-14.097361, 10.059306],
                [-14.097361, 10.059584],
                [-14.09624899999983, 10.059584],
                [-14.09624899999983, 10.05986200000018],
                [-14.095139, 10.05986200000018],
                [-14.095139, 10.060138000000109],
                [-14.093750999999827, 10.060138000000109],
                [-14.093750999999827, 10.060416],
                [-14.09236, 10.060416],
                [-14.09236, 10.060694000000126],
                [-14.091528, 10.060972],
                [-14.08986, 10.060972],
                [-14.08986, 10.06125],
                [-14.087916, 10.06125],
                [-14.087916, 10.06152800000018],
                [-14.086528, 10.06152800000018],
                [-14.086528, 10.061806000000104],
                [-14.085416, 10.061806000000104],
                [-14.085416, 10.062082],
                [-14.084028, 10.062082],
                [-14.083194, 10.062360000000126],
                [-14.082916, 10.062916],
                [-14.082084, 10.06319400000018],
                [-14.08125, 10.06319400000018],
                [-14.08097199999986, 10.06375],
                [-14.077917, 10.06375],
                [-14.077361, 10.064028000000121],
                [-14.077083, 10.064585],
                [-14.075973, 10.06486000000018],
                [-14.075693, 10.065416],
                [-14.075139, 10.065416],
                [-14.074861, 10.065973],
                [-14.074305, 10.065973],
                [-14.073749, 10.06652600000018],
                [-14.072917, 10.067917],
                [-14.072361, 10.068195],
                [-14.072361, 10.069305],
                [-14.072639, 10.069861],
                [-14.07347099999987, 10.070417],
                [-14.07347099999987, 10.070973],
                [-14.074305, 10.071251000000188],
                [-14.074583, 10.071805],
                [-14.074583, 10.072639],
                [-14.075417, 10.073749],
                [-14.075973, 10.074027000000171],
                [-14.076249, 10.074583000000189],
                [-14.077361, 10.075693000000172],
                [-14.077917, 10.075693000000172],
                [-14.078196, 10.076249000000189],
                [-14.079305, 10.076527],
                [-14.079305, 10.077083],
                [-14.080139999999858, 10.077083],
                [-14.080693, 10.077361000000167],
                [-14.080693, 10.077918],
                [-14.081528, 10.07847100000015],
                [-14.082084, 10.07847100000015],
                [-14.082084, 10.079027000000167],
                [-14.082916, 10.079584],
                [-14.08375, 10.079862000000162],
                [-14.08375, 10.080418000000122],
                [-14.084306, 10.080972000000145],
                [-14.08514, 10.08125],
                [-14.08514, 10.082084000000123],
                [-14.085972, 10.082362],
                [-14.085972, 10.082916],
                [-14.086528, 10.082916],
                [-14.087362, 10.083472],
                [-14.087362, 10.084028],
                [-14.088472, 10.08430600000014],
                [-14.08875, 10.08514],
                [-14.089306, 10.08514],
                [-14.090416, 10.085694],
                [-14.090694, 10.08625],
                [-14.091807, 10.08625],
                [-14.091807, 10.08597200000014],
                [-14.092916, 10.08597200000014],
                [-14.093750999999827, 10.085416000000123],
                [-14.093750999999827, 10.084860000000162],
                [-14.09458299999983, 10.084860000000162],
                [-14.094861, 10.08430600000014],
                [-14.095973, 10.084028],
                [-14.095973, 10.083472],
                [-14.096805, 10.083472],
                [-14.097361, 10.083194000000162],
                [-14.097639, 10.082638000000145],
                [-14.099027, 10.082084000000123],
                [-14.099861, 10.082084000000123],
                [-14.10041699999988, 10.08125],
                [-14.10041699999988, 10.080694],
                [-14.100972999999897, 10.079584],
                [-14.099582999999882, 10.079027000000167],
                [-14.098195, 10.077639],
                [-14.097916999999882, 10.07680500000015],
                [-14.097916999999882, 10.075693000000172],
                [-14.098472999999899, 10.074305],
                [-14.099027, 10.073751],
                [-14.099582999999882, 10.073749],
                [-14.099861, 10.073195],
                [-14.101527, 10.073195],
                [-14.1018049999999, 10.073749],
                [-14.101527, 10.074027000000171],
                [-14.099861, 10.074027000000171],
                [-14.099027, 10.074861],
                [-14.099027, 10.077361000000167],
                [-14.099582999999882, 10.077361000000167],
                [-14.1001389999999, 10.078196000000162],
                [-14.100972999999897, 10.078196000000162],
                [-14.102916999999877, 10.08014],
                [-14.105696, 10.08014],
                [-14.105696, 10.080418000000122],
                [-14.106806, 10.080418000000122],
                [-14.106806, 10.080694],
                [-14.10791799999987, 10.080694],
                [-14.108472, 10.080972000000145],
                [-14.108749999999873, 10.081528000000162],
                [-14.11014, 10.082084000000123],
                [-14.11014, 10.082638000000145],
                [-14.110972, 10.083194000000162],
                [-14.110972, 10.084028],
                [-14.111528, 10.08430600000014],
                [-14.112362, 10.085416000000123],
                [-14.112638, 10.086528000000158],
                [-14.113194, 10.087082000000123],
                [-14.114028, 10.088194000000158],
                [-14.11458199999987, 10.088194000000158],
                [-14.114862, 10.088750000000118],
                [-14.116249999999866, 10.088750000000118],
                [-14.116528, 10.088194000000158],
                [-14.116528, 10.087084000000118],
                [-14.116806, 10.087082000000123],
                [-14.117638, 10.085416000000123],
                [-14.117638, 10.084860000000162],
                [-14.118194, 10.084584],
                [-14.119029, 10.083750000000123],
                [-14.11986, 10.083472],
                [-14.119863, 10.083194000000162],
                [-14.122083, 10.083194000000162],
                [-14.122083, 10.083472],
                [-14.123195, 10.083472],
                [-14.123749, 10.084028],
                [-14.123473, 10.084860000000162],
                [-14.122361, 10.084860000000162],
                [-14.122361, 10.084584],
                [-14.11986, 10.084584],
                [-14.119029, 10.08514],
                [-14.119029, 10.087362],
                [-14.118749999999864, 10.087362],
                [-14.118749999999864, 10.088472],
                [-14.117915999999866, 10.089028],
                [-14.11736, 10.089584],
                [-14.116806, 10.090694],
                [-14.116806, 10.091804],
                [-14.11736, 10.09236],
                [-14.11736, 10.093195],
                [-14.117638, 10.094026],
                [-14.118194, 10.094583000000114],
                [-14.118194, 10.096249000000114],
                [-14.118472, 10.096249000000114],
                [-14.118472, 10.098751],
                [-14.118194, 10.098751],
                [-14.118194, 10.100973000000181],
                [-14.118749999999864, 10.101527],
                [-14.118749999999864, 10.102083],
                [-14.120138, 10.102917000000105],
                [-14.120973, 10.102917000000105],
                [-14.122639, 10.104583000000105],
                [-14.122917, 10.105696],
                [-14.123473, 10.105971000000181],
                [-14.123195, 10.106806000000176],
                [-14.122639, 10.107918000000154],
                [-14.122917, 10.1087500000001],
                [-14.122917, 10.109862],
                [-14.122639, 10.109862],
                [-14.122639, 10.110972],
                [-14.122361, 10.111528],
                [-14.121251, 10.11236],
              ],
            ],
            [
              [
                [-14.055138000999932, 10.126250999000149],
                [-14.055138000999932, 10.127083000000141],
                [-14.055694, 10.127638999000055],
                [-14.056249998999931, 10.128473],
                [-14.057084, 10.128473],
                [-14.057640000999982, 10.127638999000055],
                [-14.058194, 10.127638999000055],
                [-14.058471999999824, 10.127083000000141],
                [-14.059028000999888, 10.127083000000141],
                [-14.059584, 10.12652699900002],
                [-14.060416000999908, 10.12652699900002],
                [-14.060693999999899, 10.125971000000163],
                [-14.06125, 10.125971000000163],
                [-14.06180600099998, 10.125138999000114],
                [-14.062359999999899, 10.125138999000114],
                [-14.062915998999927, 10.124583],
                [-14.063194000999886, 10.123748999000043],
                [-14.06375, 10.123748999000043],
                [-14.064585, 10.123195000000123],
                [-14.064585, 10.12263899900006],
                [-14.065415998999981, 10.122083000000146],
                [-14.065415998999981, 10.12152700100006],
                [-14.065972999999872, 10.120973000000163],
                [-14.06680699899988, 10.119859999000141],
                [-14.06680699899988, 10.119029],
                [-14.067082000999903, 10.118471999000064],
                [-14.067638999999872, 10.118471999000064],
                [-14.067916998999976, 10.11791600000015],
                [-14.067916998999976, 10.116528],
                [-14.068473000999973, 10.116528],
                [-14.068473000999973, 10.115416],
                [-14.069029, 10.114862001000176],
                [-14.069029, 10.113472001000048],
                [-14.068751, 10.113472001000048],
                [-14.068751, 10.111528],
                [-14.068195000999879, 10.110140000000172],
                [-14.067360999999892, 10.109028],
                [-14.065137999999877, 10.10847200100011],
                [-14.064859999999896, 10.107917999000051],
                [-14.064027998999961, 10.10764],
                [-14.064027998999961, 10.106528],
                [-14.063194000999886, 10.105971000000181],
                [-14.063194000999886, 10.105140000000176],
                [-14.062915998999927, 10.104583000000105],
                [-14.062359999999899, 10.104583000000105],
                [-14.06180600099998, 10.104026998999984],
                [-14.061527998999907, 10.10319300100008],
                [-14.060693999999899, 10.10319300100008],
                [-14.059861999999896, 10.10291700099998],
                [-14.059861999999896, 10.102638999000078],
                [-14.058749998999929, 10.1015269990001],
                [-14.05736, 10.1015269990001],
                [-14.05652800099989, 10.102083],
                [-14.05652800099989, 10.102638999000078],
                [-14.055694, 10.10319300100008],
                [-14.055138000999932, 10.10319300100008],
                [-14.054861998999911, 10.103749],
                [-14.053470998999842, 10.103749],
                [-14.053193, 10.104305001000114],
                [-14.052361998999913, 10.104305001000114],
                [-14.051805, 10.104026998999984],
                [-14.051527, 10.104305001000114],
                [-14.051527, 10.10569600100007],
                [-14.050970998999958, 10.10569600100007],
                [-14.050973998999893, 10.105140000000176],
                [-14.049861000999954, 10.104583000000105],
                [-14.049305, 10.104583000000105],
                [-14.049026999999853, 10.105414999000118],
                [-14.048473000999934, 10.105140000000176],
                [-14.047639, 10.105140000000176],
                [-14.047361000999956, 10.10569600100007],
                [-14.046526999999855, 10.10569600100007],
                [-14.046251, 10.106249000000105],
                [-14.044583000999921, 10.106249000000105],
                [-14.044304998999962, 10.106805999000073],
                [-14.043195000999958, 10.107362],
                [-14.041528998999922, 10.107362],
                [-14.040139, 10.10819399899998],
                [-14.03986, 10.108752],
                [-14.03930700099994, 10.109028],
                [-14.038472, 10.109028],
                [-14.03791599899995, 10.109862],
                [-14.03680400099995, 10.110140000000172],
                [-14.03625, 10.110693999000091],
                [-14.035416000999874, 10.110693999000091],
                [-14.035416000999874, 10.111250000000155],
                [-14.034862, 10.111528],
                [-14.034581999999887, 10.11236000100007],
                [-14.034581999999887, 10.113193998999975],
                [-14.034862, 10.113750000999971],
                [-14.035416000999874, 10.114028000000133],
                [-14.035416000999874, 10.114581999000052],
                [-14.036527998999873, 10.115138000000172],
                [-14.037084, 10.115138000000172],
                [-14.037084, 10.115694000000133],
                [-14.037638, 10.11597199900018],
                [-14.038751, 10.11597199900018],
                [-14.03930700099994, 10.116528],
                [-14.040139, 10.116528],
                [-14.04069500099996, 10.117083999000158],
                [-14.041804998999964, 10.117083999000158],
                [-14.042916998999942, 10.11791600000015],
                [-14.044027, 10.11791600000015],
                [-14.044027, 10.118194],
                [-14.045139, 10.118194],
                [-14.04569499899992, 10.119029],
                [-14.046526999999855, 10.119307000000163],
                [-14.047361000999956, 10.119307000000163],
                [-14.047639, 10.119859999000141],
                [-14.048194998999918, 10.120139001000155],
                [-14.049305, 10.120139001000155],
                [-14.049305, 10.120695],
                [-14.050417, 10.121250999000154],
                [-14.050973998999893, 10.121250999000154],
                [-14.051249000999917, 10.121805],
                [-14.052083, 10.122083000000146],
                [-14.052918, 10.122083000000146],
                [-14.053193, 10.12263899900006],
                [-14.053193, 10.123473],
                [-14.053750000999912, 10.123748999000043],
                [-14.053750000999912, 10.124305001000039],
                [-14.054306, 10.124583],
                [-14.054306, 10.125695],
                [-14.055138000999932, 10.126250999000149],
              ],
            ],
            [
              [
                [-14.121251, 10.11236],
                [-14.121251, 10.111250000000155],
                [-14.121529, 10.111250000000155],
                [-14.121529, 10.110140000000172],
                [-14.122083, 10.109584000000154],
                [-14.122083, 10.108194],
                [-14.122361, 10.108196],
                [-14.122361, 10.106249000000105],
                [-14.122083, 10.105415],
                [-14.121529, 10.105140000000176],
                [-14.121529, 10.104583000000105],
                [-14.120695, 10.104027],
                [-14.120138, 10.104027],
                [-14.119581999999866, 10.103471000000127],
                [-14.118749999999864, 10.103471000000127],
                [-14.118194, 10.103193],
                [-14.117638, 10.102361],
                [-14.117083999999863, 10.102361],
                [-14.117083999999863, 10.100973000000181],
                [-14.116806, 10.100973000000181],
                [-14.116806, 10.098751],
                [-14.117083999999863, 10.098751],
                [-14.117083999999863, 10.096249000000114],
                [-14.116806, 10.096249000000114],
                [-14.116806, 10.094861],
                [-14.116249999999866, 10.093195],
                [-14.115694, 10.092082000000119],
                [-14.114862, 10.091529],
                [-14.114862, 10.090972000000136],
                [-14.114306, 10.090972000000136],
                [-14.114306, 10.090416000000118],
                [-14.11375, 10.090416000000118],
                [-14.113472, 10.089860000000158],
                [-14.112362, 10.089028],
                [-14.112362, 10.088472],
                [-14.111528, 10.087362],
                [-14.110696, 10.086806],
                [-14.110415999999873, 10.08625],
                [-14.109306, 10.08514],
                [-14.109306, 10.084584],
                [-14.108749999999873, 10.08430600000014],
                [-14.10791799999987, 10.083472],
                [-14.106806, 10.082916],
                [-14.106806, 10.082362],
                [-14.106248999999877, 10.081806],
                [-14.10374899999988, 10.081806],
                [-14.103193, 10.082362],
                [-14.10208299999988, 10.082362],
                [-14.101527, 10.082638000000145],
                [-14.101527, 10.083194000000162],
                [-14.100695, 10.083472],
                [-14.100695, 10.084028],
                [-14.099582999999882, 10.08430600000014],
                [-14.099305, 10.084860000000162],
                [-14.098472999999899, 10.08514],
                [-14.098472999999899, 10.085694],
                [-14.097916999999882, 10.085694],
                [-14.097916999999882, 10.086528000000158],
                [-14.097361, 10.086528000000158],
                [-14.097361, 10.087082000000123],
                [-14.096805, 10.087082000000123],
                [-14.096529, 10.087916],
                [-14.095695, 10.087916],
                [-14.095139, 10.088750000000118],
                [-14.094307, 10.089028],
                [-14.094307, 10.089584],
                [-14.093750999999827, 10.089860000000158],
                [-14.093750999999827, 10.090416000000118],
                [-14.093195, 10.090972000000136],
                [-14.09236, 10.090694],
                [-14.09236, 10.090138],
                [-14.092916, 10.089860000000158],
                [-14.092641, 10.088750000000118],
                [-14.091807, 10.088472],
                [-14.090972, 10.088472],
                [-14.090416, 10.087916],
                [-14.089306, 10.087916],
                [-14.08875, 10.08763800000014],
                [-14.088472, 10.087082000000123],
                [-14.087916, 10.086806],
                [-14.086806, 10.086806],
                [-14.08625, 10.086528000000158],
                [-14.085972, 10.08597200000014],
                [-14.085416, 10.08597200000014],
                [-14.084028, 10.08514],
                [-14.084028, 10.084584],
                [-14.08264, 10.083750000000123],
                [-14.08264, 10.083194000000162],
                [-14.081528, 10.083194000000162],
                [-14.08125, 10.082638000000145],
                [-14.080693, 10.082638000000145],
                [-14.079584, 10.082084000000123],
                [-14.079584, 10.081528000000162],
                [-14.078749, 10.080972000000145],
                [-14.077083, 10.080972000000145],
                [-14.076249, 10.080694],
                [-14.074583, 10.079862000000162],
                [-14.073749, 10.080418000000122],
                [-14.073749, 10.083750000000123],
                [-14.074027, 10.083750000000123],
                [-14.074027, 10.08625],
                [-14.074305, 10.087082000000123],
                [-14.074583, 10.087084000000118],
                [-14.074861, 10.087916],
                [-14.074861, 10.088750000000118],
                [-14.075139, 10.089584],
                [-14.075973, 10.090138],
                [-14.075973, 10.090694],
                [-14.076527, 10.090694],
                [-14.076527, 10.09125],
                [-14.077083, 10.09125],
                [-14.077083, 10.092082000000119],
                [-14.077361, 10.092638000000136],
                [-14.078196, 10.092917000000114],
                [-14.078196, 10.093473000000131],
                [-14.079305, 10.095695],
                [-14.079862, 10.095973000000185],
                [-14.080693, 10.097083],
                [-14.080693, 10.098195],
                [-14.08097199999986, 10.099027],
                [-14.081805999999858, 10.09958300000011],
                [-14.081805999999858, 10.100139000000127],
                [-14.082362, 10.100417],
                [-14.082362, 10.102083],
                [-14.08264, 10.102083],
                [-14.08264, 10.103749],
                [-14.082916, 10.103749],
                [-14.082916, 10.106528],
                [-14.083194, 10.106528],
                [-14.083194, 10.112638],
                [-14.082916, 10.112638],
                [-14.082916, 10.119029],
                [-14.08264, 10.119029],
                [-14.08264, 10.120139],
                [-14.082084, 10.120417000000145],
                [-14.082084, 10.121527000000128],
                [-14.081805999999858, 10.121527000000128],
                [-14.081805999999858, 10.123749000000146],
                [-14.081528, 10.123749000000146],
                [-14.081528, 10.125139],
                [-14.08125, 10.125139],
                [-14.08125, 10.126805],
                [-14.08097199999986, 10.126805],
                [-14.08097199999986, 10.129305000000102],
                [-14.080693, 10.129305000000102],
                [-14.080693, 10.130971000000102],
                [-14.080418, 10.130971000000102],
                [-14.080418, 10.13264],
                [-14.080139999999858, 10.13264],
                [-14.080139999999858, 10.134306],
                [-14.080693, 10.134862],
                [-14.080693, 10.135694000000115],
                [-14.08125, 10.136250000000132],
                [-14.082084, 10.138750000000186],
                [-14.082084, 10.140972],
                [-14.082362, 10.140972],
                [-14.082362, 10.142638],
                [-14.08264, 10.142638],
                [-14.08264, 10.143750000000182],
                [-14.082084, 10.144304],
                [-14.082084, 10.145138],
                [-14.081528, 10.145694000000105],
                [-14.081528, 10.146251000000177],
                [-14.080418, 10.148473],
                [-14.079862, 10.149029000000155],
                [-14.079862, 10.150417],
                [-14.079584, 10.150695000000155],
                [-14.079584, 10.151805],
                [-14.079862, 10.151805],
                [-14.079862, 10.152917000000173],
                [-14.080139999999858, 10.152917000000173],
                [-14.08125, 10.154027000000156],
                [-14.081528, 10.154583000000173],
                [-14.082084, 10.154861],
                [-14.08264, 10.154583000000173],
                [-14.084306, 10.154583000000173],
                [-14.08486, 10.153751],
                [-14.085694, 10.153471],
                [-14.086528, 10.152639],
                [-14.087362, 10.152083],
                [-14.087362, 10.151527000000101],
                [-14.087916, 10.151251000000173],
                [-14.088472, 10.150139],
                [-14.088472, 10.149583000000177],
                [-14.089028, 10.149307],
                [-14.089306, 10.148751],
                [-14.089306, 10.147639],
                [-14.08986, 10.14736100000016],
                [-14.090138, 10.146807],
                [-14.090416, 10.145694000000105],
                [-14.090972, 10.145416000000182],
                [-14.090972, 10.14486],
                [-14.091807, 10.144585000000177],
                [-14.091807, 10.143750000000182],
                [-14.092641, 10.143472],
                [-14.092916, 10.142916],
                [-14.094026, 10.142916],
                [-14.094026, 10.142638],
                [-14.09624899999983, 10.142638],
                [-14.097639, 10.143194],
                [-14.099305, 10.14486],
                [-14.099305, 10.145416000000182],
                [-14.099861, 10.145694000000105],
                [-14.099861, 10.1465290000001],
                [-14.10041699999988, 10.147082000000182],
                [-14.10041699999988, 10.1481950000001],
                [-14.100695, 10.1481950000001],
                [-14.100695, 10.149863],
                [-14.101248999999882, 10.1498610000001],
                [-14.101248999999882, 10.150973],
                [-14.101527, 10.150973],
                [-14.101527, 10.152083],
                [-14.102361, 10.152639],
                [-14.10374899999988, 10.152639],
                [-14.104582999999877, 10.152361000000155],
                [-14.104582999999877, 10.151805],
                [-14.105696, 10.151527000000101],
                [-14.105696, 10.150973],
                [-14.106806, 10.150695000000155],
                [-14.107362, 10.150417],
                [-14.108472, 10.149583000000177],
                [-14.108749999999873, 10.149029000000155],
                [-14.10958399999987, 10.149029000000155],
                [-14.109862, 10.148473],
                [-14.110415999999873, 10.1481950000001],
                [-14.11125, 10.1481950000001],
                [-14.111806, 10.147639],
                [-14.112638, 10.14736100000016],
                [-14.114306, 10.14736100000016],
                [-14.114306, 10.147639],
                [-14.115694, 10.147639],
                [-14.116528, 10.147917000000177],
                [-14.11736, 10.148473],
                [-14.11736, 10.149029000000155],
                [-14.119029, 10.150695000000155],
                [-14.119029, 10.151527000000101],
                [-14.119307, 10.152083],
                [-14.11986, 10.152083],
                [-14.11986, 10.153195],
                [-14.120138, 10.153195],
                [-14.120138, 10.154305],
                [-14.120417, 10.154305],
                [-14.120417, 10.158196000000146],
                [-14.120695, 10.158196000000146],
                [-14.120695, 10.159305],
                [-14.120417, 10.159305],
                [-14.120417, 10.160693000000151],
                [-14.120973, 10.16125],
                [-14.122361, 10.16125],
                [-14.122639, 10.160972000000129],
                [-14.122639, 10.159862000000146],
                [-14.123195, 10.159584],
                [-14.123195, 10.157917000000168],
                [-14.122917, 10.157917000000168],
                [-14.122917, 10.155139000000133],
                [-14.123195, 10.155139000000133],
                [-14.123195, 10.152917000000173],
                [-14.123749, 10.152639],
                [-14.124029, 10.151527000000101],
                [-14.124583, 10.150417],
                [-14.125139, 10.150417],
                [-14.125417, 10.1498610000001],
                [-14.125971, 10.149863],
                [-14.125971, 10.149307],
                [-14.126805, 10.149029000000155],
                [-14.126805, 10.148473],
                [-14.127917, 10.1481950000001],
                [-14.127917, 10.147639],
                [-14.128473, 10.147639],
                [-14.129305, 10.146807],
                [-14.129305, 10.146251000000177],
                [-14.130139, 10.145973],
                [-14.130139, 10.145416000000182],
                [-14.130694999999832, 10.145416000000182],
                [-14.130971, 10.14486],
                [-14.130971, 10.140138],
                [-14.131249, 10.140138],
                [-14.131249, 10.134584000000132],
                [-14.131526999999835, 10.134584000000132],
                [-14.131526999999835, 10.133196],
                [-14.131805, 10.13319300000012],
                [-14.132083, 10.132362000000114],
                [-14.132083, 10.129583],
                [-14.131805, 10.129583],
                [-14.131805, 10.128195000000119],
                [-14.130971, 10.127083000000141],
                [-14.130417, 10.127083000000141],
                [-14.130139, 10.126527000000124],
                [-14.129027, 10.126251],
                [-14.128749, 10.125695],
                [-14.127361, 10.125695],
                [-14.126805, 10.125139],
                [-14.125971, 10.125139],
                [-14.125417, 10.124583],
                [-14.124583, 10.124583],
                [-14.124029, 10.124029],
                [-14.123473, 10.124029],
                [-14.120138, 10.122361],
                [-14.119307, 10.122083000000146],
                [-14.117638, 10.121251],
                [-14.116806, 10.120973000000163],
                [-14.116806, 10.120695],
                [-14.114862, 10.120695],
                [-14.113472, 10.121251],
                [-14.112917999999866, 10.122083000000146],
                [-14.112362, 10.123195000000123],
                [-14.111528, 10.123749000000146],
                [-14.110696, 10.123473],
                [-14.109306, 10.123473],
                [-14.108749999999873, 10.123749000000146],
                [-14.108194, 10.123473],
                [-14.10791799999987, 10.122917],
                [-14.107083999999873, 10.122361],
                [-14.106806, 10.121527000000128],
                [-14.106806, 10.120417000000145],
                [-14.107083999999873, 10.11958200000015],
                [-14.107362, 10.11958200000015],
                [-14.107362, 10.118194],
                [-14.10764, 10.117638],
                [-14.108472, 10.116806000000167],
                [-14.108749999999873, 10.116806000000167],
                [-14.108749999999873, 10.112638],
                [-14.109028, 10.112638],
                [-14.109028, 10.111250000000155],
                [-14.109862, 10.1104160000001],
                [-14.110415999999873, 10.1104160000001],
                [-14.110696, 10.109862],
                [-14.115138, 10.109862],
                [-14.115416, 10.1104160000001],
                [-14.116528, 10.110694],
                [-14.11736, 10.111528],
                [-14.11736, 10.112084],
                [-14.118472, 10.113194],
                [-14.120138, 10.113194],
                [-14.120417, 10.112638],
                [-14.121251, 10.11236],
              ],
            ],
            [
              [
                [-14.046251, 10.125971000000163],
                [-14.045971000999941, 10.12652699900002],
                [-14.045971000999941, 10.128473],
                [-14.046251, 10.129305001000034],
                [-14.046805, 10.129583],
                [-14.046805, 10.130139],
                [-14.047639, 10.130139],
                [-14.047639, 10.129583],
                [-14.048194998999918, 10.129305001000034],
                [-14.048194998999918, 10.128749000000141],
                [-14.049026999999853, 10.128473],
                [-14.049305, 10.127361],
                [-14.050139, 10.127083000000141],
                [-14.050139, 10.12652699900002],
                [-14.050695, 10.125971000000163],
                [-14.050695, 10.125138999000114],
                [-14.050139, 10.124861000000124],
                [-14.050139, 10.124305001000039],
                [-14.049582998999938, 10.123748999000043],
                [-14.048473000999934, 10.12402900100011],
                [-14.047361000999956, 10.12402900100011],
                [-14.04708299899994, 10.124305001000039],
                [-14.04708299899994, 10.125695],
                [-14.046251, 10.125971000000163],
              ],
            ],
            [
              [
                [-14.187639999999874, 10.197084],
                [-14.18875, 10.197916000000134],
                [-14.19014, 10.197916000000134],
                [-14.19014, 10.198194],
                [-14.192916, 10.198194],
                [-14.194028, 10.197638],
                [-14.194028, 10.195416],
                [-14.193472, 10.194862],
                [-14.19264, 10.19375],
                [-14.19264, 10.193194],
                [-14.191528, 10.192638],
                [-14.191528, 10.192084],
                [-14.190694, 10.190972],
                [-14.19014, 10.190694000000178],
                [-14.19014, 10.190140000000156],
                [-14.189584, 10.189862],
                [-14.189584, 10.189306],
                [-14.189028, 10.189028000000178],
                [-14.18875, 10.188194],
                [-14.18875, 10.187084],
                [-14.188471999999877, 10.187084],
                [-14.188471999999877, 10.185139000000106],
                [-14.188193, 10.185139000000106],
                [-14.188193, 10.184027],
                [-14.187918, 10.184027],
                [-14.187918, 10.182917],
                [-14.188193, 10.182083],
                [-14.188471999999877, 10.182083],
                [-14.188471999999877, 10.180417],
                [-14.189028, 10.180417],
                [-14.189028, 10.179305],
                [-14.189305999999874, 10.17847300000011],
                [-14.189862, 10.177917],
                [-14.189862, 10.176805000000115],
                [-14.190416, 10.176805000000115],
                [-14.190416, 10.176251],
                [-14.190971999999874, 10.175973],
                [-14.19125, 10.174861],
                [-14.192084, 10.174583],
                [-14.192362, 10.174026],
                [-14.193472, 10.173751],
                [-14.194028, 10.173195],
                [-14.194862, 10.172916],
                [-14.19875, 10.172916],
                [-14.19875, 10.173195],
                [-14.201529, 10.173195],
                [-14.201529, 10.173473000000115],
                [-14.203473, 10.173473000000115],
                [-14.203473, 10.173195],
                [-14.204582999999843, 10.173195],
                [-14.204582999999843, 10.173473000000115],
                [-14.210416999999893, 10.173473000000115],
                [-14.211248999999896, 10.173751],
                [-14.211805, 10.174307],
                [-14.212639, 10.174307],
                [-14.213196, 10.174861],
                [-14.214027, 10.175139000000115],
                [-14.216249999999889, 10.176251],
                [-14.217362, 10.176529000000187],
                [-14.21764, 10.177083],
                [-14.220415999999886, 10.17847300000011],
                [-14.221249999999884, 10.17847300000011],
                [-14.221806, 10.179027],
                [-14.222638, 10.179027],
                [-14.223194, 10.179583],
                [-14.224028, 10.179861000000187],
                [-14.225694, 10.179861000000187],
                [-14.225694, 10.18013900000011],
                [-14.227081999999882, 10.18013900000011],
                [-14.227081999999882, 10.180417],
                [-14.230417, 10.180417],
                [-14.230973, 10.179583],
                [-14.231527, 10.179583],
                [-14.232361, 10.177917],
                [-14.233195, 10.176805000000115],
                [-14.234027, 10.176251],
                [-14.234305, 10.175139000000115],
                [-14.234861, 10.174861],
                [-14.235139, 10.173751],
                [-14.23569499999985, 10.173473000000115],
                [-14.236249, 10.172363],
                [-14.236249, 10.17097200000012],
                [-14.236527, 10.17097200000012],
                [-14.236527, 10.165972000000124],
                [-14.237083, 10.16514],
                [-14.237639, 10.16514],
                [-14.237917, 10.164306000000124],
                [-14.238471, 10.164028],
                [-14.238471, 10.163194000000146],
                [-14.239305, 10.162638000000129],
                [-14.239860999999848, 10.161528000000146],
                [-14.239860999999848, 10.160418000000107],
                [-14.24014, 10.160418000000107],
                [-14.240418, 10.159584],
                [-14.240418, 10.158749],
                [-14.240971, 10.157083],
                [-14.240418, 10.157083],
                [-14.239860999999848, 10.156249000000173],
                [-14.23902699999985, 10.155693000000156],
                [-14.237083, 10.153751],
                [-14.237083, 10.152083],
                [-14.236805, 10.152083],
                [-14.236805, 10.150417],
                [-14.236527, 10.150417],
                [-14.236527, 10.147917000000177],
                [-14.23736099999985, 10.1481950000001],
                [-14.23736099999985, 10.150417],
                [-14.237639, 10.150417],
                [-14.237639, 10.152083],
                [-14.237917, 10.152083],
                [-14.237917, 10.153751],
                [-14.238749, 10.154305],
                [-14.239305, 10.155139000000133],
                [-14.241528, 10.155139000000133],
                [-14.242084, 10.154305],
                [-14.242362, 10.153471],
                [-14.243194, 10.152639],
                [-14.243194, 10.152083],
                [-14.24375, 10.151805],
                [-14.24375, 10.1498610000001],
                [-14.244306, 10.149583000000177],
                [-14.244306, 10.149029000000155],
                [-14.245972, 10.149029000000155],
                [-14.24625, 10.1481950000001],
                [-14.246806, 10.147917000000177],
                [-14.246806, 10.14736100000016],
                [-14.247361999999896, 10.147082000000182],
                [-14.247361999999896, 10.146251000000177],
                [-14.247916, 10.145138],
                [-14.248193999999899, 10.144304],
                [-14.24875, 10.143194],
                [-14.249027999999896, 10.143194],
                [-14.249027999999896, 10.142082000000187],
                [-14.24930599999982, 10.142082000000187],
                [-14.24930599999982, 10.140972],
                [-14.249584, 10.140972],
                [-14.249584, 10.139862],
                [-14.249027999999896, 10.139584],
                [-14.249027999999896, 10.137916000000132],
                [-14.248472, 10.136806],
                [-14.247916, 10.136528],
                [-14.247638, 10.135694000000115],
                [-14.246806, 10.135694000000115],
                [-14.24625, 10.135418000000186],
                [-14.24625, 10.134584000000132],
                [-14.245694, 10.134584000000132],
                [-14.24514, 10.134028000000114],
                [-14.24486, 10.132915],
                [-14.244306, 10.132362000000114],
                [-14.244306, 10.13152700000012],
                [-14.24375, 10.130971000000102],
                [-14.242637, 10.130971000000102],
                [-14.242362, 10.130417000000136],
                [-14.241806, 10.129861000000119],
                [-14.241249, 10.128749000000141],
                [-14.240692999999851, 10.128473],
                [-14.239860999999848, 10.126805],
                [-14.239305, 10.126251],
                [-14.239305, 10.125695],
                [-14.238471, 10.125139],
                [-14.237639, 10.124029],
                [-14.236805, 10.123473],
                [-14.23569499999985, 10.122361],
                [-14.23569499999985, 10.121805],
                [-14.235139, 10.121251],
                [-14.234861, 10.120695],
                [-14.233751, 10.120139],
                [-14.233751, 10.11986],
                [-14.230695, 10.116806000000167],
                [-14.230139, 10.116528],
                [-14.229861, 10.115972],
                [-14.229305, 10.115972],
                [-14.228195, 10.114862],
                [-14.227638, 10.114582000000155],
                [-14.225415999999882, 10.112916000000155],
                [-14.225138, 10.11236],
                [-14.224304, 10.11236],
                [-14.223472, 10.112084],
                [-14.223194, 10.111528],
                [-14.222638, 10.111528],
                [-14.222362, 10.110972],
                [-14.221249999999884, 10.110694],
                [-14.220972, 10.110140000000172],
                [-14.220415999999886, 10.110140000000172],
                [-14.220138, 10.109584000000154],
                [-14.219306, 10.109584000000154],
                [-14.219028, 10.109028],
                [-14.218472, 10.1087500000001],
                [-14.217362, 10.108752],
                [-14.217362, 10.108472000000177],
                [-14.216249999999889, 10.108472000000177],
                [-14.215417999999886, 10.108196],
                [-14.215417999999886, 10.107918000000154],
                [-14.214027, 10.107918000000154],
                [-14.213196, 10.10764],
                [-14.212639, 10.1070840000001],
                [-14.211805, 10.106806000000176],
                [-14.210416999999893, 10.106806000000176],
                [-14.210416999999893, 10.106528],
                [-14.209305, 10.106528],
                [-14.209305, 10.106249000000105],
                [-14.208473, 10.105971000000181],
                [-14.207639, 10.105971000000181],
                [-14.206805, 10.105696],
                [-14.206250999999895, 10.105140000000176],
                [-14.204861, 10.105140000000176],
                [-14.204582999999843, 10.104583000000105],
                [-14.203473, 10.104305000000181],
                [-14.203195, 10.103749],
                [-14.202082999999845, 10.103471000000127],
                [-14.201529, 10.102917000000105],
                [-14.200695, 10.102639000000181],
                [-14.199307, 10.102639000000181],
                [-14.199028, 10.103471000000127],
                [-14.198194, 10.103749],
                [-14.197916, 10.104305000000181],
                [-14.19736, 10.104305000000181],
                [-14.197084, 10.104861],
                [-14.197084, 10.106249000000105],
                [-14.196806, 10.106528],
                [-14.195972, 10.106528],
                [-14.195694, 10.1070840000001],
                [-14.195138, 10.1070840000001],
                [-14.195138, 10.107918000000154],
                [-14.193472, 10.110140000000172],
                [-14.193472, 10.110972],
                [-14.192916, 10.111250000000155],
                [-14.192916, 10.11236],
                [-14.192637999999874, 10.112916000000155],
                [-14.190694, 10.114862],
                [-14.190694, 10.115694000000133],
                [-14.189862, 10.116528],
                [-14.189584, 10.117084],
                [-14.189028, 10.117360000000133],
                [-14.189028, 10.11791600000015],
                [-14.188193, 10.118472000000168],
                [-14.188193, 10.11986],
                [-14.188471999999877, 10.120139],
                [-14.191806, 10.120139],
                [-14.191806, 10.120417000000145],
                [-14.193194, 10.120417000000145],
                [-14.193194, 10.120695],
                [-14.194028, 10.120973000000163],
                [-14.194862, 10.120973000000163],
                [-14.195416, 10.121527000000128],
                [-14.19625, 10.121805],
                [-14.19736, 10.122361],
                [-14.198194, 10.122639000000163],
                [-14.199307, 10.122639000000163],
                [-14.199582, 10.123195000000123],
                [-14.200138, 10.123473],
                [-14.200138, 10.124029],
                [-14.200695, 10.124305000000163],
                [-14.200973, 10.124861000000124],
                [-14.200973, 10.127083000000141],
                [-14.200416, 10.127639000000102],
                [-14.200416, 10.129027],
                [-14.200138, 10.129027],
                [-14.200138, 10.131252000000131],
                [-14.200416, 10.131249],
                [-14.200416, 10.132362000000114],
                [-14.199582, 10.132083000000137],
                [-14.199582, 10.131249],
                [-14.199028, 10.130695],
                [-14.199028, 10.129305000000102],
                [-14.199307, 10.129305000000102],
                [-14.199307, 10.128195000000119],
                [-14.199582, 10.128195000000119],
                [-14.199582, 10.126527000000124],
                [-14.199863, 10.126527000000124],
                [-14.200138, 10.125695],
                [-14.200138, 10.124861000000124],
                [-14.199307, 10.124583],
                [-14.198194, 10.123749000000146],
                [-14.197916, 10.123195000000123],
                [-14.197084, 10.123195000000123],
                [-14.196528, 10.122639000000163],
                [-14.195694, 10.122361],
                [-14.194306, 10.122361],
                [-14.19375, 10.122083000000146],
                [-14.193472, 10.121527000000128],
                [-14.192084, 10.121527000000128],
                [-14.192084, 10.121251],
                [-14.190694, 10.121251],
                [-14.189862, 10.120973000000163],
                [-14.189584, 10.121527000000128],
                [-14.187918, 10.121527000000128],
                [-14.187084, 10.121251],
                [-14.186527, 10.121805],
                [-14.186249, 10.122917],
                [-14.185415, 10.124029],
                [-14.184583, 10.124583],
                [-14.184583, 10.125139],
                [-14.184027, 10.12541700000014],
                [-14.184027, 10.126251],
                [-14.183472999999879, 10.126527000000124],
                [-14.183472999999879, 10.127083000000141],
                [-14.182917, 10.127083000000141],
                [-14.182917, 10.127639000000102],
                [-14.182361, 10.127639000000102],
                [-14.182361, 10.128473],
                [-14.181804999999883, 10.129305000000102],
                [-14.181249, 10.129305000000102],
                [-14.181249, 10.129861000000119],
                [-14.180695, 10.130139],
                [-14.179861, 10.130971000000102],
                [-14.179861, 10.13152700000012],
                [-14.17930699999988, 10.13152700000012],
                [-14.179304999999886, 10.132083000000137],
                [-14.178472999999883, 10.132362000000114],
                [-14.178195, 10.13319300000012],
                [-14.177638999999886, 10.13319300000012],
                [-14.177638999999886, 10.133750000000191],
                [-14.177083, 10.134028000000114],
                [-14.176804999999888, 10.134584000000132],
                [-14.176251, 10.134584000000132],
                [-14.176249, 10.135138],
                [-14.175419, 10.135694000000115],
                [-14.175419, 10.136250000000132],
                [-14.174863, 10.136250000000132],
                [-14.174863, 10.136806],
                [-14.174306999999885, 10.136806],
                [-14.174306999999885, 10.13736200000011],
                [-14.173472999999888, 10.13764],
                [-14.173472999999888, 10.138194],
                [-14.172637999999893, 10.138472],
                [-14.172637999999893, 10.13902800000011],
                [-14.172082, 10.13902800000011],
                [-14.172082, 10.139584],
                [-14.17125, 10.139862],
                [-14.170416, 10.14069400000011],
                [-14.170416, 10.14125],
                [-14.16986, 10.141528],
                [-14.16986, 10.14236000000011],
                [-14.169305999999892, 10.14236000000011],
                [-14.169305999999892, 10.143194],
                [-14.169028, 10.143750000000182],
                [-14.169305999999892, 10.144028000000105],
                [-14.17014, 10.144028000000105],
                [-14.170416, 10.144585000000177],
                [-14.173194, 10.144585000000177],
                [-14.173194, 10.144304],
                [-14.175138999999888, 10.144304],
                [-14.175138999999888, 10.144028000000105],
                [-14.176529, 10.144028000000105],
                [-14.177361, 10.143750000000182],
                [-14.177361, 10.143472],
                [-14.182638999999881, 10.143472],
                [-14.183193, 10.144028000000105],
                [-14.183749, 10.144028000000105],
                [-14.184583, 10.144585000000177],
                [-14.184583, 10.145138],
                [-14.185138999999879, 10.145694000000105],
                [-14.185696, 10.145694000000105],
                [-14.185970999999881, 10.146807],
                [-14.186527, 10.147082000000182],
                [-14.187084, 10.148473],
                [-14.187084, 10.149583000000177],
                [-14.187362, 10.149583000000177],
                [-14.187362, 10.150695000000155],
                [-14.187639999999874, 10.150695000000155],
                [-14.187639999999874, 10.152361000000155],
                [-14.187918, 10.152361000000155],
                [-14.187918, 10.153471],
                [-14.188193, 10.153471],
                [-14.188193, 10.154583000000173],
                [-14.188471999999877, 10.154583000000173],
                [-14.188471999999877, 10.155973],
                [-14.188193, 10.156527],
                [-14.187084, 10.156249000000173],
                [-14.186527, 10.155417],
                [-14.186527, 10.153751],
                [-14.185970999999881, 10.153195],
                [-14.185696, 10.152361000000155],
                [-14.185696, 10.151527000000101],
                [-14.185138999999879, 10.150973],
                [-14.185138999999879, 10.1498610000001],
                [-14.184861, 10.149863],
                [-14.184861, 10.148751],
                [-14.184583, 10.148751],
                [-14.184583, 10.147639],
                [-14.184304999999881, 10.146807],
                [-14.183749, 10.145973],
                [-14.182083, 10.145138],
                [-14.18097299999988, 10.145138],
                [-14.17930699999988, 10.145694000000105],
                [-14.179304999999886, 10.145973],
                [-14.178472999999883, 10.146251000000177],
                [-14.177917, 10.146807],
                [-14.176804999999888, 10.146807],
                [-14.175138999999888, 10.14736100000016],
                [-14.174582, 10.147639],
                [-14.174306999999885, 10.1481950000001],
                [-14.173194, 10.1481950000001],
                [-14.173194, 10.148473],
                [-14.17236, 10.148751],
                [-14.167916, 10.148751],
                [-14.167362, 10.1481950000001],
                [-14.167362, 10.147639],
                [-14.166805999999838, 10.147082000000182],
                [-14.165694, 10.146807],
                [-14.165694, 10.14736100000016],
                [-14.165139999999838, 10.147639],
                [-14.165139999999838, 10.1481950000001],
                [-14.16430599999984, 10.148473],
                [-14.164028, 10.149029000000155],
                [-14.163194, 10.149307],
                [-14.163194, 10.149863],
                [-14.162638, 10.150139],
                [-14.162084, 10.150695000000155],
                [-14.161528, 10.150695000000155],
                [-14.16125, 10.151251000000173],
                [-14.160693, 10.151527000000101],
                [-14.160418, 10.152083],
                [-14.159862, 10.152361000000155],
                [-14.159027, 10.152361000000155],
                [-14.158471, 10.152917000000173],
                [-14.157639, 10.152917000000173],
                [-14.157083, 10.153471],
                [-14.155692999999872, 10.153471],
                [-14.155692999999872, 10.153751],
                [-14.154860999999869, 10.154027000000156],
                [-14.152917, 10.154027000000156],
                [-14.152917, 10.154305],
                [-14.151805, 10.154305],
                [-14.151526999999874, 10.154583000000173],
                [-14.151526999999874, 10.156249000000173],
                [-14.152083, 10.156249000000173],
                [-14.152360999999871, 10.156805000000134],
                [-14.152917, 10.156805000000134],
                [-14.152917, 10.15736100000015],
                [-14.153473, 10.15736100000015],
                [-14.154305, 10.159027000000151],
                [-14.154305, 10.159862000000146],
                [-14.154860999999869, 10.160418000000107],
                [-14.154860999999869, 10.162362],
                [-14.154305, 10.162362],
                [-14.154305, 10.162916],
                [-14.153751, 10.162918],
                [-14.153751, 10.163472],
                [-14.152639, 10.164028],
                [-14.151805, 10.164028],
                [-14.151526999999874, 10.164584],
                [-14.150694999999871, 10.164860000000147],
                [-14.149860999999873, 10.164860000000147],
                [-14.14902899999987, 10.16514],
                [-14.148473, 10.165694],
                [-14.147639, 10.165694],
                [-14.14652599999988, 10.166806],
                [-14.145972, 10.166806],
                [-14.145416, 10.167362],
                [-14.145416, 10.169584],
                [-14.145693999999878, 10.169584],
                [-14.14652599999988, 10.17125],
                [-14.146807, 10.172363],
                [-14.147359999999878, 10.17263800000012],
                [-14.147359999999878, 10.173195],
                [-14.147917, 10.173195],
                [-14.147917, 10.173751],
                [-14.148751, 10.174026],
                [-14.148751, 10.174583],
                [-14.149583, 10.174583],
                [-14.150139, 10.175973],
                [-14.149860999999873, 10.176529000000187],
                [-14.150694999999871, 10.177083],
                [-14.152639, 10.177083],
                [-14.152639, 10.177361],
                [-14.154583, 10.177361],
                [-14.154583, 10.177639],
                [-14.155417, 10.177917],
                [-14.156526999999869, 10.177917],
                [-14.156526999999869, 10.178195000000187],
                [-14.157639, 10.178195000000187],
                [-14.158195, 10.17847300000011],
                [-14.158471, 10.179027],
                [-14.159027, 10.179305],
                [-14.159862, 10.179305],
                [-14.160418, 10.179861000000187],
                [-14.16125, 10.179861000000187],
                [-14.161806, 10.18013900000011],
                [-14.162084, 10.180695],
                [-14.163194, 10.181249],
                [-14.16375, 10.181249],
                [-14.16430599999984, 10.18180500000011],
                [-14.16486, 10.182639000000165],
                [-14.165416, 10.182639000000165],
                [-14.16597199999984, 10.183193000000188],
                [-14.16625, 10.183749],
                [-14.167362, 10.184305000000165],
                [-14.168471999999838, 10.185415],
                [-14.169584, 10.185971000000166],
                [-14.17014, 10.185971000000166],
                [-14.170971999999892, 10.186527000000183],
                [-14.17125, 10.187084],
                [-14.172082, 10.187918],
                [-14.172082, 10.189584],
                [-14.174306999999885, 10.189584],
                [-14.174306999999885, 10.189306],
                [-14.175419, 10.189306],
                [-14.175419, 10.189028000000178],
                [-14.177083, 10.189028000000178],
                [-14.177083, 10.18875],
                [-14.18097299999988, 10.18875],
                [-14.181527, 10.189306],
                [-14.181527, 10.190140000000156],
                [-14.182361, 10.190416],
                [-14.183472999999879, 10.191528],
                [-14.183749, 10.192084],
                [-14.183749, 10.192916],
                [-14.184304999999881, 10.193472000000156],
                [-14.184304999999881, 10.194582],
                [-14.185138999999879, 10.195138000000156],
                [-14.185415, 10.195694000000174],
                [-14.186527, 10.196806000000151],
                [-14.187639999999874, 10.197084],
              ],
            ],
            [
              [
                [-14.124305, 10.19375],
                [-14.124305, 10.194028000000174],
                [-14.125417, 10.194028000000174],
                [-14.125971, 10.194582],
                [-14.127083, 10.194582],
                [-14.127083, 10.194862],
                [-14.128473, 10.194862],
                [-14.128473, 10.195138000000156],
                [-14.129583, 10.195138000000156],
                [-14.129583, 10.194862],
                [-14.130694999999832, 10.194862],
                [-14.130694999999832, 10.194582],
                [-14.131805, 10.194582],
                [-14.13264, 10.194306],
                [-14.133195999999884, 10.19375],
                [-14.134027999999887, 10.19375],
                [-14.134584, 10.193472000000156],
                [-14.134861999999885, 10.192916],
                [-14.136806, 10.190974],
                [-14.136806, 10.189862],
                [-14.137084, 10.189862],
                [-14.137084, 10.18680600000016],
                [-14.137361999999882, 10.18680600000016],
                [-14.137361999999882, 10.183749],
                [-14.137084, 10.183749],
                [-14.137084, 10.182083],
                [-14.136806, 10.182083],
                [-14.136806, 10.180695],
                [-14.136527999999885, 10.180695],
                [-14.136527999999885, 10.179583],
                [-14.13625, 10.179583],
                [-14.13625, 10.17847300000011],
                [-14.135972, 10.17847300000011],
                [-14.135972, 10.177361],
                [-14.135418, 10.176251],
                [-14.135137, 10.176249],
                [-14.134584, 10.175139000000115],
                [-14.134584, 10.174583],
                [-14.134027999999887, 10.174307],
                [-14.134027999999887, 10.173751],
                [-14.133471, 10.17263800000012],
                [-14.132915, 10.172363],
                [-14.132915, 10.171807000000115],
                [-14.132083, 10.171528000000137],
                [-14.132083, 10.17097200000012],
                [-14.131249, 10.170416000000102],
                [-14.130971, 10.169860000000142],
                [-14.130971, 10.169028],
                [-14.130417, 10.169028],
                [-14.130139, 10.167916],
                [-14.129583, 10.167916],
                [-14.129583, 10.167084000000102],
                [-14.128473, 10.16625],
                [-14.128473, 10.165694],
                [-14.127639, 10.165416000000107],
                [-14.127639, 10.164860000000147],
                [-14.127083, 10.164860000000147],
                [-14.127083, 10.164306000000124],
                [-14.126527, 10.164028],
                [-14.125139, 10.164028],
                [-14.125139, 10.164306000000124],
                [-14.120695, 10.164306000000124],
                [-14.120695, 10.164028],
                [-14.119581999999866, 10.164028],
                [-14.118472, 10.163194000000146],
                [-14.117638, 10.163194000000146],
                [-14.117638, 10.162362],
                [-14.115694, 10.162362],
                [-14.115416, 10.162916],
                [-14.109862, 10.162916],
                [-14.109028, 10.162638000000129],
                [-14.10791799999987, 10.163472],
                [-14.10764, 10.164028],
                [-14.10764, 10.16514],
                [-14.107362, 10.16514],
                [-14.107362, 10.167638000000125],
                [-14.10764, 10.167638000000125],
                [-14.10764, 10.168750000000102],
                [-14.10791799999987, 10.168750000000102],
                [-14.10791799999987, 10.170694],
                [-14.107362, 10.17125],
                [-14.107083999999873, 10.172082000000103],
                [-14.104861, 10.172082000000103],
                [-14.104304999999897, 10.171807000000115],
                [-14.104027, 10.17125],
                [-14.103472999999894, 10.17125],
                [-14.103472999999894, 10.169582],
                [-14.10374899999988, 10.168750000000102],
                [-14.104582999999877, 10.169028],
                [-14.104582999999877, 10.170416000000102],
                [-14.10541499999988, 10.17097200000012],
                [-14.106527, 10.17097200000012],
                [-14.107083999999873, 10.170138],
                [-14.107083999999873, 10.168472],
                [-14.106806, 10.167638000000125],
                [-14.106527, 10.167638000000125],
                [-14.106248999999877, 10.166806],
                [-14.106248999999877, 10.163750000000107],
                [-14.106527, 10.163194000000146],
                [-14.107083999999873, 10.162918],
                [-14.10764, 10.162362],
                [-14.108749999999873, 10.162362],
                [-14.109028, 10.161806],
                [-14.110415999999873, 10.161806],
                [-14.110415999999873, 10.161528000000146],
                [-14.111806, 10.161528000000146],
                [-14.111806, 10.161806],
                [-14.114028, 10.161806],
                [-14.114028, 10.161528000000146],
                [-14.116249999999866, 10.161528000000146],
                [-14.117083999999863, 10.16125],
                [-14.117083999999863, 10.16014],
                [-14.11736, 10.16014],
                [-14.11736, 10.158196000000146],
                [-14.117638, 10.158196000000146],
                [-14.117638, 10.155417],
                [-14.11736, 10.155417],
                [-14.11736, 10.154305],
                [-14.117638, 10.154305],
                [-14.117638, 10.153195],
                [-14.11736, 10.153195],
                [-14.11736, 10.151805],
                [-14.116806, 10.151527000000101],
                [-14.116528, 10.150417],
                [-14.115972, 10.150417],
                [-14.115694, 10.149583000000177],
                [-14.11458199999987, 10.149583000000177],
                [-14.11458199999987, 10.149305],
                [-14.112917999999866, 10.149307],
                [-14.112638, 10.149863],
                [-14.111528, 10.150139],
                [-14.11125, 10.150695000000155],
                [-14.110415999999873, 10.150973],
                [-14.110415999999873, 10.151527000000101],
                [-14.109862, 10.151527000000101],
                [-14.109862, 10.152083],
                [-14.109028, 10.152361000000155],
                [-14.109028, 10.152917000000173],
                [-14.10791799999987, 10.152917000000173],
                [-14.10764, 10.153471],
                [-14.107083999999873, 10.153751],
                [-14.106248999999877, 10.153751],
                [-14.10541499999988, 10.154027000000156],
                [-14.104861, 10.154583000000173],
                [-14.1018049999999, 10.154583000000173],
                [-14.10041699999988, 10.154027000000156],
                [-14.1001389999999, 10.153471],
                [-14.099305, 10.152917000000173],
                [-14.09875099999988, 10.151805],
                [-14.09875099999988, 10.150973],
                [-14.098195, 10.150417],
                [-14.098195, 10.148473],
                [-14.097916999999882, 10.148473],
                [-14.097916999999882, 10.14736100000016],
                [-14.097082999999827, 10.145694000000105],
                [-14.096529, 10.14486],
                [-14.095695, 10.144585000000177],
                [-14.094307, 10.144585000000177],
                [-14.094307, 10.145138],
                [-14.093750999999827, 10.145416000000182],
                [-14.093750999999827, 10.145973],
                [-14.092916, 10.147082000000182],
                [-14.092916, 10.147917000000177],
                [-14.09236, 10.149029000000155],
                [-14.091807, 10.149307],
                [-14.09125, 10.150417],
                [-14.09125, 10.150973],
                [-14.090694, 10.151251000000173],
                [-14.090694, 10.151805],
                [-14.08986, 10.152083],
                [-14.08986, 10.152639],
                [-14.089306, 10.152639],
                [-14.089306, 10.153195],
                [-14.088472, 10.153471],
                [-14.088472, 10.154027000000156],
                [-14.087638, 10.154027000000156],
                [-14.087082, 10.154861],
                [-14.085972, 10.155139000000133],
                [-14.085972, 10.155693000000156],
                [-14.08514, 10.155693000000156],
                [-14.084584, 10.156249000000173],
                [-14.081805999999858, 10.156249000000173],
                [-14.080139999999858, 10.155417],
                [-14.079305, 10.154583000000173],
                [-14.079027, 10.154027000000156],
                [-14.078471, 10.154027000000156],
                [-14.078471, 10.153195],
                [-14.077917, 10.152639],
                [-14.077639, 10.151805],
                [-14.077639, 10.149305],
                [-14.077361, 10.149307],
                [-14.077361, 10.14736100000016],
                [-14.077639, 10.14736100000016],
                [-14.077639, 10.146251000000177],
                [-14.078749, 10.145138],
                [-14.078749, 10.144585000000177],
                [-14.079305, 10.144304],
                [-14.079584, 10.143194],
                [-14.080139999999858, 10.142638],
                [-14.080139999999858, 10.141528],
                [-14.079862, 10.14069400000011],
                [-14.079305, 10.14069400000011],
                [-14.078749, 10.140138],
                [-14.077917, 10.139862],
                [-14.076527, 10.139862],
                [-14.076527, 10.140138],
                [-14.075139, 10.140138],
                [-14.075139, 10.140416000000187],
                [-14.074027, 10.140972],
                [-14.07347099999987, 10.140972],
                [-14.073195, 10.141528],
                [-14.072083, 10.142082000000187],
                [-14.070973, 10.14236000000011],
                [-14.070695, 10.142916],
                [-14.07013899999987, 10.142916],
                [-14.07013899999987, 10.144304],
                [-14.06986099999989, 10.144304],
                [-14.06986099999989, 10.1481950000001],
                [-14.07013899999987, 10.1481950000001],
                [-14.070417, 10.149029000000155],
                [-14.070973, 10.150139],
                [-14.07152699999989, 10.150695000000155],
                [-14.07152699999989, 10.151251000000173],
                [-14.072083, 10.151805],
                [-14.072361, 10.152361000000155],
                [-14.072917, 10.152639],
                [-14.072917, 10.153195],
                [-14.07347099999987, 10.154305],
                [-14.073749, 10.154305],
                [-14.074861, 10.156527],
                [-14.074861, 10.157083],
                [-14.075417, 10.15736100000015],
                [-14.075417, 10.158196000000146],
                [-14.075973, 10.158749],
                [-14.076249, 10.159305],
                [-14.076249, 10.16014],
                [-14.076805, 10.160693000000151],
                [-14.076805, 10.16125],
                [-14.078471, 10.164584],
                [-14.078749, 10.165416000000107],
                [-14.079305, 10.165972000000124],
                [-14.079305, 10.169860000000142],
                [-14.079584, 10.169860000000142],
                [-14.079584, 10.17097200000012],
                [-14.079862, 10.17097200000012],
                [-14.079862, 10.17263800000012],
                [-14.080139999999858, 10.173195],
                [-14.08125, 10.173751],
                [-14.084584, 10.173751],
                [-14.084584, 10.173473000000115],
                [-14.086528, 10.173473000000115],
                [-14.086528, 10.173195],
                [-14.090138, 10.173195],
                [-14.090694, 10.173751],
                [-14.091528, 10.173751],
                [-14.092082, 10.174307],
                [-14.092916, 10.174307],
                [-14.093195, 10.174861],
                [-14.094026, 10.175695],
                [-14.094307, 10.176251],
                [-14.094861, 10.176529000000187],
                [-14.094861, 10.177361],
                [-14.095416999999827, 10.177639],
                [-14.095695, 10.178751],
                [-14.09624899999983, 10.179027],
                [-14.09624899999983, 10.179583],
                [-14.096805, 10.179861000000187],
                [-14.097082999999827, 10.180973000000165],
                [-14.098195, 10.18180500000011],
                [-14.098472999999899, 10.182361],
                [-14.10041699999988, 10.182361],
                [-14.10041699999988, 10.182083],
                [-14.101527, 10.182083],
                [-14.101527, 10.18180500000011],
                [-14.105696, 10.18180500000011],
                [-14.105696, 10.182083],
                [-14.107362, 10.182917],
                [-14.10791799999987, 10.182917],
                [-14.108194, 10.183473000000106],
                [-14.108749999999873, 10.183473000000106],
                [-14.109306, 10.184305000000165],
                [-14.110972, 10.185971000000166],
                [-14.112362, 10.186527000000183],
                [-14.113472, 10.187084],
                [-14.115416, 10.187084],
                [-14.115416, 10.187362000000178],
                [-14.116528, 10.187362000000178],
                [-14.117083999999863, 10.18764],
                [-14.11736, 10.188194],
                [-14.118472, 10.18875],
                [-14.119029, 10.18875],
                [-14.119029, 10.189306],
                [-14.11986, 10.189584],
                [-14.11986, 10.190140000000156],
                [-14.120417, 10.190140000000156],
                [-14.120695, 10.190974],
                [-14.121251, 10.190972],
                [-14.121251, 10.191528],
                [-14.122083, 10.191806000000156],
                [-14.122083, 10.192360000000178],
                [-14.122639, 10.192360000000178],
                [-14.122917, 10.192916],
                [-14.123473, 10.192916],
                [-14.123473, 10.193472000000156],
                [-14.124305, 10.19375],
              ],
            ],
            [
              [
                [-14.192916, 10.228751],
                [-14.194028, 10.229861],
                [-14.194028, 10.230417],
                [-14.194584, 10.230417],
                [-14.194862, 10.229305],
                [-14.195416, 10.228473000000179],
                [-14.196806, 10.228473000000179],
                [-14.197638, 10.228195],
                [-14.197638, 10.227917000000161],
                [-14.199307, 10.227917000000161],
                [-14.199863, 10.227639],
                [-14.200416, 10.227082000000166],
                [-14.200973, 10.227082000000166],
                [-14.201250999999843, 10.226529],
                [-14.201250999999843, 10.225138],
                [-14.200973, 10.225138],
                [-14.200973, 10.223472],
                [-14.200695, 10.223472],
                [-14.200695, 10.21708400000017],
                [-14.202082999999845, 10.215694],
                [-14.202082999999845, 10.21514],
                [-14.202639, 10.214862],
                [-14.202639, 10.213471],
                [-14.202916999999843, 10.21264],
                [-14.203195, 10.21264],
                [-14.203195, 10.211527000000103],
                [-14.202639, 10.210971000000143],
                [-14.202639, 10.209583],
                [-14.202361, 10.209583],
                [-14.202361, 10.208473],
                [-14.202916999999843, 10.207639000000142],
                [-14.203473, 10.207639000000142],
                [-14.203748999999846, 10.207083000000125],
                [-14.204305, 10.207083000000125],
                [-14.204861, 10.206527000000108],
                [-14.206527, 10.206527000000108],
                [-14.206527, 10.206251],
                [-14.207639, 10.206251],
                [-14.207639, 10.205973000000142],
                [-14.208748999999898, 10.205973000000142],
                [-14.210416999999893, 10.205417000000125],
                [-14.210416999999893, 10.205139],
                [-14.212082999999893, 10.205139],
                [-14.212082999999893, 10.205417000000125],
                [-14.212914999999896, 10.205695],
                [-14.213748999999893, 10.205695],
                [-14.214862, 10.206805],
                [-14.215137, 10.207917],
                [-14.215693999999814, 10.208473],
                [-14.216249999999889, 10.208749000000125],
                [-14.218193999999812, 10.208749000000125],
                [-14.219306, 10.208195000000103],
                [-14.220694, 10.206805],
                [-14.220972, 10.206251],
                [-14.220972, 10.204305000000147],
                [-14.220415999999886, 10.203195000000107],
                [-14.219028, 10.202361],
                [-14.219028, 10.201805],
                [-14.218472, 10.201805],
                [-14.218472, 10.201251],
                [-14.217362, 10.200973000000147],
                [-14.216527999999812, 10.200138000000152],
                [-14.21653, 10.199582000000134],
                [-14.215972, 10.198194],
                [-14.215974, 10.196806000000151],
                [-14.216249999999889, 10.196250000000134],
                [-14.216806, 10.195972],
                [-14.216806, 10.195416],
                [-14.217915999999889, 10.194582],
                [-14.218472, 10.194582],
                [-14.220138, 10.19375],
                [-14.220415999999886, 10.193194],
                [-14.221249999999884, 10.193194],
                [-14.221806, 10.19264],
                [-14.223194, 10.19264],
                [-14.223472, 10.192360000000178],
                [-14.223472, 10.190140000000156],
                [-14.223749999999882, 10.190140000000156],
                [-14.223749999999882, 10.187362000000178],
                [-14.223194, 10.186527000000183],
                [-14.222638, 10.186527000000183],
                [-14.219862, 10.185139000000106],
                [-14.219028, 10.184861000000183],
                [-14.217362, 10.184027],
                [-14.217083999999886, 10.183473000000106],
                [-14.215974, 10.183193000000188],
                [-14.215693999999814, 10.182639000000165],
                [-14.214583999999888, 10.182361],
                [-14.214306, 10.18180500000011],
                [-14.213748999999893, 10.18180500000011],
                [-14.213471, 10.181249],
                [-14.212914999999896, 10.181249],
                [-14.212639, 10.180695],
                [-14.211527, 10.180417],
                [-14.211248999999896, 10.179861000000187],
                [-14.210695, 10.179861000000187],
                [-14.210416999999893, 10.179305],
                [-14.209861, 10.179305],
                [-14.209582999999895, 10.178751],
                [-14.209029, 10.17847300000011],
                [-14.208195, 10.17847300000011],
                [-14.207639, 10.177917],
                [-14.206805, 10.177639],
                [-14.205695, 10.177639],
                [-14.204029, 10.177083],
                [-14.204029, 10.176805000000115],
                [-14.203195, 10.176529000000187],
                [-14.19736, 10.176529000000187],
                [-14.196528, 10.176805000000115],
                [-14.196528, 10.177083],
                [-14.195416, 10.177083],
                [-14.194584, 10.177361],
                [-14.194028, 10.177917],
                [-14.193194, 10.177917],
                [-14.19264, 10.178195000000187],
                [-14.192084, 10.179583],
                [-14.192084, 10.182917],
                [-14.192362, 10.182917],
                [-14.192362, 10.184861000000183],
                [-14.192637999999874, 10.184861000000183],
                [-14.193194, 10.185971000000166],
                [-14.193472, 10.18680600000016],
                [-14.194306, 10.18847200000016],
                [-14.194582, 10.189584],
                [-14.195138, 10.189862],
                [-14.195138, 10.190418],
                [-14.195694, 10.191528],
                [-14.196528, 10.192084],
                [-14.196528, 10.19264],
                [-14.19736, 10.193194],
                [-14.197638, 10.19375],
                [-14.19875, 10.194862],
                [-14.19875, 10.195416],
                [-14.199582, 10.196250000000134],
                [-14.200138, 10.196528],
                [-14.200138, 10.197084],
                [-14.200973, 10.197638],
                [-14.200973, 10.198194],
                [-14.201529, 10.198472000000152],
                [-14.201529, 10.199029],
                [-14.202082999999845, 10.199582000000134],
                [-14.202639, 10.200695],
                [-14.202916999999843, 10.201526],
                [-14.202916999999843, 10.202639000000147],
                [-14.203195, 10.202639000000147],
                [-14.203195, 10.204029],
                [-14.203473, 10.204029],
                [-14.203473, 10.205695],
                [-14.203195, 10.205695],
                [-14.202639, 10.206805],
                [-14.202361, 10.207917],
                [-14.201804, 10.207917],
                [-14.201804, 10.208473],
                [-14.201250999999843, 10.208749000000125],
                [-14.200973, 10.209861000000103],
                [-14.200416, 10.210139],
                [-14.200416, 10.210695],
                [-14.199863, 10.210695],
                [-14.199582, 10.211249],
                [-14.199028, 10.211249],
                [-14.197916, 10.212361],
                [-14.19736, 10.212361],
                [-14.197084, 10.212918000000116],
                [-14.196528, 10.212915],
                [-14.19625, 10.213474],
                [-14.195138, 10.21374900000012],
                [-14.194862, 10.214306],
                [-14.19375, 10.214862],
                [-14.193194, 10.21541800000017],
                [-14.192637999999874, 10.216528],
                [-14.191806, 10.217362],
                [-14.191528, 10.217916000000116],
                [-14.190971999999874, 10.218194],
                [-14.190971999999874, 10.219306000000188],
                [-14.190694, 10.219306000000188],
                [-14.190694, 10.221528],
                [-14.190971999999874, 10.221528],
                [-14.190971999999874, 10.223472],
                [-14.19125, 10.223472],
                [-14.19125, 10.225138],
                [-14.191528, 10.225138],
                [-14.191528, 10.226251000000161],
                [-14.191806, 10.226251000000161],
                [-14.191806, 10.22736],
                [-14.192084, 10.228195],
                [-14.192916, 10.228751],
              ],
            ],
            [
              [
                [-14.302638000999877, 10.22041600000017],
                [-14.302638000999877, 10.221249999000179],
                [-14.303194, 10.222637999000085],
                [-14.304306, 10.222916000999987],
                [-14.305416, 10.222916000999987],
                [-14.30597199899995, 10.222361999000157],
                [-14.306528000999947, 10.222361999000157],
                [-14.30735999899997, 10.221249999000179],
                [-14.307362998999963, 10.22041600000017],
                [-14.307916000999967, 10.219861999000102],
                [-14.307916000999967, 10.218750001000103],
                [-14.307362998999963, 10.218194],
                [-14.30735999899997, 10.217640000000188],
                [-14.306807, 10.217362001000026],
                [-14.306807, 10.216806],
                [-14.306249998999931, 10.216528],
                [-14.305416, 10.214862001000085],
                [-14.305416, 10.214028],
                [-14.303750000999969, 10.210694999000111],
                [-14.303194, 10.210139],
                [-14.303194, 10.208195001000036],
                [-14.302916, 10.208195001000036],
                [-14.302916, 10.206527000000108],
                [-14.302638000999877, 10.206527000000108],
                [-14.302638000999877, 10.205139],
                [-14.302916, 10.205139],
                [-14.302916, 10.204028999000116],
                [-14.303194, 10.204028999000116],
                [-14.303471998999953, 10.203195000000107],
                [-14.303471998999953, 10.202361],
                [-14.304028, 10.201250999000138],
                [-14.304859998999973, 10.200695],
                [-14.305138000999875, 10.200137999000049],
                [-14.306249998999931, 10.199029001000156],
                [-14.306249998999931, 10.198472000000152],
                [-14.306807, 10.198472000000152],
                [-14.307082, 10.197916001000067],
                [-14.307082, 10.195138001000089],
                [-14.307638000999873, 10.195138001000089],
                [-14.307916000999967, 10.194582],
                [-14.308750998999926, 10.194306],
                [-14.308750998999926, 10.192916],
                [-14.308473, 10.192359999000075],
                [-14.307916000999967, 10.192083999000147],
                [-14.307638000999873, 10.191528],
                [-14.307638000999873, 10.190694000000178],
                [-14.306807, 10.189862001000165],
                [-14.306249998999931, 10.189583999000092],
                [-14.306249998999931, 10.189028000000178],
                [-14.305416, 10.18875],
                [-14.304584, 10.187918],
                [-14.304584, 10.187362001000054],
                [-14.303750000999969, 10.187084001000187],
                [-14.303471998999953, 10.186527000000183],
                [-14.302916, 10.186527000000183],
                [-14.302638000999877, 10.185971001000098],
                [-14.302081998999881, 10.185971001000098],
                [-14.301528, 10.185415],
                [-14.301528, 10.184861000000183],
                [-14.300694, 10.184304999000062],
                [-14.30013799999989, 10.183749],
                [-14.299583998999879, 10.183749],
                [-14.299028, 10.182916998999985],
                [-14.297084000999973, 10.180973000000165],
                [-14.296528, 10.180695001000174],
                [-14.295692998999982, 10.179861000000187],
                [-14.29541799899988, 10.179305001000102],
                [-14.294862, 10.179305001000102],
                [-14.294584000999976, 10.178751],
                [-14.293471000999887, 10.17847300000011],
                [-14.29291499899989, 10.177638999000123],
                [-14.29208300099998, 10.17680500099999],
                [-14.290972999999894, 10.176249],
                [-14.290695000999904, 10.175695],
                [-14.289582999999823, 10.175417001000085],
                [-14.288472999999897, 10.174307],
                [-14.28763899899991, 10.174307],
                [-14.28763899899991, 10.173750999000106],
                [-14.287082999999825, 10.173195],
                [-14.286526998999932, 10.173195],
                [-14.28625099899989, 10.172637999000017],
                [-14.285138998999912, 10.172637999000017],
                [-14.284305, 10.171807000000115],
                [-14.28374899899984, 10.171528001000013],
                [-14.28374899899984, 10.170694],
                [-14.283473, 10.170138001000112],
                [-14.282639000999836, 10.169859999000039],
                [-14.28152900099991, 10.169859999000039],
                [-14.280694, 10.169584],
                [-14.280416, 10.168750001000035],
                [-14.279582, 10.168750001000035],
                [-14.279028, 10.169028],
                [-14.278750000999935, 10.168750001000035],
                [-14.278750000999935, 10.167362001000129],
                [-14.278471998999919, 10.166806],
                [-14.277916, 10.166806],
                [-14.277359998999941, 10.165416000000107],
                [-14.276528, 10.165416000000107],
                [-14.276250000999937, 10.164860001000022],
                [-14.275694, 10.164860001000022],
                [-14.275416, 10.164306000000124],
                [-14.274305999999854, 10.16375000100004],
                [-14.27347200099996, 10.16375000100004],
                [-14.272360000999868, 10.162918],
                [-14.27125, 10.162918],
                [-14.27125, 10.163193999000043],
                [-14.269584000999942, 10.163193999000043],
                [-14.269305998999926, 10.162638000000129],
                [-14.268473999999856, 10.162638000000129],
                [-14.268193000999872, 10.163193999000043],
                [-14.267362, 10.163193999000043],
                [-14.267362, 10.162638000000129],
                [-14.266804998999874, 10.162362001000133],
                [-14.266804998999874, 10.16125],
                [-14.266249, 10.16125],
                [-14.26569500099987, 10.160692999000048],
                [-14.265139, 10.160418000000107],
                [-14.264305000999968, 10.160418000000107],
                [-14.262638998999876, 10.159584001000155],
                [-14.262638998999876, 10.159304999000142],
                [-14.259027000999879, 10.159304999000142],
                [-14.259027000999879, 10.159027000000151],
                [-14.256804999999872, 10.159027000000151],
                [-14.256804999999872, 10.158749],
                [-14.254859998999905, 10.158749],
                [-14.254582, 10.159862000000146],
                [-14.254026000999886, 10.159862000000146],
                [-14.254026000999886, 10.160418000000107],
                [-14.25263800099998, 10.161805999000137],
                [-14.252359998999907, 10.161805999000137],
                [-14.252359998999907, 10.163472001000116],
                [-14.252083998999979, 10.163472001000116],
                [-14.252083998999979, 10.164583999000115],
                [-14.251805999999817, 10.164583999000115],
                [-14.251805999999817, 10.165694],
                [-14.251250000999903, 10.167083999000056],
                [-14.250416, 10.167638001000057],
                [-14.24930599999982, 10.168750001000035],
                [-14.249027999999896, 10.16930600000012],
                [-14.248472000999982, 10.169859999000039],
                [-14.24819399899991, 10.170694],
                [-14.247084000999905, 10.17124999900011],
                [-14.24625, 10.17291600100009],
                [-14.24625, 10.173473000000115],
                [-14.245693998999911, 10.174025999000094],
                [-14.24486, 10.175695],
                [-14.244584000999907, 10.176528999000084],
                [-14.244584000999907, 10.177638999000123],
                [-14.244028, 10.17819500100012],
                [-14.244028, 10.180695001000174],
                [-14.244305998999835, 10.181527000000187],
                [-14.244584000999907, 10.181527000000187],
                [-14.245416, 10.182361],
                [-14.245693998999911, 10.182916998999985],
                [-14.24625, 10.183192999000084],
                [-14.246527999999898, 10.183749],
                [-14.247362000999885, 10.183749],
                [-14.24819399899991, 10.184304999000062],
                [-14.24819399899991, 10.184861000000183],
                [-14.249027999999896, 10.185139000000106],
                [-14.250416, 10.185139000000106],
                [-14.250416, 10.184861000000183],
                [-14.251527999999894, 10.184861000000183],
                [-14.251250000999903, 10.185695999000075],
                [-14.250416, 10.185971001000098],
                [-14.249027999999896, 10.185971001000098],
                [-14.249027999999896, 10.185695999000075],
                [-14.247362000999885, 10.185695999000075],
                [-14.247084000999905, 10.185139000000106],
                [-14.246527999999898, 10.185139000000106],
                [-14.245416, 10.184027],
                [-14.245416, 10.183749],
                [-14.244584000999907, 10.182916998999985],
                [-14.243472, 10.18208300100008],
                [-14.243472, 10.181527000000187],
                [-14.242917998999928, 10.181249],
                [-14.239860999999848, 10.181249],
                [-14.239305000999934, 10.182639000000165],
                [-14.238194999999848, 10.182916998999985],
                [-14.237917000999914, 10.183473000000106],
                [-14.23736099999985, 10.183749],
                [-14.23652699899992, 10.183749],
                [-14.235973, 10.184304999000062],
                [-14.234305, 10.184861000000183],
                [-14.233471, 10.184861000000183],
                [-14.232917000999862, 10.185415],
                [-14.232083, 10.185695999000075],
                [-14.230695, 10.185695999000075],
                [-14.230139000999941, 10.186249],
                [-14.229305, 10.186249],
                [-14.228751000999864, 10.186527000000183],
                [-14.228472998999962, 10.187084001000187],
                [-14.227638000999946, 10.187362001000054],
                [-14.22708199899995, 10.188472001000093],
                [-14.22708199899995, 10.191250001000071],
                [-14.227917, 10.192083999000147],
                [-14.229029, 10.192359999000075],
                [-14.229305, 10.192916],
                [-14.230417, 10.193194],
                [-14.23097299899996, 10.193471999000053],
                [-14.23125099899994, 10.194028000000174],
                [-14.233471, 10.195138001000089],
                [-14.234027000999959, 10.195138001000089],
                [-14.234583, 10.195694000000174],
                [-14.23569499999985, 10.196249999000031],
                [-14.23736099999985, 10.196249999000031],
                [-14.237917000999914, 10.19652800100016],
                [-14.238194999999848, 10.197084],
                [-14.238749, 10.197084],
                [-14.239026998999918, 10.197637999000165],
                [-14.239583, 10.197916001000067],
                [-14.24041799899993, 10.197916001000067],
                [-14.240971, 10.198472000000152],
                [-14.24180600099993, 10.198472000000152],
                [-14.242362, 10.198749999000142],
                [-14.242637, 10.199307000000147],
                [-14.24319400099995, 10.199307000000147],
                [-14.243472, 10.19986],
                [-14.244028, 10.19986],
                [-14.244584000999907, 10.200417001000062],
                [-14.244584000999907, 10.200973000000147],
                [-14.24514, 10.201525999000125],
                [-14.24514, 10.202638999000044],
                [-14.245416, 10.203195000000107],
                [-14.24486, 10.203473],
                [-14.244584000999907, 10.204028999000116],
                [-14.244584000999907, 10.205695001000095],
                [-14.244305998999835, 10.205695001000095],
                [-14.244305998999835, 10.208749000000125],
                [-14.24486, 10.209027],
                [-14.24486, 10.209583001000112],
                [-14.24680599899989, 10.209583001000112],
                [-14.24680599899989, 10.20930499900004],
                [-14.248472000999982, 10.20930499900004],
                [-14.24958399899998, 10.210139],
                [-14.249860000999831, 10.210971001000019],
                [-14.249860000999831, 10.213195999000106],
                [-14.250416, 10.214306],
                [-14.251805999999817, 10.214862001000085],
                [-14.252083998999979, 10.21541800000017],
                [-14.254582, 10.21541800000017],
                [-14.255417, 10.215137],
                [-14.25569499999989, 10.214583999000183],
                [-14.256251000999953, 10.214306],
                [-14.256529000999876, 10.213749001],
                [-14.257085, 10.213195999000106],
                [-14.257085, 10.21264],
                [-14.257639000999973, 10.21236100100009],
                [-14.257639000999973, 10.211804999000094],
                [-14.258194999999887, 10.211249],
                [-14.259307, 10.21041700000012],
                [-14.259860999999887, 10.21041700000012],
                [-14.26180500099997, 10.208473],
                [-14.262083, 10.207639000000142],
                [-14.264026998999952, 10.207639000000142],
                [-14.264305000999968, 10.208195001000036],
                [-14.264861, 10.208195001000036],
                [-14.265416998999967, 10.209027],
                [-14.266249, 10.20930499900004],
                [-14.267083000999946, 10.20930499900004],
                [-14.267917998999962, 10.210139],
                [-14.267917998999962, 10.211804999000094],
                [-14.26764, 10.21236100100009],
                [-14.267083000999946, 10.212918000000116],
                [-14.265416998999967, 10.212915],
                [-14.265416998999967, 10.213195999000106],
                [-14.264026998999952, 10.213195999000106],
                [-14.263473, 10.21347100100013],
                [-14.262917000999948, 10.214028],
                [-14.262917000999948, 10.215137],
                [-14.264305000999968, 10.21597199900009],
                [-14.265971, 10.21597199900009],
                [-14.26652699899995, 10.21625000099999],
                [-14.266804998999874, 10.216806],
                [-14.26764, 10.217083999000067],
                [-14.26875, 10.217083999000067],
                [-14.269584000999942, 10.216806],
                [-14.270139999999856, 10.21597199900009],
                [-14.270693998999946, 10.21597199900009],
                [-14.270972000999961, 10.215137],
                [-14.27208400099994, 10.214028],
                [-14.27208400099994, 10.213749001],
                [-14.274862000999917, 10.213749001],
                [-14.274862000999917, 10.214028],
                [-14.275971998999921, 10.214028],
                [-14.276528, 10.214862001000085],
                [-14.277359998999941, 10.21541800000017],
                [-14.277638000999957, 10.21625000099999],
                [-14.277638000999957, 10.217362001000026],
                [-14.277916, 10.217362001000026],
                [-14.277916, 10.218750001000103],
                [-14.278194, 10.219306000000188],
                [-14.279028, 10.22013800100018],
                [-14.279862998999931, 10.22013800100018],
                [-14.280416, 10.219861999000102],
                [-14.280416, 10.219306000000188],
                [-14.280972998999914, 10.219028],
                [-14.281807, 10.217916000000116],
                [-14.281807, 10.217362001000026],
                [-14.282639000999836, 10.216806],
                [-14.282639000999836, 10.21625000099999],
                [-14.283473, 10.21514],
                [-14.285417000999928, 10.213195999000106],
                [-14.285972999999899, 10.21236100100009],
                [-14.286526998999932, 10.21236100100009],
                [-14.286805000999891, 10.211804999000094],
                [-14.287361, 10.211804999000094],
                [-14.28763899899991, 10.211249],
                [-14.288195, 10.210971001000019],
                [-14.289305000999889, 10.210971001000019],
                [-14.289861, 10.21041700000012],
                [-14.29291499899989, 10.21041700000012],
                [-14.29291499899989, 10.210694999000111],
                [-14.295692998999982, 10.210694999000111],
                [-14.29847200099988, 10.212082999000188],
                [-14.29875, 10.21264],
                [-14.299306, 10.21264],
                [-14.299862000999951, 10.213195999000106],
                [-14.300694, 10.214862001000085],
                [-14.300694, 10.21541800000017],
                [-14.301250000999971, 10.21597199900009],
                [-14.301528, 10.216528],
                [-14.301528, 10.217640000000188],
                [-14.301806, 10.217640000000188],
                [-14.301806, 10.218750001000103],
                [-14.302081998999881, 10.219584000000111],
                [-14.302362000999949, 10.219584000000111],
                [-14.302638000999877, 10.22041600000017],
              ],
            ],
            [
              [
                [-14.472639, 10.277084],
                [-14.472639, 10.275694000000158],
                [-14.47208300099993, 10.274862],
                [-14.470973, 10.274862],
                [-14.470973, 10.275416001000167],
                [-14.470416998999838, 10.275416001000167],
                [-14.470139, 10.276249999000072],
                [-14.470139, 10.277084],
                [-14.46986, 10.277637999000149],
                [-14.469307000999834, 10.277637999000149],
                [-14.468751, 10.278472000000136],
                [-14.46847199999985, 10.279307001],
                [-14.46847199999985, 10.280415999000127],
                [-14.46986, 10.280415999000127],
                [-14.470416998999838, 10.280138000000136],
                [-14.471248, 10.279307001],
                [-14.471529, 10.278194001000145],
                [-14.472639, 10.277084],
              ],
            ],
            [
              [
                [-14.591529998999874, 10.505695001000106],
                [-14.592083999999886, 10.506526999000073],
                [-14.5929159989999, 10.506805001000146],
                [-14.5929159989999, 10.507361],
                [-14.593749999999886, 10.507361],
                [-14.59402799899982, 10.506805001000146],
                [-14.594584000999816, 10.506805001000146],
                [-14.594862, 10.506251],
                [-14.595417998999892, 10.505971000000159],
                [-14.597915999999884, 10.505971000000159],
                [-14.599028, 10.504861000000119],
                [-14.599028, 10.500417001000073],
                [-14.597915999999884, 10.50013900100015],
                [-14.59736, 10.500695],
                [-14.595138, 10.500695],
                [-14.59402799899982, 10.50013900100015],
                [-14.59402799899982, 10.499860999000077],
                [-14.5929159989999, 10.499305000000163],
                [-14.592083999999886, 10.498472999000171],
                [-14.590971, 10.498472999000171],
                [-14.591248999999834, 10.499305000000163],
                [-14.591248999999834, 10.500417001000073],
                [-14.591806000999895, 10.500971000000163],
                [-14.591806000999895, 10.503195000000119],
                [-14.590971, 10.503749000000141],
                [-14.590971, 10.504583],
                [-14.591529998999874, 10.505695001000106],
              ],
            ],
            [
              [
                [-14.588748999999837, 10.505971000000159],
                [-14.588748999999837, 10.507083000000137],
                [-14.589305, 10.507083000000137],
                [-14.589582999999834, 10.507639000000154],
                [-14.590971, 10.50819300000012],
                [-14.592083999999886, 10.50819300000012],
                [-14.592083999999886, 10.507083000000137],
                [-14.591528, 10.507083000000137],
                [-14.591248999999834, 10.50652700000012],
                [-14.590693, 10.506251],
                [-14.590417999999886, 10.505417000000136],
                [-14.589582999999834, 10.505417000000136],
                [-14.588748999999837, 10.505971000000159],
              ],
            ],
            [
              [
                [-14.619861999999841, 10.759027999000182],
                [-14.62014, 10.759584],
                [-14.620694000999947, 10.759584],
                [-14.620972, 10.760138],
                [-14.621527999999842, 10.760415999000088],
                [-14.622361999999839, 10.760138],
                [-14.62514, 10.760138],
                [-14.625416, 10.759584],
                [-14.625972000999923, 10.759584],
                [-14.62625, 10.759027999000182],
                [-14.626806, 10.759027999000182],
                [-14.627083998999979, 10.758472],
                [-14.627916, 10.758194001000106],
                [-14.627916, 10.757638],
                [-14.629027999999892, 10.757362],
                [-14.63013800099992, 10.75625],
                [-14.630416, 10.755694],
                [-14.63097299899988, 10.755139999000164],
                [-14.63152600099994, 10.755139999000164],
                [-14.632639000999973, 10.754028001000165],
                [-14.633194999999887, 10.754028001000165],
                [-14.633472998999878, 10.753472000000102],
                [-14.634583, 10.752915001000076],
                [-14.63513900099997, 10.752915001000076],
                [-14.635417, 10.752361999000186],
                [-14.636529000999872, 10.752084],
                [-14.636805, 10.751527001],
                [-14.637360999999885, 10.751527001],
                [-14.637638998999876, 10.750971000000106],
                [-14.638749, 10.750693000000183],
                [-14.639026998999896, 10.750139001000093],
                [-14.639583, 10.750139001000093],
                [-14.64236099999988, 10.74736099900008],
                [-14.642917998999962, 10.745972999],
                [-14.642917998999962, 10.745139],
                [-14.643474, 10.744582999000102],
                [-14.643474, 10.743194999000025],
                [-14.643749, 10.743194999000025],
                [-14.643749, 10.741527000000133],
                [-14.643474, 10.741527000000133],
                [-14.643474, 10.740139],
                [-14.643193000999872, 10.740139],
                [-14.643193000999872, 10.738473001000102],
                [-14.642917998999962, 10.738473001000102],
                [-14.642917998999962, 10.736250000000155],
                [-14.643474, 10.73569400100007],
                [-14.643749, 10.734862],
                [-14.643749, 10.733194001000129],
                [-14.644026998999948, 10.733196001000124],
                [-14.644026998999948, 10.732082],
                [-14.644861999999875, 10.729584000000159],
                [-14.645414998999968, 10.729584000000159],
                [-14.645696000999976, 10.728472000000124],
                [-14.64625, 10.728194],
                [-14.646527999999876, 10.727084],
                [-14.64708400099994, 10.727084],
                [-14.64708400099994, 10.726528001000133],
                [-14.647917998999958, 10.72624999900006],
                [-14.648193998999943, 10.725696000000141],
                [-14.64875, 10.725696000000141],
                [-14.649862, 10.724584000000164],
                [-14.650416, 10.724305000000186],
                [-14.650416, 10.723748999000065],
                [-14.65125, 10.723473999000078],
                [-14.651806, 10.722639001000061],
                [-14.652916, 10.721527],
                [-14.652916, 10.720970999000087],
                [-14.653750000999935, 10.720139],
                [-14.654306, 10.720139],
                [-14.654581998999959, 10.719582999000181],
                [-14.655138000999955, 10.719582999000181],
                [-14.655416, 10.719027],
                [-14.656529, 10.718194999000104],
                [-14.657084998999949, 10.718194999000104],
                [-14.657638000999953, 10.71763900000019],
                [-14.658195, 10.716251],
                [-14.658195, 10.715139],
                [-14.657916, 10.714306999000087],
                [-14.657359998999937, 10.714027000000101],
                [-14.657359998999937, 10.713473001000182],
                [-14.656806999999844, 10.713195001000088],
                [-14.656806999999844, 10.712639],
                [-14.655694, 10.712082001],
                [-14.655694, 10.711251000000118],
                [-14.655138000999955, 10.711251000000118],
                [-14.654862000999913, 10.710138],
                [-14.654306, 10.710138],
                [-14.654028, 10.709582001000115],
                [-14.653193998999939, 10.708750000000123],
                [-14.653193998999939, 10.707915999000136],
                [-14.652916, 10.70736],
                [-14.652360000999977, 10.707084000000123],
                [-14.652360000999977, 10.706527999],
                [-14.650693998999941, 10.70486000000011],
                [-14.650693998999941, 10.704306001000191],
                [-14.649862, 10.703749999000024],
                [-14.64875, 10.701528001000042],
                [-14.64875, 10.70097200000015],
                [-14.648193998999943, 10.700416001000065],
                [-14.647362, 10.698750001000064],
                [-14.647362, 10.698193],
                [-14.64680599899998, 10.697083],
                [-14.64625, 10.696805000000154],
                [-14.645972, 10.69569300000012],
                [-14.645414998999968, 10.695417],
                [-14.644861999999875, 10.694305],
                [-14.644861999999875, 10.693194999000127],
                [-14.644584000999942, 10.693194999000127],
                [-14.644584000999942, 10.691527],
                [-14.644861999999875, 10.691527],
                [-14.644861999999875, 10.690417],
                [-14.645414998999968, 10.68902700000018],
                [-14.645972, 10.688473001000034],
                [-14.645972, 10.687916999000038],
                [-14.64708400099994, 10.68680499900006],
                [-14.64708400099994, 10.68625100000014],
                [-14.64764, 10.685695001000056],
                [-14.648193998999943, 10.685695001000056],
                [-14.649028, 10.68458500000014],
                [-14.649584000999937, 10.68458500000014],
                [-14.649862, 10.684028999000077],
                [-14.650972000999957, 10.683751],
                [-14.651528, 10.68319400100006],
                [-14.654862000999913, 10.682082],
                [-14.659860998999932, 10.682082],
                [-14.659860998999932, 10.68236],
                [-14.660695, 10.682638000000168],
                [-14.661804999999845, 10.682638000000168],
                [-14.661804999999845, 10.682915999000159],
                [-14.662917000999926, 10.682915999000159],
                [-14.663195, 10.683473000000163],
                [-14.66374899899995, 10.683751],
                [-14.664583, 10.683751],
                [-14.665138998999907, 10.684028999000077],
                [-14.665138998999907, 10.68458500000014],
                [-14.665971, 10.68458500000014],
                [-14.666251, 10.685416999000154],
                [-14.667083, 10.685695001000056],
                [-14.667083, 10.68625100000014],
                [-14.668195000999901, 10.686529],
                [-14.668749, 10.68736100000018],
                [-14.669305000999827, 10.68736100000018],
                [-14.669305000999827, 10.687916999000038],
                [-14.670138999999892, 10.688194999000132],
                [-14.670138999999892, 10.688751],
                [-14.671249, 10.68902700000018],
                [-14.671527, 10.689583001000074],
                [-14.672639999999888, 10.689861001000168],
                [-14.672915, 10.690417],
                [-14.673749999999814, 10.690417],
                [-14.674305998999898, 10.690973001000145],
                [-14.67513799999989, 10.691249000000141],
                [-14.678193998999973, 10.691249000000141],
                [-14.678750000999969, 10.690694999000073],
                [-14.679583998999874, 10.690694999000073],
                [-14.680138000999875, 10.690417],
                [-14.680138000999875, 10.689861001000168],
                [-14.680694, 10.689304999000171],
                [-14.681250000999967, 10.689304999000171],
                [-14.681528, 10.688751],
                [-14.682082, 10.688751],
                [-14.682363000999942, 10.687639],
                [-14.682916, 10.68736100000018],
                [-14.682916, 10.686529],
                [-14.683750000999964, 10.685416999000154],
                [-14.684029, 10.684860000000185],
                [-14.684585, 10.684860000000185],
                [-14.684585, 10.684303999000065],
                [-14.68513800099987, 10.684303999000065],
                [-14.68513800099987, 10.683473000000163],
                [-14.685417, 10.68319400100006],
                [-14.68652900099994, 10.68319400100006],
                [-14.686807, 10.68236300000018],
                [-14.68791700099996, 10.68236],
                [-14.688195, 10.68125],
                [-14.688750998999922, 10.68125],
                [-14.688750998999922, 10.680416001000083],
                [-14.689026998999964, 10.679584],
                [-14.689583, 10.679584],
                [-14.689861, 10.679028001000177],
                [-14.691526998999962, 10.679028001000177],
                [-14.692083, 10.678472000000113],
                [-14.692083, 10.67736199899997],
                [-14.691526998999962, 10.676528001000065],
                [-14.693471, 10.676528001000065],
                [-14.694305000999918, 10.67763799900007],
                [-14.695974, 10.6793060010001],
                [-14.699028, 10.6793060010001],
                [-14.699028, 10.679028001000177],
                [-14.701528, 10.679028001000177],
                [-14.701528, 10.678749999000104],
                [-14.702917999999897, 10.678749999000104],
                [-14.702917999999897, 10.678472000000113],
                [-14.704583998999908, 10.678472000000113],
                [-14.704860000999929, 10.677916001000028],
                [-14.705416, 10.67763799900007],
                [-14.705416, 10.677084],
                [-14.705971998999928, 10.676806000000113],
                [-14.706527999999821, 10.675694],
                [-14.706527999999821, 10.673196000000189],
                [-14.706806, 10.673196000000189],
                [-14.706806, 10.67097099900019],
                [-14.706250000999887, 10.670418],
                [-14.706250000999887, 10.668749001000037],
                [-14.706527999999821, 10.668749001000037],
                [-14.706527999999821, 10.667361001000131],
                [-14.705971998999928, 10.666805],
                [-14.705971998999928, 10.662083001000042],
                [-14.705694, 10.662083001000042],
                [-14.705694, 10.660695001000136],
                [-14.70514, 10.659583001000158],
                [-14.704583998999908, 10.6590289990001],
                [-14.704583998999908, 10.657360000000153],
                [-14.704306, 10.657360000000153],
                [-14.704306, 10.655694000000153],
                [-14.703750000999833, 10.655137999000033],
                [-14.703750000999833, 10.654306001000123],
                [-14.703193998999836, 10.653749999000127],
                [-14.703193998999836, 10.652916001000051],
                [-14.702917999999897, 10.652084],
                [-14.702362000999926, 10.651528001000145],
                [-14.702362000999926, 10.64902800100009],
                [-14.70208399899991, 10.64902800100009],
                [-14.70208399899991, 10.646806],
                [-14.702362000999926, 10.646806],
                [-14.702362000999926, 10.643474001000072],
                [-14.702917999999897, 10.641805],
                [-14.70208399899991, 10.640139],
                [-14.701528, 10.6398610010001],
                [-14.701528, 10.639304999000103],
                [-14.700972000999911, 10.638194999000063],
                [-14.700693998999839, 10.637361000000112],
                [-14.700972000999911, 10.636529000000166],
                [-14.700416, 10.635973],
                [-14.700416, 10.635139],
                [-14.70014, 10.634307001000082],
                [-14.699584000999835, 10.633751000000188],
                [-14.699584000999835, 10.632916999000031],
                [-14.699305998999932, 10.632361000000117],
                [-14.69874899999985, 10.632083],
                [-14.69764, 10.632083],
                [-14.697083000999953, 10.632361000000117],
                [-14.697083000999953, 10.632916999000031],
                [-14.696527, 10.633195001000104],
                [-14.696527, 10.634026999000184],
                [-14.696248999999852, 10.63458300100018],
                [-14.695693000999938, 10.63458300100018],
                [-14.695416998999917, 10.635139],
                [-14.694861, 10.635139],
                [-14.694583000999955, 10.635695000999988],
                [-14.69263899899994, 10.636529000000166],
                [-14.691805000999864, 10.637361000000112],
                [-14.690695, 10.637639],
                [-14.690138998999942, 10.638473001000023],
                [-14.688473, 10.638473001000023],
                [-14.688195, 10.639027000000112],
                [-14.687638998999944, 10.639027000000112],
                [-14.687638998999944, 10.639583001000176],
                [-14.686807, 10.640139],
                [-14.684029, 10.640139],
                [-14.683750000999964, 10.640694999000175],
                [-14.683194, 10.640694999000175],
                [-14.682638000999873, 10.641249],
                [-14.682638000999873, 10.641805],
                [-14.681250000999967, 10.64264],
                [-14.680694, 10.64264],
                [-14.680416, 10.643193000000167],
                [-14.679028, 10.64374900100006],
                [-14.679028, 10.644306],
                [-14.678471998999953, 10.644306],
                [-14.677916, 10.64514],
                [-14.677083998999876, 10.64514],
                [-14.676528, 10.645415000000185],
                [-14.676528, 10.646528],
                [-14.675972000999877, 10.647084],
                [-14.675972000999877, 10.64791600000018],
                [-14.675418, 10.64791600000018],
                [-14.674305998999898, 10.648749999000131],
                [-14.674028, 10.649306],
                [-14.67347200099988, 10.649306],
                [-14.672639999999888, 10.650138001000073],
                [-14.672639999999888, 10.651249999000072],
                [-14.672084000999973, 10.651249999000072],
                [-14.672084000999973, 10.651806000000136],
                [-14.671527, 10.652084],
                [-14.67041799899988, 10.652916001000051],
                [-14.67041799899988, 10.653749999000127],
                [-14.670138999999892, 10.654306001000123],
                [-14.669583000999978, 10.654306001000123],
                [-14.669583000999978, 10.654863000000148],
                [-14.668470999999897, 10.654863000000148],
                [-14.668749, 10.655416001000162],
                [-14.668749, 10.658195001000024],
                [-14.668470999999897, 10.6590289990001],
                [-14.66680500099983, 10.659861000000149],
                [-14.665971, 10.659861000000149],
                [-14.665138998999907, 10.660139],
                [-14.664583, 10.661527000000149],
                [-14.664029000999903, 10.66180499900014],
                [-14.66374899899995, 10.662361],
                [-14.662361, 10.662916999000117],
                [-14.662917000999926, 10.664304999000024],
                [-14.662917000999926, 10.665417000000105],
                [-14.662083, 10.665694999000095],
                [-14.661804999999845, 10.666251],
                [-14.660695, 10.666251],
                [-14.660139000999948, 10.666527000000144],
                [-14.659860998999932, 10.667639000000122],
                [-14.659304, 10.667639000000122],
                [-14.659304, 10.668194999000036],
                [-14.658472998999912, 10.668194999000036],
                [-14.656806999999844, 10.669027],
                [-14.656529, 10.669582999000113],
                [-14.655138000999955, 10.670418],
                [-14.655138000999955, 10.67097099900019],
                [-14.654581998999959, 10.67097099900019],
                [-14.653471998999862, 10.671528],
                [-14.652916, 10.672640001000104],
                [-14.652916, 10.673471999000185],
                [-14.651806, 10.673471999000185],
                [-14.651806, 10.673749999000108],
                [-14.650693998999941, 10.673749999000108],
                [-14.650416, 10.674306000000172],
                [-14.649305998999864, 10.674584],
                [-14.649305998999864, 10.675418],
                [-14.64847200099996, 10.675972000000172],
                [-14.647362, 10.675972000000172],
                [-14.64680599899998, 10.67624999899999],
                [-14.646527999999876, 10.676806000000113],
                [-14.645414998999968, 10.677084],
                [-14.64514, 10.677916001000028],
                [-14.642639, 10.677916001000028],
                [-14.642639, 10.67763799900007],
                [-14.640971, 10.67763799900007],
                [-14.64041700099989, 10.677916001000028],
                [-14.640138998999873, 10.678472000000113],
                [-14.639583, 10.678749999000104],
                [-14.637917000999892, 10.678749999000104],
                [-14.637360999999885, 10.679028001000177],
                [-14.636248998999974, 10.68013799900018],
                [-14.636248998999974, 10.680416001000083],
                [-14.63513900099997, 10.680416001000083],
                [-14.634307, 10.680694],
                [-14.634307, 10.680972000000168],
                [-14.631804, 10.680972000000168],
                [-14.631804, 10.68125],
                [-14.63097299899988, 10.682082],
                [-14.630416, 10.68236],
                [-14.630416, 10.684303999000065],
                [-14.63097299899988, 10.684303999000065],
                [-14.631251000999896, 10.685139000000163],
                [-14.631251000999896, 10.688473001000034],
                [-14.631528999999887, 10.688473001000034],
                [-14.631528999999887, 10.69208299900015],
                [-14.631251000999896, 10.69208299900015],
                [-14.631251000999896, 10.694305],
                [-14.63097299899988, 10.694305],
                [-14.63097299899988, 10.69624900100007],
                [-14.630693999999892, 10.69624900100007],
                [-14.630693999999892, 10.697918],
                [-14.630416, 10.697918],
                [-14.630416, 10.699028001000102],
                [-14.629859998999905, 10.699584],
                [-14.629027999999892, 10.70124999900014],
                [-14.6287500009999, 10.702361999000118],
                [-14.628193999999894, 10.702637999000046],
                [-14.627638, 10.703749999000024],
                [-14.627638, 10.704584],
                [-14.627083998999979, 10.705139999000096],
                [-14.626527999999894, 10.70625],
                [-14.626527999999894, 10.707084000000123],
                [-14.62625, 10.707915999000136],
                [-14.62375, 10.71041599900019],
                [-14.62375, 10.710973],
                [-14.62319399899991, 10.711251000000118],
                [-14.62319399899991, 10.711803999000097],
                [-14.622361999999839, 10.712639],
                [-14.622084000999905, 10.713195001000088],
                [-14.62125, 10.714027000000101],
                [-14.620415998999931, 10.715417000000173],
                [-14.619861999999841, 10.715417000000173],
                [-14.619861999999841, 10.715973001000066],
                [-14.619306000999927, 10.716251],
                [-14.619306000999927, 10.716805],
                [-14.61875, 10.716805],
                [-14.61875, 10.717361000999972],
                [-14.618196000999944, 10.71763900000019],
                [-14.618196000999944, 10.718194999000104],
                [-14.617639998999948, 10.718194999000104],
                [-14.617639998999948, 10.718749001000106],
                [-14.617084, 10.718749001000106],
                [-14.61652699899986, 10.719582999000181],
                [-14.615693000999954, 10.720417000000168],
                [-14.615693000999954, 10.720970999000087],
                [-14.614861, 10.721527],
                [-14.614583, 10.722083000000168],
                [-14.613750998999933, 10.722918000000163],
                [-14.61374899999987, 10.723473999000078],
                [-14.612638998999955, 10.724027000999968],
                [-14.611805, 10.724584000000164],
                [-14.611805, 10.725140001000057],
                [-14.610973, 10.725696000000141],
                [-14.610695, 10.726806000000124],
                [-14.610139000999936, 10.727084],
                [-14.610139000999936, 10.727640001000168],
                [-14.609582999999873, 10.72791600100004],
                [-14.609582999999873, 10.728472000000124],
                [-14.609027000999959, 10.728749999000172],
                [-14.608751000999973, 10.729584000000159],
                [-14.608751000999973, 10.730972],
                [-14.608472998999957, 10.730972],
                [-14.608472998999957, 10.734027999000034],
                [-14.609027000999959, 10.734582001000035],
                [-14.61041699999987, 10.734582001000035],
                [-14.610973, 10.73513800000012],
                [-14.61208299999987, 10.73513800000012],
                [-14.61208299999987, 10.73541599900011],
                [-14.613195, 10.73541599900011],
                [-14.613750998999933, 10.735972],
                [-14.614583, 10.735972],
                [-14.615138998999953, 10.736250000000155],
                [-14.615417000999855, 10.736806999000123],
                [-14.615974, 10.736806999000123],
                [-14.616805000999932, 10.737638],
                [-14.617639998999948, 10.739029],
                [-14.61875, 10.740139],
                [-14.619306000999927, 10.740139],
                [-14.62014, 10.741251],
                [-14.620694000999947, 10.741251],
                [-14.620972, 10.741806999000119],
                [-14.621527999999842, 10.742082999000047],
                [-14.622361999999839, 10.742082999000047],
                [-14.623472000999925, 10.742917],
                [-14.62402799999984, 10.74430500000011],
                [-14.62402799999984, 10.745972999],
                [-14.62375, 10.745972999],
                [-14.62375, 10.74736099900008],
                [-14.62319399899991, 10.747639001000039],
                [-14.622916, 10.74847099900012],
                [-14.620415998999931, 10.74847099900012],
                [-14.619861999999841, 10.749305000000106],
                [-14.617917998999928, 10.749305000000106],
                [-14.617362, 10.749583],
                [-14.616249, 10.750418],
                [-14.616249, 10.750971000000106],
                [-14.615693000999954, 10.751248999000097],
                [-14.614861, 10.752084],
                [-14.614583, 10.752915001000076],
                [-14.614583, 10.754306],
                [-14.615138998999953, 10.755694],
                [-14.615693000999954, 10.755972],
                [-14.615693000999954, 10.75652799900007],
                [-14.61652699899986, 10.757362],
                [-14.617084, 10.757362],
                [-14.617639998999948, 10.757915998999977],
                [-14.619861999999841, 10.759027999000182],
              ],
            ],
            [
              [
                [-14.608195, 10.809584000000143],
                [-14.60875099999987, 10.809862],
                [-14.609027, 10.810416],
                [-14.609582999999873, 10.810694000000126],
                [-14.61041699999987, 10.810694000000126],
                [-14.610973, 10.810972],
                [-14.611248999999873, 10.810416],
                [-14.611805, 10.810416],
                [-14.612361, 10.809862],
                [-14.612361, 10.809306],
                [-14.612917, 10.809306],
                [-14.612917, 10.80875],
                [-14.613751, 10.808472000000165],
                [-14.613751, 10.807916000000148],
                [-14.614583, 10.806806000000165],
                [-14.615693, 10.806528],
                [-14.615974, 10.80597100000017],
                [-14.616527, 10.80597100000017],
                [-14.616527, 10.805418],
                [-14.617084, 10.805418],
                [-14.617362, 10.804862],
                [-14.617918, 10.804862],
                [-14.618471, 10.80430500000017],
                [-14.619028, 10.804027],
                [-14.619306, 10.803474000000165],
                [-14.620416, 10.802917],
                [-14.621527999999842, 10.802917],
                [-14.621527999999842, 10.80263900000017],
                [-14.630973, 10.80263900000017],
                [-14.63097, 10.802917],
                [-14.632639, 10.802917],
                [-14.632917, 10.803474000000165],
                [-14.634307, 10.803474000000165],
                [-14.635139, 10.803749000000153],
                [-14.635139, 10.804027],
                [-14.636528999999882, 10.804027],
                [-14.636805, 10.804583],
                [-14.637917, 10.804583],
                [-14.638749, 10.804862],
                [-14.639305, 10.805418],
                [-14.640417, 10.805418],
                [-14.640417, 10.805696000000125],
                [-14.641526999999883, 10.805696000000125],
                [-14.64236099999988, 10.80597100000017],
                [-14.64236099999988, 10.806250000000148],
                [-14.643192999999883, 10.806528],
                [-14.644584, 10.806528],
                [-14.644584, 10.806806000000165],
                [-14.64764, 10.806806000000165],
                [-14.64764, 10.807084],
                [-14.64875, 10.807084],
                [-14.649862, 10.806528],
                [-14.650138, 10.80597100000017],
                [-14.650972, 10.805696000000125],
                [-14.650972, 10.805140000000165],
                [-14.651528, 10.804862],
                [-14.651528, 10.80430500000017],
                [-14.652084, 10.80430500000017],
                [-14.65236, 10.803474000000165],
                [-14.65236, 10.802361],
                [-14.653194, 10.800695],
                [-14.65375, 10.799305000000174],
                [-14.654306, 10.799027],
                [-14.654306, 10.798195],
                [-14.654862, 10.797639000000174],
                [-14.655138, 10.797083000000157],
                [-14.655138, 10.795973000000174],
                [-14.655416, 10.795973000000174],
                [-14.655416, 10.794583000000102],
                [-14.655694, 10.793751000000157],
                [-14.65625, 10.792639000000179],
                [-14.656529, 10.792639000000179],
                [-14.656806999999844, 10.791804000000184],
                [-14.656806999999844, 10.790416000000107],
                [-14.657085, 10.789582],
                [-14.657916, 10.787916],
                [-14.658195, 10.787084000000107],
                [-14.659861, 10.785416000000112],
                [-14.660417, 10.784306000000129],
                [-14.660417, 10.782918],
                [-14.660972999999899, 10.78125],
                [-14.661251, 10.780694],
                [-14.661804999999845, 10.780416000000116],
                [-14.662083, 10.779306000000133],
                [-14.6626389999999, 10.779028],
                [-14.6626389999999, 10.778471000000138],
                [-14.663195, 10.777918],
                [-14.663472999999897, 10.777361],
                [-14.6643049999999, 10.776805000000138],
                [-14.6643049999999, 10.77624900000012],
                [-14.664861, 10.775695],
                [-14.665138999999897, 10.775139000000138],
                [-14.665695, 10.774861],
                [-14.665695, 10.774305],
                [-14.666251, 10.773195],
                [-14.667083, 10.77291700000012],
                [-14.667361, 10.77236100000016],
                [-14.668195, 10.772083],
                [-14.668195, 10.771529],
                [-14.668749, 10.770973],
                [-14.669304999999895, 10.770973],
                [-14.669583, 10.770417],
                [-14.670138999999892, 10.770417],
                [-14.670693, 10.769861],
                [-14.67180599999989, 10.769027000000165],
                [-14.67180599999989, 10.767083],
                [-14.671527, 10.766529],
                [-14.672915, 10.766529],
                [-14.673196, 10.767083],
                [-14.674028, 10.767361000000164],
                [-14.674862, 10.767361000000164],
                [-14.675694, 10.767639],
                [-14.67625, 10.767083],
                [-14.677084, 10.766805000000147],
                [-14.680971999999883, 10.766805000000147],
                [-14.681805999999881, 10.766529],
                [-14.681805999999881, 10.766251000000125],
                [-14.683471999999881, 10.766251000000125],
                [-14.683471999999881, 10.765973],
                [-14.684585, 10.765973],
                [-14.685137999999881, 10.765417],
                [-14.685973, 10.765417],
                [-14.686529, 10.76486000000017],
                [-14.687361, 10.76486000000017],
                [-14.687917, 10.764304000000152],
                [-14.688751, 10.764304000000152],
                [-14.689027, 10.76375000000013],
                [-14.690139, 10.762916],
                [-14.691805, 10.762363000000164],
                [-14.692916999999852, 10.762363000000164],
                [-14.69375099999985, 10.762082],
                [-14.694305, 10.76125],
                [-14.69541699999985, 10.76125],
                [-14.69541699999985, 10.760972000000152],
                [-14.696527, 10.760972000000152],
                [-14.69708299999985, 10.760694],
                [-14.697361, 10.760138],
                [-14.697918, 10.760138],
                [-14.697918, 10.759584],
                [-14.698471, 10.759584],
                [-14.69874899999985, 10.759028],
                [-14.699306, 10.759028],
                [-14.699306, 10.758472],
                [-14.70014, 10.758194000000174],
                [-14.70014, 10.757638],
                [-14.700694, 10.757638],
                [-14.700972, 10.75708400000019],
                [-14.700972, 10.755972],
                [-14.701528, 10.755972],
                [-14.701528, 10.754862000000173],
                [-14.701806, 10.754862000000173],
                [-14.701806, 10.751806000000101],
                [-14.702084, 10.751806000000101],
                [-14.702084, 10.749861000000124],
                [-14.702362, 10.749861000000124],
                [-14.702362, 10.748749],
                [-14.702917999999897, 10.747361000000183],
                [-14.703472, 10.747361000000183],
                [-14.704028, 10.745973000000106],
                [-14.704028, 10.74430500000011],
                [-14.704306, 10.74430500000011],
                [-14.704306, 10.743195000000128],
                [-14.704583999999898, 10.743195000000128],
                [-14.704583999999898, 10.742083],
                [-14.704306, 10.742083],
                [-14.704306, 10.74097300000011],
                [-14.70375, 10.740695],
                [-14.703472, 10.739861000000133],
                [-14.703472, 10.737916000000155],
                [-14.702917999999897, 10.736807],
                [-14.702084, 10.736250000000155],
                [-14.701806, 10.735416],
                [-14.701806, 10.73458200000016],
                [-14.70125, 10.734306],
                [-14.70125, 10.73375],
                [-14.700416, 10.73347200000012],
                [-14.70014, 10.73291600000016],
                [-14.699584, 10.73264],
                [-14.696527, 10.73264],
                [-14.69541699999985, 10.733194],
                [-14.695137, 10.73375],
                [-14.694582999999852, 10.73375],
                [-14.694305, 10.734306],
                [-14.693471, 10.734306],
                [-14.692916999999852, 10.73458200000016],
                [-14.692639, 10.73513800000012],
                [-14.692083, 10.735416],
                [-14.690417, 10.735416],
                [-14.690417, 10.73513800000012],
                [-14.685695, 10.73513800000012],
                [-14.685417, 10.73458200000016],
                [-14.684585, 10.73458200000016],
                [-14.684303999999884, 10.73375],
                [-14.68375, 10.733196],
                [-14.68375, 10.732360000000142],
                [-14.683471999999881, 10.731528],
                [-14.68236, 10.729306],
                [-14.68125, 10.729306],
                [-14.680971999999883, 10.72875],
                [-14.679028, 10.72875],
                [-14.678194, 10.729306],
                [-14.677916, 10.730138000000125],
                [-14.677916, 10.73125000000016],
                [-14.677639999999883, 10.73125000000016],
                [-14.677639999999883, 10.734306],
                [-14.677362, 10.734306],
                [-14.676528, 10.73513800000012],
                [-14.675971999999888, 10.735972],
                [-14.674862, 10.736529000000132],
                [-14.674305999999888, 10.736529000000132],
                [-14.673749999999814, 10.737360000000137],
                [-14.672915, 10.737082],
                [-14.67180599999989, 10.737082],
                [-14.670970999999895, 10.736807],
                [-14.670417999999813, 10.736250000000155],
                [-14.669304999999895, 10.736250000000155],
                [-14.668470999999897, 10.735972],
                [-14.668470999999897, 10.738195000000132],
                [-14.668195, 10.738195000000132],
                [-14.668195, 10.739304],
                [-14.667917, 10.739583],
                [-14.667083, 10.739583],
                [-14.666804999999897, 10.740139],
                [-14.665695, 10.74097300000011],
                [-14.662083, 10.74097300000011],
                [-14.662083, 10.740695],
                [-14.660972999999899, 10.740695],
                [-14.660972999999899, 10.74097300000011],
                [-14.659304, 10.74097300000011],
                [-14.659304, 10.741251],
                [-14.658472999999844, 10.741527000000133],
                [-14.657638, 10.741527000000133],
                [-14.657638, 10.742917],
                [-14.65736, 10.743749],
                [-14.656806999999844, 10.744029000000182],
                [-14.656806999999844, 10.745695000000183],
                [-14.656529, 10.746527000000128],
                [-14.655972, 10.746805],
                [-14.655694, 10.747639000000106],
                [-14.654582, 10.748749],
                [-14.654306, 10.749583],
                [-14.65375, 10.749305000000106],
                [-14.65375, 10.748471],
                [-14.654306, 10.748195000000123],
                [-14.654582, 10.747639000000106],
                [-14.655138, 10.747639000000106],
                [-14.655138, 10.746805],
                [-14.655694, 10.745695000000183],
                [-14.655972, 10.745695000000183],
                [-14.655972, 10.744029000000182],
                [-14.656529, 10.743473],
                [-14.656529, 10.742083],
                [-14.658195, 10.740417],
                [-14.659029, 10.740139],
                [-14.660972999999899, 10.740139],
                [-14.660972999999899, 10.739861000000133],
                [-14.662083, 10.739861000000133],
                [-14.662083, 10.740139],
                [-14.665417, 10.740139],
                [-14.665971, 10.739861000000133],
                [-14.666251, 10.739304],
                [-14.666804999999897, 10.739304],
                [-14.667361, 10.738751],
                [-14.667638999999895, 10.738195000000132],
                [-14.667638999999895, 10.735972],
                [-14.668195, 10.73513800000012],
                [-14.669304999999895, 10.73513800000012],
                [-14.669304999999895, 10.735416],
                [-14.670417999999813, 10.735416],
                [-14.670970999999895, 10.735972],
                [-14.67180599999989, 10.736250000000155],
                [-14.672915, 10.736250000000155],
                [-14.67347199999989, 10.736529000000132],
                [-14.673749999999814, 10.735972],
                [-14.674305999999888, 10.735972],
                [-14.675418, 10.735416],
                [-14.675971999999888, 10.734306],
                [-14.675971999999888, 10.73347200000012],
                [-14.676528, 10.732360000000142],
                [-14.676805999999885, 10.732360000000142],
                [-14.676805999999885, 10.730972],
                [-14.677084, 10.730138000000125],
                [-14.677362, 10.730138000000125],
                [-14.677362, 10.729028000000142],
                [-14.677916, 10.72875],
                [-14.678194, 10.728194],
                [-14.679028, 10.727916000000164],
                [-14.681528, 10.727916000000164],
                [-14.68236, 10.728194],
                [-14.682916, 10.72875],
                [-14.682916, 10.729306],
                [-14.683471999999881, 10.729584000000159],
                [-14.683471999999881, 10.730416],
                [-14.68375, 10.730972],
                [-14.684303999999884, 10.73125000000016],
                [-14.684303999999884, 10.732360000000142],
                [-14.68486, 10.73375],
                [-14.685973, 10.734306],
                [-14.691527, 10.734306],
                [-14.692083, 10.73458200000016],
                [-14.692639, 10.734306],
                [-14.693471, 10.73347200000012],
                [-14.694582999999852, 10.73291600000016],
                [-14.694861, 10.732360000000142],
                [-14.69541699999985, 10.732084],
                [-14.696248999999852, 10.732084],
                [-14.696805, 10.731528],
                [-14.699028, 10.731528],
                [-14.699584, 10.732084],
                [-14.700694, 10.732360000000142],
                [-14.700972, 10.73291600000016],
                [-14.702084, 10.73291600000016],
                [-14.702084, 10.733196],
                [-14.703194, 10.734306],
                [-14.704028, 10.73458200000016],
                [-14.70514, 10.73458200000016],
                [-14.705694, 10.73347200000012],
                [-14.705694, 10.73180600000012],
                [-14.706249999999898, 10.731528],
                [-14.706527999999821, 10.730694000000142],
                [-14.706527999999821, 10.729306],
                [-14.705972, 10.729028000000142],
                [-14.705972, 10.728472000000124],
                [-14.706527999999821, 10.728472000000124],
                [-14.707915999999898, 10.72764],
                [-14.707915999999898, 10.727084],
                [-14.708749999999895, 10.726528],
                [-14.708749999999895, 10.725972],
                [-14.709306, 10.725418],
                [-14.709581999999898, 10.724862],
                [-14.709306, 10.724027],
                [-14.709306, 10.722639000000186],
                [-14.709027999999819, 10.722639000000186],
                [-14.709027999999819, 10.721527],
                [-14.709859999999821, 10.719861],
                [-14.709581999999898, 10.719861],
                [-14.709581999999898, 10.718749000000173],
                [-14.710138, 10.718473],
                [-14.709859999999821, 10.71763900000019],
                [-14.709859999999821, 10.716805],
                [-14.710415999999896, 10.716805],
                [-14.710415999999896, 10.715139],
                [-14.710972, 10.714583000000118],
                [-14.71125099999989, 10.713751000000173],
                [-14.71125099999989, 10.712639],
                [-14.71152899999987, 10.712639],
                [-14.71152899999987, 10.711251000000118],
                [-14.712081999999896, 10.710973],
                [-14.712084999999888, 10.710416000000123],
                [-14.712639, 10.710416000000123],
                [-14.712639, 10.709860000000106],
                [-14.713473, 10.709307],
                [-14.713473, 10.70736],
                [-14.714307, 10.70736],
                [-14.715695, 10.706806],
                [-14.715695, 10.705694],
                [-14.715973, 10.705694],
                [-14.715973, 10.704028],
                [-14.716529, 10.70263800000015],
                [-14.717082999999889, 10.702362],
                [-14.717639, 10.700694],
                [-14.717639, 10.698750000000132],
                [-14.717360999999869, 10.698193],
                [-14.715973, 10.698193],
                [-14.715973, 10.697918],
                [-14.714860999999871, 10.697918],
                [-14.714860999999871, 10.698193],
                [-14.713473, 10.698193],
                [-14.71291699999989, 10.697918],
                [-14.712639, 10.697362],
                [-14.71152899999987, 10.697362],
                [-14.71125099999989, 10.697918],
                [-14.710693999999819, 10.697640000000149],
                [-14.71152899999987, 10.696805000000154],
                [-14.71152899999987, 10.696249000000137],
                [-14.710972, 10.69569300000012],
                [-14.710415999999896, 10.69569300000012],
                [-14.709581999999898, 10.694583000000137],
                [-14.708749999999895, 10.694583000000137],
                [-14.708749999999895, 10.693471000000159],
                [-14.708193999999821, 10.693471000000159],
                [-14.707638, 10.692361000000119],
                [-14.707638, 10.691805000000159],
                [-14.706806, 10.691527],
                [-14.706806, 10.690417],
                [-14.705694, 10.690139000000158],
                [-14.705694, 10.689583000000141],
                [-14.70486, 10.68902700000018],
                [-14.704306, 10.688473000000158],
                [-14.70375, 10.688473000000158],
                [-14.703472, 10.68736100000018],
                [-14.702917999999897, 10.687083],
                [-14.702917999999897, 10.686529],
                [-14.702362, 10.686529],
                [-14.702362, 10.685973],
                [-14.701806, 10.68569500000018],
                [-14.699028, 10.68569500000018],
                [-14.69874899999985, 10.68625100000014],
                [-14.69708299999985, 10.686805000000163],
                [-14.695974, 10.686805000000163],
                [-14.695693, 10.68736100000018],
                [-14.694582999999852, 10.687639],
                [-14.694582999999852, 10.688473000000158],
                [-14.694027, 10.688473000000158],
                [-14.69375099999985, 10.68902700000018],
                [-14.692916999999852, 10.689305],
                [-14.692916999999852, 10.689861],
                [-14.691805, 10.690417],
                [-14.691249, 10.690417],
                [-14.690973, 10.690973],
                [-14.689861, 10.691527],
                [-14.689305, 10.691527],
                [-14.689305, 10.692083],
                [-14.688473, 10.692639],
                [-14.687917, 10.692639],
                [-14.687639, 10.693195],
                [-14.687083, 10.693195],
                [-14.687083, 10.693751],
                [-14.686529, 10.693751],
                [-14.686251, 10.694305],
                [-14.685695, 10.694583000000137],
                [-14.684585, 10.694583000000137],
                [-14.684029, 10.695139000000154],
                [-14.683194, 10.695139000000154],
                [-14.682637999999884, 10.695417],
                [-14.682637999999884, 10.695974000000149],
                [-14.682082, 10.696249000000137],
                [-14.680416, 10.696249000000137],
                [-14.680416, 10.696527],
                [-14.679028, 10.696527],
                [-14.678471999999886, 10.697083],
                [-14.677084, 10.697083],
                [-14.677084, 10.696805000000154],
                [-14.672362, 10.696805000000154],
                [-14.671527, 10.696249000000137],
                [-14.667361, 10.696249000000137],
                [-14.667361, 10.69569300000012],
                [-14.666804999999897, 10.69569300000012],
                [-14.665417, 10.694305],
                [-14.664583, 10.69402700000012],
                [-14.664583, 10.693471000000159],
                [-14.663472999999897, 10.692917000000136],
                [-14.6626389999999, 10.691805000000159],
                [-14.662083, 10.691805000000159],
                [-14.662083, 10.691249000000141],
                [-14.661527, 10.690973],
                [-14.661527, 10.690417],
                [-14.660695, 10.690417],
                [-14.660695, 10.689861],
                [-14.659861, 10.689583000000141],
                [-14.659861, 10.68902700000018],
                [-14.659029, 10.688751],
                [-14.659029, 10.688195],
                [-14.658472999999844, 10.688195],
                [-14.658195, 10.687639],
                [-14.657638, 10.687639],
                [-14.655694, 10.68569500000018],
                [-14.654582, 10.68569500000018],
                [-14.654028, 10.68625100000014],
                [-14.653194, 10.68625100000014],
                [-14.652916, 10.686805000000163],
                [-14.652916, 10.68791700000014],
                [-14.652638, 10.68791700000014],
                [-14.652638, 10.689305],
                [-14.65236, 10.689305],
                [-14.65236, 10.690417],
                [-14.652084, 10.690417],
                [-14.652084, 10.693195],
                [-14.65236, 10.693195],
                [-14.65236, 10.69569300000012],
                [-14.652638, 10.695696],
                [-14.653472, 10.697362],
                [-14.653472, 10.698750000000132],
                [-14.654306, 10.700416000000132],
                [-14.655972, 10.702084000000127],
                [-14.656529, 10.702362],
                [-14.658751, 10.704584],
                [-14.658751, 10.70514],
                [-14.659304, 10.705416000000127],
                [-14.659861, 10.706528000000105],
                [-14.660695, 10.709028],
                [-14.660695, 10.710416000000123],
                [-14.660972999999899, 10.710416000000123],
                [-14.660972999999899, 10.714861],
                [-14.660695, 10.714861],
                [-14.660695, 10.71763900000019],
                [-14.660138999999845, 10.718195],
                [-14.660138999999845, 10.719583],
                [-14.659583, 10.720139],
                [-14.659029, 10.721249],
                [-14.658472999999844, 10.721527],
                [-14.658472999999844, 10.722083000000168],
                [-14.657916, 10.722361],
                [-14.656806999999844, 10.723474000000124],
                [-14.656806999999844, 10.724027],
                [-14.654582, 10.726250000000164],
                [-14.654028, 10.726528],
                [-14.652638, 10.727916000000164],
                [-14.652084, 10.727916000000164],
                [-14.651528, 10.72875],
                [-14.650972, 10.72875],
                [-14.650694, 10.729306],
                [-14.649584, 10.729862],
                [-14.649584, 10.730416],
                [-14.649028, 10.730416],
                [-14.647918, 10.73180600000012],
                [-14.646527999999876, 10.73458200000016],
                [-14.646806, 10.735416],
                [-14.646806, 10.736807],
                [-14.647084, 10.736807],
                [-14.647084, 10.738195000000132],
                [-14.647362, 10.738195000000132],
                [-14.647362, 10.742083],
                [-14.647084, 10.742083],
                [-14.647084, 10.743473],
                [-14.646806, 10.743473],
                [-14.646806, 10.744861000000128],
                [-14.646527999999876, 10.745695000000183],
                [-14.645972, 10.746249],
                [-14.645972, 10.746805],
                [-14.645415, 10.747083],
                [-14.645415, 10.747639000000106],
                [-14.644861999999875, 10.748749],
                [-14.644305, 10.749027000000183],
                [-14.64402699999988, 10.750139],
                [-14.642918, 10.751249],
                [-14.64236099999988, 10.751527000000124],
                [-14.642083, 10.752084],
                [-14.641526999999883, 10.752084],
                [-14.641526999999883, 10.75264],
                [-14.640971, 10.75264],
                [-14.640971, 10.753196000000173],
                [-14.640139, 10.753472000000102],
                [-14.640139, 10.754028],
                [-14.639305, 10.754306],
                [-14.639026999999885, 10.754862000000173],
                [-14.637917, 10.75514],
                [-14.637360999999885, 10.75541800000019],
                [-14.637360999999885, 10.755972],
                [-14.636249, 10.75625],
                [-14.635973, 10.756806],
                [-14.635417, 10.75708400000019],
                [-14.634583, 10.75708400000019],
                [-14.633473, 10.757638],
                [-14.633194999999887, 10.758194000000174],
                [-14.632085, 10.758194000000174],
                [-14.631528999999887, 10.758750000000191],
                [-14.630973, 10.758750000000191],
                [-14.630416, 10.759306000000151],
                [-14.629859999999894, 10.760416],
                [-14.629859999999894, 10.76125],
                [-14.630416, 10.761806],
                [-14.630416, 10.762363000000164],
                [-14.630973, 10.763472],
                [-14.631528999999887, 10.76375000000013],
                [-14.632085, 10.76486000000017],
                [-14.63236099999989, 10.765695000000164],
                [-14.63236099999989, 10.766805000000147],
                [-14.632917, 10.767083],
                [-14.63402699999989, 10.768195],
                [-14.63402699999989, 10.769583000000125],
                [-14.633751, 10.769583000000125],
                [-14.633751, 10.771249000000125],
                [-14.633194999999887, 10.77236100000016],
                [-14.63236099999989, 10.773195],
                [-14.631804, 10.773195],
                [-14.631528999999887, 10.773751],
                [-14.630416, 10.774305],
                [-14.629582, 10.774305],
                [-14.629582, 10.774861],
                [-14.62875, 10.775139000000138],
                [-14.628193999999894, 10.775695],
                [-14.627084, 10.775695],
                [-14.626527999999894, 10.777083],
                [-14.626527999999894, 10.778749],
                [-14.626806, 10.778749],
                [-14.626806, 10.780416000000116],
                [-14.627084, 10.780416000000116],
                [-14.627084, 10.781806000000188],
                [-14.627361999999891, 10.782638000000134],
                [-14.626806, 10.783194],
                [-14.626806, 10.784028],
                [-14.625972, 10.785694],
                [-14.625416, 10.785972000000129],
                [-14.625416, 10.786528],
                [-14.624584, 10.787084000000107],
                [-14.624584, 10.787638000000129],
                [-14.62375, 10.787916],
                [-14.622916, 10.788750000000107],
                [-14.622638, 10.788750000000107],
                [-14.622638, 10.790694],
                [-14.622916, 10.790694],
                [-14.622361999999839, 10.79236],
                [-14.622361999999839, 10.793195],
                [-14.621806, 10.793195],
                [-14.621806, 10.790416000000107],
                [-14.621527999999842, 10.790416000000107],
                [-14.621527999999842, 10.789306],
                [-14.620972, 10.789028],
                [-14.620694, 10.788472000000183],
                [-14.620694, 10.786806000000183],
                [-14.619584, 10.78625],
                [-14.619584, 10.785694],
                [-14.61875, 10.784862],
                [-14.618195999999841, 10.784584],
                [-14.617362, 10.783194],
                [-14.616527, 10.782362],
                [-14.616249, 10.782362],
                [-14.615139, 10.78125],
                [-14.611248999999873, 10.779306000000133],
                [-14.610973, 10.778749],
                [-14.610139, 10.778749],
                [-14.609305, 10.778471000000138],
                [-14.60875099999987, 10.777640000000133],
                [-14.607361, 10.777640000000133],
                [-14.607361, 10.777361],
                [-14.606250999999872, 10.777361],
                [-14.605973, 10.776805000000138],
                [-14.605139, 10.776527],
                [-14.603750999999875, 10.776527],
                [-14.603750999999875, 10.776805000000138],
                [-14.602081999999882, 10.776805000000138],
                [-14.601528, 10.777640000000133],
                [-14.600415999999882, 10.778471000000138],
                [-14.600415999999882, 10.779028],
                [-14.59986, 10.779306000000133],
                [-14.59986, 10.779862],
                [-14.599028, 10.780140000000188],
                [-14.599028, 10.779028],
                [-14.59958399999988, 10.778471000000138],
                [-14.59958399999988, 10.777083],
                [-14.59986, 10.776805000000138],
                [-14.600972, 10.776805000000138],
                [-14.601806, 10.776527],
                [-14.60236, 10.775974000000133],
                [-14.605695, 10.775974000000133],
                [-14.605695, 10.77624900000012],
                [-14.607082999999875, 10.77624900000012],
                [-14.607082999999875, 10.776527],
                [-14.608195, 10.776527],
                [-14.608195, 10.776805000000138],
                [-14.609305, 10.777361],
                [-14.61041699999987, 10.777361],
                [-14.61041699999987, 10.777640000000133],
                [-14.613195, 10.779028],
                [-14.613751, 10.779584],
                [-14.614305, 10.779584],
                [-14.615139, 10.780416000000116],
                [-14.615139, 10.780694],
                [-14.616249, 10.78125],
                [-14.616805, 10.78125],
                [-14.61764, 10.782084000000111],
                [-14.61764, 10.782638000000134],
                [-14.618471, 10.782918],
                [-14.619306, 10.783750000000111],
                [-14.62014, 10.784862],
                [-14.620416, 10.785972000000129],
                [-14.621806, 10.785972000000129],
                [-14.622638, 10.78625],
                [-14.623193999999842, 10.785972000000129],
                [-14.62402799999984, 10.785972000000129],
                [-14.624859999999842, 10.785416000000112],
                [-14.62514, 10.784862],
                [-14.62514, 10.782638000000134],
                [-14.624859999999842, 10.782638000000134],
                [-14.624859999999842, 10.781528],
                [-14.624584, 10.781528],
                [-14.624584, 10.778749],
                [-14.624859999999842, 10.778749],
                [-14.624859999999842, 10.777640000000133],
                [-14.62514, 10.776805000000138],
                [-14.626527999999894, 10.77402700000016],
                [-14.627084, 10.77402700000016],
                [-14.627361999999891, 10.773471000000143],
                [-14.627916, 10.773471000000143],
                [-14.628193999999894, 10.77291700000012],
                [-14.629307, 10.772639],
                [-14.629582, 10.772083],
                [-14.630693999999892, 10.772083],
                [-14.630973, 10.771529],
                [-14.631528999999887, 10.771529],
                [-14.632085, 10.770417],
                [-14.631251, 10.769027000000165],
                [-14.630693999999892, 10.768473000000142],
                [-14.630138, 10.768471000000147],
                [-14.628472, 10.766805000000147],
                [-14.628193999999894, 10.765695000000164],
                [-14.627361999999891, 10.765138],
                [-14.626806, 10.764029000000164],
                [-14.626806, 10.763472],
                [-14.62625, 10.762363000000164],
                [-14.62569399999984, 10.762082],
                [-14.622638, 10.762082],
                [-14.622638, 10.761806],
                [-14.62014, 10.761806],
                [-14.619584, 10.76125],
                [-14.61875, 10.76125],
                [-14.61764, 10.760694],
                [-14.616527, 10.759584],
                [-14.615417, 10.758750000000191],
                [-14.614861, 10.758472],
                [-14.614861, 10.757916],
                [-14.614305, 10.757638],
                [-14.614305, 10.75708400000019],
                [-14.613471, 10.756806],
                [-14.613471, 10.755694],
                [-14.613195, 10.755694],
                [-14.613195, 10.753196000000173],
                [-14.613471, 10.752362],
                [-14.613751, 10.752362],
                [-14.613751, 10.750971000000106],
                [-14.614305, 10.750971000000106],
                [-14.614305, 10.750418],
                [-14.615139, 10.749583],
                [-14.615693, 10.749583],
                [-14.615974, 10.749027000000183],
                [-14.616527, 10.749027000000183],
                [-14.616805, 10.748471],
                [-14.61764, 10.748471],
                [-14.618471, 10.748195000000123],
                [-14.61875, 10.747639000000106],
                [-14.619306, 10.747361000000183],
                [-14.620416, 10.747361000000183],
                [-14.620972, 10.747083],
                [-14.620972, 10.746527000000128],
                [-14.622084, 10.745695000000183],
                [-14.622084, 10.744583],
                [-14.62125, 10.743749],
                [-14.619861999999841, 10.742917],
                [-14.619584, 10.742361],
                [-14.618471, 10.742083],
                [-14.618471, 10.741527000000133],
                [-14.617918, 10.741527000000133],
                [-14.61764, 10.74097300000011],
                [-14.617084, 10.74097300000011],
                [-14.616249, 10.740139],
                [-14.615693, 10.739861000000133],
                [-14.614027, 10.738195000000132],
                [-14.613471, 10.737360000000137],
                [-14.612639, 10.737082],
                [-14.612639, 10.736529000000132],
                [-14.611527, 10.736529000000132],
                [-14.611527, 10.736250000000155],
                [-14.61041699999987, 10.736250000000155],
                [-14.61041699999987, 10.735972],
                [-14.60875099999987, 10.735972],
                [-14.60875099999987, 10.735694000000137],
                [-14.606527, 10.735694000000137],
                [-14.604584999999872, 10.737638],
                [-14.604584999999872, 10.738195000000132],
                [-14.604029, 10.738748],
                [-14.603750999999875, 10.739304],
                [-14.60291599999988, 10.740139],
                [-14.60236, 10.741805],
                [-14.601806, 10.742083],
                [-14.601528, 10.743195000000128],
                [-14.600972, 10.743473],
                [-14.600415999999882, 10.744583],
                [-14.600415999999882, 10.745139],
                [-14.59986, 10.745417],
                [-14.59958399999988, 10.746527000000128],
                [-14.599028, 10.746805],
                [-14.598749999999882, 10.747917],
                [-14.598194, 10.748195000000123],
                [-14.597915999999884, 10.748749],
                [-14.59764, 10.749861000000124],
                [-14.597083999999882, 10.750139],
                [-14.596806, 10.751249],
                [-14.596249999999884, 10.751527000000124],
                [-14.596249999999884, 10.752084],
                [-14.594862, 10.754862000000173],
                [-14.594028, 10.75541800000019],
                [-14.594028, 10.756528000000174],
                [-14.593749999999886, 10.75708400000019],
                [-14.593194, 10.757362],
                [-14.592915999999889, 10.757916],
                [-14.59264, 10.759028],
                [-14.592083999999886, 10.759306000000151],
                [-14.59153, 10.760416],
                [-14.59153, 10.761528000000169],
                [-14.590971, 10.762082],
                [-14.590693, 10.76319400000017],
                [-14.59014, 10.763472],
                [-14.59014, 10.764029000000164],
                [-14.589582999999834, 10.764585000000125],
                [-14.589582999999834, 10.769583000000125],
                [-14.589862, 10.769583000000125],
                [-14.589862, 10.77069500000016],
                [-14.589027, 10.770973],
                [-14.588195, 10.771529],
                [-14.587916999999834, 10.772083],
                [-14.587916999999834, 10.773195],
                [-14.587361, 10.773751],
                [-14.587361, 10.774861],
                [-14.587082999999836, 10.774861],
                [-14.587082999999836, 10.775974000000133],
                [-14.586805, 10.775974000000133],
                [-14.586805, 10.777083],
                [-14.586527, 10.777083],
                [-14.586527, 10.778193],
                [-14.586250999999834, 10.778749],
                [-14.585695, 10.779028],
                [-14.585695, 10.779584],
                [-14.585139, 10.780694],
                [-14.584583, 10.780972000000133],
                [-14.584305, 10.782084000000111],
                [-14.583749, 10.782362],
                [-14.583749, 10.782918],
                [-14.582917, 10.783472000000188],
                [-14.582639, 10.784584],
                [-14.582083, 10.784862],
                [-14.581251, 10.785972000000129],
                [-14.580971, 10.787084000000107],
                [-14.580417, 10.787084000000107],
                [-14.580139, 10.788194],
                [-14.579305, 10.788472000000183],
                [-14.579305, 10.789028],
                [-14.578751, 10.78986],
                [-14.577917, 10.790138000000184],
                [-14.577917, 10.790694],
                [-14.57736, 10.790972],
                [-14.576251, 10.792085000000156],
                [-14.576251, 10.792639000000179],
                [-14.575694, 10.792639000000179],
                [-14.575694, 10.793195],
                [-14.574863, 10.793751000000157],
                [-14.574863, 10.794583000000102],
                [-14.574305999999865, 10.794583000000102],
                [-14.574028, 10.795139],
                [-14.57263799999987, 10.796527],
                [-14.57263799999987, 10.797083000000157],
                [-14.571805999999867, 10.798195],
                [-14.57125, 10.798195],
                [-14.57125, 10.798749000000157],
                [-14.570694, 10.799027],
                [-14.569862, 10.799861],
                [-14.569584, 10.800417000000152],
                [-14.569028, 10.800417000000152],
                [-14.568471999999872, 10.801251],
                [-14.567918, 10.801805],
                [-14.568194, 10.802917],
                [-14.56875, 10.803474000000165],
                [-14.570416, 10.803474000000165],
                [-14.570694, 10.802917],
                [-14.57125, 10.80263900000017],
                [-14.572084, 10.801805],
                [-14.572916, 10.801527],
                [-14.572916, 10.800971000000175],
                [-14.57375, 10.800971000000175],
                [-14.574582, 10.800695],
                [-14.574582, 10.800417000000152],
                [-14.57736, 10.800417000000152],
                [-14.57736, 10.800695],
                [-14.578473, 10.800695],
                [-14.579583, 10.801251],
                [-14.579861, 10.801805],
                [-14.580971, 10.80263900000017],
                [-14.581251, 10.803749000000153],
                [-14.582083, 10.804583],
                [-14.583195, 10.804862],
                [-14.583473, 10.805418],
                [-14.584583, 10.805418],
                [-14.584583, 10.805696000000125],
                [-14.586250999999834, 10.805696000000125],
                [-14.586250999999834, 10.80597100000017],
                [-14.587361, 10.80597100000017],
                [-14.587361, 10.806250000000148],
                [-14.59153, 10.806250000000148],
                [-14.591528, 10.806528],
                [-14.597083999999882, 10.806528],
                [-14.597083999999882, 10.806250000000148],
                [-14.600138, 10.806250000000148],
                [-14.600138, 10.80597100000017],
                [-14.602638, 10.80597100000017],
                [-14.604304, 10.806528],
                [-14.604584999999872, 10.807084],
                [-14.605139, 10.807084],
                [-14.605973, 10.807916000000148],
                [-14.606250999999872, 10.808472000000165],
                [-14.607361, 10.809306],
                [-14.608195, 10.809584000000143],
              ],
            ],
            [
              [
                [-14.57236, 10.765417],
                [-14.57236, 10.769027000000165],
                [-14.572084, 10.769027000000165],
                [-14.572084, 10.77069500000016],
                [-14.573194, 10.77069500000016],
                [-14.573194, 10.770139000000142],
                [-14.57375, 10.769861],
                [-14.57375, 10.769305],
                [-14.574305999999865, 10.769027000000165],
                [-14.574305999999865, 10.767361000000164],
                [-14.574863, 10.767083],
                [-14.574863, 10.765417],
                [-14.575137999999868, 10.765417],
                [-14.575137999999868, 10.759860000000174],
                [-14.57375, 10.759860000000174],
                [-14.573471999999867, 10.760138],
                [-14.573471999999867, 10.761806],
                [-14.573194, 10.761806],
                [-14.573194, 10.763472],
                [-14.57263799999987, 10.764029000000164],
                [-14.57263799999987, 10.765417],
                [-14.57236, 10.765417],
              ],
            ],
            [
              [
                [-14.948472, 10.841528000000153],
                [-14.948472, 10.842362],
                [-14.95125, 10.842362],
                [-14.951528, 10.841806],
                [-14.952085, 10.842916],
                [-14.953751, 10.842916],
                [-14.954861, 10.842082000000175],
                [-14.954861, 10.840694],
                [-14.954582, 10.840694],
                [-14.954582, 10.839028],
                [-14.953751, 10.838474],
                [-14.953473, 10.837916],
                [-14.953473, 10.836806],
                [-14.952916, 10.835972],
                [-14.952085, 10.83625],
                [-14.951528, 10.836806],
                [-14.950972, 10.836528000000158],
                [-14.950972, 10.835694000000103],
                [-14.950416, 10.834862000000157],
                [-14.949306, 10.834584],
                [-14.949306, 10.835694000000103],
                [-14.94875, 10.835972],
                [-14.94875, 10.836806],
                [-14.948193999999887, 10.838194000000158],
                [-14.94763799999987, 10.838472],
                [-14.94763799999987, 10.840972],
                [-14.948193999999887, 10.840972],
                [-14.948472, 10.841528000000153],
              ],
            ],
            [
              [
                [-14.605139, 10.878749],
                [-14.605973, 10.879027],
                [-14.606250999999872, 10.879585],
                [-14.607082999999875, 10.879861000000176],
                [-14.608471, 10.879861000000176],
                [-14.608471, 10.880141],
                [-14.61041699999987, 10.880141],
                [-14.61041699999987, 10.879861000000176],
                [-14.611527, 10.879861000000176],
                [-14.61208299999987, 10.879585],
                [-14.612361, 10.879029],
                [-14.612917, 10.879029],
                [-14.614027, 10.877917],
                [-14.614583, 10.877639000000158],
                [-14.614861, 10.877083],
                [-14.615417, 10.877083],
                [-14.61764, 10.87486100000018],
                [-14.61764, 10.874307000000158],
                [-14.618195999999841, 10.874029],
                [-14.619028, 10.87319500000018],
                [-14.619584, 10.872917],
                [-14.620416, 10.872085],
                [-14.620972, 10.871804000000168],
                [-14.622084, 10.870694000000185],
                [-14.622916, 10.870416],
                [-14.623193999999842, 10.869862],
                [-14.626806, 10.869862],
                [-14.626806, 10.870138000000168],
                [-14.62875, 10.870138000000168],
                [-14.62875, 10.869862],
                [-14.629859999999894, 10.869862],
                [-14.629859999999894, 10.870138000000168],
                [-14.631251, 10.870138000000168],
                [-14.631251, 10.869862],
                [-14.63236099999989, 10.86986],
                [-14.633751, 10.869028000000185],
                [-14.634583, 10.86736000000019],
                [-14.634583, 10.86625],
                [-14.634860999999887, 10.86625],
                [-14.634860999999887, 10.865140000000167],
                [-14.635139, 10.865140000000167],
                [-14.635139, 10.863472],
                [-14.635417, 10.862638000000118],
                [-14.635694999999885, 10.862638000000118],
                [-14.635694999999885, 10.86125],
                [-14.635973, 10.86125],
                [-14.635973, 10.86014],
                [-14.636249, 10.86014],
                [-14.636251, 10.858471000000122],
                [-14.636528999999882, 10.858471000000122],
                [-14.636528999999882, 10.850417],
                [-14.636251, 10.850417],
                [-14.636249, 10.847917000000109],
                [-14.635973, 10.847917000000109],
                [-14.635973, 10.846804],
                [-14.635417, 10.846529],
                [-14.635139, 10.84541600000017],
                [-14.634583, 10.845138],
                [-14.634583, 10.844585000000109],
                [-14.633473, 10.843472],
                [-14.63236099999989, 10.843472],
                [-14.631804, 10.842916],
                [-14.629307, 10.842916],
                [-14.629307, 10.842638],
                [-14.627361999999891, 10.842638],
                [-14.627361999999891, 10.842362],
                [-14.62125, 10.842362],
                [-14.62125, 10.842082000000175],
                [-14.619584, 10.842082000000175],
                [-14.619028, 10.841528000000153],
                [-14.616805, 10.841528000000153],
                [-14.616527, 10.840972],
                [-14.615974, 10.840972],
                [-14.615417, 10.840416000000175],
                [-14.615417, 10.839306],
                [-14.614861, 10.839306],
                [-14.614861, 10.838750000000175],
                [-14.614305, 10.838474],
                [-14.614305, 10.837916],
                [-14.613471, 10.836806],
                [-14.612917, 10.836528000000158],
                [-14.612917, 10.835972],
                [-14.612361, 10.835694000000103],
                [-14.611805, 10.834584],
                [-14.611805, 10.833472],
                [-14.611248999999873, 10.833196000000157],
                [-14.611248999999873, 10.83208400000018],
                [-14.610695, 10.830417],
                [-14.61041699999987, 10.830417],
                [-14.61041699999987, 10.828471],
                [-14.610139, 10.828471],
                [-14.610139, 10.824305],
                [-14.609861, 10.824305],
                [-14.609861, 10.821527000000117],
                [-14.609305, 10.820973],
                [-14.609305, 10.819582000000139],
                [-14.609027, 10.819582000000139],
                [-14.609027, 10.815138000000104],
                [-14.609305, 10.815138000000104],
                [-14.609305, 10.813472000000104],
                [-14.609027, 10.812916000000143],
                [-14.608195, 10.812916000000143],
                [-14.607916999999873, 10.812360000000126],
                [-14.607361, 10.812360000000126],
                [-14.606807, 10.811806000000104],
                [-14.606807, 10.811250000000143],
                [-14.605973, 10.810972],
                [-14.605973, 10.810416],
                [-14.605139, 10.810138000000165],
                [-14.605139, 10.809584000000143],
                [-14.604584999999872, 10.809584000000143],
                [-14.604304, 10.809028000000126],
                [-14.603194, 10.80875],
                [-14.602081999999882, 10.808194],
                [-14.602081999999882, 10.807916000000148],
                [-14.600415999999882, 10.807916000000148],
                [-14.600415999999882, 10.808194],
                [-14.597915999999884, 10.808194],
                [-14.597915999999884, 10.808472000000165],
                [-14.591248999999834, 10.808472000000165],
                [-14.591248999999834, 10.808194],
                [-14.588471, 10.808194],
                [-14.588471, 10.807916000000148],
                [-14.586527, 10.807916000000148],
                [-14.586527, 10.80764],
                [-14.584029, 10.80764],
                [-14.583195, 10.807362000000126],
                [-14.582639, 10.806806000000165],
                [-14.581805, 10.806806000000165],
                [-14.581251, 10.806528],
                [-14.581251, 10.80597100000017],
                [-14.580695, 10.80597100000017],
                [-14.580417, 10.805418],
                [-14.579861, 10.805418],
                [-14.579305, 10.804583],
                [-14.578473, 10.803749000000153],
                [-14.578195, 10.803193],
                [-14.577637999999865, 10.80263900000017],
                [-14.577085, 10.80263900000017],
                [-14.577085, 10.802083000000152],
                [-14.575971999999865, 10.802083000000152],
                [-14.575416, 10.80263900000017],
                [-14.574582, 10.80263900000017],
                [-14.574028, 10.803193],
                [-14.573471999999867, 10.803193],
                [-14.573194, 10.803749000000153],
                [-14.572084, 10.803749000000153],
                [-14.572084, 10.804027],
                [-14.57125, 10.80430500000017],
                [-14.570416, 10.80430500000017],
                [-14.569584, 10.804583],
                [-14.569584, 10.804862],
                [-14.566805999999872, 10.804862],
                [-14.56625, 10.805418],
                [-14.565415, 10.805696000000125],
                [-14.564305999999874, 10.806250000000148],
                [-14.563470999999879, 10.806250000000148],
                [-14.562918, 10.806806000000165],
                [-14.562083, 10.806806000000165],
                [-14.561804999999879, 10.807362000000126],
                [-14.561249, 10.807362000000126],
                [-14.559583, 10.809028000000126],
                [-14.559583, 10.809584000000143],
                [-14.559027, 10.809584000000143],
                [-14.559027, 10.810138000000165],
                [-14.558194999999898, 10.810416],
                [-14.558194999999898, 10.810972],
                [-14.557638999999881, 10.811250000000143],
                [-14.557361, 10.812360000000126],
                [-14.556804999999883, 10.812360000000126],
                [-14.556528999999898, 10.813472000000104],
                [-14.555695, 10.814306],
                [-14.555138999999883, 10.814306],
                [-14.555138999999883, 10.814862],
                [-14.55430699999988, 10.815138000000104],
                [-14.554304999999829, 10.815694000000121],
                [-14.553195, 10.815972],
                [-14.552361, 10.817082],
                [-14.552361, 10.817638],
                [-14.551804, 10.817916000000139],
                [-14.550972999999829, 10.818751000000134],
                [-14.550695, 10.819304],
                [-14.54986, 10.820139],
                [-14.54986, 10.820695],
                [-14.54875, 10.821251000000188],
                [-14.547362, 10.824029],
                [-14.547362, 10.826805],
                [-14.547916, 10.827361],
                [-14.548472, 10.828471],
                [-14.548472, 10.829027],
                [-14.549029, 10.829305],
                [-14.549029, 10.829861000000108],
                [-14.54986, 10.830693],
                [-14.550417, 10.830693],
                [-14.550417, 10.831249000000184],
                [-14.551251, 10.831805],
                [-14.551804, 10.832362000000103],
                [-14.555138999999883, 10.834028000000103],
                [-14.556249, 10.834306],
                [-14.556528999999898, 10.834862000000157],
                [-14.557638999999881, 10.835418000000175],
                [-14.558194999999898, 10.835418000000175],
                [-14.559304999999881, 10.83625],
                [-14.560970999999881, 10.836806],
                [-14.562083, 10.837362],
                [-14.563192999999899, 10.837638],
                [-14.563192999999899, 10.838194000000158],
                [-14.563749, 10.838474],
                [-14.564584, 10.838472],
                [-14.564584, 10.839028],
                [-14.565139999999872, 10.839028],
                [-14.565415, 10.839584],
                [-14.565971999999874, 10.839584],
                [-14.567084, 10.840416000000175],
                [-14.567918, 10.840416000000175],
                [-14.568471999999872, 10.840972],
                [-14.569028, 10.840972],
                [-14.56930599999987, 10.841528000000153],
                [-14.570416, 10.841806],
                [-14.57125, 10.842638],
                [-14.57125, 10.843194000000153],
                [-14.571805999999867, 10.843472],
                [-14.573471999999867, 10.845138],
                [-14.57375, 10.845695000000148],
                [-14.574582, 10.846529],
                [-14.574863, 10.846529],
                [-14.576803999999868, 10.848473000000126],
                [-14.576803999999868, 10.849027000000149],
                [-14.577637999999865, 10.849305],
                [-14.577637999999865, 10.849861],
                [-14.578751, 10.850695000000144],
                [-14.579305, 10.850695000000144],
                [-14.579583, 10.85124900000011],
                [-14.580417, 10.851529],
                [-14.580417, 10.852361000000144],
                [-14.580695, 10.852917000000105],
                [-14.581251, 10.853195],
                [-14.581251, 10.853751],
                [-14.581805, 10.854027000000144],
                [-14.581805, 10.854583000000105],
                [-14.582361, 10.854861],
                [-14.582361, 10.855417],
                [-14.582917, 10.85569500000014],
                [-14.582917, 10.856249000000105],
                [-14.583473, 10.856527],
                [-14.583473, 10.857083],
                [-14.584027, 10.857639],
                [-14.584029, 10.858749],
                [-14.584305, 10.859584],
                [-14.584861, 10.859862],
                [-14.584861, 10.860415],
                [-14.585416999999836, 10.860696000000189],
                [-14.585416999999836, 10.861528],
                [-14.586527, 10.862638000000118],
                [-14.586527, 10.86375],
                [-14.587082999999836, 10.86402800000019],
                [-14.587361, 10.864584],
                [-14.587361, 10.865416],
                [-14.588748999999837, 10.866806000000167],
                [-14.589305, 10.867084],
                [-14.590417999999886, 10.868194],
                [-14.591248999999834, 10.868194],
                [-14.591806, 10.86875],
                [-14.594862, 10.86875],
                [-14.595694, 10.869028000000185],
                [-14.596806, 10.869862],
                [-14.59764, 10.870694000000185],
                [-14.59764, 10.87125],
                [-14.598472, 10.872360000000185],
                [-14.599028, 10.872638000000109],
                [-14.599028, 10.873473],
                [-14.599306, 10.874307000000158],
                [-14.600138, 10.87486100000018],
                [-14.600694, 10.875695],
                [-14.60124999999988, 10.875695],
                [-14.601806, 10.876251],
                [-14.601806, 10.876805],
                [-14.602638, 10.876805],
                [-14.603473, 10.877917],
                [-14.604584999999872, 10.878195000000176],
                [-14.605139, 10.878749],
              ],
            ],
            [
              [
                [-14.564028, 10.86125],
                [-14.564584, 10.861806],
                [-14.566528, 10.862638000000118],
                [-14.569028, 10.862638000000118],
                [-14.571528, 10.861806],
                [-14.57263799999987, 10.860972000000118],
                [-14.573471999999867, 10.859862],
                [-14.574582, 10.858749],
                [-14.575137999999868, 10.857639],
                [-14.575416, 10.856805000000122],
                [-14.575416, 10.855971],
                [-14.575694, 10.855417],
                [-14.576251, 10.855139000000122],
                [-14.576529, 10.854305],
                [-14.576529, 10.851529],
                [-14.575416, 10.850695000000144],
                [-14.575137999999868, 10.850139000000127],
                [-14.574582, 10.849861],
                [-14.574582, 10.849027000000149],
                [-14.574028, 10.848195],
                [-14.573471999999867, 10.848195],
                [-14.573194, 10.847639],
                [-14.57263799999987, 10.847639],
                [-14.572084, 10.847083],
                [-14.572084, 10.846529],
                [-14.571528, 10.846251000000109],
                [-14.571528, 10.845695000000148],
                [-14.570416, 10.844585000000109],
                [-14.569862, 10.844585000000109],
                [-14.56930599999987, 10.84375000000017],
                [-14.56875, 10.84375000000017],
                [-14.56763999999987, 10.843194000000153],
                [-14.567362, 10.842638],
                [-14.566528, 10.842638],
                [-14.56625, 10.842082000000175],
                [-14.565696, 10.841806],
                [-14.564862, 10.841806],
                [-14.564305999999874, 10.842082000000175],
                [-14.564305999999874, 10.842638],
                [-14.563749, 10.842916],
                [-14.562918, 10.844028],
                [-14.562639999999874, 10.844585000000109],
                [-14.562083, 10.844585000000109],
                [-14.561526999999899, 10.845138],
                [-14.560138999999879, 10.845695000000148],
                [-14.559027, 10.845695000000148],
                [-14.559027, 10.845973],
                [-14.557083, 10.845973],
                [-14.557083, 10.846251000000109],
                [-14.554583, 10.846251000000109],
                [-14.553195, 10.846804],
                [-14.552638999999829, 10.848195],
                [-14.552638999999829, 10.849305],
                [-14.552917, 10.849305],
                [-14.553195, 10.850139000000127],
                [-14.553751, 10.85124900000011],
                [-14.553751, 10.851805000000127],
                [-14.554583, 10.853471000000127],
                [-14.554861, 10.853473000000122],
                [-14.556249, 10.856249000000105],
                [-14.556249, 10.856805000000122],
                [-14.556804999999883, 10.857639],
                [-14.558194999999898, 10.858471000000122],
                [-14.558472999999879, 10.85902700000014],
                [-14.558472999999879, 10.86014],
                [-14.558749, 10.860415],
                [-14.560970999999881, 10.860415],
                [-14.560970999999881, 10.860696000000189],
                [-14.562360999999896, 10.860696000000189],
                [-14.562360999999896, 10.860972000000118],
                [-14.563470999999879, 10.860972000000118],
                [-14.564028, 10.86125],
              ],
            ],
            [
              [
                [-14.967362, 10.856527],
                [-14.967084, 10.856805000000122],
                [-14.967084, 10.857639],
                [-14.968193, 10.858471000000122],
                [-14.969306, 10.858471000000122],
                [-14.969306, 10.857639],
                [-14.968472, 10.857639],
                [-14.967915, 10.85736100000014],
                [-14.967915, 10.856805000000122],
                [-14.967362, 10.856527],
              ],
            ],
            [
              [
                [-14.889584, 10.918472],
                [-14.889862, 10.919028],
                [-14.890417999999897, 10.919028],
                [-14.890694, 10.919584],
                [-14.891528, 10.919860000000142],
                [-14.893749999999898, 10.919860000000142],
                [-14.895138, 10.91930600000012],
                [-14.895972, 10.918472],
                [-14.895972, 10.917916],
                [-14.897081999999898, 10.917362],
                [-14.897638, 10.917362],
                [-14.897638, 10.916528000000142],
                [-14.898472, 10.915972000000181],
                [-14.898749999999893, 10.91514],
                [-14.899581999999896, 10.91514],
                [-14.89986, 10.914584],
                [-14.90041699999989, 10.914584],
                [-14.900694999999814, 10.914028],
                [-14.901250999999888, 10.914028],
                [-14.901807, 10.913471000000186],
                [-14.902082999999891, 10.91264000000018],
                [-14.902639, 10.91264000000018],
                [-14.902916999999889, 10.911527],
                [-14.903473, 10.911527],
                [-14.903750999999886, 10.91097400000018],
                [-14.903750999999886, 10.910139000000186],
                [-14.904305, 10.910139000000186],
                [-14.904305, 10.909027],
                [-14.904860999999869, 10.90847100000019],
                [-14.905139, 10.907639],
                [-14.905139, 10.906527],
                [-14.905416999999886, 10.906527],
                [-14.905416999999886, 10.905417000000114],
                [-14.905973, 10.904305],
                [-14.906250999999884, 10.904305],
                [-14.906526999999869, 10.90347300000019],
                [-14.906526999999869, 10.902083000000118],
                [-14.906805, 10.901251000000173],
                [-14.907082999999886, 10.901251000000173],
                [-14.907082999999886, 10.900139],
                [-14.907361, 10.899304],
                [-14.907916999999884, 10.898751000000118],
                [-14.907916999999884, 10.897082],
                [-14.908195, 10.895972],
                [-14.908748999999887, 10.895972],
                [-14.908748999999887, 10.895138000000145],
                [-14.909027, 10.894584000000123],
                [-14.909582999999884, 10.894306],
                [-14.909582999999884, 10.893472000000145],
                [-14.910139, 10.893194],
                [-14.910139, 10.89264],
                [-14.910693, 10.89236000000011],
                [-14.910973, 10.891806000000145],
                [-14.910973, 10.890972],
                [-14.911527, 10.890972],
                [-14.911805, 10.889862],
                [-14.912361, 10.889584000000127],
                [-14.91264, 10.88902800000011],
                [-14.913471, 10.888194],
                [-14.913471, 10.88764],
                [-14.914028, 10.88736200000011],
                [-14.914862, 10.886249],
                [-14.914862, 10.885418],
                [-14.91514, 10.884861000000114],
                [-14.915694, 10.884861000000114],
                [-14.915972, 10.883749000000137],
                [-14.916528, 10.883749000000137],
                [-14.917638, 10.882639000000154],
                [-14.918194, 10.882361],
                [-14.918194, 10.881805],
                [-14.91875, 10.881805],
                [-14.919028, 10.881251],
                [-14.92014, 10.880695],
                [-14.920972, 10.879861000000176],
                [-14.920972, 10.879305000000159],
                [-14.921527999999853, 10.879305000000159],
                [-14.921527999999853, 10.878473],
                [-14.922916, 10.877917],
                [-14.923193999999853, 10.876805],
                [-14.92375, 10.876805],
                [-14.92375, 10.876251],
                [-14.924306, 10.876251],
                [-14.924584, 10.875695],
                [-14.925138, 10.875695],
                [-14.92569399999985, 10.875139],
                [-14.92569399999985, 10.874583],
                [-14.926251, 10.874307000000158],
                [-14.926251, 10.873473],
                [-14.927359999999851, 10.87319500000018],
                [-14.927359999999851, 10.872638000000109],
                [-14.928473, 10.871804000000168],
                [-14.928473, 10.87125],
                [-14.929027, 10.870972000000108],
                [-14.929027, 10.870416],
                [-14.929861, 10.869862],
                [-14.930417, 10.86986],
                [-14.930695, 10.869306000000108],
                [-14.931249, 10.869306000000108],
                [-14.931528999999898, 10.86875],
                [-14.932083, 10.868472000000168],
                [-14.934027, 10.868472000000168],
                [-14.934860999999898, 10.867916],
                [-14.934860999999898, 10.86736000000019],
                [-14.935417, 10.867084],
                [-14.93597299999982, 10.86569400000019],
                [-14.93597299999982, 10.864306000000113],
                [-14.936249, 10.864306000000113],
                [-14.936249, 10.862638000000118],
                [-14.93597299999982, 10.862638000000118],
                [-14.93597299999982, 10.861528],
                [-14.936249, 10.860972000000118],
                [-14.935694999999896, 10.860696000000189],
                [-14.935694999999896, 10.859862],
                [-14.935138999999822, 10.858749],
                [-14.934860999999898, 10.857918],
                [-14.934305, 10.857639],
                [-14.934305, 10.852639],
                [-14.933751, 10.852361000000144],
                [-14.933472999999822, 10.851805000000127],
                [-14.933472999999822, 10.848473000000126],
                [-14.932917, 10.846804],
                [-14.932361, 10.846251000000109],
                [-14.932361, 10.844585000000109],
                [-14.932083, 10.844028],
                [-14.931528999999898, 10.84375000000017],
                [-14.931528999999898, 10.840972],
                [-14.930973, 10.839584],
                [-14.930417, 10.839306],
                [-14.930417, 10.838750000000175],
                [-14.929861, 10.838474],
                [-14.929307, 10.837362],
                [-14.929307, 10.836528000000158],
                [-14.928473, 10.83625],
                [-14.927917, 10.835694000000103],
                [-14.927917, 10.830693],
                [-14.928195, 10.829861000000108],
                [-14.927917, 10.829305],
                [-14.926804, 10.829027],
                [-14.926804, 10.828471],
                [-14.925972, 10.827639],
                [-14.924859999999853, 10.827639],
                [-14.924306, 10.827083],
                [-14.923472, 10.827083],
                [-14.922916, 10.826805],
                [-14.922916, 10.826249000000189],
                [-14.921806, 10.825973],
                [-14.921806, 10.825417],
                [-14.92125, 10.825417],
                [-14.92125, 10.824861000000112],
                [-14.920694, 10.824583000000189],
                [-14.920694, 10.822361],
                [-14.919584, 10.821807],
                [-14.917362, 10.821807],
                [-14.917362, 10.821251000000188],
                [-14.916806, 10.820973],
                [-14.917084, 10.820417000000134],
                [-14.917084, 10.818751000000134],
                [-14.917362, 10.818194],
                [-14.916806, 10.817916000000139],
                [-14.916528, 10.817360000000122],
                [-14.916806, 10.816528],
                [-14.916806, 10.815416],
                [-14.917084, 10.815416],
                [-14.917084, 10.812084],
                [-14.91625, 10.811528],
                [-14.915418, 10.811250000000143],
                [-14.914584, 10.810416],
                [-14.913471, 10.809862],
                [-14.910973, 10.809862],
                [-14.910973, 10.810138000000165],
                [-14.909861, 10.810138000000165],
                [-14.909861, 10.809862],
                [-14.908748999999887, 10.809862],
                [-14.908195, 10.809306],
                [-14.907361, 10.809028000000126],
                [-14.906250999999884, 10.809028000000126],
                [-14.906250999999884, 10.80875],
                [-14.903473, 10.80875],
                [-14.902360999999814, 10.808194],
                [-14.902082999999891, 10.80875],
                [-14.901526, 10.809028000000126],
                [-14.90041699999989, 10.809028000000126],
                [-14.89986, 10.809306],
                [-14.89986, 10.809862],
                [-14.899028999999814, 10.810138000000165],
                [-14.89736, 10.811806000000104],
                [-14.89736, 10.812360000000126],
                [-14.896806, 10.81264],
                [-14.896528, 10.813472000000104],
                [-14.896528, 10.814306],
                [-14.895694, 10.815416],
                [-14.894028, 10.814862],
                [-14.892916, 10.814862],
                [-14.892083999999898, 10.815138000000104],
                [-14.891528, 10.815694000000121],
                [-14.890417999999897, 10.815972],
                [-14.890417999999897, 10.816528],
                [-14.889584, 10.816806],
                [-14.889584, 10.817360000000122],
                [-14.887915, 10.817360000000122],
                [-14.88764, 10.817916000000139],
                [-14.88764, 10.819304],
                [-14.887915, 10.819304],
                [-14.887915, 10.820417000000134],
                [-14.888194, 10.820417000000134],
                [-14.888194, 10.822361],
                [-14.888472, 10.822361],
                [-14.888472, 10.824305],
                [-14.888194, 10.824305],
                [-14.888194, 10.826805],
                [-14.888472, 10.826805],
                [-14.888472, 10.831527000000108],
                [-14.887915, 10.83208400000018],
                [-14.886806, 10.83208400000018],
                [-14.886527, 10.832362000000103],
                [-14.886527, 10.835418000000175],
                [-14.886249, 10.83625],
                [-14.885139, 10.836806],
                [-14.884305, 10.836806],
                [-14.883473, 10.837916],
                [-14.883473, 10.838750000000175],
                [-14.882917, 10.839028],
                [-14.881805, 10.839028],
                [-14.881251, 10.839584],
                [-14.880417, 10.839860000000158],
                [-14.879305, 10.839860000000158],
                [-14.878473, 10.840694],
                [-14.878195, 10.841528000000153],
                [-14.878195, 10.842916],
                [-14.878749, 10.843194000000153],
                [-14.878749, 10.84375000000017],
                [-14.879305, 10.84375000000017],
                [-14.879305, 10.844307],
                [-14.880139, 10.844585000000109],
                [-14.880417, 10.845138],
                [-14.880971, 10.84541600000017],
                [-14.882639, 10.845973],
                [-14.883473, 10.845973],
                [-14.883473, 10.846529],
                [-14.884305, 10.846804],
                [-14.885139, 10.847917000000109],
                [-14.885139, 10.85124900000011],
                [-14.884861, 10.85124900000011],
                [-14.884861, 10.852361000000144],
                [-14.884305, 10.852361000000144],
                [-14.884305, 10.854027000000144],
                [-14.884583, 10.854027000000144],
                [-14.884861, 10.854861],
                [-14.884861, 10.856527],
                [-14.885139, 10.856527],
                [-14.885139, 10.860415],
                [-14.884861, 10.860415],
                [-14.884861, 10.862638000000118],
                [-14.884305, 10.862918],
                [-14.884305, 10.86375],
                [-14.883749, 10.864306000000113],
                [-14.883473, 10.865140000000167],
                [-14.883473, 10.86625],
                [-14.882917, 10.86736000000019],
                [-14.882917, 10.869028000000185],
                [-14.883473, 10.869306000000108],
                [-14.883473, 10.86986],
                [-14.884027, 10.869862],
                [-14.884305, 10.870972000000108],
                [-14.884861, 10.87125],
                [-14.884861, 10.871804000000168],
                [-14.885417999999845, 10.872085],
                [-14.885696, 10.87319500000018],
                [-14.886527, 10.873473],
                [-14.886527, 10.874029],
                [-14.887083999999845, 10.874029],
                [-14.888749999999845, 10.87486100000018],
                [-14.889028, 10.875417],
                [-14.889862, 10.875417],
                [-14.890417999999897, 10.875695],
                [-14.890694, 10.876251],
                [-14.89125, 10.87652700000018],
                [-14.892083999999898, 10.87652700000018],
                [-14.89264, 10.876805],
                [-14.892916, 10.877361],
                [-14.893472, 10.877639000000158],
                [-14.894583999999895, 10.877639000000158],
                [-14.895694, 10.878473],
                [-14.895694, 10.879029],
                [-14.896249999999895, 10.879027],
                [-14.897081999999898, 10.879861000000176],
                [-14.897638, 10.880139],
                [-14.897638, 10.880695],
                [-14.898194, 10.880695],
                [-14.899028999999814, 10.882361],
                [-14.899304, 10.883195000000114],
                [-14.899304, 10.884305000000154],
                [-14.899581999999896, 10.884305000000154],
                [-14.899581999999896, 10.88736200000011],
                [-14.899304, 10.88736200000011],
                [-14.899028999999814, 10.888194],
                [-14.899028999999814, 10.889306],
                [-14.898472, 10.890418],
                [-14.896806, 10.892084],
                [-14.895972, 10.89264],
                [-14.895972, 10.893194],
                [-14.895138, 10.894028000000105],
                [-14.894583999999895, 10.894028000000105],
                [-14.894583999999895, 10.894584000000123],
                [-14.893194, 10.895416],
                [-14.89236, 10.895416],
                [-14.89236, 10.895972],
                [-14.89125, 10.897082],
                [-14.890972, 10.897082],
                [-14.890138, 10.897916000000123],
                [-14.890138, 10.898472],
                [-14.889028, 10.899304],
                [-14.888472, 10.899304],
                [-14.888472, 10.89986],
                [-14.88764, 10.89986],
                [-14.88764, 10.900417000000118],
                [-14.887083999999845, 10.900695],
                [-14.886249, 10.901527000000101],
                [-14.885696, 10.901527000000101],
                [-14.885696, 10.902361],
                [-14.885139, 10.902917000000173],
                [-14.884305, 10.904583000000173],
                [-14.884305, 10.907083000000114],
                [-14.884583, 10.907083000000114],
                [-14.884583, 10.908749000000114],
                [-14.884861, 10.908749000000114],
                [-14.884861, 10.910139000000186],
                [-14.885139, 10.910139000000186],
                [-14.885139, 10.911805000000186],
                [-14.885417999999845, 10.911805000000186],
                [-14.885417999999845, 10.914028],
                [-14.885971, 10.91514],
                [-14.886527, 10.915416000000164],
                [-14.886806, 10.916528000000142],
                [-14.88764, 10.917362],
                [-14.888194, 10.917362],
                [-14.889028, 10.918194000000142],
                [-14.889584, 10.918472],
              ],
            ],
            [
              [
                [-14.965417, 10.86875],
                [-14.965696, 10.869582],
                [-14.965696, 10.870416],
                [-14.966527, 10.87125],
                [-14.967084, 10.87125],
                [-14.96764, 10.870694000000185],
                [-14.96764, 10.86986],
                [-14.967362, 10.869028000000185],
                [-14.96653, 10.867638000000113],
                [-14.965696, 10.867638000000113],
                [-14.965696, 10.86875],
                [-14.965417, 10.86875],
              ],
            ],
            [
              [
                [-14.952085, 10.974028],
                [-14.953194, 10.974028],
                [-14.953194, 10.974306000000183],
                [-14.954861, 10.974862],
                [-14.956805, 10.974862],
                [-14.958195, 10.974306000000183],
                [-14.958472999999856, 10.97375],
                [-14.960138999999856, 10.97375],
                [-14.961251, 10.973194],
                [-14.961527, 10.972640000000183],
                [-14.962083, 10.972362],
                [-14.963195, 10.972084],
                [-14.964305, 10.971250000000111],
                [-14.964583, 10.970694],
                [-14.965417, 10.969862],
                [-14.965974, 10.969862],
                [-14.965974, 10.969306],
                [-14.96653, 10.969028],
                [-14.966805, 10.967915],
                [-14.96764, 10.966806000000133],
                [-14.968472, 10.966806000000133],
                [-14.969583999999827, 10.966249],
                [-14.969862, 10.965693],
                [-14.970972, 10.965693],
                [-14.972083999999825, 10.964861000000155],
                [-14.972915999999827, 10.964861000000155],
                [-14.973472, 10.964305000000138],
                [-14.974305999999899, 10.964027],
                [-14.9759719999999, 10.964027],
                [-14.9759719999999, 10.96374900000012],
                [-14.9776379999999, 10.96374900000012],
                [-14.978194, 10.963195000000155],
                [-14.979304, 10.963195000000155],
                [-14.980415999999877, 10.962639000000138],
                [-14.981250999999872, 10.962361],
                [-14.982361, 10.961807],
                [-14.982361, 10.96152700000016],
                [-14.98375099999987, 10.96152700000016],
                [-14.98375099999987, 10.961251],
                [-14.984861, 10.961251],
                [-14.985695, 10.960971000000143],
                [-14.986251, 10.96041700000012],
                [-14.986251, 10.95986100000016],
                [-14.987917, 10.95819500000016],
                [-14.989583, 10.957639000000142],
                [-14.989583, 10.957083000000125],
                [-14.990139, 10.957083000000125],
                [-14.990417, 10.956527000000165],
                [-14.990973, 10.956527000000165],
                [-14.990973, 10.955695],
                [-14.992361, 10.955139],
                [-14.992639, 10.954583],
                [-14.993196, 10.954583],
                [-14.993471, 10.954029],
                [-14.994027, 10.953751000000125],
                [-14.99514, 10.953751000000125],
                [-14.99514, 10.953473],
                [-14.996249999999861, 10.953473],
                [-14.996249999999861, 10.953195000000164],
                [-14.998194, 10.953195000000164],
                [-14.99875, 10.952638],
                [-15.000694, 10.952638],
                [-15.00125, 10.952085000000125],
                [-15.002638, 10.952085000000125],
                [-15.002916, 10.951528],
                [-15.003472, 10.951528],
                [-15.004584, 10.950416],
                [-15.005138, 10.950138],
                [-15.005138, 10.949582],
                [-15.005694, 10.949306],
                [-15.005694, 10.94875],
                [-15.00625, 10.948472],
                [-15.00652899999983, 10.947916],
                [-15.007638, 10.946806],
                [-15.007638, 10.944862],
                [-15.007917, 10.944862],
                [-15.007917, 10.94375],
                [-15.007638, 10.943752],
                [-15.007638, 10.940972000000102],
                [-15.007917, 10.940972000000102],
                [-15.007917, 10.939305],
                [-15.00819499999983, 10.939305],
                [-15.00819499999983, 10.937917],
                [-15.008473, 10.937917],
                [-15.008473, 10.936249],
                [-15.008751, 10.936249],
                [-15.008751, 10.935139000000106],
                [-15.009307, 10.934305],
                [-15.010139, 10.934305],
                [-15.010417, 10.933751],
                [-15.010973, 10.933473000000106],
                [-15.012360999999885, 10.933473000000106],
                [-15.012917, 10.932639],
                [-15.012917, 10.929027000000133],
                [-15.014026999999885, 10.928195],
                [-15.014305, 10.927361000000133],
                [-15.015417, 10.926804],
                [-15.015973, 10.926804],
                [-15.016526999999883, 10.92625100000015],
                [-15.017639, 10.92625100000015],
                [-15.018192999999883, 10.925416000000155],
                [-15.018749, 10.925416000000155],
                [-15.019582999999898, 10.92458500000015],
                [-15.02014, 10.924306000000115],
                [-15.021248999999898, 10.923194000000137],
                [-15.021248999999898, 10.922916],
                [-15.022918, 10.922916],
                [-15.022918, 10.92263800000012],
                [-15.02375, 10.922362],
                [-15.024584, 10.922362],
                [-15.02514, 10.92097200000012],
                [-15.025972, 10.92125],
                [-15.027084, 10.921806],
                [-15.02875, 10.921806],
                [-15.02875, 10.92208200000016],
                [-15.033472, 10.92208200000016],
                [-15.035139, 10.921528000000137],
                [-15.035695, 10.92125],
                [-15.035973, 10.920694],
                [-15.036527, 10.92041600000016],
                [-15.037361, 10.919584],
                [-15.037917, 10.919584],
                [-15.039029, 10.918472],
                [-15.039029, 10.917916],
                [-15.039583, 10.917638000000181],
                [-15.039583, 10.917084000000159],
                [-15.040417, 10.916528000000142],
                [-15.040417, 10.915694],
                [-15.042083, 10.914028],
                [-15.042638999999838, 10.914028],
                [-15.042917, 10.913471000000186],
                [-15.043472999999835, 10.913471000000186],
                [-15.043472999999835, 10.912918],
                [-15.044027, 10.912915000000169],
                [-15.044027, 10.912362],
                [-15.044861, 10.911527],
                [-15.045970999999838, 10.911527],
                [-15.046249, 10.91097400000018],
                [-15.046804999999836, 10.91097400000018],
                [-15.046804999999836, 10.910417],
                [-15.047915, 10.910417],
                [-15.049028, 10.909861],
                [-15.049862, 10.909583000000168],
                [-15.049862, 10.909027],
                [-15.050694, 10.909027],
                [-15.05125, 10.908195],
                [-15.052084, 10.907917000000168],
                [-15.052084, 10.907361],
                [-15.053194, 10.906527],
                [-15.05375, 10.906249000000173],
                [-15.054862, 10.90513900000019],
                [-15.054862, 10.904583000000173],
                [-15.055971999999883, 10.903751000000113],
                [-15.055971999999883, 10.903195],
                [-15.056805999999881, 10.902639],
                [-15.056805999999881, 10.902083000000118],
                [-15.059028, 10.89986],
                [-15.059585, 10.89986],
                [-15.060137999999881, 10.899304],
                [-15.060137999999881, 10.898751000000118],
                [-15.060694, 10.898194],
                [-15.062082, 10.898194],
                [-15.062638999999876, 10.897638],
                [-15.062638999999876, 10.897082],
                [-15.063751, 10.896250000000123],
                [-15.063751, 10.895694000000105],
                [-15.064304999999877, 10.895416],
                [-15.064304999999877, 10.894862],
                [-15.064861, 10.894862],
                [-15.064861, 10.894306],
                [-15.065695, 10.893472000000145],
                [-15.066249, 10.893194],
                [-15.066249, 10.89264],
                [-15.066804999999874, 10.89236000000011],
                [-15.066527, 10.891806000000145],
                [-15.066527, 10.890418],
                [-15.066804999999874, 10.890418],
                [-15.066804999999874, 10.88902800000011],
                [-15.067638999999872, 10.88902800000011],
                [-15.067917, 10.887916000000132],
                [-15.069304999999872, 10.887916000000132],
                [-15.069304999999872, 10.888194],
                [-15.070417, 10.888194],
                [-15.070973, 10.88764],
                [-15.071808, 10.88764],
                [-15.072639, 10.887084],
                [-15.072639, 10.886528],
                [-15.073196, 10.885971000000154],
                [-15.073749, 10.885971000000154],
                [-15.073749, 10.884861000000114],
                [-15.074027, 10.884861000000114],
                [-15.074027, 10.883749000000137],
                [-15.073471, 10.883473],
                [-15.073471, 10.882639000000154],
                [-15.074027, 10.882083000000137],
                [-15.074305, 10.881251],
                [-15.074305, 10.880139],
                [-15.074584, 10.880139],
                [-15.074584, 10.879027],
                [-15.074862, 10.879029],
                [-15.074862, 10.877917],
                [-15.07514, 10.877917],
                [-15.07514, 10.876805],
                [-15.075417999999843, 10.876805],
                [-15.075417999999843, 10.875417],
                [-15.075693, 10.875417],
                [-15.075693, 10.873751],
                [-15.075972, 10.873751],
                [-15.075972, 10.87152900000018],
                [-15.07625, 10.87152900000018],
                [-15.07625, 10.869306000000108],
                [-15.075972, 10.869306000000108],
                [-15.075972, 10.867638000000113],
                [-15.075693, 10.867638000000113],
                [-15.075693, 10.86625],
                [-15.075417999999843, 10.86625],
                [-15.075417999999843, 10.865140000000167],
                [-15.074862, 10.863472],
                [-15.074584, 10.863472],
                [-15.074584, 10.86236200000019],
                [-15.074305, 10.861528],
                [-15.073749, 10.860972000000118],
                [-15.072917, 10.85902700000014],
                [-15.072917, 10.858193],
                [-15.071527, 10.855417],
                [-15.071808, 10.855417],
                [-15.071808, 10.853473000000122],
                [-15.071527, 10.853471000000127],
                [-15.070973, 10.852361000000144],
                [-15.070973, 10.851805000000127],
                [-15.070417, 10.851529],
                [-15.069583, 10.849861],
                [-15.069583, 10.849305],
                [-15.069027, 10.849027000000149],
                [-15.067917, 10.846804],
                [-15.067361, 10.846251000000109],
                [-15.067361, 10.845695000000148],
                [-15.066527, 10.844860000000153],
                [-15.066249, 10.844307],
                [-15.064861, 10.843194000000153],
                [-15.064029, 10.842082000000175],
                [-15.064029, 10.841528000000153],
                [-15.063472999999874, 10.84125],
                [-15.063472999999874, 10.840694],
                [-15.062638999999876, 10.839584],
                [-15.062082, 10.839306],
                [-15.062082, 10.838750000000175],
                [-15.061529, 10.838474],
                [-15.061529, 10.837916],
                [-15.060972999999876, 10.837638],
                [-15.060137999999881, 10.836528000000158],
                [-15.060137999999881, 10.835972],
                [-15.059585, 10.835694000000103],
                [-15.059585, 10.83514],
                [-15.059028, 10.834862000000157],
                [-15.059028, 10.834306],
                [-15.055694, 10.829861000000108],
                [-15.054862, 10.829305],
                [-15.054862, 10.828749],
                [-15.053194, 10.827083],
                [-15.053194, 10.826527000000112],
                [-15.052362, 10.825417],
                [-15.051805999999885, 10.825139],
                [-15.051805999999885, 10.824583000000189],
                [-15.05125, 10.824305],
                [-15.05125, 10.823751],
                [-15.050694, 10.823473],
                [-15.050694, 10.822917000000189],
                [-15.049862, 10.822361],
                [-15.049862, 10.821807],
                [-15.049305999999888, 10.821527000000117],
                [-15.049305999999888, 10.820973],
                [-15.04875, 10.820695],
                [-15.04875, 10.820139],
                [-15.048196, 10.819861000000117],
                [-15.047362, 10.818751000000134],
                [-15.047362, 10.818194],
                [-15.046249, 10.817082],
                [-15.045970999999838, 10.816528],
                [-15.045138999999836, 10.815694000000121],
                [-15.044861, 10.815138000000104],
                [-15.044027, 10.814306],
                [-15.043472999999835, 10.814028000000121],
                [-15.043472999999835, 10.813472000000104],
                [-15.042917, 10.812916000000143],
                [-15.042361, 10.81264],
                [-15.042083, 10.812084],
                [-15.040971, 10.810972],
                [-15.040971, 10.810416],
                [-15.040139, 10.809306],
                [-15.039305, 10.80875],
                [-15.039305, 10.808194],
                [-15.038749, 10.807916000000148],
                [-15.038749, 10.807362000000126],
                [-15.037917, 10.806806000000165],
                [-15.037917, 10.806250000000148],
                [-15.036805, 10.805140000000165],
                [-15.036527, 10.804583],
                [-15.035139, 10.803193],
                [-15.034859999999867, 10.803193],
                [-15.034029, 10.802361],
                [-15.034029, 10.801805],
                [-15.033193999999867, 10.801251],
                [-15.032638, 10.800417000000152],
                [-15.032084, 10.800139],
                [-15.032084, 10.799583],
                [-15.030972, 10.798473],
                [-15.030693999999869, 10.797917],
                [-15.028193999999871, 10.794583000000102],
                [-15.02514, 10.791529],
                [-15.02514, 10.790972],
                [-15.024584, 10.78986],
                [-15.02375, 10.788750000000107],
                [-15.02264, 10.788194],
                [-15.02264, 10.787638000000129],
                [-15.021806, 10.787084000000107],
                [-15.021806, 10.786528],
                [-15.021248999999898, 10.78625],
                [-15.019860999999878, 10.784862],
                [-15.019305, 10.784584],
                [-15.018473, 10.783750000000111],
                [-15.017639, 10.782362],
                [-15.016526999999883, 10.78125],
                [-15.015417, 10.780694],
                [-15.015139, 10.780140000000188],
                [-15.014026999999885, 10.779862],
                [-15.013751, 10.779306000000133],
                [-15.012639, 10.779028],
                [-15.012360999999885, 10.778471000000138],
                [-15.010973, 10.777918],
                [-15.010139, 10.777918],
                [-15.009583, 10.777361],
                [-15.007085, 10.776536],
                [-15.00625, 10.776527],
                [-15.004584, 10.775974000000133],
                [-15.004584, 10.775695],
                [-15.003472, 10.775695],
                [-15.002638, 10.775417],
                [-15.002638, 10.775139000000138],
                [-15.001528, 10.775139000000138],
                [-15.001528, 10.774861],
                [-14.998194, 10.774861],
                [-14.997362, 10.77458300000012],
                [-14.997362, 10.774305],
                [-14.996249999999861, 10.774305],
                [-14.996249999999861, 10.77402700000016],
                [-14.995418, 10.773751],
                [-14.993749, 10.773751],
                [-14.993749, 10.773471000000143],
                [-14.992083, 10.773471000000143],
                [-14.992083, 10.773195],
                [-14.990139, 10.773195],
                [-14.990139, 10.77291700000012],
                [-14.988471, 10.77291700000012],
                [-14.988471, 10.772639],
                [-14.98708299999987, 10.772639],
                [-14.98708299999987, 10.77236100000016],
                [-14.985695, 10.77236100000016],
                [-14.985695, 10.772083],
                [-14.984029, 10.772083],
                [-14.984029, 10.771805000000143],
                [-14.982638999999892, 10.771805000000143],
                [-14.982638999999892, 10.771529],
                [-14.981250999999872, 10.771529],
                [-14.981250999999872, 10.771249000000125],
                [-14.97791599999988, 10.771249000000125],
                [-14.97791599999988, 10.770973],
                [-14.976528, 10.770973],
                [-14.976528, 10.77069500000016],
                [-14.974862, 10.77069500000016],
                [-14.974862, 10.770417],
                [-14.970972, 10.770417],
                [-14.969306, 10.769861],
                [-14.969306, 10.769583000000125],
                [-14.967084, 10.769583000000125],
                [-14.967084, 10.769305],
                [-14.964861, 10.769305],
                [-14.964305, 10.768751],
                [-14.960695, 10.768751],
                [-14.960138999999856, 10.769305],
                [-14.958749, 10.769305],
                [-14.958195, 10.769861],
                [-14.956529, 10.769861],
                [-14.956529, 10.770139000000142],
                [-14.954582, 10.770139000000142],
                [-14.952916, 10.771805000000143],
                [-14.952085, 10.77236100000016],
                [-14.951804, 10.773195],
                [-14.951804, 10.77458300000012],
                [-14.952638, 10.774861],
                [-14.952638, 10.775417],
                [-14.953194, 10.775695],
                [-14.953194, 10.776805000000138],
                [-14.952638, 10.776805000000138],
                [-14.95236, 10.77624900000012],
                [-14.951804, 10.775695],
                [-14.950694, 10.775695],
                [-14.950694, 10.775974000000133],
                [-14.949306, 10.777361],
                [-14.949306, 10.778193],
                [-14.94875, 10.779028],
                [-14.948193999999887, 10.779028],
                [-14.947916, 10.779584],
                [-14.947916, 10.780694],
                [-14.947084, 10.78125],
                [-14.947084, 10.781806000000188],
                [-14.946527999999887, 10.782084000000111],
                [-14.94625, 10.782918],
                [-14.94514, 10.782638000000134],
                [-14.94514, 10.781806000000188],
                [-14.944861999999887, 10.781528],
                [-14.94402799999989, 10.781806000000188],
                [-14.94402799999989, 10.782918],
                [-14.94375, 10.782918],
                [-14.94375, 10.784028],
                [-14.943471999999872, 10.784584],
                [-14.942918, 10.784862],
                [-14.942918, 10.785972000000129],
                [-14.94263999999987, 10.785972000000129],
                [-14.94263999999987, 10.787084000000107],
                [-14.941805999999872, 10.788194],
                [-14.940695999999889, 10.788194],
                [-14.940415, 10.789028],
                [-14.939583, 10.789582],
                [-14.938749, 10.791251000000102],
                [-14.938470999999822, 10.792085000000156],
                [-14.938470999999822, 10.793473],
                [-14.939026999999896, 10.793751000000157],
                [-14.939026999999896, 10.794307000000174],
                [-14.939861999999891, 10.794307000000174],
                [-14.940139999999872, 10.794861],
                [-14.94097099999982, 10.795695],
                [-14.94125, 10.795695],
                [-14.942084, 10.796527],
                [-14.942084, 10.796805],
                [-14.942918, 10.797639000000174],
                [-14.943471999999872, 10.797639000000174],
                [-14.94402799999989, 10.798195],
                [-14.94430599999987, 10.799305000000174],
                [-14.944584, 10.799861],
                [-14.94514, 10.800139],
                [-14.94514, 10.800695],
                [-14.94569399999989, 10.800695],
                [-14.94569399999989, 10.801983],
                [-14.94597199999987, 10.802083000000152],
                [-14.946527999999887, 10.803193],
                [-14.946806, 10.80430500000017],
                [-14.947916, 10.80430500000017],
                [-14.949028, 10.803193],
                [-14.949862, 10.804027],
                [-14.951528, 10.804862],
                [-14.95236, 10.804862],
                [-14.952916, 10.805418],
                [-14.953473, 10.805418],
                [-14.954582, 10.80597100000017],
                [-14.954861, 10.806528],
                [-14.956251, 10.807084],
                [-14.959583, 10.807084],
                [-14.960138999999856, 10.806250000000148],
                [-14.960970999999859, 10.806250000000148],
                [-14.960695, 10.807362000000126],
                [-14.960138999999856, 10.80764],
                [-14.960138999999856, 10.809306],
                [-14.959861, 10.809306],
                [-14.959861, 10.810694000000126],
                [-14.959583, 10.811528],
                [-14.959861, 10.812084],
                [-14.960417, 10.812084],
                [-14.960695, 10.81264],
                [-14.961251, 10.812916000000143],
                [-14.962917, 10.812916000000143],
                [-14.962917, 10.813194],
                [-14.965417, 10.813194],
                [-14.965417, 10.812916000000143],
                [-14.966805, 10.812916000000143],
                [-14.966805, 10.813194],
                [-14.968193, 10.813194],
                [-14.968193, 10.813472000000104],
                [-14.969306, 10.813472000000104],
                [-14.969306, 10.81375],
                [-14.970138, 10.814028000000121],
                [-14.971249999999827, 10.814028000000121],
                [-14.971528, 10.814584000000139],
                [-14.972083999999825, 10.814862],
                [-14.973194, 10.815138000000104],
                [-14.973749999999825, 10.815972],
                [-14.974305999999899, 10.815972],
                [-14.974305999999899, 10.816528],
                [-14.974862, 10.816528],
                [-14.975138, 10.817360000000122],
                [-14.975694, 10.817360000000122],
                [-14.976528, 10.818194],
                [-14.976805999999897, 10.818751000000134],
                [-14.97736, 10.818751000000134],
                [-14.97791599999988, 10.819304],
                [-14.97791599999988, 10.819861000000117],
                [-14.978471999999897, 10.820973],
                [-14.979028, 10.821251000000188],
                [-14.978749999999877, 10.822083000000134],
                [-14.978749999999877, 10.824029],
                [-14.979304, 10.824029],
                [-14.979304, 10.825139],
                [-14.97986, 10.825973],
                [-14.980695, 10.826249000000189],
                [-14.982916999999873, 10.826249000000189],
                [-14.983473, 10.826527000000112],
                [-14.984304999999893, 10.827639],
                [-14.984861, 10.827639],
                [-14.985139, 10.828195000000107],
                [-14.985695, 10.828195000000107],
                [-14.986251, 10.828749],
                [-14.986527, 10.829305],
                [-14.98708299999987, 10.829305],
                [-14.987361, 10.829861000000108],
                [-14.987917, 10.829861000000108],
                [-14.988195, 10.830417],
                [-14.988751, 10.830417],
                [-14.989027, 10.830971],
                [-14.989583, 10.830974],
                [-14.989861, 10.831527000000108],
                [-14.990973, 10.831805],
                [-14.991249, 10.832362000000103],
                [-14.991805, 10.832362000000103],
                [-14.992083, 10.832915000000185],
                [-14.993196, 10.833196000000157],
                [-14.993749, 10.83375000000018],
                [-14.994583999999861, 10.833472],
                [-14.995694, 10.833472],
                [-14.996806, 10.832915000000185],
                [-14.998472, 10.831249000000184],
                [-14.999028, 10.831249000000184],
                [-14.999584, 10.830417],
                [-15.000416, 10.830417],
                [-15.00125, 10.829861000000108],
                [-15.00125, 10.828471],
                [-15.002084, 10.828195000000107],
                [-15.002638, 10.827083],
                [-15.002638, 10.826527000000112],
                [-15.003472, 10.825139],
                [-15.004584, 10.825139],
                [-15.00486, 10.824583000000189],
                [-15.005972, 10.824583000000189],
                [-15.00625, 10.825139],
                [-15.005416, 10.825139],
                [-15.005416, 10.825695],
                [-15.004306, 10.825973],
                [-15.004028, 10.826527000000112],
                [-15.003472, 10.826527000000112],
                [-15.003472, 10.827917000000184],
                [-15.002916, 10.828471],
                [-15.002916, 10.829027],
                [-15.00125, 10.830693],
                [-15.000694, 10.831805],
                [-15.000694, 10.832915000000185],
                [-15.000416, 10.83375000000018],
                [-14.999584, 10.83375000000018],
                [-14.999584, 10.834306],
                [-14.999028, 10.834584],
                [-14.999028, 10.83514],
                [-14.998472, 10.835418000000175],
                [-14.998472, 10.835972],
                [-14.997362, 10.835972],
                [-14.997362, 10.83625],
                [-14.996528, 10.837084000000175],
                [-14.995972, 10.837084000000175],
                [-14.995972, 10.837638],
                [-14.99514, 10.837916],
                [-14.994862, 10.838750000000175],
                [-14.992639, 10.838750000000175],
                [-14.992361, 10.838194000000158],
                [-14.991805, 10.838194000000158],
                [-14.991527, 10.837638],
                [-14.990973, 10.837362],
                [-14.990139, 10.837362],
                [-14.990139, 10.836806],
                [-14.989305, 10.836528000000158],
                [-14.989027, 10.835972],
                [-14.988471, 10.835694000000103],
                [-14.987639, 10.835694000000103],
                [-14.98708299999987, 10.835418000000175],
                [-14.98708299999987, 10.834862000000157],
                [-14.97986, 10.834862000000157],
                [-14.979028, 10.834584],
                [-14.978749999999877, 10.834028000000103],
                [-14.97791599999988, 10.834028000000103],
                [-14.97736, 10.833472],
                [-14.976528, 10.833196000000157],
                [-14.97458399999988, 10.833196000000157],
                [-14.973749999999825, 10.832915000000185],
                [-14.973194, 10.832362000000103],
                [-14.972083999999825, 10.832362000000103],
                [-14.972083999999825, 10.83208400000018],
                [-14.970972, 10.83208400000018],
                [-14.970972, 10.831527000000108],
                [-14.970417999999825, 10.831249000000184],
                [-14.969583999999827, 10.831249000000184],
                [-14.969028, 10.830693],
                [-14.967915, 10.830693],
                [-14.967362, 10.830139],
                [-14.964583, 10.830139],
                [-14.964583, 10.829861000000108],
                [-14.963195, 10.829861000000108],
                [-14.962639, 10.830693],
                [-14.962083, 10.830974],
                [-14.961251, 10.830974],
                [-14.960695, 10.831249000000184],
                [-14.960695, 10.834306],
                [-14.960970999999859, 10.834306],
                [-14.960970999999859, 10.835418000000175],
                [-14.961251, 10.835418000000175],
                [-14.961804999999856, 10.836528000000158],
                [-14.960970999999859, 10.838472],
                [-14.960970999999859, 10.839584],
                [-14.961251, 10.840416000000175],
                [-14.962083, 10.842082000000175],
                [-14.962639, 10.842082000000175],
                [-14.963473, 10.84375000000017],
                [-14.963473, 10.844860000000153],
                [-14.963195, 10.844860000000153],
                [-14.963195, 10.845973],
                [-14.964027, 10.847639],
                [-14.964583, 10.847917000000109],
                [-14.965139, 10.849027000000149],
                [-14.965139, 10.84958300000011],
                [-14.965696, 10.849861],
                [-14.965696, 10.850417],
                [-14.966249, 10.850695000000144],
                [-14.966249, 10.85124900000011],
                [-14.966805, 10.851529],
                [-14.966805, 10.853471000000127],
                [-14.967084, 10.854305],
                [-14.96764, 10.855417],
                [-14.968193, 10.855971],
                [-14.968751999999824, 10.855971],
                [-14.969306, 10.856805000000122],
                [-14.970138, 10.856805000000122],
                [-14.970417999999825, 10.857918],
                [-14.970972, 10.85902700000014],
                [-14.972083999999825, 10.85902700000014],
                [-14.973472, 10.859862],
                [-14.973472, 10.860415],
                [-14.974028, 10.860696000000189],
                [-14.974305999999899, 10.861806],
                [-14.975138, 10.862084],
                [-14.975415999999825, 10.862638000000118],
                [-14.9776379999999, 10.862638000000118],
                [-14.978471999999897, 10.86236200000019],
                [-14.978471999999897, 10.862084],
                [-14.980695, 10.862084],
                [-14.980695, 10.86236200000019],
                [-14.981807, 10.862918],
                [-14.982638999999892, 10.862918],
                [-14.982916999999873, 10.863194],
                [-14.982916999999873, 10.865140000000167],
                [-14.982638999999892, 10.86569400000019],
                [-14.982082999999875, 10.86569400000019],
                [-14.982082999999875, 10.86375],
                [-14.981526, 10.862918],
                [-14.97986, 10.862918],
                [-14.979028, 10.863194],
                [-14.978194, 10.86375],
                [-14.978194, 10.864306000000113],
                [-14.9776379999999, 10.864306000000113],
                [-14.977081999999825, 10.865416],
                [-14.977081999999825, 10.866528],
                [-14.976528, 10.866806000000167],
                [-14.976528, 10.86736000000019],
                [-14.9759719999999, 10.867638000000113],
                [-14.9759719999999, 10.868472000000168],
                [-14.975694, 10.869028000000185],
                [-14.974862, 10.869028000000185],
                [-14.974862, 10.867916],
                [-14.975415999999825, 10.866806000000167],
                [-14.9759719999999, 10.866528],
                [-14.9759719999999, 10.865972000000113],
                [-14.976528, 10.86569400000019],
                [-14.976528, 10.864584],
                [-14.975694, 10.864306000000113],
                [-14.974028, 10.864306000000113],
                [-14.974028, 10.864584],
                [-14.972362, 10.864584],
                [-14.971806, 10.86402800000019],
                [-14.970694, 10.864584],
                [-14.969862, 10.864584],
                [-14.969583999999827, 10.865140000000167],
                [-14.969583999999827, 10.86986],
                [-14.969862, 10.86986],
                [-14.969862, 10.873473],
                [-14.969583999999827, 10.873473],
                [-14.969583999999827, 10.87486100000018],
                [-14.969306, 10.87486100000018],
                [-14.969306, 10.875973000000158],
                [-14.969028, 10.875973000000158],
                [-14.967915, 10.878195000000176],
                [-14.967915, 10.879029],
                [-14.968472, 10.880695],
                [-14.969306, 10.882083000000137],
                [-14.970417999999825, 10.882917],
                [-14.972083999999825, 10.883749000000137],
                [-14.973472, 10.883749000000137],
                [-14.973472, 10.885140000000149],
                [-14.973194, 10.885693],
                [-14.972639999999899, 10.885971000000154],
                [-14.970694, 10.885971000000154],
                [-14.970694, 10.885693],
                [-14.969583999999827, 10.885693],
                [-14.969028, 10.885418],
                [-14.968193, 10.884583],
                [-14.96764, 10.883473],
                [-14.96764, 10.882639000000154],
                [-14.967084, 10.882361],
                [-14.967084, 10.881805],
                [-14.966527, 10.881527000000176],
                [-14.966249, 10.880695],
                [-14.966249, 10.879583],
                [-14.965974, 10.879585],
                [-14.965974, 10.877361],
                [-14.965696, 10.87652700000018],
                [-14.965417, 10.87652700000018],
                [-14.965417, 10.875417],
                [-14.965139, 10.875417],
                [-14.965139, 10.874307000000158],
                [-14.964861, 10.874307000000158],
                [-14.964861, 10.87319500000018],
                [-14.964583, 10.87319500000018],
                [-14.964583, 10.872085],
                [-14.964305, 10.872085],
                [-14.964305, 10.869582],
                [-14.964583, 10.869582],
                [-14.964583, 10.868194],
                [-14.964027, 10.867084],
                [-14.962917, 10.86569400000019],
                [-14.961527, 10.86569400000019],
                [-14.961527, 10.865416],
                [-14.960695, 10.864584],
                [-14.960417, 10.86402800000019],
                [-14.959027, 10.862638000000118],
                [-14.958195, 10.862638000000118],
                [-14.957638999999858, 10.862084],
                [-14.956805, 10.862084],
                [-14.954306999999858, 10.86125],
                [-14.953751, 10.860696000000189],
                [-14.952916, 10.860696000000189],
                [-14.95236, 10.86014],
                [-14.951528, 10.859862],
                [-14.950138, 10.859862],
                [-14.949862, 10.860415],
                [-14.949306, 10.860415],
                [-14.94875, 10.860972000000118],
                [-14.947916, 10.862638000000118],
                [-14.947916, 10.863194],
                [-14.947084, 10.864862],
                [-14.94625, 10.864862],
                [-14.94597199999987, 10.865416],
                [-14.944584, 10.865416],
                [-14.94402799999989, 10.86569400000019],
                [-14.943471999999872, 10.866528],
                [-14.943193999999892, 10.86736000000019],
                [-14.943193999999892, 10.86875],
                [-14.942918, 10.86875],
                [-14.942918, 10.86986],
                [-14.94236199999989, 10.870416],
                [-14.94236199999989, 10.87125],
                [-14.941527999999892, 10.87152900000018],
                [-14.94125, 10.872085],
                [-14.940695999999889, 10.872085],
                [-14.940139999999872, 10.872638000000109],
                [-14.940139999999872, 10.873473],
                [-14.939861999999891, 10.874029],
                [-14.93930499999982, 10.874307000000158],
                [-14.93930499999982, 10.87486100000018],
                [-14.937917, 10.875417],
                [-14.937917, 10.875973000000158],
                [-14.937360999999896, 10.875973000000158],
                [-14.937360999999896, 10.87652700000018],
                [-14.936526999999899, 10.876805],
                [-14.936526999999899, 10.877361],
                [-14.93597299999982, 10.877639000000158],
                [-14.93597299999982, 10.878473],
                [-14.935138999999822, 10.878749],
                [-14.934860999999898, 10.879585],
                [-14.934305, 10.879583],
                [-14.933194999999898, 10.880695],
                [-14.932639, 10.880971000000159],
                [-14.932639, 10.881527000000176],
                [-14.932083, 10.881527000000176],
                [-14.931249, 10.882917],
                [-14.930695, 10.883195000000114],
                [-14.930695, 10.883749000000137],
                [-14.930139, 10.883749000000137],
                [-14.930139, 10.884305000000154],
                [-14.929307, 10.884583],
                [-14.929307, 10.885140000000149],
                [-14.928473, 10.885418],
                [-14.928473, 10.885971000000154],
                [-14.927639, 10.886249],
                [-14.927359999999851, 10.887084],
                [-14.926804, 10.88736200000011],
                [-14.926804, 10.887916000000132],
                [-14.926251, 10.888194],
                [-14.925138, 10.888194],
                [-14.925138, 10.88847200000015],
                [-14.92402799999985, 10.88847200000015],
                [-14.923472, 10.889584000000127],
                [-14.922916, 10.89013800000015],
                [-14.922916, 10.89069400000011],
                [-14.92236199999985, 10.89069400000011],
                [-14.92236199999985, 10.891806000000145],
                [-14.921806, 10.892084],
                [-14.921806, 10.892916000000127],
                [-14.921527999999853, 10.893472000000145],
                [-14.920416, 10.894028000000105],
                [-14.920416, 10.894584000000123],
                [-14.91986, 10.894862],
                [-14.91875, 10.897082],
                [-14.91875, 10.897638],
                [-14.918194, 10.897916000000123],
                [-14.917916, 10.899029],
                [-14.917362, 10.899585000000172],
                [-14.917084, 10.900695],
                [-14.916528, 10.900973],
                [-14.916528, 10.901527000000101],
                [-14.915694, 10.902639],
                [-14.91514, 10.902917000000173],
                [-14.91514, 10.90347300000019],
                [-14.914584, 10.903751000000113],
                [-14.914584, 10.904583000000173],
                [-14.914028, 10.904583000000173],
                [-14.914028, 10.905417000000114],
                [-14.913196, 10.905973],
                [-14.913196, 10.906527],
                [-14.912361, 10.907083000000114],
                [-14.912361, 10.908195],
                [-14.911527, 10.90847100000019],
                [-14.910973, 10.909583000000168],
                [-14.910973, 10.910417],
                [-14.910416999999882, 10.910417],
                [-14.910416999999882, 10.91097400000018],
                [-14.909861, 10.91097400000018],
                [-14.909305, 10.911527],
                [-14.909305, 10.912083],
                [-14.908748999999887, 10.912362],
                [-14.908748999999887, 10.912918],
                [-14.907916999999884, 10.914028],
                [-14.906805, 10.914862000000142],
                [-14.905973, 10.91514],
                [-14.905973, 10.915694],
                [-14.904860999999869, 10.916806],
                [-14.904582999999889, 10.916806],
                [-14.903750999999886, 10.918472],
                [-14.903473, 10.91930600000012],
                [-14.903473, 10.92125],
                [-14.903195, 10.92125],
                [-14.903195, 10.922362],
                [-14.902916999999889, 10.922362],
                [-14.902916999999889, 10.923472],
                [-14.902360999999814, 10.923750000000155],
                [-14.902082999999891, 10.92458500000015],
                [-14.902082999999891, 10.925973],
                [-14.901807, 10.925973],
                [-14.901807, 10.929027000000133],
                [-14.902360999999814, 10.929307],
                [-14.902360999999814, 10.929861],
                [-14.902916999999889, 10.93013900000011],
                [-14.902916999999889, 10.930695000000128],
                [-14.903473, 10.930973],
                [-14.903473, 10.931529],
                [-14.904029, 10.93180500000011],
                [-14.904305, 10.932361000000128],
                [-14.904305, 10.933195],
                [-14.904860999999869, 10.934305],
                [-14.905416999999886, 10.934305],
                [-14.905416999999886, 10.934861],
                [-14.905973, 10.935695000000123],
                [-14.906526999999869, 10.935695000000123],
                [-14.906805, 10.936805000000106],
                [-14.907361, 10.937083],
                [-14.908471, 10.938193],
                [-14.909027, 10.938471000000106],
                [-14.909027, 10.939027000000124],
                [-14.910416999999882, 10.940415],
                [-14.911805, 10.94125],
                [-14.912082999999882, 10.941806],
                [-14.91264, 10.941806],
                [-14.913471, 10.94264],
                [-14.914028, 10.943472],
                [-14.914584, 10.943752],
                [-14.915972, 10.94514],
                [-14.916528, 10.94514],
                [-14.916806, 10.945694000000174],
                [-14.917638, 10.946528],
                [-14.91875, 10.947360000000174],
                [-14.919584, 10.947638],
                [-14.919584, 10.948194],
                [-14.920416, 10.94875],
                [-14.92125, 10.949862],
                [-14.92125, 10.950416],
                [-14.921806, 10.950972],
                [-14.922916, 10.951528],
                [-14.92375, 10.95236000000017],
                [-14.925138, 10.952916000000187],
                [-14.92569399999985, 10.953473],
                [-14.92569399999985, 10.954029],
                [-14.926804, 10.954307000000142],
                [-14.926804, 10.954861000000164],
                [-14.927359999999851, 10.955139],
                [-14.928195, 10.955973000000142],
                [-14.928751, 10.955973000000142],
                [-14.928751, 10.956527000000165],
                [-14.929307, 10.956805],
                [-14.930139, 10.956805],
                [-14.931249, 10.957917],
                [-14.932083, 10.95819500000016],
                [-14.933194999999898, 10.959305000000143],
                [-14.934305, 10.959583],
                [-14.934305, 10.960139],
                [-14.935417, 10.960695],
                [-14.93597299999982, 10.960695],
                [-14.93597299999982, 10.961251],
                [-14.936526999999899, 10.96152700000016],
                [-14.936526999999899, 10.96208300000012],
                [-14.937083, 10.96208300000012],
                [-14.937083, 10.962639000000138],
                [-14.93763899999982, 10.963473],
                [-14.938192999999899, 10.963473],
                [-14.938470999999822, 10.964027],
                [-14.93930499999982, 10.964583],
                [-14.93930499999982, 10.965139],
                [-14.940415, 10.965971000000138],
                [-14.940415, 10.966527000000156],
                [-14.94263999999987, 10.96875],
                [-14.943471999999872, 10.969028],
                [-14.944584, 10.969028],
                [-14.944861999999887, 10.968472000000133],
                [-14.944861999999887, 10.967362],
                [-14.94514, 10.967362],
                [-14.94514, 10.964583],
                [-14.94569399999989, 10.964305000000138],
                [-14.94597199999987, 10.964583],
                [-14.94597199999987, 10.967362],
                [-14.94569399999989, 10.967362],
                [-14.94569399999989, 10.969584000000111],
                [-14.94625, 10.970138000000134],
                [-14.94625, 10.970694],
                [-14.947084, 10.971528],
                [-14.948193999999887, 10.971806000000129],
                [-14.948472, 10.97236],
                [-14.950138, 10.973194],
                [-14.950694, 10.973194],
                [-14.950972, 10.97375],
                [-14.952085, 10.97375],
                [-14.952085, 10.974028],
              ],
            ],
            [
              [
                [-14.880417, 10.897360000000106],
                [-14.880417, 10.897916000000123],
                [-14.880971, 10.898472],
                [-14.881527, 10.898472],
                [-14.882083, 10.899029],
                [-14.883749, 10.899029],
                [-14.884861, 10.897916000000123],
                [-14.885417999999845, 10.897916000000123],
                [-14.885696, 10.897360000000106],
                [-14.886249, 10.897360000000106],
                [-14.886249, 10.896806],
                [-14.887083999999845, 10.896528],
                [-14.88764, 10.895972],
                [-14.887915, 10.895416],
                [-14.888472, 10.895138000000145],
                [-14.889584, 10.894306],
                [-14.890417999999897, 10.894306],
                [-14.891528, 10.89375],
                [-14.891806, 10.892916000000127],
                [-14.89264, 10.89264],
                [-14.89264, 10.892084],
                [-14.893194, 10.891806000000145],
                [-14.893194, 10.891250000000127],
                [-14.894306, 10.89069400000011],
                [-14.894583999999895, 10.889584000000127],
                [-14.895138, 10.889306],
                [-14.895138, 10.887916000000132],
                [-14.895694, 10.88736200000011],
                [-14.895694, 10.886528],
                [-14.896249999999895, 10.885971000000154],
                [-14.89736, 10.885418],
                [-14.89736, 10.884861000000114],
                [-14.896806, 10.884583],
                [-14.896806, 10.883749000000137],
                [-14.895972, 10.883193000000176],
                [-14.895972, 10.882639000000154],
                [-14.895415999999898, 10.882361],
                [-14.895415999999898, 10.881805],
                [-14.894583999999895, 10.881251],
                [-14.893749999999898, 10.881251],
                [-14.893194, 10.880971000000159],
                [-14.89264, 10.881251],
                [-14.89264, 10.880417000000136],
                [-14.89236, 10.880139],
                [-14.890972, 10.880139],
                [-14.889862, 10.879305000000159],
                [-14.889028, 10.879305000000159],
                [-14.888472, 10.879029],
                [-14.888194, 10.878473],
                [-14.88764, 10.878473],
                [-14.887362, 10.877917],
                [-14.886527, 10.877917],
                [-14.885417999999845, 10.877361],
                [-14.885139, 10.876805],
                [-14.884305, 10.875973000000158],
                [-14.883749, 10.87486100000018],
                [-14.882083, 10.873751],
                [-14.882083, 10.87319500000018],
                [-14.881527, 10.872917],
                [-14.881527, 10.872360000000185],
                [-14.880971, 10.872085],
                [-14.880971, 10.87152900000018],
                [-14.880417, 10.87125],
                [-14.880417, 10.870416],
                [-14.879861, 10.86986],
                [-14.879861, 10.86875],
                [-14.879583, 10.868194],
                [-14.879027, 10.867916],
                [-14.879027, 10.86625],
                [-14.878473, 10.86569400000019],
                [-14.878473, 10.864584],
                [-14.877639, 10.86402800000019],
                [-14.877639, 10.863194],
                [-14.877361, 10.86236200000019],
                [-14.876804999999877, 10.86236200000019],
                [-14.876804999999877, 10.860696000000189],
                [-14.876251, 10.86014],
                [-14.876251, 10.859306000000117],
                [-14.875695, 10.85902700000014],
                [-14.875695, 10.856805000000122],
                [-14.875417, 10.856527],
                [-14.874583, 10.856527],
                [-14.874307, 10.85569500000014],
                [-14.873751, 10.855417],
                [-14.872637999999881, 10.855417],
                [-14.871803999999884, 10.854861],
                [-14.87125, 10.854861],
                [-14.870971999999881, 10.855417],
                [-14.870416, 10.855417],
                [-14.870416, 10.856805000000122],
                [-14.870694, 10.856805000000122],
                [-14.870694, 10.859584],
                [-14.87125, 10.859584],
                [-14.872085, 10.860696000000189],
                [-14.872085, 10.861528],
                [-14.872637999999881, 10.861806],
                [-14.872637999999881, 10.86236200000019],
                [-14.873472999999876, 10.862918],
                [-14.873472999999876, 10.863472],
                [-14.874029, 10.86375],
                [-14.874029, 10.864306000000113],
                [-14.874583, 10.865140000000167],
                [-14.874307, 10.86569400000019],
                [-14.874307, 10.866806000000167],
                [-14.873751, 10.867084],
                [-14.874029, 10.867638000000113],
                [-14.874029, 10.868472000000168],
                [-14.873195, 10.869028000000185],
                [-14.87236, 10.869028000000185],
                [-14.872085, 10.869582],
                [-14.871528, 10.86986],
                [-14.871528, 10.871804000000168],
                [-14.871803999999884, 10.872085],
                [-14.870971999999881, 10.872638000000109],
                [-14.870971999999881, 10.87319500000018],
                [-14.870416, 10.873473],
                [-14.869305999999881, 10.874307000000158],
                [-14.868471999999883, 10.874307000000158],
                [-14.868194, 10.87486100000018],
                [-14.868194, 10.877361],
                [-14.867916, 10.878195000000176],
                [-14.868471999999883, 10.878473],
                [-14.868471999999883, 10.879305000000159],
                [-14.869028, 10.879305000000159],
                [-14.869028, 10.879861000000176],
                [-14.870137999999884, 10.879861000000176],
                [-14.870694, 10.879585],
                [-14.870694, 10.880695],
                [-14.86986, 10.880971000000159],
                [-14.86986, 10.882083000000137],
                [-14.870416, 10.883195000000114],
                [-14.87125, 10.884027],
                [-14.870971999999881, 10.885140000000149],
                [-14.870416, 10.884861000000114],
                [-14.870416, 10.885693],
                [-14.86986, 10.885418],
                [-14.869305999999881, 10.885971000000154],
                [-14.869028, 10.88680600000015],
                [-14.869028, 10.888194],
                [-14.869305999999881, 10.88875],
                [-14.870137999999884, 10.88875],
                [-14.86986, 10.889306],
                [-14.869305999999881, 10.889306],
                [-14.869305999999881, 10.89013800000015],
                [-14.870416, 10.890972],
                [-14.870416, 10.891528],
                [-14.871528, 10.892084],
                [-14.872085, 10.89264],
                [-14.872916, 10.892916000000127],
                [-14.873751, 10.892916000000127],
                [-14.874029, 10.89375],
                [-14.875138999999876, 10.89375],
                [-14.875417, 10.894584000000123],
                [-14.876251, 10.894584000000123],
                [-14.876804999999877, 10.894862],
                [-14.877083, 10.895416],
                [-14.877917, 10.895416],
                [-14.878195, 10.895972],
                [-14.878749, 10.895972],
                [-14.879861, 10.897082],
                [-14.880417, 10.897360000000106],
              ],
            ],
            [
              [
                [-14.879861, 10.94458400000019],
                [-14.880139, 10.94514],
                [-14.881527, 10.945694000000174],
                [-14.884861, 10.945694000000174],
                [-14.885417999999845, 10.94514],
                [-14.885417999999845, 10.944028000000174],
                [-14.885696, 10.944028000000174],
                [-14.885696, 10.94264],
                [-14.886249, 10.941806],
                [-14.886249, 10.940415],
                [-14.886527, 10.939305],
                [-14.887083999999845, 10.939027000000124],
                [-14.887083999999845, 10.937917],
                [-14.887362, 10.937361000000124],
                [-14.887915, 10.937083],
                [-14.887915, 10.934305],
                [-14.888194, 10.934305],
                [-14.888194, 10.932917],
                [-14.888749999999845, 10.93180500000011],
                [-14.889028, 10.93180500000011],
                [-14.889028, 10.928751],
                [-14.888472, 10.928195],
                [-14.888472, 10.927082000000155],
                [-14.887915, 10.926804],
                [-14.887915, 10.92458500000015],
                [-14.888194, 10.92458500000015],
                [-14.888194, 10.923194000000137],
                [-14.88764, 10.922916],
                [-14.88764, 10.922362],
                [-14.886527, 10.921528000000137],
                [-14.885971, 10.921528000000137],
                [-14.885696, 10.92097200000012],
                [-14.884583, 10.920696],
                [-14.884583, 10.92014],
                [-14.883195, 10.91930600000012],
                [-14.882361, 10.919028],
                [-14.882361, 10.918472],
                [-14.881805, 10.918472],
                [-14.881251, 10.917916],
                [-14.878749, 10.917916],
                [-14.878749, 10.917362],
                [-14.878195, 10.917362],
                [-14.877917, 10.916806],
                [-14.877361, 10.916806],
                [-14.876251, 10.91625],
                [-14.876251, 10.917084000000159],
                [-14.875417, 10.917638000000181],
                [-14.875417, 10.916806],
                [-14.874583, 10.91625],
                [-14.874583, 10.91514],
                [-14.873751, 10.914584],
                [-14.873751, 10.912918],
                [-14.873195, 10.912362],
                [-14.873195, 10.911805000000186],
                [-14.874029, 10.911805000000186],
                [-14.874307, 10.910693],
                [-14.874861, 10.910417],
                [-14.874861, 10.909861],
                [-14.875417, 10.909861],
                [-14.875417, 10.908749000000114],
                [-14.875973, 10.90847100000019],
                [-14.876251, 10.907639],
                [-14.876251, 10.90680500000019],
                [-14.877083, 10.906527],
                [-14.877083, 10.904861],
                [-14.877361, 10.904861],
                [-14.877361, 10.902361],
                [-14.876529, 10.90180700000019],
                [-14.876529, 10.901251000000173],
                [-14.875695, 10.900695],
                [-14.875138999999876, 10.900695],
                [-14.874861, 10.900139],
                [-14.874307, 10.89986],
                [-14.872916, 10.89986],
                [-14.872916, 10.899304],
                [-14.872085, 10.899029],
                [-14.87125, 10.899029],
                [-14.87125, 10.898472],
                [-14.870694, 10.898472],
                [-14.869582, 10.897916000000123],
                [-14.869582, 10.897360000000106],
                [-14.869028, 10.897360000000106],
                [-14.86875, 10.896806],
                [-14.867084, 10.896806],
                [-14.866528, 10.897082],
                [-14.86625, 10.898194],
                [-14.865694, 10.897638],
                [-14.864862, 10.897638],
                [-14.864305999999885, 10.897916000000123],
                [-14.864028, 10.897638],
                [-14.862362, 10.897638],
                [-14.862362, 10.897360000000106],
                [-14.860696, 10.897360000000106],
                [-14.859862, 10.897082],
                [-14.859862, 10.897916000000123],
                [-14.859304999999893, 10.898472],
                [-14.859304999999893, 10.900973],
                [-14.859584, 10.900973],
                [-14.859584, 10.902361],
                [-14.860139999999888, 10.902917000000173],
                [-14.860696, 10.904305],
                [-14.860696, 10.905973],
                [-14.86125, 10.906249000000173],
                [-14.861528, 10.90680500000019],
                [-14.861528, 10.909027],
                [-14.86097199999989, 10.909305],
                [-14.86097199999989, 10.907917000000168],
                [-14.860696, 10.907083000000114],
                [-14.859584, 10.904861],
                [-14.859862, 10.904583000000173],
                [-14.859584, 10.904029],
                [-14.859584, 10.903195],
                [-14.859027, 10.902917000000173],
                [-14.859027, 10.90180700000019],
                [-14.858470999999895, 10.900695],
                [-14.857917, 10.900973],
                [-14.857917, 10.90347300000019],
                [-14.857638999999892, 10.903751000000113],
                [-14.857083, 10.90347300000019],
                [-14.857083, 10.90180700000019],
                [-14.856804999999895, 10.901251000000173],
                [-14.857361, 10.900973],
                [-14.857361, 10.900417000000118],
                [-14.858193, 10.899585000000172],
                [-14.858470999999895, 10.899029],
                [-14.856804999999895, 10.899029],
                [-14.856804999999895, 10.898751000000118],
                [-14.854861, 10.898751000000118],
                [-14.854861, 10.898472],
                [-14.853751, 10.898472],
                [-14.853751, 10.898751000000118],
                [-14.851804999999842, 10.898751000000118],
                [-14.851529, 10.89986],
                [-14.851804999999842, 10.900139],
                [-14.851804999999842, 10.900973],
                [-14.85263899999984, 10.901251000000173],
                [-14.85263899999984, 10.902083000000118],
                [-14.851804999999842, 10.902639],
                [-14.851529, 10.903195],
                [-14.851529, 10.914584],
                [-14.851804999999842, 10.91514],
                [-14.852361, 10.915416000000164],
                [-14.852361, 10.916806],
                [-14.852917, 10.917084000000159],
                [-14.852917, 10.918194000000142],
                [-14.853195, 10.91875000000016],
                [-14.853751, 10.919028],
                [-14.853751, 10.919860000000142],
                [-14.85430499999984, 10.92097200000012],
                [-14.85430499999984, 10.921806],
                [-14.854861, 10.922362],
                [-14.854861, 10.923750000000155],
                [-14.855417, 10.924306000000115],
                [-14.855417, 10.925416000000155],
                [-14.855972999999892, 10.925694],
                [-14.856249, 10.92625100000015],
                [-14.856249, 10.927361000000133],
                [-14.856804999999895, 10.927639],
                [-14.856804999999895, 10.92847300000011],
                [-14.857361, 10.92847300000011],
                [-14.857361, 10.929307],
                [-14.858193, 10.929861],
                [-14.858193, 10.930973],
                [-14.859304999999893, 10.931529],
                [-14.859304999999893, 10.932083],
                [-14.859862, 10.932361000000128],
                [-14.859862, 10.932917],
                [-14.86097199999989, 10.934027000000128],
                [-14.86125, 10.934027000000128],
                [-14.862362, 10.935139000000106],
                [-14.862362, 10.935695000000123],
                [-14.862918, 10.935695000000123],
                [-14.863194, 10.936805000000106],
                [-14.86375, 10.937083],
                [-14.86375, 10.937639],
                [-14.864305999999885, 10.937917],
                [-14.864305999999885, 10.938471000000106],
                [-14.864862, 10.938471000000106],
                [-14.865971999999886, 10.939305],
                [-14.865971999999886, 10.939862000000119],
                [-14.866528, 10.939862000000119],
                [-14.866805999999883, 10.940415],
                [-14.867916, 10.940972000000102],
                [-14.868471999999883, 10.940972000000102],
                [-14.869028, 10.941806],
                [-14.870416, 10.941806],
                [-14.870416, 10.942084],
                [-14.871528, 10.94264],
                [-14.87236, 10.94291800000019],
                [-14.874307, 10.94291800000019],
                [-14.874861, 10.943194000000119],
                [-14.875138999999876, 10.943752],
                [-14.877083, 10.94375],
                [-14.877639, 10.944306],
                [-14.879027, 10.944306],
                [-14.879861, 10.94458400000019],
              ],
            ],
            [
              [
                [-14.798473, 10.940972000000102],
                [-14.798195, 10.94125],
                [-14.798195, 10.943194000000119],
                [-14.798748999999873, 10.943472],
                [-14.801805, 10.943472],
                [-14.801805, 10.943194000000119],
                [-14.802916999999866, 10.943194000000119],
                [-14.802916999999866, 10.94291800000019],
                [-14.804305, 10.94291800000019],
                [-14.804861, 10.942362000000173],
                [-14.806248999999866, 10.942362000000173],
                [-14.807916, 10.941806],
                [-14.809584, 10.940972000000102],
                [-14.809862, 10.940415],
                [-14.810416, 10.940415],
                [-14.81125, 10.939584],
                [-14.81236, 10.938749],
                [-14.812916, 10.938471000000106],
                [-14.812916, 10.937361000000124],
                [-14.813194, 10.937361000000124],
                [-14.813194, 10.934861],
                [-14.81236, 10.934861],
                [-14.811806, 10.934305],
                [-14.804305, 10.934305],
                [-14.804305, 10.934583],
                [-14.803193, 10.934583],
                [-14.802916999999866, 10.935139000000106],
                [-14.802361, 10.935139000000106],
                [-14.802361, 10.935695000000123],
                [-14.801805, 10.935695000000123],
                [-14.801805, 10.936527],
                [-14.801250999999866, 10.936805000000106],
                [-14.80124899999987, 10.937361000000124],
                [-14.800139, 10.937917],
                [-14.79958299999987, 10.937917],
                [-14.799305, 10.938749],
                [-14.799305, 10.939862000000119],
                [-14.799027, 10.939862000000119],
                [-14.798473, 10.940972000000102],
              ],
            ],
            [
              [
                [-15.010973, 10.945416],
                [-15.010694999999885, 10.945416],
                [-15.010694999999885, 10.946528],
                [-15.010417, 10.946528],
                [-15.010417, 10.949582],
                [-15.010973, 10.95069400000017],
                [-15.012360999999885, 10.95069400000017],
                [-15.013194999999882, 10.950416],
                [-15.013751, 10.949862],
                [-15.014860999999883, 10.949862],
                [-15.014860999999883, 10.949582],
                [-15.01569499999988, 10.94875],
                [-15.016249, 10.948472],
                [-15.016526999999883, 10.947360000000174],
                [-15.01736099999988, 10.945694000000174],
                [-15.017639, 10.945694000000174],
                [-15.017639, 10.944306],
                [-15.01736099999988, 10.943472],
                [-15.016249, 10.942362000000173],
                [-15.014026999999885, 10.942362000000173],
                [-15.013751, 10.94291800000019],
                [-15.013194999999882, 10.94291800000019],
                [-15.012917, 10.943472],
                [-15.012360999999885, 10.943472],
                [-15.012083, 10.944028000000174],
                [-15.011528999999882, 10.944028000000174],
                [-15.010973, 10.94458400000019],
                [-15.010973, 10.945416],
              ],
            ],
            [
              [
                [-14.899581999999896, 10.96041700000012],
                [-14.900138, 10.961251],
                [-14.900694999999814, 10.961251],
                [-14.901250999999888, 10.961807],
                [-14.902360999999814, 10.961807],
                [-14.902639, 10.961251],
                [-14.903750999999886, 10.960971000000143],
                [-14.904305, 10.960139],
                [-14.904860999999869, 10.960139],
                [-14.905416999999886, 10.959305000000143],
                [-14.907639, 10.957083000000125],
                [-14.907916999999884, 10.957083000000125],
                [-14.908577999999864, 10.956251],
                [-14.909027, 10.956251],
                [-14.909305, 10.955695],
                [-14.909861, 10.955695],
                [-14.910416999999882, 10.955139],
                [-14.911248999999884, 10.955139],
                [-14.911248999999884, 10.954583],
                [-14.911805, 10.954029],
                [-14.912361, 10.954029],
                [-14.913196, 10.953195000000164],
                [-14.913196, 10.952638],
                [-14.913748999999882, 10.95236000000017],
                [-14.913748999999882, 10.951250000000186],
                [-14.913196, 10.95069400000017],
                [-14.91264, 10.950416],
                [-14.911805, 10.949582],
                [-14.911805, 10.949028000000169],
                [-14.911248999999884, 10.94875],
                [-14.910139, 10.947638],
                [-14.909861, 10.947084],
                [-14.909305, 10.947084],
                [-14.909305, 10.946528],
                [-14.908748999999887, 10.946528],
                [-14.907082999999886, 10.945694000000174],
                [-14.905139, 10.944862],
                [-14.905139, 10.94458400000019],
                [-14.902916999999889, 10.94458400000019],
                [-14.902916999999889, 10.944306],
                [-14.90041699999989, 10.944306],
                [-14.90041699999989, 10.944028000000174],
                [-14.898749999999893, 10.944028000000174],
                [-14.898749999999893, 10.94375],
                [-14.896806, 10.943752],
                [-14.896806, 10.943472],
                [-14.895415999999898, 10.943472],
                [-14.895415999999898, 10.943752],
                [-14.894583999999895, 10.944306],
                [-14.894583999999895, 10.946250000000191],
                [-14.894862, 10.946250000000191],
                [-14.894862, 10.949862],
                [-14.895138, 10.949862],
                [-14.895138, 10.951250000000186],
                [-14.895415999999898, 10.951250000000186],
                [-14.895415999999898, 10.952638],
                [-14.895694, 10.952638],
                [-14.895694, 10.954029],
                [-14.895972, 10.954029],
                [-14.895972, 10.955139],
                [-14.896249999999895, 10.955139],
                [-14.897638, 10.957917],
                [-14.897638, 10.958749000000125],
                [-14.898194, 10.959583],
                [-14.898749999999893, 10.959583],
                [-14.899581999999896, 10.96041700000012],
              ],
            ],
            [
              [
                [-14.980415999999877, 10.969306],
                [-14.980695, 10.969862],
                [-14.981526, 10.970694],
                [-14.981526, 10.971250000000111],
                [-14.982638999999892, 10.971250000000111],
                [-14.98375099999987, 10.970694],
                [-14.98375099999987, 10.970138000000134],
                [-14.984304999999893, 10.969306],
                [-14.984861, 10.969028],
                [-14.985695, 10.969028],
                [-14.986251, 10.968472000000133],
                [-14.986251, 10.967915],
                [-14.986805, 10.96764],
                [-14.98708299999987, 10.967084],
                [-14.98708299999987, 10.965418],
                [-14.987361, 10.965139],
                [-14.984861, 10.965139],
                [-14.984861, 10.965418],
                [-14.98375099999987, 10.965418],
                [-14.982082999999875, 10.965971000000138],
                [-14.980972999999892, 10.966527000000156],
                [-14.980972999999892, 10.966806000000133],
                [-14.97986, 10.967362],
                [-14.979304, 10.967362],
                [-14.979028, 10.967915],
                [-14.979028, 10.96875],
                [-14.97986, 10.969306],
                [-14.980415999999877, 10.969306],
              ],
            ],
            [
              [
                [-14.742083, 11.020971],
                [-14.742917, 11.02125000000018],
                [-14.743195, 11.021806],
                [-14.744304999999827, 11.021806],
                [-14.744304999999827, 11.022084],
                [-14.745138999999824, 11.022362000000157],
                [-14.745972999999879, 11.022084],
                [-14.748194999999896, 11.022084],
                [-14.748470999999824, 11.021528000000103],
                [-14.749304999999879, 11.021528000000103],
                [-14.749583, 11.020971],
                [-14.750138999999876, 11.020971],
                [-14.751249, 11.020415000000185],
                [-14.751526999999896, 11.019862000000103],
                [-14.752084, 11.019862000000103],
                [-14.75375, 11.019027000000108],
                [-14.754027999999892, 11.018474],
                [-14.754584, 11.018471],
                [-14.754584, 11.017917],
                [-14.755137999999874, 11.017917],
                [-14.755418, 11.017361000000108],
                [-14.756528, 11.017083000000184],
                [-14.757084, 11.016805],
                [-14.757916, 11.015971],
                [-14.757916, 11.015417000000184],
                [-14.75847199999987, 11.015417000000184],
                [-14.75847199999987, 11.01458300000013],
                [-14.759028, 11.013473],
                [-14.759584, 11.013473],
                [-14.759584, 11.01291700000013],
                [-14.760416, 11.012639],
                [-14.760416, 11.012083000000189],
                [-14.76125, 11.011805],
                [-14.76125, 11.011249000000134],
                [-14.761806, 11.011249000000134],
                [-14.762916, 11.010695000000112],
                [-14.762916, 11.010417000000189],
                [-14.764028, 11.010417000000189],
                [-14.764028, 11.010695000000112],
                [-14.765138, 11.010695000000112],
                [-14.765138, 11.010973],
                [-14.766251, 11.010973],
                [-14.767083, 11.010695000000112],
                [-14.76763899999986, 11.010139],
                [-14.769027, 11.010139],
                [-14.769861, 11.009861],
                [-14.770139, 11.009307],
                [-14.772361, 11.009307],
                [-14.772361, 11.009027000000117],
                [-14.773471, 11.009027000000117],
                [-14.774583, 11.008751000000188],
                [-14.774861, 11.008195],
                [-14.775417, 11.007917000000134],
                [-14.777083, 11.007917000000134],
                [-14.777639, 11.00736],
                [-14.778471, 11.00736],
                [-14.779027, 11.007082000000139],
                [-14.779862, 11.006251000000134],
                [-14.779862, 11.005694],
                [-14.780415, 11.005694],
                [-14.780694, 11.005138],
                [-14.781528, 11.005138],
                [-14.781528, 11.004584],
                [-14.782362, 11.004028],
                [-14.782917999999881, 11.004028],
                [-14.783194, 11.003472],
                [-14.783749999999884, 11.003472],
                [-14.784306, 11.002916],
                [-14.784306, 10.999584],
                [-14.783749999999884, 10.999584],
                [-14.782917999999881, 10.997084000000143],
                [-14.782917999999881, 10.99347100000017],
                [-14.783194, 10.99347100000017],
                [-14.783194, 10.99236100000013],
                [-14.783472, 10.99180500000017],
                [-14.784862, 10.990417],
                [-14.785972, 10.989861],
                [-14.786528, 10.989027],
                [-14.787362, 10.989027],
                [-14.787638, 10.988471000000175],
                [-14.7884719999999, 10.988471000000175],
                [-14.789028, 10.988195],
                [-14.789305999999897, 10.987361],
                [-14.79041599999988, 10.987361],
                [-14.79041599999988, 10.987083],
                [-14.793195, 10.987083],
                [-14.795695, 10.986249],
                [-14.795973, 10.985695],
                [-14.795973, 10.984861],
                [-14.796529, 10.984583],
                [-14.797361, 10.983751],
                [-14.79791699999987, 10.982639],
                [-14.798473, 10.982361],
                [-14.798473, 10.977916000000107],
                [-14.798195, 10.977916000000107],
                [-14.798195, 10.976806000000124],
                [-14.79791699999987, 10.976250000000107],
                [-14.794582999999875, 10.974584000000107],
                [-14.793750999999872, 10.974306000000183],
                [-14.793195, 10.97375],
                [-14.789305999999897, 10.97375],
                [-14.789305999999897, 10.973472000000129],
                [-14.788194, 10.973472000000129],
                [-14.78708399999988, 10.972640000000183],
                [-14.786528, 10.972640000000183],
                [-14.785972, 10.972084],
                [-14.782638, 10.972084],
                [-14.782638, 10.972362],
                [-14.781807999999899, 10.972640000000183],
                [-14.780694, 10.972640000000183],
                [-14.77958399999983, 10.973472000000129],
                [-14.778749, 10.973472000000129],
                [-14.778193, 10.97375],
                [-14.77791799999983, 10.974306000000183],
                [-14.777361, 10.974306000000183],
                [-14.777083, 10.974862],
                [-14.776527, 10.974862],
                [-14.776249, 10.975416],
                [-14.771529, 10.975416],
                [-14.771527, 10.975138000000129],
                [-14.770139, 10.975138000000129],
                [-14.770139, 10.974862],
                [-14.768751, 10.974862],
                [-14.767917, 10.975138000000129],
                [-14.767917, 10.975416],
                [-14.765416, 10.975416],
                [-14.76486, 10.974862],
                [-14.763472, 10.974862],
                [-14.762916, 10.974584000000107],
                [-14.762638, 10.974028],
                [-14.761806, 10.974028],
                [-14.76125, 10.973472000000129],
                [-14.760416, 10.973194],
                [-14.759306, 10.973194],
                [-14.757637999999872, 10.972362],
                [-14.75680599999987, 10.972084],
                [-14.755693999999892, 10.971528],
                [-14.754862, 10.971250000000111],
                [-14.754305999999872, 10.970418],
                [-14.75375, 10.970418],
                [-14.752639999999872, 10.969306],
                [-14.752084, 10.969028],
                [-14.750970999999879, 10.967915],
                [-14.749860999999896, 10.967915],
                [-14.749026999999899, 10.968194],
                [-14.748470999999824, 10.96875],
                [-14.747360999999898, 10.96875],
                [-14.747083, 10.969306],
                [-14.745972999999879, 10.969862],
                [-14.745694999999898, 10.970972],
                [-14.745138999999824, 10.970972],
                [-14.744861, 10.972084],
                [-14.744304999999827, 10.972084],
                [-14.743749, 10.972916000000112],
                [-14.742917, 10.97375],
                [-14.742917, 10.974584000000107],
                [-14.742361, 10.974584000000107],
                [-14.742083, 10.975416],
                [-14.742083, 10.976806000000124],
                [-14.741806999999824, 10.97736],
                [-14.741251, 10.977638000000184],
                [-14.740972999999826, 10.97875],
                [-14.740417, 10.979029],
                [-14.739861, 10.980138000000125],
                [-14.739861, 10.980695],
                [-14.739304, 10.980973],
                [-14.739304, 10.981526],
                [-14.738751, 10.981529],
                [-14.738751, 10.982083000000102],
                [-14.737916, 10.982361],
                [-14.737916, 10.982917],
                [-14.737085, 10.983195],
                [-14.737085, 10.983751],
                [-14.73625, 10.984583],
                [-14.735694, 10.985695],
                [-14.734862, 10.986249],
                [-14.734862, 10.986805000000174],
                [-14.73375, 10.987917000000152],
                [-14.73264, 10.988195],
                [-14.73264, 10.988751],
                [-14.731527999999855, 10.989027],
                [-14.731527999999855, 10.989583000000152],
                [-14.730416, 10.989861],
                [-14.730416, 10.990417],
                [-14.729861999999855, 10.990417],
                [-14.729306, 10.991249000000153],
                [-14.728472, 10.991527],
                [-14.728472, 10.992083],
                [-14.727916, 10.992083],
                [-14.72764, 10.992640000000165],
                [-14.727084, 10.992918],
                [-14.727084, 10.99347100000017],
                [-14.726528, 10.993749],
                [-14.72625, 10.994306000000165],
                [-14.725695999999857, 10.994584],
                [-14.725695999999857, 10.99514],
                [-14.72514, 10.995416000000148],
                [-14.72514, 10.99625],
                [-14.724305, 10.996528000000126],
                [-14.724027, 10.997638000000165],
                [-14.723474, 10.997638000000165],
                [-14.722639, 10.998750000000143],
                [-14.722639, 10.99930600000016],
                [-14.721805, 10.999584],
                [-14.721805, 11.000416000000143],
                [-14.721527, 11.00097200000016],
                [-14.720971, 11.00097200000016],
                [-14.720971, 11.001528000000121],
                [-14.720139, 11.001806],
                [-14.720139, 11.002362],
                [-14.719582999999886, 11.002362],
                [-14.719582999999886, 11.002916],
                [-14.718748999999889, 11.003194000000121],
                [-14.718748999999889, 11.003750000000139],
                [-14.718195, 11.004860000000122],
                [-14.717639, 11.005138],
                [-14.717639, 11.006529],
                [-14.717916999999886, 11.006529],
                [-14.717916999999886, 11.007639],
                [-14.718195, 11.007639],
                [-14.718195, 11.008751000000188],
                [-14.718748999999889, 11.009027000000117],
                [-14.718748999999889, 11.010417000000189],
                [-14.719026999999869, 11.010973],
                [-14.719582999999886, 11.010973],
                [-14.719582999999886, 11.011805],
                [-14.720139, 11.012361000000112],
                [-14.720139, 11.013195],
                [-14.720695, 11.013195],
                [-14.721248999999887, 11.014027000000112],
                [-14.721248999999887, 11.01458300000013],
                [-14.721805, 11.014861],
                [-14.722083, 11.015971],
                [-14.722639, 11.01624900000013],
                [-14.722917, 11.017361000000108],
                [-14.724027, 11.017361000000108],
                [-14.724027, 11.017639],
                [-14.726528, 11.017639],
                [-14.726528, 11.017917],
                [-14.728194, 11.017917],
                [-14.728194, 11.018193],
                [-14.729861999999855, 11.018193],
                [-14.729861999999855, 11.018471],
                [-14.730972, 11.018474],
                [-14.731527999999855, 11.019027000000108],
                [-14.732359999999858, 11.019305],
                [-14.734028, 11.019305],
                [-14.734028, 11.019583],
                [-14.735972, 11.019583],
                [-14.735972, 11.019862000000103],
                [-14.737916, 11.019862000000103],
                [-14.737916, 11.02014],
                [-14.739582, 11.02014],
                [-14.740417, 11.020415000000185],
                [-14.740417, 11.020696000000157],
                [-14.741527, 11.020696000000157],
                [-14.742083, 11.020971],
              ],
            ],
            [
              [
                [-14.7884719999999, 11.009307],
                [-14.789028, 11.009583000000134],
                [-14.789028, 11.010139],
                [-14.7901379999999, 11.010417000000189],
                [-14.7901379999999, 11.010973],
                [-14.790971999999897, 11.011249000000134],
                [-14.79236, 11.011249000000134],
                [-14.794026, 11.010695000000112],
                [-14.794307, 11.010139],
                [-14.795695, 11.010139],
                [-14.79625099999987, 11.009861],
                [-14.796529, 11.008751000000188],
                [-14.797639, 11.008473],
                [-14.798195, 11.008195],
                [-14.799027, 11.007082000000139],
                [-14.79958299999987, 11.007082000000139],
                [-14.799861, 11.006529],
                [-14.799861, 11.005694],
                [-14.800695, 11.005416000000139],
                [-14.800695, 11.004584],
                [-14.80124899999987, 11.004584],
                [-14.801527, 11.003750000000139],
                [-14.801527, 11.00097200000016],
                [-14.801805, 11.00097200000016],
                [-14.801805, 10.999028],
                [-14.800971, 10.998472],
                [-14.800971, 10.997916],
                [-14.800416999999868, 10.997638000000165],
                [-14.800416999999868, 10.995972000000165],
                [-14.800139, 10.995972000000165],
                [-14.800139, 10.994584],
                [-14.799861, 10.994584],
                [-14.799861, 10.992918],
                [-14.799305, 10.991527],
                [-14.798473, 10.990973],
                [-14.798473, 10.990417],
                [-14.797639, 10.99013900000017],
                [-14.797361, 10.989583000000152],
                [-14.796805, 10.989583000000152],
                [-14.79625099999987, 10.989027],
                [-14.795416999999873, 10.989027],
                [-14.795139, 10.988471000000175],
                [-14.794582999999875, 10.988195],
                [-14.793195, 10.988195],
                [-14.79236, 10.987917000000152],
                [-14.791249999999877, 10.988471000000175],
                [-14.790971999999897, 10.989027],
                [-14.78986, 10.989583000000152],
                [-14.7884719999999, 10.989583000000152],
                [-14.788194, 10.99013900000017],
                [-14.78708399999988, 10.99013900000017],
                [-14.786249999999882, 10.990417],
                [-14.785694, 10.991249000000153],
                [-14.785139999999899, 10.991249000000153],
                [-14.784306, 10.99236100000013],
                [-14.784306, 10.99347100000017],
                [-14.783749999999884, 10.993749],
                [-14.783749999999884, 10.994862000000126],
                [-14.783472, 10.994862000000126],
                [-14.783472, 10.996528000000126],
                [-14.783749999999884, 10.996528000000126],
                [-14.783749999999884, 10.997638000000165],
                [-14.784306, 10.999028],
                [-14.784862, 10.999028],
                [-14.785139999999899, 10.999584],
                [-14.785139999999899, 11.00125],
                [-14.785415999999884, 11.001806],
                [-14.785972, 11.002084000000139],
                [-14.785972, 11.003194000000121],
                [-14.786249999999882, 11.003194000000121],
                [-14.786249999999882, 11.004860000000122],
                [-14.786528, 11.005416000000139],
                [-14.78708399999988, 11.005694],
                [-14.787362, 11.006251000000134],
                [-14.787362, 11.00736],
                [-14.787638, 11.007917000000134],
                [-14.788194, 11.008195],
                [-14.788194, 11.009307],
                [-14.7884719999999, 11.009307],
              ],
            ],
            [
              [
                [-14.97561, 10.978626],
                [-14.976581, 10.976480000000151],
                [-14.971559999999897, 10.9753],
                [-14.962771, 10.979781],
                [-14.958195, 10.979813],
                [-14.957917, 10.980695],
                [-14.958472999999856, 10.980695],
                [-14.958749, 10.981251],
                [-14.958749, 10.982639],
                [-14.959029, 10.983195],
                [-14.960417, 10.984583],
                [-14.960417, 10.985139000000174],
                [-14.959583, 10.985139000000174],
                [-14.959029, 10.984863],
                [-14.958472999999856, 10.984029],
                [-14.958472999999856, 10.982917],
                [-14.958195, 10.982083000000102],
                [-14.957638999999858, 10.981807000000174],
                [-14.957638999999858, 10.981251],
                [-14.957083, 10.980973],
                [-14.956251, 10.980973],
                [-14.955972999999858, 10.979585],
                [-14.955417, 10.979304000000184],
                [-14.953751, 10.979304000000184],
                [-14.953751, 10.979029],
                [-14.95236, 10.979029],
                [-14.95236, 10.97875],
                [-14.950972, 10.97875],
                [-14.948472, 10.977916000000107],
                [-14.948193999999887, 10.97736],
                [-14.947084, 10.977082],
                [-14.946806, 10.976528],
                [-14.94625, 10.976528],
                [-14.945137999999872, 10.975972000000183],
                [-14.94514, 10.975416],
                [-14.94430599999987, 10.975138000000129],
                [-14.943193999999892, 10.974306000000183],
                [-14.942918, 10.97375],
                [-14.94236199999989, 10.97375],
                [-14.941805999999872, 10.972916000000112],
                [-14.94097099999982, 10.972362],
                [-14.940415, 10.971806000000129],
                [-14.940415, 10.971250000000111],
                [-14.939583, 10.970694],
                [-14.93763899999982, 10.96875],
                [-14.937083, 10.967915],
                [-14.93597299999982, 10.967362],
                [-14.935694999999896, 10.966249],
                [-14.934860999999898, 10.965971000000138],
                [-14.934583, 10.964861000000155],
                [-14.933751, 10.964583],
                [-14.933751, 10.964027],
                [-14.932917, 10.96374900000012],
                [-14.932917, 10.963195000000155],
                [-14.931249, 10.96152700000016],
                [-14.930695, 10.96152700000016],
                [-14.930695, 10.960971000000143],
                [-14.929861, 10.960139],
                [-14.929583, 10.960139],
                [-14.929027, 10.959305000000143],
                [-14.928195, 10.959305000000143],
                [-14.927639, 10.958749000000125],
                [-14.927082, 10.958473],
                [-14.926251, 10.958473],
                [-14.925138, 10.957361],
                [-14.92402799999985, 10.957083000000125],
                [-14.923472, 10.956805],
                [-14.923193999999853, 10.956251],
                [-14.92236199999985, 10.956251],
                [-14.92125, 10.955417000000125],
                [-14.920694, 10.955139],
                [-14.920416, 10.954583],
                [-14.91986, 10.954583],
                [-14.91875, 10.954029],
                [-14.917916, 10.953195000000164],
                [-14.917638, 10.952638],
                [-14.917084, 10.952638],
                [-14.916806, 10.952085000000125],
                [-14.91625, 10.95236000000017],
                [-14.91514, 10.95236000000017],
                [-14.91514, 10.952638],
                [-14.914306, 10.953473],
                [-14.913748999999882, 10.953473],
                [-14.913471, 10.954029],
                [-14.912361, 10.954583],
                [-14.912082999999882, 10.955139],
                [-14.911527, 10.955139],
                [-14.910693, 10.956251],
                [-14.910693, 10.957639000000142],
                [-14.909305, 10.957639000000142],
                [-14.909305, 10.957917],
                [-14.907639, 10.958749000000125],
                [-14.907639, 10.959305000000143],
                [-14.907082999999886, 10.959583],
                [-14.907082999999886, 10.960139],
                [-14.906250999999884, 10.960139],
                [-14.905416999999886, 10.960971000000143],
                [-14.905139, 10.96152700000016],
                [-14.904582999999889, 10.96152700000016],
                [-14.904305, 10.96208300000012],
                [-14.902639, 10.962917],
                [-14.901526, 10.962917],
                [-14.901526, 10.963195000000155],
                [-14.900694999999814, 10.963473],
                [-14.899028999999814, 10.963473],
                [-14.898194, 10.96374900000012],
                [-14.897915999999896, 10.964305000000138],
                [-14.897915999999896, 10.972362],
                [-14.89736, 10.972916000000112],
                [-14.89736, 10.974028],
                [-14.897081999999898, 10.974028],
                [-14.897081999999898, 10.975972000000183],
                [-14.89736, 10.975972000000183],
                [-14.89736, 10.979029],
                [-14.897638, 10.979029],
                [-14.897638, 10.987917000000152],
                [-14.897915999999896, 10.987917000000152],
                [-14.897915999999896, 10.991249000000153],
                [-14.89736, 10.99180500000017],
                [-14.897081999999898, 10.992640000000165],
                [-14.897081999999898, 10.993749],
                [-14.896806, 10.993749],
                [-14.896806, 10.994862000000126],
                [-14.896528, 10.994862000000126],
                [-14.896528, 10.995972000000165],
                [-14.896249999999895, 10.995972000000165],
                [-14.896249999999895, 10.997362],
                [-14.895972, 10.997362],
                [-14.895972, 11.000416000000143],
                [-14.896806, 11.000694],
                [-14.896806, 11.001528000000121],
                [-14.897081999999898, 11.002084000000139],
                [-14.897638, 11.002638000000161],
                [-14.897638, 11.003750000000139],
                [-14.897915999999896, 11.003750000000139],
                [-14.897915999999896, 11.005138],
                [-14.898194, 11.005138],
                [-14.898194, 11.006251000000134],
                [-14.898472, 11.006251000000134],
                [-14.898472, 11.009027000000117],
                [-14.898749999999893, 11.009861],
                [-14.900138, 11.01291700000013],
                [-14.90041699999989, 11.013195],
                [-14.901250999999888, 11.013195],
                [-14.901526, 11.013751000000184],
                [-14.901526, 11.01458300000013],
                [-14.902082999999891, 11.014861],
                [-14.901807, 11.015695000000107],
                [-14.902360999999814, 11.01624900000013],
                [-14.902360999999814, 11.016805],
                [-14.902916999999889, 11.017083000000184],
                [-14.902916999999889, 11.017639],
                [-14.903473, 11.017917],
                [-14.903473, 11.019027000000108],
                [-14.904029, 11.019583],
                [-14.904029, 11.02014],
                [-14.905416999999886, 11.021528000000103],
                [-14.905416999999886, 11.022084],
                [-14.905973, 11.022362000000157],
                [-14.906805, 11.022084],
                [-14.907639, 11.022084],
                [-14.908471, 11.02125000000018],
                [-14.909027, 11.020971],
                [-14.909305, 11.020415000000185],
                [-14.909861, 11.019862000000103],
                [-14.912082999999882, 11.019027000000108],
                [-14.915418, 11.019027000000108],
                [-14.91625, 11.019305],
                [-14.916528, 11.019862000000103],
                [-14.917638, 11.02014],
                [-14.917916, 11.020696000000157],
                [-14.919028, 11.021528000000103],
                [-14.919584, 11.022084],
                [-14.92014, 11.022084],
                [-14.920972, 11.02264],
                [-14.920972, 11.023194000000103],
                [-14.921806, 11.02375],
                [-14.92236199999985, 11.024306],
                [-14.923472, 11.024306],
                [-14.92402799999985, 11.024584000000175],
                [-14.925138, 11.025694000000158],
                [-14.925972, 11.025974],
                [-14.927359999999851, 11.025972],
                [-14.927359999999851, 11.026250000000175],
                [-14.929305, 11.026250000000175],
                [-14.929583, 11.025974],
                [-14.929583, 11.025138],
                [-14.930417, 11.023472],
                [-14.930973, 11.023194000000103],
                [-14.930973, 11.02264],
                [-14.931528999999898, 11.022362000000157],
                [-14.931805, 11.021806],
                [-14.932361, 11.021528000000103],
                [-14.933751, 11.021528000000103],
                [-14.932917, 11.022362000000157],
                [-14.932639, 11.022918000000175],
                [-14.932083, 11.022918000000175],
                [-14.931805, 11.02375],
                [-14.930973, 11.024028000000158],
                [-14.930973, 11.024862],
                [-14.930417, 11.025138],
                [-14.930139, 11.025972],
                [-14.930139, 11.027084],
                [-14.929861, 11.027360000000158],
                [-14.927082, 11.027360000000158],
                [-14.927082, 11.027084],
                [-14.925416, 11.027084],
                [-14.924304, 11.026528],
                [-14.923193999999853, 11.025416],
                [-14.922082, 11.025418],
                [-14.921527999999853, 11.024584000000175],
                [-14.920972, 11.024584000000175],
                [-14.920694, 11.024028000000158],
                [-14.91986, 11.02375],
                [-14.91986, 11.022918000000175],
                [-14.919306, 11.02264],
                [-14.919028, 11.022084],
                [-14.918472, 11.022084],
                [-14.918194, 11.021528000000103],
                [-14.917638, 11.02125000000018],
                [-14.916528, 11.02125000000018],
                [-14.915972, 11.020415000000185],
                [-14.91514, 11.02014],
                [-14.912361, 11.02014],
                [-14.910693, 11.020696000000157],
                [-14.909861, 11.021806],
                [-14.908748999999887, 11.022084],
                [-14.907916999999884, 11.023194000000103],
                [-14.906805, 11.023194000000103],
                [-14.906805, 11.024306],
                [-14.907361, 11.025416],
                [-14.907916999999884, 11.025416],
                [-14.907916999999884, 11.025972],
                [-14.908748999999887, 11.026528],
                [-14.908748999999887, 11.027360000000158],
                [-14.909027, 11.027916000000175],
                [-14.909582999999884, 11.028194],
                [-14.909582999999884, 11.029028000000153],
                [-14.910139, 11.029582000000175],
                [-14.910139, 11.030416],
                [-14.910973, 11.032085000000166],
                [-14.911527, 11.032085000000166],
                [-14.911527, 11.032638],
                [-14.912082999999882, 11.03291600000017],
                [-14.912082999999882, 11.033473],
                [-14.91264, 11.034307000000126],
                [-14.913196, 11.034307000000126],
                [-14.913196, 11.034861000000149],
                [-14.913748999999882, 11.035139],
                [-14.913748999999882, 11.035695],
                [-14.914306, 11.035695],
                [-14.914584, 11.036251],
                [-14.914584, 11.037083000000166],
                [-14.91514, 11.038195000000144],
                [-14.915694, 11.038473],
                [-14.91625, 11.039583],
                [-14.91625, 11.040139],
                [-14.916806, 11.040417000000105],
                [-14.917084, 11.041527000000144],
                [-14.917638, 11.041805],
                [-14.918194, 11.042917],
                [-14.918194, 11.043749000000105],
                [-14.91875, 11.043749000000105],
                [-14.91875, 11.044305000000122],
                [-14.919584, 11.045693],
                [-14.920694, 11.045971000000122],
                [-14.921806, 11.048196000000189],
                [-14.922916, 11.049306],
                [-14.922916, 11.04986200000019],
                [-14.92375, 11.050138000000118],
                [-14.92375, 11.050694000000135],
                [-14.924306, 11.050694000000135],
                [-14.924306, 11.05125],
                [-14.925138, 11.05152800000019],
                [-14.925138, 11.052084],
                [-14.925972, 11.052362],
                [-14.925972, 11.052916],
                [-14.927082, 11.052916],
                [-14.928195, 11.053472000000113],
                [-14.928195, 11.054028],
                [-14.929027, 11.054028],
                [-14.929583, 11.054584],
                [-14.930139, 11.054584],
                [-14.930417, 11.055138000000113],
                [-14.930973, 11.055138000000113],
                [-14.931249, 11.055694],
                [-14.931805, 11.055694],
                [-14.932083, 11.05625],
                [-14.932768999999837, 11.05625],
                [-14.934041, 11.050131],
                [-14.9316, 11.046459],
                [-14.9358, 11.039651000000163],
                [-14.9528, 11.00561],
                [-14.967209, 10.98610100000019],
                [-14.9755, 10.978871],
                [-14.97561, 10.978626],
              ],
            ],
            [
              [
                [-14.703194, 11.014027000000112],
                [-14.703194, 11.014861],
                [-14.702917999999897, 11.015695000000107],
                [-14.703472, 11.01624900000013],
                [-14.703472, 11.017083000000184],
                [-14.704306, 11.017361000000108],
                [-14.70514, 11.017361000000108],
                [-14.705416, 11.016805],
                [-14.709306, 11.016805],
                [-14.709859999999821, 11.016527],
                [-14.710138, 11.015971],
                [-14.710138, 11.014861],
                [-14.710693999999819, 11.01458300000013],
                [-14.710693999999819, 11.014027000000112],
                [-14.71152899999987, 11.013751000000184],
                [-14.712081999999896, 11.012639],
                [-14.712084999999888, 11.011805],
                [-14.712639, 11.011805],
                [-14.712639, 11.009027000000117],
                [-14.712084999999888, 11.008751000000188],
                [-14.712081999999896, 11.007639],
                [-14.71152899999987, 11.007082000000139],
                [-14.71125099999989, 11.005694],
                [-14.707361999999819, 11.005694],
                [-14.707361999999819, 11.005416000000139],
                [-14.704583999999898, 11.005416000000139],
                [-14.704583999999898, 11.005694],
                [-14.702917999999897, 11.005694],
                [-14.702917999999897, 11.005972],
                [-14.70125, 11.005972],
                [-14.70014, 11.006529],
                [-14.70014, 11.007917000000134],
                [-14.700416, 11.007917000000134],
                [-14.700416, 11.009861],
                [-14.700694, 11.009583000000134],
                [-14.701806, 11.010417000000189],
                [-14.702084, 11.011529],
                [-14.702638, 11.011805],
                [-14.702917999999897, 11.012361000000112],
                [-14.702917999999897, 11.013195],
                [-14.702638, 11.014027000000112],
                [-14.703194, 11.014027000000112],
              ],
            ],
            [
              [
                [-14.712081999999896, 11.015971],
                [-14.712081999999896, 11.017361000000108],
                [-14.712639, 11.017361000000108],
                [-14.714026999999874, 11.017917],
                [-14.715973, 11.017917],
                [-14.716805, 11.017639],
                [-14.717639, 11.016527],
                [-14.717639, 11.015417000000184],
                [-14.716529, 11.014861],
                [-14.716529, 11.014305],
                [-14.715973, 11.014027000000112],
                [-14.715973, 11.013473],
                [-14.715416999999889, 11.01291700000013],
                [-14.714582999999891, 11.013473],
                [-14.714026999999874, 11.013473],
                [-14.713750999999888, 11.014027000000112],
                [-14.71291699999989, 11.014027000000112],
                [-14.71291699999989, 11.015139],
                [-14.71235999999982, 11.015417000000184],
                [-14.712081999999896, 11.015971],
              ],
            ],
            [
              [
                [-14.71291699999989, 11.020971],
                [-14.71235999999982, 11.021528000000103],
                [-14.71235999999982, 11.022084],
                [-14.71291699999989, 11.02264],
                [-14.713473, 11.02264],
                [-14.714307, 11.022084],
                [-14.714307, 11.021528000000103],
                [-14.714860999999871, 11.02125000000018],
                [-14.714582999999891, 11.02014],
                [-14.714026999999874, 11.02014],
                [-14.713473, 11.020971],
                [-14.71291699999989, 11.020971],
              ],
            ],
            [
              [
                [-14.919306000999939, 11.066529],
                [-14.91986, 11.066805000000159],
                [-14.922230000999946, 11.062331],
                [-14.924908, 11.057362],
                [-14.924305998999955, 11.056806000999984],
                [-14.92375, 11.056806000999984],
                [-14.923194000999956, 11.055972],
                [-14.922638, 11.055972],
                [-14.922638, 11.055416001000083],
                [-14.92125, 11.055416001000083],
                [-14.920415998999943, 11.056528001000117],
                [-14.920415998999943, 11.057082],
                [-14.91986, 11.057362],
                [-14.91986, 11.057916001000024],
                [-14.919027998999923, 11.058194000000185],
                [-14.919027998999923, 11.05875],
                [-14.918472, 11.059027999000023],
                [-14.918472, 11.059585],
                [-14.917916000999867, 11.059860000000185],
                [-14.917637998999965, 11.060973000000104],
                [-14.917084, 11.061251],
                [-14.917084, 11.06236100000018],
                [-14.916527998999868, 11.06236100000018],
                [-14.916527998999868, 11.064583001000074],
                [-14.917084, 11.064861],
                [-14.917084, 11.06541699900015],
                [-14.919306000999939, 11.066529],
              ],
            ],
            [
              [
                [-14.914306, 11.071527],
                [-14.914028, 11.072083],
                [-14.914028, 11.07319600000011],
                [-14.913748999999882, 11.07319600000011],
                [-14.913748999999882, 11.07652800000011],
                [-14.914028, 11.07652800000011],
                [-14.914028, 11.07753],
                [-14.919306, 11.067748],
                [-14.919028, 11.067361000000176],
                [-14.918472, 11.067361000000176],
                [-14.916806, 11.066529],
                [-14.916528, 11.065973],
                [-14.915694, 11.065973],
                [-14.915418, 11.066527],
                [-14.915418, 11.068751],
                [-14.914862, 11.069027000000176],
                [-14.914862, 11.070693000000176],
                [-14.914584, 11.070695000000171],
                [-14.914306, 11.071527],
              ],
            ],
            [
              [
                [-14.857638999999892, 11.097639000000186],
                [-14.857638999999892, 11.099305000000186],
                [-14.858193, 11.09958300000011],
                [-14.858193, 11.10014000000018],
                [-14.858749, 11.10014000000018],
                [-14.859027, 11.100696],
                [-14.859584, 11.100696],
                [-14.859584, 11.099861],
                [-14.858470999999895, 11.099305000000186],
                [-14.858470999999895, 11.097917000000109],
                [-14.857638999999892, 11.097639000000186],
              ],
            ],
          ],
        },
        properties: { GID_0: "GIN", COUNTRY: "Guinea" },
      },
    ],
  },
  90: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [30.045150756000055, -4.256838798999979],
              [30.04371643100012, -4.25674390699993],
              [30.044609070000035, -4.256418226999813],
              [30.04502868600008, -4.256103991999908],
              [30.04559707700014, -4.256392000999938],
              [30.046827317000123, -4.255846977999966],
              [30.047071456000026, -4.256649017999905],
              [30.047193527000047, -4.257503985999961],
              [30.048749924000163, -4.258399962999931],
              [30.049953460999973, -4.258752821999963],
              [30.0504817960001, -4.260029791999955],
              [30.051279068000156, -4.260015962999944],
              [30.05173874000019, -4.259582042999909],
              [30.05229377800009, -4.260043143999894],
              [30.05309104800017, -4.259634970999969],
              [30.053836823000154, -4.258008003999976],
              [30.05403900200008, -4.257233142999894],
              [30.052482605000023, -4.255427837999946],
              [30.05378151000008, -4.25526380499997],
              [30.054349900000034, -4.254721164999978],
              [30.053945541000076, -4.253200053999819],
              [30.052970887000072, -4.25089216299989],
              [30.052469254000073, -4.250239849999957],
              [30.051792145000093, -4.249763964999886],
              [30.05111694300018, -4.24964284899994],
              [30.051063538000108, -4.248881816999926],
              [30.04988479600007, -4.248040198999945],
              [30.05114555400013, -4.247076033999974],
              [30.051265717000035, -4.245717047999904],
              [30.051929474000133, -4.24530982899995],
              [30.051670074000185, -4.244291781999948],
              [30.050575255000126, -4.243488788999912],
              [30.049749374000044, -4.243354796999938],
              [30.048208236999983, -4.24397897699987],
              [30.047517777000053, -4.244101046999958],
              [30.047058105000076, -4.243693827999948],
              [30.047666549000155, -4.243381975999967],
              [30.047693253000034, -4.242580890999932],
              [30.04762649500003, -4.241683958999886],
              [30.047950745000037, -4.242092132999915],
              [30.04822158800016, -4.242675780999889],
              [30.04899215600011, -4.242850780999959],
              [30.050155640000128, -4.242322921999914],
              [30.050508499000102, -4.242919921999942],
              [30.05109024000018, -4.243096827999977],
              [30.05181884800004, -4.24294710099997],
              [30.052375794000113, -4.242457865999882],
              [30.052646637000066, -4.241779803999918],
              [30.053146363000053, -4.242362975999924],
              [30.05405235300003, -4.242486953999901],
              [30.054742813000132, -4.2419009219999],
              [30.055215835000183, -4.239743232999899],
              [30.055189134000045, -4.238845825999817],
              [30.054931642000042, -4.238304137999933],
              [30.054134369000053, -4.238153933999854],
              [30.054119111000034, -4.237473963999889],
              [30.05464744600016, -4.23695993299998],
              [30.054809571000135, -4.235506056999952],
              [30.055662156000096, -4.235452174999864],
              [30.05612182499999, -4.234909056999868],
              [30.05624580400007, -4.234298227999943],
              [30.056299209000144, -4.233468055999936],
              [30.05573081900019, -4.232858180999926],
              [30.05516243000011, -4.232777118999934],
              [30.056272507000074, -4.231771944999935],
              [30.05676078800002, -4.231255054999963],
              [30.056772233000174, -4.230454920999932],
              [30.055473327000186, -4.229381084999886],
              [30.054756164000082, -4.229001044999961],
              [30.055082321000157, -4.228404998999963],
              [30.05516243000011, -4.227766989999907],
              [30.05536651700004, -4.226993082999911],
              [30.05516243000011, -4.226368903999969],
              [30.05466079700011, -4.225635051999916],
              [30.05398559500003, -4.225158214999965],
              [30.053445817000124, -4.22486114599991],
              [30.053565978, -4.224084853999898],
              [30.05341720600012, -4.223528860999977],
              [30.052848817000097, -4.223068236999893],
              [30.054216386000178, -4.222427843999924],
              [30.055082321000157, -4.221833228999969],
              [30.055812836000143, -4.221981047999918],
              [30.05655670100009, -4.222266196999954],
              [30.057178497000052, -4.221831798999915],
              [30.0572052010001, -4.220162867999932],
              [30.056880951000096, -4.219429015999879],
              [30.0573940270001, -4.218818187999887],
              [30.0572052010001, -4.218057154999883],
              [30.05670547500017, -4.21751403899998],
              [30.057476044000055, -4.217256068999973],
              [30.05799102800006, -4.216807840999877],
              [30.058057786000063, -4.21513700499986],
              [30.058126449000156, -4.214377879999915],
              [30.058895110000037, -4.213373184999909],
              [30.05974960300017, -4.213427066999941],
              [30.060115815000074, -4.212937831999966],
              [30.060087204000126, -4.212028026999917],
              [30.060373307000077, -4.211470125999938],
              [30.061170579000134, -4.210574148999967],
              [30.061481475999983, -4.20974683799983],
              [30.061481475999983, -4.20815801599997],
              [30.061834335000185, -4.207396984999889],
              [30.06219864000019, -4.206991195999819],
              [30.062469483000143, -4.206027030999962],
              [30.06237411500007, -4.205578804999846],
              [30.06286239600007, -4.205090046999885],
              [30.06278038, -4.204288006999889],
              [30.062089920000062, -4.203786850999904],
              [30.062944412000093, -4.203678130999947],
              [30.06342888, -4.203228949999925],
              [30.06328010600015, -4.201924800999848],
              [30.062969208000027, -4.201111792999882],
              [30.063835144000052, -4.201179025999977],
              [30.064811707000104, -4.200267790999874],
              [30.065431594000188, -4.199860096999885],
              [30.065500260000192, -4.199016094999877],
              [30.066732407000075, -4.198936938999964],
              [30.067691802000127, -4.197525977999931],
              [30.067840577000084, -4.196941852999942],
              [30.06943702700005, -4.197199821999959],
              [30.069950103, -4.197173117999967],
              [30.070465087000173, -4.196724891999907],
              [30.070777892000024, -4.196045875999971],
              [30.070735932000105, -4.19529914799989],
              [30.070167541000046, -4.194783210999958],
              [30.069585801000073, -4.194592952999926],
              [30.06973457300012, -4.194022177999955],
              [30.07049179100005, -4.194252966999954],
              [30.07108688400018, -4.193953989999898],
              [30.07126426700006, -4.193262099999913],
              [30.070749283000055, -4.191781997999954],
              [30.07030296199997, -4.191224096999974],
              [30.071296691000043, -4.190135000999931],
              [30.07143592800014, -4.188836097999911],
              [30.071556091000048, -4.188239096999894],
              [30.072666168000126, -4.187327861999904],
              [30.07299041800013, -4.18650007299982],
              [30.072977065000032, -4.185780046999923],
              [30.072868347000053, -4.185277938999946],
              [30.072044373000097, -4.183852195999918],
              [30.07165336600019, -4.183105943999919],
              [30.071680068999967, -4.182467936999956],
              [30.072708130000024, -4.18102693499992],
              [30.07338333100006, -4.180280208999875],
              [30.074331283000163, -4.179995060999829],
              [30.075061798000092, -4.18017005899992],
              [30.075601577999976, -4.17987203499996],
              [30.07614326600003, -4.178827761999969],
              [30.075967788000128, -4.178407191999895],
              [30.07516861000005, -4.177904127999909],
              [30.074844360000043, -4.177577972999927],
              [30.073993683000083, -4.177427767999973],
              [30.07387161200006, -4.17679119099995],
              [30.07466888400012, -4.17706203399996],
              [30.075317382000094, -4.176763056999846],
              [30.075265885000192, -4.175895213999979],
              [30.074886322000168, -4.175392149999937],
              [30.075616836, -4.174835203999919],
              [30.075618744000167, -4.174334047999935],
              [30.075035096000192, -4.173070906999953],
              [30.075157165000064, -4.172472953999886],
              [30.075590135000027, -4.173042774999942],
              [30.076051712000094, -4.173476217999848],
              [30.07683372600019, -4.173517226999934],
              [30.07751083500017, -4.173057078999875],
              [30.077564240000015, -4.172201155999971],
              [30.077159881000057, -4.171644209999954],
              [30.077863694000143, -4.171359060999976],
              [30.07806587300007, -4.170435904999977],
              [30.078357697000058, -4.168498039999975],
              [30.079397201000063, -4.167601107999928],
              [30.080333710000048, -4.166267872999924],
              [30.08142089800009, -4.165908812999874],
              [30.082483291000187, -4.165370940999878],
              [30.08215522800009, -4.164678095999932],
              [30.08286285300011, -4.164651869999887],
              [30.08362388600011, -4.164908885999921],
              [30.08407783500013, -4.1641659739999],
              [30.084102631, -4.162447928999882],
              [30.085546494000027, -4.160552024999959],
              [30.08582305900012, -4.160501956999894],
              [30.086660386000176, -4.16129493699998],
              [30.087116242000093, -4.161065100999963],
              [30.087799072000053, -4.160962103999964],
              [30.088609695000116, -4.160987852999938],
              [30.089748383000142, -4.16039800499982],
              [30.09048271299997, -4.16067981599997],
              [30.090988159000062, -4.160654066999939],
              [30.091747284000064, -4.160089015999915],
              [30.09210205100004, -4.158758162999959],
              [30.092380524000077, -4.159320831999935],
              [30.092885971000044, -4.15960311799995],
              [30.094657898000037, -4.159525871999961],
              [30.095088959000066, -4.1590647699999],
              [30.094936371000074, -4.157704828999897],
              [30.09627723700015, -4.157450198999811],
              [30.09686088600006, -4.156757831999812],
              [30.09825134199997, -4.156116961999885],
              [30.098707198000056, -4.155579088999957],
              [30.09916305500019, -4.155244825999944],
              [30.100023270000065, -4.155220031999932],
              [30.10045433, -4.154091834999917],
              [30.100959778000117, -4.153707026999882],
              [30.101541519000193, -4.153091907999965],
              [30.10227584800009, -4.15255403499998],
              [30.103136063000136, -4.152272223999944],
              [30.10371971100011, -4.151964186999919],
              [30.104604722000033, -4.151784896999914],
              [30.105363845000056, -4.152040957999873],
              [30.10592079100013, -4.151783943999931],
              [30.10662841700008, -4.151964186999919],
              [30.10662841700008, -4.152348994999954],
              [30.107339860000025, -4.153374195999959],
              [30.107793809000043, -4.15342521599996],
              [30.108654022000053, -4.153170108999973],
              [30.10898208700013, -4.152681825999878],
              [30.10931205800017, -4.151784896999914],
              [30.109970093000186, -4.151425836999977],
              [30.11055183300016, -4.151298045999965],
              [30.111310959000093, -4.151298045999965],
              [30.11217117400014, -4.15088796699996],
              [30.112804414000095, -4.150374887999874],
              [30.11237335100003, -4.149837969999965],
              [30.113235474000078, -4.149580000999947],
              [30.113513947000115, -4.148940085999925],
              [30.112981797000145, -4.148246765999943],
              [30.11320876999997, -4.14755487399998],
              [30.113058091000028, -4.14645290399983],
              [30.113056183000083, -4.145555019999961],
              [30.11353874300005, -4.146042822999903],
              [30.114423752000164, -4.146170138999878],
              [30.1147289270001, -4.145683765999877],
              [30.115283965000174, -4.144093990999977],
              [30.11518478300013, -4.1434020989999],
              [30.11581421000011, -4.142914770999937],
              [30.116121292000116, -4.142351149999911],
              [30.115537644000142, -4.141914843999871],
              [30.116247177000105, -4.141711234999889],
              [30.116575241000078, -4.141097067999965],
              [30.116121292000116, -4.140326976999916],
              [30.11667633000019, -4.140018939999948],
              [30.116905213000052, -4.139326094999888],
              [30.117284774000098, -4.140069007999898],
              [30.117866516000106, -4.140173911999966],
              [30.117765426000062, -4.140634058999922],
              [30.11819648700009, -4.1416082369999],
              [30.121105195000098, -4.141504764999979],
              [30.121713639000177, -4.141044140999952],
              [30.12239646800009, -4.141403197999978],
              [30.12280273500005, -4.141147135999859],
              [30.123687744000165, -4.140942095999947],
              [30.124143600000025, -4.140505790999896],
              [30.124118804000148, -4.139686106999932],
              [30.12396621700003, -4.139019012999881],
              [30.123535156000173, -4.138659953999934],
              [30.121942520000175, -4.137942790999944],
              [30.12196731600011, -4.137275218999946],
              [30.123283387000185, -4.137814998999943],
              [30.123889923000092, -4.137379168999928],
              [30.12396430900003, -4.136661052999955],
              [30.12358665400012, -4.135917185999915],
              [30.124395370000116, -4.13622522299994],
              [30.124826432000077, -4.135685919999901],
              [30.126192093000157, -4.136198995999962],
              [30.12748336900006, -4.135251043999972],
              [30.127763747000188, -4.134046077999926],
              [30.12839508200011, -4.133995055999947],
              [30.128623962, -4.133327006999934],
              [30.12943267800017, -4.133584020999876],
              [30.130573274000028, -4.132637023999962],
              [30.130849839000064, -4.133481978999953],
              [30.13130569500015, -4.133995055999947],
              [30.131887436000056, -4.133687018999979],
              [30.13233184900014, -4.134072780999929],
              [30.132635116000074, -4.134766101999901],
              [30.133344651000073, -4.134687899999904],
              [30.13382530100006, -4.134201049999945],
              [30.13395118699998, -4.133715151999979],
              [30.133522034000123, -4.132740020999961],
              [30.134887696000135, -4.132201193999947],
              [30.13486290000003, -4.131586074999973],
              [30.13536834600012, -4.131252764999829],
              [30.136154175, -4.131431102999841],
              [30.13683700500019, -4.130970953999963],
              [30.136888505, -4.130252837999933],
              [30.13784980700018, -4.130176066999979],
              [30.138355256000125, -4.129893780999907],
              [30.13863372899999, -4.129201887999955],
              [30.138305664000143, -4.128612995999902],
              [30.139190673000087, -4.128588199999967],
              [30.139545442000042, -4.128151893999927],
              [30.139368058000116, -4.127254961999881],
              [30.139976502000025, -4.127484797999898],
              [30.140607834000036, -4.127229212999964],
              [30.14088821500019, -4.126460074999955],
              [30.141342163000104, -4.126690862999851],
              [30.142126083000107, -4.126432895999926],
              [30.142177582000045, -4.125510214999963],
              [30.142814637000072, -4.125699996999913],
              [30.143367768000132, -4.125280856999893],
              [30.143621445000065, -4.124639033999813],
              [30.144227981000142, -4.124331949999885],
              [30.144987107000077, -4.124306201999957],
              [30.14592361500013, -4.123999118999961],
              [30.146429062000095, -4.122973918999946],
              [30.147060394000107, -4.122488020999981],
              [30.147871017000114, -4.122488020999981],
              [30.148580550000133, -4.12228012099996],
              [30.148630142000115, -4.121614932999876],
              [30.149364472000116, -4.121537208999939],
              [30.14959144500017, -4.120742796999934],
              [30.149339676000068, -4.120026110999959],
              [30.149442673000124, -4.119231223999918],
              [30.150201798000126, -4.119461058999946],
              [30.151010514000063, -4.119231223999918],
              [30.15232658300016, -4.119204996999883],
              [30.15278053200018, -4.118742942999916],
              [30.15306091299999, -4.118153094999968],
              [30.15298271200004, -4.117360115999929],
              [30.153766633000146, -4.118000030999838],
              [30.155059814000083, -4.117179869999916],
              [30.15533828700012, -4.116565225999977],
              [30.155794143000037, -4.116078852999863],
              [30.157361984000147, -4.11589908499991],
              [30.15812301700015, -4.11587381399994],
              [30.158803939, -4.115386008999906],
              [30.15865326000005, -4.114693163999959],
              [30.15915870600014, -4.114360807999901],
              [30.16040039100011, -4.113206863999892],
              [30.16077804600019, -4.112565993999908],
              [30.160501481000153, -4.111796854999966],
              [30.16072845399998, -4.111488817999941],
              [30.16164016700003, -4.111052989999962],
              [30.162322999000025, -4.110232829999859],
              [30.16247558600014, -4.109362125999951],
              [30.16229820300009, -4.108644007999942],
              [30.16156387300009, -4.10815715699988],
              [30.16275215200011, -4.108078955999815],
              [30.16285324100005, -4.107336043999965],
              [30.163410187000125, -4.107798099999911],
              [30.164068221000036, -4.107541082999887],
              [30.164094925000086, -4.106747150999922],
              [30.16482734700014, -4.106412887999966],
              [30.165130616000056, -4.104825018999975],
              [30.164930344000027, -4.103927133999946],
              [30.165765763000138, -4.104158877999907],
              [30.166067124000108, -4.103927133999946],
              [30.16639709500015, -4.103209972999935],
              [30.167182922000052, -4.103055952999966],
              [30.16763877800014, -4.102416037999944],
              [30.16768837000012, -4.101723192999828],
              [30.167409897000084, -4.101389884999946],
              [30.166599274000077, -4.100903034999931],
              [30.166954041000054, -4.100286960999938],
              [30.16794204700011, -4.101234912999928],
              [30.168725967000114, -4.101159096999936],
              [30.16900634700005, -4.100543974999937],
              [30.168954850000148, -4.099773883999944],
              [30.169384003, -4.099236011999892],
              [30.17004203800019, -4.099825858999964],
              [30.170751572000086, -4.099568844999908],
              [30.170827865000092, -4.098775862999958],
              [30.17135810800005, -4.09833908],
              [30.171510697000144, -4.097853183999973],
              [30.17135810800005, -4.097005844999956],
              [30.170978547000175, -4.096416949999878],
              [30.171688080000138, -4.095903872999884],
              [30.171661377000135, -4.09539079599989],
              [30.17148590100004, -4.094698905999962],
              [30.17189026000017, -4.094392776999939],
              [30.172346116000085, -4.094287871999939],
              [30.17335891600004, -4.094878196999957],
              [30.173915862000115, -4.094596861999946],
              [30.174192428000083, -4.093699931999879],
              [30.174774170000035, -4.093570230999887],
              [30.17550849900016, -4.093852996999942],
              [30.176092147000134, -4.09346914199989],
              [30.17664718600014, -4.092289923999942],
              [30.17720603900011, -4.091931818999967],
              [30.177457809000146, -4.091622829999892],
              [30.177736282000183, -4.090906143999916],
              [30.177862167, -4.09000778199993],
              [30.178596497000058, -4.089753149999922],
              [30.179584503000058, -4.0890598299999],
              [30.18020248400012, -4.088540077999824],
              [30.180177688000015, -4.087659835999887],
              [30.180013656000142, -4.086779117999868],
              [30.179567337000094, -4.086207865999938],
              [30.178863524000178, -4.08627891499998],
              [30.177570343000014, -4.087372780999942],
              [30.17764091500004, -4.086612224999897],
              [30.178134918000126, -4.086064814999929],
              [30.17815780600006, -4.085350990999928],
              [30.176654816000053, -4.08501720299995],
              [30.177240371000096, -4.084302901999877],
              [30.178676605000078, -4.084494112999892],
              [30.179332734000127, -4.083992956999907],
              [30.179615022000178, -4.083185194999942],
              [30.179098130000057, -4.08182811599994],
              [30.17992019600007, -4.081923007999876],
              [30.180555344000027, -4.082065104999913],
              [30.181165695000175, -4.081661222999969],
              [30.180696488000137, -4.080970762999868],
              [30.18109512400008, -4.080350875999841],
              [30.182458878000148, -4.080255031999968],
              [30.18231773399998, -4.079399108999951],
              [30.181871415000103, -4.078757761999896],
              [30.182388307000053, -4.078375816999937],
              [30.18299865800003, -4.079090117999954],
              [30.183446884000148, -4.078732965999905],
              [30.18375015300012, -4.078026294999972],
              [30.18370437600015, -4.077400207999858],
              [30.18306922900007, -4.076542852999978],
              [30.183681488000047, -4.076353071999904],
              [30.18450355600004, -4.076210021999884],
              [30.18483161900008, -4.075471877999917],
              [30.184879303000116, -4.07470989199993],
              [30.183492661000116, -4.074068069999953],
              [30.18356323200004, -4.073280810999904],
              [30.183351517000176, -4.072877883999979],
              [30.182952881000062, -4.071855067999877],
              [30.183422088000043, -4.071448801999907],
              [30.184339523000062, -4.070950030999939],
              [30.18426895200014, -4.070376871999883],
              [30.183727265000186, -4.069783210999958],
              [30.184291839000025, -4.06959390499992],
              [30.18461418100003, -4.069131850999895],
              [30.18586731000005, -4.068378925999923],
              [30.186220169000023, -4.067546843999935],
              [30.18621826100008, -4.066903113999956],
              [30.18568038900014, -4.066213131999973],
              [30.186853409000037, -4.066308020999941],
              [30.18701744100008, -4.065474986999902],
              [30.187652588000162, -4.064879893999887],
              [30.187417985000025, -4.06435585],
              [30.186595917000034, -4.063737867999976],
              [30.188358307000044, -4.063594818999945],
              [30.188123703000144, -4.062689780999961],
              [30.18753433300003, -4.062308788999872],
              [30.187675477000028, -4.0617380139999],
              [30.18825721700017, -4.061435222999876],
              [30.188827514000025, -4.060856817999934],
              [30.188945769000156, -4.059191226999928],
              [30.18861770600006, -4.058500765999838],
              [30.18767738200006, -4.057145118999927],
              [30.18828773600012, -4.056906222999885],
              [30.18906211899997, -4.056930063999971],
              [30.189697267000156, -4.05642890799993],
              [30.189626694000083, -4.055690765999941],
              [30.190309524999975, -4.055930136999962],
              [30.190826417000153, -4.055313110999975],
              [30.19094276500016, -4.05455017],
              [30.191719053999975, -4.0540738099999],
              [30.191694260000077, -4.053264139999897],
              [30.191366195, -4.052597998999943],
              [30.190591811000104, -4.052646160999927],
              [30.190778732000183, -4.051884174999941],
              [30.19197654600015, -4.051098822999961],
              [30.19326972900012, -4.050073145999932],
              [30.19383239800004, -4.049478054999895],
              [30.19425582800011, -4.048765181999954],
              [30.195455552000055, -4.047811984999896],
              [30.195901870000057, -4.047099113999877],
              [30.196065903000033, -4.046242235999955],
              [30.196746827000084, -4.046432971999934],
              [30.198251725000034, -4.047217845999853],
              [30.200155259000155, -4.046003816999928],
              [30.200672149000127, -4.04662322899992],
              [30.201353073000178, -4.047933101999945],
              [30.20201110900007, -4.047932147999973],
              [30.202857971000128, -4.047836780999887],
              [30.20330429200004, -4.048479080999925],
              [30.204034806000095, -4.048431872999913],
              [30.204643250000174, -4.048027038999919],
              [30.20480919000005, -4.047408102999953],
              [30.204549790000044, -4.046861171999979],
              [30.203844070000116, -4.046171187999846],
              [30.202976227000192, -4.046123027999954],
              [30.203258515000073, -4.04540920199986],
              [30.20527839700003, -4.046003816999928],
              [30.205583571999966, -4.045361994999951],
              [30.20509147600012, -4.044671057999892],
              [30.206907273000184, -4.044036864999896],
              [30.206943512000066, -4.043653011999936],
              [30.20715141200003, -4.042823790999819],
              [30.20847129900011, -4.041829108999934],
              [30.208574294000186, -4.040958880999881],
              [30.210926056000062, -4.039073944999871],
              [30.212493897, -4.039342879999936],
              [30.212741852000192, -4.037975787999869],
              [30.213216781000142, -4.037148],
              [30.214103698000088, -4.036485193999909],
              [30.215074539000057, -4.03639984199998],
              [30.215589523000176, -4.035923956999909],
              [30.2152385710001, -4.035200118999967],
              [30.215507507000154, -4.034515856999974],
              [30.216539382000178, -4.034328937999931],
              [30.216703415000154, -4.033936023999956],
              [30.216188430999978, -4.033251761999964],
              [30.215652466000165, -4.032755850999877],
              [30.217033386000026, -4.032050132999927],
              [30.21695137100005, -4.031429766999963],
              [30.217363358000114, -4.030435084999908],
              [30.217447280000158, -4.02842521599996],
              [30.21719932600007, -4.027741908999929],
              [30.218683242999987, -4.028134821999913],
              [30.219015121000098, -4.02757596899994],
              [30.219076156000085, -4.026560783999969],
              [30.220521926000174, -4.025897979999911],
              [30.221883773000172, -4.026642799999934],
              [30.222955703000025, -4.026042936999943],
              [30.223863602999984, -4.024673937999978],
              [30.224214554000184, -4.023183822999897],
              [30.22522544900005, -4.022086143999957],
              [30.22541236800015, -4.021049975999915],
              [30.225143433000028, -4.020510196999908],
              [30.224401473000114, -4.020240782999906],
              [30.224256516000082, -4.018127917999891],
              [30.225700379000102, -4.018355846999953],
              [30.226360320000083, -4.018002986999932],
              [30.22708320600003, -4.018189906999965],
              [30.22790718000016, -4.01783799999987],
              [30.22774314899999, -4.016450881999958],
              [30.229331969000043, -4.01514482399989],
              [30.228342054999985, -4.013238906999959],
              [30.229539871000156, -4.012948035999955],
              [30.23015785300015, -4.011457920999931],
              [30.230178834000185, -4.010815142999945],
              [30.227785111000117, -4.010213851999936],
              [30.22508049000004, -4.009675024999922],
              [30.22303962700005, -4.009489058999918],
              [30.22260284400005, -4.009094237999932],
              [30.222562790000097, -4.00801801699987],
              [30.22285270800012, -4.007229804999952],
              [30.222150803000034, -4.006711958999915],
              [30.22159385700013, -4.007022857999971],
              [30.220769883000173, -4.005655763999926],
              [30.219324112000038, -4.005822181999918],
              [30.220439910000152, -4.004889010999875],
              [30.219942094000146, -4.004371165999942],
              [30.21911621100014, -4.004537103999894],
              [30.219158172000164, -4.003086088999851],
              [30.218271256000094, -4.002630232999877],
              [30.21930694700012, -4.001412867999875],
              [30.218879699000126, -4.000206945999935],
              [30.21850013700015, -4.00004386899991],
              [30.217758179000043, -3.998395918999904],
              [30.216611863000026, -3.997368097999868],
              [30.216022491000103, -3.995810984999935],
              [30.215463639000063, -3.995281934999923],
              [30.215309141999967, -3.992326021999872],
              [30.215494156000034, -3.991765975999897],
              [30.214225769000166, -3.990923880999958],
              [30.215309141999967, -3.989804983999932],
              [30.21567916900017, -3.988435983999978],
              [30.216455460000134, -3.98896408],
              [30.218097687000068, -3.987812995999946],
              [30.21930694700012, -3.985758066999892],
              [30.22039032000015, -3.985291956999959],
              [30.221105576000127, -3.983922004999954],
              [30.222219468000105, -3.982490062999943],
              [30.22296524100011, -3.98112010899996],
              [30.223987579000152, -3.979814052999871],
              [30.22525787300009, -3.978662013999951],
              [30.22609329200003, -3.976514100999964],
              [30.22609329200003, -3.975642919999928],
              [30.227304459000152, -3.974397896999903],
              [30.22730255100015, -3.972748041999921],
              [30.22776794399999, -3.971596955999814],
              [30.22736549400014, -3.970942974999957],
              [30.226930619000086, -3.970601080999927],
              [30.226186752000103, -3.970880984999894],
              [30.226930619000086, -3.96957302099986],
              [30.228170395000177, -3.968827007999948],
              [30.22835540800014, -3.967300891999969],
              [30.22879028400007, -3.966679094999961],
              [30.229131699000163, -3.964251993999937],
              [30.22897529500017, -3.963411091999944],
              [30.229255676000037, -3.962789057999885],
              [30.229534149000074, -3.963536022999961],
              [30.230371475000027, -3.963877915999888],
              [30.230960846000073, -3.963318109999932],
              [30.230556489000094, -3.961824892999971],
              [30.23117637600012, -3.960237025999845],
              [30.23120880200014, -3.959333895999976],
              [30.230215073000068, -3.958992004999971],
              [30.229875565000043, -3.957808971999953],
              [30.23080444300018, -3.955537080999818],
              [30.230712891000053, -3.954042910999931],
              [30.22978019800007, -3.953545091999843],
              [30.231889724000155, -3.953171966999946],
              [30.232942581000145, -3.953109978999976],
              [30.23340797500009, -3.95242500199987],
              [30.233036040000172, -3.951585054999953],
              [30.23319053700004, -3.950650928999835],
              [30.23254013000019, -3.949965953999822],
              [30.23362541299997, -3.950027941999963],
              [30.233810424000126, -3.949156998999968],
              [30.23343849200006, -3.948379039999963],
              [30.23343849200006, -3.947570084999938],
              [30.233036040000172, -3.94716501299996],
              [30.233966827000188, -3.946075916999916],
              [30.23427581800007, -3.94535994599994],
              [30.23340797500009, -3.94402194099996],
              [30.23449134800012, -3.94405197999987],
              [30.234895707000135, -3.943212985999935],
              [30.23480224600013, -3.942434071999969],
              [30.233997345000034, -3.942029952999917],
              [30.23325347800005, -3.942059993999976],
              [30.231983186000093, -3.941063879999831],
              [30.233346940000104, -3.939136026999904],
              [30.233655929, -3.938420055999927],
              [30.235019683000075, -3.937797068999885],
              [30.236103059000186, -3.936364888999947],
              [30.236009597000077, -3.935586927999964],
              [30.237064361000137, -3.935461996999948],
              [30.237623214999985, -3.934808968999903],
              [30.23722076400003, -3.933937072999925],
              [30.23681640700005, -3.933470010999884],
              [30.2370338450001, -3.932693004999976],
              [30.238582610000094, -3.932722090999903],
              [30.240442277, -3.929734943999961],
              [30.241155623000054, -3.929173944999889],
              [30.240938186000108, -3.927432060999934],
              [30.240350723000063, -3.926903008999943],
              [30.241155623000054, -3.926249980999899],
              [30.241031647000113, -3.925595997999949],
              [30.24047470100004, -3.925221919999899],
              [30.240690231000087, -3.924226045999944],
              [30.240133285000013, -3.923635957999977],
              [30.241155623000054, -3.923322915999904],
              [30.241621017000057, -3.922514915999955],
              [30.242147445000057, -3.922451972999966],
              [30.24369621200003, -3.922826051999948],
              [30.244379044000084, -3.92201709699998],
              [30.244255066000107, -3.921302078999929],
              [30.24484443599999, -3.920958994999978],
              [30.245309830000167, -3.920212029999902],
              [30.245773315000065, -3.920710085999929],
              [30.247571946000107, -3.9211759559999],
              [30.248624801000062, -3.920460938999895],
              [30.24977111900006, -3.919153927999957],
              [30.250110625000104, -3.918842075999976],
              [30.250019073000146, -3.917690991999962],
              [30.250391006000086, -3.91632199299994],
              [30.250669479000123, -3.916135071999918],
              [30.251405716000022, -3.914396999999894],
              [30.251194001000044, -3.913156986999979],
              [30.250699998000073, -3.912625074999937],
              [30.25154686000019, -3.91202306699995],
              [30.251405716000022, -3.911206960999834],
              [30.250911713000107, -3.910568952999881],
              [30.251617431000057, -3.909861087999957],
              [30.252288818000125, -3.908797977999825],
              [30.25214767500006, -3.908159970999975],
              [30.252216339000086, -3.907663106999962],
              [30.253347397000027, -3.907912015999955],
              [30.253665924000188, -3.907131909999976],
              [30.25296020600007, -3.905855894999945],
              [30.25281906200013, -3.905112981999935],
              [30.253065109000147, -3.904546020999931],
              [30.254547118000062, -3.903836964999925],
              [30.255430222000143, -3.903376101999925],
              [30.255430222000143, -3.902384041999881],
              [30.25521850500013, -3.901463031999981],
              [30.255853651999985, -3.900577067999961],
              [30.25550079300001, -3.899266958999931],
              [30.255571366000083, -3.897778033999941],
              [30.25581932000017, -3.896856068999909],
              [30.25708961500004, -3.897104024999919],
              [30.257759094000107, -3.896537065999894],
              [30.25765419100003, -3.895651101999874],
              [30.25723075899998, -3.894942998999909],
              [30.258642197000086, -3.894090890999848],
              [30.259099961000118, -3.893099069999948],
              [30.25994873100018, -3.892319917999942],
              [30.260055541000156, -3.891681908999942],
              [30.25980758700007, -3.891114948999928],
              [30.259700775000113, -3.889484882999966],
              [30.259172440000157, -3.889060972999971],
              [30.258695603000035, -3.889154909999888],
              [30.257865906000063, -3.889591932999906],
              [30.25779533300016, -3.888883113999896],
              [30.257724762000123, -3.88696909],
              [30.257160186000135, -3.885056972999962],
              [30.256561280000142, -3.883884905999878],
              [30.257476806, -3.883884905999878],
              [30.258253097000193, -3.884594915999912],
              [30.25889015200005, -3.884701013999973],
              [30.260583878000034, -3.884418009999934],
              [30.261537551000174, -3.884170055999846],
              [30.261430741000027, -3.883353947999979],
              [30.260759354000186, -3.882148026999971],
              [30.26037216100002, -3.880414007999946],
              [30.2623844150001, -3.880022048999933],
              [30.263513565999972, -3.880165098999953],
              [30.264148713000054, -3.879776000999812],
              [30.26482009900019, -3.880589960999942],
              [30.265384674000074, -3.880696057999899],
              [30.266019821000157, -3.880094050999901],
              [30.26587867699999, -3.877790926999978],
              [30.266267777000053, -3.877082108999957],
              [30.267396928000153, -3.876797913999951],
              [30.268455505000077, -3.876302002999978],
              [30.26848983700006, -3.875593899999899],
              [30.269233704000044, -3.875983952999945],
              [30.269973756000184, -3.877187966999884],
              [30.270572662000177, -3.877754925999909],
              [30.271385192000082, -3.877754925999909],
              [30.27233695900003, -3.876657961999911],
              [30.272689819000107, -3.875097035999829],
              [30.27308082600007, -3.874209879999967],
              [30.273714066000025, -3.874353885999881],
              [30.273502349000182, -3.87506198899996],
              [30.273855210000193, -3.875771044999908],
              [30.274843217000125, -3.876054047999958],
              [30.275798797000107, -3.875556944999971],
              [30.27646827800004, -3.873716115999969],
              [30.277139664000117, -3.873431920999906],
              [30.27833747800014, -3.874209879999967],
              [30.27915191599999, -3.874316929999964],
              [30.279714585000136, -3.873609065999972],
              [30.27922058199999, -3.872119903999874],
              [30.280809402000045, -3.871766089999937],
              [30.281267165000145, -3.871198892999871],
              [30.281373978000033, -3.870562075999942],
              [30.282115937000015, -3.869959114999972],
              [30.282398223000143, -3.869427917999815],
              [30.28246879599999, -3.868719099999964],
              [30.283773423000014, -3.869143961999953],
              [30.284868241000027, -3.86886095899996],
              [30.285327911000024, -3.867938995999964],
              [30.285116196000047, -3.867124079999883],
              [30.284339904000092, -3.866274117999978],
              [30.28585624800013, -3.866274117999978],
              [30.286703110000076, -3.865741014999912],
              [30.286775589000115, -3.864927051999928],
              [30.287162781000177, -3.864396094999961],
              [30.288187027000163, -3.8635449399999],
              [30.288681030000134, -3.862694977999979],
              [30.289527892000024, -3.86294197899997],
              [30.290058137000074, -3.862128018999954],
              [30.29005622900013, -3.861561058999939],
              [30.289844512000116, -3.861063955999953],
              [30.290199279000035, -3.860354899999948],
              [30.290374757000166, -3.861347913999964],
              [30.290763855000023, -3.862092016999895],
              [30.291221619000112, -3.862658977999899],
              [30.292070389000173, -3.862730026999941],
              [30.292776107000122, -3.86230397199995],
              [30.293905258000166, -3.861100912999973],
              [30.295387267000137, -3.860037087999956],
              [30.296375275000173, -3.859682083999815],
              [30.297166825000147, -3.85856795199993],
              [30.29771614100008, -3.857875108999963],
              [30.29852867200009, -3.857486008999899],
              [30.299234390000038, -3.856138944999941],
              [30.29944610700005, -3.854792116999875],
              [30.298881531000063, -3.852631090999921],
              [30.298564911000142, -3.851674078999963],
              [30.2996234900001, -3.851707934999922],
              [30.300504684000146, -3.850717066999948],
              [30.300506592000147, -3.849937914999941],
              [30.301248551000185, -3.850680111999907],
              [30.30227279700017, -3.85043191799997],
              [30.3031520830001, -3.84947705299993],
              [30.304212570000175, -3.849581955999895],
              [30.30498886200013, -3.848555087999955],
              [30.305624009000155, -3.84685396999987],
              [30.306259156000067, -3.84618091599998],
              [30.307495117000087, -3.84618091599998],
              [30.308376313000167, -3.845791100999861],
              [30.308837890000063, -3.845294951999847],
              [30.308624267000084, -3.843983887999968],
              [30.309013368000024, -3.842566013999829],
              [30.30996704000006, -3.842494963999968],
              [30.31092071500018, -3.841468094999925],
              [30.31106185900012, -3.839943885999958],
              [30.311695099000133, -3.839483021999911],
              [30.312013626000123, -3.83874010999989],
              [30.311765672000035, -3.837251900999945],
              [30.311025620000066, -3.836009978999869],
              [30.310709, -3.835302113999944],
              [30.31106185900012, -3.833883999999898],
              [30.310283660000152, -3.83267998599996],
              [30.30866050700007, -3.832572935999963],
              [30.30940246600005, -3.831934928999942],
              [30.310813905000032, -3.831614970999851],
              [30.31194305500003, -3.830658911999876],
              [30.31268310500019, -3.830657958999893],
              [30.313072205000026, -3.83001995099994],
              [30.314344405000156, -3.829915045999883],
              [30.315155028000163, -3.829384088999916],
              [30.315261841, -3.828071116999979],
              [30.315048218000186, -3.827008962999969],
              [30.314197541000055, -3.826225757999964],
              [30.315719605000083, -3.826122998999836],
              [30.316320420000125, -3.825165986999878],
              [30.31653213500016, -3.824704884999903],
              [30.31596755900017, -3.82431602399987],
              [30.31455612200017, -3.824209928999892],
              [30.314979553000114, -3.823606967999922],
              [30.315683365000098, -3.823854922999942],
              [30.31709670900011, -3.824563980999869],
              [30.317661286000032, -3.823784111999942],
              [30.316850663000025, -3.821657896999966],
              [30.316884994000134, -3.820807933999959],
              [30.317556381000145, -3.819674013999929],
              [30.31723785300011, -3.818823098999872],
              [30.319463730000166, -3.818964003999895],
              [30.320556641000053, -3.818291901999885],
              [30.321050643999968, -3.81701493199995],
              [30.320909500000027, -3.815812110999957],
              [30.321685792000153, -3.815563916999963],
              [30.323238372000162, -3.816272019999929],
              [30.323415755000042, -3.815030096999919],
              [30.32320213300011, -3.814109085999917],
              [30.32193374600007, -3.813152073999959],
              [30.323274611000045, -3.812622070999908],
              [30.32405090300017, -3.812056064999979],
              [30.323980332000076, -3.811275004999914],
              [30.32461547900016, -3.810708045999888],
              [30.32493209900008, -3.810105085999851],
              [30.32436752300009, -3.809184074999848],
              [30.323698044000025, -3.808687923999912],
              [30.322990418000074, -3.808546064999973],
              [30.321191788000135, -3.809149980999962],
              [30.321826936000093, -3.807837008999968],
              [30.321720123000034, -3.80702090099993],
              [30.323556900000085, -3.807518958999935],
              [30.324720384000045, -3.806915998999955],
              [30.324544906000085, -3.806066035999947],
              [30.3236274730001, -3.805001973999936],
              [30.324234009000065, -3.804115056999933],
              [30.326026917000036, -3.803690909999887],
              [30.326379777000113, -3.803123950999975],
              [30.32623863200007, -3.802308082999957],
              [30.325426102000165, -3.801918981999961],
              [30.324155807000125, -3.801848888999928],
              [30.323944092000147, -3.800997971999948],
              [30.324226379000095, -3.800537109999823],
              [30.325109482000073, -3.800997971999948],
              [30.325885773000095, -3.801105021999831],
              [30.326274872000056, -3.800467013999878],
              [30.32606315700002, -3.799333095999941],
              [30.32521438700013, -3.798377035999977],
              [30.325462341000048, -3.79752397599998],
              [30.325426102000165, -3.796674965999898],
              [30.324296952000168, -3.796036003999973],
              [30.324508667000032, -3.795007942999916],
              [30.325321197000108, -3.794017076999921],
              [30.327791213000182, -3.793025015999888],
              [30.32832145800012, -3.792279957999881],
              [30.328355789, -3.791006087999904],
              [30.327756881000028, -3.790472982999972],
              [30.32832145800012, -3.789658069999859],
              [30.32938194200011, -3.789161919999913],
              [30.329698562000033, -3.78852391199996],
              [30.331075668999972, -3.788383007999869],
              [30.331392289000064, -3.787636994999957],
              [30.33103942800011, -3.786326883999834],
              [30.33072280800019, -3.785583971999927],
              [30.330192565000118, -3.785120964999976],
              [30.330543519, -3.784837961999926],
              [30.331251145000124, -3.785689113999979],
              [30.332345963000137, -3.78575897199994],
              [30.333404541000107, -3.784449100999893],
              [30.334463120000066, -3.784768103999909],
              [30.335803986000144, -3.784626959999969],
              [30.336370468000155, -3.783173083999941],
              [30.33617019600007, -3.780697344999965],
              [30.337783814000034, -3.781364916999962],
              [30.338840485000105, -3.780586004999918],
              [30.33891105600003, -3.779772041999877],
              [30.34046363900012, -3.779274940999869],
              [30.341064452000182, -3.778034924999872],
              [30.34399414000012, -3.777113914999973],
              [30.344841004000045, -3.777043103999972],
              [30.345510483000112, -3.776475906999906],
              [30.345617293000032, -3.775875091999978],
              [30.34713554399997, -3.775589942999886],
              [30.347631455000055, -3.774951933999944],
              [30.34748840300017, -3.774172066999824],
              [30.349889756000152, -3.774172066999824],
              [30.35186576800004, -3.77573108699994],
              [30.35327720700019, -3.77597904199996],
              [30.354019165000125, -3.776015042999973],
              [30.355537415000185, -3.775625944999945],
              [30.356348038000192, -3.77672505399994],
              [30.357337952000023, -3.776794909999921],
              [30.357231140000067, -3.777432916999885],
              [30.35613632100018, -3.778213023999967],
              [30.356172563000143, -3.779097079999929],
              [30.356771468000034, -3.779700993999882],
              [30.357973099000105, -3.780550955999956],
              [30.359455109000123, -3.781436919999976],
              [30.359949112000038, -3.781436919999976],
              [30.360868453000023, -3.780622003999952],
              [30.361679078, -3.778317928999911],
              [30.36238670400013, -3.777682065999898],
              [30.36340904200017, -3.777396917999909],
              [30.364856720000034, -3.777148960999909],
              [30.366973877000135, -3.775589942999886],
              [30.371845245000145, -3.775554893999924],
              [30.374280929000065, -3.776298998999948],
              [30.375303269000085, -3.77619290399997],
              [30.37590408300008, -3.775732039999923],
              [30.378833770000085, -3.777326106999908],
              [30.380105972000024, -3.776829958999826],
              [30.37992858900003, -3.778034924999872],
              [30.380281448000176, -3.778848885999878],
              [30.381164551000154, -3.779062985999929],
              [30.382255555000143, -3.7786030769999],
              [30.38402366600019, -3.778636930999937],
              [30.385858536000114, -3.780091046999928],
              [30.385999680000054, -3.780515908999973],
              [30.385683060000133, -3.780975102999946],
              [30.384128570000144, -3.781757114999891],
              [30.383846282000036, -3.782500026999912],
              [30.38405990700005, -3.783880948999979],
              [30.384729386000117, -3.78494501199998],
              [30.38537788400015, -3.785356997999941],
              [30.3847637180001, -3.785866974999976],
              [30.385541916000193, -3.788134097999887],
              [30.386690140000155, -3.788972854999827],
              [30.388893128000177, -3.789197920999868],
              [30.390483856000174, -3.789091110999948],
              [30.39059066800013, -3.789764879999893],
              [30.39115333500007, -3.79022407399998],
              [30.39097785900009, -3.791323900999942],
              [30.391401291000136, -3.792315959999939],
              [30.39242553700018, -3.79256510599987],
              [30.393693924000047, -3.791996954999945],
              [30.39482498199999, -3.790720939999972],
              [30.396131515000036, -3.79093289299982],
              [30.396131515000036, -3.792387007999878],
              [30.396238328000095, -3.79369711899983],
              [30.394542693000176, -3.794017076999921],
              [30.394260407000104, -3.79468894099989],
              [30.39447021500007, -3.795541047999961],
              [30.395107270000096, -3.796001909999916],
              [30.396413803000087, -3.79610896],
              [30.396329880000167, -3.797027109999931],
              [30.396799087000034, -3.797723054999949],
              [30.39785194300009, -3.797638893999931],
              [30.398212433000083, -3.797027109999931],
              [30.398574830000143, -3.796053886999971],
              [30.399099349000096, -3.795579909999901],
              [30.400123597000118, -3.796220064999943],
              [30.401010514000063, -3.796303033999948],
              [30.40159034800007, -3.795939921999946],
              [30.401842118000104, -3.795634984999936],
              [30.40186881900007, -3.79468894099989],
              [30.402257920000068, -3.794020891999878],
              [30.402784347000193, -3.79363107599994],
              [30.404668808000054, -3.793659924999929],
              [30.404695511, -3.793047903999934],
              [30.405139922000103, -3.791656015999877],
              [30.40491676400012, -3.790041922999819],
              [30.40610885500007, -3.789123057999973],
              [30.40555381700017, -3.788038013999937],
              [30.406217575000028, -3.787925958999892],
              [30.406274796000048, -3.787147044999927],
              [30.407604218000074, -3.787118910999823],
              [30.408767700000055, -3.786035059999847],
              [30.40973854000009, -3.785226106999971],
              [30.410375595000176, -3.784392117999971],
              [30.410375595000176, -3.783056020999936],
              [30.411066055000106, -3.78085708499998],
              [30.41054153500005, -3.779215097999895],
              [30.410152435000157, -3.777183055999899],
              [30.410207748, -3.775122880999959],
              [30.410291671000152, -3.774148940999965],
              [30.40887832700008, -3.773648022999964],
              [30.409322738000185, -3.772341012999846],
              [30.409349442000064, -3.771589993999953],
              [30.40926551800004, -3.770086049999975],
              [30.40948867800006, -3.768500088999929],
              [30.41056823700012, -3.766468047999922],
              [30.410375595000176, -3.765966891999938],
              [30.409570695000184, -3.764130115999933],
              [30.408546447000163, -3.764211892999924],
              [30.40835189800015, -3.763794897999901],
              [30.408020020000038, -3.763405084999874],
              [30.40738296500018, -3.763044117999868],
              [30.406217575000028, -3.762710094999932],
              [30.406053543000098, -3.762320993999879],
              [30.40555381700017, -3.761540889999935],
              [30.404529571000126, -3.761374949999947],
              [30.404169082000067, -3.759202957999946],
              [30.403919220000034, -3.758480071999884],
              [30.404390335000187, -3.757782935999956],
              [30.404695511, -3.756558893999909],
              [30.404336930000113, -3.75597596199998],
              [30.404777528000125, -3.755695103999926],
              [30.405221939000057, -3.754833935999898],
              [30.404722213000127, -3.752830028999938],
              [30.404336930000113, -3.751966953999954],
              [30.403587342000094, -3.751159905999941],
              [30.403005600000085, -3.749881028999937],
              [30.401952743000095, -3.748739004999948],
              [30.40214729200011, -3.747013091999861],
              [30.40095520100016, -3.746372936999933],
              [30.40106582599998, -3.745428084999901],
              [30.400455475000058, -3.74484205199991],
              [30.40023422200011, -3.744201897999972],
              [30.400899886000104, -3.744035004999944],
              [30.401121139000054, -3.743200062999961],
              [30.399126052999975, -3.740972040999964],
              [30.39893150400013, -3.740179061999925],
              [30.398738860000037, -3.739887952999879],
              [30.39790725600011, -3.739496946999964],
              [30.397686005000025, -3.7389969809999],
              [30.39810180700016, -3.737272022999889],
              [30.39859962600002, -3.737577914999974],
              [30.399402618000067, -3.737438917999953],
              [30.399488450000035, -3.735908030999951],
              [30.399070740000127, -3.735044002999928],
              [30.398378373000128, -3.734515905999956],
              [30.398405074000095, -3.732985971999938],
              [30.39862823499999, -3.731843947999948],
              [30.398323058000074, -3.731093882999971],
              [30.396467209000093, -3.72956299699996],
              [30.395856857000012, -3.729506968999942],
              [30.394916534000117, -3.729897021999875],
              [30.39422226000005, -3.729813097999966],
              [30.393251420000183, -3.729090928999881],
              [30.392755509000096, -3.728087901999913],
              [30.392311096000185, -3.727947950999976],
              [30.391590118000067, -3.728171108999959],
              [30.390815734000114, -3.728169918999868],
              [30.39070510800019, -3.72758698399997],
              [30.391036987, -3.726667880999855],
              [30.3906211850001, -3.726166962999969],
              [30.390317918000108, -3.725248098999884],
              [30.389596939000057, -3.724385975999951],
              [30.389263153000172, -3.722187040999927],
              [30.389125824000075, -3.720266102999972],
              [30.389623643000164, -3.71940398299995],
              [30.39203453100015, -3.718458890999898],
              [30.39203453100015, -3.716175078999868],
              [30.391424180000172, -3.715953110999976],
              [30.391120910000154, -3.715200900999889],
              [30.390537263000056, -3.714589119999914],
              [30.389707564000105, -3.714477061999958],
              [30.388904572000058, -3.715063094999948],
              [30.388681411000164, -3.714338062999843],
              [30.387020111000027, -3.714309931999935],
              [30.387462616000164, -3.713670014999877],
              [30.389125824000075, -3.713308094999889],
              [30.389513015000034, -3.712835072999894],
              [30.39040183900005, -3.711250065999934],
              [30.391120910000154, -3.710690974999977],
              [30.391342163000047, -3.709969042999944],
              [30.391397476000122, -3.70913410199995],
              [30.392007827000043, -3.708717106999814],
              [30.39208984300012, -3.708215950999829],
              [30.391618729000186, -3.706629991999876],
              [30.390483856000174, -3.70546007199988],
              [30.39098167400016, -3.704848050999942],
              [30.39170265300004, -3.705626964999908],
              [30.392644882000127, -3.70582199099988],
              [30.393808364000108, -3.704430103999925],
              [30.396717072000058, -3.705126046999965],
              [30.397575378000056, -3.70504402999984],
              [30.398767471000156, -3.703428028999952],
              [30.39893341100003, -3.702843903999963],
              [30.398767471000156, -3.701898097999958],
              [30.397548676000156, -3.701118945999951],
              [30.398019791000138, -3.700505971999974],
              [30.399293899000043, -3.700339078999946],
              [30.40023422200011, -3.700644968999939],
              [30.40084457400019, -3.69997691999987],
              [30.400041581000096, -3.699003934999951],
              [30.4005947120001, -3.698139905999938],
              [30.401231766000024, -3.69680499899988],
              [30.402645111000027, -3.69580197199997],
              [30.40303230300009, -3.694576978999919],
              [30.40375328000016, -3.693269013999895],
              [30.40353202700004, -3.691683053999952],
              [30.402950287000067, -3.691124914999932],
              [30.402950287000067, -3.690596102999962],
              [30.403781891000108, -3.689873933999934],
              [30.403310776000126, -3.68906688699991],
              [30.40353202700004, -3.688594101999968],
              [30.404308319000165, -3.688538073999951],
              [30.404750824000075, -3.687618970999949],
              [30.405139922000103, -3.686619043999883],
              [30.406192779000094, -3.686366079999914],
              [30.407131196000023, -3.687033890999942],
              [30.40774345400007, -3.687870024999938],
              [30.40832328900018, -3.688565014999881],
              [30.408990860000074, -3.688231944999927],
              [30.409709931000123, -3.686674116999939],
              [30.409349442000064, -3.684752941999932],
              [30.408657075000065, -3.683278082999948],
              [30.40563774100019, -3.680329083999936],
              [30.405582428000116, -3.679769992999979],
              [30.406663894000076, -3.679187058999958],
              [30.407688142000097, -3.680047988999945],
              [30.408712387000037, -3.680188892999979],
              [30.40959739600015, -3.678936003999979],
              [30.411149979000072, -3.678601025999967],
              [30.411926270000095, -3.677265882999961],
              [30.412008286000173, -3.676013946999944],
              [30.41198158300017, -3.67504095899983],
              [30.411483765000185, -3.674817085999962],
              [30.411066055000106, -3.674010990999875],
              [30.410182953, -3.673954010999978],
              [30.41001510600006, -3.67295193599989],
              [30.40959739600015, -3.6723949899999],
              [30.408740996000176, -3.671921014999953],
              [30.408462525000118, -3.67114305399997],
              [30.409072876000096, -3.670475004999958],
              [30.409349442000064, -3.670028923999894],
              [30.410070419000135, -3.668469905999871],
              [30.410402299000054, -3.667135000999906],
              [30.41140174800006, -3.667690991999905],
              [30.412229537000087, -3.666551113999901],
              [30.41547012400008, -3.66632795299995],
              [30.4159717550001, -3.666270970999904],
              [30.416219711000167, -3.665297984999882],
              [30.415388108, -3.665076016999933],
              [30.415334702000052, -3.664490936999926],
              [30.415805817000034, -3.663907049999978],
              [30.415195466000057, -3.663182973999938],
              [30.41560936000019, -3.661708116999876],
              [30.416967392000117, -3.660567045999869],
              [30.416164399000024, -3.659203051999896],
              [30.41594314600013, -3.658284901999878],
              [30.41594314600013, -3.657393931999877],
              [30.41630363500019, -3.656781910999939],
              [30.416276932000187, -3.655474899999888],
              [30.41604805100019, -3.65421295099992],
              [30.416219711000167, -3.653719901999978],
              [30.41818618800005, -3.654944896999837],
              [30.419406891000108, -3.654665946999955],
              [30.42032051100017, -3.653943060999893],
              [30.419784546000187, -3.65240097099985],
              [30.421012879000102, -3.651604890999977],
              [30.420875550000176, -3.651103019999937],
              [30.420097351000038, -3.650434970999868],
              [30.42065238900011, -3.64987897799989],
              [30.420791625999982, -3.648961066999959],
              [30.421455384000012, -3.647763967999936],
              [30.420875550000176, -3.64698505399997],
              [30.421676635000154, -3.646151064999913],
              [30.422010423000017, -3.645092010999861],
              [30.422620774000166, -3.643506048999825],
              [30.42264747500019, -3.642113923999943],
              [30.422231673000056, -3.641251085999954],
              [30.42314529400005, -3.641446112999915],
              [30.423700332000124, -3.641028880999954],
              [30.424144745000035, -3.64041590699992],
              [30.42536354000009, -3.640888928999971],
              [30.42702484100016, -3.641891002999955],
              [30.4281044010001, -3.64166903499995],
              [30.428161621000072, -3.640973089999932],
              [30.42735672100008, -3.638968943999942],
              [30.427495957000076, -3.638273953999885],
              [30.427967072000058, -3.637744903999931],
              [30.42874336300008, -3.637689113999898],
              [30.430597305000163, -3.637439011999902],
              [30.43123436000019, -3.636632918999908],
              [30.43101310700007, -3.636018990999844],
              [30.430681229000186, -3.635572909999951],
              [30.429767609000066, -3.63543510299985],
              [30.42985153300009, -3.634321927999849],
              [30.430793762000178, -3.634738920999894],
              [30.431152344000168, -3.633986949999951],
              [30.431678771000122, -3.634238004999929],
              [30.432317733000048, -3.633904933999872],
              [30.433036804000096, -3.632930040999952],
              [30.43347931000011, -3.63178801499987],
              [30.433534623000185, -3.630731104999939],
              [30.433868408000023, -3.629950998999959],
              [30.43381118800005, -3.627835989999937],
              [30.43345260600006, -3.627194881999969],
              [30.432704925999985, -3.627252101999943],
              [30.432178497000052, -3.627640962999976],
              [30.43137359700006, -3.627530097999909],
              [30.43057060200016, -3.627028941999924],
              [30.430376052000042, -3.626250027999959],
              [30.429546356000174, -3.625971079999886],
              [30.42979431200007, -3.625525950999929],
              [30.430099487000177, -3.624634979999939],
              [30.431762695000145, -3.622966050999935],
              [30.433258057000046, -3.623271940999928],
              [30.433979034000117, -3.622881889999917],
              [30.43383979700019, -3.622131109999884],
              [30.43450546300005, -3.621992110999941],
              [30.435087203000023, -3.621185062999928],
              [30.435972214000174, -3.620767115999911],
              [30.43702506900013, -3.620155094999973],
              [30.437744140000063, -3.619904040999927],
              [30.438716888000044, -3.620294093999917],
              [30.43996238699998, -3.621046065999963],
              [30.441043855000146, -3.621380089999889],
              [30.442041397000082, -3.620795011999974],
              [30.442649842000037, -3.620016097999951],
              [30.44367599500015, -3.619710921999911],
              [30.444229126000153, -3.619263887999978],
              [30.443950653000115, -3.618762016999938],
              [30.4444236760001, -3.618484973999898],
              [30.44489479000015, -3.61778902899988],
              [30.445697785000107, -3.617511032999971],
              [30.446140289000084, -3.616369961999965],
              [30.44769287100013, -3.615674019999858],
              [30.44741439800009, -3.614839075999953],
              [30.447137833000056, -3.614310025999885],
              [30.447025300000064, -3.612724065999942],
              [30.44722175700008, -3.612111090999918],
              [30.447969437000097, -3.612112044999947],
              [30.447719575, -3.612780093999959],
              [30.447719575, -3.613085030999969],
              [30.448356628000056, -3.613892078999925],
              [30.44907760600006, -3.61414289499993],
              [30.449825286000078, -3.613976001999959],
              [30.449964523000176, -3.613197088999925],
              [30.45051765400018, -3.613531111999919],
              [30.451265336000063, -3.613141058999872],
              [30.451625823000143, -3.61255693399994],
              [30.451404572, -3.611721991999957],
              [30.45159912200012, -3.610970019999911],
              [30.453010558000187, -3.610665082999844],
              [30.45306778000014, -3.610191105999945],
              [30.452568055000086, -3.609411953999882],
              [30.451625823000143, -3.608855007999978],
              [30.45165443400009, -3.608242988999962],
              [30.452955246000045, -3.608299016999979],
              [30.45381546100009, -3.607991934999973],
              [30.45508956800012, -3.607881068999973],
              [30.4556427010001, -3.60763001499987],
              [30.45708465600012, -3.60634994499992],
              [30.457056045000172, -3.605403900999932],
              [30.45647239800013, -3.604320049999956],
              [30.455865859000085, -3.602926969999942],
              [30.455865859000085, -3.602231024999924],
              [30.45650291400017, -3.600560903999963],
              [30.457029344000034, -3.59853005399998],
              [30.456695556000113, -3.597332954999956],
              [30.45600318800018, -3.596720933999904],
              [30.45647239800013, -3.596609115999911],
              [30.45780372500002, -3.595746040999927],
              [30.45805549700009, -3.59499597599995],
              [30.45802497800014, -3.593406915999822],
              [30.454450608000172, -3.591264962999958],
              [30.453842162000115, -3.5914309019999],
              [30.45237350499997, -3.592989921999958],
              [30.45159912200012, -3.593045949999976],
              [30.451044083000113, -3.592489957999874],
              [30.45082283000005, -3.59162688299989],
              [30.452291489000118, -3.589037893999887],
              [30.453037262000123, -3.58892607599995],
              [30.45439720100012, -3.589817045999951],
              [30.45483970600003, -3.589315891999888],
              [30.45420265200005, -3.587896107999939],
              [30.45497894300007, -3.58759093399982],
              [30.455615998000155, -3.587007045999826],
              [30.455450057000178, -3.586116074999893],
              [30.45483970600003, -3.585252998999977],
              [30.455062867000095, -3.584975003999887],
              [30.457195282000043, -3.583444116999885],
              [30.45650291400017, -3.582276104999892],
              [30.455364228000064, -3.581273077999924],
              [30.45650291400017, -3.580492973999981],
              [30.457277298000122, -3.579185962999929],
              [30.457138061000023, -3.578351020999946],
              [30.45647239800013, -3.577989100999901],
              [30.456918716000075, -3.577433107999923],
              [30.456779480000137, -3.576486110999895],
              [30.456529618000047, -3.575567959999944],
              [30.455698014000177, -3.575067041999887],
              [30.452596664000055, -3.574425934999965],
              [30.451875686999983, -3.574760912999977],
              [30.451986313000077, -3.575511932999973],
              [30.452678680000076, -3.576458929999944],
              [30.452512742000067, -3.5771269789999],
              [30.451791763000188, -3.577016115999868],
              [30.45051765400018, -3.576904057999855],
              [30.44907760600006, -3.576040982999928],
              [30.448274613000137, -3.57515096599991],
              [30.448438644000134, -3.5745940199999],
              [30.447969437000097, -3.573815107999963],
              [30.448440550999976, -3.573035001999926],
              [30.447719575, -3.571476935999954],
              [30.447553635000133, -3.570503950999921],
              [30.446805955000116, -3.569334030999926],
              [30.44675064000006, -3.56858205799989],
              [30.44605827200013, -3.568080901999906],
              [30.44719314499997, -3.568274973999962],
              [30.44880867100011, -3.566582916999948],
              [30.44866752700011, -3.565753935999908],
              [30.449434280000048, -3.565753935999908],
              [30.450004577000072, -3.564580917999933],
              [30.450546265000128, -3.563693998999838],
              [30.45068931700007, -3.563208103999955],
              [30.452682495000147, -3.562578916999939],
              [30.453165055000113, -3.561948059999963],
              [30.453451156000085, -3.561263084999951],
              [30.453052520000142, -3.560461997999937],
              [30.454561233000163, -3.559776067999962],
              [30.456384659000037, -3.55923199699987],
              [30.457521439000118, -3.558430909999913],
              [30.45846366900014, -3.557315109999877],
              [30.45928764399997, -3.557457923999891],
              [30.461479187000066, -3.556485890999852],
              [30.461906433000024, -3.55591392499997],
              [30.461849212000175, -3.555083035999928],
              [30.461280823000152, -3.554025887999956],
              [30.46130943200012, -3.552510022999968],
              [30.46239090000006, -3.551537036999832],
              [30.465068818000134, -3.549792049999951],
              [30.465837479000072, -3.549077033999936],
              [30.466663360000098, -3.54781699199998],
              [30.468769074000022, -3.545043944999918],
              [30.469821931000013, -3.542954921999922],
              [30.470876693000093, -3.541009902999917],
              [30.471588134000058, -3.539982079999959],
              [30.473524094000027, -3.539351940999893],
              [30.47563171300004, -3.538321971999949],
              [30.476428985000098, -3.538007974999971],
              [30.478052139000113, -3.537663936999934],
              [30.479646683000055, -3.536633967999876],
              [30.480615616000023, -3.535346985999979],
              [30.480871201000127, -3.534346103999951],
              [30.481355666000184, -3.533859967999945],
              [30.483064651000063, -3.533288001999893],
              [30.484058380000135, -3.531543014999841],
              [30.484714509000185, -3.530169962999935],
              [30.48582649300016, -3.529541015999939],
              [30.48909950200016, -3.528053043999933],
              [30.490211486000135, -3.523619890999839],
              [30.49072265500007, -3.523133038999958],
              [30.49197578400009, -3.52255988099995],
              [30.49225998000003, -3.52193188699988],
              [30.49183464000015, -3.521245001999887],
              [30.492145539000035, -3.520586013999889],
              [30.493000030000132, -3.520759104999911],
              [30.49499321000019, -3.520243882999921],
              [30.49695778000006, -3.518069028999946],
              [30.49849510200005, -3.517638920999957],
              [30.499748230000193, -3.517668961999959],
              [30.50031852699999, -3.517182111999887],
              [30.500703813000086, -3.517452954999953],
              [30.501583099000186, -3.517874001999928],
              [30.502998353000066, -3.518722056999934],
              [30.504344941000113, -3.51975393299989],
              [30.50568962100016, -3.519613027999867],
              [30.5065250400001, -3.519870995999895],
              [30.507940292000114, -3.520715951999819],
              [30.50991249200007, -3.52210211799985],
              [30.51086235100007, -3.523325919999934],
              [30.51132774299998, -3.525607108999964],
              [30.51123619100008, -3.527348995999944],
              [30.51098060700008, -3.528124094999839],
              [30.510679245000176, -3.529325007999887],
              [30.51107406600005, -3.53061699899996],
              [30.51190757800009, -3.530333995999968],
              [30.514387131000035, -3.529885052999873],
              [30.515409470000122, -3.529320000999917],
              [30.516592025000193, -3.528143882999871],
              [30.517494202000137, -3.526802062999877],
              [30.518444062000015, -3.525201082999843],
              [30.51939582900019, -3.52402591799995],
              [30.520879746000105, -3.521790027999941],
              [30.522201538000047, -3.520988940999928],
              [30.522548676000042, -3.519742011999881],
              [30.523521422000044, -3.517412899999897],
              [30.523914338000054, -3.515202043999921],
              [30.524494170000025, -3.513577937999855],
              [30.525629043000094, -3.512141942999961],
              [30.52757835500006, -3.510118007999949],
              [30.53010559100011, -3.508918046999952],
              [30.53156471200009, -3.508681058999969],
              [30.532421112000122, -3.507833956999946],
              [30.53420639000018, -3.505222081999932],
              [30.535297394000054, -3.503739116999896],
              [30.53698730499997, -3.502749920999918],
              [30.53777694700011, -3.502350091999972],
              [30.538703919000113, -3.502397058999975],
              [30.54023361300017, -3.502041099999929],
              [30.54229736400015, -3.500911949999932],
              [30.545103073, -3.499192953999966],
              [30.54593849200012, -3.49898004399995],
              [30.54876709000007, -3.498084066999979],
              [30.55134010300003, -3.497869966999929],
              [30.553611755000077, -3.496645926999975],
              [30.55504989700006, -3.496833085999924],
              [30.55660247700007, -3.496669052999948],
              [30.558134079000126, -3.496196985999973],
              [30.560544967000112, -3.495043991999978],
              [30.561286927000083, -3.495111942999927],
              [30.562723159000086, -3.494712113999867],
              [30.56395339900007, -3.494592905999923],
              [30.56470680200016, -3.494484900999964],
              [30.566051483000138, -3.492343902999949],
              [30.567026138000188, -3.49154305299993],
              [30.56834793000013, -3.490295886999945],
              [30.56971549900004, -3.489682912999967],
              [30.572496414, -3.489093065999896],
              [30.57437515300012, -3.488645076999944],
              [30.57590484700006, -3.488055944999871],
              [30.578014373000144, -3.487184046999971],
              [30.579359054000122, -3.486149072999922],
              [30.580287932000033, -3.486053942999888],
              [30.581586838000078, -3.48544192199995],
              [30.583810807000134, -3.48480391499993],
              [30.58463096600002, -3.484227894999947],
              [30.585300445000087, -3.483016967999959],
              [30.586090089000038, -3.482590913999957],
              [30.587968827000054, -3.481203078999897],
              [30.589429855000162, -3.480802059999917],
              [30.590124130000163, -3.480307101999927],
              [30.591259004000108, -3.479224920999911],
              [30.592742921000024, -3.477719067999942],
              [30.59480666999997, -3.476094961999934],
              [30.596103668000183, -3.475481987999842],
              [30.59696197600016, -3.474916933999964],
              [30.59744834900016, -3.47378897599998],
              [30.597957610000094, -3.472915887999875],
              [30.602388383000175, -3.470844030999956],
              [30.60449600200019, -3.469455002999951],
              [30.6096668240001, -3.467757940999945],
              [30.61369323700012, -3.467047929999922],
              [30.615987778000147, -3.466599941999903],
              [30.61814498900003, -3.466622112999971],
              [30.618770600000175, -3.46638607899996],
              [30.619720460000053, -3.465069054999958],
              [30.620277406000127, -3.464644907999968],
              [30.62194633400003, -3.464478969999902],
              [30.622386932000097, -3.464195966999967],
              [30.623291015000177, -3.462620019999918],
              [30.629026413000076, -3.459572076999905],
              [30.631088257000158, -3.457406043999924],
              [30.631971359000033, -3.45674800799992],
              [30.63269043000014, -3.456700085999955],
              [30.63331604000018, -3.456417083999895],
              [30.63414955100012, -3.455169915999932],
              [30.635633468000094, -3.454627990999938],
              [30.63725662200011, -3.453663110999912],
              [30.637445450000087, -3.452486990999944],
              [30.637659073000066, -3.451544998999907],
              [30.638401031, -3.45114397999987],
              [30.639163971000073, -3.449708937999958],
              [30.63914108300014, -3.44822692799994],
              [30.63937377900004, -3.447474002999968],
              [30.640346527000077, -3.446933984999873],
              [30.64401054300015, -3.444272040999977],
              [30.64505386400009, -3.443166015999964],
              [30.646467208000104, -3.442742108999937],
              [30.649549485000136, -3.442387104999966],
              [30.650571823000178, -3.441562890999933],
              [30.651590348000184, -3.44045591399987],
              [30.651962281000124, -3.439421890999938],
              [30.65298271099999, -3.438338994999981],
              [30.65532302800011, -3.436361074999923],
              [30.655647278000117, -3.435278891999928],
              [30.6577358240001, -3.43414807399995],
              [30.658752441000104, -3.433325051999816],
              [30.66016960100012, -3.432406901999968],
              [30.66098976100011, -3.432446955999978],
              [30.661602020000032, -3.431705951999959],
              [30.66163444400007, -3.431440113999884],
              [30.662145614999986, -3.430990932999976],
              [30.662490844000047, -3.430263041999922],
              [30.662885665000147, -3.42868709499993],
              [30.66464614800003, -3.425863027999867],
              [30.66636085500005, -3.423274993999939],
              [30.667194367000093, -3.42303895799995],
              [30.668680192000068, -3.422801971999888],
              [30.66995620700004, -3.42167210599996],
              [30.671972276000133, -3.419483898999886],
              [30.672504426000103, -3.418565033999812],
              [30.672351836000075, -3.41710209799993],
              [30.672468186000117, -3.41665506399994],
              [30.67360305700015, -3.414938925999877],
              [30.67427635100006, -3.413809060999938],
              [30.67427635100006, -3.412468909999973],
              [30.673765182000125, -3.410280942999975],
              [30.67302131700012, -3.408587932999865],
              [30.673324584000113, -3.407931089999977],
              [30.673971177000055, -3.407176016999927],
              [30.674526215000128, -3.40571808899989],
              [30.676475525000114, -3.403904913999895],
              [30.67719459600005, -3.403011083999843],
              [30.678096771000128, -3.399152993999905],
              [30.678144455, -3.398518083999875],
              [30.677772523000158, -3.397624014999963],
              [30.67628669800007, -3.396121023999967],
              [30.675962448000064, -3.395555972999944],
              [30.675336838000135, -3.395039080999879],
              [30.674987792000024, -3.393604038999968],
              [30.674198150000052, -3.392570017999958],
              [30.672691345000032, -3.39184188899992],
              [30.671693803000096, -3.39158391899997],
              [30.670906065999986, -3.392076969999948],
              [30.67039871200012, -3.39138007199989],
              [30.67044448900009, -3.390393017999941],
              [30.669586182000046, -3.389712093999947],
              [30.665157319000173, -3.387339114999975],
              [30.661514282999974, -3.386071920999939],
              [30.65959167600016, -3.384989022999946],
              [30.657897949000073, -3.384661911999956],
              [30.65300560000003, -3.384430883999926],
              [30.651613235000013, -3.38438391699998],
              [30.650014877000103, -3.384361982999906],
              [30.648691177000046, -3.384291886999904],
              [30.647579193000013, -3.384340046999967],
              [30.646930695000037, -3.384952067999905],
              [30.646928787000036, -3.384393929999817],
              [30.646741866000127, -3.38343000399982],
              [30.646741866000127, -3.381994961999908],
              [30.64718246400014, -3.380842922999818],
              [30.647737503000144, -3.379996060999929],
              [30.64783096200017, -3.379288910999946],
              [30.647621155000138, -3.378489970999908],
              [30.646623611, -3.376326083999913],
              [30.64602089000016, -3.374702930999831],
              [30.644674302000112, -3.373953102999963],
              [30.644298554000102, -3.37358808599987],
              [30.640817641000126, -3.368204115999902],
              [30.64074707000009, -3.367428064999899],
              [30.640237809000098, -3.36700511],
              [30.638916015000177, -3.366794109999944],
              [30.638057709, -3.366183995999904],
              [30.637292861000162, -3.366043089999891],
              [30.636386871000184, -3.364137887999902],
              [30.635902405000024, -3.363228082999967],
              [30.635129929000072, -3.36188006499998],
              [30.63441658100004, -3.361505031999911],
              [30.634624481000174, -3.360917091999966],
              [30.634786606000034, -3.360047100999907],
              [30.635713577000047, -3.359056949999967],
              [30.636314392000145, -3.358186006999915],
              [30.637359619000165, -3.35649299499994],
              [30.63826370200013, -3.355128050999952],
              [30.638679504000038, -3.353692053999964],
              [30.639839173000155, -3.352891920999866],
              [30.64046478200015, -3.35202193299989],
              [30.640834809000125, -3.351387977999934],
              [30.64180946300013, -3.350584982999862],
              [30.643199920000143, -3.34985589899992],
              [30.643804551000187, -3.350084066999955],
              [30.644523620000086, -3.350083111999879],
              [30.644916535000164, -3.349400996999862],
              [30.645509721000053, -3.349437951999903],
              [30.646215439000173, -3.34893107299996],
              [30.646909714000174, -3.348552942999959],
              [30.64714050200007, -3.347964047999881],
              [30.648000718000162, -3.347681998999917],
              [30.648530960000187, -3.344835042999819],
              [30.64901924100019, -3.343987940999966],
              [30.649599075000026, -3.343775986999958],
              [30.649969100000135, -3.343421934999867],
              [30.650619508000148, -3.342480897999963],
              [30.651824952000027, -3.341115951999939],
              [30.652421952000054, -3.340256928999906],
              [30.652914048000127, -3.339638947999958],
              [30.655002593000177, -3.338279961999945],
              [30.65565872200017, -3.337826966999955],
              [30.655660629000067, -3.337199925999926],
              [30.65676498300013, -3.33598995199992],
              [30.65718841600011, -3.335042951999981],
              [30.65757751400014, -3.334748982999827],
              [30.658260346000134, -3.334683894999955],
              [30.659496306999984, -3.333703993999961],
              [30.66050338800011, -3.332624910999925],
              [30.66099166900011, -3.332005976999881],
              [30.661121368000067, -3.331221102999962],
              [30.660959245000072, -3.329718112999956],
              [30.661544800000115, -3.328214883999976],
              [30.662584306000156, -3.328345060999936],
              [30.664339066000025, -3.324554920999958],
              [30.665380477000156, -3.323770998999919],
              [30.665740966000044, -3.322865961999924],
              [30.66645240700018, -3.322007893999967],
              [30.66692733700006, -3.319859027999883],
              [30.667438506000167, -3.319458006999923],
              [30.66926765400018, -3.319931028999974],
              [30.670801163000135, -3.319894074999979],
              [30.674566270000184, -3.320293902999936],
              [30.675006866000047, -3.319690942999955],
              [30.676467896000133, -3.319691895999938],
              [30.678478241000107, -3.318490028999975],
              [30.681001664000064, -3.318670987999838],
              [30.685386657000095, -3.317285059999904],
              [30.689079285000048, -3.315608978999876],
              [30.690139770000144, -3.314990042999909],
              [30.69145584100005, -3.314953087999868],
              [30.699277878000032, -3.311602115999904],
              [30.700189591000083, -3.311455011999897],
              [30.701324464000095, -3.311163902999965],
              [30.70271301400004, -3.309890030999895],
              [30.70388221600018, -3.310326098999951],
              [30.706113816000084, -3.31010699299992],
              [30.707464219000144, -3.309597014999895],
              [30.708744049000188, -3.308904884999947],
              [30.709817887000042, -3.307239054999968],
              [30.71131515500008, -3.306910990999882],
              [30.71190071200016, -3.306292056999951],
              [30.714387894000026, -3.30428910299986],
              [30.716251373000148, -3.304251908999959],
              [30.718992234000154, -3.303414104999945],
              [30.71961402800008, -3.302902935999896],
              [30.721130372000175, -3.301979064999955],
              [30.722690582000098, -3.29910397499998],
              [30.723129273000097, -3.29627609299996],
              [30.72353553700009, -3.295150040999886],
              [30.724836350000032, -3.294333934999941],
              [30.72534179700017, -3.293087958999877],
              [30.72600555500003, -3.29215097499997],
              [30.726846695000177, -3.291275978999977],
              [30.727138519000164, -3.29047489099986],
              [30.728197098000066, -3.289563893999969],
              [30.729587556000183, -3.288544892999937],
              [30.73018455600004, -3.286989927999969],
              [30.73051261900008, -3.284589052999934],
              [30.730073929000184, -3.283204077999926],
              [30.730474472000026, -3.281493902999898],
              [30.730548858000134, -3.279927968999914],
              [30.73025703500008, -3.279201029999911],
              [30.73065757800009, -3.276761053999906],
              [30.730293274000132, -3.275450944999932],
              [30.730327606000117, -3.27446794399998],
              [30.73127937300012, -3.273593902999949],
              [30.733617782000067, -3.272682904999897],
              [30.735336303000054, -3.271735905999947],
              [30.736944198000117, -3.270315883999899],
              [30.738260270000126, -3.268568038999945],
              [30.739976883000168, -3.267765998999948],
              [30.742025376000072, -3.268094062999978],
              [30.742828369000165, -3.267838954999945],
              [30.743852614000104, -3.268165110999917],
              [30.744472503000168, -3.268930911999973],
              [30.74451065, -3.270385026999918],
              [30.74476814200017, -3.270895958999915],
              [30.745937347, -3.274207113999978],
              [30.745609284000125, -3.275010108999879],
              [30.745790482000018, -3.275737047999826],
              [30.745864868000126, -3.277484892999951],
              [30.74557113700007, -3.27977705],
              [30.745645523, -3.281707047999873],
              [30.747072220000064, -3.284909962999848],
              [30.74758338900017, -3.286693095999965],
              [30.748754501000064, -3.287673949999942],
              [30.75189590399998, -3.289604902999884],
              [30.754676819000167, -3.291749953999954],
              [30.756174087000034, -3.292331932999957],
              [30.757709503000058, -3.29258704199998],
              [30.76286316000005, -3.291565894999962],
              [30.76385116600011, -3.290802001999907],
              [30.764509202, -3.289854049999917],
              [30.765933991000054, -3.28836107199993],
              [30.769077301000095, -3.286648988999957],
              [30.77353668300009, -3.286102055999891],
              [30.77441406300005, -3.285773992999907],
              [30.77547454800009, -3.284754990999886],
              [30.77660751400009, -3.284172057999967],
              [30.777961730000186, -3.283660888999975],
              [30.779714585000193, -3.282205103999957],
              [30.782127380000077, -3.282859086999963],
              [30.783296585000073, -3.281877040999973],
              [30.784576415000117, -3.281111001999932],
              [30.787647247000166, -3.280092],
              [30.789438248000124, -3.27979898399991],
              [30.79386139000019, -3.279725074999931],
              [30.798015595000095, -3.279037952999829],
              [30.80127716000004, -3.274537086999942],
              [30.802610397000137, -3.273329019999892],
              [30.807407380000086, -3.272039889999974],
              [30.811056138000026, -3.270649909999975],
              [30.816831589000117, -3.266433954999968],
              [30.82036018400015, -3.266028880999841],
              [30.82511329700003, -3.265408038999965],
              [30.827465057000097, -3.264414071999966],
              [30.829259872000193, -3.263623952999978],
              [30.831623077000074, -3.261734961999935],
              [30.83302497800014, -3.260111092999921],
              [30.834117889000083, -3.259452104999923],
              [30.834730148, -3.258836983999856],
              [30.838581085000044, -3.257431029999964],
              [30.84055137600012, -3.256069898999954],
              [30.84278106700009, -3.254050016999884],
              [30.842651366000155, -3.253391980999822],
              [30.839075089000062, -3.253319024999826],
              [30.837284087000057, -3.253174065999929],
              [30.832489013000156, -3.250977992999935],
              [30.831903458000113, -3.250433921999957],
              [30.831464767000114, -3.249742029999879],
              [30.83066177400019, -3.249087093999947],
              [30.825927735000107, -3.246742009999935],
              [30.825050354000098, -3.245397090999916],
              [30.82256507800014, -3.241137981999941],
              [30.821004867000113, -3.240899085999899],
              [30.82052993700006, -3.240025041999957],
              [30.821222305000163, -3.238385914999981],
              [30.82089233400012, -3.237622976999887],
              [30.81906509400011, -3.235984087999952],
              [30.81840896500006, -3.234638929999903],
              [30.81755066000005, -3.232165098999872],
              [30.814516068000103, -3.227396010999883],
              [30.813419342000145, -3.224230051999939],
              [30.815324783000165, -3.220585107999909],
              [30.81554412899999, -3.219018934999895],
              [30.81616592500012, -3.21847295699996],
              [30.817554473000087, -3.218218087999901],
              [30.81893348700015, -3.216633080999941],
              [30.820814133000113, -3.21355891199994],
              [30.822877885000025, -3.214083908999953],
              [30.825033188000077, -3.214373111999976],
              [30.82781219600014, -3.214446067999973],
              [30.829172135000135, -3.212989092999976],
              [30.834861756000066, -3.210397958999977],
              [30.841337205, -3.207456110999942],
              [30.843383788000153, -3.207232952999959],
              [30.845102311000176, -3.207631111999945],
              [30.846380234000094, -3.207741974999976],
              [30.847879409000086, -3.207595109999886],
              [30.848165512000037, -3.207060097999943],
              [30.84650230400007, -3.205918073999953],
              [30.843875886000035, -3.202491997999971],
              [30.84116363400011, -3.198451041999931],
              [30.83827590900006, -3.192698954999969],
              [30.83945655900004, -3.188658952999958],
              [30.839195251999968, -3.186419962999878],
              [30.840112687000158, -3.183696029999908],
              [30.841909409000095, -3.179303883999864],
              [30.843482970000025, -3.176405905999957],
              [30.843336106000095, -3.173202037999943],
              [30.84384727500003, -3.171564101999934],
              [30.84666061400003, -3.165266036999924],
              [30.84666252200003, -3.164355993999948],
              [30.845819472000073, -3.162609099999827],
              [30.841468811000084, -3.157082080999942],
              [30.84154128900019, -3.155663013999913],
              [30.840919495000094, -3.154206037999927],
              [30.839239121000162, -3.151912927999888],
              [30.83814430300015, -3.149729965999939],
              [30.8376312260001, -3.148421049999968],
              [30.836753845000032, -3.148093938999921],
              [30.836204529000042, -3.147511004999899],
              [30.83420562800012, -3.143117903999951],
              [30.83142852800006, -3.144431114999975],
              [30.830331802000103, -3.145013092999932],
              [30.82916259700005, -3.145231007999826],
              [30.821380615000123, -3.15033388199987],
              [30.82028007499997, -3.149985074999961],
              [30.81903648500014, -3.149184942999966],
              [30.818195343000184, -3.147911070999953],
              [30.817865372000142, -3.146820066999965],
              [30.81801223700012, -3.145581960999891],
              [30.818450927000185, -3.144963024999925],
              [30.822608947000106, -3.142152070999885],
              [30.823789597000143, -3.140702007999892],
              [30.825366974000133, -3.139296054999932],
              [30.830223084000124, -3.128933905999929],
              [30.829742431000057, -3.126517057999877],
              [30.83092307900006, -3.125202893999926],
              [30.830961228000092, -3.124366044999874],
              [30.832059860000072, -3.122253893999925],
              [30.832239151000067, -3.121526001999939],
              [30.83193016100006, -3.119184016999952],
              [30.832717896000133, -3.116535900999963],
              [30.83351898100011, -3.115592003999836],
              [30.834030152000025, -3.1145720479999],
              [30.832674026000063, -3.112684964999914],
              [30.83232307500009, -3.111675022999918],
              [30.832803727000055, -3.109874010999931],
              [30.833286285000042, -3.108732937999946],
              [30.83311080900006, -3.10772108999987],
              [30.83232307500009, -3.106228112999929],
              [30.83227920500019, -3.104779958999814],
              [30.832412719000047, -3.103549956999927],
              [30.833112717000063, -3.101353883999934],
              [30.8343811040001, -3.09946608599995],
              [30.8359985350001, -3.09806108499987],
              [30.836397171000044, -3.096524952999971],
              [30.836471557000152, -3.094594954999877],
              [30.835374833000174, -3.092648028999918],
              [30.834133147000102, -3.090393065999933],
              [30.833766937000178, -3.088516949999871],
              [30.82971191500002, -3.084013938999874],
              [30.828321457000072, -3.083723067999813],
              [30.820917130000055, -3.082614898999964],
              [30.819328308000024, -3.082506895999813],
              [30.816186904000176, -3.08183407699994],
              [30.81391906700003, -3.081253051999966],
              [30.812931061000143, -3.079068899999925],
              [30.81165122900012, -3.077667951999899],
              [30.810501100000124, -3.076648950999868],
              [30.810682296000152, -3.075429916999838],
              [30.810409546000187, -3.073883056999932],
              [30.809312821000105, -3.072208880999938],
              [30.80790329000007, -3.071008919999883],
              [30.805955887000152, -3.070414066999945],
              [30.804382323000027, -3.06995892499998],
              [30.802871705000086, -3.069118975999913],
              [30.80208587600015, -3.068196057999955],
              [30.801725388000193, -3.0645480149999],
              [30.801542282000128, -3.063673972999879],
              [30.799457550000113, -3.061125993999895],
              [30.79526138300008, -3.054236888999924],
              [30.801515579000124, -3.051511048999885],
              [30.803485871000078, -3.0501480099999],
              [30.804471970000066, -3.048942087999876],
              [30.805366517000095, -3.047776935999934],
              [30.808181763000107, -3.046061037999891],
              [30.810266494000075, -3.046113967999929],
              [30.811143875000084, -3.045857905999981],
              [30.813375472000075, -3.044219969999972],
              [30.814905166000187, -3.04290199299993],
              [30.81639480600012, -3.039783953999915],
              [30.816963195000028, -3.035042046999877],
              [30.817235948000075, -3.034321069999919],
              [30.818073272000106, -3.032901048999861],
              [30.818277359000035, -3.0318090909999],
              [30.818275452000137, -3.030297994999955],
              [30.816963195000028, -3.028191088999961],
              [30.818008424000084, -3.026546953999912],
              [30.818738938000138, -3.025819061999926],
              [30.8208751680001, -3.025290011999914],
              [30.821607590000156, -3.024597882999956],
              [30.8223018650001, -3.023578881999924],
              [30.82326507500005, -3.020987987999945],
              [30.823568344000023, -3.018291949999821],
              [30.82515907400017, -3.016690014999881],
              [30.825944901000128, -3.015325069999904],
              [30.82630920300005, -3.014233111999886],
              [30.82608986000008, -3.011921882999957],
              [30.826583863000053, -3.011084078999943],
              [30.82928848299997, -3.008754014999965],
              [30.83109855700019, -3.007241963999832],
              [30.83195686300013, -3.006192921999968],
              [30.83256912200011, -3.005635975999894],
              [30.83348464900007, -3.004961966999929],
              [30.834306718000164, -3.003961085999947],
              [30.83494567800011, -3.002614021999875],
              [30.83527565100013, -3.001575945999889],
              [30.838216781000142, -2.998244999999884],
              [30.83794212300012, -2.997662067999954],
              [30.837303161000193, -2.997189997999897],
              [30.837247848000175, -2.996351003999962],
              [30.838581085000044, -2.995076893999965],
              [30.838014602000158, -2.993628023999975],
              [30.839160920000154, -2.991858005999973],
              [30.84053039500003, -2.989059924999935],
              [30.842676163000135, -2.989059924999935],
              [30.843498231000126, -2.988646982999967],
              [30.844139099000017, -2.988050936999912],
              [30.844768523000084, -2.985491036999974],
              [30.845741271000065, -2.983442068999977],
              [30.847011566000106, -2.98216795899998],
              [30.84850502000006, -2.981491087999928],
              [30.84920311100018, -2.980967044999829],
              [30.84987640500009, -2.980592012999978],
              [30.85022544900005, -2.97951698299994],
              [30.84900474599999, -2.978518962999942],
              [30.847633362000067, -2.97794198899993],
              [30.84683609000018, -2.978193044999898],
              [30.84621429400005, -2.979017971999951],
              [30.84477043100003, -2.977567910999937],
              [30.842454910000015, -2.97886800699996],
              [30.839738845000113, -2.975817918999951],
              [30.838302612, -2.975378035999938],
              [30.83687400800011, -2.975617884999906],
              [30.83510589500014, -2.976991891999887],
              [30.832614899000077, -2.976643084999978],
              [30.83156967200017, -2.977518080999914],
              [30.831319808000103, -2.978492974999938],
              [30.830770492000113, -2.978193044999898],
              [30.829576491000182, -2.978142975999845],
              [30.827009201000067, -2.980015992999938],
              [30.826337814, -2.980149029999893],
              [30.82145118799997, -2.980561018999879],
              [30.81848335400008, -2.981523990999904],
              [30.8158359520001, -2.980881928999963],
              [30.814237595000066, -2.981249093999963],
              [30.810354233000112, -2.98519301399989],
              [30.80884742700016, -2.98528408999988],
              [30.807796478000114, -2.985881090999897],
              [30.80606269900011, -2.987761020999926],
              [30.803825379000102, -2.986752033999949],
              [30.802865982000014, -2.98666000399993],
              [30.800901413000133, -2.988310098999875],
              [30.796747208000056, -2.98881602299997],
              [30.78966903600002, -2.989640951999945],
              [30.78469085800009, -2.989917039999966],
              [30.77985191400012, -2.991612911999937],
              [30.7791671760001, -2.992897986999878],
              [30.778251648000037, -2.992943048999962],
              [30.776380539, -2.993906021999976],
              [30.773731232000102, -2.993767976999891],
              [30.77304649400014, -2.994044065999844],
              [30.771722793000094, -2.994868993999887],
              [30.76834106399997, -2.994364975999929],
              [30.765419007000048, -2.992850064999971],
              [30.756286622, -2.994961023999963],
              [30.75455284100019, -2.99486994699987],
              [30.75341033899997, -2.99601602599995],
              [30.75190353400012, -2.996153116999892],
              [30.74962043700009, -2.996244906999948],
              [30.747791291000112, -2.997208118999879],
              [30.74651336600016, -2.996701954999935],
              [30.744869232000042, -2.996748924999906],
              [30.743774413000097, -2.996198891999882],
              [30.742267610000056, -2.993767976999891],
              [30.740669250000167, -2.992208956999889],
              [30.738431930000104, -2.990741967999895],
              [30.735874175000163, -2.990926026999944],
              [30.731809617000124, -2.989136932999884],
              [30.729845048000186, -2.988998888999959],
              [30.727926255000114, -2.98922896299996],
              [30.72665023800016, -2.989871024999957],
              [30.726421357000163, -2.989090918999978],
              [30.724458695000123, -2.987118958999929],
              [30.7213058480001, -2.983360051999853],
              [30.71975326500018, -2.984230040999876],
              [30.719022751000125, -2.983908890999942],
              [30.717197418000183, -2.982120036999902],
              [30.714410782000186, -2.980240105999883],
              [30.715690612000174, -2.979644060999874],
              [30.715141296000013, -2.979094027999906],
              [30.714136124000163, -2.976799964999941],
              [30.712903976000177, -2.974736928999846],
              [30.709524155000054, -2.971204995999926],
              [30.70728683600015, -2.970012901999951],
              [30.70495986900005, -2.969599962999894],
              [30.703132629000038, -2.970104931999913],
              [30.698564529000066, -2.973315],
              [30.696054459000038, -2.972855089999939],
              [30.695415497000113, -2.973040103999949],
              [30.693542481000065, -2.973086118999959],
              [30.685825348000037, -2.978497026999889],
              [30.685825348000037, -2.980468986999938],
              [30.685504913000102, -2.981523990999904],
              [30.68463706900002, -2.98262596099994],
              [30.68262863300015, -2.983817100999829],
              [30.680847166999968, -2.985146998999824],
              [30.679431915000123, -2.987303017999864],
              [30.67835807800003, -2.988202094999906],
              [30.676965713000186, -2.989043950999928],
              [30.676372527000126, -2.989504097999884],
              [30.67271804799998, -2.992851971999869],
              [30.670389174000036, -2.993309973999942],
              [30.668653488000132, -2.992759942999896],
              [30.666187287000128, -2.990650891999849],
              [30.663539885999967, -2.987942934999978],
              [30.663402557000097, -2.986430883999901],
              [30.662397385000077, -2.985835075999887],
              [30.661392212000123, -2.985008954999955],
              [30.660890579000068, -2.983725069999935],
              [30.660434723000037, -2.982074021999949],
              [30.65874481100019, -2.980330942999956],
              [30.656553268000096, -2.978313922999973],
              [30.655502319000107, -2.977580068999885],
              [30.6532649990001, -2.976754902999915],
              [30.651622772, -2.97661590599995],
              [30.650022507000017, -2.975563049999892],
              [30.646186828, -2.968637942999976],
              [30.64591217000003, -2.965794086999949],
              [30.646095276000096, -2.964602945999957],
              [30.647464752000076, -2.960612058999971],
              [30.647144318000187, -2.957218884999975],
              [30.647237777000043, -2.954833983999947],
              [30.647418975000107, -2.953228950999971],
              [30.646780013000182, -2.952999114999898],
              [30.644678117000183, -2.951486110999952],
              [30.64248657200011, -2.950797080999962],
              [30.64093589900017, -2.949604987999976],
              [30.63664245600006, -2.944653033999884],
              [30.63422393700006, -2.941672086999972],
              [30.633171082000104, -2.939652918999968],
              [30.631801606000124, -2.937727926999969],
              [30.63052368100017, -2.936489105999954],
              [30.628240584000025, -2.935022114999924],
              [30.62664222700016, -2.934058904999972],
              [30.626047134000032, -2.932913063999934],
              [30.623809814000026, -2.929932116999908],
              [30.62221145700005, -2.928281068999922],
              [30.619882583000106, -2.926402090999886],
              [30.61764717000011, -2.925252914999931],
              [30.61595535300006, -2.925252914999931],
              [30.613079071000072, -2.925483942999961],
              [30.610111237000183, -2.923878907999949],
              [30.607645034000143, -2.921540020999942],
              [30.606275558000164, -2.920806883999944],
              [30.604448319000085, -2.920531033999964],
              [30.60230064400008, -2.921128033999878],
              [30.600063324000075, -2.922456978999946],
              [30.598693847000163, -2.920943021999847],
              [30.59873962400013, -2.919934987999909],
              [30.59773445200011, -2.916539906999958],
              [30.59613609200005, -2.913743972999896],
              [30.595542907000038, -2.913239956999917],
              [30.590017318000037, -2.912873029999958],
              [30.589195252000025, -2.9127340309999],
              [30.588558197000168, -2.912184],
              [30.587688446000072, -2.906038998999918],
              [30.587186813000017, -2.9053969389999],
              [30.585634232000075, -2.904755113999897],
              [30.583669662999966, -2.904341934999934],
              [30.582208634000153, -2.904664039999943],
              [30.581296922000035, -2.90370011199991],
              [30.581022262000033, -2.90223193099996],
              [30.58115959100013, -2.900902985999949],
              [30.581661224000186, -2.899663924999913],
              [30.57800865300004, -2.894114970999908],
              [30.577093125000147, -2.893290041999933],
              [30.57417106600019, -2.892829894999977],
              [30.57029151900008, -2.892923116999953],
              [30.566522597000187, -2.893927096999903],
              [30.56476211500018, -2.89491796599998],
              [30.564340592000065, -2.897814988999869],
              [30.56539535400009, -2.899866104999944],
              [30.561946868000064, -2.902550935999955],
              [30.55680656400017, -2.907357930999979],
              [30.553268433000085, -2.911312103999933],
              [30.548360823999985, -2.917820930999881],
              [30.546602249000102, -2.920367002999967],
              [30.54582786500015, -2.921001910999905],
              [30.545543671000075, -2.922274112999844],
              [30.543716431000064, -2.924958943999855],
              [30.542871476000016, -2.92552709499995],
              [30.542587280000134, -2.926021097999978],
              [30.542587280000134, -2.926939963999871],
              [30.539491653000084, -2.932665109999846],
              [30.538789749999978, -2.932806013999937],
              [30.536394120000182, -2.931323051999925],
              [30.5337905880001, -2.930332897999961],
              [30.531818043000044, -2.930404173999932],
              [30.530622482000126, -2.931180954999945],
              [30.52893447800011, -2.933585882999978],
              [30.527454376000037, -2.935422895999921],
              [30.526258468000037, -2.935775994999915],
              [30.526046753000173, -2.936695097999973],
              [30.52442741400006, -2.940017938999972],
              [30.522808054000166, -2.941643952999868],
              [30.520063400000083, -2.941714047999824],
              [30.51837348900017, -2.942421911999929],
              [30.51738739000018, -2.943411110999818],
              [30.51597935600006, -2.943411696999931],
              [30.512319564999984, -2.947370050999894],
              [30.511474610000164, -2.948570966999967],
              [30.509996414000057, -2.950126884999918],
              [30.508800623000184, -2.950974857999938],
              [30.50788688700004, -2.950196027999937],
              [30.50753212000012, -2.950196027999937],
              [30.506828308000138, -2.951611994999951],
              [30.505561845000045, -2.952954033999902],
              [30.504436224000074, -2.953165957999943],
              [30.502887725000164, -2.952883004999876],
              [30.50112427200014, -2.951013874999944],
              [30.49961667900004, -2.950752028999943],
              [30.498834610000074, -2.950867889999927],
              [30.49563789300015, -2.949984073999929],
              [30.49430084300019, -2.948501108999949],
              [30.493103027000018, -2.948147057999961],
              [30.49211883600003, -2.948430060999954],
              [30.490568161000112, -2.94765210199995],
              [30.48817634600016, -2.94765210199995],
              [30.487188000000117, -2.948288],
              [30.484655381000096, -2.946662902999947],
              [30.48324775700013, -2.946238994999931],
              [30.481981278000035, -2.944046972999956],
              [30.481277465000176, -2.941926002999935],
              [30.480995179000104, -2.939311027999963],
              [30.48078346200009, -2.938534021999942],
              [30.480009078000137, -2.937474011999939],
              [30.480363845000056, -2.935492991999979],
              [30.479938507000043, -2.934715985999958],
              [30.479236602000185, -2.934362888999885],
              [30.478168487000175, -2.934494972999971],
              [30.477346421000163, -2.933918952999932],
              [30.47615051200006, -2.932667969999898],
              [30.475429536000092, -2.932667016999915],
              [30.47463035600009, -2.932265995999956],
              [30.474256516000025, -2.931966066999905],
              [30.47405815200017, -2.929713010999933],
              [30.47385978700015, -2.929037093999966],
              [30.473110200000065, -2.927834987999972],
              [30.47181510900009, -2.926660060999893],
              [30.470943451000096, -2.926557062999905],
              [30.468976974000043, -2.927634953999927],
              [30.46832847600018, -2.927584885999977],
              [30.470098496000105, -2.926157951999926],
              [30.47012138500014, -2.925457000999927],
              [30.469375610000156, -2.924230098999942],
              [30.467905044000133, -2.923855066999977],
              [30.46693420299999, -2.923429011999929],
              [30.465913773000125, -2.922502040999973],
              [30.46466827400019, -2.921951054999852],
              [30.46399307300004, -2.922251938999978],
              [30.463222504000157, -2.923629045999974],
              [30.462675094000133, -2.924055098999929],
              [30.461977006000097, -2.924406051999938],
              [30.461179733000108, -2.924329995999926],
              [30.46063232500012, -2.924104927999963],
              [30.45893859800009, -2.922777890999953],
              [30.458316804000106, -2.922000884999875],
              [30.458290100000056, -2.92125105699995],
              [30.459037781000177, -2.921175955999843],
              [30.459884643000066, -2.921502113999907],
              [30.460657119000075, -2.921427010999935],
              [30.4620265960001, -2.921101092999948],
              [30.463594437000097, -2.920875071999888],
              [30.46409416200015, -2.920599936999963],
              [30.464569091999977, -2.919924974999901],
              [30.464492798000094, -2.919147968999823],
              [30.463695526000038, -2.918121097999858],
              [30.46357154800006, -2.917119025999966],
              [30.463521958000058, -2.915918111999929],
              [30.463346482000077, -2.914916037999944],
              [30.462675094000133, -2.914691924999886],
              [30.46165466300016, -2.913964985999883],
              [30.460756301000117, -2.913188933999891],
              [30.45996093800011, -2.912286996999967],
              [30.459186555000088, -2.911134004999951],
              [30.45846557599998, -2.910383939999974],
              [30.45804023600016, -2.909583090999945],
              [30.457393646000128, -2.909282921999932],
              [30.45659637400007, -2.909257887999956],
              [30.455774307000183, -2.910259006999922],
              [30.45480537500009, -2.910609960999921],
              [30.45346069400017, -2.910759925999969],
              [30.452512742000067, -2.910960911999837],
              [30.4518394480001, -2.9116609099999],
              [30.451042176000044, -2.913012027999969],
              [30.450595855000188, -2.913388013999906],
              [30.450170517000117, -2.913162945999943],
              [30.450023651000095, -2.912137029999883],
              [30.44999885500016, -2.911261081999896],
              [30.449848175000113, -2.910358906999932],
              [30.4494228370001, -2.909558056999913],
              [30.446708680000143, -2.907906054999955],
              [30.446161270000175, -2.908008098999858],
              [30.445463180000104, -2.908756971999935],
              [30.444889068000066, -2.909033059999842],
              [30.44429206900014, -2.908657073999905],
              [30.44441986100003, -2.907955883999875],
              [30.44429206900014, -2.907079934999956],
              [30.443546295000033, -2.905025958999943],
              [30.443048476000172, -2.904501914999912],
              [30.44229888900003, -2.904274938999947],
              [30.440929412000173, -2.904725074999931],
              [30.44065666200015, -2.9042010309999],
              [30.440034867000122, -2.903850078999881],
              [30.439285278, -2.903599022999913],
              [30.438463211000112, -2.903698921999933],
              [30.43805122400005, -2.90308904599982],
              [30.438215255000046, -2.902548073999981],
              [30.43853950500005, -2.901746032999881],
              [30.438938141000165, -2.90114498],
              [30.43943595900015, -2.900544881999963],
              [30.439533234000123, -2.898942946999966],
              [30.438638687000093, -2.898015975999897],
              [30.438140869000108, -2.896914003999939],
              [30.437543867999977, -2.896188019999897],
              [30.43654632600004, -2.895787953999957],
              [30.43726921000018, -2.895361898999965],
              [30.43769264300016, -2.894985913999847],
              [30.438091279000105, -2.894109964999927],
              [30.438116073000174, -2.892757891999963],
              [30.43769264300016, -2.89183211299985],
              [30.43714332700017, -2.889477967999937],
              [30.436819077000166, -2.888376951999874],
              [30.437118531000067, -2.887525081999911],
              [30.43746757499997, -2.886023043999956],
              [30.437492371000076, -2.885297059999857],
              [30.437244414000077, -2.884419918999924],
              [30.436296461999973, -2.883568048999962],
              [30.43517685000006, -2.882917880999912],
              [30.43440437400011, -2.881942032999973],
              [30.434230805000027, -2.880939960999967],
              [30.434553146000155, -2.879437922999841],
              [30.435251236, -2.878587007999954],
              [30.43600082400002, -2.877284048999968],
              [30.436098098000116, -2.876158951999969],
              [30.43507766800002, -2.874656915999935],
              [30.434303284000123, -2.87413096399996],
              [30.432958603000145, -2.874330995999969],
              [30.432336807000183, -2.873980044999883],
              [30.43131637600004, -2.872378109999943],
              [30.43054390000009, -2.871726988999967],
              [30.429248810000047, -2.870901107999941],
              [30.427976608000108, -2.870023011999933],
              [30.42675781300011, -2.869072913999958],
              [30.426084519000142, -2.869048117999853],
              [30.425437928, -2.86932301399986],
              [30.42369461200019, -2.871076105999975],
              [30.422325135000165, -2.871850966999944],
              [30.42167472900013, -2.872179029999927],
              [30.42055511500007, -2.873203992999891],
              [30.419309616000135, -2.874206064999953],
              [30.418712616000107, -2.874531029999901],
              [30.4180908190001, -2.874305962999927],
              [30.417865754000047, -2.873830079999891],
              [30.417940140000155, -2.87275290499997],
              [30.417791367000177, -2.871776103999878],
              [30.417068481000058, -2.870774983999979],
              [30.416669845000115, -2.869800090999888],
              [30.415822983000055, -2.869072913999958],
              [30.41502761800001, -2.869422912999937],
              [30.4146022810001, -2.869223116999933],
              [30.414253234000114, -2.868721960999949],
              [30.413904190000153, -2.868021010999939],
              [30.41368103000019, -2.8673200599999],
              [30.41350746200004, -2.866344928999922],
              [30.413255692000178, -2.865542888999926],
              [30.41238594000015, -2.864943025999935],
              [30.413282394000078, -2.864867924999942],
              [30.414203644000054, -2.864841938999973],
              [30.415000915000064, -2.864866971999845],
              [30.41554832500003, -2.864542006999955],
              [30.415948869000147, -2.864166021999949],
              [30.41587257300006, -2.863665103999949],
              [30.415573121000136, -2.862914085999933],
              [30.415002822000133, -2.862011908999875],
              [30.415573121000136, -2.861561059999872],
              [30.416072845000087, -2.860886095999888],
              [30.416021347000083, -2.860409973999936],
              [30.415821075000053, -2.859735011999931],
              [30.414951325, -2.859283923999954],
              [30.413829804000045, -2.859257936999825],
              [30.412635804000047, -2.85948300299998],
              [30.412160874000165, -2.860183952999876],
              [30.411788941000054, -2.860860109999976],
              [30.41091537500006, -2.860534906999874],
              [30.410242081000092, -2.860534906999874],
              [30.409271241000056, -2.861236094999811],
              [30.409868241000083, -2.859808920999853],
              [30.409620285000187, -2.85918307399993],
              [30.40899848800018, -2.858831881999834],
              [30.408226012, -2.858983038999895],
              [30.407503128000087, -2.859359026999925],
              [30.406930924000164, -2.860685108999974],
              [30.40618324400009, -2.860835075999944],
              [30.406507492000117, -2.86003398899993],
              [30.40737724200011, -2.858582019999858],
              [30.408027650000122, -2.858105897999963],
              [30.40984535300015, -2.857404947999896],
              [30.410642623000058, -2.856803894999814],
              [30.411363602000108, -2.85557794499988],
              [30.41250991800007, -2.854726074999917],
              [30.413133621999975, -2.853925942999979],
              [30.413555144000043, -2.851721048999877],
              [30.41385459800017, -2.851171015999967],
              [30.415191651000043, -2.851914882999949],
              [30.415821075000053, -2.851871965999862],
              [30.416271209000172, -2.851421117999962],
              [30.416296005000106, -2.850394963999975],
              [30.41622161900017, -2.849818944999925],
              [30.415773391000187, -2.849143027999844],
              [30.413555144000043, -2.848090886999898],
              [30.413406372000168, -2.847491025999943],
              [30.413780212, -2.846965073999854],
              [30.415100097000106, -2.846013068999866],
              [30.41544914299999, -2.845412968999938],
              [30.415649415000075, -2.844536064999943],
              [30.41527557500001, -2.843836068999963],
              [30.415376662000142, -2.843410014999961],
              [30.416246415000046, -2.843610048999892],
              [30.41689491300008, -2.84343504799989],
              [30.417316438000057, -2.842735052999956],
              [30.41746711700017, -2.84343504799989],
              [30.418064118000075, -2.843786001999888],
              [30.41858863800013, -2.843885898999872],
              [30.419233323000128, -2.843660115999967],
              [30.41963386600014, -2.843183993999901],
              [30.42055320700007, -2.841607092999823],
              [30.420579911000175, -2.840857029999938],
              [30.420156479000127, -2.839729069999862],
              [30.420780183000034, -2.84055495299998],
              [30.421352387000127, -2.840882062999981],
              [30.422073364000028, -2.840605972999924],
              [30.42247390700004, -2.840154886999926],
              [30.42259788500013, -2.83912801699995],
              [30.422523499000192, -2.838829039999894],
              [30.42205047700014, -2.837774990999833],
              [30.42160224899999, -2.837025879999942],
              [30.421352387000127, -2.836399077999943],
              [30.42222404500012, -2.836298941999928],
              [30.422796250000147, -2.835973977999913],
              [30.42294692900009, -2.835474014999875],
              [30.42294692900009, -2.834570884999948],
              [30.42200088500016, -2.833319901999971],
              [30.42170143100003, -2.832545042999925],
              [30.422325135000165, -2.83249306599987],
              [30.423892976000104, -2.832618950999972],
              [30.42476463200012, -2.832544087999906],
              [30.42558670000011, -2.83219289699997],
              [30.42603492700016, -2.831666946999974],
              [30.426010133000034, -2.83099103],
              [30.425437928, -2.828737973999864],
              [30.424640656000122, -2.827359914999875],
              [30.42650794900004, -2.825907944999926],
              [30.426956177000136, -2.824656962999882],
              [30.427206039000055, -2.823004005999962],
              [30.427480697000192, -2.822679042999937],
              [30.42782783500013, -2.821926117999965],
              [30.428028107000046, -2.820826053999895],
              [30.427928925000174, -2.819549083999959],
              [30.427577972000165, -2.818547009999975],
              [30.426881791000028, -2.817521093999915],
              [30.425661087000094, -2.817044973999941],
              [30.424989700000083, -2.817169904999901],
              [30.424190520000025, -2.817796944999827],
              [30.422950744000104, -2.819818258999931],
              [30.42386818000017, -2.818022011999972],
              [30.425088882000125, -2.816771030999917],
              [30.42700767500014, -2.815367937999952],
              [30.428178787000036, -2.813915014999964],
              [30.428352355000015, -2.813438892999841],
              [30.42825126600013, -2.812539099999981],
              [30.42820358300014, -2.81158590299998],
              [30.42837715100012, -2.811238049999929],
              [30.428949356000146, -2.811011075999943],
              [30.430095672000107, -2.810935972999971],
              [30.43066787600003, -2.811762093999903],
              [30.430818558000055, -2.812613963999979],
              [30.4312152870001, -2.813062904999924],
              [30.43211364700005, -2.813189982999916],
              [30.43283462500017, -2.812813995999932],
              [30.43338394100016, -2.81173610799982],
              [30.43338394100016, -2.810710906999816],
              [30.433034897000027, -2.809108971999876],
              [30.43330955500005, -2.808657883999956],
              [30.434106827000107, -2.808907984999962],
              [30.434680938999975, -2.808907984999962],
              [30.435251236, -2.808506965999868],
              [30.435575486, -2.807531117999872],
              [30.434255599000153, -2.804750917999968],
              [30.435401916000046, -2.804301023999869],
              [30.43597412200012, -2.803775071999951],
              [30.43594932600007, -2.802824019999946],
              [30.43567657600005, -2.80212306999988],
              [30.43415641700011, -2.801847934999955],
              [30.433532715000183, -2.801597117999961],
              [30.434181213000045, -2.801223039999911],
              [30.435026168000036, -2.800872086999959],
              [30.43662071199998, -2.800720928999965],
              [30.437244414000077, -2.801121949999924],
              [30.43741798500014, -2.801821945999905],
              [30.43704605, -2.802649974999952],
              [30.436969756999986, -2.803224086999933],
              [30.437244414000077, -2.803875921999975],
              [30.437992097000063, -2.80400109299984],
              [30.438638687000093, -2.803801058999966],
              [30.43921279900013, -2.803174971999965],
              [30.43980979900016, -2.802073001999929],
              [30.439834595000093, -2.801198005999879],
              [30.440231324000138, -2.796763895999845],
              [30.440607072000148, -2.796314],
              [30.441703796000127, -2.795888899999909],
              [30.44222640999999, -2.795337914999891],
              [30.442722321000076, -2.794387101999917],
              [30.442897797000057, -2.793235062999884],
              [30.44284820500019, -2.792535065999971],
              [30.44227409300015, -2.791481970999882],
              [30.44150352400004, -2.790656089999857],
              [30.441429138000103, -2.790355920999957],
              [30.44177627600004, -2.78975391299997],
              [30.443471909000095, -2.787750958999823],
              [30.443645477000075, -2.786951063999879],
              [30.442798614000083, -2.785722016999898],
              [30.442724228000145, -2.785123110999905],
              [30.444915771000012, -2.783519982999962],
              [30.445489883000107, -2.782968996999955],
              [30.44568824700019, -2.782141923999973],
              [30.44558715800008, -2.781517028999872],
              [30.444465638000167, -2.780263900999955],
              [30.443595887000072, -2.779162883999959],
              [30.443122864000088, -2.777862070999959],
              [30.443323136000174, -2.777260064999894],
              [30.446510316000058, -2.772478102999912],
              [30.446933746000127, -2.77130198499998],
              [30.447008132000065, -2.770401000999925],
              [30.447830200000055, -2.769349098999953],
              [30.447921752000127, -2.7686159599999],
              [30.44761467000012, -2.767949103999911],
              [30.44717025700004, -2.766082047999873],
              [30.446241378000025, -2.765059947999873],
              [30.444915771000012, -2.764569997999843],
              [30.444606782000164, -2.764170883999952],
              [30.44442939800001, -2.763369083999976],
              [30.44442939800001, -2.762612103999913],
              [30.44420814500012, -2.761857985999882],
              [30.443544387000088, -2.761502026999949],
              [30.442352296000138, -2.76114606799996],
              [30.440141677999975, -2.760123013999873],
              [30.439123155000175, -2.759500979999928],
              [30.437620162000087, -2.757632016999821],
              [30.437047958000164, -2.756699083999877],
              [30.437047958000164, -2.755676983999877],
              [30.437620162000087, -2.755321026999923],
              [30.44000816400012, -2.755053996999948],
              [30.441688538000108, -2.754343032999827],
              [30.44252967900013, -2.752964018999933],
              [30.442426682000075, -2.751858949999928],
              [30.44172286900016, -2.749500035999972],
              [30.441768646000128, -2.747942923999972],
              [30.440736770000058, -2.74638700499986],
              [30.440696716000105, -2.745742082999868],
              [30.440969466000126, -2.745193004999919],
              [30.44279479900007, -2.745193004999919],
              [30.444480896000186, -2.745512961999907],
              [30.449588775000166, -2.743221044999928],
              [30.450225830000193, -2.742902039999819],
              [30.452550889000122, -2.742625951999969],
              [30.454374314000063, -2.742259024999896],
              [30.455102921000048, -2.742077110999958],
              [30.455877305000172, -2.740839002999906],
              [30.45688056900002, -2.73941898399994],
              [30.458251954000104, -2.739464998999893],
              [30.45975494400011, -2.738136053999938],
              [30.461030959000084, -2.737447976999931],
              [30.46157836900005, -2.736212968999951],
              [30.462444305000133, -2.735479116999898],
              [30.463766097000075, -2.735846041999878],
              [30.464450837000072, -2.735754012999905],
              [30.46536445700019, -2.733510016999958],
              [30.466184616000078, -2.732455967999954],
              [30.46595764200015, -2.731173992999857],
              [30.464725494000163, -2.730530977999933],
              [30.46358490099999, -2.729796885999974],
              [30.46294593800019, -2.728562115999921],
              [30.462671281000098, -2.726087091999943],
              [30.46271705600003, -2.725307940999869],
              [30.463264466000055, -2.724806069999943],
              [30.46408653200001, -2.725583075999907],
              [30.464860916000134, -2.725627897999971],
              [30.464403152000102, -2.723750114999973],
              [30.466001512000048, -2.724666117999959],
              [30.466684341000132, -2.724483966999912],
              [30.4669589990001, -2.722239016999879],
              [30.46636772100004, -2.719764947999977],
              [30.46732330400016, -2.719537019999962],
              [30.468736648, -2.720268011999963],
              [30.469558716, -2.720222949999879],
              [30.469831466000187, -2.719672918999947],
              [30.469831466000187, -2.718847989999972],
              [30.471017837000034, -2.719856976999893],
              [30.472703933000105, -2.718986033999954],
              [30.473798752000164, -2.718116045999977],
              [30.474163054000144, -2.716419933999816],
              [30.475484849000168, -2.715733051999905],
              [30.47726249700014, -2.715917109999964],
              [30.478767395000148, -2.716878890999965],
              [30.481958389000056, -2.72132301399995],
              [30.48246192800002, -2.722285030999956],
              [30.483131409000123, -2.722910642999864],
              [30.484056473000066, -2.722970962999966],
              [30.485059737000142, -2.721872091999899],
              [30.485652923000032, -2.720679997999866],
              [30.486883163000186, -2.720268011999963],
              [30.48847961500013, -2.719444035999914],
              [30.49044036900017, -2.716741083999921],
              [30.49135208200005, -2.715275047999853],
              [30.492582322000032, -2.714909075999969],
              [30.49335861100002, -2.715503930999944],
              [30.49395179700008, -2.715045928999814],
              [30.494770050000056, -2.713442086999976],
              [30.49513626200013, -2.712800978999951],
              [30.49805450399998, -2.712114094999947],
              [30.49841880800011, -2.711426972999959],
              [30.497781753000083, -2.709501981999949],
              [30.49800872900005, -2.708447932999945],
              [30.50129127500003, -2.705105065999931],
              [30.502204896000023, -2.704552887999967],
              [30.503845215000126, -2.703912972999888],
              [30.504209519000085, -2.703408956999908],
              [30.504255294000075, -2.701849935999974],
              [30.50493812600007, -2.699973105999959],
              [30.505075455000167, -2.699331997999934],
              [30.504802705000145, -2.697726964999902],
              [30.506353378000085, -2.699009895999836],
              [30.50876808100014, -2.699789046999911],
              [30.509681701000034, -2.699147939999875],
              [30.510046006000096, -2.69823098199987],
              [30.509498595000025, -2.696582078999938],
              [30.50931739900011, -2.695667028999935],
              [30.509044646000177, -2.694886921999853],
              [30.50931739900011, -2.694015978999971],
              [30.510000229000127, -2.6932380199999],
              [30.51127624600008, -2.694154022999953],
              [30.512052537000102, -2.694520949999856],
              [30.512964250000152, -2.694428920999883],
              [30.513692856000034, -2.69397091899998],
              [30.514240265000126, -2.692275046999839],
              [30.514923096000018, -2.692047118999881],
              [30.51647567700013, -2.692734003999874],
              [30.517250061000084, -2.692869900999938],
              [30.518161774000134, -2.692229985999916],
              [30.518663407000133, -2.691634892999957],
              [30.518161774000134, -2.689802883999903],
              [30.518436431000055, -2.689205883999932],
              [30.522401810000133, -2.687922953999873],
              [30.5224475870001, -2.687052964999964],
              [30.52199173000014, -2.686089992999939],
              [30.521444320000114, -2.68563199099998],
              [30.521354676000158, -2.682333946999847],
              [30.52185440100004, -2.68173909099994],
              [30.525001526000096, -2.681508062999967],
              [30.525594712000157, -2.68095898699994],
              [30.52582168500004, -2.680042981999918],
              [30.52627754100007, -2.679218052999943],
              [30.525913239000147, -2.678392886999973],
              [30.525091171000156, -2.677064894999887],
              [30.526140211999973, -2.675461053999868],
              [30.526733398000033, -2.674040077999905],
              [30.5269165040001, -2.672666072999903],
              [30.52805519200018, -2.671293018999961],
              [30.528739929000096, -2.670650958999886],
              [30.528968810000094, -2.668910024999889],
              [30.53006172100004, -2.665473936999888],
              [30.53006172100004, -2.663779019999822],
              [30.529109954000035, -2.663017988999911],
              [30.528078078000135, -2.662602901999946],
              [30.52720260700005, -2.660377024999889],
              [30.528131485000188, -2.657788038999911],
              [30.527460099000052, -2.654679060999968],
              [30.52833557000008, -2.653851984999847],
              [30.527822494000077, -2.653022050999937],
              [30.526945115000046, -2.653177022999955],
              [30.52534866300016, -2.653644084999939],
              [30.523130416000186, -2.655817983999953],
              [30.520193101000075, -2.654989002999969],
              [30.518699646000073, -2.655249117999972],
              [30.515554429000133, -2.655301094999913],
              [30.511533738000082, -2.656388997999954],
              [30.507307052000158, -2.661412000999974],
              [30.505916595000087, -2.663433073999954],
              [30.503906250000114, -2.665090083999928],
              [30.501535417000184, -2.666641950999917],
              [30.499681473000066, -2.666591881999921],
              [30.498701096000048, -2.66736698099993],
              [30.49777221800008, -2.668350933999932],
              [30.49555587700013, -2.669750928999918],
              [30.493804932000103, -2.670320033999929],
              [30.49287414600019, -2.670734882999966],
              [30.492462159000127, -2.671770095999932],
              [30.490762710000126, -2.672600030999945],
              [30.489114761999986, -2.673996925999973],
              [30.487310410000077, -2.674050092999948],
              [30.48658752400013, -2.674568890999979],
              [30.486175537000065, -2.675395965999826],
              [30.47839355500014, -2.678193092999891],
              [30.477361679000182, -2.679280995999932],
              [30.475559235000105, -2.67912507099993],
              [30.47272300700007, -2.680366991999904],
              [30.470815658000106, -2.681766032999917],
              [30.4692707050001, -2.684511898999915],
              [30.467981339000175, -2.685703038999975],
              [30.464475633000177, -2.687722921999978],
              [30.462415696000164, -2.689172981999946],
              [30.460094452000135, -2.690778016999957],
              [30.459115982000185, -2.691140888999939],
              [30.456899643000042, -2.690778016999957],
              [30.452928543999974, -2.68880891799995],
              [30.450559616000078, -2.68663501799989],
              [30.45014572100007, -2.686013936999927],
              [30.450250626000127, -2.685391902999925],
              [30.44921875000017, -2.683943031999945],
              [30.447620393000193, -2.681972979999898],
              [30.445146560000012, -2.678970098999969],
              [30.443447113000047, -2.677984952999964],
              [30.441074371000184, -2.677520035999976],
              [30.439426422000167, -2.677261113999975],
              [30.437004090000073, -2.677104948999897],
              [30.43530273300013, -2.677623032999975],
              [30.43313789400014, -2.679022073999931],
              [30.43257141000015, -2.679539917999932],
              [30.431747437000126, -2.679280042999949],
              [30.431024551000178, -2.67891788399993],
              [30.43056106600011, -2.67824506799991],
              [30.429735185000027, -2.677311896999811],
              [30.42891120900009, -2.675395965999826],
              [30.42818832500012, -2.674879074999979],
              [30.426799775000177, -2.673170089999928],
              [30.42540741000016, -2.670630930999892],
              [30.424789428, -2.66995811399994],
              [30.424066544000084, -2.667989014999819],
              [30.422416687000066, -2.66607403699993],
              [30.4207153320001, -2.664984940999943],
              [30.420200348000094, -2.661981104999939],
              [30.420562745000154, -2.659909010999968],
              [30.421901703000117, -2.657268046999946],
              [30.422416687000066, -2.655658958999936],
              [30.42246818500007, -2.654834032999929],
              [30.422880173000067, -2.654161928999884],
              [30.42416954100014, -2.652867077999872],
              [30.425561904000176, -2.650640010999894],
              [30.42576980600012, -2.650172948999909],
              [30.42638588000011, -2.649395942999831],
              [30.427055359000178, -2.647999047999974],
              [30.428499222000028, -2.647170065999944],
              [30.429943085000048, -2.646081924999976],
              [30.43056106600011, -2.644579886999964],
              [30.43184852600001, -2.643958090999945],
              [30.43329238900003, -2.642920969999921],
              [30.432983399000022, -2.641783951999912],
              [30.435251236, -2.639244079999969],
              [30.436901093000188, -2.637017010999841],
              [30.438291551000134, -2.635567902999924],
              [30.439065932000176, -2.633961914999929],
              [30.439374924000163, -2.632460116999937],
              [30.441230774000076, -2.6295080189999],
              [30.44205474900008, -2.627696036999907],
              [30.441904068000156, -2.625711917999979],
              [30.442363739000086, -2.624017953999953],
              [30.442569734000188, -2.622620105999943],
              [30.444168091000165, -2.618529081999952],
              [30.446847917000014, -2.614178895999885],
              [30.448343277000106, -2.612210035999965],
              [30.449684142000137, -2.609723091999967],
              [30.451797486000032, -2.607289074999926],
              [30.452878952000162, -2.606151103999935],
              [30.45323944100005, -2.604752063999911],
              [30.45504379300013, -2.602888106999956],
              [30.457725524000068, -2.596153973999947],
              [30.459270477000075, -2.595016002999842],
              [30.461280823000152, -2.59356403399994],
              [30.461847305000106, -2.592890977999957],
              [30.46210479700011, -2.592011928999966],
              [30.462671281000098, -2.591336964999812],
              [30.464424133000193, -2.590145111999959],
              [30.465301514000032, -2.588696003999871],
              [30.466485977000048, -2.587245940999878],
              [30.46694946400015, -2.58574390299998],
              [30.467258453, -2.582998991999943],
              [30.468290329000126, -2.58041000299994],
              [30.470352173000038, -2.57616209899993],
              [30.472671509000065, -2.574661015999823],
              [30.47457885600005, -2.573728084999971],
              [30.47643470800017, -2.571656942999937],
              [30.476692200000116, -2.569430112999896],
              [30.47767066900019, -2.568083047999835],
              [30.47823906000002, -2.565339088999906],
              [30.4794750210001, -2.56155705499998],
              [30.48040389900018, -2.560210943999948],
              [30.48158836400006, -2.558811902999935],
              [30.482826233000083, -2.558346986999936],
              [30.48411369300004, -2.557931899999971],
              [30.48478317200005, -2.557102917999941],
              [30.48483657900016, -2.552337884999929],
              [30.484525680000104, -2.550682067999901],
              [30.48540115300011, -2.547521113999949],
              [30.485660553000116, -2.545708894999905],
              [30.485454559000118, -2.544517040999949],
              [30.484216690000096, -2.540271043999951],
              [30.48463058500016, -2.538508891999868],
              [30.484989166000048, -2.537682055999937],
              [30.48514556900011, -2.537060021999878],
              [30.484527587000173, -2.533745050999869],
              [30.484216690000096, -2.532761097999924],
              [30.483289719000027, -2.531829118999895],
              [30.483598709000034, -2.530586003999815],
              [30.483289719000027, -2.528877020999971],
              [30.484939574000066, -2.524837016999925],
              [30.485765458000174, -2.524318931999972],
              [30.49009323100006, -2.524060009999971],
              [30.493650437000042, -2.523801087999914],
              [30.496330261000082, -2.523287058999927],
              [30.495607376, -2.521315096999956],
              [30.495506287000126, -2.519865988999925],
              [30.496278763000078, -2.518414019999966],
              [30.498546600000054, -2.516187905999914],
              [30.499267578000172, -2.514945029999922],
              [30.501483917000144, -2.511888979999924],
              [30.50292587199999, -2.510437011999954],
              [30.504627227000185, -2.507693052999969],
              [30.507152558000143, -2.505259036999973],
              [30.507049560000155, -2.502254962999928],
              [30.509576798000182, -2.496608970999887],
              [30.510967254000093, -2.49153494799998],
              [30.511741638000046, -2.488426922999963],
              [30.513647079000066, -2.48314499799983],
              [30.51550292900015, -2.47822499199998],
              [30.519832612000187, -2.474445104999973],
              [30.52060508800014, -2.46957492699994],
              [30.521276474000103, -2.465898036999931],
              [30.520502091000083, -2.458698034999884],
              [30.521120072000144, -2.456679104999921],
              [30.52390670800014, -2.452534913999955],
              [30.524368287000186, -2.447666882999954],
              [30.524728776000075, -2.446784973999911],
              [30.525245666000046, -2.446113109999942],
              [30.52792358400012, -2.4451799389999],
              [30.5295238490001, -2.443679093999947],
              [30.530193330000145, -2.4402089109999],
              [30.530605316000162, -2.438292980999904],
              [30.531635285000164, -2.437153099999932],
              [30.535089492000054, -2.435444116999918],
              [30.53632736200018, -2.433942079999895],
              [30.53704834100006, -2.432285069999921],
              [30.537565230000098, -2.429903029999935],
              [30.539110183000105, -2.425966023999877],
              [30.53890419000004, -2.425189971999885],
              [30.537717820000125, -2.42244505899987],
              [30.538387299000192, -2.421926973999973],
              [30.53983116100011, -2.420217990999902],
              [30.541790008000078, -2.417473077999887],
              [30.54307746900014, -2.41379404099996],
              [30.543542863000084, -2.413155077999932],
              [30.543420792000177, -2.413010358999941],
              [30.54201889100011, -2.41230988399991],
              [30.53982734600004, -2.41125869699988],
              [30.53891944900016, -2.410523414999886],
              [30.537441254000157, -2.408372401999884],
              [30.536254884000073, -2.406276225999932],
              [30.53534507800009, -2.405265092999912],
              [30.534048080000048, -2.405073881999897],
              [30.532852172000048, -2.404961586999946],
              [30.532016755000143, -2.404737948999923],
              [30.530591966000088, -2.404119014999935],
              [30.528705597000055, -2.40381550799998],
              [30.52617645200013, -2.403748034999978],
              [30.524896622000142, -2.403984068999876],
              [30.52398490900009, -2.40458512399988],
              [30.523143769000114, -2.404658316999928],
              [30.52221298300003, -2.404636143999937],
              [30.520816803000173, -2.4044559],
              [30.51884841800012, -2.404271841999901],
              [30.517868042000146, -2.403687953999906],
              [30.516975403000117, -2.402704237999956],
              [30.516235351000148, -2.402025221999907],
              [30.515888214000142, -2.401345966999941],
              [30.514818192000064, -2.401304720999974],
              [30.51377487300016, -2.401421307999954],
              [30.512416839000082, -2.401511907999975],
              [30.511224746000096, -2.401270388999876],
              [30.510620117000087, -2.400772332999964],
              [30.509820938000132, -2.399625301999947],
              [30.509639739000136, -2.399278162999906],
              [30.50955391000008, -2.398462771999959],
              [30.510152816000073, -2.393225668999946],
              [30.50979042100016, -2.392305135999891],
              [30.508930206000116, -2.390448568999943],
              [30.508342743000128, -2.38943743699997],
              [30.50580596899999, -2.387535571999877],
              [30.50385284500004, -2.386610268999903],
              [30.503089904999968, -2.386147021999932],
              [30.50250625699999, -2.385971546999883],
              [30.501787186000115, -2.3856272699999],
              [30.500810623000064, -2.385513067999966],
              [30.499982834000036, -2.384850023999832],
              [30.49945259200007, -2.383324622999908],
              [30.49880790700007, -2.38155603499996],
              [30.49777603100017, -2.380790708999939],
              [30.49699211100011, -2.380321024999887],
              [30.496322632000044, -2.379688261999945],
              [30.49590492300007, -2.378766298999949],
              [30.49507522600004, -2.377664564999918],
              [30.49445533800008, -2.377106188999903],
              [30.49350547800003, -2.37722682999987],
              [30.491933822000192, -2.377581833999841],
              [30.490968704000124, -2.377634285999932],
              [30.490011215000038, -2.37735652799995],
              [30.48971748300005, -2.377166508999949],
              [30.489269257000103, -2.376552580999942],
              [30.48887252800006, -2.376110076999908],
              [30.488691330000165, -2.375627039999927],
              [30.48878097400012, -2.374540328999899],
              [30.48881149300007, -2.373302697999975],
              [30.488569259000087, -2.372261284999979],
              [30.488039017000062, -2.370867013999884],
              [30.487525940000182, -2.369661092999934],
              [30.487211228000092, -2.368512390999854],
              [30.486698151000155, -2.367144584999835],
              [30.486335755000027, -2.366676567999946],
              [30.485776901000122, -2.366284131999976],
              [30.484781264000105, -2.365906953999911],
              [30.48386001600005, -2.365605114999823],
              [30.48309135500017, -2.365771054999868],
              [30.482191085000125, -2.366448878999904],
              [30.481567383000026, -2.367174863999935],
              [30.481008530000054, -2.367582319999883],
              [30.47928810200017, -2.368351935999897],
              [30.478351593000014, -2.368548154999871],
              [30.477693557000123, -2.368626355999936],
              [30.477533340000093, -2.36874556499987],
              [30.475891113000046, -2.368518113999869],
              [30.471822739, -2.367053269999929],
              [30.47110748299997, -2.366524217999938],
              [30.470849991000193, -2.366057872999875],
              [30.470518113000082, -2.364940880999939],
              [30.470081330000085, -2.363944768999943],
              [30.468994140000177, -2.363627909999934],
              [30.469137191000016, -2.362832544999947],
              [30.469449996000094, -2.361568927999826],
              [30.46997451800013, -2.360941170999922],
              [30.470262527000045, -2.360503435999931],
              [30.470594407000192, -2.359628199999918],
              [30.470594407000192, -2.35857152799997],
              [30.47066879300013, -2.354692695999916],
              [30.470518113000082, -2.35313797099991],
              [30.46994399900018, -2.351175783999906],
              [30.469491958000162, -2.350511789999928],
              [30.468147277000185, -2.350527523999915],
              [30.46718216, -2.350798605999955],
              [30.466722488000187, -2.35070323899987],
              [30.466306686000053, -2.350375890999885],
              [30.46574783300008, -2.349787233999962],
              [30.464632035000136, -2.349334477999889],
              [30.462051392000035, -2.348398685999882],
              [30.460718154000062, -2.347771643999977],
              [30.45992279000012, -2.347236633999955],
              [30.45936393800008, -2.346179961999894],
              [30.458518981000054, -2.344413994999968],
              [30.45847320600012, -2.343870638999874],
              [30.45871543800007, -2.343010425999921],
              [30.459352493000097, -2.341857671999946],
              [30.459665298000175, -2.341110944999969],
              [30.459837000000164, -2.340392999999892],
              [30.460315841000067, -2.339901163999969],
              [30.461144944000182, -2.339629799999898],
              [30.46094900000014, -2.338934999999879],
              [30.460482, -2.338256],
              [30.459661484000037, -2.33674979299991],
              [30.459305, -2.33566],
              [30.45912200000015, -2.334558],
              [30.45910835400008, -2.333863972999893],
              [30.459471, -2.331826],
              [30.459999085000163, -2.33101129399995],
              [30.46048900000011, -2.330543],
              [30.461311, -2.33],
              [30.461803, -2.329049],
              [30.462944000000164, -2.326349],
              [30.463058, -2.325344],
              [30.46248800000012, -2.324822999999867],
              [30.462368079000044, -2.324234386999933],
              [30.462571979000018, -2.323936873999912],
              [30.462461471000154, -2.323267935999979],
              [30.462639, -2.32268],
              [30.462715, -2.321729],
              [30.46235300000012, -2.320974],
              [30.46153800000019, -2.320158999999876],
              [30.46091500000017, -2.31972],
              [30.460558000000105, -2.319356],
              [30.460462834000054, -2.319473893999941],
              [30.459715000000188, -2.318917],
              [30.45884500000011, -2.318341],
              [30.458448000000146, -2.318184],
              [30.457784652000157, -2.318110941999976],
              [30.457006, -2.318094],
              [30.456152, -2.31823],
              [30.455042, -2.31823],
              [30.454387665000183, -2.318274974999952],
              [30.45320100000015, -2.318167],
              [30.45204162500005, -2.318142413999908],
              [30.451267, -2.318188],
              [30.450539000000163, -2.318400999999881],
              [30.449398041000165, -2.319161175999909],
              [30.447420121000107, -2.320793867999896],
              [30.446840286, -2.32106256499992],
              [30.44628143300008, -2.3214221],
              [30.445367869000165, -2.32157205499982],
              [30.444910008000193, -2.321763995999959],
              [30.444448472000033, -2.3216979499999],
              [30.443907000000138, -2.321478],
              [30.4434833520001, -2.321743011999843],
              [30.4430618290001, -2.322527885999932],
              [30.442670821000092, -2.323456049999891],
              [30.442150116000107, -2.324077128999875],
              [30.441354752000166, -2.325497150999865],
              [30.44035720800008, -2.327112435999936],
              [30.43943214400008, -2.328375577999907],
              [30.439027786000054, -2.328874348999875],
              [30.438358307000158, -2.32943272499989],
              [30.437873841000055, -2.329704759999913],
              [30.437246322000078, -2.329819917999942],
              [30.436552047000077, -2.329732655999919],
              [30.435853959000042, -2.329491852999979],
              [30.434995651000122, -2.329017400999874],
              [30.43420028700018, -2.328452348999974],
              [30.433439256000042, -2.328186988999846],
              [30.432483674000025, -2.328312634999918],
              [30.43140220600003, -2.328745364999861],
              [30.43052864000009, -2.328769683999894],
              [30.429712296000048, -2.328560351999897],
              [30.428415298000175, -2.328012703999889],
              [30.427947998000036, -2.32762193699989],
              [30.427419662000034, -2.326915263999979],
              [30.427160264000065, -2.326315403999956],
              [30.426769256000057, -2.325670240999955],
              [30.426136016000044, -2.324901102999945],
              [30.425838470000144, -2.324882506999813],
              [30.425304412000173, -2.325062512999978],
              [30.42405891400017, -2.326092003999975],
              [30.423685074000105, -2.326284407999879],
              [30.422941209000157, -2.326550959999906],
              [30.422153472000048, -2.326600789999873],
              [30.421663283999976, -2.326439379999954],
              [30.421415330000116, -2.325924634999979],
              [30.421440124000185, -2.325496672999918],
              [30.421632767000062, -2.324528932999954],
              [30.421596526000144, -2.323840617999849],
              [30.421508789000086, -2.323418855999933],
              [30.4212417600001, -2.32299709299997],
              [30.42070961000013, -2.322624920999886],
              [30.419572831000153, -2.321917772999825],
              [30.41928291300013, -2.321694374999879],
              [30.417861938000044, -2.320900439999946],
              [30.417358398000147, -2.320478677999859],
              [30.416969299000186, -2.319963930999904],
              [30.416484834000187, -2.319033622999939],
              [30.416223527000113, -2.318109273999937],
              [30.415933608000046, -2.317699909999874],
              [30.415361404000123, -2.317445755999927],
              [30.414939881000123, -2.316912172999821],
              [30.41471672100016, -2.315882682999813],
              [30.41438293500005, -2.31485915199994],
              [30.414146424000137, -2.314369201999909],
              [30.413700104000156, -2.314040420999959],
              [30.412738801000103, -2.313488482999958],
              [30.412179947000027, -2.31288051699994],
              [30.411479950000114, -2.311627625999904],
              [30.411001205000048, -2.311119078999923],
              [30.410654067999985, -2.310846088999881],
              [30.40983009400003, -2.310449122999899],
              [30.40912818900017, -2.309952974999817],
              [30.408830643000044, -2.309834956999907],
              [30.408359528000062, -2.309890984999925],
              [30.40811729400008, -2.310238360999961],
              [30.40700149500003, -2.31118726699998],
              [30.406604766000157, -2.311317443999826],
              [30.40572929500013, -2.311726807999946],
              [30.404972076999968, -2.3121485699999],
              [30.40441513100012, -2.312328575999914],
              [30.40364456200018, -2.312316177999946],
              [30.402721404000033, -2.312092779999944],
              [30.40180397100005, -2.311677217999943],
              [30.401157378000107, -2.311658619999889],
              [30.400897979000035, -2.311733007999919],
              [30.40019035300014, -2.312160967999887],
              [30.399799347000112, -2.312607526999898],
              [30.399049759000093, -2.312985896999919],
              [30.39798927200019, -2.313079118999838],
              [30.396102906000067, -2.312942504999967],
              [30.395626069000116, -2.312799930999915],
              [30.394720077000102, -2.312719343999902],
              [30.39367103500018, -2.312849520999919],
              [30.393106461, -2.313116312999966],
              [30.392343520000054, -2.313351868999916],
              [30.391902924000192, -2.313631056999952],
              [30.39115905699998, -2.314660787999969],
              [30.39060783400015, -2.315299509999875],
              [30.38939857500003, -2.316515206999839],
              [30.389093398000057, -2.316862582999875],
              [30.38809585600012, -2.317265749999933],
              [30.387889862000122, -2.317594527999972],
              [30.38779831000005, -2.318214893999937],
              [30.387748718000182, -2.31936836299991],
              [30.387611389000085, -2.320218322999892],
              [30.387512206000167, -2.320435285999906],
              [30.387134551000088, -2.320621489999951],
              [30.38672447199997, -2.320633887999975],
              [30.385950088000072, -2.320546865999972],
              [30.385738373000038, -2.320640087999891],
              [30.385316849000162, -2.32121062199991],
              [30.38517379700005, -2.321700572999873],
              [30.38512420699999, -2.322258948999888],
              [30.385229112000104, -2.322817086999976],
              [30.38514328000008, -2.323052882999946],
              [30.384460449000187, -2.323580025999945],
              [30.38366699100004, -2.323902605999933],
              [30.382799148000117, -2.324144361999913],
              [30.38228416400011, -2.324553728999945],
              [30.38135337800003, -2.325465678999933],
              [30.38060188300011, -2.325850246999949],
              [30.38046073900017, -2.326153993999924],
              [30.380559921000042, -2.326557159999823],
              [30.38083839400008, -2.326854943999933],
              [30.38096809400014, -2.327264307999883],
              [30.380819320000057, -2.327444313999934],
              [30.380645752000078, -2.328014850999978],
              [30.380138398000042, -2.328504800999951],
              [30.380020142000035, -2.32900118699996],
              [30.380094529000075, -2.329385757999887],
              [30.380422592000116, -2.330204485999957],
              [30.38034820600018, -2.330626248999977],
              [30.379610061000108, -2.330768822999971],
              [30.37909507700016, -2.330731630999878],
              [30.378711701000043, -2.330849408999939],
              [30.37846946700006, -2.331202982999969],
              [30.37828254600015, -2.331686734999948],
              [30.37810897800017, -2.332468271999971],
              [30.378337859, -2.333032845999981],
              [30.378637313000127, -2.333237408999935],
              [30.37899017400008, -2.333160638999971],
              [30.37916565, -2.333334922999882],
              [30.379179001000182, -2.333878755999876],
              [30.378643036000142, -2.334868669999878],
              [30.378601074000017, -2.335844515999895],
              [30.378412247000142, -2.336513756999921],
              [30.378036500000064, -2.337266683999872],
              [30.377897263000136, -2.337949990999846],
              [30.377220154000156, -2.339755533999949],
              [30.37708854800013, -2.340410708999968],
              [30.37661361800008, -2.341707467999868],
              [30.37620925900012, -2.341902493999953],
              [30.375699998000186, -2.341714382999896],
              [30.375303269000085, -2.341700314999969],
              [30.375017166000134, -2.342543839999962],
              [30.374271393000186, -2.343296766999913],
              [30.373874665000017, -2.343875406999928],
              [30.373685836000107, -2.344900129999871],
              [30.37339210500005, -2.345904111999914],
              [30.373336792000032, -2.346636055999909],
              [30.373420716000055, -2.347172735999891],
              [30.374061584000117, -2.347409724999977],
              [30.374179839000078, -2.34759807599994],
              [30.37413215700019, -2.347995279999907],
              [30.373817444000167, -2.348612545999913],
              [30.37425994800003, -2.349503039999888],
              [30.374330521000104, -2.35148715899993],
              [30.374261856000032, -2.352740286999961],
              [30.37422752399999, -2.3542373169999],
              [30.37422752399999, -2.355594635999978],
              [30.373985290000064, -2.356012582999881],
              [30.372047, -2.356221999999889],
              [30.37042, -2.356536],
              [30.369071623000025, -2.356849053999895],
              [30.36786100000012, -2.357024],
              [30.366615, -2.357268],
              [30.364193, -2.35786],
              [30.362843, -2.358068998999954],
              [30.360109000000136, -2.358627],
              [30.355718612000146, -2.35992097899998],
              [30.354200000000105, -2.360141999999883],
              [30.34869, -2.361553999999899],
              [30.34747900000019, -2.361833],
              [30.345427999000037, -2.362397],
              [30.3433950000001, -2.362772998999958],
              [30.341908, -2.363087],
              [30.340080000000114, -2.363602],
              [30.338778, -2.364009998999904],
              [30.33637799900015, -2.364509999999882],
              [30.33349600000014, -2.36529899999988],
              [30.332235, -2.365404],
              [30.330470999000056, -2.365892],
              [30.329260000000147, -2.36613599999987],
              [30.32785606300007, -2.366599798999971],
              [30.32688876500015, -2.366878168999904],
              [30.325987008000027, -2.366945998999938],
              [30.324577332000104, -2.367326735999882],
              [30.323212000000126, -2.367559],
              [30.321819305000076, -2.367947816999958],
              [30.319674684000177, -2.368371093999883],
              [30.31863594100014, -2.368679523999901],
              [30.316905975000168, -2.369132517999901],
              [30.315452575000165, -2.3694460399999],
              [30.313791, -2.369864],
              [30.312582, -2.370046999999886],
              [30.31003, -2.370711],
              [30.308744000000104, -2.371085999999878],
              [30.307314000000133, -2.371274],
              [30.306818008000107, -2.371654510999974],
              [30.305346000000156, -2.371829999999818],
              [30.304412842000033, -2.372128962999966],
              [30.30313301000001, -2.372460840999963],
              [30.302491360000033, -2.372575348999931],
              [30.30044393200012, -2.373171008999918],
              [30.2985, -2.373437],
              [30.297302001000105, -2.373713998999904],
              [30.295475006, -2.374078988999827],
              [30.292352677000054, -2.374585627999977],
              [30.29064, -2.374812],
              [30.290215, -2.374711999999875],
              [30.288927079000132, -2.37503886199994],
              [30.28743884500011, -2.375178006999875],
              [30.285474999000144, -2.375268],
              [30.283246992999977, -2.375568626999893],
              [30.282075883000118, -2.375666856999942],
              [30.280032938999966, -2.375911],
              [30.27813, -2.375945998999953],
              [30.2770843510001, -2.375837801999978],
              [30.2756977090001, -2.375876663999975],
              [30.270460129000185, -2.375149964999878],
              [30.268440000000112, -2.374798999999882],
              [30.2671775820001, -2.37467789499982],
              [30.2658100000001, -2.374331999999868],
              [30.262504578000176, -2.374052047999896],
              [30.261217118000047, -2.37398266799994],
              [30.260187150000093, -2.373982904999934],
              [30.259181976000036, -2.374052762999952],
              [30.25797081100012, -2.374053000999879],
              [30.256690979000098, -2.373914003999857],
              [30.255653382000162, -2.3738794329999],
              [30.254415512000037, -2.373464822999893],
              [30.253028870000037, -2.373048065999967],
              [30.251737594000133, -2.372272729999963],
              [30.250219345, -2.371095656999898],
              [30.24961471500012, -2.369765281999946],
              [30.249559402000045, -2.369565724999973],
              [30.25057220399998, -2.368537424999886],
              [30.24976921200016, -2.366060495999932],
              [30.249671937000016, -2.366004465999936],
              [30.24866104200015, -2.364401340999962],
              [30.24791717500017, -2.363705157999902],
              [30.24812507700011, -2.363538265999978],
              [30.247068405000107, -2.362514972999918],
              [30.24664306600016, -2.361913441999889],
              [30.24541473300002, -2.360536574999912],
              [30.244003296000074, -2.359465121999904],
              [30.243030548000036, -2.359095096999965],
              [30.242757798000014, -2.359040974999914],
              [30.24185752900007, -2.359273670999869],
              [30.24118232700016, -2.359595060999936],
              [30.240585327000133, -2.359980343999837],
              [30.240102768999975, -2.360636709999938],
              [30.23936462400013, -2.361117361999959],
              [30.238285064000138, -2.361419438999974],
              [30.237422943000126, -2.361721752999927],
              [30.236429214000054, -2.361721752999927],
              [30.23578071600008, -2.36206698399991],
              [30.235349656000096, -2.362585067999873],
              [30.234659194000187, -2.362973688999944],
              [30.234054565000122, -2.363189458999955],
              [30.23336410500002, -2.363534927999865],
              [30.23275947500008, -2.364096163999932],
              [30.23150825600004, -2.365391254999963],
              [30.230903625000053, -2.365909098999907],
              [30.229867934000026, -2.36664319099998],
              [30.229301453000176, -2.366616249999822],
              [30.228826523000123, -2.367421626999942],
              [30.228071213000135, -2.367827413999919],
              [30.226963043000126, -2.36855840599992],
              [30.22547531100014, -2.369637726999883],
              [30.223226548000127, -2.370195149999972],
              [30.222394944000087, -2.370160578999958],
              [30.22063064600013, -2.370404481999913],
              [30.22002220100012, -2.370446203999961],
              [30.219459534, -2.370633602999931],
              [30.21847152800018, -2.37083244299987],
              [30.217748641000128, -2.370746373999964],
              [30.217031478000024, -2.3709270949999],
              [30.21571540800005, -2.371136188999969],
              [30.215185166000026, -2.37110352499991],
              [30.215404511000145, -2.371971605999875],
              [30.215370178000057, -2.373399019999965],
              [30.215198517000147, -2.37472176499989],
              [30.214990617000183, -2.375661610999941],
              [30.21478271500007, -2.376879930999962],
              [30.21440315200016, -2.377959250999879],
              [30.21377945000006, -2.380047796999861],
              [30.213676453000176, -2.380709169999875],
              [30.21388435300014, -2.382205962999819],
              [30.213157654000156, -2.382832764999876],
              [30.21218872100019, -2.383250473999908],
              [30.21052742000012, -2.384225606999962],
              [30.209627151000177, -2.384504079999886],
              [30.208763123000097, -2.385130881999885],
              [30.207828522000113, -2.385722637999891],
              [30.20713615400018, -2.385966538999924],
              [30.205474854000045, -2.386732577999965],
              [30.203813552999975, -2.387603043999889],
              [30.20319175700007, -2.387707470999885],
              [30.20187568700004, -2.38805580199994],
              [30.199211120000143, -2.388439177999942],
              [30.19845008900012, -2.390771626999936],
              [30.198173524000083, -2.391676663999874],
              [30.198034286999984, -2.39265132],
              [30.198186875000033, -2.393978833999938],
              [30.198524475000056, -2.395174502999907],
              [30.198724747000142, -2.396019219999914],
              [30.199056625000026, -2.397019861999922],
              [30.199090957000067, -2.398690699999975],
              [30.198503494000192, -2.4004311569999],
              [30.19815826400003, -2.401336431999937],
              [30.197845459000177, -2.402415514999973],
              [30.197292328000174, -2.404643534999934],
              [30.197154999000077, -2.405687807999925],
              [30.19680786200007, -2.406836509999835],
              [30.196981431000097, -2.40805482899998],
              [30.19653129500017, -2.409586428999887],
              [30.195978164000167, -2.411013840999942],
              [30.19532203800003, -2.411988495999935],
              [30.194595336000134, -2.413311480999823],
              [30.193834306000042, -2.414634226999908],
              [30.192956925000033, -2.416368723999938],
              [30.192621231000146, -2.417086839999911],
              [30.192199707000043, -2.417469977999929],
              [30.190402985000105, -2.419955968999886],
              [30.188999177000085, -2.421142816999975],
              [30.18877410900012, -2.421142816999975],
              [30.18784332300004, -2.422934293999958],
              [30.187076569, -2.423608064999826],
              [30.186727523000116, -2.424304007999922],
              [30.186079025000083, -2.425776004999875],
              [30.185113907000186, -2.427562712999929],
              [30.18353271500007, -2.429176091999921],
              [30.182394026999987, -2.429460764999931],
              [30.18163490400019, -2.42990374499982],
              [30.181001664000178, -2.430093526999883],
              [30.179580688000044, -2.430266617999905],
              [30.17897796700015, -2.430146216999958],
              [30.17779731700017, -2.429762600999936],
              [30.176836014000116, -2.429396151999924],
              [30.17601013300009, -2.428981541999974],
              [30.17495346100003, -2.428388833999975],
              [30.174119948999987, -2.428020954999909],
              [30.172962188000042, -2.427448749999883],
              [30.17239570600009, -2.427209614999924],
              [30.170848846000183, -2.42677426399996],
              [30.170095443000093, -2.426631211999961],
              [30.16881561300005, -2.426590679999947],
              [30.16812515300012, -2.426488399999926],
              [30.16767883300014, -2.426539659999889],
              [30.165258407000067, -2.426550150999958],
              [30.16391754100016, -2.426754712999923],
              [30.162048339000023, -2.427777050999964],
              [30.1609497070001, -2.428574560999948],
              [30.15989303500004, -2.429290056999946],
              [30.159486771000047, -2.429678201999877],
              [30.15899086000013, -2.430033206999951],
              [30.157171250000033, -2.430987118999838],
              [30.155971527000133, -2.431437014999915],
              [30.15493393, -2.431682348999971],
              [30.15381622300015, -2.431866644999957],
              [30.152740478000055, -2.431968926999957],
              [30.15131759600007, -2.432030439999835],
              [30.150199891000057, -2.43211221699994],
              [30.149326325000118, -2.432132719999856],
              [30.148126602000048, -2.432357787999933],
              [30.146682739000028, -2.432766914999945],
              [30.14615440400013, -2.432971238999869],
              [30.145320892000086, -2.43354392099991],
              [30.14342117299998, -2.434637784999893],
              [30.142507553000087, -2.435230492999949],
              [30.14080047600015, -2.436150787999964],
              [30.139865874000066, -2.436580180999954],
              [30.13825225900007, -2.437386750999906],
              [30.13717269800003, -2.437612771999966],
              [30.135993957999972, -2.437899112999844],
              [30.134733201000017, -2.437776564999979],
              [30.13062858600017, -2.437654255999917],
              [30.128076554000188, -2.43739890899991],
              [30.126735687000178, -2.437133311999958],
              [30.125230789, -2.436724662999836],
              [30.123218537000128, -2.435907124999972],
              [30.122995376000063, -2.43572306599998],
              [30.121816636000062, -2.435293912999953],
              [30.120738982999967, -2.434639929999889],
              [30.119541169000172, -2.43404722199989],
              [30.118503571000133, -2.433434008999882],
              [30.117141724000135, -2.432493686999976],
              [30.116247177000105, -2.431737423999834],
              [30.115007400000138, -2.430817602999923],
              [30.114171983000176, -2.42999267499988],
              [30.113311768000187, -2.429454803999875],
              [30.111688614000172, -2.428672074999952],
              [30.110557556000174, -2.427792548999946],
              [30.108423233000053, -2.426566123999976],
              [30.107446670000172, -2.426095961999977],
              [30.10689735400001, -2.425769089999903],
              [30.10586166300004, -2.425258159999942],
              [30.104703904000075, -2.424746988999914],
              [30.102285386000176, -2.424174784999821],
              [30.100395202000072, -2.423970699999813],
              [30.099533082000164, -2.423935412999924],
              [30.09754943900009, -2.423521040999901],
              [30.096971512000152, -2.423265694999941],
              [30.096330642000055, -2.422787666999966],
              [30.095781326000065, -2.422212839999929],
              [30.09475326500018, -2.421405554999922],
              [30.093555449, -2.42042422399993],
              [30.092254638000156, -2.419443129999934],
              [30.09081077600007, -2.418277977999878],
              [30.08983612000003, -2.417399166999928],
              [30.088859559000184, -2.416581390999909],
              [30.087131501000158, -2.414987086999929],
              [30.08613586400014, -2.414026258999968],
              [30.085405350000087, -2.413228988999947],
              [30.084693909000123, -2.412533998999891],
              [30.08448219400009, -2.412389276999818],
              [30.081048966000083, -2.408830165999916],
              [30.07986450300018, -2.407102106999957],
              [30.078681945000028, -2.405942439999933],
              [30.076715470000124, -2.404153585999893],
              [30.075025558000107, -2.402750492999871],
              [30.073705674000166, -2.401302099999953],
              [30.072954179000078, -2.400631903999908],
              [30.071126938000134, -2.399420261999921],
              [30.06964302099999, -2.398541210999952],
              [30.068721771000185, -2.397786617999941],
              [30.068603515000177, -2.397780417999968],
              [30.06779289200017, -2.397192954999923],
              [30.067131043000188, -2.396871089999877],
              [30.066278458000056, -2.396221874999981],
              [30.064395905000026, -2.394978760999891],
              [30.063417435000076, -2.394250631999967],
              [30.062376023000013, -2.393298864999963],
              [30.06156349200006, -2.391867875999878],
              [30.06111717200008, -2.390927553999973],
              [30.060647965000044, -2.389189957999918],
              [30.060344696000072, -2.38796329499985],
              [30.059783936000088, -2.385472773999936],
              [30.05835151700012, -2.381708144999948],
              [30.05798530499999, -2.381006956999897],
              [30.057527541000127, -2.379799364999826],
              [30.057153701000118, -2.379156111999976],
              [30.056989671000053, -2.378743885999882],
              [30.05601501500007, -2.377006291999919],
              [30.053819657000133, -2.373878717999958],
              [30.052864075000116, -2.37259101799998],
              [30.050659179000093, -2.369412182999952],
              [30.049785613000154, -2.368001699999979],
              [30.04866790800014, -2.366059540999856],
              [30.047945022000192, -2.36472654399995],
              [30.046897888000046, -2.362941741999975],
              [30.045688629000097, -2.36119079599996],
              [30.04487037600012, -2.360222577999878],
              [30.04378890999999, -2.359272955999927],
              [30.042932510000014, -2.358341931999973],
              [30.04110717700007, -2.356737136999811],
              [30.039419175000035, -2.355409383999927],
              [30.038505555000143, -2.354509829999927],
              [30.037611008000113, -2.35393762599989],
              [30.036838532000104, -2.353201626999919],
              [30.034730910000178, -2.351828096999952],
              [30.03344535800005, -2.350932597999929],
              [30.03096580400006, -2.349399565999931],
              [30.030092240000045, -2.348888396999939],
              [30.029495240000074, -2.348613976999957],
              [30.028535843000157, -2.348080873999891],
              [30.02739906400018, -2.347528934999957],
              [30.026483536000057, -2.347038506999979],
              [30.024898529000097, -2.346241234999923],
              [30.023618697000074, -2.345791578999922],
              [30.021705627000074, -2.345036505999872],
              [30.021261214000162, -2.344804287999978],
              [30.01940345700001, -2.344228743999963],
              [30.016981126000076, -2.343966483999907],
              [30.01561737000003, -2.343971490999877],
              [30.015638351000064, -2.344183205999968],
              [30.01468086300008, -2.344153164999909],
              [30.01402092000012, -2.344179868999959],
              [30.013162612000144, -2.34415006699993],
              [30.011545181000145, -2.344216346999872],
              [30.010589599000127, -2.344382522999979],
              [30.008508682000183, -2.344648122999899],
              [30.007652284000187, -2.344714402999955],
              [30.006099701000096, -2.34498000099984],
              [30.005109787000038, -2.345046518999936],
              [30.00283241300008, -2.345312118999971],
              [30.00194168100012, -2.345112799999924],
              [30.001182556000117, -2.344681261999881],
              [30.000093460000073, -2.344515323999929],
              [29.99933433400014, -2.344349383999884],
              [29.99837684700003, -2.343984126999942],
              [29.997287751000158, -2.34371852899983],
              [29.996631622000166, -2.343421457999853],
              [29.99540710499997, -2.343054532999929],
              [29.99385643000005, -2.342490196999961],
              [29.992864608000048, -2.342224596999927],
              [29.991874695000092, -2.341726540999957],
              [29.99068641600013, -2.34132814499992],
              [29.989795685000047, -2.340597867999975],
              [29.989004135000073, -2.340000152999835],
              [29.988574982000046, -2.339834212999961],
              [29.98798179700009, -2.339435815999934],
              [29.986230850000027, -2.33843994199998],
              [29.985605241000087, -2.337908744999879],
              [29.985570907000067, -2.337742804999948],
              [29.98398780800011, -2.336780071999954],
              [29.98303032000007, -2.336348293999834],
              [29.98154449500015, -2.335916756999836],
              [29.980588913000133, -2.335584877999906],
              [29.979333877000045, -2.335252761999868],
              [29.977981568000132, -2.334854363999966],
              [29.976884842000175, -2.334420680999926],
              [29.975625991000015, -2.333982227999911],
              [29.97475433300019, -2.333714245999943],
              [29.971752167000147, -2.332521199999974],
              [29.970640182000068, -2.332131385999958],
              [29.96950149600019, -2.331547021999938],
              [29.96844863900003, -2.331169603999967],
              [29.967866897000192, -2.330877302999966],
              [29.96651268100004, -2.330268381999929],
              [29.96496200600012, -2.329440354999974],
              [29.96392059200008, -2.329050779999818],
              [29.963510513000188, -2.328734159999897],
              [29.96285629200014, -2.328539370999977],
              [29.96246910100018, -2.328149794999945],
              [29.962009430000023, -2.328076838999948],
              [29.96150016900009, -2.327687025999978],
              [29.959877015000075, -2.32684016099995],
              [29.959217072000115, -2.326900958999943],
              [29.958921433000057, -2.327433823999968],
              [29.958148957000105, -2.328210829999932],
              [29.957283021000023, -2.328811167999959],
              [29.956125258999975, -2.329327582999895],
              [29.955844879000097, -2.329788206999979],
              [29.95557975900016, -2.330807208999943],
              [29.955844879000097, -2.332314729999951],
              [29.955760955000073, -2.333557127999882],
              [29.955860137000116, -2.334338664999905],
              [29.95619392499998, -2.334966896999845],
              [29.95635223500011, -2.335916518999966],
              [29.956083297000077, -2.336460351999961],
              [29.95517540000003, -2.337451457999975],
              [29.955284118000122, -2.338156223999931],
              [29.95531463599997, -2.339824436999948],
              [29.956125258999975, -2.341220379999811],
              [29.956277847000024, -2.342043875999821],
              [29.956560135000075, -2.342462300999955],
              [29.956630708000148, -2.34380578899993],
              [29.957176209000068, -2.344817875999922],
              [29.95746612500011, -2.345842838999886],
              [29.957321168000078, -2.347014188999935],
              [29.957176209000068, -2.34774613299993],
              [29.957176209000068, -2.349027155999863],
              [29.956811904000062, -2.349832533999972],
              [29.955938338000067, -2.350418090999938],
              [29.954555511000137, -2.350784062999935],
              [29.95411872800014, -2.351955412999928],
              [29.953390120999984, -2.353639126999894],
              [29.952808379000032, -2.355103252999868],
              [29.951862335000044, -2.355762003999928],
              [29.95062446600008, -2.357226132999926],
              [29.949605941000073, -2.357738493999875],
              [29.94873237600018, -2.358543871999927],
              [29.948150635000104, -2.358836649999887],
              [29.947931290000156, -2.360849856999835],
              [29.947132110000098, -2.361069439999881],
              [29.946331025000177, -2.361654996999903],
              [29.946331025000177, -2.362533330999952],
              [29.946039198999983, -2.36319231899995],
              [29.94472885100015, -2.363631486999964],
              [29.944147110000017, -2.364427804999934],
              [29.94436645600007, -2.36546158799996],
              [29.943796157000065, -2.366225718999942],
              [29.94372940100004, -2.366766691999942],
              [29.94383621100019, -2.367241143999934],
              [29.944082260000187, -2.367702007999924],
              [29.944211958999972, -2.368624448999981],
              [29.9440898900001, -2.369091510999965],
              [29.94387626600019, -2.369532583999956],
              [29.943529129000183, -2.369993447999832],
              [29.943668366000054, -2.370895384999926],
              [29.94388961800007, -2.371777056999974],
              [29.94390296900019, -2.372264861999895],
              [29.944068909000066, -2.372966289999965],
              [29.944356918000153, -2.373433828999907],
              [29.945566177000103, -2.373975036999923],
              [29.946073533000174, -2.37443590099997],
              [29.94722938500007, -2.375317811999935],
              [29.947484970000176, -2.375631094999903],
              [29.94782447800003, -2.3762798299999],
              [29.947803497000166, -2.377081392999912],
              [29.94787025500017, -2.37743544499989],
              [29.948150635000104, -2.377577303999885],
              [29.94924163900015, -2.378382683999917],
              [29.950222015000065, -2.379172562999941],
              [29.95054245, -2.380134342999895],
              [29.950595855000074, -2.380688904999886],
              [29.950864793000108, -2.381697653999936],
              [29.951217652000082, -2.382165430999919],
              [29.951601028000027, -2.382483005999916],
              [29.952058791000184, -2.383047104999946],
              [29.95237350399998, -2.384029150999936],
              [29.95229148900006, -2.38452148399989],
              [29.952072144000113, -2.385044573999949],
              [29.95214653000005, -2.385552406999921],
              [29.952386857000135, -2.386026619999939],
              [29.95277786200006, -2.386472702999868],
              [29.953048705000185, -2.387275932999899],
              [29.95360374400019, -2.387669800999902],
              [29.953870774000052, -2.388030767999908],
              [29.95417022800018, -2.388745546999928],
              [29.95473098700012, -2.389674662999937],
              [29.954618454000126, -2.391016960999934],
              [29.95483970700002, -2.39213919599996],
              [29.954965592000065, -2.393341779999958],
              [29.954818726000155, -2.39593386599995],
              [29.955278397000086, -2.397722004999935],
              [29.95530128600012, -2.397955178999951],
              [29.955711365000013, -2.398751497999854],
              [29.956548691000023, -2.400971649999974],
              [29.956773757000178, -2.401446103999945],
              [29.957056045000115, -2.401824234999879],
              [29.957344054000032, -2.402419566999924],
              [29.957698823000158, -2.403473138999971],
              [29.95845413100011, -2.404579161999948],
              [29.95884895400019, -2.405020951999973],
              [29.959058762000154, -2.405361890999927],
              [29.95960235500013, -2.407059192999952],
              [29.95965576200018, -2.407679080999913],
              [29.9597797400001, -2.408330201999945],
              [29.96024513200007, -2.409616469999889],
              [29.960369111000148, -2.410221099999831],
              [29.96041488600008, -2.411127805999968],
              [29.960601807000046, -2.411918162999825],
              [29.961090087000116, -2.41268539399988],
              [29.96146965000014, -2.413142679999965],
              [29.962034226000128, -2.414467811999941],
              [29.962059020000083, -2.415134430999842],
              [29.96164703300019, -2.415769815999909],
              [29.96139144900019, -2.416800498999919],
              [29.961360932000048, -2.417405127999871],
              [29.96103477400004, -2.419458627999973],
              [29.96114349400017, -2.4197919369999],
              [29.961492538000073, -2.420404194999946],
              [29.961360932000048, -2.420876979999889],
              [29.961360932000048, -2.421961783999961],
              [29.961778642000127, -2.422550915999921],
              [29.96212005500007, -2.423287152999933],
              [29.961965561000056, -2.423775195999951],
              [29.961267471000042, -2.423992395999903],
              [29.961027146000106, -2.424519300999975],
              [29.961042405000057, -2.425441503999934],
              [29.961399079000103, -2.426014900999917],
              [29.96168708800019, -2.426294088999953],
              [29.961563109000167, -2.42664265499991],
              [29.96155548100006, -2.427169799999831],
              [29.961654663000104, -2.427564858999915],
              [29.961368560000153, -2.427890538999975],
              [29.961290359000145, -2.428394079999976],
              [29.961593628000116, -2.428587913999934],
              [29.96175575300009, -2.429765938999935],
              [29.961965561000056, -2.43037033],
              [29.96200370700018, -2.430897234999861],
              [29.962127686000088, -2.431478498999979],
              [29.962314607000167, -2.431904791999898],
              [29.962182998, -2.43214511799988],
              [29.962198257000125, -2.433175800999834],
              [29.962476730000162, -2.43391966899992],
              [29.96252250700013, -2.434198616999879],
              [29.962802887000066, -2.434896229999879],
              [29.962825776000102, -2.435128687999963],
              [29.962577819000103, -2.43556380299998],
              [29.962558746000184, -2.435949563999827],
              [29.962846757000136, -2.436635732999946],
              [29.96320152300018, -2.438006877999953],
              [29.963367461000075, -2.438337562999948],
              [29.96362495300002, -2.438503025999978],
              [29.964157105000027, -2.438503025999978],
              [29.96439552200013, -2.438631533999967],
              [29.965076446000126, -2.439311265999891],
              [29.965002060000018, -2.439697026999909],
              [29.96435928300008, -2.440505264999899],
              [29.964294434000124, -2.440718889999914],
              [29.96451950000005, -2.441216945999827],
              [29.965129852000132, -2.441939829999967],
              [29.965370178000114, -2.442004202999954],
              [29.96599769700009, -2.442486047999921],
              [29.966447830000106, -2.443184852999934],
              [29.96660423300017, -2.443992137999942],
              [29.966821671000048, -2.444751261999954],
              [29.967050552000046, -2.445100784999909],
              [29.96737480200005, -2.445401906999962],
              [29.967628478999984, -2.445799587999829],
              [29.967603683999982, -2.44619727099996],
              [29.96729087800003, -2.447124957999961],
              [29.96720695600004, -2.447655199999872],
              [29.966556548000028, -2.448257684999931],
              [29.96616935700007, -2.448450326999932],
              [29.965784072000076, -2.44925761199994],
              [29.965675354000155, -2.44996857599989],
              [29.96595382700019, -2.450510740999903],
              [29.966411591000053, -2.450896261999901],
              [29.966651917000036, -2.451824187999932],
              [29.967050552000046, -2.452426670999955],
              [29.967399598000156, -2.452547073999881],
              [29.968254090000187, -2.452655553999819],
              [29.968194962000098, -2.453920602999858],
              [29.96838569600004, -2.45458698199991],
              [29.96843338100018, -2.455293892999976],
              [29.968338012000174, -2.456161498999904],
              [29.96811294600002, -2.456884384999967],
              [29.96812820400004, -2.45754313499998],
              [29.968338012000174, -2.458217859999877],
              [29.968690873000185, -2.458507060999978],
              [29.968755721000036, -2.458972929999902],
              [29.96952819800009, -2.46018862699998],
              [29.970521927000163, -2.461047171999951],
              [29.969980241000087, -2.462131500999931],
              [29.969264985000052, -2.462483881999958],
              [29.969076156000142, -2.462709902999848],
              [29.968643188000044, -2.464056252999967],
              [29.968597411000076, -2.464444875999959],
              [29.968254090000187, -2.465023278999922],
              [29.968254090000187, -2.465701102999958],
              [29.968191147000027, -2.466044425999883],
              [29.96829032900007, -2.466360806999944],
              [29.96829032900007, -2.467002390999937],
              [29.968198775000133, -2.467381953999961],
              [29.96759414700017, -2.467915056999914],
              [29.967540742000097, -2.468863963999922],
              [29.96730423000008, -2.469767570999977],
              [29.966943741000193, -2.470300673999873],
              [29.966823578000117, -2.471020936999878],
              [29.96669006400009, -2.471200227999873],
              [29.966835021000065, -2.471947430999876],
              [29.967197418000126, -2.472477435999963],
              [29.967269897000165, -2.473152398999844],
              [29.96737670900012, -2.473489760999939],
              [29.967473984000094, -2.474308966999956],
              [29.968051911000032, -2.474610327999926],
              [29.96846199000015, -2.474935530999858],
              [29.968690873000185, -2.475292919999959],
              [29.968658447000166, -2.47579097799985],
              [29.96880340600012, -2.476433752999981],
              [29.968690873000185, -2.477108477999877],
              [29.96827316300005, -2.477558135999971],
              [29.96808052, -2.478329419999909],
              [29.967710495000063, -2.478554247999966],
              [29.96759796200007, -2.478923797999869],
              [29.967903136000075, -2.479582547999939],
              [29.967950821000045, -2.479968070999973],
              [29.967823028000055, -2.480578659999935],
              [29.967870712000092, -2.481703042999868],
              [29.967710495000063, -2.482233284999893],
              [29.967325211000116, -2.48305273099993],
              [29.96747016900008, -2.483775614999956],
              [29.967742919000045, -2.484112977999871],
              [29.967840196000054, -2.484530687999893],
              [29.967790604000186, -2.48504471699988],
              [29.967309951000118, -2.485880135999935],
              [29.967197418000126, -2.486410378999892],
              [29.96687507600012, -2.486795901999926],
              [29.966264725000144, -2.487020731999962],
              [29.965364457000078, -2.487068891999968],
              [29.965188979000118, -2.487213610999959],
              [29.964498519000188, -2.488787889999969],
              [29.964193345000183, -2.489173649999941],
              [29.96390342600006, -2.48931813199988],
              [29.963003159000095, -2.48952698699992],
              [29.962072373000183, -2.489511012999969],
              [29.961446761000104, -2.489164351999875],
              [29.960807800999987, -2.48909926399989],
              [29.959831238000106, -2.489424704999919],
              [29.959489822000137, -2.489804266999954],
              [29.959016800000086, -2.490009784999927],
              [29.957302094000113, -2.490143060999912],
              [29.957019806, -2.490104198999916],
              [29.95608520500008, -2.489533184999914],
              [29.955783843000177, -2.489557265999963],
              [29.955589294000163, -2.490231036999944],
              [29.95494079600013, -2.490846395999938],
              [29.954397201000177, -2.491435765999938],
              [29.95344543400006, -2.491509199999882],
              [29.95302391100006, -2.491629599999897],
              [29.95283126800001, -2.491918801999873],
              [29.95238304100019, -2.49288153699996],
              [29.951448440000036, -2.494271278999918],
              [29.95077896100014, -2.496214149999901],
              [29.950191499000027, -2.496470211999963],
              [29.949430466000024, -2.496608495999851],
              [29.948747636000178, -2.496502638999971],
              [29.948335647000135, -2.49652624],
              [29.948051453000062, -2.496647119999921],
              [29.94766616800007, -2.496620416999861],
              [29.947158814000034, -2.496749877999889],
              [29.946512222000138, -2.497126340999955],
              [29.946241379000014, -2.497355938999874],
              [29.945558549000054, -2.49729943299991],
              [29.945106506, -2.497132300999851],
              [29.94423103400004, -2.497908829999972],
              [29.943904877000136, -2.498103140999888],
              [29.943683624000073, -2.49808716799987],
              [29.942873001000066, -2.498244284999942],
              [29.94272232100019, -2.498403309999958],
              [29.94264412000001, -2.498915194999938],
              [29.94281196600008, -2.5001068099999],
              [29.943181991000017, -2.500671623999949],
              [29.94289016700003, -2.501024722999944],
              [29.94256401100006, -2.50119233099997],
              [29.942096710000044, -2.50163364399998],
              [29.941143035000096, -2.502939939999976],
              [29.94093132000006, -2.504413841999963],
              [29.940799714000036, -2.504916905999949],
              [29.940736771000047, -2.505490779999889],
              [29.94008445800017, -2.50631165599998],
              [29.939916610000125, -2.506814716999941],
              [29.939544677000185, -2.507476567999959],
              [29.93942260700004, -2.50820040699989],
              [29.93906021200013, -2.509153603999891],
              [29.93906021200013, -2.509418486999891],
              [29.938882829000136, -2.51000976399996],
              [29.938821793000045, -2.510468720999938],
              [29.938865661000136, -2.510901212999897],
              [29.937961578, -2.511924981999869],
              [29.93806076000004, -2.512262581999949],
              [29.938579560000164, -2.513027905999934],
              [29.93855476400006, -2.513349055999925],
              [29.938671112000122, -2.514410732999863],
              [29.938852310000186, -2.514863490999915],
              [29.938817977999975, -2.515324352999869],
              [29.938390731000084, -2.5157854549999],
              [29.93801117000004, -2.516707180999958],
              [29.937608719000025, -2.517538546999845],
              [29.937566757000127, -2.518180608999955],
              [29.937015534000068, -2.518707273999894],
              [29.936588287000177, -2.519209384999954],
              [29.936275482000156, -2.519785641999874],
              [29.93597030699999, -2.519958495999958],
              [29.935174942, -2.520102023999925],
              [29.934720994000145, -2.519897221999941],
              [29.934501648000094, -2.519911765999893],
              [29.93414497400005, -2.520251750999876],
              [29.93411254800003, -2.520653247999974],
              [29.934661866, -2.521986245999869],
              [29.93487930400005, -2.522275446999913],
              [29.93560218800019, -2.524155139999891],
              [29.935613633000173, -2.524926185999959],
              [29.935493470000097, -2.525528668999868],
              [29.935445785000127, -2.526215552999929],
              [29.93551635800003, -2.526589155999943],
              [29.93548011700011, -2.527167320999922],
              [29.935552598000186, -2.527649402999941],
              [29.93538475000014, -2.528083085999924],
              [29.935323715000152, -2.528565168999933],
              [29.935094834000154, -2.529083252999897],
              [29.934713364, -2.529420613999946],
              [29.93439292900007, -2.530111312999964],
              [29.934375763000048, -2.530962943999896],
              [29.934263230000056, -2.531461],
              [29.934295654000096, -2.531975030999888],
              [29.93424797100016, -2.532794236999905],
              [29.934295654000096, -2.533228158999918],
              [29.934440613000106, -2.533613682999942],
              [29.934328080000114, -2.534127711999929],
              [29.932865142000026, -2.536230562999947],
              [29.932661057000132, -2.53694891899994],
              [29.932329177000042, -2.53725385599995],
              [29.931827546000193, -2.537863968999886],
              [29.93152236900005, -2.538534878999883],
              [29.93122482300015, -2.538799285999914],
              [29.931068420000088, -2.539382217999844],
              [29.930051803000083, -2.539870022999878],
              [29.929807663000133, -2.540073394999922],
              [29.92942237800014, -2.5406496519999],
              [29.92910385100015, -2.541347742999847],
              [29.928968429000122, -2.542154072999949],
              [29.92901420600009, -2.542682884999977],
              [29.929239272000075, -2.544248580999977],
              [29.929651261000117, -2.544966934999877],
              [29.929584503000115, -2.545807361999948],
              [29.9293880460001, -2.546268223999903],
              [29.929035187000125, -2.546593427999824],
              [29.928161621000186, -2.546986578999906],
              [29.92723846300015, -2.547522066999932],
              [29.926643371000125, -2.548112391999894],
              [29.92593383700006, -2.549160718999872],
              [29.92561531000007, -2.550250291999873],
              [29.925592423000012, -2.550901174999979],
              [29.925359725000135, -2.551311969999972],
              [29.925281524000184, -2.551668405999976],
              [29.924949646000073, -2.552288532999967],
              [29.924840926000115, -2.553071259999967],
              [29.924367904000064, -2.553706646999956],
              [29.9236545550001, -2.554171560999976],
              [29.923429490000046, -2.554419754999969],
              [29.922824859, -2.554636715999891],
              [29.922592163000104, -2.555117129999928],
              [29.922336578000056, -2.556062696999959],
              [29.922142028999986, -2.556177854999874],
              [29.921426773000178, -2.55707836199997],
              [29.920980453000027, -2.557316302999936],
              [29.92077064500006, -2.557613849999939],
              [29.92098808300011, -2.558262585999955],
              [29.92068672100004, -2.558927773999926],
              [29.920925140000122, -2.559593199999938],
              [29.920938493000108, -2.560183047999885],
              [29.920837403000064, -2.5605847839999],
              [29.92087555000012, -2.561224936999849],
              [29.92180442800003, -2.561551331999965],
              [29.922105790000103, -2.561789987999873],
              [29.922330856000087, -2.562166452999861],
              [29.922407150000026, -2.563396690999866],
              [29.922306060000153, -2.564425943999936],
              [29.921968461000063, -2.564852713999812],
              [29.921653747000107, -2.565631150999934],
              [29.922721862000117, -2.567137478999939],
              [29.92343711800015, -2.567488907999973],
              [29.924114227000132, -2.567978382999968],
              [29.924152374000187, -2.569233656999927],
              [29.924503326999968, -2.569987056999878],
              [29.92470359700002, -2.570275545999948],
              [29.924604414999976, -2.571242330999951],
              [29.92461586000013, -2.571731805999946],
              [29.92474174600011, -2.572183607999932],
              [29.924930573000154, -2.572246550999978],
              [29.92568397500014, -2.574331760999939],
              [29.926021576000096, -2.574755430999971],
              [29.92644691400011, -2.575419186999852],
              [29.926671982000073, -2.575927495999963],
              [29.926593781000122, -2.576090097999952],
              [29.92617034800014, -2.57623124],
              [29.925655364000193, -2.577361107999934],
              [29.92571258400011, -2.577820061999887],
              [29.926416397000025, -2.578025818999947],
              [29.926805496000156, -2.578386305999857],
              [29.92729949900007, -2.578717468999969],
              [29.927724838000188, -2.578775643999904],
              [29.927915573000064, -2.57895016699996],
              [29.928171157000065, -2.579705714999932],
              [29.92854309200004, -2.580321788999925],
              [29.928735733000053, -2.581007718999956],
              [29.929433823000068, -2.580850600999895],
              [29.92962455800017, -2.58103680499994],
              [29.92978286700003, -2.58182716399989],
              [29.92998504700006, -2.582478044999903],
              [29.929973602000075, -2.58334994299986],
              [29.930521012000042, -2.584041595999963],
              [29.930782319000116, -2.584506511999962],
              [29.930898667000122, -2.585471390999885],
              [29.93082237300007, -2.586000442999932],
              [29.930660248000038, -2.586552620999896],
              [29.930637360000105, -2.587052344999961],
              [29.931299209000088, -2.588749646999872],
              [29.931612014999985, -2.589202879999959],
              [29.93165397700011, -2.589668034999932],
              [29.931560516000104, -2.591295480999918],
              [29.931612014999985, -2.591841696999893],
              [29.931892394000045, -2.592498539999895],
              [29.931793212000173, -2.592719316999876],
              [29.93110084400007, -2.593527317999929],
              [29.930717469000058, -2.594869850999828],
              [29.930728912000063, -2.595282554999869],
              [29.931171417000144, -2.596055745999877],
              [29.931154252000056, -2.596386908999932],
              [29.93144989000018, -2.59731698],
              [29.931594850000124, -2.598334072999933],
              [29.931392670000093, -2.598758457999963],
              [29.93165397700011, -2.599275588999944],
              [29.931339262999984, -2.599810361999971],
              [29.93128776499998, -2.601048469999967],
              [29.931032180999978, -2.601978300999917],
              [29.930561066000166, -2.602548122999906],
              [29.930374145000087, -2.603355883999939],
              [29.93048477300016, -2.603751181999883],
              [29.930805206000116, -2.604326485999877],
              [29.931123733000106, -2.604721784999811],
              [29.931142806000025, -2.605285643999935],
              [29.93094444200011, -2.606087683999931],
              [29.93125915400003, -2.606843231999903],
              [29.93119049100011, -2.607395647999965],
              [29.930496216000165, -2.607964991999836],
              [29.930160523000154, -2.608337163999977],
              [29.930149079000103, -2.608546256999944],
              [29.930624009000155, -2.609342573999925],
              [29.93082237300007, -2.610231875999887],
              [29.93084526000007, -2.61057472199991],
              [29.93073463500008, -2.611533878999978],
              [29.930660248000038, -2.612800835999906],
              [29.930660248000038, -2.614004134999902],
              [29.93038559000007, -2.614811897999971],
              [29.93031692500017, -2.615323543999921],
              [29.93047332700013, -2.616195201999915],
              [29.930278778000115, -2.616828679999912],
              [29.930212020000056, -2.617741344999956],
              [29.930728912000063, -2.619328021999877],
              [29.931026458000133, -2.619944332999978],
              [29.930728912000063, -2.620659111999942],
              [29.930351257000154, -2.621275185999934],
              [29.93014144900019, -2.622071504999951],
              [29.930200577000107, -2.622333049999895],
              [29.93067741400006, -2.622641086999977],
              [29.930887221999967, -2.622867822999922],
              [29.931043624000154, -2.62348961899994],
              [29.930496216000165, -2.623989582999911],
              [29.930322648000185, -2.624309299999936],
              [29.93007850600003, -2.625134705999926],
              [29.929927827000085, -2.626018047999878],
              [29.929769517000125, -2.6264307499999],
              [29.929758071000094, -2.6268317709999],
              [29.92990493800005, -2.627215385999932],
              [29.930160523000154, -2.627529382999967],
              [29.929944992000173, -2.628145454999981],
              [29.929433823000068, -2.62886023599998],
              [29.929141998000034, -2.628924130999906],
              [29.928974152000137, -2.629220722999946],
              [29.928787231000058, -2.630162238999958],
              [29.92854309200004, -2.630644559999894],
              [29.928264619000174, -2.631795405999981],
              [29.928110122000078, -2.632745265999972],
              [29.928651810000133, -2.633868217999975],
              [29.92876243700016, -2.63420915599994],
              [29.929334641000025, -2.634780882999905],
              [29.92998504700006, -2.635873794999952],
              [29.93095779500004, -2.636194704999923],
              [29.93130874600007, -2.636204719999967],
              [29.931720733000134, -2.635973928999931],
              [29.93188095100004, -2.636003970999866],
              [29.93250274700017, -2.636475324999879],
              [29.933374406000098, -2.636786221999842],
              [29.9341163630001, -2.637548207999828],
              [29.934587478000083, -2.637949226999979],
              [29.93489837700014, -2.638420580999878],
              [29.93548011700011, -2.63944339699998],
              [29.93570137000006, -2.640014885999904],
              [29.93629264800012, -2.641294955999967],
              [29.93627929700017, -2.641896723999935],
              [29.935651780000057, -2.643140077999931],
              [29.93569946200006, -2.644328117999919],
              [29.935237885000163, -2.64546608899991],
              [29.93486976600019, -2.646971939999901],
              [29.934663773000068, -2.647390603999895],
              [29.93464660600017, -2.648554085999876],
              [29.93448066700006, -2.648913620999963],
              [29.93417167600012, -2.649844168999948],
              [29.93394470200019, -2.650834082999893],
              [29.933465958, -2.651455879999901],
              [29.93340683000008, -2.651883124999927],
              [29.932521821000137, -2.652361154999937],
              [29.93239975000006, -2.652708052999969],
              [29.931934356000113, -2.653638601999944],
              [29.93191337500008, -2.653905151999879],
              [29.932048797999983, -2.654311180999912],
              [29.93183708100014, -2.655009268999947],
              [29.93162155100009, -2.655326365999883],
              [29.931299209000088, -2.655343293999977],
              [29.930597306000152, -2.655905960999974],
              [29.930023194000114, -2.65619778699994],
              [29.92971801700014, -2.656692742999951],
              [29.92954826400006, -2.656773088999955],
              [29.92886734000018, -2.65744566799998],
              [29.929012299000192, -2.65799999199993],
              [29.929092407999974, -2.65948891599993],
              [29.92862320000006, -2.659848450999959],
              [29.928606033000165, -2.660047291999945],
              [29.92899894800007, -2.661688566999942],
              [29.92921447800012, -2.661836623999932],
              [29.93020820700019, -2.662081956999828],
              [29.930694580000022, -2.662538766999944],
              [29.930746078000027, -2.663456916999905],
              [29.93047142000006, -2.664256333999958],
              [29.93028450100013, -2.664527176999911],
              [29.930263520000096, -2.665267228999937],
              [29.930114746000186, -2.665445089999935],
              [29.92936325, -2.665838478999945],
              [29.92910385100015, -2.666235922999874],
              [29.92888450600003, -2.666785955999842],
              [29.928863525000168, -2.668431519999899],
              [29.92850875800019, -2.669412850999947],
              [29.928533554000126, -2.672166824999977],
              [29.92825508100009, -2.672780036999939],
              [29.927911758000164, -2.67325806599996],
              [29.927721023000117, -2.674184559999958],
              [29.927429200000063, -2.675072908999937],
              [29.927373887000158, -2.675660848999883],
              [29.926918031000127, -2.675999164999894],
              [29.927053452000052, -2.676836728999945],
              [29.926942825000026, -2.677424906999931],
              [29.926536560000102, -2.677818297999977],
              [29.926376343000072, -2.678342819999955],
              [29.92588997000007, -2.678994177999925],
              [29.925664902000108, -2.68008971199987],
              [29.92549705400006, -2.680381774999887],
              [29.925258636000024, -2.681088207999892],
              [29.92522621100011, -2.681464670999958],
              [29.925664902000108, -2.68204402799995],
              [29.925664902000108, -2.682437418999882],
              [29.925500869000075, -2.68280982899995],
              [29.92499733000011, -2.682805538999958],
              [29.92451477100002, -2.68323278399987],
              [29.92452240100016, -2.683456896999928],
              [29.92480659400013, -2.683884143999933],
              [29.92429924000004, -2.684387682999954],
              [29.924163819000114, -2.685123681999926],
              [29.92421913200019, -2.685851335999928],
              [29.924177170000064, -2.686426638999819],
              [29.923728944000118, -2.687424897999904],
              [29.9236564630001, -2.68789863499984],
              [29.923351288000163, -2.688469648999899],
              [29.922475815000098, -2.68929886799998],
              [29.92075347900004, -2.691650867999954],
              [29.920255661000056, -2.692052601999819],
              [29.919996261000108, -2.69245886799996],
              [29.919769288000055, -2.693321703999914],
              [29.919395446000067, -2.693833589999827],
              [29.919324875000143, -2.694531440999924],
              [29.919172288000027, -2.694844482999883],
              [29.919218063000187, -2.695513008999967],
              [29.918790817, -2.695935963999943],
              [29.91884994500009, -2.697077989999968],
              [29.918664932000183, -2.697526453999842],
              [29.918655396000077, -2.698321818999943],
              [29.91825485200019, -2.698537587999908],
              [29.918241501000068, -2.699200629999893],
              [29.91869354300013, -2.699924945999953],
              [29.918714524, -2.700221061999969],
              [29.918609619000108, -2.700948713999935],
              [29.918672562000097, -2.701769351999872],
              [29.91862678500013, -2.702162742999917],
              [29.918449401000032, -2.702661991999946],
              [29.91794967600015, -2.703097581999941],
              [29.917682648000095, -2.703681468999832],
              [29.916893006000123, -2.704197405999878],
              [29.91626167400011, -2.704798221999965],
              [29.915550233000147, -2.705749988999912],
              [29.914997100000107, -2.706240653999942],
              [29.914907455000048, -2.70651125899991],
              [29.914569855000025, -2.707014797999932],
              [29.914222718000133, -2.707340478999868],
              [29.91343688900008, -2.707505463999951],
              [29.912982940000063, -2.707822560999887],
              [29.91255951000005, -2.708232879999912],
              [29.91205215400015, -2.70963311099996],
              [29.912006377000182, -2.710348128999954],
              [29.911672592000173, -2.710788011999853],
              [29.911472320000144, -2.711930035999842],
              [29.911262512000178, -2.712370156999953],
              [29.910791397000025, -2.713110445999973],
              [29.910694123000155, -2.713448761999928],
              [29.910741805000157, -2.713876009999979],
              [29.910558701000127, -2.714358329999925],
              [29.91013717700008, -2.714717863999852],
              [29.90963363600008, -2.715487717999963],
              [29.909179687000062, -2.7155935749999],
              [29.908309936000137, -2.716050386999882],
              [29.907737732000044, -2.716443776999938],
              [29.90716362000012, -2.717158555999902],
              [29.906858443000033, -2.717848062999849],
              [29.906692505000137, -2.71841931299997],
              [29.906469345000176, -2.718723773999898],
              [29.906007766000073, -2.718964815999868],
              [29.905305863000024, -2.718990326999858],
              [29.904819488000157, -2.719311713999957],
              [29.904172897000024, -2.720005511999943],
              [29.9040374760001, -2.720267771999943],
              [29.903339386000084, -2.721266030999857],
              [29.902923584000177, -2.721359252999946],
              [29.902446747000056, -2.721219538999833],
              [29.90215492300007, -2.721452234999902],
              [29.902179719000173, -2.721706151999968],
              [29.902433396000106, -2.722154377999971],
              [29.902509689000112, -2.723393915999964],
              [29.90201377900013, -2.723516463999886],
              [29.901971817000174, -2.724447249999969],
              [29.902284622000025, -2.724882840999953],
              [29.902090072000135, -2.725267885999926],
              [29.901193618000036, -2.725817678999874],
              [29.90147781400009, -2.726223945999891],
              [29.901481629000102, -2.726735591999955],
              [29.901002884000036, -2.727239130999976],
              [29.90085601700008, -2.727492809999831],
              [29.90073776200012, -2.728008984999974],
              [29.900560378000023, -2.728305100999876],
              [29.899894714000027, -2.728871821999974],
              [29.899602891000086, -2.729235648999975],
              [29.899477005000165, -2.729806660999941],
              [29.89913749700014, -2.729840517999946],
              [29.898786545000064, -2.730145214999823],
              [29.89822006300011, -2.729916810999953],
              [29.897764207000023, -2.729891299999963],
              [29.89768219100017, -2.730064867999943],
              [29.897560120000094, -2.730931996999971],
              [29.89691352700015, -2.731321096999864],
              [29.89633369500018, -2.731490372999929],
              [29.89628219500014, -2.731701849999922],
              [29.895919800000115, -2.732378721999965],
              [29.896194458000082, -2.732975244999921],
              [29.896087646000126, -2.733233213999881],
              [29.896308899000076, -2.733791589999896],
              [29.896041869000157, -2.734121560999938],
              [29.895572662000177, -2.734536169999956],
              [29.894857406000142, -2.734396456999946],
              [29.8945274350001, -2.734506368999973],
              [29.894100190000188, -2.734794139999963],
              [29.89368057300004, -2.735365151999872],
              [29.893655777000106, -2.735585211999876],
              [29.893901826000103, -2.736232279999967],
              [29.893655777000106, -2.736735820999968],
              [29.892950059000157, -2.737078426999972],
              [29.892555238000057, -2.73733210599994],
              [29.89246749900019, -2.737954139999943],
              [29.893033981000144, -2.738440512999887],
              [29.893266679000078, -2.738909959999887],
              [29.89388084500007, -2.739502190999872],
              [29.89392662000006, -2.739747523999938],
              [29.893795014000148, -2.740335701999925],
              [29.893774033000113, -2.740872859999968],
              [29.893402100000174, -2.741448163999962],
              [29.893423081000037, -2.742298363999964],
              [29.893123627000136, -2.743064164999908],
              [29.892755508000107, -2.743309496999871],
              [29.892576217000112, -2.743550537999909],
              [29.891853332999972, -2.743542194999918],
              [29.891654968000182, -2.743664740999861],
              [29.891738892000035, -2.744197845999906],
              [29.891578675000176, -2.744417667999812],
              [29.891063691000056, -2.744642018999969],
              [29.89119338900008, -2.745331525999916],
              [29.89163780199999, -2.745513439999968],
              [29.89228057800011, -2.745669841999927],
              [29.892961502000105, -2.745551346999889],
              [29.89336395300012, -2.745822191999935],
              [29.893529891000185, -2.746566533999953],
              [29.89351654100011, -2.746850012999971],
              [29.893106461000116, -2.747336624999832],
              [29.892658233000134, -2.747395752999921],
              [29.89240455600003, -2.747565030999965],
              [29.89241981400005, -2.748224973999868],
              [29.891748428000085, -2.74885511399998],
              [29.890949250000062, -2.749045609999939],
              [29.89086341900014, -2.749650477999978],
              [29.89044189399999, -2.750014304999979],
              [29.890148163000106, -2.750534534999929],
              [29.89001846400015, -2.750910997999881],
              [29.889793396000186, -2.751105546999895],
              [29.889289855000186, -2.751185892999899],
              [29.889024735000078, -2.751042126999892],
              [29.888935089000142, -2.750665663999939],
              [29.88865089500007, -2.750230072999955],
              [29.887847900000168, -2.750005722999958],
              [29.887580872000058, -2.750170707999928],
              [29.88711166300004, -2.750204561999965],
              [29.88687133700006, -2.75044989599985],
              [29.886438369000132, -2.751084565999975],
              [29.886194230000115, -2.751173256999948],
              [29.885566711000138, -2.751055239999914],
              [29.885152816000073, -2.751422881999872],
              [29.884286881000094, -2.751363753999954],
              [29.883821486000045, -2.751955746999897],
              [29.883085252000058, -2.752239226999905],
              [29.88269996600019, -2.752268790999892],
              [29.882324219000054, -2.752454994999937],
              [29.881738663000135, -2.752370355999858],
              [29.881282807000048, -2.752594708999936],
              [29.880977631000064, -2.752010821999932],
              [29.88058090200019, -2.751799343999949],
              [29.880157470000142, -2.75202345699995],
              [29.87928199800018, -2.752091167999936],
              [29.879070281000168, -2.752539633999902],
              [29.878723145000038, -2.753013370999952],
              [29.87877845700018, -2.754087923999862],
              [29.87936973500007, -2.754853486999878],
              [29.87941360500014, -2.755242585999895],
              [29.87988281200012, -2.755538701999967],
              [29.880237579000095, -2.755949020999935],
              [29.880567551000013, -2.75577569],
              [29.881147384000144, -2.756232499999953],
              [29.881101609000154, -2.756490468999971],
              [29.880538940000065, -2.756642818999865],
              [29.880195619000176, -2.756879806999848],
              [29.879987717, -2.757315397999889],
              [29.879489898000145, -2.757873773999904],
              [29.878942490000156, -2.758051394999882],
              [29.87901878400004, -2.758466003999899],
              [29.87927818300011, -2.759032963999914],
              [29.879184724000083, -2.75927829699998],
              [29.878494262000174, -2.759866236999926],
              [29.87792015000008, -2.760585307999918],
              [29.87761497499997, -2.760847567999974],
              [29.87742423899999, -2.761579512999958],
              [29.877395630000024, -2.761968612999965],
              [29.877128600000162, -2.762006759999963],
              [29.876367568999967, -2.761499165999965],
              [29.876028061000113, -2.76158380499993],
              [29.874683380000192, -2.762137888999973],
              [29.874210358000084, -2.762155771999915],
              [29.87357521100006, -2.761778353999944],
              [29.873004914000035, -2.761676786999885],
              [29.872669221000024, -2.761769770999877],
              [29.87252616900014, -2.761985538999966],
              [29.87200546299999, -2.762142180999945],
              [29.871316910000132, -2.762048959999959],
              [29.871177674000023, -2.761769770999877],
              [29.87150192199999, -2.761477947999879],
              [29.871240617000126, -2.761274813999933],
              [29.870740891000025, -2.761270760999878],
              [29.86982345500013, -2.76108884699994],
              [29.86928558300002, -2.760813711999901],
              [29.868680954000183, -2.76088142499998],
              [29.868202209000117, -2.760746000999973],
              [29.86793708700003, -2.760285138999961],
              [29.86702728200015, -2.759997367999972],
              [29.866405488000055, -2.76004385799996],
              [29.865367889000083, -2.760610818999908],
              [29.864616394000166, -2.760454176999929],
              [29.864175796000097, -2.760636090999867],
              [29.86375808600019, -2.760631799999942],
              [29.86358833300011, -2.760966061999966],
              [29.863300324000136, -2.761012553999933],
              [29.86225128300015, -2.760991572999956],
              [29.862039566000135, -2.761274813999933],
              [29.861413956000035, -2.761634349999952],
              [29.860990524000158, -2.761799334999921],
              [29.86094474700019, -2.762290238999867],
              [29.860668182000154, -2.762641191999933],
              [29.860652923000032, -2.763267278999877],
              [29.860267640000075, -2.763643741999942],
              [29.859979629000122, -2.763783455999942],
              [29.85948562599998, -2.763783455999942],
              [29.859132767, -2.764240979999954],
              [29.85840797500009, -2.764369010999928],
              [29.85815429600018, -2.763975381999956],
              [29.85791778500004, -2.764020681999966],
              [29.85766792300018, -2.764362572999914],
              [29.85745048500013, -2.76399779299993],
              [29.857015610000076, -2.764087437999876],
              [29.856760026000075, -2.764010666999923],
              [29.856443405999983, -2.763735532999874],
              [29.856058120000114, -2.764039515999912],
              [29.855649949000167, -2.763665197999956],
              [29.85555076600002, -2.763300417999972],
              [29.85480880700004, -2.762746809999896],
              [29.854234695000116, -2.763402699999972],
              [29.85383796600007, -2.763537168999903],
              [29.853733064000096, -2.763706684999931],
              [29.85373878400003, -2.764305114999957],
              [29.85341072100016, -2.764768838999942],
              [29.853013993000047, -2.765008925999837],
              [29.8532791130001, -2.765479087999836],
              [29.853322982000122, -2.765987871999926],
              [29.853246689000116, -2.766624688999855],
              [29.852884292000056, -2.766807079999921],
              [29.85214042700005, -2.766970157999936],
              [29.85124397200019, -2.767014979999885],
              [29.850837707999972, -2.767229317999977],
              [29.85025596600019, -2.766858099999979],
              [29.850086213000054, -2.767235754999945],
              [29.849855422000076, -2.767469167999934],
              [29.849090576000037, -2.76782131199991],
              [29.848264695000182, -2.767914056999928],
              [29.84774398800016, -2.76835227],
              [29.84718131900007, -2.768182753999895],
              [29.84600067100007, -2.76807284399996],
              [29.845693587000085, -2.768454074999966],
              [29.844823837000035, -2.768311737999909],
              [29.844562531000122, -2.767976284999861],
              [29.844379425000056, -2.768033026999945],
              [29.844083787000102, -2.76843118599993],
              [29.843544006000116, -2.768368720999888],
              [29.84306144600015, -2.768482445999894],
              [29.843162536000193, -2.768984794999881],
              [29.84279060400013, -2.769295691999957],
              [29.842397690000098, -2.769136428999843],
              [29.84170723, -2.769417046999877],
              [29.84186554000013, -2.769735336999929],
              [29.84152412400016, -2.769864557999938],
              [29.841495513000154, -2.770190715999945],
              [29.841009140000153, -2.770645617999833],
              [29.841138839000166, -2.771191834999911],
              [29.840864181000143, -2.771454572999971],
              [29.840753556000152, -2.771909713999889],
              [29.840560914000037, -2.772294044999967],
              [29.83990478600009, -2.772668121999914],
              [29.83956146200012, -2.773123025999894],
              [29.838872910000134, -2.773507355999925],
              [29.83833694500015, -2.774124144999973],
              [29.837882996000133, -2.77449822299991],
              [29.83740615900001, -2.77525663299997],
              [29.837043762000178, -2.775650976999884],
              [29.836769104000155, -2.775742053999977],
              [29.836193084000115, -2.775448799999879],
              [29.835990905000187, -2.775175809999951],
              [29.835372926000105, -2.774886607999974],
              [29.835041046000185, -2.77449822299991],
              [29.834777832999976, -2.77446794399998],
              [29.834321977000116, -2.774740933999908],
              [29.83399963400018, -2.774720905999971],
              [29.83379745500008, -2.774387119999972],
              [29.83354377800009, -2.77440738599995],
              [29.83359527500005, -2.774912834999952],
              [29.833393096000066, -2.774963379999861],
              [29.833047867, -2.774549007999951],
              [29.832906723000065, -2.774599552999973],
              [29.83285713300006, -2.775258540999857],
              [29.832998275000193, -2.775519608999957],
              [29.83286666900011, -2.776035307999905],
              [29.832441331000098, -2.776813983999944],
              [29.832138062000183, -2.776965617999963],
              [29.83163261300018, -2.777006147999941],
              [29.83123779200008, -2.777784586999871],
              [29.83030891400017, -2.77788591399991],
              [29.829488755000057, -2.777744292999898],
              [29.828447342000118, -2.778158901999916],
              [29.828195572000084, -2.778725145999886],
              [29.82781028700009, -2.779099225999914],
              [29.82763862600018, -2.779089212999907],
              [29.827344895000124, -2.778320549999876],
              [29.827184677000048, -2.778118371999938],
              [29.82664871200018, -2.777804850999928],
              [29.825819016000082, -2.77742075999987],
              [29.825433730000043, -2.777329682999891],
              [29.824707031, -2.776905059999933],
              [29.82376670900004, -2.776652096999953],
              [29.823219299000186, -2.776894807999952],
              [29.822450638000134, -2.77715778299995],
              [29.82170295800006, -2.777167797999823],
              [29.820560455000134, -2.777056692999963],
              [29.819993973000123, -2.776945352999974],
              [29.819498062000036, -2.776510714999972],
              [29.81922531200007, -2.776419639999915],
              [29.818902969000135, -2.776530742999967],
              [29.818498611000052, -2.776389358999893],
              [29.81817436200015, -2.775721787999885],
              [29.81733512800014, -2.775377988999878],
              [29.8172645570001, -2.775186061999875],
              [29.817310332000034, -2.77455568299996],
              [29.816970826000045, -2.77440738599995],
              [29.816404342000055, -2.77450847599988],
              [29.815919877000056, -2.774316309999961],
              [29.814949036000087, -2.774771450999879],
              [29.81452369700014, -2.774518488999888],
              [29.81450843800002, -2.774147510999967],
              [29.814796447000163, -2.773689507999961],
              [29.81492042500014, -2.773174047999873],
              [29.814826966000112, -2.773042201999886],
              [29.81403732400014, -2.773327587999972],
              [29.813325883000175, -2.773236273999942],
              [29.812791825000033, -2.772919653999963],
              [29.81262207000009, -2.772567032999916],
              [29.81207656900017, -2.772142171999917],
              [29.811681748000126, -2.771707534999962],
              [29.811237335000044, -2.771555899999896],
              [29.811096191000047, -2.771353482999871],
              [29.81113624500017, -2.771040200999948],
              [29.811500550000062, -2.770109891999937],
              [29.811439514000142, -2.769937991999882],
              [29.81105613700015, -2.769583939999905],
              [29.81089591900019, -2.769184350999979],
              [29.810798645000148, -2.767990349999934],
              [29.810955047000107, -2.767541408999932],
              [29.810964585000193, -2.767025707999892],
              [29.810419082000067, -2.766226767999967],
              [29.810419082000067, -2.765781878999917],
              [29.809932709000066, -2.765245913999877],
              [29.809497833000137, -2.764487503999931],
              [29.809255600000085, -2.764245032999952],
              [29.809215545000086, -2.763698816999977],
              [29.809619904000044, -2.763334749999956],
              [29.809801102000108, -2.76306176199995],
              [29.809984205999967, -2.762404680999907],
              [29.8099536900001, -2.762000084999841],
              [29.809650421000185, -2.761059760999842],
              [29.809494018000123, -2.760795355999903],
              [29.8091545100001, -2.760645150999892],
              [29.808870316000025, -2.760341643999936],
              [29.80847740100012, -2.760190009999974],
              [29.80821418800008, -2.759502409999925],
              [29.807577133000052, -2.759492396999917],
              [29.80678749100008, -2.758774518999928],
              [29.805929184000036, -2.758238553999888],
              [29.80540275600015, -2.758198021999931],
              [29.805139542000063, -2.757682322999813],
              [29.804260253000052, -2.757106064999903],
              [29.80397796700015, -2.756843089999904],
              [29.80358314600005, -2.756954192999956],
              [29.802976608000165, -2.756782292999958],
              [29.802703858000143, -2.756185769999888],
              [29.802410125999984, -2.755973339999969],
              [29.80205536000011, -2.755427359999942],
              [29.801549911000166, -2.75524544599989],
              [29.800983429000155, -2.754911659999891],
              [29.800600052000163, -2.754992483999899],
              [29.799304963000054, -2.755488156999888],
              [29.798566819000087, -2.75567007099994],
              [29.797576905000028, -2.755720614999859],
              [29.79679679800006, -2.755882500999917],
              [29.795969010000135, -2.755851983999889],
              [29.794784546000187, -2.756054400999915],
              [29.79442215000006, -2.756742000999964],
              [29.794198989000165, -2.756893634999926],
              [29.7935314180001, -2.756964443999834],
              [29.79310607800005, -2.756893634999926],
              [29.79279327300003, -2.757287978999955],
              [29.792631148, -2.757833956999832],
              [29.792388917000153, -2.758198021999931],
              [29.792034150000177, -2.758521556999881],
              [29.79148864700005, -2.759502409999925],
              [29.790882111000144, -2.760008095999865],
              [29.79093360900015, -2.760230541999874],
              [29.791326522000077, -2.760442971999964],
              [29.7914085380001, -2.76103949499992],
              [29.791255950999982, -2.761211394999862],
              [29.79049873300005, -2.76113057099991],
              [29.790447236000148, -2.761595725999939],
              [29.79049873300005, -2.76205062899993],
              [29.790306092000037, -2.762830255999973],
              [29.790082931000143, -2.762910126999884],
              [29.789163590000157, -2.763830422999945],
              [29.789051055000186, -2.764669656999899],
              [29.788843155000052, -2.765178918999936],
              [29.787776947999987, -2.765326975999926],
              [29.78745460500005, -2.765872955999896],
              [29.787069320000057, -2.766247033999946],
              [29.78641319200017, -2.766115664999916],
              [29.785907746000078, -2.765781878999917],
              [29.785232544000166, -2.764921903999891],
              [29.784664155000144, -2.76463937799997],
              [29.783641815000067, -2.765155076999918],
              [29.78283309900013, -2.765286445999948],
              [29.78175163300017, -2.765387535999878],
              [29.781053543000155, -2.765286445999948],
              [29.780275344000188, -2.765771865999966],
              [29.77970886300011, -2.765559433999897],
              [29.77896118200016, -2.765609978999919],
              [29.778602601000046, -2.766040563999923],
              [29.77804947000004, -2.766267299999925],
              [29.777565002000074, -2.765984058999948],
              [29.77721023600003, -2.765599965999968],
              [29.776554107000038, -2.765043734999949],
              [29.77587509100016, -2.7651934619999],
              [29.77562522900007, -2.765111922999893],
              [29.775379180000073, -2.76477289199994],
              [29.775136948000068, -2.764264105999928],
              [29.77473258900011, -2.764048815999956],
              [29.77437210000005, -2.76418614399995],
              [29.774204255000086, -2.764557121999928],
              [29.773889542000063, -2.764791249999917],
              [29.77350616399997, -2.764791249999917],
              [29.772954941000137, -2.764312027999893],
              [29.772560120000037, -2.76429414699993],
              [29.77209281800009, -2.764407872999925],
              [29.771678924000128, -2.764672994999842],
              [29.770231246000094, -2.765491962999931],
              [29.769796371000098, -2.7660982619999],
              [29.76892471200017, -2.76687479099985],
              [29.76879310600009, -2.767613649999873],
              [29.76898193300019, -2.768617629999881],
              [29.768583299000056, -2.769394396999871],
              [29.768190383000046, -2.769914865999965],
              [29.767768860000103, -2.770303725999952],
              [29.767656326, -2.770758389999912],
              [29.767675399000098, -2.771383523999873],
              [29.767562866000105, -2.772374630999877],
              [29.767656326, -2.773107527999969],
              [29.76790237400013, -2.773543117999964],
              [29.768318176000093, -2.774092435999933],
              [29.768468856000084, -2.774544477999939],
              [29.768678666000085, -2.775513409999974],
              [29.768716812000036, -2.776441573999932],
              [29.768602372000146, -2.777275084999928],
              [29.76828002900004, -2.777862309999932],
              [29.76774406400017, -2.778332232999958],
              [29.76697349599999, -2.778582333999964],
              [29.76634788400014, -2.778980015999935],
              [29.7660598760001, -2.779687642999932],
              [29.76551437500018, -2.781442640999956],
              [29.765098573000046, -2.781916379999871],
              [29.76494598300002, -2.782579421999856],
              [29.764722823000056, -2.784222363999902],
              [29.764183045000152, -2.784877298999902],
              [29.763463974000047, -2.785548447999929],
              [29.763296128000036, -2.785801171999879],
              [29.763151169000025, -2.786914586999899],
              [29.762929916000132, -2.78751039499997],
              [29.762918473000127, -2.787813423999921],
              [29.76324844400017, -2.788784026999963],
              [29.763559341000075, -2.789431332999925],
              [29.76353454500014, -2.790126322999811],
              [29.76334571800004, -2.791414022999959],
              [29.763446808000083, -2.792088269999965],
              [29.76326179500012, -2.793655872999977],
              [29.76312637300009, -2.794363735999923],
              [29.76285743800014, -2.795105455999931],
              [29.76219940200008, -2.795661686999949],
              [29.760295868000128, -2.797178505999966],
              [29.759788514000093, -2.798274278999941],
              [29.759351731000095, -2.800465344999964],
              [29.758575440000072, -2.802218436999965],
              [29.75758171100017, -2.804207324999936],
              [29.75533866800015, -2.80855607899997],
              [29.754766464000056, -2.809146165999948],
              [29.753974914000082, -2.81019115499987],
              [29.75319862300006, -2.811033964999979],
              [29.752643585000158, -2.811421632999895],
              [29.751867295000068, -2.811253069999907],
              [29.750888825000118, -2.810747383999967],
              [29.749996185000157, -2.810208080999928],
              [29.74910354600007, -2.809466361999967],
              [29.747686387000158, -2.808134793999898],
              [29.746236802000055, -2.807393073999833],
              [29.745107651000126, -2.807342528999925],
              [29.744180680000113, -2.807881831999964],
              [29.743759155000134, -2.808175800999948],
              [29.742765426000062, -2.809516905999942],
              [29.741113662000146, -2.810275316999935],
              [29.73939323400009, -2.810781000999839],
              [29.73811340300017, -2.811303614999929],
              [29.73672866800007, -2.811521290999963],
              [29.736059190000105, -2.811386823999953],
              [29.73570823600005, -2.811571359999959],
              [29.73513031100009, -2.811724899999945],
              [29.734407425000143, -2.811000345999958],
              [29.73386001600005, -2.810862778999933],
              [29.732324600000027, -2.810957430999963],
              [29.731889724000098, -2.811487913999883],
              [29.730884552000077, -2.811809778999873],
              [29.730548859000066, -2.812146901999881],
              [29.73007011400017, -2.812794922999956],
              [29.72995758100018, -2.813401220999936],
              [29.728839874000187, -2.813685416999874],
              [29.728395463000084, -2.814990997999928],
              [29.728164672000105, -2.815160988999935],
              [29.727718354000103, -2.815192699999898],
              [29.727386476000163, -2.815624712999863],
              [29.72711181599999, -2.815668104999816],
              [29.72639465400016, -2.815227746999881],
              [29.72590255700004, -2.815106152999931],
              [29.72539901800002, -2.815437555999836],
              [29.72515296900019, -2.816143273999956],
              [29.72431755000008, -2.816791534999936],
              [29.72381401100006, -2.817598104999888],
              [29.723640443000136, -2.818231819999937],
              [29.72312355100013, -2.818382023999902],
              [29.722646714000064, -2.817914961999975],
              [29.72260475200011, -2.817468403999897],
              [29.722993849999966, -2.816474674999938],
              [29.722522734999984, -2.815931558999921],
              [29.721797944000173, -2.815754413999912],
              [29.721380234000037, -2.815581559999941],
              [29.720775604000153, -2.815696715999934],
              [29.720371247000173, -2.816028118999952],
              [29.719680787000073, -2.816993236999963],
              [29.71921920800014, -2.817828655999961],
              [29.71924781700011, -2.818289517999972],
              [29.71870040900012, -2.818431138999813],
              [29.71733093200004, -2.817088365999894],
              [29.717132568000125, -2.81675267299994],
              [29.71675109800003, -2.816376447999971],
              [29.71636581400014, -2.81620478699989],
              [29.715896607000104, -2.816198586999917],
              [29.715471267999988, -2.815976618999912],
              [29.714927674000137, -2.816042421999953],
              [29.71445846500012, -2.816658973999893],
              [29.71432113600008, -2.817079542999977],
              [29.713960647000135, -2.817487239999934],
              [29.713638306000064, -2.817607640999881],
              [29.713317871000072, -2.817589281999972],
              [29.71260833800011, -2.817917822999959],
              [29.712078094000105, -2.817955731999973],
              [29.711761474000184, -2.818120241999907],
              [29.71131897000015, -2.818676470999947],
              [29.71103096000013, -2.818829059999871],
              [29.710683823000124, -2.818727492999926],
              [29.710289002000025, -2.818462847999967],
              [29.710004806000143, -2.818385601999864],
              [29.70944786000007, -2.818764924999869],
              [29.70886802600006, -2.819523334999929],
              [29.708484650000116, -2.819704293999905],
              [29.708171845000095, -2.819713115999832],
              [29.707792283000117, -2.819156884999927],
              [29.707717896000133, -2.818831204999981],
              [29.70700073300003, -2.81787443099995],
              [29.706548692000183, -2.817043303999981],
              [29.705982208000023, -2.816660403999947],
              [29.70506477300006, -2.815793037999924],
              [29.704418182000097, -2.815098762999867],
              [29.70444297800003, -2.814454077999926],
              [29.70453071500009, -2.813973664999935],
              [29.704759598000123, -2.813467978999938],
              [29.705276490000074, -2.813063382999928],
              [29.705467224000074, -2.812545298999964],
              [29.705451965000123, -2.812295673999927],
              [29.704555511000024, -2.81173610799982],
              [29.70450592100019, -2.811344146999886],
              [29.70435905400018, -2.811146974999929],
              [29.70335579000016, -2.810943125999927],
              [29.701663971000073, -2.810830115999977],
              [29.701227188000075, -2.810079574999975],
              [29.700752258000193, -2.810002088999966],
              [29.700550079000095, -2.809625386999869],
              [29.699861526000063, -2.809294700999942],
              [29.699062348000155, -2.809331417999886],
              [29.698602677000054, -2.808780191999915],
              [29.69823455700015, -2.808633089999944],
              [29.697666168000126, -2.808293341999956],
              [29.6968383790001, -2.807898282999872],
              [29.696453094000105, -2.807548998999948],
              [29.69597434900004, -2.8068325509999],
              [29.695764542000177, -2.806777477999958],
              [29.695194245000152, -2.806979417999969],
              [29.694597244000192, -2.806859968999959],
              [29.69440460300018, -2.806988715999921],
              [29.69418335000006, -2.807411192999894],
              [29.694257736000168, -2.808026790999861],
              [29.694910049000043, -2.808219670999904],
              [29.69489097600018, -2.80857801499991],
              [29.694662095000183, -2.808752535999872],
              [29.69431114100007, -2.808807849999937],
              [29.693935394000164, -2.808706760999883],
              [29.693504333000135, -2.80847692499998],
              [29.69287872400014, -2.80833005799991],
              [29.69243812600007, -2.807732819999956],
              [29.692308427000114, -2.807356119999952],
              [29.691877365000153, -2.806887625999934],
              [29.691492081000035, -2.80676817799997],
              [29.691032410000105, -2.806501864999973],
              [29.690801621000105, -2.806520222999893],
              [29.690406800000062, -2.806823252999948],
              [29.69002151500007, -2.806749820999812],
              [29.68957138100012, -2.806520222999893],
              [29.68931388900012, -2.805601357999819],
              [29.689241408999976, -2.805105209999965],
              [29.68890953000016, -2.804186581999886],
              [29.688938141000108, -2.80384659799995],
              [29.68882751400014, -2.803570984999965],
              [29.68833160300005, -2.802900313999885],
              [29.68708229000015, -2.802183864999961],
              [29.686861039000064, -2.801926612999978],
              [29.686567305999972, -2.801191568999968],
              [29.6861724850001, -2.800768851999919],
              [29.68505096400014, -2.799859285999958],
              [29.684803008000188, -2.799776791999818],
              [29.684518814000114, -2.799886941999944],
              [29.684280396000133, -2.800171850999902],
              [29.6840686810001, -2.800134896999964],
              [29.683599472000083, -2.799694060999968],
              [29.68313026400017, -2.799859285999958],
              [29.682662965000134, -2.799850225999933],
              [29.68176269500009, -2.800107477999916],
              [29.681293488000108, -2.800125835999836],
              [29.680778504000102, -2.800291298999923],
              [29.67986106900014, -2.800061462999906],
              [29.679410935000192, -2.799813508999932],
              [29.678922653000143, -2.799703120999936],
              [29.678325652000183, -2.798867224999981],
              [29.67782974300013, -2.798628330999918],
              [29.677297591000126, -2.798178194999878],
              [29.676635743000077, -2.798031090999871],
              [29.67636108500011, -2.797902583999928],
              [29.675956726000095, -2.797342060999881],
              [29.675910949000126, -2.796331405999922],
              [29.675514220000082, -2.796055792999937],
              [29.67502784700008, -2.796175240999901],
              [29.674907684000175, -2.795578240999873],
              [29.674907684000175, -2.795164823999869],
              [29.67456817600015, -2.794916629999932],
              [29.67400741700004, -2.794925927999827],
              [29.673566818999973, -2.794393062999973],
              [29.673116683000046, -2.794255255999929],
              [29.672225951000087, -2.794273614999952],
              [29.671913146000065, -2.794209241999965],
              [29.67127037100005, -2.79372239099996],
              [29.67047119099999, -2.792684077999979],
              [29.66979217500017, -2.791903255999841],
              [29.669055938000042, -2.791416405999883],
              [29.66860580400015, -2.791361092999921],
              [29.66836738500001, -2.791434763999973],
              [29.667982102000053, -2.791774509999925],
              [29.667137145000027, -2.791838882999912],
              [29.666915894000113, -2.791673659999958],
              [29.666391373000124, -2.791590928999938],
              [29.665914535000127, -2.791857241999935],
              [29.665353774000152, -2.791719436999927],
              [29.664775849000023, -2.791002988999935],
              [29.66373634300004, -2.789422749999858],
              [29.6629009240001, -2.789119480999887],
              [29.662469864000116, -2.789092063999931],
              [29.66181755100007, -2.788752077999959],
              [29.66161537100004, -2.788357019999978],
              [29.66123771600013, -2.788127183999961],
              [29.66020012000007, -2.787704706999818],
              [29.659061431999987, -2.78775978199991],
              [29.658409119000112, -2.787925241999858],
              [29.65696525600015, -2.787906883999938],
              [29.655725480000058, -2.788136481999914],
              [29.65497207700014, -2.788044689999879],
              [29.654356003000146, -2.787787437999896],
              [29.654127121000045, -2.787833212999942],
              [29.65283394000005, -2.788549898999918],
              [29.652181625000026, -2.788586615999975],
              [29.651308061, -2.788219212999934],
              [29.650638580000134, -2.788191556999948],
              [29.65037155200008, -2.78828358599992],
              [29.649930954000013, -2.787842510999951],
              [29.64930534400014, -2.787824154999839],
              [29.648900986000058, -2.787364720999904],
              [29.64885520900009, -2.78703403499992],
              [29.648386001000176, -2.787144182999953],
              [29.64793586800016, -2.787511824999911],
              [29.647899628000175, -2.787686347999966],
              [29.647504807000132, -2.787621974999979],
              [29.64724731500013, -2.788081406999879],
              [29.64702797000018, -2.788081406999879],
              [29.646558761000165, -2.787814854999965],
              [29.64626503000011, -2.787842510999951],
              [29.645730971999967, -2.787713764999921],
              [29.64545631400017, -2.7874751089999],
              [29.64512634300013, -2.787557601999879],
              [29.64462089500006, -2.787814854999965],
              [29.644308090000038, -2.788127183999961],
              [29.64386749199997, -2.788035391999927],
              [29.643564225000034, -2.788430452999933],
              [29.64371109000018, -2.788880586999937],
              [29.643590927000105, -2.789082765999979],
              [29.642736434000028, -2.7890551079999],
              [29.642377853000085, -2.789165496999885],
              [29.641872406000118, -2.789137838999977],
              [29.64107322600006, -2.788981675999935],
              [29.64076232900004, -2.789018392999878],
              [29.64000892600012, -2.789284943999917],
              [29.63930320800017, -2.789112804999945],
              [29.639041901000155, -2.78915619799983],
              [29.638334275000034, -2.790185212999973],
              [29.638086319000138, -2.790332316999979],
              [29.637838364000118, -2.791168211999946],
              [29.637571335000132, -2.791315315999952],
              [29.6371116630001, -2.791333674999976],
              [29.636856079000097, -2.7918481829999],
              [29.63680076600008, -2.792720793999877],
              [29.63693809500012, -2.793024062999848],
              [29.63680076600008, -2.793235300999925],
              [29.636074067000038, -2.793814182999938],
              [29.635744094000188, -2.794154166999931],
              [29.63555908300009, -2.794696091999981],
              [29.635457993000045, -2.795559644999855],
              [29.63423728900017, -2.795743465999976],
              [29.63378715500005, -2.796111105999898],
              [29.633749008000166, -2.796478509999929],
              [29.634025574000134, -2.797682046999967],
              [29.63400650000017, -2.798196553999901],
              [29.633804321000184, -2.798885582999958],
              [29.633373260000155, -2.799409149999974],
              [29.633291244000134, -2.800024746999895],
              [29.63305091800015, -2.800429105999967],
              [29.632593154000062, -2.800704717999963],
              [29.63229751500006, -2.800741432999871],
              [29.63204193100006, -2.801274299999932],
              [29.63185691900003, -2.801485537999895],
              [29.631269454000176, -2.801825522999934],
              [29.631011962000173, -2.802055118999931],
              [29.630662918000098, -2.802817582999978],
              [29.630167008000058, -2.803203582999913],
              [29.629798888000153, -2.803874253999936],
              [29.629514695000182, -2.803901672999871],
              [29.628641129000016, -2.803708791999895],
              [29.628292085000112, -2.803929328999857],
              [29.627603530000044, -2.804158924999911],
              [29.627088546000095, -2.804719448999947],
              [29.626298904000123, -2.805224657999929],
              [29.625829697000142, -2.805399178999949],
              [29.625232696000182, -2.805748461999826],
              [29.62461853000019, -2.80578517899994],
              [29.62429618800013, -2.806373118999886],
              [29.623725891000106, -2.806318043999966],
              [29.622817993000126, -2.805693386999963],
              [29.621742248000032, -2.805417776999889],
              [29.621263503000137, -2.805224657999929],
              [29.62090682900009, -2.805335044999879],
              [29.620740891000082, -2.805684088999897],
              [29.620658875, -2.806299685999932],
              [29.620557785000187, -2.806483268999955],
              [29.62004280100001, -2.806859968999959],
              [29.61981201200001, -2.807356119999952],
              [29.619472503999987, -2.807420492999881],
              [29.619472503999987, -2.8068325509999],
              [29.61926078900018, -2.806455849999907],
              [29.618940354000017, -2.806428193999921],
              [29.61875724800018, -2.807062148999876],
              [29.618810653000025, -2.807769536999956],
              [29.61825180000011, -2.808100222999883],
              [29.617994308000107, -2.808670043999882],
              [29.617479323000055, -2.809074162999934],
              [29.616706847000046, -2.809313058999976],
              [29.616504670000097, -2.809607027999959],
              [29.616468431000044, -2.809891938999897],
              [29.616174697000076, -2.810158251999894],
              [29.61565017700019, -2.809965371999965],
              [29.614944457000092, -2.810002088999966],
              [29.61450195400016, -2.80992865599984],
              [29.614227295999967, -2.810121536999873],
              [29.61374855100007, -2.810608386999945],
              [29.61261939999997, -2.811196326999834],
              [29.612297058000138, -2.81118726699998],
              [29.611810685000137, -2.810893295999961],
              [29.611543655000048, -2.810296057999949],
              [29.611324310000157, -2.810075519999884],
              [29.61100196900003, -2.810075519999884],
              [29.610597611000117, -2.810479878999956],
              [29.60969734100007, -2.810277699999915],
              [29.609201430000155, -2.809937715999979],
              [29.60887909000013, -2.808715818999929],
              [29.608594894000078, -2.807778834999965],
              [29.60798835800017, -2.807613371999935],
              [29.607364654000094, -2.807898282999872],
              [29.607124328000054, -2.808081863999973],
              [29.60671234099999, -2.807879924999952],
              [29.606666564000022, -2.80752158099989],
              [29.606380463000107, -2.807328700999904],
              [29.60589408800007, -2.806501864999973],
              [29.605636596000068, -2.806510924999941],
              [29.605323791000046, -2.806804893999924],
              [29.6048736570001, -2.806915281999977],
              [29.603219985000067, -2.8068325509999],
              [29.602779389000034, -2.806970356999955],
              [29.601539611000135, -2.807659386999944],
              [29.60080528200018, -2.807613371999935],
              [29.600317001, -2.807429550999927],
              [29.59965515100015, -2.807089804999976],
              [29.598646163000183, -2.806327103999934],
              [29.598251342000083, -2.806235312999945],
              [29.597570420000068, -2.806648731999871],
              [29.597129822, -2.807769536999956],
              [29.596145631000013, -2.807668446999969],
              [29.595623016000047, -2.807356119999952],
              [29.595218658000192, -2.807742117999908],
              [29.59493446400012, -2.807861565999872],
              [29.594602583999972, -2.807842969999911],
              [29.59403419500012, -2.807548998999948],
              [29.593793868000034, -2.8075582969999],
              [29.593685151000045, -2.807190894999962],
              [29.594005585000048, -2.806942700999969],
              [29.59369277900015, -2.806630372999962],
              [29.593307495000033, -2.806896685999959],
              [29.593105316000106, -2.807236671999931],
              [29.592554093000047, -2.807650088999935],
              [29.59192085300009, -2.807990074999907],
              [29.591138839, -2.808026790999861],
              [29.59050559900004, -2.80728268699994],
              [29.59031295799997, -2.807548998999948],
              [29.590120316000025, -2.80745720799996],
              [29.589586258000054, -2.806841610999925],
              [29.58976936200014, -2.806100368999978],
              [29.58997344900007, -2.805739163999931],
              [29.589706422000063, -2.805279730999928],
              [29.589677810000182, -2.804737804999945],
              [29.589742661000116, -2.804076433999967],
              [29.5899276720001, -2.80356192499994],
              [29.589725495000152, -2.803414820999933],
              [29.58948516900017, -2.802881955999965],
              [29.58888816800004, -2.80279016399993],
              [29.58804321400015, -2.802863596999941],
              [29.58743667600004, -2.803056478999906],
              [29.58713340800017, -2.803864955999927],
              [29.586839675000135, -2.804012060999924],
              [29.586187362000032, -2.804048774999956],
              [29.585903168000186, -2.804131506999966],
              [29.585176467000167, -2.804517268999916],
              [29.584791184000153, -2.80493068699991],
              [29.584312439000087, -2.8048479549999],
              [29.58409309400014, -2.805068492999965],
              [29.58377075100003, -2.80491232799983],
              [29.58371543900006, -2.804646014999889],
              [29.583412170000088, -2.804498910999882],
              [29.583173751000174, -2.804618358999846],
              [29.582542419000163, -2.804760217999956],
              [29.582326889000115, -2.805361986999913],
              [29.581933975000084, -2.805303573999936],
              [29.581750869000075, -2.805696009999906],
              [29.581474303999983, -2.805913209999972],
              [29.58084869400011, -2.805996893999861],
              [29.58063125600006, -2.806330918999947],
              [29.579904557000077, -2.806740283999886],
              [29.5797786710001, -2.807350157999906],
              [29.579160690000037, -2.80758404799991],
              [29.578784942000084, -2.807592390999957],
              [29.578483581000057, -2.80780124599994],
              [29.57759094200003, -2.808185576999961],
              [29.577440261999982, -2.808837174999951],
              [29.577198028000055, -2.809371709999937],
              [29.576404571000182, -2.809580563999816],
              [29.57615280200008, -2.809781073999886],
              [29.5752429960001, -2.810157060999927],
              [29.57505035500003, -2.810432671999877],
              [29.575059890000034, -2.810950517999913],
              [29.574872971000104, -2.811217783999894],
              [29.574415208000175, -2.811510324999972],
              [29.573572158000047, -2.811902999999859],
              [29.572711945000037, -2.81174421299994],
              [29.572452546000136, -2.811418294999896],
              [29.57211113000011, -2.811301469999933],
              [29.571760178000034, -2.811368225999843],
              [29.570949555000027, -2.811384915999895],
              [29.570672990000162, -2.811451911999939],
              [29.57038879400011, -2.81129312499985],
              [29.5698223120001, -2.81186103899995],
              [29.569528579000064, -2.811911343999839],
              [29.56924438500016, -2.811652183999968],
              [29.568651200000033, -2.81170248899997],
              [29.568450928000175, -2.811618804999966],
              [29.568201064000107, -2.811844347999966],
              [29.567615509000063, -2.812053202999948],
              [29.567047119999984, -2.811810968999964],
              [29.566555024000138, -2.811919688999979],
              [29.565961836999975, -2.811961412999892],
              [29.565452575000165, -2.811769246999972],
              [29.564758300000165, -2.812379120999822],
              [29.564199447000192, -2.812370776999842],
              [29.56362342900013, -2.812287090999917],
              [29.563121796000132, -2.812663078999947],
              [29.56257820100018, -2.812896966999858],
              [29.56211090100004, -2.813423394999973],
              [29.56117439200011, -2.813632011999914],
              [29.56109237600009, -2.813790798999889],
              [29.560649872000056, -2.814125059999924],
              [29.56009864700019, -2.814125059999924],
              [29.559762954, -2.814049720999947],
              [29.559370040000033, -2.814375638999877],
              [29.558685303000118, -2.814400672999909],
              [29.558509826999966, -2.814692973999968],
              [29.558067322000056, -2.815085649999958],
              [29.557424546000163, -2.815511702999913],
              [29.55706596500005, -2.815545080999925],
              [29.556814193000037, -2.815921068999955],
              [29.556344986, -2.816359519999821],
              [29.55587577900019, -2.816471099999887],
              [29.555431366000107, -2.816448688999913],
              [29.554550172000177, -2.816571234999913],
              [29.55387115500008, -2.816994428999863],
              [29.552780151000036, -2.817306278999979],
              [29.552289963000135, -2.817640541999936],
              [29.551139832000104, -2.818169831999967],
              [29.55083274800012, -2.818243264999978],
              [29.550405503000036, -2.81814336799988],
              [29.549613953000062, -2.817608832999895],
              [29.54890632700011, -2.817657232999977],
              [29.548267365000186, -2.817897080999956],
              [29.54763412500006, -2.818005561999939],
              [29.547304153000084, -2.817560432999869],
              [29.54686164900005, -2.817383764999931],
              [29.546325684000067, -2.817398785999899],
              [29.545923233000053, -2.817140578999954],
              [29.545301438000024, -2.816953896999962],
              [29.54512024000013, -2.817012309999939],
              [29.544355392000114, -2.816980600999955],
              [29.543899535000094, -2.817128895999872],
              [29.54307937700014, -2.817590474999975],
              [29.542442322000056, -2.81800222399994],
              [29.54216384900002, -2.818081377999931],
              [29.542348862000154, -2.818561552999938],
              [29.54240036000016, -2.819020986999931],
              [29.542364121000105, -2.819589615999917],
              [29.542655945000092, -2.820107459999974],
              [29.542568208000034, -2.820654390999891],
              [29.54280853199998, -2.820945976999951],
              [29.542779923000012, -2.821135520999917],
              [29.543043137000154, -2.821536539999954],
              [29.54301261900008, -2.822068929999887],
              [29.543239594000113, -2.822418927999877],
              [29.54334831200009, -2.823024271999884],
              [29.543319703000122, -2.823323248999941],
              [29.543443679000063, -2.823724269999957],
              [29.543743133000135, -2.82423472399995],
              [29.543699264000168, -2.824686764999967],
              [29.54378700200016, -2.825219154999957],
              [29.544216155000186, -2.825853586999926],
              [29.54423904400005, -2.826079606999883],
              [29.544076919000076, -2.82656073499993],
              [29.54367637700011, -2.827020168999923],
              [29.54375648600012, -2.827304601999913],
              [29.543699264000168, -2.82788801199996],
              [29.543371201000127, -2.828369139999893],
              [29.543247222000048, -2.828814029999933],
              [29.543340684000157, -2.829069137999909],
              [29.54317283600011, -2.829571245999887],
              [29.542755127000135, -2.829648971999916],
              [29.542375564000054, -2.829989194999826],
              [29.542074204000187, -2.82984352099993],
              [29.54196739200006, -2.829269886999896],
              [29.541568756000117, -2.82890033699988],
              [29.54125785900004, -2.82889056199997],
              [29.54065513600017, -2.829104423999979],
              [29.54037284800006, -2.82907533599996],
              [29.540130616000113, -2.828871250999953],
              [29.538906097000165, -2.828851699999859],
              [29.538360596000018, -2.828569648999974],
              [29.538341522000053, -2.827937841999926],
              [29.538263321000045, -2.827548980999893],
              [29.53793335000006, -2.827548980999893],
              [29.53771019000004, -2.828433512999879],
              [29.53739738500019, -2.828666925999926],
              [29.53640747100019, -2.828365564999956],
              [29.535930634000067, -2.82856011399997],
              [29.535404205000134, -2.828666925999926],
              [29.53514289800006, -2.828550337999957],
              [29.535074234000092, -2.828278064999893],
              [29.535259248000102, -2.827597378999883],
              [29.53511428900009, -2.82750034399993],
              [29.534364701000072, -2.827568291999967],
              [29.53369331400006, -2.827762841999913],
              [29.532955170000093, -2.8277530659999],
              [29.532644273000187, -2.82731556799996],
              [29.532421112000122, -2.827296018999903],
              [29.531982422000056, -2.82758784299989],
              [29.531574250000062, -2.827762841999913],
              [29.531166076000034, -2.827373742999953],
              [29.53094291800005, -2.827305792999937],
              [29.530651092000028, -2.827422379999973],
              [29.53004837100019, -2.828200339999967],
              [29.53010559100011, -2.828715562999946],
              [29.529609681000125, -2.828715562999946],
              [29.529270172000167, -2.8284821499999],
              [29.52894973700006, -2.828705787999866],
              [29.52870559600018, -2.828715562999946],
              [29.528404236000142, -2.828472614999953],
              [29.528171538000038, -2.82859897599991],
              [29.527948380000055, -2.828919647999953],
              [29.52699470600004, -2.828608750999933],
              [29.52666473300019, -2.828608750999933],
              [29.52586746300011, -2.828919647999953],
              [29.525051117000032, -2.829726695999966],
              [29.524213790000147, -2.829979419999972],
              [29.52396202099999, -2.82984352099993],
              [29.52344703700004, -2.829814194999926],
              [29.52277564999997, -2.829998968999917],
              [29.52237701400003, -2.829979419999972],
              [29.52208519000004, -2.829804419999903],
              [29.52179336500018, -2.829804419999903],
              [29.521617889000026, -2.829998968999917],
              [29.521347046000074, -2.829823969999836],
              [29.520355225000174, -2.829337834999933],
              [29.519693374000042, -2.828812837999919],
              [29.519372941000086, -2.828851699999859],
              [29.51953697100015, -2.82928919799997],
              [29.519470215000126, -2.82941555899987],
              [29.518915177000054, -2.82935738599997],
              [29.517942429000016, -2.828997611999853],
              [29.517398834000062, -2.82889056199997],
              [29.51719474900017, -2.828929423999966],
              [29.51714515700013, -2.82856011399997],
              [29.51700019800012, -2.828472614999953],
              [29.516416549999974, -2.828433512999879],
              [29.516407012000116, -2.828151701999957],
              [29.516202927000165, -2.827626704999943],
              [29.515851974000157, -2.82750034399993],
              [29.515619278000088, -2.82785987799997],
              [29.51538658200002, -2.8276169299999],
              [29.51511383100012, -2.827043294999953],
              [29.514657975000034, -2.826518297999883],
              [29.514598847000116, -2.825769662999846],
              [29.514472961000024, -2.82545852599992],
              [29.513889314000153, -2.825050114999897],
              [29.513723374000108, -2.824388980999913],
              [29.513267517000145, -2.82394170699996],
              [29.512828827000078, -2.823883531999911],
              [29.512809753000056, -2.823621034999974],
              [29.513042449000125, -2.823251484999958],
              [29.51264381300001, -2.822813986999961],
              [29.512100220000093, -2.822988986999974],
              [29.51170158400015, -2.822668074999967],
              [29.511507035000136, -2.822697161999884],
              [29.510854722000033, -2.8231832969999],
              [29.51011657700019, -2.822687625999834],
              [29.509834289000082, -2.822658299999887],
              [29.509183884000038, -2.822784662999936],
              [29.508958816000018, -2.822463987999868],
              [29.50856018200011, -2.82253193899993],
              [29.508054733000108, -2.822512625999934],
              [29.507423401000096, -2.822075127999938],
              [29.50749206400019, -2.821724890999917],
              [29.50769615100012, -2.821374891999938],
              [29.507326126000123, -2.820918082999981],
              [29.506469726000148, -2.82029581099988],
              [29.506664276000095, -2.819731949999948],
              [29.506256104000045, -2.819469452999897],
              [29.506654739999988, -2.819245814999874],
              [29.506538392000152, -2.818934677999891],
              [29.50593566900011, -2.818545816999972],
              [29.505624772000033, -2.818147182999951],
              [29.505382538000106, -2.818040132999954],
              [29.505226135000044, -2.817602634999901],
              [29.50440025400019, -2.816844224999954],
              [29.50435066200015, -2.816504000999942],
              [29.50404930100018, -2.81661081399983],
              [29.50396156400012, -2.816280365999944],
              [29.503719330000138, -2.816367864999961],
              [29.503389359000096, -2.815881728999898],
              [29.503116607000095, -2.815891504999911],
              [29.50284385599997, -2.815589903999978],
              [29.50271797300013, -2.815201042999888],
              [29.502504348000173, -2.815064905999975],
              [29.502454758000113, -2.814520597999888],
              [29.502124785000092, -2.814413547999948],
              [29.501628877000087, -2.813908099999935],
              [29.501230241000144, -2.813713550999921],
              [29.500888825000175, -2.813266276999968],
              [29.500539779000064, -2.813276051999935],
              [29.500696183, -2.812906502999908],
              [29.500511170000095, -2.812595367999961],
              [29.499839783000027, -2.812362194999935],
              [29.499500275000173, -2.812410831999955],
              [29.499217987000122, -2.812109232999944],
              [29.498983383000052, -2.812148094999884],
              [29.498779296000123, -2.811613559999955],
              [29.498477937000132, -2.811594008999975],
              [29.49816703700003, -2.810835599999905],
              [29.497915268000042, -2.810932874999878],
              [29.49783134400002, -2.810627220999891],
              [29.4974765770001, -2.810786962999885],
              [29.49714660700016, -2.810417650999966],
              [29.496631623000155, -2.810339689999921],
              [29.496427536000056, -2.810631513999908],
              [29.496154785000158, -2.810271738999973],
              [29.495969772000024, -2.810436963999962],
              [29.495319366000047, -2.810135602999822],
              [29.49488067600015, -2.810106515999962],
              [29.494560240999988, -2.809941053999978],
              [29.493928908999976, -2.809999465999908],
              [29.49335479700011, -2.809814690999929],
              [29.49309158400007, -2.809435604999976],
              [29.492567062000035, -2.809231519999969],
              [29.49202346700008, -2.808881281999959],
              [29.491916657000104, -2.808677196999952],
              [29.491556168000045, -2.808492420999926],
              [29.491497040000127, -2.80820083499998],
              [29.490777969000078, -2.807909250999899],
              [29.49028205800016, -2.807529925999972],
              [29.490039825999986, -2.806868791999875],
              [29.489698410000187, -2.806334017999973],
              [29.48946571400012, -2.806246517999909],
              [29.48891067599999, -2.805750607999926],
              [29.488618850000023, -2.805634021999936],
              [29.48812484700011, -2.805031298999893],
              [29.487628936000192, -2.804545163999933],
              [29.487462998000126, -2.80403947699989],
              [29.487218857000073, -2.804010390999963],
              [29.486713410000107, -2.803368567999883],
              [29.48661613400003, -2.803125618999957],
              [29.486646652000104, -2.802561759999946],
              [29.486118317000148, -2.802050113999883],
              [29.485521317000178, -2.802541016999896],
              [29.484596252000074, -2.802621602999921],
              [29.48367118800013, -2.802176236999912],
              [29.48360824500014, -2.802066324999885],
              [29.48291778500004, -2.802434921999918],
              [29.482501983000077, -2.802877664999869],
              [29.482124328, -2.803505659999871],
              [29.48188972500003, -2.803570032999971],
              [29.480690003000063, -2.804740666999919],
              [29.479858399000193, -2.805224179999868],
              [29.47989845400008, -2.80588102299987],
              [29.479640961000143, -2.806159733999834],
              [29.478685379000126, -2.806796788999975],
              [29.478336333000016, -2.806941270999914],
              [29.477993012000127, -2.806081770999867],
              [29.477819444000147, -2.804967402999921],
              [29.476514815000144, -2.805542707999962],
              [29.476535796000178, -2.806234119999942],
              [29.476339340000095, -2.806814909999957],
              [29.476125717000116, -2.807144879999896],
              [29.47561264000018, -2.807171820999884],
              [29.47525024400005, -2.807299612999827],
              [29.474424362000093, -2.806165218999922],
              [29.473932267000123, -2.805941343999962],
              [29.47330474900008, -2.805792091999876],
              [29.47296142600004, -2.805866717999948],
              [29.472120284000084, -2.805853126999978],
              [29.47176742500011, -2.805941343999962],
              [29.470678329, -2.80591130199997],
              [29.469715119000114, -2.805550097999912],
              [29.468648911000173, -2.805313824999928],
              [29.468011857000192, -2.804995296999948],
              [29.467260361, -2.804361341999879],
              [29.466623307000077, -2.80406165099987],
              [29.465171814000144, -2.803532360999952],
              [29.464294433000134, -2.80267357799994],
              [29.463603973000033, -2.80153512999982],
              [29.463109970000062, -2.800865410999904],
              [29.46154403600019, -2.800880193999888],
              [29.460796356000117, -2.800925015999951],
              [29.46043968200013, -2.801044463999972],
              [29.45897674600002, -2.80102944399988],
              [29.45822906600017, -2.800969837999958],
              [29.45666122500006, -2.800402641999824],
              [29.455408095999985, -2.800103902999979],
              [29.454721451000125, -2.799999474999936],
              [29.453453064000087, -2.800118922999957],
              [29.452138900000023, -2.800148724999929],
              [29.45133209300019, -2.800507068999934],
              [29.45073509200006, -2.800536870999906],
              [29.450063706000094, -2.799820421999868],
              [29.449586868000097, -2.799432276999823],
              [29.44864654500003, -2.798521518999962],
              [29.448167802000114, -2.798282861999951],
              [29.447586060000106, -2.79816341399993],
              [29.44677925200017, -2.798282861999951],
              [29.44589996300016, -2.798671007999872],
              [29.44527244600016, -2.798700808999968],
              [29.444570541000076, -2.798984287999872],
              [29.443420410000044, -2.799223183999914],
              [29.442272186000082, -2.799193382999931],
              [29.44167518500018, -2.799357652999959],
              [29.44103241000016, -2.799283026999888],
              [29.44025611899997, -2.798894880999967],
              [29.44021224900007, -2.798506735999979],
              [29.439167023000095, -2.797670840999899],
              [29.438524247000032, -2.797670840999899],
              [29.437927247, -2.797566175999975],
              [29.43746566800013, -2.797626018999949],
              [29.436986923, -2.79816341399993],
              [29.43649482700016, -2.798521518999962],
              [29.435239791000072, -2.798999308999953],
              [29.43528556800004, -2.799447059999977],
              [29.435075761000178, -2.799954652999873],
              [29.434688568000183, -2.800328015999867],
              [29.434360505000143, -2.800387620999914],
              [29.43380737400014, -2.800148724999929],
              [29.433479309000063, -2.800342798999907],
              [29.43292617800006, -2.800387620999914],
              [29.432374954000068, -2.801014660999897],
              [29.431957245000092, -2.801328182999839],
              [29.431537628000058, -2.802582025999925],
              [29.43140411400003, -2.802820919999874],
              [29.431255342000156, -2.804074763999893],
              [29.4311504370001, -2.80428385599987],
              [29.430597306000095, -2.804627179999955],
              [29.429777, -2.804999999999893],
              [29.42904497899997, -2.805224055999929],
              [29.428747177000048, -2.805653809999967],
              [29.42827797000001, -2.805838583999901],
              [29.42777400100016, -2.805888998999819],
              [29.42737007200003, -2.806199788999948],
              [29.426597851, -2.80614914899985],
              [29.42632103000011, -2.806409597999902],
              [29.426118851000183, -2.806350945999952],
              [29.425741196000104, -2.806745528999898],
              [29.42538456700015, -2.806857322999974],
              [29.42511900100004, -2.80680500099993],
              [29.424663863000035, -2.806430000999967],
              [29.424327999000127, -2.806437000999949],
              [29.42399799899999, -2.806607999999869],
              [29.423307058000148, -2.807279949999952],
              [29.422813000000133, -2.807451],
              [29.421890258000133, -2.808037518999925],
              [29.421423, -2.808181998999828],
              [29.42056655900012, -2.808729171999971],
              [29.42022323500015, -2.80880164999985],
              [29.419928001000073, -2.808662998999921],
              [29.41960300000011, -2.80869],
              [29.41930800000017, -2.808985999999891],
              [29.419157000000155, -2.809947995999949],
              [29.418411, -2.810685998999873],
              [29.417898161000153, -2.810988904999874],
              [29.417397001000097, -2.811047998999925],
              [29.41697500100014, -2.811219],
              [29.416599, -2.811595000999944],
              [29.41654000000011, -2.812142000999927],
              [29.41642188900005, -2.812444925999955],
              [29.41542799900003, -2.813221998999836],
              [29.41461753900012, -2.813393592999887],
              [29.414124, -2.813399998999898],
              [29.413668, -2.813610998999934],
              [29.41343699900017, -2.813815],
              [29.413082001000078, -2.814315999999849],
              [29.412556, -2.814520000999949],
              [29.411922000000118, -2.81461200099983],
              [29.41136900100014, -2.814178],
              [29.411039, -2.814204],
              [29.410749, -2.814513998999871],
              [29.41028200100004, -2.815172],
              [29.409933090000038, -2.815442561999873],
              [29.409603000000118, -2.81554099899995],
              [29.409109000000115, -2.815469],
              [29.408628148000105, -2.815705932999833],
              [29.408266067000056, -2.815613746999929],
              [29.407949208000048, -2.815337110999963],
              [29.407246000000157, -2.815291],
              [29.406967001000055, -2.815594],
              [29.40652656600014, -2.816220044999909],
              [29.406064987000036, -2.816588877999948],
              [29.40583403500011, -2.817069924999885],
              [29.405664445000127, -2.817234515999928],
              [29.40513, -2.81744500099984],
              [29.404827000000182, -2.817689000999962],
              [29.40420150700004, -2.817998646999911],
              [29.403876998999976, -2.818070998999929],
              [29.403548999000066, -2.81829500099991],
              [29.403206000000182, -2.818373998999959],
              [29.402942999000174, -2.818631],
              [29.40238189600018, -2.819355964999943],
              [29.4013210010001, -2.819974998999896],
              [29.400887000000125, -2.820542],
              [29.400709000000177, -2.821069000999842],
              [29.40071678100003, -2.821641921999969],
              [29.400504999000077, -2.821826],
              [29.400016999000172, -2.821833],
              [29.39962196400012, -2.821951626999862],
              [29.39866099900007, -2.821871998999939],
              [29.39851199899999, -2.821666000999926],
              [29.398441002000084, -2.821186004999845],
              [29.398030999000184, -2.8208],
              [29.39694199900015, -2.820564999999874],
              [29.396416, -2.820108999999889],
              [29.396227001000113, -2.819393999999875],
              [29.39625199900007, -2.818832000999919],
              [29.39617000100003, -2.818070998999929],
              [29.395912, -2.817472998999847],
              [29.39537200000018, -2.817098999999814],
              [29.394974000000104, -2.816993000999901],
              [29.394235861000027, -2.817157123999891],
              [29.393814001000123, -2.817531998999925],
              [29.393568038000126, -2.817988872999877],
              [29.393124001000047, -2.818374998999957],
              [29.39236299900017, -2.818703],
              [29.391836000000183, -2.818609999999865],
              [29.391098001000103, -2.818398999999886],
              [29.390501001000132, -2.818504000999951],
              [29.389833001000113, -2.818457],
              [29.389294001000053, -2.818703],
              [29.388919940000108, -2.819031111999891],
              [29.388883591000024, -2.819417714999815],
              [29.388965607000102, -2.819944857999928],
              [29.388673782000183, -2.820354698999893],
              [29.388298001000067, -2.820576998999968],
              [29.387432000000103, -2.820483998999919],
              [29.38712695700019, -2.820870095999908],
              [29.38696289100011, -2.82128000299997],
              [29.38649400000014, -2.821385],
              [29.385826001, -2.821385],
              [29.38515900100009, -2.821537998999929],
              [29.38448000000011, -2.822228999999879],
              [29.384151, -2.822931998999877],
              [29.383297, -2.824032],
              [29.383062, -2.824653000999888],
              [29.38310999700019, -2.825437951999845],
              [29.3829799990001, -2.825882998999873],
              [29.381820975000096, -2.826515142999881],
              [29.381540001000133, -2.82793299899987],
              [29.38144683800016, -2.828740834999906],
              [29.381037000000163, -2.829268000999946],
              [29.381024999000147, -2.830063999999879],
              [29.380638103000138, -2.831223735999913],
              [29.380134639, -2.831493108999894],
              [29.379549026000177, -2.831668853999872],
              [29.378974915000185, -2.831715583999937],
              [29.378425999000058, -2.831433998999898],
              [29.3780039990001, -2.831329],
              [29.377699, -2.831563000999836],
              [29.3782020000001, -2.83183300099995],
              [29.37843698100005, -2.832277827999917],
              [29.37772178600011, -2.83281660099982],
              [29.37681996600014, -2.833191147999969],
              [29.376749024000105, -2.833507891999886],
              [29.377077001000146, -2.833905998999967],
              [29.376715001000036, -2.834164],
              [29.377218, -2.834854999999891],
              [29.377194999000153, -2.835346],
              [29.377311999000085, -2.83658799899996],
              [29.377453000000116, -2.836998],
              [29.377416611, -2.837688921999813],
              [29.377172020000103, -2.838016972999867],
              [29.376409999000032, -2.838029],
              [29.375977, -2.838204],
              [29.375438999000153, -2.838239],
              [29.37471200000016, -2.838087],
              [29.373447000000112, -2.838051999999834],
              [29.372756999000103, -2.838122],
              [29.372135001000174, -2.838415],
              [29.37143299899998, -2.839481],
              [29.37106895400018, -2.839926003999892],
              [29.370483399000136, -2.839480875999925],
              [29.370191573000113, -2.838860273999899],
              [29.370225907000133, -2.83855557499993],
              [29.369289398000035, -2.837419508999972],
              [29.368972778000114, -2.836951016999933],
              [29.368844985000123, -2.836529491999897],
              [29.368785856999978, -2.835756540999967],
              [29.36919593900018, -2.83536982499993],
              [29.369113922000054, -2.834702253999978],
              [29.36885643000005, -2.834069966999948],
              [29.368282318000183, -2.83334374299983],
              [29.367813111000146, -2.83266448899991],
              [29.36782455400015, -2.832324742999958],
              [29.367696761000104, -2.831996679999918],
              [29.367216111000175, -2.831949949999967],
              [29.366537094000023, -2.832137345999911],
              [29.366254806000086, -2.83266448899991],
              [29.365493775000118, -2.832945583999901],
              [29.36501503, -2.832921980999913],
              [29.36466217100002, -2.832746266999948],
              [29.363609314000087, -2.832441805999963],
              [29.36328124900018, -2.832242725999947],
              [29.362859727000114, -2.832242725999947],
              [29.362577439000063, -2.832348108999952],
              [29.362192153000137, -2.832043646999978],
              [29.36200332700014, -2.831668853999872],
              [29.36213302500005, -2.831118345999926],
              [29.362834931, -2.830462454999974],
              [29.363245010000128, -2.830017326999951],
              [29.364240647000145, -2.829689501999894],
              [29.364627837000057, -2.829455137999958],
              [29.364721299000166, -2.829103946999965],
              [29.364955902000077, -2.828670500999976],
              [29.364837647000172, -2.827991246999886],
              [29.364463807000163, -2.827592848999871],
              [29.36426544300008, -2.82711267399992],
              [29.363445282000157, -2.826140642999974],
              [29.363023757000178, -2.825531481999917],
              [29.362598420000097, -2.825324773999967],
              [29.362483979000103, -2.825098275999892],
              [29.361734389000105, -2.824559448999878],
              [29.36134910600009, -2.823833226999852],
              [29.36106681800004, -2.823493718999828],
              [29.361148834000062, -2.822404384999913],
              [29.362028123000073, -2.821209668999927],
              [29.362192153000137, -2.8208348739999],
              [29.362344743000165, -2.820120572999883],
              [29.36249542200011, -2.819909572999904],
              [29.36241340600003, -2.819347381999933],
              [29.362039566000192, -2.818902490999903],
              [29.36200332700014, -2.818679807999956],
              [29.36164093000008, -2.818035602999942],
              [29.360950470000148, -2.817602394999881],
              [29.360786437000172, -2.817426681999905],
              [29.360868454000126, -2.817016839999951],
              [29.360820771000192, -2.815798757999971],
              [29.360574722000138, -2.814650773999915],
              [29.36031723000019, -2.814428328999895],
              [29.359802246000186, -2.814205883999875],
              [29.359672547, -2.813573358999975],
              [29.35946273800016, -2.813362598999902],
              [29.35945129400011, -2.812636374999954],
              [29.359357833000104, -2.812437295999928],
              [29.358642579000048, -2.811992167999961],
              [29.358560563000026, -2.811793087999888],
              [29.359041213000182, -2.81103181799989],
              [29.358854294000082, -2.81068038899997],
              [29.358362198000066, -2.810410975999957],
              [29.357915878000085, -2.810328959999936],
              [29.357482910000158, -2.810399292999875],
              [29.357049942000174, -2.809930800999837],
              [29.357002258000136, -2.80947399199988],
              [29.357097627000144, -2.809169529999906],
              [29.356815339000036, -2.808548688999963],
              [29.356721879000133, -2.807939767999926],
              [29.356756211000118, -2.807471274999898],
              [29.356580735000193, -2.80696749699996],
              [29.35608863700014, -2.806815384999879],
              [29.35544586200018, -2.806791780999902],
              [29.355, -2.806511],
              [29.354696000000104, -2.805984],
              [29.354017, -2.805012],
              [29.35363962299999, -2.804895640999973],
              [29.35314867600016, -2.805233951999981],
              [29.352914925000107, -2.80519892399991],
              [29.352657000000136, -2.804940999999872],
              [29.352026, -2.804496],
              [29.351931001000025, -2.804156999999862],
              [29.3519554130001, -2.80368804799997],
              [29.35214233500011, -2.803196191999859],
              [29.3526, -2.802833],
              [29.352940000000103, -2.802352999999812],
              [29.352915, -2.801743999999871],
              [29.352669000000105, -2.800993999999889],
              [29.352447715000153, -2.800619807999851],
              [29.351944000000117, -2.800303],
              [29.351837001000092, -2.799261000999934],
              [29.351639000000148, -2.798968],
              [29.351650239000094, -2.798546314999953],
              [29.351908, -2.798195],
              [29.351908, -2.797972261999973],
              [29.352153527000155, -2.797809312999902],
              [29.352844238000046, -2.797574281999914],
              [29.353250392000064, -2.797597787999905],
              [29.35357082200011, -2.797866847999956],
              [29.353817, -2.797937],
              [29.35456679200007, -2.797305183999868],
              [29.354836000000148, -2.796742999999879],
              [29.35497700100018, -2.796216000999891],
              [29.35497700100018, -2.795746999999892],
              [29.355129, -2.795349],
              [29.355516001000183, -2.794775],
              [29.356159, -2.794200999999873],
              [29.356312, -2.793733],
              [29.356873904, -2.792888344999881],
              [29.356827, -2.792572999999834],
              [29.357121, -2.791787999999883],
              [29.356714000000125, -2.790731],
              [29.356557956000188, -2.790511968999908],
              [29.355714774000035, -2.789937518999977],
              [29.355516433000048, -2.789516208999942],
              [29.355667115000074, -2.789094686999874],
              [29.355574000000104, -2.78869],
              [29.355010987000128, -2.788684604999958],
              [29.35458946199998, -2.788227795999944],
              [29.354567, -2.787349],
              [29.354439000000184, -2.786694],
              [29.35415649400005, -2.785674571999948],
              [29.35416798900002, -2.785088942999835],
              [29.35387611300007, -2.784070013999894],
              [29.353865, -2.783636998999953],
              [29.353735001000075, -2.783379],
              [29.353104001000133, -2.782934],
              [29.352798463000056, -2.782547472999966],
              [29.352751000000126, -2.782091],
              [29.3524, -2.781410999999878],
              [29.352517886000044, -2.780966427999886],
              [29.352318, -2.780744],
              [29.351240000000132, -2.780263],
              [29.351006000000154, -2.780029],
              [29.350889, -2.779654998999888],
              [29.35094800000013, -2.779209],
              [29.350889, -2.778927999999894],
              [29.349916458000052, -2.778331039999955],
              [29.349777, -2.778073],
              [29.348957, -2.777617],
              [29.348660263000113, -2.777378605999957],
              [29.348089217000165, -2.776679514999898],
              [29.348346864000064, -2.77601225899997],
              [29.348699806000127, -2.775707166999951],
              [29.348839000000112, -2.775204],
              [29.349155427000085, -2.774665117999973],
              [29.349542760000077, -2.774325215999909],
              [29.349718094000025, -2.77396225799987],
              [29.350035000000105, -2.77386900099998],
              [29.350091000000134, -2.773604],
              [29.349812000000156, -2.773271],
              [29.349987015000124, -2.771947964999924],
              [29.35029207200006, -2.771221927999932],
              [29.350666000000103, -2.77084699999989],
              [29.350759947000142, -2.770437233999928],
              [29.350807, -2.769348],
              [29.350677000000132, -2.768188],
              [29.350419896000176, -2.767965993999894],
              [29.349976, -2.767942],
              [29.348957, -2.768129999999871],
              [29.348324000000105, -2.768364],
              [29.347902211000132, -2.768773794999959],
              [29.347025, -2.768785],
              [29.34687200600007, -2.768586007999943],
              [29.346779, -2.767766],
              [29.346485, -2.767496999999821],
              [29.346027778000177, -2.767437353999924],
              [29.34520700000013, -2.766919],
              [29.344833000000108, -2.766349],
              [29.344448, -2.766185],
              [29.34420204200012, -2.76583385399988],
              [29.343990325000107, -2.765177963999975],
              [29.344388947000027, -2.764862041999948],
              [29.344003835000137, -2.764666023999951],
              [29.343288422, -2.764779804999876],
              [29.342503, -2.764756],
              [29.341342926000152, -2.764955520999934],
              [29.341097, -2.764756],
              [29.340876, -2.764358],
              [29.340582, -2.764159],
              [29.340477000000135, -2.763913],
              [29.339926000000162, -2.763959999999884],
              [29.33943399900005, -2.764088999999899],
              [29.338952584000083, -2.76397276299997],
              [29.338392, -2.764229],
              [29.338003, -2.76394],
              [29.337841064000145, -2.763128772999949],
              [29.337982145000183, -2.762624860999949],
              [29.338486, -2.762098],
              [29.338555982000173, -2.761840066999923],
              [29.338381, -2.761464999999873],
              [29.33805300099999, -2.761090999999851],
              [29.33808708099997, -2.760704038999961],
              [29.338369066000155, -2.760317976999943],
              [29.339028766000183, -2.760031129999902],
              [29.3395900000001, -2.759543999999835],
              [29.339798, -2.75924],
              [29.340254001000062, -2.759098999999878],
              [29.340372000000116, -2.758783],
              [29.340208, -2.758338],
              [29.340197, -2.757880999999884],
              [29.340067, -2.757436],
              [29.340418, -2.755597000999842],
              [29.340630000000147, -2.754976999999883],
              [29.341021000000183, -2.754196],
              [29.341085000000135, -2.753676999999868],
              [29.34098100100016, -2.753113998999936],
              [29.340991992000113, -2.752692992999926],
              [29.340777659000025, -2.752515775999882],
              [29.340324324000107, -2.752388090999887],
              [29.33996200600012, -2.751720665999926],
              [29.339598, -2.751415],
              [29.338732000000164, -2.751135],
              [29.33829900100011, -2.750877],
              [29.337992900000074, -2.750502352999945],
              [29.337103000000184, -2.749987],
              [29.336565, -2.749740998999926],
              [29.336084, -2.749425],
              [29.33501800000016, -2.748429999999871],
              [29.33424541400018, -2.748159470999951],
              [29.33388328600006, -2.74783206099994],
              [29.333426, -2.747117998999954],
              [29.333426, -2.746684],
              [29.333286286000032, -2.746180772999935],
              [29.333118438000042, -2.746106147999967],
              [29.333342, -2.74557],
              [29.33377500100005, -2.745358999999894],
              [29.334091186000023, -2.7447264199999],
              [29.334349001000135, -2.744515999999862],
              [29.334970000000112, -2.744293],
              [29.33559, -2.743801],
              [29.33578879000015, -2.743508314999872],
              [29.336716000000138, -2.743321000999913],
              [29.33725388000005, -2.743321000999913],
              [29.33818834200008, -2.74349668699989],
              [29.339092000000107, -2.743390999999861],
              [29.33951359600013, -2.743052324999894],
              [29.340076447000115, -2.742173194999907],
              [29.34024, -2.742150000999857],
              [29.34106100000014, -2.742372],
              [29.341412, -2.742301999999881],
              [29.342419, -2.741775],
              [29.34279329000009, -2.741470577999962],
              [29.342970001000083, -2.741142998999919],
              [29.34338000000014, -2.740850000999956],
              [29.34413, -2.740627],
              [29.344797000000142, -2.740194],
              [29.345229466000035, -2.740053173999911],
              [29.34585, -2.740006],
              [29.34617800000018, -2.739877],
              [29.34710300100005, -2.739011000999938],
              [29.347795001000122, -2.738507],
              [29.34833300000014, -2.738178999999832],
              [29.348731924000106, -2.737664098999858],
              [29.349060058000077, -2.737031459999912],
              [29.349855423000065, -2.736586331999945],
              [29.3510270000001, -2.735802],
              [29.35195183400009, -2.735226458999875],
              [29.353696619000118, -2.734267214999818],
              [29.354319, -2.733846],
              [29.355090472000086, -2.73267483799998],
              [29.35516200000012, -2.731714],
              [29.35505700000016, -2.730742],
              [29.354364, -2.728751],
              [29.353823, -2.727568],
              [29.35344700000013, -2.727054],
              [29.352526000000182, -2.72558],
              [29.35022400000014, -2.723869],
              [29.34982814400007, -2.722748525999862],
              [29.34864425600017, -2.720710514999894],
              [29.34851300000014, -2.719064999999887],
              [29.34824943500007, -2.716696261999914],
              [29.34844599500019, -2.714919042999952],
              [29.34864425600017, -2.713405847999979],
              [29.347393037000074, -2.712418793999973],
              [29.346603393000123, -2.711234092999973],
              [29.346734996000066, -2.710576028999981],
              [29.346539, -2.71005],
              [29.345682001000114, -2.70853600199996],
              [29.344563000000164, -2.707417],
              [29.3444980000001, -2.706693],
              [29.344761000000176, -2.705509],
              [29.345024001000183, -2.703994998999917],
              [29.344797000000142, -2.702873998999962],
              [29.34457322899999, -2.702598869999974],
              [29.344091415000094, -2.702376125999933],
              [29.343878001000064, -2.701936],
              [29.343699, -2.70121],
              [29.343544, -2.700913],
              [29.343021, -2.700972],
              [29.342533, -2.700888998999972],
              [29.342033386000082, -2.700437067999872],
              [29.341937174000122, -2.699924211999814],
              [29.341665, -2.699663999999871],
              [29.341367999, -2.699545],
              [29.339986800000077, -2.69955658899994],
              [29.339619, -2.699247],
              [29.339226000000167, -2.698795],
              [29.33870307400008, -2.697938120999879],
              [29.33867800000013, -2.697272],
              [29.33819, -2.696071],
              [29.338225404000127, -2.695332164999911],
              [29.337928947000023, -2.694916887999909],
              [29.337536000000114, -2.693977],
              [29.337524, -2.692977],
              [29.337084, -2.692668],
              [29.336121000000105, -2.692216],
              [29.335918, -2.691917999999873],
              [29.33518, -2.691037999999878],
              [29.334669, -2.690979],
              [29.33441900000014, -2.691312],
              [29.333633000000134, -2.692026],
              [29.333312988000102, -2.692370651999909],
              [29.33253860399998, -2.692929744999901],
              [29.331861, -2.693107998999835],
              [29.33064842200008, -2.692477701999906],
              [29.3302192700001, -2.692013739999879],
              [29.32937431300013, -2.690740585999947],
              [29.32918400000011, -2.690217],
              [29.328863000000183, -2.689991],
              [29.32836532600004, -2.689916848999928],
              [29.328114, -2.689584],
              [29.328165050000166, -2.688648765999915],
              [29.328287124000155, -2.688080310999965],
              [29.328323364000084, -2.686782836999953],
              [29.328804016000106, -2.684016227999962],
              [29.328983000000164, -2.683113999999819],
              [29.329050065000104, -2.682399271999941],
              [29.329443, -2.681826999999828],
              [29.329633889000092, -2.68115938099993],
              [29.33059900000012, -2.680521],
              [29.331167, -2.68029900099998],
              [29.33182, -2.680209999999875],
              [29.331980000000158, -2.679920999999865],
              [29.33184242300007, -2.67904925299996],
              [29.331837, -2.67715],
              [29.331890106000174, -2.676438092999888],
              [29.332052230999977, -2.676181315999884],
              [29.332710266000163, -2.67607402799996],
              [29.333082199000103, -2.675908327999935],
              [29.33408546400011, -2.674996374999978],
              [29.334681001000092, -2.674539000999971],
              [29.334927000000107, -2.674252999999851],
              [29.33498800000018, -2.673658001999911],
              [29.334873, -2.673263998999971],
              [29.334697872000163, -2.67320678699997],
              [29.334356309000043, -2.672633886999904],
              [29.33427429200009, -2.672201393999899],
              [29.334482192000053, -2.671279430999903],
              [29.335049, -2.670202],
              [29.335067987000116, -2.669531492999965],
              [29.334930000000156, -2.669209],
              [29.33396239800004, -2.668381586999942],
              [29.332923788000073, -2.667607809999879],
              [29.332266001000107, -2.667016998999941],
              [29.33184619300016, -2.666422255999919],
              [29.331581132000167, -2.665642440999818],
              [29.331804276000184, -2.664784668999971],
              [29.33252, -2.663997],
              [29.33279800000014, -2.663755],
              [29.332672, -2.663234],
              [29.33235000000019, -2.662795],
              [29.330930487000103, -2.661539812999934],
              [29.330675000000156, -2.66113399999989],
              [29.329202825000152, -2.659186208999927],
              [29.329090119000057, -2.658671854999852],
              [29.329090119000057, -2.658118486999967],
              [29.32919695600009, -2.657749856999942],
              [29.32911496400004, -2.657310946999871],
              [29.32829595900006, -2.656169509999927],
              [29.327407838000056, -2.655624627999941],
              [29.326979, -2.655185],
              [29.32672885900007, -2.65474156099998],
              [29.326377780000087, -2.65390666199994],
              [29.326025000000186, -2.65336],
              [29.325487, -2.653131],
              [29.325077, -2.653098999999884],
              [29.324335000000133, -2.653157],
              [29.322901000000172, -2.653522999999893],
              [29.32242600000012, -2.653835],
              [29.32192, -2.654998],
              [29.32152176000011, -2.65623188099994],
              [29.32077789300007, -2.656739711999933],
              [29.320373536000147, -2.656827688999954],
              [29.320053101000155, -2.656774758999916],
              [29.31939889200015, -2.656433825999955],
              [29.319172, -2.655911],
              [29.318659, -2.655032],
              [29.318602000999988, -2.65480399899991],
              [29.318643780000173, -2.654210855999963],
              [29.318353234000142, -2.654020969999976],
              [29.31759814700007, -2.653951026999948],
              [29.31730700100013, -2.653744],
              [29.31674, -2.652759999999887],
              [29.31631300100014, -2.652241998999955],
              [29.315900802000044, -2.651862620999907],
              [29.315660477000165, -2.651448248999884],
              [29.31560901000006, -2.650861877999887],
              [29.315643310000098, -2.650413035999975],
              [29.315969, -2.649982],
              [29.31614099100011, -2.649447111999962],
              [29.31585121200004, -2.648894786999847],
              [29.315783, -2.648100999999883],
              [29.315281379000055, -2.646836764999932],
              [29.31496, -2.646461],
              [29.314480000000117, -2.64634],
              [29.314144134, -2.646417855999971],
              [29.313185, -2.64602],
              [29.312498092000055, -2.645606040999951],
              [29.312292, -2.645364],
              [29.311745, -2.644449],
              [29.31054300000011, -2.64438],
              [29.309444000000155, -2.64381],
              [29.308348, -2.643913],
              [29.30738600100011, -2.644222998999908],
              [29.307085001000075, -2.644214],
              [29.306246, -2.643798998999898],
              [29.305834, -2.643729999999891],
              [29.305112594000036, -2.643504807999932],
              [29.304752, -2.643333],
              [29.304100036000136, -2.643418550999968],
              [29.303277970000124, -2.643141984999829],
              [29.30224800200017, -2.643141507999872],
              [29.301836015000106, -2.643227576999891],
              [29.300943375000145, -2.643071651999946],
              [29.300455093000096, -2.642717837999896],
              [29.29963302599998, -2.641923665999912],
              [29.29927301100014, -2.641734004999933],
              [29.298878, -2.641733],
              [29.298225, -2.64209499999987],
              [29.29793400000011, -2.64209499999987],
              [29.297281001000158, -2.641800999999873],
              [29.296751, -2.641163],
              [29.296253, -2.640714],
              [29.295429000000127, -2.640541],
              [29.294657, -2.640523],
              [29.29373931800012, -2.640617608999946],
              [29.29353332600016, -2.640496730999871],
              [29.293276000000162, -2.63985799999989],
              [29.292883000000188, -2.639426],
              [29.292265, -2.638909],
              [29.29169845500013, -2.638925552999922],
              [29.290859001000115, -2.638010998999903],
              [29.29037885500003, -2.637837838999815],
              [29.29018992499999, -2.637629365999885],
              [29.290121, -2.637027],
              [29.290001, -2.636818999999889],
              [29.289672457000165, -2.636625627999877],
              [29.289265000000114, -2.635801],
              [29.288382, -2.63505],
              [29.28807300000011, -2.63456699999989],
              [29.28776363100019, -2.633910710999942],
              [29.28724832000006, -2.633616351999933],
              [29.28692436100016, -2.633306503999961],
              [29.28689005299998, -2.632978910999952],
              [29.28716500000013, -2.632513],
              [29.286976, -2.632082],
              [29.286770000000104, -2.631874],
              [29.285739898000145, -2.631821869999953],
              [29.285016853000116, -2.631716506999965],
              [29.28472883800015, -2.631613877999882],
              [29.28431701599999, -2.631303547999949],
              [29.283836364000024, -2.630595684999946],
              [29.28334808300002, -2.629068612999959],
              [29.282817949000048, -2.627877799999965],
              [29.282543000000146, -2.626807999999869],
              [29.282028993000154, -2.626134332999925],
              [29.281403000000125, -2.625832],
              [29.280922000000146, -2.626124998999956],
              [29.280426001000023, -2.625952],
              [29.279808000000116, -2.624917],
              [29.279516000000115, -2.624761],
              [29.279224000000113, -2.624846999999818],
              [29.279018366000173, -2.625123506999955],
              [29.27870926999998, -2.625174953999931],
              [29.277938505000066, -2.624950107999894],
              [29.27738952700014, -2.625070810999944],
              [29.277046203000168, -2.624949692999962],
              [29.276514, -2.624275999999895],
              [29.276222, -2.624207],
              [29.275614, -2.624474],
              [29.275218963000157, -2.624577759999909],
              [29.274515152000106, -2.624611853999966],
              [29.274034112000095, -2.624525020999897],
              [29.273779000000104, -2.624335],
              [29.273144000000116, -2.623678999999811],
              [29.27235400000012, -2.623609999999871],
              [29.270647048000114, -2.623703716999899],
              [29.270029005000083, -2.623548001999893],
              [29.26944674500004, -2.623271999999872],
              [29.269188082000028, -2.623272062999888],
              [29.268142887000124, -2.623875199999873],
              [29.267833767000127, -2.624461061999966],
              [29.267508000000134, -2.624547],
              [29.267164230000105, -2.62434005699987],
              [29.267164230000105, -2.624081134999869],
              [29.267731001000186, -2.623409],
              [29.26776499900012, -2.623081011999886],
              [29.267182569000113, -2.621285349999823],
              [29.266737001000024, -2.620905999999877],
              [29.26611900400013, -2.620767831999899],
              [29.265656, -2.620474],
              [29.265572000000134, -2.620267],
              [29.26581194900018, -2.619715111999938],
              [29.265846, -2.619301],
              [29.265743000999976, -2.619007998999962],
              [29.265289000000166, -2.618291],
              [29.265049, -2.61817],
              [29.264636994000057, -2.618463515999849],
              [29.26413900100016, -2.618617998999923],
              [29.263229, -2.618342],
              [29.26293800000019, -2.618325],
              [29.262593999000046, -2.618462],
              [29.261617660000184, -2.619134664999933],
              [29.261066987000163, -2.619462936999923],
              [29.260639192000042, -2.619858741999906],
              [29.26033, -2.620030999999869],
              [29.260107, -2.620531],
              [29.259883881000178, -2.620686530999933],
              [29.259077000000104, -2.620703],
              [29.258656, -2.620798],
              [29.257627, -2.621367],
              [29.256494755000062, -2.622056097999916],
              [29.256151000000102, -2.622193999999865],
              [29.254880923000087, -2.622383023999873],
              [29.254432824000048, -2.622523011999931],
              [29.253920000000107, -2.622917],
              [29.25361029200002, -2.623246611999832],
              [29.253489999000124, -2.623520999999869],
              [29.253455999000153, -2.624676998999973],
              [29.25326499900018, -2.624988932999941],
              [29.252485, -2.625702998999941],
              [29.251146, -2.626512999999818],
              [29.25056129100011, -2.626926224999977],
              [29.25021866800006, -2.626770571999884],
              [29.250032001000193, -2.626530000999878],
              [29.24968900000016, -2.626374],
              [29.248899459000086, -2.625700949999953],
              [29.248659037000095, -2.625407044999975],
              [29.24859047000018, -2.625113962999876],
              [29.248713, -2.62438900099994],
              [29.248747, -2.623646999999892],
              [29.248541, -2.623371],
              [29.248421, -2.622664],
              [29.24831787200003, -2.62245691499993],
              [29.247822, -2.622129],
              [29.247427000000187, -2.62199],
              [29.247063828000023, -2.622112910999817],
              [29.246792, -2.622352],
              [29.246586, -2.62337],
              [29.24661786200005, -2.62425077499995],
              [29.246541976000174, -2.624672889999886],
              [29.24619884399999, -2.625190016999966],
              [29.245923995000112, -2.625172852999867],
              [29.245581, -2.624879],
              [29.24534000000017, -2.624344],
              [29.24508299200005, -2.624050994999948],
              [29.24458698900014, -2.623739961999945],
              [29.244518279000147, -2.623308420999877],
              [29.24458694500015, -2.623084305999896],
              [29.244925000000137, -2.622711],
              [29.24464200000017, -2.622667],
              [29.244251000000133, -2.622885],
              [29.243737762000137, -2.623010084999976],
              [29.243160000000103, -2.6233],
              [29.24280400000015, -2.623739999999884],
              [29.242001999000138, -2.62395399899998],
              [29.241312026000173, -2.624243736999972],
              [29.240652, -2.62430599999982],
              [29.24010800200017, -2.624489],
              [29.24006799900019, -2.624617998999952],
              [29.24065594600006, -2.625379907999957],
              [29.240576, -2.625558],
              [29.240648, -2.626048],
              [29.240041732, -2.626653669999826],
              [29.239226789000043, -2.627191960999937],
              [29.23871000000014, -2.626942999999869],
              [29.238027721000094, -2.626742655999976],
              [29.237646, -2.626323999999897],
              [29.237651468000138, -2.625828411999919],
              [29.23746700000015, -2.625625],
              [29.236795000000143, -2.625282],
              [29.23637200000013, -2.625521998999886],
              [29.236198000000172, -2.624743],
              [29.23582246500007, -2.624490271999946],
              [29.235712, -2.62485],
              [29.235885620000033, -2.625900744999967],
              [29.23581887500012, -2.626738067999952],
              [29.235712, -2.62679600599995],
              [29.235675812000125, -2.627776144999871],
              [29.235208567000086, -2.62794994099994],
              [29.23480415400013, -2.627909658999897],
              [29.234073687000148, -2.628058475999978],
              [29.234037072000035, -2.628230661999908],
              [29.233199908000074, -2.628559949999897],
              [29.23265600000019, -2.628119],
              [29.232403, -2.628079],
              [29.231628296000054, -2.628733825999973],
              [29.230981827000107, -2.628840684999943],
              [29.23034095700001, -2.628858565999906],
              [29.23019027700019, -2.62864112799997],
              [29.230251313000053, -2.628283976999967],
              [29.230038, -2.627985],
              [29.229350875000023, -2.627757879999876],
              [29.229187021000143, -2.627601871999957],
              [29.22941, -2.626997],
              [29.229124000000127, -2.626756],
              [29.228857001000165, -2.62669799899993],
              [29.22845968500019, -2.626231446999952],
              [29.227858999000034, -2.625824998999917],
              [29.22674600100015, -2.625366],
              [29.226299, -2.625393],
              [29.225859000000185, -2.625731],
              [29.22546800000015, -2.625851999999838],
              [29.225374, -2.626238999999885],
              [29.22572500400014, -2.626453015999914],
              [29.225751877000107, -2.627326248999964],
              [29.225528717000145, -2.627780674999883],
              [29.225208000000123, -2.627669],
              [29.224531, -2.627803],
              [29.223947478000127, -2.628238874999965],
              [29.22358703600014, -2.628203629999973],
              [29.22350701099998, -2.628728949999925],
              [29.223263000000145, -2.629183999999839],
              [29.22354698100014, -2.629406451999955],
              [29.223766, -2.629999],
              [29.223819732000038, -2.630422113999941],
              [29.223752976000185, -2.630831717999911],
              [29.223207000000116, -2.631541],
              [29.22266960200011, -2.63186955399982],
              [29.221725239000193, -2.631918974999906],
              [29.221334458000115, -2.63160681699992],
              [29.22073200000017, -2.631625],
              [29.220291138000107, -2.631945370999972],
              [29.21977424700009, -2.63183402999988],
              [29.219747543000153, -2.631326198999886],
              [29.21900367800015, -2.630983352999863],
              [29.21908378700016, -2.630542276999961],
              [29.21928405699998, -2.630209683999965],
              [29.218946456000026, -2.63015484899995],
              [29.218887328000108, -2.629945276999933],
              [29.218473433999975, -2.629958867999903],
              [29.218679428000144, -2.629348515999936],
              [29.21909713800011, -2.629255056999966],
              [29.21925735500008, -2.628956555999935],
              [29.219600677000074, -2.628863095999918],
              [29.219760894000103, -2.628511189999927],
              [29.21977043200019, -2.628074645999959],
              [29.219547271000124, -2.62774944399996],
              [29.219591141000024, -2.627197027999841],
              [29.21939659200018, -2.627041100999975],
              [29.21916008100004, -2.626444339999978],
              [29.21884918100011, -2.626129387999868],
              [29.218536377000135, -2.625985145999948],
              [29.21833992000012, -2.625718115999973],
              [29.218019485000184, -2.625655888999972],
              [29.21768569900007, -2.625379799999848],
              [29.21724891600013, -2.625504493999927],
              [29.21741867100019, -2.625887393999904],
              [29.216838837000182, -2.625909804999935],
              [29.216098785000042, -2.625771760999953],
              [29.216045380000025, -2.625553368999931],
              [29.215505600000085, -2.625546932999896],
              [29.215070723999986, -2.625099181999815],
              [29.215007781000054, -2.624720334999893],
              [29.215133667000146, -2.624497651999945],
              [29.215093613000192, -2.624074458999928],
              [29.214696884000148, -2.623661279999965],
              [29.21441650400004, -2.623228311999981],
              [29.214033128000153, -2.623130321999952],
              [29.21390342700016, -2.622508287999949],
              [29.213506698000117, -2.622252701999912],
              [29.213169098000037, -2.621375322999882],
              [29.212572097000134, -2.621286153999961],
              [29.212438583000107, -2.6216335299999],
              [29.21182441700006, -2.621254919999956],
              [29.21138763400006, -2.621660232999943],
              [29.211284637000176, -2.622056721999968],
              [29.210277557000154, -2.622560022999949],
              [29.209667206, -2.622555732999956],
              [29.208808898000086, -2.622229337999954],
              [29.208259582000096, -2.62151765699997],
              [29.20780563400018, -2.621054409999886],
              [29.20695877100019, -2.620658158999959],
              [29.206817627000078, -2.620528935999857],
              [29.206460953000033, -2.619433163999872],
              [29.20612144500018, -2.618898628999943],
              [29.205930711000065, -2.618199348999838],
              [29.205003738000187, -2.61709022499997],
              [29.20433616600019, -2.615900753999881],
              [29.204133987000034, -2.615670679999937],
              [29.20359992900012, -2.615410804999954],
              [29.203226089000054, -2.615606784999898],
              [29.202175140000065, -2.615664719999927],
              [29.201587677000077, -2.616061210999931],
              [29.201408386000026, -2.616889952999941],
              [29.20095062199999, -2.617366314999913],
              [29.20044326800013, -2.617513416999941],
              [29.20012664900014, -2.61729955699991],
              [29.19972038200018, -2.616724966999868],
              [29.19940567000009, -2.616987704999929],
              [29.19913673500014, -2.616974353999979],
              [29.19846534800007, -2.617290495999953],
              [29.197868348000043, -2.617375134999918],
              [29.19749832100007, -2.617673633999971],
              [29.19695854299999, -2.617642402999934],
              [29.19662475500013, -2.616978883999934],
              [29.196197510000047, -2.616702555999893],
              [29.19589042800004, -2.616916418999892],
              [29.195457458000078, -2.617027758999882],
              [29.195436477000044, -2.617553470999951],
              [29.195337295000172, -2.617784976999928],
              [29.194946289000143, -2.617869615999894],
              [29.19447326600016, -2.617535589999875],
              [29.19403648299999, -2.617682694999814],
              [29.193672181000068, -2.617679356999872],
              [29.193096162000074, -2.617509363999943],
              [29.19270134100003, -2.617063522999899],
              [29.192474365000066, -2.616969823999966],
              [29.192104340000128, -2.617424249999942],
              [29.19186782900016, -2.61756682399988],
              [29.19144439700011, -2.617448090999915],
              [29.191087723000123, -2.616934299999969],
              [29.191123962000177, -2.616650818999972],
              [29.19062805100009, -2.61626005099987],
              [29.190320969000084, -2.616249559999972],
              [29.18963241500012, -2.616501091999908],
              [29.189352036000116, -2.616367815999979],
              [29.18856811500018, -2.615668772999868],
              [29.188388824000185, -2.615257023999902],
              [29.18786048900006, -2.615404842999965],
              [29.18759727400004, -2.615383863999853],
              [29.18665695200002, -2.615700483999945],
              [29.186382295000158, -2.61574268399994],
              [29.185560226000064, -2.615457772999946],
              [29.18527412500009, -2.615162133999888],
              [29.185581207000098, -2.614708184999813],
              [29.185844422000116, -2.614517926999952],
              [29.186847687000125, -2.614116906999925],
              [29.187185287000148, -2.613884448999954],
              [29.187576293000177, -2.613768338999932],
              [29.188789368000073, -2.613054752999972],
              [29.18876838700004, -2.612693547999925],
              [29.188512802000105, -2.612212418999889],
              [29.18811416600016, -2.612132309999936],
              [29.188135147000025, -2.611624955999957],
              [29.1877517690001, -2.610802411999941],
              [29.187051774000054, -2.610243558999969],
              [29.186782836000134, -2.610155819999932],
              [29.186489105000078, -2.610717057999977],
              [29.186077118000014, -2.610875367999938],
              [29.184978484000055, -2.6108965869999],
              [29.184333801000093, -2.610632418999899],
              [29.184196472000053, -2.610695839999835],
              [29.18417549100019, -2.611128806999943],
              [29.18400764400002, -2.611329316999843],
              [29.183490754000104, -2.61146664599994],
              [29.182876588000056, -2.612078904999976],
              [29.182876588000056, -2.612934349999932],
              [29.182708740000066, -2.613683939999874],
              [29.182550430000106, -2.61412739799988],
              [29.18151473900008, -2.614074467999956],
              [29.1808815, -2.614169597999933],
              [29.180047988000183, -2.614750384999923],
              [29.179636001000063, -2.614739656999916],
              [29.179456710000068, -2.614623545999905],
              [29.178907394000134, -2.614729165999961],
              [29.178136826000127, -2.614591836999978],
              [29.177175521000095, -2.614275216999943],
              [29.176784515000065, -2.614317416999882],
              [29.176425933000075, -2.614517926999952],
              [29.176374436000117, -2.614739656999916],
              [29.17658424299998, -2.615320443999849],
              [29.17645836000014, -2.61593293999988],
              [29.176034927000046, -2.61588001299998],
              [29.175497055000108, -2.615679502999967],
              [29.174652100000117, -2.615531683999905],
              [29.17400932300012, -2.615183114999923],
              [29.173828124000124, -2.614898203999815],
              [29.174188613000013, -2.614433526999903],
              [29.174505233000104, -2.613852739999913],
              [29.174018860000103, -2.612723111999969],
              [29.17366027899999, -2.612416982999946],
              [29.173511504000032, -2.612131833999968],
              [29.173532485000123, -2.611698866999973],
              [29.174039841000138, -2.610938785999963],
              [29.174163817000135, -2.610394715999973],
              [29.173881531, -2.610167979999858],
              [29.173597336000057, -2.610115049999934],
              [29.173057556000174, -2.609766720999971],
              [29.173048020000067, -2.609101532999887],
              [29.17281532400017, -2.608478545999958],
              [29.172054291000165, -2.608130216999825],
              [29.171823502000166, -2.607295989999898],
              [29.171283722000112, -2.606979369999976],
              [29.170862198000066, -2.607021569999915],
              [29.17033386300011, -2.607179879999876],
              [29.16980552800004, -2.607232809999914],
              [29.169404984000096, -2.607105968999974],
              [29.16891860900006, -2.606050251999932],
              [29.169151307000163, -2.604086159999952],
              [29.168455125000094, -2.603009223999948],
              [29.167747497000164, -2.602565765999941],
              [29.16739845300009, -2.60240745599998],
              [29.166765213000076, -2.60164737599996],
              [29.166278840000075, -2.601267097999937],
              [29.16555023100011, -2.601277827999979],
              [29.165002823000123, -2.601150989999951],
              [29.164674760000025, -2.600675819999879],
              [29.1644325260001, -2.600559711999949],
              [29.163703919000113, -2.600739240999872],
              [29.16308021499998, -2.600697040999933],
              [29.16263771000007, -2.600443601999928],
              [29.162435532000018, -2.600031852999962],
              [29.16303825300008, -2.597962377999977],
              [29.163322449000134, -2.597254991999819],
              [29.16339683500007, -2.59633636399991],
              [29.163312911000048, -2.595914124999979],
              [29.162775039000167, -2.595132827999976],
              [29.162647247000052, -2.595069407999972],
              [29.161539078000146, -2.594995498999936],
              [29.160524367999983, -2.595227717999933],
              [29.159236908000082, -2.595153808999953],
              [29.15896225000006, -2.595259428999896],
              [29.158349991000136, -2.595977304999963],
              [29.157749176000095, -2.596283673999835],
              [29.156522751000125, -2.596526382999912],
              [29.156017304000102, -2.596558094999978],
              [29.15521430900003, -2.596082924999905],
              [29.155023575000087, -2.596114633999946],
              [29.154359817000056, -2.595882415999938],
              [29.154169083000056, -2.595639466999899],
              [29.154073716000084, -2.595175027999915],
              [29.15363121000007, -2.594805477999898],
              [29.153224946000023, -2.594634294999878],
              [29.15276527400016, -2.595512865999979],
              [29.152383804000067, -2.595745086999955],
              [29.15158271900009, -2.595713377999971],
              [29.15083312900009, -2.595449447999897],
              [29.150611878000177, -2.595280646999868],
              [29.150663376000182, -2.594742059999931],
              [29.15064239500009, -2.594034671999907],
              [29.150758743000154, -2.593411684999978],
              [29.15103340100012, -2.592440366999881],
              [29.1512241370001, -2.592186926999887],
              [29.150747299999978, -2.591912506999904],
              [29.150409699000022, -2.591627358999858],
              [29.14965057400019, -2.591173409999954],
              [29.149301530000116, -2.591268301999889],
              [29.14926147500006, -2.591506957999911],
              [29.148763657000075, -2.592303037999898],
              [29.148784638000166, -2.592873096999881],
              [29.14890098600017, -2.593126533999907],
              [29.14924812200013, -2.593337773999963],
              [29.149185181000178, -2.593580722999832],
              [29.14874267600004, -2.593907832999889],
              [29.14880561900003, -2.594488619999879],
              [29.14874267600004, -2.594646929999954],
              [29.14831924399999, -2.59460473099989],
              [29.147981643000037, -2.594826458999876],
              [29.14753913900006, -2.594805477999898],
              [29.147041321000074, -2.594879388999971],
              [29.145786285000156, -2.594573021999963],
              [29.145174026000177, -2.594752549999953],
              [29.144445420000125, -2.594868659999975],
              [29.144287110000164, -2.595111608999844],
              [29.14446640100016, -2.59559726699996],
              [29.14435005200005, -2.596399784999846],
              [29.144149781000067, -2.596494673999871],
              [29.143789291000076, -2.596346854999979],
              [29.143030166000074, -2.596188544999961],
              [29.14197349600005, -2.596135854999943],
              [29.141193390000183, -2.596251964999965],
              [29.140960694000114, -2.596610784999882],
              [29.14091873200016, -2.597149371999876],
              [29.140697479000096, -2.597381590999817],
              [29.13971519500018, -2.597867250999855],
              [29.13929176300013, -2.598374127999932],
              [29.139186858000073, -2.599176644999943],
              [29.1395683290001, -2.600291489999961],
              [29.139957427000127, -2.600813148999976],
              [29.140506745000096, -2.601193188999957],
              [29.141244887000084, -2.601890086999902],
              [29.141447067000115, -2.602143525999907],
              [29.141351700000143, -2.602734803999965],
              [29.141372681000178, -2.603114841999968],
              [29.141551972000173, -2.603611229999956],
              [29.14131927500017, -2.604223488999878],
              [29.140897751000125, -2.604233979999947],
              [29.14035797100007, -2.603917359999969],
              [29.13987350500014, -2.603938577999941],
              [29.13958740200013, -2.605216025999937],
              [29.139354706000063, -2.605564356999878],
              [29.138996124000073, -2.605659483999943],
              [29.138036727000042, -2.605575084999884],
              [29.137771607000104, -2.605152606999923],
              [29.137454987000183, -2.604909895999981],
              [29.13638877900007, -2.604529617999958],
              [29.135681152000075, -2.60441350799988],
              [29.13511085500005, -2.604561329999854],
              [29.134647370000152, -2.605026006999935],
              [29.133949280000138, -2.60535335499992],
              [29.133516311999983, -2.605469464999942],
              [29.132524491000083, -2.606071232999909],
              [29.132101059000036, -2.607032060999927],
              [29.13156318600005, -2.607222079999929],
              [29.131004333000078, -2.607961176999936],
              [29.130592346000185, -2.608299016999922],
              [29.13021278300016, -2.608404634999886],
              [29.130064011000115, -2.609091041999932],
              [29.12986373900003, -2.609217642999909],
              [29.12933540400013, -2.609143733999929],
              [29.129009246000123, -2.609207152999943],
              [29.128215789000137, -2.610157489999949],
              [29.12779426700007, -2.610294580999948],
              [29.12747764700015, -2.610104559999911],
              [29.127161027000056, -2.609682321999969],
              [29.12708663900014, -2.608531475999882],
              [29.126749038000185, -2.608088014999964],
              [29.125554894000118, -2.607560035999882],
              [29.124933235000128, -2.60774993799987],
              [29.12454223700007, -2.607676267999921],
              [29.123908997000115, -2.607253788999969],
              [29.12393951400003, -2.607063769999968],
              [29.12477493299997, -2.60582852399989],
              [29.124826, -2.605321999999887],
              [29.124711990000037, -2.60461425699998],
              [29.12445600000018, -2.603951999999879],
              [29.124035000000163, -2.603685],
              [29.123297, -2.603601],
              [29.12270508200004, -2.604043937999961],
              [29.122093, -2.603990999999894],
              [29.121481000000188, -2.603685],
              [29.120932, -2.603168],
              [29.120520000000113, -2.602355],
              [29.120508, -2.6017],
              [29.12022400000012, -2.601088],
              [29.119959, -2.60078099999987],
              [29.11936800000018, -2.600485999999876],
              [29.119072000000187, -2.600243],
              [29.118799, -2.60019],
              [29.118037248000178, -2.600432529999921],
              [29.117659000000174, -2.600031999999885],
              [29.117689000000155, -2.599272],
              [29.117542, -2.598554],
              [29.116972000000146, -2.5983],
              [29.116485597000178, -2.598933695999961],
              [29.11583137600013, -2.59910273599985],
              [29.11551475600004, -2.599071024999887],
              [29.115303039000025, -2.598680256999955],
              [29.115430832000186, -2.598342417999902],
              [29.115747452000107, -2.598078488999931],
              [29.116401673000155, -2.597793339999953],
              [29.116506576, -2.597635029999822],
              [29.11632728500018, -2.597075461999964],
              [29.11583137600013, -2.596948860999873],
              [29.11462783799999, -2.596938132999981],
              [29.11417388900003, -2.597075461999964],
              [29.11306571900019, -2.597540140999968],
              [29.11261177000017, -2.59741330199995],
              [29.11194610700005, -2.596600293999927],
              [29.11130142200011, -2.596178054999882],
              [29.110647201000063, -2.596283673999835],
              [29.10984420800014, -2.596758603999945],
              [29.109411240000043, -2.596653221999929],
              [29.10932731600019, -2.59559726699996],
              [29.108798982000167, -2.595132827999976],
              [29.10839843800011, -2.59465765799996],
              [29.108219147000057, -2.594689369999969],
              [29.107648850000032, -2.595428466999863],
              [29.10707855100003, -2.595618485999864],
              [29.106634140000097, -2.595628975999944],
              [29.105705262000185, -2.595745086999955],
              [29.105316161000076, -2.595650194999905],
              [29.10486030500016, -2.595808504999866],
              [29.104564666000158, -2.596251964999965],
              [29.104343415000017, -2.596357582999929],
              [29.10375213500015, -2.596178054999882],
              [29.10341453500007, -2.596220253999888],
              [29.10292816200007, -2.59657931299995],
              [29.10252761800018, -2.596727132999945],
              [29.102231979000123, -2.596684692999872],
              [29.10161972000003, -2.596452474999921],
              [29.100774766000086, -2.596568584999943],
              [29.099391938000053, -2.596389054999918],
              [29.09921264700006, -2.596156835999977],
              [29.09911727800005, -2.595470665999869],
              [29.098356247000083, -2.595660684999871],
              [29.09751319800006, -2.596230744999957],
              [29.09613990800011, -2.596853732999932],
              [29.09580230800009, -2.596621512999889],
              [29.09531593300005, -2.595671175999939],
              [29.094810486000085, -2.595259428999896],
              [29.09424972500011, -2.594002961999934],
              [29.094028472000048, -2.593306064999922],
              [29.09385871900008, -2.592989444999944],
              [29.092603683000164, -2.591849088999823],
              [29.09239196800013, -2.591753959999949],
              [29.092126846000042, -2.591986417999976],
              [29.091938019000168, -2.592408657999954],
              [29.09180069000007, -2.592978714999958],
              [29.091293334, -2.592989444999944],
              [29.09085083100007, -2.592334746999939],
              [29.090639114000055, -2.592144727999937],
              [29.090152741000054, -2.592303037999898],
              [29.089509963000182, -2.59212350699994],
              [29.089244843000074, -2.591659067999899],
              [29.088506698, -2.591785668999876],
              [29.087566377000144, -2.592165708999971],
              [29.08709144700009, -2.592313527999977],
              [29.086658477000128, -2.592334746999939],
              [29.086120606000122, -2.592693805999886],
              [29.08552932700013, -2.592756986999973],
              [29.084926606000124, -2.593126533999907],
              [29.084144592000087, -2.593770741999833],
              [29.08358574, -2.594034671999907],
              [29.083005906000153, -2.594150781999929],
              [29.082773209000038, -2.594488619999879],
              [29.081981660000167, -2.594763277999959],
              [29.08132553200005, -2.595227717999933],
              [29.08115386900016, -2.595526693999886],
              [29.081211089000078, -2.596220253999888],
              [29.080957412000146, -2.596378563999963],
              [29.08052825900006, -2.596471547999897],
              [29.079605103000063, -2.59690642399994],
              [29.079055786000026, -2.597001552999814],
              [29.078538894000076, -2.597181081999906],
              [29.078348160000075, -2.597402811999871],
              [29.077186585000163, -2.59822630799988],
              [29.076459884000144, -2.598585366999942],
              [29.07626915000003, -2.598775385999943],
              [29.075698853000176, -2.599609612999927],
              [29.075424194999982, -2.599820612999906],
              [29.075054168, -2.600296257999901],
              [29.073932648000152, -2.601257324999949],
              [29.073812485000076, -2.601557731999947],
              [29.07339286800004, -2.602078198999834],
              [29.0732517240001, -2.603019237999888],
              [29.072971343000063, -2.603379726999947],
              [29.072591782000018, -2.603600024999878],
              [29.071689605000074, -2.60376000499997],
              [29.071088790000033, -2.603739975999929],
              [29.070829392000064, -2.603519915999925],
              [29.07020950300017, -2.603399752999849],
              [29.069747924000126, -2.603459835999843],
              [29.06910705700011, -2.603219508999928],
              [29.068607331000123, -2.602818965999916],
              [29.067344666000054, -2.60235857899994],
              [29.066591264000067, -2.601835726999923],
              [29.06574630700004, -2.601683616999935],
              [29.064310074000105, -2.602088928999933],
              [29.063634873000126, -2.602097511999943],
              [29.062704087000043, -2.601675033999868],
              [29.062223434000146, -2.601734161999957],
              [29.06175804200018, -2.602781533999917],
              [29.061979293000093, -2.60376143499991],
              [29.061979293000093, -2.604166983999903],
              [29.06098937900009, -2.605509996999899],
              [29.06057548500013, -2.605839489999937],
              [29.060543059999986, -2.60616874699997],
              [29.060323715000095, -2.606523513999946],
              [29.060424805000082, -2.608179091999944],
              [29.060195922000048, -2.60846638799984],
              [29.059436799000025, -2.608601568999916],
              [29.058641434000037, -2.609243391999826],
              [29.058261871000184, -2.610054254999966],
              [29.058101654000154, -2.610738516999902],
              [29.05858230600012, -2.612216710999974],
              [29.058877945000177, -2.61257147799995],
              [29.059141160000024, -2.612622022999972],
              [29.059392929000126, -2.61292624399988],
              [29.059377671000107, -2.613534449999975],
              [29.05911445600009, -2.614007472999901],
              [29.058059692000086, -2.6154010299999],
              [29.05797386200004, -2.615679740999894],
              [29.05843925500011, -2.616347074999965],
              [29.05852317800003, -2.616710423999905],
              [29.058456420000027, -2.617014407999932],
              [29.057813645000067, -2.61709880699982],
              [29.057611466000083, -2.617369174999965],
              [29.05731582700008, -2.618112564999933],
              [29.0568675990001, -2.618247745999952],
              [29.056268693000106, -2.618171691999862],
              [29.055517197000086, -2.617969035999977],
              [29.054773330000103, -2.618332147999979],
              [29.054122925, -2.618839025999932],
              [29.054206849000025, -2.620376347999923],
              [29.05392074499997, -2.621389865999959],
              [29.053033829000128, -2.622234582999852],
              [29.052593231000117, -2.622200726999893],
              [29.052036285000042, -2.622344255999963],
              [29.051664352000103, -2.622606277999978],
              [29.051385879000065, -2.623163698999974],
              [29.051225662000036, -2.623738050999975],
              [29.05142021300003, -2.623915433999912],
              [29.052036285000042, -2.623569010999859],
              [29.052696228, -2.623721122999939],
              [29.052999497000144, -2.624101162999921],
              [29.053100587000188, -2.624667166999927],
              [29.052467347000174, -2.625081061999822],
              [29.052593231000117, -2.625503538999965],
              [29.053270340000097, -2.626052378999873],
              [29.05344772300009, -2.626305818999924],
              [29.053472519000024, -2.626694439999824],
              [29.053733826000098, -2.626863239999921],
              [29.054088593000188, -2.62688875099991],
              [29.05440139800004, -2.627184389999968],
              [29.054494857000066, -2.627843140999914],
              [29.054367066000054, -2.628155707999952],
              [29.054164887000127, -2.628223180999896],
              [29.05382728600017, -2.628096579999919],
              [29.053396225000142, -2.628096579999919],
              [29.053733826000098, -2.628637074999972],
              [29.053768158000082, -2.629262209999979],
              [29.054021835000185, -2.630216598999937],
              [29.054426194000143, -2.630436419999967],
              [29.054275512000117, -2.631711721999977],
              [29.054367066000054, -2.632708548999972],
              [29.05453681900019, -2.633139371999903],
              [29.05430030800005, -2.633662938999976],
              [29.054840088000105, -2.63446545599993],
              [29.05451965300017, -2.635056733999932],
              [29.05492592000013, -2.635512827999889],
              [29.05501747200003, -2.635766266999895],
              [29.054916382000044, -2.636239288999889],
              [29.054452895000168, -2.636467217999893],
              [29.05492592000013, -2.6373119349999],
              [29.054958344000113, -2.6378102299999],
              [29.054512023000086, -2.63849449199995],
              [29.054199218, -2.639812230999837],
              [29.054164887000127, -2.640107869999952],
              [29.053962707000096, -2.640538692999883],
              [29.054460526000184, -2.640792129999909],
              [29.054714202000184, -2.640716075999933],
              [29.055372238000075, -2.640344380999977],
              [29.055778503000056, -2.640336035999837],
              [29.056039810000073, -2.640563963999966],
              [29.056175231, -2.641146898999978],
              [29.056098937000115, -2.642405270999973],
              [29.05613327100008, -2.642886875999864],
              [29.056818008000164, -2.642582653999909],
              [29.057079316000113, -2.642608164999956],
              [29.05753517200003, -2.643064258999971],
              [29.057806015000097, -2.643604754999842],
              [29.057771683000112, -2.644069433999903],
              [29.057914735000054, -2.644407271999967],
              [29.05821990900006, -2.644593000999976],
              [29.059343337000143, -2.644989966999958],
              [29.059392929000126, -2.645251988999974],
              [29.059656143999973, -2.645792483999912],
              [29.059774399000105, -2.646544216999871],
              [29.059722900000168, -2.647659301999852],
              [29.059486389000085, -2.648512363999885],
              [29.060028076000037, -2.649196623999956],
              [29.06024742200009, -2.649306296999953],
              [29.060838699000044, -2.649230240999884],
              [29.061368942000115, -2.649256943999944],
              [29.061641692000137, -2.649516343999949],
              [29.061531067000146, -2.65015101399996],
              [29.06133842500003, -2.650410650999959],
              [29.060964585000136, -2.650320053999906],
              [29.060762406000038, -2.650944947999903],
              [29.060567857000024, -2.65115618599998],
              [29.059959411000136, -2.651561736999952],
              [29.05930709800009, -2.652099846999931],
              [29.058591842000055, -2.652524709999966],
              [29.05799102800006, -2.652760982999894],
              [29.057323455000187, -2.652879238999901],
              [29.057071685000096, -2.653048276999868],
              [29.05661010600005, -2.653584479999893],
              [29.056373595000082, -2.653733968999916],
              [29.05580329900016, -2.653650521999964],
              [29.0554580700001, -2.653403043999902],
              [29.054967879000117, -2.652448653999898],
              [29.054731368000034, -2.652617453999937],
              [29.05456161500007, -2.65351295399995],
              [29.054241180000133, -2.653816937999977],
              [29.054727555000113, -2.6552290919999],
              [29.054756164000082, -2.656384705999869],
              [29.054647447000093, -2.656672000999947],
              [29.054317474000072, -2.656899929999952],
              [29.05385589700012, -2.65705704699991],
              [29.052997588999972, -2.656950473999871],
              [29.052482605000023, -2.656748054999923],
              [29.052154540000117, -2.65702676799998],
              [29.052196502000072, -2.657609462999972],
              [29.05205345200011, -2.657861231999902],
              [29.05167389000019, -2.658226251999906],
              [29.05139541700015, -2.658673762999911],
              [29.051208496000186, -2.659290551999959],
              [29.051298142000178, -2.660597800999938],
              [29.051116944000114, -2.660929678999878],
              [29.049907685000107, -2.661511897999901],
              [29.049577714000122, -2.661976574999812],
              [29.049409866000076, -2.662567852999871],
              [29.049140931000125, -2.663950680999903],
              [29.048303604000182, -2.664130448999856],
              [29.048091888999977, -2.664333105999901],
              [29.048210144999985, -2.664670943999965],
              [29.04784393300008, -2.665396689999909],
              [29.047899246000156, -2.665854929999966],
              [29.048709869000163, -2.667474507999941],
              [29.04911422700019, -2.667635917999974],
              [29.049249649000046, -2.667863845999932],
              [29.04936790400012, -2.668539522999879],
              [29.04933548000014, -2.668825387999902],
              [29.049150467000175, -2.669085263999932],
              [29.048099518000186, -2.669367313999885],
              [29.04786300600017, -2.669722080999861],
              [29.04783058200013, -2.669968366999967],
              [29.048278808000077, -2.670973301999823],
              [29.04882621700017, -2.672095774999946],
              [29.049139023000123, -2.672425031999921],
              [29.049764633, -2.672619341999962],
              [29.05016899200018, -2.67266154299989],
              [29.050436020000063, -2.672834395999871],
              [29.050794601000177, -2.673616170999935],
              [29.050693512000066, -2.673852681999961],
              [29.050296783000192, -2.674308775999919],
              [29.050313949000156, -2.674570559999836],
              [29.050592422000193, -2.674781799999892],
              [29.051082612000073, -2.674798725999949],
              [29.051572800000145, -2.67471432699989],
              [29.05183410699999, -2.675499676999948],
              [29.05231475800008, -2.675800799999934],
              [29.052825929000164, -2.67573046699988],
              [29.053218842000092, -2.675913572999946],
              [29.053396225000142, -2.676158666999868],
              [29.053117752000105, -2.676530359999958],
              [29.053142547999983, -2.677011726999922],
              [29.053287506000117, -2.677155255999821],
              [29.053911210000024, -2.676986455999952],
              [29.055061341000055, -2.676868199999944],
              [29.055305480000072, -2.676344393999955],
              [29.05549049400014, -2.676243065999927],
              [29.056377410000152, -2.676203726999972],
              [29.056631087000085, -2.676107882999929],
              [29.05696868800004, -2.676209211999947],
              [29.057050704000062, -2.676410673999953],
              [29.056961060000106, -2.676792144999979],
              [29.057079316000113, -2.677180767999971],
              [29.057510376000096, -2.677728652999917],
              [29.057636262000187, -2.678092955999944],
              [29.057678224000142, -2.678869961999851],
              [29.057823181000117, -2.679672478999976],
              [29.057771683000112, -2.680339812999875],
              [29.057434082000157, -2.68116760199996],
              [29.057323455000187, -2.682155847999923],
              [29.05685043300008, -2.683194874999913],
              [29.056571960000042, -2.683617113999958],
              [29.05596351600019, -2.684301375999894],
              [29.055212021000102, -2.684419631999845],
              [29.054845811000177, -2.684542177999958],
              [29.054122925, -2.684613703999901],
              [29.053676606000124, -2.684797286999981],
              [29.05321121200018, -2.684782743999961],
              [29.053100587000188, -2.684554575999925],
              [29.052696228, -2.684748886999898],
              [29.05256843699999, -2.685585258999936],
              [29.051849366000113, -2.685925959999963],
              [29.05114174, -2.685863971999879],
              [29.04997634800003, -2.685576675999926],
              [29.04959869300012, -2.68520188299982],
              [29.048793793000186, -2.685297965999951],
              [29.048133849000067, -2.68599915599998],
              [29.04800415000011, -2.686203002999946],
              [29.048074720999978, -2.687350511999966],
              [29.048227311000176, -2.687865733999956],
              [29.049552918000188, -2.687992571999871],
              [29.050853729000096, -2.688372611999967],
              [29.051225662000036, -2.688752649999969],
              [29.05196952900019, -2.689023017999944],
              [29.052391051000086, -2.688904761999936],
              [29.053430557000127, -2.688896416999967],
              [29.05400466899999, -2.688592194999956],
              [29.054252625000117, -2.68864512499988],
              [29.054525376000186, -2.688883303999944],
              [29.054754257000013, -2.689251660999958],
              [29.054790496000123, -2.689529895999897],
              [29.054662705000112, -2.68985915199994],
              [29.054620742999987, -2.690408229999832],
              [29.05511093100006, -2.691590785999949],
              [29.05528640700004, -2.692366121999896],
              [29.05519485500008, -2.692659377999917],
              [29.05483245800019, -2.692942380999966],
              [29.05441856400006, -2.693111181999939],
              [29.054122925, -2.693364620999944],
              [29.05376052800017, -2.693347692999964],
              [29.053396225000142, -2.693085907999944],
              [29.053129196000157, -2.693238734999966],
              [29.05282211400015, -2.693102835999923],
              [29.052610397000137, -2.692655085999945],
              [29.052282333000164, -2.692553757999974],
              [29.05146980300009, -2.692562102999943],
              [29.05087089500006, -2.692942380999966],
              [29.05019569300015, -2.694395064999924],
              [29.050245285000187, -2.694614886999887],
              [29.050945281000168, -2.695064067999851],
              [29.051467895000087, -2.695733308999877],
              [29.05179786600013, -2.696243047999872],
              [29.052080154, -2.696828125999957],
              [29.05205345200011, -2.697089670999958],
              [29.05229759200006, -2.697920559999943],
              [29.052402497000116, -2.698088406999887],
              [29.052469254000073, -2.698712586999932],
              [29.052164078000033, -2.699741839999888],
              [29.051740645999985, -2.700434684999948],
              [29.051479341000118, -2.700468300999887],
              [29.051368713000045, -2.700050829999896],
              [29.051099778000093, -2.699961662999897],
              [29.050203323000062, -2.700003861999903],
              [29.049486160000185, -2.70033311799989],
              [29.049510956000063, -2.700966834999917],
              [29.049751282000045, -2.701941967999971],
              [29.050245285000187, -2.702976941999964],
              [29.050600052000107, -2.703424691999885],
              [29.05106163100004, -2.704587458999924],
              [29.05143737900005, -2.704835413999831],
              [29.05198669500004, -2.70486068699995],
              [29.053802490000066, -2.705502747999958],
              [29.05433273200009, -2.705651998999883],
              [29.055137635000165, -2.705719231999922],
              [29.055534363000106, -2.705955505999953],
              [29.05542373600008, -2.706144569999879],
              [29.054494857000066, -2.706609248999939],
              [29.05437660200016, -2.707132815999955],
              [29.054435729000147, -2.707259654999859],
              [29.05527114900019, -2.707411526999977],
              [29.055347442000027, -2.707884548999971],
              [29.05519104000001, -2.708487033999973],
              [29.055225372000052, -2.708846568999888],
              [29.05572318999998, -2.709093570999812],
              [29.056140899000184, -2.708915232999971],
              [29.056421280000052, -2.708915232999971],
              [29.056919098000037, -2.709075689999906],
              [29.057020188000024, -2.709481000999972],
              [29.05704498400013, -2.710106132999897],
              [29.056900025000118, -2.710310697999887],
              [29.056505204000075, -2.710300444999916],
              [29.05593109200015, -2.709979533999842],
              [29.055635453000093, -2.710097789999963],
              [29.05566024700005, -2.710933923999846],
              [29.05519485500008, -2.711913822999918],
              [29.054996491000168, -2.712522268999976],
              [29.05434227000012, -2.712848901999905],
              [29.05364227200016, -2.712885140999902],
              [29.05329513600003, -2.713366507999922],
              [29.05305099500015, -2.713999985999976],
              [29.052780152000025, -2.714295624999977],
              [29.052248002000056, -2.714591264999854],
              [29.051656724000168, -2.71480250299993],
              [29.051647186000082, -2.715444564999928],
              [29.05171966500012, -2.715876578999882],
              [29.051528931000178, -2.716322898999977],
              [29.050880433000145, -2.717117786999893],
              [29.050304413000106, -2.717648981999901],
              [29.05021285900017, -2.717953205999947],
              [29.04984855700002, -2.718535899999893],
              [29.0494937900001, -2.718924520999906],
              [29.049079896000137, -2.719203233999963],
              [29.048666001000072, -2.719895838999889],
              [29.04829406700003, -2.719954966999978],
              [29.047771454000042, -2.719727038999963],
              [29.046951294000053, -2.720174549999967],
              [29.046815872000025, -2.720470188999911],
              [29.046783447999985, -2.721238851999829],
              [29.04685211200018, -2.721451281999975],
              [29.045497895000153, -2.7232408509999],
              [29.0455493930001, -2.72340130799995],
              [29.046165467000094, -2.723680018999914],
              [29.04586219800018, -2.724229096999977],
              [29.045219422000116, -2.724803446999886],
              [29.045024871000066, -2.725673436999841],
              [29.04474639800003, -2.725597380999943],
              [29.044425965000073, -2.724896430999934],
              [29.044088364000118, -2.725048540999921],
              [29.043615342000066, -2.725023031999854],
              [29.04298973100009, -2.725335596999969],
              [29.042753219000076, -2.724972486999945],
              [29.042480469000054, -2.724894760999973],
              [29.04209518400006, -2.725183486999868],
              [29.041866303, -2.725546836999911],
              [29.041831971000192, -2.726002930999925],
              [29.04152870200005, -2.726180313999862],
              [29.04105568, -2.727151631999845],
              [29.041351319000057, -2.727176904999965],
              [29.041967392000117, -2.72698283099993],
              [29.04242324900008, -2.727033375999895],
              [29.04255104000009, -2.727210759999935],
              [29.04222106900005, -2.727793693999956],
              [29.042713165000123, -2.728059053999971],
              [29.042482377, -2.72874808399996],
              [29.042161942000064, -2.729026794999925],
              [29.041967392000117, -2.729558944999894],
              [29.041580200000055, -2.729381560999968],
              [29.04143333500008, -2.729478835999942],
              [29.041444778000084, -2.72998976699995],
              [29.041570664000176, -2.730344533999869],
              [29.04107284600019, -2.730783699999961],
              [29.04121399000013, -2.731184482999936],
              [29.041511536000087, -2.731104849999895],
              [29.04187583800018, -2.731374979999941],
              [29.042009354000072, -2.731662272999927],
              [29.041664122999975, -2.731898783999952],
              [29.041816711000024, -2.732777117999888],
              [29.042179108000084, -2.73317432399989],
              [29.041740417000085, -2.733790874999841],
              [29.042016983000053, -2.734314679999841],
              [29.042593002000046, -2.734686134999947],
              [29.042871475000084, -2.735277414999928],
              [29.04289627100019, -2.735526561999905],
              [29.04264450200003, -2.735759019999875],
              [29.04228592000004, -2.735689161999972],
              [29.042449950000105, -2.735074757999939],
              [29.042213439000136, -2.734905719999972],
              [29.041681289000167, -2.734821318999934],
              [29.041351319000057, -2.735066174999929],
              [29.041303634000087, -2.735599517999901],
              [29.04083633500005, -2.735910891999936],
              [29.041320800000108, -2.736280201999932],
              [29.04129219100014, -2.736510752999891],
              [29.04099655200008, -2.737025976999973],
              [29.040950775000113, -2.737381458999835],
              [29.040472030000046, -2.737726926999926],
              [29.041334153000037, -2.738039493999906],
              [29.041681289000167, -2.738279102999854],
              [29.041849137000042, -2.738706825999884],
              [29.041748047, -2.73904466599987],
              [29.041267395000034, -2.739027737999891],
              [29.040676117000146, -2.739238977999946],
              [29.040422440000043, -2.739643811999883],
              [29.040449142000114, -2.74012231699993],
              [29.04022026100006, -2.740598916999943],
              [29.040328979000037, -2.741629362999959],
              [29.04056930500019, -2.741949557999874],
              [29.04085349999997, -2.742754220999927],
              [29.04085159300007, -2.743950843999926],
              [29.040601731000038, -2.74342727699991],
              [29.04017448400009, -2.743100166999966],
              [29.040086747000032, -2.744733095999948],
              [29.04018211400006, -2.745899914999882],
              [29.04011726300007, -2.747034072999952],
              [29.039249420000147, -2.748622892999947],
              [29.03879737900013, -2.750860929999931],
              [29.038700104000156, -2.751770019999924],
              [29.038528441999972, -2.752444981999815],
              [29.038078308000024, -2.755321026999923],
              [29.038000107000073, -2.757025956999939],
              [29.0381336210001, -2.75769090599988],
              [29.03835868800013, -2.757931946999861],
              [29.03881073000008, -2.758792399999891],
              [29.038881303000153, -2.75924658799994],
              [29.03861999600008, -2.760417699999891],
              [29.038192748000085, -2.761039017999963],
              [29.036052703000053, -2.7600829599999],
              [29.034437180000054, -2.760107040999969],
              [29.03372383100009, -2.75958108899988],
              [29.032464982000135, -2.758959769999876],
              [29.030776978000063, -2.759963511999899],
              [29.02970695500011, -2.760107040999969],
              [29.028234482000187, -2.760107040999969],
              [29.02697372500006, -2.76044154199991],
              [29.026617051000073, -2.760823964999872],
              [29.024976731000038, -2.76209068199995],
              [29.023599624000042, -2.76323795299993],
              [29.02072334200011, -2.766775130999974],
              [29.019962311000086, -2.768113373999938],
              [29.019083023000178, -2.769571303999953],
              [29.0180377960001, -2.770527361999882],
              [29.017467499000077, -2.77071857399983],
              [29.017038345000117, -2.771268128999907],
              [29.015827179999974, -2.771746158999918],
              [29.015470506000156, -2.77208089699991],
              [29.014543533000108, -2.772391556999878],
              [29.01402092000012, -2.772797822999905],
              [29.013236999000185, -2.773060797999904],
              [29.011928558000193, -2.773562668999944],
              [29.011548997000148, -2.774279594999939],
              [29.01081276000008, -2.774805306999838],
              [29.010194779000187, -2.775044440999977],
              [29.009456634000117, -2.775689600999897],
              [29.009220122999977, -2.77626323599992],
              [29.008911133000026, -2.776836871999876],
              [29.008815766000055, -2.777219294999895],
              [29.00826835600003, -2.77846193399995],
              [29.007698059000177, -2.779155015999947],
              [29.005250930000102, -2.78023052199984],
              [29.00429725700019, -2.780811308999944],
              [29.004144165000127, -2.780167372999927],
              [29.003284455000028, -2.780648945999928],
              [29.002489084000047, -2.781261935999908],
              [29.001562119000027, -2.783498048999832],
              [29.000715255000102, -2.785708902999886],
              [29.000477, -2.787973],
              [29.000345230000107, -2.790050028999872],
              [29.00100708100007, -2.7916209699999],
              [29.000929, -2.793433],
              [29.000610352000024, -2.79476404199994],
              [29.000477, -2.796603],
              [29.000504, -2.798971],
              [29.000874001000113, -2.800702998999896],
              [29.002066, -2.803445998999905],
              [29.002119000000164, -2.804910999999891],
              [29.001932001000057, -2.806216],
              [29.001616000000126, -2.806775000999949],
              [29.001350402000185, -2.807466982999927],
              [29.001484, -2.808267],
              [29.00171952600016, -2.808719087999918],
              [29.001749001000178, -2.810212000999911],
              [29.002333, -2.81127599999985],
              [29.003308997000147, -2.812020473999894],
              [29.004396, -2.814126],
              [29.00527000000011, -2.815299],
              [29.005589, -2.81607],
              [29.005959, -2.81663],
              [29.007946000000175, -2.817908],
              [29.01059341500013, -2.819719076999945],
              [29.012630463000107, -2.820837021999921],
              [29.014379999000084, -2.821182998999859],
              [29.015783, -2.821503],
              [29.016788483000028, -2.822115896999947],
              [29.0177690000001, -2.822009],
              [29.019697849000067, -2.82097369],
              [29.0203130000001, -2.820923],
              [29.020841997000048, -2.821003],
              [29.021389000000113, -2.821409],
              [29.022077806000084, -2.821409],
              [29.02266502300006, -2.821571825999968],
              [29.023277282000038, -2.822649001999935],
              [29.023942948000013, -2.822858571999916],
              [29.024217606000036, -2.822651623999946],
              [29.02507209800001, -2.822603939999908],
              [29.025405228000068, -2.822985115999927],
              [29.02568981600018, -2.823511660999884],
              [29.025881, -2.824085],
              [29.026642, -2.825424],
              [29.02692604000015, -2.825686693999899],
              [29.027853000000107, -2.82630799899988],
              [29.028543000000127, -2.826284],
              [29.03065872300016, -2.826092957999947],
              [29.031799, -2.826045],
              [29.032917, -2.825853998999889],
              [29.033392000000106, -2.825351999999839],
              [29.033985000000143, -2.824253],
              [29.034960000000126, -2.824253],
              [29.035577809000188, -2.824395955999933],
              [29.036268000000177, -2.824396],
              [29.036790848000123, -2.824515818999942],
              [29.037741, -2.82442],
              [29.038383484000065, -2.824300765999965],
              [29.03916715200006, -2.824037873999885],
              [29.040474000000188, -2.82301],
              [29.04118728600008, -2.822866676999979],
              [29.042850000000158, -2.821887],
              [29.043729246000055, -2.820859882999969],
              [29.044847000000175, -2.819999],
              [29.045918000000142, -2.819305999999813],
              [29.046297, -2.819305999999813],
              [29.047449, -2.819418],
              [29.049259187000132, -2.819969890999914],
              [29.050119400000142, -2.820015905999924],
              [29.05016517700011, -2.820244788999958],
              [29.050861, -2.820644],
              [29.05114573400016, -2.821193484999924],
              [29.051146000000188, -2.823177],
              [29.05117034900013, -2.823727129999952],
              [29.051432, -2.824157],
              [29.05192387500017, -2.82472589199989],
              [29.051853181000183, -2.825275419999969],
              [29.051949, -2.826422],
              [29.05247116000004, -2.826828717999888],
              [29.052589416000046, -2.8271296019999],
              [29.053161585000055, -2.827558689999876],
              [29.053707000000145, -2.827665],
              [29.05441856400006, -2.828047514999923],
              [29.055083668, -2.828333833999977],
              [29.05548900100007, -2.828621],
              [29.056345000000135, -2.82886],
              [29.05674900000014, -2.829146999999864],
              [29.05750725600018, -2.83000614599996],
              [29.057842000000164, -2.830509],
              [29.057556000000147, -2.8307],
              [29.057389664000027, -2.83096625099995],
              [29.057343000000117, -2.831417],
              [29.057556153000064, -2.831990718999975],
              [29.058556, -2.832923],
              [29.059148811, -2.833926694999832],
              [29.06007588400007, -2.834571923999931],
              [29.06041, -2.835791],
              [29.060645978000025, -2.836387942999977],
              [29.06071893600017, -2.837008449999928],
              [29.06114590400017, -2.837702843999978],
              [29.061716080000053, -2.838888167999926],
              [29.061123, -2.838872],
              [29.06121400000012, -2.839829],
              [29.060749000000158, -2.841399],
              [29.060522044000038, -2.842538039999909],
              [29.061293, -2.843265999999858],
              [29.062063217000116, -2.844587086999979],
              [29.06328208900004, -2.845539941999959],
              [29.064281121000135, -2.846043891999841],
              [29.065344, -2.845099999999889],
              [29.066184999000086, -2.845062998999936],
              [29.066998000000126, -2.84568],
              [29.068764, -2.849504],
              [29.06858300000016, -2.850096999999892],
              [29.068249, -2.850802999999871],
              [29.066385269000136, -2.851341008999896],
              [29.065706253000087, -2.852751016999946],
              [29.065660478000098, -2.854208945999972],
              [29.066837311000086, -2.855437992999953],
              [29.069107, -2.855922],
              [29.070323565000024, -2.857718488999979],
              [29.070188686000165, -2.858488027999897],
              [29.069553375000055, -2.858488082999884],
              [29.06837700000017, -2.859354],
              [29.067924499000128, -2.86035490099988],
              [29.068287, -2.861312],
              [29.068647385000077, -2.862723111999912],
              [29.06937217400008, -2.864273068999978],
              [29.070640798000056, -2.864773919999948],
              [29.071795999000187, -2.864436],
              [29.072983, -2.863583999999833],
              [29.073218832000066, -2.863513050999927],
              [29.074027999000123, -2.863512999999898],
              [29.074694, -2.863441],
              [29.075193000000127, -2.863488998999969],
              [29.07593, -2.863895],
              [29.076214, -2.864300999999898],
              [29.076327974000094, -2.864856912999812],
              [29.07612037600012, -2.864955901999906],
              [29.077162000000158, -2.867643],
              [29.077522249000026, -2.867914893999966],
              [29.078384, -2.867551999999876],
              [29.079877726000177, -2.868325857999935],
              [29.081869, -2.868781],
              [29.083590000000186, -2.869601],
              [29.08517646700011, -2.872287034999943],
              [29.085666999000182, -2.87295799899988],
              [29.086388, -2.873024],
              [29.086767000000123, -2.873142998999981],
              [29.08729, -2.873142998999981],
              [29.087932781000063, -2.872952064999936],
              [29.08840751600019, -2.872569798999905],
              [29.088572840000154, -2.872044512999878],
              [29.08918947, -2.870827894999934],
              [29.090000000000146, -2.870825],
              [29.090522906000047, -2.871183937999888],
              [29.090831502000185, -2.871494497999947],
              [29.091045001000168, -2.872331000999964],
              [29.091379000000188, -2.873072],
              [29.09145000000018, -2.873740998999892],
              [29.091662981000127, -2.874290059999964],
              [29.091616001000148, -2.874433998999962],
              [29.092852001000097, -2.875605],
              [29.09316100000018, -2.876107],
              [29.09311299700005, -2.877110001999938],
              [29.092663000000186, -2.877445],
              [29.092649460000132, -2.87834978199993],
              [29.093019, -2.87852],
              [29.093612834999988, -2.878926880999927],
              [29.09361300000012, -2.88036],
              [29.09377883500008, -2.881052583999917],
              [29.094183, -2.881245],
              [29.094444000000124, -2.882392],
              [29.094444000000124, -2.882822],
              [29.094111476000137, -2.883181349999973],
              [29.093899000000135, -2.88373],
              [29.093516925000074, -2.884256080999876],
              [29.09256744400011, -2.885331153999914],
              [29.092495000000156, -2.885832999999877],
              [29.092211, -2.886406],
              [29.091831001000173, -2.886956000999874],
              [29.091306785000143, -2.887386292999963],
              [29.09104538000014, -2.887744664999957],
              [29.090857000000142, -2.88839],
              [29.090857000000142, -2.88898799999987],
              [29.09066600000017, -2.889537],
              [29.090214, -2.890038999999888],
              [29.090023042000098, -2.890660285999957],
              [29.09019097200013, -2.892285786999878],
              [29.09041921500011, -2.892833320999898],
              [29.08996800000017, -2.893842],
              [29.08969700900019, -2.895663939999906],
              [29.090000000000146, -2.895578999999884],
              [29.090000000000146, -2.896133],
              [29.08971402600008, -2.896777944999883],
              [29.08969100000013, -2.89728],
              [29.08947775000007, -2.897734875999902],
              [29.08933448700003, -2.898235558999943],
              [29.089359283000135, -2.899478196999837],
              [29.089621000000193, -2.900601],
              [29.089668000000188, -2.901151],
              [29.090118000000132, -2.901629],
              [29.090286000000162, -2.903039],
              [29.090356998000175, -2.904544957999917],
              [29.090713755000138, -2.905332309999949],
              [29.09073639000013, -2.905906677999894],
              [29.091125489000092, -2.906928777999895],
              [29.091567994000172, -2.907555579999894],
              [29.091778000000147, -2.907949],
              [29.09223938100007, -2.909403084999894],
              [29.093689, -2.912046],
              [29.094072, -2.912538],
              [29.094160006000095, -2.9129080099999],
              [29.094373917000098, -2.913314841999977],
              [29.095276, -2.914438],
              [29.095395972000176, -2.914843902999962],
              [29.09554100000014, -2.915906999999891],
              [29.095705033000115, -2.91672158199998],
              [29.09591900000015, -2.916995],
              [29.095858, -2.917282999999884],
              [29.096314602000177, -2.918510932999936],
              [29.096178, -2.919375],
              [29.09672155600009, -2.920604994999962],
              [29.097728819000054, -2.921114906999947],
              [29.098360062000154, -2.921022891999883],
              [29.099674000000107, -2.920517998999912],
              [29.10005200000012, -2.920744],
              [29.10005200000012, -2.921424],
              [29.10059738200016, -2.922929047999958],
              [29.101308004000146, -2.923986513999864],
              [29.102680207000105, -2.924613],
              [29.10458183200012, -2.925293921999923],
              [29.105577001000142, -2.925067998999907],
              [29.106393814000114, -2.924339056999884],
              [29.106754, -2.922973],
              [29.107704000000183, -2.922471998999868],
              [29.108791311000175, -2.92233521299994],
              [29.109917000000166, -2.922652],
              [29.110536681000156, -2.923040814999865],
              [29.11086003600019, -2.923833633999891],
              [29.11123300000014, -2.924302],
              [29.111748, -2.924689999999885],
              [29.111961129000065, -2.92497610199996],
              [29.113152000000127, -2.925881998999955],
              [29.11449524700015, -2.926296767999872],
              [29.116744994000044, -2.927717922999875],
              [29.118347169000174, -2.928462981999871],
              [29.118980409000187, -2.928962945999956],
              [29.119568000000186, -2.929327],
              [29.12043000200009, -2.92964499999988],
              [29.122240033000026, -2.930922006999936],
              [29.124006260000044, -2.931283947999873],
              [29.12513900000016, -2.931056999999839],
              [29.127312000000188, -2.930192],
              [29.127983000000143, -2.930114],
              [29.128548000000137, -2.930135],
              [29.129511, -2.931439999999896],
              [29.130661, -2.932332],
              [29.131069, -2.933152998999958],
              [29.131521224000153, -2.934473036999862],
              [29.13215637100018, -2.936019896999937],
              [29.133652001000144, -2.937113],
              [29.13478290400019, -2.936976971999911],
              [29.136247732000072, -2.938159729999882],
              [29.137435492000122, -2.938463847999913],
              [29.138752000000125, -2.939193],
              [29.13909109900004, -2.939504025999838],
              [29.139365585000178, -2.939576888999909],
              [29.140318, -2.94082],
              [29.140436000000193, -2.941392999999891],
              [29.140436000000193, -2.942397],
              [29.14050090900014, -2.942804421999881],
              [29.141821, -2.94575],
              [29.14259, -2.946887999999888],
              [29.1461240000001, -2.947782],
              [29.14770493599997, -2.948208982999972],
              [29.149471283000025, -2.949071882999931],
              [29.15060422900018, -2.949255937999965],
              [29.151464000000146, -2.949027],
              [29.151735, -2.948708],
              [29.15227889900018, -2.94711494399985],
              [29.152058, -2.946817],
              [29.15286600000013, -2.946626],
              [29.152653001000033, -2.947072],
              [29.152816999000095, -2.947679995999977],
              [29.153312789999973, -2.947679998999888],
              [29.154266357000097, -2.948956012999929],
              [29.155124031000184, -2.949456433999956],
              [29.154852007000102, -2.95198495499983],
              [29.15690600100004, -2.952559999999892],
              [29.15808499500008, -2.954425989999834],
              [29.160182952000184, -2.953438997999967],
              [29.160492, -2.953133],
              [29.160662, -2.953771],
              [29.16070886199998, -2.95453374799996],
              [29.160704001000056, -2.955518],
              [29.161183772000015, -2.956351603999906],
              [29.161356, -2.956891],
              [29.163623917000052, -2.958538936999958],
              [29.163990001000116, -2.958693998999934],
              [29.164988, -2.959243],
              [29.166034699000193, -2.959625719999906],
              [29.16655721300009, -2.959768982999947],
              [29.167745794000098, -2.959677016999876],
              [29.167700001000128, -2.960224],
              [29.16606903000013, -2.963457106999954],
              [29.16607099700019, -2.964502954999944],
              [29.166342001000032, -2.966006999999877],
              [29.167200061000187, -2.96732802799994],
              [29.16864978500007, -2.968010896999942],
              [29.16846800000019, -2.968420999999864],
              [29.167835, -2.969241],
              [29.166115, -2.969605],
              [29.16579818800011, -2.970333099999891],
              [29.165707000000168, -2.971107],
              [29.1660710000001, -2.971609],
              [29.16683600000016, -2.973398],
              [29.167379379000067, -2.974354982999955],
              [29.169004000000143, -2.97636],
              [29.169155, -2.977659998999911],
              [29.169563, -2.979116],
              [29.170786000000135, -2.98112],
              [29.171375000000182, -2.982120999999893],
              [29.171192000000133, -2.984079999999892],
              [29.170197, -2.985126],
              [29.168522, -2.98703799999987],
              [29.167889, -2.988404000999935],
              [29.167629178000084, -2.990122381999925],
              [29.167561, -2.990248999999892],
              [29.166575515000034, -2.99068116199993],
              [29.165625000000148, -2.991000000999975],
              [29.165398, -2.991501],
              [29.16553306500009, -2.99236488199989],
              [29.165806, -2.992911999999876],
              [29.167072000000132, -2.994004],
              [29.16779700000012, -2.996416998999962],
              [29.168975939, -2.997190958999966],
              [29.17028799900004, -2.997235998999884],
              [29.17104299900018, -2.996589],
              [29.171597, -2.995946999999887],
              [29.173117000000104, -2.994870999999876],
              [29.173830000000123, -2.994201999999859],
              [29.17461398300003, -2.993848007999873],
              [29.175041000000135, -2.993366],
              [29.175493, -2.993175],
              [29.177286, -2.992272],
              [29.177420000000154, -2.992969],
              [29.17832600000014, -2.994197999999869],
              [29.179653167000083, -2.995181560999924],
              [29.180508, -2.995611999999881],
              [29.18119800000011, -2.995851],
              [29.18154900000019, -2.996081999999888],
              [29.18245700000017, -2.996377],
              [29.183064000000115, -2.997364999999888],
              [29.18362236000013, -2.998157977999881],
              [29.18511695000018, -3.000524338999924],
              [29.185345, -3.001299],
              [29.184891000000107, -3.002027999999882],
              [29.184336, -3.002447],
              [29.183521271000018, -3.003721951999978],
              [29.18356514000004, -3.004678010999953],
              [29.184065, -3.005815999999868],
              [29.184244156999966, -3.006863116999909],
              [29.184698106000155, -3.008456945999967],
              [29.185014196, -3.009046498999908],
              [29.185693956000136, -3.009366978999879],
              [29.188817979000078, -3.009229896999898],
              [29.190759660000026, -3.00909996099989],
              [29.19105170200004, -3.00878198199996],
              [29.192297, -3.008777999999893],
              [29.193392, -3.00873],
              [29.19415098000013, -3.009327982999935],
              [29.19436500000012, -3.00983],
              [29.19436500000012, -3.011717],
              [29.194412224000075, -3.012601274999952],
              [29.194864000000166, -3.013317999999856],
              [29.19526900000011, -3.013771999999847],
              [29.195507050000117, -3.014536857999872],
              [29.196005, -3.015469],
              [29.196005, -3.015971],
              [29.196541, -3.017151999999896],
              [29.19736099200003, -3.01810693799996],
              [29.197548, -3.017906],
              [29.198072001000128, -3.018264999999872],
              [29.199023572999977, -3.019195584999977],
              [29.199570000000165, -3.019841],
              [29.200047, -3.02010399999989],
              [29.20116233800013, -3.0213947299999],
              [29.201994000000184, -3.021968],
              [29.202303, -3.022325998999975],
              [29.202803001000063, -3.022709],
              [29.203308105000133, -3.022948979999853],
              [29.203836, -3.022977999999853],
              [29.204288, -3.02265899899993],
              [29.204561000000126, -3.022113998999942],
              [29.204651000000126, -3.021157],
              [29.20505900000012, -3.020929],
              [29.206915, -3.02234],
              [29.20763970300004, -3.02252292299994],
              [29.21009635900009, -3.022672891999832],
              [29.212223053, -3.022353886999895],
              [29.213898001000075, -3.021808998999916],
              [29.214975, -3.021749],
              [29.215609000000143, -3.02184],
              [29.21660423300017, -3.022614001999955],
              [29.21814345500013, -3.023159975999874],
              [29.219955333000144, -3.023068910999825],
              [29.22035600000015, -3.022962],
              [29.221142000000157, -3.022425],
              [29.22250700000012, -3.021978999999874],
              [29.223837, -3.020911],
              [29.224828939000133, -3.020612017999952],
              [29.225620005000053, -3.020806003999894],
              [29.226797104000184, -3.021717071999888],
              [29.227295, -3.023766],
              [29.227974, -3.025267999999869],
              [29.22887992900013, -3.026269912999908],
              [29.229831352000076, -3.026587783999901],
              [29.233409881000114, -3.025995969999883],
              [29.2343598760001, -3.025541040999826],
              [29.2359448470001, -3.025405009999929],
              [29.23585300000019, -3.028363999999897],
              [29.235535, -3.029002],
              [29.234541, -3.030094],
              [29.23367825600019, -3.030322630999933],
              [29.232096, -3.030686000999879],
              [29.231370998000102, -3.031550036999875],
              [29.231325150000032, -3.032598017999931],
              [29.233770370000173, -3.035693884999944],
              [29.2351284020001, -3.03605794899994],
              [29.237392001000103, -3.03592099899987],
              [29.238252640000155, -3.036011934999863],
              [29.240472887000067, -3.036057982999921],
              [29.241151780000052, -3.03651681599996],
              [29.241106034000154, -3.037105082999972],
              [29.240017763000083, -3.038290245999974],
              [29.239204407000102, -3.039108991999967],
              [29.239067078000062, -3.040292024999872],
              [29.239566959000115, -3.041475898999977],
              [29.241874842000186, -3.043250877999981],
              [29.242158891000145, -3.042943714999979],
              [29.243280411000057, -3.043711900999938],
              [29.244016648000127, -3.043711900999938],
              [29.244778001000157, -3.043854998999962],
              [29.245062, -3.044285],
              [29.245547954000187, -3.044849946999875],
              [29.24545300000011, -3.044935999999893],
              [29.246403, -3.046394],
              [29.247763000000134, -3.04744],
              [29.249935001000154, -3.048851],
              [29.25093003800015, -3.049579294999944],
              [29.25206400000019, -3.050853998999969],
              [29.253328611000086, -3.051673100999892],
              [29.253907788000163, -3.052356492999877],
              [29.253693000000112, -3.052858],
              [29.252472000000182, -3.053266999999892],
              [29.25165600100007, -3.053905000999919],
              [29.25165600100007, -3.055178999999896],
              [29.251883000000134, -3.055953],
              [29.25212679400005, -3.056126853999899],
              [29.252097547, -3.058670369999959],
              [29.251789000000144, -3.059337999999855],
              [29.251123014000143, -3.059696992999875],
              [29.250219346000165, -3.060389756999939],
              [29.24948310900004, -3.061106442999972],
              [29.24946022000006, -3.061756133999893],
              [29.249727248000113, -3.062644004999925],
              [29.250309, -3.063288999999827],
              [29.250957, -3.063544],
              [29.251717001000145, -3.064022],
              [29.252573, -3.064497],
              [29.253500000000145, -3.064762],
              [29.254556957000148, -3.064779995999913],
              [29.255161285000042, -3.065330028999938],
              [29.25498008700015, -3.06624102599983],
              [29.25493431200016, -3.067514895999977],
              [29.255388249000134, -3.068061100999955],
              [29.25615114800013, -3.068377949999956],
              [29.25680400000016, -3.068298],
              [29.256732939000017, -3.068801925999878],
              [29.25670814500012, -3.069588660999955],
              [29.256922000000145, -3.070209998999928],
              [29.257284165000158, -3.070642947999886],
              [29.25656127900004, -3.07164597499991],
              [29.255613327000106, -3.072796105999942],
              [29.255161285000042, -3.074024915999928],
              [29.25526237500003, -3.075205325999889],
              [29.255068001000154, -3.075634],
              [29.255068001000154, -3.076875998999924],
              [29.255402000000117, -3.07749699999988],
              [29.255854, -3.077856],
              [29.256067, -3.078213999999889],
              [29.256351000000166, -3.078907],
              [29.256328192000012, -3.080079070999943],
              [29.25573349000001, -3.080866336999975],
              [29.25520324700011, -3.081435917999841],
              [29.255478, -3.081809],
              [29.257515000000183, -3.083586],
              [29.257517000000178, -3.084541988999888],
              [29.257336000000123, -3.086318],
              [29.256701000000135, -3.087956],
              [29.255568151000148, -3.088457923999954],
              [29.25402800000012, -3.088320999999837],
              [29.252670288000047, -3.088139056999978],
              [29.25217247000012, -3.088548898999932],
              [29.251675000000148, -3.089550000999907],
              [29.25231, -3.091098],
              [29.25362200000012, -3.093783999999857],
              [29.25362200000012, -3.094602],
              [29.249002162000124, -3.094194014999914],
              [29.247463227000082, -3.09460306099993],
              [29.246378000999982, -3.095604998999875],
              [29.246513367000034, -3.096606016999942],
              [29.247282009, -3.097563002999891],
              [29.250225, -3.098790999999892],
              [29.251902, -3.099884],
              [29.25289721600018, -3.100840047999952],
              [29.253527, -3.100980999999877],
              [29.254259, -3.101546],
              [29.254426968000132, -3.102645128999939],
              [29.254236000000105, -3.103409999999883],
              [29.253593, -3.10396],
              [29.25283432100008, -3.103983401999813],
              [29.251930001000176, -3.104127],
              [29.25159834100009, -3.10434178],
              [29.251337051000178, -3.104843377999885],
              [29.250813001000154, -3.105632],
              [29.250667813, -3.106230348999929],
              [29.25030136, -3.106914041999971],
              [29.250439, -3.107597],
              [29.25148010300012, -3.108735084999921],
              [29.25302100000016, -3.110373],
              [29.255102000000193, -3.111193],
              [29.25560200000018, -3.112833],
              [29.256371, -3.113651999999888],
              [29.25718498200007, -3.114106893999974],
              [29.258724173000132, -3.114061053999819],
              [29.260489128000074, -3.114334862999897],
              [29.260582000000113, -3.114925],
              [29.259948730000133, -3.114608049999958],
              [29.259176254000124, -3.114608049999958],
              [29.25786066400019, -3.115066577999869],
              [29.256235123000067, -3.116156099999955],
              [29.255465000000186, -3.117339],
              [29.254379, -3.11934200099995],
              [29.25456, -3.122346999999877],
              [29.253834970000185, -3.123531806999949],
              [29.252386000000172, -3.124669],
              [29.251434, -3.124623],
              [29.250528, -3.124303999999825],
              [29.249126000000103, -3.122711],
              [29.24803918400005, -3.122302068999829],
              [29.247271, -3.122484000999975],
              [29.246546000000137, -3.123395],
              [29.246635438000055, -3.124850987999935],
              [29.247087479000072, -3.126079082999979],
              [29.249172209999983, -3.128401993999944],
              [29.249307764000037, -3.129357944999924],
              [29.24862654400016, -3.12917379299995],
              [29.24776800000018, -3.128402],
              [29.24677205400019, -3.127765081999939],
              [29.245232001000034, -3.127899999999897],
              [29.244734000000165, -3.128903000999912],
              [29.244598001000156, -3.130222998999898],
              [29.24382800000012, -3.131361],
              [29.243601000000183, -3.132180999999889],
              [29.243376, -3.13637],
              [29.243918596000185, -3.13764305199993],
              [29.24396299900019, -3.138462],
              [29.242878, -3.138600999999881],
              [29.24138293900006, -3.138099024999917],
              [29.240251780000165, -3.138554414999931],
              [29.23984336800015, -3.139328002999889],
              [29.239800000000116, -3.140421],
              [29.2405210820001, -3.141694380999923],
              [29.239753815000086, -3.143108643999881],
              [29.239662171000134, -3.143924950999917],
              [29.240025, -3.14511],
              [29.239573000000178, -3.145611000999907],
              [29.239302000000123, -3.146475999999893],
              [29.23949453500012, -3.148677489999955],
              [29.237324, -3.149359],
              [29.235966000000133, -3.149996],
              [29.23465200100003, -3.150998],
              [29.234488, -3.151692],
              [29.234291, -3.153274000999886],
              [29.234144000000185, -3.153509000999975],
              [29.234081000000117, -3.15473],
              [29.2337950000001, -3.15516],
              [29.233635000000163, -3.15591],
              [29.23297700000012, -3.15628],
              [29.231979369000044, -3.157190082999875],
              [29.23148107700007, -3.158101285999976],
              [29.230894, -3.158283],
              [29.230077743000095, -3.158101080999927],
              [29.229127984000115, -3.158010015999878],
              [29.228448897000135, -3.158692249999945],
              [29.22813034100011, -3.159466026999894],
              [29.228041, -3.160877],
              [29.22831344700012, -3.162561892999918],
              [29.229534133000072, -3.16374705599992],
              [29.23062133800005, -3.164213656999891],
              [29.231798173000186, -3.16466879799998],
              [29.23241615200004, -3.165289877999953],
              [29.232677459000115, -3.165743826999915],
              [29.232677459000115, -3.168133020999903],
              [29.232940852000127, -3.16858674599996],
              [29.23282078000011, -3.169160071999954],
              [29.23103904700008, -3.169781445999945],
              [29.230785, -3.169929],
              [29.230442039000025, -3.169481050999877],
              [29.229488372000105, -3.170665978999978],
              [29.22950600000013, -3.171263],
              [29.229374, -3.172122999999886],
              [29.229374, -3.172513],
              [29.229218000000117, -3.172715],
              [29.229174, -3.17367],
              [29.228947, -3.174399],
              [29.226231, -3.176175],
              [29.22550600000011, -3.176722],
              [29.224735260000102, -3.177722929999959],
              [29.22423744100007, -3.179043054999966],
              [29.22428321800004, -3.180363893999925],
              [29.224827, -3.181728998999972],
              [29.225460039000154, -3.182275998999842],
              [29.22722599900004, -3.18223],
              [29.22790500000019, -3.181910998999911],
              [29.228359, -3.181865999999843],
              [29.22985300900018, -3.182594007999967],
              [29.23111871599997, -3.18368675399995],
              [29.231255, -3.184370999999885],
              [29.229488, -3.186735999999826],
              [29.228584, -3.187146999999811],
              [29.226909735000163, -3.186600954999904],
              [29.225549736000175, -3.186373351999919],
              [29.22500610400016, -3.18709993199991],
              [29.224870816000134, -3.188334574999942],
              [29.22468948500017, -3.190880058999937],
              [29.22432708800011, -3.191699981999932],
              [29.22358329700012, -3.192978965999885],
              [29.22436757900016, -3.192890383999952],
              [29.223955, -3.193815],
              [29.223384730000078, -3.194915728999888],
              [29.222862244000055, -3.196324108999932],
              [29.222744, -3.19728],
              [29.222387314000173, -3.198211430999947],
              [29.22234, -3.199125],
              [29.22248983700007, -3.199495598999931],
              [29.222696000000155, -3.199717],
              [29.22295000100013, -3.200627],
              [29.224024, -3.201287],
              [29.225439, -3.201811],
              [29.226028000000156, -3.201765],
              [29.227044999000157, -3.201175],
              [29.227781, -3.200647999999831],
              [29.228519000000176, -3.200935],
              [29.229065, -3.201246],
              [29.229136641000025, -3.201795284999889],
              [29.228472, -3.20232099999987],
              [29.22744941700006, -3.202917813999875],
              [29.22743225100004, -3.203562258999966],
              [29.227745164000112, -3.204260440999917],
              [29.229153, -3.206317999999897],
              [29.23033, -3.207],
              [29.231417000000135, -3.20782],
              [29.232367, -3.209004],
              [29.23310039400019, -3.209631491999971],
              [29.233635000000163, -3.211053],
              [29.234314001000143, -3.213101000999927],
              [29.233952001000034, -3.213693998999929],
              [29.234523799999977, -3.213660011999878],
              [29.234343000000138, -3.214122],
              [29.233677000000114, -3.214647999999897],
              [29.23260688800019, -3.214791296999977],
              [29.23198890700013, -3.215030192999905],
              [29.231180191000192, -3.215818404999936],
              [29.230974197000023, -3.216909646999909],
              [29.231054305999976, -3.217744110999945],
              [29.231594164000057, -3.219470155999943],
              [29.23073116500018, -3.220523618999948],
              [29.228427815000032, -3.221069153999963],
              [29.22756811000005, -3.221751911999888],
              [29.226723000000106, -3.223127],
              [29.226572, -3.224254998999868],
              [29.226977267000052, -3.225809473999959],
              [29.225891113999978, -3.228444098999944],
              [29.22584724400008, -3.229945896999936],
              [29.226074219000168, -3.23107409499994],
              [29.225893021000047, -3.232167005999941],
              [29.22584724400008, -3.233167885999876],
              [29.226435506000087, -3.234121581999943],
              [29.227476, -3.234670999999821],
              [29.22761344900016, -3.235171079999873],
              [29.22761344900016, -3.237628936999954],
              [29.228518000000122, -3.238720999999885],
              [29.228771209000172, -3.239078520999897],
              [29.229185, -3.239493],
              [29.229422000000113, -3.239850999999874],
              [29.23004, -3.241094],
              [29.230682000000115, -3.242909],
              [29.231157000000167, -3.243626],
              [29.231157000000167, -3.24460599899993],
              [29.231087001000105, -3.245226999999886],
              [29.23103904700008, -3.246444939999947],
              [29.230682373000036, -3.246970414999964],
              [29.230492, -3.248092999999869],
              [29.2304435800001, -3.248834747999979],
              [29.230135000000132, -3.249383147999936],
              [29.230135000000132, -3.250698596999882],
              [29.230691990000025, -3.251151991999848],
              [29.231145997000056, -3.251832995999962],
              [29.230492, -3.252487000999906],
              [29.230923000000132, -3.25393],
              [29.231625000000122, -3.255160999999816],
              [29.231820989000084, -3.256855906999874],
              [29.231461, -3.258011999999894],
              [29.231586456000173, -3.259057998999936],
              [29.23217794800007, -3.259815931999924],
              [29.232716001000085, -3.261042998999926],
              [29.233398438000165, -3.262197970999921],
              [29.234024047000105, -3.262883900999952],
              [29.235228000000177, -3.263370999999836],
              [29.23594700000018, -3.263931],
              [29.237057000000163, -3.264941],
              [29.238331, -3.266946],
              [29.238724, -3.267865],
              [29.2397, -3.268865],
              [29.23793000000012, -3.269288999999844],
              [29.23455, -3.26871199999988],
              [29.23428700000011, -3.269524],
              [29.207888, -3.290202],
              [29.20382700000016, -3.297186],
              [29.200918000000172, -3.308666],
              [29.202711000000193, -3.323494],
              [29.203541, -3.346255999999869],
              [29.204119000000162, -3.361995],
              [29.21015400000016, -3.393887],
              [29.214799989000085, -3.409826960999965],
              [29.21955490200014, -3.42613005599992],
              [29.226422393000178, -3.46755084199998],
              [29.22758102500012, -3.474539040999957],
              [29.232734679000032, -3.488215921999881],
              [29.234809167000094, -3.500646237999945],
              [29.2382144930001, -3.521050930999934],
              [29.239183426000068, -3.533488988999977],
              [29.240511990000016, -3.550524359999884],
              [29.24131774900019, -3.560856103999868],
              [29.24233126300004, -3.580660506999891],
              [29.243524552000054, -3.603996037999934],
              [29.245477676, -3.642174003999912],
              [29.243133544000045, -3.65384101799998],
              [29.242203727000117, -3.697072063999883],
              [29.24174308900001, -3.71847891699997],
              [29.240400314000055, -3.728193044999841],
              [29.23180198600005, -3.790510892999976],
              [29.239946365000037, -3.828964948999953],
              [29.240240096000093, -3.843240975999891],
              [29.24454307500008, -3.868196009999849],
              [29.244596480000155, -3.875334024999972],
              [29.25370979200011, -3.918102025999929],
              [29.259054185000082, -3.931963920999976],
              [29.26418685800013, -3.942631960999961],
              [29.282567977000156, -3.970712898999921],
              [29.284254074000103, -3.973290919999897],
              [29.305898664999972, -3.98947095799997],
              [29.327030183000147, -4.012041090999958],
              [29.34385681100008, -4.03370380299998],
              [29.347841263000078, -4.041376112999956],
              [29.35637092500008, -4.069332122999924],
              [29.36128044100019, -4.085422038999923],
              [29.367755889000023, -4.100027083999976],
              [29.368650436000053, -4.119369982999956],
              [29.378816605000054, -4.175841808999905],
              [29.3803920740001, -4.19271278399998],
              [29.3820762630001, -4.210761069999876],
              [29.38338089100006, -4.242745398999944],
              [29.385301591000143, -4.289831160999938],
              [29.387447357000042, -4.31899499899987],
              [29.388851165, -4.338087081999959],
              [29.387878419000174, -4.357821940999884],
              [29.390363693000097, -4.388988971999936],
              [29.39008522000006, -4.432705877999979],
              [29.391475678, -4.445250032999979],
              [29.471727371000156, -4.444572925999978],
              [29.49482154800006, -4.444375036999873],
              [29.563394546000097, -4.443789004999871],
              [29.597820282000157, -4.444393157999969],
              [29.655046463000076, -4.445394039999883],
              [29.662475586000085, -4.446821211999918],
              [29.661886214000162, -4.447994231999928],
              [29.663139343000182, -4.448019027999919],
              [29.667854309, -4.449250220999943],
              [29.670433044000163, -4.44749879799997],
              [29.671354293000093, -4.447181223999962],
              [29.672561645000144, -4.446834086999957],
              [29.673883439000065, -4.447045801999934],
              [29.679775237000115, -4.450192926999932],
              [29.681163787000116, -4.45033693399995],
              [29.68259429900013, -4.451264857999888],
              [29.68364715600012, -4.451348780999922],
              [29.684825897000053, -4.450675009999941],
              [29.685329437000178, -4.450928211999951],
              [29.686885833000133, -4.452194213999974],
              [29.689493180000113, -4.450717926999971],
              [29.690965652000102, -4.450506209999901],
              [29.69243812600007, -4.45143318099997],
              [29.69420623799999, -4.452700136999908],
              [29.69555282600004, -4.454008100999943],
              [29.69609832700013, -4.455316066999899],
              [29.696435928000085, -4.4560332289999],
              [29.699422836000167, -4.459407805999945],
              [29.702199936000056, -4.460000991999948],
              [29.704050065000104, -4.461012838999977],
              [29.70707893399998, -4.462446211999918],
              [29.70918083300012, -4.462068080999927],
              [29.70998191800004, -4.461688040999889],
              [29.711074829000154, -4.459030150999979],
              [29.711790085000132, -4.458565234999981],
              [29.713851929000043, -4.458227155999964],
              [29.715364455000156, -4.457509994999953],
              [29.716585160000136, -4.456286907999925],
              [29.71767807100008, -4.455316066999899],
              [29.718940735000103, -4.456287859999918],
              [29.719825744000048, -4.457764148999956],
              [29.720161437, -4.459578036999915],
              [29.72306442300004, -4.461771964999912],
              [29.724157334000154, -4.462110042999825],
              [29.726009369000167, -4.462193964999926],
              [29.72853279200018, -4.461771964999912],
              [29.730468750000114, -4.460927962999961],
              [29.731981278000035, -4.459916114999942],
              [29.732402800999978, -4.45932483699994],
              [29.733581543000184, -4.458271025999977],
              [29.734254838000027, -4.457003115999953],
              [29.734378813999967, -4.456076144999884],
              [29.73496818600006, -4.455611228999885],
              [29.735683442000095, -4.455695151999919],
              [29.73863029500012, -4.457214830999874],
              [29.74178314300002, -4.457299232999958],
              [29.742877959000054, -4.458016871999973],
              [29.743509294000148, -4.459746836999955],
              [29.743677139000056, -4.461646078999877],
              [29.743930816000045, -4.463669775999904],
              [29.743972778000114, -4.465443133999827],
              [29.74414062600016, -4.466413974999966],
              [29.745065689000057, -4.466413974999966],
              [29.74674797100016, -4.466540812999938],
              [29.747169495000037, -4.467341900999884],
              [29.748136521000106, -4.468692778999923],
              [29.749273301000187, -4.469326018999936],
              [29.750154495000118, -4.46907091199995],
              [29.751417160000187, -4.46974802099993],
              [29.753774642000053, -4.470001219999915],
              [29.75772857700008, -4.468692778999923],
              [29.76042175400005, -4.46502113199989],
              [29.761177064000037, -4.466625212999929],
              [29.769296647000147, -4.455947876999971],
              [29.769254685000192, -4.454092979999871],
              [29.767944336000028, -4.451638221999872],
              [29.766584397000088, -4.450483797999937],
              [29.765069962000098, -4.449791907999952],
              [29.763826371000107, -4.449503897999932],
              [29.764017105000107, -4.446505068999898],
              [29.76554870600006, -4.441871164999895],
              [29.765281677000075, -4.439467906999937],
              [29.764860154000132, -4.438140869999927],
              [29.763824463000162, -4.4367380139999],
              [29.76487922699999, -4.435256956999922],
              [29.76491737400005, -4.434352874999945],
              [29.764495850000174, -4.433392047999917],
              [29.763557433000074, -4.432642936999912],
              [29.76385116700004, -4.432045936999941],
              [29.764801026000043, -4.431354044999978],
              [29.76462936400003, -4.428874015999952],
              [29.76526260300011, -4.427567004999958],
              [29.766584397000088, -4.426701068999819],
              [29.766948700000114, -4.426051138999867],
              [29.76708221400014, -4.424241065999922],
              [29.767944336000028, -4.42324113899997],
              [29.76963043300003, -4.421665190999875],
              [29.772026062000066, -4.419760225999937],
              [29.772869110000045, -4.418570040999953],
              [29.773557662000144, -4.418454169999961],
              [29.77424812400011, -4.417339802999948],
              [29.773635863000152, -4.416742801999931],
              [29.774305344000027, -4.415473937999934],
              [29.774286271000165, -4.414590835999945],
              [29.774860383000032, -4.414090155999929],
              [29.77493858400004, -4.413321971999949],
              [29.775222778000114, -4.412743091999914],
              [29.776126862000126, -4.41260910099993],
              [29.776584626000158, -4.412936210999931],
              [29.77692985500005, -4.413627146999943],
              [29.777809143000127, -4.41383981599995],
              [29.779075623000097, -4.414206027999967],
              [29.77997589000006, -4.414723872999957],
              [29.780609130000073, -4.414877891999879],
              [29.781528473000037, -4.414722919999861],
              [29.78191185100013, -4.41449403699994],
              [29.781740188000015, -4.413703917999897],
              [29.78260040300006, -4.413916109999889],
              [29.78319549600019, -4.414416790999951],
              [29.78371238800014, -4.413917064999964],
              [29.783941268999968, -4.412416934999953],
              [29.78451728800013, -4.411801815999922],
              [29.785953523000046, -4.411321162999968],
              [29.78627967800014, -4.410647868999945],
              [29.786489488000086, -4.409802913999897],
              [29.787946701000124, -4.409418105999976],
              [29.78947830300018, -4.408320902999947],
              [29.78982353200007, -4.407571792999875],
              [29.790437698000062, -4.406879901999901],
              [29.79039764400011, -4.40595722199987],
              [29.79062843400004, -4.405264853999938],
              [29.791471481000087, -4.404840946999968],
              [29.791606903000115, -4.40430307399987],
              [29.792467118000104, -4.404324054999904],
              [29.793197632000158, -4.402937887999883],
              [29.793693543000074, -4.403379915999949],
              [29.7945194240001, -4.403244971999811],
              [29.794824601000073, -4.402418135999938],
              [29.79585838400004, -4.402245044999916],
              [29.796203613000102, -4.401072977999945],
              [29.796051025999986, -4.40017080299998],
              [29.796586991000027, -4.39970779399988],
              [29.796663285000136, -4.399149894999823],
              [29.79722023100004, -4.398554801999978],
              [29.79767990200014, -4.395671844999981],
              [29.797201157000018, -4.395094870999969],
              [29.798828126000103, -4.39499712099996],
              [29.799461365000184, -4.393576143999951],
              [29.80003547700005, -4.393133162999902],
              [29.799978257000134, -4.39184522599993],
              [29.801971436000144, -4.387653826999951],
              [29.80235481400007, -4.387096880999877],
              [29.80151176400011, -4.385173797999926],
              [29.802181243000177, -4.384828089999928],
              [29.802526474000047, -4.384251116999963],
              [29.80227851800015, -4.383578777999958],
              [29.802928925, -4.383077144999902],
              [29.803331375000084, -4.382366180999952],
              [29.804386139000144, -4.380115985999964],
              [29.805534363000106, -4.379327773999933],
              [29.80574607800014, -4.378426073999947],
              [29.806915282999967, -4.377695082999935],
              [29.806837081000083, -4.37615823799996],
              [29.80703163100003, -4.375388144999931],
              [29.807834625000055, -4.375041960999965],
              [29.80838966400006, -4.373561858999892],
              [29.80906104999997, -4.373445987999901],
              [29.809558869000057, -4.373081206999927],
              [29.809558869000057, -4.372369765999963],
              [29.80996132000007, -4.371811866999963],
              [29.810689927, -4.371293067999943],
              [29.81139755300012, -4.371253967999962],
              [29.812105178000138, -4.370197773999905],
              [29.812164306000057, -4.368543148999947],
              [29.812662124000042, -4.366947173999961],
              [29.812356950000037, -4.366236209999954],
              [29.81308364900019, -4.365736006999953],
              [29.814233780000052, -4.364756105999959],
              [29.814924240000153, -4.36523580599993],
              [29.815805434000083, -4.365140913999937],
              [29.815671920000057, -4.364466189999973],
              [29.816705704000128, -4.365562915999931],
              [29.81785583500016, -4.36633300799997],
              [29.818315507000193, -4.367004871999939],
              [29.81908035300006, -4.366696834999971],
              [29.82028961200018, -4.365561961999958],
              [29.821132659000057, -4.365601062999872],
              [29.82159042300009, -4.364985941999976],
              [29.821380615000123, -4.362659930999882],
              [29.822530747000087, -4.362967967999964],
              [29.82371711700017, -4.362352847999887],
              [29.82413864200015, -4.36160278299991],
              [29.825576782000155, -4.362256048999882],
              [29.82630348100014, -4.361122130999945],
              [29.826686859000063, -4.361929893999957],
              [29.82710838400004, -4.362487792999957],
              [29.82785606400006, -4.36289119599985],
              [29.82917785600017, -4.362332819999836],
              [29.828756333000058, -4.363468170999965],
              [29.828199387000154, -4.364775179999924],
              [29.82848930300014, -4.365352153999879],
              [29.82998275799997, -4.365352153999879],
              [29.83049965000015, -4.365179060999878],
              [29.830978393000066, -4.364735124999925],
              [29.83116912899999, -4.365353106999976],
              [29.830633164000176, -4.366044043999864],
              [29.830556870000066, -4.366505145999895],
              [29.830825805000188, -4.36729288],
              [29.83153343100014, -4.36727380699989],
              [29.832721709000054, -4.366640089999976],
              [29.833181381000088, -4.367659091999883],
              [29.833412171000134, -4.368389128999979],
              [29.834255219000113, -4.368581770999981],
              [29.83571243200015, -4.368505001999893],
              [29.837682724000103, -4.36869716599989],
              [29.83850860600006, -4.368543148999947],
              [29.838968277000163, -4.369792936999886],
              [29.83979225100012, -4.369792936999886],
              [29.841745377000052, -4.369023798999933],
              [29.843643189000034, -4.368927953999901],
              [29.845098496000105, -4.369081018999964],
              [29.845060348000175, -4.369870185999957],
              [29.84442710800016, -4.370234012999958],
              [29.843469620000178, -4.370273111999836],
              [29.843126297000083, -4.370695113999943],
              [29.843948365000074, -4.371368883999878],
              [29.845079421000037, -4.371581077999963],
              [29.84546470600003, -4.372829913999908],
              [29.846038818000125, -4.373714923999955],
              [29.846651077000047, -4.373888015999967],
              [29.846632004000185, -4.374656199999947],
              [29.847494126000072, -4.374945163999939],
              [29.84870147800018, -4.376714228999958],
              [29.848068238000167, -4.377155779999896],
              [29.847417831000087, -4.376657008999928],
              [29.846746444000075, -4.377099036999823],
              [29.84676361000004, -4.377703189999977],
              [29.846996308000143, -4.378500937999888],
              [29.848030091000112, -4.378847121999968],
              [29.849313737000102, -4.379156112999851],
              [29.85015678400015, -4.379002093999873],
              [29.850290298000175, -4.378308773999891],
              [29.85086631700011, -4.378868102999888],
              [29.850368499000183, -4.379500864999955],
              [29.850233077000155, -4.380250929999932],
              [29.850635529000044, -4.38129091299993],
              [29.85119247500012, -4.381886003999966],
              [29.85199546800004, -4.381980895999959],
              [29.853605270000173, -4.381077765999976],
              [29.85462188800011, -4.381673811999974],
              [29.85548400800019, -4.381501197999967],
              [29.856212615000175, -4.381116867999879],
              [29.856039047000024, -4.382269858999905],
              [29.856327058000147, -4.38253879499996],
              [29.85705566400003, -4.382520198999941],
              [29.85864448600006, -4.382116794999945],
              [29.860082626000178, -4.380674838999937],
              [29.861711503000038, -4.381597994999936],
              [29.862783433000118, -4.381289958999901],
              [29.863262176000035, -4.38142490499996],
              [29.86358833300011, -4.3819050799999],
              [29.864297866000072, -4.381925105999869],
              [29.865522384000144, -4.381116867999879],
              [29.866289139000116, -4.381289958999901],
              [29.866405488000055, -4.382154941999886],
              [29.866386414000033, -4.383596896999904],
              [29.866865159000156, -4.384136198999954],
              [29.867708205999975, -4.383886812999947],
              [29.86889457699999, -4.382905006999977],
              [29.86968040400012, -4.382828235999909],
              [29.87071609500009, -4.381577968999864],
              [29.8722877510001, -4.381655215999956],
              [29.872152329000073, -4.380578994999894],
              [29.871923446000096, -4.380194186999916],
              [29.871175766000192, -4.37936687399997],
              [29.872592925000106, -4.38007783899991],
              [29.873224259000153, -4.380558966999899],
              [29.87345314000015, -4.38134813399995],
              [29.87427902200011, -4.381503105999968],
              [29.875236511000026, -4.379405974999941],
              [29.87569618300006, -4.37794494699989],
              [29.875215530000162, -4.377291200999935],
              [29.874450684000124, -4.377309798999931],
              [29.873935700000118, -4.377694129999952],
              [29.873262406000038, -4.378099918999965],
              [29.873033523000174, -4.377655982999897],
              [29.873626709000064, -4.377636909999978],
              [29.874010086000055, -4.376886844999945],
              [29.87289810200008, -4.375561236999943],
              [29.872419357000183, -4.37534904499995],
              [29.87288093600006, -4.374580858999934],
              [29.872266770000067, -4.373003959999835],
              [29.872783661000085, -4.372581004999915],
              [29.873205184000085, -4.371657846999938],
              [29.873531342000035, -4.371234892999894],
              [29.873895646000165, -4.37090778399994],
              [29.873569488000044, -4.369870185999957],
              [29.873722076000035, -4.369504928999959],
              [29.873935700000118, -4.370504855999911],
              [29.87464332600007, -4.370563030999904],
              [29.875007628000162, -4.369756220999875],
              [29.875524520000113, -4.36948585499988],
              [29.876405716000193, -4.369678019999981],
              [29.87722968999998, -4.369582174999948],
              [29.877632141000163, -4.369102],
              [29.8775177010001, -4.368600843999957],
              [29.876962662000096, -4.367928026999948],
              [29.877918244000114, -4.3681402199999],
              [29.87926101700009, -4.368717193999885],
              [29.87996864300004, -4.368293761999951],
              [29.88048744200006, -4.367736815999933],
              [29.88084983900012, -4.36692714599991],
              [29.880947114000094, -4.368082046999973],
              [29.88162803600011, -4.368192671999907],
              [29.881654740000045, -4.369215964999853],
              [29.88188552800011, -4.369831083999941],
              [29.882556915000123, -4.370504855999911],
              [29.88341713000017, -4.370581148999918],
              [29.884683609000092, -4.369756220999875],
              [29.884260177000044, -4.368428230999939],
              [29.88403129599999, -4.368216990999883],
              [29.884319305000133, -4.367409227999929],
              [29.88479805000003, -4.366735933999905],
              [29.88550758300005, -4.366332054999873],
              [29.885545730000104, -4.365581989999896],
              [29.88493156300018, -4.365217207999933],
              [29.88464355500014, -4.364583015999926],
              [29.883935929000188, -4.36427402399994],
              [29.88441467400014, -4.363678930999924],
              [29.88577461300008, -4.363142012999902],
              [29.886003494000136, -4.362545012999874],
              [29.88535308800016, -4.360237121999887],
              [29.88474082900018, -4.359776019999913],
              [29.88563919100011, -4.359065054999974],
              [29.886980057000187, -4.358643054999902],
              [29.887767791000158, -4.358603],
              [29.88728904600009, -4.356316088999904],
              [29.887979508, -4.355968951999955],
              [29.8883972160001, -4.355968951999955],
              [29.88803672900002, -4.356566904999966],
              [29.888093949000165, -4.356814861999965],
              [29.888877868000122, -4.357256889999974],
              [29.89031410299998, -4.357315062999874],
              [29.891828537000094, -4.35673999799991],
              [29.892824173, -4.355834960999971],
              [29.89294052100007, -4.354834078999943],
              [29.892274857000075, -4.354434012999889],
              [29.89330291800013, -4.354104994999943],
              [29.893592834000117, -4.35358476499988],
              [29.893495560000076, -4.352892874999839],
              [29.894145965000178, -4.3536419859999],
              [29.894645691000107, -4.353950976999897],
              [29.895315170000174, -4.353623866999897],
              [29.895776749000106, -4.354162214999974],
              [29.896541595000144, -4.353931903999921],
              [29.896963120000123, -4.353720188999887],
              [29.896907807000048, -4.352797985999871],
              [29.897251128000107, -4.353528022999967],
              [29.897577286000114, -4.354451178999966],
              [29.898151397999982, -4.355179786999941],
              [29.8983058930001, -4.355912208999882],
              [29.898494721000077, -4.356334208999897],
              [29.900276185000052, -4.356585025999948],
              [29.901006699000106, -4.356372832999909],
              [29.902307510000185, -4.35460376799989],
              [29.902538301000163, -4.354047774999913],
              [29.902116776000184, -4.353586197999903],
              [29.90234756400008, -4.353413104999959],
              [29.902885437000123, -4.353394030999823],
              [29.903669357000126, -4.353009222999958],
              [29.904281615000173, -4.3519711499999],
              [29.904954909000082, -4.352373122999893],
              [29.906389236, -4.35179805699994],
              [29.90713691600007, -4.352162837999913],
              [29.908191681, -4.352241039999967],
              [29.90868949899999, -4.351297855999917],
              [29.90886306700014, -4.350567816999842],
              [29.909549714000036, -4.350162029999922],
              [29.909500122, -4.348934172999918],
              [29.909971237000036, -4.349215982999965],
              [29.910301209000124, -4.350396155999931],
              [29.910346984000114, -4.350962161999973],
              [29.910158158000115, -4.35171699499989],
              [29.910911561000034, -4.352896212999951],
              [29.91194343600006, -4.352991104999944],
              [29.91452980000014, -4.351810931999921],
              [29.91434097399997, -4.351150988999962],
              [29.9132614130001, -4.350962161999973],
              [29.91297912499999, -4.350678920999883],
              [29.91391754200015, -4.350444792999952],
              [29.91415214500006, -4.349688052999966],
              [29.91490554800015, -4.349595070999897],
              [29.915798187, -4.350066184999889],
              [29.916219711999986, -4.349782943999912],
              [29.916408538000155, -4.348840235999887],
              [29.916971207000074, -4.349404811999875],
              [29.917442322000113, -4.350161074999846],
              [29.9181957240001, -4.349876880999943],
              [29.918806076000124, -4.348792075999882],
              [29.919183731000032, -4.34926414499995],
              [29.920591354000067, -4.349311827999941],
              [29.923833846000093, -4.348038195999948],
              [29.92463302600015, -4.348178863999976],
              [29.92622947600006, -4.34737682399998],
              [29.927310944000055, -4.34700107599997],
              [29.928062439000144, -4.346811770999921],
              [29.929002761000106, -4.345678806999956],
              [29.928203583000084, -4.343935012999907],
              [29.92825126600019, -4.343369960999894],
              [29.92989540000019, -4.343463897999925],
              [29.930412292000142, -4.344313143999955],
              [29.931070328000033, -4.344313143999955],
              [29.932809829000178, -4.342567920999954],
              [29.933465958, -4.342945098999905],
              [29.933513642000037, -4.343556879999824],
              [29.934217453000088, -4.343556879999824],
              [29.935300826000116, -4.342945098999905],
              [29.936191558000132, -4.342660902999853],
              [29.93849372799997, -4.339454172999979],
              [29.939153671000156, -4.339219091999894],
              [29.941268921000187, -4.339220046999969],
              [29.94197273300017, -4.339313983999944],
              [29.942489623000142, -4.340115068999978],
              [29.94338226200017, -4.338747977999901],
              [29.94338226200017, -4.337663173999943],
              [29.94469833400018, -4.336767196999972],
              [29.94540214500006, -4.335731028999874],
              [29.947706222000193, -4.335116862999939],
              [29.948692321000124, -4.332946777999894],
              [29.94972801200015, -4.332004068999879],
              [29.94991493300006, -4.331060886999978],
              [29.95038414000004, -4.33077812199997],
              [29.951183320000098, -4.330872058999944],
              [29.951183320000098, -4.329411029999903],
              [29.952310563000026, -4.330729961999964],
              [29.953062058000114, -4.330400943999848],
              [29.953298569000083, -4.329741000999945],
              [29.954143524000074, -4.330118177999964],
              [29.954755783, -4.329927921999968],
              [29.954990386000134, -4.32926893299998],
              [29.95639991700017, -4.328702926999938],
              [29.95762062199998, -4.329315184999871],
              [29.958372117000124, -4.329080104999889],
              [29.958984376000046, -4.328184127999919],
              [29.959924697000133, -4.327712057999918],
              [29.961191177000103, -4.326203821999854],
              [29.96161460900015, -4.325542925999969],
              [29.961896897000088, -4.324365138999951],
              [29.96325874400003, -4.323468208999884],
              [29.964387894000026, -4.321629047999977],
              [29.965797425, -4.319458960999896],
              [29.966125488000102, -4.318705080999962],
              [29.965703963000124, -4.317337988999839],
              [29.964952468000035, -4.316818236999893],
              [29.965938569000173, -4.316535948999899],
              [29.966360092000116, -4.315167902999974],
              [29.965892792000034, -4.313990114999854],
              [29.96683120800003, -4.313659191999932],
              [29.96809959300009, -4.31233978299997],
              [29.96833419900014, -4.31139516799982],
              [29.967864990000123, -4.310498236999933],
              [29.96871185400005, -4.308707236999908],
              [29.96857071000005, -4.307858942999872],
              [29.969463349000137, -4.307339190999869],
              [29.96913528400006, -4.305924891999894],
              [29.96988677900015, -4.305500029999905],
              [29.969932556000117, -4.304321766999976],
              [29.969320298000127, -4.303520201999902],
              [29.970966340000075, -4.303614138999933],
              [29.971437454000124, -4.303188800999919],
              [29.971860886000172, -4.301822184999935],
              [29.972799301000123, -4.301066874999947],
              [29.976135254000155, -4.301397799999961],
              [29.977640152000163, -4.301631927999893],
              [29.97890853900003, -4.300594806999868],
              [29.979333877000045, -4.299838066999882],
              [29.979425431000152, -4.301115035999942],
              [29.98055267400008, -4.301163195999834],
              [29.982433320000098, -4.300406932999977],
              [29.98346519500012, -4.300171851999949],
              [29.984077454000044, -4.299510955999892],
              [29.984407425000086, -4.298377990999938],
              [29.984922409000035, -4.297670839999967],
              [29.98619270300003, -4.297436236999943],
              [29.98666191000018, -4.297482013999968],
              [29.98708534300016, -4.296775817999901],
              [29.988025664000077, -4.298096179999902],
              [29.98933029300008, -4.29875898399996],
              [29.989004135000073, -4.30038404499993],
              [29.98956680400005, -4.301529883999876],
              [29.99035644600019, -4.302320957999882],
              [29.99117088400004, -4.302633762999903],
              [29.99176597700017, -4.302501201999974],
              [29.992063523000127, -4.302156924999963],
              [29.992137909000064, -4.301412104999827],
              [29.991542816000106, -4.300845145999972],
              [29.992776872000036, -4.300219058999971],
              [29.99176597700017, -4.298892974999944],
              [29.991647721000163, -4.297565937999934],
              [29.992151260000185, -4.296285152999815],
              [29.99307251, -4.295464991999893],
              [29.99369621200009, -4.294808863999947],
              [29.994377137000015, -4.293438911999942],
              [29.99509239300005, -4.293914793999875],
              [29.995508195000184, -4.297194002999959],
              [29.995433807000097, -4.298698900999966],
              [29.99587822000018, -4.299489020999943],
              [29.996517182000105, -4.29998111599997],
              [29.997987746000092, -4.300085067999873],
              [29.998701095000058, -4.299801824999975],
              [29.998790741000164, -4.299116134999906],
              [29.999338148999982, -4.298624037999957],
              [29.999561310000104, -4.297536848999926],
              [29.999874115000125, -4.29726791399986],
              [30.00065422100016, -4.29721403099984],
              [30.001134873000183, -4.29600000399995],
              [30.001514434000057, -4.294139861999952],
              [30.00139045800006, -4.291152954999973],
              [30.001430512000184, -4.29068994499994],
              [30.001398086000165, -4.288169859999812],
              [30.001697540000066, -4.286109923999902],
              [30.00227165200016, -4.285153865999916],
              [30.002704620000088, -4.284690856999816],
              [30.00401687600015, -4.283877848999964],
              [30.00431442200005, -4.282954215999837],
              [30.004341126000156, -4.2822480199999],
              [30.003705979000074, -4.280958174999967],
              [30.005872726000064, -4.280660152999928],
              [30.007089615000098, -4.281705856999906],
              [30.008483888000058, -4.282736777999958],
              [30.009212494000167, -4.283198831999926],
              [30.01089096100003, -4.283212184999968],
              [30.012784957000065, -4.284842013999878],
              [30.013406754000073, -4.285194872999966],
              [30.01397705, -4.284896850999871],
              [30.016288758000144, -4.282804010999939],
              [30.016519547000144, -4.281814096999824],
              [30.016330718000063, -4.280537127999935],
              [30.015626908000115, -4.278649805999919],
              [30.015626908000115, -4.278309822999972],
              [30.01576232900004, -4.277794838999967],
              [30.017236710000077, -4.277141092999955],
              [30.01788520800011, -4.277060984999935],
              [30.01857566800004, -4.277372836999973],
              [30.01925277700019, -4.277101993999963],
              [30.019550324000193, -4.276436804999946],
              [30.020212173000175, -4.276055811999868],
              [30.020917893000103, -4.275891779999881],
              [30.023920059000147, -4.276951788999952],
              [30.024284363000106, -4.27744197699991],
              [30.0239601130001, -4.278419017999909],
              [30.02397537100012, -4.279519081999979],
              [30.02424621600005, -4.280537127999935],
              [30.024948119000157, -4.281270980999921],
              [30.02565384000019, -4.280917167999974],
              [30.026086808000116, -4.280224799999928],
              [30.026330947000133, -4.278881071999876],
              [30.02711486800007, -4.278704165999898],
              [30.027559279000172, -4.278172968999911],
              [30.02808952400011, -4.278566836999971],
              [30.029008865000037, -4.278471945999968],
              [30.029346466000163, -4.277903078999941],
              [30.029901504000065, -4.277712821999955],
              [30.03063392600012, -4.277727125999888],
              [30.031131744000106, -4.277074812999899],
              [30.03142929100011, -4.275580882999918],
              [30.03084945600017, -4.273599146999914],
              [30.031051635000154, -4.273055076999924],
              [30.03132247800005, -4.273734092999973],
              [30.03228187600007, -4.274020194999935],
              [30.033514023000123, -4.274209975999952],
              [30.03367424100003, -4.274834154999951],
              [30.034366608000028, -4.275539874999936],
              [30.03570556600016, -4.275920867999901],
              [30.036125182999967, -4.276206016999879],
              [30.036016465000046, -4.277033805999963],
              [30.036489487000097, -4.27763080599982],
              [30.037248611000052, -4.277671812999927],
              [30.038019180000106, -4.277278899999942],
              [30.038696289000086, -4.276803016999963],
              [30.039129257000013, -4.277604101999941],
              [30.03988647400007, -4.277413845999888],
              [30.040115357000104, -4.276857851999978],
              [30.040252685999974, -4.276233195999907],
              [30.04088783400016, -4.276912211999957],
              [30.04153442400019, -4.27715444599994],
              [30.042213441000115, -4.277047155999924],
              [30.04381179800015, -4.275174140999923],
              [30.04285049300006, -4.273883819999924],
              [30.043661118000102, -4.273760794999873],
              [30.043865203, -4.273150920999967],
              [30.043228149000072, -4.272226809999893],
              [30.042377471, -4.271752832999937],
              [30.043174743000066, -4.27186012299984],
              [30.04374313300002, -4.271520136999925],
              [30.04351234400002, -4.269999980999955],
              [30.043931961000055, -4.269293784999945],
              [30.043945312000176, -4.268710135999868],
              [30.043567657000096, -4.267977236999911],
              [30.043405532000065, -4.267324922999933],
              [30.043539048000127, -4.266943930999958],
              [30.04428482000003, -4.266686915999969],
              [30.044622421000156, -4.266020774999959],
              [30.04476928800011, -4.265302180999925],
              [30.044460296000125, -4.264636991999851],
              [30.04501533500013, -4.26477193799991],
              [30.045906067000146, -4.264188765999961],
              [30.04605674800007, -4.263768195999887],
              [30.046085357000038, -4.263196944999891],
              [30.04677391000007, -4.262709139999856],
              [30.046867371000076, -4.261894225999924],
              [30.04676055900012, -4.261146068999949],
              [30.047315597000022, -4.259965895999869],
              [30.046976089, -4.258916853999949],
              [30.04616546599999, -4.258318900999939],
              [30.04557037300009, -4.258169173999931],
              [30.045230865000065, -4.257599829999947],
              [30.045150756000055, -4.256838798999979],
            ],
            [
              [29.236843, -3.27228799999989],
              [29.236874000000114, -3.271943],
              [29.237411000000122, -3.271076999999877],
              [29.238256, -3.270661999999845],
              [29.239225000000147, -3.270482],
              [29.239800000000116, -3.270193],
              [29.240266926000174, -3.26925394899996],
              [29.241024018000132, -3.268977640999935],
              [29.24175834700003, -3.269258974999843],
              [29.241436005000025, -3.270775079999964],
              [29.240734099, -3.271255254999971],
              [29.239768982000044, -3.271790027999941],
              [29.239138, -3.272288999999887],
              [29.238920212000153, -3.272741794999888],
              [29.239002228000174, -3.273357868999881],
              [29.239200922000123, -3.274028734999945],
              [29.23956108200008, -3.27461767099993],
              [29.24034500100015, -3.275615000999949],
              [29.240725, -3.27623],
              [29.241005000000143, -3.277282],
              [29.241004758000145, -3.277591327999971],
              [29.24052200000017, -3.278247],
              [29.239335888000085, -3.279367060999959],
              [29.238369373000126, -3.279892495999945],
              [29.237028, -3.280254999999897],
              [29.234890001, -3.280681000999948],
              [29.23364623700013, -3.281360869999901],
              [29.23305100000016, -3.282366999999852],
              [29.23257292700015, -3.283073053999829],
              [29.232207912000035, -3.283338143999913],
              [29.23155400000013, -3.283681],
              [29.229633331000116, -3.284441947999937],
              [29.229300000000137, -3.284768],
              [29.22896599200004, -3.285221022999906],
              [29.228777, -3.285756],
              [29.22871400000014, -3.28717],
              [29.228642, -3.287948999999856],
              [29.228659, -3.289580999999885],
              [29.228955948000078, -3.290688801999977],
              [29.226448001000165, -3.290585],
              [29.226828, -3.289916999999889],
              [29.226934000000142, -3.289141],
              [29.226685000000145, -3.287679],
              [29.22699000000017, -3.287048],
              [29.226883, -3.286308],
              [29.227207000000192, -3.285531999999876],
              [29.227549, -3.285026],
              [29.227711000000113, -3.284214],
              [29.22842800000012, -3.283834999999897],
              [29.229164, -3.283168],
              [29.23011600000018, -3.282861],
              [29.230995, -3.282392],
              [29.231571, -3.282013],
              [29.231966000000114, -3.280948],
              [29.232487, -3.280081999999879],
              [29.233189, -3.279847],
              [29.234032000000184, -3.280172],
              [29.2347850000001, -3.279901],
              [29.235844000000156, -3.279828999999893],
              [29.23765800000018, -3.278457999999887],
              [29.238682000000153, -3.277303],
              [29.239077000000123, -3.276544999999885],
              [29.239042, -3.275679],
              [29.23862800000012, -3.275011],
              [29.237787, -3.274036999999851],
              [29.236944, -3.273134],
              [29.236818, -3.272575],
              [29.236843, -3.27228799999989],
            ],
            [
              [29.21553400000016, -3.319292],
              [29.215246, -3.31848],
              [29.215139000000136, -3.317758],
              [29.214530989000025, -3.317054986999892],
              [29.225007986000094, -3.317397942999946],
              [29.224413, -3.318980000999829],
              [29.22463800100013, -3.31995],
              [29.22520599900008, -3.321572000999879],
              [29.2251968920001, -3.323085182999932],
              [29.22423719200009, -3.324541865999947],
              [29.21702000800019, -3.324146999999869],
              [29.216966628000023, -3.323334930999977],
              [29.216248999000072, -3.322721000999934],
              [29.216036, -3.321529995999981],
              [29.216211385000122, -3.320243921999975],
              [29.21553400000016, -3.319292],
            ],
          ],
        },
        properties: { GID_0: "BDI", COUNTRY: "Burundi" },
      },
    ],
  },
  100: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [0.624043824000125, 7.901198417000046],
                [0.620107236000024, 7.900340293000113],
                [0.619757665000122, 7.900466419000054],
                [0.623863586000141, 7.883866192000141],
                [0.622002559000066, 7.877983500000141],
                [0.623294479000037, 7.871409980000124],
                [0.627422642999989, 7.868203572000084],
                [0.627012593000188, 7.859572261000096],
                [0.626224007000133, 7.850379392000036],
                [0.624022255000057, 7.842287220000173],
                [0.620920974000057, 7.841766925000172],
                [0.619775104000098, 7.837454864000165],
                [0.621838708000098, 7.83575787400008],
                [0.619564432000061, 7.831637822000062],
                [0.61935016100017, 7.824882427000091],
                [0.619906762000142, 7.822815890000072],
                [0.61818703900002, 7.816066262000106],
                [0.617398201000128, 7.80668563],
                [0.617776206999963, 7.803080862000172],
                [0.620640466000111, 7.800641195000026],
                [0.621415442000114, 7.799521021999965],
                [0.621022625000023, 7.798696767000081],
                [0.619899335000127, 7.797899591000032],
                [0.617779284000164, 7.797543389000055],
                [0.617412693000119, 7.797204770000178],
                [0.617092291000063, 7.796210222000127],
                [0.617454590000079, 7.795431650000012],
                [0.618086068000139, 7.794992070999967],
                [0.621542016999967, 7.794444541000132],
                [0.623634891000165, 7.7940722190001],
                [0.62677489500004, 7.7936958680001],
                [0.628215885000088, 7.794783248000044],
                [0.628874609000093, 7.795096446000173],
                [0.62945661599997, 7.794462747000068],
                [0.62974630400015, 7.793805884000165],
                [0.629692999000099, 7.792616028000111],
                [0.629544259000056, 7.791936564000025],
                [0.628981162000116, 7.791161545000136],
                [0.627929720000111, 7.790048383000055],
                [0.627514803000167, 7.789807107000115],
                [0.627098574000115, 7.789225816000112],
                [0.626877066000077, 7.788619491000134],
                [0.626971568000101, 7.787866231000123],
                [0.628622040000039, 7.786451249000095],
                [0.629734622000058, 7.785055993000128],
                [0.629831735000039, 7.784406495000042],
                [0.629893345000141, 7.781443246000094],
                [0.630454951000104, 7.77988945900006],
                [0.630635757000107, 7.778210226000113],
                [0.630509969, 7.777166368000053],
                [0.630139549000091, 7.775832007000076],
                [0.630111262000185, 7.774812062000137],
                [0.630979829000182, 7.772720047000178],
                [0.630801690000112, 7.770729198000026],
                [0.630988436000109, 7.76863980000013],
                [0.630911450000099, 7.767620042000146],
                [0.630495058000122, 7.766990176999968],
                [0.629640174000087, 7.766313420000017],
                [0.62751692500018, 7.765082914],
                [0.625785811000128, 7.764506647000019],
                [0.621497917000056, 7.763940129000048],
                [0.620767500000113, 7.763967201000185],
                [0.619113071000129, 7.764337810000029],
                [0.618940480000106, 7.763779863000138],
                [0.618909439000106, 7.762031307000029],
                [0.620066213000086, 7.758479047000094],
                [0.619880478000084, 7.757452198000124],
                [0.619151187000057, 7.756910973000061],
                [0.617578337000111, 7.757642287000124],
                [0.614969266000173, 7.756805971000063],
                [0.613089946000116, 7.75663176300003],
                [0.611448660000178, 7.75530819700009],
                [0.611439232000123, 7.752830000000131],
                [0.613811066000096, 7.748389624000083],
                [0.61584185900017, 7.747252415000048],
                [0.616879506000146, 7.744871826000178],
                [0.61648956300013, 7.742293503000099],
                [0.616415841000048, 7.738848440000083],
                [0.617134500000077, 7.736609267000176],
                [0.618098770000131, 7.73521538600005],
                [0.619266566000078, 7.732056566000154],
                [0.618880149000177, 7.73071626400008],
                [0.618940559000066, 7.729891957],
                [0.619202616000166, 7.729172537000181],
                [0.619322430000125, 7.727259800000127],
                [0.619586832000152, 7.72642087800017],
                [0.620491662000063, 7.725564952000013],
                [0.620987213000092, 7.724971427000014],
                [0.621184989000142, 7.724062079000134],
                [0.621962159000134, 7.722326457],
                [0.62230280700004, 7.720369232],
                [0.621993253000028, 7.718657545000042],
                [0.623196317000065, 7.716285204000144],
                [0.623490887000116, 7.714067444000136],
                [0.624291446000029, 7.712099655000145],
                [0.62499349300009, 7.710787157000084],
                [0.626454038000077, 7.70972366899997],
                [0.628168629000072, 7.709062238000058],
                [0.628921546000129, 7.707850299000086],
                [0.630080873000054, 7.706226057000151],
                [0.628405868000073, 7.705613791000133],
                [0.628079582000112, 7.70480763300003],
                [0.627046344000064, 7.704062026999964],
                [0.626474254000129, 7.703992553000091],
                [0.625721021000061, 7.704232396000123],
                [0.624536746000047, 7.704446315000155],
                [0.623851406000028, 7.704380022000066],
                [0.621290500000043, 7.703623653000136],
                [0.620283445000155, 7.703222384000071],
                [0.619587213000102, 7.703197460000126],
                [0.618751480000128, 7.703536806000102],
                [0.617541729000095, 7.704318464000153],
                [0.617202127999974, 7.704730336000125],
                [0.616515397000114, 7.70611901400008],
                [0.614930006000122, 7.706918617999975],
                [0.614400985000145, 7.707281602000023],
                [0.613044294000076, 7.707928779000156],
                [0.612194617000171, 7.708237855000164],
                [0.611402876000113, 7.709257668000078],
                [0.610145014000182, 7.710474884000064],
                [0.609130060000041, 7.710911337000027],
                [0.608793038000044, 7.710912604000043],
                [0.606697678000046, 7.709815455000125],
                [0.603936329000078, 7.710123419000013],
                [0.603126976000169, 7.710140228000114],
                [0.600627905000067, 7.708937088000027],
                [0.599274448000074, 7.70898347900004],
                [0.597918532000165, 7.708374028000151],
                [0.597038145000056, 7.707864758000085],
                [0.596757876000083, 7.707529612000087],
                [0.595932845000107, 7.707047690000024],
                [0.595053288000088, 7.706758864999983],
                [0.594182227000033, 7.70504808100003],
                [0.593343112000127, 7.704491802000121],
                [0.592463356000167, 7.704147858000056],
                [0.59132987300012, 7.703176561000078],
                [0.590702119000184, 7.702997017000143],
                [0.589715467000076, 7.692496042000187],
                [0.589440724000042, 7.691414079000083],
                [0.589456465000183, 7.689705500000059],
                [0.588955714, 7.68834309500005],
                [0.588951480000048, 7.687204432000044],
                [0.587655182000049, 7.677860072000158],
                [0.586488007000071, 7.670912439000063],
                [0.585813890000111, 7.667319089999978],
                [0.584181070000056, 7.664388501000076],
                [0.584056052000165, 7.663070487000084],
                [0.584285713000099, 7.660192972000118],
                [0.583499468000127, 7.658757527000091],
                [0.582831826000131, 7.656902129000059],
                [0.583303773000182, 7.654563098999972],
                [0.583353896000176, 7.651866034000022],
                [0.583696641000188, 7.647070322000047],
                [0.584669444000099, 7.64161705600003],
                [0.584328692000156, 7.639277015000175],
                [0.584985695000114, 7.638255784000137],
                [0.584617777, 7.63621949000003],
                [0.584972669000081, 7.634719922000102],
                [0.584779004000154, 7.631064860000038],
                [0.586155702000156, 7.62974133900002],
                [0.586932163000029, 7.62853987900013],
                [0.586623437000071, 7.626263646000155],
                [0.585954097000126, 7.623928803000126],
                [0.583426231000033, 7.622559649000039],
                [0.567904159000022, 7.61578398099999],
                [0.562128696000116, 7.613287721000063],
                [0.560204204, 7.612635407000084],
                [0.558521061000135, 7.61228187200004],
                [0.557251678000171, 7.610068968000121],
                [0.555747663000147, 7.609415119000119],
                [0.554182723000167, 7.608521755000083],
                [0.553517044000046, 7.60714571300008],
                [0.545830391000095, 7.607652625000128],
                [0.543599565000079, 7.608074196000075],
                [0.541824319000114, 7.606716414000175],
                [0.540210795000178, 7.605785190000063],
                [0.536554016000025, 7.604622825000092],
                [0.5361392370001, 7.604096567000056],
                [0.53593002000008, 7.601958841000112],
                [0.535400035, 7.601266265000163],
                [0.534169382000073, 7.600813159000154],
                [0.5333633730001, 7.60016564700004],
                [0.53136063200003, 7.597722843000099],
                [0.530704172000071, 7.597187783000038],
                [0.529639632999988, 7.596844306000151],
                [0.528209745000026, 7.596278895000182],
                [0.527172030000031, 7.595709345000103],
                [0.526554514999987, 7.595251302000122],
                [0.525057863000143, 7.594548324000073],
                [0.523625874000061, 7.594167540000171],
                [0.522890150000137, 7.593864228000143],
                [0.522177131000035, 7.593734448000021],
                [0.520489456000064, 7.593685241000117],
                [0.519776186000058, 7.593483808000087],
                [0.519291007000163, 7.592956395000044],
                [0.518371633000072, 7.592262397000184],
                [0.519847718000108, 7.590259299000024],
                [0.520208577, 7.589990728000089],
                [0.520686303000105, 7.589969767000014],
                [0.522013522000066, 7.590406046000169],
                [0.522935725000082, 7.590333923000117],
                [0.524214808000124, 7.589648765000106],
                [0.52725458000009, 7.589362521000055],
                [0.528518421000115, 7.587037314000156],
                [0.529049046000068, 7.584218628000144],
                [0.528920038000081, 7.581701923000026],
                [0.528361305000089, 7.576549699000054],
                [0.527558345000102, 7.570259611999973],
                [0.526316270999985, 7.558637062000059],
                [0.521357573000046, 7.520142851000117],
                [0.519026965000137, 7.513392359000079],
                [0.518951318000177, 7.512464040999987],
                [0.519819856000083, 7.511912513000027],
                [0.520914796000113, 7.511533936000035],
                [0.521118273000184, 7.510491545000036],
                [0.521500360000061, 7.509991425000067],
                [0.521407953000164, 7.509622470000181],
                [0.520577925000055, 7.509154104000118],
                [0.52023143200006, 7.50877224900006],
                [0.520268424999983, 7.508292614000084],
                [0.520650051000132, 7.507660218000183],
                [0.521522191000031, 7.506739523000135],
                [0.521742195000115, 7.505686051000168],
                [0.521519467000132, 7.50436680100006],
                [0.521774483000115, 7.50385334300006],
                [0.522226517000036, 7.503617535999979],
                [0.523674894000067, 7.503243245000022],
                [0.52408425100009, 7.502746301000116],
                [0.523680829000114, 7.50177439099997],
                [0.523218369000176, 7.501392939000027],
                [0.523405771000057, 7.500493904000166],
                [0.523394152000094, 7.499534932000074],
                [0.52405236200002, 7.499036612000054],
                [0.524452175000135, 7.498078970000051],
                [0.524316311000064, 7.497123183999975],
                [0.524063708000085, 7.496741005000104],
                [0.524061839000183, 7.496203634000096],
                [0.523659586000178, 7.495664893000026],
                [0.523599293000075, 7.495000958000105],
                [0.523134782000113, 7.494027018000168],
                [0.523210297000048, 7.493508669000164],
                [0.524087457000178, 7.493246590000069],
                [0.523862086000065, 7.492754084000183],
                [0.524394488000098, 7.492606185000113],
                [0.52464455900008, 7.492260846000022],
                [0.525209616000041, 7.491975043000082],
                [0.525564494000093, 7.492389937000155],
                [0.526100769000095, 7.492561693000141],
                [0.526311510000085, 7.492023585000027],
                [0.526788533000172, 7.491831783000123],
                [0.526987576000067, 7.491106323000167],
                [0.527671574000124, 7.491674396000178],
                [0.528385787000161, 7.491404608000039],
                [0.528491677000034, 7.490888910000024],
                [0.527588532000038, 7.490029485000036],
                [0.527319364000164, 7.48964736400012],
                [0.525935608000054, 7.488938413000028],
                [0.525953787000049, 7.48840924000001],
                [0.526220287000058, 7.488025263000111],
                [0.525922486000184, 7.487348371999985],
                [0.526544244000149, 7.487489519000064],
                [0.526790945000187, 7.486970578000182],
                [0.527843642000164, 7.486374436000062],
                [0.528440578000186, 7.486523934000161],
                [0.529379300000073, 7.486501386999976],
                [0.529455018000078, 7.486043664000022],
                [0.528628170000047, 7.485682771000143],
                [0.526965259000065, 7.484692320000136],
                [0.526596442000027, 7.484237516000178],
                [0.526067297, 7.482935864000126],
                [0.526653174000103, 7.482288982000057],
                [0.526868286000138, 7.481420166000135],
                [0.526885286000095, 7.480750452000109],
                [0.52726801700004, 7.480443236000099],
                [0.527844618000074, 7.481099870000094],
                [0.528851332000045, 7.48157588600003],
                [0.529591013000072, 7.481487891000143],
                [0.529856173000155, 7.48072086500008],
                [0.529471945000182, 7.479804524000031],
                [0.527834480000138, 7.478184289000183],
                [0.526971084000081, 7.477630611],
                [0.525536001000091, 7.47703757000005],
                [0.525278146000176, 7.475933392000172],
                [0.524950808000085, 7.475493598000014],
                [0.525023502000067, 7.474958725000079],
                [0.52435096500011, 7.47450083200016],
                [0.522663947000126, 7.474506654999971],
                [0.52239506300009, 7.474201691000189],
                [0.521475233000047, 7.474091875000113],
                [0.520962731000054, 7.473202143000037],
                [0.521584326999971, 7.472903754000129],
                [0.521275332000073, 7.472177290000104],
                [0.520727964000116, 7.471983515000147],
                [0.520717871000045, 7.471459949000177],
                [0.52030320200015, 7.471313942000052],
                [0.51962050100002, 7.470309051000129],
                [0.519696221000117, 7.469848573000036],
                [0.520403631000022, 7.469215062000103],
                [0.520630416000188, 7.468525333000173],
                [0.520897314000081, 7.468257102999985],
                [0.522950417000061, 7.468726781000157],
                [0.523295089000158, 7.468593314000032],
                [0.523213330000033, 7.468105821000108],
                [0.522639573999982, 7.467465700000162],
                [0.522616804000052, 7.466470940000079],
                [0.521992335000164, 7.465935713000135],
                [0.521483038000042, 7.465171356000042],
                [0.522016237, 7.464464038000131],
                [0.522518618000106, 7.464426483000125],
                [0.523011896000071, 7.464151960000095],
                [0.52469801500007, 7.463898126],
                [0.524984099999983, 7.463591246000021],
                [0.524253077000026, 7.462981983000191],
                [0.522874357000148, 7.463502068000139],
                [0.52241458200001, 7.463084772000059],
                [0.521475772000144, 7.463068714000144],
                [0.521012694000035, 7.462494348000064],
                [0.52029667100004, 7.462229499000102],
                [0.519993569000178, 7.462406911000187],
                [0.519592482000121, 7.462193339000123],
                [0.51987778099999, 7.461657734000085],
                [0.519089964000102, 7.461390374000189],
                [0.519338449000145, 7.460587585000098],
                [0.519701579000184, 7.460203281000133],
                [0.519719052000141, 7.459665842000163],
                [0.521001855000122, 7.459355539000114],
                [0.521809120000057, 7.459658654000066],
                [0.522154052000133, 7.459602351000115],
                [0.522401083999966, 7.459179864000078],
                [0.522073764000083, 7.458740064000097],
                [0.522494938000136, 7.458374850999974],
                [0.521856544000059, 7.457401498000024],
                [0.521185894000155, 7.457480967000095],
                [0.521465563000106, 7.456915068000114],
                [0.520710638000082, 7.4565759460001],
                [0.520446859, 7.455345013000112],
                [0.521185454000147, 7.455353498000022],
                [0.521011343, 7.454904902000067],
                [0.521350056000074, 7.454647449000163],
                [0.522192281, 7.45468313300006],
                [0.522577044000172, 7.454968412000142],
                [0.522694860000115, 7.455505385000095],
                [0.523041694000028, 7.456000234000101],
                [0.524021589000029, 7.456724388000112],
                [0.525369467000189, 7.45647309900005],
                [0.525059603000102, 7.457486919000075],
                [0.525253862000113, 7.457772851000129],
                [0.526174458000071, 7.458114149000096],
                [0.526969216000111, 7.457995663000077],
                [0.527326589000097, 7.458343036000144],
                [0.528142379000087, 7.457919961000187],
                [0.528599172000156, 7.45827387900016],
                [0.528924737000182, 7.458206614],
                [0.529221372000109, 7.45775915300004],
                [0.530790442000125, 7.457996234],
                [0.530830152000021, 7.457505567],
                [0.531559304000098, 7.457577449000098],
                [0.532134956000164, 7.457175867999979],
                [0.53220630200002, 7.456255191000139],
                [0.53191737100002, 7.455740861000038],
                [0.531226206000099, 7.455475943000124],
                [0.530995516000189, 7.455035817000123],
                [0.5313774870001, 7.454516408000075],
                [0.532029538000131, 7.454649184],
                [0.532640542000081, 7.454093158000035],
                [0.533178982000038, 7.454107827000087],
                [0.533537013000057, 7.453056635000166],
                [0.533422741000038, 7.452748384000131],
                [0.532575503000089, 7.452062374000036],
                [0.532020758000101, 7.452122166000038],
                [0.531661129000099, 7.45191948300004],
                [0.532972611000105, 7.450338639000165],
                [0.533567472000186, 7.450700341000072],
                [0.533834314000046, 7.44962741900008],
                [0.532739539000147, 7.449212332000116],
                [0.532261237, 7.448227418000158],
                [0.53194809300004, 7.448688717000039],
                [0.531077291000145, 7.449165723000078],
                [0.53084248000016, 7.449127954000062],
                [0.530558678000091, 7.448497861000078],
                [0.530980771000145, 7.448402705000035],
                [0.531212904000085, 7.447668865000026],
                [0.530587651000133, 7.447298997000189],
                [0.530881547000092, 7.446859811000081],
                [0.531970529000148, 7.447994184000038],
                [0.532384501000081, 7.447945903000061],
                [0.532545968000022, 7.447534733000168],
                [0.534036162000064, 7.447331158000111],
                [0.534245396000131, 7.447159576000104],
                [0.534148259000176, 7.446220193000102],
                [0.533544260000042, 7.44561050100009],
                [0.532953340000176, 7.444791324000107],
                [0.532780366000054, 7.444273836000093],
                [0.532067895000182, 7.443433032000144],
                [0.533196029000123, 7.443123238999988],
                [0.533711617000108, 7.442126619000135],
                [0.534544420000032, 7.441842647000101],
                [0.534953239000174, 7.441108195000084],
                [0.534513670000138, 7.440938859000084],
                [0.534779146000062, 7.440268286000105],
                [0.534306857000161, 7.439819352000086],
                [0.53420030500007, 7.438950273000046],
                [0.53504128899999, 7.438638715000025],
                [0.535526599000036, 7.438067967000052],
                [0.536337699000171, 7.437500224000075],
                [0.536415459000182, 7.436837190000119],
                [0.536775431000137, 7.436348172000123],
                [0.537062087000038, 7.435418483000035],
                [0.537423917000126, 7.434670414000038],
                [0.537420985000097, 7.433827157000167],
                [0.537248023000188, 7.433312427000033],
                [0.537258340000051, 7.432309290000092],
                [0.537903820000167, 7.431356311],
                [0.53804588600002, 7.430925918000014],
                [0.537884787999985, 7.430249933000141],
                [0.536890750000168, 7.429811075000089],
                [0.537326525000083, 7.428891893000014],
                [0.536867250000171, 7.428609638000069],
                [0.536461766000059, 7.427916585000162],
                [0.536460310999985, 7.427497712000047],
                [0.53585567000016, 7.427490159000058],
                [0.535652972000094, 7.427156033000131],
                [0.53588053500016, 7.426302333000024],
                [0.536782493000032, 7.426463182000077],
                [0.538290924000023, 7.425173770000129],
                [0.53790695400005, 7.424315298000181],
                [0.537905489000025, 7.42389366899999],
                [0.538247987000148, 7.423145669000121],
                [0.53843542300018, 7.422265928000059],
                [0.538884426000095, 7.421977773000151],
                [0.539393732000178, 7.422359061000066],
                [0.539854841000022, 7.422376754000027],
                [0.540506747000165, 7.421688307000181],
                [0.541348882000079, 7.421718457000168],
                [0.541697379000084, 7.422695546000114],
                [0.542443371000161, 7.422863815000085],
                [0.542998436000175, 7.422115075000136],
                [0.543590396000127, 7.421655562000012],
                [0.543894502000057, 7.420984854000153],
                [0.543721531000017, 7.420467370000097],
                [0.543224603000112, 7.42046909600009],
                [0.543123422000178, 7.419954119000011],
                [0.543446843000027, 7.419280589000096],
                [0.54375524500017, 7.419050789000039],
                [0.543752575000099, 7.418284695000068],
                [0.544631903000038, 7.417898589000117],
                [0.545181199000183, 7.41629006900007],
                [0.545312005000028, 7.415008184000158],
                [0.546764767000127, 7.41360596100003],
                [0.547222364000049, 7.413411467000117],
                [0.548239572, 7.413380371000017],
                [0.548294462000115, 7.412892409000165],
                [0.548831520000135, 7.412526780000178],
                [0.548810588000038, 7.412066641000024],
                [0.549116288000107, 7.411856138000132],
                [0.549652124000033, 7.41193143400011],
                [0.550034179000079, 7.41145060100007],
                [0.549936570999989, 7.410378950000109],
                [0.55022133600005, 7.409708308000063],
                [0.55062365800012, 7.40949747000019],
                [0.550870641000188, 7.409074978000035],
                [0.550713300000041, 7.40829013400014],
                [0.551919447000103, 7.407426135000151],
                [0.552474739000047, 7.406751794],
                [0.552558419000036, 7.406208618000051],
                [0.552853033000076, 7.405987129999971],
                [0.553090390000079, 7.405181621000054],
                [0.553405369000131, 7.405257684000048],
                [0.5548596860001, 7.404315652000094],
                [0.556008903000077, 7.403754977000119],
                [0.556255077, 7.403103759999965],
                [0.557079216000091, 7.40273712000004],
                [0.558133093000151, 7.40254328900005],
                [0.558349244, 7.401991382000119],
                [0.559183270000119, 7.401296772000023],
                [0.559566516000189, 7.401160399000048],
                [0.559909835000099, 7.400660406000156],
                [0.560540262000131, 7.40016216500004],
                [0.560931435000043, 7.399139790000106],
                [0.561702458000184, 7.398587318000068],
                [0.562353686000051, 7.398510631000022],
                [0.563365260000069, 7.398851552000053],
                [0.563909215000081, 7.398885469000106],
                [0.564351855000098, 7.399153978000015],
                [0.565098380000109, 7.399093488000176],
                [0.565959098000121, 7.399319191000132],
                [0.566505434000021, 7.399245621999967],
                [0.567447190000166, 7.398575423000011],
                [0.568432071000188, 7.398392835000038],
                [0.570832757000062, 7.396854570000016],
                [0.573126201000093, 7.394740993000028],
                [0.584063565000179, 7.396999170000072],
                [0.598647492000111, 7.400201020000168],
                [0.601167733000182, 7.407848204000175],
                [0.602821367000047, 7.407817299000158],
                [0.60453957600015, 7.406539249000105],
                [0.606145435999963, 7.405724816000145],
                [0.607863775000169, 7.407619326000031],
                [0.610274581000112, 7.410187626000095],
                [0.615174716000126, 7.410477272000094],
                [0.617005443000153, 7.412021064999976],
                [0.619591833000129, 7.41333012500013],
                [0.625339890000078, 7.411205880000125],
                [0.627810771000156, 7.405263347000073],
                [0.62934047300007, 7.404109335000157],
                [0.631253644000026, 7.402953914000023],
                [0.634132538000188, 7.403708975000086],
                [0.641411513000151, 7.401768192000134],
                [0.641790740000033, 7.400618383000165],
                [0.644656864000183, 7.39792818900014],
                [0.644812481000031, 7.388166121000097],
                [0.645576571000163, 7.387397698000029],
                [0.645186034000119, 7.38548511900018],
                [0.646513981000112, 7.381652189000079],
                [0.647388810000109, 7.379669914000147],
                [0.648153648000118, 7.378963910000152],
                [0.64572938800012, 7.376870033000046],
                [0.643229892000079, 7.37496520600007],
                [0.644925034000153, 7.369835366000189],
                [0.645163561000118, 7.366372421000108],
                [0.645407824000131, 7.364464878000092],
                [0.647158009000123, 7.360496602000183],
                [0.648249396000097, 7.358182574000068],
                [0.649290174000043, 7.35421493500013],
                [0.650565561000121, 7.345730702000083],
                [0.653629753000189, 7.331720672000131],
                [0.655812427000114, 7.323985735000065],
                [0.65890108200017, 7.316648866000094],
                [0.658047444000033, 7.312071805000187],
                [0.657467176000182, 7.30937881],
                [0.656401089000155, 7.306623423000019],
                [0.652300578000052, 7.298258948000125],
                [0.650189407000084, 7.291232142000126],
                [0.646812159000092, 7.291124034000063],
                [0.64632785200007, 7.290603977999979],
                [0.64571327900012, 7.287435152000171],
                [0.643233028000168, 7.279737270000055],
                [0.643023708000044, 7.277450044000034],
                [0.643089070000144, 7.273315376000028],
                [0.643436995000059, 7.269500804000074],
                [0.644188593000081, 7.262980447000132],
                [0.643999026000131, 7.257486447000133],
                [0.643953842000087, 7.256854797000131],
                [0.642695215000174, 7.253407297000138],
                [0.642893871000069, 7.252764337000031],
                [0.642246505000173, 7.25160261100001],
                [0.640469519000021, 7.249032236000062],
                [0.640258469000173, 7.243673702000081],
                [0.63874907100012, 7.237204453000118],
                [0.639648215000079, 7.23391432000011],
                [0.638860311000087, 7.231763668000156],
                [0.638857405000067, 7.227218035000021],
                [0.639231764000044, 7.224728443000117],
                [0.635058727000171, 7.221386518999964],
                [0.633655019000173, 7.21958053000003],
                [0.632631605000086, 7.201400770000078],
                [0.631272260000117, 7.196429097000021],
                [0.626721703000101, 7.18674320100007],
                [0.625796075999972, 7.185335187000135],
                [0.624869312000044, 7.182800204000102],
                [0.623391550000179, 7.184145076000164],
                [0.62229830900003, 7.184430973000076],
                [0.621133471000121, 7.184435089999965],
                [0.620003182000119, 7.184227561000114],
                [0.618519039000148, 7.183774499000151],
                [0.617528199000049, 7.183072917000175],
                [0.617173101000162, 7.182474853000088],
                [0.615779930000087, 7.177790996000056],
                [0.615563047000023, 7.17634635100012],
                [0.615699886000073, 7.175111984000068],
                [0.617655520000028, 7.169147201000101],
                [0.618179627000131, 7.16762944200002],
                [0.61720971200009, 7.162838328000078],
                [0.616887822000081, 7.161640828000145],
                [0.615895674000058, 7.160551443000145],
                [0.61327073700005, 7.156858987000135],
                [0.612777829000152, 7.15721325300018],
                [0.611085342000138, 7.157712733000096],
                [0.608475394000038, 7.158285923000108],
                [0.607028860000071, 7.158467244000065],
                [0.60646399400008, 7.158433961000071],
                [0.605968736000136, 7.158118402000184],
                [0.605578380000168, 7.157520445000159],
                [0.605044255000109, 7.156182651000165],
                [0.604792625000073, 7.154879128],
                [0.605242240000052, 7.152233504000151],
                [0.605655696000156, 7.149341230000118],
                [0.605926862000047, 7.146132164000164],
                [0.605922186000157, 7.144792525000071],
                [0.605668353000056, 7.142854433000139],
                [0.605111447000127, 7.140985701000034],
                [0.603942008000104, 7.137739777000093],
                [0.600571841000033, 7.135988764000047],
                [0.597588687000155, 7.134511805000159],
                [0.596923666000123, 7.133577663000153],
                [0.596421612000142, 7.131926855000131],
                [0.595125403000054, 7.123888947000125],
                [0.594392201000176, 7.118933718000051],
                [0.594001549000154, 7.117833486000166],
                [0.593280660000119, 7.116679184000191],
                [0.592723695000132, 7.115083637000055],
                [0.592187175000163, 7.11410751700015],
                [0.592072513000062, 7.113689940000143],
                [0.591879083000038, 7.111841695000067],
                [0.592560178000042, 7.11235075500008],
                [0.593204317000129, 7.113563116000137],
                [0.593905297000049, 7.11413818200009],
                [0.595069577000061, 7.114134175000061],
                [0.59696726400017, 7.114427764000084],
                [0.597924718000172, 7.114349430000175],
                [0.600399867000021, 7.113290437000046],
                [0.601148609000177, 7.112593805000131],
                [0.601353808000169, 7.112199179000186],
                [0.601291366000169, 7.110436145000108],
                [0.601569860000097, 7.109516042000109],
                [0.602261869000074, 7.108707058000164],
                [0.603536981000104, 7.108177426],
                [0.60560136700002, 7.107813880000151],
                [0.606107405000103, 7.107530758000166],
                [0.606294017000096, 7.107192469000154],
                [0.606893685000159, 7.106833990000041],
                [0.608151049000128, 7.106604536000077],
                [0.610273256000085, 7.106672202000027],
                [0.611191883000174, 7.106421677000128],
                [0.61182930300015, 7.105935239000019],
                [0.612276778000137, 7.105014547000053],
                [0.612200228, 7.104602140000168],
                [0.612404566000066, 7.103963660999966],
                [0.613076069000101, 7.102667034000092],
                [0.613711189000071, 7.101708173000077],
                [0.614082696000025, 7.100543893000179],
                [0.613986452, 7.099868945000139],
                [0.613552141000071, 7.099176414000112],
                [0.612367237000058, 7.098636553000119],
                [0.611990503000186, 7.098300220000056],
                [0.611723872000027, 7.097495709000043],
                [0.61194916200003, 7.09721240500005],
                [0.613543190000144, 7.09660661700002],
                [0.613993009000126, 7.09636120100015],
                [0.614609800000096, 7.095533710000154],
                [0.614683605000096, 7.095158296000022],
                [0.614398737000101, 7.094240152000054],
                [0.614282869000135, 7.093321419000176],
                [0.614390169000103, 7.092551336000099],
                [0.614126443000146, 7.091539964000106],
                [0.612674042000151, 7.089669219000143],
                [0.612236290000169, 7.087982526000133],
                [0.612137514000153, 7.086576026999978],
                [0.612003400000106, 7.085807418000059],
                [0.611360214000172, 7.084440320000113],
                [0.61070007700016, 7.08359850100004],
                [0.609718192000173, 7.082026235000114],
                [0.608942214000137, 7.080265674000032],
                [0.608092368000143, 7.078861766000046],
                [0.605408641000054, 7.073843896000028],
                [0.605144403000054, 7.073450889000071],
                [0.604335245000073, 7.072947211000155],
                [0.603618162000146, 7.071917991000078],
                [0.603182994000179, 7.070962831000088],
                [0.602368156000068, 7.068808465000188],
                [0.600988670000163, 7.066262116000075],
                [0.60030922500016, 7.065251515000057],
                [0.598464148000176, 7.063813475000075],
                [0.598255995000102, 7.063345237000078],
                [0.598140373000149, 7.062482763000105],
                [0.597646325000142, 7.060777473000087],
                [0.597080392000123, 7.060010331000171],
                [0.598115983000071, 7.055373548000148],
                [0.599372890000041, 7.049573013000099],
                [0.601016162000121, 7.04146391900008],
                [0.602235353000026, 7.035626001000026],
                [0.602383756000165, 7.035100269000054],
                [0.602373500000169, 7.033989582000061],
                [0.601982336000162, 7.033019502000116],
                [0.601396573000102, 7.031933539000022],
                [0.601055343000098, 7.03097804500004],
                [0.600826701000187, 7.030003408000141],
                [0.601026873000023, 7.028145678000044],
                [0.601380392000067, 7.0272065690001],
                [0.602479262000031, 7.024614206000081],
                [0.602681100000041, 7.023244181000166],
                [0.602374538999982, 7.021444457000087],
                [0.601805711000168, 7.019814450000126],
                [0.601370939999981, 7.018953064000129],
                [0.599426377000157, 7.015808340000149],
                [0.599293034000027, 7.015246053000055],
                [0.598104921000072, 7.01369317699999],
                [0.596146101000159, 7.011861544000055],
                [0.594958455000096, 7.010439962000078],
                [0.594127594000042, 7.009035922000066],
                [0.593918654000163, 7.008323822000136],
                [0.595940234000068, 7.006534943000133],
                [0.596244814000045, 7.006028236000077],
                [0.595212849, 7.002429386000188],
                [0.594573697999977, 7.002187697000181],
                [0.593259376000162, 7.002154634000021],
                [0.586838709000119, 7.002213850000146],
                [0.586255857000026, 7.002167884000073],
                [0.584940480000171, 7.001619987000083],
                [0.583246496000186, 7.000350131000175],
                [0.581213006000155, 6.998612453000135],
                [0.580478415000186, 6.997902106000083],
                [0.57974477200014, 6.997473129000184],
                [0.578898801000037, 6.997138318000111],
                [0.577734111000154, 6.996935882000059],
                [0.575933237000186, 6.99737335399999],
                [0.574376739000172, 6.997903794000024],
                [0.573496109000132, 6.998431971000116],
                [0.572820926000134, 6.998640569000145],
                [0.570384283000067, 6.999849238000024],
                [0.569708282000022, 6.999813976000155],
                [0.556360753000092, 6.994512146000034],
                [0.555313428000034, 6.995734906000166],
                [0.554674605000173, 6.995586948000153],
                [0.552999726000053, 6.994391931000166],
                [0.552510913000049, 6.994187199000066],
                [0.549449036000112, 6.99369080300005],
                [0.548547190000079, 6.993487424000023],
                [0.545368603000043, 6.991753317000132],
                [0.542451521000089, 6.98960564000015],
                [0.539797179000061, 6.987419562000127],
                [0.538629505000074, 6.986297857000125],
                [0.537272380000161, 6.984651524000071],
                [0.536856535000084, 6.983789982000189],
                [0.53592584300003, 6.980491493000045],
                [0.535129274000099, 6.978017943000168],
                [0.53364339899997, 6.977178632000118],
                [0.530822763, 6.975780884000074],
                [0.527964170000189, 6.974251927000182],
                [0.526928155000178, 6.973186031000068],
                [0.526121373000024, 6.973338707000096],
                [0.522873586000173, 6.973367948000089],
                [0.522532994000073, 6.972543660000099],
                [0.522209665000048, 6.971250347000137],
                [0.522130152000102, 6.969881213000178],
                [0.522272415000089, 6.967423356000154],
                [0.523059629000045, 6.967026885999985],
                [0.524576219000096, 6.965765162000082],
                [0.525417931000106, 6.964805750999972],
                [0.525660348000031, 6.96429848400004],
                [0.525656833000141, 6.963210488000073],
                [0.525203610000062, 6.962386564000099],
                [0.523622663000083, 6.961153580000087],
                [0.523471147000123, 6.960741375000168],
                [0.524179022000169, 6.95903204800004],
                [0.525281310000082, 6.95737772699999],
                [0.526139177000118, 6.955611639999972],
                [0.526268045000108, 6.954823358000169],
                [0.525963381000111, 6.95349246800015],
                [0.52443244, 6.950289649000126],
                [0.523207319000164, 6.948774127000092],
                [0.522941120999974, 6.947724491000145],
                [0.523350921000144, 6.946728958999984],
                [0.525940860000105, 6.94159532700013],
                [0.531883637000021, 6.940542805000121],
                [0.538418740000111, 6.938507142000162],
                [0.542941617000167, 6.937702071000103],
                [0.543484817000149, 6.93740445300017],
                [0.544384115000128, 6.936051517000124],
                [0.545022902000142, 6.93541615100014],
                [0.547734831000071, 6.937630453],
                [0.547989588000121, 6.937221926000063],
                [0.548249959000088, 6.936085515000059],
                [0.548662061000073, 6.934852681000052],
                [0.549311655000167, 6.933979887000135],
                [0.549652734000063, 6.933254229000113],
                [0.549878296000088, 6.932223982000039],
                [0.550022447000174, 6.929630659000168],
                [0.551627344, 6.930311735000032],
                [0.552468532000148, 6.930842885000118],
                [0.553537667000057, 6.931068052000171],
                [0.55416045200019, 6.930951567000022],
                [0.558635326000058, 6.928965188000063],
                [0.559482409000054, 6.928646385999969],
                [0.560548316000052, 6.927918490000138],
                [0.565526808000072, 6.923038328000132],
                [0.565910416000179, 6.922410190000051],
                [0.566139908000139, 6.920801384000129],
                [0.565595444000053, 6.919475154],
                [0.563984513000094, 6.91692551400007],
                [0.562834388000056, 6.915289657000187],
                [0.562370071999965, 6.913308507000068],
                [0.562348456000166, 6.906712047000099],
                [0.562457657000039, 6.905110155000102],
                [0.562148581000031, 6.903967256000101],
                [0.560108563000028, 6.898521383000116],
                [0.559453916000109, 6.896693164999988],
                [0.558711261000042, 6.891280930000107],
                [0.558401343000128, 6.88983310500015],
                [0.557861511000056, 6.888118840000118],
                [0.557170931000144, 6.886939113000153],
                [0.556596571000057, 6.886292812000022],
                [0.555410514000073, 6.885305104],
                [0.551166817000023, 6.882649931000174],
                [0.547193175000018, 6.880908607000151],
                [0.543182197000021, 6.879434552000134],
                [0.540815979000172, 6.879251444999966],
                [0.537686333000181, 6.878918395000142],
                [0.535452896000038, 6.878301625000063],
                [0.534454643000117, 6.877444891000096],
                [0.534126337000146, 6.87526932600008],
                [0.536296891000177, 6.873965822000059],
                [0.536638813000025, 6.873507072000109],
                [0.536978488000159, 6.87236214700016],
                [0.538197530000105, 6.87178633800005],
                [0.542376240000181, 6.866082010000071],
                [0.54317293400004, 6.864716049000094],
                [0.543017693000081, 6.863915869000152],
                [0.542787360000091, 6.863496965000138],
                [0.540990026999964, 6.862168344000054],
                [0.536556841000163, 6.859856418000106],
                [0.535639998000079, 6.859478203000151],
                [0.533957201000021, 6.858225103],
                [0.531777804000058, 6.856744846000026],
                [0.531778927000062, 6.84876236100007],
                [0.531831142000044, 6.833457300000021],
                [0.532830377000039, 6.834562182000127],
                [0.533804488000101, 6.834853175000148],
                [0.534414500000082, 6.834546016000047],
                [0.534583074000182, 6.834206245000075],
                [0.534334213000136, 6.833437232000108],
                [0.533138190000045, 6.831711336000126],
                [0.53251119399999, 6.83026583899999],
                [0.532755564000183, 6.828806121000184],
                [0.531869825000172, 6.827428289000068],
                [0.532418577000101, 6.82707943700018],
                [0.532657504000042, 6.826374817000101],
                [0.533171809000066, 6.827327733000118],
                [0.533455127000025, 6.827130381000131],
                [0.535136942000179, 6.826921332000154],
                [0.536122964000072, 6.826608156000134],
                [0.53781438700014, 6.825274450000109],
                [0.539152291000164, 6.824619806000101],
                [0.539390847000163, 6.824638335000145],
                [0.540689245000124, 6.826720360000024],
                [0.541340544000093, 6.826908432000153],
                [0.54247068300009, 6.826811121000162],
                [0.543296974000157, 6.827400982000029],
                [0.544463441000119, 6.827455121000128],
                [0.546172373000161, 6.82814962600014],
                [0.546204596000052, 6.828739270000085],
                [0.545885657000042, 6.8290186320001],
                [0.54539225700006, 6.829921371000125],
                [0.544702775000019, 6.829865708000057],
                [0.544302004000031, 6.830420915000047],
                [0.54470615200006, 6.830918426000096],
                [0.545092160000024, 6.831777008000074],
                [0.545628161000025, 6.832119768000041],
                [0.545440622000058, 6.832963654000025],
                [0.546244745000081, 6.83351499600019],
                [0.546610661000159, 6.834125616000051],
                [0.547300831000086, 6.834393472000045],
                [0.547872600000062, 6.833854248000023],
                [0.54848634800004, 6.833507798000085],
                [0.548902551000083, 6.83260806100003],
                [0.549366167000073, 6.832733339000072],
                [0.55025982300009, 6.831975368000144],
                [0.550470760000053, 6.831550292000145],
                [0.550872625000181, 6.831336802000067],
                [0.552721475000055, 6.830953301000022],
                [0.553691100000094, 6.829816157000096],
                [0.554533358000128, 6.829795529000023],
                [0.555113589000086, 6.82889250400018],
                [0.555710133000048, 6.829199231000018],
                [0.555711246000044, 6.829543705000106],
                [0.556439137000154, 6.82954135599999],
                [0.556774959000052, 6.829369411000073],
                [0.557296521000069, 6.82865672600019],
                [0.557637334000106, 6.82747062499999],
                [0.558381544000042, 6.82654777700003],
                [0.55963845600013, 6.825584692],
                [0.56021535800005, 6.824935210000092],
                [0.560729951000098, 6.824627650000082],
                [0.560882369000069, 6.824015367000129],
                [0.561246310000115, 6.824014190000128],
                [0.561496308000187, 6.824586589000148],
                [0.562628276000055, 6.825059680000095],
                [0.563276724000048, 6.824368627000013],
                [0.563851762000184, 6.824846274000038],
                [0.564540176000151, 6.824576729],
                [0.565382174000149, 6.82405590500008],
                [0.565246019000028, 6.823731162000115],
                [0.565705590000107, 6.823462358000086],
                [0.56572006100015, 6.822399948000111],
                [0.566140489000134, 6.822388938000131],
                [0.566541415000074, 6.821891592000156],
                [0.566847695000035, 6.821965005000095],
                [0.567613302000041, 6.821695204999969],
                [0.568090673000029, 6.820966122000129],
                [0.567628499000136, 6.820432998000115],
                [0.567990259000055, 6.819762163000121],
                [0.567968908000125, 6.819131153000114],
                [0.567739317000076, 6.81890041000014],
                [0.566252544000065, 6.818264511000166],
                [0.565927884000189, 6.815500111000063],
                [0.566269353000109, 6.814523448000159],
                [0.56684052300011, 6.813813354000047],
                [0.567276184000036, 6.813825721000058],
                [0.567624791000185, 6.813350591000187],
                [0.5680853450001, 6.81338767799997],
                [0.568737819000091, 6.813942232000045],
                [0.569272437000109, 6.813863334000075],
                [0.569538501000068, 6.813440833000016],
                [0.570477106, 6.812961030000054],
                [0.570188876000145, 6.812501747000113],
                [0.569627441000137, 6.812812221000058],
                [0.569496505000131, 6.811547733000054],
                [0.568978924000021, 6.811778144000016],
                [0.568745864000107, 6.810478161000049],
                [0.568973454000115, 6.810094366000158],
                [0.569661970000084, 6.80986340000004],
                [0.570196952000117, 6.809897487000114],
                [0.570120030000112, 6.80913438000016],
                [0.570521437000025, 6.808788602000107],
                [0.571153790000039, 6.809092441000132],
                [0.571558356000139, 6.808870662000174],
                [0.571780335000085, 6.809305356999971],
                [0.572341922000135, 6.809890517000099],
                [0.57274401799998, 6.809756930000162],
                [0.574788807000175, 6.808620398000073],
                [0.57559311000017, 6.808389047000048],
                [0.57601518600012, 6.808464834000176],
                [0.57641388400009, 6.808135595000181],
                [0.577048060000152, 6.808152819000043],
                [0.577543324000089, 6.807845310000062],
                [0.578081940000061, 6.808149446000186],
                [0.579531823000025, 6.807207744000095],
                [0.579931003000183, 6.807027314000038],
                [0.579835661000118, 6.806537094000021],
                [0.579473494000069, 6.806232384],
                [0.579278757000054, 6.805698397000128],
                [0.577325174000123, 6.805205971000078],
                [0.576844709999989, 6.804978806],
                [0.576269072000173, 6.804311024000185],
                [0.576379674000066, 6.803563844],
                [0.577395374999981, 6.803061736000018],
                [0.577792764000094, 6.802332910000132],
                [0.577314251000018, 6.801857717000189],
                [0.577828745000147, 6.801530857000103],
                [0.57685040400014, 6.800806515000033],
                [0.576219183000035, 6.800847152000131],
                [0.576024076000124, 6.800197419000085],
                [0.576253291000171, 6.799469143000067],
                [0.576806938000061, 6.799313016000042],
                [0.576135128000089, 6.798741997000093],
                [0.575645154000028, 6.798131805000082],
                [0.575379451000174, 6.798661783000057],
                [0.575370003000103, 6.799146833000066],
                [0.574491212000169, 6.79935913200012],
                [0.574434060000044, 6.799588049000136],
                [0.573440165000136, 6.80001291800005],
                [0.57313538700015, 6.800396964000186],
                [0.57208222200012, 6.800400385000046],
                [0.569750165000187, 6.801364216000081],
                [0.569233282000141, 6.801806820000138],
                [0.569215906000125, 6.802399374000117],
                [0.567370782000012, 6.802616172000114],
                [0.567548949000127, 6.801870149000138],
                [0.568714700000044, 6.800025495000114],
                [0.569692576000136, 6.800196229000164],
                [0.570123761000161, 6.798397759000068],
                [0.570428417000187, 6.79797513300008],
                [0.570183039000085, 6.797548779000181],
                [0.570692050000048, 6.796805818000053],
                [0.570269619000157, 6.796617038000079],
                [0.569464899000025, 6.796716098000104],
                [0.568997521000085, 6.797119960000089],
                [0.568261465000091, 6.797138878000055],
                [0.567708494000101, 6.797504435000064],
                [0.56724709000008, 6.797200034000184],
                [0.567091344000175, 6.796781655000075],
                [0.567589354000177, 6.79647139299999],
                [0.567969849000121, 6.795629641000176],
                [0.56854180400012, 6.795167570000047],
                [0.569116179000105, 6.795452314000158],
                [0.569365945000186, 6.795107029000121],
                [0.569124854000052, 6.794726131],
                [0.569990281000173, 6.792944456000157],
                [0.569184202000145, 6.792621881000173],
                [0.568877003000068, 6.792259108999986],
                [0.569029901000079, 6.791798394000182],
                [0.568548891999967, 6.791397604000053],
                [0.568489569000121, 6.790956866000158],
                [0.568021182000052, 6.789770629000145],
                [0.567717819000052, 6.789314145000049],
                [0.567144441000039, 6.789335290000111],
                [0.566492851000135, 6.789041146000102],
                [0.566217607999988, 6.788328279000041],
                [0.566754859000127, 6.787366144000089],
                [0.566713536000179, 6.786523001000035],
                [0.567656285000112, 6.785632585000087],
                [0.567858157000103, 6.784970539000028],
                [0.568298271000117, 6.784663221000187],
                [0.568497963000141, 6.784180310000068],
                [0.567625931000123, 6.783918570000083],
                [0.566394345000163, 6.782872583000142],
                [0.566850865000106, 6.782096727000067],
                [0.567657348000125, 6.7816972870001],
                [0.569110666000086, 6.781616806999978],
                [0.569694921000064, 6.780913566000152],
                [0.56987248500019, 6.780408679000061],
                [0.570331767000141, 6.780062718000124],
                [0.570943238000041, 6.779889881000088],
                [0.57275018100006, 6.778552980000086],
                [0.573127665000015, 6.77848837300013],
                [0.573374423000189, 6.778068692000147],
                [0.57411469200008, 6.777658435000035],
                [0.575087074000066, 6.776563986000156],
                [0.575617867000176, 6.775319400000058],
                [0.576269982000042, 6.774931474000084],
                [0.577129495, 6.77473853500004],
                [0.577568850000034, 6.774202483000067],
                [0.577280645000087, 6.773743201000116],
                [0.576399519, 6.77407124400014],
                [0.575925158000132, 6.77401491099999],
                [0.574894732000132, 6.774211157000082],
                [0.573472461000165, 6.774292926000044],
                [0.573168310000085, 6.774867118000088],
                [0.572714290000022, 6.775133145000154],
                [0.57247000600006, 6.774614466000173],
                [0.571730396000135, 6.77437572600013],
                [0.57316728, 6.773272890000158],
                [0.573542368000176, 6.772895508000033],
                [0.573882568000045, 6.771535796000137],
                [0.574513161000084, 6.770461748000116],
                [0.576153761000114, 6.768849802000148],
                [0.576360292000061, 6.767928696000126],
                [0.577141505000156, 6.76654826500004],
                [0.577617957000143, 6.76555187800011],
                [0.578095712000106, 6.764957832000164],
                [0.579887842000062, 6.763326099000096],
                [0.581682152000042, 6.761520739000105],
                [0.58260064000018, 6.761669322000046],
                [0.583366270000113, 6.761438102000113],
                [0.584590137000021, 6.761395540000137],
                [0.585139931000072, 6.761763026000153],
                [0.586161478000122, 6.761390424000069],
                [0.586543792000157, 6.761121867000099],
                [0.587225686000068, 6.76142002500012],
                [0.588965730000155, 6.759893158000182],
                [0.590013259000045, 6.759881470999972],
                [0.590827447000095, 6.760170925000125],
                [0.591759822000029, 6.760352516000125],
                [0.592127366000113, 6.759783623000089],
                [0.59251073900009, 6.75984024100012],
                [0.593581027000027, 6.759202911000159],
                [0.594498643000065, 6.759086922000051],
                [0.59596833300003, 6.757511316000091],
                [0.596654309000087, 6.75653352400019],
                [0.597113547000106, 6.756187548000128],
                [0.597590455000102, 6.756185984000183],
                [0.599143793000053, 6.756583232000025],
                [0.60088570500011, 6.75648105900018],
                [0.601976384000182, 6.755344855000146],
                [0.602573028000165, 6.754451402000029],
                [0.603117472000122, 6.752774623000107],
                [0.603498928000022, 6.752250634000177],
                [0.603844273000107, 6.752480983000169],
                [0.604338639000161, 6.751922692000051],
                [0.604568247000145, 6.751329447000103],
                [0.605157588000168, 6.750313387000062],
                [0.605923490000123, 6.750175832000139],
                [0.605962481000063, 6.74987808200018],
                [0.608383661000175, 6.747202529000049],
                [0.609034797000049, 6.747373995000032],
                [0.609037571000101, 6.748214492000045],
                [0.608808670000087, 6.749019928000053],
                [0.609308886000065, 6.749401328000147],
                [0.609099545000049, 6.749869120000142],
                [0.609979440000131, 6.749608552999973],
                [0.610380325000165, 6.749127726999973],
                [0.610943352000106, 6.74932979499999],
                [0.611358514000187, 6.749832726000136],
                [0.61145658200013, 6.750311903000124],
                [0.612987529000065, 6.750612731000103],
                [0.613295039000093, 6.75107192400003],
                [0.613639930000147, 6.751164478000021],
                [0.61360444900015, 6.752104307000082],
                [0.614143766000097, 6.752637138000182],
                [0.614664645000119, 6.752596832000052],
                [0.615291068000147, 6.751963691000185],
                [0.61567098300003, 6.751810866000028],
                [0.616014037000184, 6.751349518000041],
                [0.617013339000096, 6.751767835000123],
                [0.617760567000119, 6.753479431000187],
                [0.618104003000099, 6.753133822000109],
                [0.618298662000143, 6.754472468000188],
                [0.618759087000115, 6.754490229000112],
                [0.619600964000085, 6.753988643000184],
                [0.619710999000176, 6.753089905000081],
                [0.621281057999965, 6.751887316000136],
                [0.62166270400013, 6.751427216000081],
                [0.62273375699999, 6.751040605000185],
                [0.622769037000069, 6.750045665000073],
                [0.623155627000074, 6.749622751000118],
                [0.623226328000158, 6.749123726000107],
                [0.624201472000038, 6.748911044000124],
                [0.624083078000183, 6.748126054000181],
                [0.624618245000136, 6.748240012000167],
                [0.624979822000114, 6.747549873000025],
                [0.625516276000099, 6.747222910000062],
                [0.627913566000018, 6.747755040000186],
                [0.628872785999988, 6.748553756000035],
                [0.629407503000095, 6.748532681000143],
                [0.630001776000142, 6.749010193000061],
                [0.630406029000142, 6.749543453000115],
                [0.632768678000105, 6.748783236000179],
                [0.633006398000077, 6.748154134000174],
                [0.633635078000054, 6.74820438800009],
                [0.633771203000038, 6.74769550100018],
                [0.634549074000176, 6.746200671000111],
                [0.635525580000149, 6.745987964000165],
                [0.635946726000157, 6.74621527700009],
                [0.637034826000161, 6.745988414000067],
                [0.637601294000092, 6.745573156000148],
                [0.637580268000136, 6.745057907000046],
                [0.638114932000178, 6.745023043000174],
                [0.638044112000159, 6.74445560300012],
                [0.638490121000189, 6.744288762999986],
                [0.6387143930001, 6.74458287200008],
                [0.639385977000074, 6.744277487000147],
                [0.641950902000133, 6.743841730000042],
                [0.642904458000146, 6.742956694000043],
                [0.643631872000128, 6.742860551000149],
                [0.643858346, 6.742170859999987],
                [0.644852810000032, 6.741974611000046],
                [0.645408666000037, 6.741686145000131],
                [0.645847021000179, 6.741703957000027],
                [0.646048681000138, 6.741014350000057],
                [0.646744282000043, 6.74047739700012],
                [0.647299629000088, 6.74085581200012],
                [0.648848458000089, 6.739932934000137],
                [0.648432978000187, 6.739341861000128],
                [0.649383672000113, 6.739247713000168],
                [0.649092478, 6.738727868000069],
                [0.649244701999976, 6.738093542000172],
                [0.650256656000067, 6.737774596000065],
                [0.650275033000071, 6.737095253000064],
                [0.650501626000107, 6.736444142000096],
                [0.65051808200019, 6.735603596000033],
                [0.649923236000177, 6.734955259000117],
                [0.649636446999978, 6.735738847000107],
                [0.648628658000177, 6.735248977000083],
                [0.64835144500006, 6.73478695200015],
                [0.648427046000052, 6.734318227000188],
                [0.648100199000055, 6.733850859000029],
                [0.647468571000047, 6.733737248000125],
                [0.647030508000114, 6.732986416000188],
                [0.647193854000136, 6.732379610000066],
                [0.645646573000079, 6.731301826000049],
                [0.645837784000037, 6.729146216000061],
                [0.644833516000062, 6.728468934000148],
                [0.645550457000127, 6.727314635000141],
                [0.644665483000097, 6.725633870000081],
                [0.644908477000115, 6.724120167000137],
                [0.644278394000139, 6.723232188000111],
                [0.643982161000054, 6.721616956000048],
                [0.643231641000114, 6.719131754000159],
                [0.644066491000103, 6.718821001000094],
                [0.644092741000122, 6.718019],
                [0.645023159, 6.718054457000108],
                [0.645212834000176, 6.717899500000158],
                [0.645171994000179, 6.717229999000097],
                [0.645419367000102, 6.717019735000065],
                [0.646033914999975, 6.71697909],
                [0.647064731, 6.716132380000147],
                [0.647616071000186, 6.715328615000146],
                [0.647634076000145, 6.714945511000053],
                [0.648606677000032, 6.714002544000095],
                [0.649160054000106, 6.712986582000099],
                [0.648826705000147, 6.711400415000185],
                [0.648003251000034, 6.710791415000074],
                [0.647391402000153, 6.710812761000057],
                [0.647407863000183, 6.709969457999989],
                [0.646792620000156, 6.709800670000107],
                [0.646834908000073, 6.709260408000148],
                [0.647460286000126, 6.709164615000134],
                [0.64788053699999, 6.708725044000062],
                [0.647877509000125, 6.707823938000104],
                [0.648411524000153, 6.706788753000183],
                [0.648371972000064, 6.706502292000039],
                [0.647562170000072, 6.705030705000013],
                [0.647560181000131, 6.704438235000055],
                [0.647786449000023, 6.703690677000168],
                [0.647536844000115, 6.703234067000039],
                [0.647399786000108, 6.701818091000121],
                [0.646821017000093, 6.701015364000057],
                [0.645920473000047, 6.700425904000156],
                [0.64568413000012, 6.698990968999965],
                [0.645473174000074, 6.698550761000149],
                [0.644554625000183, 6.698344401000156],
                [0.644131822000077, 6.698020642000074],
                [0.643707545000041, 6.69725597200005],
                [0.643706328000064, 6.696892222000145],
                [0.644164064999984, 6.696124601000122],
                [0.644160995000107, 6.695206958000142],
                [0.643639099000097, 6.69409814800008],
                [0.64365621800016, 6.693447742000103],
                [0.644114977000186, 6.692986003000158],
                [0.643883524000159, 6.692184864000069],
                [0.644148320000056, 6.691417889000093],
                [0.643897189000143, 6.6905010750001],
                [0.643416273000128, 6.690105858000152],
                [0.643946195000069, 6.68949507300016],
                [0.642975579000051, 6.689374309000129],
                [0.643353575999981, 6.688664826000093],
                [0.644424283000035, 6.68822033500004],
                [0.644594988000051, 6.687340690000156],
                [0.644860869000127, 6.68689888800003],
                [0.645624928000188, 6.686245985000085],
                [0.645871007000039, 6.685652683],
                [0.645467187000179, 6.685232409000093],
                [0.646382706000168, 6.68454041900003],
                [0.646190988000114, 6.684080856000094],
                [0.64374331900018, 6.682807622999974],
                [0.643805296000096, 6.680319003000136],
                [0.643654842000046, 6.679834498000105],
                [0.64380114800008, 6.679076186999964],
                [0.643281449000142, 6.678617715000087],
                [0.643222548000153, 6.678312027000175],
                [0.642724835000138, 6.677834191000045],
                [0.642839327000161, 6.677453519000039],
                [0.643543504000093, 6.677029547000132],
                [0.642526675000056, 6.676266845000043],
                [0.642408822000164, 6.674812218000056],
                [0.642828992000091, 6.674353368000084],
                [0.64259613400003, 6.673951808000084],
                [0.642900214000122, 6.673394140000141],
                [0.642785934000131, 6.67301147500018],
                [0.64318341000012, 6.672359802000187],
                [0.643354312000042, 6.671538027000111],
                [0.643256763000068, 6.671213178000187],
                [0.643522640000128, 6.670771377000108],
                [0.642775785000083, 6.669136965000064],
                [0.643806617000109, 6.669144555000173],
                [0.644232534000082, 6.668757336000169],
                [0.644110125000168, 6.668416035000121],
                [0.644529646000137, 6.667764287000182],
                [0.644717329000116, 6.667016862000082],
                [0.644409040000085, 6.6663096690001],
                [0.643641369000079, 6.665871310000114],
                [0.643755154000189, 6.665278452000109],
                [0.643084206000083, 6.664916930000061],
                [0.642573475, 6.664248988],
                [0.64190517600008, 6.661782082000173],
                [0.642286807000119, 6.66133989799999],
                [0.642056402000151, 6.660844634000057],
                [0.64234182600012, 6.660479930000065],
                [0.642319603000146, 6.659598172000131],
                [0.641434606000189, 6.658683457000052],
                [0.641183767000143, 6.657843793000041],
                [0.64066584599999, 6.657082176000131],
                [0.640586507000137, 6.656429333000062],
                [0.640257163000058, 6.65601706700005],
                [0.640064425000105, 6.65524334700018],
                [0.639449024000044, 6.654173410000169],
                [0.639237535000063, 6.653562339000132],
                [0.639118802999974, 6.652664365000135],
                [0.639213484000095, 6.652126684000109],
                [0.638849183000048, 6.651976326000124],
                [0.63860280300014, 6.650815603000069],
                [0.638824751000186, 6.650424932000021],
                [0.639377509999974, 6.650059345000045],
                [0.64008562000015, 6.649999129000037],
                [0.640448723000077, 6.649788490000105],
                [0.638350092000053, 6.648574654000015],
                [0.638011248000112, 6.647787636000089],
                [0.637398644000029, 6.647558181000022],
                [0.637260372000128, 6.646583110000108],
                [0.637010243000134, 6.645952874000159],
                [0.63760196700008, 6.645702902000096],
                [0.63794469100003, 6.645164401000045],
                [0.637849218000042, 6.64463010500009],
                [0.637503195000079, 6.644171041999982],
                [0.63696533600006, 6.644040545000109],
                [0.636599958000033, 6.643562255000177],
                [0.636007672000062, 6.643641371000172],
                [0.635509262000028, 6.643355043000099],
                [0.635197296000172, 6.641941004000046],
                [0.635447327000179, 6.641708697000126],
                [0.635169936000011, 6.641166732000102],
                [0.63540791600002, 6.640623068000082],
                [0.63628240900016, 6.640041477000182],
                [0.638043357000186, 6.639997080000171],
                [0.639056993000054, 6.639822873000014],
                [0.639419643, 6.63947720800013],
                [0.639970112000185, 6.638422698999989],
                [0.639734541000166, 6.638026653000111],
                [0.639298323000105, 6.637793859],
                [0.638023046, 6.638021288000118],
                [0.637307683000074, 6.637549665000051],
                [0.637000324000155, 6.637112519000141],
                [0.636454882000123, 6.636769853000033],
                [0.636690172000044, 6.635829371],
                [0.637302442000077, 6.635962381000184],
                [0.637494221000054, 6.635617281000066],
                [0.637453427000025, 6.634947773000079],
                [0.636992672000019, 6.634794973000055],
                [0.636723708000147, 6.634299828000053],
                [0.636392412000077, 6.629946862000111],
                [0.640274593000072, 6.631322935000185],
                [0.641212034000091, 6.632096932000025],
                [0.642191579000041, 6.632636588000082],
                [0.642446438999968, 6.632200340000054],
                [0.643069454000113, 6.632250636000094],
                [0.643338769000081, 6.632018264000067],
                [0.643603055000142, 6.631099732000109],
                [0.643869239000139, 6.630754385000046],
                [0.643562190000011, 6.630410937000022],
                [0.644591350000098, 6.629106826000054],
                [0.644512030000101, 6.628456738000068],
                [0.645278430000076, 6.628531359000135],
                [0.64571683500003, 6.627763815000037],
                [0.646406506000176, 6.627970963000109],
                [0.646382710000182, 6.62661247200009],
                [0.646646797000074, 6.625636072000134],
                [0.646932524000022, 6.625367819000019],
                [0.64754483400003, 6.625517352999964],
                [0.648081410000032, 6.625267558000189],
                [0.648501286000112, 6.624731555000153],
                [0.648538490000021, 6.62348584800003],
                [0.648724987999969, 6.622382941000183],
                [0.649256340000079, 6.622221346],
                [0.649199759999988, 6.621780619000049],
                [0.648719288000052, 6.621495619000086],
                [0.649117895000074, 6.620364452000047],
                [0.649690986000167, 6.620323971000175],
                [0.650169362000099, 6.619977918000018],
                [0.650841195, 6.620626036000033],
                [0.651177236000024, 6.620572561000131],
                [0.652118858000108, 6.619475414000021],
                [0.65172430399997, 6.618921449000084],
                [0.652232250000111, 6.618766819000086],
                [0.652459969, 6.618460178000021],
                [0.65199662100008, 6.617522019000148],
                [0.652339836000124, 6.617140590000133],
                [0.652680685000178, 6.617371826000067],
                [0.653257739000139, 6.616371451000134],
                [0.651970088999974, 6.615342338000176],
                [0.65248716800005, 6.615034736000155],
                [0.652256394000119, 6.614420977000066],
                [0.652347428000041, 6.613618762000044],
                [0.653627627000162, 6.61240750800016],
                [0.654161365000107, 6.61213843000013],
                [0.653834843000084, 6.611737181000024],
                [0.653930023000157, 6.611353820000034],
                [0.655001782999989, 6.611275854000155],
                [0.655846201000088, 6.610788041000035],
                [0.655213253000113, 6.610227980000161],
                [0.655574535000142, 6.609479982000096],
                [0.656261704000087, 6.60894308900015],
                [0.656412126000077, 6.608598125000185],
                [0.6582684870001, 6.607800630000043],
                [0.660007989000178, 6.607629920000136],
                [0.66069278000009, 6.606382059000168],
                [0.664667368000096, 6.604952363000166],
                [0.666947343, 6.605234088000145],
                [0.66731333700011, 6.606723694000095],
                [0.667884078000156, 6.60763391800009],
                [0.669280225000136, 6.607323358000144],
                [0.670345647000033, 6.608648028000175],
                [0.670673963000183, 6.611223496000093],
                [0.672768313000063, 6.613663511000084],
                [0.675269065000066, 6.611571801000025],
                [0.677163300000188, 6.610231672999987],
                [0.682031213000187, 6.606503356000189],
                [0.685064549000174, 6.603833881000185],
                [0.686281867000162, 6.601991732000101],
                [0.688784367000096, 6.600451110000165],
                [0.690655152999966, 6.59872247900006],
                [0.690631716000098, 6.597499044000131],
                [0.688733478000074, 6.596816558000171],
                [0.691747449000104, 6.591724942000042],
                [0.69786095000012, 6.588444527000149],
                [0.711728160000177, 6.585015002000148],
                [0.723656942000048, 6.58993562600017],
                [0.731658008000181, 6.589685593000183],
                [0.742444774000091, 6.589040968],
                [0.747166778000121, 6.577409822000107],
                [0.748631057000068, 6.573016985000152],
                [0.749309237000034, 6.568755126000042],
                [0.748856496000144, 6.563095863000115],
                [0.746321630000011, 6.559517228000061],
                [0.741211828000189, 6.554824189000158],
                [0.728233772000181, 6.543217174000176],
                [0.719034540000052, 6.534914110000102],
                [0.717383097999971, 6.52746794900014],
                [0.718659669000033, 6.51211873800014],
                [0.720262030000185, 6.506399559000045],
                [0.721725586000105, 6.50200638900003],
                [0.723502817000167, 6.499216932000081],
                [0.732156203000102, 6.484813218000056],
                [0.746516482000175, 6.483945342000027],
                [0.744883170000037, 6.45837792399999],
                [0.749223939000103, 6.448189283000147],
                [0.754349973000046, 6.44555688100013],
                [0.760363430000098, 6.443017412000074],
                [0.773002880000149, 6.438041215000169],
                [0.781952913000055, 6.434477461000029],
                [0.78752093900016, 6.413394656999969],
                [0.802388345000168, 6.408921023],
                [0.815528105, 6.406483288000061],
                [0.826094119000118, 6.404113292000034],
                [0.83976325399999, 6.396357141000181],
                [0.855878625000173, 6.383712683000056],
                [0.861894103000168, 6.379161042000135],
                [0.864749744000051, 6.375202188000174],
                [0.864733005, 6.373236249000115],
                [0.872275368000146, 6.368652850000046],
                [0.874371508000024, 6.366808760000083],
                [0.875583637000034, 6.363471685000093],
                [0.877819759000147, 6.359986234000076],
                [0.879703947000053, 6.358188372000029],
                [0.880753764000133, 6.356808229000137],
                [0.882290862000161, 6.356511943000101],
                [0.883795121000162, 6.35551727800015],
                [0.88493444400001, 6.353005713000073],
                [0.886855144000151, 6.350281891000122],
                [0.887545750000072, 6.347829826],
                [0.887640833000034, 6.344443095000031],
                [0.888472591000152, 6.34218339500012],
                [0.890730379000047, 6.33901748500017],
                [0.891744992000099, 6.337061590000076],
                [0.892637126000182, 6.33550567400016],
                [0.907253557000104, 6.335799342000087],
                [0.92228782300009, 6.335914702000025],
                [0.93607095100009, 6.335833155000159],
                [0.951782403000095, 6.335649305],
                [0.963997659000029, 6.33495122800008],
                [1.003357651000101, 6.335500619000186],
                [1.004351581000094, 6.334736343000145],
                [1.004911458000152, 6.3328165530001],
                [1.005461443000115, 6.332616054000141],
                [1.006259551000085, 6.33177538800004],
                [1.006529822000118, 6.331165442000042],
                [1.006574168000043, 6.329098864000116],
                [1.00633819899997, 6.327892994000138],
                [1.006412604000047, 6.327192884000112],
                [1.007448895000039, 6.325993121000067],
                [1.007684524000126, 6.324970029000156],
                [1.00754935000009, 6.323479985000176],
                [1.007612644000176, 6.322755120000124],
                [1.00804062800006, 6.321626588000186],
                [1.007698713000139, 6.320080859000086],
                [1.006905485000061, 6.314321400000097],
                [1.005335526000067, 6.311007418000145],
                [1.006553873000087, 6.305955181000172],
                [1.006457030000149, 6.305117970000083],
                [1.00683184900015, 6.30448558400019],
                [1.007005886000059, 6.303198232000057],
                [1.006839592000176, 6.302201485000126],
                [1.006710718000022, 6.300193432000128],
                [1.007147641000188, 6.298524848999989],
                [1.007267540000044, 6.297480163000103],
                [1.008138464000183, 6.295534391000047],
                [1.008283759000051, 6.294649410000091],
                [1.007934305000049, 6.2939977690001],
                [1.00800182200004, 6.293653109000161],
                [1.009277507000036, 6.292422136000027],
                [1.00966773600004, 6.292219505000048],
                [1.010371952000071, 6.291321353000114],
                [1.011218744000132, 6.290981960000011],
                [1.012349571000186, 6.290035327000112],
                [1.012864117000106, 6.289939669000034],
                [1.01444134500008, 6.289079481000101],
                [1.015132848000178, 6.287743300000102],
                [1.015739430000053, 6.287236760000155],
                [1.016028834000053, 6.28588008800017],
                [1.015859032000037, 6.284687742000074],
                [1.015818019999983, 6.283332343000097],
                [1.016072298000097, 6.282149383000103],
                [1.015916798000035, 6.281094741000061],
                [1.01595837300016, 6.280449866000083],
                [1.016289994000147, 6.280046329000186],
                [1.01694123700014, 6.279699421000089],
                [1.017131986000038, 6.279180710000048],
                [1.01709150500011, 6.277963069000123],
                [1.016646365000042, 6.276771783000129],
                [1.016639512000097, 6.275705548000133],
                [1.016846823000151, 6.273765092000133],
                [1.017352994000021, 6.272211968000079],
                [1.017411028000026, 6.270834147000187],
                [1.017805855000063, 6.269683710000152],
                [1.018566703000147, 6.268485027000168],
                [1.019236462000038, 6.267228836000072],
                [1.019890960000055, 6.265584221000154],
                [1.02033426200012, 6.264868920000026],
                [1.020703295000033, 6.263456841000163],
                [1.021671109000124, 6.262381345000165],
                [1.022410189000027, 6.261254381000185],
                [1.02262571000017, 6.260735575000069],
                [1.022631279, 6.260035735000088],
                [1.022978003000105, 6.25855210900005],
                [1.023363265000171, 6.25778192700011],
                [1.023865397000179, 6.257330898000021],
                [1.023997787000099, 6.256674653000118],
                [1.024373528000183, 6.256292990000077],
                [1.025027884000167, 6.256048012000065],
                [1.025217128000179, 6.255140827000048],
                [1.02627899200013, 6.254184818000113],
                [1.026672630000178, 6.253446290000113],
                [1.027405158000022, 6.253125242000181],
                [1.027524677000088, 6.251630095],
                [1.027809914000102, 6.251342456000089],
                [1.028728917000024, 6.251184621000107],
                [1.029196072000048, 6.25094862800006],
                [1.029590761000122, 6.250484235000045],
                [1.030511753000099, 6.248700837000115],
                [1.031333345000064, 6.247559777000049],
                [1.032281341000157, 6.245641271000125],
                [1.032835063000164, 6.245008198000051],
                [1.032942037000112, 6.244189498000139],
                [1.033285014000171, 6.24316876000006],
                [1.033857123000189, 6.24230693700008],
                [1.034388230000161, 6.242236006000155],
                [1.035167265000041, 6.241486345000055],
                [1.035975275000112, 6.241111275000094],
                [1.036551939000049, 6.240004222999971],
                [1.036612327000057, 6.239243563000116],
                [1.0365177380001, 6.238276865000046],
                [1.037853804000179, 6.237756484000101],
                [1.03854017600014, 6.237255144000187],
                [1.039484670000093, 6.237290062000056],
                [1.040030639000065, 6.236792020000053],
                [1.040791492000039, 6.236328963000176],
                [1.041172704000189, 6.235944519000157],
                [1.042548409000062, 6.235710512000082],
                [1.043416246000163, 6.235858682000071],
                [1.044246852000129, 6.235635049000166],
                [1.044972376000146, 6.234582522000039],
                [1.046864141000071, 6.233952517000034],
                [1.047648717000129, 6.23393018400003],
                [1.048276967000106, 6.233354674000054],
                [1.049147745000028, 6.233552416000066],
                [1.050036821000049, 6.233499368000082],
                [1.050871379000171, 6.233587042000067],
                [1.051578586000176, 6.233493372000112],
                [1.05206417900007, 6.233758731000137],
                [1.052349618000164, 6.233528944000057],
                [1.052828028000135, 6.233717185999979],
                [1.053498055999967, 6.233620902000041],
                [1.054278234000037, 6.233177041000147],
                [1.054834970000115, 6.233329160000039],
                [1.055004325000027, 6.232984108000096],
                [1.055740505000188, 6.232554192000066],
                [1.056526385000154, 6.231454586000041],
                [1.056780675000084, 6.23029643700005],
                [1.057761078000112, 6.22895362100013],
                [1.058361950000062, 6.228422292000118],
                [1.059377816000108, 6.226996682000106],
                [1.060025164000024, 6.226382517000047],
                [1.061054474000116, 6.225229612000135],
                [1.061932744000103, 6.224881792000133],
                [1.06224399600012, 6.224213836000104],
                [1.063038508000147, 6.223921444000041],
                [1.063871596000126, 6.222931855000127],
                [1.064196266000181, 6.222175684000035],
                [1.064765958000066, 6.221418554000081],
                [1.065128266000045, 6.220430805000149],
                [1.066312112000105, 6.219023822000168],
                [1.066495684000188, 6.218091874000152],
                [1.066410534000056, 6.216025867000042],
                [1.066517882000085, 6.214609317000054],
                [1.066783498000177, 6.213536539000131],
                [1.066780585000061, 6.212789913000165],
                [1.067019293000101, 6.211877036000033],
                [1.067824024000117, 6.210680927000112],
                [1.068381378000083, 6.210293032000038],
                [1.069035355000096, 6.209268328000178],
                [1.069266198000094, 6.208457422000038],
                [1.069780244000185, 6.207554490000121],
                [1.070941537000067, 6.206020862000059],
                [1.072879075000174, 6.200233064000088],
                [1.073432490000187, 6.199544877999983],
                [1.074413960000072, 6.197791546000076],
                [1.075187740000104, 6.19572770000002],
                [1.075535304000084, 6.195200115000148],
                [1.07569743300013, 6.19441978600014],
                [1.075183628000161, 6.193969958000025],
                [1.074688095000056, 6.193969141000082],
                [1.073863276000111, 6.194242366000026],
                [1.072879610000086, 6.19472697600014],
                [1.071575540000026, 6.194940081000141],
                [1.072196543000189, 6.193582141000036],
                [1.072926357000142, 6.192595716000028],
                [1.073595260000161, 6.191874020000171],
                [1.075134924, 6.190661266],
                [1.075861374000056, 6.189873222000188],
                [1.076877624000133, 6.187866287000134],
                [1.077269188000059, 6.187327511000149],
                [1.078223525000055, 6.186387044000071],
                [1.079479070000105, 6.185034886999972],
                [1.080673874000183, 6.18363888600004],
                [1.080951800000094, 6.18290494200005],
                [1.081014827000047, 6.181422453000039],
                [1.080573186000038, 6.179710511000167],
                [1.080242448000035, 6.178904561000081],
                [1.079376408000144, 6.17777835600009],
                [1.078950023000118, 6.177857165000091],
                [1.07863807200016, 6.178337773000067],
                [1.078221899000084, 6.179623276000143],
                [1.078103860000169, 6.180414452000036],
                [1.077559420000057, 6.181986987000073],
                [1.077225626000143, 6.182514516000083],
                [1.076779784000053, 6.182546564000063],
                [1.076492371000143, 6.182261156000095],
                [1.076417814000138, 6.180790222000042],
                [1.075430471000061, 6.177144938000083],
                [1.077550704000032, 6.174809978000155],
                [1.07769846500014, 6.174580727000091],
                [1.077356596000186, 6.173741755000151],
                [1.077428099000144, 6.173016884000106],
                [1.078071865000084, 6.172915187000115],
                [1.078301415000112, 6.172481741000127],
                [1.078200296000148, 6.171964176000074],
                [1.079135199000177, 6.171172810000144],
                [1.080774882000185, 6.170730858000127],
                [1.085207671000035, 6.168812509000134],
                [1.085902482000108, 6.168743667000115],
                [1.127436915000033, 6.167921306000039],
                [1.164150834000111, 6.168418046999989],
                [1.182181513000103, 6.167699909000078],
                [1.200214586000072, 6.167625749000024],
                [1.198589384000115, 6.15227912000006],
                [1.199612073000083, 6.137227697000185],
                [1.199555296000085, 6.13009000600016],
                [1.19948470200012, 6.113472999000067],
                [1.202361001000156, 6.113472999000067],
                [1.202361001000156, 6.113751001000139],
                [1.202917, 6.11402900000013],
                [1.203751001000057, 6.11402900000013],
                [1.203751001000057, 6.114305],
                [1.204582999000138, 6.114305],
                [1.204582999000138, 6.114583000000152],
                [1.205139, 6.114860999000143],
                [1.206249001000117, 6.114860999000143],
                [1.206249001000117, 6.115139001000046],
                [1.206805, 6.115417],
                [1.207360999000116, 6.115417],
                [1.23549807400002, 6.124834112000144],
                [1.238124639000034, 6.125093439000182],
                [1.247593922000021, 6.128296283000168],
                [1.251771548000022, 6.128714045999971],
                [1.255949174000023, 6.129828079000049],
                [1.262633375000178, 6.13094211300006],
                [1.27446997900006, 6.131916892000163],
                [1.287281362000044, 6.132056146000139],
                [1.287083000000109, 6.133750000000134],
                [1.2873609990001, 6.134028],
                [1.287917001000096, 6.133750000000134],
                [1.291805001000114, 6.133750000000134],
                [1.291805001000114, 6.134306],
                [1.290138999000135, 6.134306],
                [1.290138999000135, 6.134583999000085],
                [1.288195000000144, 6.134583999000085],
                [1.288195000000144, 6.13486000100005],
                [1.287083000000109, 6.13486000100005],
                [1.287083000000109, 6.134583999000085],
                [1.284664849000137, 6.134529739000072],
                [1.284664810000038, 6.134024745000033],
                [1.273773708000078, 6.13400570500005],
                [1.273700882000071, 6.13597199899999],
                [1.275416, 6.13597199899999],
                [1.275416, 6.136250001000121],
                [1.276250000000175, 6.136250001000121],
                [1.276250000000175, 6.13597199899999],
                [1.284305000000131, 6.13597199899999],
                [1.284305000000131, 6.136528000000112],
                [1.283751001000042, 6.136528000000112],
                [1.283751001000042, 6.137084999000081],
                [1.283195000000148, 6.137084999000081],
                [1.283195000000148, 6.139304],
                [1.284029, 6.139860999000121],
                [1.284029, 6.140139001000023],
                [1.284583, 6.140695000000108],
                [1.285139001000118, 6.140695000000108],
                [1.285139001000118, 6.141250999000022],
                [1.285695, 6.141250999000022],
                [1.285973000000126, 6.1415270010001],
                [1.285973000000126, 6.142639001000077],
                [1.286248999000122, 6.142639001000077],
                [1.286248999000122, 6.143750999000076],
                [1.286527001000025, 6.144305],
                [1.287083000000109, 6.144583],
                [1.288473, 6.144583],
                [1.289027001000136, 6.144305],
                [1.289027001000136, 6.143750999000076],
                [1.289583, 6.143750999000076],
                [1.289583, 6.139304],
                [1.289305001000059, 6.138751001000117],
                [1.289305001000059, 6.137638001000028],
                [1.290138999000135, 6.137638001000028],
                [1.290695, 6.138472998999987],
                [1.290695, 6.142083000000184],
                [1.290971000000127, 6.142083000000184],
                [1.290971000000127, 6.145138999000153],
                [1.290695, 6.145417001000055],
                [1.291526999000041, 6.146249],
                [1.291805001000114, 6.146250999000188],
                [1.292361, 6.146805001000189],
                [1.292361, 6.147361000000103],
                [1.292918001000032, 6.147638999000094],
                [1.293749000000105, 6.147638999000094],
                [1.293749000000105, 6.147917001000167],
                [1.294862, 6.147917001000167],
                [1.294862, 6.147638999000094],
                [1.295694001000186, 6.147638999000094],
                [1.295972000000177, 6.148195000000158],
                [1.296805999000185, 6.148195000000158],
                [1.296805999000185, 6.148473],
                [1.29875, 6.148473],
                [1.299862000000189, 6.149026999000171],
                [1.299862000000189, 6.149305001000073],
                [1.300972001000105, 6.149305001000073],
                [1.301528000000189, 6.149583],
                [1.301528000000189, 6.149861000000158],
                [1.302083999000104, 6.150139],
                [1.303472001000159, 6.150139],
                [1.303750001000083, 6.15069300100015],
                [1.305138000000113, 6.15069300100015],
                [1.306250000999967, 6.151249],
                [1.306529, 6.151805999000089],
                [1.307082, 6.152084001000162],
                [1.308195, 6.152084001000162],
                [1.308751001000132, 6.152362000000153],
                [1.309583000000146, 6.152362000000153],
                [1.309583000000146, 6.15264],
                [1.310139001000096, 6.152914999000188],
                [1.310973, 6.152917999000067],
                [1.311527001000172, 6.153194001000145],
                [1.311527001000172, 6.153472001000068],
                [1.312917001000074, 6.153472001000068],
                [1.312917001000074, 6.15375],
                [1.313472986000136, 6.154027939000059],
                [1.314304947000153, 6.154027939000059],
                [1.314304947000153, 6.154305934000035],
                [1.326562453000065, 6.160063679000132],
                [1.332268388000102, 6.161341126000025],
                [1.336526547000062, 6.163044391000028],
                [1.346248984000056, 6.165137767000033],
                [1.347082973000056, 6.16519130800009],
                [1.347640037000133, 6.165417195000032],
                [1.347640037000133, 6.165973186000144],
                [1.348749996000095, 6.165973186000144],
                [1.348749996000095, 6.166251184000032],
                [1.349861979000139, 6.166251184000032],
                [1.349861979000139, 6.166529179000065],
                [1.351250053000058, 6.166529179000065],
                [1.351250053000058, 6.166807175000145],
                [1.352084040000079, 6.166807175000145],
                [1.352084040000079, 6.16708278800013],
                [1.353193997000176, 6.16708278800013],
                [1.353193997000176, 6.167638779000185],
                [1.354305982000028, 6.167638779000185],
                [1.354583980000143, 6.168194770000071],
                [1.355694055000072, 6.168194770000071],
                [1.355694055000072, 6.168472768000186],
                [1.356806039000048, 6.168472768000186],
                [1.356806039000048, 6.168750763000162],
                [1.357638001000169, 6.168750763000162],
                [1.357638001000169, 6.169026852],
                [1.359027981000054, 6.169026852],
                [1.359027981000054, 6.169304847000035],
                [1.35985994400005, 6.169304847000035],
                [1.35985994400005, 6.169582845000093],
                [1.361528993000036, 6.169582845000093],
                [1.361528993000036, 6.169860840000126],
                [1.362638950000132, 6.169860840000126],
                [1.362638950000132, 6.170416832000115],
                [1.363751054000147, 6.170416832000115],
                [1.364029050000056, 6.170972824999979],
                [1.365139007000096, 6.170972824999979],
                [1.365695000000187, 6.171248913000113],
                [1.365695000000187, 6.171804904],
                [1.366804957000113, 6.171804904],
                [1.366804957000113, 6.172082902000113],
                [1.368195057000037, 6.172082902000113],
                [1.368749023000021, 6.172638893000169],
                [1.369861006000122, 6.172638893000169],
                [1.369861006000122, 6.172916889000078],
                [1.370970965000026, 6.172916889000078],
                [1.370972990000098, 6.173194884999987],
                [1.372360945000082, 6.173194884999987],
                [1.37263894099999, 6.173748970000133],
                [1.373196007000104, 6.174026966000042],
                [1.374305009000182, 6.174026966000042],
                [1.374305009000182, 6.174304962000122],
                [1.375417948000177, 6.174304962000122],
                [1.375692964, 6.174860954999986],
                [1.377084017000072, 6.174860954999986],
                [1.377084017000072, 6.175138950000189],
                [1.37791597800009, 6.175138950000189],
                [1.37791597800009, 6.175416946000098],
                [1.379583954000054, 6.176249027000097],
                [1.38041603500011, 6.176249027000097],
                [1.38041603500011, 6.176527023000176],
                [1.38125002400011, 6.176527023000176],
                [1.381806016000098, 6.176805019000085],
                [1.381806016000098, 6.177083016000097],
                [1.38319397000015, 6.177083016000097],
                [1.38319397000015, 6.177361012000176],
                [1.38569402700017, 6.177361012000176],
                [1.38569402700017, 6.177639962000171],
                [1.386528013999964, 6.177639962000171],
                [1.386806965000062, 6.178195954000159],
                [1.388195037000173, 6.178195954000159],
                [1.388195037000173, 6.178471089000084],
                [1.389304042000163, 6.178471089000084],
                [1.389304042000163, 6.17874908499999],
                [1.390694977000067, 6.17874908499999],
                [1.391805054000145, 6.179306031000067],
                [1.392361045000087, 6.179862022000179],
                [1.392361045000087, 6.180140020000067],
                [1.392917036000142, 6.180416108000031],
                [1.396805048000147, 6.180416108000031],
                [1.396805048000147, 6.180694103000178],
                [1.398195028000032, 6.180694103000178],
                [1.398748994000073, 6.180972099000087],
                [1.398748994000073, 6.181250097000032],
                [1.400138974000129, 6.181250097000032],
                [1.400138974000129, 6.181528092000178],
                [1.401249051000036, 6.181528092000178],
                [1.401249051000036, 6.181250097000032],
                [1.402361989000099, 6.181250097000032],
                [1.402361989000099, 6.181528092000178],
                [1.403193950000116, 6.181528092000178],
                [1.403193950000116, 6.181806088000087],
                [1.404306055, 6.181806088000087],
                [1.404306055, 6.182084083000063],
                [1.405694007000136, 6.182084083000063],
                [1.405694007000136, 6.182362078999972],
                [1.406805992000045, 6.182362078999972],
                [1.406805992000045, 6.182640077000087],
                [1.407637953, 6.182640077000087],
                [1.407637953, 6.18291616499999],
                [1.409306049000179, 6.18291616499999],
                [1.409306049000179, 6.183194160000028],
                [1.410138011000129, 6.183194160000028],
                [1.41069400399999, 6.183472156000107],
                [1.41069400399999, 6.183750154000165],
                [1.411249995000105, 6.184028149000028],
                [1.41180598599999, 6.184028149000028],
                [1.412081957, 6.184584141000187],
                [1.412915946000169, 6.184584141000187],
                [1.412915946000169, 6.184862136000163],
                [1.413750052000125, 6.184862136000163],
                [1.413750052000125, 6.185138226000106],
                [1.414582014000189, 6.185138226000106],
                [1.414582014000189, 6.185416222000185],
                [1.41513800700011, 6.185694218000094],
                [1.415972949000093, 6.185694218000094],
                [1.416529060000187, 6.185972213000071],
                [1.416529060000187, 6.186250211000186],
                [1.417639017000113, 6.186250211000186],
                [1.417917013000022, 6.186806202000071],
                [1.419026972000097, 6.186806202000071],
                [1.419026972000097, 6.18708419799998],
                [1.419582963000153, 6.187362193000183],
                [1.42152702900006, 6.187362193000183],
                [1.42152702900006, 6.187637806000168],
                [1.422361016000082, 6.187637806000168],
                [1.422361016000082, 6.187915802000077],
                [1.423195005000082, 6.187915802000077],
                [1.423470973000178, 6.188471795000169],
                [1.424582958000087, 6.188471795000169],
                [1.424582958000087, 6.188749791000078],
                [1.426527023000062, 6.188749791000078],
                [1.426527023000062, 6.189027786000054],
                [1.427639961000125, 6.189027786000054],
                [1.427639961000125, 6.189305782000133],
                [1.429306031000124, 6.189305782000133],
                [1.429306031000124, 6.189581872000076],
                [1.430140018000145, 6.189581872000076],
                [1.430140018000145, 6.189859868000156],
                [1.431527973000129, 6.189859868000156],
                [1.432083964000071, 6.190137863000132],
                [1.432083964000071, 6.190415859000041],
                [1.432639957000106, 6.19069385500012],
                [1.43375003400007, 6.19069385500012],
                [1.43375003400007, 6.190971852000132],
                [1.435137987000076, 6.190971852000132],
                [1.435137987000076, 6.191250801000024],
                [1.435971976000019, 6.191250801000024],
                [1.435971976000019, 6.191528798000036],
                [1.437360049000063, 6.191528798000036],
                [1.437916040000118, 6.191803932000084],
                [1.43819403700013, 6.192359925000176],
                [1.438750029000119, 6.192638875000114],
                [1.439859986000045, 6.192638875000114],
                [1.439859986000045, 6.192916871000023],
                [1.441807032000043, 6.192916871000023],
                [1.441807032000043, 6.193194866000056],
                [1.442638993, 6.193194866000056],
                [1.442638993, 6.193472862000135],
                [1.443472982, 6.193472862000135],
                [1.443472982, 6.193750858000044],
                [1.444583059000138, 6.193750858000044],
                [1.444583059000138, 6.194028855000056],
                [1.447082997, 6.194028855000056],
                [1.447082997, 6.194304943000134],
                [1.448748947000183, 6.194304943000134],
                [1.448748947000183, 6.194582939000043],
                [1.451804996000021, 6.194582939000043],
                [1.452360987000077, 6.194860935000122],
                [1.452360987000077, 6.195138932000134],
                [1.453470945999982, 6.195138932000134],
                [1.453470945999982, 6.195416928000043],
                [1.454027057000076, 6.195694924000122],
                [1.456805945000156, 6.195694924000122],
                [1.456805945000156, 6.195972919000155],
                [1.457640052000158, 6.195972919000155],
                [1.457916022000063, 6.196528913000122],
                [1.458472013000176, 6.19680500100003],
                [1.460139991000176, 6.19680500100003],
                [1.460139991000176, 6.197082996000063],
                [1.461249948000045, 6.197082996000063],
                [1.461249948000045, 6.197360992000142],
                [1.462362050000081, 6.197360992000142],
                [1.462362050000081, 6.19763899000003],
                [1.463472009000157, 6.19763899000003],
                [1.463472009000157, 6.197916985000063],
                [1.464305996000178, 6.197916985000063],
                [1.464859962000162, 6.198194981000142],
                [1.465137958000071, 6.198749067000165],
                [1.465693951000162, 6.198749067000165],
                [1.466249942000047, 6.199027062000141],
                [1.466249942000047, 6.19930505800005],
                [1.467638015000091, 6.19930505800005],
                [1.467638015000091, 6.199583053000026],
                [1.469861031000164, 6.199583053000026],
                [1.469861031000164, 6.199861049000162],
                [1.470695018000129, 6.199861049000162],
                [1.470695018000129, 6.20013904700005],
                [1.471529007000129, 6.20013904700005],
                [1.471804977000033, 6.200695038000106],
                [1.472916960000134, 6.200695038000106],
                [1.472916960000134, 6.20097112600007],
                [1.474305035000157, 6.20097112600007],
                [1.474305035000157, 6.201249124000128],
                [1.475695015000042, 6.201249124000128],
                [1.476248979000047, 6.201527119000161],
                [1.476251006000098, 6.20180511500007],
                [1.476804972000139, 6.20208311100015],
                [1.477916956000115, 6.20208311100015],
                [1.477916956000115, 6.202361106000126],
                [1.478749036000068, 6.202361106000126],
                [1.478749036000068, 6.20263910400007],
                [1.480139018000102, 6.20263910400007],
                [1.480139018000102, 6.20291710000015],
                [1.480970979000119, 6.20291710000015],
                [1.480970979000119, 6.203195095000126],
                [1.481804966000141, 6.203195095000126],
                [1.482362032000026, 6.204027177000057],
                [1.483471989000122, 6.204027177000057],
                [1.483471989000122, 6.204305172000034],
                [1.485139965000087, 6.204305172000034],
                [1.485139965000087, 6.204583168000113],
                [1.485972047000189, 6.204583168000113],
                [1.485972047000189, 6.204862118000108],
                [1.490972041000021, 6.204862118000108],
                [1.4920840260001, 6.20541810900005],
                [1.4920840260001, 6.205696107000108],
                [1.492637992000084, 6.205970765000132],
                [1.493749976000061, 6.205970765000132],
                [1.493749976000061, 6.206250191000152],
                [1.494307042000173, 6.206528186000128],
                [1.495138049000104, 6.206528186000128],
                [1.495138049000104, 6.206806184000016],
                [1.496250988000099, 6.206806184000016],
                [1.496250988000099, 6.207084180000152],
                [1.497360944999969, 6.207084180000152],
                [1.497360944999969, 6.207362175000128],
                [1.500416994000148, 6.207362175000128],
                [1.500416994000148, 6.207640171000037],
                [1.500972987000068, 6.207918167000116],
                [1.504027010000073, 6.207918167000116],
                [1.504027010000073, 6.208193779000169],
                [1.506804943000134, 6.208193779000169],
                [1.506804943000134, 6.207918167000116],
                [1.507639050000137, 6.207918167000116],
                [1.507639050000137, 6.208193779000169],
                [1.510972023000136, 6.208193779000169],
                [1.510972023000136, 6.208471775000078],
                [1.511806012000136, 6.208471775000078],
                [1.512362003000021, 6.208749770999987],
                [1.512362003000021, 6.209027768000169],
                [1.514027954000142, 6.209027768000169],
                [1.514027954000142, 6.208749770999987],
                [1.514583945000027, 6.208193779000169],
                [1.514861941000106, 6.208193779000169],
                [1.514861941000106, 6.207362175000128],
                [1.515416026000025, 6.207084180000152],
                [1.515416026000025, 6.205970765000132],
                [1.515694022000105, 6.205970765000132],
                [1.515694022000105, 6.204305172000034],
                [1.515972018000014, 6.204305172000034],
                [1.515972018000014, 6.203195095000126],
                [1.516250015000026, 6.203195095000126],
                [1.516250015000026, 6.201527119000161],
                [1.516528011000105, 6.201527119000161],
                [1.516806006000138, 6.20097112600007],
                [1.516806006000138, 6.19930505800005],
                [1.516528011000105, 6.19930505800005],
                [1.516528011000105, 6.19763899000003],
                [1.517361998000126, 6.198194981000142],
                [1.517637968000031, 6.198749067000165],
                [1.515972018000014, 6.206250191000152],
                [1.515972018000014, 6.208471775000078],
                [1.515694022000105, 6.208471775000078],
                [1.515694022000105, 6.209305764000078],
                [1.515972018000014, 6.209584237000115],
                [1.517084002000047, 6.209584237000115],
                [1.517084002000047, 6.209862233000024],
                [1.521528960000126, 6.209862233000024],
                [1.521528960000126, 6.210137845000077],
                [1.523473024, 6.210137845000077],
                [1.523473024, 6.210415840999985],
                [1.524304985000185, 6.210415840999985],
                [1.524304985000185, 6.210693836000189],
                [1.525416970000094, 6.210693836000189],
                [1.525416970000094, 6.210971832000098],
                [1.526250959000095, 6.210971832000098],
                [1.526250959000095, 6.211249828000177],
                [1.528195023000023, 6.211249828000177],
                [1.528195023000023, 6.211527825000189],
                [1.530138969000063, 6.211527825000189],
                [1.530138969000063, 6.211805821000098],
                [1.531249046000028, 6.212361812000154],
                [1.531805039000119, 6.212361812000154],
                [1.532361030000175, 6.212637902000097],
                [1.532361030000175, 6.212915898000176],
                [1.534026981000068, 6.212915898000176],
                [1.534304976000101, 6.213749885000027],
                [1.535140038000066, 6.213749885000027],
                [1.535140038000066, 6.214027883000085],
                [1.536527991000071, 6.214027883000085],
                [1.536527991000071, 6.214305878000062],
                [1.537361980000071, 6.214305878000062],
                [1.537361980000071, 6.21458387399997],
                [1.538193941000088, 6.21458387399997],
                [1.538750052000182, 6.214859962000105],
                [1.539028048000091, 6.215415955000026],
                [1.539584041000182, 6.215693951000105],
                [1.54124999000004, 6.215693951000105],
                [1.54124999000004, 6.215971946000082],
                [1.543472051000151, 6.215971946000082],
                [1.543472051000151, 6.216249942000161],
                [1.54458403600006, 6.216249942000161],
                [1.54458403600006, 6.216527940000105],
                [1.547916054000041, 6.216527940000105],
                [1.548472048000065, 6.216805935000082],
                [1.548472048000065, 6.21708488600018],
                [1.550694942000121, 6.21708488600018],
                [1.551251053000044, 6.217360019000125],
                [1.551805019000028, 6.217916011999989],
                [1.552361012000119, 6.218194008000069],
                [1.552361012000119, 6.218472958000064],
                [1.553194999000141, 6.218472958000064],
                [1.553194999000141, 6.218750953999972],
                [1.554026961000034, 6.218750953999972],
                [1.554026961000034, 6.219028950000052],
                [1.555973052000013, 6.219028950000052],
                [1.555973052000013, 6.219304085000033],
                [1.557361007000054, 6.219304085000033],
                [1.557361007000054, 6.219582081000112],
                [1.559026957000185, 6.219582081000112],
                [1.559026957000185, 6.219861031000107],
                [1.56041705500013, 6.219861031000107],
                [1.56041705500013, 6.219582081000112],
                [1.562639952000097, 6.219582081000112],
                [1.562639952000097, 6.219861031000107],
                [1.565971970000078, 6.219861031000107],
                [1.567083955000157, 6.220417022000163],
                [1.567083955000157, 6.220695020000107],
                [1.568194032000122, 6.221251011000163],
                [1.569028021000065, 6.221251011000163],
                [1.569028021000065, 6.22152709900007],
                [1.571805954000183, 6.22152709900007],
                [1.571805954000183, 6.221805094999979],
                [1.572916031000091, 6.221805094999979],
                [1.572916031000091, 6.222083093000094],
                [1.574306011000147, 6.222083093000094],
                [1.574306011000147, 6.221805094999979],
                [1.575693966000188, 6.221805094999979],
                [1.575693966000188, 6.222083093000094],
                [1.57652902500007, 6.222083093000094],
                [1.576807023000129, 6.222639083999979],
                [1.577360986999963, 6.222639083999979],
                [1.577916980000055, 6.22347307300015],
                [1.578472971000167, 6.223751068000183],
                [1.579305053000098, 6.223751068000183],
                [1.579305053000098, 6.224027156000091],
                [1.58097302800013, 6.224027156000091],
                [1.581248999000138, 6.224583150000115],
                [1.582916974000057, 6.224583150000115],
                [1.582916974000057, 6.224305153999978],
                [1.584583045000159, 6.224305153999978],
                [1.584583045000159, 6.224027156000091],
                [1.585139036000044, 6.223751068000183],
                [1.58624899300014, 6.223751068000183],
                [1.586526991000028, 6.224027156000091],
                [1.586526991000028, 6.224861145000091],
                [1.586804986000061, 6.22513914100017],
                [1.587360978000049, 6.22513914100017],
                [1.587638973000026, 6.224861145000091],
                [1.587638973000026, 6.224027156000091],
                [1.588196039000138, 6.224305153999978],
                [1.589027048000048, 6.224305153999978],
                [1.589027048000048, 6.224861145000091],
                [1.589305044000128, 6.22513914100017],
                [1.591806054000131, 6.22513914100017],
                [1.591806054000131, 6.225417137000079],
                [1.594027995000033, 6.225417137000079],
                [1.594583988000125, 6.225695134000091],
                [1.594861984000033, 6.226251126000079],
                [1.59625005700002, 6.226251126000079],
                [1.596806050000112, 6.226527213999987],
                [1.596806050000112, 6.226805211000169],
                [1.597916007000038, 6.227361203000157],
                [1.599027992000117, 6.227361203000157],
                [1.599027992000117, 6.22763919800019],
                [1.599859953000134, 6.22763919800019],
                [1.599859953000134, 6.227917194000099],
                [1.600693942000078, 6.227917194000099],
                [1.600693942000078, 6.228195191000054],
                [1.601528049000081, 6.228195191000054],
                [1.601528049000081, 6.228470802000061],
                [1.606528998000101, 6.228470802000061],
                [1.60899814600009, 6.230846504000169],
                [1.610586486000045, 6.231613289000109],
                [1.613872706, 6.232489613000155],
                [1.61781617000014, 6.233585021000181],
                [1.6213762430001, 6.233913642000118],
                [1.627915978000033, 6.234583856000143],
                [1.628193974000112, 6.235139847000028],
                [1.630127974000118, 6.235139847000028],
                [1.629444, 6.236389],
                [1.634167000000161, 6.238333000000182],
                [1.639722, 6.239444000000106],
                [1.644167000000152, 6.242222],
                [1.648333, 6.244722],
                [1.6525, 6.247500000000173],
                [1.656667, 6.25],
                [1.662222, 6.25],
                [1.667500000000189, 6.248333000000173],
                [1.672500000000184, 6.250278000000151],
                [1.675278000000162, 6.254167],
                [1.67888900000014, 6.257500000000107],
                [1.684444, 6.258889000000124],
                [1.689167, 6.256667000000107],
                [1.693889000000127, 6.255833],
                [1.698056000000122, 6.258333000000107],
                [1.703056, 6.260556],
                [1.708333, 6.258889000000124],
                [1.714722, 6.259444],
                [1.718889, 6.261944000000142],
                [1.7225, 6.265278000000137],
                [1.728611000000115, 6.265833],
                [1.734167000000127, 6.264444],
                [1.739444000000106, 6.264167],
                [1.743056, 6.2675],
                [1.747222, 6.270278],
                [1.752778, 6.27138900000017],
                [1.759722000000124, 6.271111],
                [1.765278000000137, 6.271111],
                [1.77, 6.273056000000111],
                [1.774167, 6.276111],
                [1.778611000000183, 6.278889000000106],
                [1.784722000000102, 6.279444000000183],
                [1.790833000000134, 6.278611000000183],
                [1.797222000000147, 6.278889000000106],
                [1.8025, 6.280278],
                [1.807465131000072, 6.282545072000062],
                [1.80671200200004, 6.284195001000057],
                [1.807178994000083, 6.284424505000061],
                [1.807527000000107, 6.286359999000126],
                [1.805496000000119, 6.29101500000013],
                [1.8014510000001, 6.292412],
                [1.795649, 6.293630000000121],
                [1.789654, 6.302759000000151],
                [1.79790300000019, 6.306822],
                [1.798415, 6.313504999000088],
                [1.795056000000159, 6.322464],
                [1.792405, 6.328787999000042],
                [1.78695, 6.331940000000145],
                [1.78271500000011, 6.339139],
                [1.7842950000001, 6.339846],
                [1.787801000000115, 6.343898000000138],
                [1.789368, 6.34988],
                [1.785118000000182, 6.363936],
                [1.781930999000167, 6.37395],
                [1.779113000000166, 6.376404999000101],
                [1.772425000000112, 6.38025800000014],
                [1.770816, 6.391682000000174],
                [1.774325, 6.394152],
                [1.769216001000075, 6.399239],
                [1.769538000000182, 6.411547000000155],
                [1.775335, 6.412792000000138],
                [1.775856000000147, 6.415782],
                [1.775629999000159, 6.417553001000158],
                [1.777521, 6.417558],
                [1.780587, 6.421302],
                [1.780794000000128, 6.426797],
                [1.776393001000088, 6.42942299900011],
                [1.772667, 6.425897999000142],
                [1.764754, 6.427198000000146],
                [1.757708, 6.433553999000139],
                [1.753944999000055, 6.445192999000028],
                [1.753053, 6.45046600000012],
                [1.75436, 6.455083999000067],
                [1.753030000000138, 6.459697],
                [1.750823000000139, 6.464087],
                [1.744654, 6.470885000000124],
                [1.740689, 6.474831000000108],
                [1.736295000000155, 6.47438000000011],
                [1.733873001, 6.47635200000019],
                [1.737597, 6.480977],
                [1.735168001000034, 6.486025999000106],
                [1.730329999000162, 6.487552000000164],
                [1.72878, 6.491944001000036],
                [1.72899, 6.496120000000133],
                [1.727225998999984, 6.498533000000123],
                [1.725898999000151, 6.501825999000175],
                [1.722604000000104, 6.501598001000161],
                [1.721293001000106, 6.498518001000036],
                [1.719543001000091, 6.495436999000049],
                [1.716903, 6.49652900100017],
                [1.71249900000015, 6.500254],
                [1.708767, 6.498926000000154],
                [1.705914001000053, 6.4976],
                [1.703273, 6.499132000000145],
                [1.695579000000123, 6.50043],
                [1.694250000000125, 6.504602999000099],
                [1.694237001000033, 6.509657],
                [1.696865001000049, 6.512960999000086],
                [1.702359001000048, 6.512975],
                [1.701859000000184, 6.536269999000126],
                [1.695689001000119, 6.542847000000108],
                [1.698533000000111, 6.548129001000064],
                [1.696544, 6.552299],
                [1.696321001000172, 6.55339799900014],
                [1.687749999000118, 6.553595000000144],
                [1.683996, 6.560618],
                [1.67389900000012, 6.556196],
                [1.670823000000155, 6.555967999000131],
                [1.668400001000123, 6.557940000000144],
                [1.66640800100015, 6.563429],
                [1.67418399900015, 6.57300900100006],
                [1.675705000000107, 6.579605999000023],
                [1.674157001000026, 6.583119000000124],
                [1.670195, 6.585526000000186],
                [1.662725999000145, 6.58418699900011],
                [1.658997, 6.581540001000121],
                [1.65615, 6.577795999000159],
                [1.650654002000067, 6.578879999000151],
                [1.643616000000179, 6.580838999000036],
                [1.641413001000103, 6.582811001000152],
                [1.641036, 6.583085000000153],
                [1.636632, 6.600498999000138],
                [1.633054001000176, 6.602687],
                [1.627009000000157, 6.603218999000092],
                [1.621795, 6.60128200000014],
                [1.61848800100006, 6.604843999000082],
                [1.616007999000033, 6.607584],
                [1.612428001000126, 6.610871],
                [1.612960999000165, 6.616641001000062],
                [1.610747, 6.62240400100012],
                [1.61019099900011, 6.624875001000135],
                [1.608537001000059, 6.626793],
                [1.603032, 6.630349],
                [1.603014001000133, 6.636941001000139],
                [1.608228001000043, 6.638879],
                [1.611517999000114, 6.641634999000075],
                [1.603755, 6.666336999],
                [1.593564, 6.675373],
                [1.590242, 6.684153998999989],
                [1.579247999000131, 6.685496000000114],
                [1.577691001000119, 6.685706],
                [1.573793001000183, 6.688854000000163],
                [1.576526, 6.693805999000176],
                [1.581199, 6.69327000100003],
                [1.584747001000039, 6.701522],
                [1.589689001000181, 6.702909],
                [1.592152000000169, 6.706213],
                [1.591021001000115, 6.717198000000167],
                [1.592920001000039, 6.725994001000117],
                [1.600064001000135, 6.726013999000145],
                [1.608286000000135, 6.733729000000153],
                [1.614044000000149, 6.737865999000064],
                [1.61953, 6.741452999000046],
                [1.620891999000037, 6.745578],
                [1.618129001, 6.750789],
                [1.620589, 6.755466000000126],
                [1.624993000000131, 6.759647001000133],
                [1.625876001000108, 6.759826001000022],
                [1.622084000000143, 6.764623001000075],
                [1.608327001000077, 6.770421000000113],
                [1.608649998999965, 6.777633000000151],
                [1.610006999000177, 6.783474],
                [1.613419999000143, 6.791382000000169],
                [1.611338999000054, 6.798244001000114],
                [1.608929001000149, 6.799953999000024],
                [1.598621, 6.800611000000117],
                [1.596887, 6.806442999000126],
                [1.598930000000166, 6.81262999900008],
                [1.604071001000023, 6.816765001000078],
                [1.608188, 6.818836999000098],
                [1.615040000000136, 6.825380999000117],
                [1.613312000000178, 6.82881],
                [1.611595, 6.828461999000126],
                [1.603698000000122, 6.827065999000126],
                [1.599561000000165, 6.831860999000185],
                [1.601259999000149, 6.838390000000118],
                [1.601905001000034, 6.852814],
                [1.595689001000153, 6.86378400000018],
                [1.59464700000018, 6.867558000000145],
                [1.596348001000138, 6.873400000000174],
                [1.606626000000119, 6.883388],
                [1.603857001000051, 6.890591],
                [1.605555001000028, 6.897119999000154],
                [1.605185999000071, 6.906046999000182],
                [1.592804000000115, 6.910131000000149],
                [1.582477999000048, 6.915937999000164],
                [1.576687999000058, 6.922085000000152],
                [1.575690999000187, 6.946515999000155],
                [1.567658000000165, 6.960573999000133],
                [1.559123, 6.971118000000104],
                [1.559625001000143, 6.984674001000087],
                [1.557616, 6.99521799900009],
                [1.557638001000044, 6.998007],
                [1.587361001000147, 6.997604999000089],
                [1.587360999000111, 6.994793000000186],
                [1.590976000000183, 6.9939900010001],
                [1.598207, 6.9939900010001],
                [1.607444999000109, 6.995597],
                [1.641587001000119, 6.9939900010001],
                [1.64110658200002, 7.013391751000142],
                [1.639771999, 7.06728900000013],
                [1.63939900000014, 7.07591899900018],
                [1.637760001000174, 7.120060000000137],
                [1.637512998999966, 7.125461],
                [1.63757399900004, 7.125537001000112],
                [1.637557853000146, 7.128449852000131],
                [1.637173002000168, 7.197436],
                [1.637615000000153, 7.205802999000071],
                [1.63757399900004, 7.205871001000162],
                [1.636771000000124, 7.246842],
                [1.636537000000146, 7.247918],
                [1.636370001000103, 7.262507001000074],
                [1.63631099700018, 7.271527236000054],
                [1.635968002000141, 7.323961999000062],
                [1.635968000000162, 7.345373001000041],
                [1.635968000000162, 7.391041],
                [1.634918999000092, 7.400688000000116],
                [1.63565200100004, 7.4166080010001],
                [1.642079, 7.468342000000177],
                [1.644007, 7.485695001000067],
                [1.648184001000061, 7.492442999000105],
                [1.654290000000174, 7.494370999000068],
                [1.675498, 7.494370999000068],
                [1.677426, 7.497584000000131],
                [1.677104999000051, 7.501119],
                [1.675498, 7.506581],
                [1.670355999000151, 7.513007999000081],
                [1.669071000000145, 7.515579001000162],
                [1.66875, 7.518149000000108],
                [1.660074001000112, 7.52072],
                [1.6592539990001, 7.521287],
                [1.65300400000018, 7.535662001000048],
                [1.651398000000143, 7.542732000000171],
                [1.649470000000122, 7.548194],
                [1.649148000000139, 7.558798],
                [1.650755001000164, 7.61342500000012],
                [1.636938001000033, 7.641381999000032],
                [1.631475, 7.661304],
                [1.63115400099997, 7.675763999000026],
                [1.630903000000103, 7.67660599900006],
                [1.632158, 7.711124000000154],
                [1.632785001000116, 7.731835001000036],
                [1.635924001000149, 7.796478999000101],
                [1.635296000000153, 7.81028600000019],
                [1.629647001000024, 7.826603999000042],
                [1.631530000000112, 7.852336000999969],
                [1.632158, 7.886226998999973],
                [1.634040999000149, 7.896896],
                [1.634040999000149, 7.903171999000108],
                [1.632716999000024, 7.917295001000184],
                [1.633639000000187, 7.917369000000178],
                [1.634040999000149, 7.920984000000146],
                [1.634844, 7.956129999000154],
                [1.636051377, 7.991617259000066],
                [1.63946844000003, 8.193212167000127],
                [1.641271000000131, 8.299557001000096],
                [1.641271000000131, 8.30036],
                [1.638056999000071, 8.327674000000115],
                [1.636851998999987, 8.33972399900017],
                [1.630425999000181, 8.343741001000183],
                [1.625318, 8.351598],
                [1.626007000000186, 8.357799],
                [1.626007000000186, 8.367037],
                [1.626810001000081, 8.379890999],
                [1.629622000000154, 8.39234299899999],
                [1.633237001000055, 8.403991001000122],
                [1.637254000000155, 8.41041800100004],
                [1.643681, 8.413229000000172],
                [1.646091000000183, 8.417246],
                [1.645287, 8.424074001000179],
                [1.64327900100011, 8.427288],
                [1.639664001000142, 8.432509999000047],
                [1.640869001, 8.43974],
                [1.642876999000066, 8.455003000000147],
                [1.646091000000183, 8.467455000000143],
                [1.646401, 8.469837],
                [1.650547001000177, 8.478289999000026],
                [1.650192, 8.478432000000112],
                [1.649507002000178, 8.481172000000186],
                [1.649164, 8.490761],
                [1.641287001000137, 8.503433000000143],
                [1.637504, 8.508925],
                [1.633007016000079, 8.587033777000102],
                [1.630328000000134, 8.633565999000098],
                [1.630328000000134, 8.63562800000011],
                [1.628615999000147, 8.647614],
                [1.628616001000125, 8.65069699899999],
                [1.629301, 8.654806001000168],
                [1.630328000000134, 8.661312999000131],
                [1.631355, 8.673985001000062],
                [1.631698001000075, 8.679463999000063],
                [1.632212001000141, 8.6953899990001],
                [1.632554, 8.722102999000185],
                [1.632554, 8.750185001000034],
                [1.632554, 8.778952999000182],
                [1.63323900100005, 8.78409],
                [1.633582, 8.796076999000036],
                [1.632896999000138, 8.799844],
                [1.632896999000138, 8.804981001000101],
                [1.632554, 8.81183100100003],
                [1.631868999000176, 8.816625001000034],
                [1.631698001000075, 8.816795999000135],
                [1.631355, 8.820906001000026],
                [1.628958001, 8.830495001000088],
                [1.631013000000166, 8.839057001000128],
                [1.631698001000075, 8.847618999000133],
                [1.632726001000037, 8.85241399900002],
                [1.632726001000037, 8.860291001],
                [1.630328000000134, 8.880153999000186],
                [1.628615999000147, 8.895564999000044],
                [1.626903001000187, 8.907894000000113],
                [1.626561, 8.914401],
                [1.625666001000127, 8.921263000000124],
                [1.625876001000108, 8.921936000000187],
                [1.623479, 8.945566999000107],
                [1.622109001000183, 8.95515599900017],
                [1.625876001000108, 8.972279],
                [1.623821001000067, 8.974677001000089],
                [1.62793100100015, 8.99077300099998],
                [1.628615999000147, 8.995567999000059],
                [1.629177, 8.998374001000059],
                [1.629986, 8.998992002000136],
                [1.626219000000162, 9.025363001000073],
                [1.624506000000167, 9.042144],
                [1.615602, 9.062692999000035],
                [1.617657000000122, 9.0715970010001],
                [1.609542000000147, 9.088150001000145],
                [1.609436999000081, 9.088720999000145],
                [1.604642999000078, 9.099336999000116],
                [1.598136001000114, 9.110981001000027],
                [1.591971002000093, 9.117146],
                [1.583067, 9.127420001000075],
                [1.578957001000049, 9.131871999000168],
                [1.572792, 9.144886],
                [1.567997999000113, 9.153105999000104],
                [1.56354600100002, 9.161666999000033],
                [1.559435999000129, 9.169887],
                [1.553613999000163, 9.175023999000132],
                [1.550873999000089, 9.176736000000119],
                [1.544367001000126, 9.180161001000045],
                [1.536148, 9.18495600100016],
                [1.528613999000015, 9.189750001000164],
                [1.526323999000113, 9.190436999000156],
                [1.525868000000116, 9.190947999000173],
                [1.508598001000166, 9.208374],
                [1.503764001000093, 9.21320899900013],
                [1.495967001000111, 9.22085],
                [1.489885, 9.227633002000118],
                [1.485207, 9.23433800000015],
                [1.478658001000042, 9.238705000000152],
                [1.475420999000107, 9.24077099900012],
                [1.471678999000119, 9.244747999000026],
                [1.462011001000064, 9.254727999000124],
                [1.459905000000163, 9.262056999000094],
                [1.452966000000174, 9.274766000000113],
                [1.450159001000145, 9.278898],
                [1.444623000000149, 9.284278],
                [1.444546001000049, 9.284207],
                [1.436966, 9.293004001000043],
                [1.431143002000169, 9.296549],
                [1.421417001000066, 9.306945],
                [1.418072001000041, 9.312973001000103],
                [1.413042000000132, 9.32448800100019],
                [1.413074000000108, 9.325017000000173],
                [1.407869001000108, 9.335027],
                [1.403064, 9.355048000000181],
                [1.402663001000178, 9.371064002000026],
                [1.402663001000178, 9.381474999000147],
                [1.401462000000151, 9.389083001000074],
                [1.39986000000016, 9.39508900100003],
                [1.401062001000184, 9.399894000000131],
                [1.401862, 9.405499],
                [1.401862, 9.413107],
                [1.401462000000151, 9.416711001000067],
                [1.39986000000016, 9.422317],
                [1.39986000000016, 9.428323],
                [1.400660999000081, 9.434729],
                [1.400261000000114, 9.444339],
                [1.398259001000099, 9.449945000000127],
                [1.398259001000099, 9.453948999000033],
                [1.398214, 9.453996000000132],
                [1.394773, 9.479964],
                [1.38976700000012, 9.497172],
                [1.375375, 9.49122700100014],
                [1.365363000000116, 9.4855960000001],
                [1.362235000000112, 9.486221001000047],
                [1.360650001000124, 9.487014],
                [1.35854200100016, 9.490175],
                [1.359343, 9.507793000000106],
                [1.347732, 9.536622000000136],
                [1.342526001000124, 9.545831999000029],
                [1.343010001000039, 9.548898000000122],
                [1.35854200100016, 9.588675],
                [1.366551000000129, 9.611499],
                [1.366551002000165, 9.649937999000088],
                [1.369753999000068, 9.661550001000023],
                [1.366150000000175, 9.682771999000181],
                [1.365750001000038, 9.683572],
                [1.363347, 9.697185999000169],
                [1.365349999000159, 9.727217],
                [1.366150000000175, 9.73963],
                [1.366097, 9.739895001000036],
                [1.366754, 9.750815001000092],
                [1.36611900000014, 9.806606001000034],
                [1.365921999000079, 9.807447001000185],
                [1.366478000000143, 9.807327001000033],
                [1.366400999000064, 9.823165000000131],
                [1.365207566000038, 9.853214359000162],
                [1.363914, 9.885784999000123],
                [1.366421, 9.947709000000145],
                [1.366856999000163, 9.974144999000146],
                [1.366149000000121, 10.002263],
                [1.365115001000106, 10.00327699899998],
                [1.364112999000156, 10.012135],
                [1.341565001000049, 10.024984],
                [1.310982001000184, 10.042406],
                [1.157397001000049, 10.129829000000143],
                [1.134927001000165, 10.142609000999983],
                [1.001665001000049, 10.218342000000177],
                [0.99999, 10.220018001000028],
                [0.996769001000132, 10.215187],
                [0.954366000000107, 10.25061200000016],
                [0.9528110010001, 10.25143199900009],
                [0.953016001000037, 10.251986001000091],
                [0.937589001000106, 10.263824999000178],
                [0.901657, 10.289993],
                [0.888980095000022, 10.299225094000064],
                [0.871573001, 10.3119019990001],
                [0.859734, 10.320871000000125],
                [0.846459000000152, 10.331993001000114],
                [0.839283, 10.33881],
                [0.823496999000156, 10.351007999000103],
                [0.774345001000086, 10.385092000000157],
                [0.775062001000094, 10.392985],
                [0.775780000000111, 10.39836700100011],
                [0.775421, 10.405184],
                [0.774702999000056, 10.412001000000146],
                [0.775421, 10.4195350010001],
                [0.777932999000143, 10.443214],
                [0.777527, 10.443503998999972],
                [0.778470999000092, 10.444111001000124],
                [0.778470999000092, 10.448774999000022],
                [0.781340999000065, 10.473890000000154],
                [0.781340999000065, 10.474248000000102],
                [0.78241700000018, 10.483576999000093],
                [0.783135, 10.494699],
                [0.782059001000107, 10.497928],
                [0.782417002000159, 10.502592000000163],
                [0.783493283000155, 10.512631321000129],
                [0.78744000099999, 10.519454000000167],
                [0.791745001000038, 10.535598999000115],
                [0.798562001000107, 10.552103000999978],
                [0.80143200100008, 10.558920001000047],
                [0.805020001000116, 10.57398899900005],
                [0.806454998999982, 10.581163999000012],
                [0.807173000000148, 10.591209999000114],
                [0.807890000000157, 10.604842999000084],
                [0.808608001000152, 10.610225000000185],
                [0.808156001000043, 10.614590001000124],
                [0.809338, 10.625621001000127],
                [0.809811001000014, 10.640289],
                [0.810758000000135, 10.65590400100001],
                [0.811231001000124, 10.663474001],
                [0.811703999000144, 10.6738840000001],
                [0.812650001000122, 10.691390999000077],
                [0.813597000000186, 10.697069000000113],
                [0.815016000000185, 10.704639000000157],
                [0.819748, 10.710790000000145],
                [0.823533001000101, 10.719307000000185],
                [0.825426001000096, 10.727351000000112],
                [0.829684001000146, 10.738233],
                [0.83678100200018, 10.754794],
                [0.840567001000181, 10.758578999000122],
                [0.851923002000149, 10.775612998999975],
                [0.868009999000094, 10.79501300100003],
                [0.873688000000129, 10.806368000000134],
                [0.87747299900002, 10.825295000000153],
                [0.877371, 10.825325000000134],
                [0.879582002000177, 10.83756200099998],
                [0.881399001000148, 10.848766],
                [0.883519000000149, 10.856942],
                [0.885033, 10.861484000000189],
                [0.900780000000168, 10.886921999000037],
                [0.898054001000105, 10.89388599900019],
                [0.891089, 10.912964000000159],
                [0.890181001000087, 10.920232],
                [0.899568, 10.944760999000152],
                [0.900174000000106, 10.954148001000021],
                [0.906533, 10.97292300000015],
                [0.912287001000038, 10.996542999000042],
                [0.901083002000178, 10.995937999000091],
                [0.897751001000074, 10.998057001000063],
                [0.891695, 10.996542999000042],
                [0.868075001000136, 10.995332000000133],
                [0.854448000000161, 10.995332000000133],
                [0.848089000000186, 10.997452001000113],
                [0.830221999000059, 10.996542999000042],
                [0.81229437900015, 10.99667393600015],
                [0.788736001000132, 10.996845999000072],
                [0.787524001, 10.997148999000103],
                [0.76996, 10.996845999000072],
                [0.761481, 10.996542999000042],
                [0.755122, 10.997148999000103],
                [0.741495000000157, 10.99623999900001],
                [0.731805, 10.997148999000103],
                [0.714543999000171, 10.998360001000094],
                [0.704551000000151, 10.997753999000054],
                [0.696072, 10.997451999000134],
                [0.670938000999968, 10.997451999000134],
                [0.65943, 10.998057001000063],
                [0.650043001000086, 10.998360001000094],
                [0.639141000000166, 10.999571],
                [0.626724999000032, 10.993515],
                [0.611281998999971, 10.984733000000176],
                [0.595232000000124, 10.978374],
                [0.590993001000129, 10.975951001000169],
                [0.58221099900004, 10.978979000000152],
                [0.575548998999977, 10.978677000000175],
                [0.550717, 10.967168999000023],
                [0.537696, 10.957782],
                [0.500752, 10.936584],
                [0.509231, 10.952633999],
                [0.509835999000131, 10.97776800000014],
                [0.50711100000018, 10.984733000000176],
                [0.494997999000077, 10.984733000000176],
                [0.491364, 10.986853],
                [0.497421001000021, 10.995029000000102],
                [0.502719999000021, 11.004264999000043],
                [0.5042340010001, 11.009413000000109],
                [0.503931001000069, 11.012744001000101],
                [0.444880999000077, 11.023343000000182],
                [0.413993000000175, 11.03272999900014],
                [0.399621001000185, 11.036065000000121],
                [0.392362, 11.037539],
                [0.362, 11.044244],
                [0.359261, 11.044742001000145],
                [0.332639999000037, 11.047575001000041],
                [0.314400001000024, 11.048660001000144],
                [0.303119001000141, 11.04982999900011],
                [0.296353000000124, 11.05024],
                [0.291528, 11.050425],
                [0.291228002000082, 11.050649999000086],
                [0.266626999000039, 11.056800001000056],
                [0.266540001000124, 11.056578],
                [0.265601999000125, 11.057004999000128],
                [0.205599391000078, 11.068776979000177],
                [0.191302001000167, 11.071582001000081],
                [0.190313000000117, 11.072288000000128],
                [0.169802001000051, 11.078431],
                [0.146084, 11.08448300100008],
                [0.100766999000143, 11.092948999000157],
                [0.096686, 11.094297],
                [0.074632000000179, 11.100126],
                [0.074451000000124, 11.09958],
                [0.071539, 11.100744001000123],
                [0.04308300100007, 11.108785999000133],
                [0.035510001000148, 11.111309],
                [0.012119, 11.113761999000076],
                [0.001746, 11.11453900000015],
                [-0.006473000999961, 11.115713],
                [-0.030737, 11.120796001000144],
                [-0.036134999999888, 11.122104],
                [-0.05465699899986, 11.12548899900014],
                [-0.07621, 11.128320000000144],
                [-0.085588, 11.129199999000036],
                [-0.085414998999966, 11.128785001000153],
                [-0.093511, 11.12929099900009],
                [-0.110050998999895, 11.133605999000054],
                [-0.115321, 11.135025001000088],
                [-0.125456999999869, 11.136849],
                [-0.131712000999869, 11.138039999000114],
                [-0.132583422999971, 11.136421415000143],
                [-0.134967871999891, 11.13706899000016],
                [-0.134878874999913, 11.136638848000132],
                [-0.134263415999953, 11.135891505000188],
                [-0.134722196999917, 11.134243998000045],
                [-0.134483222999961, 11.133327092000172],
                [-0.134190146999856, 11.13290213300013],
                [-0.133838455999978, 11.131803099000138],
                [-0.134154466999917, 11.131080932000145],
                [-0.134032809999894, 11.130350993000036],
                [-0.134204800999896, 11.129473147000056],
                [-0.134924956999896, 11.128972220000094],
                [-0.135479678999957, 11.128198268],
                [-0.136285637999947, 11.127436271000136],
                [-0.136681289999899, 11.126351891000127],
                [-0.137589823999861, 11.12598554699997],
                [-0.138073398999893, 11.1252235500001],
                [-0.13949481599991, 11.12487185800012],
                [-0.140344735999975, 11.124168477000126],
                [-0.14181881899998, 11.12341657800016],
                [-0.142021578999902, 11.122646087000078],
                [-0.142710964999935, 11.121551179000107],
                [-0.14315703799997, 11.120334616000036],
                [-0.143035381999937, 11.119239708000066],
                [-0.143238142999849, 11.118874738000045],
                [-0.143968080999912, 11.118753082000126],
                [-0.144009410999956, 11.117256763000057],
                [-0.143919511999911, 11.116747333000149],
                [-0.143380115999946, 11.115878306000127],
                [-0.143290215999969, 11.114260118000175],
                [-0.143709745999956, 11.113600857000051],
                [-0.143619847999901, 11.113241258000073],
                [-0.143080450999946, 11.112671896],
                [-0.142810752999935, 11.111623070000121],
                [-0.142571020999981, 11.109885017000067],
                [-0.14227135699997, 11.10931565400017],
                [-0.142391222999947, 11.108686359000046],
                [-0.142750819999947, 11.108146962000092],
                [-0.143589880999969, 11.107277935000013],
                [-0.143649813999957, 11.106588707000014],
                [-0.143038098999966, 11.10637764200004],
                [-0.142750819999947, 11.105509915000141],
                [-0.142391222999947, 11.105240216000084],
                [-0.141124826999942, 11.10502051200001],
                [-0.139247324999872, 11.10585612400007],
                [-0.138144080999894, 11.106539084000076],
                [-0.135632000999976, 11.106664687000091],
                [-0.134250356999928, 11.105785460000106],
                [-0.132429098999978, 11.105534252000098],
                [-0.13029383099996, 11.106099470000117],
                [-0.128598176999901, 11.10509463800014],
                [-0.1261236819999, 11.10516206900013],
                [-0.124757024999951, 11.10487129000012],
                [-0.123506679999821, 11.104173424000123],
                [-0.12202371199993, 11.102748611000095],
                [-0.121529388999818, 11.101818121000179],
                [-0.12036627699996, 11.101236565000022],
                [-0.119930109999927, 11.100625931000138],
                [-0.119493942999895, 11.099462819000166],
                [-0.119057775999863, 11.099172041000088],
                [-0.117225873999814, 11.099113885000065],
                [-0.115771983999934, 11.099142964000066],
                [-0.114841494999951, 11.098939419000033],
                [-0.113649303999921, 11.098125240000059],
                [-0.112864203999948, 11.097194750000028],
                [-0.111140385999875, 11.096374898000022],
                [-0.110152371999959, 11.096005632000015],
                [-0.108369480999954, 11.095651457000145],
                [-0.107115417999921, 11.095647746000111],
                [-0.106356764999873, 11.095366374000093],
                [-0.105316834999883, 11.094648031000077],
                [-0.10406399999988, 11.094243069000129],
                [-0.103307372999893, 11.093298773000129],
                [-0.102197020999938, 11.092510431000107],
                [-0.102130096999929, 11.091289061000111],
                [-0.101473785999872, 11.089973262000058],
                [-0.101005663999956, 11.089331838000135],
                [-0.100913225999932, 11.088849421000077],
                [-0.100273514999969, 11.088355097000033],
                [-0.099604724999949, 11.088267863999988],
                [-0.098994090999952, 11.088384176000034],
                [-0.098616079999942, 11.08823878600009],
                [-0.098092678999819, 11.087453686000117],
                [-0.097627434999936, 11.087017519000028],
                [-0.096958644999972, 11.086668585000098],
                [-0.094574264999949, 11.085825329000158],
                [-0.093585618999953, 11.085999796000124],
                [-0.091986340999938, 11.085650863000126],
                [-0.091317550999861, 11.085854407000056],
                [-0.091375705999951, 11.086377808000123],
                [-0.09096861799992, 11.086639508000076],
                [-0.089979971999924, 11.086174263000089],
                [-0.088124530999892, 11.086744459000158],
                [-0.087604754999973, 11.087064320000081],
                [-0.08636528999989, 11.08702433700006],
                [-0.085845513999971, 11.086864407000121],
                [-0.085445685999957, 11.086204691000091],
                [-0.0843261699999, 11.08578487200009],
                [-0.084026298999902, 11.085285088000148],
                [-0.083326600999953, 11.085165140000129],
                [-0.081367445999945, 11.084185563000176],
                [-0.080867661999946, 11.084065615000043],
                [-0.079708161999861, 11.084345493000114],
                [-0.078868524999905, 11.084805296000013],
                [-0.077749006999852, 11.084625373000165],
                [-0.076980826999886, 11.08490670499998],
                [-0.075780859999895, 11.084568085000058],
                [-0.074918426999943, 11.084732915000188],
                [-0.074323183999979, 11.085303292000049],
                [-0.073837089999927, 11.085433478000141],
                [-0.073586992999935, 11.085761586000103],
                [-0.072667202999924, 11.086191401000065],
                [-0.071705679999866, 11.08641174200011],
                [-0.071169235999889, 11.08625657400006],
                [-0.069703410999921, 11.085093699000026],
                [-0.069222649999858, 11.08520386500004],
                [-0.068094268999914, 11.084572358000116],
                [-0.067543170999897, 11.084584610999968],
                [-0.067160920999868, 11.084820684000078],
                [-0.067017943999872, 11.08535057500012],
                [-0.066325105999908, 11.085501949000047],
                [-0.065474891999827, 11.086266901999977],
                [-0.064570194999931, 11.086389704000055],
                [-0.064060416999951, 11.08673702599998],
                [-0.063057121999975, 11.08676182400012],
                [-0.061433722999936, 11.08628227100013],
                [-0.060232525999936, 11.086334357000169],
                [-0.058127348999847, 11.085848424],
                [-0.056912643999965, 11.086142569000117],
                [-0.05605109499993, 11.086028226000167],
                [-0.055457068999885, 11.08620779600011],
                [-0.054369089999909, 11.08620438800017],
                [-0.054281261999961, 11.087153126000146],
                [-0.054068599999937, 11.087375756000085],
                [-0.054151449999949, 11.087976127000104],
                [-0.053994048999868, 11.08858970000017],
                [-0.053397817999951, 11.089453106],
                [-0.053024738999966, 11.090346518000047],
                [-0.052571794999892, 11.090590722000172],
                [-0.052355438999939, 11.091081296000084],
                [-0.051675730999932, 11.091536918000145],
                [-0.051335066999968, 11.092015935000063],
                [-0.050552611999876, 11.092783846000088],
                [-0.049953651999886, 11.092735905000097],
                [-0.04996397899987, 11.093840811000064],
                [-0.049494310999819, 11.095182719000093],
                [-0.048823357999879, 11.095350457999984],
                [-0.048118855999974, 11.096021412000084],
                [-0.047783378999895, 11.096759461000147],
                [-0.047246615999939, 11.096591723000131],
                [-0.046971779999978, 11.096773596000162],
                [-0.046832193999933, 11.097369280000066],
                [-0.046387430999971, 11.097378332000119],
                [-0.046047469999905, 11.09772238100004],
                [-0.046247435999931, 11.098099513000022],
                [-0.046062597999935, 11.098554240000055],
                [-0.04610635399996, 11.099155359000122],
                [-0.045868613999858, 11.100629230000152],
                [-0.045347956999933, 11.101181873000144],
                [-0.044586438999886, 11.100907542000073],
                [-0.044172485999923, 11.1012095210001],
                [-0.043420400999878, 11.101290801000061],
                [-0.042676859999915, 11.10199960599999],
                [-0.042109059999916, 11.103180063000082],
                [-0.04079579099988, 11.1040852860001],
                [-0.040878966999912, 11.104556175000084],
                [-0.040200824999943, 11.10468997300012],
                [-0.03864399899993, 11.104758217000096],
                [-0.038503637999952, 11.105583975],
                [-0.038025657999924, 11.106032156000083],
                [-0.037453265999886, 11.10620811800004],
                [-0.036309679999931, 11.106194004000145],
                [-0.036498122999831, 11.106853479999984],
                [-0.036136993999946, 11.106933968000078],
                [-0.036051596999926, 11.107395882000105],
                [-0.035194290999925, 11.107267638000167],
                [-0.034230062999939, 11.107452796000189],
                [-0.033793501999867, 11.108183476000079],
                [-0.033124320999832, 11.108808826000143],
                [-0.032785948999901, 11.108661324000025],
                [-0.032232074999968, 11.109642623000127],
                [-0.031500661999871, 11.109870353000133],
                [-0.031109621999917, 11.109639013000162],
                [-0.029328841999927, 11.110177108000187],
                [-0.029041447999873, 11.110625887000083],
                [-0.028500982999901, 11.11074964300002],
                [-0.028149150999923, 11.111470128000065],
                [-0.02766190799997, 11.111510389000102],
                [-0.026169726999967, 11.111233650000031],
                [-0.025649709999925, 11.111577091000129],
                [-0.024601133999909, 11.11164690400011],
                [-0.024146873999882, 11.111321225000097],
                [-0.023594912999954, 11.111716849000118],
                [-0.022928556999886, 11.111484606000033],
                [-0.021879910999928, 11.11157532500016],
                [-0.021190112999932, 11.11202278800016],
                [-0.020233836999978, 11.11299229500014],
                [-0.020230955999978, 11.11342310900011],
                [-0.019738698999959, 11.114356472000111],
                [-0.018665275999865, 11.114186088000054],
                [-0.017966699999874, 11.114305357999967],
                [-0.01748962399995, 11.113623819000168],
                [-0.016688815999942, 11.113385281000149],
                [-0.016194700999904, 11.113419358000044],
                [-0.014933855999857, 11.112686705000101],
                [-0.014879102999942, 11.112371671000119],
                [-0.015147267999907, 11.111567176000108],
                [-0.014745019999964, 11.110695639000028],
                [-0.013895830999957, 11.110662119000096],
                [-0.012577352999926, 11.11040512700015],
                [-0.012376228999869, 11.109667673000047],
                [-0.011895766999942, 11.109701193000149],
                [-0.011337088999881, 11.110047574000134],
                [-0.010398510999948, 11.109835276000126],
                [-0.009761618999903, 11.110081095000169],
                [-0.009258809999949, 11.109511243999975],
                [-0.008331404999979, 11.109388335],
                [-0.007962677999842, 11.109086649000119],
                [-0.006811803999938, 11.108606186999964],
                [-0.005962613999884, 11.108449757000187],
                [-0.005392763999907, 11.107824039000093],
                [-0.00493464799996, 11.10780169100019],
                [-0.0047078599999, 11.106801512000118],
                [-0.004456121999965, 11.10651298900018],
                [-0.004172470999947, 11.105715208000163],
                [-0.004313630999889, 11.10530158],
                [-0.004035988999931, 11.104748536000102],
                [-0.004261865999979, 11.104049486000179],
                [-0.004058126999894, 11.103467262000152],
                [-0.005080790999955, 11.102799826000137],
                [-0.005003133999935, 11.102148849],
                [-0.005274719999932, 11.101787880000074],
                [-0.005060981999918, 11.101119107999978],
                [-0.005327054999896, 11.100665596000113],
                [-0.004259506999858, 11.100042964000124],
                [-0.004030591999935, 11.099712775000057],
                [-0.00339252699996, 11.099631147000082],
                [-0.002589115999854, 11.099128660000133],
                [-0.001662376999889, 11.099381186000073],
                [-0.001405850999902, 11.098715792000121],
                [-0.000698296999815, 11.098775924999984],
                [0.000006783000174, 11.098109041999976],
                [-0.000249048999933, 11.097286309000026],
                [0.000294892000113, 11.09658588100018],
                [0.000910293000175, 11.09660655800019],
                [0.001363399000184, 11.09625290200006],
                [0.001516212000126, 11.095531051000137],
                [0.002112481000154, 11.095000841000115],
                [0.002220408000085, 11.094608572000084],
                [0.001920135000034, 11.094051742000033],
                [0.002223101000084, 11.093711144000054],
                [0.002187049000099, 11.093256875000066],
                [0.002598694000142, 11.092551203000141],
                [0.00249743099999, 11.091523484000106],
                [0.001936721000163, 11.090673367000022],
                [0.001559667000038, 11.089714722000167],
                [0.001552080000067, 11.089175159000035],
                [0.001803189000157, 11.088612018000049],
                [0.002216145000034, 11.088292575000025],
                [0.002433039000039, 11.08781474500006],
                [0.002442290000147, 11.08663609399997],
                [0.002793938000138, 11.086001459000158],
                [0.002953217000083, 11.084781248000183],
                [0.003295893000029, 11.084089554000172],
                [0.003974435000032, 11.08306028300018],
                [0.003981178000117, 11.082688229000098],
                [0.004379856000071, 11.0824447600001],
                [0.005169661000139, 11.082406258000162],
                [0.005558859000189, 11.082046232000039],
                [0.006785685000068, 11.07970143200015],
                [0.007399689000067, 11.078703910000058],
                [0.008611368000061, 11.077247010000121],
                [0.011100554000166, 11.075005596000096],
                [0.012458462000041, 11.073726839000074],
                [0.013828391000175, 11.07257739400012],
                [0.016357211000127, 11.070224625000037],
                [0.019532072, 11.067322281000088],
                [0.023110967000093, 11.064173604000018],
                [0.024303262999979, 11.062936425000089],
                [0.024336521000066, 11.062071577000097],
                [0.024268162, 11.060286056000109],
                [0.02413507600005, 11.059252918000027],
                [0.024400305000029, 11.056570830000169],
                [0.025008727000113, 11.049381076000031],
                [0.026108258000022, 11.035547136000048],
                [0.026999815000181, 11.022226794000176],
                [0.028692946000035, 11.00346099300009],
                [0.03312076899999, 10.995482141000025],
                [0.033877909000125, 10.992621832000054],
                [0.032572593000111, 10.990205777000085],
                [0.032520242000146, 10.989201396000055],
                [0.032893733000037, 10.988326548000146],
                [0.032422929000177, 10.986079437000114],
                [0.031475908000061, 10.984839795000028],
                [0.030641900000148, 10.984015888000158],
                [0.030310165000174, 10.983259172999965],
                [0.030452577000062, 10.982503609000162],
                [0.031104654000103, 10.981536881000125],
                [0.031242529000053, 10.981081711000115],
                [0.030953835000105, 10.980672079000158],
                [0.030250648000106, 10.980572495000104],
                [0.029710323000074, 10.980789273000084],
                [0.029204132000018, 10.980594636000035],
                [0.028220747000148, 10.97911328400005],
                [0.02778158700005, 10.978742736000129],
                [0.024818072000073, 10.97252078800011],
                [0.01040311600002, 10.968381839000017],
                [0.004789372, 10.966478873000142],
                [0.000292794000131, 10.965496201],
                [-0.00035776999988, 10.965275115],
                [-0.001034705999928, 10.965506065000113],
                [-0.001811565999901, 10.96504839],
                [-0.004446208999923, 10.96448379700007],
                [-0.004911890999836, 10.96450460800014],
                [-0.00576538699994, 10.96364482500013],
                [-0.005652569999938, 10.963186993000079],
                [-0.006047338999906, 10.96272806100012],
                [-0.006023368999877, 10.961554009000167],
                [-0.006159230999913, 10.960956442],
                [-0.006540422999933, 10.960578414000167],
                [-0.004690235999874, 10.959165889000076],
                [-0.005280635999952, 10.958478863000096],
                [-0.005348579999975, 10.957340937000083],
                [-0.005837922999888, 10.956953963000046],
                [-0.005403029999968, 10.95606793100012],
                [-0.005309745999966, 10.955607407000173],
                [-0.005542679999962, 10.955169993000027],
                [-0.005407499999876, 10.95472862400004],
                [-0.005894094999917, 10.954327861000024],
                [-0.00558555799995, 10.954020961000026],
                [-0.006495663999942, 10.953737321000176],
                [-0.00640781899989, 10.953318152000065],
                [-0.007234074999928, 10.953089353000053],
                [-0.007660649999877, 10.953123810000136],
                [-0.007662751999874, 10.952492737000057],
                [-0.00673217699989, 10.95222515200004],
                [-0.007244278999906, 10.951700458000118],
                [-0.007023672999935, 10.95095016100015],
                [-0.006623641999965, 10.950483128000144],
                [-0.006877301999907, 10.949682012000096],
                [-0.007228127999895, 10.949013492],
                [-0.00711253299994, 10.948552896000137],
                [-0.007406819999858, 10.948112923000053],
                [-0.007308763999902, 10.94740987300014],
                [-0.007580161999897, 10.947143441000094],
                [-0.007760620999818, 10.946129889000076],
                [-0.007491094999978, 10.945415258000025],
                [-0.00815062699985, 10.944998518000034],
                [-0.008576476999906, 10.945247923000125],
                [-0.00896791599996, 10.944943299000158],
                [-0.009894743999894, 10.94505669],
                [-0.010089556999958, 10.944773320000024],
                [-0.011289867999949, 10.944427219999966],
                [-0.011369385999956, 10.943994815],
                [-0.011832769999955, 10.943858523000074],
                [-0.011798597999871, 10.943230087000131],
                [-0.011286838999979, 10.942396175000056],
                [-0.011456218999967, 10.941355026000167],
                [-0.011205050999877, 10.940568806000158],
                [-0.011304937999967, 10.939880175000098],
                [-0.012234013999944, 10.93974814500018],
                [-0.012351270999943, 10.938023383000086],
                [-0.01179531799994, 10.934957119000046],
                [-0.011602769999911, 10.934154554000031],
                [-0.011344941999823, 10.93369625500003],
                [-0.010199016999934, 10.92942654500007],
                [-0.010142928999926, 10.927839014000085],
                [-0.010263362999865, 10.926839042000097],
                [-0.010571092999896, 10.925699130000112],
                [-0.011287421999953, 10.924957375000133],
                [-0.011622511999974, 10.924817911000105],
                [-0.012235805999978, 10.924031727999989],
                [-0.011775825999962, 10.923148381000033],
                [-0.009617965999951, 10.922163089000037],
                [-0.008654375999868, 10.921787934000179],
                [-0.008190917999968, 10.921116770000026],
                [-0.0082705129999, 10.920659562000026],
                [-0.007768776999967, 10.920600064000098],
                [-0.007551659999933, 10.919643092000172],
                [-0.007211851999955, 10.918685721999964],
                [-0.007253562999949, 10.917881160000036],
                [-0.007641918999923, 10.917653686000051],
                [-0.008319012999834, 10.917807450000169],
                [-0.008137341999884, 10.917093105000106],
                [-0.008828101999939, 10.916489062000153],
                [-0.008983748999924, 10.915800612],
                [-0.008444187999885, 10.915377225000043],
                [-0.008008258999951, 10.915656907000084],
                [-0.007618689999958, 10.914569852000113],
                [-0.007829084999969, 10.914190231000077],
                [-0.00841002899989, 10.913905508000084],
                [-0.008762581999918, 10.912699602000089],
                [-0.009034154999938, 10.912369781999985],
                [-0.009537124999895, 10.912895015],
                [-0.010196267999902, 10.912571959000104],
                [-0.01073906299996, 10.912017037000055],
                [-0.011070937999875, 10.911158293000028],
                [-0.011575193999931, 10.911294956000063],
                [-0.011866143999953, 10.91100653300009],
                [-0.011479692999956, 10.910660810000024],
                [-0.011422456999924, 10.910261031000061],
                [-0.011831178999898, 10.909773191],
                [-0.011716964999948, 10.908895094000115],
                [-0.011989184999948, 10.908366855000168],
                [-0.012651795999943, 10.90783436099997],
                [-0.013342513999817, 10.90807909900019],
                [-0.013813649999975, 10.907264892000057],
                [-0.014941146999945, 10.906119340000089],
                [-0.014127760999941, 10.905868700000156],
                [-0.013664829999925, 10.905886502000044],
                [-0.012773944999935, 10.905461993000188],
                [-0.012812057999952, 10.904051134000042],
                [-0.013286984999922, 10.902931044000127],
                [-0.013125451999883, 10.902132368000082],
                [-0.014199990999884, 10.901715907000039],
                [-0.014569373999961, 10.901314744000103],
                [-0.014694480999935, 10.900579341000139],
                [-0.015718616999948, 10.899483057],
                [-0.015739202999953, 10.899157936],
                [-0.015333877999979, 10.898619248000045],
                [-0.015378020999833, 10.897913899],
                [-0.015072904999897, 10.897427894000032],
                [-0.015241441999876, 10.897050887000034],
                [-0.01590048099996, 10.89674710600002],
                [-0.016038617999868, 10.89628732599999],
                [-0.015564874999939, 10.89535709300003],
                [-0.01555861099996, 10.894717721000063],
                [-0.015830899999912, 10.89416191800018],
                [-0.017576561999931, 10.892401027000062],
                [-0.017350346999933, 10.891661741000178],
                [-0.018221276999896, 10.891358632000049],
                [-0.018397936999861, 10.891053301000113],
                [-0.018341333999956, 10.89045786000014],
                [-0.01785004899989, 10.889775597999972],
                [-0.01848189999987, 10.88925401199998],
                [-0.018637505999948, 10.888565553000149],
                [-0.01922710599996, 10.888173354000116],
                [-0.019260867999947, 10.887228212000082],
                [-0.020115306999912, 10.886847880000062],
                [-0.020615929999963, 10.887232538000092],
                [-0.020871526999883, 10.886660140000117],
                [-0.020505114999935, 10.886160167000071],
                [-0.020506491999868, 10.88573852899998],
                [-0.02098971199996, 10.88547551],
                [-0.021125423999933, 10.884899974000177],
                [-0.02196225299997, 10.884786896000037],
                [-0.022659433999934, 10.883890715000064],
                [-0.023147219999885, 10.88393636],
                [-0.023456429999953, 10.883165710000014],
                [-0.023324338999885, 10.882630659000085],
                [-0.023976936999873, 10.881723308000062],
                [-0.024894146999941, 10.88175378000011],
                [-0.025283005999938, 10.881352662000097],
                [-0.025439710999933, 10.880319720999978],
                [-0.025694790999978, 10.879901644000142],
                [-0.025484638999842, 10.879256111000132],
                [-0.025914861999979, 10.878243327000177],
                [-0.026070774999937, 10.877452896000023],
                [-0.02572499899992, 10.876608514000168],
                [-0.025882931999945, 10.875195271],
                [-0.025382945999866, 10.874617715],
                [-0.02555990299993, 10.873356103000106],
                [-0.025427936999961, 10.872782470000118],
                [-0.024618923999867, 10.87120907700006],
                [-0.024469819999979, 10.870762158000105],
                [-0.025008197999853, 10.87067568000009],
                [-0.026031855999975, 10.870792291000043],
                [-0.026304950999929, 10.870583335000163],
                [-0.025697453999953, 10.869630646000132],
                [-0.02565022899995, 10.868718314000148],
                [-0.025420252999936, 10.868276651000087],
                [-0.025595469999928, 10.867549665000183],
                [-0.025135083999942, 10.865941553000141],
                [-0.025640704999944, 10.865637256000127],
                [-0.026360630999932, 10.864586806000034],
                [-0.026381699999888, 10.86410735700008],
                [-0.027563115999953, 10.862600893000149],
                [-0.027684486999931, 10.862141052000027],
                [-0.027631838999866, 10.861179096],
                [-0.028534860999969, 10.861267380000015],
                [-0.028778801999977, 10.860838239000145],
                [-0.028957077999905, 10.860023073000036],
                [-0.028946511999948, 10.858978575000094],
                [-0.029150250999976, 10.858047742999986],
                [-0.029814701999896, 10.856035317000135],
                [-0.029909449999934, 10.855184060000113],
                [-0.029875724999897, 10.853538714000024],
                [-0.029287536999902, 10.850927077000051],
                [-0.029265542999951, 10.84910263900008],
                [-0.029045671999938, 10.848123620000024],
                [-0.028569222999977, 10.847174107000058],
                [-0.028525271999968, 10.846107457000073],
                [-0.028804490999903, 10.843380051000167],
                [-0.028634572999977, 10.842472842000177],
                [-0.028213132999895, 10.84175775000017],
                [-0.02784252399988, 10.840841641000111],
                [-0.027307759999871, 10.83982580300011],
                [-0.02641948899992, 10.838643498000067],
                [-0.025337599999943, 10.83699208400003],
                [-0.024829389999979, 10.83638970300018],
                [-0.024599917999979, 10.835799227000166],
                [-0.023895665999817, 10.834614742000156],
                [-0.023206189999883, 10.833174009],
                [-0.022731637999868, 10.831651281000177],
                [-0.022783378999975, 10.831169173000035],
                [-0.023225403999959, 10.830685544000119],
                [-0.023736480999958, 10.829537975000108],
                [-0.023793460999968, 10.828298026000084],
                [-0.023696041999926, 10.82739104500007],
                [-0.023442728999896, 10.826417430999982],
                [-0.023497959999929, 10.825610143000176],
                [-0.023850754999899, 10.824807931000066],
                [-0.02380952599998, 10.823334801000044],
                [-0.0231296499999, 10.82237912800008],
                [-0.022442355999829, 10.821993889000112],
                [-0.02203519699998, 10.82118513800009],
                [-0.022246424999935, 10.819659069000181],
                [-0.022683230999974, 10.819059678000087],
                [-0.026104773999975, 10.816970532000028],
                [-0.027632792999952, 10.81597221800007],
                [-0.029047643999945, 10.815488883000114],
                [-0.029646319999927, 10.81482384800006],
                [-0.029773894999892, 10.813291996000146],
                [-0.030259090999891, 10.811504970999977],
                [-0.030380783999931, 10.810066798000094],
                [-0.030633096999964, 10.808750293000173],
                [-0.031082433999927, 10.807709989000045],
                [-0.031613760999903, 10.807178397000143],
                [-0.032434135999836, 10.806803414000115],
                [-0.035558395999942, 10.806199999000171],
                [-0.03800343599994, 10.805193460000112],
                [-0.038777382999854, 10.804622648000134],
                [-0.04093185499994, 10.80206363200017],
                [-0.042451421999885, 10.800175063000097],
                [-0.044005053999854, 10.798967288000085],
                [-0.044881799999928, 10.797611355000072],
                [-0.045038394999892, 10.796570121000116],
                [-0.044703366999897, 10.794976196000107],
                [-0.04465801799995, 10.794331184000043],
                [-0.044991196999888, 10.792979082000045],
                [-0.045774085999938, 10.79220985400002],
                [-0.046719808999853, 10.791953716000137],
                [-0.048493123999947, 10.791777286000126],
                [-0.049846832999947, 10.791191687000037],
                [-0.050933705999967, 10.790365505000068],
                [-0.052198864999866, 10.78957017700003],
                [-0.053721252999878, 10.788519335000103],
                [-0.054558787999895, 10.788091977000079],
                [-0.05570403299987, 10.787310047000062],
                [-0.056785859999877, 10.78630469400008],
                [-0.058271721999972, 10.784440730000085],
                [-0.059669922999944, 10.782077679000054],
                [-0.061596657999928, 10.778244579000159],
                [-0.062579046999929, 10.776930240000183],
                [-0.063850598999977, 10.774037650000139],
                [-0.064637794999953, 10.77274476000008],
                [-0.065188269999908, 10.772291699000164],
                [-0.065483751999921, 10.772278810999978],
                [-0.067266840999935, 10.773416849],
                [-0.067904892999877, 10.773487664000072],
                [-0.068170327999894, 10.773276259999989],
                [-0.068842568999912, 10.772197971000082],
                [-0.06930110299993, 10.771730847000129],
                [-0.070224770999971, 10.771369840000034],
                [-0.070908967999912, 10.770933704000186],
                [-0.072812347999957, 10.770109878000028],
                [-0.073094801999957, 10.769799307000028],
                [-0.073150031999887, 10.7690553810001],
                [-0.07284797799997, 10.768484009000076],
                [-0.07118275299996, 10.767084546000035],
                [-0.070911022999951, 10.76571405600015],
                [-0.070183958999905, 10.764681175000021],
                [-0.070052112999917, 10.764049681000188],
                [-0.070343397999864, 10.76358480800019],
                [-0.071028006999938, 10.76300812200003],
                [-0.071727088999864, 10.762255100000118],
                [-0.072516507999978, 10.761124788000188],
                [-0.073612655999909, 10.75994302700019],
                [-0.07393370199992, 10.758855407000112],
                [-0.07441294399996, 10.757985974000178],
                [-0.074628143999973, 10.756875990000026],
                [-0.074469932999875, 10.755748358000119],
                [-0.074557926999944, 10.754310043000089],
                [-0.073983926999972, 10.752439836000121],
                [-0.073544300999913, 10.751289317000044],
                [-0.073850937999907, 10.750353227000062],
                [-0.073549392999894, 10.749619259999974],
                [-0.073435559999893, 10.748563413000113],
                [-0.073556837999888, 10.747177559000022],
                [-0.073360295999976, 10.745826584000042],
                [-0.071779664999951, 10.743228571000145],
                [-0.071446178999963, 10.742006714000013],
                [-0.071822735999945, 10.74098815700006],
                [-0.07178905499984, 10.74015302000015],
                [-0.072522700999968, 10.73809931],
                [-0.07257577099989, 10.737145929000121],
                [-0.072915163999937, 10.736430407000057],
                [-0.073562398999911, 10.73437643599999],
                [-0.07419041299994, 10.733140907000177],
                [-0.074480253999923, 10.732221300000049],
                [-0.074755802999903, 10.730502438000087],
                [-0.074776426999904, 10.72921825100019],
                [-0.074655182999948, 10.728765923000026],
                [-0.074099805999879, 10.728113879000091],
                [-0.073748628999965, 10.727211655000076],
                [-0.073651809999944, 10.7260594010001],
                [-0.073247158999948, 10.725330640000095],
                [-0.073327946999882, 10.724424190000093],
                [-0.073026406999873, 10.723695734000046],
                [-0.073055068999963, 10.72251905100012],
                [-0.072778376999963, 10.721867836000115],
                [-0.073012823999932, 10.7199173570001],
                [-0.073597631999974, 10.719133665000129],
                [-0.075143881999963, 10.718339047000086],
                [-0.076536105999935, 10.717791996000017],
                [-0.077597829999945, 10.71779514100001],
                [-0.078940474999911, 10.717975491000061],
                [-0.080105989999822, 10.717752950000033],
                [-0.081069279999952, 10.717130201000089],
                [-0.082230725999921, 10.717332049999982],
                [-0.082965728999909, 10.717560198000058],
                [-0.084198939999965, 10.717065006000098],
                [-0.085406471999875, 10.716768158000036],
                [-0.085987637999949, 10.716257264999967],
                [-0.087270272999831, 10.716004724000129],
                [-0.088363017999825, 10.714952409000034],
                [-0.088971374999971, 10.714664817000028],
                [-0.089904987999944, 10.713549807000163],
                [-0.090398038999979, 10.711902047000024],
                [-0.090596181999956, 10.709139379000078],
                [-0.088517449999813, 10.706301526],
                [-0.083293881999964, 10.698235068000145],
                [-0.078427974999897, 10.690635364000173],
                [-0.076291522999952, 10.685217266000052],
                [-0.073932307999883, 10.67933272900018],
                [-0.072507109999833, 10.676045946000102],
                [-0.07162318099995, 10.673804234000045],
                [-0.071799432999967, 10.672783468000148],
                [-0.071556538999971, 10.671540699000104],
                [-0.071215588999905, 10.67091779600014],
                [-0.070341064999923, 10.669892189000109],
                [-0.070093075999921, 10.669254429000091],
                [-0.069956501999911, 10.668441779000034],
                [-0.070196649999957, 10.666801719000034],
                [-0.069917908999912, 10.665553474000035],
                [-0.070163043999969, 10.664653020000117],
                [-0.070265729999903, 10.662950174000116],
                [-0.070440128999962, 10.662590477000094],
                [-0.070379716999923, 10.661195],
                [-0.069639302999974, 10.660043587000075],
                [-0.069172286999958, 10.65877723799997],
                [-0.069060683999965, 10.657870499000182],
                [-0.068812545999947, 10.657050971000047],
                [-0.067926049999869, 10.65538376700016],
                [-0.067709970999942, 10.654060288000153],
                [-0.067974270999969, 10.653181112000084],
                [-0.067768737999927, 10.652837019000117],
                [-0.067257641999959, 10.652550084000097],
                [-0.066719353999929, 10.651822299000173],
                [-0.066760924999926, 10.650979116000144],
                [-0.066220101999932, 10.650170024000147],
                [-0.066221368999948, 10.649753885999985],
                [-0.065721458999974, 10.649234285999967],
                [-0.065655858999946, 10.648475527000187],
                [-0.065087653999854, 10.647854444000018],
                [-0.064644922999889, 10.646858240000029],
                [-0.064083019999941, 10.646569950000185],
                [-0.063833564999925, 10.646147552000059],
                [-0.063835666999978, 10.645458581000128],
                [-0.063483871999949, 10.644793358000072],
                [-0.063530742999887, 10.644041136],
                [-0.063121881999905, 10.642904481000187],
                [-0.06299808599988, 10.641476552000086],
                [-0.062043399999936, 10.640266613000051],
                [-0.061602911999898, 10.638998269000126],
                [-0.061571284999957, 10.63817967000017],
                [-0.06141013499996, 10.637809207000146],
                [-0.061260572999913, 10.63660994200012],
                [-0.060808043999941, 10.63609323300011],
                [-0.060544118999871, 10.634940475000121],
                [-0.060446691999971, 10.634003176000078],
                [-0.059295168999938, 10.63235569900013],
                [-0.055526640999972, 10.631450442000073],
                [-0.053120311999976, 10.63075008800007],
                [-0.041041284999949, 10.628460845000063],
                [-0.026736135999897, 10.625552873000174],
                [-0.02188310799994, 10.624720186000104],
                [-0.016652322999846, 10.62356828500009],
                [-0.015665280999883, 10.62351977100019],
                [-0.015804080999828, 10.623190889000114],
                [-0.015680357999884, 10.622395600000175],
                [-0.015786828999978, 10.621407984000143],
                [-0.015489287999969, 10.621123158000046],
                [-0.014545522999867, 10.620836856000153],
                [-0.014243974999886, 10.620364998000184],
                [-0.013913094999964, 10.619297285000016],
                [-0.013289336999946, 10.618543124999974],
                [-0.013280354999893, 10.617857266000158],
                [-0.013093603999891, 10.616816872000072],
                [-0.012614394999957, 10.616317588000129],
                [-0.01178845499993, 10.61585039800002],
                [-0.01119283099996, 10.615789723000091],
                [-0.010338259999912, 10.616527518000112],
                [-0.009694476999869, 10.616788209000106],
                [-0.008967766999945, 10.616758265000101],
                [-0.008438917999968, 10.616051409000079],
                [-0.008468296999922, 10.61560903100002],
                [-0.007811295999943, 10.615634613000054],
                [-0.007153001999882, 10.616061178000052],
                [-0.006551818999981, 10.616114588000016],
                [-0.006609778999973, 10.61547872],
                [-0.006276611999965, 10.61477248],
                [-0.005380041999956, 10.615405697000085],
                [-0.004094576999876, 10.615277183000103],
                [-0.003089007999904, 10.61503893400004],
                [-0.003007834999948, 10.614209045000052],
                [-0.00183571599996, 10.613610758000164],
                [0.000357191000035, 10.613161320000131],
                [0.009070387000122, 10.610616944000071],
                [0.030099563000135, 10.604741416000138],
                [0.034319943000128, 10.603509869000106],
                [0.04125026100013, 10.601558768000075],
                [0.041572164, 10.600952322000126],
                [0.041244173000166, 10.599556917000086],
                [0.041328149000151, 10.59885390300019],
                [0.040813496000112, 10.598130364000099],
                [0.041700431000038, 10.59779409000015],
                [0.041816172000154, 10.597448933000067],
                [0.04243394000008, 10.59725677099999],
                [0.042820257000187, 10.596930906000125],
                [0.042768440000032, 10.596435028000144],
                [0.042973195000059, 10.596029243000032],
                [0.042536668000082, 10.59544094100005],
                [0.042813070000022, 10.59480618399999],
                [0.04287796400007, 10.594227245000184],
                [0.043353391000039, 10.593830619000016],
                [0.044513554000048, 10.59317747900019],
                [0.045014476000063, 10.593022248000011],
                [0.045445938000057, 10.592495390000124],
                [0.046463940000137, 10.59207683000011],
                [0.047625038999968, 10.591127723000056],
                [0.048002190000034, 10.590635931],
                [0.048697822, 10.590421418000119],
                [0.049025208000103, 10.590040025000121],
                [0.04871412500006, 10.589481627000055],
                [0.049051442000177, 10.588744699000017],
                [0.049657891000152, 10.588516702000106],
                [0.050141077000148, 10.588082428000064],
                [0.050839443000029, 10.588672603000134],
                [0.051224265000087, 10.587982363000037],
                [0.051850574000127, 10.587861773000157],
                [0.052188511000111, 10.587309480000044],
                [0.052029467000068, 10.58641161300011],
                [0.052184472000022, 10.586124489000156],
                [0.052918239000064, 10.585661817000016],
                [0.054232085000081, 10.585409403000142],
                [0.054945634000035, 10.584737351000115],
                [0.055466859000092, 10.58402736000005],
                [0.05608449000016, 10.583815849000075],
                [0.056236760000104, 10.58354526900007],
                [0.056233818000067, 10.582685462000086],
                [0.056888469000114, 10.581897860000083],
                [0.056769158000122, 10.58040460300009],
                [0.058037496000054, 10.57825907900019],
                [0.058305045000111, 10.577492062999966],
                [0.058355180000092, 10.576678927000046],
                [0.058845444000156, 10.575880850000146],
                [0.058796167000082, 10.575318828000036],
                [0.059029893000059, 10.574436181000067],
                [0.058949903000155, 10.573852215000102],
                [0.059762542000158, 10.572846368000057],
                [0.060104726000077, 10.571916508000072],
                [0.05975990200011, 10.571264536000115],
                [0.059831080000151, 10.570903284000053],
                [0.059672349000095, 10.57009911700004],
                [0.060076240000171, 10.569295818000114],
                [0.060094244000084, 10.568860337000046],
                [0.059815579000031, 10.568006968000077],
                [0.06031850700009, 10.566845079000132],
                [0.060315965000029, 10.566103771000087],
                [0.060626242000069, 10.565617704999966],
                [0.060507761999986, 10.565179927000031],
                [0.060815006000041, 10.564622219000057],
                [0.060851636000052, 10.563933141000064],
                [0.06050354700011, 10.562570903000164],
                [0.060439886999973, 10.561630656000034],
                [0.080696583000019, 10.553917008000099],
                [0.117700054000068, 10.541294885000127],
                [0.127947525000025, 10.530040975000134],
                [0.142636715000037, 10.526618838000047],
                [0.143552151000108, 10.525656533000074],
                [0.143274632000157, 10.523648587000025],
                [0.143639323, 10.522861881000154],
                [0.144796028000087, 10.521631396000032],
                [0.14519771700003, 10.521062259000132],
                [0.145023918000106, 10.520751487000155],
                [0.144425706000163, 10.520888678000063],
                [0.144189253000036, 10.520200592000151],
                [0.144321466000065, 10.51980604199997],
                [0.14478756699998, 10.519335881000075],
                [0.144919605999974, 10.518894484000043],
                [0.145401956000114, 10.518300255000042],
                [0.145224053000049, 10.517631247999986],
                [0.145704987000045, 10.516653974000121],
                [0.145605197000123, 10.51602326599999],
                [0.14438375300017, 10.515548173000013],
                [0.144300674000135, 10.514917404000187],
                [0.144845622000105, 10.514686710000092],
                [0.145620951000012, 10.515009091000024],
                [0.14602729600017, 10.51494975300011],
                [0.146284418000107, 10.514447276000169],
                [0.147355910000158, 10.51352849300008],
                [0.147471664000136, 10.513202896000053],
                [0.146558650999964, 10.512544814000137],
                [0.146188855000105, 10.511942639000154],
                [0.146029269000053, 10.510953899000071],
                [0.145719488999987, 10.510012549000123],
                [0.146220982000045, 10.50932731],
                [0.146308952000027, 10.508263270999976],
                [0.146847839000088, 10.506392922000032],
                [0.14686228100004, 10.50577833599999],
                [0.147152617000131, 10.505223380000075],
                [0.147847129000183, 10.50477995000017],
                [0.148037645000159, 10.504341096000132],
                [0.14792194000006, 10.502415240000119],
                [0.147111673000097, 10.501671357000077],
                [0.147110677000171, 10.501401297000143],
                [0.147841562000167, 10.500252262],
                [0.148833130000128, 10.499570762000133],
                [0.149133129000177, 10.498618941000132],
                [0.150200965000067, 10.497556835000182],
                [0.15077167100003, 10.497447328000078],
                [0.150999665000086, 10.496603241000173],
                [0.150844257000188, 10.495992026000124],
                [0.15023588400004, 10.494872636000082],
                [0.150699049000082, 10.493619847000048],
                [0.150951623000026, 10.49264615200002],
                [0.151779604000069, 10.491408575000037],
                [0.151908444000071, 10.490859713000134],
                [0.151436722000085, 10.489042623000046],
                [0.150739135000038, 10.48864280700019],
                [0.15071526700018, 10.487457919],
                [0.151222693000079, 10.486882885000057],
                [0.151262612999972, 10.486006410000073],
                [0.151742166000133, 10.48580074900002],
                [0.152074718000108, 10.486127480000164],
                [0.152458402000093, 10.485974523000039],
                [0.152295084000059, 10.48548183400004],
                [0.152734220000184, 10.485265295000147],
                [0.153272073000153, 10.485390110000083],
                [0.153525992000141, 10.484782924000115],
                [0.154533340000171, 10.484619436000116],
                [0.15477525700004, 10.483028489000162],
                [0.153966836, 10.482772381000132],
                [0.154285730000083, 10.482418487000132],
                [0.153983785999969, 10.482086136000078],
                [0.15332864100003, 10.481865295000034],
                [0.153779102000101, 10.480948752000131],
                [0.15426976700013, 10.481115073000126],
                [0.154248134, 10.480536444000109],
                [0.154961368000045, 10.47990278899999],
                [0.155025813000123, 10.479260465000152],
                [0.154283676000091, 10.478855305000025],
                [0.15421957500007, 10.478086681000036],
                [0.154371364999975, 10.477722371000084],
                [0.154933223000114, 10.477566012000011],
                [0.155714155000055, 10.477921426000137],
                [0.156397632000051, 10.477524872000174],
                [0.1570012520001, 10.477368357000103],
                [0.157751254000118, 10.476142076000144],
                [0.158188121000137, 10.476068838000117],
                [0.158734332000108, 10.475449562000051],
                [0.158924450000029, 10.47491149900003],
                [0.158921605000103, 10.474145410000176],
                [0.159189641000069, 10.473596041000064],
                [0.158993738999982, 10.47257712600009],
                [0.158991467000021, 10.471965357000158],
                [0.158720822000078, 10.471812020000186],
                [0.158062102000088, 10.472123059000069],
                [0.157945380000058, 10.471431790000167],
                [0.157596072000104, 10.471088590000022],
                [0.157496641000137, 10.470554335000145],
                [0.157941688999983, 10.47043697700019],
                [0.158171360000097, 10.470053093000047],
                [0.15791051399998, 10.469538714],
                [0.157841301000019, 10.46889412000013],
                [0.157382676, 10.468352903000039],
                [0.157215434000022, 10.46755158600007],
                [0.156364803000088, 10.467915678],
                [0.155356286000028, 10.467748479000136],
                [0.155207132000157, 10.467319122000049],
                [0.154488419000074, 10.467217008000148],
                [0.15417533700014, 10.46612686200018],
                [0.154500826000117, 10.465301712000098],
                [0.155426126000123, 10.464802323000072],
                [0.156094818000042, 10.462675211000146],
                [0.156500030000188, 10.462329272000034],
                [0.15702500499998, 10.462746242000037],
                [0.157333843000174, 10.462690006],
                [0.157386973000087, 10.462000875000115],
                [0.158848527000089, 10.461210176000122],
                [0.159838119000085, 10.460784947000036],
                [0.160204490000069, 10.460477726000136],
                [0.160651448000124, 10.460878439000112],
                [0.161117248000096, 10.460355906000132],
                [0.161829855000178, 10.460317486000179],
                [0.162560817000099, 10.459226300000125],
                [0.162579168000093, 10.458920345000138],
                [0.162227872000187, 10.458039785000096],
                [0.16176000400003, 10.457256101000098],
                [0.161677501000042, 10.456032848000063],
                [0.162393651000116, 10.45620109500004],
                [0.162803052000129, 10.456984992000116],
                [0.163090348000139, 10.45712724399999],
                [0.16361373400008, 10.456370260000085],
                [0.163627442000177, 10.45556553099999],
                [0.164168277000044, 10.455009652000115],
                [0.163976147000142, 10.45425527700013],
                [0.164682916000061, 10.453401172000099],
                [0.165704394000045, 10.453323036000029],
                [0.166691513000103, 10.452244687000075],
                [0.167460764000055, 10.451723794000031],
                [0.168467027000077, 10.451301237000166],
                [0.16877705700017, 10.45156740900012],
                [0.169410582000125, 10.452722501000153],
                [0.170037244000071, 10.452786342000024],
                [0.170388767000134, 10.452236659999983],
                [0.170847755000068, 10.45213301400014],
                [0.17136479100003, 10.451174873000184],
                [0.172060369000064, 10.451056578000077],
                [0.171998610000173, 10.450177723000081],
                [0.172261488000061, 10.449005565999983],
                [0.172166970999967, 10.44830044299999],
                [0.172484422000082, 10.446833223000112],
                [0.171871269000064, 10.446656351000058],
                [0.171368725000036, 10.447021953000103],
                [0.17105712100016, 10.446334160000106],
                [0.170517601000142, 10.44649046000012],
                [0.170242974000075, 10.446011966000128],
                [0.170698272000038, 10.444924534000052],
                [0.170393579000176, 10.44459496200011],
                [0.16981647700004, 10.445123424000144],
                [0.169448742000043, 10.44505863300003],
                [0.169368493999968, 10.444444395000176],
                [0.168980377000139, 10.44413992800014],
                [0.168601986000169, 10.443457888000182],
                [0.169131636000088, 10.44289653300018],
                [0.168336135000118, 10.442345538000154],
                [0.166785029000039, 10.441488659000072],
                [0.166803449000099, 10.44120199400004],
                [0.167571113000065, 10.441009042000076],
                [0.168077534000133, 10.440191489000028],
                [0.16863229400002, 10.438899770000148],
                [0.168302781000023, 10.438630913000054],
                [0.168074949000186, 10.438006190000124],
                [0.168764562000092, 10.437788720000071],
                [0.168934881999974, 10.437176321000095],
                [0.168257955000058, 10.43706305500018],
                [0.169067714000164, 10.436216835000153],
                [0.169527127000038, 10.435487636000119],
                [0.169948350000027, 10.434223961999976],
                [0.169809366000038, 10.433535534000043],
                [0.170061918000158, 10.433325173000128],
                [0.17070021100011, 10.433532273000026],
                [0.170756603000029, 10.432978161000051],
                [0.169939848000183, 10.431947746000105],
                [0.169915360000118, 10.430608542000186],
                [0.169295552000165, 10.430131309000046],
                [0.169595948000165, 10.429314508000061],
                [0.170276038000111, 10.428788428000018],
                [0.171666386000027, 10.428361708000068],
                [0.172593551000091, 10.428396890000158],
                [0.172978504000071, 10.427860864000024],
                [0.172804617000168, 10.427517033000072],
                [0.173014322000029, 10.427017474000024],
                [0.17367030500003, 10.426747760000126],
                [0.173494334000111, 10.425847278000049],
                [0.174658060000127, 10.425123756000062],
                [0.175161057000082, 10.42414637600018],
                [0.175842748000093, 10.424052931000176],
                [0.176872589000141, 10.424746348000099],
                [0.178770227000143, 10.42523539500013],
                [0.179155590000107, 10.42481234600001],
                [0.179383484000141, 10.423971005000112],
                [0.179838814000163, 10.423646905000169],
                [0.18089122399999, 10.423678847000133],
                [0.181340589000172, 10.423250048000057],
                [0.182241477000105, 10.422965635000082],
                [0.182451891000085, 10.422658972000022],
                [0.182468779000089, 10.421969974000035],
                [0.182757461000165, 10.421748448000187],
                [0.18328113300015, 10.42183194100005],
                [0.183799316000091, 10.421198960000027],
                [0.183409191000067, 10.420357147000061],
                [0.183871052000029, 10.419553519000033],
                [0.184379813000078, 10.419375270000103],
                [0.184275160000027, 10.418937495000023],
                [0.184970670000041, 10.418821937000075],
                [0.185316181000132, 10.418170303000181],
                [0.185080754000069, 10.417749546999971],
                [0.185195068000155, 10.41706019000003],
                [0.185737750000101, 10.41701960000006],
                [0.186337919000096, 10.417458295000017],
                [0.1870613050001, 10.417353650000166],
                [0.186821456000075, 10.417971824000176],
                [0.187752674000023, 10.418351417000054],
                [0.187965251000094, 10.418617935000043],
                [0.188448901000129, 10.418425994000074],
                [0.18898589700018, 10.417619325000032],
                [0.188596421000113, 10.416951128999983],
                [0.189793595000026, 10.41625774900018],
                [0.189984292000076, 10.415893284000163],
                [0.189997173000165, 10.414151613000172],
                [0.190421051999977, 10.413615425000046],
                [0.190129874000036, 10.413175591000083],
                [0.189452059000189, 10.412814352000112],
                [0.189544924000131, 10.411609752000118],
                [0.18908725700004, 10.411149829000124],
                [0.18959435700009, 10.41036476100004],
                [0.189461497000082, 10.409344365000152],
                [0.190437658000121, 10.408890971000119],
                [0.190573703000098, 10.407623896000189],
                [0.190454678000094, 10.407070063999981],
                [0.190954634000093, 10.407048547000102],
                [0.190664138000045, 10.406132473000127],
                [0.19081608800002, 10.405478564000134],
                [0.191898501000139, 10.40538073800002],
                [0.191991067000117, 10.404804420000119],
                [0.191651169000068, 10.404295729000069],
                [0.191658718000099, 10.403792150000015],
                [0.192073740000126, 10.403454655000075],
                [0.192930146000094, 10.403649290000146],
                [0.193107103000102, 10.403309487000172],
                [0.192737618000024, 10.402754739000102],
                [0.192872891000093, 10.402447715000108],
                [0.193398771999966, 10.402675471000123],
                [0.194065312000077, 10.402674861000037],
                [0.194401028000129, 10.403170172000102],
                [0.195313016000114, 10.403626978000034],
                [0.196284603000095, 10.405114203000096],
                [0.196672986000181, 10.40549579900005],
                [0.197574729999985, 10.405453851000118],
                [0.197987872000112, 10.405758192000178],
                [0.198605982000061, 10.405794461000085],
                [0.199648966000041, 10.405561834000082],
                [0.200321081000141, 10.40589000400007],
                [0.201259728000082, 10.406762806000188],
                [0.201780919000157, 10.406931707000183],
                [0.20290140000003, 10.406583037000132],
                [0.204075724, 10.40720968700009],
                [0.205882667000026, 10.407299342000158],
                [0.206282809000129, 10.407115958000077],
                [0.20675546300015, 10.405930797000167],
                [0.206477901000085, 10.405537105000064],
                [0.20626024500001, 10.404768693000108],
                [0.206636583999966, 10.404261203000033],
                [0.208389225000133, 10.40538820300003],
                [0.209186220000106, 10.405263740000123],
                [0.20940138900005, 10.40538438600015],
                [0.209291888000109, 10.406154029000163],
                [0.208998941000118, 10.407076186000154],
                [0.207745834000093, 10.409703577000016],
                [0.206813676000081, 10.410525537000183],
                [0.206554014000062, 10.411047346000089],
                [0.206518719000087, 10.412003713000104],
                [0.206289307000134, 10.413153711000064],
                [0.206351292000022, 10.414071132000174],
                [0.20611928600016, 10.415270742000132],
                [0.205278876000079, 10.41679230699998],
                [0.205109599000025, 10.417655484000079],
                [0.20515107000017, 10.418305676999978],
                [0.20541424400011, 10.418695997000157],
                [0.205946433000179, 10.418823512000131],
                [0.206658165000079, 10.418586594000033],
                [0.208263492000128, 10.418351814000175],
                [0.208771904000116, 10.418088102000183],
                [0.209412658000019, 10.417493203000106],
                [0.210287210000047, 10.416889153],
                [0.211115668000048, 10.416604937000159],
                [0.211815836000085, 10.416979820000165],
                [0.211602597000081, 10.417267221000031],
                [0.210655607999968, 10.41785501400011],
                [0.210176790000162, 10.41857331],
                [0.210178416000133, 10.418994928000075],
                [0.210511405000034, 10.420162091000122],
                [0.210144650000188, 10.420867235],
                [0.210422736000112, 10.421711136000113],
                [0.211164500000166, 10.42204177100001],
                [0.213175102000093, 10.422224298000117],
                [0.21413658300014, 10.42250173400015],
                [0.215339050000068, 10.423186095],
                [0.215881471000102, 10.423795802000143],
                [0.216209929000115, 10.424505525000143],
                [0.216391416000135, 10.425361859000134],
                [0.216798561000132, 10.426261426],
                [0.217400970000142, 10.426545728000121],
                [0.219677166, 10.42558082700009],
                [0.22037146800011, 10.42515655699998],
                [0.22062896400007, 10.424797333000129],
                [0.220524377000061, 10.424389889000167],
                [0.220074529000101, 10.423964470000044],
                [0.219461512000123, 10.423815244000139],
                [0.21843506700003, 10.42401481000013],
                [0.217999676000147, 10.423727119000034],
                [0.217501428000105, 10.423023554000167],
                [0.217576847000032, 10.42237292100009],
                [0.219296012000086, 10.422060492000071],
                [0.219775223000056, 10.422163383000168],
                [0.221368206000136, 10.423047397000119],
                [0.222298429000091, 10.423159585000121],
                [0.223405360000129, 10.422899073999986],
                [0.224461396000095, 10.42243758799998],
                [0.225552777000075, 10.421761021000066],
                [0.226332923000143, 10.421937152000169],
                [0.226613315000066, 10.42174042300013],
                [0.226774428000169, 10.42094065300006],
                [0.227522744000055, 10.420097297000098],
                [0.228726991000144, 10.419811595000056],
                [0.229357219000065, 10.419382042000166],
                [0.229471996000086, 10.418835974000103],
                [0.229817744000059, 10.418269728000098],
                [0.23153243199999, 10.416827420000175],
                [0.232582369000113, 10.415530961000115],
                [0.232876801000145, 10.414659029000063],
                [0.233344131000081, 10.41386634500003],
                [0.234038094000027, 10.413367648000076],
                [0.234804519000136, 10.413598927000123],
                [0.236058431000117, 10.412502837000034],
                [0.236522397000158, 10.412272324000014],
                [0.236926480000079, 10.41238650200006],
                [0.237046260000056, 10.413113541000087],
                [0.236955318000184, 10.414759038000057],
                [0.237267770000074, 10.415636895000148],
                [0.237580301000094, 10.415828585000042],
                [0.238119418000053, 10.415595023000037],
                [0.239355517000092, 10.415628820000109],
                [0.239741848000165, 10.415475762000028],
                [0.241130343, 10.414627144000178],
                [0.241595213000153, 10.414625341000146],
                [0.242176492000169, 10.41519902400006],
                [0.243456461000108, 10.415767238],
                [0.243887382000139, 10.415627780000023],
                [0.244360972999971, 10.41501693500004],
                [0.244958321000013, 10.41472802200019],
                [0.245562520000021, 10.414061553000181],
                [0.246074048000082, 10.413191525000173],
                [0.246576069000128, 10.412732129000176],
                [0.247405480000111, 10.412701343000094],
                [0.24866728700016, 10.41360304300008],
                [0.24929250800011, 10.41332503699999],
                [0.25029794600016, 10.411973588000023],
                [0.250635725000109, 10.40950594100019],
                [0.250982158999989, 10.409124305000091],
                [0.251522242000021, 10.409138729],
                [0.25246974099997, 10.409405083000024],
                [0.253741656000159, 10.409353260999978],
                [0.254630963000125, 10.408988787000055],
                [0.256314978000091, 10.408968409000124],
                [0.258347005000076, 10.40965761800004],
                [0.258972617000154, 10.409481553000148],
                [0.259176966000098, 10.409072911000067],
                [0.25917266700003, 10.408000974000117],
                [0.259568475000094, 10.407787232000032],
                [0.261436897000124, 10.408286923000048],
                [0.262076606000051, 10.408849315000055],
                [0.262417264000135, 10.409804188000123],
                [0.262699946999987, 10.410186110999973],
                [0.263692524000135, 10.410584524000171],
                [0.264323634000164, 10.410391892000064],
                [0.264360595000085, 10.409892971999966],
                [0.264029902000175, 10.409340389000022],
                [0.264337415000114, 10.408975428000076],
                [0.266039285999966, 10.409933188000139],
                [0.266779759000087, 10.410630198000035],
                [0.267244853000022, 10.410686227000156],
                [0.267578684000171, 10.40994639000013],
                [0.267978786000072, 10.409762933000138],
                [0.268789509999976, 10.41062224400008],
                [0.269198833000189, 10.411342604000026],
                [0.26912346600011, 10.411979459000065],
                [0.268663405000154, 10.412480056000163],
                [0.268082321000179, 10.412653208000108],
                [0.267290231, 10.412273306000088],
                [0.266923340000119, 10.412409785000136],
                [0.267159397000057, 10.413651653000045],
                [0.267162569000106, 10.41443700300016],
                [0.266574496000032, 10.41563804100008],
                [0.266960907000112, 10.416198666000184],
                [0.267865589, 10.416195084000037],
                [0.268212308000045, 10.415887833000113],
                [0.26857123800005, 10.415158918000145],
                [0.269253071000094, 10.41511763699998],
                [0.270229775000075, 10.415371417000017],
                [0.270653007000078, 10.415743129000077],
                [0.271196834000023, 10.417366806000189],
                [0.270914786000162, 10.418519789000129],
                [0.27114057599999, 10.419259313],
                [0.271992929000078, 10.419794257000035],
                [0.272897500000056, 10.42075501000005],
                [0.273473709000029, 10.420749962000173],
                [0.274917309000102, 10.419782492000081],
                [0.275478398000075, 10.419482647000052],
                [0.277040839000165, 10.41899142300008],
                [0.277714134000178, 10.418903311000065],
                [0.278316368000105, 10.419137892000037],
                [0.27864479699997, 10.419808957000043],
                [0.278342796000118, 10.420840775000158],
                [0.278595124000049, 10.421280670000044],
                [0.279253161000042, 10.421478873000183],
                [0.279788501000098, 10.421279598000126],
                [0.280102388000046, 10.420910899000091],
                [0.280382125000187, 10.419881925000027],
                [0.280870619000098, 10.419530004000137],
                [0.28139147800016, 10.419605077000028],
                [0.281666994000034, 10.419884559000081],
                [0.282327009000028, 10.421017729000027],
                [0.282732432000046, 10.421454251000114],
                [0.28339357200008, 10.421796056000062],
                [0.284418594000158, 10.421946259000094],
                [0.285474445000034, 10.421468049000168],
                [0.285971027000073, 10.421052708000161],
                [0.286420163000116, 10.419929359000037],
                [0.287419948000149, 10.418679791000045],
                [0.287784401000181, 10.417950838000024],
                [0.287915562000137, 10.41701339299999],
                [0.287682734000043, 10.416239996000058],
                [0.287657525000157, 10.415523632000088],
                [0.28781334700011, 10.415166584000076],
                [0.288538805999963, 10.414640502000054],
                [0.288697933000094, 10.414222848000122],
                [0.287764304, 10.413054152000086],
                [0.287762258000043, 10.412555390000023],
                [0.288145726000153, 10.411713379000048],
                [0.28909843299999, 10.410530134000112],
                [0.289723682000158, 10.41027134300009],
                [0.29001183600019, 10.40994501800003],
                [0.290028015000189, 10.409140307000143],
                [0.290335732000187, 10.408833193000135],
                [0.290995274000181, 10.408791956000186],
                [0.292832735000104, 10.410214720000113],
                [0.29405655700009, 10.410656191000157],
                [0.29459609100013, 10.41053827400009],
                [0.294287048000058, 10.40917273000008],
                [0.294859231000089, 10.408872810000105],
                [0.295310700000016, 10.409000499000115],
                [0.295906142000092, 10.409615352000117],
                [0.29726203700011, 10.409684266000113],
                [0.297957297000096, 10.409529891000034],
                [0.298435682000161, 10.408761888000186],
                [0.298646220000137, 10.407843410000055],
                [0.298655414, 10.405355042000053],
                [0.298206260000143, 10.404439238000123],
                [0.298510229000158, 10.40387470200011],
                [0.299904001000016, 10.403312490000133],
                [0.300183608000168, 10.402401511000164],
                [0.299810628000046, 10.401106494000032],
                [0.299741110000127, 10.39999921499998],
                [0.299306278000074, 10.399179800000127],
                [0.299336832000108, 10.398490769999967],
                [0.299679829000127, 10.397296192999988],
                [0.29977670500017, 10.396491157000185],
                [0.300121306000165, 10.395685118000188],
                [0.300183357000037, 10.394535770000118],
                [0.29993013800015, 10.393880956000146],
                [0.299859360000141, 10.392919529000096],
                [0.300239952000027, 10.391953226000169],
                [0.300084543000025, 10.390832606000174],
                [0.298701821000066, 10.389639507000084],
                [0.298220374999971, 10.388985615000024],
                [0.297737626000128, 10.388689959000033],
                [0.29723710899998, 10.388135345000023],
                [0.296967840000036, 10.386959778000175],
                [0.296698753000044, 10.386503430000062],
                [0.296135836000076, 10.38634036600007],
                [0.295785491000174, 10.38575207499997],
                [0.295796642000084, 10.384404525000036],
                [0.295357641000123, 10.383915794000188],
                [0.29438669200016, 10.383992976000172],
                [0.29356660500008, 10.383837058000097],
                [0.291820560000076, 10.382701028000042],
                [0.290753153000139, 10.381685734000087],
                [0.290619716000037, 10.381046962000141],
                [0.291132883000103, 10.380503157000021],
                [0.292887072000042, 10.380609832000061],
                [0.292956907000075, 10.380169537000086],
                [0.292347661000122, 10.379565748000175],
                [0.292029645000071, 10.378712779000182],
                [0.291664029, 10.378468998000187],
                [0.29043516400003, 10.37843605300003],
                [0.290111535000165, 10.37795209900014],
                [0.289947330000189, 10.377126312000087],
                [0.290037363000067, 10.37667102600011],
                [0.290396775000147, 10.376225489000092],
                [0.291399012000056, 10.375606953999977],
                [0.293019769000125, 10.37514300200013],
                [0.293483621000121, 10.374909661000117],
                [0.294059626999967, 10.374199144000045],
                [0.294755209000186, 10.37414123000002],
                [0.295319084000027, 10.373866149000094],
                [0.29610316600008, 10.373881612000048],
                [0.297826403999977, 10.373089968000102],
                [0.29810639700014, 10.372818786000039],
                [0.300528650000103, 10.373015667000061],
                [0.301192895000099, 10.372784261000106],
                [0.301547473000142, 10.372377749000066],
                [0.301852779000171, 10.371497466000164],
                [0.30184302300006, 10.370483434999983],
                [0.302099253000108, 10.369851358000176],
                [0.302999343000181, 10.369445389000077],
                [0.30412580300009, 10.36993683500009],
                [0.305091650000179, 10.370624578000047],
                [0.305586215000176, 10.371091026999977],
                [0.306567832000098, 10.371552740000084],
                [0.30714348500004, 10.371437419000074],
                [0.30760701000014, 10.37112965900019],
                [0.307886214000121, 10.370671090000144],
                [0.307693173000075, 10.369759764000094],
                [0.307750881, 10.368910798000172],
                [0.308327006000184, 10.36756922100011],
                [0.308728335000069, 10.367030241000066],
                [0.309569663000048, 10.366552850000119],
                [0.310544624000158, 10.36675555],
                [0.31102794200018, 10.366524863],
                [0.311986428000068, 10.365443508000169],
                [0.312315409000064, 10.364910331000033],
                [0.312346896000065, 10.36311353900004],
                [0.312990969000168, 10.362055509000072],
                [0.314211627000077, 10.360444],
                [0.31443936200003, 10.359660474000066],
                [0.314566246000027, 10.358050675000186],
                [0.31450516700005, 10.356750273000046],
                [0.314363397000022, 10.356122570000082],
                [0.314585375000092, 10.354625363000082],
                [0.314271414000132, 10.354083788000139],
                [0.314116164000097, 10.35289123800004],
                [0.31439964899999, 10.35212953000007],
                [0.314753558000064, 10.351571450000108],
                [0.315407858000015, 10.350970809000046],
                [0.316213188000063, 10.349881806000099],
                [0.316440022000165, 10.34821648500008],
                [0.315774394000186, 10.346764238000048],
                [0.315758219000145, 10.346221448000108],
                [0.316178307000087, 10.344850190000159],
                [0.316228571000181, 10.343554843000163],
                [0.316152605000127, 10.342020274000106],
                [0.315724472000113, 10.340790259000073],
                [0.315664353000045, 10.339718569000183],
                [0.315204983000058, 10.338337121000109],
                [0.315314040000089, 10.337793819000126],
                [0.316430739, 10.336634658000037],
                [0.317046449000145, 10.336130621000166],
                [0.31751080500004, 10.33536817300012],
                [0.318309399000043, 10.334676006000166],
                [0.319020616000159, 10.334386515000062],
                [0.319488021000041, 10.334357046000036],
                [0.320251950000113, 10.334028758000045],
                [0.321488158000079, 10.334172499000147],
                [0.321941537000157, 10.334782388000065],
                [0.322721217000037, 10.335559031000116],
                [0.322863260000133, 10.336916966000103],
                [0.322978614000078, 10.337216854000076],
                [0.323937053000066, 10.338147073000187],
                [0.324173655000095, 10.338823982000179],
                [0.325096791000135, 10.339961012000117],
                [0.32604434100017, 10.340282279000121],
                [0.326536972000156, 10.34029403000011],
                [0.327205892999984, 10.33987242400002],
                [0.328122651000172, 10.339488375000144],
                [0.32868091600011, 10.338562947000128],
                [0.328814847999979, 10.337322373000177],
                [0.32959375300004, 10.336586171000022],
                [0.330044346000136, 10.336529201000076],
                [0.331090263000078, 10.337081518000048],
                [0.331625084000166, 10.337200556000141],
                [0.332473058000176, 10.337006917],
                [0.33345840700008, 10.336393857000076],
                [0.333674064000036, 10.336059537000153],
                [0.333889853000073, 10.335096939000096],
                [0.334101794000105, 10.33322225300003],
                [0.334423811000022, 10.331713607000097],
                [0.336115302000053, 10.329620618000035],
                [0.336834988000135, 10.328049701000168],
                [0.336788324000167, 10.3268842760001],
                [0.337074228000176, 10.326053656000113],
                [0.337592602000086, 10.325577545000044],
                [0.337807422000083, 10.325047581000092],
                [0.33757289100015, 10.32420533900006],
                [0.33718755700005, 10.32389555300017],
                [0.336021016000075, 10.32376535700007],
                [0.334967784000185, 10.32410865200012],
                [0.332482362000064, 10.324336348000145],
                [0.332198541000082, 10.324023644000022],
                [0.331555608000087, 10.32399873899999],
                [0.330947810000112, 10.32372568500017],
                [0.329710827000156, 10.322724984000047],
                [0.327115541000182, 10.321649950000108],
                [0.32631324700003, 10.321446575000039],
                [0.324706529000139, 10.320530038000129],
                [0.323510688000056, 10.320044442000096],
                [0.322932178000144, 10.31945159899999],
                [0.322639551000179, 10.318014365000124],
                [0.322646723000105, 10.316399544000149],
                [0.322423670000035, 10.31562888500008],
                [0.322374133999972, 10.313427350000097],
                [0.321067064000033, 10.31261978700013],
                [0.319443295000042, 10.31294056000013],
                [0.318827373999966, 10.312714357000175],
                [0.317962145000081, 10.312092360000122],
                [0.31648024600014, 10.31173190100003],
                [0.316067564999969, 10.311529665000023],
                [0.316023125000072, 10.310879519000025],
                [0.317034616000171, 10.309869597000159],
                [0.317390176000117, 10.309719345000133],
                [0.318576632000031, 10.308628792999968],
                [0.319810481000047, 10.308229706000077],
                [0.320332978000067, 10.308742873000028],
                [0.320804535000093, 10.309719193000149],
                [0.32125594200005, 10.309866151000051],
                [0.321639212000093, 10.309688226000162],
                [0.322253452000155, 10.308845250000047],
                [0.32241478900005, 10.308163952000143],
                [0.322741047000079, 10.307661096000118],
                [0.324101251000172, 10.306870178000054],
                [0.324498762000133, 10.306772104000117],
                [0.325403904000041, 10.306958534000046],
                [0.326477994000072, 10.30828509100013],
                [0.327605518000098, 10.309079592000046],
                [0.328211182000132, 10.310843451000096],
                [0.328446852000127, 10.311302670000146],
                [0.329253703000177, 10.311271797000188],
                [0.329484834000027, 10.310648078000156],
                [0.329574601000047, 10.309493109000073],
                [0.32950514900017, 10.308856848000119],
                [0.330065435000165, 10.308424668000043],
                [0.33140892300014, 10.308576601000084],
                [0.331424465000168, 10.311334510000108],
                [0.332385275000092, 10.311520687000154],
                [0.332890686000098, 10.31127335500014],
                [0.334970006000162, 10.309446076000029],
                [0.335747259000073, 10.308996457000035],
                [0.336501626000086, 10.309059475000083],
                [0.336750546000076, 10.309358806999967],
                [0.337365562000116, 10.309370042000126],
                [0.339672503000145, 10.308079137000163],
                [0.340328918000182, 10.308010282000055],
                [0.34233086800009, 10.308299583000121],
                [0.343964548000088, 10.308361687000058],
                [0.344799094000052, 10.30830310500005],
                [0.345510235000177, 10.308016321000082],
                [0.346044493000136, 10.308014097000125],
                [0.346947502000148, 10.307696199000077],
                [0.348018227000011, 10.307556713999986],
                [0.348469697999974, 10.307717411000056],
                [0.349295888000142, 10.308311928000137],
                [0.350349808000146, 10.309453853000036],
                [0.351373893000016, 10.312081160000105],
                [0.351892099000111, 10.312880869000082],
                [0.352992973000084, 10.313934407000033],
                [0.353363005000119, 10.313919079000186],
                [0.354648705000102, 10.313290931000097],
                [0.355754732000037, 10.312294284000131],
                [0.356835897000053, 10.31199214500009],
                [0.357710321000127, 10.311497980000127],
                [0.358256642000129, 10.311063059000105],
                [0.359121561000165, 10.310949201000142],
                [0.360013165000055, 10.31121825299999],
                [0.361834897000165, 10.311006669999983],
                [0.362358322000034, 10.31107335400003],
                [0.363741039000104, 10.311012413000014],
                [0.365234358000123, 10.310788420000051],
                [0.365904086000114, 10.310581679000109],
                [0.367300251000074, 10.31062436200017],
                [0.368030397000098, 10.310809969000161],
                [0.368765144000065, 10.311156333000042],
                [0.369479687000137, 10.311675736000097],
                [0.371120274000077, 10.312218448000067],
                [0.373235989000023, 10.312425777999977],
                [0.373975092000023, 10.31281806100003],
                [0.37479890100002, 10.313492426000153],
                [0.376118031000146, 10.314167435000115],
                [0.376708531000133, 10.314299942000162],
                [0.378692871000169, 10.31436863800019],
                [0.379604105000055, 10.314028572000097],
                [0.380338388000155, 10.313311747],
                [0.380750082000134, 10.313279678000129],
                [0.381783640000037, 10.313553571000114],
                [0.383322133000036, 10.314756678000094],
                [0.383868932999974, 10.315076736000151],
                [0.384654522000062, 10.315277282000011],
                [0.386645428000122, 10.315574610000112],
                [0.387236115000064, 10.315748430000099],
                [0.389287542000091, 10.315885660000106],
                [0.390704013000118, 10.315907122999988],
                [0.392015554000182, 10.31547987700003],
                [0.393117160000031, 10.314772467000068],
                [0.393927009000151, 10.314478264000172],
                [0.396027973000059, 10.314177108000138],
                [0.396400184000072, 10.31276328700011],
                [0.397618549000072, 10.309510621000129],
                [0.39788111200005, 10.30784376300005],
                [0.397919414000171, 10.306432762999975],
                [0.397665126000106, 10.305557601999965],
                [0.396864461000177, 10.304456089000041],
                [0.395074456000145, 10.303637152000078],
                [0.393883709000136, 10.30333769300006],
                [0.392431802000146, 10.302702539000109],
                [0.392184298000132, 10.302961122000056],
                [0.391452598000171, 10.30260288800008],
                [0.390576957000064, 10.30279954200006],
                [0.388890928000137, 10.304115670000044],
                [0.388092179000182, 10.30408052100006],
                [0.387398127000154, 10.303171412000097],
                [0.386199531999978, 10.302055040000084],
                [0.385863357000176, 10.301535681000189],
                [0.385654445000114, 10.300847688000033],
                [0.385179689999973, 10.300452922000034],
                [0.383860551000168, 10.300403455000094],
                [0.383113080000044, 10.300001583999972],
                [0.382791234000081, 10.299575848000131],
                [0.382385408000061, 10.298395449000054],
                [0.382634089000135, 10.297994831000153],
                [0.383450753000034, 10.297674452000024],
                [0.384435083000142, 10.297518688000082],
                [0.384268106000093, 10.296874603000163],
                [0.383265283000128, 10.296617113000025],
                [0.381831395000177, 10.295771770000158],
                [0.381466620000083, 10.295070659000146],
                [0.380355527000063, 10.294868732],
                [0.378994625000075, 10.29480839700011],
                [0.378575573000091, 10.294421647999968],
                [0.378395901000147, 10.29341387800008],
                [0.376733187000013, 10.291125568000098],
                [0.376640710000117, 10.290332359000104],
                [0.377697256000147, 10.288867418000166],
                [0.378132463000156, 10.287848765000149],
                [0.37821617100002, 10.28726147400016],
                [0.378024843000162, 10.286771798000132],
                [0.37761456800007, 10.286481452999965],
                [0.376919847000011, 10.286685562000116],
                [0.37581773200003, 10.288550250000185],
                [0.374427579000042, 10.288153839000188],
                [0.373301950000098, 10.288445191000108],
                [0.372840072000088, 10.288447149000092],
                [0.372419881000042, 10.287793104000059],
                [0.372386181000081, 10.287076799000033],
                [0.372718030999977, 10.286604191000038],
                [0.373698839000156, 10.286283144000038],
                [0.374144395000087, 10.285724630000175],
                [0.373997174000067, 10.285138319000055],
                [0.373138048000044, 10.283998400000144],
                [0.372803355000144, 10.283167635999973],
                [0.372282996000138, 10.283156062000103],
                [0.37193829500012, 10.28387396900007],
                [0.372119138000073, 10.285160054000187],
                [0.37174611100005, 10.285762348000105],
                [0.371123359000023, 10.2858807180001],
                [0.369417460000022, 10.285171483000113],
                [0.369209908000073, 10.284141775000023],
                [0.369541083000058, 10.283512106000046],
                [0.371213171000022, 10.282835432000013],
                [0.371730997000043, 10.282260083000097],
                [0.372041822000085, 10.280784538000148],
                [0.372314989000074, 10.280254312000181],
                [0.372717498000043, 10.280037673000095],
                [0.373482886000147, 10.28009229900016],
                [0.373959531000025, 10.280291436000027],
                [0.375036418000093, 10.279400518000045],
                [0.375905288000183, 10.279208512000139],
                [0.377163022000161, 10.279233484000088],
                [0.379086527000084, 10.278798196000139],
                [0.379586026000084, 10.278492959000175],
                [0.379318731000069, 10.277807961000178],
                [0.379469427000117, 10.277269985999965],
                [0.3801359200001, 10.276977816000112],
                [0.381218349000051, 10.27700076300016],
                [0.381509771000026, 10.27683418800018],
                [0.381716060000144, 10.276284952000026],
                [0.381636234999974, 10.273921021999968],
                [0.380673905000151, 10.273360229000048],
                [0.379269739000108, 10.272925313000144],
                [0.378174125000044, 10.272371139000086],
                [0.376904981000052, 10.271042284000032],
                [0.375995132000185, 10.269745516],
                [0.375481936000142, 10.26881079900005],
                [0.374520225000083, 10.267742957000166],
                [0.37238056800004, 10.266429336000101],
                [0.371422030000133, 10.266094452000061],
                [0.369364230000087, 10.265695314000084],
                [0.367634496000107, 10.265239669000152],
                [0.36690846099998, 10.265270282000074],
                [0.365469083999983, 10.265047626000126],
                [0.364977497000098, 10.26525085100019],
                [0.364623005000055, 10.265632611000171],
                [0.363394777000053, 10.26468985200006],
                [0.363124113000026, 10.263856046000171],
                [0.362670616000116, 10.26320763200016],
                [0.362238152000032, 10.2629228670001],
                [0.361882295000157, 10.261684347000141],
                [0.361879963000149, 10.26113875100009],
                [0.361590608000085, 10.260486891000028],
                [0.361398988000019, 10.259266970000056],
                [0.361023571000146, 10.258006485000124],
                [0.361060024000039, 10.25726584500012],
                [0.360870988000045, 10.255502296000145],
                [0.360843084000066, 10.254179732000125],
                [0.360962192000159, 10.253404914000157],
                [0.360756408000157, 10.252693800000088],
                [0.360516407000091, 10.251298756000153],
                [0.360563123000077, 10.250510462000136],
                [0.360916230000043, 10.24980631000011],
                [0.360973495999986, 10.248882949000176],
                [0.360677246000023, 10.248567297000022],
                [0.360635285000171, 10.247208967000063],
                [0.360675646000061, 10.245583002000103],
                [0.360854276000111, 10.243760811000016],
                [0.360702130000107, 10.242659211000046],
                [0.360326944000178, 10.241445567000142],
                [0.359957548000125, 10.240937326000108],
                [0.359650301000045, 10.237383899000179],
                [0.359728386000029, 10.236121512000068],
                [0.359616477000088, 10.235320104000039],
                [0.359928985000181, 10.234230340000124],
                [0.359828251000181, 10.233439906000058],
                [0.360033329000089, 10.232601350000152],
                [0.359909587000175, 10.23228773000011],
                [0.360222949000161, 10.231399120000162],
                [0.36018472000012, 10.23091429599998],
                [0.360501284000179, 10.230124875000115],
                [0.36072344300004, 10.22741795700017],
                [0.360818123000058, 10.22678653100013],
                [0.360640686000181, 10.225621658000136],
                [0.361163778000105, 10.222376143000076],
                [0.360772688000111, 10.220685843000012],
                [0.360220937000179, 10.219156036000015],
                [0.359918792000144, 10.218754980000085],
                [0.358346053000105, 10.217210135000073],
                [0.357247129000143, 10.21653132400013],
                [0.356823601000087, 10.216407810000021],
                [0.356281501000183, 10.21579684000011],
                [0.355491916000119, 10.214590415000032],
                [0.355157899000119, 10.213886370000125],
                [0.35486583200003, 10.211920084000155],
                [0.355142386000182, 10.210221487000183],
                [0.355531473000156, 10.209478615000023],
                [0.355500536000136, 10.20808441100013],
                [0.355716676999975, 10.204600435000145],
                [0.355549238000094, 10.20250136300001],
                [0.355583349000142, 10.20201623500003],
                [0.355431465000038, 10.200305638000145],
                [0.35494742000003, 10.198982202000082],
                [0.35488497, 10.19835693900012],
                [0.354379453000035, 10.197546133000174],
                [0.35378269600011, 10.19719588900017],
                [0.353360940000016, 10.196128462000047],
                [0.35198177500007, 10.194276894000154],
                [0.351216119000128, 10.192789277000088],
                [0.350989960000163, 10.19192770699999],
                [0.350946176000093, 10.190117455000177],
                [0.351051312999971, 10.188662058999967],
                [0.351390124000034, 10.187872556000116],
                [0.352110640000149, 10.18657168600015],
                [0.352683094999975, 10.185781216000123],
                [0.353178513000159, 10.183872287000156],
                [0.353344322000112, 10.182295395000097],
                [0.353201272000149, 10.18069222400004],
                [0.35290564100012, 10.179844719000187],
                [0.352947866000136, 10.178643100000158],
                [0.35273415000006, 10.177431517000173],
                [0.352867575000175, 10.17675033],
                [0.352654223000116, 10.175624168000127],
                [0.352842844000179, 10.174179451000043],
                [0.352864246000138, 10.173316857000088],
                [0.352093537999963, 10.171606052000186],
                [0.350759148000066, 10.170450068000093],
                [0.35055555800011, 10.169989343000054],
                [0.350155155000095, 10.168031753000093],
                [0.350588872000174, 10.166663183000026],
                [0.351148742000134, 10.165531074000057],
                [0.35121888000009, 10.165012730000171],
                [0.35154118299999, 10.164270141000088],
                [0.351585502000034, 10.163564522000058],
                [0.352285386000062, 10.160673756000165],
                [0.352759948000028, 10.159762446000059],
                [0.353417864000107, 10.158800780000092],
                [0.354281951000132, 10.157923683999968],
                [0.355038605, 10.156633690000035],
                [0.355418801000042, 10.15577236900009],
                [0.356297036000171, 10.15495583500018],
                [0.356609141000149, 10.153777899999966],
                [0.357245854000155, 10.15306983],
                [0.357341710000071, 10.1527167160001],
                [0.357914764000043, 10.152083311000183],
                [0.358364467000058, 10.151218945000096],
                [0.359058972000071, 10.150367344000074],
                [0.360148113000037, 10.148786626000174],
                [0.361283222000054, 10.147555677000014],
                [0.362121515000069, 10.145851993000122],
                [0.362384835000171, 10.144324296000093],
                [0.362542126000108, 10.144031550000022],
                [0.362622541000121, 10.142658926000138],
                [0.362964470000065, 10.139990085000022],
                [0.362922469000068, 10.138606946000095],
                [0.363091823000048, 10.137878765000153],
                [0.36257664600015, 10.137721077000037],
                [0.362719736999964, 10.136700910000116],
                [0.362273343000027, 10.135707988000036],
                [0.362020406000113, 10.13442492500019],
                [0.361469585000123, 10.133068695000077],
                [0.360711397999978, 10.131999903000064],
                [0.359653550000019, 10.131094928999971],
                [0.358606320999968, 10.130068662000099],
                [0.35811318400016, 10.129197170999987],
                [0.357549657000163, 10.126796606000084],
                [0.35753070300018, 10.124272545000053],
                [0.357359307000024, 10.122531706000132],
                [0.356839091000154, 10.120505721000086],
                [0.356244699000172, 10.118694977000018],
                [0.355377450000105, 10.116805443000032],
                [0.354318769000145, 10.11502140499999],
                [0.35359229400018, 10.11420321899999],
                [0.352208766000103, 10.111874895000085],
                [0.351914802000124, 10.110073927000087],
                [0.352178976000118, 10.108075019000125],
                [0.352230182000085, 10.10568844300019],
                [0.352040356000032, 10.103517793000151],
                [0.352137399000128, 10.102117540000052],
                [0.352440591000175, 10.099473657000033],
                [0.352736747000165, 10.098474908000071],
                [0.353373693000094, 10.095846189000099],
                [0.35382075300015, 10.095025936000184],
                [0.353924545000041, 10.094576343000028],
                [0.354656046000059, 10.09328371099997],
                [0.354968935000102, 10.092298670000105],
                [0.356119036000166, 10.090698443000122],
                [0.356704321000166, 10.09035158600011],
                [0.358340081000165, 10.089187500000094],
                [0.359330322000119, 10.08859647600002],
                [0.360789990000114, 10.087220923000189],
                [0.361857918000169, 10.086590995000108],
                [0.363103988000091, 10.086004419000062],
                [0.364457814000161, 10.085254813000063],
                [0.366580199, 10.085425149000116],
                [0.368474001000038, 10.085461395999971],
                [0.371815127000104, 10.085163704000024],
                [0.37461800400007, 10.085152048000168],
                [0.376271026999973, 10.084803469000178],
                [0.378228141000079, 10.084690593000062],
                [0.379253264999988, 10.084468620999985],
                [0.380684731000144, 10.084333126000047],
                [0.381806080000104, 10.083854472999974],
                [0.382300594000185, 10.08375044799999],
                [0.383052435000138, 10.083347738999976],
                [0.383766380000111, 10.083187679000048],
                [0.385273940000161, 10.082641265000177],
                [0.385871544000054, 10.082586402000118],
                [0.387259531000041, 10.08204047900017],
                [0.388889695000103, 10.081556913000156],
                [0.389956989000098, 10.081447712000056],
                [0.390423134000059, 10.08121428100003],
                [0.391880051000101, 10.081183345000113],
                [0.39294350800003, 10.08082890300011],
                [0.393660729000146, 10.080787300000168],
                [0.393973207000045, 10.081031228000086],
                [0.394779857, 10.081093956000132],
                [0.395182424000041, 10.08094896599999],
                [0.396398883000018, 10.079965597000125],
                [0.398475990000065, 10.077383106000013],
                [0.398771227000054, 10.076852786000018],
                [0.399666616000161, 10.076217968000094],
                [0.400284889000034, 10.075157210000043],
                [0.401846950000049, 10.074370761000125],
                [0.403311208000048, 10.073474499000042],
                [0.403659776000154, 10.073062440000115],
                [0.404231377000087, 10.072787211000161],
                [0.404515598000046, 10.072284490000072],
                [0.40631989200017, 10.071579661999976],
                [0.406793134000054, 10.071065114000078],
                [0.407242851000035, 10.070255819000181],
                [0.407528344000127, 10.070047937000027],
                [0.408407893000174, 10.068975033000072],
                [0.410481764000053, 10.06696565600015],
                [0.412035599000092, 10.064941957000144],
                [0.413098089000073, 10.064383553000141],
                [0.413449510000135, 10.063993518000075],
                [0.41513527800015, 10.062903378000101],
                [0.415806424000095, 10.061869929000125],
                [0.416260390000161, 10.061405053000044],
                [0.416829292000159, 10.060518084000137],
                [0.417317734000108, 10.059033504000013],
                [0.417415946000062, 10.057963929000096],
                [0.417295569000089, 10.057137777000094],
                [0.416469004000021, 10.055041581000069],
                [0.41591087400019, 10.053930722000075],
                [0.415060672000038, 10.05278804400001],
                [0.414641698000025, 10.051706898000077],
                [0.414018910000152, 10.049149642000089],
                [0.413870696000174, 10.04831258500019],
                [0.413337033000118, 10.047708637000142],
                [0.412807102000102, 10.045401736000144],
                [0.412692259000096, 10.043283198000154],
                [0.412466574000177, 10.041889845000071],
                [0.412448323000149, 10.040886896000131],
                [0.412781771000027, 10.039562806000163],
                [0.412818065000181, 10.038956427000187],
                [0.413099668000143, 10.03785851400005],
                [0.413805507000177, 10.03585772200006],
                [0.413763309000046, 10.035102878000089],
                [0.414009906000103, 10.034908938000058],
                [0.414031234000106, 10.034057378000114],
                [0.413818922000132, 10.033182013000044],
                [0.413801388000081, 10.032344397000031],
                [0.413057012000138, 10.031226053000069],
                [0.412218356000039, 10.029521172000102],
                [0.411676930000169, 10.027757156000177],
                [0.411716523000166, 10.025985159000015],
                [0.411922572000094, 10.025416636000159],
                [0.412019844000156, 10.023481815000025],
                [0.411755955000046, 10.022890490000066],
                [0.410399716000029, 10.021708601000057],
                [0.408966826000153, 10.021444635000023],
                [0.407590264000078, 10.021348515000113],
                [0.402923468000154, 10.020285314000148],
                [0.402025786000138, 10.020352482000021],
                [0.401337329000114, 10.020586855000147],
                [0.400180769000144, 10.02122551400015],
                [0.39879509900004, 10.022248161000107],
                [0.397473120000029, 10.023862987000143],
                [0.395852338000054, 10.025170438000032],
                [0.394141153000135, 10.026144837000118],
                [0.393180180000115, 10.026369317000047],
                [0.391918235000048, 10.026426966000088],
                [0.39076346000013, 10.026842387000102],
                [0.38894961799997, 10.026566153000147],
                [0.387338582000098, 10.026909071000091],
                [0.385843815000158, 10.027135762000114],
                [0.383904808000011, 10.026846245000058],
                [0.384033087999967, 10.02628356800011],
                [0.383440900000039, 10.026280525000118],
                [0.382539091000069, 10.02603627600007],
                [0.380788551000023, 10.026264008000112],
                [0.380767058000117, 10.027097667000078],
                [0.3803366570001, 10.027222080000172],
                [0.378684714000144, 10.02709943100001],
                [0.377629962000071, 10.026847538000084],
                [0.376166264000119, 10.026863252000112],
                [0.374465868000129, 10.02744759500007],
                [0.372612565000168, 10.029025960000183],
                [0.371988747000046, 10.02943636900011],
                [0.370719836000148, 10.030488743000149],
                [0.368903045000025, 10.032163386999969],
                [0.368436283000108, 10.032901061000075],
                [0.367383798000162, 10.033856086000128],
                [0.365900028000112, 10.034730216000185],
                [0.365233932000081, 10.035008520000133],
                [0.363982675000045, 10.034975087000078],
                [0.363000883000154, 10.03422408300014],
                [0.361617002000116, 10.032391765000057],
                [0.360987329000181, 10.031405080000013],
                [0.360236754000141, 10.02942686400013],
                [0.360366373000147, 10.027841850000073],
                [0.360278898999979, 10.025532997000084],
                [0.362022001000184, 10.021519177000187],
                [0.362388622000026, 10.02010128400019],
                [0.362695963000135, 10.018468696000127],
                [0.36277565000006, 10.01757830300005],
                [0.36356486599999, 10.017497909000156],
                [0.36384422500015, 10.017155065000054],
                [0.363996272000179, 10.01628366500006],
                [0.363787941000112, 10.01299706399999],
                [0.363882998000065, 10.012459326999988],
                [0.363722532000111, 10.011313647000065],
                [0.363146764000078, 10.00924377900003],
                [0.362750257000187, 10.008159688000148],
                [0.362257511000109, 10.007329509000101],
                [0.362078583000027, 10.006420885000068],
                [0.361622648999969, 10.005083518000163],
                [0.361601893000056, 10.004772217000095],
                [0.359401948000027, 10.001532326000131],
                [0.359452280000085, 10.000934150000035],
                [0.358585507000043, 10.000069665000183],
                [0.357630119000021, 9.998952021000093],
                [0.357152343000109, 9.997702913000126],
                [0.35704139000012, 9.997091615000159],
                [0.357157205000021, 9.994853569999975],
                [0.357699405000119, 9.992200443000115],
                [0.358568681000065, 9.989320021000026],
                [0.359040443000026, 9.987113592000128],
                [0.360520943000154, 9.983486654999979],
                [0.360726509000074, 9.982783130000144],
                [0.361679539000079, 9.980668428000115],
                [0.361451733000081, 9.979363190000129],
                [0.361797369000044, 9.97892087800011],
                [0.362650803000122, 9.978261554000142],
                [0.363238668, 9.977245081000149],
                [0.363841926000021, 9.975919913999974],
                [0.364764557000058, 9.974524550000183],
                [0.36568233700018, 9.97263319199999],
                [0.36648920600004, 9.971478037999987],
                [0.367090651000126, 9.969722998000066],
                [0.367847567000069, 9.96658950799997],
                [0.368777327000089, 9.965579896000122],
                [0.369682327000021, 9.963966902000095],
                [0.370333015000028, 9.963360753000075],
                [0.37246921100018, 9.961042774000077],
                [0.373063398000056, 9.960221913000112],
                [0.373899103000042, 9.959328413000037],
                [0.374919386000158, 9.958040100000119],
                [0.376413243000059, 9.95700335000015],
                [0.377757909000081, 9.95618490400011],
                [0.379282161000106, 9.955109440000115],
                [0.379531981000127, 9.955028497000114],
                [0.381649958000139, 9.953694302000088],
                [0.382239535000167, 9.953107675000126],
                [0.384043568000152, 9.951813345000176],
                [0.384242245000166, 9.95147522700006],
                [0.384712842999988, 9.951672793000057],
                [0.385341962000098, 9.95123755800006],
                [0.386077899000156, 9.950427115000025],
                [0.386335404000079, 9.949533232000078],
                [0.386365167000122, 9.94868162600011],
                [0.386244260000126, 9.947692864000032],
                [0.385455457000148, 9.945849873000043],
                [0.385418837000145, 9.944414353000127],
                [0.385917992000145, 9.941502364000144],
                [0.386388293000095, 9.939659670000083],
                [0.386494210000023, 9.93841094100003],
                [0.386299057000144, 9.937609866000116],
                [0.385813696000071, 9.93719577800016],
                [0.385204228000077, 9.937010918000112],
                [0.38241924100015, 9.937005892000059],
                [0.381906405000166, 9.936666312],
                [0.381180596000036, 9.936448751000057],
                [0.379023731000188, 9.93523700600008],
                [0.37836229100003, 9.934586647000174],
                [0.37728297200016, 9.934367881000185],
                [0.37671364400012, 9.934474934000093],
                [0.37651816499999, 9.934919392999973],
                [0.375995854000053, 9.934971140000073],
                [0.374470480000127, 9.934429033000072],
                [0.373333737000166, 9.933769590000168],
                [0.372244912000042, 9.933269772000187],
                [0.37059289400014, 9.93301475000004],
                [0.369666399000096, 9.932784310000159],
                [0.368896636000102, 9.932815012000049],
                [0.367516698000145, 9.932487216000084],
                [0.365695438999978, 9.932166778000067],
                [0.364026830000057, 9.931223581999973],
                [0.360581369000158, 9.93025079400013],
                [0.359243721000155, 9.929115824000064],
                [0.356649415000106, 9.925629733000164],
                [0.355798120999964, 9.923805653000045],
                [0.355190214000061, 9.921697847000075],
                [0.35425526400013, 9.919937483000126],
                [0.353756138000051, 9.918261728000118],
                [0.352957620000041, 9.914638639000088],
                [0.352554782000141, 9.912490125000147],
                [0.352027022000186, 9.909089029000086],
                [0.351381919000175, 9.904163200000085],
                [0.351206045000026, 9.900879411000119],
                [0.35123411699999, 9.896648558000095],
                [0.352906528000119, 9.890675936000093],
                [0.353023870000015, 9.889092676000189],
                [0.352843646000053, 9.887563614000157],
                [0.35204246100011, 9.886088196000117],
                [0.351499261000129, 9.883697783000116],
                [0.351264717000049, 9.882407642000032],
                [0.351557792000051, 9.880648450000137],
                [0.352378768000108, 9.878830619000098],
                [0.353361721, 9.877001854000014],
                [0.354393380000033, 9.872587883000108],
                [0.354414154000153, 9.871083634000115],
                [0.35457950000017, 9.869638842000086],
                [0.354728705000127, 9.869437499000071],
                [0.355594174999965, 9.862277602000177],
                [0.356385114000148, 9.859298321000097],
                [0.356863327000042, 9.857334477000165],
                [0.356865011000139, 9.855703070000118],
                [0.358141601000114, 9.848505671999987],
                [0.356011470000169, 9.844022450000125],
                [0.352783431000148, 9.838743265000062],
                [0.351554703999966, 9.831754194000098],
                [0.348760101000153, 9.825729085000091],
                [0.343420319000074, 9.821046394000177],
                [0.340265408000107, 9.817250597000054],
                [0.339653711000039, 9.813695412000186],
                [0.339753368000174, 9.810014189000128],
                [0.340213894999977, 9.808312011000169],
                [0.340377276000083, 9.806269441000097],
                [0.337794198000097, 9.803897261000088],
                [0.336785419000023, 9.802353280000148],
                [0.335889629000121, 9.801359226000159],
                [0.335151046000078, 9.800808325000162],
                [0.33405145200004, 9.8002422080001],
                [0.332803636000165, 9.799464471000135],
                [0.331468795000092, 9.798793233000026],
                [0.330707473000189, 9.794261618000178],
                [0.330883487000165, 9.793792588000088],
                [0.330451173000142, 9.792686472000014],
                [0.330564865000099, 9.7912392610001],
                [0.331172632000062, 9.79035776500018],
                [0.332944699, 9.786417072000177],
                [0.334242484000185, 9.784936362000053],
                [0.335325095000144, 9.781072789000063],
                [0.335440191000089, 9.778600421000135],
                [0.334301436000089, 9.776578140000026],
                [0.333738024000183, 9.776001608000115],
                [0.331961465, 9.774955880000107],
                [0.331138804999966, 9.774899859000186],
                [0.33047489400002, 9.775429501000133],
                [0.32700037800015, 9.775110248999965],
                [0.325936436000063, 9.774428312000168],
                [0.325355058000071, 9.773568072000046],
                [0.325855307000154, 9.771127326000055],
                [0.326779168000144, 9.76873317799999],
                [0.329829651000011, 9.76493761200004],
                [0.329855337000026, 9.758785408999984],
                [0.327350020000097, 9.748362163000024],
                [0.326897633000101, 9.747068727999988],
                [0.326735797000026, 9.746870908000176],
                [0.326271059000135, 9.745252376],
                [0.32612887200014, 9.744442693000053],
                [0.325848430000065, 9.743765949000021],
                [0.325319266000122, 9.741279610000106],
                [0.324615211000037, 9.739576526000064],
                [0.324392878000026, 9.738844437000125],
                [0.32342845200003, 9.737383020000095],
                [0.321992692000038, 9.73432556299997],
                [0.321762780000029, 9.733520002000034],
                [0.321265758000152, 9.732660625999983],
                [0.320679467000048, 9.73189711700013],
                [0.319178158000057, 9.729605615000082],
                [0.31865811800003, 9.72819520600018],
                [0.318797357000108, 9.727239667000106],
                [0.319380419000026, 9.725749424000128],
                [0.320672590000072, 9.724479558000041],
                [0.322339526000178, 9.723150337000106],
                [0.323631556000123, 9.722550055],
                [0.324766240000031, 9.72219021799998],
                [0.325264384000036, 9.72244449699997],
                [0.325942472000179, 9.722521832999973],
                [0.327030978000039, 9.72243771900014],
                [0.327509611000096, 9.722653538000145],
                [0.330358814000022, 9.7224854480001],
                [0.332427185000029, 9.72222106900017],
                [0.332879993000176, 9.722238639000182],
                [0.335428401000172, 9.72187312900013],
                [0.336342156, 9.7218310720001],
                [0.337888662000069, 9.721317946000113],
                [0.339557563000028, 9.72014573600012],
                [0.342901261000122, 9.717917100000136],
                [0.346166075999975, 9.714032413000098],
                [0.346592073000181, 9.712892690000047],
                [0.346841216000087, 9.71059621500001],
                [0.346891044000131, 9.708463117000122],
                [0.347176961000059, 9.70740389600013],
                [0.347973935000084, 9.702473547000125],
                [0.348316980000163, 9.701105360000156],
                [0.34916322, 9.698803824000152],
                [0.349755968000181, 9.696982645000048],
                [0.350424652000072, 9.695453389000136],
                [0.351196219999963, 9.693907246000038],
                [0.351392586999964, 9.693017744000144],
                [0.351347810999982, 9.691550454000094],
                [0.350346049000052, 9.685519489000114],
                [0.350077957999986, 9.683048629000155],
                [0.350052693000123, 9.681607584],
                [0.350736114000085, 9.678174097000067],
                [0.350808120000011, 9.676743548000104],
                [0.350575540000136, 9.67586396300004],
                [0.35259253900017, 9.676375497000095],
                [0.352944988000104, 9.677007963000051],
                [0.353506715000094, 9.676490479000051],
                [0.354448262000119, 9.676133359000119],
                [0.354606730000114, 9.676568794000048],
                [0.355091961000028, 9.676399296000056],
                [0.35568134, 9.676490063000188],
                [0.35564863500008, 9.675950163999971],
                [0.356268333000173, 9.675696264000123],
                [0.35718686000007, 9.675866868000014],
                [0.357128470000021, 9.675156224000034],
                [0.357416632000081, 9.674995167000134],
                [0.357891898000105, 9.675264703000039],
                [0.358083351000062, 9.676010491000113],
                [0.358656447000044, 9.675971334000167],
                [0.358760315000097, 9.675568553000119],
                [0.359582555000145, 9.67552427600009],
                [0.359744673000023, 9.675178916000107],
                [0.361029264000024, 9.67486787100006],
                [0.361930948000179, 9.674545064000085],
                [0.362209144000133, 9.674061338000058],
                [0.363192519000052, 9.673431912000183],
                [0.363245295000127, 9.67320084000005],
                [0.362703779000071, 9.672788232000016],
                [0.362859300000082, 9.672511084],
                [0.362717535000172, 9.67188940400007],
                [0.363481523000132, 9.6712988540001],
                [0.362136718000158, 9.670388261000141],
                [0.362691147000135, 9.669677467000156],
                [0.362669531000108, 9.668623243000127],
                [0.363575706, 9.66879468000019],
                [0.363922119000165, 9.668255460000182],
                [0.364288464000026, 9.668409464000092],
                [0.36465172000004, 9.66778583900009],
                [0.365487855000026, 9.667799814000091],
                [0.365770824000094, 9.668870230000039],
                [0.366142642000113, 9.668765900000096],
                [0.366325534000168, 9.667974296000182],
                [0.36654632300008, 9.66765369699999],
                [0.367559454000116, 9.667005579000033],
                [0.368192345000182, 9.66606674600007],
                [0.368037105000099, 9.665434952],
                [0.36815178099999, 9.664952319000122],
                [0.367538540000169, 9.66440354600013],
                [0.366924177000044, 9.664243451000118],
                [0.367001235000032, 9.66339303000018],
                [0.366136607000101, 9.663141611000071],
                [0.366647805000127, 9.66250842900007],
                [0.366681772000163, 9.661979299],
                [0.366365396999981, 9.66167640499998],
                [0.365682396000068, 9.662410739000165],
                [0.365150847000052, 9.662412952000068],
                [0.365285734000111, 9.661850752000134],
                [0.365076876000046, 9.661102857000117],
                [0.365246293000155, 9.66057469500015],
                [0.366138712000179, 9.660724274000188],
                [0.367038150000155, 9.660131491000072],
                [0.3667946220001, 9.65898342100013],
                [0.366943126000137, 9.657927364000045],
                [0.367384563000144, 9.657903564000037],
                [0.367985733000125, 9.658237456000109],
                [0.36815922, 9.657870217000038],
                [0.368159080000169, 9.65643446100006],
                [0.367602405000014, 9.656122568000114],
                [0.367567876000123, 9.655836136],
                [0.368099847000167, 9.655550118000178],
                [0.368761372000108, 9.65635212899997],
                [0.370062666000138, 9.655743563000101],
                [0.370138040000143, 9.654789755000024],
                [0.369939709000164, 9.654514945000017],
                [0.369117610000103, 9.654142027000091],
                [0.368827763000184, 9.653552139000112],
                [0.369197054000097, 9.652839787000175],
                [0.369138945000145, 9.652206170000056],
                [0.369821665000188, 9.652104188000123],
                [0.370360233000156, 9.65274140300005],
                [0.37194211000002, 9.652503676000151],
                [0.372103526000046, 9.65256082400009],
                [0.372348457000157, 9.654078237000135],
                [0.373106410000048, 9.654556457000126],
                [0.373997848000101, 9.65364526400009],
                [0.375402448000159, 9.653903471000092],
                [0.375587445000122, 9.653616206000038],
                [0.375565408000114, 9.652974149000102],
                [0.375899891000188, 9.652265948000036],
                [0.375108529999977, 9.651558714000146],
                [0.375047754000093, 9.65099774099997],
                [0.376697145000094, 9.650608075999969],
                [0.376811259000078, 9.649998531999984],
                [0.376269183000034, 9.649849225000025],
                [0.37614005000006, 9.649527363],
                [0.376697145000094, 9.648848490000091],
                [0.376677073, 9.648209053000187],
                [0.375857642000142, 9.647970354000051],
                [0.375575514000104, 9.647124734000101],
                [0.375832797000157, 9.646155818000011],
                [0.376248128000157, 9.645480260000113],
                [0.376734201000147, 9.645098060000066],
                [0.376454039, 9.644319823000103],
                [0.376762553000162, 9.643507959000033],
                [0.378371942000172, 9.643263306000165],
                [0.378754147000109, 9.643558883000026],
                [0.379031922000081, 9.644961897000087],
                [0.379685868000024, 9.645148292000158],
                [0.380827290000127, 9.644792799000129],
                [0.382027804000131, 9.64492135200004],
                [0.381911584000136, 9.64376229800007],
                [0.381609947000129, 9.643196331000013],
                [0.381663004000188, 9.642872110000098],
                [0.381440811000175, 9.641765909000185],
                [0.381160368000167, 9.639714711000067],
                [0.380608185000028, 9.637745698000174],
                [0.380550075000031, 9.63685480200013],
                [0.380086320000089, 9.633443553000063],
                [0.379832546000046, 9.632528982000167],
                [0.37938142400003, 9.630155432000095],
                [0.378862364999975, 9.628125888],
                [0.378119291000132, 9.62572352300009],
                [0.377879553000014, 9.624715520000109],
                [0.377099703000169, 9.62212824500017],
                [0.376341609000121, 9.619366443000104],
                [0.375565829999971, 9.617044142000054],
                [0.374164318000055, 9.61237029300014],
                [0.373679086000038, 9.611228702000119],
                [0.37265444500008, 9.608150999000088],
                [0.373124377000067, 9.606950439000059],
                [0.374365175000037, 9.60478499200002],
                [0.374929148999968, 9.60347107500013],
                [0.375533827000083, 9.60263932200013],
                [0.37682880400007, 9.601506972000038],
                [0.377569213000129, 9.600602971000114],
                [0.378733092000061, 9.599344534000181],
                [0.379677868000044, 9.598131072000172],
                [0.380444383000167, 9.596810763000065],
                [0.38092498200001, 9.595516468000028],
                [0.381410212000048, 9.594665735000035],
                [0.38174469400002, 9.59360075800015],
                [0.382094475999963, 9.592902120000076],
                [0.382793618000107, 9.592091931000084],
                [0.382535913000027, 9.591528092000033],
                [0.382665889000123, 9.590722882000023],
                [0.382027945000061, 9.590174264000098],
                [0.38120935500001, 9.588948590000143],
                [0.380241420000118, 9.587122665000152],
                [0.379356720000146, 9.585996353000041],
                [0.377985385000102, 9.584684577000019],
                [0.376884949000157, 9.583763094000062],
                [0.375866484000028, 9.583229688000188],
                [0.374143123000124, 9.582024058000059],
                [0.37223813300011, 9.581268236000085],
                [0.371864911000102, 9.580980356000055],
                [0.370825953000121, 9.57945514100004],
                [0.370031926000081, 9.578783048000162],
                [0.369284360000051, 9.577912481000169],
                [0.368334953000101, 9.577241077],
                [0.364986904000091, 9.575931621000052],
                [0.36414501400003, 9.575777851999987],
                [0.362337576000073, 9.57508499000005],
                [0.36125931600003, 9.574846655000158],
                [0.358434676000115, 9.573876701000188],
                [0.357308975000137, 9.573575250000033],
                [0.355183477000139, 9.57317843800007],
                [0.353202832000079, 9.572907852000014],
                [0.351816338000162, 9.57266245600016],
                [0.348610757000188, 9.572956018000127],
                [0.34770921300003, 9.573144113000126],
                [0.345688425000162, 9.573273246999975],
                [0.344840780000141, 9.57375324200018],
                [0.343806454000173, 9.5747852020001],
                [0.343379473000084, 9.574943954000105],
                [0.342136429000107, 9.576558045000183],
                [0.34117116200008, 9.577498787000025],
                [0.341150108000079, 9.577796497000122],
                [0.340336851000075, 9.577871236000021],
                [0.339821163000181, 9.578071647000172],
                [0.339112476000082, 9.578578624000102],
                [0.338532782000073, 9.578735160000065],
                [0.334430990000158, 9.579230510000059],
                [0.332939646000057, 9.579166014],
                [0.329429198000184, 9.579125738000073],
                [0.328667174000032, 9.579258191000122],
                [0.326701548000131, 9.579320750000022],
                [0.32545976800003, 9.579135427000097],
                [0.324956571000087, 9.57894996499999],
                [0.323423259000037, 9.57806569500002],
                [0.322191865000093, 9.576995684000053],
                [0.321601925000152, 9.576639566000097],
                [0.320668238000053, 9.576452995000182],
                [0.319920111000101, 9.576133416000118],
                [0.318450382000037, 9.575860757000044],
                [0.317653549000113, 9.575871968000172],
                [0.315544753999973, 9.576213415000097],
                [0.314258197000015, 9.576675829000123],
                [0.311911630000111, 9.577288135000174],
                [0.309211911000148, 9.577739336000093],
                [0.307645895000064, 9.577734215000135],
                [0.305306907000158, 9.577420588000052],
                [0.301198939000074, 9.576311683000029],
                [0.299850764000155, 9.575903386000107],
                [0.298420337000039, 9.57577369900008],
                [0.296052435000036, 9.575878887000101],
                [0.295256303000087, 9.576080268000169],
                [0.294147445000135, 9.57654199000018],
                [0.291580368000098, 9.576849113000094],
                [0.290231913000184, 9.577088415000162],
                [0.289543298000126, 9.577099212000064],
                [0.288740007, 9.576851465000118],
                [0.287885626000104, 9.576341994000131],
                [0.286970748000101, 9.575967883000033],
                [0.284421778000024, 9.575175370000068],
                [0.283451457000126, 9.5748289390001],
                [0.282184271000119, 9.574549773000115],
                [0.277337720000105, 9.573021622000056],
                [0.276337502000104, 9.573207227000012],
                [0.275576601000125, 9.573154909000152],
                [0.274959851000119, 9.572869790000084],
                [0.274372859000039, 9.572007373000076],
                [0.273735616000124, 9.571615680000036],
                [0.273130655999978, 9.570984125000109],
                [0.272421970000153, 9.570024957000157],
                [0.271430033000058, 9.569102602000157],
                [0.270600776000094, 9.568601285000113],
                [0.270411148000051, 9.569122809000135],
                [0.269503709000048, 9.568514364000123],
                [0.268296457000133, 9.567898720000187],
                [0.266639908000116, 9.567397678000134],
                [0.265726293000114, 9.567348682000159],
                [0.264550061000136, 9.567639618000044],
                [0.263654413000097, 9.567956990000027],
                [0.262920321000138, 9.568384121000122],
                [0.261142219000078, 9.56959478300007],
                [0.259240176000162, 9.571051252000075],
                [0.258698099000185, 9.571648482000114],
                [0.257703636000031, 9.572272147000092],
                [0.257197351000173, 9.572753528000078],
                [0.255978590000154, 9.57354051100009],
                [0.25335354399999, 9.574743265000109],
                [0.252087201000109, 9.574670740000045],
                [0.249474646000067, 9.57548207800005],
                [0.247727142999963, 9.575940340000102],
                [0.245764464000047, 9.576063106000163],
                [0.24491274899998, 9.57622877800003],
                [0.243399649000025, 9.576275559000067],
                [0.242537126000173, 9.575785325000027],
                [0.241668989000175, 9.575662974000068],
                [0.240446999000142, 9.575164435000147],
                [0.239564124000026, 9.574423546000048],
                [0.238643210000021, 9.572382043000118],
                [0.238463968000133, 9.571200457000089],
                [0.238699074000067, 9.570937758000127],
                [0.239178410000079, 9.569886133000068],
                [0.239437517000113, 9.567854153000155],
                [0.239358353000171, 9.566702447000068],
                [0.239171673000158, 9.565733024000053],
                [0.238991307000163, 9.56269684599999],
                [0.238427474000105, 9.561094870000147],
                [0.237256997000031, 9.557077438000135],
                [0.23713544400016, 9.555969849000064],
                [0.236601227000108, 9.554455458000064],
                [0.236539748999974, 9.553697498000076],
                [0.236301555000182, 9.552823544000034],
                [0.23588664600004, 9.551833735000116],
                [0.234787191000123, 9.549833067000122],
                [0.234577210000054, 9.548980282],
                [0.234573983000075, 9.54805980700013],
                [0.235081109000078, 9.54627768000006],
                [0.235154238000177, 9.545751138000185],
                [0.23507549500016, 9.54470137200019],
                [0.235379518000173, 9.543562184000052],
                [0.235392291000096, 9.542481821000138],
                [0.235581078000166, 9.541720651000048],
                [0.23560648400013, 9.539125823000063],
                [0.236184213000058, 9.536124151000081],
                [0.237079300000119, 9.533731654000064],
                [0.237808338000036, 9.532272533000082],
                [0.239680344000078, 9.528258913000172],
                [0.241832791000093, 9.52544180800004],
                [0.242990776000056, 9.524007291000089],
                [0.244089808000012, 9.523129801000152],
                [0.245596591000094, 9.522209534000126],
                [0.246153828000104, 9.521976006000159],
                [0.247208927000088, 9.521286080999971],
                [0.249058334000097, 9.520651111000063],
                [0.249923804000105, 9.52047447600006],
                [0.250661265000076, 9.52019346600008],
                [0.251863885000148, 9.519577319000177],
                [0.253957521000189, 9.518933209000181],
                [0.25516182500013, 9.518810423000161],
                [0.256402763000153, 9.518825235000122],
                [0.258464396000136, 9.519286341999987],
                [0.259847100000115, 9.519369538000149],
                [0.26219394900005, 9.519658576999973],
                [0.263939067000024, 9.52010154900006],
                [0.266480597000168, 9.520489149000127],
                [0.268216030000133, 9.520590341000172],
                [0.269493744000158, 9.520792308000068],
                [0.271669351000128, 9.521316259000173],
                [0.272783963000165, 9.521698043000129],
                [0.275222749000022, 9.522066677000112],
                [0.276128784000093, 9.52236097500014],
                [0.276616120000085, 9.522736805000136],
                [0.27723258900005, 9.522806158000094],
                [0.279870127000095, 9.52378899200005],
                [0.28245095900013, 9.52456072200016],
                [0.28273168200019, 9.524952055000085],
                [0.285387607000132, 9.524730018000128],
                [0.287437030000149, 9.52414654900008],
                [0.290047337000033, 9.523624262999988],
                [0.291608300000121, 9.523105991000136],
                [0.294747070000142, 9.521728359000178],
                [0.296371898000075, 9.5208313440001],
                [0.299466593999966, 9.518469194999966],
                [0.300451513000041, 9.517973617],
                [0.301183500000093, 9.518089344000089],
                [0.30500105800013, 9.516022033000183],
                [0.306224451000048, 9.51507364500003],
                [0.306917979000048, 9.514216758000146],
                [0.307907810000131, 9.513187937000168],
                [0.308862270000077, 9.512330076000183],
                [0.309530252000059, 9.511335443],
                [0.309950635000121, 9.510182024000073],
                [0.310317119999979, 9.50875035000007],
                [0.310580579000032, 9.506495127000107],
                [0.31056471800008, 9.505235927000172],
                [0.310372142000119, 9.504219676000048],
                [0.309843539000155, 9.503196502000094],
                [0.307907108000052, 9.501682568000092],
                [0.306670802000099, 9.501028595000037],
                [0.305124015000104, 9.500116713000125],
                [0.30417839800009, 9.49964076700013],
                [0.3029231430001, 9.499130073000174],
                [0.302111852, 9.498595705000184],
                [0.30162044500014, 9.498559020000073],
                [0.300566469000159, 9.498066874000074],
                [0.29826902800005, 9.496832563000169],
                [0.297362571000065, 9.496433584000101],
                [0.295214053000109, 9.494202507000125],
                [0.29287801300012, 9.493044046000136],
                [0.29122146300017, 9.49324035300009],
                [0.290756725000108, 9.492897576000075],
                [0.290408488000139, 9.49109107300012],
                [0.288856227000053, 9.489462594000088],
                [0.283022933000041, 9.488536699000122],
                [0.278137221000065, 9.488677909999979],
                [0.27749169700013, 9.488605782000093],
                [0.27653246400007, 9.48820693200014],
                [0.276044005000074, 9.488230882000153],
                [0.275921048000157, 9.488728718000118],
                [0.27354037300006, 9.486014703000023],
                [0.272841792000065, 9.484737154000072],
                [0.272451306000107, 9.483473583000091],
                [0.272310664000031, 9.482211945000131],
                [0.271903754999983, 9.481028386000162],
                [0.271398452000028, 9.48022554500011],
                [0.269970974000046, 9.47965750600008],
                [0.267230269000038, 9.479017059000114],
                [0.265264643000137, 9.478908518000082],
                [0.264492092000069, 9.478643949000173],
                [0.264070305000075, 9.478684097000098],
                [0.263083983000172, 9.47843683400015],
                [0.261792936000177, 9.47836705800006],
                [0.259537603000183, 9.477953520000142],
                [0.257089413000131, 9.47798162500004],
                [0.256356444000119, 9.477907141000117],
                [0.254333971000051, 9.478198154000097],
                [0.248952501000133, 9.480733499],
                [0.248271045000024, 9.481088609000096],
                [0.247449928000037, 9.481226638000066],
                [0.246084770000095, 9.482154629000036],
                [0.244256699000061, 9.483274501000096],
                [0.243109802000106, 9.483904142000085],
                [0.238301570000033, 9.486068003000128],
                [0.237262612000052, 9.486639355000023],
                [0.235847625000076, 9.487595158000147],
                [0.23472809899999, 9.488144771],
                [0.234018150000168, 9.488309794000088],
                [0.233115905000034, 9.488312978000181],
                [0.231773344000032, 9.48779963700008],
                [0.231580066000049, 9.487312460000169],
                [0.229721255000186, 9.487591836000036],
                [0.229362771000183, 9.487482882000052],
                [0.228680613000051, 9.486892843000021],
                [0.228316936000056, 9.485315014000093],
                [0.227766296000141, 9.48425592600006],
                [0.227652322000154, 9.483443263000026],
                [0.227237834000107, 9.482386523],
                [0.226820960000111, 9.479888432000166],
                [0.226704600000062, 9.478397931000018],
                [0.226976621000119, 9.477633572000116],
                [0.226999079000166, 9.47690050100016],
                [0.22721285, 9.475378559000148],
                [0.227375249000033, 9.474975541000049],
                [0.227612741000144, 9.472285092000106],
                [0.227773595000144, 9.471468801000128],
                [0.228038598000126, 9.468698163000056],
                [0.227989752000155, 9.467449900000076],
                [0.228442981000114, 9.463769328000183],
                [0.228687631000071, 9.463090501000067],
                [0.22996745, 9.461592450000182],
                [0.23073115800014, 9.460939369000073],
                [0.231330222000167, 9.460013951000064],
                [0.232038488000171, 9.459358377000171],
                [0.234134931000142, 9.456515229000104],
                [0.236942166000176, 9.453705425000123],
                [0.237654081000187, 9.453297812000073],
                [0.237923576000185, 9.452616195000076],
                [0.23887873700005, 9.451934852000079],
                [0.239343334000068, 9.451470332000042],
                [0.241181933000064, 9.450245682000116],
                [0.24200108500014, 9.449562120000053],
                [0.242982915000027, 9.448583088000078],
                [0.243554749000054, 9.44781769400015],
                [0.24390733700011, 9.447083452000129],
                [0.24455875699999, 9.446077968000111],
                [0.245458054000096, 9.444950360000064],
                [0.246822932000043, 9.443578642999967],
                [0.247594641000035, 9.442087847000096],
                [0.247783006000077, 9.441213608000112],
                [0.247627907000094, 9.439764478000143],
                [0.246940415000097, 9.438266603000102],
                [0.247749179000039, 9.438099065000074],
                [0.247943159999977, 9.437871710000024],
                [0.24892120100003, 9.437337524000043],
                [0.249926191000043, 9.436559920000036],
                [0.250510797000061, 9.436557982000068],
                [0.251761420000093, 9.435962039],
                [0.252726548000169, 9.436395981000032],
                [0.253126579000138, 9.43625004100005],
                [0.25367441100002, 9.43533037300017],
                [0.254143220000174, 9.435627237000062],
                [0.254705087000048, 9.435503728000015],
                [0.254842502000088, 9.435022153999967],
                [0.255391878000069, 9.434511779000104],
                [0.25617523700015, 9.434352270000147],
                [0.256815146000065, 9.433926079000173],
                [0.25761661100006, 9.43384023200008],
                [0.258702450000101, 9.43421713000015],
                [0.259015316000045, 9.434183621000159],
                [0.259419418000107, 9.433551951000084],
                [0.260229446000153, 9.43275301500006],
                [0.259531427000013, 9.432202482000093],
                [0.258853199000043, 9.43092805599997],
                [0.259430928000143, 9.430583002000049],
                [0.260213585000145, 9.429905910000116],
                [0.260749767000107, 9.428859112000055],
                [0.261244121000175, 9.428536765000104],
                [0.262117592000038, 9.428362021000055],
                [0.263763053000048, 9.429168997000033],
                [0.264349063000054, 9.429575809000085],
                [0.265622005000182, 9.429002009000044],
                [0.26599649000002, 9.429420728000139],
                [0.265514207000138, 9.429997296000067],
                [0.265777946000014, 9.430549216999964],
                [0.266673874999981, 9.430419060000133],
                [0.267490921000103, 9.430028865999986],
                [0.268127181000182, 9.430479985000147],
                [0.269141155000057, 9.43065888100017],
                [0.269594944, 9.431414620999988],
                [0.269896160000087, 9.431579393000163],
                [0.270324124000126, 9.431367820000105],
                [0.270422376999989, 9.430825593000122],
                [0.27005238300012, 9.430152377000184],
                [0.270044102000043, 9.429400511000154],
                [0.26965937, 9.429258169000093],
                [0.269309729000156, 9.429541469000071],
                [0.268936227000154, 9.429410203999964],
                [0.268893837000178, 9.428481655000155],
                [0.269366715000047, 9.428374759],
                [0.270373390000145, 9.428846650000082],
                [0.271697282000048, 9.42929527800004],
                [0.27233747299999, 9.429287247000048],
                [0.272520223000015, 9.42902139400013],
                [0.272401197000136, 9.42841906800004],
                [0.272997032000148, 9.428455623000161],
                [0.273294880000094, 9.427680492000036],
                [0.273844537000173, 9.42729168],
                [0.27483198200008, 9.427824220000048],
                [0.275732543000061, 9.427422946000036],
                [0.276178753000067, 9.42767578400003],
                [0.276235880000058, 9.428067919000057],
                [0.276689669000064, 9.428834742000163],
                [0.276251320000085, 9.429234353000084],
                [0.276269287000105, 9.429588270000124],
                [0.276874245000045, 9.43056444900003],
                [0.277228518000129, 9.43048884600006],
                [0.277721048000046, 9.429190322000068],
                [0.277982401999964, 9.429106410999964],
                [0.278623995000032, 9.429458113000123],
                [0.279787172999988, 9.429337649000104],
                [0.280629063000049, 9.42967744200007],
                [0.281858211000099, 9.429308017000153],
                [0.282516648000183, 9.42970928900013],
                [0.28312567800009, 9.430281980000188],
                [0.283348291000152, 9.430281149],
                [0.28473268, 9.42976744400005],
                [0.285207804000095, 9.430268964000163],
                [0.285087374000057, 9.430800115000125],
                [0.285666787000139, 9.430908395000017],
                [0.285629029000177, 9.43126231000008],
                [0.28606555500005, 9.431846906000146],
                [0.2862179870001, 9.432415717000083],
                [0.286602860000016, 9.432618982000065],
                [0.287035735000075, 9.432219098000019],
                [0.286953764000089, 9.4311526460001],
                [0.28701819000014, 9.430494385000088],
                [0.287826113000108, 9.430684912000117],
                [0.287827376000109, 9.431027751000158],
                [0.288553467000042, 9.431627579000065],
                [0.288805697000157, 9.43209669600003],
                [0.288841629000103, 9.432787631999986],
                [0.28848693600014, 9.433209115000182],
                [0.288579434000155, 9.43412629800008],
                [0.289009643000156, 9.434517318000132],
                [0.289665975000105, 9.43437110100001],
                [0.289480697000045, 9.43394075700013],
                [0.28976759700015, 9.433237638000094],
                [0.290079059000163, 9.433170068000038],
                [0.290520917000094, 9.433688199000187],
                [0.291467097000066, 9.433623674999978],
                [0.291541208000069, 9.434071189000065],
                [0.291036607000137, 9.434609534000117],
                [0.292336637000062, 9.435373019000167],
                [0.293477358000018, 9.435214064000036],
                [0.29431840500007, 9.435288280000123],
                [0.295645245000117, 9.435764592000112],
                [0.296945977000064, 9.436693952000098],
                [0.297988304000057, 9.437005215],
                [0.298493466000139, 9.436610875000042],
                [0.298803104000172, 9.436029331999976],
                [0.299309951000112, 9.436060624000106],
                [0.299639520000085, 9.436374381000064],
                [0.299644152000099, 9.437585094000099],
                [0.299763037999981, 9.438104326000087],
                [0.300349751000169, 9.438677280000093],
                [0.300762414000076, 9.438847034000105],
                [0.301723612000046, 9.438337495000098],
                [0.30208111200011, 9.438626603000046],
                [0.302892123000163, 9.438142541000161],
                [0.303619477000154, 9.43908518700016],
                [0.304246614000078, 9.438535496000043],
                [0.304786024000123, 9.438367680000056],
                [0.305048501000101, 9.438576757000021],
                [0.305007094000132, 9.439406141000063],
                [0.305521801000168, 9.440084323000065],
                [0.305334418000029, 9.440571428000112],
                [0.304565515999968, 9.440452629000163],
                [0.304311741000049, 9.441061856000033],
                [0.30475275900011, 9.441375330000028],
                [0.305922253000062, 9.441577760000087],
                [0.306446925000159, 9.441800125999976],
                [0.307044726000129, 9.442334306000134],
                [0.308293945, 9.442887868000128],
                [0.30855150900004, 9.443251741000097],
                [0.308181936999972, 9.444192988000111],
                [0.309078147000093, 9.444117943000037],
                [0.309934775000045, 9.443898901000182],
                [0.31033073500015, 9.444085544000075],
                [0.310711817000083, 9.44473657900005],
                [0.311224419000041, 9.444839592000108],
                [0.311931139000023, 9.44473187200009],
                [0.311966792000078, 9.445339983000054],
                [0.312219864000042, 9.44598021600018],
                [0.312967150000077, 9.446320270000058],
                [0.314735849000101, 9.445838157000082],
                [0.316243755000187, 9.445561517000101],
                [0.316895454000189, 9.445647638000025],
                [0.318529125000111, 9.446260733000031],
                [0.319943130000127, 9.446211165000022],
                [0.320646202000034, 9.446589987000095],
                [0.32105479500018, 9.447174281000116],
                [0.321621435000168, 9.44827806700016],
                [0.321501847000036, 9.449057860000096],
                [0.32217039, 9.4491492410001],
                [0.322893393000072, 9.448958724000079],
                [0.323198680000189, 9.448692055000095],
                [0.324515695000173, 9.448034935000123],
                [0.324930464000147, 9.44729806200013],
                [0.325270140000157, 9.447313293000093],
                [0.325868221000178, 9.447913646000075],
                [0.326568626000153, 9.447607100000141],
                [0.326660703000186, 9.446954409000057],
                [0.327167128000099, 9.44686385700004],
                [0.327926064000167, 9.44730886200017],
                [0.328245528000139, 9.447871555000063],
                [0.328314726000031, 9.448507075000066],
                [0.328673350000031, 9.449102720000099],
                [0.330220839000049, 9.449873648999983],
                [0.330569077000121, 9.449775622000175],
                [0.33056149700019, 9.449146195000026],
                [0.330759266000143, 9.448575197000025],
                [0.331320433000087, 9.448268928000175],
                [0.331638633000125, 9.448488799000188],
                [0.332099020000101, 9.449465754000073],
                [0.332984141000111, 9.45088853700014],
                [0.333606224000107, 9.451491377000082],
                [0.333608751000099, 9.452187535000121],
                [0.334062119000123, 9.45259238000017],
                [0.334660200000144, 9.452245686000083],
                [0.334502154000177, 9.451557282000124],
                [0.334769542000117, 9.45107960700011],
                [0.335547567999981, 9.451290061000122],
                [0.336504274000106, 9.4520958760001],
                [0.337197941000056, 9.451740321000102],
                [0.337609061000137, 9.451877393000075],
                [0.337260262000143, 9.452555274000076],
                [0.337320618000149, 9.452845200000127],
                [0.33788094200014, 9.453247553000097],
                [0.338137945000028, 9.454415009000058],
                [0.339104896000151, 9.455386685000178],
                [0.339464082000063, 9.456045453000115],
                [0.339457064000158, 9.456744093000054],
                [0.34011690400007, 9.457352461000085],
                [0.341094384000087, 9.457854218000136],
                [0.341254958000093, 9.458306685000025],
                [0.340886087, 9.46043000300017],
                [0.341598002000012, 9.460649035000188],
                [0.342832765000139, 9.46266130000015],
                [0.34252663500007, 9.463161388000117],
                [0.342601589000026, 9.463877459000116],
                [0.342895647000034, 9.464540640000052],
                [0.342521302000023, 9.46537965300007],
                [0.342987582999967, 9.466069690999973],
                [0.344092511000099, 9.466123408999977],
                [0.344046613000046, 9.46738884400014],
                [0.344316388000095, 9.467988886000057],
                [0.344326354000032, 9.46850419500015],
                [0.344109635000109, 9.469105065000122],
                [0.344324810000046, 9.469506568000043],
                [0.345093992000102, 9.469580777000033],
                [0.345293026000036, 9.470803557000181],
                [0.345563783000159, 9.471185398000046],
                [0.34525877700014, 9.471991167],
                [0.34533864299999, 9.472525576000066],
                [0.345868789000178, 9.472912122000082],
                [0.345725199000185, 9.473441823000144],
                [0.346112177000066, 9.473746131000098],
                [0.346728505000101, 9.473763021000082],
                [0.346962488000145, 9.473974292000037],
                [0.34692781900003, 9.47504642600012],
                [0.347086006000097, 9.476498176000177],
                [0.347477055000184, 9.477835842000161],
                [0.347673842000063, 9.477900081000087],
                [0.347521549000135, 9.479252139000039],
                [0.347891683000057, 9.480226237000124],
                [0.347375011999986, 9.480820719000121],
                [0.348146300000053, 9.481470854000179],
                [0.347995270000183, 9.481890339000188],
                [0.346978490000083, 9.483062682000082],
                [0.34597532399999, 9.483034163000127],
                [0.345552695000151, 9.48331631100001],
                [0.344804005000185, 9.484198194999976],
                [0.344575917000157, 9.484813436000024],
                [0.344720911000081, 9.48567288900017],
                [0.344591217000186, 9.486582732000045],
                [0.344238487000098, 9.487242547000164],
                [0.343770662000054, 9.487577853],
                [0.343636476000142, 9.48803858700012],
                [0.34394920200009, 9.488519533000158],
                [0.344299123000155, 9.488576294000097],
                [0.344973001000142, 9.487688883000146],
                [0.345396332000064, 9.488129681000032],
                [0.34543493100017, 9.488650775000167],
                [0.345979114000158, 9.488748792000024],
                [0.346769631000086, 9.48859152200015],
                [0.347210086000018, 9.48836115600011],
                [0.347752164000156, 9.488571033000142],
                [0.348312488000147, 9.489180728000065],
                [0.348818772000072, 9.490193009000052],
                [0.349147078999977, 9.490381842000147],
                [0.350321205000171, 9.4903774120001],
                [0.35063, 9.49053052700009],
                [0.35078804800014, 9.49121885300002],
                [0.350713797000139, 9.49209822500012],
                [0.350350961000117, 9.49301718800001],
                [0.350815559000182, 9.493285485000115],
                [0.351701944000069, 9.493510587000117],
                [0.352953690000106, 9.493486499000028],
                [0.35366813100012, 9.493731813000124],
                [0.354941493000183, 9.493910123000092],
                [0.355366368000034, 9.494317687999967],
                [0.355446936000135, 9.495025664000138],
                [0.35565972400002, 9.495485004000102],
                [0.356144393000022, 9.495943235000084],
                [0.35676170500011, 9.496208207],
                [0.357668722000142, 9.496050387000082],
                [0.358655184000042, 9.497002842000143],
                [0.358578828000134, 9.497347552000065],
                [0.359236422000151, 9.497995440000182],
                [0.358774913000104, 9.498496030000013],
                [0.35900945800006, 9.498839632000056],
                [0.359626909000042, 9.499181572000111],
                [0.360106525000106, 9.499025415000062],
                [0.360667832000104, 9.499177419000034],
                [0.361128499000131, 9.498792564000041],
                [0.361630152000146, 9.498925740000061],
                [0.362323258000117, 9.49871088500015],
                [0.362859721, 9.498212788000046],
                [0.36340334200014, 9.498783704],
                [0.364326361000053, 9.498474434],
                [0.36474983200003, 9.498166827000034],
                [0.364996869000095, 9.497457748000102],
                [0.365304823000031, 9.497398497000063],
                [0.36634336000003, 9.496802106000018],
                [0.367521135000061, 9.49700699499999],
                [0.36786684600014, 9.496699662000083],
                [0.369328012999972, 9.496272165000164],
                [0.369590208000091, 9.495896444000039],
                [0.370480102000045, 9.495625658000051],
                [0.371472319000134, 9.495155520000026],
                [0.372801264000032, 9.494731344000115],
                [0.374290923000103, 9.494144639000126],
                [0.375084108000124, 9.493981834000067],
                [0.376103976000138, 9.493556272000035],
                [0.376585417000058, 9.493173902000024],
                [0.377452993000077, 9.49351778600004],
                [0.379546208000022, 9.49302217200011],
                [0.380091934, 9.493350274000079],
                [0.380707702000166, 9.493251428000065],
                [0.381040640000037, 9.492530988000112],
                [0.382342636000089, 9.491867030000094],
                [0.382998967000105, 9.492178797],
                [0.383440124000117, 9.492152216000079],
                [0.384210008000082, 9.491688996999983],
                [0.384725698000068, 9.49201876],
                [0.385294023000142, 9.491966707000074],
                [0.385597344000075, 9.492546493000077],
                [0.385306093000054, 9.492887886000176],
                [0.385878208000065, 9.493790234000073],
                [0.386227007000059, 9.493207958000028],
                [0.386652303000062, 9.492940770000132],
                [0.386975697000025, 9.49331704900004],
                [0.388106872000094, 9.49232388200005],
                [0.388261550999971, 9.491855401000123],
                [0.389452941000172, 9.491590981000058],
                [0.390534288000026, 9.4919124380001],
                [0.391342211000165, 9.492043680000108],
                [0.392652488000124, 9.492076905000147],
                [0.393542663000176, 9.492588025000146],
                [0.394564636000041, 9.492149171000051],
                [0.39544231800005, 9.492554246000111],
                [0.396095001000106, 9.49343195299997],
                [0.396496716000058, 9.493555995000122],
                [0.396979982000062, 9.493009159000167],
                [0.397632523000141, 9.492550923000067],
                [0.398167302000104, 9.492999192000127],
                [0.398291101000098, 9.493596143000048],
                [0.398674007000182, 9.494306889000086],
                [0.399156992000087, 9.494013122000183],
                [0.398860548000073, 9.493467394000163],
                [0.40031989, 9.493249213000126],
                [0.401026892000061, 9.49352775300008],
                [0.401527563, 9.494311597000092],
                [0.401568548000114, 9.49520342000011],
                [0.401965070000074, 9.495393081000088],
                [0.402348398000186, 9.494956168000044],
                [0.403316613000072, 9.49517545499998],
                [0.403613057000086, 9.494663785000114],
                [0.404419576000123, 9.49409452399999],
                [0.405762558000049, 9.49418783200008],
                [0.40659013100003, 9.493915937000054],
                [0.407552030000147, 9.494045793000168],
                [0.40841511400015, 9.494500704000018],
                [0.408506490000036, 9.49480582300015],
                [0.408259172000044, 9.495954588000075],
                [0.408728541000016, 9.49724012400003],
                [0.409325501000069, 9.497290515000032],
                [0.409756131000165, 9.497554655000044],
                [0.410519839000131, 9.497533059000034],
                [0.411236665999979, 9.497072061000097],
                [0.4116385210001, 9.497431168000048],
                [0.411609607000059, 9.498779274000185],
                [0.412195337000185, 9.499982845000034],
                [0.412457252000081, 9.499839424000186],
                [0.412997364000034, 9.498957857000164],
                [0.413340829000106, 9.499317240000096],
                [0.413882345000161, 9.499420514000121],
                [0.413662118000104, 9.500055663000012],
                [0.414108468000109, 9.500255288000119],
                [0.414041936000103, 9.500823156000024],
                [0.414571100000046, 9.501184198000033],
                [0.414760588000092, 9.502109781000172],
                [0.415361477000147, 9.502432613000053],
                [0.417402478000156, 9.501964146000091],
                [0.417630144000157, 9.501274181000099],
                [0.41739742499999, 9.500702162000152],
                [0.417586492000112, 9.500125435000086],
                [0.418139798000084, 9.500034067],
                [0.418551479000087, 9.500542960000132],
                [0.419053694000183, 9.500502537000045],
                [0.419667214000128, 9.499868773],
                [0.419975168000065, 9.499809799000104],
                [0.420862676000127, 9.500304849000031],
                [0.421500620000131, 9.500186624000094],
                [0.422248608000018, 9.499839148000149],
                [0.422829705000083, 9.500068122000073],
                [0.424370176000082, 9.500062031000027],
                [0.425717929000143, 9.499750548000065],
                [0.426795346000176, 9.499189601000126],
                [0.42781970700014, 9.499893691000125],
                [0.429263749000029, 9.499352957000156],
                [0.42945071000014, 9.49826096400011],
                [0.430046266000033, 9.497972183000115],
                [0.431534240000076, 9.498693442000047],
                [0.432228610000152, 9.498784257000182],
                [0.432690961000105, 9.498515135000105],
                [0.433994220000045, 9.498854306],
                [0.435321902000112, 9.499063622999984],
                [0.436397775000046, 9.499447925000027],
                [0.437564462000182, 9.499579440000161],
                [0.438731430000132, 9.499959034000085],
                [0.440233441000146, 9.50069690100014],
                [0.445276499000158, 9.501238187000183],
                [0.445957534000115, 9.50149457100008],
                [0.44735974800011, 9.500912862000177],
                [0.44902584200014, 9.500423627000089],
                [0.450277308000125, 9.499657795000132],
                [0.451696927000057, 9.498399954999968],
                [0.452233810000166, 9.498743556000079],
                [0.453848953000147, 9.498179839000045],
                [0.454581080000025, 9.498022574000117],
                [0.456546566000156, 9.498091517000034],
                [0.457450776000144, 9.497302422000018],
                [0.458276384999976, 9.496935284000017],
                [0.458698032000143, 9.496189379000043],
                [0.458385446000023, 9.49513807599999],
                [0.458496471999979, 9.493837027000097],
                [0.459573748000139, 9.493256412000108],
                [0.460075823000182, 9.49383038200017],
                [0.460328193000123, 9.494441176000066],
                [0.460773982000148, 9.494822160000183],
                [0.461256966000121, 9.494820221000111],
                [0.46168212300006, 9.494491844000038],
                [0.462312768000118, 9.494432869000036],
                [0.462275431000137, 9.494126918],
                [0.46165503200001, 9.493785249999974],
                [0.461480984000048, 9.493345013000067],
                [0.461690825000062, 9.493057613000076],
                [0.462502537000091, 9.493359965000082],
                [0.462711535000153, 9.492898961000094],
                [0.462743819000139, 9.49135009500003],
                [0.463529845000153, 9.49017861100009],
                [0.464089467000122, 9.490559323000127],
                [0.464497639000115, 9.491246542000056],
                [0.464962236000133, 9.491511793000086],
                [0.465964560000089, 9.491584612000167],
                [0.466614575000165, 9.490416452000147],
                [0.467670657000099, 9.489425492000066],
                [0.46857767400013, 9.489928587000179],
                [0.469389106000108, 9.490153969000175],
                [0.470310441000038, 9.489441829000043],
                [0.470734614000037, 9.489346581000177],
                [0.471467442000119, 9.489993654000159],
                [0.471967833000065, 9.48955064300003],
                [0.472392988000138, 9.489645337000013],
                [0.473180839000065, 9.4895456590001],
                [0.474130947000162, 9.490403991999983],
                [0.474496309000187, 9.490151754000067],
                [0.474319593000132, 9.48971455700007],
                [0.474543330000074, 9.489032597000119],
                [0.475201627000104, 9.488944548000063],
                [0.475569656000062, 9.489306987000077],
                [0.476321152000082, 9.489168546000087],
                [0.476553311000032, 9.488939010000138],
                [0.476393860000087, 9.487983487000179],
                [0.477094686000157, 9.487349424000058],
                [0.477204449000055, 9.488018652000164],
                [0.477513385000066, 9.488207485999965],
                [0.477819514000089, 9.487745921000055],
                [0.478673054000183, 9.48744190300016],
                [0.478896791000125, 9.487187447000053],
                [0.479007115000172, 9.486363162000146],
                [0.479508908000071, 9.486228874000062],
                [0.480012948000024, 9.487279373000035],
                [0.480555024000125, 9.487467100000174],
                [0.480840801000056, 9.48679621000008],
                [0.480756303000135, 9.486449274000165],
                [0.481490957000176, 9.485666520000052],
                [0.481700096000168, 9.485243992999983],
                [0.482414116000086, 9.4854117860001],
                [0.483321134000164, 9.485275835000152],
                [0.48398013200017, 9.486190664000105],
                [0.484288788000129, 9.486305017000063],
                [0.48559878400016, 9.486299479000138],
                [0.485557096999969, 9.485649353000156],
                [0.485786308000058, 9.485378283000102],
                [0.486695852000139, 9.485815208000133],
                [0.487120166000068, 9.48638669800016],
                [0.487602589000119, 9.486271514],
                [0.489145305000079, 9.486780428000031],
                [0.489567934000092, 9.48631830700009],
                [0.490261321000048, 9.486202569000113],
                [0.490904880000073, 9.485441689000027],
                [0.490796100000125, 9.484706558000028],
                [0.491488785000172, 9.484417212000096],
                [0.492063005000034, 9.483706445000166],
                [0.492638769000109, 9.483359505000067],
                [0.492715266000118, 9.483072650000054],
                [0.492327727000088, 9.4826719940001],
                [0.492517214999964, 9.482213745000081],
                [0.493484729000102, 9.48259003600009],
                [0.494215734000079, 9.48216805900006],
                [0.494289283000057, 9.48186182],
                [0.493632670000181, 9.481481652000184],
                [0.493631267000126, 9.481175691000033],
                [0.49421039900011, 9.480941719000043],
                [0.494826868000018, 9.481016479000175],
                [0.495247673000108, 9.480785831000105],
                [0.495630159000143, 9.480018294000104],
                [0.497421596000152, 9.47966609000008],
                [0.497555501000136, 9.479169625000111],
                [0.497976446000052, 9.478936207000174],
                [0.497840856000039, 9.478421467000032],
                [0.497434228000145, 9.478098058],
                [0.497740638000096, 9.477694627000119],
                [0.498473888000149, 9.477826428000071],
                [0.498780860000181, 9.477555074],
                [0.499357606000103, 9.47743961000009],
                [0.499548638000078, 9.476692001000117],
                [0.501161675000048, 9.476158706000149],
                [0.501390746000084, 9.47587129100009],
                [0.502504235000117, 9.475329134000049],
                [0.503064560000041, 9.475263233000021],
                [0.503079860000071, 9.474962804000143],
                [0.503751350000186, 9.47427112299999],
                [0.50320492100002, 9.473733393000089],
                [0.502523606000068, 9.474061513000095],
                [0.502485006000029, 9.473463698000103],
                [0.501608026000156, 9.473497755000153],
                [0.501729579000141, 9.472723002000066],
                [0.500924604999966, 9.472704451000027],
                [0.500774137000178, 9.471925266000142],
                [0.501893942000152, 9.471584129000064],
                [0.501849588000027, 9.470950590000143],
                [0.502627754000116, 9.470569855000178],
                [0.502184913000178, 9.470194106000065],
                [0.502238391000162, 9.469744700000035],
                [0.502561784000022, 9.469421007000165],
                [0.503324651000128, 9.469337937000034],
                [0.503264716000103, 9.468954986000085],
                [0.502626491000115, 9.468365469000048],
                [0.502922373000104, 9.46809687700005],
                [0.503418131000046, 9.46849699600017],
                [0.503881746000161, 9.468514164000169],
                [0.504629734000048, 9.468855580000024],
                [0.505002675000071, 9.468443277000063],
                [0.505072574999986, 9.467933229000153],
                [0.505745189000038, 9.46750874200012],
                [0.505366072000015, 9.466803477000099],
                [0.505720625000151, 9.466323886000168],
                [0.505294066000147, 9.46590410400006],
                [0.505297294000059, 9.465372453000043],
                [0.50477472700004, 9.46517086900002],
                [0.504692616000114, 9.464801759000068],
                [0.504972076000115, 9.463892412000064],
                [0.504507900000078, 9.463174125000023],
                [0.504574712000135, 9.462276958000132],
                [0.50403684600002, 9.462073986000064],
                [0.504207104000045, 9.461648939000156],
                [0.503587548000155, 9.460860590000038],
                [0.50385718300015, 9.459688449000112],
                [0.504148433000125, 9.459023043000116],
                [0.50397185800017, 9.45797661100005],
                [0.503157478999981, 9.457051462000152],
                [0.502325134000159, 9.457126781000056],
                [0.500666199000079, 9.456643299000064],
                [0.499691947000144, 9.456620038000153],
                [0.499151555000026, 9.456164800000124],
                [0.498262082000053, 9.45582420200003],
                [0.497565747000067, 9.455251277000173],
                [0.497450230000027, 9.454852250000101],
                [0.498101929000029, 9.454102654000053],
                [0.498119475000067, 9.453661812000178],
                [0.497535429000038, 9.4527272360001],
                [0.497228037000184, 9.452880091000054],
                [0.496687785000063, 9.45246361600016],
                [0.496014750000086, 9.452772373000016],
                [0.49543603800015, 9.45243038600006],
                [0.495086678000064, 9.451856070000133],
                [0.495198967000022, 9.451513806000094],
                [0.495661319000078, 9.451280369000017],
                [0.495176369000092, 9.450805740000135],
                [0.495501728000079, 9.450286251000023],
                [0.496062333000054, 9.45030314200011],
                [0.496502507000059, 9.45005336600002],
                [0.49717119100012, 9.448711162],
                [0.497113642000045, 9.448251206000123],
                [0.49664988700016, 9.448176162000152],
                [0.496339828000146, 9.447720082000046],
                [0.495641809000062, 9.447105606000036],
                [0.495790311000064, 9.445771421000018],
                [0.496478224000043, 9.445195709000188],
                [0.497256390000132, 9.445475396000177],
                [0.49760827700004, 9.44498525099999],
                [0.498548279000033, 9.444323694000047],
                [0.499446314000181, 9.443973944999982],
                [0.499971689000176, 9.44402296000004],
                [0.500300556000127, 9.443091127000116],
                [0.499878489000139, 9.44288399100003],
                [0.499127693000162, 9.44295571400005],
                [0.498815247999971, 9.442579934000037],
                [0.499123482000073, 9.44200421700009],
                [0.498991682, 9.441464776000146],
                [0.49940448600006, 9.441094256000042],
                [0.499358027000028, 9.44031444500007],
                [0.499858697000093, 9.440312230000188],
                [0.499934492000023, 9.439866108000047],
                [0.500672515000076, 9.439287341000181],
                [0.501016682000056, 9.438558479999983],
                [0.500652443000035, 9.438191557000096],
                [0.500640512000189, 9.437428632000149],
                [0.50128154500004, 9.437923218000037],
                [0.50211571400007, 9.437182447000112],
                [0.502435459000139, 9.437104354000155],
                [0.502697233000106, 9.43797140200013],
                [0.503155374000073, 9.438644603000114],
                [0.503155655, 9.439358233000178],
                [0.503682854000033, 9.439962201000071],
                [0.504465791000086, 9.438779464000163],
                [0.50493740700017, 9.438735988000133],
                [0.505760769000119, 9.43917906400003],
                [0.50594885400011, 9.439021218000107],
                [0.505793333000156, 9.437715525000158],
                [0.506376536000175, 9.43781771],
                [0.507311486000049, 9.437778110000124],
                [0.507765416000154, 9.438120387000083],
                [0.508078423000029, 9.437992449000149],
                [0.508300475000112, 9.436737705999974],
                [0.508664152000051, 9.436127086000113],
                [0.510183849000157, 9.436517551000179],
                [0.511172416000136, 9.437281862000077],
                [0.510944890000133, 9.437924879000093],
                [0.511986515000103, 9.438135341000077],
                [0.512620529000117, 9.43779943300018],
                [0.51286335500015, 9.438128972000186],
                [0.513677032000089, 9.438252203000161],
                [0.513999162000061, 9.4376665100001],
                [0.51436831400008, 9.437684233000084],
                [0.514625877000128, 9.438176603000102],
                [0.51513861900014, 9.438689465000095],
                [0.515588759000082, 9.438789434000114],
                [0.515873554000109, 9.439187926000045],
                [0.516352047000169, 9.438819341],
                [0.51683573300005, 9.439032295000061],
                [0.517088524999963, 9.439719893000074],
                [0.517709906000164, 9.439651217000119],
                [0.518144607000124, 9.440057184000011],
                [0.519564085000184, 9.439734017000035],
                [0.51978627799997, 9.439151649000166],
                [0.520112619000088, 9.438883034000014],
                [0.52102553200001, 9.43886808000002],
                [0.521290675000046, 9.438566511000033],
                [0.522191657000178, 9.438364357000012],
                [0.522399814000039, 9.437743495000177],
                [0.522900344000107, 9.437316754000108],
                [0.522861324000075, 9.436672352000073],
                [0.523101482000186, 9.436362473000145],
                [0.523532253000042, 9.436517828000092],
                [0.523677246000034, 9.436034871],
                [0.523055443000032, 9.434720582000068],
                [0.523223877000135, 9.433915002000049],
                [0.52319033200007, 9.4332141000001],
                [0.523681598000053, 9.433235423000042],
                [0.524054539000076, 9.432839694000052],
                [0.52449008200017, 9.431550870000024],
                [0.525529881000125, 9.431339850000143],
                [0.526024797000048, 9.430932487000064],
                [0.527304334000121, 9.430362013000149],
                [0.527528352000047, 9.430807593000054],
                [0.527853008000079, 9.430795129999979],
                [0.52807562300012, 9.43125704800002],
                [0.52853614899999, 9.43090507200003],
                [0.529576089000102, 9.430759961000035],
                [0.529898360000175, 9.430824762000157],
                [0.530346395000038, 9.430478323000159],
                [0.530512583000132, 9.431050736000032],
                [0.531541154000024, 9.430825870000035],
                [0.531994944000132, 9.43116843100006],
                [0.532410415000129, 9.430970981000087],
                [0.532571971000152, 9.42922327600013],
                [0.531842231000155, 9.429262323000103],
                [0.531804894000175, 9.428953823000143],
                [0.533455127000025, 9.42813106],
                [0.533895863000112, 9.42864033700016],
                [0.534440888000177, 9.428889851000179],
                [0.535400541000058, 9.428160967999986],
                [0.535953707000033, 9.428373652],
                [0.535901352000053, 9.42907373300011],
                [0.536375353999972, 9.428944961000127],
                [0.536555719000091, 9.428335159000142],
                [0.536946065000052, 9.4281130600001],
                [0.537179065000146, 9.427447870000151],
                [0.537765216000082, 9.4276134750001],
                [0.538453971000138, 9.427081488000056],
                [0.539095845000134, 9.426020559000108],
                [0.538896251000097, 9.42546752300018],
                [0.539753440000084, 9.42538665800015],
                [0.54099451899998, 9.424920855000039],
                [0.541618708000044, 9.424876823000147],
                [0.542112922000058, 9.424287783000068],
                [0.542379889000074, 9.42443815900009],
                [0.542410349000079, 9.425038829000073],
                [0.542862594000098, 9.425028583000085],
                [0.54331820800013, 9.424511269999982],
                [0.543895796000129, 9.424596841999971],
                [0.544298915000184, 9.424148762000016],
                [0.544443207000086, 9.423503227000026],
                [0.545590525000136, 9.423782100999972],
                [0.545947605000038, 9.423584923000021],
                [0.54630959800005, 9.423880967000173],
                [0.546908802000075, 9.423842472000103],
                [0.547365539000111, 9.422956003000024],
                [0.548326878000125, 9.42322463100004],
                [0.548445343000083, 9.422701776000054],
                [0.549133677000043, 9.42243564000006],
                [0.549720951000097, 9.422810058000096],
                [0.550328998000055, 9.422844951000059],
                [0.550757662000024, 9.423144873000183],
                [0.551006945000097, 9.423690712000166],
                [0.551500738000186, 9.42367908100016],
                [0.55158818200016, 9.424131316],
                [0.552262621000068, 9.424166210000067],
                [0.553314913000122, 9.423756069000149],
                [0.553895871000066, 9.422763531999976],
                [0.554431632000046, 9.422902278000095],
                [0.554648912000118, 9.422637250000037],
                [0.555445605000045, 9.42241708600011],
                [0.5553393510001, 9.421990881000113],
                [0.555527295000047, 9.420577948000187],
                [0.555752434999988, 9.420061460000056],
                [0.556178153000076, 9.419679562000113],
                [0.556352903000118, 9.41906835900005],
                [0.5559959630001, 9.418657935],
                [0.556396415000165, 9.417623012000092],
                [0.55663096000012, 9.416712433000157],
                [0.555949644000066, 9.415133867000122],
                [0.556167064000135, 9.414730640000073],
                [0.55595427600008, 9.414296117999982],
                [0.556134360000101, 9.413623146000077],
                [0.556644575000121, 9.412915555999973],
                [0.557133735999969, 9.412461368000038],
                [0.557072959000038, 9.411910526000156],
                [0.557392703000176, 9.411438058000101],
                [0.55731718800007, 9.410680613000068],
                [0.557648162000078, 9.410240823000095],
                [0.558639537000147, 9.409787463000043],
                [0.558962509000025, 9.409416632000045],
                [0.559485497000139, 9.409689978000074],
                [0.560474346000149, 9.409935075000021],
                [0.560371601000099, 9.409292006000044],
                [0.560971227000152, 9.409644835000165],
                [0.562466360000144, 9.409233293000057],
                [0.563153571000043, 9.40841186800003],
                [0.563265439000077, 9.407987031000118],
                [0.564346225000179, 9.407656909000025],
                [0.56513056700004, 9.406837973999984],
                [0.565001294000069, 9.40647184800008],
                [0.565483015000098, 9.405910471000084],
                [0.566030005000016, 9.40631315500002],
                [0.566590189000181, 9.405599734000077],
                [0.564910404000045, 9.380816819000017],
                [0.563125779000075, 9.354487144000132],
                [0.55275753699999, 9.345657133000032],
                [0.552872914000034, 9.345403127000168],
                [0.552661810000188, 9.344117025000173],
                [0.552882879000094, 9.343289629000083],
                [0.553677187000062, 9.341580540000166],
                [0.554059954000024, 9.340958672000056],
                [0.553990053, 9.340201350000086],
                [0.554278778000025, 9.339615768000158],
                [0.555627654000091, 9.337879514],
                [0.556066987000122, 9.33746705599998],
                [0.556251843000098, 9.335986467000168],
                [0.556133518000024, 9.33432],
                [0.556306163000158, 9.333828591000156],
                [0.557451374000152, 9.331828596000094],
                [0.55765700500001, 9.330656849000093],
                [0.558165114000076, 9.330128314000149],
                [0.558430117000057, 9.329220553000084],
                [0.559781941000153, 9.327519981000023],
                [0.562991734000093, 9.324456217000034],
                [0.56367024299999, 9.31729920399999],
                [0.560235871000089, 9.309414021000123],
                [0.55977660700006, 9.305739526000139],
                [0.55392043400002, 9.30539517900013],
                [0.541174744000102, 9.306658153],
                [0.539681996000184, 9.306313530000182],
                [0.53738553800008, 9.301835334000032],
                [0.54055448500003, 9.30115799000015],
                [0.534319475000132, 9.294793105999986],
                [0.515874255000028, 9.272841056000175],
                [0.508535159000132, 9.258097813000063],
                [0.507950693000112, 9.254590685000153],
                [0.515338914999973, 9.238462101000039],
                [0.515283473000181, 9.237153440000043],
                [0.516014055000085, 9.236770233000016],
                [0.516332676000047, 9.236038455000084],
                [0.517344825000066, 9.235359044000063],
                [0.517616987000167, 9.234801272000084],
                [0.517562667000107, 9.234421389000033],
                [0.517015817000186, 9.233646657000065],
                [0.51763579500016, 9.232712599000024],
                [0.518709984000111, 9.231597600000043],
                [0.518818624000062, 9.231059773000084],
                [0.518614959000161, 9.230771324000102],
                [0.518826484000158, 9.2303241030001],
                [0.518894139000167, 9.22928224900005],
                [0.52002012000014, 9.228602271000113],
                [0.520241892000058, 9.228593127000067],
                [0.52070663000012, 9.227663766000035],
                [0.520825095000077, 9.226810603000047],
                [0.522596321000037, 9.225276345000111],
                [0.523555552000119, 9.225184073000094],
                [0.524056083000062, 9.224843249000116],
                [0.524372598000014, 9.224274099000127],
                [0.525138833000085, 9.223810799000148],
                [0.525338007000073, 9.222423942999967],
                [0.525885418000144, 9.221994446000053],
                [0.527097862000119, 9.220834800000034],
                [0.527714331000027, 9.22034711100008],
                [0.539054158000113, 9.213539895000167],
                [0.532808481000188, 9.20641138000019],
                [0.527184325000064, 9.197684885000115],
                [0.52614185900012, 9.197741694000115],
                [0.525370009000028, 9.19754106300013],
                [0.525215050000099, 9.196965773000159],
                [0.525244666000049, 9.196128054000042],
                [0.524880006000103, 9.1957905270001],
                [0.524909763000153, 9.19497469800018],
                [0.52433287600013, 9.1949688790001],
                [0.523723846000166, 9.194574819000024],
                [0.523347537000063, 9.194121733000031],
                [0.523079445000064, 9.194326799000123],
                [0.522136776000139, 9.19373266000008],
                [0.521753589000127, 9.192957285000148],
                [0.521922023000059, 9.19214643700019],
                [0.522643482000149, 9.19160051300014],
                [0.522432518000073, 9.190887763000148],
                [0.522630428000127, 9.190498409999975],
                [0.52193760199998, 9.189299864000134],
                [0.521816049000165, 9.188768624000033],
                [0.522038804000033, 9.18833492900012],
                [0.521655194000061, 9.187449247000018],
                [0.521176, 9.185641021000151],
                [0.520775267000033, 9.18530376100017],
                [0.520342672000027, 9.184646976000067],
                [0.52008061600003, 9.183036049000123],
                [0.519599878, 9.182789960999969],
                [0.51926974700018, 9.182110725000086],
                [0.518675736000148, 9.181336156000043],
                [0.518605274000151, 9.180427179000048],
                [0.518002280000189, 9.179468038000039],
                [0.517623864000143, 9.179188416000159],
                [0.516825346000132, 9.179216683000107],
                [0.516400331000057, 9.179039321000175],
                [0.516338993000147, 9.178303820000167],
                [0.516080306000163, 9.177486564000048],
                [0.516074832000015, 9.176819789000035],
                [0.515449520000175, 9.175863411000137],
                [0.515190692999965, 9.174988229000178],
                [0.515118126000118, 9.174228055000185],
                [0.514902952000057, 9.173823719000097],
                [0.514091941, 9.17286539200012],
                [0.513970388000189, 9.172323041000084],
                [0.514676969000107, 9.170884435000062],
                [0.514503201000139, 9.169802221000054],
                [0.514512606000096, 9.16875797099999],
                [0.513263245000189, 9.167749191000041],
                [0.512761593000107, 9.167158888000131],
                [0.51235314000013, 9.164994710000087],
                [0.511797448000152, 9.1640820880001],
                [0.511197541000172, 9.163869520999981],
                [0.509685846000082, 9.163752014000124],
                [0.509249601000136, 9.163563558000021],
                [0.508948244000123, 9.162459981000097],
                [0.508976317000077, 9.161861909000152],
                [0.508125724000138, 9.161383007000154],
                [0.507559786000172, 9.161319264000099],
                [0.50677151400015, 9.161804818000121],
                [0.506370781000101, 9.161481392000042],
                [0.506237578000025, 9.160779112],
                [0.505016571000169, 9.160572087000048],
                [0.50451239099999, 9.160028608000061],
                [0.504419331000122, 9.158992922000039],
                [0.504473792000113, 9.158099961000119],
                [0.504110255000114, 9.157371069000021],
                [0.504093412000088, 9.156671275],
                [0.503784334999978, 9.15636946300009],
                [0.503712049000058, 9.155669944000067],
                [0.503211659000044, 9.154699097000105],
                [0.503047436000031, 9.153892596000048],
                [0.501580235000063, 9.153821369000127],
                [0.50087940800006, 9.153344950000076],
                [0.500729782000121, 9.15468745600009],
                [0.50040330000013, 9.154854023000155],
                [0.499815045000048, 9.15474621200002],
                [0.498762051000142, 9.154940769],
                [0.498491012000159, 9.154423889000043],
                [0.498052663000067, 9.154373448000058],
                [0.497872157000188, 9.154977630000133],
                [0.497416823000037, 9.155492016000096],
                [0.49630221100017, 9.156213152000078],
                [0.4943242330001, 9.155309099000135],
                [0.493641935000142, 9.15489337800011],
                [0.492504583000027, 9.154512022000176],
                [0.492117044000167, 9.154014818000064],
                [0.491146443000048, 9.154005118000157],
                [0.490581907000092, 9.153281206000088],
                [0.490281253000092, 9.15266371700011],
                [0.489809356000023, 9.152533457000175],
                [0.489667591000114, 9.151773512999966],
                [0.489222223000183, 9.151367488000062],
                [0.489017716000092, 9.15086141099999],
                [0.488360542000066, 9.150864183000067],
                [0.487983110000073, 9.1514692020001],
                [0.487752495000109, 9.151351413000043],
                [0.487852011000143, 9.150631930000088],
                [0.487693262000107, 9.150434044000065],
                [0.486722801000155, 9.150471183000036],
                [0.486280942000121, 9.150889126000095],
                [0.485701248000112, 9.15085004700012],
                [0.485103868000067, 9.150557654000181],
                [0.484711137000147, 9.149484800000039],
                [0.484343669000111, 9.149125334000132],
                [0.483675407000078, 9.149152772000093],
                [0.483399456000086, 9.14879552300016],
                [0.482562759000018, 9.148305794],
                [0.481937167000126, 9.148550797000155],
                [0.481526327999973, 9.147789181000178],
                [0.481067485000153, 9.147474335000084],
                [0.481440567000107, 9.14716946600015],
                [0.481165739000119, 9.145729373000108],
                [0.480843749000087, 9.14564816700016],
                [0.480352903000153, 9.146308626000177],
                [0.480006491000097, 9.146351585000048],
                [0.47948799500017, 9.146046438000155],
                [0.479149442000164, 9.146308072000124],
                [0.478258565999965, 9.145438360000071],
                [0.477497665000158, 9.145176726000102],
                [0.477423132000126, 9.144590264999977],
                [0.476917269000126, 9.144311170000094],
                [0.477901767000048, 9.142989965000083],
                [0.478964024000106, 9.14304622800006],
                [0.479739523000035, 9.14281147600019],
                [0.480264055000077, 9.142255498000168],
                [0.481123069000091, 9.140783235000129],
                [0.481312136000042, 9.14022309700016],
                [0.481084469000109, 9.138832587000024],
                [0.480698896000092, 9.138114742000027],
                [0.480217174000018, 9.137640242000032],
                [0.480187276000038, 9.137100055000019],
                [0.480766831000153, 9.137116962000107],
                [0.481287151000174, 9.136869734000072],
                [0.481651250000027, 9.136416297000096],
                [0.481900392000057, 9.135649112000124],
                [0.48169995600017, 9.134784641000124],
                [0.481951483999978, 9.133904646000076],
                [0.481506818000184, 9.13231649300019],
                [0.481764943000087, 9.131723082],
                [0.48248471800008, 9.13144175799999],
                [0.482524722000164, 9.131052895000096],
                [0.482374955000125, 9.129683968000052],
                [0.482675610000058, 9.128632948000131],
                [0.483651405000046, 9.12792894100005],
                [0.48394434000005, 9.126337989000035],
                [0.483609437000155, 9.125162510000052],
                [0.483682425000154, 9.124704901000143],
                [0.484589724000159, 9.12418575900017],
                [0.485241564000091, 9.123574318000124],
                [0.485410840000043, 9.122920469000064],
                [0.485261495000032, 9.121678179000128],
                [0.484750298000108, 9.12076821800008],
                [0.484370338000133, 9.11939758900013],
                [0.483810716000164, 9.118829378999976],
                [0.483357768000189, 9.117930220000119],
                [0.483652247000123, 9.11739443800019],
                [0.483461074000047, 9.116758594000146],
                [0.484070104000125, 9.11584668],
                [0.484446835000142, 9.115093031000129],
                [0.484976981000159, 9.11522580000019],
                [0.485752761000185, 9.115084716000013],
                [0.485671071000183, 9.114106829000036],
                [0.485980990000144, 9.113637287000074],
                [0.485669527000084, 9.113051606000056],
                [0.486761962000116, 9.112396628],
                [0.48726768500012, 9.111333639000065],
                [0.48679270100007, 9.110445548000087],
                [0.486972785000091, 9.10974760300013],
                [0.486573597000074, 9.109090679000133],
                [0.486834108000039, 9.108403541000143],
                [0.485354835000123, 9.10737906800017],
                [0.485164224000073, 9.106859070000155],
                [0.485527902000172, 9.104969772000118],
                [0.485588818000053, 9.103591876000166],
                [0.485410840000043, 9.102798841000038],
                [0.484973472000092, 9.101613028000031],
                [0.484563054999967, 9.100942230000157],
                [0.484622989000172, 9.10065561600004],
                [0.485363257000131, 9.100652568000157],
                [0.485668965000173, 9.100174414000037],
                [0.485903930000177, 9.098660676],
                [0.48545421099999, 9.098505449000072],
                [0.484229976000051, 9.09879317400015],
                [0.483424861000117, 9.09923307500003],
                [0.482521914000131, 9.099451502000079],
                [0.48074437300005, 9.099354208000022],
                [0.480042143000048, 9.099175143000025],
                [0.478481460000125, 9.099217276000047],
                [0.459374575000084, 9.072417734000112],
                [0.46431137899998, 9.07200635200013],
                [0.462582964000092, 9.05526541],
                [0.456289143000049, 9.055138718000023],
                [0.455466342000079, 9.035597154000186],
                [0.463118444000145, 9.034080371000186],
                [0.46311254900013, 9.03330076800006],
                [0.462907621, 9.031978877000029],
                [0.463138375000085, 9.03078728500003],
                [0.46274774900013, 9.029463444000157],
                [0.463482542000065, 9.028804710000031],
                [0.463731825000025, 9.028095516000121],
                [0.463764108000078, 9.0271556510001],
                [0.464607121000142, 9.02586534400018],
                [0.464810224000132, 9.025352159000079],
                [0.464897109000162, 9.02351649500008],
                [0.465075087000116, 9.023000534000118],
                [0.465702504000092, 9.022548894000067],
                [0.466781325000056, 9.021996057000024],
                [0.468497669000158, 9.021429911000098],
                [0.469685550000122, 9.020430420000082],
                [0.470401535000121, 9.019245167000179],
                [0.470442942000091, 9.018523201000107],
                [0.47014004100015, 9.01764541600005],
                [0.470827954000129, 9.017091462000053],
                [0.470439854000176, 9.016398604000074],
                [0.470457680000038, 9.01600712000004],
                [0.470798479000109, 9.015278215000023],
                [0.470413747000066, 9.01336736400009],
                [0.470837359000143, 9.01254446400003],
                [0.470416273000126, 9.011920634000148],
                [0.469843457000138, 9.01149032900014],
                [0.46965074100018, 9.01112185300002],
                [0.469962484000121, 9.010056899],
                [0.470033928000021, 9.008532800000125],
                [0.469822402000091, 9.007635306000111],
                [0.478132661000132, 8.990058995000084],
                [0.49416169400007, 8.957288881000068],
                [0.499428488000092, 8.954248551000035],
                [0.49962766200008, 8.95433978400007],
                [0.50055798000011, 8.954115723000086],
                [0.50215276900002, 8.953064746000052],
                [0.503706996000062, 8.952256683000087],
                [0.504245143000105, 8.951697638000098],
                [0.504928985000049, 8.951529592000043],
                [0.506418083000142, 8.950415936000127],
                [0.507066134000183, 8.949638925000045],
                [0.507447777000039, 8.949447307000185],
                [0.50823113600012, 8.947873314000049],
                [0.508364761000109, 8.94732452300002],
                [0.509759957000085, 8.944950208000137],
                [0.509640930000046, 8.944290766000108],
                [0.509271918000081, 8.943506808999985],
                [0.509320344000173, 8.942484364000052],
                [0.50980557400004, 8.941898128000048],
                [0.510300349000033, 8.940901468000163],
                [0.51087639400015, 8.940133033999984],
                [0.510949101000051, 8.939636643000028],
                [0.512888620000183, 8.938182131000133],
                [0.513663698000187, 8.937321901000189],
                [0.514310905, 8.9363299420001],
                [0.514703498000074, 8.935446690000049],
                [0.514605245000041, 8.934471643000109],
                [0.513929121000047, 8.93380469400006],
                [0.51350214100006, 8.933059817000185],
                [0.513709877000053, 8.932369848000178],
                [0.513575691000142, 8.932083933000058],
                [0.513666926000099, 8.931375381000066],
                [0.513415397000188, 8.930860953000149],
                [0.513451611000164, 8.930246411000098],
                [0.51270629000004, 8.929576959000087],
                [0.512731976000055, 8.92841414899999],
                [0.513009051000154, 8.928087741000184],
                [0.513247666000041, 8.927130700000134],
                [0.51386160800007, 8.926838680000117],
                [0.514185422000025, 8.926074098000072],
                [0.513559409000038, 8.925797052000064],
                [0.513295669000058, 8.925331147000179],
                [0.512698149000187, 8.924931246000028],
                [0.512867566000068, 8.923676627000077],
                [0.511997744000041, 8.923057358000051],
                [0.512170249000178, 8.922560667000084],
                [0.51118252200007, 8.921608603000038],
                [0.509911547000172, 8.921153510000124],
                [0.509421966000048, 8.92071172600015],
                [0.509484566000083, 8.920427742000072],
                [0.510234098000126, 8.919847849000178],
                [0.510017520000133, 8.918645351000123],
                [0.50940147200015, 8.918438463000086],
                [0.508936594000033, 8.917903217],
                [0.509357539000177, 8.917157475000124],
                [0.509448915000064, 8.916468031000136],
                [0.509969376000072, 8.916369578000058],
                [0.51013963500003, 8.915985752000097],
                [0.510659395000062, 8.915677914000014],
                [0.510808599000143, 8.914911091000079],
                [0.511328218000074, 8.914564703],
                [0.512001253000051, 8.914484831000152],
                [0.512675691000084, 8.914074101000097],
                [0.51238121200015, 8.913221024000109],
                [0.512547681000171, 8.912360179000075],
                [0.513027578000163, 8.91136122000006],
                [0.512713730000144, 8.909852515000068],
                [0.512846090000039, 8.909008581000023],
                [0.512630916000035, 8.908587860000125],
                [0.512744048000116, 8.90778552400019],
                [0.512199303000102, 8.90671583000011],
                [0.512351315000046, 8.905949266000107],
                [0.512579684000059, 8.905555444000015],
                [0.512537575000181, 8.904760589000091],
                [0.513132569000049, 8.903898615000173],
                [0.512877812, 8.903268497000113],
                [0.51225993900016, 8.902582077000147],
                [0.512393985000017, 8.902179377000039],
                [0.513026174000061, 8.901584201000162],
                [0.513659908000079, 8.901408088000096],
                [0.514564961000076, 8.901806629000077],
                [0.515256523000119, 8.901536498000041],
                [0.515370076000181, 8.900847301000113],
                [0.515617814000052, 8.900444044000096],
                [0.515560546000131, 8.900003346],
                [0.514918532000138, 8.899540182000067],
                [0.514976080000054, 8.89870509800005],
                [0.515587496000023, 8.897784867000041],
                [0.516072867000162, 8.89723461400007],
                [0.516865069000119, 8.896830244],
                [0.517181022999978, 8.896494378000114],
                [0.518159766000053, 8.895974908000142],
                [0.518459157000052, 8.893984108000097],
                [0.518863118000183, 8.89384765300008],
                [0.520202731000097, 8.89213918400003],
                [0.519871337000041, 8.891068613000186],
                [0.520175781000148, 8.89030118400018],
                [0.521023848000084, 8.890336407000177],
                [0.522022942000149, 8.890064881000058],
                [0.522249908000106, 8.889336556000103],
                [0.522017469000104, 8.88872582800002],
                [0.522112915000037, 8.888361664000115],
                [0.521935638, 8.887692689000062],
                [0.522470837000185, 8.887136596000175],
                [0.52239279600002, 8.886351686000069],
                [0.522504524, 8.885891000000129],
                [0.522288788000083, 8.88462986900015],
                [0.522421289000135, 8.883863258000076],
                [0.523350484000048, 8.883415882000179],
                [0.52446116699997, 8.883306603999984],
                [0.525171678000049, 8.882926071000043],
                [0.526318715000059, 8.881568409000181],
                [0.525835870000151, 8.879707612000118],
                [0.52667649600005, 8.879662403],
                [0.527218854000182, 8.87945133200003],
                [0.527506455000093, 8.87865087299997],
                [0.527566530000172, 8.877791057000081],
                [0.527139269000088, 8.876968683000086],
                [0.526522099000147, 8.876478030000044],
                [0.526622036000163, 8.875860346000138],
                [0.526164176000123, 8.875669244000107],
                [0.52576793500009, 8.875009676000047],
                [0.525426154000172, 8.874785012000132],
                [0.524737960000095, 8.875190240000109],
                [0.524959451000029, 8.87581208500012],
                [0.524608687000125, 8.876083622000124],
                [0.523184576000176, 8.874755612],
                [0.52218758700019, 8.874211426000102],
                [0.521755693000159, 8.873620642000049],
                [0.52159273400008, 8.873067302000095],
                [0.520629150000104, 8.872644599000068],
                [0.520103496000104, 8.872081549000029],
                [0.520350251000025, 8.871068889000071],
                [0.520131287000027, 8.870868076000079],
                [0.519748100000015, 8.86968205900007],
                [0.519766346000097, 8.868605320000086],
                [0.519656724000129, 8.86805779700012],
                [0.519054572000186, 8.866634343000158],
                [0.517505960000165, 8.864849747000108],
                [0.517177234000144, 8.864161592000073],
                [0.517107894000048, 8.863467055000115],
                [0.517290085000127, 8.86314558100014],
                [0.519925797000099, 8.86133489600013],
                [0.52061160500017, 8.86068140499998],
                [0.521040130000188, 8.85994192700008],
                [0.521479461000183, 8.85885989000019],
                [0.521565082999985, 8.858281286000079],
                [0.521200844000134, 8.85754208000003],
                [0.520868748000055, 8.855324178000046],
                [0.520675891000167, 8.85464904000014],
                [0.51965798700013, 8.853213330000187],
                [0.51909008400014, 8.85291320600004],
                [0.518200752000098, 8.852731244999973],
                [0.514943656000071, 8.853952544000038],
                [0.51165427900014, 8.85543124500009],
                [0.510761157000104, 8.855664797000031],
                [0.509750271000144, 8.85570224300011],
                [0.508870343000183, 8.855458983000062],
                [0.508177799000066, 8.854878432000078],
                [0.507784645000072, 8.854335603000038],
                [0.507447637000041, 8.853193636000128],
                [0.507354015000146, 8.851621171000147],
                [0.507410160000063, 8.850965999000096],
                [0.507915603000072, 8.849037643000088],
                [0.507953079000117, 8.84843877500009],
                [0.507316539000044, 8.847446576000152],
                [0.506305653000084, 8.846978353000054],
                [0.504096640000171, 8.847334236000108],
                [0.503516384000136, 8.847221896000065],
                [0.502561643999968, 8.846379482000145],
                [0.501232418000143, 8.84549961800019],
                [0.499996813000166, 8.844563443000084],
                [0.499584992000109, 8.843870811000158],
                [0.499154502000181, 8.842448095],
                [0.498517961000175, 8.841774044000147],
                [0.496215327000073, 8.84014549900013],
                [0.495822174000125, 8.839078382000025],
                [0.496440046000089, 8.837898919000054],
                [0.496926680000058, 8.835783534000086],
                [0.497076446000165, 8.83462292300004],
                [0.498031187000038, 8.833031794000135],
                [0.499772234000147, 8.831234553000172],
                [0.501438329000052, 8.829736891000096],
                [0.501457137000159, 8.829137714000126],
                [0.501082651000104, 8.82861371100006],
                [0.499154362000183, 8.828272512000126],
                [0.497942058000035, 8.828184854000028],
                [0.497388610999963, 8.828015642000025],
                [0.4969264, 8.827598990000013],
                [0.496796144000086, 8.827208135000092],
                [0.496887239000046, 8.826544042000137],
                [0.496887239000046, 8.824857730000076],
                [0.496346846000051, 8.82308042200009],
                [0.495624263000025, 8.82171310699999],
                [0.495415826000112, 8.820899206999968],
                [0.495604613000182, 8.820111381000174],
                [0.497199825000166, 8.818197289000068],
                [0.498117791000084, 8.817507104000072],
                [0.49941346899999, 8.816322301000184],
                [0.500246798000148, 8.815065647000154],
                [0.500220830000103, 8.81428446600006],
                [0.498690747000069, 8.812350649000052],
                [0.498039749000043, 8.810905340999966],
                [0.496932856, 8.80932964499999],
                [0.496542229000113, 8.808529033000184],
                [0.495591699000158, 8.80733754400012],
                [0.494015437000144, 8.806428736000044],
                [0.493592808000074, 8.806048401000112],
                [0.493130597000118, 8.80521504799998],
                [0.492863628000123, 8.804179733000069],
                [0.492023703000029, 8.801484351000113],
                [0.491607110000075, 8.800878747000127],
                [0.490422176000095, 8.800064801000076],
                [0.48713420199999, 8.799179557000059],
                [0.486248800000112, 8.79913378200007],
                [0.484217343000182, 8.799277208000035],
                [0.482791549000126, 8.798437181000054],
                [0.481098786000132, 8.798202761000027],
                [0.48001800100019, 8.798170303],
                [0.478917564000142, 8.798521794000067],
                [0.477400675000126, 8.798482955000111],
                [0.476684408000096, 8.79858060700002],
                [0.475518985000065, 8.799003673000072],
                [0.474927220000041, 8.799064705000148],
                [0.472921310000174, 8.799628698000163],
                [0.472374320000029, 8.799661157000116],
                [0.471072184000093, 8.798997292000138],
                [0.470714121000071, 8.797961960000123],
                [0.47070106700005, 8.797571351000045],
                [0.471169875000101, 8.795598608000148],
                [0.470937998000181, 8.794508059000066],
                [0.470503579000024, 8.793484644000046],
                [0.469872793000093, 8.79241933500009],
                [0.469354156000065, 8.79208281800004],
                [0.46867985800003, 8.792115832000093],
                [0.467372669000156, 8.793109844000071],
                [0.466544254000098, 8.794711687000017],
                [0.465807916000074, 8.796313247],
                [0.464638843000103, 8.799277485000175],
                [0.464169333000143, 8.800050652999971],
                [0.463414467000064, 8.80099887],
                [0.462751819000118, 8.801376158000153],
                [0.461748373000034, 8.801606137000078],
                [0.459902054000111, 8.801799219999964],
                [0.459226212000146, 8.802094115000159],
                [0.458959245000187, 8.802628143000106],
                [0.458860149000145, 8.804454652000118],
                [0.459016792000057, 8.8061982050001],
                [0.45944489600015, 8.808192531000088],
                [0.45944489600015, 8.809079974000042],
                [0.459300604000077, 8.809940782000012],
                [0.458651431000135, 8.810583544999986],
                [0.457680268000161, 8.810969978000116],
                [0.456333357000176, 8.811011589000088],
                [0.453347160000078, 8.810855129000117],
                [0.452156892000176, 8.810949172000164],
                [0.450287834999983, 8.811241563000124],
                [0.449190908000105, 8.811125605000086],
                [0.447813258000167, 8.81083432400004],
                [0.446737805000055, 8.810155499000132],
                [0.445996555000022, 8.809414255000092],
                [0.445265551000148, 8.808150919000184],
                [0.445119435000095, 8.807023234000042],
                [0.445056833000081, 8.804757313000039],
                [0.444649503000107, 8.80399525100006],
                [0.443866425000181, 8.802846470000134],
                [0.443093734000115, 8.802000903000078],
                [0.441172602000108, 8.800664303000133],
                [0.440076236000039, 8.800257053],
                [0.438959096000133, 8.800006543000052],
                [0.437246683000126, 8.799776840000163],
                [0.433780169000158, 8.799400937000087],
                [0.432381043000021, 8.799098272000151],
                [0.431128033000107, 8.79829430899997],
                [0.430707089000123, 8.79763599100005],
                [0.430198698000027, 8.796111838000172],
                [0.430270283000027, 8.79405835600005],
                [0.429731152999977, 8.792531414],
                [0.42982996700016, 8.791630060000045],
                [0.430603641000062, 8.790860482000141],
                [0.431048167000085, 8.790609412000151],
                [0.431859037000038, 8.790469311000038],
                [0.432468067000116, 8.790814984000122],
                [0.432465120000188, 8.792030662000172],
                [0.432890977000113, 8.792481478000127],
                [0.433775958000069, 8.792682055000114],
                [0.434711048000167, 8.793324848000111],
                [0.437032210000041, 8.794468665000181],
                [0.437474772000087, 8.794460342000093],
                [0.438710516000185, 8.793700757000067],
                [0.439378497000121, 8.793157838000184],
                [0.439820918, 8.792047308000122],
                [0.440722743000151, 8.790636322000069],
                [0.441098491000105, 8.789809870000056],
                [0.441231975000164, 8.789074965000111],
                [0.441173585000115, 8.78828180100004],
                [0.440455494000105, 8.786520130000042],
                [0.439879449000159, 8.785568270000113],
                [0.438518502000079, 8.784240766000096],
                [0.437959019000061, 8.78409039900015],
                [0.437276581000106, 8.78420414500016],
                [0.435696388000167, 8.784958756000151],
                [0.434861376000185, 8.785092478000024],
                [0.43406819099999, 8.784875249000095],
                [0.432874274000085, 8.784249089000127],
                [0.431179266000186, 8.782929903000081],
                [0.429893552000181, 8.781535529000166],
                [0.428774728000178, 8.78065051700014],
                [0.427547405000155, 8.780226876000143],
                [0.426670565000052, 8.780257949000145],
                [0.425635256000135, 8.779932519000056],
                [0.425217821000103, 8.780007426000111],
                [0.424257605000093, 8.780475179000177],
                [0.423673139000016, 8.781034485000077],
                [0.423297391000062, 8.782003003000113],
                [0.423347500000034, 8.78242053800011],
                [0.423957091000091, 8.783931708000068],
                [0.424107279000054, 8.78483363500004],
                [0.424079346000156, 8.787643993000188],
                [0.424166932000105, 8.789278875000093],
                [0.424064749000138, 8.789687524000101],
                [0.42349530100006, 8.790037914000095],
                [0.422546455999964, 8.790183840000111],
                [0.421743587000094, 8.791016118000073],
                [0.421203474000095, 8.791322673000082],
                [0.420575777000067, 8.791351803000055],
                [0.419419757000185, 8.790918741000098],
                [0.418730862000132, 8.790907644000129],
                [0.418364237000048, 8.791174251000086],
                [0.417686429000128, 8.792096689],
                [0.417241904000036, 8.792429877000075],
                [0.415030785000113, 8.793041042000084],
                [0.413030770000091, 8.793396700000017],
                [0.409997272000112, 8.793263260000174],
                [0.408630569000081, 8.793641111000113],
                [0.407286184000156, 8.79360782000009],
                [0.406386184000098, 8.793818939000062],
                [0.405297258000076, 8.793652208000026],
                [0.40293047800003, 8.793763453999986],
                [0.40237492600005, 8.793641111000113],
                [0.401919312000189, 8.79322996899998],
                [0.401076721000038, 8.791387036],
                [0.400771574000089, 8.79105967400011],
                [0.399725738000086, 8.790550875],
                [0.398112420000132, 8.790175795000096],
                [0.397492021000062, 8.790335870000092],
                [0.39722238700017, 8.790753396000184],
                [0.397080481000103, 8.791436972000042],
                [0.396674694000012, 8.792063398000039],
                [0.39540807000003, 8.792741147000072],
                [0.394219206000059, 8.79289678200007],
                [0.393941289999987, 8.793185581000046],
                [0.393981293000024, 8.79364083300004],
                [0.394778828000028, 8.794320798000115],
                [0.396271435000131, 8.794469219000064],
                [0.396510472000102, 8.79468061600005],
                [0.39659300400001, 8.795267364999972],
                [0.396019205000073, 8.79594122300017],
                [0.393919112000106, 8.796018902000071],
                [0.392630170000075, 8.795818881000173],
                [0.391842741000175, 8.795458509000184],
                [0.391516400000114, 8.79486704400017],
                [0.391277504000072, 8.793977903000041],
                [0.391196796000145, 8.792829923000113],
                [0.390874666000116, 8.792196562000015],
                [0.389636394000149, 8.791212813000072],
                [0.38835236500006, 8.790929839000114],
                [0.386907902000189, 8.790952033000167],
                [0.384385601000133, 8.791129863000151],
                [0.383751446000133, 8.79138675900009],
                [0.381750108000119, 8.793406041000026],
                [0.381113122000158, 8.793400100000156],
                [0.380821523000066, 8.79301054800004],
                [0.379940771000065, 8.792319316000032],
                [0.379627146000132, 8.791413810999984],
                [0.379624394000075, 8.790669759000082],
                [0.37987333600006, 8.78906773500006],
                [0.379985832000159, 8.78727606300015],
                [0.379942668000126, 8.7868408060001],
                [0.379964306000147, 8.784451460000128],
                [0.380355210000118, 8.783033555000088],
                [0.38074795700004, 8.78211444100009],
                [0.381708650000064, 8.780460208000079],
                [0.381834580000145, 8.780054647000043],
                [0.381877945000099, 8.777549479000015],
                [0.381490599000188, 8.776930845000038],
                [0.381305486000088, 8.77631422700017],
                [0.379526070000111, 8.774557028000174],
                [0.379094377000172, 8.773930285000063],
                [0.378697097000156, 8.773620331000188],
                [0.378628982000123, 8.772427324000148],
                [0.378873125000155, 8.771776064999983],
                [0.37992858199999, 8.770281330000046],
                [0.380669328000181, 8.769082610000169],
                [0.381800388000158, 8.767810814000086],
                [0.38326357699998, 8.766463392000162],
                [0.383946085000161, 8.766000679000172],
                [0.386503601000186, 8.763946515000043],
                [0.387384350000161, 8.763160641000184],
                [0.389337675000036, 8.761778334000155],
                [0.389915427000062, 8.761456540000154],
                [0.390574216000118, 8.761324597000169],
                [0.392405694000161, 8.761447379],
                [0.394031081000037, 8.762017348000029],
                [0.395139097000083, 8.762013261000106],
                [0.395941453000034, 8.761754012000097],
                [0.396686792000139, 8.761062318000029],
                [0.396988934000092, 8.76037501500008],
                [0.397097112000154, 8.759671893000188],
                [0.396983802000022, 8.758999903000188],
                [0.396560615999988, 8.757684207000068],
                [0.39660561900007, 8.757124620000184],
                [0.397079589000043, 8.756924453000067],
                [0.397842117999971, 8.758150711000098],
                [0.398099239000032, 8.760985448000099],
                [0.398727965000035, 8.761705134000124],
                [0.399210002000189, 8.761717130000022],
                [0.399593903000095, 8.761412575000179],
                [0.400778312000114, 8.759616941000104],
                [0.401091216000054, 8.758846923000135],
                [0.401292525000088, 8.757865123999977],
                [0.401144122000176, 8.756699984000079],
                [0.400209396000093, 8.755631451],
                [0.39935397500011, 8.755028346000131],
                [0.398082575999979, 8.754297255000097],
                [0.396812075000128, 8.75380590800006],
                [0.393396455000129, 8.753755116000093],
                [0.392338793000022, 8.753885774000082],
                [0.389896208000152, 8.754040808000127],
                [0.388885755000103, 8.754204353000091],
                [0.386354575000155, 8.754373470000132],
                [0.385517323000158, 8.754183631000103],
                [0.383605855000098, 8.75339420099999],
                [0.382414169000072, 8.753238721000116],
                [0.381306173000041, 8.753242765999971],
                [0.379266159000167, 8.753649795],
                [0.377935555000022, 8.754134150000027],
                [0.375701922000076, 8.754621785000154],
                [0.374383864000094, 8.754753341000026],
                [0.374174415000084, 8.754291127000158],
                [0.374494147000064, 8.753857306000043],
                [0.376721878000069, 8.752518158999976],
                [0.378019841000082, 8.751444189000154],
                [0.379806924999968, 8.749301940000066],
                [0.380769784000165, 8.748251230000164],
                [0.381557759000088, 8.747851523000065],
                [0.382551144000104, 8.74756956200008],
                [0.384278558000062, 8.74578301400004],
                [0.384732604000135, 8.744971153000108],
                [0.384750012000154, 8.743684136000127],
                [0.384444306000091, 8.742660101000013],
                [0.383506084999965, 8.740621489000091],
                [0.416601706000165, 8.68770671700014],
                [0.421478960000115, 8.679821526000069],
                [0.441047164000054, 8.648185072000103],
                [0.457344551000176, 8.622404998999968],
                [0.471701535000022, 8.599694358000022],
                [0.476561019000144, 8.59528070600004],
                [0.479576597000062, 8.59395570100014],
                [0.480137756000033, 8.593813694000175],
                [0.480426536000152, 8.594027529000186],
                [0.480653153000162, 8.59463291100019],
                [0.480351908000159, 8.595488335000084],
                [0.479577239000093, 8.595995604000052],
                [0.47913481400002, 8.596501596000167],
                [0.479398890000027, 8.596770640000045],
                [0.480441182000106, 8.596910621000177],
                [0.480873726000027, 8.597960945000068],
                [0.482048640000073, 8.598607458000174],
                [0.48196799800013, 8.597493434000171],
                [0.48276613500002, 8.597592667000129],
                [0.483313198000076, 8.597117959000116],
                [0.483966112000076, 8.597163669000167],
                [0.484372511000061, 8.597718752000162],
                [0.485045676000027, 8.59780985000009],
                [0.485951095000075, 8.597417978000124],
                [0.486941295000122, 8.597201451000103],
                [0.488221638000084, 8.598212321000062],
                [0.491778099000101, 8.597499995000078],
                [0.492250567000099, 8.596539186000086],
                [0.494050342000094, 8.595147308000037],
                [0.49576361600009, 8.59358800700005],
                [0.49586659900018, 8.591606273000082],
                [0.496304211000165, 8.588113130000124],
                [0.497369970000136, 8.586653994000187],
                [0.497720234000155, 8.584189058000163],
                [0.498049454000068, 8.583418945000119],
                [0.500672929000132, 8.581350260000136],
                [0.50146212900006, 8.578213987000083],
                [0.500814674000026, 8.57619935200006],
                [0.499630154000101, 8.574192311000047],
                [0.501120281, 8.573295060000135],
                [0.501529435000123, 8.574906917000021],
                [0.502211350000152, 8.575121963000015],
                [0.503167760000053, 8.574735202000113],
                [0.504009937000035, 8.57486970500014],
                [0.505796089000171, 8.574273031000132],
                [0.506774325000094, 8.573806263000051],
                [0.507955121000123, 8.5731044690001],
                [0.509752011000103, 8.571725128000082],
                [0.511471996000068, 8.569453246000023],
                [0.511868224000125, 8.568134489000101],
                [0.512278409999965, 8.56754592800013],
                [0.51313912300003, 8.566770973000075],
                [0.513573861000168, 8.56609137900017],
                [0.513778903000059, 8.56543472800007],
                [0.514422264000075, 8.565005080000049],
                [0.515299263000088, 8.56485008200002],
                [0.516523725000127, 8.565049198000168],
                [0.518162865000079, 8.565097877000028],
                [0.51868410899999, 8.565291481000031],
                [0.519185151999977, 8.565975670000057],
                [0.519567975000143, 8.566169817000059],
                [0.520466945000067, 8.565970629000105],
                [0.521668847000058, 8.566065103000085],
                [0.522706183000082, 8.5658515880001],
                [0.524302354000099, 8.566233842000145],
                [0.525853342000175, 8.56708748400007],
                [0.527154637000081, 8.568154288000017],
                [0.527652119000038, 8.569328982000059],
                [0.528103909000151, 8.569806677000088],
                [0.528608420000012, 8.569967264000127],
                [0.52964965000001, 8.57003478900009],
                [0.531100609000077, 8.56940764500007],
                [0.534478915000022, 8.566827367000087],
                [0.534306730000083, 8.56358329],
                [0.535793717000104, 8.561247498],
                [0.536738839000122, 8.560827642000106],
                [0.537148556000034, 8.560134349],
                [0.537540240000112, 8.559777316000066],
                [0.538759106000043, 8.559276455000088],
                [0.539496126000074, 8.55874168600019],
                [0.539883876000033, 8.558095325000068],
                [0.540779575000158, 8.557091465000155],
                [0.541619073000163, 8.556570064000084],
                [0.542352994000055, 8.555955389000133],
                [0.543574141000079, 8.555338770000162],
                [0.545754373000023, 8.555026949000023],
                [0.546328476000156, 8.554600289000064],
                [0.547061829000029, 8.553847829000176],
                [0.547741069000097, 8.553406970000026],
                [0.549321304000159, 8.553282155999966],
                [0.550778867000076, 8.55362903800011],
                [0.552025348000086, 8.553805911999973],
                [0.552800243000149, 8.5537449360001],
                [0.553831699000057, 8.553462479000075],
                [0.554779031000123, 8.552915819000134],
                [0.555271295000182, 8.552109203000043],
                [0.555424150000135, 8.550890609000078],
                [0.555619201000184, 8.550520573000028],
                [0.556356424000171, 8.550046401000031],
                [0.557001468000067, 8.550052075000167],
                [0.557953744000145, 8.55072612400005],
                [0.558784030000083, 8.550673181000036],
                [0.560118065999973, 8.549918282000021],
                [0.561138114000187, 8.548878064000121],
                [0.562260222000134, 8.547068619000072],
                [0.563592924000091, 8.545991312000183],
                [0.564564770000061, 8.544675722000022],
                [0.564780551000013, 8.543961150000086],
                [0.56491480700015, 8.541582524999967],
                [0.565392551000059, 8.538579747000028],
                [0.566652121000175, 8.537227172000144],
                [0.566914912000129, 8.536501387000044],
                [0.568762244000084, 8.534694514000023],
                [0.569741925000187, 8.534629927000083],
                [0.570349963000069, 8.534054303000062],
                [0.571047173000181, 8.533030876000055],
                [0.570961776000161, 8.532370913000022],
                [0.571314373000121, 8.531270003000088],
                [0.570823478000079, 8.530365400000107],
                [0.570773977000158, 8.529767637000134],
                [0.570905631000016, 8.528791624000121],
                [0.571310606000054, 8.526960269000028],
                [0.571730039000045, 8.525958291000052],
                [0.572261232000187, 8.525887251000029],
                [0.572622953000177, 8.526343214],
                [0.573020812000152, 8.527512728000147],
                [0.573290828000097, 8.527880882999966],
                [0.574840445000177, 8.52842296200015],
                [0.575798672000133, 8.528532052000116],
                [0.57732298500008, 8.52830265700004],
                [0.579092282000147, 8.529086316000132],
                [0.580336740000121, 8.528791915000056],
                [0.581133660000148, 8.52871702300007],
                [0.582394075000082, 8.52893784500003],
                [0.58321324100018, 8.528887659000134],
                [0.584308126000053, 8.52857181100012],
                [0.585125723000147, 8.528141358000141],
                [0.585465785, 8.527368408000143],
                [0.585484330000043, 8.52649481400016],
                [0.585023463000027, 8.524821306000035],
                [0.586981701000013, 8.524100992000115],
                [0.587375969000163, 8.52262945300015],
                [0.588291619000074, 8.520725570000081],
                [0.590482924000071, 8.517631744000084],
                [0.591007766000189, 8.516698212000108],
                [0.591582600000152, 8.51647541300008],
                [0.592747723000173, 8.516421040000068],
                [0.594945539000094, 8.517087141000047],
                [0.597820117000083, 8.517411511000091],
                [0.598862165000185, 8.517715848000137],
                [0.601831028000049, 8.518081132000134],
                [0.604311707000079, 8.51687499000019],
                [0.605013766000127, 8.515924180000184],
                [0.605310325000062, 8.515060468000115],
                [0.607350841000141, 8.514845370000103],
                [0.608893122000154, 8.514301657000146],
                [0.610341632000086, 8.513344136000057],
                [0.611757052000144, 8.512749446000043],
                [0.613201423000021, 8.512624966999965],
                [0.613747319000026, 8.512438081000141],
                [0.614975627000092, 8.512275915000032],
                [0.617063716000075, 8.513180698000042],
                [0.618546615000128, 8.513003685000058],
                [0.619293888000072, 8.512654754000039],
                [0.621243184000036, 8.512147880000043],
                [0.62154081000017, 8.511835263000023],
                [0.622369204000108, 8.511360612999965],
                [0.62329037600017, 8.511227259000123],
                [0.624918852000064, 8.510458554000024],
                [0.627799666000044, 8.508670572000085],
                [0.629405052000152, 8.508010793000096],
                [0.629695851000179, 8.50739509400006],
                [0.629413524000142, 8.506740462000096],
                [0.628948669000181, 8.506122416000096],
                [0.628321395000114, 8.505714469],
                [0.627818433000073, 8.50459231900004],
                [0.626599984000052, 8.50347315900018],
                [0.626111107000042, 8.502735687000097],
                [0.627245149999965, 8.499921346],
                [0.628755991000048, 8.497689944000172],
                [0.629850526999974, 8.49650600100017],
                [0.636479245000089, 8.496766162000085],
                [0.639489692000041, 8.497841929],
                [0.640261312000121, 8.49769814900003],
                [0.643236845000047, 8.495770531000062],
                [0.644471237000175, 8.494456458000116],
                [0.645315474000142, 8.493819129000087],
                [0.645996807000131, 8.492625875000101],
                [0.646470453000063, 8.492058997000072],
                [0.646918701000175, 8.490728951000165],
                [0.64707028100014, 8.490576758000088],
                [0.647389048000093, 8.489389169000162],
                [0.645860944000162, 8.486721407000061],
                [0.645599207000089, 8.481801183000073],
                [0.644852376000188, 8.477351436000049],
                [0.645012687000076, 8.475347509000073],
                [0.64526276100014, 8.474927618000095],
                [0.645971031000101, 8.474216469000112],
                [0.647354103000168, 8.473386742000116],
                [0.647581048000177, 8.473385785000119],
                [0.648537667000028, 8.472502744000167],
                [0.648908167000116, 8.47176683900011],
                [0.648746347000042, 8.468861847000085],
                [0.649035488000095, 8.467868646000056],
                [0.651364128000012, 8.464548083000068],
                [0.651327739000124, 8.463477726000065],
                [0.65100410000008, 8.462867372000176],
                [0.650407906000169, 8.46259433900002],
                [0.650140311000143, 8.462146321000091],
                [0.650949375000096, 8.460415208000143],
                [0.65157645, 8.459492224000144],
                [0.652392686000042, 8.45879714800003],
                [0.653387060000057, 8.4583410460001],
                [0.653599065000151, 8.45808388900008],
                [0.65500842400013, 8.456945421],
                [0.655643596000118, 8.45597831100008],
                [0.655810073000112, 8.455429264000088],
                [0.655871607000051, 8.453311407000058],
                [0.655755210999985, 8.452949552000064],
                [0.655202631000122, 8.452516521],
                [0.654641604000119, 8.452374229000156],
                [0.65287532799999, 8.452556663000166],
                [0.652033692000032, 8.451832764000017],
                [0.651528661000043, 8.448263767000071],
                [0.651879062000035, 8.446710942999971],
                [0.65289694400002, 8.445932355000082],
                [0.653501916000153, 8.44567354000003],
                [0.653685174000032, 8.445165756000108],
                [0.653485990000092, 8.443882534000181],
                [0.653737008000064, 8.442714522000188],
                [0.652216924000072, 8.440257516000088],
                [0.652408419000039, 8.437780891000102],
                [0.652842873000111, 8.437123252000106],
                [0.653484750000189, 8.43578412900007],
                [0.651569406000135, 8.435059235000153],
                [0.651550085000054, 8.433441836000156],
                [0.652127402000133, 8.429606504000105],
                [0.653261468000153, 8.429152583000075],
                [0.65394599900003, 8.42906152300003],
                [0.653752126000086, 8.428698614000098],
                [0.65226283700008, 8.428264004000027],
                [0.651959999000042, 8.427981462000105],
                [0.652105526000071, 8.427060512000082],
                [0.653093450000142, 8.426406056000133],
                [0.653225766000048, 8.425225456000135],
                [0.651905316000068, 8.423367947000031],
                [0.651646280000136, 8.422524130999989],
                [0.651821013000074, 8.421964029000037],
                [0.652373395000154, 8.421057902000086],
                [0.652913668000167, 8.4205596380001],
                [0.65415922, 8.419972987000165],
                [0.655371417000026, 8.420003704000123],
                [0.656449911000095, 8.420479994000061],
                [0.660510627000178, 8.420420145000037],
                [0.661815917000183, 8.4198938400001],
                [0.662654093000072, 8.419173867000097],
                [0.663791876000175, 8.418628976000036],
                [0.664971080000043, 8.418712159000108],
                [0.665808553000147, 8.41912468800001],
                [0.667295313000068, 8.418005169000025],
                [0.67136340400009, 8.416772725000044],
                [0.671796265000125, 8.41543310100002],
                [0.673959921000119, 8.41439472500008],
                [0.674213469000108, 8.413509140000087],
                [0.674978208000084, 8.412129523999965],
                [0.675996610000141, 8.412147230000016],
                [0.678097740000055, 8.41137638399999],
                [0.678485811000087, 8.410244986],
                [0.679053952000118, 8.409804442000166],
                [0.679937837000182, 8.410073460000035],
                [0.681946326000173, 8.411246973000061],
                [0.683283869000093, 8.411478220999982],
                [0.684777389000033, 8.411303744000065],
                [0.685668545000055, 8.411332992000155],
                [0.686223639000161, 8.411077111000054],
                [0.686349501000052, 8.410106650000102],
                [0.686768237000081, 8.408713349000038],
                [0.687148246000163, 8.40668370300017],
                [0.688253675000055, 8.40539492],
                [0.691956125000161, 8.403808417000107],
                [0.693740654000123, 8.403749767000022],
                [0.695984774000181, 8.404386259000148],
                [0.696123846000148, 8.40391447899998],
                [0.695903586000043, 8.403532421000023],
                [0.694858518000046, 8.403101560000152],
                [0.694537442000126, 8.40180512600017],
                [0.693692163000094, 8.40086364300015],
                [0.693362392000097, 8.400112823000029],
                [0.693550229000095, 8.39939973200012],
                [0.695122560000073, 8.39890801000007],
                [0.695222446000173, 8.398333070000149],
                [0.694862931000159, 8.397742194999978],
                [0.69317700300013, 8.396283544000028],
                [0.693155531000116, 8.395159413000158],
                [0.693548882000073, 8.394620410000073],
                [0.696367198000189, 8.394321724000065],
                [0.699290112000085, 8.394535079000036],
                [0.700881126000127, 8.394528216000083],
                [0.701884826000025, 8.393724805000147],
                [0.703474020000044, 8.394889001000024],
                [0.70398227700008, 8.395005288000107],
                [0.704975020000063, 8.394863223000186],
                [0.705848843000183, 8.394101697000053],
                [0.706127863000177, 8.393364789000032],
                [0.705555188000119, 8.392752804999986],
                [0.704244842000037, 8.392397510000023],
                [0.703478154000038, 8.39234571500009],
                [0.702818550000188, 8.3921033310001],
                [0.702217612000027, 8.391353692000166],
                [0.701662591000172, 8.390981348000082],
                [0.70012455900013, 8.390442405000158],
                [0.69934820899999, 8.390076522000186],
                [0.698933527000179, 8.389524465000022],
                [0.698993408000035, 8.38865899700005],
                [0.700923628000055, 8.387760671000137],
                [0.702773251000053, 8.387419282000053],
                [0.70389050600005, 8.387915947000181],
                [0.704053116000182, 8.386180697000157],
                [0.705522051000116, 8.385471714000175],
                [0.707331000000124, 8.385641615000111],
                [0.708605448000071, 8.385385354000164],
                [0.709508492, 8.384984660000043],
                [0.709025294000185, 8.384584460000156],
                [0.708025025000097, 8.384263650000037],
                [0.708162913000137, 8.383524598],
                [0.708804097000098, 8.382719992000034],
                [0.709232963000034, 8.381450637000114],
                [0.709320192000064, 8.380516169000032],
                [0.711129249000066, 8.3797671210001],
                [0.711018271000171, 8.37906496700009],
                [0.710206031000098, 8.378713035999965],
                [0.709167462000039, 8.378488832000073],
                [0.708381738000071, 8.377872261000164],
                [0.708522713999969, 8.377285949000111],
                [0.709226129000172, 8.376715458000092],
                [0.71196087300018, 8.376957113000117],
                [0.712082785000121, 8.37636279000003],
                [0.711697110999978, 8.376116473000025],
                [0.710448186000122, 8.375873894999984],
                [0.709907487000066, 8.375608960000022],
                [0.709098072000188, 8.374634285000127],
                [0.70934978400004, 8.37334916499998],
                [0.708423577000019, 8.372873726000023],
                [0.708171561000142, 8.372185957000056],
                [0.708364241000083, 8.371956423000029],
                [0.709548014000063, 8.371854864000113],
                [0.709975618000101, 8.371566449000113],
                [0.710049831000106, 8.37081940600001],
                [0.709272572000032, 8.369602112000052],
                [0.709019061000106, 8.367936172000043],
                [0.709126232000187, 8.367767627000035],
                [0.710114656999963, 8.36792041200016],
                [0.71095732100008, 8.367641223000078],
                [0.711200337000093, 8.367416121000076],
                [0.711595544000033, 8.366481180999983],
                [0.710816224000041, 8.365106838999964],
                [0.71091041599999, 8.36450023600014],
                [0.710734392000177, 8.364106971000012],
                [0.70965299500017, 8.363568827000108],
                [0.70938295100018, 8.363186989000098],
                [0.710752678000119, 8.362590027000124],
                [0.710982374000082, 8.360063682000032],
                [0.710372956000128, 8.35926173100006],
                [0.709632827000064, 8.358939787000054],
                [0.709649530000149, 8.358328009000047],
                [0.71035907300012, 8.357980515000065],
                [0.710177779000048, 8.357011386000124],
                [0.710542797000073, 8.356331973000124],
                [0.711171233000186, 8.356420186000037],
                [0.711985997999989, 8.356728027000031],
                [0.712311471000021, 8.356497919000162],
                [0.712405962000105, 8.355960202000176],
                [0.712153112000181, 8.355713305999984],
                [0.711025232000111, 8.355296601000077],
                [0.710244199000044, 8.354474724],
                [0.710053889000108, 8.353578652000124],
                [0.710130367000033, 8.353112654000086],
                [0.710053341000048, 8.35194330600018],
                [0.710350503000143, 8.351565906000019],
                [0.713034711000034, 8.350410804000035],
                [0.713549814000089, 8.349890555000059],
                [0.714031295000041, 8.349907760000121],
                [0.714412525000171, 8.35041035400019],
                [0.714075954000123, 8.35126599299997],
                [0.714110320000088, 8.351533121000045],
                [0.715302218000147, 8.351406721999979],
                [0.716937259000133, 8.350754869000184],
                [0.718467902000043, 8.349640553000143],
                [0.720126930000163, 8.349415675000046],
                [0.719311021000067, 8.34884333399998],
                [0.719247654000071, 8.347639490000063],
                [0.718386268000017, 8.346789045000094],
                [0.717876100000126, 8.345904011000073],
                [0.717318735999982, 8.345614351000108],
                [0.717064736000111, 8.345102943000029],
                [0.717137688000093, 8.344069342000068],
                [0.717618401000038, 8.343279209000173],
                [0.718343092, 8.34324024800003],
                [0.718634426000165, 8.343431865000184],
                [0.719366304000118, 8.34440411100013],
                [0.720424688000151, 8.34475221200006],
                [0.721441167000137, 8.344375819000106],
                [0.721825639000144, 8.34435486100017],
                [0.722519134000038, 8.344773426000074],
                [0.722980902000074, 8.344713556999977],
                [0.72367078700006, 8.34430826700003],
                [0.725092476000157, 8.344224931000042],
                [0.726171539000177, 8.343611287999977],
                [0.727355369000065, 8.343545513000151],
                [0.728169094000179, 8.343621874000178],
                [0.728697492000038, 8.342985826000131],
                [0.728695978000076, 8.342641406000041],
                [0.728311164000047, 8.341954232000035],
                [0.728359521000186, 8.341626128000087],
                [0.729317993000109, 8.341274768000119],
                [0.729460853000035, 8.340417213000137],
                [0.72918759900017, 8.339933451999968],
                [0.728221764000068, 8.339237788000162],
                [0.727507653000089, 8.338532758000099],
                [0.726978515000155, 8.337738748000106],
                [0.726905035000129, 8.336755386000107],
                [0.727435103000118, 8.335240359000068],
                [0.72808630600008, 8.334220777000098],
                [0.728861712000082, 8.33376551300006],
                [0.729423054000165, 8.33307421600017],
                [0.72893598100012, 8.331147551000186],
                [0.729099774000076, 8.329386131000092],
                [0.728960019000169, 8.328115118000028],
                [0.729149816000131, 8.327550810000048],
                [0.729551531000084, 8.327050332],
                [0.729635401000053, 8.325989135000043],
                [0.729926488999979, 8.324866415999963],
                [0.730476900000156, 8.324208232000046],
                [0.729177517000039, 8.323775779000073],
                [0.727655911000056, 8.322505263000096],
                [0.727928454000164, 8.322199605000037],
                [0.728800544000023, 8.322347360000094],
                [0.729393398000013, 8.322187916000075],
                [0.729469510000115, 8.321597735000182],
                [0.729198895000025, 8.321080895000023],
                [0.72909905, 8.320392476000166],
                [0.729734140000176, 8.319488695000075],
                [0.729858724000167, 8.318248218000065],
                [0.730352397000047, 8.317270655000073],
                [0.72956472400017, 8.316813928000045],
                [0.728750259000037, 8.316555705000042],
                [0.728754005000155, 8.31614788800016],
                [0.729127758000061, 8.315896899000109],
                [0.729772035, 8.316143462000127],
                [0.730541089000042, 8.316140116000042],
                [0.730991658000107, 8.315427261000139],
                [0.730959244000076, 8.314344526000184],
                [0.730168643000127, 8.313218247000123],
                [0.729562211000086, 8.311190146000115],
                [0.729321759000072, 8.310609799000133],
                [0.729319525000051, 8.31010005800016],
                [0.729586225000105, 8.30846218500011],
                [0.729785536000179, 8.307860639000069],
                [0.729886723000106, 8.306961935000118],
                [0.730262184000139, 8.30615572400012],
                [0.730850023000016, 8.305205307999984],
                [0.730942086000084, 8.304750265999985],
                [0.730719032000138, 8.304351700000097],
                [0.729963276000092, 8.304228235000096],
                [0.729563639000162, 8.303935142000057],
                [0.729560792000029, 8.303284876000077],
                [0.729942968000046, 8.30274866700006],
                [0.729709929000023, 8.301964387000169],
                [0.730437296000105, 8.301291663000086],
                [0.730778389000136, 8.30021832600005],
                [0.7299685750001, 8.299750668000058],
                [0.730019230000153, 8.299315093000189],
                [0.730618038000102, 8.298976333000041],
                [0.730810084000041, 8.298611784000173],
                [0.730229501000053, 8.298057713000048],
                [0.730698780000068, 8.297198742000091],
                [0.730474508000157, 8.295888140000045],
                [0.730690425000148, 8.295289278000041],
                [0.731142001000023, 8.294810632000065],
                [0.731300236000095, 8.294305705000113],
                [0.731055380000043, 8.293347884000184],
                [0.730129288000171, 8.292222184000138],
                [0.729780378999976, 8.291507291000016],
                [0.729777319999982, 8.290807428000107],
                [0.730192268000053, 8.289543649999985],
                [0.730967271000168, 8.288698510000074],
                [0.73045060100003, 8.287573786999985],
                [0.729375834000166, 8.286921280000115],
                [0.728204465000147, 8.286634280000158],
                [0.727727420000065, 8.286347025999987],
                [0.72725106200005, 8.285583082000016],
                [0.72632197300004, 8.282492766000132],
                [0.726389619000088, 8.281511545000058],
                [0.723946642000044, 8.281320214000118],
                [0.722456416000171, 8.28154945600005],
                [0.719842632000109, 8.280595201999972],
                [0.717755280000063, 8.280678455000043],
                [0.713952725000127, 8.280694792000077],
                [0.703589498000042, 8.280887673000109],
                [0.688681154000165, 8.281842251000057],
                [0.682855912000093, 8.279638678000083],
                [0.681426337000062, 8.276599539000074],
                [0.68320727400004, 8.27458669400005],
                [0.683340321000117, 8.270798255000045],
                [0.681904179000128, 8.266199422999989],
                [0.677636768000127, 8.262058088000117],
                [0.673498345000098, 8.259009382000102],
                [0.666595938000171, 8.26061875500011],
                [0.661228373000085, 8.260789610000131],
                [0.657570367000062, 8.259690676000162],
                [0.65436467000012, 8.259778208000114],
                [0.647578310000142, 8.259434824000152],
                [0.642064346999973, 8.260274486000128],
                [0.638934097000174, 8.260584408000057],
                [0.636599213000068, 8.254874871000027],
                [0.638303948000157, 8.252416867000079],
                [0.638592081000127, 8.24996465200013],
                [0.638209509000092, 8.24758945300016],
                [0.638273671000036, 8.245063881000078],
                [0.637302912000109, 8.244696475000069],
                [0.633871192000015, 8.244190551000088],
                [0.631400792000079, 8.241749560000017],
                [0.628181530000177, 8.23849457000017],
                [0.626912571999981, 8.238128344000074],
                [0.624591968000175, 8.23583523800005],
                [0.62414070300008, 8.234871496000096],
                [0.62502900100003, 8.233308141000123],
                [0.626070642000172, 8.232784005000099],
                [0.626361891000158, 8.231074516000149],
                [0.625042560000168, 8.227827189000152],
                [0.625442121000106, 8.2249134650001],
                [0.620582906000152, 8.22166500200018],
                [0.618934318000129, 8.219591952000087],
                [0.619667791000154, 8.216618015000108],
                [0.619065357000068, 8.215134946000092],
                [0.617647679000186, 8.214843548000147],
                [0.615712347999988, 8.21559406800003],
                [0.61302339299999, 8.214342182000053],
                [0.610645234000174, 8.216208578000078],
                [0.608697913000071, 8.213988116000053],
                [0.603851465000162, 8.213858904000176],
                [0.599820023, 8.212463714000023],
                [0.595263482000064, 8.210253529000056],
                [0.590034842000136, 8.207748833000096],
                [0.59062504700006, 8.206186714000069],
                [0.59023871100004, 8.202771557000062],
                [0.589112496000041, 8.20077055300004],
                [0.590371283000025, 8.198611595000102],
                [0.589025268000114, 8.197577040000056],
                [0.58983528300007, 8.195048473000156],
                [0.5913972620001, 8.19415100700013],
                [0.592508401000089, 8.192364008000027],
                [0.596524817000045, 8.190045599000086],
                [0.6009065020001, 8.185868825],
                [0.603153061000114, 8.18138007500005],
                [0.606850872999985, 8.18101733400016],
                [0.613274613000044, 8.184185565],
                [0.613854543000059, 8.180098128000111],
                [0.613215155000091, 8.176703030000056],
                [0.611147668000172, 8.17431547300015],
                [0.607790508000051, 8.173660356000028],
                [0.606964878000099, 8.172252407000144],
                [0.608153712000046, 8.171282106000035],
                [0.609479131000057, 8.167191703000128],
                [0.610650984000074, 8.161987784000019],
                [0.611307212000042, 8.154067284000178],
                [0.608805278000148, 8.147808554000108],
                [0.609765087000085, 8.145502216000068],
                [0.607596946000172, 8.143876753000143],
                [0.606905673000085, 8.138754494000068],
                [0.602509427000143, 8.139143240000067],
                [0.599828126000091, 8.139673738000113],
                [0.594235126000058, 8.139027250000083],
                [0.592073519000166, 8.13903572900017],
                [0.591391834000149, 8.136290192000104],
                [0.594267348000074, 8.127402426000117],
                [0.591646373000174, 8.116226593000135],
                [0.592245241000171, 8.106947867000144],
                [0.591857691000087, 8.103161306000118],
                [0.589393486000176, 8.101982498000098],
                [0.587236728999983, 8.10317931200018],
                [0.58573737200004, 8.100956858000131],
                [0.585036679000041, 8.093309129000033],
                [0.588281696000024, 8.084531935000143],
                [0.589829054000177, 8.079995077000092],
                [0.590405218000058, 8.074867785000038],
                [0.591805520000094, 8.070851433000087],
                [0.592023283000117, 8.069365065000056],
                [0.591269024000098, 8.06706543600012],
                [0.592370336000101, 8.062827420000076],
                [0.592659718000107, 8.060598013000117],
                [0.594820323000079, 8.060441062],
                [0.597288632000073, 8.062734008000177],
                [0.597576238999977, 8.060058954],
                [0.598761617000036, 8.058271713000181],
                [0.59838142699999, 8.056342021000034],
                [0.598895509000101, 8.05440884400008],
                [0.598889683000152, 8.052923348000093],
                [0.596041270000114, 8.048700704000112],
                [0.596776890000058, 8.046246736000114],
                [0.597888596000189, 8.04468261600016],
                [0.596836589000077, 8.0424584270001],
                [0.594143708000104, 8.039869219000082],
                [0.595252802000061, 8.037636634000023],
                [0.598578861000021, 8.03064177300007],
                [0.59863915000011, 8.02700201499999],
                [0.599232095000048, 8.02618267600019],
                [0.599752844000079, 8.025957825000148],
                [0.601679697000066, 8.025647075000165],
                [0.60438836600008, 8.026187652000033],
                [0.604784316000178, 8.02634318000014],
                [0.605250899000112, 8.026885594000078],
                [0.606405320000022, 8.027287545000036],
                [0.607482701000038, 8.027079424000135],
                [0.609554759000105, 8.027075461000038],
                [0.610462479000034, 8.02520498600012],
                [0.611304130000121, 8.024460435000037],
                [0.611703466000165, 8.023370409000108],
                [0.611967176000121, 8.022239577000164],
                [0.612586393000072, 8.021526208000182],
                [0.613807573000088, 8.02133404400007],
                [0.614034605000029, 8.020718655000053],
                [0.61318857200007, 8.019997244000137],
                [0.613327097000081, 8.019371179000132],
                [0.614488431999973, 8.018727330000047],
                [0.615956131000189, 8.01725697500018],
                [0.618692171000077, 8.01599382],
                [0.620376649000036, 8.016567255000098],
                [0.621377276000146, 8.01653438600016],
                [0.621941771000024, 8.01527285800006],
                [0.622121116000017, 8.014486809000118],
                [0.622332431000189, 8.012000431000104],
                [0.622232662000044, 8.010890319000055],
                [0.622593626000025, 8.00814708200005],
                [0.622046560000058, 8.007523713000069],
                [0.621047996000073, 8.007373324000127],
                [0.619253378000053, 8.007950779000055],
                [0.617721317000075, 8.006410894999988],
                [0.617126436000149, 8.006278202000146],
                [0.615950492000025, 8.006362720000027],
                [0.613567297999964, 8.006304532000172],
                [0.61258083000007, 8.005714555000111],
                [0.613061836999975, 8.00325191799999],
                [0.614621637000084, 8.00060870600015],
                [0.614587077000067, 7.999553444000071],
                [0.613923921000094, 7.998927757000104],
                [0.613579378999987, 7.997074579000071],
                [0.615473146000113, 7.995708665],
                [0.615362670000025, 7.994328538000104],
                [0.615590506000046, 7.993217137999977],
                [0.615388713000129, 7.991812569000047],
                [0.615570498000181, 7.990941085999964],
                [0.616339032000155, 7.989546502000053],
                [0.616741298000079, 7.988506067],
                [0.616718133000177, 7.987536183999964],
                [0.617268584000158, 7.986219610000092],
                [0.617151736000096, 7.985327250000182],
                [0.616891061000103, 7.98441340800008],
                [0.615856965000148, 7.982240518000026],
                [0.615139698000064, 7.981201698000064],
                [0.614897291000148, 7.981066241000121],
                [0.615364846000034, 7.980109598000126],
                [0.616239324000105, 7.979651510000053],
                [0.616855762000114, 7.978949180000029],
                [0.616952167000022, 7.978157944000088],
                [0.616850808000038, 7.976984452000181],
                [0.616605241000059, 7.976395711000066],
                [0.616286642000091, 7.974806969000042],
                [0.616103932000101, 7.972625240000127],
                [0.616185560000076, 7.972294250000061],
                [0.617328813000086, 7.971300526000107],
                [0.618016848000082, 7.970531783000183],
                [0.618282721000128, 7.969957579000095],
                [0.618223245000024, 7.969594071000017],
                [0.617708542000173, 7.968747354000072],
                [0.617018968000139, 7.96630582],
                [0.617131159000053, 7.965310609000028],
                [0.617397183000037, 7.964774983000098],
                [0.617755471000066, 7.964506293000056],
                [0.618164779000153, 7.964559808000161],
                [0.618600133000143, 7.964910827000153],
                [0.618846082000118, 7.965598769000053],
                [0.619361456000149, 7.965913651000165],
                [0.620666433, 7.965985711000087],
                [0.621029273000033, 7.965468996000027],
                [0.620316106000075, 7.964763592000111],
                [0.620609497000089, 7.963807628999973],
                [0.622403694000127, 7.96352092300009],
                [0.623476180000068, 7.962816805000102],
                [0.623644626000157, 7.962072135000085],
                [0.623261832000082, 7.961029260000032],
                [0.623175331000141, 7.960120250000159],
                [0.624012335000089, 7.957879430000048],
                [0.623971751000113, 7.957400114000166],
                [0.623623347000091, 7.956665732000147],
                [0.622978333000162, 7.955714816000182],
                [0.622355858999981, 7.954168599000013],
                [0.621922706000134, 7.953671527000097],
                [0.621366281000178, 7.952753328000085],
                [0.621407109000188, 7.951887909000163],
                [0.62175794000018, 7.951131506000024],
                [0.623214796000127, 7.950475494999978],
                [0.623788990000037, 7.949585947000116],
                [0.623728865000089, 7.949057107000044],
                [0.622495947000061, 7.947612475000085],
                [0.622279050000145, 7.946577216000151],
                [0.622467960000108, 7.946116292],
                [0.623798215000136, 7.945604069000183],
                [0.624269322000146, 7.945210933000169],
                [0.624037127, 7.944503651000048],
                [0.622670721000134, 7.942847357000176],
                [0.621913566999979, 7.941486286000099],
                [0.621795157000179, 7.94089429200011],
                [0.622061013000121, 7.940320090000057],
                [0.622676177000017, 7.940011820000109],
                [0.623018893000165, 7.939302293000139],
                [0.622456963000047, 7.938384112999984],
                [0.621263669000029, 7.937165275000041],
                [0.621164977000035, 7.93666689500003],
                [0.62143068099999, 7.936054116000037],
                [0.622732406000182, 7.935321563000116],
                [0.623404783000126, 7.934803645000045],
                [0.623632686000065, 7.934419727000091],
                [0.623737452000171, 7.933647750000091],
                [0.624031266000145, 7.933156107000173],
                [0.62456633000005, 7.932864682000115],
                [0.62679209200013, 7.932359985000176],
                [0.627210548000164, 7.931936744000097],
                [0.627324379000072, 7.931363134000037],
                [0.627189600000179, 7.930826319000119],
                [0.626773247000131, 7.930026066999972],
                [0.626057975000094, 7.929119512000057],
                [0.625929652000025, 7.928114218000189],
                [0.626391394000052, 7.927101110000024],
                [0.626609649000045, 7.925317386000017],
                [0.627488521000146, 7.923897578000151],
                [0.629451017000179, 7.921823215000018],
                [0.631270589000053, 7.919253398000137],
                [0.630698697000184, 7.91860807],
                [0.627501018000032, 7.917928901000039],
                [0.627154418000089, 7.917643671000064],
                [0.627039372000183, 7.917203224000105],
                [0.627402086000188, 7.916664467000032],
                [0.631050430000073, 7.915277936000166],
                [0.63229563200008, 7.914600702000087],
                [0.632150761000048, 7.91325378300013],
                [0.631333761000121, 7.912124427000094],
                [0.630278115000181, 7.911453430000108],
                [0.629487912000116, 7.910811704000139],
                [0.628396179999982, 7.910099507999973],
                [0.627993232000051, 7.909538937000036],
                [0.627766321000024, 7.908762741000146],
                [0.627821638000057, 7.908068114000116],
                [0.628094424000096, 7.907152192000183],
                [0.628059618000066, 7.901796818000094],
                [0.626075154000148, 7.901418758000091],
                [0.625132621000091, 7.901040772000101],
                [0.624043824000125, 7.901198417000046],
              ],
            ],
            [
              [
                [0.624043824000125, 7.901198417000046],
                [0.622650540000166, 7.901686958000084],
                [0.622163462000174, 7.901481457000045],
                [0.624043824000125, 7.901198417000046],
              ],
            ],
          ],
        },
        properties: { GID_0: "TGO", COUNTRY: "Togo" },
      },
    ],
  },
  101: {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: { GID_0: "MLI", COUNTRY: "Mali" },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-6.967905043999963, 10.347371101000078],
              [-6.96819114699997, 10.347107888000096],
              [-6.977698801999964, 10.33837890699999],
              [-6.995326042999977, 10.310601235000092],
              [-6.997068881999837, 10.30303001499999],
              [-6.99498796499995, 10.287818910000055],
              [-6.989507197999899, 10.281761169000049],
              [-6.987092971999971, 10.272751809000169],
              [-6.978427886999953, 10.262491226],
              [-6.970952987999908, 10.250417710000079],
              [-6.96744489699995, 10.238618852000116],
              [-6.9667639729999, 10.236319542000103],
              [-6.965371131999916, 10.221581459000049],
              [-6.966001986999913, 10.211009979000039],
              [-6.969604014999959, 10.201272012000175],
              [-6.969637869999929, 10.201180458000067],
              [-6.969721793999952, 10.20112323800015],
              [-6.972447870999872, 10.199300767000068],
              [-6.973989010999958, 10.198270799000113],
              [-6.974332809999964, 10.198041916000079],
              [-6.97474193599993, 10.197196961000088],
              [-6.975772856999924, 10.195070267000119],
              [-6.976613043999976, 10.194527626000081],
              [-6.982718944999931, 10.19058704400004],
              [-6.984850882999922, 10.189210892],
              [-7.016118049999875, 10.169031144000087],
              [-7.025029182999845, 10.164209365000033],
              [-7.03016710299994, 10.161430360000054],
              [-7.040181159999861, 10.159510613000123],
              [-7.045536995999953, 10.161319733000028],
              [-7.047339915999942, 10.161919593000107],
              [-7.049753188999944, 10.164628983000114],
              [-7.054952144999959, 10.170465469000021],
              [-7.063545226999963, 10.180111885000088],
              [-7.069890021999925, 10.190328599000054],
              [-7.077493190999917, 10.209300996000138],
              [-7.082468031999952, 10.217890741000076],
              [-7.089310169999976, 10.224653245000013],
              [-7.089560985999924, 10.224901199000101],
              [-7.094544887999916, 10.225803376000044],
              [-7.11397409499989, 10.229317666000043],
              [-7.11874103599996, 10.230179787000054],
              [-7.122800826999878, 10.232739449000121],
              [-7.134640216999912, 10.240200996000112],
              [-7.136683939999898, 10.241491319000033],
              [-7.15388488799988, 10.240599632000055],
              [-7.161522865999928, 10.24232101500013],
              [-7.168795108999973, 10.253009796000129],
              [-7.17339420299993, 10.255046844000105],
              [-7.178483962999962, 10.257301330000132],
              [-7.18687295899997, 10.256503105000093],
              [-7.201509951999981, 10.255108833000179],
              [-7.215394019999906, 10.257198335000055],
              [-7.216182231999937, 10.25731659000013],
              [-7.216804981999871, 10.257411003000072],
              [-7.226522921999958, 10.259860993000075],
              [-7.232124804999899, 10.264055252000105],
              [-7.233416079999927, 10.265021324000088],
              [-7.244510174999903, 10.268630981000058],
              [-7.252109050999877, 10.26898193500017],
              [-7.25959587199992, 10.269318581000107],
              [-7.276360987999965, 10.26534175900008],
              [-7.281507968999904, 10.264121055000146],
              [-7.285418986999957, 10.266480445000127],
              [-7.292612075999955, 10.283643722000136],
              [-7.293951988999879, 10.286841392000042],
              [-7.29601717099996, 10.288477898000053],
              [-7.318653106999875, 10.281221391000145],
              [-7.332643031999908, 10.269310951000023],
              [-7.332997798999884, 10.269008636000137],
              [-7.333226203999971, 10.269039155000087],
              [-7.345054148999907, 10.27056789400001],
              [-7.350619791999975, 10.273208618000012],
              [-7.3570499409999, 10.276259423000113],
              [-7.366802214999893, 10.276400567000053],
              [-7.368165968999961, 10.276030540000079],
              [-7.375666140999954, 10.274001122000072],
              [-7.378909109999938, 10.274499893000041],
              [-7.384366988999886, 10.28263187400006],
              [-7.371282099999917, 10.305700303000151],
              [-7.370783806999839, 10.306578636999973],
              [-7.36798715599997, 10.318419457000061],
              [-7.367310046999933, 10.321286201000078],
              [-7.365881919999879, 10.327333451000072],
              [-7.363367081999968, 10.337981223000156],
              [-7.36510896599998, 10.362389564000068],
              [-7.369614124999941, 10.365460396000117],
              [-7.373813151999912, 10.368320465000068],
              [-7.3742160799999, 10.368442536000146],
              [-7.384725092999929, 10.371641160000138],
              [-7.390062807999925, 10.374533653000071],
              [-7.392562864999889, 10.375888825000118],
              [-7.402078150999898, 10.376721383000074],
              [-7.410220146999905, 10.381752014000028],
              [-7.419587135999905, 10.387539863000086],
              [-7.425366878999853, 10.389471054000126],
              [-7.428146839999897, 10.392887116000168],
              [-7.43199205499991, 10.397609710000097],
              [-7.436016083999959, 10.406208038000102],
              [-7.437407015999952, 10.409180642000024],
              [-7.439207077999868, 10.417661667000175],
              [-7.447196006999889, 10.455320359000154],
              [-7.448957919999941, 10.463628769000138],
              [-7.452879905999964, 10.465748788000155],
              [-7.456085203999976, 10.468584061000172],
              [-7.45736789699987, 10.469719886000064],
              [-7.458160876999955, 10.470420837000063],
              [-7.465798853999957, 10.472829819000083],
              [-7.466166018999957, 10.472882272000106],
              [-7.480182170999967, 10.474869728000044],
              [-7.480809211999883, 10.475168229000133],
              [-7.48618507399982, 10.477721214000098],
              [-7.50847482699993, 10.479410173000133],
              [-7.513113021999914, 10.477561951000155],
              [-7.516292094999812, 10.476299287000131],
              [-7.521689890999937, 10.47415447200018],
              [-7.526938913999913, 10.472068786000079],
              [-7.529118060999906, 10.470121385000027],
              [-7.531061171999966, 10.468384744000105],
              [-7.533527850999917, 10.466179848000081],
              [-7.551259040999923, 10.444350243000031],
              [-7.558495997999899, 10.441921234000063],
              [-7.56308221799992, 10.441825867000034],
              [-7.565237045999879, 10.441781996999964],
              [-7.567246912999906, 10.443007470000055],
              [-7.573346138999966, 10.44672775399999],
              [-7.580521106999981, 10.451104164000071],
              [-7.584139824999909, 10.453311920000147],
              [-7.5901780129999, 10.45362186400007],
              [-7.606339931999912, 10.449193955000055],
              [-7.606975078999881, 10.449020385999972],
              [-7.615571974999966, 10.449131966000152],
              [-7.620172977999971, 10.452638626000066],
              [-7.620265006999944, 10.453840257000081],
              [-7.621747969999888, 10.473818779000055],
              [-7.627010820999942, 10.480100633000063],
              [-7.631846904999975, 10.483388902000058],
              [-7.637623785999949, 10.486207009000111],
              [-7.641550064999876, 10.488121033000027],
              [-7.64645004199997, 10.486801147000051],
              [-7.651189803999898, 10.479741096000055],
              [-7.653090000999953, 10.473531723000065],
              [-7.654835224999886, 10.467829705000099],
              [-7.655533789999879, 10.466727256000127],
              [-7.664092063999931, 10.453231811000023],
              [-7.676333904999979, 10.441431046000162],
              [-7.681705951999902, 10.438679695000133],
              [-7.686396120999916, 10.436280252000131],
              [-7.686553955999955, 10.436244965000128],
              [-7.696672915999898, 10.433988572000032],
              [-7.709805965999919, 10.431060792000096],
              [-7.71728897099996, 10.429392814000096],
              [-7.718562125999938, 10.429109573000062],
              [-7.727859973999898, 10.425956726000038],
              [-7.728321075999929, 10.425800323000146],
              [-7.729319094999937, 10.42257118200007],
              [-7.730501174999972, 10.420598984000094],
              [-7.730985165999925, 10.419790269000089],
              [-7.732093809999981, 10.419354439000074],
              [-7.735874174999879, 10.417868614000156],
              [-7.743381023999973, 10.400348663000159],
              [-7.743443011999887, 10.395981789000132],
              [-7.741384982999932, 10.39365959200012],
              [-7.732325075999938, 10.393759727000145],
              [-7.728906155999937, 10.388880731000029],
              [-7.728520869999954, 10.383130074],
              [-7.730207918999952, 10.378779412000085],
              [-7.739307880999945, 10.375691415000119],
              [-7.738195895999979, 10.372220040000059],
              [-7.73266506199991, 10.36915874400006],
              [-7.729668140999934, 10.367510797000079],
              [-7.727279186999965, 10.355508804000067],
              [-7.728498936999927, 10.351161003000129],
              [-7.730184077999979, 10.349079133000032],
              [-7.731810091999876, 10.347069741000041],
              [-7.737410068999964, 10.345070840000119],
              [-7.751582145999976, 10.344808578000084],
              [-7.75620079, 10.342189788000098],
              [-7.756731986999967, 10.341889381000044],
              [-7.75933790199997, 10.338009834000104],
              [-7.756937981999954, 10.326939583000126],
              [-7.758255958999939, 10.32336521100018],
              [-7.758625983999878, 10.322361946],
              [-7.760964870999942, 10.32102203400018],
              [-7.768773078999971, 10.321297646000062],
              [-7.771411894999972, 10.321391105999965],
              [-7.772294043999978, 10.320537568000077],
              [-7.774236201999884, 10.318669318000047],
              [-7.775709151999934, 10.312939645000142],
              [-7.77367591899997, 10.308771134000096],
              [-7.768136978999905, 10.308267593000096],
              [-7.758831977999876, 10.307419776000017],
              [-7.756669998999939, 10.303215981000108],
              [-7.75656795499998, 10.303018570000063],
              [-7.756782054999974, 10.302879332999964],
              [-7.762427806999938, 10.299189568000145],
              [-7.763709068999901, 10.295780183000034],
              [-7.76381397199998, 10.295501710000167],
              [-7.764836786999979, 10.292780877000041],
              [-7.77137994799989, 10.289651871000103],
              [-7.777121067999929, 10.284786225000062],
              [-7.777256011999896, 10.284671783000022],
              [-7.782148837999898, 10.283351898000149],
              [-7.786382198999888, 10.279500008000127],
              [-7.79174709299997, 10.277737618000117],
              [-7.796851156999935, 10.270368577000113],
              [-7.797430991999931, 10.26953125100016],
              [-7.797730922999904, 10.269357682000077],
              [-7.800940990999948, 10.267510414000185],
              [-7.810098170999879, 10.243029594],
              [-7.815900801999931, 10.236529351000172],
              [-7.815989970999965, 10.236429215000044],
              [-7.816245078999941, 10.236355780999986],
              [-7.826711178999915, 10.233359338000128],
              [-7.836328981999884, 10.226080895000052],
              [-7.837868213999968, 10.224917412000138],
              [-7.839846133999913, 10.223420143000169],
              [-7.846461771999941, 10.214760781000052],
              [-7.854331970999908, 10.206487656000093],
              [-7.855926989999887, 10.204811096000071],
              [-7.863751886999921, 10.203591346000053],
              [-7.871261118999883, 10.202422143000035],
              [-7.887333869999964, 10.199919701000113],
              [-7.895032882999885, 10.197020530000145],
              [-7.907612799999981, 10.184166908000122],
              [-7.911260127999981, 10.180439949000061],
              [-7.914566992999937, 10.178380012000048],
              [-7.91733217299992, 10.17665863100018],
              [-7.926466940999887, 10.170969962000129],
              [-7.927585125999883, 10.170623778999982],
              [-7.937188147999962, 10.167649269000094],
              [-7.945398807999936, 10.167754174000152],
              [-7.948329924999882, 10.167791366000017],
              [-7.965976236999893, 10.175442697000108],
              [-7.966341971999952, 10.175601006000136],
              [-7.966815948999908, 10.175581933000046],
              [-7.978549003999945, 10.175108911],
              [-7.978137016999938, 10.179651261000174],
              [-7.963347911999961, 10.194760321999979],
              [-7.957448959999908, 10.200790405000021],
              [-7.952459811999916, 10.209920883],
              [-7.950551986999926, 10.233359338000128],
              [-7.947204112999941, 10.241130830000145],
              [-7.948703764999948, 10.251501083000107],
              [-7.959441185999879, 10.285440446000109],
              [-7.960949896999921, 10.29489135700004],
              [-7.968624113999965, 10.31361198400009],
              [-7.98009204899995, 10.325710296000125],
              [-7.995274066999969, 10.338311196000177],
              [-8.001265525999941, 10.340768814000114],
              [-8.006831168999952, 10.343051911000146],
              [-8.01023006399987, 10.343674660000033],
              [-8.011528013999964, 10.343913079000174],
              [-8.019609450999894, 10.345394135000106],
              [-8.047696113999905, 10.350543022000068],
              [-8.051108360999876, 10.351169586000083],
              [-8.05202197899996, 10.351237297000068],
              [-8.080357551999953, 10.353325845000029],
              [-8.080821036999907, 10.353360176000137],
              [-8.082222938999905, 10.353968619999989],
              [-8.084182738999971, 10.354821205000121],
              [-8.091686247999974, 10.358089448000101],
              [-8.108613013999957, 10.381052017000115],
              [-8.114282608999929, 10.392850877000058],
              [-8.11561107599988, 10.404832841000086],
              [-8.115967750999971, 10.408049584000025],
              [-8.118845939999915, 10.420270919000075],
              [-8.119229315999917, 10.420713425000088],
              [-8.120452880999949, 10.422128678000036],
              [-8.123620033999941, 10.422977448000097],
              [-8.125754356999892, 10.423547745000121],
              [-8.127634047999891, 10.424050331000103],
              [-8.136722565999946, 10.422079086],
              [-8.139248848999898, 10.421531678000179],
              [-8.140680313999951, 10.421220779000123],
              [-8.14817619299987, 10.416010857000174],
              [-8.160474775999944, 10.404290200000048],
              [-8.163415908999923, 10.401495934000138],
              [-8.16391563499991, 10.401021004000086],
              [-8.173233031999871, 10.399049760000025],
              [-8.189496993999967, 10.399238586000024],
              [-8.195640563999916, 10.400210381000079],
              [-8.198639869999909, 10.400684356000113],
              [-8.201305389999959, 10.401105881000092],
              [-8.202022552999949, 10.401220322000029],
              [-8.225393295999879, 10.409998893000136],
              [-8.2315969469999, 10.416279792000125],
              [-8.240627289999964, 10.440291405000096],
              [-8.242721557999971, 10.461020469000175],
              [-8.24425983499998, 10.464328766000051],
              [-8.244764327999974, 10.465409279000028],
              [-8.257006644999933, 10.472219468000162],
              [-8.261837005999894, 10.476868629000137],
              [-8.262447356999871, 10.479140283000163],
              [-8.263765333999913, 10.484041215000104],
              [-8.264067649999902, 10.48516941000014],
              [-8.262269973999935, 10.500790597000048],
              [-8.266160010999897, 10.506361008000056],
              [-8.270656586999905, 10.510218621000149],
              [-8.272184370999923, 10.511528015000124],
              [-8.273758886999929, 10.512879371],
              [-8.274828909999883, 10.515742303000138],
              [-8.275811195999893, 10.518367768000189],
              [-8.276694296999949, 10.520730972000138],
              [-8.277743338999812, 10.531091690000096],
              [-8.272542, 10.56025123600017],
              [-8.273184775999937, 10.575491905000035],
              [-8.273278236999943, 10.57773018000006],
              [-8.278051375999894, 10.587910652000062],
              [-8.280410765999875, 10.588089943000057],
              [-8.282729149999966, 10.58826732600005],
              [-8.285019874999932, 10.588441849000048],
              [-8.289155005999874, 10.593091012],
              [-8.289396284999953, 10.597820281999986],
              [-8.290280341999903, 10.615189553000164],
              [-8.2903032299999, 10.615638734000129],
              [-8.292215346999967, 10.617940902000157],
              [-8.295124052999938, 10.621440888000052],
              [-8.30147266299997, 10.636231422000094],
              [-8.301113128999873, 10.647958756000151],
              [-8.296880722999958, 10.673441887000024],
              [-8.296477316999926, 10.689079285000105],
              [-8.296703339999965, 10.689551353000127],
              [-8.305573462999973, 10.708271980000177],
              [-8.306881903999965, 10.716340065000054],
              [-8.30179882099992, 10.731886864000103],
              [-8.300941467999962, 10.734511377000047],
              [-8.295570373999908, 10.750939369000037],
              [-8.29702186499992, 10.753892900000096],
              [-8.297846793999952, 10.755571366000083],
              [-8.313447951999933, 10.754360199000132],
              [-8.322470664999969, 10.756176949000178],
              [-8.325519561999897, 10.756790161000083],
              [-8.32895660399987, 10.76189136500011],
              [-8.329323767999881, 10.770858765000071],
              [-8.326701162999882, 10.776809694000178],
              [-8.323508262999951, 10.782891274000121],
              [-8.316722869999921, 10.795800209000163],
              [-8.307519911999975, 10.807430268000076],
              [-8.306200026999932, 10.821900367000183],
              [-8.300062179999941, 10.82966137000011],
              [-8.298208237999972, 10.830080985000109],
              [-8.290353774999971, 10.831864356000096],
              [-8.286531447999948, 10.83273124699997],
              [-8.282066343999929, 10.836588860000063],
              [-8.2787427909999, 10.842531204000011],
              [-8.275600432999966, 10.853080750000174],
              [-8.275931358999969, 10.855440141000088],
              [-8.280493735999926, 10.888138771000172],
              [-8.281059263999964, 10.892203331000019],
              [-8.281644821999919, 10.896391870000116],
              [-8.281659126999955, 10.901508332000162],
              [-8.281693458999882, 10.913180352000097],
              [-8.278993606999961, 10.925568582000153],
              [-8.291296958999908, 10.971480371000098],
              [-8.286995887999922, 10.98154926300009],
              [-8.287069320999876, 10.993502618000093],
              [-8.287079810999956, 10.995130539000058],
              [-8.293227196999908, 11.007849693000139],
              [-8.295784949999927, 11.008331300000123],
              [-8.296593666999854, 11.009762765000175],
              [-8.297213553999882, 11.010864259000186],
              [-8.298879623999881, 11.013819695000109],
              [-8.305837630999974, 11.026161193000178],
              [-8.312975882999979, 11.033599854000045],
              [-8.317317962999937, 11.041231155000105],
              [-8.322566986999959, 11.04711532500005],
              [-8.323533056999963, 11.048199654000143],
              [-8.324189185999956, 11.048737526000082],
              [-8.344988822999824, 11.065740585000128],
              [-8.345119475999979, 11.06584835100017],
              [-8.347776412999963, 11.068021775000034],
              [-8.371001243999956, 11.074481010000056],
              [-8.377093314999968, 11.07531738400013],
              [-8.380153654999958, 11.075736999000128],
              [-8.381934165999951, 11.075981140000181],
              [-8.389576911999939, 11.069023132000041],
              [-8.389930725999875, 11.068700791000083],
              [-8.39523983, 11.065678597000158],
              [-8.39650630899996, 11.064958574000173],
              [-8.399067878999972, 11.063511849000065],
              [-8.401350020999871, 11.06305313200005],
              [-8.405605315999878, 11.062198639000144],
              [-8.415782927999885, 11.06897926400012],
              [-8.417428969999946, 11.069500924000124],
              [-8.422922133999975, 11.071244241000045],
              [-8.425533293999933, 11.072072030000015],
              [-8.432622908999974, 11.072625160000143],
              [-8.446018218999939, 11.07367038700005],
              [-8.468095778999953, 11.069540024000162],
              [-8.469677924999928, 11.069246292000173],
              [-8.472417830999973, 11.068733216],
              [-8.472857474999898, 11.068651199000044],
              [-8.482442855999977, 11.065070152000146],
              [-8.486263274999942, 11.056140900000116],
              [-8.501869200999977, 11.033207893000053],
              [-8.503048896999871, 11.031469345000119],
              [-8.51366805899994, 10.995010376000153],
              [-8.514455795999822, 10.986041068000191],
              [-8.513183593999884, 10.97383880600006],
              [-8.514596939999933, 10.972473145000151],
              [-8.515065191999952, 10.972020149000173],
              [-8.531160353999951, 10.975366593000103],
              [-8.535978316999888, 10.976368905000072],
              [-8.564065932999938, 10.961700440000129],
              [-8.571305274999929, 10.959711075000087],
              [-8.573570250999865, 10.958319664000157],
              [-8.580908774999898, 10.953821183000173],
              [-8.588253021999947, 10.951077462000114],
              [-8.590490340999963, 10.950241087999984],
              [-8.593050956999946, 10.951688766000075],
              [-8.598362922999968, 10.954691887000081],
              [-8.599732398999947, 10.954947471000139],
              [-8.60742664299994, 10.956390381000119],
              [-8.611378669999965, 10.957868576000124],
              [-8.61736106799998, 10.962887763000026],
              [-8.618282318999889, 10.963660240000081],
              [-8.622454643999902, 10.965319635000128],
              [-8.623436927999819, 10.965191841000035],
              [-8.628979682999955, 10.964471817000174],
              [-8.658911704999923, 10.950172425000062],
              [-8.659136772999887, 10.950064660000123],
              [-8.661031721999848, 10.949159622000025],
              [-8.66792392699989, 10.949810028000059],
              [-8.671962738999923, 10.950190544000066],
              [-8.677262304999942, 10.955311776000144],
              [-8.68021488099987, 10.962240220000183],
              [-8.680629729999907, 10.967069627000171],
              [-8.679527283999846, 10.97124767299999],
              [-8.677721976999976, 10.978079795000042],
              [-8.677755355999977, 10.978257179000025],
              [-8.682355880999921, 11.00275039600001],
              [-8.681370734999973, 11.007800103000136],
              [-8.679238319999911, 11.011448861000019],
              [-8.660475730999963, 11.024600030000045],
              [-8.654709815999979, 11.029790878000028],
              [-8.650367736999897, 11.03369998900007],
              [-8.650094986999875, 11.03502273600003],
              [-8.647668837999959, 11.046779632000039],
              [-8.648077009999895, 11.052311898000028],
              [-8.64763069199995, 11.054071426000121],
              [-8.645161627999926, 11.063782691000142],
              [-8.628282546999913, 11.078966140000091],
              [-8.624943732999952, 11.081970216000116],
              [-8.620675085999892, 11.08906078300015],
              [-8.620630263999942, 11.093429565000122],
              [-8.624897955999927, 11.108110428000089],
              [-8.625123976999873, 11.108888627000056],
              [-8.625302314999885, 11.113948822000168],
              [-8.622446058999969, 11.119899751000048],
              [-8.617500304999965, 11.124911308000037],
              [-8.611413001999893, 11.127840043000106],
              [-8.60652637499993, 11.127790451000124],
              [-8.602794647999929, 11.127751351000086],
              [-8.585638046999975, 11.124579430000097],
              [-8.581154823999896, 11.125909805000106],
              [-8.579833029999975, 11.127117156999986],
              [-8.577409743999908, 11.129330635000031],
              [-8.57691955599995, 11.129771233000042],
              [-8.570989607999934, 11.139830590000031],
              [-8.564303396999947, 11.15493869800008],
              [-8.570876121999959, 11.171809196000083],
              [-8.573287962999928, 11.185859681000068],
              [-8.570841788999871, 11.196649552000167],
              [-8.566327093999973, 11.204651832000025],
              [-8.562014579999868, 11.208033563000129],
              [-8.558808326999952, 11.210550309000041],
              [-8.553178787999912, 11.211933136000141],
              [-8.552501678999931, 11.212101936000067],
              [-8.529925345999914, 11.210009576000118],
              [-8.526531218999935, 11.212594032000084],
              [-8.52442073799989, 11.21420097399999],
              [-8.517705917999876, 11.219308854000019],
              [-8.516527175999954, 11.219346046000112],
              [-8.512346266999884, 11.219479562000117],
              [-8.497705458999974, 11.216111184000113],
              [-8.494501113999888, 11.217077255000049],
              [-8.492563247999954, 11.217660904000127],
              [-8.491444587999922, 11.21844768599999],
              [-8.486456870999973, 11.221961975999989],
              [-8.484544753999955, 11.226309777000097],
              [-8.484736442999918, 11.229990960000123],
              [-8.491641996999931, 11.237660409000114],
              [-8.493388175999883, 11.248261453000055],
              [-8.498417854999957, 11.25706005100011],
              [-8.499285697999881, 11.262820243000135],
              [-8.497338293999974, 11.270388603000185],
              [-8.493296621999946, 11.27748108000003],
              [-8.491107939999949, 11.27965068900005],
              [-8.48457908599994, 11.286122321999983],
              [-8.47918891899991, 11.288828849000026],
              [-8.476307868999925, 11.288904190000153],
              [-8.467065810999884, 11.289151192000134],
              [-8.45794677799995, 11.284461975000056],
              [-8.45014953499998, 11.280450821000159],
              [-8.446813583999926, 11.279258728],
              [-8.439602850999904, 11.276677132000088],
              [-8.422299384999974, 11.270480157000122],
              [-8.412516593999897, 11.269909860000098],
              [-8.411299704999976, 11.270281792000105],
              [-8.409068107999872, 11.270960807000108],
              [-8.406670569999903, 11.271690369000055],
              [-8.401547432999962, 11.271905899000103],
              [-8.393847464999965, 11.272230149000109],
              [-8.393280982999954, 11.272394181000038],
              [-8.382391929999869, 11.275550841999973],
              [-8.37786102299998, 11.278585434000092],
              [-8.376988411999889, 11.279170037000029],
              [-8.371987342999944, 11.288551331000178],
              [-8.364432335999936, 11.316989899000021],
              [-8.365085601999965, 11.320900917000188],
              [-8.367747306999945, 11.323170663000042],
              [-8.369191168999919, 11.324401856],
              [-8.369470596999975, 11.324641227000029],
              [-8.373958587999937, 11.324239731000034],
              [-8.385002134999922, 11.323260308000101],
              [-8.401671408999903, 11.321781158000078],
              [-8.408635139999944, 11.324193001000083],
              [-8.413707732999967, 11.325948716000141],
              [-8.416946411999959, 11.327071191000186],
              [-8.418153762999964, 11.327489852999975],
              [-8.421379089999903, 11.33096790400009],
              [-8.421792983999978, 11.335580825000136],
              [-8.421112059999928, 11.338211060000049],
              [-8.415992736999954, 11.353461265000021],
              [-8.410790442999939, 11.36007023000019],
              [-8.399912833999963, 11.364072800000088],
              [-8.399644850999835, 11.364171027000111],
              [-8.397447585999942, 11.364979745000028],
              [-8.396853447999888, 11.36497306900003],
              [-8.395191191999913, 11.36495494900015],
              [-8.383926391999921, 11.36483192500009],
              [-8.381281852999962, 11.365702629000168],
              [-8.380780219999963, 11.365868569000042],
              [-8.378637313999889, 11.366575242000181],
              [-8.375888824999947, 11.367480277000027],
              [-8.376374244999965, 11.368145943],
              [-8.378172874999962, 11.370615959000077],
              [-8.380577087999939, 11.373913766000044],
              [-8.380673408999883, 11.374044420000189],
              [-8.381550787999913, 11.375248909000106],
              [-8.384617804999948, 11.376663208000139],
              [-8.394411085999934, 11.381176949000121],
              [-8.396868705999907, 11.382309914000189],
              [-8.409009932999936, 11.381058693000114],
              [-8.411833761999901, 11.378561019000074],
              [-8.414237974999878, 11.37853813300012],
              [-8.423494338999944, 11.378449441000043],
              [-8.430682182999874, 11.382439613000145],
              [-8.432489394999948, 11.382829666000191],
              [-8.433721542999933, 11.383093834000022],
              [-8.437431334999928, 11.383890151000116],
              [-8.439333915999953, 11.383339883000019],
              [-8.440534591999892, 11.382991791],
              [-8.448187827999959, 11.380781173000059],
              [-8.456342697999958, 11.381560327000045],
              [-8.460655212999882, 11.383295059000034],
              [-8.476317405999907, 11.389590263000116],
              [-8.477436065999939, 11.38984489500001],
              [-8.47825431699988, 11.39003181400011],
              [-8.491207121999935, 11.392960549000065],
              [-8.501002310999979, 11.398639680000031],
              [-8.50210285199995, 11.399280548000092],
              [-8.508899687999929, 11.418910980000021],
              [-8.517228125999964, 11.425891877000083],
              [-8.526736258999904, 11.431280137000044],
              [-8.528556823999907, 11.435671807000119],
              [-8.52688694099993, 11.439332009000111],
              [-8.522334097999931, 11.441234588000043],
              [-8.519523620999905, 11.442408561000093],
              [-8.517751692999923, 11.443148613000062],
              [-8.525517463999961, 11.459561347000033],
              [-8.525639534999925, 11.468993187000081],
              [-8.525647162999974, 11.469614982999985],
              [-8.528784751999865, 11.473394395000071],
              [-8.530949591999899, 11.47595119500005],
              [-8.531934737999904, 11.476371765000067],
              [-8.534306525999966, 11.477391243],
              [-8.534390449999876, 11.477425576000087],
              [-8.535363197999971, 11.477840424000135],
              [-8.54010295799992, 11.473257064000165],
              [-8.5445499409999, 11.468957902000056],
              [-8.547685622999893, 11.467476845000022],
              [-8.55480289399992, 11.464116096000055],
              [-8.563282966999907, 11.46010971000004],
              [-8.563892364999958, 11.45982170200017],
              [-8.565674780999927, 11.45897960700006],
              [-8.57187938699991, 11.445801735000032],
              [-8.576062202999879, 11.443473816000051],
              [-8.576336860999959, 11.443320274000143],
              [-8.578324318999933, 11.443343162000076],
              [-8.581698416999927, 11.443380356000148],
              [-8.585564612999917, 11.446962357000132],
              [-8.588591574999896, 11.449766158000045],
              [-8.591659545999903, 11.452608109000096],
              [-8.593489646999899, 11.454301833000045],
              [-8.596014975999935, 11.456640243000095],
              [-8.60094642599995, 11.45685863500006],
              [-8.601140975999954, 11.45686817100011],
              [-8.601691245999916, 11.456892014000061],
              [-8.602772711999876, 11.456939697],
              [-8.603057860999854, 11.456870079000112],
              [-8.605390549999925, 11.456289291000189],
              [-8.610721586999944, 11.454961777000108],
              [-8.611232757999972, 11.45437431400012],
              [-8.611968992999948, 11.45352363700016],
              [-8.612629890999926, 11.452760695999984],
              [-8.613195419999954, 11.453372002000151],
              [-8.613407134999932, 11.453601838000168],
              [-8.620209693999868, 11.460969925000029],
              [-8.630042074999835, 11.471296310000128],
              [-8.63092041099992, 11.472217560000161],
              [-8.632178306999947, 11.473539352000103],
              [-8.635683058999916, 11.477231980000056],
              [-8.63926029199996, 11.483053208000172],
              [-8.641654968999944, 11.486949921000075],
              [-8.645084381999936, 11.489252091000139],
              [-8.647912979999944, 11.491149902000018],
              [-8.654132843999946, 11.499261857000022],
              [-8.65773868499997, 11.512410164000187],
              [-8.665340423999908, 11.522830009000131],
              [-8.670388222999975, 11.556010247000131],
              [-8.672033309999904, 11.561462402000132],
              [-8.673102377999896, 11.564998628000183],
              [-8.685283660999971, 11.570261002],
              [-8.685650825999971, 11.570421219000025],
              [-8.688864708999972, 11.576198578000117],
              [-8.689313887999902, 11.579091073000086],
              [-8.690624235999962, 11.587491989000114],
              [-8.68592739099995, 11.605430603],
              [-8.682872772999872, 11.617091179000113],
              [-8.682334899999887, 11.624670982000112],
              [-8.683341979999966, 11.627051353000127],
              [-8.684966087999896, 11.630883217000132],
              [-8.685074805999932, 11.631138802],
              [-8.691568374999861, 11.635569572000179],
              [-8.695562362999908, 11.63578796500002],
              [-8.702066421999916, 11.636141776999978],
              [-8.702956198999914, 11.635811806000163],
              [-8.710257530999968, 11.633106232000102],
              [-8.71516704599992, 11.631285667000043],
              [-8.719633102999978, 11.629631044000178],
              [-8.725849151999967, 11.629918100000111],
              [-8.733699798999908, 11.630279541999982],
              [-8.740148543999965, 11.624550821000184],
              [-8.759404181999969, 11.607444764000149],
              [-8.759575844999858, 11.607293130000016],
              [-8.760130882999931, 11.606800078999981],
              [-8.761932372999979, 11.608308792000059],
              [-8.769623755999874, 11.614754677],
              [-8.772986412999956, 11.617570876000059],
              [-8.77342796399995, 11.617940903000033],
              [-8.774437903999967, 11.618057251000096],
              [-8.779954909999901, 11.618687631],
              [-8.781094550999967, 11.619338036000102],
              [-8.785052298999972, 11.621589662000076],
              [-8.785294531999909, 11.621728896000036],
              [-8.785471915999949, 11.621744157000137],
              [-8.785719871999959, 11.621764183000039],
              [-8.786601065999946, 11.621837616000164],
              [-8.791595459999826, 11.622247696000159],
              [-8.795826910999892, 11.618841171000099],
              [-8.797312735999981, 11.615447999000025],
              [-8.797607421999942, 11.614774705000116],
              [-8.797732352999901, 11.614489555000148],
              [-8.798626899999931, 11.614790916000118],
              [-8.801646232999929, 11.615813256000138],
              [-8.804713249999907, 11.616850853000074],
              [-8.80566978499985, 11.617567063000024],
              [-8.808425903999876, 11.61962413800012],
              [-8.811272621999876, 11.62174987700007],
              [-8.812827109999887, 11.622908592000101],
              [-8.822134970999969, 11.626210212999979],
              [-8.854325294999967, 11.628337861000034],
              [-8.852081298999906, 11.63822078700008],
              [-8.845238685999959, 11.668269157000111],
              [-8.823991775999957, 11.761619569000175],
              [-8.822243690999926, 11.769301414000097],
              [-8.820287704999885, 11.780366897000022],
              [-8.801586150999981, 11.886150359000112],
              [-8.795610427999918, 11.919921874000124],
              [-8.794913292999979, 11.923871994000081],
              [-8.796194075999892, 11.928807258000063],
              [-8.797845840999969, 11.935169221000024],
              [-8.801346778999914, 11.935889245000055],
              [-8.801725386999976, 11.945550918000038],
              [-8.814425467999968, 11.962459565000074],
              [-8.8162021629999, 11.972601890000021],
              [-8.807828902999972, 11.993921279000176],
              [-8.805889129999969, 12.002868653000121],
              [-8.805390358999887, 12.005172729000151],
              [-8.812392234999891, 12.014049530000023],
              [-8.831009864999942, 12.03094100900006],
              [-8.832016943999975, 12.037082672000111],
              [-8.832331656999884, 12.038999557000182],
              [-8.835360525999931, 12.039950371000145],
              [-8.840438843999948, 12.037090302000024],
              [-8.842174529999909, 12.036109924000073],
              [-8.845909117999952, 12.036829948000161],
              [-8.846157073999905, 12.037094117000038],
              [-8.850302695999915, 12.041481973000032],
              [-8.855904578999969, 12.041990279000174],
              [-8.8617963799999, 12.03722000099998],
              [-8.866103171999953, 12.029961586000127],
              [-8.868449210999927, 12.026008606000062],
              [-8.871512412999948, 12.023281098000155],
              [-8.87524318699991, 12.02400112100014],
              [-8.876620291999927, 12.025829315000067],
              [-8.881978034999918, 12.032951356000012],
              [-8.883088111999939, 12.034429550000141],
              [-8.896122932999901, 12.040080070000101],
              [-8.906888962999972, 12.038110733000167],
              [-8.906626700999937, 12.041091919999985],
              [-8.910683632999962, 12.041131019000147],
              [-8.912936210999874, 12.041151047000028],
              [-8.915381430999958, 12.054287911000188],
              [-8.919301031999908, 12.059848786000146],
              [-8.91619682399994, 12.066720963000023],
              [-8.914991379999947, 12.095009804000028],
              [-8.909853933999955, 12.094498635000093],
              [-8.907036781999921, 12.095850945000109],
              [-8.905934332999834, 12.097313882000094],
              [-8.905649184999959, 12.097694397000055],
              [-8.903248786999939, 12.100880624000126],
              [-8.901293754999927, 12.109830856000144],
              [-8.901329994999969, 12.130081177000079],
              [-8.895483970999976, 12.146591186000023],
              [-8.896680831999959, 12.151889801000095],
              [-8.897148130999938, 12.152240753],
              [-8.90503597299994, 12.158180236000021],
              [-8.905706404999876, 12.161400795000134],
              [-8.905508040999905, 12.161907196000016],
              [-8.902830124999923, 12.168740273000083],
              [-8.910034179999968, 12.173170089000109],
              [-8.913674354999955, 12.184053420999987],
              [-8.914125441999886, 12.185408592000158],
              [-8.920189857999901, 12.184585571000184],
              [-8.922085761999938, 12.184330941000042],
              [-8.924632071999952, 12.187111855000126],
              [-8.924835205999955, 12.190340996000032],
              [-8.922444343999928, 12.195840836000173],
              [-8.923356056999978, 12.197059631000059],
              [-8.924523353999916, 12.198620796000171],
              [-8.930625914999837, 12.19591140700004],
              [-8.934553146999974, 12.197970391000069],
              [-8.934738159999824, 12.198064805000058],
              [-8.936445234999951, 12.198961257000178],
              [-8.939283370999931, 12.195760727000049],
              [-8.938657760999945, 12.187480927000081],
              [-8.939336775999891, 12.18695736],
              [-8.941012381999883, 12.185660362000021],
              [-8.945693968999933, 12.185240747000023],
              [-8.94871425499997, 12.18696403500013],
              [-8.952670097999885, 12.189220428000056],
              [-8.95501136799993, 12.189009666],
              [-8.957271575999869, 12.186969757999975],
              [-8.958547592999935, 12.185818672000153],
              [-8.961241721999897, 12.186972618000027],
              [-8.963925360999895, 12.188120842000046],
              [-8.964604376999944, 12.188408853000112],
              [-8.971437454999943, 12.183779716],
              [-8.973310469999888, 12.182510376000153],
              [-8.97859096499991, 12.192910193999978],
              [-8.977144241999952, 12.197718621000035],
              [-8.979435919999958, 12.202580452000063],
              [-8.978046416999973, 12.211343765000095],
              [-8.97797107599996, 12.211811067000042],
              [-8.979084967999938, 12.212529183000129],
              [-8.992900847999977, 12.221440316000155],
              [-8.993820190999941, 12.251360893000026],
              [-8.993721961999881, 12.251784325000017],
              [-8.993239401999915, 12.253870010000014],
              [-8.987021444999868, 12.280740737000144],
              [-8.982889175999844, 12.287058830000035],
              [-8.981332778999956, 12.289428711000085],
              [-8.96743393, 12.300359726000067],
              [-8.960328101999892, 12.310651779000182],
              [-8.957666396999855, 12.320751190000124],
              [-8.960143087999938, 12.332041741000182],
              [-8.966151236999963, 12.343300819000092],
              [-8.968897818999949, 12.348448754000117],
              [-8.969964027999936, 12.349466325000037],
              [-8.983238219999919, 12.362096787000041],
              [-8.987531661999981, 12.366183281000133],
              [-8.988400459999923, 12.36701107000016],
              [-8.992359160999911, 12.369118690999983],
              [-9.000255584999934, 12.377553941000031],
              [-9.002337454999974, 12.379777908000108],
              [-9.008668899999975, 12.384101868000073],
              [-9.01241397799987, 12.388772964000054],
              [-9.013022422999939, 12.38953113499997],
              [-9.018724440999961, 12.400644303000036],
              [-9.019168853999929, 12.401510239000118],
              [-9.023253440999952, 12.409465789000024],
              [-9.024998664999941, 12.412872314000083],
              [-9.03427887, 12.423070909000046],
              [-9.045703887999878, 12.428448678000109],
              [-9.05776214499997, 12.431654931000082],
              [-9.058212280999953, 12.431775093999988],
              [-9.078178406999939, 12.437083245],
              [-9.085883139999964, 12.439131737000025],
              [-9.093564032999893, 12.444950104000156],
              [-9.103255271999899, 12.449152946000083],
              [-9.10382938399988, 12.449401855000133],
              [-9.104236602999947, 12.450133324000035],
              [-9.106671333999941, 12.454500199000108],
              [-9.107312201999946, 12.454943657000058],
              [-9.108903883999915, 12.456047059000014],
              [-9.112327576999974, 12.458417893000103],
              [-9.117941856999892, 12.45893096900005],
              [-9.122741699999949, 12.461179734000041],
              [-9.124934196999959, 12.462209703000099],
              [-9.127659797999968, 12.463893892000101],
              [-9.131664275999924, 12.466366768000057],
              [-9.135875702999954, 12.468970300000024],
              [-9.146788596999954, 12.479191781000111],
              [-9.147583961999942, 12.47969818199999],
              [-9.1544685369999, 12.484089851000022],
              [-9.158293723999975, 12.485211373000084],
              [-9.159628867999913, 12.485606194000184],
              [-9.161707877999902, 12.486221313000158],
              [-9.163172721999956, 12.486284256000147],
              [-9.181363106999925, 12.487071038000011],
              [-9.182120322999879, 12.486484528000176],
              [-9.182540893999942, 12.486159325000187],
              [-9.186784743999908, 12.487242699000092],
              [-9.188145636999877, 12.48759079000007],
              [-9.189864157999978, 12.487456321000025],
              [-9.192461013999889, 12.487250329000062],
              [-9.201262473999918, 12.486551286],
              [-9.201842308999915, 12.48669242800014],
              [-9.202515602999938, 12.486854553000171],
              [-9.209688186999927, 12.488599777000161],
              [-9.211073875999944, 12.488930703000165],
              [-9.211176871999896, 12.488986015000137],
              [-9.217405317999919, 12.492308616000116],
              [-9.221126554999898, 12.494295121000107],
              [-9.22552108799988, 12.496641159000035],
              [-9.233359335999978, 12.495795249000082],
              [-9.235364914999934, 12.495578766000165],
              [-9.244618415999923, 12.498262405000105],
              [-9.24517154699987, 12.498420715000066],
              [-9.247950553999942, 12.500868797000066],
              [-9.248230934999981, 12.501114846000121],
              [-9.24912166699994, 12.501898765000021],
              [-9.253329276999921, 12.502631187000077],
              [-9.274454116999948, 12.495670318000123],
              [-9.280309675999945, 12.485027314000035],
              [-9.280632972999968, 12.48444080400003],
              [-9.280829429999869, 12.484301568000035],
              [-9.281805037999959, 12.483611108000105],
              [-9.284166336999931, 12.481941223000035],
              [-9.286767958999974, 12.482097625000051],
              [-9.288610457999937, 12.482210160000022],
              [-9.29235267599995, 12.483619691],
              [-9.292703628999846, 12.483750344999976],
              [-9.29421234199998, 12.484319687000038],
              [-9.296309470999915, 12.486042977000125],
              [-9.312580107999963, 12.499419212000134],
              [-9.312966345999939, 12.499485969999967],
              [-9.313688277999972, 12.499610901000096],
              [-9.31555938799994, 12.499935151000102],
              [-9.319593428999895, 12.500630377999983],
              [-9.32082557699988, 12.492818832000069],
              [-9.321035383999856, 12.492356301000086],
              [-9.325353621999909, 12.482731820000026],
              [-9.330077170999971, 12.477938652000091],
              [-9.33184528299995, 12.47758960800013],
              [-9.333434104999981, 12.477277756000092],
              [-9.335703848999913, 12.476828575000184],
              [-9.336052894999909, 12.477277756000092],
              [-9.338947295999958, 12.481001854000056],
              [-9.339392661999966, 12.489795685000161],
              [-9.339552878999882, 12.492968559000133],
              [-9.340390205999881, 12.493159293000076],
              [-9.342085837999946, 12.493543626000189],
              [-9.343757628999981, 12.49392032600008],
              [-9.348014830999887, 12.492059707000067],
              [-9.348928450999892, 12.491661073000103],
              [-9.354696273999821, 12.493430138],
              [-9.356451988999936, 12.493968964000032],
              [-9.358254432999956, 12.494522095000036],
              [-9.362003326999911, 12.495670318000123],
              [-9.364145278999956, 12.491318702000115],
              [-9.36660194399991, 12.476390840000079],
              [-9.369669912999825, 12.473419190000072],
              [-9.38512897499993, 12.472161294000102],
              [-9.391322135999872, 12.46616745099999],
              [-9.391732216999969, 12.465769767000154],
              [-9.393860816999961, 12.46533966200019],
              [-9.397919654999953, 12.464509963000182],
              [-9.399431227999969, 12.464206696000076],
              [-9.401577948999886, 12.463770866000061],
              [-9.404639243999952, 12.461499215000117],
              [-9.4046592709999, 12.46139717200009],
              [-9.405808448999949, 12.455438613000126],
              [-9.406102179999948, 12.453921319000187],
              [-9.40360164599997, 12.444469451999964],
              [-9.400338172999966, 12.443879129000095],
              [-9.399626731999888, 12.443749428000103],
              [-9.394543648999957, 12.447711946000027],
              [-9.392327307999892, 12.449440002000017],
              [-9.387411116999942, 12.449628831000098],
              [-9.384477614999867, 12.447380066000107],
              [-9.38438892299996, 12.447312355000122],
              [-9.384287832999974, 12.447037697000098],
              [-9.376596449999965, 12.426091195000026],
              [-9.369876860999966, 12.417518616000109],
              [-9.367777824999962, 12.416588783000179],
              [-9.36510944399987, 12.418340682000064],
              [-9.362065314999882, 12.420338631000106],
              [-9.361191748999886, 12.42091179],
              [-9.357688903999872, 12.420189859000061],
              [-9.357098578999967, 12.419232369000042],
              [-9.356547355999965, 12.418334962000131],
              [-9.3558397299999, 12.417181015000153],
              [-9.355876921999879, 12.412579537000113],
              [-9.35744857799989, 12.407617570000127],
              [-9.357633591999957, 12.407036782000034],
              [-9.358277321999822, 12.405011178000166],
              [-9.353225707999911, 12.404518127000131],
              [-9.34798908199997, 12.404007912999987],
              [-9.343246459999875, 12.4007940300001],
              [-9.340772628999901, 12.399119376999977],
              [-9.337080955999909, 12.392648698000073],
              [-9.336226463999822, 12.38251972099999],
              [-9.334165571999904, 12.376991272000112],
              [-9.32858943899987, 12.371878625000079],
              [-9.325846671999955, 12.370505333000153],
              [-9.324395178999907, 12.369779587000153],
              [-9.318926810999926, 12.368839265000133],
              [-9.318610190999834, 12.368783950000136],
              [-9.314580916999944, 12.368088722000152],
              [-9.312465667999902, 12.369448661000149],
              [-9.311572075999948, 12.368900300000121],
              [-9.30906867899995, 12.36735725300008],
              [-9.303233146999958, 12.36376094700006],
              [-9.300358772999971, 12.361989974000039],
              [-9.297580717999949, 12.358518601000128],
              [-9.296911238999883, 12.354600906000087],
              [-9.299269676999927, 12.352318764000074],
              [-9.303577423999911, 12.351257324000187],
              [-9.304659843999957, 12.350991249000117],
              [-9.307023047999962, 12.348251343000072],
              [-9.307519912999965, 12.344570160000046],
              [-9.304082869999888, 12.335570336000046],
              [-9.304347991999975, 12.331892014000175],
              [-9.307839392999938, 12.324785233],
              [-9.308629989999872, 12.323181153000178],
              [-9.309941291999962, 12.321771621999972],
              [-9.312877654999966, 12.318619729000034],
              [-9.316599845999917, 12.321180344000084],
              [-9.320118903999969, 12.319829941000023],
              [-9.322484969999891, 12.316620826000133],
              [-9.323701858999925, 12.310651779000182],
              [-9.31882858299997, 12.277029991000063],
              [-9.31979846899992, 12.272891998999967],
              [-9.329249381999944, 12.263743401000113],
              [-9.329581259999941, 12.263422012999968],
              [-9.329933165999933, 12.263079644000186],
              [-9.331425665999973, 12.263064384000131],
              [-9.3406791699999, 12.262964249000106],
              [-9.341863632999946, 12.262949943000024],
              [-9.342756271999974, 12.25933265600014],
              [-9.344522476999941, 12.252161025000134],
              [-9.340349196999966, 12.247520448000103],
              [-9.340357779999977, 12.246364594000056],
              [-9.340381622999871, 12.243334771000093],
              [-9.340385435999963, 12.242920875000152],
              [-9.342868804999966, 12.243574142999989],
              [-9.348883628999829, 12.245157242000175],
              [-9.35297107599996, 12.246230125000011],
              [-9.353920937999874, 12.246480942000062],
              [-9.359532355999932, 12.246751786000118],
              [-9.361280439999973, 12.246236802000112],
              [-9.365627288999974, 12.24496078500016],
              [-9.369541166999909, 12.246027948000062],
              [-9.372125625999956, 12.24673271100005],
              [-9.387332915999934, 12.250880242000108],
              [-9.388229369999976, 12.251774787000159],
              [-9.391276359999893, 12.25482273199998],
              [-9.398923873999934, 12.250873565000177],
              [-9.401372908999974, 12.24960994800017],
              [-9.402888297999937, 12.249870300000055],
              [-9.40557765899996, 12.250329972000031],
              [-9.409267425999929, 12.256089212000177],
              [-9.409534453999982, 12.256506920999982],
              [-9.411355018999871, 12.259350777000122],
              [-9.41462135199987, 12.260290146000102],
              [-9.424929617999965, 12.258069992000117],
              [-9.432250022999824, 12.254988670000046],
              [-9.441588400999933, 12.251059533000102],
              [-9.446127890999946, 12.25073623600008],
              [-9.457432745999938, 12.249929428000144],
              [-9.461944579999965, 12.24960994800017],
              [-9.472212790999947, 12.246320724000157],
              [-9.473901747999946, 12.245780946000082],
              [-9.477284430999873, 12.24580574],
              [-9.479280471999914, 12.245820046000119],
              [-9.48796176899998, 12.242210389000149],
              [-9.488535880999962, 12.241600990000109],
              [-9.488798140999961, 12.241323472000033],
              [-9.490554809999935, 12.239460945000019],
              [-9.497371674999954, 12.226638794000053],
              [-9.499567032999892, 12.222511291000046],
              [-9.505222319999916, 12.216799736000041],
              [-9.50577068299998, 12.216503144000058],
              [-9.508840559999953, 12.214843749000124],
              [-9.52916813, 12.203861237000069],
              [-9.539996146999954, 12.193970681000053],
              [-9.541410445999929, 12.192680358000132],
              [-9.54678153999987, 12.193400384000029],
              [-9.548998831999938, 12.192050934000122],
              [-9.551151274999938, 12.190741540000147],
              [-9.553124426999943, 12.18954086299999],
              [-9.556644439999957, 12.189864159000138],
              [-9.56150340999983, 12.190308572000049],
              [-9.563526153999931, 12.190496445],
              [-9.569477080999945, 12.191040993000115],
              [-9.575645446999943, 12.188794136000126],
              [-9.578151702999946, 12.187879563000024],
              [-9.584235189999959, 12.187459946000047],
              [-9.584528923999926, 12.187275887],
              [-9.588932037999882, 12.184499740000092],
              [-9.595050811999897, 12.183581352000147],
              [-9.600634575999948, 12.182740212000169],
              [-9.602998733999925, 12.181088446999979],
              [-9.604151725999941, 12.180280685000128],
              [-9.604865073999974, 12.179781914000159],
              [-9.607303618999936, 12.179718971],
              [-9.61188125599989, 12.179599762],
              [-9.618223189999981, 12.176436424000144],
              [-9.619155883999952, 12.175971032000177],
              [-9.625705718999939, 12.175318719000074],
              [-9.626812934999975, 12.174731254000051],
              [-9.630397795999954, 12.17283058300012],
              [-9.6345615379999, 12.168642044000023],
              [-9.63699245499987, 12.166200637000088],
              [-9.639596938999887, 12.161389352000185],
              [-9.664050102999966, 12.140851974000157],
              [-9.669227599999942, 12.134351730000049],
              [-9.677724837999904, 12.123689652000166],
              [-9.678282736999961, 12.122365951000177],
              [-9.680125235999981, 12.117987633000098],
              [-9.680814742999928, 12.116349221000064],
              [-9.680461883999953, 12.099550247000082],
              [-9.680110930999888, 12.098031998000181],
              [-9.67782688099993, 12.088102340000148],
              [-9.676639556999874, 12.082942008000032],
              [-9.675691604999884, 12.078821183000059],
              [-9.67691135299998, 12.075158119000037],
              [-9.67974853599992, 12.06665039000012],
              [-9.68390274099994, 12.063252448000185],
              [-9.684212684999977, 12.062998771000082],
              [-9.690255165999872, 12.061640740000087],
              [-9.691463469999974, 12.061369897000134],
              [-9.692170142999885, 12.061209680000104],
              [-9.695935248999945, 12.057560921000118],
              [-9.698081969999919, 12.050901414],
              [-9.697719572999972, 12.035250664000046],
              [-9.699756622999928, 12.023820878000038],
              [-9.727231978999953, 12.025895120000087],
              [-9.733663559999968, 12.026379585000143],
              [-9.739184378999823, 12.027405738000027],
              [-9.765653610999891, 12.03232193100007],
              [-9.769631384999968, 12.031880378000039],
              [-9.776702880999949, 12.034788132000131],
              [-9.790621756999883, 12.040511132000063],
              [-9.805690764999952, 12.053155900000036],
              [-9.809936523999966, 12.056719780000037],
              [-9.8160114289999, 12.057682037000177],
              [-9.819258690999902, 12.056019784000057],
              [-9.825385092999852, 12.052900315000102],
              [-9.833087920999901, 12.054099084000086],
              [-9.863693237999939, 12.058861731999968],
              [-9.866395949999969, 12.058100701000171],
              [-9.876003264999895, 12.055406570000116],
              [-9.876602172999981, 12.055238725000152],
              [-9.876885413999958, 12.055410386000062],
              [-9.880093573999943, 12.057331085999976],
              [-9.881434440999954, 12.057230949000143],
              [-9.882902144999946, 12.057121278000068],
              [-9.888948440999911, 12.061988831000178],
              [-9.908229826999957, 12.081648827000038],
              [-9.911481856999956, 12.09045982300006],
              [-9.914239883999926, 12.097938538000051],
              [-9.914440155999955, 12.098481179000089],
              [-9.919802664999907, 12.100818634000177],
              [-9.929700850999836, 12.098473549],
              [-9.935591696999893, 12.097079278000024],
              [-9.936889647999976, 12.096771241000056],
              [-9.941795349999893, 12.097489357000143],
              [-9.945743559999926, 12.100522995000119],
              [-9.946432112999958, 12.101051332000054],
              [-9.952964782999914, 12.106069565000041],
              [-9.958578108999973, 12.105408669000042],
              [-9.967220306999934, 12.100406647000113],
              [-9.970161436999945, 12.098704339000108],
              [-9.97077369799996, 12.098348618000045],
              [-9.977893828999925, 12.105759620000072],
              [-9.980998039999918, 12.108989716000167],
              [-9.981474876999926, 12.110102653000126],
              [-9.981808662999924, 12.11088085200015],
              [-9.983062743999881, 12.113801002000173],
              [-9.984176635999916, 12.114451409000083],
              [-9.989219664999951, 12.117383957000072],
              [-9.990297317999932, 12.118011474000184],
              [-9.995778084999927, 12.124830246000045],
              [-9.996543882999958, 12.125265121000041],
              [-9.999653815999977, 12.127033234000066],
              [-9.999999046999903, 12.127229691000082],
              [-10.001689911999847, 12.12819194799999],
              [-10.003243446999932, 12.128232003000051],
              [-10.024370193999971, 12.128780366],
              [-10.030429840999943, 12.131798745000083],
              [-10.033172606999869, 12.134887695000032],
              [-10.037700653999877, 12.139986039000178],
              [-10.039258956999959, 12.141740799000047],
              [-10.045101165999938, 12.14268875099998],
              [-10.047522544999879, 12.141981125000086],
              [-10.052065849999906, 12.140651703],
              [-10.052820204999875, 12.14043045000011],
              [-10.053628920999927, 12.142045020000126],
              [-10.056518553999922, 12.147818566000126],
              [-10.059320449999916, 12.14966964700011],
              [-10.060051918999875, 12.149216651000074],
              [-10.061900137999942, 12.148070335000114],
              [-10.062542915999927, 12.14388847400005],
              [-10.063349723999977, 12.138649941000153],
              [-10.065373420999947, 12.138909341000101],
              [-10.067090033999932, 12.139128686000049],
              [-10.072334289999958, 12.146388054000113],
              [-10.072429656999873, 12.146519662000173],
              [-10.076629637999929, 12.147229195000136],
              [-10.077610016999927, 12.149835588000087],
              [-10.078121185999976, 12.151187896000067],
              [-10.078708647999974, 12.152761460000022],
              [-10.079093933999957, 12.15523624400015],
              [-10.081299781999974, 12.16942119600003],
              [-10.081648825999878, 12.171641350000016],
              [-10.084429740999951, 12.17649078400018],
              [-10.088806151999961, 12.17866992900008],
              [-10.089734076999889, 12.179132461000165],
              [-10.090958594999961, 12.179740906000177],
              [-10.095629690999942, 12.179507256000022],
              [-10.098583221999945, 12.179359436000027],
              [-10.099139212999944, 12.179330825000079],
              [-10.104011535999859, 12.177440644000058],
              [-10.107339859999911, 12.176151276000155],
              [-10.109582899999964, 12.175978660000055],
              [-10.112959860999922, 12.175721168000109],
              [-10.129323959999965, 12.17784118700007],
              [-10.131408690999876, 12.178111078000143],
              [-10.138420103999977, 12.17512130800003],
              [-10.143131256999936, 12.17311096100002],
              [-10.144023895999965, 12.173045158000036],
              [-10.144672393999883, 12.172997475000045],
              [-10.148979185999906, 12.172679901000095],
              [-10.16069221499987, 12.179964065000036],
              [-10.167619704999936, 12.184271812000077],
              [-10.17281818299989, 12.185188294000056],
              [-10.180491447999941, 12.1865396500001],
              [-10.183740614999977, 12.187111855000126],
              [-10.186770438999929, 12.189661979000107],
              [-10.190472602999932, 12.197864533000029],
              [-10.190931319999947, 12.198881149000101],
              [-10.191346168999928, 12.199064255000167],
              [-10.193691253999873, 12.200100898000073],
              [-10.195130348999953, 12.200740815000131],
              [-10.210650443999953, 12.201009751000186],
              [-10.213600158999952, 12.201060296000037],
              [-10.218559263999907, 12.201972008000155],
              [-10.219444274999944, 12.202134133000129],
              [-10.220728872999928, 12.202370644000098],
              [-10.223880767999958, 12.202948571000036],
              [-10.22756385799994, 12.201820374000022],
              [-10.237929343999951, 12.198641777000034],
              [-10.241375922999964, 12.199102402000051],
              [-10.243309973999942, 12.199359894],
              [-10.246100424999952, 12.20189857500003],
              [-10.247481346999905, 12.206281663000141],
              [-10.247264861999952, 12.207562448000147],
              [-10.246355056999903, 12.212947845000144],
              [-10.24627971599989, 12.213401794000106],
              [-10.24837875399993, 12.215250016000084],
              [-10.248663901999976, 12.215251923000153],
              [-10.25310516299993, 12.215275764000069],
              [-10.25376033799995, 12.215279579000139],
              [-10.263433456999962, 12.209328650999964],
              [-10.264291761999914, 12.208800316000065],
              [-10.26712131499994, 12.207058907000146],
              [-10.27644920399996, 12.212849618000064],
              [-10.281081198999971, 12.212708474000124],
              [-10.282999038999947, 12.212649346000035],
              [-10.286532401999921, 12.210292816000106],
              [-10.28722000099998, 12.209832192000022],
              [-10.28745079, 12.209679603999973],
              [-10.287478445999966, 12.209574700000189],
              [-10.288880348999953, 12.204401017000123],
              [-10.286119459999952, 12.196125984000162],
              [-10.285650252999915, 12.194719316000089],
              [-10.288941381999905, 12.19129181],
              [-10.29362010899996, 12.190850256999965],
              [-10.294352530999902, 12.191123010000069],
              [-10.294469833999926, 12.191166877000057],
              [-10.299220085999877, 12.192938805000153],
              [-10.304390905999981, 12.210386277000111],
              [-10.304751395999858, 12.211601257000098],
              [-10.309389114999874, 12.218750954000029],
              [-10.312890051999943, 12.220609664000165],
              [-10.313396452999882, 12.220661163999978],
              [-10.3201398839999, 12.221330643000044],
              [-10.322891234999929, 12.220471383000188],
              [-10.327399253999943, 12.219060899],
              [-10.327518462999876, 12.219136238000146],
              [-10.335493087999964, 12.224246980000032],
              [-10.336588859999949, 12.224949837],
              [-10.339319229999887, 12.225481033000108],
              [-10.341211318999967, 12.220420838],
              [-10.340060234999839, 12.215589524000109],
              [-10.333046913999965, 12.20393943800002],
              [-10.332151411999973, 12.202449798000089],
              [-10.33195114199998, 12.194399834000137],
              [-10.333038330999955, 12.19186592200009],
              [-10.333248137999931, 12.19137573200004],
              [-10.334483145999968, 12.18850040500007],
              [-10.335008620999929, 12.187271119000172],
              [-10.351598740999918, 12.193090440000162],
              [-10.355850219999979, 12.192505837],
              [-10.358148574999973, 12.192190170000117],
              [-10.360959051999885, 12.189901352999982],
              [-10.360548972999823, 12.175641060000032],
              [-10.362565039999936, 12.173007966000114],
              [-10.363352775999886, 12.17198085800004],
              [-10.370720863999964, 12.168924332000131],
              [-10.371089933999883, 12.168771745000186],
              [-10.371099471999969, 12.167620658999965],
              [-10.378103254999928, 12.168931008000129],
              [-10.381150245999947, 12.16950130400005],
              [-10.396140097999876, 12.176369666000085],
              [-10.404048918999933, 12.175790787000096],
              [-10.409683227999835, 12.173076629000036],
              [-10.410719870999969, 12.172577858000068],
              [-10.413081168999952, 12.168955804000063],
              [-10.414460181999914, 12.166838647000134],
              [-10.41363716099994, 12.157868386000189],
              [-10.412461279999832, 12.145011902000078],
              [-10.413097381999876, 12.143421172000103],
              [-10.414029120999885, 12.141091348000089],
              [-10.41594982099997, 12.140348434999964],
              [-10.418284415999892, 12.139450073999967],
              [-10.43188476499995, 12.134204865000072],
              [-10.434099196999966, 12.133351326000025],
              [-10.435129164999978, 12.132740974000171],
              [-10.439668654999878, 12.130048753000096],
              [-10.448142051999923, 12.121004104000178],
              [-10.448980330999916, 12.120109558000081],
              [-10.456400871999904, 12.116531372000111],
              [-10.467478752999909, 12.117818833000172],
              [-10.475872993999872, 12.12042427199998],
              [-10.476961134999954, 12.120761871000127],
              [-10.481036185999869, 12.121200561000023],
              [-10.494488715999978, 12.122659684000041],
              [-10.503141403999962, 12.121770860000026],
              [-10.504346847999955, 12.121219635000159],
              [-10.50741767999989, 12.119817734000094],
              [-10.509201050999934, 12.119004250000103],
              [-10.51062965299991, 12.118350030000158],
              [-10.516501425999934, 12.112851142000068],
              [-10.517057418999912, 12.111162186000115],
              [-10.518141746999845, 12.107866288000082],
              [-10.518618583999967, 12.106418611000151],
              [-10.518141746999845, 12.105652810000095],
              [-10.514830589999974, 12.100350379000076],
              [-10.5102539049999, 12.09442234100004],
              [-10.507229805999884, 12.090500831999975],
              [-10.506956100999901, 12.089626312000121],
              [-10.505578993999904, 12.0852108],
              [-10.505148887999951, 12.08383274],
              [-10.50702857899995, 12.080380441000102],
              [-10.516605377999895, 12.079104423000047],
              [-10.51873016299993, 12.078821183000059],
              [-10.521540641999877, 12.076071739000099],
              [-10.523669243999962, 12.068941117000065],
              [-10.523364066999932, 12.067691804000162],
              [-10.520448684999963, 12.055820465000124],
              [-10.521217345999958, 12.053569794000168],
              [-10.521389961999944, 12.053059578000045],
              [-10.520700454999883, 12.051117897000097],
              [-10.520300865999957, 12.049989701000186],
              [-10.520758629999932, 12.048464775000127],
              [-10.52218341899993, 12.043740274000072],
              [-10.522939681999901, 12.041231156000151],
              [-10.524480818999962, 12.039768220000099],
              [-10.528776167999979, 12.035690309000074],
              [-10.530981063999889, 12.033596038999974],
              [-10.532690048999939, 12.031708717000186],
              [-10.558639526999968, 12.026510239000118],
              [-10.569157599999926, 12.027159692000112],
              [-10.570672035999905, 12.027253151000082],
              [-10.570949553999981, 12.027271272000064],
              [-10.575959205999936, 12.023658753000063],
              [-10.578290938999885, 12.021979332000114],
              [-10.579009056999951, 12.013929367000117],
              [-10.574140548999935, 12.008132935000106],
              [-10.572030066999844, 12.005621910000116],
              [-10.568285941999932, 12.003347396000038],
              [-10.565956114999892, 12.00193691200019],
              [-10.563640593999878, 12.000530244000174],
              [-10.562950133999948, 11.997770310000078],
              [-10.564358710999954, 11.995928764000098],
              [-10.567971229999898, 11.994956018000096],
              [-10.572779654999977, 11.993660927000121],
              [-10.576126098999907, 11.987672806000035],
              [-10.577010155999972, 11.986089707000019],
              [-10.577815055999963, 11.985828400000173],
              [-10.581918715999905, 11.984490395000023],
              [-10.585396765999917, 11.984960557000022],
              [-10.588768004999963, 11.985417366000092],
              [-10.588930129999881, 11.985440254000025],
              [-10.589777946999959, 11.98453521700003],
              [-10.596220015999904, 11.977671623000049],
              [-10.599479675999874, 11.974199295000119],
              [-10.605548857999963, 11.973990440000023],
              [-10.610564230999955, 11.975922585999967],
              [-10.612790107999842, 11.976781846000051],
              [-10.617074965999961, 11.972703935000027],
              [-10.620751380999877, 11.969209671000101],
              [-10.625699042999884, 11.957730293000111],
              [-10.62689590399998, 11.952882766000073],
              [-10.628379822999932, 11.946869850000098],
              [-10.630188942999951, 11.939570427000035],
              [-10.633478163999882, 11.934749604000046],
              [-10.634354590999919, 11.934070587000065],
              [-10.63741874699997, 11.931694031000063],
              [-10.64027023299991, 11.929480552000143],
              [-10.641460418999884, 11.923500062000016],
              [-10.640089988999932, 11.913150787],
              [-10.642439841999931, 11.910160064000138],
              [-10.644021033999934, 11.909387588000129],
              [-10.645116804999816, 11.908852578000108],
              [-10.651328085999921, 11.905820846000097],
              [-10.656241415999943, 11.904596329000128],
              [-10.659240721999936, 11.903846741000109],
              [-10.65950965899998, 11.903780938000068],
              [-10.662560461999817, 11.900540351000075],
              [-10.662653923999926, 11.900001526000153],
              [-10.662819861999878, 11.899061204000191],
              [-10.670069693999949, 11.895066262],
              [-10.672221184999955, 11.893879890999983],
              [-10.678298949999942, 11.893584252000153],
              [-10.690210342999876, 11.893000603000075],
              [-10.697220801999947, 11.896011354000052],
              [-10.717040061999967, 11.915610314000048],
              [-10.731288909999876, 11.916560174000153],
              [-10.735489844999904, 11.91932868900011],
              [-10.73941898399994, 11.927499772000033],
              [-10.739666937999914, 11.928016663000108],
              [-10.742470741999909, 11.933840752000151],
              [-10.748226165999881, 11.940426827000181],
              [-10.74857902499997, 11.94083118400016],
              [-10.750415801999964, 11.942934037000157],
              [-10.751330376999874, 11.943980217000046],
              [-10.745451926999863, 11.962828637000086],
              [-10.749871253999856, 11.973877907000031],
              [-10.753093719999924, 11.983749390000128],
              [-10.756368637999913, 11.993780135000065],
              [-10.761340140999948, 11.999211312000114],
              [-10.77772903399989, 12.010951042000158],
              [-10.783089637999979, 12.019844056000181],
              [-10.786008833999915, 12.024688721000189],
              [-10.78939437799994, 12.027420044000166],
              [-10.792308806999927, 12.029771804000063],
              [-10.795088767999971, 12.037599564000061],
              [-10.794150352999964, 12.041508674],
              [-10.788990020999961, 12.04839038900019],
              [-10.78497028299995, 12.06587123800017],
              [-10.784480094999935, 12.073911667000061],
              [-10.786300657999902, 12.093063355000027],
              [-10.787172316999829, 12.102217674000087],
              [-10.787428855999906, 12.104981423000083],
              [-10.787508964999972, 12.105106353999986],
              [-10.790229796999938, 12.109358788000122],
              [-10.79513931199989, 12.112723351000056],
              [-10.796151161999944, 12.113415719000159],
              [-10.79629039799994, 12.113512038000067],
              [-10.808912277999866, 12.115077019000125],
              [-10.815009117999921, 12.115835190000098],
              [-10.815220832999955, 12.115860938000083],
              [-10.819281577999845, 12.117500305000078],
              [-10.82900047399994, 12.121419906000142],
              [-10.845100402999947, 12.131581307000033],
              [-10.860751151999921, 12.145481109000116],
              [-10.870301246999873, 12.153959274000044],
              [-10.876830099999893, 12.161110879000148],
              [-10.879112243999884, 12.167894364000176],
              [-10.879151343999922, 12.168009759000029],
              [-10.880048750999947, 12.176700592000145],
              [-10.880290031999834, 12.179061890000128],
              [-10.880540846999963, 12.179410934000032],
              [-10.88634967899992, 12.187581062000106],
              [-10.887414930999967, 12.191344260999983],
              [-10.889767646999928, 12.199658395000142],
              [-10.890521048999915, 12.202320100000179],
              [-10.892160415999911, 12.203931808000107],
              [-10.893326757999887, 12.203131675000066],
              [-10.896036147999894, 12.201274873000102],
              [-10.899180411999964, 12.199121475000084],
              [-10.900167465999857, 12.19960403500005],
              [-10.902919769999926, 12.200960158999976],
              [-10.907811164999828, 12.212711334000176],
              [-10.910610197999972, 12.214320182000051],
              [-10.910891533999973, 12.214320182000051],
              [-10.918319701999962, 12.214341163000142],
              [-10.928601265999873, 12.222419739000088],
              [-10.930253982999943, 12.222785949000013],
              [-10.93279933999986, 12.223349572000018],
              [-10.935824394999941, 12.220958710000104],
              [-10.936106682999934, 12.220734597000103],
              [-10.940059662999886, 12.217611314000067],
              [-10.94381046399991, 12.217390061000174],
              [-10.948279380999907, 12.219431877000147],
              [-10.952919005999945, 12.221551896000165],
              [-10.964904784999931, 12.221190454000066],
              [-10.967180251999878, 12.221120835000079],
              [-10.97297954599992, 12.218742372000065],
              [-10.974438666999959, 12.218139649000022],
              [-10.982761383999957, 12.21118164000012],
              [-10.985679625999921, 12.208741188000147],
              [-10.987339018999933, 12.208393097000055],
              [-10.992086410999946, 12.207398415000171],
              [-10.992230414999881, 12.207368851000183],
              [-10.993491171999949, 12.207409858000119],
              [-11.004811286999882, 12.207777023000062],
              [-11.007429122999952, 12.207860947000029],
              [-11.009984969999948, 12.20712471000013],
              [-11.010475157999906, 12.20698356600019],
              [-11.013665199999878, 12.206065178000074],
              [-11.015378951999935, 12.205570222000119],
              [-11.015883445999918, 12.206065178000074],
              [-11.017658233999953, 12.207818985000131],
              [-11.02004909599998, 12.210181236000096],
              [-11.023089408999908, 12.211111069000026],
              [-11.028889655999876, 12.210856437000075],
              [-11.030696867999836, 12.210777283000141],
              [-11.033140181999954, 12.210671426000147],
              [-11.033302306999929, 12.210860252000145],
              [-11.039440154999909, 12.218040466000048],
              [-11.042721748999952, 12.219868660000145],
              [-11.044820784999956, 12.221038819000057],
              [-11.048331260999817, 12.217129708000186],
              [-11.049800872999981, 12.205222131000028],
              [-11.050231932999964, 12.201729775000103],
              [-11.055158613999936, 12.191841125999986],
              [-11.055168151999908, 12.18517017400012],
              [-11.057723043999829, 12.18038368200007],
              [-11.060561179999979, 12.17506027200011],
              [-11.061194418999889, 12.169450760000132],
              [-11.061989783999877, 12.162409782000054],
              [-11.06737995199984, 12.154600144000085],
              [-11.072058677999962, 12.137860299000181],
              [-11.072778701999937, 12.13527965600008],
              [-11.077855109999973, 12.131924629000025],
              [-11.082139967999922, 12.129090308000059],
              [-11.088671683999905, 12.125747681000178],
              [-11.09243869799991, 12.123820306000141],
              [-11.099450111999886, 12.121990203000166],
              [-11.099901197999941, 12.121672630999967],
              [-11.10176086499996, 12.120367050000027],
              [-11.103410719999943, 12.119209290000185],
              [-11.104370116999917, 12.118538856000157],
              [-11.106480598999951, 12.113948821000065],
              [-11.105560302999891, 12.109732628000188],
              [-11.103919028999826, 12.102211953000051],
              [-11.105798721999918, 12.097149849000118],
              [-11.109119414999952, 12.094985008000094],
              [-11.109309195999856, 12.094861032000154],
              [-11.110058783999875, 12.094867707000105],
              [-11.129729270999917, 12.095081330000085],
              [-11.134320258999821, 12.095129967000105],
              [-11.139459608999971, 12.093058587000144],
              [-11.142270087999975, 12.089850427000101],
              [-11.14415931699989, 12.078808785000092],
              [-11.138111113999969, 12.059471131000066],
              [-11.141816139999889, 12.052006721000055],
              [-11.14255905199991, 12.050509453000188],
              [-11.144510268999966, 12.04864120600007],
              [-11.152150153999969, 12.041319848],
              [-11.15256500299995, 12.04106903100012],
              [-11.156248092999931, 12.038853645000188],
              [-11.160690306999982, 12.036181450000129],
              [-11.162947654999869, 12.03482055600017],
              [-11.164728165999918, 12.033748628000126],
              [-11.16541671799996, 12.033333779000145],
              [-11.172546386999954, 12.029042244999971],
              [-11.172731398999872, 12.028930665000189],
              [-11.172971724999968, 12.028593064],
              [-11.175365446999876, 12.025247575000094],
              [-11.178819655999973, 12.020420074000072],
              [-11.184199332999981, 12.01744079600013],
              [-11.184382438999876, 12.017373086000191],
              [-11.186547278999853, 12.016569138000079],
              [-11.189514159999931, 12.015463829000055],
              [-11.192021369999964, 12.014531135000141],
              [-11.205084799999952, 12.016581536000047],
              [-11.212506294999912, 12.017746926000029],
              [-11.212590217999946, 12.017761231000065],
              [-11.224040984999931, 12.012022018000096],
              [-11.233319282999901, 12.001544952000074],
              [-11.238301275999902, 11.995920181000031],
              [-11.245334624999828, 11.993115426000031],
              [-11.246343612999908, 11.992712975000018],
              [-11.246951103999891, 11.992468834000022],
              [-11.257471083999917, 11.992480279000119],
              [-11.28005504499987, 12.004310608000139],
              [-11.297101020999946, 12.013238907000186],
              [-11.303627966999954, 12.016656874999967],
              [-11.312400817999901, 12.021247865000078],
              [-11.339627265999979, 12.042044639000039],
              [-11.355179787999816, 12.053918838000129],
              [-11.361760138999898, 12.059941293000065],
              [-11.361627579999947, 12.061724664000053],
              [-11.36034870199984, 12.079039572999989],
              [-11.370948791999979, 12.099250795000046],
              [-11.375990867999974, 12.103891372000021],
              [-11.381920813999841, 12.106738091000125],
              [-11.385109900999907, 12.108269692000079],
              [-11.397958754999877, 12.108269692000079],
              [-11.40735626299994, 12.110754014000122],
              [-11.411050795999813, 12.111728669],
              [-11.417265891999875, 12.113938331000043],
              [-11.424679756999922, 12.116573334000066],
              [-11.433051108999962, 12.119548798000039],
              [-11.439570426999978, 12.121870995000052],
              [-11.449949263999883, 12.126837730000034],
              [-11.451094626999975, 12.127386093000041],
              [-11.464111326999955, 12.133610725000096],
              [-11.469020842999953, 12.138670922000188],
              [-11.469479560999957, 12.139802933000112],
              [-11.47114467699987, 12.143903732000069],
              [-11.473220823999895, 12.149021149000077],
              [-11.47844886699994, 12.156250001000103],
              [-11.481639860999962, 12.16067791099999],
              [-11.494214057999955, 12.178074836000121],
              [-11.494501113999888, 12.178468704000068],
              [-11.494630812999901, 12.179183006000187],
              [-11.495901106999952, 12.186060905000033],
              [-11.494594572999915, 12.195276260000185],
              [-11.494501113999888, 12.195949554000094],
              [-11.497685432999958, 12.209059715000137],
              [-11.497797012999968, 12.209525110000186],
              [-11.492390631999911, 12.21459102700004],
              [-11.490188598999964, 12.218600273000106],
              [-11.482569694999825, 12.232529640000052],
              [-11.476491927999916, 12.238511086000017],
              [-11.463158607999901, 12.241720201000078],
              [-11.45946216699997, 12.247837067000091],
              [-11.458709716999977, 12.249078751000184],
              [-11.456152916999883, 12.250861169000018],
              [-11.453101158999971, 12.252987862000055],
              [-11.45192909199983, 12.265872002000094],
              [-11.449818609999909, 12.271162033],
              [-11.448018072999957, 12.271729469999968],
              [-11.443955420999885, 12.273012162000043],
              [-11.443270682999923, 12.273228645000131],
              [-11.4420080189999, 12.274471283000082],
              [-11.439290998999979, 12.277141571000072],
              [-11.43859004899997, 12.277830123000172],
              [-11.434848785999975, 12.286801338000032],
              [-11.434785842999872, 12.287380218000123],
              [-11.434512137999889, 12.289836883000135],
              [-11.433670997999911, 12.297381402000042],
              [-11.437877654999909, 12.304739952000148],
              [-11.43748188, 12.309100151000052],
              [-11.436709402999895, 12.317388534000031],
              [-11.439266204999967, 12.331903457000124],
              [-11.439330100999939, 12.332267761000082],
              [-11.439749717999916, 12.334651948000044],
              [-11.440224647999969, 12.335384370000099],
              [-11.442009925999969, 12.33813858100018],
              [-11.450039862999915, 12.350521087000118],
              [-11.448631286999898, 12.35489273100012],
              [-11.441848753999864, 12.357647896000117],
              [-11.439970970999923, 12.36340141300019],
              [-11.439864158999967, 12.366826058000129],
              [-11.439740179999944, 12.370759965000104],
              [-11.439661025999953, 12.378608703000054],
              [-11.439361571999939, 12.379014969000082],
              [-11.437190055999963, 12.381971360000023],
              [-11.433623313999874, 12.383042336000187],
              [-11.432877540999925, 12.383266449000132],
              [-11.432129860999964, 12.383488654000132],
              [-11.43194007999989, 12.383549690000052],
              [-11.428192138999918, 12.384754181000176],
              [-11.425930975999904, 12.385480880000159],
              [-11.417027472999962, 12.39465427500005],
              [-11.416331290999892, 12.395369531000085],
              [-11.413990973999887, 12.395131112000115],
              [-11.413883208999948, 12.394769668000094],
              [-11.412818908999895, 12.391222001000074],
              [-11.414699553999981, 12.382940293000161],
              [-11.413046836999854, 12.378467559000114],
              [-11.412831306999976, 12.377880096000069],
              [-11.408618926999964, 12.377650261000156],
              [-11.405345915999931, 12.380741120000039],
              [-11.402697561999844, 12.383242608000046],
              [-11.402528763999896, 12.383399962999988],
              [-11.402175902999943, 12.383239747000061],
              [-11.399491308999927, 12.382020950000026],
              [-11.395141600999921, 12.383235932000048],
              [-11.392941474999873, 12.383851051000022],
              [-11.388609886999973, 12.383691788000078],
              [-11.386715887999912, 12.383621215],
              [-11.380301474999953, 12.383381844000041],
              [-11.378522873999884, 12.385002137000072],
              [-11.377434730999937, 12.385994912000058],
              [-11.37374973299984, 12.389360429000078],
              [-11.373924255999896, 12.390021325000021],
              [-11.375149725999961, 12.39465046000015],
              [-11.374679565999941, 12.40223884500017],
              [-11.380518912999946, 12.411218643000097],
              [-11.379562377999832, 12.413569451000114],
              [-11.379460334999919, 12.413559913000029],
              [-11.377452850999816, 12.41799545300006],
              [-11.377387045999967, 12.417985916000021],
              [-11.377306936999901, 12.416150092],
              [-11.374330519999944, 12.416466714000137],
              [-11.37329482899986, 12.41670131800015],
              [-11.372562407999908, 12.417057037],
              [-11.372146605999887, 12.417531967000059],
              [-11.371109008999838, 12.419489861000045],
              [-11.370275497999955, 12.419412613000077],
              [-11.369300841999973, 12.419006348000096],
              [-11.368609428999946, 12.418863297000087],
              [-11.367496489999894, 12.418926240000019],
              [-11.366852759999915, 12.419082642000035],
              [-11.366104126999971, 12.419415475000108],
              [-11.365470884999979, 12.419881822000036],
              [-11.364720344999967, 12.421226502000138],
              [-11.364527702999908, 12.421938896000086],
              [-11.364417075999938, 12.423913956000092],
              [-11.36436271599996, 12.427042961999973],
              [-11.364190101999952, 12.42880725800012],
              [-11.363607406999904, 12.43033027700011],
              [-11.362908362999974, 12.431492806000108],
              [-11.362686156999871, 12.432073593000098],
              [-11.362541197999974, 12.433257104000063],
              [-11.36270141499989, 12.43562221500008],
              [-11.363501547999931, 12.437095641000155],
              [-11.363907813999958, 12.437586784000189],
              [-11.365092276999917, 12.4384698880001],
              [-11.367382049999947, 12.439491273000101],
              [-11.36756801599995, 12.440698624999982],
              [-11.367976187999886, 12.442099571000085],
              [-11.368312835999916, 12.442721367000047],
              [-11.368980406999924, 12.443327903000124],
              [-11.369483947999925, 12.443785667000157],
              [-11.369611738999936, 12.444618226000046],
              [-11.369433402999846, 12.447141647000024],
              [-11.369229315999917, 12.448894501000098],
              [-11.369335173999957, 12.450569153000117],
              [-11.369805335999956, 12.451575280000156],
              [-11.370267866999939, 12.45217323300011],
              [-11.370856284999888, 12.452571869000053],
              [-11.370913504999919, 12.452672006000114],
              [-11.3712263099999, 12.453217507000033],
              [-11.369629859999918, 12.453840256000149],
              [-11.369493483999918, 12.453845978000061],
              [-11.363595008999937, 12.454109193000079],
              [-11.361878393999916, 12.45346736800019],
              [-11.361275672999966, 12.453674316000104],
              [-11.360781669999938, 12.454071044000045],
              [-11.360455512999977, 12.454609872000049],
              [-11.360335349999957, 12.455224991000023],
              [-11.360335349999957, 12.456333160000156],
              [-11.359813689999953, 12.456840516000057],
              [-11.357728956999949, 12.458624840000084],
              [-11.356878279999933, 12.459479332000058],
              [-11.356363295999927, 12.460656164999989],
              [-11.356322288999934, 12.461576463000142],
              [-11.35651302299982, 12.46332454800006],
              [-11.356346129999963, 12.46412658800017],
              [-11.355671882999957, 12.46434879300017],
              [-11.354965209999932, 12.464381217000039],
              [-11.353742599999919, 12.463464737000038],
              [-11.352696418999869, 12.463096619000112],
              [-11.351898193999887, 12.463068008000164],
              [-11.349580764999928, 12.463540078000165],
              [-11.34856319499994, 12.464407921000088],
              [-11.34810829099996, 12.465080262000015],
              [-11.347989082999845, 12.465695381000046],
              [-11.348365783999839, 12.466816902],
              [-11.348898887999894, 12.467500686000108],
              [-11.349400519999904, 12.468030930000111],
              [-11.349557876999882, 12.469541549000098],
              [-11.348873137999874, 12.471496583000032],
              [-11.348764419999952, 12.473381996000171],
              [-11.348863601999881, 12.474078179000117],
              [-11.349138259999961, 12.47484111700004],
              [-11.349591255999883, 12.476099969000074],
              [-11.351667402999965, 12.479422569],
              [-11.352825164999956, 12.48016357400013],
              [-11.353461264999908, 12.480410576000168],
              [-11.354541777999941, 12.480502128000069],
              [-11.357880591999901, 12.481681823000088],
              [-11.357816695999929, 12.482831954000119],
              [-11.356476783999938, 12.484572411000158],
              [-11.354384422999885, 12.488261224000098],
              [-11.350674628999911, 12.486000060000038],
              [-11.350524901999961, 12.487059593000083],
              [-11.350495337999917, 12.488117219000173],
              [-11.350358961999973, 12.489088060000142],
              [-11.350297928999908, 12.492424965999987],
              [-11.350443840999901, 12.493392944000163],
              [-11.350559233999945, 12.494411469000113],
              [-11.350755690999961, 12.495047569000178],
              [-11.351577758999952, 12.496597288999965],
              [-11.351784705999933, 12.497574807000035],
              [-11.352004050999938, 12.498492241000122],
              [-11.351987837999957, 12.499623299000064],
              [-11.352089882999962, 12.500242233000108],
              [-11.352494239999942, 12.50134754200019],
              [-11.352799415999925, 12.501877783999987],
              [-11.353717803999928, 12.503012657],
              [-11.354178427999898, 12.503555298000038],
              [-11.35438728299988, 12.505742073000079],
              [-11.354513168999972, 12.50703334800005],
              [-11.354738234999957, 12.507739067000102],
              [-11.355141638999953, 12.508225442000139],
              [-11.357967375999976, 12.510065078000082],
              [-11.359191893999935, 12.511053086000118],
              [-11.359895705999918, 12.511440277000077],
              [-11.36099910799993, 12.511782646000086],
              [-11.362117767999905, 12.511960029000136],
              [-11.363774299999932, 12.512136460000079],
              [-11.364804268999876, 12.511985778000053],
              [-11.366212844999893, 12.511858940000025],
              [-11.366939543999933, 12.511636734000092],
              [-11.367985724999869, 12.510765076000098],
              [-11.369049072999928, 12.509293557000092],
              [-11.369602203999932, 12.507871628000146],
              [-11.370500564999929, 12.505332946000067],
              [-11.371510505999936, 12.503301621000105],
              [-11.372349739999891, 12.50208282400007],
              [-11.372611044999928, 12.501565934000155],
              [-11.373259544999939, 12.500284195000063],
              [-11.373818397999912, 12.49799823799998],
              [-11.374816894999924, 12.496010782000042],
              [-11.374825477999934, 12.495384217000151],
              [-11.374615668999979, 12.494791985000177],
              [-11.374212264999926, 12.494305612000176],
              [-11.373037336999971, 12.493867873999989],
              [-11.373251914999969, 12.492555618000154],
              [-11.37300205299988, 12.491426467000053],
              [-11.372950552999953, 12.48922061899998],
              [-11.372424125999942, 12.487453461000143],
              [-11.372146605999887, 12.486897470000088],
              [-11.372276305999947, 12.486245156000109],
              [-11.373103141999877, 12.486732482000093],
              [-11.373731611999915, 12.487579347000064],
              [-11.374378204999971, 12.488494872000047],
              [-11.375244140999939, 12.489676475000067],
              [-11.377268790999949, 12.491233826000041],
              [-11.377944946999946, 12.49175453200013],
              [-11.378268241999933, 12.492411613000058],
              [-11.3783922199999, 12.493503572000066],
              [-11.378274917999875, 12.494336128000043],
              [-11.377738, 12.496089936000033],
              [-11.377577781999946, 12.496987343000114],
              [-11.377290725999899, 12.497532845000137],
              [-11.376855850999959, 12.498980523000171],
              [-11.376762388999964, 12.501725197000098],
              [-11.376662253999939, 12.502349854000158],
              [-11.376314162999904, 12.503226280000035],
              [-11.375466345999939, 12.505228044000091],
              [-11.375326156999961, 12.505896567999969],
              [-11.375432968999917, 12.507088661000125],
              [-11.375182151999979, 12.508661270000118],
              [-11.37491512299988, 12.509650231000137],
              [-11.37486171799992, 12.510280610000166],
              [-11.375063896999961, 12.51240158000013],
              [-11.374786376999907, 12.512989997999966],
              [-11.374667167999974, 12.513605118999976],
              [-11.37463092899992, 12.516177176999975],
              [-11.374881743999879, 12.518817903000183],
              [-11.375106811999956, 12.519523621000133],
              [-11.376277923999965, 12.521373747999974],
              [-11.376718521999976, 12.522342683000147],
              [-11.377166747999922, 12.523328782000078],
              [-11.377649307999889, 12.523739815999988],
              [-11.379759788999934, 12.524676323000108],
              [-11.381919860999915, 12.525391578999972],
              [-11.385499953999954, 12.525574685000038],
              [-11.387637138999935, 12.525605201000076],
              [-11.386722565999946, 12.526227950000191],
              [-11.385520934999818, 12.527079583000045],
              [-11.385193823999941, 12.527617455000097],
              [-11.384644507999951, 12.529445649000024],
              [-11.38462257499998, 12.530959129000053],
              [-11.384203909999826, 12.531597137000063],
              [-11.38309478799988, 12.533850670000106],
              [-11.383053778999965, 12.534777641000119],
              [-11.382807731999947, 12.535799981000025],
              [-11.382735251999975, 12.53781509300012],
              [-11.382837295999821, 12.538434030000076],
              [-11.383149145999937, 12.538980485000081],
              [-11.38379955399995, 12.53964710200006],
              [-11.38530540499994, 12.54070568100019],
              [-11.386001586999953, 12.540991784000141],
              [-11.3875598899999, 12.541039467000076],
              [-11.389236450999931, 12.540768624000123],
              [-11.390274046999878, 12.540656089000151],
              [-11.391248702999974, 12.540443421000134],
              [-11.39339733099996, 12.540684700000156],
              [-11.394384384999967, 12.540352822000045],
              [-11.396110535999981, 12.539855957000043],
              [-11.396809577999932, 12.539638519000164],
              [-11.39745426099995, 12.541069031000063],
              [-11.398240089999888, 12.541872026000135],
              [-11.401437759999965, 12.543933868000011],
              [-11.402082442999927, 12.544186592000131],
              [-11.403964043999906, 12.54409408500004],
              [-11.407686232999936, 12.54297733300001],
              [-11.408289908999905, 12.542655945000092],
              [-11.409108161999939, 12.541734697000038],
              [-11.409743308999964, 12.540774344999988],
              [-11.410399437999899, 12.53908157300009],
              [-11.410665512999913, 12.538454056000148],
              [-11.411060333999956, 12.536789895000027],
              [-11.411074638999935, 12.536239624000075],
              [-11.41113376699991, 12.533999444000131],
              [-11.411017416999869, 12.532485008000094],
              [-11.411148070999957, 12.530977250000035],
              [-11.410804748999965, 12.529984474000116],
              [-11.41063022599991, 12.528584481],
              [-11.410665512999913, 12.527592659000049],
              [-11.410833357999934, 12.526845932000128],
              [-11.411431312999923, 12.525794983000139],
              [-11.412487030999898, 12.525765418999981],
              [-11.413204193999889, 12.525774956000191],
              [-11.413892745999931, 12.526268960000039],
              [-11.414794920999896, 12.526769638000076],
              [-11.415885924999941, 12.527023315000179],
              [-11.416986465999969, 12.527198791000103],
              [-11.419184684999948, 12.527377129000115],
              [-11.420503615999905, 12.527486802000055],
              [-11.421002386999874, 12.527918817],
              [-11.421565055999963, 12.52825260200018],
              [-11.42274284399997, 12.528656959000159],
              [-11.423373222999942, 12.530099868999969],
              [-11.423434257999929, 12.53486347200004],
              [-11.423391341999888, 12.537961007000092],
              [-11.423110007999981, 12.53972625800003],
              [-11.423065185999917, 12.541650773000015],
              [-11.422919273999923, 12.542913437000038],
              [-11.42289257099992, 12.544176101000062],
              [-11.422994612999958, 12.544794083000056],
              [-11.423753738999949, 12.546349526000085],
              [-11.426335334999976, 12.549216270000102],
              [-11.426775932999931, 12.549998283999969],
              [-11.427183151999941, 12.550491332000092],
              [-11.427731514999948, 12.550811767000027],
              [-11.429776190999974, 12.551419258000124],
              [-11.432209967999938, 12.55152130100015],
              [-11.433719634999875, 12.551226617000111],
              [-11.434922218999816, 12.550913812000033],
              [-11.435894012999938, 12.550384522000115],
              [-11.43722915699982, 12.549453736000032],
              [-11.438101767999967, 12.548362731999987],
              [-11.438537598999972, 12.547709465000025],
              [-11.438727377999953, 12.546846389000052],
              [-11.439345358999958, 12.545672417000105],
              [-11.440267562999964, 12.545128823000027],
              [-11.440691946999891, 12.545602798000118],
              [-11.441894530999832, 12.546725273000163],
              [-11.442606926999872, 12.547533036000118],
              [-11.442978858999936, 12.548218728000165],
              [-11.442344664999951, 12.550703049000106],
              [-11.441846846999965, 12.55141544300011],
              [-11.441375731999869, 12.552159310000093],
              [-11.441134452999904, 12.552770614000053],
              [-11.440946578999899, 12.554342270000063],
              [-11.440276144999928, 12.555320741000116],
              [-11.439766883999937, 12.556259155000134],
              [-11.438718794999886, 12.557173730000045],
              [-11.438124656999946, 12.558073998000054],
              [-11.437882422999962, 12.558685304000051],
              [-11.437834738999925, 12.560081482000101],
              [-11.438092230999928, 12.561390878000054],
              [-11.438055991999931, 12.564056398000162],
              [-11.437808036999911, 12.564618112000119],
              [-11.437668800999973, 12.564933777000022],
              [-11.436470031999875, 12.565333367000051],
              [-11.434221266999884, 12.565540315000135],
              [-11.433463095999969, 12.566180230000043],
              [-11.43231773499997, 12.565753937000011],
              [-11.430445671999962, 12.565056802000186],
              [-11.428888321999978, 12.564523697000027],
              [-11.427517889999933, 12.56405449],
              [-11.425300598999968, 12.563812256000062],
              [-11.422708511999815, 12.563138962000096],
              [-11.420915602999969, 12.562933923000116],
              [-11.41921234099982, 12.563044548000107],
              [-11.417306898999982, 12.563152313000046],
              [-11.41651630399997, 12.563379288000078],
              [-11.413497924999945, 12.565659523000022],
              [-11.412646292999909, 12.56645870300008],
              [-11.411623, 12.567569732999971],
              [-11.410860061999927, 12.568299294000042],
              [-11.409358023999971, 12.569603920000191],
              [-11.408229828999822, 12.57067966599999],
              [-11.406663894999838, 12.571480751000138],
              [-11.406122206999953, 12.571903228000167],
              [-11.405234335999864, 12.573110580000048],
              [-11.405200958999956, 12.574298858000134],
              [-11.401915549999956, 12.585550308000109],
              [-11.40128517099987, 12.585651397000049],
              [-11.400728224999966, 12.585957527000119],
              [-11.40010738299992, 12.586918831999981],
              [-11.399866103999955, 12.587866784000084],
              [-11.399662971999931, 12.589602469999988],
              [-11.399778364999975, 12.59130096500013],
              [-11.400001525999869, 12.592020988000115],
              [-11.400540350999961, 12.592975616000047],
              [-11.402075767999975, 12.594881057],
              [-11.402865409999947, 12.595688819999964],
              [-11.405218124999919, 12.597925187000044],
              [-11.408813475999978, 12.600561141000185],
              [-11.411986351999929, 12.602639198000077],
              [-11.414259910999931, 12.603292466000084],
              [-11.415821074999883, 12.604145051000046],
              [-11.416469574999894, 12.604538917000127],
              [-11.417092323999896, 12.604777336000041],
              [-11.417954443999918, 12.604908942000122],
              [-11.418235777999939, 12.60565853200012],
              [-11.418496131999973, 12.606804848000138],
              [-11.418892860999961, 12.607929229000149],
              [-11.419128416999968, 12.608909607000101],
              [-11.419225693999863, 12.609775543000126],
              [-11.419206617999976, 12.61115169500016],
              [-11.419327735999957, 12.612349511000161],
              [-11.419316290999973, 12.613163949000011],
              [-11.418548584999883, 12.614621162000049],
              [-11.417708397999888, 12.615324020000116],
              [-11.417015074999938, 12.615982056000064],
              [-11.415792464999925, 12.616516113999978],
              [-11.414720534999958, 12.616606713000124],
              [-11.41221523299987, 12.616846084000144],
              [-11.411496161999878, 12.617066384000054],
              [-11.41050243299992, 12.617930412000135],
              [-11.408435820999898, 12.618850708000082],
              [-11.407804488999886, 12.619316102000028],
              [-11.40639972799994, 12.620926858000075],
              [-11.40487289399988, 12.623090744000081],
              [-11.403733252999928, 12.625388147000024],
              [-11.403286933999937, 12.627684594000016],
              [-11.403278350999926, 12.628311157000098],
              [-11.403509139999926, 12.629533768000101],
              [-11.403495788999976, 12.63048362800015],
              [-11.403348922999896, 12.631746292000173],
              [-11.403295516999947, 12.634903908000126],
              [-11.403397559999917, 12.635521889000017],
              [-11.404473304999954, 12.637115479000101],
              [-11.406137465999961, 12.63794517499997],
              [-11.406881332999887, 12.638111115000015],
              [-11.408501623999939, 12.638645173000157],
              [-11.408324240999889, 12.64051914300012],
              [-11.40828228099997, 12.643095017000121],
              [-11.409061431999874, 12.64657783500013],
              [-11.409137725999869, 12.647874833000174],
              [-11.409351348999962, 12.648535729000173],
              [-11.409451483999931, 12.649296760000141],
              [-11.409569740999927, 12.650488853000127],
              [-11.409794806999969, 12.651194573000055],
              [-11.411623954999982, 12.65325164799998],
              [-11.412447928999939, 12.654926301000103],
              [-11.413421631999938, 12.656288147000168],
              [-11.41447448699995, 12.657751083000051],
              [-11.415568351999923, 12.65941143100008],
              [-11.415643690999957, 12.66007232700008],
              [-11.415865898999868, 12.660754204000114],
              [-11.418370245999938, 12.66363811400015],
              [-11.41948890599997, 12.664834023000083],
              [-11.420172690999948, 12.665248872],
              [-11.420795439999893, 12.665488243000027],
              [-11.422355650999918, 12.665535928000168],
              [-11.422985076999964, 12.665435791000107],
              [-11.425190925999971, 12.664232254000126],
              [-11.427237511999977, 12.663159372000166],
              [-11.428782463999937, 12.662602425000159],
              [-11.430729865999922, 12.662404061000075],
              [-11.432759284999975, 12.662542343000155],
              [-11.434956550999971, 12.66341590900015],
              [-11.436923027999967, 12.663689614000134],
              [-11.437839508999957, 12.663829803000112],
              [-11.439846992999946, 12.663874626000165],
              [-11.440587996999909, 12.66403103000016],
              [-11.441119194999885, 12.664566040000182],
              [-11.441415785999936, 12.665588378000052],
              [-11.441732405999915, 12.666202545000147],
              [-11.442162513999961, 12.666744232000099],
              [-11.444229125999982, 12.670809745000156],
              [-11.444704054999931, 12.671514511],
              [-11.444688796999912, 12.672567368000159],
              [-11.444586754999932, 12.673210145000155],
              [-11.443854332999877, 12.67516136200004],
              [-11.443257331999973, 12.6760082240001],
              [-11.442833899999926, 12.676471711000033],
              [-11.440999031999979, 12.676643371000182],
              [-11.439889906999952, 12.677035332],
              [-11.43803119699993, 12.67811584500015],
              [-11.435084341999982, 12.679942131000075],
              [-11.434383391999972, 12.680745126000033],
              [-11.434060096999872, 12.681279183000072],
              [-11.433729170999868, 12.682343483000182],
              [-11.43340778399994, 12.683662415000072],
              [-11.4332304, 12.68474483599999],
              [-11.433062552999957, 12.6861724850001],
              [-11.433044433999953, 12.686870575000114],
              [-11.432752609999966, 12.687972069000125],
              [-11.432721138999966, 12.690274240000122],
              [-11.432802199999969, 12.691382409000028],
              [-11.432843206999905, 12.691928865000136],
              [-11.433490752999944, 12.695547104000127],
              [-11.433568953999952, 12.696550369000079],
              [-11.433600424999952, 12.696949005000022],
              [-11.434049605999974, 12.698493005000046],
              [-11.434247969999888, 12.699429512000165],
              [-11.434227943999929, 12.70085716300008],
              [-11.43329238799987, 12.701416017000156],
              [-11.432758330999832, 12.7004108430001],
              [-11.43251609899994, 12.699124337000057],
              [-11.431822776999979, 12.697621346000119],
              [-11.431425094999895, 12.696760179000023],
              [-11.430970191999847, 12.696178437000015],
              [-11.430283546999874, 12.695581436000111],
              [-11.429702759999941, 12.694238663000135],
              [-11.429213523999977, 12.693582535000189],
              [-11.42866420699994, 12.693261147000044],
              [-11.426843642999927, 12.693001748000029],
              [-11.426047325999946, 12.693084717000033],
              [-11.423691749999875, 12.693865776000109],
              [-11.421523093999951, 12.695161819000134],
              [-11.420542717999922, 12.696231842000088],
              [-11.42019081199993, 12.696799278000128],
              [-11.419954299999915, 12.698243141000148],
              [-11.419587135999905, 12.699275017000105],
              [-11.419349669999974, 12.70070266800019],
              [-11.419292449999944, 12.703768730000036],
              [-11.419572829999879, 12.705584527000099],
              [-11.420029638999949, 12.707057954000049],
              [-11.420409201999973, 12.707978249000064],
              [-11.420598982999877, 12.70897483900012],
              [-11.420583724999972, 12.710014343000125],
              [-11.420844076999913, 12.71112346700005],
              [-11.420775413999934, 12.716003418000184],
              [-11.420764922999922, 12.716759681000099],
              [-11.420520781999812, 12.717987061000088],
              [-11.420033453999906, 12.719756126000163],
              [-11.420101164999892, 12.721681594000131],
              [-11.420321464999915, 12.722363471000165],
              [-11.420553205999909, 12.723851203000152],
              [-11.42077255199996, 12.724900245000072],
              [-11.420653342999856, 12.726103784000088],
              [-11.419981001999929, 12.726506233000066],
              [-11.419699668999897, 12.726580621000039],
              [-11.419339178999905, 12.726676941000164],
              [-11.418256758999973, 12.727051736000021],
              [-11.417615890999969, 12.7271480550001],
              [-11.416271209999934, 12.72733306900011],
              [-11.414875029999962, 12.726952553000046],
              [-11.413084983999966, 12.726928710000152],
              [-11.412447928999939, 12.726823807000073],
              [-11.41153812399989, 12.726673126000151],
              [-11.410127639999814, 12.726274492000186],
              [-11.407575607999945, 12.726177215000064],
              [-11.404097556999943, 12.726541518999966],
              [-11.402587891999929, 12.726598739999986],
              [-11.401541709999947, 12.72663879400011],
              [-11.400115012999947, 12.726757049000071],
              [-11.397671698999943, 12.726857186000075],
              [-11.396509169999945, 12.727119446000131],
              [-11.393454550999877, 12.727203370000097],
              [-11.39231681799987, 12.726769447000038],
              [-11.391651153999874, 12.726339340000038],
              [-11.390419006999878, 12.726015092000011],
              [-11.388720511999963, 12.725567819000048],
              [-11.386382102999903, 12.72548198700008],
              [-11.382973669999899, 12.725301743999978],
              [-11.381050109999933, 12.725573538999981],
              [-11.379964828999903, 12.725893021000161],
              [-11.37817955, 12.726660730000162],
              [-11.376210212999922, 12.726994515000172],
              [-11.374629973999845, 12.727231979000067],
              [-11.373309134999886, 12.727343559000076],
              [-11.372273445999895, 12.727620125000044],
              [-11.370066642999916, 12.727805138000178],
              [-11.369441985999913, 12.727622032000113],
              [-11.368709563999971, 12.72755527400011],
              [-11.367718695999883, 12.727663994000068],
              [-11.363557814999979, 12.727606774000094],
              [-11.362004279999951, 12.727910041000086],
              [-11.361426352999899, 12.728151322000031],
              [-11.360198020999974, 12.729018211000096],
              [-11.359870909999927, 12.729556083000034],
              [-11.359751701999926, 12.730172157000027],
              [-11.359885215999952, 12.731480598000189],
              [-11.36005687699992, 12.732067108000024],
              [-11.360458374999951, 12.732885361000115],
              [-11.360974311999883, 12.733839036000063],
              [-11.361404418999882, 12.734635354000091],
              [-11.361527442999943, 12.735237122000115],
              [-11.361984253999879, 12.736523629000089],
              [-11.362558365999973, 12.737849236000045],
              [-11.363245010999947, 12.738939285000129],
              [-11.363879202999897, 12.73975181600008],
              [-11.365360259999875, 12.740881921000096],
              [-11.36598777699993, 12.740999222000085],
              [-11.36843204399986, 12.740421294999976],
              [-11.36908245199993, 12.740186691000133],
              [-11.370860099999959, 12.739993095000102],
              [-11.372036932999947, 12.74011421299997],
              [-11.373548507999885, 12.740507126000068],
              [-11.375307082999825, 12.740682602000049],
              [-11.377496718999964, 12.742375374000119],
              [-11.378814697999871, 12.743411065000089],
              [-11.379768371999887, 12.74388408700014],
              [-11.380151747999946, 12.744661331000145],
              [-11.379434584999899, 12.745247841000037],
              [-11.378275869999982, 12.745984078000106],
              [-11.377337455999964, 12.746830940000166],
              [-11.37626647899998, 12.747724534000042],
              [-11.375197410999874, 12.748740197000132],
              [-11.374115944999858, 12.750595094000062],
              [-11.373821258999897, 12.751945495000143],
              [-11.373763083999961, 12.753517151000153],
              [-11.373865127999977, 12.754136085000027],
              [-11.375180244999967, 12.756292343000098],
              [-11.376194, 12.758616447000179],
              [-11.378557205999869, 12.761833191000051],
              [-11.380977630999951, 12.765232087000072],
              [-11.38348579399991, 12.768333434999988],
              [-11.384325980999961, 12.769324304000179],
              [-11.384797095999943, 12.769720078000091],
              [-11.38646221099998, 12.770500184000127],
              [-11.387221336999914, 12.770671845000038],
              [-11.388008117999902, 12.770524025000043],
              [-11.392555236999897, 12.772114755000075],
              [-11.393059729999891, 12.772989273000121],
              [-11.393648147999897, 12.773942948000069],
              [-11.390348433999918, 12.773873330000185],
              [-11.388283728999966, 12.774264335999987],
              [-11.385710715999892, 12.774459840000191],
              [-11.384193419999917, 12.775234223000041],
              [-11.382261276999941, 12.776618958000142],
              [-11.381242751999935, 12.776978493000115],
              [-11.380702971999881, 12.777277947000073],
              [-11.379883765999978, 12.778050423000025],
              [-11.3794679639999, 12.77865028399998],
              [-11.378896713999893, 12.779996872000027],
              [-11.378631591999977, 12.781438827000045],
              [-11.378380774999926, 12.782478333000086],
              [-11.377878188999944, 12.784173012000053],
              [-11.377980230999924, 12.786661149000167],
              [-11.378049849999911, 12.788402558000087],
              [-11.378387449999877, 12.78944396899999],
              [-11.378485679999926, 12.790434838000181],
              [-11.378591536999977, 12.792426109000189],
              [-11.378847121999968, 12.793616295000106],
              [-11.378833770999961, 12.794499397000152],
              [-11.378711699999883, 12.795361519000039],
              [-11.378480911999873, 12.796216964000052],
              [-11.377672194999946, 12.798123361000137],
              [-11.377319334999981, 12.799240113000167],
              [-11.377109527999835, 12.801113129000044],
              [-11.377488135999897, 12.803555488000086],
              [-11.377747534999969, 12.805074692],
              [-11.377951621999955, 12.805650711000169],
              [-11.378489494999883, 12.8066053390001],
              [-11.379341124999826, 12.807457924000062],
              [-11.379710196999895, 12.808112144000177],
              [-11.380117415999905, 12.808605195000041],
              [-11.381721496999944, 12.809774399000105],
              [-11.382008552999878, 12.810662269000034],
              [-11.383417128999895, 12.813108444000022],
              [-11.384032249999905, 12.813912391000031],
              [-11.382554052999922, 12.817039490000013],
              [-11.381602287999954, 12.81897068000012],
              [-11.380957602999899, 12.819951059000175],
              [-11.380315780999979, 12.821734428000184],
              [-11.379757881999922, 12.823674203],
              [-11.379250525999964, 12.824754714000164],
              [-11.378650664999896, 12.826164245000143],
              [-11.378279684999939, 12.827474594000137],
              [-11.378072737999958, 12.829083442000183],
              [-11.37804317399997, 12.830481530000043],
              [-11.378145218999975, 12.831099511000104],
              [-11.378457068999865, 12.831646919000093],
              [-11.378940580999881, 12.832057954000106],
              [-11.381612778999909, 12.83286952899999],
              [-11.382722853999951, 12.83251857800019],
              [-11.383205413999974, 12.832928657000139],
              [-11.383802413999888, 12.83315086500005],
              [-11.384947777999912, 12.833003045000055],
              [-11.38633632799997, 12.832345962000147],
              [-11.386929512999927, 12.831898689000184],
              [-11.387524605999943, 12.8309993740001],
              [-11.388166427999863, 12.829794885000183],
              [-11.3888521199999, 12.829243660000145],
              [-11.389750479999918, 12.828633308000065],
              [-11.390995978999854, 12.828393937000101],
              [-11.392114638999942, 12.828287125000145],
              [-11.394865034999953, 12.82845401700007],
              [-11.39757442399997, 12.82803154000004],
              [-11.398641586999872, 12.82774448400005],
              [-11.39879703399987, 12.82865238200003],
              [-11.399178504999895, 12.83038425500007],
              [-11.399272918999884, 12.831843376000108],
              [-11.398834227999942, 12.83320903800012],
              [-11.398424148999936, 12.834113120000154],
              [-11.398026465999976, 12.835746766000113],
              [-11.397758482999905, 12.836619377000147],
              [-11.397659300999976, 12.838603973000033],
              [-11.397761344999878, 12.839222909000057],
              [-11.399070739999956, 12.841368675000183],
              [-11.399687765999943, 12.84263515400005],
              [-11.401006697999833, 12.843596459000082],
              [-11.403693198999974, 12.844305038000186],
              [-11.404281615999878, 12.844646454000042],
              [-11.40493011399991, 12.845206260000168],
              [-11.405685424999945, 12.84665489300005],
              [-11.405604362999838, 12.847297668000181],
              [-11.400510786999916, 12.850124360000109],
              [-11.398298262999901, 12.850856782000164],
              [-11.396498678999933, 12.851761817000181],
              [-11.394918441999948, 12.853039742000135],
              [-11.394104003999871, 12.853687287000128],
              [-11.393681526999899, 12.854166032000023],
              [-11.393242835999956, 12.85499000599998],
              [-11.392584800999941, 12.855992318000176],
              [-11.391970633999961, 12.857138634000137],
              [-11.391311645999963, 12.858140946000105],
              [-11.390715597999929, 12.859538079000174],
              [-11.390437124999892, 12.861643792000052],
              [-11.390530586999887, 12.862412453000161],
              [-11.390990257999931, 12.86352062300017],
              [-11.391724585999896, 12.865291596000077],
              [-11.391773224999952, 12.865408897000066],
              [-11.393148421999967, 12.866807937000033],
              [-11.393786429999977, 12.867667198000163],
              [-11.394205093999972, 12.86851692200014],
              [-11.394919394999931, 12.870537757000079],
              [-11.39523124699997, 12.871087075000048],
              [-11.395714759999919, 12.871497154000167],
              [-11.398621558999935, 12.872945785000184],
              [-11.399622917999977, 12.874325752000061],
              [-11.399762153999973, 12.874983788000179],
              [-11.399752616999876, 12.875597001000187],
              [-11.39987182699997, 12.876917839000043],
              [-11.399861335999901, 12.877652168000111],
              [-11.39974880099993, 12.878444672000171],
              [-11.399521827999877, 12.879084587000079],
              [-11.398841858999958, 12.882892608000134],
              [-11.398826597999914, 12.883059501000162],
              [-11.398677825999869, 12.884704591000059],
              [-11.398466110999948, 12.885298729000169],
              [-11.398160933999918, 12.886628151000025],
              [-11.397139548999974, 12.888564109000185],
              [-11.396761893999951, 12.889986038000131],
              [-11.395721434999928, 12.891374588000076],
              [-11.394974707999893, 12.891984939000054],
              [-11.392528532999904, 12.893667221000157],
              [-11.39205074299997, 12.894147873000122],
              [-11.390994070999966, 12.895711899000048],
              [-11.39006328499994, 12.896302224000124],
              [-11.387621879999926, 12.898075104999975],
              [-11.385282515999961, 12.899648666000076],
              [-11.384741782999924, 12.900213242000063],
              [-11.383805273999883, 12.902142525000102],
              [-11.38368797399994, 12.904951097000151],
              [-11.383641243999875, 12.906083107000029],
              [-11.383598326999902, 12.90727138599999],
              [-11.383543968999845, 12.908778191000067],
              [-11.383645056999967, 12.909435272000167],
              [-11.383956908999892, 12.909982682000134],
              [-11.384440421999955, 12.910392762000185],
              [-11.389511108999898, 12.911985397000024],
              [-11.392927168999961, 12.912475587000074],
              [-11.394392012999901, 12.912978173000113],
              [-11.396550178999973, 12.913747787000034],
              [-11.397136688999979, 12.915529250000077],
              [-11.39753437, 12.917318344000137],
              [-11.397099494999907, 12.918303490000085],
              [-11.396931648999896, 12.920015335000073],
              [-11.396718023999881, 12.920614243000102],
              [-11.39634132399982, 12.922654152],
              [-11.396212577999904, 12.923353194000128],
              [-11.395953177999957, 12.924198151000155],
              [-11.395499229999928, 12.925875665000035],
              [-11.395279883999933, 12.92677784000017],
              [-11.394798278999929, 12.927420617000109],
              [-11.394589423999889, 12.927718164000169],
              [-11.39402675599996, 12.928520204000051],
              [-11.393170355999928, 12.928735734000156],
              [-11.392466544999934, 12.92908477800006],
              [-11.391540526999961, 12.929516793],
              [-11.390939712999909, 12.929698944000108],
              [-11.390668867999977, 12.927834512000175],
              [-11.390552519999915, 12.926351547000138],
              [-11.390307425999879, 12.925531387000149],
              [-11.389943121999977, 12.924311639000109],
              [-11.38961505899988, 12.923717499000134],
              [-11.38913154599993, 12.923307418999968],
              [-11.386795043999882, 12.922561645000087],
              [-11.385464668999873, 12.92266368899999],
              [-11.38247966699987, 12.924180031000049],
              [-11.381471634999969, 12.925141335000035],
              [-11.378885268999852, 12.926200868000024],
              [-11.377398490999951, 12.926293374000011],
              [-11.375195502999929, 12.926878929000054],
              [-11.374175072999947, 12.927280425999982],
              [-11.372160910999924, 12.927743912000153],
              [-11.37076473299993, 12.927972792999981],
              [-11.367059706999896, 12.928310394000107],
              [-11.365225790999943, 12.928284645000019],
              [-11.362770080999894, 12.928389550000077],
              [-11.359504699999889, 12.928911210000081],
              [-11.356846808999876, 12.929587364000099],
              [-11.355226516999949, 12.930383683000173],
              [-11.354497908999974, 12.930728912000063],
              [-11.353181838999888, 12.93122387000011],
              [-11.352099418999956, 12.931834222000191],
              [-11.347912787999917, 12.932395935000045],
              [-11.347265243999971, 12.932607651000126],
              [-11.34677028599998, 12.933004379000124],
              [-11.346184730999937, 12.933818816999974],
              [-11.345476150999957, 12.934494972000095],
              [-11.345094679999818, 12.935018541000147],
              [-11.344382286999917, 12.93649864300005],
              [-11.34390354199985, 12.937687874000119],
              [-11.343452452999827, 12.939310074000048],
              [-11.342520713999932, 12.941237450000187],
              [-11.342470169999899, 12.942769051000141],
              [-11.342602730999943, 12.944077493000066],
              [-11.342825888999926, 12.94476032200015],
              [-11.344306945999904, 12.94735527099999],
              [-11.346357344999944, 12.950140954],
              [-11.347224234999885, 12.9519023900001],
              [-11.348392486999899, 12.953701974000126],
              [-11.349179266999897, 12.955029488000037],
              [-11.350327492999952, 12.956638336000083],
              [-11.350814818999879, 12.957377434000136],
              [-11.350832938999872, 12.958093644000087],
              [-11.351949691999891, 12.960649490000151],
              [-11.351919174999864, 12.960844994000183],
              [-11.351732253999955, 12.96200561500018],
              [-11.351269721999927, 12.965134622000164],
              [-11.351231574999815, 12.966657639000175],
              [-11.350984573999881, 12.967679979000025],
              [-11.350864410999975, 12.972944260000077],
              [-11.351126669999928, 12.974802970000042],
              [-11.351238250999927, 12.976226806999989],
              [-11.351511955999911, 12.977879524000059],
              [-11.353128433999927, 12.981472015],
              [-11.355286597999964, 12.985306740000169],
              [-11.358420371999955, 12.988193513000169],
              [-11.3588209159999, 12.988882065000098],
              [-11.359367370999905, 12.990118028000154],
              [-11.359822272999963, 12.990699768000127],
              [-11.362631796999892, 12.992831230000093],
              [-11.363285064999957, 12.993147850000014],
              [-11.36548614599991, 12.993720054000107],
              [-11.369993209999961, 12.994482040000094],
              [-11.37295818299998, 12.995187760000078],
              [-11.375342369999885, 12.995523453000033],
              [-11.379014969999901, 12.995853424000074],
              [-11.381353378999961, 12.995602607000023],
              [-11.382016182999905, 12.995388032000108],
              [-11.38324165299997, 12.994305611000186],
              [-11.384100912999941, 12.993116378000082],
              [-11.384221075999903, 12.992501259000107],
              [-11.384160040999916, 12.990169525000056],
              [-11.384091377999937, 12.989956855000059],
              [-11.383675575999973, 12.988672257000189],
              [-11.383570670999916, 12.987998008999966],
              [-11.382263183999896, 12.984665870000072],
              [-11.382277487999943, 12.983673096000189],
              [-11.382175444999859, 12.983055114000024],
              [-11.381726263999894, 12.982229233000169],
              [-11.381217002999961, 12.980657577000159],
              [-11.38125228899986, 12.978242874000102],
              [-11.381126403999929, 12.977386474000127],
              [-11.38116836599994, 12.974507331000041],
              [-11.381476401999919, 12.973735810000051],
              [-11.382275581999977, 12.97137737200012],
              [-11.38268375399997, 12.970811843000149],
              [-11.383110046999889, 12.970005034999986],
              [-11.383689880999839, 12.969122887000026],
              [-11.385582924999881, 12.968227387000184],
              [-11.387048720999928, 12.967889786000057],
              [-11.388111113999969, 12.967564583000069],
              [-11.38831806099995, 12.968141556000091],
              [-11.388708113999826, 12.968587876000072],
              [-11.390548704999958, 12.970692635000034],
              [-11.392818450999926, 12.972538949000068],
              [-11.393124580999938, 12.973069191000036],
              [-11.395258902999956, 12.974642753000012],
              [-11.395834922999938, 12.97491073499998],
              [-11.398406982999916, 12.975613593000048],
              [-11.401113509999959, 12.976397515000087],
              [-11.40423107099997, 12.976197243000058],
              [-11.40478897, 12.975891114000035],
              [-11.405206679999878, 12.975416183999982],
              [-11.405433654999911, 12.974830627000131],
              [-11.405587196999932, 12.974003793000122],
              [-11.405803679999963, 12.973367691000078],
              [-11.406511305999913, 12.972159385999987],
              [-11.406779287999882, 12.971499443000027],
              [-11.407440185999917, 12.971437455000057],
              [-11.407479285999955, 12.972167969000111],
              [-11.407463072999974, 12.973283769000034],
              [-11.407093047999979, 12.974138259000028],
              [-11.406601904999945, 12.975278855000113],
              [-11.405893326999944, 12.975955009000074],
              [-11.40518760599997, 12.977003098000012],
              [-11.404454231999978, 12.977601051000022],
              [-11.402928352999936, 12.979552270000113],
              [-11.402596474999939, 12.980615615000033],
              [-11.402529716999879, 12.981334686000139],
              [-11.402774810999915, 12.983504295000159],
              [-11.402997015999915, 12.984187127000155],
              [-11.40408420499989, 12.985626220000142],
              [-11.407028197999978, 12.987936973000103],
              [-11.410407065999948, 12.99160766500006],
              [-11.411355017999938, 12.992804528000022],
              [-11.411895752999897, 12.99379062700018],
              [-11.412662505999947, 12.995806694000123],
              [-11.412075995999885, 12.998168945000089],
              [-11.411890029999881, 12.999219894000078],
              [-11.410796165999955, 13.000255585000048],
              [-11.410061836999944, 13.000691413000084],
              [-11.409243583999853, 13.000072478999982],
              [-11.40884494799991, 12.999567986000102],
              [-11.408333778999975, 12.999125480000089],
              [-11.407736777999958, 12.998902322000106],
              [-11.406609534999973, 12.998746872000027],
              [-11.405299185999979, 12.998470307000161],
              [-11.404618263999964, 12.998453141000141],
              [-11.404014585999903, 12.998659133000103],
              [-11.402490614999863, 12.999668123000106],
              [-11.401945113999943, 13.000236512000185],
              [-11.401219367999943, 13.000294685000085],
              [-11.400540350999961, 13.000021934000188],
              [-11.399901390999958, 13.000012399000184],
              [-11.399297714999932, 13.000218390999976],
              [-11.398567200999935, 13.000678063],
              [-11.395472525999878, 13.001641273000132],
              [-11.392827033999936, 13.003240585000128],
              [-11.39250564699995, 13.003762245000132],
              [-11.392075538999904, 13.004838943000038],
              [-11.39194965299987, 13.005471230000012],
              [-11.391931534999969, 13.006690025000069],
              [-11.39243221299995, 13.01125335800009],
              [-11.393054007999979, 13.013144494000073],
              [-11.393365859999903, 13.013691902000062],
              [-11.39683342, 13.016781807000029],
              [-11.402261733999921, 13.019445420000011],
              [-11.40474033299995, 13.01988697000013],
              [-11.408195494999973, 13.020478248000074],
              [-11.410614967999891, 13.020792960000165],
              [-11.411588668999912, 13.020818711000061],
              [-11.410326003999955, 13.022348402999967],
              [-11.409099578999928, 13.024622917000045],
              [-11.408959387999971, 13.025778771000148],
              [-11.408547400999964, 13.026557923000098],
              [-11.407354354999939, 13.029221535000033],
              [-11.407079696999972, 13.030433654999968],
              [-11.406773567999949, 13.032212258000129],
              [-11.406726837999884, 13.033975601000122],
              [-11.406828880999967, 13.034593582000184],
              [-11.407342909999954, 13.035558700000081],
              [-11.407545088999939, 13.036746978],
              [-11.407753943999978, 13.037329674999967],
              [-11.40950489099987, 13.039233207000109],
              [-11.411451339999928, 13.039844513000048],
              [-11.41557216599989, 13.040226936000181],
              [-11.418111801999885, 13.042917252],
              [-11.418673515999899, 13.043596269000147],
              [-11.419260977999954, 13.044548988000031],
              [-11.42166328299993, 13.04887771600005],
              [-11.422794340999815, 13.050114632000088],
              [-11.4248523699999, 13.05299186700006],
              [-11.4277067189999, 13.055069924000122],
              [-11.42894363399995, 13.05549335600017],
              [-11.430029869999942, 13.05575561600017],
              [-11.43052864099991, 13.056240081000055],
              [-11.431580542999882, 13.05722141200016],
              [-11.432058334999908, 13.058691026000076],
              [-11.432031631999905, 13.060550691000174],
              [-11.431598663999978, 13.061524391000091],
              [-11.429488181999886, 13.064162255000042],
              [-11.428756712999927, 13.064974785000118],
              [-11.427304267999943, 13.066232682000191],
              [-11.424317359999975, 13.069622041000059],
              [-11.42354011599997, 13.070648194000114],
              [-11.422709464999969, 13.071702958],
              [-11.421963691999906, 13.073051454000051],
              [-11.421526908999908, 13.074498177000123],
              [-11.42147636399983, 13.075159073000066],
              [-11.42158603699994, 13.076132774000087],
              [-11.421566963999965, 13.077466011000183],
              [-11.421013832999904, 13.079990387000123],
              [-11.42099666699994, 13.080658913000036],
              [-11.421323776999884, 13.081732751000061],
              [-11.42378425499993, 13.085923195000021],
              [-11.424608230999979, 13.086953162999976],
              [-11.42513942599993, 13.087623597000061],
              [-11.427058220999982, 13.08925914800011],
              [-11.429211616999908, 13.090217591000055],
              [-11.430815696999957, 13.091032029000132],
              [-11.431803702999957, 13.09121227300011],
              [-11.431864738999877, 13.091223718000094],
              [-11.432129860999964, 13.092144013000109],
              [-11.432517051999923, 13.093276977000073],
              [-11.433059691999972, 13.093961715999967],
              [-11.434480665999956, 13.095109940000157],
              [-11.436942100999943, 13.096224785000118],
              [-11.437314986999979, 13.096828461000143],
              [-11.43788433099985, 13.097693444000186],
              [-11.438654898999914, 13.098520280000059],
              [-11.439321517999929, 13.098950385000023],
              [-11.441613197999914, 13.099555016000011],
              [-11.443035124999881, 13.100084306000156],
              [-11.443662643999915, 13.100201606000041],
              [-11.447887419999972, 13.100016595000113],
              [-11.449107168999944, 13.099599838000188],
              [-11.450716018999913, 13.09962177400007],
              [-11.451966285999958, 13.099514009000131],
              [-11.45700931499988, 13.100104332],
              [-11.459895134999897, 13.099872589000086],
              [-11.463570594999908, 13.09937095700019],
              [-11.464642524999874, 13.099158286999966],
              [-11.466964720999897, 13.09942913000009],
              [-11.467903136999894, 13.099452019000125],
              [-11.470669746999931, 13.099737167000114],
              [-11.471667288999981, 13.100045204000082],
              [-11.472321509999915, 13.100422859000162],
              [-11.472893713999838, 13.100884438000094],
              [-11.47343921699985, 13.101299285000039],
              [-11.473286629999905, 13.101924897000117],
              [-11.473144530999889, 13.102593422999973],
              [-11.473413467999933, 13.104631424000104],
              [-11.473629950999907, 13.105262756000116],
              [-11.474034309999979, 13.105749131000152],
              [-11.474344253999959, 13.106309890000091],
              [-11.47452068299998, 13.106940270000052],
              [-11.47484397799991, 13.107597351000152],
              [-11.475393295999879, 13.109393120000107],
              [-11.476364135999916, 13.110988617000032],
              [-11.476696966999839, 13.112013817000161],
              [-11.476998328999969, 13.112575531000061],
              [-11.477464675999954, 13.113162994000106],
              [-11.477807043999917, 13.113740921000044],
              [-11.480436324999971, 13.116329194000059],
              [-11.483163832999935, 13.117866516000106],
              [-11.485674857999982, 13.118705751000164],
              [-11.487203596999962, 13.119137764000129],
              [-11.487979887999927, 13.119403839000029],
              [-11.490753172999973, 13.120070457000111],
              [-11.491236685999979, 13.120475769000052],
              [-11.492113113999949, 13.121183395000173],
              [-11.492375373999892, 13.122573852000187],
              [-11.492800711999905, 13.123582839999983],
              [-11.492883682999945, 13.124214172],
              [-11.492974281999977, 13.125127792000114],
              [-11.492845535999891, 13.12588214900012],
              [-11.492831229999922, 13.12689590500014],
              [-11.492673872999887, 13.128583908999985],
              [-11.492650031999972, 13.129940987],
              [-11.492752074999885, 13.130559921000042],
              [-11.493368147999945, 13.131952286000057],
              [-11.49345874799991, 13.13264465400016],
              [-11.493678092999914, 13.133296012000073],
              [-11.494919775999904, 13.13498783100016],
              [-11.495384214999888, 13.135931968000023],
              [-11.495964049999884, 13.136727333000181],
              [-11.496116636999886, 13.138790132000054],
              [-11.496508597999934, 13.141771317000064],
              [-11.496843337999906, 13.142891884000164],
              [-11.498084068999958, 13.14481449200008],
              [-11.500405310999952, 13.146741867000117],
              [-11.500942230999954, 13.147052764000023],
              [-11.504183769999941, 13.147702217000187],
              [-11.505060195999874, 13.14795970900019],
              [-11.508063316999937, 13.148202896000157],
              [-11.509525297999915, 13.148341179000113],
              [-11.512065886999892, 13.149049760000025],
              [-11.512347221999903, 13.150627137000072],
              [-11.512314795999885, 13.151717185000052],
              [-11.512710571999946, 13.153076172000112],
              [-11.513054847999911, 13.15372180900016],
              [-11.514452933999962, 13.155008317000011],
              [-11.514660835999848, 13.15624046400012],
              [-11.514739989999896, 13.156987190000052],
              [-11.514482497999836, 13.157634735000102],
              [-11.514146803999949, 13.15866375100012],
              [-11.513077736999946, 13.159242630000165],
              [-11.511542319999933, 13.160487176000117],
              [-11.509530067999947, 13.161891937000064],
              [-11.508115768999915, 13.163347245000068],
              [-11.507754324999951, 13.163924216999987],
              [-11.507634161999931, 13.16454029099998],
              [-11.507736205999947, 13.165158272000042],
              [-11.506640434999952, 13.165224076000186],
              [-11.506010055999866, 13.165323258000058],
              [-11.504262923999875, 13.166090011000165],
              [-11.502991676999955, 13.167712211999969],
              [-11.502340315999959, 13.168457985000146],
              [-11.50155067399993, 13.169501306000029],
              [-11.500697135999928, 13.170660973000167],
              [-11.499211311999886, 13.171839713000168],
              [-11.498788833999924, 13.172318458000063],
              [-11.498562811999875, 13.172895432000018],
              [-11.498310089999961, 13.174160957000026],
              [-11.497763633999853, 13.175294877000056],
              [-11.496050833999959, 13.177820206000035],
              [-11.495244978999892, 13.180620194000028],
              [-11.495109556999978, 13.190096856000082],
              [-11.495384214999888, 13.19202232400005],
              [-11.495596884999884, 13.192631722000044],
              [-11.496259688999942, 13.193705558000033],
              [-11.496758461999889, 13.194719314999986],
              [-11.498023985999964, 13.196665764000045],
              [-11.49908638099987, 13.197098732000143],
              [-11.500773429999924, 13.197651862999976],
              [-11.501323700999876, 13.197972298000138],
              [-11.503772735999917, 13.198718069999984],
              [-11.508565902999862, 13.199513435000028],
              [-11.51132488199994, 13.199702264000109],
              [-11.514041899999881, 13.200006485000131],
              [-11.517093657999965, 13.200319290000152],
              [-11.517843246999973, 13.200337411000135],
              [-11.522426604999964, 13.20092964100013],
              [-11.525632857999938, 13.200847625000108],
              [-11.528343199999938, 13.200323105000052],
              [-11.53073501699987, 13.20009517699998],
              [-11.531517027999939, 13.199946403000126],
              [-11.533404348999909, 13.201234818000046],
              [-11.534583091999934, 13.202342033000093],
              [-11.535307883999963, 13.203193665000015],
              [-11.536074638999878, 13.203973771000108],
              [-11.535820959999967, 13.205996514000049],
              [-11.536030769999911, 13.206587791],
              [-11.536856650999937, 13.207979202000161],
              [-11.537738799999943, 13.208946229000105],
              [-11.538259506999907, 13.210300445000087],
              [-11.538470268999959, 13.211651801000187],
              [-11.538311003999922, 13.214368821000107],
              [-11.537645339999926, 13.216599465000058],
              [-11.537479399999938, 13.217331887000114],
              [-11.53721141799997, 13.218204498000091],
              [-11.537040709999872, 13.219277381000154],
              [-11.53700256299993, 13.2195205700001],
              [-11.536859510999932, 13.220294953000121],
              [-11.536674499999947, 13.222210884000106],
              [-11.536617279999916, 13.225870133000114],
              [-11.536764143999903, 13.226838113000099],
              [-11.536880491999966, 13.227857590000156],
              [-11.537035941999932, 13.229731561000051],
              [-11.535595893999812, 13.231898307999984],
              [-11.532661436999945, 13.23588466700005],
              [-11.531791685999906, 13.237125397000057],
              [-11.531054496999843, 13.237757682000051],
              [-11.53010177699997, 13.237977982000132],
              [-11.529358864999949, 13.23833560900016],
              [-11.528941154999927, 13.238810539000042],
              [-11.528074264999816, 13.240242958000124],
              [-11.527166366999836, 13.241784096000117],
              [-11.526580809999871, 13.24245262200003],
              [-11.525342940999963, 13.24482250300008],
              [-11.523659704999943, 13.247879028999989],
              [-11.522995948999892, 13.247411727000099],
              [-11.522500037999919, 13.24780941],
              [-11.521488190999946, 13.249617578000084],
              [-11.520264625999971, 13.251130103999969],
              [-11.519790648999901, 13.251583100000175],
              [-11.518921852999881, 13.252899169000045],
              [-11.518337250999934, 13.254434586],
              [-11.518109321999873, 13.256283760000088],
              [-11.518493652999894, 13.258455277000053],
              [-11.518700599999875, 13.259028434000129],
              [-11.519544601999883, 13.260091781000085],
              [-11.520076751999966, 13.26076221500017],
              [-11.520587920999958, 13.261204721000183],
              [-11.521743774999948, 13.261722565000014],
              [-11.523788450999916, 13.26255607600018],
              [-11.527711867999926, 13.264478684000096],
              [-11.529541015999939, 13.265026092000085],
              [-11.530421256999944, 13.265101432999984],
              [-11.533312797999884, 13.26845645800006],
              [-11.534171104999871, 13.269943238000167],
              [-11.537036894999915, 13.273587226000188],
              [-11.537548065999943, 13.274029732000031],
              [-11.538626670999918, 13.274411202000181],
              [-11.538182257999949, 13.27547931700019],
              [-11.538140295999938, 13.2761526110001],
              [-11.537798881999834, 13.276719093000054],
              [-11.537576674999912, 13.277297975000181],
              [-11.53730392499989, 13.278772354000068],
              [-11.536789892999934, 13.280097961000024],
              [-11.536425590999954, 13.28153801100018],
              [-11.53639984199998, 13.28224277400011],
              [-11.536842345999901, 13.283807755000055],
              [-11.538081168999895, 13.286097527000152],
              [-11.540007590999949, 13.28812885300016],
              [-11.543109893999883, 13.29083442700005],
              [-11.543636320999894, 13.291155814000035],
              [-11.547485351999967, 13.292696954],
              [-11.551651953999908, 13.293258668000135],
              [-11.557165144999942, 13.292123795000123],
              [-11.558484076999889, 13.292023660000098],
              [-11.559761046999938, 13.292040826000118],
              [-11.560613631999956, 13.292332649000173],
              [-11.562561034999874, 13.293262483000035],
              [-11.564108847999876, 13.294820786000059],
              [-11.566278456999896, 13.296767235000118],
              [-11.566493986999888, 13.297698021000031],
              [-11.566767692999974, 13.298286438000105],
              [-11.568122863999918, 13.300200463000124],
              [-11.57039165499998, 13.302876473000026],
              [-11.570683478999968, 13.304092408000031],
              [-11.571221350999963, 13.3052816390001],
              [-11.571677207999926, 13.305863381000052],
              [-11.574825287999886, 13.308251380000058],
              [-11.575756073999912, 13.308445932000154],
              [-11.57695102699995, 13.308814049000148],
              [-11.577603339999882, 13.309014321],
              [-11.578726767999967, 13.30930614499999],
              [-11.58155727299993, 13.309531213000128],
              [-11.582425116999957, 13.30954551799999],
              [-11.583248137999931, 13.309736252000164],
              [-11.584752082999955, 13.310497283000132],
              [-11.585263251999947, 13.31095504699999],
              [-11.585324286999935, 13.311734200999979],
              [-11.585553169999969, 13.312476158000038],
              [-11.585530281999922, 13.313258172000076],
              [-11.583885191999968, 13.315733911000166],
              [-11.583367347999967, 13.316695213000116],
              [-11.58293056499997, 13.318141937000121],
              [-11.582719802999918, 13.319903374000091],
              [-11.582679747999975, 13.322233200000142],
              [-11.582782745999964, 13.322852135000119],
              [-11.58332920099997, 13.324248315000148],
              [-11.584001539999917, 13.325817108000024],
              [-11.584545135999974, 13.326501845999985],
              [-11.586493491999875, 13.328206063000039],
              [-11.589115141999912, 13.33096408900019],
              [-11.589635848999933, 13.333056450000186],
              [-11.590615272999969, 13.335268974000144],
              [-11.59082222, 13.336130142000115],
              [-11.591068268999948, 13.337565422000068],
              [-11.59145164399996, 13.339046478000057],
              [-11.591549873999895, 13.340567589000045],
              [-11.591468809999981, 13.346294403000115],
              [-11.591223715999945, 13.347520828000086],
              [-11.590820311999948, 13.348772049000104],
              [-11.59060573499994, 13.34998130800011],
              [-11.590483665999898, 13.357398986000078],
              [-11.590210913999954, 13.358872415000064],
              [-11.590163230999963, 13.360522270000047],
              [-11.590279578999969, 13.362423896],
              [-11.590760231999866, 13.364081384000031],
              [-11.591444015999969, 13.365363121000087],
              [-11.592256546999863, 13.366182327000047],
              [-11.592618941999888, 13.366915704000064],
              [-11.593074797999975, 13.367497444000037],
              [-11.593625068999927, 13.367818832000182],
              [-11.596012115999827, 13.368447304000142],
              [-11.59784221599989, 13.368525505000093],
              [-11.598473547999902, 13.368426323000051],
              [-11.602121353999905, 13.36691761000003],
              [-11.603038786999889, 13.366153716000042],
              [-11.604331015999946, 13.365591050000035],
              [-11.605827330999944, 13.365362167000058],
              [-11.607079505999934, 13.364837647000172],
              [-11.607916830999898, 13.364377023000031],
              [-11.60868835499997, 13.364011765000043],
              [-11.609720229999937, 13.363254547999986],
              [-11.611864090999916, 13.362714767],
              [-11.613368033999961, 13.362291335000123],
              [-11.615482329999907, 13.362756729000125],
              [-11.619585037999968, 13.363766671000178],
              [-11.620315551999965, 13.363945960000024],
              [-11.621905325999933, 13.363847733000114],
              [-11.626252174999877, 13.36455249800008],
              [-11.632730483999978, 13.36465930900016],
              [-11.63371944499994, 13.365688324000132],
              [-11.632178307999936, 13.367940902000157],
              [-11.631487845999914, 13.368410112000049],
              [-11.624793051999973, 13.374439239000026],
              [-11.62403392799996, 13.376118659999975],
              [-11.623143196999877, 13.377449990000173],
              [-11.622024536999959, 13.379042625000068],
              [-11.621733665999955, 13.379727364000132],
              [-11.621407507999947, 13.382828712000048],
              [-11.620730400999889, 13.385097504000044],
              [-11.620660782999892, 13.385743141000091],
              [-11.620772360999979, 13.38715267200007],
              [-11.620720862999917, 13.390861511000082],
              [-11.62082290599983, 13.39148044600006],
              [-11.621472358999938, 13.392558098000052],
              [-11.621947287999944, 13.393643379000025],
              [-11.622730254999908, 13.394725800000174],
              [-11.625011443999824, 13.396450996000056],
              [-11.625758171999962, 13.396770478000064],
              [-11.627431869999896, 13.397066117000065],
              [-11.630700110999953, 13.397134780000158],
              [-11.632281302999957, 13.397284507000052],
              [-11.634647368999879, 13.397322654000106],
              [-11.637374878999935, 13.397221565000166],
              [-11.639415741999869, 13.397115707000125],
              [-11.642673491999972, 13.397158623999985],
              [-11.645809173999965, 13.396623611000052],
              [-11.64888382, 13.395306587000164],
              [-11.649873733999982, 13.394776344999968],
              [-11.655754089999903, 13.392563821000067],
              [-11.657139777999816, 13.39246845200006],
              [-11.660489081999913, 13.391479493000077],
              [-11.661634444999891, 13.390956878000054],
              [-11.663155556999982, 13.390659332000155],
              [-11.664342879999936, 13.390674590000174],
              [-11.665911673999972, 13.390550614],
              [-11.667929649999962, 13.390045166000107],
              [-11.669267652999906, 13.390062332000127],
              [-11.671470641999917, 13.39057254800008],
              [-11.674834251999869, 13.391674995000074],
              [-11.678627966999954, 13.392205239000077],
              [-11.683826445999955, 13.39228534800003],
              [-11.684457779999946, 13.392185211000026],
              [-11.685017585999901, 13.391879082000173],
              [-11.685688972999969, 13.391168595000067],
              [-11.686561583999946, 13.390967369000123],
              [-11.687994002999972, 13.390986442000042],
              [-11.689370154999892, 13.390906334000135],
              [-11.689881323999941, 13.391254425000056],
              [-11.68989944499998, 13.391956329999971],
              [-11.69031238499997, 13.393061637000017],
              [-11.690404890999901, 13.393980027000168],
              [-11.690492629999937, 13.395469665000178],
              [-11.689618111999891, 13.39781379700014],
              [-11.689506530999893, 13.398409843000081],
              [-11.689485549999858, 13.399899482000137],
              [-11.689600943999892, 13.401925087000109],
              [-11.689908980999974, 13.403322220000177],
              [-11.69019794399992, 13.403949737000119],
              [-11.692312240999968, 13.406877517000112],
              [-11.692794800999934, 13.407730102000187],
              [-11.693595885999969, 13.40921401900016],
              [-11.694022177999955, 13.409739494000178],
              [-11.696133613999962, 13.411170960000163],
              [-11.699728965999896, 13.414384841000071],
              [-11.703651428999819, 13.416355133000025],
              [-11.706644056999892, 13.416554450000092],
              [-11.709568976999947, 13.416217804000098],
              [-11.712272643999938, 13.416855812000108],
              [-11.715553283999952, 13.417206764000014],
              [-11.721534729999973, 13.417694091000101],
              [-11.724186896999925, 13.41786670700003],
              [-11.726531028999887, 13.418030739000073],
              [-11.728299141999912, 13.418057440999974],
              [-11.728931426999907, 13.417957306000119],
              [-11.730222701999935, 13.417359353000165],
              [-11.731142044999956, 13.417270661000089],
              [-11.733781813999883, 13.416883468000094],
              [-11.73531341599994, 13.416226387],
              [-11.73585891599987, 13.415865898000106],
              [-11.736314772999947, 13.41543102300011],
              [-11.737252235999961, 13.415038109000079],
              [-11.739130973999977, 13.413958549000142],
              [-11.740075110999953, 13.413625718000048],
              [-11.742386816999897, 13.412474632],
              [-11.742937087999962, 13.412048339000023],
              [-11.744090079999978, 13.410676957000021],
              [-11.744812010999908, 13.409633636000137],
              [-11.745271681999952, 13.408212662000153],
              [-11.745694159999971, 13.40702533700005],
              [-11.746101378999924, 13.40575695199999],
              [-11.746112823999965, 13.405722618000027],
              [-11.746498106999979, 13.404872895000153],
              [-11.746657371999902, 13.404164315000116],
              [-11.746699333999914, 13.401049614000101],
              [-11.746582983999815, 13.39902305600009],
              [-11.746459007999931, 13.397616388000074],
              [-11.746113776999891, 13.396408081000175],
              [-11.74569129899993, 13.395256042000142],
              [-11.744904517999942, 13.393783571000085],
              [-11.744099616999961, 13.392874718000144],
              [-11.743670463999877, 13.392094612000051],
              [-11.743378638999957, 13.390778542000078],
              [-11.743404386999885, 13.388839722000057],
              [-11.743988989999934, 13.38831997],
              [-11.74456119599995, 13.388030051000101],
              [-11.745151518999933, 13.387815476000014],
              [-11.745720862999974, 13.387491226],
              [-11.746782302999975, 13.386994363000042],
              [-11.747498511999879, 13.386161804000153],
              [-11.748294830999953, 13.384962082000186],
              [-11.748556135999934, 13.384322167000107],
              [-11.748728751999977, 13.382990837000079],
              [-11.748764037999877, 13.381229400000052],
              [-11.74891853299988, 13.380586625000092],
              [-11.749520300999905, 13.379821777000075],
              [-11.750993727999969, 13.377337455000031],
              [-11.752152441999954, 13.375988007000103],
              [-11.752742766999916, 13.374965669000062],
              [-11.753649711999913, 13.373732566000115],
              [-11.754296302999933, 13.372646333000034],
              [-11.754920958999946, 13.372136116000149],
              [-11.758308410999973, 13.369984628000111],
              [-11.75901126899987, 13.369180680000056],
              [-11.759492873999932, 13.368573189000131],
              [-11.76002121099998, 13.367489816000102],
              [-11.760261534999927, 13.366122247000021],
              [-11.761028289999956, 13.364670754000088],
              [-11.761612892999949, 13.36380004800003],
              [-11.762221335999925, 13.361579894000158],
              [-11.762458799999933, 13.361019135000049],
              [-11.76282977999989, 13.35970783199997],
              [-11.76313495699992, 13.358447075000015],
              [-11.763459204999947, 13.356573105000052],
              [-11.763778686999899, 13.354063987000131],
              [-11.763948439999922, 13.35190486900018],
              [-11.764378547999968, 13.349563599000078],
              [-11.764413833999981, 13.348789215000124],
              [-11.764655112999947, 13.347789765000016],
              [-11.764932632999887, 13.346761705000063],
              [-11.765212058999907, 13.345846177000169],
              [-11.765357017999918, 13.345212937000156],
              [-11.765706061999936, 13.344442368000102],
              [-11.765968321999878, 13.343202591000079],
              [-11.766262053999924, 13.342533112000012],
              [-11.766901970999868, 13.341985703000148],
              [-11.767324447999897, 13.341448784000022],
              [-11.768175124999971, 13.340293884000062],
              [-11.768694876999973, 13.339899063000132],
              [-11.772690771999919, 13.339932442000134],
              [-11.773082732999853, 13.339935303000118],
              [-11.773994445999961, 13.340170861000047],
              [-11.779004097999973, 13.340289116000179],
              [-11.782050132999927, 13.339900971000134],
              [-11.782700538999904, 13.339687348000098],
              [-11.783316611999908, 13.339336395000146],
              [-11.784015654999905, 13.339053154000055],
              [-11.785619734999955, 13.338641167000162],
              [-11.787574766999967, 13.337237359000028],
              [-11.788446425999894, 13.336497307000059],
              [-11.789151190999917, 13.336163521000174],
              [-11.789745330999892, 13.335716247000107],
              [-11.790984153999887, 13.33427238500019],
              [-11.793044090999899, 13.332175254000106],
              [-11.793439864999868, 13.331562995000183],
              [-11.79360961999987, 13.330814362000126],
              [-11.793847083999879, 13.330417634000185],
              [-11.794187546999979, 13.329848290000143],
              [-11.794220923999944, 13.32974529300003],
              [-11.794620514999963, 13.328499794000095],
              [-11.79499244699997, 13.326041222000072],
              [-11.795017242999904, 13.325231553000151],
              [-11.795158386999958, 13.3240156170001],
              [-11.795450210999945, 13.322305679000181],
              [-11.79604244199993, 13.318106652000097],
              [-11.796270369999945, 13.317465782000056],
              [-11.796512602999826, 13.315878868000027],
              [-11.796670912999957, 13.31450557800008],
              [-11.796690941999941, 13.313722611],
              [-11.797192572999961, 13.31310749000005],
              [-11.799184798999931, 13.311587334000023],
              [-11.799804687999881, 13.311239243000102],
              [-11.80094528099994, 13.311067582000021],
              [-11.802207947999875, 13.311319352000055],
              [-11.803519248999919, 13.311581611000179],
              [-11.805844307999905, 13.311900139000045],
              [-11.80816841199993, 13.312074661999986],
              [-11.808915136999815, 13.311979295000015],
              [-11.80980205599991, 13.311572076000175],
              [-11.81223487799997, 13.310890199000141],
              [-11.813836097999911, 13.310789109000154],
              [-11.815636634999862, 13.310546875000171],
              [-11.818675994999978, 13.310585022000055],
              [-11.820359230999884, 13.310463906000166],
              [-11.821243286999959, 13.310402871000178],
              [-11.823898315999884, 13.310377122000091],
              [-11.824571609999907, 13.310387611000124],
              [-11.826776503999895, 13.3105430600001],
              [-11.829092024999909, 13.310930253000095],
              [-11.829398154999922, 13.312136650000184],
              [-11.82918357899996, 13.313209535000055],
              [-11.828299522999885, 13.31399250000004],
              [-11.827792166999927, 13.314718246000098],
              [-11.826452255999982, 13.316253663],
              [-11.826128958999959, 13.316787720000036],
              [-11.825573919999954, 13.318223953000143],
              [-11.825248717999898, 13.318942071000038],
              [-11.824663161999922, 13.319724083000096],
              [-11.824337959999923, 13.320455552000112],
              [-11.824166296999863, 13.321785927000178],
              [-11.824524878999966, 13.325975418000155],
              [-11.824793815999897, 13.327709197000161],
              [-11.825391768999907, 13.329616547000057],
              [-11.825720786999966, 13.330209732000014],
              [-11.828056335999918, 13.332427026000175],
              [-11.829145431999905, 13.333154679000074],
              [-11.829636572999959, 13.33373260500008],
              [-11.830187796999894, 13.334053039000139],
              [-11.832766531999937, 13.334324837000054],
              [-11.834004402999938, 13.33422279500013],
              [-11.834961889999931, 13.334345818000145],
              [-11.835419653999963, 13.336032866999972],
              [-11.836727142999962, 13.337435723000056],
              [-11.837079048999954, 13.338146211000037],
              [-11.837305069999843, 13.338840484000059],
              [-11.837607383999966, 13.339401244999976],
              [-11.838448523999944, 13.340458870000191],
              [-11.838614463999932, 13.341145516000154],
              [-11.838972090999903, 13.342754365000133],
              [-11.839076042999977, 13.344641686000045],
              [-11.839262961999907, 13.345342636000169],
              [-11.840182304999871, 13.34716987600018],
              [-11.840723037999851, 13.349444390000087],
              [-11.841013908999912, 13.350078583000027],
              [-11.842053412999974, 13.351516723000032],
              [-11.842824935999943, 13.352811813000073],
              [-11.843680380999956, 13.353558541000041],
              [-11.846294402999945, 13.354675293000071],
              [-11.848689078999939, 13.355531693000046],
              [-11.850823402999936, 13.35803413300016],
              [-11.85129642499993, 13.358428954000033],
              [-11.854238510999892, 13.359986305],
              [-11.856010436999952, 13.360949517000165],
              [-11.857376097999975, 13.361701012000083],
              [-11.858346938999944, 13.362482071000102],
              [-11.858717917999968, 13.363136292000149],
              [-11.859112738999954, 13.363615990000085],
              [-11.860795020999888, 13.365063667000015],
              [-11.861588477999931, 13.36643600400015],
              [-11.863909720999857, 13.368974686000058],
              [-11.864452361999895, 13.370409012999971],
              [-11.865169524999885, 13.371628762000114],
              [-11.865368842999885, 13.372240066000074],
              [-11.865943908999952, 13.373571396000102],
              [-11.866424559999928, 13.374919892000094],
              [-11.866796493999971, 13.376459122000085],
              [-11.867605209999908, 13.378193855000177],
              [-11.869524001999935, 13.380905152000082],
              [-11.869990348999977, 13.38135528600003],
              [-11.870813369999951, 13.381907464000051],
              [-11.87381172299996, 13.383085252000171],
              [-11.876581192999879, 13.383489609000151],
              [-11.879474640999945, 13.383537293000188],
              [-11.881138800999963, 13.383966446000045],
              [-11.882964133999906, 13.385450363000189],
              [-11.886109351999892, 13.384233476000134],
              [-11.886749266999914, 13.384754180000016],
              [-11.888108252999928, 13.387385368000139],
              [-11.888077735999957, 13.389771463000102],
              [-11.887694358999966, 13.392494203000183],
              [-11.88684272799992, 13.395551681000029],
              [-11.885056494999901, 13.39905262000002],
              [-11.883039474999919, 13.402437211000063],
              [-11.882079124999962, 13.404925346000141],
              [-11.880521774999977, 13.408656120000103],
              [-11.879093169999976, 13.411365510000053],
              [-11.878347397999903, 13.415220260000183],
              [-11.877853392999896, 13.41748714400012],
              [-11.878046988999927, 13.420444488000044],
              [-11.877790451999942, 13.422372818000099],
              [-11.876720428999931, 13.424291610000068],
              [-11.874835013999927, 13.426426888000151],
              [-11.8716554639999, 13.429910661000179],
              [-11.8687315, 13.431578636999973],
              [-11.866157531999932, 13.433137895000016],
              [-11.865551947999847, 13.435062408000022],
              [-11.864454269999953, 13.439139366],
              [-11.864058493999892, 13.442770959000029],
              [-11.864005088999932, 13.446861268000134],
              [-11.864194869999892, 13.450159073000066],
              [-11.866421698999943, 13.457459450000044],
              [-11.868682859999979, 13.462147712000046],
              [-11.870506285999966, 13.464783668000166],
              [-11.871759413999882, 13.466617584],
              [-11.874894140999913, 13.468784333000144],
              [-11.876831054999968, 13.46952152200015],
              [-11.881799696999906, 13.471402168000111],
              [-11.884552000999918, 13.474049569000044],
              [-11.889867782999943, 13.476047516000108],
              [-11.89307880399997, 13.479155541000125],
              [-11.894887922999885, 13.482928276000052],
              [-11.895750045999932, 13.488052368000012],
              [-11.898742675999813, 13.490135193000128],
              [-11.901387214999943, 13.49221325000002],
              [-11.905075072999978, 13.494418144000065],
              [-11.911553383999888, 13.496316910000132],
              [-11.916750907999813, 13.498539925000102],
              [-11.920087813999942, 13.500967026000126],
              [-11.922601698999927, 13.504179954000051],
              [-11.924060821999944, 13.508062363000022],
              [-11.925647734999814, 13.511150360000158],
              [-11.928045271999963, 13.514361381000185],
              [-11.93092250899997, 13.516442299000062],
              [-11.934110640999961, 13.517875671000184],
              [-11.936191558999951, 13.518604280000147],
              [-11.943250656999851, 13.518855094000116],
              [-11.947435377999966, 13.520386696],
              [-11.947916984999949, 13.522202491000087],
              [-11.949598311999978, 13.522222519000138],
              [-11.952119826999876, 13.522253991000071],
              [-11.95411205299996, 13.524251936999974],
              [-11.956758499999978, 13.527739525000072],
              [-11.959755897999912, 13.530079842000191],
              [-11.965108870999813, 13.532283783000025],
              [-11.969277381999916, 13.535131454000179],
              [-11.971009254999956, 13.537303926000163],
              [-11.971263884999871, 13.537623406000137],
              [-11.971051216999967, 13.541076660000044],
              [-11.971688269999902, 13.543881417000023],
              [-11.974978445999909, 13.549679756000046],
              [-11.97910404199996, 13.555982589000109],
              [-11.983246803999975, 13.560804367000117],
              [-11.986577033999936, 13.563476563000052],
              [-11.989087104999953, 13.564494133000039],
              [-11.994958876999931, 13.565716745000145],
              [-11.996167183999887, 13.566573143000085],
              [-11.997792244999971, 13.567725182000117],
              [-11.99862289399988, 13.570517540000083],
              [-11.998922347999951, 13.571523667000065],
              [-11.997659683999927, 13.578254700000059],
              [-11.996260642999914, 13.582515717000092],
              [-11.996560096999929, 13.5854806910001],
              [-12.000514984999938, 13.591945649000138],
              [-12.004342078999969, 13.59528255500004],
              [-12.013669966999942, 13.602634430000023],
              [-12.019227981999961, 13.608764648999966],
              [-12.029301643999929, 13.614679337000155],
              [-12.03241920499994, 13.617900848000147],
              [-12.037695884999891, 13.623355866000111],
              [-12.038528441999972, 13.63116168900001],
              [-12.037901876999967, 13.638820648000035],
              [-12.036198614999932, 13.647506715000077],
              [-12.03973007299993, 13.662622451],
              [-12.050005911999961, 13.680417062],
              [-12.056921005999982, 13.690764427000033],
              [-12.056874275999917, 13.694532394000134],
              [-12.058172226999943, 13.697016715000075],
              [-12.060786246999953, 13.700555801000178],
              [-12.06774330099995, 13.707526207000114],
              [-12.073179244999949, 13.719285011000125],
              [-12.07335567399997, 13.719966888000158],
              [-12.073455810999917, 13.720353126000134],
              [-12.073044776999836, 13.720174790000158],
              [-12.068559645999812, 13.718229294000082],
              [-12.067467689999944, 13.717870712000092],
              [-12.066867828999875, 13.717649460000075],
              [-12.066226004999976, 13.717641831000094],
              [-12.065620422999871, 13.717848779000121],
              [-12.06374931299996, 13.719571114000075],
              [-12.062598228999946, 13.72059822000017],
              [-12.061533926999971, 13.722092627999984],
              [-12.060828208999965, 13.723148347000063],
              [-12.059500693999894, 13.725700378999989],
              [-12.058857916999898, 13.725692749000075],
              [-12.057146071999853, 13.726151468000126],
              [-12.056475639999974, 13.726492881000013],
              [-12.055371283999818, 13.727875709000045],
              [-12.053542136999909, 13.728428842000085],
              [-12.051631927999949, 13.72854709700016],
              [-12.05087375699992, 13.728757858000108],
              [-12.049327849999827, 13.729654312000036],
              [-12.048706053999979, 13.729906080999967],
              [-12.04739665999989, 13.730008125999973],
              [-12.045679091999887, 13.730494499000031],
              [-12.045118332999948, 13.730779647000134],
              [-12.043023109999979, 13.732340813000178],
              [-12.041694639999946, 13.732252121000101],
              [-12.039759636999975, 13.732810019999988],
              [-12.038310050999939, 13.733551980000129],
              [-12.037220954999952, 13.734312058000114],
              [-12.036723136999967, 13.734848023000154],
              [-12.035066603999951, 13.737604142],
              [-12.034425734999957, 13.738033295000093],
              [-12.030160903999956, 13.739034654000079],
              [-12.029393195999944, 13.739031791000116],
              [-12.02877807699997, 13.739132881000103],
              [-12.028218268999979, 13.739439965000145],
              [-12.026446342999918, 13.741191865000133],
              [-12.025546072999873, 13.74162483300006],
              [-12.023743628999966, 13.742379188000029],
              [-12.023141860999885, 13.742783547000045],
              [-12.020273208999868, 13.74540615],
              [-12.017277717999889, 13.747046472000022],
              [-12.01679420499994, 13.747437478000052],
              [-12.016466139999977, 13.747976303000144],
              [-12.015833854999926, 13.749787330000174],
              [-12.011218071999906, 13.749459267000077],
              [-12.009285925999905, 13.749642373000142],
              [-12.008606911999948, 13.749859811000022],
              [-12.007353782999928, 13.750773429000105],
              [-12.006897926999955, 13.751269340000022],
              [-12.00640487599992, 13.752058983999973],
              [-12.005751609999948, 13.752590179000151],
              [-12.004599571999961, 13.753058434000081],
              [-12.003339767999876, 13.753757477000136],
              [-12.002489089999926, 13.754502296],
              [-12.00206375099998, 13.755143166000096],
              [-12.001833914999906, 13.756211281000105],
              [-12.001403808999953, 13.757046700000046],
              [-12.00064277599995, 13.758563996000191],
              [-12.000607489999936, 13.759906769000168],
              [-12.00065422199998, 13.761352540000132],
              [-12.000820159999876, 13.762250900000026],
              [-11.999496459999932, 13.762984276000168],
              [-11.999008178999873, 13.763378144000058],
              [-11.998364448999894, 13.764379502000111],
              [-11.997050284999943, 13.764848709000148],
              [-11.99326324499998, 13.767237663000117],
              [-11.990749359999882, 13.768742561000067],
              [-11.988282203999972, 13.770360947000029],
              [-11.98715686699984, 13.771095276000153],
              [-11.986595152999882, 13.771674156000074],
              [-11.986022948999903, 13.772667885000146],
              [-11.985509871999966, 13.773525238000104],
              [-11.984675407999873, 13.77507591300008],
              [-11.983755111999926, 13.77610015800019],
              [-11.982080458999917, 13.776872635000075],
              [-11.981115340999906, 13.777941705000046],
              [-11.980303762999824, 13.778777124000158],
              [-11.979187010999965, 13.781215668000129],
              [-11.977897642999949, 13.783228875000191],
              [-11.977548598999931, 13.784463883000058],
              [-11.97711563099989, 13.785164833000124],
              [-11.976302147999945, 13.78654194000012],
              [-11.975645064999924, 13.787807465000128],
              [-11.974986075999936, 13.788209916000085],
              [-11.974350928999968, 13.789220811000177],
              [-11.974164962999851, 13.790048599000102],
              [-11.97399806999988, 13.791433334000033],
              [-11.97390556299996, 13.792212485999983],
              [-11.973441123999976, 13.793170928000052],
              [-11.973397254999895, 13.79326152800013],
              [-11.973349571999961, 13.793277741000111],
              [-11.971516608999934, 13.793890954000119],
              [-11.97031402499988, 13.794101715000068],
              [-11.969552993999969, 13.794496536000167],
              [-11.968907356999864, 13.794793129000084],
              [-11.968006133999893, 13.794858932000125],
              [-11.966991423999957, 13.794905662000076],
              [-11.966369627999939, 13.794906617000038],
              [-11.964876174999915, 13.795253753],
              [-11.963808059999906, 13.795768738000049],
              [-11.963280677999876, 13.796181679000028],
              [-11.962107657999979, 13.79773998200011],
              [-11.961988449999978, 13.798356056000102],
              [-11.962023733999899, 13.799350738000157],
              [-11.961791992999906, 13.799925805000044],
              [-11.961664199999973, 13.800564767000139],
              [-11.96183681499997, 13.801794052000162],
              [-11.961050032999879, 13.80318737000016],
              [-11.960348127999964, 13.804045678000136],
              [-11.95968437199997, 13.804797173000054],
              [-11.958775519999904, 13.805309297000122],
              [-11.957650183999874, 13.805379868000045],
              [-11.956009864999942, 13.805569649000176],
              [-11.955229757999973, 13.805609704000062],
              [-11.95320892299992, 13.80575943100007],
              [-11.951027870999951, 13.806256294000093],
              [-11.950032234999981, 13.8068447120001],
              [-11.948385237999958, 13.80843067100011],
              [-11.947988508999913, 13.809043885000051],
              [-11.947743414999877, 13.811143875000084],
              [-11.947833061999916, 13.812830925000071],
              [-11.948504447999881, 13.814983368000071],
              [-11.948725700999887, 13.816028595000148],
              [-11.949490546999925, 13.817921637999973],
              [-11.950008392999962, 13.819048881000072],
              [-11.95001220599994, 13.82003784200009],
              [-11.949650763999955, 13.821162224999966],
              [-11.947952269999917, 13.823775292000107],
              [-11.947695732999932, 13.824407577000102],
              [-11.947540282999967, 13.828099250000093],
              [-11.946926116999919, 13.830693244000145],
              [-11.946691512999962, 13.834877015000188],
              [-11.946695327999919, 13.837754250000103],
              [-11.946045874999925, 13.839959146000126],
              [-11.945456504999925, 13.841444970000168],
              [-11.944477081999878, 13.843242645000089],
              [-11.944153785999958, 13.844335556000033],
              [-11.943940161999876, 13.845058442000152],
              [-11.943887710999888, 13.845670701000131],
              [-11.944032668999967, 13.84728908600016],
              [-11.944708824999964, 13.850075722000156],
              [-11.944880485999875, 13.852423666999982],
              [-11.944840430999932, 13.854127885000139],
              [-11.944683073999954, 13.85599708500007],
              [-11.944388389999915, 13.856679917000122],
              [-11.943891524999856, 13.857267379],
              [-11.943045616999939, 13.857648850000089],
              [-11.942420005999963, 13.858110428999964],
              [-11.940542219999941, 13.861132623000117],
              [-11.940313338999886, 13.861721038000042],
              [-11.940117834999967, 13.863044740000134],
              [-11.937845230999869, 13.883589745000165],
              [-11.936891555999921, 13.887626649000026],
              [-11.936397552999836, 13.89214134299999],
              [-11.933255195999891, 13.907958032000124],
              [-11.930096625999965, 13.923852922000037],
              [-11.930088042999955, 13.924490929000171],
              [-11.930004118999932, 13.924603462000164],
              [-11.938400268999885, 13.997722626000154],
              [-11.962409971999875, 13.997170448000134],
              [-11.983611105999898, 13.99601841100008],
              [-12.004812240999968, 13.994864464000102],
              [-12.003656386999978, 13.996929169000168],
              [-12.001637457999891, 13.998835563000114],
              [-12.001193999999884, 13.99949550600013],
              [-12.001073836999979, 14.000111580000123],
              [-12.00128936699997, 14.001684189000116],
              [-12.001371382999935, 14.003283501000112],
              [-12.001682281999877, 14.004197122000107],
              [-12.001672744999894, 14.004998207000028],
              [-12.001916885999947, 14.006606102000148],
              [-12.002264976999868, 14.007678985000155],
              [-12.002694129999952, 14.008470535000129],
              [-12.002851486999816, 14.009116172000176],
              [-12.003150938999966, 14.011490821000052],
              [-12.00371646799988, 14.012691497000105],
              [-12.004238128999873, 14.014088630000174],
              [-12.00469875399989, 14.015062333000174],
              [-12.005382537999878, 14.016345978000061],
              [-12.005928038999969, 14.017214775000127],
              [-12.006085395999946, 14.017925263000109],
              [-12.00628852799997, 14.018664360000059],
              [-12.006641386999945, 14.019333839000126],
              [-12.00786495199992, 14.020659446000082],
              [-12.008566856999948, 14.021363259000168],
              [-12.009045599999922, 14.022003174000076],
              [-12.010168074999967, 14.023192407000181],
              [-12.010622023999872, 14.023829459000126],
              [-12.010946273999878, 14.024855615000092],
              [-12.011381148999817, 14.025868417000083],
              [-12.012229918999878, 14.02719211699997],
              [-12.011358260999941, 14.02761840900007],
              [-12.01056671099991, 14.027893067000093],
              [-12.008949279999968, 14.028856276999988],
              [-12.008338928999876, 14.02918624800003],
              [-12.007557867999935, 14.02978134100016],
              [-12.006435394999869, 14.030310631000077],
              [-12.005543707999891, 14.030986787000074],
              [-12.004601478999916, 14.032253266000168],
              [-12.004438398999923, 14.033354760000179],
              [-12.004149437999899, 14.034968376000108],
              [-12.004161833999945, 14.036108970000157],
              [-12.004358292999939, 14.039302826999972],
              [-12.00471687199996, 14.040388108000172],
              [-12.004743575999953, 14.04209232300002],
              [-12.004950522999934, 14.044856072000186],
              [-12.005138396999939, 14.046175957000059],
              [-12.005490302999931, 14.048164367000084],
              [-12.005661010999916, 14.049578666000116],
              [-12.004874228999938, 14.04983806600012],
              [-12.003732680999974, 14.049983025000131],
              [-12.001883506999945, 14.050614357000143],
              [-11.999609947999943, 14.051166535000164],
              [-11.999125479999975, 14.051549911000052],
              [-11.998551367999823, 14.05218792],
              [-11.998209951999911, 14.052741051000055],
              [-11.99769115499987, 14.054286004000062],
              [-11.996663093999928, 14.056184768000151],
              [-11.996069906999935, 14.056503295000141],
              [-11.994352340999853, 14.058706284000039],
              [-11.993170738999936, 14.061141015000146],
              [-11.992263793999882, 14.062116623000065],
              [-11.991918564999935, 14.06265449600005],
              [-11.991578101999892, 14.06347847],
              [-11.991443634999882, 14.064131738000071],
              [-11.9917049399999, 14.06642437000005],
              [-11.991739273999826, 14.067099572000132],
              [-11.99246120499987, 14.069500924000067],
              [-11.993589400999952, 14.071772576000058],
              [-11.993746757999929, 14.073519708000049],
              [-11.994548797999926, 14.07501697500004],
              [-11.994869232999974, 14.075625419000062],
              [-11.99501323699991, 14.076516151000078],
              [-11.995000838999943, 14.077379226000176],
              [-11.995082855999897, 14.078392983000128],
              [-11.995059966999975, 14.080188750000104],
              [-11.995185852999896, 14.081490516000144],
              [-11.995176314999981, 14.082165718000056],
              [-11.994895934999874, 14.082785607000119],
              [-11.994209289999901, 14.084280014000058],
              [-11.993791579999879, 14.084810257000129],
              [-11.992671012999892, 14.085589408000033],
              [-11.991326331999858, 14.08632278500005],
              [-11.988850593999928, 14.08718681300013],
              [-11.987523078999857, 14.08737182700014],
              [-11.986742972999878, 14.087068558],
              [-11.986155509999946, 14.08685398],
              [-11.98551273299995, 14.086845397000104],
              [-11.984474180999939, 14.087222100000076],
              [-11.982485770999972, 14.086514474000182],
              [-11.981875418999834, 14.086404801000072],
              [-11.981241226999941, 14.086505891000058],
              [-11.98068046599991, 14.086812020000082],
              [-11.980260848999933, 14.087287903000117],
              [-11.980033874999947, 14.087874413000179],
              [-11.981742857999961, 14.114892006000161],
              [-11.981975555999952, 14.115660667000043],
              [-11.9839715949999, 14.118536950000134],
              [-11.9845781329999, 14.120133400000043],
              [-11.985271453999871, 14.121845245000031],
              [-11.985686302999909, 14.123629571000038],
              [-11.985460281999963, 14.124666214000115],
              [-11.984616279999955, 14.126547813000059],
              [-11.983407020999891, 14.129193306000047],
              [-11.98305034699996, 14.130685806000088],
              [-11.982894896999881, 14.131784440000047],
              [-11.982801437999967, 14.132441521000146],
              [-11.982767105999869, 14.134024620000105],
              [-11.981626509999955, 14.137187004000111],
              [-11.980083464999893, 14.139403343000083],
              [-11.978116035999903, 14.142178535000028],
              [-11.977820396999959, 14.142868995000129],
              [-11.977705000999833, 14.143568040000162],
              [-11.976517677999823, 14.144694328000071],
              [-11.974975585999971, 14.146298408000121],
              [-11.97459602299989, 14.146894456000041],
              [-11.974194526999895, 14.149025918000177],
              [-11.97368335799996, 14.152062416000035],
              [-11.973612784999887, 14.155826569],
              [-11.973863600999948, 14.158868790000099],
              [-11.973974228999964, 14.16113948800006],
              [-11.974535942999978, 14.163585663000049],
              [-11.974230765999948, 14.167004586000019],
              [-11.97397136699982, 14.169485092000116],
              [-11.973381995999887, 14.172479630000055],
              [-11.973275183999931, 14.17512702900018],
              [-11.972710609999865, 14.17762279599998],
              [-11.972455024999931, 14.180435180000188],
              [-11.972053528999936, 14.183951378000131],
              [-11.97257137299988, 14.18610572800003],
              [-11.972939490999977, 14.186833382000032],
              [-11.974357604999966, 14.188267707000136],
              [-11.975230217999979, 14.188630104000026],
              [-11.976986884999917, 14.189084052999988],
              [-11.978874205999944, 14.189604760000179],
              [-11.980505942999855, 14.190093040000079],
              [-11.982140541999911, 14.19119834900016],
              [-11.984329223999907, 14.192128181000157],
              [-11.986307143999966, 14.193240165000134],
              [-11.986460685999873, 14.194062234000057],
              [-11.986187933999872, 14.19617176],
              [-11.985840797999913, 14.198054313000057],
              [-11.985612868999965, 14.199907303000089],
              [-11.985890387999859, 14.203133584000113],
              [-11.986129760999859, 14.203722001000017],
              [-11.986324309999873, 14.205022812000095],
              [-11.986259459999928, 14.206121444000075],
              [-11.986472129999925, 14.206713677000096],
              [-11.987114906999921, 14.207528115000173],
              [-11.988303184999893, 14.208732606000126],
              [-11.989853857999947, 14.209341049999978],
              [-11.990493774999891, 14.209461213000054],
              [-11.991370201999928, 14.210103035000088],
              [-11.996185301999901, 14.215147972000125],
              [-11.997090339999886, 14.218253135000111],
              [-11.997419357999945, 14.219389916000125],
              [-11.999846458999968, 14.225642204000167],
              [-12.00206756599988, 14.230872154000167],
              [-12.004595755999958, 14.23648357400009],
              [-12.00493430999984, 14.237290382000083],
              [-12.007077217999949, 14.243404388000044],
              [-12.008991241999979, 14.250943184000107],
              [-12.009225845999936, 14.25147056600008],
              [-12.012115478999931, 14.257973671000173],
              [-12.015668867999921, 14.266279220000172],
              [-12.017201423999893, 14.272818566000183],
              [-12.019000053999946, 14.278514861000133],
              [-12.019219398999894, 14.279276847000119],
              [-12.020017623999934, 14.280991555000071],
              [-12.020311354999933, 14.281489373000056],
              [-12.022438049999948, 14.285092353999971],
              [-12.023200035999935, 14.28563881000008],
              [-12.02401733399995, 14.28455448200009],
              [-12.02411365599994, 14.284267426000099],
              [-12.024218558999905, 14.284006119000082],
              [-12.0243978499999, 14.283746719000135],
              [-12.024667739999927, 14.283696174000113],
              [-12.02506160799993, 14.284008026000151],
              [-12.025152206999962, 14.284144402000095],
              [-12.025421141999914, 14.284852027999989],
              [-12.025726318999943, 14.286960602000022],
              [-12.026128767999921, 14.287363053000036],
              [-12.026221274999955, 14.287382127000171],
              [-12.026675223999973, 14.287315369000169],
              [-12.027050018999944, 14.287031173000116],
              [-12.027294159999826, 14.28677272800013],
              [-12.028548240999953, 14.285722734000103],
              [-12.028832434999913, 14.285246849000089],
              [-12.029052734999937, 14.284726142000068],
              [-12.029346465999879, 14.284188272000165],
              [-12.029804229999854, 14.283905029000096],
              [-12.030329704999815, 14.284100533000071],
              [-12.030575751999834, 14.284357072000091],
              [-12.030894278999881, 14.284794808000072],
              [-12.031186104999961, 14.285140992000095],
              [-12.031610489999935, 14.285282136000035],
              [-12.032276153999931, 14.285335541000109],
              [-12.032625197999948, 14.285593033000112],
              [-12.032756805999895, 14.286171913000032],
              [-12.032457351999881, 14.286403656000118],
              [-12.031922339999937, 14.286197663000053],
              [-12.031747818999975, 14.286096574000112],
              [-12.031340599999965, 14.286064148000094],
              [-12.030930517999877, 14.286293983],
              [-12.030907631999924, 14.286591530000067],
              [-12.03095340699997, 14.286646841999982],
              [-12.031332016999954, 14.286732674000177],
              [-12.031758308999883, 14.28673744200006],
              [-12.031979559999968, 14.286821365000151],
              [-12.033185958999979, 14.288002013000153],
              [-12.033927917999904, 14.288607597000123],
              [-12.03302574099996, 14.288911821000113],
              [-12.031956672999968, 14.289223671000116],
              [-12.031467437999879, 14.289850235000131],
              [-12.032111167999972, 14.290173530000118],
              [-12.033411978999936, 14.290587425000183],
              [-12.034255028999951, 14.290738106000106],
              [-12.034391402999972, 14.29076290200004],
              [-12.034834860999979, 14.290850639000098],
              [-12.035693168999956, 14.291022300000179],
              [-12.036919593999812, 14.290712357000018],
              [-12.03807067799994, 14.290492057999984],
              [-12.039216040999918, 14.290027618000124],
              [-12.041107176999901, 14.28917503299999],
              [-12.042819976999965, 14.289196969000045],
              [-12.043546676999938, 14.290316582000059],
              [-12.044580459999963, 14.291124344000082],
              [-12.044651985999906, 14.291251183000043],
              [-12.045392035999953, 14.291143418000104],
              [-12.046063421999975, 14.291151045999982],
              [-12.047216415999912, 14.290841104000094],
              [-12.048194884999873, 14.291096688000096],
              [-12.048426627999959, 14.291731835000178],
              [-12.049396515999888, 14.292700768000145],
              [-12.050116538999873, 14.293585777000089],
              [-12.050844192999875, 14.294552802999988],
              [-12.052057265999963, 14.29528045700016],
              [-12.053108214999952, 14.295691491000071],
              [-12.054329871999869, 14.296428681000123],
              [-12.056114195999896, 14.297408104000056],
              [-12.058229446999974, 14.297831536000103],
              [-12.060185431999969, 14.298406601000181],
              [-12.061151503999952, 14.299619675000031],
              [-12.062291144999961, 14.299552916999971],
              [-12.063846587999933, 14.299571991000164],
              [-12.064507483999932, 14.298623086000077],
              [-12.065407752999874, 14.298471451000069],
              [-12.0664644229999, 14.299125672000059],
              [-12.067279815999882, 14.299054146],
              [-12.067702293999844, 14.297948837000149],
              [-12.068600654999898, 14.297960282000133],
              [-12.070643423999911, 14.298219682000081],
              [-12.072106361999886, 14.298237800000152],
              [-12.07285022699989, 14.2980127350001],
              [-12.073670387999925, 14.297625542000105],
              [-12.074735640999847, 14.297637940000072],
              [-12.075546263999968, 14.297964096999976],
              [-12.077405930999873, 14.299576760000093],
              [-12.077547072999948, 14.300779342000112],
              [-12.078024864999861, 14.301815033000139],
              [-12.079005240999891, 14.301908493000042],
              [-12.079390525999884, 14.303665162000186],
              [-12.080612182999971, 14.304437638000138],
              [-12.080933570999832, 14.304641723000032],
              [-12.081732750999947, 14.305843354000103],
              [-12.082868576999886, 14.306100846000049],
              [-12.084426879999967, 14.30595779500004],
              [-12.086883544999978, 14.305752755000128],
              [-12.089178084999958, 14.305221558000142],
              [-12.090892792999966, 14.30516147700007],
              [-12.092439650999893, 14.305098534000081],
              [-12.093332289999864, 14.305588722000152],
              [-12.0945510869999, 14.306523324000011],
              [-12.095861434999961, 14.30626010899999],
              [-12.096756934999974, 14.306506158000047],
              [-12.09789085299991, 14.306917190000092],
              [-12.098690985999951, 14.308047295000108],
              [-12.099432944999876, 14.307974816000069],
              [-12.100433349999946, 14.30655098],
              [-12.100341797999931, 14.30626010899999],
              [-12.100032805999945, 14.305273057000079],
              [-12.100855826999918, 14.305363654000189],
              [-12.10207176199998, 14.305857658000036],
              [-12.102383613999962, 14.306818961999966],
              [-12.102291106999928, 14.30753135600014],
              [-12.101954460999877, 14.308484078000106],
              [-12.101001738999912, 14.308633805000113],
              [-12.100563048999959, 14.308702468000035],
              [-12.100057601999936, 14.309816360000184],
              [-12.100294112999904, 14.31084823600014],
              [-12.101276396999822, 14.310860634000107],
              [-12.10147857599992, 14.31070327700013],
              [-12.102183341999876, 14.31015777600004],
              [-12.103081703999976, 14.310169220000091],
              [-12.10412693, 14.31097602900013],
              [-12.104104996999979, 14.312727929000118],
              [-12.103606223999918, 14.31336307600003],
              [-12.101816176999876, 14.313539505000108],
              [-12.101482390999877, 14.313571931000126],
              [-12.101001738999912, 14.312771797000039],
              [-12.100515365999911, 14.312368393000042],
              [-12.09993267099992, 14.313075066000181],
              [-12.099028586999907, 14.313461304000157],
              [-12.098130226999899, 14.313449860000105],
              [-12.097398757999883, 14.313441278000084],
              [-12.096987723999973, 14.313761710000108],
              [-12.096982002999937, 14.314158438999982],
              [-12.097059248999926, 14.314638139000067],
              [-12.096805571999937, 14.314950943000042],
              [-12.09639358499993, 14.315262795000081],
              [-12.095835686999976, 14.314704895000091],
              [-12.09549903899989, 14.315016747000129],
              [-12.095572471999958, 14.315731050000181],
              [-12.095240593999904, 14.316368103000059],
              [-12.094980238999881, 14.3171596520001],
              [-12.094408989999863, 14.317631722000044],
              [-12.094149589999972, 14.318341255000064],
              [-12.093730925999978, 14.319212913000058],
              [-12.093317030999913, 14.319685936000042],
              [-12.093551634999869, 14.320167543000025],
              [-12.094205855999917, 14.320491791],
              [-12.094937324999876, 14.320500374000119],
              [-12.095098494999888, 14.320899965000024],
              [-12.095829963999904, 14.320909500000027],
              [-12.096481321999931, 14.321395875],
              [-12.095985411999891, 14.321786881000037],
              [-12.095405578999873, 14.322258949],
              [-12.095081328999981, 14.322255135999967],
              [-12.094186781999952, 14.321928024999977],
              [-12.093531608999911, 14.321757317000106],
              [-12.093197822999912, 14.322475434000069],
              [-12.092697142999953, 14.323264123000058],
              [-12.091962814999874, 14.323490144],
              [-12.091548918999933, 14.323963166000055],
              [-12.091207504999829, 14.324600220000036],
              [-12.092987060999974, 14.325896264000164],
              [-12.093966483999907, 14.326070785000127],
              [-12.09608840899989, 14.326728821000017],
              [-12.096911429999977, 14.326188088000151],
              [-12.097645758999874, 14.325952529000119],
              [-12.098304747999975, 14.325880051000127],
              [-12.098865508999893, 14.326211929000067],
              [-12.099349975999928, 14.32668685900012],
              [-12.099922180999954, 14.326856614],
              [-12.100817679999977, 14.32711124400015],
              [-12.101312637999968, 14.326792718000092],
              [-12.102132796999967, 14.326405525000098],
              [-12.102458, 14.327050210000039],
              [-12.103273392999938, 14.326978683999982],
              [-12.103924750999909, 14.326743126000054],
              [-12.104419708999842, 14.326433182000187],
              [-12.105232239999907, 14.32668685900012],
              [-12.105715751999981, 14.327243805000023],
              [-12.105065346999879, 14.327398300000084],
              [-12.104242325999905, 14.328029634000075],
              [-12.104886055999941, 14.328352929000118],
              [-12.105546951999941, 14.328117371000189],
              [-12.105956077999963, 14.327968598000041],
              [-12.106448173999979, 14.327894210000068],
              [-12.106273650999924, 14.328523637000046],
              [-12.106023788999948, 14.329243661000078],
              [-12.10633659399997, 14.330122947],
              [-12.106823921999876, 14.330364228000178],
              [-12.107807159999879, 14.330304146000174],
              [-12.108449935999943, 14.330708504000086],
              [-12.10869312199992, 14.331262588000072],
              [-12.108691214999965, 14.331370353000011],
              [-12.10868453899991, 14.331904411000153],
              [-12.108275412999831, 14.332061768000131],
              [-12.107906341999922, 14.331845283000064],
              [-12.107300758999941, 14.331489563000105],
              [-12.106153487999961, 14.331394196000133],
              [-12.10606193599989, 14.332034111000041],
              [-12.106797217999826, 14.332441330000108],
              [-12.106840132999821, 14.332489013000043],
              [-12.107363701999873, 14.33308029300008],
              [-12.106618881999964, 14.333387375000086],
              [-12.106125831999975, 14.333543777000102],
              [-12.10636711099994, 14.334187507000081],
              [-12.106679916999951, 14.33514022899999],
              [-12.106181143999834, 14.33569336000005],
              [-12.105524062999905, 14.335685730000137],
              [-12.105290412999921, 14.33512306099999],
              [-12.104722975999948, 14.334565163000036],
              [-12.103823660999979, 14.334635736000109],
              [-12.102839469999935, 14.334776878000071],
              [-12.102419852999901, 14.335729600000036],
              [-12.102495193999857, 14.336371422000184],
              [-12.103219985999942, 14.33765316],
              [-12.103285788999813, 14.338286400000186],
              [-12.102719306999916, 14.338360786000123],
              [-12.101901053999882, 14.337953569000092],
              [-12.101427078999961, 14.33730697600015],
              [-12.101018904999933, 14.336668969000016],
              [-12.101110458999926, 14.336029054000107],
              [-12.10160732199995, 14.335556984000107],
              [-12.101944922999962, 14.334530831000052],
              [-12.102211952999937, 14.333179475000122],
              [-12.102000235999924, 14.332597733000171],
              [-12.101656913999932, 14.331655502999979],
              [-12.101171493999914, 14.331252098000107],
              [-12.10027408499991, 14.331160546000149],
              [-12.099534987999959, 14.331710816000054],
              [-12.099743842999942, 14.332356453999978],
              [-12.099766730999875, 14.332427025000072],
              [-12.100335119999897, 14.332913400000052],
              [-12.100492477999978, 14.333637237000175],
              [-12.099745749999897, 14.334106446000021],
              [-12.098930358999951, 14.334096908000106],
              [-12.098254202999954, 14.333843231000174],
              [-12.098035811999921, 14.333761215000152],
              [-12.097958563999839, 14.333281517000046],
              [-12.097807883999963, 14.332809447999978],
              [-12.097318648999931, 14.332641602000137],
              [-12.096982002999937, 14.332872391000137],
              [-12.096817015999875, 14.333511353000063],
              [-12.096551894999891, 14.33461856800011],
              [-12.096954346999951, 14.335021019000123],
              [-12.097777367999925, 14.335193634000063],
              [-12.098417282999947, 14.335843087000057],
              [-12.098406791999878, 14.336637497000083],
              [-12.098159790999944, 14.337113380000119],
              [-12.097349167999937, 14.336787224000147],
              [-12.096375464999937, 14.336133956000083],
              [-12.095478057999856, 14.336041452000075],
              [-12.094579695999869, 14.336030961000176],
              [-12.094166755999879, 14.336422920000189],
              [-12.093270301999951, 14.336257934000059],
              [-12.092860221999956, 14.335774421999986],
              [-12.092627525999887, 14.335130692],
              [-12.093207358999905, 14.334658622000063],
              [-12.093698501999938, 14.33466530000004],
              [-12.094425200999979, 14.335071564000089],
              [-12.094997404999958, 14.335241317000055],
              [-12.095324515999948, 14.335000993000051],
              [-12.095742224999867, 14.3342113490001],
              [-12.09600257999989, 14.333419799000126],
              [-12.09585285299994, 14.332144737000135],
              [-12.095620154999892, 14.331500054000173],
              [-12.095134733999942, 14.331025124000121],
              [-12.094387053999981, 14.331566810000027],
              [-12.093733787999952, 14.33195591000009],
              [-12.092986105999898, 14.332425117000071],
              [-12.092247008999948, 14.333057404000101],
              [-12.091914177999854, 14.333685876],
              [-12.091415404999964, 14.334321021000108],
              [-12.09091091099998, 14.335353852000026],
              [-12.090493201999948, 14.336143494000169],
              [-12.090807913999981, 14.336860657999978],
              [-12.091695784999899, 14.337666513000158],
              [-12.092509268999891, 14.337838173000137],
              [-12.093252180999968, 14.337685586000021],
              [-12.093730925999978, 14.337934495000127],
              [-12.094959257999903, 14.338184356999989],
              [-12.095192908999877, 14.338747979000118],
              [-12.094936369999971, 14.339936256999977],
              [-12.094839094999941, 14.341054917000179],
              [-12.095643042999939, 14.341940881000141],
              [-12.096529006999958, 14.342909814000109],
              [-12.097744940999917, 14.343402862000062],
              [-12.099128723999911, 14.343889237000042],
              [-12.100275039999929, 14.344065666000176],
              [-12.101494788999958, 14.344325065000021],
              [-12.101785660999951, 14.344687462000081],
              [-12.10246086199993, 14.345528604000037],
              [-12.103908537999928, 14.34746170000011],
              [-12.104303360999893, 14.348495483000136],
              [-12.104376791999925, 14.349291802000039],
              [-12.103709220999917, 14.350006104000158],
              [-12.102803230999939, 14.350626945000101],
              [-12.102673529999947, 14.350638391000189],
              [-12.101986884999974, 14.350698471000157],
              [-12.101655006999977, 14.351254464000078],
              [-12.101565360999928, 14.351731300000097],
              [-12.102012633999891, 14.352026941000133],
              [-12.102052687999901, 14.352053642000101],
              [-12.101793288999943, 14.352764130000082],
              [-12.10151767799988, 14.35306930500002],
              [-12.10122013199998, 14.353398323000079],
              [-12.100559235999981, 14.353625298000111],
              [-12.099897384999849, 14.353941918000032],
              [-12.099399565999875, 14.354486466000139],
              [-12.099725723999939, 14.355051040000149],
              [-12.099308966999899, 14.355768204000128],
              [-12.09881591899989, 14.35591602300002],
              [-12.098484992999943, 14.35575771300006],
              [-12.097763061999899, 14.354945182999984],
              [-12.096705436999969, 14.354381561000082],
              [-12.095734596999932, 14.354207038000141],
              [-12.09524154699983, 14.354363443000182],
              [-12.094745635999914, 14.35475444900004],
              [-12.094315527999925, 14.356501580000099],
              [-12.094710348999911, 14.357463838000115],
              [-12.094941139999946, 14.358261108000022],
              [-12.095269202999873, 14.358662605000063],
              [-12.096572875999925, 14.358913421000068],
              [-12.096977232999905, 14.359162330000117],
              [-12.096718787999919, 14.35979175500006],
              [-12.096148490999894, 14.360181808000107],
              [-12.095656395999868, 14.360257149000063],
              [-12.095163344999946, 14.360413551000022],
              [-12.094501494999975, 14.36072158799999],
              [-12.094401359999949, 14.362074852000092],
              [-12.094472884999902, 14.362952233000101],
              [-12.094224928999893, 14.363509179000175],
              [-12.094214438999927, 14.364303588999974],
              [-12.094598768999845, 14.366141320000111],
              [-12.094907760999831, 14.367338181000093],
              [-12.095388412999966, 14.368138313000031],
              [-12.096193313999947, 14.368951799],
              [-12.097323416999927, 14.370392799000058],
              [-12.098361967999892, 14.371760368000139],
              [-12.099167822999959, 14.372492789999967],
              [-12.10022544799989, 14.373056411000164],
              [-12.100960731999976, 14.373544694000145],
              [-12.102087974999904, 14.374515534000182],
              [-12.102490425999974, 14.374917984000092],
              [-12.103217124999901, 14.375324250000119],
              [-12.103703497999902, 14.375655175000077],
              [-12.104199408999818, 14.375254632000065],
              [-12.104779242999939, 14.374864578000143],
              [-12.10567951299987, 14.374722482000095],
              [-12.106337546999896, 14.37473011000003],
              [-12.107069015999912, 14.374738693000097],
              [-12.108051298999953, 14.374751091000064],
              [-12.109036445999891, 14.374519348000149],
              [-12.109608649999927, 14.373975754000128],
              [-12.110355376999962, 14.373505592000129],
              [-12.111091613999974, 14.373189927],
              [-12.112487791999911, 14.372655870000131],
              [-12.113556861999939, 14.372425079000152],
              [-12.115031241999873, 14.371566772000108],
              [-12.115050314999962, 14.371525765000172],
              [-12.115288733999932, 14.371009828000012],
              [-12.115624426999943, 14.370138169000143],
              [-12.116038322999884, 14.369591714000137],
              [-12.116860389999943, 14.369123458999979],
              [-12.117354392999971, 14.368885994000152],
              [-12.117362022999885, 14.368253709000157],
              [-12.117371558999878, 14.367530822000106],
              [-12.11763095799995, 14.36682033600016],
              [-12.118363379999892, 14.366748810999979],
              [-12.119584083999882, 14.367639542000063],
              [-12.120054243999903, 14.368602753000062],
              [-12.12020778599998, 14.369561195000188],
              [-12.120694159999914, 14.369964599000127],
              [-12.121101378999924, 14.369970321999972],
              [-12.121761321999941, 14.369815827000082],
              [-12.122323035999955, 14.370065689000171],
              [-12.122699737999937, 14.370238304000168],
              [-12.123216629999888, 14.370473863000029],
              [-12.12345886199995, 14.371109010000055],
              [-12.123863219999919, 14.371357919000161],
              [-12.124356270999954, 14.371201515000166],
              [-12.12485694899982, 14.370493889000045],
              [-12.125577925999949, 14.371297836000053],
              [-12.126632689999951, 14.372186661000171],
              [-12.12767028899998, 14.37363529300012],
              [-12.127743721999934, 14.374349594000137],
              [-12.128394126999922, 14.374999047000131],
              [-12.1287107469999, 14.375554086000136],
              [-12.128965376999929, 14.376068115000123],
              [-12.129506111999888, 14.37716197900005],
              [-12.129247664999923, 14.378513337000129],
              [-12.129645347999883, 14.379313469000067],
              [-12.130117415999962, 14.380754472000035],
              [-12.129290579999918, 14.381702424000139],
              [-12.128594398999951, 14.382356644000083],
              [-12.128455162999899, 14.382487298000058],
              [-12.127965926999934, 14.383112908000101],
              [-12.127705572999957, 14.383913995000057],
              [-12.126964567999948, 14.384618760000023],
              [-12.126621246999946, 14.385327339000128],
              [-12.12660694099992, 14.386446954000121],
              [-12.126270292999948, 14.387473107000176],
              [-12.125535010999954, 14.387707710000143],
              [-12.124626158999888, 14.388491629999976],
              [-12.124932289999947, 14.389931679000028],
              [-12.12507915499998, 14.391441345000089],
              [-12.125227928999948, 14.392166137000174],
              [-12.125303267999982, 14.392798423999977],
              [-12.12561988799996, 14.393363],
              [-12.126189231999831, 14.393767357000172],
              [-12.126597403999938, 14.393772125],
              [-12.127343177999933, 14.393383979000021],
              [-12.127674103999937, 14.392909049000025],
              [-12.128253935999965, 14.392436982000106],
              [-12.128905295999914, 14.392210005999971],
              [-12.129564284999844, 14.392137528000035],
              [-12.13038063099998, 14.392066002000149],
              [-12.130472182999824, 14.391434670000137],
              [-12.130228042999875, 14.39095306500019],
              [-12.129670142999885, 14.39038658100003],
              [-12.128938674999858, 14.38966369700006],
              [-12.128304480999873, 14.388536454000132],
              [-12.128070831999821, 14.387178421000158],
              [-12.128561972999933, 14.387185096000053],
              [-12.129219055999954, 14.38727378800013],
              [-12.129869461999931, 14.387119293000069],
              [-12.130243301999883, 14.38676357300011],
              [-12.130282400999931, 14.386727334000057],
              [-12.13086509599998, 14.386101723000024],
              [-12.131848334999972, 14.385951043000034],
              [-12.132658957999922, 14.386358262000044],
              [-12.133475302999955, 14.386286736000159],
              [-12.133966445999931, 14.386292459],
              [-12.134902954999973, 14.386899948000121],
              [-12.135344505999967, 14.387186051000072],
              [-12.136078834999978, 14.387754440000094],
              [-12.13778972599988, 14.388019562000181],
              [-12.138769148999927, 14.38818454799997],
              [-12.139334677999898, 14.388191223000092],
              [-12.140404700999966, 14.387806892000185],
              [-12.140736580999828, 14.387250901000073],
              [-12.140750883999942, 14.386139869000033],
              [-12.141015053999865, 14.385023117000173],
              [-12.141224860999898, 14.384912492000126],
              [-12.142488479999827, 14.384246826000151],
              [-12.143501282999978, 14.384186745000079],
              [-12.143544196999869, 14.383974075000026],
              [-12.14365387, 14.384149551000178],
              [-12.143669127999885, 14.384174347000112],
              [-12.144454954999901, 14.384117126999968],
              [-12.146167754999965, 14.384209634000058],
              [-12.147313118999875, 14.384467126000061],
              [-12.149683952999908, 14.384576798000069],
              [-12.151483534999954, 14.384436607000112],
              [-12.153693197999928, 14.384065627000155],
              [-12.15474700999988, 14.384241105000115],
              [-12.15540409099998, 14.384321214000067],
              [-12.15621471399993, 14.38465595200006],
              [-12.156943320999972, 14.384981155000048],
              [-12.15840721099994, 14.385711669000102],
              [-12.159214973999894, 14.386281968000105],
              [-12.16027545899982, 14.386692048000157],
              [-12.16272449399986, 14.387199402000022],
              [-12.1629591, 14.387681006999969],
              [-12.163612364999949, 14.388086318000035],
              [-12.164097785999957, 14.388489724000181],
              [-12.16391945, 14.389445304000049],
              [-12.163915633999977, 14.389760971000101],
              [-12.164310455999896, 14.390723229000173],
              [-12.164708136999934, 14.391522407000025],
              [-12.164862633999917, 14.392481804000113],
              [-12.165754317999813, 14.393053056000099],
              [-12.166885375999982, 14.393779755000082],
              [-12.167940139999928, 14.394587518000037],
              [-12.169069290999914, 14.395477295000035],
              [-12.168980598999951, 14.396595954000134],
              [-12.169212339999945, 14.397312165000187],
              [-12.169363974999953, 14.398514747000036],
              [-12.169755935999888, 14.39979362400004],
              [-12.170479774999933, 14.400434494000137],
              [-12.170804024999939, 14.400438309000037],
              [-12.171634673999904, 14.399969102000171],
              [-12.172946929999853, 14.399506568000106],
              [-12.174086570999975, 14.399519921000035],
              [-12.174819945999843, 14.400170327000069],
              [-12.175379753999948, 14.400574683000116],
              [-12.176526069999966, 14.40083217599999],
              [-12.177099226999871, 14.400911331000088],
              [-12.17751216899984, 14.400518418000161],
              [-12.178662299999871, 14.39965629500017],
              [-12.179254530999856, 14.398308754000141],
              [-12.179511069999933, 14.39703750699999],
              [-12.180335043999889, 14.396334649000096],
              [-12.181400298999847, 14.396347045000084],
              [-12.182297705999929, 14.39651107800006],
              [-12.183360100999892, 14.39676761700008],
              [-12.18384552099991, 14.39717102100002],
              [-12.184489250999889, 14.397576333000188],
              [-12.184893607999982, 14.397896768000123],
              [-12.185192108999956, 14.397900580999988],
              [-12.185187338999924, 14.3978128440001],
              [-12.187912940999922, 14.398736954000185],
              [-12.188799857999868, 14.399695397000187],
              [-12.190087317999883, 14.40122795100018],
              [-12.191214560999981, 14.402198792000092],
              [-12.192022323999936, 14.40284061400007],
              [-12.193490982999947, 14.403183938000097],
              [-12.194953918999943, 14.403995514000087],
              [-12.196256637999909, 14.404327392000027],
              [-12.197470664999912, 14.405055045999973],
              [-12.198291777999884, 14.405390739000154],
              [-12.19925785099997, 14.40596199099997],
              [-12.200242041999957, 14.405765534000182],
              [-12.201744079999912, 14.406211853000059],
              [-12.201702117999901, 14.406721116000028],
              [-12.201363563999962, 14.408751489000053],
              [-12.201956749999908, 14.411402703000022],
              [-12.201913832999935, 14.414860725000153],
              [-12.202238082999941, 14.416619300000093],
              [-12.202368735999926, 14.417327881000176],
              [-12.202402114999927, 14.417511940000054],
              [-12.202568052999879, 14.420972824000046],
              [-12.202221869999903, 14.423613548000048],
              [-12.200733184999933, 14.425833702000091],
              [-12.19934272699993, 14.428462028000126],
              [-12.19595527599995, 14.431423187000064],
              [-12.194720267999969, 14.432503700000041],
              [-12.192588805999947, 14.434996606000084],
              [-12.190011977999973, 14.438261032000185],
              [-12.189847946999976, 14.441672326000173],
              [-12.192346571999963, 14.444644928000059],
              [-12.197603225999956, 14.44929790600014],
              [-12.203025816999912, 14.45390701299999],
              [-12.203219413999932, 14.454071998000074],
              [-12.206556319999891, 14.457642555000177],
              [-12.206544876999942, 14.458596230000126],
              [-12.206531523999899, 14.459688186000051],
              [-12.206501959999855, 14.46199703200017],
              [-12.205878257999927, 14.463637353000081],
              [-12.203254699999945, 14.468655586000068],
              [-12.200680731999967, 14.473579407000045],
              [-12.199439048999977, 14.476271630000156],
              [-12.199172973999964, 14.478151321000155],
              [-12.199521064999942, 14.479332924000119],
              [-12.200712203999956, 14.48064136500011],
              [-12.202868461999969, 14.482079505000058],
              [-12.205876350999972, 14.483057023000129],
              [-12.208840369999962, 14.483836175000135],
              [-12.211413382999979, 14.484652520000054],
              [-12.215850830999955, 14.487176896000051],
              [-12.218960761999881, 14.489686012000163],
              [-12.220981597999923, 14.492299079000134],
              [-12.221189497999887, 14.495008468000037],
              [-12.221161841999901, 14.497243881000145],
              [-12.221116064999933, 14.500892639000085],
              [-12.22106647499993, 14.504893304000063],
              [-12.220447540999885, 14.508387565000078],
              [-12.21990585299983, 14.510764122000182],
              [-12.219286918999956, 14.511934281000094],
              [-12.217669485999977, 14.515681267000105],
              [-12.215446471999883, 14.519538880000027],
              [-12.213849066999956, 14.524222375000022],
              [-12.213309288999881, 14.526222229000098],
              [-12.213832855999954, 14.532584191000183],
              [-12.214862823999908, 14.537186622000036],
              [-12.216149329999951, 14.540615083000091],
              [-12.216593742999976, 14.544357301000105],
              [-12.216695784999956, 14.545211793000135],
              [-12.217013359999953, 14.548746109000149],
              [-12.215533255999958, 14.551199914000051],
              [-12.213090895999926, 14.553379059000179],
              [-12.208455085999958, 14.556765557000062],
              [-12.206158638999966, 14.556737901000076],
              [-12.199276923999946, 14.556302071000061],
              [-12.193734168999924, 14.555059432000178],
              [-12.1897506699999, 14.55477619200019],
              [-12.187590598999975, 14.555361749000042],
              [-12.18684005699987, 14.55556488100018],
              [-12.185491562999971, 14.557078362000084],
              [-12.185577391999971, 14.55990409900005],
              [-12.185997961999931, 14.564852714000097],
              [-12.185958861999893, 14.567912102000093],
              [-12.183849333999888, 14.572240830000169],
              [-12.180279730999928, 14.57737636600018],
              [-12.176597595999908, 14.581804276000071],
              [-12.172571182999945, 14.584815979000155],
              [-12.171939849999944, 14.586926461000132],
              [-12.171979904999944, 14.588679313000057],
              [-12.172017096999923, 14.590340615],
              [-12.172196387999918, 14.595286370000053],
              [-12.172583580999969, 14.599669457000061],
              [-12.172557831999882, 14.603614808000088],
              [-12.172695160999979, 14.605351449000182],
              [-12.172756193999874, 14.606117250000011],
              [-12.173034666999911, 14.609626769000045],
              [-12.173011780999957, 14.611448287000144],
              [-12.171943665999891, 14.613662721000026],
              [-12.17116165199991, 14.615205766000088],
              [-12.169564246999926, 14.61629676900003],
              [-12.169413566999879, 14.616398812],
              [-12.166500090999875, 14.616633414999967],
              [-12.161994933999893, 14.616645813000105],
              [-12.154076575999909, 14.618034363000106],
              [-12.150498390999815, 14.621432305000042],
              [-12.148732185999961, 14.624042512000074],
              [-12.148191452999981, 14.626405716000022],
              [-12.147978782999871, 14.62733459400016],
              [-12.147777557999916, 14.628213883000171],
              [-12.144408225999939, 14.63687801399999],
              [-12.144326208999928, 14.643220901000177],
              [-12.144469260999927, 14.645079613000121],
              [-12.144800185999941, 14.649367332000054],
              [-12.145267485999966, 14.650790215000143],
              [-12.146346090999884, 14.653164864000075],
              [-12.149015425999949, 14.65576267300014],
              [-12.151560782999979, 14.657210351],
              [-12.155914306999932, 14.658276559000115],
              [-12.159647941999935, 14.658996583000032],
              [-12.166694641999982, 14.660837174000164],
              [-12.171122549999893, 14.661499023000147],
              [-12.174991607999914, 14.662490844000047],
              [-12.176156997999954, 14.663516999000137],
              [-12.177383422999924, 14.665151596000101],
              [-12.177495002999933, 14.666340828999978],
              [-12.177566528999876, 14.66711044300007],
              [-12.177565573999971, 14.667209625000112],
              [-12.177536009999926, 14.669472694000035],
              [-12.177099226999871, 14.67108631200017],
              [-12.175694465999982, 14.672554016000106],
              [-12.173746109999968, 14.673137665000127],
              [-12.169853209999928, 14.673967362000155],
              [-12.166087150999886, 14.675744057000145],
              [-12.162730216999876, 14.677996636000103],
              [-12.162444114999914, 14.678309440000078],
              [-12.160409927999922, 14.680533409000134],
              [-12.158926962999885, 14.682606697000097],
              [-12.158897399999944, 14.68490123700002],
              [-12.159490584999958, 14.687270165000086],
              [-12.160785674999943, 14.689040183000031],
              [-12.162096976999976, 14.689461708000181],
              [-12.163834570999938, 14.689145088000089],
              [-12.166413306999971, 14.688097001000131],
              [-12.16864204399991, 14.687247277000154],
              [-12.169963835999965, 14.686925889000065],
              [-12.171280859999911, 14.686942100000067],
              [-12.171986579999952, 14.687045097000123],
              [-12.173639297999955, 14.687978746],
              [-12.17413330099987, 14.688259124000126],
              [-12.176650046999896, 14.690988542000184],
              [-12.179771422999977, 14.694049835000101],
              [-12.182190895999895, 14.695698737999976],
              [-12.184346198999947, 14.696318627000039],
              [-12.186896323999918, 14.696404458000131],
              [-12.19033908799986, 14.696122170000024],
              [-12.194894790999967, 14.695528985000067],
              [-12.199163435999822, 14.695634842000061],
              [-12.204421997999873, 14.696454048000135],
              [-12.208327292999968, 14.699038506000136],
              [-12.211125373999948, 14.701501847000088],
              [-12.213039398999967, 14.703630447000023],
              [-12.21394443399987, 14.70498848],
              [-12.21445464999988, 14.705753326000035],
              [-12.214329718999863, 14.706830979000131],
              [-12.213481901999955, 14.707986831000028],
              [-12.213426589999926, 14.708062172000155],
              [-12.211618422999948, 14.710685730000137],
              [-12.209622383999942, 14.714981078999983],
              [-12.208985328999972, 14.717078209000135],
              [-12.20888137899982, 14.717761039000152],
              [-12.208662985999865, 14.719180108000046],
              [-12.209503172999916, 14.722933769000065],
              [-12.210476875999916, 14.727767945000153],
              [-12.211910247999981, 14.731384277000075],
              [-12.214320183999973, 14.733789445000127],
              [-12.217329979999931, 14.735552788000064],
              [-12.220202446999963, 14.7365236280001],
              [-12.221534728999927, 14.736467361000166],
              [-12.223002432999976, 14.736298562000172],
              [-12.229742048999924, 14.736124991999986],
              [-12.234000205999905, 14.738149644000032],
              [-12.234310150999875, 14.739575386000013],
              [-12.234804153999903, 14.741850853000074],
              [-12.236504553999907, 14.748426437999967],
              [-12.236612320999939, 14.748843193000084],
              [-12.238911628999915, 14.759259224000118],
              [-12.23622131299993, 14.758051872000067],
              [-12.220951079999963, 14.756941795000103],
              [-12.205679893999957, 14.758581162000155],
              [-12.180229186999952, 14.75802040100001],
              [-12.153271675999861, 14.772192955000094],
              [-12.153071403999888, 14.772298812000031],
              [-12.152303694999944, 14.772471428000131],
              [-12.145718573999943, 14.773948669000049],
              [-12.140061378999917, 14.773296356000174],
              [-12.136239051999951, 14.772854805000122],
              [-12.136100768999938, 14.772838592000141],
              [-12.128190040999982, 14.768989564000151],
              [-12.122360229999913, 14.760560989000112],
              [-12.111809729999948, 14.745311737000122],
              [-12.080819130999942, 14.727792739000108],
              [-12.063237189999882, 14.717853546000072],
              [-12.062081337999814, 14.717201233000026],
              [-12.056989668999961, 14.715551376],
              [-12.054421423999941, 14.7179803840001],
              [-12.04971027299996, 14.723280907000174],
              [-12.0445499409999, 14.739600182000117],
              [-12.049769400999935, 14.751758575000053],
              [-12.048515319999922, 14.754880906000039],
              [-12.047180176999916, 14.758199691000129],
              [-12.03987121699987, 14.759590149000076],
              [-12.022871017999876, 14.758699417000059],
              [-12.007538794999959, 14.76286125200005],
              [-12.00022125199996, 14.762869835000174],
              [-11.991238592999878, 14.759900093000113],
              [-11.979542731999913, 14.761477470000159],
              [-11.978932380999936, 14.761560441000029],
              [-11.956101416999957, 14.775571823000064],
              [-11.948081015999946, 14.783169746000169],
              [-11.944320678999929, 14.790060044000029],
              [-11.940329551999923, 14.808450699000161],
              [-11.939148901999943, 14.810218811000084],
              [-11.936878203999981, 14.813623428000142],
              [-11.936799049999934, 14.813739775999977],
              [-11.93043231899992, 14.816731454000092],
              [-11.919811248999963, 14.817891121],
              [-11.906828879999978, 14.821580886000049],
              [-11.869318961999966, 14.844371797000065],
              [-11.851620672999843, 14.861809731000164],
              [-11.847379683999861, 14.865989685000159],
              [-11.843536376999907, 14.870635987000185],
              [-11.835590361999891, 14.880241394999985],
              [-11.831819534999909, 14.885300637000114],
              [-11.826869963999911, 14.888521195000123],
              [-11.823891638999953, 14.889442444000053],
              [-11.812701224999898, 14.89290237400013],
              [-11.807741164999925, 14.896809579000035],
              [-11.806433677999905, 14.900650024000129],
              [-11.80609989199985, 14.901631355000063],
              [-11.809372900999961, 14.908953668000038],
              [-11.815331459999925, 14.922288895000065],
              [-11.816989897999918, 14.928951263000101],
              [-11.815309523999872, 14.939061164000179],
              [-11.813780784999949, 14.944041252000147],
              [-11.807208061999972, 14.964900017000161],
              [-11.806879996999896, 14.965941429000168],
              [-11.806675911999889, 14.967016221000108],
              [-11.802096366999876, 14.991181374000178],
              [-11.801480293999873, 14.994429588000173],
              [-11.799591064999959, 15.000000000000114],
              [-11.798780441999952, 15.002395629000148],
              [-11.79332828399987, 15.018500329000062],
              [-11.793000220999886, 15.019471169000099],
              [-11.792952536999962, 15.019846917000109],
              [-11.792404175999877, 15.024295808000034],
              [-11.79229068799998, 15.025210381000136],
              [-11.797465324999962, 15.035627366000028],
              [-11.80152988399982, 15.043811798000092],
              [-11.80395221699996, 15.044364929000096],
              [-11.814531324999962, 15.046779634000131],
              [-11.821620941999925, 15.045849800000155],
              [-11.827988625999922, 15.039420129000064],
              [-11.832240104999869, 15.037120819000052],
              [-11.837829589999899, 15.037336349000157],
              [-11.837909697999862, 15.037340164],
              [-11.83803272199998, 15.037432671000147],
              [-11.843350410999903, 15.041468619999989],
              [-11.846900939999955, 15.048360825000145],
              [-11.84469318399988, 15.052456855000116],
              [-11.841865538999969, 15.057701111000085],
              [-11.837720871999977, 15.065388681000059],
              [-11.837103842999966, 15.06653595],
              [-11.835286139999937, 15.069917678000024],
              [-11.834650992999968, 15.071100236],
              [-11.834830283999963, 15.074548722000088],
              [-11.834890365999968, 15.075699806999978],
              [-11.835037230999944, 15.075971604000188],
              [-11.837730406999924, 15.080980301000125],
              [-11.842500685999937, 15.083400727000026],
              [-11.842699049999908, 15.083500862000051],
              [-11.843166351999969, 15.084051133000173],
              [-11.846010207999882, 15.087401389999968],
              [-11.847200393999969, 15.091532708000045],
              [-11.846756934999917, 15.092571259000067],
              [-11.843243597999958, 15.100810051],
              [-11.838479994999886, 15.111981392000189],
              [-11.838073730999952, 15.112375260000078],
              [-11.833990096999912, 15.116338731000042],
              [-11.824469565999891, 15.123784065],
              [-11.819579123999915, 15.127609254000049],
              [-11.816495894999889, 15.135146142000167],
              [-11.816289901999824, 15.135649681000132],
              [-11.817001342999959, 15.141388892000123],
              [-11.819841383999972, 15.147362710000039],
              [-11.832151412999906, 15.155840874000035],
              [-11.836171148999881, 15.163418769999964],
              [-11.838081358999943, 15.171911240000099],
              [-11.836669921999942, 15.179949761000046],
              [-11.83572864599995, 15.181878090000168],
              [-11.833189963999871, 15.187081338000098],
              [-11.832188604999885, 15.189141275000111],
              [-11.829011915999956, 15.192913056000179],
              [-11.828411102999894, 15.193627358000128],
              [-11.822509765999939, 15.200630188000105],
              [-11.819188117999886, 15.206102372000032],
              [-11.813300132999871, 15.215800286000103],
              [-11.809568404999936, 15.225277901000084],
              [-11.806699752999918, 15.232561112000099],
              [-11.804819106999901, 15.241978646000177],
              [-11.806970595999928, 15.260351182000136],
              [-11.805330276999939, 15.277571679000062],
              [-11.800746916999969, 15.293077470000014],
              [-11.79939937599994, 15.297638894000158],
              [-11.798057555999947, 15.302174568999988],
              [-11.797321318999934, 15.304671288000065],
              [-11.795228004999956, 15.307831764000071],
              [-11.793304442999954, 15.310738562999973],
              [-11.786218642999927, 15.321440697000185],
              [-11.784130095999956, 15.323599816000183],
              [-11.77109909, 15.337061882000114],
              [-11.756481169999972, 15.394010545000185],
              [-11.7571792599999, 15.404697418000069],
              [-11.757201193999947, 15.405030251000142],
              [-11.752950668999972, 15.411461830000178],
              [-11.749529838999933, 15.442920684000057],
              [-11.747309684999891, 15.456231118000176],
              [-11.742349624999918, 15.46770858799999],
              [-11.741327284999898, 15.46866512299999],
              [-11.738044738999974, 15.471728324000082],
              [-11.736201286999972, 15.4734497070001],
              [-11.733789443999967, 15.474371911000048],
              [-11.725892066999961, 15.477393150000182],
              [-11.72177028599998, 15.478969574000075],
              [-11.720171928999889, 15.480441093000024],
              [-11.717270849999863, 15.483111382000061],
              [-11.714909553999973, 15.487468719000105],
              [-11.714902876999872, 15.487623214],
              [-11.714629173999981, 15.49442005200018],
              [-11.714699744999848, 15.526490213000045],
              [-11.714048385999945, 15.529028892000042],
              [-11.712270735999937, 15.535946846000115],
              [-11.711869239999942, 15.537511827000174],
              [-11.70813846499982, 15.536767007000037],
              [-11.705260276999979, 15.536191940000151],
              [-11.704999922999946, 15.536140442000146],
              [-11.704297064999935, 15.533954620000088],
              [-11.703819274999944, 15.532469750000189],
              [-11.703657149999913, 15.532468796000103],
              [-11.70269966199993, 15.532461167000065],
              [-11.691877364999982, 15.532363893000024],
              [-11.67920017299997, 15.532249450000052],
              [-11.678140638999878, 15.532666206999977],
              [-11.673472404999927, 15.534502983000095],
              [-11.669260977999954, 15.536160469000095],
              [-11.664139747999968, 15.531649589999972],
              [-11.653618811999877, 15.522390366000025],
              [-11.653431890999968, 15.522349359000088],
              [-11.646280288999947, 15.520792008000114],
              [-11.639797209999927, 15.521295547000136],
              [-11.632585524999968, 15.521856308000054],
              [-11.62852954899995, 15.522171019999973],
              [-11.624642371999926, 15.525747300000148],
              [-11.620841978999977, 15.529240609000055],
              [-11.619539260999943, 15.530440330000147],
              [-11.618988036999895, 15.53039932300004],
              [-11.6107969279999, 15.529794693000099],
              [-11.609396934999893, 15.529691695999986],
              [-11.600838661999944, 15.529061318000061],
              [-11.595389365999893, 15.531132698000022],
              [-11.587820053999963, 15.532508850000056],
              [-11.577160834999972, 15.537560464000023],
              [-11.571249007999938, 15.542610168000067],
              [-11.5695858, 15.548164368000073],
              [-11.569451330999982, 15.548613548999981],
              [-11.569120407999947, 15.549718856000084],
              [-11.569824217999894, 15.55118847000017],
              [-11.57219886699994, 15.556151390000082],
              [-11.569828985999948, 15.570841789000099],
              [-11.568864822999956, 15.573092460999987],
              [-11.568380355999977, 15.574226380000141],
              [-11.567469597999946, 15.576351167000155],
              [-11.565814972999931, 15.576788902000033],
              [-11.56120586399993, 15.578007699000068],
              [-11.559650421999947, 15.578419686000132],
              [-11.552261352999892, 15.583340643999975],
              [-11.546389579999982, 15.588568687000134],
              [-11.537535667999975, 15.596240997000052],
              [-11.527722357999892, 15.604743957000153],
              [-11.525789259999954, 15.606418611000151],
              [-11.524753570999962, 15.606648446000065],
              [-11.517511366999884, 15.608248711000044],
              [-11.516167640999981, 15.609177590000058],
              [-11.504485128999875, 15.617256164000082],
              [-11.502309799999921, 15.618761064000068],
              [-11.502949714999943, 15.622409820000144],
              [-11.50475597399992, 15.625849724000034],
              [-11.509301184999856, 15.634506227000145],
              [-11.510051726999961, 15.635934829],
              [-11.511019707999935, 15.637780190000058],
              [-11.505105017999938, 15.640257836000046],
              [-11.504527090999886, 15.64050006799999],
              [-11.498941421999973, 15.642840385000113],
              [-11.481249809999952, 15.643480300000022],
              [-11.428818702999934, 15.645389556999987],
              [-11.42218017499988, 15.641030312],
              [-11.421610832999932, 15.640466691000029],
              [-11.414253235999922, 15.633185387000083],
              [-11.411511419999897, 15.630471230000126],
              [-11.40247631099993, 15.614100456000074],
              [-11.398469923999812, 15.60684108800001],
              [-11.395645141999921, 15.602944375000106],
              [-11.386151313999903, 15.589850427000101],
              [-11.382411003999891, 15.586071015000016],
              [-11.379907608999872, 15.583533288000126],
              [-11.377335547999962, 15.580924988000163],
              [-11.370981215999905, 15.574481964000142],
              [-11.368611334999969, 15.568970681],
              [-11.368492126999854, 15.555845261000059],
              [-11.368482588999939, 15.554877281000131],
              [-11.368475912999941, 15.554097176000141],
              [-11.368421555999873, 15.548122406000118],
              [-11.368393896999976, 15.545085907000157],
              [-11.368369102999964, 15.542341233000059],
              [-11.36457824799993, 15.531330109000123],
              [-11.362681388999931, 15.525819779000187],
              [-11.361148834999938, 15.524970054000107],
              [-11.328570365999951, 15.506888388999982],
              [-11.328338622999922, 15.50675869100013],
              [-11.31862926499997, 15.497348785000099],
              [-11.317658424999934, 15.495888711000077],
              [-11.313891410999872, 15.490228654000134],
              [-11.291158675999952, 15.45465183400006],
              [-11.28240013199985, 15.445240975000104],
              [-11.280945776999943, 15.44397068100011],
              [-11.253748894999944, 15.420211793000021],
              [-11.252695082999878, 15.419462205000173],
              [-11.240501403999872, 15.410790444000042],
              [-11.232351301999927, 15.40798187200005],
              [-11.210438727999929, 15.40044021600005],
              [-11.200260162999882, 15.394241333000082],
              [-11.177079199999923, 15.372868539000081],
              [-11.162031173999878, 15.362661361000164],
              [-11.15429592199996, 15.35741329200016],
              [-11.142530440999963, 15.349431037999977],
              [-11.13875103099997, 15.348509788000172],
              [-11.125089645999822, 15.3390197760001],
              [-11.074649809999869, 15.303910255000062],
              [-11.060460089999879, 15.29104232800006],
              [-11.044630050999899, 15.268749238000055],
              [-11.043172836999929, 15.267583848000129],
              [-11.040778159999945, 15.265667916000041],
              [-11.03257083799997, 15.25910186800013],
              [-11.028349875999879, 15.256409645000076],
              [-11.0148410799999, 15.247819900000138],
              [-11.010351181999965, 15.247129440000037],
              [-11.00096988699994, 15.240012169000124],
              [-10.998529434999966, 15.238161087000037],
              [-10.990336418999959, 15.224893570000177],
              [-10.986007690999884, 15.217886925000187],
              [-10.968298911999966, 15.189211845999978],
              [-10.96060085299996, 15.181453705000138],
              [-10.95647716499991, 15.177297592000059],
              [-10.954840660999935, 15.17564869000006],
              [-10.948533057999953, 15.171709061000172],
              [-10.945695876999935, 15.169937135000112],
              [-10.945618628999966, 15.169890403000124],
              [-10.945917129999941, 15.166357040000094],
              [-10.946041107999918, 15.164879798000072],
              [-10.94535636899991, 15.159464837000144],
              [-10.944890021999981, 15.155783654000118],
              [-10.94472789699995, 15.154502869000055],
              [-10.944688796999912, 15.15419960000014],
              [-10.942315100999906, 15.152577401000087],
              [-10.935343741999873, 15.147813798000072],
              [-10.931019781999964, 15.144859315000133],
              [-10.928170203999912, 15.14291095800013],
              [-10.926119804999814, 15.13884067500004],
              [-10.925939558999914, 15.138485909000167],
              [-10.924403189999964, 15.135435106000102],
              [-10.920869826999933, 15.128422737000108],
              [-10.919555664999905, 15.127199173000122],
              [-10.915680885999848, 15.12359142300005],
              [-10.913968085999954, 15.119527818],
              [-10.912389753999946, 15.115782737000188],
              [-10.911970138999948, 15.115372658000126],
              [-10.909287452999934, 15.112749100000087],
              [-10.905320167999946, 15.108869553000034],
              [-10.902919769999926, 15.103618622000113],
              [-10.895519255999943, 15.106101035999984],
              [-10.894143103999909, 15.108711243000073],
              [-10.88517189099997, 15.125728608000031],
              [-10.879599571999904, 15.130351067000163],
              [-10.879549026999882, 15.140549660000147],
              [-10.876608847999876, 15.14974880200009],
              [-10.875949858999888, 15.155887604000156],
              [-10.875581741999895, 15.159312249000095],
              [-10.873571394999942, 15.178030967000097],
              [-10.873737334999873, 15.183193206000112],
              [-10.873929023999949, 15.189149858000178],
              [-10.871858596999971, 15.191180230000157],
              [-10.863122939999869, 15.199744224000142],
              [-10.861510276999923, 15.20132637100005],
              [-10.856618880999974, 15.206118583000091],
              [-10.842889784999898, 15.209790230000181],
              [-10.839111327999831, 15.21410942000017],
              [-10.835081100999957, 15.217781067000089],
              [-10.824838638999893, 15.24392986200013],
              [-10.829269408999949, 15.268969535999986],
              [-10.822870253999952, 15.27446079300006],
              [-10.805818557999942, 15.295681955000134],
              [-10.800009727999907, 15.302908898000112],
              [-10.798191069999973, 15.305157661000067],
              [-10.794879912999932, 15.3058395380001],
              [-10.787561416999949, 15.302841188000173],
              [-10.781778335999945, 15.303200722000099],
              [-10.780459403999942, 15.303282738000121],
              [-10.779799460999982, 15.304113388000076],
              [-10.778088570999898, 15.306262016000062],
              [-10.776869773999977, 15.318651199000101],
              [-10.775890349999941, 15.319931984000107],
              [-10.761699675999864, 15.330089570000155],
              [-10.749859809999975, 15.332578659000148],
              [-10.747501372999977, 15.330969811000102],
              [-10.743241309999917, 15.332109452000111],
              [-10.726890563999916, 15.349188805000097],
              [-10.726252556999896, 15.351829528],
              [-10.726195335999932, 15.352070809000168],
              [-10.724200247999818, 15.360339165000028],
              [-10.723988532999954, 15.36121177800004],
              [-10.723861694999869, 15.361343384000122],
              [-10.717820166999957, 15.367620468000041],
              [-10.718303679999963, 15.370494843000131],
              [-10.718747139999891, 15.37313079900008],
              [-10.720411300999956, 15.373820306000027],
              [-10.720462797999915, 15.377756118000093],
              [-10.720587730999966, 15.38713836800008],
              [-10.720600128999934, 15.388059616000135],
              [-10.722249983999916, 15.395010948999982],
              [-10.72362041599996, 15.400678635000133],
              [-10.723660469999913, 15.40075397600009],
              [-10.72646903999987, 15.405981064000059],
              [-10.727547644999959, 15.412541391000047],
              [-10.728645323999899, 15.419214249000106],
              [-10.729491233999966, 15.424360275000083],
              [-10.728638648999947, 15.429110527000034],
              [-10.728149412999812, 15.43184471100011],
              [-10.728051185999959, 15.432391166000116],
              [-10.727763174999893, 15.432706833000054],
              [-10.724230766999938, 15.436579705000042],
              [-10.713191031999827, 15.438440322000076],
              [-10.693811416999949, 15.4335899350001],
              [-10.689457892999883, 15.433568954000066],
              [-10.687650679999933, 15.433560371000112],
              [-10.68667220999987, 15.432782174000124],
              [-10.680079460999934, 15.427540778999969],
              [-10.669650076999972, 15.414118767000048],
              [-10.668451307999874, 15.411993027000165],
              [-10.664280890999976, 15.404599190999988],
              [-10.655750274999889, 15.405948639000087],
              [-10.645039558999883, 15.413080215999969],
              [-10.642909048999911, 15.412850380000123],
              [-10.633191108999938, 15.420161248],
              [-10.62584972399992, 15.422201156000028],
              [-10.596740722999925, 15.421422005000181],
              [-10.590600967999933, 15.42418193900005],
              [-10.587678908999919, 15.427331924000043],
              [-10.584641455999872, 15.427719116999981],
              [-10.56939983399991, 15.429670334000036],
              [-10.56252193399996, 15.432630540000048],
              [-10.548789977999945, 15.434047699000132],
              [-10.530079841999964, 15.435980797000013],
              [-10.507580756999971, 15.440051080000103],
              [-10.500009536999869, 15.439109803000122],
              [-10.494798660999948, 15.440470695000101],
              [-10.4699411389999, 15.440388680000126],
              [-10.459521292999909, 15.437730789000057],
              [-10.458109856999897, 15.437371254000084],
              [-10.418581008999922, 15.438619613000071],
              [-10.406611442999974, 15.436630249000132],
              [-10.394100189999961, 15.439261438000131],
              [-10.383701324999834, 15.437150001000134],
              [-10.38228988599991, 15.434619903000055],
              [-10.370710373999827, 15.431820869000092],
              [-10.34798908199997, 15.431269646000032],
              [-10.33638954099996, 15.433512688000178],
              [-10.322671889999981, 15.4314689630001],
              [-10.320601462999889, 15.431160928000111],
              [-10.267869949999977, 15.413511276000122],
              [-10.256508826999891, 15.413240433000169],
              [-10.252738952999891, 15.409779549000177],
              [-10.239259718999904, 15.406050682000114],
              [-10.232878684999946, 15.402810097000099],
              [-10.224128722999865, 15.401630402000137],
              [-10.213259695999909, 15.397260667000126],
              [-10.18206977899996, 15.384697914000128],
              [-10.131488798999953, 15.367038726000089],
              [-10.089839934999929, 15.358461379000119],
              [-10.088219641999899, 15.358120918000111],
              [-10.039468764999981, 15.35859108000011],
              [-9.999999046999903, 15.356639861000019],
              [-9.998298644999977, 15.35655975300017],
              [-9.990484236999919, 15.35789489800004],
              [-9.989533424999877, 15.358057975000122],
              [-9.968877791999944, 15.361581803000036],
              [-9.96561431899994, 15.36213874900011],
              [-9.960488318999978, 15.363389970000128],
              [-9.949425697999914, 15.366097450000154],
              [-9.947312355999941, 15.366615296000191],
              [-9.934334754999895, 15.369790077000062],
              [-9.930908202999888, 15.369888307000053],
              [-9.927706717999968, 15.369978904000163],
              [-9.926204681999934, 15.371094704000086],
              [-9.922480582999981, 15.373861313000134],
              [-9.919520378999948, 15.373170853000033],
              [-9.912364005999962, 15.371505738],
              [-9.90026187899997, 15.368688583000051],
              [-9.895130157999972, 15.366930008000111],
              [-9.89247989699993, 15.366022111000063],
              [-9.883958816999893, 15.363101006000022],
              [-9.862914085999933, 15.359768868],
              [-9.862373350999974, 15.359788894000076],
              [-9.841147423999928, 15.360596657000031],
              [-9.834474563999891, 15.360851288000049],
              [-9.830958365999948, 15.360980987000062],
              [-9.829232215999923, 15.361334801],
              [-9.827983856999936, 15.361591339000086],
              [-9.811292647999949, 15.365011215999971],
              [-9.810747146999972, 15.36530971500008],
              [-9.806497572999945, 15.367631912000093],
              [-9.801324844999954, 15.370459556000128],
              [-9.801111220999928, 15.370520592000048],
              [-9.800392150999869, 15.370726586000046],
              [-9.793499945999884, 15.37271022900012],
              [-9.76712608299988, 15.390929223000114],
              [-9.756678580999903, 15.400779725000177],
              [-9.751680373999818, 15.408469200000013],
              [-9.731226920999916, 15.403651237000076],
              [-9.716089248999936, 15.402870178000057],
              [-9.712429046999944, 15.404821395999988],
              [-9.706681250999907, 15.410080910000147],
              [-9.706090927999981, 15.416671754000163],
              [-9.703969000999848, 15.425000192000027],
              [-9.697562217999973, 15.427491189000136],
              [-9.583213806999879, 15.430210114000147],
              [-9.559059142999899, 15.430780411000171],
              [-9.549579620999907, 15.432551384000078],
              [-9.542250632999981, 15.430900574000077],
              [-9.487085342999933, 15.433387757000048],
              [-9.475474357999872, 15.433911324000121],
              [-9.464117048999867, 15.432910920000154],
              [-9.453574179999976, 15.433191300999965],
              [-9.433889389999933, 15.433702470000071],
              [-9.408240317999912, 15.434371949000138],
              [-9.408263206999948, 15.434747696000045],
              [-9.408665657999961, 15.441259384000091],
              [-9.419196128999943, 15.489541054000085],
              [-9.420303345999969, 15.494613647000108],
              [-9.423659324999903, 15.510003090000055],
              [-9.42424583299993, 15.512694359000079],
              [-9.424737930999982, 15.514950752000175],
              [-9.432198523999887, 15.548975944000063],
              [-9.432776450999938, 15.55160904000013],
              [-9.43837165799988, 15.577130318000059],
              [-9.438020705999975, 15.578201294],
              [-9.437364577999972, 15.582740784000123],
              [-9.439678191999974, 15.589870453000174],
              [-9.441355704999978, 15.591180802000167],
              [-9.442871093999941, 15.597580909000044],
              [-9.439641951999931, 15.599466325000094],
              [-9.437879561999921, 15.600495338000087],
              [-9.419562338999867, 15.611191749],
              [-9.410124778999887, 15.620363236000117],
              [-9.402132986999959, 15.628129006000165],
              [-9.389898300999846, 15.640019418],
              [-9.378877639999871, 15.650728227000116],
              [-9.370579718999977, 15.658791542000188],
              [-9.364699362999943, 15.664507865000189],
              [-9.357619285999931, 15.670528412000181],
              [-9.350760459999947, 15.676361083000018],
              [-9.332036971999912, 15.692278861999966],
              [-9.327399254999875, 15.688055039000176],
              [-9.312734602999967, 15.674699783000051],
              [-9.311137199999962, 15.666198730000019],
              [-9.319918631999826, 15.631829262000053],
              [-9.324911117999875, 15.6066522590001],
              [-9.325283050999929, 15.604778289000137],
              [-9.32621574399991, 15.600071908000018],
              [-9.332461356999943, 15.568578720000176],
              [-9.334675788999959, 15.524291040000094],
              [-9.334035872999948, 15.514650345000064],
              [-9.328486442999974, 15.496250153000176],
              [-9.306881903999965, 15.496212006000121],
              [-9.200626373999967, 15.495990754000104],
              [-9.176713943999914, 15.496299743000179],
              [-9.142374039999936, 15.496747017000075],
              [-9.134963989999903, 15.496844291000116],
              [-9.134317397999894, 15.49685192100003],
              [-9.129612921999922, 15.497438431000035],
              [-9.12388801599991, 15.498150825000039],
              [-9.120405197999958, 15.497442246000105],
              [-9.119160651999948, 15.497188566999966],
              [-9.046734810999908, 15.497371673000032],
              [-9.00081920699995, 15.497471810000093],
              [-8.987504004999948, 15.497499467000125],
              [-8.970939636999958, 15.496441842000138],
              [-8.968479155999944, 15.497515678000127],
              [-8.967848776999972, 15.497791291000112],
              [-8.955520628999921, 15.497522355000058],
              [-8.916913031999968, 15.496681214000034],
              [-8.89872074199991, 15.496279717000107],
              [-8.875118255999951, 15.497561455000096],
              [-8.862238882999975, 15.498261452],
              [-8.78680419799997, 15.498060226000064],
              [-8.656633375999888, 15.497670175000053],
              [-8.655821799999899, 15.497668267000051],
              [-8.60769557999987, 15.49751091000013],
              [-8.607058524999957, 15.497691156000087],
              [-8.602232933999972, 15.499060630000088],
              [-8.594291686999895, 15.49769687600002],
              [-8.593722342999968, 15.49759960200015],
              [-8.531698226999936, 15.496299743000179],
              [-8.526896476999923, 15.497009277000075],
              [-8.522023200999968, 15.497732163000023],
              [-8.520076751999852, 15.498021126000026],
              [-8.431281088999981, 15.497809411000048],
              [-8.429968833999908, 15.49777698500003],
              [-8.414949417999935, 15.497407913000188],
              [-8.414956092999944, 15.496489525000072],
              [-8.401212691999945, 15.497489929000039],
              [-8.397808074999887, 15.49740123700019],
              [-8.387482643999931, 15.497120857000084],
              [-8.386263846999952, 15.495000840000102],
              [-8.35889625599998, 15.497699738999984],
              [-8.358588219999888, 15.497729302000039],
              [-8.305079459999945, 15.497561455000096],
              [-8.26695346799994, 15.497440339000036],
              [-8.263901709999857, 15.497857094000153],
              [-8.256341933999977, 15.498891830000161],
              [-8.249410628999897, 15.497864724000067],
              [-8.244932173999871, 15.497200966000037],
              [-8.126317022999899, 15.497691156000087],
              [-8.11066436699997, 15.497758866000027],
              [-8.107845306999934, 15.49588966400006],
              [-8.09645557399989, 15.4975900660001],
              [-8.067107200999885, 15.496560098000145],
              [-8.058805464999921, 15.497850418000155],
              [-8.01315784499991, 15.497782708000045],
              [-7.997001170999965, 15.497758866000027],
              [-7.992267131999881, 15.49775028300013],
              [-7.984470843999816, 15.496051789000092],
              [-7.972371101999897, 15.498201370000061],
              [-7.904419897999958, 15.497151375000101],
              [-7.867238997999891, 15.497699738999984],
              [-7.785052776999919, 15.498891830000161],
              [-7.737079143999949, 15.498682022000025],
              [-7.675896166999962, 15.498411179000072],
              [-7.667354106999937, 15.499679566000111],
              [-7.663031100999831, 15.499418259000095],
              [-7.659058092999885, 15.499176980000129],
              [-7.647952079999811, 15.498501779000094],
              [-7.643465994999872, 15.499047279000138],
              [-7.639411926999912, 15.499541283000156],
              [-7.607109070999968, 15.499480247000065],
              [-7.47693920099988, 15.499199867000129],
              [-7.346767901999954, 15.49887084900007],
              [-7.294875144999878, 15.498709679000058],
              [-7.281257151999967, 15.498630525000124],
              [-7.216598033999958, 15.498261452],
              [-7.162271975999886, 15.497929574000125],
              [-7.152142048999906, 15.494798660000072],
              [-7.143134116999875, 15.495351791000076],
              [-7.139070986999968, 15.497820854000167],
              [-7.125276087999964, 15.497775077000028],
              [-7.087680815999875, 15.497650146000069],
              [-6.95751285599988, 15.497179985000173],
              [-6.903460978999931, 15.49697113000002],
              [-6.882133006999879, 15.497278214000062],
              [-6.827351091999958, 15.498068809000188],
              [-6.752337931999932, 15.499139786000057],
              [-6.730093956999951, 15.498089790000051],
              [-6.718235015999881, 15.499281882999981],
              [-6.69728994299993, 15.498550416000114],
              [-6.668531894999887, 15.497540474000061],
              [-6.578073023999878, 15.497407913000188],
              [-6.572178839999879, 15.495928765000031],
              [-6.569547176999947, 15.497489929000039],
              [-6.567787170999964, 15.497481346000143],
              [-6.455656051999938, 15.496471406000069],
              [-6.453391075999946, 15.497654914000123],
              [-6.45183181799996, 15.498470307000161],
              [-6.450422763999882, 15.497653962000015],
              [-6.448552131999918, 15.496568681000042],
              [-6.438681126999882, 15.496521951000091],
              [-6.404701231999979, 15.49634933499999],
              [-6.392901896999888, 15.496289252999986],
              [-6.380140780999909, 15.494921684000076],
              [-6.373478888999898, 15.496640206],
              [-6.326490879999938, 15.496306419000177],
              [-6.319890022999914, 15.496258736000073],
              [-6.308823108999945, 15.496179580000103],
              [-6.307116984999936, 15.496167182000136],
              [-6.250948904999916, 15.495753288000174],
              [-6.178657054999917, 15.49522018499999],
              [-6.157293795999976, 15.495052337000175],
              [-6.153995990999874, 15.49407100600007],
              [-6.150803088999908, 15.494326591000174],
              [-6.149952888999906, 15.494395256000075],
              [-6.137631892999934, 15.495380402000023],
              [-6.112553119999973, 15.494231223999975],
              [-6.076766014999919, 15.495630264000113],
              [-6.048757074999969, 15.495107650000023],
              [-6.006453990999944, 15.494311333000155],
              [-5.998616217999881, 15.495538711999984],
              [-5.998631953999961, 15.494629860000089],
              [-5.919662952999829, 15.495320320000019],
              [-5.808906077999893, 15.496258736000073],
              [-5.789537905999907, 15.497481346000143],
              [-5.772861003999935, 15.49852943500008],
              [-5.688292026999932, 15.499609948000057],
              [-5.659410000999912, 15.499541283000156],
              [-5.597115038999903, 15.499382020000041],
              [-5.58558511699988, 15.500300408000157],
              [-5.553307055999937, 15.500631333000115],
              [-5.541172979999885, 15.501679420000016],
              [-5.514934062999942, 15.501920701000188],
              [-5.510067939999942, 15.505410195000081],
              [-5.507554054999844, 15.507177353000088],
              [-5.506422996999959, 15.507969857000148],
              [-5.50633192, 15.508371354000019],
              [-5.497056007999959, 15.549361229000056],
              [-5.469754218999981, 15.672741891000044],
              [-5.451056002999906, 15.75714969600017],
              [-5.450138091999975, 15.766770362000045],
              [-5.446868895999955, 15.775202752000155],
              [-5.442327022999962, 15.795910835000143],
              [-5.415248871999836, 15.919301031999964],
              [-5.397523878999891, 16.000001908000058],
              [-5.327115059999926, 16.333139419000133],
              [-5.602798937999978, 16.499429704000022],
              [-5.6439371109999, 16.881320954000046],
              [-5.644299984999918, 16.884897232000185],
              [-5.645874976999949, 16.900451661000091],
              [-5.648615836999966, 16.916530609000176],
              [-5.64860296199987, 16.922649383000135],
              [-5.652489185999912, 16.957090376999986],
              [-5.656396866999955, 16.984649659000127],
              [-5.658768177999946, 16.99077034000004],
              [-5.659546851999949, 16.996902465000176],
              [-5.65954017699994, 17.000719071000105],
              [-5.65833902299994, 17.003780365000125],
              [-5.666504860999964, 17.079559325999981],
              [-5.668829916999869, 17.105579376000151],
              [-5.675078869999879, 17.155719756999986],
              [-5.675065993999908, 17.162229538000133],
              [-5.677008152999918, 17.182132720000084],
              [-5.678271769999924, 17.189523697000084],
              [-5.67897176699995, 17.193609237999965],
              [-5.678956031999974, 17.200120926000181],
              [-5.682871817999967, 17.22883033800008],
              [-5.682859896999958, 17.234180450999986],
              [-5.68636608099996, 17.268629073000113],
              [-5.68754386899991, 17.27552032599999],
              [-5.688310146999811, 17.289300919000084],
              [-5.691452979999951, 17.30997085600012],
              [-5.691433905999872, 17.317239761000167],
              [-5.695344924999915, 17.349779128000137],
              [-5.69728517599998, 17.373510360000068],
              [-5.699254034999967, 17.385000229000127],
              [-5.710256100999914, 17.492942810000102],
              [-5.710534095999833, 17.495679855000162],
              [-5.715206145999957, 17.532150269000056],
              [-5.717267989999925, 17.538129807000132],
              [-5.717246055999965, 17.548477174000141],
              [-5.717246055999965, 17.549280167000063],
              [-5.724304198999903, 17.60932731600019],
              [-5.725077152999916, 17.622350693000101],
              [-5.726661204999971, 17.627708435000045],
              [-5.729014872999926, 17.648380280000083],
              [-5.729813099999888, 17.649152757000138],
              [-5.730578897999919, 17.665231705000167],
              [-5.732933043999935, 17.685899734000088],
              [-5.733705996999959, 17.698919296000156],
              [-5.735957144999873, 17.705429077000076],
              [-5.742972850999934, 17.767019272000141],
              [-5.743955134999908, 17.769369125000139],
              [-5.743134021999936, 17.778560638000101],
              [-5.743524074999868, 17.784679413000049],
              [-5.745097160999933, 17.79846954400017],
              [-5.747084140999903, 17.80382919200008],
              [-5.7470541, 17.819139480000104],
              [-5.749011993999943, 17.839822769000023],
              [-5.750995159999945, 17.849010467000141],
              [-5.758062838999877, 17.917169571000045],
              [-5.759655952999879, 17.920999528000152],
              [-5.759642124999971, 17.929420472],
              [-5.7612190239999, 17.941289901000061],
              [-5.761993884999981, 17.955070497],
              [-5.764387129999932, 17.959289552000143],
              [-5.764747141999976, 17.98148727500012],
              [-5.766324995999923, 17.99527931300014],
              [-5.765910148999978, 18.002170564000039],
              [-5.765511034999918, 18.002550123999981],
              [-5.767500877999964, 18.008300781],
              [-5.769086837999851, 18.015190124000071],
              [-5.771050929999888, 18.035871506000092],
              [-5.780519007999942, 18.117822648000072],
              [-5.781298159999892, 18.130830765000155],
              [-5.78573083799995, 18.166070939000065],
              [-5.786811827999941, 18.185600282000053],
              [-5.79470205299998, 18.261419297000089],
              [-5.798264026999902, 18.288988114000176],
              [-5.798654079999949, 18.295890808000024],
              [-5.801438808999933, 18.309289933000173],
              [-5.801427840999963, 18.315420150000136],
              [-5.803791045999958, 18.342990875000055],
              [-5.807357787999933, 18.370569229000182],
              [-5.808144092999896, 18.38205909800007],
              [-5.810527801999967, 18.395458221000183],
              [-5.814097881999942, 18.423030853000171],
              [-5.825583934999941, 18.526060105000113],
              [-5.82757186799995, 18.539848329000165],
              [-5.828355789999876, 18.553630830000031],
              [-5.831139087999873, 18.574319839000054],
              [-5.834301948999894, 18.608787537000183],
              [-5.835495948999949, 18.615680695000094],
              [-5.84383296999988, 18.691509248000045],
              [-5.847414015999959, 18.719089508000138],
              [-5.849016189999929, 18.725219728000184],
              [-5.849812029999953, 18.732109071000082],
              [-5.849792956999977, 18.745130539000115],
              [-5.856551170999978, 18.807569504000071],
              [-5.857748031999961, 18.814460755000141],
              [-5.858936785999902, 18.828250886000092],
              [-5.861729145999959, 18.848930360000168],
              [-5.862514972999975, 18.861949920000029],
              [-5.864653110999939, 18.870758058000149],
              [-5.865704059999928, 18.889152527000022],
              [-5.876328944999955, 18.984882355000025],
              [-5.876459121999972, 18.986049653000123],
              [-5.876849174999961, 18.997919084000159],
              [-5.87885904399991, 19.000989915000105],
              [-5.878444194999872, 19.011709213000131],
              [-5.88164186399996, 19.032400132000134],
              [-5.884431839999877, 19.059980392000057],
              [-5.885632990999966, 19.066869735000125],
              [-5.886028766999914, 19.07375908],
              [-5.887229918999935, 19.080661774000077],
              [-5.887627124999938, 19.087547302000075],
              [-5.890028952999955, 19.101350785000079],
              [-5.896028040999966, 19.145780563000073],
              [-5.896018982999976, 19.156499862000146],
              [-5.897625922999907, 19.163400651000075],
              [-5.905210970999974, 19.239240647000145],
              [-5.906414032999976, 19.246129990000043],
              [-5.910416124999927, 19.280609131000119],
              [-5.912415026999895, 19.301290512000037],
              [-5.912805079999941, 19.315080642999988],
              [-5.915214061999961, 19.328870774000166],
              [-5.916810989999931, 19.349550248000185],
              [-5.924028872999941, 19.403940202],
              [-5.9300389299999, 19.459480285000097],
              [-5.932453154999905, 19.472509385000023],
              [-5.934854984999959, 19.499700547000089],
              [-5.936062811999875, 19.506599426000093],
              [-5.93848085299993, 19.512729645000036],
              [-5.937260150999919, 19.52613067600015],
              [-5.938867090999906, 19.539920807000101],
              [-5.93765211099992, 19.541830064000067],
              [-5.940874099999974, 19.557159424000133],
              [-5.948609829999953, 19.619689942000093],
              [-5.949719906999917, 19.620740891000082],
              [-5.949637889999906, 19.621822357000042],
              [-5.948976993999906, 19.630529405000175],
              [-5.951316833999897, 19.650997162000181],
              [-5.952930926999954, 19.658279419999985],
              [-5.952927111999941, 19.665939332000164],
              [-5.956141947999981, 19.693519592000087],
              [-5.956951140999877, 19.695810319000145],
              [-5.956944942999883, 19.704240799000104],
              [-5.960165023999878, 19.731819154000164],
              [-5.960166930999947, 19.72492027300018],
              [-5.964994907999937, 19.773181915000123],
              [-5.966609955999957, 19.780082703000176],
              [-5.96741676399995, 19.786207200000035],
              [-5.967412948999879, 19.792339325000171],
              [-5.969026088999954, 19.806129456000122],
              [-5.972911833999945, 19.828269959000181],
              [-5.974678993999874, 19.832170486000052],
              [-5.97265005099996, 19.839069367000036],
              [-5.974264144999893, 19.852849960000071],
              [-5.97628307399998, 19.858980180000117],
              [-5.983541964999915, 19.923330308000061],
              [-5.983534813999938, 19.934440613000163],
              [-5.985557078999932, 19.947839737000152],
              [-5.987165926999978, 19.968530656000155],
              [-5.989593027999831, 19.982320785000127],
              [-5.991403102999925, 19.999982835000083],
              [-5.991726874999927, 20.000000001000103],
              [-5.994843958999979, 20.018320084000038],
              [-5.994843958999979, 20.024450302000105],
              [-5.996459961999904, 20.031341552000072],
              [-5.998083114999929, 20.035169601000177],
              [-5.996459961999904, 20.041299821000052],
              [-5.996456146999947, 20.048189164000178],
              [-5.998075962999963, 20.054319381000141],
              [-5.998072147999892, 20.061220169000023],
              [-5.999210833999882, 20.066070557000046],
              [-6.000926970999956, 20.095409394000058],
              [-6.004547118999881, 20.099519729000065],
              [-6.001429079999923, 20.104419709000126],
              [-6.003580092999926, 20.122779847000118],
              [-6.001859187999969, 20.126026154000044],
              [-5.999669074999872, 20.130159378000087],
              [-6.003315925999971, 20.138969422],
              [-6.004528044999915, 20.154289246000133],
              [-6.022188187999973, 20.281990052000026],
              [-6.026401042999964, 20.312461853],
              [-6.026240824999945, 20.314874650000093],
              [-6.025992870999914, 20.318588257000101],
              [-6.027921198999877, 20.335018159000072],
              [-6.050353049999956, 20.526159286],
              [-6.054821966999953, 20.560630798999966],
              [-6.055962084999976, 20.579929353000182],
              [-6.058889864999912, 20.588199616000111],
              [-6.060514925999939, 20.60199165400013],
              [-6.059703825999975, 20.608877182000185],
              [-6.06051778899996, 20.615770340000154],
              [-6.062959192999927, 20.622669221000137],
              [-6.063775061999934, 20.629560471000104],
              [-6.063366889999884, 20.643350602000055],
              [-6.067030906999946, 20.670917512000074],
              [-6.069067001999883, 20.691602706000083],
              [-6.07191801099998, 20.705379487000187],
              [-6.072328089999814, 20.719169618000137],
              [-6.074363231999882, 20.732950211000173],
              [-6.07558488799998, 20.736400605000028],
              [-6.077217101999963, 20.760520936000091],
              [-6.079257011999971, 20.774309159000097],
              [-6.080883980999943, 20.781200410000167],
              [-6.084966181999903, 20.82255745000009],
              [-6.088634968999884, 20.850128174000076],
              [-6.091493129999947, 20.863910675000113],
              [-6.093943119999892, 20.891479492000087],
              [-6.093288898999958, 20.891479492000087],
              [-6.097620009999901, 20.929010391000077],
              [-6.09966182799991, 20.941259385000137],
              [-6.115202903999887, 21.072969437000097],
              [-6.116837024999938, 21.079860687000064],
              [-6.1176581389999, 21.086748123000064],
              [-6.117067813999938, 21.0917301180001],
              [-6.124896049999961, 21.157579422000026],
              [-6.126258849999942, 21.162937165000073],
              [-6.126674175999938, 21.176729202000161],
              [-6.12790489199989, 21.190509797000061],
              [-6.129951954999967, 21.203910828000176],
              [-6.132822989999966, 21.231470109000043],
              [-6.135690211999929, 21.24525261000008],
              [-6.135289192999892, 21.258651732999965],
              [-6.142268179999917, 21.3206672660001],
              [-6.147191047999911, 21.355129241000157],
              [-6.148015975999954, 21.368911744],
              [-6.151710033999905, 21.396469116000162],
              [-6.159114836999947, 21.465370179000104],
              [-6.161581039999874, 21.479150773000072],
              [-6.163225173999933, 21.492929458000162],
              [-6.164052009999978, 21.506708146000165],
              [-6.166102885999976, 21.513221741000052],
              [-6.170218943999942, 21.547670364000112],
              [-6.179697990999898, 21.630718232000049],
              [-6.181353090999949, 21.651388169000086],
              [-6.187533855999959, 21.699230195000098],
              [-6.187541007999926, 21.706119538],
              [-6.194560049999893, 21.768510819000085],
              [-6.197031020999873, 21.782279969],
              [-6.199925898999879, 21.809448243000134],
              [-6.201570987999901, 21.8163394930001],
              [-6.20157814099997, 21.822460175000117],
              [-6.205296992999934, 21.853458405000026],
              [-6.206530093999902, 21.859201431000031],
              [-6.206536768999911, 21.864559175000124],
              [-6.209846973999902, 21.892110824000042],
              [-6.212333202999901, 21.91966056900003],
              [-6.214810848999832, 21.935737610000047],
              [-6.215231895999921, 21.946830750000061],
              [-6.216886995999914, 21.960607528000025],
              [-6.21853494599992, 21.967489243000045],
              [-6.219370841999933, 21.980890274000103],
              [-6.222370147999925, 22.002410890000135],
              [-6.221445084999857, 22.001550673999986],
              [-6.224330901999963, 22.014940262000152],
              [-6.225173949999942, 22.035217285000158],
              [-6.239685058999953, 22.15611839200011],
              [-6.239706039999874, 22.174488068000187],
              [-6.242795944999955, 22.181179047000171],
              [-6.248410225999919, 22.234939576000102],
              [-6.24964904899997, 22.24181938300012],
              [-6.250473974999977, 22.24181938300012],
              [-6.250483989999907, 22.250999451000098],
              [-6.252976894999961, 22.271659851000152],
              [-6.252573965999943, 22.277780533000112],
              [-6.255465983999955, 22.288488388000133],
              [-6.258581161999928, 22.312780381000039],
              [-6.257145881999918, 22.316799164000088],
              [-6.2610249519999, 22.33115959200012],
              [-6.267960071999937, 22.404779434000147],
              [-6.270102024999972, 22.411720277000029],
              [-6.271287917999871, 22.431547166000144],
              [-6.273776053999939, 22.445318222000026],
              [-6.275452137999878, 22.46598243700015],
              [-6.281363964999912, 22.506517411000061],
              [-6.287199973999975, 22.554710388000103],
              [-6.287939070999926, 22.5577697760001],
              [-6.289204119999965, 22.577659607999976],
              [-6.290874002999942, 22.591417313000136],
              [-6.292943954999885, 22.599069596000106],
              [-6.293382167999937, 22.61627960300001],
              [-6.297132968999904, 22.643430710000075],
              [-6.304997919999892, 22.709007263000103],
              [-6.303018091999945, 22.714559555000108],
              [-6.30704689099997, 22.731279374],
              [-6.309679984999946, 22.755090714000175],
              [-6.309689044999914, 22.760440828000185],
              [-6.311782837999942, 22.781089783000084],
              [-6.311503886999958, 22.789228439000112],
              [-6.31138896899995, 22.792570114000114],
              [-6.313348770999937, 22.79679107600009],
              [-6.313872813999979, 22.797920227000191],
              [-6.31411981499997, 22.799951554000131],
              [-6.318893908999939, 22.83921051100009],
              [-6.318492889999959, 22.846479417000069],
              [-6.32016801799989, 22.860240937000015],
              [-6.32141017899994, 22.862150192000172],
              [-6.321424006999905, 22.870559693000075],
              [-6.321475028999942, 22.870979310000109],
              [-6.323935984999935, 22.891210556000146],
              [-6.324782846999938, 22.90420913700018],
              [-6.327012061999881, 22.912410737000016],
              [-6.329390047999937, 22.94148063599999],
              [-6.333749771999976, 22.971380235000083],
              [-6.335371970999972, 22.997619629000042],
              [-6.336368084999947, 23.000820160000103],
              [-6.338198185999943, 23.016220093000015],
              [-6.338634013999979, 23.029600143000096],
              [-6.340712070999928, 23.035709382000107],
              [-6.343227863999914, 23.056350709000071],
              [-6.343239783999934, 23.062469483000086],
              [-6.344079018999878, 23.069349288000126],
              [-6.345745086999898, 23.075460434000036],
              [-6.349102018999929, 23.10297966100012],
              [-6.353233814999953, 23.10297966100012],
              [-6.350786207999931, 23.119041444000118],
              [-6.353304863999938, 23.139680864000184],
              [-6.351664066999888, 23.145029069000145],
              [-6.35168409299996, 23.155742646000078],
              [-6.354578970999967, 23.156879425000113],
              [-6.354761123999936, 23.158079148000127],
              [-6.356675148999898, 23.170640945000059],
              [-6.355864047999944, 23.180189132000066],
              [-6.359198092999975, 23.192039490000184],
              [-6.363962172999948, 23.230640411000081],
              [-6.367753982999886, 23.251419067000143],
              [-6.366776942999877, 23.259679794000192],
              [-6.369698046999929, 23.272670746000188],
              [-6.376359938999883, 23.32909965500005],
              [-6.377688883999895, 23.334577561000174],
              [-6.376046181999868, 23.339929580000046],
              [-6.376059054999871, 23.346807480000166],
              [-6.379384994999896, 23.352920533000145],
              [-6.380228042999931, 23.359798431000115],
              [-6.382720946999882, 23.365142822000053],
              [-6.383551120999925, 23.365142822000053],
              [-6.38109397799991, 23.378900528000088],
              [-6.382760048999899, 23.38311004600007],
              [-6.383428097999968, 23.388551713000084],
              [-6.384447097999953, 23.396860123000067],
              [-6.384060859999977, 23.410240174000023],
              [-6.386985778999872, 23.422840118000181],
              [-6.393733977999943, 23.477859498000043],
              [-6.393349169999965, 23.491239548000124],
              [-6.396262168999954, 23.496578218000025],
              [-6.398378847999936, 23.517589569999984],
              [-6.39799404099989, 23.530969619000132],
              [-6.401340006999874, 23.543190002000074],
              [-6.403028010999947, 23.556940079000071],
              [-6.402663230999963, 23.569982530000175],
              [-6.402643202999968, 23.570699691000073],
              [-6.402797222999936, 23.57102966400015],
              [-6.406219005999901, 23.57840919500012],
              [-6.413815973999931, 23.640207291000024],
              [-6.413018226999895, 23.646940231000087],
              [-6.418222902999958, 23.673440933],
              [-6.417356967999979, 23.68329620500009],
              [-6.417013168999972, 23.687200547000032],
              [-6.418327807999901, 23.690122605000056],
              [-6.419930935999958, 23.693687439000087],
              [-6.420777797999961, 23.700559616000191],
              [-6.422453880999967, 23.707441330999984],
              [-6.423298835999901, 23.714309693000018],
              [-6.42244720399998, 23.719085694000057],
              [-6.422070980999934, 23.721191406],
              [-6.424593925999943, 23.734941482000124],
              [-6.428008078999937, 23.758192062000091],
              [-6.432613849999882, 23.789550781000116],
              [-6.432617187999881, 23.790939331000061],
              [-6.432643890999941, 23.80253028900006],
              [-6.434753894999915, 23.816280366000058],
              [-6.437695980999934, 23.829639435000047],
              [-6.437287806999962, 23.833080291000158],
              [-6.441101074999835, 23.864009858000031],
              [-6.441120147999925, 23.870117187000062],
              [-6.442049979999922, 23.873941421000097],
              [-6.445340155999929, 23.904315949000136],
              [-6.448338031999981, 23.931980133000025],
              [-6.449603080999964, 23.938850402000128],
              [-6.452106952999884, 23.943429947000084],
              [-6.450633048999976, 23.951679231000128],
              [-6.454700946999935, 23.982379914000035],
              [-6.451756, 23.999998093000045],
              [-6.452319145999979, 24.005823136000174],
              [-6.454820155999926, 24.031700135000108],
              [-6.469214914999952, 24.151380539000172],
              [-6.472731112999895, 24.184928894000166],
              [-6.476466179999932, 24.220569611000087],
              [-6.485498905999975, 24.306747437000183],
              [-6.485816002999968, 24.309774399000162],
              [-6.486752033999949, 24.318700792000186],
              [-6.49257993699996, 24.360469819],
              [-6.497154236999961, 24.412170409000112],
              [-6.499385833999952, 24.431138993000161],
              [-6.552691936999906, 24.884340287000043],
              [-6.555284022999899, 24.920759201000124],
              [-6.557859896999958, 24.943143845000179],
              [-6.564404964999881, 25.0],
              [-6.563048839999965, 25.0],
              [-6.562486170999932, 25.0],
              [-6.51698493899994, 25.0],
              [-6.501399992999893, 25.0],
              [-6.483726024999953, 25.0],
              [-6.481391905999885, 25.0],
              [-6.473134993999906, 25.0],
              [-6.46454715699997, 25.0],
              [-6.418099879999886, 25.0],
              [-6.334799766999879, 25.0],
              [-6.31031322399997, 25.0],
              [-6.251500129999897, 25.0],
              [-6.168200014999968, 25.0],
              [-6.152740954999956, 25.0],
              [-6.148137091999956, 25.0],
              [-6.084899901999961, 25.0],
              [-6.001599787999908, 25.0],
              [-5.94623994899996, 25.0],
              [-5.918300151999972, 25.0],
              [-5.890707968999948, 25.0],
              [-5.882464885999923, 25.0],
              [-5.834999083999946, 25.0],
              [-5.795485971999938, 25.0],
              [-5.780807971999934, 25.0],
              [-5.7516989699999, 25.0],
              [-5.668398856999886, 25.0],
              [-5.585101127999906, 25.0],
              [-5.501801012999977, 25.0],
              [-5.418498991999968, 25.0],
              [-5.376749992999976, 25.0],
              [-5.335198878999961, 25.0],
              [-5.290637015999891, 25.0],
              [-5.251901149999981, 25.0],
              [-5.168601036999974, 25.0],
              [-5.1330189699999, 25.0],
              [-5.112915039999962, 25.0],
              [-5.104547977999914, 25.0],
              [-5.08530092299992, 25.0],
              [-5.001999854999951, 25.0],
              [-4.999999045999914, 25.0],
              [-4.999999045999914, 24.99799919100019],
              [-4.999999045999914, 24.995891572000119],
              [-4.990931033999971, 24.995979309000177],
              [-4.978202819999979, 24.995592118000047],
              [-4.941232204999949, 24.994470596000156],
              [-4.937542914999881, 24.995981216000075],
              [-4.93700885699991, 24.996200561000023],
              [-4.933785915999977, 24.996017456000061],
              [-4.907717226999978, 24.994539261000057],
              [-4.904493808999916, 24.99600982700008],
              [-4.832172869999965, 24.996564866000085],
              [-4.829955323999911, 24.996582606000118],
              [-4.829153299999973, 24.996119358000158],
              [-4.824529170999938, 24.990293502000043],
              [-4.813550948999932, 24.982902528000125],
              [-4.763688087999924, 24.954772950000063],
              [-4.660945892999848, 24.890539170000181],
              [-4.660173892999922, 24.890038491000041],
              [-4.659401893999927, 24.889537811000025],
              [-4.65913428499988, 24.889275480000038],
              [-4.654855488999942, 24.886135102000082],
              [-4.653040885999928, 24.885410310000168],
              [-4.416556833999891, 24.731948854000109],
              [-4.037438868999971, 24.493942261000143],
              [-3.984628916999895, 24.460865021000131],
              [-3.961039065999955, 24.445127488000026],
              [-3.925719975999925, 24.423965453999983],
              [-3.894555090999972, 24.405290604000072],
              [-3.889524937999966, 24.403192521000165],
              [-3.887604474999876, 24.402391435000141],
              [-3.885684012999946, 24.401590348000184],
              [-3.875451087999977, 24.39355278000005],
              [-3.851378917999966, 24.378789902000165],
              [-3.754307983999922, 24.31925964300018],
              [-3.741559028999973, 24.310119630000031],
              [-3.706764935999956, 24.290189743000099],
              [-3.69706511399994, 24.282592775000182],
              [-3.590102911999907, 24.215900422000175],
              [-3.581274032999943, 24.212858200000028],
              [-3.512948036999944, 24.167839050000111],
              [-3.505651949999958, 24.165910722000092],
              [-3.498295067999948, 24.161239623000029],
              [-3.497379062999926, 24.159419059000072],
              [-3.315000057999953, 24.042329789000121],
              [-3.304903982999917, 24.03722953700003],
              [-3.296037673999877, 24.029539109000154],
              [-3.247293947999935, 23.999998093000045],
              [-3.200618980999934, 23.971879960000081],
              [-3.173495054999933, 23.955020905000083],
              [-3.163642882999909, 23.948379516000045],
              [-3.156235933999881, 23.94444084200012],
              [-3.150496959999884, 23.940149308000173],
              [-3.135711906999916, 23.930740357000161],
              [-3.129959106999934, 23.927211762000127],
              [-3.1233699309999, 23.92404937800012],
              [-3.118643999999847, 23.919551850000119],
              [-3.027334928999835, 23.862119674000098],
              [-3.022382020999942, 23.860509874000172],
              [-3.017488956999898, 23.85584068300011],
              [-3.006827114999965, 23.848789215000181],
              [-2.999397038999973, 23.846370696000179],
              [-2.95264005599995, 23.81654930100018],
              [-2.947751998999877, 23.811880112000154],
              [-2.918225050999922, 23.79342079200012],
              [-2.903454065999824, 23.784759522000058],
              [-2.890352009999958, 23.775739669000075],
              [-2.882128953999938, 23.771781921000184],
              [-2.877233027999921, 23.76749038700018],
              [-2.862482070999874, 23.758060456000067],
              [-2.855088949999924, 23.754110336000053],
              [-2.847723006999956, 23.749010086000112],
              [-2.844630001999917, 23.746059418000073],
              [-2.825387000999967, 23.733039856000175],
              [-2.819068908999896, 23.729660035000052],
              [-2.772779940999953, 23.700681687000099],
              [-2.772990941999979, 23.700490952],
              [-2.739197016999924, 23.679819107000185],
              [-2.71255898599992, 23.664430618000097],
              [-2.705214976999969, 23.658559800000035],
              [-2.690494059999935, 23.648729325000147],
              [-2.675739049999947, 23.640428543000041],
              [-2.670114039999874, 23.635038376000011],
              [-2.619709014999955, 23.604230880000102],
              [-2.604557037999939, 23.595529557000191],
              [-2.599668025999904, 23.591619491000131],
              [-2.585355042999936, 23.582550049000076],
              [-2.580872057999898, 23.579029084000126],
              [-2.54733300099997, 23.558509827000137],
              [-2.47807693599998, 23.513889314000096],
              [-2.473315, 23.511890413000174],
              [-2.448662042999956, 23.495159149000131],
              [-2.438245534999908, 23.489100458000053],
              [-2.437832116999971, 23.489089967000155],
              [-2.422333000999913, 23.478460311000106],
              [-2.407625913999937, 23.469379426000046],
              [-2.403521059999946, 23.467769623000038],
              [-2.387629031999893, 23.456359863000102],
              [-2.303972959999896, 23.403051377000054],
              [-2.293339013999969, 23.397470474000045],
              [-2.287642954999967, 23.393150329000093],
              [-2.272963046999905, 23.383670807000158],
              [-2.269717931999935, 23.380929946000151],
              [-2.253788947999908, 23.371799468000177],
              [-2.217525958999943, 23.347719192000113],
              [-2.209748981999951, 23.344110488000126],
              [-2.20037102799995, 23.338180543000192],
              [-2.189801931999966, 23.33031272900007],
              [-2.184489965999887, 23.327520371000162],
              [-2.16838753199994, 23.317423820999977],
              [-2.166954039999894, 23.316818237000177],
              [-2.156348944999934, 23.310470582000107],
              [-2.151066064999895, 23.306541443000185],
              [-2.136404037999853, 23.297040940000045],
              [-2.134994506999931, 23.295485497000072],
              [-2.122941971999921, 23.289110184000037],
              [-2.116452932999948, 23.283870698000158],
              [-2.06636405099988, 23.251930236000078],
              [-2.052948951999952, 23.242460250000079],
              [-2.026858091999941, 23.227350235000131],
              [-2.017091988999937, 23.221021653000037],
              [-2.012248992999957, 23.216321945000175],
              [-2.004904985999929, 23.212329865000129],
              [-1.981325030999869, 23.196510314000079],
              [-1.976019024999971, 23.193710328000066],
              [-1.94716298499992, 23.174320222000119],
              [-1.939110994999851, 23.169950485000129],
              [-1.902415037999958, 23.146150589],
              [-1.882066964999979, 23.133829117000175],
              [-1.869482994999885, 23.125129700000059],
              [-1.858489036999913, 23.118749618000095],
              [-1.815400003999855, 23.089500427000019],
              [-1.79876196399988, 23.080259324000053],
              [-1.785804986999949, 23.070398330000046],
              [-1.753697036999938, 23.050508500000149],
              [-1.738682030999883, 23.04059982299998],
              [-1.724046944999941, 23.031829835000167],
              [-1.723040997999931, 23.030849458000148],
              [-1.704965948999814, 23.019519806000119],
              [-1.697685001999901, 23.013990402000047],
              [-1.690379976999907, 23.009220123000148],
              [-1.683858991999841, 23.0060005200001],
              [-1.684324979999929, 23.004098893000048],
              [-1.677392958999974, 23.000879287000146],
              [-1.669252037999968, 22.99646949800001],
              [-1.64169800399992, 22.97743988100018],
              [-1.606001972999877, 22.954380036000089],
              [-1.5808919669999, 22.936929703000146],
              [-1.514824985999951, 22.893970490000072],
              [-1.503103016999887, 22.885261537000076],
              [-1.497807025999919, 22.882829666000021],
              [-1.481391488999975, 22.872469903000024],
              [-1.479557990999979, 22.871660232000124],
              [-1.47063899099993, 22.866079331000094],
              [-1.465801000999932, 22.862129211000138],
              [-1.451220987999875, 22.852579118000165],
              [-1.447185038999976, 22.849409104000074],
              [-1.43744599799993, 22.8438091270001],
              [-1.375921011999935, 22.803209305000109],
              [-1.371042965999891, 22.800790786000107],
              [-1.363782047999905, 22.795238496000081],
              [-1.358504055999902, 22.792430877000186],
              [-1.313171981999972, 22.763309480000032],
              [-1.225080966999883, 22.703582764000089],
              [-1.210507988999893, 22.694770813000048],
              [-1.194769978999887, 22.683639527000082],
              [-1.147575974999825, 22.652679443000125],
              [-1.134199977999913, 22.642969131000143],
              [-1.126904963999948, 22.638940812000158],
              [-1.107522964999873, 22.626167298000041],
              [-1.10262703799998, 22.624500275000059],
              [-1.09378194899989, 22.617519378000054],
              [-1.062273024999911, 22.597368241000083],
              [-1.035663962999877, 22.579029084000183],
              [-1.029996036999876, 22.575809479000156],
              [-1.010653018999904, 22.562259674000188],
              [-1.003317951999918, 22.55974960400016],
              [-0.999350011999979, 22.555049896000128],
              [-0.962444007999977, 22.530670166000164],
              [-0.926823972999955, 22.508699417000116],
              [-0.920423984999843, 22.503280640000071],
              [-0.886415003999957, 22.4814300540001],
              [-0.822350979999896, 22.439010621000136],
              [-0.807825982999873, 22.430170059000147],
              [-0.781024991999971, 22.411779405000118],
              [-0.750252008999951, 22.391740799000161],
              [-0.730481029999851, 22.380050660000052],
              [-0.693055003999916, 22.355210305000071],
              [-0.691422997999894, 22.354780197000025],
              [-0.681793986999878, 22.347600937000038],
              [-0.676540969999905, 22.344760895000036],
              [-0.659875988999943, 22.334079742000142],
              [-0.636705994999886, 22.318689347000088],
              [-0.62541001999989, 22.312219620000121],
              [-0.612578987999882, 22.302650451000147],
              [-0.598076998999943, 22.293790818000105],
              [-0.555868982999925, 22.265712738000047],
              [-0.544991015999869, 22.259250640000062],
              [-0.532962024999961, 22.250459670999987],
              [-0.520087002999958, 22.242397308000022],
              [-0.508854984999914, 22.23439979599999],
              [-0.503211974999942, 22.231159211000033],
              [-0.488752991999888, 22.221530915000073],
              [-0.483906000999866, 22.219081878000054],
              [-0.470663994999939, 22.209867478000035],
              [-0.456180005999954, 22.20099067699999],
              [-0.427695006999897, 22.18133926400003],
              [-0.407748013999878, 22.170190812000044],
              [-0.401995987999953, 22.164459228000112],
              [-0.394317000999877, 22.160770416000105],
              [-0.380282996999824, 22.151140214000179],
              [-0.375176995999936, 22.149389267000117],
              [-0.363550990999897, 22.139730454000073],
              [-0.336694001999945, 22.123529435000137],
              [-0.325904995999906, 22.11515045200008],
              [-0.287957011999936, 22.091150283000161],
              [-0.272003008999945, 22.080873490000101],
              [-0.270485012999927, 22.080249787000071],
              [-0.235791995999932, 22.057500839000056],
              [-0.231811001999858, 22.054109574000051],
              [-0.217383994999977, 22.044450760000075],
              [-0.208925000999955, 22.039970398000094],
              [-0.207708998999976, 22.039550782000163],
              [-0.20295700399987, 22.034790038999972],
              [-0.18332900099989, 22.021520615000043],
              [-0.168879001999869, 22.012620926000068],
              [-0.166164993999928, 22.008319853999978],
              [-0.154897004999953, 22.002212525000118],
              [-0.147191001999943, 21.999649048000038],
              [-0.101966001999926, 21.968679429000076],
              [-0.096995002999961, 21.963699341000165],
              [-0.073477000999958, 21.949190141000145],
              [-0.027551998999911, 21.917940139000166],
              [-0.02032, 21.913869858000055],
              [-0.013032, 21.911319734000131],
              [-0.010568998999929, 21.897411347000116],
              [-0.006276999999898, 21.873172761000149],
              [-0.000170998999977, 21.838685989000055],
              [0.004749999000069, 21.834480286000144],
              [0.023343, 21.823226928000054],
              [0.029814999000052, 21.819307327000161],
              [0.079007000000161, 21.789529801000185],
              [0.149941996000052, 21.745439530000169],
              [0.165052996000156, 21.736860276000129],
              [0.178001001000041, 21.728200913000137],
              [0.206052004000128, 21.710939407000126],
              [0.230772003000084, 21.695930481000119],
              [0.248145997000165, 21.684379577000072],
              [0.263244986000075, 21.675792695000155],
              [0.285371006000048, 21.66235733000002],
              [0.316727012000115, 21.642850876000068],
              [0.341434986000024, 21.627479553],
              [0.379043013, 21.604110718000129],
              [0.411105991000113, 21.584262848000151],
              [0.450850009000135, 21.560947418000126],
              [0.502071024000088, 21.528547287000151],
              [0.534821987000157, 21.508699417000173],
              [0.578256012000054, 21.481769563000057],
              [0.596634983000115, 21.470891953000091],
              [0.618184984000152, 21.457550048000144],
              [0.644827009000153, 21.441059113000165],
              [0.676048994000041, 21.421304701999986],
              [0.692104995000022, 21.411146164000058],
              [0.706263006000086, 21.402187348000098],
              [0.747497976000034, 21.376499177000142],
              [0.777933001000122, 21.357540131000178],
              [0.845838009000033, 21.316789627000162],
              [0.915198982000163, 21.274379730000078],
              [0.93575000800007, 21.261510849000103],
              [0.969006182000101, 21.241035462000184],
              [1.040264011000033, 21.197162629000047],
              [1.125638009000056, 21.144680023999967],
              [1.166674017000048, 21.118892669000161],
              [1.170938014000171, 21.108900070000175],
              [1.173702956000113, 21.100900650000028],
              [1.176815987000055, 21.093240739000123],
              [1.182132959000171, 21.072509765000063],
              [1.184262036000177, 21.06179046599999],
              [1.187255024000024, 21.04637909000013],
              [1.188784003000137, 21.031940461000147],
              [1.190742015000126, 21.003700256000059],
              [1.191202044000136, 20.96599960400016],
              [1.188879967000105, 20.936969757000099],
              [1.184913992000134, 20.90317726100011],
              [1.175861000000168, 20.860147477000169],
              [1.166949987000123, 20.824199677000138],
              [1.165868402000115, 20.820041657000047],
              [1.161833048000176, 20.804527283000141],
              [1.158612967000124, 20.792980194000108],
              [1.157611967000037, 20.779150009000034],
              [1.158696891000091, 20.763734818000103],
              [1.160436035000146, 20.757669449000161],
              [1.167637945000024, 20.745069504000128],
              [1.172166944000082, 20.737787248000132],
              [1.176954984000076, 20.733539581000116],
              [1.190690995000182, 20.729549409000185],
              [1.207280993000097, 20.725959778000117],
              [1.216570973000103, 20.726064681000139],
              [1.227689028000157, 20.726190567000117],
              [1.232632995000188, 20.726577760000055],
              [1.240911961000052, 20.727228165000156],
              [1.246977448, 20.730710030000182],
              [1.264834046999965, 20.741359710000097],
              [1.275133014000119, 20.744329452000159],
              [1.28656602000018, 20.74531936600016],
              [1.305946946000063, 20.744161606000148],
              [1.317543984000167, 20.739759445000118],
              [1.327779472000088, 20.732148171000063],
              [1.333939578000184, 20.723335255999984],
              [1.335492094000188, 20.721114159000024],
              [1.336203766000097, 20.71974774500012],
              [1.336428998999963, 20.719390869000051],
              [1.34175503300014, 20.709089280000057],
              [1.344266056000151, 20.697027208000179],
              [1.345257998000136, 20.687959670999987],
              [1.350867033000156, 20.680030824000085],
              [1.356745004000061, 20.675130844000023],
              [1.371830523000085, 20.665664674000141],
              [1.378355026000179, 20.664352417000032],
              [1.384678005000126, 20.663080215000093],
              [1.398379981000176, 20.661693573000093],
              [1.42058301000003, 20.659660339000084],
              [1.426424981000139, 20.658222199000136],
              [1.437896014000103, 20.655399322000051],
              [1.455214978000072, 20.650800704000062],
              [1.465260983, 20.647283554000012],
              [1.472216964000097, 20.644849778000093],
              [1.500841021000156, 20.633127213000137],
              [1.511257529000147, 20.6293096550001],
              [1.524006009000175, 20.624309540000013],
              [1.541452051000022, 20.620214463000025],
              [1.545989991000113, 20.619150161000107],
              [1.561295033000079, 20.613023759000157],
              [1.568916499000125, 20.609974861000126],
              [1.592697025000064, 20.600469588000124],
              [1.617380024000113, 20.588300705000051],
              [1.634106040000063, 20.578960418],
              [1.640020967, 20.572370529000011],
              [1.653113961000088, 20.552160264000179],
              [1.657183052000107, 20.534748078000121],
              [1.662135004000163, 20.498489380000137],
              [1.66849398700009, 20.43769073499999],
              [1.669320941000137, 20.420869827000047],
              [1.671043992000136, 20.410467148],
              [1.678388, 20.40357971200018],
              [1.699939013, 20.386940002000074],
              [1.715633036000099, 20.375209808000136],
              [1.73963296300002, 20.360309602000086],
              [1.768350006000105, 20.342487335000101],
              [1.775694965000071, 20.335260391000077],
              [1.780611991000114, 20.325277328000141],
              [1.78626203500005, 20.314638137000145],
              [1.79296398200006, 20.304700851],
              [1.798454999000114, 20.299779893000164],
              [1.803176998000083, 20.296859741000162],
              [1.813163996000071, 20.297439576000102],
              [1.822573483000099, 20.299454689000072],
              [1.839148999000031, 20.300771714000064],
              [1.865844966000054, 20.300004960000081],
              [1.87600696100003, 20.298290253],
              [1.880396962000077, 20.294351579000079],
              [1.889724970999964, 20.27942085400008],
              [1.898344993000023, 20.264129640000192],
              [1.905501008000101, 20.250150680000161],
              [1.911782980000112, 20.242219926000132],
              [1.916874051000093, 20.238630295000064],
              [1.926962019000087, 20.238235474000192],
              [1.933575988000143, 20.241720200000032],
              [1.942641972999979, 20.250019074000079],
              [1.949262976000057, 20.256240845000036],
              [1.952762008000036, 20.259019853000098],
              [1.958791970000164, 20.26050949100005],
              [1.96522402700009, 20.260320664000176],
              [1.974815549000027, 20.259873391000042],
              [1.982084988999986, 20.257350922000114],
              [1.992949963000115, 20.251539230000105],
              [2.001479029999985, 20.245216370000094],
              [2.008548022000127, 20.24213504800008],
              [2.014914990000079, 20.244469643000059],
              [2.021582485000067, 20.249003410000114],
              [2.02676930400014, 20.250898447000111],
              [2.028211673000044, 20.251425426000139],
              [2.032440286000053, 20.253602330000092],
              [2.037641526000186, 20.25627994600012],
              [2.043924650000122, 20.256831487000056],
              [2.048264027000073, 20.252819062000128],
              [2.04983901900016, 20.247129441000027],
              [2.056509972000129, 20.237510680000071],
              [2.062799930999972, 20.228900909000117],
              [2.06717896400005, 20.225299836000147],
              [2.078062057000182, 20.218469620000064],
              [2.088891984000156, 20.213670731000093],
              [2.095357895000177, 20.212131501000101],
              [2.102459907000025, 20.213640214000122],
              [2.111612916000183, 20.217676164000125],
              [2.120029926000086, 20.224819183000079],
              [2.128355026, 20.234430313000189],
              [2.138241530000016, 20.249295234000101],
              [2.143821001000106, 20.25836181699998],
              [2.152132987000186, 20.268650055000023],
              [2.161163091000049, 20.278959275000034],
              [2.173080921000064, 20.287977220000187],
              [2.18015289300007, 20.290832519000105],
              [2.185602009000093, 20.291712761000099],
              [2.192864538000151, 20.290411949000088],
              [2.196989059000089, 20.288850784000147],
              [2.210633994000091, 20.2857818600001],
              [2.222896098000035, 20.280998230000023],
              [2.237565994000022, 20.273790360000191],
              [2.253288030000078, 20.264829635000126],
              [2.265995025000109, 20.25635147100013],
              [2.279093027000101, 20.246189117000029],
              [2.296238900000049, 20.231069566000031],
              [2.317847013000119, 20.208290101000046],
              [2.338418961000116, 20.183809281000094],
              [2.355453014999966, 20.15790176500019],
              [2.364392041000087, 20.141929626000149],
              [2.36808800700004, 20.13662147499997],
              [2.372128964000183, 20.131650925000145],
              [2.375504970000065, 20.124647141000082],
              [2.381709098000044, 20.103559494000137],
              [2.384572982, 20.097681046000105],
              [2.386580944000059, 20.09355926500001],
              [2.394398927000054, 20.079580307000185],
              [2.39921498300015, 20.071929931000113],
              [2.406273206000151, 20.06429608600007],
              [2.411627054000121, 20.059728622000137],
              [2.418839932000026, 20.056179046000068],
              [2.427738548000036, 20.056062699000108],
              [2.438761949000025, 20.058280944000103],
              [2.444543481000039, 20.059772492000036],
              [2.456865072000142, 20.062019349000025],
              [2.462915898000063, 20.062490464000064],
              [2.46793699300008, 20.061237334999987],
              [2.474786044000041, 20.058019639000065],
              [2.478770018000148, 20.05540084900008],
              [2.489367962000074, 20.044170379999969],
              [2.495981932000177, 20.035549164000031],
              [2.503232002000175, 20.030309678000151],
              [2.513705015000141, 20.024459839000087],
              [2.52561545400016, 20.021217347000061],
              [2.526043890999972, 20.021102905000191],
              [2.533435107000116, 20.019140243000152],
              [2.542701959000169, 20.019319534000147],
              [2.551647900999967, 20.017820358000108],
              [2.560616016000097, 20.01531028800008],
              [2.56638693900004, 20.012390136000022],
              [2.572185040000079, 20.008129119000159],
              [2.578320025999972, 20.004880905000164],
              [2.589736938000158, 20.004440309000131],
              [2.65426993400007, 20.004030227000101],
              [2.693118095000159, 20.004449844000135],
              [2.703384041000106, 20.004653931000064],
              [2.710247517000084, 20.003128052000022],
              [2.712345627000161, 20.002439479000145],
              [2.718941928000163, 20.00027465900007],
              [2.724248886000112, 19.998987198000066],
              [2.733755947000077, 19.998167992000106],
              [2.743154050000101, 19.998350142999982],
              [2.750415921000069, 19.997088910000173],
              [2.75825595800012, 19.992227554000124],
              [2.767006038999966, 19.98650169400014],
              [2.772686959000168, 19.984092713],
              [2.791433455000174, 19.976554871000189],
              [2.802860975000101, 19.972869874000025],
              [2.81132102000015, 19.970385552000153],
              [2.823266029000024, 19.968540192000091],
              [2.835391044000062, 19.968090058000143],
              [2.84945046900009, 19.967406274000041],
              [2.861555100000032, 19.965414047000138],
              [2.867902995000065, 19.964309693000075],
              [2.887708903000146, 19.961044313000059],
              [2.895437003000097, 19.959770203000176],
              [2.915876866000076, 19.957147598000176],
              [2.936882973000081, 19.954452514000081],
              [2.954397916, 19.951738357000124],
              [2.964040995000062, 19.950569152000128],
              [2.974413871000138, 19.947502136000026],
              [2.980144977000066, 19.945808411000144],
              [2.993813991000025, 19.940650940000182],
              [3.002441882000085, 19.936761857000022],
              [3.007673025000088, 19.931503296000187],
              [3.012980938000055, 19.926170349000131],
              [3.021574021000049, 19.918439866000085],
              [3.029767037000113, 19.912570954000103],
              [3.039175034000039, 19.907180786000026],
              [3.050030947000039, 19.902740478000112],
              [3.05717396700004, 19.900550843000076],
              [3.068845510000074, 19.898320199000068],
              [3.081104993, 19.890779495000174],
              [3.109123946000068, 19.871822356999985],
              [3.137089014000026, 19.855400085000156],
              [3.16726493800013, 19.838090896000153],
              [3.180330993000041, 19.832750321000162],
              [3.195076942000071, 19.829290391000029],
              [3.209064007000109, 19.827209474000085],
              [3.222105027, 19.826445580000097],
              [3.229897976000075, 19.825234412999976],
              [3.232894898000041, 19.821819306000123],
              [3.232985973000041, 19.816719055000135],
              [3.231621028000177, 19.809926987000154],
              [3.227936029000034, 19.798360825000088],
              [3.226008697000054, 19.790700599000104],
              [3.224560023000095, 19.782337188999975],
              [3.226634503000128, 19.775774956000134],
              [3.229569436000077, 19.769989968000061],
              [3.228600980000067, 19.764930725000056],
              [3.229191065000123, 19.759439467999982],
              [3.230062008000175, 19.752101899000138],
              [3.229254961000095, 19.743602753000175],
              [3.218564034000053, 19.7190818790001],
              [3.215101957, 19.709840774000099],
              [3.212548970000114, 19.691427231000034],
              [3.21228706800008, 19.679944992000117],
              [3.218529940000053, 19.670869828000093],
              [3.228122950000113, 19.663909912000122],
              [3.232795508000038, 19.654465103000177],
              [3.23294091300005, 19.653209686000082],
              [3.233024429000011, 19.653039251000109],
              [3.232933499000126, 19.642274024000187],
              [3.229084015000126, 19.635850907000133],
              [3.225039960000117, 19.63072204700012],
              [3.221750736000104, 19.621822357000042],
              [3.219120026000041, 19.613231659000178],
              [3.212740899000096, 19.591470720000075],
              [3.211194992000173, 19.583419800000058],
              [3.210656881000148, 19.577999116000115],
              [3.211397886000043, 19.572189331000175],
              [3.212574006000011, 19.565799714000036],
              [3.216114045000097, 19.557229995000171],
              [3.218766927000047, 19.551061631000152],
              [3.222909928000092, 19.544309617000181],
              [3.226383924000118, 19.539560318000042],
              [3.233273028999975, 19.532850265000036],
              [3.242733955000119, 19.524578095000038],
              [3.249012947000097, 19.518231710000123],
              [3.249706029000038, 19.513471605000177],
              [3.247869015000106, 19.509630203000143],
              [3.247180938000099, 19.508178712000188],
              [3.247095323000053, 19.507997904000149],
              [3.240056039000081, 19.497070314000155],
              [3.236377954000147, 19.489791870000147],
              [3.234998942000061, 19.484149933000026],
              [3.234669924, 19.478939057000048],
              [3.236042976000135, 19.473339082000052],
              [3.239984988000174, 19.465789795000092],
              [3.243027926000025, 19.461227417000032],
              [3.249074937000046, 19.454309463000186],
              [3.255359889000147, 19.445789338000168],
              [3.259500028000105, 19.440113067000084],
              [3.263164044000064, 19.435089112000185],
              [3.267113924, 19.427129746000105],
              [3.269315958000163, 19.422149658000137],
              [3.271106959000122, 19.416559219000078],
              [3.272078037000142, 19.409557343000188],
              [3.271997928000189, 19.402139663000185],
              [3.271246553000083, 19.396455765000098],
              [3.268451930000083, 19.387649536000026],
              [3.263954043000126, 19.379050255000038],
              [3.260463, 19.373279571000069],
              [3.257136107000179, 19.370220185000051],
              [3.253813029000071, 19.366960527000174],
              [3.247535944000163, 19.363250733000029],
              [3.23978900899999, 19.359109879000073],
              [3.22933006400018, 19.353120804000184],
              [3.224528074000034, 19.349830627000188],
              [3.217468023000038, 19.34289932199999],
              [3.209996938000131, 19.335359574000051],
              [3.202996970000072, 19.325019837000127],
              [3.197961092000128, 19.315973283000119],
              [3.194770097000173, 19.307315827000025],
              [3.190752029000066, 19.296409606000168],
              [3.195127011000182, 19.29097938600006],
              [3.197988033000115, 19.280700685000056],
              [3.194164823000165, 19.270013370000129],
              [3.194082420000029, 19.269783021000023],
              [3.190319776000081, 19.265871048000065],
              [3.181495904999963, 19.257690429000093],
              [3.177244901000165, 19.252340316000186],
              [3.175192832000107, 19.24818038900014],
              [3.17010402700015, 19.238783836999971],
              [3.163141012000153, 19.231960933000153],
              [3.15322804499999, 19.218629836000162],
              [3.14640903600008, 19.208639145000177],
              [3.141196012000023, 19.203039170000181],
              [3.132083891999969, 19.19898033100003],
              [3.12511205800007, 19.196792603000176],
              [3.119773507000048, 19.193156242000157],
              [3.114331960000072, 19.187248231000069],
              [3.108067990000109, 19.178124428000046],
              [3.106503011000029, 19.171670913000128],
              [3.109474421000016, 19.168274881000173],
              [3.107239008000079, 19.159061432000158],
              [3.111950517000082, 19.147200585000121],
              [3.119333029000018, 19.134700776000159],
              [3.120576681999978, 19.130911655000091],
              [3.120798457000149, 19.130235959000117],
              [3.120066680000093, 19.128117889000066],
              [3.119260311000062, 19.125783920000117],
              [3.121174096000061, 19.119499206000057],
              [3.127325059000157, 19.113859177000109],
              [3.156790018000152, 19.092479705000073],
              [3.163472891000026, 19.089040756000031],
              [3.169001679000019, 19.085653487000172],
              [3.169067281000139, 19.085601163000092],
              [3.184187889000157, 19.073528290000127],
              [3.191781997000021, 19.067369461000055],
              [3.204524039000035, 19.062929153000027],
              [3.21236896400012, 19.058689117000029],
              [3.225198030000058, 19.049329758000056],
              [3.237462043000051, 19.03914260800002],
              [3.245923042000129, 19.032709122000085],
              [3.256129026999986, 19.02494812000009],
              [3.264832973000011, 19.020719527999972],
              [3.270370960000037, 19.01670074399999],
              [3.272789000999978, 19.010177613000053],
              [3.275516986000014, 19.002298354000118],
              [3.27846908600003, 18.997970581000061],
              [3.285784960000171, 18.990980149000052],
              [3.294835091000152, 18.983190536000109],
              [3.30848908400003, 18.975210190000041],
              [3.31659889299999, 18.972059250000143],
              [3.321841002000042, 18.968320847000143],
              [3.328844070000059, 18.962409973000092],
              [3.335249067000177, 18.960230827000089],
              [3.336724044000107, 18.960429691000058],
              [3.342952013000172, 18.961269379000044],
              [3.354686976000096, 18.964460373000122],
              [3.367566109000165, 18.966983795000033],
              [3.385066032000111, 18.970409393000125],
              [3.413444042000151, 18.975770951000186],
              [3.441828012000144, 18.98085021900016],
              [3.469911098000068, 18.986749650000036],
              [3.492851020000103, 18.990919113000132],
              [3.54014992600014, 19.000368119000029],
              [3.585457087000123, 19.008960724000133],
              [3.638788938000062, 19.019567491000146],
              [3.671200037000119, 19.025760651000098],
              [3.693557977000125, 19.031000137000035],
              [3.719383002000029, 19.035177232000024],
              [3.773890972000061, 19.045219421000013],
              [3.81434607500006, 19.052860261000035],
              [3.874022961000151, 19.064569474000109],
              [3.919362068000112, 19.073350905999973],
              [3.952646018000166, 19.080049515000098],
              [3.980195999000159, 19.085590362000175],
              [4.020092964000185, 19.093450546000042],
              [4.057408810000027, 19.100460054],
              [4.101613044000146, 19.109447480000028],
              [4.134057999000163, 19.115560532000131],
              [4.184585094000056, 19.12623977700008],
              [4.219625951000069, 19.132089616000144],
              [4.236124038000071, 19.135731698000029],
              [4.242887021000172, 19.136716843000102],
              [4.242959977000169, 19.13043022100004],
              [4.243737221000174, 19.088600158000133],
              [4.243566036000118, 19.056297302000132],
              [4.243288040000039, 19.00383949400009],
              [4.244226932000117, 18.972410201000116],
              [4.243980885000099, 18.94424057100008],
              [4.244338989000028, 18.913621904000081],
              [4.244258880000075, 18.82148361100019],
              [4.244967938000116, 18.810005188000162],
              [4.243717193000123, 18.794139862],
              [4.243881225000166, 18.73070907500005],
              [4.243710042000146, 18.707954407000102],
              [4.243471146000104, 18.67605972399997],
              [4.243441104999988, 18.672210694],
              [4.243430137000189, 18.670829773999969],
              [4.243094921000136, 18.625440597000136],
              [4.242062092000026, 18.590984345000095],
              [4.243239880000147, 18.563657760000126],
              [4.24253606700006, 18.525650024000186],
              [4.242215156999976, 18.429960252000171],
              [4.241541863000066, 18.389226914000176],
              [4.242344856000159, 18.369281769000054],
              [4.24162721700003, 18.357240677000107],
              [4.241538048, 18.340290070000151],
              [4.241706848000149, 18.325805664000029],
              [4.241609096000047, 18.260200500000053],
              [4.241823196000155, 18.241891862000045],
              [4.241687775000059, 18.228761674000054],
              [4.241652011000099, 18.13336563200005],
              [4.240549086999977, 18.08060073900009],
              [4.240869999000097, 18.030059816000119],
              [4.24040079200006, 18.011844635000159],
              [4.240263938000112, 18.006521225000029],
              [4.241073131000178, 17.986034393000125],
              [4.24098205700011, 17.975282669000137],
              [4.240590096000119, 17.929178238000077],
              [4.241038799000023, 17.865779878000012],
              [4.240815162000047, 17.835979461000022],
              [4.240860939000015, 17.807277679000038],
              [4.240336894000109, 17.766670228000066],
              [4.24076080399999, 17.712928773000101],
              [4.240989207000155, 17.693300248000071],
              [4.239780903000053, 17.677280427000028],
              [4.239483834000055, 17.651351930000089],
              [4.239511968000045, 17.614761353000119],
              [4.239224912000111, 17.570924759000036],
              [4.239758014000188, 17.559114457000192],
              [4.239390850000177, 17.539289474000043],
              [4.239103794000073, 17.49545669500003],
              [4.239243030000182, 17.466299058000061],
              [4.239490987000181, 17.444959641000025],
              [4.239332199000046, 17.424379349000048],
              [4.239705087000175, 17.409328461000086],
              [4.238905907000117, 17.392549515000042],
              [4.239170074000185, 17.369691849000048],
              [4.239253998000038, 17.328149795000115],
              [4.239147186000082, 17.285839082000052],
              [4.240067959000157, 17.240507126000068],
              [4.239388942000176, 17.230590821000192],
              [4.23962307000005, 17.210399627000015],
              [4.239582061000078, 17.179529190000096],
              [4.239858151000021, 17.155719756999986],
              [4.240989207000155, 17.109239579000018],
              [4.240365983000118, 17.094566343999986],
              [4.240426063000143, 17.072282792000124],
              [4.241024971000115, 17.054759979000039],
              [4.241638183000021, 17.018760682000163],
              [4.24120283200017, 16.987697601000093],
              [4.240818024000134, 16.986934663],
              [4.22900295300019, 16.980710984000154],
              [4.223031043000049, 16.978086471000154],
              [4.199020863000101, 16.967535019000024],
              [4.166740895000032, 16.953350067000144],
              [4.122035980000135, 16.933412553000039],
              [4.099572183000134, 16.923519134000117],
              [4.096468925000067, 16.922153473000151],
              [4.090919018000136, 16.919710160000079],
              [4.076756001000035, 16.91230964600004],
              [4.075839996000184, 16.874961854000048],
              [4.076538086000028, 16.80373191800004],
              [4.076918126000066, 16.789260865000074],
              [4.076927184000112, 16.788728715000047],
              [4.077457904000141, 16.761648179000133],
              [4.077823161000083, 16.732700348000094],
              [4.077935219000096, 16.723754883000083],
              [4.078557015000058, 16.674242020000065],
              [4.078784944000176, 16.64015579300019],
              [4.07922697000015, 16.62054061900011],
              [4.079617022999969, 16.613328933999981],
              [4.079741954000099, 16.611028670999985],
              [4.079659940000056, 16.609779358000139],
              [4.07891512000009, 16.598260880000112],
              [4.078956127000026, 16.57921981800007],
              [4.079286098000068, 16.552751540000145],
              [4.079482079000172, 16.537141801000075],
              [4.079099179000139, 16.504570006999984],
              [4.079049111000074, 16.492763519000107],
              [4.07936811400009, 16.483057022000025],
              [4.078526974000113, 16.471239090000097],
              [4.078900814000122, 16.457153321000192],
              [4.078137874000106, 16.439252854000131],
              [4.077805043000183, 16.418680190000032],
              [4.077674867000042, 16.397350311000082],
              [4.077900887000112, 16.363464355000133],
              [4.077315330000033, 16.349882126000182],
              [4.077136994000057, 16.345748902000139],
              [4.076927184000112, 16.331090928000037],
              [4.076467038000033, 16.320419311000023],
              [4.076075077000041, 16.318523407000157],
              [4.075168132000044, 16.314119338000182],
              [4.069468022000024, 16.296728135000023],
              [4.065062046000151, 16.286399842000151],
              [4.057452202000036, 16.264219285000138],
              [4.055172920000132, 16.257339477000073],
              [4.050795077000032, 16.245103836000112],
              [4.045658112000126, 16.230384827000023],
              [4.037669182000116, 16.207445144000133],
              [4.031980038000143, 16.189859390000095],
              [4.023042202000113, 16.164436341000055],
              [4.00995492900006, 16.124870300000055],
              [3.996642112000131, 16.08797454900008],
              [3.992095947000109, 16.074214936000146],
              [3.989248991000125, 16.065992356000152],
              [3.987938882000151, 16.061349868999969],
              [3.987740040000062, 16.060646058000088],
              [3.990446092000127, 16.049640655000132],
              [3.995771884000078, 16.033721924000133],
              [4.00154304400013, 16.013799668000047],
              [4.003334046000134, 16.000001908000058],
              [4.004453183000123, 15.995611191000137],
              [4.004405021000025, 15.985981942000024],
              [3.998795986000175, 15.978466987000047],
              [3.998435974000074, 15.97798442900006],
              [3.997845888000086, 15.97759056100017],
              [3.985497952000173, 15.969367981000175],
              [3.980881929000077, 15.966294289000018],
              [3.974168062000103, 15.961823464000076],
              [3.964421987000094, 15.957053184000074],
              [3.947273969000037, 15.941540719000045],
              [3.942024946000174, 15.935374260000117],
              [3.929572104999977, 15.909519196000133],
              [3.925674914000012, 15.887981415000013],
              [3.915858984000181, 15.86806869600008],
              [3.911720038000055, 15.844927787000074],
              [3.911128998000152, 15.820618630000183],
              [3.910092116000158, 15.813715935000062],
              [3.906481982000059, 15.789670945000069],
              [3.905725002000168, 15.780271530000164],
              [3.905695915000138, 15.774079322000091],
              [3.895734071000106, 15.740229606000128],
              [3.893897057000174, 15.733991624000055],
              [3.889081002000069, 15.717041969000149],
              [3.883097887000133, 15.704448700000114],
              [3.877125024000065, 15.697593688000097],
              [3.872142076000046, 15.691880226000023],
              [3.868108034000045, 15.690296174000082],
              [3.865250110000034, 15.686639785000125],
              [3.854795933000048, 15.679800987000078],
              [3.850761891000047, 15.678443908000133],
              [3.84358596800007, 15.673469543000067],
              [3.837933063000151, 15.669550896000089],
              [3.822273016000167, 15.663427352000042],
              [3.811291932000188, 15.662558556000135],
              [3.791945935000115, 15.661029815000177],
              [3.764914989000147, 15.658862113000055],
              [3.756643056000087, 15.658199310000043],
              [3.754398108000146, 15.657810211000083],
              [3.741816999000037, 15.655628204000095],
              [3.739814043000024, 15.655282020000072],
              [3.738481998000054, 15.654870986000162],
              [3.734370947000173, 15.653601647000187],
              [3.731750012000077, 15.652792931000079],
              [3.729337931000089, 15.651718141000117],
              [3.72154903400002, 15.648245811000095],
              [3.706119061000095, 15.639360427000156],
              [3.6851758950001, 15.613290788000086],
              [3.674010039000109, 15.604148864000024],
              [3.640963078000027, 15.568715096000119],
              [3.617177963000131, 15.539428712000074],
              [3.60766005500011, 15.526162147999969],
              [3.594183921000081, 15.515047074000108],
              [3.592701911000063, 15.513825417000021],
              [3.580940008000027, 15.510136604000081],
              [3.57105398200008, 15.507037164000167],
              [3.565924884000083, 15.505428314000085],
              [3.538172006000082, 15.488309861000062],
              [3.533420086000035, 15.483282089999989],
              [3.53268599400019, 15.479520797000077],
              [3.531493903000069, 15.473420143000169],
              [3.534142017000136, 15.453579904000037],
              [3.534955978000085, 15.447479248000093],
              [3.536115885000015, 15.440217973000131],
              [3.537586926000188, 15.430985450000151],
              [3.537739038000154, 15.430031778000114],
              [3.536988973000177, 15.41833114700006],
              [3.534033060000127, 15.405126571000096],
              [3.531886101000168, 15.395530700999984],
              [3.53170299500016, 15.394713403000083],
              [3.531407117000185, 15.393700600000045],
              [3.525722980000126, 15.374306679000028],
              [3.525902986000119, 15.357790948000172],
              [3.527453899000079, 15.342038155000182],
              [3.527507066000169, 15.341489791000072],
              [3.435481072000073, 15.341321946000164],
              [3.409929036999984, 15.340471268000101],
              [3.403074026000127, 15.342101097000068],
              [3.382488013000057, 15.341469765000056],
              [3.376574039000104, 15.34080028600016],
              [3.307497979000175, 15.341670037000085],
              [3.261131049000142, 15.341551782000181],
              [3.235110045000113, 15.341151238000066],
              [3.221621990000131, 15.34026432100012],
              [3.221621990000131, 15.341182709000066],
              [3.15420293800014, 15.341551782000181],
              [3.131494045000125, 15.341140747000168],
              [3.097902060000081, 15.341658592000101],
              [3.057923079000034, 15.343791962000068],
              [3.020201922000126, 15.345369338000182],
              [3.014784098000064, 15.34559726700013],
              [3.006592989000069, 15.345938682000053],
              [2.989089012000136, 15.346650123000188],
              [2.956913949000125, 15.348527909000154],
              [2.918121099000075, 15.351100922000114],
              [2.896593095000185, 15.350897790000033],
              [2.874420881000049, 15.35205554900017],
              [2.851881027000104, 15.353235244000132],
              [2.841237068000055, 15.353011131000187],
              [2.830353976000026, 15.354860306000148],
              [2.799268962000042, 15.356821060000186],
              [2.797235966000187, 15.356949805999989],
              [2.775470019000068, 15.357430458000181],
              [2.749551057000133, 15.359189033000121],
              [2.724370002, 15.360897064000142],
              [2.708092927000166, 15.362031937000154],
              [2.694499970000038, 15.362978936000047],
              [2.691248895000058, 15.363205909000101],
              [2.651266099000168, 15.365282059000094],
              [2.63626503900008, 15.366510391000133],
              [2.628789901000118, 15.367120742999987],
              [2.60815691900018, 15.365801811000154],
              [2.603652, 15.36551380200018],
              [2.58573198300013, 15.364370346000101],
              [2.572510004000151, 15.363299371000039],
              [2.548825979000071, 15.361379623000175],
              [2.537942886000167, 15.36115169500016],
              [2.529580117000023, 15.360181810000086],
              [2.512156963000109, 15.358161927000026],
              [2.499145985000041, 15.35792732200008],
              [2.492994070000066, 15.357238769000048],
              [2.477380038000092, 15.354939461000015],
              [2.466559886000084, 15.354233741000087],
              [2.456089973000076, 15.35355091100007],
              [2.396476985000049, 15.34800624900015],
              [2.382507085000043, 15.346877099000153],
              [2.365015983000035, 15.345462800000121],
              [2.346726893999971, 15.344584466000072],
              [2.345618008999963, 15.34453105900019],
              [2.300204038000061, 15.33942985499999],
              [2.296166897000035, 15.339114190000089],
              [2.246747016000086, 15.335243226000102],
              [2.246273994000035, 15.33522129000005],
              [2.231369973000142, 15.334536553000135],
              [2.216047048000178, 15.332875253000168],
              [2.203461885000081, 15.331509591000156],
              [2.189270020000095, 15.330577850000054],
              [2.18505191800017, 15.330154420000042],
              [2.164382934000116, 15.32807636300015],
              [2.156867027000033, 15.327321052000059],
              [2.143385887000136, 15.326609612000027],
              [2.063210963000074, 15.319351196000014],
              [2.049967050000021, 15.317951203000121],
              [2.026551008000013, 15.316529274000118],
              [2.004796027999987, 15.313961028999984],
              [1.985692978000031, 15.312313079000091],
              [1.975738049000142, 15.311454774000026],
              [1.972468973000161, 15.311173438000026],
              [1.969331979000174, 15.310902596000176],
              [1.958650946000034, 15.309981347000075],
              [1.939290046000053, 15.308311462000177],
              [1.918948055000101, 15.308262825000156],
              [1.912564039000188, 15.306181907000109],
              [1.890810012000145, 15.30475330500019],
              [1.87330603700002, 15.30401897500019],
              [1.860178947000122, 15.303069116000017],
              [1.849850058000016, 15.30232238800005],
              [1.771149040000069, 15.296629907000067],
              [1.757434965000186, 15.29543972099998],
              [1.741827011000169, 15.294711114],
              [1.708485961, 15.292080879000082],
              [1.689805985000078, 15.290410043000065],
              [1.652624011000057, 15.287781715000165],
              [1.604681014000107, 15.284393311000144],
              [1.600690007000026, 15.284165383000072],
              [1.584076046, 15.283214568],
              [1.557625054000141, 15.28170204200012],
              [1.549829006000039, 15.279840470000124],
              [1.522189021000088, 15.279342652000139],
              [1.517428041000187, 15.279255868000064],
              [1.517197012000167, 15.27923965400015],
              [1.515308023000102, 15.279108048000069],
              [1.501134992000061, 15.278114319],
              [1.490207918000124, 15.277348012000118],
              [1.475813985000116, 15.276338578000093],
              [1.438648616000023, 15.273436451],
              [1.43562100000014, 15.2732],
              [1.423797, 15.27315],
              [1.412282972000014, 15.27141485400017],
              [1.40654397000003, 15.27054977500012],
              [1.389276981000137, 15.27116966300008],
              [1.387584432000153, 15.270613917000105],
              [1.385658026000101, 15.269981385000165],
              [1.381480507000049, 15.268609910000066],
              [1.380606054000111, 15.26855659600011],
              [1.370808067000041, 15.267967808000094],
              [1.367053000000112, 15.267742],
              [1.351959787000169, 15.266831109000066],
              [1.347682954000049, 15.266572953000036],
              [1.344169809999983, 15.266361047000089],
              [1.324030041000128, 15.265146256000037],
              [1.322546959000135, 15.264510154000163],
              [1.322458982000057, 15.264472007000109],
              [1.31348204599999, 15.260622025000089],
              [1.313447427000085, 15.260607187000062],
              [1.311050057000159, 15.259579658000064],
              [1.304947019000167, 15.25471687300012],
              [1.28584504100013, 15.239501000000189],
              [1.28454546100005, 15.238262097000074],
              [1.277851000000112, 15.23188],
              [1.274983717999987, 15.229146362000165],
              [1.269119025000123, 15.223554612000044],
              [1.268901945000152, 15.223348618000045],
              [1.266517768000142, 15.221013887000083],
              [1.265843987000039, 15.220354080000106],
              [1.264695581000069, 15.219488720000072],
              [1.263962031000119, 15.218935966000174],
              [1.246530056000097, 15.20580196300017],
              [1.244787213000052, 15.204364601000066],
              [1.240983, 15.201227],
              [1.210983, 15.176481],
              [1.208274220000135, 15.173565489999987],
              [1.202963948000047, 15.167849540000134],
              [1.201897921000182, 15.16670232000007],
              [1.201344014000085, 15.166106225000021],
              [1.195325386000036, 15.161910542000044],
              [1.187095999000121, 15.156173705000128],
              [1.186224789000164, 15.155566523000175],
              [1.185038, 15.154739000000177],
              [1.18065212900018, 15.151681613000051],
              [1.176144003000047, 15.148538590000157],
              [1.171901415000036, 15.144963453],
              [1.17159, 15.144701],
              [1.170231524000144, 15.143556154],
              [1.151203037000187, 15.127519607000181],
              [1.126968979000026, 15.107654572000115],
              [1.095391989000177, 15.081073761000027],
              [1.092952966999974, 15.07901954700003],
              [1.07922100900015, 15.067462921000072],
              [1.073014835000038, 15.062473360000126],
              [1.058524000000148, 15.050823],
              [1.048416000000145, 15.041355],
              [1.038777, 15.033798],
              [1.038065741000025, 15.03324050000009],
              [1.036589026000172, 15.032082558000013],
              [1.029837012000144, 15.026788712000041],
              [0.998902581000095, 15.000665930000139],
              [0.998114000000157, 15.000000000000114],
              [0.983242, 14.98744],
              [0.975654, 14.981032],
              [0.97454661800009, 14.980096678000166],
              [0.973188995999976, 14.978949548000116],
              [0.956914007000137, 14.974724770000137],
              [0.950445068000022, 14.974452014000121],
              [0.941511000000162, 14.974075],
              [0.931643, 14.973660000000109],
              [0.924868555000103, 14.972453384000119],
              [0.923619985000187, 14.972230911000111],
              [0.915401265000128, 14.972281484000121],
              [0.904246986000146, 14.972350121000034],
              [0.893207014000097, 14.972929001000125],
              [0.89243197400009, 14.97297001000004],
              [0.891185998000026, 14.972531319999973],
              [0.886472317000027, 14.970872928000119],
              [0.88512498200015, 14.970398902000113],
              [0.87369208400014, 14.969835535000129],
              [0.847810982999988, 14.968560219000153],
              [0.832938015000082, 14.967094422000116],
              [0.823586510000041, 14.966570130000036],
              [0.814516009000101, 14.966061592000074],
              [0.809029997000096, 14.965359689000024],
              [0.806963027000108, 14.965094566000175],
              [0.785706997000148, 14.964269638000133],
              [0.762570022999967, 14.962517738000145],
              [0.755861912000171, 14.96225468300014],
              [0.748635000000149, 14.961971],
              [0.745731, 14.961679],
              [0.73635723700005, 14.960741698000106],
              [0.733099996000135, 14.96041584000011],
              [0.720808029000125, 14.959186554000041],
              [0.719126999000139, 14.959018707000098],
              [0.717239977000133, 14.95707988800018],
              [0.716218961000038, 14.956031497000026],
              [0.711997986000142, 14.951697351000064],
              [0.707872988000076, 14.947460175000117],
              [0.707531703000143, 14.947341618000166],
              [0.706838012000048, 14.947100640000144],
              [0.705499534000126, 14.946637782000039],
              [0.704720000000123, 14.946368],
              [0.703759014000184, 14.946035386000119],
              [0.703157008000119, 14.945824622999965],
              [0.701940769000089, 14.946035386000119],
              [0.700811634000047, 14.946230503000152],
              [0.700611697999989, 14.946264991000191],
              [0.694172977999983, 14.947373391000042],
              [0.689189971000076, 14.948947907000104],
              [0.672149003000186, 14.954339982000135],
              [0.665050029000099, 14.956131936000077],
              [0.659833013000082, 14.95885086100003],
              [0.642567993000114, 14.961489677000088],
              [0.627891004000105, 14.965749742000014],
              [0.619831979000082, 14.969141006000143],
              [0.609430016000147, 14.970211983000013],
              [0.594040989000064, 14.97445964800005],
              [0.588582992999989, 14.977640152000106],
              [0.572740019000094, 14.979591370000094],
              [0.566465974000153, 14.981384277000188],
              [0.562388002000034, 14.982550622000133],
              [0.561154008000074, 14.982905388],
              [0.560190976000172, 14.98318004600003],
              [0.541112005000116, 14.981284142000163],
              [0.537761986000135, 14.980951309000091],
              [0.522343994000039, 14.993245125000101],
              [0.513870002000033, 15.000000000000114],
              [0.512082994000139, 15.001424789000112],
              [0.510527015000093, 15.001091958000018],
              [0.505425990000049, 15.000000000000114],
              [0.504302024000083, 15.000000000000114],
              [0.499601006000148, 14.996741294000117],
              [0.479781985000159, 14.991805078000084],
              [0.476819009000167, 14.991067886],
              [0.470124007000095, 14.989399910000031],
              [0.45674699599999, 14.985241890000054],
              [0.449827999000149, 14.983091355000056],
              [0.444644005000043, 14.98147964399999],
              [0.441374988000177, 14.980463982000174],
              [0.427466988000162, 14.976166725000155],
              [0.421739996000042, 14.974397660000079],
              [0.418518008000092, 14.973402978000024],
              [0.401775002000136, 14.969598770000175],
              [0.382865012000138, 14.971717834],
              [0.378749997000114, 14.972179413000106],
              [0.365359009000031, 14.973678589000144],
              [0.352584989000036, 14.975643158000082],
              [0.341719001000172, 14.978270531000135],
              [0.336959005000153, 14.979421615000149],
              [0.328179985000133, 14.980734824000024],
              [0.319454998000026, 14.982041360000096],
              [0.305249990000164, 14.985365868000031],
              [0.295322, 14.985975265000093],
              [0.284435988000155, 14.987951280000061],
              [0.268592001000059, 14.989660263000189],
              [0.242449999000144, 14.989767075000145],
              [0.23149700499999, 14.989812852000114],
              [0.229350001000171, 14.989670753000155],
              [0.219667628000138, 14.989029885000036],
              [0.211664006000092, 14.988499641000033],
              [0.200554997000154, 14.988860130000148],
              [0.196337999000093, 14.987673759000131],
              [0.19224000100013, 14.98652076799999],
              [0.182879998999965, 14.983888627000113],
              [0.164240003000145, 14.980750085000125],
              [0.163529574000052, 14.980796813999973],
              [0.162365004000151, 14.980874061000065],
              [0.150151998000013, 14.981677055999967],
              [0.148880004000148, 14.98176098],
              [0.145176143000185, 14.980890274000103],
              [0.144693002000054, 14.980776786000092],
              [0.141809999000031, 14.980099679000148],
              [0.133415998000146, 14.980214120000085],
              [0.132315561000098, 14.980229378000104],
              [0.121250005000093, 14.980381965000049],
              [0.105925999000135, 14.979059220000124],
              [0.102679529000113, 14.978778840000189],
              [0.094575004000035, 14.978079797000134],
              [0.085480005000079, 14.977998733000049],
              [0.07939299899999, 14.97794437400006],
              [0.071326002000035, 14.977871895000021],
              [0.070229560000087, 14.977862359000085],
              [0.062212002000138, 14.97779274100003],
              [0.059836998000094, 14.978093148000085],
              [0.050383003000093, 14.979290009000124],
              [0.045255419000057, 14.979829789000178],
              [0.038322002000029, 14.980559349000146],
              [0.035539932000063, 14.980728149000072],
              [0.028394001000095, 14.981161117],
              [0.013637084000038, 14.983040810000034],
              [0.013249999000095, 14.983090402000073],
              [0.002143000000103, 14.983909608000147],
              [0.0020164660001, 14.983923910999977],
              [0.0, 14.984150887000112],
              [-0.009209998999836, 14.98518180800005],
              [-0.027673438999841, 14.993475914000101],
              [-0.036742000999936, 14.99755001200009],
              [-0.042954998999903, 15.000000000000114],
              [-0.077912143999924, 15.01376915000003],
              [-0.093679144999953, 15.019979478000153],
              [-0.098115265999979, 15.02096176200007],
              [-0.098650000999896, 15.021080017000145],
              [-0.109807997999951, 15.026251792000039],
              [-0.132100998999931, 15.03407096900014],
              [-0.132372780999958, 15.034255982000104],
              [-0.135433003999935, 15.03633880700005],
              [-0.143044650999911, 15.037975311000082],
              [-0.148695005999969, 15.039190293000047],
              [-0.148699997999927, 15.039607048000164],
              [-0.148706003999905, 15.040108681000049],
              [-0.158455236999941, 15.044061661000057],
              [-0.163657991999969, 15.046171188000073],
              [-0.167949004999912, 15.049801826000078],
              [-0.179545, 15.051520348],
              [-0.220266237999965, 15.06705760900013],
              [-0.231750993999867, 15.071439744000031],
              [-0.245261995999897, 15.075209618000031],
              [-0.246464267999897, 15.075545312000088],
              [-0.247631996999871, 15.075871467000184],
              [-0.248295992999942, 15.075986863000139],
              [-0.253791003999936, 15.076951028000053],
              [-0.269804, 15.071511268000108],
              [-0.298287002999871, 15.055254936000097],
              [-0.301744013999837, 15.053281784000092],
              [-0.30254501099995, 15.052912712000079],
              [-0.312507033999964, 15.048322679000023],
              [-0.317027002999964, 15.046239854000078],
              [-0.324810086999889, 15.04167556699997],
              [-0.331588118999946, 15.037700653000115],
              [-0.3508488249999, 15.026406289000136],
              [-0.372619985999961, 15.013640404],
              [-0.376424014999941, 15.011409759000117],
              [-0.384025425999937, 15.009050369000079],
              [-0.388666987999954, 15.007610321000129],
              [-0.394276291999972, 15.00841426900007],
              [-0.397659986999884, 15.008899689000089],
              [-0.403618991999906, 15.013428688999966],
              [-0.41562700299994, 15.030520440000089],
              [-0.419807850999916, 15.038856507000162],
              [-0.419981002999975, 15.039201736000052],
              [-0.442056000999912, 15.068121911000048],
              [-0.451279729999953, 15.076723099000105],
              [-0.455673993999937, 15.080821037000078],
              [-0.455916106999894, 15.080887795000081],
              [-0.458662002999972, 15.081646920000082],
              [-0.462076008999929, 15.082592010000155],
              [-0.476725994999867, 15.082431794000058],
              [-0.499058007999963, 15.08202457499999],
              [-0.500465988999963, 15.081998824000095],
              [-0.506973028999937, 15.081880569000191],
              [-0.507739007999874, 15.08181858099999],
              [-0.530115007999939, 15.080010414000071],
              [-0.549721537999972, 15.080279351],
              [-0.567228973999931, 15.080519676999984],
              [-0.576599657999964, 15.080629350000095],
              [-0.60907501, 15.081009865000055],
              [-0.637435018999895, 15.081342698000185],
              [-0.678321002999951, 15.081101417000184],
              [-0.697728991999952, 15.081453324000051],
              [-0.701488018999839, 15.081521034000161],
              [-0.710844754999869, 15.080794335000178],
              [-0.715420006999977, 15.080439569000134],
              [-0.719201027999929, 15.080389976000049],
              [-0.719368637999935, 15.08030891500016],
              [-0.720198928999878, 15.079908371000045],
              [-0.739169001999869, 15.070749282000122],
              [-0.742715000999908, 15.068254470000113],
              [-0.746056020999958, 15.065903665],
              [-0.749731002999908, 15.06331825400008],
              [-0.762579977999962, 15.054278373000045],
              [-0.762736558999961, 15.054167748000054],
              [-0.763060987999893, 15.053937912000038],
              [-0.774576543999956, 15.047079086000053],
              [-0.787455022999893, 15.03940868400008],
              [-0.794729470999869, 15.034384728000077],
              [-0.796589016999974, 15.033100128],
              [-0.801201402999936, 15.029570580000041],
              [-0.805491089999975, 15.026287079000042],
              [-0.812740027999951, 15.020739556000137],
              [-0.81365126299994, 15.020120620000057],
              [-0.819030582999972, 15.016466141000137],
              [-0.8237500199999, 15.013259888000164],
              [-0.827973306999979, 15.009175302000074],
              [-0.828555403999871, 15.008612633000098],
              [-0.83429098199997, 15.003067971000178],
              [-0.837463020999905, 15.000000000000114],
              [-0.839613019999888, 14.997921944000097],
              [-0.841354964999937, 14.995137214000067],
              [-0.843086003999929, 14.992369652000093],
              [-0.84905385899998, 14.987377168000023],
              [-0.851073025999881, 14.985688209000159],
              [-0.856175005999944, 14.981418609],
              [-0.856779991999929, 14.980797767000183],
              [-0.870780468999953, 14.966444017000185],
              [-0.877850711999827, 14.95919704500011],
              [-0.883675276999895, 14.953226090000157],
              [-0.884148776999837, 14.952738761999967],
              [-0.93365997199993, 14.914329530000089],
              [-0.92569112699988, 14.91144180200007],
              [-0.931765019999943, 14.904697419000058],
              [-0.93216800599987, 14.904249192000179],
              [-0.935957014999929, 14.900715827000113],
              [-0.946632027999897, 14.890761376000114],
              [-0.95020401599993, 14.886704445000078],
              [-0.951748012999872, 14.884949683],
              [-0.982370972999945, 14.861378671000182],
              [-0.983559011999944, 14.860563278000143],
              [-1.001791000999958, 14.848038674000179],
              [-1.012073992999888, 14.84265995100003],
              [-1.018440960999953, 14.839330673000063],
              [-1.031826017999947, 14.834111214000131],
              [-1.046555517999934, 14.823356629000159],
              [-1.047557949999884, 14.822624207000104],
              [-1.047830104999946, 14.822898865000127],
              [-1.047916769999915, 14.822986602000185],
              [-1.087859988999924, 14.790120125000158],
              [-1.112591147999922, 14.783370971000181],
              [-1.15557944699998, 14.77163887100005],
              [-1.155337213999928, 14.772399902000075],
              [-1.246889947999932, 14.749420167000039],
              [-1.314939974999902, 14.733590126000081],
              [-1.409432649999872, 14.674080849],
              [-1.560505866999961, 14.578938484000105],
              [-1.674909949999972, 14.506890298000087],
              [-1.746019959999956, 14.485759735000102],
              [-1.763905882999893, 14.485730171000171],
              [-1.79890525299993, 14.484927178000078],
              [-1.975370048999935, 14.480879784000024],
              [-1.979154108999921, 14.421899795000115],
              [-1.985269070999891, 14.326600074000112],
              [-1.986235618999956, 14.311547279000138],
              [-1.987390756999957, 14.293556213000159],
              [-1.993669986999976, 14.195759773000077],
              [-2.089202403999934, 14.157024383000191],
              [-2.101938009999969, 14.151855469000054],
              [-2.103057144999923, 14.151401520000093],
              [-2.10581993999989, 14.150279999000134],
              [-2.132249592999926, 14.164752008000164],
              [-2.239140033999945, 14.223279953000031],
              [-2.25943994599993, 14.23439979599999],
              [-2.291980027999898, 14.252220154000099],
              [-2.393911361999926, 14.277436256000158],
              [-2.398224115999824, 14.276098251],
              [-2.398800610999899, 14.274559976000035],
              [-2.476469992999967, 14.297860145000072],
              [-2.576282024999898, 14.229168891000029],
              [-2.57232999799993, 14.224466324000048],
              [-2.572330951999959, 14.222729682000022],
              [-2.661683321999874, 14.143661499000132],
              [-2.672329902999877, 14.134240151000085],
              [-2.804038284999876, 14.075374604000046],
              [-2.817469596999899, 14.069372178000037],
              [-2.817423104999875, 14.070602416000042],
              [-2.837029933999929, 14.060629844000061],
              [-2.844770193999977, 14.029058457000019],
              [-2.8513500699999, 14.002220154000156],
              [-2.847950457999957, 14.001436235000028],
              [-2.848078488999875, 14.00133323700004],
              [-2.832429885999886, 13.997859955000081],
              [-2.854647636999914, 13.937562942000056],
              [-2.88079285699996, 13.866606712000134],
              [-2.892778395999869, 13.834078790000149],
              [-2.895910024999921, 13.825579644000186],
              [-2.896677732999933, 13.814690589000065],
              [-2.896686553999871, 13.814564706000056],
              [-2.897709846999874, 13.80004978300019],
              [-2.898446558999922, 13.789600373000155],
              [-2.901738880999915, 13.742897988000152],
              [-2.903039931999842, 13.724439620000055],
              [-2.87196993799995, 13.65532970400011],
              [-2.92865705499986, 13.636271476000104],
              [-2.929440975999967, 13.635760307],
              [-2.937180041999909, 13.634690285000147],
              [-2.941508769999871, 13.630862235000109],
              [-2.943856001999961, 13.628787040000077],
              [-2.944103002999952, 13.628568650000147],
              [-2.945560693999937, 13.628297805000045],
              [-2.947709082999893, 13.627898216000119],
              [-2.950138090999872, 13.627445220000084],
              [-2.953393935999884, 13.626837731000023],
              [-2.953710078999905, 13.626779556000088],
              [-2.95942807199998, 13.630569459000071],
              [-2.960093020999921, 13.631185533000064],
              [-2.978982924999968, 13.648677825000163],
              [-2.979278086999955, 13.648951530000147],
              [-2.982642649999889, 13.652067184000032],
              [-2.995151042999964, 13.663649559000078],
              [-2.997220992999871, 13.664689065000118],
              [-3.001329897999938, 13.666751862000183],
              [-3.006520985999941, 13.667572023000105],
              [-3.008050917999981, 13.66695117900008],
              [-3.013570070999947, 13.664711952000175],
              [-3.013604402999931, 13.664697648000015],
              [-3.013700961999916, 13.664658546000169],
              [-3.017250060999857, 13.66322040600005],
              [-3.019966840999928, 13.660449029000119],
              [-3.021962403999908, 13.658413886000062],
              [-3.022768974999963, 13.65759182000005],
              [-3.023638009999843, 13.654121400000179],
              [-3.039088011999979, 13.628531456000076],
              [-3.045567989999938, 13.623571396000045],
              [-3.046158075999927, 13.623604775000047],
              [-3.051450013999897, 13.62391090400007],
              [-3.059927940999899, 13.63570117900008],
              [-3.068739890999893, 13.663109779000024],
              [-3.082904099999894, 13.676840782000056],
              [-3.09194993899996, 13.684414864000018],
              [-3.103015899999946, 13.693679809000059],
              [-3.105738877999841, 13.69479465600017],
              [-3.107290029999945, 13.695429803000081],
              [-3.122119902999941, 13.696279526000126],
              [-3.124657630999934, 13.696132661000149],
              [-3.12974, 13.695837975000074],
              [-3.134335039999883, 13.695570945000043],
              [-3.134511947999954, 13.695516587000157],
              [-3.135020016999874, 13.695363045000022],
              [-3.146482943999956, 13.691869736000115],
              [-3.155696153999941, 13.689418794000176],
              [-3.157511948999968, 13.688936234000039],
              [-3.159557341999914, 13.688392639000085],
              [-3.168930053999929, 13.685899734000145],
              [-3.170714616999817, 13.685838699000158],
              [-3.179496048999965, 13.685539245000086],
              [-3.180054902999927, 13.685520172],
              [-3.185616015999869, 13.685331345000122],
              [-3.209845065999957, 13.696551323000108],
              [-3.228925942999979, 13.708799362000036],
              [-3.239356040999951, 13.712491990000046],
              [-3.254357576999951, 13.716524124000046],
              [-3.257843969999954, 13.717461586000184],
              [-3.261593102999882, 13.718469620000064],
              [-3.270556925999927, 13.718283654000174],
              [-3.271749496999917, 13.71825885800007],
              [-3.272063016999937, 13.718252183000118],
              [-3.272350549999942, 13.718246460000103],
              [-3.272641895999868, 13.71823978499998],
              [-3.279644011999949, 13.715789795000148],
              [-3.284842967999964, 13.706259728000077],
              [-3.284315585999877, 13.703633308000178],
              [-3.283787012999937, 13.701000214000089],
              [-3.280630112999916, 13.696229936000123],
              [-3.28026437799997, 13.695882797000081],
              [-3.274719237999875, 13.690625191000095],
              [-3.26774215699993, 13.68400859799999],
              [-3.261893986999951, 13.678462028000069],
              [-3.256294011999955, 13.669851302000154],
              [-3.255120039999895, 13.648490906000063],
              [-3.258270024999945, 13.631190300000185],
              [-3.256489991999899, 13.625020981000148],
              [-3.256497859999854, 13.624823570000046],
              [-3.257246971999905, 13.605921746000035],
              [-3.257804392999958, 13.601254463000146],
              [-3.259036063999872, 13.590941429],
              [-3.260584115999905, 13.575738907000186],
              [-3.266998290999936, 13.563074111000162],
              [-3.268469094999944, 13.560170175000053],
              [-3.269010305999871, 13.559916496000085],
              [-3.271028994999938, 13.558971406000069],
              [-3.27122545199984, 13.55864238800018],
              [-3.271279571999969, 13.558551789000092],
              [-3.278964996999832, 13.545700074000138],
              [-3.282001018999949, 13.534139633000109],
              [-3.276551008999945, 13.510810852000134],
              [-3.273593902999949, 13.496012688000178],
              [-3.269689082999889, 13.476470948000099],
              [-3.268341064999902, 13.466007233000084],
              [-3.266196965999882, 13.449365615000147],
              [-3.266177892999906, 13.449217796000084],
              [-3.262895106999963, 13.423729897000044],
              [-3.261497973999951, 13.405420304000017],
              [-3.261249064999959, 13.402159691000179],
              [-3.261184691999972, 13.394045830000039],
              [-3.260996102999968, 13.370262146000186],
              [-3.260982036999963, 13.368599891000031],
              [-3.260047435999979, 13.362092973000074],
              [-3.259598969999843, 13.358969689000105],
              [-3.244360922999874, 13.338830948000123],
              [-3.244264125999962, 13.337424278000128],
              [-3.244257210999933, 13.337321282000119],
              [-3.243669985999929, 13.328720092000026],
              [-3.241909980999878, 13.324626923000039],
              [-3.238480089999939, 13.316650391000167],
              [-3.238191603999951, 13.312172889000067],
              [-3.238117932999899, 13.311031341000103],
              [-3.237770079999905, 13.305629730000021],
              [-3.242848157999958, 13.292769432000171],
              [-3.243998050999892, 13.289857864000112],
              [-3.256079911999905, 13.283860207000089],
              [-3.269648790999952, 13.28249836100008],
              [-3.273415089999901, 13.282119750999982],
              [-3.274565695999968, 13.282014846000095],
              [-3.300708530999884, 13.279629707000026],
              [-3.302569865999885, 13.279457093000133],
              [-3.305362938999963, 13.27919960100013],
              [-3.305988073999913, 13.279141426000024],
              [-3.321776390999901, 13.27819442700013],
              [-3.341397047999919, 13.277018547000182],
              [-3.353790043999936, 13.274689675000047],
              [-3.368762014999959, 13.274999619000084],
              [-3.370234966999874, 13.275030136000055],
              [-3.387562990999925, 13.272821427000167],
              [-3.397931813999946, 13.272011757000143],
              [-3.403733968999973, 13.271558761000165],
              [-3.404778957999895, 13.271342278000077],
              [-3.411710977999974, 13.269908906000126],
              [-3.424770116999923, 13.26720142400012],
              [-3.431493996999905, 13.263381005000099],
              [-3.432343959999969, 13.261800766000022],
              [-3.434874057999878, 13.257102013000178],
              [-3.434806107999975, 13.25626754700005],
              [-3.434221982999929, 13.249058724000179],
              [-3.425122975999898, 13.230629921000116],
              [-3.423463343999913, 13.221701622000069],
              [-3.420314072999929, 13.204758643000048],
              [-3.419884680999928, 13.198046684000076],
              [-3.419594049999944, 13.19350147300014],
              [-3.422281027999873, 13.178195954000046],
              [-3.422327994999932, 13.17793369400016],
              [-3.42271399599997, 13.175729753000155],
              [-3.427527903999931, 13.169835092000142],
              [-3.428639888999953, 13.168473243000165],
              [-3.430000066999924, 13.166807175000145],
              [-3.433223962999875, 13.162859917000048],
              [-3.434432029999925, 13.162478447000126],
              [-3.438778875999958, 13.161106110000162],
              [-3.448837994999963, 13.157930374000102],
              [-3.464798927999937, 13.158041954000112],
              [-3.468606948999877, 13.159919739000145],
              [-3.468832968999834, 13.16003132000003],
              [-3.47821807899993, 13.159831048000171],
              [-3.481732606999913, 13.161075591000042],
              [-3.485552072999951, 13.162428857000066],
              [-3.498663424999961, 13.165228843000136],
              [-3.499233960999902, 13.165350913999987],
              [-3.504686831999948, 13.166447640000172],
              [-3.532244920999915, 13.171990395000023],
              [-3.54795312899995, 13.180219651000016],
              [-3.550776958999904, 13.181698800000106],
              [-3.579210996999962, 13.193261147000158],
              [-3.582808017999923, 13.196629525000105],
              [-3.586119174999965, 13.197965622000083],
              [-3.590393065999933, 13.199690818000022],
              [-3.599839925999959, 13.202240945000085],
              [-3.60698699999989, 13.206681251000134],
              [-3.626097918999903, 13.220971108000015],
              [-3.649823187999971, 13.243788720000055],
              [-3.660680056999979, 13.25422954600009],
              [-3.671438932999934, 13.262498856000036],
              [-3.686424017999912, 13.27066993700015],
              [-3.706551073999833, 13.277810096],
              [-3.708817719999956, 13.278569222000101],
              [-3.722645997999962, 13.283200264000129],
              [-3.735137938999969, 13.295100212000079],
              [-3.764698980999924, 13.333521842000152],
              [-3.771168468999974, 13.33980369600016],
              [-3.776962995999895, 13.345430375000035],
              [-3.786205530999894, 13.351266861000113],
              [-3.797707079999952, 13.358530044000076],
              [-3.813786983999933, 13.362991332999968],
              [-3.818755863999911, 13.362651825000114],
              [-3.819339036999963, 13.362611770000115],
              [-3.82033968099995, 13.362544060000175],
              [-3.825242995999929, 13.36221027400012],
              [-3.83315801599997, 13.361670494],
              [-3.834189891999927, 13.361599923000142],
              [-3.836713075999967, 13.361702920000027],
              [-3.850813625999933, 13.362279892000174],
              [-3.852294920999952, 13.362340927000162],
              [-3.857299089999913, 13.363983154000039],
              [-3.857635020999908, 13.364093780000132],
              [-3.863697051999907, 13.366083145000175],
              [-3.868124961999911, 13.36753654600011],
              [-3.877156017999937, 13.3705005650001],
              [-3.883156061999955, 13.371970176000104],
              [-3.891738891999921, 13.374071122000032],
              [-3.895339012999898, 13.374953271000095],
              [-3.906061887999897, 13.377578735999975],
              [-3.908441066999956, 13.378161430999967],
              [-3.912365912999974, 13.379121781000038],
              [-3.930912016999969, 13.378691674000095],
              [-3.93545508499983, 13.37812137700007],
              [-3.946603059999916, 13.376720429000102],
              [-3.950396537999836, 13.378345490000015],
              [-3.959265947999882, 13.38214397500019],
              [-3.960848092999868, 13.382822037000153],
              [-3.965601444999948, 13.38581562000013],
              [-3.96753001299993, 13.387029649000112],
              [-3.967640400999926, 13.387148857],
              [-3.972781896999891, 13.392690659000095],
              [-3.973803043999908, 13.393791199000191],
              [-3.973971366999876, 13.39666462100007],
              [-3.974205970999947, 13.400671959000022],
              [-3.974536418999946, 13.404238701000054],
              [-3.974749088999943, 13.406536103000064],
              [-3.974843977999967, 13.407559396000124],
              [-3.9726285939999, 13.410490036000112],
              [-3.972389935999956, 13.410805703000051],
              [-3.970067023999945, 13.413879395000151],
              [-3.96492910399985, 13.417203903000086],
              [-3.964040516999944, 13.417778970000143],
              [-3.958272933999979, 13.421509743000172],
              [-3.957331179999926, 13.421660424000095],
              [-3.948205947999952, 13.423118591000048],
              [-3.948225497999943, 13.423866273000101],
              [-3.948230028999888, 13.424039841000081],
              [-3.947848557999976, 13.424140931000125],
              [-3.936520814999881, 13.427147866000098],
              [-3.93149495199998, 13.42848205700011],
              [-3.930838108999978, 13.428656578000073],
              [-3.9251189229999, 13.430174828000077],
              [-3.919750928999974, 13.431599618000064],
              [-3.915641068999889, 13.436519623000095],
              [-3.915695905999883, 13.43876647899998],
              [-3.915721892999898, 13.439832688000024],
              [-3.915837049999936, 13.444561004999969],
              [-3.920175075999964, 13.449060441000142],
              [-3.921442985999875, 13.449431419000121],
              [-3.933136938999951, 13.452854156000058],
              [-3.939817904999927, 13.454810143000145],
              [-3.940190076999897, 13.45506668000013],
              [-3.946270941999899, 13.459260941000025],
              [-3.946659802999932, 13.459979057999988],
              [-3.950206993999871, 13.466531754000016],
              [-3.949609995999936, 13.470910072000095],
              [-3.951644897999927, 13.477835655000149],
              [-3.951818941999932, 13.47842693400014],
              [-3.956789016999949, 13.495340347000081],
              [-3.958141565999938, 13.496325493000029],
              [-3.958637713999963, 13.496686935000071],
              [-3.960095404999947, 13.49775028300013],
              [-3.962290047999886, 13.499349595000126],
              [-3.963649033999957, 13.499608994000027],
              [-3.966474055999868, 13.500148772999978],
              [-3.97197198899994, 13.501199722000024],
              [-3.981441974999939, 13.497159958000054],
              [-3.984533071999977, 13.495841981000183],
              [-3.984632968999904, 13.495609285000114],
              [-3.986531972999899, 13.491201401000069],
              [-3.98470449499996, 13.48129749400016],
              [-3.983278990999963, 13.473571777000132],
              [-3.984205721999899, 13.463801384000078],
              [-3.984467268999936, 13.461043358000154],
              [-3.984590052999977, 13.45975017700016],
              [-3.998430013999894, 13.439880371000129],
              [-3.999384880999855, 13.439052582000102],
              [-4.005793093999898, 13.433500290000097],
              [-4.016526221999868, 13.431521416000123],
              [-4.020058154999902, 13.43087005600006],
              [-4.021489143999929, 13.430494309000153],
              [-4.036070824999911, 13.426666259000172],
              [-4.036236762999977, 13.426623345000166],
              [-4.036398887999951, 13.426581383000041],
              [-4.050342560999923, 13.419624329000158],
              [-4.050467966999918, 13.419562341000187],
              [-4.053295134999871, 13.41815185500019],
              [-4.05333995899997, 13.418129922000048],
              [-4.05834722599991, 13.411570549000089],
              [-4.060806750999859, 13.409714700000109],
              [-4.068724154999927, 13.403741837000155],
              [-4.077091216999975, 13.400382042000103],
              [-4.084540843999946, 13.397390365000092],
              [-4.088462829999912, 13.39638710000014],
              [-4.092476844999965, 13.395360947000029],
              [-4.102580546999889, 13.385919572000034],
              [-4.103104113999962, 13.38482856800016],
              [-4.108606815999963, 13.373358726000049],
              [-4.110839842999894, 13.359290124000097],
              [-4.11273050199992, 13.352416040000094],
              [-4.115362167999876, 13.342849732000104],
              [-4.1226921089999, 13.326121330000149],
              [-4.133371828999941, 13.311840058000143],
              [-4.136400698999978, 13.310095787000137],
              [-4.137539863999905, 13.30943965900002],
              [-4.139568328999872, 13.307744979000176],
              [-4.144899844999941, 13.303291321000074],
              [-4.145175456999937, 13.302686692000066],
              [-4.147126198999956, 13.298411369000064],
              [-4.153138160999902, 13.292997360000186],
              [-4.156311033999884, 13.290140152000049],
              [-4.160728930999937, 13.287563324000189],
              [-4.163715838999963, 13.285821914000167],
              [-4.178516864999892, 13.276729583000076],
              [-4.190937518999874, 13.272897721000106],
              [-4.192265033999945, 13.272488594000095],
              [-4.195525646999897, 13.271226883000054],
              [-4.196691035999947, 13.270775794000087],
              [-4.198390483999958, 13.270118712999988],
              [-4.203376769999977, 13.26819038300016],
              [-4.205425737999974, 13.267396926000117],
              [-4.206214904999968, 13.267091752000113],
              [-4.213319777999971, 13.263245584],
              [-4.216919898999947, 13.261297225000021],
              [-4.220224856999835, 13.259509086000151],
              [-4.222083568999949, 13.258502007],
              [-4.22266101799994, 13.258189202000153],
              [-4.237325190999854, 13.246489526000062],
              [-4.2388148299999, 13.245301248000146],
              [-4.243204115999902, 13.241798401000153],
              [-4.246065139999928, 13.239515304000179],
              [-4.250174999999899, 13.236236573000042],
              [-4.251513957999919, 13.235168458000032],
              [-4.252327918999868, 13.234519005000038],
              [-4.254056929999933, 13.233139038000161],
              [-4.258329866999873, 13.229729653000049],
              [-4.260787009999888, 13.224841119000075],
              [-4.260417937999819, 13.219559669000034],
              [-4.257940291999887, 13.214709282000115],
              [-4.254733085999931, 13.208431245000156],
              [-4.251079081999933, 13.205615997999985],
              [-4.247788905999926, 13.203080177000061],
              [-4.246273516999963, 13.201010705000101],
              [-4.243651866999926, 13.1974296570001],
              [-4.241821288999972, 13.190035821000095],
              [-4.241559981999956, 13.188981056000102],
              [-4.242722987999969, 13.187437057000182],
              [-4.246327876999885, 13.182649612000091],
              [-4.249172687999931, 13.181119918000036],
              [-4.249449730999913, 13.180969238000159],
              [-4.252119063999885, 13.179519653000057],
              [-4.253738403999932, 13.179444314000079],
              [-4.262434959999894, 13.179041863000123],
              [-4.272521019999886, 13.180628777000152],
              [-4.276340007999977, 13.18122959100009],
              [-4.279737472999955, 13.181076051000048],
              [-4.28736209799996, 13.180730820000122],
              [-4.295575619999966, 13.176925659000176],
              [-4.303143501999898, 13.173420907000093],
              [-4.303599833999897, 13.173210145000041],
              [-4.320001124999919, 13.161940574000084],
              [-4.323439597999936, 13.159578324000051],
              [-4.33215284399995, 13.153593063000187],
              [-4.336578845999952, 13.150552750000031],
              [-4.342009067999925, 13.147994041000175],
              [-4.343831538999893, 13.147134781000148],
              [-4.345856188999903, 13.146181106000029],
              [-4.351119994999976, 13.140989305000062],
              [-4.355400085999975, 13.133990287000017],
              [-4.35605955199992, 13.129271508000159],
              [-4.356197832999897, 13.1282815940001],
              [-4.356496809999953, 13.12614822400019],
              [-4.356985092999935, 13.122678757000074],
              [-4.353531837999924, 13.116729735999968],
              [-4.353299139999876, 13.116329194000059],
              [-4.351186275999851, 13.114647866000041],
              [-4.333408832999851, 13.100500108000062],
              [-4.323289870999872, 13.090169906000142],
              [-4.313107013999968, 13.077540397000121],
              [-4.305850028999942, 13.066911699000173],
              [-4.300912856999901, 13.059680940000078],
              [-4.296955584999864, 13.05055618300014],
              [-4.291437148999876, 13.037831306000044],
              [-4.289579391999894, 13.032467842000131],
              [-4.288339137999969, 13.02888965700015],
              [-4.281557082999939, 13.009329796000088],
              [-4.281499862999851, 13.009261132000063],
              [-4.274338244999967, 13.000686646000133],
              [-4.273543834999884, 12.999735833000045],
              [-4.256149769999979, 12.978910447000032],
              [-4.241723060999846, 12.96475505900014],
              [-4.23855686099995, 12.961647988000152],
              [-4.237621783999941, 12.960729598],
              [-4.234537601999932, 12.954804421000119],
              [-4.231948852999892, 12.949831010000139],
              [-4.231389521999972, 12.945740700999977],
              [-4.230975150999882, 12.94271659900005],
              [-4.230970382999942, 12.94268036],
              [-4.230224132999922, 12.937218666000035],
              [-4.230515003999926, 12.93482112900017],
              [-4.232434748999935, 12.919153215000165],
              [-4.232976913999949, 12.914728165000156],
              [-4.233329772999923, 12.911849975000052],
              [-4.232409, 12.903370858000017],
              [-4.233638762999931, 12.896142007000094],
              [-4.236691950999955, 12.878190995000068],
              [-4.236595630999887, 12.874377252000045],
              [-4.236436843999968, 12.868081094000104],
              [-4.238499164999894, 12.856990815000074],
              [-4.236279010999965, 12.843480111000076],
              [-4.231100080999909, 12.824060440999972],
              [-4.228181838999944, 12.80707931600017],
              [-4.227241992999893, 12.801609994000046],
              [-4.227965353999821, 12.785386087000177],
              [-4.228289126999869, 12.778129577000186],
              [-4.233339785999874, 12.755470275000107],
              [-4.239275931999941, 12.740151405000063],
              [-4.241209029999936, 12.737093926000114],
              [-4.245996951999871, 12.729520799000113],
              [-4.246165751999911, 12.729253769000024],
              [-4.246206283999868, 12.729189873000109],
              [-4.247777937999899, 12.726705552000169],
              [-4.24780130399995, 12.726668358000097],
              [-4.248288153999908, 12.725898742000027],
              [-4.249429702999976, 12.725230218000092],
              [-4.257106779999958, 12.720735550000029],
              [-4.258026122999979, 12.720197676999987],
              [-4.264679909999927, 12.716301919000102],
              [-4.267288206999922, 12.714412689000085],
              [-4.271296501999927, 12.711511612000095],
              [-4.275306701999966, 12.70860862699999],
              [-4.276189803999955, 12.707969666000167],
              [-4.287691115999905, 12.702478409000094],
              [-4.294475077999948, 12.699239730000102],
              [-4.311305999999888, 12.69768142700002],
              [-4.318204879999883, 12.699332237000021],
              [-4.32262182199986, 12.700388909000083],
              [-4.327044009999895, 12.702033996000011],
              [-4.334216117999858, 12.704701423000188],
              [-4.352508067999963, 12.723340034000046],
              [-4.357758998999941, 12.727121354000076],
              [-4.364815711999938, 12.729684829000178],
              [-4.368457794999927, 12.731007576000138],
              [-4.369023798999933, 12.731213571000183],
              [-4.369589805999965, 12.731419563000031],
              [-4.379889965999894, 12.731169701000113],
              [-4.380817889999946, 12.730986595000047],
              [-4.383323191999921, 12.730490684000131],
              [-4.387384891999886, 12.729687692000141],
              [-4.393186091999951, 12.728541375000077],
              [-4.400362014999928, 12.727258683000173],
              [-4.409239768999953, 12.725672721000024],
              [-4.414179802999968, 12.724789620000081],
              [-4.426559924999935, 12.72332954400008],
              [-4.439459799999952, 12.723699571000054],
              [-4.447128771999871, 12.72120857200008],
              [-4.458933829999921, 12.720662116000142],
              [-4.468664168999965, 12.720211030000144],
              [-4.470162868999978, 12.720833779000031],
              [-4.473879814999862, 12.722379684000146],
              [-4.476916789999962, 12.72204113100014],
              [-4.479249001999904, 12.721780776000173],
              [-4.480245590999971, 12.721006393000152],
              [-4.480960368999945, 12.720451356000126],
              [-4.481645106999963, 12.719920159000139],
              [-4.484762191999948, 12.717500686000051],
              [-4.487117767999962, 12.714008332000105],
              [-4.489498138999977, 12.710478784000088],
              [-4.48763990499998, 12.684309960000064],
              [-4.489559649999933, 12.674512864],
              [-4.491353033999928, 12.665361405000169],
              [-4.489897250999889, 12.660216331000072],
              [-4.487475871999948, 12.651660919999983],
              [-4.477878092999902, 12.633958816000131],
              [-4.475843906999899, 12.63284397100017],
              [-4.474468229999957, 12.632090568000081],
              [-4.472033977999899, 12.630758286000173],
              [-4.470793247999893, 12.630078316000038],
              [-4.466229915999975, 12.627579689000129],
              [-4.46522188199998, 12.626331329000038],
              [-4.46332216299993, 12.623978616000159],
              [-4.448462963999873, 12.61787414500003],
              [-4.444849967999971, 12.616389275000074],
              [-4.439412116999904, 12.61222267200003],
              [-4.437881945999891, 12.611051560000135],
              [-4.434094905999871, 12.608149529000059],
              [-4.433138845999906, 12.607187271000043],
              [-4.429687976999958, 12.603711129000033],
              [-4.428080081999951, 12.602091790000088],
              [-4.425112724999849, 12.597956657000111],
              [-4.419813156999965, 12.590571403000126],
              [-4.418360233999977, 12.585584641000025],
              [-4.415955065999924, 12.577328682000029],
              [-4.414555072999917, 12.576022148999982],
              [-4.413074015999939, 12.57464027400016],
              [-4.410409927999979, 12.562279702000069],
              [-4.4061908729999, 12.55319118599999],
              [-4.396749018999969, 12.541241646],
              [-4.393457888999933, 12.531889916000068],
              [-4.400129793999895, 12.51815032899998],
              [-4.407453536999924, 12.509172441000032],
              [-4.412383078999881, 12.503129960000024],
              [-4.423684119999962, 12.491882324000017],
              [-4.424479007999878, 12.491090774000099],
              [-4.425108909999949, 12.490139962000114],
              [-4.429433822999954, 12.483609200000103],
              [-4.438024044999906, 12.453950883000118],
              [-4.443302154999913, 12.442778587000134],
              [-4.444101809999893, 12.441085816000168],
              [-4.44828176599998, 12.432238579000057],
              [-4.450889109999878, 12.426719665000121],
              [-4.452919959999974, 12.414710045000163],
              [-4.452449320999961, 12.408433915000103],
              [-4.451835154999912, 12.400250435000146],
              [-4.451407431999883, 12.388463020000131],
              [-4.45098495499991, 12.376811028000077],
              [-4.44127607299987, 12.354510308000044],
              [-4.439858435999895, 12.349055291000127],
              [-4.439223288999926, 12.346611023000037],
              [-4.438966273999881, 12.345623017000037],
              [-4.437891960999934, 12.341488837999975],
              [-4.430552959999943, 12.329250335000154],
              [-4.429615973999944, 12.329271316000018],
              [-4.428098677999969, 12.327444077999985],
              [-4.426841258999957, 12.325929642000119],
              [-4.426556587999926, 12.325586319000024],
              [-4.423820018999891, 12.32229042099999],
              [-4.421255111999869, 12.31492710100008],
              [-4.42104005899995, 12.314310075000151],
              [-4.421090125999967, 12.314205170000093],
              [-4.423699855999928, 12.308720587999971],
              [-4.424201011999969, 12.308825493000029],
              [-4.427540300999965, 12.309525491000045],
              [-4.428728103999958, 12.309774400000151],
              [-4.429582118999974, 12.309952736000128],
              [-4.429994105999981, 12.310197830000163],
              [-4.4366068839999, 12.314134599000056],
              [-4.448122977999901, 12.320989608000104],
              [-4.466969966999955, 12.326029777000144],
              [-4.476830005999887, 12.326939584],
              [-4.484439849999887, 12.322830199000066],
              [-4.486619949999977, 12.316570283000146],
              [-4.48750829599993, 12.301520348],
              [-4.487912177999874, 12.294679642000119],
              [-4.495758532999901, 12.27844238400013],
              [-4.495900153999912, 12.278149605000124],
              [-4.497147082999959, 12.2763834000001],
              [-4.503229140999963, 12.267767906000131],
              [-4.50334215099997, 12.267608644000063],
              [-4.506671904999962, 12.263595581000118],
              [-4.514118194999924, 12.254624368000123],
              [-4.514451980999922, 12.254220962000147],
              [-4.516536235999979, 12.252971648000141],
              [-4.523664950999887, 12.248701096000104],
              [-4.538828848999913, 12.234230995000189],
              [-4.541007040999944, 12.232151985000144],
              [-4.541078090999974, 12.232105255000022],
              [-4.541749000999971, 12.231665612000143],
              [-4.542786121999882, 12.230986595000161],
              [-4.543195247999961, 12.230718613000022],
              [-4.547213076999981, 12.22809124000014],
              [-4.572215557999925, 12.208034515],
              [-4.586606979999942, 12.196490289000167],
              [-4.587009428999977, 12.195873260000042],
              [-4.590879917999871, 12.189940452000144],
              [-4.590882300999965, 12.188923837000118],
              [-4.590896605999944, 12.183340073000124],
              [-4.590898035999942, 12.182843209000055],
              [-4.590899943999943, 12.18210983300014],
              [-4.586864946999924, 12.171180725],
              [-4.583950042999902, 12.16787147500014],
              [-4.583489893999911, 12.16734886100005],
              [-4.582884787999944, 12.167124748000049],
              [-4.578292847999876, 12.165418625000029],
              [-4.575894832999893, 12.163877487999969],
              [-4.572104930999956, 12.161441804000049],
              [-4.566445827999928, 12.156514168],
              [-4.564305304999948, 12.154649736000124],
              [-4.564253806999943, 12.154604911000035],
              [-4.561573028999931, 12.152271272000178],
              [-4.561504840999874, 12.149741173000166],
              [-4.561563968999963, 12.149622918000034],
              [-4.569054126999959, 12.134599686000115],
              [-4.574930189999918, 12.132430077000095],
              [-4.581097126999907, 12.130151748000173],
              [-4.581573963999972, 12.130139352000185],
              [-4.585137365999913, 12.130048753000096],
              [-4.589038848999962, 12.129948616000036],
              [-4.590709209999943, 12.129207611000083],
              [-4.593223571999886, 12.128091812000093],
              [-4.600594997999906, 12.124820710000108],
              [-4.607369899999924, 12.124651910000011],
              [-4.611411571999952, 12.122858048000126],
              [-4.613251685999956, 12.12204170300015],
              [-4.614540098999896, 12.121470451000107],
              [-4.625549792999948, 12.113599777000104],
              [-4.628025055999899, 12.110485076999964],
              [-4.633012771999972, 12.104209900000114],
              [-4.637453079999887, 12.095349312000053],
              [-4.640442847999907, 12.080060958000047],
              [-4.64067506799995, 12.078871727000148],
              [-4.641974926999922, 12.072230339000043],
              [-4.643644809999898, 12.069746017000057],
              [-4.64443778999987, 12.068567277],
              [-4.645154475999959, 12.067502975000082],
              [-4.646699905999981, 12.065210342000171],
              [-4.650180816999978, 12.061810493000166],
              [-4.65105295199993, 12.060958863000053],
              [-4.658310889999939, 12.053871156000071],
              [-4.669288158999962, 12.053352357000051],
              [-4.669993876999911, 12.053561211000101],
              [-4.683187960999931, 12.057360649000032],
              [-4.68885278599987, 12.056755066000107],
              [-4.69740819999987, 12.055839538000043],
              [-4.700687885999969, 12.053924561000144],
              [-4.701482772999952, 12.05346012200016],
              [-4.707304000999955, 12.050060272000053],
              [-4.710289000999978, 12.046489715000121],
              [-4.711860178999871, 12.044610977000104],
              [-4.713240145999919, 12.042960167000103],
              [-4.714054106999868, 12.041986466000139],
              [-4.717250823999962, 12.038160324999978],
              [-4.71743965099995, 12.037934304000032],
              [-4.717719078999949, 12.037599564000061],
              [-4.717687129999888, 12.036399842000094],
              [-4.717666625999868, 12.035615921000158],
              [-4.717639922999979, 12.034609796000098],
              [-4.717625141999918, 12.034090042000116],
              [-4.717569351999941, 12.032020569000053],
              [-4.717539787999954, 12.030929566000111],
              [-4.72459983899995, 12.006830216000083],
              [-4.725093363999918, 12.006214142999966],
              [-4.731196402999956, 11.998599052999964],
              [-4.731379985999979, 11.998370170000157],
              [-4.731626510999945, 11.998238564000076],
              [-4.732497215999956, 11.997775077000028],
              [-4.734347819999869, 11.996789933000059],
              [-4.74571609599991, 11.999670983000044],
              [-4.746449947999963, 12.001031875000024],
              [-4.747824191999882, 11.999851227000022],
              [-4.752758980999886, 12.000881195000147],
              [-4.75452375399982, 12.001940727999965],
              [-4.760493277999899, 12.005525588000125],
              [-4.763433932999931, 12.007291793000149],
              [-4.787749767999969, 12.007630349000067],
              [-4.791716575999885, 12.008105279000119],
              [-4.804506777999961, 12.009634973000061],
              [-4.806970118999971, 12.0099296570001],
              [-4.807063102999905, 12.009639741000058],
              [-4.807405948999872, 12.008539199000154],
              [-4.814896106999925, 12.00817108200016],
              [-4.826078891999885, 12.007620811000038],
              [-4.83173799399998, 12.009559632000105],
              [-4.83507680799994, 12.012240410000061],
              [-4.837115287999893, 12.015842439000096],
              [-4.837576389999981, 12.016656874999967],
              [-4.837769984999909, 12.017000198000062],
              [-4.837860107999973, 12.017011644000149],
              [-4.838782309999886, 12.017129899000054],
              [-4.84081506699988, 12.017391204000091],
              [-4.846088886999951, 12.013349532000177],
              [-4.84746313099987, 12.01349544600015],
              [-4.856637000999911, 12.014472007000052],
              [-4.857707500999936, 12.013932229000147],
              [-4.862867832999939, 12.01132869700001],
              [-4.868120193999971, 12.006369590000133],
              [-4.881080150999935, 12.001230239000051],
              [-4.881601810999939, 12.001198768000052],
              [-4.887608051999962, 12.000840188000041],
              [-4.89282989499992, 12.003931046000162],
              [-4.900043009999877, 12.002599716000134],
              [-4.901943683999946, 12.003407479000089],
              [-4.906186102999868, 12.005211831000167],
              [-4.918599604999884, 12.001005174],
              [-4.920087813999942, 12.000500680000187],
              [-4.921642779999957, 12.000528336000173],
              [-4.924075125999934, 12.00057125100011],
              [-4.94108533799988, 12.000855447000163],
              [-4.953205108999953, 12.001058579000073],
              [-4.953289984999856, 12.00105953100018],
              [-4.953404425999906, 12.000967980000155],
              [-4.953433989999951, 12.000944138000136],
              [-4.959474086999933, 11.996081353000022],
              [-4.966528892999918, 11.994376183000156],
              [-4.968996047999951, 11.993780135000065],
              [-4.973810195999931, 11.989979744000095],
              [-4.97683715799991, 11.989667892000057],
              [-4.977084636999962, 11.989832878000016],
              [-4.980169771999897, 11.991889954000044],
              [-4.985818386999881, 11.98695755000017],
              [-4.988852023999925, 11.984310151000045],
              [-4.990547655999876, 11.983945847000086],
              [-4.990900039999815, 11.983870506000187],
              [-4.991117, 11.983823776000065],
              [-4.994894026999873, 11.98301124600016],
              [-4.994987963999904, 11.983035087000076],
              [-4.995957373999943, 11.983286858000042],
              [-4.999999045999914, 11.984338760000185],
              [-5.002813337999896, 11.985217094000063],
              [-5.006220817999918, 11.986281395999981],
              [-5.012060165999912, 11.986650466000128],
              [-5.017117022999969, 11.983653068000137],
              [-5.018364428999973, 11.982913971000187],
              [-5.021777628999871, 11.980891229000122],
              [-5.034790038999972, 11.983961105000105],
              [-5.049791811999967, 11.982011796000052],
              [-5.060884951999981, 11.977804184000092],
              [-5.061820982999905, 11.977449417000116],
              [-5.073511123999936, 11.977259636000156],
              [-5.075912952999886, 11.979290963000096],
              [-5.078549861999932, 11.98151969800017],
              [-5.079626559999952, 11.98105049100019],
              [-5.082562922999955, 11.979769706000184],
              [-5.08293437999987, 11.97859573400018],
              [-5.083068848999972, 11.978170396000166],
              [-5.085619925999936, 11.977813722000121],
              [-5.087289808999913, 11.97758197800016],
              [-5.087710856999934, 11.975732804000074],
              [-5.08807182299995, 11.974148750000154],
              [-5.088577746999874, 11.97367286799999],
              [-5.091177940999899, 11.971229553000114],
              [-5.093877792999933, 11.970155716000136],
              [-5.101390837999929, 11.967166901000041],
              [-5.101506232999952, 11.967121124000073],
              [-5.101560114999927, 11.967100143000039],
              [-5.103445053999963, 11.96638202600019],
              [-5.111536024999907, 11.963300705000051],
              [-5.114132881999922, 11.962711334000062],
              [-5.126732826999898, 11.959850312000128],
              [-5.128174304999902, 11.958738328000152],
              [-5.129117011999938, 11.95801067400015],
              [-5.140060901999846, 11.949568748000104],
              [-5.140799998999967, 11.949269295000136],
              [-5.1434850699999, 11.948181153000178],
              [-5.145771026999967, 11.947256088000074],
              [-5.145959853999955, 11.947179793999965],
              [-5.146986960999925, 11.947750090999989],
              [-5.149417399999948, 11.949098587000037],
              [-5.152468203999945, 11.949301721000154],
              [-5.154551982999976, 11.949440957000093],
              [-5.155508995999924, 11.948639870000022],
              [-5.156524180999895, 11.947791100000131],
              [-5.156668184999944, 11.946330071000091],
              [-5.157662391999963, 11.936281204000068],
              [-5.157968997999888, 11.933179855000105],
              [-5.161737917999972, 11.931889535000096],
              [-5.162443637999957, 11.932102203000113],
              [-5.163401602999898, 11.932391166000116],
              [-5.169164180999928, 11.934129715000154],
              [-5.174627303999955, 11.931718827000168],
              [-5.177746772999967, 11.930343628000173],
              [-5.178730010999857, 11.929909707000036],
              [-5.178995608999969, 11.929459571000109],
              [-5.180438995999964, 11.927009584000189],
              [-5.180328845999895, 11.921815873000071],
              [-5.180319785999927, 11.921389580000039],
              [-5.180401801999892, 11.921182633000058],
              [-5.184345721999875, 11.911217689000125],
              [-5.18556022599995, 11.90814972000004],
              [-5.188639162999948, 11.904750823000086],
              [-5.192957877999959, 11.904067994000172],
              [-5.19299984, 11.904060364000088],
              [-5.194579123999972, 11.902094841000064],
              [-5.19470501, 11.901941299000157],
              [-5.197215079999978, 11.898821831000191],
              [-5.206715107999969, 11.896676065000065],
              [-5.20728540399989, 11.896546364000073],
              [-5.207666874999916, 11.896460533000038],
              [-5.208673, 11.896233559000052],
              [-5.210773944999971, 11.895760537000172],
              [-5.216474054999878, 11.887121200000081],
              [-5.220809935999967, 11.886380195000129],
              [-5.222683905999872, 11.88236141200008],
              [-5.224471091999874, 11.878529549000177],
              [-5.235912800999927, 11.870321275000151],
              [-5.240458965999949, 11.869582176000165],
              [-5.241443157999925, 11.869421960000068],
              [-5.243430137999951, 11.869099618000064],
              [-5.250490188999947, 11.863320352000073],
              [-5.250859260999903, 11.862572670000191],
              [-5.255051134999917, 11.854080201000158],
              [-5.25492143699995, 11.854048730000102],
              [-5.25247716999985, 11.853450775000113],
              [-5.252347945999816, 11.850749970000038],
              [-5.253931044999945, 11.846941947000175],
              [-5.254381178999893, 11.845858574000147],
              [-5.254001617999961, 11.844735146000119],
              [-5.253286837999951, 11.842619897000134],
              [-5.256222724999873, 11.842415810000034],
              [-5.263349056999914, 11.841920854000136],
              [-5.267697810999948, 11.835581779999984],
              [-5.270266055999969, 11.835630417000061],
              [-5.270921230999932, 11.836338997000041],
              [-5.271602152999947, 11.837077142000112],
              [-5.274106025999913, 11.83979034400005],
              [-5.275360106999926, 11.839940071000115],
              [-5.278052805999892, 11.840616226000179],
              [-5.278799057999891, 11.840803147000088],
              [-5.280182837999917, 11.841150283000047],
              [-5.281242369999916, 11.842362405000131],
              [-5.28293609699989, 11.844300271000066],
              [-5.283193588999893, 11.847228050000126],
              [-5.283309936999956, 11.848549842000068],
              [-5.284007549999899, 11.852403640000091],
              [-5.284110068999951, 11.85297012400008],
              [-5.286190031999922, 11.854169846000048],
              [-5.290539265999882, 11.853824617000157],
              [-5.292974949999973, 11.853631020000023],
              [-5.298504828999967, 11.85072040600005],
              [-5.301090239999894, 11.848814010000069],
              [-5.304174899999964, 11.84654045100018],
              [-5.307343005999883, 11.838311195000131],
              [-5.30829095799993, 11.838411332000021],
              [-5.315889358999925, 11.83927250000005],
              [-5.321825981999893, 11.83994579400013],
              [-5.322480201999952, 11.840020180000067],
              [-5.322646139999961, 11.840275765000172],
              [-5.323109626999951, 11.840989113000035],
              [-5.323627948999956, 11.841791153000145],
              [-5.324478148999958, 11.842511177],
              [-5.327238082999941, 11.845141410000167],
              [-5.328325270999926, 11.84495162900015],
              [-5.328680037999902, 11.844889641000179],
              [-5.331571100999895, 11.840354921000142],
              [-5.33347988099996, 11.837360382999975],
              [-5.336259840999901, 11.83559322300016],
              [-5.349366186999873, 11.827260972000033],
              [-5.3541269299999, 11.824850082000069],
              [-5.359477995999953, 11.824398995000138],
              [-5.360682009999891, 11.824297906000027],
              [-5.367261885999881, 11.832731246000037],
              [-5.367987631999881, 11.833166124000172],
              [-5.373252868999884, 11.836320877000162],
              [-5.376039504999881, 11.836688042000048],
              [-5.379541873999926, 11.837149620000048],
              [-5.381329536999886, 11.841050148000022],
              [-5.381464003999895, 11.841343880000181],
              [-5.381989000999909, 11.842490195999972],
              [-5.388309001999971, 11.841940879999981],
              [-5.389757156999963, 11.839900970000144],
              [-5.397999763999962, 11.836858749000044],
              [-5.404223917999957, 11.836053849000052],
              [-5.406334876999893, 11.835781097000051],
              [-5.407959937999976, 11.833652497000173],
              [-5.408860206999975, 11.832473754000091],
              [-5.409314155999937, 11.831878663000111],
              [-5.409700869999881, 11.829355240000154],
              [-5.410006998999904, 11.827359199000114],
              [-5.409802913999897, 11.826931954000031],
              [-5.409285067999861, 11.825845718000039],
              [-5.408094881999944, 11.823349954000093],
              [-5.400145053999893, 11.819178581000017],
              [-5.397700786999963, 11.816466331000129],
              [-5.396226882999883, 11.814830780000079],
              [-5.394209860999979, 11.814780235000057],
              [-5.393449305999923, 11.815439223000055],
              [-5.391184329999931, 11.817401885000095],
              [-5.39038801199996, 11.818090440000162],
              [-5.387238024999931, 11.81744003200015],
              [-5.386515140999961, 11.81663799200004],
              [-5.383319855999901, 11.813090325000076],
              [-5.381215094999902, 11.812582017000125],
              [-5.374169826999889, 11.8108787540001],
              [-5.373664855999948, 11.809988975000124],
              [-5.369766235999975, 11.80312061300009],
              [-5.36768341099986, 11.802810669000053],
              [-5.365180968999937, 11.802438736000056],
              [-5.364119052999968, 11.801259994000077],
              [-5.361265181999954, 11.798090935000118],
              [-5.358542919999934, 11.796859742000152],
              [-5.357283115999962, 11.796291351000093],
              [-5.355770109999924, 11.798054696000065],
              [-5.355626105999932, 11.798222542000133],
              [-5.354906081999957, 11.799061776000087],
              [-5.354156971999942, 11.802990913000031],
              [-5.351298807999967, 11.805460931000141],
              [-5.34864902399994, 11.803242684000168],
              [-5.347630023999841, 11.802390099000036],
              [-5.337840079999921, 11.801712037000073],
              [-5.336346626999898, 11.800529480000023],
              [-5.334642886999973, 11.79918003099999],
              [-5.334207534999905, 11.798967361],
              [-5.333985805999873, 11.798858644000177],
              [-5.323669910999968, 11.794309616000021],
              [-5.315917014999911, 11.793551445000048],
              [-5.313060283999903, 11.789172172000178],
              [-5.311553000999879, 11.786861419000047],
              [-5.310326099999884, 11.786390305000168],
              [-5.308310030999962, 11.785630226000023],
              [-5.300822734999883, 11.785655022000128],
              [-5.299439907999954, 11.785659790000182],
              [-5.297691345999965, 11.782232285000191],
              [-5.297420025999884, 11.781699180000032],
              [-5.296154975999968, 11.779780387000187],
              [-5.293642997999939, 11.775980949000029],
              [-5.297514914999908, 11.770982743000047],
              [-5.287772655999959, 11.761691094000128],
              [-5.285559176999868, 11.759579658000064],
              [-5.28532648099997, 11.759531976000176],
              [-5.281837462999874, 11.758811950000108],
              [-5.280728340999929, 11.759729385000071],
              [-5.278725146999932, 11.76138687100007],
              [-5.273983001999966, 11.765310286999977],
              [-5.269762039999932, 11.766130448000069],
              [-5.269308090999971, 11.765939714000126],
              [-5.269077777999883, 11.765843392000136],
              [-5.268661022999879, 11.765667916000041],
              [-5.263979910999979, 11.763697624000088],
              [-5.268839835999927, 11.755530358000044],
              [-5.266026496999928, 11.748577118000071],
              [-5.265080927999975, 11.74623966300004],
              [-5.265224456999931, 11.744740486000069],
              [-5.265388964999943, 11.743020057000081],
              [-5.267542362999961, 11.738838196000017],
              [-5.271010875999878, 11.732101441000111],
              [-5.270818233999933, 11.730010987000185],
              [-5.27007722899998, 11.721961021000084],
              [-5.270447731999923, 11.721199035000097],
              [-5.273509024999896, 11.714900017000105],
              [-5.276199818999885, 11.709670067000104],
              [-5.273551939999891, 11.699561118000076],
              [-5.272943973999873, 11.697240830000112],
              [-5.271473884999921, 11.691620826000189],
              [-5.27153921099989, 11.688781738000102],
              [-5.27160596899995, 11.68587112400013],
              [-5.275588034999885, 11.67409038500017],
              [-5.289000033999912, 11.650360108000029],
              [-5.290210722999973, 11.646127702000172],
              [-5.29074192, 11.644271850000052],
              [-5.294144153999923, 11.637414933000173],
              [-5.299405098999898, 11.626811027000144],
              [-5.299204348999922, 11.621980668000106],
              [-5.299092768999913, 11.619290352000064],
              [-5.293193817999963, 11.611620903000073],
              [-5.287268637999944, 11.606937408000078],
              [-5.284440039999936, 11.604701043000034],
              [-5.278639792999968, 11.603420258000028],
              [-5.26371097599997, 11.604805946000113],
              [-5.256031990999873, 11.605519293999976],
              [-5.248699187999875, 11.60620117100018],
              [-5.241261958999928, 11.604881287000069],
              [-5.240999698999929, 11.604471207000074],
              [-5.239925861999893, 11.602790833000086],
              [-5.234686850999935, 11.594609260000027],
              [-5.23041439099984, 11.592365265000069],
              [-5.228672026999902, 11.591450692000024],
              [-5.227647303999959, 11.590289116000179],
              [-5.223245144999964, 11.585301399000059],
              [-5.222859858999868, 11.575990678000153],
              [-5.222654819999946, 11.57118034500013],
              [-5.219389914999908, 11.55815029199999],
              [-5.217639446999954, 11.554466247000107],
              [-5.214572905999944, 11.548011779000035],
              [-5.212463379999974, 11.545044900000164],
              [-5.204774856999904, 11.53423118600017],
              [-5.204226493999954, 11.531102182000097],
              [-5.20389318499997, 11.529199600000027],
              [-5.209452150999937, 11.520820618000073],
              [-5.212403773999881, 11.516369819000147],
              [-5.215003965999927, 11.512451173000102],
              [-5.210954188999949, 11.489370346000044],
              [-5.210760592999918, 11.486714364000022],
              [-5.21048402699995, 11.482919694000145],
              [-5.213718890999928, 11.469361306000053],
              [-5.214361190999966, 11.466670037000029],
              [-5.213602065999908, 11.46133041500002],
              [-5.212357521999877, 11.458170892000169],
              [-5.212309836999964, 11.458049774000131],
              [-5.21091699699997, 11.454171181999982],
              [-5.205696104999959, 11.447970391000069],
              [-5.203199862999895, 11.445019723000144],
              [-5.202948093999851, 11.443396569000129],
              [-5.202390669999886, 11.439800263000109],
              [-5.201982975999954, 11.437170982999987],
              [-5.201097488999949, 11.435491562000038],
              [-5.199525832999939, 11.432510377000028],
              [-5.200261115999922, 11.428830146999985],
              [-5.200510501999929, 11.427580834000082],
              [-5.20060682399992, 11.427098274000116],
              [-5.200932978999902, 11.425470352000104],
              [-5.20191097299994, 11.424253465000049],
              [-5.205644607999943, 11.419604302000039],
              [-5.207437038999899, 11.417371750000029],
              [-5.210968016999971, 11.405480384999976],
              [-5.216320037999935, 11.39570045500011],
              [-5.2195520399999, 11.393264770000087],
              [-5.23173999799991, 11.384080887000039],
              [-5.233850001999883, 11.383190156000182],
              [-5.234505176999903, 11.382915497999988],
              [-5.246335028999965, 11.377959251000163],
              [-5.259379862999879, 11.368350030000101],
              [-5.26365947599993, 11.36184787799999],
              [-5.265651226999978, 11.358820916000184],
              [-5.267480849999913, 11.349888803],
              [-5.267551420999894, 11.346697808000044],
              [-5.26780700799992, 11.335170746000017],
              [-5.26577711099992, 11.298892021000086],
              [-5.265079974999878, 11.286437035],
              [-5.264351843999862, 11.27343082499999],
              [-5.264623165999922, 11.266080855999974],
              [-5.26738119099997, 11.248880387000156],
              [-5.26941108799997, 11.242726326000138],
              [-5.272128104999979, 11.234490395000023],
              [-5.274714468999889, 11.231965065000168],
              [-5.278232096999886, 11.228529931000139],
              [-5.282248974999902, 11.2246027],
              [-5.285943984999904, 11.220991135000077],
              [-5.314230918999954, 11.206649781000067],
              [-5.317272662999926, 11.204660415000092],
              [-5.323060511999813, 11.20087528300013],
              [-5.328189849999944, 11.197520256000075],
              [-5.332215785999949, 11.183136939000065],
              [-5.332990169999903, 11.180370332000052],
              [-5.332749365999973, 11.178064345999985],
              [-5.332220076999874, 11.172989846000064],
              [-5.335709571999928, 11.154349327000091],
              [-5.3359098439999, 11.153280258000166],
              [-5.336087702999976, 11.152740478999988],
              [-5.33837556799989, 11.145789146000141],
              [-5.340880394999942, 11.138178825000068],
              [-5.341400146999945, 11.136599541000123],
              [-5.341981410999892, 11.136051178000173],
              [-5.345095156999889, 11.133111955000061],
              [-5.349012850999941, 11.129409791000171],
              [-5.359870909999927, 11.120803833000082],
              [-5.361474989999977, 11.119532586000105],
              [-5.363715171999957, 11.117759705000083],
              [-5.39420318599997, 11.097940444000074],
              [-5.405186175999972, 11.094241143000147],
              [-5.407163142999877, 11.093575477000115],
              [-5.408987998999976, 11.092960358000141],
              [-5.412088393999966, 11.092189790000134],
              [-5.458413124999879, 11.080671310000128],
              [-5.459117889999959, 11.080661774000021],
              [-5.465427397999918, 11.080605507000087],
              [-5.466103076999957, 11.080599784000071],
              [-5.470592974999931, 11.077711106000152],
              [-5.480226993999906, 11.074942589000159],
              [-5.484133719999932, 11.073820114000057],
              [-5.490749834999974, 11.071919442000024],
              [-5.492228506999936, 11.069656372],
              [-5.496531962999939, 11.063069343000052],
              [-5.496310232999974, 11.058682442000077],
              [-5.49627208599992, 11.057925224000144],
              [-5.495800971999927, 11.04850959800001],
              [-5.49456977899996, 11.023920058000101],
              [-5.493598460999976, 11.020380020000118],
              [-5.486965654999949, 10.996201515000052],
              [-5.486569881999969, 10.994759559000101],
              [-5.486575603999938, 10.993476867000027],
              [-5.486600875999955, 10.987646103000031],
              [-5.486629961999881, 10.980959893000147],
              [-5.487223146999952, 10.980093002000103],
              [-5.489271162999955, 10.977100372000109],
              [-5.489358901999935, 10.97601699900008],
              [-5.489586829999951, 10.973200799000153],
              [-5.48875284199994, 10.972340584000108],
              [-5.487610339999947, 10.971162795999987],
              [-5.486852169999963, 10.970380784000156],
              [-5.485953807999977, 10.97010040400005],
              [-5.481463909999945, 10.968700408000188],
              [-5.479153155999882, 10.96797943200005],
              [-5.471815109999966, 10.965688705000161],
              [-5.469803332999959, 10.963171006000096],
              [-5.468637943999909, 10.961711883000078],
              [-5.469266890999904, 10.943091393000032],
              [-5.464338779999935, 10.93400859799999],
              [-5.464950083999952, 10.905500412000038],
              [-5.449038982999923, 10.873478889000125],
              [-5.448430061999886, 10.872249604000103],
              [-5.44664716799997, 10.871672631000081],
              [-5.44396209699994, 10.870804786000122],
              [-5.435481071999902, 10.868062020000025],
              [-5.433637618999967, 10.866244317000167],
              [-5.431293010999923, 10.863931656000034],
              [-5.427362919999951, 10.860054970000078],
              [-5.426710128999901, 10.859411240000099],
              [-5.424993990999951, 10.857719422000116],
              [-5.422415255999908, 10.852801322000118],
              [-5.422309874999883, 10.852600098000039],
              [-5.424050806999958, 10.836529732000145],
              [-5.428989887999876, 10.823530198000128],
              [-5.432678698999951, 10.819516182000029],
              [-5.435420989999898, 10.816531182000119],
              [-5.43516779, 10.81481266000003],
              [-5.435012817999962, 10.813758850000056],
              [-5.436480044999939, 10.813020706000088],
              [-5.438074111999981, 10.812218665999978],
              [-5.441435336999973, 10.807144165000125],
              [-5.444087027999899, 10.803140640000095],
              [-5.444441794999932, 10.79471492800019],
              [-5.444561004999969, 10.791879655000116],
              [-5.445012091999956, 10.790630341000167],
              [-5.44777393399994, 10.78298187300004],
              [-5.44870233499995, 10.774927139999988],
              [-5.449203014999966, 10.770581245000074],
              [-5.450050829999952, 10.769379616000037],
              [-5.450298308999948, 10.769029617000115],
              [-5.452565192999828, 10.765819549000071],
              [-5.459127425999952, 10.764539719000027],
              [-5.466351986999939, 10.76313114300001],
              [-5.470990180999934, 10.760253906000059],
              [-5.471331119999888, 10.760042191000082],
              [-5.473899840999877, 10.758449553999981],
              [-5.46997022599993, 10.735369682000112],
              [-5.474539279999931, 10.72317028000009],
              [-5.475461482999947, 10.720707893000167],
              [-5.476381778999894, 10.718250275000059],
              [-5.476414202999933, 10.717638969000063],
              [-5.479444979999869, 10.660578727000086],
              [-5.48026323299996, 10.645180703000051],
              [-5.479894160999891, 10.62864112799997],
              [-5.482114314999933, 10.624756814000136],
              [-5.482879638999918, 10.623417854000024],
              [-5.480969906999917, 10.615089416000103],
              [-5.480550766999954, 10.614851952000038],
              [-5.478445052999916, 10.613659859000052],
              [-5.477683067999919, 10.610323907000065],
              [-5.477600098999972, 10.609959603000163],
              [-5.477511406999952, 10.609933854000076],
              [-5.475976942999978, 10.609487533000163],
              [-5.471563815999957, 10.608201981000036],
              [-5.470302103999927, 10.607256889000155],
              [-5.467473028999962, 10.605138779000072],
              [-5.467013834999932, 10.603623391000099],
              [-5.465870380999888, 10.599843979000184],
              [-5.465088844999968, 10.597260475000155],
              [-5.4652061459999, 10.596560478000015],
              [-5.465896129999976, 10.592451095000115],
              [-5.472893237999926, 10.580869675000031],
              [-5.46977949199993, 10.572850227000117],
              [-5.465415, 10.561609267000165],
              [-5.468870639999977, 10.556563378000021],
              [-5.470221995999964, 10.554590226000187],
              [-5.472806453999908, 10.551652908000165],
              [-5.477375982999945, 10.546460152],
              [-5.482089518999942, 10.529141426000024],
              [-5.485219955999924, 10.517640114000187],
              [-5.48973131199989, 10.508229256000106],
              [-5.491111754999906, 10.505349159000104],
              [-5.495695113999886, 10.495789528000046],
              [-5.494947433999926, 10.493404388999977],
              [-5.494607925999901, 10.492321015000073],
              [-5.495088577999979, 10.491866111999968],
              [-5.497933863999947, 10.489171983000119],
              [-5.49948596899992, 10.482069970000168],
              [-5.502234935999979, 10.480284691000179],
              [-5.503254890999926, 10.479621888000111],
              [-5.506188868999971, 10.473010063000117],
              [-5.508157252999922, 10.472007751000092],
              [-5.508778094999968, 10.471691131000057],
              [-5.50944566599992, 10.469973563000053],
              [-5.510993003999886, 10.465991021000036],
              [-5.513011932999973, 10.463804244000187],
              [-5.514327049999963, 10.462380410000151],
              [-5.514450549999879, 10.461136819000046],
              [-5.514624118999961, 10.459389687000055],
              [-5.512629507999918, 10.454429627000025],
              [-5.51219892499995, 10.453359605000117],
              [-5.513036251999949, 10.451396943000077],
              [-5.516574859999878, 10.443100929000025],
              [-5.518324851999978, 10.43900108400004],
              [-5.518749713999966, 10.434888839000052],
              [-5.518918036999878, 10.433259965000104],
              [-5.524500846999956, 10.43292045700008],
              [-5.530447959999947, 10.437191010000163],
              [-5.532735824999975, 10.437698364000028],
              [-5.533916949999934, 10.437958718000118],
              [-5.535060881999812, 10.439004899000054],
              [-5.539352894999922, 10.442927361000045],
              [-5.543297766999899, 10.446531297000035],
              [-5.543715, 10.446911812000167],
              [-5.553559780999819, 10.453561784000044],
              [-5.558597086999953, 10.459018707000098],
              [-5.56079101499995, 10.461396219000164],
              [-5.563094139999976, 10.46389103000007],
              [-5.569808006999949, 10.464960098000063],
              [-5.579412936999916, 10.461480140000106],
              [-5.589561939999953, 10.454341888000101],
              [-5.597033022999938, 10.452659607000101],
              [-5.611876963999976, 10.454130173000067],
              [-5.626298903999896, 10.453280449000147],
              [-5.626368044999936, 10.453303337000079],
              [-5.626496790999909, 10.453348159000029],
              [-5.63773393699995, 10.457205772000123],
              [-5.638020037999979, 10.457304955000041],
              [-5.640603064999937, 10.458190918000071],
              [-5.653420924999978, 10.456391334000045],
              [-5.672576904999914, 10.462539673000094],
              [-5.676299094999877, 10.462869645000069],
              [-5.679665089999958, 10.461158753000063],
              [-5.700797079999916, 10.444520951000129],
              [-5.708201884999937, 10.441040993000115],
              [-5.733262061999881, 10.437713624000082],
              [-5.73340892799996, 10.437693596000031],
              [-5.735237120999955, 10.437451362000047],
              [-5.73811578699997, 10.435802460000048],
              [-5.739140033999945, 10.435215949999986],
              [-5.740640163999899, 10.434356689000026],
              [-5.741300105999926, 10.433978080999964],
              [-5.745050906999893, 10.431830406000188],
              [-5.74580097199987, 10.431400298000142],
              [-5.749152183999968, 10.431411744],
              [-5.749699115999931, 10.431412696000109],
              [-5.752329826999926, 10.431421279000176],
              [-5.757822989999909, 10.43144130800016],
              [-5.769649981999976, 10.438535690000037],
              [-5.769841192999934, 10.438650131000031],
              [-5.771650789999967, 10.439736366000091],
              [-5.772508144999961, 10.440250397999989],
              [-5.777385233999894, 10.44036197600019],
              [-5.78380489299991, 10.437458039000148],
              [-5.785819053999944, 10.436547279000138],
              [-5.790522098999873, 10.434419632000186],
              [-5.79652118599995, 10.43040466399998],
              [-5.797263144999874, 10.429907798000102],
              [-5.799451827999974, 10.428442955000151],
              [-5.800839900999961, 10.42751407600008],
              [-5.804190159999962, 10.42527198800002],
              [-5.806303023999817, 10.42441272799999],
              [-5.806406020999873, 10.424370766000095],
              [-5.809453963999943, 10.42313289599997],
              [-5.809579849999977, 10.423081397999965],
              [-5.810679911999955, 10.423048018999964],
              [-5.811307905999854, 10.423028946000102],
              [-5.814229964999925, 10.422940254000025],
              [-5.820330143999911, 10.419175148000079],
              [-5.821481227999868, 10.417661667000175],
              [-5.82512903099996, 10.412820815000032],
              [-5.828505991999975, 10.405441284000062],
              [-5.829511165999975, 10.403249741000025],
              [-5.828927993999969, 10.397709847000101],
              [-5.837552070999948, 10.391025544000115],
              [-5.840864180999915, 10.388458251000088],
              [-5.846022129999938, 10.384461404000035],
              [-5.846131800999956, 10.384374619000027],
              [-5.848142146999919, 10.382818222000139],
              [-5.848290918999965, 10.382701874000134],
              [-5.849398135999934, 10.382337571000107],
              [-5.858805178999944, 10.379241944],
              [-5.859578132999957, 10.379173279000099],
              [-5.864870070999928, 10.378700256000116],
              [-5.868546007999896, 10.379960060000087],
              [-5.870821, 10.380739212000094],
              [-5.873106001999872, 10.379321098000162],
              [-5.873190880999971, 10.379267693000088],
              [-5.875483990999896, 10.377844811000159],
              [-5.876202106999926, 10.377401353000039],
              [-5.881607054999904, 10.374647141000082],
              [-5.883667946999935, 10.373597145000076],
              [-5.884331225999972, 10.373258591000081],
              [-5.888857841999879, 10.367600441000036],
              [-5.888347148999912, 10.358160018999968],
              [-5.893426893999845, 10.347910881000189],
              [-5.894435881999925, 10.3440818790001],
              [-5.894822119999901, 10.342613220000146],
              [-5.896440029999951, 10.336469650000026],
              [-5.896389960999954, 10.335931777000042],
              [-5.895481109999878, 10.326330185000188],
              [-5.902895927999964, 10.321644783000124],
              [-5.904161929999873, 10.3208436970001],
              [-5.906066893999935, 10.31964015900013],
              [-5.908043861999886, 10.315189362000012],
              [-5.908506870999929, 10.314145089000078],
              [-5.909711836999975, 10.311430931000189],
              [-5.910255909999876, 10.30731391900008],
              [-5.910791872999823, 10.303254128000162],
              [-5.911077022999962, 10.301099777000161],
              [-5.905829905999951, 10.284440994000022],
              [-5.908917904999953, 10.280255318000059],
              [-5.909172056999978, 10.279911041999981],
              [-5.91090393099995, 10.278614044000165],
              [-5.910980223999957, 10.27855682400002],
              [-5.9124717709999, 10.277440071000115],
              [-5.924584865999918, 10.27561187800012],
              [-5.940691948999927, 10.28350925500007],
              [-5.957285880999962, 10.278320313000108],
              [-5.96105194099988, 10.277878762000057],
              [-5.963654994999899, 10.277573585000141],
              [-5.964036940999961, 10.277528762999964],
              [-5.967832087999909, 10.278609276000111],
              [-5.970970152999939, 10.279500008000127],
              [-5.97507, 10.278113365000024],
              [-5.976113795999936, 10.277758598999981],
              [-5.97453308199988, 10.2634687420001],
              [-5.976357937999978, 10.253160476000176],
              [-5.983592033999969, 10.239501001000178],
              [-5.987079142999903, 10.227378845000146],
              [-5.990684985999963, 10.221011162000025],
              [-5.995718954999973, 10.21681499500005],
              [-6.00012922399992, 10.213141441],
              [-6.005209921999949, 10.208906175000095],
              [-6.011456966999901, 10.203701019000164],
              [-6.023159980999935, 10.198860169],
              [-6.041411875999927, 10.200881004000166],
              [-6.045869826999933, 10.201369286000045],
              [-6.051166057999978, 10.203768731000082],
              [-6.060688971999923, 10.203721047000045],
              [-6.080059051999967, 10.198570253000184],
              [-6.084571837999874, 10.198705674000109],
              [-6.100946902999965, 10.199198723000166],
              [-6.121273040999881, 10.205100059000074],
              [-6.132554053999968, 10.210371971000029],
              [-6.149538039999868, 10.22103977300003],
              [-6.158081054999911, 10.223731995000037],
              [-6.162191867999979, 10.223420143000169],
              [-6.171041964999915, 10.222756386000015],
              [-6.172730921999971, 10.222628593000024],
              [-6.183538913999882, 10.228580474000182],
              [-6.19196891799993, 10.235750198000062],
              [-6.195426940999937, 10.238690376000022],
              [-6.193562030999942, 10.251530647000038],
              [-6.197762964999981, 10.252929688000108],
              [-6.198150157999862, 10.253748894000182],
              [-6.199010848999933, 10.255567551000127],
              [-6.199669836999931, 10.256961822000108],
              [-6.20197391499994, 10.25792789400009],
              [-6.21732902499997, 10.256381035000118],
              [-6.222620964999976, 10.259011268000052],
              [-6.223703860999876, 10.262940407000144],
              [-6.22574090899991, 10.281690598000182],
              [-6.231387137999946, 10.290478707000148],
              [-6.23928403799988, 10.297969818000126],
              [-6.238914965999811, 10.298370362000014],
              [-6.235191821999933, 10.302393913000117],
              [-6.232809066999892, 10.304969789000097],
              [-6.22742080699993, 10.307778358000064],
              [-6.226844787999937, 10.308077812000136],
              [-6.225134849999961, 10.308968544000095],
              [-6.220600127999944, 10.312120439000068],
              [-6.219016075999946, 10.313220025000078],
              [-6.21364402699993, 10.31472873600012],
              [-6.205098150999902, 10.313653945999988],
              [-6.204631804999963, 10.31298732700003],
              [-6.201686858999835, 10.308520318000149],
              [-6.199576854999975, 10.302496910000059],
              [-6.194834232999938, 10.302639962000171],
              [-6.194134234999922, 10.30514812500013],
              [-6.19208812699992, 10.31248188100011],
              [-6.187292098999933, 10.317048071999977],
              [-6.185461998999926, 10.318790436000086],
              [-6.187479017999976, 10.32250785799999],
              [-6.192767142999969, 10.325369835000117],
              [-6.197731017999899, 10.332830429000126],
              [-6.198348044999875, 10.336750031000065],
              [-6.197751044999961, 10.338149071000032],
              [-6.194942950999973, 10.344731332000094],
              [-6.191610813999944, 10.348811150000131],
              [-6.186484813999925, 10.349629402000176],
              [-6.185500144999935, 10.350585938999984],
              [-6.175718784999901, 10.360084534000123],
              [-6.174644946999877, 10.361127853000028],
              [-6.167201995999903, 10.361680984000088],
              [-6.1655988699999, 10.363209725000161],
              [-6.165544987999851, 10.363261223000166],
              [-6.166647911999974, 10.366271974000142],
              [-6.174057006999874, 10.367562294000152],
              [-6.183956145999957, 10.372119903000055],
              [-6.18691682899987, 10.379677772000036],
              [-6.187497138999959, 10.381158829000071],
              [-6.18851900199985, 10.382357598000056],
              [-6.190199852999911, 10.384330750000061],
              [-6.19249296299995, 10.387008668000135],
              [-6.18967580799989, 10.390698433000125],
              [-6.188680171999863, 10.392001152000034],
              [-6.188358783999888, 10.393443107000053],
              [-6.186595916999977, 10.401365281000039],
              [-6.185749053999814, 10.40516853400004],
              [-6.184857844999954, 10.40916919700004],
              [-6.183094977999929, 10.410193444000129],
              [-6.17897510499995, 10.412586212000065],
              [-6.177574156999924, 10.41339874200014],
              [-6.17140197699996, 10.408221245000107],
              [-6.167903900999875, 10.408849716000191],
              [-6.166765212999906, 10.409865379000109],
              [-6.164123058999962, 10.412220955000123],
              [-6.16167306899996, 10.418621064000035],
              [-6.163660049999919, 10.423950196000078],
              [-6.168450831999962, 10.428409576000149],
              [-6.18454408599996, 10.437230111000133],
              [-6.187073230999943, 10.442706108000152],
              [-6.187327860999972, 10.443256379000104],
              [-6.187439918999928, 10.443499564999968],
              [-6.189959048999981, 10.44539165600014],
              [-6.190334796999878, 10.450578689000054],
              [-6.191060065999977, 10.460591316000091],
              [-6.189868927999896, 10.463554382000098],
              [-6.189013004999936, 10.46568393699999],
              [-6.188858032999917, 10.466070175000027],
              [-6.185385226999927, 10.469772339000087],
              [-6.182928084999901, 10.472390174000054],
              [-6.18230295099994, 10.472780227000101],
              [-6.18027591799995, 10.474019049999981],
              [-6.177162169999974, 10.475921632000052],
              [-6.175270080999894, 10.477078437999978],
              [-6.171224116999895, 10.479550362000055],
              [-6.17030000599982, 10.480114936000064],
              [-6.168344020999882, 10.481310844000063],
              [-6.165235042999939, 10.484974861000126],
              [-6.164747237999904, 10.485549928000182],
              [-6.165996073999963, 10.488041877],
              [-6.167459009999959, 10.49096107400004],
              [-6.167828082999904, 10.491428375999988],
              [-6.170403957999895, 10.494691849000105],
              [-6.172825813999907, 10.49556732200017],
              [-6.178483008999933, 10.497611046000145],
              [-6.194512844999906, 10.497920990000011],
              [-6.198727129999895, 10.496390343000144],
              [-6.204998968999973, 10.496509552000134],
              [-6.20861101099996, 10.501871110000025],
              [-6.205926894999891, 10.508489608000161],
              [-6.208161829999938, 10.513130188000105],
              [-6.209784983999896, 10.513401031],
              [-6.212294100999941, 10.518184662000124],
              [-6.212522028999899, 10.518619538000053],
              [-6.213338851999879, 10.518877983000039],
              [-6.215240955999889, 10.519479753000098],
              [-6.219266890999904, 10.523811341],
              [-6.220252990999882, 10.524871826000094],
              [-6.221889972999975, 10.524365425000042],
              [-6.222523212999874, 10.524168968000026],
              [-6.224654197999882, 10.523509027000046],
              [-6.225763796999956, 10.522559167000168],
              [-6.231538772999954, 10.514975547000063],
              [-6.231937885999912, 10.514451980000047],
              [-6.235544204999883, 10.514168739000127],
              [-6.237792967999951, 10.513991356000076],
              [-6.242402076999952, 10.515419959999974],
              [-6.245985031999908, 10.516388893000169],
              [-6.247304916999951, 10.518312454000068],
              [-6.248259066999935, 10.519699096000011],
              [-6.249825, 10.521980285000041],
              [-6.251119137999979, 10.527301789000148],
              [-6.246397018999971, 10.543080329000077],
              [-6.244884967999951, 10.54461479299999],
              [-6.244074821999959, 10.545438767000178],
              [-6.243966102999934, 10.54555034700013],
              [-6.239521026999967, 10.550079347000064],
              [-6.235834121999972, 10.551342964000071],
              [-6.231576919999895, 10.552802087000089],
              [-6.227273940999965, 10.554277421000165],
              [-6.226181028999918, 10.554651261000174],
              [-6.226026056999899, 10.554826737000099],
              [-6.225705146999928, 10.555191041000057],
              [-6.212160109999957, 10.570490838000069],
              [-6.210649013999898, 10.576439857000139],
              [-6.210281848999898, 10.583330155000056],
              [-6.215881823999894, 10.582289696000032],
              [-6.21531200499993, 10.58780098],
              [-6.210029124999949, 10.596441269000024],
              [-6.210356234999949, 10.599752427000055],
              [-6.210415839999882, 10.600361823000071],
              [-6.212737083999912, 10.60062790000012],
              [-6.218729972999938, 10.59748363600005],
              [-6.219079016999842, 10.597300530000155],
              [-6.221611976999895, 10.598499298000036],
              [-6.225121021999883, 10.609379768000053],
              [-6.22315215999987, 10.615100861000087],
              [-6.220631121999929, 10.617876054000135],
              [-6.218636989999936, 10.620072364000123],
              [-6.215123175999906, 10.621379853000064],
              [-6.203052997999976, 10.620458602000099],
              [-6.202544211999964, 10.62061596000018],
              [-6.198139189999949, 10.621978761000037],
              [-6.190774917999875, 10.629890442000146],
              [-6.18853902799998, 10.633621216000108],
              [-6.190156935999937, 10.637701035000077],
              [-6.19196891799993, 10.640259743000058],
              [-6.192105769999955, 10.64024543700009],
              [-6.195620059999953, 10.639844896000113],
              [-6.196463108999978, 10.639748574000123],
              [-6.198441028999923, 10.639525413000058],
              [-6.200592994999909, 10.639280319000022],
              [-6.204709052999931, 10.642812730000173],
              [-6.213015079999934, 10.646189690000142],
              [-6.215950011999951, 10.65061187800012],
              [-6.216343880999943, 10.654299736000041],
              [-6.22115278299998, 10.657670021000115],
              [-6.222081184999979, 10.658320427000092],
              [-6.228756904999898, 10.657581329000095],
              [-6.230935096999929, 10.657340050000073],
              [-6.235434054999928, 10.665250779000132],
              [-6.234645842999896, 10.670419693000042],
              [-6.227668761999951, 10.681352616000027],
              [-6.225520132999975, 10.684720994000031],
              [-6.225553034999905, 10.684784889000071],
              [-6.233303069999977, 10.699998856999969],
              [-6.230264187999978, 10.701841354000123],
              [-6.228535174999934, 10.702889443000061],
              [-6.226550101999919, 10.704092026000183],
              [-6.219531059999952, 10.711669922000112],
              [-6.219305038999892, 10.712171555000168],
              [-6.214879036999946, 10.722028732000126],
              [-6.217734812999822, 10.726328851],
              [-6.238160132999951, 10.731598855000129],
              [-6.241806983999823, 10.732540132000111],
              [-6.246427057999881, 10.733540536000135],
              [-6.248219966999898, 10.736080170000093],
              [-6.25356483499985, 10.736445427000035],
              [-6.256415843999946, 10.736639977000152],
              [-6.267169, 10.732693672000039],
              [-6.269728184999963, 10.731755257000088],
              [-6.27492284799996, 10.729847908000124],
              [-6.28316879199997, 10.72425079300001],
              [-6.289702892999969, 10.723222733000057],
              [-6.290935993999938, 10.722680092000189],
              [-6.310575008999933, 10.71417045600009],
              [-6.319074153999907, 10.707419395000045],
              [-6.324678896999956, 10.706138612000018],
              [-6.346978186999877, 10.696208954000042],
              [-6.350481033999813, 10.694648744000062],
              [-6.35375976499995, 10.706209183000112],
              [-6.359451771999886, 10.70876693800011],
              [-6.36068391699996, 10.709320069000114],
              [-6.36659193099996, 10.709683418000054],
              [-6.376485824999918, 10.71029090900015],
              [-6.379825115999893, 10.711629867000113],
              [-6.382493018999924, 10.712699890000067],
              [-6.38835716299991, 10.71099567499999],
              [-6.390677929999924, 10.710321426000121],
              [-6.393204212999876, 10.710751533000064],
              [-6.396708011999976, 10.711351394000076],
              [-6.400895118999927, 10.709080697000047],
              [-6.416440963999946, 10.70065879800012],
              [-6.419569968999952, 10.694728852000139],
              [-6.421504973999902, 10.67751026100018],
              [-6.420338154999968, 10.625510216000123],
              [-6.408260821999932, 10.624600411000074],
              [-6.400442122999948, 10.619628906],
              [-6.396594046999951, 10.613580705000061],
              [-6.396512030999929, 10.609010696000041],
              [-6.396480083999961, 10.607139588000166],
              [-6.401051997999957, 10.598481178000156],
              [-6.415155886999912, 10.589988709000124],
              [-6.431512832999886, 10.572569848000114],
              [-6.440736770999877, 10.567377089999979],
              [-6.444196224999928, 10.565428734000079],
              [-6.454010962999973, 10.565096856000139],
              [-6.459163188999924, 10.564922333000027],
              [-6.459821224999928, 10.564900399000123],
              [-6.461877823999942, 10.564829826000107],
              [-6.46331977899996, 10.565510750000101],
              [-6.46665096299995, 10.567084314000112],
              [-6.468565939999962, 10.567988396000146],
              [-6.475545883999928, 10.571284295000055],
              [-6.490705011999978, 10.578440666000063],
              [-6.497340202999908, 10.577870369000038],
              [-6.499557018999838, 10.577679635000038],
              [-6.500904082999909, 10.576569558000131],
              [-6.504200934999915, 10.574886322000111],
              [-6.504370211999913, 10.574844360000043],
              [-6.513088225999866, 10.576779366000096],
              [-6.523215770999912, 10.58061885800015],
              [-6.531497000999877, 10.5837583550001],
              [-6.537855148999881, 10.586167336000017],
              [-6.539867877999882, 10.586931229000072],
              [-6.544927119999954, 10.590009690000159],
              [-6.545876979999946, 10.591041566000115],
              [-6.548161983999933, 10.59352493300014],
              [-6.548801898999955, 10.59421920800014],
              [-6.550027846999967, 10.596901894000041],
              [-6.55282688099993, 10.603030205000039],
              [-6.557503221999923, 10.605359077000173],
              [-6.558588980999957, 10.605899810000039],
              [-6.560595989999968, 10.608030320000125],
              [-6.5652031899999, 10.612919807000139],
              [-6.566410064999957, 10.61357784300003],
              [-6.573042869999881, 10.617194177000101],
              [-6.573492048999981, 10.617439271000137],
              [-6.573830126999894, 10.617506981000076],
              [-6.586708067999894, 10.620107651000126],
              [-6.593289851999941, 10.621437073000152],
              [-6.595581053999979, 10.621898652000084],
              [-6.600553989999924, 10.627569198000117],
              [-6.605220795999969, 10.632889747000092],
              [-6.611220836999905, 10.639731408000102],
              [-6.613666056999932, 10.650070190000065],
              [-6.615974901999891, 10.659831047000068],
              [-6.616169928999966, 10.660042762000046],
              [-6.623500824999894, 10.668010713000058],
              [-6.625244140999882, 10.668404579000139],
              [-6.630239962999895, 10.669532777000029],
              [-6.636693000999969, 10.670991898000068],
              [-6.642239092999887, 10.672245025999985],
              [-6.644699095999954, 10.666091918000177],
              [-6.651793955999892, 10.659311295],
              [-6.657330989999934, 10.646469116000162],
              [-6.657533168999976, 10.645999909000182],
              [-6.658586025999966, 10.643560410000191],
              [-6.65678119699993, 10.640528679999989],
              [-6.653172014999882, 10.641573907000065],
              [-6.650002001999951, 10.642491340000049],
              [-6.646574973999918, 10.639200211000116],
              [-6.646891115999949, 10.638734818999978],
              [-6.649199961999955, 10.635341645000096],
              [-6.65412807499996, 10.632901193000123],
              [-6.656108855999889, 10.632496834000165],
              [-6.667388915999879, 10.630195618000187],
              [-6.667661188999944, 10.630141259000027],
              [-6.668150901999979, 10.629699707999976],
              [-6.67191410099997, 10.626310349000164],
              [-6.673175811999897, 10.622212410000088],
              [-6.673395157999948, 10.621500969000124],
              [-6.672830103999957, 10.618759155000077],
              [-6.672110079999925, 10.615268707000155],
              [-6.675364017999925, 10.606731414000137],
              [-6.675549030999946, 10.606245994000119],
              [-6.676987170999951, 10.602470399000083],
              [-6.677051066999979, 10.598937989000035],
              [-6.677052973999878, 10.598791122000023],
              [-6.677000998999915, 10.598736763999966],
              [-6.676902771999892, 10.598637582000094],
              [-6.675226210999881, 10.596920014000091],
              [-6.669322966999971, 10.596147538000139],
              [-6.669197082999858, 10.596131325000158],
              [-6.669124124999939, 10.596014977000095],
              [-6.666707991999942, 10.592169762000026],
              [-6.6680440899999, 10.586059569999975],
              [-6.67101097099993, 10.572468758000127],
              [-6.670662879999895, 10.56904125300008],
              [-6.670266150999851, 10.565144540000176],
              [-6.6692290299999, 10.554949760000056],
              [-6.670248030999971, 10.549910546000035],
              [-6.669981955999958, 10.549361228000066],
              [-6.667635917999917, 10.54450512000011],
              [-6.664113997999834, 10.537215233999973],
              [-6.663970945999893, 10.536919595000143],
              [-6.664218902999892, 10.536487580000028],
              [-6.664696216999971, 10.535655976000101],
              [-6.665411948999918, 10.53441047699999],
              [-6.674056053999891, 10.531801223000116],
              [-6.676639079999916, 10.53240585400016],
              [-6.679379940999866, 10.533049584000139],
              [-6.68146085799998, 10.5204296120001],
              [-6.677505015999884, 10.507710457000087],
              [-6.684803009999939, 10.489440918000071],
              [-6.682528017999914, 10.486641884000107],
              [-6.676743984999973, 10.485162735000188],
              [-6.67632198299998, 10.482619286000158],
              [-6.681191920999936, 10.470049859000142],
              [-6.679163932999927, 10.466571809000129],
              [-6.674959182999942, 10.467870712000035],
              [-6.66608619799996, 10.457140922000065],
              [-6.658929824999973, 10.454481124999973],
              [-6.657005786999946, 10.455390930000021],
              [-6.656856059999882, 10.455462456000078],
              [-6.653306960999942, 10.457140922000065],
              [-6.648674011999958, 10.456370353000182],
              [-6.644309044999943, 10.453531265000095],
              [-6.643833159999929, 10.451898576000076],
              [-6.643432140999948, 10.450531007],
              [-6.643579960999944, 10.450200082000094],
              [-6.64418601899996, 10.448849678000101],
              [-6.646093845999928, 10.444601058999979],
              [-6.644506931999956, 10.442269325000154],
              [-6.638257980999924, 10.441011429000184],
              [-6.634746073999963, 10.442561149000142],
              [-6.631268023999951, 10.442029954000134],
              [-6.630167006999898, 10.438791275000142],
              [-6.630540846999907, 10.435833931000047],
              [-6.631790160999913, 10.425938607000035],
              [-6.632233143999883, 10.425047875000075],
              [-6.636816023999927, 10.415835381000079],
              [-6.637574196999935, 10.414311409999982],
              [-6.645885943999929, 10.404111862000036],
              [-6.652455806999967, 10.400488854000116],
              [-6.653161049999881, 10.400100709000071],
              [-6.653738021999914, 10.399002075000112],
              [-6.655355929999928, 10.395918845999972],
              [-6.654843805999917, 10.394992829000046],
              [-6.654057026999851, 10.393570900000043],
              [-6.659892082999932, 10.38726329800005],
              [-6.660672186999875, 10.386419296000042],
              [-6.664842128999965, 10.381911279000178],
              [-6.66494703199993, 10.38179874399998],
              [-6.667932033999875, 10.374935150000169],
              [-6.671949862999895, 10.365696907000086],
              [-6.672196863999886, 10.365130425000132],
              [-6.678081034999934, 10.360630989000128],
              [-6.690465927999981, 10.356471062000139],
              [-6.699295996999979, 10.356380463000164],
              [-6.705605984999977, 10.358389855000155],
              [-6.709235191999881, 10.359540939],
              [-6.722694872999966, 10.374489784000104],
              [-6.764007090999883, 10.391502381000066],
              [-6.764727114999971, 10.390131950000068],
              [-6.774264811999956, 10.3893709190001],
              [-6.791310787999919, 10.384225845000174],
              [-6.795031069999936, 10.383103370000129],
              [-6.797387122999965, 10.382391929000164],
              [-6.820706842999925, 10.378548622000039],
              [-6.839034079999919, 10.375537873000042],
              [-6.839323042999922, 10.37549114300009],
              [-6.839581012999872, 10.37526702800011],
              [-6.841907023999966, 10.373247146000153],
              [-6.843715190999888, 10.371677399000021],
              [-6.849740980999854, 10.366441727000051],
              [-6.850746155999957, 10.365570068000181],
              [-6.854406832999928, 10.362389564000068],
              [-6.86532783399997, 10.362339974000065],
              [-6.898900031999972, 10.369778633000124],
              [-6.91075801799991, 10.369279862000155],
              [-6.934069157999943, 10.364589692000038],
              [-6.940341948999958, 10.367318153000099],
              [-6.9410610199999, 10.367630004999967],
              [-6.941224097999964, 10.367700576000061],
              [-6.946690081999975, 10.369400025000061],
              [-6.947463987999981, 10.369640351000044],
              [-6.94814586699988, 10.36920642900003],
              [-6.952220915999931, 10.366610526999978],
              [-6.952394008999931, 10.366501809000056],
              [-6.958741188999909, 10.358491897000079],
              [-6.964124202999869, 10.351697921000152],
              [-6.966618060999906, 10.348550796000097],
              [-6.967905043999963, 10.347371101000078],
            ],
          ],
        },
      },
    ],
  },
  102: {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: { GID_0: "MRT", COUNTRY: "Mauritania" },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-15.500075339999967, 16.573995591000084],
                [-15.502158164999912, 16.573165894000113],
                [-15.504596710999976, 16.572191239],
                [-15.510521888999961, 16.568834304000177],
                [-15.513662338999836, 16.565656662000151],
                [-15.516024588999869, 16.563089372000036],
                [-15.51743984299992, 16.560880661000112],
                [-15.518291472999977, 16.559558869],
                [-15.519137382999872, 16.558244705000163],
                [-15.519750593999959, 16.556386948000011],
                [-15.519845962999966, 16.556098937000115],
                [-15.520732879999969, 16.552524567000091],
                [-15.52077198, 16.552368165000132],
                [-15.520792960999813, 16.550029755000082],
                [-15.520352363999962, 16.545898437000062],
                [-15.518980025999895, 16.542734146000157],
                [-15.517048834999969, 16.540561675999982],
                [-15.515322683999898, 16.539011001],
                [-15.513311386999931, 16.536754608000138],
                [-15.512133596999888, 16.53507232700008],
                [-15.51119041499993, 16.533937454000068],
                [-15.511156080999967, 16.53389549200017],
                [-15.510684966999975, 16.533329009999989],
                [-15.51008892099992, 16.531847001000074],
                [-15.509331702999873, 16.530241013000023],
                [-15.509328842999935, 16.529357911000147],
                [-15.509778021999978, 16.527677535],
                [-15.51015472399996, 16.527320861000192],
                [-15.511477470999978, 16.526073455000187],
                [-15.515161513999885, 16.523723602000189],
                [-15.515610694999907, 16.523475648000101],
                [-15.518173217999959, 16.522069932000136],
                [-15.52042579599987, 16.521333695000067],
                [-15.522601127999849, 16.520622254000102],
                [-15.526770592999867, 16.519300460000125],
                [-15.531000135999875, 16.51792335600004],
                [-15.533121107999932, 16.517528535000167],
                [-15.53410243899998, 16.517240524000044],
                [-15.534797668999943, 16.51703834599999],
                [-15.53681087599989, 16.516302110000026],
                [-15.537654875999976, 16.51599311800004],
                [-15.54116535199995, 16.515157701000135],
                [-15.544732093999926, 16.514095306000058],
                [-15.55047988899986, 16.513019561000135],
                [-15.55379295399996, 16.512498856000093],
                [-15.561372756999901, 16.512571335000189],
                [-15.561553954999965, 16.512596131000066],
                [-15.568362236999917, 16.513515472000051],
                [-15.572140693999927, 16.514625549000129],
                [-15.575071333999915, 16.515525818000128],
                [-15.579668998999921, 16.518671036000114],
                [-15.585417747999941, 16.523115159000042],
                [-15.589252470999895, 16.525396348000015],
                [-15.591292380999846, 16.526735306000148],
                [-15.594721794999828, 16.528364182000075],
                [-15.598599433999937, 16.529458998999985],
                [-15.601866721999897, 16.529415131000121],
                [-15.605898857999932, 16.528488158000073],
                [-15.610558508999929, 16.527261733000103],
                [-15.612105368999835, 16.527318956000101],
                [-15.613334656999939, 16.5291252130001],
                [-15.619410513999924, 16.529489518000105],
                [-15.623475074999874, 16.528976441000054],
                [-15.62622356299994, 16.527772904000187],
                [-15.626358031999871, 16.525720596000042],
                [-15.627758024999878, 16.524906158000135],
                [-15.629184721999877, 16.524032591999969],
                [-15.630620956999962, 16.522502900000063],
                [-15.631270409999956, 16.521202087000177],
                [-15.632215498999926, 16.518396378000091],
                [-15.632619857999885, 16.514835357999971],
                [-15.632704734999891, 16.514083863000053],
                [-15.6321792599999, 16.511854171000152],
                [-15.63146877399987, 16.50878715500005],
                [-15.630178450999949, 16.505802154999969],
                [-15.629164695999918, 16.503639221000071],
                [-15.628602981999961, 16.50283432100008],
                [-15.628243445999885, 16.502319337000131],
                [-15.628040312999872, 16.501171113000169],
                [-15.62791442799994, 16.499769210000068],
                [-15.628092765999952, 16.497652053000138],
                [-15.628386496999838, 16.496212006000064],
                [-15.628673552999942, 16.495365143000072],
                [-15.62876892, 16.495151521000025],
                [-15.629005430999882, 16.494626999000104],
                [-15.629265785999849, 16.494049072000166],
                [-15.630079268999907, 16.49330520699999],
                [-15.63221836199989, 16.491689682000185],
                [-15.633050917999924, 16.490999222000085],
                [-15.634507178999911, 16.490392685000074],
                [-15.636638640999877, 16.48935699499998],
                [-15.640042305999941, 16.48709869400011],
                [-15.644665717999942, 16.485492706000116],
                [-15.649855612999943, 16.484231949000161],
                [-15.660994529999982, 16.483213424000155],
                [-15.666356085999951, 16.482723236000083],
                [-15.671607971999947, 16.482242584000119],
                [-15.676671026999884, 16.48177909900005],
                [-15.679027557999916, 16.481563569000173],
                [-15.681614875999855, 16.481327058000034],
                [-15.682725905999916, 16.481225968000047],
                [-15.684184073999972, 16.481092451999984],
                [-15.688805580999826, 16.480669022000143],
                [-15.690032005999967, 16.48023796200016],
                [-15.691893577999963, 16.479581834000044],
                [-15.693377494999936, 16.479059220000181],
                [-15.693747519999874, 16.478927613],
                [-15.695756911999979, 16.476846696000052],
                [-15.695942877999983, 16.476654052000129],
                [-15.697115897999879, 16.474882126000068],
                [-15.697804449999921, 16.472881317000144],
                [-15.69857120599994, 16.472606660000054],
                [-15.699965475999932, 16.472106934000124],
                [-15.700005530999874, 16.472114564000037],
                [-15.702445984999827, 16.472534179000036],
                [-15.705521583999939, 16.473634720000064],
                [-15.706566810999902, 16.474235536000037],
                [-15.707479475999833, 16.474760056000093],
                [-15.707568167999966, 16.474802017000059],
                [-15.710726737999948, 16.476325989000031],
                [-15.710842132999971, 16.476430894000146],
                [-15.712435722999942, 16.477876664],
                [-15.7125587459999, 16.477920533000031],
                [-15.713035583999897, 16.47808647100004],
                [-15.714014053999961, 16.478004455000189],
                [-15.714444159999914, 16.478284836000057],
                [-15.715496063999922, 16.478971481000087],
                [-15.715776443999857, 16.479154587000153],
                [-15.71685028, 16.47988891600005],
                [-15.718706131999966, 16.481159209999987],
                [-15.719969748999972, 16.482023239000171],
                [-15.72101307, 16.482837677000077],
                [-15.723242759999891, 16.484474183000088],
                [-15.724514961999887, 16.4851055150001],
                [-15.725840568999899, 16.486207962000151],
                [-15.726095199999918, 16.486419677000129],
                [-15.727360724999926, 16.487216949000015],
                [-15.728184700999975, 16.487884521000012],
                [-15.728983878999827, 16.488334656000063],
                [-15.730438231999813, 16.489151002000085],
                [-15.731595992999928, 16.489532470000029],
                [-15.733730315999878, 16.490234376000046],
                [-15.735730171999876, 16.49068069399999],
                [-15.737686156999814, 16.490911485000026],
                [-15.73980045299993, 16.490905762000182],
                [-15.740974425999923, 16.491090776000021],
                [-15.744717597999909, 16.491170884000041],
                [-15.746217727999976, 16.491395950000026],
                [-15.749170303999904, 16.49217033400015],
                [-15.750698088999968, 16.492437364000068],
                [-15.75250148799995, 16.492553712000074],
                [-15.755538939999894, 16.492752076000158],
                [-15.760941505999881, 16.492963791000022],
                [-15.762839316999816, 16.493175506],
                [-15.765058516999943, 16.493629455000189],
                [-15.76628494199997, 16.493680955000173],
                [-15.766514776999884, 16.493690490000176],
                [-15.768992422999929, 16.493789672000048],
                [-15.772650719999888, 16.494226455000046],
                [-15.774950026999818, 16.494567871000015],
                [-15.776949882999816, 16.494865418000018],
                [-15.778511046999938, 16.495361328000058],
                [-15.781847952999897, 16.49601745700005],
                [-15.783059119999905, 16.496614457000078],
                [-15.784618377999948, 16.496980667000173],
                [-15.785343169999976, 16.497150422000118],
                [-15.786418914999899, 16.49759483299999],
                [-15.788518905999922, 16.498464584000146],
                [-15.789435385999923, 16.49869918800016],
                [-15.790432929999952, 16.498954774000026],
                [-15.792722702999868, 16.500139237000042],
                [-15.795673370999907, 16.50164604299999],
                [-15.798743247999937, 16.50299453800011],
                [-15.798985480999875, 16.50310134900019],
                [-15.80071449199994, 16.504117965999967],
                [-15.802819250999903, 16.505065918000071],
                [-15.80475711899993, 16.506086349000043],
                [-15.806147574999954, 16.506923675000053],
                [-15.806613920999837, 16.507204056000091],
                [-15.807356833999847, 16.507520676000183],
                [-15.807395934999874, 16.507537842000147],
                [-15.808484076999946, 16.508003236000093],
                [-15.808590888999902, 16.508039475000032],
                [-15.808947562999833, 16.508163453000122],
                [-15.81091403999983, 16.508848190000037],
                [-15.813337325999953, 16.509201050000115],
                [-15.813463211999874, 16.509208680000029],
                [-15.815873144999898, 16.509340286000054],
                [-15.816479682999898, 16.509340286000054],
                [-15.818724630999839, 16.509332658000176],
                [-15.819230079999897, 16.509296417000087],
                [-15.820824621999975, 16.509185791000164],
                [-15.821546553999895, 16.5090045930001],
                [-15.822456360999979, 16.508779527000115],
                [-15.82389068599997, 16.50814438000009],
                [-15.824391363999837, 16.50792312700014],
                [-15.825882911999884, 16.507097244000136],
                [-15.82952308699987, 16.506427765000069],
                [-15.830268859999933, 16.506250381000143],
                [-15.831770896999956, 16.505849838000131],
                [-15.833654402999855, 16.505517959000088],
                [-15.834950446999869, 16.505029679000188],
                [-15.835577010999941, 16.504793168000106],
                [-15.836883544999978, 16.504028320000032],
                [-15.838741301999903, 16.503187180000054],
                [-15.840294836999931, 16.502397538000082],
                [-15.842100143999915, 16.501304627000025],
                [-15.843755721999969, 16.500019074000136],
                [-15.844331740999905, 16.499431609000112],
                [-15.844832419999932, 16.498922348000178],
                [-15.845770835999872, 16.498191834000124],
                [-15.846669197999972, 16.497503281000093],
                [-15.847891807999872, 16.496751786000175],
                [-15.849545477999982, 16.495811462000177],
                [-15.851779937999879, 16.494493485000078],
                [-15.852787971999874, 16.494064330000185],
                [-15.853831290999949, 16.493318558000112],
                [-15.85477256799993, 16.49286079400008],
                [-15.85637664799998, 16.492370606],
                [-15.857669830999953, 16.492025374999969],
                [-15.858613014999946, 16.492021562000104],
                [-15.859194754999919, 16.492269516000022],
                [-15.859312057999944, 16.492504120000092],
                [-15.859607696999888, 16.493091584000183],
                [-15.859730719999959, 16.494066238000187],
                [-15.85985660599988, 16.495229721999976],
                [-15.859747885999923, 16.496385574000044],
                [-15.85971641499998, 16.496540069000162],
                [-15.859491348999939, 16.497652053000138],
                [-15.859142302999942, 16.499509813000031],
                [-15.859023093999838, 16.500715257000081],
                [-15.858985899999936, 16.501091003999989],
                [-15.858851433999916, 16.502738952000129],
                [-15.859150885999952, 16.505344391000108],
                [-15.859777449999967, 16.506963730000109],
                [-15.860782623999853, 16.508987427000079],
                [-15.861310958999866, 16.509595870999988],
                [-15.861330032999945, 16.509616852000022],
                [-15.861541747999865, 16.510049819000074],
                [-15.861661910999942, 16.510293961],
                [-15.862100600999895, 16.510864258000026],
                [-15.862806319999947, 16.51147079600014],
                [-15.864232063999964, 16.512468338000076],
                [-15.865330694999841, 16.513149262000127],
                [-15.865509032999853, 16.513261795000119],
                [-15.866927146999956, 16.513916015000063],
                [-15.868490217999977, 16.514354706],
                [-15.870483398999852, 16.514532089000056],
                [-15.872056007999959, 16.514841079],
                [-15.873551367999937, 16.515054704000022],
                [-15.875119208999877, 16.515300750000108],
                [-15.876494407999928, 16.515672685000084],
                [-15.878345489999958, 16.515735626000037],
                [-15.87939739199993, 16.515882492000117],
                [-15.880222319999973, 16.51619720500014],
                [-15.880250931999967, 16.516208648000088],
                [-15.880869865999898, 16.516361237000012],
                [-15.881899833999967, 16.516614914000172],
                [-15.883994101999974, 16.516971589000093],
                [-15.885735510999893, 16.517116548000104],
                [-15.887802122999972, 16.517374040000107],
                [-15.890034674999981, 16.517511368999976],
                [-15.892408370999931, 16.517505646000131],
                [-15.894830702999968, 16.517284393000011],
                [-15.897008896999978, 16.516851425000084],
                [-15.898489951999863, 16.516649246000156],
                [-15.899841309999942, 16.516155243000071],
                [-15.900844573999905, 16.515882492000117],
                [-15.901895522999894, 16.51575851399997],
                [-15.904924392999874, 16.515060424000126],
                [-15.906402586999945, 16.51493072500017],
                [-15.907786369999883, 16.514791489000174],
                [-15.909103393999885, 16.514720918000137],
                [-15.910481452999932, 16.51464653],
                [-15.912094115999878, 16.514484405000189],
                [-15.912872314999959, 16.514406204000181],
                [-15.913381576999939, 16.514354706],
                [-15.914124488999903, 16.514089584000089],
                [-15.914410589999875, 16.513988496000081],
                [-15.91558074999989, 16.513572694000175],
                [-15.91705989899998, 16.513111115000072],
                [-15.918735504999972, 16.512382508000087],
                [-15.919850349999933, 16.511995315000092],
                [-15.922579764999966, 16.510473252000054],
                [-15.924387931999945, 16.509361267000145],
                [-15.912545204999901, 16.531240464000064],
                [-15.906894682999905, 16.55288505599998],
                [-15.908127784999976, 16.562063217000116],
                [-15.924840925999945, 16.551563262000059],
                [-15.941552161999937, 16.541061402000139],
                [-15.960826874999896, 16.507873535000158],
                [-15.965848922999953, 16.489057541000079],
                [-15.966935158999945, 16.484987260000025],
                [-15.966951369999947, 16.484926224000105],
                [-15.966996193999933, 16.484928132000107],
                [-15.967123031999847, 16.484931947000177],
                [-15.96786880499991, 16.484962464000091],
                [-15.969230650999975, 16.485183716000108],
                [-15.970363616999919, 16.485542298000098],
                [-15.971269606999897, 16.48579597500003],
                [-15.971798896999928, 16.486309052000138],
                [-15.972047805999978, 16.487108231000093],
                [-15.972505569999953, 16.488351822000027],
                [-15.97305393299996, 16.489358902000049],
                [-15.973704337999948, 16.490459441000098],
                [-15.974734306999949, 16.491603852000026],
                [-15.975763319999942, 16.492519378000111],
                [-15.976518629999816, 16.492992402000141],
                [-15.97732353299989, 16.493495941000162],
                [-15.97854614299996, 16.494338990000188],
                [-15.979948997999941, 16.495061874000157],
                [-15.98048210099995, 16.495605470000157],
                [-15.981840133999924, 16.496244430000104],
                [-15.982080458999917, 16.496358872000144],
                [-15.982889175999958, 16.496833802000026],
                [-15.984025001999953, 16.497461318999967],
                [-15.984679222999944, 16.498138428000118],
                [-15.986175537999827, 16.499195097999973],
                [-15.986811637999949, 16.499479294000082],
                [-15.987702368999862, 16.49987983799997],
                [-15.988698958999976, 16.500387192000062],
                [-15.990045546999909, 16.500896454999975],
                [-15.990829467999959, 16.501193999000066],
                [-15.991909026999906, 16.501651763000154],
                [-15.99205875399997, 16.501714706000087],
                [-15.99226379299995, 16.50177192600006],
                [-15.992864608999923, 16.50193977400005],
                [-15.994069099999933, 16.502275467000061],
                [-15.99426364899989, 16.502330780000079],
                [-15.996363639999913, 16.502927780000107],
                [-15.998279571999888, 16.503122330000053],
                [-16.000057220999963, 16.503250123000043],
                [-16.002313613999888, 16.503246307999973],
                [-16.004608153999925, 16.503023148000182],
                [-16.007953642999894, 16.502231598000037],
                [-16.008905410999944, 16.50148391800019],
                [-16.009674071999882, 16.501182556000117],
                [-16.010797500999843, 16.500640869000165],
                [-16.011869429999877, 16.500045776000036],
                [-16.013027190999935, 16.499383927000054],
                [-16.014371871999913, 16.498725891000163],
                [-16.014966964999928, 16.498268127000131],
                [-16.015665054999886, 16.497385026000131],
                [-16.016323089999958, 16.496686936000117],
                [-16.017028808999839, 16.496061326000074],
                [-16.017753599999935, 16.495189668000023],
                [-16.018461227999978, 16.494745255000112],
                [-16.019582747999948, 16.49371147100004],
                [-16.020566939999924, 16.493144989000086],
                [-16.021814345999928, 16.492197037000153],
                [-16.023191451999935, 16.491191863000097],
                [-16.023998259999871, 16.490549088000137],
                [-16.025442122999891, 16.489477158000057],
                [-16.026733397999976, 16.488239289000092],
                [-16.027788162999911, 16.487209321000137],
                [-16.028417586999979, 16.486663818000181],
                [-16.028814315999909, 16.486175538000111],
                [-16.029546737999965, 16.48534965500005],
                [-16.030445099999952, 16.484607697000172],
                [-16.031099319999896, 16.4841175090001],
                [-16.031959532999906, 16.483800889000179],
                [-16.033260345999906, 16.48303413300016],
                [-16.034067153999956, 16.48257637],
                [-16.035562514999981, 16.481607437000036],
                [-16.036256789999925, 16.481237412000098],
                [-16.036991118999936, 16.480850221000139],
                [-16.038484573999881, 16.480340957000067],
                [-16.039365767999982, 16.47978401099999],
                [-16.040126800999872, 16.479642869000031],
                [-16.041219711999872, 16.479360581000151],
                [-16.042943954999828, 16.479095459000064],
                [-16.043249129999936, 16.478998184000091],
                [-16.044141768999964, 16.478710175000174],
                [-16.044548034999934, 16.478630067000097],
                [-16.045259475999899, 16.478488923000157],
                [-16.04736709499997, 16.478406906000032],
                [-16.048204421999969, 16.478372575000094],
                [-16.048919677999891, 16.478340147999972],
                [-16.050041197999974, 16.478334428000039],
                [-16.052087783999923, 16.478324890000181],
                [-16.05330657899998, 16.478473664000035],
                [-16.05448722899996, 16.478982926000072],
                [-16.055498123999939, 16.479585647000135],
                [-16.056270599999891, 16.480060577000131],
                [-16.056722640999965, 16.480529786000147],
                [-16.05717658999987, 16.481342316000053],
                [-16.057649611999864, 16.482116700000176],
                [-16.058029174999888, 16.482961655],
                [-16.058456419999914, 16.483781816000089],
                [-16.058551788999978, 16.484149933000083],
                [-16.058906555999954, 16.485504151000043],
                [-16.059473037999908, 16.486465454000097],
                [-16.059848784999929, 16.487417221000101],
                [-16.06036949199995, 16.488620758000081],
                [-16.061422348999827, 16.490055085000051],
                [-16.062101362999954, 16.491027833000032],
                [-16.063282012999935, 16.492397309000012],
                [-16.064493179999886, 16.493625642000154],
                [-16.065353392999896, 16.494699477999973],
                [-16.06638717699991, 16.495897293000041],
                [-16.06748771599996, 16.497091294000029],
                [-16.068370818999824, 16.49794006400009],
                [-16.068952558999911, 16.498935701000107],
                [-16.070096969999895, 16.500185012000031],
                [-16.070755003999921, 16.501153947000148],
                [-16.07164955099995, 16.502660751000121],
                [-16.072471618999941, 16.50371170000011],
                [-16.073362350999901, 16.504953385000078],
                [-16.074615478999931, 16.506256104000158],
                [-16.076011657999913, 16.507808686000033],
                [-16.077209472999982, 16.508548736000137],
                [-16.078624724999941, 16.509937286000081],
                [-16.079664230999981, 16.510520936000091],
                [-16.080797193999899, 16.511482239000088],
                [-16.082349776999934, 16.51244544900004],
                [-16.083232880999901, 16.51302909900005],
                [-16.084106444999975, 16.513584137000123],
                [-16.085395811999831, 16.514308929000038],
                [-16.087087630999918, 16.514947891000133],
                [-16.088357925999958, 16.515520096000159],
                [-16.089576720999901, 16.516071319000048],
                [-16.090354919999982, 16.516759873000183],
                [-16.091846466999925, 16.517673491999972],
                [-16.093185424999945, 16.518630981000058],
                [-16.094778061999932, 16.51945114199998],
                [-16.095849989999977, 16.520233154000039],
                [-16.097183226999903, 16.520967483000106],
                [-16.098350523999954, 16.521621703999983],
                [-16.098979948999954, 16.521989823000183],
                [-16.099658965999936, 16.522789001000035],
                [-16.100196837999931, 16.52350425700007],
                [-16.100576400999898, 16.524433136000084],
                [-16.100769042999957, 16.525590897000029],
                [-16.100536346999945, 16.526865005000104],
                [-16.100276946999884, 16.52788543700018],
                [-16.099866867999879, 16.5294380200001],
                [-16.099573135999947, 16.530809402000102],
                [-16.099311828999873, 16.532432556000117],
                [-16.098999023999966, 16.533304214000111],
                [-16.098318099999915, 16.534740449000026],
                [-16.098169325999947, 16.535995483000079],
                [-16.098175048999963, 16.539121629000135],
                [-16.098363876999883, 16.5400238040001],
                [-16.098722456999894, 16.541730881000035],
                [-16.099554060999935, 16.544063568000183],
                [-16.100112914999897, 16.54591369700006],
                [-16.101037979999887, 16.54767227200017],
                [-16.102317809999931, 16.549621583000089],
                [-16.103931425999917, 16.551357270000096],
                [-16.104787825999836, 16.552024841000161],
                [-16.105560301999958, 16.552627564000034],
                [-16.107341765999934, 16.553834916000085],
                [-16.108970641999974, 16.554824830000086],
                [-16.111366271999941, 16.555616379000128],
                [-16.11177444599997, 16.555744171000072],
                [-16.112916946999917, 16.55610466000013],
                [-16.113258362999829, 16.556213380000031],
                [-16.115135192999958, 16.556379318000097],
                [-16.116519927999946, 16.556501388000072],
                [-16.117071151999824, 16.556549073000042],
                [-16.11926460199993, 16.556545257999971],
                [-16.12137222299998, 16.556241989],
                [-16.122774123999875, 16.556030274000022],
                [-16.125259399999891, 16.555526733000022],
                [-16.126691817999927, 16.555156708000027],
                [-16.128423690999966, 16.554660797000111],
                [-16.128555296999934, 16.554595947000053],
                [-16.129793167999935, 16.553993226000046],
                [-16.130933760999824, 16.553672791000054],
                [-16.131902693999962, 16.553558350000117],
                [-16.133546828999954, 16.553207397000108],
                [-16.133764265999901, 16.553771973000096],
                [-16.133993148999934, 16.554365158000053],
                [-16.134492874999978, 16.554767609000066],
                [-16.135107038999877, 16.554979324999977],
                [-16.135757446999946, 16.55497550900003],
                [-16.136663436999982, 16.554800033999982],
                [-16.136827469999957, 16.555000306000068],
                [-16.142017364999845, 16.553993226000046],
                [-16.144483565999963, 16.553121567000119],
                [-16.146274566999921, 16.552488328000038],
                [-16.148473739999872, 16.551710129000071],
                [-16.149669646999882, 16.551288604000092],
                [-16.151653289999956, 16.550514221000014],
                [-16.152725219999923, 16.54997062699999],
                [-16.15399932899993, 16.549249649000046],
                [-16.155906676999905, 16.548013688000026],
                [-16.157661437999877, 16.546640396000043],
                [-16.159288406999963, 16.54575348000003],
                [-16.160219192999875, 16.544897080000055],
                [-16.161823272999982, 16.543718339000122],
                [-16.162799834999873, 16.542493820000175],
                [-16.163595198999872, 16.541078568000103],
                [-16.164257049999833, 16.540256500000169],
                [-16.165370941999981, 16.538829803000112],
                [-16.1665954599999, 16.537368774000129],
                [-16.167741775999957, 16.536050797000087],
                [-16.169736861999979, 16.533969880000086],
                [-16.170497894999926, 16.533018113000139],
                [-16.171298979999904, 16.532175064000114],
                [-16.172136306999846, 16.53141784800016],
                [-16.173223494999888, 16.530668258000105],
                [-16.173700332999942, 16.53019142100004],
                [-16.174318313999891, 16.529283523000061],
                [-16.174922942999842, 16.528635025000028],
                [-16.175933836999889, 16.527814864000106],
                [-16.181427001999907, 16.526538849000133],
                [-16.183538435999935, 16.525493623000159],
                [-16.18407630899992, 16.525064468000096],
                [-16.185035705999894, 16.523853300999974],
                [-16.186033247999944, 16.523435594000148],
                [-16.186672210999973, 16.52295494100008],
                [-16.187374115999944, 16.522132873000089],
                [-16.187980651999965, 16.521289826000043],
                [-16.189054489999876, 16.520591736000029],
                [-16.190603256999964, 16.520067215999973],
                [-16.194284439999876, 16.520029069000088],
                [-16.198207855999897, 16.519897461000085],
                [-16.199609756999962, 16.519641876000151],
                [-16.200901031999877, 16.519407273000184],
                [-16.203866958999981, 16.518493652000188],
                [-16.207643507999876, 16.518032073000086],
                [-16.210783004999939, 16.518163682000022],
                [-16.213832855999954, 16.518592835000106],
                [-16.217931747999899, 16.520093918000043],
                [-16.22389602699991, 16.523111344000142],
                [-16.227834701999939, 16.525371552000081],
                [-16.229017256999896, 16.52630233800005],
                [-16.229572295999901, 16.526603700000067],
                [-16.232101439999838, 16.527212143000099],
                [-16.239242553999873, 16.528873444000112],
                [-16.243873595999958, 16.52998733600009],
                [-16.246400833999928, 16.52985572800003],
                [-16.248964308999916, 16.529138566000029],
                [-16.250762938999969, 16.528549194000107],
                [-16.253204345999904, 16.527944565000098],
                [-16.25481414799998, 16.527536393000105],
                [-16.256853103999902, 16.526765823000119],
                [-16.258222580999927, 16.526388170000189],
                [-16.259103774999971, 16.526445390000163],
                [-16.26015853899986, 16.52651596100003],
                [-16.261373520999939, 16.526025773000129],
                [-16.262840271999949, 16.52543449500007],
                [-16.264463424999974, 16.524780274000022],
                [-16.266017913999974, 16.524152755000046],
                [-16.267107009999961, 16.523715972000105],
                [-16.267688751999856, 16.52318573000008],
                [-16.268243789999929, 16.522899629000108],
                [-16.270463943999971, 16.521753312000044],
                [-16.27125930699998, 16.521343231000117],
                [-16.272315978999927, 16.520797730000027],
                [-16.273092269999836, 16.520351410000046],
                [-16.27404022199994, 16.519586564000178],
                [-16.275196075999929, 16.518653870000094],
                [-16.276475905999916, 16.517623902000139],
                [-16.277818678999836, 16.516542436],
                [-16.278467178999847, 16.516609192000033],
                [-16.280223846999888, 16.515396117000137],
                [-16.281579969999939, 16.514104844000144],
                [-16.28277015599997, 16.512651444000142],
                [-16.283458710999923, 16.511686326000017],
                [-16.284147262999966, 16.510744094000074],
                [-16.284961700999929, 16.509426116999975],
                [-16.285804747999975, 16.508544921000066],
                [-16.286560058999953, 16.5075778960001],
                [-16.28715324399991, 16.506256104000158],
                [-16.287462233999975, 16.505275726000036],
                [-16.287662504999957, 16.504686355000047],
                [-16.28791999799995, 16.503541947000031],
                [-16.288066863999916, 16.502220155000089],
                [-16.288297652999915, 16.50142097500003],
                [-16.288539885999967, 16.500171661000081],
                [-16.288688660999924, 16.499057769000103],
                [-16.288696288999972, 16.497690200000022],
                [-16.288818358999947, 16.496423721000099],
                [-16.288745880999954, 16.495046616000138],
                [-16.288377760999879, 16.493955612999969],
                [-16.288309097999957, 16.492385864000028],
                [-16.288228988999833, 16.491130830000145],
                [-16.288068771999974, 16.489868164000086],
                [-16.287700651999899, 16.488426207999964],
                [-16.287494659999936, 16.486314774000107],
                [-16.287517547999926, 16.483844756000167],
                [-16.287714004999941, 16.48293685900012],
                [-16.287860869999918, 16.482133866000027],
                [-16.287944793999941, 16.480670930000088],
                [-16.288063048999959, 16.479591370000151],
                [-16.288248062999969, 16.478729249000139],
                [-16.288341521999882, 16.477138519000164],
                [-16.288494108999942, 16.476320266000016],
                [-16.289552688999947, 16.47520828200004],
                [-16.290220259999899, 16.474948884000071],
                [-16.290761947999954, 16.474740982000071],
                [-16.292434691999915, 16.47411346500013],
                [-16.293283462999966, 16.473827362000179],
                [-16.294408798999939, 16.473445892000029],
                [-16.296094894999896, 16.472875595],
                [-16.297050474999821, 16.472492219000117],
                [-16.298772810999935, 16.471805572000051],
                [-16.299551009999959, 16.471054077000133],
                [-16.301097869999865, 16.470510483999988],
                [-16.302221297999893, 16.469442368999978],
                [-16.302923202999978, 16.468578337999986],
                [-16.303556442999877, 16.468111039000178],
                [-16.304052352999975, 16.467565536000052],
                [-16.304637907999847, 16.466545106000183],
                [-16.305276870999933, 16.465671540000187],
                [-16.30597496099989, 16.46429443300002],
                [-16.306674955999881, 16.463438035000024],
                [-16.307165146999978, 16.462669374000143],
                [-16.307706831999838, 16.46202278100003],
                [-16.308830261999958, 16.459917068000152],
                [-16.309329985999909, 16.458566667000071],
                [-16.309499740999968, 16.458114623000142],
                [-16.309808730999919, 16.457286834000115],
                [-16.309968947999948, 16.45606994700006],
                [-16.310205458999974, 16.455131531000063],
                [-16.310777663999886, 16.451005935000126],
                [-16.310956953999892, 16.449544907000188],
                [-16.311445236999873, 16.448162079000156],
                [-16.311859130999949, 16.445829392000178],
                [-16.3118667599999, 16.443216325000037],
                [-16.312051772999894, 16.441843033000112],
                [-16.312059402999978, 16.440410613999973],
                [-16.31211471599994, 16.438476563000108],
                [-16.312105177999968, 16.436801912000021],
                [-16.31222534099993, 16.435420990000125],
                [-16.312421797999946, 16.433822632000044],
                [-16.312484740999935, 16.433038713000144],
                [-16.312669753999955, 16.432121277000078],
                [-16.312671661999957, 16.431453706000184],
                [-16.312484740999935, 16.430627823000123],
                [-16.312471389999871, 16.428785323999989],
                [-16.312391280999918, 16.427253724000082],
                [-16.312370300999874, 16.424070358000165],
                [-16.312425612999903, 16.421998977999976],
                [-16.312343596999881, 16.420803069999977],
                [-16.31212806699989, 16.419309617000124],
                [-16.312112807999881, 16.417106629000102],
                [-16.312232971999947, 16.41613197300012],
                [-16.312170028999901, 16.414628983000114],
                [-16.312227248999932, 16.41364860499999],
                [-16.312223433999975, 16.412687302000109],
                [-16.31236267, 16.41194152900016],
                [-16.31284904499995, 16.41074562100016],
                [-16.313072203999923, 16.410097123000128],
                [-16.313459396999917, 16.409574509000038],
                [-16.313896179999915, 16.408666611000058],
                [-16.314012527999921, 16.408424377000131],
                [-16.314596176999942, 16.407527923000032],
                [-16.315422057999967, 16.40744590900016],
                [-16.315994262999936, 16.407518387000096],
                [-16.318168639999897, 16.407789230000049],
                [-16.321519851999824, 16.40772056600008],
                [-16.323595046999912, 16.407012939000026],
                [-16.324207304999959, 16.406803131000061],
                [-16.324876783999912, 16.406356813000116],
                [-16.326408385999912, 16.405334472000163],
                [-16.326995848999957, 16.405820846999973],
                [-16.327556609999874, 16.405546189000177],
                [-16.328279495999936, 16.405033111000023],
                [-16.328998564999893, 16.40458488500002],
                [-16.330060958999979, 16.403844833000107],
                [-16.331546783999897, 16.40213966500005],
                [-16.332302093999942, 16.401105882000024],
                [-16.332979201999933, 16.400012970000034],
                [-16.333711622999942, 16.398969651000129],
                [-16.334424971999908, 16.39774131900009],
                [-16.335041045999901, 16.396469116999981],
                [-16.335418700999981, 16.395246507000081],
                [-16.335712431999866, 16.393877030000056],
                [-16.336248396999963, 16.391887665000183],
                [-16.33648300099992, 16.390310288000137],
                [-16.336742399999821, 16.389080048000153],
                [-16.336696624999945, 16.386644364000063],
                [-16.336688994999918, 16.385564804000069],
                [-16.336746214999948, 16.384204865000072],
                [-16.336809157999937, 16.383277894000059],
                [-16.336872100999869, 16.382177352000099],
                [-16.336797714999932, 16.380987167000114],
                [-16.336786269999891, 16.379354477000049],
                [-16.336799622999933, 16.377996444000019],
                [-16.3366279599999, 16.376768111000104],
                [-16.336547850999978, 16.375247955000077],
                [-16.336544035999907, 16.374473571000124],
                [-16.336463926999954, 16.373205184000085],
                [-16.336442947999899, 16.3701019290001],
                [-16.336565016999884, 16.368980409000017],
                [-16.336410521999881, 16.367471695000063],
                [-16.33608245899984, 16.366310119000048],
                [-16.335878371999911, 16.365423202000159],
                [-16.335437773999956, 16.363636016000044],
                [-16.335069656999963, 16.36256218],
                [-16.334875106999959, 16.36165618800004],
                [-16.334646224999915, 16.360725402000128],
                [-16.334199905999924, 16.359611512000129],
                [-16.334049223999898, 16.358865737000144],
                [-16.334045410999977, 16.358085633000087],
                [-16.333959579999942, 16.356254578],
                [-16.333703994999951, 16.354940415999977],
                [-16.333803176999879, 16.354274750000172],
                [-16.33386421299997, 16.353317259999983],
                [-16.333976745999962, 16.352460861999987],
                [-16.334215163999943, 16.35151291000011],
                [-16.334804533999886, 16.350233078000088],
                [-16.335817336999924, 16.348033905000079],
                [-16.336551665999934, 16.346641540000064],
                [-16.33724784899988, 16.34571266100005],
                [-16.337669372999869, 16.345033646000104],
                [-16.337903976999826, 16.344655991000025],
                [-16.338760376999971, 16.343641281000089],
                [-16.339303969999946, 16.342960357000038],
                [-16.339828491999981, 16.342279435000023],
                [-16.340419769999869, 16.341733932000125],
                [-16.34080505299994, 16.341247559000124],
                [-16.341381072999923, 16.340742111],
                [-16.342145920999883, 16.339923860000113],
                [-16.34251022299992, 16.339330674000053],
                [-16.342914581999878, 16.338043214000095],
                [-16.343420027999969, 16.33758163499999],
                [-16.343955992999895, 16.337162018000186],
                [-16.344001769999977, 16.337125779000132],
                [-16.344633101999875, 16.336732865000101],
                [-16.345275879999974, 16.336387634000062],
                [-16.346698761999903, 16.335916519000079],
                [-16.348129270999948, 16.336248398000066],
                [-16.348773955999945, 16.336355210000022],
                [-16.349414825999929, 16.336242677000087],
                [-16.349975584999981, 16.335924150000096],
                [-16.350502014999904, 16.335105896000073],
                [-16.352144241999895, 16.335294723000118],
                [-16.353212356999904, 16.335109711000086],
                [-16.354190825999865, 16.334629059000122],
                [-16.355175018999944, 16.333850860000155],
                [-16.356285095999965, 16.333185196000102],
                [-16.357040405999896, 16.332502365000039],
                [-16.357269286999951, 16.332294465000075],
                [-16.358041762999903, 16.331665040000132],
                [-16.358833312999934, 16.33090210000006],
                [-16.359567641999945, 16.330247879000183],
                [-16.360324859999878, 16.329357147000053],
                [-16.360818862999906, 16.328580856000031],
                [-16.361299515999974, 16.327995301000158],
                [-16.362089157999947, 16.326942444000167],
                [-16.362535475999834, 16.326396943000077],
                [-16.363410949999945, 16.325599671000191],
                [-16.364379882999913, 16.324359893000064],
                [-16.36497688299994, 16.3236484520001],
                [-16.36562347399996, 16.322477340000034],
                [-16.366270065999913, 16.321439744000145],
                [-16.366516112999932, 16.320714950000024],
                [-16.366821289999962, 16.320053101000099],
                [-16.367141723999907, 16.319072723000147],
                [-16.367572784999879, 16.31816864000001],
                [-16.36800575299992, 16.317106248000073],
                [-16.368604660999893, 16.315866470000174],
                [-16.368894576999935, 16.314861298000096],
                [-16.369316100999981, 16.313913346000049],
                [-16.369750976999967, 16.312358856],
                [-16.369970321999915, 16.31121253900011],
                [-16.370227813999918, 16.310258865000094],
                [-16.370523452999976, 16.308658600000115],
                [-16.37078857299997, 16.307470322000029],
                [-16.371477127999981, 16.303810120000037],
                [-16.371490477999942, 16.301313400000026],
                [-16.371408461999977, 16.300115585000128],
                [-16.371330260999912, 16.299264907000122],
                [-16.371095656999955, 16.297794343000135],
                [-16.370565414999874, 16.296344757999975],
                [-16.370145797999953, 16.295412064000061],
                [-16.369674682999971, 16.293951035000077],
                [-16.368989943999907, 16.292661667000175],
                [-16.368585586999927, 16.291830063000077],
                [-16.368036268999958, 16.290851593000127],
                [-16.367334365999966, 16.289905548000092],
                [-16.366439818999936, 16.289093018000017],
                [-16.365562437999927, 16.288320542000065],
                [-16.364881515999969, 16.287673951000102],
                [-16.363916395999979, 16.287111282000183],
                [-16.363401411999973, 16.286668777000102],
                [-16.362564086999953, 16.286062240999968],
                [-16.361906051999881, 16.285375596000108],
                [-16.361188887999958, 16.284690857000044],
                [-16.360351561999948, 16.284418106000089],
                [-16.359685897999952, 16.283246994000024],
                [-16.359582900999953, 16.282419205],
                [-16.359022140999912, 16.281885148000129],
                [-16.358474730999944, 16.280904769000131],
                [-16.358165740999937, 16.280143738000106],
                [-16.35768699699986, 16.279214860000025],
                [-16.357294082999942, 16.278364182000132],
                [-16.357116699999835, 16.277482986000052],
                [-16.35677528399998, 16.276296616000138],
                [-16.356710432999876, 16.275402069000108],
                [-16.356565475999957, 16.274255753000091],
                [-16.35655784599993, 16.273075105000146],
                [-16.356481552999924, 16.271968843000082],
                [-16.356523513999946, 16.270721437000077],
                [-16.357437134999941, 16.269029618000161],
                [-16.358085632999973, 16.267740250000031],
                [-16.359058380999898, 16.266668320000178],
                [-16.359628677999922, 16.265884400000175],
                [-16.360359191999976, 16.265392305000034],
                [-16.361362456999927, 16.264900206999982],
                [-16.362653731999956, 16.263954162000118],
                [-16.363203047999889, 16.263534545000141],
                [-16.364238738999973, 16.262899398000116],
                [-16.365221023999879, 16.262237549000133],
                [-16.366104125999982, 16.261478424000131],
                [-16.367208479999931, 16.260183334999965],
                [-16.367874145999963, 16.258970261000172],
                [-16.368387222999957, 16.258152008000025],
                [-16.369333266999888, 16.256052017000115],
                [-16.37019920299997, 16.254840850000164],
                [-16.371164322999959, 16.252784729000155],
                [-16.371673583999893, 16.251913071000104],
                [-16.372051239999962, 16.250854493000134],
                [-16.372566223999968, 16.248802185000102],
                [-16.372699737999881, 16.248039246000133],
                [-16.372991560999878, 16.246826172000169],
                [-16.373620985999935, 16.245109559000127],
                [-16.373674392999874, 16.244365692000144],
                [-16.373916626999971, 16.243347169000174],
                [-16.374071121999862, 16.242225647000055],
                [-16.374248504999969, 16.241405488000169],
                [-16.374505996999972, 16.24051284900014],
                [-16.374561309999933, 16.238100053000153],
                [-16.374929426999927, 16.237112045000117],
                [-16.375320434999935, 16.235960008000063],
                [-16.375804900999981, 16.235004426000046],
                [-16.376369476999969, 16.233957292000127],
                [-16.377353667999955, 16.232542038000076],
                [-16.378313064999929, 16.231304170000158],
                [-16.379558563999922, 16.229801179000049],
                [-16.380340575999981, 16.228826524000169],
                [-16.381240844999979, 16.227380753000148],
                [-16.382276533999857, 16.226114274000111],
                [-16.382883071999856, 16.225261689000149],
                [-16.383853912999882, 16.224311829999976],
                [-16.384733198999982, 16.223518372000058],
                [-16.385805128999948, 16.222398758000168],
                [-16.38653755099989, 16.221240998000098],
                [-16.387006759999906, 16.220678330000112],
                [-16.387893676999909, 16.21980857900013],
                [-16.388904571999888, 16.218885423000131],
                [-16.389554976999875, 16.218313218000162],
                [-16.390565871999968, 16.21750640800019],
                [-16.391307831999882, 16.217021943000134],
                [-16.392402647999972, 16.216352462000089],
                [-16.393289564999975, 16.215772629000185],
                [-16.393991469999946, 16.215541839000082],
                [-16.395278930999893, 16.215284347000079],
                [-16.396389007999915, 16.214879990000099],
                [-16.397071836999942, 16.214824677000081],
                [-16.397928236999917, 16.214754104000178],
                [-16.399280547999979, 16.214384079000013],
                [-16.400451659999874, 16.214263916000107],
                [-16.401411055999972, 16.21420669600019],
                [-16.402511596999886, 16.214576721000185],
                [-16.404201507999915, 16.21511840900007],
                [-16.405454634999955, 16.216518402000133],
                [-16.407033920999879, 16.217529297000056],
                [-16.408123016999923, 16.21822738700007],
                [-16.408533096999918, 16.218111039],
                [-16.408878325999979, 16.218011857000022],
                [-16.409688948999928, 16.218698502000052],
                [-16.4108943949999, 16.219713212000158],
                [-16.411792754999965, 16.220130921000191],
                [-16.412799833999827, 16.220600128],
                [-16.414415359999907, 16.220859528000176],
                [-16.416276931999846, 16.221124650000093],
                [-16.418170927999881, 16.221260072000064],
                [-16.418781279999905, 16.221305847000053],
                [-16.420660019999957, 16.220684052000024],
                [-16.422559738999837, 16.220079422000083],
                [-16.423288345999879, 16.219882965000068],
                [-16.424690245999898, 16.219171524000103],
                [-16.425725936999982, 16.218811035000044],
                [-16.427097320999906, 16.218046189000177],
                [-16.42820358299997, 16.217941284000119],
                [-16.429946899999891, 16.217794419000143],
                [-16.430662155999926, 16.217590332000043],
                [-16.43226814399992, 16.216766358000086],
                [-16.434278487999904, 16.215677261999986],
                [-16.435270309999908, 16.215223314000127],
                [-16.436513899999966, 16.214542388999973],
                [-16.437767028999872, 16.213525771999969],
                [-16.438631056999952, 16.212980271000049],
                [-16.440483093999831, 16.211359025000036],
                [-16.441576004999945, 16.210071565000078],
                [-16.442390440999816, 16.208986282000069],
                [-16.443614959999877, 16.207405090000179],
                [-16.444368362999967, 16.206666946000041],
                [-16.445272445999876, 16.205430985000191],
                [-16.446062087999849, 16.204597473000149],
                [-16.447151183999893, 16.203529358000139],
                [-16.448118208999972, 16.201967240000045],
                [-16.448770521999961, 16.201005935000126],
                [-16.449396133999926, 16.199874877000184],
                [-16.449787139999955, 16.199232102],
                [-16.450134275999972, 16.198507308000103],
                [-16.450546264999957, 16.198003769000081],
                [-16.45120811399994, 16.196859361000065],
                [-16.451822279999931, 16.19578552300004],
                [-16.452133179999976, 16.195064544000161],
                [-16.452581405999979, 16.194406508999975],
                [-16.453081131999909, 16.19351959200003],
                [-16.453500748999886, 16.192552567000064],
                [-16.454044341999918, 16.191347122000082],
                [-16.454326629999855, 16.190431595000064],
                [-16.454710005999971, 16.189867020000179],
                [-16.455068587999961, 16.189365387000123],
                [-16.455659866999952, 16.188831330000085],
                [-16.45656585699993, 16.188219071000162],
                [-16.45711517299992, 16.187631608000117],
                [-16.457334517999925, 16.18704032800008],
                [-16.457105637999916, 16.186203004000049],
                [-16.458030700999927, 16.185882569000114],
                [-16.458444594999946, 16.185398100999976],
                [-16.45897102299989, 16.183656692000056],
                [-16.458776473999876, 16.18230056800013],
                [-16.45882797299987, 16.181333543000108],
                [-16.458883285999946, 16.180152893000127],
                [-16.459060668999939, 16.179208756000094],
                [-16.459259033999842, 16.178281785000024],
                [-16.459356307999883, 16.177082062000181],
                [-16.459417343999974, 16.175716400000169],
                [-16.459480285999916, 16.174932481000042],
                [-16.459478378999961, 16.173969268000178],
                [-16.459266661999948, 16.172758102000159],
                [-16.459318160999942, 16.171405793000076],
                [-16.459087370999953, 16.170127868000122],
                [-16.459018706999927, 16.169242859000178],
                [-16.458717345999958, 16.167879105000168],
                [-16.458650589999934, 16.167125702000078],
                [-16.45828056299996, 16.165744783000093],
                [-16.458215712999959, 16.164850236000063],
                [-16.458057402999884, 16.16354370199997],
                [-16.457410811999864, 16.161365510000053],
                [-16.457342148999942, 16.160501480000164],
                [-16.457187651999959, 16.158916473000033],
                [-16.45690155099993, 16.157299042000034],
                [-16.45689392, 16.156360626000037],
                [-16.456945419999897, 16.155609131000119],
                [-16.457139968999911, 16.154733657000122],
                [-16.458526611999901, 16.153537751000158],
                [-16.459020614999929, 16.153131484000028],
                [-16.459341049999978, 16.152585983999984],
                [-16.459449767999899, 16.151966095000148],
                [-16.459331511999892, 16.151348113000154],
                [-16.459003448999908, 16.150808335000079],
                [-16.459316253999873, 16.149871826000151],
                [-16.459211348999872, 16.149131775000114],
                [-16.458889006999868, 16.148220062000064],
                [-16.458774565999818, 16.147487640000065],
                [-16.458568573999912, 16.146564484000066],
                [-16.458496092999951, 16.145196915000156],
                [-16.458490371999915, 16.144355772999972],
                [-16.458333969999956, 16.143108366999968],
                [-16.458259581999926, 16.141950608],
                [-16.45818328799993, 16.140846253000063],
                [-16.458074569999894, 16.139041901000155],
                [-16.457788466999943, 16.138229371000079],
                [-16.457469939999953, 16.137674332000074],
                [-16.457029341999942, 16.137149811000086],
                [-16.456718444999865, 16.136186600000087],
                [-16.456584930999895, 16.135322572000177],
                [-16.456439971999828, 16.13419342200018],
                [-16.456794737999871, 16.132917405],
                [-16.457916260999923, 16.131406783999978],
                [-16.458332062999887, 16.130590438000127],
                [-16.458707809999964, 16.129396439],
                [-16.459095000999923, 16.128377914000055],
                [-16.459323883999957, 16.127704620000088],
                [-16.459564207999961, 16.126768112000036],
                [-16.459802626999874, 16.125881195000147],
                [-16.460329055999978, 16.12468147300018],
                [-16.46123886099997, 16.123399734000088],
                [-16.461347578999892, 16.122779847],
                [-16.461229323999873, 16.122163773000011],
                [-16.461122512999907, 16.121351243000106],
                [-16.46111869799995, 16.120206834000044],
                [-16.460664748999875, 16.118259430000023],
                [-16.460239409999872, 16.11751556500019],
                [-16.459794998999939, 16.11700058100007],
                [-16.458763122999926, 16.116142273000094],
                [-16.458362578999925, 16.115594865000105],
                [-16.457824706999929, 16.114984512000149],
                [-16.456886291999979, 16.114152908000051],
                [-16.455680845999893, 16.113203048000173],
                [-16.455135344999974, 16.112751007000156],
                [-16.454622268999969, 16.112262727000086],
                [-16.45346641499998, 16.111381531000177],
                [-16.452253342999882, 16.11069870100016],
                [-16.451457977999894, 16.110317231000067],
                [-16.450307846999976, 16.109830857000134],
                [-16.449115752999887, 16.109060287000148],
                [-16.448789595999926, 16.108537675000036],
                [-16.448099135999882, 16.10755538900014],
                [-16.447645186999921, 16.106920242000058],
                [-16.447374343999968, 16.106203080000057],
                [-16.446899413999915, 16.105407715000069],
                [-16.447004318999916, 16.104780198000128],
                [-16.447290419999945, 16.103008271000135],
                [-16.447631835999971, 16.10228347900005],
                [-16.448001860999852, 16.101499557000182],
                [-16.448286056999905, 16.100360871000134],
                [-16.448911665999901, 16.099935532000188],
                [-16.44960784899996, 16.099466324000105],
                [-16.45049858099992, 16.098648071000184],
                [-16.451255798999966, 16.098207473000116],
                [-16.452480315999935, 16.098194122000166],
                [-16.454130172999953, 16.097755432000099],
                [-16.45549011199995, 16.097114564000037],
                [-16.45995712399997, 16.094209671000101],
                [-16.462024687999872, 16.093095779000123],
                [-16.464284896999914, 16.089239120000173],
                [-16.46489333999989, 16.085210801000187],
                [-16.46492767399991, 16.085071565000192],
                [-16.46556091399998, 16.082527160999973],
                [-16.467107773999885, 16.081937791000087],
                [-16.470325469999864, 16.080709458000172],
                [-16.47211837899988, 16.079381942999987],
                [-16.479335784999932, 16.068424225000172],
                [-16.483106613999894, 16.065694809000149],
                [-16.483434676999877, 16.062158584000031],
                [-16.487436293999963, 16.062164307000046],
                [-16.490709304999939, 16.062168122000116],
                [-16.494176864999872, 16.062007904000154],
                [-16.495786667999937, 16.061937333000117],
                [-16.497652052999911, 16.06185722500004],
                [-16.499830244999941, 16.061903],
                [-16.509552002999897, 16.06167221100003],
                [-16.509531021999919, 16.062105179000127],
                [-16.505720137999901, 16.062196731000085],
                [-16.509582518999935, 16.062637329000097],
                [-16.509582518999935, 16.065416336000112],
                [-16.509305953999956, 16.065416336000112],
                [-16.509305953999956, 16.067916871000136],
                [-16.509582518999935, 16.067916871000136],
                [-16.509582518999935, 16.069860460000086],
                [-16.509859084999903, 16.069860460000086],
                [-16.509859084999903, 16.07569694599999],
                [-16.510139464999895, 16.07569694599999],
                [-16.510139464999895, 16.075971604000188],
                [-16.509859084999903, 16.075971604000188],
                [-16.509859084999903, 16.076248169000053],
                [-16.509582518999935, 16.076248169000053],
                [-16.509582518999935, 16.077917100000036],
                [-16.509859084999903, 16.077917100000036],
                [-16.509859084999903, 16.079305649999981],
                [-16.510139464999895, 16.079305649999981],
                [-16.510139464999895, 16.081249238000055],
                [-16.510416030999977, 16.081249238000055],
                [-16.510416030999977, 16.082639693999965],
                [-16.5106945039999, 16.082639693999965],
                [-16.5106945039999, 16.083194732000095],
                [-16.510971068999879, 16.083194732000095],
                [-16.510971068999879, 16.084028244000137],
                [-16.511249541999916, 16.084028244000137],
                [-16.511249541999916, 16.08458328200004],
                [-16.510971068999879, 16.08458328200004],
                [-16.510971068999879, 16.087638854999966],
                [-16.5106945039999, 16.087638854999966],
                [-16.5106945039999, 16.090415955000083],
                [-16.510416030999977, 16.090415955000083],
                [-16.510416030999977, 16.092916488000128],
                [-16.510139464999895, 16.092916488000128],
                [-16.510139464999895, 16.095138549000012],
                [-16.510416030999977, 16.095138549000012],
                [-16.510416030999977, 16.096250534000092],
                [-16.5106945039999, 16.096250534000092],
                [-16.5106945039999, 16.098472595000032],
                [-16.510971068999879, 16.098472595000032],
                [-16.510971068999879, 16.102918626000076],
                [-16.5106945039999, 16.102918626000076],
                [-16.5106945039999, 16.104305269000179],
                [-16.510971068999879, 16.104305269000179],
                [-16.510971068999879, 16.106252670000117],
                [-16.511249541999916, 16.106252670000117],
                [-16.511249541999916, 16.110137940000016],
                [-16.510971068999879, 16.110137940000016],
                [-16.510971068999879, 16.112083436000091],
                [-16.511249541999916, 16.112083436000091],
                [-16.511249541999916, 16.113750459000073],
                [-16.511528014999953, 16.113750459000073],
                [-16.511528014999953, 16.11875152600004],
                [-16.511804580999922, 16.11875152600004],
                [-16.511804580999922, 16.120695114000114],
                [-16.511528014999953, 16.120695114000114],
                [-16.511528014999953, 16.122360230000027],
                [-16.511804580999922, 16.122360230000027],
                [-16.511806487999934, 16.122638703000064],
                [-16.5120811459999, 16.122638703000064],
                [-16.5120811459999, 16.124305726000046],
                [-16.511806487999934, 16.124305726000046],
                [-16.511806487999934, 16.130695343000184],
                [-16.5120811459999, 16.130695343000184],
                [-16.5120811459999, 16.13541603200008],
                [-16.512363433999894, 16.13541603200008],
                [-16.512363433999894, 16.135974885000053],
                [-16.512638091999975, 16.135974885000053],
                [-16.512638091999975, 16.137638093000191],
                [-16.512363433999894, 16.137638093000191],
                [-16.512363433999894, 16.140693664000139],
                [-16.512638091999975, 16.140693664000139],
                [-16.512638091999975, 16.142360687000121],
                [-16.512363433999894, 16.142360687000121],
                [-16.512363433999894, 16.151527404000149],
                [-16.512638091999975, 16.151527404000149],
                [-16.512638091999975, 16.155136108000136],
                [-16.512916564999841, 16.155136108000136],
                [-16.512916564999841, 16.155416489000174],
                [-16.51319313099998, 16.155416489000174],
                [-16.51319313099998, 16.159862519000114],
                [-16.513473510999916, 16.159862519000114],
                [-16.513473510999916, 16.162082671000178],
                [-16.513750076999884, 16.162082671000178],
                [-16.513750076999884, 16.164304733000165],
                [-16.513473510999916, 16.164304733000165],
                [-16.513473510999916, 16.165416718000074],
                [-16.513750076999884, 16.165416718000074],
                [-16.513750076999884, 16.165695191000111],
                [-16.513473510999916, 16.165695191000111],
                [-16.513473510999916, 16.165971756000147],
                [-16.51319313099998, 16.165971756000147],
                [-16.51319313099998, 16.166528702000051],
                [-16.5120811459999, 16.166528702000051],
                [-16.5120811459999, 16.166805268000189],
                [-16.511806487999934, 16.166805268000189],
                [-16.511806487999934, 16.167915345000097],
                [-16.5120811459999, 16.167915345000097],
                [-16.5120811459999, 16.168750764000038],
                [-16.512363433999894, 16.168750764000038],
                [-16.512363433999894, 16.169582368000135],
                [-16.5120811459999, 16.169584275000034],
                [-16.5120811459999, 16.169860841000173],
                [-16.512363433999894, 16.169860841000173],
                [-16.512363433999894, 16.172639846000152],
                [-16.5120811459999, 16.172639846000152],
                [-16.5120811459999, 16.17902755800003],
                [-16.512363433999894, 16.17902755800003],
                [-16.512363433999894, 16.180139542000177],
                [-16.5120811459999, 16.180139542000177],
                [-16.5120811459999, 16.181249619000084],
                [-16.511806487999934, 16.181249619000084],
                [-16.511804580999922, 16.181526185000052],
                [-16.511528014999953, 16.181526185000052],
                [-16.511528014999953, 16.18236160399999],
                [-16.511804580999922, 16.18236160399999],
                [-16.511806487999934, 16.182636262000187],
                [-16.5120811459999, 16.182636262000187],
                [-16.5120811459999, 16.182916642000123],
                [-16.512363433999894, 16.182916642000123],
                [-16.512363433999894, 16.186527252000076],
                [-16.5120811459999, 16.186527252000076],
                [-16.5120811459999, 16.187639236000052],
                [-16.511806487999934, 16.187639236000052],
                [-16.511804580999922, 16.187915802000191],
                [-16.511528014999953, 16.187915802000191],
                [-16.511528014999953, 16.188472748000095],
                [-16.511249541999916, 16.188472748000095],
                [-16.511249541999916, 16.194305421000138],
                [-16.510971068999879, 16.194305421000138],
                [-16.510971068999879, 16.197082519000048],
                [-16.511249541999916, 16.197082519000048],
                [-16.511249541999916, 16.200416565000069],
                [-16.510971068999879, 16.200416565000069],
                [-16.510971068999879, 16.202638627000056],
                [-16.511249541999916, 16.202638627000056],
                [-16.511249541999916, 16.205694200000039],
                [-16.511528014999953, 16.205694200000039],
                [-16.511528014999953, 16.206804277000117],
                [-16.511249541999916, 16.206804277000117],
                [-16.511249541999916, 16.207639694000022],
                [-16.510971068999879, 16.207639694000022],
                [-16.510971068999879, 16.210138321000102],
                [-16.511249541999916, 16.210138321000102],
                [-16.511249541999916, 16.210695267000176],
                [-16.511528014999953, 16.210695267000176],
                [-16.511528014999953, 16.211805344000084],
                [-16.511804580999922, 16.211805344000084],
                [-16.511804580999922, 16.21541595500014],
                [-16.511249541999916, 16.21541595500014],
                [-16.511249541999916, 16.215694428000177],
                [-16.510971068999879, 16.215694428000177],
                [-16.510971068999879, 16.216527940000049],
                [-16.511249541999916, 16.216527940000049],
                [-16.511249541999916, 16.216806413000086],
                [-16.511528014999953, 16.216806413000086],
                [-16.511528014999953, 16.217084886000123],
                [-16.511804580999922, 16.217084886000123],
                [-16.511806487999934, 16.217361450999988],
                [-16.5120811459999, 16.217361450999988],
                [-16.5120811459999, 16.219583511],
                [-16.512363433999894, 16.219583511],
                [-16.512363433999894, 16.223747253000056],
                [-16.512638091999975, 16.223747253000056],
                [-16.512638091999975, 16.225416184000039],
                [-16.512916564999841, 16.225416184000039],
                [-16.512916564999841, 16.226804734000041],
                [-16.51319313099998, 16.226804734000041],
                [-16.51319313099998, 16.227638245000151],
                [-16.513473510999916, 16.227638245000151],
                [-16.513473510999916, 16.229583740000066],
                [-16.513750076999884, 16.229583740000066],
                [-16.513750076999884, 16.233472823999989],
                [-16.514028549999921, 16.233472823999989],
                [-16.514028549999921, 16.234306336000031],
                [-16.514303207999887, 16.234306336000031],
                [-16.514303207999887, 16.235137940000129],
                [-16.514581680999925, 16.235139846999971],
                [-16.514581680999925, 16.236526490000074],
                [-16.514860153999962, 16.236526490000074],
                [-16.514862060999974, 16.237361909000185],
                [-16.515138626999942, 16.237361909000185],
                [-16.515138626999942, 16.238470078000091],
                [-16.515417099999979, 16.238470078000091],
                [-16.515417099999979, 16.240972518000035],
                [-16.515695570999981, 16.240972518000035],
                [-16.515695570999981, 16.243194580000022],
                [-16.515972135999959, 16.243194580000022],
                [-16.515972135999959, 16.244028091000189],
                [-16.516250609999929, 16.244028091000189],
                [-16.516250609999929, 16.244583130000024],
                [-16.516527174999965, 16.244583130000024],
                [-16.516527174999965, 16.246528626000043],
                [-16.516805647999945, 16.246528626000043],
                [-16.516805647999945, 16.24819374100008],
                [-16.517084120999982, 16.24819374100008],
                [-16.517084120999982, 16.249584198999969],
                [-16.517360685999904, 16.249584198999969],
                [-16.517360685999904, 16.25013733000003],
                [-16.517639159999874, 16.25013733000003],
                [-16.517639159999874, 16.250972749000141],
                [-16.517917632999911, 16.250972749000141],
                [-16.517917632999911, 16.25180626000008],
                [-16.518194197999946, 16.25180626000008],
                [-16.518194197999946, 16.253469468000048],
                [-16.518472670999927, 16.253469468000048],
                [-16.518472670999927, 16.25569534300007],
                [-16.518749236999952, 16.25569534300007],
                [-16.518749236999952, 16.257083893000015],
                [-16.519027709999932, 16.257083893000015],
                [-16.519027709999932, 16.258195877000048],
                [-16.519306182999969, 16.258195877000048],
                [-16.519306182999969, 16.260139466000169],
                [-16.519582747999948, 16.260139466000169],
                [-16.519582747999948, 16.260972977000108],
                [-16.519861220999871, 16.260972977000108],
                [-16.519861220999871, 16.262916566000115],
                [-16.520139693999909, 16.262916566000115],
                [-16.520139693999909, 16.265140532999965],
                [-16.520416259999877, 16.265140532999965],
                [-16.520416259999877, 16.267915726000183],
                [-16.520694732999914, 16.267917633000081],
                [-16.520694732999914, 16.272083283000143],
                [-16.520416259999877, 16.272083283000143],
                [-16.520416259999877, 16.274860383000032],
                [-16.520694732999914, 16.274860383000032],
                [-16.520694732999914, 16.275138856000069],
                [-16.520416259999877, 16.275138856000069],
                [-16.520416259999877, 16.27625083900017],
                [-16.520694732999914, 16.27625083900017],
                [-16.520694732999914, 16.27708244300004],
                [-16.520416259999877, 16.27708244300004],
                [-16.520416259999877, 16.27763748100017],
                [-16.520139693999909, 16.27763748100017],
                [-16.520139693999909, 16.279306412000096],
                [-16.519861220999871, 16.279306412000096],
                [-16.519861220999871, 16.281805039000176],
                [-16.519582747999948, 16.281805039000176],
                [-16.519582747999948, 16.284585954000136],
                [-16.519306182999969, 16.284585954000136],
                [-16.519306182999969, 16.286804200000176],
                [-16.519027709999932, 16.286804200000176],
                [-16.519027709999932, 16.287639617000139],
                [-16.518749236999952, 16.287639617000139],
                [-16.518749236999952, 16.288473129000181],
                [-16.518472670999927, 16.288473129000181],
                [-16.518472670999927, 16.289304733000051],
                [-16.518194197999946, 16.289304733000051],
                [-16.518194197999946, 16.290416717000028],
                [-16.517917632999911, 16.290416717000028],
                [-16.517917632999911, 16.291248321000126],
                [-16.517639159999874, 16.291248321000126],
                [-16.517639159999874, 16.292640686000084],
                [-16.517360685999904, 16.292640686000084],
                [-16.517360685999904, 16.294027329000187],
                [-16.517084120999982, 16.294027329000187],
                [-16.517084120999982, 16.294862748000128],
                [-16.516805647999945, 16.294862748000128],
                [-16.516805647999945, 16.295694352000169],
                [-16.516527174999965, 16.295694352000169],
                [-16.516527174999965, 16.298194885000044],
                [-16.516250609999929, 16.298194885000044],
                [-16.516250609999929, 16.298471450000079],
                [-16.515972135999959, 16.298471450000079],
                [-16.515972135999959, 16.299861908000025],
                [-16.515695570999981, 16.299861908000025],
                [-16.515695570999981, 16.300138473000061],
                [-16.515417099999979, 16.300138473000061],
                [-16.515417099999979, 16.300416946000098],
                [-16.515138626999942, 16.300416946000098],
                [-16.515138626999942, 16.30125045799997],
                [-16.514862060999974, 16.30125045799997],
                [-16.514860153999962, 16.303747177000048],
                [-16.514581680999925, 16.303747177000048],
                [-16.514581680999925, 16.304582595999989],
                [-16.514303207999887, 16.304582595999989],
                [-16.514303207999887, 16.305141450000065],
                [-16.514028549999921, 16.305141450000065],
                [-16.514028549999921, 16.305416108000031],
                [-16.513750076999884, 16.305416108000031],
                [-16.513750076999884, 16.305694581000068],
                [-16.513473510999916, 16.305694581000068],
                [-16.513473510999916, 16.307081223000068],
                [-16.51319313099998, 16.307081223000068],
                [-16.51319313099998, 16.308750154000052],
                [-16.512916564999841, 16.308750154000052],
                [-16.512916564999841, 16.309583663000183],
                [-16.512638091999975, 16.309583663000183],
                [-16.512638091999975, 16.31180572500017],
                [-16.512363433999894, 16.31180572500017],
                [-16.512363433999894, 16.312084198000036],
                [-16.5120811459999, 16.312084198000036],
                [-16.5120811459999, 16.312639236000109],
                [-16.511806487999934, 16.312639236000109],
                [-16.511804580999922, 16.314027786000054],
                [-16.511528014999953, 16.314027786000054],
                [-16.511528014999953, 16.315971375000061],
                [-16.511249541999916, 16.315971375000061],
                [-16.511249541999916, 16.317638398000042],
                [-16.510971068999879, 16.317638398000042],
                [-16.510971068999879, 16.317916871000079],
                [-16.5106945039999, 16.317916871000079],
                [-16.5106945039999, 16.318195344000117],
                [-16.510416030999977, 16.318195344000117],
                [-16.510416030999977, 16.318471908999982],
                [-16.510139464999895, 16.318471908999982],
                [-16.510139464999895, 16.319026947999987],
                [-16.509859084999903, 16.319026947999987],
                [-16.509859084999903, 16.320692063000024],
                [-16.509582518999935, 16.320692063000024],
                [-16.509582518999935, 16.3215274800001],
                [-16.509305953999956, 16.3215274800001],
                [-16.509305953999956, 16.322639465],
                [-16.509027480999919, 16.322639465],
                [-16.509027480999919, 16.323749542000144],
                [-16.508750914999951, 16.323749542000144],
                [-16.508750914999951, 16.324583053000083],
                [-16.508472441999913, 16.324583053000083],
                [-16.508472441999913, 16.325416565000126],
                [-16.508193968999876, 16.325416565000126],
                [-16.508193968999876, 16.325971603000028],
                [-16.507917403999897, 16.325971603000028],
                [-16.507917403999897, 16.327083588000107],
                [-16.507638930999974, 16.327083588000107],
                [-16.507638930999974, 16.328750611000089],
                [-16.507360457999937, 16.328750611000089],
                [-16.507360457999937, 16.329305650000094],
                [-16.507083891999969, 16.329305650000094],
                [-16.507083891999969, 16.329860688000053],
                [-16.506805418999932, 16.329860688000053],
                [-16.506805418999932, 16.330137253000089],
                [-16.506528853999953, 16.330137253000089],
                [-16.506528853999953, 16.33124733],
                [-16.506250380999916, 16.33124733],
                [-16.506250380999916, 16.332082750000041],
                [-16.505971907999879, 16.332082750000041],
                [-16.505971907999879, 16.332916259000172],
                [-16.50569534199991, 16.332916259000172],
                [-16.50569534199991, 16.333194732000038],
                [-16.505416868999873, 16.333194732000038],
                [-16.505416868999873, 16.333751678000112],
                [-16.50513839599995, 16.333751678000112],
                [-16.50513839599995, 16.335138321000159],
                [-16.504861830999971, 16.335138321000159],
                [-16.504861830999971, 16.335973740000099],
                [-16.504583357999934, 16.335973740000099],
                [-16.504583357999934, 16.336526871000103],
                [-16.504306791999966, 16.336526871000103],
                [-16.504306791999966, 16.337083817],
                [-16.504028320999907, 16.337083817],
                [-16.504028320999907, 16.337917328000174],
                [-16.50374984799987, 16.337917328000174],
                [-16.50374984799987, 16.338472367000179],
                [-16.503471374999947, 16.338472367000179],
                [-16.503471374999947, 16.339582444000087],
                [-16.503196714999945, 16.339582444000087],
                [-16.503196714999945, 16.340139390000161],
                [-16.502916336999931, 16.340139390000161],
                [-16.502916336999931, 16.340694428000063],
                [-16.502639770999963, 16.340694428000063],
                [-16.502639770999963, 16.341527940000105],
                [-16.502361297999926, 16.341527940000105],
                [-16.502361297999926, 16.342361451000045],
                [-16.502084732999947, 16.342361451000045],
                [-16.502084732999947, 16.34347152800018],
                [-16.5018062599999, 16.34347152800018],
                [-16.5018062599999, 16.344583511000053],
                [-16.501527786999873, 16.344583511000053],
                [-16.501527786999873, 16.34541893],
                [-16.501249313999949, 16.34541893],
                [-16.501249313999949, 16.345693588000188],
                [-16.500974655999926, 16.345693588000188],
                [-16.500974655999926, 16.34624862600009],
                [-16.500692367999932, 16.34624862600009],
                [-16.500692367999932, 16.346527099000127],
                [-16.500415801999964, 16.346527099000127],
                [-16.500415801999964, 16.347362518000068],
                [-16.500139236999928, 16.347362518000068],
                [-16.500139236999928, 16.348194122000109],
                [-16.49986267099996, 16.348194122000109],
                [-16.49986267099996, 16.348749161000114],
                [-16.499582290999854, 16.348749161000114],
                [-16.499582290999854, 16.349861145000091],
                [-16.499305724999886, 16.349861145000091],
                [-16.499305724999886, 16.351249695000092],
                [-16.499027251999848, 16.351249695000092],
                [-16.499027251999848, 16.351528168000129],
                [-16.498752593999939, 16.351528168000129],
                [-16.498752593999939, 16.352361680000172],
                [-16.498470305999945, 16.352361680000172],
                [-16.498470305999945, 16.353471757000079],
                [-16.498193740999966, 16.353471757000079],
                [-16.498193740999966, 16.354305268000019],
                [-16.497917174999941, 16.354305268000019],
                [-16.497917174999941, 16.355138778000082],
                [-16.497638701999961, 16.355138778000082],
                [-16.497638701999961, 16.356250762000059],
                [-16.497360228999923, 16.356250762000059],
                [-16.497360228999923, 16.3570823660001],
                [-16.497083663999945, 16.357084274000101],
                [-16.497083663999945, 16.357637405000105],
                [-16.496805190999908, 16.357637405000105],
                [-16.496805190999908, 16.358472824000046],
                [-16.496528624999939, 16.358472824000046],
                [-16.496528624999939, 16.359306336000088],
                [-16.496248244999947, 16.359306336000088],
                [-16.496248244999947, 16.360137939000083],
                [-16.495971678999979, 16.360137939000083],
                [-16.495971678999979, 16.36069488600009],
                [-16.495695113999943, 16.36069488600009],
                [-16.495695113999943, 16.360969543000124],
                [-16.495416640999963, 16.360969543000124],
                [-16.495416640999963, 16.361528397000029],
                [-16.495138167999926, 16.361528397000029],
                [-16.495138167999926, 16.361804963000168],
                [-16.494861601999958, 16.361804963000168],
                [-16.494861601999958, 16.362363816000141],
                [-16.494583128999921, 16.362363816000141],
                [-16.494583128999921, 16.363193513000112],
                [-16.494304655999883, 16.363193513000112],
                [-16.494304655999883, 16.363473893000048],
                [-16.494028090999905, 16.363473893000048],
                [-16.494028090999905, 16.364027024000052],
                [-16.493749617999981, 16.364027024000052],
                [-16.493749617999981, 16.364585878000128],
                [-16.493473051999899, 16.364585878000128],
                [-16.493473051999899, 16.365695953000056],
                [-16.493194578999976, 16.365695953000056],
                [-16.493194578999976, 16.367361068000093],
                [-16.492916107999918, 16.367361068000093],
                [-16.492916107999918, 16.368749618000038],
                [-16.492639542999882, 16.368749618000038],
                [-16.492639542999882, 16.369304657000043],
                [-16.492361069999845, 16.369304657000043],
                [-16.492361069999845, 16.369861603000118],
                [-16.492082596999978, 16.369861603000118],
                [-16.492082596999978, 16.370695114000057],
                [-16.491807936999976, 16.370695114000057],
                [-16.491807936999976, 16.372083664],
                [-16.491527557999973, 16.372083664],
                [-16.491527557999973, 16.372917176000044],
                [-16.491249084999936, 16.372917176000044],
                [-16.491249084999936, 16.374584199000083],
                [-16.490972519999957, 16.374584199000083],
                [-16.490972519999957, 16.375139237000155],
                [-16.490695953999932, 16.375139237000155],
                [-16.490695953999932, 16.376249314000063],
                [-16.490417480999952, 16.376249314000063],
                [-16.490417480999952, 16.376806260000137],
                [-16.490139007999915, 16.376806260000137],
                [-16.490139007999915, 16.377084731000025],
                [-16.489860534999877, 16.377084731000025],
                [-16.489860534999877, 16.377361299000142],
                [-16.489585876999968, 16.377361299000142],
                [-16.489585876999968, 16.37763977000003],
                [-16.489305496999975, 16.37763977000003],
                [-16.489305496999975, 16.378471374000071],
                [-16.489027023999938, 16.378471374000071],
                [-16.489027023999938, 16.379583358000048],
                [-16.48875045799997, 16.379583358000048],
                [-16.48875045799997, 16.380971908000049],
                [-16.488473892999934, 16.380971908000049],
                [-16.488473892999934, 16.381526947000054],
                [-16.488195419999954, 16.381526947000054],
                [-16.488195419999954, 16.381805420000092],
                [-16.487916946999917, 16.381805420000092],
                [-16.487916946999917, 16.382083893000129],
                [-16.48763847399988, 16.382083893000129],
                [-16.48763847399988, 16.382360458000164],
                [-16.48736381599997, 16.382360458000164],
                [-16.48736381599997, 16.382638931000031],
                [-16.487081526999816, 16.382638931000031],
                [-16.487081526999816, 16.383472443000073],
                [-16.48736381599997, 16.383472443000073],
                [-16.48736381599997, 16.384305954000183],
                [-16.487081526999816, 16.384305954000183],
                [-16.487081526999816, 16.384859085000016],
                [-16.486803053999949, 16.384859085000016],
                [-16.486803053999949, 16.385141373000124],
                [-16.486528396999972, 16.385141373000124],
                [-16.486528396999972, 16.385416031000091],
                [-16.48625183099989, 16.385416031000091],
                [-16.48625183099989, 16.385971070000096],
                [-16.485971449999965, 16.385971070000096],
                [-16.485971449999965, 16.386251450000032],
                [-16.48569488499993, 16.386251450000032],
                [-16.48569488499993, 16.386804581000092],
                [-16.485416411999893, 16.386806489000037],
                [-16.485416411999893, 16.387638093000135],
                [-16.485137938999856, 16.387638093000135],
                [-16.485137938999856, 16.38847351000004],
                [-16.484859465999818, 16.38847351000004],
                [-16.484859465999818, 16.389028548000169],
                [-16.48458289999985, 16.389028548000169],
                [-16.48458289999985, 16.389583587000175],
                [-16.484306334999872, 16.389583587000175],
                [-16.484306334999872, 16.390138625000077],
                [-16.484027861999948, 16.390138625000077],
                [-16.484027861999948, 16.390972137000119],
                [-16.483749388999968, 16.390972137000119],
                [-16.483749388999968, 16.391805649000162],
                [-16.483472822999943, 16.391805649000162],
                [-16.483472822999943, 16.393194199000106],
                [-16.483194349999962, 16.393194199000106],
                [-16.483194349999962, 16.393749237],
                [-16.482915876999925, 16.393749237],
                [-16.482915876999925, 16.394582749000051],
                [-16.482639311999947, 16.394582749000051],
                [-16.482639311999947, 16.395416260000047],
                [-16.482360838999909, 16.395416260000047],
                [-16.482360838999909, 16.395694733000084],
                [-16.482084272999941, 16.395694733000084],
                [-16.482082367999965, 16.395971299000053],
                [-16.481805799999904, 16.395971299000053],
                [-16.481805799999904, 16.39624977200009],
                [-16.48152732899996, 16.39624977200009],
                [-16.48152732899996, 16.396526337000125],
                [-16.481250761999945, 16.396526337000125],
                [-16.481250761999945, 16.397916795000071],
                [-16.480972290999887, 16.397916795000071],
                [-16.480972290999887, 16.398471833000144],
                [-16.48069381799985, 16.398471833000144],
                [-16.48069381799985, 16.399583818000053],
                [-16.480417251999882, 16.399583818000053],
                [-16.480417251999882, 16.400417327000184],
                [-16.480138778999844, 16.400417327000184],
                [-16.480138778999844, 16.401527404000092],
                [-16.479860305999978, 16.401527404000092],
                [-16.479860305999978, 16.401805877000129],
                [-16.479583740999942, 16.401805877000129],
                [-16.479583740999942, 16.402915954000093],
                [-16.479305267999962, 16.402915954000093],
                [-16.479305267999962, 16.403474808000112],
                [-16.479028701999937, 16.403474808000112],
                [-16.479028701999937, 16.404027939000173],
                [-16.478750228999957, 16.404027939000173],
                [-16.478750228999957, 16.404861450000112],
                [-16.478471755999919, 16.404861450000112],
                [-16.478471755999919, 16.405416489000118],
                [-16.478195190999884, 16.405416489000118],
                [-16.478195190999884, 16.40597152700019],
                [-16.477916717999847, 16.40597152700019],
                [-16.477916717999847, 16.406806946000131],
                [-16.47763824499998, 16.406806946000131],
                [-16.47763824499998, 16.40763855],
                [-16.477361678999841, 16.40763855],
                [-16.477361678999841, 16.40791511599997],
                [-16.477083205999975, 16.40791511599997],
                [-16.477083205999975, 16.409027100000174],
                [-16.476806640999939, 16.409027100000174],
                [-16.476806640999939, 16.409584046000077],
                [-16.476528167999959, 16.409584046000077],
                [-16.476528167999959, 16.410137177000081],
                [-16.476249694999922, 16.410137177000081],
                [-16.476249694999922, 16.410694123000155],
                [-16.475973128999954, 16.410694123000155],
                [-16.475973128999954, 16.411527635000027],
                [-16.475694655999916, 16.411527635000027],
                [-16.475694655999916, 16.4120826730001],
                [-16.475416182999879, 16.4120826730001],
                [-16.475416182999879, 16.412916183000164],
                [-16.475139617999901, 16.412916183000164],
                [-16.475139617999901, 16.41402816700014],
                [-16.474861144999977, 16.41402816700014],
                [-16.474861144999977, 16.415138244000048],
                [-16.474584578999895, 16.415138244000048],
                [-16.474584578999895, 16.415695190000122],
                [-16.474306105999972, 16.415695190000122],
                [-16.474306105999972, 16.416805267000086],
                [-16.474027632999935, 16.416805267000086],
                [-16.474027632999935, 16.417638779000129],
                [-16.473749159999898, 16.417638779000129],
                [-16.473749159999898, 16.418472290000068],
                [-16.473474501999931, 16.418472290000068],
                [-16.473474501999931, 16.419027329000073],
                [-16.473192213999823, 16.419027329000073],
                [-16.473192213999823, 16.419860840000183],
                [-16.472917555999913, 16.419860840000183],
                [-16.472917555999913, 16.420694352000055],
                [-16.472639082999876, 16.420694352000055],
                [-16.472639082999876, 16.421806336000031],
                [-16.472360609999953, 16.421806336000031],
                [-16.472360609999953, 16.423749923],
                [-16.472082136999973, 16.423749923],
                [-16.472082136999973, 16.424304961000132],
                [-16.471805571999937, 16.424304961000132],
                [-16.471805571999937, 16.425416945999984],
                [-16.4715270989999, 16.425416945999984],
                [-16.4715270989999, 16.426248550000082],
                [-16.47125244099982, 16.426248550000082],
                [-16.47125244099982, 16.426527023000119],
                [-16.470970154999975, 16.426527023000119],
                [-16.470970154999975, 16.426805496000156],
                [-16.47069358899995, 16.426805496000156],
                [-16.47069358899995, 16.42736244200006],
                [-16.470417021999879, 16.42736244200006],
                [-16.470417021999879, 16.427917481000065],
                [-16.470138550999934, 16.427917481000065],
                [-16.470138550999934, 16.429306031000181],
                [-16.469860077999897, 16.429306031000181],
                [-16.469860077999897, 16.431528092000121],
                [-16.469583511999929, 16.431528092000121],
                [-16.469583511999929, 16.432083131000127],
                [-16.469305038999892, 16.432083131000127],
                [-16.469305038999892, 16.432361604000164],
                [-16.469028473999913, 16.432361604000164],
                [-16.469028473999913, 16.432638169000029],
                [-16.468748092999931, 16.432638169000029],
                [-16.468748092999931, 16.434860231000187],
                [-16.468471527999952, 16.434860231000187],
                [-16.468471527999952, 16.436527252000189],
                [-16.46819496199987, 16.436527252000189],
                [-16.46819496199987, 16.437637329000097],
                [-16.467916488999947, 16.437637329000097],
                [-16.467916488999947, 16.439306258999977],
                [-16.467639923999968, 16.439306258999977],
                [-16.467639923999968, 16.439582825000173],
                [-16.467361450999931, 16.439582825000173],
                [-16.467361450999931, 16.439859390000038],
                [-16.467082977999894, 16.439859390000038],
                [-16.467082977999894, 16.442083359000094],
                [-16.466806411999926, 16.442083359000094],
                [-16.466806411999926, 16.442916871000136],
                [-16.466527938999889, 16.442916871000136],
                [-16.466527938999889, 16.44347381700004],
                [-16.466249465999852, 16.44347381700004],
                [-16.466249465999852, 16.444585801000017],
                [-16.465972900999873, 16.444585801000017],
                [-16.465972900999873, 16.445417405000114],
                [-16.465694427999949, 16.445417405000114],
                [-16.465694427999949, 16.446250915000121],
                [-16.465417861999981, 16.446250915000121],
                [-16.465417861999981, 16.446527479999986],
                [-16.465139388999944, 16.446527479999986],
                [-16.465139388999944, 16.44680404800016],
                [-16.464860915999964, 16.44680404800016],
                [-16.464860915999964, 16.448194503000025],
                [-16.464582442999927, 16.448194503000025],
                [-16.464582442999927, 16.451805115000127],
                [-16.46430778499996, 16.451805115000127],
                [-16.46430778499996, 16.452638626000123],
                [-16.464582442999927, 16.452638626000123],
                [-16.464582442999927, 16.454027176000068],
                [-16.46430778499996, 16.454027176000068],
                [-16.46430778499996, 16.454862595000179],
                [-16.464027404999854, 16.454862595000179],
                [-16.464027404999854, 16.45569419900005],
                [-16.463750838999886, 16.45569419900005],
                [-16.463750838999886, 16.456806184000129],
                [-16.463472365999849, 16.456806184000129],
                [-16.463472365999849, 16.457639694000022],
                [-16.46319580099987, 16.457639694000022],
                [-16.46319580099987, 16.4587497710001],
                [-16.462917327999946, 16.4587497710001],
                [-16.462917327999946, 16.459861755000077],
                [-16.462638854999966, 16.459861755000077],
                [-16.462638854999966, 16.461250305000021],
                [-16.462360381999929, 16.461250305000021],
                [-16.462360381999929, 16.462360382000156],
                [-16.462085723999962, 16.462360382000156],
                [-16.462085723999962, 16.464582444000143],
                [-16.461805342999924, 16.464582444000143],
                [-16.461805342999924, 16.465415955000083],
                [-16.461526869999886, 16.465415955000083],
                [-16.461526869999886, 16.466529847000061],
                [-16.461250304999908, 16.466529847000061],
                [-16.461250304999908, 16.467916490000164],
                [-16.46097373899994, 16.467916490000164],
                [-16.46097373899994, 16.469863891000045],
                [-16.460695265999902, 16.469863891000045],
                [-16.460695265999902, 16.471250534000148],
                [-16.460416792999979, 16.471250534000148],
                [-16.460416792999979, 16.472085953000089],
                [-16.460138319999942, 16.472085953000089],
                [-16.460138319999942, 16.47291755700013],
                [-16.459863661999975, 16.47291755700013],
                [-16.459863661999975, 16.473749161],
                [-16.45958137599996, 16.473749161],
                [-16.45958137599996, 16.474582672000167],
                [-16.459304808999889, 16.474582672000167],
                [-16.459304808999889, 16.475139618000014],
                [-16.459028242999921, 16.475139618000014],
                [-16.459028242999921, 16.476526261000117],
                [-16.458749771999976, 16.476526261000117],
                [-16.458749771999976, 16.477916718000131],
                [-16.458471298999939, 16.477916718000131],
                [-16.458471298999939, 16.478195191000168],
                [-16.45819473399996, 16.478195191000168],
                [-16.45819473399996, 16.47903060800013],
                [-16.457916260999923, 16.47903060800013],
                [-16.457916260999923, 16.479858399000136],
                [-16.457641600999864, 16.479858399000136],
                [-16.457641600999864, 16.480417251000176],
                [-16.457359314999962, 16.480417251000176],
                [-16.457359314999962, 16.481252670000117],
                [-16.457082748999881, 16.481252670000117],
                [-16.457082748999881, 16.481805801000121],
                [-16.456806183999845, 16.481805801000121],
                [-16.456806183999845, 16.483194351000066],
                [-16.456527710999978, 16.483194351000066],
                [-16.456527710999978, 16.485416412000177],
                [-16.456249237999941, 16.485416412000177],
                [-16.456249237999941, 16.485694885000044],
                [-16.455972671999973, 16.485694885000044],
                [-16.455972671999973, 16.486249924000049],
                [-16.455694198999936, 16.486249924000049],
                [-16.455694198999936, 16.487083434999988],
                [-16.455415725999899, 16.487083434999988],
                [-16.455415725999899, 16.488193512000123],
                [-16.455137252999975, 16.488193512000123],
                [-16.455137252999975, 16.48902893200011],
                [-16.454860687999883, 16.48902893200011],
                [-16.454860687999883, 16.489860535000105],
                [-16.454584121999915, 16.489860535000105],
                [-16.454584121999915, 16.490972520000184],
                [-16.454305648999878, 16.490972520000184],
                [-16.454305648999878, 16.492641449000132],
                [-16.454027175999954, 16.492641449000132],
                [-16.454027175999954, 16.494028091000132],
                [-16.453750610999975, 16.494028091000132],
                [-16.453750610999975, 16.496805191000021],
                [-16.453472137999938, 16.496805191000021],
                [-16.453472137999938, 16.49958419900014],
                [-16.453193663999969, 16.49958419900014],
                [-16.453193663999969, 16.500694276000047],
                [-16.452917098999933, 16.500694276000047],
                [-16.452917098999933, 16.50208282599999],
                [-16.452638625999896, 16.50208282599999],
                [-16.452638625999896, 16.50319480800016],
                [-16.452362060999917, 16.50319480800016],
                [-16.452360152999859, 16.50513839700011],
                [-16.45208358699989, 16.50513839700011],
                [-16.45208358699989, 16.507362366000052],
                [-16.451805113999853, 16.507362366000052],
                [-16.451805113999853, 16.509580612000093],
                [-16.451528548999875, 16.509580612000093],
                [-16.451528548999875, 16.511528016000057],
                [-16.451250075999951, 16.511528016000057],
                [-16.451250075999951, 16.523750306000068],
                [-16.451528548999875, 16.523750306000068],
                [-16.451528548999875, 16.528749466000022],
                [-16.451805113999853, 16.528749466000022],
                [-16.451805113999853, 16.532083512000156],
                [-16.451528548999875, 16.532083512000156],
                [-16.451528548999875, 16.535139085000139],
                [-16.451250075999951, 16.535139085000139],
                [-16.451250075999951, 16.53791618300005],
                [-16.450971602999971, 16.53791618300005],
                [-16.450971602999971, 16.541805267000143],
                [-16.451250075999951, 16.541805267000143],
                [-16.451250075999951, 16.547359467000149],
                [-16.450971602999971, 16.547359467000149],
                [-16.450971602999971, 16.550138473000061],
                [-16.450696944999891, 16.550138473000061],
                [-16.450696944999891, 16.552360534000115],
                [-16.450416563999966, 16.552360534000115],
                [-16.450416563999966, 16.554027557000097],
                [-16.450138090999928, 16.554027557000097],
                [-16.450138090999928, 16.555694581000012],
                [-16.449861525999893, 16.555694581000012],
                [-16.449861525999893, 16.557083131000184],
                [-16.449583052999856, 16.557083131000184],
                [-16.449583052999856, 16.558471681000128],
                [-16.449306486999888, 16.558471681000128],
                [-16.449302671999931, 16.560417175000168],
                [-16.44902801399985, 16.560417175000168],
                [-16.44902801399985, 16.56291580200002],
                [-16.448749540999813, 16.56291580200002],
                [-16.448749540999813, 16.565694809000036],
                [-16.448474882999903, 16.565694809000036],
                [-16.448474882999903, 16.56819534400006],
                [-16.448194502999968, 16.56819534400006],
                [-16.448194502999968, 16.570693971000139],
                [-16.447914123999965, 16.570693971000139],
                [-16.447914123999965, 16.573749542000087],
                [-16.447639463999963, 16.573749542000087],
                [-16.447639463999963, 16.575973511000143],
                [-16.447362898999927, 16.575973511000143],
                [-16.447362898999927, 16.576805115000013],
                [-16.447084425999947, 16.576805115000013],
                [-16.447084425999947, 16.578195572000084],
                [-16.447362898999927, 16.578195572000084],
                [-16.447362898999927, 16.579305649000162],
                [-16.447084425999947, 16.579305649000162],
                [-16.447084425999947, 16.581527711000149],
                [-16.446805954999945, 16.581527711000149],
                [-16.446805954999945, 16.583471299000053],
                [-16.446527481999965, 16.583471299000053],
                [-16.446527481999965, 16.586248397000134],
                [-16.446252821999906, 16.586248397000134],
                [-16.446252821999906, 16.589027405000024],
                [-16.445970535999891, 16.589027405000024],
                [-16.445970535999891, 16.590974809000045],
                [-16.445692062999967, 16.590974809000045],
                [-16.445692062999967, 16.592638017000183],
                [-16.445417404999887, 16.592638017000183],
                [-16.445417404999887, 16.594306945000028],
                [-16.44513893199985, 16.594306945000028],
                [-16.44513893199985, 16.595972061000168],
                [-16.444860458999813, 16.595972061000168],
                [-16.444860458999813, 16.597360611000113],
                [-16.444583892999844, 16.597360611000113],
                [-16.444583892999844, 16.598747253000113],
                [-16.444305419999978, 16.598747253000113],
                [-16.444305419999978, 16.60125160299998],
                [-16.444026946999941, 16.60125160299998],
                [-16.444026946999941, 16.604583741],
                [-16.443750381999962, 16.604583741],
                [-16.443750381999962, 16.607360839000137],
                [-16.443471908999925, 16.607360839000137],
                [-16.443471908999925, 16.609306335000156],
                [-16.443195342999957, 16.609306335000156],
                [-16.443195342999957, 16.610416412000063],
                [-16.4429168699999, 16.610416412000063],
                [-16.4429168699999, 16.611804962],
                [-16.442638396999882, 16.611804962],
                [-16.442638396999882, 16.612916947000087],
                [-16.442361831999847, 16.612916947000087],
                [-16.442361831999847, 16.614582062000125],
                [-16.44208335899998, 16.614582062000125],
                [-16.44208335899998, 16.616249085000106],
                [-16.441804885999943, 16.616249085000106],
                [-16.441804885999943, 16.617361068000037],
                [-16.441528319999975, 16.617361068000037],
                [-16.441528319999975, 16.618473053000116],
                [-16.441249846999938, 16.618473053000116],
                [-16.441249846999938, 16.619583130000024],
                [-16.440971373999901, 16.619583130000024],
                [-16.440971373999901, 16.620416641000133],
                [-16.440694808999922, 16.620416641000133],
                [-16.440694808999922, 16.621528626000043],
                [-16.440416335999885, 16.621528626000043],
                [-16.440416335999885, 16.62236023000014],
                [-16.440139769999917, 16.62236023000014],
                [-16.440139769999917, 16.623472214000117],
                [-16.439861296999879, 16.623472214000117],
                [-16.439861296999879, 16.624305726000159],
                [-16.439582823999956, 16.624305726000159],
                [-16.439582823999956, 16.62513733000003],
                [-16.439306258999977, 16.62513733000003],
                [-16.439306258999977, 16.626249314],
                [-16.43902778599994, 16.626249314],
                [-16.43902778599994, 16.627082826000048],
                [-16.438749312999903, 16.627082826000048],
                [-16.438749312999903, 16.627916337000045],
                [-16.438472746999935, 16.627916337000045],
                [-16.438472746999935, 16.629028320000089],
                [-16.438194273999898, 16.629028320000089],
                [-16.438194273999898, 16.629863739000029],
                [-16.437917708999919, 16.629863739000029],
                [-16.437917708999919, 16.63069534300007],
                [-16.437639235999882, 16.63069534300007],
                [-16.437639235999882, 16.631805420000035],
                [-16.437360762999958, 16.631805420000035],
                [-16.437360762999958, 16.633195877000048],
                [-16.437084196999876, 16.633195877000048],
                [-16.437084196999876, 16.634304047000057],
                [-16.436805723999953, 16.634304047000057],
                [-16.436805723999953, 16.635139466],
                [-16.436527250999973, 16.635139466],
                [-16.436527250999973, 16.635694504000128],
                [-16.436250685999937, 16.635694504000128],
                [-16.436250685999937, 16.636526108000169],
                [-16.435972214999879, 16.636526108000169],
                [-16.435972214999879, 16.63736152700011],
                [-16.435695646999932, 16.63736152700011],
                [-16.435695646999932, 16.638195039000152],
                [-16.435417175999874, 16.638195039000152],
                [-16.435417175999874, 16.638748170000156],
                [-16.43513870299995, 16.638748170000156],
                [-16.43513870299995, 16.639583589000097],
                [-16.43486023, 16.639583589000097],
                [-16.43486023, 16.640417098000057],
                [-16.434585571999833, 16.640417098000057],
                [-16.434585571999833, 16.640970231000097],
                [-16.434303283999895, 16.640970231000097],
                [-16.434303283999895, 16.641805648],
                [-16.434028625999929, 16.641805648],
                [-16.434028625999929, 16.642639160000044],
                [-16.433750152999892, 16.642639160000044],
                [-16.433750152999892, 16.643192291000105],
                [-16.433473587999913, 16.643192291000105],
                [-16.433473587999913, 16.644027709999989],
                [-16.433195114999876, 16.644027709999989],
                [-16.433195114999876, 16.644861221000156],
                [-16.432916641999952, 16.644861221000156],
                [-16.432916641999952, 16.645416260000161],
                [-16.432638168999972, 16.645416260000161],
                [-16.432638168999972, 16.6462497710001],
                [-16.432363510999892, 16.6462497710001],
                [-16.432363510999892, 16.647083283000143],
                [-16.432081222999898, 16.647083283000143],
                [-16.432081222999898, 16.647638321000045],
                [-16.43180465699993, 16.647638321000045],
                [-16.43180465699993, 16.648471833000087],
                [-16.431528091999894, 16.648471833000087],
                [-16.431528091999894, 16.649026871000046],
                [-16.431249618999857, 16.649026871000046],
                [-16.431249618999857, 16.649860383000089],
                [-16.43097114599982, 16.649860383000089],
                [-16.43097114599982, 16.650417329000163],
                [-16.430694579999852, 16.650417329000163],
                [-16.430694579999852, 16.65125083900017],
                [-16.430416106999814, 16.65125083900017],
                [-16.430416106999814, 16.651805877000072],
                [-16.430139541999949, 16.651805877000072],
                [-16.430139541999949, 16.652360916000077],
                [-16.429859160999911, 16.652360916000077],
                [-16.429859160999911, 16.653194427000074],
                [-16.429582595999932, 16.653194427000074],
                [-16.429582595999932, 16.653749466000079],
                [-16.429306029999964, 16.653749466000079],
                [-16.429306029999964, 16.65458107000012],
                [-16.429027556999927, 16.65458107000012],
                [-16.429027556999927, 16.655138016000024],
                [-16.42874908399989, 16.655138016000024],
                [-16.42874908399989, 16.655973435000135],
                [-16.428472518999854, 16.655973435000135],
                [-16.428472518999854, 16.656528473000037],
                [-16.428194045999817, 16.656528473000037],
                [-16.428194045999817, 16.657083512000042],
                [-16.427917479999849, 16.657083512000042],
                [-16.427917479999849, 16.657638550000172],
                [-16.427637099999913, 16.657638550000172],
                [-16.427637099999913, 16.658195496000019],
                [-16.427360533999945, 16.658195496000019],
                [-16.427360533999945, 16.659027100000117],
                [-16.427083968999966, 16.659027100000117],
                [-16.427083968999966, 16.659585953999965],
                [-16.426805495999929, 16.659585953999965],
                [-16.426805495999929, 16.660139085000026],
                [-16.426528929999961, 16.660139085000026],
                [-16.426528929999961, 16.660694123000098],
                [-16.426250456999924, 16.660694123000098],
                [-16.426250456999924, 16.661249162000104],
                [-16.425971983999887, 16.661249162000104],
                [-16.425971983999887, 16.661806108000178],
                [-16.425693510999963, 16.661806108000178],
                [-16.425693510999963, 16.662639617000139],
                [-16.425416945999871, 16.662639617000139],
                [-16.425416945999871, 16.663473129000181],
                [-16.425138472999947, 16.663473129000181],
                [-16.425138472999947, 16.664026260000014],
                [-16.424861906999979, 16.664026260000014],
                [-16.424861906999979, 16.664861679000126],
                [-16.424583433999942, 16.664861679000126],
                [-16.424583433999942, 16.665695190000065],
                [-16.424306868999963, 16.665695190000065],
                [-16.424306868999963, 16.666530609],
                [-16.424028397999848, 16.666530609],
                [-16.424028397999848, 16.667360306000035],
                [-16.423749924999981, 16.667360306000035],
                [-16.423749924999981, 16.667917252000109],
                [-16.423471451999944, 16.667917252000109],
                [-16.423471451999944, 16.66875267100005],
                [-16.423196793999978, 16.66875267100005],
                [-16.423196793999978, 16.66958046000002],
                [-16.422916412999939, 16.669584275000091],
                [-16.422916412999939, 16.67013931299999],
                [-16.422637939999902, 16.67013931299999],
                [-16.422637939999902, 16.670694352],
                [-16.422361374999923, 16.670694352],
                [-16.422361374999923, 16.671527863000165],
                [-16.422084808999955, 16.671527863000165],
                [-16.422084808999955, 16.672084809000012],
                [-16.421806335999918, 16.672084809000012],
                [-16.421806335999918, 16.67291641300011],
                [-16.421527862999881, 16.67291641300011],
                [-16.421527862999881, 16.673471452000115],
                [-16.421249389999957, 16.673471452000115],
                [-16.421249389999957, 16.674028395999983],
                [-16.420974731999877, 16.674028395999983],
                [-16.420974731999877, 16.67486190700015],
                [-16.42069244399994, 16.67486190700015],
                [-16.42069244399994, 16.675416946000155],
                [-16.420415877999972, 16.675416946000155],
                [-16.420415877999972, 16.676250457000094],
                [-16.420139312999936, 16.676250457000094],
                [-16.420139312999936, 16.6768054960001],
                [-16.419862747999957, 16.6768054960001],
                [-16.419862747999957, 16.677360534],
                [-16.419584273999931, 16.677360534],
                [-16.419580458999974, 16.678194046000044],
                [-16.419305800999894, 16.678194046000044],
                [-16.419305800999894, 16.678747177000105],
                [-16.41902732799997, 16.678747177000105],
                [-16.41902732799997, 16.679306030000021],
                [-16.418752670999936, 16.679306030000021],
                [-16.418752670999936, 16.679859161000081],
                [-16.418470381999953, 16.679859161000081],
                [-16.418470381999953, 16.680694580000022],
                [-16.418193816999974, 16.680694580000022],
                [-16.418193816999974, 16.681249619000027],
                [-16.417917250999949, 16.681249619000027],
                [-16.417917250999949, 16.6818046570001],
                [-16.417638777999969, 16.6818046570001],
                [-16.417638777999969, 16.682638169000143],
                [-16.417360304999931, 16.682638169000143],
                [-16.417360304999931, 16.683195115000046],
                [-16.417083739999896, 16.683195115000046],
                [-16.417083739999896, 16.683750153000119],
                [-16.416805266999859, 16.683750153000119],
                [-16.416805266999859, 16.684305192000124],
                [-16.416528700999891, 16.684305192000124],
                [-16.416528700999891, 16.685138703000121],
                [-16.416248320999955, 16.685138703000121],
                [-16.416248320999955, 16.685972213000127],
                [-16.415971754999816, 16.685972213000127],
                [-16.415971754999816, 16.686527252000133],
                [-16.415695189999951, 16.686527252000133],
                [-16.415695189999951, 16.687360763000072],
                [-16.415416716999971, 16.687360763000072],
                [-16.415416716999971, 16.688194275000114],
                [-16.415138243999934, 16.688194275000114],
                [-16.415138243999934, 16.689027786000111],
                [-16.414861677999966, 16.689027786000111],
                [-16.414861677999966, 16.689861298000153],
                [-16.414583204999929, 16.689861298000153],
                [-16.414583204999929, 16.690416336000055],
                [-16.414304731999891, 16.690416336000055],
                [-16.414304731999891, 16.691249848000098],
                [-16.414028166999856, 16.691249848000098],
                [-16.414028166999856, 16.692083359000037],
                [-16.413749693999819, 16.692083359000037],
                [-16.413749693999819, 16.692638398000042],
                [-16.41347312799985, 16.692638398000042],
                [-16.41347312799985, 16.693195344000117],
                [-16.413194654999813, 16.693195344000117],
                [-16.413194654999813, 16.693752290000191],
                [-16.412916183999926, 16.693752290000191],
                [-16.412916183999926, 16.694305421000024],
                [-16.41263961899989, 16.694305421000024],
                [-16.41263961899989, 16.694860459000154],
                [-16.412361145999967, 16.694860459000154],
                [-16.412361145999967, 16.695692063000024],
                [-16.412082672999929, 16.695692063000024],
                [-16.412082672999929, 16.696252822000133],
                [-16.411806106999961, 16.696252822000133],
                [-16.411806106999961, 16.69708442600006],
                [-16.411527633999981, 16.69708442600006],
                [-16.411527633999981, 16.697639465000066],
                [-16.411249160999944, 16.697639465000066],
                [-16.411249160999944, 16.698194503000138],
                [-16.410972595999965, 16.698194503000138],
                [-16.410972595999965, 16.699028015000181],
                [-16.410694122999928, 16.699028015000181],
                [-16.410694122999928, 16.699583053000083],
                [-16.41041755699996, 16.699583053000083],
                [-16.41041755699996, 16.700138092000088],
                [-16.410139083999923, 16.700138092000088],
                [-16.410139083999923, 16.700971603000028],
                [-16.409860610999885, 16.700971603000028],
                [-16.409860610999885, 16.701528549000102],
                [-16.409585952999919, 16.701528549000102],
                [-16.409585952999919, 16.702083588000107],
                [-16.409305572999813, 16.702083588000107],
                [-16.409305572999813, 16.702638626000066],
                [-16.409027099999946, 16.702638626000066],
                [-16.409027099999946, 16.703472138000109],
                [-16.408750533999978, 16.703472138000109],
                [-16.408750533999978, 16.704027176000181],
                [-16.408472060999941, 16.704027176000181],
                [-16.408472060999941, 16.704584122000085],
                [-16.408195495999962, 16.704584122000085],
                [-16.408195495999962, 16.705415726000126],
                [-16.407917022999925, 16.705415726000126],
                [-16.407917022999925, 16.706249238],
                [-16.407638549999888, 16.706249238],
                [-16.407638549999888, 16.706806184000072],
                [-16.407363891999921, 16.706806184000072],
                [-16.407363891999921, 16.707641601000034],
                [-16.407081603999927, 16.707641601000034],
                [-16.407081603999927, 16.70846939200004],
                [-16.406803130999947, 16.70846939200004],
                [-16.406803130999947, 16.709028242999977],
                [-16.40652847299998, 16.709028242999977],
                [-16.40652847299998, 16.709863662000089],
                [-16.406251906999842, 16.709863662000089],
                [-16.406251906999842, 16.710695266000187],
                [-16.405973433999975, 16.710695266000187],
                [-16.405973433999975, 16.711250305000021],
                [-16.405693053999983, 16.711250305000021],
                [-16.405693053999983, 16.712085724000133],
                [-16.405416487999901, 16.712085724000133],
                [-16.405416487999901, 16.712638855000137],
                [-16.405138014999977, 16.712638855000137],
                [-16.405138014999977, 16.71319580100004],
                [-16.404863356999954, 16.71319580100004],
                [-16.404863356999954, 16.713750839000113],
                [-16.40458106899996, 16.713750839000113],
                [-16.40458106899996, 16.714305878000118],
                [-16.40430641099988, 16.714305878000118],
                [-16.40430641099988, 16.714860916000021],
                [-16.404027937999956, 16.714860916000021],
                [-16.404027937999956, 16.715417862000095],
                [-16.403749464999976, 16.715417862000095],
                [-16.403749464999976, 16.7159729010001],
                [-16.4034729, 16.7159729010001],
                [-16.4034729, 16.716527939000059],
                [-16.403194426999903, 16.716527939000059],
                [-16.403194426999903, 16.717361451000102],
                [-16.402915953999866, 16.717361451000102],
                [-16.402915953999866, 16.717916490000107],
                [-16.402639387999898, 16.717916490000107],
                [-16.402639387999898, 16.71847152800018],
                [-16.402360914999861, 16.71847152800018],
                [-16.402360914999861, 16.719028474000083],
                [-16.402082443999916, 16.719028474000083],
                [-16.402082443999916, 16.719583513000089],
                [-16.401805876999958, 16.719583513000089],
                [-16.401805876999958, 16.720138549000183],
                [-16.4015274059999, 16.720138549000183],
                [-16.4015274059999, 16.720693588000188],
                [-16.401250839999932, 16.720693588000188],
                [-16.401250839999932, 16.721252441000161],
                [-16.400972366999895, 16.721252441000161],
                [-16.400972366999895, 16.721805572000164],
                [-16.400693893999971, 16.721805572000164],
                [-16.400693893999971, 16.722362518000068],
                [-16.400417328999879, 16.722362518000068],
                [-16.400417328999879, 16.72319221500004],
                [-16.400138855999955, 16.72319221500004],
                [-16.400138855999955, 16.723749161000114],
                [-16.399860382999975, 16.723749161000114],
                [-16.399860382999975, 16.724306107000018],
                [-16.39958381699995, 16.724306107000018],
                [-16.39958381699995, 16.724861145000148],
                [-16.39930534399997, 16.724861145000148],
                [-16.39930534399997, 16.72569465700019],
                [-16.399028778999934, 16.72569465700019],
                [-16.399028778999934, 16.726249695000092],
                [-16.398750305999897, 16.726249695000092],
                [-16.398750305999897, 16.726806641000167],
                [-16.398471832999974, 16.726806641000167],
                [-16.398471832999974, 16.727638245000037],
                [-16.398195266999892, 16.727638245000037],
                [-16.398195266999892, 16.728195191000111],
                [-16.397916793999968, 16.728195191000111],
                [-16.397916793999968, 16.728750230000117],
                [-16.397638320999931, 16.728750230000117],
                [-16.397638320999931, 16.729305268000019],
                [-16.397361755999952, 16.729305268000019],
                [-16.397361755999952, 16.730138780000061],
                [-16.397083282999972, 16.730138780000061],
                [-16.397083282999972, 16.730972291000057],
                [-16.396806716999947, 16.730972291000057],
                [-16.396806716999947, 16.731527328000027],
                [-16.396528243999967, 16.731527328000027],
                [-16.396528243999967, 16.732360839000023],
                [-16.39624977099993, 16.732360839000023],
                [-16.39624977099993, 16.733194351000066],
                [-16.395971297999893, 16.733194351000066],
                [-16.395971297999893, 16.733749389000138],
                [-16.395694732999857, 16.733749389000138],
                [-16.395694732999857, 16.734582901000181],
                [-16.39541625999982, 16.734582901000181],
                [-16.39541625999982, 16.73541641200012],
                [-16.395139693999852, 16.73541641200012],
                [-16.395139693999852, 16.735973358000024],
                [-16.394861220999815, 16.735973358000024],
                [-16.394861220999815, 16.736803055],
                [-16.39458465599995, 16.736803055],
                [-16.39458465599995, 16.737363816000141],
                [-16.394306182999969, 16.737363816000141],
                [-16.394306182999969, 16.738191605000168],
                [-16.394027709999932, 16.738191605000168],
                [-16.394027709999932, 16.73875045800014],
                [-16.393749236999895, 16.73875045800014],
                [-16.393749236999895, 16.739585877000081],
                [-16.393474578999928, 16.739585877000081],
                [-16.393474578999928, 16.740417481000122],
                [-16.393192290999934, 16.740417481000122],
                [-16.393192290999934, 16.740972520000128],
                [-16.392915724999966, 16.740972520000128],
                [-16.392915724999966, 16.741807937000033],
                [-16.392639159999817, 16.741807937000033],
                [-16.392639159999817, 16.742361070000072],
                [-16.392362593999849, 16.742361070000072],
                [-16.392362593999849, 16.743194579000033],
                [-16.392082213999913, 16.743194579000033],
                [-16.392082213999913, 16.743749618000038],
                [-16.391805647999945, 16.743749618000038],
                [-16.391805647999945, 16.744583128999977],
                [-16.391527174999908, 16.744583128999977],
                [-16.391527174999908, 16.745138167999983],
                [-16.391252516999941, 16.745138167999983],
                [-16.391252516999941, 16.745695114000057],
                [-16.390970230999926, 16.745695114000057],
                [-16.390970230999926, 16.746528625000053],
                [-16.390693665999947, 16.746528625000053],
                [-16.390693665999947, 16.747083664000058],
                [-16.390417097999887, 16.747083664000058],
                [-16.390417097999887, 16.747917175000168],
                [-16.390138626999942, 16.747917175000168],
                [-16.390138626999942, 16.748470306],
                [-16.389860153999905, 16.748470306],
                [-16.389858245999847, 16.749027252000076],
                [-16.389583588999926, 16.749027252000076],
                [-16.389583588999926, 16.74986267200012],
                [-16.3893051149999, 16.74986267200012],
                [-16.3893051149999, 16.750692367999989],
                [-16.389028549999921, 16.750692367999989],
                [-16.389028549999921, 16.7515277870001],
                [-16.388748168999939, 16.7515277870001],
                [-16.388748168999939, 16.752361298999972],
                [-16.38847160399996, 16.752361298999972],
                [-16.38847160399996, 16.753196716000105],
                [-16.388195038999982, 16.753196716000105],
                [-16.388195038999982, 16.753749849000144],
                [-16.387916564999955, 16.753749849000144],
                [-16.387916564999955, 16.754306793000012],
                [-16.387638091999918, 16.754306793000012],
                [-16.387638091999918, 16.754861831000142],
                [-16.387361526999939, 16.754861831000142],
                [-16.387361526999939, 16.755416870000147],
                [-16.387083053999902, 16.755416870000147],
                [-16.387083053999902, 16.755971908000049],
                [-16.386806487999934, 16.755971908000049],
                [-16.386804580999978, 16.756528854000123],
                [-16.386526107999941, 16.756528854000123],
                [-16.386526107999941, 16.757083893000129],
                [-16.386249541999973, 16.757083893000129],
                [-16.386249541999973, 16.757638931000031],
                [-16.385972976999881, 16.757638931000031],
                [-16.385972976999881, 16.758193970000036],
                [-16.385694503999957, 16.758193970000036],
                [-16.385694503999957, 16.758472443000073],
                [-16.385417937999875, 16.758472443000073],
                [-16.385417937999875, 16.759027480999976],
                [-16.385139464999952, 16.759027480999976],
                [-16.385139464999952, 16.759582519999981],
                [-16.384860991999972, 16.759582519999981],
                [-16.384860991999972, 16.760139466000055],
                [-16.384582518999935, 16.760139466000055],
                [-16.384582518999935, 16.760694504000185],
                [-16.384307860999968, 16.760694504000185],
                [-16.384307860999968, 16.76124954300019],
                [-16.384027480999976, 16.76124954300019],
                [-16.384027480999976, 16.76208114700006],
                [-16.383750914999894, 16.76208114700006],
                [-16.383750914999894, 16.762916566000172],
                [-16.38347244199997, 16.762916566000172],
                [-16.38347244199997, 16.763750077000111],
                [-16.383195876999878, 16.763750077000111],
                [-16.383195876999878, 16.764581680999981],
                [-16.382917403999954, 16.764581680999981],
                [-16.382917403999954, 16.765417098000114],
                [-16.382638930999974, 16.765417098000114],
                [-16.382638930999974, 16.766250610000156],
                [-16.382360457999937, 16.766250610000156],
                [-16.382360457999937, 16.767084121000096],
                [-16.3820858, 16.767084121000096],
                [-16.3820858, 16.767639160000101],
                [-16.381805418999932, 16.767639160000101],
                [-16.381805418999932, 16.76819419800006],
                [-16.381526945999894, 16.76819419800006],
                [-16.381526945999894, 16.769027710000103],
                [-16.381250380999859, 16.769027710000103],
                [-16.381250380999859, 16.769582748000175],
                [-16.380973814999891, 16.769582748000175],
                [-16.380973814999891, 16.770139694000079],
                [-16.380695341999854, 16.770139694000079],
                [-16.380695341999854, 16.77097129800012],
                [-16.380416868999816, 16.77097129800012],
                [-16.380416868999816, 16.771528244000024],
                [-16.38013839599995, 16.771528244000024],
                [-16.38013839599995, 16.772083283000029],
                [-16.379863737999869, 16.772083283000029],
                [-16.379863737999869, 16.772916793999968],
                [-16.379581451999968, 16.772916793999968],
                [-16.379581451999968, 16.773471832999974],
                [-16.379304886999932, 16.773471832999974],
                [-16.379304886999932, 16.77430534400014],
                [-16.379028318999929, 16.77430534400014],
                [-16.379028318999929, 16.774860383000146],
                [-16.378751753999893, 16.774860383000146],
                [-16.378751753999893, 16.775417329000049],
                [-16.378471374999947, 16.775417329000049],
                [-16.378471374999947, 16.776252746000125],
                [-16.378194809999968, 16.776252746000125],
                [-16.378194809999968, 16.776805877000186],
                [-16.377916336999931, 16.776805877000186],
                [-16.377916336999931, 16.777639388000125],
                [-16.377641676999872, 16.777639388000125],
                [-16.377641676999872, 16.778474808000112],
                [-16.37735939099997, 16.778474808000112],
                [-16.37735939099997, 16.779584885000077],
                [-16.377082824999889, 16.779584885000077],
                [-16.377082824999889, 16.780416488000014],
                [-16.376806259999967, 16.780416488000014],
                [-16.376806259999967, 16.781806946000131],
                [-16.37652778699993, 16.781806946000131],
                [-16.37652778699993, 16.78291893100004],
                [-16.376249313999949, 16.78291893100004],
                [-16.376249313999949, 16.783750535000138],
                [-16.375972747999981, 16.783750535000138],
                [-16.375972747999981, 16.784860612000045],
                [-16.375694274999944, 16.784860612000045],
                [-16.375694274999944, 16.785694123000155],
                [-16.375415801999907, 16.785694123000155],
                [-16.375415801999907, 16.786527635000027],
                [-16.375139236999928, 16.786527635000027],
                [-16.375139236999928, 16.787359239000068],
                [-16.374860763999891, 16.787359239000068],
                [-16.374860763999891, 16.787916184999972],
                [-16.374584197999923, 16.787916184999972],
                [-16.374584197999923, 16.788471223000101],
                [-16.374305724999886, 16.788471223000101],
                [-16.374305724999886, 16.789028166999969],
                [-16.374027251999962, 16.789028166999969],
                [-16.374027251999962, 16.789863586000081],
                [-16.373750686999813, 16.789863586000081],
                [-16.373750686999813, 16.790416717000141],
                [-16.373472213999946, 16.790416717000141],
                [-16.373472213999946, 16.790973662999988],
                [-16.373193740999909, 16.790973662999988],
                [-16.373193740999909, 16.79152870199999],
                [-16.372917174999941, 16.79152870199999],
                [-16.372917174999941, 16.792085648000068],
                [-16.372638701999904, 16.792085648000068],
                [-16.372638701999904, 16.792917252000166],
                [-16.37236022899998, 16.792917252000166],
                [-16.37236022899998, 16.793750763000105],
                [-16.372083663999888, 16.793750763000105],
                [-16.372083663999888, 16.794860840000013],
                [-16.371805190999964, 16.794860840000013],
                [-16.371805190999964, 16.795694352000055],
                [-16.371528624999883, 16.795694352000055],
                [-16.371528624999883, 16.796527863000051],
                [-16.371250151999959, 16.796527863000051],
                [-16.371250151999959, 16.797361375000094],
                [-16.370971678999979, 16.797361375000094],
                [-16.370971678999979, 16.797916413000166],
                [-16.370695113999943, 16.797916413000166],
                [-16.370695113999943, 16.798471452000172],
                [-16.370416640999906, 16.798471452000172],
                [-16.370416640999906, 16.799028398000075],
                [-16.370138167999983, 16.799028398000075],
                [-16.370138167999983, 16.799583436000148],
                [-16.369861601999901, 16.799583436000148],
                [-16.369861601999901, 16.800138473000175],
                [-16.369583128999977, 16.800138473000175],
                [-16.369583128999977, 16.800693511000077],
                [-16.369306563999885, 16.800693511000077],
                [-16.369306563999885, 16.801252365000153],
                [-16.369028090999961, 16.801252365000153],
                [-16.369028090999961, 16.801805496000156],
                [-16.368749617999981, 16.801805496000156],
                [-16.368749617999981, 16.802360534000059],
                [-16.368473051999956, 16.802360534000059],
                [-16.368473051999956, 16.802917480000133],
                [-16.368194578999976, 16.802917480000133],
                [-16.368194578999976, 16.803470611000023],
                [-16.367916107999974, 16.803470611000023],
                [-16.367916107999974, 16.804027557000097],
                [-16.367639540999903, 16.804027557000097],
                [-16.367639540999903, 16.804584503000171],
                [-16.367361069999959, 16.804584503000171],
                [-16.367361069999959, 16.805139542000177],
                [-16.367084501999898, 16.805139542000177],
                [-16.367084501999898, 16.805694580000079],
                [-16.366806030999953, 16.805694580000079],
                [-16.366806030999953, 16.805973053000116],
                [-16.366527557999916, 16.805973053000116],
                [-16.366527557999916, 16.806528092000121],
                [-16.366249084999879, 16.806528092000121],
                [-16.366249084999879, 16.807361603000061],
                [-16.365970611999842, 16.807361603000061],
                [-16.365970611999842, 16.808195115000103],
                [-16.365692138999975, 16.808195115000103],
                [-16.365692138999975, 16.809028626000043],
                [-16.365417480999895, 16.809028626000043],
                [-16.365417480999895, 16.80986023000014],
                [-16.365140915999916, 16.80986023000014],
                [-16.365140915999916, 16.810693742000183],
                [-16.364860534999934, 16.810693742000183],
                [-16.364860534999934, 16.811527251000143],
                [-16.364582061999954, 16.811527251000143],
                [-16.364582061999954, 16.812360763000186],
                [-16.364305496999975, 16.812360763000186],
                [-16.364305496999975, 16.813194274000125],
                [-16.364027023999938, 16.813194274000125],
                [-16.364027023999938, 16.814027786000167],
                [-16.363752365999972, 16.814027786000167],
                [-16.363752365999972, 16.814582825000173],
                [-16.363470077999978, 16.814582825000173],
                [-16.363470077999978, 16.815416336000112],
                [-16.363193511999896, 16.815416336000112],
                [-16.363193511999896, 16.816251755000053],
                [-16.362916946999974, 16.816251755000053],
                [-16.362916946999974, 16.817081452000025],
                [-16.362638473999937, 16.817081452000025],
                [-16.362638473999937, 16.817916870999966],
                [-16.362361907999968, 16.817916870999966],
                [-16.362361907999968, 16.81847381700004],
                [-16.362083434999931, 16.81847381700004],
                [-16.362083434999931, 16.819305421000138],
                [-16.361804961999951, 16.819305421000138],
                [-16.361804961999951, 16.820138932000077],
                [-16.361528396999972, 16.820138932000077],
                [-16.361528396999972, 16.820695878000151],
                [-16.361249923999935, 16.820695878000151],
                [-16.361249923999935, 16.821527482000022],
                [-16.360971450999898, 16.821527482000022],
                [-16.360971450999898, 16.822360994000064],
                [-16.36069488499993, 16.822360994000064],
                [-16.36069488499993, 16.82291793800016],
                [-16.360416411999893, 16.82291793800016],
                [-16.360416411999893, 16.82374954200003],
                [-16.360137938999969, 16.82374954200003],
                [-16.360137938999969, 16.824583052999969],
                [-16.35986137399982, 16.824583052999969],
                [-16.35986137399982, 16.825138091999975],
                [-16.359582900999953, 16.825138091999975],
                [-16.359582900999953, 16.825695038000049],
                [-16.359306334999815, 16.825695038000049],
                [-16.359306334999815, 16.82624816900011],
                [-16.359027861999948, 16.82624816900011],
                [-16.359027861999948, 16.826805115000184],
                [-16.358749388999911, 16.826805115000184],
                [-16.358749388999911, 16.82791709900016],
                [-16.358472823999932, 16.82791709900016],
                [-16.358472823999932, 16.829027176000068],
                [-16.358194350999895, 16.829027176000068],
                [-16.358194350999895, 16.829862595000179],
                [-16.357917784999927, 16.829862595000179],
                [-16.357917784999927, 16.830974580000088],
                [-16.35763931199989, 16.830974580000088],
                [-16.35763931199989, 16.831806184000129],
                [-16.357360838999966, 16.831806184000129],
                [-16.357360838999966, 16.832639695000125],
                [-16.357084273999817, 16.832639695000125],
                [-16.357082365999929, 16.833749772000033],
                [-16.35680580099995, 16.833749772000033],
                [-16.35680580099995, 16.83458328200004],
                [-16.356527327999913, 16.83458328200004],
                [-16.356527327999913, 16.835416793000036],
                [-16.356250761999945, 16.835416793000036],
                [-16.356250761999945, 16.836250305000078],
                [-16.355911255999899, 16.836233139000058],
                [-16.355972290999887, 16.837360382000156],
                [-16.355695723999929, 16.837341308000191],
                [-16.355695723999929, 16.838193893000096],
                [-16.355356216999894, 16.838176727000132],
                [-16.355417251999882, 16.839305878],
                [-16.35514068699996, 16.839286804000039],
                [-16.355138778999958, 16.84041595500014],
                [-16.354860305999921, 16.84041595500014],
                [-16.354860305999921, 16.841527939000116],
                [-16.354585648999944, 16.841527939000116],
                [-16.354585648999944, 16.842638016000024],
                [-16.354305267999905, 16.842638016000024],
                [-16.354305267999905, 16.843751908000172],
                [-16.354028701999937, 16.843751908000172],
                [-16.354028701999937, 16.845138551000048],
                [-16.3537502289999, 16.845138551000048],
                [-16.3537502289999, 16.846250534000148],
                [-16.353473663999921, 16.846250534000148],
                [-16.353473663999921, 16.847360611000056],
                [-16.353195190999884, 16.847360611000056],
                [-16.353195190999884, 16.84791755700013],
                [-16.35291671799996, 16.84791755700013],
                [-16.35291671799996, 16.848472595000032],
                [-16.352638244999923, 16.848472595000032],
                [-16.352638244999923, 16.849306107000075],
                [-16.352363586999843, 16.849306107000075],
                [-16.352363586999843, 16.849861145000034],
                [-16.352081298999849, 16.849861145000034],
                [-16.352081298999849, 16.850416184000039],
                [-16.351804732999881, 16.850416184000039],
                [-16.351804732999881, 16.850973130000114],
                [-16.351528167999902, 16.850973130000114],
                [-16.351528167999902, 16.851804734000154],
                [-16.351249694999979, 16.851804734000154],
                [-16.351249694999979, 16.852638245000094],
                [-16.350971221999941, 16.852638245000094],
                [-16.350971221999941, 16.853471756999966],
                [-16.350694655999973, 16.853471756999966],
                [-16.350694655999973, 16.85402870300004],
                [-16.350416182999936, 16.85402870300004],
                [-16.350416182999936, 16.854860307000138],
                [-16.350141524999913, 16.854860307000138],
                [-16.350141524999913, 16.855417252999985],
                [-16.349859236999919, 16.855417252999985],
                [-16.349859236999919, 16.855970384000045],
                [-16.34958267199994, 16.855970384000045],
                [-16.34958267199994, 16.856527330000119],
                [-16.349306105999972, 16.856527330000119],
                [-16.349306105999972, 16.857082368000022],
                [-16.349027632999935, 16.857082368000022],
                [-16.349027632999935, 16.857639312000117],
                [-16.348749159999898, 16.857639312000117],
                [-16.348749159999898, 16.858192443000121],
                [-16.348472594999976, 16.858192443000121],
                [-16.348472594999976, 16.858749389000025],
                [-16.348194121999938, 16.858749389000025],
                [-16.348194121999938, 16.859306335000099],
                [-16.34791755599997, 16.859306335000099],
                [-16.34791755599997, 16.859861374000104],
                [-16.347637175999921, 16.859861374000104],
                [-16.347637175999921, 16.860416412],
                [-16.347360609999953, 16.860416412],
                [-16.347360609999953, 16.860971451000012],
                [-16.347084044999974, 16.860971451000012],
                [-16.347084044999974, 16.861528397000086],
                [-16.346805571999937, 16.861528397000086],
                [-16.346805571999937, 16.862083434999988],
                [-16.3465270989999, 16.862083434999988],
                [-16.3465270989999, 16.86263847399999],
                [-16.346250532999932, 16.86263847399999],
                [-16.346250532999932, 16.863193512000123],
                [-16.345972059999895, 16.863193512000123],
                [-16.345972059999895, 16.863750458000027],
                [-16.345693586999971, 16.863750458000027],
                [-16.345693586999971, 16.864305497000032],
                [-16.345415115999913, 16.864305497000032],
                [-16.345415115999913, 16.864860535000105],
                [-16.345138548999955, 16.864860535000105],
                [-16.345138548999955, 16.865694047000147],
                [-16.344861982999817, 16.865694047000147],
                [-16.344861982999817, 16.866527558000087],
                [-16.344583511999872, 16.866527558000087],
                [-16.344583511999872, 16.867082597000092],
                [-16.344306944999971, 16.867082597000092],
                [-16.344306944999971, 16.867916108000088],
                [-16.34402847399997, 16.867916108000088],
                [-16.34402847399997, 16.868471147000093],
                [-16.343750000999933, 16.868471147000093],
                [-16.343750000999933, 16.869028091000132],
                [-16.343471527999952, 16.869028091000132],
                [-16.343471527999952, 16.869581221999965],
                [-16.343196869999872, 16.869581221999965],
                [-16.343196869999872, 16.87013816800004],
                [-16.342916488999947, 16.87013816800004],
                [-16.342916488999947, 16.870693206000169],
                [-16.34263801599991, 16.870693206000169],
                [-16.34263801599991, 16.871250152000073],
                [-16.342361450999931, 16.871250152000073],
                [-16.342361450999931, 16.87152862500011],
                [-16.342084884999963, 16.87152862500011],
                [-16.342084884999963, 16.872085571000184],
                [-16.341806411999926, 16.872085571000184],
                [-16.341806411999926, 16.872638702000188],
                [-16.341527938999889, 16.872638702000188],
                [-16.341527938999889, 16.873195648000092],
                [-16.341249465999965, 16.873195648000092],
                [-16.341249465999965, 16.873750687000097],
                [-16.340974807999885, 16.873750687000097],
                [-16.340974807999885, 16.874305725],
                [-16.340694427999949, 16.874305725],
                [-16.340694427999949, 16.875139237000042],
                [-16.340414046999854, 16.875139237000042],
                [-16.340414046999854, 16.875972747999981],
                [-16.340139388999944, 16.875972747999981],
                [-16.340139388999944, 16.877082825000116],
                [-16.339862823999965, 16.877082825000116],
                [-16.339862823999965, 16.877916337000158],
                [-16.339584350999928, 16.877916337000158],
                [-16.339584350999928, 16.878749848000098],
                [-16.339305877999891, 16.878749848000098],
                [-16.339305877999891, 16.879583358000161],
                [-16.339027404999968, 16.879583358000161],
                [-16.339027404999968, 16.880416870000033],
                [-16.338752746999887, 16.880416870000033],
                [-16.338752746999887, 16.880971908000106],
                [-16.338470458999893, 16.880971908000106],
                [-16.338470458999893, 16.881805420000148],
                [-16.338193892999925, 16.881805420000148],
                [-16.338193892999925, 16.882360458000051],
                [-16.337917327999946, 16.882360458000051],
                [-16.337917327999946, 16.883192062000148],
                [-16.337638854999909, 16.883192062000148],
                [-16.337638854999909, 16.883752823000066],
                [-16.337360381999872, 16.883752823000066],
                [-16.337360381999872, 16.884584427000107],
                [-16.337083815999904, 16.884584427000107],
                [-16.337083815999904, 16.885139466000112],
                [-16.33680534299998, 16.885139466000112],
                [-16.33680534299998, 16.885692597000173],
                [-16.336526869999943, 16.885692597000173],
                [-16.336526869999943, 16.886528016000113],
                [-16.336248396999963, 16.886528016000113],
                [-16.336248396999963, 16.887083054000186],
                [-16.335971831999927, 16.887083054000186],
                [-16.335971831999927, 16.887916566000058],
                [-16.335695265999959, 16.887916566000058],
                [-16.335695265999959, 16.888471604000131],
                [-16.335416792999979, 16.888471604000131],
                [-16.335416792999979, 16.889028550000035],
                [-16.335138319999942, 16.889028550000035],
                [-16.335138319999942, 16.889860154000132],
                [-16.334861754999906, 16.889862062000077],
                [-16.334861754999906, 16.890417099999979],
                [-16.334583281999869, 16.890417099999979],
                [-16.334583281999869, 16.891250610000043],
                [-16.334304808999946, 16.891250610000043],
                [-16.334304808999946, 16.891805648000116],
                [-16.334028242999977, 16.891805648000116],
                [-16.334028242999977, 16.892639159999987],
                [-16.3337497699999, 16.892639159999987],
                [-16.3337497699999, 16.893194198000117],
                [-16.333471298999882, 16.893194198000117],
                [-16.333471298999882, 16.893749237000122],
                [-16.333194733999903, 16.893749237000122],
                [-16.333194733999903, 16.894582748000062],
                [-16.33291626099998, 16.894582748000062],
                [-16.33291626099998, 16.895141602000137],
                [-16.332639694999898, 16.895141602000137],
                [-16.332639694999898, 16.895971298],
                [-16.332361221999975, 16.895971298],
                [-16.332361221999975, 16.896528244000081],
                [-16.332082748999937, 16.896528244000081],
                [-16.332082748999937, 16.897363663000021],
                [-16.331806183999959, 16.897363663000021],
                [-16.331806183999959, 16.897916794000025],
                [-16.331527710999922, 16.897916794000025],
                [-16.331527710999922, 16.898473740000099],
                [-16.331249237999884, 16.898473740000099],
                [-16.331249237999884, 16.89902687100016],
                [-16.330972671999916, 16.89902687100016],
                [-16.330972671999916, 16.899585725],
                [-16.330694198999879, 16.899585725],
                [-16.330694198999879, 16.900138856000069],
                [-16.330415725999842, 16.900138856000069],
                [-16.330415725999842, 16.900695802000143],
                [-16.330139160999977, 16.900695802000143],
                [-16.330139160999977, 16.901250840000046],
                [-16.32986068799994, 16.901250840000046],
                [-16.32986068799994, 16.901807784000141],
                [-16.329584121999972, 16.901807784000141],
                [-16.329584121999972, 16.902360917000124],
                [-16.329305648999934, 16.902360917000124],
                [-16.329305648999934, 16.902917861000049],
                [-16.329027175999954, 16.902917861000049],
                [-16.329027175999954, 16.903472900000054],
                [-16.328750610999975, 16.903472900000054],
                [-16.328750610999975, 16.904027938000127],
                [-16.328472137999938, 16.904027938000127],
                [-16.328472137999938, 16.904582977000132],
                [-16.32819557199997, 16.904582977000132],
                [-16.32819557199997, 16.905139923000036],
                [-16.327917098999933, 16.905139923000036],
                [-16.327917098999933, 16.905694961000108],
                [-16.327638625999896, 16.905694961000108],
                [-16.327638625999896, 16.906248092000169],
                [-16.327362060999974, 16.906248092000169],
                [-16.327360152999972, 16.906805038000073],
                [-16.327083587999937, 16.906805038000073],
                [-16.327083587999937, 16.907360077000078],
                [-16.326803206999898, 16.907360077000078],
                [-16.326803206999898, 16.907917023000152],
                [-16.326528548999931, 16.907917023000152],
                [-16.326528548999931, 16.908752442000093],
                [-16.326250075999951, 16.908752442000093],
                [-16.326250075999951, 16.909305573000097],
                [-16.325973510999972, 16.909305573000097],
                [-16.325973510999972, 16.910139084000036],
                [-16.325695037999935, 16.910139084000036],
                [-16.325695037999935, 16.910692215000097],
                [-16.325416564999898, 16.910692215000097],
                [-16.325416564999898, 16.911527633999981],
                [-16.325138091999975, 16.911527633999981],
                [-16.325138091999975, 16.912084581000158],
                [-16.324863433999894, 16.912084581000158],
                [-16.324863433999894, 16.912639619000061],
                [-16.324581144999968, 16.912639619000061],
                [-16.324581144999968, 16.913194658000066],
                [-16.32430648799982, 16.913194658000066],
                [-16.324304579999932, 16.913473129000124],
                [-16.324028014999953, 16.913473129000124],
                [-16.324028014999953, 16.914028167000026],
                [-16.323749541999916, 16.914028167000026],
                [-16.323749541999916, 16.914306640000063],
                [-16.32341766299993, 16.914283754000166],
                [-16.32347106799989, 16.914861679000069],
                [-16.323194502999911, 16.914878846000022],
                [-16.323194502999911, 16.915416717000028],
                [-16.32347106799989, 16.915416717000028],
                [-16.32347106799989, 16.915971756000033],
                [-16.323749541999916, 16.915971756000033],
                [-16.323749541999916, 16.91708374000018],
                [-16.32347106799989, 16.91708374000018],
                [-16.32347106799989, 16.917638779000185],
                [-16.323194502999911, 16.917638779000185],
                [-16.323194502999911, 16.918193817000088],
                [-16.322916029999874, 16.918193817000088],
                [-16.322916029999874, 16.918750763000162],
                [-16.322639464999895, 16.918750763000162],
                [-16.322639464999895, 16.91902732900013],
                [-16.322359085999892, 16.91902732900013],
                [-16.322359085999892, 16.919305802000167],
                [-16.322082519999924, 16.919305802000167],
                [-16.322082519999924, 16.919860840000069],
                [-16.321805952999966, 16.919860840000069],
                [-16.321805952999966, 16.920139313000107],
                [-16.321527481999908, 16.920139313000107],
                [-16.321527481999908, 16.920415879000075],
                [-16.32125091599994, 16.920415879000075],
                [-16.32125091599994, 16.920972824999978],
                [-16.320911407999915, 16.92094993600017],
                [-16.320972442999903, 16.921249390000071],
                [-16.320785521999881, 16.921249390000071],
                [-16.320693969999979, 16.921249390000071],
                [-16.320693969999979, 16.921527863000108],
                [-16.320417404999887, 16.921527863000108],
                [-16.320417404999887, 16.922082902000113],
                [-16.320138931999963, 16.922082902000113],
                [-16.320138931999963, 16.922363282000049],
                [-16.319860458999926, 16.922363282000049],
                [-16.319860458999926, 16.922916413000053],
                [-16.319583892999958, 16.922916413000053],
                [-16.319583892999958, 16.923749925000095],
                [-16.319305419999921, 16.923749925000095],
                [-16.319305419999921, 16.924581528999965],
                [-16.319028854999942, 16.924581528999965],
                [-16.319093702999965, 16.925441742000032],
                [-16.318750381999905, 16.925416946000098],
                [-16.318782805999945, 16.926263809999966],
                [-16.318471908999982, 16.926250457000037],
                [-16.318471908999982, 16.927085876000149],
                [-16.3181953429999, 16.927085876000149],
                [-16.3181953429999, 16.927646637000123],
                [-16.3181953429999, 16.928195953000056],
                [-16.317916869999976, 16.928195953000056],
                [-16.317916869999976, 16.928375244000051],
                [-16.317916869999976, 16.929307937999965],
                [-16.317571638999823, 16.929281234000086],
                [-16.317638396999939, 16.930416107000099],
                [-16.31736183199996, 16.930416107000099],
                [-16.31736183199996, 16.931528092000178],
                [-16.317083358999923, 16.931528092000178],
                [-16.317083358999923, 16.93319511500016],
                [-16.316806792999955, 16.93319511500016],
                [-16.316806792999955, 16.934583665000105],
                [-16.316528319999918, 16.934583665000105],
                [-16.316528319999918, 16.935138703000064],
                [-16.316249846999881, 16.935138703000064],
                [-16.316249846999881, 16.935693742000069],
                [-16.315971373999844, 16.935693742000069],
                [-16.315971373999844, 16.936252593],
                [-16.315694808999979, 16.936252593],
                [-16.315694808999979, 16.936805724000067],
                [-16.315416335999942, 16.936805724000067],
                [-16.315416335999942, 16.937639236000109],
                [-16.315139769999973, 16.937639236000109],
                [-16.315139769999973, 16.938749313000187],
                [-16.314861296999936, 16.938749313000187],
                [-16.314861296999936, 16.939861297000164],
                [-16.314584731999958, 16.939861297000164],
                [-16.314584731999958, 16.940416336000169],
                [-16.314306258999977, 16.940416336000169],
                [-16.314306258999977, 16.940696716000105],
                [-16.31402778599994, 16.940696716000105],
                [-16.31402778599994, 16.940971374000071],
                [-16.313749312999903, 16.940971374000071],
                [-16.313749312999903, 16.941249847000108],
                [-16.313474654999936, 16.941249847000108],
                [-16.313474654999936, 16.941528319999975],
                [-16.313192366999942, 16.941528319999975],
                [-16.313192366999942, 16.941806793000012],
                [-16.312915800999974, 16.941806793000012],
                [-16.312915800999974, 16.942361832000017],
                [-16.312593460999949, 16.942344666000054],
                [-16.312639235999939, 16.942916870000147],
                [-16.31236267, 16.942951202000131],
                [-16.31236267, 16.943471909000152],
                [-16.312084196999933, 16.943498613000031],
                [-16.312084196999933, 16.94367790300015],
                [-16.312084196999933, 16.944028855000056],
                [-16.311950682999964, 16.944028855000056],
                [-16.311805723999953, 16.944028855000056],
                [-16.311805723999953, 16.94440841800008],
                [-16.311805723999953, 16.944583894000061],
                [-16.311527250999916, 16.944583894000061],
                [-16.311527250999916, 16.945138932000134],
                [-16.311252592999949, 16.945138932000134],
                [-16.311252592999949, 16.945762634000062],
                [-16.311252592999949, 16.946250917000043],
                [-16.311052321999966, 16.946250917000043],
                [-16.310970306999934, 16.946250917000043],
                [-16.310970306999934, 16.946489335000024],
                [-16.310970306999934, 16.947082521000084],
                [-16.310693741999955, 16.947082521000084],
                [-16.310693741999955, 16.947219849000078],
                [-16.310693741999955, 16.948194503000082],
                [-16.310419081999896, 16.948169709000183],
                [-16.310417173999895, 16.949028015000124],
                [-16.31013870299995, 16.949028015000124],
                [-16.31013870299995, 16.950138092000032],
                [-16.309858322999958, 16.950138092000032],
                [-16.309858322999958, 16.950971603000028],
                [-16.309583664999877, 16.950971603000028],
                [-16.309583664999877, 16.951528549000102],
                [-16.30930519199984, 16.951528549000102],
                [-16.30930519199984, 16.952085495000176],
                [-16.309028625999872, 16.952085495000176],
                [-16.309028625999872, 16.952917099000047],
                [-16.308748245999936, 16.952917099000047],
                [-16.308748245999936, 16.953472138000052],
                [-16.308471679999968, 16.953472138000052],
                [-16.308471679999968, 16.954027176000125],
                [-16.308195114999933, 16.954027176000125],
                [-16.308195114999933, 16.954584122000028],
                [-16.307916641999952, 16.954584122000028],
                [-16.307916641999952, 16.955139161000034],
                [-16.307638168999915, 16.955139161000034],
                [-16.307638168999915, 16.955694199000106],
                [-16.307361602999947, 16.955694199000106],
                [-16.307361602999947, 16.956249238000112],
                [-16.3070831299999, 16.956249238000112],
                [-16.3070831299999, 16.956806184000015],
                [-16.306804656999873, 16.956806184000015],
                [-16.306804656999873, 16.957082749000108],
                [-16.306526183999949, 16.957082749000108],
                [-16.306526183999949, 16.957639695000182],
                [-16.306249618999971, 16.957639695000182],
                [-16.306249618999971, 16.958194734000188],
                [-16.305973052999889, 16.958194734000188],
                [-16.305973052999889, 16.95874977200009],
                [-16.305694579999965, 16.95874977200009],
                [-16.305694579999965, 16.959028245000127],
                [-16.305416106999928, 16.959083557999975],
                [-16.305416106999928, 16.959583282000153],
                [-16.305139541999949, 16.959583282000153],
                [-16.305139541999949, 16.95965767000007],
                [-16.305139541999949, 16.960138320000056],
                [-16.304861068999912, 16.960138320000056],
                [-16.304861068999912, 16.960237502000098],
                [-16.304861068999912, 16.96069526600013],
                [-16.304582595999875, 16.96069526600013],
                [-16.304582595999875, 16.96081733699998],
                [-16.304582595999875, 16.960971832000098],
                [-16.304307937999965, 16.960971832000098],
                [-16.304307937999965, 16.961393356000144],
                [-16.304307937999965, 16.964862823999965],
                [-16.30402755699987, 16.964862823999965],
                [-16.30402755699987, 16.965414047000024],
                [-16.303749083999946, 16.965414047000024],
                [-16.303749083999946, 16.965974808000169],
                [-16.303472518999968, 16.965974808000169],
                [-16.303472518999968, 16.966527939000173],
                [-16.303195952999886, 16.966527939000173],
                [-16.303195952999886, 16.967084885000077],
                [-16.302917479999962, 16.967084885000077],
                [-16.302917479999962, 16.96763801600008],
                [-16.302639006999925, 16.96763801600008],
                [-16.302639006999925, 16.968196870000156],
                [-16.302360533999888, 16.968196870000156],
                [-16.302360533999888, 16.96875000100016],
                [-16.302085875999978, 16.96875000100016],
                [-16.302085875999978, 16.969306947000064],
                [-16.301805495999872, 16.969306947000064],
                [-16.301805495999872, 16.969861984999966],
                [-16.301527022999949, 16.969861984999966],
                [-16.301527022999949, 16.970418929000061],
                [-16.301250456999981, 16.970418929000061],
                [-16.301250456999981, 16.970972060000065],
                [-16.300973891999888, 16.970972060000065],
                [-16.300973891999888, 16.971250533000102],
                [-16.30069160599993, 16.971250533000102],
                [-16.30069160599993, 16.971805572000108],
                [-16.300416945999928, 16.971805572000108],
                [-16.300416945999928, 16.972360610000067],
                [-16.300138472999947, 16.972360610000067],
                [-16.300138472999947, 16.972917556000141],
                [-16.299863814999981, 16.972917556000141],
                [-16.299863814999981, 16.973472595000146],
                [-16.299581528999965, 16.973472595000146],
                [-16.299581528999965, 16.974027633000048],
                [-16.299303055999928, 16.974027633000048],
                [-16.299303055999928, 16.974582672000054],
                [-16.299028395999869, 16.974582672000054],
                [-16.299028395999869, 16.975139618000128],
                [-16.298751829999901, 16.975139618000128],
                [-16.298751829999901, 16.975971222000169],
                [-16.298473358999956, 16.975971222000169],
                [-16.298473358999956, 16.976528168000073],
                [-16.298194885999919, 16.976528168000073],
                [-16.298194885999919, 16.977363587000184],
                [-16.297916412999882, 16.977363587000184],
                [-16.297916412999882, 16.977916718000017],
                [-16.297637939999845, 16.977916718000017],
                [-16.297637939999845, 16.97875023000006],
                [-16.297359466999978, 16.97875023000006],
                [-16.297359466999978, 16.979303360000188],
                [-16.297082901999943, 16.979303360000188],
                [-16.297082901999943, 16.980138780000061],
                [-16.296806335999975, 16.980138780000061],
                [-16.296806335999975, 16.980695726000135],
                [-16.296527862999937, 16.980695726000135],
                [-16.296527862999937, 16.981527330000176],
                [-16.296249389999957, 16.981527330000176],
                [-16.296249389999957, 16.982082368000079],
                [-16.295972824999922, 16.982084274000044],
                [-16.295972824999922, 16.982917785000041],
                [-16.295694351999884, 16.982917785000041],
                [-16.295694351999884, 16.983472824000046],
                [-16.295415878999847, 16.983472824000046],
                [-16.295415878999847, 16.984306335000156],
                [-16.295139312999879, 16.984306335000156],
                [-16.295139312999879, 16.984861374000161],
                [-16.294860839999842, 16.984861374000161],
                [-16.294860839999842, 16.9856948850001],
                [-16.294584274999977, 16.9856948850001],
                [-16.294582366999975, 16.986249924000106],
                [-16.29430580199994, 16.986249924000106],
                [-16.29430580199994, 16.986804962000065],
                [-16.29402732899996, 16.986804962000065],
                [-16.29402732899996, 16.987361908000139],
                [-16.293750762999935, 16.987361908000139],
                [-16.293750762999935, 16.987916947000144],
                [-16.293472289999954, 16.987916947000144],
                [-16.293472289999954, 16.988470078000148],
                [-16.293193816999917, 16.988470078000148],
                [-16.293193816999917, 16.989027024000052],
                [-16.292917251999938, 16.989027024000052],
                [-16.292917251999938, 16.989582062000125],
                [-16.292638778999901, 16.989582062000125],
                [-16.292638778999901, 16.990139008000028],
                [-16.292360305999978, 16.990139008000028],
                [-16.292360305999978, 16.990692139000032],
                [-16.292083739999896, 16.990692139000032],
                [-16.292083739999896, 16.991249085000106],
                [-16.291805266999972, 16.991249085000106],
                [-16.291805266999972, 16.991804124000112],
                [-16.291528701999937, 16.991804124000112],
                [-16.291528701999937, 16.992639543000053],
                [-16.291250228999957, 16.992639543000053],
                [-16.291250228999957, 16.993192674000113],
                [-16.290971755999919, 16.993192674000113],
                [-16.290971755999919, 16.994028091000018],
                [-16.290695189999951, 16.994028091000018],
                [-16.290695189999951, 16.994861602000185],
                [-16.290416716999914, 16.994861602000185],
                [-16.290416716999914, 16.99541664100019],
                [-16.290138243999877, 16.99541664100019],
                [-16.290138243999877, 16.99625015200013],
                [-16.289861678999898, 16.99625015200013],
                [-16.289861678999898, 16.997083664000172],
                [-16.289583204999872, 16.997083664000172],
                [-16.289583204999872, 16.997638702000074],
                [-16.289306639999893, 16.997638702000074],
                [-16.289306639999893, 16.998472214000117],
                [-16.289028166999969, 16.998472214000117],
                [-16.289028166999969, 16.999027252000019],
                [-16.288749693999932, 16.999027252000019],
                [-16.288749693999932, 16.999584198000093],
                [-16.288473127999964, 16.999584198000093],
                [-16.288473127999964, 16.999860764000061],
                [-16.288473127999964, 17.00017356900014],
                [-16.288473127999964, 17.000972748000095],
                [-16.288194654999927, 17.000972748000095],
                [-16.288194654999927, 17.002082825000173],
                [-16.287916183999982, 17.002082825000173],
                [-16.287916183999982, 17.002359391000141],
                [-16.287639616999911, 17.002359391000141],
                [-16.287639616999911, 17.002916337000045],
                [-16.287361145999967, 17.002916337000045],
                [-16.287361145999967, 17.003194810000082],
                [-16.287084577999906, 17.003194810000082],
                [-16.287084577999906, 17.003471375000117],
                [-16.286806106999961, 17.003471375000117],
                [-16.286806106999961, 17.004028321000192],
                [-16.286527633999924, 17.004028321000192],
                [-16.286527633999924, 17.00430488700016],
                [-16.286249160999887, 17.00430488700016],
                [-16.286249160999887, 17.004863739000029],
                [-16.285970687999964, 17.004863739000029],
                [-16.285970687999964, 17.006526946000065],
                [-16.285692214999926, 17.006526946000065],
                [-16.285692214999926, 17.006803512000033],
                [-16.285417556999903, 17.006803512000033],
                [-16.285417556999903, 17.007360458000107],
                [-16.285139083999979, 17.007360458000107],
                [-16.285139083999979, 17.008472443000016],
                [-16.284862518999887, 17.008472443000016],
                [-16.284862518999887, 17.008750916000054],
                [-16.284582137999962, 17.008750916000054],
                [-16.284582137999962, 17.009027481000146],
                [-16.284305572999926, 17.009027481000146],
                [-16.284305572999926, 17.010139466],
                [-16.284027099999889, 17.010139466],
                [-16.284027099999889, 17.011249543000133],
                [-16.28375244199998, 17.011249543000133],
                [-16.28375244199998, 17.011804581000035],
                [-16.283470153999815, 17.011806489000037],
                [-16.283470153999815, 17.012638093000078],
                [-16.281805037999959, 17.012638093000078],
                [-16.281805037999959, 17.012916566000115],
                [-16.281528472999923, 17.012916566000115],
                [-16.281528472999923, 17.014583589000097],
                [-16.281248091999885, 17.014583589000097],
                [-16.281248091999885, 17.014860154000189],
                [-16.280971526999849, 17.014860154000189],
                [-16.280971526999849, 17.015138627000056],
                [-16.280694960999881, 17.015138627000056],
                [-16.280694960999881, 17.015693666000061],
                [-16.280416487999844, 17.015693666000061],
                [-16.280416487999844, 17.0162506100001],
                [-16.280136107999908, 17.0162506100001],
                [-16.280136107999908, 17.017360687],
                [-16.279861449999942, 17.017360687],
                [-16.279861449999942, 17.017915725000137],
                [-16.279582976999961, 17.017915725000137],
                [-16.279582976999961, 17.018749237000179],
                [-16.279306410999936, 17.018749237000179],
                [-16.279306410999936, 17.019306183000083],
                [-16.279027937999956, 17.019306183000083],
                [-16.279027937999956, 17.020414351999989],
                [-16.278749464999919, 17.020414351999989],
                [-16.278749464999919, 17.020971298000063],
                [-16.2784729, 17.020971298000063],
                [-16.2784729, 17.021528243999967],
                [-16.278194426999903, 17.021528243999967],
                [-16.278194426999903, 17.022083282999972],
                [-16.277917860999935, 17.022083282999972],
                [-16.277917860999935, 17.023195267000176],
                [-16.277639387999898, 17.023195267000176],
                [-16.277639387999898, 17.023471833000144],
                [-16.277360914999974, 17.023471833000144],
                [-16.277360914999974, 17.02402877899999],
                [-16.277082441999937, 17.02402877899999],
                [-16.277082441999937, 17.024305344000084],
                [-16.276805876999958, 17.024305344000084],
                [-16.276805876999958, 17.024583817000121],
                [-16.276460647999897, 17.024591445000056],
                [-16.276527405999843, 17.025138856000126],
                [-16.276233671999876, 17.025131226000042],
                [-16.276250837999953, 17.025972367000065],
                [-16.275911331999851, 17.025949479000133],
                [-16.275972366999838, 17.026527406000071],
                [-16.275686263999944, 17.026523591],
                [-16.275695799999937, 17.026805879000108],
                [-16.275575638999953, 17.026805879000108],
                [-16.275417328999936, 17.026805879000108],
                [-16.275417328999936, 17.027206420000141],
                [-16.275417328999936, 17.02736091700001],
                [-16.275138855999955, 17.02736091700001],
                [-16.275138855999955, 17.027915956000015],
                [-16.274860382999918, 17.027915956000015],
                [-16.274860382999918, 17.028408051000156],
                [-16.274860382999918, 17.028749464999976],
                [-16.274723053999935, 17.028749464999976],
                [-16.274585724999952, 17.028749464999976],
                [-16.274585724999952, 17.029108046999966],
                [-16.274585724999952, 17.029581069000074],
                [-16.274383544999921, 17.029581069000074],
                [-16.274305343999913, 17.029581069000074],
                [-16.274305343999913, 17.029811860000052],
                [-16.274305343999913, 17.030416488000185],
                [-16.274061202999974, 17.030429841000114],
                [-16.274026870999876, 17.031528473000037],
                [-16.27375030599984, 17.031528473000037],
                [-16.27375030599984, 17.032085419000111],
                [-16.273416519999842, 17.032060623000064],
                [-16.273473739999872, 17.032363891999978],
                [-16.273284911999951, 17.032363891999978],
                [-16.273195266999835, 17.032363891999978],
                [-16.273195266999835, 17.032617569000138],
                [-16.273195266999835, 17.032917023000039],
                [-16.273075103999929, 17.032917023000039],
                [-16.272916793999968, 17.032917023000039],
                [-16.272916793999968, 17.033321382000167],
                [-16.272916793999968, 17.033750534000148],
                [-16.272748946999855, 17.033750534000148],
                [-16.272638320999931, 17.033750534000148],
                [-16.272638320999931, 17.034025192000115],
                [-16.272363662999965, 17.034025192000115],
                [-16.272363662999965, 17.034585953000089],
                [-16.272081374999971, 17.034585953000089],
                [-16.272123336999869, 17.035154343000045],
                [-16.271804809999878, 17.035139084000093],
                [-16.271802901999934, 17.035720824000123],
                [-16.27152824399991, 17.035694123000098],
                [-16.27152824399991, 17.036249161000057],
                [-16.271251678999931, 17.036249161000057],
                [-16.271251678999931, 17.036527634000095],
                [-16.270969390999937, 17.036527634000095],
                [-16.270969390999937, 17.037361146000137],
                [-16.270694732999971, 17.037361146000137],
                [-16.270694732999971, 17.038749696000082],
                [-16.270416259999934, 17.038749696000082],
                [-16.27043914799998, 17.039314270000091],
                [-16.270141601999967, 17.039304734000154],
                [-16.270210264999946, 17.03989029000013],
                [-16.269859313999859, 17.039861679000126],
                [-16.269859313999859, 17.040416717000085],
                [-16.269582747999891, 17.040416717000085],
                [-16.269657133999829, 17.041278840000075],
                [-16.269306182999912, 17.041250229000127],
                [-16.269306182999912, 17.041805267000029],
                [-16.269027709999875, 17.041805267000029],
                [-16.269027709999875, 17.042360306000035],
                [-16.268749236999952, 17.042360306000035],
                [-16.268749236999952, 17.042917252000109],
                [-16.26847267099987, 17.042917252000109],
                [-16.26847267099987, 17.043470382000066],
                [-16.268194197999946, 17.043470382000066],
                [-16.268194197999946, 17.044027329000016],
                [-16.267915724999966, 17.044027329000016],
                [-16.267915724999966, 17.044860840000183],
                [-16.267637251999929, 17.044860840000183],
                [-16.267637251999929, 17.045415879000188],
                [-16.267360686999893, 17.045415879000188],
                [-16.267360686999893, 17.045974732000104],
                [-16.267084120999925, 17.045974732000104],
                [-16.267084120999925, 17.046249390000128],
                [-16.266805647999888, 17.046249390000128],
                [-16.266805647999888, 17.046527863000165],
                [-16.266527174999851, 17.046527863000165],
                [-16.266527174999851, 17.048471452000115],
                [-16.266250609999872, 17.048471452000115],
                [-16.266250609999872, 17.049028398000189],
                [-16.265972136999949, 17.049028398000189],
                [-16.265972136999949, 17.049306871000056],
                [-16.265693663999969, 17.049306871000056],
                [-16.265693663999969, 17.049861909000128],
                [-16.265417097999944, 17.049861909000128],
                [-16.265417097999944, 17.050138475000097],
                [-16.265138626999885, 17.050138475000097],
                [-16.265138626999885, 17.050693513000056],
                [-16.264862059999928, 17.050693513000056],
                [-16.264860153999962, 17.052083969000137],
                [-16.264583588999869, 17.052083969000137],
                [-16.264583588999869, 17.052360534],
                [-16.264305115999946, 17.052360534],
                [-16.264305115999946, 17.0526371],
                [-16.264028549999978, 17.0526371],
                [-16.264028549999978, 17.052917480000076],
                [-16.263750076999941, 17.052917480000076],
                [-16.263750076999941, 17.053472519000081],
                [-16.26347160399996, 17.053472519000081],
                [-16.26347160399996, 17.054306030000077],
                [-16.26319694599988, 17.054306030000077],
                [-16.26319694599988, 17.05513954200012],
                [-16.262916565999888, 17.05513954200012],
                [-16.262916565999888, 17.055416107000156],
                [-16.26263809299985, 17.055416107000156],
                [-16.26263809299985, 17.055971146000161],
                [-16.262361526999882, 17.055971146000161],
                [-16.262361526999882, 17.056249619000027],
                [-16.262083053999845, 17.056249619000027],
                [-16.262083053999845, 17.056528092000065],
                [-16.26180648899998, 17.056528092000065],
                [-16.261804580999978, 17.057081223000068],
                [-16.261528015999943, 17.057081223000068],
                [-16.261528015999943, 17.057638168999972],
                [-16.261249542999963, 17.057638168999972],
                [-16.261249542999963, 17.058193207000102],
                [-16.260974884999882, 17.058193207000102],
                [-16.260974884999882, 17.058750153000176],
                [-16.260694503999957, 17.058750153000176],
                [-16.260694503999957, 17.05930328400018],
                [-16.26041603099992, 17.05930328400018],
                [-16.26041603099992, 17.059860230000083],
                [-16.260139465999885, 17.059860230000083],
                [-16.260139465999885, 17.060695649000024],
                [-16.259862899999916, 17.060695649000024],
                [-16.259862899999916, 17.060972215000163],
                [-16.259584426999879, 17.060972215000163],
                [-16.259584426999879, 17.061527253000065],
                [-16.259305953999842, 17.061527253000065],
                [-16.259305953999842, 17.062084197000161],
                [-16.259027480999976, 17.062084197000161],
                [-16.259027480999976, 17.063194274000068],
                [-16.258752822999895, 17.063194274000068],
                [-16.258752822999895, 17.063472747000105],
                [-16.258470534999901, 17.063472747000105],
                [-16.258470534999901, 17.064306259000148],
                [-16.258192061999978, 17.064306259000148],
                [-16.258192061999978, 17.065139770000087],
                [-16.257917403999954, 17.065139770000087],
                [-16.257917403999954, 17.065694809000092],
                [-16.257640838999976, 17.065694809000092],
                [-16.257640838999976, 17.066249847],
                [-16.25736045799988, 17.066249847],
                [-16.25736045799988, 17.067083359000037],
                [-16.257083891999912, 17.067083359000037],
                [-16.257083891999912, 17.067638397000167],
                [-16.256805418999875, 17.067638397000167],
                [-16.256805418999875, 17.068471909000039],
                [-16.256526945999951, 17.068471909000039],
                [-16.256526945999951, 17.069305420000148],
                [-16.256248472999971, 17.069305420000148],
                [-16.256248472999971, 17.069860459000154],
                [-16.255971907999935, 17.069860459000154],
                [-16.255971907999935, 17.070417405000057],
                [-16.255695341999967, 17.070417405000057],
                [-16.255695341999967, 17.070974351000132],
                [-16.25541686899993, 17.070974351000132],
                [-16.25541686899993, 17.071527481999965],
                [-16.255138395999893, 17.071527481999965],
                [-16.255138395999893, 17.072082520000095],
                [-16.254861830999914, 17.072082520000095],
                [-16.254861830999914, 17.072639466000169],
                [-16.254583357999877, 17.072639466000169],
                [-16.254583357999877, 17.073474884000177],
                [-16.254304884999954, 17.073474884000177],
                [-16.254304884999954, 17.073749542000144],
                [-16.254028318999872, 17.073749542000144],
                [-16.254028318999872, 17.074583053000083],
                [-16.253749845999948, 17.074583053000083],
                [-16.253749845999948, 17.07486152600012],
                [-16.25347137499989, 17.07486152600012],
                [-16.25347137499989, 17.075971603000085],
                [-16.253194809999911, 17.075971603000085],
                [-16.253194809999911, 17.076528549000159],
                [-16.252916336999874, 17.076528549000159],
                [-16.252916336999874, 17.077083588000164],
                [-16.252639770999906, 17.077083588000164],
                [-16.252639770999906, 17.077360153000029],
                [-16.252361297999983, 17.077362061000031],
                [-16.252361297999983, 17.077917099000103],
                [-16.252082824999945, 17.077917099000103],
                [-16.252082824999945, 17.078472138000109],
                [-16.251806259999967, 17.078472138000109],
                [-16.251806259999967, 17.078750611000146],
                [-16.25152778699993, 17.078750611000146],
                [-16.25152778699993, 17.079305649000048],
                [-16.251249313999892, 17.079305649000048],
                [-16.251249313999892, 17.079860688000053],
                [-16.250972747999924, 17.079860688000053],
                [-16.250972747999924, 17.08097267200003],
                [-16.251249313999892, 17.08097267200003],
                [-16.251249313999892, 17.081249238],
                [-16.25152778699993, 17.081249238],
                [-16.25152778699993, 17.082082749000165],
                [-16.251249313999892, 17.082082749000165],
                [-16.251249313999892, 17.082639695000069],
                [-16.250972747999924, 17.082639695000069],
                [-16.250972747999924, 17.082916261000037],
                [-16.250694274999887, 17.082916261000037],
                [-16.250694274999887, 17.083749772000147],
                [-16.250415801999964, 17.083749772000147],
                [-16.250415801999964, 17.084304811000152],
                [-16.250139236999871, 17.084304811000152],
                [-16.250139236999871, 17.084863662000146],
                [-16.249860763999948, 17.084863662000146],
                [-16.249860763999948, 17.085416793000149],
                [-16.24958419799998, 17.085416793000149],
                [-16.24958419799998, 17.085693359000118],
                [-16.248472213999889, 17.085693359000118],
                [-16.248472213999889, 17.085973739000053],
                [-16.248195647999921, 17.085973739000053],
                [-16.248195647999921, 17.086526870000057],
                [-16.247917174999884, 17.086526870000057],
                [-16.247917174999884, 17.087085724000133],
                [-16.247638701999847, 17.087085724000133],
                [-16.247638701999847, 17.087360382000099],
                [-16.24736022899998, 17.087360382000099],
                [-16.24736022899998, 17.087917328000174],
                [-16.247083663999945, 17.087917328000174],
                [-16.247083663999945, 17.088472366000076],
                [-16.246805190999964, 17.088472366000076],
                [-16.246805190999964, 17.089027405000081],
                [-16.246528624999939, 17.089027405000081],
                [-16.246528624999939, 17.090139389000115],
                [-16.246250151999959, 17.090139389000115],
                [-16.246250151999959, 17.09069442800012],
                [-16.245973586999924, 17.09069442800012],
                [-16.245973586999924, 17.091249466000022],
                [-16.245695113999886, 17.091249466000022],
                [-16.245695113999886, 17.092361451000102],
                [-16.245416640999849, 17.092361451000102],
                [-16.245416640999849, 17.093194962000041],
                [-16.245138167999983, 17.093194962000041],
                [-16.245138167999983, 17.093471528],
                [-16.244863509999902, 17.093471528],
                [-16.244863509999902, 17.094583511999986],
                [-16.244581221999908, 17.094583511999986],
                [-16.244581221999908, 17.09569358900012],
                [-16.24430465599994, 17.09569358900012],
                [-16.24430465599994, 17.096527099000127],
                [-16.244028090999961, 17.096527099000127],
                [-16.244028090999961, 17.097360610000123],
                [-16.243749617999924, 17.097360610000123],
                [-16.243749617999924, 17.097639083000161],
                [-16.243471144999887, 17.097639083000161],
                [-16.243471144999887, 17.098474502000101],
                [-16.243194578999919, 17.098474502000101],
                [-16.243194578999919, 17.099027633000105],
                [-16.242916105999882, 17.099027633000105],
                [-16.242916105999882, 17.099304199000073],
                [-16.242641447999972, 17.099304199000073],
                [-16.242641447999972, 17.100139618000185],
                [-16.2423591619999, 17.100139618000185],
                [-16.2423591619999, 17.100414275999981],
                [-16.242082596999921, 17.100414275999981],
                [-16.242082596999921, 17.100973129000124],
                [-16.241806030999953, 17.100973129000124],
                [-16.241806030999953, 17.101528168000129],
                [-16.241527557999916, 17.101528168000129],
                [-16.241527557999916, 17.102083206000032],
                [-16.241247177999924, 17.102083206000032],
                [-16.241247177999924, 17.102638245000037],
                [-16.240972519999843, 17.102638245000037],
                [-16.240972519999843, 17.103471756000033],
                [-16.240694046999977, 17.103471756000033],
                [-16.240694046999977, 17.104305268000076],
                [-16.240417480999838, 17.104305268000076],
                [-16.240417480999838, 17.104860306000148],
                [-16.240137100999902, 17.104860306000148],
                [-16.240137100999902, 17.105972291000057],
                [-16.239860534999934, 17.105972291000057],
                [-16.239860534999934, 17.106250764000094],
                [-16.239583969999956, 17.106250764000094],
                [-16.239583969999956, 17.107360841],
                [-16.239305496999918, 17.107360841],
                [-16.239305496999918, 17.107915879000132],
                [-16.23902893099995, 17.107915879000132],
                [-16.23902893099995, 17.108472824000103],
                [-16.238750457999913, 17.108472824000103],
                [-16.238750457999913, 17.109306335000042],
                [-16.238471984999876, 17.109306335000042],
                [-16.238471984999876, 17.109861374000047],
                [-16.238193511999953, 17.109861374000047],
                [-16.238193511999953, 17.110137939000083],
                [-16.237916946999974, 17.110137939000083],
                [-16.237916946999974, 17.110694884999987],
                [-16.237638473999937, 17.110694884999987],
                [-16.237638473999937, 17.11124992399999],
                [-16.237361907999968, 17.11124992399999],
                [-16.237361907999968, 17.111803055000053],
                [-16.237083434999931, 17.111803055000053],
                [-16.237083434999931, 17.11236381599997],
                [-16.236806869999953, 17.11236381599997],
                [-16.236806869999953, 17.112638474000164],
                [-16.236528396999915, 17.112638474000164],
                [-16.236528396999915, 17.113473893000105],
                [-16.236249923999878, 17.113473893000105],
                [-16.236249923999878, 17.114303588999974],
                [-16.235971450999955, 17.114303588999974],
                [-16.235971450999955, 17.115139008000085],
                [-16.235696792999931, 17.115139008000085],
                [-16.235696792999931, 17.11624908499999],
                [-16.23541641199995, 17.11624908499999],
                [-16.23541641199995, 17.117082597000035],
                [-16.235137938999969, 17.117082597000035],
                [-16.235137938999969, 17.117916108000031],
                [-16.234861373999934, 17.117916108000031],
                [-16.234861373999934, 17.118749620000074],
                [-16.234584807999966, 17.118749620000074],
                [-16.234584807999966, 17.120416641000077],
                [-16.234306334999928, 17.120416641000077],
                [-16.234306334999928, 17.121250152000187],
                [-16.234027861999891, 17.121250152000187],
                [-16.234027861999891, 17.121805191000192],
                [-16.233749388999854, 17.121805191000192],
                [-16.233749388999854, 17.122337341000161],
                [-16.233749388999854, 17.122638702000131],
                [-16.233606337999902, 17.122638702000131],
                [-16.233474730999944, 17.122638702000131],
                [-16.233474730999944, 17.122917175000168],
                [-16.233194350999952, 17.122917175000168],
                [-16.233194350999952, 17.123470306],
                [-16.232915877999972, 17.123470306],
                [-16.232915877999972, 17.123952865999968],
                [-16.232915877999972, 17.124305725000113],
                [-16.232725142999925, 17.124305725000113],
                [-16.232639311999947, 17.124305725000113],
                [-16.232639311999947, 17.124528886000064],
                [-16.232639311999947, 17.125692368000045],
                [-16.232362746999968, 17.125692368000045],
                [-16.232362746999968, 17.12624931400012],
                [-16.232084273999931, 17.12624931400012],
                [-16.232080460999953, 17.126806260000023],
                [-16.231805800999894, 17.126806260000023],
                [-16.231805800999894, 17.127361298000096],
                [-16.231527327999856, 17.127361298000096],
                [-16.231527327999856, 17.1279144290001],
                [-16.231252669999947, 17.1279144290001],
                [-16.231252669999947, 17.128196717000037],
                [-16.230911255999956, 17.128206253000087],
                [-16.230970383999932, 17.128471375],
                [-16.230693817999963, 17.128471375],
                [-16.230693817999963, 17.128599167000118],
                [-16.230693817999963, 17.129306794000115],
                [-16.230367660999889, 17.129283906000182],
                [-16.230417252999928, 17.130138397999986],
                [-16.229957580999894, 17.130138397999986],
                [-16.229583740999885, 17.130138397999986],
                [-16.229583740999885, 17.130418777000045],
                [-16.229305267999962, 17.130418777000045],
                [-16.229305267999962, 17.130971908000049],
                [-16.229030609999938, 17.130971908000049],
                [-16.229030609999938, 17.131250381000086],
                [-16.228748321999944, 17.131250381000086],
                [-16.228748321999944, 17.132663727000136],
                [-16.228748321999944, 17.132917404000068],
                [-16.229030609999938, 17.132917404000068],
                [-16.229030609999938, 17.133750915000064],
                [-16.228748321999944, 17.133750915000064],
                [-16.228748321999944, 17.134582519000105],
                [-16.228471756999966, 17.134582519000105],
                [-16.228471756999966, 17.135416031000148],
                [-16.228195190999941, 17.135416031000148],
                [-16.228195190999941, 17.135694504000185],
                [-16.22791671799996, 17.135694504000185],
                [-16.22791671799996, 17.136249542000087],
                [-16.227638244999923, 17.136249542000087],
                [-16.227638244999923, 17.136528015000124],
                [-16.227361679999888, 17.136528015000124],
                [-16.227361679999888, 17.137081146000185],
                [-16.227083206999851, 17.137081146000185],
                [-16.227083206999851, 17.137916565000069],
                [-16.226804732999881, 17.137916565000069],
                [-16.226804732999881, 17.138750077000111],
                [-16.226526259999957, 17.138750077000111],
                [-16.226526259999957, 17.139585496000052],
                [-16.226249694999979, 17.139585496000052],
                [-16.226249694999979, 17.14041710000015],
                [-16.225973129999943, 17.14041710000015],
                [-16.225973129999943, 17.141250612000022],
                [-16.225694655999973, 17.141250612000022],
                [-16.225694655999973, 17.141525269000056],
                [-16.225416182999936, 17.141525269000056],
                [-16.225416182999936, 17.141805648000116],
                [-16.225103378999904, 17.141790390000097],
                [-16.225139617999957, 17.142360687000121],
                [-16.22486114499992, 17.142360687000121],
                [-16.22486114499992, 17.142639160000158],
                [-16.224784850999924, 17.142639160000158],
                [-16.224582671999883, 17.142639160000158],
                [-16.224651336999898, 17.142942429000072],
                [-16.224306105999915, 17.142917632999968],
                [-16.224306105999915, 17.143472671000097],
                [-16.224027632999878, 17.143472671000097],
                [-16.224027632999878, 17.144027710000103],
                [-16.223749159999954, 17.144027710000103],
                [-16.223749159999954, 17.144861221000042],
                [-16.223472594999976, 17.144861221000042],
                [-16.223472594999976, 17.145416260000047],
                [-16.223194121999938, 17.145416260000047],
                [-16.223194121999938, 17.14597129800012],
                [-16.22291755599997, 17.14597129800012],
                [-16.22291755599997, 17.146528244000024],
                [-16.222639082999933, 17.146528244000024],
                [-16.222639082999933, 17.147083283000029],
                [-16.222360609999896, 17.147083283000029],
                [-16.222360609999896, 17.147638321000159],
                [-16.222085952999976, 17.147638321000159],
                [-16.222085952999976, 17.148195267000062],
                [-16.22180557199988, 17.148195267000062],
                [-16.22180557199988, 17.149026871000103],
                [-16.221527098999957, 17.149026871000103],
                [-16.221527098999957, 17.150138856000183],
                [-16.221250532999875, 17.150138856000183],
                [-16.221250532999875, 17.150972367000122],
                [-16.220972059999951, 17.150972367000122],
                [-16.220972059999951, 17.151527406000127],
                [-16.221290587999931, 17.151527406000127],
                [-16.221527098999957, 17.151527406000127],
                [-16.221527098999957, 17.15208244400003],
                [-16.221250532999875, 17.15208244400003],
                [-16.221250532999875, 17.152639390000104],
                [-16.220972059999951, 17.152639390000104],
                [-16.220972059999951, 17.152914048000127],
                [-16.220691681999938, 17.152914048000127],
                [-16.220691681999938, 17.153043747000083],
                [-16.220691681999938, 17.153474807000066],
                [-16.22052574199995, 17.153474807000066],
                [-16.220417021999936, 17.153474807000066],
                [-16.220417021999936, 17.153749465000033],
                [-16.220138548999898, 17.153749465000033],
                [-16.220140455999967, 17.154306411000107],
                [-16.219863890999932, 17.154306411000107],
                [-16.219863890999932, 17.155014039000037],
                [-16.219863890999932, 17.155136108000136],
                [-16.219583509999893, 17.155136108000136],
                [-16.219583509999893, 17.155416488000071],
                [-16.219303131999879, 17.155416488000071],
                [-16.219303131999879, 17.155971527000077],
                [-16.219028471999877, 17.155971527000077],
                [-16.219028471999877, 17.156528473000151],
                [-16.218751905999909, 17.156528473000151],
                [-16.218751905999909, 17.157083511000053],
                [-16.218473434999964, 17.157083511000053],
                [-16.218473434999964, 17.157918930000164],
                [-16.218194961999927, 17.157918930000164],
                [-16.218194961999927, 17.158472061000168],
                [-16.217638015999967, 17.158472061000168],
                [-16.217638015999967, 17.158750534000035],
                [-16.21735954299993, 17.158750534000035],
                [-16.21735954299993, 17.159584046000077],
                [-16.217081069999949, 17.159584046000077],
                [-16.217081069999949, 17.160139083999979],
                [-16.216806411999983, 17.160139083999979],
                [-16.216806411999983, 17.160694122999985],
                [-16.216527938999945, 17.160694122999985],
                [-16.216527938999945, 17.162082673000157],
                [-16.216249465999965, 17.162082673000157],
                [-16.216249465999965, 17.162639619000061],
                [-16.21597290099993, 17.162639619000061],
                [-16.21597290099993, 17.162916184000096],
                [-16.215694427999892, 17.162916184000096],
                [-16.215694427999892, 17.163471223000101],
                [-16.215415954999969, 17.163471223000101],
                [-16.215415954999969, 17.163749696000139],
                [-16.215139388999887, 17.163749696000139],
                [-16.215139388999887, 17.164304734000041],
                [-16.214860915999964, 17.164304734000041],
                [-16.214860915999964, 17.1645813],
                [-16.214582442999927, 17.1645813],
                [-16.214582442999927, 17.165138244000104],
                [-16.214305877999948, 17.165138244000104],
                [-16.214305877999948, 17.165693282000177],
                [-16.214582442999927, 17.165693282000177],
                [-16.214582442999927, 17.166250228000081],
                [-16.214860915999964, 17.166250228000081],
                [-16.214860915999964, 17.166528701000118],
                [-16.214582442999927, 17.166528701000118],
                [-16.214582442999927, 17.167085647000022],
                [-16.214305877999948, 17.167085647000022],
                [-16.214305877999948, 17.167360304999988],
                [-16.214027404999968, 17.167360304999988],
                [-16.214027404999968, 17.167638778000025],
                [-16.213750838999943, 17.167638778000025],
                [-16.213750838999943, 17.167917251000063],
                [-16.213472365999962, 17.167917251000063],
                [-16.213472365999962, 17.1681957240001],
                [-16.213193892999925, 17.1681957240001],
                [-16.213193892999925, 17.168750763000105],
                [-16.212917327999889, 17.168750763000105],
                [-16.212917327999889, 17.169027328000027],
                [-16.212638854999852, 17.169027328000027],
                [-16.212638854999852, 17.169860840000069],
                [-16.212360381999815, 17.169860840000069],
                [-16.212360381999815, 17.170139313000107],
                [-16.212083815999847, 17.170139313000107],
                [-16.212083815999847, 17.171249390000185],
                [-16.21180534299998, 17.171249390000185],
                [-16.21180534299998, 17.171527863000051],
                [-16.210971831999927, 17.171527863000051],
                [-16.210971831999927, 17.171806336000088],
                [-16.210695265999959, 17.171806336000088],
                [-16.210695265999959, 17.172639848000131],
                [-16.210971831999927, 17.172639848000131],
                [-16.210971831999927, 17.174028398000075],
                [-16.210695265999959, 17.174028398000075],
                [-16.210695265999959, 17.174861909000015],
                [-16.210416792999922, 17.174861909000015],
                [-16.210416792999922, 17.175970079000081],
                [-16.210138319999885, 17.175970079000081],
                [-16.210138319999885, 17.177082061000078],
                [-16.209861754999849, 17.177082061000078],
                [-16.209861754999849, 17.177639007000153],
                [-16.209583281999812, 17.177639007000153],
                [-16.209583281999812, 17.178192138000156],
                [-16.209306715999844, 17.178192138000156],
                [-16.209306715999844, 17.17874908400006],
                [-16.209028242999977, 17.17874908400006],
                [-16.209028242999977, 17.179582596000103],
                [-16.2087497699999, 17.179582596000103],
                [-16.2087497699999, 17.17986106900014],
                [-16.208471298999882, 17.17986106900014],
                [-16.208471298999882, 17.180414200000143],
                [-16.208194731999924, 17.180414200000143],
                [-16.208194731999924, 17.180696488000081],
                [-16.207639692999919, 17.180696488000081],
                [-16.207639692999919, 17.180973053000116],
                [-16.207361221999975, 17.180973053000116],
                [-16.207361221999975, 17.181249619000084],
                [-16.207084656999939, 17.181249619000084],
                [-16.207084656999939, 17.181806565000159],
                [-16.206806183999959, 17.181806565000159],
                [-16.206806183999959, 17.182361603000061],
                [-16.206527710999922, 17.182361603000061],
                [-16.206527710999922, 17.183750153000062],
                [-16.206249237999884, 17.183750153000062],
                [-16.206249237999884, 17.184583665000105],
                [-16.205974579999918, 17.184583665000105],
                [-16.205974579999918, 17.185693742000183],
                [-16.205692291999924, 17.185693742000183],
                [-16.205692291999924, 17.186805726000159],
                [-16.205415725999956, 17.186805726000159],
                [-16.205415725999956, 17.187360765000165],
                [-16.205139160999977, 17.187360765000165],
                [-16.205139160999977, 17.187639236000052],
                [-16.204862594999838, 17.187639236000052],
                [-16.204862594999838, 17.188472747000162],
                [-16.204584121999972, 17.188472747000162],
                [-16.204584121999972, 17.189306259000034],
                [-16.204305648999934, 17.189306259000034],
                [-16.204305648999934, 17.190139769999973],
                [-16.204027175999897, 17.190139769999973],
                [-16.204027175999897, 17.190694808999979],
                [-16.203752517999931, 17.190694808999979],
                [-16.203752517999931, 17.191249847000108],
                [-16.203470229999937, 17.191249847000108],
                [-16.203470229999937, 17.191804886000114],
                [-16.203193664999958, 17.191804886000114],
                [-16.203193664999958, 17.192081451000149],
                [-16.202917098999876, 17.192081451000149],
                [-16.202917098999876, 17.192363739000029],
                [-16.202638625999953, 17.192363739000029],
                [-16.202638625999953, 17.192638397000053],
                [-16.202360152999972, 17.192638397000053],
                [-16.202358245999903, 17.193193436000058],
                [-16.202083587999937, 17.193193436000058],
                [-16.202083587999937, 17.193473816000164],
                [-16.2018051149999, 17.193473816000164],
                [-16.2018051149999, 17.194303513000136],
                [-16.201528548999931, 17.194303513000136],
                [-16.201528548999931, 17.195138932000077],
                [-16.201248168999939, 17.195138932000077],
                [-16.201248168999939, 17.195417405000114],
                [-16.200971602999971, 17.195417405000114],
                [-16.200971602999971, 17.195695878000151],
                [-16.200695037999878, 17.195695878000151],
                [-16.200695037999878, 17.196250916000054],
                [-16.200416564999955, 17.196250916000054],
                [-16.200416564999955, 17.196527482000022],
                [-16.200138091999975, 17.196527482000022],
                [-16.200138091999975, 17.196807862000128],
                [-16.19986152599995, 17.196807862000128],
                [-16.19986152599995, 17.197360993000189],
                [-16.199583052999969, 17.197360993000189],
                [-16.199583052999969, 17.19819450500006],
                [-16.199306487999934, 17.19819450500006],
                [-16.199304579999932, 17.198749543000133],
                [-16.199026106999895, 17.198749543000133],
                [-16.199026106999895, 17.199861526],
                [-16.19874954199986, 17.199861526],
                [-16.19874954199986, 17.200138091000099],
                [-16.198472975999891, 17.200138091000099],
                [-16.198472975999891, 17.200971603000141],
                [-16.198194502999854, 17.200971603000141],
                [-16.198194502999854, 17.201250076000179],
                [-16.197917937999875, 17.201250076000179],
                [-16.197917937999875, 17.201805114000081],
                [-16.197639464999952, 17.201805114000081],
                [-16.197639464999952, 17.202638626000123],
                [-16.197360991999972, 17.202638626000123],
                [-16.197360991999972, 17.203750611000032],
                [-16.197082518999935, 17.203750611000032],
                [-16.197082518999935, 17.204305649000105],
                [-16.196804047999933, 17.204305649000105],
                [-16.196804047999933, 17.205139160999977],
                [-16.196527481999965, 17.205139160999977],
                [-16.196527481999965, 17.205974580000088],
                [-16.196250914999894, 17.205974580000088],
                [-16.196250914999894, 17.206527711000149],
                [-16.195972443999949, 17.206527711000149],
                [-16.195972443999949, 17.207084657],
                [-16.195695877999981, 17.207084657],
                [-16.195695877999981, 17.208196641000029],
                [-16.195417404999944, 17.208196641000029],
                [-16.195417404999944, 17.208471299000166],
                [-16.195138931999963, 17.208471299000166],
                [-16.195138931999963, 17.209306718000107],
                [-16.194860458999926, 17.209306718000107],
                [-16.194860458999926, 17.210138321999978],
                [-16.19458580099996, 17.210138321999978],
                [-16.19458580099996, 17.210695266000073],
                [-16.194305419999921, 17.210695266000073],
                [-16.194305419999921, 17.211528778000115],
                [-16.194026946999884, 17.211528778000115],
                [-16.194026946999884, 17.212305069000138],
                [-16.194026946999884, 17.212638855000023],
                [-16.193750381999962, 17.212638855000023],
                [-16.193750381999962, 17.212717056000031],
                [-16.193750381999962, 17.21347236600019],
                [-16.19347381699987, 17.21347236600019],
                [-16.19347381699987, 17.214305878],
                [-16.193195343999946, 17.214305878],
                [-16.193195343999946, 17.214582443000097],
                [-16.192916869999976, 17.214582443000097],
                [-16.192916869999976, 17.21541595500014],
                [-16.192638396999939, 17.21541595500014],
                [-16.192638396999939, 17.216249466000079],
                [-16.192363739999962, 17.216249466000079],
                [-16.192363739999962, 17.21708107000012],
                [-16.192081450999979, 17.21708107000012],
                [-16.192081450999979, 17.217361451000158],
                [-16.191804885999886, 17.217361451000158],
                [-16.191804885999886, 17.217638016000024],
                [-16.191528319999918, 17.217638016000024],
                [-16.191528319999918, 17.217916489000061],
                [-16.191251754999882, 17.217916489000061],
                [-16.191251754999882, 17.218193055000029],
                [-16.190969466999888, 17.218193055000029],
                [-16.190969466999888, 17.21902847399997],
                [-16.190694808999979, 17.21902847399997],
                [-16.190694808999979, 17.22041511600014],
                [-16.190416335999942, 17.22041511600014],
                [-16.190416335999942, 17.220972062000044],
                [-16.190141677999975, 17.220972062000044],
                [-16.190141677999975, 17.222085951999986],
                [-16.189859389999981, 17.222085951999986],
                [-16.189859389999981, 17.22236061000018],
                [-16.189702987999965, 17.22236061000018],
                [-16.189582823999899, 17.22236061000018],
                [-16.189582823999899, 17.223472595000089],
                [-16.18930625899992, 17.223472595000089],
                [-16.18930625899992, 17.223749160000125],
                [-16.189027785999883, 17.223749160000125],
                [-16.189027785999883, 17.224582672000167],
                [-16.18874931299996, 17.224582672000167],
                [-16.18874931299996, 17.224861145000034],
                [-16.188472746999878, 17.224861145000034],
                [-16.188472746999878, 17.225694655999973],
                [-16.188194273999954, 17.225694655999973],
                [-16.188194273999954, 17.227083206000145],
                [-16.187915800999974, 17.227083206000145],
                [-16.187915800999974, 17.228195191],
                [-16.187637327999937, 17.228195191],
                [-16.187637327999937, 17.228750229000127],
                [-16.187360762999901, 17.228750229000127],
                [-16.187360762999901, 17.229305268000132],
                [-16.187084196999933, 17.229305268000132],
                [-16.187084196999933, 17.22958374100017],
                [-16.186805723999896, 17.22958374100017],
                [-16.186805723999896, 17.230138779000072],
                [-16.186527250999973, 17.230138779000072],
                [-16.186527250999973, 17.230972291000114],
                [-16.18625068599988, 17.230972291000114],
                [-16.18625068599988, 17.231805802000054],
                [-16.185972212999957, 17.231805802000054],
                [-16.185972212999957, 17.232082368000022],
                [-16.185693739999977, 17.232082368000022],
                [-16.185693739999977, 17.232360841000059],
                [-16.185417173999952, 17.232360841000059],
                [-16.185417173999952, 17.233192445000157],
                [-16.185138702999893, 17.233192445000157],
                [-16.185138702999893, 17.233749389000025],
                [-16.18486023, 17.233749389000025],
                [-16.18486023, 17.234027862000062],
                [-16.184583664999877, 17.234027862000062],
                [-16.184583664999877, 17.234861373],
                [-16.184305191999954, 17.234861373],
                [-16.184305191999954, 17.235414504000062],
                [-16.184028625999872, 17.235414504000062],
                [-16.184028625999872, 17.235971450000136],
                [-16.183750152999949, 17.235971450000136],
                [-16.183750152999949, 17.237083435000045],
                [-16.183471679999911, 17.237083435000045],
                [-16.183471679999911, 17.23763847400005],
                [-16.183195114999933, 17.23763847400005],
                [-16.183195114999933, 17.238193512000123],
                [-16.182916641999896, 17.238193512000123],
                [-16.182916641999896, 17.23847198500016],
                [-16.182638168999972, 17.23847198500016],
                [-16.182638168999972, 17.238750458000027],
                [-16.18236160299989, 17.238750458000027],
                [-16.18236160299989, 17.239028931000064],
                [-16.182083129999967, 17.239028931000064],
                [-16.182083129999967, 17.239305497000032],
                [-16.18180465699993, 17.239305497000032],
                [-16.18180465699993, 17.239583970000069],
                [-16.181528091999951, 17.239583970000069],
                [-16.181528091999951, 17.240140916000144],
                [-16.181249618999971, 17.240140916000144],
                [-16.181249618999971, 17.242359162000184],
                [-16.180973052999946, 17.242359162000184],
                [-16.180973052999946, 17.242916108000088],
                [-16.180694579999965, 17.242916108000088],
                [-16.180694579999965, 17.243194581000125],
                [-16.180416106999928, 17.243194581000125],
                [-16.180416106999928, 17.245138168000096],
                [-16.180139541999893, 17.245138168000096],
                [-16.180139541999893, 17.245693206000169],
                [-16.179861068999969, 17.245693206000169],
                [-16.179861068999969, 17.246250152000073],
                [-16.179584502999887, 17.246250152000073],
                [-16.179584502999887, 17.246803283000077],
                [-16.179306029999964, 17.246803283000077],
                [-16.179306029999964, 17.24736022899998],
                [-16.179027556999927, 17.24736022899998],
                [-16.179027556999927, 17.247917175000055],
                [-16.178749083999946, 17.247917175000055],
                [-16.178749083999946, 17.248750687000097],
                [-16.178470610999909, 17.248750687000097],
                [-16.178470610999909, 17.249307633000171],
                [-16.178192137999872, 17.249307633000171],
                [-16.178192137999872, 17.250139237000042],
                [-16.177917479999962, 17.250139237000042],
                [-16.177917479999962, 17.250694275000171],
                [-16.177640914999927, 17.250694275000171],
                [-16.177640914999927, 17.251249314000177],
                [-16.17736244199989, 17.251249314000177],
                [-16.17736244199989, 17.25180626000008],
                [-16.177082060999965, 17.25180626000008],
                [-16.177082060999965, 17.25263977100019],
                [-16.176805495999815, 17.25263977100019],
                [-16.176805495999815, 17.253194810000025],
                [-16.176527022999949, 17.253194810000025],
                [-16.176527022999949, 17.254028321000135],
                [-16.176248549999912, 17.254028321000135],
                [-16.176248549999912, 17.25458336],
                [-16.175970076999874, 17.25458336],
                [-16.175970076999874, 17.255416871000136],
                [-16.175693510999906, 17.255416871000136],
                [-16.175693510999906, 17.255971908000106],
                [-16.175416945999928, 17.255971908000106],
                [-16.175416945999928, 17.256526946],
                [-16.17513847299989, 17.256526946],
                [-16.17513847299989, 17.257360458000051],
                [-16.174861906999922, 17.257360458000051],
                [-16.174861906999922, 17.257917404000125],
                [-16.174583433999885, 17.257917404000125],
                [-16.174583433999885, 17.258472442000084],
                [-16.174304960999962, 17.258472442000084],
                [-16.174304960999962, 17.259027481000089],
                [-16.174028395999812, 17.259027481000089],
                [-16.174028395999812, 17.259582519000162],
                [-16.173749924999925, 17.259582519000162],
                [-16.173749924999925, 17.260139465000066],
                [-16.173471451999887, 17.260139465000066],
                [-16.173471451999887, 17.260414123000032],
                [-16.173194885999919, 17.260414123000032],
                [-16.173194885999919, 17.260974884000177],
                [-16.172916412999882, 17.260974884000177],
                [-16.172916412999882, 17.261806488000047],
                [-16.172637939999959, 17.261806488000047],
                [-16.172637939999959, 17.262361527000053],
                [-16.172082901999943, 17.262361527000053],
                [-16.172082901999943, 17.262636185000076],
                [-16.171806335999975, 17.262636185000076],
                [-16.171806335999975, 17.263750077],
                [-16.171527862999937, 17.263750077],
                [-16.171527862999937, 17.265693665000072],
                [-16.1712493899999, 17.265693665000072],
                [-16.1712493899999, 17.266805650000151],
                [-16.170694351999884, 17.266805650000151],
                [-16.170694351999884, 17.267084121000039],
                [-16.170417785999916, 17.267084121000039],
                [-16.170417785999916, 17.26791572500008],
                [-16.170139312999879, 17.26791572500008],
                [-16.170139312999879, 17.268472671000154],
                [-16.169860839999956, 17.268472671000154],
                [-16.169860839999956, 17.269027710000159],
                [-16.169584274999977, 17.269027710000159],
                [-16.169582366999919, 17.269861221000099],
                [-16.16930580199994, 17.269861221000099],
                [-16.16930580199994, 17.270694733000141],
                [-16.169027328999903, 17.270694733000141],
                [-16.169027328999903, 17.271249771000043],
                [-16.168750762999935, 17.271249771000043],
                [-16.168750762999935, 17.271804810000049],
                [-16.168472289999897, 17.271804810000049],
                [-16.168472289999897, 17.272363663000021],
                [-16.168195724999919, 17.272363663000021],
                [-16.168195724999919, 17.272916794000082],
                [-16.167917251999882, 17.272916794000082],
                [-16.167917251999882, 17.273471833000087],
                [-16.167638778999958, 17.273471833000087],
                [-16.167638778999958, 17.274303437000128],
                [-16.167360305999978, 17.274303437000128],
                [-16.167360305999978, 17.274585725000065],
                [-16.167083739999953, 17.274585725000065],
                [-16.167083739999953, 17.274860383000032],
                [-16.166805266999972, 17.274860383000032],
                [-16.166805266999972, 17.275695802000143],
                [-16.166528701999937, 17.275695802000143],
                [-16.166528701999937, 17.277082444000143],
                [-16.1662502289999, 17.277082444000143],
                [-16.1662502289999, 17.27763939],
                [-16.165973662999932, 17.27763939],
                [-16.165973662999932, 17.277917861000049],
                [-16.165695189999894, 17.277917861000049],
                [-16.165695189999894, 17.27930641099999],
                [-16.165416716999971, 17.27930641099999],
                [-16.165416716999971, 17.280139923000036],
                [-16.165138243999934, 17.280139923000036],
                [-16.165138243999934, 17.280694961000165],
                [-16.16486358599991, 17.280694961000165],
                [-16.16486358599991, 17.281248092000169],
                [-16.164581297999916, 17.281248092000169],
                [-16.164581297999916, 17.281805038000073],
                [-16.164304732999938, 17.281805038000073],
                [-16.164304732999938, 17.282638550000115],
                [-16.164028166999969, 17.282638550000115],
                [-16.164028166999969, 17.283193588000017],
                [-16.163751601999934, 17.283193588000017],
                [-16.163751601999934, 17.283750534000092],
                [-16.163471220999895, 17.283750534000092],
                [-16.163471220999895, 17.284305573000097],
                [-16.16319465599986, 17.284305573000097],
                [-16.16319465599986, 17.285139084000036],
                [-16.162916182999822, 17.285139084000036],
                [-16.162916182999822, 17.285417557000073],
                [-16.162641524999913, 17.285417557000073],
                [-16.162641524999913, 17.286249161000171],
                [-16.162359237999908, 17.286249161000171],
                [-16.162359237999908, 17.286804200000176],
                [-16.162082672999873, 17.286804200000176],
                [-16.162082672999873, 17.28736114600008],
                [-16.161806107999894, 17.28736114600008],
                [-16.161806107999894, 17.287914277000084],
                [-16.161527633999867, 17.287914277000084],
                [-16.161527633999867, 17.288473130000057],
                [-16.161247253999932, 17.288473130000057],
                [-16.161247253999932, 17.289028169000062],
                [-16.160972595999965, 17.289028169000062],
                [-16.160972595999965, 17.289583207000135],
                [-16.160694122999928, 17.289583207000135],
                [-16.160694122999928, 17.290418624000097],
                [-16.16041755699996, 17.290418624000097],
                [-16.16041755699996, 17.291250228000138],
                [-16.16013717699991, 17.291250228000138],
                [-16.16013717699991, 17.292360305000045],
                [-16.159860610999942, 17.292360305000045],
                [-16.159860610999942, 17.293193817000088],
                [-16.159584045999964, 17.293193817000088],
                [-16.159584045999964, 17.294027328000084],
                [-16.159305572999926, 17.294027328000084],
                [-16.159305572999926, 17.294305801000121],
                [-16.159027099999889, 17.294305801000121],
                [-16.159027099999889, 17.294582367000089],
                [-16.158750533999921, 17.294584274000158],
                [-16.158750533999921, 17.296527863000108],
                [-16.158472060999884, 17.296527863000108],
                [-16.158472060999884, 17.29708290100001],
                [-16.158193587999961, 17.29708290100001],
                [-16.158193587999961, 17.297637940000016],
                [-16.157915114999923, 17.297637940000016],
                [-16.157915114999923, 17.29819488600009],
                [-16.157638549999945, 17.29819488600009],
                [-16.157638549999945, 17.29874992399999],
                [-16.157361983999976, 17.29874992399999],
                [-16.157361983999976, 17.299303055000053],
                [-16.157083510999939, 17.299303055000053],
                [-16.157083510999939, 17.299863815000094],
                [-16.156806945999961, 17.299863815000094],
                [-16.156806945999961, 17.300416947000031],
                [-16.156528472999923, 17.300416947000031],
                [-16.156528472999923, 17.301250457000037],
                [-16.156249999999886, 17.301250457000037],
                [-16.156249999999886, 17.301803590000077],
                [-16.155971526999963, 17.301803590000077],
                [-16.155971526999963, 17.302639007000039],
                [-16.155696868999883, 17.302639007000039],
                [-16.155696868999883, 17.303472519000081],
                [-16.155416487999958, 17.303472519000081],
                [-16.155416487999958, 17.304307937999965],
                [-16.15513801499992, 17.304307937999965],
                [-16.15513801499992, 17.305139542000063],
                [-16.154861449999942, 17.305139542000063],
                [-16.154861449999942, 17.305416107000099],
                [-16.154584883999973, 17.305416107000099],
                [-16.154584883999973, 17.305694580000136],
                [-16.154306410999936, 17.305694580000136],
                [-16.154306410999936, 17.306249619000141],
                [-16.154027937999899, 17.306249619000141],
                [-16.154027937999899, 17.30708313000008],
                [-16.153749464999976, 17.30708313000008],
                [-16.153749464999976, 17.307638169000086],
                [-16.153474806999952, 17.307638169000086],
                [-16.153474806999952, 17.307916642000123],
                [-16.15319442699996, 17.307916642000123],
                [-16.15319442699996, 17.30819511499999],
                [-16.15291595399998, 17.30819511499999],
                [-16.15291595399998, 17.308471680000082],
                [-16.152639387999955, 17.308471680000082],
                [-16.152639387999955, 17.309028626000156],
                [-16.152362822999976, 17.309028626000156],
                [-16.152362822999976, 17.310138703000064],
                [-16.152080536999961, 17.310138703000064],
                [-16.152080536999961, 17.311527253],
                [-16.151805876999902, 17.311527253],
                [-16.151805876999902, 17.31291580300018],
                [-16.151527403999978, 17.31291580300018],
                [-16.151527403999978, 17.313749313000187],
                [-16.151252745999955, 17.313749313000187],
                [-16.151252745999955, 17.31513977000003],
                [-16.150970459999883, 17.31513977000003],
                [-16.150970459999883, 17.315696716000105],
                [-16.150693893999915, 17.315696716000105],
                [-16.150693893999915, 17.315971374000128],
                [-16.150417328999936, 17.315971374000128],
                [-16.150417328999936, 17.317083359000037],
                [-16.150140760999875, 17.317083359000037],
                [-16.150140760999875, 17.317918778000148],
                [-16.149860382999975, 17.317918778000148],
                [-16.149860382999975, 17.318195343000184],
                [-16.149583816999893, 17.318195343000184],
                [-16.149583816999893, 17.318471909000152],
                [-16.14930534399997, 17.318471909000152],
                [-16.14930534399997, 17.319028855000056],
                [-16.149026870999933, 17.319028855000056],
                [-16.149026870999933, 17.319583893000129],
                [-16.148748397999952, 17.319583893000129],
                [-16.148748397999952, 17.320693970000036],
                [-16.148471832999917, 17.320693970000036],
                [-16.148471832999917, 17.32125091600011],
                [-16.148195266999949, 17.32125091600011],
                [-16.148195266999949, 17.321523667000065],
                [-16.148195266999949, 17.322082520000038],
                [-16.147916793999912, 17.322082520000038],
                [-16.147916793999912, 17.32291603200008],
                [-16.147638320999874, 17.32291603200008],
                [-16.147638320999874, 17.32374954300019],
                [-16.147361755999896, 17.32374954300019],
                [-16.147361755999896, 17.325138091000156],
                [-16.147083282999972, 17.325138091000156],
                [-16.147083282999972, 17.325416564000022],
                [-16.146804809999935, 17.325416564000022],
                [-16.146804809999935, 17.328195572000084],
                [-16.146526336999955, 17.328195572000084],
                [-16.146526336999955, 17.32902526800018],
                [-16.14624977099993, 17.32902526800018],
                [-16.14624977099993, 17.32930755600006],
                [-16.145971297999949, 17.32930755600006],
                [-16.145971297999949, 17.329860687000121],
                [-16.145694732999971, 17.329860687000121],
                [-16.145694732999971, 17.330139160000158],
                [-16.145139693999965, 17.330139160000158],
                [-16.145139693999965, 17.330417633000025],
                [-16.144582747999891, 17.330417633000025],
                [-16.144582747999891, 17.330694199000163],
                [-16.144306182999969, 17.330694199000163],
                [-16.144306182999969, 17.33180618300014],
                [-16.144027709999932, 17.33180618300014],
                [-16.144027709999932, 17.332361222000145],
                [-16.143749236999952, 17.332361222000145],
                [-16.143749236999952, 17.333194733000084],
                [-16.143472670999927, 17.333194733000084],
                [-16.143472670999927, 17.334028245000127],
                [-16.143194197999946, 17.334028245000127],
                [-16.143194197999946, 17.334583284000132],
                [-16.142915724999909, 17.334583284000132],
                [-16.142915724999909, 17.335416795000071],
                [-16.142639159999931, 17.335416795000071],
                [-16.142639159999931, 17.336250305000135],
                [-16.142360686999893, 17.336250305000135],
                [-16.142360686999893, 17.336805343000037],
                [-16.142084120999925, 17.336805343000037],
                [-16.142084120999925, 17.33763885500008],
                [-16.141805647999888, 17.33763885500008],
                [-16.141805647999888, 17.338472366000076],
                [-16.141527174999965, 17.338472366000076],
                [-16.141527174999965, 17.339027405000081],
                [-16.141250609999815, 17.339027405000081],
                [-16.141250609999815, 17.339860916000191],
                [-16.140972136999949, 17.339860916000191],
                [-16.140972136999949, 17.340692520000061],
                [-16.140693663999912, 17.340692520000061],
                [-16.140693663999912, 17.341527939000173],
                [-16.140417097999944, 17.341527939000173],
                [-16.140417097999944, 17.342084885000077],
                [-16.140138624999906, 17.342084885000077],
                [-16.140138624999906, 17.342914582000049],
                [-16.139862059999928, 17.342914582000049],
                [-16.139860153999962, 17.343750000999989],
                [-16.13958358699989, 17.343750000999989],
                [-16.13958358699989, 17.344306947000064],
                [-16.139305115999946, 17.344306947000064],
                [-16.139305115999946, 17.345138551000161],
                [-16.139028549999978, 17.345138551000161],
                [-16.139028549999978, 17.345972062000101],
                [-16.138750076999941, 17.345972062000101],
                [-16.138750076999941, 17.346527101000106],
                [-16.138473511999962, 17.346527101000106],
                [-16.138473511999962, 17.347360610000067],
                [-16.138195038999925, 17.347360610000067],
                [-16.138195038999925, 17.348194122000109],
                [-16.137916565999888, 17.348194122000109],
                [-16.137916565999888, 17.348749160000182],
                [-16.137638092999964, 17.348749160000182],
                [-16.137638092999964, 17.349582672000054],
                [-16.137359619999927, 17.349582672000054],
                [-16.137359619999927, 17.350139618000128],
                [-16.13708114699989, 17.350139618000128],
                [-16.13708114699989, 17.35069465600003],
                [-16.13680648899998, 17.35069465600003],
                [-16.136804580999922, 17.352361679000069],
                [-16.136249542999906, 17.352361679000069],
                [-16.136249542999906, 17.352638245000037],
                [-16.135971069999982, 17.352638245000037],
                [-16.135971069999982, 17.354028702000051],
                [-16.1356945039999, 17.354028702000051],
                [-16.1356945039999, 17.356250764000038],
                [-16.135416030999977, 17.356250764000038],
                [-16.135416030999977, 17.357639313999982],
                [-16.135141372999954, 17.357639313999982],
                [-16.135141372999954, 17.357917785000041],
                [-16.13485908499996, 17.357917785000041],
                [-16.13485908499996, 17.358749389000081],
                [-16.134582519999924, 17.358749389000081],
                [-16.134582519999924, 17.359582900000078],
                [-16.134305953999956, 17.359582900000078],
                [-16.134305953999956, 17.36041641200012],
                [-16.134027480999919, 17.36041641200012],
                [-16.134027480999919, 17.360971450000022],
                [-16.13375091599994, 17.360971450000022],
                [-16.13375091599994, 17.361528396000097],
                [-16.133472442999903, 17.361528396000097],
                [-16.133472442999903, 17.362638473000175],
                [-16.132360457999937, 17.362638473000175],
                [-16.132360457999937, 17.362916946000041],
                [-16.132083892999958, 17.362916946000041],
                [-16.132083892999958, 17.363470077000045],
                [-16.131805419999978, 17.363470077000045],
                [-16.131805419999978, 17.364305495999986],
                [-16.131526946999941, 17.364305495999986],
                [-16.131526946999941, 17.367082596000103],
                [-16.131250380999973, 17.367082596000103],
                [-16.131250380999973, 17.367639543000109],
                [-16.130971907999935, 17.367639543000109],
                [-16.130971907999935, 17.368196489000184],
                [-16.1306953429999, 17.368196489000184],
                [-16.1306953429999, 17.369306564000055],
                [-16.130416869999976, 17.369306564000055],
                [-16.130416869999976, 17.370418548000089],
                [-16.130138396999939, 17.370418548000089],
                [-16.130138396999939, 17.370971679000093],
                [-16.129861830999971, 17.370971679000093],
                [-16.129861830999971, 17.372083664000172],
                [-16.129583357999934, 17.372083664000172],
                [-16.129583357999934, 17.372638702000074],
                [-16.129306792999955, 17.372638702000074],
                [-16.129306792999955, 17.37319374100008],
                [-16.129028319999975, 17.37319374100008],
                [-16.129028319999975, 17.373750686999983],
                [-16.128749846999938, 17.373750686999983],
                [-16.128749846999938, 17.374860764000118],
                [-16.128471374999833, 17.374860764000118],
                [-16.128471374999833, 17.376249314000063],
                [-16.128194807999932, 17.376249314000063],
                [-16.128194807999932, 17.377082825000173],
                [-16.127916336999931, 17.377082825000173],
                [-16.127916336999931, 17.377361298000039],
                [-16.127639770999963, 17.377361298000039],
                [-16.127639770999963, 17.377916337000045],
                [-16.127361297999983, 17.377916337000045],
                [-16.127361297999983, 17.378749847999984],
                [-16.127084732999947, 17.378749847999984],
                [-16.127084732999947, 17.379304886999989],
                [-16.1268062599999, 17.379304886999989],
                [-16.1268062599999, 17.380416871000023],
                [-16.126527786999873, 17.380416871000023],
                [-16.126527786999873, 17.380693437000161],
                [-16.126249313999836, 17.380693437000161],
                [-16.126249313999836, 17.380971910000028],
                [-16.125974655999926, 17.380971910000028],
                [-16.125974655999926, 17.381526948000101],
                [-16.12569427499983, 17.381526948000101],
                [-16.12569427499983, 17.382917404000011],
                [-16.125415801999964, 17.382917404000011],
                [-16.125415801999964, 17.383195877000048],
                [-16.125139236999928, 17.383195877000048],
                [-16.125139236999928, 17.383750915000178],
                [-16.12486267099996, 17.383750915000178],
                [-16.12486267099996, 17.385139465000123],
                [-16.12458419799998, 17.385139465000123],
                [-16.12458419799998, 17.385972977000165],
                [-16.124305724999942, 17.385972977000165],
                [-16.124305724999942, 17.387083054000072],
                [-16.124027251999905, 17.387083054000072],
                [-16.124027251999905, 17.387638092000032],
                [-16.123752593999939, 17.387638092000032],
                [-16.123752593999939, 17.388195038000106],
                [-16.123470305999945, 17.388195038000106],
                [-16.123470305999945, 17.389305115000184],
                [-16.123193740999966, 17.389305115000184],
                [-16.12320137099988, 17.390422821000129],
                [-16.122917174999884, 17.390417100000093],
                [-16.122968672999889, 17.390993119000029],
                [-16.122638701999961, 17.390970231000097],
                [-16.122638701999961, 17.391805650000038],
                [-16.122358321999855, 17.391805650000038],
                [-16.122358321999855, 17.392295838000109],
                [-16.122358321999855, 17.392915727000116],
                [-16.122083663999945, 17.392915727000116],
                [-16.122083663999945, 17.393192292000037],
                [-16.122007369999949, 17.393192292000037],
                [-16.121805190999908, 17.393192292000037],
                [-16.121868133999953, 17.39349937500009],
                [-16.121530532999941, 17.393474578000109],
                [-16.121530532999941, 17.393749236000076],
                [-16.121248244999947, 17.393749236000076],
                [-16.121248244999947, 17.394304275000081],
                [-16.120971678999979, 17.394304275000081],
                [-16.120971678999979, 17.394861220999985],
                [-16.120695113999886, 17.394861220999985],
                [-16.120742797999924, 17.39626884400019],
                [-16.120416640999963, 17.396249771000157],
                [-16.120416640999963, 17.397068024000077],
                [-16.120416640999963, 17.3976364130001],
                [-16.120138167999926, 17.3976364130001],
                [-16.120138167999926, 17.397750854000094],
                [-16.120138167999926, 17.398195267000176],
                [-16.119943618999969, 17.398195267000176],
                [-16.119861601999958, 17.398195267000176],
                [-16.119861601999958, 17.398433686000089],
                [-16.119861601999958, 17.399028779000048],
                [-16.119583128999921, 17.399028779000048],
                [-16.119583128999921, 17.399276733000136],
                [-16.119583128999921, 17.399860383000089],
                [-16.119409560999941, 17.399860383000089],
                [-16.119304655999883, 17.399860383000089],
                [-16.119304655999883, 17.400213242000063],
                [-16.119304655999883, 17.400693894000028],
                [-16.119161606999967, 17.400693894000028],
                [-16.119026182999846, 17.400693894000028],
                [-16.119026182999846, 17.401145935000045],
                [-16.119026182999846, 17.401250840000102],
                [-16.118749617999981, 17.401250840000102],
                [-16.118749617999981, 17.40208244400003],
                [-16.118473051999899, 17.40208244400003],
                [-16.118473051999899, 17.402915956000072],
                [-16.118194578999976, 17.402915956000072],
                [-16.118194578999976, 17.403749467000182],
                [-16.117916105999939, 17.403749467000182],
                [-16.117929458999981, 17.404586792000089],
                [-16.11763954099996, 17.404581071000052],
                [-16.11763954099996, 17.405138015000148],
                [-16.11736106799998, 17.405138015000148],
                [-16.11736106799998, 17.40597152700019],
                [-16.117082596999978, 17.40597152700019],
                [-16.117082596999978, 17.406803131000061],
                [-16.116804123999941, 17.406803131000061],
                [-16.116804123999941, 17.407638550000172],
                [-16.116527557999973, 17.407638550000172],
                [-16.116527557999973, 17.408193588000074],
                [-16.116249084999936, 17.408193588000074],
                [-16.116249084999936, 17.409025192000172],
                [-16.115972519999957, 17.409025192000172],
                [-16.115972519999957, 17.41013908400015],
                [-16.115695953999875, 17.41013908400015],
                [-16.115695953999875, 17.410972595999965],
                [-16.115417480999952, 17.410972595999965],
                [-16.115417480999952, 17.4120826730001],
                [-16.115139007999915, 17.4120826730001],
                [-16.115139007999915, 17.412639619000174],
                [-16.114860534999877, 17.412639619000174],
                [-16.114860534999877, 17.412916184000039],
                [-16.114585876999968, 17.412916184000039],
                [-16.114585876999968, 17.413473130000114],
                [-16.114305496999975, 17.413473130000114],
                [-16.114305496999975, 17.414028169000119],
                [-16.114027023999938, 17.414028169000119],
                [-16.114027023999938, 17.414583207000021],
                [-16.11375045799997, 17.414583207000021],
                [-16.11375045799997, 17.415138246000026],
                [-16.113473892999878, 17.415138246000026],
                [-16.113473892999878, 17.416528701000061],
                [-16.113191604999884, 17.416528701000061],
                [-16.113191604999884, 17.418193816999974],
                [-16.112916946999917, 17.418193816999974],
                [-16.112916946999917, 17.418750763000048],
                [-16.11263847399988, 17.418750763000048],
                [-16.11263847399988, 17.419027328000141],
                [-16.11236381599997, 17.419027328000141],
                [-16.11236381599997, 17.420415878000085],
                [-16.112083434999874, 17.420415878000085],
                [-16.112083434999874, 17.421249390000128],
                [-16.111803054999939, 17.421249390000128],
                [-16.111803054999939, 17.422361374000104],
                [-16.111528396999972, 17.422361374000104],
                [-16.111528396999972, 17.423196793000045],
                [-16.11125183099989, 17.423196793000045],
                [-16.11125183099989, 17.423749924000106],
                [-16.110969542999896, 17.423749924000106],
                [-16.110969542999896, 17.42430687000018],
                [-16.11069488499993, 17.42430687000018],
                [-16.11069488499993, 17.424861909000185],
                [-16.11041641199995, 17.424861909000185],
                [-16.11041641199995, 17.425693513000056],
                [-16.110137938999912, 17.425693513000056],
                [-16.110137938999912, 17.42625045900013],
                [-16.109859465999875, 17.42625045900013],
                [-16.109859465999875, 17.426805497000032],
                [-16.109582900999897, 17.426805497000032],
                [-16.109582900999897, 17.427917480000133],
                [-16.109306334999928, 17.427917480000133],
                [-16.109306334999928, 17.429582595000113],
                [-16.109027861999891, 17.429582595000113],
                [-16.109027861999891, 17.43013954200012],
                [-16.108749388999968, 17.43013954200012],
                [-16.108749388999968, 17.431249619000027],
                [-16.108472823999932, 17.431249619000027],
                [-16.108472823999932, 17.433750153000176],
                [-16.108194350999952, 17.433750153000176],
                [-16.108194350999952, 17.434860230000083],
                [-16.107915877999915, 17.434860230000083],
                [-16.107915877999915, 17.435415269000089],
                [-16.107637404999878, 17.435415269000089],
                [-16.107637404999878, 17.43625068800003],
                [-16.107360838999909, 17.43625068800003],
                [-16.107360838999909, 17.436807634000104],
                [-16.107084273999931, 17.436807634000104],
                [-16.107082365999872, 17.437360765000108],
                [-16.106250761999888, 17.437360765000108],
                [-16.106250761999888, 17.437639237999974],
                [-16.105972288999965, 17.437639237999974],
                [-16.105972288999965, 17.438194276000104],
                [-16.105693817999907, 17.438194276000104],
                [-16.105693817999907, 17.438749313000073],
                [-16.105972288999965, 17.438749313000073],
                [-16.105972288999965, 17.43902969300018],
                [-16.106250761999888, 17.43902969300018],
                [-16.106250761999888, 17.43986129700005],
                [-16.105972288999965, 17.43986129700005],
                [-16.105972288999965, 17.440416336000055],
                [-16.105693817999907, 17.440416336000055],
                [-16.105693817999907, 17.441249847000051],
                [-16.105417250999949, 17.441249847000051],
                [-16.105417250999949, 17.441804886000057],
                [-16.105138779999834, 17.441804886000057],
                [-16.105138779999834, 17.442638397000167],
                [-16.104860306999967, 17.442638397000167],
                [-16.104860306999967, 17.44319534300007],
                [-16.104583740999828, 17.44319534300007],
                [-16.104583740999828, 17.444026947000111],
                [-16.104305267999962, 17.444026947000111],
                [-16.104305267999962, 17.444860458999983],
                [-16.104028702999926, 17.444860458999983],
                [-16.104028702999926, 17.445692063000081],
                [-16.103750229999946, 17.445692063000081],
                [-16.103750229999946, 17.446527481999965],
                [-16.103471756999909, 17.446527481999965],
                [-16.103471756999909, 17.447639466000169],
                [-16.103195190999941, 17.447639466000169],
                [-16.103195190999941, 17.448474883000074],
                [-16.102916717999904, 17.448474883000074],
                [-16.102916717999904, 17.449306487000172],
                [-16.10263824499998, 17.449306487000172],
                [-16.10263824499998, 17.450136186000123],
                [-16.102361679999888, 17.450136186000123],
                [-16.102361679999888, 17.450971603000085],
                [-16.102083206999964, 17.450971603000085],
                [-16.102083206999964, 17.451805114000024],
                [-16.101804733999927, 17.451805114000024],
                [-16.101804733999927, 17.452638626000066],
                [-16.101528167999959, 17.452638626000066],
                [-16.101528167999959, 17.453750610000043],
                [-16.101249694999922, 17.453750610000043],
                [-16.101249694999922, 17.454584122000085],
                [-16.100973129999943, 17.454584122000085],
                [-16.100973129999943, 17.455415726000183],
                [-16.100694656999906, 17.455415726000183],
                [-16.100694656999906, 17.455972672000087],
                [-16.100139617999901, 17.455972672000087],
                [-16.100139617999901, 17.456527710000159],
                [-16.099861144999977, 17.456527710000159],
                [-16.099861144999977, 17.458471299000109],
                [-16.099584579999885, 17.458471299000109],
                [-16.099584579999885, 17.459304810000106],
                [-16.099845886999958, 17.459302903000037],
                [-16.099861144999977, 17.460971833000087],
                [-16.099584579999885, 17.460958481000034],
                [-16.099584579999885, 17.461803437000128],
                [-16.099306106999961, 17.461868286000083],
                [-16.099306106999961, 17.462360381000053],
                [-16.099203108999973, 17.462360381000053],
                [-16.099027633999924, 17.462360381000053],
                [-16.099027633999924, 17.46319580100004],
                [-16.098749160999887, 17.46319580100004],
                [-16.098749160999887, 17.46375083900017],
                [-16.098472594999919, 17.46375083900017],
                [-16.098472594999919, 17.464307785000074],
                [-16.09819221399988, 17.464307785000074],
                [-16.09819221399988, 17.464860916000077],
                [-16.097917556999903, 17.464860916000077],
                [-16.097917556999903, 17.465417862000152],
                [-16.097639083999979, 17.465417862000152],
                [-16.097639083999979, 17.466529847000061],
                [-16.097362517999898, 17.466529847000061],
                [-16.097362517999898, 17.467361451000102],
                [-16.097084044999974, 17.467361451000102],
                [-16.097084044999974, 17.467916489],
                [-16.096805571999937, 17.467916489],
                [-16.096805571999937, 17.468471528],
                [-16.096527098999957, 17.468471528],
                [-16.096527098999957, 17.468751908000115],
                [-16.096252440999876, 17.468751908000115],
                [-16.096252440999876, 17.469305039000176],
                [-16.095970152999882, 17.469305039000176],
                [-16.095970152999882, 17.47069358900012],
                [-16.095693586999914, 17.47069358900012],
                [-16.095693586999914, 17.472639083000161],
                [-16.095417021999936, 17.472639083000161],
                [-16.095417021999936, 17.474861145000148],
                [-16.095138548999898, 17.474861145000148],
                [-16.095138548999898, 17.476806641],
                [-16.094860075999975, 17.476806641],
                [-16.094860075999975, 17.477918625000029],
                [-16.094583509999893, 17.477918625000029],
                [-16.094583509999893, 17.479583741000113],
                [-16.09430503699997, 17.479583741000113],
                [-16.09430503699997, 17.48069381800002],
                [-16.094030379999879, 17.48069381800002],
                [-16.094030379999879, 17.480972291000057],
                [-16.093748092999874, 17.480972291000057],
                [-16.093748092999874, 17.482082367999965],
                [-16.093471527999839, 17.482084275000034],
                [-16.093471527999839, 17.482639314000039],
                [-16.09319496199987, 17.482639314000039],
                [-16.09319496199987, 17.483472823],
                [-16.092916488999833, 17.483472823],
                [-16.092916488999833, 17.484027862],
                [-16.092636108999898, 17.484027862],
                [-16.092636108999898, 17.484306335000042],
                [-16.092361450999931, 17.484306335000042],
                [-16.092361450999931, 17.484582900000134],
                [-16.092082977999951, 17.484582900000134],
                [-16.092082977999951, 17.485416412000177],
                [-16.091806411999983, 17.485416412000177],
                [-16.091806411999983, 17.485971450000079],
                [-16.091527938999945, 17.485971450000079],
                [-16.091527938999945, 17.486249923000116],
                [-16.091249465999908, 17.486249923000116],
                [-16.091249465999908, 17.489860535000048],
                [-16.090972900999873, 17.489860535000048],
                [-16.090972900999873, 17.490417481000122],
                [-16.090694427999836, 17.490417481000122],
                [-16.090694427999836, 17.491247177000048],
                [-16.090417861999867, 17.491247177000048],
                [-16.090417861999867, 17.491807938000022],
                [-16.09013938899983, 17.491807938000022],
                [-16.09013938899983, 17.492082596000159],
                [-16.089305877999948, 17.492082596000159],
                [-16.089305877999948, 17.492361069000026],
                [-16.089027404999911, 17.492361069000026],
                [-16.089027404999911, 17.492916108000031],
                [-16.089305877999948, 17.492916108000031],
                [-16.089305877999948, 17.493194581000068],
                [-16.089582442999927, 17.493194581000068],
                [-16.089582442999927, 17.493473054000106],
                [-16.089860915999964, 17.493473054000106],
                [-16.089860915999964, 17.493749618999971],
                [-16.089582442999927, 17.493749618999971],
                [-16.089582442999927, 17.494028092],
                [-16.089305877999948, 17.494028092],
                [-16.089305877999948, 17.49486160400005],
                [-16.089027404999911, 17.49486160400005],
                [-16.089027404999911, 17.496250152000187],
                [-16.088750838999943, 17.496250152000187],
                [-16.088750838999943, 17.497083664000058],
                [-16.088472365999905, 17.497083664000058],
                [-16.088472365999905, 17.497917175],
                [-16.088195800999927, 17.497917175],
                [-16.088195800999927, 17.49875068700004],
                [-16.087917327999889, 17.49875068700004],
                [-16.087917327999889, 17.499582291000138],
                [-16.087638854999966, 17.499582291000138],
                [-16.087638854999966, 17.500139237000042],
                [-16.087360381999929, 17.500139237000042],
                [-16.087360381999929, 17.500974656000153],
                [-16.087083815999961, 17.500974656000153],
                [-16.087083815999961, 17.501804352000022],
                [-16.086805342999924, 17.501804352000022],
                [-16.086805342999924, 17.502914429000157],
                [-16.086526869999886, 17.502914429000157],
                [-16.086526869999886, 17.503749848000041],
                [-16.086250304999908, 17.503749848000041],
                [-16.086250304999908, 17.505136491000144],
                [-16.08597373899994, 17.505136491000144],
                [-16.08597373899994, 17.506528854000123],
                [-16.085695265999902, 17.506528854000123],
                [-16.085695265999902, 17.507917404000125],
                [-16.085416792999979, 17.507917404000125],
                [-16.085416792999979, 17.508750915000064],
                [-16.085138319999942, 17.508750915000064],
                [-16.085138319999942, 17.509582519000105],
                [-16.084863661999918, 17.509582519000105],
                [-16.084863661999918, 17.510694504000185],
                [-16.084581373999924, 17.510694504000185],
                [-16.084581373999924, 17.511804581000092],
                [-16.084304808999889, 17.51180648799999],
                [-16.084304808999889, 17.512361527],
                [-16.084028242999921, 17.512361527],
                [-16.084028242999921, 17.513193131000094],
                [-16.083751677999942, 17.513193131000094],
                [-16.083751677999942, 17.514862061000144],
                [-16.084028242999921, 17.514862061000144],
                [-16.084028242999921, 17.515693665000015],
                [-16.083751677999942, 17.515693665000015],
                [-16.083751677999942, 17.515972138000052],
                [-16.083469391999927, 17.515972138000052],
                [-16.083469391999927, 17.516525269000113],
                [-16.083194731999981, 17.516525269000113],
                [-16.083194731999981, 17.517084123000132],
                [-16.082916258999944, 17.517084123000132],
                [-16.082916258999944, 17.517639161000034],
                [-16.082641600999921, 17.517639161000034],
                [-16.082641600999921, 17.51819419800006],
                [-16.082359314999962, 17.51819419800006],
                [-16.082359314999962, 17.519029617000172],
                [-16.082082748999881, 17.519029617000172],
                [-16.082082748999881, 17.520416259000172],
                [-16.081806183999902, 17.520416259000172],
                [-16.081806183999902, 17.521528244000081],
                [-16.081527710999978, 17.521528244000081],
                [-16.081527710999978, 17.523471832000098],
                [-16.0812473299999, 17.523471832000098],
                [-16.0812473299999, 17.524583817000178],
                [-16.080972671999973, 17.524583817000178],
                [-16.080972671999973, 17.525693894000085],
                [-16.080694198999936, 17.525693894000085],
                [-16.080694198999936, 17.527639390000161],
                [-16.080415725999956, 17.527639390000161],
                [-16.080415725999956, 17.529304504999971],
                [-16.080137252999918, 17.529304504999971],
                [-16.080137252999918, 17.530136109000068],
                [-16.079860687999883, 17.530136109000068],
                [-16.079860687999883, 17.530696869000053],
                [-16.079584121999915, 17.530696869000053],
                [-16.079584121999915, 17.531250000000114],
                [-16.079305648999878, 17.531250000000114],
                [-16.079305648999878, 17.5340271],
                [-16.07902717599984, 17.5340271],
                [-16.07902717599984, 17.53486061100017],
                [-16.078750610999975, 17.53486061100017],
                [-16.078750610999975, 17.535417557000073],
                [-16.078472137999938, 17.535417557000073],
                [-16.078472137999938, 17.537082673000157],
                [-16.078193664999958, 17.537082673000157],
                [-16.078193664999958, 17.537916184000096],
                [-16.077915191999921, 17.537916184000096],
                [-16.077915191999921, 17.539304734000041],
                [-16.077638625999953, 17.539304734000041],
                [-16.077638625999953, 17.54041671900012],
                [-16.077362060999917, 17.54041671900012],
                [-16.077360152999915, 17.542085647000022],
                [-16.07708358799988, 17.542085647000022],
                [-16.07708358799988, 17.54291534400005],
                [-16.076805114999843, 17.54291534400005],
                [-16.076805114999843, 17.543472290000125],
                [-16.076528548999875, 17.543472290000125],
                [-16.076528548999875, 17.54652977000012],
                [-16.076805114999843, 17.54652977000012],
                [-16.076805114999843, 17.546806336000088],
                [-16.076528548999875, 17.546806336000088],
                [-16.076528548999875, 17.54736137399999],
                [-16.075696944999891, 17.54736137399999],
                [-16.075696944999891, 17.547639847000028],
                [-16.075416564999955, 17.547639847000028],
                [-16.075416564999955, 17.548471451000125],
                [-16.075138091999918, 17.548471451000125],
                [-16.075138091999918, 17.550138474000107],
                [-16.07486152599995, 17.550138474000107],
                [-16.07486152599995, 17.550416947000144],
                [-16.074583052999913, 17.550416947000144],
                [-16.074583052999913, 17.551527024000052],
                [-16.074306487999934, 17.551527024000052],
                [-16.074304579999875, 17.552360536000094],
                [-16.074028014999897, 17.552360536000094],
                [-16.074028014999897, 17.554027557000097],
                [-16.073749541999973, 17.554027557000097],
                [-16.073749541999973, 17.555139541000074],
                [-16.073474883999893, 17.555139541000074],
                [-16.073474883999893, 17.555973053000116],
                [-16.073194502999968, 17.555973053000116],
                [-16.073194502999968, 17.55708313000008],
                [-16.072916029999931, 17.55708313000008],
                [-16.072916029999931, 17.558750153000062],
                [-16.072639464999952, 17.558750153000062],
                [-16.072639464999952, 17.560417176000044],
                [-16.072362898999927, 17.560417176000044],
                [-16.072362898999927, 17.561805725999989],
                [-16.072080612999912, 17.561805725999989],
                [-16.072080612999912, 17.563194276000161],
                [-16.07180595299991, 17.563194276000161],
                [-16.07180595299991, 17.564306259000034],
                [-16.071527479999872, 17.564306259000034],
                [-16.071527479999872, 17.565694808999979],
                [-16.071252821999963, 17.565694808999979],
                [-16.071252821999963, 17.566528320000145],
                [-16.070970535999891, 17.566528320000145],
                [-16.070970535999891, 17.567638397000053],
                [-16.070692062999967, 17.567638397000053],
                [-16.070692062999967, 17.568471909000095],
                [-16.070417404999944, 17.568471909000095],
                [-16.070417404999944, 17.569307328000036],
                [-16.070140837999816, 17.569307328000036],
                [-16.070140837999816, 17.570138932000077],
                [-16.069862366999871, 17.570138932000077],
                [-16.069862366999871, 17.571807862000185],
                [-16.069583893999834, 17.571807862000185],
                [-16.069583893999834, 17.57319450500006],
                [-16.069305420999967, 17.57319450500006],
                [-16.069305420999967, 17.574583055000176],
                [-16.06902694799993, 17.574583055000176],
                [-16.06902694799993, 17.576250076000179],
                [-16.068748474999893, 17.576250076000179],
                [-16.068748474999893, 17.577638626000123],
                [-16.068471908999925, 17.577638626000123],
                [-16.068471908999925, 17.578193664000082],
                [-16.068195343999946, 17.578193664000082],
                [-16.068195343999946, 17.579305649000162],
                [-16.067916870999909, 17.579305649000162],
                [-16.067916870999909, 17.579860687000064],
                [-16.067638397999872, 17.579860687000064],
                [-16.067638397999872, 17.581249237000179],
                [-16.067361831999904, 17.581249237000179],
                [-16.067361831999904, 17.583749772000033],
                [-16.06708335899998, 17.583749772000033],
                [-16.06708335899998, 17.585136414000033],
                [-16.066804885999943, 17.585136414000033],
                [-16.066804885999943, 17.58680534299998],
                [-16.066528320999964, 17.58680534299998],
                [-16.066528320999964, 17.588193893000152],
                [-16.066249847999927, 17.588193893000152],
                [-16.066249847999927, 17.589027404000092],
                [-16.06597137499989, 17.589027404000092],
                [-16.06597137499989, 17.590694427000074],
                [-16.065694808999922, 17.590694427000074],
                [-16.065694808999922, 17.592081070000177],
                [-16.065416335999885, 17.592081070000177],
                [-16.065416335999885, 17.594583512000099],
                [-16.065139770999906, 17.594583512000099],
                [-16.065139770999906, 17.595693589000177],
                [-16.064861297999983, 17.595693589000177],
                [-16.064861297999983, 17.597637178000127],
                [-16.064582824999945, 17.597637178000127],
                [-16.064582824999945, 17.598472595000089],
                [-16.064306258999977, 17.598472595000089],
                [-16.064306258999977, 17.599861145000034],
                [-16.06402778599994, 17.599861145000034],
                [-16.06402778599994, 17.600694655999973],
                [-16.06374931299996, 17.600694655999973],
                [-16.06374931299996, 17.602361679000182],
                [-16.063472747999924, 17.602361679000182],
                [-16.063472747999924, 17.604305268000132],
                [-16.063194273999954, 17.604305268000132],
                [-16.063194273999954, 17.605417252000109],
                [-16.062639235999882, 17.605417252000109],
                [-16.062639235999882, 17.605693818000077],
                [-16.062360762999845, 17.605693818000077],
                [-16.062360762999845, 17.607639314000153],
                [-16.062639235999882, 17.607639314000153],
                [-16.062639235999882, 17.609304429000133],
                [-16.062360762999845, 17.609304429000133],
                [-16.062360762999845, 17.610971450000136],
                [-16.06208419799998, 17.610971450000136],
                [-16.06208419799998, 17.612636566000049],
                [-16.061805723999839, 17.612636566000049],
                [-16.061805723999839, 17.612918854000156],
                [-16.061527250999973, 17.612918854000156],
                [-16.061527250999973, 17.61347198499999],
                [-16.061250685999937, 17.61347198499999],
                [-16.061250685999937, 17.614583968999966],
                [-16.060972212999957, 17.614583968999966],
                [-16.060972212999957, 17.615694046000101],
                [-16.060695646999932, 17.615694046000101],
                [-16.060695646999932, 17.61763954200012],
                [-16.060417173999952, 17.61763954200012],
                [-16.060417173999952, 17.61847114599999],
                [-16.060138700999914, 17.61847114599999],
                [-16.060138700999914, 17.619861604000107],
                [-16.05986023, 17.619861604000107],
                [-16.05986023, 17.620971679000036],
                [-16.059583662999898, 17.620971679000036],
                [-16.059583662999898, 17.62291526700011],
                [-16.059305191999954, 17.62291526700011],
                [-16.059305191999954, 17.624307633],
                [-16.059028625999929, 17.624307633],
                [-16.059028625999929, 17.625694275000171],
                [-16.058750152999949, 17.625694275000171],
                [-16.058750152999949, 17.627082825000116],
                [-16.058473587999913, 17.627082825000116],
                [-16.058473587999913, 17.62958336],
                [-16.058195114999876, 17.62958336],
                [-16.058195114999876, 17.630416871000136],
                [-16.057916641999839, 17.630416871000136],
                [-16.057916641999839, 17.632083892000139],
                [-16.057638168999972, 17.632083892000139],
                [-16.057638168999972, 17.634304046000125],
                [-16.057916641999839, 17.634304046000125],
                [-16.057916641999839, 17.635139465000066],
                [-16.057638168999972, 17.635139465000066],
                [-16.057638168999972, 17.63819694599999],
                [-16.057359695999935, 17.63819694599999],
                [-16.057359695999935, 17.641527177000114],
                [-16.057081222999898, 17.641527177000114],
                [-16.057081222999898, 17.641805649999981],
                [-16.05680465699993, 17.641805649999981],
                [-16.05680465699993, 17.642639161000147],
                [-16.056528091999951, 17.642639161000147],
                [-16.056528091999951, 17.644306182000093],
                [-16.056251525999926, 17.644306182000093],
                [-16.056251525999926, 17.64708328200004],
                [-16.055971145999877, 17.64708328200004],
                [-16.055971145999877, 17.649860382000156],
                [-16.055694579999908, 17.649860382000156],
                [-16.055694579999908, 17.651525498000069],
                [-16.055416106999871, 17.651525498000069],
                [-16.055416106999871, 17.652917863000084],
                [-16.055141448999905, 17.652917863000084],
                [-16.055141448999905, 17.655416488000128],
                [-16.054859160999968, 17.655416488000128],
                [-16.054859160999968, 17.657638549000012],
                [-16.054582595999932, 17.657638549000012],
                [-16.054582595999932, 17.65902710000006],
                [-16.054306029999964, 17.65902710000006],
                [-16.054306029999964, 17.659582138000189],
                [-16.054027556999927, 17.659582138000189],
                [-16.054027556999927, 17.660972596000136],
                [-16.053747176999934, 17.660972596000136],
                [-16.053747176999934, 17.663196565000192],
                [-16.053472518999911, 17.663196565000192],
                [-16.053472518999911, 17.664026261000117],
                [-16.053194045999874, 17.664026261000117],
                [-16.053194045999874, 17.666805266999972],
                [-16.052917479999905, 17.666805266999972],
                [-16.052917479999905, 17.668193817000144],
                [-16.0526371, 17.668193817000144],
                [-16.0526371, 17.669860840000126],
                [-16.052360533999945, 17.669860840000126],
                [-16.052360533999945, 17.671249390000071],
                [-16.052083968999966, 17.671249390000071],
                [-16.052083968999966, 17.671806336000145],
                [-16.051805495999929, 17.671806336000145],
                [-16.051805495999929, 17.67319488600009],
                [-16.05152511499989, 17.67319488600009],
                [-16.05152511499989, 17.67652511700004],
                [-16.051250456999924, 17.67652511700004],
                [-16.051250456999924, 17.676807403000169],
                [-16.050971983999887, 17.676807403000169],
                [-16.050971983999887, 17.677085875999978],
                [-16.050693510999849, 17.677085875999978],
                [-16.050693510999849, 17.678195953000113],
                [-16.050416945999871, 17.678195953000113],
                [-16.050416945999871, 17.682361603000174],
                [-16.050138472999947, 17.682361603000174],
                [-16.050138472999947, 17.683750153000119],
                [-16.049861906999979, 17.683750153000119],
                [-16.049861906999979, 17.685972214],
                [-16.049583433999942, 17.685972214],
                [-16.049583433999942, 17.68874931400012],
                [-16.049306868999963, 17.68874931400012],
                [-16.049306868999963, 17.691249847000165],
                [-16.049028395999926, 17.691249847000165],
                [-16.049028395999926, 17.692636489000165],
                [-16.048749922999889, 17.692636489000165],
                [-16.048749922999889, 17.694028855000056],
                [-16.048471451999944, 17.694028855000056],
                [-16.048471451999944, 17.696250916000167],
                [-16.048194883999884, 17.696250916000167],
                [-16.048194883999884, 17.697362901000076],
                [-16.047916412999939, 17.697362901000076],
                [-16.047916412999939, 17.700138093000191],
                [-16.047361374999923, 17.700138093000191],
                [-16.047361374999923, 17.700416566],
                [-16.047084808999955, 17.700416566],
                [-16.047084808999955, 17.700693131999969],
                [-16.046806335999918, 17.700693131999969],
                [-16.046806335999918, 17.701528549000102],
                [-16.047084808999955, 17.701528549000102],
                [-16.047084808999955, 17.703472137000176],
                [-16.047361374999923, 17.703472137000176],
                [-16.047361374999923, 17.70402526800018],
                [-16.047084808999955, 17.70402526800018],
                [-16.047084808999955, 17.707916260000047],
                [-16.046806335999918, 17.707916260000047],
                [-16.046806335999918, 17.710695268000165],
                [-16.046527862999881, 17.710695268000165],
                [-16.046527862999881, 17.712083816000131],
                [-16.046249389999957, 17.712083816000131],
                [-16.046249389999957, 17.713193893000039],
                [-16.046527862999881, 17.713193893000039],
                [-16.046527862999881, 17.713472366000076],
                [-16.046249389999957, 17.713472366000076],
                [-16.046249389999957, 17.714027404000149],
                [-16.045974731999934, 17.714027404000149],
                [-16.045974731999934, 17.71569442700013],
                [-16.045694351999941, 17.71569442700013],
                [-16.045694351999941, 17.717918396000073],
                [-16.044862747999957, 17.717918396000073],
                [-16.044862747999957, 17.717636108000136],
                [-16.044305801999883, 17.717636108000136],
                [-16.044305801999883, 17.717918396000073],
                [-16.043470382999942, 17.717918396000073],
                [-16.043470382999942, 17.717636108000136],
                [-16.04264068599997, 17.717636108000136],
                [-16.04264068599997, 17.716249465999965],
                [-16.042083739999953, 17.716249465999965],
                [-16.042083739999953, 17.717082977000132],
                [-16.041805266999916, 17.717082977000132],
                [-16.041805266999916, 17.719306946000188],
                [-16.041530608999949, 17.719306946000188],
                [-16.041530608999949, 17.719583512000156],
                [-16.041248320999955, 17.719583512000156],
                [-16.041248320999955, 17.719861985000023],
                [-16.040416716999971, 17.719861985000023],
                [-16.040416716999971, 17.720136642999989],
                [-16.039861678999955, 17.720136642999989],
                [-16.039861678999955, 17.720693589000064],
                [-16.040695189999838, 17.720693589000064],
                [-16.040695189999838, 17.7215271],
                [-16.042083739999953, 17.7215271],
                [-16.042083739999953, 17.72180557300004],
                [-16.041805266999916, 17.72180557300004],
                [-16.041805266999916, 17.722360612000045],
                [-16.04264068599997, 17.722360612000045],
                [-16.04264068599997, 17.722084046000077],
                [-16.042917251999938, 17.722084046000077],
                [-16.042917251999938, 17.720972062000101],
                [-16.041805266999916, 17.720972062000101],
                [-16.041805266999916, 17.720693589000064],
                [-16.042083739999953, 17.720693589000064],
                [-16.042083739999953, 17.720418931000097],
                [-16.042358397999976, 17.720418931000097],
                [-16.042358397999976, 17.720136642999989],
                [-16.043752670999936, 17.720136642999989],
                [-16.043752670999936, 17.720418931000097],
                [-16.044027328999846, 17.720418931000097],
                [-16.044027328999846, 17.721250535000138],
                [-16.044305801999883, 17.721250535000138],
                [-16.044305801999883, 17.722639085000083],
                [-16.044580459999963, 17.722639085000083],
                [-16.04458427499992, 17.722917556000141],
                [-16.044862747999957, 17.722917556000141],
                [-16.044862747999957, 17.724306106000085],
                [-16.045139312999936, 17.724306106000085],
                [-16.045139312999936, 17.725139618000128],
                [-16.045415878999961, 17.725139618000128],
                [-16.045415878999961, 17.725971222000055],
                [-16.044862747999957, 17.725971222000055],
                [-16.044862747999957, 17.725694656000087],
                [-16.044305801999883, 17.725694656000087],
                [-16.044305801999883, 17.725139618000128],
                [-16.044027328999846, 17.725139618000128],
                [-16.044027328999846, 17.723749160000011],
                [-16.043752670999936, 17.723749160000011],
                [-16.043752670999936, 17.723472595000146],
                [-16.042917251999938, 17.723472595000146],
                [-16.042917251999938, 17.723749160000011],
                [-16.04264068599997, 17.723749160000011],
                [-16.04264068599997, 17.724306106000085],
                [-16.042358397999976, 17.724306106000085],
                [-16.042358397999976, 17.724582672000054],
                [-16.042083739999953, 17.724582672000054],
                [-16.042083739999953, 17.725694656000087],
                [-16.042358397999976, 17.725694656000087],
                [-16.042358397999976, 17.725971222000055],
                [-16.04264068599997, 17.725971222000055],
                [-16.04264068599997, 17.726249695000092],
                [-16.043470382999942, 17.726249695000092],
                [-16.043470382999942, 17.726528168000129],
                [-16.043752670999936, 17.726528168000129],
                [-16.043752670999936, 17.726804733000165],
                [-16.044580459999963, 17.726804733000165],
                [-16.04458427499992, 17.727638245000037],
                [-16.044862747999957, 17.727638245000037],
                [-16.044862747999957, 17.72819328300011],
                [-16.045139312999936, 17.72819328300011],
                [-16.045139312999936, 17.729028702000051],
                [-16.045415878999961, 17.729028702000051],
                [-16.045415878999961, 17.730138779000129],
                [-16.045139312999936, 17.730138779000129],
                [-16.045139312999936, 17.730693818000134],
                [-16.04458427499992, 17.730693818000134],
                [-16.044580459999963, 17.730972291],
                [-16.044027328999846, 17.730972291],
                [-16.044027328999846, 17.731250764000038],
                [-16.043193816999974, 17.731250764000038],
                [-16.043193816999974, 17.732639314000039],
                [-16.043470382999942, 17.732639314000039],
                [-16.043470382999942, 17.732360841000173],
                [-16.043752670999936, 17.732360841000173],
                [-16.043752670999936, 17.732084275000034],
                [-16.044027328999846, 17.732084275000034],
                [-16.044027328999846, 17.731807710000112],
                [-16.04458427499992, 17.731807710000112],
                [-16.04458427499992, 17.731525422000061],
                [-16.044862747999957, 17.731525422000061],
                [-16.044862747999957, 17.732639314000039],
                [-16.04458427499992, 17.732639314000039],
                [-16.044580459999963, 17.734582902000056],
                [-16.044305801999883, 17.734582902000056],
                [-16.044305801999883, 17.73541641200012],
                [-16.045139312999936, 17.73541641200012],
                [-16.045139312999936, 17.739027023000176],
                [-16.044862747999957, 17.739027023000176],
                [-16.044862747999957, 17.739305496000043],
                [-16.044027328999846, 17.739305496000043],
                [-16.044027328999846, 17.739582062000181],
                [-16.043752670999936, 17.739582062000181],
                [-16.043752670999936, 17.739305496000043],
                [-16.043470382999942, 17.739305496000043],
                [-16.043470382999942, 17.739027023000176],
                [-16.042917251999938, 17.739027023000176],
                [-16.042917251999938, 17.738473892000115],
                [-16.04264068599997, 17.738473892000115],
                [-16.04264068599997, 17.737916946000041],
                [-16.042358397999976, 17.737916946000041],
                [-16.042358397999976, 17.737361908000139],
                [-16.042083739999953, 17.737361908000139],
                [-16.042083739999953, 17.737083435000102],
                [-16.041248320999955, 17.737083435000102],
                [-16.041248320999955, 17.739860535000048],
                [-16.040971755999976, 17.739860535000048],
                [-16.040971755999976, 17.740139008000085],
                [-16.041530608999949, 17.740139008000085],
                [-16.041530608999949, 17.740417481000122],
                [-16.043193816999974, 17.740417481000122],
                [-16.043193816999974, 17.740692139000089],
                [-16.044305801999883, 17.740692139000089],
                [-16.044305801999883, 17.743232728000066],
                [-16.044305801999883, 17.748750686000108],
                [-16.044603347999896, 17.748750686000108],
                [-16.044580459999963, 17.754583359000151],
                [-16.044305801999883, 17.754583359000151],
                [-16.044305801999883, 17.754861832000188],
                [-16.044027328999846, 17.754861832000188],
                [-16.044027328999846, 17.754583359000151],
                [-16.043470382999942, 17.754583359000151],
                [-16.043470382999942, 17.756526948000101],
                [-16.043193816999974, 17.756526948000101],
                [-16.043193816999974, 17.757638931000031],
                [-16.043752670999936, 17.757638931000031],
                [-16.043752670999936, 17.7570858],
                [-16.044027328999846, 17.7570858],
                [-16.044027328999846, 17.756250383000065],
                [-16.044305801999883, 17.756250383000065],
                [-16.044305801999883, 17.755416871000023],
                [-16.044580459999963, 17.755416871000023],
                [-16.044580459999963, 17.760694504000128],
                [-16.043752670999936, 17.760694504000128],
                [-16.043752670999936, 17.76097297699999],
                [-16.043193816999974, 17.76097297699999],
                [-16.043193816999974, 17.761249542000087],
                [-16.042917251999938, 17.761249542000087],
                [-16.042917251999938, 17.762361527000166],
                [-16.043193816999974, 17.762361527000166],
                [-16.043193816999974, 17.762638092000032],
                [-16.044027328999846, 17.762638092000032],
                [-16.044027328999846, 17.762916565000069],
                [-16.044305801999883, 17.762916565000069],
                [-16.044305801999883, 17.763195038000106],
                [-16.044580459999963, 17.763195038000106],
                [-16.04458427499992, 17.76458358800005],
                [-16.044862747999957, 17.76458358800005],
                [-16.044862747999957, 17.76708221500013],
                [-16.04458427499992, 17.76708221500013],
                [-16.044580459999963, 17.767360688000167],
                [-16.043752670999936, 17.767360688000167],
                [-16.043752670999936, 17.767639161000034],
                [-16.043470382999942, 17.767639161000034],
                [-16.043470382999942, 17.770414352000046],
                [-16.043193816999974, 17.770414352000046],
                [-16.043193816999974, 17.774583817000121],
                [-16.042917251999938, 17.774583817000121],
                [-16.042917251999938, 17.78152847199999],
                [-16.043193816999974, 17.78152847199999],
                [-16.043193816999974, 17.781650543000069],
                [-16.043193816999974, 17.785148622000065],
                [-16.043470382999942, 17.785137176000148],
                [-16.043470382999942, 17.786247253000056],
                [-16.043224333999945, 17.786245345999987],
                [-16.043193816999974, 17.787639619000174],
                [-16.043388365999931, 17.787639619000174],
                [-16.043470382999942, 17.787639619000174],
                [-16.043470382999942, 17.788387299000021],
                [-16.043470382999942, 17.789304734000041],
                [-16.04357528699984, 17.789304734000041],
                [-16.043752670999936, 17.789304734000041],
                [-16.043752670999936, 17.789583207000078],
                [-16.044027328999846, 17.789583207000078],
                [-16.044027328999846, 17.79041671900012],
                [-16.044305801999883, 17.79041671900012],
                [-16.044305801999883, 17.793472290000068],
                [-16.044055937999815, 17.793468476000101],
                [-16.044027328999846, 17.794582367000146],
                [-16.043752670999936, 17.794582367000146],
                [-16.043752670999936, 17.798471451000069],
                [-16.043470382999942, 17.798471451000069],
                [-16.043470382999942, 17.800971986000093],
                [-16.043193816999974, 17.800971986000093],
                [-16.043193816999974, 17.802083970000069],
                [-16.042917251999938, 17.802083970000069],
                [-16.042917251999938, 17.802917480000133],
                [-16.04264068599997, 17.802917480000133],
                [-16.04264068599997, 17.805139541000017],
                [-16.042358397999976, 17.805139541000017],
                [-16.042358397999976, 17.805971145000115],
                [-16.04264068599997, 17.805971145000115],
                [-16.04264068599997, 17.806249618000152],
                [-16.042358397999976, 17.806249618000152],
                [-16.042358397999976, 17.807361603000061],
                [-16.04264068599997, 17.807361603000061],
                [-16.04264068599997, 17.809583664000115],
                [-16.042917251999938, 17.809583664000115],
                [-16.042917251999938, 17.811525345000121],
                [-16.04264068599997, 17.811525345000121],
                [-16.04264068599997, 17.811807633],
                [-16.042358397999976, 17.811807633],
                [-16.042358397999976, 17.81263733000003],
                [-16.042083739999953, 17.81263733000003],
                [-16.042083739999953, 17.814582826000048],
                [-16.042358397999976, 17.814582826000048],
                [-16.042358397999976, 17.817916870000033],
                [-16.04264068599997, 17.817916870000033],
                [-16.04264068599997, 17.81819534300007],
                [-16.042917251999938, 17.81819534300007],
                [-16.042917251999938, 17.819026947000168],
                [-16.04264068599997, 17.819026947000168],
                [-16.04264068599997, 17.819860458000107],
                [-16.042917251999938, 17.819860458000107],
                [-16.042917251999938, 17.821250916000054],
                [-16.043193816999974, 17.821250916000054],
                [-16.043193816999974, 17.821804047000057],
                [-16.043102264999959, 17.821804047000057],
                [-16.042917251999938, 17.821804047000057],
                [-16.042917251999938, 17.822360993000132],
                [-16.042358397999976, 17.822360993000132],
                [-16.042358397999976, 17.822639466000169],
                [-16.042083739999953, 17.822639466000169],
                [-16.042083739999953, 17.823194505000174],
                [-16.041805266999916, 17.823194505000174],
                [-16.041805266999916, 17.824026109000044],
                [-16.041530608999949, 17.824026109000044],
                [-16.041530608999949, 17.826248170000156],
                [-16.041248320999955, 17.826248170000156],
                [-16.041248320999955, 17.828193664000025],
                [-16.040971755999976, 17.828193664000025],
                [-16.040971755999976, 17.833749771999976],
                [-16.040695189999838, 17.833749771999976],
                [-16.040695189999838, 17.83569336000005],
                [-16.040971755999976, 17.83569336000005],
                [-16.040971755999976, 17.835971833000087],
                [-16.041452407999941, 17.835971833000087],
                [-16.041530608999949, 17.835971833000087],
                [-16.041530608999949, 17.838472366000133],
                [-16.041248320999955, 17.838472366000133],
                [-16.041248320999955, 17.841247558000077],
                [-16.040971755999976, 17.841247558000077],
                [-16.040971755999976, 17.841806412000096],
                [-16.040695189999838, 17.841806412000096],
                [-16.040695189999838, 17.843751908000172],
                [-16.040971755999976, 17.843751908000172],
                [-16.040971755999976, 17.844583512000042],
                [-16.040695189999838, 17.844583512000042],
                [-16.040695189999838, 17.844305039000176],
                [-16.039968490999911, 17.844305039000176],
                [-16.039861678999955, 17.844305039000176],
                [-16.039915085999951, 17.844591140000148],
                [-16.039583205999918, 17.844583512000042],
                [-16.039583205999918, 17.846475601000179],
                [-16.039583205999918, 17.855417252000052],
                [-16.039304732999881, 17.855417252000052],
                [-16.039304732999881, 17.857082368000022],
                [-16.039026259999957, 17.857084275000091],
                [-16.039026259999957, 17.857915879000132],
                [-16.038473128999897, 17.857915879000132],
                [-16.038473128999897, 17.858194352000169],
                [-16.038194655999973, 17.858194352000169],
                [-16.038194655999973, 17.859306337000078],
                [-16.038473128999897, 17.859306337000078],
                [-16.038473128999897, 17.859582902000113],
                [-16.038749693999875, 17.859582902000113],
                [-16.038749693999875, 17.860416412000177],
                [-16.038473128999897, 17.860416412000177],
                [-16.038473128999897, 17.86513710000014],
                [-16.038749693999875, 17.86513710000014],
                [-16.038749693999875, 17.867916108000031],
                [-16.038473128999897, 17.867916108000031],
                [-16.038473128999897, 17.869028092],
                [-16.038194655999973, 17.869028092],
                [-16.038194655999973, 17.870971681000185],
                [-16.037916182999936, 17.870971681000185],
                [-16.037916182999936, 17.872083663000183],
                [-16.038194655999973, 17.872083663000183],
                [-16.038194655999973, 17.872917175000055],
                [-16.037916182999936, 17.872917175000055],
                [-16.037916182999936, 17.873472213000127],
                [-16.037639616999968, 17.873472213000127],
                [-16.037639616999968, 17.874860763000072],
                [-16.037916182999936, 17.874860763000072],
                [-16.037916182999936, 17.875139236000109],
                [-16.038194655999973, 17.875139236000109],
                [-16.038194655999973, 17.876527786000054],
                [-16.037916182999936, 17.876527786000054],
                [-16.037916182999936, 17.876804352000022],
                [-16.037639616999968, 17.876804352000022],
                [-16.037639616999968, 17.877639771000133],
                [-16.037916182999936, 17.877639771000133],
                [-16.037916182999936, 17.87930488600017],
                [-16.037639616999968, 17.87930488600017],
                [-16.037639616999968, 17.880136490000041],
                [-16.037361143999931, 17.880136490000041],
                [-16.037361143999931, 17.880695344000117],
                [-16.037082672999873, 17.880695344000117],
                [-16.037082672999873, 17.882360459000154],
                [-16.036806105999915, 17.882360459000154],
                [-16.036806105999915, 17.885694504000014],
                [-16.03652763499997, 17.885694504000014],
                [-16.036540984999931, 17.886528015000181],
                [-16.036806105999915, 17.886528015000181],
                [-16.036806105999915, 17.886804581000149],
                [-16.03652763499997, 17.886806488000047],
                [-16.03652763499997, 17.889028550000035],
                [-16.036249161999933, 17.889028550000035],
                [-16.036249161999933, 17.892915727000059],
                [-16.03652763499997, 17.892919540000094],
                [-16.03652763499997, 17.894582750000041],
                [-16.036249161999933, 17.894582750000041],
                [-16.036249161999933, 17.896251678000112],
                [-16.035972595999965, 17.896251678000112],
                [-16.035972595999965, 17.89930534399997],
                [-16.035694122999928, 17.89930534399997],
                [-16.035694122999928, 17.902360917000124],
                [-16.035417557999949, 17.902360917000124],
                [-16.035417557999949, 17.904027940000105],
                [-16.035694122999928, 17.904027940000105],
                [-16.035694122999928, 17.904304504999971],
                [-16.035972595999965, 17.904304504999971],
                [-16.035972595999965, 17.906526564999979],
                [-16.035694122999928, 17.906526564999979],
                [-16.035694122999928, 17.909305572000164],
                [-16.035417557999949, 17.909305572000164],
                [-16.035417557999949, 17.910694122000109],
                [-16.035139084999912, 17.910694122000109],
                [-16.035139084999912, 17.911806107000189],
                [-16.035417557999949, 17.911806107000189],
                [-16.035417557999949, 17.912916184000096],
                [-16.035694122999928, 17.912916184000096],
                [-16.035694122999928, 17.913471222000055],
                [-16.035417557999949, 17.913471222000055],
                [-16.035417557999949, 17.913749695000092],
                [-16.035139084999912, 17.913749695000092],
                [-16.035139084999912, 17.914304734000098],
                [-16.034860611999875, 17.914304734000098],
                [-16.034860611999875, 17.915138245000037],
                [-16.034585953999908, 17.915138245000037],
                [-16.034585953999908, 17.915971757000079],
                [-16.03430557299987, 17.915971757000079],
                [-16.03430557299987, 17.917360307000024],
                [-16.034027099999946, 17.917360307000024],
                [-16.034027099999946, 17.919307709000066],
                [-16.033750534999967, 17.919307709000066],
                [-16.033750534999967, 17.924304963000168],
                [-16.03347206199993, 17.924304963000168],
                [-16.03347206199993, 17.927360536000094],
                [-16.032917022999925, 17.927360536000094],
                [-16.032917022999925, 17.927639009000131],
                [-16.032638549999888, 17.927639009000131],
                [-16.032638549999888, 17.928472518000092],
                [-16.032363891999978, 17.928472518000092],
                [-16.032363891999978, 17.92930412200019],
                [-16.032083511999872, 17.92930412200019],
                [-16.032083511999872, 17.929861068000093],
                [-16.031805037999959, 17.929861068000093],
                [-16.031805037999959, 17.930694580000136],
                [-16.03152847299998, 17.930694580000136],
                [-16.03152847299998, 17.932361603000118],
                [-16.031251907999888, 17.932361603000118],
                [-16.031251907999888, 17.934305191000135],
                [-16.030973434999964, 17.934305191000135],
                [-16.030973434999964, 17.935693741000136],
                [-16.030694961999927, 17.935728073000121],
                [-16.030694961999927, 17.936519623000038],
                [-16.030973434999964, 17.936527253000179],
                [-16.030973434999964, 17.937639237000155],
                [-16.030694961999927, 17.937639237000155],
                [-16.030694961999927, 17.940694808000103],
                [-16.030416487999958, 17.940694808000103],
                [-16.030416487999958, 17.94291687000009],
                [-16.029859541999883, 17.94291687000009],
                [-16.029859541999883, 17.943069459000014],
                [-16.029859541999883, 17.943195343000127],
                [-16.02958106899996, 17.943195343000127],
                [-16.02958106899996, 17.943750381000086],
                [-16.029785155999946, 17.943750381000086],
                [-16.029859541999883, 17.943750381000086],
                [-16.029859541999883, 17.94541549700017],
                [-16.029594421999889, 17.94541549700017],
                [-16.02930641099988, 17.94541549700017],
                [-16.02930641099988, 17.945693970000036],
                [-16.029029845999901, 17.945693970000036],
                [-16.029029845999901, 17.947082520000151],
                [-16.028749464999976, 17.947082520000151],
                [-16.028749464999976, 17.947639466000055],
                [-16.029029845999901, 17.947639466000055],
                [-16.029090880999888, 17.949869156000148],
                [-16.028749464999976, 17.949861527000166],
                [-16.028749464999976, 17.952091217000088],
                [-16.0284729, 17.952083587000175],
                [-16.0284729, 17.95291709899999],
                [-16.02819442699996, 17.95291709899999],
                [-16.02819442699996, 17.953472137000119],
                [-16.027915953999923, 17.953472137000119],
                [-16.027915953999923, 17.954305649000162],
                [-16.027637480999886, 17.954305649000162],
                [-16.027637480999886, 17.955139160000101],
                [-16.027360914999917, 17.955139160000101],
                [-16.027360914999917, 17.956527710000046],
                [-16.02708244199988, 17.956527710000046],
                [-16.02708244199988, 17.95874977200009],
                [-16.026805876999845, 17.95874977200009],
                [-16.026805876999845, 17.960695268000109],
                [-16.026527403999978, 17.960695268000109],
                [-16.026527403999978, 17.961250306000181],
                [-16.026250837999839, 17.961250306000181],
                [-16.026250837999839, 17.962083818000053],
                [-16.025972364999973, 17.962083818000053],
                [-16.025972364999973, 17.962640762000149],
                [-16.025693893999915, 17.962640762000149],
                [-16.025693893999915, 17.962917327000184],
                [-16.025417326999957, 17.962917327000184],
                [-16.025417326999957, 17.964027404000092],
                [-16.025693893999915, 17.964027404000092],
                [-16.025693893999915, 17.964862823000033],
                [-16.025417326999957, 17.964862823000033],
                [-16.025417326999957, 17.965972900000168],
                [-16.025138855999955, 17.965972900000168],
                [-16.025138855999955, 17.966527939000173],
                [-16.024860382999975, 17.966527939000173],
                [-16.024860382999975, 17.96708488500002],
                [-16.02458381699995, 17.96708488500002],
                [-16.02458381699995, 17.968193054000153],
                [-16.02430534399997, 17.968193054000153],
                [-16.02430534399997, 17.970972062000044],
                [-16.024026870999933, 17.970972062000044],
                [-16.024026870999933, 17.971525193000105],
                [-16.023750305999954, 17.971525193000105],
                [-16.023750305999954, 17.972637177000081],
                [-16.023471832999917, 17.972637177000081],
                [-16.023471832999917, 17.97402954000006],
                [-16.023195266999949, 17.97402954000006],
                [-16.023195266999949, 17.974861144000158],
                [-16.022916793999912, 17.974861144000158],
                [-16.022916793999912, 17.97569465600003],
                [-16.022638320999874, 17.97569465600003],
                [-16.022638320999874, 17.977083206000145],
                [-16.022361755999839, 17.977083206000145],
                [-16.022361755999839, 17.977638244000048],
                [-16.022083282999972, 17.977638244000048],
                [-16.022083282999972, 17.978195190000122],
                [-16.021804809999935, 17.978195190000122],
                [-16.021804809999935, 17.978750229000127],
                [-16.021528243999967, 17.978750229000127],
                [-16.021528243999967, 17.979860306000091],
                [-16.02124977099993, 17.979860306000091],
                [-16.02124977099993, 17.980417252000166],
                [-16.020971297999893, 17.980417252000166],
                [-16.020971297999893, 17.981250763000105],
                [-16.020694732999914, 17.981250763000105],
                [-16.020694732999914, 17.981527329000073],
                [-16.020416259999877, 17.981527329000073],
                [-16.020416259999877, 17.982084275000147],
                [-16.020694732999914, 17.982084275000147],
                [-16.020694732999914, 17.982360840000183],
                [-16.02124977099993, 17.982360840000183],
                [-16.02124977099993, 17.982915879000188],
                [-16.021528243999967, 17.982915879000188],
                [-16.021528243999967, 17.983472825000092],
                [-16.021804809999935, 17.983472825000092],
                [-16.021804809999935, 17.985137941],
                [-16.022083282999972, 17.985139846000095],
                [-16.022083282999972, 17.985416414000042],
                [-16.022361755999839, 17.985416414000042],
                [-16.022361755999839, 17.985971449999965],
                [-16.022638320999874, 17.985971449999965],
                [-16.022638320999874, 17.986249923],
                [-16.022916793999912, 17.986249923],
                [-16.022916793999912, 17.986526488999971],
                [-16.023195266999949, 17.986526488999971],
                [-16.023195266999949, 17.986804962],
                [-16.02430534399997, 17.986804962],
                [-16.02430534399997, 17.987083435000045],
                [-16.02458381699995, 17.987083435000045],
                [-16.02458381699995, 17.987361908000082],
                [-16.024860382999975, 17.987361908000082],
                [-16.024860382999975, 17.987909318000106],
                [-16.025138855999955, 17.987918854000156],
                [-16.025138855999955, 17.98819351200018],
                [-16.025417326999957, 17.988195419000022],
                [-16.025417326999957, 17.98847198499999],
                [-16.025693893999915, 17.98847198499999],
                [-16.025693893999915, 17.989305496000156],
                [-16.025972364999973, 17.989305496000156],
                [-16.025972364999973, 17.989860535000162],
                [-16.026250837999839, 17.989860535000162],
                [-16.026250837999839, 17.990694046000101],
                [-16.026527403999978, 17.990694046000101],
                [-16.026527403999978, 17.991249085000106],
                [-16.026805876999845, 17.991249085000106],
                [-16.026805876999845, 17.991527558000143],
                [-16.031805037999959, 17.991527558000143],
                [-16.031805037999959, 17.991249085000106],
                [-16.03430557299987, 17.991249085000106],
                [-16.03430557299987, 17.990972519000138],
                [-16.035139084999912, 17.990972519000138],
                [-16.035139084999912, 17.990694046000101],
                [-16.035417557999949, 17.990694046000101],
                [-16.035417557999949, 17.990417481000065],
                [-16.035694122999928, 17.990417481000065],
                [-16.035694122999928, 17.989860535000162],
                [-16.035972595999965, 17.989860535000162],
                [-16.035972595999965, 17.989583969000023],
                [-16.036249161999933, 17.989583969000023],
                [-16.036249161999933, 17.989028931000064],
                [-16.03652763499997, 17.989028931000064],
                [-16.03652763499997, 17.988750458000027],
                [-16.037082672999873, 17.988750458000027],
                [-16.037082672999873, 17.98847198499999],
                [-16.037361143999931, 17.98847198499999],
                [-16.037361143999931, 17.988195419000022],
                [-16.037916182999936, 17.988195419000022],
                [-16.037916182999936, 17.988750458000027],
                [-16.037639616999968, 17.988750458000027],
                [-16.037639616999968, 17.989028931000064],
                [-16.037361143999931, 17.989028931000064],
                [-16.037361143999931, 17.989860535000162],
                [-16.037639616999968, 17.989860535000162],
                [-16.037639616999968, 17.990417481000065],
                [-16.037361143999931, 17.990417481000065],
                [-16.037361143999931, 17.990694046000101],
                [-16.03652763499997, 17.990694046000101],
                [-16.03652763499997, 17.990972519000138],
                [-16.035972595999965, 17.990972519000138],
                [-16.035972595999965, 17.991249085000106],
                [-16.035417557999949, 17.991249085000106],
                [-16.035417557999949, 17.991527558000143],
                [-16.035139084999912, 17.991527558000143],
                [-16.035139084999912, 17.991806031000181],
                [-16.034860611999875, 17.991806031000181],
                [-16.034860611999875, 17.992082596000046],
                [-16.03430557299987, 17.992082596000046],
                [-16.03430557299987, 17.992361069000083],
                [-16.033750534999967, 17.992361069000083],
                [-16.033750534999967, 17.99263954200012],
                [-16.033193588999893, 17.99263954200012],
                [-16.033193588999893, 17.992916108000088],
                [-16.02958106899996, 17.992916108000088],
                [-16.02958106899996, 17.993194581000125],
                [-16.02930641099988, 17.993194581000125],
                [-16.02930641099988, 17.993749619000084],
                [-16.0284729, 17.993749619000084],
                [-16.0284729, 17.994583131000127],
                [-16.02819442699996, 17.994583131000127],
                [-16.02819442699996, 17.994861604000164],
                [-16.027915953999923, 17.994861604000164],
                [-16.027915953999923, 17.996250154000109],
                [-16.027637480999886, 17.996250154000109],
                [-16.027637480999886, 17.997083663000069],
                [-16.027360914999917, 17.997083663000069],
                [-16.027360914999917, 17.997638702000074],
                [-16.02708244199988, 17.997638702000074],
                [-16.02708244199988, 17.998472213000184],
                [-16.026805876999845, 17.998472213000184],
                [-16.026805876999845, 17.998750686000051],
                [-16.026527403999978, 17.998750686000051],
                [-16.026527403999978, 17.999025344000017],
                [-16.026250837999839, 17.999025344000017],
                [-16.026250837999839, 17.999584198000093],
                [-16.025417326999957, 17.999584198000093],
                [-16.025417326999957, 17.999860763000129],
                [-16.02430534399997, 17.999860763000129],
                [-16.02430534399997, 18.004304886000057],
                [-16.024026870999933, 18.004304886000057],
                [-16.024026870999933, 18.00597381700004],
                [-16.023750305999954, 18.005971909000039],
                [-16.023750305999954, 18.016805649999981],
                [-16.023471832999917, 18.016805649999981],
                [-16.023471832999917, 18.025138855000023],
                [-16.023750305999954, 18.025138855000023],
                [-16.023750305999954, 18.026250840000102],
                [-16.023471832999917, 18.026250840000102],
                [-16.023471832999917, 18.027360917000181],
                [-16.023750305999954, 18.027360917000181],
                [-16.023750305999954, 18.02874755900018],
                [-16.024026870999933, 18.02874755900018],
                [-16.024026870999933, 18.029306413000029],
                [-16.02430534399997, 18.029306413000029],
                [-16.02430534399997, 18.038473130000057],
                [-16.02458381699995, 18.038473130000057],
                [-16.02458381699995, 18.039861680000058],
                [-16.02430534399997, 18.039861680000058],
                [-16.02430534399997, 18.040695191000168],
                [-16.024026870999933, 18.040695191000168],
                [-16.024026870999933, 18.042640685000038],
                [-16.023750305999954, 18.042640685000038],
                [-16.023750305999954, 18.048471451000182],
                [-16.023494720999963, 18.048471451000182],
                [-16.023471832999917, 18.056528091000132],
                [-16.023805618999916, 18.056526184000063],
                [-16.023750305999954, 18.06097221400006],
                [-16.024026870999933, 18.06097221400006],
                [-16.024026870999933, 18.062328338000157],
                [-16.024026870999933, 18.07097244300013],
                [-16.024360656999932, 18.07097244300013],
                [-16.02430534399997, 18.073749543000019],
                [-16.024026870999933, 18.073749543000019],
                [-16.024026870999933, 18.075138093000191],
                [-16.02430534399997, 18.075138093000191],
                [-16.02430534399997, 18.082639695000012],
                [-16.02458381699995, 18.082639695000012],
                [-16.02458381699995, 18.086526872000036],
                [-16.024860382999975, 18.086526872000036],
                [-16.024860382999975, 18.089027403999978],
                [-16.025138855999955, 18.089027403999978],
                [-16.025138855999955, 18.090139389000058],
                [-16.025417326999957, 18.090139389000058],
                [-16.025417326999957, 18.091249466000022],
                [-16.025693893999915, 18.091249466000022],
                [-16.025693893999915, 18.092361450000169],
                [-16.025972364999973, 18.092361450000169],
                [-16.025972364999973, 18.093471527000077],
                [-16.026250837999839, 18.093471527000077],
                [-16.026250837999839, 18.094583512000156],
                [-16.026527403999978, 18.094583512000156],
                [-16.026527403999978, 18.095693589000064],
                [-16.026805876999845, 18.095693589000064],
                [-16.026805876999845, 18.10124969400016],
                [-16.02708244199988, 18.10124969400016],
                [-16.02708244199988, 18.102916716999971],
                [-16.027360914999917, 18.102916716999971],
                [-16.027360914999917, 18.104860306000148],
                [-16.027637480999886, 18.104860306000148],
                [-16.027637480999886, 18.10541534400005],
                [-16.027915953999923, 18.10541534400005],
                [-16.027915953999923, 18.114027023000176],
                [-16.02819442699996, 18.114027023000176],
                [-16.02819442699996, 18.118473054000049],
                [-16.0284729, 18.118473054000049],
                [-16.0284729, 18.120971681000128],
                [-16.028749464999976, 18.120971681000128],
                [-16.028749464999976, 18.127639771000133],
                [-16.029029845999901, 18.127639771000133],
                [-16.029029845999901, 18.130060196999978],
                [-16.029029845999901, 18.136806488000161],
                [-16.02930641099988, 18.136806488000161],
                [-16.02930641099988, 18.140693665000185],
                [-16.02958106899996, 18.140693665000185],
                [-16.02958106899996, 18.142915726000069],
                [-16.029859541999883, 18.142917634000071],
                [-16.029859541999883, 18.144861222000088],
                [-16.02958106899996, 18.144861222000088],
                [-16.02958106899996, 18.157638549000126],
                [-16.02930641099988, 18.157638549000126],
                [-16.02930641099988, 18.158750534000035],
                [-16.029029845999901, 18.158750534000035],
                [-16.029029845999901, 18.166429521000055],
                [-16.029029845999901, 18.168750762],
                [-16.028749464999976, 18.168750762],
                [-16.028749464999976, 18.171249389000081],
                [-16.029029845999901, 18.171249389000081],
                [-16.029029845999901, 18.172361374000161],
                [-16.02930641099988, 18.172361374000161],
                [-16.02930641099988, 18.17569351200018],
                [-16.02958106899996, 18.175695420000125],
                [-16.02958106899996, 18.179582597000149],
                [-16.029859541999883, 18.179582597000149],
                [-16.029859541999883, 18.180973053000116],
                [-16.03013801499992, 18.180973053000116],
                [-16.03013801499992, 18.183750153],
                [-16.030416487999958, 18.183750153],
                [-16.030416487999958, 18.18486023000014],
                [-16.030694961999927, 18.18486023000014],
                [-16.030694961999927, 18.18773460400007],
                [-16.030694961999927, 18.188472749000141],
                [-16.030973434999964, 18.18849373],
                [-16.030973434999964, 18.18930626000008],
                [-16.03126335099995, 18.189300538000168],
                [-16.031251907999888, 18.189859391000141],
                [-16.03152847299998, 18.189859391000141],
                [-16.03152847299998, 18.19002532899998],
                [-16.03152847299998, 18.190694808000046],
                [-16.031805037999959, 18.190694808000046],
                [-16.031805037999959, 18.190784455000085],
                [-16.031805037999959, 18.191804885000181],
                [-16.032083511999872, 18.191781999000057],
                [-16.032083511999872, 18.192638397],
                [-16.032363891999978, 18.192638397],
                [-16.032363891999978, 18.193473816000107],
                [-16.032638549999888, 18.193473816000107],
                [-16.032638549999888, 18.194305420000035],
                [-16.032917022999925, 18.194305420000035],
                [-16.032917022999925, 18.197639466],
                [-16.033193588999893, 18.197639466],
                [-16.033193588999893, 18.198472977000165],
                [-16.03347206199993, 18.198472977000165],
                [-16.03347206199993, 18.19986152700011],
                [-16.033750534999967, 18.19986152700011],
                [-16.033750534999967, 18.20180511600006],
                [-16.034027099999946, 18.20180511600006],
                [-16.034027099999946, 18.202640533000022],
                [-16.03430557299987, 18.202640533000022],
                [-16.03430557299987, 18.203193664000025],
                [-16.034585953999908, 18.203193664000025],
                [-16.034585953999908, 18.2037506100001],
                [-16.034860611999875, 18.2037506100001],
                [-16.034860611999875, 18.204584121000039],
                [-16.035139084999912, 18.204584121000039],
                [-16.035139084999912, 18.205972670999984],
                [-16.035417557999949, 18.205972670999984],
                [-16.035417557999949, 18.206249237000179],
                [-16.035694122999928, 18.206249237000179],
                [-16.035694122999928, 18.207082748000118],
                [-16.035972595999965, 18.207082748000118],
                [-16.035972595999965, 18.207639694000022],
                [-16.036249161999933, 18.207639694000022],
                [-16.036249161999933, 18.211250306000125],
                [-16.03652763499997, 18.211250306000125],
                [-16.03652763499997, 18.212083818],
                [-16.036806105999915, 18.212083818],
                [-16.036806105999915, 18.219305039],
                [-16.03652763499997, 18.219305039],
                [-16.03652763499997, 18.225139617000139],
                [-16.036806105999915, 18.225139617000139],
                [-16.036806105999915, 18.225416183000107],
                [-16.037082672999873, 18.225416183000107],
                [-16.037082672999873, 18.225973129000181],
                [-16.037361143999931, 18.225973129000181],
                [-16.037361143999931, 18.227636337000149],
                [-16.037639616999968, 18.227636337000149],
                [-16.037639616999968, 18.229583740000066],
                [-16.037361143999931, 18.229583740000066],
                [-16.037361143999931, 18.229858398000033],
                [-16.037082672999873, 18.229858398000033],
                [-16.037082672999873, 18.230140686000141],
                [-16.036806105999915, 18.230140686000141],
                [-16.036806105999915, 18.230693817000144],
                [-16.03652763499997, 18.230693817000144],
                [-16.03652763499997, 18.230972290000182],
                [-16.036249161999933, 18.230972290000182],
                [-16.036249161999933, 18.234027863000165],
                [-16.03652763499997, 18.234027863000165],
                [-16.03652763499997, 18.236528395999983],
                [-16.036806105999915, 18.236528395999983],
                [-16.036806105999915, 18.237083434000112],
                [-16.037082672999873, 18.237083434000112],
                [-16.037099838999893, 18.240972519000081],
                [-16.037361143999931, 18.240972519000081],
                [-16.037361143999931, 18.241807938000022],
                [-16.037639616999968, 18.241807938000022],
                [-16.037639616999968, 18.242082595999989],
                [-16.037916182999936, 18.242082595999989],
                [-16.037916182999936, 18.245138169000143],
                [-16.038194655999973, 18.245138169000143],
                [-16.038194655999973, 18.246805192000124],
                [-16.038473128999897, 18.246805192000124],
                [-16.038473128999897, 18.253194809000092],
                [-16.038194655999973, 18.253194809000092],
                [-16.038223266999921, 18.258193971000026],
                [-16.038473128999897, 18.258193971000026],
                [-16.038473128999897, 18.259862899000098],
                [-16.038749693999875, 18.259862899000098],
                [-16.038749693999875, 18.262638092000088],
                [-16.038618087999907, 18.262638092000088],
                [-16.038473128999897, 18.262638092000088],
                [-16.038473128999897, 18.264583588000107],
                [-16.038194655999973, 18.264583588000107],
                [-16.038194655999973, 18.27236175500002],
                [-16.037916182999936, 18.27236175500002],
                [-16.037916182999936, 18.273750305000192],
                [-16.038194655999973, 18.273750305000192],
                [-16.038194655999973, 18.275972367000179],
                [-16.038473128999897, 18.275972367000179],
                [-16.038473128999897, 18.276805878000118],
                [-16.038749693999875, 18.276805878000118],
                [-16.038749693999875, 18.281805040000052],
                [-16.039026259999957, 18.281805040000052],
                [-16.039026259999957, 18.283193588000188],
                [-16.039304732999881, 18.283193588000188],
                [-16.039304732999881, 18.284027099000127],
                [-16.039583205999918, 18.284027099000127],
                [-16.039583205999918, 18.284584045000031],
                [-16.039861678999955, 18.284584045000031],
                [-16.039861678999955, 18.285139084000036],
                [-16.040138243999934, 18.285139084000036],
                [-16.040138243999934, 18.285417557000073],
                [-16.040416716999971, 18.285417557000073],
                [-16.040416716999971, 18.285694122000109],
                [-16.040695189999838, 18.285694122000109],
                [-16.040695189999838, 18.286249161000114],
                [-16.040971755999976, 18.286249161000114],
                [-16.040971755999976, 18.286527633999981],
                [-16.041248320999955, 18.286527633999981],
                [-16.041248320999955, 18.286806107000018],
                [-16.041530608999949, 18.286806107000018],
                [-16.041530608999949, 18.287361145000148],
                [-16.041805266999916, 18.287361145000148],
                [-16.041805266999916, 18.289028168000129],
                [-16.042083739999953, 18.289028168000129],
                [-16.042083739999953, 18.289861680000172],
                [-16.042358397999976, 18.289852142000086],
                [-16.042358397999976, 18.290971757000079],
                [-16.042608261999931, 18.290977477000013],
                [-16.04264068599997, 18.291179657000043],
                [-16.04264068599997, 18.291805268000019],
                [-16.042732237999871, 18.291805268000019],
                [-16.042917251999938, 18.291805268000019],
                [-16.042858123999906, 18.292646408],
                [-16.043193816999974, 18.292638780000061],
                [-16.043161392999878, 18.294040681000183],
                [-16.043470382999942, 18.294027328000027],
                [-16.043470382999942, 18.294584274000101],
                [-16.043752670999936, 18.294584274000101],
                [-16.043752670999936, 18.295137405000162],
                [-16.044027328999846, 18.295137405000162],
                [-16.044027328999846, 18.296615601000042],
                [-16.044027328999846, 18.297639847000085],
                [-16.044364928999983, 18.297620773000119],
                [-16.044305801999883, 18.299304962000065],
                [-16.044580459999963, 18.299304962000065],
                [-16.044580459999963, 18.303195954000159],
                [-16.044305801999883, 18.303195954000159],
                [-16.044305801999883, 18.305139541000131],
                [-16.044580459999963, 18.305139541000131],
                [-16.04458427499992, 18.308748245000118],
                [-16.044862747999957, 18.308748245000118],
                [-16.044862747999957, 18.310140610000133],
                [-16.045139312999936, 18.310140610000133],
                [-16.045139312999936, 18.310693741000136],
                [-16.045415878999961, 18.310693741000136],
                [-16.045415878999961, 18.311527253000179],
                [-16.045694351999941, 18.311527253000179],
                [-16.045694351999941, 18.3140277870001],
                [-16.045974731999934, 18.3140277870001],
                [-16.045974731999934, 18.315694810000139],
                [-16.046249389999957, 18.315694810000139],
                [-16.046249389999957, 18.316528319999975],
                [-16.046527862999881, 18.316528319999975],
                [-16.046527862999881, 18.317083358000104],
                [-16.046806335999918, 18.317083358000104],
                [-16.046806335999918, 18.31763839700011],
                [-16.047084808999955, 18.31763839700011],
                [-16.047084808999955, 18.318750381000086],
                [-16.047361374999923, 18.318750381000086],
                [-16.047361374999923, 18.321527480999976],
                [-16.047637939999959, 18.321527480999976],
                [-16.047637939999959, 18.322916031000148],
                [-16.047361374999923, 18.322916031000148],
                [-16.047361374999923, 18.323751450000088],
                [-16.047084808999955, 18.323751450000088],
                [-16.047084808999955, 18.325416566000172],
                [-16.047361374999923, 18.325416566000172],
                [-16.047361374999923, 18.326250077000111],
                [-16.047637939999959, 18.326250077000111],
                [-16.047637939999959, 18.326805116000116],
                [-16.047916412999939, 18.326805116000116],
                [-16.047916412999939, 18.329860687000064],
                [-16.048194883999884, 18.329860687000064],
                [-16.048194883999884, 18.330972671000097],
                [-16.048471451999944, 18.330972671000097],
                [-16.048471451999944, 18.333194733000084],
                [-16.048749922999889, 18.333194733000084],
                [-16.048749922999889, 18.334583283000029],
                [-16.049028395999926, 18.334583283000029],
                [-16.049028395999926, 18.335416793999968],
                [-16.049306868999963, 18.335416793999968],
                [-16.049306868999963, 18.336248398000066],
                [-16.049583433999942, 18.336248398000066],
                [-16.049583433999942, 18.338752746000125],
                [-16.049861906999979, 18.338752746000125],
                [-16.049861906999979, 18.339305877000186],
                [-16.050138472999947, 18.339305877000186],
                [-16.050138472999947, 18.339862823000033],
                [-16.050416945999871, 18.339862823000033],
                [-16.050416945999871, 18.345693589000064],
                [-16.050138472999947, 18.345693589000064],
                [-16.050138472999947, 18.346250535000138],
                [-16.049861906999979, 18.346250535000138],
                [-16.049861906999979, 18.347360612000045],
                [-16.050138472999947, 18.347360612000045],
                [-16.050138472999947, 18.347637177000081],
                [-16.050416945999871, 18.347637177000081],
                [-16.050416945999871, 18.350141525000026],
                [-16.050138472999947, 18.350141525000026],
                [-16.050138472999947, 18.352083205999975],
                [-16.049583433999942, 18.352083205999975],
                [-16.049583433999942, 18.352361679000012],
                [-16.049306868999963, 18.352361679000012],
                [-16.049306868999963, 18.35763931300005],
                [-16.048749922999889, 18.35763931300005],
                [-16.048749922999889, 18.357915879000018],
                [-16.048471451999944, 18.357915879000018],
                [-16.048471451999944, 18.360971452000172],
                [-16.048749922999889, 18.360971452000172],
                [-16.048749922999889, 18.361249925000038],
                [-16.049028395999926, 18.361249925000038],
                [-16.049028395999926, 18.361526490000074],
                [-16.049306868999963, 18.361526490000074],
                [-16.049306868999963, 18.362083434000169],
                [-16.049583433999942, 18.362083434000169],
                [-16.049583433999942, 18.362638473000175],
                [-16.049861906999979, 18.362638473000175],
                [-16.049861906999979, 18.365694046000101],
                [-16.049583433999942, 18.365694046000101],
                [-16.049583433999942, 18.366527557000097],
                [-16.049306868999963, 18.366527557000097],
                [-16.049306868999963, 18.367639542000177],
                [-16.049028395999926, 18.367639542000177],
                [-16.049028395999926, 18.368473053000116],
                [-16.048749922999889, 18.368473053000116],
                [-16.048749922999889, 18.372915269000146],
                [-16.049028395999926, 18.372915269000146],
                [-16.049028395999926, 18.374860763000186],
                [-16.049306868999963, 18.374860763000186],
                [-16.049306868999963, 18.375972748000038],
                [-16.049583433999942, 18.375972748000038],
                [-16.049583433999942, 18.38624954200003],
                [-16.049861906999979, 18.38624954200003],
                [-16.049861906999979, 18.389860153000086],
                [-16.050138472999947, 18.389860153000086],
                [-16.050138472999947, 18.390300751000098],
                [-16.050138472999947, 18.391805649000105],
                [-16.050416945999871, 18.391805649000105],
                [-16.050416945999871, 18.392639161000147],
                [-16.050693510999849, 18.392639161000147],
                [-16.050693510999849, 18.395971299000166],
                [-16.050971983999887, 18.395971299000166],
                [-16.050971983999887, 18.398195266000073],
                [-16.050693510999849, 18.398195266000073],
                [-16.050693510999849, 18.401805878],
                [-16.050971983999887, 18.401805878],
                [-16.050971983999887, 18.402637482000102],
                [-16.050693510999849, 18.402637482000102],
                [-16.050693510999849, 18.408472061000111],
                [-16.050971983999887, 18.408472061000111],
                [-16.050971983999887, 18.412082672000167],
                [-16.051250456999924, 18.412082672000167],
                [-16.051250456999924, 18.419027328000084],
                [-16.05152511499989, 18.419027328000084],
                [-16.05152511499989, 18.421806335000099],
                [-16.051805495999929, 18.421806335000099],
                [-16.051805495999929, 18.428194047000147],
                [-16.052083968999966, 18.428194047000147],
                [-16.052083968999966, 18.431804656],
                [-16.052360533999945, 18.431804656],
                [-16.052360533999945, 18.435138702000188],
                [-16.0526371, 18.435138702000188],
                [-16.0526371, 18.437915802000134],
                [-16.052917479999905, 18.437915802000134],
                [-16.052917479999905, 18.439027786999986],
                [-16.053194045999874, 18.439027786999986],
                [-16.053194045999874, 18.43958282599999],
                [-16.053472518999911, 18.43958282599999],
                [-16.053472518999911, 18.439861299000029],
                [-16.053747176999934, 18.439861299000029],
                [-16.053747176999934, 18.440971376000164],
                [-16.054027556999927, 18.440971376000164],
                [-16.054027556999927, 18.442638397000167],
                [-16.054306029999964, 18.442638397000167],
                [-16.054306029999964, 18.443471908000106],
                [-16.054582595999932, 18.443471908000106],
                [-16.054582595999932, 18.444026947000111],
                [-16.054859160999968, 18.444026947000111],
                [-16.054859160999968, 18.445692062000148],
                [-16.055141448999905, 18.445692062000148],
                [-16.055141448999905, 18.446252823000066],
                [-16.055416106999871, 18.446252823000066],
                [-16.055416106999871, 18.451528550000035],
                [-16.055694579999908, 18.451528550000035],
                [-16.055694579999908, 18.455390931000125],
                [-16.055694579999908, 18.456247329000121],
                [-16.055971145999877, 18.456319809999968],
                [-16.055971145999877, 18.457082748000062],
                [-16.056251525999926, 18.457082748000062],
                [-16.056251525999926, 18.457328797000059],
                [-16.056251525999926, 18.459028244000081],
                [-16.056528091999951, 18.459028244000081],
                [-16.056528091999951, 18.461250306000068],
                [-16.05680465699993, 18.461250306000068],
                [-16.05680465699993, 18.462360383000032],
                [-16.057081222999898, 18.462360383000032],
                [-16.057081222999898, 18.463195802000143],
                [-16.057359695999935, 18.463195802000143],
                [-16.057359695999935, 18.463750840000046],
                [-16.057638168999972, 18.463750840000046],
                [-16.057638168999972, 18.465694427000187],
                [-16.057916641999839, 18.465694427000187],
                [-16.057916641999839, 18.466249465000089],
                [-16.058195114999876, 18.466249465000089],
                [-16.058195114999876, 18.466806411000164],
                [-16.058473587999913, 18.466806411000164],
                [-16.058473587999913, 18.467636107999965],
                [-16.058750152999949, 18.467636107999965],
                [-16.058750152999949, 18.469030381000152],
                [-16.059028625999929, 18.469030381000152],
                [-16.059028625999929, 18.470970154000156],
                [-16.059305191999954, 18.470970154000156],
                [-16.059305191999954, 18.472639085000139],
                [-16.059583662999898, 18.472639085000139],
                [-16.059583662999898, 18.473194123000042],
                [-16.05986023, 18.473194123000042],
                [-16.05986023, 18.474027635000084],
                [-16.060138700999914, 18.474027635000084],
                [-16.060138700999914, 18.475416185000029],
                [-16.060417173999952, 18.475416185000029],
                [-16.060417173999952, 18.475973129000124],
                [-16.060695646999932, 18.475973129000124],
                [-16.060695646999932, 18.47680473299999],
                [-16.060972212999957, 18.47680473299999],
                [-16.060972212999957, 18.477361679000069],
                [-16.061250685999937, 18.477361679000069],
                [-16.061250685999937, 18.478195190000065],
                [-16.061527250999973, 18.478195190000065],
                [-16.061527250999973, 18.47875022900007],
                [-16.061805723999839, 18.47875022900007],
                [-16.061805723999839, 18.479305267000143],
                [-16.06208419799998, 18.479305267000143],
                [-16.06208419799998, 18.479860306000148],
                [-16.06241035499994, 18.479845047000026],
                [-16.062360762999845, 18.481250763000162],
                [-16.062639235999882, 18.481250763000162],
                [-16.062639235999882, 18.482915879000075],
                [-16.062915800999917, 18.482915879000075],
                [-16.062915800999917, 18.483472824999978],
                [-16.063194273999954, 18.483472824999978],
                [-16.063194273999954, 18.484029771000053],
                [-16.063470839999923, 18.484010697000087],
                [-16.063472747999924, 18.484582902000113],
                [-16.06370925899995, 18.484594345000062],
                [-16.063781738999978, 18.484859467000149],
                [-16.06402778599994, 18.484859467000149],
                [-16.064029693999885, 18.485435487000018],
                [-16.064306258999977, 18.485416413000053],
                [-16.064306258999977, 18.486251830000185],
                [-16.064582824999945, 18.486251830000185],
                [-16.064582824999945, 18.486528398000132],
                [-16.064861297999983, 18.486528398000132],
                [-16.064861297999983, 18.486804963],
                [-16.065139770999906, 18.486804963],
                [-16.065139770999906, 18.487363815000037],
                [-16.065416335999885, 18.487363815000037],
                [-16.065416335999885, 18.488473892000172],
                [-16.065694808999922, 18.488473892000172],
                [-16.065694808999922, 18.489860534000115],
                [-16.06597137499989, 18.489860534000115],
                [-16.06597137499989, 18.490695953000056],
                [-16.066249847999927, 18.490695953000056],
                [-16.066249847999927, 18.491804123000122],
                [-16.066528320999964, 18.491804123000122],
                [-16.066528320999964, 18.492361069000026],
                [-16.066804885999943, 18.492361069000026],
                [-16.066804885999943, 18.492916107000099],
                [-16.06708335899998, 18.492916107000099],
                [-16.06708335899998, 18.493473053000173],
                [-16.067361831999904, 18.493473053000173],
                [-16.067361831999904, 18.493749619000141],
                [-16.067638397999872, 18.493749619000141],
                [-16.067638397999872, 18.49458313000008],
                [-16.067916870999909, 18.49458313000008],
                [-16.067916870999909, 18.494861603000118],
                [-16.068195343999946, 18.494861603000118],
                [-16.068195343999946, 18.495971680000025],
                [-16.068471908999925, 18.495971680000025],
                [-16.068471908999925, 18.497083665000105],
                [-16.068748474999893, 18.497083665000105],
                [-16.068748474999893, 18.49764060900003],
                [-16.06902694799993, 18.49764060900003],
                [-16.06902694799993, 18.498193742000069],
                [-16.069305420999967, 18.498193742000069],
                [-16.069305420999967, 18.49902725100003],
                [-16.069583893999834, 18.49902725100003],
                [-16.069583893999834, 18.499584197000104],
                [-16.069862366999871, 18.499584197000104],
                [-16.069862366999871, 18.501527786000054],
                [-16.070140837999816, 18.501527786000054],
                [-16.070140837999816, 18.502361298000096],
                [-16.070417404999944, 18.502361298000096],
                [-16.070417404999944, 18.502916336000169],
                [-16.070692062999967, 18.502916336000169],
                [-16.070692062999967, 18.503471375000174],
                [-16.070970535999891, 18.503471375000174],
                [-16.070970535999891, 18.504028321000078],
                [-16.071252821999963, 18.504028321000078],
                [-16.071252821999963, 18.504306794000115],
                [-16.071527479999872, 18.504306794000115],
                [-16.071527479999872, 18.504861832000017],
                [-16.07180595299991, 18.504861832000017],
                [-16.07180595299991, 18.505416871000023],
                [-16.072080612999912, 18.505416871000023],
                [-16.072080612999912, 18.506250382000189],
                [-16.072362898999927, 18.506250382000189],
                [-16.072362898999927, 18.507083894000061],
                [-16.072639464999952, 18.507083894000061],
                [-16.072639464999952, 18.507638932000134],
                [-16.072916029999931, 18.507638932000134],
                [-16.072916029999931, 18.508193971000139],
                [-16.073194502999968, 18.508193971000139],
                [-16.073194502999968, 18.508750917000043],
                [-16.073474883999893, 18.508750917000043],
                [-16.073474883999893, 18.510416030000044],
                [-16.073749541999973, 18.510416030000044],
                [-16.073749541999973, 18.51235961899999],
                [-16.074028014999897, 18.51235961899999],
                [-16.074028014999897, 18.513473510999972],
                [-16.074304579999875, 18.513473510999972],
                [-16.074306487999934, 18.514583588000107],
                [-16.074583052999913, 18.514583588000107],
                [-16.074583052999913, 18.516527176000125],
                [-16.07486152599995, 18.516527176000125],
                [-16.07486152599995, 18.517084122000028],
                [-16.075138091999918, 18.517084122000028],
                [-16.075138091999918, 18.517915726000069],
                [-16.075416564999955, 18.517917634000071],
                [-16.075416564999955, 18.518749238000112],
                [-16.075696944999891, 18.518749238000112],
                [-16.075696944999891, 18.52041626100015],
                [-16.075971602999971, 18.52041626100015],
                [-16.075971602999971, 18.52124977200009],
                [-16.076250075999837, 18.52124977200009],
                [-16.076250075999837, 18.52236175500019],
                [-16.076528548999875, 18.52236175500019],
                [-16.076528548999875, 18.523748397000134],
                [-16.076805114999843, 18.523748397000134],
                [-16.076805114999843, 18.524305343000037],
                [-16.07708358799988, 18.524305343000037],
                [-16.07708358799988, 18.524858474000041],
                [-16.077360152999915, 18.524858474000041],
                [-16.077362060999917, 18.525417328000117],
                [-16.077638625999953, 18.525417328000117],
                [-16.077638625999953, 18.525693892999982],
                [-16.077915191999921, 18.525693892999982],
                [-16.077915191999921, 18.526805878000062],
                [-16.078193664999958, 18.526805878000062],
                [-16.078193664999958, 18.527362823999965],
                [-16.078472137999938, 18.527362823999965],
                [-16.078472137999938, 18.527915955000026],
                [-16.078750610999975, 18.527915955000026],
                [-16.078750610999975, 18.528474808000169],
                [-16.07902717599984, 18.528474808000169],
                [-16.07902717599984, 18.529027939000173],
                [-16.079305648999878, 18.529027939000173],
                [-16.079305648999878, 18.529584885000077],
                [-16.079584121999915, 18.529584885000077],
                [-16.079584121999915, 18.529861451000045],
                [-16.079860687999883, 18.529861451000045],
                [-16.079860687999883, 18.530693055000086],
                [-16.080137252999918, 18.530693055000086],
                [-16.080137252999918, 18.531806947000064],
                [-16.080415725999956, 18.531806947000064],
                [-16.080415725999956, 18.532638551000105],
                [-16.080694198999936, 18.532638551000105],
                [-16.080694198999936, 18.535915375000172],
                [-16.080694198999936, 18.537359238000022],
                [-16.080972671999973, 18.537359238000022],
                [-16.080972671999973, 18.537916184000096],
                [-16.0812473299999, 18.537916184000096],
                [-16.0812473299999, 18.53847313000017],
                [-16.081527710999978, 18.53847313000017],
                [-16.081527710999978, 18.538751603000037],
                [-16.081861495999874, 18.538728714000172],
                [-16.081806183999902, 18.539304734000041],
                [-16.082098007999889, 18.539300918000094],
                [-16.082082748999881, 18.54013824499998],
                [-16.082429885999829, 18.540145875000121],
                [-16.082359314999962, 18.540695191000054],
                [-16.08267211999987, 18.540681838000125],
                [-16.082641600999921, 18.54125023000006],
                [-16.082916258999944, 18.541269303000149],
                [-16.082916258999944, 18.542360307000024],
                [-16.083194731999981, 18.542360307000024],
                [-16.083194731999981, 18.544860839000137],
                [-16.083469391999927, 18.544860839000137],
                [-16.083469391999927, 18.545139312000174],
                [-16.083751677999942, 18.545139312000174],
                [-16.083751677999942, 18.545694351000179],
                [-16.084028242999921, 18.545694351000179],
                [-16.084028242999921, 18.545972824000046],
                [-16.084304808999889, 18.545972824000046],
                [-16.084304808999889, 18.546806335000156],
                [-16.084581373999924, 18.546806335000156],
                [-16.084581373999924, 18.547361374000161],
                [-16.084863661999918, 18.547361374000161],
                [-16.084863661999918, 18.547637939000026],
                [-16.085138319999942, 18.547639847000028],
                [-16.085138319999942, 18.5481948850001],
                [-16.085416792999979, 18.5481948850001],
                [-16.085416792999979, 18.548471451000069],
                [-16.085695265999902, 18.548471451000069],
                [-16.085695265999902, 18.549028396999972],
                [-16.08597373899994, 18.549028396999972],
                [-16.08597373899994, 18.549304962000065],
                [-16.086252211999977, 18.549304962000065],
                [-16.086250304999908, 18.549583435000102],
                [-16.086368559999926, 18.549583435000102],
                [-16.086526869999886, 18.549583435000102],
                [-16.08649063099989, 18.549877167000034],
                [-16.086805342999924, 18.549861908000139],
                [-16.086805342999924, 18.550636292000036],
                [-16.086805342999924, 18.550970078000148],
                [-16.086944579999965, 18.550970078000148],
                [-16.087083815999961, 18.550970078000148],
                [-16.087062834999927, 18.551258086000189],
                [-16.086805342999924, 18.551248551000185],
                [-16.086805342999924, 18.552917481000065],
                [-16.087083815999961, 18.552917481000065],
                [-16.087083815999961, 18.553472520000071],
                [-16.087360381999929, 18.553472520000071],
                [-16.087360381999929, 18.55430603100001],
                [-16.087638854999966, 18.55430603100001],
                [-16.087638854999966, 18.555416108000145],
                [-16.087917327999889, 18.555416108000145],
                [-16.087917327999889, 18.556249617999981],
                [-16.088195800999927, 18.556249617999981],
                [-16.088195800999927, 18.556528091000018],
                [-16.088472365999905, 18.556528091000018],
                [-16.088472365999905, 18.557361602000185],
                [-16.088750838999943, 18.557361602000185],
                [-16.088750838999943, 18.559305191000135],
                [-16.089027404999911, 18.559305191000135],
                [-16.089027404999911, 18.559860229000037],
                [-16.089305877999948, 18.559860229000037],
                [-16.089305877999948, 18.561250687000154],
                [-16.089582442999927, 18.561250687000154],
                [-16.089582442999927, 18.563194275000058],
                [-16.089860915999964, 18.563194275000058],
                [-16.089860915999964, 18.563472748000095],
                [-16.09013938899983, 18.563472748000095],
                [-16.09013938899983, 18.564582825000173],
                [-16.090417861999867, 18.564582825000173],
                [-16.090417861999867, 18.567083358000048],
                [-16.090694427999836, 18.567083358000048],
                [-16.090694427999836, 18.570138930999974],
                [-16.090972900999873, 18.570138930999974],
                [-16.090972900999873, 18.570417404000011],
                [-16.091249465999908, 18.570417404000011],
                [-16.091249465999908, 18.570972443000016],
                [-16.091527938999945, 18.570972443000016],
                [-16.091527938999945, 18.571527481000146],
                [-16.091806411999983, 18.571527481000146],
                [-16.091806411999983, 18.574026108000169],
                [-16.092082977999951, 18.574026108000169],
                [-16.092082977999951, 18.575136185000076],
                [-16.092361450999931, 18.575136185000076],
                [-16.092361450999931, 18.575695039000152],
                [-16.092636108999898, 18.575695039000152],
                [-16.092636108999898, 18.576248169999985],
                [-16.092916488999833, 18.576248169999985],
                [-16.092916488999833, 18.576530458000093],
                [-16.09319496199987, 18.576530458000093],
                [-16.09319496199987, 18.57680511600006],
                [-16.093471527999839, 18.57680511600006],
                [-16.093471527999839, 18.577917100000093],
                [-16.093748092999874, 18.577917100000093],
                [-16.093748092999874, 18.580139160000044],
                [-16.094030379999879, 18.580139160000044],
                [-16.094030379999879, 18.580694198000174],
                [-16.09430503699997, 18.580694198000174],
                [-16.09430503699997, 18.58097267100004],
                [-16.094583509999893, 18.58097267100004],
                [-16.094583509999893, 18.581527710000046],
                [-16.094860075999975, 18.581527710000046],
                [-16.094860075999975, 18.581806183000083],
                [-16.095138548999898, 18.581806183000083],
                [-16.095138548999898, 18.582361221000156],
                [-16.095417021999936, 18.582361221000156],
                [-16.095417021999936, 18.582916260000161],
                [-16.095693586999914, 18.582916260000161],
                [-16.095693586999914, 18.5837497710001],
                [-16.095970152999882, 18.5837497710001],
                [-16.095970152999882, 18.584306717],
                [-16.096252440999876, 18.584306717],
                [-16.096252440999876, 18.584583282999972],
                [-16.096527098999957, 18.584583282999972],
                [-16.096527098999957, 18.585138321000102],
                [-16.096805571999937, 18.585138321000102],
                [-16.096805571999937, 18.585695267000176],
                [-16.097084044999974, 18.585695267000176],
                [-16.097084044999974, 18.586526871000046],
                [-16.097362517999898, 18.586526871000046],
                [-16.097362517999898, 18.586805344000084],
                [-16.099027633999924, 18.586805344000084],
                [-16.099027633999924, 18.587083817000121],
                [-16.09949493299996, 18.587083817000121],
                [-16.099584579999885, 18.587083817000121],
                [-16.099601744999973, 18.587360383000089],
                [-16.100139617999901, 18.587360383000089],
                [-16.100139617999901, 18.587638856000126],
                [-16.100694656999906, 18.587638856000126],
                [-16.100694656999906, 18.587917329000163],
                [-16.100973129999943, 18.587917329000163],
                [-16.100973129999943, 18.588193894000028],
                [-16.101249694999922, 18.588193894000028],
                [-16.101249694999922, 18.588472367000065],
                [-16.101528167999959, 18.588472367000065],
                [-16.101528167999959, 18.588750840000102],
                [-16.102083206999964, 18.588750840000102],
                [-16.102083206999964, 18.589305879000108],
                [-16.102361679999888, 18.589305879000108],
                [-16.102361679999888, 18.589582443999973],
                [-16.10263824499998, 18.589582443999973],
                [-16.10263824499998, 18.58986091700001],
                [-16.103195190999941, 18.58986091700001],
                [-16.103195190999941, 18.590137482999978],
                [-16.103471756999909, 18.590137482999978],
                [-16.103471756999909, 18.590415954000036],
                [-16.103750229999946, 18.590415954000036],
                [-16.103750229999946, 18.590694427000074],
                [-16.104028702999926, 18.590694427000074],
                [-16.104028702999926, 18.590972900000111],
                [-16.104305267999962, 18.590972900000111],
                [-16.104305267999962, 18.591249464999976],
                [-16.104583740999828, 18.591249464999976],
                [-16.104583740999828, 18.591529846000185],
                [-16.105138779999834, 18.591529846000185],
                [-16.105138779999834, 18.592081069000074],
                [-16.105417250999949, 18.592081069000074],
                [-16.105417250999949, 18.592916488000185],
                [-16.105693817999907, 18.592916488000185],
                [-16.105693817999907, 18.594028473000037],
                [-16.105972288999965, 18.594028473000037],
                [-16.105972288999965, 18.594583511000167],
                [-16.106250761999888, 18.594583511000167],
                [-16.106250761999888, 18.594863891999978],
                [-16.10652732799997, 18.594863891999978],
                [-16.10652732799997, 18.595138550000172],
                [-16.106805800999894, 18.595138550000172],
                [-16.106805800999894, 18.595693588000074],
                [-16.107082365999872, 18.595693588000074],
                [-16.107084273999931, 18.597360611000056],
                [-16.107360838999909, 18.597360611000056],
                [-16.107360838999909, 18.59791755700013],
                [-16.107637404999878, 18.59791755700013],
                [-16.107637404999878, 18.598194123000098],
                [-16.107915877999915, 18.598194123000098],
                [-16.107915877999915, 18.598472595999965],
                [-16.108194350999952, 18.598472595999965],
                [-16.108194350999952, 18.599306108],
                [-16.108472823999932, 18.599306108],
                [-16.108472823999932, 18.5995826730001],
                [-16.108749388999968, 18.5995826730001],
                [-16.108749388999968, 18.602083206000088],
                [-16.109027861999891, 18.602083206000088],
                [-16.109027861999891, 18.603195190000122],
                [-16.109306334999928, 18.603195190000122],
                [-16.109306334999928, 18.603748321000126],
                [-16.109582900999897, 18.603748321000126],
                [-16.109582900999897, 18.604583740000066],
                [-16.109859465999875, 18.604583740000066],
                [-16.109859465999875, 18.605140686000141],
                [-16.110137938999912, 18.605140686000141],
                [-16.110137938999912, 18.605417252000109],
                [-16.11041641199995, 18.605417252000109],
                [-16.11041641199995, 18.605970383000113],
                [-16.11069488499993, 18.605970383000113],
                [-16.11069488499993, 18.60625267100005],
                [-16.110969542999896, 18.60625267100005],
                [-16.110969542999896, 18.607084275000091],
                [-16.11125183099989, 18.607084275000091],
                [-16.11125183099989, 18.607362748000128],
                [-16.111528396999972, 18.607362748000128],
                [-16.111528396999972, 18.607915879000188],
                [-16.111803054999939, 18.607915879000188],
                [-16.111803054999939, 18.608474732000104],
                [-16.112083434999874, 18.608474732000104],
                [-16.112083434999874, 18.609306336000031],
                [-16.11236381599997, 18.609306336000031],
                [-16.11236381599997, 18.610137940000072],
                [-16.11263847399988, 18.610139848000074],
                [-16.11263847399988, 18.610971452000115],
                [-16.112916946999917, 18.610971452000115],
                [-16.112916946999917, 18.611528398000189],
                [-16.113191604999884, 18.611528398000189],
                [-16.113191604999884, 18.612083436000091],
                [-16.113473892999878, 18.612083436000091],
                [-16.113473892999878, 18.612638475000097],
                [-16.11375045799997, 18.612638475000097],
                [-16.11375045799997, 18.61319351100002],
                [-16.114027023999938, 18.613195419000022],
                [-16.114027023999938, 18.613471984000057],
                [-16.114305496999975, 18.613471984000057],
                [-16.114305496999975, 18.6143054960001],
                [-16.114585876999968, 18.6143054960001],
                [-16.114585876999968, 18.614860534],
                [-16.114860534999877, 18.614860534],
                [-16.114860534999877, 18.6151371],
                [-16.115417480999952, 18.6151371],
                [-16.115417480999952, 18.615417480000076],
                [-16.115695953999875, 18.615417480000076],
                [-16.115640639999924, 18.617668153000068],
                [-16.115972519999957, 18.617641449000189],
                [-16.115932464999844, 18.618215561000056],
                [-16.116249084999936, 18.618194580000022],
                [-16.116249084999936, 18.618471146000161],
                [-16.116527557999973, 18.618471146000161],
                [-16.116527557999973, 18.618751526000096],
                [-16.116804123999941, 18.618751526000096],
                [-16.116804123999941, 18.6193046570001],
                [-16.117082596999978, 18.6193046570001],
                [-16.117082596999978, 18.619863511000176],
                [-16.11736106799998, 18.619863511000176],
                [-16.11736106799998, 18.620138168999972],
                [-16.11763954099996, 18.620138168999972],
                [-16.11763954099996, 18.620416642],
                [-16.117916105999939, 18.620416642],
                [-16.117916105999939, 18.620695115000046],
                [-16.118194578999976, 18.620695115000046],
                [-16.118194578999976, 18.620973588000084],
                [-16.118473051999899, 18.620973588000084],
                [-16.118473051999899, 18.621528625999986],
                [-16.118749617999981, 18.621528625999986],
                [-16.118749617999981, 18.621805192000181],
                [-16.119026182999846, 18.621805192000181],
                [-16.119026182999846, 18.62208366499999],
                [-16.119304655999883, 18.62208366499999],
                [-16.119304655999883, 18.622360230000083],
                [-16.119583128999921, 18.622360230000083],
                [-16.119583128999921, 18.622638703000121],
                [-16.119861601999958, 18.622638703000121],
                [-16.119861601999958, 18.62375068599999],
                [-16.120138167999926, 18.62375068599999],
                [-16.120138167999926, 18.624860763000129],
                [-16.120416640999963, 18.624860763000129],
                [-16.120416640999963, 18.625139236000166],
                [-16.120971678999979, 18.625139236000166],
                [-16.120971678999979, 18.624860763000129],
                [-16.121248244999947, 18.624860763000129],
                [-16.121248244999947, 18.625139236000166],
                [-16.121530532999941, 18.625139236000166],
                [-16.121530532999941, 18.625694274000068],
                [-16.121805190999908, 18.625694274000068],
                [-16.121805190999908, 18.626249313000073],
                [-16.122083663999945, 18.626249313000073],
                [-16.122083663999945, 18.626806259000148],
                [-16.122358321999855, 18.626806259000148],
                [-16.122358321999855, 18.62736129700005],
                [-16.122638701999961, 18.62736129700005],
                [-16.122638701999961, 18.627639770000087],
                [-16.122917174999884, 18.627639770000087],
                [-16.122917174999884, 18.628194809000092],
                [-16.123193740999966, 18.628194809000092],
                [-16.123193740999966, 18.629028320000032],
                [-16.123470305999945, 18.629028320000032],
                [-16.123470305999945, 18.629304886],
                [-16.123752593999939, 18.629304886],
                [-16.123752593999939, 18.629583359000037],
                [-16.124027251999905, 18.629583359000037],
                [-16.124027251999905, 18.629861832000074],
                [-16.124305724999942, 18.629861832000074],
                [-16.124305724999942, 18.630416870000033],
                [-16.12458419799998, 18.630416870000033],
                [-16.12458419799998, 18.630695344000117],
                [-16.12486267099996, 18.630695344000117],
                [-16.12486267099996, 18.631248474000074],
                [-16.125139236999928, 18.631248474000074],
                [-16.125139236999928, 18.631805420000148],
                [-16.125415801999964, 18.631805420000148],
                [-16.125415801999964, 18.632362367000155],
                [-16.12569427499983, 18.632362367000155],
                [-16.12569427499983, 18.632917405000057],
                [-16.125974655999926, 18.632917405000057],
                [-16.125974655999926, 18.633470536000061],
                [-16.126249313999836, 18.633470536000061],
                [-16.126249313999836, 18.634027481999965],
                [-16.126527786999873, 18.634027481999965],
                [-16.126527786999873, 18.634580613000026],
                [-16.1268062599999, 18.634580613000026],
                [-16.1268062599999, 18.634862899000098],
                [-16.127084732999947, 18.634862899000098],
                [-16.127084732999947, 18.635974884000177],
                [-16.127361297999983, 18.635974884000177],
                [-16.127361297999983, 18.636249542000144],
                [-16.127639770999963, 18.636249542000144],
                [-16.127639770999963, 18.636806488000047],
                [-16.127916336999931, 18.636806488000047],
                [-16.127916336999931, 18.637083053000083],
                [-16.128194807999932, 18.637083053000083],
                [-16.128194807999932, 18.638471603000085],
                [-16.128471374999833, 18.638471603000085],
                [-16.128471374999833, 18.639305115000127],
                [-16.128194807999932, 18.639305115000127],
                [-16.128194807999932, 18.639862061000031],
                [-16.128471374999833, 18.639862061000031],
                [-16.128471374999833, 18.640972138000109],
                [-16.128749846999938, 18.640972138000109],
                [-16.128749846999938, 18.641527176000011],
                [-16.129028319999975, 18.641527176000011],
                [-16.129028319999975, 18.642637253000146],
                [-16.129306792999955, 18.642637253000146],
                [-16.129306792999955, 18.642915726000183],
                [-16.129583357999934, 18.642917634000128],
                [-16.129583357999934, 18.643749238],
                [-16.129861830999971, 18.643749238],
                [-16.129861830999971, 18.644306184000072],
                [-16.130138396999939, 18.644306184000072],
                [-16.130138396999939, 18.644859315000133],
                [-16.130416869999976, 18.644859315000133],
                [-16.130416869999976, 18.645416261000037],
                [-16.1306953429999, 18.645416261000037],
                [-16.1306953429999, 18.64596939200004],
                [-16.130971907999935, 18.64596939200004],
                [-16.130971907999935, 18.646528245000184],
                [-16.131250380999973, 18.646528245000184],
                [-16.131250380999973, 18.646804811000152],
                [-16.131526946999941, 18.646804811000152],
                [-16.131526946999941, 18.648195266000187],
                [-16.131805419999978, 18.648195266000187],
                [-16.131805419999978, 18.649305343000094],
                [-16.132083892999958, 18.649305343000094],
                [-16.132083892999958, 18.651250839000113],
                [-16.132360457999937, 18.651250839000113],
                [-16.132360457999937, 18.651805878000118],
                [-16.132638930999974, 18.651805878000118],
                [-16.132638930999974, 18.652360916000077],
                [-16.132917403999897, 18.652360916000077],
                [-16.132917403999897, 18.653472901000157],
                [-16.133193969999979, 18.653472901000157],
                [-16.133193969999979, 18.654027939000059],
                [-16.133472442999903, 18.654027939000059],
                [-16.133415221999883, 18.655998229000033],
                [-16.13375091599994, 18.655971528],
                [-16.13375091599994, 18.656744004000188],
                [-16.13375091599994, 18.659027099000127],
                [-16.134027480999919, 18.659027099000127],
                [-16.134027480999919, 18.65930557199999],
                [-16.134305953999956, 18.65930557199999],
                [-16.134305953999956, 18.660139083000161],
                [-16.134582519999924, 18.660139083000161],
                [-16.134582519999924, 18.660692214000164],
                [-16.13485908499996, 18.660692214000164],
                [-16.13485908499996, 18.660972595000032],
                [-16.135141372999954, 18.660972595000032],
                [-16.135141372999954, 18.661527633000105],
                [-16.135416030999977, 18.661527633000105],
                [-16.135416030999977, 18.663473130000057],
                [-16.1356945039999, 18.663473130000057],
                [-16.1356945039999, 18.664861680000172],
                [-16.135416030999977, 18.664861680000172],
                [-16.135416030999977, 18.665416718000074],
                [-16.1356945039999, 18.665416718000074],
                [-16.1356945039999, 18.665695191000111],
                [-16.135971069999982, 18.665695191000111],
                [-16.135971069999982, 18.666250230000117],
                [-16.136249542999906, 18.666250230000117],
                [-16.136249542999906, 18.6693058030001],
                [-16.136529922999955, 18.6693058030001],
                [-16.136529922999955, 18.67291641200012],
                [-16.136804580999922, 18.67291641200012],
                [-16.13680648899998, 18.674303055000053],
                [-16.13708114699989, 18.674303055000053],
                [-16.13708114699989, 18.67486381599997],
                [-16.137359619999927, 18.67486381599997],
                [-16.137359619999927, 18.676527024000109],
                [-16.137638092999964, 18.676527024000109],
                [-16.137638092999964, 18.679861070000072],
                [-16.137916565999888, 18.679861070000072],
                [-16.137916565999888, 18.680694581000068],
                [-16.138195038999925, 18.680694581000068],
                [-16.138195038999925, 18.681804656000168],
                [-16.138473511999962, 18.681804656000168],
                [-16.138473511999962, 18.682916641000077],
                [-16.138750076999941, 18.682916641000077],
                [-16.138750076999941, 18.683748245000118],
                [-16.139028549999978, 18.683748245000118],
                [-16.139028549999978, 18.685417175000168],
                [-16.139305115999946, 18.685417175000168],
                [-16.139305115999946, 18.690139771000133],
                [-16.13958358699989, 18.690139771000133],
                [-16.13958358699989, 18.692916869000044],
                [-16.139860153999962, 18.692916869000044],
                [-16.139862059999928, 18.694583892000026],
                [-16.140138624999906, 18.694583892000026],
                [-16.140138624999906, 18.69625091600011],
                [-16.140417097999944, 18.69625091600011],
                [-16.140417097999944, 18.697641373000181],
                [-16.140693663999912, 18.697641373000181],
                [-16.140693663999912, 18.700973512000076],
                [-16.140972136999949, 18.700973512000076],
                [-16.140972136999949, 18.706527710000103],
                [-16.141250609999815, 18.706527710000103],
                [-16.141250609999815, 18.707082748000175],
                [-16.141527174999965, 18.707082748000175],
                [-16.141527174999965, 18.707916260000047],
                [-16.141805647999888, 18.707916260000047],
                [-16.141805647999888, 18.70847129800012],
                [-16.142084120999925, 18.70847129800012],
                [-16.142084120999925, 18.714027406000127],
                [-16.142360686999893, 18.714027406000127],
                [-16.142360686999893, 18.71486282300009],
                [-16.142639159999931, 18.71486282300009],
                [-16.142639159999931, 18.715415956000072],
                [-16.142915724999909, 18.715415956000072],
                [-16.142915724999909, 18.715974807000066],
                [-16.143194197999946, 18.715974807000066],
                [-16.143194197999946, 18.717638015000034],
                [-16.143472670999927, 18.717638015000034],
                [-16.143472670999927, 18.719583511000053],
                [-16.143749236999952, 18.719583511000053],
                [-16.143749236999952, 18.721250534000035],
                [-16.144027709999932, 18.721250534000035],
                [-16.144027709999932, 18.722084046000077],
                [-16.144306182999969, 18.722084046000077],
                [-16.144306182999969, 18.723747254000045],
                [-16.144582747999891, 18.723747254000045],
                [-16.144582747999891, 18.726528166999969],
                [-16.144861220999928, 18.726528166999969],
                [-16.144861220999928, 18.728195190000179],
                [-16.145139693999965, 18.728195190000179],
                [-16.145139693999965, 18.729028702000051],
                [-16.145416259999934, 18.729028702000051],
                [-16.145416259999934, 18.730138779000129],
                [-16.145694732999971, 18.730138779000129],
                [-16.145694732999971, 18.732917786000144],
                [-16.145971297999949, 18.732917786000144],
                [-16.145971297999949, 18.733472825000149],
                [-16.14624977099993, 18.733472825000149],
                [-16.14624977099993, 18.734306336000088],
                [-16.146526336999955, 18.734306336000088],
                [-16.146526336999955, 18.734582902000056],
                [-16.146804809999935, 18.734582902000056],
                [-16.146804809999935, 18.734861375000094],
                [-16.147083282999972, 18.734861375000094],
                [-16.147083282999972, 18.735416413000166],
                [-16.147361755999896, 18.735416413000166],
                [-16.147361755999896, 18.735971452000172],
                [-16.147638320999874, 18.735971452000172],
                [-16.147638320999874, 18.740140915000097],
                [-16.147916793999912, 18.740140915000097],
                [-16.147916793999912, 18.74041748000019],
                [-16.148195266999949, 18.74041748000019],
                [-16.148195266999949, 18.740970611000023],
                [-16.148471832999917, 18.740970611000023],
                [-16.148471832999917, 18.741527557000097],
                [-16.148748397999952, 18.741527557000097],
                [-16.148748397999952, 18.742084503000171],
                [-16.149026870999933, 18.742084503000171],
                [-16.149026870999933, 18.743194580000079],
                [-16.149318694999977, 18.743194580000079],
                [-16.14930534399997, 18.744028092000121],
                [-16.149421691999976, 18.744028092000121],
                [-16.149583816999893, 18.744028092000121],
                [-16.149593352999887, 18.745416642000066],
                [-16.149860382999975, 18.745416642000066],
                [-16.149860382999975, 18.747613907000073],
                [-16.149860382999975, 18.748750688000086],
                [-16.150001526999915, 18.748750688000086],
                [-16.150140760999875, 18.748750688000086],
                [-16.150068282999939, 18.749313354000094],
                [-16.150417328999936, 18.749305726000159],
                [-16.150417328999936, 18.750972747000162],
                [-16.150693893999915, 18.750972747000162],
                [-16.150747298999875, 18.754863739000029],
                [-16.150970459999883, 18.754863739000029],
                [-16.150970459999883, 18.755973816000164],
                [-16.151065826999968, 18.755973816000164],
                [-16.151252745999955, 18.755973816000164],
                [-16.151252745999955, 18.756517410000185],
                [-16.151252745999955, 18.757085801000073],
                [-16.151443481999934, 18.757085801000073],
                [-16.151527403999978, 18.757085801000073],
                [-16.151525497999955, 18.75736045900004],
                [-16.152080536999961, 18.75736045900004],
                [-16.152080536999961, 18.757917405000114],
                [-16.152362822999976, 18.757917405000114],
                [-16.152362822999976, 18.758472443000016],
                [-16.152639387999955, 18.758472443000016],
                [-16.152639387999955, 18.759304047000114],
                [-16.15291595399998, 18.759304047000114],
                [-16.15291595399998, 18.760972976000062],
                [-16.15319442699996, 18.760972976000062],
                [-16.15319442699996, 18.761806488000104],
                [-16.153474806999952, 18.761806488000104],
                [-16.153469084999927, 18.762641907000045],
                [-16.153749464999976, 18.762638091999975],
                [-16.153718948999938, 18.762941361000117],
                [-16.154027937999899, 18.762916565000012],
                [-16.154027937999899, 18.763471603000141],
                [-16.154100418999974, 18.763471603000141],
                [-16.154306410999936, 18.763471603000141],
                [-16.154306410999936, 18.763666152000155],
                [-16.154306410999936, 18.764028549000045],
                [-16.154594421999946, 18.76401710600004],
                [-16.154584883999973, 18.765693665000128],
                [-16.154861449999942, 18.765693665000128],
                [-16.154861449999942, 18.76597023],
                [-16.15513801499992, 18.76597023],
                [-16.15513801499992, 18.766252518000101],
                [-16.155696868999883, 18.766252518000101],
                [-16.155696868999883, 18.766527176000068],
                [-16.156249999999886, 18.766527176000068],
                [-16.156249999999886, 18.766805649000105],
                [-16.156528472999923, 18.766805649000105],
                [-16.156528472999923, 18.767084121999972],
                [-16.156806945999961, 18.767084121999972],
                [-16.156806945999961, 18.767362595],
                [-16.157321929999966, 18.767362595],
                [-16.157915114999923, 18.767362595],
                [-16.157915114999923, 18.76808929399999],
                [-16.157915114999923, 18.771249772000033],
                [-16.158193587999961, 18.771249772000033],
                [-16.158193587999961, 18.773195266000073],
                [-16.158472060999884, 18.773195266000073],
                [-16.158472060999884, 18.774026870000114],
                [-16.158750533999921, 18.774026870000114],
                [-16.158750533999921, 18.776527404999968],
                [-16.159027099999889, 18.776527404999968],
                [-16.159027099999889, 18.777082443000097],
                [-16.159305572999926, 18.777082443000097],
                [-16.159305572999926, 18.777639389000171],
                [-16.159584045999964, 18.777639389000171],
                [-16.159584045999964, 18.778749466000079],
                [-16.159860610999942, 18.778749466000079],
                [-16.159860610999942, 18.779306412000153],
                [-16.16013717699991, 18.779306412000153],
                [-16.16013717699991, 18.779859542999986],
                [-16.16041755699996, 18.779859542999986],
                [-16.16041755699996, 18.780416489000061],
                [-16.160694122999928, 18.780416489000061],
                [-16.160694122999928, 18.780973435000135],
                [-16.160972595999965, 18.780973435000135],
                [-16.160972595999965, 18.78152847399997],
                [-16.161247253999932, 18.78152847399997],
                [-16.161247253999932, 18.781803132000164],
                [-16.161527633999867, 18.781803132000164],
                [-16.161527633999867, 18.782363891000102],
                [-16.161806107999894, 18.782363891000102],
                [-16.161806107999894, 18.782917024000142],
                [-16.162082672999873, 18.782917024000142],
                [-16.162082672999873, 18.783195495000143],
                [-16.162916182999822, 18.783195495000143],
                [-16.162916182999822, 18.783472062000044],
                [-16.163471220999895, 18.783472062000044],
                [-16.163471220999895, 18.783750533000045],
                [-16.163751601999934, 18.783750533000045],
                [-16.163751601999934, 18.784027099000014],
                [-16.164028166999969, 18.784027099000014],
                [-16.164028166999969, 18.784305572000051],
                [-16.164304732999938, 18.784305572000051],
                [-16.164304732999938, 18.784585951999986],
                [-16.164581297999916, 18.784585951999986],
                [-16.164581297999916, 18.785417556000084],
                [-16.164930343999856, 18.78540802100008],
                [-16.16486358599991, 18.78847312900001],
                [-16.164581297999916, 18.78847312900001],
                [-16.164581297999916, 18.790416718000188],
                [-16.164304732999938, 18.790416718000188],
                [-16.164304732999938, 18.796527862000062],
                [-16.164581297999916, 18.796527862000062],
                [-16.164581297999916, 18.798471451000012],
                [-16.16486358599991, 18.798471451000012],
                [-16.16486358599991, 18.799306870000123],
                [-16.164581297999916, 18.799306870000123],
                [-16.164581297999916, 18.80013847400005],
                [-16.16486358599991, 18.80013847400005],
                [-16.16486358599991, 18.80097198500016],
                [-16.165138243999934, 18.80097198500016],
                [-16.165138243999934, 18.802360535000105],
                [-16.165416716999971, 18.802360535000105],
                [-16.165416716999971, 18.803747178000037],
                [-16.165695189999894, 18.803747178000037],
                [-16.165695189999894, 18.805694581000125],
                [-16.165973662999932, 18.805694581000125],
                [-16.165973662999932, 18.806249620000131],
                [-16.1662502289999, 18.806249620000131],
                [-16.1662502289999, 18.806528091000132],
                [-16.165973662999932, 18.806528091000132],
                [-16.165973662999932, 18.807916641000133],
                [-16.165695189999894, 18.807916641000133],
                [-16.165695189999894, 18.811250687000097],
                [-16.165416716999971, 18.811250687000097],
                [-16.165416716999971, 18.813472748000038],
                [-16.165138243999934, 18.813472748000038],
                [-16.165138243999934, 18.81430626000008],
                [-16.16486358599991, 18.81430626000008],
                [-16.16486358599991, 18.81513977100019],
                [-16.164581297999916, 18.81513977100019],
                [-16.164581297999916, 18.817361833],
                [-16.164304732999938, 18.817361833],
                [-16.164304732999938, 18.819026946],
                [-16.164028166999969, 18.819026946],
                [-16.164028166999969, 18.820140837999986],
                [-16.163751601999934, 18.820140837999986],
                [-16.163751601999934, 18.820692062000148],
                [-16.164028166999969, 18.820692062000148],
                [-16.164028166999969, 18.821252823000066],
                [-16.164304732999938, 18.821252823000066],
                [-16.164304732999938, 18.82236290000003],
                [-16.164581297999916, 18.82236290000003],
                [-16.164581297999916, 18.822914123000032],
                [-16.16486358599991, 18.822914123000032],
                [-16.16486358599991, 18.823749541999973],
                [-16.165138243999934, 18.823749541999973],
                [-16.165138243999934, 18.824306488000047],
                [-16.165416716999971, 18.824306488000047],
                [-16.165416716999971, 18.824583054000016],
                [-16.165695189999894, 18.824583054000016],
                [-16.165695189999894, 18.824861527000053],
                [-16.165973662999932, 18.824861527000053],
                [-16.165973662999932, 18.825138092000145],
                [-16.1662502289999, 18.825138092000145],
                [-16.1662502289999, 18.825696946000164],
                [-16.166528701999937, 18.825696946000164],
                [-16.166528701999937, 18.826250077],
                [-16.166805266999972, 18.826250077],
                [-16.166805266999972, 18.826805115000127],
                [-16.167083739999953, 18.826805115000127],
                [-16.167083739999953, 18.827362062000077],
                [-16.167360305999978, 18.827362062000077],
                [-16.167360305999978, 18.829027177000114],
                [-16.167638778999958, 18.829027177000114],
                [-16.167638778999958, 18.830137254000022],
                [-16.167917251999882, 18.830137254000022],
                [-16.167917251999882, 18.831527710000159],
                [-16.168195724999919, 18.831527710000159],
                [-16.168195724999919, 18.835416794000082],
                [-16.168472289999897, 18.835416794000082],
                [-16.168472289999897, 18.83652687100016],
                [-16.168750762999935, 18.83652687100016],
                [-16.168750762999935, 18.838472367],
                [-16.169027328999903, 18.838472367],
                [-16.169027328999903, 18.839027406000014],
                [-16.16930580199994, 18.839027406000014],
                [-16.16930580199994, 18.839305879000051],
                [-16.169582366999919, 18.839305879000051],
                [-16.169584274999977, 18.839860917000181],
                [-16.169860839999956, 18.839860917000181],
                [-16.169860839999956, 18.84013939],
                [-16.170139312999879, 18.84013939],
                [-16.170139312999879, 18.840694427000187],
                [-16.170417785999916, 18.840694427000187],
                [-16.170417785999916, 18.843748092000169],
                [-16.170694351999884, 18.843748092000169],
                [-16.170694351999884, 18.844305038000073],
                [-16.170972824999922, 18.844305038000073],
                [-16.170972824999922, 18.851249696000025],
                [-16.1712493899999, 18.851249696000025],
                [-16.1712493899999, 18.852361678000022],
                [-16.171527862999937, 18.852361678000022],
                [-16.171527862999937, 18.853195190000065],
                [-16.171806335999975, 18.853195190000065],
                [-16.171806335999975, 18.854028701000175],
                [-16.172082901999943, 18.854028701000175],
                [-16.172082901999943, 18.854860305000045],
                [-16.17236137499998, 18.854860305000045],
                [-16.17236137499998, 18.856527328000084],
                [-16.172637939999959, 18.856527328000084],
                [-16.172637939999959, 18.85958290100001],
                [-16.172916412999882, 18.85958290100001],
                [-16.172916412999882, 18.860416413000053],
                [-16.173194885999919, 18.860416413000053],
                [-16.173194885999919, 18.860973359000127],
                [-16.173471451999887, 18.860973359000127],
                [-16.173471451999887, 18.864860534000172],
                [-16.173749924999925, 18.864860534000172],
                [-16.173749924999925, 18.865417480000076],
                [-16.174028395999812, 18.865417480000076],
                [-16.174028395999812, 18.865972519000081],
                [-16.174304960999962, 18.865972519000081],
                [-16.174304960999962, 18.866249084000117],
                [-16.174583433999885, 18.866249084000117],
                [-16.174583433999885, 18.867082596000159],
                [-16.174861906999922, 18.867082596000159],
                [-16.174861906999922, 18.872083665000162],
                [-16.17513847299989, 18.872083665000162],
                [-16.17513847299989, 18.87597465500005],
                [-16.175416945999928, 18.87597465500005],
                [-16.175416945999928, 18.878196716000105],
                [-16.175693510999906, 18.878196716000105],
                [-16.175693510999906, 18.879306793000069],
                [-16.175970076999874, 18.879306793000069],
                [-16.175970076999874, 18.880416870000147],
                [-16.176248549999912, 18.880416870000147],
                [-16.176248549999912, 18.882083893000129],
                [-16.176527022999949, 18.882083893000129],
                [-16.176527022999949, 18.882638932000134],
                [-16.176805495999815, 18.882638932000134],
                [-16.176805495999815, 18.884027482000079],
                [-16.177137373999926, 18.88401985199999],
                [-16.177082060999965, 18.885971070000153],
                [-16.177379607999967, 18.885969163000084],
                [-16.177413940999884, 18.88624954300019],
                [-16.177917479999962, 18.88624954300019],
                [-16.177917479999962, 18.886528014000078],
                [-16.178192137999872, 18.886528014000078],
                [-16.178192137999872, 18.889862061000144],
                [-16.178470610999909, 18.889862061000144],
                [-16.178470610999909, 18.893749238000169],
                [-16.178749083999946, 18.893749238000169],
                [-16.178749083999946, 18.897079468000186],
                [-16.178749083999946, 18.897916793000093],
                [-16.179050445999849, 18.897909164999987],
                [-16.179027556999927, 18.900970459000177],
                [-16.179306029999964, 18.900970459000177],
                [-16.179306029999964, 18.902639389000058],
                [-16.179584502999887, 18.902639389000058],
                [-16.179584502999887, 18.904027939000173],
                [-16.179861068999969, 18.904027939000173],
                [-16.179861068999969, 18.90513801600008],
                [-16.180139541999893, 18.90513801600008],
                [-16.180139541999893, 18.906250000999989],
                [-16.180416106999928, 18.906250000999989],
                [-16.180416106999928, 18.907638551000161],
                [-16.180694579999965, 18.907638551000161],
                [-16.180694579999965, 18.909027101000106],
                [-16.180973052999946, 18.909027101000106],
                [-16.180973052999946, 18.910972595000146],
                [-16.181249618999971, 18.910972595000146],
                [-16.181249618999971, 18.911527633000048],
                [-16.181528091999951, 18.911527633000048],
                [-16.181528091999951, 18.912082672000054],
                [-16.18180465699993, 18.912082672000054],
                [-16.18180465699993, 18.91291618299999],
                [-16.182083129999967, 18.91291618299999],
                [-16.182083129999967, 18.914028168000073],
                [-16.18236160299989, 18.914028168000073],
                [-16.18236160299989, 18.914581299000133],
                [-16.182638168999972, 18.914581299000133],
                [-16.182638168999972, 18.915138245000037],
                [-16.182916641999896, 18.915138245000037],
                [-16.182916641999896, 18.915695191000111],
                [-16.183195114999933, 18.915695191000111],
                [-16.183195114999933, 18.916250229000184],
                [-16.183471679999911, 18.916250229000184],
                [-16.183471679999911, 18.916803360000017],
                [-16.183750152999949, 18.916803360000017],
                [-16.183750152999949, 18.917638779000129],
                [-16.184028625999872, 18.917638779000129],
                [-16.184028625999872, 18.918472291000171],
                [-16.184305191999954, 18.918472291000171],
                [-16.184305191999954, 18.919305802000167],
                [-16.184583664999877, 18.919305802000167],
                [-16.184583664999877, 18.920139313999982],
                [-16.18486023, 18.920139313999982],
                [-16.18486023, 18.920972823000113],
                [-16.185136795999938, 18.920946122000146],
                [-16.185138702999893, 18.923194885000157],
                [-16.185417173999952, 18.923194885000157],
                [-16.185417173999952, 18.924583435000102],
                [-16.185693739999977, 18.924583435000102],
                [-16.185693739999977, 18.925138474000107],
                [-16.185972212999957, 18.925138474000107],
                [-16.185972212999957, 18.92569351200018],
                [-16.18625068599988, 18.925695420000181],
                [-16.18625068599988, 18.926252366000085],
                [-16.186527250999973, 18.926252366000085],
                [-16.186527250999973, 18.926805497000089],
                [-16.186805723999896, 18.926805497000089],
                [-16.186805723999896, 18.927639008000028],
                [-16.187084196999933, 18.927639008000028],
                [-16.187084196999933, 18.928192139000089],
                [-16.187360762999901, 18.928192139000089],
                [-16.187360762999901, 18.928749085000163],
                [-16.187637327999937, 18.928749085000163],
                [-16.187637327999937, 18.92902755800003],
                [-16.187915800999974, 18.92902755800003],
                [-16.187915800999974, 18.929584504000104],
                [-16.188194273999954, 18.929584504000104],
                [-16.188194273999954, 18.930694581000182],
                [-16.188472746999878, 18.930694581000182],
                [-16.188472746999878, 18.931806564000112],
                [-16.18874931299996, 18.931806564000112],
                [-16.18874931299996, 18.93291664100019],
                [-16.189027785999883, 18.93291664100019],
                [-16.189027785999883, 18.93375015200013],
                [-16.18930625899992, 18.93375015200013],
                [-16.18930625899992, 18.934305191000135],
                [-16.189582823999899, 18.934305191000135],
                [-16.189582823999899, 18.934860229000037],
                [-16.189859389999981, 18.934860229000037],
                [-16.189859389999981, 18.93569374100008],
                [-16.190141677999975, 18.93569374100008],
                [-16.190141677999975, 18.936250686999983],
                [-16.190416335999942, 18.936250686999983],
                [-16.190416335999942, 18.936805725000113],
                [-16.190694808999979, 18.936805725000113],
                [-16.190694808999979, 18.937360764000118],
                [-16.190969466999888, 18.937360764000118],
                [-16.190969466999888, 18.938194275000058],
                [-16.191251754999882, 18.938194275000058],
                [-16.191251754999882, 18.938749314000063],
                [-16.191528319999918, 18.938749314000063],
                [-16.191528319999918, 18.939306260000137],
                [-16.191804885999886, 18.939306260000137],
                [-16.191804885999886, 18.940141679000078],
                [-16.192081450999979, 18.940141679000078],
                [-16.192081450999979, 18.941249847999984],
                [-16.192363739999962, 18.941249847999984],
                [-16.192363739999962, 18.942363738000154],
                [-16.192638396999939, 18.942363738000154],
                [-16.192638396999939, 18.942916871000023],
                [-16.192916869999976, 18.942916871000023],
                [-16.192916869999976, 18.943473815000061],
                [-16.193195343999946, 18.943473815000061],
                [-16.193195343999946, 18.944305419000159],
                [-16.19347381699987, 18.944305419000159],
                [-16.19347381699987, 18.944860458000164],
                [-16.193750381999962, 18.944860458000164],
                [-16.193750381999962, 18.945695877000105],
                [-16.194026946999884, 18.945695877000105],
                [-16.194026946999884, 18.946807861000082],
                [-16.194305419999921, 18.946807861000082],
                [-16.194305419999921, 18.947360992000085],
                [-16.19458580099996, 18.947360992000085],
                [-16.19458580099996, 18.94791793800016],
                [-16.194860458999926, 18.94791793800016],
                [-16.194860458999926, 18.948472977000165],
                [-16.195138931999963, 18.948472977000165],
                [-16.195138931999963, 18.949026108],
                [-16.195417404999944, 18.949026108],
                [-16.195417404999944, 18.949306488000104],
                [-16.195695877999981, 18.949306488000104],
                [-16.195695877999981, 18.94986152700011],
                [-16.195972443999949, 18.94986152700011],
                [-16.195972443999949, 18.950416565000069],
                [-16.196250914999894, 18.950416565000069],
                [-16.196250914999894, 18.950971604000074],
                [-16.196527481999965, 18.950971604000074],
                [-16.196527481999965, 18.95124816900011],
                [-16.196804047999933, 18.95124816900011],
                [-16.196804047999933, 18.951805115000184],
                [-16.197082518999935, 18.951805115000184],
                [-16.197082518999935, 18.952360154000189],
                [-16.197360991999972, 18.952360154000189],
                [-16.197360991999972, 18.953193665000128],
                [-16.197639464999952, 18.953193665000128],
                [-16.197639464999952, 18.953752517],
                [-16.197917937999875, 18.953752517],
                [-16.197917937999875, 18.95458221500013],
                [-16.198194502999854, 18.95458221500013],
                [-16.198194502999854, 18.955139160000101],
                [-16.198472975999891, 18.955139160000101],
                [-16.198472975999891, 18.955692292000037],
                [-16.19874954199986, 18.955692292000037],
                [-16.19874954199986, 18.955974579000042],
                [-16.198472975999891, 18.955974579000042],
                [-16.198472975999891, 18.956249237000179],
                [-16.199026106999895, 18.956249237000179],
                [-16.199026106999895, 18.957639694000022],
                [-16.199304579999932, 18.957639694000022],
                [-16.199306487999934, 18.958196640000097],
                [-16.19986152599995, 18.958196640000097],
                [-16.19986152599995, 18.959028244000024],
                [-16.200416564999955, 18.959028244000024],
                [-16.200416564999955, 18.959306717000061],
                [-16.200138091999975, 18.959306717000061],
                [-16.200138091999975, 18.959583283000029],
                [-16.200416564999955, 18.959583283000029],
                [-16.200416564999955, 18.959861756000066],
                [-16.200695037999878, 18.959861756000066],
                [-16.200695037999878, 18.960695267000176],
                [-16.200971602999971, 18.960695267000176],
                [-16.200971602999971, 18.960971833000144],
                [-16.200695037999878, 18.960971833000144],
                [-16.200695037999878, 18.961250306000181],
                [-16.201248168999939, 18.961250306000181],
                [-16.201248168999939, 18.962083817000121],
                [-16.201528548999931, 18.962083817000121],
                [-16.201528548999931, 18.962360383000089],
                [-16.2018051149999, 18.962360383000089],
                [-16.2018051149999, 18.962638856000126],
                [-16.201528548999931, 18.962638856000126],
                [-16.201528548999931, 18.962917329000163],
                [-16.2018051149999, 18.962917329000163],
                [-16.2018051149999, 18.963193894000028],
                [-16.202083587999937, 18.963193894000028],
                [-16.202083587999937, 18.964027406000071],
                [-16.202358245999903, 18.964027406000071],
                [-16.202360152999972, 18.964860917000067],
                [-16.202638625999953, 18.964860917000067],
                [-16.202638625999953, 18.965415956000072],
                [-16.202917098999876, 18.965415956000072],
                [-16.202917098999876, 18.965694429000109],
                [-16.203193664999958, 18.965694429000109],
                [-16.203193664999958, 18.96652793800007],
                [-16.203470229999937, 18.96652793800007],
                [-16.203470229999937, 18.967081071000052],
                [-16.203752517999931, 18.967081071000052],
                [-16.203752517999931, 18.968193054000153],
                [-16.204027175999897, 18.968193054000153],
                [-16.204027175999897, 18.96847152700019],
                [-16.204305648999934, 18.96847152700019],
                [-16.204305648999934, 18.968751907000126],
                [-16.204584121999972, 18.968751907000126],
                [-16.204584121999972, 18.969028473000094],
                [-16.204862594999838, 18.969028473000094],
                [-16.204862594999838, 18.969581604000098],
                [-16.205139160999977, 18.969581604000098],
                [-16.205139160999977, 18.970138550000172],
                [-16.205692291999924, 18.970138550000172],
                [-16.205692291999924, 18.970417023000039],
                [-16.205974579999918, 18.970417023000039],
                [-16.205974579999918, 18.970695496000076],
                [-16.206249237999884, 18.970695496000076],
                [-16.206249237999884, 18.970972061000111],
                [-16.206527710999922, 18.970972061000111],
                [-16.206527710999922, 18.971250533999978],
                [-16.206249237999884, 18.971250533999978],
                [-16.206249237999884, 18.971527100000117],
                [-16.206527710999922, 18.971527100000117],
                [-16.206527710999922, 18.972085953000089],
                [-16.206806183999959, 18.972085953000089],
                [-16.206806183999959, 18.97263908400015],
                [-16.207084656999939, 18.97263908400015],
                [-16.207084656999939, 18.973472596000022],
                [-16.207361221999975, 18.973472596000022],
                [-16.207361221999975, 18.974027634000095],
                [-16.207639692999919, 18.974027634000095],
                [-16.207639692999919, 18.975416184000039],
                [-16.20791626099998, 18.975416184000039],
                [-16.20791626099998, 18.975694657000076],
                [-16.208194731999924, 18.975694657000076],
                [-16.208194731999924, 18.976528169000119],
                [-16.208471298999882, 18.976528169000119],
                [-16.208471298999882, 18.976804733999984],
                [-16.2087497699999, 18.976804733999984],
                [-16.2087497699999, 18.977361678000079],
                [-16.209028242999977, 18.977361678000079],
                [-16.209028242999977, 18.977916717000085],
                [-16.2087497699999, 18.977916717000085],
                [-16.2087497699999, 18.978195190000122],
                [-16.209028242999977, 18.978195190000122],
                [-16.209028242999977, 18.978471754999987],
                [-16.209306715999844, 18.978471754999987],
                [-16.209306715999844, 18.978748321000126],
                [-16.209583281999812, 18.978748321000126],
                [-16.209583281999812, 18.979860305000159],
                [-16.209861754999849, 18.979860305000159],
                [-16.209861754999849, 18.980138778000025],
                [-16.210138319999885, 18.980138778000025],
                [-16.210138319999885, 18.980693817000031],
                [-16.210416792999922, 18.980693817000031],
                [-16.210416792999922, 18.980970382000066],
                [-16.210695265999959, 18.980970382000066],
                [-16.210695265999959, 18.981805801000178],
                [-16.210971831999927, 18.981805801000178],
                [-16.210971831999927, 18.982362747000082],
                [-16.211250304999965, 18.982362747000082],
                [-16.211250304999965, 18.98263931300005],
                [-16.211526869999943, 18.98263931300005],
                [-16.211526869999943, 18.982915878000085],
                [-16.21180534299998, 18.982915878000085],
                [-16.21180534299998, 18.983474732000161],
                [-16.212360381999815, 18.983474732000161],
                [-16.212360381999815, 18.984027863000165],
                [-16.212638854999852, 18.984027863000165],
                [-16.212638854999852, 18.984861374000104],
                [-16.212917327999889, 18.984861374000104],
                [-16.212917327999889, 18.98541641300011],
                [-16.213472365999962, 18.98541641300011],
                [-16.213472365999962, 18.985971451000069],
                [-16.213750838999943, 18.985971451000069],
                [-16.213750838999943, 18.986249924000106],
                [-16.214027404999968, 18.986249924000106],
                [-16.214027404999968, 18.987083436000148],
                [-16.214305877999948, 18.987083436000148],
                [-16.214305877999948, 18.98763847400005],
                [-16.214860915999964, 18.98763847400005],
                [-16.214860915999964, 18.988471986000093],
                [-16.215139388999887, 18.988471986000093],
                [-16.215139388999887, 18.989028930000188],
                [-16.215415954999969, 18.989028930000188],
                [-16.215415954999969, 18.989305496000156],
                [-16.215694427999892, 18.989305496000156],
                [-16.215694427999892, 18.990137100000027],
                [-16.216249465999965, 18.990137100000027],
                [-16.216249465999965, 18.990694046000101],
                [-16.216527938999945, 18.990694046000101],
                [-16.216527938999945, 18.991249084000174],
                [-16.216806411999983, 18.991249084000174],
                [-16.216806411999983, 18.991806030000077],
                [-16.217081069999949, 18.991806030000077],
                [-16.217081069999949, 18.992359161000081],
                [-16.21735954299993, 18.992359161000081],
                [-16.21735954299993, 18.992916106999985],
                [-16.217638015999967, 18.992916106999985],
                [-16.217638015999967, 18.993749619000027],
                [-16.21791648899989, 18.993749619000027],
                [-16.21791648899989, 18.994028092000065],
                [-16.218238829999905, 18.99400711100003],
                [-16.218194961999927, 18.995416642000066],
                [-16.218473434999964, 18.995416642000066],
                [-16.218473434999964, 18.995889664000117],
                [-16.218473434999964, 18.99597358800014],
                [-16.218751905999909, 18.99597358800014],
                [-16.218751905999909, 18.996528626000043],
                [-16.219028471999877, 18.996528626000043],
                [-16.219028471999877, 18.99680328400018],
                [-16.219303131999879, 18.99680328400018],
                [-16.219303131999879, 18.997360230000083],
                [-16.219583509999893, 18.997360230000083],
                [-16.219583509999893, 18.997638703000121],
                [-16.219863890999932, 18.997705459000144],
                [-16.219863890999932, 18.998195649000024],
                [-16.220138548999898, 18.998195649000024],
                [-16.220138548999898, 18.998336791000156],
                [-16.220138548999898, 18.99875068800003],
                [-16.220336912999869, 18.99875068800003],
                [-16.220417021999936, 18.99875068800003],
                [-16.220449447999954, 18.999040604000015],
                [-16.220691681999938, 18.999027253000065],
                [-16.220664977999945, 18.999595642000145],
                [-16.220972059999951, 18.999584198999969],
                [-16.220972059999951, 19.000139236000166],
                [-16.220972059999951, 19.000379562000148],
                [-16.220972059999951, 19.000694274000068],
                [-16.221094130999916, 19.000694274000068],
                [-16.221250532999875, 19.000694274000068],
                [-16.221250532999875, 19.001096725000082],
                [-16.221250532999875, 19.001527786000111],
                [-16.22142028899998, 19.001527786000111],
                [-16.221527098999957, 19.001527786000111],
                [-16.221527098999957, 19.001804351000033],
                [-16.221527098999957, 19.00208282400007],
                [-16.221635818999971, 19.00208282400007],
                [-16.22180557199988, 19.00208282400007],
                [-16.22180557199988, 19.002515794000146],
                [-16.22180557199988, 19.002916336000112],
                [-16.221961973999953, 19.002916336000112],
                [-16.222085952999976, 19.002916336000112],
                [-16.222085952999976, 19.003471374000185],
                [-16.222177505999923, 19.003471374000185],
                [-16.222360609999896, 19.003471374000185],
                [-16.222297667999953, 19.003774643000156],
                [-16.222639082999933, 19.003749847000051],
                [-16.222639082999933, 19.004304886000057],
                [-16.22291755599997, 19.004304886000057],
                [-16.222919463999972, 19.005161286000032],
                [-16.223194121999938, 19.005138397000167],
                [-16.223194121999938, 19.006076814000096],
                [-16.223194121999938, 19.006805419999978],
                [-16.223478317999934, 19.006803513000079],
                [-16.223472594999976, 19.007917405000057],
                [-16.223749159999954, 19.007917405000057],
                [-16.223749159999954, 19.008752824000169],
                [-16.224027632999878, 19.008752824000169],
                [-16.224027632999878, 19.009304047000057],
                [-16.224306105999915, 19.009304047000057],
                [-16.224306105999915, 19.010139466000169],
                [-16.224582671999883, 19.010139466000169],
                [-16.224582671999883, 19.011806487000172],
                [-16.22486114499992, 19.011806487000172],
                [-16.22486114499992, 19.014028549000159],
                [-16.225139617999957, 19.014028549000159],
                [-16.225139617999957, 19.014862060000098],
                [-16.225416182999936, 19.014862060000098],
                [-16.225416182999936, 19.015417099000103],
                [-16.225694655999973, 19.015417099000103],
                [-16.225694655999973, 19.015693664000025],
                [-16.225973129999943, 19.015693664000025],
                [-16.225973129999943, 19.0162506100001],
                [-16.226249694999979, 19.0162506100001],
                [-16.226249694999979, 19.016805649000105],
                [-16.226526259999957, 19.016805649000105],
                [-16.226526259999957, 19.018749237000122],
                [-16.226804732999881, 19.018749237000122],
                [-16.226804732999881, 19.019306183000026],
                [-16.227083206999851, 19.019306183000026],
                [-16.227083206999851, 19.020139695000069],
                [-16.227361679999888, 19.020139695000069],
                [-16.227361679999888, 19.020694732999971],
                [-16.227638244999923, 19.020694732999971],
                [-16.227638244999923, 19.020971299000109],
                [-16.228195190999941, 19.020971299000109],
                [-16.228195190999941, 19.021249771999976],
                [-16.228748321999944, 19.021249771999976],
                [-16.228748321999944, 19.021804810000106],
                [-16.229030609999938, 19.021804810000106],
                [-16.229030609999938, 19.022081376000074],
                [-16.229440688999944, 19.022081376000074],
                [-16.229860306999967, 19.022081376000074],
                [-16.229860306999967, 19.022363662000146],
                [-16.230417252999928, 19.022363662000146],
                [-16.230417252999928, 19.022638322000148],
                [-16.230693817999963, 19.022638322000148],
                [-16.230693817999963, 19.022916795000185],
                [-16.230970383999932, 19.022916795000185],
                [-16.230970383999932, 19.023195266000187],
                [-16.231252669999947, 19.023195266000187],
                [-16.231252669999947, 19.023750305000192],
                [-16.231527327999856, 19.023750305000192],
                [-16.231527327999856, 19.024303436000082],
                [-16.231805800999894, 19.024303436000082],
                [-16.231805800999894, 19.024860382000156],
                [-16.232080460999953, 19.024860382000156],
                [-16.232084273999931, 19.025138854999966],
                [-16.232362746999968, 19.025138854999966],
                [-16.232362746999968, 19.025972366000133],
                [-16.232639311999947, 19.025972366000133],
                [-16.232639311999947, 19.02625083900017],
                [-16.232915877999972, 19.02625083900017],
                [-16.232915877999972, 19.026527405000138],
                [-16.233194350999952, 19.026527405000138],
                [-16.233194350999952, 19.02708244300004],
                [-16.233474730999944, 19.02708244300004],
                [-16.233474730999944, 19.027360916000077],
                [-16.233749388999854, 19.027360916000077],
                [-16.233749388999854, 19.027639389000115],
                [-16.234027861999891, 19.027639389000115],
                [-16.234027861999891, 19.027917862000152],
                [-16.234306334999928, 19.027917862000152],
                [-16.234306334999928, 19.028472901000157],
                [-16.234584807999966, 19.028472901000157],
                [-16.234584807999966, 19.029306412000096],
                [-16.234306334999928, 19.029306412000096],
                [-16.234306334999928, 19.029861451000102],
                [-16.234584807999966, 19.029861451000102],
                [-16.234584807999966, 19.032125474000111],
                [-16.234584807999966, 19.032360078000181],
                [-16.234708785999942, 19.032360078000181],
                [-16.234861373999934, 19.032360078000181],
                [-16.234861373999934, 19.032646179000153],
                [-16.234861373999934, 19.033470155000089],
                [-16.235137938999969, 19.033470155000089],
                [-16.235137938999969, 19.034027101000163],
                [-16.23541641199995, 19.034027101000163],
                [-16.23541641199995, 19.03430557400003],
                [-16.235750197999948, 19.03430557400003],
                [-16.237083434999931, 19.03430557400003],
                [-16.237083434999931, 19.034582139000065],
                [-16.237638473999937, 19.034582139000065],
                [-16.237638473999937, 19.034860610000123],
                [-16.237916946999974, 19.034860610000123],
                [-16.237916946999974, 19.035139083000161],
                [-16.238193511999953, 19.035139083000161],
                [-16.238193511999953, 19.035692216000029],
                [-16.238471984999876, 19.035692216000029],
                [-16.238471984999876, 19.036527633000105],
                [-16.238750457999913, 19.036527633000105],
                [-16.238750457999913, 19.037361145000148],
                [-16.23902893099995, 19.037361145000148],
                [-16.23902893099995, 19.038749695000149],
                [-16.238750457999913, 19.038749695000149],
                [-16.238750457999913, 19.039907456000094],
                [-16.238750457999913, 19.040971756000033],
                [-16.23902893099995, 19.040971756000033],
                [-16.23902893099995, 19.041528702000107],
                [-16.239305496999918, 19.041528702000107],
                [-16.239305496999918, 19.042083741000113],
                [-16.239583969999956, 19.042083741000113],
                [-16.239583969999956, 19.042360305999978],
                [-16.239860534999934, 19.042360305999978],
                [-16.239860534999934, 19.042917252000052],
                [-16.240137100999902, 19.042917252000052],
                [-16.240137100999902, 19.043472291000057],
                [-16.240417480999838, 19.043472291000057],
                [-16.240417480999838, 19.044027329000187],
                [-16.240694046999977, 19.044027329000187],
                [-16.240694046999977, 19.044305802000054],
                [-16.240972519999843, 19.044305802000054],
                [-16.240972519999843, 19.044860841000059],
                [-16.241247177999924, 19.044860841000059],
                [-16.241247177999924, 19.045694352000169],
                [-16.241527557999916, 19.045694352000169],
                [-16.241527557999916, 19.046249388999968],
                [-16.241806030999953, 19.046249388999968],
                [-16.241806030999953, 19.046806335000042],
                [-16.242082596999921, 19.046806335000042],
                [-16.242082596999921, 19.047082900000134],
                [-16.2423591619999, 19.047082900000134],
                [-16.2423591619999, 19.04763793900014],
                [-16.242641447999972, 19.047641753999983],
                [-16.242641447999972, 19.047916412000177],
                [-16.242916105999882, 19.047916412000177],
                [-16.242916105999882, 19.048471450000079],
                [-16.243194578999919, 19.048471450000079],
                [-16.243194578999919, 19.049028396000153],
                [-16.243471144999887, 19.049028396000153],
                [-16.243471144999887, 19.049863816000027],
                [-16.243749617999924, 19.049863816000027],
                [-16.243749617999924, 19.050695419000135],
                [-16.244028090999961, 19.050695419000135],
                [-16.244028090999961, 19.051525116000164],
                [-16.24430465599994, 19.051525116000164],
                [-16.24430465599994, 19.052085877000081],
                [-16.244581221999908, 19.052085877000081],
                [-16.244581221999908, 19.052639008000142],
                [-16.244863509999902, 19.052639008000142],
                [-16.244863509999902, 19.053195953999989],
                [-16.245138167999983, 19.053195953999989],
                [-16.245138167999983, 19.053472520000184],
                [-16.245416640999849, 19.053472520000184],
                [-16.245416640999849, 19.054027558000087],
                [-16.245695113999886, 19.054027558000087],
                [-16.245695113999886, 19.054582596000159],
                [-16.245973586999924, 19.054582596000159],
                [-16.245973586999924, 19.055139543000166],
                [-16.246250151999959, 19.055139543000166],
                [-16.246250151999959, 19.055973054000106],
                [-16.246528624999939, 19.055973054000106],
                [-16.246528624999939, 19.056528093000111],
                [-16.246805190999964, 19.056528093000111],
                [-16.246805190999964, 19.057361602000071],
                [-16.247083663999945, 19.057361602000071],
                [-16.247083663999945, 19.058471679000149],
                [-16.24736022899998, 19.058471679000149],
                [-16.24736022899998, 19.059305191000192],
                [-16.247638701999847, 19.059305191000192],
                [-16.247638701999847, 19.059860229000151],
                [-16.247917174999884, 19.059860229000151],
                [-16.247917174999884, 19.060138702000188],
                [-16.248195647999921, 19.060138702000188],
                [-16.248195647999921, 19.060970306000058],
                [-16.248472213999889, 19.060970306000058],
                [-16.248472213999889, 19.061527252000133],
                [-16.248750686999927, 19.061527252000133],
                [-16.248750686999927, 19.06180572500017],
                [-16.249027251999962, 19.06180572500017],
                [-16.249027251999962, 19.062639237000042],
                [-16.249305724999942, 19.062639237000042],
                [-16.249305724999942, 19.063192368000045],
                [-16.24958419799998, 19.063192368000045],
                [-16.24958419799998, 19.064584733000061],
                [-16.249860763999948, 19.064584733000061],
                [-16.249860763999948, 19.065416337000102],
                [-16.250139236999871, 19.065416337000102],
                [-16.250139236999871, 19.066249848000098],
                [-16.250415801999964, 19.066249848000098],
                [-16.250415801999964, 19.06708336000014],
                [-16.250694274999887, 19.06708336000014],
                [-16.250694274999887, 19.068750381000086],
                [-16.250972747999924, 19.068750381000086],
                [-16.250972747999924, 19.069583892000082],
                [-16.251249313999892, 19.069583892000082],
                [-16.251249313999892, 19.070417404000125],
                [-16.25152778699993, 19.070417404000125],
                [-16.25152778699993, 19.071250915000064],
                [-16.251806259999967, 19.071250915000064],
                [-16.251806259999967, 19.07180595400007],
                [-16.252082824999945, 19.07180595400007],
                [-16.252082824999945, 19.072082519000105],
                [-16.252361297999983, 19.072082519000105],
                [-16.252361297999983, 19.072639465000179],
                [-16.252639770999906, 19.072639465000179],
                [-16.252639770999906, 19.072916031000148],
                [-16.252916336999874, 19.072916031000148],
                [-16.252916336999874, 19.073194504000185],
                [-16.253194809999911, 19.073194504000185],
                [-16.253194809999911, 19.073749542000144],
                [-16.25347137499989, 19.073749542000144],
                [-16.25347137499989, 19.07430648799999],
                [-16.253749845999948, 19.07430648799999],
                [-16.253749845999948, 19.074581146000185],
                [-16.254028318999872, 19.074581146000185],
                [-16.254028318999872, 19.074863434000065],
                [-16.254304884999954, 19.074863434000065],
                [-16.254304884999954, 19.075138092000088],
                [-16.254583357999877, 19.075138092000088],
                [-16.254583357999877, 19.075416565000126],
                [-16.254861830999914, 19.075416565000126],
                [-16.254861830999914, 19.075693131000094],
                [-16.255138395999893, 19.075693131000094],
                [-16.255138395999893, 19.076250077000168],
                [-16.25541686899993, 19.076250077000168],
                [-16.25541686899993, 19.076528550000035],
                [-16.255695341999967, 19.076528550000035],
                [-16.255695341999967, 19.077638627000113],
                [-16.255971907999935, 19.077638627000113],
                [-16.255971907999935, 19.078195573000187],
                [-16.256248472999971, 19.078195573000187],
                [-16.256248472999971, 19.078472138000052],
                [-16.256526945999951, 19.078472138000052],
                [-16.256526945999951, 19.079027177000057],
                [-16.256805418999875, 19.079027177000057],
                [-16.256805418999875, 19.079584123000132],
                [-16.257083891999912, 19.079584123000132],
                [-16.257083891999912, 19.080139159000055],
                [-16.25736045799988, 19.080139159000055],
                [-16.25736045799988, 19.080417632000092],
                [-16.257640838999976, 19.080417632000092],
                [-16.257640838999976, 19.08069419800006],
                [-16.258470534999901, 19.08069419800006],
                [-16.258470534999901, 19.080417632000092],
                [-16.258752822999895, 19.080417632000092],
                [-16.258752822999895, 19.080972671000097],
                [-16.259027480999976, 19.080972671000097],
                [-16.259027480999976, 19.081527709],
                [-16.259305953999842, 19.081527709],
                [-16.259305953999842, 19.081806182000037],
                [-16.259584426999879, 19.081806182000037],
                [-16.259584426999879, 19.082082748],
                [-16.259862899999916, 19.082082748],
                [-16.259862899999916, 19.082361221000042],
                [-16.260139465999885, 19.082361221000042],
                [-16.260139465999885, 19.082639694000079],
                [-16.26041603099992, 19.082639694000079],
                [-16.26041603099992, 19.082916259000172],
                [-16.260694503999957, 19.082916259000172],
                [-16.260694503999957, 19.083194732000038],
                [-16.260974884999882, 19.083194732000038],
                [-16.260974884999882, 19.083749771000043],
                [-16.261249542999963, 19.083749771000043],
                [-16.261249542999963, 19.084028244000081],
                [-16.261528015999943, 19.084028244000081],
                [-16.261528015999943, 19.084304809000116],
                [-16.261804580999978, 19.084304809000116],
                [-16.26180648899998, 19.08486175500019],
                [-16.262083053999845, 19.08486175500019],
                [-16.262083053999845, 19.085138321000159],
                [-16.262361526999882, 19.085138321000159],
                [-16.262361526999882, 19.085416794000025],
                [-16.26263809299985, 19.085416794000025],
                [-16.26263809299985, 19.085695267000062],
                [-16.262916565999888, 19.085695267000062],
                [-16.262916565999888, 19.085971832000098],
                [-16.26319694599988, 19.085971832000098],
                [-16.26319694599988, 19.086250306000068],
                [-16.26347160399996, 19.086250306000068],
                [-16.26347160399996, 19.086526871000103],
                [-16.263750076999941, 19.086526871000103],
                [-16.263750076999941, 19.08680534400014],
                [-16.264028549999978, 19.08680534400014],
                [-16.264028549999978, 19.087083817000178],
                [-16.264305115999946, 19.087083817000178],
                [-16.264305115999946, 19.087638856000183],
                [-16.264583588999869, 19.087638856000183],
                [-16.264583588999869, 19.087917329000049],
                [-16.264860153999962, 19.087917329000049],
                [-16.264862059999928, 19.088193894000142],
                [-16.265138626999885, 19.088193894000142],
                [-16.265138626999885, 19.088752748000161],
                [-16.265417097999944, 19.088752748000161],
                [-16.265417097999944, 19.089027406000184],
                [-16.265972136999949, 19.089027406000184],
                [-16.265972136999949, 19.08930587899999],
                [-16.266250609999872, 19.08930587899999],
                [-16.266250609999872, 19.089582444000087],
                [-16.266527174999851, 19.089582444000087],
                [-16.266527174999851, 19.090692521000165],
                [-16.266805647999888, 19.090692521000165],
                [-16.266805647999888, 19.090974807000066],
                [-16.267084120999925, 19.090974807000066],
                [-16.267084120999925, 19.091249467000068],
                [-16.267360686999893, 19.091249467000068],
                [-16.267360686999893, 19.091527938000127],
                [-16.267637251999929, 19.091527938000127],
                [-16.267637251999929, 19.09208488400003],
                [-16.267915724999966, 19.09208488400003],
                [-16.267915724999966, 19.092361450000169],
                [-16.268194197999946, 19.092361450000169],
                [-16.268194197999946, 19.092916488000071],
                [-16.26847267099987, 19.092916488000071],
                [-16.26847267099987, 19.093471527000077],
                [-16.268749236999952, 19.093471527000077],
                [-16.268749236999952, 19.093750000000114],
                [-16.269027709999875, 19.093750000000114],
                [-16.269027709999875, 19.094306946000188],
                [-16.269306182999912, 19.094306946000188],
                [-16.269306182999912, 19.094583511000053],
                [-16.269582747999891, 19.094583511000053],
                [-16.269582747999891, 19.09486198400009],
                [-16.269859313999859, 19.09486198400009],
                [-16.269859313999859, 19.095138550000058],
                [-16.270141601999967, 19.095138550000058],
                [-16.270141601999967, 19.095693588000188],
                [-16.270416259999934, 19.095693588000188],
                [-16.270416259999934, 19.095972061],
                [-16.270694732999971, 19.095972061],
                [-16.270694732999971, 19.0965271],
                [-16.270969390999937, 19.0965271],
                [-16.270969390999937, 19.097084046000077],
                [-16.271251678999931, 19.097084046000077],
                [-16.271251678999931, 19.09736061100017],
                [-16.27152824399991, 19.09736061100017],
                [-16.27152824399991, 19.097637177000138],
                [-16.272363662999965, 19.097637177000138],
                [-16.272363662999965, 19.098472596000079],
                [-16.272638320999931, 19.098472596000079],
                [-16.272638320999931, 19.098749161000114],
                [-16.272916793999968, 19.098749161000114],
                [-16.272916793999968, 19.099027634000151],
                [-16.273195266999835, 19.099027634000151],
                [-16.273195266999835, 19.099582673000157],
                [-16.273473739999872, 19.099582673000157],
                [-16.273473739999872, 19.099859238000022],
                [-16.274026870999876, 19.099859238000022],
                [-16.274026870999876, 19.100139619000061],
                [-16.274305343999913, 19.100139619000061],
                [-16.274305343999913, 19.100416184000096],
                [-16.274585724999952, 19.100416184000096],
                [-16.274585724999952, 19.100694657000133],
                [-16.274860382999918, 19.100694657000133],
                [-16.274860382999918, 19.100971223000101],
                [-16.275138855999955, 19.100971223000101],
                [-16.275138855999955, 19.102081300000066],
                [-16.275417328999936, 19.102081300000066],
                [-16.275417328999936, 19.102363586000138],
                [-16.275695799999937, 19.102363586000138],
                [-16.275695799999937, 19.103193284000042],
                [-16.275972366999838, 19.103193284000042],
                [-16.275972366999838, 19.103473663000045],
                [-16.276250837999953, 19.103473663000045],
                [-16.276250837999953, 19.103750228000081],
                [-16.276527405999843, 19.103750228000081],
                [-16.276527405999843, 19.104028701000118],
                [-16.276805876999958, 19.104028701000118],
                [-16.276805876999958, 19.104303359000141],
                [-16.277082441999937, 19.104303359000141],
                [-16.277082441999937, 19.104585647000022],
                [-16.277360914999974, 19.104585647000022],
                [-16.277360914999974, 19.104860305000045],
                [-16.277639387999898, 19.104860305000045],
                [-16.277639387999898, 19.105138778000082],
                [-16.277917860999935, 19.105138778000082],
                [-16.277917860999935, 19.105417251000119],
                [-16.278194426999903, 19.105417251000119],
                [-16.278194426999903, 19.105695724000157],
                [-16.2784729, 19.105695724000157],
                [-16.2784729, 19.105972290000125],
                [-16.278749464999919, 19.105972290000125],
                [-16.278749464999919, 19.106250763000162],
                [-16.279027937999956, 19.106250763000162],
                [-16.279027937999956, 19.106807709000066],
                [-16.279306410999936, 19.106807709000066],
                [-16.279306410999936, 19.107084274000101],
                [-16.279582976999961, 19.107084274000101],
                [-16.279582976999961, 19.107360840000069],
                [-16.279861449999942, 19.107360840000069],
                [-16.279861449999942, 19.108472824000046],
                [-16.280136107999908, 19.108472824000046],
                [-16.280136107999908, 19.108749390000185],
                [-16.280416487999844, 19.108749390000185],
                [-16.280416487999844, 19.109027863000051],
                [-16.280694960999881, 19.109027863000051],
                [-16.280694960999881, 19.109582901000181],
                [-16.280971526999849, 19.109582901000181],
                [-16.280971526999849, 19.10986137399999],
                [-16.281248091999885, 19.10986137399999],
                [-16.281248091999885, 19.110139847000028],
                [-16.281528472999923, 19.110139847000028],
                [-16.281528472999923, 19.110416413],
                [-16.281805037999959, 19.110416413],
                [-16.281805037999959, 19.110971451000125],
                [-16.282083510999939, 19.110971451000125],
                [-16.282083510999939, 19.111249924000163],
                [-16.282360076999964, 19.111249924000163],
                [-16.282360076999964, 19.111528397000029],
                [-16.282638549999945, 19.111528397000029],
                [-16.282638549999945, 19.111804963000168],
                [-16.282917022999982, 19.111804963000168],
                [-16.282917022999982, 19.112083436000034],
                [-16.283193587999847, 19.112083436000034],
                [-16.283193587999847, 19.112361909000072],
                [-16.283470153999815, 19.112361909000072],
                [-16.283470153999815, 19.112638474000107],
                [-16.28375244199998, 19.112638474000107],
                [-16.28375244199998, 19.112916947000144],
                [-16.284027099999889, 19.112916947000144],
                [-16.284027099999889, 19.113193513000112],
                [-16.284305572999926, 19.113195420000181],
                [-16.284305572999926, 19.113470077999978],
                [-16.284582137999962, 19.113470077999978],
                [-16.284582137999962, 19.114027024000052],
                [-16.284862518999887, 19.114027024000052],
                [-16.284862518999887, 19.114305497000089],
                [-16.285139083999979, 19.114305497000089],
                [-16.285139083999979, 19.114582063000057],
                [-16.285417556999903, 19.114582063000057],
                [-16.285417556999903, 19.114860534000115],
                [-16.285692214999926, 19.114860534000115],
                [-16.285692214999926, 19.115139007000153],
                [-16.285970687999964, 19.115139007000153],
                [-16.285970687999964, 19.11541748000019],
                [-16.286249160999887, 19.11541748000019],
                [-16.286249160999887, 19.115692138000156],
                [-16.286527633999924, 19.115692138000156],
                [-16.286527633999924, 19.115974426000093],
                [-16.286806106999961, 19.115974426000093],
                [-16.286806106999961, 19.11624908400006],
                [-16.287084577999906, 19.11624908400006],
                [-16.287084577999906, 19.11680412200019],
                [-16.287361145999967, 19.11680412200019],
                [-16.287361145999967, 19.117084503000171],
                [-16.287639616999911, 19.117084503000171],
                [-16.287639616999911, 19.117361068000037],
                [-16.287916183999982, 19.117361068000037],
                [-16.287916183999982, 19.117639542000177],
                [-16.288194654999927, 19.117639542000177],
                [-16.288194654999927, 19.117914199000097],
                [-16.288473127999964, 19.117914199000097],
                [-16.288473127999964, 19.118192672000134],
                [-16.288749693999932, 19.118192672000134],
                [-16.288749693999932, 19.118473053000116],
                [-16.289028166999969, 19.118473053000116],
                [-16.289028166999969, 19.118749619000084],
                [-16.289306639999893, 19.118749619000084],
                [-16.289306639999893, 19.119028092000121],
                [-16.289583204999872, 19.119028092000121],
                [-16.289583204999872, 19.119306564999988],
                [-16.289861678999898, 19.119306564999988],
                [-16.289861678999898, 19.11958313000008],
                [-16.290138243999877, 19.11958313000008],
                [-16.290138243999877, 19.120138169000086],
                [-16.290416716999914, 19.120138169000086],
                [-16.290416716999914, 19.120416642000123],
                [-16.290695189999951, 19.120416642000123],
                [-16.290695189999951, 19.12069511500016],
                [-16.290971755999919, 19.12069511500016],
                [-16.290971755999919, 19.121250153000062],
                [-16.291250228999957, 19.121250153000062],
                [-16.291250228999957, 19.121528626000099],
                [-16.291528701999937, 19.121528626000099],
                [-16.291528701999937, 19.122083665000105],
                [-16.291805266999972, 19.122083665000105],
                [-16.291805266999972, 19.12236023000014],
                [-16.292083739999896, 19.12236023000014],
                [-16.292083739999896, 19.122917176000044],
                [-16.292360305999978, 19.122917176000044],
                [-16.292360305999978, 19.123193742000183],
                [-16.292638778999901, 19.123193742000183],
                [-16.292638778999901, 19.123472215000049],
                [-16.292917251999938, 19.123472215000049],
                [-16.292917251999938, 19.123750688000086],
                [-16.293193816999917, 19.123750688000086],
                [-16.293193816999917, 19.124027253000179],
                [-16.293472289999954, 19.124027253000179],
                [-16.293472289999954, 19.124305725999989],
                [-16.29402732899996, 19.124305725999989],
                [-16.29402732899996, 19.124584199000026],
                [-16.29430580199994, 19.124584199000026],
                [-16.29430580199994, 19.12486076499999],
                [-16.294582366999975, 19.12486076499999],
                [-16.294584274999977, 19.125139238000031],
                [-16.294860839999842, 19.125139238000031],
                [-16.294860839999842, 19.125415803000124],
                [-16.295139312999879, 19.125415803000124],
                [-16.295139312999879, 19.12597274699999],
                [-16.295415878999847, 19.12597274699999],
                [-16.295415878999847, 19.12624931300013],
                [-16.295694351999884, 19.12624931300013],
                [-16.295694351999884, 19.126527786],
                [-16.295972824999922, 19.126527786],
                [-16.295972824999922, 19.126806259000034],
                [-16.296249389999957, 19.126806259000034],
                [-16.296249389999957, 19.127082824000126],
                [-16.296527862999937, 19.127082824000126],
                [-16.296527862999937, 19.127361297000164],
                [-16.296806335999975, 19.127361297000164],
                [-16.296806335999975, 19.12763977000003],
                [-16.297082901999943, 19.12763977000003],
                [-16.297082901999943, 19.127916336000169],
                [-16.297359466999978, 19.127916336000169],
                [-16.297359466999978, 19.128194809000036],
                [-16.297637939999845, 19.128194809000036],
                [-16.297637939999845, 19.128749847000108],
                [-16.297916412999882, 19.128749847000108],
                [-16.297916412999882, 19.129028320000145],
                [-16.298194885999919, 19.129028320000145],
                [-16.298194885999919, 19.129304886000114],
                [-16.298473358999956, 19.129304886000114],
                [-16.298473358999956, 19.129863739000086],
                [-16.298751829999901, 19.129863739000086],
                [-16.298751829999901, 19.130695343000127],
                [-16.299028395999869, 19.130695343000127],
                [-16.299028395999869, 19.130973816000164],
                [-16.299303055999928, 19.130973816000164],
                [-16.299303055999928, 19.131250382000133],
                [-16.299581528999965, 19.131250382000133],
                [-16.299581528999965, 19.131803512999966],
                [-16.299863814999981, 19.131803512999966],
                [-16.299863814999981, 19.13236045900004],
                [-16.300138472999947, 19.13236045900004],
                [-16.300138472999947, 19.132638932000077],
                [-16.30069160599993, 19.132638932000077],
                [-16.30069160599993, 19.132917405000114],
                [-16.300973891999888, 19.132917405000114],
                [-16.300973891999888, 19.133195877999981],
                [-16.301250456999981, 19.133195877999981],
                [-16.301250456999981, 19.13375091600011],
                [-16.301527022999949, 19.13375091600011],
                [-16.301527022999949, 19.134027482000079],
                [-16.301805495999872, 19.134027482000079],
                [-16.301805495999872, 19.134582520000151],
                [-16.302085875999978, 19.134582520000151],
                [-16.302085875999978, 19.135139466000055],
                [-16.302360533999888, 19.135139466000055],
                [-16.302360533999888, 19.136249543000133],
                [-16.302639006999925, 19.136249543000133],
                [-16.302639006999925, 19.136529921999966],
                [-16.302917479999962, 19.136529921999966],
                [-16.302917479999962, 19.137361526000063],
                [-16.303195952999886, 19.137361526000063],
                [-16.303195952999886, 19.137916564000136],
                [-16.303472518999968, 19.137916564000136],
                [-16.303472518999968, 19.138471603000141],
                [-16.303749083999946, 19.138471603000141],
                [-16.303749083999946, 19.138748168],
                [-16.30402755699987, 19.138748168],
                [-16.30402755699987, 19.139305114000081],
                [-16.304307937999965, 19.139305114000081],
                [-16.304307937999965, 19.139583587000118],
                [-16.304582595999875, 19.139583587000118],
                [-16.304582595999875, 19.139860153000086],
                [-16.304861068999912, 19.139860153000086],
                [-16.304861068999912, 19.140138626000123],
                [-16.305139541999949, 19.140138626000123],
                [-16.305139541999949, 19.14041709899999],
                [-16.305416106999928, 19.14041709899999],
                [-16.305416106999928, 19.140693664000082],
                [-16.305694579999965, 19.140693664000082],
                [-16.305694579999965, 19.14097023000005],
                [-16.305973052999889, 19.14097023000005],
                [-16.305973052999889, 19.141250610000156],
                [-16.306249618999971, 19.141250610000156],
                [-16.306249618999971, 19.141527176000125],
                [-16.306526183999949, 19.141527176000125],
                [-16.306526183999949, 19.142082214000027],
                [-16.306804656999873, 19.142082214000027],
                [-16.306804656999873, 19.142639160000101],
                [-16.3070831299999, 19.142639160000101],
                [-16.3070831299999, 19.143192291000105],
                [-16.307361602999947, 19.143192291000105],
                [-16.307361602999947, 19.143749237000179],
                [-16.307638168999915, 19.143749237000179],
                [-16.307638168999915, 19.144027710000046],
                [-16.307916641999952, 19.144027710000046],
                [-16.307916641999952, 19.14458465600012],
                [-16.308195114999933, 19.14458465600012],
                [-16.308195114999933, 19.144861222000088],
                [-16.308471679999968, 19.144861222000088],
                [-16.308471679999968, 19.145414353000149],
                [-16.308748245999936, 19.145414353000149],
                [-16.308748245999936, 19.145696641000029],
                [-16.30930519199984, 19.145696641000029],
                [-16.30930519199984, 19.145971299],
                [-16.309858322999958, 19.145971299],
                [-16.309858322999958, 19.146249772000033],
                [-16.31013870299995, 19.146249772000033],
                [-16.31013870299995, 19.14652824500007],
                [-16.310417173999895, 19.14652824500007],
                [-16.310417173999895, 19.146806718000107],
                [-16.310693741999955, 19.146806718000107],
                [-16.310693741999955, 19.147083283000029],
                [-16.310970306999934, 19.147083283000029],
                [-16.310970306999934, 19.147361756000066],
                [-16.311252592999949, 19.147361756000066],
                [-16.311252592999949, 19.147916795000071],
                [-16.311527250999916, 19.147916795000071],
                [-16.311527250999916, 19.148195266000073],
                [-16.311805723999953, 19.148195266000073],
                [-16.311805723999953, 19.148750304000032],
                [-16.312084196999933, 19.148750304000032],
                [-16.312084196999933, 19.149305343000037],
                [-16.31236267, 19.149305343000037],
                [-16.31236267, 19.149583816000074],
                [-16.312639235999939, 19.149583816000074],
                [-16.312639235999939, 19.15013885500008],
                [-16.312915800999974, 19.15013885500008],
                [-16.312915800999974, 19.150417328000117],
                [-16.313474654999936, 19.150417328000117],
                [-16.313474654999936, 19.150693893000152],
                [-16.313749312999903, 19.150693893000152],
                [-16.313749312999903, 19.15097236600019],
                [-16.31402778599994, 19.15097236600019],
                [-16.31402778599994, 19.151250839000056],
                [-16.314306258999977, 19.151250839000056],
                [-16.314306258999977, 19.151527405000024],
                [-16.314584731999958, 19.151527405000024],
                [-16.314584731999958, 19.151805878000062],
                [-16.314861296999936, 19.151805878000062],
                [-16.314861296999936, 19.152082443000097],
                [-16.315139769999973, 19.152082443000097],
                [-16.315139769999973, 19.152360916000134],
                [-16.315416335999942, 19.152360916000134],
                [-16.315416335999942, 19.152639389000171],
                [-16.315694808999979, 19.152639389000171],
                [-16.315694808999979, 19.15291595500014],
                [-16.316528319999918, 19.15291595500014],
                [-16.316528319999918, 19.153472901000043],
                [-16.316806792999955, 19.153472901000043],
                [-16.316806792999955, 19.153749466000079],
                [-16.317083358999923, 19.153749466000079],
                [-16.317083358999923, 19.154027939000116],
                [-16.31736183199996, 19.154027939000116],
                [-16.31736183199996, 19.154306411999983],
                [-16.317638396999939, 19.154306411999983],
                [-16.317638396999939, 19.154581070000177],
                [-16.318750381999905, 19.154581070000177],
                [-16.318750381999905, 19.154861450999988],
                [-16.319028854999942, 19.154861450999988],
                [-16.319028854999942, 19.155693055000086],
                [-16.319305419999921, 19.155693055000086],
                [-16.319305419999921, 19.156528474000027],
                [-16.319583892999958, 19.156528474000027],
                [-16.319583892999958, 19.156803132000164],
                [-16.320138931999963, 19.156803132000164],
                [-16.320138931999963, 19.157085420000101],
                [-16.320693969999979, 19.157085420000101],
                [-16.320693969999979, 19.157363893000138],
                [-16.320972442999903, 19.157363893000138],
                [-16.320972442999903, 19.157638551000105],
                [-16.321527481999908, 19.157638551000105],
                [-16.321527481999908, 19.158472062000044],
                [-16.321805952999966, 19.158472062000044],
                [-16.321805952999966, 19.159025193000105],
                [-16.322082519999924, 19.159025193000105],
                [-16.322082519999924, 19.159305574000086],
                [-16.322359085999892, 19.159305574000086],
                [-16.322359085999892, 19.159585952000043],
                [-16.322639464999895, 19.159585952000043],
                [-16.322639464999895, 19.159860611999989],
                [-16.322916029999874, 19.159860611999989],
                [-16.322916029999874, 19.160139083000047],
                [-16.323194502999911, 19.160139083000047],
                [-16.323194502999911, 19.160417556000084],
                [-16.32347106799989, 19.160417556000084],
                [-16.32347106799989, 19.160694122000052],
                [-16.323749541999916, 19.160694122000052],
                [-16.323749541999916, 19.160972595000089],
                [-16.324581144999968, 19.160972595000089],
                [-16.324581144999968, 19.160694122000052],
                [-16.324863433999894, 19.160694122000052],
                [-16.324863433999894, 19.161249160000125],
                [-16.325138091999975, 19.161249160000125],
                [-16.325138091999975, 19.162361145000034],
                [-16.325416564999898, 19.162361145000034],
                [-16.325416564999898, 19.162639618000071],
                [-16.325695037999935, 19.162639618000071],
                [-16.325695037999935, 19.163473129000067],
                [-16.325973510999972, 19.163473129000067],
                [-16.325973510999972, 19.163749695000035],
                [-16.327083587999937, 19.163749695000035],
                [-16.327083587999937, 19.164028168000073],
                [-16.32819557199997, 19.164028168000073],
                [-16.32819557199997, 19.164304733000108],
                [-16.329004287999851, 19.164304733000108],
                [-16.329305648999934, 19.164304733000108],
                [-16.329305648999934, 19.164682388000188],
                [-16.329305648999934, 19.165416718000188],
                [-16.329584121999972, 19.165416718000188],
                [-16.329584121999972, 19.166528702000164],
                [-16.330139160999977, 19.166528702000164],
                [-16.330139160999977, 19.166805268000132],
                [-16.330415725999842, 19.166805268000132],
                [-16.330415725999842, 19.16708374100017],
                [-16.330694198999879, 19.16708374100017],
                [-16.330694198999879, 19.167360306000035],
                [-16.330972671999916, 19.167360306000035],
                [-16.330972671999916, 19.167638779000072],
                [-16.331527710999922, 19.167638779000072],
                [-16.331527710999922, 19.167917252000109],
                [-16.331855773999962, 19.16787529000004],
                [-16.331806183999959, 19.168193818000077],
                [-16.332099914999901, 19.168178559000182],
                [-16.332082748999937, 19.168470383000169],
                [-16.332361221999975, 19.168510438000169],
                [-16.332361221999975, 19.16875267100005],
                [-16.332586287999845, 19.168792726000106],
                [-16.332639694999898, 19.169027329000073],
                [-16.332773208999924, 19.169027329000073],
                [-16.33291626099998, 19.169027329000073],
                [-16.33291626099998, 19.16930580200011],
                [-16.332994461999931, 19.16930580200011],
                [-16.333194733999903, 19.16930580200011],
                [-16.333217619999857, 19.169582368000079],
                [-16.3337497699999, 19.169582368000079],
                [-16.3337497699999, 19.169860841000116],
                [-16.334028242999977, 19.169860841000116],
                [-16.334028242999977, 19.170139314000153],
                [-16.334304808999946, 19.170139314000153],
                [-16.334304808999946, 19.170415879000188],
                [-16.334583281999869, 19.170415879000188],
                [-16.334583281999869, 19.170692445000157],
                [-16.334861754999906, 19.170692445000157],
                [-16.334861754999906, 19.170974731000058],
                [-16.335138319999942, 19.170974731000058],
                [-16.335138319999942, 19.17124939100006],
                [-16.335416792999979, 19.17124939100006],
                [-16.335416792999979, 19.171527862000119],
                [-16.335695265999959, 19.171527862000119],
                [-16.335695265999959, 19.171806335000156],
                [-16.335971831999927, 19.171806335000156],
                [-16.335971831999927, 19.172361373000058],
                [-16.336248396999963, 19.172361373000058],
                [-16.336248396999963, 19.172637939000026],
                [-16.336526869999943, 19.172639846000095],
                [-16.336526869999943, 19.172916412000063],
                [-16.33680534299998, 19.172916412000063],
                [-16.33680534299998, 19.173196792000169],
                [-16.337083815999904, 19.173196792000169],
                [-16.337083815999904, 19.173471450000136],
                [-16.337360381999872, 19.173471450000136],
                [-16.337360381999872, 19.173749923000173],
                [-16.337638854999909, 19.173749923000173],
                [-16.337638854999909, 19.17402839600004],
                [-16.337917327999946, 19.17402839600004],
                [-16.337917327999946, 19.174306869000077],
                [-16.338193892999925, 19.174306869000077],
                [-16.338193892999925, 19.174583435000045],
                [-16.338470458999893, 19.174583435000045],
                [-16.338470458999893, 19.175138473000118],
                [-16.338752746999887, 19.175138473000118],
                [-16.338752746999887, 19.175418854000156],
                [-16.339027404999968, 19.175418854000156],
                [-16.339027404999968, 19.175693512000123],
                [-16.339305877999891, 19.175695419000022],
                [-16.339305877999891, 19.17597198499999],
                [-16.339584350999928, 19.17597198499999],
                [-16.339584350999928, 19.176250458000027],
                [-16.339862823999965, 19.176250458000027],
                [-16.339862823999965, 19.176528931000064],
                [-16.340139388999944, 19.176528931000064],
                [-16.340139388999944, 19.176805496000156],
                [-16.340414046999854, 19.176805496000156],
                [-16.340414046999854, 19.177083969000023],
                [-16.340694427999949, 19.177083969000023],
                [-16.340694427999949, 19.177360535000162],
                [-16.340974807999885, 19.177360535000162],
                [-16.340974807999885, 19.177917481000065],
                [-16.341249465999965, 19.177917481000065],
                [-16.341249465999965, 19.178194046000101],
                [-16.341527938999889, 19.178194046000101],
                [-16.341527938999889, 19.178472519000138],
                [-16.341806411999926, 19.178472519000138],
                [-16.341806411999926, 19.178749085000106],
                [-16.342084884999963, 19.178749085000106],
                [-16.342084884999963, 19.179027558000143],
                [-16.342361450999931, 19.179027558000143],
                [-16.342361450999931, 19.179306031000181],
                [-16.34263801599991, 19.179306031000181],
                [-16.34263801599991, 19.179582596000046],
                [-16.342916488999947, 19.179582596000046],
                [-16.342916488999947, 19.179859162000184],
                [-16.343196869999872, 19.179859162000184],
                [-16.343196869999872, 19.18013954200012],
                [-16.343471527999952, 19.18013954200012],
                [-16.343471527999952, 19.180694581000125],
                [-16.343750000999933, 19.180694581000125],
                [-16.343750000999933, 19.18097114599999],
                [-16.34402847399997, 19.18097114599999],
                [-16.34402847399997, 19.181249619000027],
                [-16.344306944999971, 19.181249619000027],
                [-16.344306944999971, 19.181528092000065],
                [-16.344583511999872, 19.181528092000065],
                [-16.344583511999872, 19.181804658000033],
                [-16.344861982999817, 19.181804658000033],
                [-16.344861982999817, 19.182081223000125],
                [-16.345138548999955, 19.182081223000125],
                [-16.345138548999955, 19.18236351000013],
                [-16.345415115999913, 19.18236351000013],
                [-16.345415115999913, 19.182638169000029],
                [-16.345972059999895, 19.182638169000029],
                [-16.345972059999895, 19.182916641000133],
                [-16.346250532999932, 19.182916641000133],
                [-16.346250532999932, 19.183193208000034],
                [-16.3465270989999, 19.183193208000034],
                [-16.3465270989999, 19.183471679000036],
                [-16.346805571999937, 19.183471679000036],
                [-16.346805571999937, 19.183750152000073],
                [-16.347084044999974, 19.183750152000073],
                [-16.347084044999974, 19.18402862500011],
                [-16.347360609999953, 19.18402862500011],
                [-16.347360609999953, 19.184303283000133],
                [-16.347637175999921, 19.184303283000133],
                [-16.347637175999921, 19.184585571000184],
                [-16.34791755599997, 19.184585571000184],
                [-16.34791755599997, 19.184860229000037],
                [-16.348472594999976, 19.184860229000037],
                [-16.348472594999976, 19.185138702000074],
                [-16.348749159999898, 19.185138702000074],
                [-16.348749159999898, 19.185417175000111],
                [-16.349027632999935, 19.185417175000111],
                [-16.349027632999935, 19.185695648000149],
                [-16.349306105999972, 19.185695648000149],
                [-16.349306105999972, 19.185972214000117],
                [-16.34958267199994, 19.185972214000117],
                [-16.34958267199994, 19.186250687000154],
                [-16.349859236999919, 19.186250687000154],
                [-16.349859236999919, 19.186527252000189],
                [-16.350141524999913, 19.186527252000189],
                [-16.350141524999913, 19.186807633],
                [-16.350694655999973, 19.186807633],
                [-16.350694655999973, 19.187084198000093],
                [-16.350971221999941, 19.187084198000093],
                [-16.350971221999941, 19.187360764000061],
                [-16.351249694999979, 19.187360764000061],
                [-16.351249694999979, 19.187639237000099],
                [-16.351528167999902, 19.187639237000099],
                [-16.351528167999902, 19.187917710000136],
                [-16.351804732999881, 19.187917710000136],
                [-16.351804732999881, 19.188194275000171],
                [-16.352363586999843, 19.188194275000171],
                [-16.352363586999843, 19.188472748000038],
                [-16.352638244999923, 19.188472748000038],
                [-16.352638244999923, 19.188749314000177],
                [-16.35291671799996, 19.188749314000177],
                [-16.35291671799996, 19.189027787000043],
                [-16.353473663999921, 19.189027787000043],
                [-16.353473663999921, 19.18930626000008],
                [-16.3537502289999, 19.18930626000008],
                [-16.3537502289999, 19.189582825000116],
                [-16.354028701999937, 19.189582825000116],
                [-16.354028701999937, 19.189861297999983],
                [-16.354585648999944, 19.189861297999983],
                [-16.354585648999944, 19.19013977100002],
                [-16.354860305999921, 19.19013977100002],
                [-16.354860305999921, 19.190416336999988],
                [-16.355138778999958, 19.190416336999988],
                [-16.355138778999958, 19.190694810000025],
                [-16.355695723999929, 19.190694810000025],
                [-16.355695723999929, 19.190971375000117],
                [-16.355972290999887, 19.190971375000117],
                [-16.355972290999887, 19.191249848000155],
                [-16.356250761999945, 19.191249848000155],
                [-16.356250761999945, 19.191528321000192],
                [-16.35680580099995, 19.191528321000192],
                [-16.35680580099995, 19.19180488700016],
                [-16.357082365999929, 19.19180488700016],
                [-16.357084273999817, 19.192083360000026],
                [-16.357360838999966, 19.192083360000026],
                [-16.357360838999966, 19.192361833000064],
                [-16.357917784999927, 19.192361833000064],
                [-16.357917784999927, 19.192638398000099],
                [-16.358194350999895, 19.192638398000099],
                [-16.358194350999895, 19.192916871000136],
                [-16.358472823999932, 19.192916871000136],
                [-16.358472823999932, 19.193195344000173],
                [-16.359027861999948, 19.193195344000173],
                [-16.359027861999948, 19.193471910000142],
                [-16.359306334999815, 19.193471910000142],
                [-16.359306334999815, 19.193750383000179],
                [-16.359582900999953, 19.193750383000179],
                [-16.359582900999953, 19.194026948000044],
                [-16.360137938999969, 19.194026948000044],
                [-16.360137938999969, 19.194305419000102],
                [-16.360416411999893, 19.194305419000102],
                [-16.360416411999893, 19.194583892000139],
                [-16.36069488499993, 19.194583892000139],
                [-16.36069488499993, 19.194860458000107],
                [-16.361249923999935, 19.194860458000107],
                [-16.361249923999935, 19.195138931000145],
                [-16.361528396999972, 19.195138931000145],
                [-16.361528396999972, 19.195417404000182],
                [-16.361804961999951, 19.195417404000182],
                [-16.361804961999951, 19.195692062000148],
                [-16.362361907999968, 19.195692062000148],
                [-16.362361907999968, 19.195974350000085],
                [-16.362638473999937, 19.195974350000085],
                [-16.362638473999937, 19.196252823000123],
                [-16.363193511999896, 19.196252823000123],
                [-16.363193511999896, 19.196527481000089],
                [-16.363752365999972, 19.196527481000089],
                [-16.363752365999972, 19.196804046000125],
                [-16.364305496999975, 19.196804046000125],
                [-16.364305496999975, 19.19708251899999],
                [-16.364860534999934, 19.19708251899999],
                [-16.364860534999934, 19.19736290000003],
                [-16.365417480999895, 19.19736290000003],
                [-16.365417480999895, 19.197639465000066],
                [-16.365692138999975, 19.197639465000066],
                [-16.365692138999975, 19.197914123000089],
                [-16.365970611999842, 19.197914123000089],
                [-16.365970611999842, 19.198192596000126],
                [-16.366527557999916, 19.198192596000126],
                [-16.366527557999916, 19.198474884000177],
                [-16.367084501999898, 19.198474884000177],
                [-16.367084501999898, 19.19874954200003],
                [-16.367361069999959, 19.19874954200003],
                [-16.367361069999959, 19.198474884000177],
                [-16.367639540999903, 19.198474884000177],
                [-16.367639540999903, 19.19874954200003],
                [-16.368194578999976, 19.19874954200003],
                [-16.368194578999976, 19.199028015000067],
                [-16.368749617999981, 19.199028015000067],
                [-16.368749617999981, 19.199306488000104],
                [-16.369306563999885, 19.199306488000104],
                [-16.369306563999885, 19.199583054000072],
                [-16.369861601999901, 19.199583054000072],
                [-16.369861601999901, 19.19986152700011],
                [-16.370416640999906, 19.19986152700011],
                [-16.370416640999906, 19.200138092000145],
                [-16.370971678999979, 19.200138092000145],
                [-16.370971678999979, 19.200416565000182],
                [-16.371528624999883, 19.200416565000182],
                [-16.371528624999883, 19.20069694599999],
                [-16.372083663999888, 19.20069694599999],
                [-16.372079848999931, 19.200984955000138],
                [-16.372287750999931, 19.200981139000191],
                [-16.372917174999941, 19.200971604000188],
                [-16.372917174999941, 19.201250077000054],
                [-16.373472213999946, 19.201250077000054],
                [-16.373472213999946, 19.201528550000091],
                [-16.374027251999962, 19.201528550000091],
                [-16.374027251999962, 19.201805115000127],
                [-16.374584197999923, 19.201805115000127],
                [-16.374584197999923, 19.202083588000164],
                [-16.374860763999891, 19.202083588000164],
                [-16.374860763999891, 19.202362061000031],
                [-16.375415801999907, 19.202362061000031],
                [-16.375415801999907, 19.202638627000169],
                [-16.375694274999944, 19.202638627000169],
                [-16.375694274999944, 19.202917100000036],
                [-16.375972747999981, 19.202917100000036],
                [-16.375972747999981, 19.203193665000072],
                [-16.376249313999949, 19.203193665000072],
                [-16.376249313999949, 19.203472138000109],
                [-16.376806259999967, 19.203472138000109],
                [-16.376806259999967, 19.203750610999975],
                [-16.377082824999889, 19.203750610999975],
                [-16.377082824999889, 19.204305649999981],
                [-16.377641676999872, 19.204305649999981],
                [-16.377641676999872, 19.204584123000018],
                [-16.378194809999968, 19.204584123000018],
                [-16.378194809999968, 19.20486068800011],
                [-16.378751753999893, 19.20486068800011],
                [-16.378751753999893, 19.205139161000147],
                [-16.379028318999929, 19.205139161000147],
                [-16.379028318999929, 19.205415727000116],
                [-16.379304886999932, 19.205417632000149],
                [-16.379304886999932, 19.205694198000117],
                [-16.379863737999869, 19.205694198000117],
                [-16.379863737999869, 19.205972671000154],
                [-16.380416868999816, 19.205972671000154],
                [-16.380416868999816, 19.205694198000117],
                [-16.380695341999854, 19.205694198000117],
                [-16.380695341999854, 19.205417632000149],
                [-16.381250380999859, 19.205415727000116],
                [-16.381250380999859, 19.205139161000147],
                [-16.381526945999894, 19.205139161000147],
                [-16.381526945999894, 19.20486068800011],
                [-16.381805418999932, 19.20486068800011],
                [-16.381805418999932, 19.204584123000018],
                [-16.3820858, 19.204584123000018],
                [-16.3820858, 19.20486068800011],
                [-16.382638930999974, 19.20486068800011],
                [-16.382638930999974, 19.205139161000147],
                [-16.383195876999878, 19.205139161000147],
                [-16.383195876999878, 19.205415727000116],
                [-16.383750914999894, 19.205417632000149],
                [-16.383750914999894, 19.205694198000117],
                [-16.384027480999976, 19.205694198000117],
                [-16.384027480999976, 19.205972671000154],
                [-16.384582518999935, 19.205972671000154],
                [-16.384582518999935, 19.206249236000076],
                [-16.385139464999952, 19.206249236000076],
                [-16.385139464999952, 19.206527709000113],
                [-16.385417937999875, 19.206527709000113],
                [-16.385417937999875, 19.20680618200015],
                [-16.385972976999881, 19.20680618200015],
                [-16.385972976999881, 19.207082748000118],
                [-16.386526107999941, 19.207082748000118],
                [-16.386526107999941, 19.207359313000154],
                [-16.386804580999978, 19.207359313000154],
                [-16.386806487999934, 19.207639693999965],
                [-16.387083053999902, 19.207639693999965],
                [-16.387083053999902, 19.207916259000058],
                [-16.387361526999939, 19.207916259000058],
                [-16.387361526999939, 19.208471298000063],
                [-16.387638091999918, 19.208471298000063],
                [-16.387638091999918, 19.2087497710001],
                [-16.387916564999955, 19.2087497710001],
                [-16.387916564999955, 19.209028244000137],
                [-16.388195038999982, 19.209028244000137],
                [-16.388195038999982, 19.209581375000141],
                [-16.38847160399996, 19.209581375000141],
                [-16.38847160399996, 19.209863663000078],
                [-16.388748168999939, 19.209863663000078],
                [-16.388748168999939, 19.210138321000045],
                [-16.389028549999921, 19.210138321000045],
                [-16.389028549999921, 19.210693359000118],
                [-16.3893051149999, 19.210693359000118],
                [-16.3893051149999, 19.210973740000156],
                [-16.393474578999928, 19.210973740000156],
                [-16.393474578999928, 19.211250305000021],
                [-16.394027709999932, 19.211250305000021],
                [-16.394027709999932, 19.21152687099999],
                [-16.394306182999969, 19.21152687099999],
                [-16.394306182999969, 19.211803436000082],
                [-16.394861220999815, 19.211803436000082],
                [-16.394861220999815, 19.212085724000133],
                [-16.395139693999852, 19.212085724000133],
                [-16.395139693999852, 19.212360382000156],
                [-16.395694732999857, 19.212360382000156],
                [-16.395694732999857, 19.212638855000023],
                [-16.395971297999893, 19.212638855000023],
                [-16.395971297999893, 19.21291732800006],
                [-16.39624977099993, 19.21291732800006],
                [-16.39624977099993, 19.213195801000097],
                [-16.396806716999947, 19.213195801000097],
                [-16.396806716999947, 19.213472367000065],
                [-16.397083282999972, 19.213472367000065],
                [-16.397083282999972, 19.213750840000102],
                [-16.397638320999931, 19.213750840000102],
                [-16.397638320999931, 19.214027405000138],
                [-16.397916793999968, 19.214027405000138],
                [-16.397916793999968, 19.214307786000177],
                [-16.398471832999974, 19.214307786000177],
                [-16.398471832999974, 19.214582444000143],
                [-16.399028778999934, 19.214582444000143],
                [-16.399028778999934, 19.214860917000181],
                [-16.399860382999975, 19.214860917000181],
                [-16.399860382999975, 19.215139390000047],
                [-16.400417328999879, 19.215139390000047],
                [-16.400417328999879, 19.215417863000084],
                [-16.401250839999932, 19.215417863000084],
                [-16.401250839999932, 19.21569442800012],
                [-16.402082443999916, 19.21569442800012],
                [-16.402082443999916, 19.21597290200009],
                [-16.402915953999866, 19.21597290200009],
                [-16.402915953999866, 19.216249467000125],
                [-16.403749464999976, 19.216249467000125],
                [-16.403749464999976, 19.216529846000128],
                [-16.40430641099988, 19.216529846000128],
                [-16.40430641099988, 19.21680641100005],
                [-16.405138014999977, 19.21680641100005],
                [-16.405138014999977, 19.217082978000064],
                [-16.405973433999975, 19.217082978000064],
                [-16.405973433999975, 19.217361450000055],
                [-16.40652847299998, 19.217361450000055],
                [-16.40652847299998, 19.217639923000092],
                [-16.406803130999947, 19.217639923000092],
                [-16.406803130999947, 19.217916488000128],
                [-16.407081603999927, 19.217916488000128],
                [-16.407081603999927, 19.218471527000133],
                [-16.407363891999921, 19.218471527000133],
                [-16.407363891999921, 19.218748092],
                [-16.407638549999888, 19.218748092],
                [-16.407638549999888, 19.219028473000037],
                [-16.407917022999925, 19.219028473000037],
                [-16.407917022999925, 19.21958351100011],
                [-16.408195495999962, 19.21958351100011],
                [-16.408195495999962, 19.219860077000078],
                [-16.408472060999941, 19.219860077000078],
                [-16.408472060999941, 19.220138550000115],
                [-16.409027099999946, 19.220138550000115],
                [-16.409027099999946, 19.220417023000152],
                [-16.409305572999813, 19.220417023000152],
                [-16.409305572999813, 19.220693588000074],
                [-16.409585952999919, 19.220693588000074],
                [-16.409585952999919, 19.220970154000042],
                [-16.410139083999923, 19.220970154000042],
                [-16.410139083999923, 19.221250534000148],
                [-16.41041755699996, 19.221250534000148],
                [-16.41041755699996, 19.221527100000117],
                [-16.410972595999965, 19.221527100000117],
                [-16.410972595999965, 19.221805573000154],
                [-16.411249160999944, 19.221805573000154],
                [-16.411249160999944, 19.222082138000189],
                [-16.411527633999981, 19.222082138000189],
                [-16.411527633999981, 19.222360611000056],
                [-16.412082672999929, 19.222360611000056],
                [-16.412082672999929, 19.222639084000093],
                [-16.412361145999967, 19.222639084000093],
                [-16.412361145999967, 19.22291755700013],
                [-16.412916183999926, 19.22291755700013],
                [-16.412916183999926, 19.223192215000097],
                [-16.413194654999813, 19.223192215000097],
                [-16.413194654999813, 19.223474503000034],
                [-16.413749693999819, 19.223474503000034],
                [-16.413749693999819, 19.223749161000171],
                [-16.414028166999856, 19.223749161000171],
                [-16.414028166999856, 19.224027634000038],
                [-16.414304731999891, 19.224027634000038],
                [-16.414304731999891, 19.224304200000176],
                [-16.414861677999966, 19.224304200000176],
                [-16.414861677999966, 19.224584580000112],
                [-16.415138243999934, 19.224584580000112],
                [-16.415138243999934, 19.22486114600008],
                [-16.415695189999951, 19.22486114600008],
                [-16.415695189999951, 19.225139619000117],
                [-16.416248320999955, 19.225139619000117],
                [-16.416248320999955, 19.225414277000084],
                [-16.416805266999859, 19.225414277000084],
                [-16.416805266999859, 19.225696565000192],
                [-16.417083739999896, 19.225696565000192],
                [-16.417083739999896, 19.225973130000057],
                [-16.417638777999969, 19.225973130000057],
                [-16.417638777999969, 19.226249696000025],
                [-16.418193816999974, 19.226249696000025],
                [-16.418193816999974, 19.226528169000062],
                [-16.418752670999936, 19.226528169000062],
                [-16.418752670999936, 19.226806642000099],
                [-16.41902732799997, 19.226806642000099],
                [-16.41902732799997, 19.227083207000192],
                [-16.419580458999974, 19.227083207000192],
                [-16.419584273999931, 19.227361680000058],
                [-16.420139312999936, 19.227361680000058],
                [-16.420139312999936, 19.227638246000026],
                [-16.420415877999972, 19.227638246000026],
                [-16.420415877999972, 19.227916719000063],
                [-16.420974731999877, 19.227916719000063],
                [-16.420974731999877, 19.228195190000065],
                [-16.421527862999881, 19.228195190000065],
                [-16.421527862999881, 19.228471757000136],
                [-16.422084808999955, 19.228471757000136],
                [-16.422084808999955, 19.228750227999967],
                [-16.422361374999923, 19.228750227999967],
                [-16.422361374999923, 19.229028701],
                [-16.422916412999939, 19.229028701],
                [-16.422916412999939, 19.229305266999972],
                [-16.423471451999944, 19.229305266999972],
                [-16.423471451999944, 19.22958374000001],
                [-16.424028397999848, 19.22958374000001],
                [-16.424028397999848, 19.229860305000102],
                [-16.424583433999942, 19.229860305000102],
                [-16.424583433999942, 19.230138778000139],
                [-16.425138472999947, 19.230138778000139],
                [-16.425138472999947, 19.230417251000176],
                [-16.425693510999963, 19.230417251000176],
                [-16.425693510999963, 19.230693817000144],
                [-16.426250456999924, 19.230693817000144],
                [-16.426250456999924, 19.230972290000182],
                [-16.426805495999929, 19.230972290000182],
                [-16.426805495999929, 19.231250763000048],
                [-16.427360533999945, 19.231250763000048],
                [-16.427360533999945, 19.231527328000084],
                [-16.427917479999849, 19.231527328000084],
                [-16.427917479999849, 19.231805801000121],
                [-16.428472518999854, 19.231805801000121],
                [-16.428472518999854, 19.232084274000158],
                [-16.429027556999927, 19.232084274000158],
                [-16.429027556999927, 19.232360840000126],
                [-16.429582595999932, 19.232360840000126],
                [-16.429582595999932, 19.232639313000163],
                [-16.430416106999814, 19.232639313000163],
                [-16.430416106999814, 19.232915878000028],
                [-16.43097114599982, 19.232915878000028],
                [-16.43097114599982, 19.233194351000066],
                [-16.431528091999894, 19.233194351000066],
                [-16.431528091999894, 19.233472824000103],
                [-16.432081222999898, 19.233472824000103],
                [-16.432081222999898, 19.233749390000071],
                [-16.432638168999972, 19.233749390000071],
                [-16.432638168999972, 19.234027863000108],
                [-16.433195114999876, 19.234027863000108],
                [-16.433195114999876, 19.234306336000145],
                [-16.433750152999892, 19.234306336000145],
                [-16.433750152999892, 19.234582901000067],
                [-16.434303283999895, 19.234582901000067],
                [-16.434303283999895, 19.234861374000104],
                [-16.43486023, 19.234861374000104],
                [-16.43486023, 19.235137940000072],
                [-16.435417175999874, 19.235139847000141],
                [-16.435417175999874, 19.23541641300011],
                [-16.435695646999932, 19.23541641300011],
                [-16.435695646999932, 19.235971451000182],
                [-16.437084196999876, 19.235971451000182],
                [-16.437084196999876, 19.236249924000049],
                [-16.437360762999958, 19.236249924000049],
                [-16.437360762999958, 19.236528397000086],
                [-16.437917708999919, 19.236528397000086],
                [-16.437917708999919, 19.236803055000053],
                [-16.438472746999935, 19.236803055000053],
                [-16.438472746999935, 19.23708152800009],
                [-16.438749312999903, 19.23708152800009],
                [-16.438749312999903, 19.237363816000027],
                [-16.440139769999917, 19.237363816000027],
                [-16.440139769999917, 19.237638474000164],
                [-16.440971373999901, 19.237638474000164],
                [-16.440971373999901, 19.237916947000031],
                [-16.442361831999847, 19.237916947000031],
                [-16.442361831999847, 19.238195420000068],
                [-16.4429168699999, 19.238195420000068],
                [-16.4429168699999, 19.238473893000105],
                [-16.443750381999962, 19.238473893000105],
                [-16.443750381999962, 19.238750459000073],
                [-16.444860458999813, 19.238750459000073],
                [-16.444860458999813, 19.23902511700004],
                [-16.444583892999844, 19.23902511700004],
                [-16.444583892999844, 19.239860535999981],
                [-16.444860458999813, 19.239860535999981],
                [-16.444860458999813, 19.240139007000039],
                [-16.447914123999965, 19.240139007000039],
                [-16.447914123999965, 19.240417480000076],
                [-16.448194502999968, 19.240417480000076],
                [-16.448194502999968, 19.240695953000113],
                [-16.448474882999903, 19.240695953000113],
                [-16.448474882999903, 19.240972518000149],
                [-16.449302671999931, 19.240972518000149],
                [-16.449306486999888, 19.241527557000154],
                [-16.449583052999856, 19.241527557000154],
                [-16.449583052999856, 19.24180793700009],
                [-16.450138090999928, 19.24180793700009],
                [-16.450138090999928, 19.242082595000113],
                [-16.450416563999966, 19.242082595000113],
                [-16.450416563999966, 19.24236106800015],
                [-16.450696944999891, 19.24236106800015],
                [-16.450696944999891, 19.242639541000187],
                [-16.451250075999951, 19.242639541000187],
                [-16.451250075999951, 19.242916107000156],
                [-16.45208358699989, 19.242916107000156],
                [-16.45208358699989, 19.243473053],
                [-16.452360152999859, 19.243473053],
                [-16.452362060999917, 19.243749618000095],
                [-16.452638625999896, 19.243749618000095],
                [-16.452638625999896, 19.244028091000132],
                [-16.452917098999933, 19.244028091000132],
                [-16.452917098999933, 19.244583130000137],
                [-16.453193663999969, 19.244583130000137],
                [-16.453193663999969, 19.24513816800004],
                [-16.453472137999938, 19.24513816800004],
                [-16.453472137999938, 19.245416641000077],
                [-16.455415725999899, 19.245416641000077],
                [-16.455415725999899, 19.245695114000114],
                [-16.455694198999936, 19.245695114000114],
                [-16.455694198999936, 19.245416641000077],
                [-16.455972671999973, 19.245416641000077],
                [-16.455972671999973, 19.245695114000114],
                [-16.456527710999978, 19.245695114000114],
                [-16.456527710999978, 19.246250153000119],
                [-16.457082748999881, 19.246250153000119],
                [-16.457082748999881, 19.246528626000156],
                [-16.457359314999962, 19.246528626000156],
                [-16.457359314999962, 19.246805191000021],
                [-16.457641600999864, 19.246805191000021],
                [-16.457641600999864, 19.247083664000058],
                [-16.459028242999921, 19.247083664000058],
                [-16.459028242999921, 19.247360230000027],
                [-16.45958137599996, 19.247360230000027],
                [-16.45958137599996, 19.247638703000064],
                [-16.460416792999979, 19.247638703000064],
                [-16.460416792999979, 19.247917176000101],
                [-16.460695265999902, 19.247917176000101],
                [-16.460695265999902, 19.248193741000023],
                [-16.461250304999908, 19.248193741000023],
                [-16.461250304999908, 19.248470307000161],
                [-16.461526869999886, 19.248470307000161],
                [-16.461526869999886, 19.248750688000143],
                [-16.461805342999924, 19.248750688000143],
                [-16.461805342999924, 19.249027253000065],
                [-16.462085723999962, 19.249027253000065],
                [-16.462085723999962, 19.249305726000102],
                [-16.462360381999929, 19.249305726000102],
                [-16.462360381999929, 19.249582291000138],
                [-16.462638854999966, 19.249582291000138],
                [-16.462638854999966, 19.249860765000108],
                [-16.462917327999946, 19.249860765000108],
                [-16.462917327999946, 19.250139238000145],
                [-16.46319580099987, 19.250139238000145],
                [-16.46319580099987, 19.25041580300018],
                [-16.46430778499996, 19.25041580300018],
                [-16.46430778499996, 19.250692368000045],
                [-16.464860915999964, 19.250692368000045],
                [-16.464860915999964, 19.25097465500005],
                [-16.465139388999944, 19.25097465500005],
                [-16.465139388999944, 19.251249315000052],
                [-16.465972900999873, 19.251249315000052],
                [-16.465972900999873, 19.251527786000054],
                [-16.466249465999852, 19.251527786000054],
                [-16.466249465999852, 19.251806259000091],
                [-16.466527938999889, 19.251806259000091],
                [-16.466527938999889, 19.252639770000087],
                [-16.467082977999894, 19.252639770000087],
                [-16.467082977999894, 19.252914428000054],
                [-16.467916488999947, 19.252914428000054],
                [-16.467916488999947, 19.253471374000128],
                [-16.46819496199987, 19.253471374000128],
                [-16.46819496199987, 19.253749847000165],
                [-16.468748092999931, 19.253749847000165],
                [-16.468748092999931, 19.254028320000032],
                [-16.469305038999892, 19.254028320000032],
                [-16.469305038999892, 19.254306793000069],
                [-16.469583511999929, 19.254306793000069],
                [-16.469583511999929, 19.254583359000037],
                [-16.469860077999897, 19.254583359000037],
                [-16.469860077999897, 19.254861832000074],
                [-16.470138550999934, 19.254861832000074],
                [-16.470138550999934, 19.25513839700011],
                [-16.47069358899995, 19.25513839700011],
                [-16.47069358899995, 19.255695343000184],
                [-16.470970154999975, 19.255695343000184],
                [-16.470970154999975, 19.255971909000152],
                [-16.4715270989999, 19.255971909000152],
                [-16.4715270989999, 19.256250382000189],
                [-16.471805571999937, 19.256250382000189],
                [-16.471805571999937, 19.256528855000056],
                [-16.472082136999973, 19.256528855000056],
                [-16.472082136999973, 19.257360459000154],
                [-16.47125244099982, 19.257360459000154],
                [-16.47125244099982, 19.257638932000191],
                [-16.470970154999975, 19.257638932000191],
                [-16.470970154999975, 19.25847244300013],
                [-16.47125244099982, 19.25847244300013],
                [-16.47125244099982, 19.258750916000167],
                [-16.4715270989999, 19.258750916000167],
                [-16.4715270989999, 19.259582520000038],
                [-16.471805571999937, 19.259582520000038],
                [-16.471805571999937, 19.26041603200008],
                [-16.472082136999973, 19.26041603200008],
                [-16.472082136999973, 19.260971069999982],
                [-16.472360609999953, 19.260971069999982],
                [-16.472360609999953, 19.261806489000094],
                [-16.472639082999876, 19.261806489000094],
                [-16.472639082999876, 19.262638093000191],
                [-16.472917555999913, 19.262638093000191],
                [-16.472917555999913, 19.263193132000026],
                [-16.473192213999823, 19.263193132000026],
                [-16.473192213999823, 19.263750076000065],
                [-16.473474501999931, 19.263750076000065],
                [-16.473474501999931, 19.265138626],
                [-16.473749159999898, 19.265138626],
                [-16.473749159999898, 19.265972137000176],
                [-16.474027632999935, 19.265972137000176],
                [-16.474027632999935, 19.266805649000048],
                [-16.474306105999972, 19.266805649000048],
                [-16.474306105999972, 19.267639160000158],
                [-16.474584578999895, 19.267639160000158],
                [-16.474584578999895, 19.268749237000065],
                [-16.474861144999977, 19.268749237000065],
                [-16.474861144999977, 19.269582749000108],
                [-16.475139617999901, 19.269582749000108],
                [-16.475139617999901, 19.270416260000104],
                [-16.475416182999879, 19.270416260000104],
                [-16.475416182999879, 19.270971299000109],
                [-16.475694655999916, 19.270971299000109],
                [-16.475694655999916, 19.274305343000094],
                [-16.475973128999954, 19.274305343000094],
                [-16.475973128999954, 19.275138854000033],
                [-16.476249694999922, 19.275138854000033],
                [-16.476249694999922, 19.275693893000039],
                [-16.476528167999959, 19.275693893000039],
                [-16.476528167999959, 19.276527404000149],
                [-16.476806640999939, 19.276527404000149],
                [-16.476806640999939, 19.277360916000191],
                [-16.477083205999975, 19.277360916000191],
                [-16.477083205999975, 19.277639389000058],
                [-16.477361678999841, 19.277639389000058],
                [-16.477361678999841, 19.277914047000081],
                [-16.47763824499998, 19.277914047000081],
                [-16.47763824499998, 19.278474808000169],
                [-16.477916717999847, 19.278474808000169],
                [-16.477916717999847, 19.278749465999965],
                [-16.478195190999884, 19.278749465999965],
                [-16.478195190999884, 19.280136108000136],
                [-16.478471755999919, 19.280136108000136],
                [-16.478471755999919, 19.280416489000174],
                [-16.479028701999937, 19.280416489000174],
                [-16.479028701999937, 19.280696869999986],
                [-16.479583740999942, 19.280696869999986],
                [-16.479583740999942, 19.280971527000077],
                [-16.479860305999978, 19.280971527000077],
                [-16.479860305999978, 19.281250001000046],
                [-16.48069381799985, 19.281250001000046],
                [-16.48069381799985, 19.281528474000083],
                [-16.480972290999887, 19.281528474000083],
                [-16.480972290999887, 19.282638551000161],
                [-16.48069381799985, 19.282638551000161],
                [-16.48069381799985, 19.284860612000102],
                [-16.480417251999882, 19.284860612000102],
                [-16.480417251999882, 19.286527633000048],
                [-16.48069381799985, 19.286527633000048],
                [-16.48069381799985, 19.287082672000054],
                [-16.480972290999887, 19.287082672000054],
                [-16.480972290999887, 19.287359237000146],
                [-16.48152732899996, 19.287359237000146],
                [-16.48152732899996, 19.287639618000128],
                [-16.481805799999904, 19.287639618000128],
                [-16.481805799999904, 19.28791618300005],
                [-16.483194349999962, 19.28791618300005],
                [-16.483194349999962, 19.287639618000128],
                [-16.483749388999968, 19.287639618000128],
                [-16.483749388999968, 19.28791618300005],
                [-16.484306334999872, 19.28791618300005],
                [-16.484306334999872, 19.288471222000055],
                [-16.48458289999985, 19.288471222000055],
                [-16.48458289999985, 19.288749695000092],
                [-16.485137938999856, 19.288749695000092],
                [-16.485137938999856, 19.289304733000165],
                [-16.485416411999893, 19.289304733000165],
                [-16.485416411999893, 19.28986358700007],
                [-16.48569488499993, 19.28986358700007],
                [-16.48569488499993, 19.290416718000074],
                [-16.485971449999965, 19.290416718000074],
                [-16.485971449999965, 19.291250229000184],
                [-16.48625183099989, 19.291250229000184],
                [-16.48625183099989, 19.291528702000051],
                [-16.486528396999972, 19.291528702000051],
                [-16.486528396999972, 19.291803360000074],
                [-16.486803053999949, 19.291803360000074],
                [-16.486803053999949, 19.292085648000125],
                [-16.487081526999816, 19.292085648000125],
                [-16.487081526999816, 19.292638779000185],
                [-16.48736381599997, 19.292638779000185],
                [-16.48736381599997, 19.292917252],
                [-16.48763847399988, 19.292917252],
                [-16.48763847399988, 19.293195725000032],
                [-16.487916946999917, 19.293195725000032],
                [-16.487916946999917, 19.293472291],
                [-16.488195419999954, 19.293472291],
                [-16.488195419999954, 19.293750764000038],
                [-16.488473892999934, 19.293750764000038],
                [-16.488473892999934, 19.29402732900013],
                [-16.48875045799997, 19.29402732900013],
                [-16.48875045799997, 19.294307710000112],
                [-16.489027023999938, 19.294307710000112],
                [-16.489027023999938, 19.294584275000034],
                [-16.489305496999975, 19.294584275000034],
                [-16.489305496999975, 19.294860841000173],
                [-16.489585876999968, 19.294860841000173],
                [-16.489585876999968, 19.295139314000039],
                [-16.489860534999877, 19.295139314000039],
                [-16.489860534999877, 19.295417787000076],
                [-16.490972519999957, 19.295417787000076],
                [-16.490972519999957, 19.295694352000112],
                [-16.491527557999973, 19.295694352000112],
                [-16.491527557999973, 19.299304962000065],
                [-16.491807936999976, 19.299304962000065],
                [-16.491807936999976, 19.300970077000102],
                [-16.492082596999978, 19.300970077000102],
                [-16.492082596999978, 19.302082062000181],
                [-16.492361069999845, 19.302082062000181],
                [-16.492361069999845, 19.303749085000163],
                [-16.492639542999882, 19.303749085000163],
                [-16.492639542999882, 19.30541420000003],
                [-16.492916107999918, 19.30541420000003],
                [-16.492916107999918, 19.307083131000184],
                [-16.493194578999976, 19.307083131000184],
                [-16.493194578999976, 19.307916642000123],
                [-16.493473051999899, 19.307916642000123],
                [-16.493473051999899, 19.309860229000094],
                [-16.493749617999981, 19.309860229000094],
                [-16.493749617999981, 19.310693740000033],
                [-16.494028090999905, 19.310693740000033],
                [-16.494028090999905, 19.31097221300007],
                [-16.494304655999883, 19.31097221300007],
                [-16.494304655999883, 19.311527252000076],
                [-16.494583128999921, 19.311527252000076],
                [-16.494583128999921, 19.31208419800015],
                [-16.494861601999958, 19.31208419800015],
                [-16.494861601999958, 19.312915802000191],
                [-16.495138167999926, 19.312915802000191],
                [-16.495138167999926, 19.3140277870001],
                [-16.495416640999963, 19.3140277870001],
                [-16.495416640999963, 19.314582825],
                [-16.495695113999943, 19.314582825],
                [-16.495695113999943, 19.315694810000082],
                [-16.495971678999979, 19.315694810000082],
                [-16.495971678999979, 19.316804887000046],
                [-16.496248244999947, 19.316804887000046],
                [-16.496248244999947, 19.317363740000189],
                [-16.496528624999939, 19.317363740000189],
                [-16.496528624999939, 19.317916871000023],
                [-16.496805190999908, 19.317916871000023],
                [-16.496805190999908, 19.318193437000161],
                [-16.497083663999945, 19.318193437000161],
                [-16.497083663999945, 19.318473817000097],
                [-16.497360228999923, 19.318473817000097],
                [-16.497360228999923, 19.318750383000065],
                [-16.497917174999941, 19.318750383000065],
                [-16.497917174999941, 19.3195858],
                [-16.498193740999966, 19.3195858],
                [-16.498193740999966, 19.320138931000031],
                [-16.498470305999945, 19.320138931000031],
                [-16.498470305999945, 19.320695877000105],
                [-16.498752593999939, 19.320695877000105],
                [-16.498752593999939, 19.320972442000141],
                [-16.499027251999848, 19.320972442000141],
                [-16.499027251999848, 19.321527481000146],
                [-16.499305724999886, 19.321527481000146],
                [-16.499305724999886, 19.322082519000048],
                [-16.499582290999854, 19.322082519000048],
                [-16.499582290999854, 19.322917937999989],
                [-16.49986267099996, 19.322917937999989],
                [-16.49986267099996, 19.323749542000087],
                [-16.500139236999928, 19.323749542000087],
                [-16.500139236999928, 19.324029923000069],
                [-16.500415801999964, 19.324029923000069],
                [-16.500415801999964, 19.326250077000111],
                [-16.500692367999932, 19.326250077000111],
                [-16.500692367999932, 19.326805115000184],
                [-16.500974655999926, 19.326805115000184],
                [-16.500974655999926, 19.327638627000056],
                [-16.501249313999949, 19.327638627000056],
                [-16.501249313999949, 19.329305650000038],
                [-16.501527786999873, 19.329305650000038],
                [-16.501527786999873, 19.330415727000172],
                [-16.5018062599999, 19.330417632000035],
                [-16.5018062599999, 19.330692292000037],
                [-16.502084732999947, 19.330692292000037],
                [-16.502084732999947, 19.330974578000109],
                [-16.502916336999931, 19.330974578000109],
                [-16.502916336999931, 19.331249238000112],
                [-16.503471374999947, 19.331249238000112],
                [-16.503471374999947, 19.33152770900017],
                [-16.50374984799987, 19.33152770900017],
                [-16.50374984799987, 19.331804275000138],
                [-16.504028320999907, 19.331804275000138],
                [-16.504028320999907, 19.332639694000079],
                [-16.504306791999966, 19.332639694000079],
                [-16.504306791999966, 19.334583282000096],
                [-16.504583357999934, 19.334583282000096],
                [-16.504583357999934, 19.334861755000134],
                [-16.504861830999971, 19.334861755000134],
                [-16.504861830999971, 19.335695267000176],
                [-16.50513839599995, 19.335695267000176],
                [-16.50513839599995, 19.335971832000041],
                [-16.505416868999873, 19.335971832000041],
                [-16.505416868999873, 19.336250305000078],
                [-16.50569534199991, 19.336250305000078],
                [-16.50569534199991, 19.33763885500008],
                [-16.505971907999879, 19.33763885500008],
                [-16.505971907999879, 19.338193894000085],
                [-16.506250380999916, 19.338193894000085],
                [-16.506250380999916, 19.339027405000024],
                [-16.506528853999953, 19.339027405000024],
                [-16.506528853999953, 19.339305878000062],
                [-16.506805418999932, 19.339305878000062],
                [-16.506805418999932, 19.339860917000067],
                [-16.507083891999969, 19.339860917000067],
                [-16.507083891999969, 19.340972901000043],
                [-16.507360457999937, 19.340972901000043],
                [-16.507360457999937, 19.341806413000086],
                [-16.507638930999974, 19.341806413000086],
                [-16.507638930999974, 19.342638017000183],
                [-16.507917403999897, 19.342638017000183],
                [-16.507917403999897, 19.343749999000124],
                [-16.508193968999876, 19.343749999000124],
                [-16.508193968999876, 19.344303130000185],
                [-16.508472441999913, 19.344303130000185],
                [-16.508472441999913, 19.344585419000168],
                [-16.508750914999951, 19.344585419000168],
                [-16.508750914999951, 19.345138549000126],
                [-16.509027480999919, 19.345138549000126],
                [-16.509027480999919, 19.345695496000076],
                [-16.509305953999956, 19.345695496000076],
                [-16.509305953999956, 19.346250534000035],
                [-16.509582518999935, 19.346250534000035],
                [-16.509582518999935, 19.346803665000039],
                [-16.509859084999903, 19.346803665000039],
                [-16.509859084999903, 19.347360611000113],
                [-16.509582518999935, 19.347360611000113],
                [-16.509582518999935, 19.348194123000155],
                [-16.509859084999903, 19.348194123000155],
                [-16.509859084999903, 19.348472596000022],
                [-16.510139464999895, 19.348472596000022],
                [-16.510139464999895, 19.348749161000057],
                [-16.510416030999977, 19.348749161000057],
                [-16.510416030999977, 19.349027634000095],
                [-16.5106945039999, 19.349027634000095],
                [-16.5106945039999, 19.349306107000132],
                [-16.511249541999916, 19.349306107000132],
                [-16.511249541999916, 19.349861146000137],
                [-16.511528014999953, 19.349861146000137],
                [-16.511528014999953, 19.350139619000174],
                [-16.511804580999922, 19.350139619000174],
                [-16.511806487999934, 19.350694657000076],
                [-16.5120811459999, 19.350694657000076],
                [-16.5120811459999, 19.350973130000114],
                [-16.512363433999894, 19.350973130000114],
                [-16.512363433999894, 19.351528169000119],
                [-16.512638091999975, 19.351528169000119],
                [-16.512638091999975, 19.351804734000041],
                [-16.512916564999841, 19.351804734000041],
                [-16.512916564999841, 19.352083207000078],
                [-16.514028549999921, 19.352083207000078],
                [-16.514028549999921, 19.352361680000115],
                [-16.514303207999887, 19.352361680000115],
                [-16.514303207999887, 19.352638246000083],
                [-16.514581680999925, 19.352638246000083],
                [-16.514581680999925, 19.353195192000157],
                [-16.514860153999962, 19.353195192000157],
                [-16.514862060999974, 19.353471757000023],
                [-16.515138626999942, 19.353471757000023],
                [-16.515138626999942, 19.353750228000081],
                [-16.515417099999979, 19.353750228000081],
                [-16.515417099999979, 19.354028701000118],
                [-16.515695570999981, 19.354028701000118],
                [-16.515695570999981, 19.354305267000086],
                [-16.515972135999959, 19.354305267000086],
                [-16.515972135999959, 19.354583740000123],
                [-16.516250609999929, 19.354583740000123],
                [-16.516250609999929, 19.354860305000159],
                [-16.516527174999965, 19.354860305000159],
                [-16.516527174999965, 19.355417251000063],
                [-16.516805647999945, 19.355417251000063],
                [-16.516805647999945, 19.355693817000031],
                [-16.517084120999982, 19.355693817000031],
                [-16.517084120999982, 19.355972290000068],
                [-16.517360685999904, 19.355972290000068],
                [-16.517360685999904, 19.356250763000105],
                [-16.517639159999874, 19.356250763000105],
                [-16.517639159999874, 19.356527328000141],
                [-16.518194197999946, 19.356527328000141],
                [-16.518194197999946, 19.356805801000178],
                [-16.518472670999927, 19.356805801000178],
                [-16.518472670999927, 19.357082367000146],
                [-16.518749236999952, 19.357082367000146],
                [-16.518749236999952, 19.357360840000183],
                [-16.519027709999932, 19.357360840000183],
                [-16.519027709999932, 19.35763931300005],
                [-16.519306182999969, 19.35763931300005],
                [-16.519306182999969, 19.357915878000085],
                [-16.519582747999948, 19.357915878000085],
                [-16.519582747999948, 19.358192444000053],
                [-16.519861220999871, 19.358192444000053],
                [-16.519861220999871, 19.358749390000128],
                [-16.520139693999909, 19.358749390000128],
                [-16.520139693999909, 19.35902786299999],
                [-16.520416259999877, 19.35902786299999],
                [-16.520416259999877, 19.359306336000031],
                [-16.520694732999914, 19.359306336000031],
                [-16.520694732999914, 19.359584809000069],
                [-16.520971297999893, 19.359584809000069],
                [-16.520971297999893, 19.359861374000161],
                [-16.52124977099993, 19.359861374000161],
                [-16.52124977099993, 19.360137940000129],
                [-16.521528243999967, 19.360139847000028],
                [-16.521528243999967, 19.360414505000165],
                [-16.521804809999935, 19.360414505000165],
                [-16.521804809999935, 19.360971451000069],
                [-16.522083282999972, 19.360971451000069],
                [-16.522083282999972, 19.361249924000106],
                [-16.522361755999896, 19.361249924000106],
                [-16.522361755999896, 19.361528397000143],
                [-16.522638320999874, 19.361528397000143],
                [-16.522638320999874, 19.362083436000148],
                [-16.522916793999912, 19.362083436000148],
                [-16.522916793999912, 19.362361909000185],
                [-16.523471832999917, 19.362361909000185],
                [-16.523471832999917, 19.36263847400005],
                [-16.523750305999954, 19.36263847400005],
                [-16.523750305999954, 19.362918855000089],
                [-16.524026870999933, 19.362918855000089],
                [-16.524026870999933, 19.363193513000056],
                [-16.52430534399997, 19.363195420000125],
                [-16.52430534399997, 19.364028932000167],
                [-16.524583816999893, 19.364028932000167],
                [-16.524583816999893, 19.364305497000032],
                [-16.524860382999975, 19.364305497000032],
                [-16.524860382999975, 19.364583970000069],
                [-16.526527405999957, 19.364583970000069],
                [-16.526527405999957, 19.365417480000133],
                [-16.526252745999898, 19.365417480000133],
                [-16.526252745999898, 19.365972518000035],
                [-16.525970459999883, 19.365972518000035],
                [-16.525970459999883, 19.36708259500017],
                [-16.526252745999898, 19.36708259500017],
                [-16.526252745999898, 19.367639541000074],
                [-16.526527405999957, 19.367639541000074],
                [-16.526527405999957, 19.367916107000042],
                [-16.527084349999939, 19.367916107000042],
                [-16.527084349999939, 19.368194580000079],
                [-16.527362822999976, 19.368194580000079],
                [-16.527362822999976, 19.368471145000115],
                [-16.527914047999957, 19.368471145000115],
                [-16.527914047999957, 19.368749618000152],
                [-16.52819252099988, 19.368749618000152],
                [-16.52819252099988, 19.369028091000189],
                [-16.528474806999895, 19.369028091000189],
                [-16.528474806999895, 19.369304657000157],
                [-16.528749464999976, 19.369304657000157],
                [-16.528749464999976, 19.369581222000022],
                [-16.529306410999936, 19.369581222000022],
                [-16.529306410999936, 19.369861603000061],
                [-16.529584883999973, 19.369861603000061],
                [-16.529584883999973, 19.370138168000096],
                [-16.53013801499992, 19.370138168000096],
                [-16.53013801499992, 19.370416641000133],
                [-16.531806945999904, 19.370416641000133],
                [-16.531806945999904, 19.370693207000102],
                [-16.532638549999945, 19.370693207000102],
                [-16.532638549999945, 19.370971680000139],
                [-16.533193587999961, 19.370971680000139],
                [-16.533193587999961, 19.371250153000176],
                [-16.533472060999941, 19.371250153000176],
                [-16.533472060999941, 19.371528626000043],
                [-16.534027099999946, 19.371528626000043],
                [-16.534027099999946, 19.371803284],
                [-16.534860610999885, 19.371803284],
                [-16.534860610999885, 19.372085572000117],
                [-16.53541755699996, 19.372085572000117],
                [-16.53541755699996, 19.372360230000083],
                [-16.535694122999928, 19.372360230000083],
                [-16.535694122999928, 19.372638703000121],
                [-16.536249160999944, 19.372638703000121],
                [-16.536249160999944, 19.372917175999987],
                [-16.537082672999873, 19.372917175999987],
                [-16.537082672999873, 19.373195649000024],
                [-16.537639618999947, 19.373195649000024],
                [-16.537639618999947, 19.373472214000117],
                [-16.538749695999968, 19.373472214000117],
                [-16.538749695999968, 19.373750687000154],
                [-16.539028166999913, 19.373750687000154],
                [-16.539028166999913, 19.374025345000121],
                [-16.539581299999952, 19.374025345000121],
                [-16.539581299999952, 19.374307633000058],
                [-16.539863585999854, 19.374307633000058],
                [-16.539863585999854, 19.374584199000026],
                [-16.540138243999934, 19.374584199000026],
                [-16.540138243999934, 19.374860764000061],
                [-16.540695189999894, 19.374860764000061],
                [-16.540695189999894, 19.375139237000099],
                [-16.540973662999932, 19.375139237000099],
                [-16.540973662999932, 19.375417710000136],
                [-16.54125022799991, 19.375417710000136],
                [-16.54125022799991, 19.375694276000104],
                [-16.541528700999947, 19.375694276000104],
                [-16.541528700999947, 19.375972749000141],
                [-16.541803358999857, 19.375972749000141],
                [-16.541803358999857, 19.376249314000177],
                [-16.542360304999931, 19.376249314000177],
                [-16.542360304999931, 19.376527785000064],
                [-16.542638777999969, 19.376527785000064],
                [-16.542638777999969, 19.376806258999977],
                [-16.542917250999892, 19.376806258999977],
                [-16.542917250999892, 19.37708282400007],
                [-16.543195723999929, 19.37708282400007],
                [-16.543195723999929, 19.377361297000107],
                [-16.543472289999897, 19.377361297000107],
                [-16.543472289999897, 19.378749847000051],
                [-16.543750762999935, 19.378749847000051],
                [-16.543750762999935, 19.379861832000131],
                [-16.544027327999913, 19.379861832000131],
                [-16.544027327999913, 19.380416870000033],
                [-16.544307708999952, 19.380416870000033],
                [-16.544307708999952, 19.381250382000076],
                [-16.544582366999919, 19.381250382000076],
                [-16.544584273999931, 19.384304047000057],
                [-16.544860839999956, 19.384304047000057],
                [-16.544860839999956, 19.384582520000095],
                [-16.544584273999931, 19.384582520000095],
                [-16.544582366999919, 19.385414123999965],
                [-16.544027327999913, 19.385414123999965],
                [-16.544027327999913, 19.385696412000073],
                [-16.543472289999897, 19.385696412000073],
                [-16.543472289999897, 19.38597488500011],
                [-16.542917250999892, 19.38597488500011],
                [-16.542917250999892, 19.386249543000076],
                [-16.542360304999931, 19.386249543000076],
                [-16.542360304999931, 19.386528016000113],
                [-16.541803358999857, 19.386528016000113],
                [-16.541803358999857, 19.38680648899998],
                [-16.540973662999932, 19.38680648899998],
                [-16.540973662999932, 19.387083055000119],
                [-16.540138243999934, 19.387083055000119],
                [-16.540138243999934, 19.387361527999985],
                [-16.539304731999948, 19.387361527999985],
                [-16.539304731999948, 19.387636186000179],
                [-16.538471222999931, 19.387636186000179],
                [-16.538471222999931, 19.387916566000115],
                [-16.53795051599991, 19.387916566000115],
                [-16.537082672999873, 19.387916566000115],
                [-16.537082672999873, 19.388196945000118],
                [-16.53125, 19.388196945000118],
                [-16.53125, 19.388471603000085],
                [-16.530971526999963, 19.388471603000085],
                [-16.530971526999963, 19.389028549000159],
                [-16.52819252099988, 19.389028549000159],
                [-16.52819252099988, 19.389305114000081],
                [-16.526805878999937, 19.389305114000081],
                [-16.526805878999937, 19.389583587000118],
                [-16.524583816999893, 19.389583587000118],
                [-16.524583816999893, 19.389860153000086],
                [-16.52430534399997, 19.389862060000155],
                [-16.52430534399997, 19.390138626000123],
                [-16.522083282999972, 19.390138626000123],
                [-16.522083282999972, 19.39041709900016],
                [-16.521528243999967, 19.39041709900016],
                [-16.521528243999967, 19.390693664000025],
                [-16.520694732999914, 19.390693664000025],
                [-16.520694732999914, 19.390972137000063],
                [-16.519027709999932, 19.390972137000063],
                [-16.519027709999932, 19.3912506100001],
                [-16.517084120999982, 19.3912506100001],
                [-16.517084120999982, 19.390972137000063],
                [-16.514581680999925, 19.390972137000063],
                [-16.514581680999925, 19.390693664000025],
                [-16.513750076999884, 19.390693664000025],
                [-16.513750076999884, 19.390972137000063],
                [-16.512916564999841, 19.390972137000063],
                [-16.512916564999841, 19.3912506100001],
                [-16.509027480999919, 19.3912506100001],
                [-16.509027480999919, 19.390972137000063],
                [-16.507083891999969, 19.390972137000063],
                [-16.507083891999969, 19.390693664000025],
                [-16.506528853999953, 19.390693664000025],
                [-16.506528853999953, 19.39041709900016],
                [-16.506250380999916, 19.39041709900016],
                [-16.506250380999916, 19.390138626000123],
                [-16.50513839599995, 19.390138626000123],
                [-16.50513839599995, 19.39041709900016],
                [-16.504028320999907, 19.39041709900016],
                [-16.504028320999907, 19.390693664000025],
                [-16.503196714999945, 19.390693664000025],
                [-16.503196714999945, 19.390972137000063],
                [-16.502361297999926, 19.390972137000063],
                [-16.502361297999926, 19.3912506100001],
                [-16.501527786999873, 19.3912506100001],
                [-16.501527786999873, 19.391527176000068],
                [-16.499582290999854, 19.391527176000068],
                [-16.499582290999854, 19.391805649000105],
                [-16.498193740999966, 19.391805649000105],
                [-16.498193740999966, 19.391527176000068],
                [-16.497917174999941, 19.391527176000068],
                [-16.497917174999941, 19.3912506100001],
                [-16.497638701999961, 19.3912506100001],
                [-16.497638701999961, 19.390972137000063],
                [-16.497360228999923, 19.390972137000063],
                [-16.497360228999923, 19.390693664000025],
                [-16.497083663999945, 19.390693664000025],
                [-16.497083663999945, 19.389862060000155],
                [-16.496805190999908, 19.389860153000086],
                [-16.496805190999908, 19.389305114000081],
                [-16.496528624999939, 19.389305114000081],
                [-16.496528624999939, 19.388471603000085],
                [-16.496248244999947, 19.388471603000085],
                [-16.496248244999947, 19.388196945000118],
                [-16.495971678999979, 19.388196945000118],
                [-16.495971678999979, 19.387916566000115],
                [-16.494583128999921, 19.387916566000115],
                [-16.494583128999921, 19.387636186000179],
                [-16.493749617999981, 19.387636186000179],
                [-16.493749617999981, 19.387361527999985],
                [-16.492916107999918, 19.387361527999985],
                [-16.492916107999918, 19.387083055000119],
                [-16.492639542999882, 19.387083055000119],
                [-16.492639542999882, 19.38680648899998],
                [-16.492361069999845, 19.38680648899998],
                [-16.492361069999845, 19.386528016000113],
                [-16.492082596999978, 19.386528016000113],
                [-16.492082596999978, 19.386249543000076],
                [-16.491807936999976, 19.386249543000076],
                [-16.491807936999976, 19.38597488500011],
                [-16.491527557999973, 19.38597488500011],
                [-16.491527557999973, 19.385696412000073],
                [-16.490972519999957, 19.385696412000073],
                [-16.490972519999957, 19.385414123999965],
                [-16.490695953999932, 19.385414123999965],
                [-16.490695953999932, 19.385139466000169],
                [-16.490417480999952, 19.385139466000169],
                [-16.490417480999952, 19.384860993000132],
                [-16.490139007999915, 19.384860993000132],
                [-16.490139007999915, 19.384304047000057],
                [-16.489860534999877, 19.384304047000057],
                [-16.489860534999877, 19.384027482000022],
                [-16.489585876999968, 19.384027482000022],
                [-16.489585876999968, 19.383752824],
                [-16.489305496999975, 19.383752824],
                [-16.489305496999975, 19.383472443000187],
                [-16.489027023999938, 19.383472443000187],
                [-16.489027023999938, 19.383192063000081],
                [-16.48875045799997, 19.383192063000081],
                [-16.48875045799997, 19.382917405000114],
                [-16.488473892999934, 19.382917405000114],
                [-16.488473892999934, 19.38236045900004],
                [-16.488195419999954, 19.38236045900004],
                [-16.488195419999954, 19.381805420000035],
                [-16.487916946999917, 19.381805420000035],
                [-16.487916946999917, 19.381250382000076],
                [-16.488195419999954, 19.381250382000076],
                [-16.488195419999954, 19.38069534300007],
                [-16.488473892999934, 19.38069534300007],
                [-16.488473892999934, 19.380416870000033],
                [-16.489860534999877, 19.380416870000033],
                [-16.489860534999877, 19.38069534300007],
                [-16.490139007999915, 19.38069534300007],
                [-16.490139007999915, 19.380971909000039],
                [-16.490417480999952, 19.380971909000039],
                [-16.490417480999952, 19.381250382000076],
                [-16.490695953999932, 19.381250382000076],
                [-16.490695953999932, 19.382083893000072],
                [-16.490972519999957, 19.382083893000072],
                [-16.490972519999957, 19.38236045900004],
                [-16.491249084999936, 19.38236045900004],
                [-16.491249084999936, 19.382638932000077],
                [-16.491527557999973, 19.382638932000077],
                [-16.491527557999973, 19.382917405000114],
                [-16.491807936999976, 19.382917405000114],
                [-16.491807936999976, 19.383192063000081],
                [-16.492082596999978, 19.383192063000081],
                [-16.492082596999978, 19.383472443000187],
                [-16.492361069999845, 19.383472443000187],
                [-16.492361069999845, 19.383752824],
                [-16.492639542999882, 19.383752824],
                [-16.492639542999882, 19.384027482000022],
                [-16.492916107999918, 19.384027482000022],
                [-16.492916107999918, 19.384304047000057],
                [-16.493194578999976, 19.384304047000057],
                [-16.493194578999976, 19.384860993000132],
                [-16.493473051999899, 19.384860993000132],
                [-16.493473051999899, 19.385414123999965],
                [-16.493749617999981, 19.385414123999965],
                [-16.493749617999981, 19.385696412000073],
                [-16.494028090999905, 19.385696412000073],
                [-16.494028090999905, 19.38597488500011],
                [-16.494583128999921, 19.38597488500011],
                [-16.494583128999921, 19.385696412000073],
                [-16.494861601999958, 19.385696412000073],
                [-16.494861601999958, 19.385139466000169],
                [-16.495138167999926, 19.385139466000169],
                [-16.495138167999926, 19.385414123999965],
                [-16.495695113999943, 19.385414123999965],
                [-16.495695113999943, 19.385696412000073],
                [-16.496248244999947, 19.385696412000073],
                [-16.496248244999947, 19.38597488500011],
                [-16.496805190999908, 19.38597488500011],
                [-16.496805190999908, 19.386249543000076],
                [-16.497360228999923, 19.386249543000076],
                [-16.497360228999923, 19.387083055000119],
                [-16.497638701999961, 19.387083055000119],
                [-16.497638701999961, 19.387361527999985],
                [-16.497917174999941, 19.387361527999985],
                [-16.497917174999941, 19.387636186000179],
                [-16.498470305999945, 19.387636186000179],
                [-16.498470305999945, 19.387916566000115],
                [-16.499305724999886, 19.387916566000115],
                [-16.499305724999886, 19.387636186000179],
                [-16.500692367999932, 19.387636186000179],
                [-16.500692367999932, 19.387916566000115],
                [-16.500974655999926, 19.387916566000115],
                [-16.500974655999926, 19.388196945000118],
                [-16.501527786999873, 19.388196945000118],
                [-16.501527786999873, 19.388471603000085],
                [-16.502361297999926, 19.388471603000085],
                [-16.502361297999926, 19.388750076000122],
                [-16.503471374999947, 19.388750076000122],
                [-16.503471374999947, 19.388471603000085],
                [-16.50374984799987, 19.388471603000085],
                [-16.50374984799987, 19.386528016000113],
                [-16.503471374999947, 19.386528016000113],
                [-16.503471374999947, 19.386249543000076],
                [-16.499027251999848, 19.386249543000076],
                [-16.499027251999848, 19.38597488500011],
                [-16.498752593999939, 19.38597488500011],
                [-16.498752593999939, 19.385414123999965],
                [-16.498470305999945, 19.385414123999965],
                [-16.498470305999945, 19.384027482000022],
                [-16.498193740999966, 19.384027482000022],
                [-16.498193740999966, 19.383472443000187],
                [-16.497917174999941, 19.383472443000187],
                [-16.497917174999941, 19.383192063000081],
                [-16.497638701999961, 19.383192063000081],
                [-16.497638701999961, 19.382917405000114],
                [-16.497083663999945, 19.382917405000114],
                [-16.497083663999945, 19.382638932000077],
                [-16.496528624999939, 19.382638932000077],
                [-16.496528624999939, 19.38236045900004],
                [-16.495695113999943, 19.38236045900004],
                [-16.495695113999943, 19.382083893000072],
                [-16.495138167999926, 19.382083893000072],
                [-16.495138167999926, 19.381805420000035],
                [-16.494861601999958, 19.381805420000035],
                [-16.494861601999958, 19.381526947000168],
                [-16.494583128999921, 19.381526947000168],
                [-16.494583128999921, 19.380971909000039],
                [-16.495971678999979, 19.380971909000039],
                [-16.495971678999979, 19.381250382000076],
                [-16.497360228999923, 19.381250382000076],
                [-16.497360228999923, 19.381526947000168],
                [-16.498193740999966, 19.381526947000168],
                [-16.498193740999966, 19.381805420000035],
                [-16.500139236999928, 19.381805420000035],
                [-16.500139236999928, 19.381526947000168],
                [-16.500692367999932, 19.381526947000168],
                [-16.500692367999932, 19.381250382000076],
                [-16.500974655999926, 19.381250382000076],
                [-16.500974655999926, 19.380138397],
                [-16.500692367999932, 19.380138397],
                [-16.500692367999932, 19.379861832000131],
                [-16.500139236999928, 19.379861832000131],
                [-16.500139236999928, 19.379583359000094],
                [-16.49986267099996, 19.379583359000094],
                [-16.49986267099996, 19.379304886000057],
                [-16.499582290999854, 19.379304886000057],
                [-16.499582290999854, 19.379028320000089],
                [-16.499305724999886, 19.379028320000089],
                [-16.499305724999886, 19.378749847000051],
                [-16.498193740999966, 19.378749847000051],
                [-16.498193740999966, 19.378471374000185],
                [-16.497917174999941, 19.378471374000185],
                [-16.497917174999941, 19.377916336000112],
                [-16.497638701999961, 19.377916336000112],
                [-16.497638701999961, 19.377639770000144],
                [-16.497083663999945, 19.377639770000144],
                [-16.497083663999945, 19.377361297000107],
                [-16.496805190999908, 19.377361297000107],
                [-16.496805190999908, 19.37708282400007],
                [-16.494304655999883, 19.37708282400007],
                [-16.494304655999883, 19.377361297000107],
                [-16.493473051999899, 19.377361297000107],
                [-16.493473051999899, 19.377639770000144],
                [-16.492639542999882, 19.377639770000144],
                [-16.492639542999882, 19.377916336000112],
                [-16.491807936999976, 19.377916336000112],
                [-16.491807936999976, 19.378194809000149],
                [-16.490139007999915, 19.378194809000149],
                [-16.490139007999915, 19.378471374000185],
                [-16.489305496999975, 19.378471374000185],
                [-16.489305496999975, 19.378749847000051],
                [-16.486528396999972, 19.378749847000051],
                [-16.486528396999972, 19.379028320000089],
                [-16.485971449999965, 19.379028320000089],
                [-16.485971449999965, 19.378749847000051],
                [-16.484306334999872, 19.378749847000051],
                [-16.484306334999872, 19.378471374000185],
                [-16.482639311999947, 19.378471374000185],
                [-16.482639311999947, 19.378194809000149],
                [-16.481805799999904, 19.378194809000149],
                [-16.481805799999904, 19.377916336000112],
                [-16.479860305999978, 19.377916336000112],
                [-16.479860305999978, 19.377639770000144],
                [-16.478750228999957, 19.377639770000144],
                [-16.478750228999957, 19.377361297000107],
                [-16.477916717999847, 19.377361297000107],
                [-16.477916717999847, 19.37708282400007],
                [-16.477361678999841, 19.37708282400007],
                [-16.477361678999841, 19.376806258999977],
                [-16.476806640999939, 19.376806258999977],
                [-16.476806640999939, 19.376527785000064],
                [-16.475973128999954, 19.376527785000064],
                [-16.475973128999954, 19.376249314000177],
                [-16.475694655999916, 19.376249314000177],
                [-16.475694655999916, 19.375972749000141],
                [-16.475416182999879, 19.375972749000141],
                [-16.475416182999879, 19.375417710000136],
                [-16.475694655999916, 19.375417710000136],
                [-16.475694655999916, 19.373750687000154],
                [-16.476806640999939, 19.373750687000154],
                [-16.476806640999939, 19.374025345000121],
                [-16.47763824499998, 19.374025345000121],
                [-16.47763824499998, 19.373750687000154],
                [-16.478195190999884, 19.373750687000154],
                [-16.478195190999884, 19.373472214000117],
                [-16.479860305999978, 19.373472214000117],
                [-16.479860305999978, 19.373750687000154],
                [-16.480972290999887, 19.373750687000154],
                [-16.480972290999887, 19.374025345000121],
                [-16.481250761999945, 19.374025345000121],
                [-16.481250761999945, 19.374307633000058],
                [-16.481805799999904, 19.374307633000058],
                [-16.481805799999904, 19.374584199000026],
                [-16.482639311999947, 19.374584199000026],
                [-16.482639311999947, 19.374860764000061],
                [-16.483194349999962, 19.374860764000061],
                [-16.483194349999962, 19.375139237000099],
                [-16.484306334999872, 19.375139237000099],
                [-16.484306334999872, 19.375417710000136],
                [-16.484859465999818, 19.375417710000136],
                [-16.484859465999818, 19.375139237000099],
                [-16.485137938999856, 19.375139237000099],
                [-16.485137938999856, 19.374307633000058],
                [-16.484859465999818, 19.374307633000058],
                [-16.484859465999818, 19.374025345000121],
                [-16.483749388999968, 19.374025345000121],
                [-16.483749388999968, 19.373750687000154],
                [-16.482915876999925, 19.373750687000154],
                [-16.482915876999925, 19.373472214000117],
                [-16.482360838999909, 19.373472214000117],
                [-16.482360838999909, 19.373195649000024],
                [-16.481805799999904, 19.373195649000024],
                [-16.481805799999904, 19.372917175999987],
                [-16.48152732899996, 19.372917175999987],
                [-16.48152732899996, 19.372638703000121],
                [-16.480417251999882, 19.372638703000121],
                [-16.480417251999882, 19.372085572000117],
                [-16.480138778999844, 19.372085572000117],
                [-16.480138778999844, 19.371528626000043],
                [-16.479860305999978, 19.371528626000043],
                [-16.479860305999978, 19.371250153000176],
                [-16.475973128999954, 19.371250153000176],
                [-16.475973128999954, 19.370971680000139],
                [-16.474027632999935, 19.370971680000139],
                [-16.474027632999935, 19.370693207000102],
                [-16.472917555999913, 19.370693207000102],
                [-16.472917555999913, 19.370416641000133],
                [-16.471805571999937, 19.370416641000133],
                [-16.471805571999937, 19.370693207000102],
                [-16.4715270989999, 19.370693207000102],
                [-16.4715270989999, 19.372085572000117],
                [-16.471805571999937, 19.372085572000117],
                [-16.471805571999937, 19.372360230000083],
                [-16.472082136999973, 19.372360230000083],
                [-16.472082136999973, 19.372638703000121],
                [-16.472360609999953, 19.372638703000121],
                [-16.472360609999953, 19.373195649000024],
                [-16.472639082999876, 19.373195649000024],
                [-16.472639082999876, 19.373472214000117],
                [-16.472360609999953, 19.373472214000117],
                [-16.472360609999953, 19.374025345000121],
                [-16.472082136999973, 19.374025345000121],
                [-16.472082136999973, 19.374307633000058],
                [-16.47125244099982, 19.374307633000058],
                [-16.47125244099982, 19.374584199000026],
                [-16.470417021999879, 19.374584199000026],
                [-16.470417021999879, 19.374307633000058],
                [-16.469305038999892, 19.374307633000058],
                [-16.469305038999892, 19.374025345000121],
                [-16.467916488999947, 19.374025345000121],
                [-16.467916488999947, 19.373750687000154],
                [-16.466806411999926, 19.373750687000154],
                [-16.466806411999926, 19.373472214000117],
                [-16.465417861999981, 19.373472214000117],
                [-16.465417861999981, 19.373195649000024],
                [-16.462360381999929, 19.373195649000024],
                [-16.462360381999929, 19.373472214000117],
                [-16.460695265999902, 19.373472214000117],
                [-16.460695265999902, 19.373750687000154],
                [-16.459304808999889, 19.373750687000154],
                [-16.459304808999889, 19.374025345000121],
                [-16.457082748999881, 19.374025345000121],
                [-16.457082748999881, 19.374307633000058],
                [-16.456527710999978, 19.374307633000058],
                [-16.456527710999978, 19.374584199000026],
                [-16.455415725999899, 19.374584199000026],
                [-16.455415725999899, 19.374860764000061],
                [-16.453472137999938, 19.374860764000061],
                [-16.453472137999938, 19.375139237000099],
                [-16.452362060999917, 19.375139237000099],
                [-16.452360152999859, 19.375417710000136],
                [-16.451805113999853, 19.375417710000136],
                [-16.451805113999853, 19.375139237000099],
                [-16.451528548999875, 19.375139237000099],
                [-16.451528548999875, 19.374860764000061],
                [-16.450696944999891, 19.374860764000061],
                [-16.450696944999891, 19.375139237000099],
                [-16.450138090999928, 19.375139237000099],
                [-16.450138090999928, 19.375417710000136],
                [-16.44902801399985, 19.375417710000136],
                [-16.44902801399985, 19.375694276000104],
                [-16.448194502999968, 19.375694276000104],
                [-16.448194502999968, 19.375972749000141],
                [-16.447639463999963, 19.375972749000141],
                [-16.447639463999963, 19.376249314000177],
                [-16.445692062999967, 19.376249314000177],
                [-16.445692062999967, 19.376527785000064],
                [-16.444583892999844, 19.376527785000064],
                [-16.444583892999844, 19.376806258999977],
                [-16.444026946999941, 19.376806258999977],
                [-16.444026946999941, 19.37708282400007],
                [-16.443471908999925, 19.37708282400007],
                [-16.443471908999925, 19.377361297000107],
                [-16.443195342999957, 19.377361297000107],
                [-16.443195342999957, 19.377639770000144],
                [-16.442638396999882, 19.377639770000144],
                [-16.442638396999882, 19.377916336000112],
                [-16.44208335899998, 19.377916336000112],
                [-16.44208335899998, 19.378194809000149],
                [-16.441528319999975, 19.378194809000149],
                [-16.441528319999975, 19.378471374000185],
                [-16.440971373999901, 19.378471374000185],
                [-16.440971373999901, 19.378749847000051],
                [-16.440416335999885, 19.378749847000051],
                [-16.440416335999885, 19.379028320000089],
                [-16.439861296999879, 19.379028320000089],
                [-16.439861296999879, 19.379304886000057],
                [-16.439582823999956, 19.379304886000057],
                [-16.439582823999956, 19.379583359000094],
                [-16.439306258999977, 19.379583359000094],
                [-16.439306258999977, 19.379861832000131],
                [-16.438749312999903, 19.379861832000131],
                [-16.438749312999903, 19.380138397],
                [-16.438472746999935, 19.380138397],
                [-16.438472746999935, 19.380416870000033],
                [-16.438194273999898, 19.380416870000033],
                [-16.438222884999959, 19.380769729],
                [-16.438051224999981, 19.380748748000144],
                [-16.437639235999882, 19.38069534300007],
                [-16.437639235999882, 19.380971909000039],
                [-16.437360762999958, 19.380971909000039],
                [-16.437406539999927, 19.381242751000059],
                [-16.437084196999876, 19.381250382000076],
                [-16.437112807999938, 19.381565094],
                [-16.436805723999953, 19.381526947000168],
                [-16.436805723999953, 19.381805420000035],
                [-16.436527250999973, 19.381805420000035],
                [-16.436527250999973, 19.381952287000161],
                [-16.436527250999973, 19.382083893000072],
                [-16.436349867999979, 19.382083893000072],
                [-16.435972214999879, 19.382083893000072],
                [-16.435972214999879, 19.38236045900004],
                [-16.435417175999874, 19.38236045900004],
                [-16.435445784999956, 19.382677078000029],
                [-16.43513870299995, 19.382638932000077],
                [-16.43513870299995, 19.382896424000023],
                [-16.43513870299995, 19.383192063000081],
                [-16.434585571999833, 19.383192063000081],
                [-16.434585571999833, 19.383289337000122],
                [-16.434585571999833, 19.383472443000187],
                [-16.434303283999895, 19.383485794000137],
                [-16.434303283999895, 19.383752824],
                [-16.433931351999945, 19.383752824],
                [-16.433750152999892, 19.383752824],
                [-16.433750152999892, 19.383878707000065],
                [-16.433750152999892, 19.384027482000022],
                [-16.433473587999913, 19.384075164000024],
                [-16.433473587999913, 19.384304047000057],
                [-16.433147429999963, 19.384304047000057],
                [-16.432916641999952, 19.384304047000057],
                [-16.432916641999952, 19.384506225999985],
                [-16.432916641999952, 19.384582520000095],
                [-16.432363510999892, 19.384582520000095],
                [-16.432363510999892, 19.384860993000132],
                [-16.43180465699993, 19.384860993000132],
                [-16.431829452999978, 19.385175705000051],
                [-16.43097114599982, 19.385139466000169],
                [-16.43097114599982, 19.385414123999965],
                [-16.430416106999814, 19.385414123999965],
                [-16.430416106999814, 19.385696412000073],
                [-16.429306029999964, 19.385696412000073],
                [-16.429306029999964, 19.38597488500011],
                [-16.428194045999817, 19.38597488500011],
                [-16.428194045999817, 19.386249543000076],
                [-16.427637099999913, 19.386249543000076],
                [-16.427637099999913, 19.386528016000113],
                [-16.425971983999887, 19.386528016000113],
                [-16.425971983999887, 19.38680648899998],
                [-16.425138472999947, 19.38680648899998],
                [-16.425138472999947, 19.387083055000119],
                [-16.424306868999963, 19.387083055000119],
                [-16.424306868999963, 19.387361527999985],
                [-16.423471451999944, 19.387361527999985],
                [-16.423471451999944, 19.387636186000179],
                [-16.422361374999923, 19.387636186000179],
                [-16.422361374999923, 19.387916566000115],
                [-16.421527862999881, 19.387916566000115],
                [-16.421527862999881, 19.388196945000118],
                [-16.42069244399994, 19.388196945000118],
                [-16.42069244399994, 19.388471603000085],
                [-16.419862747999957, 19.388471603000085],
                [-16.419862747999957, 19.388750076000122],
                [-16.418752670999936, 19.388750076000122],
                [-16.418752670999936, 19.389028549000159],
                [-16.418193816999974, 19.389028549000159],
                [-16.418193816999974, 19.389305114000081],
                [-16.417638777999969, 19.389305114000081],
                [-16.417638777999969, 19.389583587000118],
                [-16.417083739999896, 19.389583587000118],
                [-16.417083739999896, 19.389860153000086],
                [-16.416805266999859, 19.389862060000155],
                [-16.416805266999859, 19.390138626000123],
                [-16.415971754999816, 19.390138626000123],
                [-16.415971754999816, 19.39041709900016],
                [-16.415416716999971, 19.39041709900016],
                [-16.415416716999971, 19.390693664000025],
                [-16.414861677999966, 19.390693664000025],
                [-16.414861677999966, 19.390972137000063],
                [-16.414304731999891, 19.390972137000063],
                [-16.414304731999891, 19.3912506100001],
                [-16.41347312799985, 19.3912506100001],
                [-16.41347312799985, 19.391527176000068],
                [-16.412916183999926, 19.391527176000068],
                [-16.412916183999926, 19.391805649000105],
                [-16.411806106999961, 19.391805649000105],
                [-16.411806106999961, 19.392084122000142],
                [-16.411249160999944, 19.392084122000142],
                [-16.411249160999944, 19.392360687000178],
                [-16.410694122999928, 19.392360687000178],
                [-16.410694122999928, 19.392639160000044],
                [-16.409585952999919, 19.392639160000044],
                [-16.409585952999919, 19.392915726000183],
                [-16.409027099999946, 19.392915726000183],
                [-16.409027099999946, 19.39319419900005],
                [-16.408472060999941, 19.39319419900005],
                [-16.408472060999941, 19.393472672000087],
                [-16.407917022999925, 19.393472672000087],
                [-16.407917022999925, 19.393749237000122],
                [-16.407638549999888, 19.393749237000122],
                [-16.407638549999888, 19.394027709999989],
                [-16.406803130999947, 19.394027709999989],
                [-16.406803130999947, 19.394306183000026],
                [-16.40652847299998, 19.394306183000026],
                [-16.40652847299998, 19.39458274899999],
                [-16.405973433999975, 19.39458274899999],
                [-16.405973433999975, 19.394861222000031],
                [-16.405416487999901, 19.394861222000031],
                [-16.405416487999901, 19.395139695000069],
                [-16.40458106899996, 19.395139695000069],
                [-16.40458106899996, 19.395416260000161],
                [-16.40430641099988, 19.395416260000161],
                [-16.40430641099988, 19.395694733000028],
                [-16.403749464999976, 19.395694733000028],
                [-16.403749464999976, 19.395971299000166],
                [-16.403194426999903, 19.395971299000166],
                [-16.403194426999903, 19.396249772000033],
                [-16.402639387999898, 19.396249772000033],
                [-16.402639387999898, 19.39652824500007],
                [-16.402360914999861, 19.39652824500007],
                [-16.402360914999861, 19.396804810000106],
                [-16.402082443999916, 19.396804810000106],
                [-16.402082443999916, 19.397081376000074],
                [-16.4015274059999, 19.397081376000074],
                [-16.4015274059999, 19.397363664000181],
                [-16.401250839999932, 19.397363664000181],
                [-16.401250839999932, 19.397638322000148],
                [-16.400972366999895, 19.397638322000148],
                [-16.400972366999895, 19.397916795000185],
                [-16.400693893999971, 19.397916795000185],
                [-16.400693893999971, 19.398471833000087],
                [-16.400138855999955, 19.398471833000087],
                [-16.400138855999955, 19.398750306000125],
                [-16.399860382999975, 19.398750306000125],
                [-16.399860382999975, 19.399026872000093],
                [-16.39930534399997, 19.399026872000093],
                [-16.39930534399997, 19.399303437000185],
                [-16.399028778999934, 19.399303437000185],
                [-16.399028778999934, 19.399585723000087],
                [-16.398471832999974, 19.399585723000087],
                [-16.398471832999974, 19.399860381000053],
                [-16.397916793999968, 19.399860381000053],
                [-16.397916793999968, 19.40013885400009],
                [-16.397638320999931, 19.40013885400009],
                [-16.397638320999931, 19.400417327000127],
                [-16.397083282999972, 19.400417327000127],
                [-16.397083282999972, 19.400695800000165],
                [-16.396806716999947, 19.400695800000165],
                [-16.396806716999947, 19.400972366000133],
                [-16.396528243999967, 19.400972366000133],
                [-16.396528243999967, 19.40125083900017],
                [-16.39624977099993, 19.40125083900017],
                [-16.39624977099993, 19.401527404000035],
                [-16.395971297999893, 19.401527404000035],
                [-16.396009444999947, 19.401800157000139],
                [-16.395694732999857, 19.401807785000074],
                [-16.395694732999857, 19.40208244300004],
                [-16.39541625999982, 19.40208244300004],
                [-16.39541625999982, 19.40216255200005],
                [-16.39541625999982, 19.402360916000077],
                [-16.395137786999953, 19.402355195000041],
                [-16.395139693999852, 19.402639389000115],
                [-16.394861220999815, 19.402639389000115],
                [-16.394861220999815, 19.402917862000152],
                [-16.394306182999969, 19.402942658000086],
                [-16.394306182999969, 19.403194427000074],
                [-16.394027709999932, 19.403247833000023],
                [-16.394027709999932, 19.403472900000111],
                [-16.393749236999895, 19.403472900000111],
                [-16.393749236999895, 19.403553009000063],
                [-16.393749236999895, 19.403749466000079],
                [-16.393526077999923, 19.403797150000116],
                [-16.393474578999928, 19.404029846000185],
                [-16.393192290999934, 19.404087067000034],
                [-16.393192290999934, 19.404193879000161],
                [-16.393123626999909, 19.404306412000153],
                [-16.392915724999966, 19.404306412000153],
                [-16.392915724999966, 19.404582977000189],
                [-16.392362593999849, 19.404582977000189],
                [-16.392362593999849, 19.404861450000055],
                [-16.392082213999913, 19.404861450000055],
                [-16.392082213999913, 19.405139923000092],
                [-16.391527174999908, 19.405139923000092],
                [-16.391527174999908, 19.405416489000061],
                [-16.390970230999926, 19.405416489000061],
                [-16.390970230999926, 19.405694962000098],
                [-16.390693665999947, 19.405694962000098],
                [-16.390693665999947, 19.405971527000133],
                [-16.390417097999887, 19.405971527000133],
                [-16.390417097999887, 19.406248093000102],
                [-16.390138626999942, 19.406248093000102],
                [-16.390138626999942, 19.406528473000037],
                [-16.389860153999905, 19.406528473000037],
                [-16.389858245999847, 19.406805039000176],
                [-16.3893051149999, 19.406805039000176],
                [-16.3893051149999, 19.407083512000042],
                [-16.389028549999921, 19.407083512000042],
                [-16.389028549999921, 19.407360077000078],
                [-16.38847160399996, 19.407360077000078],
                [-16.38847160399996, 19.407638550000115],
                [-16.387916564999955, 19.407638550000115],
                [-16.387916564999955, 19.407917022999982],
                [-16.387638091999918, 19.407917022999982],
                [-16.387638091999918, 19.40819358900012],
                [-16.387083053999902, 19.40819358900012],
                [-16.387083053999902, 19.408470154000042],
                [-16.386806487999934, 19.408470154000042],
                [-16.386804580999978, 19.408750535000024],
                [-16.386249541999973, 19.408750535000024],
                [-16.386249541999973, 19.409027100000117],
                [-16.385972976999881, 19.409027100000117],
                [-16.385972976999881, 19.409305573000154],
                [-16.385694503999957, 19.409305573000154],
                [-16.385694503999957, 19.409582139000122],
                [-16.385417937999875, 19.409582139000122],
                [-16.385417937999875, 19.409860612000159],
                [-16.385139464999952, 19.409860612000159],
                [-16.385139464999952, 19.410139085000026],
                [-16.384860991999972, 19.410139085000026],
                [-16.384860991999972, 19.410417556000027],
                [-16.384582518999935, 19.410417556000027],
                [-16.384582518999935, 19.410692216000029],
                [-16.384027480999976, 19.410692216000029],
                [-16.384027480999976, 19.410974502000101],
                [-16.383750914999894, 19.410974502000101],
                [-16.383750914999894, 19.411249160000125],
                [-16.38347244199997, 19.411249160000125],
                [-16.38347244199997, 19.411527633000162],
                [-16.383195876999878, 19.411527633000162],
                [-16.383195876999878, 19.41180419900013],
                [-16.382917403999954, 19.41180419900013],
                [-16.382917403999954, 19.412082672000167],
                [-16.382638930999974, 19.412082672000167],
                [-16.382638930999974, 19.412361144999977],
                [-16.3820858, 19.412361144999977],
                [-16.3820858, 19.412639618000014],
                [-16.381805418999932, 19.412639618000014],
                [-16.381805418999932, 19.412914276000038],
                [-16.381526945999894, 19.412914276000038],
                [-16.381526945999894, 19.413196564000089],
                [-16.381250380999859, 19.413196564000089],
                [-16.381250380999859, 19.413473129000181],
                [-16.380973814999891, 19.413473129000181],
                [-16.380973814999891, 19.413749695000149],
                [-16.380695341999854, 19.413749695000149],
                [-16.380695341999854, 19.414028168000186],
                [-16.380416868999816, 19.414028168000186],
                [-16.380416868999816, 19.414306641000053],
                [-16.38013839599995, 19.414306641000053],
                [-16.38013839599995, 19.414583206000088],
                [-16.379863737999869, 19.414583206000088],
                [-16.379863737999869, 19.414861679000126],
                [-16.379304886999932, 19.414861679000126],
                [-16.379304886999932, 19.415136337000092],
                [-16.378751753999893, 19.415136337000092],
                [-16.378751753999893, 19.415418625000029],
                [-16.378471374999947, 19.415418625000029],
                [-16.378471374999947, 19.415695191000168],
                [-16.378194809999968, 19.415695191000168],
                [-16.378194809999968, 19.415971756000033],
                [-16.37735939099997, 19.415971756000033],
                [-16.37735939099997, 19.41625022900007],
                [-16.377082824999889, 19.41625022900007],
                [-16.377082824999889, 19.416528702000107],
                [-16.37652778699993, 19.416528702000107],
                [-16.37652778699993, 19.416805268000076],
                [-16.376249313999949, 19.416805268000076],
                [-16.376249313999949, 19.417083741000113],
                [-16.375972747999981, 19.417083741000113],
                [-16.375972747999981, 19.417360306000035],
                [-16.375139236999928, 19.417360306000035],
                [-16.375139236999928, 19.417638779000072],
                [-16.374860763999891, 19.417638779000072],
                [-16.374860763999891, 19.417917252000109],
                [-16.374584197999923, 19.417917252000109],
                [-16.374584197999923, 19.418193818000077],
                [-16.374027251999962, 19.418193818000077],
                [-16.374027251999962, 19.418472291000114],
                [-16.373472213999946, 19.418472291000114],
                [-16.373472213999946, 19.418750764000151],
                [-16.373193740999909, 19.418750764000151],
                [-16.373193740999909, 19.419027329000187],
                [-16.372638701999904, 19.419027329000187],
                [-16.372638701999904, 19.419305802000054],
                [-16.372083663999888, 19.419305802000054],
                [-16.372083663999888, 19.419582368000022],
                [-16.371805190999964, 19.419584275000091],
                [-16.371805190999964, 19.419860841000059],
                [-16.371250151999959, 19.419860841000059],
                [-16.371250151999959, 19.420139314000096],
                [-16.370971678999979, 19.420139314000096],
                [-16.370971678999979, 19.420415879000132],
                [-16.370695113999943, 19.420415879000132],
                [-16.370695113999943, 19.420694352000169],
                [-16.370416640999906, 19.420694352000169],
                [-16.370416640999906, 19.420972825000035],
                [-16.370138167999983, 19.420972825000035],
                [-16.370138167999983, 19.421249391000174],
                [-16.369861601999901, 19.421249391000174],
                [-16.369861601999901, 19.421527864000041],
                [-16.369583128999977, 19.421527864000041],
                [-16.369583128999977, 19.421806335000099],
                [-16.369306563999885, 19.421806335000099],
                [-16.369306563999885, 19.422082902000113],
                [-16.369028090999961, 19.422082902000113],
                [-16.369028090999961, 19.422361373000172],
                [-16.368473051999956, 19.422361373000172],
                [-16.368473051999956, 19.422637941000119],
                [-16.368194578999976, 19.422637941000119],
                [-16.368194578999976, 19.422916412000177],
                [-16.367916107999974, 19.422916412000177],
                [-16.367916107999974, 19.423194885000044],
                [-16.367361069999959, 19.423194885000044],
                [-16.367361069999959, 19.423471450000079],
                [-16.366806030999953, 19.423471450000079],
                [-16.366806030999953, 19.423749923000116],
                [-16.366249084999879, 19.423749923000116],
                [-16.366249084999879, 19.424028396000153],
                [-16.365692138999975, 19.424028396000153],
                [-16.365692138999975, 19.424303054000177],
                [-16.365140915999916, 19.424303054000177],
                [-16.365140915999916, 19.424585342000057],
                [-16.364582061999954, 19.424585342000057],
                [-16.364582061999954, 19.424863815000094],
                [-16.364027023999938, 19.424863815000094],
                [-16.364027023999938, 19.425138473000118],
                [-16.363752365999972, 19.425138473000118],
                [-16.363752365999972, 19.425416946000155],
                [-16.363193511999896, 19.425416946000155],
                [-16.363193511999896, 19.425693512000123],
                [-16.362916946999974, 19.425693512000123],
                [-16.362916946999974, 19.425973892],
                [-16.362638473999937, 19.425973892],
                [-16.362638473999937, 19.42625045799997],
                [-16.362361907999968, 19.42625045799997],
                [-16.362361907999968, 19.426525116000164],
                [-16.362083434999931, 19.426525116000164],
                [-16.362083434999931, 19.426803589000031],
                [-16.361528396999972, 19.426803589000031],
                [-16.361528396999972, 19.427085877000081],
                [-16.361249923999935, 19.427085877000081],
                [-16.361249923999935, 19.427639008000142],
                [-16.360971450999898, 19.427639008000142],
                [-16.360971450999898, 19.427917481000179],
                [-16.36069488499993, 19.427917481000179],
                [-16.36069488499993, 19.428195954000046],
                [-16.360416411999893, 19.428195954000046],
                [-16.360416411999893, 19.428472519000081],
                [-16.360137938999969, 19.428472519000081],
                [-16.360137938999969, 19.428747177000048],
                [-16.35986137399982, 19.428747177000048],
                [-16.35986137399982, 19.429027558000087],
                [-16.359582900999953, 19.429027558000087],
                [-16.359582900999953, 19.429307938000022],
                [-16.359027861999948, 19.429307938000022],
                [-16.359027861999948, 19.429582595999989],
                [-16.358749388999911, 19.429582595999989],
                [-16.358749388999911, 19.430139542000063],
                [-16.358472823999932, 19.430139542000063],
                [-16.358472823999932, 19.430416108000031],
                [-16.358194350999895, 19.430416108000031],
                [-16.358194350999895, 19.430694581000068],
                [-16.357917784999927, 19.430694581000068],
                [-16.357917784999927, 19.430973054000106],
                [-16.35763931199989, 19.430973054000106],
                [-16.35763931199989, 19.431249619000027],
                [-16.357360838999966, 19.431249619000027],
                [-16.357360838999966, 19.431528092000065],
                [-16.357084273999817, 19.431528092000065],
                [-16.357082365999929, 19.431804658000033],
                [-16.35680580099995, 19.431804658000033],
                [-16.35680580099995, 19.43208313100007],
                [-16.356527327999913, 19.43208313100007],
                [-16.356527327999913, 19.432361604000107],
                [-16.356250761999945, 19.432361604000107],
                [-16.356250761999945, 19.432638169000143],
                [-16.355972290999887, 19.432638169000143],
                [-16.355972290999887, 19.43291664200018],
                [-16.355417251999882, 19.43291664200018],
                [-16.355417251999882, 19.433195113000011],
                [-16.355138778999958, 19.433195113000011],
                [-16.355138778999958, 19.433750152000016],
                [-16.354860305999921, 19.433750152000016],
                [-16.354860305999921, 19.434028625000053],
                [-16.354585648999944, 19.434028625000053],
                [-16.354585648999944, 19.434305190000146],
                [-16.354028701999937, 19.434305190000146],
                [-16.354028701999937, 19.434583663000183],
                [-16.3537502289999, 19.434583663000183],
                [-16.3537502289999, 19.43569374000009],
                [-16.353473663999921, 19.43569374000009],
                [-16.353473663999921, 19.436141969000118],
                [-16.353473663999921, 19.436527252000133],
                [-16.3531436909999, 19.436531067000033],
                [-16.353195190999884, 19.437082290000035],
                [-16.35291671799996, 19.437082290000035],
                [-16.35291671799996, 19.437915802000077],
                [-16.352638244999923, 19.437915802000077],
                [-16.352638244999923, 19.43847465500005],
                [-16.352363586999843, 19.43847465500005],
                [-16.352363586999843, 19.439027786000111],
                [-16.352081298999849, 19.439027786000111],
                [-16.352081298999849, 19.439304352000079],
                [-16.351804732999881, 19.439304352000079],
                [-16.351804732999881, 19.439861298000153],
                [-16.351528167999902, 19.439861298000153],
                [-16.351528167999902, 19.440696717000037],
                [-16.351249694999979, 19.440696717000037],
                [-16.351249694999979, 19.441249848000098],
                [-16.350971221999941, 19.441249848000098],
                [-16.350971221999941, 19.441528321000135],
                [-16.350694655999973, 19.441528321000135],
                [-16.350694655999973, 19.441806794000172],
                [-16.350141524999913, 19.441806794000172],
                [-16.350141524999913, 19.442083359000037],
                [-16.349859236999919, 19.442083359000037],
                [-16.349859236999919, 19.442361832000074],
                [-16.34958267199994, 19.442361832000074],
                [-16.34958267199994, 19.442638398000042],
                [-16.349027632999935, 19.442638398000042],
                [-16.349027632999935, 19.442918779000081],
                [-16.348749159999898, 19.442918779000081],
                [-16.348749159999898, 19.443195344000117],
                [-16.348472594999976, 19.443195344000117],
                [-16.348472594999976, 19.443750383000122],
                [-16.348194121999938, 19.443750383000122],
                [-16.348194121999938, 19.444305421000024],
                [-16.34791755599997, 19.444305421000024],
                [-16.34791755599997, 19.445140838000157],
                [-16.347637175999921, 19.445140838000157],
                [-16.347637175999921, 19.44569396900016],
                [-16.347360609999953, 19.44569396900016],
                [-16.347360609999953, 19.446527481000032],
                [-16.347084044999974, 19.446527481000032],
                [-16.347084044999974, 19.447082519000162],
                [-16.346805571999937, 19.447082519000162],
                [-16.346805571999937, 19.44735908500013],
                [-16.3465270989999, 19.44735908500013],
                [-16.3465270989999, 19.448194504000014],
                [-16.346250532999932, 19.448194504000014],
                [-16.346250532999932, 19.448471069000107],
                [-16.345972059999895, 19.448471069000107],
                [-16.345972059999895, 19.448749542000144],
                [-16.345693586999971, 19.448749542000144],
                [-16.345693586999971, 19.449304581000149],
                [-16.345415115999913, 19.449306488000047],
                [-16.345415115999913, 19.449861527000053],
                [-16.345138548999955, 19.449861527000053],
                [-16.345138548999955, 19.450693131000094],
                [-16.344861982999817, 19.450693131000094],
                [-16.344861982999817, 19.451250077000168],
                [-16.344583511999872, 19.451250077000168],
                [-16.344583511999872, 19.451528550000035],
                [-16.344306944999971, 19.451528550000035],
                [-16.344306944999971, 19.452360154000075],
                [-16.34402847399997, 19.452362061000144],
                [-16.34402847399997, 19.452638627000113],
                [-16.343750000999933, 19.452638627000113],
                [-16.343750000999933, 19.452915192000034],
                [-16.343471527999952, 19.452915192000034],
                [-16.343471527999952, 19.453195573000187],
                [-16.343196869999872, 19.453195573000187],
                [-16.343196869999872, 19.453472138000109],
                [-16.342916488999947, 19.453472138000109],
                [-16.342916488999947, 19.453750611000146],
                [-16.342361450999931, 19.453750611000146],
                [-16.342361450999931, 19.454025269000113],
                [-16.342084884999963, 19.454072952000047],
                [-16.342084884999963, 19.454584123000188],
                [-16.341806411999926, 19.454633713000192],
                [-16.341806411999926, 19.454860688000053],
                [-16.341693876999955, 19.454860688000053],
                [-16.341527938999889, 19.454860688000053],
                [-16.341550827999924, 19.455150604000039],
                [-16.341249465999965, 19.455139161000091],
                [-16.341249465999965, 19.455417634000128],
                [-16.340974807999885, 19.455417634000128],
                [-16.340974807999885, 19.455694200000096],
                [-16.340694427999949, 19.455694200000096],
                [-16.340694427999949, 19.455972673000133],
                [-16.340414046999854, 19.455972673000133],
                [-16.340414046999854, 19.456249238000169],
                [-16.339584350999928, 19.456249238000169],
                [-16.339584350999928, 19.456527709000056],
                [-16.339027404999968, 19.456527709000056],
                [-16.339027404999968, 19.456806182000093],
                [-16.338193892999925, 19.456806182000093],
                [-16.338193892999925, 19.457082748000062],
                [-16.337917327999946, 19.457082748000062],
                [-16.337917327999946, 19.457361221000099],
                [-16.337638854999909, 19.457361221000099],
                [-16.337638854999909, 19.457639694000136],
                [-16.337360381999872, 19.457639694000136],
                [-16.337360381999872, 19.457916259000172],
                [-16.337083815999904, 19.457916259000172],
                [-16.337083815999904, 19.458194732000038],
                [-16.33680534299998, 19.458194732000038],
                [-16.33680534299998, 19.458471298000177],
                [-16.336526869999943, 19.458471298000177],
                [-16.336527, 19.458693],
                [-16.336527, 19.459028],
                [-16.336248, 19.459028],
                [-16.336248, 19.459305],
                [-16.335972, 19.459305],
                [-16.335972, 19.459583000000123],
                [-16.335695, 19.459583000000123],
                [-16.335695, 19.459862000000157],
                [-16.335417, 19.459862000000157],
                [-16.335417, 19.460138],
                [-16.335138, 19.460138],
                [-16.335138, 19.460417],
                [-16.334862, 19.460417],
                [-16.334862, 19.460695000000158],
                [-16.334305, 19.460695000000158],
                [-16.334305, 19.46097200000014],
                [-16.33375, 19.46097200000014],
                [-16.33375, 19.46125],
                [-16.333195, 19.46125],
                [-16.333195, 19.461527],
                [-16.332916, 19.461527],
                [-16.332916, 19.46180500000014],
                [-16.33264, 19.46180500000014],
                [-16.33264, 19.462084000000118],
                [-16.332361, 19.462084000000118],
                [-16.332361, 19.46236],
                [-16.331806, 19.46236],
                [-16.331806, 19.462639],
                [-16.331528, 19.462639],
                [-16.331528, 19.462917000000118],
                [-16.331249, 19.462917000000118],
                [-16.331249, 19.463194000000158],
                [-16.330973, 19.463194000000158],
                [-16.330973, 19.463472],
                [-16.330694, 19.463472],
                [-16.330694, 19.463751],
                [-16.330416, 19.463751],
                [-16.330416, 19.464027000000158],
                [-16.329861, 19.464027000000158],
                [-16.329861, 19.464306000000136],
                [-16.32930599999986, 19.464306000000136],
                [-16.32930599999986, 19.464582],
                [-16.329027, 19.464582],
                [-16.329027, 19.464861],
                [-16.328751, 19.464861],
                [-16.328751, 19.465139000000136],
                [-16.328196, 19.465139000000136],
                [-16.328196, 19.465414000000123],
                [-16.327917, 19.465414000000123],
                [-16.327917, 19.465696],
                [-16.327638999999863, 19.465696],
                [-16.327638999999863, 19.465975000000185],
                [-16.327362, 19.465975000000185],
                [-16.32736, 19.466249000000118],
                [-16.327084, 19.466249000000118],
                [-16.327084, 19.466528000000153],
                [-16.32680299999987, 19.466528000000153],
                [-16.32680299999987, 19.466806],
                [-16.32625, 19.466806],
                [-16.32625, 19.467085],
                [-16.32597399999986, 19.467085],
                [-16.32597399999986, 19.467361000000153],
                [-16.325695, 19.467361000000153],
                [-16.325695, 19.467636000000141],
                [-16.325417, 19.467636000000141],
                [-16.325417, 19.469307000000185],
                [-16.325675999999874, 19.469275],
                [-16.325695, 19.470419],
                [-16.325417, 19.470419],
                [-16.325417, 19.470694],
                [-16.325138, 19.470694],
                [-16.325138, 19.470972000000131],
                [-16.324863, 19.470972000000131],
                [-16.324863, 19.471251000000109],
                [-16.324581, 19.471251000000109],
                [-16.324581, 19.471527],
                [-16.324306, 19.471527],
                [-16.324305, 19.471806000000186],
                [-16.324028, 19.471806000000186],
                [-16.324028, 19.473473000000126],
                [-16.32375, 19.473473000000126],
                [-16.32375, 19.474028],
                [-16.32347099999987, 19.474028],
                [-16.32347099999987, 19.474861],
                [-16.323195, 19.474861],
                [-16.323195, 19.475140000000181],
                [-16.322916, 19.475140000000181],
                [-16.322916, 19.475695],
                [-16.322639, 19.475695],
                [-16.322639, 19.47625],
                [-16.322358999999892, 19.47625],
                [-16.322358999999892, 19.476805000000127],
                [-16.321806, 19.476805000000127],
                [-16.321806, 19.477081],
                [-16.321251, 19.477081],
                [-16.321251, 19.477362],
                [-16.320931999999857, 19.477362],
                [-16.320694, 19.477362],
                [-16.320694, 19.477638000000127],
                [-16.320417, 19.477638000000127],
                [-16.320417, 19.477917000000105],
                [-16.32013899999987, 19.477917000000105],
                [-16.32013899999987, 19.478193],
                [-16.319859999999892, 19.478193],
                [-16.319859999999892, 19.478472000000181],
                [-16.319584, 19.478472000000181],
                [-16.319584, 19.478750000000105],
                [-16.319304999999872, 19.478750000000105],
                [-16.319304999999872, 19.479029],
                [-16.319029, 19.479029],
                [-16.319029, 19.479303000000186],
                [-16.31875, 19.479303000000186],
                [-16.31875, 19.479586000000154],
                [-16.318471999999872, 19.479586000000154],
                [-16.318471999999872, 19.47986],
                [-16.318195, 19.47986],
                [-16.318195, 19.480139000000122],
                [-16.317917, 19.480139000000122],
                [-16.317917, 19.480417],
                [-16.317637999999874, 19.480417],
                [-16.317637999999874, 19.480696],
                [-16.317362, 19.480696],
                [-16.317362, 19.480972000000122],
                [-16.317083, 19.480972000000122],
                [-16.317083, 19.481525],
                [-16.316806999999869, 19.481525],
                [-16.316806999999869, 19.481808000000171],
                [-16.316527999999892, 19.481808000000171],
                [-16.316527999999892, 19.482361],
                [-16.31625, 19.482361],
                [-16.31625, 19.483885],
                [-16.31625, 19.484030000000189],
                [-16.315970999999877, 19.484030000000189],
                [-16.315970999999877, 19.484861],
                [-16.315694999999891, 19.484861],
                [-16.315694999999891, 19.485695],
                [-16.315970999999877, 19.485695],
                [-16.315970999999877, 19.485958000000153],
                [-16.31625, 19.485971000000177],
                [-16.31625, 19.486805],
                [-16.316527999999892, 19.486805],
                [-16.316527999999892, 19.488470000000177],
                [-16.31625, 19.488470000000177],
                [-16.31625, 19.48986100000019],
                [-16.315970999999877, 19.48986100000019],
                [-16.315970999999877, 19.49041700000015],
                [-16.315694999999891, 19.49041700000015],
                [-16.315694999999891, 19.490973000000167],
                [-16.315416, 19.490973000000167],
                [-16.315416, 19.491804000000172],
                [-16.315139999999872, 19.491804000000172],
                [-16.315139999999872, 19.49264],
                [-16.314860999999894, 19.49264],
                [-16.314860999999894, 19.493473],
                [-16.314551999999821, 19.493467000000123],
                [-16.314585, 19.494307000000163],
                [-16.314402, 19.494307000000163],
                [-16.314305999999874, 19.494307000000163],
                [-16.314305999999874, 19.494862],
                [-16.314027999999894, 19.494862],
                [-16.314027999999894, 19.495981],
                [-16.313749, 19.495972],
                [-16.313749, 19.496529000000123],
                [-16.313475, 19.496529000000123],
                [-16.313518999999872, 19.49711],
                [-16.313192, 19.497084],
                [-16.313192, 19.497360000000128],
                [-16.312916, 19.497360000000128],
                [-16.312992, 19.497967000000131],
                [-16.312638999999876, 19.497917],
                [-16.312638999999876, 19.498194],
                [-16.312362999999891, 19.498194],
                [-16.312362999999891, 19.498472000000163],
                [-16.312084, 19.498472000000163],
                [-16.312084, 19.498751000000141],
                [-16.311805999999876, 19.498751000000141],
                [-16.311805999999876, 19.499306],
                [-16.311526999999899, 19.499306],
                [-16.311526999999899, 19.499584000000141],
                [-16.311253, 19.499584000000141],
                [-16.311253, 19.499861000000124],
                [-16.310969999999827, 19.499861000000124],
                [-16.310969999999827, 19.500416],
                [-16.310693999999899, 19.500416],
                [-16.310693999999899, 19.500973000000158],
                [-16.309858, 19.500973000000158],
                [-16.309858, 19.501249],
                [-16.309584, 19.501249],
                [-16.309584, 19.501806000000158],
                [-16.309304999999824, 19.501806000000158],
                [-16.309304999999824, 19.502361],
                [-16.309028999999896, 19.502361],
                [-16.309028999999896, 19.502916000000141],
                [-16.308748, 19.502916000000141],
                [-16.308748, 19.503471],
                [-16.308471999999824, 19.503471],
                [-16.308471999999824, 19.504028000000119],
                [-16.308194999999898, 19.504028000000119],
                [-16.308194999999898, 19.504583],
                [-16.307917, 19.504583],
                [-16.307917, 19.504864],
                [-16.307637999999827, 19.504864],
                [-16.307637999999827, 19.505138000000159],
                [-16.307361999999898, 19.505138000000159],
                [-16.307361999999898, 19.505693],
                [-16.307083, 19.505693],
                [-16.307083, 19.506250000000136],
                [-16.306804999999827, 19.506250000000136],
                [-16.306804999999827, 19.507360000000119],
                [-16.306526, 19.507360000000119],
                [-16.306526, 19.507639],
                [-16.30625, 19.507639],
                [-16.30625, 19.507917000000191],
                [-16.305972999999824, 19.507917000000191],
                [-16.305972999999824, 19.508196],
                [-16.305695, 19.508196],
                [-16.305695, 19.508472],
                [-16.304861, 19.508472],
                [-16.304861, 19.508751000000132],
                [-16.304307999999878, 19.508751000000132],
                [-16.304307999999878, 19.509026000000119],
                [-16.304028, 19.509026000000119],
                [-16.304028, 19.509308],
                [-16.303472999999826, 19.509308],
                [-16.303472999999826, 19.509583000000191],
                [-16.303196, 19.509583000000191],
                [-16.303196, 19.509861000000114],
                [-16.302917, 19.509861000000114],
                [-16.302917, 19.510139],
                [-16.302639, 19.510139],
                [-16.302639, 19.510418000000186],
                [-16.302361, 19.510418000000186],
                [-16.302361, 19.510973],
                [-16.302086, 19.510973],
                [-16.302086, 19.511250000000132],
                [-16.301805, 19.511250000000132],
                [-16.301805, 19.511805],
                [-16.301527, 19.511806],
                [-16.301527, 19.512083000000132],
                [-16.30125, 19.512083000000132],
                [-16.30125, 19.512638],
                [-16.300973999999826, 19.512638],
                [-16.300973999999826, 19.512917000000186],
                [-16.300691999999856, 19.512917000000186],
                [-16.300691999999856, 19.513472],
                [-16.300417, 19.513472],
                [-16.300417, 19.513750000000186],
                [-16.300138, 19.513750000000186],
                [-16.300138, 19.514029],
                [-16.299864, 19.514029],
                [-16.299864, 19.514305],
                [-16.299582, 19.514305],
                [-16.299582, 19.514584000000127],
                [-16.299303, 19.514584000000127],
                [-16.299303, 19.51486],
                [-16.299028, 19.51486],
                [-16.299028, 19.515139],
                [-16.298752, 19.515139],
                [-16.298752, 19.515417000000127],
                [-16.298473, 19.515417000000127],
                [-16.298473, 19.516806],
                [-16.298195, 19.516806],
                [-16.298195, 19.517361],
                [-16.297916, 19.517361],
                [-16.297916, 19.517916000000127],
                [-16.297638, 19.517916000000127],
                [-16.297638, 19.518192],
                [-16.297358999999858, 19.518192],
                [-16.297358999999858, 19.518475],
                [-16.297083, 19.518475],
                [-16.297083, 19.518749000000128],
                [-16.296806, 19.518749000000128],
                [-16.296806, 19.519028000000105],
                [-16.296249, 19.519028000000105],
                [-16.296249, 19.519304],
                [-16.295973, 19.519304],
                [-16.295973, 19.519861000000105],
                [-16.295693999999855, 19.519861000000105],
                [-16.295693999999855, 19.52014],
                [-16.295416, 19.52014],
                [-16.295416, 19.520414000000187],
                [-16.295139, 19.520414000000187],
                [-16.295139, 19.520697000000155],
                [-16.294860999999855, 19.520697000000155],
                [-16.294860999999855, 19.520971],
                [-16.294584, 19.520971],
                [-16.294582, 19.52125],
                [-16.294306, 19.52125],
                [-16.294306, 19.52152800000016],
                [-16.293751, 19.52152800000016],
                [-16.293751, 19.521807],
                [-16.293472, 19.521807],
                [-16.293472, 19.522083],
                [-16.292917, 19.522083],
                [-16.292917, 19.522362000000101],
                [-16.292639, 19.522362000000101],
                [-16.292639, 19.522636],
                [-16.29236, 19.522636],
                [-16.29236, 19.522919000000172],
                [-16.292084, 19.522919000000172],
                [-16.292084, 19.523195000000101],
                [-16.291805, 19.523195000000101],
                [-16.291805, 19.523750000000177],
                [-16.291528999999855, 19.523750000000177],
                [-16.291528999999855, 19.524029000000155],
                [-16.29125, 19.524029000000155],
                [-16.29125, 19.524305],
                [-16.290972, 19.524305],
                [-16.290972, 19.524584],
                [-16.290694999999857, 19.524584],
                [-16.290694999999857, 19.52486000000016],
                [-16.290417, 19.52486000000016],
                [-16.290417, 19.525417],
                [-16.290138, 19.525417],
                [-16.290138, 19.526251000000173],
                [-16.289861999999857, 19.526251000000173],
                [-16.289861999999857, 19.526806],
                [-16.289583, 19.526806],
                [-16.289583, 19.527082000000178],
                [-16.289307, 19.527082000000178],
                [-16.289307, 19.527361000000155],
                [-16.289028, 19.527361000000155],
                [-16.289028, 19.527639],
                [-16.28875, 19.527639],
                [-16.28875, 19.527916],
                [-16.288473, 19.527916],
                [-16.288473, 19.528473000000133],
                [-16.288195, 19.528473000000133],
                [-16.288195, 19.529028],
                [-16.287916, 19.529028],
                [-16.287916, 19.529861],
                [-16.28764, 19.529861],
                [-16.28764, 19.530693000000156],
                [-16.287361, 19.530693000000156],
                [-16.287361, 19.531437],
                [-16.287361, 19.531803],
                [-16.287067, 19.531794000000161],
                [-16.287085, 19.532364],
                [-16.286806, 19.532364],
                [-16.286806, 19.534025000000156],
                [-16.287085, 19.534025000000156],
                [-16.287085, 19.534307],
                [-16.287361, 19.534307],
                [-16.287361, 19.534586],
                [-16.28764, 19.534586],
                [-16.28764, 19.535139000000129],
                [-16.287916, 19.535139000000129],
                [-16.287916, 19.535694],
                [-16.288195, 19.535694],
                [-16.288195, 19.536247000000174],
                [-16.288473, 19.536247000000174],
                [-16.288473, 19.536806],
                [-16.28875, 19.536806],
                [-16.28875, 19.537083],
                [-16.288195, 19.537083],
                [-16.288195, 19.537361000000146],
                [-16.288473, 19.537361000000146],
                [-16.288473, 19.538195],
                [-16.28875, 19.538195],
                [-16.28875, 19.539028],
                [-16.289028, 19.539028],
                [-16.289028, 19.539862000000142],
                [-16.289307, 19.539862000000142],
                [-16.289307, 19.540695000000142],
                [-16.289583, 19.540695000000142],
                [-16.289583, 19.541529],
                [-16.289861999999857, 19.541529],
                [-16.289861999999857, 19.542084000000102],
                [-16.290138, 19.542084000000102],
                [-16.290138, 19.542639],
                [-16.290694999999857, 19.542639],
                [-16.290694999999857, 19.542917000000102],
                [-16.290972, 19.542917000000102],
                [-16.290972, 19.543194000000142],
                [-16.292639, 19.543194000000142],
                [-16.292639, 19.542917000000102],
                [-16.293472, 19.542917000000102],
                [-16.293472, 19.542639],
                [-16.294584, 19.542639],
                [-16.294584, 19.54236],
                [-16.294860999999855, 19.54236],
                [-16.294860999999855, 19.542084000000102],
                [-16.295416, 19.542084000000102],
                [-16.295416, 19.541805000000124],
                [-16.295973, 19.541805000000124],
                [-16.295973, 19.541529],
                [-16.296249, 19.541529],
                [-16.296249, 19.54125],
                [-16.296806, 19.54125],
                [-16.296806, 19.540972000000124],
                [-16.297358999999858, 19.540972000000124],
                [-16.297358999999858, 19.540695000000142],
                [-16.297916, 19.540695000000142],
                [-16.297916, 19.540417],
                [-16.298195, 19.540417],
                [-16.298195, 19.540138],
                [-16.298752, 19.540138],
                [-16.298752, 19.539862000000142],
                [-16.299303, 19.539862000000142],
                [-16.299303, 19.539583000000164],
                [-16.299864, 19.539583000000164],
                [-16.299864, 19.539305],
                [-16.300691999999856, 19.539305],
                [-16.300695, 19.539028],
                [-16.301527, 19.539028],
                [-16.301527, 19.538750000000164],
                [-16.302361, 19.538750000000164],
                [-16.302361, 19.538473000000124],
                [-16.302917, 19.538473000000124],
                [-16.302917, 19.538195],
                [-16.303196, 19.538195],
                [-16.303196, 19.537640000000124],
                [-16.303472999999826, 19.537640000000124],
                [-16.303472999999826, 19.537083],
                [-16.303196, 19.537083],
                [-16.303196, 19.536528000000146],
                [-16.302917, 19.536528000000146],
                [-16.302917, 19.536247000000174],
                [-16.303472999999826, 19.536247000000174],
                [-16.303472999999826, 19.535973],
                [-16.304028, 19.535973],
                [-16.304028, 19.535418000000163],
                [-16.304307999999878, 19.535418000000163],
                [-16.304307999999878, 19.535139000000129],
                [-16.304861, 19.535139000000129],
                [-16.304861, 19.534861],
                [-16.307917, 19.534861],
                [-16.307917, 19.534307],
                [-16.308748, 19.534307],
                [-16.308748, 19.534586],
                [-16.309304999999824, 19.534586],
                [-16.309304999999824, 19.534861],
                [-16.310138999999879, 19.534861],
                [-16.310138999999879, 19.535139000000129],
                [-16.312084, 19.535139000000129],
                [-16.312084, 19.534861],
                [-16.313475, 19.534861],
                [-16.313475, 19.534586],
                [-16.314585, 19.534586],
                [-16.314585, 19.534307],
                [-16.315694999999891, 19.534307],
                [-16.315694999999891, 19.534025000000156],
                [-16.317083, 19.534025000000156],
                [-16.317083, 19.533751],
                [-16.319029, 19.533751],
                [-16.319029, 19.533472],
                [-16.321527, 19.533472],
                [-16.321527, 19.533194000000151],
                [-16.32347099999987, 19.533194000000151],
                [-16.32347099999987, 19.532915000000173],
                [-16.324306, 19.532915000000173],
                [-16.324306, 19.532639],
                [-16.325417, 19.532639],
                [-16.325417, 19.532364],
                [-16.32625, 19.532364],
                [-16.32625, 19.532085],
                [-16.327084, 19.532085],
                [-16.327084, 19.531803],
                [-16.327362, 19.531803],
                [-16.327362, 19.531528000000151],
                [-16.327638999999863, 19.531528000000151],
                [-16.327638999999863, 19.53125],
                [-16.327917, 19.53125],
                [-16.327917, 19.530972000000133],
                [-16.328196, 19.530972000000133],
                [-16.328196, 19.530693000000156],
                [-16.328471999999863, 19.530693000000156],
                [-16.328471999999863, 19.530416000000173],
                [-16.328751, 19.530416000000173],
                [-16.328751, 19.529861],
                [-16.329027, 19.529861],
                [-16.329027, 19.529581000000178],
                [-16.32930599999986, 19.529581000000178],
                [-16.32930599999986, 19.529028],
                [-16.329584, 19.529028],
                [-16.329584, 19.527361000000155],
                [-16.329861, 19.527361000000155],
                [-16.329861, 19.525972],
                [-16.330138999999861, 19.525972],
                [-16.330138999999861, 19.525694000000101],
                [-16.330416, 19.525694000000101],
                [-16.330418, 19.525417],
                [-16.330694, 19.525417],
                [-16.330694, 19.524584],
                [-16.330973, 19.524584],
                [-16.330973, 19.523472],
                [-16.331249, 19.523472],
                [-16.331249, 19.522636],
                [-16.331528, 19.522636],
                [-16.331528, 19.522083],
                [-16.331806, 19.522083],
                [-16.331806, 19.521807],
                [-16.33264, 19.521807],
                [-16.33264, 19.522083],
                [-16.332916, 19.522083],
                [-16.332916, 19.522362000000101],
                [-16.333195, 19.522362000000101],
                [-16.333195, 19.522636],
                [-16.335417, 19.522636],
                [-16.335417, 19.522362000000101],
                [-16.337084, 19.522362000000101],
                [-16.337084, 19.522083],
                [-16.337639, 19.522083],
                [-16.337639, 19.521807],
                [-16.337917, 19.521807],
                [-16.337917, 19.52152800000016],
                [-16.33847, 19.52152800000016],
                [-16.33847, 19.52125],
                [-16.339306, 19.52125],
                [-16.339306, 19.520971],
                [-16.339583999999832, 19.520971],
                [-16.339583999999832, 19.520697000000155],
                [-16.339863, 19.520697000000155],
                [-16.339863, 19.52014],
                [-16.340139, 19.52014],
                [-16.340139, 19.519304],
                [-16.339863, 19.519304],
                [-16.339863, 19.518749000000128],
                [-16.339583999999832, 19.518749000000128],
                [-16.339583999999832, 19.518192],
                [-16.339306, 19.518192],
                [-16.339306, 19.517918],
                [-16.341528, 19.517916000000127],
                [-16.341528, 19.517639],
                [-16.342361, 19.517639],
                [-16.342361, 19.517361],
                [-16.342915999999832, 19.517361],
                [-16.342915999999832, 19.517082000000187],
                [-16.343749999999829, 19.517082000000187],
                [-16.343749999999829, 19.516806],
                [-16.344028, 19.516806],
                [-16.344028, 19.517082000000187],
                [-16.344583999999884, 19.517082000000187],
                [-16.344583999999884, 19.516806],
                [-16.346250999999882, 19.516806],
                [-16.346250999999882, 19.51652700000011],
                [-16.346806, 19.51652700000011],
                [-16.346806, 19.516251000000182],
                [-16.347083999999882, 19.516251000000182],
                [-16.347083999999882, 19.51597],
                [-16.347637, 19.51597],
                [-16.347637, 19.51569400000011],
                [-16.348194, 19.51569400000011],
                [-16.348194, 19.515417000000127],
                [-16.349305999999899, 19.515417000000127],
                [-16.349305999999899, 19.515139],
                [-16.350142, 19.515139],
                [-16.350142, 19.51486],
                [-16.350695, 19.51486],
                [-16.350695, 19.514584000000127],
                [-16.351249999999879, 19.514584000000127],
                [-16.351249999999879, 19.514305],
                [-16.351528, 19.514305],
                [-16.351528, 19.514029],
                [-16.351804999999899, 19.514029],
                [-16.351804999999899, 19.513750000000186],
                [-16.352364, 19.513750000000186],
                [-16.352364, 19.513472],
                [-16.352637999999899, 19.513472],
                [-16.352637999999899, 19.51319500000011],
                [-16.353195, 19.51319500000011],
                [-16.353195, 19.512917000000186],
                [-16.354029, 19.512917000000186],
                [-16.354029, 19.512638],
                [-16.35458599999987, 19.512638],
                [-16.35458599999987, 19.51236200000011],
                [-16.35486, 19.51236200000011],
                [-16.35486, 19.512083000000132],
                [-16.355416999999875, 19.512083000000132],
                [-16.355416999999875, 19.511806],
                [-16.355696, 19.511806],
                [-16.355696, 19.51153],
                [-16.355972, 19.51153],
                [-16.355972, 19.511250000000132],
                [-16.356250999999872, 19.511250000000132],
                [-16.356250999999872, 19.510973],
                [-16.356527, 19.510973],
                [-16.356527, 19.510695],
                [-16.357083999999873, 19.510695],
                [-16.357083999999873, 19.510418000000186],
                [-16.357361, 19.510418000000186],
                [-16.357361, 19.510139],
                [-16.357639, 19.510139],
                [-16.357639, 19.509861000000114],
                [-16.35791799999987, 19.509861000000114],
                [-16.35791799999987, 19.509583000000191],
                [-16.358194, 19.509583000000191],
                [-16.358194, 19.509308],
                [-16.358473, 19.509308],
                [-16.358473, 19.509026000000119],
                [-16.358748999999875, 19.509026000000119],
                [-16.358748999999875, 19.508472],
                [-16.359306, 19.508472],
                [-16.359306, 19.508196],
                [-16.359582999999873, 19.508196],
                [-16.359582999999873, 19.507917000000191],
                [-16.359861, 19.507917000000191],
                [-16.359861, 19.507639],
                [-16.360415999999873, 19.507639],
                [-16.360415999999873, 19.507360000000119],
                [-16.360695, 19.507360000000119],
                [-16.360695, 19.507086000000186],
                [-16.360971, 19.507086000000186],
                [-16.360971, 19.506804000000159],
                [-16.36125, 19.506804000000159],
                [-16.36125, 19.506527000000119],
                [-16.361528, 19.506527000000119],
                [-16.361528, 19.505972],
                [-16.361805, 19.505972],
                [-16.361805, 19.505693],
                [-16.36208299999987, 19.505693],
                [-16.36208299999987, 19.504864],
                [-16.362362, 19.504864],
                [-16.362362, 19.504305000000159],
                [-16.362638, 19.504305000000159],
                [-16.362638, 19.504028000000119],
                [-16.362916999999868, 19.504028000000119],
                [-16.362916999999868, 19.50375],
                [-16.363194, 19.50375],
                [-16.363195, 19.503471],
                [-16.36347, 19.503471],
                [-16.36347, 19.503195000000119],
                [-16.363752, 19.503195000000119],
                [-16.363752, 19.50264],
                [-16.364027, 19.50264],
                [-16.364027, 19.502361],
                [-16.364305, 19.502361],
                [-16.364305, 19.501806000000158],
                [-16.364659999999844, 19.501839000000189],
                [-16.364581999999871, 19.501249],
                [-16.364906, 19.50127],
                [-16.364861, 19.500416],
                [-16.365141, 19.500416],
                [-16.365141, 19.499306],
                [-16.365416999999866, 19.499306],
                [-16.365416999999866, 19.498472000000163],
                [-16.365692, 19.498472000000163],
                [-16.365692, 19.497917],
                [-16.365971, 19.497917],
                [-16.365971, 19.497360000000128],
                [-16.366248999999868, 19.497360000000128],
                [-16.366248999999868, 19.496805],
                [-16.366528, 19.496805],
                [-16.366528, 19.496250000000146],
                [-16.366806, 19.496250000000146],
                [-16.366806, 19.49541900000014],
                [-16.367085, 19.49541900000014],
                [-16.367085, 19.494862],
                [-16.367361, 19.494862],
                [-16.367361, 19.494307000000163],
                [-16.36764, 19.494307000000163],
                [-16.36764, 19.49375],
                [-16.367915999999866, 19.49375],
                [-16.367915999999866, 19.493196],
                [-16.368195, 19.493196],
                [-16.368195, 19.49208300000015],
                [-16.368473, 19.49208300000015],
                [-16.368473, 19.491249],
                [-16.36875, 19.491249],
                [-16.36875, 19.490692],
                [-16.369028, 19.490692],
                [-16.369028, 19.48986100000019],
                [-16.369307, 19.48986100000019],
                [-16.369307, 19.489305000000172],
                [-16.369583, 19.489305000000172],
                [-16.369583, 19.48875],
                [-16.369862, 19.48875],
                [-16.369862, 19.488470000000177],
                [-16.370138, 19.488470000000177],
                [-16.370138, 19.487917],
                [-16.370417, 19.487917],
                [-16.370417, 19.487638],
                [-16.370695, 19.487638],
                [-16.370695, 19.487362000000189],
                [-16.370972, 19.487362000000189],
                [-16.370972, 19.487083000000155],
                [-16.37125, 19.487083000000155],
                [-16.37125, 19.486805],
                [-16.371529, 19.486805],
                [-16.371529, 19.486528],
                [-16.372084, 19.486528],
                [-16.372084, 19.486250000000155],
                [-16.37236, 19.486250000000155],
                [-16.37236, 19.485971000000177],
                [-16.372639, 19.485971000000177],
                [-16.372639, 19.485695],
                [-16.373194, 19.485695],
                [-16.373194, 19.4854160000001],
                [-16.373472, 19.4854160000001],
                [-16.373472, 19.485140000000172],
                [-16.374027, 19.485140000000172],
                [-16.374027, 19.484861],
                [-16.374306, 19.484861],
                [-16.374306, 19.4854160000001],
                [-16.374584, 19.4854160000001],
                [-16.374584, 19.485695],
                [-16.375139, 19.485695],
                [-16.375139, 19.485971000000177],
                [-16.375595, 19.485971000000177],
                [-16.376527999999837, 19.485971000000177],
                [-16.376527999999837, 19.485695],
                [-16.377083, 19.485695],
                [-16.377083, 19.4854160000001],
                [-16.377359, 19.4854160000001],
                [-16.377359, 19.485140000000172],
                [-16.378194999999835, 19.485140000000172],
                [-16.378194999999835, 19.484861],
                [-16.378471, 19.484861],
                [-16.378471, 19.4845830000001],
                [-16.378752, 19.4845830000001],
                [-16.378752, 19.484306],
                [-16.379027999999835, 19.484306],
                [-16.379027999999835, 19.484030000000189],
                [-16.379305, 19.484030000000189],
                [-16.379305, 19.483749],
                [-16.379863999999884, 19.483749],
                [-16.379863999999884, 19.483473],
                [-16.380138, 19.483473],
                [-16.380138, 19.483194],
                [-16.380694999999889, 19.483194],
                [-16.380694999999889, 19.482918000000154],
                [-16.380974, 19.482918000000154],
                [-16.380974, 19.482639000000177],
                [-16.38125, 19.482639000000177],
                [-16.38125, 19.482361],
                [-16.381526999999835, 19.482361],
                [-16.381526999999835, 19.4820840000001],
                [-16.381805, 19.4820840000001],
                [-16.381805, 19.4812510000001],
                [-16.382086, 19.4812510000001],
                [-16.382086, 19.480696],
                [-16.382359999999835, 19.480696],
                [-16.382359999999835, 19.47986],
                [-16.382086, 19.47986],
                [-16.382086, 19.47945],
                [-16.382086, 19.479347000000132],
                [-16.382359999999835, 19.479303000000186],
                [-16.382358999999838, 19.479027],
                [-16.382639, 19.479029],
                [-16.38258, 19.478764000000183],
                [-16.382917, 19.478750000000105],
                [-16.382917, 19.478472000000181],
                [-16.383195999999884, 19.478472000000181],
                [-16.383199999999874, 19.477879000000144],
                [-16.383472, 19.477917000000105],
                [-16.383472, 19.477638000000127],
                [-16.38402699999989, 19.477638000000127],
                [-16.38402699999989, 19.477362],
                [-16.384308, 19.477362],
                [-16.384308, 19.477081],
                [-16.384583, 19.477081],
                [-16.384583, 19.476805000000127],
                [-16.384860999999887, 19.476805000000127],
                [-16.384860999999887, 19.476528],
                [-16.385139, 19.476528],
                [-16.385139, 19.475971000000186],
                [-16.385694999999885, 19.475971000000186],
                [-16.385694999999885, 19.475695],
                [-16.385973, 19.475695],
                [-16.385973, 19.475140000000181],
                [-16.38625, 19.475140000000181],
                [-16.38625, 19.474861],
                [-16.38652599999989, 19.474861],
                [-16.38652599999989, 19.474377000000118],
                [-16.386755, 19.474241],
                [-16.386806, 19.473749],
                [-16.38736699999987, 19.473749],
                [-16.387917, 19.473749],
                [-16.387917, 19.473473000000126],
                [-16.388194999999882, 19.473473000000126],
                [-16.38815099999988, 19.473202],
                [-16.388748, 19.473194],
                [-16.388748, 19.472918],
                [-16.389305, 19.472918],
                [-16.389305, 19.472639000000186],
                [-16.389584, 19.472639000000186],
                [-16.389584, 19.472361],
                [-16.389859999999885, 19.472361],
                [-16.389859999999885, 19.472084000000109],
                [-16.390139, 19.472084000000109],
                [-16.390139, 19.471525],
                [-16.390417, 19.471527],
                [-16.390417, 19.471302],
                [-16.390693999999883, 19.471251000000109],
                [-16.390693999999883, 19.471081],
                [-16.390693999999883, 19.470694],
                [-16.39097, 19.470694],
                [-16.39097, 19.470419],
                [-16.391298, 19.470446],
                [-16.391253, 19.470139000000131],
                [-16.391526999999883, 19.470139000000131],
                [-16.391526999999883, 19.469307000000185],
                [-16.391806, 19.469307000000185],
                [-16.391806, 19.468472],
                [-16.392082, 19.468472],
                [-16.392082, 19.467915000000119],
                [-16.392362999999875, 19.467915000000119],
                [-16.392362999999875, 19.467361000000153],
                [-16.392639, 19.467361000000153],
                [-16.392639, 19.466528000000153],
                [-16.392916, 19.466528000000153],
                [-16.392918, 19.465975000000185],
                [-16.393191999999885, 19.465975000000185],
                [-16.393191999999885, 19.465414000000123],
                [-16.393475, 19.465414000000123],
                [-16.393475, 19.464861],
                [-16.393749, 19.464861],
                [-16.393749, 19.464306000000136],
                [-16.394027999999878, 19.464306000000136],
                [-16.394027999999878, 19.463751],
                [-16.394306, 19.463751],
                [-16.394306, 19.462639],
                [-16.394860999999878, 19.462639],
                [-16.394860999999878, 19.46236],
                [-16.395971, 19.46236],
                [-16.395971, 19.462084000000118],
                [-16.396274999999889, 19.462099000000137],
                [-16.39625, 19.46180500000014],
                [-16.396527999999876, 19.46180500000014],
                [-16.396527999999876, 19.461527],
                [-16.396682999999825, 19.461527],
                [-16.396807, 19.461527],
                [-16.396807, 19.461308],
                [-16.397083, 19.46125],
                [-16.397023999999874, 19.460985000000164],
                [-16.397361999999873, 19.46097200000014],
                [-16.397361999999873, 19.460695000000158],
                [-16.397638, 19.460695000000158],
                [-16.397638, 19.460417],
                [-16.397917, 19.460417],
                [-16.397917, 19.460138],
                [-16.398409, 19.460197000000164],
                [-16.398472, 19.459862000000157],
                [-16.39875, 19.459862000000157],
                [-16.39875, 19.459583000000123],
                [-16.399028999999871, 19.459583000000123],
                [-16.399028999999871, 19.459305],
                [-16.399305, 19.459305],
                [-16.399305, 19.459028],
                [-16.399584, 19.459028],
                [-16.399584, 19.458750000000123],
                [-16.399859999999876, 19.458750000000123],
                [-16.399859999999876, 19.458471000000145],
                [-16.401526999999874, 19.458471000000145],
                [-16.401526999999874, 19.459862000000157],
                [-16.401251, 19.459862000000157],
                [-16.401251, 19.460138],
                [-16.400972, 19.460138],
                [-16.400972, 19.460417],
                [-16.400693999999874, 19.460417],
                [-16.400693999999874, 19.460695000000158],
                [-16.400417, 19.460695000000158],
                [-16.400417, 19.46125],
                [-16.400139, 19.46125],
                [-16.400139, 19.46180500000014],
                [-16.39994, 19.46180500000014],
                [-16.399859999999876, 19.46180500000014],
                [-16.399859999999876, 19.462084000000118],
                [-16.399584, 19.462084000000118],
                [-16.399614, 19.462379],
                [-16.399305, 19.46236],
                [-16.399305, 19.462883000000147],
                [-16.399305, 19.464306000000136],
                [-16.399028999999871, 19.464306000000136],
                [-16.399028999999871, 19.465139000000136],
                [-16.39875, 19.465139000000136],
                [-16.39875, 19.468197],
                [-16.398472, 19.468197],
                [-16.398472, 19.469862],
                [-16.398194999999873, 19.469862],
                [-16.398194999999873, 19.470139000000131],
                [-16.397917, 19.470139000000131],
                [-16.397917, 19.470419],
                [-16.397638, 19.470419],
                [-16.397638, 19.470694],
                [-16.397361999999873, 19.470694],
                [-16.397361999999873, 19.470972000000131],
                [-16.397083, 19.470972000000131],
                [-16.397083, 19.471527],
                [-16.396807, 19.471527],
                [-16.396807, 19.472639000000186],
                [-16.396527999999876, 19.472639000000186],
                [-16.396527999999876, 19.473070000000178],
                [-16.396527999999876, 19.473749],
                [-16.39625, 19.473749],
                [-16.39625, 19.474861],
                [-16.395971, 19.474861],
                [-16.395971, 19.475140000000181],
                [-16.395416, 19.475140000000181],
                [-16.395416, 19.475416000000109],
                [-16.39514, 19.475416000000109],
                [-16.39514, 19.475695],
                [-16.394860999999878, 19.475695],
                [-16.394860999999878, 19.475971000000186],
                [-16.394585, 19.475971000000186],
                [-16.394585, 19.47625],
                [-16.394306, 19.47625],
                [-16.394306, 19.476528],
                [-16.394027999999878, 19.476528],
                [-16.394027999999878, 19.476805000000127],
                [-16.393749, 19.476805000000127],
                [-16.393749, 19.477081],
                [-16.393191999999885, 19.477081],
                [-16.393191999999885, 19.477362],
                [-16.392639, 19.477362],
                [-16.392639, 19.477638000000127],
                [-16.392082, 19.477638000000127],
                [-16.392082, 19.477917000000105],
                [-16.391526999999883, 19.477917000000105],
                [-16.391526999999883, 19.478193],
                [-16.39097, 19.478193],
                [-16.39097, 19.478472000000181],
                [-16.390417, 19.478516000000127],
                [-16.390417, 19.478750000000105],
                [-16.390157999999872, 19.478750000000105],
                [-16.389584, 19.478750000000105],
                [-16.389584, 19.479029],
                [-16.38902899999988, 19.479029],
                [-16.38902899999988, 19.479303000000186],
                [-16.388472, 19.479303000000186],
                [-16.388472, 19.479586000000154],
                [-16.388194999999882, 19.479586000000154],
                [-16.388194999999882, 19.47986],
                [-16.387917, 19.47986],
                [-16.387917, 19.480139000000122],
                [-16.387638, 19.480139000000122],
                [-16.387638, 19.480417],
                [-16.387361999999882, 19.480417],
                [-16.387361999999882, 19.480972000000122],
                [-16.387083, 19.480972000000122],
                [-16.387083, 19.481808000000171],
                [-16.386806, 19.481808000000171],
                [-16.386805, 19.482082000000105],
                [-16.38652599999989, 19.4820840000001],
                [-16.38652599999989, 19.482361],
                [-16.38625, 19.482361],
                [-16.386257, 19.484587],
                [-16.385973, 19.4845830000001],
                [-16.385973, 19.485249000000124],
                [-16.385973, 19.485695],
                [-16.385759, 19.48571800000019],
                [-16.385694999999885, 19.485897],
                [-16.385694999999885, 19.486805],
                [-16.385418, 19.486805],
                [-16.385418, 19.487362000000189],
                [-16.385077, 19.487335],
                [-16.385139, 19.488194],
                [-16.384860999999887, 19.488194],
                [-16.384860999999887, 19.489027],
                [-16.384583, 19.489027],
                [-16.384583, 19.489305000000172],
                [-16.38423, 19.489305000000172],
                [-16.383751, 19.489305000000172],
                [-16.383751, 19.489582000000155],
                [-16.383472, 19.489582000000155],
                [-16.383472, 19.490139],
                [-16.383195999999884, 19.490139],
                [-16.383195999999884, 19.49041700000015],
                [-16.382917, 19.49041700000015],
                [-16.382917, 19.491249],
                [-16.382639, 19.491249],
                [-16.382639, 19.491804000000172],
                [-16.382359999999835, 19.491804000000172],
                [-16.382359999999835, 19.49264],
                [-16.382086, 19.49264],
                [-16.382086, 19.493196],
                [-16.381805, 19.493196],
                [-16.381805, 19.49375],
                [-16.381526999999835, 19.49375],
                [-16.381526999999835, 19.494307000000163],
                [-16.38125, 19.494307000000163],
                [-16.38125, 19.494862],
                [-16.380974, 19.494862],
                [-16.380974, 19.49541900000014],
                [-16.380694999999889, 19.49541900000014],
                [-16.380694999999889, 19.495695],
                [-16.380417, 19.495695],
                [-16.380417, 19.495972],
                [-16.380138, 19.495972],
                [-16.380138, 19.496250000000146],
                [-16.379863999999884, 19.496250000000146],
                [-16.379863999999884, 19.496529000000123],
                [-16.379581, 19.496529000000123],
                [-16.379581, 19.496805],
                [-16.379305, 19.496805],
                [-16.379305, 19.497639000000163],
                [-16.379027999999835, 19.497639000000163],
                [-16.379027999999835, 19.498194],
                [-16.378752, 19.498194],
                [-16.378752, 19.498472000000163],
                [-16.378471, 19.498472000000163],
                [-16.378471, 19.498751000000141],
                [-16.378194999999835, 19.498751000000141],
                [-16.378194999999835, 19.499306],
                [-16.377916, 19.499306],
                [-16.377916, 19.499861000000124],
                [-16.377642, 19.499861000000124],
                [-16.377642, 19.500694000000124],
                [-16.377359, 19.500694000000124],
                [-16.377359, 19.501528],
                [-16.377083, 19.501528],
                [-16.377083, 19.502361],
                [-16.376806, 19.502361],
                [-16.376806, 19.502916000000141],
                [-16.376527999999837, 19.502916000000141],
                [-16.376606, 19.503519],
                [-16.376249, 19.503471],
                [-16.376249, 19.504076],
                [-16.376249, 19.504305000000159],
                [-16.376102, 19.504305000000159],
                [-16.375973, 19.504305000000159],
                [-16.375973, 19.50450700000016],
                [-16.375973, 19.505138000000159],
                [-16.375694, 19.505138000000159],
                [-16.375694, 19.505693],
                [-16.375416, 19.505693],
                [-16.375416, 19.506804000000159],
                [-16.375139, 19.506804000000159],
                [-16.375139, 19.507639],
                [-16.374861, 19.507639],
                [-16.374861, 19.507917000000191],
                [-16.374584, 19.507917000000191],
                [-16.374584, 19.508472],
                [-16.374306, 19.508472],
                [-16.374306, 19.509026000000119],
                [-16.374027, 19.509026000000119],
                [-16.374027, 19.509583000000191],
                [-16.373751, 19.509583000000191],
                [-16.373751, 19.510418000000186],
                [-16.373472, 19.510418000000186],
                [-16.373472, 19.510695],
                [-16.373194, 19.510695],
                [-16.373194, 19.510973],
                [-16.372917, 19.510973],
                [-16.372917, 19.511250000000132],
                [-16.372639, 19.511250000000132],
                [-16.372639, 19.51153],
                [-16.37236, 19.51153],
                [-16.37236, 19.511805],
                [-16.372084, 19.511806],
                [-16.372084, 19.512083000000132],
                [-16.371805, 19.512083000000132],
                [-16.371805, 19.512638],
                [-16.371529, 19.512638],
                [-16.371529, 19.514305],
                [-16.37125, 19.514305],
                [-16.37125, 19.515139],
                [-16.370972, 19.515139],
                [-16.370972, 19.51569400000011],
                [-16.370695, 19.51569400000011],
                [-16.370695, 19.516251000000182],
                [-16.370417, 19.516251000000182],
                [-16.370417, 19.517082000000187],
                [-16.370138, 19.517082000000187],
                [-16.370138, 19.517639],
                [-16.369862, 19.517639],
                [-16.369862, 19.517916000000127],
                [-16.369583, 19.517916000000127],
                [-16.369583, 19.518192],
                [-16.369307, 19.518192],
                [-16.369307, 19.518475],
                [-16.369028, 19.518475],
                [-16.369028, 19.518749000000128],
                [-16.36875, 19.518749000000128],
                [-16.36875, 19.519304],
                [-16.368473, 19.519304],
                [-16.368473, 19.519583000000182],
                [-16.368195, 19.519583000000182],
                [-16.368195, 19.519861000000105],
                [-16.367915999999866, 19.519861000000105],
                [-16.367915999999866, 19.52014],
                [-16.36764, 19.52014],
                [-16.36764, 19.520414000000187],
                [-16.367361, 19.520414000000187],
                [-16.367361, 19.520971],
                [-16.367085, 19.520971],
                [-16.367085, 19.52125],
                [-16.366806, 19.52125],
                [-16.366806, 19.52152800000016],
                [-16.366528, 19.52152800000016],
                [-16.366528, 19.522083],
                [-16.366248999999868, 19.522083],
                [-16.366248999999868, 19.522636],
                [-16.365971, 19.522636],
                [-16.365971, 19.522919000000172],
                [-16.365692, 19.522919000000172],
                [-16.365692, 19.523195000000101],
                [-16.365277999999819, 19.523195000000101],
                [-16.365141, 19.523195000000101],
                [-16.365152, 19.523478],
                [-16.364861, 19.523472],
                [-16.364861, 19.523750000000177],
                [-16.364581999999871, 19.523750000000177],
                [-16.364581999999871, 19.524029000000155],
                [-16.364305, 19.524029000000155],
                [-16.364305, 19.52486000000016],
                [-16.364229, 19.52486000000016],
                [-16.364027, 19.52486000000016],
                [-16.364027, 19.525026],
                [-16.364027, 19.525694000000101],
                [-16.363752, 19.525694000000101],
                [-16.363752, 19.526251000000173],
                [-16.36347, 19.526251000000173],
                [-16.36347, 19.526806],
                [-16.363194, 19.526806],
                [-16.363194, 19.527082000000178],
                [-16.362916999999868, 19.527082000000178],
                [-16.362916999999868, 19.527361000000155],
                [-16.362638, 19.527361000000155],
                [-16.362638, 19.527639],
                [-16.362362, 19.527639],
                [-16.362362, 19.527916],
                [-16.36208299999987, 19.527916],
                [-16.36208299999987, 19.528194000000155],
                [-16.361528, 19.528194000000155],
                [-16.361528, 19.528473000000133],
                [-16.36125, 19.528473000000133],
                [-16.36125, 19.528749],
                [-16.360971, 19.528749],
                [-16.360971, 19.529028],
                [-16.360695, 19.529028],
                [-16.360695, 19.529581000000178],
                [-16.360415999999873, 19.529581000000178],
                [-16.360415999999873, 19.530138],
                [-16.360138, 19.530138],
                [-16.360138, 19.530416000000173],
                [-16.359861, 19.530416000000173],
                [-16.359861, 19.530693000000156],
                [-16.359582999999873, 19.530693000000156],
                [-16.359582999999873, 19.530972000000133],
                [-16.359306, 19.530972000000133],
                [-16.359306, 19.53125],
                [-16.359028, 19.53125],
                [-16.359028, 19.531528000000151],
                [-16.358748999999875, 19.531528000000151],
                [-16.358748999999875, 19.531803],
                [-16.358473, 19.531803],
                [-16.358473, 19.532085],
                [-16.357874, 19.532047],
                [-16.35791799999987, 19.532364],
                [-16.357599, 19.532364],
                [-16.357361, 19.532364],
                [-16.357361, 19.532639],
                [-16.356806, 19.532639],
                [-16.356806, 19.532915000000173],
                [-16.356527, 19.532915000000173],
                [-16.356527, 19.533194000000151],
                [-16.356250999999872, 19.533194000000151],
                [-16.356250999999872, 19.533472],
                [-16.355972, 19.533472],
                [-16.356016, 19.534065000000112],
                [-16.355696, 19.534025000000156],
                [-16.355696, 19.5344300000001],
                [-16.355696, 19.535139000000129],
                [-16.355972, 19.535139000000129],
                [-16.355972, 19.535418000000163],
                [-16.358748999999875, 19.535418000000163],
                [-16.358748999999875, 19.535139000000129],
                [-16.359861, 19.535139000000129],
                [-16.359861, 19.534861],
                [-16.360415999999873, 19.534861],
                [-16.360415999999873, 19.534586],
                [-16.360971, 19.534586],
                [-16.360971, 19.534307],
                [-16.36125, 19.534307],
                [-16.36125, 19.534025000000156],
                [-16.361805, 19.534025000000156],
                [-16.361805, 19.533751],
                [-16.36208299999987, 19.533751],
                [-16.36208299999987, 19.533472],
                [-16.362362, 19.533472],
                [-16.362362, 19.533194000000151],
                [-16.362638, 19.533194000000151],
                [-16.362638, 19.532915000000173],
                [-16.362916999999868, 19.532915000000173],
                [-16.362916999999868, 19.532085],
                [-16.363194, 19.532085],
                [-16.363195, 19.531803],
                [-16.364305, 19.531803],
                [-16.364305, 19.531528000000151],
                [-16.364581999999871, 19.531528000000151],
                [-16.364581999999871, 19.53125],
                [-16.365141, 19.53125],
                [-16.365141, 19.530972000000133],
                [-16.365416999999866, 19.530972000000133],
                [-16.365416999999866, 19.530693000000156],
                [-16.365692, 19.530693000000156],
                [-16.365692, 19.530416000000173],
                [-16.369028, 19.530416000000173],
                [-16.369028, 19.530138],
                [-16.370138, 19.530138],
                [-16.370138, 19.529861],
                [-16.371805, 19.529861],
                [-16.371805, 19.529581000000178],
                [-16.37236, 19.529581000000178],
                [-16.37236, 19.529306000000133],
                [-16.372917, 19.529306000000133],
                [-16.372917, 19.529028],
                [-16.373194, 19.529028],
                [-16.373194, 19.528749],
                [-16.373472, 19.528749],
                [-16.373472, 19.528473000000133],
                [-16.373751, 19.528473000000133],
                [-16.373751, 19.528194000000155],
                [-16.374027, 19.528194000000155],
                [-16.374027, 19.527916],
                [-16.374306, 19.527916],
                [-16.374306, 19.527639],
                [-16.374584, 19.527639],
                [-16.374584, 19.527361000000155],
                [-16.374861, 19.527361000000155],
                [-16.374861, 19.526806],
                [-16.375139, 19.526806],
                [-16.375139, 19.526527000000101],
                [-16.375416, 19.526527000000101],
                [-16.375416, 19.525694000000101],
                [-16.375694, 19.525694000000101],
                [-16.375694, 19.52486000000016],
                [-16.375973, 19.52486000000016],
                [-16.375973, 19.524584],
                [-16.376249, 19.524584],
                [-16.376249, 19.524029000000155],
                [-16.376527999999837, 19.524029000000155],
                [-16.376527999999837, 19.523472],
                [-16.376806, 19.523472],
                [-16.376806, 19.523195000000101],
                [-16.377083, 19.523195000000101],
                [-16.377083, 19.522919000000172],
                [-16.377359, 19.522919000000172],
                [-16.377359, 19.522362000000101],
                [-16.377642, 19.522362000000101],
                [-16.377642, 19.522083],
                [-16.377916, 19.522083],
                [-16.377916, 19.521807],
                [-16.378194999999835, 19.521807],
                [-16.378194999999835, 19.52152800000016],
                [-16.378471, 19.52152800000016],
                [-16.378471, 19.520971],
                [-16.378752, 19.520971],
                [-16.378752, 19.520697000000155],
                [-16.379027999999835, 19.520697000000155],
                [-16.379027999999835, 19.520414000000187],
                [-16.379305, 19.520414000000187],
                [-16.379305, 19.52014],
                [-16.379581, 19.52014],
                [-16.379581, 19.519583000000182],
                [-16.379863999999884, 19.519583000000182],
                [-16.379863999999884, 19.519028000000105],
                [-16.380138, 19.519028000000105],
                [-16.380138, 19.518749000000128],
                [-16.380417, 19.518749000000128],
                [-16.380417, 19.518192],
                [-16.380694999999889, 19.518192],
                [-16.380694999999889, 19.517918],
                [-16.380974, 19.517916000000127],
                [-16.380974, 19.517639],
                [-16.38125, 19.517639],
                [-16.38125, 19.517082000000187],
                [-16.381526999999835, 19.517082000000187],
                [-16.381526999999835, 19.516806],
                [-16.381805, 19.516806],
                [-16.381805, 19.51652700000011],
                [-16.382639, 19.51652700000011],
                [-16.382639, 19.516251000000182],
                [-16.383472, 19.516251000000182],
                [-16.383472, 19.51597],
                [-16.384308, 19.51597],
                [-16.384308, 19.51569400000011],
                [-16.384860999999887, 19.51569400000011],
                [-16.384860999999887, 19.515417000000127],
                [-16.385139, 19.515417000000127],
                [-16.385139, 19.515139],
                [-16.385418, 19.515139],
                [-16.385418, 19.51486],
                [-16.385694999999885, 19.51486],
                [-16.385694999999885, 19.514584000000127],
                [-16.385973, 19.514584000000127],
                [-16.385973, 19.513750000000186],
                [-16.38625, 19.513750000000186],
                [-16.38625, 19.51319500000011],
                [-16.38652599999989, 19.51319500000011],
                [-16.38652599999989, 19.512917000000186],
                [-16.386806, 19.512917000000186],
                [-16.386806, 19.512638],
                [-16.387083, 19.512638],
                [-16.387083, 19.512083000000132],
                [-16.387361999999882, 19.512083000000132],
                [-16.387361999999882, 19.51153],
                [-16.387638, 19.51153],
                [-16.387638, 19.510973],
                [-16.387917, 19.510973],
                [-16.387917, 19.510695],
                [-16.388054, 19.510695],
                [-16.388194999999882, 19.510695],
                [-16.388192999999887, 19.510414],
                [-16.388472, 19.510418000000186],
                [-16.38847, 19.509857000000125],
                [-16.388748, 19.509861000000114],
                [-16.388748, 19.509583000000191],
                [-16.38902899999988, 19.509583000000191],
                [-16.38902899999988, 19.509026000000119],
                [-16.389305, 19.509026000000119],
                [-16.389305, 19.508472],
                [-16.389584, 19.508472],
                [-16.389584, 19.507917000000191],
                [-16.389859999999885, 19.507917000000191],
                [-16.389859999999885, 19.507360000000119],
                [-16.390139, 19.507360000000119],
                [-16.390139, 19.507086000000186],
                [-16.390417, 19.507086000000186],
                [-16.390417, 19.506250000000136],
                [-16.390693999999883, 19.506250000000136],
                [-16.390693999999883, 19.505972],
                [-16.39097, 19.505972],
                [-16.39097, 19.505417000000136],
                [-16.391253, 19.505417000000136],
                [-16.391253, 19.505138000000159],
                [-16.391526999999883, 19.505138000000159],
                [-16.391526999999883, 19.504583],
                [-16.391806, 19.504583],
                [-16.391806, 19.504028000000119],
                [-16.392082, 19.504028000000119],
                [-16.392082, 19.50375],
                [-16.392362999999875, 19.50375],
                [-16.392362999999875, 19.502916000000141],
                [-16.392639, 19.502916000000141],
                [-16.392639, 19.50264],
                [-16.392916, 19.50264],
                [-16.392918, 19.502083000000141],
                [-16.393191999999885, 19.502083000000141],
                [-16.393191999999885, 19.501806000000158],
                [-16.393475, 19.501806000000158],
                [-16.393475, 19.500973000000158],
                [-16.393749, 19.500973000000158],
                [-16.393749, 19.500139],
                [-16.394027999999878, 19.500139],
                [-16.394025999999883, 19.499582000000146],
                [-16.394306, 19.499584000000141],
                [-16.394306, 19.49937100000011],
                [-16.394306, 19.498751000000141],
                [-16.394585, 19.498751000000141],
                [-16.394585, 19.498194],
                [-16.394860999999878, 19.498194],
                [-16.394860999999878, 19.497917],
                [-16.39514, 19.497917],
                [-16.39514, 19.497639000000163],
                [-16.395416, 19.497639000000163],
                [-16.395416, 19.497360000000128],
                [-16.395694999999876, 19.497360000000128],
                [-16.395694999999876, 19.496529000000123],
                [-16.395971, 19.496529000000123],
                [-16.395971, 19.496250000000146],
                [-16.39625, 19.496250000000146],
                [-16.39625, 19.495972],
                [-16.396527999999876, 19.495972],
                [-16.396527999999876, 19.495695],
                [-16.396807, 19.495695],
                [-16.396807, 19.495136000000173],
                [-16.397083, 19.495136000000173],
                [-16.397083, 19.494583],
                [-16.397361999999873, 19.494583],
                [-16.397361999999873, 19.494307000000163],
                [-16.397638, 19.494307000000163],
                [-16.397638, 19.494028000000128],
                [-16.397917, 19.494028000000128],
                [-16.397917, 19.49375],
                [-16.398194999999873, 19.49375],
                [-16.398194999999873, 19.493473],
                [-16.398472, 19.493473],
                [-16.398472, 19.49375],
                [-16.399584, 19.49375],
                [-16.399584, 19.493473],
                [-16.401251, 19.493473],
                [-16.401251, 19.493196],
                [-16.401526999999874, 19.493196],
                [-16.401526999999874, 19.492914000000155],
                [-16.402082, 19.492914000000155],
                [-16.402082, 19.49264],
                [-16.402360999999871, 19.49264],
                [-16.402360999999871, 19.49208300000015],
                [-16.402639, 19.49208300000015],
                [-16.402639, 19.491804000000172],
                [-16.403193999999871, 19.491804000000172],
                [-16.403193999999871, 19.491528],
                [-16.403473, 19.491528],
                [-16.403473, 19.490973000000167],
                [-16.403749, 19.490973000000167],
                [-16.403749, 19.490692],
                [-16.404028, 19.490692],
                [-16.404028, 19.48986100000019],
                [-16.404306, 19.48986100000019],
                [-16.404306, 19.489582000000155],
                [-16.404581, 19.489582000000155],
                [-16.404581, 19.48875],
                [-16.404863, 19.48875],
                [-16.404863, 19.488195000000189],
                [-16.405138, 19.488194],
                [-16.405138, 19.487638],
                [-16.405416, 19.487638],
                [-16.405416, 19.486250000000155],
                [-16.405692999999872, 19.486250000000155],
                [-16.405692999999872, 19.481525],
                [-16.405416, 19.481525],
                [-16.405416, 19.480417],
                [-16.405692999999872, 19.480417],
                [-16.405692999999872, 19.474028],
                [-16.405973, 19.474028],
                [-16.405973, 19.473749],
                [-16.406252, 19.473749],
                [-16.406252, 19.472918],
                [-16.406528, 19.472918],
                [-16.406528, 19.472361],
                [-16.406803, 19.472361],
                [-16.406803, 19.470972000000131],
                [-16.407082, 19.470972000000131],
                [-16.407082, 19.470419],
                [-16.407364, 19.470419],
                [-16.407364, 19.470139000000131],
                [-16.407639, 19.470139000000131],
                [-16.407639, 19.469584],
                [-16.407917, 19.469584],
                [-16.407917, 19.469028],
                [-16.408195, 19.469028],
                [-16.408195, 19.468750000000114],
                [-16.408472, 19.468750000000114],
                [-16.408472, 19.468472],
                [-16.408751, 19.468472],
                [-16.408751, 19.468197],
                [-16.409027, 19.468197],
                [-16.409027, 19.467636000000141],
                [-16.409306, 19.467636000000141],
                [-16.409306, 19.467361000000153],
                [-16.409861, 19.467361000000153],
                [-16.409861, 19.467085],
                [-16.410139, 19.467085],
                [-16.410139, 19.466806],
                [-16.410418, 19.466806],
                [-16.410418, 19.466528000000153],
                [-16.410694, 19.466528000000153],
                [-16.410694, 19.466249000000118],
                [-16.411249, 19.466249000000118],
                [-16.411249, 19.465975000000185],
                [-16.411528, 19.465975000000185],
                [-16.411528, 19.465696],
                [-16.411805999999842, 19.465696],
                [-16.411805999999842, 19.465414000000123],
                [-16.412083, 19.465414000000123],
                [-16.412083, 19.465139000000136],
                [-16.412361, 19.465139000000136],
                [-16.412361, 19.464861],
                [-16.41264, 19.464861],
                [-16.41264, 19.464582],
                [-16.412916, 19.464582],
                [-16.412916, 19.464306000000136],
                [-16.413195, 19.464306000000136],
                [-16.413195, 19.464027000000158],
                [-16.41347299999984, 19.464027000000158],
                [-16.41347299999984, 19.463472],
                [-16.41375, 19.463472],
                [-16.41375, 19.462917000000118],
                [-16.414028, 19.462917000000118],
                [-16.414028, 19.46236],
                [-16.414304999999842, 19.46236],
                [-16.414304999999842, 19.462084000000118],
                [-16.414583, 19.462084000000118],
                [-16.414583, 19.461527],
                [-16.414862, 19.461527],
                [-16.414862, 19.459862000000157],
                [-16.414583, 19.459862000000157],
                [-16.414583, 19.459305],
                [-16.414304999999842, 19.459305],
                [-16.414304999999842, 19.459028],
                [-16.414583, 19.459028],
                [-16.414583, 19.458471000000145],
                [-16.414862, 19.458471000000145],
                [-16.414862, 19.457916],
                [-16.415137999999843, 19.457916],
                [-16.415137999999843, 19.456249000000184],
                [-16.414862, 19.456249000000184],
                [-16.414862, 19.455694],
                [-16.414583, 19.455694],
                [-16.414583, 19.455139000000145],
                [-16.414304999999842, 19.455139000000145],
                [-16.414304999999842, 19.45430800000014],
                [-16.414028, 19.45430800000014],
                [-16.414028, 19.453751],
                [-16.41375, 19.453751],
                [-16.41375, 19.452915000000189],
                [-16.41347299999984, 19.452915000000189],
                [-16.41347299999984, 19.452085],
                [-16.413195, 19.452085],
                [-16.413195, 19.450138],
                [-16.412916, 19.450138],
                [-16.412916, 19.449581],
                [-16.41264, 19.449581],
                [-16.41264, 19.449306000000149],
                [-16.412361, 19.449305],
                [-16.412361, 19.449028],
                [-16.412083, 19.449028],
                [-16.412083, 19.448750000000189],
                [-16.410972999999842, 19.448750000000189],
                [-16.410972999999842, 19.448471],
                [-16.410139, 19.448471],
                [-16.410139, 19.448750000000189],
                [-16.409027, 19.448750000000189],
                [-16.409027, 19.449028],
                [-16.408751, 19.449028],
                [-16.408751, 19.449581],
                [-16.408472, 19.449581],
                [-16.408472, 19.45125],
                [-16.408195, 19.45125],
                [-16.408195, 19.451529],
                [-16.407364, 19.451529],
                [-16.407364, 19.451803],
                [-16.407082, 19.451803],
                [-16.407082, 19.452085],
                [-16.406803, 19.452085],
                [-16.406803, 19.45236],
                [-16.406528, 19.452362],
                [-16.406528, 19.453751],
                [-16.406252, 19.453751],
                [-16.406252, 19.454025000000172],
                [-16.405692999999872, 19.454025000000172],
                [-16.405692999999872, 19.453751],
                [-16.405416, 19.453751],
                [-16.405416, 19.453472],
                [-16.405138, 19.453472],
                [-16.405138, 19.453196000000162],
                [-16.404863, 19.453196000000162],
                [-16.404863, 19.452915000000189],
                [-16.403193999999871, 19.452915000000189],
                [-16.403193999999871, 19.452639],
                [-16.402916, 19.452639],
                [-16.402916, 19.451803],
                [-16.403193999999871, 19.451803],
                [-16.403193999999871, 19.451529],
                [-16.403473, 19.451529],
                [-16.403473, 19.449862000000167],
                [-16.403193999999871, 19.449862000000167],
                [-16.403193999999871, 19.448750000000189],
                [-16.402916, 19.448750000000189],
                [-16.402916, 19.448195],
                [-16.403749, 19.448195],
                [-16.403749, 19.447916],
                [-16.403473, 19.447916],
                [-16.403473, 19.447359000000176],
                [-16.403749, 19.447359000000176],
                [-16.403749, 19.447083],
                [-16.404581, 19.447083],
                [-16.404581, 19.446806],
                [-16.405138, 19.446806],
                [-16.405138, 19.446527000000117],
                [-16.406528, 19.446527000000117],
                [-16.406528, 19.446251000000188],
                [-16.407082, 19.446251000000188],
                [-16.407082, 19.445694000000117],
                [-16.407364, 19.445694000000117],
                [-16.407364, 19.445141000000149],
                [-16.407639, 19.445141000000149],
                [-16.407639, 19.444860000000176],
                [-16.407917, 19.444860000000176],
                [-16.407917, 19.444584],
                [-16.408472, 19.444584],
                [-16.408472, 19.444029000000171],
                [-16.408751, 19.444029000000171],
                [-16.408751, 19.443472],
                [-16.409027, 19.443472],
                [-16.409027, 19.442919000000188],
                [-16.409306, 19.442919000000188],
                [-16.409306, 19.442362000000116],
                [-16.409586, 19.442362000000116],
                [-16.409586, 19.441528000000176],
                [-16.409861, 19.441528000000176],
                [-16.409861, 19.440971000000104],
                [-16.410139, 19.440971000000104],
                [-16.410139, 19.439585],
                [-16.410418, 19.439585],
                [-16.410418, 19.439304],
                [-16.410694, 19.439304],
                [-16.410694, 19.438475],
                [-16.410972999999842, 19.438475],
                [-16.410972999999842, 19.438192],
                [-16.411249, 19.438192],
                [-16.411249, 19.437082],
                [-16.411528, 19.437082],
                [-16.411528, 19.436806000000104],
                [-16.411805999999842, 19.436806000000104],
                [-16.411805999999842, 19.43597],
                [-16.412083, 19.43597],
                [-16.412083, 19.435417],
                [-16.41264, 19.435417],
                [-16.41264, 19.435139],
                [-16.41347299999984, 19.435139],
                [-16.41347299999984, 19.43486],
                [-16.414304999999842, 19.43486],
                [-16.414304999999842, 19.434584],
                [-16.414583, 19.434584],
                [-16.414583, 19.43402900000018],
                [-16.415417, 19.43402900000018],
                [-16.415417, 19.43375],
                [-16.415695, 19.43375],
                [-16.415695, 19.433195000000126],
                [-16.416248, 19.433195000000126],
                [-16.416248, 19.432917],
                [-16.416529, 19.432917],
                [-16.416529, 19.432638],
                [-16.41680499999984, 19.432638],
                [-16.41680499999984, 19.432362000000126],
                [-16.41736, 19.432362000000126],
                [-16.41736, 19.432083000000148],
                [-16.417917, 19.432083000000148],
                [-16.417917, 19.431805],
                [-16.417638999999895, 19.431805],
                [-16.417638999999895, 19.430973000000108],
                [-16.417917, 19.430973000000108],
                [-16.417917, 19.430695],
                [-16.418194, 19.430695],
                [-16.418194, 19.430416],
                [-16.418753, 19.430416],
                [-16.418753, 19.430973000000108],
                [-16.419027, 19.430973000000108],
                [-16.419027, 19.430695],
                [-16.419305999999892, 19.430695],
                [-16.419305999999892, 19.42986100000013],
                [-16.419863, 19.42986100000013],
                [-16.419863, 19.429583],
                [-16.420692, 19.429583],
                [-16.420692, 19.429308],
                [-16.420974999999885, 19.429308],
                [-16.420974999999885, 19.429583],
                [-16.421528, 19.429583],
                [-16.421528, 19.429308],
                [-16.422637999999893, 19.429308],
                [-16.422637999999893, 19.42986100000013],
                [-16.422916, 19.42986100000013],
                [-16.422916, 19.430140000000108],
                [-16.423197, 19.430140000000108],
                [-16.423197, 19.430416],
                [-16.424028, 19.430416],
                [-16.424028, 19.430140000000108],
                [-16.424306999999885, 19.430140000000108],
                [-16.424306999999885, 19.430416],
                [-16.424583, 19.430416],
                [-16.424583, 19.430140000000108],
                [-16.42513799999989, 19.430140000000108],
                [-16.42513799999989, 19.42986100000013],
                [-16.425417, 19.42986100000013],
                [-16.425417, 19.429583],
                [-16.425694, 19.429583],
                [-16.425695, 19.429308],
                [-16.425971999999888, 19.429308],
                [-16.425971999999888, 19.428473],
                [-16.42625, 19.428473],
                [-16.42625, 19.426804000000118],
                [-16.426804999999888, 19.426804000000118],
                [-16.426804999999888, 19.426250000000152],
                [-16.427084, 19.426250000000152],
                [-16.427084, 19.425417000000152],
                [-16.427636999999891, 19.425417000000152],
                [-16.427636999999891, 19.425138000000118],
                [-16.427917, 19.425138000000118],
                [-16.427917, 19.424864],
                [-16.428194, 19.424864],
                [-16.428194, 19.425138000000118],
                [-16.428472999999883, 19.425138000000118],
                [-16.428472999999883, 19.424864],
                [-16.429305999999883, 19.424864],
                [-16.429305999999883, 19.424585],
                [-16.429859, 19.424585],
                [-16.429859, 19.42430300000018],
                [-16.430498, 19.42430300000018],
                [-16.432081, 19.42430300000018],
                [-16.432081, 19.424585],
                [-16.432364, 19.424585],
                [-16.432364, 19.424864],
                [-16.432917, 19.424864],
                [-16.432917, 19.425138000000118],
                [-16.433195, 19.425138000000118],
                [-16.433195, 19.425417000000152],
                [-16.433473999999876, 19.425417000000152],
                [-16.433473999999876, 19.425694000000135],
                [-16.434029, 19.425694000000135],
                [-16.434029, 19.425974],
                [-16.435138999999879, 19.425974],
                [-16.435138999999879, 19.426250000000152],
                [-16.435696, 19.426250000000152],
                [-16.435696, 19.425974],
                [-16.436251, 19.425974],
                [-16.436251, 19.425694000000135],
                [-16.437361, 19.425694000000135],
                [-16.437361, 19.425417000000152],
                [-16.437918, 19.425417000000152],
                [-16.437918, 19.425138000000118],
                [-16.438194, 19.425138000000118],
                [-16.438194, 19.424028000000135],
                [-16.438473, 19.424028000000135],
                [-16.438473, 19.42375],
                [-16.438749, 19.42375],
                [-16.438749, 19.422916000000157],
                [-16.439306, 19.422916000000157],
                [-16.439306, 19.422361],
                [-16.439583, 19.422361],
                [-16.439583, 19.421806000000117],
                [-16.44125, 19.421806000000117],
                [-16.44125, 19.421249],
                [-16.440695, 19.421249],
                [-16.440695, 19.420139],
                [-16.440970999999877, 19.420139],
                [-16.440970999999877, 19.419027],
                [-16.440695, 19.419027],
                [-16.440695, 19.418472000000179],
                [-16.440970999999877, 19.418472000000179],
                [-16.440970999999877, 19.417084],
                [-16.44125, 19.417084],
                [-16.44125, 19.415972],
                [-16.441528, 19.415972],
                [-16.441528, 19.414862],
                [-16.44125, 19.414862],
                [-16.44125, 19.414583],
                [-16.441805, 19.414583],
                [-16.441805, 19.414028000000144],
                [-16.442083, 19.414028000000144],
                [-16.442083, 19.411249000000112],
                [-16.442362, 19.411249000000112],
                [-16.442362, 19.410975000000178],
                [-16.442638, 19.410975000000178],
                [-16.442638, 19.410418],
                [-16.442917, 19.410418],
                [-16.442917, 19.410139],
                [-16.443195, 19.410139],
                [-16.443195, 19.409582000000171],
                [-16.443472, 19.409582000000171],
                [-16.443472, 19.409452000000101],
                [-16.443472, 19.409306],
                [-16.44375, 19.409306],
                [-16.44375, 19.408751000000166],
                [-16.444305, 19.408751000000166],
                [-16.444305, 19.408194],
                [-16.444584, 19.408194],
                [-16.444584, 19.407917000000111],
                [-16.44486, 19.407917000000111],
                [-16.44486, 19.407639000000188],
                [-16.445417, 19.407639000000188],
                [-16.445417, 19.40736],
                [-16.445692, 19.40736],
                [-16.445692, 19.407084000000111],
                [-16.446527, 19.407084000000111],
                [-16.446527, 19.406805],
                [-16.447639, 19.406805],
                [-16.447639, 19.407084000000111],
                [-16.447914, 19.407084000000111],
                [-16.447914, 19.40736],
                [-16.448195, 19.40736],
                [-16.448195, 19.407639000000188],
                [-16.447914, 19.407639000000188],
                [-16.447914, 19.408194],
                [-16.448195, 19.408194],
                [-16.448195, 19.40847],
                [-16.448749999999848, 19.40847],
                [-16.448749999999848, 19.408751000000166],
                [-16.449582999999848, 19.408751000000166],
                [-16.449582999999848, 19.40847],
                [-16.450138, 19.40847],
                [-16.450138, 19.408751000000166],
                [-16.450697, 19.408751000000166],
                [-16.450697, 19.409027],
                [-16.450972, 19.409027],
                [-16.450972, 19.409306],
                [-16.451249999999845, 19.409306],
                [-16.451249999999845, 19.409582000000171],
                [-16.452084, 19.409582000000171],
                [-16.452084, 19.409861],
                [-16.453472, 19.409861],
                [-16.453472, 19.410139],
                [-16.454306, 19.410139],
                [-16.454306, 19.410418],
                [-16.455416, 19.410418],
                [-16.455416, 19.410692],
                [-16.456528, 19.410692],
                [-16.456528, 19.410975000000178],
                [-16.456806, 19.410975000000178],
                [-16.456806, 19.411249000000112],
                [-16.457359, 19.411249000000112],
                [-16.457359, 19.411528],
                [-16.458195, 19.411528],
                [-16.458195, 19.411804000000188],
                [-16.458471, 19.411804000000188],
                [-16.458471, 19.412083000000166],
                [-16.459028, 19.412083000000166],
                [-16.459028, 19.412361],
                [-16.459305, 19.412361],
                [-16.459305, 19.41264],
                [-16.459581, 19.41264],
                [-16.459581, 19.412914000000171],
                [-16.460138, 19.412914000000171],
                [-16.460138, 19.413197000000139],
                [-16.460694999999816, 19.413197000000139],
                [-16.460694999999816, 19.413473],
                [-16.461805, 19.413473],
                [-16.461805, 19.41375],
                [-16.462085999999886, 19.41375],
                [-16.462085999999886, 19.414028000000144],
                [-16.462639, 19.414028000000144],
                [-16.462639, 19.414307000000179],
                [-16.463196, 19.414307000000179],
                [-16.463196, 19.414583],
                [-16.463750999999888, 19.414583],
                [-16.463750999999888, 19.414862],
                [-16.464026999999817, 19.414862],
                [-16.464026999999817, 19.415136000000189],
                [-16.464581999999893, 19.415136000000189],
                [-16.464581999999893, 19.415419000000156],
                [-16.465417999999886, 19.415419000000156],
                [-16.465417999999886, 19.415695],
                [-16.465693999999814, 19.415695],
                [-16.465693999999814, 19.415972],
                [-16.466248999999891, 19.415972],
                [-16.466248999999891, 19.416250000000161],
                [-16.466528, 19.416250000000161],
                [-16.466528, 19.416529000000139],
                [-16.467082999999889, 19.416529000000139],
                [-16.467082999999889, 19.416805],
                [-16.467361, 19.416805],
                [-16.467361, 19.417084],
                [-16.46764, 19.417084],
                [-16.46764, 19.417360000000144],
                [-16.467915999999889, 19.417360000000144],
                [-16.467915999999889, 19.417917],
                [-16.468195, 19.417917],
                [-16.468195, 19.418472000000179],
                [-16.468472, 19.418472000000179],
                [-16.468472, 19.418751000000157],
                [-16.468747999999891, 19.418751000000157],
                [-16.468747999999891, 19.419306],
                [-16.469028, 19.419306],
                [-16.469028, 19.419584000000157],
                [-16.469305, 19.419584000000157],
                [-16.469305, 19.420139],
                [-16.469583999999884, 19.420139],
                [-16.469583999999884, 19.42069400000014],
                [-16.469859999999869, 19.42069400000014],
                [-16.469859999999869, 19.421806000000117],
                [-16.470139, 19.421806000000117],
                [-16.470139, 19.422361],
                [-16.470416999999884, 19.422361],
                [-16.470416999999884, 19.422638],
                [-16.470694, 19.42264],
                [-16.470694, 19.423195000000135],
                [-16.47097, 19.423195000000135],
                [-16.47097, 19.424028000000135],
                [-16.470694, 19.424028000000135],
                [-16.470694, 19.425138000000118],
                [-16.47097, 19.425138000000118],
                [-16.47097, 19.425694000000135],
                [-16.471252, 19.425694000000135],
                [-16.471252, 19.42652500000014],
                [-16.47097, 19.42652500000014],
                [-16.47097, 19.427361],
                [-16.470694, 19.427361],
                [-16.470694, 19.427917],
                [-16.470416999999884, 19.427917],
                [-16.470416999999884, 19.428473],
                [-16.470139, 19.428473],
                [-16.470139, 19.430140000000108],
                [-16.470416999999884, 19.430140000000108],
                [-16.470416999999884, 19.431805],
                [-16.470139, 19.431805],
                [-16.470139, 19.432083000000148],
                [-16.469859999999869, 19.432083000000148],
                [-16.469859999999869, 19.432362000000126],
                [-16.469583999999884, 19.432362000000126],
                [-16.469583999999884, 19.432638],
                [-16.469305, 19.432638],
                [-16.469305, 19.433195000000126],
                [-16.469028, 19.433195000000126],
                [-16.469028, 19.434584],
                [-16.468747999999891, 19.434584],
                [-16.468747999999891, 19.435139],
                [-16.468472, 19.435139],
                [-16.468472, 19.43597],
                [-16.468195, 19.43597],
                [-16.468195, 19.436806000000104],
                [-16.467915999999889, 19.436806000000104],
                [-16.467915999999889, 19.438475],
                [-16.46764, 19.438475],
                [-16.46764, 19.438749],
                [-16.467361, 19.438749],
                [-16.467361, 19.439028000000121],
                [-16.467082999999889, 19.439028000000121],
                [-16.467082999999889, 19.439304],
                [-16.466528, 19.439304],
                [-16.466528, 19.439585],
                [-16.466248999999891, 19.439585],
                [-16.466248999999891, 19.440414],
                [-16.465973, 19.440414],
                [-16.465973, 19.440697000000171],
                [-16.465693999999814, 19.440697000000171],
                [-16.465693999999814, 19.440971000000104],
                [-16.465417999999886, 19.440971000000104],
                [-16.465417999999886, 19.44125],
                [-16.465139, 19.44125],
                [-16.465139, 19.441528000000176],
                [-16.464860999999814, 19.441528000000176],
                [-16.464860999999814, 19.441807],
                [-16.464581999999893, 19.441807],
                [-16.464581999999893, 19.442083],
                [-16.464308, 19.442083],
                [-16.464308, 19.442362000000116],
                [-16.464026999999817, 19.442362000000116],
                [-16.464026999999817, 19.442638],
                [-16.463750999999888, 19.442638],
                [-16.463750999999888, 19.442919000000188],
                [-16.463472, 19.442919000000188],
                [-16.463472, 19.443195000000117],
                [-16.463196, 19.443195000000117],
                [-16.463196, 19.443472],
                [-16.462916999999891, 19.443472],
                [-16.462916999999891, 19.444029000000171],
                [-16.462639, 19.444029000000171],
                [-16.462639, 19.444305],
                [-16.46236, 19.444305],
                [-16.46236, 19.444860000000176],
                [-16.462085999999886, 19.444860000000176],
                [-16.462085999999886, 19.445694000000117],
                [-16.461805, 19.445694000000117],
                [-16.461805, 19.446527000000117],
                [-16.461527, 19.446527000000117],
                [-16.461527, 19.446806],
                [-16.461249999999893, 19.446806],
                [-16.461249999999893, 19.447359000000176],
                [-16.460974, 19.447359000000176],
                [-16.460974, 19.447916],
                [-16.460694999999816, 19.447916],
                [-16.460694999999816, 19.448195],
                [-16.460416999999893, 19.448195],
                [-16.460416999999893, 19.448471],
                [-16.460138, 19.448471],
                [-16.460138, 19.448750000000189],
                [-16.459864, 19.448750000000189],
                [-16.459864, 19.449028],
                [-16.459581, 19.449028],
                [-16.459581, 19.449581],
                [-16.459305, 19.449581],
                [-16.459305, 19.450138],
                [-16.459028, 19.450138],
                [-16.459028, 19.450417],
                [-16.458749999999895, 19.450476000000151],
                [-16.458749999999895, 19.450972000000149],
                [-16.458471, 19.450972000000149],
                [-16.458471, 19.451143000000116],
                [-16.458471, 19.451529],
                [-16.458311, 19.451529],
                [-16.458195, 19.451529],
                [-16.458195, 19.451803],
                [-16.457915999999898, 19.451803],
                [-16.457915999999898, 19.452221000000122],
                [-16.457915999999898, 19.452639],
                [-16.457703, 19.452663],
                [-16.457642, 19.452831000000174],
                [-16.457642, 19.453472],
                [-16.457352, 19.4534680000001],
                [-16.457359, 19.45430800000014],
                [-16.457082999999898, 19.45430800000014],
                [-16.457082999999898, 19.455139000000145],
                [-16.456806, 19.455139000000145],
                [-16.456806, 19.455694],
                [-16.456528, 19.455694],
                [-16.456528, 19.455973],
                [-16.456223, 19.455961000000116],
                [-16.456249, 19.456528000000162],
                [-16.455969, 19.456524000000172],
                [-16.455973, 19.457083],
                [-16.455694, 19.45712500000019],
                [-16.455694, 19.458471000000145],
                [-16.455416, 19.458471000000145],
                [-16.455416, 19.459028],
                [-16.455137, 19.459028],
                [-16.455137, 19.459583000000123],
                [-16.454861, 19.459583000000123],
                [-16.454861, 19.460138],
                [-16.454583999999898, 19.460138],
                [-16.454583999999898, 19.460695000000158],
                [-16.454306, 19.460695000000158],
                [-16.454306, 19.46125],
                [-16.454027, 19.46125],
                [-16.454027, 19.462084000000118],
                [-16.453750999999897, 19.462084000000118],
                [-16.453750999999897, 19.462639],
                [-16.453472, 19.462639],
                [-16.453472, 19.462917000000118],
                [-16.453194, 19.462917000000118],
                [-16.453194, 19.463194000000158],
                [-16.452917, 19.463255],
                [-16.452917, 19.463751],
                [-16.452639, 19.463751],
                [-16.452639, 19.463869],
                [-16.452639, 19.465975000000185],
                [-16.452362, 19.465975000000185],
                [-16.45236, 19.466528000000153],
                [-16.452084, 19.466528000000153],
                [-16.452084, 19.466806],
                [-16.451805, 19.466806],
                [-16.451805, 19.467085],
                [-16.451529, 19.467085],
                [-16.451529, 19.467361000000153],
                [-16.451249999999845, 19.467361000000153],
                [-16.451249999999845, 19.467636000000141],
                [-16.450697, 19.467636000000141],
                [-16.450697, 19.467915000000119],
                [-16.450376999999889, 19.467894000000172],
                [-16.450416999999845, 19.469307000000185],
                [-16.450138, 19.469307000000185],
                [-16.450138, 19.471251000000109],
                [-16.449862, 19.471251000000109],
                [-16.449862, 19.471527],
                [-16.449582999999848, 19.471527],
                [-16.449582999999848, 19.472084000000109],
                [-16.449306, 19.472084000000109],
                [-16.449303, 19.472639000000186],
                [-16.449028, 19.472639000000186],
                [-16.449028, 19.472918],
                [-16.448749999999848, 19.472918],
                [-16.448749999999848, 19.473749],
                [-16.448475, 19.473749],
                [-16.448475, 19.474583000000109],
                [-16.448195, 19.474583000000109],
                [-16.448195, 19.475140000000181],
                [-16.447914, 19.475140000000181],
                [-16.447914, 19.475416000000109],
                [-16.447639, 19.475416000000109],
                [-16.447639, 19.475695],
                [-16.447363, 19.475695],
                [-16.447363, 19.47625],
                [-16.447083999999847, 19.47625],
                [-16.447083999999847, 19.476528],
                [-16.446806, 19.476528],
                [-16.446806, 19.477081],
                [-16.446527, 19.477081],
                [-16.446527, 19.477917000000105],
                [-16.446252999999899, 19.477917000000105],
                [-16.446252999999899, 19.478193],
                [-16.445971, 19.478193],
                [-16.445971, 19.478750000000105],
                [-16.445692, 19.478750000000105],
                [-16.445692, 19.479029],
                [-16.445417, 19.479029],
                [-16.445417, 19.479303000000186],
                [-16.445139, 19.479303000000186],
                [-16.445139, 19.479586000000154],
                [-16.44486, 19.479586000000154],
                [-16.44486, 19.480139000000122],
                [-16.444584, 19.480139000000122],
                [-16.444584, 19.480696],
                [-16.444305, 19.480696],
                [-16.444305, 19.481525],
                [-16.444027, 19.481525],
                [-16.444027, 19.481808000000171],
                [-16.44375, 19.481808000000171],
                [-16.44375, 19.482361],
                [-16.443195, 19.482361],
                [-16.443195, 19.482639000000177],
                [-16.442917, 19.482639000000177],
                [-16.442917, 19.482918000000154],
                [-16.442638, 19.482918000000154],
                [-16.442638, 19.483194],
                [-16.442362, 19.483194],
                [-16.442362, 19.483473],
                [-16.442083, 19.483473],
                [-16.442083, 19.483749],
                [-16.441805, 19.483749],
                [-16.441805, 19.484306],
                [-16.441528, 19.484306],
                [-16.441528, 19.4845830000001],
                [-16.44125, 19.4845830000001],
                [-16.44125, 19.484861],
                [-16.440970999999877, 19.484861],
                [-16.440970999999877, 19.485140000000172],
                [-16.440695, 19.485140000000172],
                [-16.440695, 19.485695],
                [-16.440416, 19.485695],
                [-16.440416, 19.485971000000177],
                [-16.44014, 19.485971000000177],
                [-16.44014, 19.486805],
                [-16.439861, 19.486805],
                [-16.439861, 19.487083000000155],
                [-16.439306, 19.487083000000155],
                [-16.439306, 19.487362000000189],
                [-16.439028, 19.487362000000189],
                [-16.439028, 19.487638],
                [-16.438749, 19.487638],
                [-16.438749, 19.487917],
                [-16.438473, 19.487917],
                [-16.438473, 19.488194],
                [-16.438194, 19.488194],
                [-16.438194, 19.48875],
                [-16.437918, 19.48875],
                [-16.437918, 19.489027],
                [-16.437638999999876, 19.489027],
                [-16.437638999999876, 19.489305000000172],
                [-16.437361, 19.489305000000172],
                [-16.437361, 19.489582000000155],
                [-16.437084, 19.489582000000155],
                [-16.437084, 19.49041700000015],
                [-16.436805999999876, 19.49041700000015],
                [-16.436805999999876, 19.490973000000167],
                [-16.436527, 19.490973000000167],
                [-16.436527, 19.491804000000172],
                [-16.436251, 19.491804000000172],
                [-16.436251, 19.49208300000015],
                [-16.435971999999879, 19.49208300000015],
                [-16.435971999999879, 19.492914000000155],
                [-16.435696, 19.492914000000155],
                [-16.435696, 19.493196],
                [-16.435417, 19.493196],
                [-16.435417, 19.493473],
                [-16.435138999999879, 19.493473],
                [-16.435138999999879, 19.495136000000173],
                [-16.43486, 19.495136000000173],
                [-16.43486, 19.495972],
                [-16.434586, 19.495972],
                [-16.434586, 19.496529000000123],
                [-16.434302999999886, 19.496529000000123],
                [-16.434302999999886, 19.496805],
                [-16.434029, 19.496805],
                [-16.434029, 19.497360000000128],
                [-16.43375, 19.497360000000128],
                [-16.43375, 19.497639000000163],
                [-16.433473999999876, 19.497639000000163],
                [-16.433473999999876, 19.498194],
                [-16.433195, 19.498194],
                [-16.433195, 19.498472000000163],
                [-16.432917, 19.498472000000163],
                [-16.432917, 19.499306],
                [-16.432637999999884, 19.499306],
                [-16.432637999999884, 19.499584000000141],
                [-16.432364, 19.499584000000141],
                [-16.432364, 19.500139],
                [-16.432081, 19.500139],
                [-16.432081, 19.500694000000124],
                [-16.431804999999883, 19.500694000000124],
                [-16.431804999999883, 19.500973000000158],
                [-16.431528, 19.500973000000158],
                [-16.431528, 19.501249],
                [-16.43125, 19.501249],
                [-16.43125, 19.501806000000158],
                [-16.430970999999886, 19.501806000000158],
                [-16.430970999999886, 19.502361],
                [-16.430695, 19.502361],
                [-16.430695, 19.502916000000141],
                [-16.430416, 19.502916000000141],
                [-16.430416, 19.503471],
                [-16.430139999999881, 19.503471],
                [-16.430139999999881, 19.504028000000119],
                [-16.429859, 19.504028000000119],
                [-16.429859, 19.504305000000159],
                [-16.429583, 19.504305000000159],
                [-16.429583, 19.504864],
                [-16.429305999999883, 19.504864],
                [-16.429305999999883, 19.505417000000136],
                [-16.429028, 19.505417000000136],
                [-16.429028, 19.506250000000136],
                [-16.428749, 19.506250000000136],
                [-16.428749, 19.506804000000159],
                [-16.428472999999883, 19.506804000000159],
                [-16.428472999999883, 19.507086000000186],
                [-16.428194, 19.507086000000186],
                [-16.428194, 19.507360000000119],
                [-16.427917, 19.507360000000119],
                [-16.427917, 19.507917000000191],
                [-16.427636999999891, 19.507917000000191],
                [-16.427636999999891, 19.508751000000132],
                [-16.427361, 19.508751000000132],
                [-16.427361, 19.509308],
                [-16.427084, 19.509308],
                [-16.427084, 19.509583000000191],
                [-16.426804999999888, 19.509583000000191],
                [-16.426804999999888, 19.509861000000114],
                [-16.426529, 19.509861000000114],
                [-16.426529, 19.510695],
                [-16.42625, 19.510695],
                [-16.42625, 19.51153],
                [-16.425971999999888, 19.51153],
                [-16.425971999999888, 19.512083000000132],
                [-16.425694, 19.512083000000132],
                [-16.425694, 19.51236200000011],
                [-16.42513799999989, 19.51236200000011],
                [-16.42513799999989, 19.512638],
                [-16.424862, 19.512638],
                [-16.424862, 19.512917000000186],
                [-16.424528, 19.512896],
                [-16.424583, 19.514029],
                [-16.424306999999885, 19.514029],
                [-16.424306999999885, 19.51597],
                [-16.424028, 19.51597],
                [-16.424028, 19.517082000000187],
                [-16.424306999999885, 19.517082000000187],
                [-16.424306999999885, 19.517639],
                [-16.424583, 19.517639],
                [-16.424583, 19.518749000000128],
                [-16.424862, 19.518749000000128],
                [-16.424862, 19.519583000000182],
                [-16.424583, 19.519583000000182],
                [-16.424583, 19.519861000000105],
                [-16.424306999999885, 19.519861000000105],
                [-16.424306999999885, 19.52014],
                [-16.424583, 19.52014],
                [-16.424583, 19.520697000000155],
                [-16.424862, 19.520697000000155],
                [-16.424862, 19.521807],
                [-16.42513799999989, 19.521807],
                [-16.42513799999989, 19.523195000000101],
                [-16.424862, 19.523195000000101],
                [-16.424862, 19.524584],
                [-16.424583, 19.524584],
                [-16.424583, 19.525417],
                [-16.424306999999885, 19.525417],
                [-16.424306999999885, 19.526527000000101],
                [-16.424028, 19.526527000000101],
                [-16.424028, 19.527361000000155],
                [-16.42375, 19.527361000000155],
                [-16.42375, 19.528194000000155],
                [-16.423470999999893, 19.528194000000155],
                [-16.423470999999893, 19.530693000000156],
                [-16.423197, 19.530693000000156],
                [-16.423197, 19.53125],
                [-16.422916, 19.53125],
                [-16.422916, 19.531803],
                [-16.423197, 19.531803],
                [-16.423197, 19.532639],
                [-16.422916, 19.532639],
                [-16.422916, 19.532915000000173],
                [-16.422637999999893, 19.532915000000173],
                [-16.422637999999893, 19.533194000000151],
                [-16.422085, 19.533194000000151],
                [-16.422085, 19.533472],
                [-16.421528, 19.533472],
                [-16.421528, 19.533751],
                [-16.421249, 19.533751],
                [-16.421249, 19.534025000000156],
                [-16.420974999999885, 19.534025000000156],
                [-16.420974999999885, 19.534307],
                [-16.420692, 19.534307],
                [-16.420692, 19.534586],
                [-16.420416, 19.534586],
                [-16.420416, 19.534861],
                [-16.420138999999892, 19.534861],
                [-16.420138999999892, 19.535139000000129],
                [-16.419584, 19.535139000000129],
                [-16.41958, 19.535418000000163],
                [-16.419305999999892, 19.535418000000163],
                [-16.419305999999892, 19.535694],
                [-16.419027, 19.535694],
                [-16.419027, 19.535973],
                [-16.417917, 19.535973],
                [-16.417917, 19.536247000000174],
                [-16.41736, 19.536247000000174],
                [-16.41736, 19.536528000000146],
                [-16.417084, 19.536528000000146],
                [-16.417084, 19.536806],
                [-16.416529, 19.536806],
                [-16.416529, 19.537083],
                [-16.41597199999984, 19.537083],
                [-16.41597199999984, 19.537361000000146],
                [-16.415695, 19.537361000000146],
                [-16.415695, 19.537640000000124],
                [-16.415137999999843, 19.537640000000124],
                [-16.415137999999843, 19.537916],
                [-16.414862, 19.537916],
                [-16.414862, 19.538195],
                [-16.414583, 19.538195],
                [-16.414583, 19.538473000000124],
                [-16.41375, 19.538473000000124],
                [-16.41375, 19.538750000000164],
                [-16.41347299999984, 19.538750000000164],
                [-16.41347299999984, 19.539305],
                [-16.413195, 19.539305],
                [-16.413195, 19.539583000000164],
                [-16.412916, 19.539583000000164],
                [-16.412916, 19.539862000000142],
                [-16.41264, 19.539862000000142],
                [-16.41264, 19.540417],
                [-16.412361, 19.540417],
                [-16.412361, 19.540695000000142],
                [-16.412083, 19.540695000000142],
                [-16.412083, 19.540972000000124],
                [-16.411805999999842, 19.540972000000124],
                [-16.411805999999842, 19.54125],
                [-16.410694, 19.54125],
                [-16.410694, 19.541529],
                [-16.410418, 19.541529],
                [-16.410418, 19.541805000000124],
                [-16.410139, 19.541805000000124],
                [-16.410139, 19.542639],
                [-16.409861, 19.542639],
                [-16.409861, 19.543194000000142],
                [-16.409586, 19.543194000000142],
                [-16.409586, 19.543751],
                [-16.409306, 19.543751],
                [-16.409306, 19.544582],
                [-16.409027, 19.544582],
                [-16.409027, 19.546804],
                [-16.409306, 19.546804],
                [-16.409306, 19.54875],
                [-16.409027, 19.54875],
                [-16.409027, 19.549862000000132],
                [-16.408751, 19.549862000000132],
                [-16.408751, 19.55125],
                [-16.408472, 19.55125],
                [-16.408472, 19.552084],
                [-16.408195, 19.552084],
                [-16.408195, 19.552641000000165],
                [-16.407917, 19.552641000000165],
                [-16.407917, 19.552917],
                [-16.407364, 19.552917],
                [-16.407364, 19.55347300000011],
                [-16.407082, 19.55347300000011],
                [-16.407082, 19.554028000000187],
                [-16.406803, 19.554028000000187],
                [-16.406803, 19.554306000000111],
                [-16.406528, 19.554306000000111],
                [-16.406528, 19.554583],
                [-16.406252, 19.554583],
                [-16.406252, 19.555140000000165],
                [-16.405692999999872, 19.555140000000165],
                [-16.405692999999872, 19.555416],
                [-16.405416, 19.555416],
                [-16.405416, 19.555695],
                [-16.405138, 19.555695],
                [-16.405138, 19.555971],
                [-16.404863, 19.555971],
                [-16.404863, 19.55625],
                [-16.404581, 19.55625],
                [-16.404581, 19.556528],
                [-16.404028, 19.556528],
                [-16.404028, 19.556805000000111],
                [-16.403749, 19.556805000000111],
                [-16.403749, 19.557081],
                [-16.403473, 19.557081],
                [-16.403473, 19.557362000000182],
                [-16.403193999999871, 19.557362000000182],
                [-16.403193999999871, 19.558193000000188],
                [-16.402916, 19.558193000000188],
                [-16.402916, 19.55875],
                [-16.402639, 19.55875],
                [-16.402639, 19.559029],
                [-16.402082, 19.559029],
                [-16.402082, 19.559303],
                [-16.401526999999874, 19.559303],
                [-16.401526999999874, 19.559586],
                [-16.401251, 19.559586],
                [-16.401251, 19.55986],
                [-16.400972, 19.55986],
                [-16.400972, 19.560139000000106],
                [-16.400693999999874, 19.560139000000106],
                [-16.400693999999874, 19.560415],
                [-16.400139, 19.560415],
                [-16.400139, 19.560694000000183],
                [-16.399859999999876, 19.560694000000183],
                [-16.399859999999876, 19.560972000000106],
                [-16.399584, 19.560972000000106],
                [-16.399584, 19.561251],
                [-16.399305, 19.561251],
                [-16.399305, 19.561525000000188],
                [-16.399028999999871, 19.561525000000188],
                [-16.399028999999871, 19.562084],
                [-16.39875, 19.562084],
                [-16.39875, 19.562361],
                [-16.398472, 19.562361],
                [-16.398472, 19.562639000000161],
                [-16.398194999999873, 19.562639000000161],
                [-16.398194999999873, 19.563194],
                [-16.397638, 19.563194],
                [-16.397638, 19.563749],
                [-16.397361999999873, 19.563749],
                [-16.397361999999873, 19.564030000000173],
                [-16.397083, 19.564030000000173],
                [-16.397083, 19.564306000000101],
                [-16.396807, 19.564306000000101],
                [-16.396807, 19.564583],
                [-16.396527999999876, 19.564583],
                [-16.396527999999876, 19.565140000000156],
                [-16.39625, 19.565140000000156],
                [-16.39625, 19.565416],
                [-16.395971, 19.565416],
                [-16.395971, 19.565695],
                [-16.395694999999876, 19.565695],
                [-16.395694999999876, 19.56625],
                [-16.395416, 19.56625],
                [-16.395416, 19.566805000000102],
                [-16.39514, 19.566805000000102],
                [-16.39514, 19.567362000000173],
                [-16.394860999999878, 19.567362000000173],
                [-16.394860999999878, 19.567638000000102],
                [-16.394585, 19.567638000000102],
                [-16.394585, 19.568195000000173],
                [-16.394306, 19.568195000000173],
                [-16.394306, 19.568472000000156],
                [-16.394027999999878, 19.568472000000156],
                [-16.394027999999878, 19.569027],
                [-16.393749, 19.569027],
                [-16.393749, 19.569305000000156],
                [-16.393475, 19.569305000000156],
                [-16.393475, 19.56986],
                [-16.393191999999885, 19.56986],
                [-16.393191999999885, 19.570417000000134],
                [-16.392916, 19.570417000000134],
                [-16.392916, 19.570692000000179],
                [-16.392639, 19.570692000000179],
                [-16.392639, 19.571251],
                [-16.392362999999875, 19.571251],
                [-16.392362999999875, 19.571527000000174],
                [-16.392082, 19.571527000000174],
                [-16.392082, 19.572083000000134],
                [-16.391806, 19.572083000000134],
                [-16.391806, 19.572361],
                [-16.391526999999883, 19.572361],
                [-16.391526999999883, 19.572914],
                [-16.391253, 19.572914],
                [-16.391253, 19.573196],
                [-16.39097, 19.573196],
                [-16.39097, 19.57375],
                [-16.390693999999883, 19.57375],
                [-16.390693999999883, 19.574305000000152],
                [-16.390417, 19.574305000000152],
                [-16.390417, 19.575136000000157],
                [-16.390139, 19.575136000000157],
                [-16.390139, 19.575972],
                [-16.389859999999885, 19.575972],
                [-16.38985799999989, 19.576805],
                [-16.389584, 19.576805],
                [-16.389584, 19.577639000000147],
                [-16.389305, 19.577639000000147],
                [-16.389305, 19.578472000000147],
                [-16.38902899999988, 19.578472000000147],
                [-16.38902899999988, 19.580139],
                [-16.388748, 19.580139],
                [-16.388748, 19.581528],
                [-16.38902899999988, 19.581528],
                [-16.38902899999988, 19.582083000000125],
                [-16.388748, 19.582083000000125],
                [-16.388748, 19.584028000000103],
                [-16.388472, 19.584028000000103],
                [-16.388472, 19.584305000000143],
                [-16.387917, 19.584305000000143],
                [-16.387917, 19.584583],
                [-16.387638, 19.584583],
                [-16.387638, 19.585138000000143],
                [-16.387361999999882, 19.585138000000143],
                [-16.387361999999882, 19.585972],
                [-16.387638, 19.585972],
                [-16.387638, 19.586803],
                [-16.387917, 19.586803],
                [-16.387917, 19.589025],
                [-16.387638, 19.589025],
                [-16.387638, 19.589582000000121],
                [-16.387083, 19.589582000000121],
                [-16.387083, 19.589861],
                [-16.38652599999989, 19.589861],
                [-16.38652599999989, 19.590973],
                [-16.38625, 19.590973],
                [-16.38625, 19.591806],
                [-16.385973, 19.591806],
                [-16.385973, 19.592083000000116],
                [-16.385694999999885, 19.592083000000116],
                [-16.385694999999885, 19.592361],
                [-16.385418, 19.592361],
                [-16.385418, 19.592916000000116],
                [-16.385139, 19.592916000000116],
                [-16.385139, 19.594028],
                [-16.384860999999887, 19.594028],
                [-16.384860999999887, 19.594584000000111],
                [-16.386805, 19.594584000000111],
                [-16.386805, 19.595694],
                [-16.38652599999989, 19.595694],
                [-16.38652599999989, 19.59597],
                [-16.385973, 19.59597],
                [-16.385973, 19.596251000000166],
                [-16.385418, 19.596251000000166],
                [-16.385418, 19.596527],
                [-16.385139, 19.596527],
                [-16.385139, 19.596806],
                [-16.384860999999887, 19.596806],
                [-16.384860999999887, 19.597639],
                [-16.384583, 19.597639],
                [-16.384583, 19.598473000000183],
                [-16.384308, 19.598473000000183],
                [-16.384308, 19.598749000000112],
                [-16.38402699999989, 19.598749000000112],
                [-16.38402699999989, 19.599304000000188],
                [-16.383751, 19.599304000000188],
                [-16.383751, 19.599861],
                [-16.383472, 19.599861],
                [-16.383472, 19.600697000000139],
                [-16.383195999999884, 19.600697000000139],
                [-16.383195999999884, 19.601250000000107],
                [-16.382639, 19.601250000000107],
                [-16.382639, 19.601807000000179],
                [-16.382359999999835, 19.601807000000179],
                [-16.382359999999835, 19.602362],
                [-16.382086, 19.602362],
                [-16.382086, 19.602919000000156],
                [-16.381805, 19.602919000000156],
                [-16.381805, 19.603472],
                [-16.381526999999835, 19.603472],
                [-16.381526999999835, 19.603750000000161],
                [-16.38125, 19.603750000000161],
                [-16.38125, 19.604584],
                [-16.380974, 19.604584],
                [-16.380974, 19.605417],
                [-16.380138, 19.605417],
                [-16.380138, 19.605694],
                [-16.379305, 19.605694],
                [-16.379305, 19.605972000000179],
                [-16.379027999999835, 19.605972000000179],
                [-16.379027999999835, 19.606251000000157],
                [-16.378752, 19.606251000000157],
                [-16.378752, 19.606806],
                [-16.378194999999835, 19.606806],
                [-16.378194999999835, 19.607082000000162],
                [-16.377083, 19.607084000000157],
                [-16.377083, 19.607361000000139],
                [-16.376806, 19.607361000000139],
                [-16.376806, 19.607639],
                [-16.376527999999837, 19.607639],
                [-16.376527999999837, 19.607916],
                [-16.376249, 19.607916],
                [-16.376249, 19.60819400000014],
                [-16.375973, 19.60819400000014],
                [-16.375973, 19.608473000000174],
                [-16.375694, 19.608473000000174],
                [-16.375694, 19.608749],
                [-16.375416, 19.608749],
                [-16.375416, 19.609028],
                [-16.375139, 19.609028],
                [-16.375139, 19.609306000000174],
                [-16.374861, 19.609306000000174],
                [-16.374861, 19.609583000000157],
                [-16.374584, 19.609583000000157],
                [-16.374584, 19.609861],
                [-16.374306, 19.609861],
                [-16.374306, 19.610138],
                [-16.374027, 19.610138],
                [-16.374027, 19.610416000000157],
                [-16.373751, 19.610416000000157],
                [-16.373751, 19.610695000000135],
                [-16.373472, 19.610695000000135],
                [-16.373472, 19.610971],
                [-16.373194, 19.610971],
                [-16.373194, 19.61125],
                [-16.372917, 19.61125],
                [-16.372917, 19.611528000000135],
                [-16.372639, 19.611528000000135],
                [-16.372639, 19.61180300000018],
                [-16.37236, 19.61180300000018],
                [-16.37236, 19.612083],
                [-16.372084, 19.612083],
                [-16.372084, 19.612364],
                [-16.371805, 19.612364],
                [-16.371805, 19.612638000000175],
                [-16.371529, 19.612638000000175],
                [-16.371529, 19.612917000000152],
                [-16.37125, 19.612917000000152],
                [-16.37125, 19.613194000000135],
                [-16.370972, 19.613194000000135],
                [-16.370972, 19.613472],
                [-16.370695, 19.613472],
                [-16.370695, 19.613750000000152],
                [-16.370417, 19.613750000000152],
                [-16.370417, 19.61402500000014],
                [-16.370138, 19.61402500000014],
                [-16.370138, 19.614307],
                [-16.369862, 19.614307],
                [-16.369862, 19.614586],
                [-16.369583, 19.614586],
                [-16.369583, 19.614861],
                [-16.369028, 19.614861],
                [-16.369028, 19.615417],
                [-16.369307, 19.615417],
                [-16.369307, 19.617916],
                [-16.369028, 19.617916],
                [-16.369028, 19.618750000000148],
                [-16.36875, 19.618750000000148],
                [-16.36875, 19.619305],
                [-16.368473, 19.619305],
                [-16.368473, 19.619862000000126],
                [-16.368195, 19.619862000000126],
                [-16.368195, 19.620138],
                [-16.36764, 19.620138],
                [-16.36764, 19.620417],
                [-16.367361, 19.620417],
                [-16.367361, 19.620695000000126],
                [-16.367085, 19.620695000000126],
                [-16.367085, 19.620972000000108],
                [-16.366806, 19.620972000000108],
                [-16.366806, 19.62125],
                [-16.366528, 19.62125],
                [-16.366528, 19.621529],
                [-16.365971, 19.621529],
                [-16.365971, 19.621805000000109],
                [-16.365692, 19.621822000000122],
                [-16.365692, 19.622084000000143],
                [-16.365141, 19.622084000000143],
                [-16.365141, 19.62236],
                [-16.364581999999871, 19.62236],
                [-16.364581999999871, 19.622639],
                [-16.364305, 19.622639],
                [-16.364305, 19.622917000000143],
                [-16.364027, 19.622917000000143],
                [-16.364027, 19.623194000000126],
                [-16.36347, 19.623194000000126],
                [-16.36347, 19.623472],
                [-16.362916999999868, 19.623472],
                [-16.362916999999868, 19.623751],
                [-16.362638, 19.623751],
                [-16.362638, 19.624027000000126],
                [-16.36208299999987, 19.624027000000126],
                [-16.36208299999987, 19.624306000000104],
                [-16.361805, 19.624306000000104],
                [-16.361805, 19.624582],
                [-16.36125, 19.624582],
                [-16.36125, 19.624861],
                [-16.360971, 19.624861],
                [-16.360971, 19.625139000000104],
                [-16.360415999999873, 19.625139000000104],
                [-16.360415999999873, 19.625416000000143],
                [-16.359861, 19.625416000000143],
                [-16.359861, 19.625692],
                [-16.359582999999873, 19.625692],
                [-16.359582999999873, 19.625975],
                [-16.359028, 19.625975],
                [-16.359028, 19.626249000000143],
                [-16.358748999999875, 19.626249000000143],
                [-16.358748999999875, 19.626528000000121],
                [-16.358473, 19.626528000000121],
                [-16.358473, 19.626804],
                [-16.35791799999987, 19.626804],
                [-16.35791799999987, 19.627083],
                [-16.357639, 19.627083],
                [-16.357639, 19.627361000000121],
                [-16.357083999999873, 19.627361000000121],
                [-16.357081999999878, 19.62764],
                [-16.356806, 19.62764],
                [-16.356806, 19.627914],
                [-16.356527, 19.627914],
                [-16.356527, 19.628197000000171],
                [-16.356250999999872, 19.628197000000171],
                [-16.356250999999872, 19.628471000000104],
                [-16.355972, 19.628471000000104],
                [-16.355972, 19.62875],
                [-16.355696, 19.62875],
                [-16.355696, 19.629028000000176],
                [-16.355139, 19.629028000000176],
                [-16.355139, 19.629583],
                [-16.35486, 19.629583],
                [-16.35486, 19.629862000000116],
                [-16.35458599999987, 19.629862000000116],
                [-16.35458599999987, 19.630695000000117],
                [-16.354304999999897, 19.630695000000117],
                [-16.354304999999897, 19.63125],
                [-16.354029, 19.63125],
                [-16.354029, 19.632084],
                [-16.354304999999897, 19.632084],
                [-16.354304999999897, 19.632641],
                [-16.354029, 19.632641],
                [-16.354029, 19.633194000000117],
                [-16.353474, 19.633194000000117],
                [-16.353474, 19.633751000000188],
                [-16.353195, 19.633751000000188],
                [-16.353195, 19.634306],
                [-16.352916999999877, 19.634306],
                [-16.352916999999877, 19.634863000000166],
                [-16.352637999999899, 19.634863000000166],
                [-16.352637999999899, 19.635695000000112],
                [-16.352364, 19.635695000000112],
                [-16.352364, 19.636250000000189],
                [-16.352080999999885, 19.636250000000189],
                [-16.352080999999885, 19.636805],
                [-16.351804999999899, 19.636806],
                [-16.351804999999899, 19.637081],
                [-16.351528, 19.637081],
                [-16.351528, 19.641525000000172],
                [-16.351804999999899, 19.641525000000172],
                [-16.351804999999899, 19.64180800000014],
                [-16.352080999999885, 19.64180800000014],
                [-16.352080999999885, 19.643473],
                [-16.352364, 19.643473],
                [-16.352364, 19.643747000000189],
                [-16.352080999999885, 19.643747000000189],
                [-16.352080999999885, 19.644030000000157],
                [-16.351804999999899, 19.644030000000157],
                [-16.351804999999899, 19.644306],
                [-16.351528, 19.644306],
                [-16.351528, 19.644583],
                [-16.351249999999879, 19.644583],
                [-16.351249999999879, 19.64514000000014],
                [-16.350971, 19.64514000000014],
                [-16.350971, 19.645695],
                [-16.350695, 19.645695],
                [-16.350695, 19.64625000000018],
                [-16.350415999999882, 19.64625000000018],
                [-16.350415999999882, 19.646528],
                [-16.350142, 19.646528],
                [-16.350142, 19.646805],
                [-16.349859, 19.646805],
                [-16.349859, 19.64708300000018],
                [-16.349582999999882, 19.64708300000018],
                [-16.349582999999882, 19.647638],
                [-16.349305999999899, 19.647638],
                [-16.349305999999899, 19.64875],
                [-16.349582999999882, 19.64875],
                [-16.349582999999882, 19.649027],
                [-16.350415999999882, 19.649027],
                [-16.350415999999882, 19.64847200000014],
                [-16.350971, 19.64847200000014],
                [-16.350971, 19.647917],
                [-16.351249999999879, 19.647917],
                [-16.351249999999879, 19.647362000000157],
                [-16.351528, 19.647362000000157],
                [-16.351528, 19.646805],
                [-16.351804999999899, 19.646805],
                [-16.351804999999899, 19.647362000000157],
                [-16.352080999999885, 19.647362000000157],
                [-16.352080999999885, 19.648195000000158],
                [-16.352637999999899, 19.648195000000158],
                [-16.352637999999899, 19.647917],
                [-16.352916999999877, 19.647917],
                [-16.352916999999877, 19.647362000000157],
                [-16.353195, 19.647362000000157],
                [-16.353195, 19.64708300000018],
                [-16.353474, 19.64708300000018],
                [-16.353474, 19.646528],
                [-16.353749999999877, 19.646528],
                [-16.353749999999877, 19.645695],
                [-16.353474, 19.645695],
                [-16.353474, 19.644861000000162],
                [-16.353749999999877, 19.644861000000162],
                [-16.353749999999877, 19.644583],
                [-16.354029, 19.644583],
                [-16.354029, 19.644306],
                [-16.354304999999897, 19.644306],
                [-16.354304999999897, 19.643747000000189],
                [-16.35458599999987, 19.643747000000189],
                [-16.35458599999987, 19.643473],
                [-16.35486, 19.643473],
                [-16.35486, 19.642918000000179],
                [-16.355139, 19.642918000000179],
                [-16.355139, 19.642361],
                [-16.355416999999875, 19.642361],
                [-16.355416999999875, 19.641525000000172],
                [-16.355696, 19.641525000000172],
                [-16.355696, 19.640415000000189],
                [-16.355972, 19.640415000000189],
                [-16.355972, 19.638193000000172],
                [-16.355696, 19.638193000000172],
                [-16.355696, 19.637638],
                [-16.355972, 19.637638],
                [-16.355972, 19.635416],
                [-16.356250999999872, 19.635416],
                [-16.356250999999872, 19.634863000000166],
                [-16.356527, 19.634863000000166],
                [-16.356527, 19.634306],
                [-16.356806, 19.634306],
                [-16.356806, 19.633751000000188],
                [-16.357083999999873, 19.633751000000188],
                [-16.357083999999873, 19.633472],
                [-16.357361, 19.633472],
                [-16.357361, 19.633194000000117],
                [-16.357639, 19.633194000000117],
                [-16.357639, 19.632641],
                [-16.35791799999987, 19.632641],
                [-16.35791799999987, 19.632360000000176],
                [-16.358194, 19.632360000000176],
                [-16.358194, 19.632084],
                [-16.359028, 19.632084],
                [-16.359028, 19.631805],
                [-16.359582999999873, 19.631805],
                [-16.359582999999873, 19.631529000000171],
                [-16.360415999999873, 19.631529000000171],
                [-16.360415999999873, 19.63125],
                [-16.36125, 19.63125],
                [-16.36125, 19.630972],
                [-16.361528, 19.630972],
                [-16.361528, 19.630695000000117],
                [-16.361805, 19.630695000000117],
                [-16.361805, 19.630419000000188],
                [-16.36208299999987, 19.630419000000188],
                [-16.36208299999987, 19.630136],
                [-16.362362, 19.630136],
                [-16.362362, 19.629583],
                [-16.362638, 19.629583],
                [-16.362638, 19.629307],
                [-16.362916999999868, 19.629307],
                [-16.362916999999868, 19.627914],
                [-16.363194, 19.627914],
                [-16.363195, 19.627361000000121],
                [-16.36347, 19.627361000000121],
                [-16.36347, 19.626804],
                [-16.364027, 19.626804],
                [-16.364027, 19.626528000000121],
                [-16.365416999999866, 19.626528000000121],
                [-16.365416999999866, 19.626249000000143],
                [-16.365971, 19.626249000000143],
                [-16.365971, 19.625975],
                [-16.366528, 19.625975],
                [-16.366528, 19.625692],
                [-16.366806, 19.625692],
                [-16.366806, 19.625416000000143],
                [-16.367361, 19.625416000000143],
                [-16.367361, 19.625139000000104],
                [-16.36764, 19.625139000000104],
                [-16.36764, 19.624861],
                [-16.367915999999866, 19.624861],
                [-16.367915999999866, 19.624582],
                [-16.368473, 19.624582],
                [-16.368473, 19.624306000000104],
                [-16.369028, 19.624306000000104],
                [-16.369028, 19.624027000000126],
                [-16.369583, 19.624027000000126],
                [-16.369583, 19.623751],
                [-16.369862, 19.623751],
                [-16.369862, 19.623472],
                [-16.370417, 19.623472],
                [-16.370417, 19.623194000000126],
                [-16.370695, 19.623194000000126],
                [-16.370695, 19.622917000000143],
                [-16.370972, 19.622917000000143],
                [-16.370972, 19.622639],
                [-16.371529, 19.622639],
                [-16.371529, 19.62236],
                [-16.371805, 19.62236],
                [-16.371805, 19.622084000000143],
                [-16.37236, 19.622084000000143],
                [-16.37236, 19.621822000000122],
                [-16.372639, 19.621805000000109],
                [-16.372639, 19.621529],
                [-16.373194, 19.621529],
                [-16.373194, 19.62125],
                [-16.373751, 19.62125],
                [-16.373751, 19.620972000000108],
                [-16.374027, 19.620972000000108],
                [-16.374027, 19.620695000000126],
                [-16.374584, 19.620695000000126],
                [-16.374584, 19.620138],
                [-16.375416, 19.620138],
                [-16.375416, 19.619862000000126],
                [-16.375973, 19.619862000000126],
                [-16.375973, 19.619583000000148],
                [-16.376249, 19.619583000000148],
                [-16.376249, 19.619305],
                [-16.376806, 19.619305],
                [-16.376806, 19.618750000000148],
                [-16.377083, 19.618750000000148],
                [-16.377083, 19.618473000000108],
                [-16.377359, 19.618473000000108],
                [-16.377359, 19.618195],
                [-16.377642, 19.618195],
                [-16.377642, 19.617640000000108],
                [-16.377916, 19.617640000000108],
                [-16.377916, 19.61736100000013],
                [-16.378194999999835, 19.61736100000013],
                [-16.378194999999835, 19.616808],
                [-16.378471, 19.616808],
                [-16.378471, 19.616526000000135],
                [-16.378752, 19.616526000000135],
                [-16.378752, 19.615973],
                [-16.379027999999835, 19.615973],
                [-16.379027999999835, 19.615696],
                [-16.379305, 19.615696],
                [-16.379305, 19.615417],
                [-16.379863999999884, 19.615417],
                [-16.379863999999884, 19.615137000000175],
                [-16.380417, 19.615137000000175],
                [-16.380417, 19.614861],
                [-16.380694999999889, 19.614861],
                [-16.380694999999889, 19.614586],
                [-16.381526999999835, 19.614586],
                [-16.381526999999835, 19.614307],
                [-16.382359999999835, 19.614307],
                [-16.382359999999835, 19.61402500000014],
                [-16.384308, 19.61402500000014],
                [-16.384308, 19.613750000000152],
                [-16.384583, 19.613750000000152],
                [-16.384583, 19.613194000000135],
                [-16.384860999999887, 19.613194000000135],
                [-16.384860999999887, 19.612917000000152],
                [-16.385139, 19.612917000000152],
                [-16.385139, 19.612638000000175],
                [-16.385418, 19.612638000000175],
                [-16.385418, 19.612917000000152],
                [-16.385694999999885, 19.612917000000152],
                [-16.385694999999885, 19.613194000000135],
                [-16.385854999999822, 19.613194000000135],
                [-16.385973, 19.613194000000135],
                [-16.385973, 19.613472],
                [-16.38625, 19.613472],
                [-16.38625, 19.613750000000152],
                [-16.386805, 19.613750000000152],
                [-16.386806, 19.61402500000014],
                [-16.387361999999882, 19.61402500000014],
                [-16.387361999999882, 19.614307],
                [-16.387638, 19.614307],
                [-16.387638, 19.614586],
                [-16.387917, 19.614586],
                [-16.387917, 19.615696],
                [-16.387638, 19.615696],
                [-16.387638, 19.615973],
                [-16.387361999999882, 19.615973],
                [-16.387361999999882, 19.615417],
                [-16.38652599999989, 19.615417],
                [-16.38652599999989, 19.615973],
                [-16.385694999999885, 19.615973],
                [-16.385694999999885, 19.616247000000158],
                [-16.385418, 19.616247000000158],
                [-16.385418, 19.616526000000135],
                [-16.385139, 19.616526000000135],
                [-16.385139, 19.616808],
                [-16.384860999999887, 19.616808],
                [-16.384860999999887, 19.617083],
                [-16.384583, 19.617083],
                [-16.384583, 19.61736100000013],
                [-16.384308, 19.61736100000013],
                [-16.384308, 19.617916],
                [-16.38402699999989, 19.617916],
                [-16.38402699999989, 19.618473000000108],
                [-16.383751, 19.618473000000108],
                [-16.383751, 19.618750000000148],
                [-16.383472, 19.618750000000148],
                [-16.383472, 19.619028],
                [-16.382817999999872, 19.619028],
                [-16.382639, 19.619028],
                [-16.382639, 19.619244000000151],
                [-16.382639, 19.619583000000148],
                [-16.382358999999838, 19.619581000000153],
                [-16.382359999999835, 19.620138],
                [-16.383195999999884, 19.620138],
                [-16.383195999999884, 19.620417],
                [-16.383751, 19.620417],
                [-16.383751, 19.620695000000126],
                [-16.38402699999989, 19.620695000000126],
                [-16.38402699999989, 19.620972000000108],
                [-16.384308, 19.620972000000108],
                [-16.384308, 19.62125],
                [-16.384860999999887, 19.62125],
                [-16.384860999999887, 19.620972000000108],
                [-16.385139, 19.620972000000108],
                [-16.385139, 19.620417],
                [-16.385418, 19.620417],
                [-16.385418, 19.620138],
                [-16.385694999999885, 19.620138],
                [-16.385694999999885, 19.619862000000126],
                [-16.385973, 19.619862000000126],
                [-16.385973, 19.619583000000148],
                [-16.38652599999989, 19.619583000000148],
                [-16.38652599999989, 19.619305],
                [-16.386806, 19.619305],
                [-16.386806, 19.619028],
                [-16.387083, 19.619028],
                [-16.387083, 19.618750000000148],
                [-16.387361999999882, 19.618750000000148],
                [-16.387361999999882, 19.618473000000108],
                [-16.387638, 19.618473000000108],
                [-16.387638, 19.618195],
                [-16.387917, 19.618195],
                [-16.387917, 19.617640000000108],
                [-16.388194999999882, 19.617640000000108],
                [-16.388194999999882, 19.61736100000013],
                [-16.388472, 19.61736100000013],
                [-16.388472, 19.616808],
                [-16.388748, 19.616808],
                [-16.388748, 19.617083],
                [-16.38902899999988, 19.617083],
                [-16.38902899999988, 19.61736100000013],
                [-16.390139, 19.61736100000013],
                [-16.390139, 19.617083],
                [-16.39097, 19.617083],
                [-16.39097, 19.616808],
                [-16.391526999999883, 19.616808],
                [-16.391526999999883, 19.616526000000135],
                [-16.392082, 19.616526000000135],
                [-16.392082, 19.616247000000158],
                [-16.392639, 19.616247000000158],
                [-16.392639, 19.615973],
                [-16.392916, 19.615973],
                [-16.392918, 19.615696],
                [-16.393191999999885, 19.615696],
                [-16.393191999999885, 19.615417],
                [-16.393475, 19.615417],
                [-16.393475, 19.615137000000175],
                [-16.393749, 19.615137000000175],
                [-16.393749, 19.614861],
                [-16.394027999999878, 19.614861],
                [-16.394027999999878, 19.614307],
                [-16.394306, 19.614307],
                [-16.394306, 19.61402500000014],
                [-16.394585, 19.61402500000014],
                [-16.394585, 19.613194000000135],
                [-16.394860999999878, 19.613194000000135],
                [-16.394860999999878, 19.612638000000175],
                [-16.39514, 19.612638000000175],
                [-16.39514, 19.61180300000018],
                [-16.395416, 19.61180300000018],
                [-16.395416, 19.61125],
                [-16.395694999999876, 19.61125],
                [-16.395694999999876, 19.607916],
                [-16.395971, 19.607916],
                [-16.395971, 19.607361000000139],
                [-16.39625, 19.607361000000139],
                [-16.39625, 19.606251000000157],
                [-16.396527999999876, 19.606251000000157],
                [-16.396527999999876, 19.604584],
                [-16.396807, 19.604584],
                [-16.396807, 19.604134000000158],
                [-16.396807, 19.603750000000161],
                [-16.397083, 19.603750000000161],
                [-16.397083, 19.602362],
                [-16.397361999999873, 19.602362],
                [-16.397361999999873, 19.601526],
                [-16.397638, 19.601526],
                [-16.397638, 19.600697000000139],
                [-16.397917, 19.600697000000139],
                [-16.397917, 19.599583000000166],
                [-16.398194999999873, 19.599583000000166],
                [-16.398194999999873, 19.598192],
                [-16.398472, 19.598192],
                [-16.398472, 19.596527],
                [-16.39875, 19.596527],
                [-16.39875, 19.595694],
                [-16.399028999999871, 19.595694],
                [-16.399028999999871, 19.595417000000111],
                [-16.399305, 19.595417000000111],
                [-16.399305, 19.594305],
                [-16.399584, 19.594305],
                [-16.399584, 19.593195],
                [-16.399305, 19.593195],
                [-16.399305, 19.592361],
                [-16.399028999999871, 19.592361],
                [-16.399028999999871, 19.591806],
                [-16.399305, 19.591806],
                [-16.399305, 19.591249],
                [-16.400139, 19.591249],
                [-16.400139, 19.590973],
                [-16.400417, 19.590973],
                [-16.400417, 19.590694],
                [-16.401251, 19.590694],
                [-16.401251, 19.59041800000017],
                [-16.402360999999871, 19.59041800000017],
                [-16.402360999999871, 19.590139],
                [-16.402639, 19.590139],
                [-16.402639, 19.59041800000017],
                [-16.403473, 19.59041800000017],
                [-16.403473, 19.590694],
                [-16.404028, 19.590694],
                [-16.404028, 19.590973],
                [-16.404581, 19.590973],
                [-16.404581, 19.591249],
                [-16.404863, 19.591249],
                [-16.404863, 19.59153],
                [-16.405416, 19.59153],
                [-16.405416, 19.591806],
                [-16.405692999999872, 19.591806],
                [-16.405692999999872, 19.592640000000188],
                [-16.405973, 19.592640000000188],
                [-16.405973, 19.592916000000116],
                [-16.405692999999872, 19.592916000000116],
                [-16.405692999999872, 19.593472],
                [-16.405416, 19.593472],
                [-16.405416, 19.594305],
                [-16.405138, 19.594305],
                [-16.405138, 19.594584000000111],
                [-16.404863, 19.594584000000111],
                [-16.404863, 19.595694],
                [-16.404581, 19.595694],
                [-16.404581, 19.596251000000166],
                [-16.404306, 19.596251000000166],
                [-16.404306, 19.597082000000171],
                [-16.404028, 19.597082000000171],
                [-16.404028, 19.597918000000107],
                [-16.403749, 19.597918000000107],
                [-16.403749, 19.598749000000112],
                [-16.403473, 19.598749000000112],
                [-16.403473, 19.599304000000188],
                [-16.403193999999871, 19.599304000000188],
                [-16.403193999999871, 19.599861],
                [-16.402916, 19.599861],
                [-16.402916, 19.60014],
                [-16.402639, 19.60014],
                [-16.402639, 19.600414000000171],
                [-16.402360999999871, 19.600414000000171],
                [-16.402360999999871, 19.600973],
                [-16.402082, 19.600973],
                [-16.402082, 19.601250000000107],
                [-16.401806, 19.601250000000107],
                [-16.401806, 19.601807000000179],
                [-16.401526999999874, 19.601807000000179],
                [-16.401526999999874, 19.602362],
                [-16.401251, 19.602362],
                [-16.401251, 19.602636000000189],
                [-16.400972, 19.602636000000189],
                [-16.400972, 19.603195],
                [-16.400693999999874, 19.603195],
                [-16.400693999999874, 19.603750000000161],
                [-16.400417, 19.603750000000161],
                [-16.400417, 19.60486],
                [-16.400972, 19.60486],
                [-16.400972, 19.604584],
                [-16.401251, 19.604584],
                [-16.401251, 19.604029000000139],
                [-16.401806, 19.604029000000139],
                [-16.401806, 19.603750000000161],
                [-16.402082, 19.603750000000161],
                [-16.402082, 19.605417],
                [-16.401806, 19.605417],
                [-16.401806, 19.606251000000157],
                [-16.401526999999874, 19.606251000000157],
                [-16.401526999999874, 19.607639],
                [-16.401251, 19.607639],
                [-16.401251, 19.608473000000174],
                [-16.400139, 19.608473000000174],
                [-16.400139, 19.608749],
                [-16.399859999999876, 19.608749],
                [-16.399859999999876, 19.609028],
                [-16.399584, 19.609028],
                [-16.399584, 19.609583000000157],
                [-16.399305, 19.609583000000157],
                [-16.399305, 19.610138],
                [-16.399028999999871, 19.610138],
                [-16.399028999999871, 19.610971],
                [-16.39875, 19.610971],
                [-16.39875, 19.612364],
                [-16.399028999999871, 19.612364],
                [-16.399028999999871, 19.613194000000135],
                [-16.39875, 19.613194000000135],
                [-16.39875, 19.613472],
                [-16.398472, 19.613472],
                [-16.398472, 19.613750000000152],
                [-16.398194999999873, 19.613750000000152],
                [-16.398194999999873, 19.614307],
                [-16.397917, 19.614307],
                [-16.397917, 19.614586],
                [-16.397638, 19.614586],
                [-16.397638, 19.614861],
                [-16.397361999999873, 19.614861],
                [-16.397361999999873, 19.615137000000175],
                [-16.397083, 19.615137000000175],
                [-16.397083, 19.615417],
                [-16.396807, 19.615417],
                [-16.396807, 19.615973],
                [-16.396527999999876, 19.615973],
                [-16.396527999999876, 19.616247000000158],
                [-16.39625, 19.616247000000158],
                [-16.39625, 19.616526000000135],
                [-16.395971, 19.616526000000135],
                [-16.395971, 19.616808],
                [-16.395694999999876, 19.616808],
                [-16.395694999999876, 19.617083],
                [-16.395416, 19.617083],
                [-16.395416, 19.617640000000108],
                [-16.39514, 19.617640000000108],
                [-16.39514, 19.617916],
                [-16.394860999999878, 19.617916],
                [-16.394860999999878, 19.618195],
                [-16.394585, 19.618195],
                [-16.394585, 19.618750000000148],
                [-16.394306, 19.618750000000148],
                [-16.394306, 19.619028],
                [-16.394027999999878, 19.619028],
                [-16.394027999999878, 19.619305],
                [-16.393749, 19.619305],
                [-16.393749, 19.619583000000148],
                [-16.393475, 19.619583000000148],
                [-16.393475, 19.620138],
                [-16.393191999999885, 19.620138],
                [-16.393191999999885, 19.62125],
                [-16.393475, 19.62125],
                [-16.393475, 19.621529],
                [-16.393749, 19.621529],
                [-16.393749, 19.621822000000122],
                [-16.393749, 19.622084000000143],
                [-16.393475, 19.622084000000143],
                [-16.393475, 19.62236],
                [-16.392916, 19.62236],
                [-16.392916, 19.622639],
                [-16.392639, 19.622639],
                [-16.392639, 19.622917000000143],
                [-16.392362999999875, 19.622917000000143],
                [-16.392362999999875, 19.623194000000126],
                [-16.392082, 19.623194000000126],
                [-16.392082, 19.623751],
                [-16.391806, 19.623751],
                [-16.391806, 19.624306000000104],
                [-16.391526999999883, 19.624306000000104],
                [-16.391526999999883, 19.624861],
                [-16.391253, 19.624861],
                [-16.391253, 19.625692],
                [-16.39097, 19.625692],
                [-16.39097, 19.626528000000121],
                [-16.390693999999883, 19.626528000000121],
                [-16.390693999999883, 19.627914],
                [-16.390417, 19.627914],
                [-16.390417, 19.628471000000104],
                [-16.390139, 19.628471000000104],
                [-16.390139, 19.629028000000176],
                [-16.389859999999885, 19.629028000000176],
                [-16.38985799999989, 19.629583],
                [-16.389584, 19.629583],
                [-16.389584, 19.630136],
                [-16.389305, 19.630136],
                [-16.389305, 19.630695000000117],
                [-16.38902899999988, 19.630695000000117],
                [-16.38902899999988, 19.631529000000171],
                [-16.388748, 19.631529000000171],
                [-16.388748, 19.631805],
                [-16.388472, 19.631805],
                [-16.388472, 19.632360000000176],
                [-16.388194999999882, 19.632360000000176],
                [-16.388194999999882, 19.632641],
                [-16.387917, 19.632641],
                [-16.387917, 19.632917],
                [-16.387638, 19.632917],
                [-16.387638, 19.633194000000117],
                [-16.387361999999882, 19.633194000000117],
                [-16.387361999999882, 19.633472],
                [-16.387083, 19.633472],
                [-16.387083, 19.633751000000188],
                [-16.386806, 19.633751000000188],
                [-16.386805, 19.634027000000117],
                [-16.38652599999989, 19.634027000000117],
                [-16.38652599999989, 19.634583],
                [-16.38625, 19.634583],
                [-16.38625, 19.634863000000166],
                [-16.385973, 19.634863000000166],
                [-16.385973, 19.635139],
                [-16.385694999999885, 19.635139],
                [-16.385694999999885, 19.635416],
                [-16.385418, 19.635416],
                [-16.385418, 19.635695000000112],
                [-16.385139, 19.635695000000112],
                [-16.385139, 19.636250000000189],
                [-16.384860999999887, 19.636250000000189],
                [-16.384860999999887, 19.636528000000112],
                [-16.384308, 19.636528000000112],
                [-16.384308, 19.636805],
                [-16.38402699999989, 19.636806],
                [-16.38402699999989, 19.637081],
                [-16.383751, 19.637081],
                [-16.383751, 19.637362000000167],
                [-16.383472, 19.637362000000167],
                [-16.383472, 19.637638],
                [-16.383195999999884, 19.637638],
                [-16.383195999999884, 19.637917],
                [-16.382639, 19.637917],
                [-16.382639, 19.638472],
                [-16.382359999999835, 19.638472],
                [-16.382359999999835, 19.63875],
                [-16.382086, 19.63875],
                [-16.382086, 19.639303],
                [-16.381805, 19.639303],
                [-16.381805, 19.639860000000112],
                [-16.381526999999835, 19.639862],
                [-16.381526999999835, 19.640139],
                [-16.38125, 19.640139],
                [-16.38125, 19.640415000000189],
                [-16.380974, 19.640415000000189],
                [-16.380974, 19.640694000000167],
                [-16.380694999999889, 19.640694000000167],
                [-16.380694999999889, 19.640972],
                [-16.380417, 19.640972],
                [-16.380417, 19.641251],
                [-16.380138, 19.641251],
                [-16.380138, 19.641525000000172],
                [-16.379863999999884, 19.641525000000172],
                [-16.379863999999884, 19.64180800000014],
                [-16.379581, 19.64180800000014],
                [-16.379581, 19.642084],
                [-16.379027999999835, 19.642084],
                [-16.379027999999835, 19.642918000000179],
                [-16.378752, 19.642918000000179],
                [-16.378752, 19.643473],
                [-16.378471, 19.643473],
                [-16.378471, 19.643747000000189],
                [-16.378194999999835, 19.643747000000189],
                [-16.378194999999835, 19.644030000000157],
                [-16.377916, 19.644030000000157],
                [-16.377916, 19.644583],
                [-16.377642, 19.644583],
                [-16.377642, 19.645416],
                [-16.377359, 19.645416],
                [-16.377359, 19.645695],
                [-16.377083, 19.645695],
                [-16.377083, 19.64625000000018],
                [-16.376806, 19.64625000000018],
                [-16.376806, 19.646805],
                [-16.376527999999837, 19.646805],
                [-16.376527999999837, 19.647638],
                [-16.376249, 19.647638],
                [-16.376249, 19.64875],
                [-16.375973, 19.64875],
                [-16.375973, 19.650139],
                [-16.375694, 19.650139],
                [-16.375694, 19.651251],
                [-16.375416, 19.651251],
                [-16.375416, 19.651806000000136],
                [-16.375139, 19.651806000000136],
                [-16.375139, 19.652639000000136],
                [-16.374861, 19.652639000000136],
                [-16.374861, 19.653194],
                [-16.374584, 19.653194],
                [-16.374584, 19.654028000000153],
                [-16.374306, 19.654028000000153],
                [-16.374306, 19.654583],
                [-16.374027, 19.654583],
                [-16.374027, 19.655136000000141],
                [-16.373751, 19.655136000000141],
                [-16.373751, 19.655972],
                [-16.373472, 19.655972],
                [-16.373472, 19.656528],
                [-16.373194, 19.656528],
                [-16.373194, 19.657084],
                [-16.372917, 19.657084],
                [-16.372917, 19.657639000000131],
                [-16.372639, 19.657639000000131],
                [-16.372639, 19.657919],
                [-16.372084, 19.657919],
                [-16.372084, 19.658472000000131],
                [-16.371805, 19.658472000000131],
                [-16.371805, 19.658751000000109],
                [-16.371529, 19.658751000000109],
                [-16.371529, 19.659306],
                [-16.37125, 19.659306],
                [-16.37125, 19.659861000000149],
                [-16.370695, 19.659861000000149],
                [-16.370695, 19.660418],
                [-16.370417, 19.660418],
                [-16.370417, 19.660694000000149],
                [-16.370138, 19.660694000000149],
                [-16.370138, 19.660973000000126],
                [-16.369862, 19.660973000000126],
                [-16.369862, 19.661528],
                [-16.369583, 19.661528],
                [-16.369583, 19.661806000000126],
                [-16.369307, 19.661806000000126],
                [-16.369307, 19.662083000000109],
                [-16.369028, 19.662083000000109],
                [-16.369028, 19.662640000000181],
                [-16.36875, 19.662640000000181],
                [-16.36875, 19.663195],
                [-16.368473, 19.663195],
                [-16.368473, 19.663471],
                [-16.368195, 19.663471],
                [-16.368195, 19.66375],
                [-16.367915999999866, 19.66375],
                [-16.367915999999866, 19.664028],
                [-16.36764, 19.664028],
                [-16.36764, 19.664305000000127],
                [-16.367361, 19.664305000000127],
                [-16.367361, 19.664862],
                [-16.367085, 19.664862],
                [-16.367085, 19.665138000000127],
                [-16.366806, 19.665138000000127],
                [-16.366806, 19.665693],
                [-16.366248999999868, 19.665693],
                [-16.366248999999868, 19.665972000000181],
                [-16.365971, 19.665972000000181],
                [-16.365971, 19.666529],
                [-16.365692, 19.666529],
                [-16.365692, 19.667086],
                [-16.365416999999866, 19.667086],
                [-16.365416999999866, 19.667639000000122],
                [-16.365141, 19.667639000000122],
                [-16.365141, 19.667915],
                [-16.364861, 19.667915],
                [-16.364861, 19.668472000000122],
                [-16.364581999999871, 19.668472000000122],
                [-16.364581999999871, 19.669308000000171],
                [-16.364305, 19.669308000000171],
                [-16.364305, 19.669861],
                [-16.364027, 19.669861],
                [-16.364027, 19.670139000000177],
                [-16.363752, 19.670139000000177],
                [-16.363752, 19.670694],
                [-16.36347, 19.670694],
                [-16.36347, 19.670973000000117],
                [-16.363194, 19.670973000000117],
                [-16.363194, 19.671249],
                [-16.362916999999868, 19.671249],
                [-16.362916999999868, 19.671806000000117],
                [-16.362638, 19.671806000000117],
                [-16.362638, 19.6720830000001],
                [-16.362362, 19.6720830000001],
                [-16.362362, 19.672640000000172],
                [-16.36208299999987, 19.672640000000172],
                [-16.36208299999987, 19.673195],
                [-16.361805, 19.673195],
                [-16.361805, 19.673471000000177],
                [-16.361528, 19.673471000000177],
                [-16.361528, 19.674028],
                [-16.36125, 19.674028],
                [-16.36125, 19.674305000000118],
                [-16.360971, 19.674305000000118],
                [-16.360971, 19.675138000000118],
                [-16.360695, 19.675138000000118],
                [-16.360695, 19.675694],
                [-16.360415999999873, 19.675694],
                [-16.360415999999873, 19.675970000000177],
                [-16.360138, 19.675970000000177],
                [-16.360138, 19.676527],
                [-16.359861, 19.676527],
                [-16.359861, 19.677082],
                [-16.359582999999873, 19.677082],
                [-16.359582999999873, 19.677639],
                [-16.359306, 19.677639],
                [-16.359306, 19.67791700000015],
                [-16.359028, 19.67791700000015],
                [-16.359028, 19.678473000000167],
                [-16.358748999999875, 19.678473000000167],
                [-16.358748999999875, 19.678749],
                [-16.358473, 19.678749],
                [-16.358473, 19.67958300000015],
                [-16.358194, 19.67958300000015],
                [-16.358194, 19.68014],
                [-16.35791799999987, 19.68014],
                [-16.35791799999987, 19.680414],
                [-16.357639, 19.680414],
                [-16.357639, 19.680696],
                [-16.357361, 19.680696],
                [-16.357361, 19.68125],
                [-16.357083999999873, 19.68125],
                [-16.357081999999878, 19.68152600000019],
                [-16.356806, 19.68152600000019],
                [-16.356806, 19.682083],
                [-16.356527, 19.682083],
                [-16.356527, 19.682636000000173],
                [-16.356250999999872, 19.682636000000173],
                [-16.356250999999872, 19.683195],
                [-16.355972, 19.683195],
                [-16.355972, 19.683750000000146],
                [-16.355696, 19.683750000000146],
                [-16.355696, 19.684029000000123],
                [-16.355416999999875, 19.684029000000123],
                [-16.355416999999875, 19.684584],
                [-16.355139, 19.684584],
                [-16.355139, 19.684860000000185],
                [-16.35486, 19.684860000000185],
                [-16.35486, 19.685417],
                [-16.35458599999987, 19.685417],
                [-16.35458599999987, 19.685972000000163],
                [-16.354304999999897, 19.685972000000163],
                [-16.354304999999897, 19.686806],
                [-16.354029, 19.686806],
                [-16.354029, 19.687639],
                [-16.353749999999877, 19.687639],
                [-16.353749999999877, 19.688194000000124],
                [-16.353474, 19.688194000000124],
                [-16.353474, 19.688749],
                [-16.353195, 19.688749],
                [-16.353195, 19.689583000000141],
                [-16.352916999999877, 19.689583000000141],
                [-16.352916999999877, 19.689861],
                [-16.352637999999899, 19.689861],
                [-16.352637999999899, 19.690416000000141],
                [-16.352364, 19.690416000000141],
                [-16.352364, 19.690695000000119],
                [-16.352080999999885, 19.690695000000119],
                [-16.352080999999885, 19.69125],
                [-16.351804999999899, 19.69125],
                [-16.351804999999899, 19.691805000000159],
                [-16.351528, 19.691805000000159],
                [-16.351528, 19.692362],
                [-16.351249999999879, 19.692362],
                [-16.351249999999879, 19.692638000000159],
                [-16.350971, 19.692638000000159],
                [-16.350971, 19.692917000000136],
                [-16.350695, 19.692917000000136],
                [-16.350695, 19.693193],
                [-16.350415999999882, 19.693193],
                [-16.350415999999882, 19.693472],
                [-16.349859, 19.693472],
                [-16.349859, 19.694027000000119],
                [-16.349582999999882, 19.694027000000119],
                [-16.349582999999882, 19.694307],
                [-16.349305999999899, 19.694307],
                [-16.349305999999899, 19.694586000000186],
                [-16.349028, 19.694586000000186],
                [-16.349028, 19.694860000000119],
                [-16.348748999999884, 19.694860000000119],
                [-16.348748999999884, 19.695139000000154],
                [-16.348472999999899, 19.695139000000154],
                [-16.348472999999899, 19.695415],
                [-16.347917999999879, 19.695415],
                [-16.347917999999879, 19.695696],
                [-16.347637, 19.695696],
                [-16.347637, 19.695972000000154],
                [-16.347361, 19.695972000000154],
                [-16.347361, 19.696251000000132],
                [-16.347083999999882, 19.696251000000132],
                [-16.347083999999882, 19.696526000000119],
                [-16.346806, 19.696526000000119],
                [-16.346806, 19.696808],
                [-16.346527, 19.696808],
                [-16.346527, 19.697083],
                [-16.345972, 19.697083],
                [-16.345972, 19.697361000000114],
                [-16.345694, 19.697361000000114],
                [-16.345694, 19.697639],
                [-16.345414999999832, 19.697639],
                [-16.345414999999832, 19.697918000000186],
                [-16.344862, 19.697918000000186],
                [-16.344862, 19.698195],
                [-16.344583999999884, 19.698195],
                [-16.344583999999884, 19.698473],
                [-16.344307, 19.698473],
                [-16.344307, 19.698750000000132],
                [-16.344028, 19.698750000000132],
                [-16.344028, 19.69903],
                [-16.343749999999829, 19.69903],
                [-16.343749999999829, 19.699305],
                [-16.343472, 19.699306],
                [-16.343472, 19.699583000000132],
                [-16.343197, 19.699583000000132],
                [-16.343197, 19.69986200000011],
                [-16.342915999999832, 19.69986200000011],
                [-16.342915999999832, 19.700138],
                [-16.342638, 19.700138],
                [-16.342638, 19.700417000000186],
                [-16.342361, 19.700417000000186],
                [-16.342361, 19.70069500000011],
                [-16.342084999999884, 19.70069500000011],
                [-16.342084999999884, 19.700972],
                [-16.341806, 19.700972],
                [-16.341806, 19.701529],
                [-16.341528, 19.701529],
                [-16.341528, 19.701805],
                [-16.341249, 19.701805],
                [-16.341249, 19.702084000000127],
                [-16.340975, 19.702084000000127],
                [-16.340975, 19.70236],
                [-16.340694, 19.70236],
                [-16.340694, 19.702639],
                [-16.340414, 19.702639],
                [-16.340414, 19.70319400000011],
                [-16.340139, 19.70319400000011],
                [-16.340139, 19.703472],
                [-16.339863, 19.703472],
                [-16.339863, 19.70402700000011],
                [-16.339583999999832, 19.70402700000011],
                [-16.339583999999832, 19.704306],
                [-16.339306, 19.704306],
                [-16.339306, 19.704861],
                [-16.339027, 19.704861],
                [-16.339027, 19.705139],
                [-16.338752999999883, 19.705139],
                [-16.338752999999883, 19.705416000000127],
                [-16.33847, 19.705416000000127],
                [-16.33847, 19.705692],
                [-16.338194, 19.705692],
                [-16.338194, 19.705973],
                [-16.337917, 19.705973],
                [-16.337917, 19.706249000000128],
                [-16.337639, 19.706249000000128],
                [-16.337639, 19.706528000000105],
                [-16.33736, 19.706528000000105],
                [-16.33736, 19.706804],
                [-16.337084, 19.706804],
                [-16.337084, 19.707083000000182],
                [-16.336805, 19.707083000000182],
                [-16.336805, 19.707361000000105],
                [-16.336527, 19.707361000000105],
                [-16.336527, 19.70764],
                [-16.336248, 19.70764],
                [-16.336248, 19.707914000000187],
                [-16.335972, 19.707914000000187],
                [-16.335972, 19.708197000000155],
                [-16.335695, 19.708197000000155],
                [-16.335695, 19.708471],
                [-16.335417, 19.708471],
                [-16.335417, 19.709026],
                [-16.335138, 19.709026],
                [-16.335138, 19.709307],
                [-16.334862, 19.709307],
                [-16.334862, 19.709862000000101],
                [-16.334583, 19.709862000000101],
                [-16.334583, 19.710136],
                [-16.334305, 19.710136],
                [-16.334305, 19.710695000000101],
                [-16.334028, 19.710695000000101],
                [-16.334028, 19.710972],
                [-16.33375, 19.710972],
                [-16.33375, 19.711529000000155],
                [-16.333470999999861, 19.711529000000155],
                [-16.333470999999861, 19.712084],
                [-16.333195, 19.712084],
                [-16.333195, 19.71236],
                [-16.332916, 19.71236],
                [-16.332916, 19.71264100000019],
                [-16.33264, 19.71264100000019],
                [-16.33264, 19.713194000000101],
                [-16.332361, 19.713194000000101],
                [-16.332361, 19.713472],
                [-16.332083, 19.713472],
                [-16.332083, 19.714306],
                [-16.331806, 19.714306],
                [-16.331806, 19.714861000000155],
                [-16.331528, 19.714861000000155],
                [-16.331528, 19.71597300000019],
                [-16.331249, 19.71597300000019],
                [-16.331249, 19.716528],
                [-16.330973, 19.716528],
                [-16.330973, 19.717081000000178],
                [-16.330694, 19.717081000000178],
                [-16.330694, 19.717916000000173],
                [-16.330416, 19.717916000000173],
                [-16.330416, 19.71847200000019],
                [-16.330138999999861, 19.71847200000019],
                [-16.330138999999861, 19.719028000000151],
                [-16.329861, 19.719028000000151],
                [-16.329861, 19.720139],
                [-16.329584, 19.720139],
                [-16.329584, 19.720694000000151],
                [-16.32930599999986, 19.720694000000151],
                [-16.32930599999986, 19.721251],
                [-16.329027, 19.721251],
                [-16.329027, 19.721525000000156],
                [-16.328751, 19.721525000000156],
                [-16.328751, 19.722086],
                [-16.328471999999863, 19.722086],
                [-16.328471999999863, 19.722637000000191],
                [-16.328196, 19.722637000000191],
                [-16.328196, 19.723194],
                [-16.327917, 19.723194],
                [-16.327917, 19.723747000000174],
                [-16.327638999999863, 19.723747000000174],
                [-16.327638999999863, 19.725140000000124],
                [-16.327362, 19.725140000000124],
                [-16.32736, 19.725695],
                [-16.327084, 19.725695],
                [-16.327084, 19.726528],
                [-16.32680299999987, 19.726528],
                [-16.32680299999987, 19.726805],
                [-16.326529, 19.726805],
                [-16.326529, 19.727362000000142],
                [-16.32625, 19.727362000000142],
                [-16.32625, 19.727638],
                [-16.32597399999986, 19.727638],
                [-16.32597399999986, 19.728195000000142],
                [-16.325695, 19.728195000000142],
                [-16.325695, 19.728472000000124],
                [-16.325417, 19.728472000000124],
                [-16.325417, 19.72875],
                [-16.325138, 19.72875],
                [-16.325138, 19.729305000000124],
                [-16.324863, 19.729305000000124],
                [-16.324863, 19.729584000000159],
                [-16.324581, 19.729584000000159],
                [-16.324581, 19.72986],
                [-16.324306, 19.72986],
                [-16.324305, 19.730417000000159],
                [-16.324028, 19.730417000000159],
                [-16.324028, 19.730694000000142],
                [-16.32375, 19.730694000000142],
                [-16.32375, 19.730972],
                [-16.322916, 19.730972],
                [-16.322916, 19.731251],
                [-16.322639, 19.731251],
                [-16.322639, 19.731527000000142],
                [-16.322358999999892, 19.731527000000142],
                [-16.322358999999892, 19.73180600000012],
                [-16.322083, 19.73180600000012],
                [-16.322083, 19.732082],
                [-16.321527, 19.732082],
                [-16.321527, 19.732361],
                [-16.321251, 19.732361],
                [-16.321251, 19.73263900000012],
                [-16.320694, 19.73263900000012],
                [-16.320694, 19.732916000000159],
                [-16.320417, 19.732916000000159],
                [-16.320417, 19.733194],
                [-16.319859999999892, 19.733194],
                [-16.319859999999892, 19.733473],
                [-16.319584, 19.733473],
                [-16.319584, 19.733749000000159],
                [-16.319304999999872, 19.733749000000159],
                [-16.319304999999872, 19.734028000000137],
                [-16.319029, 19.734028000000137],
                [-16.319029, 19.734304],
                [-16.31875, 19.734304],
                [-16.31875, 19.734583],
                [-16.318471999999872, 19.734583],
                [-16.318471999999872, 19.734861000000137],
                [-16.318195, 19.734861000000137],
                [-16.318195, 19.73513800000012],
                [-16.316527999999892, 19.73513800000012],
                [-16.316527999999892, 19.73541500000016],
                [-16.315416, 19.73541500000016],
                [-16.315416, 19.735697000000187],
                [-16.312916, 19.735697000000187],
                [-16.312916, 19.73597100000012],
                [-16.310969999999827, 19.73597100000012],
                [-16.310969999999827, 19.73625],
                [-16.310138999999879, 19.73625],
                [-16.310138999999879, 19.736528000000192],
                [-16.308471999999824, 19.736528000000192],
                [-16.308471999999824, 19.736807],
                [-16.307361999999898, 19.736807],
                [-16.307361999999898, 19.737083],
                [-16.306804999999827, 19.737083],
                [-16.306804999999827, 19.737362000000132],
                [-16.30625, 19.737362000000132],
                [-16.30625, 19.73763700000012],
                [-16.305416, 19.73763700000012],
                [-16.305416, 19.737919],
                [-16.302917, 19.737919],
                [-16.302917, 19.738194000000192],
                [-16.300691999999856, 19.738194000000192],
                [-16.300691999999856, 19.738472000000115],
                [-16.300417, 19.738472000000115],
                [-16.300417, 19.739029000000187],
                [-16.299864, 19.739029000000187],
                [-16.299864, 19.739305000000115],
                [-16.299028, 19.739305000000115],
                [-16.299028, 19.739584],
                [-16.298473, 19.739584],
                [-16.298473, 19.739861000000133],
                [-16.297638, 19.739861000000133],
                [-16.297638, 19.740141],
                [-16.296806, 19.740141],
                [-16.296806, 19.740417],
                [-16.293193999999858, 19.740417],
                [-16.293193999999858, 19.740694000000133],
                [-16.292084, 19.740694000000133],
                [-16.292084, 19.740417],
                [-16.28875, 19.740417],
                [-16.28875, 19.740141],
                [-16.28764, 19.740141],
                [-16.28764, 19.739861000000133],
                [-16.287085, 19.739861000000133],
                [-16.287085, 19.739584],
                [-16.286528, 19.739584],
                [-16.286528, 19.739305000000115],
                [-16.285971, 19.739305000000115],
                [-16.285971, 19.739029000000187],
                [-16.285691999999869, 19.739029000000187],
                [-16.285691999999869, 19.73875],
                [-16.285418, 19.73875],
                [-16.285418, 19.738472000000115],
                [-16.285139, 19.738472000000115],
                [-16.285139, 19.738194000000192],
                [-16.284863, 19.738194000000192],
                [-16.284863, 19.737919],
                [-16.284306, 19.737919],
                [-16.284306, 19.73763700000012],
                [-16.284027, 19.73763700000012],
                [-16.284027, 19.737362000000132],
                [-16.28347, 19.737362000000132],
                [-16.28347, 19.737083],
                [-16.283194, 19.737083],
                [-16.283194, 19.736807],
                [-16.282917, 19.736807],
                [-16.282917, 19.736528000000192],
                [-16.282639, 19.736528000000192],
                [-16.282639, 19.73625],
                [-16.282084, 19.73625],
                [-16.282084, 19.73597100000012],
                [-16.281805, 19.73597100000012],
                [-16.281805, 19.73541500000016],
                [-16.281528, 19.73541500000016],
                [-16.281528, 19.734583],
                [-16.281247999999891, 19.734583],
                [-16.281247999999891, 19.733749000000159],
                [-16.280972, 19.733749000000159],
                [-16.280972, 19.733194],
                [-16.280695, 19.733194],
                [-16.280695, 19.732916000000159],
                [-16.280415999999889, 19.732916000000159],
                [-16.280415999999889, 19.73180600000012],
                [-16.280695, 19.73180600000012],
                [-16.280695, 19.731251],
                [-16.280972, 19.731251],
                [-16.280972, 19.730417000000159],
                [-16.281247999999891, 19.730417000000159],
                [-16.281247999999891, 19.730139],
                [-16.281528, 19.730139],
                [-16.281528, 19.729584000000159],
                [-16.281805, 19.729584000000159],
                [-16.281805, 19.729305000000124],
                [-16.282084, 19.729305000000124],
                [-16.282084, 19.72875],
                [-16.282639, 19.72875],
                [-16.282639, 19.728472000000124],
                [-16.282917, 19.728472000000124],
                [-16.282917, 19.728195000000142],
                [-16.283194, 19.728195000000142],
                [-16.283194, 19.727917],
                [-16.283752, 19.727917],
                [-16.283752, 19.727638],
                [-16.284027, 19.727638],
                [-16.284027, 19.727362000000142],
                [-16.284306, 19.727362000000142],
                [-16.284306, 19.727083000000164],
                [-16.284581999999887, 19.727083000000164],
                [-16.284581999999887, 19.726805],
                [-16.284863, 19.726805],
                [-16.284863, 19.726528],
                [-16.285139, 19.726528],
                [-16.285139, 19.726250000000164],
                [-16.285418, 19.726250000000164],
                [-16.285418, 19.725973000000124],
                [-16.285691999999869, 19.725973000000124],
                [-16.285691999999869, 19.725695],
                [-16.286249, 19.725695],
                [-16.286249, 19.725416],
                [-16.286806, 19.725416],
                [-16.286806, 19.725140000000124],
                [-16.287361, 19.725140000000124],
                [-16.287361, 19.724861000000146],
                [-16.287916, 19.724861000000146],
                [-16.287916, 19.724583],
                [-16.288195, 19.724583],
                [-16.288195, 19.724306],
                [-16.28875, 19.724306],
                [-16.28875, 19.724030000000141],
                [-16.289307, 19.724030000000141],
                [-16.289307, 19.723747000000174],
                [-16.289583, 19.723747000000174],
                [-16.289583, 19.723473],
                [-16.290138, 19.723473],
                [-16.290138, 19.723194],
                [-16.290972, 19.723194],
                [-16.290972, 19.722918000000163],
                [-16.29125, 19.722918000000163],
                [-16.29125, 19.722637000000191],
                [-16.291805, 19.722637000000191],
                [-16.291805, 19.722361],
                [-16.292084, 19.722361],
                [-16.292084, 19.722086],
                [-16.29236, 19.722086],
                [-16.29236, 19.721807],
                [-16.292639, 19.721807],
                [-16.292639, 19.721525000000156],
                [-16.292917, 19.721525000000156],
                [-16.292917, 19.720972],
                [-16.293193999999858, 19.720972],
                [-16.293193999999858, 19.720694000000151],
                [-16.293472, 19.720694000000151],
                [-16.293472, 19.720415000000173],
                [-16.293751, 19.720415000000173],
                [-16.293751, 19.720139],
                [-16.294026999999858, 19.720139],
                [-16.294026999999858, 19.719862],
                [-16.294306, 19.719862],
                [-16.294306, 19.719584000000168],
                [-16.294584, 19.719584000000168],
                [-16.294584, 19.719303],
                [-16.294860999999855, 19.719303],
                [-16.294860999999855, 19.719028000000151],
                [-16.295139, 19.719028000000151],
                [-16.295139, 19.71875],
                [-16.295416, 19.71875],
                [-16.295416, 19.71847200000019],
                [-16.295693999999855, 19.71847200000019],
                [-16.295693999999855, 19.718193000000156],
                [-16.295973, 19.718193000000156],
                [-16.295973, 19.717916000000173],
                [-16.296249, 19.717916000000173],
                [-16.296249, 19.717638],
                [-16.296528, 19.717638],
                [-16.296528, 19.717081000000178],
                [-16.296806, 19.717081000000178],
                [-16.296806, 19.71680600000019],
                [-16.297358999999858, 19.71680600000019],
                [-16.297358999999858, 19.716249],
                [-16.297638, 19.716249],
                [-16.297641999999826, 19.71597300000019],
                [-16.297916, 19.71597300000019],
                [-16.297916, 19.715694000000155],
                [-16.298195, 19.715694000000155],
                [-16.298195, 19.715416],
                [-16.298473, 19.715416],
                [-16.298473, 19.715139],
                [-16.298752, 19.715139],
                [-16.298752, 19.714861000000155],
                [-16.299303, 19.714861000000155],
                [-16.299303, 19.714582000000178],
                [-16.299582, 19.714582000000178],
                [-16.299582, 19.714306],
                [-16.299864, 19.714306],
                [-16.299864, 19.714027000000101],
                [-16.300138, 19.714027000000101],
                [-16.300138, 19.713751000000173],
                [-16.300417, 19.713751000000173],
                [-16.300417, 19.713472],
                [-16.300691999999856, 19.713472],
                [-16.300695, 19.713194000000101],
                [-16.300973999999826, 19.713194000000101],
                [-16.300973999999826, 19.712917],
                [-16.30125, 19.712917],
                [-16.30125, 19.71264100000019],
                [-16.301527, 19.71264100000019],
                [-16.301527, 19.71236],
                [-16.302086, 19.71236],
                [-16.302086, 19.712084],
                [-16.302361, 19.712084],
                [-16.302361, 19.711805],
                [-16.302917, 19.711805],
                [-16.302917, 19.711529000000155],
                [-16.303196, 19.711529000000155],
                [-16.303196, 19.711250000000177],
                [-16.303472999999826, 19.711250000000177],
                [-16.303472999999826, 19.710972],
                [-16.303749, 19.710972],
                [-16.303749, 19.710695000000101],
                [-16.304028, 19.710695000000101],
                [-16.304028, 19.710419000000172],
                [-16.304307999999878, 19.710419000000172],
                [-16.304307999999878, 19.709862000000101],
                [-16.304583, 19.709862000000101],
                [-16.304583, 19.709026],
                [-16.304028, 19.709026],
                [-16.304028, 19.708750000000123],
                [-16.303196, 19.708750000000123],
                [-16.303196, 19.708471],
                [-16.30125, 19.708471],
                [-16.30125, 19.707914000000187],
                [-16.300417, 19.707914000000187],
                [-16.300417, 19.708471],
                [-16.299864, 19.708471],
                [-16.299864, 19.708750000000123],
                [-16.299582, 19.708750000000123],
                [-16.299582, 19.709026],
                [-16.299303, 19.709026],
                [-16.299303, 19.709307],
                [-16.299028, 19.709307],
                [-16.299028, 19.710136],
                [-16.298752, 19.710136],
                [-16.298752, 19.710972],
                [-16.298473, 19.710972],
                [-16.298473, 19.711250000000177],
                [-16.297916, 19.711250000000177],
                [-16.297916, 19.711529000000155],
                [-16.297638, 19.711529000000155],
                [-16.297638, 19.711805],
                [-16.297358999999858, 19.711805],
                [-16.297358999999858, 19.712084],
                [-16.297083, 19.712084],
                [-16.297083, 19.71236],
                [-16.296528, 19.71236],
                [-16.296528, 19.71264100000019],
                [-16.296249, 19.71264100000019],
                [-16.296249, 19.712917],
                [-16.295973, 19.712917],
                [-16.295973, 19.713194000000101],
                [-16.295693999999855, 19.713194000000101],
                [-16.295693999999855, 19.713472],
                [-16.295139, 19.713472],
                [-16.295139, 19.713751000000173],
                [-16.294860999999855, 19.713751000000173],
                [-16.294860999999855, 19.714027000000101],
                [-16.293193999999858, 19.714027000000101],
                [-16.293193999999858, 19.714306],
                [-16.292639, 19.714306],
                [-16.292639, 19.714582000000178],
                [-16.29236, 19.714582000000178],
                [-16.29236, 19.714861000000155],
                [-16.291805, 19.714861000000155],
                [-16.291805, 19.715139],
                [-16.291528999999855, 19.715139],
                [-16.291528999999855, 19.715416],
                [-16.29125, 19.715416],
                [-16.29125, 19.715694000000155],
                [-16.290694999999857, 19.715694000000155],
                [-16.290694999999857, 19.71597300000019],
                [-16.290417, 19.71597300000019],
                [-16.290417, 19.716249],
                [-16.290138, 19.716249],
                [-16.290138, 19.716528],
                [-16.289583, 19.716528],
                [-16.289583, 19.71680600000019],
                [-16.289307, 19.71680600000019],
                [-16.289307, 19.717081000000178],
                [-16.28875, 19.717081000000178],
                [-16.28875, 19.717361],
                [-16.288473, 19.717361],
                [-16.288473, 19.717638],
                [-16.288195, 19.717638],
                [-16.288195, 19.717916000000173],
                [-16.287916, 19.717916000000173],
                [-16.287916, 19.718193000000156],
                [-16.28764, 19.718193000000156],
                [-16.28764, 19.71847200000019],
                [-16.287361, 19.71847200000019],
                [-16.287361, 19.71875],
                [-16.287085, 19.71875],
                [-16.287085, 19.719028000000151],
                [-16.286249, 19.719028000000151],
                [-16.286249, 19.719303],
                [-16.285691999999869, 19.719303],
                [-16.285691999999869, 19.719584000000168],
                [-16.285139, 19.719584000000168],
                [-16.285139, 19.719862],
                [-16.284581999999887, 19.719862],
                [-16.284581999999887, 19.720139],
                [-16.284306, 19.720139],
                [-16.284306, 19.720415000000173],
                [-16.284027, 19.720415000000173],
                [-16.284027, 19.720694000000151],
                [-16.283752, 19.720694000000151],
                [-16.283752, 19.720972],
                [-16.28347, 19.720972],
                [-16.28347, 19.721251],
                [-16.282917, 19.721251],
                [-16.282917, 19.721807],
                [-16.282359999999869, 19.721807],
                [-16.282359999999869, 19.722086],
                [-16.282084, 19.722086],
                [-16.282084, 19.722361],
                [-16.281805, 19.722361],
                [-16.281805, 19.722637000000191],
                [-16.281528, 19.722637000000191],
                [-16.281528, 19.722918000000163],
                [-16.281247999999891, 19.722918000000163],
                [-16.281247999999891, 19.723194],
                [-16.280972, 19.723194],
                [-16.280972, 19.723473],
                [-16.280695, 19.723473],
                [-16.280695, 19.723747000000174],
                [-16.280415999999889, 19.723747000000174],
                [-16.280415999999889, 19.724030000000141],
                [-16.280136, 19.724030000000141],
                [-16.280136, 19.724306],
                [-16.279861, 19.724306],
                [-16.279861, 19.724861000000146],
                [-16.279582999999889, 19.724861000000146],
                [-16.279582999999889, 19.725140000000124],
                [-16.279306, 19.725140000000124],
                [-16.279306, 19.725416],
                [-16.279028, 19.725416],
                [-16.279028, 19.725695],
                [-16.278748999999891, 19.725695],
                [-16.278748999999891, 19.725973000000124],
                [-16.278473, 19.725973000000124],
                [-16.278473, 19.726250000000164],
                [-16.278193999999871, 19.726250000000164],
                [-16.278193999999871, 19.726528],
                [-16.277917999999886, 19.726528],
                [-16.277917999999886, 19.726805],
                [-16.277639, 19.726805],
                [-16.277639, 19.727083000000164],
                [-16.277360999999871, 19.727083000000164],
                [-16.277360999999871, 19.727362000000142],
                [-16.277081999999893, 19.727362000000142],
                [-16.277081999999893, 19.727638],
                [-16.276806, 19.727638],
                [-16.276806, 19.727917],
                [-16.276526999999874, 19.727917],
                [-16.276526999999874, 19.728195000000142],
                [-16.275972, 19.728195000000142],
                [-16.275972, 19.728472000000124],
                [-16.275416999999891, 19.728472000000124],
                [-16.275416999999891, 19.72875],
                [-16.275139, 19.72875],
                [-16.275139, 19.729029],
                [-16.274859999999819, 19.729029],
                [-16.274859999999819, 19.729305000000124],
                [-16.274574, 19.729305000000124],
                [-16.274305, 19.729305000000124],
                [-16.274305, 19.729584000000159],
                [-16.274026999999819, 19.729584000000159],
                [-16.274026999999819, 19.72986],
                [-16.273749999999893, 19.72986],
                [-16.273749999999893, 19.730417000000159],
                [-16.273474, 19.730417000000159],
                [-16.273474, 19.730694000000142],
                [-16.273194999999873, 19.730694000000142],
                [-16.273194999999873, 19.731251],
                [-16.272916999999893, 19.731251],
                [-16.272916999999893, 19.73263900000012],
                [-16.272638, 19.73263900000012],
                [-16.272638, 19.732916000000159],
                [-16.272364, 19.732916000000159],
                [-16.272364, 19.733749000000159],
                [-16.272081, 19.733749000000159],
                [-16.272081, 19.734583],
                [-16.272364, 19.734583],
                [-16.272364, 19.734861000000137],
                [-16.272638, 19.734861000000137],
                [-16.272638, 19.73541500000016],
                [-16.273474, 19.73541500000016],
                [-16.273474, 19.734861000000137],
                [-16.273749999999893, 19.734861000000137],
                [-16.273749999999893, 19.73513800000012],
                [-16.274026999999819, 19.735140000000115],
                [-16.274026999999819, 19.735697000000187],
                [-16.275416999999891, 19.735697000000187],
                [-16.275416999999891, 19.73597100000012],
                [-16.275696, 19.73597100000012],
                [-16.275696, 19.73625],
                [-16.275972, 19.73625],
                [-16.275972, 19.737083],
                [-16.276250999999888, 19.737083],
                [-16.276250999999888, 19.737919],
                [-16.276526999999874, 19.737919],
                [-16.276526999999874, 19.738472000000115],
                [-16.276806, 19.738472000000115],
                [-16.276806, 19.740417],
                [-16.277081999999893, 19.740417],
                [-16.277081999999893, 19.741528000000187],
                [-16.277360999999871, 19.741528000000187],
                [-16.277360999999871, 19.741806000000111],
                [-16.277639, 19.741806000000111],
                [-16.277639, 19.742361000000187],
                [-16.277917999999886, 19.742361000000187],
                [-16.277917999999886, 19.74264],
                [-16.278193999999871, 19.74264],
                [-16.278193999999871, 19.743471],
                [-16.278473, 19.743471],
                [-16.278511, 19.74375],
                [-16.278748999999891, 19.74375],
                [-16.278748999999891, 19.744028000000128],
                [-16.279028, 19.744028000000128],
                [-16.279028, 19.744305000000111],
                [-16.279306, 19.744305000000111],
                [-16.279306, 19.744581],
                [-16.279582999999889, 19.744581],
                [-16.279582999999889, 19.744862000000182],
                [-16.279861, 19.744862000000182],
                [-16.279861, 19.745138000000111],
                [-16.280415999999889, 19.745138000000111],
                [-16.280415999999889, 19.745693000000188],
                [-16.280695, 19.745693000000188],
                [-16.280695, 19.745972],
                [-16.280972, 19.745972],
                [-16.280972, 19.746803],
                [-16.280695, 19.746803],
                [-16.280695, 19.747084],
                [-16.280415999999889, 19.747084],
                [-16.280415999999889, 19.747360000000128],
                [-16.280136, 19.747360000000128],
                [-16.280136, 19.747639000000106],
                [-16.279861, 19.747639000000106],
                [-16.279861, 19.747915],
                [-16.279582999999889, 19.747915],
                [-16.279582999999889, 19.748472000000106],
                [-16.279306, 19.748472000000106],
                [-16.279306, 19.749025000000188],
                [-16.279028, 19.749025000000188],
                [-16.279028, 19.749584],
                [-16.278748999999891, 19.749584],
                [-16.278748999999891, 19.750418],
                [-16.278473, 19.750418],
                [-16.278473, 19.750973000000101],
                [-16.278193999999871, 19.750973000000101],
                [-16.278193999999871, 19.751530000000173],
                [-16.277917999999886, 19.751530000000173],
                [-16.277917999999886, 19.751806000000101],
                [-16.277639, 19.751806000000101],
                [-16.277639, 19.752361000000178],
                [-16.277360999999871, 19.752361000000178],
                [-16.277360999999871, 19.752916],
                [-16.277081999999893, 19.752916],
                [-16.277081999999893, 19.753471000000161],
                [-16.276806, 19.753471000000161],
                [-16.276806, 19.754583],
                [-16.276526999999874, 19.754583],
                [-16.276526999999874, 19.755138000000102],
                [-16.276250999999888, 19.755138000000102],
                [-16.276250999999888, 19.755695000000173],
                [-16.275972, 19.755695000000173],
                [-16.275972, 19.755972000000156],
                [-16.275696, 19.755972000000156],
                [-16.275696, 19.75625],
                [-16.275416999999891, 19.75625],
                [-16.275416999999891, 19.756805000000156],
                [-16.275139, 19.756805000000156],
                [-16.275139, 19.75736],
                [-16.274859999999819, 19.75736],
                [-16.274859999999819, 19.757917000000134],
                [-16.274585999999886, 19.757917000000134],
                [-16.274585999999886, 19.758192000000179],
                [-16.274305, 19.758192000000179],
                [-16.274305, 19.758751],
                [-16.274026999999819, 19.758751],
                [-16.274026999999819, 19.759027000000174],
                [-16.273749999999893, 19.759027000000174],
                [-16.273749999999893, 19.759304000000157],
                [-16.273474, 19.759304000000157],
                [-16.273474, 19.759861],
                [-16.273194999999873, 19.759861],
                [-16.273194999999873, 19.760139000000152],
                [-16.272916999999893, 19.760139000000152],
                [-16.272916999999893, 19.760695000000169],
                [-16.272638, 19.760695000000169],
                [-16.272638, 19.760975],
                [-16.272364, 19.760975],
                [-16.272364, 19.76125],
                [-16.272081, 19.76125],
                [-16.272081, 19.761526000000174],
                [-16.271805, 19.761526000000174],
                [-16.271805, 19.762083],
                [-16.271527999999819, 19.762083],
                [-16.271527999999819, 19.762362],
                [-16.27125199999989, 19.762362],
                [-16.27125199999989, 19.762918],
                [-16.270969, 19.762918],
                [-16.270969, 19.764305],
                [-16.270694999999819, 19.764305],
                [-16.270694999999819, 19.766251000000125],
                [-16.270415999999898, 19.766251000000125],
                [-16.270415999999898, 19.766527],
                [-16.270142, 19.766527],
                [-16.270142, 19.767084000000125],
                [-16.269859, 19.767084000000125],
                [-16.269859, 19.767916],
                [-16.269582999999898, 19.767916],
                [-16.269582999999898, 19.768749],
                [-16.269306, 19.768749],
                [-16.269306, 19.769583000000125],
                [-16.268749, 19.769583000000125],
                [-16.268749, 19.769861],
                [-16.268194, 19.769861],
                [-16.268194, 19.77014],
                [-16.267916, 19.77014],
                [-16.267916, 19.770416000000125],
                [-16.267361, 19.770416000000125],
                [-16.267361, 19.770695000000103],
                [-16.267083999999898, 19.770695000000103],
                [-16.267083999999898, 19.770971],
                [-16.266806, 19.770971],
                [-16.266806, 19.77125],
                [-16.266527, 19.77125],
                [-16.266527, 19.771528000000103],
                [-16.265972, 19.771528000000103],
                [-16.265972, 19.771805000000143],
                [-16.265694, 19.771805000000143],
                [-16.265694, 19.772083],
                [-16.265417, 19.772083],
                [-16.265417, 19.772362],
                [-16.264862, 19.772362],
                [-16.26486, 19.772638000000143],
                [-16.264584, 19.772638000000143],
                [-16.264584, 19.77291700000012],
                [-16.264305, 19.77291700000012],
                [-16.264305, 19.773193],
                [-16.264029, 19.773193],
                [-16.264029, 19.773472],
                [-16.26375, 19.773472],
                [-16.26375, 19.773750000000121],
                [-16.263472, 19.773750000000121],
                [-16.263472, 19.774027000000103],
                [-16.26319699999982, 19.774027000000103],
                [-16.26319699999982, 19.774303],
                [-16.262917, 19.774303],
                [-16.262917, 19.774586],
                [-16.262638, 19.774586],
                [-16.262638, 19.774860000000103],
                [-16.262083, 19.774860000000103],
                [-16.262083, 19.775139000000138],
                [-16.261806, 19.775139000000138],
                [-16.261805, 19.775415],
                [-16.26125, 19.775415],
                [-16.26125, 19.775972000000138],
                [-16.260975, 19.775972000000138],
                [-16.260975, 19.776251000000116],
                [-16.26041599999985, 19.776251000000116],
                [-16.26041599999985, 19.776525],
                [-16.260139, 19.776525],
                [-16.260139, 19.777082000000121],
                [-16.259863, 19.777082000000121],
                [-16.259863, 19.777361],
                [-16.259584, 19.777361],
                [-16.259584, 19.777918],
                [-16.259306, 19.777918],
                [-16.259306, 19.778194],
                [-16.259027, 19.778194],
                [-16.259027, 19.77903],
                [-16.258471, 19.77903],
                [-16.258471, 19.779306000000133],
                [-16.258192, 19.779306000000133],
                [-16.258192, 19.779583000000116],
                [-16.25791699999985, 19.779583000000116],
                [-16.25791699999985, 19.779861],
                [-16.25736, 19.779861],
                [-16.25736, 19.780140000000188],
                [-16.25708399999985, 19.780140000000188],
                [-16.25708399999985, 19.780695],
                [-16.256805, 19.780695],
                [-16.256805, 19.781252000000165],
                [-16.256527, 19.781252000000165],
                [-16.256527, 19.782084000000111],
                [-16.256248, 19.782084000000111],
                [-16.256248, 19.784027],
                [-16.255972, 19.784027],
                [-16.255972, 19.786249000000112],
                [-16.256248, 19.786249000000112],
                [-16.256248, 19.787361],
                [-16.256527, 19.787361],
                [-16.256527, 19.788750000000107],
                [-16.256805, 19.788750000000107],
                [-16.256805, 19.789305000000184],
                [-16.25708399999985, 19.789305000000184],
                [-16.25708399999985, 19.789862],
                [-16.25736, 19.789862],
                [-16.25736, 19.790136000000189],
                [-16.257641, 19.790136000000189],
                [-16.257641, 19.790419000000156],
                [-16.258192, 19.790419000000156],
                [-16.258192, 19.790972],
                [-16.258471, 19.790972],
                [-16.258471, 19.791529],
                [-16.258752999999899, 19.791529],
                [-16.258752999999899, 19.791805],
                [-16.259584, 19.791805],
                [-16.259584, 19.792084000000102],
                [-16.259863, 19.792084000000102],
                [-16.259863, 19.79236],
                [-16.260139, 19.79236],
                [-16.260139, 19.792641000000174],
                [-16.26041599999985, 19.792641000000174],
                [-16.26041599999985, 19.794027],
                [-16.260695, 19.794027],
                [-16.260695, 19.794306],
                [-16.260975, 19.794306],
                [-16.260975, 19.795139],
                [-16.26125, 19.795139],
                [-16.26125, 19.796528],
                [-16.260975, 19.796528],
                [-16.260975, 19.797361],
                [-16.260695, 19.797361],
                [-16.260695, 19.798195000000135],
                [-16.26041599999985, 19.798195000000135],
                [-16.26041599999985, 19.79875],
                [-16.260139, 19.79875],
                [-16.260139, 19.800138000000175],
                [-16.259863, 19.800138000000175],
                [-16.259863, 19.800972],
                [-16.260139, 19.800972],
                [-16.260139, 19.801807],
                [-16.259863, 19.801807],
                [-16.259863, 19.803473],
                [-16.259584, 19.803473],
                [-16.259584, 19.804583],
                [-16.259306, 19.804583],
                [-16.259306, 19.806805],
                [-16.259584, 19.806805],
                [-16.259584, 19.807083000000148],
                [-16.26041599999985, 19.807083000000148],
                [-16.26041599999985, 19.806528],
                [-16.26125, 19.806528],
                [-16.26125, 19.807638],
                [-16.260975, 19.807638],
                [-16.260975, 19.808195000000126],
                [-16.260695, 19.808195000000126],
                [-16.260695, 19.808472000000108],
                [-16.26041599999985, 19.808472000000108],
                [-16.26041599999985, 19.809029],
                [-16.260695, 19.809029],
                [-16.260695, 19.80986],
                [-16.26041599999985, 19.80986],
                [-16.26041599999985, 19.810139],
                [-16.260139, 19.810139],
                [-16.260139, 19.810417000000143],
                [-16.259863, 19.810417000000143],
                [-16.259863, 19.810694000000126],
                [-16.259584, 19.810694000000126],
                [-16.259584, 19.811527000000126],
                [-16.259306, 19.811527000000126],
                [-16.259306, 19.812361],
                [-16.259027, 19.812361],
                [-16.259027, 19.812916000000143],
                [-16.258752999999899, 19.812916000000143],
                [-16.258752999999899, 19.813749000000143],
                [-16.258471, 19.813749000000143],
                [-16.258471, 19.81514],
                [-16.258192, 19.81514],
                [-16.258192, 19.817083000000139],
                [-16.25791699999985, 19.817083000000139],
                [-16.25791699999985, 19.81875],
                [-16.257641, 19.81875],
                [-16.257641, 19.819305],
                [-16.25736, 19.819305],
                [-16.25736, 19.819584],
                [-16.25708399999985, 19.819584],
                [-16.25708399999985, 19.820417],
                [-16.256805, 19.820417],
                [-16.256805, 19.820972],
                [-16.256527, 19.820972],
                [-16.256527, 19.821251000000188],
                [-16.256248, 19.821251000000188],
                [-16.256248, 19.821806],
                [-16.255972, 19.821806],
                [-16.255972, 19.822083],
                [-16.255695, 19.822083],
                [-16.255695, 19.822363000000166],
                [-16.255138, 19.822363000000166],
                [-16.255138, 19.822639],
                [-16.254862, 19.822639],
                [-16.254862, 19.822916],
                [-16.254583, 19.822916],
                [-16.254583, 19.823195000000112],
                [-16.254305, 19.823195000000112],
                [-16.254305, 19.823471],
                [-16.254028, 19.823471],
                [-16.254028, 19.823750000000189],
                [-16.25375, 19.823750000000189],
                [-16.25375, 19.824028000000112],
                [-16.253471, 19.824028000000112],
                [-16.253471, 19.824862000000167],
                [-16.253195, 19.824862000000167],
                [-16.253195, 19.82930800000014],
                [-16.253471, 19.82930800000014],
                [-16.253471, 19.830137],
                [-16.25375, 19.830137],
                [-16.25375, 19.830418000000179],
                [-16.254028, 19.830418000000179],
                [-16.254028, 19.830973],
                [-16.254305, 19.830973],
                [-16.254305, 19.832083],
                [-16.254583, 19.832083],
                [-16.254583, 19.834028],
                [-16.254305, 19.834028],
                [-16.254305, 19.83458300000018],
                [-16.254028, 19.83458300000018],
                [-16.254028, 19.835138],
                [-16.25375, 19.835138],
                [-16.25375, 19.835417],
                [-16.253471, 19.835417],
                [-16.253471, 19.83597200000014],
                [-16.253195, 19.83597200000014],
                [-16.253195, 19.836527],
                [-16.252916, 19.836527],
                [-16.252916, 19.83680500000014],
                [-16.25264, 19.83680500000014],
                [-16.25264, 19.83736],
                [-16.252361, 19.83736],
                [-16.252361, 19.838472],
                [-16.252083, 19.838472],
                [-16.252083, 19.839306000000136],
                [-16.251806, 19.839306000000136],
                [-16.251806, 19.840973],
                [-16.251528, 19.840973],
                [-16.251528, 19.841805000000136],
                [-16.251249, 19.841805000000136],
                [-16.251249, 19.842636000000141],
                [-16.250973, 19.842636000000141],
                [-16.250973, 19.844307],
                [-16.250694, 19.844307],
                [-16.250694, 19.844862000000148],
                [-16.250973, 19.844862000000148],
                [-16.250973, 19.846251000000109],
                [-16.251249, 19.846251000000109],
                [-16.251249, 19.846806],
                [-16.251528, 19.846806],
                [-16.251528, 19.847084000000109],
                [-16.251806, 19.847084000000109],
                [-16.251806, 19.847361000000149],
                [-16.252083, 19.847361000000149],
                [-16.252083, 19.847639],
                [-16.252361, 19.847639],
                [-16.252361, 19.847918],
                [-16.25264, 19.847918],
                [-16.25264, 19.848194000000149],
                [-16.254028, 19.848194000000149],
                [-16.254028, 19.847918],
                [-16.254305, 19.847918],
                [-16.254305, 19.847639],
                [-16.254583, 19.847639],
                [-16.254583, 19.847084000000109],
                [-16.254862, 19.847084000000109],
                [-16.254862, 19.846806],
                [-16.255138, 19.846806],
                [-16.255138, 19.846251000000109],
                [-16.257641, 19.846251000000109],
                [-16.257641, 19.845972000000131],
                [-16.25791699999985, 19.845972000000131],
                [-16.25791699999985, 19.845694],
                [-16.258192, 19.845694],
                [-16.258192, 19.845419],
                [-16.258471, 19.845419],
                [-16.258471, 19.845137000000136],
                [-16.258752999999899, 19.845137000000136],
                [-16.258752999999899, 19.844584],
                [-16.259027, 19.844584],
                [-16.259027, 19.845137000000136],
                [-16.259584, 19.845137000000136],
                [-16.259584, 19.845419],
                [-16.261806, 19.845419],
                [-16.261806, 19.845137000000136],
                [-16.262083, 19.845137000000136],
                [-16.262083, 19.845419],
                [-16.262638, 19.845419],
                [-16.262638, 19.845694],
                [-16.262917, 19.845694],
                [-16.262909, 19.845972000000131],
                [-16.26319699999982, 19.845972000000131],
                [-16.26319699999982, 19.846087],
                [-16.26319699999982, 19.846251000000109],
                [-16.263758, 19.846251000000109],
                [-16.26375, 19.846527],
                [-16.263905, 19.846527],
                [-16.264305, 19.846527],
                [-16.264305, 19.846806],
                [-16.264584, 19.846806],
                [-16.264584, 19.847084000000109],
                [-16.26486, 19.847084000000109],
                [-16.264862, 19.847918],
                [-16.265139, 19.847918],
                [-16.265139, 19.849028],
                [-16.265417, 19.849028],
                [-16.265417, 19.849583000000109],
                [-16.265694, 19.849583000000109],
                [-16.265694, 19.850416000000109],
                [-16.265972, 19.850416000000109],
                [-16.266043, 19.852083],
                [-16.266250999999897, 19.852083],
                [-16.266250999999897, 19.852621000000113],
                [-16.266250999999897, 19.85410700000017],
                [-16.266250999999897, 19.854303],
                [-16.266527, 19.854303],
                [-16.266527, 19.854860000000144],
                [-16.265972, 19.854860000000144],
                [-16.265972, 19.855694],
                [-16.265694, 19.855694],
                [-16.265694, 19.856525],
                [-16.265417, 19.856525],
                [-16.265417, 19.857082000000105],
                [-16.265139, 19.8570840000001],
                [-16.265139, 19.857918],
                [-16.265417, 19.857918],
                [-16.265417, 19.8595830000001],
                [-16.265139, 19.8595830000001],
                [-16.265139, 19.8604160000001],
                [-16.264862, 19.8604160000001],
                [-16.26486, 19.860695],
                [-16.264584, 19.860695],
                [-16.264584, 19.860971000000177],
                [-16.264305, 19.860971000000177],
                [-16.264305, 19.861528],
                [-16.264029, 19.861528],
                [-16.264029, 19.862083],
                [-16.26375, 19.862083],
                [-16.26375, 19.862362000000189],
                [-16.263472, 19.862362000000189],
                [-16.263472, 19.862638000000118],
                [-16.26319699999982, 19.862638000000118],
                [-16.26319699999982, 19.863194],
                [-16.262917, 19.863194],
                [-16.262917, 19.86375],
                [-16.262638, 19.86375],
                [-16.262638, 19.864305000000172],
                [-16.262362, 19.864305000000172],
                [-16.262362, 19.865139000000113],
                [-16.262083, 19.865139000000113],
                [-16.262083, 19.865417],
                [-16.261806, 19.865417],
                [-16.261805, 19.866249],
                [-16.261528, 19.866249],
                [-16.261528, 19.866804000000172],
                [-16.26125, 19.866804000000172],
                [-16.26125, 19.867361],
                [-16.260975, 19.867361],
                [-16.260975, 19.86764],
                [-16.260695, 19.86764],
                [-16.260695, 19.868195000000185],
                [-16.26041599999985, 19.868195000000185],
                [-16.26041599999985, 19.86875],
                [-16.260139, 19.86875],
                [-16.260139, 19.86902600000019],
                [-16.259863, 19.86902600000019],
                [-16.259863, 19.869583],
                [-16.259584, 19.869583],
                [-16.259584, 19.869862],
                [-16.259306, 19.869862],
                [-16.259306, 19.87041900000014],
                [-16.259027, 19.87041900000014],
                [-16.259027, 19.870695],
                [-16.258752999999899, 19.870695],
                [-16.258752999999899, 19.871250000000146],
                [-16.258471, 19.871250000000146],
                [-16.258471, 19.871805],
                [-16.258192, 19.871805],
                [-16.258192, 19.872084],
                [-16.25791699999985, 19.872084],
                [-16.25791699999985, 19.872641000000158],
                [-16.257641, 19.872641000000158],
                [-16.257641, 19.873194],
                [-16.25736, 19.873194],
                [-16.25736, 19.874027],
                [-16.25708399999985, 19.874027],
                [-16.25708399999985, 19.875416],
                [-16.256805, 19.875416],
                [-16.256805, 19.87569400000018],
                [-16.256527, 19.87569400000018],
                [-16.256527, 19.876249],
                [-16.255972, 19.876249],
                [-16.255972, 19.876528],
                [-16.255695, 19.876528],
                [-16.255695, 19.876806000000158],
                [-16.255416999999852, 19.876806000000158],
                [-16.255416999999852, 19.877361],
                [-16.255138, 19.877361],
                [-16.255138, 19.877916000000141],
                [-16.254862, 19.877916000000141],
                [-16.254862, 19.879028000000119],
                [-16.254583, 19.879028000000119],
                [-16.254583, 19.879862],
                [-16.254305, 19.879862],
                [-16.254305, 19.881250000000136],
                [-16.254028, 19.881250000000136],
                [-16.254028, 19.882360000000119],
                [-16.254305, 19.882360000000119],
                [-16.254305, 19.884308],
                [-16.254583, 19.884308],
                [-16.254583, 19.884861000000114],
                [-16.254862, 19.884861000000114],
                [-16.254862, 19.885139],
                [-16.256248, 19.885139],
                [-16.256248, 19.885418],
                [-16.255972, 19.885418],
                [-16.255972, 19.885973000000149],
                [-16.255695, 19.885973000000149],
                [-16.255695, 19.886250000000132],
                [-16.255138, 19.886250000000132],
                [-16.255138, 19.886805],
                [-16.254862, 19.886806000000149],
                [-16.254862, 19.887083000000132],
                [-16.255138, 19.887083000000132],
                [-16.255138, 19.887917],
                [-16.255416999999852, 19.887917],
                [-16.255416999999852, 19.88819500000011],
                [-16.255695, 19.88819500000011],
                [-16.255695, 19.888472000000149],
                [-16.256248, 19.888472000000149],
                [-16.256248, 19.88875],
                [-16.25708399999985, 19.88875],
                [-16.25708399999985, 19.889029],
                [-16.25791699999985, 19.889029],
                [-16.25791699999985, 19.889305000000149],
                [-16.258752999999899, 19.889305000000149],
                [-16.258752999999899, 19.889584000000127],
                [-16.259306, 19.889584000000127],
                [-16.259306, 19.890139],
                [-16.259584, 19.890139],
                [-16.259584, 19.890972],
                [-16.259863, 19.890972],
                [-16.259863, 19.89152700000011],
                [-16.260139, 19.89152700000011],
                [-16.260139, 19.892361],
                [-16.26041599999985, 19.892361],
                [-16.26041599999985, 19.893194],
                [-16.260695, 19.893194],
                [-16.260695, 19.893749000000128],
                [-16.260975, 19.893749000000128],
                [-16.260975, 19.895414],
                [-16.26125, 19.895414],
                [-16.26125, 19.895697],
                [-16.261805, 19.895697],
                [-16.261806, 19.895971],
                [-16.26319699999982, 19.895971],
                [-16.26319699999982, 19.896250000000123],
                [-16.264029, 19.896250000000123],
                [-16.264029, 19.896526],
                [-16.26486, 19.896526],
                [-16.264862, 19.896805],
                [-16.265417, 19.896805],
                [-16.265417, 19.896526],
                [-16.266250999999897, 19.896526],
                [-16.266250999999897, 19.895414],
                [-16.265972, 19.895414],
                [-16.265972, 19.894304],
                [-16.266250999999897, 19.894304],
                [-16.266250999999897, 19.894028000000105],
                [-16.267637, 19.894028000000105],
                [-16.267637, 19.893749000000128],
                [-16.267916, 19.893749000000128],
                [-16.267917999999895, 19.893473],
                [-16.269859, 19.893473],
                [-16.269859, 19.894028000000105],
                [-16.270415999999898, 19.894028000000105],
                [-16.270415999999898, 19.894583000000182],
                [-16.270694999999819, 19.894583000000182],
                [-16.270694999999819, 19.895697],
                [-16.270969, 19.895697],
                [-16.270969, 19.896250000000123],
                [-16.27125199999989, 19.896250000000123],
                [-16.27125199999989, 19.896526],
                [-16.272081, 19.896526],
                [-16.272081, 19.896805],
                [-16.272364, 19.896805],
                [-16.272364, 19.898472],
                [-16.272081, 19.898472],
                [-16.272081, 19.898750000000177],
                [-16.271805, 19.898750000000177],
                [-16.271805, 19.899029],
                [-16.271527999999819, 19.899029],
                [-16.271527999999819, 19.899305],
                [-16.27125199999989, 19.899305],
                [-16.27125199999989, 19.899584000000118],
                [-16.270969, 19.899584000000118],
                [-16.270969, 19.90014100000019],
                [-16.27125199999989, 19.90014100000019],
                [-16.27125199999989, 19.900417000000118],
                [-16.271527999999819, 19.900417000000118],
                [-16.271523, 19.900991000000147],
                [-16.271805, 19.900972],
                [-16.271805, 19.901079000000152],
                [-16.271805, 19.901527000000101],
                [-16.272081, 19.901527000000101],
                [-16.272081, 19.90236300000015],
                [-16.272364, 19.90236300000015],
                [-16.272364, 19.902916000000118],
                [-16.272638, 19.902916000000118],
                [-16.272638, 19.90347300000019],
                [-16.272916999999893, 19.90347300000019],
                [-16.272916999999893, 19.90430600000019],
                [-16.273194999999873, 19.90430600000019],
                [-16.273194999999873, 19.905138],
                [-16.273474, 19.905138],
                [-16.273474, 19.905693],
                [-16.273749999999893, 19.905693],
                [-16.273749999999893, 19.90625],
                [-16.274026999999819, 19.90625],
                [-16.274026999999819, 19.906803],
                [-16.274305, 19.906803],
                [-16.274305, 19.907362],
                [-16.274585999999886, 19.907362],
                [-16.274585999999886, 19.907639],
                [-16.274859999999819, 19.907639],
                [-16.274859999999819, 19.907915000000173],
                [-16.275139, 19.907915000000173],
                [-16.275139, 19.908194000000151],
                [-16.275416999999891, 19.908194000000151],
                [-16.275416999999891, 19.908472],
                [-16.275696, 19.908472],
                [-16.275696, 19.908751],
                [-16.275972, 19.908751],
                [-16.275972, 19.909025],
                [-16.276250999999888, 19.909025],
                [-16.276250999999888, 19.909307],
                [-16.276526999999874, 19.909307],
                [-16.276526999999874, 19.910137000000191],
                [-16.276806, 19.910137000000191],
                [-16.276806, 19.910694],
                [-16.277639, 19.910694],
                [-16.277639, 19.910973],
                [-16.278193999999871, 19.910973],
                [-16.278193999999871, 19.910694],
                [-16.278473, 19.910694],
                [-16.278473, 19.910416000000168],
                [-16.278748999999891, 19.910416000000168],
                [-16.278748999999891, 19.910137000000191],
                [-16.279028, 19.910137000000191],
                [-16.279028, 19.909861],
                [-16.279306, 19.909861],
                [-16.279306, 19.909586],
                [-16.279582999999889, 19.909586],
                [-16.279582999999889, 19.909307],
                [-16.280136, 19.909307],
                [-16.280136, 19.909861],
                [-16.280972, 19.909861],
                [-16.280972, 19.910137000000191],
                [-16.281805, 19.910137000000191],
                [-16.281805, 19.909861],
                [-16.282359999999869, 19.909861],
                [-16.282359999999869, 19.909586],
                [-16.282639, 19.909586],
                [-16.282639, 19.909025],
                [-16.282917, 19.909025],
                [-16.282917, 19.908751],
                [-16.28347, 19.908751],
                [-16.28347, 19.908472],
                [-16.284306, 19.908472],
                [-16.284306, 19.908194000000151],
                [-16.284581999999887, 19.908194000000151],
                [-16.284581999999887, 19.907639],
                [-16.284863, 19.907639],
                [-16.284863, 19.907084000000168],
                [-16.285139, 19.907084000000168],
                [-16.285139, 19.906528000000151],
                [-16.285418, 19.906528000000151],
                [-16.285418, 19.905693],
                [-16.285691999999869, 19.905693],
                [-16.285691999999869, 19.904581000000178],
                [-16.285418, 19.904581000000178],
                [-16.285418, 19.90430600000019],
                [-16.285139, 19.90430600000019],
                [-16.285139, 19.90347300000019],
                [-16.284863, 19.90347300000019],
                [-16.284863, 19.903194],
                [-16.284581999999887, 19.903194],
                [-16.284581999999887, 19.902916000000118],
                [-16.283752, 19.902916000000118],
                [-16.283752, 19.90236300000015],
                [-16.28347, 19.90236300000015],
                [-16.28347, 19.901806],
                [-16.283752, 19.901806],
                [-16.283752, 19.900972],
                [-16.284027, 19.900972],
                [-16.284027, 19.900549000000183],
                [-16.284027, 19.900417000000118],
                [-16.284306, 19.900417000000118],
                [-16.284306, 19.89986],
                [-16.284581999999887, 19.89986],
                [-16.284581999999887, 19.899305],
                [-16.284863, 19.899305],
                [-16.284863, 19.899029],
                [-16.285139, 19.899029],
                [-16.285139, 19.898750000000177],
                [-16.285418, 19.898750000000177],
                [-16.285418, 19.897636],
                [-16.285691999999869, 19.897636],
                [-16.285691999999869, 19.897362000000101],
                [-16.285971, 19.897362000000101],
                [-16.285971, 19.897083000000123],
                [-16.286249, 19.897083000000123],
                [-16.286249, 19.896526],
                [-16.286806, 19.896526],
                [-16.286806, 19.896250000000123],
                [-16.287085, 19.896250000000123],
                [-16.287085, 19.895697],
                [-16.28764, 19.895697],
                [-16.28764, 19.896526],
                [-16.287361, 19.896526],
                [-16.287361, 19.897083000000123],
                [-16.287916, 19.897083000000123],
                [-16.287916, 19.896526],
                [-16.288195, 19.896526],
                [-16.288195, 19.895971],
                [-16.288473, 19.895971],
                [-16.288473, 19.895414],
                [-16.28875, 19.895414],
                [-16.28875, 19.894861000000105],
                [-16.289307, 19.894861000000105],
                [-16.289307, 19.894304],
                [-16.289583, 19.894304],
                [-16.289583, 19.894028000000105],
                [-16.289861999999857, 19.894028000000105],
                [-16.289861999999857, 19.893749000000128],
                [-16.290138, 19.893749000000128],
                [-16.290138, 19.892918000000122],
                [-16.290417, 19.892916000000127],
                [-16.290417, 19.892361],
                [-16.290694999999857, 19.892361],
                [-16.290694999999857, 19.891806],
                [-16.290972, 19.891806],
                [-16.290972, 19.891251000000182],
                [-16.290417, 19.891251000000182],
                [-16.290417, 19.89152700000011],
                [-16.289861999999857, 19.89152700000011],
                [-16.289861999999857, 19.892082],
                [-16.289307, 19.892082],
                [-16.289307, 19.892639],
                [-16.289028, 19.892639],
                [-16.289028, 19.892916000000127],
                [-16.28875, 19.892916000000127],
                [-16.28875, 19.893473],
                [-16.287916, 19.893473],
                [-16.287916, 19.892361],
                [-16.288195, 19.892361],
                [-16.288195, 19.891806],
                [-16.288473, 19.891806],
                [-16.288473, 19.89152700000011],
                [-16.28875, 19.89152700000011],
                [-16.28875, 19.891251000000182],
                [-16.289028, 19.891251000000182],
                [-16.289028, 19.89069400000011],
                [-16.289307, 19.89069400000011],
                [-16.289307, 19.890139],
                [-16.289583, 19.890139],
                [-16.289583, 19.889584000000127],
                [-16.289861999999857, 19.889584000000127],
                [-16.289861999999857, 19.889029],
                [-16.290138, 19.889029],
                [-16.290138, 19.88819500000011],
                [-16.290417, 19.88819500000011],
                [-16.290417, 19.887917],
                [-16.290694999999857, 19.887917],
                [-16.290694999999857, 19.887638],
                [-16.290972, 19.887638],
                [-16.290972, 19.88653],
                [-16.29125, 19.88653],
                [-16.29125, 19.886250000000132],
                [-16.291528999999855, 19.886250000000132],
                [-16.291528999999855, 19.885973000000149],
                [-16.291805, 19.885973000000149],
                [-16.291805, 19.885418],
                [-16.292084, 19.885418],
                [-16.292084, 19.884026000000119],
                [-16.29236, 19.884026000000119],
                [-16.29236, 19.883194],
                [-16.292639, 19.883194],
                [-16.292639, 19.882360000000119],
                [-16.292917, 19.882360000000119],
                [-16.292917, 19.882086],
                [-16.293193999999858, 19.882086],
                [-16.293193999999858, 19.881805],
                [-16.293472, 19.881805],
                [-16.293472, 19.881250000000136],
                [-16.293751, 19.881250000000136],
                [-16.293751, 19.880972],
                [-16.294026999999858, 19.880972],
                [-16.294026999999858, 19.880417000000136],
                [-16.294584, 19.880417000000136],
                [-16.294584, 19.880138000000159],
                [-16.294860999999855, 19.880138000000159],
                [-16.294860999999855, 19.879862],
                [-16.295139, 19.879862],
                [-16.295139, 19.879583],
                [-16.295416, 19.879583],
                [-16.295416, 19.879305000000159],
                [-16.295693999999855, 19.879305000000159],
                [-16.295693999999855, 19.879028000000119],
                [-16.295973, 19.879028000000119],
                [-16.295973, 19.87875],
                [-16.296249, 19.87875],
                [-16.296249, 19.878471],
                [-16.296806, 19.878471],
                [-16.296806, 19.878195000000119],
                [-16.297358999999858, 19.878195000000119],
                [-16.297358999999858, 19.877916000000141],
                [-16.297564, 19.877916000000141],
                [-16.297916, 19.877916000000141],
                [-16.297916, 19.87764],
                [-16.298473, 19.87764],
                [-16.298473, 19.877361],
                [-16.298752, 19.877361],
                [-16.298752, 19.877083000000141],
                [-16.300417, 19.877083000000141],
                [-16.300417, 19.876806000000158],
                [-16.300973999999826, 19.876806000000158],
                [-16.300973999999826, 19.877083000000141],
                [-16.302639, 19.877083000000141],
                [-16.302639, 19.877361],
                [-16.304028, 19.877361],
                [-16.304028, 19.87764],
                [-16.305139999999824, 19.87764],
                [-16.305139999999824, 19.877916000000141],
                [-16.306526, 19.877916000000141],
                [-16.306526, 19.878195000000119],
                [-16.307083, 19.878195000000119],
                [-16.307083, 19.87875],
                [-16.307361999999898, 19.87875],
                [-16.307361999999898, 19.879028000000119],
                [-16.307637999999827, 19.879028000000119],
                [-16.307637999999827, 19.879305000000159],
                [-16.307917, 19.879305000000159],
                [-16.307917, 19.879862],
                [-16.308194999999898, 19.879862],
                [-16.308194999999898, 19.880417000000136],
                [-16.308471999999824, 19.880417000000136],
                [-16.308471999999824, 19.880972],
                [-16.308748, 19.880972],
                [-16.308748, 19.881250000000136],
                [-16.309028999999896, 19.881250000000136],
                [-16.309028999999896, 19.882360000000119],
                [-16.309304999999824, 19.882360000000119],
                [-16.309304999999824, 19.882915],
                [-16.309584, 19.882915],
                [-16.309584, 19.883194],
                [-16.309858, 19.883194],
                [-16.309858, 19.883472000000154],
                [-16.310138999999879, 19.883472000000154],
                [-16.310138999999879, 19.883751000000132],
                [-16.310417, 19.883751000000132],
                [-16.310417, 19.884026000000119],
                [-16.310693999999899, 19.884026000000119],
                [-16.310693999999899, 19.884308],
                [-16.310969999999827, 19.884308],
                [-16.310969999999827, 19.885139],
                [-16.311253, 19.885139],
                [-16.311253, 19.885418],
                [-16.311526999999899, 19.885418],
                [-16.311526999999899, 19.885695],
                [-16.311805999999876, 19.885695],
                [-16.311805999999876, 19.885973000000149],
                [-16.312084, 19.885973000000149],
                [-16.312084, 19.886250000000132],
                [-16.312362999999891, 19.886250000000132],
                [-16.312362999999891, 19.886806000000149],
                [-16.312638999999876, 19.886806000000149],
                [-16.312638999999876, 19.887083000000132],
                [-16.312916, 19.887083000000132],
                [-16.312916, 19.88736200000011],
                [-16.312638999999876, 19.88736200000011],
                [-16.312638999999876, 19.88819500000011],
                [-16.312362999999891, 19.88819500000011],
                [-16.312362999999891, 19.889305000000149],
                [-16.312084, 19.889305000000149],
                [-16.312084, 19.890139],
                [-16.311805999999876, 19.890139],
                [-16.311805999999876, 19.890972],
                [-16.311526999999899, 19.890972],
                [-16.311526999999899, 19.892082],
                [-16.311253, 19.892082],
                [-16.311253, 19.892916000000127],
                [-16.310969999999827, 19.892916000000127],
                [-16.310969999999827, 19.894028000000105],
                [-16.310693999999899, 19.894028000000105],
                [-16.310693999999899, 19.894861000000105],
                [-16.310417, 19.894861000000105],
                [-16.310417, 19.895414],
                [-16.310138999999879, 19.895414],
                [-16.310138999999879, 19.895971],
                [-16.309858, 19.895971],
                [-16.309858, 19.896805],
                [-16.309584, 19.896805],
                [-16.309584, 19.897362000000101],
                [-16.309304999999824, 19.897362000000101],
                [-16.309304999999824, 19.897919000000172],
                [-16.309028999999896, 19.897919000000172],
                [-16.309028999999896, 19.898750000000177],
                [-16.308748, 19.898750000000177],
                [-16.308748, 19.89986],
                [-16.308471999999824, 19.89986],
                [-16.308471999999824, 19.900972],
                [-16.308194999999898, 19.900972],
                [-16.308194999999898, 19.901251000000173],
                [-16.308748, 19.901251000000173],
                [-16.308748, 19.902082000000178],
                [-16.309028999999896, 19.902082000000178],
                [-16.309028999999896, 19.902639],
                [-16.310333, 19.902639],
                [-16.310417, 19.902639],
                [-16.310417, 19.901527000000101],
                [-16.310693999999899, 19.901527000000101],
                [-16.310693999999899, 19.901806],
                [-16.310773999999867, 19.901806],
                [-16.310969999999827, 19.901806],
                [-16.310969999999827, 19.902082000000178],
                [-16.311253, 19.902082000000178],
                [-16.311253, 19.903194],
                [-16.310969999999827, 19.903194],
                [-16.310969999999827, 19.903749000000118],
                [-16.310693999999899, 19.903749000000118],
                [-16.310693999999899, 19.904028],
                [-16.310417, 19.904028],
                [-16.310417, 19.904581000000178],
                [-16.310138999999879, 19.904581000000178],
                [-16.310138999999879, 19.904861],
                [-16.309858, 19.904861],
                [-16.309858, 19.905416000000173],
                [-16.309584, 19.905416000000173],
                [-16.309584, 19.90625],
                [-16.309028999999896, 19.90625],
                [-16.309028999999896, 19.906528000000151],
                [-16.308748, 19.906528000000151],
                [-16.308748, 19.906803],
                [-16.308471999999824, 19.906803],
                [-16.308471999999824, 19.907084000000168],
                [-16.308194999999898, 19.907084000000168],
                [-16.308194999999898, 19.907362],
                [-16.307917, 19.907362],
                [-16.307917, 19.907639],
                [-16.307637999999827, 19.907639],
                [-16.307637999999827, 19.907915000000173],
                [-16.307361999999898, 19.907915000000173],
                [-16.307361999999898, 19.908194000000151],
                [-16.306803, 19.908192],
                [-16.306804999999827, 19.908472],
                [-16.306526, 19.90847],
                [-16.306526, 19.908751],
                [-16.306248, 19.908749],
                [-16.30625, 19.909025],
                [-16.305971, 19.909025],
                [-16.305972999999824, 19.909307],
                [-16.305693, 19.909307],
                [-16.305416, 19.909307],
                [-16.305416, 19.909586],
                [-16.305139999999824, 19.909586],
                [-16.305139999999824, 19.909861],
                [-16.304861, 19.909861],
                [-16.304861, 19.910137000000191],
                [-16.304583, 19.910137000000191],
                [-16.304583, 19.910416000000168],
                [-16.304307999999878, 19.910416000000168],
                [-16.304307999999878, 19.910694],
                [-16.304028, 19.910694],
                [-16.304028, 19.910973],
                [-16.303749, 19.910973],
                [-16.303749, 19.911247000000174],
                [-16.303472999999826, 19.911247000000174],
                [-16.303472999999826, 19.911530000000141],
                [-16.303196, 19.911530000000141],
                [-16.303196, 19.911806],
                [-16.303196, 19.912083],
                [-16.302917, 19.912083],
                [-16.302917, 19.912361000000146],
                [-16.302639, 19.912361000000146],
                [-16.302639, 19.912916],
                [-16.302086, 19.912918],
                [-16.302086, 19.913473000000124],
                [-16.301805, 19.913473000000124],
                [-16.301805, 19.913750000000164],
                [-16.301527, 19.913750000000164],
                [-16.301527, 19.914305],
                [-16.30125, 19.914305],
                [-16.30125, 19.914583000000164],
                [-16.300973999999826, 19.914583000000164],
                [-16.300973999999826, 19.914862000000142],
                [-16.300691999999856, 19.914862000000142],
                [-16.300691999999856, 19.915138],
                [-16.300417, 19.915138],
                [-16.300417, 19.915695000000142],
                [-16.300138, 19.915695000000142],
                [-16.300138, 19.915972000000124],
                [-16.299864, 19.915972000000124],
                [-16.299864, 19.91625],
                [-16.299582, 19.91625],
                [-16.299582, 19.916529],
                [-16.299303, 19.916529],
                [-16.299303, 19.916805000000124],
                [-16.299028, 19.916805000000124],
                [-16.299028, 19.917084000000159],
                [-16.298752, 19.917084000000159],
                [-16.298752, 19.917639],
                [-16.298473, 19.917639],
                [-16.298473, 19.917917000000159],
                [-16.298195, 19.917917000000159],
                [-16.298195, 19.918194000000142],
                [-16.297916, 19.918194000000142],
                [-16.297916, 19.918472],
                [-16.297638, 19.918472],
                [-16.297638, 19.918751],
                [-16.297358999999858, 19.918751],
                [-16.297358999999858, 19.919027000000142],
                [-16.297083, 19.919027000000142],
                [-16.297083, 19.91930600000012],
                [-16.296806, 19.91930600000012],
                [-16.296806, 19.919582],
                [-16.296528, 19.919582],
                [-16.296528, 19.919861],
                [-16.296249, 19.919861],
                [-16.296249, 19.920416000000159],
                [-16.295973, 19.920416000000159],
                [-16.295973, 19.920694],
                [-16.295693999999855, 19.920694],
                [-16.295693999999855, 19.921249000000159],
                [-16.295416, 19.921249000000159],
                [-16.295416, 19.921528000000137],
                [-16.295139, 19.921528000000137],
                [-16.295139, 19.921804],
                [-16.294860999999855, 19.921804],
                [-16.294860999999855, 19.922083],
                [-16.294584, 19.922083],
                [-16.294582, 19.922361000000137],
                [-16.294306, 19.922361000000137],
                [-16.294306, 19.92263800000012],
                [-16.294026999999858, 19.92263800000012],
                [-16.294026999999858, 19.922918],
                [-16.293751, 19.922918],
                [-16.293751, 19.923197000000187],
                [-16.293193999999858, 19.923197000000187],
                [-16.293193999999858, 19.92347100000012],
                [-16.292917, 19.92347100000012],
                [-16.292917, 19.923750000000155],
                [-16.292639, 19.923750000000155],
                [-16.292639, 19.924026],
                [-16.29236, 19.924026],
                [-16.29236, 19.924307],
                [-16.292084, 19.924307],
                [-16.292084, 19.924583000000155],
                [-16.291528999999855, 19.924583000000155],
                [-16.291528999999855, 19.924862000000132],
                [-16.29125, 19.924862000000132],
                [-16.29125, 19.92513700000012],
                [-16.290972, 19.92513700000012],
                [-16.290972, 19.925419],
                [-16.290694999999857, 19.925419],
                [-16.290694999999857, 19.925694],
                [-16.290138, 19.925694],
                [-16.290138, 19.925972000000115],
                [-16.289861999999857, 19.925972000000115],
                [-16.289861999999857, 19.92625],
                [-16.289307, 19.92625],
                [-16.289307, 19.926529000000187],
                [-16.28875, 19.926529000000187],
                [-16.28875, 19.926805000000115],
                [-16.288230999999826, 19.926805000000115],
                [-16.287916, 19.926805000000115],
                [-16.287933, 19.927101000000107],
                [-16.286528, 19.927084],
                [-16.286528, 19.926805000000115],
                [-16.285971, 19.926805000000115],
                [-16.285971, 19.927084],
                [-16.285418, 19.927084],
                [-16.285418, 19.927361000000133],
                [-16.285139, 19.927361000000133],
                [-16.285139, 19.927641],
                [-16.284863, 19.927641],
                [-16.284863, 19.927917],
                [-16.284581999999887, 19.927917],
                [-16.284581999999887, 19.928194000000133],
                [-16.284306, 19.928194000000133],
                [-16.284306, 19.928749],
                [-16.282917, 19.928749],
                [-16.282917, 19.929028000000187],
                [-16.282359999999869, 19.929028000000187],
                [-16.282359999999869, 19.929306000000111],
                [-16.281528, 19.929306000000111],
                [-16.281528, 19.929583],
                [-16.280972, 19.929583],
                [-16.280972, 19.929861000000187],
                [-16.280695, 19.929861000000187],
                [-16.280695, 19.93014],
                [-16.280415999999889, 19.93014],
                [-16.280415999999889, 19.930416],
                [-16.280136, 19.930416],
                [-16.280136, 19.930695000000128],
                [-16.279582999999889, 19.930695000000128],
                [-16.279582999999889, 19.930971],
                [-16.279306, 19.930971],
                [-16.279306, 19.93125],
                [-16.279028, 19.93125],
                [-16.279028, 19.931528000000128],
                [-16.278473, 19.931528000000128],
                [-16.278473, 19.931805000000111],
                [-16.277639, 19.931805000000111],
                [-16.277639, 19.932083],
                [-16.277360999999871, 19.932083],
                [-16.277360999999871, 19.932362000000182],
                [-16.276806, 19.932362000000182],
                [-16.276806, 19.932638000000111],
                [-16.275972, 19.932638000000111],
                [-16.275972, 19.932917],
                [-16.275696, 19.932917],
                [-16.275696, 19.933193000000188],
                [-16.275416999999891, 19.933193000000188],
                [-16.275416999999891, 19.933472],
                [-16.275139, 19.933472],
                [-16.275139, 19.93375],
                [-16.274859999999819, 19.93375],
                [-16.274859999999819, 19.934029000000123],
                [-16.274585999999886, 19.934029000000123],
                [-16.274585999999886, 19.934860000000128],
                [-16.274859999999819, 19.934860000000128],
                [-16.274859999999819, 19.935415],
                [-16.273749999999893, 19.935415],
                [-16.273749999999893, 19.935694000000183],
                [-16.273474, 19.935694000000183],
                [-16.273474, 19.936251],
                [-16.272916999999893, 19.936251],
                [-16.272916999999893, 19.936525000000188],
                [-16.272364, 19.936525000000188],
                [-16.272364, 19.936808000000156],
                [-16.271805, 19.936808000000156],
                [-16.271805, 19.937084],
                [-16.271527999999819, 19.937084],
                [-16.271527999999819, 19.937361000000124],
                [-16.27125199999989, 19.937361000000124],
                [-16.27125199999989, 19.937637],
                [-16.270694999999819, 19.937637],
                [-16.270694999999819, 19.937918],
                [-16.270142, 19.937918],
                [-16.270142, 19.938194000000124],
                [-16.269582999999898, 19.938194000000124],
                [-16.269582999999898, 19.938473000000101],
                [-16.269306, 19.938473000000101],
                [-16.269306, 19.938747],
                [-16.269027999999821, 19.938747],
                [-16.269027999999821, 19.939030000000173],
                [-16.268749, 19.939030000000173],
                [-16.268749, 19.939583],
                [-16.268473, 19.939583],
                [-16.268473, 19.940140000000156],
                [-16.268194, 19.940140000000156],
                [-16.268194, 19.940416],
                [-16.267916, 19.940416],
                [-16.267916, 19.940971],
                [-16.267361, 19.940971],
                [-16.267361, 19.94125200000019],
                [-16.267083999999898, 19.94125200000019],
                [-16.267083999999898, 19.941528],
                [-16.266527, 19.941528],
                [-16.266527, 19.941805000000102],
                [-16.265972, 19.941805000000102],
                [-16.265972, 19.942083],
                [-16.265694, 19.942083],
                [-16.265694, 19.942362000000173],
                [-16.265417, 19.942362000000173],
                [-16.265417, 19.942638000000102],
                [-16.265139, 19.942638000000102],
                [-16.265139, 19.942917],
                [-16.264862, 19.942917],
                [-16.26486, 19.943195000000173],
                [-16.264584, 19.943195000000173],
                [-16.264584, 19.943472000000156],
                [-16.264305, 19.943472000000156],
                [-16.264305, 19.94375],
                [-16.264029, 19.94375],
                [-16.264029, 19.944027],
                [-16.26375, 19.944027],
                [-16.26375, 19.944305000000156],
                [-16.263472, 19.944305000000156],
                [-16.263472, 19.944584000000191],
                [-16.262917, 19.944584000000191],
                [-16.262917, 19.94486],
                [-16.262638, 19.94486],
                [-16.262638, 19.945139],
                [-16.262362, 19.945139],
                [-16.262362, 19.945417000000191],
                [-16.261806, 19.945417000000191],
                [-16.261805, 19.945692000000179],
                [-16.261528, 19.945692000000179],
                [-16.261528, 19.945972],
                [-16.260975, 19.945972],
                [-16.260975, 19.946251],
                [-16.260695, 19.946251],
                [-16.260695, 19.946527000000174],
                [-16.26041599999985, 19.946527000000174],
                [-16.26041599999985, 19.946804000000157],
                [-16.260139, 19.946804000000157],
                [-16.260139, 19.947083000000191],
                [-16.259584, 19.947083000000191],
                [-16.259584, 19.947361],
                [-16.259306, 19.947361],
                [-16.259306, 19.947639000000152],
                [-16.258752999999899, 19.947639000000152],
                [-16.258752999999899, 19.947914],
                [-16.258471, 19.947914],
                [-16.258471, 19.948195000000169],
                [-16.258192, 19.948195000000169],
                [-16.258192, 19.948473],
                [-16.25791699999985, 19.948473],
                [-16.25791699999985, 19.94875],
                [-16.25736, 19.94875],
                [-16.25736, 19.949026000000174],
                [-16.256805, 19.949026000000174],
                [-16.256805, 19.949305000000152],
                [-16.256527, 19.949305000000152],
                [-16.256527, 19.949583],
                [-16.256248, 19.949583],
                [-16.256248, 19.949862],
                [-16.255972, 19.949862],
                [-16.255972, 19.950136000000157],
                [-16.254862, 19.950136000000157],
                [-16.254862, 19.950418],
                [-16.254583, 19.950418],
                [-16.254583, 19.950697],
                [-16.254305, 19.950697],
                [-16.254305, 19.950972],
                [-16.253471, 19.950972],
                [-16.253471, 19.951248000000191],
                [-16.252916, 19.951248000000191],
                [-16.252916, 19.951529000000164],
                [-16.25264, 19.951529000000164],
                [-16.25264, 19.951805],
                [-16.252361, 19.951805],
                [-16.252361, 19.952084],
                [-16.252083, 19.952084],
                [-16.252083, 19.952358000000174],
                [-16.251806, 19.952362000000164],
                [-16.251806, 19.952641000000142],
                [-16.251528, 19.952641000000142],
                [-16.251528, 19.952917],
                [-16.251249, 19.952917],
                [-16.251249, 19.953472000000147],
                [-16.250694, 19.953472000000147],
                [-16.250694, 19.953751000000125],
                [-16.250416, 19.953751000000125],
                [-16.250416, 19.954027],
                [-16.250139, 19.954027],
                [-16.250139, 19.954584000000125],
                [-16.249861, 19.954584000000125],
                [-16.249861, 19.954861000000164],
                [-16.249584, 19.954861000000164],
                [-16.249584, 19.955139],
                [-16.249306, 19.955139],
                [-16.249306, 19.955416],
                [-16.248472, 19.955416],
                [-16.248472, 19.955694000000165],
                [-16.247639, 19.955694000000165],
                [-16.247639, 19.955973000000142],
                [-16.24736, 19.955973000000142],
                [-16.24736, 19.956249],
                [-16.247084, 19.956249],
                [-16.247084, 19.956528],
                [-16.246804999999881, 19.956528],
                [-16.246804999999881, 19.956806000000142],
                [-16.24625, 19.956806000000142],
                [-16.24625, 19.957083000000125],
                [-16.245974, 19.957083000000125],
                [-16.245974, 19.957361],
                [-16.245695, 19.957361],
                [-16.245695, 19.95764],
                [-16.245417, 19.95764],
                [-16.245417, 19.957916000000125],
                [-16.245137999999884, 19.957916000000125],
                [-16.245137999999884, 19.958471],
                [-16.244864, 19.958471],
                [-16.244864, 19.95875],
                [-16.244304999999883, 19.95875],
                [-16.244304999999883, 19.95902800000016],
                [-16.244028, 19.95902800000016],
                [-16.244028, 19.959305000000143],
                [-16.24375, 19.959305000000143],
                [-16.24375, 19.959583],
                [-16.242916, 19.959583],
                [-16.242916, 19.959862],
                [-16.242641, 19.959862],
                [-16.242641, 19.960138000000143],
                [-16.242083, 19.960138000000143],
                [-16.242083, 19.96041700000012],
                [-16.241805999999883, 19.96041700000012],
                [-16.241805999999883, 19.960693],
                [-16.241528, 19.960693],
                [-16.241528, 19.960972],
                [-16.240694, 19.960972],
                [-16.240694, 19.961250000000121],
                [-16.240136999999891, 19.961250000000121],
                [-16.240136999999891, 19.96152700000016],
                [-16.239861, 19.96152700000016],
                [-16.239861, 19.961805],
                [-16.239584, 19.961805],
                [-16.239584, 19.962084],
                [-16.239304999999888, 19.962084],
                [-16.239304999999888, 19.96236000000016],
                [-16.239029, 19.96236000000016],
                [-16.239029, 19.963472000000138],
                [-16.238194, 19.963472000000138],
                [-16.238194, 19.963751000000116],
                [-16.23763799999989, 19.963751000000116],
                [-16.23763799999989, 19.964025],
                [-16.237362, 19.964025],
                [-16.237362, 19.964308000000187],
                [-16.237082999999814, 19.964308000000187],
                [-16.237082999999814, 19.964582000000121],
                [-16.236806999999885, 19.964582000000121],
                [-16.236806999999885, 19.964861],
                [-16.236528, 19.964861],
                [-16.236528, 19.965139],
                [-16.236249999999814, 19.965139],
                [-16.236249999999814, 19.965418],
                [-16.235970999999893, 19.965418],
                [-16.235970999999893, 19.965694],
                [-16.235697, 19.965694],
                [-16.235697, 19.965973000000133],
                [-16.235137999999893, 19.965973000000133],
                [-16.235137999999893, 19.966248000000121],
                [-16.234861, 19.966248000000121],
                [-16.234861, 19.96653],
                [-16.234585, 19.96653],
                [-16.234585, 19.966806000000133],
                [-16.23430599999989, 19.966806000000133],
                [-16.23430599999989, 19.967083000000116],
                [-16.234028, 19.967083000000116],
                [-16.234028, 19.967640000000188],
                [-16.233749, 19.967640000000188],
                [-16.233749, 19.967916000000116],
                [-16.233474999999885, 19.967916000000116],
                [-16.233474999999885, 19.968752],
                [-16.233194, 19.968752],
                [-16.233194, 19.969305000000134],
                [-16.232916, 19.969305000000134],
                [-16.232916, 19.970139000000188],
                [-16.232638999999892, 19.970139000000188],
                [-16.232638999999892, 19.970694],
                [-16.232363, 19.970694],
                [-16.232363, 19.971251],
                [-16.232083999999816, 19.971251],
                [-16.23208, 19.971806000000129],
                [-16.231805999999892, 19.971806000000129],
                [-16.231805999999892, 19.972082],
                [-16.231527, 19.972082],
                [-16.231527, 19.973473000000183],
                [-16.231253, 19.973473000000183],
                [-16.231253, 19.974028],
                [-16.23097, 19.974028],
                [-16.23097, 19.974304000000188],
                [-16.230694, 19.974304000000188],
                [-16.230694, 19.974861],
                [-16.230417, 19.974861],
                [-16.230417, 19.975695],
                [-16.230138999999895, 19.975695],
                [-16.230138999999895, 19.976526],
                [-16.22986, 19.976526],
                [-16.22986, 19.978472],
                [-16.229584, 19.978472],
                [-16.229584, 19.978750000000161],
                [-16.229304999999897, 19.978750000000161],
                [-16.229304999999897, 19.979584000000102],
                [-16.229031, 19.979584000000102],
                [-16.229031, 19.981251000000157],
                [-16.228748, 19.981251000000157],
                [-16.228748, 19.982363000000134],
                [-16.228471999999897, 19.982363000000134],
                [-16.228471999999897, 19.982916000000102],
                [-16.228195, 19.982916000000102],
                [-16.228195, 19.983749000000103],
                [-16.227917, 19.983749000000103],
                [-16.227917, 19.984306000000174],
                [-16.227637999999899, 19.984306000000174],
                [-16.227637999999899, 19.984583000000157],
                [-16.227362, 19.984583000000157],
                [-16.227362, 19.984861],
                [-16.227083, 19.984861],
                [-16.227083, 19.985416000000157],
                [-16.226804999999899, 19.985416000000157],
                [-16.226804999999899, 19.985971],
                [-16.226526, 19.985971],
                [-16.226526, 19.986528000000135],
                [-16.22625, 19.986528000000135],
                [-16.22625, 19.98680300000018],
                [-16.225972999999897, 19.98680300000018],
                [-16.225972999999897, 19.987083],
                [-16.225416, 19.987083],
                [-16.225416, 19.987362],
                [-16.225139999999897, 19.987362],
                [-16.225139999999897, 19.988194000000135],
                [-16.224861, 19.988194000000135],
                [-16.224861, 19.989025],
                [-16.224583, 19.989025],
                [-16.224583, 19.989861],
                [-16.224305999999899, 19.989861],
                [-16.224305999999899, 19.990416000000153],
                [-16.224028, 19.990416000000153],
                [-16.224028, 19.990694],
                [-16.223749, 19.990694],
                [-16.223749, 19.992083],
                [-16.224028, 19.992083],
                [-16.224028, 19.992916],
                [-16.223749, 19.992916],
                [-16.223749, 19.995138],
                [-16.224028, 19.995138],
                [-16.224028, 19.995695000000126],
                [-16.224305999999899, 19.995695000000126],
                [-16.224305999999899, 19.995972000000165],
                [-16.224583, 19.995972000000165],
                [-16.224583, 19.99625],
                [-16.225416, 19.99625],
                [-16.225416, 19.996529],
                [-16.225695, 19.996529],
                [-16.225695, 19.997084000000143],
                [-16.22625, 19.997084000000143],
                [-16.22625, 19.99736],
                [-16.226526, 19.99736],
                [-16.226526, 19.997639],
                [-16.226804999999899, 19.997639],
                [-16.226804999999899, 19.997917000000143],
                [-16.227083, 19.997917000000143],
                [-16.227083, 19.998194000000126],
                [-16.227362, 19.998194000000126],
                [-16.227362, 19.998472],
                [-16.227637999999899, 19.998472],
                [-16.227637999999899, 19.999027000000126],
                [-16.227917, 19.999027000000126],
                [-16.227917, 19.999306000000104],
                [-16.228195, 19.999306000000104],
                [-16.228195, 19.999582],
                [-16.228748, 19.999582],
                [-16.228748, 20.000139000000104],
                [-16.229031, 20.000139000000104],
                [-16.229031, 20.000416000000143],
                [-16.229304999999897, 20.000416000000143],
                [-16.229304999999897, 20.000694],
                [-16.22986, 20.000694],
                [-16.22986, 20.001249000000143],
                [-16.230138999999895, 20.001249000000143],
                [-16.230138999999895, 20.001804],
                [-16.230417, 20.001804],
                [-16.230417, 20.003471000000104],
                [-16.230694, 20.003471000000104],
                [-16.230694, 20.004026],
                [-16.23097, 20.004026],
                [-16.23097, 20.005695000000117],
                [-16.231253, 20.005695000000117],
                [-16.231253, 20.00625],
                [-16.231527, 20.00625],
                [-16.231527, 20.009306],
                [-16.231805999999892, 20.009306],
                [-16.231805999999892, 20.011528000000112],
                [-16.23208, 20.011528000000112],
                [-16.23208, 20.014303],
                [-16.231805999999892, 20.014303],
                [-16.231805999999892, 20.014860000000112],
                [-16.231527, 20.014862000000107],
                [-16.231527, 20.015415000000189],
                [-16.231253, 20.015415000000189],
                [-16.231253, 20.015972],
                [-16.23097, 20.015972],
                [-16.23097, 20.016525],
                [-16.230694, 20.016525],
                [-16.230694, 20.017084],
                [-16.230417, 20.017084],
                [-16.230417, 20.017637],
                [-16.230138999999895, 20.017637],
                [-16.230138999999895, 20.02014],
                [-16.230417, 20.02014],
                [-16.230417, 20.022362000000157],
                [-16.230694, 20.022362000000157],
                [-16.230694, 20.026806000000136],
                [-16.23097, 20.026806000000136],
                [-16.23097, 20.027361],
                [-16.231253, 20.027361],
                [-16.231253, 20.02791400000018],
                [-16.231527, 20.02791400000018],
                [-16.231527, 20.028473],
                [-16.231805999999892, 20.028473],
                [-16.231805999999892, 20.029861000000153],
                [-16.23208, 20.029861000000153],
                [-16.23208, 20.032919],
                [-16.231805999999892, 20.032919],
                [-16.231805999999892, 20.034306],
                [-16.231527, 20.034306],
                [-16.231527, 20.034861000000149],
                [-16.231253, 20.034861000000149],
                [-16.231253, 20.035418],
                [-16.23097, 20.035418],
                [-16.23097, 20.035973000000126],
                [-16.230694, 20.035973000000126],
                [-16.230694, 20.036249],
                [-16.230417, 20.036249],
                [-16.230417, 20.036806000000126],
                [-16.230138999999895, 20.036806000000126],
                [-16.230138999999895, 20.037083000000109],
                [-16.22986, 20.037083000000109],
                [-16.22986, 20.037916000000109],
                [-16.229584, 20.037916000000109],
                [-16.229584, 20.039028000000144],
                [-16.229304999999897, 20.039028000000144],
                [-16.229304999999897, 20.039862],
                [-16.229031, 20.039862],
                [-16.229031, 20.040138000000127],
                [-16.228748, 20.040138000000127],
                [-16.228748, 20.040693],
                [-16.228471999999897, 20.040693],
                [-16.228471999999897, 20.041250000000105],
                [-16.228195, 20.041250000000105],
                [-16.228195, 20.041529000000139],
                [-16.227917, 20.041529000000139],
                [-16.227917, 20.041805],
                [-16.227637999999899, 20.041805],
                [-16.227637999999899, 20.042360000000144],
                [-16.227362, 20.042360000000144],
                [-16.227362, 20.042915],
                [-16.227083, 20.042915],
                [-16.227083, 20.043194],
                [-16.226804999999899, 20.043194],
                [-16.226804999999899, 20.043472000000122],
                [-16.226526, 20.043472000000122],
                [-16.226526, 20.044025],
                [-16.22625, 20.044025],
                [-16.22625, 20.044308000000171],
                [-16.225972999999897, 20.044308000000171],
                [-16.225972999999897, 20.044861000000139],
                [-16.225695, 20.044861000000139],
                [-16.225695, 20.045137],
                [-16.225416, 20.045137],
                [-16.225416, 20.04569400000014],
                [-16.225139999999897, 20.04569400000014],
                [-16.225139999999897, 20.045973000000117],
                [-16.224861, 20.045973000000117],
                [-16.224861, 20.047640000000172],
                [-16.224583, 20.047640000000172],
                [-16.224583, 20.049583],
                [-16.224305999999899, 20.049583],
                [-16.224305999999899, 20.050138000000118],
                [-16.224028, 20.050138000000118],
                [-16.224028, 20.050417],
                [-16.223749, 20.050417],
                [-16.223749, 20.050694],
                [-16.223472999999899, 20.050694],
                [-16.223472999999899, 20.050974000000167],
                [-16.223194, 20.050974000000167],
                [-16.223194, 20.05125],
                [-16.222638999999845, 20.05125],
                [-16.222638999999845, 20.051527],
                [-16.222361, 20.051527],
                [-16.222361, 20.05236100000019],
                [-16.222638999999845, 20.05236100000019],
                [-16.222638999999845, 20.052917],
                [-16.222918, 20.052917],
                [-16.222918, 20.053192],
                [-16.223194, 20.053192],
                [-16.223194, 20.053473000000167],
                [-16.223472999999899, 20.053473000000167],
                [-16.223472999999899, 20.053749],
                [-16.224028, 20.053749],
                [-16.224028, 20.054028],
                [-16.225139999999897, 20.054028],
                [-16.225139999999897, 20.054304000000172],
                [-16.232638999999892, 20.054304000000172],
                [-16.232638999999892, 20.054583],
                [-16.232916, 20.054583],
                [-16.232916, 20.054861],
                [-16.233194, 20.054861],
                [-16.233194, 20.055140000000108],
                [-16.234028, 20.055140000000108],
                [-16.234028, 20.055414],
                [-16.23430599999989, 20.055414],
                [-16.23430599999989, 20.055695000000185],
                [-16.234585, 20.055695000000185],
                [-16.234585, 20.05625],
                [-16.234861, 20.05625],
                [-16.234861, 20.05652600000019],
                [-16.235137999999893, 20.05652600000019],
                [-16.235137999999893, 20.056805000000168],
                [-16.235415999999816, 20.056805000000168],
                [-16.235415999999816, 20.057083],
                [-16.235970999999893, 20.057083],
                [-16.235970999999893, 20.057362],
                [-16.236249999999814, 20.057362],
                [-16.236249999999814, 20.057636000000173],
                [-16.237082999999814, 20.057636000000173],
                [-16.237082999999814, 20.05791900000014],
                [-16.23763799999989, 20.05791900000014],
                [-16.23763799999989, 20.058195],
                [-16.237917, 20.058195],
                [-16.237917, 20.058748],
                [-16.238194, 20.058748],
                [-16.238194, 20.05902900000018],
                [-16.238471999999888, 20.05902900000018],
                [-16.238471999999888, 20.059305000000109],
                [-16.23875, 20.059305000000109],
                [-16.23875, 20.059584],
                [-16.239029, 20.059584],
                [-16.239029, 20.060141000000158],
                [-16.239304999999888, 20.060141000000158],
                [-16.239304999999888, 20.060972000000163],
                [-16.239584, 20.060972000000163],
                [-16.239584, 20.061527],
                [-16.239861, 20.061527],
                [-16.239861, 20.068195],
                [-16.240136999999891, 20.068195],
                [-16.240136999999891, 20.068472],
                [-16.240417, 20.068472],
                [-16.240417, 20.072639],
                [-16.239861, 20.072639],
                [-16.239861, 20.072918],
                [-16.239584, 20.072918],
                [-16.239584, 20.073195],
                [-16.239304999999888, 20.073195],
                [-16.239304999999888, 20.073473000000149],
                [-16.239029, 20.073473000000149],
                [-16.239029, 20.073748000000137],
                [-16.238471999999888, 20.073748000000137],
                [-16.238471999999888, 20.07403],
                [-16.238194, 20.07403],
                [-16.238194, 20.074305],
                [-16.237917, 20.074306000000149],
                [-16.237917, 20.074583000000132],
                [-16.237362, 20.074583000000132],
                [-16.237362, 20.07486200000011],
                [-16.237082999999814, 20.07486200000011],
                [-16.237082999999814, 20.075138],
                [-16.236806999999885, 20.075138],
                [-16.236806999999885, 20.07569500000011],
                [-16.236528, 20.07569500000011],
                [-16.236528, 20.07625],
                [-16.236249999999814, 20.07625],
                [-16.236249999999814, 20.077084000000127],
                [-16.235970999999893, 20.077084000000127],
                [-16.235970999999893, 20.079306000000145],
                [-16.236249999999814, 20.079306000000145],
                [-16.236249999999814, 20.079861],
                [-16.236528, 20.079861],
                [-16.236528, 20.08264],
                [-16.236249999999814, 20.08264],
                [-16.236249999999814, 20.084583000000123],
                [-16.236528, 20.084583000000123],
                [-16.236528, 20.084862000000101],
                [-16.236806999999885, 20.084862000000101],
                [-16.236806999999885, 20.085136],
                [-16.237362, 20.085136],
                [-16.237362, 20.085419000000172],
                [-16.238194, 20.085419000000172],
                [-16.238194, 20.085695000000101],
                [-16.240136999999891, 20.085695000000101],
                [-16.240136999999891, 20.085972],
                [-16.244028, 20.085972],
                [-16.244028, 20.085695000000101],
                [-16.244581, 20.085695000000101],
                [-16.244581, 20.085419000000172],
                [-16.245137999999884, 20.085419000000172],
                [-16.245137999999884, 20.085136],
                [-16.245417, 20.085136],
                [-16.245417, 20.084862000000101],
                [-16.245974, 20.084862000000101],
                [-16.245974, 20.085136],
                [-16.246529, 20.085136],
                [-16.246529, 20.085695000000101],
                [-16.246804999999881, 20.085695000000101],
                [-16.246804999999881, 20.085972],
                [-16.247639, 20.085972],
                [-16.247639, 20.086805],
                [-16.247917, 20.086805],
                [-16.247917, 20.087358],
                [-16.248196, 20.087358],
                [-16.248196, 20.089582000000178],
                [-16.248425999999881, 20.089569],
                [-16.248472, 20.090139],
                [-16.248751, 20.090139],
                [-16.248751, 20.09180600000019],
                [-16.249027, 20.09180600000019],
                [-16.249027, 20.092916000000173],
                [-16.249306, 20.092916000000173],
                [-16.249306, 20.093193],
                [-16.249584, 20.093193],
                [-16.249584, 20.093750000000114],
                [-16.249861, 20.093750000000114],
                [-16.249861, 20.094862],
                [-16.250139, 20.094862],
                [-16.250139, 20.097086],
                [-16.249861, 20.097086],
                [-16.249861, 20.100416],
                [-16.250139, 20.100416],
                [-16.250139, 20.101528],
                [-16.250416, 20.101528],
                [-16.250416, 20.102083000000164],
                [-16.250694, 20.102083000000164],
                [-16.250694, 20.102362000000142],
                [-16.250973, 20.102362000000142],
                [-16.250973, 20.102638],
                [-16.251249, 20.102638],
                [-16.251249, 20.103195000000142],
                [-16.251528, 20.103195000000142],
                [-16.251528, 20.10375],
                [-16.251806, 20.10375],
                [-16.251806, 20.10486],
                [-16.252083, 20.10486],
                [-16.252083, 20.105139],
                [-16.252361, 20.105139],
                [-16.252361, 20.105417000000159],
                [-16.252916, 20.105417000000159],
                [-16.252916, 20.105694000000142],
                [-16.253313, 20.105694000000142],
                [-16.25375, 20.105694000000142],
                [-16.25375, 20.106251],
                [-16.254028, 20.106251],
                [-16.254028, 20.106527000000142],
                [-16.255416999999852, 20.106527000000142],
                [-16.255416999999852, 20.10680600000012],
                [-16.255695, 20.10680600000012],
                [-16.255695, 20.107084],
                [-16.255972, 20.107084],
                [-16.255972, 20.108749000000159],
                [-16.256248, 20.108749000000159],
                [-16.256248, 20.109028000000137],
                [-16.255972, 20.109028000000137],
                [-16.255972, 20.111250000000155],
                [-16.256248, 20.111250000000155],
                [-16.256248, 20.111526],
                [-16.256527, 20.111526],
                [-16.256531, 20.112083000000155],
                [-16.256805, 20.112083000000155],
                [-16.256805, 20.112375000000156],
                [-16.256805, 20.11263700000012],
                [-16.25708399999985, 20.112669000000153],
                [-16.25708399999985, 20.112919],
                [-16.25736, 20.112961000000155],
                [-16.25736, 20.113472000000115],
                [-16.257641, 20.113472000000115],
                [-16.257641, 20.114859000000138],
                [-16.25791699999985, 20.114859000000138],
                [-16.25791699999985, 20.115694000000133],
                [-16.258192, 20.115694000000133],
                [-16.258192, 20.11597300000011],
                [-16.258471, 20.11597300000011],
                [-16.258471, 20.116528],
                [-16.258752999999899, 20.116528],
                [-16.258752999999899, 20.116806000000111],
                [-16.259306, 20.116806000000111],
                [-16.259306, 20.11708300000015],
                [-16.260139, 20.11708300000015],
                [-16.260139, 20.117361],
                [-16.26041599999985, 20.117361],
                [-16.26041599999985, 20.119028000000128],
                [-16.260695, 20.119028000000128],
                [-16.260695, 20.119305000000111],
                [-16.260975, 20.119305000000111],
                [-16.260975, 20.119583],
                [-16.262083, 20.119583],
                [-16.262083, 20.119305000000111],
                [-16.262362, 20.119305000000111],
                [-16.262362, 20.119028000000128],
                [-16.262526, 20.119028000000128],
                [-16.262638, 20.119028000000128],
                [-16.262638, 20.11875],
                [-16.262917, 20.11875],
                [-16.262917, 20.118471],
                [-16.263472, 20.118471],
                [-16.263472, 20.11875],
                [-16.264305, 20.11875],
                [-16.264305, 20.119305000000111],
                [-16.264584, 20.119305000000111],
                [-16.264584, 20.119583],
                [-16.26486, 20.119583],
                [-16.264862, 20.120138000000111],
                [-16.265139, 20.120138000000111],
                [-16.265139, 20.121805],
                [-16.264862, 20.121805],
                [-16.26486, 20.122639000000106],
                [-16.264584, 20.122639000000106],
                [-16.264584, 20.124308],
                [-16.26486, 20.124308],
                [-16.26486, 20.125137],
                [-16.264305, 20.125137],
                [-16.264305, 20.125416],
                [-16.264029, 20.125416],
                [-16.264029, 20.126530000000173],
                [-16.26375, 20.126530000000173],
                [-16.26375, 20.127361000000178],
                [-16.263472, 20.127361000000178],
                [-16.263472, 20.127642000000151],
                [-16.26319699999982, 20.127642000000151],
                [-16.26319699999982, 20.127916],
                [-16.262917, 20.127916],
                [-16.262917, 20.128195000000119],
                [-16.26264, 20.128195000000119],
                [-16.262648, 20.12875200000019],
                [-16.262362, 20.12875200000019],
                [-16.262362, 20.12903],
                [-16.262362, 20.129862000000173],
                [-16.261806, 20.129862000000173],
                [-16.261805, 20.130138000000102],
                [-16.26125, 20.130138000000102],
                [-16.26125, 20.130417],
                [-16.260139, 20.130417],
                [-16.260139, 20.130138000000102],
                [-16.259584, 20.130138000000102],
                [-16.259571, 20.129536],
                [-16.259306, 20.129583],
                [-16.259306, 20.129305000000102],
                [-16.258698, 20.129358],
                [-16.258752999999899, 20.129028000000119],
                [-16.258413, 20.129087],
                [-16.258471, 20.128469],
                [-16.258192, 20.128469],
                [-16.258192, 20.128195000000119],
                [-16.25791699999985, 20.128195000000119],
                [-16.25791699999985, 20.127642000000151],
                [-16.257641, 20.127642000000151],
                [-16.257641, 20.127361000000178],
                [-16.25708399999985, 20.127361000000178],
                [-16.25708399999985, 20.126806000000101],
                [-16.256805, 20.126806000000101],
                [-16.256805, 20.126530000000173],
                [-16.256248, 20.126530000000173],
                [-16.256248, 20.126247],
                [-16.255447, 20.126219],
                [-16.255416999999852, 20.126530000000173],
                [-16.255138, 20.126530000000173],
                [-16.255138, 20.126806000000101],
                [-16.254583, 20.126806000000101],
                [-16.254583, 20.127083],
                [-16.254028, 20.127083],
                [-16.254028, 20.127361000000178],
                [-16.253195, 20.127361000000178],
                [-16.253195, 20.127642000000151],
                [-16.252361, 20.127642000000151],
                [-16.252361, 20.127916],
                [-16.251806, 20.127916],
                [-16.251806, 20.128195000000119],
                [-16.251249, 20.128195000000119],
                [-16.251249, 20.128469],
                [-16.250973, 20.128469],
                [-16.250973, 20.12875200000019],
                [-16.250694, 20.12875200000019],
                [-16.250694, 20.129028000000119],
                [-16.250139, 20.129028000000119],
                [-16.250139, 20.129305000000102],
                [-16.249584, 20.129305000000102],
                [-16.249584, 20.129583],
                [-16.249027, 20.129583],
                [-16.249027, 20.129862000000173],
                [-16.248751, 20.129862000000173],
                [-16.248751, 20.130138000000102],
                [-16.248472, 20.130138000000102],
                [-16.248472, 20.130417],
                [-16.248196, 20.130417],
                [-16.248196, 20.130695000000173],
                [-16.247917, 20.130695000000173],
                [-16.247917, 20.130974000000151],
                [-16.247639, 20.130974000000151],
                [-16.247639, 20.13125],
                [-16.246529, 20.13125],
                [-16.246529, 20.131527000000119],
                [-16.245974, 20.131527000000119],
                [-16.245974, 20.131805],
                [-16.245695, 20.131805],
                [-16.245695, 20.132084000000191],
                [-16.244864, 20.132084000000191],
                [-16.244864, 20.132639],
                [-16.244581, 20.132639],
                [-16.244581, 20.132917000000191],
                [-16.244028, 20.132917000000191],
                [-16.244028, 20.133192000000179],
                [-16.243195, 20.133192000000179],
                [-16.243195, 20.133472],
                [-16.242916, 20.133472],
                [-16.242916, 20.133751],
                [-16.242641, 20.133751],
                [-16.242641, 20.134027000000174],
                [-16.242359, 20.134027000000174],
                [-16.242359, 20.134304],
                [-16.242083, 20.134304],
                [-16.242083, 20.134583000000191],
                [-16.241805999999883, 20.134583000000191],
                [-16.241805999999883, 20.134861],
                [-16.241528, 20.134861],
                [-16.241528, 20.135139000000152],
                [-16.241246999999817, 20.135139000000152],
                [-16.241246999999817, 20.135414],
                [-16.240972999999883, 20.135414],
                [-16.240972999999883, 20.135695000000169],
                [-16.240694, 20.135695000000169],
                [-16.240694, 20.135973],
                [-16.240417, 20.135973],
                [-16.240417, 20.13625],
                [-16.240136999999891, 20.13625],
                [-16.240136999999891, 20.136526000000174],
                [-16.239861, 20.136526000000174],
                [-16.239861, 20.136805000000152],
                [-16.239584, 20.136808],
                [-16.239584, 20.137083],
                [-16.239304999999888, 20.137083],
                [-16.239304999999888, 20.137362],
                [-16.239029, 20.137362],
                [-16.239029, 20.137636],
                [-16.23875, 20.137636],
                [-16.23875, 20.137918],
                [-16.238194, 20.137918],
                [-16.238194, 20.138197],
                [-16.237917, 20.138197],
                [-16.237917, 20.138472],
                [-16.23763799999989, 20.138472],
                [-16.23763799999989, 20.138748000000191],
                [-16.237082999999814, 20.138748000000191],
                [-16.237082999999814, 20.139027000000169],
                [-16.236806999999885, 20.139027000000169],
                [-16.236806999999885, 20.139305],
                [-16.236528, 20.139305],
                [-16.236528, 20.139584],
                [-16.236249999999814, 20.139584],
                [-16.236249999999814, 20.139858000000174],
                [-16.235970999999893, 20.139862000000164],
                [-16.235970999999893, 20.140141000000142],
                [-16.235697, 20.140141000000142],
                [-16.235697, 20.140417],
                [-16.235415999999816, 20.140417],
                [-16.235415999999816, 20.140694],
                [-16.235137999999893, 20.140694],
                [-16.235137999999893, 20.140972000000147],
                [-16.234861, 20.140972000000147],
                [-16.234861, 20.141251000000125],
                [-16.234585, 20.141251000000125],
                [-16.234585, 20.141527],
                [-16.23430599999989, 20.141527],
                [-16.23430599999989, 20.141806],
                [-16.234028, 20.141806],
                [-16.234028, 20.142084000000125],
                [-16.233749, 20.142084000000125],
                [-16.233749, 20.142639],
                [-16.233474999999885, 20.142639],
                [-16.233474999999885, 20.142916],
                [-16.233194, 20.142916],
                [-16.233194, 20.143194000000165],
                [-16.232638999999892, 20.143194000000165],
                [-16.232638999999892, 20.143473000000142],
                [-16.232363, 20.143473000000142],
                [-16.232363, 20.143749],
                [-16.232083999999816, 20.143749],
                [-16.23208, 20.144028],
                [-16.231527, 20.144028],
                [-16.231527, 20.144583000000125],
                [-16.231253, 20.144583000000125],
                [-16.231253, 20.144861],
                [-16.23097, 20.144861],
                [-16.23097, 20.14514],
                [-16.230694, 20.14514],
                [-16.230694, 20.145416000000125],
                [-16.230417, 20.145416000000125],
                [-16.230417, 20.14569500000016],
                [-16.230138999999895, 20.14569500000016],
                [-16.230138999999895, 20.14652800000016],
                [-16.22986, 20.14652800000016],
                [-16.22986, 20.146805000000143],
                [-16.229584, 20.146805000000143],
                [-16.229584, 20.147083],
                [-16.229304999999897, 20.147083],
                [-16.229304999999897, 20.147362],
                [-16.229031, 20.147362],
                [-16.229031, 20.147638000000143],
                [-16.228748, 20.147638000000143],
                [-16.228748, 20.14791700000012],
                [-16.228471999999897, 20.14791700000012],
                [-16.228471999999897, 20.148193],
                [-16.228195, 20.148193],
                [-16.228195, 20.148472],
                [-16.227917, 20.148472],
                [-16.227917, 20.148750000000121],
                [-16.227637999999899, 20.148750000000121],
                [-16.227637999999899, 20.14902700000016],
                [-16.227362, 20.14902700000016],
                [-16.227362, 20.149584],
                [-16.227083, 20.149584],
                [-16.227083, 20.150139000000138],
                [-16.226804999999899, 20.150139000000138],
                [-16.226804999999899, 20.150415],
                [-16.226526, 20.150415],
                [-16.226526, 20.150694],
                [-16.22625, 20.150694],
                [-16.22625, 20.151251000000116],
                [-16.225972999999897, 20.151251000000116],
                [-16.225972999999897, 20.152082000000121],
                [-16.225695, 20.152082000000121],
                [-16.225695, 20.152637],
                [-16.225416, 20.152637],
                [-16.225416, 20.152918],
                [-16.225139999999897, 20.152918],
                [-16.225139999999897, 20.153473000000133],
                [-16.224861, 20.153473000000133],
                [-16.224861, 20.153748000000121],
                [-16.224583, 20.153748000000121],
                [-16.224583, 20.154583000000116],
                [-16.224861, 20.154583000000116],
                [-16.224861, 20.155140000000188],
                [-16.224583, 20.155165000000125],
                [-16.224583, 20.155357],
                [-16.224583, 20.155972000000133],
                [-16.224861, 20.156034],
                [-16.224861, 20.157084000000111],
                [-16.224583, 20.157084000000111],
                [-16.224583, 20.157917000000111],
                [-16.224861, 20.157917000000111],
                [-16.224861, 20.158194],
                [-16.225139999999897, 20.158194],
                [-16.225139999999897, 20.158472000000188],
                [-16.225864, 20.158472000000188],
                [-16.225972999999897, 20.158472000000188],
                [-16.225972999999897, 20.158194],
                [-16.22625, 20.158194],
                [-16.22625, 20.157917000000111],
                [-16.227637999999899, 20.157917000000111],
                [-16.227637999999899, 20.158194],
                [-16.227917, 20.158194],
                [-16.227917, 20.158472000000188],
                [-16.228195, 20.158472000000188],
                [-16.228195, 20.159861],
                [-16.227637999999899, 20.159861],
                [-16.227637999999899, 20.160139000000129],
                [-16.227083, 20.160139000000129],
                [-16.227083, 20.160418000000107],
                [-16.226804999999899, 20.160418000000107],
                [-16.226804999999899, 20.160973000000183],
                [-16.226526, 20.160973000000183],
                [-16.226526, 20.161249000000112],
                [-16.22625, 20.161249000000112],
                [-16.22625, 20.162083],
                [-16.225972999999897, 20.162083],
                [-16.225972999999897, 20.162914],
                [-16.225695, 20.162914],
                [-16.225695, 20.164305000000184],
                [-16.225416, 20.164305000000184],
                [-16.225416, 20.164862],
                [-16.225695, 20.164862],
                [-16.225695, 20.165136000000189],
                [-16.22625, 20.165136000000189],
                [-16.22625, 20.166805000000124],
                [-16.226526, 20.166805000000124],
                [-16.226526, 20.167084000000102],
                [-16.226804999999899, 20.167084000000102],
                [-16.226804999999899, 20.167641000000174],
                [-16.226526, 20.167641000000174],
                [-16.226526, 20.167917000000102],
                [-16.22625, 20.167917000000102],
                [-16.22625, 20.168194],
                [-16.222638999999845, 20.168194],
                [-16.222638999999845, 20.168472000000179],
                [-16.222361, 20.168472000000179],
                [-16.222361, 20.169027],
                [-16.222086, 20.169027],
                [-16.222086, 20.169306],
                [-16.221805999999845, 20.169306],
                [-16.221805999999845, 20.169582],
                [-16.221527, 20.169584000000157],
                [-16.221527, 20.170139],
                [-16.221251, 20.170139],
                [-16.221251, 20.170416000000102],
                [-16.220971999999847, 20.170416000000102],
                [-16.220971999999847, 20.170694],
                [-16.220692, 20.170694],
                [-16.220692, 20.170973000000174],
                [-16.219864, 20.170973000000174],
                [-16.219864, 20.171249000000103],
                [-16.219584, 20.171249000000103],
                [-16.219584, 20.171528],
                [-16.219864, 20.171528],
                [-16.219864, 20.171806000000174],
                [-16.219303, 20.171806000000174],
                [-16.219303, 20.172085000000152],
                [-16.218472999999847, 20.172085000000152],
                [-16.218472999999847, 20.172361],
                [-16.217916, 20.172361],
                [-16.217916, 20.172638],
                [-16.216806, 20.172638],
                [-16.216806, 20.172361],
                [-16.216249, 20.172361],
                [-16.216249, 20.172085000000152],
                [-16.215694, 20.172085000000152],
                [-16.215694, 20.171806000000174],
                [-16.215416, 20.171806000000174],
                [-16.215416, 20.172085000000152],
                [-16.213751, 20.172085000000152],
                [-16.213751, 20.172361],
                [-16.213472, 20.172361],
                [-16.213472, 20.172638],
                [-16.213194, 20.172638],
                [-16.213194, 20.172916000000157],
                [-16.212917, 20.172916000000157],
                [-16.212917, 20.173471],
                [-16.212639, 20.173471],
                [-16.212639, 20.17375],
                [-16.212359999999876, 20.17375],
                [-16.212359999999876, 20.174028000000192],
                [-16.212084, 20.174028000000192],
                [-16.212084, 20.174583],
                [-16.211805, 20.174583],
                [-16.211805, 20.174862],
                [-16.211526999999876, 20.174862],
                [-16.211526999999876, 20.175694000000192],
                [-16.21125, 20.175694000000192],
                [-16.21125, 20.176250000000152],
                [-16.210972, 20.176250000000152],
                [-16.210972, 20.176525],
                [-16.210695, 20.176525],
                [-16.210695, 20.176805],
                [-16.210417, 20.176805],
                [-16.210417, 20.177361],
                [-16.210138, 20.177361],
                [-16.210138, 20.177916000000153],
                [-16.209862, 20.177916000000153],
                [-16.209862, 20.178473],
                [-16.209583, 20.178473],
                [-16.209583, 20.178747000000158],
                [-16.209307, 20.178747000000158],
                [-16.209307, 20.179029],
                [-16.209027999999876, 20.179029],
                [-16.209027999999876, 20.179308],
                [-16.20875, 20.179308],
                [-16.20875, 20.179583],
                [-16.208471, 20.179583],
                [-16.208471, 20.179859],
                [-16.208194999999876, 20.179882000000134],
                [-16.208194999999876, 20.180140000000165],
                [-16.208076, 20.180140000000165],
                [-16.207916, 20.180140000000165],
                [-16.207916, 20.180483],
                [-16.207916, 20.181528],
                [-16.20764, 20.181528],
                [-16.20764, 20.182083000000148],
                [-16.207360999999878, 20.182083000000148],
                [-16.207360999999878, 20.182362000000126],
                [-16.207047, 20.182346],
                [-16.207085, 20.182917],
                [-16.206787, 20.182911000000104],
                [-16.206806, 20.183472000000165],
                [-16.206525999999883, 20.183472000000165],
                [-16.206527999999878, 20.18375],
                [-16.206393999999875, 20.18375],
                [-16.206249, 20.18375],
                [-16.206249, 20.184065],
                [-16.206249, 20.184584000000143],
                [-16.205975, 20.184584000000143],
                [-16.205975, 20.18466],
                [-16.205975, 20.185417000000143],
                [-16.205633, 20.18539],
                [-16.205691999999885, 20.186251],
                [-16.205206, 20.186275],
                [-16.205139, 20.186453],
                [-16.205139, 20.187084],
                [-16.204847, 20.187078],
                [-16.204862999999875, 20.187361],
                [-16.204716, 20.187361],
                [-16.204584, 20.187361],
                [-16.204584, 20.187647],
                [-16.204584, 20.188473],
                [-16.204309, 20.188440000000128],
                [-16.204306, 20.189583],
                [-16.204026999999883, 20.189583],
                [-16.204026999999883, 20.19014],
                [-16.203753, 20.19014],
                [-16.203753, 20.190695],
                [-16.20347, 20.190695],
                [-16.20347, 20.191250000000139],
                [-16.203193999999883, 20.191250000000139],
                [-16.203193999999883, 20.191805],
                [-16.202921, 20.191788],
                [-16.202917, 20.192362000000116],
                [-16.202639, 20.192362000000116],
                [-16.202639, 20.192539000000181],
                [-16.202639, 20.192919000000188],
                [-16.202543, 20.192919000000188],
                [-16.202359999999885, 20.192919000000188],
                [-16.20235799999989, 20.193592],
                [-16.20235799999989, 20.194029],
                [-16.202246, 20.194029],
                [-16.202084, 20.194029],
                [-16.202084, 20.194645],
                [-16.202084, 20.194859000000122],
                [-16.201805, 20.194859000000122],
                [-16.201805, 20.195704],
                [-16.201805, 20.195972],
                [-16.20152899999988, 20.195972],
                [-16.20152899999988, 20.196753000000172],
                [-16.20152899999988, 20.197639],
                [-16.201248, 20.197639],
                [-16.201248, 20.19781300000011],
                [-16.201248, 20.200138],
                [-16.20152899999988, 20.200138],
                [-16.20152899999988, 20.201529000000107],
                [-16.201248, 20.201529000000107],
                [-16.201248, 20.201805],
                [-16.200972, 20.201805],
                [-16.200972, 20.202915000000189],
                [-16.201248, 20.202915000000189],
                [-16.201248, 20.203472],
                [-16.200972, 20.203472],
                [-16.200972, 20.204306],
                [-16.201248, 20.204306],
                [-16.201248, 20.204584],
                [-16.20152899999988, 20.204584],
                [-16.20152899999988, 20.207359],
                [-16.201805, 20.207359],
                [-16.201805, 20.209305],
                [-16.202084, 20.209305],
                [-16.202084, 20.210138],
                [-16.20235799999989, 20.210138],
                [-16.202359999999885, 20.211527000000103],
                [-16.202639, 20.211527000000103],
                [-16.202639, 20.212917000000175],
                [-16.202917, 20.212917000000175],
                [-16.202917, 20.214027000000158],
                [-16.203193999999883, 20.214027000000158],
                [-16.203193999999883, 20.21541400000018],
                [-16.20347, 20.21541400000018],
                [-16.20347, 20.217083],
                [-16.203753, 20.217083],
                [-16.203753, 20.218197],
                [-16.204026999999883, 20.218197],
                [-16.204026999999883, 20.218748000000176],
                [-16.204306, 20.218748000000176],
                [-16.204306, 20.219305],
                [-16.204584, 20.219305],
                [-16.204584, 20.219862000000148],
                [-16.204862999999875, 20.219862000000148],
                [-16.204862999999875, 20.22014],
                [-16.205139, 20.22014],
                [-16.205139, 20.220694],
                [-16.205416, 20.220694],
                [-16.205416, 20.22097],
                [-16.205691999999885, 20.22097],
                [-16.205691999999885, 20.221251000000166],
                [-16.205975, 20.221251000000166],
                [-16.205975, 20.221527],
                [-16.206249, 20.221527],
                [-16.206249, 20.221806],
                [-16.206527999999878, 20.221806],
                [-16.206527999999878, 20.222359000000154],
                [-16.206806, 20.222359000000154],
                [-16.206806, 20.222918],
                [-16.207085, 20.222918],
                [-16.207085, 20.223194000000149],
                [-16.207360999999878, 20.223194000000149],
                [-16.207360999999878, 20.223473000000126],
                [-16.20764, 20.223473000000126],
                [-16.20764, 20.224028],
                [-16.207916, 20.224028],
                [-16.207916, 20.224306000000126],
                [-16.208194999999876, 20.224306000000126],
                [-16.208194999999876, 20.224861],
                [-16.208471, 20.224861],
                [-16.208471, 20.22514],
                [-16.20875, 20.22514],
                [-16.20875, 20.225695000000144],
                [-16.209027999999876, 20.225695000000144],
                [-16.209027999999876, 20.22625],
                [-16.209307, 20.22625],
                [-16.209307, 20.226528000000144],
                [-16.209583, 20.226528000000144],
                [-16.209583, 20.226805000000127],
                [-16.209862, 20.226805000000127],
                [-16.209862, 20.227362],
                [-16.210138, 20.227362],
                [-16.210138, 20.227638000000127],
                [-16.210417, 20.227638000000127],
                [-16.210417, 20.227917000000105],
                [-16.210695, 20.227917000000105],
                [-16.210695, 20.228193],
                [-16.210972, 20.228193],
                [-16.210972, 20.228472],
                [-16.21125, 20.228472],
                [-16.21125, 20.229029000000139],
                [-16.211526999999876, 20.229029000000139],
                [-16.211526999999876, 20.229860000000144],
                [-16.211805, 20.229860000000144],
                [-16.211805, 20.230139000000122],
                [-16.212084, 20.230139000000122],
                [-16.212084, 20.230972000000122],
                [-16.212359999999876, 20.230972000000122],
                [-16.212359999999876, 20.231527],
                [-16.212639, 20.231527],
                [-16.212639, 20.232637],
                [-16.212917, 20.232637],
                [-16.212917, 20.233473000000117],
                [-16.213194, 20.233473000000117],
                [-16.213194, 20.233747],
                [-16.213472, 20.233747],
                [-16.213472, 20.234030000000189],
                [-16.213751, 20.234030000000189],
                [-16.213751, 20.2345830000001],
                [-16.214027, 20.2345830000001],
                [-16.214027, 20.234861],
                [-16.214306, 20.234861],
                [-16.214306, 20.23514],
                [-16.214582, 20.235142000000167],
                [-16.214582, 20.235695000000135],
                [-16.214861, 20.235695000000135],
                [-16.214861, 20.235970000000123],
                [-16.215139, 20.235970000000123],
                [-16.215139, 20.236252],
                [-16.215416, 20.236252],
                [-16.215416, 20.236805000000118],
                [-16.215694, 20.236805000000118],
                [-16.215694, 20.237638000000118],
                [-16.215973, 20.237638000000118],
                [-16.215973, 20.237917],
                [-16.216249, 20.237917],
                [-16.216249, 20.238194000000135],
                [-16.216528, 20.238195000000189],
                [-16.216528, 20.239027000000135],
                [-16.216806, 20.239027000000135],
                [-16.216806, 20.23986100000019],
                [-16.217081, 20.23986100000019],
                [-16.217081, 20.240696000000185],
                [-16.21736, 20.240696000000185],
                [-16.21736, 20.241804],
                [-16.217638, 20.241804],
                [-16.217638, 20.242083],
                [-16.217916, 20.242083],
                [-16.217916, 20.242914],
                [-16.218195, 20.242914],
                [-16.218195, 20.243473000000108],
                [-16.218472999999847, 20.243473000000108],
                [-16.218472999999847, 20.244305000000168],
                [-16.218752, 20.244305000000168],
                [-16.218752, 20.245136],
                [-16.219028, 20.245136],
                [-16.219028, 20.245419],
                [-16.219584, 20.245419],
                [-16.219584, 20.246805000000109],
                [-16.219864, 20.246805000000109],
                [-16.219864, 20.24735800000019],
                [-16.220138999999847, 20.24735800000019],
                [-16.220138999999847, 20.247641000000158],
                [-16.220417, 20.247641000000158],
                [-16.220417, 20.248194],
                [-16.220692, 20.248194],
                [-16.220692, 20.248751],
                [-16.220971999999847, 20.248751],
                [-16.220971999999847, 20.249306000000104],
                [-16.221251, 20.249306000000104],
                [-16.221251, 20.249863000000175],
                [-16.221527, 20.249863000000175],
                [-16.221527, 20.250416],
                [-16.221805999999845, 20.250416],
                [-16.221805999999845, 20.250973000000158],
                [-16.222086, 20.250973000000158],
                [-16.222086, 20.251249],
                [-16.222361, 20.251249],
                [-16.222361, 20.251528],
                [-16.222638999999845, 20.251528],
                [-16.222638999999845, 20.251806000000158],
                [-16.222918, 20.251806000000158],
                [-16.222918, 20.252916],
                [-16.223194, 20.252916],
                [-16.223194, 20.253195000000176],
                [-16.223472999999899, 20.253195000000176],
                [-16.223472999999899, 20.25375],
                [-16.223749, 20.25375],
                [-16.223749, 20.254305000000159],
                [-16.224028, 20.254305000000159],
                [-16.224028, 20.254862],
                [-16.224305999999899, 20.254862],
                [-16.224305999999899, 20.255417000000136],
                [-16.224583, 20.255417000000136],
                [-16.224583, 20.255972],
                [-16.224861, 20.255972],
                [-16.224861, 20.256527000000176],
                [-16.225139999999897, 20.256527000000176],
                [-16.225139999999897, 20.257084],
                [-16.225416, 20.257084],
                [-16.225416, 20.257639000000154],
                [-16.225695, 20.257639000000154],
                [-16.225695, 20.258194],
                [-16.225972999999897, 20.258194],
                [-16.225972999999897, 20.258751000000132],
                [-16.22625, 20.258751000000132],
                [-16.22625, 20.259583],
                [-16.226526, 20.259583],
                [-16.226526, 20.260416],
                [-16.226804999999899, 20.260416],
                [-16.226804999999899, 20.26153],
                [-16.227083, 20.26153],
                [-16.227083, 20.262083000000132],
                [-16.227362, 20.262083000000132],
                [-16.227362, 20.26236200000011],
                [-16.227637999999899, 20.26236200000011],
                [-16.227637999999899, 20.262638],
                [-16.227917, 20.262638],
                [-16.227917, 20.26375],
                [-16.228195, 20.26375],
                [-16.228195, 20.264584000000127],
                [-16.228471999999897, 20.264584000000127],
                [-16.228471999999897, 20.265139],
                [-16.228748, 20.265139],
                [-16.228748, 20.26652700000011],
                [-16.229031, 20.26652700000011],
                [-16.229031, 20.266806000000145],
                [-16.229304999999897, 20.266806000000145],
                [-16.229304999999897, 20.267084],
                [-16.229584, 20.267084],
                [-16.229584, 20.267639000000145],
                [-16.22986, 20.267639000000145],
                [-16.22986, 20.267916000000127],
                [-16.230138999999895, 20.267918000000122],
                [-16.230138999999895, 20.268194],
                [-16.230417, 20.268194],
                [-16.230417, 20.268749000000128],
                [-16.230694, 20.268749000000128],
                [-16.230694, 20.269028000000105],
                [-16.23097, 20.269028000000105],
                [-16.23097, 20.269583],
                [-16.231253, 20.269583],
                [-16.231253, 20.270971000000145],
                [-16.231527, 20.270971000000145],
                [-16.231527, 20.271526],
                [-16.231805999999892, 20.271526],
                [-16.231805999999892, 20.272362000000101],
                [-16.23208, 20.272362000000101],
                [-16.232083999999816, 20.272919000000172],
                [-16.232363, 20.272919000000172],
                [-16.232363, 20.27347200000014],
                [-16.232638999999892, 20.27347200000014],
                [-16.232638999999892, 20.273748],
                [-16.232916, 20.273748],
                [-16.232916, 20.27430500000014],
                [-16.233194, 20.27430500000014],
                [-16.233194, 20.274584000000118],
                [-16.233474999999885, 20.274584000000118],
                [-16.233474999999885, 20.27514100000019],
                [-16.233749, 20.27514100000019],
                [-16.233749, 20.275417000000118],
                [-16.234028, 20.275417000000118],
                [-16.234028, 20.275694000000101],
                [-16.23430599999989, 20.275694000000101],
                [-16.23430599999989, 20.276253000000168],
                [-16.234585, 20.276253000000168],
                [-16.234585, 20.276806],
                [-16.234861, 20.276806],
                [-16.234861, 20.277082],
                [-16.235137999999893, 20.277082],
                [-16.235137999999893, 20.277639],
                [-16.235415999999816, 20.277639],
                [-16.235415999999816, 20.277916000000118],
                [-16.235697, 20.277916000000118],
                [-16.235697, 20.278194],
                [-16.235970999999893, 20.278194],
                [-16.235970999999893, 20.278749000000118],
                [-16.236249999999814, 20.278749000000118],
                [-16.236249999999814, 20.27930600000019],
                [-16.236528, 20.27930600000019],
                [-16.236528, 20.279861],
                [-16.236806999999885, 20.279861],
                [-16.236806999999885, 20.280138],
                [-16.237082999999814, 20.280138],
                [-16.237082999999814, 20.280416000000173],
                [-16.237362, 20.280416000000173],
                [-16.237362, 20.28097200000019],
                [-16.23763799999989, 20.28097200000019],
                [-16.23763799999989, 20.281250000000114],
                [-16.237917, 20.281250000000114],
                [-16.237917, 20.281528],
                [-16.238194, 20.281528],
                [-16.238194, 20.282084000000168],
                [-16.238471999999888, 20.282084000000168],
                [-16.238471999999888, 20.282362],
                [-16.23875, 20.282362],
                [-16.23875, 20.282639],
                [-16.239029, 20.282639],
                [-16.239029, 20.282915000000173],
                [-16.239304999999888, 20.282915000000173],
                [-16.239304999999888, 20.283194],
                [-16.239584, 20.283194],
                [-16.239584, 20.283472],
                [-16.240136999999891, 20.283472],
                [-16.240136999999891, 20.283751000000109],
                [-16.240417, 20.283751000000109],
                [-16.240417, 20.284025],
                [-16.240694, 20.284025],
                [-16.240694, 20.284306000000186],
                [-16.241246999999817, 20.284306000000186],
                [-16.241246999999817, 20.284584000000109],
                [-16.241528, 20.284584000000109],
                [-16.241528, 20.284861],
                [-16.242083, 20.284861],
                [-16.242083, 20.285137000000191],
                [-16.242641, 20.285137000000191],
                [-16.242641, 20.285419],
                [-16.243195, 20.285419],
                [-16.243195, 20.285694],
                [-16.244581, 20.285694],
                [-16.244581, 20.285973],
                [-16.24625, 20.285973],
                [-16.24625, 20.286247000000174],
                [-16.249299999999891, 20.286247000000174],
                [-16.25264, 20.286247000000174],
                [-16.25264, 20.285973],
                [-16.254583, 20.285973],
                [-16.254583, 20.285694],
                [-16.255138, 20.285694],
                [-16.255136, 20.28541],
                [-16.255695, 20.285419],
                [-16.255695, 20.285137000000191],
                [-16.256248, 20.285137000000191],
                [-16.256248, 20.284861],
                [-16.256805, 20.284861],
                [-16.256805, 20.284584000000109],
                [-16.25708399999985, 20.284584000000109],
                [-16.25708399999985, 20.284306000000186],
                [-16.257641, 20.284306000000186],
                [-16.257641, 20.284025],
                [-16.258192, 20.284025],
                [-16.258192, 20.283751000000109],
                [-16.258752999999899, 20.283751000000109],
                [-16.258752999999899, 20.283472],
                [-16.259027, 20.283472],
                [-16.259027, 20.283194],
                [-16.259863, 20.283194],
                [-16.259863, 20.282915000000173],
                [-16.26041599999985, 20.282915000000173],
                [-16.26041599999985, 20.282639],
                [-16.26319699999982, 20.282639],
                [-16.26319699999982, 20.282362],
                [-16.263472, 20.282362],
                [-16.263472, 20.281803],
                [-16.26375, 20.281803],
                [-16.26375, 20.281528],
                [-16.264029, 20.281528],
                [-16.264029, 20.280693],
                [-16.26375, 20.280693],
                [-16.26375, 20.280138],
                [-16.264029, 20.280138],
                [-16.264029, 20.279861],
                [-16.264305, 20.279861],
                [-16.264305, 20.279585000000168],
                [-16.264584, 20.279585000000168],
                [-16.264584, 20.27930600000019],
                [-16.268473, 20.27930600000019],
                [-16.268473, 20.279585000000168],
                [-16.268749, 20.279585000000168],
                [-16.268749, 20.280138],
                [-16.269027999999821, 20.280138],
                [-16.269027999999821, 20.280416000000173],
                [-16.269306, 20.280416000000173],
                [-16.269306, 20.281250000000114],
                [-16.269582999999898, 20.281250000000114],
                [-16.269582999999898, 20.281528],
                [-16.269859, 20.281528],
                [-16.269859, 20.282639],
                [-16.270142, 20.282639],
                [-16.270142, 20.283194],
                [-16.270415999999898, 20.283194],
                [-16.270415999999898, 20.284025],
                [-16.270694999999819, 20.284025],
                [-16.270694999999819, 20.284584000000109],
                [-16.270969, 20.284584000000109],
                [-16.270969, 20.285419],
                [-16.27125199999989, 20.285419],
                [-16.27125199999989, 20.285973],
                [-16.271527999999819, 20.285973],
                [-16.271527999999819, 20.286530000000141],
                [-16.271805, 20.286530000000141],
                [-16.271805, 20.287083000000109],
                [-16.272081, 20.287083000000109],
                [-16.272081, 20.287638000000186],
                [-16.272364, 20.287638000000186],
                [-16.272364, 20.288195],
                [-16.272638, 20.288195],
                [-16.272638, 20.289028],
                [-16.272916999999893, 20.289028],
                [-16.272916999999893, 20.289862000000142],
                [-16.273194999999873, 20.289862000000142],
                [-16.273194999999873, 20.290974000000176],
                [-16.273474, 20.290974000000176],
                [-16.273474, 20.291805000000181],
                [-16.273749999999893, 20.291805000000181],
                [-16.273749999999893, 20.292639],
                [-16.274026999999819, 20.292639],
                [-16.274026999999819, 20.293194000000142],
                [-16.274305, 20.293194000000142],
                [-16.274305, 20.293751],
                [-16.274585999999886, 20.293751],
                [-16.274585999999886, 20.294584],
                [-16.274859999999819, 20.294584],
                [-16.274859999999819, 20.295694],
                [-16.275139, 20.295694],
                [-16.275139, 20.295973],
                [-16.275416999999891, 20.295973],
                [-16.275416999999891, 20.297083],
                [-16.275696, 20.297083],
                [-16.275696, 20.297361000000137],
                [-16.275972, 20.297361000000137],
                [-16.275972, 20.297916],
                [-16.276250999999888, 20.297916],
                [-16.276250999999888, 20.298471000000177],
                [-16.276526999999874, 20.298471000000177],
                [-16.276526999999874, 20.299583000000155],
                [-16.276806, 20.299583000000155],
                [-16.276806, 20.300419],
                [-16.277081999999893, 20.300419],
                [-16.277081999999893, 20.300970000000177],
                [-16.277360999999871, 20.300970000000177],
                [-16.277360999999871, 20.301805000000115],
                [-16.277639, 20.301805000000115],
                [-16.277639, 20.30291700000015],
                [-16.277917999999886, 20.30291700000015],
                [-16.277917999999886, 20.30347300000011],
                [-16.278193999999871, 20.30347300000011],
                [-16.278193999999871, 20.304306000000111],
                [-16.278473, 20.304306000000111],
                [-16.278473, 20.30541600000015],
                [-16.278748999999891, 20.30541600000015],
                [-16.278748999999891, 20.306528000000128],
                [-16.279028, 20.306528000000128],
                [-16.279028, 20.307362],
                [-16.279306, 20.307362],
                [-16.279306, 20.307917000000145],
                [-16.279582999999889, 20.307917000000145],
                [-16.279582999999889, 20.308750000000146],
                [-16.279861, 20.308750000000146],
                [-16.279861, 20.309860000000128],
                [-16.280136, 20.309860000000128],
                [-16.280136, 20.310415],
                [-16.280415999999889, 20.310415],
                [-16.280415999999889, 20.310972000000106],
                [-16.280695, 20.310972000000106],
                [-16.280695, 20.311806],
                [-16.280972, 20.311806],
                [-16.280972, 20.312637],
                [-16.281247999999891, 20.312637],
                [-16.281247999999891, 20.313194000000124],
                [-16.281528, 20.313194000000124],
                [-16.281528, 20.313747],
                [-16.281805, 20.313747],
                [-16.281805, 20.314859],
                [-16.282084, 20.314859],
                [-16.282084, 20.315416],
                [-16.282359999999869, 20.315416],
                [-16.282359999999869, 20.315695000000119],
                [-16.282639, 20.315695000000119],
                [-16.282639, 20.31625200000019],
                [-16.282917, 20.31625200000019],
                [-16.282917, 20.316805000000102],
                [-16.283194, 20.316805000000102],
                [-16.283194, 20.317362000000173],
                [-16.28347, 20.317362000000173],
                [-16.28347, 20.317917],
                [-16.283752, 20.317917],
                [-16.283752, 20.318195000000173],
                [-16.284027, 20.318195000000173],
                [-16.284027, 20.31875],
                [-16.284306, 20.31875],
                [-16.284306, 20.319027000000119],
                [-16.284581999999887, 20.319027000000119],
                [-16.284581999999887, 20.319305],
                [-16.284863, 20.319305],
                [-16.284863, 20.319860000000119],
                [-16.285139, 20.319860000000119],
                [-16.285139, 20.320417000000191],
                [-16.285418, 20.320417000000191],
                [-16.285418, 20.321251],
                [-16.285691999999869, 20.321251],
                [-16.285691999999869, 20.321804],
                [-16.285971, 20.321804],
                [-16.285971, 20.322083000000191],
                [-16.286249, 20.322083000000191],
                [-16.286249, 20.322361000000114],
                [-16.286528, 20.322361000000114],
                [-16.286528, 20.322639],
                [-16.286806, 20.322639],
                [-16.286806, 20.322914],
                [-16.287085, 20.322914],
                [-16.287085, 20.323473],
                [-16.287361, 20.323473],
                [-16.287361, 20.32375],
                [-16.28764, 20.32375],
                [-16.28764, 20.324026000000174],
                [-16.287916, 20.324026000000174],
                [-16.287916, 20.324583],
                [-16.288195, 20.324583],
                [-16.288195, 20.325136],
                [-16.288473, 20.325136],
                [-16.288473, 20.325695],
                [-16.28875, 20.325695],
                [-16.28875, 20.325972],
                [-16.289028, 20.325972],
                [-16.289028, 20.326248000000191],
                [-16.290138, 20.326248000000191],
                [-16.290138, 20.32653],
                [-16.29125, 20.32653],
                [-16.29125, 20.326805],
                [-16.292084, 20.326805],
                [-16.292084, 20.327084],
                [-16.292917, 20.327084],
                [-16.292917, 20.327362000000164],
                [-16.294306, 20.327362000000164],
                [-16.294306, 20.327641000000142],
                [-16.295416, 20.327641000000142],
                [-16.295416, 20.327917],
                [-16.296249, 20.327917],
                [-16.296249, 20.328194],
                [-16.297083, 20.328194],
                [-16.297083, 20.32847],
                [-16.298195, 20.32847],
                [-16.298195, 20.328751000000182],
                [-16.298752, 20.328751000000182],
                [-16.298752, 20.329027],
                [-16.299303, 20.329027],
                [-16.299303, 20.329306],
                [-16.299864, 20.329306],
                [-16.299864, 20.329584000000182],
                [-16.300417, 20.329584000000182],
                [-16.300417, 20.329863000000159],
                [-16.300973999999826, 20.329863000000159],
                [-16.300973999999826, 20.330139],
                [-16.301527, 20.330139],
                [-16.301527, 20.330416],
                [-16.302086, 20.330418],
                [-16.302086, 20.330694000000165],
                [-16.302639, 20.330694000000165],
                [-16.302639, 20.330973000000142],
                [-16.303196, 20.330973000000142],
                [-16.303196, 20.331249],
                [-16.303472999999826, 20.331249],
                [-16.303472999999826, 20.331528],
                [-16.303749, 20.331528],
                [-16.303749, 20.331806000000142],
                [-16.304028, 20.331806000000142],
                [-16.304028, 20.332083000000182],
                [-16.304583, 20.332083000000182],
                [-16.304583, 20.332361],
                [-16.304861, 20.332361],
                [-16.304861, 20.33264],
                [-16.305139999999824, 20.33264],
                [-16.305139999999824, 20.332916000000182],
                [-16.305416, 20.332916000000182],
                [-16.305416, 20.333471],
                [-16.305695, 20.333471],
                [-16.305695, 20.33375],
                [-16.305972999999824, 20.33375],
                [-16.305972999999824, 20.334305000000143],
                [-16.30625, 20.334305000000143],
                [-16.30625, 20.334862],
                [-16.306526, 20.334862],
                [-16.306526, 20.33541700000012],
                [-16.306804999999827, 20.33541700000012],
                [-16.306804999999827, 20.335972],
                [-16.307083, 20.335972],
                [-16.307083, 20.33652700000016],
                [-16.307361999999898, 20.33652700000016],
                [-16.307361999999898, 20.337084],
                [-16.307637999999827, 20.337084],
                [-16.307637999999827, 20.337915],
                [-16.307917, 20.337915],
                [-16.307917, 20.338194],
                [-16.308194999999898, 20.338194],
                [-16.308194999999898, 20.338472000000138],
                [-16.308471999999824, 20.338472000000138],
                [-16.308471999999824, 20.338751000000116],
                [-16.308748, 20.338751000000116],
                [-16.308748, 20.339027],
                [-16.309584, 20.339027],
                [-16.309584, 20.339306],
                [-16.310138999999879, 20.339306],
                [-16.310138999999879, 20.339582000000121],
                [-16.310693999999899, 20.339582000000121],
                [-16.310693999999899, 20.340416],
                [-16.310969999999827, 20.340416],
                [-16.310969999999827, 20.342361],
                [-16.311253, 20.342361],
                [-16.311253, 20.342916000000116],
                [-16.311526999999899, 20.342916000000116],
                [-16.311526999999899, 20.343752],
                [-16.311805999999876, 20.343752],
                [-16.311805999999876, 20.344305000000134],
                [-16.312084, 20.344305000000134],
                [-16.312084, 20.344584000000111],
                [-16.312362999999891, 20.344584000000111],
                [-16.312362999999891, 20.345139],
                [-16.312638999999876, 20.345139],
                [-16.312638999999876, 20.346251],
                [-16.312916, 20.346251],
                [-16.312916, 20.346527000000151],
                [-16.312638999999876, 20.346527000000151],
                [-16.312638999999876, 20.348473000000183],
                [-16.312916, 20.348473000000183],
                [-16.312916, 20.349028],
                [-16.313192, 20.349028],
                [-16.313192, 20.349304],
                [-16.313475, 20.349304],
                [-16.313475, 20.349583],
                [-16.314305999999874, 20.349583],
                [-16.314305999999874, 20.349861],
                [-16.314860999999894, 20.349861],
                [-16.314860999999894, 20.350140000000124],
                [-16.315139999999872, 20.350140000000124],
                [-16.315139999999872, 20.350416],
                [-16.315416, 20.350416],
                [-16.315416, 20.350695],
                [-16.315694999999891, 20.350695],
                [-16.315694999999891, 20.351250000000107],
                [-16.315970999999877, 20.351250000000107],
                [-16.315970999999877, 20.351526],
                [-16.31625, 20.351526],
                [-16.31625, 20.351805000000184],
                [-16.316527999999892, 20.351805000000184],
                [-16.316527999999892, 20.352083000000107],
                [-16.316806999999869, 20.352083000000107],
                [-16.316806999999869, 20.352636],
                [-16.317083, 20.352636],
                [-16.317083, 20.352917],
                [-16.317362, 20.352917],
                [-16.317362, 20.353472000000124],
                [-16.317637999999874, 20.353472000000124],
                [-16.317637999999874, 20.354031000000191],
                [-16.317917, 20.354031000000191],
                [-16.317917, 20.354584000000102],
                [-16.318195, 20.354584000000102],
                [-16.318195, 20.355141000000174],
                [-16.318471999999872, 20.355141000000174],
                [-16.318471999999872, 20.355417000000102],
                [-16.31875, 20.355417000000102],
                [-16.31875, 20.356253000000152],
                [-16.319029, 20.356253000000152],
                [-16.319029, 20.356527],
                [-16.319304999999872, 20.356527],
                [-16.319304999999872, 20.35680600000012],
                [-16.319859999999892, 20.35680600000012],
                [-16.319859999999892, 20.357363000000191],
                [-16.32013899999987, 20.357363000000191],
                [-16.32013899999987, 20.357916000000102],
                [-16.320417, 20.357916000000102],
                [-16.320417, 20.358473000000174],
                [-16.320694, 20.358473000000174],
                [-16.320694, 20.358749000000103],
                [-16.32097199999987, 20.358749000000103],
                [-16.32097199999987, 20.359585000000152],
                [-16.321251, 20.359585000000152],
                [-16.321251, 20.360416],
                [-16.321527, 20.360416],
                [-16.321527, 20.36097100000012],
                [-16.321806, 20.36097100000012],
                [-16.321806, 20.36125],
                [-16.322083, 20.36125],
                [-16.322083, 20.361528000000192],
                [-16.322358999999892, 20.361528000000192],
                [-16.322358999999892, 20.36180300000018],
                [-16.322916, 20.36180300000018],
                [-16.322916, 20.361528000000192],
                [-16.323195, 20.361528000000192],
                [-16.323195, 20.365137000000175],
                [-16.32347099999987, 20.365137000000175],
                [-16.32347099999987, 20.365419],
                [-16.324028, 20.365419],
                [-16.324028, 20.36763800000017],
                [-16.324305, 20.36763800000017],
                [-16.324306, 20.369862000000126],
                [-16.324581, 20.369862000000126],
                [-16.324581, 20.370695000000126],
                [-16.324863, 20.370695000000126],
                [-16.324863, 20.37125],
                [-16.325138, 20.37125],
                [-16.325138, 20.371529],
                [-16.325417, 20.371529],
                [-16.325417, 20.372084000000143],
                [-16.325695, 20.372084000000143],
                [-16.325695, 20.37236],
                [-16.32597399999986, 20.37236],
                [-16.32597399999986, 20.372639],
                [-16.32625, 20.372639],
                [-16.32625, 20.372917000000143],
                [-16.326529, 20.372917000000143],
                [-16.326529, 20.373194000000126],
                [-16.32680299999987, 20.373194000000126],
                [-16.32680299999987, 20.374027000000126],
                [-16.327084, 20.374027000000126],
                [-16.327084, 20.374584],
                [-16.32736, 20.374584],
                [-16.32736, 20.379026],
                [-16.327084, 20.379026],
                [-16.327084, 20.381527],
                [-16.32680299999987, 20.381527],
                [-16.32680299999987, 20.382917000000134],
                [-16.326529, 20.382917000000134],
                [-16.326529, 20.384027000000117],
                [-16.326614, 20.384027000000117],
                [-16.32680299999987, 20.384027000000117],
                [-16.32680299999987, 20.384863],
                [-16.32680299999987, 20.385695000000112],
                [-16.326998, 20.385695000000112],
                [-16.327084, 20.385695000000112],
                [-16.327084, 20.386089],
                [-16.327084, 20.387083000000189],
                [-16.327310999999895, 20.387094],
                [-16.327435, 20.387638],
                [-16.327638999999863, 20.387638],
                [-16.327638999999863, 20.388472],
                [-16.327732, 20.388472],
                [-16.327917, 20.388472],
                [-16.327917, 20.388691],
                [-16.327917, 20.389305],
                [-16.328196, 20.389305],
                [-16.328196, 20.389862000000107],
                [-16.328471999999863, 20.389862000000107],
                [-16.328471999999863, 20.391251000000125],
                [-16.328751, 20.391251000000125],
                [-16.328751, 20.391806],
                [-16.329027, 20.391806],
                [-16.329027, 20.393473],
                [-16.32930599999986, 20.393473],
                [-16.32930599999986, 20.393747000000189],
                [-16.329861, 20.393747000000189],
                [-16.329861, 20.396252000000175],
                [-16.330138999999861, 20.396252000000175],
                [-16.330138999999861, 20.397638],
                [-16.329861, 20.397638],
                [-16.329861, 20.398195000000158],
                [-16.330138999999861, 20.398195000000158],
                [-16.330138999999861, 20.399305],
                [-16.330416, 20.399305],
                [-16.330416, 20.399860000000103],
                [-16.330694, 20.399860000000103],
                [-16.330694, 20.400417000000175],
                [-16.330973, 20.400417000000175],
                [-16.330973, 20.400696000000153],
                [-16.331249, 20.400696000000153],
                [-16.331249, 20.400972],
                [-16.331806, 20.400972],
                [-16.331806, 20.401527000000158],
                [-16.332083, 20.401527000000158],
                [-16.332083, 20.401806],
                [-16.333470999999861, 20.401806],
                [-16.333470999999861, 20.401251],
                [-16.334862, 20.401251],
                [-16.334862, 20.402639],
                [-16.335138, 20.402639],
                [-16.335138, 20.40291400000018],
                [-16.335417, 20.40291400000018],
                [-16.335417, 20.404861000000153],
                [-16.335972, 20.404861000000153],
                [-16.335972, 20.404583],
                [-16.336805, 20.404583],
                [-16.336805, 20.404306],
                [-16.337084, 20.404306],
                [-16.337084, 20.404028000000153],
                [-16.33736, 20.404028000000153],
                [-16.33736, 20.403473],
                [-16.337639, 20.403473],
                [-16.337639, 20.403194],
                [-16.337917, 20.403194],
                [-16.337917, 20.402082],
                [-16.338194, 20.402082],
                [-16.338224, 20.398474000000192],
                [-16.338457, 20.398506000000168],
                [-16.33847, 20.398195000000158],
                [-16.339863, 20.398195000000158],
                [-16.339863, 20.398474000000192],
                [-16.340139, 20.398474000000192],
                [-16.340139, 20.399027000000103],
                [-16.340414, 20.399027000000103],
                [-16.340414, 20.399391],
                [-16.340414, 20.399584000000175],
                [-16.340718999999865, 20.399529],
                [-16.340694, 20.400139],
                [-16.340975, 20.400139],
                [-16.340975, 20.400696000000153],
                [-16.341249, 20.400696000000153],
                [-16.341249, 20.401527000000158],
                [-16.341528, 20.401527000000158],
                [-16.341528, 20.401806],
                [-16.341806, 20.401806],
                [-16.341806, 20.402361],
                [-16.342084999999884, 20.402361],
                [-16.342084999999884, 20.402639],
                [-16.342361, 20.402639],
                [-16.342361, 20.403194],
                [-16.342638, 20.403194],
                [-16.342638, 20.403749000000175],
                [-16.342915999999832, 20.403749000000175],
                [-16.342915999999832, 20.404028000000153],
                [-16.343197, 20.404028000000153],
                [-16.343197, 20.404306],
                [-16.343472, 20.404306],
                [-16.343472, 20.404583],
                [-16.344028, 20.404583],
                [-16.344028, 20.404861000000153],
                [-16.344583999999884, 20.404861000000153],
                [-16.344583999999884, 20.405136],
                [-16.344862, 20.405136],
                [-16.344862, 20.405416],
                [-16.345139, 20.405416],
                [-16.345139, 20.405695],
                [-16.344583999999884, 20.405695],
                [-16.344583999999884, 20.405972],
                [-16.345139, 20.405972],
                [-16.345139, 20.406248000000176],
                [-16.345972, 20.406248000000176],
                [-16.345972, 20.40653],
                [-16.346806, 20.40653],
                [-16.346806, 20.406805],
                [-16.347083999999882, 20.406805],
                [-16.347083999999882, 20.407084],
                [-16.347361, 20.407084],
                [-16.347361, 20.407360000000153],
                [-16.347083999999882, 20.407360000000153],
                [-16.347083999999882, 20.40764],
                [-16.346806, 20.40764],
                [-16.346806, 20.407919],
                [-16.345972, 20.407919],
                [-16.345972, 20.408194],
                [-16.345414999999832, 20.408194],
                [-16.345414999999832, 20.40847],
                [-16.344583999999884, 20.40847],
                [-16.344583999999884, 20.408749000000171],
                [-16.343749999999829, 20.408749000000171],
                [-16.343749999999829, 20.409027],
                [-16.343197, 20.409027],
                [-16.343197, 20.408749000000171],
                [-16.341806, 20.408749000000171],
                [-16.341806, 20.409027],
                [-16.342084999999884, 20.409027],
                [-16.342084999999884, 20.409306],
                [-16.342915999999832, 20.409306],
                [-16.342915999999832, 20.409584000000166],
                [-16.344307, 20.409584000000166],
                [-16.344307, 20.409306],
                [-16.344862, 20.409306],
                [-16.344862, 20.409027],
                [-16.345139, 20.409027],
                [-16.345139, 20.408749000000171],
                [-16.345694, 20.408749000000171],
                [-16.345694, 20.40847],
                [-16.346806, 20.40847],
                [-16.346806, 20.409306],
                [-16.347083999999882, 20.409306],
                [-16.347083999999882, 20.409863000000144],
                [-16.347361, 20.409863000000144],
                [-16.347361, 20.410139],
                [-16.347637, 20.410139],
                [-16.347637, 20.410694000000149],
                [-16.347917999999879, 20.410694000000149],
                [-16.347917999999879, 20.410973000000126],
                [-16.348748999999884, 20.410973000000126],
                [-16.348748999999884, 20.411249],
                [-16.349028, 20.411249],
                [-16.349028, 20.411806000000126],
                [-16.349582999999882, 20.411806000000126],
                [-16.349582999999882, 20.412361],
                [-16.350142, 20.412361],
                [-16.350142, 20.413195000000144],
                [-16.349859, 20.413195000000144],
                [-16.349859, 20.41375],
                [-16.349582999999882, 20.41375],
                [-16.349582999999882, 20.414305000000127],
                [-16.349305999999899, 20.414305000000127],
                [-16.349305999999899, 20.414862],
                [-16.349028, 20.414862],
                [-16.349028, 20.415417000000161],
                [-16.348748999999884, 20.415417000000161],
                [-16.348748999999884, 20.415972],
                [-16.349859, 20.415972],
                [-16.349859, 20.415695],
                [-16.350142, 20.415695],
                [-16.350142, 20.415417000000161],
                [-16.350415999999882, 20.415417000000161],
                [-16.350415999999882, 20.415138000000127],
                [-16.350695, 20.415138000000127],
                [-16.350695, 20.414583],
                [-16.350971, 20.414583],
                [-16.350971, 20.414305000000127],
                [-16.352080999999885, 20.414356000000112],
                [-16.352080999999885, 20.414454],
                [-16.352080999999885, 20.414583],
                [-16.352177, 20.414583],
                [-16.352364, 20.414583],
                [-16.352364, 20.414839],
                [-16.352364, 20.415138000000127],
                [-16.352637999999899, 20.415138000000127],
                [-16.352637999999899, 20.41522],
                [-16.352637999999899, 20.415417000000161],
                [-16.352781, 20.415417000000161],
                [-16.353195, 20.415417000000161],
                [-16.353195, 20.415695],
                [-16.353474, 20.415695],
                [-16.353474, 20.416368000000148],
                [-16.353474, 20.417360000000144],
                [-16.353749999999877, 20.417360000000144],
                [-16.353749999999877, 20.417639000000122],
                [-16.354029, 20.417639000000122],
                [-16.354029, 20.418194],
                [-16.35458599999987, 20.418194],
                [-16.35458599999987, 20.418472000000122],
                [-16.354996, 20.418472000000122],
                [-16.355416999999875, 20.418472000000122],
                [-16.355416999999875, 20.4187510000001],
                [-16.356806, 20.4187510000001],
                [-16.356806, 20.419027],
                [-16.357081999999878, 20.419027],
                [-16.357083999999873, 20.4195840000001],
                [-16.357361, 20.4195840000001],
                [-16.357361, 20.420137],
                [-16.357639, 20.420137],
                [-16.357639, 20.42069400000014],
                [-16.35791799999987, 20.42069400000014],
                [-16.35791799999987, 20.420973000000117],
                [-16.358748999999875, 20.420973000000117],
                [-16.358748999999875, 20.42069400000014],
                [-16.359306, 20.42069400000014],
                [-16.359306, 20.420973000000117],
                [-16.359582999999873, 20.420973000000117],
                [-16.359582999999873, 20.421530000000189],
                [-16.359861, 20.421530000000189],
                [-16.359861, 20.4220830000001],
                [-16.360138, 20.4220830000001],
                [-16.360138, 20.422359],
                [-16.360415999999873, 20.422359],
                [-16.360415999999873, 20.42264],
                [-16.360971, 20.422642],
                [-16.360971, 20.4229160000001],
                [-16.36125, 20.4229160000001],
                [-16.36125, 20.423195000000135],
                [-16.361528, 20.423195000000135],
                [-16.361528, 20.423470000000123],
                [-16.36208299999987, 20.423470000000123],
                [-16.36208299999987, 20.424305000000118],
                [-16.361805, 20.424305000000118],
                [-16.361805, 20.424583],
                [-16.361528, 20.424583],
                [-16.361528, 20.424864000000184],
                [-16.36125, 20.424864000000184],
                [-16.36125, 20.425417],
                [-16.360971, 20.425417],
                [-16.360971, 20.425694000000135],
                [-16.359861, 20.425694000000135],
                [-16.359861, 20.425974],
                [-16.358748999999875, 20.425974],
                [-16.358748999999875, 20.42625],
                [-16.358194, 20.42625],
                [-16.358194, 20.426527000000135],
                [-16.35791799999987, 20.426527000000135],
                [-16.35791799999987, 20.426805],
                [-16.357361, 20.426805],
                [-16.357361, 20.427082],
                [-16.357083999999873, 20.427082],
                [-16.357081999999878, 20.42736100000019],
                [-16.356806, 20.42736100000019],
                [-16.356806, 20.427917],
                [-16.356250999999872, 20.427917],
                [-16.356250999999872, 20.428196000000185],
                [-16.355972, 20.428196000000185],
                [-16.355972, 20.428473],
                [-16.355696, 20.428473],
                [-16.355696, 20.428749],
                [-16.355139, 20.428776],
                [-16.355139, 20.42902800000013],
                [-16.35486, 20.429094],
                [-16.35486, 20.429583],
                [-16.35458599999987, 20.429583],
                [-16.35458599999987, 20.430140000000108],
                [-16.354304999999897, 20.430140000000108],
                [-16.354304999999897, 20.430695000000185],
                [-16.354029, 20.430695000000185],
                [-16.354029, 20.43125],
                [-16.353749999999877, 20.43125],
                [-16.353749999999877, 20.43152600000019],
                [-16.353474, 20.43152600000019],
                [-16.353474, 20.432531],
                [-16.353474, 20.435694],
                [-16.353749999999877, 20.435694],
                [-16.353749999999877, 20.436253],
                [-16.354029, 20.436253],
                [-16.354029, 20.436806000000104],
                [-16.354304999999897, 20.436806000000104],
                [-16.354304999999897, 20.437639000000104],
                [-16.35458599999987, 20.437639000000104],
                [-16.35458599999987, 20.43819400000018],
                [-16.35486, 20.43819400000018],
                [-16.35486, 20.438749],
                [-16.355139, 20.438749],
                [-16.355139, 20.439306000000158],
                [-16.355416999999875, 20.439306000000158],
                [-16.355416999999875, 20.439861],
                [-16.355696, 20.439861],
                [-16.355696, 20.440416],
                [-16.355972, 20.440416],
                [-16.355972, 20.440695000000176],
                [-16.356250999999872, 20.440695000000176],
                [-16.356250999999872, 20.44125],
                [-16.356527, 20.44125],
                [-16.356527, 20.441805000000159],
                [-16.356806, 20.441805000000159],
                [-16.356806, 20.442083],
                [-16.357081999999878, 20.442083],
                [-16.357083999999873, 20.442638000000159],
                [-16.357359, 20.442614000000162],
                [-16.357361, 20.443195],
                [-16.357672, 20.443184],
                [-16.357639, 20.44375],
                [-16.35791799999987, 20.4438],
                [-16.35791799999987, 20.444027000000176],
                [-16.358005999999875, 20.444027000000176],
                [-16.358194, 20.444027000000176],
                [-16.358225, 20.444584],
                [-16.358473, 20.444584],
                [-16.358473, 20.444860000000176],
                [-16.358748999999875, 20.444860000000176],
                [-16.358748999999875, 20.445139000000154],
                [-16.359028, 20.445139000000154],
                [-16.359028, 20.445694],
                [-16.359306, 20.445694],
                [-16.359306, 20.445972000000154],
                [-16.359582999999873, 20.445972000000154],
                [-16.359582999999873, 20.446527],
                [-16.359861, 20.446527],
                [-16.359861, 20.446806],
                [-16.360138, 20.446806],
                [-16.360138, 20.447359000000176],
                [-16.360415999999873, 20.447359000000176],
                [-16.360415999999873, 20.447916],
                [-16.360695, 20.447916],
                [-16.360695, 20.448195],
                [-16.360971, 20.448195],
                [-16.360971, 20.448473000000149],
                [-16.36125, 20.448473000000149],
                [-16.36125, 20.449306000000149],
                [-16.361528, 20.449306000000149],
                [-16.361528, 20.449862000000167],
                [-16.361805, 20.449862000000167],
                [-16.361805, 20.450417],
                [-16.36208299999987, 20.450417],
                [-16.36208299999987, 20.450970000000154],
                [-16.362362, 20.450970000000154],
                [-16.362362, 20.45125],
                [-16.362638, 20.45125],
                [-16.362638, 20.451805000000149],
                [-16.362916999999868, 20.451805000000149],
                [-16.362916999999868, 20.452084000000127],
                [-16.363194, 20.452084000000127],
                [-16.363194, 20.452639],
                [-16.36347, 20.452639],
                [-16.36347, 20.453194000000167],
                [-16.363752, 20.453194000000167],
                [-16.363752, 20.453472],
                [-16.364027, 20.453472],
                [-16.364027, 20.454306000000145],
                [-16.364305, 20.454306000000145],
                [-16.364305, 20.454584],
                [-16.364581999999871, 20.454584],
                [-16.364581999999871, 20.455139000000145],
                [-16.364861, 20.455139000000145],
                [-16.364861, 20.456249000000128],
                [-16.365141, 20.456249000000128],
                [-16.365141, 20.457083],
                [-16.365416999999866, 20.457083],
                [-16.365416999999866, 20.45764000000014],
                [-16.365692, 20.45764000000014],
                [-16.365692, 20.458195],
                [-16.365971, 20.458195],
                [-16.365971, 20.459026],
                [-16.366248999999868, 20.459026],
                [-16.366248999999868, 20.460138],
                [-16.366528, 20.460138],
                [-16.366528, 20.460695000000101],
                [-16.366806, 20.460695000000101],
                [-16.366806, 20.461527],
                [-16.367085, 20.461527],
                [-16.367085, 20.462084000000118],
                [-16.367361, 20.462084000000118],
                [-16.367361, 20.46264100000019],
                [-16.36764, 20.46264100000019],
                [-16.36764, 20.462917000000118],
                [-16.367915999999866, 20.462917000000118],
                [-16.367915999999866, 20.463194000000101],
                [-16.368195, 20.463194000000101],
                [-16.368195, 20.463472],
                [-16.368473, 20.463472],
                [-16.368473, 20.464027000000101],
                [-16.36875, 20.464027000000101],
                [-16.36875, 20.465139000000136],
                [-16.369028, 20.465139000000136],
                [-16.369028, 20.466528],
                [-16.369307, 20.466528],
                [-16.369307, 20.467638000000136],
                [-16.369583, 20.467638000000136],
                [-16.369583, 20.468193],
                [-16.369862, 20.468193],
                [-16.369862, 20.469028],
                [-16.370138, 20.469028],
                [-16.370138, 20.469307000000185],
                [-16.370417, 20.469307000000185],
                [-16.370417, 20.469584000000168],
                [-16.370695, 20.469584000000168],
                [-16.370695, 20.470415],
                [-16.370972, 20.470415],
                [-16.370972, 20.472361],
                [-16.37125, 20.472361],
                [-16.37125, 20.473194],
                [-16.371529, 20.473194],
                [-16.371529, 20.474028],
                [-16.371805, 20.474028],
                [-16.371805, 20.475142000000176],
                [-16.372084, 20.475142000000176],
                [-16.372084, 20.475973000000181],
                [-16.37236, 20.475973000000181],
                [-16.37236, 20.477083000000164],
                [-16.372639, 20.477083000000164],
                [-16.372639, 20.478195],
                [-16.372917, 20.478195],
                [-16.372917, 20.479029],
                [-16.373194, 20.479029],
                [-16.373194, 20.47986],
                [-16.373472, 20.47986],
                [-16.373472, 20.480694],
                [-16.373751, 20.480694],
                [-16.373751, 20.481251],
                [-16.374027, 20.481251],
                [-16.374027, 20.481806000000176],
                [-16.374306, 20.481806000000176],
                [-16.374306, 20.482361],
                [-16.374584, 20.482361],
                [-16.374584, 20.483194],
                [-16.374861, 20.483194],
                [-16.374861, 20.484306],
                [-16.375139, 20.484306],
                [-16.375139, 20.485695],
                [-16.375416, 20.485695],
                [-16.375416, 20.486526],
                [-16.375694, 20.486526],
                [-16.375694, 20.488194],
                [-16.375973, 20.488195000000132],
                [-16.375973, 20.489305000000172],
                [-16.375694, 20.489305000000172],
                [-16.37565, 20.492643000000101],
                [-16.375973, 20.49264],
                [-16.375973, 20.495695],
                [-16.376249, 20.495695],
                [-16.376249, 20.496805],
                [-16.376527999999837, 20.496805],
                [-16.376527999999837, 20.497084],
                [-16.376806, 20.497084],
                [-16.376806, 20.497639000000106],
                [-16.377083, 20.497639000000106],
                [-16.377083, 20.498194],
                [-16.377359, 20.498194],
                [-16.377359, 20.500137],
                [-16.377642, 20.500137],
                [-16.377642, 20.500416],
                [-16.377916, 20.500416],
                [-16.377916, 20.500973000000101],
                [-16.378194999999835, 20.500973000000101],
                [-16.378194999999835, 20.501530000000173],
                [-16.378471, 20.501530000000173],
                [-16.378471, 20.502916000000141],
                [-16.378752, 20.502916000000141],
                [-16.378752, 20.503469],
                [-16.379027999999835, 20.503469],
                [-16.379027999999835, 20.504305000000102],
                [-16.379305, 20.504305000000102],
                [-16.379305, 20.504864000000168],
                [-16.379027999999835, 20.504864000000168],
                [-16.379027999999835, 20.50625],
                [-16.378752, 20.50625],
                [-16.378752, 20.507084000000191],
                [-16.379027999999835, 20.507084000000191],
                [-16.379027999999835, 20.509861000000114],
                [-16.379305, 20.509861000000114],
                [-16.379305, 20.510418000000186],
                [-16.379027999999835, 20.510418000000186],
                [-16.379027999999835, 20.511806],
                [-16.379305, 20.511806],
                [-16.379305, 20.512917000000186],
                [-16.379581, 20.512917000000186],
                [-16.379581, 20.51319500000011],
                [-16.379863999999884, 20.51319500000011],
                [-16.379863999999884, 20.514858000000174],
                [-16.379581, 20.514860000000169],
                [-16.379581, 20.51597],
                [-16.379305, 20.51597],
                [-16.379305, 20.516806],
                [-16.379027999999835, 20.516825],
                [-16.379027999999835, 20.517363000000159],
                [-16.378752, 20.517363000000159],
                [-16.378752, 20.518473000000142],
                [-16.379027999999835, 20.518473000000142],
                [-16.379027999999835, 20.519028],
                [-16.379305, 20.519028],
                [-16.379305, 20.519585000000177],
                [-16.379646, 20.519598000000144],
                [-16.379581, 20.519861],
                [-16.379731999999819, 20.519861],
                [-16.379863999999884, 20.519861],
                [-16.379875, 20.520416000000182],
                [-16.380138, 20.520416000000182],
                [-16.380138, 20.520971],
                [-16.380417, 20.520971],
                [-16.380417, 20.52152800000016],
                [-16.380694999999889, 20.52152800000016],
                [-16.380694999999889, 20.522083],
                [-16.380974, 20.522083],
                [-16.380974, 20.522362],
                [-16.38125, 20.522362],
                [-16.38125, 20.522638000000143],
                [-16.381526999999835, 20.522638000000143],
                [-16.381526999999835, 20.523195],
                [-16.381805, 20.523195],
                [-16.381805, 20.523750000000177],
                [-16.382086, 20.523750000000177],
                [-16.382086, 20.52402700000016],
                [-16.382359999999835, 20.52402700000016],
                [-16.382359999999835, 20.524305],
                [-16.382639, 20.524305],
                [-16.382639, 20.52486000000016],
                [-16.382917, 20.52486000000016],
                [-16.382917, 20.525139000000138],
                [-16.383195999999884, 20.525139000000138],
                [-16.383195999999884, 20.525417],
                [-16.383472, 20.525417],
                [-16.383472, 20.525972000000138],
                [-16.383751, 20.525972000000138],
                [-16.383751, 20.526251000000116],
                [-16.38402699999989, 20.526251000000116],
                [-16.38402699999989, 20.527916],
                [-16.384308, 20.527916],
                [-16.384308, 20.529581000000178],
                [-16.384583, 20.529581000000178],
                [-16.384583, 20.530695000000151],
                [-16.384860999999887, 20.530695000000151],
                [-16.384860999999887, 20.531252],
                [-16.385139, 20.531252],
                [-16.385139, 20.531805000000134],
                [-16.385418, 20.531805000000134],
                [-16.385418, 20.532364],
                [-16.385694999999885, 20.532364],
                [-16.385694999999885, 20.533472],
                [-16.385973, 20.533472],
                [-16.385973, 20.534306000000129],
                [-16.38625, 20.534306000000129],
                [-16.38625, 20.534861],
                [-16.38652599999989, 20.534861],
                [-16.38652599999989, 20.535418000000107],
                [-16.386805, 20.535418000000107],
                [-16.386806, 20.536528000000146],
                [-16.387083, 20.536528000000146],
                [-16.387083, 20.537361000000146],
                [-16.387361999999882, 20.537361000000146],
                [-16.387361999999882, 20.538195],
                [-16.387638, 20.538195],
                [-16.387638, 20.538750000000107],
                [-16.387917, 20.538750000000107],
                [-16.387917, 20.539305],
                [-16.388194999999882, 20.539305],
                [-16.388194999999882, 20.540138],
                [-16.388472, 20.540138],
                [-16.388472, 20.540695],
                [-16.388748, 20.540695],
                [-16.388748, 20.541248],
                [-16.38902899999988, 20.541248],
                [-16.38902899999988, 20.542084000000102],
                [-16.389305, 20.542084000000102],
                [-16.389305, 20.542917000000102],
                [-16.389584, 20.542917000000102],
                [-16.389584, 20.543753],
                [-16.38985799999989, 20.543753],
                [-16.389859999999885, 20.544584],
                [-16.390139, 20.544584],
                [-16.390139, 20.54513900000012],
                [-16.390417, 20.54513900000012],
                [-16.390417, 20.545694],
                [-16.390693999999883, 20.545694],
                [-16.390693999999883, 20.546528],
                [-16.39097, 20.546528],
                [-16.39097, 20.547085],
                [-16.391253, 20.547085],
                [-16.391253, 20.547361],
                [-16.391526999999883, 20.547361],
                [-16.391526999999883, 20.547916],
                [-16.391806, 20.547916],
                [-16.391806, 20.54847100000012],
                [-16.392082, 20.54847100000012],
                [-16.392082, 20.54875],
                [-16.392362999999875, 20.54875],
                [-16.392362999999875, 20.549307000000169],
                [-16.392639, 20.549307000000169],
                [-16.392639, 20.549862],
                [-16.392916, 20.549862],
                [-16.392916, 20.550417],
                [-16.393191999999885, 20.550417],
                [-16.393191999999885, 20.550972000000115],
                [-16.393475, 20.550972000000115],
                [-16.393475, 20.552084],
                [-16.393749, 20.552084],
                [-16.393749, 20.552637000000175],
                [-16.394027999999878, 20.552637000000175],
                [-16.394027999999878, 20.553194],
                [-16.394306, 20.553194],
                [-16.394306, 20.553747],
                [-16.394585, 20.553747],
                [-16.394585, 20.554306],
                [-16.394860999999878, 20.554306],
                [-16.394860999999878, 20.555141],
                [-16.39514, 20.555141],
                [-16.39514, 20.555695],
                [-16.395416, 20.555695],
                [-16.395416, 20.555973000000165],
                [-16.395694999999876, 20.555973000000165],
                [-16.395694999999876, 20.556252000000143],
                [-16.395971, 20.556252000000143],
                [-16.395971, 20.557362000000182],
                [-16.39625, 20.557362000000182],
                [-16.39625, 20.557917],
                [-16.396527999999876, 20.557917],
                [-16.396527999999876, 20.55847400000016],
                [-16.396807, 20.55847400000016],
                [-16.396807, 20.559029],
                [-16.397083, 20.559029],
                [-16.397083, 20.559631000000138],
                [-16.397083, 20.55986],
                [-16.397361999999873, 20.55986],
                [-16.397361999999873, 20.559946],
                [-16.397361999999873, 20.560694000000183],
                [-16.397638, 20.560694000000183],
                [-16.397638, 20.561251],
                [-16.397917, 20.561251],
                [-16.397917, 20.561806000000161],
                [-16.398194999999873, 20.561806000000161],
                [-16.398194999999873, 20.562361],
                [-16.398472, 20.562361],
                [-16.398472, 20.562916000000143],
                [-16.39875, 20.562916000000143],
                [-16.39875, 20.563194],
                [-16.399028999999871, 20.563194],
                [-16.399028999999871, 20.563749000000143],
                [-16.399305, 20.563749000000143],
                [-16.399305, 20.564583],
                [-16.399584, 20.564583],
                [-16.399584, 20.565416],
                [-16.399859999999876, 20.565416],
                [-16.399859999999876, 20.565695],
                [-16.402411, 20.565695],
                [-16.402639, 20.565695],
                [-16.402652999999873, 20.565971000000161],
                [-16.403473, 20.565971000000161],
                [-16.403473, 20.566250000000139],
                [-16.403749, 20.566250000000139],
                [-16.403749, 20.566526],
                [-16.404028, 20.566526],
                [-16.404028, 20.566805],
                [-16.404581, 20.566805],
                [-16.404581, 20.567083000000139],
                [-16.405416, 20.567083000000139],
                [-16.405416, 20.567362000000116],
                [-16.405692999999872, 20.567362000000116],
                [-16.405692999999872, 20.567917],
                [-16.405973, 20.567917],
                [-16.405973, 20.568472000000156],
                [-16.406252, 20.568472000000156],
                [-16.406252, 20.568748],
                [-16.406528, 20.568748],
                [-16.406528, 20.569027],
                [-16.406803, 20.569027],
                [-16.406803, 20.569584000000134],
                [-16.407082, 20.569584000000134],
                [-16.407082, 20.570141],
                [-16.407364, 20.570141],
                [-16.407364, 20.570692000000122],
                [-16.407639, 20.570692000000122],
                [-16.407639, 20.570972],
                [-16.407917, 20.570972],
                [-16.407917, 20.571527000000117],
                [-16.408195, 20.571527000000117],
                [-16.408195, 20.571806000000151],
                [-16.408472, 20.571806000000151],
                [-16.408472, 20.572081000000139],
                [-16.408751, 20.572081000000139],
                [-16.408751, 20.572363],
                [-16.409027, 20.572363],
                [-16.409027, 20.572639000000152],
                [-16.409306, 20.572639000000152],
                [-16.409306, 20.572916000000134],
                [-16.409586, 20.572916000000134],
                [-16.409586, 20.573195000000112],
                [-16.410139, 20.573195000000112],
                [-16.410139, 20.573475],
                [-16.410694, 20.573475],
                [-16.410694, 20.57375],
                [-16.410972999999842, 20.57375],
                [-16.410972999999842, 20.574028000000112],
                [-16.411249, 20.574028000000112],
                [-16.411249, 20.574306],
                [-16.411528, 20.574306],
                [-16.411528, 20.574583],
                [-16.411805999999842, 20.574583],
                [-16.411805999999842, 20.574862],
                [-16.412361, 20.574862],
                [-16.412361, 20.575138000000152],
                [-16.412916, 20.575138000000152],
                [-16.412916, 20.57541700000013],
                [-16.413195, 20.57541700000013],
                [-16.413195, 20.575697],
                [-16.41375, 20.575697],
                [-16.41375, 20.575972],
                [-16.414028, 20.575972],
                [-16.414028, 20.57625000000013],
                [-16.414304999999842, 20.57625000000013],
                [-16.414304999999842, 20.576529000000107],
                [-16.414862, 20.576529000000107],
                [-16.414862, 20.576805],
                [-16.416248, 20.576805],
                [-16.416248, 20.577084000000184],
                [-16.416529, 20.577084000000184],
                [-16.416529, 20.579027],
                [-16.41680499999984, 20.579027],
                [-16.41680499999984, 20.579306],
                [-16.417084, 20.579306],
                [-16.417084, 20.579584000000125],
                [-16.41736, 20.579584000000125],
                [-16.41736, 20.579861000000108],
                [-16.417638999999895, 20.579861000000108],
                [-16.417638999999895, 20.580137],
                [-16.417917, 20.580137],
                [-16.417917, 20.580416000000184],
                [-16.418194, 20.580420000000174],
                [-16.418194, 20.580694000000108],
                [-16.418753, 20.580694000000108],
                [-16.418753, 20.580973],
                [-16.421528, 20.580973],
                [-16.421528, 20.582083000000125],
                [-16.42180599999989, 20.582083000000125],
                [-16.42180599999989, 20.583469],
                [-16.422085, 20.583469],
                [-16.422085, 20.583752000000175],
                [-16.422637999999893, 20.583752000000175],
                [-16.422637999999893, 20.584305],
                [-16.422916, 20.584305],
                [-16.422916, 20.585138],
                [-16.423197, 20.585138],
                [-16.423197, 20.585974000000192],
                [-16.422916, 20.585974000000192],
                [-16.422916, 20.586250000000121],
                [-16.422637999999893, 20.586250000000121],
                [-16.422637999999893, 20.586805],
                [-16.422916, 20.586805],
                [-16.422916, 20.587084000000175],
                [-16.42375, 20.587084000000175],
                [-16.42375, 20.587360000000103],
                [-16.424028, 20.587360000000103],
                [-16.424028, 20.587639],
                [-16.424306999999885, 20.587639],
                [-16.424306999999885, 20.589582000000121],
                [-16.424583, 20.589582000000121],
                [-16.424583, 20.590973],
                [-16.424862, 20.590973],
                [-16.424862, 20.592636],
                [-16.42513799999989, 20.592636],
                [-16.42513799999989, 20.593472],
                [-16.425417, 20.593472],
                [-16.425417, 20.593748000000176],
                [-16.425694, 20.593748000000176],
                [-16.425694, 20.59403],
                [-16.425971999999888, 20.59403],
                [-16.425971999999888, 20.594305],
                [-16.42625, 20.594305],
                [-16.42625, 20.594584],
                [-16.426529, 20.594584],
                [-16.426529, 20.594860000000153],
                [-16.426804999999888, 20.594860000000153],
                [-16.426804999999888, 20.595139000000188],
                [-16.427084, 20.595139000000188],
                [-16.427084, 20.595419],
                [-16.427361, 20.595419],
                [-16.427361, 20.59597],
                [-16.427636999999891, 20.59597],
                [-16.427636999999891, 20.596527],
                [-16.427917, 20.596527],
                [-16.427917, 20.596806],
                [-16.428194, 20.596806],
                [-16.428194, 20.597918],
                [-16.428472999999883, 20.597918],
                [-16.428472999999883, 20.599028],
                [-16.428749, 20.599028],
                [-16.428749, 20.599306000000126],
                [-16.429305999999883, 20.599306000000126],
                [-16.429305999999883, 20.599861],
                [-16.429028, 20.599861],
                [-16.429028, 20.601528000000144],
                [-16.428749, 20.601528000000144],
                [-16.428749, 20.601805000000127],
                [-16.428472999999883, 20.601805000000127],
                [-16.428472999999883, 20.602638000000127],
                [-16.428194, 20.602638000000127],
                [-16.428194, 20.603750000000161],
                [-16.428472999999883, 20.603750000000161],
                [-16.428472999999883, 20.604029000000139],
                [-16.429028, 20.604029000000139],
                [-16.429028, 20.604584],
                [-16.429305999999883, 20.604584],
                [-16.429305999999883, 20.605972000000122],
                [-16.429583, 20.605972000000122],
                [-16.429583, 20.606251000000157],
                [-16.429859, 20.606251000000157],
                [-16.429859, 20.607361000000139],
                [-16.430139999999881, 20.607361000000139],
                [-16.430139999999881, 20.610138],
                [-16.429859, 20.610138],
                [-16.429859, 20.611805000000118],
                [-16.430139999999881, 20.611805000000118],
                [-16.430139999999881, 20.612364000000184],
                [-16.430416, 20.612364000000184],
                [-16.430416, 20.612638000000118],
                [-16.430695, 20.612638000000118],
                [-16.430695, 20.612917],
                [-16.431528, 20.612917],
                [-16.431528, 20.613194000000135],
                [-16.431804999999883, 20.613195000000189],
                [-16.431804999999883, 20.615696000000185],
                [-16.431528, 20.615696000000185],
                [-16.431528, 20.615973],
                [-16.43125, 20.615973],
                [-16.43125, 20.61652800000013],
                [-16.430970999999886, 20.61652800000013],
                [-16.430970999999886, 20.616804],
                [-16.430695, 20.616804],
                [-16.430695, 20.620136],
                [-16.430970999999886, 20.620136],
                [-16.430970999999886, 20.621531000000175],
                [-16.43125, 20.621531000000175],
                [-16.43125, 20.625416],
                [-16.430970999999886, 20.625416],
                [-16.430970999999886, 20.625973000000158],
                [-16.43125, 20.625973000000158],
                [-16.43125, 20.627916],
                [-16.431528, 20.627916],
                [-16.431528, 20.629028000000176],
                [-16.43125, 20.629028000000176],
                [-16.43125, 20.63125],
                [-16.430970999999886, 20.63125],
                [-16.430970999999886, 20.635695],
                [-16.43125, 20.635695],
                [-16.43125, 20.636806000000149],
                [-16.431528, 20.636806000000149],
                [-16.431528, 20.638474000000144],
                [-16.43125, 20.638474000000144],
                [-16.43125, 20.63875],
                [-16.430970999999886, 20.63875],
                [-16.430970999999886, 20.640972],
                [-16.430695, 20.640972],
                [-16.430695, 20.641251],
                [-16.430970999999886, 20.641251],
                [-16.430943, 20.641523000000177],
                [-16.431528, 20.641527000000167],
                [-16.431528, 20.641251],
                [-16.431804999999883, 20.641251],
                [-16.431804999999883, 20.640694000000167],
                [-16.432081, 20.640694000000167],
                [-16.432081, 20.640417000000127],
                [-16.432364, 20.640417000000127],
                [-16.432364, 20.640139],
                [-16.432637999999884, 20.640139],
                [-16.432637999999884, 20.639862],
                [-16.432917, 20.639862],
                [-16.432917, 20.639584000000127],
                [-16.433473999999876, 20.639584000000127],
                [-16.433473999999876, 20.639305000000149],
                [-16.43375, 20.639305000000149],
                [-16.43375, 20.639029],
                [-16.434302999999886, 20.639029],
                [-16.434302999999886, 20.63875],
                [-16.434586, 20.63875],
                [-16.434586, 20.637081],
                [-16.43486, 20.637081],
                [-16.43486, 20.636806000000149],
                [-16.435417, 20.636806000000149],
                [-16.435417, 20.637081],
                [-16.435696, 20.637081],
                [-16.435696, 20.637360000000172],
                [-16.436251, 20.637360000000172],
                [-16.436251, 20.639029],
                [-16.436527, 20.639029],
                [-16.436527, 20.639305000000149],
                [-16.437084, 20.639305000000149],
                [-16.437084, 20.639029],
                [-16.437361, 20.639029],
                [-16.437361, 20.63875],
                [-16.437638999999876, 20.63875],
                [-16.437638999999876, 20.639029],
                [-16.438194, 20.639029],
                [-16.438194, 20.63875],
                [-16.438473, 20.63875],
                [-16.438473, 20.638195000000167],
                [-16.438749, 20.638195000000167],
                [-16.438749, 20.637638],
                [-16.439028, 20.637642],
                [-16.439028, 20.637360000000172],
                [-16.43911, 20.637360000000172],
                [-16.439306, 20.637360000000172],
                [-16.439306, 20.635141],
                [-16.439583, 20.635141],
                [-16.439583, 20.633472000000154],
                [-16.439861, 20.633472000000154],
                [-16.439861, 20.632267000000127],
                [-16.439861, 20.630972],
                [-16.440695, 20.630972],
                [-16.440695, 20.63125],
                [-16.440970999999877, 20.63125],
                [-16.440970999999877, 20.632360000000176],
                [-16.44125, 20.632360000000176],
                [-16.44125, 20.632629],
                [-16.44125, 20.632917],
                [-16.441528, 20.632917],
                [-16.441528, 20.633194],
                [-16.442638, 20.633194],
                [-16.442638, 20.633751000000132],
                [-16.442917, 20.633751000000132],
                [-16.442917, 20.634027],
                [-16.443246999999872, 20.634010000000103],
                [-16.443195, 20.634583],
                [-16.443447, 20.634588000000122],
                [-16.443536999999822, 20.634836],
                [-16.444305, 20.634859000000176],
                [-16.444305, 20.634583],
                [-16.445139, 20.634583],
                [-16.445139, 20.635416],
                [-16.445417, 20.635416],
                [-16.445417, 20.635695],
                [-16.445692, 20.635695],
                [-16.445692, 20.635971000000154],
                [-16.446252999999899, 20.635971000000154],
                [-16.446252999999899, 20.636806000000149],
                [-16.446527, 20.636806000000149],
                [-16.446527, 20.637081],
                [-16.446806, 20.637081],
                [-16.446806, 20.637638],
                [-16.447083999999847, 20.637638],
                [-16.447083999999847, 20.638195000000167],
                [-16.447363, 20.638195000000167],
                [-16.447363, 20.63875],
                [-16.447639, 20.63875],
                [-16.447639, 20.639584000000127],
                [-16.447914, 20.639584000000127],
                [-16.447914, 20.639862],
                [-16.448475, 20.639862],
                [-16.448475, 20.640139],
                [-16.448749999999848, 20.640139],
                [-16.448749999999848, 20.641251],
                [-16.449028, 20.641251],
                [-16.449028, 20.642084],
                [-16.449303, 20.642084],
                [-16.449306, 20.642361],
                [-16.449582999999848, 20.642361],
                [-16.449582999999848, 20.642916000000127],
                [-16.449862, 20.642918000000122],
                [-16.449862, 20.644028000000162],
                [-16.450138, 20.644028000000162],
                [-16.450138, 20.644306],
                [-16.450972, 20.644306],
                [-16.450972, 20.644583],
                [-16.451249999999845, 20.644583],
                [-16.451249999999845, 20.644861000000162],
                [-16.451529, 20.644861000000162],
                [-16.451529, 20.64514000000014],
                [-16.451805, 20.64514000000014],
                [-16.451805, 20.645695],
                [-16.452084, 20.645695],
                [-16.452084, 20.645971000000145],
                [-16.45236, 20.645971000000145],
                [-16.452362, 20.646526],
                [-16.452639, 20.646526],
                [-16.452639, 20.646805],
                [-16.452917, 20.646805],
                [-16.452917, 20.647083000000123],
                [-16.453194, 20.647083000000123],
                [-16.453194, 20.647362000000101],
                [-16.453472, 20.647362000000101],
                [-16.453472, 20.647638],
                [-16.453750999999897, 20.647638],
                [-16.453750999999897, 20.648195000000101],
                [-16.454027, 20.648195000000101],
                [-16.454027, 20.64847200000014],
                [-16.454861, 20.64847200000014],
                [-16.454861, 20.648748],
                [-16.455416, 20.648748],
                [-16.455416, 20.649027],
                [-16.457359, 20.649027],
                [-16.457359, 20.64930500000014],
                [-16.459028, 20.64930500000014],
                [-16.459028, 20.649584000000118],
                [-16.459581, 20.649584000000118],
                [-16.459581, 20.649858],
                [-16.460416999999893, 20.649858],
                [-16.460416999999893, 20.65014100000019],
                [-16.46236, 20.65014100000019],
                [-16.46236, 20.650694000000101],
                [-16.462639, 20.650694000000101],
                [-16.462639, 20.65097],
                [-16.462916999999891, 20.65097],
                [-16.462916999999891, 20.651253],
                [-16.463196, 20.651253],
                [-16.463196, 20.651527000000101],
                [-16.463472, 20.651527000000101],
                [-16.463472, 20.651806000000136],
                [-16.464026999999817, 20.651806000000136],
                [-16.464026999999817, 20.652081000000123],
                [-16.464581999999893, 20.652081000000123],
                [-16.464581999999893, 20.652363],
                [-16.464860999999814, 20.652363],
                [-16.464860999999814, 20.652639000000136],
                [-16.465139, 20.652639000000136],
                [-16.465139, 20.652916000000118],
                [-16.465417999999886, 20.652916000000118],
                [-16.465417999999886, 20.653475000000185],
                [-16.465693999999814, 20.653475000000185],
                [-16.465693999999814, 20.653749000000118],
                [-16.465973, 20.653749000000118],
                [-16.465973, 20.65430600000019],
                [-16.466248999999891, 20.65430600000019],
                [-16.466248999999891, 20.654861],
                [-16.466528, 20.654861],
                [-16.466528, 20.655416],
                [-16.466806, 20.655416],
                [-16.466806, 20.655693],
                [-16.467082999999889, 20.655693],
                [-16.467082999999889, 20.65597200000019],
                [-16.467361, 20.65597200000019],
                [-16.467361, 20.656250000000114],
                [-16.467915999999889, 20.656250000000114],
                [-16.467915999999889, 20.656528],
                [-16.468195, 20.656528],
                [-16.468195, 20.656807000000185],
                [-16.468472, 20.656807000000185],
                [-16.468472, 20.657084],
                [-16.469028, 20.657084],
                [-16.469028, 20.657362],
                [-16.469583999999884, 20.657362],
                [-16.469583999999884, 20.657639000000131],
                [-16.469859999999869, 20.657639000000131],
                [-16.469859999999869, 20.657915],
                [-16.470139, 20.657915],
                [-16.470139, 20.658194],
                [-16.470416999999884, 20.658194],
                [-16.470416999999884, 20.658472000000131],
                [-16.470694, 20.658472000000131],
                [-16.470694, 20.658751000000109],
                [-16.471252, 20.658751000000109],
                [-16.471252, 20.659027],
                [-16.471527, 20.659027],
                [-16.471527, 20.659306000000186],
                [-16.471806, 20.659306000000186],
                [-16.471806, 20.659584000000109],
                [-16.472081999999887, 20.659584000000109],
                [-16.472081999999887, 20.659861],
                [-16.472639, 20.659861],
                [-16.472639, 20.660137000000191],
                [-16.472918, 20.660137000000191],
                [-16.472918, 20.660418000000163],
                [-16.473191999999869, 20.660418000000163],
                [-16.473191999999869, 20.660694],
                [-16.473475, 20.660694],
                [-16.473475, 20.660973],
                [-16.473748999999884, 20.660973],
                [-16.473748999999884, 20.661247],
                [-16.474028, 20.661247],
                [-16.474028, 20.661528],
                [-16.474306, 20.661528],
                [-16.474306, 20.661806],
                [-16.474585, 20.661806],
                [-16.474585, 20.662083000000109],
                [-16.474861, 20.662083000000109],
                [-16.474861, 20.662916000000109],
                [-16.47514, 20.662916000000109],
                [-16.47514, 20.663195],
                [-16.475973, 20.663195],
                [-16.475973, 20.663752000000159],
                [-16.47625, 20.663752000000159],
                [-16.47625, 20.664305],
                [-16.476528, 20.664305],
                [-16.476528, 20.664864],
                [-16.476807, 20.664864],
                [-16.476807, 20.665417000000105],
                [-16.477083, 20.665417000000105],
                [-16.477083, 20.665974000000176],
                [-16.477362, 20.665974000000176],
                [-16.477362, 20.666250000000105],
                [-16.477638, 20.666250000000105],
                [-16.477638, 20.666529],
                [-16.477917, 20.666529],
                [-16.477917, 20.667084000000159],
                [-16.478195, 20.667084000000159],
                [-16.478195, 20.667917000000159],
                [-16.478472, 20.667917000000159],
                [-16.478472, 20.668196],
                [-16.479029, 20.668196],
                [-16.479029, 20.668472],
                [-16.479584, 20.668472],
                [-16.479584, 20.6687510000001],
                [-16.480694, 20.6687510000001],
                [-16.480694, 20.669027],
                [-16.481251, 20.669027],
                [-16.481251, 20.669306000000176],
                [-16.481527, 20.669306000000176],
                [-16.481527, 20.670416000000159],
                [-16.481806, 20.670416000000159],
                [-16.481806, 20.670973],
                [-16.482082, 20.670973],
                [-16.482084, 20.672361],
                [-16.482361, 20.672361],
                [-16.482361, 20.672638000000177],
                [-16.482639, 20.672640000000172],
                [-16.482639, 20.6729160000001],
                [-16.483473, 20.6729160000001],
                [-16.483473, 20.673195],
                [-16.484027999999853, 20.673195],
                [-16.484027999999853, 20.673471000000177],
                [-16.484583, 20.673471000000177],
                [-16.484583, 20.673750000000155],
                [-16.485138, 20.673750000000155],
                [-16.485138, 20.674028],
                [-16.485416, 20.674028],
                [-16.485416, 20.674305],
                [-16.485971, 20.674305],
                [-16.485971, 20.674583000000155],
                [-16.486527999999851, 20.674583000000155],
                [-16.486527999999851, 20.674862000000132],
                [-16.487364, 20.674862000000132],
                [-16.487364, 20.675694],
                [-16.487638, 20.675695000000132],
                [-16.487638, 20.677641],
                [-16.487917, 20.677641],
                [-16.487917, 20.678473000000167],
                [-16.488195, 20.678473000000167],
                [-16.488195, 20.679585000000145],
                [-16.488474, 20.679585000000145],
                [-16.488474, 20.68014],
                [-16.48875, 20.68014],
                [-16.48875, 20.680695000000128],
                [-16.489026999999851, 20.680695000000128],
                [-16.489026999999851, 20.681805000000168],
                [-16.489305, 20.681805000000168],
                [-16.489305, 20.682917000000145],
                [-16.489586, 20.682917000000145],
                [-16.489586, 20.683750000000146],
                [-16.489861, 20.683750000000146],
                [-16.489861, 20.684305],
                [-16.490139, 20.684305],
                [-16.490139, 20.684860000000128],
                [-16.490417, 20.684860000000128],
                [-16.490417, 20.685415],
                [-16.490696, 20.685415],
                [-16.490696, 20.686527],
                [-16.490973, 20.686527],
                [-16.490973, 20.687361000000124],
                [-16.491249, 20.687361000000124],
                [-16.491249, 20.689306000000101],
                [-16.490973, 20.689306000000101],
                [-16.490973, 20.694027000000119],
                [-16.490696, 20.694027000000119],
                [-16.490696, 20.694860000000119],
                [-16.490417, 20.694860000000119],
                [-16.490417, 20.697361000000114],
                [-16.490696, 20.697361000000114],
                [-16.490696, 20.697918000000186],
                [-16.490973, 20.697918000000186],
                [-16.490973, 20.698473],
                [-16.490696, 20.698473],
                [-16.490696, 20.702358],
                [-16.490417, 20.702360000000169],
                [-16.490417, 20.706528000000105],
                [-16.490139, 20.706528000000105],
                [-16.490139, 20.707916000000182],
                [-16.489861, 20.707916000000182],
                [-16.489861, 20.70875],
                [-16.489586, 20.70875],
                [-16.489586, 20.711250000000177],
                [-16.489305, 20.711250000000177],
                [-16.489305, 20.713194],
                [-16.489026999999851, 20.713194],
                [-16.489026999999851, 20.713751000000173],
                [-16.48875, 20.713751000000173],
                [-16.48875, 20.714027],
                [-16.488195, 20.714027],
                [-16.488195, 20.714306],
                [-16.487917, 20.714306],
                [-16.487917, 20.714861000000155],
                [-16.487638, 20.714861000000155],
                [-16.487638, 20.715141],
                [-16.487364, 20.715141],
                [-16.487364, 20.715694000000155],
                [-16.487082, 20.715694000000155],
                [-16.487082, 20.715973000000133],
                [-16.486803, 20.715973000000133],
                [-16.486803, 20.716806000000133],
                [-16.486527999999851, 20.716806000000133],
                [-16.486527999999851, 20.717360000000156],
                [-16.486252, 20.717360000000156],
                [-16.486252, 20.718195000000151],
                [-16.485971, 20.718195000000151],
                [-16.485971, 20.721251],
                [-16.486252, 20.721251],
                [-16.486252, 20.722086],
                [-16.486527999999851, 20.722086],
                [-16.486527999999851, 20.722361],
                [-16.486803, 20.722361],
                [-16.486803, 20.722639000000129],
                [-16.487082, 20.722639000000129],
                [-16.487082, 20.722918000000107],
                [-16.487364, 20.722918000000107],
                [-16.487364, 20.723194],
                [-16.48875, 20.723194],
                [-16.48875, 20.723473],
                [-16.489861, 20.723473],
                [-16.489861, 20.723749000000112],
                [-16.490696, 20.723749000000112],
                [-16.490696, 20.724028000000146],
                [-16.491249, 20.724028000000146],
                [-16.491249, 20.724306],
                [-16.491807999999821, 20.724306],
                [-16.491807999999821, 20.724583],
                [-16.492083, 20.724583],
                [-16.492083, 20.724861000000146],
                [-16.492361, 20.724861000000146],
                [-16.492361, 20.725140000000124],
                [-16.49264, 20.725140000000124],
                [-16.49264, 20.725416],
                [-16.495138, 20.725416],
                [-16.495138, 20.725140000000124],
                [-16.495417, 20.725140000000124],
                [-16.495417, 20.724861000000146],
                [-16.495694999999898, 20.724861000000146],
                [-16.495694999999898, 20.724583],
                [-16.495972, 20.724583],
                [-16.495972, 20.724028000000146],
                [-16.496248, 20.724028000000146],
                [-16.496248, 20.723749000000112],
                [-16.496528999999896, 20.723749000000112],
                [-16.496528999999896, 20.723473],
                [-16.496805, 20.723473],
                [-16.496805, 20.723194],
                [-16.49847, 20.723194],
                [-16.49847, 20.722918000000107],
                [-16.499582, 20.722918000000107],
                [-16.499582, 20.722639000000129],
                [-16.502360999999894, 20.722639000000129],
                [-16.502360999999894, 20.723194],
                [-16.502639999999872, 20.723194],
                [-16.502639999999872, 20.723473],
                [-16.502916, 20.723473],
                [-16.502916, 20.724028000000146],
                [-16.503196999999886, 20.724028000000146],
                [-16.503196999999886, 20.724306],
                [-16.50347099999982, 20.724306],
                [-16.50347099999982, 20.725140000000124],
                [-16.50375, 20.725140000000124],
                [-16.50375, 20.725973000000124],
                [-16.504027999999892, 20.725973000000124],
                [-16.504027999999892, 20.726526],
                [-16.504306999999869, 20.726526],
                [-16.504272, 20.727116],
                [-16.504583, 20.727083000000107],
                [-16.504543, 20.7274],
                [-16.505694999999889, 20.727362000000142],
                [-16.505694999999889, 20.727083000000107],
                [-16.506528999999887, 20.727083000000107],
                [-16.506528999999887, 20.726805],
                [-16.507084, 20.726805],
                [-16.507084, 20.726526],
                [-16.507359999999892, 20.726526],
                [-16.507359999999892, 20.726250000000107],
                [-16.50763899999987, 20.726250000000107],
                [-16.50763899999987, 20.725973000000124],
                [-16.508193999999889, 20.725973000000124],
                [-16.508193999999889, 20.725695],
                [-16.50847199999987, 20.725695],
                [-16.50847199999987, 20.725416],
                [-16.508751, 20.725416],
                [-16.508751, 20.725140000000124],
                [-16.50902699999989, 20.725140000000124],
                [-16.50902699999989, 20.724861000000146],
                [-16.509306, 20.724861000000146],
                [-16.509306, 20.724583],
                [-16.509583, 20.724583],
                [-16.509583, 20.724306],
                [-16.510139, 20.724306],
                [-16.510139, 20.724028000000146],
                [-16.511528, 20.724028000000146],
                [-16.511528, 20.723749000000112],
                [-16.511806, 20.723749000000112],
                [-16.511806, 20.723473],
                [-16.512081, 20.723473],
                [-16.512081, 20.723194],
                [-16.512363, 20.723194],
                [-16.512363, 20.723473],
                [-16.514029, 20.723473],
                [-16.514029, 20.723194],
                [-16.514582, 20.723194],
                [-16.514582, 20.723473],
                [-16.51486, 20.723473],
                [-16.514862, 20.723749000000112],
                [-16.515139, 20.723749000000112],
                [-16.515139, 20.724028000000146],
                [-16.515417, 20.724028000000146],
                [-16.515417, 20.724306],
                [-16.515972, 20.724306],
                [-16.515972, 20.724583],
                [-16.516251, 20.724583],
                [-16.516251, 20.724861000000146],
                [-16.516806, 20.724861000000146],
                [-16.516806, 20.724583],
                [-16.517361, 20.724583],
                [-16.517361, 20.724861000000146],
                [-16.518194, 20.724861000000146],
                [-16.518194, 20.725140000000124],
                [-16.519028, 20.725140000000124],
                [-16.519028, 20.725416],
                [-16.520139999999856, 20.725416],
                [-16.520139999999856, 20.725695],
                [-16.520416, 20.725695],
                [-16.520416, 20.725973000000124],
                [-16.520695, 20.725973000000124],
                [-16.520695, 20.726250000000107],
                [-16.52125, 20.726250000000107],
                [-16.52125, 20.726526],
                [-16.521528, 20.726526],
                [-16.521528, 20.726805],
                [-16.522083, 20.726805],
                [-16.522083, 20.727083000000107],
                [-16.522362, 20.727083000000107],
                [-16.522362, 20.727362000000142],
                [-16.522637999999858, 20.727362000000142],
                [-16.522637999999858, 20.727638],
                [-16.522917, 20.727638],
                [-16.522917, 20.728195000000142],
                [-16.522637999999858, 20.728195000000142],
                [-16.522637999999858, 20.728472000000124],
                [-16.522362, 20.728472000000124],
                [-16.522362, 20.729584000000102],
                [-16.522637999999858, 20.729584000000102],
                [-16.522637999999858, 20.729858],
                [-16.522917, 20.729858],
                [-16.522917, 20.730417000000102],
                [-16.523195, 20.730417000000102],
                [-16.523195, 20.730694000000142],
                [-16.523471999999856, 20.730694000000142],
                [-16.523471999999856, 20.732363000000191],
                [-16.52375, 20.732363000000191],
                [-16.52375, 20.733194],
                [-16.524027, 20.733194],
                [-16.524027, 20.734306000000174],
                [-16.524304999999856, 20.734306000000174],
                [-16.524304999999856, 20.734861],
                [-16.524584, 20.734861],
                [-16.524584, 20.73513800000012],
                [-16.52486, 20.735140000000115],
                [-16.52486, 20.735416],
                [-16.525139, 20.735416],
                [-16.525139, 20.735695000000192],
                [-16.525969999999859, 20.735695000000192],
                [-16.525969999999859, 20.735416],
                [-16.526252999999826, 20.735416],
                [-16.526252999999826, 20.734585],
                [-16.526527, 20.734585],
                [-16.526527, 20.734306000000174],
                [-16.526806, 20.734306000000174],
                [-16.526806, 20.733475000000169],
                [-16.527084, 20.733475000000169],
                [-16.527084, 20.733194],
                [-16.527245, 20.733194],
                [-16.527363, 20.733194],
                [-16.527363, 20.732529],
                [-16.527363, 20.732363000000191],
                [-16.527639, 20.732363000000191],
                [-16.527639, 20.73180600000012],
                [-16.527914, 20.73180600000012],
                [-16.527914, 20.731527000000142],
                [-16.528193, 20.731527000000142],
                [-16.528193, 20.73097],
                [-16.528475, 20.73097],
                [-16.528475, 20.728472000000124],
                [-16.528749, 20.728472000000124],
                [-16.528749, 20.727638],
                [-16.529028, 20.727638],
                [-16.529028, 20.727083000000107],
                [-16.528749, 20.727083000000107],
                [-16.528749, 20.726526],
                [-16.529028, 20.726526],
                [-16.529028, 20.725416],
                [-16.528749, 20.725416],
                [-16.528749, 20.72456700000015],
                [-16.528749, 20.723473],
                [-16.52894, 20.723473],
                [-16.529028, 20.723473],
                [-16.529028, 20.722918000000107],
                [-16.529306, 20.722918000000107],
                [-16.529306, 20.722361],
                [-16.529584999999827, 20.722361],
                [-16.529584999999827, 20.721527000000151],
                [-16.529861, 20.721527000000151],
                [-16.529861, 20.719584000000111],
                [-16.529619, 20.719584000000111],
                [-16.529584999999827, 20.718195000000151],
                [-16.529861, 20.718195000000151],
                [-16.529861, 20.716249],
                [-16.529584999999827, 20.716249],
                [-16.529584999999827, 20.715694000000155],
                [-16.529306, 20.715694000000155],
                [-16.529306, 20.715141],
                [-16.529028, 20.715141],
                [-16.529028, 20.714582000000178],
                [-16.528749, 20.714582000000178],
                [-16.528749, 20.713751000000173],
                [-16.528475, 20.713751000000173],
                [-16.528475, 20.713194],
                [-16.529028, 20.713194],
                [-16.529028, 20.712917],
                [-16.529306, 20.712917],
                [-16.529306, 20.71152700000016],
                [-16.529028, 20.71152700000016],
                [-16.529028, 20.70875],
                [-16.529306, 20.70875],
                [-16.529306, 20.707916000000182],
                [-16.529584999999827, 20.707916000000182],
                [-16.529584999999827, 20.706249],
                [-16.529861, 20.706249],
                [-16.529861, 20.704584000000182],
                [-16.530138, 20.704584000000182],
                [-16.530138, 20.70347],
                [-16.530416, 20.70347],
                [-16.530416, 20.701530000000105],
                [-16.530697, 20.701530000000105],
                [-16.530697, 20.698195000000169],
                [-16.530972, 20.698195000000169],
                [-16.530972, 20.697639],
                [-16.530697, 20.697639],
                [-16.530697, 20.697083000000191],
                [-16.530972, 20.697083000000191],
                [-16.530972, 20.695696000000169],
                [-16.53125, 20.695696000000169],
                [-16.53125, 20.695417000000191],
                [-16.531528, 20.695417000000191],
                [-16.531528, 20.694860000000119],
                [-16.531807, 20.694860000000119],
                [-16.531807, 20.694027000000119],
                [-16.532083999999827, 20.694027000000119],
                [-16.532083999999827, 20.692638000000102],
                [-16.532362, 20.692638000000102],
                [-16.532362, 20.691528000000119],
                [-16.532639, 20.691528000000119],
                [-16.532639, 20.689028],
                [-16.532918999999879, 20.689028],
                [-16.532918999999879, 20.687916],
                [-16.533236, 20.68792000000019],
                [-16.533194, 20.687361000000124],
                [-16.533266, 20.687361000000124],
                [-16.533472, 20.687361000000124],
                [-16.533472, 20.686806],
                [-16.533750999999825, 20.686806],
                [-16.533750999999825, 20.685694],
                [-16.534027, 20.685694],
                [-16.534027, 20.684860000000128],
                [-16.534306, 20.684860000000128],
                [-16.534306, 20.681805000000168],
                [-16.534583999999825, 20.681805000000168],
                [-16.534583999999825, 20.68041600000015],
                [-16.534306, 20.68041600000015],
                [-16.534306, 20.678192],
                [-16.534583999999825, 20.678192],
                [-16.534583999999825, 20.676249000000155],
                [-16.534306, 20.676249000000155],
                [-16.534306, 20.675970000000177],
                [-16.534027, 20.675970000000177],
                [-16.534027, 20.675694],
                [-16.533750999999825, 20.675694],
                [-16.533750999999825, 20.675417],
                [-16.533472, 20.675417],
                [-16.533472, 20.675138],
                [-16.532918999999879, 20.675138],
                [-16.532918999999879, 20.674862000000132],
                [-16.532639, 20.674862000000132],
                [-16.532639, 20.674583000000155],
                [-16.532362, 20.674583000000155],
                [-16.532362, 20.674028],
                [-16.532083999999827, 20.674028],
                [-16.532083999999827, 20.673750000000155],
                [-16.531528, 20.673750000000155],
                [-16.531528, 20.673195],
                [-16.53125, 20.673195],
                [-16.53125, 20.6729160000001],
                [-16.530416, 20.6729160000001],
                [-16.530416, 20.673195],
                [-16.530199, 20.673159000000112],
                [-16.530143999999893, 20.672937],
                [-16.529584999999827, 20.6729160000001],
                [-16.529584999999827, 20.672361],
                [-16.529306, 20.672361],
                [-16.529306, 20.6711600000001],
                [-16.529306, 20.670139000000177],
                [-16.529584999999827, 20.670139000000177],
                [-16.529584999999827, 20.66736],
                [-16.529306, 20.66736],
                [-16.529306, 20.666805000000181],
                [-16.529028, 20.666805000000181],
                [-16.529028, 20.666250000000105],
                [-16.528749, 20.666250000000105],
                [-16.528749, 20.665695],
                [-16.528475, 20.665695],
                [-16.528475, 20.664305],
                [-16.528193, 20.664305],
                [-16.528193, 20.662083000000109],
                [-16.527914, 20.662083000000109],
                [-16.527914, 20.656250000000114],
                [-16.528193, 20.656250000000114],
                [-16.528193, 20.65597200000019],
                [-16.528749, 20.65597200000019],
                [-16.528749, 20.658472000000131],
                [-16.529028, 20.658472000000131],
                [-16.529028, 20.658751000000109],
                [-16.529306, 20.658751000000109],
                [-16.529306, 20.659027],
                [-16.530138, 20.659027],
                [-16.530138, 20.659306000000186],
                [-16.530697, 20.659306000000186],
                [-16.530697, 20.659027],
                [-16.530972, 20.659027],
                [-16.530972, 20.658751000000109],
                [-16.53125, 20.658751000000109],
                [-16.53125, 20.650694000000101],
                [-16.531528, 20.650694000000101],
                [-16.531528, 20.650417000000118],
                [-16.531807, 20.650417000000118],
                [-16.531807, 20.649584000000118],
                [-16.531528, 20.649584000000118],
                [-16.531528, 20.64930500000014],
                [-16.530972, 20.64930500000014],
                [-16.530972, 20.649027],
                [-16.530697, 20.649027],
                [-16.530697, 20.645416],
                [-16.530972, 20.645416],
                [-16.530972, 20.643473],
                [-16.53125, 20.643473],
                [-16.53125, 20.639862],
                [-16.530972, 20.63986],
                [-16.530972, 20.639029],
                [-16.530697, 20.639029],
                [-16.530697, 20.637917],
                [-16.530416, 20.637917],
                [-16.530416, 20.635695],
                [-16.530138, 20.635695],
                [-16.530138, 20.633194],
                [-16.530416, 20.633194],
                [-16.530416, 20.632917],
                [-16.530697, 20.632917],
                [-16.530697, 20.632639000000154],
                [-16.530972, 20.632639000000154],
                [-16.530972, 20.63125],
                [-16.53125, 20.63125],
                [-16.53125, 20.630417],
                [-16.531528, 20.630417],
                [-16.531528, 20.629307000000153],
                [-16.531807, 20.629307000000153],
                [-16.531807, 20.628195000000176],
                [-16.532083999999827, 20.628195000000176],
                [-16.532083999999827, 20.62764],
                [-16.532362, 20.62764],
                [-16.532362, 20.626806000000158],
                [-16.532083999999827, 20.626806000000158],
                [-16.532083999999827, 20.625973000000158],
                [-16.531807, 20.625973000000158],
                [-16.531807, 20.62569400000018],
                [-16.531528, 20.62569400000018],
                [-16.531528, 20.625416],
                [-16.53125, 20.625416],
                [-16.53125, 20.624027000000126],
                [-16.531528, 20.624027000000126],
                [-16.531528, 20.623753],
                [-16.531807, 20.623753],
                [-16.531807, 20.622917],
                [-16.532083999999827, 20.622917],
                [-16.532083999999827, 20.621248],
                [-16.532362, 20.621248],
                [-16.532362, 20.620695000000126],
                [-16.532639, 20.620695000000126],
                [-16.532639, 20.620417],
                [-16.532918999999879, 20.620417],
                [-16.532882999999856, 20.61736100000013],
                [-16.532639, 20.61736100000013],
                [-16.532665, 20.616520000000151],
                [-16.532362, 20.61652800000013],
                [-16.532418999999834, 20.615677],
                [-16.532083999999827, 20.615696000000185],
                [-16.532082, 20.61484],
                [-16.531807, 20.61486100000019],
                [-16.531807, 20.614027000000135],
                [-16.531528, 20.614027000000135],
                [-16.531528, 20.613474],
                [-16.53125, 20.613474],
                [-16.53125, 20.613194000000135],
                [-16.530697, 20.613194000000135],
                [-16.530697, 20.612083],
                [-16.530972, 20.612083],
                [-16.530972, 20.610725000000116],
                [-16.53125, 20.610695000000135],
                [-16.53125, 20.609583000000157],
                [-16.530972, 20.609583000000157],
                [-16.530972, 20.609306000000117],
                [-16.530697, 20.609306000000117],
                [-16.530697, 20.609030000000189],
                [-16.530474, 20.609030000000189],
                [-16.530393999999887, 20.608755],
                [-16.530138, 20.608749],
                [-16.530138, 20.608473000000117],
                [-16.529861, 20.608473000000117],
                [-16.529861, 20.607916],
                [-16.529584999999827, 20.607916],
                [-16.529584999999827, 20.606806],
                [-16.529823, 20.606806],
                [-16.530138, 20.606806],
                [-16.530138, 20.606527],
                [-16.530697, 20.606527],
                [-16.530697, 20.606251000000157],
                [-16.530972, 20.606251000000157],
                [-16.530972, 20.605972000000122],
                [-16.53125, 20.605972000000122],
                [-16.53125, 20.604305],
                [-16.530972, 20.604305],
                [-16.530972, 20.603750000000161],
                [-16.530697, 20.603750000000161],
                [-16.530697, 20.603472],
                [-16.530416, 20.603472],
                [-16.530416, 20.602917000000161],
                [-16.530138, 20.602917000000161],
                [-16.530138, 20.602083],
                [-16.529861, 20.602083],
                [-16.529861, 20.600416000000166],
                [-16.529584999999827, 20.600416000000166],
                [-16.529584999999827, 20.597363000000144],
                [-16.529306, 20.597363000000144],
                [-16.529306, 20.594584],
                [-16.529028, 20.594584],
                [-16.529028, 20.592361000000153],
                [-16.528749, 20.592361000000153],
                [-16.528749, 20.591528000000153],
                [-16.528475, 20.591528000000153],
                [-16.528475, 20.591249000000175],
                [-16.528193, 20.591249000000175],
                [-16.528193, 20.590973],
                [-16.527914, 20.590973],
                [-16.527914, 20.590139],
                [-16.527639, 20.590139],
                [-16.527639, 20.588751],
                [-16.527363, 20.588751],
                [-16.527363, 20.587639],
                [-16.527084, 20.587639],
                [-16.527084, 20.586527000000103],
                [-16.526806, 20.586527000000103],
                [-16.526806, 20.58541700000012],
                [-16.526527, 20.58541700000012],
                [-16.526527, 20.580973],
                [-16.526806, 20.580973],
                [-16.526806, 20.580137],
                [-16.527084, 20.580137],
                [-16.527023, 20.577612],
                [-16.527363, 20.577639],
                [-16.527363, 20.576950000000124],
                [-16.527363, 20.576529000000107],
                [-16.527639, 20.576529000000107],
                [-16.527639, 20.576403],
                [-16.527639, 20.575972],
                [-16.527914, 20.575972],
                [-16.527914, 20.575857],
                [-16.527914, 20.574862],
                [-16.528193, 20.574862],
                [-16.528193, 20.574306],
                [-16.528475, 20.574306],
                [-16.528475, 20.573475],
                [-16.528749, 20.573475],
                [-16.528749, 20.572916000000134],
                [-16.529028, 20.572916000000134],
                [-16.529028, 20.571806000000151],
                [-16.529306, 20.571806000000151],
                [-16.529306, 20.571253000000183],
                [-16.529584999999827, 20.571253000000183],
                [-16.529584999999827, 20.570692000000122],
                [-16.529861, 20.570692000000122],
                [-16.529861, 20.569862],
                [-16.530138, 20.569862],
                [-16.530138, 20.569672000000139],
                [-16.530138, 20.568472000000156],
                [-16.530416, 20.568472000000156],
                [-16.530416, 20.567362000000116],
                [-16.530697, 20.567362000000116],
                [-16.530697, 20.566250000000139],
                [-16.530972, 20.566250000000139],
                [-16.530972, 20.564861000000121],
                [-16.53125, 20.564861000000121],
                [-16.53125, 20.563194],
                [-16.531528, 20.563194],
                [-16.531528, 20.562639000000161],
                [-16.531807, 20.562639000000161],
                [-16.531807, 20.560139],
                [-16.531528, 20.560139],
                [-16.531528, 20.558935],
                [-16.531528, 20.55847400000016],
                [-16.53125, 20.55847400000016],
                [-16.53125, 20.558195000000183],
                [-16.530972, 20.558195000000183],
                [-16.530972, 20.557917],
                [-16.530138, 20.557917],
                [-16.530138, 20.557638],
                [-16.529861, 20.557638],
                [-16.529861, 20.556252000000143],
                [-16.530138, 20.556252000000143],
                [-16.530138, 20.555973000000165],
                [-16.530416, 20.555973000000165],
                [-16.530416, 20.555695],
                [-16.530697, 20.555695],
                [-16.530697, 20.555416],
                [-16.53125, 20.555416],
                [-16.53125, 20.555973000000165],
                [-16.531528, 20.555973000000165],
                [-16.531528, 20.556528],
                [-16.531807, 20.556528],
                [-16.531807, 20.556805],
                [-16.532639, 20.556805],
                [-16.532639, 20.557081],
                [-16.533194, 20.557081],
                [-16.533194, 20.557362000000182],
                [-16.534027, 20.557362000000182],
                [-16.534027, 20.557638],
                [-16.534861, 20.557638],
                [-16.534861, 20.557917],
                [-16.535137, 20.557917],
                [-16.535137, 20.558195000000183],
                [-16.535417999999879, 20.558195000000183],
                [-16.535417999999879, 20.55847400000016],
                [-16.535694, 20.55847400000016],
                [-16.535694, 20.559029],
                [-16.535972999999899, 20.559029],
                [-16.535972999999899, 20.560139],
                [-16.536248999999827, 20.560139],
                [-16.536248999999827, 20.560694000000183],
                [-16.536528, 20.560694000000183],
                [-16.536528, 20.560972],
                [-16.536805999999899, 20.560972],
                [-16.536805999999899, 20.561251],
                [-16.537082999999825, 20.561251],
                [-16.537082999999825, 20.561527000000183],
                [-16.537359, 20.561527000000183],
                [-16.537359, 20.561806000000161],
                [-16.537639999999897, 20.561806000000161],
                [-16.537639999999897, 20.562084],
                [-16.537915999999825, 20.562084],
                [-16.537915999999825, 20.562361],
                [-16.539580999999828, 20.562361],
                [-16.539580999999828, 20.562084],
                [-16.540137999999899, 20.562084],
                [-16.540137999999899, 20.562639000000161],
                [-16.540416999999877, 20.562639000000161],
                [-16.540416999999877, 20.563194],
                [-16.540695, 20.563194],
                [-16.540695, 20.563473],
                [-16.54236, 20.563473],
                [-16.54236, 20.563749000000143],
                [-16.542916999999875, 20.563749000000143],
                [-16.542916999999875, 20.564028000000121],
                [-16.543471999999895, 20.564028000000121],
                [-16.543471999999895, 20.564306],
                [-16.543750999999872, 20.564306],
                [-16.543750999999872, 20.564583],
                [-16.544027, 20.564583],
                [-16.544027, 20.564861000000121],
                [-16.544308, 20.564861000000121],
                [-16.544308, 20.566250000000139],
                [-16.544581999999878, 20.566250000000139],
                [-16.544583999999873, 20.566526],
                [-16.544861, 20.566526],
                [-16.544861, 20.566805],
                [-16.545138999999892, 20.566805],
                [-16.545138999999892, 20.567083000000139],
                [-16.54541799999987, 20.567083000000139],
                [-16.54541799999987, 20.567362000000116],
                [-16.545973, 20.567362000000116],
                [-16.545973, 20.567638],
                [-16.546528, 20.567638],
                [-16.546528, 20.567917],
                [-16.546806, 20.567917],
                [-16.546806, 20.568195000000117],
                [-16.547361, 20.568195000000117],
                [-16.547361, 20.568472000000156],
                [-16.547915999999873, 20.568472000000156],
                [-16.547915999999873, 20.568748],
                [-16.54875, 20.568748],
                [-16.54875, 20.569027],
                [-16.549028, 20.569027],
                [-16.549028, 20.569305000000156],
                [-16.549305, 20.569305000000156],
                [-16.549305, 20.569584000000134],
                [-16.549862, 20.569584000000134],
                [-16.549862, 20.569862],
                [-16.551805, 20.569862],
                [-16.551805, 20.570141],
                [-16.552361, 20.570141],
                [-16.552361, 20.570417000000134],
                [-16.552917, 20.570417000000134],
                [-16.552917, 20.570692000000122],
                [-16.553749, 20.570692000000122],
                [-16.553749, 20.571253000000183],
                [-16.554028, 20.571253000000183],
                [-16.554028, 20.571527000000117],
                [-16.554306, 20.571527000000117],
                [-16.554306, 20.571806000000151],
                [-16.554861, 20.571806000000151],
                [-16.554861, 20.572081000000139],
                [-16.555973, 20.572081000000139],
                [-16.555973, 20.572363],
                [-16.556249999999864, 20.572363],
                [-16.556249999999864, 20.572639000000152],
                [-16.556528, 20.572639000000152],
                [-16.556528, 20.572916000000134],
                [-16.557082999999864, 20.572916000000134],
                [-16.557082999999864, 20.573195000000112],
                [-16.557362, 20.573195000000112],
                [-16.557362, 20.573475],
                [-16.557916999999861, 20.573475],
                [-16.557916999999861, 20.57375],
                [-16.558195, 20.57375],
                [-16.558195, 20.574028000000112],
                [-16.558749999999861, 20.574028000000112],
                [-16.558749999999861, 20.574306],
                [-16.559305, 20.574306],
                [-16.559305, 20.574583],
                [-16.55986, 20.574583],
                [-16.55986, 20.574862],
                [-16.560417, 20.574862],
                [-16.560417, 20.575138000000152],
                [-16.561251, 20.575138000000152],
                [-16.561251, 20.57541700000013],
                [-16.561527, 20.57541700000013],
                [-16.561527, 20.575972],
                [-16.561806, 20.575972],
                [-16.561806, 20.57625000000013],
                [-16.562084, 20.57625000000013],
                [-16.562084, 20.576529000000107],
                [-16.562639, 20.576529000000107],
                [-16.562639, 20.576805],
                [-16.562916, 20.576805],
                [-16.562916, 20.577084000000184],
                [-16.563194, 20.577084000000184],
                [-16.563194, 20.577362000000107],
                [-16.563473, 20.577362000000107],
                [-16.563473, 20.578472],
                [-16.563749, 20.578472],
                [-16.563749, 20.578751000000125],
                [-16.564583, 20.578751000000125],
                [-16.564583, 20.579027],
                [-16.564859, 20.579027],
                [-16.564859, 20.579306],
                [-16.565416, 20.579306],
                [-16.565416, 20.580416000000184],
                [-16.565695, 20.580420000000174],
                [-16.565695, 20.580694000000108],
                [-16.565971, 20.580694000000108],
                [-16.565971, 20.580973],
                [-16.566528, 20.580973],
                [-16.566528, 20.581247],
                [-16.56875, 20.581247],
                [-16.56875, 20.581528],
                [-16.569027, 20.581528],
                [-16.569027, 20.581806],
                [-16.569586, 20.581806],
                [-16.569586, 20.582083000000125],
                [-16.56986, 20.582083000000125],
                [-16.56986, 20.582359],
                [-16.570417, 20.582359],
                [-16.570417, 20.582642000000192],
                [-16.570695999999884, 20.582642000000192],
                [-16.570695999999884, 20.582916000000125],
                [-16.570972, 20.582916000000125],
                [-16.570972, 20.583195000000103],
                [-16.571251, 20.583195000000103],
                [-16.571251, 20.583469],
                [-16.571526999999833, 20.583469],
                [-16.571526999999833, 20.58458300000018],
                [-16.571808, 20.58458300000018],
                [-16.571808, 20.585138],
                [-16.572083, 20.585138],
                [-16.572083, 20.585695],
                [-16.57236099999983, 20.585695],
                [-16.57236099999983, 20.585974000000192],
                [-16.572639, 20.585974000000192],
                [-16.572639, 20.586250000000121],
                [-16.573473, 20.586250000000121],
                [-16.573473, 20.586527000000103],
                [-16.57403, 20.586527000000103],
                [-16.57403, 20.586805],
                [-16.575417, 20.586805],
                [-16.575417, 20.587084000000175],
                [-16.575972, 20.587084000000175],
                [-16.575972, 20.587360000000103],
                [-16.57652899999988, 20.587360000000103],
                [-16.57652899999988, 20.587639],
                [-16.576805, 20.587639],
                [-16.576805, 20.587917000000175],
                [-16.577084, 20.587917000000175],
                [-16.577084, 20.588196000000153],
                [-16.577359999999885, 20.588196000000153],
                [-16.577359999999885, 20.588472],
                [-16.577639, 20.588472],
                [-16.577639, 20.588751],
                [-16.577917, 20.588751],
                [-16.577917, 20.589306],
                [-16.578193999999883, 20.589306],
                [-16.578193999999883, 20.589582000000121],
                [-16.57847, 20.589582000000121],
                [-16.57847, 20.589861],
                [-16.578753, 20.589861],
                [-16.578753, 20.590139],
                [-16.579306, 20.590139],
                [-16.579306, 20.59041400000018],
                [-16.579582, 20.59041400000018],
                [-16.579557, 20.590757000000167],
                [-16.57986099999988, 20.590694],
                [-16.579891, 20.590902],
                [-16.580139, 20.591009],
                [-16.580139, 20.591249000000175],
                [-16.580416, 20.591249000000175],
                [-16.580416, 20.591528000000153],
                [-16.580691999999885, 20.591528000000153],
                [-16.580691999999885, 20.591806],
                [-16.580975, 20.591806],
                [-16.580975, 20.592083],
                [-16.581527999999878, 20.592083],
                [-16.581527999999878, 20.592361000000153],
                [-16.581806, 20.592361000000153],
                [-16.581806, 20.592636],
                [-16.582085, 20.592636],
                [-16.582085, 20.592916],
                [-16.58264, 20.592916],
                [-16.58264, 20.593195],
                [-16.582914, 20.593195],
                [-16.582914, 20.593472],
                [-16.583196999999871, 20.593472],
                [-16.583196999999871, 20.593748000000176],
                [-16.583471, 20.593748000000176],
                [-16.583471, 20.59403],
                [-16.58375, 20.59403],
                [-16.58375, 20.594305],
                [-16.584027999999876, 20.594305],
                [-16.584027999999876, 20.594584],
                [-16.584583, 20.594584],
                [-16.584583, 20.595139000000188],
                [-16.584861999999873, 20.595139000000188],
                [-16.584861999999873, 20.595419],
                [-16.585138, 20.595419],
                [-16.585138, 20.595694],
                [-16.585417, 20.595694],
                [-16.585417, 20.59597],
                [-16.585986999999875, 20.595963000000154],
                [-16.585972, 20.596249000000171],
                [-16.58625, 20.596249000000171],
                [-16.58625, 20.596375000000137],
                [-16.58625, 20.596806],
                [-16.586528999999871, 20.596807000000126],
                [-16.586528999999871, 20.597084000000166],
                [-16.586748, 20.597115],
                [-16.58685899999989, 20.597324000000128],
                [-16.587084, 20.597363000000144],
                [-16.587066999999877, 20.597651000000155],
                [-16.587359999999876, 20.597639],
                [-16.587359999999876, 20.597918],
                [-16.587917, 20.597918],
                [-16.587917, 20.598194000000149],
                [-16.588193999999874, 20.598194000000149],
                [-16.588193999999874, 20.598473000000126],
                [-16.588472, 20.598473000000126],
                [-16.588472, 20.598749],
                [-16.589026999999874, 20.598749],
                [-16.589026999999874, 20.599028],
                [-16.589306, 20.599028],
                [-16.589306, 20.599306000000126],
                [-16.589582, 20.599306000000126],
                [-16.589582, 20.599585000000161],
                [-16.589860999999871, 20.599585000000161],
                [-16.589860999999871, 20.599861],
                [-16.590139, 20.599861],
                [-16.590139, 20.60014],
                [-16.590693999999871, 20.60014],
                [-16.590693999999871, 20.600416000000166],
                [-16.590973, 20.600416000000166],
                [-16.590973, 20.600695000000144],
                [-16.591249, 20.600695000000144],
                [-16.591249, 20.600973],
                [-16.591527999999869, 20.600973],
                [-16.591527999999869, 20.60125],
                [-16.591806, 20.60125],
                [-16.591806, 20.601528000000144],
                [-16.592081, 20.601528000000144],
                [-16.592081, 20.601805000000127],
                [-16.592363, 20.601805000000127],
                [-16.592363, 20.602083],
                [-16.592638, 20.602083],
                [-16.592638, 20.602362],
                [-16.593192999999872, 20.602362],
                [-16.593192999999872, 20.602638000000127],
                [-16.593472, 20.602638000000127],
                [-16.593472, 20.604305],
                [-16.593752, 20.604305],
                [-16.593752, 20.604860000000144],
                [-16.594027999999867, 20.604860000000144],
                [-16.594027999999867, 20.605417],
                [-16.594303, 20.605417],
                [-16.594303, 20.605694],
                [-16.594582, 20.605694],
                [-16.594582, 20.605972000000122],
                [-16.594864, 20.605972000000122],
                [-16.594864, 20.606527],
                [-16.595139, 20.606527],
                [-16.595139, 20.607084000000157],
                [-16.595417, 20.607084000000157],
                [-16.595417, 20.607916],
                [-16.595695, 20.607916],
                [-16.595695, 20.608749],
                [-16.595972, 20.608749],
                [-16.595972, 20.609030000000189],
                [-16.596251, 20.609030000000189],
                [-16.596251, 20.609583000000157],
                [-16.596524999999872, 20.609583000000157],
                [-16.596524999999872, 20.609859],
                [-16.596806, 20.609859],
                [-16.596806, 20.610416000000157],
                [-16.597086, 20.610416000000157],
                [-16.597086, 20.610695000000135],
                [-16.597918, 20.610695000000135],
                [-16.597918, 20.610970000000123],
                [-16.598473, 20.610970000000123],
                [-16.598473, 20.611252],
                [-16.598749, 20.611252],
                [-16.598749, 20.611528000000135],
                [-16.599028, 20.611528000000135],
                [-16.599028, 20.611805000000118],
                [-16.601528, 20.611805000000118],
                [-16.601528, 20.612083],
                [-16.601805, 20.612083],
                [-16.601805, 20.612364000000184],
                [-16.602083, 20.612364000000184],
                [-16.602083, 20.612638000000118],
                [-16.602362, 20.612638000000118],
                [-16.602362, 20.612917],
                [-16.603195, 20.612917],
                [-16.603195, 20.613194000000135],
                [-16.603472, 20.613195000000189],
                [-16.603472, 20.613474],
                [-16.60375, 20.613474],
                [-16.60375, 20.61375],
                [-16.604029, 20.61375],
                [-16.604029, 20.614027000000135],
                [-16.604305, 20.614027000000135],
                [-16.604305, 20.614586],
                [-16.604584, 20.614586],
                [-16.604584, 20.61486100000019],
                [-16.605138999999838, 20.61486100000019],
                [-16.605138999999838, 20.615139000000113],
                [-16.606253, 20.615139000000113],
                [-16.606253, 20.615417],
                [-16.608192, 20.615417],
                [-16.608192, 20.615696000000185],
                [-16.608749, 20.615696000000185],
                [-16.608749, 20.615973],
                [-16.609585, 20.615973],
                [-16.609585, 20.616249],
                [-16.609861, 20.616249],
                [-16.609861, 20.61652800000013],
                [-16.610137999999836, 20.61652800000013],
                [-16.610137999999836, 20.616804],
                [-16.610416, 20.616804],
                [-16.610416, 20.617083],
                [-16.610697, 20.617083],
                [-16.610697, 20.61736100000013],
                [-16.611806999999885, 20.61736100000013],
                [-16.611806999999885, 20.617640000000108],
                [-16.612083, 20.617640000000108],
                [-16.612083, 20.617916],
                [-16.612362, 20.617916],
                [-16.612362, 20.618195000000185],
                [-16.61263799999989, 20.618195000000185],
                [-16.61263799999989, 20.618473000000108],
                [-16.612919, 20.618473000000108],
                [-16.612919, 20.61902600000019],
                [-16.613194, 20.61902600000019],
                [-16.613194, 20.62347],
                [-16.612919, 20.62347],
                [-16.612919, 20.624027000000126],
                [-16.61263799999989, 20.624027000000126],
                [-16.61263799999989, 20.624863000000175],
                [-16.613194, 20.624863000000175],
                [-16.613194, 20.625139000000104],
                [-16.613471999999888, 20.625139000000104],
                [-16.613471999999888, 20.625416],
                [-16.61375, 20.625416],
                [-16.61375, 20.625139000000104],
                [-16.614029, 20.625139000000104],
                [-16.614029, 20.62569400000018],
                [-16.614304999999888, 20.62569400000018],
                [-16.614304999999888, 20.625973000000158],
                [-16.614584, 20.625973000000158],
                [-16.614584, 20.626249],
                [-16.614861, 20.626249],
                [-16.614861, 20.626806000000158],
                [-16.615136999999891, 20.626806000000158],
                [-16.615136999999891, 20.627361],
                [-16.615417, 20.627361],
                [-16.615417, 20.62764],
                [-16.615694, 20.62764],
                [-16.615694, 20.627916],
                [-16.616249, 20.627916],
                [-16.616249, 20.628195000000176],
                [-16.616805999999883, 20.628195000000176],
                [-16.616805999999883, 20.628471000000104],
                [-16.617916, 20.628471000000104],
                [-16.617916, 20.62875],
                [-16.61875, 20.62875],
                [-16.61875, 20.629028000000176],
                [-16.619581, 20.629028000000176],
                [-16.619581, 20.629307000000153],
                [-16.619864, 20.629307000000153],
                [-16.619864, 20.629583],
                [-16.620417, 20.629583],
                [-16.620417, 20.629862],
                [-16.620693, 20.629862],
                [-16.620693, 20.630138000000159],
                [-16.62125, 20.630138000000159],
                [-16.62125, 20.629862],
                [-16.621529, 20.629862],
                [-16.621529, 20.630695],
                [-16.621802999999886, 20.630695],
                [-16.621802999999886, 20.631527000000176],
                [-16.622086, 20.631527000000176],
                [-16.622086, 20.631805],
                [-16.62236, 20.631805],
                [-16.62236, 20.632360000000176],
                [-16.622638999999879, 20.632360000000176],
                [-16.622638999999879, 20.632639000000154],
                [-16.622917, 20.632639000000154],
                [-16.622917, 20.632917],
                [-16.623196, 20.632917],
                [-16.623196, 20.633194],
                [-16.623751, 20.633194],
                [-16.623751, 20.633472000000154],
                [-16.624027, 20.633472000000154],
                [-16.624027, 20.634027],
                [-16.624305999999876, 20.634027],
                [-16.624305999999876, 20.634583],
                [-16.624584, 20.634583],
                [-16.624584, 20.635141],
                [-16.624861, 20.635141],
                [-16.624861, 20.635416],
                [-16.625418, 20.635416],
                [-16.625418, 20.635695],
                [-16.625694, 20.635695],
                [-16.625694, 20.636251],
                [-16.625972999999874, 20.636251],
                [-16.625972999999874, 20.636806000000149],
                [-16.626249, 20.636806000000149],
                [-16.626249, 20.637081],
                [-16.626528, 20.637081],
                [-16.626528, 20.637360000000172],
                [-16.626805999999874, 20.637360000000172],
                [-16.626805999999874, 20.637917],
                [-16.626528, 20.637917],
                [-16.626528, 20.639029],
                [-16.626805999999874, 20.639029],
                [-16.626805999999874, 20.639305000000149],
                [-16.627083, 20.639305000000149],
                [-16.627024, 20.640730000000133],
                [-16.626805999999874, 20.640694000000167],
                [-16.626805999999874, 20.641527000000167],
                [-16.626528, 20.641527000000167],
                [-16.626528, 20.643194],
                [-16.626805999999874, 20.643194],
                [-16.626805999999874, 20.643473],
                [-16.627916, 20.643473],
                [-16.627916, 20.643749000000128],
                [-16.62875, 20.643749000000128],
                [-16.62875, 20.644028000000162],
                [-16.629061, 20.644007000000158],
                [-16.629028, 20.644306],
                [-16.629248, 20.644306],
                [-16.629583, 20.644306],
                [-16.629583, 20.644583],
                [-16.629862, 20.644583],
                [-16.629862, 20.644861000000162],
                [-16.630137999999874, 20.644861000000162],
                [-16.630137999999874, 20.64514000000014],
                [-16.630417, 20.64514000000014],
                [-16.630417, 20.645416],
                [-16.630695, 20.645416],
                [-16.630695, 20.645695],
                [-16.630971999999872, 20.645695],
                [-16.630971999999872, 20.645971000000145],
                [-16.63125, 20.645971000000145],
                [-16.631253999999842, 20.647398],
                [-16.631527, 20.647362000000101],
                [-16.631527, 20.647638],
                [-16.631804999999872, 20.647638],
                [-16.631804999999872, 20.647917],
                [-16.63236, 20.647917],
                [-16.63236, 20.648195000000101],
                [-16.632639, 20.648195000000101],
                [-16.632639, 20.64847200000014],
                [-16.633192, 20.64847200000014],
                [-16.633192, 20.648748],
                [-16.633474, 20.648748],
                [-16.633474, 20.64930500000014],
                [-16.633752999999842, 20.64930500000014],
                [-16.633752999999842, 20.649858],
                [-16.634027, 20.649858],
                [-16.634027, 20.650417000000118],
                [-16.634303999999872, 20.650417000000118],
                [-16.634303999999872, 20.650694000000101],
                [-16.634863, 20.650694000000101],
                [-16.634863, 20.65097],
                [-16.635139, 20.65097],
                [-16.635139, 20.651253],
                [-16.635414, 20.651253],
                [-16.635414, 20.651527000000101],
                [-16.635693, 20.651527000000101],
                [-16.635693, 20.652363],
                [-16.635975, 20.652363],
                [-16.635975, 20.652639000000136],
                [-16.63625, 20.652639000000136],
                [-16.63625, 20.652916000000118],
                [-16.636528, 20.652916000000118],
                [-16.636528, 20.653475000000185],
                [-16.636806, 20.653475000000185],
                [-16.636806, 20.653749000000118],
                [-16.637083, 20.653749000000118],
                [-16.637083, 20.654028],
                [-16.637362, 20.654028],
                [-16.637362, 20.65430600000019],
                [-16.637917, 20.65430600000019],
                [-16.637917, 20.654861],
                [-16.637638, 20.654861],
                [-16.637638, 20.655416],
                [-16.637917, 20.655416],
                [-16.637917, 20.655693],
                [-16.638197, 20.655693],
                [-16.638197, 20.65597200000019],
                [-16.638472, 20.65597200000019],
                [-16.638472, 20.656250000000114],
                [-16.639305, 20.656250000000114],
                [-16.639305, 20.657639000000131],
                [-16.639583999999843, 20.657639000000131],
                [-16.639583999999843, 20.657915],
                [-16.63986, 20.657915],
                [-16.639862, 20.658194],
                [-16.640139, 20.658194],
                [-16.640139, 20.658472000000131],
                [-16.640694, 20.658472000000131],
                [-16.640694, 20.658751000000109],
                [-16.640972, 20.658751000000109],
                [-16.640972, 20.659027],
                [-16.641250999999841, 20.659027],
                [-16.641250999999841, 20.659306000000186],
                [-16.641527, 20.659306000000186],
                [-16.641539, 20.659584000000109],
                [-16.641806, 20.659584000000109],
                [-16.641806, 20.659723],
                [-16.641806, 20.659861],
                [-16.642065, 20.659861],
                [-16.642361, 20.659861],
                [-16.642361, 20.660015],
                [-16.642361, 20.660418000000163],
                [-16.642639, 20.660418000000163],
                [-16.642639, 20.660694],
                [-16.642915999999843, 20.660694],
                [-16.642915999999843, 20.660973],
                [-16.643473, 20.660973],
                [-16.643473, 20.661247],
                [-16.643748999999843, 20.661247],
                [-16.643748999999843, 20.661528],
                [-16.644306, 20.661528],
                [-16.644306, 20.661247],
                [-16.644609, 20.661198],
                [-16.644582999999841, 20.661528],
                [-16.645161, 20.661488],
                [-16.64514, 20.661806],
                [-16.645709999999895, 20.661777],
                [-16.645695, 20.662083000000109],
                [-16.64629, 20.662083000000109],
                [-16.646528, 20.662083000000109],
                [-16.646528, 20.662209],
                [-16.646528, 20.662359],
                [-16.646818, 20.662359],
                [-16.647080999999844, 20.662359],
                [-16.647080999999844, 20.662498000000141],
                [-16.647080999999844, 20.662642000000176],
                [-16.647364, 20.662647],
                [-16.647364, 20.662916000000109],
                [-16.647916999999893, 20.662939],
                [-16.647916999999893, 20.663195],
                [-16.648403, 20.663195],
                [-16.648749999999893, 20.663195],
                [-16.648779, 20.663410000000113],
                [-16.648932, 20.663473000000181],
                [-16.649303, 20.663473000000181],
                [-16.649339999999881, 20.663687],
                [-16.649585999999886, 20.663816000000111],
                [-16.649585999999886, 20.664028],
                [-16.649986, 20.664028],
                [-16.650972, 20.664028],
                [-16.650972, 20.664305],
                [-16.652081999999893, 20.664305],
                [-16.652081999999893, 20.664581],
                [-16.652361, 20.664581],
                [-16.652361, 20.664864],
                [-16.654306, 20.664864],
                [-16.654306, 20.665138],
                [-16.654861, 20.665138],
                [-16.654861, 20.665417000000105],
                [-16.655415999999889, 20.665417000000105],
                [-16.655415999999889, 20.665695],
                [-16.655972, 20.665695],
                [-16.655972, 20.665974000000176],
                [-16.657359999999812, 20.665974000000176],
                [-16.657359999999812, 20.665695],
                [-16.658194, 20.665695],
                [-16.658194, 20.665974000000176],
                [-16.65847, 20.665974000000176],
                [-16.65847, 20.666529],
                [-16.658750999999882, 20.666529],
                [-16.658750999999882, 20.667084000000159],
                [-16.659027, 20.667084000000159],
                [-16.659027, 20.667917000000159],
                [-16.659306, 20.667917000000159],
                [-16.659346, 20.670139000000177],
                [-16.659581999999887, 20.670139000000177],
                [-16.659581999999887, 20.670431000000178],
                [-16.659581999999887, 20.670694],
                [-16.659861, 20.670694],
                [-16.659861, 20.670771],
                [-16.659861, 20.670973],
                [-16.66007, 20.671028],
                [-16.660206, 20.671249000000159],
                [-16.660417999999879, 20.671249000000159],
                [-16.660479, 20.671528],
                [-16.660691999999813, 20.671528],
                [-16.660705999999891, 20.671806],
                [-16.660975, 20.671806],
                [-16.660949999999843, 20.672104],
                [-16.662361, 20.6720830000001],
                [-16.662361, 20.672361],
                [-16.66264, 20.672361],
                [-16.66264, 20.6729160000001],
                [-16.662361, 20.6729160000001],
                [-16.662361, 20.673836000000165],
                [-16.662361, 20.674028],
                [-16.662579999999878, 20.674057000000175],
                [-16.66264, 20.674177000000157],
                [-16.66264, 20.674862000000132],
                [-16.662913999999887, 20.674862000000132],
                [-16.66295099999985, 20.674557000000163],
                [-16.663749999999879, 20.674583000000155],
                [-16.663749999999879, 20.674862000000132],
                [-16.664307, 20.674862000000132],
                [-16.664307, 20.675138],
                [-16.665138, 20.675138],
                [-16.665138, 20.675417],
                [-16.665695, 20.675417],
                [-16.665695, 20.675138],
                [-16.667917, 20.675138],
                [-16.667917, 20.675417],
                [-16.672916, 20.675417],
                [-16.672916, 20.675138],
                [-16.673195, 20.675138],
                [-16.673195, 20.674862000000132],
                [-16.673471, 20.674862000000132],
                [-16.673471, 20.674583000000155],
                [-16.67375, 20.674583000000155],
                [-16.67375, 20.674028],
                [-16.674028, 20.674028],
                [-16.674028, 20.673750000000155],
                [-16.674582, 20.673750000000155],
                [-16.674582, 20.673471000000177],
                [-16.675974, 20.673471000000177],
                [-16.675974, 20.676249000000155],
                [-16.67625, 20.676249000000155],
                [-16.67625, 20.676727],
                [-16.67625, 20.67708400000015],
                [-16.676525, 20.67708400000015],
                [-16.676525, 20.677641],
                [-16.676804, 20.677641],
                [-16.676804, 20.678749],
                [-16.677086, 20.678749],
                [-16.677086, 20.679861],
                [-16.677360999999848, 20.679861],
                [-16.677360999999848, 20.680695000000128],
                [-16.677639, 20.680695000000128],
                [-16.677639, 20.683750000000146],
                [-16.677917, 20.683750000000146],
                [-16.677917, 20.684029000000123],
                [-16.678196, 20.684029000000123],
                [-16.678196, 20.684584],
                [-16.678473, 20.684584],
                [-16.678473, 20.684860000000128],
                [-16.678749, 20.684860000000128],
                [-16.678749, 20.685139000000106],
                [-16.679027999999846, 20.685139000000106],
                [-16.679027999999846, 20.685415],
                [-16.679308, 20.685415],
                [-16.679308, 20.685757],
                [-16.679308, 20.686806],
                [-16.679583, 20.686806],
                [-16.679583, 20.687916],
                [-16.679860999999846, 20.687916],
                [-16.679860999999846, 20.688473000000101],
                [-16.679583, 20.688473000000101],
                [-16.679583, 20.689028],
                [-16.679860999999846, 20.689028],
                [-16.679860999999846, 20.689859],
                [-16.68014, 20.689859],
                [-16.68014, 20.690142],
                [-16.680695, 20.690142],
                [-16.680695, 20.690416000000141],
                [-16.680973, 20.690416000000141],
                [-16.680973, 20.690695000000119],
                [-16.680695, 20.690695000000119],
                [-16.680695, 20.690969],
                [-16.679583, 20.690969],
                [-16.679583, 20.69125200000019],
                [-16.679308, 20.69125200000019],
                [-16.679308, 20.691528000000119],
                [-16.679027999999846, 20.691528000000119],
                [-16.679027999999846, 20.691805000000102],
                [-16.678473, 20.691805000000102],
                [-16.678473, 20.692083],
                [-16.677917, 20.692083],
                [-16.677917, 20.692364000000168],
                [-16.677639, 20.692364000000168],
                [-16.677639, 20.692638000000102],
                [-16.677360999999848, 20.692638000000102],
                [-16.677360999999848, 20.692917000000136],
                [-16.677086, 20.692917000000136],
                [-16.677086, 20.693195],
                [-16.676804, 20.693195],
                [-16.676804, 20.693474],
                [-16.676525, 20.693474],
                [-16.676525, 20.693750000000136],
                [-16.675974, 20.693750000000136],
                [-16.675974, 20.694027000000119],
                [-16.675694999999848, 20.694027000000119],
                [-16.675694999999848, 20.694586000000186],
                [-16.675417, 20.694586000000186],
                [-16.675417, 20.695139],
                [-16.675138, 20.695139],
                [-16.675138, 20.695417000000191],
                [-16.674864, 20.695417000000191],
                [-16.674864, 20.695696000000169],
                [-16.674582, 20.695696000000169],
                [-16.674582, 20.695972],
                [-16.674303, 20.695972],
                [-16.674303, 20.696251],
                [-16.674028, 20.696251],
                [-16.674028, 20.696527],
                [-16.67375, 20.696527],
                [-16.67375, 20.697361000000114],
                [-16.673471, 20.697361000000114],
                [-16.673471, 20.697639],
                [-16.673195, 20.697639],
                [-16.673195, 20.698195000000169],
                [-16.672916, 20.698195000000169],
                [-16.672916, 20.698473],
                [-16.672638, 20.698473],
                [-16.672638, 20.69875],
                [-16.6723, 20.698763],
                [-16.672361, 20.699026],
                [-16.672083, 20.699089],
                [-16.672083, 20.699305],
                [-16.671967, 20.699305],
                [-16.671806, 20.699306],
                [-16.671812, 20.699589],
                [-16.671528, 20.699583],
                [-16.671528, 20.700115],
                [-16.671528, 20.700417000000186],
                [-16.671366, 20.700417000000186],
                [-16.671249, 20.700417000000186],
                [-16.671249, 20.700628],
                [-16.671249, 20.700972],
                [-16.670973, 20.70101],
                [-16.670973, 20.701141],
                [-16.670973, 20.701805],
                [-16.67063, 20.701771000000122],
                [-16.670694, 20.702358],
                [-16.670422, 20.702328],
                [-16.670416, 20.702917],
                [-16.670139, 20.702917],
                [-16.670139, 20.70347],
                [-16.669861, 20.70347],
                [-16.669861, 20.704306],
                [-16.669935, 20.704306],
                [-16.670139, 20.704306],
                [-16.67013, 20.7045940000001],
                [-16.670416, 20.704584000000182],
                [-16.670341, 20.70490800000016],
                [-16.670694, 20.704863000000159],
                [-16.670694, 20.705139],
                [-16.670973, 20.705139],
                [-16.670973, 20.705416],
                [-16.671806, 20.705418],
                [-16.671806, 20.705694000000165],
                [-16.67375, 20.705694000000165],
                [-16.67375, 20.706249],
                [-16.674028, 20.706249],
                [-16.674028, 20.706528000000105],
                [-16.674303, 20.706528000000105],
                [-16.674303, 20.707085000000177],
                [-16.674582, 20.707085000000177],
                [-16.674582, 20.70764],
                [-16.674303, 20.70764],
                [-16.674303, 20.707916000000182],
                [-16.674028, 20.707916000000182],
                [-16.674028, 20.70819500000016],
                [-16.67375, 20.70819500000016],
                [-16.67375, 20.708471],
                [-16.673471, 20.708471],
                [-16.673471, 20.70875],
                [-16.673195, 20.70875],
                [-16.673195, 20.70902800000016],
                [-16.672916, 20.70902800000016],
                [-16.672916, 20.709307000000138],
                [-16.672638, 20.709307000000138],
                [-16.672638, 20.709862],
                [-16.672361, 20.709862],
                [-16.672361, 20.710138],
                [-16.672096, 20.710138],
                [-16.672083, 20.710417000000177],
                [-16.671806, 20.710417000000177],
                [-16.671806, 20.710695],
                [-16.671528, 20.710695],
                [-16.671528, 20.710972],
                [-16.671249, 20.710972],
                [-16.671249, 20.71152700000016],
                [-16.670973, 20.71152700000016],
                [-16.670973, 20.712084],
                [-16.670694, 20.712084],
                [-16.670694, 20.712639000000138],
                [-16.670416, 20.712639000000138],
                [-16.670416, 20.713472000000138],
                [-16.670139, 20.713472000000138],
                [-16.670139, 20.714027],
                [-16.669861, 20.714027],
                [-16.669861, 20.715141],
                [-16.669584, 20.715141],
                [-16.669581999999878, 20.715416],
                [-16.669306, 20.715416],
                [-16.669306, 20.715694000000155],
                [-16.669027, 20.715694000000155],
                [-16.669027, 20.716249],
                [-16.668751, 20.716249],
                [-16.668751, 20.716806000000133],
                [-16.668472, 20.716806000000133],
                [-16.668484, 20.717922000000101],
                [-16.668194, 20.717916000000173],
                [-16.668194, 20.718304000000103],
                [-16.668194, 20.718473],
                [-16.668472, 20.718473],
                [-16.668472, 20.719584000000111],
                [-16.668751, 20.719584000000111],
                [-16.668751, 20.721251],
                [-16.669027, 20.721251],
                [-16.669027, 20.721527000000151],
                [-16.669306, 20.721527000000151],
                [-16.669306, 20.722361],
                [-16.669581999999878, 20.722361],
                [-16.669581999999878, 20.725416],
                [-16.669306, 20.725416],
                [-16.669306, 20.728195000000142],
                [-16.669027, 20.728195000000142],
                [-16.669027, 20.728748],
                [-16.668751, 20.728748],
                [-16.668751, 20.729584000000102],
                [-16.668472, 20.729584000000102],
                [-16.668472, 20.730694000000142],
                [-16.668194, 20.730694000000142],
                [-16.668194, 20.731527000000142],
                [-16.667917, 20.731527000000142],
                [-16.667917, 20.73513800000012],
                [-16.668194, 20.735140000000115],
                [-16.668194, 20.735695000000192],
                [-16.668472, 20.735695000000192],
                [-16.668472, 20.73625],
                [-16.668751, 20.73625],
                [-16.668751, 20.736807000000169],
                [-16.669027, 20.736807000000169],
                [-16.669027, 20.737083],
                [-16.669306, 20.737083],
                [-16.669306, 20.737638000000175],
                [-16.669581999999878, 20.737638000000175],
                [-16.669584, 20.737917],
                [-16.669861, 20.737917],
                [-16.669861, 20.738472000000115],
                [-16.670139, 20.738472000000115],
                [-16.670139, 20.73875],
                [-16.670416, 20.73875],
                [-16.670416, 20.739029000000187],
                [-16.670694, 20.739029000000187],
                [-16.670694, 20.739305000000115],
                [-16.670973, 20.739305000000115],
                [-16.670973, 20.739584],
                [-16.671249, 20.739584],
                [-16.671249, 20.739861],
                [-16.671528, 20.739861],
                [-16.671528, 20.740137000000175],
                [-16.672083, 20.740137000000175],
                [-16.672083, 20.740417],
                [-16.672361, 20.740417],
                [-16.672361, 20.740694],
                [-16.672638, 20.740694],
                [-16.672638, 20.74097300000011],
                [-16.673195, 20.74097300000011],
                [-16.673195, 20.741247],
                [-16.674303, 20.741247],
                [-16.674303, 20.741528000000187],
                [-16.679860999999846, 20.741528000000187],
                [-16.679860999999846, 20.741806000000111],
                [-16.680695, 20.741806000000111],
                [-16.680695, 20.742083],
                [-16.680973, 20.742083],
                [-16.680973, 20.742359],
                [-16.681528, 20.742359],
                [-16.681528, 20.742641],
                [-16.682083, 20.742641],
                [-16.682083, 20.742916],
                [-16.682638, 20.742916],
                [-16.682638, 20.743195],
                [-16.683194999999898, 20.743195],
                [-16.683194999999898, 20.74347100000017],
                [-16.684028999999896, 20.74347100000017],
                [-16.684028999999896, 20.743752000000143],
                [-16.68486, 20.743752000000143],
                [-16.68486, 20.744028],
                [-16.686806, 20.744028],
                [-16.686806, 20.743752000000143],
                [-16.687639, 20.743752000000143],
                [-16.687639, 20.744028],
                [-16.688475, 20.744028],
                [-16.688475, 20.744305000000111],
                [-16.688749, 20.744305000000111],
                [-16.688749, 20.744581],
                [-16.689027999999894, 20.744581],
                [-16.689027999999894, 20.745138000000111],
                [-16.689305999999817, 20.745138000000111],
                [-16.689305999999817, 20.745417],
                [-16.689860999999894, 20.745417],
                [-16.689860999999894, 20.745695000000183],
                [-16.690139999999815, 20.745695000000183],
                [-16.690139999999815, 20.74597400000016],
                [-16.690696999999886, 20.74597400000016],
                [-16.690696999999886, 20.74625],
                [-16.690971, 20.74625],
                [-16.690971, 20.746529],
                [-16.69125, 20.746529],
                [-16.69125, 20.746805000000165],
                [-16.691806999999869, 20.746805000000165],
                [-16.691806999999869, 20.747084000000143],
                [-16.692637999999818, 20.747084000000143],
                [-16.692637999999818, 20.74736],
                [-16.693194999999889, 20.74736],
                [-16.693194999999889, 20.747639],
                [-16.69375, 20.747639],
                [-16.69375, 20.747917000000143],
                [-16.694304999999815, 20.747917000000143],
                [-16.694304999999815, 20.748196000000178],
                [-16.69652699999989, 20.748196000000178],
                [-16.69652699999989, 20.748472],
                [-16.696806, 20.748472],
                [-16.696806, 20.748751],
                [-16.697358999999892, 20.748751],
                [-16.697358999999892, 20.749027000000183],
                [-16.697639, 20.749027000000183],
                [-16.697639, 20.749306000000161],
                [-16.698194999999885, 20.749306000000161],
                [-16.698194999999885, 20.749584],
                [-16.69847099999987, 20.749584],
                [-16.69847099999987, 20.749861],
                [-16.69875, 20.749861],
                [-16.69875, 20.750416000000143],
                [-16.699027999999885, 20.750416000000143],
                [-16.699027999999885, 20.750694],
                [-16.699305, 20.750694],
                [-16.699306, 20.750973],
                [-16.699581, 20.750973],
                [-16.699581, 20.751528000000178],
                [-16.699863, 20.751528000000178],
                [-16.699863, 20.751806],
                [-16.700138, 20.751806],
                [-16.700138, 20.752361000000178],
                [-16.700417, 20.752361000000178],
                [-16.700417, 20.752916],
                [-16.700692999999887, 20.752916],
                [-16.700692999999887, 20.753471000000161],
                [-16.700972, 20.753471000000161],
                [-16.700972, 20.754305],
                [-16.70125, 20.754305],
                [-16.70125, 20.754583000000139],
                [-16.701529, 20.754583000000139],
                [-16.701529, 20.754862000000116],
                [-16.702359999999885, 20.754862000000116],
                [-16.702362, 20.755138],
                [-16.702639, 20.755138],
                [-16.702639, 20.755695000000117],
                [-16.702917, 20.755695000000117],
                [-16.702917, 20.755972000000156],
                [-16.703196, 20.755972000000156],
                [-16.703196, 20.756527],
                [-16.703472, 20.756527],
                [-16.703472, 20.757084000000134],
                [-16.703751, 20.757084000000134],
                [-16.703751, 20.757641],
                [-16.704024999999888, 20.757641],
                [-16.704024999999888, 20.758471000000156],
                [-16.703751, 20.758471000000156],
                [-16.703751, 20.759306000000151],
                [-16.704024999999888, 20.759306000000151],
                [-16.704024999999888, 20.759863],
                [-16.704306, 20.759863],
                [-16.704306, 20.760416000000134],
                [-16.704584, 20.760416000000134],
                [-16.704584, 20.760695000000112],
                [-16.704861, 20.760695000000112],
                [-16.704861, 20.760975],
                [-16.705139, 20.760975],
                [-16.705139, 20.76125],
                [-16.705418, 20.76125],
                [-16.705418, 20.761528000000112],
                [-16.705694, 20.761528000000112],
                [-16.705694, 20.761806],
                [-16.705973, 20.761806],
                [-16.705973, 20.762362],
                [-16.706249, 20.762362],
                [-16.706249, 20.762638000000152],
                [-16.706528, 20.762638000000152],
                [-16.706528, 20.76291700000013],
                [-16.706806, 20.76291700000013],
                [-16.706806, 20.763472],
                [-16.707083, 20.763472],
                [-16.707083, 20.764862000000107],
                [-16.707361, 20.764862000000107],
                [-16.707361, 20.765139000000147],
                [-16.707916, 20.765139000000147],
                [-16.707916, 20.765415],
                [-16.70875, 20.765415],
                [-16.70875, 20.765694],
                [-16.709583, 20.765694],
                [-16.709583, 20.765972000000147],
                [-16.712084, 20.765972000000147],
                [-16.712084, 20.765694],
                [-16.71236, 20.765694],
                [-16.71236, 20.765415],
                [-16.712917, 20.765415],
                [-16.712917, 20.765694],
                [-16.713194, 20.765694],
                [-16.713194, 20.765972000000147],
                [-16.713474, 20.765972000000147],
                [-16.713474, 20.767361000000108],
                [-16.713194, 20.767361000000108],
                [-16.713194, 20.768473],
                [-16.713474, 20.768473],
                [-16.713474, 20.769028],
                [-16.713753, 20.769028],
                [-16.713753, 20.769583000000125],
                [-16.714027, 20.769583000000125],
                [-16.714027, 20.770142000000192],
                [-16.714305999999851, 20.770142000000192],
                [-16.714305999999851, 20.770695000000103],
                [-16.714582, 20.770695000000103],
                [-16.714582, 20.771252000000175],
                [-16.714861, 20.771252000000175],
                [-16.714861, 20.772364],
                [-16.715138999999851, 20.772364],
                [-16.715138999999851, 20.776251000000116],
                [-16.715414, 20.776251000000116],
                [-16.715414, 20.780416000000116],
                [-16.715693, 20.780416000000116],
                [-16.715693, 20.780972],
                [-16.715975, 20.780972],
                [-16.715975, 20.781248000000176],
                [-16.716249, 20.781248000000176],
                [-16.716249, 20.781805],
                [-16.716528, 20.781805],
                [-16.716528, 20.782084],
                [-16.716806, 20.782084],
                [-16.716806, 20.78236],
                [-16.717085, 20.78236],
                [-16.717085, 20.782639000000188],
                [-16.717361, 20.782639000000188],
                [-16.717361, 20.782917],
                [-16.717636, 20.782917],
                [-16.717636, 20.783194],
                [-16.717916, 20.783194],
                [-16.717916, 20.78347],
                [-16.718472, 20.78347],
                [-16.718472, 20.783752],
                [-16.71875, 20.783752],
                [-16.71875, 20.784027],
                [-16.719028, 20.784027],
                [-16.719028, 20.784584000000166],
                [-16.719307, 20.784584000000166],
                [-16.719307, 20.784863000000144],
                [-16.719584, 20.784863000000144],
                [-16.719584, 20.785139],
                [-16.719862, 20.785139],
                [-16.719862, 20.785692],
                [-16.720139, 20.785692],
                [-16.720139, 20.786806000000183],
                [-16.720418999999822, 20.786806000000183],
                [-16.720418999999822, 20.78764],
                [-16.720694, 20.78764],
                [-16.720694, 20.788195000000144],
                [-16.720972, 20.788195000000144],
                [-16.720972, 20.789028000000144],
                [-16.721251, 20.789028000000144],
                [-16.721251, 20.790417000000161],
                [-16.721527, 20.790417000000161],
                [-16.721527, 20.790972],
                [-16.721806, 20.790972],
                [-16.721806, 20.791805],
                [-16.722084, 20.791805],
                [-16.722084, 20.792639000000122],
                [-16.721806, 20.792639000000122],
                [-16.721806, 20.792917],
                [-16.721527, 20.792917],
                [-16.721527, 20.793194],
                [-16.721251, 20.793194],
                [-16.721251, 20.796249],
                [-16.721527, 20.796249],
                [-16.721527, 20.796528],
                [-16.721251, 20.796528],
                [-16.721251, 20.796806000000117],
                [-16.720972, 20.796806000000117],
                [-16.720972, 20.797083000000157],
                [-16.720694, 20.797083000000157],
                [-16.720694, 20.797359],
                [-16.720418999999822, 20.797359],
                [-16.720418999999822, 20.797916000000157],
                [-16.720139, 20.797916000000157],
                [-16.720139, 20.798195000000135],
                [-16.719862, 20.798195000000135],
                [-16.719862, 20.798473],
                [-16.719584, 20.798473],
                [-16.719584, 20.798752],
                [-16.719307, 20.798752],
                [-16.719307, 20.799583],
                [-16.719584, 20.799583],
                [-16.719584, 20.799864000000184],
                [-16.719862, 20.799864000000184],
                [-16.719862, 20.800417000000152],
                [-16.718979, 20.800426000000186],
                [-16.719028, 20.80069200000014],
                [-16.717916, 20.80069200000014],
                [-16.717916, 20.800974],
                [-16.717636, 20.800974],
                [-16.717636, 20.801527000000135],
                [-16.717361, 20.801527000000135],
                [-16.717361, 20.802361],
                [-16.717636, 20.802361],
                [-16.717636, 20.802917],
                [-16.717916, 20.802917],
                [-16.717916, 20.803473],
                [-16.718197, 20.803473],
                [-16.718197, 20.80402800000013],
                [-16.718472, 20.80402800000013],
                [-16.718472, 20.804308],
                [-16.71875, 20.804308],
                [-16.71875, 20.804583],
                [-16.719307, 20.804583],
                [-16.719307, 20.804308],
                [-16.719584, 20.804308],
                [-16.719584, 20.80402800000013],
                [-16.720139, 20.80402800000013],
                [-16.720139, 20.803749000000153],
                [-16.720694, 20.803749000000153],
                [-16.720703, 20.803465],
                [-16.721806, 20.803473],
                [-16.721806, 20.803749000000153],
                [-16.722084, 20.803749000000153],
                [-16.722084, 20.80402800000013],
                [-16.722361, 20.80402800000013],
                [-16.722361, 20.804308],
                [-16.722639, 20.804308],
                [-16.722639, 20.804583],
                [-16.722917999999822, 20.804583],
                [-16.722917999999822, 20.80486100000013],
                [-16.723472999999899, 20.80486100000013],
                [-16.723472999999899, 20.804583],
                [-16.723749, 20.804583],
                [-16.723749, 20.803749000000153],
                [-16.724028, 20.803749000000153],
                [-16.724028, 20.803473],
                [-16.724305999999899, 20.803473],
                [-16.724305999999899, 20.803196000000185],
                [-16.724583, 20.803196000000185],
                [-16.724583, 20.803473],
                [-16.724859, 20.803473],
                [-16.724859, 20.80402800000013],
                [-16.725139999999897, 20.80402800000013],
                [-16.725139999999897, 20.804308],
                [-16.725416, 20.804308],
                [-16.725416, 20.804583],
                [-16.725695, 20.804583],
                [-16.725695, 20.805140000000108],
                [-16.725971, 20.805140000000108],
                [-16.725971, 20.805416],
                [-16.726249999999823, 20.805416],
                [-16.726249999999823, 20.805695000000185],
                [-16.726528, 20.805695000000185],
                [-16.726528, 20.805973000000108],
                [-16.727081, 20.805973000000108],
                [-16.727081, 20.80625],
                [-16.727637999999899, 20.80625],
                [-16.727637999999899, 20.807083],
                [-16.72791699999982, 20.807083],
                [-16.72791699999982, 20.807638],
                [-16.728193, 20.807638],
                [-16.728193, 20.807917],
                [-16.728471999999897, 20.807917],
                [-16.728471999999897, 20.808472000000108],
                [-16.72875, 20.808472000000108],
                [-16.72875, 20.808748],
                [-16.729029, 20.808748],
                [-16.729029, 20.809031000000175],
                [-16.729303, 20.809031000000175],
                [-16.729303, 20.809305000000109],
                [-16.72958599999987, 20.809305000000109],
                [-16.72958599999987, 20.809584],
                [-16.72986, 20.809584],
                [-16.72986, 20.809858],
                [-16.730416999999875, 20.809858],
                [-16.730416999999875, 20.810139],
                [-16.730696, 20.810139],
                [-16.730696, 20.810417],
                [-16.730971999999895, 20.810417],
                [-16.730971999999895, 20.810694000000126],
                [-16.731250999999872, 20.810694000000126],
                [-16.731250999999872, 20.81097],
                [-16.731527, 20.81097],
                [-16.731527, 20.811253],
                [-16.731807999999887, 20.811253],
                [-16.731807999999887, 20.811806000000104],
                [-16.732081999999821, 20.811806000000104],
                [-16.732083999999873, 20.812363000000175],
                [-16.732361, 20.812363000000175],
                [-16.732361, 20.81319400000018],
                [-16.732638999999892, 20.81319400000018],
                [-16.732638999999892, 20.813475000000153],
                [-16.73291799999987, 20.813475000000153],
                [-16.73291799999987, 20.813749],
                [-16.733194, 20.813749],
                [-16.733194, 20.814028000000121],
                [-16.73347299999989, 20.814028000000121],
                [-16.73347299999989, 20.814585],
                [-16.733748999999875, 20.814585],
                [-16.733748999999875, 20.814861000000121],
                [-16.734028, 20.814861000000121],
                [-16.734028, 20.815416],
                [-16.73430599999989, 20.815416],
                [-16.73430599999989, 20.815695000000176],
                [-16.734582999999873, 20.815695000000176],
                [-16.734582999999873, 20.815971000000104],
                [-16.734861, 20.815971000000104],
                [-16.734861, 20.81625],
                [-16.735415999999873, 20.81625],
                [-16.735415999999873, 20.816807000000153],
                [-16.735695, 20.816807000000153],
                [-16.735695, 20.817917],
                [-16.735970999999893, 20.817917],
                [-16.735970999999893, 20.818195],
                [-16.73625, 20.818195],
                [-16.73625, 20.818472],
                [-16.736528, 20.818472],
                [-16.736528, 20.81875],
                [-16.73708299999987, 20.81875],
                [-16.73708299999987, 20.819027000000176],
                [-16.73763799999989, 20.819027000000176],
                [-16.73763799999989, 20.819305],
                [-16.738194, 20.819305],
                [-16.738194, 20.819584],
                [-16.738469999999893, 20.819584],
                [-16.738469999999893, 20.819860000000176],
                [-16.73875, 20.819860000000176],
                [-16.73875, 20.820139000000154],
                [-16.739304999999888, 20.820139000000154],
                [-16.739304999999888, 20.820417],
                [-16.739581999999871, 20.820417],
                [-16.739581999999871, 20.820694],
                [-16.740139, 20.820694],
                [-16.740139, 20.820972000000154],
                [-16.740417, 20.820972000000154],
                [-16.740417, 20.821251000000188],
                [-16.740692, 20.821251000000188],
                [-16.740692, 20.821527],
                [-16.741249, 20.821527],
                [-16.741249, 20.821806],
                [-16.741803999999888, 20.821806],
                [-16.741803999999888, 20.822083],
                [-16.742083, 20.822083],
                [-16.742083, 20.822359000000176],
                [-16.742361, 20.822359000000176],
                [-16.742361, 20.822641],
                [-16.745695, 20.822641],
                [-16.745695, 20.822916],
                [-16.745972, 20.822916],
                [-16.745972, 20.823195],
                [-16.746529, 20.823195],
                [-16.746529, 20.823471000000154],
                [-16.747083999999859, 20.823471000000154],
                [-16.747083999999859, 20.823750000000189],
                [-16.748194, 20.823750000000189],
                [-16.748194, 20.82403],
                [-16.748750999999857, 20.82403],
                [-16.748750999999857, 20.824306000000149],
                [-16.749306, 20.824306000000149],
                [-16.749306, 20.824860000000172],
                [-16.749583999999857, 20.824860000000172],
                [-16.749583999999857, 20.825138],
                [-16.749861, 20.825138],
                [-16.749861, 20.825417],
                [-16.750415999999859, 20.825417],
                [-16.750415999999859, 20.825695000000167],
                [-16.750973, 20.825695000000167],
                [-16.750973, 20.825974000000144],
                [-16.751248999999859, 20.825974000000144],
                [-16.751248999999859, 20.82625],
                [-16.751528, 20.82625],
                [-16.751528, 20.826529],
                [-16.751806, 20.826529],
                [-16.751806, 20.826803000000154],
                [-16.752082999999857, 20.826803000000154],
                [-16.752082999999857, 20.827084000000127],
                [-16.75264, 20.827084000000127],
                [-16.75264, 20.827362],
                [-16.753195, 20.827362],
                [-16.753195, 20.827639],
                [-16.753471, 20.827639],
                [-16.753471, 20.827917000000127],
                [-16.75375, 20.827917000000127],
                [-16.75375, 20.828196000000162],
                [-16.754028, 20.828196000000162],
                [-16.754028, 20.828472],
                [-16.754305, 20.828472],
                [-16.754305, 20.828751],
                [-16.75458099999986, 20.828751],
                [-16.75458099999986, 20.829027000000167],
                [-16.755138, 20.829027000000167],
                [-16.755138, 20.829306000000145],
                [-16.755974, 20.829306000000145],
                [-16.755974, 20.829584],
                [-16.755693, 20.829584],
                [-16.755693, 20.829861],
                [-16.755974, 20.829861],
                [-16.755974, 20.830694],
                [-16.75625, 20.830694],
                [-16.75625, 20.831249000000128],
                [-16.756527, 20.831249000000128],
                [-16.756527, 20.831528000000162],
                [-16.756804, 20.831528000000162],
                [-16.756804, 20.831806],
                [-16.757086, 20.831806],
                [-16.757086, 20.831528000000162],
                [-16.75736, 20.831528000000162],
                [-16.75736, 20.831249000000128],
                [-16.757639, 20.831249000000128],
                [-16.757639, 20.831528000000162],
                [-16.757917, 20.831528000000162],
                [-16.757917, 20.83264000000014],
                [-16.758195999999828, 20.83264000000014],
                [-16.758195999999828, 20.833750000000123],
                [-16.758472, 20.833750000000123],
                [-16.758472, 20.834583000000123],
                [-16.758751, 20.834583000000123],
                [-16.758751, 20.83597200000014],
                [-16.759027, 20.83597200000014],
                [-16.759027, 20.83736],
                [-16.759308, 20.83736],
                [-16.759308, 20.83847],
                [-16.759583, 20.83847],
                [-16.759583, 20.839306000000136],
                [-16.759861, 20.839306000000136],
                [-16.759861, 20.839581000000123],
                [-16.759583, 20.839581000000123],
                [-16.759583, 20.840139000000136],
                [-16.759308, 20.840139000000136],
                [-16.759308, 20.840975000000185],
                [-16.759027, 20.840975000000185],
                [-16.759027, 20.841528],
                [-16.758751, 20.841528],
                [-16.758751, 20.842085],
                [-16.758472, 20.842085],
                [-16.758472, 20.842638000000136],
                [-16.758195999999828, 20.842638000000136],
                [-16.758195999999828, 20.84347200000019],
                [-16.757917, 20.84347200000019],
                [-16.757917, 20.844307000000185],
                [-16.757639, 20.844307000000185],
                [-16.757639, 20.846527],
                [-16.757917, 20.846527],
                [-16.757917, 20.849583000000109],
                [-16.757639, 20.849583000000109],
                [-16.757639, 20.857918000000154],
                [-16.757917, 20.857918000000154],
                [-16.757917, 20.859028],
                [-16.758195999999828, 20.859028],
                [-16.758195999999828, 20.8604160000001],
                [-16.758472, 20.8604160000001],
                [-16.758472, 20.861528],
                [-16.758751, 20.861528],
                [-16.758751, 20.862362000000132],
                [-16.759027, 20.862362000000132],
                [-16.759027, 20.863470000000177],
                [-16.759308, 20.863470000000177],
                [-16.759308, 20.864305000000172],
                [-16.759583, 20.864305000000172],
                [-16.759583, 20.865141],
                [-16.759861, 20.865141],
                [-16.759861, 20.865692],
                [-16.760139, 20.865692],
                [-16.760139, 20.866528],
                [-16.760418, 20.866528],
                [-16.760418, 20.867361],
                [-16.760694999999828, 20.867361],
                [-16.760694999999828, 20.868195000000128],
                [-16.760973, 20.868195000000128],
                [-16.760973, 20.86875],
                [-16.76125, 20.86875],
                [-16.76125, 20.869862],
                [-16.76153, 20.869862],
                [-16.76153, 20.870417000000145],
                [-16.761805, 20.870417000000145],
                [-16.761806, 20.870695],
                [-16.762083, 20.870695],
                [-16.762083, 20.870972],
                [-16.762917, 20.870972],
                [-16.762917, 20.871250000000146],
                [-16.764305, 20.871250000000146],
                [-16.764305, 20.871529000000123],
                [-16.764584, 20.871529000000123],
                [-16.764584, 20.871805],
                [-16.764859999999828, 20.871805],
                [-16.764859999999828, 20.872084],
                [-16.765139, 20.872084],
                [-16.765139, 20.872360000000128],
                [-16.765417, 20.872360000000128],
                [-16.765417, 20.872917],
                [-16.765693999999826, 20.872917],
                [-16.765693999999826, 20.873194],
                [-16.76597, 20.873194],
                [-16.76597, 20.873472000000163],
                [-16.766250999999897, 20.873472000000163],
                [-16.766250999999897, 20.874027],
                [-16.766526999999826, 20.874027],
                [-16.766526999999826, 20.874306],
                [-16.766806, 20.874306],
                [-16.766806, 20.874584000000141],
                [-16.767082, 20.874584000000141],
                [-16.767082, 20.874861000000124],
                [-16.76736099999988, 20.874861000000124],
                [-16.76736099999988, 20.875416],
                [-16.767639, 20.875416],
                [-16.767639, 20.875973000000101],
                [-16.767916, 20.875973000000101],
                [-16.767916, 20.876249],
                [-16.768191999999829, 20.876249],
                [-16.768191999999829, 20.876806000000101],
                [-16.768475, 20.876806000000101],
                [-16.768475, 20.877916000000141],
                [-16.768749, 20.877916000000141],
                [-16.768749, 20.878473],
                [-16.769027999999878, 20.878473],
                [-16.769027999999878, 20.879028000000119],
                [-16.769304, 20.879028000000119],
                [-16.769304, 20.879581],
                [-16.769584999999893, 20.879581],
                [-16.769584999999893, 20.880138000000102],
                [-16.769304, 20.880138000000102],
                [-16.769304, 20.880974],
                [-16.769584999999893, 20.880974],
                [-16.769584999999893, 20.881250000000136],
                [-16.769860999999878, 20.881250000000136],
                [-16.769860999999878, 20.882086000000186],
                [-16.77014, 20.882086000000186],
                [-16.77014, 20.882639],
                [-16.770414, 20.882639],
                [-16.770414, 20.883472],
                [-16.770696999999871, 20.883472],
                [-16.770696999999871, 20.883751000000132],
                [-16.770971, 20.883751000000132],
                [-16.770971, 20.884308],
                [-16.771249999999895, 20.884308],
                [-16.771249999999895, 20.884861000000114],
                [-16.771527999999876, 20.884861000000114],
                [-16.771527999999876, 20.885418000000186],
                [-16.771807, 20.885418000000186],
                [-16.771807, 20.885973],
                [-16.772082999999895, 20.885973],
                [-16.772082999999895, 20.886526],
                [-16.772361999999873, 20.886526],
                [-16.772361999999873, 20.88736200000011],
                [-16.772638, 20.88736200000011],
                [-16.772638, 20.887917000000186],
                [-16.772919, 20.887917000000186],
                [-16.772919, 20.88819500000011],
                [-16.773194999999873, 20.88819500000011],
                [-16.773194999999873, 20.888748000000191],
                [-16.773472, 20.888748000000191],
                [-16.773472, 20.889305],
                [-16.773749999999893, 20.889305],
                [-16.773749999999893, 20.889584],
                [-16.774028999999871, 20.889584],
                [-16.774028999999871, 20.890417],
                [-16.774305, 20.890417],
                [-16.774305, 20.89069400000011],
                [-16.774584, 20.89069400000011],
                [-16.774584, 20.891253000000177],
                [-16.774859999999876, 20.891253000000177],
                [-16.774859999999876, 20.89152700000011],
                [-16.775139, 20.89152700000011],
                [-16.775139, 20.892639],
                [-16.775417, 20.892639],
                [-16.775417, 20.894028000000105],
                [-16.775139, 20.894028000000105],
                [-16.775139, 20.894861000000105],
                [-16.774859999999876, 20.894861000000105],
                [-16.774859999999876, 20.89514],
                [-16.774584, 20.89514],
                [-16.774584, 20.895971],
                [-16.774859999999876, 20.895971],
                [-16.774859999999876, 20.89625],
                [-16.775139, 20.89625],
                [-16.775139, 20.897638],
                [-16.774859999999876, 20.897638],
                [-16.774859999999876, 20.899584],
                [-16.775139, 20.899584],
                [-16.775139, 20.906803],
                [-16.775417, 20.906803],
                [-16.775417, 20.908195],
                [-16.775693999999874, 20.908195],
                [-16.775693999999874, 20.909027000000151],
                [-16.775972, 20.909027000000151],
                [-16.775972, 20.910139000000129],
                [-16.776251, 20.910139000000129],
                [-16.776251, 20.912916],
                [-16.776526999999874, 20.912916],
                [-16.776526999999874, 20.913750000000107],
                [-16.776806, 20.913750000000107],
                [-16.776806, 20.915138],
                [-16.777081999999893, 20.915138],
                [-16.777081999999893, 20.916531000000191],
                [-16.777360999999871, 20.916531000000191],
                [-16.777360999999871, 20.91930600000012],
                [-16.777639, 20.91930600000012],
                [-16.777639, 20.92625],
                [-16.777916, 20.92625],
                [-16.777916, 20.928194],
                [-16.778193999999871, 20.928194],
                [-16.778193999999871, 20.92847300000011],
                [-16.778473, 20.92847300000011],
                [-16.778473, 20.928747],
                [-16.779028, 20.928747],
                [-16.779028, 20.929028000000187],
                [-16.779306, 20.929028000000187],
                [-16.779306, 20.929306000000111],
                [-16.779861, 20.929306000000111],
                [-16.779861, 20.929583],
                [-16.780416, 20.929583],
                [-16.780416, 20.929859000000192],
                [-16.780692999999872, 20.929859000000192],
                [-16.780692999999872, 20.930141000000106],
                [-16.78125, 20.930141000000106],
                [-16.78125, 20.930416],
                [-16.781803, 20.930416],
                [-16.781803, 20.930695],
                [-16.782364, 20.930695],
                [-16.782364, 20.93097100000017],
                [-16.782915, 20.93097100000017],
                [-16.782915, 20.93125],
                [-16.783751, 20.93125],
                [-16.783751, 20.931528],
                [-16.785418, 20.931528],
                [-16.785418, 20.93125],
                [-16.786953, 20.93125],
                [-16.787360999999862, 20.93125],
                [-16.787360999999862, 20.93097100000017],
                [-16.788473, 20.93097100000017],
                [-16.788473, 20.930695],
                [-16.789583, 20.930695],
                [-16.789583, 20.930416],
                [-16.790138, 20.930416],
                [-16.790138, 20.930141000000106],
                [-16.790417, 20.930141000000106],
                [-16.790417, 20.929859000000192],
                [-16.790695, 20.929859000000192],
                [-16.790695, 20.929583],
                [-16.790972, 20.929583],
                [-16.790972, 20.925694000000192],
                [-16.790695, 20.925694000000192],
                [-16.790695, 20.925417],
                [-16.790138, 20.925417],
                [-16.790138, 20.924862],
                [-16.789862, 20.924862],
                [-16.789862, 20.924583],
                [-16.789583, 20.924583],
                [-16.789583, 20.924307000000169],
                [-16.789028, 20.924307000000169],
                [-16.789028, 20.924028000000192],
                [-16.78875, 20.924028000000192],
                [-16.78875, 20.92375],
                [-16.788195, 20.92375],
                [-16.788195, 20.92347100000012],
                [-16.786886, 20.92347100000012],
                [-16.786806, 20.92347100000012],
                [-16.786806, 20.923197000000187],
                [-16.786249, 20.923197000000187],
                [-16.786249, 20.922916],
                [-16.784586, 20.922916],
                [-16.784586, 20.92263800000012],
                [-16.784304, 20.92263800000012],
                [-16.784304, 20.922361000000137],
                [-16.786879, 20.922361000000137],
                [-16.789305, 20.922361000000137],
                [-16.789305, 20.922085],
                [-16.790083, 20.922085],
                [-16.79125, 20.922085],
                [-16.79125, 20.922361000000137],
                [-16.792639, 20.922361000000137],
                [-16.792639, 20.92263800000012],
                [-16.79347, 20.922640000000115],
                [-16.79347, 20.922916],
                [-16.793751, 20.922916],
                [-16.793751, 20.923197000000187],
                [-16.794027, 20.923197000000187],
                [-16.794027, 20.92375],
                [-16.794306, 20.92375],
                [-16.794306, 20.924307000000169],
                [-16.794582, 20.924307000000169],
                [-16.794582, 20.924583],
                [-16.794861, 20.924583],
                [-16.794861, 20.924862],
                [-16.795139, 20.924862],
                [-16.795139, 20.925138],
                [-16.795416, 20.925138],
                [-16.795416, 20.925417],
                [-16.795692, 20.925417],
                [-16.795692, 20.925694000000192],
                [-16.796528, 20.925694000000192],
                [-16.796528, 20.925417],
                [-16.797085, 20.925417],
                [-16.797085, 20.925138],
                [-16.797638, 20.925138],
                [-16.797639999999831, 20.924862],
                [-16.798197, 20.924862],
                [-16.798197, 20.924583],
                [-16.79875, 20.924583],
                [-16.79875, 20.924307000000169],
                [-16.800137999999833, 20.924307000000169],
                [-16.800137999999833, 20.925417],
                [-16.800419, 20.925417],
                [-16.800419, 20.925694000000192],
                [-16.800694, 20.925695],
                [-16.800694, 20.925972000000115],
                [-16.800971999999831, 20.925972000000115],
                [-16.800971999999831, 20.92625],
                [-16.803194, 20.92625],
                [-16.803194, 20.926529000000187],
                [-16.803472999999883, 20.926529000000187],
                [-16.803472999999883, 20.926805000000115],
                [-16.804028, 20.926805000000115],
                [-16.804028, 20.927084],
                [-16.804305999999883, 20.927084],
                [-16.804305999999883, 20.927917],
                [-16.804583, 20.927917],
                [-16.804583, 20.928194],
                [-16.804859, 20.928194],
                [-16.804859, 20.928747],
                [-16.805139999999881, 20.928747],
                [-16.805139999999881, 20.929028000000187],
                [-16.805416, 20.929028000000187],
                [-16.805416, 20.929583],
                [-16.805695, 20.929583],
                [-16.805695, 20.930141000000106],
                [-16.805970999999886, 20.930141000000106],
                [-16.805970999999886, 20.930695],
                [-16.80625, 20.930695],
                [-16.80625, 20.93097100000017],
                [-16.806804999999883, 20.93097100000017],
                [-16.806804999999883, 20.931528],
                [-16.807081, 20.931528],
                [-16.807081, 20.932081],
                [-16.807361999999898, 20.932081],
                [-16.807361999999898, 20.932638000000111],
                [-16.807637999999884, 20.932638000000111],
                [-16.807637999999884, 20.933195000000183],
                [-16.807917, 20.933195000000183],
                [-16.807917, 20.93375],
                [-16.808193, 20.93375],
                [-16.808193, 20.93430300000017],
                [-16.808471999999881, 20.93430300000017],
                [-16.808471999999881, 20.93486],
                [-16.80875, 20.93486],
                [-16.80875, 20.935417],
                [-16.809029, 20.935417],
                [-16.809029, 20.935972000000106],
                [-16.809302999999886, 20.935972000000106],
                [-16.809302999999886, 20.936527000000183],
                [-16.809586, 20.936527000000183],
                [-16.809586, 20.937084],
                [-16.80986, 20.937084],
                [-16.80986, 20.938473],
                [-16.810138999999879, 20.938473],
                [-16.810138999999879, 20.939028000000178],
                [-16.810417, 20.939028000000178],
                [-16.810417, 20.939583],
                [-16.810696, 20.939583],
                [-16.810696, 20.940140000000156],
                [-16.810971999999879, 20.940140000000156],
                [-16.810971999999879, 20.940971000000161],
                [-16.811251, 20.940971000000161],
                [-16.811251, 20.941528],
                [-16.811525, 20.941528],
                [-16.811525, 20.942083000000139],
                [-16.811807999999871, 20.942083000000139],
                [-16.811807999999871, 20.943195000000173],
                [-16.812084, 20.943195000000173],
                [-16.812084, 20.943752],
                [-16.812361, 20.943752],
                [-16.812361, 20.944305000000156],
                [-16.812638999999876, 20.944305000000156],
                [-16.812638999999876, 20.944584000000134],
                [-16.812918, 20.944584000000134],
                [-16.812918, 20.94486],
                [-16.813194, 20.94486],
                [-16.813194, 20.945141],
                [-16.813472999999874, 20.945141],
                [-16.813472999999874, 20.945692000000179],
                [-16.813749, 20.945692000000179],
                [-16.813749, 20.945971000000156],
                [-16.814028, 20.945971000000156],
                [-16.814028, 20.946253],
                [-16.814305999999874, 20.946253],
                [-16.814305999999874, 20.946527000000174],
                [-16.814583, 20.946527000000174],
                [-16.814583, 20.947084],
                [-16.814861, 20.947084],
                [-16.814861, 20.947639000000152],
                [-16.815139999999872, 20.947639000000152],
                [-16.815139999999872, 20.947914000000139],
                [-16.815416, 20.947914000000139],
                [-16.815416, 20.948195000000112],
                [-16.815695, 20.948195000000112],
                [-16.815695, 20.948475],
                [-16.815970999999877, 20.948475],
                [-16.815970999999877, 20.94875],
                [-16.81625, 20.94875],
                [-16.81625, 20.949028000000112],
                [-16.816528, 20.949028000000112],
                [-16.816528, 20.949583],
                [-16.816804999999874, 20.949583],
                [-16.816804999999874, 20.949862],
                [-16.817083, 20.949862],
                [-16.817083, 20.950138000000152],
                [-16.817637999999874, 20.950138000000152],
                [-16.817637999999874, 20.95041700000013],
                [-16.818471999999872, 20.95041700000013],
                [-16.818471999999872, 20.951529000000107],
                [-16.818195, 20.951529000000107],
                [-16.818195, 20.952084],
                [-16.817917, 20.952084],
                [-16.817917, 20.952917],
                [-16.817637999999874, 20.952917],
                [-16.817637999999874, 20.953751000000125],
                [-16.817362, 20.953751000000125],
                [-16.817362, 20.957083000000125],
                [-16.817083, 20.957083000000125],
                [-16.817083, 20.959305000000143],
                [-16.816804999999874, 20.959305000000143],
                [-16.816804999999874, 20.96041700000012],
                [-16.816528, 20.96041700000012],
                [-16.816528, 20.961527000000103],
                [-16.81625, 20.961527000000103],
                [-16.81625, 20.964306],
                [-16.815970999999877, 20.964306],
                [-16.815970999999877, 20.966528],
                [-16.815695, 20.966528],
                [-16.815695, 20.969305],
                [-16.815416, 20.969305],
                [-16.815416, 20.970139000000188],
                [-16.815695, 20.970139000000188],
                [-16.815695, 20.974306000000183],
                [-16.815970999999877, 20.974306000000183],
                [-16.815970999999877, 20.974861],
                [-16.81625, 20.974861],
                [-16.81625, 20.97514],
                [-16.816528, 20.97514],
                [-16.816528, 20.976528000000144],
                [-16.816804999999874, 20.976528000000144],
                [-16.816804999999874, 20.977083],
                [-16.817083, 20.977083],
                [-16.817083, 20.977917000000161],
                [-16.817362, 20.977917000000161],
                [-16.817362, 20.978750000000161],
                [-16.817637999999874, 20.978750000000161],
                [-16.817637999999874, 20.979584],
                [-16.817917, 20.979584],
                [-16.817917, 20.980417],
                [-16.818195, 20.980417],
                [-16.818195, 20.98319400000014],
                [-16.818471999999872, 20.98319400000014],
                [-16.818471999999872, 20.984028],
                [-16.81875, 20.984028],
                [-16.81875, 20.984859],
                [-16.819027, 20.984859],
                [-16.819027, 20.985138],
                [-16.819304999999872, 20.985142],
                [-16.819304999999872, 20.985416000000157],
                [-16.819584, 20.985416000000157],
                [-16.819584, 20.985971],
                [-16.81986, 20.985971],
                [-16.81986, 20.986528000000135],
                [-16.82013899999987, 20.986528000000135],
                [-16.82013899999987, 20.98680300000018],
                [-16.820417, 20.98680300000018],
                [-16.820417, 20.987082000000157],
                [-16.82097199999987, 20.987082000000157],
                [-16.82097199999987, 20.987364],
                [-16.821253, 20.987364],
                [-16.821253, 20.987638000000118],
                [-16.821527, 20.987638000000118],
                [-16.821527, 20.987917000000152],
                [-16.821803999999872, 20.987917000000152],
                [-16.821803999999872, 20.98819200000014],
                [-16.822083, 20.988195],
                [-16.822083, 20.988474],
                [-16.822361, 20.988474],
                [-16.822361, 20.988750000000152],
                [-16.822638999999867, 20.988750000000152],
                [-16.822638999999867, 20.989027000000135],
                [-16.822914, 20.989027000000135],
                [-16.822914, 20.989305],
                [-16.823193, 20.989305],
                [-16.823193, 20.989586],
                [-16.823475, 20.989586],
                [-16.823475, 20.989861],
                [-16.82375, 20.989861],
                [-16.82375, 20.990139000000113],
                [-16.824028, 20.990139000000113],
                [-16.824028, 20.990417],
                [-16.824306, 20.990417],
                [-16.824306, 20.990696],
                [-16.824583, 20.990696],
                [-16.824583, 20.990973],
                [-16.824862, 20.990973],
                [-16.824823, 20.991302],
                [-16.825135999999873, 20.991249000000153],
                [-16.825155, 20.99152800000013],
                [-16.825417, 20.99152800000013],
                [-16.825462, 20.991808],
                [-16.825567, 20.991808],
                [-16.825972, 20.991808],
                [-16.825972, 20.992079],
                [-16.825972, 20.99236100000013],
                [-16.826288, 20.992363000000125],
                [-16.82625, 20.992916],
                [-16.826529, 20.992916],
                [-16.826529, 20.993473000000108],
                [-16.826805, 20.993473000000108],
                [-16.826805, 20.993750000000148],
                [-16.827084, 20.993750000000148],
                [-16.827084, 20.994026],
                [-16.82736, 20.994026],
                [-16.827362, 20.994305],
                [-16.827917, 20.994305],
                [-16.827917, 20.994583000000148],
                [-16.828194, 20.994583000000148],
                [-16.828194, 20.994862000000126],
                [-16.828472, 20.994862000000126],
                [-16.828472, 20.995138],
                [-16.828751, 20.995138],
                [-16.828751, 20.995417],
                [-16.829027, 20.995417],
                [-16.829027, 20.995695000000126],
                [-16.829306, 20.995695000000126],
                [-16.829306, 20.995972000000108],
                [-16.829584, 20.995972000000108],
                [-16.829584, 20.996248],
                [-16.830139, 20.996248],
                [-16.830139, 20.99652900000018],
                [-16.830416, 20.99652900000018],
                [-16.830416, 20.996805000000109],
                [-16.832129999999893, 20.996805000000109],
                [-16.832916, 20.996805000000109],
                [-16.832916, 20.99652900000018],
                [-16.833749999999839, 20.99652900000018],
                [-16.833749999999839, 20.996248],
                [-16.834305, 20.996248],
                [-16.834305, 20.995972000000108],
                [-16.836527, 20.995972000000108],
                [-16.836527, 20.996248],
                [-16.836803, 20.996248],
                [-16.836803, 20.99652900000018],
                [-16.837085999999886, 20.99652900000018],
                [-16.837085999999886, 20.997084],
                [-16.83736, 20.997084],
                [-16.83736, 20.997639],
                [-16.837639, 20.997639],
                [-16.837639, 20.998194000000126],
                [-16.837916999999891, 20.998194000000126],
                [-16.837916999999891, 20.99847],
                [-16.838196, 20.99847],
                [-16.838196, 20.998753],
                [-16.838472, 20.998753],
                [-16.838472, 20.999582],
                [-16.839027, 20.999582],
                [-16.839027, 21.000139000000104],
                [-16.839027, 21.001249],
                [-16.839308, 21.001249],
                [-16.839308, 21.003197000000171],
                [-16.839581999999837, 21.003197000000171],
                [-16.839581999999837, 21.005138],
                [-16.839861, 21.005138],
                [-16.839861, 21.005695000000117],
                [-16.839581999999837, 21.005695000000117],
                [-16.839581999999837, 21.006529000000171],
                [-16.839861, 21.006529000000171],
                [-16.839861, 21.007084],
                [-16.839581999999837, 21.007084],
                [-16.839581999999837, 21.007917],
                [-16.839861, 21.007917],
                [-16.839861, 21.009859000000176],
                [-16.840139, 21.009859000000176],
                [-16.840139, 21.010139],
                [-16.839861, 21.010139],
                [-16.839861, 21.010416],
                [-16.840139, 21.010416],
                [-16.840139, 21.010971],
                [-16.840417999999886, 21.010971],
                [-16.840417999999886, 21.012081],
                [-16.840694, 21.012081],
                [-16.840694, 21.012638],
                [-16.840973, 21.012638],
                [-16.840973, 21.012917],
                [-16.841248999999891, 21.012917],
                [-16.841248999999891, 21.013474000000144],
                [-16.841806, 21.013474000000144],
                [-16.841806, 21.01375],
                [-16.842082999999889, 21.01375],
                [-16.842082999999889, 21.014303],
                [-16.842361, 21.014303],
                [-16.842361, 21.014582000000189],
                [-16.84264, 21.014582000000189],
                [-16.84264, 21.014862],
                [-16.842915999999889, 21.014862],
                [-16.842915999999889, 21.015417000000184],
                [-16.843195, 21.015417000000184],
                [-16.843195, 21.015696000000162],
                [-16.843472, 21.015696000000162],
                [-16.843472, 21.015972],
                [-16.843747999999891, 21.015972],
                [-16.843747999999891, 21.016527000000167],
                [-16.844305, 21.016527000000167],
                [-16.844305, 21.016806000000145],
                [-16.844028, 21.016806000000145],
                [-16.844028, 21.017084],
                [-16.844305, 21.017084],
                [-16.844305, 21.017361],
                [-16.844583999999884, 21.017361],
                [-16.844583999999884, 21.017639000000145],
                [-16.84486, 21.017639000000145],
                [-16.84486, 21.017918000000122],
                [-16.845139, 21.017918000000122],
                [-16.845139, 21.018749000000184],
                [-16.845416999999884, 21.018749000000184],
                [-16.845416999999884, 21.019028000000162],
                [-16.845694, 21.019028000000162],
                [-16.845694, 21.019306],
                [-16.84597, 21.019306],
                [-16.84597, 21.019583],
                [-16.846527, 21.019583],
                [-16.846527, 21.019861000000162],
                [-16.846806, 21.019861000000162],
                [-16.846806, 21.02014000000014],
                [-16.847081999999887, 21.02014000000014],
                [-16.847081999999887, 21.020416],
                [-16.847639, 21.020416],
                [-16.847639, 21.020695],
                [-16.848192, 21.020695],
                [-16.848192, 21.021250000000123],
                [-16.849028, 21.021250000000123],
                [-16.849028, 21.021528],
                [-16.849582999999882, 21.021528],
                [-16.849582999999882, 21.021805],
                [-16.85014, 21.021805],
                [-16.85014, 21.022083000000123],
                [-16.850413999999887, 21.022083000000123],
                [-16.850413999999887, 21.022362000000157],
                [-16.850973, 21.022362000000157],
                [-16.850973, 21.022638],
                [-16.851528, 21.022638],
                [-16.851528, 21.022917],
                [-16.851807, 21.022917],
                [-16.851807, 21.023195000000158],
                [-16.852362, 21.023195000000158],
                [-16.852362, 21.023748],
                [-16.852916999999877, 21.023748],
                [-16.852916999999877, 21.02430500000014],
                [-16.853472, 21.02430500000014],
                [-16.853472, 21.024584000000118],
                [-16.854029, 21.024584000000118],
                [-16.854029, 21.025139],
                [-16.85486, 21.025139],
                [-16.85486, 21.025417000000118],
                [-16.856250999999872, 21.025417000000118],
                [-16.856250999999872, 21.025694000000158],
                [-16.857081999999878, 21.025694000000158],
                [-16.857083999999873, 21.02597],
                [-16.857915999999875, 21.02597],
                [-16.857915999999875, 21.026253],
                [-16.859306, 21.026253],
                [-16.859306, 21.026527000000158],
                [-16.860138, 21.026527000000158],
                [-16.86014, 21.026253],
                [-16.860971, 21.026253],
                [-16.860971, 21.026527000000158],
                [-16.861528, 21.026527000000158],
                [-16.861528, 21.026806000000136],
                [-16.862363999999843, 21.026806000000136],
                [-16.862363999999843, 21.027914000000123],
                [-16.862638, 21.027914000000123],
                [-16.862638, 21.029861000000153],
                [-16.862917, 21.029861000000153],
                [-16.862917, 21.031250000000114],
                [-16.863194, 21.031250000000114],
                [-16.863194, 21.031807000000185],
                [-16.863474, 21.031807000000185],
                [-16.863474, 21.032084],
                [-16.863194, 21.032084],
                [-16.863194, 21.032639000000131],
                [-16.863474, 21.032639000000131],
                [-16.863474, 21.033472000000131],
                [-16.86375, 21.033472000000131],
                [-16.86375, 21.033751000000109],
                [-16.863474, 21.033751000000109],
                [-16.863474, 21.034027],
                [-16.86375, 21.034027],
                [-16.86375, 21.034584000000109],
                [-16.864025, 21.034584000000109],
                [-16.864025, 21.034861],
                [-16.864304, 21.034861],
                [-16.864304, 21.035137],
                [-16.864025, 21.035137],
                [-16.864025, 21.035418],
                [-16.864304, 21.035418],
                [-16.864304, 21.035973000000126],
                [-16.864586, 21.035973000000126],
                [-16.864586, 21.036249],
                [-16.864304, 21.036249],
                [-16.864304, 21.036806000000126],
                [-16.864025, 21.036806000000126],
                [-16.864025, 21.037916000000109],
                [-16.86375, 21.037916000000109],
                [-16.86375, 21.038195],
                [-16.863474, 21.038195],
                [-16.863474, 21.039864],
                [-16.863194, 21.039864],
                [-16.863194, 21.040695],
                [-16.862917, 21.040695],
                [-16.862917, 21.041529],
                [-16.863194, 21.041529],
                [-16.863194, 21.041803000000186],
                [-16.862917, 21.041803000000186],
                [-16.862917, 21.042086000000154],
                [-16.862638, 21.042086000000154],
                [-16.862638, 21.041803000000186],
                [-16.862085, 21.041803000000186],
                [-16.862085, 21.04236],
                [-16.862363999999843, 21.04236],
                [-16.862363999999843, 21.042639000000122],
                [-16.862638, 21.042639000000122],
                [-16.862638, 21.043196],
                [-16.863474, 21.043196],
                [-16.863474, 21.043472000000122],
                [-16.86375, 21.043472000000122],
                [-16.86375, 21.0437510000001],
                [-16.864025, 21.0437510000001],
                [-16.864025, 21.044027],
                [-16.86375, 21.044027],
                [-16.86375, 21.045694],
                [-16.863474, 21.045694],
                [-16.863474, 21.045973],
                [-16.86375, 21.045973],
                [-16.86375, 21.046806],
                [-16.864025, 21.046806],
                [-16.864025, 21.047640000000172],
                [-16.86375, 21.047640000000172],
                [-16.86375, 21.0479160000001],
                [-16.864025, 21.0479160000001],
                [-16.864025, 21.048750000000155],
                [-16.864304, 21.048750000000155],
                [-16.864304, 21.049583000000155],
                [-16.864025, 21.049583000000155],
                [-16.864025, 21.05125],
                [-16.864304, 21.05125],
                [-16.864304, 21.051805000000172],
                [-16.864586, 21.051805000000172],
                [-16.864586, 21.052082000000155],
                [-16.864304, 21.052082000000155],
                [-16.864304, 21.05236100000019],
                [-16.864586, 21.05236100000019],
                [-16.864586, 21.053192],
                [-16.864304, 21.053192],
                [-16.864304, 21.053474],
                [-16.864586, 21.053474],
                [-16.864586, 21.054306000000167],
                [-16.864861, 21.054306000000167],
                [-16.864861, 21.054861],
                [-16.865139, 21.054861],
                [-16.865139, 21.05514],
                [-16.865417, 21.05514],
                [-16.865417, 21.05625],
                [-16.865695999999843, 21.05625],
                [-16.865695999999843, 21.057083],
                [-16.865973, 21.057083],
                [-16.865973, 21.057362],
                [-16.866249, 21.057362],
                [-16.866249, 21.057917000000145],
                [-16.866528, 21.057917000000145],
                [-16.866528, 21.058195],
                [-16.866808, 21.058195],
                [-16.866808, 21.059029000000123],
                [-16.867361, 21.059029000000123],
                [-16.867361, 21.060139000000163],
                [-16.86764, 21.060139000000163],
                [-16.86764, 21.060694],
                [-16.867916, 21.060694],
                [-16.867916, 21.060972000000163],
                [-16.868194999999844, 21.060972000000163],
                [-16.868194999999844, 21.061251000000141],
                [-16.86875, 21.061251000000141],
                [-16.86875, 21.061806],
                [-16.869027999999844, 21.061806],
                [-16.869027999999844, 21.062084000000141],
                [-16.87125, 21.062084000000141],
                [-16.87125, 21.062361000000124],
                [-16.872084, 21.062361000000124],
                [-16.872084, 21.062639],
                [-16.872639, 21.062639],
                [-16.872639, 21.062916],
                [-16.872917, 21.062916],
                [-16.872917, 21.063473000000158],
                [-16.874306, 21.063473000000158],
                [-16.874306, 21.064028],
                [-16.874860999999896, 21.064028],
                [-16.874860999999896, 21.064306000000158],
                [-16.875416, 21.064306000000158],
                [-16.875416, 21.064583000000141],
                [-16.875975, 21.064583000000141],
                [-16.875975, 21.064859],
                [-16.877085, 21.064859],
                [-16.877085, 21.064583000000141],
                [-16.877360999999894, 21.064583000000141],
                [-16.877360999999894, 21.064859],
                [-16.877914, 21.064859],
                [-16.877914, 21.065142],
                [-16.878471, 21.065142],
                [-16.878471, 21.065416000000141],
                [-16.87875, 21.065416000000141],
                [-16.87875, 21.069860000000119],
                [-16.879027999999892, 21.069860000000119],
                [-16.879027999999892, 21.070139],
                [-16.879306999999812, 21.070139],
                [-16.879306999999812, 21.073195],
                [-16.879583, 21.073195],
                [-16.879583, 21.074583000000132],
                [-16.879861999999889, 21.074583000000132],
                [-16.879861999999889, 21.07569500000011],
                [-16.880138, 21.07569500000011],
                [-16.880138, 21.07819400000011],
                [-16.880419, 21.07819400000011],
                [-16.880419, 21.082916000000182],
                [-16.880694999999889, 21.082916000000182],
                [-16.880694999999889, 21.083471],
                [-16.880419, 21.083471],
                [-16.880419, 21.085972],
                [-16.880138, 21.085972],
                [-16.880138, 21.086250000000177],
                [-16.880419, 21.086250000000177],
                [-16.880419, 21.086805],
                [-16.880694999999889, 21.086805],
                [-16.880694999999889, 21.08736000000016],
                [-16.880972, 21.08736000000016],
                [-16.880972, 21.087639],
                [-16.88125, 21.087639],
                [-16.88125, 21.089582000000178],
                [-16.880972, 21.089582000000178],
                [-16.880972, 21.090139],
                [-16.88125, 21.090139],
                [-16.88125, 21.090416],
                [-16.880972, 21.090416],
                [-16.880972, 21.092363],
                [-16.880694999999889, 21.092363],
                [-16.880694999999889, 21.092638],
                [-16.880419, 21.092638],
                [-16.880419, 21.093472000000133],
                [-16.880694999999889, 21.093472000000133],
                [-16.880694999999889, 21.093752],
                [-16.880972, 21.093752],
                [-16.880972, 21.094864],
                [-16.88125, 21.094864],
                [-16.88125, 21.097086],
                [-16.881528999999887, 21.097086],
                [-16.881528999999887, 21.097918000000163],
                [-16.881805, 21.097918000000163],
                [-16.881805, 21.100140000000124],
                [-16.882084, 21.100140000000124],
                [-16.882084, 21.101250000000164],
                [-16.882359999999892, 21.101250000000164],
                [-16.882359999999892, 21.102917],
                [-16.882641, 21.102917],
                [-16.882641, 21.103748],
                [-16.882917, 21.103748],
                [-16.882917, 21.104029],
                [-16.882641, 21.104029],
                [-16.882641, 21.105694000000142],
                [-16.882917, 21.105694000000142],
                [-16.882917, 21.106527000000142],
                [-16.882641, 21.106527000000142],
                [-16.882641, 21.108192],
                [-16.882917, 21.108192],
                [-16.882917, 21.109861000000137],
                [-16.883193999999889, 21.109861000000137],
                [-16.883193999999889, 21.11125],
                [-16.883471999999813, 21.11125],
                [-16.883471999999813, 21.112638],
                [-16.883751, 21.112638],
                [-16.883751, 21.11375],
                [-16.88402699999989, 21.11375],
                [-16.88402699999989, 21.115694000000133],
                [-16.884306, 21.115694000000133],
                [-16.884306, 21.118195],
                [-16.884583, 21.118195],
                [-16.884583, 21.119581],
                [-16.884858999999892, 21.119581],
                [-16.884858999999892, 21.120417],
                [-16.885416, 21.120417],
                [-16.885416, 21.120693000000188],
                [-16.885694999999885, 21.120693000000188],
                [-16.885694999999885, 21.12097400000016],
                [-16.885970999999813, 21.12097400000016],
                [-16.885970999999813, 21.122086],
                [-16.88625, 21.122086],
                [-16.88625, 21.122639000000106],
                [-16.886805, 21.122639000000106],
                [-16.886806, 21.122917],
                [-16.887081, 21.122917],
                [-16.887081, 21.123472000000106],
                [-16.887638, 21.123472000000106],
                [-16.887638, 21.123751],
                [-16.887917, 21.123751],
                [-16.887917, 21.124027000000183],
                [-16.88902899999988, 21.124027000000183],
                [-16.88902899999988, 21.123472000000106],
                [-16.889302999999813, 21.123472000000106],
                [-16.889302999999813, 21.123196000000178],
                [-16.889585, 21.123196000000178],
                [-16.889585, 21.123472000000106],
                [-16.889859999999885, 21.123472000000106],
                [-16.88986199999988, 21.124027000000183],
                [-16.890139, 21.124027000000183],
                [-16.890139, 21.124861],
                [-16.890415, 21.124861],
                [-16.890415, 21.125418],
                [-16.890695999999878, 21.125418],
                [-16.890695999999878, 21.125973000000101],
                [-16.890415, 21.125973000000101],
                [-16.890415, 21.126528000000178],
                [-16.890972, 21.126528000000178],
                [-16.890972, 21.126806000000101],
                [-16.891808, 21.126806000000101],
                [-16.891808, 21.127083],
                [-16.892084, 21.127083],
                [-16.892084, 21.127361000000178],
                [-16.89236099999988, 21.127361000000178],
                [-16.89236099999988, 21.127640000000156],
                [-16.892639, 21.127640000000156],
                [-16.892639, 21.128195],
                [-16.892918, 21.128195],
                [-16.892918, 21.129028],
                [-16.89319399999988, 21.129028],
                [-16.89319399999988, 21.129583],
                [-16.893473, 21.129583],
                [-16.893473, 21.130138000000102],
                [-16.893749, 21.130138000000102],
                [-16.893749, 21.130417],
                [-16.894027999999878, 21.130417],
                [-16.894027999999878, 21.130695000000173],
                [-16.893749, 21.130695000000173],
                [-16.893749, 21.13236],
                [-16.894027999999878, 21.13236],
                [-16.894027999999878, 21.133753],
                [-16.894306, 21.133753],
                [-16.894306, 21.135975],
                [-16.894583, 21.135975],
                [-16.894583, 21.136528000000169],
                [-16.894860999999878, 21.136528000000169],
                [-16.894860999999878, 21.136806],
                [-16.89514, 21.136806],
                [-16.89514, 21.137638000000152],
                [-16.894860999999878, 21.137638000000152],
                [-16.894860999999878, 21.138197],
                [-16.89514, 21.138197],
                [-16.89514, 21.138472],
                [-16.895695, 21.138472],
                [-16.895695, 21.13875000000013],
                [-16.897638, 21.13875000000013],
                [-16.897638, 21.139029000000107],
                [-16.898195, 21.139029000000107],
                [-16.898195, 21.139305],
                [-16.89875, 21.139305],
                [-16.89875, 21.139584],
                [-16.899027, 21.139584],
                [-16.899027, 21.139862000000107],
                [-16.899305, 21.139862000000107],
                [-16.899305, 21.140417],
                [-16.900139, 21.140417],
                [-16.900139, 21.140694],
                [-16.900417, 21.140694],
                [-16.900417, 21.140972000000147],
                [-16.900694, 21.140972000000147],
                [-16.900694, 21.141251000000125],
                [-16.901251, 21.141251000000125],
                [-16.901251, 21.141527],
                [-16.901527, 21.141527],
                [-16.901527, 21.141806],
                [-16.901806, 21.141806],
                [-16.901806, 21.142084000000125],
                [-16.902082, 21.142084000000125],
                [-16.902082, 21.142639],
                [-16.902361, 21.142639],
                [-16.902361, 21.143194000000108],
                [-16.902639, 21.143194000000108],
                [-16.902639, 21.144028],
                [-16.902914, 21.144028],
                [-16.902914, 21.144583000000125],
                [-16.903193, 21.144583000000125],
                [-16.903193, 21.145416000000125],
                [-16.903475, 21.145416000000125],
                [-16.903475, 21.145695000000103],
                [-16.903749, 21.145695000000103],
                [-16.903749, 21.145971],
                [-16.904028, 21.145971],
                [-16.904028, 21.14625],
                [-16.904861, 21.14625],
                [-16.904861, 21.146528000000103],
                [-16.905971999999849, 21.146528000000103],
                [-16.905971999999849, 21.146805000000143],
                [-16.90625, 21.146805000000143],
                [-16.90625, 21.147081],
                [-16.906528, 21.147081],
                [-16.906528, 21.147364],
                [-16.907084, 21.147364],
                [-16.907084, 21.147638000000143],
                [-16.907638999999847, 21.147638000000143],
                [-16.907638999999847, 21.14791700000012],
                [-16.908194, 21.14791700000012],
                [-16.908194, 21.148195],
                [-16.908751, 21.148195],
                [-16.908751, 21.148474000000192],
                [-16.909306, 21.148474000000192],
                [-16.909306, 21.148750000000121],
                [-16.909861, 21.148750000000121],
                [-16.909861, 21.149027000000103],
                [-16.910694, 21.149027000000103],
                [-16.910694, 21.149303],
                [-16.910972999999899, 21.149303],
                [-16.910972999999899, 21.150696],
                [-16.910694, 21.150696],
                [-16.910694, 21.151527],
                [-16.910418, 21.151527],
                [-16.910418, 21.152361],
                [-16.910694, 21.152361],
                [-16.910694, 21.152639],
                [-16.910418, 21.152639],
                [-16.910418, 21.153749],
                [-16.910694, 21.153749],
                [-16.910694, 21.155140000000188],
                [-16.910418, 21.155140000000188],
                [-16.910418, 21.155416000000116],
                [-16.910139, 21.155416000000116],
                [-16.910139, 21.155972],
                [-16.909861, 21.155972],
                [-16.909861, 21.156528],
                [-16.909584, 21.156528],
                [-16.909584, 21.157084000000111],
                [-16.910139, 21.157084000000111],
                [-16.910139, 21.15736],
                [-16.910418, 21.15736],
                [-16.910418, 21.157639000000188],
                [-16.910694, 21.157639000000188],
                [-16.910694, 21.159027],
                [-16.910418, 21.159027],
                [-16.910418, 21.161249000000112],
                [-16.910694, 21.161249000000112],
                [-16.910694, 21.162085000000161],
                [-16.910972999999899, 21.162085000000161],
                [-16.910972999999899, 21.162361],
                [-16.911528, 21.162361],
                [-16.911528, 21.161528],
                [-16.911249, 21.161528],
                [-16.911249, 21.159861],
                [-16.911528, 21.159861],
                [-16.911528, 21.159582000000171],
                [-16.911805999999899, 21.159582000000171],
                [-16.911805999999899, 21.159306],
                [-16.912361, 21.159306],
                [-16.912361, 21.158751000000166],
                [-16.912916, 21.158751000000166],
                [-16.912916, 21.159027],
                [-16.913471, 21.159027],
                [-16.913471, 21.159306],
                [-16.91375, 21.159306],
                [-16.91375, 21.160418000000107],
                [-16.914028, 21.160418000000107],
                [-16.914028, 21.160692],
                [-16.914304999999899, 21.160692],
                [-16.914304999999899, 21.160975000000178],
                [-16.914581, 21.160975000000178],
                [-16.914581, 21.161806000000183],
                [-16.915137999999899, 21.161806000000183],
                [-16.915137999999899, 21.162085000000161],
                [-16.915693, 21.162085000000161],
                [-16.915693, 21.162361],
                [-16.915971999999897, 21.162361],
                [-16.915971999999897, 21.16264],
                [-16.915693, 21.16264],
                [-16.915693, 21.163750000000107],
                [-16.915417, 21.163750000000107],
                [-16.915417, 21.164583000000107],
                [-16.915137999999899, 21.164583000000107],
                [-16.915137999999899, 21.164862],
                [-16.914862, 21.164862],
                [-16.914862, 21.165417000000161],
                [-16.914581, 21.165417000000161],
                [-16.914581, 21.166250000000161],
                [-16.915417, 21.166250000000161],
                [-16.915417, 21.165972],
                [-16.915971999999897, 21.165972],
                [-16.915971999999897, 21.165417000000161],
                [-16.91625, 21.165417000000161],
                [-16.91625, 21.164307000000179],
                [-16.916529, 21.164307000000179],
                [-16.916529, 21.163750000000107],
                [-16.91708599999987, 21.163750000000107],
                [-16.91708599999987, 21.163473],
                [-16.91736, 21.163473],
                [-16.91736, 21.163195],
                [-16.917916999999818, 21.163195],
                [-16.917916999999818, 21.162914000000171],
                [-16.919025, 21.162914000000171],
                [-16.919025, 21.16264],
                [-16.919307999999887, 21.16264],
                [-16.919307999999887, 21.162361],
                [-16.920138999999892, 21.162361],
                [-16.920138999999892, 21.162914000000171],
                [-16.92041799999987, 21.162914000000171],
                [-16.92041799999987, 21.164307000000179],
                [-16.920694, 21.164307000000179],
                [-16.920694, 21.165695],
                [-16.92097299999989, 21.165695],
                [-16.92097299999989, 21.165417000000161],
                [-16.92180599999989, 21.165417000000161],
                [-16.92180599999989, 21.164028],
                [-16.922082999999816, 21.164028],
                [-16.922082999999816, 21.163473],
                [-16.922361, 21.163473],
                [-16.922361, 21.162914000000171],
                [-16.922639999999888, 21.162914000000171],
                [-16.922639999999888, 21.162361],
                [-16.922915999999816, 21.162361],
                [-16.922915999999816, 21.162085000000161],
                [-16.923195, 21.162085000000161],
                [-16.923195, 21.161806000000183],
                [-16.923470999999893, 21.161806000000183],
                [-16.923470999999893, 21.161528],
                [-16.924028, 21.161528],
                [-16.924028, 21.161249000000112],
                [-16.92458299999987, 21.161249000000112],
                [-16.92458299999987, 21.161528],
                [-16.924862, 21.161528],
                [-16.924862, 21.162085000000161],
                [-16.925417, 21.162085000000161],
                [-16.925417, 21.162361],
                [-16.926527, 21.162361],
                [-16.926527, 21.162085000000161],
                [-16.927081999999871, 21.162085000000161],
                [-16.927081999999871, 21.161528],
                [-16.929303999999888, 21.161528],
                [-16.929303999999888, 21.161249000000112],
                [-16.929028, 21.161249000000112],
                [-16.929028, 21.160975000000178],
                [-16.928749, 21.160975000000178],
                [-16.928749, 21.160418000000107],
                [-16.929028, 21.160418000000107],
                [-16.929028, 21.160139],
                [-16.929303999999888, 21.160139],
                [-16.929303999999888, 21.15847],
                [-16.929583, 21.15847],
                [-16.929583, 21.158194],
                [-16.929861, 21.158194],
                [-16.929861, 21.157917000000111],
                [-16.93014, 21.157917000000111],
                [-16.93014, 21.15736],
                [-16.930413999999871, 21.15736],
                [-16.930413999999871, 21.157084000000111],
                [-16.930696, 21.157084000000111],
                [-16.930696, 21.155972],
                [-16.930973, 21.155972],
                [-16.930973, 21.155140000000188],
                [-16.93125, 21.155140000000188],
                [-16.93125, 21.154861],
                [-16.931528, 21.154861],
                [-16.931528, 21.154583000000116],
                [-16.931807, 21.154583000000116],
                [-16.931807, 21.154306],
                [-16.932083, 21.154306],
                [-16.932083, 21.154028],
                [-16.932362, 21.154028],
                [-16.932362, 21.153749],
                [-16.932635999999889, 21.153749],
                [-16.932635999999889, 21.15291800000017],
                [-16.932917, 21.15291800000017],
                [-16.932917, 21.152082000000121],
                [-16.933195, 21.152082000000121],
                [-16.933195, 21.151808000000187],
                [-16.934029, 21.151808000000187],
                [-16.934029, 21.150696],
                [-16.934305, 21.150696],
                [-16.934305, 21.148750000000121],
                [-16.934584, 21.148750000000121],
                [-16.934584, 21.148474000000192],
                [-16.93486, 21.148474000000192],
                [-16.93486, 21.147081],
                [-16.935139, 21.147081],
                [-16.935139, 21.145971],
                [-16.935417, 21.145971],
                [-16.935417, 21.141806],
                [-16.935694, 21.141806],
                [-16.935694, 21.141527],
                [-16.935972, 21.141527],
                [-16.935972, 21.141251000000125],
                [-16.936251, 21.141251000000125],
                [-16.936251, 21.140972000000147],
                [-16.936527, 21.140972000000147],
                [-16.936527, 21.140417],
                [-16.936806, 21.140417],
                [-16.936806, 21.138197],
                [-16.936527, 21.138197],
                [-16.936527, 21.137638000000152],
                [-16.936806, 21.137638000000152],
                [-16.936806, 21.137083],
                [-16.937084, 21.137083],
                [-16.937084, 21.136528000000169],
                [-16.936806, 21.136528000000169],
                [-16.936806, 21.13625],
                [-16.936527, 21.13625],
                [-16.936527, 21.134863],
                [-16.936806, 21.134863],
                [-16.936806, 21.134584],
                [-16.937084, 21.134584],
                [-16.937084, 21.134027000000174],
                [-16.937361, 21.134027000000174],
                [-16.937361, 21.133471000000156],
                [-16.937639, 21.133471000000156],
                [-16.937639, 21.13236],
                [-16.937916, 21.13236],
                [-16.937916, 21.131805000000156],
                [-16.938473, 21.131805000000156],
                [-16.938473, 21.131527],
                [-16.938194, 21.131527],
                [-16.938194, 21.13125],
                [-16.939306, 21.13125],
                [-16.939306, 21.130972000000156],
                [-16.939583, 21.130972000000156],
                [-16.939583, 21.130695000000173],
                [-16.939861, 21.130695000000173],
                [-16.939861, 21.130417],
                [-16.94014, 21.130417],
                [-16.94014, 21.130138000000102],
                [-16.940416, 21.130138000000102],
                [-16.940416, 21.129862000000173],
                [-16.940695, 21.129862000000173],
                [-16.940695, 21.129583],
                [-16.940971, 21.129583],
                [-16.940971, 21.129305000000102],
                [-16.941249999999854, 21.129305000000102],
                [-16.941249999999854, 21.129028],
                [-16.941528, 21.129028],
                [-16.941528, 21.12875],
                [-16.941805, 21.12875],
                [-16.941805, 21.127916],
                [-16.942082999999855, 21.127916],
                [-16.942082999999855, 21.127083],
                [-16.942364, 21.127083],
                [-16.942364, 21.126528000000178],
                [-16.942638, 21.126528000000178],
                [-16.942638, 21.125418],
                [-16.942916999999852, 21.125418],
                [-16.942916999999852, 21.124861],
                [-16.943193, 21.124861],
                [-16.943193, 21.124306000000161],
                [-16.943472, 21.124306000000161],
                [-16.943472, 21.123196000000178],
                [-16.943749999999852, 21.123196000000178],
                [-16.943749999999852, 21.120138000000111],
                [-16.943472, 21.120138000000111],
                [-16.943472, 21.117916],
                [-16.944304, 21.117916],
                [-16.944304, 21.117640000000165],
                [-16.944586, 21.117640000000165],
                [-16.944586, 21.11597300000011],
                [-16.94486, 21.11597300000011],
                [-16.94486, 21.114029000000187],
                [-16.945139, 21.114029000000187],
                [-16.945139, 21.111807],
                [-16.94486, 21.111807],
                [-16.94486, 21.11125],
                [-16.944586, 21.11125],
                [-16.944586, 21.110697000000187],
                [-16.944304, 21.110697000000187],
                [-16.944304, 21.11013800000012],
                [-16.944027, 21.11013800000012],
                [-16.944027, 21.109861000000137],
                [-16.943749999999852, 21.109861000000137],
                [-16.943749999999852, 21.109585],
                [-16.943472, 21.109585],
                [-16.943472, 21.109306],
                [-16.942916999999852, 21.109306],
                [-16.942916999999852, 21.109028000000137],
                [-16.942364, 21.109028000000137],
                [-16.942364, 21.108749000000103],
                [-16.941528, 21.108749000000103],
                [-16.941528, 21.108475],
                [-16.942082999999855, 21.108475],
                [-16.942082999999855, 21.108192],
                [-16.942364, 21.108192],
                [-16.942364, 21.107916000000102],
                [-16.942916999999852, 21.107916000000102],
                [-16.942916999999852, 21.10763900000012],
                [-16.943472, 21.10763900000012],
                [-16.943472, 21.107363000000191],
                [-16.943749999999852, 21.107363000000191],
                [-16.943749999999852, 21.107082],
                [-16.944027, 21.107082],
                [-16.944027, 21.10680600000012],
                [-16.944304, 21.10680600000012],
                [-16.944304, 21.106527000000142],
                [-16.94486, 21.106527000000142],
                [-16.94486, 21.106253],
                [-16.945417, 21.106253],
                [-16.945417, 21.10597],
                [-16.945696, 21.10597],
                [-16.945696, 21.105694000000142],
                [-16.946251, 21.105694000000142],
                [-16.946251, 21.105417000000102],
                [-16.946808, 21.105417000000102],
                [-16.946808, 21.105139],
                [-16.947361, 21.105139],
                [-16.947361, 21.10486],
                [-16.947918, 21.10486],
                [-16.947918, 21.104584000000102],
                [-16.948195, 21.104584000000102],
                [-16.948195, 21.104305000000124],
                [-16.94875, 21.104305000000124],
                [-16.94875, 21.104029],
                [-16.949306, 21.104029],
                [-16.949306, 21.103748],
                [-16.950417, 21.103748],
                [-16.950417, 21.103472000000124],
                [-16.951528999999823, 21.103472000000124],
                [-16.951528999999823, 21.103195000000142],
                [-16.952639, 21.103195000000142],
                [-16.952639, 21.102917],
                [-16.953750999999897, 21.102917],
                [-16.953750999999897, 21.102638],
                [-16.954860999999823, 21.102638],
                [-16.954860999999823, 21.102362000000142],
                [-16.955975, 21.102362000000142],
                [-16.955975, 21.102083000000164],
                [-16.957360999999821, 21.102083000000164],
                [-16.957360999999821, 21.101805],
                [-16.958749999999895, 21.101805],
                [-16.958749999999895, 21.101528],
                [-16.959582999999895, 21.101528],
                [-16.959582999999895, 21.101250000000164],
                [-16.959861999999873, 21.101250000000164],
                [-16.959861999999873, 21.100973000000124],
                [-16.960138, 21.100973000000124],
                [-16.960138, 21.100695],
                [-16.960418999999888, 21.100695],
                [-16.960418999999888, 21.100416],
                [-16.960972, 21.100416],
                [-16.960972, 21.100140000000124],
                [-16.961249999999893, 21.100140000000124],
                [-16.961249999999893, 21.099861000000146],
                [-16.961805, 21.099861000000146],
                [-16.961805, 21.099583],
                [-16.962359999999876, 21.099583],
                [-16.962359999999876, 21.099306],
                [-16.962639, 21.099306],
                [-16.962639, 21.099028000000146],
                [-16.963193999999874, 21.099028000000146],
                [-16.963193999999874, 21.098473],
                [-16.963472, 21.098473],
                [-16.963472, 21.098194],
                [-16.963750999999888, 21.098194],
                [-16.963750999999888, 21.097918000000163],
                [-16.964026999999874, 21.097918000000163],
                [-16.964026999999874, 21.097639000000129],
                [-16.964581999999893, 21.097639000000129],
                [-16.964581999999893, 21.096806000000129],
                [-16.964860999999871, 21.096806000000129],
                [-16.964860999999871, 21.096251],
                [-16.965139, 21.096251],
                [-16.965139, 21.094582000000173],
                [-16.965415999999891, 21.094582000000173],
                [-16.965415999999891, 21.093193000000156],
                [-16.965693999999871, 21.093193000000156],
                [-16.965693999999871, 21.092916000000173],
                [-16.965973, 21.092916000000173],
                [-16.965973, 21.091528],
                [-16.966248999999891, 21.091528],
                [-16.966248999999891, 21.090416],
                [-16.966528, 21.090416],
                [-16.966528, 21.089306],
                [-16.966806, 21.089306],
                [-16.966806, 21.088472],
                [-16.966528, 21.088472],
                [-16.966528, 21.088194000000101],
                [-16.966806, 21.088194000000101],
                [-16.966806, 21.087917],
                [-16.967080999999894, 21.087917],
                [-16.967080999999894, 21.08736000000016],
                [-16.967361, 21.08736000000016],
                [-16.967361, 21.086250000000177],
                [-16.967638, 21.086250000000177],
                [-16.967638, 21.085138],
                [-16.967915999999889, 21.085138],
                [-16.967915999999889, 21.080692],
                [-16.968192999999872, 21.080692],
                [-16.968192999999872, 21.07902700000011],
                [-16.968472, 21.07902700000011],
                [-16.968472, 21.077084000000127],
                [-16.96875, 21.077084000000127],
                [-16.96875, 21.07569500000011],
                [-16.968472, 21.07569500000011],
                [-16.968472, 21.07486200000011],
                [-16.968192999999872, 21.07486200000011],
                [-16.968192999999872, 21.074583000000132],
                [-16.968472, 21.074583000000132],
                [-16.968472, 21.073750000000132],
                [-16.96875, 21.073750000000132],
                [-16.96875, 21.072918000000186],
                [-16.969028, 21.072918000000186],
                [-16.969028, 21.072361000000114],
                [-16.969303, 21.072361000000114],
                [-16.969303, 21.071251000000132],
                [-16.969585, 21.071251000000132],
                [-16.969585, 21.070417000000191],
                [-16.969863999999859, 21.070417000000191],
                [-16.969863999999859, 21.069860000000119],
                [-16.970139, 21.069860000000119],
                [-16.970139, 21.069586000000186],
                [-16.970414999999889, 21.069586000000186],
                [-16.970414999999889, 21.068474],
                [-16.970694, 21.068474],
                [-16.970694, 21.067917000000136],
                [-16.970972, 21.067917000000136],
                [-16.970972, 21.067081],
                [-16.971251, 21.067081],
                [-16.971251, 21.065971],
                [-16.971524999999872, 21.065971],
                [-16.971524999999872, 21.064583000000141],
                [-16.971807, 21.064583000000141],
                [-16.971807, 21.062916],
                [-16.972086, 21.062916],
                [-16.972086, 21.062639],
                [-16.972361, 21.062639],
                [-16.972361, 21.061527],
                [-16.972639, 21.061527],
                [-16.972639, 21.059860000000128],
                [-16.972918, 21.059860000000128],
                [-16.972918, 21.058472],
                [-16.973473, 21.058472],
                [-16.973473, 21.057362],
                [-16.973749, 21.057362],
                [-16.973749, 21.056807000000163],
                [-16.97625, 21.056807000000163],
                [-16.97625, 21.056528000000128],
                [-16.977361999999857, 21.056528000000128],
                [-16.977361999999857, 21.05625],
                [-16.978472, 21.05625],
                [-16.978472, 21.055973],
                [-16.979029, 21.055973],
                [-16.979029, 21.055695000000128],
                [-16.979305, 21.055695000000128],
                [-16.979305, 21.055414000000155],
                [-16.979584, 21.055414000000155],
                [-16.979584, 21.055695000000128],
                [-16.97986, 21.055695000000128],
                [-16.97986, 21.055414000000155],
                [-16.980139, 21.055414000000155],
                [-16.980139, 21.05514],
                [-16.980417, 21.05514],
                [-16.980417, 21.054585000000145],
                [-16.980693999999858, 21.054585000000145],
                [-16.980693999999858, 21.054028],
                [-16.981251, 21.054028],
                [-16.981251, 21.053192],
                [-16.981526999999858, 21.053192],
                [-16.981526999999858, 21.052639],
                [-16.981806, 21.052639],
                [-16.981806, 21.05236100000019],
                [-16.982082, 21.05236100000019],
                [-16.982082, 21.050970000000177],
                [-16.982639, 21.050970000000177],
                [-16.982639, 21.049583000000155],
                [-16.982916, 21.049583000000155],
                [-16.982916, 21.049305],
                [-16.98319199999986, 21.049305],
                [-16.98319199999986, 21.0479160000001],
                [-16.983474999999828, 21.0479160000001],
                [-16.983474999999828, 21.046528],
                [-16.984028, 21.046528],
                [-16.984028, 21.044861],
                [-16.984585, 21.044861],
                [-16.984585, 21.044027],
                [-16.984861, 21.044027],
                [-16.984861, 21.043472000000122],
                [-16.985138, 21.043472000000122],
                [-16.98514, 21.042917],
                [-16.985415, 21.042917],
                [-16.985415, 21.042086000000154],
                [-16.985697, 21.042086000000154],
                [-16.985697, 21.041250000000105],
                [-16.985971, 21.041250000000105],
                [-16.985971, 21.040417000000105],
                [-16.986528, 21.040417000000105],
                [-16.986528, 21.039581],
                [-16.986806999999828, 21.039581],
                [-16.986806999999828, 21.039028],
                [-16.987362, 21.039028],
                [-16.987362, 21.03875],
                [-16.987919, 21.03875],
                [-16.987919, 21.038471000000186],
                [-16.989029, 21.038471000000186],
                [-16.989029, 21.038195],
                [-16.989305, 21.038195],
                [-16.989305, 21.038471000000186],
                [-16.989861, 21.038471000000186],
                [-16.989861, 21.038195],
                [-16.991528, 21.038195],
                [-16.991528, 21.037916000000109],
                [-16.992359, 21.037916000000109],
                [-16.992359, 21.037642000000176],
                [-16.992639999999881, 21.037642000000176],
                [-16.992639999999881, 21.037916000000109],
                [-16.992916, 21.037916000000109],
                [-16.992916, 21.037642000000176],
                [-16.993470999999829, 21.037642000000176],
                [-16.993470999999829, 21.037359],
                [-16.994028, 21.037359],
                [-16.994028, 21.037083000000109],
                [-16.994861999999898, 21.037083000000109],
                [-16.994861999999898, 21.036806000000126],
                [-16.995137999999827, 21.036806000000126],
                [-16.995137999999827, 21.036528],
                [-16.995417, 21.036528],
                [-16.995417, 21.036249],
                [-16.995971999999881, 21.036249],
                [-16.995971999999881, 21.035973000000126],
                [-16.99625, 21.035973000000126],
                [-16.99625, 21.035694],
                [-16.996528999999896, 21.035694],
                [-16.996528999999896, 21.034861],
                [-16.997086, 21.034861],
                [-16.997086, 21.034306000000186],
                [-16.99736, 21.034306000000186],
                [-16.99736, 21.033472000000131],
                [-16.997638999999879, 21.033472000000131],
                [-16.997638999999879, 21.033194],
                [-16.997915, 21.033194],
                [-16.997915, 21.032919],
                [-16.997638999999879, 21.032919],
                [-16.997638999999879, 21.032362],
                [-16.997915, 21.032362],
                [-16.997915, 21.031807000000185],
                [-16.998471999999879, 21.031807000000185],
                [-16.998471999999879, 21.030972],
                [-16.998751, 21.030972],
                [-16.998751, 21.030697],
                [-16.998471999999879, 21.030697],
                [-16.998471999999879, 21.030138000000136],
                [-16.998751, 21.030138000000136],
                [-16.998751, 21.029585],
                [-16.999025, 21.029585],
                [-16.999025, 21.029028000000153],
                [-16.999307999999871, 21.029028000000153],
                [-16.999307999999871, 21.027914000000123],
                [-16.999025, 21.027914000000123],
                [-16.999025, 21.027363],
                [-16.999307999999871, 21.027363],
                [-16.999307999999871, 21.027084],
                [-16.999584, 21.027084],
                [-16.999584, 21.025417000000118],
                [-17.000069, 21.025417000000118],
                [-17.00153, 21.025417000000118],
                [-17.00153, 21.025694000000158],
                [-17.002083, 21.025694000000158],
                [-17.002083, 21.02597],
                [-17.002639999999872, 21.02597],
                [-17.002639999999872, 21.026253],
                [-17.002916, 21.026253],
                [-17.002916, 21.027914000000123],
                [-17.003195, 21.027914000000123],
                [-17.003195, 21.034861],
                [-17.003470999999877, 21.034861],
                [-17.003470999999877, 21.035973000000126],
                [-17.003195, 21.035973000000126],
                [-17.003195, 21.037916000000109],
                [-17.003470999999877, 21.037916000000109],
                [-17.003470999999877, 21.038471000000186],
                [-17.00375, 21.038471000000186],
                [-17.00375, 21.039028],
                [-17.004028, 21.039028],
                [-17.004028, 21.039305000000127],
                [-17.004862, 21.039305000000127],
                [-17.004862, 21.039581],
                [-17.005417, 21.039581],
                [-17.005417, 21.039864],
                [-17.005971999999872, 21.039864],
                [-17.005971999999872, 21.040138000000127],
                [-17.00625, 21.040138000000127],
                [-17.00625, 21.040417000000105],
                [-17.006804999999872, 21.040417000000105],
                [-17.006804999999872, 21.040695],
                [-17.00736, 21.040695],
                [-17.00736, 21.040974000000176],
                [-17.007917, 21.040974000000176],
                [-17.007917, 21.041250000000105],
                [-17.00847199999987, 21.041250000000105],
                [-17.00847199999987, 21.041529],
                [-17.009027, 21.041529],
                [-17.009027, 21.041803000000186],
                [-17.009303999999872, 21.041803000000186],
                [-17.009303999999872, 21.042086000000154],
                [-17.009861, 21.042086000000154],
                [-17.009861, 21.04236],
                [-17.010414, 21.04236],
                [-17.010414, 21.042639000000122],
                [-17.010975, 21.042639000000122],
                [-17.010975, 21.043196],
                [-17.011526, 21.043196],
                [-17.011526, 21.043472000000122],
                [-17.012083, 21.043472000000122],
                [-17.012083, 21.0437510000001],
                [-17.012635999999873, 21.0437510000001],
                [-17.012635999999873, 21.044027],
                [-17.012915, 21.044027],
                [-17.012915, 21.044306000000176],
                [-17.013471999999865, 21.044306000000176],
                [-17.013471999999865, 21.0445840000001],
                [-17.014029, 21.0445840000001],
                [-17.014029, 21.044861],
                [-17.014584, 21.044861],
                [-17.014584, 21.045139000000177],
                [-17.015138999999863, 21.045139000000177],
                [-17.015138999999863, 21.045418000000154],
                [-17.015694, 21.045418000000154],
                [-17.015694, 21.045694],
                [-17.015971999999863, 21.045694],
                [-17.015971999999863, 21.045973],
                [-17.016527, 21.045973],
                [-17.016527, 21.046249],
                [-17.017084, 21.046249],
                [-17.017084, 21.046528],
                [-17.019861, 21.046528],
                [-17.019861, 21.046249],
                [-17.020416, 21.046249],
                [-17.020416, 21.045973],
                [-17.02125, 21.045973],
                [-17.02125, 21.045694],
                [-17.021805, 21.045694],
                [-17.021805, 21.045418000000154],
                [-17.022638, 21.045418000000154],
                [-17.022638, 21.045137000000182],
                [-17.023193, 21.045139000000177],
                [-17.023193, 21.044861],
                [-17.023472, 21.044861],
                [-17.023472, 21.0445840000001],
                [-17.02375, 21.0445840000001],
                [-17.02375, 21.044306000000176],
                [-17.024303, 21.044306000000176],
                [-17.024303, 21.044027],
                [-17.024585999999886, 21.044027],
                [-17.024585999999886, 21.0437510000001],
                [-17.025139, 21.0437510000001],
                [-17.025139, 21.043472000000122],
                [-17.025696, 21.043472000000122],
                [-17.025696, 21.043196],
                [-17.026250999999831, 21.043196],
                [-17.026250999999831, 21.04236],
                [-17.025972, 21.04236],
                [-17.025972, 21.041529],
                [-17.026250999999831, 21.041529],
                [-17.026250999999831, 21.040974000000176],
                [-17.026525, 21.040974000000176],
                [-17.026525, 21.040138000000127],
                [-17.025696, 21.040138000000127],
                [-17.025696, 21.039864],
                [-17.025139, 21.039864],
                [-17.025139, 21.039305000000127],
                [-17.025416999999834, 21.039305000000127],
                [-17.025416999999834, 21.03875],
                [-17.025696, 21.03875],
                [-17.025696, 21.038471000000186],
                [-17.025972, 21.038471000000186],
                [-17.025972, 21.038195],
                [-17.027082, 21.038195],
                [-17.027082, 21.037642000000176],
                [-17.026808, 21.037642000000176],
                [-17.026808, 21.036528],
                [-17.026525, 21.036528],
                [-17.026525, 21.030972],
                [-17.026250999999831, 21.030972],
                [-17.026250999999831, 21.030416],
                [-17.025972, 21.030416],
                [-17.025972, 21.030138000000136],
                [-17.025696, 21.030138000000136],
                [-17.025696, 21.029585],
                [-17.025416999999834, 21.029585],
                [-17.025416999999834, 21.029028000000153],
                [-17.025139, 21.029028000000153],
                [-17.025139, 21.028749000000118],
                [-17.02486, 21.028749000000118],
                [-17.02486, 21.028193000000158],
                [-17.024585999999886, 21.028193000000158],
                [-17.024585999999886, 21.027914000000123],
                [-17.024303, 21.027914000000123],
                [-17.024303, 21.027363],
                [-17.024027, 21.027363],
                [-17.024027, 21.026806000000136],
                [-17.02375, 21.026806000000136],
                [-17.02375, 21.026527000000158],
                [-17.023472, 21.026527000000158],
                [-17.023472, 21.02597],
                [-17.023193, 21.02597],
                [-17.023193, 21.025417000000118],
                [-17.022917, 21.025417000000118],
                [-17.022917, 21.025139],
                [-17.022638, 21.025139],
                [-17.022638, 21.024584000000118],
                [-17.022362, 21.024584000000118],
                [-17.022362, 21.024027],
                [-17.022081, 21.024027],
                [-17.022081, 21.02347200000014],
                [-17.022362, 21.02347200000014],
                [-17.022362, 21.022917],
                [-17.022638, 21.022917],
                [-17.022638, 21.022362000000157],
                [-17.021528, 21.022362000000157],
                [-17.021528, 21.022083000000123],
                [-17.020971, 21.022083000000123],
                [-17.020971, 21.021805],
                [-17.020416, 21.021805],
                [-17.020416, 21.021528],
                [-17.019583, 21.021528],
                [-17.019583, 21.021805],
                [-17.019028, 21.021805],
                [-17.019028, 21.022083000000123],
                [-17.018749, 21.022083000000123],
                [-17.018749, 21.022362000000157],
                [-17.016799999999876, 21.022358000000168],
                [-17.015694, 21.022352000000183],
                [-17.015694, 21.022638],
                [-17.014862, 21.022638],
                [-17.01487, 21.022362000000157],
                [-17.014029, 21.022362000000157],
                [-17.014029, 21.022205],
                [-17.014029, 21.022083000000123],
                [-17.013191, 21.022133],
                [-17.013197, 21.021805],
                [-17.012635999999873, 21.021805],
                [-17.012635999999873, 21.021528],
                [-17.013471999999865, 21.021528],
                [-17.013471999999865, 21.021250000000123],
                [-17.014862, 21.021250000000123],
                [-17.014862, 21.020971000000145],
                [-17.015138999999863, 21.020971000000145],
                [-17.015138999999863, 21.020695],
                [-17.015694, 21.020695],
                [-17.015694, 21.020416],
                [-17.016806, 21.020416],
                [-17.016806, 21.019861000000162],
                [-17.016527, 21.019861000000162],
                [-17.016527, 21.019306],
                [-17.016806, 21.019306],
                [-17.016806, 21.019028000000162],
                [-17.017084, 21.019028000000162],
                [-17.017084, 21.018749000000184],
                [-17.017361, 21.018749000000184],
                [-17.017361, 21.018194],
                [-17.017639, 21.018194],
                [-17.017639, 21.017918000000122],
                [-17.017916, 21.017918000000122],
                [-17.017918, 21.017639000000145],
                [-17.019306, 21.017639000000145],
                [-17.019306, 21.017361],
                [-17.019583, 21.017361],
                [-17.019583, 21.016806000000145],
                [-17.019861, 21.016806000000145],
                [-17.019861, 21.016527000000167],
                [-17.02014, 21.016527000000167],
                [-17.02014, 21.016251],
                [-17.020416, 21.016251],
                [-17.020416, 21.015696000000162],
                [-17.020695, 21.015696000000162],
                [-17.020695, 21.015417000000184],
                [-17.020971, 21.015417000000184],
                [-17.020971, 21.014582000000189],
                [-17.02125, 21.014582000000189],
                [-17.02125, 21.01375],
                [-17.020971, 21.01375],
                [-17.020971, 21.013474000000144],
                [-17.020695, 21.013474000000144],
                [-17.020695, 21.012638],
                [-17.020416, 21.012638],
                [-17.020416, 21.012081],
                [-17.02014, 21.012081],
                [-17.02014, 21.011528],
                [-17.019861, 21.011528],
                [-17.019861, 21.011250000000189],
                [-17.019583, 21.011250000000189],
                [-17.019583, 21.011806000000149],
                [-17.019861, 21.011806000000149],
                [-17.019861, 21.012363],
                [-17.02014, 21.012363],
                [-17.02014, 21.014582000000189],
                [-17.019861, 21.014582000000189],
                [-17.019861, 21.015139],
                [-17.019583, 21.015139],
                [-17.019583, 21.015417000000184],
                [-17.019306, 21.015417000000184],
                [-17.019306, 21.015696000000162],
                [-17.019028, 21.015696000000162],
                [-17.019028, 21.015972],
                [-17.018749, 21.015972],
                [-17.018749, 21.016251],
                [-17.018473, 21.016251],
                [-17.018473, 21.016527000000167],
                [-17.018194, 21.016527000000167],
                [-17.018194, 21.016806000000145],
                [-17.017639, 21.016806000000145],
                [-17.017639, 21.017084],
                [-17.015971999999863, 21.017084],
                [-17.015971999999863, 21.017361],
                [-17.015138999999863, 21.017361],
                [-17.015138999999863, 21.017639000000145],
                [-17.014584, 21.017639000000145],
                [-17.014584, 21.017918000000122],
                [-17.014029, 21.017918000000122],
                [-17.014029, 21.018194],
                [-17.012915, 21.018194],
                [-17.012915, 21.018473],
                [-17.012083, 21.018473],
                [-17.012083, 21.018749000000184],
                [-17.010696, 21.018749000000184],
                [-17.010696, 21.019028000000162],
                [-17.006527, 21.019028000000162],
                [-17.006527, 21.019306],
                [-17.005971999999872, 21.019306],
                [-17.005971999999872, 21.019583],
                [-17.00469, 21.019583],
                [-17.004304999999874, 21.019583],
                [-17.004304999999874, 21.019306],
                [-17.004028, 21.019306],
                [-17.004028, 21.019028000000162],
                [-17.003470999999877, 21.019028000000162],
                [-17.003470999999877, 21.018749000000184],
                [-17.003195, 21.018749000000184],
                [-17.003195, 21.018194],
                [-17.002916, 21.018194],
                [-17.002976999999873, 21.015944],
                [-17.003195, 21.015972],
                [-17.003195, 21.015417000000184],
                [-17.003470999999877, 21.015417000000184],
                [-17.003470999999877, 21.01375],
                [-17.003195, 21.01375],
                [-17.003195, 21.013193000000172],
                [-17.002916, 21.013193000000172],
                [-17.002916, 21.012638],
                [-17.002639999999872, 21.012638],
                [-17.002639999999872, 21.012081],
                [-17.002360999999894, 21.012081],
                [-17.002360999999894, 21.011528],
                [-17.002029, 21.011517],
                [-17.002083, 21.010139],
                [-17.002360999999894, 21.010139],
                [-17.002360999999894, 21.009954],
                [-17.002360999999894, 21.009859000000176],
                [-17.002639999999872, 21.009859000000176],
                [-17.002639999999872, 21.008633],
                [-17.002639999999872, 21.008194000000117],
                [-17.002727999999877, 21.008194000000117],
                [-17.002916, 21.008194000000117],
                [-17.002916, 21.007318000000112],
                [-17.002916, 21.00625],
                [-17.003195, 21.00625],
                [-17.003195, 21.005972],
                [-17.00375, 21.005972],
                [-17.00375, 21.005695000000117],
                [-17.004304999999874, 21.005695000000117],
                [-17.004304999999874, 21.005417],
                [-17.004862, 21.005417],
                [-17.004862, 21.005138],
                [-17.005417, 21.005138],
                [-17.005417, 21.004583],
                [-17.005695, 21.004583],
                [-17.005695, 21.002916],
                [-17.005971999999872, 21.002916],
                [-17.005971999999872, 21.002361000000121],
                [-17.00625, 21.002361000000121],
                [-17.00625, 21.001528000000121],
                [-17.006527, 21.001528000000121],
                [-17.006527, 21.001249],
                [-17.007084, 21.001249],
                [-17.007084, 21.000139000000104],
                [-17.007084, 20.999863000000175],
                [-17.007084, 20.997639],
                [-17.006804999999872, 20.997639],
                [-17.006804999999872, 20.997084],
                [-17.006527, 20.997084],
                [-17.006527, 20.994862000000126],
                [-17.006804999999872, 20.994862000000126],
                [-17.006804999999872, 20.994583000000148],
                [-17.007084, 20.994583000000148],
                [-17.007084, 20.993750000000148],
                [-17.00736, 20.993750000000148],
                [-17.00736, 20.990696],
                [-17.007084, 20.990696],
                [-17.007084, 20.989861],
                [-17.006804999999872, 20.989861],
                [-17.006804999999872, 20.988873000000183],
                [-17.006804999999872, 20.987917000000152],
                [-17.007084, 20.987917000000152],
                [-17.007084, 20.987082000000157],
                [-17.007252, 20.987082000000157],
                [-17.007917, 20.987082000000157],
                [-17.007917, 20.98680300000018],
                [-17.008191999999895, 20.98680300000018],
                [-17.008191999999895, 20.986528000000135],
                [-17.00847199999987, 20.986528000000135],
                [-17.00847199999987, 20.984859],
                [-17.008751, 20.984859],
                [-17.008751, 20.984306000000117],
                [-17.009027, 20.984306000000117],
                [-17.009027, 20.983473000000117],
                [-17.009303999999872, 20.983473000000117],
                [-17.009303999999872, 20.981527],
                [-17.009583, 20.981527],
                [-17.009583, 20.980972000000179],
                [-17.009861, 20.980972000000179],
                [-17.009861, 20.977083],
                [-17.009583, 20.977083],
                [-17.009583, 20.97625],
                [-17.009303999999872, 20.97625],
                [-17.009303999999872, 20.975695000000144],
                [-17.009027, 20.975695000000144],
                [-17.009027, 20.974861],
                [-17.008751, 20.974861],
                [-17.008751, 20.974028],
                [-17.00847199999987, 20.974028],
                [-17.00847199999987, 20.973475000000178],
                [-17.008191999999895, 20.973475000000178],
                [-17.008191999999895, 20.972361],
                [-17.007917, 20.972361],
                [-17.007917, 20.972082000000171],
                [-17.00763899999987, 20.972082000000171],
                [-17.00763899999987, 20.971806],
                [-17.00736, 20.971806],
                [-17.00736, 20.971527],
                [-17.007084, 20.971527],
                [-17.007084, 20.971252],
                [-17.006804999999872, 20.971252],
                [-17.006804999999872, 20.97097],
                [-17.006527, 20.97097],
                [-17.006527, 20.970417000000111],
                [-17.00625, 20.970417000000111],
                [-17.00625, 20.970139000000188],
                [-17.005971999999872, 20.970139000000188],
                [-17.005971999999872, 20.96986],
                [-17.005695, 20.96986],
                [-17.005695, 20.969584000000111],
                [-17.005417, 20.969584000000111],
                [-17.005417, 20.968748000000176],
                [-17.005137999999874, 20.968748000000176],
                [-17.005137999999874, 20.968472],
                [-17.004862, 20.968472],
                [-17.004862, 20.968195],
                [-17.005137999999874, 20.968195],
                [-17.005137999999874, 20.967916000000116],
                [-17.005695, 20.967916000000116],
                [-17.005695, 20.967640000000188],
                [-17.005971999999872, 20.967640000000188],
                [-17.005971999999872, 20.967361],
                [-17.006804999999872, 20.967361],
                [-17.006804999999872, 20.967083000000116],
                [-17.007084, 20.967083000000116],
                [-17.007084, 20.964027],
                [-17.008191999999895, 20.964027],
                [-17.008191999999895, 20.963751000000116],
                [-17.009027, 20.963751000000116],
                [-17.009027, 20.963472],
                [-17.009303999999872, 20.963472],
                [-17.009303999999872, 20.963196],
                [-17.009583, 20.963196],
                [-17.009583, 20.962639],
                [-17.009303999999872, 20.962639],
                [-17.009303999999872, 20.960974000000192],
                [-17.009583, 20.960974000000192],
                [-17.009583, 20.960138000000143],
                [-17.009303999999872, 20.960138000000143],
                [-17.009303999999872, 20.956528],
                [-17.010414, 20.956528],
                [-17.010414, 20.956249],
                [-17.010696, 20.956249],
                [-17.010696, 20.955418],
                [-17.010975, 20.955416],
                [-17.010975, 20.954306],
                [-17.01125, 20.954306],
                [-17.01125, 20.954027],
                [-17.011526, 20.954027],
                [-17.011526, 20.953751000000125],
                [-17.011805999999865, 20.953751000000125],
                [-17.011805999999865, 20.953472000000147],
                [-17.012083, 20.953472000000147],
                [-17.012083, 20.953194],
                [-17.012635999999873, 20.953194],
                [-17.012635999999873, 20.952917],
                [-17.012915, 20.952917],
                [-17.012915, 20.952362000000107],
                [-17.013471999999865, 20.952362000000107],
                [-17.013471999999865, 20.95125000000013],
                [-17.01375, 20.95125000000013],
                [-17.01375, 20.950697],
                [-17.014029, 20.950697],
                [-17.014029, 20.949028000000112],
                [-17.01375, 20.949028000000112],
                [-17.01375, 20.947639000000152],
                [-17.013471999999865, 20.947639000000152],
                [-17.013471999999865, 20.945971000000156],
                [-17.013197, 20.945971000000156],
                [-17.013197, 20.943752],
                [-17.012915, 20.943752],
                [-17.012915, 20.941250000000139],
                [-17.012635999999873, 20.941250000000139],
                [-17.012635999999873, 20.939028000000178],
                [-17.012915, 20.939028000000178],
                [-17.012915, 20.938749],
                [-17.013197, 20.938749],
                [-17.013197, 20.935696000000178],
                [-17.012915, 20.935696000000178],
                [-17.012915, 20.93097100000017],
                [-17.013197, 20.93097100000017],
                [-17.013197, 20.929583],
                [-17.013471999999865, 20.929583],
                [-17.013471999999865, 20.929306000000111],
                [-17.01375, 20.929306000000111],
                [-17.01375, 20.928194],
                [-17.014029, 20.928194],
                [-17.014029, 20.92625],
                [-17.014304999999865, 20.92625],
                [-17.014304999999865, 20.925417],
                [-17.014584, 20.925417],
                [-17.014584, 20.925138],
                [-17.014862, 20.925138],
                [-17.014862, 20.924307000000169],
                [-17.015138999999863, 20.924307000000169],
                [-17.015138999999863, 20.924028000000192],
                [-17.015694, 20.924028000000192],
                [-17.015694, 20.92375],
                [-17.015971999999863, 20.92375],
                [-17.015971999999863, 20.92347100000012],
                [-17.016251, 20.92347100000012],
                [-17.016251, 20.923197000000187],
                [-17.016527, 20.923197000000187],
                [-17.016527, 20.922916],
                [-17.016806, 20.922916],
                [-17.016806, 20.921249000000103],
                [-17.016527, 20.921249000000103],
                [-17.016527, 20.919027000000142],
                [-17.016251, 20.919027000000142],
                [-17.016251, 20.917917000000102],
                [-17.016527, 20.917917000000102],
                [-17.016527, 20.916805000000124],
                [-17.016806, 20.916805000000124],
                [-17.016806, 20.915695000000142],
                [-17.017084, 20.915695000000142],
                [-17.017084, 20.914862000000142],
                [-17.017639, 20.914862000000142],
                [-17.017639, 20.914583000000107],
                [-17.017916, 20.914583000000107],
                [-17.017918, 20.914305],
                [-17.018194, 20.914305],
                [-17.018194, 20.914028],
                [-17.018473, 20.914028],
                [-17.018473, 20.913750000000107],
                [-17.018749, 20.913750000000107],
                [-17.018749, 20.913473000000124],
                [-17.019028, 20.913473000000124],
                [-17.019028, 20.913195],
                [-17.019306, 20.913195],
                [-17.019306, 20.912958000000174],
                [-17.019581, 20.912901],
                [-17.019583, 20.912640000000124],
                [-17.019861, 20.912640000000124],
                [-17.019861, 20.912361000000146],
                [-17.02014, 20.912361000000146],
                [-17.02014, 20.912083],
                [-17.020416, 20.912083],
                [-17.020416, 20.911806],
                [-17.020695, 20.911806],
                [-17.020695, 20.911528000000146],
                [-17.020971, 20.911528000000146],
                [-17.020971, 20.910694],
                [-17.02125, 20.910694],
                [-17.02125, 20.910418000000107],
                [-17.022081, 20.910418000000107],
                [-17.022081, 20.910139000000129],
                [-17.024303, 20.910139000000129],
                [-17.024303, 20.910973],
                [-17.024585999999886, 20.910973],
                [-17.024585999999886, 20.911249000000169],
                [-17.024027, 20.911249000000169],
                [-17.024027, 20.911959],
                [-17.024027, 20.912083],
                [-17.024585999999886, 20.912083],
                [-17.024585999999886, 20.91184],
                [-17.024857, 20.911783],
                [-17.02486, 20.912083],
                [-17.026250999999831, 20.912083],
                [-17.026250999999831, 20.911806],
                [-17.027082, 20.911806],
                [-17.027082, 20.911528000000146],
                [-17.028748999999834, 20.911528000000146],
                [-17.028748999999834, 20.911249000000169],
                [-17.03014, 20.911249000000169],
                [-17.03014, 20.910973],
                [-17.030972, 20.910973],
                [-17.030972, 20.910694],
                [-17.033750999999882, 20.910694],
                [-17.033750999999882, 20.910418000000107],
                [-17.034581999999887, 20.910418000000107],
                [-17.034581999999887, 20.910139000000129],
                [-17.035417999999879, 20.910139000000129],
                [-17.035417999999879, 20.909861],
                [-17.035972999999899, 20.909861],
                [-17.035972999999899, 20.909586],
                [-17.037361, 20.909586],
                [-17.037361, 20.909306000000129],
                [-17.03764, 20.909306000000129],
                [-17.03764, 20.90907900000019],
                [-17.038181, 20.908964],
                [-17.038197, 20.908751],
                [-17.038473, 20.908751],
                [-17.038473, 20.908472],
                [-17.039028, 20.908472],
                [-17.039028, 20.908195],
                [-17.039307, 20.908195],
                [-17.039307, 20.907917000000168],
                [-17.03958299999988, 20.907917000000168],
                [-17.03958299999988, 20.907639],
                [-17.040136, 20.907639],
                [-17.040136, 20.907364],
                [-17.040972, 20.907364],
                [-17.040972, 20.907082000000173],
                [-17.042083999999875, 20.907082000000173],
                [-17.042083999999875, 20.906528000000151],
                [-17.04236, 20.906528000000151],
                [-17.04236, 20.906252],
                [-17.043194, 20.906252],
                [-17.043194, 20.905973],
                [-17.044306, 20.905973],
                [-17.044306, 20.906528000000151],
                [-17.044558, 20.906500000000165],
                [-17.044583999999873, 20.907639],
                [-17.044861, 20.907639],
                [-17.044861, 20.908195],
                [-17.045139, 20.908195],
                [-17.045139, 20.908751],
                [-17.041529, 20.908751],
                [-17.041529, 20.909027000000151],
                [-17.040972, 20.909027000000151],
                [-17.040972, 20.909306000000129],
                [-17.040418999999872, 20.909306000000129],
                [-17.040418999999872, 20.909586],
                [-17.040136, 20.909586],
                [-17.040136, 20.910139000000129],
                [-17.039862, 20.910139000000129],
                [-17.039862, 20.911249000000169],
                [-17.039307, 20.911249000000169],
                [-17.039307, 20.911528000000146],
                [-17.038749999999879, 20.911528000000146],
                [-17.038749999999879, 20.911806],
                [-17.038197, 20.911806],
                [-17.038197, 20.912083],
                [-17.037361, 20.912083],
                [-17.037361, 20.912916],
                [-17.03764, 20.912916],
                [-17.03764, 20.913473000000124],
                [-17.039028, 20.913473000000124],
                [-17.039028, 20.912916],
                [-17.038749999999879, 20.912916],
                [-17.038749999999879, 20.912640000000124],
                [-17.039307, 20.912640000000124],
                [-17.039307, 20.912361000000146],
                [-17.03958299999988, 20.912361000000146],
                [-17.03958299999988, 20.912083],
                [-17.041249999999877, 20.912083],
                [-17.041249999999877, 20.911806],
                [-17.042083999999875, 20.911806],
                [-17.042083999999875, 20.911528000000146],
                [-17.042639, 20.911528000000146],
                [-17.042639, 20.911249000000169],
                [-17.043194, 20.911249000000169],
                [-17.043194, 20.910973],
                [-17.043472, 20.910973],
                [-17.043472, 20.910694],
                [-17.044027, 20.910694],
                [-17.044027, 20.910418000000107],
                [-17.044306, 20.910418000000107],
                [-17.044306, 20.910139000000129],
                [-17.044861, 20.910139000000129],
                [-17.044861, 20.909861],
                [-17.045973, 20.909861],
                [-17.045973, 20.909586],
                [-17.046248999999875, 20.909586],
                [-17.046248999999875, 20.909306000000129],
                [-17.046528, 20.909306000000129],
                [-17.046528, 20.908751],
                [-17.046806, 20.908751],
                [-17.046806, 20.908472],
                [-17.047082999999873, 20.908472],
                [-17.047082999999873, 20.908195],
                [-17.047361, 20.908195],
                [-17.047361, 20.907917000000168],
                [-17.047638, 20.907917000000168],
                [-17.04764, 20.907639],
                [-17.047915999999873, 20.907639],
                [-17.047915999999873, 20.907364],
                [-17.048195, 20.907364],
                [-17.048195, 20.907082000000173],
                [-17.048471, 20.907082000000173],
                [-17.048471, 20.906803],
                [-17.04875, 20.906803],
                [-17.04875, 20.906528000000151],
                [-17.049028, 20.906528000000151],
                [-17.049028, 20.906252],
                [-17.049303, 20.906252],
                [-17.049303, 20.905973],
                [-17.04958299999987, 20.905973],
                [-17.04958299999987, 20.905695000000151],
                [-17.049864, 20.905695000000151],
                [-17.049864, 20.905138],
                [-17.050138, 20.905138],
                [-17.050138, 20.904860000000156],
                [-17.050416999999868, 20.904860000000156],
                [-17.050416999999868, 20.904306000000133],
                [-17.050694, 20.904306000000133],
                [-17.050694, 20.902639],
                [-17.050416999999868, 20.902639],
                [-17.050416999999868, 20.900694000000101],
                [-17.050694, 20.900694000000101],
                [-17.050694, 20.900972],
                [-17.051525, 20.900972],
                [-17.051525, 20.899584],
                [-17.051249999999868, 20.899584],
                [-17.051249999999868, 20.89902700000016],
                [-17.050972, 20.89902700000016],
                [-17.050972, 20.898195000000101],
                [-17.050694, 20.898195000000101],
                [-17.050694, 20.897638],
                [-17.050416999999868, 20.897638],
                [-17.050416999999868, 20.897083],
                [-17.050694, 20.897083],
                [-17.050694, 20.896807],
                [-17.051249999999868, 20.896807],
                [-17.051249999999868, 20.89652800000016],
                [-17.05514, 20.89652800000016],
                [-17.05514, 20.89625],
                [-17.055416, 20.89625],
                [-17.055416, 20.895971],
                [-17.055695, 20.895971],
                [-17.055695, 20.89514],
                [-17.055973, 20.89514],
                [-17.055973, 20.894861000000105],
                [-17.05625, 20.894861000000105],
                [-17.05625, 20.894306],
                [-17.056528, 20.894306],
                [-17.056528, 20.893475],
                [-17.056805, 20.893475],
                [-17.056805, 20.892639],
                [-17.056528, 20.892639],
                [-17.056528, 20.89069400000011],
                [-17.056805, 20.89069400000011],
                [-17.056805, 20.890417],
                [-17.057083, 20.890417],
                [-17.057083, 20.890139],
                [-17.057362, 20.890139],
                [-17.057362, 20.88986],
                [-17.057638, 20.88986],
                [-17.057638, 20.889584],
                [-17.057917, 20.889584],
                [-17.057917, 20.887083000000132],
                [-17.057638, 20.887083000000132],
                [-17.057638, 20.886806],
                [-17.057362, 20.886805],
                [-17.057362, 20.886250000000132],
                [-17.057083, 20.886250000000132],
                [-17.057083, 20.885973],
                [-17.056805, 20.885973],
                [-17.056805, 20.885695],
                [-17.056528, 20.885695],
                [-17.056528, 20.885418000000186],
                [-17.05625, 20.885418000000186],
                [-17.05625, 20.885139],
                [-17.056528, 20.885139],
                [-17.056528, 20.884861000000114],
                [-17.056805, 20.884861000000114],
                [-17.056805, 20.883196],
                [-17.056528, 20.883196],
                [-17.056528, 20.882917000000191],
                [-17.055695, 20.882917000000191],
                [-17.055695, 20.882360000000119],
                [-17.055973, 20.882360000000119],
                [-17.055973, 20.882086000000186],
                [-17.05625, 20.882086000000186],
                [-17.05625, 20.881250000000136],
                [-17.055973, 20.881250000000136],
                [-17.055973, 20.880974],
                [-17.054861, 20.880974],
                [-17.054861, 20.880695],
                [-17.054583, 20.880695],
                [-17.054583, 20.880138000000102],
                [-17.054308, 20.880138000000102],
                [-17.054308, 20.879864],
                [-17.054583, 20.879864],
                [-17.054583, 20.87875200000019],
                [-17.05514, 20.87875200000019],
                [-17.05514, 20.879028000000119],
                [-17.055695, 20.879028000000119],
                [-17.055695, 20.879305000000102],
                [-17.05625, 20.879305000000102],
                [-17.05625, 20.878473],
                [-17.055973, 20.878473],
                [-17.055973, 20.878195000000119],
                [-17.055695, 20.878195000000119],
                [-17.055695, 20.876528],
                [-17.054861, 20.876528],
                [-17.054861, 20.876249],
                [-17.054583, 20.876249],
                [-17.054583, 20.875973000000101],
                [-17.054308, 20.875973000000101],
                [-17.054308, 20.875416],
                [-17.054028, 20.875416],
                [-17.054028, 20.874306],
                [-17.053746999999873, 20.874306],
                [-17.053746999999873, 20.873194],
                [-17.053473, 20.873194],
                [-17.053473, 20.872917],
                [-17.052917, 20.872917],
                [-17.052917, 20.873194],
                [-17.052639, 20.873194],
                [-17.052639, 20.873472000000163],
                [-17.052361, 20.873472000000163],
                [-17.052361, 20.872917],
                [-17.052086, 20.872917],
                [-17.052086, 20.872084],
                [-17.051807, 20.872084],
                [-17.051807, 20.871805],
                [-17.051525, 20.871805],
                [-17.051525, 20.871250000000146],
                [-17.051249999999868, 20.871250000000146],
                [-17.051249999999868, 20.870417000000145],
                [-17.050694, 20.870417000000145],
                [-17.050694, 20.870138000000168],
                [-17.050138, 20.870138000000168],
                [-17.050138, 20.869862],
                [-17.049864, 20.869862],
                [-17.049864, 20.869583],
                [-17.049303, 20.869583],
                [-17.049303, 20.869305000000168],
                [-17.049028, 20.869305000000168],
                [-17.049028, 20.869028000000128],
                [-17.04875, 20.869028000000128],
                [-17.04875, 20.86875],
                [-17.048195, 20.86875],
                [-17.048195, 20.868473],
                [-17.047915999999873, 20.868473],
                [-17.047915999999873, 20.868195000000128],
                [-17.04764, 20.868195000000128],
                [-17.047638, 20.86791600000015],
                [-17.047361, 20.86791600000015],
                [-17.047361, 20.86764],
                [-17.047082999999873, 20.86764],
                [-17.047082999999873, 20.867361],
                [-17.046806, 20.867361],
                [-17.046806, 20.867085000000145],
                [-17.046528, 20.867085000000145],
                [-17.046528, 20.866806000000167],
                [-17.046248999999875, 20.866806000000167],
                [-17.046248999999875, 20.866528],
                [-17.045694, 20.866528],
                [-17.045694, 20.866249],
                [-17.045139, 20.866249],
                [-17.045139, 20.865971000000172],
                [-17.044861, 20.865971000000172],
                [-17.044861, 20.865692],
                [-17.044583999999873, 20.865692],
                [-17.044583999999873, 20.86541700000015],
                [-17.044027, 20.86541700000015],
                [-17.044027, 20.865141],
                [-17.043750999999872, 20.865141],
                [-17.043750999999872, 20.864862],
                [-17.042916999999875, 20.864862],
                [-17.042916999999875, 20.864582000000155],
                [-17.04236, 20.864582000000155],
                [-17.04236, 20.864305000000172],
                [-17.041805, 20.864305000000172],
                [-17.041805, 20.864027],
                [-17.040972, 20.864027],
                [-17.040972, 20.863752],
                [-17.040418999999872, 20.863752],
                [-17.040418999999872, 20.863470000000177],
                [-17.03958299999988, 20.863470000000177],
                [-17.03958299999988, 20.863194],
                [-17.039028, 20.863194],
                [-17.039028, 20.862917],
                [-17.038473, 20.862917],
                [-17.038473, 20.862638],
                [-17.038197, 20.862638],
                [-17.038197, 20.862362000000132],
                [-17.03764, 20.862362000000132],
                [-17.03764, 20.862083000000155],
                [-17.037082999999882, 20.862083000000155],
                [-17.037082999999882, 20.861805],
                [-17.036804, 20.861805],
                [-17.036804, 20.861528],
                [-17.036248999999884, 20.861528],
                [-17.036248999999884, 20.861250000000155],
                [-17.035692, 20.861250000000155],
                [-17.035692, 20.860971000000177],
                [-17.035417999999879, 20.860971000000177],
                [-17.035417999999879, 20.860695],
                [-17.034861, 20.860695],
                [-17.034861, 20.8604160000001],
                [-17.034306, 20.8604160000001],
                [-17.034306, 20.860138000000177],
                [-17.033750999999882, 20.860138000000177],
                [-17.033750999999882, 20.859861],
                [-17.033194, 20.859861],
                [-17.033194, 20.8595830000001],
                [-17.032916999999884, 20.8595830000001],
                [-17.032916999999884, 20.859306],
                [-17.03236, 20.859306],
                [-17.03236, 20.859028],
                [-17.032083999999884, 20.859028],
                [-17.032083999999884, 20.858749000000159],
                [-17.031805, 20.858749000000159],
                [-17.031805, 20.858473],
                [-17.031528, 20.858473],
                [-17.031528, 20.857918000000154],
                [-17.031251999999881, 20.857918000000154],
                [-17.031251999999881, 20.857639000000177],
                [-17.030972, 20.857639000000177],
                [-17.030972, 20.857361],
                [-17.030695, 20.857361],
                [-17.030695, 20.8570840000001],
                [-17.030415999999832, 20.857082000000105],
                [-17.030415999999832, 20.856806000000176],
                [-17.03014, 20.856806000000176],
                [-17.03014, 20.856527],
                [-17.029582999999832, 20.856527],
                [-17.029582999999832, 20.855972],
                [-17.029306, 20.855972],
                [-17.029306, 20.855417000000159],
                [-17.02903, 20.855417000000159],
                [-17.02903, 20.855139],
                [-17.028748999999834, 20.855139],
                [-17.028748999999834, 20.85486],
                [-17.028473, 20.85486],
                [-17.028473, 20.854305000000181],
                [-17.028194, 20.854305000000181],
                [-17.028194, 20.853750000000105],
                [-17.027917999999886, 20.853750000000105],
                [-17.027917999999886, 20.853474000000176],
                [-17.028194, 20.853474000000176],
                [-17.028194, 20.853195],
                [-17.028473, 20.853195],
                [-17.028473, 20.852638000000127],
                [-17.029306, 20.852638000000127],
                [-17.029306, 20.852364],
                [-17.029582999999832, 20.852364],
                [-17.029582999999832, 20.851805000000127],
                [-17.029861, 20.851805000000127],
                [-17.029861, 20.851252000000159],
                [-17.03014, 20.851252000000159],
                [-17.03014, 20.850971000000186],
                [-17.030695, 20.850971000000186],
                [-17.030695, 20.850695],
                [-17.031251999999881, 20.850695],
                [-17.031251999999881, 20.850416000000109],
                [-17.031805, 20.850416000000109],
                [-17.031805, 20.850142000000176],
                [-17.032083999999884, 20.850142000000176],
                [-17.032083999999884, 20.849859],
                [-17.032639, 20.849859],
                [-17.032639, 20.849583000000109],
                [-17.03347, 20.849583000000109],
                [-17.03347, 20.849306000000126],
                [-17.033750999999882, 20.849306000000126],
                [-17.033750999999882, 20.848749],
                [-17.034027, 20.848749],
                [-17.034027, 20.847361],
                [-17.034306, 20.847361],
                [-17.034306, 20.845694],
                [-17.034027, 20.845694],
                [-17.034027, 20.845139000000131],
                [-17.033750999999882, 20.845139000000131],
                [-17.033750999999882, 20.844584],
                [-17.03347, 20.844584],
                [-17.03347, 20.844028],
                [-17.033194, 20.844028],
                [-17.033194, 20.84347200000019],
                [-17.032916999999884, 20.84347200000019],
                [-17.032916999999884, 20.842638000000136],
                [-17.033194, 20.842638000000136],
                [-17.033194, 20.840139000000136],
                [-17.03347, 20.840139000000136],
                [-17.03347, 20.839581000000123],
                [-17.033194, 20.839581000000123],
                [-17.033194, 20.839027000000158],
                [-17.03347, 20.839027000000158],
                [-17.03347, 20.838194000000158],
                [-17.033750999999882, 20.838194000000158],
                [-17.033750999999882, 20.837917000000118],
                [-17.034027, 20.837917000000118],
                [-17.034027, 20.83764100000019],
                [-17.034306, 20.83764100000019],
                [-17.034306, 20.83680500000014],
                [-17.034581999999887, 20.83680500000014],
                [-17.034581999999887, 20.835695000000158],
                [-17.034861, 20.835695000000158],
                [-17.034861, 20.834862000000157],
                [-17.035139, 20.834862000000157],
                [-17.035139, 20.834583000000123],
                [-17.034861, 20.834583000000123],
                [-17.034861, 20.834305],
                [-17.034581999999887, 20.834305],
                [-17.034581999999887, 20.831806],
                [-17.034027, 20.831806],
                [-17.034027, 20.831528000000162],
                [-17.03347, 20.831528000000162],
                [-17.03347, 20.831249000000128],
                [-17.033194, 20.831249000000128],
                [-17.033194, 20.830973],
                [-17.032862, 20.830973],
                [-17.032639, 20.830973],
                [-17.032639, 20.830694],
                [-17.03236, 20.830694],
                [-17.03236, 20.830139000000145],
                [-17.031805, 20.830139000000145],
                [-17.031805, 20.830416000000127],
                [-17.031528, 20.830416000000127],
                [-17.031528, 20.830139000000145],
                [-17.030972, 20.830139000000145],
                [-17.030972, 20.829861],
                [-17.030695, 20.829861],
                [-17.030695, 20.829584],
                [-17.030415999999832, 20.829584],
                [-17.030415999999832, 20.829027000000167],
                [-17.03014, 20.829027000000167],
                [-17.03014, 20.828472],
                [-17.030415999999832, 20.828472],
                [-17.030415999999832, 20.827917000000127],
                [-17.030695, 20.827917000000127],
                [-17.030695, 20.827639],
                [-17.030972, 20.827639],
                [-17.030972, 20.827362],
                [-17.031251999999881, 20.827362],
                [-17.031251999999881, 20.827639],
                [-17.031528, 20.827639],
                [-17.031528, 20.827917000000127],
                [-17.031251999999881, 20.827917000000127],
                [-17.031251999999881, 20.828472],
                [-17.030972, 20.828472],
                [-17.030972, 20.829027000000167],
                [-17.031251999999881, 20.829027000000167],
                [-17.031251999999881, 20.829306000000145],
                [-17.031528, 20.829306000000145],
                [-17.031528, 20.829584],
                [-17.032083999999884, 20.829584],
                [-17.032083999999884, 20.829861],
                [-17.032639, 20.829861],
                [-17.032639, 20.830139000000145],
                [-17.033186, 20.830133],
                [-17.033194, 20.830416000000127],
                [-17.03347, 20.830418000000122],
                [-17.03347, 20.830694],
                [-17.034027, 20.830694],
                [-17.034027, 20.830973],
                [-17.034581999999887, 20.830973],
                [-17.034581999999887, 20.831249000000128],
                [-17.035139, 20.831249000000128],
                [-17.035139, 20.830694],
                [-17.035417999999879, 20.830694],
                [-17.035417999999879, 20.830139000000145],
                [-17.035692, 20.830139000000145],
                [-17.035692, 20.829584],
                [-17.036248999999884, 20.829584],
                [-17.036248999999884, 20.829306000000145],
                [-17.036528, 20.829306000000145],
                [-17.036528, 20.829027000000167],
                [-17.037082999999882, 20.829027000000167],
                [-17.037082999999882, 20.828751],
                [-17.037361, 20.828751],
                [-17.037361, 20.828472],
                [-17.03764, 20.828472],
                [-17.03764, 20.826803000000154],
                [-17.037913999999887, 20.826803000000154],
                [-17.037913999999887, 20.826529],
                [-17.038197, 20.826529],
                [-17.038197, 20.825138],
                [-17.038473, 20.825138],
                [-17.038473, 20.82403],
                [-17.038749999999879, 20.82403],
                [-17.038749999999879, 20.823750000000189],
                [-17.039028, 20.823750000000189],
                [-17.039028, 20.823471000000154],
                [-17.038749999999879, 20.823471000000154],
                [-17.038749999999879, 20.823195],
                [-17.039028, 20.823195],
                [-17.039028, 20.822083],
                [-17.038749999999879, 20.822083],
                [-17.038749999999879, 20.820972000000154],
                [-17.038473, 20.820972000000154],
                [-17.038473, 20.820139000000154],
                [-17.038197, 20.820139000000154],
                [-17.038197, 20.819584],
                [-17.037913999999887, 20.819584],
                [-17.037913999999887, 20.819305],
                [-17.038197, 20.819305],
                [-17.038197, 20.81875],
                [-17.037913999999887, 20.81875],
                [-17.037913999999887, 20.817917],
                [-17.03764, 20.817917],
                [-17.03764, 20.817638],
                [-17.037913999999887, 20.817638],
                [-17.037913999999887, 20.816807000000153],
                [-17.038749999999879, 20.816807000000153],
                [-17.038749999999879, 20.816528000000176],
                [-17.039307, 20.816528000000176],
                [-17.039307, 20.815971000000104],
                [-17.03958299999988, 20.815971000000104],
                [-17.03958299999988, 20.815695000000176],
                [-17.039862, 20.815695000000176],
                [-17.039862, 20.81514],
                [-17.040136, 20.81514],
                [-17.040136, 20.814306],
                [-17.040418999999872, 20.814306],
                [-17.040418999999872, 20.813749],
                [-17.040695, 20.813749],
                [-17.040695, 20.813475000000153],
                [-17.040972, 20.813475000000153],
                [-17.040972, 20.812916],
                [-17.041249999999877, 20.812916],
                [-17.041249999999877, 20.812639000000104],
                [-17.041529, 20.812639000000104],
                [-17.041529, 20.812084],
                [-17.041805, 20.812084],
                [-17.041805, 20.811253],
                [-17.041529, 20.811253],
                [-17.041529, 20.810694000000126],
                [-17.041805, 20.810694000000126],
                [-17.041805, 20.809305000000109],
                [-17.042083999999875, 20.809305000000109],
                [-17.042083999999875, 20.807638],
                [-17.04236, 20.807638],
                [-17.04236, 20.807083],
                [-17.042639, 20.807083],
                [-17.042639, 20.805416],
                [-17.042916999999875, 20.805416],
                [-17.042916999999875, 20.804583],
                [-17.043194, 20.804583],
                [-17.043194, 20.80402800000013],
                [-17.043472, 20.80402800000013],
                [-17.043472, 20.803473],
                [-17.043750999999872, 20.803473],
                [-17.043750999999872, 20.803196000000185],
                [-17.044027, 20.803196000000185],
                [-17.044027, 20.802361],
                [-17.044306, 20.802361],
                [-17.044306, 20.801527000000135],
                [-17.044583999999873, 20.801527000000135],
                [-17.044583999999873, 20.800695],
                [-17.044861, 20.80069200000014],
                [-17.044861, 20.799864000000184],
                [-17.045139, 20.799864000000184],
                [-17.045139, 20.799303000000123],
                [-17.044861, 20.799303000000123],
                [-17.044861, 20.798752],
                [-17.045139, 20.798752],
                [-17.045139, 20.793751000000157],
                [-17.045415999999875, 20.793751000000157],
                [-17.045415999999875, 20.793472000000122],
                [-17.045694, 20.793472000000122],
                [-17.045694, 20.792084],
                [-17.045415999999875, 20.792084],
                [-17.045415999999875, 20.790695],
                [-17.045169999999871, 20.790695],
                [-17.045147, 20.789051],
                [-17.045139, 20.788485000000151],
                [-17.045415999999875, 20.788473],
                [-17.045415999999875, 20.787361],
                [-17.045694, 20.787361],
                [-17.045694, 20.787085000000161],
                [-17.045973, 20.787085000000161],
                [-17.045973, 20.786528],
                [-17.046248999999875, 20.786528],
                [-17.046248999999875, 20.785692],
                [-17.046528, 20.785692],
                [-17.046528, 20.784584000000166],
                [-17.046248999999875, 20.784584000000166],
                [-17.046248999999875, 20.78236],
                [-17.045973, 20.78236],
                [-17.045973, 20.781805],
                [-17.045694, 20.781805],
                [-17.045694, 20.780136],
                [-17.045973, 20.780136],
                [-17.045973, 20.779306],
                [-17.045694, 20.779306],
                [-17.045694, 20.778749000000175],
                [-17.045415999999875, 20.778749000000175],
                [-17.045415999999875, 20.77791800000017],
                [-17.045139, 20.77791800000017],
                [-17.045139, 20.777361],
                [-17.045415999999875, 20.777361],
                [-17.045415999999875, 20.777082000000121],
                [-17.045694, 20.777082000000121],
                [-17.045694, 20.773750000000121],
                [-17.045415999999875, 20.773750000000121],
                [-17.045415999999875, 20.773474000000192],
                [-17.045139, 20.773474000000192],
                [-17.045139, 20.77291700000012],
                [-17.044861, 20.77291700000012],
                [-17.044861, 20.772638],
                [-17.045139, 20.772638],
                [-17.045139, 20.772364],
                [-17.045415999999875, 20.772364],
                [-17.045415999999875, 20.772081],
                [-17.045694, 20.772081],
                [-17.045694, 20.771805],
                [-17.045986, 20.771763000000192],
                [-17.05056, 20.773161],
                [-17.051849, 20.774160000000109],
                [-17.057631, 20.799891000000116],
                [-17.066521, 20.83983000000012],
                [-17.066369999999893, 20.923],
                [-17.059933, 20.943937],
                [-17.0488, 20.980162000000121],
                [-17.039828999999884, 21.040258000000108],
                [-17.027901, 21.065830000000119],
                [-17.0261, 21.069677],
                [-17.009331, 21.09272],
                [-17.00309899999985, 21.104589],
                [-16.987301, 21.16951],
                [-16.98308, 21.183317000000102],
                [-16.97736, 21.20203000000015],
                [-16.97023, 21.220739],
                [-16.950899, 21.28783],
                [-16.939710999999875, 21.326639000000171],
                [-16.919257999999843, 21.330351000000178],
                [-16.8482, 21.33053],
                [-16.828541, 21.33057],
                [-16.713409, 21.33213000000012],
                [-16.578609, 21.33386],
                [-16.443821, 21.335480000000189],
                [-16.309016999999869, 21.337009],
                [-16.17421, 21.338428000000192],
                [-16.03941, 21.339760000000126],
                [-15.904599, 21.340981],
                [-15.877051, 21.341209000000106],
                [-15.769790999999884, 21.341740000000129],
                [-15.634979999999871, 21.342312],
                [-15.50016, 21.342777000000183],
                [-15.365349, 21.343140000000176],
                [-15.230528999999876, 21.343397],
                [-15.09572, 21.343571000000111],
                [-14.999998999999889, 21.343611],
                [-14.9609, 21.34363],
                [-14.82825, 21.34359000000012],
                [-14.826089, 21.343552000000159],
                [-14.73672, 21.34196100000014],
                [-14.722801, 21.34325],
                [-14.691361, 21.342680000000144],
                [-14.65171, 21.341948000000116],
                [-14.644318999999882, 21.343342000000177],
                [-14.596489, 21.343599000000154],
                [-14.581809, 21.341789000000119],
                [-14.556850999999881, 21.342730000000188],
                [-14.547859, 21.343069000000185],
                [-14.422051, 21.342409],
                [-14.30117035, 21.341701507000096],
                [-14.294202231999918, 21.341660516000161],
                [-14.287229, 21.341619],
                [-14.2326, 21.341272000000174],
                [-14.222649, 21.342369000000133],
                [-14.152488999999889, 21.34201000000013],
                [-14.01768, 21.34123],
                [-13.947169775999896, 21.34077548700003],
                [-13.882868765999945, 21.340360642000064],
                [-13.805354507999937, 21.339796930000148],
                [-13.74806, 21.339380000000119],
                [-13.693642939999961, 21.338945225000089],
                [-13.613261221999949, 21.338302613000053],
                [-13.516072139999949, 21.337448702000188],
                [-13.47845, 21.337118],
                [-13.429619999999886, 21.336660000000109],
                [-13.373888015999967, 21.335987734000071],
                [-13.343651, 21.335623000000112],
                [-13.20886, 21.333910000000117],
                [-13.20159, 21.333813],
                [-13.150141, 21.331673000000137],
                [-13.142748999999867, 21.333010000000115],
                [-13.130465127999912, 21.332958694000126],
                [-13.10923, 21.33287],
                [-13.074241, 21.332197000000178],
                [-13.052119084999902, 21.331773979000047],
                [-13.001759999999877, 21.330811],
                [-13.00785, 21.38888],
                [-13.014329, 21.450548],
                [-13.016150315999937, 21.46467415300009],
                [-13.022559, 21.51438],
                [-13.022869, 21.516823],
                [-13.023518764999892, 21.518360083000118],
                [-13.02476978299984, 21.521320344000173],
                [-13.024518966999949, 21.541999818000022],
                [-13.029959678999887, 21.581430435000186],
                [-13.031520842999953, 21.592750551000165],
                [-13.033147883999902, 21.609862825000107],
                [-13.035981178999862, 21.639661789000058],
                [-13.04197120699996, 21.702581405000103],
                [-13.042133097999965, 21.702950836000184],
                [-13.045028685999966, 21.709558487000095],
                [-13.044388770999888, 21.729839325000171],
                [-13.045639820999838, 21.737199720000149],
                [-13.04680919599997, 21.744079590000069],
                [-13.047789164999926, 21.755249893000155],
                [-13.048629760999972, 21.76483154400006],
                [-13.050081673999898, 21.781390286000033],
                [-13.052981375999934, 21.814460755000084],
                [-13.055470466999907, 21.820140839000032],
                [-13.055125136999948, 21.831209424000065],
                [-13.05485, 21.840040000000101],
                [-13.055785219999962, 21.84806860700013],
                [-13.056461333999948, 21.85387039200009],
                [-13.059139251999966, 21.860069275000058],
                [-13.058815898999967, 21.865300196000078],
                [-13.058288999999888, 21.873831000000166],
                [-13.059310942999957, 21.884611784000072],
                [-13.059800147999965, 21.88977050900013],
                [-13.063520431999848, 21.929119110000045],
                [-13.072420120999823, 22.015430450000167],
                [-13.074075038999979, 22.031481892000102],
                [-13.08018875099998, 22.090780258000166],
                [-13.084038733999876, 22.115007401000014],
                [-13.085093097999959, 22.127370601000166],
                [-13.086250304999965, 22.140939713000023],
                [-13.092080114999874, 22.209409714000174],
                [-13.098479269999928, 22.266601562000062],
                [-13.102661626999918, 22.30400543200011],
                [-13.106209754999952, 22.335737229000017],
                [-13.109362057999931, 22.367098064000061],
                [-13.11188125599989, 22.392160415999967],
                [-13.124500273999956, 22.517770767000172],
                [-13.126144568999848, 22.534101827000143],
                [-13.128508566999869, 22.55758094700019],
                [-13.13408805499995, 22.627108195000176],
                [-13.13414096899993, 22.627767563000191],
                [-13.134568876999936, 22.630056289000038],
                [-13.137049, 22.643320000000131],
                [-13.138013690999912, 22.648458457000118],
                [-13.138029097999947, 22.64854049700017],
                [-13.14574909199996, 22.731409073000179],
                [-13.144698142999914, 22.752050399999973],
                [-13.141551017999973, 22.768789292000122],
                [-13.13920450199987, 22.781277756000122],
                [-13.138258933999964, 22.786310195000056],
                [-13.136729010999943, 22.792324055000051],
                [-13.11143016699998, 22.891769409000119],
                [-13.085240364999891, 22.994520187000148],
                [-13.081526706999966, 23.003532164000148],
                [-13.077328681999973, 23.013719559000094],
                [-13.06746557799994, 23.037647842000069],
                [-13.058870315999854, 23.05850029000004],
                [-13.050113952999936, 23.07483457699999],
                [-13.045619010999872, 23.083219528000029],
                [-13.018618582999977, 23.126960755000141],
                [-13.018522488999963, 23.127086673000065],
                [-13.018480300999897, 23.127141954000138],
                [-12.957139014999939, 23.213079453000148],
                [-12.941780089999838, 23.23135948200013],
                [-12.898758888999964, 23.282480240000041],
                [-12.895700150999915, 23.284125224999968],
                [-12.89201, 23.286110000000178],
                [-12.85288, 23.32641000000018],
                [-12.85043, 23.328932000000123],
                [-12.836420330999886, 23.340285646000041],
                [-12.824890136999954, 23.34963035700008],
                [-12.816293630999951, 23.35548325799999],
                [-12.807089805999908, 23.361749650000149],
                [-12.790599157999907, 23.370972386000119],
                [-12.775601387999927, 23.379360200000121],
                [-12.775025918999916, 23.378794400000174],
                [-12.773841857999912, 23.377630234000151],
                [-12.769619533999901, 23.381810952000023],
                [-12.767841338999858, 23.383571625000116],
                [-12.765761591999819, 23.384604566000064],
                [-12.759830473999955, 23.387550354000098],
                [-12.748087965999957, 23.389849840000124],
                [-12.740730285999859, 23.39129066400011],
                [-12.740400312999896, 23.391349792000028],
                [-12.663701059999823, 23.398002416000168],
                [-12.604721069999869, 23.403118133000021],
                [-12.468689918999871, 23.414810181000064],
                [-12.46428871199987, 23.415180206],
                [-12.37042140899996, 23.421499252000103],
                [-12.353375419999963, 23.423332376000076],
                [-12.332590102999973, 23.425567628000067],
                [-12.309671401999935, 23.42803955100004],
                [-12.271844765999901, 23.429664106000075],
                [-12.258198737999976, 23.430250167000167],
                [-12.201681136999923, 23.434589386000141],
                [-12.198408683999901, 23.434992385000157],
                [-12.196399999999869, 23.435240000000135],
                [-12.124461, 23.444111000000134],
                [-12.076141, 23.44434],
                [-12.068580352999902, 23.445000110000137],
                [-12.060461043999965, 23.445709229000101],
                [-12.037878990999957, 23.44768142700002],
                [-12.000000000999933, 23.454519272000141],
                [-12.000000000999933, 23.500000001000103],
                [-12.000000000999933, 23.600000382000189],
                [-12.000000000999933, 23.700000763000048],
                [-12.000000000999933, 23.799999238000112],
                [-12.000000000999933, 23.899999619000027],
                [-12.000000000999933, 23.999998093000045],
                [-12.000498771999901, 24.003049851000128],
                [-12.003557056999909, 24.094622331000153],
                [-12.003911018999815, 24.105220795999969],
                [-12.003781317999938, 24.124349595000069],
                [-12.003324884999813, 24.19070296100017],
                [-12.002989999999897, 24.23942],
                [-12.003045002999841, 24.243415551000112],
                [-12.003141402999972, 24.250389098000085],
                [-12.004806019999876, 24.369705306000071],
                [-12.004899977999912, 24.376440048000063],
                [-12.004929541999957, 24.378570557000046],
                [-12.004229055999929, 24.426827522000053],
                [-12.003865241999847, 24.451890946000049],
                [-12.003129004999948, 24.502510070000028],
                [-12.002981185999886, 24.513280869000084],
                [-12.004360197999858, 24.628599166000129],
                [-12.005870818999881, 24.75469017000006],
                [-12.006260871999928, 24.786569596000049],
                [-12.00596904799994, 24.880809784],
                [-12.005613326999878, 24.999149322000051],
                [-12.00561141899982, 25.0],
                [-12.005591392999918, 25.006940842000063],
                [-12.005201339999928, 25.133089066000082],
                [-12.004819869999949, 25.259239197],
                [-12.004430770999932, 25.385410309000179],
                [-12.004040717999885, 25.511587142000167],
                [-12.003648756999951, 25.637779235000039],
                [-12.003371238999932, 25.729240418000188],
                [-12.003810881999982, 25.763967514000058],
                [-12.005049704999976, 25.863149643000099],
                [-12.004819869999949, 25.890169144000083],
                [-12.004672049999897, 25.907350540000039],
                [-12.003911018999815, 25.996122360000186],
                [-12.00388813099994, 25.998640060000128],
                [-11.98425960599991, 25.998462677000134],
                [-11.918629645999829, 25.997831345000122],
                [-11.844578742999943, 25.998052597000139],
                [-11.704891204999967, 25.998359680000021],
                [-11.565199851999921, 25.998529435000137],
                [-11.42551040599983, 25.998550416000171],
                [-11.304828642999837, 25.998447418000183],
                [-11.285819052999955, 25.998479843000098],
                [-11.146128653999938, 25.998630524000077],
                [-11.006440162999922, 25.998640060000128],
                [-10.866749762999916, 25.998510361000172],
                [-10.841290474999937, 25.998470307000048],
                [-10.727070807999894, 25.999610901000096],
                [-10.686209677999898, 26.00000000100016],
                [-10.587380408999934, 25.999670028000082],
                [-10.447691917999919, 25.999067307000075],
                [-10.374051092999935, 25.998699188000046],
                [-10.307998657999974, 25.99913025],
                [-10.217929839999954, 25.999679566000168],
                [-10.168311118999952, 25.999439240000186],
                [-10.028619765999963, 25.998672486000146],
                [-9.999999046999903, 25.998479843000098],
                [-9.888938902999939, 25.997760774000028],
                [-9.888143538999941, 25.997749328000168],
                [-9.749253272999965, 25.99865913400015],
                [-9.696992873999932, 25.998960495000119],
                [-9.686016081999981, 25.997539521000135],
                [-9.609695434999935, 25.999080658000025],
                [-9.562904357999912, 26.000009537000039],
                [-9.487068176999912, 25.997949600000027],
                [-9.470049856999935, 25.998069763000103],
                [-9.330366133999974, 25.999000549000016],
                [-9.283807754999941, 25.999280930000054],
                [-9.190677642999958, 25.998619079000093],
                [-9.050996779999935, 25.99751091000013],
                [-8.979048728999942, 25.996889115000158],
                [-8.911311149999904, 25.997110366000072],
                [-8.771624563999978, 25.997459412000183],
                [-8.674157141999956, 25.997608184000057],
                [-8.674116134999963, 26.003383637000127],
                [-8.673889159999817, 26.035760880000055],
                [-8.672994612999958, 26.161981584000046],
                [-8.672099112999945, 26.288211822000108],
                [-8.671984672999884, 26.30443954500015],
                [-8.672140121999973, 26.414440154000033],
                [-8.67231845799995, 26.54067993200016],
                [-8.672495841999819, 26.666929245000119],
                [-8.672674179999831, 26.793180466000081],
                [-8.672807693999971, 26.887157440000124],
                [-8.672854423999979, 26.919429780000087],
                [-8.673020363999967, 27.045679093000047],
                [-8.671062469999981, 27.126510621000136],
                [-8.67233562399997, 27.171909333000144],
                [-8.673351286999889, 27.207950592000145],
                [-8.672477722999929, 27.288320541000076],
                [-8.673868178999953, 27.298070908000113],
                [-8.663563727999929, 27.293909073000123],
                [-8.554161071999943, 27.231159209999987],
                [-8.43538379599994, 27.162849426000037],
                [-8.356336593999913, 27.117277146000049],
                [-8.316575050999973, 27.094690323],
                [-8.197548865999977, 27.026939393000134],
                [-8.078668594999897, 26.959089279000182],
                [-7.959938048999959, 26.89113998400012],
                [-7.894824980999943, 26.853790284000127],
                [-7.840659141999879, 26.824079514000118],
                [-7.805765151999935, 26.804901123000036],
                [-7.740276813999913, 26.768909454000038],
                [-7.720890997999959, 26.757820129999971],
                [-7.602366923999966, 26.689880371000186],
                [-7.509963988999971, 26.63678932300013],
                [-7.48409795699996, 26.621692658000086],
                [-7.366371154999968, 26.552850723000063],
                [-7.248787879999895, 26.483909607000101],
                [-7.131627082999898, 26.415029527000172],
                [-7.015338897999925, 26.344020844000113],
                [-6.999999999999886, 26.341360092000059],
                [-6.94098186399998, 26.304159164000112],
                [-6.930003166999939, 26.299360276000073],
                [-6.914603232999923, 26.288309098000127],
                [-6.906527996999955, 26.285209656],
                [-6.694906235999952, 26.155899049000084],
                [-6.675567149999949, 26.146600723000063],
                [-6.670958995999968, 26.141279221000161],
                [-6.628077028999826, 26.11503028900006],
                [-6.624569892999887, 26.111270905000026],
                [-6.540824890999943, 26.062091828000121],
                [-6.477716445999931, 26.022029877000023],
                [-6.475655078999978, 26.021539688000189],
                [-6.422891139999933, 25.988969802000099],
                [-6.416396139999961, 25.986240387000123],
                [-6.410524844999941, 25.980730056000084],
                [-6.397719859999938, 25.973890305000054],
                [-6.385004996999953, 25.96359062300013],
                [-6.34927511199993, 25.945480347000057],
                [-6.34210681899998, 25.939329148000127],
                [-6.153655052999909, 25.82533073400009],
                [-6.13841199899997, 25.814422608000143],
                [-6.123826980999979, 25.806709289000082],
                [-6.116415977999907, 25.800321579000013],
                [-6.102673053999865, 25.794181824000134],
                [-6.084087847999911, 25.782249450000165],
                [-6.071372984999925, 25.776292801000181],
                [-6.066070078999928, 25.771438599000135],
                [-5.979632853999931, 25.718599320000067],
                [-5.934062002999895, 25.692699432000097],
                [-5.859897135999972, 25.644859315000133],
                [-5.841221809999922, 25.635421753000173],
                [-5.763617038999882, 25.586248398],
                [-5.752318858999956, 25.579090118000181],
                [-5.652600763999885, 25.517894745000092],
                [-5.569502829999919, 25.466899873000102],
                [-5.538249015999895, 25.445680619000029],
                [-5.408523081999874, 25.366388321000159],
                [-5.405858993999971, 25.363040924000188],
                [-5.346468925999943, 25.325700759000028],
                [-5.335638998999968, 25.320310594000148],
                [-5.162302971999964, 25.208909989000063],
                [-5.135982035999973, 25.193878174000076],
                [-5.129982946999974, 25.188781738000102],
                [-5.034568785999966, 25.127389909000101],
                [-5.001045464999834, 25.107940674000133],
                [-4.9421620359999, 25.069528580000167],
                [-4.836943149999968, 25.002790450000191],
                [-4.833104964999905, 24.998401835000095],
                [-4.832305320999922, 24.997939961000043],
                [-4.829955323999911, 24.996582606000118],
                [-4.832172869999965, 24.996564866000085],
                [-4.904493808999916, 24.99600982700008],
                [-4.907717226999978, 24.994539261000057],
                [-4.933785915999977, 24.996017456000061],
                [-4.93700885699991, 24.996200561000023],
                [-4.937542914999881, 24.995981216000075],
                [-4.941232204999949, 24.994470596000156],
                [-4.978202819999979, 24.995592118000047],
                [-4.990931033999971, 24.995979309000177],
                [-4.999999045999914, 24.995891572000119],
                [-4.999999045999914, 24.99799919100019],
                [-4.999999045999914, 25.0],
                [-5.001999854999951, 25.0],
                [-5.08530092299992, 25.0],
                [-5.104547977999914, 25.0],
                [-5.112915039999962, 25.0],
                [-5.1330189699999, 25.0],
                [-5.168601036999974, 25.0],
                [-5.251901149999981, 25.0],
                [-5.290637015999891, 25.0],
                [-5.335198878999961, 25.0],
                [-5.376749992999976, 25.0],
                [-5.418498991999968, 25.0],
                [-5.501801012999977, 25.0],
                [-5.585101127999906, 25.0],
                [-5.668398856999886, 25.0],
                [-5.7516989699999, 25.0],
                [-5.780807971999934, 25.0],
                [-5.795485971999938, 25.0],
                [-5.834999083999946, 25.0],
                [-5.882464885999923, 25.0],
                [-5.890707968999948, 25.0],
                [-5.918300151999972, 25.0],
                [-5.94623994899996, 25.0],
                [-6.001599787999908, 25.0],
                [-6.084899901999961, 25.0],
                [-6.148137091999956, 25.0],
                [-6.152740954999956, 25.0],
                [-6.168200014999968, 25.0],
                [-6.251500129999897, 25.0],
                [-6.31031322399997, 25.0],
                [-6.334799766999879, 25.0],
                [-6.418099879999886, 25.0],
                [-6.46454715699997, 25.0],
                [-6.473134993999906, 25.0],
                [-6.481391905999885, 25.0],
                [-6.483726024999953, 25.0],
                [-6.501399992999893, 25.0],
                [-6.51698493899994, 25.0],
                [-6.562486170999932, 25.0],
                [-6.563048839999965, 25.0],
                [-6.564404964999881, 25.0],
                [-6.557859896999958, 24.943143845000179],
                [-6.555284022999899, 24.920759201000124],
                [-6.552691936999906, 24.884340287000043],
                [-6.499385833999952, 24.431138993000161],
                [-6.497154236999961, 24.412170409000112],
                [-6.49257993699996, 24.360469819],
                [-6.486752033999949, 24.318700792000186],
                [-6.485816002999968, 24.309774399000162],
                [-6.485498905999975, 24.306747437000183],
                [-6.476466179999932, 24.220569611000087],
                [-6.472731112999895, 24.184928894000166],
                [-6.469214914999952, 24.151380539000172],
                [-6.454820155999926, 24.031700135000108],
                [-6.452319145999979, 24.005823136000174],
                [-6.451756, 23.999998093000045],
                [-6.454700946999935, 23.982379914000035],
                [-6.450633048999976, 23.951679231000128],
                [-6.452106952999884, 23.943429947000084],
                [-6.449603080999964, 23.938850402000128],
                [-6.448338031999981, 23.931980133000025],
                [-6.445340155999929, 23.904315949000136],
                [-6.442049979999922, 23.873941421000097],
                [-6.441120147999925, 23.870117187000062],
                [-6.441101074999835, 23.864009858000031],
                [-6.437287806999962, 23.833080291000158],
                [-6.437695980999934, 23.829639435000047],
                [-6.434753894999915, 23.816280366000058],
                [-6.432643890999941, 23.80253028900006],
                [-6.432617187999881, 23.790939331000061],
                [-6.432613849999882, 23.789550781000116],
                [-6.428008078999937, 23.758192062000091],
                [-6.424593925999943, 23.734941482000124],
                [-6.422070980999934, 23.721191406],
                [-6.42244720399998, 23.719085694000057],
                [-6.423298835999901, 23.714309693000018],
                [-6.422453880999967, 23.707441330999984],
                [-6.420777797999961, 23.700559616000191],
                [-6.419930935999958, 23.693687439000087],
                [-6.418327807999901, 23.690122605000056],
                [-6.417013168999972, 23.687200547000032],
                [-6.417356967999979, 23.68329620500009],
                [-6.418222902999958, 23.673440933],
                [-6.413018226999895, 23.646940231000087],
                [-6.413815973999931, 23.640207291000024],
                [-6.406219005999901, 23.57840919500012],
                [-6.402797222999936, 23.57102966400015],
                [-6.402643202999968, 23.570699691000073],
                [-6.402663230999963, 23.569982530000175],
                [-6.403028010999947, 23.556940079000071],
                [-6.401340006999874, 23.543190002000074],
                [-6.39799404099989, 23.530969619000132],
                [-6.398378847999936, 23.517589569999984],
                [-6.396262168999954, 23.496578218000025],
                [-6.393349169999965, 23.491239548000124],
                [-6.393733977999943, 23.477859498000043],
                [-6.386985778999872, 23.422840118000181],
                [-6.384060859999977, 23.410240174000023],
                [-6.384447097999953, 23.396860123000067],
                [-6.383428097999968, 23.388551713000084],
                [-6.382760048999899, 23.38311004600007],
                [-6.38109397799991, 23.378900528000088],
                [-6.383551120999925, 23.365142822000053],
                [-6.382720946999882, 23.365142822000053],
                [-6.380228042999931, 23.359798431000115],
                [-6.379384994999896, 23.352920533000145],
                [-6.376059054999871, 23.346807480000166],
                [-6.376046181999868, 23.339929580000046],
                [-6.377688883999895, 23.334577561000174],
                [-6.376359938999883, 23.32909965500005],
                [-6.369698046999929, 23.272670746000188],
                [-6.366776942999877, 23.259679794000192],
                [-6.367753982999886, 23.251419067000143],
                [-6.363962172999948, 23.230640411000081],
                [-6.359198092999975, 23.192039490000184],
                [-6.355864047999944, 23.180189132000066],
                [-6.356675148999898, 23.170640945000059],
                [-6.354761123999936, 23.158079148000127],
                [-6.354578970999967, 23.156879425000113],
                [-6.35168409299996, 23.155742646000078],
                [-6.351664066999888, 23.145029069000145],
                [-6.353304863999938, 23.139680864000184],
                [-6.350786207999931, 23.119041444000118],
                [-6.353233814999953, 23.10297966100012],
                [-6.349102018999929, 23.10297966100012],
                [-6.345745086999898, 23.075460434000036],
                [-6.344079018999878, 23.069349288000126],
                [-6.343239783999934, 23.062469483000086],
                [-6.343227863999914, 23.056350709000071],
                [-6.340712070999928, 23.035709382000107],
                [-6.338634013999979, 23.029600143000096],
                [-6.338198185999943, 23.016220093000015],
                [-6.336368084999947, 23.000820160000103],
                [-6.335371970999972, 22.997619629000042],
                [-6.333749771999976, 22.971380235000083],
                [-6.329390047999937, 22.94148063599999],
                [-6.327012061999881, 22.912410737000016],
                [-6.324782846999938, 22.90420913700018],
                [-6.323935984999935, 22.891210556000146],
                [-6.321475028999942, 22.870979310000109],
                [-6.321424006999905, 22.870559693000075],
                [-6.32141017899994, 22.862150192000172],
                [-6.32016801799989, 22.860240937000015],
                [-6.318492889999959, 22.846479417000069],
                [-6.318893908999939, 22.83921051100009],
                [-6.31411981499997, 22.799951554000131],
                [-6.313872813999979, 22.797920227000191],
                [-6.313348770999937, 22.79679107600009],
                [-6.31138896899995, 22.792570114000114],
                [-6.311503886999958, 22.789228439000112],
                [-6.311782837999942, 22.781089783000084],
                [-6.309689044999914, 22.760440828000185],
                [-6.309679984999946, 22.755090714000175],
                [-6.30704689099997, 22.731279374],
                [-6.303018091999945, 22.714559555000108],
                [-6.304997919999892, 22.709007263000103],
                [-6.297132968999904, 22.643430710000075],
                [-6.293382167999937, 22.61627960300001],
                [-6.292943954999885, 22.599069596000106],
                [-6.290874002999942, 22.591417313000136],
                [-6.289204119999965, 22.577659607999976],
                [-6.287939070999926, 22.5577697760001],
                [-6.287199973999975, 22.554710388000103],
                [-6.281363964999912, 22.506517411000061],
                [-6.275452137999878, 22.46598243700015],
                [-6.273776053999939, 22.445318222000026],
                [-6.271287917999871, 22.431547166000144],
                [-6.270102024999972, 22.411720277000029],
                [-6.267960071999937, 22.404779434000147],
                [-6.2610249519999, 22.33115959200012],
                [-6.257145881999918, 22.316799164000088],
                [-6.258581161999928, 22.312780381000039],
                [-6.255465983999955, 22.288488388000133],
                [-6.252573965999943, 22.277780533000112],
                [-6.252976894999961, 22.271659851000152],
                [-6.250483989999907, 22.250999451000098],
                [-6.250473974999977, 22.24181938300012],
                [-6.24964904899997, 22.24181938300012],
                [-6.248410225999919, 22.234939576000102],
                [-6.242795944999955, 22.181179047000171],
                [-6.239706039999874, 22.174488068000187],
                [-6.239685058999953, 22.15611839200011],
                [-6.225173949999942, 22.035217285000158],
                [-6.224330901999963, 22.014940262000152],
                [-6.221445084999857, 22.001550673999986],
                [-6.222370147999925, 22.002410890000135],
                [-6.219370841999933, 21.980890274000103],
                [-6.21853494599992, 21.967489243000045],
                [-6.216886995999914, 21.960607528000025],
                [-6.215231895999921, 21.946830750000061],
                [-6.214810848999832, 21.935737610000047],
                [-6.212333202999901, 21.91966056900003],
                [-6.209846973999902, 21.892110824000042],
                [-6.206536768999911, 21.864559175000124],
                [-6.206530093999902, 21.859201431000031],
                [-6.205296992999934, 21.853458405000026],
                [-6.20157814099997, 21.822460175000117],
                [-6.201570987999901, 21.8163394930001],
                [-6.199925898999879, 21.809448243000134],
                [-6.197031020999873, 21.782279969],
                [-6.194560049999893, 21.768510819000085],
                [-6.187541007999926, 21.706119538],
                [-6.187533855999959, 21.699230195000098],
                [-6.181353090999949, 21.651388169000086],
                [-6.179697990999898, 21.630718232000049],
                [-6.170218943999942, 21.547670364000112],
                [-6.166102885999976, 21.513221741000052],
                [-6.164052009999978, 21.506708146000165],
                [-6.163225173999933, 21.492929458000162],
                [-6.161581039999874, 21.479150773000072],
                [-6.159114836999947, 21.465370179000104],
                [-6.151710033999905, 21.396469116000162],
                [-6.148015975999954, 21.368911744],
                [-6.147191047999911, 21.355129241000157],
                [-6.142268179999917, 21.3206672660001],
                [-6.135289192999892, 21.258651732999965],
                [-6.135690211999929, 21.24525261000008],
                [-6.132822989999966, 21.231470109000043],
                [-6.129951954999967, 21.203910828000176],
                [-6.12790489199989, 21.190509797000061],
                [-6.126674175999938, 21.176729202000161],
                [-6.126258849999942, 21.162937165000073],
                [-6.124896049999961, 21.157579422000026],
                [-6.117067813999938, 21.0917301180001],
                [-6.1176581389999, 21.086748123000064],
                [-6.116837024999938, 21.079860687000064],
                [-6.115202903999887, 21.072969437000097],
                [-6.09966182799991, 20.941259385000137],
                [-6.097620009999901, 20.929010391000077],
                [-6.093288898999958, 20.891479492000087],
                [-6.093943119999892, 20.891479492000087],
                [-6.091493129999947, 20.863910675000113],
                [-6.088634968999884, 20.850128174000076],
                [-6.084966181999903, 20.82255745000009],
                [-6.080883980999943, 20.781200410000167],
                [-6.079257011999971, 20.774309159000097],
                [-6.077217101999963, 20.760520936000091],
                [-6.07558488799998, 20.736400605000028],
                [-6.074363231999882, 20.732950211000173],
                [-6.072328089999814, 20.719169618000137],
                [-6.07191801099998, 20.705379487000187],
                [-6.069067001999883, 20.691602706000083],
                [-6.067030906999946, 20.670917512000074],
                [-6.063366889999884, 20.643350602000055],
                [-6.063775061999934, 20.629560471000104],
                [-6.062959192999927, 20.622669221000137],
                [-6.06051778899996, 20.615770340000154],
                [-6.059703825999975, 20.608877182000185],
                [-6.060514925999939, 20.60199165400013],
                [-6.058889864999912, 20.588199616000111],
                [-6.055962084999976, 20.579929353000182],
                [-6.054821966999953, 20.560630798999966],
                [-6.050353049999956, 20.526159286],
                [-6.027921198999877, 20.335018159000072],
                [-6.025992870999914, 20.318588257000101],
                [-6.026240824999945, 20.314874650000093],
                [-6.026401042999964, 20.312461853],
                [-6.022188187999973, 20.281990052000026],
                [-6.004528044999915, 20.154289246000133],
                [-6.003315925999971, 20.138969422],
                [-5.999669074999872, 20.130159378000087],
                [-6.001859187999969, 20.126026154000044],
                [-6.003580092999926, 20.122779847000118],
                [-6.001429079999923, 20.104419709000126],
                [-6.004547118999881, 20.099519729000065],
                [-6.000926970999956, 20.095409394000058],
                [-5.999210833999882, 20.066070557000046],
                [-5.998072147999892, 20.061220169000023],
                [-5.998075962999963, 20.054319381000141],
                [-5.996456146999947, 20.048189164000178],
                [-5.996459961999904, 20.041299821000052],
                [-5.998083114999929, 20.035169601000177],
                [-5.996459961999904, 20.031341552000072],
                [-5.994843958999979, 20.024450302000105],
                [-5.994843958999979, 20.018320084000038],
                [-5.991726874999927, 20.000000001000103],
                [-5.991403102999925, 19.999982835000083],
                [-5.989593027999831, 19.982320785000127],
                [-5.987165926999978, 19.968530656000155],
                [-5.985557078999932, 19.947839737000152],
                [-5.983534813999938, 19.934440613000163],
                [-5.983541964999915, 19.923330308000061],
                [-5.97628307399998, 19.858980180000117],
                [-5.974264144999893, 19.852849960000071],
                [-5.97265005099996, 19.839069367000036],
                [-5.974678993999874, 19.832170486000052],
                [-5.972911833999945, 19.828269959000181],
                [-5.969026088999954, 19.806129456000122],
                [-5.967412948999879, 19.792339325000171],
                [-5.96741676399995, 19.786207200000035],
                [-5.966609955999957, 19.780082703000176],
                [-5.964994907999937, 19.773181915000123],
                [-5.960166930999947, 19.72492027300018],
                [-5.960165023999878, 19.731819154000164],
                [-5.956944942999883, 19.704240799000104],
                [-5.956951140999877, 19.695810319000145],
                [-5.956141947999981, 19.693519592000087],
                [-5.952927111999941, 19.665939332000164],
                [-5.952930926999954, 19.658279419999985],
                [-5.951316833999897, 19.650997162000181],
                [-5.948976993999906, 19.630529405000175],
                [-5.949637889999906, 19.621822357000042],
                [-5.949719906999917, 19.620740891000082],
                [-5.948609829999953, 19.619689942000093],
                [-5.940874099999974, 19.557159424000133],
                [-5.93765211099992, 19.541830064000067],
                [-5.938867090999906, 19.539920807000101],
                [-5.937260150999919, 19.52613067600015],
                [-5.93848085299993, 19.512729645000036],
                [-5.936062811999875, 19.506599426000093],
                [-5.934854984999959, 19.499700547000089],
                [-5.932453154999905, 19.472509385000023],
                [-5.9300389299999, 19.459480285000097],
                [-5.924028872999941, 19.403940202],
                [-5.916810989999931, 19.349550248000185],
                [-5.915214061999961, 19.328870774000166],
                [-5.912805079999941, 19.315080642999988],
                [-5.912415026999895, 19.301290512000037],
                [-5.910416124999927, 19.280609131000119],
                [-5.906414032999976, 19.246129990000043],
                [-5.905210970999974, 19.239240647000145],
                [-5.897625922999907, 19.163400651000075],
                [-5.896018982999976, 19.156499862000146],
                [-5.896028040999966, 19.145780563000073],
                [-5.890028952999955, 19.101350785000079],
                [-5.887627124999938, 19.087547302000075],
                [-5.887229918999935, 19.080661774000077],
                [-5.886028766999914, 19.07375908],
                [-5.885632990999966, 19.066869735000125],
                [-5.884431839999877, 19.059980392000057],
                [-5.88164186399996, 19.032400132000134],
                [-5.878444194999872, 19.011709213000131],
                [-5.87885904399991, 19.000989915000105],
                [-5.876849174999961, 18.997919084000159],
                [-5.876459121999972, 18.986049653000123],
                [-5.876328944999955, 18.984882355000025],
                [-5.865704059999928, 18.889152527000022],
                [-5.864653110999939, 18.870758058000149],
                [-5.862514972999975, 18.861949920000029],
                [-5.861729145999959, 18.848930360000168],
                [-5.858936785999902, 18.828250886000092],
                [-5.857748031999961, 18.814460755000141],
                [-5.856551170999978, 18.807569504000071],
                [-5.849792956999977, 18.745130539000115],
                [-5.849812029999953, 18.732109071000082],
                [-5.849016189999929, 18.725219728000184],
                [-5.847414015999959, 18.719089508000138],
                [-5.84383296999988, 18.691509248000045],
                [-5.835495948999949, 18.615680695000094],
                [-5.834301948999894, 18.608787537000183],
                [-5.831139087999873, 18.574319839000054],
                [-5.828355789999876, 18.553630830000031],
                [-5.82757186799995, 18.539848329000165],
                [-5.825583934999941, 18.526060105000113],
                [-5.814097881999942, 18.423030853000171],
                [-5.810527801999967, 18.395458221000183],
                [-5.808144092999896, 18.38205909800007],
                [-5.807357787999933, 18.370569229000182],
                [-5.803791045999958, 18.342990875000055],
                [-5.801427840999963, 18.315420150000136],
                [-5.801438808999933, 18.309289933000173],
                [-5.798654079999949, 18.295890808000024],
                [-5.798264026999902, 18.288988114000176],
                [-5.79470205299998, 18.261419297000089],
                [-5.786811827999941, 18.185600282000053],
                [-5.78573083799995, 18.166070939000065],
                [-5.781298159999892, 18.130830765000155],
                [-5.780519007999942, 18.117822648000072],
                [-5.771050929999888, 18.035871506000092],
                [-5.769086837999851, 18.015190124000071],
                [-5.767500877999964, 18.008300781],
                [-5.765511034999918, 18.002550123999981],
                [-5.765910148999978, 18.002170564000039],
                [-5.766324995999923, 17.99527931300014],
                [-5.764747141999976, 17.98148727500012],
                [-5.764387129999932, 17.959289552000143],
                [-5.761993884999981, 17.955070497],
                [-5.7612190239999, 17.941289901000061],
                [-5.759642124999971, 17.929420472],
                [-5.759655952999879, 17.920999528000152],
                [-5.758062838999877, 17.917169571000045],
                [-5.750995159999945, 17.849010467000141],
                [-5.749011993999943, 17.839822769000023],
                [-5.7470541, 17.819139480000104],
                [-5.747084140999903, 17.80382919200008],
                [-5.745097160999933, 17.79846954400017],
                [-5.743524074999868, 17.784679413000049],
                [-5.743134021999936, 17.778560638000101],
                [-5.743955134999908, 17.769369125000139],
                [-5.742972850999934, 17.767019272000141],
                [-5.735957144999873, 17.705429077000076],
                [-5.733705996999959, 17.698919296000156],
                [-5.732933043999935, 17.685899734000088],
                [-5.730578897999919, 17.665231705000167],
                [-5.729813099999888, 17.649152757000138],
                [-5.729014872999926, 17.648380280000083],
                [-5.726661204999971, 17.627708435000045],
                [-5.725077152999916, 17.622350693000101],
                [-5.724304198999903, 17.60932731600019],
                [-5.717246055999965, 17.549280167000063],
                [-5.717246055999965, 17.548477174000141],
                [-5.717267989999925, 17.538129807000132],
                [-5.715206145999957, 17.532150269000056],
                [-5.710534095999833, 17.495679855000162],
                [-5.710256100999914, 17.492942810000102],
                [-5.699254034999967, 17.385000229000127],
                [-5.69728517599998, 17.373510360000068],
                [-5.695344924999915, 17.349779128000137],
                [-5.691433905999872, 17.317239761000167],
                [-5.691452979999951, 17.30997085600012],
                [-5.688310146999811, 17.289300919000084],
                [-5.68754386899991, 17.27552032599999],
                [-5.68636608099996, 17.268629073000113],
                [-5.682859896999958, 17.234180450999986],
                [-5.682871817999967, 17.22883033800008],
                [-5.678956031999974, 17.200120926000181],
                [-5.67897176699995, 17.193609237999965],
                [-5.678271769999924, 17.189523697000084],
                [-5.677008152999918, 17.182132720000084],
                [-5.675065993999908, 17.162229538000133],
                [-5.675078869999879, 17.155719756999986],
                [-5.668829916999869, 17.105579376000151],
                [-5.666504860999964, 17.079559325999981],
                [-5.65833902299994, 17.003780365000125],
                [-5.65954017699994, 17.000719071000105],
                [-5.659546851999949, 16.996902465000176],
                [-5.658768177999946, 16.99077034000004],
                [-5.656396866999955, 16.984649659000127],
                [-5.652489185999912, 16.957090376999986],
                [-5.64860296199987, 16.922649383000135],
                [-5.648615836999966, 16.916530609000176],
                [-5.645874976999949, 16.900451661000091],
                [-5.644299984999918, 16.884897232000185],
                [-5.6439371109999, 16.881320954000046],
                [-5.602798937999978, 16.499429704000022],
                [-5.327115059999926, 16.333139419000133],
                [-5.397523878999891, 16.000001908000058],
                [-5.415248871999836, 15.919301031999964],
                [-5.442327022999962, 15.795910835000143],
                [-5.446868895999955, 15.775202752000155],
                [-5.450138091999975, 15.766770362000045],
                [-5.451056002999906, 15.75714969600017],
                [-5.469754218999981, 15.672741891000044],
                [-5.497056007999959, 15.549361229000056],
                [-5.50633192, 15.508371354000019],
                [-5.506422996999959, 15.507969857000148],
                [-5.507554054999844, 15.507177353000088],
                [-5.510067939999942, 15.505410195000081],
                [-5.514934062999942, 15.501920701000188],
                [-5.541172979999885, 15.501679420000016],
                [-5.553307055999937, 15.500631333000115],
                [-5.58558511699988, 15.500300408000157],
                [-5.597115038999903, 15.499382020000041],
                [-5.659410000999912, 15.499541283000156],
                [-5.688292026999932, 15.499609948000057],
                [-5.772861003999935, 15.49852943500008],
                [-5.789537905999907, 15.497481346000143],
                [-5.808906077999893, 15.496258736000073],
                [-5.919662952999829, 15.495320320000019],
                [-5.998631953999961, 15.494629860000089],
                [-5.998616217999881, 15.495538711999984],
                [-6.006453990999944, 15.494311333000155],
                [-6.048757074999969, 15.495107650000023],
                [-6.076766014999919, 15.495630264000113],
                [-6.112553119999973, 15.494231223999975],
                [-6.137631892999934, 15.495380402000023],
                [-6.149952888999906, 15.494395256000075],
                [-6.150803088999908, 15.494326591000174],
                [-6.153995990999874, 15.49407100600007],
                [-6.157293795999976, 15.495052337000175],
                [-6.178657054999917, 15.49522018499999],
                [-6.250948904999916, 15.495753288000174],
                [-6.307116984999936, 15.496167182000136],
                [-6.308823108999945, 15.496179580000103],
                [-6.319890022999914, 15.496258736000073],
                [-6.326490879999938, 15.496306419000177],
                [-6.373478888999898, 15.496640206],
                [-6.380140780999909, 15.494921684000076],
                [-6.392901896999888, 15.496289252999986],
                [-6.404701231999979, 15.49634933499999],
                [-6.438681126999882, 15.496521951000091],
                [-6.448552131999918, 15.496568681000042],
                [-6.450422763999882, 15.497653962000015],
                [-6.45183181799996, 15.498470307000161],
                [-6.453391075999946, 15.497654914000123],
                [-6.455656051999938, 15.496471406000069],
                [-6.567787170999964, 15.497481346000143],
                [-6.569547176999947, 15.497489929000039],
                [-6.572178839999879, 15.495928765000031],
                [-6.578073023999878, 15.497407913000188],
                [-6.668531894999887, 15.497540474000061],
                [-6.69728994299993, 15.498550416000114],
                [-6.718235015999881, 15.499281882999981],
                [-6.730093956999951, 15.498089790000051],
                [-6.752337931999932, 15.499139786000057],
                [-6.827351091999958, 15.498068809000188],
                [-6.882133006999879, 15.497278214000062],
                [-6.903460978999931, 15.49697113000002],
                [-6.95751285599988, 15.497179985000173],
                [-7.087680815999875, 15.497650146000069],
                [-7.125276087999964, 15.497775077000028],
                [-7.139070986999968, 15.497820854000167],
                [-7.143134116999875, 15.495351791000076],
                [-7.152142048999906, 15.494798660000072],
                [-7.162271975999886, 15.497929574000125],
                [-7.216598033999958, 15.498261452],
                [-7.281257151999967, 15.498630525000124],
                [-7.294875144999878, 15.498709679000058],
                [-7.346767901999954, 15.49887084900007],
                [-7.47693920099988, 15.499199867000129],
                [-7.607109070999968, 15.499480247000065],
                [-7.639411926999912, 15.499541283000156],
                [-7.643465994999872, 15.499047279000138],
                [-7.647952079999811, 15.498501779000094],
                [-7.659058092999885, 15.499176980000129],
                [-7.663031100999831, 15.499418259000095],
                [-7.667354106999937, 15.499679566000111],
                [-7.675896166999962, 15.498411179000072],
                [-7.737079143999949, 15.498682022000025],
                [-7.785052776999919, 15.498891830000161],
                [-7.867238997999891, 15.497699738999984],
                [-7.904419897999958, 15.497151375000101],
                [-7.972371101999897, 15.498201370000061],
                [-7.984470843999816, 15.496051789000092],
                [-7.992267131999881, 15.49775028300013],
                [-7.997001170999965, 15.497758866000027],
                [-8.01315784499991, 15.497782708000045],
                [-8.058805464999921, 15.497850418000155],
                [-8.067107200999885, 15.496560098000145],
                [-8.09645557399989, 15.4975900660001],
                [-8.107845306999934, 15.49588966400006],
                [-8.11066436699997, 15.497758866000027],
                [-8.126317022999899, 15.497691156000087],
                [-8.244932173999871, 15.497200966000037],
                [-8.249410628999897, 15.497864724000067],
                [-8.256341933999977, 15.498891830000161],
                [-8.263901709999857, 15.497857094000153],
                [-8.26695346799994, 15.497440339000036],
                [-8.305079459999945, 15.497561455000096],
                [-8.358588219999888, 15.497729302000039],
                [-8.35889625599998, 15.497699738999984],
                [-8.386263846999952, 15.495000840000102],
                [-8.387482643999931, 15.497120857000084],
                [-8.397808074999887, 15.49740123700019],
                [-8.401212691999945, 15.497489929000039],
                [-8.414956092999944, 15.496489525000072],
                [-8.414949417999935, 15.497407913000188],
                [-8.429968833999908, 15.49777698500003],
                [-8.431281088999981, 15.497809411000048],
                [-8.520076751999852, 15.498021126000026],
                [-8.522023200999968, 15.497732163000023],
                [-8.526896476999923, 15.497009277000075],
                [-8.531698226999936, 15.496299743000179],
                [-8.593722342999968, 15.49759960200015],
                [-8.594291686999895, 15.49769687600002],
                [-8.602232933999972, 15.499060630000088],
                [-8.607058524999957, 15.497691156000087],
                [-8.60769557999987, 15.49751091000013],
                [-8.655821799999899, 15.497668267000051],
                [-8.656633375999888, 15.497670175000053],
                [-8.78680419799997, 15.498060226000064],
                [-8.862238882999975, 15.498261452],
                [-8.875118255999951, 15.497561455000096],
                [-8.89872074199991, 15.496279717000107],
                [-8.916913031999968, 15.496681214000034],
                [-8.955520628999921, 15.497522355000058],
                [-8.967848776999972, 15.497791291000112],
                [-8.968479155999944, 15.497515678000127],
                [-8.970939636999958, 15.496441842000138],
                [-8.987504004999948, 15.497499467000125],
                [-9.00081920699995, 15.497471810000093],
                [-9.046734810999908, 15.497371673000032],
                [-9.119160651999948, 15.497188566999966],
                [-9.120405197999958, 15.497442246000105],
                [-9.12388801599991, 15.498150825000039],
                [-9.129612921999922, 15.497438431000035],
                [-9.134317397999894, 15.49685192100003],
                [-9.134963989999903, 15.496844291000116],
                [-9.142374039999936, 15.496747017000075],
                [-9.176713943999914, 15.496299743000179],
                [-9.200626373999967, 15.495990754000104],
                [-9.306881903999965, 15.496212006000121],
                [-9.328486442999974, 15.496250153000176],
                [-9.334035872999948, 15.514650345000064],
                [-9.334675788999959, 15.524291040000094],
                [-9.332461356999943, 15.568578720000176],
                [-9.32621574399991, 15.600071908000018],
                [-9.325283050999929, 15.604778289000137],
                [-9.324911117999875, 15.6066522590001],
                [-9.319918631999826, 15.631829262000053],
                [-9.311137199999962, 15.666198730000019],
                [-9.312734602999967, 15.674699783000051],
                [-9.327399254999875, 15.688055039000176],
                [-9.332036971999912, 15.692278861999966],
                [-9.350760459999947, 15.676361083000018],
                [-9.357619285999931, 15.670528412000181],
                [-9.364699362999943, 15.664507865000189],
                [-9.370579718999977, 15.658791542000188],
                [-9.378877639999871, 15.650728227000116],
                [-9.389898300999846, 15.640019418],
                [-9.402132986999959, 15.628129006000165],
                [-9.410124778999887, 15.620363236000117],
                [-9.419562338999867, 15.611191749],
                [-9.437879561999921, 15.600495338000087],
                [-9.439641951999931, 15.599466325000094],
                [-9.442871093999941, 15.597580909000044],
                [-9.441355704999978, 15.591180802000167],
                [-9.439678191999974, 15.589870453000174],
                [-9.437364577999972, 15.582740784000123],
                [-9.438020705999975, 15.578201294],
                [-9.43837165799988, 15.577130318000059],
                [-9.432776450999938, 15.55160904000013],
                [-9.432198523999887, 15.548975944000063],
                [-9.424737930999982, 15.514950752000175],
                [-9.42424583299993, 15.512694359000079],
                [-9.423659324999903, 15.510003090000055],
                [-9.420303345999969, 15.494613647000108],
                [-9.419196128999943, 15.489541054000085],
                [-9.408665657999961, 15.441259384000091],
                [-9.408263206999948, 15.434747696000045],
                [-9.408240317999912, 15.434371949000138],
                [-9.433889389999933, 15.433702470000071],
                [-9.453574179999976, 15.433191300999965],
                [-9.464117048999867, 15.432910920000154],
                [-9.475474357999872, 15.433911324000121],
                [-9.487085342999933, 15.433387757000048],
                [-9.542250632999981, 15.430900574000077],
                [-9.549579620999907, 15.432551384000078],
                [-9.559059142999899, 15.430780411000171],
                [-9.583213806999879, 15.430210114000147],
                [-9.697562217999973, 15.427491189000136],
                [-9.703969000999848, 15.425000192000027],
                [-9.706090927999981, 15.416671754000163],
                [-9.706681250999907, 15.410080910000147],
                [-9.712429046999944, 15.404821395999988],
                [-9.716089248999936, 15.402870178000057],
                [-9.731226920999916, 15.403651237000076],
                [-9.751680373999818, 15.408469200000013],
                [-9.756678580999903, 15.400779725000177],
                [-9.76712608299988, 15.390929223000114],
                [-9.793499945999884, 15.37271022900012],
                [-9.800392150999869, 15.370726586000046],
                [-9.801111220999928, 15.370520592000048],
                [-9.801324844999954, 15.370459556000128],
                [-9.806497572999945, 15.367631912000093],
                [-9.810747146999972, 15.36530971500008],
                [-9.811292647999949, 15.365011215999971],
                [-9.827983856999936, 15.361591339000086],
                [-9.829232215999923, 15.361334801],
                [-9.830958365999948, 15.360980987000062],
                [-9.834474563999891, 15.360851288000049],
                [-9.841147423999928, 15.360596657000031],
                [-9.862373350999974, 15.359788894000076],
                [-9.862914085999933, 15.359768868],
                [-9.883958816999893, 15.363101006000022],
                [-9.89247989699993, 15.366022111000063],
                [-9.895130157999972, 15.366930008000111],
                [-9.90026187899997, 15.368688583000051],
                [-9.912364005999962, 15.371505738],
                [-9.919520378999948, 15.373170853000033],
                [-9.922480582999981, 15.373861313000134],
                [-9.926204681999934, 15.371094704000086],
                [-9.927706717999968, 15.369978904000163],
                [-9.930908202999888, 15.369888307000053],
                [-9.934334754999895, 15.369790077000062],
                [-9.947312355999941, 15.366615296000191],
                [-9.949425697999914, 15.366097450000154],
                [-9.960488318999978, 15.363389970000128],
                [-9.96561431899994, 15.36213874900011],
                [-9.968877791999944, 15.361581803000036],
                [-9.989533424999877, 15.358057975000122],
                [-9.990484236999919, 15.35789489800004],
                [-9.998298644999977, 15.35655975300017],
                [-9.999999046999903, 15.356639861000019],
                [-10.039468764999981, 15.35859108000011],
                [-10.088219641999899, 15.358120918000111],
                [-10.089839934999929, 15.358461379000119],
                [-10.131488798999953, 15.367038726000089],
                [-10.18206977899996, 15.384697914000128],
                [-10.213259695999909, 15.397260667000126],
                [-10.224128722999865, 15.401630402000137],
                [-10.232878684999946, 15.402810097000099],
                [-10.239259718999904, 15.406050682000114],
                [-10.252738952999891, 15.409779549000177],
                [-10.256508826999891, 15.413240433000169],
                [-10.267869949999977, 15.413511276000122],
                [-10.320601462999889, 15.431160928000111],
                [-10.322671889999981, 15.4314689630001],
                [-10.33638954099996, 15.433512688000178],
                [-10.34798908199997, 15.431269646000032],
                [-10.370710373999827, 15.431820869000092],
                [-10.38228988599991, 15.434619903000055],
                [-10.383701324999834, 15.437150001000134],
                [-10.394100189999961, 15.439261438000131],
                [-10.406611442999974, 15.436630249000132],
                [-10.418581008999922, 15.438619613000071],
                [-10.458109856999897, 15.437371254000084],
                [-10.459521292999909, 15.437730789000057],
                [-10.4699411389999, 15.440388680000126],
                [-10.494798660999948, 15.440470695000101],
                [-10.500009536999869, 15.439109803000122],
                [-10.507580756999971, 15.440051080000103],
                [-10.530079841999964, 15.435980797000013],
                [-10.548789977999945, 15.434047699000132],
                [-10.56252193399996, 15.432630540000048],
                [-10.56939983399991, 15.429670334000036],
                [-10.584641455999872, 15.427719116999981],
                [-10.587678908999919, 15.427331924000043],
                [-10.590600967999933, 15.42418193900005],
                [-10.596740722999925, 15.421422005000181],
                [-10.62584972399992, 15.422201156000028],
                [-10.633191108999938, 15.420161248],
                [-10.642909048999911, 15.412850380000123],
                [-10.645039558999883, 15.413080215999969],
                [-10.655750274999889, 15.405948639000087],
                [-10.664280890999976, 15.404599190999988],
                [-10.668451307999874, 15.411993027000165],
                [-10.669650076999972, 15.414118767000048],
                [-10.680079460999934, 15.427540778999969],
                [-10.68667220999987, 15.432782174000124],
                [-10.687650679999933, 15.433560371000112],
                [-10.689457892999883, 15.433568954000066],
                [-10.693811416999949, 15.4335899350001],
                [-10.713191031999827, 15.438440322000076],
                [-10.724230766999938, 15.436579705000042],
                [-10.727763174999893, 15.432706833000054],
                [-10.728051185999959, 15.432391166000116],
                [-10.728149412999812, 15.43184471100011],
                [-10.728638648999947, 15.429110527000034],
                [-10.729491233999966, 15.424360275000083],
                [-10.728645323999899, 15.419214249000106],
                [-10.727547644999959, 15.412541391000047],
                [-10.72646903999987, 15.405981064000059],
                [-10.723660469999913, 15.40075397600009],
                [-10.72362041599996, 15.400678635000133],
                [-10.722249983999916, 15.395010948999982],
                [-10.720600128999934, 15.388059616000135],
                [-10.720587730999966, 15.38713836800008],
                [-10.720462797999915, 15.377756118000093],
                [-10.720411300999956, 15.373820306000027],
                [-10.718747139999891, 15.37313079900008],
                [-10.718303679999963, 15.370494843000131],
                [-10.717820166999957, 15.367620468000041],
                [-10.723861694999869, 15.361343384000122],
                [-10.723988532999954, 15.36121177800004],
                [-10.724200247999818, 15.360339165000028],
                [-10.726195335999932, 15.352070809000168],
                [-10.726252556999896, 15.351829528],
                [-10.726890563999916, 15.349188805000097],
                [-10.743241309999917, 15.332109452000111],
                [-10.747501372999977, 15.330969811000102],
                [-10.749859809999975, 15.332578659000148],
                [-10.761699675999864, 15.330089570000155],
                [-10.775890349999941, 15.319931984000107],
                [-10.776869773999977, 15.318651199000101],
                [-10.778088570999898, 15.306262016000062],
                [-10.779799460999982, 15.304113388000076],
                [-10.780459403999942, 15.303282738000121],
                [-10.781778335999945, 15.303200722000099],
                [-10.787561416999949, 15.302841188000173],
                [-10.794879912999932, 15.3058395380001],
                [-10.798191069999973, 15.305157661000067],
                [-10.800009727999907, 15.302908898000112],
                [-10.805818557999942, 15.295681955000134],
                [-10.822870253999952, 15.27446079300006],
                [-10.829269408999949, 15.268969535999986],
                [-10.824838638999893, 15.24392986200013],
                [-10.835081100999957, 15.217781067000089],
                [-10.839111327999831, 15.21410942000017],
                [-10.842889784999898, 15.209790230000181],
                [-10.856618880999974, 15.206118583000091],
                [-10.861510276999923, 15.20132637100005],
                [-10.863122939999869, 15.199744224000142],
                [-10.871858596999971, 15.191180230000157],
                [-10.873929023999949, 15.189149858000178],
                [-10.873737334999873, 15.183193206000112],
                [-10.873571394999942, 15.178030967000097],
                [-10.875581741999895, 15.159312249000095],
                [-10.875949858999888, 15.155887604000156],
                [-10.876608847999876, 15.14974880200009],
                [-10.879549026999882, 15.140549660000147],
                [-10.879599571999904, 15.130351067000163],
                [-10.88517189099997, 15.125728608000031],
                [-10.894143103999909, 15.108711243000073],
                [-10.895519255999943, 15.106101035999984],
                [-10.902919769999926, 15.103618622000113],
                [-10.905320167999946, 15.108869553000034],
                [-10.909287452999934, 15.112749100000087],
                [-10.911970138999948, 15.115372658000126],
                [-10.912389753999946, 15.115782737000188],
                [-10.913968085999954, 15.119527818],
                [-10.915680885999848, 15.12359142300005],
                [-10.919555664999905, 15.127199173000122],
                [-10.920869826999933, 15.128422737000108],
                [-10.924403189999964, 15.135435106000102],
                [-10.925939558999914, 15.138485909000167],
                [-10.926119804999814, 15.13884067500004],
                [-10.928170203999912, 15.14291095800013],
                [-10.931019781999964, 15.144859315000133],
                [-10.935343741999873, 15.147813798000072],
                [-10.942315100999906, 15.152577401000087],
                [-10.944688796999912, 15.15419960000014],
                [-10.94472789699995, 15.154502869000055],
                [-10.944890021999981, 15.155783654000118],
                [-10.94535636899991, 15.159464837000144],
                [-10.946041107999918, 15.164879798000072],
                [-10.945917129999941, 15.166357040000094],
                [-10.945618628999966, 15.169890403000124],
                [-10.945695876999935, 15.169937135000112],
                [-10.948533057999953, 15.171709061000172],
                [-10.954840660999935, 15.17564869000006],
                [-10.95647716499991, 15.177297592000059],
                [-10.96060085299996, 15.181453705000138],
                [-10.968298911999966, 15.189211845999978],
                [-10.986007690999884, 15.217886925000187],
                [-10.990336418999959, 15.224893570000177],
                [-10.998529434999966, 15.238161087000037],
                [-11.00096988699994, 15.240012169000124],
                [-11.010351181999965, 15.247129440000037],
                [-11.0148410799999, 15.247819900000138],
                [-11.028349875999879, 15.256409645000076],
                [-11.03257083799997, 15.25910186800013],
                [-11.040778159999945, 15.265667916000041],
                [-11.043172836999929, 15.267583848000129],
                [-11.044630050999899, 15.268749238000055],
                [-11.060460089999879, 15.29104232800006],
                [-11.074649809999869, 15.303910255000062],
                [-11.125089645999822, 15.3390197760001],
                [-11.13875103099997, 15.348509788000172],
                [-11.142530440999963, 15.349431037999977],
                [-11.15429592199996, 15.35741329200016],
                [-11.162031173999878, 15.362661361000164],
                [-11.177079199999923, 15.372868539000081],
                [-11.200260162999882, 15.394241333000082],
                [-11.210438727999929, 15.40044021600005],
                [-11.232351301999927, 15.40798187200005],
                [-11.240501403999872, 15.410790444000042],
                [-11.252695082999878, 15.419462205000173],
                [-11.253748894999944, 15.420211793000021],
                [-11.280945776999943, 15.44397068100011],
                [-11.28240013199985, 15.445240975000104],
                [-11.291158675999952, 15.45465183400006],
                [-11.313891410999872, 15.490228654000134],
                [-11.317658424999934, 15.495888711000077],
                [-11.31862926499997, 15.497348785000099],
                [-11.328338622999922, 15.50675869100013],
                [-11.328570365999951, 15.506888388999982],
                [-11.361148834999938, 15.524970054000107],
                [-11.362681388999931, 15.525819779000187],
                [-11.36457824799993, 15.531330109000123],
                [-11.368369102999964, 15.542341233000059],
                [-11.368393896999976, 15.545085907000157],
                [-11.368421555999873, 15.548122406000118],
                [-11.368475912999941, 15.554097176000141],
                [-11.368482588999939, 15.554877281000131],
                [-11.368492126999854, 15.555845261000059],
                [-11.368611334999969, 15.568970681],
                [-11.370981215999905, 15.574481964000142],
                [-11.377335547999962, 15.580924988000163],
                [-11.379907608999872, 15.583533288000126],
                [-11.382411003999891, 15.586071015000016],
                [-11.386151313999903, 15.589850427000101],
                [-11.395645141999921, 15.602944375000106],
                [-11.398469923999812, 15.60684108800001],
                [-11.40247631099993, 15.614100456000074],
                [-11.411511419999897, 15.630471230000126],
                [-11.414253235999922, 15.633185387000083],
                [-11.421610832999932, 15.640466691000029],
                [-11.42218017499988, 15.641030312],
                [-11.428818702999934, 15.645389556999987],
                [-11.481249809999952, 15.643480300000022],
                [-11.498941421999973, 15.642840385000113],
                [-11.504527090999886, 15.64050006799999],
                [-11.505105017999938, 15.640257836000046],
                [-11.511019707999935, 15.637780190000058],
                [-11.510051726999961, 15.635934829],
                [-11.509301184999856, 15.634506227000145],
                [-11.50475597399992, 15.625849724000034],
                [-11.502949714999943, 15.622409820000144],
                [-11.502309799999921, 15.618761064000068],
                [-11.504485128999875, 15.617256164000082],
                [-11.516167640999981, 15.609177590000058],
                [-11.517511366999884, 15.608248711000044],
                [-11.524753570999962, 15.606648446000065],
                [-11.525789259999954, 15.606418611000151],
                [-11.527722357999892, 15.604743957000153],
                [-11.537535667999975, 15.596240997000052],
                [-11.546389579999982, 15.588568687000134],
                [-11.552261352999892, 15.583340643999975],
                [-11.559650421999947, 15.578419686000132],
                [-11.56120586399993, 15.578007699000068],
                [-11.565814972999931, 15.576788902000033],
                [-11.567469597999946, 15.576351167000155],
                [-11.568380355999977, 15.574226380000141],
                [-11.568864822999956, 15.573092460999987],
                [-11.569828985999948, 15.570841789000099],
                [-11.57219886699994, 15.556151390000082],
                [-11.569824217999894, 15.55118847000017],
                [-11.569120407999947, 15.549718856000084],
                [-11.569451330999982, 15.548613548999981],
                [-11.5695858, 15.548164368000073],
                [-11.571249007999938, 15.542610168000067],
                [-11.577160834999972, 15.537560464000023],
                [-11.587820053999963, 15.532508850000056],
                [-11.595389365999893, 15.531132698000022],
                [-11.600838661999944, 15.529061318000061],
                [-11.609396934999893, 15.529691695999986],
                [-11.6107969279999, 15.529794693000099],
                [-11.618988036999895, 15.53039932300004],
                [-11.619539260999943, 15.530440330000147],
                [-11.620841978999977, 15.529240609000055],
                [-11.624642371999926, 15.525747300000148],
                [-11.62852954899995, 15.522171019999973],
                [-11.632585524999968, 15.521856308000054],
                [-11.639797209999927, 15.521295547000136],
                [-11.646280288999947, 15.520792008000114],
                [-11.653431890999968, 15.522349359000088],
                [-11.653618811999877, 15.522390366000025],
                [-11.664139747999968, 15.531649589999972],
                [-11.669260977999954, 15.536160469000095],
                [-11.673472404999927, 15.534502983000095],
                [-11.678140638999878, 15.532666206999977],
                [-11.67920017299997, 15.532249450000052],
                [-11.691877364999982, 15.532363893000024],
                [-11.70269966199993, 15.532461167000065],
                [-11.703657149999913, 15.532468796000103],
                [-11.703819274999944, 15.532469750000189],
                [-11.704297064999935, 15.533954620000088],
                [-11.704999922999946, 15.536140442000146],
                [-11.705260276999979, 15.536191940000151],
                [-11.70813846499982, 15.536767007000037],
                [-11.711869239999942, 15.537511827000174],
                [-11.712270735999937, 15.535946846000115],
                [-11.714048385999945, 15.529028892000042],
                [-11.714699744999848, 15.526490213000045],
                [-11.714629173999981, 15.49442005200018],
                [-11.714902876999872, 15.487623214],
                [-11.714909553999973, 15.487468719000105],
                [-11.717270849999863, 15.483111382000061],
                [-11.720171928999889, 15.480441093000024],
                [-11.72177028599998, 15.478969574000075],
                [-11.725892066999961, 15.477393150000182],
                [-11.733789443999967, 15.474371911000048],
                [-11.736201286999972, 15.4734497070001],
                [-11.738044738999974, 15.471728324000082],
                [-11.741327284999898, 15.46866512299999],
                [-11.742349624999918, 15.46770858799999],
                [-11.747309684999891, 15.456231118000176],
                [-11.749529838999933, 15.442920684000057],
                [-11.752950668999972, 15.411461830000178],
                [-11.757201193999947, 15.405030251000142],
                [-11.7571792599999, 15.404697418000069],
                [-11.756481169999972, 15.394010545000185],
                [-11.77109909, 15.337061882000114],
                [-11.784130095999956, 15.323599816000183],
                [-11.786218642999927, 15.321440697000185],
                [-11.793304442999954, 15.310738562999973],
                [-11.795228004999956, 15.307831764000071],
                [-11.797321318999934, 15.304671288000065],
                [-11.798057555999947, 15.302174568999988],
                [-11.79939937599994, 15.297638894000158],
                [-11.800746916999969, 15.293077470000014],
                [-11.805330276999939, 15.277571679000062],
                [-11.806970595999928, 15.260351182000136],
                [-11.804819106999901, 15.241978646000177],
                [-11.806699752999918, 15.232561112000099],
                [-11.809568404999936, 15.225277901000084],
                [-11.813300132999871, 15.215800286000103],
                [-11.819188117999886, 15.206102372000032],
                [-11.822509765999939, 15.200630188000105],
                [-11.828411102999894, 15.193627358000128],
                [-11.829011915999956, 15.192913056000179],
                [-11.832188604999885, 15.189141275000111],
                [-11.833189963999871, 15.187081338000098],
                [-11.83572864599995, 15.181878090000168],
                [-11.836669921999942, 15.179949761000046],
                [-11.838081358999943, 15.171911240000099],
                [-11.836171148999881, 15.163418769999964],
                [-11.832151412999906, 15.155840874000035],
                [-11.819841383999972, 15.147362710000039],
                [-11.817001342999959, 15.141388892000123],
                [-11.816289901999824, 15.135649681000132],
                [-11.816495894999889, 15.135146142000167],
                [-11.819579123999915, 15.127609254000049],
                [-11.824469565999891, 15.123784065],
                [-11.833990096999912, 15.116338731000042],
                [-11.838073730999952, 15.112375260000078],
                [-11.838479994999886, 15.111981392000189],
                [-11.843243597999958, 15.100810051],
                [-11.846756934999917, 15.092571259000067],
                [-11.847200393999969, 15.091532708000045],
                [-11.846010207999882, 15.087401389999968],
                [-11.843166351999969, 15.084051133000173],
                [-11.842699049999908, 15.083500862000051],
                [-11.842500685999937, 15.083400727000026],
                [-11.837730406999924, 15.080980301000125],
                [-11.835037230999944, 15.075971604000188],
                [-11.834890365999968, 15.075699806999978],
                [-11.834830283999963, 15.074548722000088],
                [-11.834650992999968, 15.071100236],
                [-11.835286139999937, 15.069917678000024],
                [-11.837103842999966, 15.06653595],
                [-11.837720871999977, 15.065388681000059],
                [-11.841865538999969, 15.057701111000085],
                [-11.84469318399988, 15.052456855000116],
                [-11.846900939999955, 15.048360825000145],
                [-11.843350410999903, 15.041468619999989],
                [-11.83803272199998, 15.037432671000147],
                [-11.837909697999862, 15.037340164],
                [-11.837829589999899, 15.037336349000157],
                [-11.832240104999869, 15.037120819000052],
                [-11.827988625999922, 15.039420129000064],
                [-11.821620941999925, 15.045849800000155],
                [-11.814531324999962, 15.046779634000131],
                [-11.80395221699996, 15.044364929000096],
                [-11.80152988399982, 15.043811798000092],
                [-11.797465324999962, 15.035627366000028],
                [-11.79229068799998, 15.025210381000136],
                [-11.792404175999877, 15.024295808000034],
                [-11.792952536999962, 15.019846917000109],
                [-11.793000220999886, 15.019471169000099],
                [-11.79332828399987, 15.018500329000062],
                [-11.798780441999952, 15.002395629000148],
                [-11.799591064999959, 15.000000000000114],
                [-11.801480293999873, 14.994429588000173],
                [-11.802096366999876, 14.991181374000178],
                [-11.806675911999889, 14.967016221000108],
                [-11.806879996999896, 14.965941429000168],
                [-11.807208061999972, 14.964900017000161],
                [-11.813780784999949, 14.944041252000147],
                [-11.815309523999872, 14.939061164000179],
                [-11.816989897999918, 14.928951263000101],
                [-11.815331459999925, 14.922288895000065],
                [-11.809372900999961, 14.908953668000038],
                [-11.80609989199985, 14.901631355000063],
                [-11.806433677999905, 14.900650024000129],
                [-11.807741164999925, 14.896809579000035],
                [-11.812701224999898, 14.89290237400013],
                [-11.823891638999953, 14.889442444000053],
                [-11.826869963999911, 14.888521195000123],
                [-11.831819534999909, 14.885300637000114],
                [-11.835590361999891, 14.880241394999985],
                [-11.843536376999907, 14.870635987000185],
                [-11.847379683999861, 14.865989685000159],
                [-11.851620672999843, 14.861809731000164],
                [-11.869318961999966, 14.844371797000065],
                [-11.906828879999978, 14.821580886000049],
                [-11.919811248999963, 14.817891121],
                [-11.93043231899992, 14.816731454000092],
                [-11.936799049999934, 14.813739775999977],
                [-11.936878203999981, 14.813623428000142],
                [-11.939148901999943, 14.810218811000084],
                [-11.940329551999923, 14.808450699000161],
                [-11.944320678999929, 14.790060044000029],
                [-11.948081015999946, 14.783169746000169],
                [-11.956101416999957, 14.775571823000064],
                [-11.978932380999936, 14.761560441000029],
                [-11.979542731999913, 14.761477470000159],
                [-11.991238592999878, 14.759900093000113],
                [-12.00022125199996, 14.762869835000174],
                [-12.007538794999959, 14.76286125200005],
                [-12.022871017999876, 14.758699417000059],
                [-12.03987121699987, 14.759590149000076],
                [-12.047180176999916, 14.758199691000129],
                [-12.048515319999922, 14.754880906000039],
                [-12.049769400999935, 14.751758575000053],
                [-12.0445499409999, 14.739600182000117],
                [-12.04971027299996, 14.723280907000174],
                [-12.054421423999941, 14.7179803840001],
                [-12.056989668999961, 14.715551376],
                [-12.062081337999814, 14.717201233000026],
                [-12.063237189999882, 14.717853546000072],
                [-12.080819130999942, 14.727792739000108],
                [-12.111809729999948, 14.745311737000122],
                [-12.122360229999913, 14.760560989000112],
                [-12.128190040999982, 14.768989564000151],
                [-12.136100768999938, 14.772838592000141],
                [-12.136239051999951, 14.772854805000122],
                [-12.140061378999917, 14.773296356000174],
                [-12.145718573999943, 14.773948669000049],
                [-12.152303694999944, 14.772471428000131],
                [-12.153071403999888, 14.772298812000031],
                [-12.153271675999861, 14.772192955000094],
                [-12.180229186999952, 14.75802040100001],
                [-12.205679893999957, 14.758581162000155],
                [-12.220951079999963, 14.756941795000103],
                [-12.23622131299993, 14.758051872000067],
                [-12.238911628999915, 14.759259224000118],
                [-12.239056587999926, 14.759916307000026],
                [-12.241158485999961, 14.76439762199999],
                [-12.241957663999926, 14.764674187000082],
                [-12.247150419999855, 14.766473771000108],
                [-12.252297400999907, 14.767516136000097],
                [-12.253100395999923, 14.767678261000128],
                [-12.258981703999893, 14.768869401000075],
                [-12.267628669999965, 14.769731522000086],
                [-12.269488333999959, 14.769917488000033],
                [-12.27576351099998, 14.770560265000029],
                [-12.280482291999931, 14.772961616000032],
                [-12.284031867999943, 14.775349617000131],
                [-12.285463332999882, 14.777402879000022],
                [-12.287980079999841, 14.781011583],
                [-12.290923118999899, 14.785240173000147],
                [-12.291800499999908, 14.786653519000026],
                [-12.293501853999942, 14.789394378000054],
                [-12.30361652299996, 14.798611640000104],
                [-12.310735701999874, 14.807865143000015],
                [-12.314101219999884, 14.813448904999973],
                [-12.317016600999978, 14.81683540400013],
                [-12.325899124999978, 14.824906350000049],
                [-12.33801555499997, 14.827874184000109],
                [-12.346364020999943, 14.827970506000099],
                [-12.3481884, 14.827991487000133],
                [-12.350855826999975, 14.828022003000171],
                [-12.353865622999933, 14.828056335000156],
                [-12.363076209999974, 14.830989837000061],
                [-12.366954803999931, 14.832320214000106],
                [-12.377021789999901, 14.835775376000129],
                [-12.38325595799995, 14.840049744000169],
                [-12.39127063799998, 14.84252834300014],
                [-12.395241736999935, 14.844056130000183],
                [-12.396242140999959, 14.844440460000158],
                [-12.39811420399991, 14.84569835700006],
                [-12.399840354999924, 14.846858026000177],
                [-12.402779579999958, 14.848833083999978],
                [-12.407196044999978, 14.852693559000102],
                [-12.413373946999968, 14.856866837000098],
                [-12.4133634559999, 14.856510163000053],
                [-12.4209537509999, 14.863109590000136],
                [-12.430123328999912, 14.873311043000172],
                [-12.435884475999899, 14.879044534],
                [-12.43906784099994, 14.88241863200011],
                [-12.439319609999927, 14.882455826000182],
                [-12.446781158999897, 14.887475967000171],
                [-12.451050758999941, 14.891083718000118],
                [-12.453158377999898, 14.892865182000094],
                [-12.454345703999934, 14.895241738000095],
                [-12.454081535999819, 14.897729874000106],
                [-12.454070090999949, 14.89783954699999],
                [-12.453333853999936, 14.900240897000117],
                [-12.453243254999904, 14.900535584000068],
                [-12.453058242999873, 14.901137352000092],
                [-12.452504157999954, 14.903007507000041],
                [-12.452046393999922, 14.904553414000134],
                [-12.45194244399994, 14.904723167000043],
                [-12.451016425999967, 14.906238556000119],
                [-12.449319838999941, 14.909013748000064],
                [-12.449269293999976, 14.909867286000178],
                [-12.449016571999948, 14.914092064000158],
                [-12.450070380999932, 14.917531015000179],
                [-12.451744079999969, 14.919913292000047],
                [-12.453172683999924, 14.922410966000086],
                [-12.455554007999979, 14.936236382000061],
                [-12.456075667999869, 14.941888809000091],
                [-12.457280158999879, 14.954957009000111],
                [-12.457654953999963, 14.957221985000103],
                [-12.458539007999946, 14.96257305099999],
                [-12.459788321999952, 14.970975877000171],
                [-12.4605426789999, 14.975712775999966],
                [-12.460844040999973, 14.977605820000122],
                [-12.461545943999965, 14.982006074000026],
                [-12.463133810999977, 14.984514236000109],
                [-12.468446731999904, 14.991390228000057],
                [-12.473773956999878, 14.997085571000071],
                [-12.477616309999917, 15.001603127000124],
                [-12.478163719999941, 15.002245902000141],
                [-12.483904838999877, 15.007159233000152],
                [-12.492325782999956, 15.013675690000071],
                [-12.501320839999892, 15.017182350000155],
                [-12.50376892, 15.017210006000141],
                [-12.505226135999976, 15.017225266000139],
                [-12.505366324999954, 15.017227174000141],
                [-12.508732794999958, 15.017657279000105],
                [-12.512268065999933, 15.019095422000191],
                [-12.513699531999976, 15.01967811600008],
                [-12.519210815999884, 15.021310806000145],
                [-12.523845672999926, 15.022041322],
                [-12.529038428999968, 15.022860528000137],
                [-12.530392646999928, 15.023251534000167],
                [-12.539773941999954, 15.025967599000069],
                [-12.540330885999879, 15.026128769000081],
                [-12.540555000999859, 15.026222228000108],
                [-12.544196128999943, 15.027747155000043],
                [-12.548261641999886, 15.029449464000152],
                [-12.55093574499989, 15.030569077000109],
                [-12.557760239999936, 15.035361291000129],
                [-12.566315650999854, 15.042139054000074],
                [-12.575567244999888, 15.050229073000025],
                [-12.589221953999925, 15.061237336000033],
                [-12.591543197999954, 15.064334869000049],
                [-12.592029570999955, 15.064985275000083],
                [-12.592363356999954, 15.065429688000165],
                [-12.593657493999956, 15.067156791000116],
                [-12.594355581999878, 15.06808948500003],
                [-12.596944808999979, 15.071496964000175],
                [-12.601842879999936, 15.078141213000038],
                [-12.607657432999929, 15.08277511599999],
                [-12.613164900999891, 15.085396766000031],
                [-12.617262840999956, 15.087529183000186],
                [-12.621185301999958, 15.089311600000144],
                [-12.62743091699997, 15.091640472999984],
                [-12.63313579499993, 15.094311714000128],
                [-12.637299536999933, 15.095259666000061],
                [-12.646873472999971, 15.099852563000184],
                [-12.650639533999936, 15.101831436000055],
                [-12.656673431999934, 15.105002404000061],
                [-12.659875869999951, 15.107125282000027],
                [-12.666311263999944, 15.108584405000045],
                [-12.671151160999955, 15.108460427000068],
                [-12.67474079099992, 15.107975006000174],
                [-12.678519248999919, 15.106620788000043],
                [-12.681586264999964, 15.104737282000144],
                [-12.684666633999825, 15.101634979000039],
                [-12.685956954999824, 15.098340035000092],
                [-12.686718939999878, 15.094169616999977],
                [-12.686742781999897, 15.091905593999968],
                [-12.68641471899997, 15.089117050000027],
                [-12.686798095999961, 15.08685779700005],
                [-12.687828064999906, 15.086188316000175],
                [-12.687831879999976, 15.085751533000177],
                [-12.68934631299993, 15.084286689000123],
                [-12.69153976399997, 15.084322929999985],
                [-12.694196700999896, 15.086725236000177],
                [-12.695528030999924, 15.089205742000047],
                [-12.695214270999884, 15.092255592000186],
                [-12.69621944499994, 15.094960214000139],
                [-12.698816299999862, 15.098737716000073],
                [-12.702322005999918, 15.09995555800009],
                [-12.713870047999933, 15.103335381000079],
                [-12.726017951999893, 15.109389305000036],
                [-12.734910965999916, 15.116840362000062],
                [-12.740651129999947, 15.123586655],
                [-12.746048927999936, 15.12988567299999],
                [-12.746842384999923, 15.13037681600008],
                [-12.750100134999911, 15.132392882999966],
                [-12.75529193899996, 15.136693002000072],
                [-12.778841018999856, 15.144040109000173],
                [-12.782504080999956, 15.146324158000084],
                [-12.789874076999865, 15.150918961],
                [-12.791901588999849, 15.154912949000106],
                [-12.793440818999841, 15.157945633],
                [-12.793881416999966, 15.165699006000182],
                [-12.792015076999974, 15.17431163800012],
                [-12.791725157999906, 15.174973488000148],
                [-12.791687011999954, 15.175060273000156],
                [-12.78778648399998, 15.183959962000131],
                [-12.786379814999918, 15.192354203000036],
                [-12.789544105999937, 15.199587822000183],
                [-12.792931556999974, 15.206867218000127],
                [-12.796270370999935, 15.209671022000123],
                [-12.80096244899994, 15.213044167000021],
                [-12.807733535999887, 15.215291023000134],
                [-12.815185546999885, 15.214674949000141],
                [-12.822225569999944, 15.21376800600018],
                [-12.828463554999928, 15.210557938000136],
                [-12.832052230999977, 15.206289291000132],
                [-12.834501266999894, 15.203673363000064],
                [-12.836404799999912, 15.20242977200013],
                [-12.838437079999949, 15.200097083000117],
                [-12.842170714999952, 15.198985101000119],
                [-12.845948218999979, 15.1994228370001],
                [-12.84913444599988, 15.199854852000044],
                [-12.851594923999926, 15.201454162000061],
                [-12.852539062999938, 15.202068328000053],
                [-12.854730605999919, 15.206069946000014],
                [-12.854887007999878, 15.207148551000046],
                [-12.855281828999978, 15.209864616000118],
                [-12.857392310999955, 15.217343331000109],
                [-12.863156317999938, 15.225890160000063],
                [-12.870072364999942, 15.231520653000075],
                [-12.879069328999947, 15.235966682000139],
                [-12.887074470999892, 15.239197731000047],
                [-12.889132499999903, 15.240874291000011],
                [-12.892586706999964, 15.243495942000152],
                [-12.895855902999926, 15.24754238200012],
                [-12.898062705999905, 15.251407623000091],
                [-12.898916243999849, 15.254800796999973],
                [-12.898914335999962, 15.254833221000183],
                [-12.898602485999902, 15.261392593000039],
                [-12.8972854619999, 15.264195442000073],
                [-12.895483969999816, 15.267105103],
                [-12.891682624999873, 15.268905640000071],
                [-12.885886192999976, 15.26930999699999],
                [-12.879207609999924, 15.269304276000184],
                [-12.876083373999961, 15.268470765000018],
                [-12.874632835999932, 15.267288209000071],
                [-12.872714042999917, 15.264850616999979],
                [-12.872194288999879, 15.264344216000097],
                [-12.871688842999902, 15.263853073000064],
                [-12.869187354999951, 15.261416436000161],
                [-12.868467331999966, 15.260964393000165],
                [-12.867442130999962, 15.260321617999978],
                [-12.865633010999943, 15.259187699000051],
                [-12.864221572999952, 15.258301736000021],
                [-12.861307143999966, 15.25682449400017],
                [-12.856103896999969, 15.254544257000077],
                [-12.852696417999937, 15.253838539000128],
                [-12.852436065999939, 15.253784179000093],
                [-12.849388122999926, 15.254145623000113],
                [-12.848051069999883, 15.255749703000163],
                [-12.84799766499998, 15.25586795900017],
                [-12.847051620999878, 15.257970810000188],
                [-12.845543859999907, 15.259461403000159],
                [-12.843973158999972, 15.261398316000054],
                [-12.840026855999952, 15.265374184000052],
                [-12.838719367999943, 15.267283439000039],
                [-12.838593481999908, 15.267467500000066],
                [-12.83536148099995, 15.272188187000154],
                [-12.833628653999938, 15.278750419000062],
                [-12.833928107999895, 15.28063011200004],
                [-12.83513927499996, 15.288244249000059],
                [-12.839167593999889, 15.29498768000019],
                [-12.840393065999876, 15.297427177000145],
                [-12.844340323999972, 15.306438446000072],
                [-12.84697723499994, 15.311865806000071],
                [-12.850083350999967, 15.315380098000105],
                [-12.85373687699996, 15.318029404000072],
                [-12.859480857999927, 15.321054458999981],
                [-12.86266613, 15.322732925000139],
                [-12.863792419999982, 15.323380470000188],
                [-12.868169784999964, 15.325899123999989],
                [-12.870520590999945, 15.327250481000021],
                [-12.878546713999981, 15.33193779100003],
                [-12.882311820999917, 15.33400249499999],
                [-12.889528273999872, 15.336652757000081],
                [-12.895143508999979, 15.336777688000041],
                [-12.903908729999841, 15.336530686],
                [-12.908524513999907, 15.336400985000012],
                [-12.91870880099998, 15.337930680000056],
                [-12.923641204999967, 15.339738846999978],
                [-12.925868033999905, 15.341064454000161],
                [-12.929849624999918, 15.34343433500004],
                [-12.931398390999959, 15.344355583000038],
                [-12.935015677999957, 15.348123549999968],
                [-12.940286636999815, 15.353117944000019],
                [-12.943432806999965, 15.356593132000171],
                [-12.947360038999932, 15.363156319000097],
                [-12.94842624599994, 15.367468834000135],
                [-12.947651862999919, 15.376921654000114],
                [-12.947587013999964, 15.377717972000085],
                [-12.947428703999947, 15.381612779000022],
                [-12.947252273999879, 15.385942458999978],
                [-12.947099685999888, 15.389690400000063],
                [-12.947419165999918, 15.402771950000101],
                [-12.947435379999945, 15.40345287299999],
                [-12.947335242999941, 15.40345287299999],
                [-12.948097227999824, 15.408635140000058],
                [-12.947379111999908, 15.414020538000159],
                [-12.947046278999892, 15.417734147000033],
                [-12.944565772999908, 15.421942711000099],
                [-12.93718433399988, 15.428113938000081],
                [-12.932074546999957, 15.431205750000117],
                [-12.930399893999947, 15.432218553000155],
                [-12.928778647999934, 15.433199884000089],
                [-12.925322532999871, 15.438761711999973],
                [-12.925290106999967, 15.441988946000038],
                [-12.927034378999963, 15.445016860000123],
                [-12.929763793999939, 15.445688249000114],
                [-12.933382033999976, 15.446224214000154],
                [-12.939018249999947, 15.444626809000056],
                [-12.941893577999963, 15.443106651000051],
                [-12.948369025999909, 15.443102838000186],
                [-12.948466300999883, 15.442179679999981],
                [-12.951984404999905, 15.441949844000021],
                [-12.955300330999933, 15.442465783000159],
                [-12.957374572999981, 15.442789078000146],
                [-12.958338737999952, 15.443686485000057],
                [-12.960690497999963, 15.445875168000043],
                [-12.961269377999884, 15.446413993000135],
                [-12.962473868999894, 15.45074272100004],
                [-12.962525366999898, 15.451187134000122],
                [-12.962956428999973, 15.454888345000029],
                [-12.961427687999958, 15.459775924000041],
                [-12.960453986999937, 15.462093353],
                [-12.960663794999959, 15.464265824000108],
                [-12.960071563999975, 15.469435692000104],
                [-12.960226058999979, 15.475652695000122],
                [-12.961658478999937, 15.481559753000056],
                [-12.963180541999918, 15.487446786000135],
                [-12.963120459999914, 15.491568566000126],
                [-12.963132857999881, 15.494168281000043],
                [-12.963132857999881, 15.49421119800013],
                [-12.963149070999975, 15.497569085],
                [-12.964253424999981, 15.500452043],
                [-12.967031477999967, 15.501576423000131],
                [-12.968756675999884, 15.501303673000109],
                [-12.972271918999979, 15.499404908000145],
                [-12.972660063999911, 15.49912357300002],
                [-12.974771499999918, 15.497593879000135],
                [-12.976481437999894, 15.4970865250001],
                [-12.982004164999978, 15.495448113000066],
                [-12.992173195999897, 15.495600700000125],
                [-12.997226714999954, 15.495201112000132],
                [-13.00022983599996, 15.494964600000117],
                [-13.009110449999923, 15.489435195999988],
                [-13.009988785999951, 15.488888740999982],
                [-13.016142843999944, 15.483445168000173],
                [-13.020053862999816, 15.480491638000046],
                [-13.022411345999899, 15.479961395000146],
                [-13.036504745999935, 15.478826522000134],
                [-13.047620773999938, 15.477697372000137],
                [-13.052605627999924, 15.477696419000154],
                [-13.065689085999963, 15.481457710000029],
                [-13.077777861999834, 15.488687516000141],
                [-13.082163810999816, 15.490311623000139],
                [-13.087218284999949, 15.492216110000072],
                [-13.092996597999957, 15.493469237999989],
                [-13.099926947999961, 15.495293618000119],
                [-13.100752830999909, 15.495921135000117],
                [-13.103507042999979, 15.498012543000129],
                [-13.104572295999901, 15.502871514000105],
                [-13.104795455999977, 15.505681038000034],
                [-13.104563712999834, 15.506605149],
                [-13.104193687999953, 15.508081435000122],
                [-13.10290050499998, 15.512084008000102],
                [-13.10192012899995, 15.516857147000053],
                [-13.101154327999893, 15.52104091699999],
                [-13.099497794999877, 15.525894165000125],
                [-13.095575331999953, 15.534109115000035],
                [-13.09062290199995, 15.541741372000104],
                [-13.088509559999864, 15.546241761000033],
                [-13.08624267599987, 15.551314354000112],
                [-13.085910795999951, 15.555185318000099],
                [-13.086104392999971, 15.559032440000067],
                [-13.086380004999967, 15.560564042000181],
                [-13.087318419999974, 15.563015937000102],
                [-13.08827114199994, 15.564229011000066],
                [-13.090538024999944, 15.566663743000106],
                [-13.092298507999942, 15.568653106000113],
                [-13.094199179999976, 15.571108819000187],
                [-13.094408033999855, 15.572545051000191],
                [-13.09439563799998, 15.573907852000048],
                [-13.092979431999936, 15.57504272500006],
                [-13.089309692999961, 15.576465607000046],
                [-13.087847708999902, 15.577795982000055],
                [-13.087311743999976, 15.579728127000124],
                [-13.08737182699997, 15.581306459000189],
                [-13.089282988999912, 15.582613945000162],
                [-13.09245681799996, 15.583501817000069],
                [-13.099775312999952, 15.585937500999989],
                [-13.108540533999872, 15.589483262000044],
                [-13.120540618999883, 15.594492912000078],
                [-13.121125220999943, 15.594732285000077],
                [-13.127700806999826, 15.597433089999981],
                [-13.129841804999955, 15.598312378000116],
                [-13.134046553999951, 15.599938392000183],
                [-13.142971039999964, 15.603229523999971],
                [-13.156627654999966, 15.609859467000092],
                [-13.156804084999976, 15.609970092000083],
                [-13.160159109999938, 15.6120901110001],
                [-13.163239479999902, 15.613915444000042],
                [-13.168994903999931, 15.617328644],
                [-13.176557540999966, 15.621360779000156],
                [-13.18137073399987, 15.623934746000032],
                [-13.186491011999863, 15.625267982000025],
                [-13.191568374999918, 15.625931740000055],
                [-13.195121765999943, 15.625864982000053],
                [-13.199086188999956, 15.624280930000054],
                [-13.20081710799991, 15.62352085200007],
                [-13.203303336999852, 15.621341705000134],
                [-13.205694198999879, 15.618779182000139],
                [-13.206657408999888, 15.617326737000155],
                [-13.207744597999977, 15.615687370000103],
                [-13.209936142999936, 15.613314629000172],
                [-13.212325096999962, 15.610942840000121],
                [-13.214312552999843, 15.609333039999967],
                [-13.217079162999937, 15.608972549999976],
                [-13.217936514999906, 15.608866693000039],
                [-13.21999359199998, 15.608613968000157],
                [-13.222457884999926, 15.608968735000133],
                [-13.225653648999923, 15.610285759000021],
                [-13.228203773999894, 15.612171173000036],
                [-13.229834556999833, 15.61465072600015],
                [-13.230068205999885, 15.615006448000088],
                [-13.231326102999958, 15.617544174999978],
                [-13.233601568999859, 15.624090194000075],
                [-13.235913276999895, 15.630707741000094],
                [-13.238752365999972, 15.636325837000072],
                [-13.241134644999931, 15.639035224000111],
                [-13.242192268999929, 15.64087295500002],
                [-13.243865967999966, 15.643045425000025],
                [-13.246037481999963, 15.645709037000131],
                [-13.24928188399997, 15.650488853000013],
                [-13.250349043999904, 15.652342797000131],
                [-13.251791000999901, 15.655238151000049],
                [-13.252299307999976, 15.658025742000063],
                [-13.252746581999929, 15.661711694000019],
                [-13.252867698999921, 15.66423988300005],
                [-13.252917288999924, 15.665555954000126],
                [-13.253014563999898, 15.668107986000109],
                [-13.252610206999918, 15.67013072900005],
                [-13.252370833999919, 15.671331405000046],
                [-13.251749038999833, 15.672490119000031],
                [-13.250678061999963, 15.674486160000072],
                [-13.245837211999969, 15.677794458000051],
                [-13.242653845999939, 15.677757263999979],
                [-13.238694190999979, 15.676074982000046],
                [-13.235716820999812, 15.674796105000041],
                [-13.235177992999979, 15.674564362000126],
                [-13.228986739999925, 15.67272091000001],
                [-13.224454879999939, 15.672787666000033],
                [-13.223741531999906, 15.672798156],
                [-13.221236229999931, 15.673331261000158],
                [-13.219184875999929, 15.674261092000108],
                [-13.217816351999943, 15.675383567000154],
                [-13.215223312999967, 15.678384781000148],
                [-13.212878227999909, 15.682401656000025],
                [-13.211636542999941, 15.684399605000067],
                [-13.210742950999816, 15.686301231000016],
                [-13.209340094999902, 15.688838958000133],
                [-13.207972525999935, 15.692775726999969],
                [-13.207950591999975, 15.69532108400017],
                [-13.20881557499996, 15.696546554000122],
                [-13.211579321999977, 15.698487282000144],
                [-13.215668678999975, 15.70136261],
                [-13.219752312999958, 15.70479011499998],
                [-13.224530218999973, 15.709433555000146],
                [-13.227719305999869, 15.712972641000079],
                [-13.2327842709999, 15.717553139000131],
                [-13.245892524999931, 15.729778291000059],
                [-13.250751494999975, 15.73332500500004],
                [-13.263174056999958, 15.741362572000071],
                [-13.275730132999911, 15.751409530000046],
                [-13.28283405399992, 15.758111954000015],
                [-13.284504889999937, 15.759081842000171],
                [-13.28715229099987, 15.761302948000093],
                [-13.289030073999925, 15.763074874000154],
                [-13.290678978999949, 15.764725686000133],
                [-13.291957855999954, 15.766533851000077],
                [-13.29290199299993, 15.769391060000146],
                [-13.292870521999873, 15.770825387000059],
                [-13.292293548999851, 15.773594857000091],
                [-13.292213440999944, 15.778548241000124],
                [-13.290973662999875, 15.782690048000063],
                [-13.289929388999894, 15.78465175700012],
                [-13.289340018999894, 15.785503388000109],
                [-13.287650108999969, 15.787942887000099],
                [-13.285580634999974, 15.789648057000136],
                [-13.283007621999957, 15.790297509000027],
                [-13.28006839699998, 15.789757729000144],
                [-13.277298926999947, 15.78780365],
                [-13.275108336999892, 15.785262109000143],
                [-13.273023605999981, 15.784173964999979],
                [-13.272207260999949, 15.783945084000152],
                [-13.27063846499982, 15.78350448600014],
                [-13.268899917999931, 15.783529282000018],
                [-13.267198562999965, 15.783802033000143],
                [-13.266142843999887, 15.784846307],
                [-13.265300749999938, 15.786350250000055],
                [-13.265325545999872, 15.788014413000155],
                [-13.26585483599996, 15.790888787000142],
                [-13.266242025999929, 15.791829109000105],
                [-13.267804146999879, 15.793926240000189],
                [-13.273678779999955, 15.797740936000139],
                [-13.282917974999918, 15.804960252000058],
                [-13.287606239999889, 15.808164597000086],
                [-13.294827460999898, 15.814991952000184],
                [-13.297521591999953, 15.818572045000053],
                [-13.299478530999977, 15.823752403000185],
                [-13.301882743999954, 15.837543488000051],
                [-13.301541327999928, 15.842272758000036],
                [-13.301289558999883, 15.846506119000082],
                [-13.299150465999901, 15.854092599000012],
                [-13.298290252999948, 15.859537124000099],
                [-13.298186302999966, 15.864164353000092],
                [-13.298285484999951, 15.864086152000084],
                [-13.299833297999896, 15.870016098000121],
                [-13.301983833999941, 15.872758866000027],
                [-13.306488036999951, 15.876833917000113],
                [-13.306569098999887, 15.876907348000088],
                [-13.308432577999895, 15.877994537000063],
                [-13.317232132999948, 15.882195473000024],
                [-13.323552131999975, 15.885113715000045],
                [-13.328957557999956, 15.891238212000133],
                [-13.329151153999874, 15.897815705000141],
                [-13.329104423999922, 15.897895814000094],
                [-13.324267387999953, 15.906180382000059],
                [-13.322691916999872, 15.907541276000075],
                [-13.318473815999937, 15.911185264000096],
                [-13.318246840999961, 15.911405564000063],
                [-13.31357097699987, 15.91596698799998],
                [-13.312541960999908, 15.919057846000101],
                [-13.312098502999902, 15.921316147000027],
                [-13.313056944999971, 15.924957275000168],
                [-13.315258025999924, 15.927652359000035],
                [-13.318165778999912, 15.928631782000025],
                [-13.325452803999951, 15.928646088000107],
                [-13.328346251999903, 15.929520605999983],
                [-13.337268828999981, 15.934313773000042],
                [-13.338318823999941, 15.9349536900001],
                [-13.341917991999935, 15.93715000100002],
                [-13.342918396999892, 15.938037873000155],
                [-13.345613479999884, 15.940433502000189],
                [-13.347476004999976, 15.942088127000034],
                [-13.349217414999828, 15.943719864000172],
                [-13.351760863999971, 15.946824074000119],
                [-13.358445166999957, 15.95706367400004],
                [-13.359839439999917, 15.959925651000162],
                [-13.360968589999914, 15.962241172000176],
                [-13.362395286999913, 15.967366219000098],
                [-13.364689826999893, 15.973070144000133],
                [-13.365991592999933, 15.977436066000053],
                [-13.366375922999964, 15.981141090000051],
                [-13.365268706999927, 15.986039162000111],
                [-13.363800047999973, 15.992797851000034],
                [-13.363766670999894, 15.995637894000083],
                [-13.36439323299993, 15.997943878000115],
                [-13.367691039999954, 16.002088547000142],
                [-13.369597434999946, 16.004484176],
                [-13.372092245999966, 16.007320404000041],
                [-13.373624800999949, 16.00898170500011],
                [-13.374332428999935, 16.009748458000047],
                [-13.377448081999887, 16.01333046100018],
                [-13.377840042999935, 16.019720078000148],
                [-13.377920151999888, 16.021022796000125],
                [-13.377063751999913, 16.031518936000111],
                [-13.376584051999941, 16.03497505200005],
                [-13.375415801999964, 16.046602250000035],
                [-13.374959945999933, 16.053251267000121],
                [-13.377262115999883, 16.055744171000129],
                [-13.378868103999878, 16.057483673000036],
                [-13.386520384999869, 16.061429977000046],
                [-13.400849341999901, 16.065700532000164],
                [-13.407836913999972, 16.068553926000163],
                [-13.42568302199993, 16.075334549000161],
                [-13.42798709799996, 16.076210022],
                [-13.438193320999972, 16.08054733300014],
                [-13.440218925999886, 16.081510543000093],
                [-13.444564819999925, 16.083574295000119],
                [-13.450899123999932, 16.09039688199999],
                [-13.45497226699996, 16.09526825],
                [-13.45625591299995, 16.103372575000094],
                [-13.457189558999914, 16.106622695000056],
                [-13.457420349999893, 16.107427598000129],
                [-13.459952353999881, 16.108747482000126],
                [-13.461746214999891, 16.108760835000055],
                [-13.468120575999933, 16.106204988000059],
                [-13.475372313999969, 16.098669052000048],
                [-13.47984409299994, 16.090131760000133],
                [-13.483099937999953, 16.084775924000155],
                [-13.484590529999934, 16.08232498100017],
                [-13.486678122999876, 16.08149147],
                [-13.490455627999893, 16.079984664000051],
                [-13.490498541999898, 16.079980851000187],
                [-13.491396903999885, 16.079896927000163],
                [-13.495244979999939, 16.079538346000049],
                [-13.498827934999952, 16.080589295000038],
                [-13.498849764999875, 16.080596086000185],
                [-13.498870849999946, 16.080602645999988],
                [-13.501399385999889, 16.082702523000137],
                [-13.502313613999945, 16.083461762000127],
                [-13.504275838999945, 16.085221577000027],
                [-13.50566959299988, 16.086471558000142],
                [-13.505773872999896, 16.086565189000169],
                [-13.50661277699993, 16.087318420000031],
                [-13.508941629999981, 16.08978712000004],
                [-13.509264946999906, 16.090129852000132],
                [-13.510112682999875, 16.09102788199999],
                [-13.511949539999932, 16.092973710000081],
                [-13.513773638999851, 16.097186528000123],
                [-13.51426029299995, 16.09831047],
                [-13.514521980999973, 16.10178785100004],
                [-13.514597891999927, 16.102796555000054],
                [-13.514363169999967, 16.104210642000055],
                [-13.513742446999913, 16.107950210000013],
                [-13.509706496999968, 16.114227296000024],
                [-13.508850275999976, 16.115557689000013],
                [-13.508625030999895, 16.115907670000126],
                [-13.501795892999837, 16.12111075700011],
                [-13.498984203999896, 16.123252907999984],
                [-13.492746794999846, 16.125061156000072],
                [-13.481878280999865, 16.128211976000159],
                [-13.480973588999973, 16.128636595000046],
                [-13.475982000999977, 16.13098],
                [-13.475076432999913, 16.133789191],
                [-13.474905046999936, 16.134321109000155],
                [-13.477080137999963, 16.139218949000053],
                [-13.477701061999937, 16.14061708800017],
                [-13.479548650999845, 16.14322928100006],
                [-13.480648992999932, 16.144784928000036],
                [-13.480656756999963, 16.144795945000169],
                [-13.480761, 16.144943000000183],
                [-13.480879, 16.144981000000143],
                [-13.483043000999942, 16.145689001000164],
                [-13.487671, 16.147203000000104],
                [-13.489223000999971, 16.146317999000189],
                [-13.49265399899997, 16.142636999000047],
                [-13.49639417599991, 16.140301564000026],
                [-13.500607490999926, 16.137670517000174],
                [-13.507763863999969, 16.131862640000065],
                [-13.511224745999982, 16.129425049000076],
                [-13.51198699299988, 16.128888054000129],
                [-13.515410421999832, 16.126476288000049],
                [-13.519705120999902, 16.125151136000056],
                [-13.522043227999916, 16.124429702000043],
                [-13.526901971999962, 16.125045029000034],
                [-13.527992248999908, 16.125183105000076],
                [-13.533035277999886, 16.12665748600017],
                [-13.536734623999905, 16.128650883000148],
                [-13.539442062999854, 16.130109788000141],
                [-13.541928311999868, 16.130957120000119],
                [-13.544546, 16.131849001000091],
                [-13.546927091999919, 16.132660239000074],
                [-13.548572540999828, 16.133220674000142],
                [-13.551124474999938, 16.133705422000105],
                [-13.55414005199998, 16.134277811000118],
                [-13.554471042999978, 16.134340834000113],
                [-13.554871558999935, 16.134416580000163],
                [-13.554882926999881, 16.134374895000178],
                [-13.560623167999836, 16.135122300000091],
                [-13.563087463999977, 16.134908676],
                [-13.566520691999926, 16.134609224000087],
                [-13.575800894999929, 16.132972717000143],
                [-13.581755637999947, 16.13117408800008],
                [-13.587342262999982, 16.12748909000004],
                [-13.594968794999943, 16.124416351000093],
                [-13.602035521999881, 16.121246338000049],
                [-13.602741241999922, 16.120929718000184],
                [-13.6105976099999, 16.117391587000043],
                [-13.617761610999878, 16.113693238000053],
                [-13.618144035999933, 16.113494874000139],
                [-13.619441986999902, 16.112834931000179],
                [-13.624201774999847, 16.110410690000037],
                [-13.625795364999874, 16.109624863000136],
                [-13.629242895999937, 16.107927323000183],
                [-13.630687713999862, 16.107189179000045],
                [-13.636444090999817, 16.106262207999976],
                [-13.643763541999931, 16.106262207999976],
                [-13.650264738999965, 16.10772705200003],
                [-13.658381461999909, 16.109157563],
                [-13.66123676299992, 16.108715058000087],
                [-13.664811134999979, 16.107221603000085],
                [-13.66696357599983, 16.105075837000129],
                [-13.66922187699987, 16.101594923999983],
                [-13.673389434999933, 16.09733390700012],
                [-13.673611639999933, 16.097087861000034],
                [-13.673679350999919, 16.09701347399999],
                [-13.674018858999943, 16.096817018000081],
                [-13.677599906999944, 16.09474754300004],
                [-13.678223608999872, 16.094387054000094],
                [-13.681743620999953, 16.095012666],
                [-13.683382987999892, 16.09579086299999],
                [-13.684592246999955, 16.096363067000084],
                [-13.686585425999908, 16.097953796000184],
                [-13.690644263999957, 16.101350785000136],
                [-13.692428588999974, 16.10291290300006],
                [-13.695027350999965, 16.105186463000052],
                [-13.696200370999918, 16.106287002000101],
                [-13.702280043999906, 16.111431122999988],
                [-13.709024429999886, 16.11999511700003],
                [-13.715420721999863, 16.129503250000084],
                [-13.71758461099995, 16.135736466000139],
                [-13.719435691999877, 16.141324996000151],
                [-13.719903946999978, 16.143955232000167],
                [-13.718838691999963, 16.146753311000111],
                [-13.718619346999844, 16.147327423000036],
                [-13.715977667999937, 16.15027046299997],
                [-13.711340902999893, 16.153715134000151],
                [-13.708602904999907, 16.156469346000165],
                [-13.704148292999946, 16.161792754999965],
                [-13.703122137999969, 16.164398194000171],
                [-13.702617644999862, 16.16567993200016],
                [-13.70201301599991, 16.16901016300011],
                [-13.702660560999959, 16.173896790000072],
                [-13.703781128999935, 16.176860809000061],
                [-13.704332351999881, 16.178319932000079],
                [-13.707813262999935, 16.180921554000065],
                [-13.712422370999946, 16.181842804999974],
                [-13.717817306999905, 16.181406021999976],
                [-13.721670149999909, 16.178987502999973],
                [-13.726607322999939, 16.174322129000132],
                [-13.730523109999865, 16.169651031],
                [-13.732828139999981, 16.166191101000095],
                [-13.735873222999942, 16.160903931000178],
                [-13.739485739999964, 16.157155990000092],
                [-13.741909980999935, 16.1548595430001],
                [-13.744957923999948, 16.152067184000032],
                [-13.747321128999829, 16.150049210000077],
                [-13.753898619999973, 16.146858215000123],
                [-13.7541580199999, 16.146766663000164],
                [-13.76015949299989, 16.144622803000061],
                [-13.765889166999955, 16.14345932100008],
                [-13.773232460999964, 16.141231537000124],
                [-13.778367996999975, 16.141021728999988],
                [-13.783556936999958, 16.142007828000146],
                [-13.78843784299994, 16.142992019000133],
                [-13.789191245999916, 16.143106462000105],
                [-13.791713714999901, 16.14348983799999],
                [-13.795735359999867, 16.143213272000025],
                [-13.798214911999935, 16.14233017],
                [-13.802046775999827, 16.14046096800007],
                [-13.803302764999898, 16.139848709000091],
                [-13.812171935999856, 16.135772706000125],
                [-13.820620536999968, 16.131034851000038],
                [-13.822711944999924, 16.129518510000082],
                [-13.826513289999923, 16.126764299],
                [-13.829716682999958, 16.123188019000054],
                [-13.832801819999872, 16.119743347000167],
                [-13.833791733999874, 16.118305207000162],
                [-13.836980819999951, 16.113668442000119],
                [-13.841027259999919, 16.109205246000158],
                [-13.841798781999898, 16.108467102000191],
                [-13.843576431999907, 16.106767654000066],
                [-13.844677925999918, 16.106184007000024],
                [-13.84638023399998, 16.10589408800007],
                [-13.848047256999962, 16.106351852000103],
                [-13.849309920999929, 16.10719490200006],
                [-13.85047721799998, 16.10854721000004],
                [-13.851542472999881, 16.111602783000023],
                [-13.852813719999915, 16.116331099999968],
                [-13.853877066999871, 16.120105744],
                [-13.854156492999891, 16.123352051000097],
                [-13.854958532999945, 16.125865937000128],
                [-13.855840681999894, 16.127305984000145],
                [-13.857469557999934, 16.128105164000033],
                [-13.861063956999885, 16.129020692000097],
                [-13.864662169999974, 16.129325866000102],
                [-13.866586684999959, 16.13027000500017],
                [-13.867320059999884, 16.131744385000104],
                [-13.867303848999882, 16.132472991000043],
                [-13.867220877999955, 16.136156082000014],
                [-13.867754934999823, 16.140104294000025],
                [-13.869262695999964, 16.142587663000086],
                [-13.872353552999982, 16.147232055000131],
                [-13.873713493999901, 16.148395538000045],
                [-13.876714706999962, 16.151479722000147],
                [-13.878014563999955, 16.153305055000089],
                [-13.878570556999875, 16.15522194000016],
                [-13.878767965999941, 16.158075333000056],
                [-13.87882232599992, 16.158857345000115],
                [-13.87845897699998, 16.162200927000185],
                [-13.877690315999871, 16.166481018000184],
                [-13.876950263999902, 16.169101715000068],
                [-13.876131057999885, 16.17101669300007],
                [-13.874734877999856, 16.172779083000023],
                [-13.873740195999972, 16.17356300400013],
                [-13.871724128999972, 16.175657272000024],
                [-13.869432448999873, 16.178371430000084],
                [-13.868060111999853, 16.180324554000038],
                [-13.867578506999962, 16.182306289000167],
                [-13.867787361999945, 16.184770584000034],
                [-13.869128226999919, 16.188270569000167],
                [-13.870111465999912, 16.189495086000136],
                [-13.872497557999907, 16.192018509000093],
                [-13.876405715999965, 16.195667267000033],
                [-13.877632140999935, 16.196517945000096],
                [-13.879090308999878, 16.197528840000132],
                [-13.882053374999828, 16.198711395000032],
                [-13.884744643999909, 16.198694229000182],
                [-13.887722968999981, 16.197275163000029],
                [-13.891760824999949, 16.19442749000001],
                [-13.892603874999963, 16.193899155000111],
                [-13.895455360999904, 16.192113876000121],
                [-13.897138594999831, 16.191461563000018],
                [-13.899453162999919, 16.190820695000127],
                [-13.901713370999971, 16.190532684000175],
                [-13.905633924999904, 16.192018509000093],
                [-13.91013813099994, 16.194223405000116],
                [-13.913379667999891, 16.196775437000042],
                [-13.915401457999849, 16.199123382000096],
                [-13.916872978999834, 16.201702118000128],
                [-13.917957304999845, 16.2070274350001],
                [-13.91869926499993, 16.212921143000131],
                [-13.919319153999879, 16.21824455300009],
                [-13.919672012999968, 16.22177505600007],
                [-13.919501304999869, 16.224584580000169],
                [-13.919392584999912, 16.227512360000105],
                [-13.919723509999869, 16.229217529999971],
                [-13.920617104999906, 16.22999954200003],
                [-13.922658919999833, 16.229980468000065],
                [-13.925080298999887, 16.22861862200017],
                [-13.927841185999966, 16.227376938000134],
                [-13.929481504999899, 16.22714615000001],
                [-13.930746078999903, 16.227151870000114],
                [-13.932480812999927, 16.227609633999975],
                [-13.934762, 16.229145051000103],
                [-13.93725681199993, 16.231430053000167],
                [-13.939222335999943, 16.234159469000076],
                [-13.940486907999912, 16.234973907000096],
                [-13.942437171999927, 16.234804154000187],
                [-13.946249007999825, 16.233249664000027],
                [-13.94823551199994, 16.23244094800009],
                [-13.95294761699995, 16.230819702000019],
                [-13.956643104999898, 16.228326798000182],
                [-13.962415695999937, 16.225126268000054],
                [-13.964642524999817, 16.223962784000037],
                [-13.966045378999979, 16.223230362000038],
                [-13.969825744999866, 16.222173692000183],
                [-13.97194385499995, 16.222297668000124],
                [-13.972879408999916, 16.222635269000079],
                [-13.9740781779999, 16.223621370000046],
                [-13.974028587999953, 16.224449159000073],
                [-13.972611425999958, 16.227628708000168],
                [-13.971177100999967, 16.233097075999979],
                [-13.970708846999912, 16.237310408999974],
                [-13.970529555999917, 16.245733261000055],
                [-13.970203400999935, 16.247781754000187],
                [-13.968466758999966, 16.251483918000076],
                [-13.966191292999952, 16.254615785000112],
                [-13.962464332999957, 16.258831025000177],
                [-13.959239959999934, 16.261871337],
                [-13.95638370599994, 16.264141083000084],
                [-13.952613830999951, 16.267206192000117],
                [-13.951923369999918, 16.26917648400007],
                [-13.951880454999923, 16.269298553000112],
                [-13.95263767299997, 16.271352769000089],
                [-13.954933165999819, 16.273023605000105],
                [-13.960299491999933, 16.275232316000029],
                [-13.966485023999951, 16.27622223000003],
                [-13.97145557399989, 16.276290893000123],
                [-13.976202011999931, 16.275920868000128],
                [-13.980731963999972, 16.275070191000168],
                [-13.982764243999952, 16.274562837000133],
                [-13.984590529999821, 16.274465560000181],
                [-13.986154555999974, 16.274641038000084],
                [-13.987222670999927, 16.275251389000061],
                [-13.988501547999931, 16.276870728],
                [-13.988850593999871, 16.278869629000155],
                [-13.988788603999978, 16.283784867],
                [-13.989160536999918, 16.290853500000026],
                [-13.989101408999943, 16.295228958000052],
                [-13.989898680999886, 16.298999787000184],
                [-13.990622519999931, 16.302843095000071],
                [-13.990912436999849, 16.304170609000096],
                [-13.990175246999968, 16.30598068200004],
                [-13.988888740999926, 16.307641982000177],
                [-13.987137793999977, 16.308673858000134],
                [-13.9824514399999, 16.30983352700008],
                [-13.976204871999926, 16.310916901000155],
                [-13.972862242999952, 16.310863495000035],
                [-13.968561171999966, 16.309335709000095],
                [-13.963780402999873, 16.307161332000192],
                [-13.96111011499994, 16.306394576000173],
                [-13.959795950999933, 16.306686401999968],
                [-13.957930565999845, 16.308256149000101],
                [-13.956826208999928, 16.310928346000139],
                [-13.95701313, 16.313129424000181],
                [-13.957347870999968, 16.315307616000098],
                [-13.959071158999961, 16.317300797000087],
                [-13.959743500999934, 16.318597793000094],
                [-13.962751388999948, 16.324056626000129],
                [-13.965208053999959, 16.327638626],
                [-13.9682617199999, 16.331073760000038],
                [-13.972307204999936, 16.334131240999966],
                [-13.976538657999981, 16.335153579000178],
                [-13.978375433999929, 16.335744859000101],
                [-13.981691360999946, 16.336547852000024],
                [-13.985863684999913, 16.337942124000051],
                [-13.988673210999934, 16.339361190999966],
                [-13.991228103999958, 16.34224319600014],
                [-13.994771956999955, 16.345579147000024],
                [-13.999994276999871, 16.347391129000016],
                [-14.002128601999914, 16.347885133000034],
                [-14.003899574999878, 16.348325729000067],
                [-14.010259627999972, 16.349464417000149],
                [-14.017098425999961, 16.349102020000089],
                [-14.022215842999969, 16.349472047000063],
                [-14.028157233999877, 16.350435257000186],
                [-14.029491423999957, 16.351015092000125],
                [-14.031121252999981, 16.352123261000031],
                [-14.032935142999975, 16.354843139000025],
                [-14.035237312999925, 16.362565995000068],
                [-14.037814138999977, 16.367601394000076],
                [-14.040968894999878, 16.371864319000053],
                [-14.041813850999972, 16.372888565000096],
                [-14.046658515999979, 16.37760925300006],
                [-14.051465988999951, 16.382545472],
                [-14.053014755999925, 16.383970261000059],
                [-14.054768562999982, 16.385583877000158],
                [-14.056756018999863, 16.387413025000171],
                [-14.057121275999975, 16.387739183000122],
                [-14.061862945999906, 16.391990662000069],
                [-14.066228865999904, 16.395956040000101],
                [-14.067440986999941, 16.398939133000113],
                [-14.068762778999883, 16.402389527000139],
                [-14.069774626999845, 16.403940202000058],
                [-14.074641226999972, 16.408908845000042],
                [-14.080566406999878, 16.41352653600012],
                [-14.08660507299993, 16.417642594000142],
                [-14.091574667999964, 16.420103074000167],
                [-14.096176146999937, 16.421594619000018],
                [-14.098440169999947, 16.422361375000037],
                [-14.102932929999952, 16.424221040000191],
                [-14.106585501999916, 16.427394866000043],
                [-14.108306884999877, 16.430223466000143],
                [-14.108613966999883, 16.433549882000023],
                [-14.108750342999826, 16.436395645000061],
                [-14.111304282999924, 16.441453935000084],
                [-14.114363670999921, 16.444456101000128],
                [-14.119155882999962, 16.447967530000142],
                [-14.124621391999938, 16.450382233000028],
                [-14.130141257999924, 16.45193481400014],
                [-14.136466026999926, 16.453010559000063],
                [-14.138361930999963, 16.453691483000057],
                [-14.140110014999891, 16.456306457999972],
                [-14.140794754999888, 16.458513259000085],
                [-14.141008376999878, 16.460638046000099],
                [-14.140871047999894, 16.463506698000117],
                [-14.140168189999827, 16.465608597000028],
                [-14.13841915099988, 16.468734741000048],
                [-14.135529517999885, 16.472143173000177],
                [-14.13331222599993, 16.47450256400009],
                [-14.132812499999886, 16.475456238000106],
                [-14.133054733999927, 16.47679710400007],
                [-14.133968351999954, 16.477638245000094],
                [-14.136420249999958, 16.478006363000191],
                [-14.136883735999959, 16.477886200000114],
                [-14.137958526999967, 16.477605819000075],
                [-14.138628005999919, 16.476905823000095],
                [-14.138755798999853, 16.476772308000136],
                [-14.139620781999895, 16.475547790000064],
                [-14.142807006999931, 16.472261429000184],
                [-14.144547462999981, 16.471118927000191],
                [-14.147175788999903, 16.470987321000109],
                [-14.149785995999878, 16.471454621000078],
                [-14.151703833999932, 16.472007752000081],
                [-14.15385341699988, 16.473522187000071],
                [-14.156222342999968, 16.476114274000054],
                [-14.158660888999918, 16.479532242000062],
                [-14.160814284999958, 16.484119416000169],
                [-14.160871505999978, 16.484241485000041],
                [-14.16311931599995, 16.489021301000093],
                [-14.167080878999911, 16.493055343000094],
                [-14.1716794969999, 16.495727538000153],
                [-14.176877974999968, 16.497541428000147],
                [-14.180174827999906, 16.499008179000043],
                [-14.183625221999932, 16.501186371000188],
                [-14.184148788999948, 16.501504899000054],
                [-14.189602851999894, 16.50481414900014],
                [-14.193748473999904, 16.506593705000114],
                [-14.198390005999897, 16.508195877000162],
                [-14.200677870999925, 16.509580612000093],
                [-14.20109653499992, 16.510141373000181],
                [-14.202310561999923, 16.511768342000096],
                [-14.203271864999977, 16.513309479000156],
                [-14.204298972999879, 16.516685485000039],
                [-14.204427718999966, 16.520133972000167],
                [-14.203359603999957, 16.522949219000168],
                [-14.201275825999915, 16.526037216000134],
                [-14.199315071999877, 16.52857589700011],
                [-14.199268341999925, 16.530103684000153],
                [-14.199954031999823, 16.531400680000161],
                [-14.203523635999943, 16.532392501000061],
                [-14.209444045999874, 16.534961700999986],
                [-14.215110778999872, 16.539842605000104],
                [-14.220416067999963, 16.543859481000084],
                [-14.228859900999907, 16.545379640000021],
                [-14.237832068999921, 16.546627046000026],
                [-14.24701595199997, 16.545719148000046],
                [-14.250763892999942, 16.543067932000042],
                [-14.253170012999874, 16.540647508000177],
                [-14.255749701999889, 16.536119461000055],
                [-14.255909918999976, 16.535839080000017],
                [-14.256063460999826, 16.535123824000038],
                [-14.256517409999901, 16.53300285500012],
                [-14.257150649999971, 16.530044556000064],
                [-14.257777213999873, 16.525463104000039],
                [-14.256570814999975, 16.522218704000181],
                [-14.257131575999892, 16.522157669000023],
                [-14.259768486999974, 16.521873473000142],
                [-14.260263442999872, 16.521820068000068],
                [-14.260947226999974, 16.52174568200013],
                [-14.263631820999933, 16.527851106000071],
                [-14.266861914999879, 16.533927918000188],
                [-14.272747992999882, 16.543188095000119],
                [-14.275157927999885, 16.548746110000025],
                [-14.275836944999867, 16.552814483000134],
                [-14.275172233999911, 16.558073044000082],
                [-14.27371597299998, 16.562995910000097],
                [-14.271813391999956, 16.566415788000086],
                [-14.269765852999967, 16.571399689000089],
                [-14.269895553999959, 16.576229095000144],
                [-14.273749351999982, 16.580549240000096],
                [-14.279289244999973, 16.584634782000137],
                [-14.279609679999908, 16.584739684000112],
                [-14.282559394999907, 16.585699082000076],
                [-14.285580633999928, 16.585111619000031],
                [-14.286430358999894, 16.584947586000055],
                [-14.289314268999874, 16.583187104000046],
                [-14.290907858999901, 16.581422806000091],
                [-14.291250229999832, 16.580862045000174],
                [-14.29245471899992, 16.578891755000029],
                [-14.294403074999934, 16.575120926000011],
                [-14.296838759999844, 16.571813583000051],
                [-14.298387526999932, 16.570541381000112],
                [-14.302407264999886, 16.570028305000108],
                [-14.306375503999959, 16.569898606000152],
                [-14.308629036999889, 16.570041658000036],
                [-14.313153265999858, 16.570114136000029],
                [-14.317168236999976, 16.568695070000047],
                [-14.320674895999957, 16.565876008000032],
                [-14.32378005899983, 16.56466674800015],
                [-14.327284812999892, 16.56464767500006],
                [-14.329901695999979, 16.565662385],
                [-14.332222937999916, 16.568170548000126],
                [-14.333733557999949, 16.57103538500013],
                [-14.334524154999883, 16.574153901000045],
                [-14.334540365999828, 16.577445985000111],
                [-14.334179878999919, 16.58063125700005],
                [-14.333251, 16.5834140770001],
                [-14.330198287999906, 16.593984605],
                [-14.328003881999962, 16.600914002000025],
                [-14.32749271299997, 16.605337144000089],
                [-14.327750204999973, 16.611436845000014],
                [-14.327634811999928, 16.617893219999985],
                [-14.327236175999872, 16.625366211000028],
                [-14.327065467999944, 16.63008880699999],
                [-14.328351973999929, 16.634458542000175],
                [-14.329797743999904, 16.637870789000146],
                [-14.332518577999963, 16.640180587000089],
                [-14.336979864999876, 16.641601563000108],
                [-14.339088438999909, 16.641517640000188],
                [-14.341476440999941, 16.641405107000026],
                [-14.345821379999904, 16.640462875000026],
                [-14.351642609999828, 16.639291763000131],
                [-14.35968875899988, 16.636127472000055],
                [-14.36218643199993, 16.634826661000147],
                [-14.366066932999956, 16.633260727999982],
                [-14.369966505999912, 16.633466720000172],
                [-14.374909399999979, 16.634864808000032],
                [-14.378915787999915, 16.636404038000023],
                [-14.38261222899996, 16.637264253000069],
                [-14.386564253999836, 16.637033462000034],
                [-14.389358519999917, 16.636508941999978],
                [-14.393817900999863, 16.63496971100011],
                [-14.396918296999957, 16.633304597000176],
                [-14.399630545999855, 16.632377624000128],
                [-14.399621009999976, 16.632154466000145],
                [-14.402458190999937, 16.631978988000185],
                [-14.403234481999959, 16.632053376000101],
                [-14.40607261599996, 16.632326126000123],
                [-14.409199713999953, 16.633798600000091],
                [-14.410895346999951, 16.636192323000159],
                [-14.412771223999982, 16.640052796000134],
                [-14.413875580999957, 16.643913269000052],
                [-14.413833618999945, 16.647590637],
                [-14.414171219999901, 16.648845673000153],
                [-14.416396141999883, 16.651304246000052],
                [-14.418750761999945, 16.652208328000086],
                [-14.420735359999981, 16.652214051000101],
                [-14.422537803999944, 16.650962830000083],
                [-14.425803184999836, 16.647922517000154],
                [-14.429437637999968, 16.645898820000127],
                [-14.432887076999918, 16.643426894000015],
                [-14.434938430999921, 16.641939164000064],
                [-14.437302588999955, 16.639522553],
                [-14.439359664999927, 16.635702132],
                [-14.440514563999898, 16.633373261000145],
                [-14.44251346599998, 16.628326416000107],
                [-14.445081710999943, 16.624782563000053],
                [-14.447649955999907, 16.623235702999978],
                [-14.450509069999953, 16.622707368000079],
                [-14.455131531999882, 16.623159409000095],
                [-14.457551002999935, 16.623613358000057],
                [-14.471604345999936, 16.626140594000105],
                [-14.476013182999964, 16.625980377000076],
                [-14.478694915999881, 16.625102998000045],
                [-14.480957031999822, 16.623239518000048],
                [-14.483892441999956, 16.619949341000051],
                [-14.487285613999973, 16.616661072],
                [-14.491734503999908, 16.614488603000098],
                [-14.4975185399999, 16.613796233000016],
                [-14.501466750999953, 16.614110948000018],
                [-14.506605148999938, 16.61789703300002],
                [-14.5100574499999, 16.621345519999977],
                [-14.512599944999977, 16.622920991000058],
                [-14.516812324999876, 16.624753952000106],
                [-14.52111911899982, 16.626110078000067],
                [-14.52623271899995, 16.627258301000097],
                [-14.529360769999869, 16.628997803000175],
                [-14.532485961999896, 16.631607055000018],
                [-14.534865379999871, 16.635139466],
                [-14.538515090999965, 16.639123917000063],
                [-14.541051864999872, 16.641580582000017],
                [-14.542260169999963, 16.642391205000081],
                [-14.544303892999949, 16.643472670999984],
                [-14.546505927999874, 16.643297196000162],
                [-14.547997475999921, 16.641984939],
                [-14.54912090299996, 16.639146806000099],
                [-14.550029753999922, 16.634962082000072],
                [-14.550070762999951, 16.630928041000175],
                [-14.550915717999885, 16.627700805000131],
                [-14.551916121999966, 16.623907089000113],
                [-14.552759170999821, 16.621425628000054],
                [-14.554564475999882, 16.61856079100005],
                [-14.556211470999926, 16.616979600000093],
                [-14.558073042999922, 16.616474151000091],
                [-14.559932707999963, 16.616928100000052],
                [-14.563183784999921, 16.619176865000043],
                [-14.56628322499995, 16.621845245000031],
                [-14.570041655999887, 16.623622894000164],
                [-14.572244643999909, 16.62371063300003],
                [-14.574492454999927, 16.623420716000112],
                [-14.577090263999935, 16.622585297000171],
                [-14.581303596999931, 16.620700837000015],
                [-14.584752082999955, 16.619781494000108],
                [-14.587609291999968, 16.620040893000123],
                [-14.590441704999932, 16.621623993000014],
                [-14.593099593999909, 16.623163224000109],
                [-14.594909668999946, 16.623418809000043],
                [-14.597027777999926, 16.622098922000191],
                [-14.598382949999973, 16.620672226000067],
                [-14.600613593999981, 16.618194579000146],
                [-14.603080749999947, 16.617189407000126],
                [-14.604579924999882, 16.617614747000118],
                [-14.605776785999979, 16.618402481000146],
                [-14.607111930999963, 16.621150970000087],
                [-14.608350753999957, 16.624086380000108],
                [-14.609807014999944, 16.626050950000149],
                [-14.613249778999943, 16.628318786000023],
                [-14.61710262399987, 16.628694534000033],
                [-14.620957374999875, 16.628679276000184],
                [-14.624213219999888, 16.628568650000091],
                [-14.627422332999913, 16.628320693999967],
                [-14.629027367999925, 16.627586363999967],
                [-14.630298614999958, 16.62645721399997],
                [-14.631547927999975, 16.62424278300017],
                [-14.633637428999918, 16.62055397000006],
                [-14.635628699999927, 16.618064881000066],
                [-14.637651443999857, 16.617021562000161],
                [-14.638811110999882, 16.616716385000075],
                [-14.640905379999879, 16.617370606000122],
                [-14.643387793999921, 16.619634629000132],
                [-14.645852088999902, 16.621883392000086],
                [-14.649257660999865, 16.624799729000074],
                [-14.651807784999903, 16.628810882000039],
                [-14.65199851899996, 16.631345748000172],
                [-14.651036261999934, 16.633039475000089],
                [-14.649490356999934, 16.633619309000096],
                [-14.646327972999927, 16.633802413000126],
                [-14.642188071999954, 16.634292603],
                [-14.640710829999932, 16.635864257000037],
                [-14.639905929999941, 16.638826371000164],
                [-14.639757155999916, 16.642627717000096],
                [-14.640109061999965, 16.644632340000101],
                [-14.641861916999972, 16.647426606000067],
                [-14.64590835599995, 16.649385453000036],
                [-14.650615691999974, 16.649700165000127],
                [-14.656230926999967, 16.648374558000114],
                [-14.659394263999957, 16.647506715000191],
                [-14.665614126999969, 16.646335603000125],
                [-14.669450759999961, 16.646100997000076],
                [-14.672986029999947, 16.645900727000026],
                [-14.679221152999958, 16.64597892900008],
                [-14.68602466599998, 16.645784377000155],
                [-14.689773558999889, 16.645294189000083],
                [-14.694198608999955, 16.644136430000117],
                [-14.695496559999924, 16.643657685000051],
                [-14.699353217999942, 16.64227676500019],
                [-14.702250480999908, 16.640996933000167],
                [-14.704046247999827, 16.640245438000079],
                [-14.710390090999965, 16.638576507000096],
                [-14.713907242999937, 16.638273240000103],
                [-14.717139243999839, 16.639064790000077],
                [-14.720297812999945, 16.640953063000097],
                [-14.722781180999903, 16.643747329000178],
                [-14.724651335999908, 16.646188736000113],
                [-14.725757597999973, 16.646606446000021],
                [-14.727152823999972, 16.646053315000188],
                [-14.727741240999876, 16.645223618000045],
                [-14.727875709999978, 16.645032882000066],
                [-14.727878569999973, 16.642934799000102],
                [-14.727879524999935, 16.642250061000084],
                [-14.72678375299995, 16.635015488000079],
                [-14.727170943999965, 16.633068084000058],
                [-14.72880840299996, 16.631772995000063],
                [-14.731165885999928, 16.631357193000156],
                [-14.734235762999958, 16.632171632000109],
                [-14.736045837999825, 16.632228852000082],
                [-14.736721038999917, 16.631742477000046],
                [-14.73775768299987, 16.630994797000028],
                [-14.737957954999956, 16.628902435999976],
                [-14.736941336999848, 16.626682282000161],
                [-14.735352514999931, 16.625211716000138],
                [-14.73460960299991, 16.624523163000106],
                [-14.73343086299991, 16.620971679999968],
                [-14.733729361999906, 16.619417191000082],
                [-14.735538481999924, 16.618499756000062],
                [-14.738300322999919, 16.618694305000133],
                [-14.741422651999926, 16.619998931000055],
                [-14.745661735999818, 16.622318268000186],
                [-14.749605177999968, 16.624509812000156],
                [-14.752463341999942, 16.626892089000023],
                [-14.753871917999959, 16.630475997000076],
                [-14.75367259899997, 16.632894516000079],
                [-14.752517699999885, 16.635515213000076],
                [-14.752119063999942, 16.638875961000167],
                [-14.752114295999888, 16.641983032000098],
                [-14.753326414999947, 16.645915986000091],
                [-14.754474638999966, 16.647977830000173],
                [-14.75782585099995, 16.651119232000042],
                [-14.761440277999895, 16.653533935000098],
                [-14.765089035999893, 16.655250550000119],
                [-14.767193794999969, 16.655473708999978],
                [-14.769134520999955, 16.655000687000097],
                [-14.771735191999937, 16.653070451000076],
                [-14.776839256999835, 16.647399903000064],
                [-14.780630111999869, 16.639858247000063],
                [-14.782574652999926, 16.636119842000141],
                [-14.785145758999931, 16.630954742000142],
                [-14.787318229999926, 16.629180909000013],
                [-14.789652824999905, 16.629024507000054],
                [-14.790341376999947, 16.629226684000173],
                [-14.792409895999924, 16.630319595000117],
                [-14.794795990999944, 16.632608415000107],
                [-14.797720909999896, 16.636039733000132],
                [-14.799981115999969, 16.638914108000051],
                [-14.804954528999872, 16.645233154000096],
                [-14.807999611999946, 16.649332046000154],
                [-14.810277938999889, 16.651895523000064],
                [-14.813097000999903, 16.653577805000168],
                [-14.81571960399998, 16.653476715000124],
                [-14.818450926999958, 16.652423858000134],
                [-14.820177077999972, 16.650762558],
                [-14.821274756999912, 16.648944854999968],
                [-14.821619032999877, 16.648405075000085],
                [-14.82204818799994, 16.647733688000073],
                [-14.822625159999916, 16.645742417000065],
                [-14.822314262999953, 16.643762590000108],
                [-14.822125433999872, 16.642555236000021],
                [-14.822379112999897, 16.639577866000081],
                [-14.823106764999977, 16.638467789000174],
                [-14.823422431999973, 16.637985229000037],
                [-14.82451152799996, 16.636457443000097],
                [-14.825346946999957, 16.635286330000099],
                [-14.82827567999982, 16.634128571000133],
                [-14.830862045999936, 16.634597778000114],
                [-14.834236143999874, 16.637006761000066],
                [-14.83858108499993, 16.639659882000103],
                [-14.840407370999969, 16.639715195000178],
                [-14.841883659999894, 16.639837266000029],
                [-14.847436904999824, 16.638504028000057],
                [-14.856299399999898, 16.636039733000132],
                [-14.86046314299989, 16.635768890000065],
                [-14.862632751999911, 16.637245179000047],
                [-14.864479065999831, 16.643558503000179],
                [-14.868389128999979, 16.649942399000111],
                [-14.873474119999969, 16.657356262000064],
                [-14.877458571999966, 16.659896851000042],
                [-14.881052969999814, 16.659830093000039],
                [-14.884185791999926, 16.65832328700003],
                [-14.88582420399996, 16.656333924000023],
                [-14.886786460999929, 16.654447556000093],
                [-14.887893675999976, 16.64731216399997],
                [-14.890746116999878, 16.638015748000157],
                [-14.893631934999917, 16.632528306000154],
                [-14.894847868999875, 16.631649017000143],
                [-14.897472381999876, 16.630273820000127],
                [-14.899921416999916, 16.630174638000085],
                [-14.903288841999938, 16.630279541000164],
                [-14.906766891999951, 16.632390977000057],
                [-14.907786369999826, 16.633161545000064],
                [-14.907939909999982, 16.633060456000123],
                [-14.910057066999855, 16.634639740000068],
                [-14.911633490999975, 16.637414932000183],
                [-14.912022590999925, 16.640619278000088],
                [-14.911389350999968, 16.64513015700004],
                [-14.911388396999939, 16.64731216399997],
                [-14.912334440999814, 16.648118974000113],
                [-14.913987160999966, 16.64806556700006],
                [-14.915824889999953, 16.647117615000127],
                [-14.917014122999944, 16.646242141000187],
                [-14.918165206999959, 16.644756316999974],
                [-14.922106743999962, 16.640657425000143],
                [-14.927707670999837, 16.63545608600009],
                [-14.931501388999948, 16.632282257000099],
                [-14.93511390599997, 16.630661011000086],
                [-14.935791969999912, 16.630523681999989],
                [-14.939996719999954, 16.630962372000056],
                [-14.944412231999877, 16.63349914600019],
                [-14.946650503999876, 16.636432648999971],
                [-14.947040556999923, 16.636943818000077],
                [-14.951455116999966, 16.644216538000023],
                [-14.95294666399991, 16.646692277000113],
                [-14.953587531999915, 16.647756577000052],
                [-14.953960418999941, 16.649082184000065],
                [-14.95425033499987, 16.65011215200019],
                [-14.954529762999982, 16.651105881000092],
                [-14.95453167099987, 16.651239395000118],
                [-14.954555511999899, 16.653009416000089],
                [-14.954566954999962, 16.653886794000073],
                [-14.954578399999946, 16.654741287000149],
                [-14.954503058999933, 16.655115127000158],
                [-14.953724860999898, 16.658969879999972],
                [-14.953535078999948, 16.659906387000092],
                [-14.952572821999979, 16.663150788000053],
                [-14.952108381999949, 16.664718629000049],
                [-14.951150894999955, 16.667943955000055],
                [-14.951101302999916, 16.671100616000047],
                [-14.951199531999976, 16.673923493000132],
                [-14.952637671999923, 16.676794053000151],
                [-14.954374312999903, 16.678611755000077],
                [-14.959486007999828, 16.680717469000058],
                [-14.968908309999961, 16.685186386000055],
                [-14.969751358999929, 16.68558693000017],
                [-14.979002, 16.690462113000024],
                [-14.983499527999925, 16.691568375000088],
                [-14.985541343999955, 16.692071916000089],
                [-14.987336157999948, 16.691514970000185],
                [-14.989329338999937, 16.69089698800002],
                [-14.989831924999976, 16.690433502000076],
                [-14.993433950999929, 16.687116624000055],
                [-14.995854376999887, 16.683000566000032],
                [-14.996890067999971, 16.673870086000022],
                [-14.996909141999936, 16.66823005700013],
                [-14.996834754999838, 16.661701203000121],
                [-14.997838974999979, 16.65213966499999],
                [-14.998921393999979, 16.648841858000083],
                [-15.000402449999967, 16.646425247000082],
                [-15.002075195999907, 16.643165588999977],
                [-15.005979538999838, 16.640365601000155],
                [-15.010851859999832, 16.638532640000108],
                [-15.019697188999942, 16.635913849000019],
                [-15.026898383999878, 16.63252067600007],
                [-15.033058165999819, 16.630502701000125],
                [-15.037365912999974, 16.63022995100016],
                [-15.04086017599991, 16.630506516000025],
                [-15.04127120999982, 16.63053894],
                [-15.044652938999945, 16.63240242],
                [-15.046666145999893, 16.632478714000115],
                [-15.04807472199991, 16.631896974000142],
                [-15.04817295099997, 16.631782531000169],
                [-15.049644469999919, 16.630071641000029],
                [-15.051294326999937, 16.627645492000056],
                [-15.052783966999982, 16.627645492000056],
                [-15.054233549999935, 16.628129960000024],
                [-15.055684089999943, 16.63049697800011],
                [-15.057415961999823, 16.634649278000154],
                [-15.058524130999956, 16.636199951000094],
                [-15.064194678999968, 16.643079758000113],
                [-15.066048621999983, 16.646760940000036],
                [-15.068694114999971, 16.656847],
                [-15.069091796999885, 16.657781602000057],
                [-15.069401740999979, 16.658508301000097],
                [-15.071040152999899, 16.662359239000011],
                [-15.07176971399997, 16.664134980000142],
                [-15.077578543999891, 16.672454834000177],
                [-15.086290359999964, 16.676612854000155],
                [-15.094107628999893, 16.677614211000105],
                [-15.097456932999933, 16.677253724000082],
                [-15.099690437999982, 16.675819397000112],
                [-15.100602149999929, 16.674777986000038],
                [-15.104971884999884, 16.66978836200019],
                [-15.110175131999938, 16.662221910000142],
                [-15.115026473999933, 16.657682420000071],
                [-15.118150709999895, 16.654163360000041],
                [-15.118662833999906, 16.651763916999982],
                [-15.1202688219999, 16.644533158000115],
                [-15.118976593999946, 16.637571335000075],
                [-15.11653613999988, 16.634822846000077],
                [-15.113177299999961, 16.633451462000153],
                [-15.109531401999959, 16.632619858000055],
                [-15.107418059999873, 16.63244056700006],
                [-15.098859786999981, 16.632444382000131],
                [-15.094527243999892, 16.631168365000178],
                [-15.091488837999862, 16.628458023000064],
                [-15.088360786999942, 16.62332153400007],
                [-15.085754394999924, 16.620037078000109],
                [-15.083848, 16.616104126000096],
                [-15.083384513999874, 16.612373352000134],
                [-15.083575247999931, 16.611867905000167],
                [-15.083503723999968, 16.60762977700017],
                [-15.085858344999906, 16.602905273000033],
                [-15.089577673999827, 16.599256517000128],
                [-15.095280645999878, 16.595485688000167],
                [-15.099929808999889, 16.59195709200003],
                [-15.103648185999873, 16.588426590000154],
                [-15.108568190999904, 16.583850860000098],
                [-15.111368179999943, 16.581188203000124],
                [-15.115231513999959, 16.579246522000176],
                [-15.121331213999895, 16.577449800000181],
                [-15.128474235999931, 16.577302933],
                [-15.137627600999906, 16.579090119000114],
                [-15.150131225999928, 16.58345794700017],
                [-15.160998344999882, 16.587898255000027],
                [-15.167026519999922, 16.588827133000166],
                [-15.171788215999925, 16.588106157000027],
                [-15.175060271999939, 16.58638000600007],
                [-15.176543235999873, 16.58078193599999],
                [-15.17795562799995, 16.578844070000059],
                [-15.181227683999964, 16.576688767000178],
                [-15.185020445999896, 16.575107575000061],
                [-15.1928310399999, 16.572875976000091],
                [-15.201903342999969, 16.568418502000043],
                [-15.212189673999944, 16.561088563000112],
                [-15.220116615999871, 16.555337906000091],
                [-15.225879668999937, 16.552579880000167],
                [-15.234496116999935, 16.552152634000151],
                [-15.241379736999875, 16.553998946000149],
                [-15.244843481999965, 16.556680679000067],
                [-15.249438284999883, 16.56202697700013],
                [-15.256585121999876, 16.567539216000114],
                [-15.263290405999953, 16.56831932],
                [-15.266831397999908, 16.567316055000049],
                [-15.271245001999944, 16.56694221500004],
                [-15.278551101999881, 16.568403244000024],
                [-15.290547370999832, 16.57201385500008],
                [-15.301886557999921, 16.573890686000027],
                [-15.309081076999973, 16.574037553000039],
                [-15.315456390999884, 16.572767258000169],
                [-15.322807312999942, 16.56860351600011],
                [-15.333298681999906, 16.563241959000095],
                [-15.335089682999978, 16.561992646000022],
                [-15.336668014999816, 16.561403275000032],
                [-15.339170456999909, 16.560636520000116],
                [-15.342064857999958, 16.56012344300018],
                [-15.345303534999971, 16.559835434999968],
                [-15.347432136999942, 16.559988022000084],
                [-15.349227905999953, 16.560102462000145],
                [-15.350189208999893, 16.560512544000119],
                [-15.351548194999964, 16.561040879000075],
                [-15.353659628999878, 16.561531067000146],
                [-15.35616397799987, 16.561748504000093],
                [-15.358147620999887, 16.561281205000057],
                [-15.360713003999876, 16.560411454000132],
                [-15.363455772999885, 16.559097290000125],
                [-15.366243362999967, 16.556798935000074],
                [-15.367928504999952, 16.555034637000119],
                [-15.369446753999966, 16.553367614000138],
                [-15.372237205999909, 16.550649644000146],
                [-15.372369765999963, 16.550519943000154],
                [-15.375253677999922, 16.548328400000116],
                [-15.379127501999903, 16.546009064000032],
                [-15.381942748999961, 16.5445594790001],
                [-15.385680198999921, 16.54244232200017],
                [-15.388383865999913, 16.541048048999983],
                [-15.390872001999981, 16.539646149000191],
                [-15.392571448999831, 16.53893852300007],
                [-15.395590780999896, 16.538192750000121],
                [-15.397686003999979, 16.537763595],
                [-15.399332045999927, 16.537279130000172],
                [-15.402280806999897, 16.53751182600007],
                [-15.402657508999823, 16.537559509],
                [-15.404914855999891, 16.537843704000181],
                [-15.405761717999894, 16.537918090000119],
                [-15.406280516999914, 16.537963867000087],
                [-15.408382414999835, 16.538284302000022],
                [-15.411266327999954, 16.538883210000051],
                [-15.413236617999928, 16.539331436000168],
                [-15.420363426999927, 16.541877746000182],
                [-15.423793792999902, 16.543710709000038],
                [-15.426950454999883, 16.546310426000105],
                [-15.427496910999878, 16.547441484000103],
                [-15.428292273999944, 16.548912048000091],
                [-15.428466796999942, 16.550344467],
                [-15.4287586199999, 16.551130294000131],
                [-15.429667472999881, 16.553577424000082],
                [-15.429914473999872, 16.555105210000022],
                [-15.430054663999954, 16.557325364000064],
                [-15.430213927999887, 16.558481216000132],
                [-15.430264472999966, 16.558912277000161],
                [-15.430447578999974, 16.560480117000054],
                [-15.430680274999929, 16.562196733000121],
                [-15.430858610999962, 16.564083099000072],
                [-15.430969238999865, 16.565174102000185],
                [-15.43129444099992, 16.566690446000052],
                [-15.431418419999886, 16.567049027000166],
                [-15.431379317999927, 16.567087174000051],
                [-15.431642532999945, 16.567697524999971],
                [-15.432324409999978, 16.569286347000173],
                [-15.434185981999917, 16.573537827000052],
                [-15.435099601999923, 16.575159073000066],
                [-15.438473700999964, 16.579553604000182],
                [-15.439103126999953, 16.58022499000009],
                [-15.442548750999947, 16.583309174000021],
                [-15.444455145999939, 16.585016252000059],
                [-15.444843291999916, 16.585363388000189],
                [-15.448374748999981, 16.587900162000096],
                [-15.449204445999897, 16.588495255000055],
                [-15.449696540999923, 16.588848114000029],
                [-15.449850082999944, 16.588939666000158],
                [-15.45028495899993, 16.589199066000106],
                [-15.451437949999956, 16.58988762000007],
                [-15.45343971199992, 16.590721130000077],
                [-15.45797443399988, 16.592580796000163],
                [-15.45909976899992, 16.592746734],
                [-15.463039398999911, 16.592931748000069],
                [-15.465965270999959, 16.593082428000059],
                [-15.469492911999907, 16.59269142200003],
                [-15.473493575999896, 16.591291428000034],
                [-15.474707602999899, 16.590398789000176],
                [-15.477050780999889, 16.588562011000079],
                [-15.47796535599997, 16.587842942000179],
                [-15.478771208999945, 16.586723327000186],
                [-15.479745864999927, 16.584184645999983],
                [-15.482016562999888, 16.581766130000062],
                [-15.485107420999839, 16.580009460000042],
                [-15.486145973999953, 16.579383851000046],
                [-15.487745283999971, 16.578418733000149],
                [-15.491616247999957, 16.57710266100014],
                [-15.494060514999944, 16.576059342000065],
                [-15.496503829999881, 16.575086594000027],
                [-15.498138428999937, 16.574586868000097],
                [-15.500075339999967, 16.573995591000084],
              ],
            ],
            [
              [
                [-16.334862, 19.753471000000161],
                [-16.334305, 19.753471000000161],
                [-16.334305, 19.752916],
                [-16.334028, 19.752916],
                [-16.334028, 19.752640000000156],
                [-16.33375, 19.752640000000156],
                [-16.33375, 19.752361000000178],
                [-16.333470999999861, 19.752361000000178],
                [-16.333470999999861, 19.752083],
                [-16.333195, 19.752083],
                [-16.333195, 19.750694],
                [-16.332916, 19.750694],
                [-16.332916, 19.750139000000161],
                [-16.33264, 19.750139000000161],
                [-16.33264, 19.749584],
                [-16.332361, 19.749584],
                [-16.332361, 19.748751],
                [-16.332083, 19.748751],
                [-16.332083, 19.747915],
                [-16.331806, 19.747915],
                [-16.331806, 19.747360000000128],
                [-16.332083, 19.747360000000128],
                [-16.332083, 19.746803],
                [-16.332361, 19.746803],
                [-16.332361, 19.746529],
                [-16.334583, 19.746529],
                [-16.334583, 19.74625],
                [-16.335417, 19.74625],
                [-16.335417, 19.745972],
                [-16.335972, 19.745972],
                [-16.335972, 19.745693000000188],
                [-16.336527, 19.745693000000188],
                [-16.336527, 19.745417],
                [-16.336805, 19.745417],
                [-16.336805, 19.745138000000111],
                [-16.33736, 19.745138000000111],
                [-16.33736, 19.744862000000182],
                [-16.337917, 19.744862000000182],
                [-16.337917, 19.744581],
                [-16.338752999999883, 19.744581],
                [-16.338752999999883, 19.744305000000111],
                [-16.339306, 19.744305000000111],
                [-16.339306, 19.744028000000128],
                [-16.339583999999832, 19.744028000000128],
                [-16.339583999999832, 19.743471],
                [-16.339863, 19.743471],
                [-16.339863, 19.742916],
                [-16.339583999999832, 19.742916],
                [-16.339583999999832, 19.74264],
                [-16.339306, 19.74264],
                [-16.339306, 19.742083],
                [-16.339027, 19.742083],
                [-16.339027, 19.741806000000111],
                [-16.338752999999883, 19.741806000000111],
                [-16.338752999999883, 19.74097300000011],
                [-16.33847, 19.74097300000011],
                [-16.33847, 19.740141],
                [-16.338194, 19.740141],
                [-16.338194, 19.734861000000137],
                [-16.33847, 19.734861000000137],
                [-16.33847, 19.734028000000137],
                [-16.338752999999883, 19.734028000000137],
                [-16.338752999999883, 19.733473],
                [-16.339027, 19.733473],
                [-16.339027, 19.73263900000012],
                [-16.339306, 19.73263900000012],
                [-16.339306, 19.73180600000012],
                [-16.339583999999832, 19.73180600000012],
                [-16.339583999999832, 19.730694000000142],
                [-16.339863, 19.730694000000142],
                [-16.339863, 19.728472000000124],
                [-16.340139, 19.728472000000124],
                [-16.340139, 19.727362000000142],
                [-16.340414, 19.727362000000142],
                [-16.340414, 19.726250000000164],
                [-16.340694, 19.726250000000164],
                [-16.340694, 19.725695],
                [-16.340975, 19.725695],
                [-16.340975, 19.724583],
                [-16.341249, 19.724583],
                [-16.341249, 19.723747000000174],
                [-16.341528, 19.723747000000174],
                [-16.341528, 19.722918000000163],
                [-16.341806, 19.722918000000163],
                [-16.341806, 19.722361],
                [-16.342084999999884, 19.722361],
                [-16.342084999999884, 19.721807],
                [-16.342361, 19.721807],
                [-16.342361, 19.721251],
                [-16.342638, 19.721251],
                [-16.342638, 19.720694000000151],
                [-16.342915999999832, 19.720694000000151],
                [-16.342915999999832, 19.720139],
                [-16.343197, 19.720139],
                [-16.343197, 19.719303],
                [-16.343472, 19.719303],
                [-16.343472, 19.71875],
                [-16.343749999999829, 19.71875],
                [-16.343749999999829, 19.718193000000156],
                [-16.344028, 19.718193000000156],
                [-16.344028, 19.717638],
                [-16.344307, 19.717638],
                [-16.344307, 19.717081000000178],
                [-16.344583999999884, 19.717081000000178],
                [-16.344583999999884, 19.716528],
                [-16.344862, 19.716528],
                [-16.344862, 19.71597300000019],
                [-16.345139, 19.71597300000019],
                [-16.345139, 19.715139],
                [-16.345414999999832, 19.715139],
                [-16.345414999999832, 19.714306],
                [-16.345694, 19.714306],
                [-16.345694, 19.713472],
                [-16.345972, 19.713472],
                [-16.345972, 19.712917],
                [-16.346250999999882, 19.712917],
                [-16.346250999999882, 19.71236],
                [-16.346527, 19.71236],
                [-16.346527, 19.711805],
                [-16.346806, 19.711805],
                [-16.346806, 19.710136],
                [-16.347083999999882, 19.710136],
                [-16.347083999999882, 19.708750000000123],
                [-16.347361, 19.708750000000123],
                [-16.347361, 19.708197000000155],
                [-16.347637, 19.708197000000155],
                [-16.347637, 19.70764],
                [-16.347917999999879, 19.70764],
                [-16.347917999999879, 19.707083000000182],
                [-16.348194, 19.707083000000182],
                [-16.348194, 19.706528000000105],
                [-16.348472999999899, 19.706528000000105],
                [-16.348472999999899, 19.705973],
                [-16.348748999999884, 19.705973],
                [-16.348748999999884, 19.705692],
                [-16.349028, 19.705692],
                [-16.349028, 19.705416000000127],
                [-16.349305999999899, 19.705416000000127],
                [-16.349305999999899, 19.705139],
                [-16.349582999999882, 19.705139],
                [-16.349582999999882, 19.704861],
                [-16.349859, 19.704861],
                [-16.349859, 19.704306],
                [-16.350142, 19.704306],
                [-16.350142, 19.70319400000011],
                [-16.350415999999882, 19.70319400000011],
                [-16.350415999999882, 19.70236],
                [-16.350695, 19.70236],
                [-16.350695, 19.701529],
                [-16.350971, 19.701529],
                [-16.350971, 19.700972],
                [-16.351249999999879, 19.700972],
                [-16.351249999999879, 19.700138],
                [-16.351528, 19.700138],
                [-16.351528, 19.699306],
                [-16.351804999999899, 19.699305],
                [-16.351804999999899, 19.698195],
                [-16.352080999999885, 19.698195],
                [-16.352080999999885, 19.697639],
                [-16.352364, 19.697639],
                [-16.352364, 19.696808],
                [-16.352637999999899, 19.696808],
                [-16.352637999999899, 19.696526000000119],
                [-16.352916999999877, 19.696526000000119],
                [-16.352916999999877, 19.695972000000154],
                [-16.353195, 19.695972000000154],
                [-16.353195, 19.695696],
                [-16.353749999999877, 19.695696],
                [-16.353749999999877, 19.695415],
                [-16.354304999999897, 19.695415],
                [-16.354304999999897, 19.695139000000154],
                [-16.35486, 19.695139000000154],
                [-16.35486, 19.694860000000119],
                [-16.355139, 19.694860000000119],
                [-16.355139, 19.694586000000186],
                [-16.355416999999875, 19.694586000000186],
                [-16.355416999999875, 19.694307],
                [-16.357639, 19.694307],
                [-16.357639, 19.694027000000119],
                [-16.357361, 19.694027000000119],
                [-16.357361, 19.693750000000136],
                [-16.359582999999873, 19.693750000000136],
                [-16.359582999999873, 19.693193],
                [-16.36125, 19.693193],
                [-16.36125, 19.693750000000136],
                [-16.362362, 19.693750000000136],
                [-16.362362, 19.694027000000119],
                [-16.36347, 19.694027000000119],
                [-16.36347, 19.694586000000186],
                [-16.363752, 19.694586000000186],
                [-16.363752, 19.694969],
                [-16.363752, 19.695139000000154],
                [-16.364027, 19.695139000000154],
                [-16.364027, 19.696808],
                [-16.363752, 19.696808],
                [-16.363752, 19.697361000000114],
                [-16.36347, 19.697361000000114],
                [-16.36347, 19.697918000000186],
                [-16.363195, 19.697918000000186],
                [-16.363194, 19.698473],
                [-16.362916999999868, 19.698473],
                [-16.362916999999868, 19.698750000000132],
                [-16.362638, 19.698750000000132],
                [-16.362638, 19.699306],
                [-16.362362, 19.699306],
                [-16.362362, 19.699583000000132],
                [-16.36208299999987, 19.699583000000132],
                [-16.36208299999987, 19.69986200000011],
                [-16.361805, 19.69986200000011],
                [-16.361805, 19.700417000000186],
                [-16.361528, 19.700417000000186],
                [-16.361528, 19.70069500000011],
                [-16.36125, 19.70069500000011],
                [-16.36125, 19.701250000000186],
                [-16.360971, 19.701250000000186],
                [-16.360971, 19.701805],
                [-16.360695, 19.701805],
                [-16.360695, 19.702084000000127],
                [-16.360415999999873, 19.702084000000127],
                [-16.360415999999873, 19.702639],
                [-16.36014, 19.702639],
                [-16.360138, 19.702917000000127],
                [-16.359861, 19.702917000000127],
                [-16.359861, 19.70319400000011],
                [-16.359582999999873, 19.70319400000011],
                [-16.359582999999873, 19.703472],
                [-16.359306, 19.703472],
                [-16.359306, 19.703751000000182],
                [-16.359028, 19.703751000000182],
                [-16.359028, 19.70402700000011],
                [-16.358748999999875, 19.70402700000011],
                [-16.358748999999875, 19.704582000000187],
                [-16.358473, 19.704582000000187],
                [-16.358473, 19.705139],
                [-16.358194, 19.705139],
                [-16.358194, 19.705692],
                [-16.35791799999987, 19.705692],
                [-16.35791799999987, 19.706249000000128],
                [-16.357639, 19.706249000000128],
                [-16.357639, 19.707083000000182],
                [-16.357361, 19.707083000000182],
                [-16.357361, 19.70764],
                [-16.357083999999873, 19.70764],
                [-16.357083999999873, 19.708197000000155],
                [-16.356806, 19.708197000000155],
                [-16.356806, 19.708471],
                [-16.356527, 19.708471],
                [-16.356527, 19.709307],
                [-16.356250999999872, 19.709307],
                [-16.356250999999872, 19.709862000000101],
                [-16.355972, 19.709862000000101],
                [-16.355972, 19.710695000000101],
                [-16.355696, 19.710695000000101],
                [-16.355696, 19.711529000000155],
                [-16.355416999999875, 19.711529000000155],
                [-16.355416999999875, 19.71264100000019],
                [-16.355139, 19.71264100000019],
                [-16.355139, 19.713472],
                [-16.35486, 19.713472],
                [-16.35486, 19.714582000000178],
                [-16.35458599999987, 19.714582000000178],
                [-16.35458599999987, 19.715694000000155],
                [-16.354304999999897, 19.715694000000155],
                [-16.354304999999897, 19.717361],
                [-16.354029, 19.717361],
                [-16.354029, 19.71875],
                [-16.353749999999877, 19.71875],
                [-16.353749999999877, 19.720139],
                [-16.353474, 19.720139],
                [-16.353474, 19.721807],
                [-16.353195, 19.721807],
                [-16.353195, 19.723194],
                [-16.352916999999877, 19.723194],
                [-16.352916999999877, 19.724306],
                [-16.352637999999899, 19.724306],
                [-16.352637999999899, 19.725140000000124],
                [-16.352364, 19.725140000000124],
                [-16.352364, 19.726250000000164],
                [-16.352080999999885, 19.726250000000164],
                [-16.352080999999885, 19.727083000000164],
                [-16.351804999999899, 19.727083000000164],
                [-16.351804999999899, 19.728195000000142],
                [-16.351528, 19.728195000000142],
                [-16.351528, 19.72875],
                [-16.351231, 19.72875],
                [-16.351249999999879, 19.730972],
                [-16.351528, 19.730972],
                [-16.351528, 19.733473],
                [-16.351804999999899, 19.733473],
                [-16.351804999999899, 19.73597100000012],
                [-16.351528, 19.73597100000012],
                [-16.351528, 19.736807],
                [-16.351249999999879, 19.736807],
                [-16.351249999999879, 19.737362000000132],
                [-16.350971, 19.737362000000132],
                [-16.350971, 19.738472000000115],
                [-16.350695, 19.738472000000115],
                [-16.350695, 19.739029000000187],
                [-16.350415999999882, 19.739029000000187],
                [-16.350415999999882, 19.739584],
                [-16.350142, 19.739584],
                [-16.350142, 19.740141],
                [-16.349859, 19.740141],
                [-16.349859, 19.740694000000133],
                [-16.349582999999882, 19.740694000000133],
                [-16.349582999999882, 19.74097300000011],
                [-16.349305999999899, 19.74097300000011],
                [-16.349305999999899, 19.741528000000187],
                [-16.349028, 19.741528000000187],
                [-16.349028, 19.742083],
                [-16.348748999999884, 19.742083],
                [-16.348748999999884, 19.742361000000187],
                [-16.348472999999899, 19.742361000000187],
                [-16.348472999999899, 19.742916],
                [-16.348194, 19.742916],
                [-16.348194, 19.744581],
                [-16.347917999999879, 19.744581],
                [-16.347917999999879, 19.746803],
                [-16.347637, 19.746803],
                [-16.347637, 19.747360000000128],
                [-16.347361, 19.747360000000128],
                [-16.347361, 19.747915],
                [-16.347083999999882, 19.747915],
                [-16.347083999999882, 19.748472000000106],
                [-16.346806, 19.748472000000106],
                [-16.346806, 19.748751],
                [-16.346527, 19.748751],
                [-16.346527, 19.749025000000188],
                [-16.346250999999882, 19.749025000000188],
                [-16.346250999999882, 19.749308000000156],
                [-16.345972, 19.749308000000156],
                [-16.345972, 19.749584],
                [-16.345694, 19.749584],
                [-16.345694, 19.749861],
                [-16.345414999999832, 19.749861],
                [-16.345414999999832, 19.750139000000161],
                [-16.345139, 19.750139000000161],
                [-16.345139, 19.750694],
                [-16.344862, 19.750694],
                [-16.344862, 19.750973000000101],
                [-16.344583999999884, 19.750973000000101],
                [-16.344583999999884, 19.751530000000173],
                [-16.344307, 19.751530000000173],
                [-16.344307, 19.751806000000101],
                [-16.343749999999829, 19.751806000000101],
                [-16.343749999999829, 19.752083],
                [-16.342915999999832, 19.752083],
                [-16.342915999999832, 19.752361000000178],
                [-16.342361, 19.752361000000178],
                [-16.342361, 19.752640000000156],
                [-16.342084999999884, 19.752640000000156],
                [-16.342084999999884, 19.752916],
                [-16.341528, 19.752916],
                [-16.341528, 19.753195],
                [-16.340975, 19.753195],
                [-16.340975, 19.753471000000161],
                [-16.340414, 19.753471000000161],
                [-16.340414, 19.753752000000134],
                [-16.339583999999832, 19.753752000000134],
                [-16.339583999999832, 19.754028],
                [-16.338752999999883, 19.754028],
                [-16.338752999999883, 19.754305000000102],
                [-16.336248, 19.754305000000102],
                [-16.336248, 19.754028],
                [-16.335417, 19.754028],
                [-16.335417, 19.753752000000134],
                [-16.334862, 19.753752000000134],
                [-16.334862, 19.753471000000161],
              ],
            ],
            [
              [
                [-16.486527999999851, 19.646805001000132],
                [-16.486252, 19.646805001000132],
                [-16.486252, 19.645971000000145],
                [-16.485971000999939, 19.645971000000145],
                [-16.485971000999939, 19.645416001000171],
                [-16.485695000999954, 19.645416001000171],
                [-16.485695000999954, 19.644861000000162],
                [-16.48541599899994, 19.644861000000162],
                [-16.48541599899994, 19.644583],
                [-16.485138, 19.644583],
                [-16.485138, 19.644306],
                [-16.484859, 19.644306],
                [-16.484859, 19.643747000000189],
                [-16.484583000999919, 19.643747000000189],
                [-16.484583000999919, 19.642918000000179],
                [-16.484859, 19.642918000000179],
                [-16.484859, 19.642639001000077],
                [-16.485138, 19.642639001000077],
                [-16.485138, 19.641250998999965],
                [-16.48541599899994, 19.641250998999965],
                [-16.48541599899994, 19.640972],
                [-16.485695000999954, 19.640972],
                [-16.485695000999954, 19.640694000000167],
                [-16.485971000999939, 19.640694000000167],
                [-16.485971000999939, 19.640139000999966],
                [-16.485695000999954, 19.640139000999966],
                [-16.485695000999954, 19.639861999000175],
                [-16.48541599899994, 19.639861999000175],
                [-16.48541599899994, 19.639303],
                [-16.485138, 19.639303],
                [-16.485138, 19.639029],
                [-16.484859, 19.639029],
                [-16.484859, 19.638750001000176],
                [-16.484583000999919, 19.638750001000176],
                [-16.484583000999919, 19.638193000000172],
                [-16.484305998999957, 19.638193000000172],
                [-16.484305998999957, 19.637917],
                [-16.484027999999853, 19.637917],
                [-16.484027999999853, 19.637638001000028],
                [-16.483749, 19.637638001000028],
                [-16.483749, 19.637362001000099],
                [-16.483473, 19.637362001000099],
                [-16.483473, 19.636806],
                [-16.483194000999958, 19.636806],
                [-16.483194000999958, 19.636250001000064],
                [-16.482915998999943, 19.636250001000064],
                [-16.482915998999943, 19.635695000000112],
                [-16.482639, 19.635695000000112],
                [-16.482639, 19.634863001000099],
                [-16.483473, 19.634863001000099],
                [-16.483473, 19.634582999000031],
                [-16.483749, 19.634582999000031],
                [-16.483749, 19.633193999000184],
                [-16.483473, 19.633193999000184],
                [-16.483473, 19.632641],
                [-16.483194000999958, 19.632641],
                [-16.483194000999958, 19.63208400100018],
                [-16.482915998999943, 19.63208400100018],
                [-16.482915998999943, 19.631529000000171],
                [-16.482639, 19.631529000000171],
                [-16.482639, 19.62764],
                [-16.482915998999943, 19.62764],
                [-16.482915998999943, 19.62708300100013],
                [-16.483194000999958, 19.62708300100013],
                [-16.483194000999958, 19.626527999000189],
                [-16.483473, 19.626527999000189],
                [-16.483473, 19.625975],
                [-16.483749, 19.625975],
                [-16.483749, 19.625692001000118],
                [-16.484027999999853, 19.625692001000118],
                [-16.484027999999853, 19.62541599900004],
                [-16.484305998999957, 19.62541599900004],
                [-16.484305998999957, 19.625975],
                [-16.484583000999919, 19.625975],
                [-16.484583000999919, 19.626249000000143],
                [-16.484305998999957, 19.626249000000143],
                [-16.484305998999957, 19.626803999000117],
                [-16.484027999999853, 19.626803999000117],
                [-16.484027999999853, 19.62708300100013],
                [-16.483749, 19.62708300100013],
                [-16.483749, 19.62764],
                [-16.484027999999853, 19.62764],
                [-16.484027999999853, 19.627914],
                [-16.484583000999919, 19.627914],
                [-16.484583000999919, 19.628471001000037],
                [-16.484859, 19.628471001000037],
                [-16.484859, 19.62875],
                [-16.485971000999939, 19.62875],
                [-16.485971000999939, 19.628471001000037],
                [-16.487917, 19.628471001000037],
                [-16.487917, 19.628197001000103],
                [-16.488194998999916, 19.628197001000103],
                [-16.488194998999916, 19.62764],
                [-16.487917, 19.62764],
                [-16.487917, 19.627361000000121],
                [-16.485971000999939, 19.627361000000121],
                [-16.485971000999939, 19.62708300100013],
                [-16.485695000999954, 19.62708300100013],
                [-16.485695000999954, 19.626249000000143],
                [-16.485971000999939, 19.626249000000143],
                [-16.485971000999939, 19.625139000000104],
                [-16.486252, 19.625139000000104],
                [-16.486252, 19.624582001000135],
                [-16.486527999999851, 19.624582001000135],
                [-16.486527999999851, 19.624306001000036],
                [-16.486802998999963, 19.624306001000036],
                [-16.486802998999963, 19.623751],
                [-16.487082000999919, 19.623751],
                [-16.487082000999919, 19.623472],
                [-16.487917, 19.623472],
                [-16.487917, 19.623751],
                [-16.488194998999916, 19.623751],
                [-16.488194998999916, 19.624026999000023],
                [-16.488474000999929, 19.624026999000023],
                [-16.488474000999929, 19.624306001000036],
                [-16.489026999999851, 19.624306001000036],
                [-16.489026999999851, 19.624582001000135],
                [-16.489305, 19.624582001000135],
                [-16.489305, 19.62541599900004],
                [-16.489586000999907, 19.62541599900004],
                [-16.489586000999907, 19.625975],
                [-16.489861000999952, 19.625975],
                [-16.489861000999952, 19.626527999000189],
                [-16.490139, 19.626527999000189],
                [-16.490139, 19.626803999000117],
                [-16.490417, 19.626803999000117],
                [-16.490417, 19.627361000000121],
                [-16.490695998999911, 19.627361000000121],
                [-16.490695998999911, 19.62764],
                [-16.490973000999929, 19.62764],
                [-16.490973000999929, 19.628197001000103],
                [-16.491249000999915, 19.628197001000103],
                [-16.491249000999915, 19.628471001000037],
                [-16.491528, 19.628471001000037],
                [-16.491528, 19.629028000000176],
                [-16.491807999999821, 19.629028000000176],
                [-16.491807999999821, 19.629583001000185],
                [-16.492082998999933, 19.629583001000185],
                [-16.492082998999933, 19.630418999000085],
                [-16.492361000999836, 19.630418999000085],
                [-16.492361000999836, 19.630694999000184],
                [-16.49264, 19.630694999000184],
                [-16.49264, 19.630972001000032],
                [-16.492916, 19.630972001000032],
                [-16.492916, 19.63125],
                [-16.493472998999891, 19.63125],
                [-16.493472998999891, 19.631529000000171],
                [-16.494582998999931, 19.631529000000171],
                [-16.494582998999931, 19.631805],
                [-16.494862000999888, 19.631805],
                [-16.494862000999888, 19.632360001000109],
                [-16.49513800099993, 19.632360001000109],
                [-16.49513800099993, 19.633193999000184],
                [-16.495417, 19.633193999000184],
                [-16.495417, 19.634863001000099],
                [-16.495694999999898, 19.634863001000099],
                [-16.495694999999898, 19.635970999000108],
                [-16.495417, 19.635970999000108],
                [-16.495417, 19.636528000000112],
                [-16.49513800099993, 19.636528000000112],
                [-16.49513800099993, 19.637081],
                [-16.494862000999888, 19.637081],
                [-16.494862000999888, 19.637362001000099],
                [-16.494582998999931, 19.637362001000099],
                [-16.494582998999931, 19.637917],
                [-16.494305, 19.637917],
                [-16.494305, 19.638193000000172],
                [-16.494028, 19.638193000000172],
                [-16.494028, 19.638750001000176],
                [-16.49375000099991, 19.638750001000176],
                [-16.49375000099991, 19.639029],
                [-16.493472998999891, 19.639029],
                [-16.493472998999891, 19.639303],
                [-16.493195, 19.639303],
                [-16.493195, 19.639585],
                [-16.492916, 19.639585],
                [-16.492916, 19.639861999000175],
                [-16.49264, 19.639861999000175],
                [-16.49264, 19.640139000999966],
                [-16.492361000999836, 19.640139000999966],
                [-16.492361000999836, 19.640694000000167],
                [-16.492082998999933, 19.640694000000167],
                [-16.492082998999933, 19.640972],
                [-16.491807999999821, 19.640972],
                [-16.491807999999821, 19.641250998999965],
                [-16.491528, 19.641250998999965],
                [-16.491528, 19.64180800000014],
                [-16.491249000999915, 19.64180800000014],
                [-16.491249000999915, 19.642084],
                [-16.490973000999929, 19.642084],
                [-16.490973000999929, 19.642639001000077],
                [-16.490695998999911, 19.642639001000077],
                [-16.490695998999911, 19.642918000000179],
                [-16.490417, 19.642918000000179],
                [-16.490417, 19.643194],
                [-16.490139, 19.643194],
                [-16.490139, 19.644306],
                [-16.490417, 19.644306],
                [-16.490417, 19.64708300000018],
                [-16.490139, 19.64708300000018],
                [-16.490139, 19.647362000000157],
                [-16.489305, 19.647362000000157],
                [-16.489305, 19.647637999000153],
                [-16.48875, 19.647637999000153],
                [-16.48875, 19.647917001000167],
                [-16.488474000999929, 19.647917001000167],
                [-16.488474000999929, 19.648195000000158],
                [-16.487638, 19.648195000000158],
                [-16.487638, 19.647917001000167],
                [-16.487082000999919, 19.647917001000167],
                [-16.487082000999919, 19.647637999000153],
                [-16.486802998999963, 19.647637999000153],
                [-16.486802998999963, 19.647362000000157],
                [-16.486527999999851, 19.647362000000157],
                [-16.486527999999851, 19.646805001000132],
              ],
            ],
            [
              [
                [-16.485971, 19.660694000000149],
                [-16.486252, 19.660694000000149],
                [-16.486252, 19.660418],
                [-16.486527999999851, 19.660418],
                [-16.486527999999851, 19.660694000000149],
                [-16.486803, 19.660694000000149],
                [-16.486803, 19.660973000000126],
                [-16.487364, 19.660973000000126],
                [-16.487364, 19.660694000000149],
                [-16.487638, 19.660694000000149],
                [-16.487638, 19.659584000000109],
                [-16.487917, 19.659584000000109],
                [-16.487917, 19.659027],
                [-16.488195, 19.659027],
                [-16.488195, 19.658194],
                [-16.488474, 19.658194],
                [-16.488474, 19.657919],
                [-16.489026999999851, 19.657919],
                [-16.489026999999851, 19.657639000000131],
                [-16.489586, 19.657639000000131],
                [-16.489586, 19.657362000000148],
                [-16.490139, 19.657362000000148],
                [-16.490139, 19.657084],
                [-16.492083, 19.657084],
                [-16.492083, 19.657362000000148],
                [-16.492361, 19.657362000000148],
                [-16.492361, 19.657639000000131],
                [-16.49264, 19.657639000000131],
                [-16.49264, 19.657919],
                [-16.492916, 19.657919],
                [-16.492916, 19.658751000000109],
                [-16.493195, 19.658751000000109],
                [-16.493195, 19.659027],
                [-16.492916, 19.659027],
                [-16.492916, 19.659306],
                [-16.49264, 19.659306],
                [-16.49264, 19.659861000000149],
                [-16.492361, 19.659861000000149],
                [-16.492361, 19.660139],
                [-16.492083, 19.660139],
                [-16.492083, 19.660694000000149],
                [-16.491807999999821, 19.660694000000149],
                [-16.491807999999821, 19.661806000000126],
                [-16.492083, 19.661806000000126],
                [-16.492083, 19.662083000000109],
                [-16.491807999999821, 19.662083000000109],
                [-16.491807999999821, 19.662361],
                [-16.491528, 19.662361],
                [-16.491528, 19.662640000000181],
                [-16.488474, 19.662640000000181],
                [-16.488474, 19.662361],
                [-16.487638, 19.662361],
                [-16.487638, 19.662083000000109],
                [-16.486252, 19.662083000000109],
                [-16.486252, 19.661249],
                [-16.485971, 19.661249],
                [-16.485971, 19.660694000000149],
              ],
            ],
            [
              [
                [-16.350415999999882, 19.864305000000172],
                [-16.350142, 19.864305000000172],
                [-16.350142, 19.864027],
                [-16.348748999999884, 19.864027],
                [-16.348748999999884, 19.863474000000167],
                [-16.348472999999899, 19.863474000000167],
                [-16.348472999999899, 19.863195000000189],
                [-16.347917999999879, 19.863194],
                [-16.347917999999879, 19.862917],
                [-16.347637, 19.862917],
                [-16.347637, 19.862638000000118],
                [-16.346527, 19.862638000000118],
                [-16.346527, 19.862362000000189],
                [-16.346250999999882, 19.862362000000189],
                [-16.346250999999882, 19.862083],
                [-16.345972, 19.862083],
                [-16.345972, 19.860971000000177],
                [-16.345694, 19.860971000000177],
                [-16.345694, 19.860695],
                [-16.345414999999832, 19.860695],
                [-16.345414999999832, 19.860140000000172],
                [-16.345139, 19.860140000000172],
                [-16.345139, 19.8595830000001],
                [-16.344862, 19.8595830000001],
                [-16.344862, 19.859306000000117],
                [-16.344583999999884, 19.859306000000117],
                [-16.344583999999884, 19.859030000000189],
                [-16.344307, 19.859030000000189],
                [-16.344307, 19.858747],
                [-16.343749999999829, 19.858747],
                [-16.343749999999829, 19.858473000000117],
                [-16.343472, 19.858473000000117],
                [-16.343472, 19.858194],
                [-16.342638, 19.858194],
                [-16.342638, 19.857918],
                [-16.341806, 19.857918],
                [-16.341806, 19.857639000000177],
                [-16.341249, 19.857639000000177],
                [-16.341249, 19.857361],
                [-16.340414, 19.857361],
                [-16.340414, 19.8570840000001],
                [-16.339583999999832, 19.8570840000001],
                [-16.339583999999832, 19.856808000000171],
                [-16.338752999999883, 19.856808000000171],
                [-16.338752999999883, 19.856525],
                [-16.337917, 19.856525],
                [-16.337917, 19.8562510000001],
                [-16.337084, 19.8562510000001],
                [-16.337084, 19.855415],
                [-16.336805, 19.855415],
                [-16.336805, 19.855139000000122],
                [-16.336527, 19.855139000000122],
                [-16.336527, 19.854860000000144],
                [-16.336248, 19.854860000000144],
                [-16.336248, 19.854584],
                [-16.335972, 19.854584],
                [-16.335972, 19.854029],
                [-16.335695, 19.854029],
                [-16.335695, 19.852917000000105],
                [-16.335417, 19.852917000000105],
                [-16.335417, 19.852638000000127],
                [-16.335138, 19.852638000000127],
                [-16.335138, 19.852083],
                [-16.334862, 19.852083],
                [-16.334862, 19.851805000000127],
                [-16.334583, 19.851805000000127],
                [-16.334583, 19.850416000000109],
                [-16.334305, 19.850416000000109],
                [-16.334305, 19.848194000000149],
                [-16.334583, 19.848194000000149],
                [-16.334583, 19.847639],
                [-16.334862, 19.847639],
                [-16.334862, 19.847084000000109],
                [-16.335138, 19.847084000000109],
                [-16.335138, 19.846527],
                [-16.335417, 19.846527],
                [-16.335417, 19.845972000000131],
                [-16.335695, 19.845972000000131],
                [-16.335695, 19.846251000000109],
                [-16.335972, 19.846251000000109],
                [-16.335972, 19.846527],
                [-16.336527, 19.846527],
                [-16.336527, 19.846251000000109],
                [-16.336805, 19.846251000000109],
                [-16.336805, 19.844307],
                [-16.336527, 19.844307],
                [-16.336527, 19.841805000000136],
                [-16.336805, 19.841805000000136],
                [-16.336805, 19.840973],
                [-16.337084, 19.840973],
                [-16.337084, 19.839861],
                [-16.33736, 19.839861],
                [-16.33736, 19.838751],
                [-16.337639, 19.838751],
                [-16.337639, 19.83680500000014],
                [-16.33736, 19.83680500000014],
                [-16.33736, 19.835138],
                [-16.337639, 19.835138],
                [-16.337639, 19.834862000000157],
                [-16.337917, 19.834862000000157],
                [-16.337917, 19.834305],
                [-16.338194, 19.834305],
                [-16.338194, 19.834028],
                [-16.33847, 19.834028],
                [-16.33847, 19.833752000000175],
                [-16.338752999999883, 19.833752000000175],
                [-16.338752999999883, 19.833471],
                [-16.339027, 19.833471],
                [-16.339027, 19.833195],
                [-16.339306, 19.833195],
                [-16.339306, 19.83264000000014],
                [-16.339583999999832, 19.83264000000014],
                [-16.339583999999832, 19.832083],
                [-16.339863, 19.832083],
                [-16.339863, 19.831530000000157],
                [-16.340139, 19.831530000000157],
                [-16.340139, 19.830973],
                [-16.340414, 19.830973],
                [-16.340414, 19.830694000000108],
                [-16.340694, 19.830694000000108],
                [-16.340694, 19.830418000000179],
                [-16.340975, 19.830418000000179],
                [-16.340975, 19.830137],
                [-16.341806, 19.830137],
                [-16.341806, 19.829861000000108],
                [-16.342361, 19.829861000000108],
                [-16.342361, 19.829584],
                [-16.343472, 19.829584],
                [-16.343472, 19.82930800000014],
                [-16.343749999999829, 19.82930800000014],
                [-16.343749999999829, 19.829025000000172],
                [-16.344028, 19.829025000000172],
                [-16.344028, 19.828751],
                [-16.344307, 19.828751],
                [-16.344307, 19.828194000000167],
                [-16.344583999999884, 19.828194000000167],
                [-16.344583999999884, 19.827362000000107],
                [-16.344862, 19.827360000000112],
                [-16.344862, 19.825693000000172],
                [-16.345139, 19.825693000000172],
                [-16.345139, 19.825138],
                [-16.344862, 19.825138],
                [-16.344862, 19.823750000000189],
                [-16.344583999999884, 19.823750000000189],
                [-16.344583999999884, 19.823195000000112],
                [-16.344307, 19.823195000000112],
                [-16.344307, 19.822916],
                [-16.344028, 19.822916],
                [-16.344028, 19.822363000000166],
                [-16.343749999999829, 19.822363000000166],
                [-16.343749999999829, 19.821806],
                [-16.343472, 19.821806],
                [-16.343472, 19.821527000000117],
                [-16.343197, 19.821527000000117],
                [-16.343197, 19.820694000000117],
                [-16.343472, 19.820694000000117],
                [-16.343472, 19.820141],
                [-16.343749999999829, 19.820141],
                [-16.343749999999829, 19.819584],
                [-16.344028, 19.819584],
                [-16.344028, 19.819029000000171],
                [-16.344862, 19.819029000000171],
                [-16.344862, 19.81875],
                [-16.345139, 19.81875],
                [-16.345139, 19.818472],
                [-16.345414999999832, 19.818472],
                [-16.345414999999832, 19.817919000000188],
                [-16.345694, 19.817919000000188],
                [-16.345694, 19.817636],
                [-16.345972, 19.817636],
                [-16.345972, 19.817083000000139],
                [-16.346527, 19.817083000000139],
                [-16.346527, 19.816526],
                [-16.346806, 19.816526],
                [-16.346806, 19.816250000000139],
                [-16.347083999999882, 19.816250000000139],
                [-16.347083999999882, 19.815971000000104],
                [-16.347361, 19.815971000000104],
                [-16.347361, 19.815414],
                [-16.347637, 19.815414],
                [-16.347637, 19.814861000000121],
                [-16.347917999999879, 19.814861000000121],
                [-16.347917999999879, 19.814028000000121],
                [-16.348194, 19.814028000000121],
                [-16.348194, 19.812916000000143],
                [-16.348472999999899, 19.812916000000143],
                [-16.348472999999899, 19.812082],
                [-16.348748999999884, 19.812082],
                [-16.348748999999884, 19.808472000000108],
                [-16.348472999999899, 19.808472000000108],
                [-16.348472999999899, 19.805695],
                [-16.348194, 19.805695],
                [-16.348194, 19.804583],
                [-16.347917999999879, 19.804583],
                [-16.347917999999879, 19.804308],
                [-16.347361, 19.804308],
                [-16.347361, 19.803747000000158],
                [-16.346250999999882, 19.803747000000158],
                [-16.346250999999882, 19.804029],
                [-16.345972, 19.804029],
                [-16.345972, 19.804308],
                [-16.341806, 19.804308],
                [-16.341806, 19.804029],
                [-16.340975, 19.804029],
                [-16.340975, 19.803747000000158],
                [-16.339027, 19.803747000000158],
                [-16.339027, 19.803473],
                [-16.33847, 19.803473],
                [-16.33847, 19.803196],
                [-16.337917, 19.803196],
                [-16.337917, 19.802637000000175],
                [-16.337639, 19.802637000000175],
                [-16.337639, 19.802086],
                [-16.33736, 19.802086],
                [-16.33736, 19.800972],
                [-16.337639, 19.800972],
                [-16.337639, 19.800694000000135],
                [-16.337917, 19.800694000000135],
                [-16.337917, 19.800417000000152],
                [-16.338752999999883, 19.800417000000152],
                [-16.338752999999883, 19.800138000000175],
                [-16.339027, 19.800138000000175],
                [-16.339027, 19.799862],
                [-16.339306, 19.799862],
                [-16.339306, 19.800417000000152],
                [-16.339863, 19.800417000000152],
                [-16.339863, 19.800694000000135],
                [-16.340414, 19.800694000000135],
                [-16.340414, 19.800972],
                [-16.341528, 19.800972],
                [-16.341528, 19.801250000000152],
                [-16.341806, 19.801250000000152],
                [-16.341806, 19.801525],
                [-16.342084999999884, 19.801525],
                [-16.342084999999884, 19.801807],
                [-16.342361, 19.801807],
                [-16.342361, 19.802086],
                [-16.342638, 19.802086],
                [-16.342638, 19.802361],
                [-16.342915999999832, 19.802361],
                [-16.342915999999832, 19.802637000000175],
                [-16.343472, 19.802637000000175],
                [-16.343472, 19.802916000000153],
                [-16.343749999999829, 19.802916000000153],
                [-16.343749999999829, 19.803196],
                [-16.344862, 19.803196],
                [-16.344862, 19.802916000000153],
                [-16.345139, 19.802916000000153],
                [-16.345139, 19.802637000000175],
                [-16.345694, 19.802637000000175],
                [-16.345694, 19.802086],
                [-16.345414999999832, 19.802086],
                [-16.345414999999832, 19.801250000000152],
                [-16.345139, 19.801250000000152],
                [-16.345139, 19.800417000000152],
                [-16.344862, 19.800417000000152],
                [-16.344862, 19.796528],
                [-16.345139, 19.796528],
                [-16.345139, 19.794584000000157],
                [-16.345414999999832, 19.794582000000162],
                [-16.345414999999832, 19.793751000000157],
                [-16.345694, 19.793751000000157],
                [-16.345694, 19.790695],
                [-16.345414999999832, 19.790695],
                [-16.345414999999832, 19.789862],
                [-16.344583999999884, 19.789862],
                [-16.344583999999884, 19.790419000000156],
                [-16.344307, 19.790419000000156],
                [-16.344307, 19.790972],
                [-16.344028, 19.790972],
                [-16.344028, 19.791250000000161],
                [-16.343749999999829, 19.791250000000161],
                [-16.343749999999829, 19.791529],
                [-16.343472, 19.791529],
                [-16.343472, 19.791805],
                [-16.343197, 19.791805],
                [-16.343197, 19.79236],
                [-16.342915999999832, 19.79236],
                [-16.342915999999832, 19.792641000000174],
                [-16.342361, 19.792641000000174],
                [-16.342361, 19.792917000000102],
                [-16.342084999999884, 19.792917000000102],
                [-16.342084999999884, 19.793194],
                [-16.341528, 19.793194],
                [-16.341528, 19.793472000000179],
                [-16.340975, 19.793472000000179],
                [-16.340975, 19.793751000000157],
                [-16.340694, 19.793751000000157],
                [-16.340694, 19.794027],
                [-16.340139, 19.794027],
                [-16.340139, 19.794306],
                [-16.339583999999832, 19.794306],
                [-16.339583999999832, 19.794584000000157],
                [-16.339306, 19.794584000000157],
                [-16.339306, 19.794861],
                [-16.338752999999883, 19.794861],
                [-16.338752999999883, 19.795139],
                [-16.337917, 19.795139],
                [-16.337917, 19.795416000000102],
                [-16.337084, 19.795416000000102],
                [-16.337084, 19.795694],
                [-16.335972, 19.795694],
                [-16.335972, 19.795973000000174],
                [-16.334862, 19.795973000000174],
                [-16.334862, 19.796249000000103],
                [-16.333470999999861, 19.796249000000103],
                [-16.333470999999861, 19.796528],
                [-16.331806, 19.796528],
                [-16.331806, 19.796806000000174],
                [-16.330694, 19.796806000000174],
                [-16.330694, 19.797083000000157],
                [-16.329584, 19.797083000000157],
                [-16.329584, 19.797361],
                [-16.328751, 19.797361],
                [-16.328751, 19.794861],
                [-16.329027, 19.794861],
                [-16.329027, 19.794306],
                [-16.32930599999986, 19.794306],
                [-16.32930599999986, 19.794027],
                [-16.329861, 19.794027],
                [-16.329861, 19.793751000000157],
                [-16.330973, 19.793751000000157],
                [-16.330973, 19.793472000000179],
                [-16.331249, 19.793472000000179],
                [-16.331249, 19.793194],
                [-16.332083, 19.793194],
                [-16.332083, 19.792917000000102],
                [-16.33264, 19.792917000000102],
                [-16.33264, 19.792641000000174],
                [-16.333195, 19.792641000000174],
                [-16.333195, 19.79236],
                [-16.333470999999861, 19.79236],
                [-16.333470999999861, 19.792084000000102],
                [-16.33375, 19.792084000000102],
                [-16.33375, 19.791805],
                [-16.334028, 19.791805],
                [-16.334028, 19.791250000000161],
                [-16.334305, 19.791250000000161],
                [-16.334305, 19.790972],
                [-16.334583, 19.790972],
                [-16.334583, 19.790419000000156],
                [-16.334862, 19.790419000000156],
                [-16.334862, 19.789583000000107],
                [-16.335138, 19.789583000000107],
                [-16.335138, 19.788750000000107],
                [-16.335417, 19.788750000000107],
                [-16.335417, 19.787914],
                [-16.335695, 19.787914],
                [-16.335695, 19.787361],
                [-16.335972, 19.787361],
                [-16.335972, 19.787083000000166],
                [-16.336248, 19.787083000000166],
                [-16.336248, 19.786804000000188],
                [-16.336527, 19.786804000000188],
                [-16.336527, 19.786528],
                [-16.337084, 19.786528],
                [-16.337084, 19.786249000000112],
                [-16.33736, 19.786249000000112],
                [-16.33736, 19.785973000000183],
                [-16.33847, 19.785973000000183],
                [-16.33847, 19.785692],
                [-16.339027, 19.785692],
                [-16.339027, 19.785418000000107],
                [-16.339306, 19.785418000000107],
                [-16.339306, 19.785139],
                [-16.339583999999832, 19.785139],
                [-16.339583999999832, 19.784861],
                [-16.339863, 19.784861],
                [-16.339863, 19.784582],
                [-16.340139, 19.784582],
                [-16.340139, 19.784306],
                [-16.340414, 19.784306],
                [-16.340414, 19.784027],
                [-16.340694, 19.784027],
                [-16.340694, 19.783751000000166],
                [-16.340975, 19.783751000000166],
                [-16.340975, 19.782917000000111],
                [-16.340694, 19.782917000000111],
                [-16.340694, 19.78236],
                [-16.340414, 19.78236],
                [-16.340414, 19.781805000000134],
                [-16.339583999999832, 19.781805000000134],
                [-16.339583999999832, 19.781252000000165],
                [-16.339306, 19.781252000000165],
                [-16.339306, 19.780140000000188],
                [-16.339027, 19.780140000000188],
                [-16.339027, 19.777639],
                [-16.339306, 19.777639],
                [-16.339306, 19.776808000000187],
                [-16.338194, 19.776808000000187],
                [-16.338194, 19.777361],
                [-16.337917, 19.777361],
                [-16.337917, 19.777639],
                [-16.337639, 19.777639],
                [-16.337639, 19.777918],
                [-16.33736, 19.777918],
                [-16.33736, 19.778749],
                [-16.337084, 19.778749],
                [-16.337084, 19.779306000000133],
                [-16.336805, 19.779306000000133],
                [-16.336805, 19.779861],
                [-16.336527, 19.779861],
                [-16.336527, 19.780140000000188],
                [-16.335695, 19.780140000000188],
                [-16.335695, 19.780416000000116],
                [-16.335417, 19.780416000000116],
                [-16.335417, 19.782084000000111],
                [-16.335695, 19.782084000000111],
                [-16.335695, 19.783472000000188],
                [-16.335972, 19.783472000000188],
                [-16.335972, 19.784306],
                [-16.335695, 19.784306],
                [-16.335695, 19.784861],
                [-16.335417, 19.784861],
                [-16.335417, 19.785139],
                [-16.334862, 19.785139],
                [-16.334862, 19.785418000000107],
                [-16.334028, 19.785418000000107],
                [-16.334028, 19.785692],
                [-16.33375, 19.785692],
                [-16.33375, 19.786249000000112],
                [-16.333470999999861, 19.786249000000112],
                [-16.333470999999861, 19.786528],
                [-16.333195, 19.786528],
                [-16.333195, 19.787083000000166],
                [-16.332916, 19.787083000000166],
                [-16.332916, 19.78764],
                [-16.33264, 19.78764],
                [-16.33264, 19.787914],
                [-16.332361, 19.787914],
                [-16.332361, 19.788197],
                [-16.332083, 19.788197],
                [-16.332083, 19.788473],
                [-16.331806, 19.788473],
                [-16.331806, 19.788750000000107],
                [-16.331249, 19.788750000000107],
                [-16.331249, 19.789026],
                [-16.330973, 19.789026],
                [-16.330973, 19.789305000000184],
                [-16.330694, 19.789305000000184],
                [-16.330694, 19.789583000000107],
                [-16.330418, 19.789583000000107],
                [-16.330416, 19.789862],
                [-16.330138999999861, 19.789862],
                [-16.330138999999861, 19.790136000000189],
                [-16.329861, 19.790136000000189],
                [-16.329861, 19.790419000000156],
                [-16.329584, 19.790419000000156],
                [-16.329584, 19.790695],
                [-16.327638999999863, 19.790695],
                [-16.327638999999863, 19.790419000000156],
                [-16.327362, 19.790419000000156],
                [-16.327362, 19.789862],
                [-16.328196, 19.789862],
                [-16.328196, 19.789583000000107],
                [-16.328471999999863, 19.789583000000107],
                [-16.328471999999863, 19.789305000000184],
                [-16.328751, 19.789305000000184],
                [-16.328751, 19.788750000000107],
                [-16.327638999999863, 19.788750000000107],
                [-16.327638999999863, 19.788473],
                [-16.327362, 19.788473],
                [-16.32736, 19.788197],
                [-16.327084, 19.788197],
                [-16.327084, 19.787914],
                [-16.32680299999987, 19.787914],
                [-16.32680299999987, 19.78764],
                [-16.32625, 19.78764],
                [-16.32625, 19.787361],
                [-16.32597399999986, 19.787361],
                [-16.32597399999986, 19.787083000000166],
                [-16.325138, 19.787083000000166],
                [-16.325138, 19.786528],
                [-16.32375, 19.786528],
                [-16.32375, 19.787083000000166],
                [-16.324306, 19.787083000000166],
                [-16.324306, 19.787361],
                [-16.324581, 19.787361],
                [-16.324581, 19.78764],
                [-16.325138, 19.78764],
                [-16.325138, 19.787914],
                [-16.325417, 19.787914],
                [-16.325417, 19.788473],
                [-16.325695, 19.788473],
                [-16.325695, 19.788750000000107],
                [-16.32597399999986, 19.788750000000107],
                [-16.32597399999986, 19.789583000000107],
                [-16.324863, 19.789583000000107],
                [-16.324863, 19.789862],
                [-16.324306, 19.789862],
                [-16.324305, 19.789583000000107],
                [-16.324028, 19.789583000000107],
                [-16.324028, 19.789305000000184],
                [-16.32347099999987, 19.789305000000184],
                [-16.32347099999987, 19.788473],
                [-16.323195, 19.788473],
                [-16.323195, 19.788197],
                [-16.322639, 19.788197],
                [-16.322639, 19.787914],
                [-16.322358999999892, 19.787914],
                [-16.322358999999892, 19.786804000000188],
                [-16.32097199999987, 19.786804000000188],
                [-16.32097199999987, 19.787361],
                [-16.320694, 19.787361],
                [-16.320694, 19.787914],
                [-16.320417, 19.787914],
                [-16.320417, 19.789862],
                [-16.320694, 19.789862],
                [-16.320694, 19.791529],
                [-16.320417, 19.791529],
                [-16.320417, 19.791805],
                [-16.32013899999987, 19.791805],
                [-16.32013899999987, 19.791529],
                [-16.319859999999892, 19.791529],
                [-16.319859999999892, 19.791250000000161],
                [-16.319029, 19.791250000000161],
                [-16.319029, 19.790695],
                [-16.318471999999872, 19.790695],
                [-16.318471999999872, 19.790136000000189],
                [-16.31875, 19.790136000000189],
                [-16.31875, 19.789026],
                [-16.319029, 19.789026],
                [-16.319029, 19.788750000000107],
                [-16.319304999999872, 19.788750000000107],
                [-16.319304999999872, 19.788197],
                [-16.319584, 19.788197],
                [-16.319584, 19.787914],
                [-16.319859999999892, 19.787914],
                [-16.319859999999892, 19.78764],
                [-16.32013899999987, 19.78764],
                [-16.32013899999987, 19.787083000000166],
                [-16.320417, 19.787083000000166],
                [-16.320417, 19.786528],
                [-16.320694, 19.786528],
                [-16.320694, 19.785692],
                [-16.32097199999987, 19.785692],
                [-16.32097199999987, 19.785139],
                [-16.321251, 19.785139],
                [-16.321251, 19.784861],
                [-16.321527, 19.784861],
                [-16.321527, 19.784582],
                [-16.322639, 19.784582],
                [-16.322639, 19.784027],
                [-16.323195, 19.784027],
                [-16.323195, 19.784582],
                [-16.32375, 19.784582],
                [-16.32375, 19.784861],
                [-16.324581, 19.784861],
                [-16.324581, 19.785139],
                [-16.325138, 19.785139],
                [-16.325138, 19.785418000000107],
                [-16.325417, 19.785418000000107],
                [-16.325417, 19.785692],
                [-16.32597399999986, 19.785692],
                [-16.32597399999986, 19.785973000000183],
                [-16.326529, 19.785973000000183],
                [-16.326529, 19.786249000000112],
                [-16.327084, 19.786249000000112],
                [-16.327084, 19.786528],
                [-16.327638999999863, 19.786528],
                [-16.327638999999863, 19.786804000000188],
                [-16.328196, 19.786804000000188],
                [-16.328196, 19.787083000000166],
                [-16.329027, 19.787083000000166],
                [-16.329027, 19.787361],
                [-16.329861, 19.787361],
                [-16.329861, 19.787083000000166],
                [-16.330416, 19.787083000000166],
                [-16.330416, 19.786804000000188],
                [-16.330694, 19.786804000000188],
                [-16.330694, 19.786528],
                [-16.330973, 19.786528],
                [-16.330973, 19.786249000000112],
                [-16.331249, 19.786249000000112],
                [-16.331249, 19.785973000000183],
                [-16.331528, 19.785973000000183],
                [-16.331528, 19.785692],
                [-16.331806, 19.785692],
                [-16.331806, 19.785418000000107],
                [-16.332083, 19.785418000000107],
                [-16.332083, 19.784861],
                [-16.332361, 19.784861],
                [-16.332361, 19.784582],
                [-16.33264, 19.784582],
                [-16.33264, 19.784306],
                [-16.332916, 19.784306],
                [-16.332916, 19.783472000000188],
                [-16.333195, 19.783472000000188],
                [-16.333195, 19.782639000000188],
                [-16.333470999999861, 19.782639000000188],
                [-16.333470999999861, 19.781528],
                [-16.33375, 19.781528],
                [-16.33375, 19.780140000000188],
                [-16.334028, 19.780140000000188],
                [-16.334028, 19.77903],
                [-16.334305, 19.77903],
                [-16.334305, 19.778473000000133],
                [-16.334583, 19.778473000000133],
                [-16.334583, 19.777918],
                [-16.334862, 19.777918],
                [-16.334862, 19.777361],
                [-16.335138, 19.777361],
                [-16.335138, 19.777082000000121],
                [-16.335417, 19.777082000000121],
                [-16.335417, 19.776808000000187],
                [-16.335695, 19.776808000000187],
                [-16.335695, 19.776525],
                [-16.335972, 19.776525],
                [-16.335972, 19.776251000000116],
                [-16.336248, 19.776251000000116],
                [-16.336248, 19.775972000000138],
                [-16.336527, 19.775972000000138],
                [-16.336527, 19.775694],
                [-16.337084, 19.775694],
                [-16.337084, 19.775415],
                [-16.33736, 19.775415],
                [-16.33736, 19.775139000000138],
                [-16.337917, 19.775139000000138],
                [-16.337917, 19.774860000000103],
                [-16.33847, 19.774860000000103],
                [-16.33847, 19.774586],
                [-16.338752999999883, 19.774586],
                [-16.338752999999883, 19.774303],
                [-16.339306, 19.774303],
                [-16.339306, 19.774027000000103],
                [-16.339583999999832, 19.774027000000103],
                [-16.339583999999832, 19.773750000000121],
                [-16.340139, 19.773750000000121],
                [-16.340139, 19.773472],
                [-16.340975, 19.773472],
                [-16.340975, 19.773193],
                [-16.341806, 19.773193],
                [-16.341806, 19.77291700000012],
                [-16.342361, 19.77291700000012],
                [-16.342361, 19.772638000000143],
                [-16.342915999999832, 19.772638000000143],
                [-16.342915999999832, 19.772362],
                [-16.343197, 19.772362],
                [-16.343197, 19.772083],
                [-16.343472, 19.772083],
                [-16.343472, 19.771805000000143],
                [-16.343749999999829, 19.771805000000143],
                [-16.343749999999829, 19.77125],
                [-16.344028, 19.77125],
                [-16.344028, 19.770416000000125],
                [-16.344307, 19.770416000000125],
                [-16.344307, 19.769583000000125],
                [-16.344583999999884, 19.769583000000125],
                [-16.344583999999884, 19.768473000000142],
                [-16.344862, 19.768473000000142],
                [-16.344862, 19.767916],
                [-16.345139, 19.767916],
                [-16.345139, 19.767084000000125],
                [-16.345414999999832, 19.767084000000125],
                [-16.345414999999832, 19.766806],
                [-16.347083999999882, 19.766806],
                [-16.347083999999882, 19.766527],
                [-16.347637, 19.766527],
                [-16.347637, 19.766251000000125],
                [-16.348194, 19.766251000000125],
                [-16.348194, 19.765972000000147],
                [-16.348472999999899, 19.765972000000147],
                [-16.348472999999899, 19.765694],
                [-16.348748999999884, 19.765694],
                [-16.348748999999884, 19.765139000000147],
                [-16.349028, 19.765139000000147],
                [-16.349028, 19.764862000000164],
                [-16.349305999999899, 19.764858000000174],
                [-16.349305999999899, 19.764029000000164],
                [-16.349582999999882, 19.764029000000164],
                [-16.349582999999882, 19.763197],
                [-16.349859, 19.763197],
                [-16.349859, 19.762362],
                [-16.350142, 19.762362],
                [-16.350142, 19.76125],
                [-16.349859, 19.76125],
                [-16.349859, 19.760414],
                [-16.350142, 19.760414],
                [-16.350142, 19.759304000000157],
                [-16.350415999999882, 19.759304000000157],
                [-16.350415999999882, 19.759027000000174],
                [-16.350695, 19.759027000000174],
                [-16.350695, 19.758751],
                [-16.351249999999879, 19.758751],
                [-16.351249999999879, 19.758472],
                [-16.351528, 19.758472],
                [-16.351528, 19.758192000000179],
                [-16.351804999999899, 19.758192000000179],
                [-16.351804999999899, 19.757639],
                [-16.352080999999885, 19.757639],
                [-16.352080999999885, 19.756805000000156],
                [-16.352364, 19.756805000000156],
                [-16.352364, 19.755417],
                [-16.352637999999899, 19.755417],
                [-16.352637999999899, 19.754583],
                [-16.352916999999877, 19.754583],
                [-16.352916999999877, 19.754305000000102],
                [-16.353195, 19.754305000000102],
                [-16.353195, 19.753752000000134],
                [-16.353474, 19.753752000000134],
                [-16.353474, 19.753195],
                [-16.353749999999877, 19.753195],
                [-16.353749999999877, 19.752640000000156],
                [-16.354029, 19.752640000000156],
                [-16.354029, 19.751530000000173],
                [-16.354304999999897, 19.751530000000173],
                [-16.354304999999897, 19.750973000000101],
                [-16.35458599999987, 19.750973000000101],
                [-16.35458599999987, 19.750139000000161],
                [-16.35486, 19.750139000000161],
                [-16.35486, 19.749308000000156],
                [-16.355139, 19.749308000000156],
                [-16.355139, 19.748751],
                [-16.355416999999875, 19.748751],
                [-16.355416999999875, 19.747084],
                [-16.355696, 19.747084],
                [-16.355696, 19.745693000000188],
                [-16.355972, 19.745693000000188],
                [-16.355972, 19.744305000000111],
                [-16.356250999999872, 19.744305000000111],
                [-16.356250999999872, 19.74264],
                [-16.356527, 19.74264],
                [-16.356527, 19.741249],
                [-16.356806, 19.741249],
                [-16.356806, 19.739861000000133],
                [-16.357081999999878, 19.739861000000133],
                [-16.357083999999873, 19.738472000000115],
                [-16.357361, 19.738472000000115],
                [-16.357361, 19.737083],
                [-16.357639, 19.737083],
                [-16.357639, 19.735697000000187],
                [-16.35791799999987, 19.735697000000187],
                [-16.35791799999987, 19.734304],
                [-16.358194, 19.734304],
                [-16.358194, 19.732916000000159],
                [-16.35791799999987, 19.732916000000159],
                [-16.35791799999987, 19.730417000000159],
                [-16.358194, 19.730417000000159],
                [-16.358194, 19.729305000000124],
                [-16.358473, 19.729305000000124],
                [-16.358473, 19.727638],
                [-16.358748999999875, 19.727638],
                [-16.358748999999875, 19.727083000000164],
                [-16.359028, 19.727083000000164],
                [-16.359028, 19.726528],
                [-16.359306, 19.726528],
                [-16.359306, 19.725973000000124],
                [-16.359582999999873, 19.725973000000124],
                [-16.359582999999873, 19.725140000000124],
                [-16.359861, 19.725140000000124],
                [-16.359861, 19.724306],
                [-16.360138, 19.724306],
                [-16.360138, 19.722361],
                [-16.360415999999873, 19.722361],
                [-16.360415999999873, 19.721251],
                [-16.360695, 19.721251],
                [-16.360695, 19.720139],
                [-16.360971, 19.720139],
                [-16.360971, 19.719028000000151],
                [-16.36125, 19.719028000000151],
                [-16.36125, 19.717916000000173],
                [-16.361528, 19.717916000000173],
                [-16.361528, 19.717081000000178],
                [-16.361805, 19.717081000000178],
                [-16.361805, 19.71597300000019],
                [-16.36208299999987, 19.71597300000019],
                [-16.36208299999987, 19.714861000000155],
                [-16.362362, 19.714861000000155],
                [-16.362362, 19.714027000000101],
                [-16.362638, 19.714027000000101],
                [-16.362638, 19.711529000000155],
                [-16.362916999999868, 19.711529000000155],
                [-16.362916999999868, 19.711250000000177],
                [-16.362638, 19.711250000000177],
                [-16.362638, 19.710972],
                [-16.36347, 19.710972],
                [-16.36347, 19.710419000000172],
                [-16.363752, 19.710419000000172],
                [-16.363752, 19.709026],
                [-16.364027, 19.709026],
                [-16.364027, 19.70764],
                [-16.364305, 19.70764],
                [-16.364305, 19.705973],
                [-16.364581999999871, 19.705973],
                [-16.364581999999871, 19.704861],
                [-16.364861, 19.704861],
                [-16.364861, 19.704306],
                [-16.365141, 19.704306],
                [-16.365141, 19.703472],
                [-16.365416999999866, 19.703472],
                [-16.365416999999866, 19.702917000000127],
                [-16.365692, 19.702917000000127],
                [-16.365692, 19.702084000000127],
                [-16.365971, 19.702084000000127],
                [-16.365971, 19.701805],
                [-16.366248999999868, 19.701805],
                [-16.366248999999868, 19.701529],
                [-16.366528, 19.701529],
                [-16.366528, 19.701250000000186],
                [-16.366806, 19.701250000000186],
                [-16.366806, 19.700972],
                [-16.367085, 19.700972],
                [-16.367085, 19.70069500000011],
                [-16.367915999999866, 19.70069500000011],
                [-16.367915999999866, 19.700417000000186],
                [-16.368195, 19.700417000000186],
                [-16.368195, 19.700138],
                [-16.368473, 19.700138],
                [-16.368473, 19.69986200000011],
                [-16.36875, 19.69986200000011],
                [-16.36875, 19.699583000000132],
                [-16.369028, 19.699583000000132],
                [-16.369028, 19.699306],
                [-16.369307, 19.699305],
                [-16.369307, 19.69903],
                [-16.369583, 19.69903],
                [-16.369583, 19.698473],
                [-16.369862, 19.698473],
                [-16.369862, 19.698195],
                [-16.370138, 19.698195],
                [-16.370138, 19.697918000000186],
                [-16.370417, 19.697918000000186],
                [-16.370417, 19.697639],
                [-16.370695, 19.697639],
                [-16.370695, 19.697083],
                [-16.370972, 19.697083],
                [-16.370972, 19.696526000000119],
                [-16.37125, 19.696526000000119],
                [-16.37125, 19.695972000000154],
                [-16.371529, 19.695972000000154],
                [-16.371529, 19.695139000000154],
                [-16.371805, 19.695139000000154],
                [-16.371805, 19.694860000000119],
                [-16.372084, 19.694860000000119],
                [-16.372084, 19.694307],
                [-16.372639, 19.694307],
                [-16.372639, 19.693750000000136],
                [-16.372917, 19.693750000000136],
                [-16.372917, 19.693193],
                [-16.373194, 19.693193],
                [-16.373194, 19.692917000000136],
                [-16.373472, 19.692917000000136],
                [-16.373472, 19.692083],
                [-16.373751, 19.692083],
                [-16.373751, 19.690971],
                [-16.374027, 19.690971],
                [-16.374027, 19.689861],
                [-16.374584, 19.689861],
                [-16.374584, 19.689583000000141],
                [-16.374861, 19.689583000000141],
                [-16.374861, 19.689306000000158],
                [-16.375139, 19.689306000000158],
                [-16.375139, 19.689028],
                [-16.375416, 19.689028],
                [-16.375416, 19.688749],
                [-16.375694, 19.688749],
                [-16.375694, 19.688194000000124],
                [-16.376249, 19.688194000000124],
                [-16.376249, 19.687916],
                [-16.376527999999837, 19.687916],
                [-16.376527999999837, 19.687639],
                [-16.376806, 19.687639],
                [-16.376806, 19.687084000000141],
                [-16.377642, 19.687084000000141],
                [-16.377642, 19.686806],
                [-16.377916, 19.686806],
                [-16.377916, 19.686527],
                [-16.378194999999835, 19.686527],
                [-16.378194999999835, 19.685972000000163],
                [-16.379027999999835, 19.685972000000163],
                [-16.379027999999835, 19.685694],
                [-16.379305, 19.685694],
                [-16.379305, 19.685417],
                [-16.379581, 19.685417],
                [-16.379581, 19.685139000000163],
                [-16.379863999999884, 19.685139000000163],
                [-16.379863999999884, 19.684860000000185],
                [-16.380138, 19.684860000000185],
                [-16.380138, 19.684305],
                [-16.380417, 19.684305],
                [-16.380417, 19.683750000000146],
                [-16.380974, 19.683750000000146],
                [-16.380974, 19.683195],
                [-16.381526999999835, 19.683195],
                [-16.381526999999835, 19.68291900000014],
                [-16.381805, 19.68291900000014],
                [-16.381805, 19.682636000000173],
                [-16.382086, 19.682636000000173],
                [-16.382086, 19.682362],
                [-16.382359999999835, 19.682362],
                [-16.382359999999835, 19.681805000000168],
                [-16.382639, 19.681805000000168],
                [-16.382639, 19.680973],
                [-16.382917, 19.680973],
                [-16.382917, 19.680696],
                [-16.383195999999884, 19.680696],
                [-16.383195999999884, 19.68014],
                [-16.383472, 19.68014],
                [-16.383472, 19.679861],
                [-16.383751, 19.679861],
                [-16.383751, 19.67958300000015],
                [-16.38402699999989, 19.67958300000015],
                [-16.38402699999989, 19.679304000000172],
                [-16.384308, 19.679304000000172],
                [-16.384308, 19.679028],
                [-16.384583, 19.679028],
                [-16.384583, 19.678749],
                [-16.384860999999887, 19.678749],
                [-16.384860999999887, 19.678473000000167],
                [-16.385139, 19.678473000000167],
                [-16.385139, 19.678192],
                [-16.385418, 19.678192],
                [-16.385418, 19.67791700000015],
                [-16.385973, 19.67791700000015],
                [-16.385973, 19.676805000000172],
                [-16.38625, 19.676805000000172],
                [-16.38625, 19.675970000000177],
                [-16.38652599999989, 19.675970000000177],
                [-16.38652599999989, 19.673471000000177],
                [-16.386805, 19.673471000000177],
                [-16.386806, 19.6720830000001],
                [-16.387083, 19.6720830000001],
                [-16.387083, 19.670694],
                [-16.387361999999882, 19.670694],
                [-16.387361999999882, 19.670418],
                [-16.387917, 19.670418],
                [-16.387917, 19.6695840000001],
                [-16.388194999999882, 19.669582000000105],
                [-16.388194999999882, 19.669025],
                [-16.388472, 19.669025],
                [-16.388472, 19.668472000000122],
                [-16.388748, 19.668472000000122],
                [-16.388748, 19.667915],
                [-16.38902899999988, 19.667915],
                [-16.38902899999988, 19.667086],
                [-16.389305, 19.667086],
                [-16.389305, 19.664583],
                [-16.389584, 19.664583],
                [-16.389584, 19.663195],
                [-16.38985799999989, 19.663195],
                [-16.389859999999885, 19.662916000000109],
                [-16.390139, 19.662916000000109],
                [-16.390139, 19.662640000000181],
                [-16.390417, 19.662640000000181],
                [-16.390417, 19.662083000000109],
                [-16.39097, 19.662083000000109],
                [-16.39097, 19.661528],
                [-16.391253, 19.661528],
                [-16.391253, 19.661249],
                [-16.391526999999883, 19.661249],
                [-16.391526999999883, 19.660973000000126],
                [-16.391806, 19.660973000000126],
                [-16.391806, 19.660694000000149],
                [-16.392082, 19.660694000000149],
                [-16.392082, 19.660418],
                [-16.392362999999875, 19.660418],
                [-16.392362999999875, 19.660139],
                [-16.392916, 19.660139],
                [-16.392916, 19.659861000000149],
                [-16.393191999999885, 19.659861000000149],
                [-16.393191999999885, 19.659306],
                [-16.393475, 19.659306],
                [-16.393475, 19.659027],
                [-16.393749, 19.659027],
                [-16.393749, 19.658194],
                [-16.394027999999878, 19.658194],
                [-16.394027999999878, 19.657639000000131],
                [-16.394306, 19.657639000000131],
                [-16.394306, 19.657362000000148],
                [-16.394585, 19.657362000000148],
                [-16.394585, 19.656528],
                [-16.394860999999878, 19.656528],
                [-16.394860999999878, 19.655972],
                [-16.39514, 19.655972],
                [-16.39514, 19.655415000000119],
                [-16.395416, 19.655415000000119],
                [-16.395416, 19.655136000000141],
                [-16.395694999999876, 19.655136000000141],
                [-16.395694999999876, 19.654861000000153],
                [-16.39625, 19.654861000000153],
                [-16.39625, 19.654583],
                [-16.397083, 19.654583],
                [-16.397083, 19.654305000000136],
                [-16.397346, 19.654305000000136],
                [-16.397638, 19.654305000000136],
                [-16.397638, 19.654028000000153],
                [-16.398472, 19.654028000000153],
                [-16.398472, 19.653749000000118],
                [-16.399028999999871, 19.653749000000118],
                [-16.399028999999871, 19.653475],
                [-16.399305, 19.653475],
                [-16.399305, 19.653194],
                [-16.399859999999876, 19.653194],
                [-16.399859999999876, 19.65291400000018],
                [-16.400693999999874, 19.65291400000018],
                [-16.400693999999874, 19.652639000000136],
                [-16.400972, 19.652639000000136],
                [-16.400972, 19.652361],
                [-16.401806, 19.652361],
                [-16.401806, 19.652082],
                [-16.402360999999871, 19.652082],
                [-16.402360999999871, 19.651806000000136],
                [-16.402639, 19.651806000000136],
                [-16.402639, 19.651251],
                [-16.402916, 19.651251],
                [-16.402916, 19.650694000000158],
                [-16.403193999999871, 19.650694000000158],
                [-16.403193999999871, 19.650139],
                [-16.403473, 19.650139],
                [-16.403473, 19.649584000000118],
                [-16.403749, 19.649584000000118],
                [-16.403749, 19.649027],
                [-16.404028, 19.649027],
                [-16.404028, 19.64847200000014],
                [-16.404306, 19.64847200000014],
                [-16.404306, 19.648195000000158],
                [-16.404581, 19.648195000000158],
                [-16.404581, 19.647917],
                [-16.404863, 19.647917],
                [-16.404863, 19.647362000000157],
                [-16.405138, 19.647362000000157],
                [-16.405138, 19.64708300000018],
                [-16.405416, 19.64708300000018],
                [-16.405416, 19.646528],
                [-16.405692999999872, 19.646528],
                [-16.405692999999872, 19.64625000000018],
                [-16.405973, 19.64625000000018],
                [-16.405973, 19.645695],
                [-16.406252, 19.645695],
                [-16.406252, 19.64514000000014],
                [-16.406528, 19.64514000000014],
                [-16.406528, 19.644861000000162],
                [-16.406803, 19.644861000000162],
                [-16.406803, 19.644306],
                [-16.407082, 19.644306],
                [-16.407082, 19.644030000000157],
                [-16.407364, 19.644030000000157],
                [-16.407364, 19.643473],
                [-16.407639, 19.643473],
                [-16.407639, 19.643194],
                [-16.407917, 19.643194],
                [-16.407917, 19.642639000000145],
                [-16.408195, 19.642639000000145],
                [-16.408195, 19.642084],
                [-16.408472, 19.642084],
                [-16.408472, 19.641525000000172],
                [-16.408751, 19.641525000000172],
                [-16.408751, 19.640972],
                [-16.409027, 19.640972],
                [-16.409027, 19.640415000000189],
                [-16.409306, 19.640415000000189],
                [-16.409306, 19.640139],
                [-16.409586, 19.640139],
                [-16.409586, 19.639585],
                [-16.409861, 19.639585],
                [-16.409861, 19.639029],
                [-16.410139, 19.639029],
                [-16.410139, 19.638472],
                [-16.410418, 19.638472],
                [-16.410418, 19.637917],
                [-16.410694, 19.637917],
                [-16.410694, 19.637362000000167],
                [-16.410972999999842, 19.637362000000167],
                [-16.410972999999842, 19.636806],
                [-16.411249, 19.636805],
                [-16.411249, 19.636528000000112],
                [-16.411528, 19.636528000000112],
                [-16.411528, 19.635971],
                [-16.411805999999842, 19.635971],
                [-16.411805999999842, 19.635416],
                [-16.412083, 19.635416],
                [-16.412083, 19.634863000000166],
                [-16.412361, 19.634863000000166],
                [-16.412361, 19.634306],
                [-16.41264, 19.634306],
                [-16.41264, 19.633751000000188],
                [-16.412916, 19.633751000000188],
                [-16.412916, 19.633472],
                [-16.413195, 19.633472],
                [-16.413195, 19.633194000000117],
                [-16.41347299999984, 19.633194000000117],
                [-16.41347299999984, 19.632641],
                [-16.41375, 19.632641],
                [-16.41375, 19.631805],
                [-16.414028, 19.631805],
                [-16.414028, 19.630695000000117],
                [-16.414304999999842, 19.630695000000117],
                [-16.414304999999842, 19.629862000000116],
                [-16.414583, 19.629862000000116],
                [-16.414583, 19.629583],
                [-16.414862, 19.629583],
                [-16.414862, 19.629307],
                [-16.415137999999843, 19.629307],
                [-16.415137999999843, 19.62875],
                [-16.415417, 19.62875],
                [-16.415417, 19.628197000000171],
                [-16.415695, 19.628197000000171],
                [-16.415695, 19.627914],
                [-16.41597199999984, 19.627914],
                [-16.41597199999984, 19.627083],
                [-16.416248, 19.627083],
                [-16.416248, 19.626528000000121],
                [-16.416529, 19.626528000000121],
                [-16.416529, 19.625975],
                [-16.41680499999984, 19.625975],
                [-16.41680499999984, 19.625692],
                [-16.417084, 19.625692],
                [-16.417084, 19.625416000000143],
                [-16.41736, 19.625416000000143],
                [-16.41736, 19.624306000000104],
                [-16.417638999999895, 19.624306000000104],
                [-16.417638999999895, 19.622084000000143],
                [-16.41736, 19.622084000000143],
                [-16.41736, 19.621822000000122],
                [-16.41736, 19.618750000000148],
                [-16.417638999999895, 19.618750000000148],
                [-16.417638999999895, 19.618195],
                [-16.417917, 19.618195],
                [-16.417917, 19.617916],
                [-16.418469999999843, 19.617916],
                [-16.418469999999843, 19.61736100000013],
                [-16.418753, 19.61736100000013],
                [-16.418753, 19.617083],
                [-16.419863, 19.617083],
                [-16.419863, 19.616808],
                [-16.420138999999892, 19.616808],
                [-16.420138999999892, 19.615973],
                [-16.420416, 19.615973],
                [-16.420416, 19.615417],
                [-16.420692, 19.615417],
                [-16.420692, 19.614861],
                [-16.420974999999885, 19.614861],
                [-16.420974999999885, 19.614586],
                [-16.421249, 19.614586],
                [-16.421249, 19.614307],
                [-16.421528, 19.614307],
                [-16.421528, 19.61402500000014],
                [-16.422085, 19.61402500000014],
                [-16.422085, 19.613750000000152],
                [-16.422637999999893, 19.613750000000152],
                [-16.422637999999893, 19.613472],
                [-16.422916, 19.613472],
                [-16.422916, 19.613194000000135],
                [-16.423197, 19.613194000000135],
                [-16.423197, 19.612917000000152],
                [-16.423470999999893, 19.612917000000152],
                [-16.423470999999893, 19.61180300000018],
                [-16.42375, 19.61180300000018],
                [-16.42375, 19.611528000000135],
                [-16.424028, 19.611528000000135],
                [-16.424028, 19.61125],
                [-16.424862, 19.61125],
                [-16.424862, 19.610971],
                [-16.42513799999989, 19.610971],
                [-16.42513799999989, 19.610416000000157],
                [-16.425417, 19.610416000000157],
                [-16.425417, 19.609861],
                [-16.425694, 19.609861],
                [-16.425694, 19.609583000000157],
                [-16.425971999999888, 19.609583000000157],
                [-16.425971999999888, 19.609306000000174],
                [-16.42625, 19.609306000000174],
                [-16.42625, 19.608749],
                [-16.426529, 19.608749],
                [-16.426529, 19.60819400000014],
                [-16.426804999999888, 19.60819400000014],
                [-16.426804999999888, 19.607916],
                [-16.427084, 19.607916],
                [-16.427084, 19.607361000000139],
                [-16.427361, 19.607361000000139],
                [-16.427361, 19.606806],
                [-16.427636999999891, 19.606806],
                [-16.427636999999891, 19.606251000000157],
                [-16.427917, 19.606251000000157],
                [-16.427917, 19.605694],
                [-16.428194, 19.605694],
                [-16.428194, 19.60486],
                [-16.428472999999883, 19.60486],
                [-16.428472999999883, 19.604029000000139],
                [-16.428749, 19.604029000000139],
                [-16.428749, 19.603472],
                [-16.429028, 19.603472],
                [-16.429028, 19.603195],
                [-16.429305999999883, 19.603195],
                [-16.429305999999883, 19.602636000000189],
                [-16.429583, 19.602636000000189],
                [-16.429583, 19.602362],
                [-16.429859, 19.602362],
                [-16.429859, 19.601807000000179],
                [-16.430139999999881, 19.601807000000179],
                [-16.430139999999881, 19.601526],
                [-16.430416, 19.601526],
                [-16.430416, 19.600973],
                [-16.430695, 19.600973],
                [-16.430695, 19.600697000000139],
                [-16.430970999999886, 19.600697000000139],
                [-16.430970999999886, 19.600414000000171],
                [-16.43125, 19.600414000000171],
                [-16.43125, 19.60014],
                [-16.431528, 19.60014],
                [-16.431528, 19.599861],
                [-16.431804999999883, 19.599861],
                [-16.431804999999883, 19.599304000000188],
                [-16.432081, 19.599304000000188],
                [-16.432081, 19.599028],
                [-16.432364, 19.599028],
                [-16.432364, 19.598473000000183],
                [-16.432637999999884, 19.598473000000183],
                [-16.432637999999884, 19.598192],
                [-16.432772, 19.598192],
                [-16.432917, 19.598192],
                [-16.432913, 19.598473000000183],
                [-16.433195, 19.598473000000183],
                [-16.433195, 19.598749000000112],
                [-16.43375, 19.598749000000112],
                [-16.43375, 19.599028],
                [-16.434029, 19.599028],
                [-16.434029, 19.599304000000188],
                [-16.434302999999886, 19.599304000000188],
                [-16.434302999999886, 19.599583000000166],
                [-16.434586, 19.599583000000166],
                [-16.434586, 19.599861],
                [-16.43486, 19.599861],
                [-16.43486, 19.60014],
                [-16.435138999999879, 19.60014],
                [-16.435138999999879, 19.600414000000171],
                [-16.435417, 19.600414000000171],
                [-16.435417, 19.600697000000139],
                [-16.435696, 19.600697000000139],
                [-16.435696, 19.601250000000107],
                [-16.435971999999879, 19.601250000000107],
                [-16.435971999999879, 19.601807000000179],
                [-16.436251, 19.601807000000179],
                [-16.436251, 19.602083000000107],
                [-16.436527, 19.602083000000107],
                [-16.436527, 19.602919000000156],
                [-16.436805999999876, 19.602919000000156],
                [-16.436805999999876, 19.603472],
                [-16.437084, 19.603472],
                [-16.437084, 19.604029000000139],
                [-16.437361, 19.604029000000139],
                [-16.437361, 19.604305],
                [-16.437638999999876, 19.604305],
                [-16.437638999999876, 19.604584],
                [-16.437918, 19.604584],
                [-16.437918, 19.60486],
                [-16.438194, 19.60486],
                [-16.438194, 19.605139000000179],
                [-16.438473, 19.605139000000179],
                [-16.438473, 19.605694],
                [-16.438749, 19.605694],
                [-16.438749, 19.606806],
                [-16.438473, 19.606806],
                [-16.438473, 19.607084000000157],
                [-16.437638999999876, 19.607084000000157],
                [-16.437638999999876, 19.607361000000139],
                [-16.437361, 19.607361000000139],
                [-16.437361, 19.609861],
                [-16.437084, 19.609861],
                [-16.437095999999883, 19.61013000000014],
                [-16.436805999999876, 19.61014],
                [-16.436805999999876, 19.610416000000157],
                [-16.436527, 19.610416000000157],
                [-16.436527, 19.612364],
                [-16.436805999999876, 19.612364],
                [-16.436805999999876, 19.613472],
                [-16.437084, 19.613472],
                [-16.437084, 19.613750000000152],
                [-16.437361, 19.613750000000152],
                [-16.437361, 19.61402500000014],
                [-16.437918, 19.61402500000014],
                [-16.437918, 19.614307],
                [-16.438194, 19.614307],
                [-16.438194, 19.614586],
                [-16.438749, 19.614586],
                [-16.438749, 19.614861],
                [-16.439028, 19.614861],
                [-16.439028, 19.615696],
                [-16.439306, 19.615696],
                [-16.439306, 19.616808],
                [-16.439583, 19.616808],
                [-16.439583, 19.617083],
                [-16.439861, 19.617083],
                [-16.439861, 19.61736100000013],
                [-16.44014, 19.61736100000013],
                [-16.44014, 19.617640000000108],
                [-16.440416, 19.617640000000108],
                [-16.440416, 19.617916],
                [-16.44125, 19.617916],
                [-16.44125, 19.618195],
                [-16.441805, 19.618195],
                [-16.441805, 19.618473000000108],
                [-16.442638, 19.618473000000108],
                [-16.442638, 19.618750000000148],
                [-16.444305, 19.618750000000148],
                [-16.444305, 19.619028],
                [-16.44504499999988, 19.619028],
                [-16.445139, 19.619028],
                [-16.445118, 19.619308000000103],
                [-16.446252999999899, 19.619305],
                [-16.446252999999899, 19.619583000000148],
                [-16.447363, 19.619583000000148],
                [-16.447363, 19.619862000000126],
                [-16.449862, 19.619862000000126],
                [-16.449862, 19.620138],
                [-16.450138, 19.620138],
                [-16.450138, 19.620417],
                [-16.450697, 19.620417],
                [-16.450697, 19.620695000000126],
                [-16.450972, 19.620695000000126],
                [-16.450972, 19.620972000000108],
                [-16.451249999999845, 19.620972000000108],
                [-16.451249999999845, 19.621805000000109],
                [-16.451529, 19.621822000000122],
                [-16.451529, 19.62236],
                [-16.451805, 19.62236],
                [-16.451805, 19.623194000000126],
                [-16.452084, 19.623194000000126],
                [-16.452084, 19.625975],
                [-16.451529, 19.625975],
                [-16.451529, 19.625692],
                [-16.450697, 19.625692],
                [-16.450697, 19.625416000000143],
                [-16.449862, 19.625416000000143],
                [-16.449862, 19.626249000000143],
                [-16.449582999999848, 19.626249000000143],
                [-16.449582999999848, 19.626528000000121],
                [-16.449862, 19.626528000000121],
                [-16.449862, 19.627083],
                [-16.450138, 19.627083],
                [-16.450138, 19.627361000000121],
                [-16.450416999999845, 19.627361000000121],
                [-16.450416999999845, 19.62764],
                [-16.450697, 19.62764],
                [-16.450697, 19.628197000000171],
                [-16.450972, 19.628197000000171],
                [-16.450972, 19.62875],
                [-16.451249999999845, 19.62875],
                [-16.451249999999845, 19.629028000000176],
                [-16.451529, 19.629028000000176],
                [-16.451529, 19.630136],
                [-16.451249999999845, 19.630136],
                [-16.451249999999845, 19.632641],
                [-16.450972, 19.632641],
                [-16.450972, 19.633472],
                [-16.450697, 19.633472],
                [-16.450697, 19.634306],
                [-16.450416999999845, 19.634306],
                [-16.450416999999845, 19.635416],
                [-16.450697, 19.635416],
                [-16.450697, 19.637638],
                [-16.450972, 19.637638],
                [-16.450972, 19.638193000000172],
                [-16.451249999999845, 19.638193000000172],
                [-16.451249999999845, 19.638472],
                [-16.451529, 19.638472],
                [-16.451529, 19.63875],
                [-16.451805, 19.63875],
                [-16.451805, 19.639585],
                [-16.452084, 19.639585],
                [-16.452084, 19.640415000000189],
                [-16.452362, 19.640415000000189],
                [-16.452362, 19.641251],
                [-16.452639, 19.641251],
                [-16.452639, 19.64180800000014],
                [-16.452917, 19.64180800000014],
                [-16.452917, 19.642639000000145],
                [-16.453194, 19.642639000000145],
                [-16.453194, 19.643194],
                [-16.453472, 19.643194],
                [-16.453472, 19.644030000000157],
                [-16.453750999999897, 19.644030000000157],
                [-16.453750999999897, 19.644583],
                [-16.454027, 19.644583],
                [-16.454027, 19.64514000000014],
                [-16.454306, 19.64514000000014],
                [-16.454306, 19.645695],
                [-16.454583999999898, 19.645695],
                [-16.454583999999898, 19.64625000000018],
                [-16.454861, 19.64625000000018],
                [-16.454861, 19.646805],
                [-16.455137, 19.646805],
                [-16.455137, 19.647362000000157],
                [-16.455416, 19.647362000000157],
                [-16.455417999999895, 19.647917],
                [-16.455694, 19.647917],
                [-16.455694, 19.64875],
                [-16.455973, 19.64875],
                [-16.455973, 19.64930500000014],
                [-16.456249, 19.64930500000014],
                [-16.456249, 19.650139],
                [-16.456528, 19.650139],
                [-16.456528, 19.650417000000118],
                [-16.456806, 19.650417000000118],
                [-16.456806, 19.652639000000136],
                [-16.457082999999898, 19.652639000000136],
                [-16.457082999999898, 19.653194],
                [-16.457359, 19.653194],
                [-16.457359, 19.653749000000118],
                [-16.457642, 19.653749000000118],
                [-16.457642, 19.654583],
                [-16.457359, 19.654583],
                [-16.457359, 19.657084],
                [-16.457642, 19.657084],
                [-16.457642, 19.659306],
                [-16.457915999999898, 19.659306],
                [-16.457915999999898, 19.660418],
                [-16.458195, 19.660418],
                [-16.458195, 19.660694000000149],
                [-16.459581, 19.660694000000149],
                [-16.459581, 19.661249],
                [-16.459864, 19.661249],
                [-16.459864, 19.662640000000181],
                [-16.460138, 19.662640000000181],
                [-16.460138, 19.663471],
                [-16.460416999999893, 19.663471],
                [-16.460416999999893, 19.664028],
                [-16.460694999999816, 19.664028],
                [-16.460694999999816, 19.665417000000105],
                [-16.460974, 19.665417000000105],
                [-16.460974, 19.666803],
                [-16.461249999999893, 19.666803],
                [-16.461249999999893, 19.668194],
                [-16.461527, 19.668194],
                [-16.461527, 19.6687510000001],
                [-16.461805, 19.6687510000001],
                [-16.461805, 19.669308000000171],
                [-16.462085999999886, 19.669308000000171],
                [-16.462085999999886, 19.669861],
                [-16.462639, 19.669861],
                [-16.462639, 19.670694],
                [-16.462916999999891, 19.670694],
                [-16.462916999999891, 19.6720830000001],
                [-16.463196, 19.6720830000001],
                [-16.463196, 19.6729160000001],
                [-16.463472, 19.6729160000001],
                [-16.463472, 19.673195],
                [-16.463750999999888, 19.673195],
                [-16.463750999999888, 19.673471000000177],
                [-16.464026999999817, 19.673471000000177],
                [-16.464026999999817, 19.674028],
                [-16.464308, 19.674028],
                [-16.464308, 19.674583],
                [-16.464581999999893, 19.674583],
                [-16.464581999999893, 19.680973],
                [-16.464860999999814, 19.680973],
                [-16.464860999999814, 19.681805000000168],
                [-16.465139, 19.681805000000168],
                [-16.465139, 19.682636000000173],
                [-16.465417999999886, 19.682636000000173],
                [-16.465417999999886, 19.683472],
                [-16.465693999999814, 19.683472],
                [-16.465693999999814, 19.684584],
                [-16.465973, 19.684584],
                [-16.465973, 19.689583000000141],
                [-16.465693999999814, 19.689583000000141],
                [-16.465693999999814, 19.692083],
                [-16.465417999999886, 19.692083],
                [-16.465417999999886, 19.693472],
                [-16.465139, 19.693472],
                [-16.465139, 19.694586000000186],
                [-16.465417999999886, 19.694586000000186],
                [-16.465417999999886, 19.695139000000154],
                [-16.465139, 19.695139000000154],
                [-16.465139, 19.697361000000114],
                [-16.465417999999886, 19.697361000000114],
                [-16.465417999999886, 19.697639],
                [-16.465973, 19.697639],
                [-16.465973, 19.698195],
                [-16.466248999999891, 19.698195],
                [-16.466248999999891, 19.698473],
                [-16.466528, 19.698473],
                [-16.466528, 19.699583000000132],
                [-16.466806, 19.699583000000132],
                [-16.466806, 19.70069500000011],
                [-16.467082999999889, 19.70069500000011],
                [-16.467082999999889, 19.70236],
                [-16.466806, 19.70236],
                [-16.466806, 19.703472],
                [-16.466528, 19.703472],
                [-16.466528, 19.70402700000011],
                [-16.466248999999891, 19.70402700000011],
                [-16.466248999999891, 19.704861],
                [-16.465973, 19.704861],
                [-16.465973, 19.705692],
                [-16.465693999999814, 19.705692],
                [-16.465693999999814, 19.706249000000128],
                [-16.465417999999886, 19.706249000000128],
                [-16.465417999999886, 19.707083000000182],
                [-16.465139, 19.707083000000182],
                [-16.465139, 19.711805],
                [-16.464860999999814, 19.711805],
                [-16.464860999999814, 19.712917],
                [-16.464581999999893, 19.712917],
                [-16.464581999999893, 19.713472],
                [-16.464308, 19.713472],
                [-16.464308, 19.713751000000173],
                [-16.464026999999817, 19.713751000000173],
                [-16.464026999999817, 19.714306],
                [-16.463750999999888, 19.714306],
                [-16.463750999999888, 19.714861000000155],
                [-16.463472, 19.714861000000155],
                [-16.463472, 19.715694000000155],
                [-16.463196, 19.715694000000155],
                [-16.463196, 19.716249],
                [-16.462916999999891, 19.716249],
                [-16.462916999999891, 19.71680600000019],
                [-16.462639, 19.71680600000019],
                [-16.462639, 19.717361],
                [-16.46236, 19.717361],
                [-16.46236, 19.717916000000173],
                [-16.462085999999886, 19.717916000000173],
                [-16.462085999999886, 19.71875],
                [-16.461805, 19.71875],
                [-16.461805, 19.719028000000151],
                [-16.462916999999891, 19.719028000000151],
                [-16.462916999999891, 19.71875],
                [-16.463472, 19.71875],
                [-16.463472, 19.718193000000156],
                [-16.463750999999888, 19.718193000000156],
                [-16.463750999999888, 19.717916000000173],
                [-16.464026999999817, 19.717916000000173],
                [-16.464026999999817, 19.717361],
                [-16.464308, 19.717361],
                [-16.464308, 19.717081000000178],
                [-16.464581999999893, 19.717081000000178],
                [-16.464581999999893, 19.71680600000019],
                [-16.464860999999814, 19.71680600000019],
                [-16.464860999999814, 19.716528],
                [-16.465139, 19.716528],
                [-16.465139, 19.716249],
                [-16.465417999999886, 19.716249],
                [-16.465417999999886, 19.71597300000019],
                [-16.467082999999889, 19.71597300000019],
                [-16.467082999999889, 19.715416],
                [-16.467915999999889, 19.715416],
                [-16.467915999999889, 19.71597300000019],
                [-16.468195, 19.71597300000019],
                [-16.468195, 19.716528],
                [-16.468472, 19.716528],
                [-16.468472, 19.71680600000019],
                [-16.468747999999891, 19.71680600000019],
                [-16.468747999999891, 19.717081000000178],
                [-16.469028, 19.717081000000178],
                [-16.469028, 19.717361],
                [-16.469305, 19.717361],
                [-16.469305, 19.717638],
                [-16.469583999999884, 19.717638],
                [-16.469583999999884, 19.717916000000173],
                [-16.469859999999869, 19.717916000000173],
                [-16.469859999999869, 19.718193000000156],
                [-16.470139, 19.718193000000156],
                [-16.470139, 19.719028000000151],
                [-16.470416999999884, 19.719028000000151],
                [-16.470416999999884, 19.719584000000168],
                [-16.470694, 19.719584000000168],
                [-16.470694, 19.720415000000173],
                [-16.47097, 19.720415000000173],
                [-16.47097, 19.720972],
                [-16.471527, 19.720972],
                [-16.471527, 19.71847200000019],
                [-16.471252, 19.71847200000019],
                [-16.471252, 19.717081000000178],
                [-16.47097, 19.717081000000178],
                [-16.47097, 19.713751000000173],
                [-16.471252, 19.713751000000173],
                [-16.471252, 19.713194000000101],
                [-16.471527, 19.713194000000101],
                [-16.471527, 19.71264100000019],
                [-16.471806, 19.71264100000019],
                [-16.471806, 19.712084],
                [-16.472081999999887, 19.712084],
                [-16.472081999999887, 19.711805],
                [-16.472361, 19.711805],
                [-16.472361, 19.711529000000155],
                [-16.472639, 19.711529000000155],
                [-16.472639, 19.711250000000177],
                [-16.472918, 19.711250000000177],
                [-16.472918, 19.710695000000101],
                [-16.473191999999869, 19.710695000000101],
                [-16.473191999999869, 19.710136],
                [-16.473475, 19.710136],
                [-16.473475, 19.708197000000155],
                [-16.473191999999869, 19.708197000000155],
                [-16.473191999999869, 19.70764],
                [-16.472918, 19.70764],
                [-16.472918, 19.707083000000182],
                [-16.472639, 19.707083000000182],
                [-16.472639, 19.706804],
                [-16.472918, 19.706804],
                [-16.472918, 19.706528000000105],
                [-16.474306, 19.706528000000105],
                [-16.474306, 19.706249000000128],
                [-16.474585, 19.706249000000128],
                [-16.474585, 19.705416000000127],
                [-16.474861, 19.705416000000127],
                [-16.474861, 19.702639],
                [-16.47514, 19.702639],
                [-16.47514, 19.699583000000132],
                [-16.475415999999882, 19.699583000000132],
                [-16.475415999999882, 19.697918000000186],
                [-16.475695, 19.697918000000186],
                [-16.475695, 19.697361000000114],
                [-16.475973, 19.697361000000114],
                [-16.475973, 19.697083],
                [-16.477362, 19.697083],
                [-16.477362, 19.696808],
                [-16.477638, 19.696808],
                [-16.477638, 19.696251000000132],
                [-16.477917, 19.696251000000132],
                [-16.477917, 19.695972000000154],
                [-16.478195, 19.695972000000154],
                [-16.478195, 19.695415],
                [-16.478472, 19.695415],
                [-16.478472, 19.694860000000119],
                [-16.47875, 19.694860000000119],
                [-16.47875, 19.694586000000186],
                [-16.479029, 19.694586000000186],
                [-16.479029, 19.694307],
                [-16.479584, 19.694307],
                [-16.479584, 19.693750000000136],
                [-16.47986, 19.693750000000136],
                [-16.47986, 19.692083],
                [-16.480139, 19.692083],
                [-16.480139, 19.690416000000141],
                [-16.480417, 19.690416000000141],
                [-16.480417, 19.689028],
                [-16.480694, 19.689028],
                [-16.480694, 19.688194000000124],
                [-16.480972, 19.688194000000124],
                [-16.480972, 19.687361000000124],
                [-16.481251, 19.687361000000124],
                [-16.481251, 19.686251000000141],
                [-16.481527, 19.686251000000141],
                [-16.481527, 19.685972000000163],
                [-16.481806, 19.685972000000163],
                [-16.481806, 19.685417],
                [-16.482082, 19.685417],
                [-16.482084, 19.684584],
                [-16.483903999999882, 19.684584],
                [-16.484027999999853, 19.684584],
                [-16.483974, 19.684292],
                [-16.484583, 19.684305],
                [-16.484583, 19.681793],
                [-16.484583, 19.680696],
                [-16.484859, 19.680696],
                [-16.484859, 19.67958300000015],
                [-16.484583, 19.67958300000015],
                [-16.484583, 19.678192],
                [-16.484859, 19.678192],
                [-16.484859, 19.67791700000015],
                [-16.485416, 19.67791700000015],
                [-16.485416, 19.67736100000019],
                [-16.485694999999851, 19.67736100000019],
                [-16.485694999999851, 19.677624],
                [-16.485971, 19.677639],
                [-16.485971, 19.67791700000015],
                [-16.486803, 19.67791700000015],
                [-16.486803, 19.678192],
                [-16.487364, 19.678192],
                [-16.487364, 19.678473000000167],
                [-16.487917, 19.678473000000167],
                [-16.487917, 19.678749],
                [-16.48875, 19.678749],
                [-16.48875, 19.678473000000167],
                [-16.489026999999851, 19.678473000000167],
                [-16.489026999999851, 19.677639],
                [-16.48875, 19.677639],
                [-16.48875, 19.677082],
                [-16.489026999999851, 19.677082],
                [-16.489026999999851, 19.673195],
                [-16.489586, 19.673195],
                [-16.489586, 19.6729160000001],
                [-16.489861, 19.6729160000001],
                [-16.489861, 19.672640000000172],
                [-16.492916, 19.672640000000172],
                [-16.492916, 19.6720830000001],
                [-16.49375, 19.6720830000001],
                [-16.49375, 19.672640000000172],
                [-16.494583, 19.672640000000172],
                [-16.494583, 19.6729160000001],
                [-16.495138, 19.6729160000001],
                [-16.495138, 19.673195],
                [-16.495417, 19.673195],
                [-16.495417, 19.673471000000177],
                [-16.495694999999898, 19.673471000000177],
                [-16.495694999999898, 19.67375200000015],
                [-16.496248, 19.67375200000015],
                [-16.496248, 19.674028],
                [-16.496528999999896, 19.674028],
                [-16.496528999999896, 19.674305000000118],
                [-16.496805, 19.674305000000118],
                [-16.496805, 19.674583],
                [-16.49736, 19.674583],
                [-16.49736, 19.674862000000189],
                [-16.497638999999822, 19.674862000000189],
                [-16.497638999999822, 19.675138000000118],
                [-16.497917, 19.675138000000118],
                [-16.497917, 19.675417],
                [-16.498193999999899, 19.675417],
                [-16.498193999999899, 19.675694],
                [-16.49847, 19.675694],
                [-16.49847, 19.675970000000177],
                [-16.499026999999899, 19.675970000000177],
                [-16.499026999999899, 19.67625],
                [-16.499582, 19.67625],
                [-16.499582, 19.676527],
                [-16.499862999999891, 19.676527],
                [-16.499862999999891, 19.678192],
                [-16.50013899999982, 19.678192],
                [-16.50013899999982, 19.679028],
                [-16.499862999999891, 19.679028],
                [-16.499862999999891, 19.680973],
                [-16.499582, 19.680973],
                [-16.499582, 19.683195],
                [-16.49930599999982, 19.683195],
                [-16.49930599999982, 19.684860000000185],
                [-16.499026999999899, 19.684860000000185],
                [-16.499026999999899, 19.687084000000141],
                [-16.49930599999982, 19.687084000000141],
                [-16.49930599999982, 19.687361000000124],
                [-16.500692, 19.687361000000124],
                [-16.500692, 19.687639],
                [-16.500975, 19.687639],
                [-16.500975, 19.687916],
                [-16.501249, 19.687916],
                [-16.501249, 19.689306000000158],
                [-16.500975, 19.689306000000158],
                [-16.500975, 19.692083],
                [-16.501713, 19.692083],
                [-16.501805999999874, 19.692083],
                [-16.501805999999874, 19.691729],
                [-16.501805999999874, 19.691528000000119],
                [-16.502085, 19.691528000000119],
                [-16.502085, 19.692083],
                [-16.502360999999894, 19.692083],
                [-16.502360999999894, 19.692638000000159],
                [-16.502639999999872, 19.692638000000159],
                [-16.502639999999872, 19.694307],
                [-16.502360999999894, 19.694307],
                [-16.502360999999894, 19.695139000000154],
                [-16.502639999999872, 19.695139000000154],
                [-16.502639999999872, 19.695696],
                [-16.502916, 19.695696],
                [-16.502916, 19.695972000000154],
                [-16.503196999999886, 19.695972000000154],
                [-16.503196999999886, 19.696526000000119],
                [-16.50347099999982, 19.696526000000119],
                [-16.50347099999982, 19.696808],
                [-16.50375, 19.696808],
                [-16.50375, 19.697639],
                [-16.504027999999892, 19.697639],
                [-16.504027999999892, 19.699305],
                [-16.504306999999869, 19.699306],
                [-16.504306999999869, 19.701250000000186],
                [-16.504027999999892, 19.701250000000186],
                [-16.504027999999892, 19.701805],
                [-16.50375, 19.701805],
                [-16.50375, 19.70236],
                [-16.50347099999982, 19.70236],
                [-16.50347099999982, 19.70319400000011],
                [-16.503196999999886, 19.70319400000011],
                [-16.503196999999886, 19.703751000000182],
                [-16.502916, 19.703751000000182],
                [-16.502916, 19.704582000000187],
                [-16.502639999999872, 19.704582000000187],
                [-16.502639999999872, 19.705692],
                [-16.502360999999894, 19.705692],
                [-16.502360999999894, 19.706528000000105],
                [-16.502085, 19.706528000000105],
                [-16.502085, 19.707361000000105],
                [-16.501805999999874, 19.707361000000105],
                [-16.501805999999874, 19.709307],
                [-16.501527999999894, 19.709307],
                [-16.501527999999894, 19.712917],
                [-16.501249, 19.712917],
                [-16.501249, 19.713751000000173],
                [-16.500975, 19.713751000000173],
                [-16.500975, 19.715416],
                [-16.500692, 19.715416],
                [-16.500692, 19.71680600000019],
                [-16.500975, 19.71680600000019],
                [-16.500975, 19.717361],
                [-16.501249, 19.717361],
                [-16.501249, 19.717638],
                [-16.501527999999894, 19.717638],
                [-16.501527999999894, 19.717916000000173],
                [-16.502360999999894, 19.717916000000173],
                [-16.502360999999894, 19.721251],
                [-16.502085, 19.721251],
                [-16.502085, 19.721807],
                [-16.501805999999874, 19.721807],
                [-16.501805999999874, 19.722361],
                [-16.501527999999894, 19.722361],
                [-16.501527999999894, 19.722918000000163],
                [-16.501249, 19.722918000000163],
                [-16.501249, 19.723194],
                [-16.500975, 19.723194],
                [-16.500975, 19.723747000000174],
                [-16.500692, 19.723747000000174],
                [-16.500692, 19.724306],
                [-16.500416, 19.724306],
                [-16.500416, 19.724583],
                [-16.50013899999982, 19.724583],
                [-16.50013899999982, 19.725140000000124],
                [-16.499862999999891, 19.725140000000124],
                [-16.499862999999891, 19.725416],
                [-16.499582, 19.725416],
                [-16.499582, 19.725695],
                [-16.49930599999982, 19.725695],
                [-16.49930599999982, 19.725973000000124],
                [-16.499026999999899, 19.725973000000124],
                [-16.499026999999899, 19.726528],
                [-16.498753, 19.726528],
                [-16.498753, 19.727083000000164],
                [-16.49847, 19.727083000000164],
                [-16.49847, 19.727362000000142],
                [-16.498193999999899, 19.727362000000142],
                [-16.498193999999899, 19.727917],
                [-16.497917, 19.727917],
                [-16.497917, 19.728472000000124],
                [-16.497638999999822, 19.728472000000124],
                [-16.497638999999822, 19.729029],
                [-16.49736, 19.729029],
                [-16.49736, 19.729305000000124],
                [-16.497084, 19.729305000000124],
                [-16.497084, 19.730139],
                [-16.496805, 19.730139],
                [-16.496805, 19.730694000000142],
                [-16.496528999999896, 19.730694000000142],
                [-16.496528999999896, 19.731251],
                [-16.496248, 19.731251],
                [-16.496248, 19.731527000000142],
                [-16.495972, 19.731527000000142],
                [-16.495972, 19.732082],
                [-16.495694999999898, 19.732082],
                [-16.495694999999898, 19.732361],
                [-16.495417, 19.732361],
                [-16.495417, 19.732916000000159],
                [-16.495138, 19.732916000000159],
                [-16.495138, 19.733194],
                [-16.494861999999898, 19.733194],
                [-16.494861999999898, 19.733749000000159],
                [-16.494583, 19.733749000000159],
                [-16.494583, 19.734304],
                [-16.494305, 19.734304],
                [-16.494305, 19.73513800000012],
                [-16.494028, 19.735140000000115],
                [-16.494028, 19.735697000000187],
                [-16.49375, 19.735697000000187],
                [-16.49375, 19.73625],
                [-16.493473, 19.73625],
                [-16.493473, 19.736528000000192],
                [-16.493195, 19.736528000000192],
                [-16.493195, 19.737083],
                [-16.492916, 19.737083],
                [-16.492916, 19.73763700000012],
                [-16.49264, 19.73763700000012],
                [-16.49264, 19.738194000000192],
                [-16.492361, 19.738195000000132],
                [-16.492361, 19.738472000000115],
                [-16.492083, 19.738472000000115],
                [-16.492083, 19.73875],
                [-16.491807999999821, 19.73875],
                [-16.491807999999821, 19.739029000000187],
                [-16.491528, 19.739029000000187],
                [-16.491528, 19.739305000000115],
                [-16.491249, 19.739305000000115],
                [-16.491249, 19.739584],
                [-16.490973, 19.739584],
                [-16.490973, 19.740141],
                [-16.490696, 19.740141],
                [-16.490696, 19.740417],
                [-16.490417, 19.740417],
                [-16.490417, 19.740694000000133],
                [-16.490139, 19.740694000000133],
                [-16.490139, 19.74097300000011],
                [-16.489861, 19.74097300000011],
                [-16.489861, 19.741249],
                [-16.489026999999851, 19.741249],
                [-16.489026999999851, 19.741528000000187],
                [-16.488474, 19.741528000000187],
                [-16.488474, 19.741806000000111],
                [-16.487364, 19.741806000000111],
                [-16.487364, 19.742083],
                [-16.486803, 19.742083],
                [-16.486803, 19.742361000000187],
                [-16.485971, 19.742361000000187],
                [-16.485971, 19.74264],
                [-16.485142, 19.74264],
                [-16.485138, 19.742916],
                [-16.481527, 19.742916],
                [-16.481527, 19.74264],
                [-16.480694, 19.74264],
                [-16.480694, 19.742361000000187],
                [-16.479584, 19.742361000000187],
                [-16.479584, 19.742083],
                [-16.47875, 19.742083],
                [-16.47875, 19.741806000000111],
                [-16.478195, 19.741806000000111],
                [-16.478195, 19.741528000000187],
                [-16.477638, 19.741528000000187],
                [-16.477638, 19.741249],
                [-16.477362, 19.741249],
                [-16.477362, 19.74097300000011],
                [-16.476807, 19.74097300000011],
                [-16.476807, 19.740694000000133],
                [-16.476528, 19.740694000000133],
                [-16.476528, 19.740417],
                [-16.475695, 19.740417],
                [-16.475695, 19.740141],
                [-16.474306, 19.740141],
                [-16.474306, 19.739861000000133],
                [-16.473748999999884, 19.739861000000133],
                [-16.473748999999884, 19.739584],
                [-16.473191999999869, 19.739584],
                [-16.473191999999869, 19.739305000000115],
                [-16.472918, 19.739305000000115],
                [-16.472918, 19.739029000000187],
                [-16.470416999999884, 19.739029000000187],
                [-16.470416999999884, 19.73875],
                [-16.470139, 19.73875],
                [-16.470139, 19.738472000000115],
                [-16.469583999999884, 19.738472000000115],
                [-16.469583999999884, 19.738195000000132],
                [-16.469028, 19.738194000000192],
                [-16.469028, 19.737919],
                [-16.468472, 19.737919],
                [-16.468472, 19.73763700000012],
                [-16.468195, 19.73763700000012],
                [-16.468195, 19.737362000000132],
                [-16.467915999999889, 19.737362000000132],
                [-16.467915999999889, 19.737083],
                [-16.467361, 19.737083],
                [-16.467361, 19.736807],
                [-16.466806, 19.736807],
                [-16.466806, 19.736528000000192],
                [-16.466248999999891, 19.736528000000192],
                [-16.466248999999891, 19.73625],
                [-16.465693999999814, 19.73625],
                [-16.465693999999814, 19.73597100000012],
                [-16.465417999999886, 19.73597100000012],
                [-16.465417999999886, 19.735697000000187],
                [-16.465139, 19.735697000000187],
                [-16.465139, 19.73541500000016],
                [-16.464581999999893, 19.73541500000016],
                [-16.464581999999893, 19.735140000000115],
                [-16.464308, 19.73513800000012],
                [-16.464308, 19.734861000000137],
                [-16.464026999999817, 19.734861000000137],
                [-16.464026999999817, 19.734583],
                [-16.463750999999888, 19.734583],
                [-16.463750999999888, 19.734304],
                [-16.463472, 19.734304],
                [-16.463472, 19.734028000000137],
                [-16.463196, 19.734028000000137],
                [-16.463196, 19.733473],
                [-16.462916999999891, 19.733473],
                [-16.462916999999891, 19.733194],
                [-16.462639, 19.733194],
                [-16.462639, 19.732916000000159],
                [-16.46236, 19.732916000000159],
                [-16.46236, 19.732082],
                [-16.462085999999886, 19.732082],
                [-16.462085999999886, 19.731251],
                [-16.461805, 19.731251],
                [-16.461805, 19.730417000000159],
                [-16.461527, 19.730417000000159],
                [-16.461527, 19.72986],
                [-16.461249999999893, 19.72986],
                [-16.461249999999893, 19.729305000000124],
                [-16.460974, 19.729305000000124],
                [-16.460974, 19.72875],
                [-16.460694999999816, 19.72875],
                [-16.460694999999816, 19.728195000000142],
                [-16.459864, 19.728195000000142],
                [-16.459864, 19.728472000000124],
                [-16.459581, 19.728472000000124],
                [-16.459581, 19.728195000000142],
                [-16.459305, 19.728195000000142],
                [-16.459305, 19.727917],
                [-16.459028, 19.727917],
                [-16.459028, 19.727638],
                [-16.458471, 19.727638],
                [-16.458471, 19.727362000000142],
                [-16.457359, 19.727362000000142],
                [-16.457359, 19.727638],
                [-16.457082999999898, 19.727638],
                [-16.457082999999898, 19.728195000000142],
                [-16.456806, 19.728195000000142],
                [-16.456806, 19.72875],
                [-16.456528, 19.72875],
                [-16.456528, 19.729029],
                [-16.455694, 19.729029],
                [-16.455694, 19.729305000000124],
                [-16.455417999999895, 19.729305000000124],
                [-16.455416, 19.729584000000159],
                [-16.455137, 19.729584000000159],
                [-16.455137, 19.72986],
                [-16.454861, 19.72986],
                [-16.454861, 19.730139],
                [-16.454583999999898, 19.730139],
                [-16.454583999999898, 19.730417000000159],
                [-16.454027, 19.730417000000159],
                [-16.454027, 19.730694000000142],
                [-16.453472, 19.730694000000142],
                [-16.453472, 19.730972],
                [-16.453194, 19.730972],
                [-16.453194, 19.731251],
                [-16.452639, 19.731251],
                [-16.452639, 19.731527000000142],
                [-16.452084, 19.731527000000142],
                [-16.452084, 19.73180600000012],
                [-16.451805, 19.73180600000012],
                [-16.451805, 19.732082],
                [-16.451249999999845, 19.732082],
                [-16.451249999999845, 19.732361],
                [-16.450697, 19.732361],
                [-16.450697, 19.73263900000012],
                [-16.450138, 19.73263900000012],
                [-16.450138, 19.732916000000159],
                [-16.449582999999848, 19.732916000000159],
                [-16.449582999999848, 19.733194],
                [-16.449306, 19.733194],
                [-16.449306, 19.733473],
                [-16.448749999999848, 19.733473],
                [-16.448749999999848, 19.733749000000159],
                [-16.448475, 19.733749000000159],
                [-16.448475, 19.734028000000137],
                [-16.447914, 19.734028000000137],
                [-16.447914, 19.734304],
                [-16.447639, 19.734304],
                [-16.447639, 19.734861000000137],
                [-16.447363, 19.734861000000137],
                [-16.447363, 19.73513800000012],
                [-16.446806, 19.735140000000115],
                [-16.446806, 19.73541500000016],
                [-16.446527, 19.73541500000016],
                [-16.446527, 19.735697000000187],
                [-16.445971, 19.735697000000187],
                [-16.445971, 19.73597100000012],
                [-16.445692, 19.73597100000012],
                [-16.445692, 19.73625],
                [-16.445417, 19.73625],
                [-16.445417, 19.736528000000192],
                [-16.445139, 19.736528000000192],
                [-16.445139, 19.736807],
                [-16.444584, 19.736807],
                [-16.444584, 19.737083],
                [-16.444305, 19.737083],
                [-16.444305, 19.737362000000132],
                [-16.444027, 19.737362000000132],
                [-16.444027, 19.73763700000012],
                [-16.443472, 19.73763700000012],
                [-16.443472, 19.737919],
                [-16.443195, 19.737919],
                [-16.443195, 19.738194000000192],
                [-16.442638, 19.738195000000132],
                [-16.442638, 19.738472000000115],
                [-16.442362, 19.738472000000115],
                [-16.442362, 19.73875],
                [-16.442638, 19.73875],
                [-16.442638, 19.739029000000187],
                [-16.442362, 19.739029000000187],
                [-16.442362, 19.739305000000115],
                [-16.442083, 19.739305000000115],
                [-16.442083, 19.739584],
                [-16.441805, 19.739584],
                [-16.441805, 19.739861000000133],
                [-16.441528, 19.739861000000133],
                [-16.441528, 19.740141],
                [-16.44125, 19.740141],
                [-16.44125, 19.740417],
                [-16.440970999999877, 19.740417],
                [-16.440970999999877, 19.74097300000011],
                [-16.440416, 19.74097300000011],
                [-16.440416, 19.741249],
                [-16.44014, 19.741249],
                [-16.44014, 19.741528000000187],
                [-16.439583, 19.741528000000187],
                [-16.439583, 19.741806000000111],
                [-16.439028, 19.741806000000111],
                [-16.439028, 19.742083],
                [-16.438473, 19.742083],
                [-16.438473, 19.742361000000187],
                [-16.437638999999876, 19.742361000000187],
                [-16.437638999999876, 19.74264],
                [-16.436805999999876, 19.74264],
                [-16.436805999999876, 19.742916],
                [-16.436527, 19.742916],
                [-16.436527, 19.743195000000128],
                [-16.436251, 19.743195000000128],
                [-16.436251, 19.743471],
                [-16.435971999999879, 19.743471],
                [-16.435971999999879, 19.74375],
                [-16.435696, 19.74375],
                [-16.435696, 19.744028000000128],
                [-16.435417, 19.744028000000128],
                [-16.435417, 19.744581],
                [-16.43486, 19.744581],
                [-16.43486, 19.744862000000182],
                [-16.434586, 19.744862000000182],
                [-16.434586, 19.747639000000106],
                [-16.43486, 19.747639000000106],
                [-16.43486, 19.747915],
                [-16.435138999999879, 19.747915],
                [-16.435138999999879, 19.748472000000106],
                [-16.43486, 19.748472000000106],
                [-16.43486, 19.748751],
                [-16.434586, 19.748751],
                [-16.434586, 19.748472000000106],
                [-16.43375, 19.748472000000106],
                [-16.43375, 19.748751],
                [-16.432364, 19.748751],
                [-16.432364, 19.749025000000188],
                [-16.432081, 19.749025000000188],
                [-16.432081, 19.749308000000156],
                [-16.431804999999883, 19.749308000000156],
                [-16.431804999999883, 19.749861],
                [-16.431528, 19.749861],
                [-16.431528, 19.750139000000161],
                [-16.43125, 19.750139000000161],
                [-16.43125, 19.750418],
                [-16.430970999999886, 19.750418],
                [-16.430970999999886, 19.751247],
                [-16.430695, 19.751247],
                [-16.430695, 19.754028],
                [-16.430970999999886, 19.754028],
                [-16.430970999999886, 19.754862000000173],
                [-16.43125, 19.754862000000173],
                [-16.43125, 19.755138000000102],
                [-16.430970999999886, 19.755138000000102],
                [-16.430970999999886, 19.755695000000173],
                [-16.430695, 19.755695000000173],
                [-16.430695, 19.755972000000156],
                [-16.430139999999881, 19.755972000000156],
                [-16.430139999999881, 19.75625],
                [-16.429583, 19.75625],
                [-16.429583, 19.756527],
                [-16.429305999999883, 19.756527],
                [-16.429305999999883, 19.756805000000156],
                [-16.428749, 19.756805000000156],
                [-16.428749, 19.756527],
                [-16.428194, 19.756527],
                [-16.428194, 19.75625],
                [-16.427636999999891, 19.75625],
                [-16.427636999999891, 19.755695000000173],
                [-16.427361, 19.755695000000173],
                [-16.427361, 19.755417],
                [-16.426804999999888, 19.755417],
                [-16.426804999999888, 19.755695000000173],
                [-16.426529, 19.755695000000173],
                [-16.426529, 19.757639],
                [-16.42625, 19.757639],
                [-16.42625, 19.758751],
                [-16.425971999999888, 19.758751],
                [-16.425971999999888, 19.759861],
                [-16.425695, 19.759861],
                [-16.425694, 19.760975],
                [-16.425417, 19.760975],
                [-16.425417, 19.762636000000157],
                [-16.42513799999989, 19.762636000000157],
                [-16.42513799999989, 19.764305],
                [-16.424862, 19.764305],
                [-16.424862, 19.764862000000164],
                [-16.424583, 19.764862000000164],
                [-16.424583, 19.765417],
                [-16.424306999999885, 19.765417],
                [-16.424306999999885, 19.766527],
                [-16.424028, 19.766527],
                [-16.424028, 19.766806],
                [-16.42375, 19.766806],
                [-16.42375, 19.769583000000125],
                [-16.423470999999893, 19.769583000000125],
                [-16.423470999999893, 19.77125],
                [-16.423197, 19.77125],
                [-16.423197, 19.773472],
                [-16.422916, 19.773472],
                [-16.422916, 19.775139000000138],
                [-16.422639999999888, 19.775139000000138],
                [-16.422637999999893, 19.776525],
                [-16.422361, 19.776525],
                [-16.422361, 19.779583000000116],
                [-16.422637999999893, 19.779583000000116],
                [-16.422639999999888, 19.780972000000133],
                [-16.422916, 19.780972000000133],
                [-16.422916, 19.781528],
                [-16.423197, 19.781528],
                [-16.423197, 19.78236],
                [-16.423470999999893, 19.78236],
                [-16.423470999999893, 19.783472000000188],
                [-16.42375, 19.783472000000188],
                [-16.42375, 19.785418000000107],
                [-16.423197, 19.785418000000107],
                [-16.423197, 19.785973000000183],
                [-16.422916, 19.785973000000183],
                [-16.422916, 19.786804000000188],
                [-16.422639999999888, 19.786804000000188],
                [-16.422637999999893, 19.787361],
                [-16.422361, 19.787361],
                [-16.422361, 19.788197],
                [-16.422085, 19.788197],
                [-16.422085, 19.789026],
                [-16.42180599999989, 19.789026],
                [-16.42180599999989, 19.789583000000107],
                [-16.421528, 19.789583000000107],
                [-16.421528, 19.789862],
                [-16.421249, 19.789862],
                [-16.421249, 19.790419000000156],
                [-16.420974999999885, 19.790419000000156],
                [-16.420974999999885, 19.790695],
                [-16.420692, 19.790695],
                [-16.420692, 19.790972],
                [-16.420416, 19.790972],
                [-16.420416, 19.791250000000161],
                [-16.420138999999892, 19.791250000000161],
                [-16.420138999999892, 19.791805],
                [-16.419863, 19.791805],
                [-16.419863, 19.792084000000102],
                [-16.419584, 19.792084000000102],
                [-16.419584, 19.79236],
                [-16.419863, 19.79236],
                [-16.419863, 19.794861],
                [-16.420138999999892, 19.794861],
                [-16.420138999999892, 19.796249000000103],
                [-16.420416, 19.796249000000103],
                [-16.420416, 19.797083000000157],
                [-16.420692, 19.797083000000157],
                [-16.420692, 19.797916000000157],
                [-16.420974999999885, 19.797916000000157],
                [-16.420974999999885, 19.799028000000135],
                [-16.421249, 19.799028000000135],
                [-16.421249, 19.800138000000175],
                [-16.421528, 19.800138000000175],
                [-16.421528, 19.800694000000135],
                [-16.42180599999989, 19.800694000000135],
                [-16.42180599999989, 19.801250000000152],
                [-16.422085, 19.801250000000152],
                [-16.422085, 19.802086],
                [-16.422361, 19.802086],
                [-16.422361, 19.802916000000153],
                [-16.422637999999893, 19.802916000000153],
                [-16.422639999999888, 19.804583],
                [-16.422916, 19.804583],
                [-16.422916, 19.807362000000126],
                [-16.422639999999888, 19.807362000000126],
                [-16.422637999999893, 19.808472000000108],
                [-16.422361, 19.808472000000108],
                [-16.422361, 19.810139],
                [-16.422085, 19.810139],
                [-16.422085, 19.810694000000126],
                [-16.422361, 19.810694000000126],
                [-16.422361, 19.811806000000104],
                [-16.422085, 19.811806000000104],
                [-16.422085, 19.813194],
                [-16.42180599999989, 19.813194],
                [-16.42180599999989, 19.814304],
                [-16.422085, 19.814304],
                [-16.422085, 19.81514],
                [-16.42180599999989, 19.81514],
                [-16.42180599999989, 19.816526],
                [-16.421528, 19.816526],
                [-16.421528, 19.819029000000171],
                [-16.421249, 19.819029000000171],
                [-16.421249, 19.81986],
                [-16.420974999999885, 19.81986],
                [-16.420974999999885, 19.820417],
                [-16.420692, 19.820417],
                [-16.420692, 19.821251000000188],
                [-16.420416, 19.821251000000188],
                [-16.420416, 19.821527000000117],
                [-16.420138999999892, 19.821527000000117],
                [-16.420138999999892, 19.822083],
                [-16.419863, 19.822083],
                [-16.419863, 19.822363000000166],
                [-16.419584, 19.822363000000166],
                [-16.419584, 19.822639],
                [-16.419305999999892, 19.822639],
                [-16.419305999999892, 19.823195000000112],
                [-16.419027, 19.823195000000112],
                [-16.419027, 19.823471],
                [-16.418753, 19.823471],
                [-16.418753, 19.824028000000112],
                [-16.418469999999843, 19.824028000000112],
                [-16.418469999999843, 19.824306],
                [-16.418194, 19.824306],
                [-16.418194, 19.824581],
                [-16.417917, 19.824581],
                [-16.417917, 19.824862000000167],
                [-16.417638999999895, 19.824862000000167],
                [-16.417638999999895, 19.825138],
                [-16.417084, 19.825138],
                [-16.417084, 19.825417],
                [-16.41680499999984, 19.825417],
                [-16.41680499999984, 19.825693000000172],
                [-16.416529, 19.825693000000172],
                [-16.416529, 19.82625],
                [-16.416248, 19.82625],
                [-16.416248, 19.826529000000107],
                [-16.41597199999984, 19.826529000000107],
                [-16.41597199999984, 19.826803],
                [-16.415417, 19.826803],
                [-16.415417, 19.827084000000184],
                [-16.415137999999843, 19.827084000000184],
                [-16.415137999999843, 19.827362000000107],
                [-16.414862, 19.827362000000107],
                [-16.414862, 19.827639],
                [-16.414583, 19.827639],
                [-16.414583, 19.828194000000167],
                [-16.414304999999842, 19.828194000000167],
                [-16.414304999999842, 19.828472],
                [-16.414028, 19.828472],
                [-16.414028, 19.828751],
                [-16.41375, 19.828751],
                [-16.41375, 19.82930800000014],
                [-16.41347299999984, 19.82930800000014],
                [-16.41347299999984, 19.829584],
                [-16.412916, 19.829584],
                [-16.412916, 19.829861000000108],
                [-16.41264, 19.829861000000108],
                [-16.41264, 19.830137],
                [-16.412361, 19.830137],
                [-16.412361, 19.830418000000179],
                [-16.411805999999842, 19.830418000000179],
                [-16.411805999999842, 19.830694000000108],
                [-16.411249, 19.830694000000108],
                [-16.411249, 19.830973],
                [-16.410694, 19.830973],
                [-16.410694, 19.831247000000189],
                [-16.410139, 19.831247000000189],
                [-16.410139, 19.831530000000157],
                [-16.409586, 19.831530000000157],
                [-16.409586, 19.831806],
                [-16.409027, 19.831806],
                [-16.409027, 19.832083],
                [-16.408751, 19.832083],
                [-16.408751, 19.832361000000162],
                [-16.408195, 19.832361000000162],
                [-16.408195, 19.83264000000014],
                [-16.407364, 19.83264000000014],
                [-16.407364, 19.832916],
                [-16.406803, 19.832916],
                [-16.406803, 19.833195],
                [-16.406252, 19.833195],
                [-16.406252, 19.833471],
                [-16.405416, 19.833471],
                [-16.405416, 19.833752000000175],
                [-16.404581, 19.833752000000175],
                [-16.404581, 19.834028],
                [-16.404306, 19.834028],
                [-16.404306, 19.83458300000018],
                [-16.401526999999874, 19.83458300000018],
                [-16.401526999999874, 19.834862000000157],
                [-16.401251, 19.834862000000157],
                [-16.401251, 19.835138],
                [-16.400417, 19.835138],
                [-16.400417, 19.835417],
                [-16.397361999999873, 19.835417],
                [-16.397361999999873, 19.835695000000158],
                [-16.394585, 19.835695000000158],
                [-16.394585, 19.835417],
                [-16.394027999999878, 19.835417],
                [-16.394027999999878, 19.835138],
                [-16.393749, 19.835138],
                [-16.393749, 19.834862000000157],
                [-16.393191999999885, 19.834862000000157],
                [-16.393191999999885, 19.83458300000018],
                [-16.392916, 19.83458300000018],
                [-16.392916, 19.834305],
                [-16.392362999999875, 19.834305],
                [-16.392362999999875, 19.834028],
                [-16.391806, 19.834028],
                [-16.391806, 19.833752000000175],
                [-16.391253, 19.833752000000175],
                [-16.391253, 19.833471],
                [-16.390693999999883, 19.833471],
                [-16.390693999999883, 19.833195],
                [-16.390417, 19.833195],
                [-16.390417, 19.832916],
                [-16.389859999999885, 19.832916],
                [-16.38985799999989, 19.83264000000014],
                [-16.389584, 19.83264000000014],
                [-16.389584, 19.832361000000162],
                [-16.389305, 19.832361000000162],
                [-16.389305, 19.832083],
                [-16.38902899999988, 19.832083],
                [-16.38902899999988, 19.831806],
                [-16.388748, 19.831806],
                [-16.388748, 19.831530000000157],
                [-16.388472, 19.831530000000157],
                [-16.388472, 19.831247000000189],
                [-16.387917, 19.831247000000189],
                [-16.387917, 19.830973],
                [-16.387638, 19.830973],
                [-16.387638, 19.830694000000108],
                [-16.387361999999882, 19.830694000000108],
                [-16.387361999999882, 19.830418000000179],
                [-16.386806, 19.830418000000179],
                [-16.386805, 19.830137],
                [-16.38652599999989, 19.830137],
                [-16.38652599999989, 19.829584],
                [-16.38625, 19.829584],
                [-16.38625, 19.82930800000014],
                [-16.385973, 19.82930800000014],
                [-16.385973, 19.829025000000172],
                [-16.385694999999885, 19.829025000000172],
                [-16.385694999999885, 19.828751],
                [-16.385418, 19.828751],
                [-16.385418, 19.828472],
                [-16.384583, 19.828472],
                [-16.384583, 19.828751],
                [-16.384308, 19.828751],
                [-16.384308, 19.82930800000014],
                [-16.38402699999989, 19.82930800000014],
                [-16.38402699999989, 19.830137],
                [-16.383751, 19.830137],
                [-16.383751, 19.831247000000189],
                [-16.383472, 19.831247000000189],
                [-16.383472, 19.831530000000157],
                [-16.383195999999884, 19.831530000000157],
                [-16.383195999999884, 19.832361000000162],
                [-16.382917, 19.832361000000162],
                [-16.382917, 19.832916],
                [-16.382639, 19.832916],
                [-16.382639, 19.83458300000018],
                [-16.382359999999835, 19.83458300000018],
                [-16.382359999999835, 19.835695000000158],
                [-16.382086, 19.835695000000158],
                [-16.382086, 19.83625],
                [-16.381805, 19.83625],
                [-16.381805, 19.837917000000175],
                [-16.381526999999835, 19.837917000000175],
                [-16.381526999999835, 19.838194000000158],
                [-16.38125, 19.838194000000158],
                [-16.38125, 19.838751],
                [-16.380974, 19.838751],
                [-16.380974, 19.840139000000136],
                [-16.38125, 19.840139000000136],
                [-16.38125, 19.841249000000175],
                [-16.380974, 19.841249000000175],
                [-16.380974, 19.842083],
                [-16.380694999999889, 19.842083],
                [-16.380694999999889, 19.843472],
                [-16.380417, 19.843472],
                [-16.380417, 19.844028],
                [-16.380138, 19.844028],
                [-16.380138, 19.844584],
                [-16.379863999999884, 19.844584],
                [-16.379863999999884, 19.844862000000148],
                [-16.379305, 19.844862000000148],
                [-16.379305, 19.845137000000136],
                [-16.377083, 19.845137000000136],
                [-16.377083, 19.845419],
                [-16.376806, 19.845419],
                [-16.376806, 19.845694],
                [-16.376527999999837, 19.845694],
                [-16.376527999999837, 19.845972000000131],
                [-16.376249, 19.845972000000131],
                [-16.376249, 19.846251000000109],
                [-16.375973, 19.846251000000109],
                [-16.375973, 19.846806],
                [-16.375694, 19.846806],
                [-16.375694, 19.847084000000109],
                [-16.375416, 19.847084000000109],
                [-16.375416, 19.847361000000149],
                [-16.375139, 19.847361000000149],
                [-16.375139, 19.847639],
                [-16.374861, 19.847639],
                [-16.374861, 19.848194000000149],
                [-16.374584, 19.848194000000149],
                [-16.374584, 19.848473000000126],
                [-16.374306, 19.848473000000126],
                [-16.374306, 19.849028],
                [-16.374027, 19.849028],
                [-16.374027, 19.849306000000126],
                [-16.373751, 19.849306000000126],
                [-16.373751, 19.849861],
                [-16.373472, 19.849861],
                [-16.373472, 19.85014],
                [-16.373194, 19.85014],
                [-16.373194, 19.850695000000144],
                [-16.372917, 19.850695000000144],
                [-16.372917, 19.851528000000144],
                [-16.372639, 19.851528000000144],
                [-16.372639, 19.852083],
                [-16.37236, 19.852083],
                [-16.37236, 19.852917000000105],
                [-16.372084, 19.852917000000105],
                [-16.372084, 19.853193],
                [-16.371529, 19.853193],
                [-16.371529, 19.853472],
                [-16.369028, 19.853472],
                [-16.369028, 19.853750000000105],
                [-16.368473, 19.853750000000105],
                [-16.368473, 19.853472],
                [-16.368195, 19.853472],
                [-16.368195, 19.852917000000105],
                [-16.36764, 19.852917000000105],
                [-16.36764, 19.852638000000127],
                [-16.367915999999866, 19.852638000000127],
                [-16.367915999999866, 19.852362],
                [-16.368195, 19.852362],
                [-16.368195, 19.852083],
                [-16.368473, 19.852083],
                [-16.368473, 19.851805000000127],
                [-16.36875, 19.851805000000127],
                [-16.36875, 19.851528000000144],
                [-16.369028, 19.851528000000144],
                [-16.369028, 19.850971],
                [-16.369307, 19.850971],
                [-16.369307, 19.850695000000144],
                [-16.369583, 19.850695000000144],
                [-16.369583, 19.850416000000109],
                [-16.369862, 19.850416000000109],
                [-16.369862, 19.85014],
                [-16.370138, 19.85014],
                [-16.370138, 19.849861],
                [-16.370417, 19.849861],
                [-16.370417, 19.849583000000109],
                [-16.370695, 19.849583000000109],
                [-16.370695, 19.849028],
                [-16.370972, 19.849028],
                [-16.370972, 19.848473000000126],
                [-16.37125, 19.848473000000126],
                [-16.37125, 19.847918],
                [-16.370695, 19.847918],
                [-16.370695, 19.848194000000149],
                [-16.370417, 19.848194000000149],
                [-16.370417, 19.848473000000126],
                [-16.370138, 19.848473000000126],
                [-16.370138, 19.848749],
                [-16.369862, 19.848749],
                [-16.369862, 19.849028],
                [-16.369583, 19.849028],
                [-16.369583, 19.849306000000126],
                [-16.369307, 19.849306000000126],
                [-16.369307, 19.849583000000109],
                [-16.369028, 19.849583000000109],
                [-16.369028, 19.849861],
                [-16.36875, 19.849861],
                [-16.36875, 19.850416000000109],
                [-16.368473, 19.850416000000109],
                [-16.368473, 19.850695000000144],
                [-16.368195, 19.850695000000144],
                [-16.368195, 19.850971],
                [-16.367915999999866, 19.850971],
                [-16.367915999999866, 19.85125],
                [-16.36764, 19.85125],
                [-16.36764, 19.851528000000144],
                [-16.366806, 19.851528000000144],
                [-16.366806, 19.851805000000127],
                [-16.366528, 19.851805000000127],
                [-16.366528, 19.852083],
                [-16.366248999999868, 19.852083],
                [-16.366248999999868, 19.852362],
                [-16.365971, 19.852362],
                [-16.365971, 19.852638000000127],
                [-16.365692, 19.852638000000127],
                [-16.365692, 19.853193],
                [-16.365416999999866, 19.853193],
                [-16.365416999999866, 19.853472],
                [-16.365141, 19.853472],
                [-16.365141, 19.854029],
                [-16.364861, 19.854029],
                [-16.364861, 19.854303],
                [-16.364581999999871, 19.854303],
                [-16.364581999999871, 19.854584],
                [-16.364305, 19.854584],
                [-16.364305, 19.855972000000122],
                [-16.364027, 19.855972000000122],
                [-16.364027, 19.862083],
                [-16.363752, 19.862083],
                [-16.363752, 19.862362000000189],
                [-16.36347, 19.862362000000189],
                [-16.36347, 19.862638000000118],
                [-16.363195, 19.862638000000118],
                [-16.363194, 19.862917],
                [-16.362916999999868, 19.862917],
                [-16.362916999999868, 19.863194],
                [-16.362638, 19.863195000000189],
                [-16.362638, 19.863474000000167],
                [-16.362362, 19.863474000000167],
                [-16.362362, 19.86375],
                [-16.361805, 19.86375],
                [-16.361805, 19.864027],
                [-16.36125, 19.864027],
                [-16.36125, 19.864305000000172],
                [-16.360415999999873, 19.864305000000172],
                [-16.360415999999873, 19.864582],
                [-16.359582999999873, 19.864582],
                [-16.359582999999873, 19.86486100000019],
                [-16.359028, 19.86486100000019],
                [-16.359028, 19.865139000000113],
                [-16.35791799999987, 19.865139000000113],
                [-16.35791799999987, 19.865417],
                [-16.353195, 19.865417],
                [-16.353195, 19.865139000000113],
                [-16.352364, 19.865139000000113],
                [-16.352364, 19.86486100000019],
                [-16.350695, 19.86486100000019],
                [-16.350695, 19.864582],
                [-16.350415999999882, 19.864582],
                [-16.350415999999882, 19.864305000000172],
              ],
            ],
            [
              [
                [-16.440695, 19.77125],
                [-16.440416, 19.77125],
                [-16.440416, 19.770971],
                [-16.440695, 19.770971],
                [-16.440695, 19.769583000000125],
                [-16.440970999999877, 19.769583000000125],
                [-16.440970999999877, 19.769028],
                [-16.44125, 19.769028],
                [-16.44125, 19.768749],
                [-16.441528, 19.768749],
                [-16.441528, 19.768194000000165],
                [-16.441805, 19.768194000000165],
                [-16.441805, 19.767639],
                [-16.442083, 19.767639],
                [-16.442083, 19.767084000000125],
                [-16.441805, 19.767084000000125],
                [-16.441805, 19.766806],
                [-16.441528, 19.766806],
                [-16.441528, 19.766527],
                [-16.440970999999877, 19.766527],
                [-16.440970999999877, 19.766251000000125],
                [-16.440695, 19.766251000000125],
                [-16.440695, 19.765139000000147],
                [-16.440970999999877, 19.765139000000147],
                [-16.440970999999877, 19.764862000000164],
                [-16.44125, 19.764858000000174],
                [-16.44125, 19.764584],
                [-16.441528, 19.764584],
                [-16.441528, 19.764305],
                [-16.444027, 19.764305],
                [-16.444027, 19.763472],
                [-16.44486, 19.763472],
                [-16.44486, 19.76375000000013],
                [-16.445417, 19.76375000000013],
                [-16.445417, 19.764029000000164],
                [-16.445971, 19.764029000000164],
                [-16.445971, 19.764305],
                [-16.446252999999899, 19.764305],
                [-16.446252999999899, 19.765139000000147],
                [-16.445971, 19.765139000000147],
                [-16.445971, 19.765417],
                [-16.445692, 19.765417],
                [-16.445692, 19.765972000000147],
                [-16.445417, 19.765972000000147],
                [-16.445417, 19.767639],
                [-16.445139, 19.767639],
                [-16.445139, 19.768473000000142],
                [-16.44486, 19.768473000000142],
                [-16.44486, 19.768749],
                [-16.44375, 19.768749],
                [-16.44375, 19.769306000000142],
                [-16.443472, 19.769306000000142],
                [-16.443472, 19.769861],
                [-16.443195, 19.769861],
                [-16.443195, 19.77014],
                [-16.443472, 19.77014],
                [-16.443472, 19.770416000000125],
                [-16.44375, 19.770416000000125],
                [-16.44375, 19.770695000000103],
                [-16.443472, 19.770695000000103],
                [-16.443472, 19.770971],
                [-16.443195, 19.770971],
                [-16.443195, 19.771528000000103],
                [-16.442917, 19.771528000000103],
                [-16.442917, 19.771805000000143],
                [-16.442638, 19.771805000000143],
                [-16.442638, 19.772083],
                [-16.441805, 19.772083],
                [-16.441805, 19.772638000000143],
                [-16.441528, 19.772638000000143],
                [-16.441528, 19.773193],
                [-16.44125, 19.773193],
                [-16.44125, 19.773472],
                [-16.440970999999877, 19.773472],
                [-16.440970999999877, 19.773750000000121],
                [-16.440416, 19.773750000000121],
                [-16.440416, 19.773472],
                [-16.44014, 19.773472],
                [-16.44014, 19.772638000000143],
                [-16.440416, 19.772638000000143],
                [-16.440416, 19.772083],
                [-16.440695, 19.772083],
                [-16.440695, 19.77125],
              ],
            ],
            [
              [
                [-16.31875, 19.794861],
                [-16.318471999999872, 19.794861],
                [-16.318471999999872, 19.792917000000102],
                [-16.31875, 19.792917000000102],
                [-16.31875, 19.793194],
                [-16.319584, 19.793194],
                [-16.319584, 19.793472000000179],
                [-16.319859999999892, 19.793472000000179],
                [-16.319859999999892, 19.793751000000157],
                [-16.32097199999987, 19.793751000000157],
                [-16.32097199999987, 19.794027],
                [-16.321806, 19.794027],
                [-16.321806, 19.793472000000179],
                [-16.322083, 19.793472000000179],
                [-16.322083, 19.794027],
                [-16.322358999999892, 19.794027],
                [-16.322358999999892, 19.795139],
                [-16.322083, 19.795139],
                [-16.322083, 19.794861],
                [-16.32097199999987, 19.794861],
                [-16.32097199999987, 19.795416000000102],
                [-16.320694, 19.795416000000102],
                [-16.320694, 19.795694],
                [-16.321251, 19.795694],
                [-16.321251, 19.796249000000103],
                [-16.321806, 19.796249000000103],
                [-16.321806, 19.796528],
                [-16.322083, 19.796528],
                [-16.322083, 19.796806000000174],
                [-16.322639, 19.796806000000174],
                [-16.322639, 19.797083000000157],
                [-16.32375, 19.797083000000157],
                [-16.32375, 19.797361],
                [-16.324028, 19.797361],
                [-16.324028, 19.797638],
                [-16.322916, 19.79764],
                [-16.322916, 19.797916000000157],
                [-16.320417, 19.797916000000157],
                [-16.320417, 19.79764],
                [-16.319584, 19.797638],
                [-16.319584, 19.797083000000157],
                [-16.319304999999872, 19.797083000000157],
                [-16.319304999999872, 19.796528],
                [-16.319029, 19.796528],
                [-16.319029, 19.795694],
                [-16.31875, 19.795694],
                [-16.31875, 19.794861],
              ],
            ],
            [
              [
                [-16.327917, 19.797361],
                [-16.32625, 19.797361],
                [-16.32625, 19.797638],
                [-16.325417, 19.797638],
                [-16.325417, 19.796528],
                [-16.325695, 19.796528],
                [-16.325695, 19.796249000000103],
                [-16.32597399999986, 19.796249000000103],
                [-16.32597399999986, 19.795973000000174],
                [-16.32625, 19.795973000000174],
                [-16.32625, 19.795694],
                [-16.327362, 19.795694],
                [-16.327362, 19.796249000000103],
                [-16.327917, 19.796249000000103],
                [-16.327917, 19.797361],
              ],
            ],
            [
              [
                [-16.324305, 19.834305],
                [-16.32375, 19.834305],
                [-16.32375, 19.834028],
                [-16.32347099999987, 19.834028],
                [-16.32347099999987, 19.833752000000175],
                [-16.323195, 19.833752000000175],
                [-16.323195, 19.833195],
                [-16.32347099999987, 19.833195],
                [-16.32347099999987, 19.832916],
                [-16.324028, 19.832916],
                [-16.324028, 19.83264000000014],
                [-16.32736, 19.83264000000014],
                [-16.327362, 19.832361000000162],
                [-16.327917, 19.832361000000162],
                [-16.327917, 19.832083],
                [-16.328751, 19.832083],
                [-16.328751, 19.832361000000162],
                [-16.329027, 19.832361000000162],
                [-16.329027, 19.832916],
                [-16.328751, 19.832916],
                [-16.328751, 19.833195],
                [-16.328471999999863, 19.833195],
                [-16.328471999999863, 19.833752000000175],
                [-16.328196, 19.833752000000175],
                [-16.328196, 19.834028],
                [-16.327917, 19.834028],
                [-16.327917, 19.834305],
                [-16.327638999999863, 19.834305],
                [-16.327638999999863, 19.83458300000018],
                [-16.327362, 19.83458300000018],
                [-16.327362, 19.834862000000157],
                [-16.327084, 19.834862000000157],
                [-16.327084, 19.835138],
                [-16.32680299999987, 19.835138],
                [-16.32680299999987, 19.835417],
                [-16.325417, 19.835417],
                [-16.325417, 19.835138],
                [-16.325138, 19.835138],
                [-16.325138, 19.834862000000157],
                [-16.324863, 19.834862000000157],
                [-16.324863, 19.83458300000018],
                [-16.324306, 19.83458300000018],
                [-16.324305, 19.834305],
              ],
            ],
            [
              [
                [-16.38652599999989, 19.847361000000149],
                [-16.387083, 19.847361000000149],
                [-16.387083, 19.847084000000109],
                [-16.387638, 19.847084000000109],
                [-16.387638, 19.846806],
                [-16.387917, 19.846806],
                [-16.387917, 19.846527],
                [-16.388194999999882, 19.846527],
                [-16.388194999999882, 19.846251000000109],
                [-16.388472, 19.846251000000109],
                [-16.388472, 19.843472],
                [-16.387638, 19.843472],
                [-16.387638, 19.843197],
                [-16.387917, 19.843197],
                [-16.387917, 19.842636000000141],
                [-16.388472, 19.842636000000141],
                [-16.388472, 19.842361000000153],
                [-16.388748, 19.842361000000153],
                [-16.388748, 19.841528000000153],
                [-16.38902899999988, 19.841528000000153],
                [-16.38902899999988, 19.841249000000175],
                [-16.389305, 19.841249000000175],
                [-16.389305, 19.840973],
                [-16.390139, 19.840973],
                [-16.390139, 19.841249000000175],
                [-16.390417, 19.841249000000175],
                [-16.390417, 19.841528000000153],
                [-16.391253, 19.841528000000153],
                [-16.391253, 19.841805000000136],
                [-16.391526999999883, 19.841805000000136],
                [-16.391526999999883, 19.842361000000153],
                [-16.391806, 19.842361000000153],
                [-16.391806, 19.842636000000141],
                [-16.392082, 19.842636000000141],
                [-16.392082, 19.842918],
                [-16.390693999999883, 19.842918],
                [-16.390693999999883, 19.843197],
                [-16.390417, 19.843197],
                [-16.390417, 19.843472],
                [-16.390693999999883, 19.843472],
                [-16.390693999999883, 19.843748000000176],
                [-16.391526999999883, 19.843748000000176],
                [-16.391526999999883, 19.844862000000148],
                [-16.391806, 19.844862000000148],
                [-16.391806, 19.845137000000136],
                [-16.392082, 19.845137000000136],
                [-16.392082, 19.845419],
                [-16.392362999999875, 19.845419],
                [-16.392362999999875, 19.845694],
                [-16.392639, 19.845694],
                [-16.392639, 19.846806],
                [-16.392362999999875, 19.846806],
                [-16.392362999999875, 19.846527],
                [-16.391806, 19.846527],
                [-16.391806, 19.846806],
                [-16.391526999999883, 19.846806],
                [-16.391526999999883, 19.846527],
                [-16.391253, 19.846527],
                [-16.391253, 19.845419],
                [-16.39097, 19.845419],
                [-16.39097, 19.845137000000136],
                [-16.390139, 19.845137000000136],
                [-16.390139, 19.845694],
                [-16.389859999999885, 19.845694],
                [-16.389859999999885, 19.847084000000109],
                [-16.390139, 19.847084000000109],
                [-16.390139, 19.847361000000149],
                [-16.390417, 19.847361000000149],
                [-16.390417, 19.847918],
                [-16.390139, 19.847918],
                [-16.390139, 19.848194000000149],
                [-16.389584, 19.848194000000149],
                [-16.389584, 19.848473000000126],
                [-16.389305, 19.848473000000126],
                [-16.389305, 19.848749],
                [-16.38902899999988, 19.848749],
                [-16.38902899999988, 19.849028],
                [-16.388748, 19.849028],
                [-16.388748, 19.849306000000126],
                [-16.388472, 19.849306000000126],
                [-16.388472, 19.85014],
                [-16.388748, 19.85014],
                [-16.388748, 19.850519000000133],
                [-16.388748, 19.850695000000144],
                [-16.388557, 19.850695000000144],
                [-16.387917, 19.850695000000144],
                [-16.387917, 19.850971],
                [-16.387083, 19.850971],
                [-16.387083, 19.850695000000144],
                [-16.387361999999882, 19.850695000000144],
                [-16.387361999999882, 19.850416000000109],
                [-16.387083, 19.850416000000109],
                [-16.387083, 19.849028],
                [-16.386806, 19.849028],
                [-16.386805, 19.847918],
                [-16.38652599999989, 19.847918],
                [-16.38652599999989, 19.847361000000149],
              ],
            ],
            [
              [
                [-16.379581, 19.852917000000105],
                [-16.379863999999884, 19.852917000000105],
                [-16.379863999999884, 19.852362],
                [-16.380138, 19.852362],
                [-16.380138, 19.852083],
                [-16.380417, 19.852083],
                [-16.380417, 19.851805000000127],
                [-16.380694999999889, 19.851805000000127],
                [-16.380694999999889, 19.852083],
                [-16.380974, 19.852083],
                [-16.380974, 19.852638000000127],
                [-16.380694999999889, 19.852638000000127],
                [-16.380694999999889, 19.853193],
                [-16.380417, 19.853193],
                [-16.380417, 19.853750000000105],
                [-16.380138, 19.853750000000105],
                [-16.380138, 19.854584],
                [-16.379863999999884, 19.854584],
                [-16.379863999999884, 19.854860000000144],
                [-16.379581, 19.854860000000144],
                [-16.379581, 19.854584],
                [-16.379027999999835, 19.854584],
                [-16.379027999999835, 19.854029],
                [-16.379305, 19.854029],
                [-16.379305, 19.853193],
                [-16.379581, 19.853193],
                [-16.379581, 19.852917000000105],
              ],
            ],
            [
              [
                [-16.368473, 19.863474000000167],
                [-16.368195, 19.863474000000167],
                [-16.368195, 19.864027],
                [-16.367915999999866, 19.864027],
                [-16.367915999999866, 19.865139000000113],
                [-16.36764, 19.865139000000113],
                [-16.36764, 19.866249],
                [-16.367361, 19.866249],
                [-16.367361, 19.867361],
                [-16.367085, 19.867361],
                [-16.367085, 19.867914],
                [-16.366528, 19.867914],
                [-16.366528, 19.868195000000185],
                [-16.365971, 19.868195000000185],
                [-16.365971, 19.868473],
                [-16.365692, 19.868473],
                [-16.365692, 19.86875],
                [-16.365416999999866, 19.86875],
                [-16.365416999999866, 19.869583],
                [-16.36347, 19.869583],
                [-16.36347, 19.869862],
                [-16.363194, 19.869862],
                [-16.363194, 19.86875],
                [-16.36347, 19.86875],
                [-16.36347, 19.868195000000185],
                [-16.363752, 19.868195000000185],
                [-16.363752, 19.86764],
                [-16.364027, 19.86764],
                [-16.364027, 19.867361],
                [-16.364305, 19.867361],
                [-16.364305, 19.867083],
                [-16.364581999999871, 19.867083],
                [-16.364581999999871, 19.866804000000172],
                [-16.364861, 19.866804000000172],
                [-16.364861, 19.865973000000167],
                [-16.365692, 19.865973000000167],
                [-16.365692, 19.865692],
                [-16.365971, 19.865692],
                [-16.365971, 19.865417],
                [-16.366248999999868, 19.865417],
                [-16.366248999999868, 19.86486100000019],
                [-16.366528, 19.86486100000019],
                [-16.366528, 19.864305000000172],
                [-16.366806, 19.864305000000172],
                [-16.366806, 19.86375],
                [-16.367085, 19.86375],
                [-16.367085, 19.862917],
                [-16.367361, 19.862917],
                [-16.367361, 19.862638000000118],
                [-16.368195, 19.862638000000118],
                [-16.368195, 19.862917],
                [-16.36875, 19.862917],
                [-16.36875, 19.863194],
                [-16.368473, 19.863195000000189],
                [-16.368473, 19.863474000000167],
              ],
            ],
            [
              [
                [-16.38402699999989, 19.874584000000141],
                [-16.384308, 19.874584000000141],
                [-16.384308, 19.873194],
                [-16.38402699999989, 19.873194],
                [-16.38402699999989, 19.87152900000018],
                [-16.384308, 19.87152900000018],
                [-16.384308, 19.870972],
                [-16.384583, 19.870972],
                [-16.384583, 19.867914],
                [-16.385139, 19.867914],
                [-16.385139, 19.86764],
                [-16.385694999999885, 19.86764],
                [-16.385694999999885, 19.867361],
                [-16.38625, 19.867361],
                [-16.38625, 19.867083],
                [-16.38652599999989, 19.867083],
                [-16.38652599999989, 19.866804000000172],
                [-16.386805, 19.866804000000172],
                [-16.386805, 19.866438],
                [-16.386805, 19.866249],
                [-16.38652599999989, 19.866249],
                [-16.38652599999989, 19.865973000000167],
                [-16.385973, 19.865973000000167],
                [-16.385973, 19.865692],
                [-16.385694999999885, 19.865692],
                [-16.385694999999885, 19.865417],
                [-16.385418, 19.865417],
                [-16.385418, 19.865139000000113],
                [-16.385139, 19.865139000000113],
                [-16.385139, 19.864582],
                [-16.384860999999887, 19.864582],
                [-16.384860999999887, 19.864305000000172],
                [-16.384583, 19.864305000000172],
                [-16.384583, 19.86375],
                [-16.384308, 19.86375],
                [-16.384308, 19.861805000000118],
                [-16.384583, 19.861805000000118],
                [-16.384583, 19.861252],
                [-16.385418, 19.861252],
                [-16.385418, 19.860971000000177],
                [-16.385694999999885, 19.860971000000177],
                [-16.385694999999885, 19.860695],
                [-16.385973, 19.860695],
                [-16.385973, 19.859306000000117],
                [-16.38625, 19.859306000000117],
                [-16.38625, 19.859030000000189],
                [-16.386805, 19.859030000000189],
                [-16.386806, 19.858747],
                [-16.387361999999882, 19.858747],
                [-16.387361999999882, 19.858473000000117],
                [-16.387638, 19.858473000000117],
                [-16.387638, 19.858194],
                [-16.387917, 19.858194],
                [-16.387917, 19.857918],
                [-16.388194999999882, 19.857918],
                [-16.388194999999882, 19.857639000000177],
                [-16.388472, 19.857639000000177],
                [-16.388472, 19.857361],
                [-16.388748, 19.857361],
                [-16.388748, 19.8570840000001],
                [-16.38902899999988, 19.857082000000105],
                [-16.38902899999988, 19.856808000000171],
                [-16.389305, 19.856808000000171],
                [-16.389305, 19.856525],
                [-16.389584, 19.856525],
                [-16.389584, 19.8562510000001],
                [-16.390693999999883, 19.8562510000001],
                [-16.390693999999883, 19.856808000000171],
                [-16.39097, 19.856808000000171],
                [-16.39097, 19.857082000000105],
                [-16.391253, 19.8570840000001],
                [-16.391253, 19.857918],
                [-16.391526999999883, 19.857918],
                [-16.391526999999883, 19.859030000000189],
                [-16.391806, 19.859030000000189],
                [-16.391806, 19.859861],
                [-16.392082, 19.859861],
                [-16.392082, 19.8604160000001],
                [-16.392362999999875, 19.8604160000001],
                [-16.392362999999875, 19.860971000000177],
                [-16.392639, 19.860971000000177],
                [-16.392639, 19.861805000000118],
                [-16.392916, 19.861805000000118],
                [-16.392918, 19.862362000000189],
                [-16.393191999999885, 19.862362000000189],
                [-16.393191999999885, 19.863163],
                [-16.393191999999885, 19.863474000000167],
                [-16.393475, 19.863474000000167],
                [-16.393475, 19.864027],
                [-16.393749, 19.864027],
                [-16.393749, 19.864305000000172],
                [-16.394027999999878, 19.864305000000172],
                [-16.394027999999878, 19.864582],
                [-16.394585, 19.864582],
                [-16.394585, 19.865139000000113],
                [-16.394860999999878, 19.865139000000113],
                [-16.394860999999878, 19.865692],
                [-16.39514, 19.865692],
                [-16.39514, 19.866528],
                [-16.395416, 19.866528],
                [-16.395416, 19.87041900000014],
                [-16.39514, 19.87041900000014],
                [-16.39514, 19.87152900000018],
                [-16.394860999999878, 19.87152900000018],
                [-16.394860999999878, 19.87235800000019],
                [-16.394585, 19.87235800000019],
                [-16.394585, 19.872641000000158],
                [-16.394306, 19.872641000000158],
                [-16.394306, 19.873194],
                [-16.394027999999878, 19.873194],
                [-16.394027999999878, 19.873751000000141],
                [-16.393749, 19.873751000000141],
                [-16.393749, 19.874306],
                [-16.393475, 19.874306],
                [-16.393475, 19.874584000000141],
                [-16.393191999999885, 19.874584000000141],
                [-16.393191999999885, 19.87486100000018],
                [-16.392918, 19.87486100000018],
                [-16.392916, 19.875139],
                [-16.392082, 19.875139],
                [-16.392082, 19.875416],
                [-16.391253, 19.875416],
                [-16.391253, 19.87569400000018],
                [-16.390139, 19.87569400000018],
                [-16.390139, 19.875973000000158],
                [-16.389305, 19.875973000000158],
                [-16.389305, 19.876249],
                [-16.388748, 19.876249],
                [-16.388748, 19.876528],
                [-16.388194999999882, 19.876528],
                [-16.388194999999882, 19.876806000000158],
                [-16.387638, 19.876806000000158],
                [-16.387638, 19.877083000000141],
                [-16.385973, 19.877083000000141],
                [-16.385973, 19.877361],
                [-16.384308, 19.877361],
                [-16.384308, 19.876528],
                [-16.38402699999989, 19.876528],
                [-16.38402699999989, 19.875416],
                [-16.383751, 19.875416],
                [-16.383751, 19.875139],
                [-16.38402699999989, 19.875139],
                [-16.38402699999989, 19.874584000000141],
              ],
            ],
            [
              [
                [-16.293751, 19.871250000000146],
                [-16.293472, 19.871250000000146],
                [-16.293472, 19.870695],
                [-16.293751, 19.870695],
                [-16.293751, 19.87041900000014],
                [-16.294584, 19.87041900000014],
                [-16.294584, 19.870695],
                [-16.296249, 19.870695],
                [-16.296249, 19.870972],
                [-16.296528, 19.870972],
                [-16.296528, 19.87041900000014],
                [-16.296249, 19.87041900000014],
                [-16.296249, 19.870136000000173],
                [-16.295973, 19.870136000000173],
                [-16.295973, 19.869305000000168],
                [-16.295693999999855, 19.869305000000168],
                [-16.295693999999855, 19.86902600000019],
                [-16.295416, 19.86902600000019],
                [-16.295416, 19.868473],
                [-16.295139, 19.868473],
                [-16.295139, 19.867361],
                [-16.295416, 19.867361],
                [-16.295416, 19.867083],
                [-16.295693999999855, 19.867083],
                [-16.295693999999855, 19.866804000000172],
                [-16.296249, 19.866804000000172],
                [-16.296249, 19.867083],
                [-16.296528, 19.867083],
                [-16.296528, 19.867361],
                [-16.296806, 19.867361],
                [-16.296806, 19.86764],
                [-16.297083, 19.86764],
                [-16.297083, 19.868473],
                [-16.297358999999858, 19.868473],
                [-16.297358999999858, 19.86902600000019],
                [-16.297638, 19.86902600000019],
                [-16.297641999999826, 19.869583],
                [-16.297916, 19.869583],
                [-16.297916, 19.870136000000173],
                [-16.298195, 19.870136000000173],
                [-16.298195, 19.870972],
                [-16.298473, 19.870972],
                [-16.298473, 19.871805],
                [-16.298195, 19.871805],
                [-16.298195, 19.872084],
                [-16.297916, 19.872084],
                [-16.297916, 19.87235800000019],
                [-16.296806, 19.87235800000019],
                [-16.296806, 19.872084],
                [-16.295973, 19.872084],
                [-16.295973, 19.871805],
                [-16.294306, 19.871805],
                [-16.294306, 19.87152900000018],
                [-16.293751, 19.87152900000018],
                [-16.293751, 19.871250000000146],
              ],
            ],
            [
              [
                [-16.290138, 20.007641],
                [-16.290138, 20.006805],
                [-16.290417, 20.006805],
                [-16.290417, 20.005419000000188],
                [-16.290694999999857, 20.005419000000188],
                [-16.290694999999857, 20.004026],
                [-16.290417, 20.004026],
                [-16.290417, 20.003750000000139],
                [-16.290138, 20.003750000000139],
                [-16.290138, 20.003471000000104],
                [-16.289861999999857, 20.003471000000104],
                [-16.289861999999857, 20.003197],
                [-16.289583, 20.003197],
                [-16.289583, 20.002916],
                [-16.289307, 20.002916],
                [-16.289307, 20.00264],
                [-16.28764, 20.00264],
                [-16.28764, 20.002916],
                [-16.287085, 20.002916],
                [-16.287085, 20.00264],
                [-16.286806, 20.00264],
                [-16.286806, 20.002361000000121],
                [-16.287361, 20.002361000000121],
                [-16.287361, 20.002083],
                [-16.28764, 20.002083],
                [-16.28764, 20.001804],
                [-16.287916, 20.001804],
                [-16.287916, 20.000973],
                [-16.28764, 20.000973],
                [-16.28764, 20.000694],
                [-16.287361, 20.000694],
                [-16.287361, 19.999861],
                [-16.287361, 19.998751],
                [-16.287085, 19.998751],
                [-16.287085, 19.99736],
                [-16.286806, 19.99736],
                [-16.286806, 19.995972000000165],
                [-16.286528, 19.995972000000165],
                [-16.286528, 19.995417],
                [-16.286249, 19.995417],
                [-16.286249, 19.994862000000126],
                [-16.285971, 19.994862000000126],
                [-16.285971, 19.994028],
                [-16.285691999999869, 19.994028],
                [-16.285691999999869, 19.992916],
                [-16.285418, 19.992916],
                [-16.285418, 19.99236100000013],
                [-16.285139, 19.99236100000013],
                [-16.285139, 19.991808],
                [-16.285691999999869, 19.991808],
                [-16.285691999999869, 19.992083],
                [-16.285971, 19.992083],
                [-16.285971, 19.992640000000165],
                [-16.286249, 19.992640000000165],
                [-16.286249, 19.993195],
                [-16.286528, 19.993195],
                [-16.286528, 19.994028],
                [-16.286806, 19.994028],
                [-16.286806, 19.994305],
                [-16.287085, 19.994305],
                [-16.287085, 19.994583000000148],
                [-16.288195, 19.994583000000148],
                [-16.288195, 19.995138],
                [-16.288473, 19.995138],
                [-16.288473, 19.99625],
                [-16.28875, 19.99625],
                [-16.28875, 19.996529],
                [-16.289307, 19.996529],
                [-16.289307, 19.997084000000143],
                [-16.289583, 19.997084000000143],
                [-16.289583, 19.99736],
                [-16.290417, 19.99736],
                [-16.290417, 19.997639],
                [-16.290694999999857, 19.997639],
                [-16.290694999999857, 19.997917000000143],
                [-16.290972, 19.997917000000143],
                [-16.290972, 19.999027000000126],
                [-16.29125, 19.999027000000126],
                [-16.29125, 19.999306000000104],
                [-16.292084, 19.999306000000104],
                [-16.292084, 19.999582],
                [-16.29236, 19.999582],
                [-16.29236, 20.000139000000104],
                [-16.29236, 20.000973],
                [-16.292639, 20.000973],
                [-16.292639, 20.001249000000143],
                [-16.292917, 20.001249000000143],
                [-16.292917, 20.001528000000121],
                [-16.293193999999858, 20.001528000000121],
                [-16.293193999999858, 20.001804],
                [-16.293472, 20.001804],
                [-16.293472, 20.002083],
                [-16.293751, 20.002083],
                [-16.293751, 20.002361000000121],
                [-16.294026999999858, 20.002361000000121],
                [-16.294026999999858, 20.00264],
                [-16.294306, 20.00264],
                [-16.294306, 20.002916],
                [-16.294582, 20.002916],
                [-16.294584, 20.003197],
                [-16.295416, 20.003197],
                [-16.295416, 20.003471000000104],
                [-16.295693999999855, 20.003471000000104],
                [-16.295693999999855, 20.003750000000139],
                [-16.296249, 20.003750000000139],
                [-16.296249, 20.003471000000104],
                [-16.297083, 20.003471000000104],
                [-16.297083, 20.003750000000139],
                [-16.296806, 20.003750000000139],
                [-16.296806, 20.004305],
                [-16.296249, 20.004305],
                [-16.296249, 20.004583000000139],
                [-16.295416, 20.004583000000139],
                [-16.295416, 20.004305],
                [-16.294860999999855, 20.004305],
                [-16.294860999999855, 20.004026],
                [-16.294584, 20.004026],
                [-16.294584, 20.003750000000139],
                [-16.294306, 20.003750000000139],
                [-16.294306, 20.003197],
                [-16.293751, 20.003197],
                [-16.293751, 20.003471000000104],
                [-16.29236, 20.003471000000104],
                [-16.29236, 20.003750000000139],
                [-16.292084, 20.003750000000139],
                [-16.292084, 20.004305],
                [-16.29236, 20.004305],
                [-16.29236, 20.004862000000116],
                [-16.292084, 20.004862000000116],
                [-16.292084, 20.005136],
                [-16.291805, 20.005136],
                [-16.291805, 20.005695000000117],
                [-16.292084, 20.005695000000117],
                [-16.292084, 20.007641],
                [-16.291805, 20.007641],
                [-16.291805, 20.008472],
                [-16.291528999999855, 20.008472],
                [-16.291528999999855, 20.008751000000188],
                [-16.290972, 20.008751000000188],
                [-16.290972, 20.009027000000117],
                [-16.290138, 20.009027000000117],
                [-16.290138, 20.008751000000188],
                [-16.288473, 20.008751000000188],
                [-16.288473, 20.007917000000134],
                [-16.289861999999857, 20.007917000000134],
                [-16.289861999999857, 20.007641],
                [-16.290138, 20.007641],
              ],
            ],
            [
              [
                [-16.300973999999826, 20.012638],
                [-16.300973999999826, 20.010416000000134],
                [-16.300691999999856, 20.010416000000134],
                [-16.300691999999856, 20.010139],
                [-16.300138, 20.010139],
                [-16.300138, 20.009863000000166],
                [-16.299582, 20.009863000000166],
                [-16.299582, 20.008194000000117],
                [-16.299303, 20.008194000000117],
                [-16.299303, 20.007641],
                [-16.299028, 20.007641],
                [-16.299028, 20.006805],
                [-16.299864, 20.006805],
                [-16.299864, 20.007084000000134],
                [-16.300417, 20.007084000000134],
                [-16.300417, 20.00736],
                [-16.300691999999856, 20.00736],
                [-16.300695, 20.007641],
                [-16.301805, 20.007641],
                [-16.301805, 20.00736],
                [-16.302086, 20.00736],
                [-16.302086, 20.007084000000134],
                [-16.302639, 20.007084000000134],
                [-16.302639, 20.006805],
                [-16.303196, 20.006805],
                [-16.303196, 20.007084000000134],
                [-16.303749, 20.007084000000134],
                [-16.303749, 20.00736],
                [-16.303472999999826, 20.00736],
                [-16.303472999999826, 20.007917000000134],
                [-16.303749, 20.007917000000134],
                [-16.303749, 20.008194000000117],
                [-16.304307999999878, 20.008194000000117],
                [-16.304307999999878, 20.008472],
                [-16.304583, 20.008472],
                [-16.304583, 20.011528000000112],
                [-16.304307999999878, 20.011528000000112],
                [-16.304307999999878, 20.011806],
                [-16.304028, 20.011806],
                [-16.304028, 20.012362000000167],
                [-16.303749, 20.012362000000167],
                [-16.303749, 20.013193],
                [-16.303472999999826, 20.013193],
                [-16.303472999999826, 20.013472],
                [-16.303196, 20.013472],
                [-16.303196, 20.01375],
                [-16.303472999999826, 20.01375],
                [-16.303472999999826, 20.014584000000184],
                [-16.303749, 20.014584000000184],
                [-16.303749, 20.015415000000189],
                [-16.303472999999826, 20.015415000000189],
                [-16.303472999999826, 20.015694000000167],
                [-16.303196, 20.015694000000167],
                [-16.303196, 20.015972],
                [-16.302917, 20.015972],
                [-16.302917, 20.016251],
                [-16.302639, 20.016251],
                [-16.302639, 20.016525],
                [-16.300691999999856, 20.016525],
                [-16.300691999999856, 20.015972],
                [-16.300973999999826, 20.015972],
                [-16.300973999999826, 20.014584000000184],
                [-16.30125, 20.014584000000184],
                [-16.30125, 20.014029000000107],
                [-16.300973999999826, 20.014029000000107],
                [-16.300973999999826, 20.013472],
                [-16.300691999999856, 20.013472],
                [-16.300691999999856, 20.012638],
                [-16.300973999999826, 20.012638],
              ],
            ],
            [
              [
                [-16.407639, 19.861805000000118],
                [-16.407639, 19.861252],
                [-16.407364, 19.861252],
                [-16.407364, 19.860971000000177],
                [-16.407082, 19.860971000000177],
                [-16.407082, 19.860140000000172],
                [-16.407639, 19.860140000000172],
                [-16.407639, 19.859861],
                [-16.407917, 19.859861],
                [-16.407917, 19.8595830000001],
                [-16.408195, 19.8595830000001],
                [-16.408195, 19.858747],
                [-16.408472, 19.858747],
                [-16.408472, 19.858473000000117],
                [-16.408751, 19.858473000000117],
                [-16.408751, 19.858194],
                [-16.409027, 19.858194],
                [-16.409027, 19.857918],
                [-16.409861, 19.857918],
                [-16.409861, 19.857639000000177],
                [-16.410694, 19.857639000000177],
                [-16.410694, 19.858194],
                [-16.410972999999842, 19.858194],
                [-16.410972999999842, 19.858473000000117],
                [-16.411805999999842, 19.858473000000117],
                [-16.411805999999842, 19.859030000000189],
                [-16.412083, 19.859030000000189],
                [-16.412083, 19.860695],
                [-16.412361, 19.860695],
                [-16.412361, 19.861252],
                [-16.41264, 19.861252],
                [-16.41264, 19.861528],
                [-16.412916, 19.861528],
                [-16.412916, 19.861805000000118],
                [-16.414028, 19.861805000000118],
                [-16.414028, 19.862083],
                [-16.414862, 19.862083],
                [-16.414862, 19.862362000000189],
                [-16.415137999999843, 19.862362000000189],
                [-16.415137999999843, 19.862638000000118],
                [-16.414583, 19.862638000000118],
                [-16.414583, 19.862917],
                [-16.414304999999842, 19.862917],
                [-16.414304999999842, 19.863194],
                [-16.41347299999984, 19.863195000000189],
                [-16.41347299999984, 19.863474000000167],
                [-16.413195, 19.863474000000167],
                [-16.413195, 19.86375],
                [-16.41264, 19.86375],
                [-16.41264, 19.864027],
                [-16.412361, 19.864027],
                [-16.412361, 19.864305000000172],
                [-16.411805999999842, 19.864305000000172],
                [-16.411805999999842, 19.864582],
                [-16.411528, 19.864582],
                [-16.411528, 19.86486100000019],
                [-16.410694, 19.86486100000019],
                [-16.410694, 19.865139000000113],
                [-16.409586, 19.865139000000113],
                [-16.409586, 19.865417],
                [-16.408472, 19.865417],
                [-16.408472, 19.864305000000172],
                [-16.408195, 19.864305000000172],
                [-16.408195, 19.862362000000189],
                [-16.407917, 19.862362000000189],
                [-16.407917, 19.861805000000118],
                [-16.407639, 19.861805000000118],
              ],
            ],
            [
              [
                [-16.510416, 19.890417000000127],
                [-16.510416, 19.892082],
                [-16.510139, 19.892082],
                [-16.510139, 19.892639],
                [-16.509858999999892, 19.892639],
                [-16.509858999999892, 19.893473],
                [-16.509306, 19.893473],
                [-16.509306, 19.893749000000128],
                [-16.508751, 19.893749000000128],
                [-16.508751, 19.892639],
                [-16.50902699999989, 19.892639],
                [-16.50902699999989, 19.892082],
                [-16.509306, 19.892082],
                [-16.509306, 19.890972],
                [-16.509583, 19.890972],
                [-16.509583, 19.890139],
                [-16.509858999999892, 19.890139],
                [-16.509858999999892, 19.88986],
                [-16.510416, 19.88986],
                [-16.510416, 19.890139],
                [-16.510695, 19.890139],
                [-16.510695, 19.890417000000127],
                [-16.510416, 19.890417000000127],
              ],
            ],
            [
              [
                [-16.501527999999894, 19.901806],
                [-16.501527999999894, 19.901251000000173],
                [-16.501249, 19.901251000000173],
                [-16.501249, 19.90014100000019],
                [-16.501527999999894, 19.90014100000019],
                [-16.501527999999894, 19.899584000000118],
                [-16.501805999999874, 19.899584000000118],
                [-16.501805999999874, 19.899305],
                [-16.502360999999894, 19.899305],
                [-16.502360999999894, 19.899029],
                [-16.502916, 19.899029],
                [-16.502916, 19.898750000000177],
                [-16.50375, 19.898750000000177],
                [-16.50375, 19.898472],
                [-16.505417, 19.898472],
                [-16.505417, 19.898750000000177],
                [-16.505694999999889, 19.898750000000177],
                [-16.505694999999889, 19.900417000000118],
                [-16.505417, 19.900417000000118],
                [-16.505417, 19.900972],
                [-16.505694999999889, 19.900972],
                [-16.505694999999889, 19.901251000000173],
                [-16.505417, 19.901251000000173],
                [-16.505417, 19.901527000000101],
                [-16.505137999999874, 19.901527000000101],
                [-16.505137999999874, 19.901806],
                [-16.504583, 19.901806],
                [-16.504583, 19.902082000000178],
                [-16.504027999999892, 19.902082000000178],
                [-16.504027999999892, 19.90236300000015],
                [-16.50347099999982, 19.90236300000015],
                [-16.50347099999982, 19.902639],
                [-16.502360999999894, 19.902639],
                [-16.502360999999894, 19.90236300000015],
                [-16.502085, 19.90236300000015],
                [-16.502085, 19.902082000000178],
                [-16.501805999999874, 19.902082000000178],
                [-16.501805999999874, 19.901806],
                [-16.501527999999894, 19.901806],
              ],
            ],
            [
              [
                [-16.449894, 20.61981],
                [-16.449862, 20.620136],
                [-16.449306, 20.620136],
                [-16.449303, 20.619434],
                [-16.449303, 20.61902600000019],
                [-16.449028, 20.61902600000019],
                [-16.449028, 20.618195000000185],
                [-16.448749999999848, 20.618195000000185],
                [-16.448749999999848, 20.61736100000013],
                [-16.448475, 20.61736100000013],
                [-16.448475, 20.616804],
                [-16.448195, 20.616804],
                [-16.448195, 20.615139000000113],
                [-16.447914, 20.615139000000113],
                [-16.447914, 20.614027000000135],
                [-16.447639, 20.614027000000135],
                [-16.447639, 20.613195000000189],
                [-16.447557, 20.613195000000189],
                [-16.447363, 20.613194000000135],
                [-16.447363, 20.612917],
                [-16.447083999999847, 20.612917],
                [-16.447083999999847, 20.612638000000118],
                [-16.446806, 20.612638000000118],
                [-16.446806, 20.612083],
                [-16.446527, 20.612083],
                [-16.446527, 20.611252],
                [-16.447083999999847, 20.611252],
                [-16.447083999999847, 20.610970000000123],
                [-16.447363, 20.610970000000123],
                [-16.447363, 20.610416000000157],
                [-16.447639, 20.610416000000157],
                [-16.447639, 20.608749],
                [-16.447914, 20.608749],
                [-16.447914, 20.608473000000117],
                [-16.448195, 20.608473000000117],
                [-16.448195, 20.60819400000014],
                [-16.448475, 20.60819400000014],
                [-16.448475, 20.607361000000139],
                [-16.448195, 20.607361000000139],
                [-16.448195, 20.607084000000157],
                [-16.448475, 20.607082000000162],
                [-16.448475, 20.606806],
                [-16.449028, 20.606806],
                [-16.449028, 20.606527],
                [-16.449303, 20.606527],
                [-16.449306, 20.606251000000157],
                [-16.449582999999848, 20.606251000000157],
                [-16.449582999999848, 20.605984],
                [-16.449582999999848, 20.605139000000122],
                [-16.449306, 20.605139000000122],
                [-16.449303, 20.604860000000144],
                [-16.449028, 20.604860000000144],
                [-16.449028, 20.604029000000139],
                [-16.449303, 20.604029000000139],
                [-16.449306, 20.603472],
                [-16.449402, 20.603472],
                [-16.449582999999848, 20.603472],
                [-16.449582999999848, 20.602083],
                [-16.449306, 20.602083],
                [-16.449303, 20.60125],
                [-16.448971, 20.601261],
                [-16.449028, 20.600973],
                [-16.448913999999832, 20.600973],
                [-16.448749999999848, 20.600973],
                [-16.448802999999884, 20.600407000000132],
                [-16.449303, 20.600416000000166],
                [-16.449306, 20.60014],
                [-16.449582999999848, 20.60014],
                [-16.449582999999848, 20.599585000000161],
                [-16.449306, 20.599585000000161],
                [-16.449303, 20.599028],
                [-16.449028, 20.599028],
                [-16.449028, 20.597084000000166],
                [-16.449303, 20.597084000000166],
                [-16.449303, 20.595139000000188],
                [-16.449028, 20.595139000000188],
                [-16.449028, 20.594860000000153],
                [-16.448475, 20.594860000000153],
                [-16.448475, 20.592916],
                [-16.448195, 20.592916],
                [-16.448195, 20.592636],
                [-16.447914, 20.592636],
                [-16.447914, 20.591249000000175],
                [-16.448195, 20.591249000000175],
                [-16.448195, 20.590973],
                [-16.448475, 20.590973],
                [-16.448475, 20.589582000000121],
                [-16.448749999999848, 20.589582000000121],
                [-16.448749999999848, 20.589306],
                [-16.449028, 20.589306],
                [-16.449028, 20.589027],
                [-16.449303, 20.589027],
                [-16.449306, 20.587360000000103],
                [-16.449582999999848, 20.587360000000103],
                [-16.449582999999848, 20.585974000000192],
                [-16.449862, 20.585974000000192],
                [-16.449862, 20.585138],
                [-16.450138, 20.585138],
                [-16.450138, 20.584028000000103],
                [-16.450416999999845, 20.584028000000103],
                [-16.450416999999845, 20.583752000000175],
                [-16.450697, 20.583752000000175],
                [-16.450697, 20.583195000000103],
                [-16.450972, 20.583195000000103],
                [-16.450972, 20.582916000000125],
                [-16.451249999999845, 20.582916000000125],
                [-16.451249999999845, 20.577915],
                [-16.450972, 20.577915],
                [-16.450972, 20.577084000000184],
                [-16.450697, 20.577084000000184],
                [-16.450697, 20.576805],
                [-16.450138, 20.576805],
                [-16.450138, 20.577362000000107],
                [-16.449862, 20.577362000000107],
                [-16.449862, 20.577915],
                [-16.449582999999848, 20.577915],
                [-16.449582999999848, 20.578472],
                [-16.449306, 20.578472],
                [-16.449306, 20.579027],
                [-16.449028, 20.579027],
                [-16.449028, 20.583195000000103],
                [-16.448749999999848, 20.583195000000103],
                [-16.448749999999848, 20.586805],
                [-16.448475, 20.586805],
                [-16.448475, 20.589027],
                [-16.448195, 20.589027],
                [-16.448195, 20.589582000000121],
                [-16.447914, 20.589582000000121],
                [-16.447914, 20.582083000000125],
                [-16.448195, 20.582083000000125],
                [-16.448195, 20.581806],
                [-16.448475, 20.581806],
                [-16.448475, 20.580973],
                [-16.448749999999848, 20.580973],
                [-16.448749999999848, 20.578194],
                [-16.448475, 20.578194],
                [-16.448475, 20.577915],
                [-16.448749999999848, 20.577915],
                [-16.448749999999848, 20.577639],
                [-16.449028, 20.577639],
                [-16.449028, 20.575697],
                [-16.448749999999848, 20.575697],
                [-16.448749999999848, 20.571806000000151],
                [-16.448475, 20.571806000000151],
                [-16.448475, 20.570692000000122],
                [-16.448359, 20.570692000000122],
                [-16.448195, 20.570692000000122],
                [-16.448195, 20.569584000000134],
                [-16.447914, 20.569584000000134],
                [-16.447914, 20.569027],
                [-16.447639, 20.569027],
                [-16.447639, 20.568748],
                [-16.447363, 20.568748],
                [-16.447363, 20.567638],
                [-16.447083999999847, 20.567638],
                [-16.447083999999847, 20.567083000000139],
                [-16.448195, 20.567083000000139],
                [-16.448195, 20.567362000000116],
                [-16.449306, 20.567362000000116],
                [-16.449303, 20.567675000000122],
                [-16.450972, 20.567638],
                [-16.450972, 20.567757000000142],
                [-16.450972, 20.567917],
                [-16.454027, 20.567907000000105],
                [-16.454027, 20.568748],
                [-16.452362, 20.568748],
                [-16.452362, 20.569027],
                [-16.449582999999848, 20.569027],
                [-16.449582999999848, 20.569305000000156],
                [-16.449862, 20.569305000000156],
                [-16.449862, 20.569862],
                [-16.450138, 20.569862],
                [-16.450138, 20.570417000000134],
                [-16.449862, 20.570417000000134],
                [-16.449862, 20.570972],
                [-16.449582999999848, 20.570972],
                [-16.449582999999848, 20.572639000000152],
                [-16.450138, 20.572639000000152],
                [-16.450138, 20.572363],
                [-16.450697, 20.572363],
                [-16.450697, 20.572081000000139],
                [-16.450972, 20.572081000000139],
                [-16.450972, 20.571806000000151],
                [-16.451249999999845, 20.571806000000151],
                [-16.451249999999845, 20.571527000000117],
                [-16.451529, 20.571527000000117],
                [-16.451529, 20.571253000000183],
                [-16.451805, 20.571253000000183],
                [-16.451805, 20.570972],
                [-16.452084, 20.570972],
                [-16.452084, 20.570692000000122],
                [-16.452362, 20.570692000000122],
                [-16.452362, 20.570972],
                [-16.452639, 20.570972],
                [-16.452639, 20.571253000000183],
                [-16.453472, 20.571253000000183],
                [-16.453472, 20.571527000000117],
                [-16.454861, 20.571527000000117],
                [-16.454861, 20.570417000000134],
                [-16.455973, 20.570417000000134],
                [-16.455973, 20.570141],
                [-16.456249, 20.570141],
                [-16.456249, 20.569584000000134],
                [-16.456528, 20.569584000000134],
                [-16.456528, 20.569305000000156],
                [-16.456249, 20.569305000000156],
                [-16.456249, 20.569023],
                [-16.456249, 20.568748],
                [-16.455973, 20.568748],
                [-16.455973, 20.568195000000117],
                [-16.456528, 20.568195000000117],
                [-16.456528, 20.567917],
                [-16.457082999999898, 20.567917],
                [-16.457082999999898, 20.568472000000156],
                [-16.457359, 20.568472000000156],
                [-16.457359, 20.569027],
                [-16.457642, 20.569027],
                [-16.457642, 20.569305000000156],
                [-16.458471, 20.569305000000156],
                [-16.458471, 20.569584000000134],
                [-16.458749999999895, 20.569584000000134],
                [-16.458749999999895, 20.569862],
                [-16.459028, 20.569862],
                [-16.459028, 20.570141],
                [-16.459864, 20.570141],
                [-16.459864, 20.570417000000134],
                [-16.460416999999893, 20.570417000000134],
                [-16.460416999999893, 20.570141],
                [-16.460694999999816, 20.570141],
                [-16.460694999999816, 20.570417000000134],
                [-16.461249999999893, 20.570417000000134],
                [-16.461249999999893, 20.570692000000122],
                [-16.462085999999886, 20.570692000000122],
                [-16.462085999999886, 20.570972],
                [-16.46236, 20.570972],
                [-16.46236, 20.571253000000183],
                [-16.462639, 20.571253000000183],
                [-16.462639, 20.571527000000117],
                [-16.463196, 20.571527000000117],
                [-16.463196, 20.572081000000139],
                [-16.463472, 20.572081000000139],
                [-16.463472, 20.572363],
                [-16.464581999999893, 20.572363],
                [-16.464581999999893, 20.572639000000152],
                [-16.465139, 20.572639000000152],
                [-16.465139, 20.572916000000134],
                [-16.465417999999886, 20.572916000000134],
                [-16.465417999999886, 20.573475],
                [-16.465693999999814, 20.573475],
                [-16.465693999999814, 20.574583],
                [-16.465973, 20.574583],
                [-16.465973, 20.57541700000013],
                [-16.465693999999814, 20.57541700000013],
                [-16.465693999999814, 20.576353],
                [-16.465693999999814, 20.576529000000107],
                [-16.465417999999886, 20.576529000000107],
                [-16.465417999999886, 20.577084000000184],
                [-16.465139, 20.577084000000184],
                [-16.465139, 20.577362000000107],
                [-16.464581999999893, 20.577362000000107],
                [-16.464581999999893, 20.577084000000184],
                [-16.464026999999817, 20.577084000000184],
                [-16.464026999999817, 20.576529000000107],
                [-16.463750999999888, 20.576529000000107],
                [-16.463750999999888, 20.575972],
                [-16.462916999999891, 20.575972],
                [-16.462916999999891, 20.575697],
                [-16.462085999999886, 20.575697],
                [-16.462085999999886, 20.57541700000013],
                [-16.461249999999893, 20.57541700000013],
                [-16.461249999999893, 20.575138000000152],
                [-16.460974, 20.575138000000152],
                [-16.460974, 20.574583],
                [-16.460694999999816, 20.574583],
                [-16.460694999999816, 20.574306],
                [-16.460138, 20.574306],
                [-16.460138, 20.574583],
                [-16.459864, 20.574583],
                [-16.459864, 20.574862],
                [-16.459581, 20.574862],
                [-16.459581, 20.57541700000013],
                [-16.459305, 20.57541700000013],
                [-16.459305, 20.575972],
                [-16.459581, 20.575972],
                [-16.459581, 20.57625000000013],
                [-16.459864, 20.57625000000013],
                [-16.459864, 20.576529000000107],
                [-16.460138, 20.576529000000107],
                [-16.460138, 20.576805],
                [-16.460416999999893, 20.576805],
                [-16.460416999999893, 20.577084000000184],
                [-16.460694999999816, 20.577084000000184],
                [-16.460694999999816, 20.577360000000112],
                [-16.460974, 20.577362000000107],
                [-16.460974, 20.577639],
                [-16.461527, 20.577639],
                [-16.461527, 20.577915],
                [-16.461805, 20.577915],
                [-16.461805, 20.578194],
                [-16.462085999999886, 20.578194],
                [-16.462085999999886, 20.578472],
                [-16.46236, 20.578472],
                [-16.46236, 20.578751000000125],
                [-16.462639, 20.578751000000125],
                [-16.462639, 20.579306],
                [-16.462916999999891, 20.579306],
                [-16.462916999999891, 20.579584000000125],
                [-16.463196, 20.579584000000125],
                [-16.463196, 20.579861000000108],
                [-16.463472, 20.579861000000108],
                [-16.463472, 20.580137],
                [-16.463750999999888, 20.580137],
                [-16.463750999999888, 20.580416000000184],
                [-16.464026999999817, 20.580416000000184],
                [-16.464026999999817, 20.580694000000108],
                [-16.464308, 20.580694000000108],
                [-16.464308, 20.581247],
                [-16.464860999999814, 20.581247],
                [-16.464860999999814, 20.581528],
                [-16.465417999999886, 20.581528],
                [-16.465417999999886, 20.581806],
                [-16.466528, 20.581806],
                [-16.466528, 20.582083000000125],
                [-16.467361, 20.582083000000125],
                [-16.467361, 20.582916000000125],
                [-16.467082999999889, 20.582916000000125],
                [-16.467082999999889, 20.583195000000103],
                [-16.467361, 20.583195000000103],
                [-16.467361, 20.583469],
                [-16.468195, 20.583469],
                [-16.468195, 20.584028000000103],
                [-16.468747999999891, 20.584028000000103],
                [-16.468747999999891, 20.582359],
                [-16.468472, 20.582359],
                [-16.468472, 20.579861000000108],
                [-16.468747999999891, 20.579861000000108],
                [-16.468747999999891, 20.579306],
                [-16.469028, 20.579306],
                [-16.469028, 20.578751000000125],
                [-16.469305, 20.578751000000125],
                [-16.469305, 20.578472],
                [-16.469028, 20.578472],
                [-16.469028, 20.577362000000107],
                [-16.468747999999891, 20.577362000000107],
                [-16.468747999999891, 20.577084000000184],
                [-16.467361, 20.577084000000184],
                [-16.467361, 20.576805],
                [-16.467082999999889, 20.576805],
                [-16.467082999999889, 20.57541700000013],
                [-16.46764, 20.57541700000013],
                [-16.46764, 20.575697],
                [-16.467915999999889, 20.575697],
                [-16.467915999999889, 20.575972],
                [-16.468472, 20.575972],
                [-16.468472, 20.57625000000013],
                [-16.469028, 20.57625000000013],
                [-16.469028, 20.576529000000107],
                [-16.469305, 20.576529000000107],
                [-16.469305, 20.576805],
                [-16.469583999999884, 20.576805],
                [-16.469583999999884, 20.577084000000184],
                [-16.470139, 20.577084000000184],
                [-16.470139, 20.577360000000112],
                [-16.470694, 20.577362000000107],
                [-16.470694, 20.578194],
                [-16.47097, 20.578194],
                [-16.47097, 20.578472],
                [-16.471527, 20.578472],
                [-16.471527, 20.578751000000125],
                [-16.471806, 20.578751000000125],
                [-16.471806, 20.579027],
                [-16.472081999999887, 20.579027],
                [-16.472081999999887, 20.579306],
                [-16.472361, 20.579306],
                [-16.472361, 20.579584000000125],
                [-16.472639, 20.579584000000125],
                [-16.472639, 20.579861000000108],
                [-16.472918, 20.579861000000108],
                [-16.472918, 20.580137],
                [-16.472639, 20.580137],
                [-16.472639, 20.581247],
                [-16.472918, 20.581247],
                [-16.472918, 20.582083000000125],
                [-16.473191999999869, 20.582083000000125],
                [-16.473191999999869, 20.582359],
                [-16.473475, 20.582359],
                [-16.473475, 20.582642000000192],
                [-16.473748999999884, 20.582642000000192],
                [-16.473748999999884, 20.582916000000125],
                [-16.474585, 20.582916000000125],
                [-16.474585, 20.583469],
                [-16.474861, 20.583469],
                [-16.474861, 20.583752000000175],
                [-16.47514, 20.583752000000175],
                [-16.47514, 20.585974000000192],
                [-16.475415999999882, 20.585974000000192],
                [-16.475415999999882, 20.588751],
                [-16.475695, 20.588751],
                [-16.475695, 20.589027],
                [-16.476528, 20.589027],
                [-16.476528, 20.589306],
                [-16.476807, 20.589306],
                [-16.476807, 20.589582000000121],
                [-16.477083, 20.589582000000121],
                [-16.477083, 20.590139],
                [-16.477362, 20.590139],
                [-16.477362, 20.590973],
                [-16.477638, 20.590973],
                [-16.477638, 20.591528000000153],
                [-16.477362, 20.591528000000153],
                [-16.477362, 20.594305],
                [-16.477083, 20.594305],
                [-16.477083, 20.596249000000171],
                [-16.476807, 20.596249000000171],
                [-16.476807, 20.597918],
                [-16.477083, 20.597918],
                [-16.477083, 20.60014],
                [-16.476807, 20.60014],
                [-16.476807, 20.600695000000144],
                [-16.476528, 20.600695000000144],
                [-16.476528, 20.60125],
                [-16.47625, 20.60125],
                [-16.47625, 20.602362],
                [-16.475973, 20.602362],
                [-16.475973, 20.603472],
                [-16.475695, 20.603472],
                [-16.475695, 20.604584],
                [-16.474861, 20.604584],
                [-16.474861, 20.604860000000144],
                [-16.474585, 20.604860000000144],
                [-16.474585, 20.605694],
                [-16.474861, 20.605694],
                [-16.474861, 20.605972000000122],
                [-16.47514, 20.605972000000122],
                [-16.47514, 20.606527],
                [-16.474861, 20.606527],
                [-16.474861, 20.607361000000139],
                [-16.474585, 20.607361000000139],
                [-16.474585, 20.607916],
                [-16.474306, 20.607916],
                [-16.474306, 20.608473000000117],
                [-16.474028, 20.608473000000117],
                [-16.474028, 20.612638000000118],
                [-16.474306, 20.612638000000118],
                [-16.474306, 20.615696000000185],
                [-16.474028, 20.615696000000185],
                [-16.474028, 20.615973],
                [-16.473748999999884, 20.615973],
                [-16.473748999999884, 20.616249],
                [-16.473475, 20.616249],
                [-16.473475, 20.61652800000013],
                [-16.473191999999869, 20.61652800000013],
                [-16.473191999999869, 20.616804],
                [-16.472361, 20.616804],
                [-16.472361, 20.617083],
                [-16.471806, 20.617083],
                [-16.471806, 20.61736100000013],
                [-16.471252, 20.61736100000013],
                [-16.471252, 20.617640000000108],
                [-16.47097, 20.617640000000108],
                [-16.47097, 20.617916],
                [-16.470416999999884, 20.617916],
                [-16.470416999999884, 20.618195000000185],
                [-16.468195, 20.618195000000185],
                [-16.468195, 20.618473000000108],
                [-16.460694999999816, 20.618473000000108],
                [-16.460694999999816, 20.61875],
                [-16.458471, 20.61875],
                [-16.458471, 20.61902600000019],
                [-16.458195, 20.61902600000019],
                [-16.458195, 20.619305],
                [-16.457359, 20.619305],
                [-16.457359, 20.619583],
                [-16.457082999999898, 20.619583],
                [-16.457082999999898, 20.619862000000126],
                [-16.455973, 20.619862000000126],
                [-16.455973, 20.620136],
                [-16.455694, 20.620136],
                [-16.455694, 20.620695000000126],
                [-16.454861, 20.620695000000126],
                [-16.454861, 20.620417],
                [-16.454306, 20.620417],
                [-16.454306, 20.620136],
                [-16.453472, 20.620136],
                [-16.453472, 20.620417],
                [-16.453194, 20.620417],
                [-16.453194, 20.620695000000126],
                [-16.452639, 20.620695000000126],
                [-16.452639, 20.620417],
                [-16.450697, 20.620417],
                [-16.450697, 20.620136],
                [-16.450416999999845, 20.620142000000158],
                [-16.450416999999845, 20.619862000000126],
                [-16.449894, 20.61981],
              ],
            ],
            [
              [
                [-16.480139, 20.623753],
                [-16.47986, 20.623753],
                [-16.47986, 20.62347],
                [-16.479584, 20.62347],
                [-16.479584, 20.623194000000126],
                [-16.47986, 20.623194000000126],
                [-16.47986, 20.622917],
                [-16.479584, 20.622917],
                [-16.479584, 20.62227200000018],
                [-16.479584, 20.621805000000109],
                [-16.479475, 20.621805000000109],
                [-16.479305, 20.621805000000109],
                [-16.479305, 20.621531000000175],
                [-16.479029, 20.621531000000175],
                [-16.479029, 20.619305],
                [-16.479305, 20.619305],
                [-16.479305, 20.61875],
                [-16.479584, 20.61875],
                [-16.479584, 20.61736100000013],
                [-16.479305, 20.61736100000013],
                [-16.479305, 20.617083],
                [-16.479029, 20.617083],
                [-16.479029, 20.616804],
                [-16.47875, 20.616804],
                [-16.47875, 20.616249],
                [-16.479305, 20.616249],
                [-16.479305, 20.615973],
                [-16.479584, 20.615973],
                [-16.479584, 20.615696000000185],
                [-16.480139, 20.615696000000185],
                [-16.480139, 20.615417],
                [-16.480417, 20.615417],
                [-16.480417, 20.615139000000113],
                [-16.480694, 20.615139000000113],
                [-16.480694, 20.614586],
                [-16.480789, 20.614586],
                [-16.480972, 20.614586],
                [-16.480972, 20.614305],
                [-16.481251, 20.614305],
                [-16.481251, 20.61375],
                [-16.481527, 20.61375],
                [-16.481527, 20.613194000000135],
                [-16.481806, 20.613194000000135],
                [-16.481806, 20.612638000000118],
                [-16.481527, 20.612638000000118],
                [-16.481527, 20.612364000000184],
                [-16.481192, 20.612352000000101],
                [-16.481251, 20.612083],
                [-16.480972, 20.612083],
                [-16.480972, 20.612364000000184],
                [-16.480694, 20.612364000000184],
                [-16.480694, 20.612917],
                [-16.480417, 20.612917],
                [-16.480417, 20.613194000000135],
                [-16.480139, 20.613195000000189],
                [-16.480139, 20.61375],
                [-16.47986, 20.61375],
                [-16.47986, 20.61486100000019],
                [-16.479584, 20.61486100000019],
                [-16.479584, 20.614027000000135],
                [-16.479305, 20.614027000000135],
                [-16.479305, 20.612364000000184],
                [-16.479584, 20.612364000000184],
                [-16.479584, 20.612083],
                [-16.480139, 20.612083],
                [-16.480139, 20.611805000000118],
                [-16.480417, 20.611805000000118],
                [-16.480417, 20.611528000000135],
                [-16.480694, 20.611528000000135],
                [-16.480694, 20.610970000000123],
                [-16.480972, 20.610970000000123],
                [-16.480972, 20.609030000000189],
                [-16.481251, 20.609030000000189],
                [-16.481251, 20.606806],
                [-16.480972, 20.606806],
                [-16.480972, 20.605972000000122],
                [-16.480694, 20.605972000000122],
                [-16.480694, 20.605139000000122],
                [-16.480972, 20.605139000000122],
                [-16.480972, 20.604584],
                [-16.481251, 20.604584],
                [-16.481251, 20.604305],
                [-16.481527, 20.604305],
                [-16.481527, 20.603750000000161],
                [-16.481806, 20.603750000000161],
                [-16.481806, 20.602917000000161],
                [-16.482082, 20.602917000000161],
                [-16.482084, 20.602083],
                [-16.482361, 20.602083],
                [-16.482361, 20.600695000000144],
                [-16.482639, 20.600695000000144],
                [-16.482639, 20.599306000000126],
                [-16.482916, 20.599306000000126],
                [-16.482916, 20.597918],
                [-16.482639, 20.597918],
                [-16.482639, 20.594860000000153],
                [-16.482916, 20.594860000000153],
                [-16.482916, 20.59403],
                [-16.483194, 20.59403],
                [-16.483194, 20.591806],
                [-16.483473, 20.591806],
                [-16.483473, 20.590973],
                [-16.483749, 20.590973],
                [-16.483749, 20.590694],
                [-16.484859, 20.590694],
                [-16.484859, 20.590973],
                [-16.485138, 20.590973],
                [-16.485138, 20.591806],
                [-16.484306, 20.591806],
                [-16.484306, 20.592083],
                [-16.484027999999853, 20.592083],
                [-16.484027999999853, 20.592636],
                [-16.484306, 20.592636],
                [-16.484306, 20.592916],
                [-16.484583, 20.592916],
                [-16.484583, 20.593195],
                [-16.484306, 20.593195],
                [-16.484306, 20.593748000000176],
                [-16.484027999999853, 20.593748000000176],
                [-16.484027999999853, 20.594305],
                [-16.483749, 20.594305],
                [-16.483749, 20.59597],
                [-16.484027999999853, 20.595984000000101],
                [-16.484018, 20.596525],
                [-16.484306, 20.596527],
                [-16.484306, 20.596806],
                [-16.484583, 20.596806],
                [-16.484583, 20.597084000000166],
                [-16.484859, 20.597084000000166],
                [-16.484859, 20.597639],
                [-16.485138, 20.597639],
                [-16.485138, 20.602362],
                [-16.484859, 20.602362],
                [-16.484859, 20.603472],
                [-16.485138, 20.603472],
                [-16.485142, 20.604029000000139],
                [-16.485416, 20.604029000000139],
                [-16.485416, 20.604584],
                [-16.485694999999851, 20.604584],
                [-16.485694999999851, 20.605139000000122],
                [-16.485971, 20.605139000000122],
                [-16.485971, 20.605694],
                [-16.486527999999851, 20.605694],
                [-16.486527999999851, 20.607082000000162],
                [-16.486803, 20.607084000000157],
                [-16.486803, 20.607916],
                [-16.487082, 20.607916],
                [-16.487082, 20.608473000000117],
                [-16.487242, 20.608473000000117],
                [-16.487917, 20.608473000000117],
                [-16.487917, 20.607637],
                [-16.488195, 20.607637],
                [-16.488195, 20.607361000000139],
                [-16.488474, 20.607361000000139],
                [-16.488474, 20.605972000000122],
                [-16.48875, 20.605972000000122],
                [-16.48875, 20.605417],
                [-16.488474, 20.605417],
                [-16.488474, 20.605139000000122],
                [-16.488195, 20.605139000000122],
                [-16.488195, 20.604305],
                [-16.487917, 20.604305],
                [-16.487917, 20.604029000000139],
                [-16.487803, 20.604029000000139],
                [-16.487638, 20.604029000000139],
                [-16.487638, 20.600973],
                [-16.487364, 20.600973],
                [-16.487364, 20.600695000000144],
                [-16.487082, 20.600695000000144],
                [-16.487082, 20.600416000000166],
                [-16.486527999999851, 20.600416000000166],
                [-16.486527999999851, 20.599585000000161],
                [-16.486252, 20.599585000000161],
                [-16.486252, 20.595419],
                [-16.486527999999851, 20.595419],
                [-16.486527999999851, 20.594860000000153],
                [-16.486803, 20.594860000000153],
                [-16.486803, 20.595139000000188],
                [-16.487082, 20.595139000000188],
                [-16.487082, 20.595419],
                [-16.487364, 20.595419],
                [-16.487364, 20.595694],
                [-16.487638, 20.595694],
                [-16.487638, 20.596249000000171],
                [-16.487917, 20.596249000000171],
                [-16.487917, 20.596527],
                [-16.488474, 20.596527],
                [-16.488474, 20.597084000000166],
                [-16.48875, 20.597084000000166],
                [-16.48875, 20.597363000000144],
                [-16.488474, 20.597363000000144],
                [-16.488474, 20.597639],
                [-16.487917, 20.597639],
                [-16.487917, 20.597918],
                [-16.487638, 20.597918],
                [-16.487638, 20.598761],
                [-16.487917, 20.598749],
                [-16.487917, 20.599028],
                [-16.488195, 20.599028],
                [-16.488195, 20.599306000000126],
                [-16.488474, 20.599306000000126],
                [-16.488474, 20.599585000000161],
                [-16.48875, 20.599585000000161],
                [-16.48875, 20.599861],
                [-16.489026999999851, 20.599861],
                [-16.489026999999851, 20.60014],
                [-16.489305, 20.60014],
                [-16.489305, 20.600416000000166],
                [-16.489586, 20.600416000000166],
                [-16.489586, 20.600973],
                [-16.489861, 20.600973],
                [-16.489861, 20.60125],
                [-16.490139, 20.60125],
                [-16.490139, 20.601528000000144],
                [-16.490696, 20.601528000000144],
                [-16.490696, 20.603195],
                [-16.490417, 20.603195],
                [-16.490417, 20.605139000000122],
                [-16.490139, 20.605139000000122],
                [-16.490139, 20.606527],
                [-16.489861, 20.606527],
                [-16.489861, 20.607084000000157],
                [-16.489586, 20.607084000000157],
                [-16.489586, 20.607637],
                [-16.489305, 20.607637],
                [-16.489305, 20.609306000000117],
                [-16.489026999999851, 20.609306000000117],
                [-16.489026999999851, 20.609859],
                [-16.48875, 20.609859],
                [-16.48875, 20.610416000000157],
                [-16.488474, 20.610416000000157],
                [-16.488474, 20.610695000000135],
                [-16.488195, 20.610695000000135],
                [-16.488195, 20.611252],
                [-16.487638, 20.611252],
                [-16.487638, 20.611528000000135],
                [-16.487082, 20.611528000000135],
                [-16.487082, 20.611805000000118],
                [-16.486803, 20.611805000000118],
                [-16.486803, 20.611948],
                [-16.486803, 20.614027000000135],
                [-16.487082, 20.614027000000135],
                [-16.487082, 20.614305],
                [-16.487364, 20.614305],
                [-16.487364, 20.61486100000019],
                [-16.487082, 20.61486100000019],
                [-16.487082, 20.61652800000013],
                [-16.486803, 20.61652800000013],
                [-16.486803, 20.617083],
                [-16.486527999999851, 20.617083],
                [-16.486527999999851, 20.617640000000108],
                [-16.486252, 20.617640000000108],
                [-16.486252, 20.618195000000185],
                [-16.485971, 20.618195000000185],
                [-16.485971, 20.618473000000108],
                [-16.485694999999851, 20.618473000000108],
                [-16.485694999999851, 20.61875],
                [-16.485416, 20.61875],
                [-16.485416, 20.619305],
                [-16.485142, 20.619305],
                [-16.485138, 20.620136],
                [-16.484859, 20.620136],
                [-16.484859, 20.620695000000126],
                [-16.484306, 20.620695000000126],
                [-16.484306, 20.620972000000108],
                [-16.484027999999853, 20.620972000000108],
                [-16.484027999999853, 20.621805000000109],
                [-16.483749, 20.621805000000109],
                [-16.483749, 20.62235800000019],
                [-16.483473, 20.62235800000019],
                [-16.483473, 20.622641000000158],
                [-16.483194, 20.622641000000158],
                [-16.483194, 20.623194000000126],
                [-16.482916, 20.623194000000126],
                [-16.482916, 20.624027000000126],
                [-16.482639, 20.624027000000126],
                [-16.482639, 20.624306000000104],
                [-16.482361, 20.624306000000104],
                [-16.482361, 20.624863000000175],
                [-16.482084, 20.624863000000175],
                [-16.482084, 20.62569400000018],
                [-16.481527, 20.62569400000018],
                [-16.481527, 20.625416],
                [-16.480694, 20.625416],
                [-16.480694, 20.625139000000104],
                [-16.480417, 20.625139000000104],
                [-16.480417, 20.624027000000126],
                [-16.480139, 20.624027000000126],
                [-16.480139, 20.623753],
              ],
            ],
            [
              [
                [-16.490417, 20.61652800000013],
                [-16.490696, 20.61652800000013],
                [-16.490696, 20.615973],
                [-16.490973, 20.615973],
                [-16.490973, 20.614027000000135],
                [-16.491249, 20.614027000000135],
                [-16.491249, 20.613194000000135],
                [-16.491528, 20.613194000000135],
                [-16.491528, 20.612917],
                [-16.491807999999821, 20.612917],
                [-16.491807999999821, 20.612638000000118],
                [-16.492361, 20.612638000000118],
                [-16.492361, 20.612083],
                [-16.49264, 20.612083],
                [-16.49264, 20.611252],
                [-16.492916, 20.611252],
                [-16.492916, 20.610695000000135],
                [-16.493195, 20.610695000000135],
                [-16.493195, 20.610416000000157],
                [-16.493473, 20.610416000000157],
                [-16.493473, 20.609859],
                [-16.49375, 20.609859],
                [-16.49375, 20.609583000000157],
                [-16.494028, 20.609583000000157],
                [-16.494028, 20.607637],
                [-16.494305, 20.607637],
                [-16.494305, 20.607361000000139],
                [-16.494583, 20.607361000000139],
                [-16.494583, 20.607084000000157],
                [-16.494861999999898, 20.607082000000162],
                [-16.494861999999898, 20.606527],
                [-16.495694999999898, 20.606527],
                [-16.495694999999898, 20.606806],
                [-16.495972, 20.606806],
                [-16.495972, 20.607082000000162],
                [-16.496248, 20.607084000000157],
                [-16.496248, 20.607361000000139],
                [-16.496528999999896, 20.607361000000139],
                [-16.496528999999896, 20.60819400000014],
                [-16.496248, 20.60819400000014],
                [-16.496248, 20.608749],
                [-16.496528999999896, 20.608749],
                [-16.496528999999896, 20.609030000000189],
                [-16.497084, 20.609030000000189],
                [-16.497084, 20.609306000000117],
                [-16.49736, 20.609306000000117],
                [-16.49736, 20.610138],
                [-16.497638999999822, 20.610138],
                [-16.497638999999822, 20.610970000000123],
                [-16.497917, 20.610970000000123],
                [-16.497917, 20.611805000000118],
                [-16.497638999999822, 20.611805000000118],
                [-16.497638999999822, 20.612638000000118],
                [-16.497917, 20.612638000000118],
                [-16.497917, 20.613474],
                [-16.498193999999899, 20.613474],
                [-16.498193999999899, 20.614305],
                [-16.49847, 20.614305],
                [-16.49847, 20.61486100000019],
                [-16.498753, 20.61486100000019],
                [-16.498753, 20.615417],
                [-16.499026999999899, 20.615417],
                [-16.499026999999899, 20.616249],
                [-16.49930599999982, 20.616249],
                [-16.49930599999982, 20.617916],
                [-16.499582, 20.617916],
                [-16.499582, 20.618195000000185],
                [-16.50013899999982, 20.618195000000185],
                [-16.50013899999982, 20.617916],
                [-16.500416, 20.617916],
                [-16.500416, 20.617640000000108],
                [-16.500692, 20.617640000000108],
                [-16.500692, 20.619305],
                [-16.500416, 20.619305],
                [-16.500416, 20.620136],
                [-16.50013899999982, 20.620136],
                [-16.50013899999982, 20.619583],
                [-16.499862999999891, 20.619583],
                [-16.499862999999891, 20.61902600000019],
                [-16.499582, 20.61902600000019],
                [-16.499582, 20.61875],
                [-16.49930599999982, 20.61875],
                [-16.49930599999982, 20.618473000000108],
                [-16.499026999999899, 20.618473000000108],
                [-16.499026999999899, 20.618195000000185],
                [-16.498753, 20.618195000000185],
                [-16.498753, 20.617916],
                [-16.49847, 20.617916],
                [-16.49847, 20.61736100000013],
                [-16.498193999999899, 20.61736100000013],
                [-16.498193999999899, 20.617083],
                [-16.497917, 20.617083],
                [-16.497917, 20.61652800000013],
                [-16.497638999999822, 20.616537000000164],
                [-16.497638999999822, 20.615417],
                [-16.497355999999854, 20.615417],
                [-16.49736, 20.61486100000019],
                [-16.497263, 20.61486100000019],
                [-16.497084, 20.61486100000019],
                [-16.497084, 20.614586],
                [-16.496805, 20.614586],
                [-16.496805, 20.614305],
                [-16.496528999999896, 20.614305],
                [-16.496571, 20.612886],
                [-16.496248, 20.612917],
                [-16.496248, 20.612440000000106],
                [-16.496248, 20.612364000000184],
                [-16.495417, 20.612364000000184],
                [-16.495417, 20.612083],
                [-16.494861999999898, 20.612083],
                [-16.494861999999898, 20.611805000000118],
                [-16.494028, 20.611805000000118],
                [-16.494028, 20.612083],
                [-16.49375, 20.612083],
                [-16.49375, 20.612364000000184],
                [-16.493347, 20.612364000000184],
                [-16.492916, 20.612364000000184],
                [-16.492916, 20.612917],
                [-16.49264, 20.612917],
                [-16.492668, 20.614027000000135],
                [-16.492916, 20.614027000000135],
                [-16.492916, 20.615139000000113],
                [-16.493195, 20.615139000000113],
                [-16.493195, 20.616249],
                [-16.492361, 20.616249],
                [-16.492361, 20.61652800000013],
                [-16.492083, 20.61652800000013],
                [-16.492083, 20.616804],
                [-16.491528, 20.616804],
                [-16.491528, 20.617083],
                [-16.491438, 20.617083],
                [-16.491249, 20.617083],
                [-16.491249, 20.61736100000013],
                [-16.490973, 20.61736100000013],
                [-16.490973, 20.617916],
                [-16.490696, 20.617916],
                [-16.490696, 20.61736100000013],
                [-16.490417, 20.61736100000013],
                [-16.490417, 20.617083],
                [-16.490139, 20.617083],
                [-16.490139, 20.616804],
                [-16.490417, 20.616804],
                [-16.490417, 20.61652800000013],
              ],
            ],
            [
              [
                [-16.489861, 20.621531000000175],
                [-16.489305, 20.621531000000175],
                [-16.489305, 20.620695000000126],
                [-16.489586, 20.620695000000126],
                [-16.489586, 20.620136],
                [-16.489861, 20.620136],
                [-16.489861, 20.619583],
                [-16.490139, 20.619583],
                [-16.490139, 20.618473000000108],
                [-16.490417, 20.618473000000108],
                [-16.490417, 20.617916],
                [-16.490696, 20.617916],
                [-16.490696, 20.619862000000126],
                [-16.490417, 20.619862000000126],
                [-16.490417, 20.620695000000126],
                [-16.490139, 20.620695000000126],
                [-16.490139, 20.621248],
                [-16.489861, 20.621248],
                [-16.489861, 20.621531000000175],
              ],
            ],
            [
              [
                [-16.459581, 20.632639000000154],
                [-16.458749999999895, 20.632639000000154],
                [-16.458749999999895, 20.632360000000176],
                [-16.458195, 20.632360000000176],
                [-16.458195, 20.632084],
                [-16.457915999999898, 20.632084],
                [-16.457915999999898, 20.631527000000176],
                [-16.457359, 20.631527000000176],
                [-16.457359, 20.630417],
                [-16.457082999999898, 20.630417],
                [-16.457082999999898, 20.630138000000159],
                [-16.456528, 20.630138000000159],
                [-16.456528, 20.629028000000176],
                [-16.456249, 20.629028000000176],
                [-16.456249, 20.628195000000176],
                [-16.455694, 20.628195000000176],
                [-16.455694, 20.62764],
                [-16.455416, 20.62764],
                [-16.455416, 20.627361],
                [-16.454583999999898, 20.627361],
                [-16.454583999999898, 20.627085],
                [-16.454306, 20.627085],
                [-16.454306, 20.626528],
                [-16.454027, 20.626528],
                [-16.454027, 20.626249],
                [-16.453750999999897, 20.626249],
                [-16.453750999999897, 20.625973000000158],
                [-16.453472, 20.625973000000158],
                [-16.453472, 20.62569400000018],
                [-16.453194, 20.62569400000018],
                [-16.453194, 20.624306000000104],
                [-16.452917, 20.624306000000104],
                [-16.452917, 20.624027000000126],
                [-16.452362, 20.624027000000126],
                [-16.45236, 20.62347],
                [-16.452084, 20.62347],
                [-16.452084, 20.622917],
                [-16.451805, 20.622917],
                [-16.451805, 20.622641000000158],
                [-16.451529, 20.622641000000158],
                [-16.451529, 20.62235800000019],
                [-16.451249999999845, 20.62235800000019],
                [-16.451249999999845, 20.621805000000109],
                [-16.450972, 20.621805000000109],
                [-16.450972, 20.621531000000175],
                [-16.451529, 20.621531000000175],
                [-16.451529, 20.621248],
                [-16.452084, 20.621248],
                [-16.452084, 20.621531000000175],
                [-16.452362, 20.621531000000175],
                [-16.452362, 20.622084],
                [-16.452639, 20.622084],
                [-16.452639, 20.62235800000019],
                [-16.452917, 20.62235800000019],
                [-16.452917, 20.622641000000158],
                [-16.453194, 20.622641000000158],
                [-16.453194, 20.622917],
                [-16.453750999999897, 20.622917],
                [-16.453750999999897, 20.623194000000126],
                [-16.454583999999898, 20.623194000000126],
                [-16.454583999999898, 20.62347],
                [-16.457082999999898, 20.62347],
                [-16.457082999999898, 20.623753],
                [-16.457642, 20.623753],
                [-16.457642, 20.624027000000126],
                [-16.457915999999898, 20.624027000000126],
                [-16.457915999999898, 20.624584],
                [-16.458195, 20.624584],
                [-16.458195, 20.624863000000175],
                [-16.458471, 20.624863000000175],
                [-16.458471, 20.625139000000104],
                [-16.458749999999895, 20.625139000000104],
                [-16.458749999999895, 20.627085],
                [-16.459028, 20.627085],
                [-16.459028, 20.627916],
                [-16.459305, 20.627916],
                [-16.459305, 20.628195000000176],
                [-16.459581, 20.628195000000176],
                [-16.459581, 20.628471000000104],
                [-16.459864, 20.628471000000104],
                [-16.459864, 20.62875],
                [-16.460138, 20.62875],
                [-16.460138, 20.629862],
                [-16.460416999999893, 20.629862],
                [-16.460416999999893, 20.630138000000159],
                [-16.460694999999816, 20.630138000000159],
                [-16.460694999999816, 20.630417],
                [-16.461805, 20.630417],
                [-16.461805, 20.630695],
                [-16.464308, 20.630695],
                [-16.464308, 20.630972],
                [-16.464581999999893, 20.630972],
                [-16.464581999999893, 20.63125],
                [-16.464860999999814, 20.63125],
                [-16.464860999999814, 20.631527000000176],
                [-16.464581999999893, 20.631527000000176],
                [-16.464581999999893, 20.632084],
                [-16.464308, 20.632084],
                [-16.464308, 20.632360000000176],
                [-16.464026999999817, 20.632360000000176],
                [-16.464026999999817, 20.632917],
                [-16.463750999999888, 20.632917],
                [-16.463750999999888, 20.633751000000132],
                [-16.46236, 20.633751000000132],
                [-16.46236, 20.633472000000154],
                [-16.461249999999893, 20.633472000000154],
                [-16.461249999999893, 20.633194],
                [-16.460416999999893, 20.633194],
                [-16.460416999999893, 20.632917],
                [-16.459581, 20.632917],
                [-16.459581, 20.632639000000154],
              ],
            ],
            [
              [
                [-16.493473, 20.642084],
                [-16.493473, 20.641806000000145],
                [-16.493195, 20.641806000000145],
                [-16.493195, 20.641527000000167],
                [-16.492916, 20.641527000000167],
                [-16.492916, 20.641251],
                [-16.49264, 20.641251],
                [-16.49264, 20.640972],
                [-16.492361, 20.640972],
                [-16.492361, 20.640694000000167],
                [-16.491807999999821, 20.640694000000167],
                [-16.491807999999821, 20.640417000000127],
                [-16.491528, 20.640417000000127],
                [-16.491528, 20.637081],
                [-16.491249, 20.637081],
                [-16.491249, 20.63653],
                [-16.490973, 20.63653],
                [-16.490973, 20.635695],
                [-16.490696, 20.635695],
                [-16.490696, 20.634583],
                [-16.490417, 20.634583],
                [-16.490417, 20.633751000000132],
                [-16.490139, 20.633751000000132],
                [-16.490139, 20.632639000000154],
                [-16.489861, 20.632639000000154],
                [-16.489861, 20.631805],
                [-16.489305, 20.631805],
                [-16.489305, 20.631527000000176],
                [-16.489026999999851, 20.631527000000176],
                [-16.489026999999851, 20.630972],
                [-16.489305, 20.630972],
                [-16.489305, 20.630695],
                [-16.489586, 20.630695],
                [-16.489586, 20.630417],
                [-16.49011, 20.630424],
                [-16.490183, 20.630695],
                [-16.490417, 20.630695],
                [-16.490417, 20.631268],
                [-16.490696, 20.63125],
                [-16.490696, 20.631527000000176],
                [-16.490973, 20.631527000000176],
                [-16.490999, 20.633751000000132],
                [-16.491249, 20.633751000000132],
                [-16.491249, 20.632917],
                [-16.491528, 20.632917],
                [-16.491528, 20.632639000000154],
                [-16.491807999999821, 20.632639000000154],
                [-16.491807999999821, 20.632360000000176],
                [-16.492083, 20.632360000000176],
                [-16.492083, 20.631805],
                [-16.492361, 20.631805],
                [-16.492361, 20.63125],
                [-16.49264, 20.63125],
                [-16.49264, 20.630417],
                [-16.492916, 20.630417],
                [-16.492916, 20.629307000000153],
                [-16.493195, 20.629307000000153],
                [-16.493195, 20.62875],
                [-16.493473, 20.62875],
                [-16.493473, 20.628471000000104],
                [-16.49375, 20.628471000000104],
                [-16.49375, 20.628195000000176],
                [-16.494028, 20.628195000000176],
                [-16.494028, 20.627916],
                [-16.494305, 20.627916],
                [-16.494305, 20.62764],
                [-16.494583, 20.62764],
                [-16.494583, 20.627361],
                [-16.494861999999898, 20.627361],
                [-16.494861999999898, 20.627085],
                [-16.496248, 20.627085],
                [-16.496248, 20.627361],
                [-16.496528999999896, 20.627361],
                [-16.496528999999896, 20.62764],
                [-16.497084, 20.62764],
                [-16.497084, 20.629028000000176],
                [-16.49736, 20.629028000000176],
                [-16.49736, 20.629307000000153],
                [-16.497917, 20.629307000000153],
                [-16.497917, 20.629028000000176],
                [-16.498193999999899, 20.629028000000176],
                [-16.498193999999899, 20.62875],
                [-16.498753, 20.62875],
                [-16.498753, 20.628471000000104],
                [-16.499026999999899, 20.628471000000104],
                [-16.499026999999899, 20.629028000000176],
                [-16.49930599999982, 20.629028000000176],
                [-16.49930599999982, 20.629307000000153],
                [-16.499754, 20.629307000000153],
                [-16.499862999999891, 20.629307000000153],
                [-16.499862999999891, 20.629028000000176],
                [-16.50013899999982, 20.629028000000176],
                [-16.50013899999982, 20.629307000000153],
                [-16.500416, 20.629307000000153],
                [-16.500416, 20.629862],
                [-16.500692, 20.629862],
                [-16.500692, 20.630138000000159],
                [-16.500975, 20.630138000000159],
                [-16.500975, 20.630417],
                [-16.500692, 20.630417],
                [-16.500692, 20.63125],
                [-16.500975, 20.63125],
                [-16.500970999999822, 20.632614],
                [-16.500692, 20.632639000000154],
                [-16.500692, 20.634583],
                [-16.500975, 20.634583],
                [-16.500975, 20.635141],
                [-16.501249, 20.635141],
                [-16.501249, 20.635695],
                [-16.501527999999894, 20.635695],
                [-16.501527999999894, 20.637081],
                [-16.501805999999874, 20.637081],
                [-16.501805999999874, 20.638474000000144],
                [-16.502085, 20.638474000000144],
                [-16.502085, 20.639662],
                [-16.502085, 20.640139],
                [-16.501805999999874, 20.640171],
                [-16.501805999999874, 20.640694000000167],
                [-16.501249, 20.640694000000167],
                [-16.501249, 20.640972],
                [-16.500975, 20.640972],
                [-16.500975, 20.641251],
                [-16.50013899999982, 20.641251],
                [-16.50013899999982, 20.641527000000167],
                [-16.499862999999891, 20.641527000000167],
                [-16.499862999999891, 20.641806000000145],
                [-16.499582, 20.641806000000145],
                [-16.499582, 20.641527000000167],
                [-16.497638999999822, 20.641527000000167],
                [-16.497638999999822, 20.641806000000145],
                [-16.49736, 20.641806000000145],
                [-16.49736, 20.642084],
                [-16.496528999999896, 20.642084],
                [-16.496528999999896, 20.642361],
                [-16.496248, 20.642361],
                [-16.496248, 20.642639000000145],
                [-16.495417, 20.642639000000145],
                [-16.495417, 20.642916000000127],
                [-16.494583, 20.642916000000127],
                [-16.494583, 20.642639000000145],
                [-16.494028, 20.642639000000145],
                [-16.494028, 20.642361],
                [-16.49375, 20.642361],
                [-16.49375, 20.642084],
                [-16.493473, 20.642084],
              ],
            ],
            [
              [
                [-17.034581999999887, 20.81625],
                [-17.034581999999887, 20.815971000000104],
                [-17.034306, 20.815971000000104],
                [-17.034306, 20.814861000000121],
                [-17.034581999999887, 20.814861000000121],
                [-17.034581999999887, 20.814028000000121],
                [-17.034861, 20.814028000000121],
                [-17.034861, 20.813475000000153],
                [-17.035139, 20.813475000000153],
                [-17.035139, 20.812916],
                [-17.035417999999879, 20.812916],
                [-17.035417999999879, 20.812639000000104],
                [-17.035692, 20.812639000000104],
                [-17.035692, 20.812363000000175],
                [-17.035972999999899, 20.812363000000175],
                [-17.035972999999899, 20.812084],
                [-17.036804, 20.812084],
                [-17.036804, 20.81514],
                [-17.036528, 20.81514],
                [-17.036528, 20.815971000000104],
                [-17.037082999999882, 20.815971000000104],
                [-17.037082999999882, 20.81625],
                [-17.037361, 20.81625],
                [-17.037361, 20.817083],
                [-17.037082999999882, 20.817083],
                [-17.037082999999882, 20.817917],
                [-17.036528, 20.817917],
                [-17.036528, 20.817638],
                [-17.036804, 20.817638],
                [-17.036804, 20.817362],
                [-17.036248999999884, 20.817362],
                [-17.036248999999884, 20.816528000000176],
                [-17.035692, 20.816528000000176],
                [-17.035692, 20.81625],
                [-17.034581999999887, 20.81625],
              ],
            ],
          ],
        },
      },
    ],
  },
  103: {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: { GID_0: "TCD", COUNTRY: "Chad" },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [16.800699233000046, 7.555726050999965],
              [16.798973083000192, 7.558103085000028],
              [16.796350479000068, 7.561717987000179],
              [16.796714782000151, 7.566340924000144],
              [16.797580719999985, 7.577322961000107],
              [16.796428681000179, 7.579865932000075],
              [16.7939300540001, 7.585377216000097],
              [16.789499283000055, 7.590267182000048],
              [16.774700165000183, 7.606597900000168],
              [16.763807297000085, 7.615603924000141],
              [16.761850358000061, 7.617220878000182],
              [16.758668899000156, 7.618299008000179],
              [16.758567809000169, 7.618332863000035],
              [16.754957200000092, 7.61955499700008],
              [16.75152015600014, 7.622326852000015],
              [16.750736237000183, 7.623744966000118],
              [16.750410079, 7.624334810999983],
              [16.748088836000079, 7.628543853999986],
              [16.738679885000067, 7.633183956000039],
              [16.735700607000126, 7.636641979999979],
              [16.73366165200008, 7.642620088000115],
              [16.72677993700006, 7.650231839000071],
              [16.719440461000147, 7.6498970990001],
              [16.715894699000046, 7.650586129000089],
              [16.711271285000123, 7.65148305800011],
              [16.71052551300005, 7.651485921000131],
              [16.707019806000176, 7.65149497900012],
              [16.698152543000049, 7.65091419200013],
              [16.696071625000172, 7.650904179000122],
              [16.690179825000087, 7.650875092000092],
              [16.685119630000031, 7.653194904000031],
              [16.6791801440001, 7.663325785999973],
              [16.676595688000134, 7.663961888000017],
              [16.672508239000081, 7.664967060000095],
              [16.667230606000146, 7.668667794000044],
              [16.666608810000184, 7.671400071000051],
              [16.665660858000081, 7.675559997000164],
              [16.666250228000024, 7.686474800000099],
              [16.66666031, 7.693921089000071],
              [16.666568756000061, 7.695016861000113],
              [16.666360856000097, 7.69749879900013],
              [16.666147231000082, 7.700016975000153],
              [16.665359497000111, 7.709308148000162],
              [16.669170380000025, 7.725084782000067],
              [16.670288085000038, 7.729712964000044],
              [16.66801834099999, 7.73821783000011],
              [16.649652481000089, 7.748178005000113],
              [16.63841056900003, 7.758103847000143],
              [16.631290435000153, 7.762271882000107],
              [16.629981994000161, 7.762423992000095],
              [16.627826690000177, 7.76267290100003],
              [16.627149580999969, 7.762751101999982],
              [16.626192092999986, 7.762338163000038],
              [16.617689133000056, 7.75866699300019],
              [16.612390519000087, 7.758234023000057],
              [16.594480515000043, 7.768878937000125],
              [16.596809387000178, 7.774837016000049],
              [16.595279693000066, 7.792975903000126],
              [16.598070146000111, 7.798242093000113],
              [16.597869874000082, 7.804899215000091],
              [16.591449738000165, 7.812046051999971],
              [16.591379165000092, 7.817523956000059],
              [16.591281890000118, 7.825130940000122],
              [16.587308884000038, 7.829730033999965],
              [16.583717346000071, 7.83388710100013],
              [16.585580826000069, 7.837781906000032],
              [16.586446761000047, 7.847550869000031],
              [16.586799622000171, 7.851545811000165],
              [16.586946487000148, 7.851747990000092],
              [16.594449996000094, 7.862063886000101],
              [16.598169328000154, 7.870309830000053],
              [16.598064423000096, 7.873345852000114],
              [16.597969056000125, 7.876050948999989],
              [16.597904205000134, 7.87618398699999],
              [16.59734535199999, 7.877312183000072],
              [16.595920563000163, 7.880189895000171],
              [16.595449448000181, 7.878355980000038],
              [16.584688186000108, 7.873334884000087],
              [16.577438354000094, 7.869952203000082],
              [16.563619614000174, 7.868413926000073],
              [16.562952042, 7.86753606800005],
              [16.560840606000113, 7.864756107000176],
              [16.562108993000152, 7.861800194000125],
              [16.563110352000137, 7.859466076999979],
              [16.560794829000145, 7.855099202000076],
              [16.560560226000177, 7.854657174000124],
              [16.55607795800006, 7.854416848000142],
              [16.552730561000089, 7.854238034000048],
              [16.548173905, 7.856111050000152],
              [16.546520232999967, 7.856791019000184],
              [16.543886184000087, 7.856252194000092],
              [16.542139053000028, 7.855895042000043],
              [16.536090850999983, 7.849534034000044],
              [16.534969329000091, 7.848354815999983],
              [16.530349732000047, 7.84631300000018],
              [16.521179200000176, 7.845807076000085],
              [16.520654678000085, 7.845777988000123],
              [16.518831253000144, 7.845677853000098],
              [16.518356323000091, 7.845209121000039],
              [16.518226624000079, 7.845081805000177],
              [16.516059875000167, 7.842937947000053],
              [16.515130997000028, 7.835399151000161],
              [16.514139175000025, 7.827339174000031],
              [16.50982284600002, 7.82066583600016],
              [16.508810042999983, 7.819099903000165],
              [16.505138396000063, 7.816177846000073],
              [16.495630264999988, 7.808609963000151],
              [16.494476319000114, 7.804717064000045],
              [16.493999482000163, 7.803104878000056],
              [16.494396210000104, 7.799506187000077],
              [16.494659425000123, 7.797135831000048],
              [16.493307114000061, 7.793889999000101],
              [16.492559434000043, 7.792097092000176],
              [16.492200852, 7.791909218000058],
              [16.489099502000101, 7.790277957],
              [16.459829330000161, 7.784906865000039],
              [16.456159591000187, 7.787908078000157],
              [16.454109192000089, 7.792510032000052],
              [16.457359315000133, 7.798690796000017],
              [16.456764222000061, 7.799752236000131],
              [16.455297470000176, 7.802371979000043],
              [16.452028275000146, 7.802676201],
              [16.449390411000024, 7.802920819000178],
              [16.447479248999969, 7.803099154999984],
              [16.444702149000079, 7.804557801000101],
              [16.44219017100005, 7.805879116000085],
              [16.438959122000142, 7.805203914000174],
              [16.431846619000055, 7.793004037000173],
              [16.423879623000062, 7.779338837000125],
              [16.422254562000035, 7.778417111000067],
              [16.414640426000062, 7.774100781000186],
              [16.413431168000045, 7.770540237000091],
              [16.412538529000187, 7.767912864000039],
              [16.413909911000133, 7.764235019000125],
              [16.417407990000129, 7.761846065000157],
              [16.420339585000079, 7.75984478100014],
              [16.421390534000125, 7.757517815000142],
              [16.426269531000116, 7.746733189000111],
              [16.42417907700019, 7.743299008000065],
              [16.422090530000105, 7.74296188500017],
              [16.418649673000061, 7.742406846000165],
              [16.417995453000117, 7.741761208000014],
              [16.416799544000014, 7.740580083000111],
              [16.415620804000014, 7.734158993000108],
              [16.417284012000152, 7.72824478300015],
              [16.417430878000062, 7.727723121000167],
              [16.425230027000111, 7.721028805000117],
              [16.427597045000027, 7.721231937000084],
              [16.427759170000172, 7.721245765000163],
              [16.430519104000098, 7.718708993000064],
              [16.430164338000054, 7.717974186000106],
              [16.42587089400007, 7.709088803000043],
              [16.418939589999979, 7.70384216399998],
              [16.410379411000065, 7.694240094000065],
              [16.408811570000069, 7.690670967000074],
              [16.408021928000096, 7.68887806000015],
              [16.406650543000069, 7.685763837000138],
              [16.403766631, 7.682909966000182],
              [16.403650283000161, 7.682794095000077],
              [16.399198532000128, 7.680543899999975],
              [16.394649506000178, 7.678245067000091],
              [16.39439964200011, 7.677298068000027],
              [16.392770768000162, 7.671136857000136],
              [16.389476776000095, 7.671152115000155],
              [16.384710312999971, 7.67117500400019],
              [16.38370704600004, 7.67060709000009],
              [16.375480653000182, 7.665937901],
              [16.372259140000153, 7.666870117000087],
              [16.360969543000124, 7.66439676300007],
              [16.357069014999979, 7.667400837000116],
              [16.355442047000054, 7.66715908000009],
              [16.354240418000188, 7.666979789999971],
              [16.354070664000176, 7.666954041000054],
              [16.347600937000152, 7.661016940000081],
              [16.344655991000025, 7.661464214000148],
              [16.342479706000177, 7.661794186000122],
              [16.341653823000115, 7.661921025000027],
              [16.341379165000149, 7.661962987000095],
              [16.311859131000119, 7.651078224000059],
              [16.306882858000051, 7.651432038000166],
              [16.304460525000138, 7.651604176000035],
              [16.30150794900004, 7.651813031000188],
              [16.29665946900019, 7.647934914000189],
              [16.293891907000159, 7.641059875000053],
              [16.292230607000022, 7.636933803999966],
              [16.286458969000137, 7.632598878000124],
              [16.277868271000045, 7.627913952000085],
              [16.277690888000052, 7.62781715400007],
              [16.261779785000101, 7.623528003000047],
              [16.25114822400019, 7.613244057000031],
              [16.250333786000112, 7.612901211000064],
              [16.248542785000154, 7.612147807999975],
              [16.24482727100002, 7.61058902700006],
              [16.235460282000133, 7.606657983000048],
              [16.234933852000097, 7.607028008000043],
              [16.234460830000046, 7.607361793000052],
              [16.233169557000053, 7.608273983000174],
              [16.22920608600009, 7.608826160000149],
              [16.211080552000169, 7.611354828000117],
              [16.204189301000099, 7.61506080700002],
              [16.1966323850001, 7.615757942000073],
              [16.196359634000032, 7.615783215000135],
              [16.194614410000042, 7.615569115000028],
              [16.185531617000038, 7.61445188600004],
              [16.183315278000066, 7.613306999000088],
              [16.180690765000065, 7.611949921000019],
              [16.172819137000147, 7.602343083000108],
              [16.16357994100008, 7.594577789000084],
              [16.149309157, 7.595788001000017],
              [16.139629363999973, 7.594911100000047],
              [16.135597229000041, 7.59291982700006],
              [16.131330490000096, 7.590813160000096],
              [16.1262149800001, 7.590968133000047],
              [16.118522644000109, 7.591199876000133],
              [16.107965470000067, 7.591516971000146],
              [16.10461998, 7.59161806100019],
              [16.089124679000179, 7.589356900000041],
              [16.087810516000047, 7.589165211000079],
              [16.066137315000162, 7.581911088000027],
              [16.063398361, 7.58065891200016],
              [16.056680679000181, 7.577589036000177],
              [16.052772521000122, 7.573969841000121],
              [16.049278260000108, 7.57073402400016],
              [16.043249130000049, 7.559968948000119],
              [16.039047241000105, 7.543455124000161],
              [16.039024352000069, 7.537427902000047],
              [16.03900909400005, 7.53381204700014],
              [16.037576676000015, 7.532496930000093],
              [16.035770417000037, 7.530841828000121],
              [16.027009964000058, 7.529936790000022],
              [16.025852203999989, 7.527851105000025],
              [16.023080825000136, 7.522858143000065],
              [16.017539978000059, 7.519895077000058],
              [16.012920379000036, 7.51076984499997],
              [16.010349273000088, 7.505692004000025],
              [15.997890472000165, 7.498627186000078],
              [15.990031243000089, 7.49039220700007],
              [15.98104858500011, 7.489282130000106],
              [15.97757339500015, 7.485972882000056],
              [15.974808693, 7.483339787000034],
              [15.95110130400019, 7.483665944000109],
              [15.950593948000119, 7.483541011],
              [15.94843101400005, 7.483005046000187],
              [15.931049347000112, 7.478697777000036],
              [15.928032876000032, 7.476762771000153],
              [15.923940659000095, 7.47413683000002],
              [15.919678687000044, 7.471408844999985],
              [15.918588638000188, 7.470712185000082],
              [15.909370422000052, 7.467075825000165],
              [15.888540268000156, 7.46159982800009],
              [15.883561134000104, 7.460291862000076],
              [15.878375054, 7.45836782400005],
              [15.863961221000125, 7.453022956000098],
              [15.851298333000102, 7.452433109000083],
              [15.845770836000099, 7.452176094000038],
              [15.840239524000026, 7.450592042000039],
              [15.834010125000191, 7.445563794000066],
              [15.829859733000148, 7.444661142000143],
              [15.825489043000175, 7.446286203000057],
              [15.821443558000055, 7.445878029000028],
              [15.816740991000074, 7.445404054000164],
              [15.81626415300002, 7.444931030000078],
              [15.813049316000047, 7.441743850000023],
              [15.80959034, 7.441069126000116],
              [15.807139396000082, 7.444585801000017],
              [15.805813789000069, 7.446489810000116],
              [15.799969673000078, 7.454885005999984],
              [15.785288812000147, 7.461888791000149],
              [15.776774406000129, 7.46254396500018],
              [15.773529053000118, 7.462793827000041],
              [15.769139290000112, 7.457990169000141],
              [15.766379356000186, 7.457770824000022],
              [15.763697625000077, 7.459959031000153],
              [15.759031296000046, 7.463768959999982],
              [15.758139610000171, 7.470891953000034],
              [15.755790710000156, 7.474037171000077],
              [15.755389213000058, 7.474575043000129],
              [15.746179582000025, 7.476218223000046],
              [15.742970466000088, 7.478527069000108],
              [15.740403176000086, 7.483487128999968],
              [15.731540681000183, 7.50061416599999],
              [15.727641106000021, 7.504992009000091],
              [15.728845597000145, 7.511066914000139],
              [15.729861260000064, 7.516184806000126],
              [15.727951050000115, 7.519134044000055],
              [15.699073791999979, 7.518468858000063],
              [15.660038948000022, 7.517570973000147],
              [15.657350540000152, 7.517107964000104],
              [15.648069382000131, 7.515510082000048],
              [15.645859718000111, 7.515810013000078],
              [15.641180039000062, 7.51644516000016],
              [15.639550210000095, 7.516665936000152],
              [15.633716584000069, 7.515521050000075],
              [15.622052193000115, 7.513232232000064],
              [15.622051240000133, 7.513885020999965],
              [15.622051240000133, 7.514146805000166],
              [15.560359002000098, 7.510505200000011],
              [15.55428123500019, 7.510811806000163],
              [15.546549797000125, 7.511202812000022],
              [15.538227082000105, 7.51466608000004],
              [15.533470154000156, 7.516644955000061],
              [15.518247604000067, 7.522984027],
              [15.507479667000098, 7.527468205000162],
              [15.516850471000055, 7.532179833000043],
              [15.517578125, 7.533700944000088],
              [15.522878648000074, 7.544785976000128],
              [15.527365684000074, 7.546257973000138],
              [15.527720452000153, 7.546373845000119],
              [15.538161277000086, 7.554864883000164],
              [15.538707733000024, 7.555309772000101],
              [15.54065227600006, 7.556890011000178],
              [15.542803764000098, 7.559713841000075],
              [15.545590400000094, 7.56336784400014],
              [15.547251700000061, 7.565547944000059],
              [15.54781055400008, 7.566283226000167],
              [15.549900054000148, 7.569025040000156],
              [15.55192375200005, 7.570376872999987],
              [15.55308818900005, 7.570375443000046],
              [15.553669929000023, 7.571060657000089],
              [15.554615019999972, 7.572174072000109],
              [15.555653572000097, 7.575203419000047],
              [15.556487083000093, 7.577618122000104],
              [15.558011055000065, 7.579686642000183],
              [15.560711861000073, 7.581477643000142],
              [15.563410760000181, 7.582785607000176],
              [15.565281868000056, 7.585337161000041],
              [15.56902408600007, 7.590646745000072],
              [15.570202828000106, 7.592922687000112],
              [15.570548057000167, 7.594703198000104],
              [15.568065643000125, 7.598239898000145],
              [15.566061974000149, 7.600313188000087],
              [15.564681052000083, 7.602178573000174],
              [15.564130784000042, 7.604249477999986],
              [15.564760208000052, 7.609701158000064],
              [15.565315247000058, 7.611288070000057],
              [15.568084716000158, 7.612733840000146],
              [15.570368766000172, 7.614180088000069],
              [15.571617127000138, 7.616387367000016],
              [15.57231044700012, 7.617766858000039],
              [15.574874877000013, 7.620869159000108],
              [15.577991486000087, 7.624177934000045],
              [15.579031944000178, 7.625970840000093],
              [15.579106330000116, 7.629628659000161],
              [15.579111098000169, 7.633079529000042],
              [15.579667091999966, 7.635425568000073],
              [15.582645416000048, 7.638182163000181],
              [15.583270073000108, 7.639838220000115],
              [15.582306863000156, 7.643497467000145],
              [15.581826210000088, 7.646189690000028],
              [15.582036973000015, 7.648536205000141],
              [15.582179069000063, 7.650951386000031],
              [15.581351281000138, 7.65281629600014],
              [15.580176353000127, 7.653714658000013],
              [15.578792572999987, 7.65385484699999],
              [15.577409744000079, 7.654478074000167],
              [15.576858520000144, 7.655652046000114],
              [15.579695701000105, 7.656683445000112],
              [15.581634521000126, 7.657716275000098],
              [15.582814217000021, 7.660475255000108],
              [15.58302402400011, 7.662407398000028],
              [15.584202767000022, 7.663855076000061],
              [15.587179184000149, 7.665093421000165],
              [15.58821869000019, 7.666679383000087],
              [15.588500023000108, 7.669853687],
              [15.589126587000067, 7.672751904999984],
              [15.590721130000134, 7.675165176000121],
              [15.592177391000064, 7.677648067000121],
              [15.592251779000037, 7.681029798000054],
              [15.592532157000107, 7.684134961000041],
              [15.593574523000029, 7.687584877000177],
              [15.593646049000085, 7.689240933000065],
              [15.592680931000189, 7.691244126000186],
              [15.590953828000181, 7.693109512000149],
              [15.59040260200004, 7.694697858000097],
              [15.590824127000019, 7.699252607000119],
              [15.590895653000075, 7.701599122],
              [15.590139390000104, 7.704705715000102],
              [15.589658737000036, 7.707191467000087],
              [15.589595794000104, 7.712091446000045],
              [15.590085030000068, 7.715680122000151],
              [15.590917587000149, 7.717335224000124],
              [15.592511176000073, 7.718575477000172],
              [15.592651368000134, 7.720162392000134],
              [15.591961860000083, 7.721474647999969],
              [15.589887618000091, 7.722996235000039],
              [15.588713646000087, 7.724378109000156],
              [15.588578224000116, 7.72631073000008],
              [15.589342117000172, 7.728587151000056],
              [15.589693069000077, 7.732175827000162],
              [15.590389252000023, 7.735004425000057],
              [15.590947149999977, 7.738178731000176],
              [15.590257645000065, 7.740250110000034],
              [15.588459968999985, 7.741219043],
              [15.586593629000106, 7.742463589000124],
              [15.58652591800012, 7.743775368000058],
              [15.587706566000122, 7.746879578000062],
              [15.591524125000035, 7.754949094000153],
              [15.59242630000017, 7.757225513000151],
              [15.591600418000041, 7.759987355000021],
              [15.589874267000141, 7.76309585600012],
              [15.589116097000101, 7.765374183000063],
              [15.589190483000039, 7.768479823000121],
              [15.588642120000031, 7.772483826000041],
              [15.586843490000092, 7.773245334000023],
              [15.541807175000145, 7.783589364000022],
              [15.506870270000036, 7.792190552000079],
              [15.504727363000086, 7.793642522000141],
              [15.498094558999981, 7.80477476100009],
              [15.497130394000067, 7.806834221000145],
              [15.489324571000168, 7.819888115000026],
              [15.458443641000031, 7.870651723000037],
              [15.452363969000032, 7.881149293000135],
              [15.429175377000035, 7.94777727200011],
              [15.411096573000066, 8.001755714000012],
              [15.405668258000105, 8.016860009000027],
              [15.403307915000084, 8.023154259000137],
              [15.40047550200012, 8.030628205000028],
              [15.398157119000075, 8.037278176000029],
              [15.39567566, 8.044395447000113],
              [15.39491176700011, 8.055356026000084],
              [15.394897461000028, 8.055563926000048],
              [15.395793915000127, 8.084031105000065],
              [15.39619255100007, 8.088199615],
              [15.396727562000024, 8.091211318999967],
              [15.396739007000178, 8.091275214],
              [15.381235123000067, 8.122181892000185],
              [15.380495071000098, 8.123827935000065],
              [15.377036094000175, 8.131522180000104],
              [15.37455272700015, 8.13704586099999],
              [15.374538422000114, 8.13707733300015],
              [15.374520301000132, 8.137116432000084],
              [15.370443345000126, 8.146183014000087],
              [15.36213970100016, 8.16424274600007],
              [15.361543656000151, 8.165537834000133],
              [15.359023095000055, 8.17088794700004],
              [15.35604, 8.177262306000159],
              [15.355635644000188, 8.178126336000048],
              [15.349491119000049, 8.190713884000047],
              [15.34913921399999, 8.191488265000089],
              [15.345236779000118, 8.200076103000129],
              [15.341054916000076, 8.208909989000063],
              [15.339092254000036, 8.213057518000142],
              [15.331844329000148, 8.229343414000084],
              [15.325081825000154, 8.243246078000027],
              [15.323884964000172, 8.245707513000014],
              [15.318999290000022, 8.255934714999967],
              [15.31608295500007, 8.262169837000045],
              [15.311749457000133, 8.271432876000063],
              [15.30599498800018, 8.282290460000127],
              [15.305801391000045, 8.282710075000068],
              [15.305659294000122, 8.283018112000093],
              [15.298586845000159, 8.298338890000139],
              [15.295683862000033, 8.305042265999987],
              [15.293306351000069, 8.310532571000181],
              [15.291937829000176, 8.313304901000038],
              [15.288024903000064, 8.321231841999975],
              [15.282919883999966, 8.331790924000018],
              [15.279430390000073, 8.33900833199999],
              [15.275283813000101, 8.347583772000064],
              [15.269175529999984, 8.360918045000119],
              [15.259916306000036, 8.381490708000115],
              [15.252851487000157, 8.396253585000125],
              [15.247218131000182, 8.407587051000121],
              [15.241393090000031, 8.419683456000087],
              [15.236809731000164, 8.430159569000125],
              [15.230792999000073, 8.441969872000016],
              [15.22458648800017, 8.456445693000035],
              [15.200230599000122, 8.50901794400005],
              [15.197649956000078, 8.512351036000155],
              [15.192008971000064, 8.513496399000132],
              [15.188375473000178, 8.515402794000067],
              [15.182543755000097, 8.519404411000096],
              [15.177763939000045, 8.525024414000143],
              [15.173942565000061, 8.532452583000065],
              [15.169357300000172, 8.545404434000091],
              [15.165057183000101, 8.55540371],
              [15.160470008000061, 8.562451363000037],
              [15.15597629500013, 8.56797504400015],
              [15.154925346000141, 8.570222855000054],
              [15.154160500000103, 8.572318078000137],
              [15.15358829600018, 8.576031685000146],
              [15.153303147000088, 8.580735207000146],
              [15.152731896000034, 8.585877418000052],
              [15.152134895000074, 8.589977265000073],
              [15.151989936000064, 8.590979577000098],
              [15.151872635000075, 8.591780663000122],
              [15.152066230000173, 8.597208024],
              [15.151494980000166, 8.602159501000131],
              [15.147669791000112, 8.605112077],
              [15.143556596000053, 8.607589721000011],
              [15.140592575000028, 8.609018325000136],
              [15.13581085300018, 8.613114357000086],
              [15.129020690000118, 8.61902046199998],
              [15.123761178000166, 8.622925759000111],
              [15.119266510000045, 8.627782822000086],
              [15.115823746000103, 8.631592751000085],
              [15.11300659200009, 8.634918213999981],
              [15.110969544000113, 8.6370506290001],
              [15.110030175000134, 8.639598846000013],
              [15.109926225000038, 8.642770767],
              [15.109927177000145, 8.645318986000063],
              [15.109952926, 8.64837551200003],
              [15.109434126999986, 8.65187931100013],
              [15.108338356000047, 8.656015397000033],
              [15.106030465000117, 8.657796861000179],
              [15.103895187000035, 8.658543586000064],
              [15.099970818000088, 8.659980773000029],
              [15.096277238000027, 8.661417961000097],
              [15.092699050000078, 8.663487435000093],
              [15.090243340000143, 8.664612770000076],
              [15.089998246000107, 8.664725303000012],
              [15.088313102000086, 8.665498733999982],
              [15.084734917000105, 8.667107582000085],
              [15.079136849000179, 8.67026805800009],
              [15.074345588000142, 8.671360968999977],
              [15.070421219000082, 8.671936036000091],
              [15.06768226600019, 8.67287254300004],
              [15.066554070000109, 8.673257828000033],
              [15.063380241000118, 8.676187515000038],
              [15.063052178000078, 8.676482201000056],
              [15.061013223000032, 8.678313255000035],
              [15.059941293000179, 8.679129601000113],
              [15.059051513999975, 8.679807663000076],
              [15.056742668000084, 8.679520607000143],
              [15.056090355000038, 8.679537772],
              [15.054549217000044, 8.67957782700006],
              [15.052721977000033, 8.679396630000099],
              [15.052240372000085, 8.679348946000062],
              [15.0519647590001, 8.679262162000157],
              [15.050046921000046, 8.678659439000114],
              [15.046929360000092, 8.678774834000137],
              [15.042831421000074, 8.677683830000092],
              [15.040199280000138, 8.676650047000123],
              [15.037055015000135, 8.675787926000112],
              [15.036850928000035, 8.675731659000121],
              [15.034657478000099, 8.676248551000128],
              [15.034193039000115, 8.676493645000107],
              [15.033677102000183, 8.676766395000129],
              [15.032234192000146, 8.677627563000158],
              [15.03029441800004, 8.679085731000043],
              [15.029250145000049, 8.679870605000133],
              [15.027893066000161, 8.682085992],
              [15.027275085000099, 8.683094979000089],
              [15.023560523000015, 8.685061456000028],
              [15.020162581000079, 8.685926437000092],
              [15.018759728000077, 8.68614864400007],
              [15.016685486000085, 8.686477662000129],
              [15.015090943000018, 8.686301232000062],
              [15.013129234000189, 8.686083794000012],
              [15.010926246000167, 8.687245369000152],
              [15.010442733000048, 8.687500000000171],
              [15.008146286000169, 8.688535691000141],
              [15.00538539900009, 8.689780235000058],
              [15.001829147000024, 8.689544678000061],
              [15.0007848730001, 8.689531326000179],
              [14.99566459600004, 8.689466477000053],
              [14.99116039200004, 8.689859391000084],
              [14.989945413000157, 8.69002437600011],
              [14.98768329699999, 8.690330505000134],
              [14.986065864000011, 8.689963341000123],
              [14.984916687000066, 8.689702034000106],
              [14.983561516000123, 8.689629555000067],
              [14.981992721999973, 8.689544678000061],
              [14.983019828000067, 8.692375184000127],
              [14.98388957899999, 8.695049286000028],
              [14.984778403000178, 8.697808266000038],
              [14.983968735000133, 8.699925422999968],
              [14.985235214000056, 8.701045990000068],
              [14.9861021050001, 8.701812744000108],
              [14.987919808000129, 8.703070640000078],
              [14.987838743999987, 8.703208923999966],
              [14.987270355000135, 8.704174996000177],
              [14.986801148000097, 8.706077575999984],
              [14.98641777, 8.707632066000031],
              [14.986597061, 8.707877160000066],
              [14.988314629, 8.710227966000048],
              [14.990368843000169, 8.713215828000159],
              [14.991175651000162, 8.715700149000099],
              [14.991175651000162, 8.718380928000158],
              [14.990368843000169, 8.721473695000157],
              [14.990921974, 8.723518371000068],
              [14.990636825000138, 8.727091790000031],
              [14.989674568, 8.728223801000183],
              [14.988414764000026, 8.729705811000031],
              [14.987403869000161, 8.732252122000148],
              [14.98531627599999, 8.734061242000109],
              [14.984335900000076, 8.735299110000028],
              [14.983887672000094, 8.73586463900017],
              [14.983333588000107, 8.736205100000177],
              [14.982352257000173, 8.736808776000032],
              [14.981642723999983, 8.737815858000033],
              [14.981409073000123, 8.738148689000127],
              [14.977973937000115, 8.740427018000105],
              [14.974404334999974, 8.742302896000069],
              [14.973244668000063, 8.742687226000044],
              [14.971776962000092, 8.743173599000045],
              [14.96941947900018, 8.743508340000119],
              [14.967267990000096, 8.743336677000059],
              [14.966052056000024, 8.743240357000104],
              [14.964303971000049, 8.74351215500019],
              [14.963896752000039, 8.743575095999972],
              [14.962280274000022, 8.744981766000024],
              [14.961000442, 8.745718956000076],
              [14.958575248000102, 8.746254921000116],
              [14.957160949000013, 8.74759483400004],
              [14.955611229000056, 8.750475884000025],
              [14.954263688000026, 8.751615525000091],
              [14.952917099999979, 8.752486228000123],
              [14.95230960900011, 8.753492355000105],
              [14.951501846000156, 8.754831315000047],
              [14.951315880000038, 8.754923819999988],
              [14.950694084000077, 8.755233764000082],
              [14.947601318000181, 8.757261277000055],
              [14.946895600000062, 8.757723808000037],
              [14.942864418999989, 8.75819492300019],
              [14.940493583000148, 8.758273124000027],
              [14.938200951000169, 8.756306649000123],
              [14.936663628000076, 8.755286217000048],
              [14.936067581000032, 8.754891396000176],
              [14.932510376000153, 8.755126954000104],
              [14.931226731000038, 8.754929543],
              [14.930455208000069, 8.754811287],
              [14.927531243000033, 8.754732133000061],
              [14.925081253000144, 8.754810334000013],
              [14.92191887000007, 8.755438804000164],
              [14.921251296000094, 8.75653267000007],
              [14.920575142000075, 8.757640839000032],
              [14.919278144000089, 8.758746147000181],
              [14.918361662999985, 8.75952720600003],
              [14.918174744000055, 8.759758949000116],
              [14.91709613900008, 8.761100769000109],
              [14.916739465000092, 8.761662483000066],
              [14.916147232000185, 8.762594224000111],
              [14.914092063000055, 8.763459205],
              [14.912590028000011, 8.764874459000055],
              [14.912905693000141, 8.766919137000116],
              [14.91535568200004, 8.768413544000055],
              [14.919702529000119, 8.770931245000099],
              [14.921057702000041, 8.772323608000079],
              [14.922152519000122, 8.773447990000079],
              [14.923331261000158, 8.774570465000124],
              [14.923970223000083, 8.775178909000033],
              [14.92539215000005, 8.777145386000086],
              [14.925564766000093, 8.777915954999969],
              [14.925708770000142, 8.778560640000137],
              [14.923890115000177, 8.781155586000068],
              [14.921439170000156, 8.78351402200019],
              [14.918829918000085, 8.784929276000014],
              [14.918004989000167, 8.785613060000117],
              [14.915984154, 8.787287712000136],
              [14.914244653000083, 8.788702965000084],
              [14.913033486000131, 8.790205955999966],
              [14.91227340800009, 8.790696144000037],
              [14.911952017999965, 8.790904046000037],
              [14.912030219000144, 8.79247760700008],
              [14.911911011000086, 8.79290294700013],
              [14.91163444599999, 8.793892861000131],
              [14.911400795000134, 8.794067384000073],
              [14.909737587000166, 8.7953081120001],
              [14.907760619000044, 8.79570102800011],
              [14.907979012000055, 8.798245430000122],
              [14.907140732000187, 8.799225808000074],
              [14.906311989000073, 8.799867631000154],
              [14.906191826000168, 8.799960137000142],
              [14.905962945000169, 8.800220490000129],
              [14.904715539000165, 8.801637649000043],
              [14.903059006000149, 8.807559014000162],
              [14.902721404999966, 8.810373306000088],
              [14.901445388000184, 8.813485146],
              [14.897861480000131, 8.815056800000036],
              [14.895912171000077, 8.816114427000059],
              [14.895542144000103, 8.816314697000109],
              [14.893539428000111, 8.816733361000104],
              [14.892714501000171, 8.817266464000056],
              [14.891430854000077, 8.818096160000152],
              [14.88868999500005, 8.818514824000147],
              [14.886476517000062, 8.819667816000163],
              [14.88500023, 8.821554184000092],
              [14.884245872000122, 8.822015763000024],
              [14.882258414000034, 8.823231697999972],
              [14.88100528800004, 8.821775437000042],
              [14.880362510000168, 8.821028710000121],
              [14.879120827, 8.819602966000105],
              [14.878992081000035, 8.819455146000166],
              [14.87804317400014, 8.81997966800003],
              [14.877620697000054, 8.82301998100013],
              [14.876701356000126, 8.823826791000101],
              [14.875828743000113, 8.824592590000123],
              [14.872982979000028, 8.824801444],
              [14.872616767000125, 8.824610710000059],
              [14.870769501000041, 8.823646545000088],
              [14.869320869999967, 8.82208442699999],
              [14.868240357000047, 8.82091999000005],
              [14.867129325000121, 8.819388390000142],
              [14.866870880000135, 8.819031716000097],
              [14.865606309000043, 8.818087577000085],
              [14.864552497000091, 8.817353248000131],
              [14.863603592000175, 8.818506242000126],
              [14.861495019000017, 8.820497512999964],
              [14.857910156000173, 8.821754456000122],
              [14.855864525000129, 8.82062339800018],
              [14.854575157, 8.819910050000146],
              [14.854116440000155, 8.819656372999987],
              [14.853945732000057, 8.816522599000052],
              [14.853843690000133, 8.815137864000178],
              [14.853697776000161, 8.813155174000087],
              [14.850851059999968, 8.815251351000029],
              [14.8488483430001, 8.815460206000182],
              [14.845475197999974, 8.815144539000073],
              [14.843993186000091, 8.81514358600009],
              [14.842735290000121, 8.815142632],
              [14.841311454999982, 8.814772607000066],
              [14.840311051000185, 8.814513207000061],
              [14.838401794000049, 8.814819336000085],
              [14.836006164000082, 8.81520366699999],
              [14.800995826000189, 8.841052056000081],
              [14.789328575000184, 8.849666597000066],
              [14.768077851000157, 8.865210533000095],
              [14.729217530000085, 8.893633842000043],
              [14.728277206000087, 8.894321442000091],
              [14.703260421000095, 8.912613869000097],
              [14.647451401000069, 8.953454972000031],
              [14.618288995, 8.974796295000033],
              [14.611342429000103, 8.980278970000086],
              [14.590342522000071, 8.996854782000071],
              [14.587565422000182, 9.000232697],
              [14.58608341300004, 9.002035142000011],
              [14.561708451000129, 9.016354560000025],
              [14.551568986000063, 9.022244454000088],
              [14.540404320000164, 9.029252052000061],
              [14.533029557000077, 9.033617974000038],
              [14.51909828200013, 9.042554855000049],
              [14.497076035000134, 9.0537185660001],
              [14.496048927000061, 9.054711342000189],
              [14.492976188000114, 9.05768108400008],
              [14.490093230000127, 9.069887161000054],
              [14.489381789000163, 9.07256793900018],
              [14.488447190000159, 9.07609272000002],
              [14.486652374000187, 9.079146385],
              [14.483726502000025, 9.084124566000071],
              [14.471211433000065, 9.102625848000173],
              [14.470787048000034, 9.112495423000098],
              [14.470270156000083, 9.113254548000157],
              [14.467811584, 9.116867065000179],
              [14.466822625000191, 9.117238998000119],
              [14.462690353000028, 9.118792533000146],
              [14.458531379000021, 9.118945122000071],
              [14.457366944000057, 9.118988037000122],
              [14.452347756000052, 9.120812417000082],
              [14.446198463000087, 9.125178337000023],
              [14.433701515000109, 9.129534721000084],
              [14.426185608000083, 9.13805294000008],
              [14.417625427000132, 9.142019271000095],
              [14.403778076000037, 9.151041984000131],
              [14.397388458000023, 9.162246704000097],
              [14.392287254000166, 9.165132522000135],
              [14.389365195000039, 9.170554161000098],
              [14.381696701000067, 9.174405099000069],
              [14.373326301000134, 9.181977271000051],
              [14.368506432, 9.181329728000037],
              [14.364164352000103, 9.182919503000051],
              [14.361262321000083, 9.188104631000101],
              [14.357793808000054, 9.195206643000063],
              [14.359472274000041, 9.200240135000172],
              [14.358101845000078, 9.20247364100004],
              [14.355208397000126, 9.203027725000084],
              [14.351511001000063, 9.203340530000105],
              [14.348530769000035, 9.206849099000067],
              [14.348359108000125, 9.21291732800006],
              [14.350841522, 9.217473984000151],
              [14.355582237000135, 9.218201638000153],
              [14.357666015000063, 9.221159935],
              [14.356928825000182, 9.229304314000046],
              [14.36807823200013, 9.241222382000103],
              [14.370802880000156, 9.24609851800011],
              [14.37770652800009, 9.250423431000115],
              [14.381392479000112, 9.256978036000021],
              [14.384911536000061, 9.267045975000031],
              [14.38457870600007, 9.27359390200013],
              [14.382310867000115, 9.283412934000069],
              [14.380206108000095, 9.292113304000168],
              [14.373689651000177, 9.295136451000076],
              [14.347546578000049, 9.304832458000021],
              [14.340211869000086, 9.315280915000074],
              [14.334321023000086, 9.327008247000151],
              [14.330285072000038, 9.335865022000121],
              [14.327459333999968, 9.342168808000167],
              [14.323134422000066, 9.350264549000087],
              [14.323117256000103, 9.35029602100019],
              [14.315355302000171, 9.364825249000091],
              [14.313468932999967, 9.36669158899997],
              [14.311408042000096, 9.368730545000119],
              [14.306890488000022, 9.366602898],
              [14.298636436000095, 9.362715720999972],
              [14.292461394000043, 9.360419273000048],
              [14.291471480000041, 9.360050201000092],
              [14.291288377000058, 9.359352113000057],
              [14.291230202000179, 9.359361648000061],
              [14.284176826000078, 9.356738090000022],
              [14.279194831999973, 9.355850219000047],
              [14.274445535000041, 9.358395576000021],
              [14.270814895000058, 9.362814903000015],
              [14.270676613000148, 9.362983703000168],
              [14.267110824000042, 9.367323876000171],
              [14.257439613000031, 9.378483771999981],
              [14.256576538000104, 9.379993440000021],
              [14.250350953000066, 9.390883445000043],
              [14.2430038440001, 9.398576736],
              [14.237999917000081, 9.406950951000056],
              [14.239017487000069, 9.419650078000132],
              [14.221539498000141, 9.432787896000036],
              [14.215737344000047, 9.438205720000156],
              [14.211860656999988, 9.445463180000047],
              [14.201334954000174, 9.441846848000125],
              [14.196987153, 9.444072722999977],
              [14.19585228, 9.447902680000084],
              [14.193824767000024, 9.455484390000152],
              [14.189069747000076, 9.45978546200007],
              [14.185915947000069, 9.467205047000107],
              [14.183165550000126, 9.473826408999969],
              [14.177136420000068, 9.480636596000068],
              [14.175088882000125, 9.482062340000084],
              [14.163020134000078, 9.490468025000041],
              [14.156396866000023, 9.502190591000101],
              [14.15463542800012, 9.501977921000105],
              [14.153019905000122, 9.501783372000091],
              [14.148348809000026, 9.50424766500015],
              [14.145605088000082, 9.503611565000085],
              [14.141034126000079, 9.502552032999972],
              [14.134997368000143, 9.504214286000092],
              [14.123966216000099, 9.509217263000096],
              [14.117359161000081, 9.513672829000143],
              [14.11091423, 9.517808915000046],
              [14.108308792000059, 9.52993965100012],
              [14.107486724000125, 9.536884309000072],
              [14.107240677000107, 9.537296296000136],
              [14.099730491000059, 9.549880981],
              [14.092156409999973, 9.55489158600011],
              [14.083786964000069, 9.556386948000181],
              [14.07886028300004, 9.563560487000132],
              [14.074267387000191, 9.566423415999964],
              [14.072169304000056, 9.568892479000056],
              [14.059740067000064, 9.584031106000055],
              [14.055104256999982, 9.590680123000141],
              [14.054930686000091, 9.59092903200002],
              [14.051905632000114, 9.595268250000061],
              [14.046660424000095, 9.600764275000131],
              [14.046608924000111, 9.601524353000173],
              [14.045905113, 9.611941339000168],
              [14.043807983000079, 9.614011766000147],
              [14.03487300900008, 9.615503311],
              [14.023275375000082, 9.620182038000166],
              [14.00790119200019, 9.622694015000093],
              [14.002179147000049, 9.626030922000041],
              [13.987672807000081, 9.6377029410001],
              [13.987630845000126, 9.637736320000101],
              [13.982501030000151, 9.641862869000022],
              [13.982441902000062, 9.64243126000008],
              [13.982252121000101, 9.644258499000159],
              [13.991772652000066, 9.663026810000076],
              [13.992045402000088, 9.663564683000061],
              [14.005130768000072, 9.689359664000165],
              [14.016083718000061, 9.711254120000035],
              [14.020867348000081, 9.720817566000108],
              [14.021101951000048, 9.721286773000145],
              [14.026252747000058, 9.731581688000063],
              [14.03556346900001, 9.740470887000072],
              [14.048249245000022, 9.751047135000135],
              [14.064227104000167, 9.764904977000072],
              [14.068643569000074, 9.768589974000065],
              [14.074104309000177, 9.773157121000054],
              [14.083498955000152, 9.78232193000008],
              [14.083863259000111, 9.782676697000056],
              [14.109279633000028, 9.803354263000131],
              [14.120725632000187, 9.813019754000038],
              [14.120895385000097, 9.813162803000068],
              [14.127245902000027, 9.818525314000112],
              [14.130243300000075, 9.821056366000107],
              [14.133089066000139, 9.822712898000191],
              [14.135066031, 9.823863029000052],
              [14.142132760000038, 9.838064195000186],
              [14.154131890000031, 9.862180710000132],
              [14.154192925000018, 9.86230373300009],
              [14.157028198000035, 9.868000985000037],
              [14.159464837000144, 9.872896195000067],
              [14.162280082000109, 9.878552437000167],
              [14.162859916000116, 9.879844665000178],
              [14.16486168, 9.88430595400007],
              [14.173274038999978, 9.901175500000022],
              [14.177914619000092, 9.913404465000099],
              [14.183349609000174, 9.929866791999984],
              [14.184965133999981, 9.93449878600012],
              [14.186012268000127, 9.937501907000126],
              [14.191571236000186, 9.953439714000183],
              [14.193105698000124, 9.957839012000022],
              [14.198104858000079, 9.973009111000124],
              [14.206537248000188, 9.998596192000036],
              [14.208221436000088, 10.001634597000191],
              [14.208051680999972, 10.006039619000148],
              [14.208049774000074, 10.006105424000168],
              [14.212801934000026, 10.00635623900007],
              [14.215785026000106, 10.005005837000056],
              [14.220541001000072, 10.003659249000179],
              [14.225131989000147, 10.003670693000061],
              [14.228758812000081, 10.002960205000079],
              [14.232464790000108, 10.002729416000079],
              [14.235363961000132, 10.002975465000077],
              [14.240596771000185, 10.004105568000057],
              [14.24305152900007, 10.004042625000125],
              [14.243186950000165, 10.004038810000054],
              [14.252035140000032, 10.003811837],
              [14.262909889000014, 10.003676415000029],
              [14.267339707000076, 10.003925324000079],
              [14.300445556000057, 10.004157066000062],
              [14.352401733000022, 10.004421235000166],
              [14.380325317000143, 10.004953384999965],
              [14.380412103000026, 10.004954338000118],
              [14.394368172000043, 10.005219460000035],
              [14.419820785000013, 10.006302834000167],
              [14.458566665000092, 10.006608010000036],
              [14.461546897000119, 10.00653266900008],
              [14.466057776000071, 10.006619453000155],
              [14.466724395000028, 10.006472589000111],
              [14.472504616000037, 10.005192757000088],
              [14.47629261100019, 10.004240037000102],
              [14.47991848099997, 10.003367424000089],
              [14.489023208000049, 10.002423287000056],
              [14.494604111000058, 10.001325608000116],
              [14.499094962000072, 10.00044250600007],
              [14.514128684000184, 9.997288704000027],
              [14.529714584000146, 9.994018555000082],
              [14.535660743000165, 9.992743493000091],
              [14.541494369000191, 9.991492272000073],
              [14.54162216200001, 9.991464616000087],
              [14.54233169500003, 9.99131202600006],
              [14.557470322000029, 9.98806476600015],
              [14.562267304000045, 9.987035751000178],
              [14.575659752000092, 9.984316826],
              [14.615443229000107, 9.97624015700012],
              [14.634538651000184, 9.97235965800013],
              [14.662290574000167, 9.966719628000078],
              [14.662374495999984, 9.966702461000182],
              [14.676226617000111, 9.963885308000045],
              [14.676296235, 9.963871955000116],
              [14.691657065000186, 9.960747719000096],
              [14.708061219000115, 9.957052231000034],
              [14.708170891000179, 9.9570274350001],
              [14.738059997000164, 9.950291634000109],
              [14.738375664000046, 9.950221063000072],
              [14.761282920000042, 9.945055961000094],
              [14.779223441000056, 9.941011429000071],
              [14.802422523000189, 9.93567562100003],
              [14.804109574000051, 9.936203956000156],
              [14.804341316000034, 9.936276437000174],
              [14.828228950000153, 9.943750382000133],
              [14.828269004000106, 9.943763733000083],
              [14.838981629000159, 9.947114945000067],
              [14.897115708000058, 9.965300560000117],
              [14.897772789000157, 9.965505599000096],
              [14.918785096000136, 9.972126007000043],
              [14.951492309000116, 9.982430458000067],
              [14.953830718000063, 9.983167649000052],
              [14.956213952000098, 9.984015464000152],
              [14.956972123000014, 9.984285354000065],
              [14.959541321000188, 9.984649659000127],
              [14.961483002000136, 9.98492526900003],
              [15.000712395000164, 9.969674110000142],
              [15.004260063000061, 9.969204903000104],
              [15.004293442000062, 9.969201088000091],
              [15.004337310000153, 9.969195367000054],
              [15.028369903000055, 9.968954086000053],
              [15.036314963, 9.968873977000101],
              [15.061924933000171, 9.948745729],
              [15.080297469000072, 9.956178665000039],
              [15.089737892000073, 9.959998131000077],
              [15.089938163, 9.960079193000183],
              [15.099491119000106, 9.963943481000172],
              [15.10535717099998, 9.966317177000121],
              [15.106310845000053, 9.966702461000182],
              [15.106486320999977, 9.967025758000034],
              [15.106564522000156, 9.967169762000026],
              [15.108003617000122, 9.969816209000101],
              [15.109089851000135, 9.972745896000106],
              [15.109454155000037, 9.973729134000109],
              [15.109807014000012, 9.977559089000181],
              [15.109858512000017, 9.978120803000081],
              [15.109859204000088, 9.980767485000058],
              [15.109858999000153, 9.980916],
              [15.11123, 9.984031000000186],
              [15.111481850000075, 9.984455020999974],
              [15.112841999000068, 9.986745],
              [15.112907850000113, 9.98676474500013],
              [15.120653153000148, 9.989087104000021],
              [15.120737530000099, 9.989112399000078],
              [15.12107899900019, 9.989215000000115],
              [15.124022288999981, 9.990097386000116],
              [15.12647577000007, 9.990832932000046],
              [15.12693977400005, 9.990972520000071],
              [15.140393000000131, 9.994599],
              [15.14462, 9.995739],
              [15.148515169000063, 9.99678973000016],
              [15.148531, 9.996794000000136],
              [15.14855285200008, 9.996795310999971],
              [15.150730822000185, 9.996926089000169],
              [15.152459620000059, 9.997029895000026],
              [15.152478000000144, 9.997031],
              [15.177608000000191, 9.988793],
              [15.180023017000053, 9.988472003000027],
              [15.194231000000116, 9.991095],
              [15.209349, 9.993886],
              [15.212872597000057, 9.990078436000147],
              [15.214027009000176, 9.988831048000122],
              [15.21482181500005, 9.987972260000163],
              [15.229860600000109, 9.98969202800015],
              [15.231609001000095, 9.989892001000101],
              [15.231638001000022, 9.98989500100015],
              [15.235810299000093, 9.990371794000055],
              [15.240520000000117, 9.99091],
              [15.242509, 9.991049000000146],
              [15.24258174199997, 9.991054104000057],
              [15.242679596000073, 9.991061212000091],
              [15.243023872000151, 9.991085053],
              [15.243966418000184, 9.991151080000066],
              [15.248415, 9.991463001000057],
              [15.250791455000069, 9.990868775000081],
              [15.253762000000108, 9.990126],
              [15.263621000000114, 9.98766],
              [15.264346929000055, 9.987478308000107],
              [15.264488220000146, 9.987442970000018],
              [15.265683056000057, 9.987144069000067],
              [15.269837, 9.98610500000018],
              [15.303976497000065, 9.977564704000031],
              [15.304467, 9.977442000000167],
              [15.304518377000136, 9.977428731000032],
              [15.318743000000154, 9.973755],
              [15.345370262000074, 9.966877098000168],
              [15.345390001000055, 9.966872],
              [15.346087, 9.966692000000137],
              [15.353761502000168, 9.964709317000143],
              [15.361053, 9.96282558300004],
              [15.36106301000018, 9.962822996999989],
              [15.361106873000153, 9.96281147000002],
              [15.3623796010001, 9.962482280000131],
              [15.364056588000039, 9.962049108000031],
              [15.369114, 9.959266000000127],
              [15.37866, 9.954013],
              [15.385575, 9.950208001000021],
              [15.385628000000168, 9.950179],
              [15.385699944000066, 9.950139154000112],
              [15.38582325100009, 9.950071336000178],
              [15.386082650000162, 9.949928284000066],
              [15.399168014000111, 9.942726135000044],
              [15.41331958700016, 9.934937477000062],
              [15.420114967000188, 9.936083324000094],
              [15.423096386000054, 9.936586177000038],
              [15.424784, 9.93687100000011],
              [15.465228081000021, 9.943689347000145],
              [15.505593300000044, 9.95048904500004],
              [15.527767182000105, 9.9546756740001],
              [15.529255867000074, 9.955812455000114],
              [15.594977379000113, 9.971782684000175],
              [15.652609825000013, 9.985246659000154],
              [15.655916214000115, 9.987316131000114],
              [15.681156158000022, 9.993293762000064],
              [15.68734550500011, 9.994759560000034],
              [15.68952465000018, 9.996432304000052],
              [15.687675476000152, 9.99819278800004],
              [15.678389550000077, 10.003620148000039],
              [15.677940369000112, 10.003882408000095],
              [15.676657677000037, 10.006999016],
              [15.674538612000163, 10.010029793000058],
              [15.673525810000172, 10.011477471000148],
              [15.671032906000164, 10.013558388000092],
              [15.665479660000017, 10.015965461],
              [15.66177844900011, 10.017889024000112],
              [15.659893989000182, 10.02110767400012],
              [15.656451225000069, 10.023593903000062],
              [15.652139663000014, 10.025986671000169],
              [15.643486022000161, 10.030788421000068],
              [15.63652801600017, 10.034747124000148],
              [15.635451316000058, 10.035359383000127],
              [15.629981994000161, 10.038853646000121],
              [15.625214576000076, 10.042437554000173],
              [15.623637200000076, 10.04362297],
              [15.618505479000135, 10.046849251000083],
              [15.613843917999986, 10.048465729000043],
              [15.611545563000163, 10.048537255000099],
              [15.607352257000116, 10.047539711000184],
              [15.601332664000097, 10.046143533000134],
              [15.601015092000068, 10.046173097000064],
              [15.599845887000072, 10.046279907000041],
              [15.598090172000184, 10.047422409999967],
              [15.59697914100019, 10.049347878000106],
              [15.596509933000107, 10.05016136100005],
              [15.596000672000173, 10.051044465000075],
              [15.595652581000024, 10.052000045000113],
              [15.594924927000022, 10.053994178000153],
              [15.594796181000049, 10.057679176000079],
              [15.594692231000181, 10.058010100999979],
              [15.594057083, 10.060026169000025],
              [15.591155052000147, 10.062853813],
              [15.590682030000096, 10.063315392000106],
              [15.585956574000079, 10.066740990000028],
              [15.583254815000089, 10.06815242700003],
              [15.580010415000061, 10.069096566000098],
              [15.578025816999968, 10.069251061000159],
              [15.577374459000112, 10.069301605000078],
              [15.574735641000075, 10.068033219000085],
              [15.572165490000145, 10.067300797000087],
              [15.567703246000065, 10.067241669000168],
              [15.564863205000165, 10.067707061000135],
              [15.563985824000156, 10.067851067000163],
              [15.562027930000113, 10.069596292000028],
              [15.561631202000171, 10.070431709000161],
              [15.559737205000033, 10.074423790000139],
              [15.558594704000086, 10.078446388999964],
              [15.558006287000183, 10.079398156000138],
              [15.556977271000108, 10.081062317000089],
              [15.552517892000026, 10.082945825000138],
              [15.551187514999981, 10.083758355000043],
              [15.548667907000038, 10.085296630000016],
              [15.546991348000176, 10.087095262000162],
              [15.545226098000114, 10.088988305000044],
              [15.542527199, 10.09227562000018],
              [15.538950921000037, 10.096502305],
              [15.536723137000138, 10.098784447000014],
              [15.532804488000181, 10.100667000000101],
              [15.530472756000165, 10.101148605000049],
              [15.528883934000135, 10.101476670000125],
              [15.525773049000065, 10.101213455999982],
              [15.523820877000162, 10.100117684000111],
              [15.522321702, 10.099276543000087],
              [15.521359444000154, 10.098393440999985],
              [15.519477844000107, 10.096667290000084],
              [15.51697254100003, 10.09446048699999],
              [15.51473903700014, 10.093257903000165],
              [15.512508392000029, 10.093596459000139],
              [15.510821342999975, 10.095609666000144],
              [15.510218620000103, 10.099362374000123],
              [15.510024071000089, 10.104588509000109],
              [15.509814263000123, 10.105410575000064],
              [15.509083747000091, 10.108275414000047],
              [15.507820130000084, 10.109806061000143],
              [15.506923675000053, 10.110891342000173],
              [15.505327225000144, 10.111837386000104],
              [15.503748894000012, 10.112772942000049],
              [15.502038956000149, 10.114647865],
              [15.501791, 10.114919663000137],
              [15.501392364000083, 10.115528107000046],
              [15.499530792000087, 10.118372917000045],
              [15.499464989000046, 10.11847305200007],
              [15.498850822000122, 10.119410516000187],
              [15.496259688000123, 10.123370171000147],
              [15.495980263000035, 10.123542785000041],
              [15.49484062200014, 10.124243737000143],
              [15.491931915000066, 10.123511315000087],
              [15.489354134000052, 10.12327766500016],
              [15.489360810000051, 10.123203279],
              [15.489323616000036, 10.123274801999969],
              [15.489109038000038, 10.123254776000124],
              [15.489023209000095, 10.123247145999983],
              [15.487690926000084, 10.123579979000112],
              [15.48713111800015, 10.123719216000154],
              [15.485240937000185, 10.125799180000115],
              [15.484360696000067, 10.128636361000133],
              [15.484096528000066, 10.129486083000074],
              [15.483900069000072, 10.130702973000041],
              [15.483761788000095, 10.131563188000086],
              [15.484167099000103, 10.134293556000046],
              [15.4843082430001, 10.135248183000101],
              [15.484244347000129, 10.137593270000025],
              [15.483100892000095, 10.139736176000099],
              [15.482490540000072, 10.140878676999989],
              [15.480601310000054, 10.143561363000117],
              [15.479047774000151, 10.144836425000108],
              [15.475012780000043, 10.146620751000114],
              [15.474790572000131, 10.146718977999967],
              [15.470871926000086, 10.149538992999965],
              [15.467561721000038, 10.152022362000025],
              [15.464387895000129, 10.155242920000035],
              [15.461215973000094, 10.159468651],
              [15.45831585000019, 10.165435790000174],
              [15.456430435000073, 10.17126846400015],
              [15.454815864000125, 10.17676448900005],
              [15.45472908000005, 10.17733574000016],
              [15.454620362000128, 10.178053856000076],
              [15.454145432000075, 10.181187631000057],
              [15.452595710000139, 10.185544968000102],
              [15.45036983500006, 10.189702035000039],
              [15.448143959000106, 10.192554474000076],
              [15.445239067000102, 10.196275711000055],
              [15.443570137000052, 10.197459221000088],
              [15.440981865000083, 10.199295997000036],
              [15.437535286000127, 10.201377869000169],
              [15.4349765770001, 10.202724456999988],
              [15.432939529000123, 10.203796388000171],
              [15.432029724000074, 10.20395279000013],
              [15.430977822000045, 10.204133988000024],
              [15.429833413000154, 10.204558373000054],
              [15.429274559000078, 10.204765321000139],
              [15.427530288000071, 10.205411911000169],
              [15.425436974000093, 10.207826614],
              [15.425375939000105, 10.208241463000149],
              [15.425016403000029, 10.210674284999982],
              [15.42483329900017, 10.211914063000052],
              [15.423618317000148, 10.213926315000151],
              [15.4224290840001, 10.214434623999978],
              [15.422111511000026, 10.214571],
              [15.418750764000038, 10.21600914000004],
              [15.415100098000153, 10.21748828900013],
              [15.41165351900014, 10.219703675000119],
              [15.409627914000168, 10.222185135000075],
              [15.408682822000117, 10.224129678000168],
              [15.40936279400006, 10.226808549],
              [15.409433365000154, 10.229354858000136],
              [15.408132554000076, 10.232393265000042],
              [15.406605720000016, 10.235988617000032],
              [15.405527114000108, 10.238649369000086],
              [15.404597283000044, 10.240942001000064],
              [15.404347419000146, 10.24135875800016],
              [15.403227806000132, 10.243231773000161],
              [15.400774002000162, 10.245188713000061],
              [15.398768425000014, 10.246788980000019],
              [15.396518707000041, 10.247964859999968],
              [15.396199227000068, 10.248131753000052],
              [15.394643784000095, 10.248669625000105],
              [15.392756463000126, 10.249341965000156],
              [15.388727189000178, 10.250544548000107],
              [15.388494491000131, 10.250701904000152],
              [15.388453484000024, 10.250729562000117],
              [15.386713982000174, 10.251904487000104],
              [15.385111810000126, 10.253572465000047],
              [15.384549140999979, 10.255208015000051],
              [15.384335518000171, 10.255829811000126],
              [15.384235382000043, 10.256119727000168],
              [15.380720139000061, 10.258603096],
              [15.380326271000172, 10.25905323000012],
              [15.377612114000044, 10.262157440000124],
              [15.375741006000112, 10.264147758000149],
              [15.375720977000185, 10.264169693999975],
              [15.375384331000191, 10.26441574099999],
              [15.373422622000135, 10.265848160000132],
              [15.371327399000108, 10.267659188000039],
              [15.369367600000032, 10.269016266000051],
              [15.369096757000079, 10.269203184999981],
              [15.368963241000074, 10.269467355000188],
              [15.368677140000102, 10.27003574400004],
              [15.36774635400019, 10.271883966000189],
              [15.367209434000131, 10.275503158000163],
              [15.366873740000074, 10.277513505000115],
              [15.365454674000091, 10.279324532000146],
              [15.36525249400006, 10.27948761000016],
              [15.365166665000174, 10.279556275000061],
              [15.364809991000129, 10.279843331000166],
              [15.364035607000176, 10.280465126000024],
              [15.362393380000128, 10.280847551000136],
              [15.36207771300019, 10.280920982000055],
              [15.360305785000151, 10.282120705000125],
              [15.359016419000056, 10.283240318000139],
              [15.358426094000151, 10.285898210000141],
              [15.358490945000085, 10.286869047999971],
              [15.358564376, 10.287975312000071],
              [15.358057975000122, 10.290667534000022],
              [15.357028961000083, 10.291821480000124],
              [15.356204986000023, 10.292746544000124],
              [15.35249805400008, 10.293948175000139],
              [15.350950242000124, 10.294528007000167],
              [15.348226547000081, 10.295549392000055],
              [15.345574380000073, 10.295944213000098],
              [15.344437599000116, 10.296113014000127],
              [15.3402471550001, 10.298353196000051],
              [15.339944839000168, 10.299254417999975],
              [15.339363098999968, 10.30098915200017],
              [15.335978508000153, 10.302669526000102],
              [15.331866264999974, 10.302913665000176],
              [15.33035850400006, 10.303849221000121],
              [15.329287529000169, 10.304513932000134],
              [15.327273369000125, 10.305793762000178],
              [15.325650216000042, 10.305326463000142],
              [15.32137107799997, 10.30536842500004],
              [15.320572853000101, 10.305339814000092],
              [15.317784310000036, 10.305237771000066],
              [15.314216612999985, 10.305450439000083],
              [15.312205314000096, 10.306989671000053],
              [15.312192918000164, 10.307045936],
              [15.311986923000063, 10.307998658000145],
              [15.311881066000126, 10.309731484000167],
              [15.311786652000137, 10.311281204000124],
              [15.312071800000069, 10.312515260000112],
              [15.312466621000169, 10.314229012000169],
              [15.312187195000149, 10.31675148100004],
              [15.312126160000162, 10.317307472000152],
              [15.312147141000025, 10.320117951000043],
              [15.311963082000148, 10.321610451000083],
              [15.311771393000186, 10.323169709000126],
              [15.311509133000186, 10.323930740000151],
              [15.311124801000176, 10.325044632000129],
              [15.31060028100012, 10.325772286000131],
              [15.310314178000169, 10.326169967000055],
              [15.30853176100004, 10.327616692000106],
              [15.306501389000061, 10.329264642000055],
              [15.30615425000002, 10.329546928000127],
              [15.304100991000041, 10.330726623000146],
              [15.302424430000144, 10.331263543000148],
              [15.300436974000036, 10.332250595000119],
              [15.300035476, 10.332449914000165],
              [15.299289703000056, 10.33281993900016],
              [15.297143936000055, 10.334564208000131],
              [15.295993804000091, 10.335499764000076],
              [15.295076371000107, 10.337321282000175],
              [15.294755936000172, 10.341765403000068],
              [15.294650078000132, 10.343853951000028],
              [15.296977997000113, 10.346689224000102],
              [15.29724693300011, 10.347388269000078],
              [15.298007966000114, 10.349366189000136],
              [15.296551705000184, 10.35343647000002],
              [15.294824599000094, 10.357186318000174],
              [15.293449402000078, 10.358701706000147],
              [15.292393685000036, 10.359865189],
              [15.291602135000062, 10.360752105000017],
              [15.29071903200014, 10.361741066000093],
              [15.290454864000083, 10.361903191000067],
              [15.290237427000136, 10.362036705000094],
              [15.287746430000027, 10.363564492000137],
              [15.286230087000035, 10.36454773000014],
              [15.285398483000165, 10.365086556000108],
              [15.284993171, 10.365332605000162],
              [15.283947945000023, 10.365966797000056],
              [15.283195496000076, 10.366936685000155],
              [15.283142088999966, 10.367005348000077],
              [15.28330516900013, 10.368921280000166],
              [15.283142088999966, 10.369449615000065],
              [15.282662392000191, 10.370998383000142],
              [15.282958983000128, 10.372487068000112],
              [15.28304958300015, 10.372939111000164],
              [15.28391647300009, 10.374705313999982],
              [15.283988952000129, 10.375436783000055],
              [15.284080506000066, 10.376364709000086],
              [15.283811568000033, 10.378025055000137],
              [15.283659936000106, 10.378903388000083],
              [15.283488273000046, 10.3798990250001],
              [15.283597946000157, 10.381398200000092],
              [15.283878326000035, 10.382826805000036],
              [15.283924103, 10.383057595000139],
              [15.284250259000032, 10.384449958000175],
              [15.283819198000117, 10.386323929000071],
              [15.283171653000124, 10.388091088000181],
              [15.283171653000124, 10.388677596000036],
              [15.283172608000086, 10.389911651999967],
              [15.283716201000061, 10.391038894000189],
              [15.284256934000098, 10.392159462000166],
              [15.285015107000049, 10.393497467000088],
              [15.285612107000077, 10.395639420000123],
              [15.285509110000191, 10.39714050300006],
              [15.285504340000102, 10.397197723000033],
              [15.285074234999968, 10.39895916],
              [15.284967422000136, 10.399522782000133],
              [15.284961700000167, 10.399550438000063],
              [15.284890174000111, 10.399924278000128],
              [15.284776688000136, 10.400524139000083],
              [15.284771918000104, 10.400551795000069],
              [15.284697532000166, 10.400940896],
              [15.283834457000069, 10.403244019000056],
              [15.283606529000053, 10.404006957000149],
              [15.283402444000103, 10.404689789000145],
              [15.283404351, 10.406885147000082],
              [15.283155442000123, 10.40809536099999],
              [15.282974244000059, 10.408973695000043],
              [15.281537056000161, 10.410890580000114],
              [15.281407356000045, 10.411063195000111],
              [15.281124115000182, 10.411236763000034],
              [15.279569627000171, 10.412189483000191],
              [15.278102875000116, 10.413645744000178],
              [15.275925636000181, 10.414606094000021],
              [15.275215148000029, 10.415122985999972],
              [15.273507118000111, 10.416364671000167],
              [15.27157306700002, 10.418043137000097],
              [15.269881248000104, 10.419960974999981],
              [15.269792556000084, 10.420048714000075],
              [15.269495010000128, 10.420344353000075],
              [15.268694877000087, 10.421137810000118],
              [15.26794624200005, 10.4218797690001],
              [15.26560974, 10.424516678000089],
              [15.263619421999977, 10.426403046000189],
              [15.260128976000033, 10.429712296000105],
              [15.25667095099999, 10.433141710000143],
              [15.25642109, 10.433388711000077],
              [15.256052017000115, 10.433662414000082],
              [15.254163741000013, 10.435067177000064],
              [15.253446580000116, 10.436135292000017],
              [15.252875328000073, 10.436985015000118],
              [15.25230693900005, 10.438223838],
              [15.252262115000065, 10.438322068000161],
              [15.251667022000106, 10.439620971000068],
              [15.251189232000058, 10.441044808000186],
              [15.250701904000039, 10.442496300000187],
              [15.249414444000081, 10.44633102500012],
              [15.248456001000079, 10.449500084000078],
              [15.24820709200003, 10.450324060000014],
              [15.247643471, 10.452161789000115],
              [15.247001648000094, 10.456315041000039],
              [15.246864319000053, 10.45768261000012],
              [15.246521951000148, 10.461106300000097],
              [15.246524811000029, 10.464619637000055],
              [15.244510651000155, 10.468375206000019],
              [15.241271972000163, 10.47235107500012],
              [15.240075111000181, 10.473734857000011],
              [15.239647866000041, 10.474227905000134],
              [15.238183975000027, 10.475177765000012],
              [15.237336158000119, 10.475727081],
              [15.235474586000066, 10.476872444000037],
              [15.234997748000069, 10.477166175000093],
              [15.23201465600016, 10.479563713],
              [15.228466987000161, 10.483320236000054],
              [15.225564956000142, 10.485877038000069],
              [15.222662925000066, 10.488834382000164],
              [15.219598769000072, 10.491151810000019],
              [15.216372490000026, 10.492831230000093],
              [15.213227273000086, 10.494510651000041],
              [15.210658073000161, 10.49657154200014],
              [15.210424424000109, 10.496759416000032],
              [15.209839822000049, 10.49722766800005],
              [15.20814705000015, 10.498906136000187],
              [15.206292153000049, 10.500185014000124],
              [15.203872680000131, 10.501304626000035],
              [15.201372147000086, 10.502264024],
              [15.196531296000046, 10.503066064000109],
              [15.192336082000054, 10.503626823000047],
              [15.187415123000108, 10.504828454000119],
              [15.184592246000022, 10.506426811000097],
              [15.182737350000025, 10.507625580000081],
              [15.17983341300004, 10.509384155000021],
              [15.177252770000109, 10.511062621000178],
              [15.174349784000071, 10.513380050000137],
              [15.172598839000045, 10.515051842999981],
              [15.172172547000116, 10.515458107000029],
              [15.168785094999976, 10.518095016000188],
              [15.165842055000041, 10.520112992000179],
              [15.164590836000116, 10.52097129800012],
              [15.162123681000025, 10.522641180999983],
              [15.161283494000031, 10.523209573000145],
              [15.158057213000177, 10.524968148000085],
              [15.15651226000017, 10.526202203000139],
              [15.155556678000153, 10.526965141],
              [15.152249336000068, 10.529682159000117],
              [15.15007209800001, 10.531280518000131],
              [15.147974968000085, 10.53391647400008],
              [15.146604539000066, 10.536393165999982],
              [15.146121026000117, 10.538069723999968],
              [15.14563846599998, 10.541743279000116],
              [15.145768165000163, 10.543275833000052],
              [15.146044730000028, 10.546534538000117],
              [15.146044730000028, 10.54711914100011],
              [15.14604663800003, 10.550766944000031],
              [15.146015166000097, 10.551438333000078],
              [15.145977974, 10.552232743000047],
              [15.145870209000066, 10.554554939000184],
              [15.145727158000057, 10.557635308000044],
              [15.14521789600019, 10.562698365000017],
              [15.145164489000138, 10.563225746000057],
              [15.145115852000117, 10.563668250999967],
              [15.14476299200004, 10.566899300000046],
              [15.144577981000111, 10.568233490000125],
              [15.144199371000013, 10.570971489000101],
              [15.144201279000185, 10.573645591000059],
              [15.144201279000185, 10.57400608100005],
              [15.14471817000009, 10.576745034000055],
              [15.144952774000103, 10.577984810000146],
              [15.14500045800014, 10.578249932000062],
              [15.14518737700007, 10.579285623000033],
              [15.145738601, 10.582550049000133],
              [15.145806314000026, 10.582961083000043],
              [15.146709441000041, 10.588459015000012],
              [15.147089959000084, 10.590224266000178],
              [15.147519112000168, 10.592211724000094],
              [15.149134635000166, 10.595884323000121],
              [15.152527809000105, 10.602990151000085],
              [15.155032158000097, 10.607780456000057],
              [15.155842781000104, 10.60956001400001],
              [15.157214166000131, 10.612570763000178],
              [15.157719612000051, 10.616377831000079],
              [15.158104897000044, 10.619277954000097],
              [15.158350944000063, 10.627023697000027],
              [15.158191681000119, 10.631176949000121],
              [15.157791138000107, 10.637085914000068],
              [15.157954216000121, 10.64020061500014],
              [15.157511712000087, 10.642310144000135],
              [15.156746864000013, 10.645950317000143],
              [15.154568672000096, 10.648427009999978],
              [15.151583672000015, 10.651782990000186],
              [15.149700163999967, 10.654043198000181],
              [15.148921012000187, 10.65497780000004],
              [15.145693779000055, 10.658653260000051],
              [15.144403458000113, 10.660889627000074],
              [15.143435478000185, 10.663206100000139],
              [15.142226219, 10.665522576000171],
              [15.141339302000119, 10.667757989000108],
              [15.140615463000131, 10.674067497000067],
              [15.139407159000029, 10.680216790000031],
              [15.13894653300008, 10.682822227000031],
              [15.138602256000127, 10.68476867600009],
              [15.138232231000131, 10.686453820000054],
              [15.137796401000116, 10.688442230000078],
              [15.137748719000115, 10.689043046000052],
              [15.13755607700017, 10.691476823000073],
              [15.13860702400018, 10.695629120000035],
              [15.138632775000076, 10.695960998000089],
              [15.138850213000126, 10.698743821000051],
              [15.138448714000049, 10.702816009],
              [15.136189461000072, 10.706171036000057],
              [15.13312721200009, 10.707976341000119],
              [15.132800102000033, 10.7081689850001],
              [15.130926132000013, 10.709202766000089],
              [15.127876281, 10.710886002000109],
              [15.126092910000182, 10.712087631000145],
              [15.125858307000044, 10.712244988000066],
              [15.124243736000096, 10.714161872999966],
              [15.12263012, 10.715919495000094],
              [15.119320870000138, 10.720232963000115],
              [15.117061614000079, 10.723986626000112],
              [15.114156723000121, 10.727741242000093],
              [15.111816406, 10.730776786999968],
              [15.10770034799998, 10.735170365000045],
              [15.10422897300009, 10.738365173000091],
              [15.102615357000161, 10.740601540000114],
              [15.102087020000056, 10.741941452000106],
              [15.101566315000071, 10.743262292000168],
              [15.10132408200019, 10.743876457999988],
              [15.100032806000115, 10.746512414000165],
              [15.099549294000042, 10.749547004000078],
              [15.099351882000065, 10.751050949000046],
              [15.099066734000075, 10.753220558000066],
              [15.097856522000143, 10.756654739000112],
              [15.095758438000075, 10.760807990999979],
              [15.093888283000183, 10.764844895000067],
              [15.09309482599997, 10.766557693000038],
              [15.092232704000082, 10.7681722640001],
              [15.090835571000184, 10.770791054000085],
              [15.09057045000003, 10.771211624000046],
              [15.090527533999989, 10.771279335000088],
              [15.089044571000159, 10.77363491],
              [15.087364198000103, 10.776302338000107],
              [15.08720111800011, 10.776534081000023],
              [15.086328507000133, 10.777770044000022],
              [15.086295128000131, 10.777818681000042],
              [15.084942817000069, 10.779736519000096],
              [15.082763672000112, 10.783170700000142],
              [15.080880164000064, 10.785965920000137],
              [15.080502511000191, 10.786525727000026],
              [15.078770638000151, 10.792475700000182],
              [15.077759743000115, 10.795948983000017],
              [15.077467919000128, 10.796857833000104],
              [15.076737405000074, 10.799132347000182],
              [15.075890540000046, 10.801759720000064],
              [15.075406075000046, 10.803264618000071],
              [15.074485779000099, 10.8060388560001],
              [15.073885918000087, 10.807847976],
              [15.07154464700011, 10.81104278600003],
              [15.069767953, 10.813439370000083],
              [15.069375992000175, 10.815575600000102],
              [15.069284438000068, 10.81607437100007],
              [15.069972993000079, 10.817006112000172],
              [15.072354317000134, 10.820226669000078],
              [15.072769164000078, 10.82252693099997],
              [15.073406219000105, 10.826055527000108],
              [15.073730469000111, 10.832203865000054],
              [15.074219704000029, 10.834551812000086],
              [15.074862480000093, 10.837634087000083],
              [15.075901986000133, 10.839621545000057],
              [15.077367782000067, 10.842424393000158],
              [15.078499795000027, 10.845858573999976],
              [15.080842972000028, 10.849690437000049],
              [15.081562996000116, 10.850443839000036],
              [15.083589552999968, 10.852564811000036],
              [15.087817192000102, 10.855581285000028],
              [15.087952613000027, 10.855677604999983],
              [15.091588973000171, 10.858311653000158],
              [15.092671394000092, 10.859661103000064],
              [15.093446732000132, 10.860627174000172],
              [15.096033096000099, 10.864060402],
              [15.096070290000171, 10.864394188000119],
              [15.09643840800004, 10.867653847999975],
              [15.096834184000102, 10.870776177000153],
              [15.097004890000051, 10.872124673000144],
              [15.097167969000111, 10.876996041000154],
              [15.096748352000134, 10.879458429000124],
              [15.09620094200011, 10.882666589000053],
              [15.093941688000086, 10.89073181200007],
              [15.092588425000088, 10.893342019000102],
              [15.092326165000088, 10.893847466000125],
              [15.092052460000048, 10.894539833000124],
              [15.090873717000136, 10.897521020000113],
              [15.089824676000148, 10.901514053000028],
              [15.089648247000184, 10.903327942000089],
              [15.089421272000152, 10.905666352000139],
              [15.089261054000019, 10.908940315],
              [15.088374138000177, 10.915568351000047],
              [15.087890625000057, 10.919402123],
              [15.087787628000171, 10.91981125],
              [15.087002753000149, 10.922915458000034],
              [15.086841583000137, 10.92435264500017],
              [15.086661340000035, 10.926321983000037],
              [15.086600304000171, 10.926988601000119],
              [15.086440087000142, 10.930821418000107],
              [15.086069107000185, 10.933621407000032],
              [15.085794449000161, 10.93569278699999],
              [15.085311889000025, 10.942560196000045],
              [15.084989549, 10.945834159000128],
              [15.083778382000048, 10.949987411000052],
              [15.07998180400017, 10.953901292000126],
              [15.077215195000178, 10.95605945599999],
              [15.07533073400009, 10.957530022000185],
              [15.074378967000143, 10.95829773000014],
              [15.072754860000146, 10.95960712499999],
              [15.071434021000186, 10.960536958000091],
              [15.069398881000041, 10.961969375000081],
              [15.067055701000129, 10.963886260000152],
              [15.065682412000115, 10.965244294000058],
              [15.065116883000087, 10.967000961000167],
              [15.064616204000174, 10.968239784000104],
              [15.064310075000151, 10.968997002000037],
              [15.06437015500012, 10.971672058000024],
              [15.06439113600004, 10.972590446000027],
              [15.065280915000187, 10.976822854000091],
              [15.064877509000041, 10.980096817000174],
              [15.062777518000075, 10.984009743000058],
              [15.059787750000169, 10.987364770000113],
              [15.059500694, 10.987587928000096],
              [15.059432029000106, 10.987641336000081],
              [15.059189797000158, 10.987829209000097],
              [15.057525635000104, 10.989122390000091],
              [15.054779053000061, 10.991278649000037],
              [15.052758216000086, 10.992636680000032],
              [15.050496102000182, 10.9943132410001],
              [15.04839515700013, 10.99718856800007],
              [15.048202515000014, 10.99771881099997],
              [15.048393249000185, 10.99778652100008],
              [15.048188209000102, 10.997756957000149],
              [15.047668457000043, 10.999185562000093],
              [15.04863834300005, 11.001101494000181],
              [15.051064492000137, 11.002265931000181],
              [15.051466941000115, 11.002458573000126],
              [15.055266381000081, 11.005332947000113],
              [15.054819108000117, 11.007670402000144],
              [15.05470085200011, 11.008287431000099],
              [15.054201126000123, 11.008766175000119],
              [15.052286149000054, 11.01060008900015],
              [15.0501251230001, 11.011248589000161],
              [15.049610138000048, 11.011403084000051],
              [15.048155785000063, 11.012600898000073],
              [15.047347068000022, 11.013559342000121],
              [15.047670365000045, 11.014916421000066],
              [15.047994613000071, 11.016912460000128],
              [15.048561097, 11.019468307000125],
              [15.05066204000002, 11.021144868000022],
              [15.054945946000089, 11.023220063],
              [15.05777549600009, 11.024337768000066],
              [15.058752060000074, 11.024378777000152],
              [15.06157398199997, 11.02449607799997],
              [15.06367778800012, 11.024335860000065],
              [15.065776826000103, 11.024176598000054],
              [15.06884765500007, 11.023696900000118],
              [15.070948601000168, 11.023456574000136],
              [15.074181557000145, 11.023775101000126],
              [15.075114251000059, 11.024885178000034],
              [15.076930999000069, 11.027048112000102],
              [15.077818870000101, 11.027750015000038],
              [15.078547477000086, 11.028326035000077],
              [15.080538750000073, 11.02954578399999],
              [15.080891609000048, 11.029762269000059],
              [15.084044457000175, 11.031278611000118],
              [15.083883285000127, 11.033354760000066],
              [15.083156586000143, 11.035430908000023],
              [15.083385469000177, 11.036879540000029],
              [15.083560944, 11.03798675500002],
              [15.083399772000178, 11.039663316000144],
              [15.082865715000139, 11.041380882000112],
              [15.082754134000083, 11.041739463000056],
              [15.083805084000176, 11.042617797000048],
              [15.085790634000148, 11.042652131000068],
              [15.088412284000128, 11.042696953000018],
              [15.092696190000026, 11.043494225000074],
              [15.0949020380001, 11.043422700000121],
              [15.095201492, 11.043413162000036],
              [15.096489906000045, 11.044296265000014],
              [15.098112106000144, 11.045408250000094],
              [15.097669601000064, 11.046920776000036],
              [15.097224235000169, 11.048443795000026],
              [15.097335815000122, 11.049619675000145],
              [15.097467423000182, 11.050998687000174],
              [15.097580909000158, 11.051560401000131],
              [15.098114967000129, 11.054192544000045],
              [15.097901344000149, 11.055039406000105],
              [15.097550392000016, 11.05642890900009],
              [15.097016334999978, 11.057517052000151],
              [15.096257210000147, 11.059063912000056],
              [15.096343041000068, 11.060035706000178],
              [15.096420287000058, 11.06090068900005],
              [15.098845481000183, 11.062336921000053],
              [15.1024837490001, 11.065290452000056],
              [15.102702141000066, 11.065809251000132],
              [15.103858948000095, 11.068564414999969],
              [15.103940964000174, 11.071438790000059],
              [15.103733062000174, 11.07194232900008],
              [15.103214263000154, 11.073196412000186],
              [15.103214263000154, 11.073641778000081],
              [15.103214263000154, 11.074953080000057],
              [15.104249954000124, 11.076275826000028],
              [15.104589461999979, 11.076708794000183],
              [15.10628795700012, 11.078065873000128],
              [15.107731820000083, 11.079031945000111],
              [15.108794212000078, 11.079742431999989],
              [15.109280587000114, 11.082456589000117],
              [15.109684944000037, 11.083893776000139],
              [15.111862182999971, 11.084560395000096],
              [15.112029076000056, 11.084611892],
              [15.111303329000123, 11.088685036000072],
              [15.111128806000181, 11.089036942000064],
              [15.109284400000149, 11.092758178999986],
              [15.108146667000085, 11.093463897000163],
              [15.107485771000086, 11.093873979000136],
              [15.106129647000159, 11.094343186000174],
              [15.103687287000014, 11.095617295000125],
              [15.101653100000021, 11.097494126000072],
              [15.100900650000142, 11.098063470000113],
              [15.100500106000027, 11.098365784000066],
              [15.100347519000081, 11.100502967000068],
              [15.100233078000144, 11.102104187000066],
              [15.09958744, 11.104100228000107],
              [15.099426269999981, 11.10443401399999],
              [15.098698616000036, 11.105937959000187],
              [15.098557472000039, 11.106910705000189],
              [15.098375321000162, 11.108173371000021],
              [15.098299027000053, 11.109013558000186],
              [15.098215104000133, 11.109930039000062],
              [15.099993705000145, 11.111526489000028],
              [15.099751472999969, 11.113284111000155],
              [15.099469185000089, 11.114439965000031],
              [15.099186896999981, 11.115599632000169],
              [15.09925556200011, 11.116878509000173],
              [15.09926796000002, 11.117116928000087],
              [15.099430083000072, 11.118793488999984],
              [15.099121094000168, 11.119710922000138],
              [15.098784448000174, 11.120710374000055],
              [15.098784448000174, 11.121677399000021],
              [15.098784448000174, 11.12246704099999],
              [15.098988533000124, 11.123229982000169],
              [15.099189759000069, 11.123984337000138],
              [15.099229813000022, 11.125059127999975],
              [15.099270821, 11.126140595000038],
              [15.099517822000109, 11.127161026000181],
              [15.09967613200007, 11.127817154000127],
              [15.099319458000025, 11.128814698000042],
              [15.098706244000084, 11.130532264000067],
              [15.098706244000084, 11.130754472000149],
              [15.098707199000103, 11.131708146000165],
              [15.098707199000103, 11.131969452000135],
              [15.099031449000108, 11.133486748000053],
              [15.099193572000104, 11.13540363200002],
              [15.09926891300006, 11.135775567000167],
              [15.099516869000126, 11.137000083999965],
              [15.099841117000153, 11.139236450000112],
              [15.100233078000144, 11.141113282000106],
              [15.100408554000069, 11.141950607000069],
              [15.100239753999972, 11.142894746000081],
              [15.099924087999966, 11.144665719000159],
              [15.100248337000096, 11.147061348000022],
              [15.100266457000146, 11.147149085000137],
              [15.100734712000076, 11.14937687000014],
              [15.100169183000105, 11.151613237000163],
              [15.100001335000059, 11.151875497000049],
              [15.099199295000119, 11.153130531000102],
              [15.09854221400002, 11.154255868000064],
              [15.097987175000014, 11.155207635000011],
              [15.096856117000073, 11.157683374000158],
              [15.096649170000092, 11.158843041000068],
              [15.096613884000021, 11.15904045200017],
              [15.096618652000018, 11.159152029999973],
              [15.09669494700006, 11.160877228000118],
              [15.096799850000139, 11.164159774999973],
              [15.096858978000057, 11.165987968000024],
              [15.095000268000092, 11.168623924000144],
              [15.093317032000073, 11.169091226000091],
              [15.092411994000145, 11.169342995000022],
              [15.09184074500007, 11.169448853000119],
              [15.09111881299998, 11.169583321000061],
              [15.089420319000169, 11.169743539000137],
              [15.087803841000152, 11.170462608000037],
              [15.086988450000092, 11.171133042000122],
              [15.086348533000034, 11.171660424000038],
              [15.085727691000159, 11.172529222000151],
              [15.085378647000027, 11.173018455000033],
              [15.084489823000069, 11.174455642000055],
              [15.084442138000099, 11.174523353000041],
              [15.083196640000097, 11.176293373000135],
              [15.082226754000146, 11.178369523000129],
              [15.079397202000109, 11.180605888000173],
              [15.078235627000026, 11.181868554000175],
              [15.077779771000166, 11.182363510000073],
              [15.077570916000184, 11.182755471000121],
              [15.076972008000041, 11.183880806000047],
              [15.076244354000039, 11.185717583000041],
              [15.07511329600004, 11.188352586],
              [15.074230195000098, 11.190501214000165],
              [15.074062347000051, 11.190908433],
              [15.073944091000044, 11.191089629000089],
              [15.073173523000037, 11.192266464000056],
              [15.07285022800005, 11.194262505000097],
              [15.071718217000068, 11.196418762000064],
              [15.070841789000042, 11.197376251000151],
              [15.070182800000111, 11.198096275000012],
              [15.069859505000124, 11.199533462000034],
              [15.069617271000141, 11.20184993800018],
              [15.068728447000126, 11.203686714000128],
              [15.067677498000137, 11.205043793000016],
              [15.067413330000136, 11.20560169200013],
              [15.067111016000183, 11.206241607000038],
              [15.066995621000103, 11.207274437000024],
              [15.066949843999964, 11.207679749000022],
              [15.066384315000164, 11.209356308000054],
              [15.065656663000027, 11.211273193000125],
              [15.064686775000041, 11.21319008],
              [15.063847541000086, 11.2158679960001],
              [15.063635826000052, 11.216544152000097],
              [15.063533783000025, 11.217152596000176],
              [15.063232422000056, 11.218939782000064],
              [15.062872887000083, 11.220069885000044],
              [15.062342643000079, 11.221734048000144],
              [15.062325477000059, 11.222079277000034],
              [15.06231403400011, 11.222316742000032],
              [15.062182425000117, 11.224928857000066],
              [15.061864853000145, 11.226094247000049],
              [15.061615944000096, 11.22700500500008],
              [15.060553551, 11.227996825999981],
              [15.060160638000127, 11.228363038000111],
              [15.059918404000143, 11.230598450000116],
              [15.060280801000033, 11.231234550000181],
              [15.060647011000128, 11.231876373000091],
              [15.063235282000107, 11.234190941000122],
              [15.064157485000123, 11.234841347000099],
              [15.0655002580001, 11.235788346000049],
              [15.066956519000087, 11.238023758000054],
              [15.067280769000092, 11.240578650999964],
              [15.06711959900008, 11.242974281000158],
              [15.067215919000034, 11.243573189000131],
              [15.067605972000081, 11.246008873000051],
              [15.067373276000012, 11.247616768000114],
              [15.067201615000101, 11.248804093000047],
              [15.065746307000154, 11.251519203000157],
              [15.064695358000165, 11.253834724000171],
              [15.063321114000132, 11.256390572000043],
              [15.062997818000042, 11.257827759000065],
              [15.062674523000169, 11.259824753000089],
              [15.062675475999981, 11.263177872000142],
              [15.062271119, 11.265254021999965],
              [15.060815811000055, 11.267650604000039],
              [15.06037426, 11.268178939000109],
              [15.058882713000173, 11.269964218000155],
              [15.056507112000133, 11.271505355000045],
              [15.055171012000073, 11.272363662999965],
              [15.05379295400013, 11.273248672000136],
              [15.052162171000077, 11.274918556999978],
              [15.051111220999985, 11.276196480000124],
              [15.050302506000151, 11.277474403999975],
              [15.050221442000179, 11.278592110000091],
              [15.050140381000119, 11.279709817000139],
              [15.050683021000111, 11.280181884],
              [15.051516533000154, 11.280907631000105],
              [15.051571846000172, 11.28091812100007],
              [15.053133964000097, 11.281227113000057],
              [15.054994583000166, 11.283062933999986],
              [15.055851936000124, 11.285458566000159],
              [15.055966377000061, 11.285778046000132],
              [15.056073189000017, 11.286462783000047],
              [15.056451797000079, 11.288891792000186],
              [15.056870461000074, 11.2910203940001],
              [15.056938172000116, 11.291367531000105],
              [15.05718135800015, 11.293283463000023],
              [15.05807209000011, 11.296557426000106],
              [15.058833123000113, 11.29719352800015],
              [15.060174941000128, 11.298314094000148],
              [15.062359811000078, 11.300868988000161],
              [15.062893868000117, 11.302083970000126],
              [15.063412666000033, 11.303263665000145],
              [15.063412666000033, 11.304983139000115],
              [15.063412666000033, 11.305500030000189],
              [15.063979149000147, 11.307735443000126],
              [15.064950943000042, 11.309332848000054],
              [15.066892624000047, 11.311248780000085],
              [15.069127083000069, 11.312160492000032],
              [15.069239616000061, 11.312206269],
              [15.07009124800004, 11.313229560000025],
              [15.071100235000131, 11.314441682000165],
              [15.074013710000031, 11.316677094],
              [15.074092866000171, 11.31688404099998],
              [15.075227737000034, 11.319870949000062],
              [15.075568200000021, 11.321252823000179],
              [15.075876237000045, 11.322505952000029],
              [15.075067519000072, 11.324661256000184],
              [15.073429107000038, 11.32647323599997],
              [15.073045732000026, 11.326897621],
              [15.072558404000063, 11.327247620000094],
              [15.070504188000086, 11.328721046000169],
              [15.068743704999974, 11.33029556200006],
              [15.06843471600007, 11.330572128000028],
              [15.068170548000069, 11.330881120000186],
              [15.067544937000093, 11.33161067900005],
              [15.067162514000188, 11.33200836200001],
              [15.066007614000171, 11.333208083999978],
              [15.064228058000026, 11.334964753000122],
              [15.062435150000169, 11.336190224000177],
              [15.062087058000145, 11.336427689],
              [15.05923652700011, 11.338371278000182],
              [15.056792260000122, 11.340113640000084],
              [15.054484367000043, 11.341118812000104],
              [15.051972389000184, 11.342123986000161],
              [15.050637244000143, 11.342952728000171],
              [15.050799369000117, 11.344310760999974],
              [15.052256585000066, 11.346945763000065],
              [15.052429198000084, 11.347256660000085],
              [15.052832604000059, 11.347981453000102],
              [15.053631782000082, 11.349420548000069],
              [15.054825783000013, 11.351327896000157],
              [15.055332184000122, 11.352135658],
              [15.058406831000013, 11.354929924000146],
              [15.060782432000053, 11.357547759000113],
              [15.061159135000025, 11.35796356100002],
              [15.063667297000052, 11.360278130000154],
              [15.064679145000127, 11.36089038800003],
              [15.066176415000029, 11.361795426000128],
              [15.067695618000073, 11.362569810000025],
              [15.068523407000043, 11.362992287000111],
              [15.069180487999972, 11.363615991000188],
              [15.070708275000186, 11.365068437000104],
              [15.070660590000045, 11.366405487000065],
              [15.070628166000063, 11.367303849000166],
              [15.070473671000116, 11.368157387000053],
              [15.070223809000083, 11.369540213999983],
              [15.069658280000056, 11.372494697000093],
              [15.069522857000152, 11.372809410000116],
              [15.069445610000059, 11.372989655000026],
              [15.069241524000063, 11.373465538000062],
              [15.068526268000028, 11.375129700000116],
              [15.068312645000049, 11.375604630000112],
              [15.067555427000059, 11.377285957000083],
              [15.067307472999971, 11.378438951000078],
              [15.06674766600014, 11.381039620000024],
              [15.065453529000081, 11.383674621000011],
              [15.064816476000033, 11.385317803000078],
              [15.064401627000109, 11.386389733000158],
              [15.06343078600014, 11.388226509000049],
              [15.062136650000184, 11.389743805000023],
              [15.059871674000021, 11.392299653000123],
              [15.058981894999988, 11.39397716600007],
              [15.058415414000137, 11.395255089000045],
              [15.058465004000141, 11.395672798000021],
              [15.058577537000133, 11.396612167000058],
              [15.058982850000177, 11.397079469000175],
              [15.059548377000169, 11.397729874000049],
              [15.059719085000097, 11.397788048000109],
              [15.061653138000111, 11.398447991000069],
              [15.062739372000124, 11.400149346000035],
              [15.063029290000145, 11.400604249000139],
              [15.063050271000179, 11.401209830000028],
              [15.063110350999978, 11.402919770000153],
              [15.061816216000125, 11.405235291000167],
              [15.06143283800003, 11.406656265000095],
              [15.06116962499999, 11.407630921000134],
              [15.06116962499999, 11.408682823000106],
              [15.06116962499999, 11.409627915000158],
              [15.061413764000065, 11.410588265],
              [15.061574937000159, 11.411224365000066],
              [15.061980248, 11.413061143000164],
              [15.062946319000105, 11.413954735000061],
              [15.063275337000164, 11.414258957000186],
              [15.065055847000053, 11.415296555000054],
              [15.06626892200012, 11.415968895000049],
              [15.066350937999971, 11.416014671000141],
              [15.069022178000012, 11.417450906000056],
              [15.069608688000073, 11.418774606000113],
              [15.070155144000182, 11.420006751000017],
              [15.071451187000036, 11.424158096000042],
              [15.071775437000042, 11.427112578999981],
              [15.071372033000046, 11.430466651000017],
              [15.071615220000183, 11.431903839000142],
              [15.073314667000034, 11.432541848000085],
              [15.074298858000191, 11.432541848000085],
              [15.074961662000135, 11.432541848000085],
              [15.075015069000187, 11.432541848000085],
              [15.077037811000025, 11.432461739000075],
              [15.078090668000016, 11.43461704300006],
              [15.078035355000168, 11.436162948000174],
              [15.078010559000063, 11.436853408000104],
              [15.07787799800019, 11.437150002000124],
              [15.077792167000098, 11.437342644000069],
              [15.077767373000029, 11.437397956000041],
              [15.077120780000087, 11.438849449000145],
              [15.075259208000034, 11.440127374000099],
              [15.074207306000062, 11.441645623000056],
              [15.074208259000045, 11.442603112000143],
              [15.074612618, 11.443561555000144],
              [15.076150895000012, 11.443800926000108],
              [15.078633309000054, 11.44457340200006],
              [15.078597069000068, 11.444616317000168],
              [15.078880310000159, 11.444667816999981],
              [15.07924175200003, 11.444734574999984],
              [15.079272270999979, 11.444789887000127],
              [15.081070900000043, 11.446030618000066],
              [15.082465171000024, 11.446992874999978],
              [15.084165573000064, 11.449069023000163],
              [15.086109161000081, 11.451942445000043],
              [15.087243080000064, 11.454257966000057],
              [15.087983131999977, 11.456314087000123],
              [15.088133811000091, 11.456733704000101],
              [15.089186668000082, 11.459208488000058],
              [15.089439392000031, 11.459830285000066],
              [15.08988952600015, 11.460938454000029],
              [15.089915275000067, 11.461002350000172],
              [15.090020180000124, 11.461260796000033],
              [15.090249061000179, 11.461825371000145],
              [15.091876029000105, 11.465186118000133],
              [15.094391821999977, 11.467537881000112],
              [15.094799996, 11.467920304000017],
              [15.094897270999979, 11.468010903000163],
              [15.098924637000152, 11.470606804999989],
              [15.101713180000047, 11.47266864900007],
              [15.101665497999988, 11.473521233999975],
              [15.101558685000157, 11.475418090000119],
              [15.100853920000191, 11.476232527999969],
              [15.100089074000152, 11.47711563200005],
              [15.098758697000108, 11.479267120000088],
              [15.098540306000075, 11.479620934000025],
              [15.09792137200003, 11.481683731000089],
              [15.09861946, 11.483057976],
              [15.099936485, 11.484050752000087],
              [15.10094261200004, 11.48481369000018],
              [15.102491379000014, 11.484583855000039],
              [15.103139877000046, 11.484341621000112],
              [15.105356216000189, 11.483513832000085],
              [15.108841897000048, 11.484810830000072],
              [15.108904839000104, 11.486044883000147],
              [15.108997343999988, 11.487866401000076],
              [15.108643532000031, 11.490315438000096],
              [15.108602523000116, 11.490926742000056],
              [15.108589172000165, 11.491123199000072],
              [15.108551978000094, 11.492086411000173],
              [15.10853481200013, 11.492526054000052],
              [15.108551978000094, 11.492860795000126],
              [15.108613014000184, 11.494053841000039],
              [15.10876846300016, 11.495047570000111],
              [15.108953477, 11.495375632999981],
              [15.109542847000114, 11.496421814000144],
              [15.110943795000026, 11.496961594000027],
              [15.111324310000157, 11.497108459],
              [15.113415718, 11.498101235000092],
              [15.115662574000112, 11.499704361000056],
              [15.11907005300003, 11.50092506500016],
              [15.120928765000031, 11.501476289000095],
              [15.124724388000118, 11.502602577000062],
              [15.126870156000166, 11.503502846000174],
              [15.128364564000037, 11.504129410000132],
              [15.130854606000185, 11.505261421000114],
              [15.133399962999988, 11.506418227999973],
              [15.134171486000128, 11.508059502000094],
              [15.13487243600008, 11.509550095000066],
              [15.133711815000026, 11.511612891000027],
              [15.133325577000051, 11.513445855000157],
              [15.133265496000149, 11.513691903000108],
              [15.132862091999982, 11.515356064],
              [15.132772446000047, 11.516468049000082],
              [15.132707595000113, 11.517265320999968],
              [15.133715630000097, 11.518869401000188],
              [15.134000778000086, 11.519746780000048],
              [15.13433551900016, 11.520778656000175],
              [15.136659623000071, 11.52222919400009],
              [15.138019561000135, 11.523903846999985],
              [15.138520240000105, 11.524519920999978],
              [15.139483452000036, 11.525691033000101],
              [15.140844346000165, 11.527344703000153],
              [15.14154338899999, 11.530629157000135],
              [15.14157295300015, 11.53180885300003],
              [15.141622542999983, 11.533837318000053],
              [15.141822815000069, 11.534648896000078],
              [15.142320633, 11.53666305500019],
              [15.143174171000112, 11.539183618000095],
              [15.143407821000096, 11.541704179000021],
              [15.142944337000131, 11.544683456000087],
              [15.143013, 11.545383454000103],
              [15.14310073900009, 11.546287536000079],
              [15.14310073900009, 11.547053337000136],
              [15.14310073900009, 11.547891616000129],
              [15.143566131000057, 11.549036980000039],
              [15.14480686200011, 11.551557541000136],
              [15.146821976000069, 11.554306031000181],
              [15.146513939000045, 11.557056428000124],
              [15.14455413800016, 11.559169769999983],
              [15.143011093000155, 11.560783385000036],
              [15.142245292000098, 11.561583520000056],
              [15.138985634000051, 11.56413078200012],
              [15.13505458800006, 11.566228868000167],
              [15.132344246000116, 11.568445207000138],
              [15.13054943100002, 11.569303512000033],
              [15.129789353000035, 11.569667817000038],
              [15.129336357000057, 11.569875717],
              [15.128608703000054, 11.570209504000047],
              [15.127620697, 11.570662498000047],
              [15.123747825, 11.571122170000024],
              [15.120572091000156, 11.572574615000065],
              [15.116931916000169, 11.573721886000044],
              [15.113756180000109, 11.574792861999981],
              [15.111897468000166, 11.575481414000137],
              [15.10957431800017, 11.576475143000039],
              [15.106785775000105, 11.577087402000132],
              [15.106549264000137, 11.577203749999967],
              [15.104307174000098, 11.578310014000067],
              [15.102843285, 11.580359460000125],
              [15.102816582, 11.580501557000105],
              [15.102762222000138, 11.580784797999968],
              [15.102449417000116, 11.582435607999969],
              [15.102140427000109, 11.584269523000103],
              [15.102218629000049, 11.586790084000029],
              [15.102136612000095, 11.589704513000015],
              [15.102066039000022, 11.592213631000163],
              [15.10284233100009, 11.596872329000121],
              [15.10284233100009, 11.59712505300007],
              [15.102843285, 11.600844382999981],
              [15.10313510899999, 11.602351188000057],
              [15.103465080000035, 11.604052543000023],
              [15.102923394000186, 11.606574059000138],
              [15.102637291, 11.607203482999978],
              [15.101917267000147, 11.608789445000127],
              [15.100203513999986, 11.610844613000154],
              [15.09843158700005, 11.612074851000159],
              [15.095875739000121, 11.613832474000048],
              [15.093319892000125, 11.615132332000087],
              [15.091073036000012, 11.616583825000021],
              [15.088827133999985, 11.617731094000021],
              [15.087566375000051, 11.619826317000104],
              [15.087821007000173, 11.620786667000118],
              [15.088750840000102, 11.622084618000144],
              [15.088922501000184, 11.624215125000148],
              [15.088984489000154, 11.624987602000033],
              [15.089372634000028, 11.62773704500006],
              [15.089269637000143, 11.628396988000191],
              [15.088908195000045, 11.630716325000151],
              [15.087230682000097, 11.633293153000011],
              [15.085996628000089, 11.634657860000061],
              [15.085346223000045, 11.63537693100011],
              [15.08294487, 11.636981964000142],
              [15.082845688000191, 11.637035371000024],
              [15.080543518000127, 11.638280869000084],
              [15.079846383000074, 11.639808656000071],
              [15.079691885999978, 11.640648843000065],
              [15.080001830000072, 11.641947746000142],
              [15.080234528000119, 11.642787933000136],
              [15.080434798000169, 11.643077851000157],
              [15.081552505000047, 11.644697190000102],
              [15.082012177000024, 11.64515018600008],
              [15.082559585000013, 11.645689964000155],
              [15.083246232000079, 11.646366120000152],
              [15.08387756400009, 11.646987915000182],
              [15.086201668, 11.647674562000077],
              [15.0872974400001, 11.648214340000152],
              [15.088526725000122, 11.648819923000076],
              [15.088527680000084, 11.651569366999979],
              [15.087814331000118, 11.653427124000132],
              [15.087676048000162, 11.653785706000122],
              [15.086863517000154, 11.65543937800004],
              [15.086437225000054, 11.656306267000048],
              [15.085120200000063, 11.658521653000037],
              [15.083648681000113, 11.659744263000107],
              [15.082098961000099, 11.660279275000164],
              [15.080162047999977, 11.660967827000093],
              [15.077993393000042, 11.662496567000119],
              [15.076976776000038, 11.663846970000066],
              [15.076831817000027, 11.665322304000085],
              [15.076754570000162, 11.666926384000078],
              [15.07737445700019, 11.669065477000061],
              [15.077762603000167, 11.671509744000105],
              [15.078086853000173, 11.672857285000134],
              [15.078461647999973, 11.674411775000124],
              [15.080476760000124, 11.677008629000056],
              [15.081640244000084, 11.679452896000043],
              [15.08233833200012, 11.68227863300018],
              [15.08194637400004, 11.683347701000173],
              [15.08133125300003, 11.685029030000123],
              [15.080766678000089, 11.685637473999975],
              [15.079974174000085, 11.686491966000176],
              [15.079708099000186, 11.68650341100016],
              [15.078464508000025, 11.686557769000046],
              [15.07652759500013, 11.687474252000072],
              [15.075443267000082, 11.688773154999978],
              [15.074513435000085, 11.690224648000083],
              [15.073893547000125, 11.691829681000115],
              [15.073041916000079, 11.694426537000027],
              [15.07264232600005, 11.695666313000117],
              [15.071958541000072, 11.697788238000101],
              [15.069916725000041, 11.699291230000142],
              [15.069314002, 11.700083733000042],
              [15.068897247000109, 11.700631141000031],
              [15.067581175999976, 11.702148437000176],
              [15.067386627000133, 11.702372550000177],
              [15.066379547000111, 11.70390033699999],
              [15.065450668000096, 11.705886842000155],
              [15.064908028000161, 11.707643509000093],
              [15.064520835000167, 11.709476471000073],
              [15.065218925000011, 11.711386680000089],
              [15.06565761600018, 11.712558747000173],
              [15.066305161, 11.714288711000165],
              [15.068701743000076, 11.717504502000054],
              [15.069948196999974, 11.719176292000157],
              [15.071897508000063, 11.721608162000109],
              [15.073437690000162, 11.723529816000109],
              [15.074445725000146, 11.72566795400013],
              [15.07630634200018, 11.727806092000094],
              [15.07731437699999, 11.72918128900011],
              [15.07847690599999, 11.730174065000028],
              [15.080647468, 11.730860710000059],
              [15.086382867, 11.729713441000058],
              [15.088782311000102, 11.728923797000107],
              [15.091032027000097, 11.728183747000173],
              [15.092599868000036, 11.727565766000112],
              [15.094132423000133, 11.726961137000103],
              [15.096555711000065, 11.726145744],
              [15.097541810000052, 11.725813866000124],
              [15.098247528000172, 11.725521087000118],
              [15.100486755000077, 11.724591256],
              [15.102292060000138, 11.724133491999964],
              [15.103508949, 11.723825455000167],
              [15.105568886000015, 11.724145890000102],
              [15.106454850000148, 11.724283219000029],
              [15.106229782000185, 11.726103782000109],
              [15.106067656999983, 11.727415086000065],
              [15.105350495000152, 11.729207992999989],
              [15.105073930000117, 11.729898452000043],
              [15.103436471000066, 11.732569695000166],
              [15.103307725000093, 11.732779503000131],
              [15.101269722000097, 11.73753738500011],
              [15.099367142000062, 11.742160797000054],
              [15.098075867000091, 11.744037628000171],
              [15.096445083000162, 11.74557876700004],
              [15.094133377000048, 11.747120857000084],
              [15.091754913000045, 11.748528480000118],
              [15.089443207000102, 11.749869346000082],
              [15.086996078000027, 11.751477241000146],
              [15.085364342000048, 11.752348900000072],
              [15.083460807000165, 11.75429248800009],
              [15.082437515000038, 11.756602288000067],
              [15.082293510000113, 11.75707721800012],
              [15.081973075000178, 11.758130073000075],
              [15.081741334000128, 11.759886742000049],
              [15.082647324000106, 11.761122703000069],
              [15.082749367000133, 11.761261940000168],
              [15.084145547000162, 11.764011383000025],
              [15.084274292999964, 11.764138222000099],
              [15.086006164000025, 11.765843392000136],
              [15.08725357000003, 11.766711235000116],
              [15.088642120000145, 11.767676353000184],
              [15.091193200000191, 11.769203187000073],
              [15.092596053000022, 11.770042419000049],
              [15.095697403000088, 11.771645546000059],
              [15.098876000000132, 11.772713661000068],
              [15.101976395000065, 11.772483825000052],
              [15.102758407000124, 11.77244949400017],
              [15.105464936000146, 11.772330283000144],
              [15.109573364000084, 11.773092269000131],
              [15.112597465000079, 11.774847984000189],
              [15.115147590000163, 11.776410102000114],
              [15.116086959000143, 11.77698516900017],
              [15.117099761000077, 11.77756977100006],
              [15.118335724000133, 11.778283120000026],
              [15.119037629000047, 11.778636933000087],
              [15.121359826000059, 11.779808999000068],
              [15.122028352000143, 11.781250001000103],
              [15.122600556000066, 11.782482147000053],
              [15.124494552999977, 11.786002159000191],
              [15.124695777000113, 11.78637695399999],
              [15.123117448000187, 11.789719583000078],
              [15.122508049000146, 11.791250230000173],
              [15.122369766000133, 11.79159641300015],
              [15.12136554599999, 11.792565346000117],
              [15.119659424000076, 11.793253899000149],
              [15.119111061000069, 11.793313981000153],
              [15.117567063000024, 11.793483734000063],
              [15.115241051000169, 11.793867112000157],
              [15.113191605000054, 11.79548549600014],
              [15.111627579000128, 11.796759605000148],
              [15.109860420000189, 11.799239159000138],
              [15.108229638000068, 11.801651955000068],
              [15.106462478000083, 11.80446624700005],
              [15.104083061000097, 11.806812286000081],
              [15.100671769000087, 11.808767318000037],
              [15.099354744000095, 11.810372353000048],
              [15.098347665, 11.81304550100009],
              [15.098038673000019, 11.815948487000128],
              [15.098350525000058, 11.820378304000087],
              [15.097887038000181, 11.825267791000101],
              [15.097887992999972, 11.828017235],
              [15.097931863000099, 11.828220367000142],
              [15.098509788, 11.830920219000063],
              [15.098976135000157, 11.834433556000022],
              [15.098019600000157, 11.835804940000173],
              [15.097270965000121, 11.836877823000066],
              [15.09696102200013, 11.83832931600017],
              [15.09707069500007, 11.83900833199999],
              [15.097194671000182, 11.839780809000047],
              [15.097738266000135, 11.842225076000091],
              [15.097662925, 11.842370988000084],
              [15.097498893000136, 11.842689515000018],
              [15.096275330000083, 11.84506988600009],
              [15.095490456000107, 11.845968246000155],
              [15.093475342000033, 11.847115517000134],
              [15.089676856000153, 11.849102021000022],
              [15.086652757000138, 11.850020408999967],
              [15.084239006000189, 11.85197448800011],
              [15.083015442000033, 11.853382111000144],
              [15.082157136000035, 11.855291366000074],
              [15.081614494000064, 11.856972694000092],
              [15.080606461000059, 11.857048988000031],
              [15.07998561900007, 11.856285095000146],
              [15.079442979000078, 11.854604721000044],
              [15.078589438999984, 11.851550103000079],
              [15.075951576000136, 11.848953246000065],
              [15.073081971000136, 11.845593453000163],
              [15.071220399000083, 11.843531609000081],
              [15.069746972000132, 11.842233659000158],
              [15.068995477000044, 11.841460228000017],
              [15.068040847000077, 11.840476989000138],
              [15.066024780000191, 11.839331628000139],
              [15.063853264000102, 11.83979034400005],
              [15.06185913000013, 11.841327666999973],
              [15.058934211000121, 11.843204499000024],
              [15.056758881000121, 11.845281602],
              [15.05417442300012, 11.847694397000055],
              [15.052269936000016, 11.850240707000069],
              [15.050230980000094, 11.854193687000134],
              [15.048599244000059, 11.858347894000019],
              [15.048117638, 11.860952378000036],
              [15.048117638, 11.863549233000072],
              [15.048893928000098, 11.865000726000176],
              [15.049436570000069, 11.866909980000059],
              [15.05044555700016, 11.86927795400004],
              [15.049757958000043, 11.8720149990001],
              [15.049282072999972, 11.87456131100015],
              [15.048193932000117, 11.877039910000121],
              [15.046697617, 11.879652977000035],
              [15.044794083000113, 11.882735252000089],
              [15.043501855000102, 11.88608551100009],
              [15.042413711000165, 11.889636040000141],
              [15.041053772000168, 11.89506340000014],
              [15.040213586000107, 11.899909974000025],
              [15.039826393000112, 11.902888298000107],
              [15.03990459400012, 11.905103684999972],
              [15.040369988000066, 11.906707765000021],
              [15.041456222000079, 11.908693313000185],
              [15.043473244000154, 11.910908699000174],
              [15.045256614999971, 11.912206649999973],
              [15.047816277000038, 11.913580895000109],
              [15.048466682000139, 11.913730622000116],
              [15.049289703999989, 11.913919448000115],
              [15.050143241, 11.914115907000109],
              [15.053012848000037, 11.914649963999977],
              [15.055882455000074, 11.914878845000032],
              [15.057669639000153, 11.916069984000046],
              [15.058287620000044, 11.916481973000145],
              [15.05879878900015, 11.917168618],
              [15.059993743000064, 11.918773651000038],
              [15.062166214000115, 11.922515868000119],
              [15.064106941000034, 11.928397179000115],
              [15.064728737000166, 11.932368280000162],
              [15.064587593, 11.935370446000036],
              [15.064574242000049, 11.935652734000143],
              [15.063309670000081, 11.937870025],
              [15.061609268000041, 11.941153527000097],
              [15.059228897000025, 11.945240975000047],
              [15.057392121000078, 11.94798851000013],
              [15.055623055000069, 11.949997901000188],
              [15.054263114000094, 11.952209472000106],
              [15.05235862699999, 11.954421043000082],
              [15.050385475000155, 11.956498146000058],
              [15.048820496000076, 11.958039283000119],
              [15.048522950000176, 11.959946632000083],
              [15.048911096000097, 11.962162018000072],
              [15.049764633000109, 11.964376449999975],
              [15.05209255200009, 11.969722748000038],
              [15.054110526999978, 11.972625734000076],
              [15.055429459000038, 11.97453498800013],
              [15.057834626000044, 11.976444245000096],
              [15.06047248800013, 11.977283477000071],
              [15.062256813999966, 11.977283477000071],
              [15.066058159000022, 11.976671219000025],
              [15.06862545000007, 11.975253105000093],
              [15.070598602000075, 11.973778725000159],
              [15.072728157000142, 11.971780777000049],
              [15.075443267000082, 11.971550941000032],
              [15.077615738000191, 11.972925186000168],
              [15.081184388000054, 11.975701333000075],
              [15.082426070999986, 11.976666450999971],
              [15.084124566000128, 11.97853469800009],
              [15.086383819000105, 11.981019975000152],
              [15.087082862000102, 11.983845712000061],
              [15.087421418000076, 11.985000611000146],
              [15.088248252000085, 11.98781681100013],
              [15.088521957000069, 11.989386559000138],
              [15.088965415000189, 11.991936684],
              [15.089258194000138, 11.993621825000105],
              [15.088405609000063, 11.995837211999969],
              [15.086969376000127, 11.998136522000152],
              [15.086870194000085, 11.99829483100001],
              [15.086524011000165, 11.998385429000052],
              [15.085458756000037, 11.998664856000175],
              [15.083499909000011, 11.998664856000175],
              [15.081113815000151, 11.998665809000158],
              [15.07738971800012, 11.999125481000021],
              [15.073743820000061, 11.99859142300005],
              [15.068158150000102, 11.998439789000145],
              [15.063657762000105, 11.998594283000102],
              [15.059391022, 11.999664306000113],
              [15.055590629000051, 12.003254891000097],
              [15.052875519000111, 12.006769181000095],
              [15.050005912000131, 12.012193680000109],
              [15.046127319999982, 12.016929627000025],
              [15.043102264000026, 12.021207810000021],
              [15.039300918000094, 12.023958207000135],
              [15.039183617000049, 12.02451801300009],
              [15.038758278000103, 12.026555061000067],
              [15.039760590000071, 12.027956962000133],
              [15.040232658999969, 12.028617860000111],
              [15.04124164600006, 12.029304505000027],
              [15.042638779000129, 12.029915811000137],
              [15.044655799, 12.030755998000132],
              [15.050009726999974, 12.029379846000097],
              [15.053578377, 12.028233529000033],
              [15.055829048000192, 12.030906677000075],
              [15.056449889000135, 12.03419017900012],
              [15.056994437000071, 12.036710739000114],
              [15.058702468999968, 12.043890953000016],
              [15.059246062999989, 12.046792984000092],
              [15.058879853000064, 12.049421310000128],
              [15.05877971600006, 12.050496103000171],
              [15.05870437700014, 12.051300048000144],
              [15.058317183999975, 12.053591728000015],
              [15.056998252000085, 12.05595970100012],
              [15.055446625000059, 12.058404923000126],
              [15.05503273, 12.059322356999985],
              [15.054631234, 12.060211182999979],
              [15.054521561000058, 12.06045436900007],
              [15.0538949970001, 12.061841965000099],
              [15.052499771000157, 12.064002038000012],
              [15.052266121000173, 12.064363480000111],
              [15.050637244000143, 12.065967560000104],
              [15.047765732000016, 12.066808700000081],
              [15.044118881000031, 12.068183899000076],
              [15.042887688000178, 12.06888580399999],
              [15.041170121000107, 12.069864273000064],
              [15.038609505000124, 12.07238578800002],
              [15.036825180000051, 12.074142456000061],
              [15.036757469, 12.074348450000059],
              [15.035738946000038, 12.077426910000042],
              [15.03558921900003, 12.080654144000107],
              [15.035817147000046, 12.083690643000068],
              [15.035998343000131, 12.085359574000051],
              [15.036018372000058, 12.085550308],
              [15.036206245000074, 12.087281227000176],
              [15.036982537000029, 12.091176034000114],
              [15.037137983999969, 12.093926430000124],
              [15.037371635000056, 12.096828461000143],
              [15.036725997000076, 12.098671913000089],
              [15.035936355000104, 12.100928306000185],
              [15.034888268000032, 12.102710724000019],
              [15.032793045000119, 12.10454368600017],
              [15.030620574000011, 12.10607147200011],
              [15.030337333000148, 12.106276512000022],
              [15.029276848000052, 12.107043267000108],
              [15.02681732100001, 12.108821869000053],
              [15.022936821000144, 12.111189842000101],
              [15.019805907000091, 12.112566949000097],
              [15.019289018, 12.112794877000113],
              [15.015486717000101, 12.114093781000122],
              [15.012403488000189, 12.115011216000084],
              [15.012148858000046, 12.115086555000062],
              [15.00951004, 12.116079331000094],
              [15.007414819000132, 12.116843223999979],
              [15.006018638000171, 12.116961479999986],
              [15.004697799000041, 12.117073060000166],
              [15.003556251000077, 12.116973878000124],
              [15.00205898300004, 12.116843223999979],
              [15.000154496000107, 12.115690232000134],
              [15.000041008000096, 12.115621567000062],
              [14.99709129300004, 12.114093781000122],
              [14.995190621000177, 12.112924576000125],
              [14.994607926000185, 12.112565994000136],
              [14.992822646999969, 12.109969140000032],
              [14.992341042000191, 12.108799934999979],
              [14.991659164999987, 12.10714244899998],
              [14.991038322000065, 12.105004312000119],
              [14.991659164999987, 12.096448899],
              [14.991737366000166, 12.088810921000061],
              [14.988554955000041, 12.083846093000147],
              [14.981803894, 12.084685325000123],
              [14.973499297000046, 12.08766365200006],
              [14.967756271000042, 12.090184212000111],
              [14.964108467000017, 12.091634751000129],
              [14.959839822000106, 12.092856408000046],
              [14.955648423000127, 12.095529556000031],
              [14.952931403000036, 12.099729538000133],
              [14.950315476000014, 12.103241922000109],
              [14.946255683000118, 12.104998589000047],
              [14.943150520000131, 12.106755257000088],
              [14.940433503000122, 12.108664513000178],
              [14.936862945000087, 12.11141300200012],
              [14.934066772000108, 12.113828660000138],
              [14.933680534000132, 12.114162446000023],
              [14.931901933000177, 12.115912438000066],
              [14.929409981000049, 12.118362428000125],
              [14.925839424000117, 12.121493339999972],
              [14.922888756000191, 12.125082016000079],
              [14.91970539100015, 12.128595352000161],
              [14.9173755650001, 12.132642747000091],
              [14.915512085000103, 12.134780885000112],
              [14.913570404000154, 12.137454033000097],
              [14.91108513, 12.14035511100019],
              [14.907746315999987, 12.143333436000148],
              [14.905183791000184, 12.146005631000151],
              [14.901612281000041, 12.148066520000043],
              [14.897109985000043, 12.149974824000026],
              [14.894547462000048, 12.151730537000105],
              [14.891442299000062, 12.153104783000117],
              [14.888724327000034, 12.154707909000081],
              [14.886548995, 12.157837868000115],
              [14.884995459999971, 12.160739899000191],
              [14.885326385000099, 12.164100646000122],
              [14.886157988999969, 12.165858269000182],
              [14.887011527000084, 12.167615891000139],
              [14.889105797000184, 12.169754980999983],
              [14.889389991000087, 12.169961929000067],
              [14.890891076000173, 12.171053887000028],
              [14.893238068000016, 12.171761513000092],
              [14.893686295000066, 12.171893119000174],
              [14.893784436000146, 12.171931981],
              [14.893830754000078, 12.17193394800006],
              [14.894187343000169, 12.172026649000031],
              [14.896325111000124, 12.172583581000083],
              [14.899401969000166, 12.172719659000165],
              [14.899784486999977, 12.172736550000081],
              [14.899818, 12.172738],
              [14.900119, 12.172804000000156],
              [14.901158, 12.173032],
              [14.901736, 12.173158000000114],
              [14.902613, 12.17335],
              [14.903054, 12.173485],
              [14.903153658000122, 12.173515829000166],
              [14.903192520000175, 12.173527718000173],
              [14.903989165999974, 12.173771796000096],
              [14.906106, 12.17442],
              [14.906839, 12.175275000000113],
              [14.908201000000133, 12.176866000000132],
              [14.910037000000159, 12.178941],
              [14.91029600000013, 12.179235],
              [14.910767, 12.180091000000175],
              [14.910989, 12.180495],
              [14.911024000000111, 12.180558],
              [14.911046553000119, 12.180599974000188],
              [14.911060334000126, 12.180624963000014],
              [14.911762237000175, 12.181904793000058],
              [14.91183937500017, 12.182045354000024],
              [14.911847134000027, 12.182059460000119],
              [14.911656, 12.182798000000162],
              [14.911316174000149, 12.184103491000087],
              [14.910993, 12.185345],
              [14.910962937000136, 12.185362235000127],
              [14.908455, 12.186800000000176],
              [14.908197, 12.186948000000143],
              [14.907339, 12.18724200000014],
              [14.907286000000113, 12.18726],
              [14.907276401000104, 12.187263200000132],
              [14.907258988000024, 12.187269212000103],
              [14.906412124000099, 12.187559128000089],
              [14.906255114000089, 12.187621661000037],
              [14.905740590000107, 12.187826582000184],
              [14.903538704000027, 12.188703536999981],
              [14.902520019, 12.189078945000176],
              [14.901710287000185, 12.189377349000154],
              [14.901054381999984, 12.189619065000102],
              [14.897560119000161, 12.190993309000078],
              [14.896463347000179, 12.192368685000019],
              [14.896232388000158, 12.192658313000038],
              [14.895307541000136, 12.193818094000164],
              [14.893908500000066, 12.196949005000135],
              [14.893153817000098, 12.19998120299999],
              [14.893086822000157, 12.200250376000156],
              [14.893053055000109, 12.200386047000109],
              [14.893914223000138, 12.202394486000117],
              [14.894698142000038, 12.204583168],
              [14.895028910000065, 12.20539761200007],
              [14.895210715000076, 12.205845266000154],
              [14.895482064000078, 12.206513406],
              [14.896103915000083, 12.207355200000052],
              [14.897569348000104, 12.209339699],
              [14.898778549999975, 12.211178357000108],
              [14.89901277700011, 12.211534741000094],
              [14.899306298000113, 12.211869240000169],
              [14.899350862000063, 12.211920171000031],
              [14.899497525000072, 12.21208778700003],
              [14.899580001000118, 12.212182044000087],
              [14.902281762000143, 12.215268136000191],
              [14.903718947000129, 12.220288277000122],
              [14.903848648000064, 12.224534988000073],
              [14.903592450000019, 12.225671437000017],
              [14.903337426000064, 12.226802677000023],
              [14.903098106000186, 12.22786426600004],
              [14.901884079000183, 12.230455398000174],
              [14.899395942000069, 12.235216141000024],
              [14.896778107000102, 12.239720345000023],
              [14.896738804000165, 12.240219887000137],
              [14.896639672000106, 12.241479871000081],
              [14.896514892000084, 12.243065834000163],
              [14.897036552000088, 12.247313500000075],
              [14.89760655399999, 12.249800342000128],
              [14.897855355000047, 12.250885830000016],
              [14.897951127000169, 12.251303673000052],
              [14.898668118999979, 12.253157129000101],
              [14.898839311000131, 12.253599667000117],
              [14.900042535000011, 12.25671005300012],
              [14.901896204000025, 12.258615230000089],
              [14.902857, 12.259603],
              [14.903053217000092, 12.25993973500016],
              [14.903506673000152, 12.26071828500011],
              [14.904096603000085, 12.261731148000138],
              [14.905405012000074, 12.263276064000081],
              [14.905595342000026, 12.264255060999972],
              [14.906308704000082, 12.267924858000129],
              [14.906580924000025, 12.269325257000105],
              [14.906580677000079, 12.269993146000047],
              [14.906579, 12.273460000000171],
              [14.906578715000137, 12.274475054000163],
              [14.906494719000079, 12.275078906000033],
              [14.906007767000119, 12.278577805000054],
              [14.905668617000117, 12.279540399000098],
              [14.905521, 12.27995900000019],
              [14.905010264000111, 12.28140806600004],
              [14.905004999000084, 12.281422999000085],
              [14.904524954000067, 12.282210032000137],
              [14.903434754000102, 12.283996582000157],
              [14.900947570000028, 12.286698341000147],
              [14.89795271600002, 12.291470303000096],
              [14.897189788000162, 12.292685943000095],
              [14.896101953000141, 12.294419288000086],
              [14.895778968000116, 12.295224400999984],
              [14.895355588000086, 12.296279768000034],
              [14.894398690000173, 12.2986650470001],
              [14.893219948000137, 12.302397729000063],
              [14.89324848800004, 12.302599517000033],
              [14.893708228000037, 12.305850029000055],
              [14.895834923000052, 12.307932854000171],
              [14.897274018000189, 12.309477806000132],
              [14.897548499999971, 12.309702836999975],
              [14.898185616000148, 12.310225170000137],
              [14.899629593000157, 12.311408997000115],
              [14.90211486700008, 12.313468933000024],
              [14.902405738000084, 12.313820839000186],
              [14.903550146999976, 12.315207482000062],
              [14.905516624000086, 12.317588806000117],
              [14.907300950000092, 12.319512368000119],
              [14.907946613000149, 12.320207895000181],
              [14.908263999000098, 12.32055],
              [14.908706890000076, 12.321475983000141],
              [14.91035652100004, 12.324926377000168],
              [14.910445860999971, 12.328206997000166],
              [14.910486, 12.329689000000144],
              [14.909766485000091, 12.330525242000022],
              [14.909696580000059, 12.330606461000116],
              [14.909560339000052, 12.330764563000059],
              [14.90805497600013, 12.332510641999988],
              [14.906643183000142, 12.333396399000037],
              [14.904938034000168, 12.334466018000114],
              [14.903939247000039, 12.335092544000133],
              [14.903826355000092, 12.335266876000162],
              [14.903698169000165, 12.335464823999985],
              [14.902106284000183, 12.337923049000096],
              [14.901502988000175, 12.339436412000168],
              [14.901304984000149, 12.339933103000135],
              [14.900926589000164, 12.340882302000068],
              [14.900987624000152, 12.344309808000162],
              [14.900924684000074, 12.346931458000029],
              [14.901315690000104, 12.349891662000175],
              [14.901478814000143, 12.350248291000071],
              [14.901539000000128, 12.35038],
              [14.902493, 12.352467],
              [14.903408999000021, 12.355041001000131],
              [14.903415155, 12.355052021000176],
              [14.904392320000113, 12.35680113],
              [14.904699540000138, 12.357351192000067],
              [14.90484714500019, 12.357615472000077],
              [14.904901682000173, 12.357745822000027],
              [14.905163260000165, 12.358371022000085],
              [14.906678, 12.361992],
              [14.907724, 12.365338000000122],
              [14.908901000000128, 12.369457],
              [14.90914, 12.37087],
              [14.909291367000037, 12.371763279000163],
              [14.909423827000126, 12.37254619600003],
              [14.909553528000117, 12.376150130000099],
              [14.909039157000109, 12.377836837000132],
              [14.908418421000079, 12.379872330000069],
              [14.908327102999976, 12.380171776000168],
              [14.907195090000016, 12.382713318000128],
              [14.904967308000096, 12.387860299000124],
              [14.9046020400001, 12.388737708000122],
              [14.904440621000106, 12.389125452000087],
              [14.903788566000117, 12.39069175700007],
              [14.902346611000041, 12.39352226200009],
              [14.901167870000165, 12.396096230000069],
              [14.899989128000129, 12.398412706000045],
              [14.898416519000136, 12.400728227000059],
              [14.896494878000055, 12.402852790000054],
              [14.896334176000039, 12.403030461000128],
              [14.896321296000053, 12.403044701000056],
              [14.895823122000081, 12.403810405000172],
              [14.8947404970001, 12.405474429000037],
              [14.893307687, 12.40767669700017],
              [14.891090061000057, 12.410517699000025],
              [14.890716797000039, 12.410995887000183],
              [14.890295028000082, 12.411536216],
              [14.889601372000072, 12.412509500000056],
              [14.889543112000013, 12.41259124600009],
              [14.887543679000089, 12.415396692000058],
              [14.88531597500014, 12.419771035000053],
              [14.884695666000027, 12.420684926999968],
              [14.883481979000067, 12.422472954000057],
              [14.880730630000073, 12.426590919000148],
              [14.879287720000036, 12.431094170000165],
              [14.878632546, 12.433668138000144],
              [14.878644485000052, 12.43501638500004],
              [14.878648, 12.43542900000017],
              [14.878658, 12.436576],
              [14.87836773000015, 12.438376345000052],
              [14.878312000000165, 12.438721999000052],
              [14.879544000000124, 12.442935],
              [14.879932, 12.453876],
              [14.878883000000144, 12.457864999000151],
              [14.877047999000126, 12.460438000000181],
              [14.875084, 12.461724000000174],
              [14.873960657999987, 12.462109880000128],
              [14.872464180000179, 12.462623597000061],
              [14.872316039999987, 12.462608340000145],
              [14.872190270999965, 12.462595387000022],
              [14.871889999000189, 12.462564],
              [14.871607524000183, 12.462535502000151],
              [14.871325001000173, 12.46250700000013],
              [14.86999588700013, 12.462370988000032],
              [14.868667, 12.462235],
              [14.866824000000179, 12.461113],
              [14.866587442000139, 12.460969031000104],
              [14.86591720600012, 12.460560798000188],
              [14.865315184, 12.458842117000188],
              [14.8650992580001, 12.458225682000091],
              [14.864609717000178, 12.456828118000089],
              [14.86461162500018, 12.45247554700012],
              [14.864612580000141, 12.451165200000105],
              [14.86235380100004, 12.448695481000129],
              [14.861523011000031, 12.447787104000042],
              [14.86107921700011, 12.447301864000053],
              [14.859967545000131, 12.447058585000093],
              [14.859247575999973, 12.446901026000091],
              [14.855187416000149, 12.446012497000027],
              [14.850505542000178, 12.448794348999968],
              [14.850276274, 12.44893059400016],
              [14.850210146000052, 12.448969913000099],
              [14.849910954, 12.449454453000044],
              [14.849335095000072, 12.450387056000068],
              [14.847588539000128, 12.453215599000032],
              [14.84614658400011, 12.456689834000031],
              [14.845096588000104, 12.460550308000052],
              [14.844291687, 12.463566781000111],
              [14.844404180000026, 12.464900088000036],
              [14.844455099000072, 12.465503592000061],
              [14.844569206000187, 12.466856003000146],
              [14.844960206, 12.469687404000013],
              [14.845066607000035, 12.469896954000149],
              [14.845687369000018, 12.471119238000028],
              [14.846006393000152, 12.47174740000014],
              [14.847577093999973, 12.473549844000047],
              [14.849802018000048, 12.474709511000128],
              [14.851994231000162, 12.476345548999973],
              [14.852598439000076, 12.476796466000053],
              [14.853598594000118, 12.477542877000133],
              [14.853832040999976, 12.477667697000129],
              [14.854169174000106, 12.477847957000108],
              [14.854258693000133, 12.477895836000073],
              [14.856085707000091, 12.478871765000122],
              [14.858181, 12.479991],
              [14.858200728000043, 12.48007702600006],
              [14.859095574000037, 12.4839811330001],
              [14.859159948000183, 12.485911876000046],
              [14.859168000000125, 12.48615500000011],
              [14.859223020000059, 12.487813448000168],
              [14.859223999000051, 12.487842999000065],
              [14.859221000000105, 12.492347000000109],
              [14.8592060040001, 12.492580969000016],
              [14.858956998999986, 12.496465999000122],
              [14.858609, 12.497574],
              [14.858418360000144, 12.498180746000173],
              [14.858224293000148, 12.498797908000085],
              [14.857984542000111, 12.499560356000075],
              [14.857066800000041, 12.503382568000177],
              [14.857047067000053, 12.503464724000025],
              [14.856932722000124, 12.503667905000043],
              [14.85667920100019, 12.504118393],
              [14.856303214999969, 12.504786491000061],
              [14.856003237000039, 12.505320104000077],
              [14.855907277000085, 12.505490800000132],
              [14.855798722000088, 12.505683900000122],
              [14.855171205000147, 12.506637573000091],
              [14.854498863000117, 12.507659913000111],
              [14.854159355000093, 12.508059501000162],
              [14.854023841000071, 12.508219082000096],
              [14.853866002000018, 12.508404955000117],
              [14.852532386000178, 12.509975434000125],
              [14.85131037900004, 12.513213085000075],
              [14.85122511000003, 12.513439056000038],
              [14.851221085000134, 12.513449774000037],
              [14.851218223999979, 12.517182351000031],
              [14.851466515000027, 12.518265615000132],
              [14.85177289700016, 12.519602323000072],
              [14.852132797000081, 12.521172523000132],
              [14.851904932000139, 12.522567423000055],
              [14.85160732300011, 12.524389267000174],
              [14.851734161000138, 12.528038978000097],
              [14.852063300999987, 12.530044888000134],
              [14.852122104000159, 12.530403262000164],
              [14.852128030000131, 12.53043937699999],
              [14.852650642000015, 12.533142089000023],
              [14.85302606, 12.535317245000044],
              [14.853375400000118, 12.537341305000155],
              [14.85356712399999, 12.538452148000147],
              [14.853623054000025, 12.538775622000117],
              [14.853657974000043, 12.538977585],
              [14.853694916000109, 12.539191245000097],
              [14.853783180000107, 12.541209591000154],
              [14.853815026000063, 12.541937813000175],
              [14.853816996999967, 12.541982943000164],
              [14.853952999000057, 12.545111999000085],
              [14.854016363000142, 12.548361311000178],
              [14.854081, 12.551676],
              [14.854049, 12.551786000000106],
              [14.854002493000053, 12.551947275000032],
              [14.853924751000022, 12.552215576000094],
              [14.85352104899999, 12.553608471000189],
              [14.853386242000113, 12.554073594000045],
              [14.852965354000105, 12.555525780000096],
              [14.852827328000103, 12.557310898000026],
              [14.852752224000085, 12.558282230999964],
              [14.852651597000033, 12.559583663000012],
              [14.852116791000185, 12.561767498000165],
              [14.851824283000155, 12.562961929000096],
              [14.851714000000129, 12.563412000000142],
              [14.848825999000042, 12.569918],
              [14.846095000000105, 12.574587000000179],
              [14.846027386, 12.574706926000147],
              [14.844992220000165, 12.57654267800018],
              [14.844066620000149, 12.578184128000089],
              [14.842193604000045, 12.582087518000094],
              [14.841595649000055, 12.583955765000042],
              [14.8411825660001, 12.585247033000144],
              [14.841031946000044, 12.585717860000102],
              [14.840944290999971, 12.585991861000139],
              [14.840379607000159, 12.58819443100009],
              [14.840247882000028, 12.588708228000087],
              [14.839515686000027, 12.591564179000045],
              [14.838054934000127, 12.59606469],
              [14.83768817300006, 12.597194665000131],
              [14.837677977000112, 12.597226079999984],
              [14.837658668000188, 12.597309785000107],
              [14.837363112000048, 12.598591485999975],
              [14.837091445000056, 12.599769592000143],
              [14.836967605000098, 12.600305996000088],
              [14.836898723000104, 12.60060435500003],
              [14.836757660000046, 12.601215362],
              [14.836944598000059, 12.602504946000124],
              [14.837113938000073, 12.603675570000178],
              [14.837671000000171, 12.607522000000131],
              [14.836933, 12.611754000000133],
              [14.836800255000128, 12.612516191000054],
              [14.836750984000048, 12.612798692000069],
              [14.836727983, 12.612924498000041],
              [14.836625391000041, 12.613485631000174],
              [14.835859367000069, 12.61767305800015],
              [14.835856999000043, 12.617686000000162],
              [14.834518, 12.62052000000017],
              [14.834394, 12.620788000000118],
              [14.83437303300002, 12.62083334800019],
              [14.834221840000055, 12.621159554000144],
              [14.833610584000098, 12.62247791599998],
              [14.833346918000132, 12.623046592000151],
              [14.833206176000033, 12.623350144000028],
              [14.831892966999987, 12.627467157000069],
              [14.831878024000105, 12.627500624999982],
              [14.831840021000062, 12.627585742],
              [14.831403731000023, 12.628562928000065],
              [14.830974579000042, 12.629526138000188],
              [14.830507241000078, 12.629984566000076],
              [14.830109763000053, 12.630374466000035],
              [14.829324000000156, 12.631144999000071],
              [14.829007, 12.631455],
              [14.828904982000154, 12.631504996000103],
              [14.828705000000127, 12.631602999000165],
              [14.826380381000035, 12.632741430000124],
              [14.825403000000108, 12.633044],
              [14.825108347000139, 12.633135249000077],
              [14.82389545400008, 12.633510590000128],
              [14.820226670000068, 12.633379935999983],
              [14.816293551000172, 12.632990004000078],
              [14.816204932000062, 12.63295434500003],
              [14.813413620000176, 12.631831169000179],
              [14.811519510000153, 12.631701378],
              [14.810229863000131, 12.631613005000077],
              [14.809614182000075, 12.631570816000078],
              [14.803586960000189, 12.63027954100005],
              [14.800966262000031, 12.630148888000178],
              [14.795594215999984, 12.62924385000008],
              [14.790353775000085, 12.628338815000063],
              [14.785505295000064, 12.628463746000023],
              [14.785185145000185, 12.62855078900003],
              [14.784391941000138, 12.628766447000089],
              [14.780786173000024, 12.62974597200008],
              [14.778053003000025, 12.629654890000154],
              [14.776856106000025, 12.629615004000073],
              [14.772661209000091, 12.63192749100017],
              [14.77223009800008, 12.632219070000019],
              [14.771673509000095, 12.632595516000094],
              [14.770657540000173, 12.633282662000113],
              [14.768858910000176, 12.634498596000185],
              [14.768273577000173, 12.635202271000026],
              [14.768083050000143, 12.63543131900019],
              [14.767632485000036, 12.635972978000154],
              [14.765972138000052, 12.637970924000058],
              [14.763479233000112, 12.641571998000131],
              [14.76320605799998, 12.642882445000112],
              [14.763070606000156, 12.643532222000147],
              [14.762628555999981, 12.645652771000186],
              [14.76276874500013, 12.646850585000152],
              [14.762834878000035, 12.647416384000167],
              [14.762927269000102, 12.648206831000095],
              [14.762948974000039, 12.648392782000144],
              [14.762943470000153, 12.648464131000082],
              [14.762905205999971, 12.648959456000114],
              [14.762551001000077, 12.653541000000132],
              [14.762081, 12.659126],
              [14.762021000000175, 12.659846],
              [14.760663, 12.662954],
              [14.760195235000026, 12.664411143000052],
              [14.759657609000101, 12.66608541100004],
              [14.758942603000094, 12.668312073000095],
              [14.758604596000112, 12.670104316000106],
              [14.758500998999978, 12.67065299900014],
              [14.758355002000087, 12.67142549],
              [14.758209, 12.672198000000151],
              [14.75790000000012, 12.673507],
              [14.75769924399998, 12.674358959000187],
              [14.757419587000072, 12.675543785000173],
              [14.756780624000044, 12.676851273000125],
              [14.755974769000034, 12.678502083000126],
              [14.755137578000131, 12.679849567000076],
              [14.754733999000052, 12.680499],
              [14.754174, 12.6814],
              [14.75326200100011, 12.682054999000115],
              [14.75229586200004, 12.682749357000034],
              [14.750976902000161, 12.683611153000072],
              [14.750355248000062, 12.684017299000118],
              [14.749940871000149, 12.684288025000114],
              [14.747318268000129, 12.685443878000115],
              [14.743909836, 12.685698510000066],
              [14.742332652000073, 12.685100663000071],
              [14.742207000000178, 12.685053],
              [14.741238000000124, 12.684597],
              [14.740259113000036, 12.684136292000119],
              [14.739680710000016, 12.683863959000064],
              [14.739193914999987, 12.683634759000086],
              [14.738336349000122, 12.683159937000084],
              [14.737095353000086, 12.682471069000087],
              [14.736351883000054, 12.681648462000169],
              [14.735765231000187, 12.680999031000169],
              [14.735003471000027, 12.680155753000179],
              [14.73474560000011, 12.678968477000183],
              [14.734588711000185, 12.678246139000123],
              [14.734221, 12.676551],
              [14.734176586000103, 12.676143056000058],
              [14.734157958000139, 12.675970076000169],
              [14.7341403960001, 12.675806999000088],
              [14.733831406000149, 12.672946930000137],
              [14.73368087099999, 12.670772781000096],
              [14.733604777000096, 12.669673778],
              [14.733572959000128, 12.669214248],
              [14.733432857000082, 12.668138674000033],
              [14.733304643000167, 12.667154369],
              [14.733053207000125, 12.665224076000072],
              [14.73070049200004, 12.65943050400017],
              [14.726373043000024, 12.656076308000138],
              [14.72492118200006, 12.655176531],
              [14.722843168999987, 12.653888702000131],
              [14.718256951000171, 12.653368950000129],
              [14.717435673000068, 12.653713551000124],
              [14.715420795000114, 12.654558972000132],
              [14.714586257000178, 12.654909135000082],
              [14.710258484000121, 12.657220841000026],
              [14.708884553000075, 12.658782490000021],
              [14.708767962000081, 12.658915010000158],
              [14.706977843, 12.660949708000089],
              [14.706650089000107, 12.662070045000178],
              [14.706379256000162, 12.662995810000098],
              [14.706004143000087, 12.664278031000094],
              [14.705529213000091, 12.6675119410001],
              [14.705399317000172, 12.668772902000114],
              [14.705287247000115, 12.669860812000024],
              [14.705131531, 12.671372414000075],
              [14.705519676999984, 12.675491332000149],
              [14.705655103000026, 12.67593646500012],
              [14.705843461000143, 12.676555577000045],
              [14.706224441000018, 12.677807808000182],
              [14.70624731099997, 12.677882482000143],
              [14.706264746000102, 12.677939409000146],
              [14.706303596000112, 12.678066253000111],
              [14.70654733900011, 12.678474656999981],
              [14.706750353000018, 12.678814817000102],
              [14.706912040000191, 12.6790857310001],
              [14.707611083000188, 12.680254936000154],
              [14.709629060000111, 12.683344840000188],
              [14.709685669000066, 12.68343151300013],
              [14.709797638, 12.683602945000132],
              [14.709966660000134, 12.683861732000139],
              [14.710261345000106, 12.68411541100005],
              [14.710302138000031, 12.684150529000135],
              [14.710371017000114, 12.68420982300006],
              [14.710795559000076, 12.684575364000011],
              [14.711453505000179, 12.685141871000098],
              [14.713109016000089, 12.686567305999972],
              [14.714271956, 12.687510009999983],
              [14.714382599000032, 12.687486626],
              [14.714463606999971, 12.687469505000138],
              [14.71438222300003, 12.687501495000106],
              [14.714301109000019, 12.687533379000058],
              [14.715247153000178, 12.688301087000184],
              [14.715334892000044, 12.688371658000051],
              [14.719263076000175, 12.691850661000046],
              [14.721750260000078, 12.694942474000186],
              [14.72341537599999, 12.697159767000073],
              [14.723975182000117, 12.697904587000039],
              [14.725935936000042, 12.703183175000163],
              [14.725931168000159, 12.707302093000067],
              [14.724231719000159, 12.710519791000024],
              [14.723960876000035, 12.711031915000149],
              [14.72369766200012, 12.711461068],
              [14.723052979000158, 12.71251392400012],
              [14.72283363400004, 12.712871551000092],
              [14.721991539000044, 12.714247703000126],
              [14.719891548000135, 12.716818809000017],
              [14.717529297000169, 12.719004631000075],
              [14.715212822000069, 12.720174789000055],
              [14.713463784000112, 12.721058845000016],
              [14.712319374000117, 12.721568109000088],
              [14.710577964000095, 12.722343445000092],
              [14.709901810000133, 12.722514153000191],
              [14.706513404000077, 12.723367691000078],
              [14.703629493999983, 12.723879815000032],
              [14.70100784300007, 12.723619462000045],
              [14.697207450000121, 12.722971917000052],
              [14.69419288600011, 12.722453118000033],
              [14.690000535000081, 12.720775604000153],
              [14.685676575000059, 12.719354630000169],
              [14.680040359000031, 12.718446732000189],
              [14.675977708000062, 12.717798232000177],
              [14.672045708000155, 12.718050957],
              [14.667457581, 12.718045234000158],
              [14.663000107000187, 12.718554497000127],
              [14.65959072000004, 12.719450952000102],
              [14.65631198900013, 12.720604896000054],
              [14.653163909000057, 12.722402573000181],
              [14.650275231000137, 12.725873948000071],
              [14.648569106000139, 12.727673530000118],
              [14.646467210000083, 12.730759622000051],
              [14.644235611000113, 12.732686997000087],
              [14.641085625000073, 12.735384941000177],
              [14.637412071000085, 12.737696647000121],
              [14.634264945999973, 12.738721848000125],
              [14.631773949000035, 12.738461495000024],
              [14.628498078000121, 12.737941740999986],
              [14.625615120000191, 12.73716545200017],
              [14.621552468000118, 12.736515999000176],
              [14.617618561000143, 12.737411499000189],
              [14.616827011, 12.740499496000155],
              [14.617084503000171, 12.743459702000109],
              [14.618393896999976, 12.744619369000077],
              [14.62066459600004, 12.746738433000075],
              [14.62088108100005, 12.746939660000123],
              [14.622840881000059, 12.751317977999975],
              [14.623501777, 12.752970695000045],
              [14.624539375000097, 12.755567552000059],
              [14.625580789000082, 12.760588647000134],
              [14.625577926000119, 12.762085915000171],
              [14.625574111000049, 12.764706613000101],
              [14.624682426999982, 12.768376350000096],
              [14.623242379000033, 12.76927852700004],
              [14.622945785000013, 12.769464493000157],
              [14.621764183000153, 12.77036380800007],
              [14.619928359000141, 12.770232201000056],
              [14.619450569000037, 12.769996643000127],
              [14.617046356000117, 12.768813133000094],
              [14.614819527000179, 12.767651558000182],
              [14.612595558000123, 12.764302254000143],
              [14.610507965000124, 12.760749817000146],
              [14.609719275999964, 12.759407044000113],
              [14.607626915000139, 12.756057740000131],
              [14.60605812099999, 12.753095626000174],
              [14.603309631000172, 12.750260353000158],
              [14.601346971000112, 12.7478122710001],
              [14.598335267000152, 12.745491027000071],
              [14.595191002000149, 12.743941307000114],
              [14.59296226500004, 12.744195939],
              [14.588504791000162, 12.7447032930001],
              [14.586142541000129, 12.746115684000131],
              [14.584174156000074, 12.747528075000048],
              [14.581679344000065, 12.749969482000154],
              [14.579314231000012, 12.752797127000065],
              [14.577998162000085, 12.756140709000135],
              [14.578048706, 12.761109352000119],
              [14.578376769999977, 12.764763833000075],
              [14.579023362000044, 12.770170211000107],
              [14.578025818000071, 12.775043487000062],
              [14.576915741000164, 12.776215553999975],
              [14.574420929000155, 12.778399467000099],
              [14.573778151999988, 12.778803826000114],
              [14.572584153000037, 12.779555321000032],
              [14.569753648000074, 12.779297829000029],
              [14.569699288000038, 12.779293061000146],
              [14.56642436900006, 12.778129577000186],
              [14.566045762000044, 12.777613640000027],
              [14.565295219000063, 12.776590347000024],
              [14.564723015000141, 12.775810243000137],
              [14.562892914000145, 12.772975923000104],
              [14.559749604000103, 12.770911216000059],
              [14.556861877000188, 12.772707940000032],
              [14.554075241000191, 12.776764870000136],
              [14.551807404000044, 12.780053139000188],
              [14.54977226200009, 12.783954620000145],
              [14.548284532000082, 12.787243844000159],
              [14.547575951000169, 12.790994644000136],
              [14.547410966000086, 12.795970918000137],
              [14.549468995000097, 12.801780700000165],
              [14.55286788900014, 12.807320595000135],
              [14.555159569000011, 12.810000420000108],
              [14.556009294000091, 12.810993194000162],
              [14.557335853000154, 12.812644004000163],
              [14.558494568000185, 12.814086913999972],
              [14.561241149000182, 12.81820964800005],
              [14.562409401000025, 12.820840836000116],
              [14.563070296999967, 12.822330475],
              [14.563765525000122, 12.824125290000097],
              [14.564766884000107, 12.82670879300008],
              [14.56552505500008, 12.832674027],
              [14.565985679000164, 12.836655617000133],
              [14.56551170400013, 12.840176582000026],
              [14.562768936, 12.844722747000048],
              [14.560661317000154, 12.850253106000139],
              [14.559474946000137, 12.853597641000022],
              [14.557896615, 12.856554984000184],
              [14.556448937000141, 12.859254838000084],
              [14.556030273000147, 12.863128662000179],
              [14.556100846000049, 12.865651130000174],
              [14.556993485000078, 12.867606164000108],
              [14.557584763000136, 12.870830536000085],
              [14.557884217000037, 12.874003411000103],
              [14.558114052000178, 12.876454354000145],
              [14.557720184000061, 12.878749847000108],
              [14.558863640000027, 12.879914284000051],
              [14.55893039800003, 12.879956246000177],
              [14.559956551000084, 12.880599976000155],
              [14.56154918600015, 12.880602836000037],
              [14.564336777000165, 12.88050937700001],
              [14.567323684000087, 12.88012409300012],
              [14.568233488999965, 12.880125999000086],
              [14.570110322000119, 12.880128861000173],
              [14.570462226000075, 12.880648613000176],
              [14.571499825000046, 12.882182122000131],
              [14.571551322000118, 12.883054733000108],
              [14.571597099000087, 12.883842468000012],
              [14.570831298000087, 12.8845253],
              [14.569303512000147, 12.885889054000074],
              [14.567310332999966, 12.887448312000117],
              [14.564220429000102, 12.889884949000191],
              [14.564017296000088, 12.892521859000055],
              [14.564410210000119, 12.8954524990001],
              [14.564403535000167, 12.89935875000009],
              [14.564389229000028, 12.902954101000091],
              [14.564139367000166, 12.907724382000026],
              [14.563277244000119, 12.910667420000152],
              [14.562523842000132, 12.912663461000022],
              [14.562215805000164, 12.913364411000089],
              [14.56155490899999, 12.914870262000079],
              [14.560588836000079, 12.916339875000062],
              [14.55962085800013, 12.917811394000069],
              [14.558281898000189, 12.919396401000029],
              [14.557579995000083, 12.920226098],
              [14.555109977000029, 12.922745706000114],
              [14.553960800000084, 12.924458504000086],
              [14.553658486000074, 12.924908637000101],
              [14.553530693000084, 12.925091743000166],
              [14.552745819000165, 12.926211357999989],
              [14.552421570999968, 12.92789363899999],
              [14.552529336000077, 12.928354264],
              [14.552741051000112, 12.929260254000042],
              [14.552737236000098, 12.930981637000059],
              [14.552735328000097, 12.932205201000045],
              [14.551994323000145, 12.933549882000023],
              [14.551593780000132, 12.934275628000023],
              [14.548442842000043, 12.935814858000015],
              [14.54319381800002, 12.936449051000181],
              [14.539650917000074, 12.936957359000132],
              [14.53806972600006, 12.940687179],
              [14.531744957000171, 12.955089569000108],
              [14.529241562000095, 12.961004258],
              [14.532904626000118, 12.966545106000183],
              [14.533405304000155, 12.970511436999971],
              [14.525444983999989, 12.98191738100013],
              [14.516798019000134, 12.994895934000056],
              [14.51456260700013, 12.998250009],
              [14.513846398000112, 12.999338150000085],
              [14.507394791000024, 13.009139062000088],
              [14.488501548000045, 13.032907486000113],
              [14.487723351000056, 13.033886909000046],
              [14.484708785, 13.038034438000125],
              [14.464970588000142, 13.066263199000161],
              [14.459829330000048, 13.073822022],
              [14.45347499900015, 13.073340415000075],
              [14.44206142400003, 13.074119569000061],
              [14.4003782260001, 13.074092865000125],
              [14.370447159000094, 13.074155808000114],
              [14.364157676000104, 13.075613976000113],
              [14.357872009000062, 13.075062751000075],
              [14.324869155000158, 13.074174882000079],
              [14.318511010000179, 13.075364113000148],
              [14.301960944000029, 13.075594903000081],
              [14.301484107000078, 13.075601577999976],
              [14.298352242000021, 13.075644493000084],
              [14.289539337000122, 13.075084687000128],
              [14.22639465300017, 13.07684612200012],
              [14.187103272000172, 13.076926231000073],
              [14.179862023000112, 13.076367379000033],
              [14.112348557000132, 13.077149391000091],
              [14.088843345000043, 13.07706737500007],
              [14.085529327000131, 13.077386857000022],
              [14.085430145000089, 13.078060151000102],
              [14.085221291000039, 13.078175543999976],
              [14.082939148000094, 13.079441071000133],
              [14.081095695000101, 13.081868172000156],
              [14.072119712000188, 13.093689919000155],
              [14.067650795000191, 13.09735965800013],
              [14.066000938000172, 13.101731300000154],
              [14.058994294000058, 13.111641885000097],
              [14.051870345000111, 13.121719359999986],
              [14.04835128799999, 13.123780251000085],
              [14.048086166000076, 13.124268532000087],
              [14.045987129000082, 13.128150941000058],
              [14.03020858800005, 13.150659561000111],
              [14.028967857000168, 13.152862549000133],
              [14.028169632000129, 13.154278755000064],
              [14.025031090000141, 13.159851074000073],
              [14.015380860000164, 13.171798706000061],
              [14.011768341000106, 13.177018166000096],
              [14.002658844, 13.190179825000087],
              [13.988058090000038, 13.210144043000128],
              [13.979810714000109, 13.22142028899998],
              [13.976970673000096, 13.227621079000016],
              [13.974392890000047, 13.229000091999978],
              [13.966851234000046, 13.239102365000178],
              [13.963026047000028, 13.244447708000052],
              [13.961428642000101, 13.246680260000062],
              [13.935029983000163, 13.282509804000028],
              [13.93196868900003, 13.287329675000137],
              [13.931404113000042, 13.288108826000041],
              [13.928701401000012, 13.291833878000034],
              [13.918598175000056, 13.305750848000116],
              [13.889289857000165, 13.346122742000034],
              [13.884330749000185, 13.356691360000184],
              [13.883795739000107, 13.356691360000184],
              [13.883389473000079, 13.356691360000184],
              [13.870188714000051, 13.373449325000024],
              [13.865928650000171, 13.382869722000066],
              [13.861220359000072, 13.386308670000062],
              [13.860232353000015, 13.388031006000119],
              [13.856735229000037, 13.394118309000078],
              [13.849659920000022, 13.402838707000058],
              [13.84463596400019, 13.410838127000034],
              [13.841870307000079, 13.41524028900011],
              [13.803399087000173, 13.47034931100012],
              [13.800161362999972, 13.477383613000029],
              [13.799380301000042, 13.479081155000188],
              [13.793709755000179, 13.487110139000151],
              [13.784508705, 13.496520997000061],
              [13.783035278000057, 13.500432968000155],
              [13.780960083000025, 13.505940437000106],
              [13.774359704000062, 13.51167011199999],
              [13.763780593000035, 13.526682853000068],
              [13.762550353000051, 13.528430937999985],
              [13.761897086999966, 13.530632019000109],
              [13.761119843000188, 13.533248901000093],
              [13.760582923000129, 13.533685684000091],
              [13.755470276000153, 13.537841796000066],
              [13.751919747000102, 13.54496193000017],
              [13.715538978000097, 13.596140861000151],
              [13.712209701000063, 13.605559349000146],
              [13.711345673000153, 13.606166840000071],
              [13.707968713000128, 13.608539582000105],
              [13.704407691000029, 13.612471581000079],
              [13.702779770000063, 13.614270210000143],
              [13.693090438000013, 13.626429558000098],
              [13.683160783000119, 13.64135074699999],
              [13.670649529000173, 13.656491281000058],
              [13.667341232000126, 13.66062069000003],
              [13.664228438000123, 13.664189339000188],
              [13.662139893000074, 13.666581154000085],
              [13.662131310000177, 13.669341088000181],
              [13.638729096000077, 13.701462746000175],
              [13.638536454000132, 13.701462746000175],
              [13.63778972700004, 13.701462746000175],
              [13.636627197000166, 13.704770088000089],
              [13.636013984000158, 13.706510545000128],
              [13.634547235000184, 13.710688591000121],
              [13.633506776000161, 13.713636399000109],
              [13.631423951000045, 13.722515107000049],
              [13.628008843000089, 13.73707199200004],
              [13.626094819000173, 13.746035577000157],
              [13.623000146000038, 13.755910875000097],
              [13.620821952000085, 13.7713212970001],
              [13.618451118000053, 13.777520180000067],
              [13.61635399000005, 13.787551880000024],
              [13.614608765000185, 13.795901299000093],
              [13.607596397000123, 13.826785088000065],
              [13.601929664000124, 13.851741790000176],
              [13.597422600000073, 13.868672371000059],
              [13.594529152000121, 13.879541398000129],
              [13.590668678999975, 13.902058601000078],
              [13.587630271000137, 13.914047241999981],
              [13.586906433000081, 13.91690254100007],
              [13.58672237400009, 13.91762638],
              [13.585891725000181, 13.920895576000134],
              [13.58218574600005, 13.936726570000133],
              [13.581237792000138, 13.940778733000116],
              [13.578471184000023, 13.952603339000063],
              [13.576870917, 13.959561347999966],
              [13.57448577800011, 13.969927788000064],
              [13.570876120999969, 13.985623359000101],
              [13.567689895000115, 13.999470710000026],
              [13.566752433000033, 14.002867699000149],
              [13.563631057000066, 14.014169694000145],
              [13.560729981000179, 14.031870841000057],
              [13.559882164000101, 14.035763741000096],
              [13.557128907000106, 14.048411369000121],
              [13.554524421999986, 14.059117318000119],
              [13.551148414000068, 14.072986603000061],
              [13.549639701000046, 14.079476357000033],
              [13.549402237000095, 14.080496787000072],
              [13.546038626999973, 14.094964980000043],
              [13.539087296000105, 14.12488937500018],
              [13.535080909000158, 14.142131805000076],
              [13.53267097600019, 14.156841277000126],
              [13.529519081000046, 14.16961574600009],
              [13.528590202000032, 14.173380852000037],
              [13.519277573000181, 14.212462426000172],
              [13.518520355000021, 14.215640067999971],
              [13.517487527000014, 14.220174790000044],
              [13.500995637000074, 14.292586328000084],
              [13.499294282000108, 14.30476379400011],
              [13.496373177000066, 14.313673019000191],
              [13.493801117000089, 14.321519851000176],
              [13.488040924000131, 14.345409393000068],
              [13.485833168999989, 14.357191085000181],
              [13.485660553000116, 14.358104706],
              [13.485630036000146, 14.358268738000106],
              [13.481079101000091, 14.375041009000086],
              [13.480257988000119, 14.37766170499998],
              [13.479640961000143, 14.379631042000142],
              [13.477793694000127, 14.394884108999975],
              [13.476220131000048, 14.407888413000023],
              [13.473756790000095, 14.439234735000184],
              [13.473475457000177, 14.442815780000103],
              [13.475087165000105, 14.452013969000063],
              [13.475949288000152, 14.453869820000023],
              [13.47883129100012, 14.460068703000047],
              [13.493391038000141, 14.474610330000189],
              [13.499333381000042, 14.477636337000149],
              [13.499749183000176, 14.47784805399999],
              [13.519768714000065, 14.485961914000086],
              [13.533491133999973, 14.490855216000114],
              [13.544508933000088, 14.494784355000036],
              [13.559089660999973, 14.500550270000076],
              [13.566214561000095, 14.503368378000175],
              [13.572069167000109, 14.505682946000036],
              [13.578207017000068, 14.505116462000046],
              [13.586465836000173, 14.504351616000179],
              [13.588639259000104, 14.503811836000125],
              [13.592843056000049, 14.502765657000168],
              [13.597108841000136, 14.502534866000133],
              [13.600868224000067, 14.502330781000069],
              [13.609814643000163, 14.50458908000013],
              [13.633160592000138, 14.510482788000161],
              [13.635100365000142, 14.511289596000097],
              [13.636702538000065, 14.511956215000112],
              [13.640939712000034, 14.513719560000084],
              [13.644233704000044, 14.515819549000014],
              [13.657550813000171, 14.524309159000097],
              [13.659058571, 14.525270462000151],
              [13.662818909000123, 14.52942085300009],
              [13.665888785999982, 14.529890060000071],
              [13.673421859000086, 14.535428047000096],
              [13.675544738000099, 14.536902429000065],
              [13.69602203300002, 14.55111789800003],
              [13.693353653000031, 14.569404602000134],
              [13.691429138000046, 14.582599640000183],
              [13.691609383000127, 14.59374332499999],
              [13.691821099000094, 14.60696125000004],
              [13.69250488299997, 14.612936974000093],
              [13.685067177000121, 14.620380401000034],
              [13.682936668000139, 14.62251186300017],
              [13.67900085499997, 14.626452445000098],
              [13.678758621000043, 14.62705040100019],
              [13.676860810000107, 14.631731033000051],
              [13.676999092000187, 14.633228303000124],
              [13.677308083000071, 14.636560441000142],
              [13.679320335000114, 14.638338090000047],
              [13.690439224000045, 14.64815807500014],
              [13.694020272000046, 14.651320458000043],
              [13.702265739999973, 14.656856537000067],
              [13.706494330999988, 14.663304329000084],
              [13.74556923, 14.698344231000135],
              [13.748382569, 14.697961808],
              [13.752183915000103, 14.697444917000155],
              [13.761685372000159, 14.694302560000096],
              [13.768728257000134, 14.691972733000171],
              [13.77036857700017, 14.695652008000025],
              [13.781451226000115, 14.702349664000167],
              [13.783100128000115, 14.702729225000041],
              [13.788524628000062, 14.703974723000044],
              [13.78861522700015, 14.704982758000085],
              [13.788749695000092, 14.706500054],
              [13.795123099000023, 14.708818435000069],
              [13.796069146000036, 14.710575105000146],
              [13.797228813000174, 14.712731361000181],
              [13.805835724000076, 14.718813896000142],
              [13.809960365000052, 14.721729280000147],
              [13.810227394000037, 14.722628594000128],
              [13.811594009000032, 14.727245331000063],
              [13.80987739599999, 14.741892815000028],
              [13.809412956000131, 14.745852470000159],
              [13.807751656000164, 14.75139522500001],
              [13.803149223000105, 14.766740799000161],
              [13.799153328000159, 14.780063629000097],
              [13.794603347000191, 14.795689583000183],
              [13.794433594000111, 14.796274185000016],
              [13.794140816000038, 14.797283173000039],
              [13.792235374000086, 14.802335739000114],
              [13.791562080000176, 14.812314034000167],
              [13.790995598000052, 14.820712090000086],
              [13.791440009000098, 14.829901695999979],
              [13.791009904000134, 14.834118844000045],
              [13.789730072000111, 14.846670152000115],
              [13.790656090000027, 14.85425663],
              [13.789679527000089, 14.863208772000178],
              [13.79226017000002, 14.868510246000085],
              [13.796490669000036, 14.877706529000079],
              [13.796624184000166, 14.881845475000034],
              [13.796705246000101, 14.884370804000184],
              [13.810228347000191, 14.914607048000107],
              [13.82417011300015, 14.945779801000185],
              [13.830043793000129, 14.956594468000162],
              [13.839189529000123, 14.980508804000124],
              [13.842312813000092, 14.988006592000033],
              [13.843885422000085, 14.99178028200015],
              [13.845178605000058, 14.993199348000132],
              [13.847661971000036, 14.995921136000106],
              [13.848367692000068, 14.998456002000069],
              [13.84879875199999, 15.000000000000114],
              [13.84996128099999, 15.004137993000086],
              [13.851399421000167, 15.009256363000077],
              [13.858053208000115, 15.020998001000123],
              [13.862219811000159, 15.028352737999967],
              [13.862215043000106, 15.029932022000082],
              [13.86220932000009, 15.031338693],
              [13.864991188000033, 15.035722733000057],
              [13.869506835000038, 15.042839050000055],
              [13.908916474000023, 15.08684253600012],
              [13.918541908000122, 15.096243859000026],
              [13.917599678000101, 15.096241952000128],
              [13.931030272999976, 15.112580300000161],
              [13.933150292000164, 15.116258622000032],
              [13.944005013000094, 15.1256990440001],
              [13.944827079000049, 15.12676334400004],
              [13.954849244000172, 15.139734267000051],
              [13.970181465, 15.156531334000135],
              [14.007429123000122, 15.209659576000035],
              [14.007850647000168, 15.211180688000127],
              [14.00813102799998, 15.212189675000047],
              [14.012634277000188, 15.21811294500003],
              [14.012850762000085, 15.218399047999981],
              [14.028181075000077, 15.240469933000156],
              [14.030197144, 15.242621420999967],
              [14.035731316000124, 15.248526573000106],
              [14.037609100000111, 15.253811836000125],
              [14.043245316000139, 15.26177215600012],
              [14.045866966000176, 15.265474319000077],
              [14.046265602000119, 15.266038896000168],
              [14.047050477000141, 15.267148973000076],
              [14.047518731000139, 15.270130158000086],
              [14.051060677000123, 15.272211075000087],
              [14.06901454900003, 15.297546387000068],
              [14.092119216000128, 15.33014869699997],
              [14.096365928000125, 15.336355210000079],
              [14.103448867000054, 15.343482017000099],
              [14.105260849000047, 15.348782540000173],
              [14.105570794000016, 15.349687576000065],
              [14.114304543000117, 15.362103462000107],
              [14.116184234000116, 15.364331246000063],
              [14.119735718000186, 15.368540763999988],
              [14.123041153000031, 15.375431062000075],
              [14.123719215000165, 15.37585163100016],
              [14.125638962000096, 15.37704563200009],
              [14.159641265000175, 15.425767898000117],
              [14.177577018000136, 15.450702667000087],
              [14.186100005000185, 15.462551117000032],
              [14.215633392000143, 15.504140853000024],
              [14.228869439000107, 15.523670196000069],
              [14.235718728000052, 15.53538799200004],
              [14.241394044000174, 15.541132927000092],
              [14.275919914000099, 15.58823204100014],
              [14.277869225000188, 15.590109826000173],
              [14.279230117000168, 15.591910363000011],
              [14.299569130000179, 15.618557930000122],
              [14.306435584000042, 15.62246418],
              [14.306324006000068, 15.623446464000153],
              [14.306200981000075, 15.624528885000075],
              [14.30747413500012, 15.625318527000047],
              [14.308798790000026, 15.626140595000038],
              [14.30984401600017, 15.629100799000184],
              [14.311636925000073, 15.634177209000086],
              [14.315656661000162, 15.639966965000042],
              [14.318494797000142, 15.644055367000078],
              [14.322971344000109, 15.649612427000022],
              [14.324411392000059, 15.651399613000081],
              [14.325307846000157, 15.65281677199999],
              [14.328194619000158, 15.657376289000069],
              [14.356731416000059, 15.693240166000123],
              [14.357069016000139, 15.693664551000154],
              [14.384760857000117, 15.731551170000103],
              [14.392581941000117, 15.73775863700007],
              [14.414485932000161, 15.760914803000048],
              [14.416259765000063, 15.762790681000183],
              [14.421829224000021, 15.768527032000065],
              [14.438769340000135, 15.785976409999989],
              [14.446187973000121, 15.793281555000021],
              [14.45345783300013, 15.800441742000146],
              [14.504410744000097, 15.851403236000181],
              [14.514840127000184, 15.862190246000068],
              [14.524797439000167, 15.870454788000188],
              [14.542579652000029, 15.890419961000191],
              [14.55537891400013, 15.90373039300016],
              [14.594276427000068, 15.942279816],
              [14.610408783000082, 15.956041336],
              [14.621318817000031, 15.969348906999983],
              [14.622268676000033, 15.972559930000045],
              [14.626154901000177, 15.976317406000078],
              [14.63460636200017, 15.984489442000154],
              [14.636494636000066, 15.987915040000075],
              [14.638401032000104, 15.991372109000054],
              [14.646678925000117, 16.000001908000058],
              [14.675205232000053, 16.026737214000036],
              [14.693863869000154, 16.044225693000101],
              [14.715449333000038, 16.067258835000018],
              [14.746358871000041, 16.100242616],
              [14.799110413000051, 16.156482696000182],
              [14.810552596999969, 16.166091919000053],
              [14.817249298000092, 16.175840378000089],
              [14.83400344800009, 16.193380355000158],
              [14.913618088000021, 16.276639939],
              [14.922219277000124, 16.286380768000129],
              [14.956649780000077, 16.325326921000169],
              [14.999999999000181, 16.37046432600016],
              [15.009430886000075, 16.380283356000064],
              [15.098360061000164, 16.47275924700017],
              [15.113504410000075, 16.48848533600011],
              [15.171659470000066, 16.548873901000036],
              [15.182994842000028, 16.560646057999975],
              [15.187390327000173, 16.565210342000171],
              [15.194866180000133, 16.572963715000185],
              [15.195078850000129, 16.573183060000076],
              [15.219064711999977, 16.598060608000026],
              [15.258351325000149, 16.639280320000125],
              [15.259960175, 16.642749787000071],
              [15.26805687000018, 16.650779724000188],
              [15.27554512100005, 16.658206939000024],
              [15.328828812000097, 16.711029052000072],
              [15.330434800000148, 16.714956284000095],
              [15.3644104, 16.750480653000182],
              [15.438458442000126, 16.827848434000089],
              [15.453224182000099, 16.843276979000052],
              [15.473140717000149, 16.864059447999978],
              [15.505545616000177, 16.897871017000057],
              [15.507878303000041, 16.941356660000167],
              [15.508326530999966, 16.949724197000023],
              [15.508669852000025, 16.956125259000089],
              [15.509277343000122, 16.966674804000149],
              [15.508300782000049, 16.973693848000039],
              [15.510681152000188, 16.986877441000104],
              [15.509887695000032, 17.009521484000061],
              [15.512878417000024, 17.043090820000089],
              [15.513342856000179, 17.067779541000107],
              [15.513347626000041, 17.068002702],
              [15.513522147000174, 17.075519562000068],
              [15.513876914000093, 17.093183517000057],
              [15.514056205000145, 17.101732255000059],
              [15.517190934000041, 17.144857405999971],
              [15.51838016500011, 17.16122055000011],
              [15.5206289300001, 17.170209884000144],
              [15.519483566000019, 17.171985627000083],
              [15.518429757000149, 17.173620224000047],
              [15.519520760000091, 17.194662095000126],
              [15.519831658000044, 17.20064544700017],
              [15.521550178000155, 17.233779907999974],
              [15.521283151000148, 17.23531913700009],
              [15.520241737000163, 17.241336822000164],
              [15.524950028000092, 17.292119979000063],
              [15.524669647000053, 17.310016631999986],
              [15.525501251000094, 17.326503754999976],
              [15.531869889000177, 17.452592851000134],
              [15.535799981000139, 17.530269623000095],
              [15.538249016000179, 17.578689575000169],
              [15.538847924000152, 17.590520859000094],
              [15.539319991000013, 17.599870682000017],
              [15.538440705000141, 17.609958650000181],
              [15.542385102000082, 17.667993545000058],
              [15.544878959000073, 17.704690934000041],
              [15.545078278000176, 17.707605363000027],
              [15.544421195000041, 17.71907234300005],
              [15.547313691000113, 17.771047593000105],
              [15.550430297000048, 17.827049255000077],
              [15.551060676000077, 17.830730438000103],
              [15.553070068000068, 17.842470170000126],
              [15.552179336000052, 17.848958969000137],
              [15.551636696000116, 17.852893829000095],
              [15.551178932000084, 17.856220244999975],
              [15.553216935000023, 17.88164329600005],
              [15.55338955000019, 17.883800507000103],
              [15.55338955000019, 17.884912492000183],
              [15.553394318000073, 17.91455841100003],
              [15.554376602000161, 17.921195984000065],
              [15.55513095900011, 17.92629051300014],
              [15.556111335000082, 17.944543839000175],
              [15.556753158000163, 17.956491471000163],
              [15.558789253000157, 17.994390487000146],
              [15.558889388000182, 17.996244431000093],
              [15.558959007000169, 17.997552872000085],
              [15.559190750000027, 18.001882553000087],
              [15.559425353, 18.006246567000062],
              [15.562046051000152, 18.072668075000081],
              [15.562439919000042, 18.082649230000186],
              [15.562891960000059, 18.094099044000075],
              [15.564955712000085, 18.146375656000032],
              [15.566184045, 18.164052964000177],
              [15.569290160999969, 18.20875930699998],
              [15.569683074000068, 18.214405059000114],
              [15.572858810000128, 18.298950196000021],
              [15.573610305000045, 18.318929672000081],
              [15.574458123, 18.334959031000039],
              [15.576588630000174, 18.375204086000053],
              [15.581137658000159, 18.461099624000042],
              [15.582406998000181, 18.485065460000044],
              [15.583879471000103, 18.512880325000083],
              [15.587257385000157, 18.587276459000066],
              [15.587670327000012, 18.596290588999977],
              [15.587670327000012, 18.612140656000065],
              [15.585907936000126, 18.616706848000035],
              [15.586992265000049, 18.623069764000149],
              [15.589671135, 18.638801576000105],
              [15.591972350999981, 18.690671921000103],
              [15.592960357000038, 18.71293068000017],
              [15.593214034000141, 18.718677521000018],
              [15.593798637000134, 18.731866837000098],
              [15.595900535000112, 18.741073608000079],
              [15.596059800000091, 18.741769791000081],
              [15.596392631000185, 18.745332718000043],
              [15.598340989000064, 18.766149520999988],
              [15.600871085000165, 18.774219514000094],
              [15.602347374000033, 18.796140671000103],
              [15.602450372000021, 18.797670364999988],
              [15.607883454000103, 18.838178636000123],
              [15.611990929000058, 18.868759156000124],
              [15.611840249000011, 18.878633499000102],
              [15.613834380000071, 18.886003495000068],
              [15.615269660000024, 18.891309738000075],
              [15.624349593000147, 18.963327408000112],
              [15.624799729000074, 18.966878892000125],
              [15.625193594999985, 18.969989777000023],
              [15.630179406000138, 19.020519258000149],
              [15.631274223000048, 19.031623841000112],
              [15.634280205999971, 19.040847778000057],
              [15.638669967, 19.0705394740001],
              [15.639884948000088, 19.08857727000003],
              [15.640250206000132, 19.093982698000161],
              [15.643874168000139, 19.11859130900018],
              [15.650348664000092, 19.162559509000118],
              [15.655867577000095, 19.181695938000132],
              [15.654491425000117, 19.187149048000094],
              [15.653554916000132, 19.190851212000041],
              [15.654252052000118, 19.206768035999971],
              [15.65445995400006, 19.21153068600006],
              [15.654547691000118, 19.211822510000047],
              [15.654958725000029, 19.213180543000078],
              [15.656428336000033, 19.218019485000013],
              [15.658379555000124, 19.224449159000187],
              [15.66480731900009, 19.272909165000044],
              [15.673459053999977, 19.338157654999975],
              [15.674095154000042, 19.342960356999981],
              [15.676160812999967, 19.358514786000114],
              [15.676230431000079, 19.35903930600017],
              [15.676253318000079, 19.363006592000147],
              [15.676258088000111, 19.363784791000114],
              [15.676299095000047, 19.371219634000056],
              [15.677644730000111, 19.377540588000102],
              [15.677991867000117, 19.379169464000029],
              [15.679240227000037, 19.385036469000113],
              [15.688198090000014, 19.463590622000083],
              [15.689970970000161, 19.479110717000026],
              [15.697401046000152, 19.517799378000063],
              [15.698040009000181, 19.538066865000189],
              [15.698060035000026, 19.538709639999979],
              [15.700588226000036, 19.548385621000079],
              [15.70357513499999, 19.57604408200018],
              [15.704940797000063, 19.588689804000182],
              [15.708061218000012, 19.617620468000098],
              [15.70873832699999, 19.621822357000042],
              [15.71317958800006, 19.64937973100001],
              [15.711696626000105, 19.651050567000084],
              [15.710960389000036, 19.651880265000159],
              [15.713508606000062, 19.660087585000156],
              [15.715829850000148, 19.667560578000177],
              [15.716345785999977, 19.672220231000153],
              [15.716898917000037, 19.677219391000108],
              [15.71533584600013, 19.684547424000073],
              [15.717432021999969, 19.68904686000019],
              [15.719100953000122, 19.692630768000072],
              [15.721559524999975, 19.711559297000122],
              [15.72161483800005, 19.711980819000189],
              [15.722061157000098, 19.715412140000126],
              [15.727620125000158, 19.758199692000119],
              [15.727030754000168, 19.759275436000109],
              [15.724618912000096, 19.763679503999981],
              [15.727267266000183, 19.769166947000087],
              [15.72863197200013, 19.771995544000106],
              [15.731367110000122, 19.784433365000041],
              [15.738125802000127, 19.829746247000116],
              [15.739011764000111, 19.835681916000112],
              [15.739118576000067, 19.836402892000081],
              [15.740221978000022, 19.84379959100005],
              [15.745732308000129, 19.891481400000089],
              [15.746919632000186, 19.901765823000062],
              [15.746220589000131, 19.907444001000044],
              [15.745819092000033, 19.910709382000107],
              [15.747899055000119, 19.917133332000162],
              [15.749775886000066, 19.922931672000118],
              [15.750152588000162, 19.92541503800004],
              [15.753550530000098, 19.947784424000133],
              [15.760490417000085, 19.958929061000049],
              [15.78116607600009, 19.992086410000184],
              [15.786100387000033, 20.000000001000103],
              [15.788438797000083, 20.003749847],
              [15.82231712399999, 20.058082580000189],
              [15.82837009300016, 20.067789077000157],
              [15.831007957000111, 20.07201385500008],
              [15.837269783000067, 20.082040786999983],
              [15.893946648000053, 20.177915573000064],
              [15.898123742000166, 20.18497467100002],
              [15.911258697000051, 20.207174301000123],
              [15.913059234000116, 20.210189820000096],
              [15.915615081999988, 20.21447181800005],
              [15.955596925000123, 20.281425476000038],
              [15.981349944000101, 20.324550629000157],
              [15.981666563999966, 20.325080871000182],
              [15.982233048000126, 20.326028823000058],
              [15.991128922000087, 20.340917587999968],
              [15.995641709000154, 20.348470687000088],
              [15.982689857000025, 20.360174178000079],
              [15.923611641000036, 20.413551332000111],
              [15.919719696000186, 20.41663169899999],
              [15.88787460400016, 20.441837309999983],
              [15.865159989000063, 20.462459564000085],
              [15.836279869000066, 20.504371643000013],
              [15.832262993000029, 20.509506226000042],
              [15.831610680000153, 20.510339736999981],
              [15.829277038000157, 20.513320922000048],
              [15.816164016000187, 20.530073167000126],
              [15.81439113600004, 20.534643174000109],
              [15.790983201000131, 20.567882539000152],
              [15.756528854000123, 20.614780426000095],
              [15.746827125000038, 20.627977372000089],
              [15.745671272999971, 20.629550934000122],
              [15.736433030000057, 20.639804841000057],
              [15.727060318000099, 20.650209427000107],
              [15.696828842000059, 20.680131913000025],
              [15.670441628000106, 20.700117112000044],
              [15.667563438000172, 20.702297210000154],
              [15.667367936000119, 20.702445984000178],
              [15.663744926000049, 20.705190658000106],
              [15.645521165000048, 20.718988419000141],
              [15.64050007000003, 20.722789765000073],
              [15.617959976, 20.74269676300014],
              [15.590099335000104, 20.774469377],
              [15.578259468000056, 20.800109863000046],
              [15.564335823000022, 20.830230712000116],
              [15.556460381000022, 20.847269058000165],
              [15.553015707999975, 20.856113435000168],
              [15.551974297000072, 20.858787538000172],
              [15.548810958000104, 20.866910934000146],
              [15.54615783700001, 20.880418777000159],
              [15.550577164, 20.892248152999969],
              [15.552020073000108, 20.896110534000115],
              [15.557492255000113, 20.904493332000186],
              [15.56110000699999, 20.910020829000018],
              [15.561623574000066, 20.910551071000043],
              [15.566367149000087, 20.915359496000065],
              [15.573370934000025, 20.922454834000064],
              [15.576030731000117, 20.925149918000102],
              [15.615242959, 20.954107285000191],
              [15.620049476000077, 20.957656861000089],
              [15.623555183000178, 20.961189271000137],
              [15.625590324000029, 20.963239670000064],
              [15.602482796, 20.994981765000148],
              [15.600661278000132, 20.99748420700007],
              [15.59471988700011, 21.005645752000078],
              [15.593903541000088, 21.006767272000161],
              [15.517934799000045, 21.11098289500012],
              [15.499497413000086, 21.13624954200003],
              [15.484181405000072, 21.159450532000164],
              [15.473711014000173, 21.17032814099997],
              [15.472922325000184, 21.173759460000156],
              [15.455120086000079, 21.198759079000126],
              [15.44305038499999, 21.215408326000045],
              [15.368051528000024, 21.31876182700006],
              [15.367288590000157, 21.319812776000106],
              [15.36273574900008, 21.326080322000166],
              [15.330129623, 21.370960235000155],
              [15.321539879, 21.385297775000026],
              [15.30120563600002, 21.411851883999987],
              [15.299270629999967, 21.414987564000114],
              [15.293050766000135, 21.425073624000106],
              [15.289652824000029, 21.430580138999971],
              [15.288578987000051, 21.432319641000049],
              [15.198430062000114, 21.49130630500008],
              [15.197851179999986, 21.506229401000041],
              [15.197521210000048, 21.514480591000108],
              [15.195880890000069, 21.522678375],
              [15.194820403000165, 21.527980805000141],
              [15.197198867000111, 21.532150269000169],
              [15.196873664000123, 21.559627534000185],
              [15.19602107899999, 21.631689073000132],
              [15.194520951000072, 21.757925033000163],
              [15.194379807000132, 21.769447326000034],
              [15.193329811000069, 21.777702332000047],
              [15.192951203, 21.780679702000043],
              [15.194000244000051, 21.845190048000063],
              [15.193003655000098, 21.884065629000077],
              [15.191742896000164, 21.933506013000056],
              [15.190589905000081, 21.978708267000172],
              [15.191369056000156, 21.985443115000123],
              [15.191865922000034, 21.989744186000109],
              [15.189792632999968, 22.00092888000006],
              [15.188131332000125, 22.00989914000013],
              [15.168086053000081, 22.117979050000145],
              [15.165065766000168, 22.134265901000106],
              [15.164472581000041, 22.137456895000128],
              [15.150301934000026, 22.21373748700006],
              [15.148667335000084, 22.230281830000024],
              [15.147878646000095, 22.238260269000136],
              [15.14520073, 22.244899750000172],
              [15.143741606999981, 22.248516082000037],
              [15.143128396000179, 22.25281143300009],
              [15.142329216000121, 22.258390427],
              [15.138070106000157, 22.288120270000093],
              [15.132206917000076, 22.316654205000134],
              [15.12368965200011, 22.358100892000095],
              [15.122328759000027, 22.370653153000092],
              [15.122200013000054, 22.371839523000062],
              [15.119628907000106, 22.382720948000042],
              [15.117038726000089, 22.393686295],
              [15.114425658000073, 22.404750823000143],
              [15.094344140000146, 22.506664276000038],
              [15.069854737000071, 22.630752563000101],
              [15.050438880000058, 22.728982926000072],
              [15.047501565000118, 22.7443046570001],
              [15.04547977500016, 22.754850388000023],
              [15.028969764000067, 22.841009141000029],
              [15.029642105000164, 22.844917297000109],
              [15.029125214000146, 22.847381591999977],
              [15.022500991000129, 22.878940583000031],
              [15.008365631000117, 22.946193695000147],
              [15.005349159000104, 22.960550308000109],
              [15.005870819000108, 22.9628639230001],
              [15.006489753000153, 22.965610504000097],
              [15.002903938000031, 22.979627609000147],
              [14.999999046000028, 22.990980148000062],
              [14.997902871000065, 22.999176025000054],
              [14.996606827000107, 23.000905991000025],
              [14.995869636000123, 23.001890182000182],
              [14.999999046000028, 23.005136489000108],
              [15.001238822000118, 23.006109237000146],
              [15.008507728000041, 23.007808685000043],
              [15.008691788000021, 23.007862092000096],
              [15.086561203000031, 23.043045043000177],
              [15.089975357000071, 23.046079635000069],
              [15.094794273000161, 23.047969819000173],
              [15.102073669000106, 23.050823212000068],
              [15.115576745000055, 23.056121826000094],
              [15.116951942000014, 23.057281495000041],
              [15.117328644000111, 23.057600021999974],
              [15.117567063000024, 23.05780029400006],
              [15.119428635000077, 23.059375763000105],
              [15.119729042000131, 23.059629440000037],
              [15.12051391600005, 23.059988022000027],
              [15.135043144000178, 23.066616058000079],
              [15.142104148000158, 23.069839478000176],
              [15.14630317700005, 23.071098328000062],
              [15.147175788000027, 23.071361542000147],
              [15.150982856000041, 23.072502136000026],
              [15.184790611000039, 23.088327408000055],
              [15.223791123000183, 23.106580735000023],
              [15.239149093000094, 23.113769532000163],
              [15.240800858000057, 23.114542008000114],
              [15.244194984000046, 23.116130830000145],
              [15.245858192000185, 23.116590499000097],
              [15.247490883000182, 23.11704254100016],
              [15.251000405000127, 23.118013381000026],
              [15.252094269000054, 23.118316650000168],
              [15.360871314000121, 23.168989182000075],
              [15.361184121000178, 23.169103623000069],
              [15.361522675000117, 23.169227601000046],
              [15.363998413000104, 23.170120240000074],
              [15.371831895000071, 23.172948837999968],
              [15.373452186000122, 23.173532485000067],
              [15.379599572000188, 23.176612854000098],
              [15.400770185999988, 23.187219619000075],
              [15.424529075, 23.197154999000077],
              [15.438229561000128, 23.202884675],
              [15.440629959000148, 23.205350876000068],
              [15.441399575000048, 23.206140518000041],
              [15.474072456000044, 23.220403671000042],
              [15.483638764000034, 23.224580765000155],
              [15.495129584999972, 23.229589462000092],
              [15.504847526000162, 23.232387543000016],
              [15.505999565000025, 23.232719421000127],
              [15.510152817000119, 23.234773637000103],
              [15.542699814000173, 23.250869751000153],
              [15.562678338000126, 23.25957489000001],
              [15.59263515400005, 23.272623063000083],
              [15.606715202000089, 23.278757096000163],
              [15.617639541000074, 23.283512116000168],
              [15.645302772000036, 23.295553208000058],
              [15.649752616000114, 23.298540115000037],
              [15.653659821000019, 23.301162721000139],
              [15.664800643999968, 23.303369522000025],
              [15.688959121000039, 23.31513977100019],
              [15.690013885000099, 23.315355301000068],
              [15.696149825000077, 23.316610336999986],
              [15.71058845400006, 23.322776794000163],
              [15.727401734000125, 23.329957962000151],
              [15.728231431000097, 23.33031272900007],
              [15.728712081000083, 23.330526352000106],
              [15.730153083000118, 23.331169129000045],
              [15.812193870000158, 23.368057251000039],
              [15.82274913700013, 23.372804643000109],
              [15.852950094999983, 23.386383057000046],
              [15.856596946000025, 23.388019561000078],
              [15.856684685000118, 23.388120651000122],
              [15.856970786000034, 23.388448715999971],
              [15.861239433000037, 23.393339157000071],
              [15.8808012, 23.398618698000178],
              [15.939787864000095, 23.425382614000057],
              [15.943350791000057, 23.427000045],
              [15.949720382000123, 23.429889680000088],
              [15.952967644000069, 23.431240081],
              [15.956072807000055, 23.432531357000073],
              [15.967268944000125, 23.437189102000104],
              [15.971489907000034, 23.437469483000143],
              [15.972108841000079, 23.43788528500005],
              [15.975138663000166, 23.439920425000025],
              [15.980854987999976, 23.443759918000012],
              [15.982663156, 23.44402885500017],
              [15.983012200000132, 23.444080353000174],
              [15.985842705000096, 23.444509506000031],
              [15.98803711000005, 23.445554734000041],
              [15.993616104000182, 23.448207856000181],
              [15.996273041000165, 23.449468613000136],
              [15.997116088000041, 23.449869156000148],
              [15.998169900000164, 23.450370790000079],
              [16.037586212000122, 23.432264329000191],
              [16.038785934999964, 23.431713105000085],
              [16.077346802000079, 23.413999558000057],
              [16.097200393000094, 23.404880523000031],
              [16.17214965800008, 23.370399475000056],
              [16.219778062000046, 23.349319458000025],
              [16.220493318000081, 23.349004745000173],
              [16.226350785000022, 23.346412659000123],
              [16.230377198000042, 23.34562683199999],
              [16.231956482000101, 23.345319748000179],
              [16.233072280000101, 23.345100402000128],
              [16.256776810000133, 23.331687928000065],
              [16.259653091000189, 23.330059052000138],
              [16.263328552000075, 23.327978135000023],
              [16.298759460000099, 23.314100267000185],
              [16.313749313000017, 23.305994034000094],
              [16.318956374000038, 23.30375290000012],
              [16.341859817000113, 23.29389381500016],
              [16.356176376000121, 23.287725449000163],
              [16.378370286000063, 23.278165818000105],
              [16.381362916000057, 23.276493072000108],
              [16.394109726000124, 23.269371033000084],
              [16.449480058000063, 23.244869232999974],
              [16.456729888000041, 23.240119935000166],
              [16.459384917000079, 23.238903046000075],
              [16.463447570000028, 23.23704147400008],
              [16.50810051000019, 23.216543198000124],
              [16.546989440000175, 23.198690414000168],
              [16.559968948000119, 23.191459655000074],
              [16.566682815000036, 23.190139771000133],
              [16.576667785000041, 23.185153962000186],
              [16.577121734, 23.184928894],
              [16.578899383000135, 23.184038162000036],
              [16.579320907000181, 23.183128357000157],
              [16.579536437000058, 23.182659150000177],
              [16.580162049000137, 23.181304931999989],
              [16.58390045200008, 23.179956436000168],
              [16.602569578999976, 23.173229218000074],
              [16.608449937000159, 23.17046737700008],
              [16.677066803000116, 23.138252259000012],
              [16.706289292000122, 23.124530792000087],
              [16.707237244000055, 23.124084472999982],
              [16.717420578000088, 23.119289398000035],
              [16.717840195000065, 23.119207382000127],
              [16.721897126000101, 23.118408204000104],
              [16.732549667000171, 23.11263465900015],
              [16.738590240000178, 23.109359741000105],
              [16.795358658, 23.084190369000169],
              [16.813526154000044, 23.076135636000117],
              [16.815500259000032, 23.075260163000053],
              [16.815597534000176, 23.075201035000134],
              [16.816947937000123, 23.074363708000021],
              [16.82721328800011, 23.06800079500016],
              [16.82773018000006, 23.068006515000093],
              [16.828758240000013, 23.068012238000165],
              [16.829288483000084, 23.068016053000179],
              [16.830190658000049, 23.068021774000044],
              [16.845344543000067, 23.059503556000095],
              [16.847124099000041, 23.058502197000109],
              [16.857727051000154, 23.053817750000064],
              [16.88344574100006, 23.042455673000063],
              [16.914812088000133, 23.027084350000052],
              [16.938432693000095, 23.015510560000052],
              [16.944278715999985, 23.012825012000064],
              [16.94874954300019, 23.010770798000067],
              [16.971500397, 23.000339509000071],
              [16.97852516099999, 22.998218537000071],
              [16.981929778000051, 22.997190477000061],
              [16.986921310000071, 22.994152069000052],
              [17.009550095, 22.980377197],
              [17.033399582000072, 22.971330643000044],
              [17.069587708000086, 22.953432084000156],
              [17.079986573000099, 22.948284150000177],
              [17.089372635000075, 22.943637848000151],
              [17.095296860000076, 22.940704344999972],
              [17.139282227000137, 22.920763016000024],
              [17.176736832000188, 22.903781892000097],
              [17.191413879000152, 22.897127153000042],
              [17.228410721000103, 22.880329132999975],
              [17.232536316000107, 22.877466202000164],
              [17.236612320000063, 22.8746376040001],
              [17.248352050000051, 22.868848800000023],
              [17.268659592000063, 22.858833313000105],
              [17.268890380000187, 22.858720780000112],
              [17.26980209300018, 22.85830307100008],
              [17.31203079200003, 22.839010239000061],
              [17.362993240000151, 22.815706253000087],
              [17.366397857000038, 22.814149857000075],
              [17.366697311000109, 22.813873292000039],
              [17.3701248160001, 22.810724259000153],
              [17.399633407000124, 22.798213958000076],
              [17.415491104000125, 22.790611267000088],
              [17.433160782000186, 22.78214073200013],
              [17.522989274000167, 22.73901367100018],
              [17.553949355000043, 22.724151612000185],
              [17.557296752000127, 22.722539902000051],
              [17.592216491000102, 22.70627021700011],
              [17.675426483000081, 22.667501450000032],
              [17.712551117000146, 22.650171281000098],
              [17.72130584700011, 22.646087647000058],
              [17.735593797000149, 22.639417649000109],
              [17.736785888000099, 22.638315200000079],
              [17.737453460999973, 22.637697221000053],
              [17.739059449000024, 22.636209489000066],
              [17.79580307100008, 22.609395980000045],
              [17.867954253999983, 22.575241088999974],
              [17.9168396, 22.552099228000031],
              [17.948167800000078, 22.537240982000071],
              [17.989580154000123, 22.517599106000091],
              [18.030294419000086, 22.497417450000057],
              [18.037349702000029, 22.493921279000062],
              [18.087661741999966, 22.468957902000056],
              [18.116800308000052, 22.457239150000134],
              [18.158523560000049, 22.437175750000108],
              [18.218999864000068, 22.408060073000058],
              [18.22640228299997, 22.406675338000127],
              [18.236181259000148, 22.400644303000036],
              [18.242149353000116, 22.396961213000111],
              [18.243917466000141, 22.395870209000066],
              [18.251014709000174, 22.392513275000113],
              [18.278709412000069, 22.379411698000126],
              [18.363773345000084, 22.339105607000192],
              [18.394605637000154, 22.324497223000151],
              [18.395759582000153, 22.324243546000048],
              [18.400047303000065, 22.323299409000128],
              [18.401296615999968, 22.323028564000026],
              [18.402004242000089, 22.322874069000136],
              [18.412004470000056, 22.317377091000083],
              [18.422954559000061, 22.31135749900011],
              [18.460941314000081, 22.293315889000155],
              [18.507467269000074, 22.271217346000014],
              [18.512931825000123, 22.269725801000163],
              [18.51338386600014, 22.269601822000141],
              [18.520620345000168, 22.265968322000049],
              [18.58602714500006, 22.233129501000178],
              [18.626907348000088, 22.214279175000115],
              [18.640911102000018, 22.207820893000076],
              [18.670709610000188, 22.194072723000147],
              [18.687189103000037, 22.184139253000183],
              [18.726081848000092, 22.167699815000105],
              [18.73845481800015, 22.16160011400018],
              [18.761360168000124, 22.15031051700015],
              [18.790090560000181, 22.136144639000122],
              [18.804283142000145, 22.129146576000096],
              [18.81358528100003, 22.124559403000092],
              [18.840177537000045, 22.111448289000066],
              [19.010881423000171, 22.028055191000192],
              [19.070989610000026, 21.998691558000075],
              [19.071105958000032, 21.998718262000125],
              [19.185903550000091, 21.942642212000067],
              [20.000122071000078, 21.544921875000171],
              [20.250457764000146, 21.422498703000144],
              [20.350708007000094, 21.373474121000129],
              [20.422063828000148, 21.335861206000118],
              [20.485620499000049, 21.302312851000124],
              [20.547376632000123, 21.271995545000038],
              [20.554870606000065, 21.268310548000045],
              [20.578550338000184, 21.256217956000171],
              [20.668764114999988, 21.210119248000126],
              [20.787969589000056, 21.151519775000054],
              [20.812519074000136, 21.139051438000024],
              [20.907480239000165, 21.093429566],
              [20.918447495000066, 21.088157654000099],
              [20.92187500100016, 21.08648681700015],
              [20.930692672000077, 21.082090379000192],
              [21.007619859000101, 21.043556213000045],
              [21.008657455000161, 21.043035507000127],
              [21.026309967000032, 21.034191132000103],
              [21.069240569000101, 21.012662888000023],
              [21.082111359000123, 21.007389069],
              [21.101707458000135, 20.995800018000125],
              [21.144660950000173, 20.97439003],
              [21.215829848000112, 20.938894272000084],
              [21.221368790000099, 20.934431077000056],
              [21.263301850000062, 20.91540145800019],
              [21.360813142000154, 20.865032196000129],
              [21.381340026000032, 20.855024339000124],
              [21.446464538000043, 20.823236467000015],
              [21.480285645000038, 20.806701659999987],
              [21.483213425000145, 20.804733277000139],
              [21.483289719000084, 20.804681778000031],
              [21.498718262000068, 20.794311524000136],
              [21.504882813000052, 20.79351806700015],
              [21.548706054000149, 20.769897460000038],
              [21.554321288999972, 20.768310548000159],
              [21.62329101600011, 20.733886720000157],
              [21.63408661, 20.728403091000189],
              [21.638671875000057, 20.726074219000054],
              [21.646171571000082, 20.72096061700006],
              [21.652099609000118, 20.716918945000145],
              [21.652988433000132, 20.716678619000163],
              [21.658874511000079, 20.715087891000167],
              [21.675563813, 20.70750617900012],
              [21.680881500000112, 20.705064774000164],
              [21.740001678000169, 20.673746109000092],
              [21.740690230000098, 20.673460006000141],
              [21.754030228000033, 20.667936325000085],
              [21.766769410000165, 20.659402847000138],
              [21.782760619000157, 20.654012680000108],
              [21.801519393000092, 20.641897202000052],
              [21.813840866000021, 20.63719749400002],
              [21.824518203000025, 20.633119583000052],
              [21.829895020000038, 20.627502442000093],
              [21.845577240000125, 20.619556428000067],
              [21.857530593000149, 20.613550186000111],
              [21.866491318000044, 20.609046935000094],
              [21.877996444000189, 20.603267669000104],
              [21.884054183000046, 20.601064681000082],
              [21.890075683000191, 20.598876954000161],
              [21.890260696000098, 20.598760606000155],
              [21.89145660500003, 20.598005295000064],
              [21.90811920100009, 20.587450028000092],
              [21.913679124000112, 20.584800721000022],
              [21.919036865000123, 20.584453583000027],
              [21.939224243000126, 20.571603776000075],
              [21.958780289000117, 20.562900543000012],
              [21.97499084400016, 20.5541992200001],
              [22.050764085000083, 20.513515473000041],
              [22.058021545000145, 20.511051178000173],
              [22.08832550000011, 20.49381828300011],
              [22.091630935000126, 20.492141724000192],
              [22.160802841000191, 20.456993103000173],
              [22.166446686000086, 20.456100463000041],
              [22.167659760000106, 20.455883026000038],
              [22.194091796, 20.439697267000099],
              [22.20007324200003, 20.437927247000061],
              [22.200939178999988, 20.437433243000044],
              [22.202203751000184, 20.436714172000109],
              [22.209236146000023, 20.432748794000133],
              [22.21303749100008, 20.430604936000179],
              [22.227699281000127, 20.422340394000116],
              [22.237350463000155, 20.41865921100009],
              [22.275960922000138, 20.398246765000124],
              [22.301069259000087, 20.384971618000122],
              [22.302818299000023, 20.384637832000067],
              [22.305192947000023, 20.384183883000048],
              [22.317878724000082, 20.375375748000181],
              [22.326190949000136, 20.372009278000178],
              [22.341989517000059, 20.365600585000095],
              [22.353239059000089, 20.357740402000161],
              [22.4387969980001, 20.313816071000133],
              [22.443790437000189, 20.312070846999973],
              [22.448690414000168, 20.310350418000041],
              [22.47218704200003, 20.296440124000185],
              [22.475114822000023, 20.29636573800002],
              [22.475421906000179, 20.296356200000162],
              [22.476074219000054, 20.296325684000124],
              [22.477943420000088, 20.295213699000044],
              [22.480947495000066, 20.29343032800017],
              [22.517883301000097, 20.271484375000057],
              [22.524902343000178, 20.268920899000079],
              [22.556577682000068, 20.252506256000174],
              [22.560020446000181, 20.2507305150001],
              [22.56846237200017, 20.246370315000092],
              [22.583740235000164, 20.238479615000188],
              [22.589899063000075, 20.237379073999989],
              [22.622955322000053, 20.218145370000059],
              [22.623094559000094, 20.218086243000016],
              [22.623188018000121, 20.218046188000187],
              [22.626998902000082, 20.216423035000105],
              [22.648925782000163, 20.207092285000044],
              [22.661071778000064, 20.199096680000082],
              [22.69470206800014, 20.1823119660001],
              [22.721609115000035, 20.168909073000179],
              [22.723433698000122, 20.167905105000102],
              [22.728355699000133, 20.165196798000068],
              [22.73094749500018, 20.163770675000137],
              [22.765928269000085, 20.144525528000031],
              [22.768139850000011, 20.14358199100019],
              [22.770675, 20.1425],
              [22.777744, 20.139484000000152],
              [22.795288, 20.13208],
              [22.800147000000152, 20.128154],
              [22.801457575000143, 20.127095131000146],
              [22.80291748000019, 20.125915528000121],
              [22.883300782000106, 20.084472656000059],
              [23.000488281000116, 20.023925782000049],
              [23.038256834000038, 20.004467743000077],
              [23.046692, 20.000122000000147],
              [23.046909766999988, 20.00000839900008],
              [23.049526629000184, 19.998643079999965],
              [23.055114746000072, 19.995727540000189],
              [23.057079227000145, 19.993561573000079],
              [23.057299290000174, 19.993318940000165],
              [23.057495117000087, 19.993103028000064],
              [23.091125488000102, 19.977294922000056],
              [23.101030306000041, 19.972044451000045],
              [23.112087734000113, 19.966182991000153],
              [23.11749267600004, 19.963317872000175],
              [23.12286406200019, 19.960482047000085],
              [23.125652366000168, 19.959009962000039],
              [23.127319336000028, 19.958129884000073],
              [23.132690431000128, 19.953674317000093],
              [23.138364792, 19.950836182000046],
              [23.145948410000074, 19.947111130000167],
              [23.147970199000099, 19.946718216000022],
              [23.151489257000151, 19.946105958000146],
              [23.23468017499999, 19.903320313000052],
              [23.267976200000078, 19.885967494],
              [23.275238395000088, 19.882182672000056],
              [23.276489259000016, 19.881530762000125],
              [23.278785875000153, 19.880606514000192],
              [23.286484644000097, 19.877507779000041],
              [23.286499, 19.877502000000163],
              [23.300559000000192, 19.868299],
              [23.302904212000101, 19.866764500000102],
              [23.303283692000036, 19.866516113000102],
              [23.303410523000139, 19.866468753000049],
              [23.312918648000164, 19.862918119000085],
              [23.312942942000063, 19.862906766000151],
              [23.320269371000052, 19.859122571000114],
              [23.351684570000145, 19.842895509000073],
              [23.380283183000188, 19.827877815000022],
              [23.39919013500014, 19.817949403000114],
              [23.402709961000028, 19.816101074000073],
              [23.405883790000189, 19.813110351000148],
              [23.431173324999975, 19.800985336999986],
              [23.438476562000062, 19.797485351000148],
              [23.444551467000053, 19.794317245],
              [23.446523667000179, 19.793289184000116],
              [23.468322753000109, 19.781921387000068],
              [23.547119141000167, 19.740295411000091],
              [23.575979812000071, 19.726433200000031],
              [23.579831563000027, 19.7245831460001],
              [23.585876464000023, 19.721679689000098],
              [23.600268859999971, 19.714185918999988],
              [23.616678, 19.705642000000182],
              [23.690674000000115, 19.667114],
              [23.70208700000012, 19.660278000000176],
              [23.705078000000128, 19.658508],
              [23.757507, 19.632690000000139],
              [23.775763000000154, 19.621822000000122],
              [23.778320000000178, 19.6203000000001],
              [23.818481, 19.599121000000139],
              [23.864214754999978, 19.575045892000162],
              [23.86651536, 19.573834794000163],
              [23.935119629000098, 19.537719726000148],
              [24.000001907000183, 19.50817303000008],
              [24.000122, 19.5081177080001],
              [24.000122070000089, 19.500122072000067],
              [24.000122070000089, 19.400085449000187],
              [24.000122070000089, 19.300109864000035],
              [24.000122070000089, 19.200073242000087],
              [24.000122070000089, 19.100097657000163],
              [24.000122070000089, 19.000122069999975],
              [24.000122070000089, 18.90008544900013],
              [24.000122070000089, 18.800109864000149],
              [24.000122070000089, 18.70007324200003],
              [24.000122070000089, 18.600097657000049],
              [24.000122070000089, 18.500122070000089],
              [24.000122070000089, 18.40008545000012],
              [24.000122070000089, 18.300109863000159],
              [24.000122070000089, 18.200073242000144],
              [24.000122070000089, 18.100097657000163],
              [24.000122070000089, 18.000122070000032],
              [24.000122070000089, 17.900085450000063],
              [24.000122070000089, 17.800109863000102],
              [24.000122070000089, 17.700073243000134],
              [24.000122070000089, 17.600097656000173],
              [24.000122070000089, 17.500122071000192],
              [24.000122070000089, 17.400085450000176],
              [24.000122070000089, 17.300109862999989],
              [24.000122070000089, 17.200073243000077],
              [24.000122070000089, 17.100097656000059],
              [24.000122070000089, 17.000122071000135],
              [24.000122070000089, 16.900085449000187],
              [24.000122070000089, 16.800109864000035],
              [24.000122070000089, 16.700073243000134],
              [24.000122070000089, 16.600097656000173],
              [24.000122070000089, 16.500122071000021],
              [24.000122070000089, 16.49328613400013],
              [24.000122070000089, 16.400085449000073],
              [24.000122070000089, 16.300109864000092],
              [24.000122070000089, 16.200073241999974],
              [24.000122070000089, 16.10009765699999],
              [24.000122070000089, 16.000122070000032],
              [24.002502442000036, 15.884887695000032],
              [24.002564203000077, 15.854398446],
              [24.002593, 15.840283],
              [24.002686, 15.794495000000154],
              [24.002476000000115, 15.77403900000013],
              [24.002319000000114, 15.75872800000019],
              [24.001801534000094, 15.71305787099999],
              [24.001736, 15.707274],
              [24.001709000000119, 15.704895],
              [24.001419975999966, 15.704858690000094],
              [23.99491085599999, 15.70404098099999],
              [23.994836000000191, 15.704031000000157],
              [23.994801005000056, 15.7040270010001],
              [23.989075000000128, 15.703308],
              [23.963928000000124, 15.703918],
              [23.961121, 15.705688000000123],
              [23.943398000000172, 15.709872000000132],
              [23.942595675000121, 15.710061575000054],
              [23.942504883000083, 15.71008300799997],
              [23.942439149000165, 15.710094293000111],
              [23.94205600000015, 15.710160000000144],
              [23.92828400000019, 15.712524000000144],
              [23.907717, 15.71594400000015],
              [23.903257000000167, 15.716711],
              [23.884603, 15.722654],
              [23.856300000000147, 15.731671],
              [23.84241400000019, 15.734709],
              [23.836319204, 15.736042749000035],
              [23.829788209000128, 15.737471582000012],
              [23.805839683000102, 15.742711991000078],
              [23.805656000000113, 15.742707000000109],
              [23.804401, 15.742676],
              [23.802307, 15.742676],
              [23.790710000000161, 15.742493],
              [23.782269, 15.74407100000019],
              [23.780899673000192, 15.744326909000108],
              [23.776694484000132, 15.74511279900014],
              [23.776672, 15.745117],
              [23.776032702000066, 15.745095064],
              [23.766938, 15.744783],
              [23.741089, 15.74389600000012],
              [23.729130000000168, 15.744959000000108],
              [23.72691900000018, 15.745171],
              [23.725269, 15.74533],
              [23.723344000000111, 15.745954],
              [23.722921000000156, 15.746091],
              [23.719063000000119, 15.747340000000122],
              [23.712700000000154, 15.749401000000148],
              [23.677301000000114, 15.755163],
              [23.673510000000135, 15.755780000000186],
              [23.673169000000144, 15.755835000000104],
              [23.672724704000188, 15.755907385000114],
              [23.672279358000083, 15.755979539000066],
              [23.661970138000129, 15.757659913],
              [23.578140259000065, 15.753810882000153],
              [23.573986997000134, 15.752723501000162],
              [23.56939100000011, 15.751520000000141],
              [23.544830703, 15.745089458000052],
              [23.544680001000188, 15.745049999000116],
              [23.544549611000036, 15.745015833000139],
              [23.525530000000117, 15.740032000000156],
              [23.518609599000115, 15.738218851000113],
              [23.51857200000012, 15.73820899899999],
              [23.516952065000112, 15.737784579000049],
              [23.514758999000037, 15.737209999000129],
              [23.514719032000187, 15.73719474],
              [23.502407, 15.732494],
              [23.497376996000071, 15.730573707000133],
              [23.491602, 15.728369],
              [23.443918000000167, 15.702428],
              [23.44016831600004, 15.701153041000168],
              [23.438233000000139, 15.70049500000016],
              [23.432215, 15.698449000000153],
              [23.427028267000026, 15.696645689000093],
              [23.42547600100005, 15.696105999],
              [23.423707797000134, 15.69551660000019],
              [23.417067, 15.693302999000082],
              [23.416916770000171, 15.693252523000183],
              [23.416817, 15.69321900000017],
              [23.416504000000145, 15.693115],
              [23.403962000000149, 15.692149],
              [23.389385000000175, 15.691026],
              [23.389168001000087, 15.691008999000189],
              [23.381486446000167, 15.690420788000097],
              [23.377689, 15.690130000000181],
              [23.375608733000149, 15.689813814000161],
              [23.372867212000074, 15.689397121000013],
              [23.364225000000147, 15.688085000000115],
              [23.352610000000141, 15.686321000000135],
              [23.352567025000042, 15.68632074900006],
              [23.345425, 15.686279],
              [23.333496000000139, 15.686279],
              [23.333453291000069, 15.686284049000051],
              [23.311317, 15.688901000000101],
              [23.301020515000118, 15.69011877500003],
              [23.296325684000067, 15.690673829000161],
              [23.28701522099999, 15.691752939000082],
              [23.273682000000179, 15.693298000000141],
              [23.24755083000008, 15.699744925000175],
              [23.241271973999972, 15.701293946000021],
              [23.230670929000098, 15.701027871000122],
              [23.230636621000087, 15.701027036000141],
              [23.230564, 15.701025000000129],
              [23.230239718000064, 15.701016993999986],
              [23.226822, 15.700946],
              [23.226665, 15.700943000000109],
              [23.2222000000001, 15.704466],
              [23.221303, 15.705181],
              [23.219776877000072, 15.706399463000139],
              [23.218921660999968, 15.707081795000079],
              [23.218714903000091, 15.707157556000027],
              [23.218393, 15.707275],
              [23.214722, 15.708679000000188],
              [23.208975, 15.707059],
              [23.198486000000116, 15.704102],
              [23.19839231200001, 15.704105221000191],
              [23.185281, 15.704556],
              [23.178539000000114, 15.704788000000121],
              [23.170104507000076, 15.705078211000057],
              [23.17005640900004, 15.705098783000153],
              [23.169939041000077, 15.705148697000027],
              [23.16975580400009, 15.705156781000142],
              [23.169700333000037, 15.705159197000171],
              [23.169679780000024, 15.705160093000075],
              [23.157543183000143, 15.710418702000027],
              [23.153520681000032, 15.712161977000108],
              [23.152876, 15.712115],
              [23.150804694000101, 15.711965039000177],
              [23.147945403000051, 15.711757659000057],
              [23.131464004000122, 15.710564613000145],
              [23.124061584000174, 15.710028648000105],
              [23.122280121000131, 15.709900857000093],
              [23.116083145, 15.705430985000078],
              [23.115207672000167, 15.704799653000066],
              [23.111789704000159, 15.699526785999979],
              [23.105989457000135, 15.690581322000071],
              [23.099929810000106, 15.684080125000094],
              [23.085409165000101, 15.668500900000083],
              [23.077299118000099, 15.663210869000181],
              [23.064340591000189, 15.658880234000037],
              [23.057720185000051, 15.657091139999977],
              [23.054418563000127, 15.653401375000158],
              [23.04269027600003, 15.627209663000031],
              [23.036479950000114, 15.618614197000056],
              [23.036407471000018, 15.618514060000052],
              [23.035869599000137, 15.617769242000065],
              [23.032793045000119, 15.615908623000053],
              [23.025947571000188, 15.611770629000148],
              [23.024856568000075, 15.608978271000012],
              [23.024778366000191, 15.608779907000155],
              [23.023847580000108, 15.608779907000155],
              [23.022649764000107, 15.608780861000014],
              [23.004737854999973, 15.589430810000124],
              [22.996719359000167, 15.583668710000154],
              [22.989389421000169, 15.580651283000122],
              [22.987276077000104, 15.580541611000115],
              [22.985681534000037, 15.58045959399999],
              [22.974950789000104, 15.579901696000036],
              [22.968559265000067, 15.581480980000151],
              [22.965610504000097, 15.581369400000142],
              [22.961929321000071, 15.5812301630001],
              [22.956073762000074, 15.57924938300016],
              [22.954357147000053, 15.578667640000049],
              [22.953516007000076, 15.577872276000164],
              [22.947290421000105, 15.571990966999977],
              [22.930589676000011, 15.550810815000091],
              [22.92883682300004, 15.546734809000156],
              [22.928340912000124, 15.545583725000142],
              [22.926139832000104, 15.540470122999977],
              [22.925325394000026, 15.533484458000089],
              [22.924318314000175, 15.524860382000099],
              [22.92815971400006, 15.514319420000163],
              [22.927679062000095, 15.512269974000048],
              [22.927518844000133, 15.511587142],
              [22.923389435000161, 15.493980407000095],
              [22.922367095000141, 15.48961067200014],
              [22.922130584000172, 15.488601685000049],
              [22.924585342000057, 15.478899003000151],
              [22.925029754999969, 15.477139473000079],
              [22.926046371999973, 15.474838257000101],
              [22.93029022200011, 15.465230942000176],
              [22.931299209000031, 15.46479511199999],
              [22.932899475999989, 15.464100837000046],
              [22.949552536000169, 15.446728707999966],
              [22.951848984000094, 15.445331575000125],
              [22.963792801000068, 15.438071252000043],
              [22.977800370000068, 15.427119256000026],
              [22.978532792000067, 15.425804137000171],
              [22.978818893000039, 15.425292016000185],
              [22.979709625, 15.423691751000035],
              [22.988740921000158, 15.415920257000039],
              [22.988954543000034, 15.415922164000108],
              [22.990159989000119, 15.415931702000023],
              [22.992788314999984, 15.409145356000181],
              [22.996938705000048, 15.398431777000042],
              [22.996360780000089, 15.391099931000042],
              [22.995397567000055, 15.38781261500003],
              [22.991350175000036, 15.373999595000043],
              [22.990369797000085, 15.370639802000142],
              [22.99107170200017, 15.340580941000042],
              [22.991641999000024, 15.337905884000122],
              [22.992670059000147, 15.33308506000003],
              [22.993270875000121, 15.330267906000188],
              [22.999477386000024, 15.322291374000088],
              [23.002937318000136, 15.311422348000178],
              [23.003850936000049, 15.308551788000159],
              [22.999210358000141, 15.29613113400012],
              [22.992427826000096, 15.287483216000055],
              [22.99124908499999, 15.285979270000155],
              [22.988449097000171, 15.271764755999982],
              [22.986810685000137, 15.263449669000067],
              [22.986812592000035, 15.263130187000115],
              [22.986829758, 15.259480476000022],
              [22.989622117000067, 15.256499291000182],
              [22.990200044000176, 15.25588131000012],
              [22.991674423, 15.252992630000051],
              [22.999729156000058, 15.237211227000159],
              [22.996919631000083, 15.231459618000088],
              [22.991971969000133, 15.228000641000108],
              [22.98706817700014, 15.221669197000097],
              [22.987005234000151, 15.221586228000092],
              [22.986799240000153, 15.221320153000022],
              [22.98168182400002, 15.208291055000075],
              [22.979959489000066, 15.203898429000049],
              [22.978860855000164, 15.201090812000189],
              [22.975330352000128, 15.196261406000076],
              [22.969209670000168, 15.191641807000053],
              [22.964960098000063, 15.190941812000119],
              [22.946800232000157, 15.180991173000052],
              [22.935970307000161, 15.171991349000052],
              [22.931739807000099, 15.165780068000117],
              [22.930597306000152, 15.15750885],
              [22.936239243000045, 15.137564660000066],
              [22.939739227000075, 15.125188828000148],
              [22.938915253000118, 15.112051010000073],
              [22.938859940000043, 15.111181259000148],
              [22.939941406, 15.107878685000117],
              [22.940013885000042, 15.107650756000169],
              [22.94005966200001, 15.107509612],
              [22.938165665000099, 15.10611057400007],
              [22.920989991000056, 15.093428612000082],
              [22.918855668000106, 15.093326568000123],
              [22.915790557000093, 15.093180656000129],
              [22.907272338000041, 15.095441819000087],
              [22.898529053000061, 15.095170021000172],
              [22.892860413000164, 15.094449998000186],
              [22.880132676000187, 15.088891030000127],
              [22.873737336000033, 15.084971428000188],
              [22.870700837000072, 15.083108901],
              [22.862022400000058, 15.070899964000148],
              [22.85951805000019, 15.06597137600005],
              [22.855707169000084, 15.058471679000093],
              [22.852180481000119, 15.054788589000168],
              [22.849105835000103, 15.052705764000052],
              [22.83799743700007, 15.045181274000072],
              [22.836572647000139, 15.04486465400015],
              [22.834648132000154, 15.044441224000138],
              [22.818132401000128, 15.032373429000017],
              [22.814159392000079, 15.02947044299998],
              [22.804262161000167, 15.018857956000033],
              [22.79237937900001, 15.006119729000091],
              [22.784349442000064, 15.000000000000114],
              [22.777009964000115, 14.994400979000034],
              [22.767120361000138, 14.984520912000107],
              [22.756910324000103, 14.972210885000038],
              [22.756486892000055, 14.971409797000035],
              [22.756290435000096, 14.971040725000023],
              [22.753610610000123, 14.965985298000192],
              [22.753139495000141, 14.96510028900002],
              [22.750850678, 14.955431938000061],
              [22.749649047000162, 14.950371742000073],
              [22.749755859000118, 14.949625969000067],
              [22.750427246000186, 14.944958687999986],
              [22.75005340600012, 14.937252044000047],
              [22.75004005500017, 14.93699169199999],
              [22.75162887700003, 14.936358452000036],
              [22.752880096000069, 14.935858726000049],
              [22.75864982600018, 14.919330597000055],
              [22.758956910000165, 14.918451310000023],
              [22.759359359000143, 14.91730022400003],
              [22.759870528000079, 14.90995025699999],
              [22.757080078000115, 14.902819633000149],
              [22.752380372000118, 14.899130819999982],
              [22.744358064000153, 14.897250175000067],
              [22.740829468000072, 14.894708633000107],
              [22.729448318000038, 14.896552087000089],
              [22.725700379000159, 14.897158623000166],
              [22.720041274000096, 14.896438599000078],
              [22.707250595000062, 14.891797065999981],
              [22.704008102999978, 14.890620232000174],
              [22.684980392000114, 14.874679565000065],
              [22.674680709000143, 14.858780862000117],
              [22.674352646000045, 14.857864379000034],
              [22.671070099000133, 14.848720550999985],
              [22.679960251000125, 14.826310158000183],
              [22.684457779000127, 14.814959526],
              [22.701450348000037, 14.761380195000186],
              [22.711320878000095, 14.738498688000107],
              [22.717800140000122, 14.70588970200015],
              [22.718170166000164, 14.704028130000154],
              [22.720699309999986, 14.691280365000068],
              [22.717199326000127, 14.686429978000149],
              [22.665519714000027, 14.667720794000047],
              [22.617269516000135, 14.650251388000015],
              [22.616422654000075, 14.650097848000144],
              [22.615539550000165, 14.64993858299999],
              [22.532249450000108, 14.635048866000034],
              [22.523059844000045, 14.634510995000028],
              [22.503051759000186, 14.63042926900016],
              [22.491540909000094, 14.62551116899999],
              [22.490646362000064, 14.625348091000035],
              [22.488740921000044, 14.625000955000075],
              [22.481990814000142, 14.623761177000176],
              [22.466579437000121, 14.615961076000019],
              [22.465307235000068, 14.614874840000027],
              [22.465167999000073, 14.614755630000161],
              [22.455869675000088, 14.606820108000079],
              [22.453889847000028, 14.601819038000031],
              [22.451950074000024, 14.596921921000103],
              [22.44854927, 14.602755547000129],
              [22.447160720000056, 14.605138779000129],
              [22.445055008000111, 14.605119706000096],
              [22.442909241000109, 14.605098725000062],
              [22.440999984000143, 14.603783608000015],
              [22.431430818000081, 14.597190857000157],
              [22.42774772700011, 14.596150398000134],
              [22.425550460000125, 14.595528603000105],
              [22.422124861999976, 14.596038818000125],
              [22.415399551000178, 14.59704017700011],
              [22.413719178000122, 14.596572875000163],
              [22.412948609000068, 14.596358300000077],
              [22.412858963000076, 14.596332550999989],
              [22.411161423000124, 14.59585952700013],
              [22.408916473000033, 14.58816623600012],
              [22.40798187200005, 14.584959984000079],
              [22.402549744, 14.566340446000083],
              [22.402513505000115, 14.566231728000162],
              [22.40249061600008, 14.566160202000106],
              [22.402528763000134, 14.562481880000064],
              [22.40044403100012, 14.558417320000046],
              [22.399957658000119, 14.557467460000112],
              [22.397439957000074, 14.552560807000134],
              [22.39201545800006, 14.533769607000181],
              [22.384210587000098, 14.506738663000078],
              [22.387300492000065, 14.504470824000123],
              [22.400308609000149, 14.50815868400008],
              [22.400688172000173, 14.508267402000172],
              [22.409566878000135, 14.505552292000061],
              [22.410104751000119, 14.505388260000132],
              [22.417505265000102, 14.500914575000081],
              [22.417989730000158, 14.500621796000132],
              [22.418399810000153, 14.500474930000053],
              [22.428630829000156, 14.496810913000047],
              [22.440629959000148, 14.487788200000182],
              [22.440809250000143, 14.487652779000086],
              [22.442209243000093, 14.486599922000096],
              [22.44474983200007, 14.484686852000095],
              [22.447629929000072, 14.482520103000127],
              [22.449609757000076, 14.472431183000083],
              [22.446243287000073, 14.451432229000091],
              [22.442939759000126, 14.430810928000142],
              [22.443437576, 14.42619800599999],
              [22.443460464000168, 14.425991059000012],
              [22.442863464000141, 14.424250603000075],
              [22.441169739000031, 14.419308663000095],
              [22.443216325000094, 14.373276711000074],
              [22.443569184000069, 14.365359306000016],
              [22.443920135000099, 14.365036965000115],
              [22.444152832000043, 14.364824295000119],
              [22.444570541000076, 14.364440918000071],
              [22.449991225000019, 14.359440802999984],
              [22.455440521000071, 14.356741905000149],
              [22.461830140000188, 14.329000474000111],
              [22.461618423000175, 14.328536035000184],
              [22.459518432000095, 14.323929787000054],
              [22.458229066000172, 14.323570252000081],
              [22.445899963000102, 14.320129394000105],
              [22.437383652000051, 14.313926698000103],
              [22.437162399000158, 14.313765526000111],
              [22.435369492, 14.31245994700015],
              [22.429100035999966, 14.302530289000117],
              [22.428529739000112, 14.295500755000091],
              [22.428260804000161, 14.292181014000107],
              [22.431640625000114, 14.284169196000164],
              [22.432243347999986, 14.283316613000068],
              [22.437610627000083, 14.275732041000083],
              [22.44042778000005, 14.272770882000145],
              [22.445659637000119, 14.267271996999966],
              [22.479246139000168, 14.232004166000024],
              [22.489810943000123, 14.235308647000124],
              [22.495187760000135, 14.238265992000152],
              [22.501289369000062, 14.24162006500012],
              [22.508569717000057, 14.243982315000153],
              [22.513828279000109, 14.243878365000114],
              [22.514366149000068, 14.243867875000092],
              [22.516820907000124, 14.243820190000179],
              [22.519844056000068, 14.242788315000155],
              [22.55456733799997, 14.230939865000153],
              [22.557979583000133, 14.229770660000156],
              [22.558160781000026, 14.229709626000044],
              [22.556873321000069, 14.224099159000104],
              [22.553411484000094, 14.208998681000025],
              [22.579069138000079, 14.184411049000062],
              [22.577533722000055, 14.179777146999982],
              [22.576320647000159, 14.17611789800003],
              [22.54319954000016, 14.128429413000163],
              [22.541114807000099, 14.125431060000153],
              [22.536350249, 14.118578912000032],
              [22.503719330000081, 14.109560014000067],
              [22.47065925600009, 14.080987930000163],
              [22.452888488000042, 14.065629960000024],
              [22.441774368000097, 14.058227539000143],
              [22.440111160000129, 14.057120324000095],
              [22.431047440000157, 14.051089286999968],
              [22.428590775000032, 14.050458907000063],
              [22.42613983199999, 14.049831390000065],
              [22.417598725000062, 14.049868584000137],
              [22.392719269000111, 14.04997921000006],
              [22.366859437000073, 14.022859574000051],
              [22.358770370000059, 14.017711640000073],
              [22.35844040000012, 14.017501830000128],
              [22.35771560600017, 14.017470361000107],
              [22.351850509000087, 14.017211914000143],
              [22.344705581000085, 14.015341759000023],
              [22.344375611000146, 14.015255928000101],
              [22.328149796000105, 14.011011124000106],
              [22.32756805300005, 14.010553360000074],
              [22.327489852000042, 14.010491372000047],
              [22.32180023300009, 14.005960464000168],
              [22.319980620000081, 14.004510879000065],
              [22.310604096000191, 13.994649887000037],
              [22.297014237000099, 13.980355262000046],
              [22.292135238000071, 13.975225449000106],
              [22.291749955000114, 13.974820138000041],
              [22.283771516000115, 13.971636773000171],
              [22.263450624000086, 13.96352958600005],
              [22.259290696000164, 13.961401939000098],
              [22.231859206000138, 13.947369576000028],
              [22.230318069000077, 13.945199967000178],
              [22.226312638000024, 13.939556123000045],
              [22.225019455000051, 13.937731742000153],
              [22.210708618000183, 13.917540551000116],
              [22.209609986000032, 13.915990829000123],
              [22.203456878000054, 13.909896851000042],
              [22.151264192000099, 13.858197213000039],
              [22.149309158000165, 13.856259347000105],
              [22.148355483000046, 13.855047225000021],
              [22.139299392999988, 13.843530655000166],
              [22.137956618000032, 13.84181976300016],
              [22.133178712000131, 13.835739136000029],
              [22.132379532000073, 13.834718705000057],
              [22.126380921000134, 13.824321747999988],
              [22.122161866, 13.822440147],
              [22.113414763000037, 13.80893707199999],
              [22.092212677000134, 13.776200295000024],
              [22.091020584000148, 13.767271995000101],
              [22.090797424000186, 13.765600204000066],
              [22.100109100000054, 13.752599717000066],
              [22.109579086000053, 13.7433395380001],
              [22.11401748600008, 13.742468835000068],
              [22.123910905000059, 13.732588769000074],
              [22.12438011200004, 13.730280876],
              [22.124889374000077, 13.727780342000074],
              [22.124574661000111, 13.725928307],
              [22.123989106000067, 13.722478866000188],
              [22.125295640000161, 13.720895767],
              [22.129449845000067, 13.715860368000051],
              [22.125110626000094, 13.699739457000135],
              [22.124456406000149, 13.698987007000028],
              [22.124362946000076, 13.698879242000089],
              [22.119281768000121, 13.69304180099999],
              [22.117809296000132, 13.689189910999971],
              [22.117429734000154, 13.68819618200007],
              [22.116287231000058, 13.685198784000079],
              [22.120420455999977, 13.668001175000086],
              [22.122739793000164, 13.664648056000033],
              [22.123268127000188, 13.663880348000077],
              [22.135339738000027, 13.653170585999987],
              [22.141176224000162, 13.633763314000078],
              [22.14123725900015, 13.633559227000148],
              [22.142580032000126, 13.629091262000031],
              [22.143131256000061, 13.627964974000122],
              [22.146160125000108, 13.621759416000032],
              [22.150621414000057, 13.617282869000121],
              [22.151611328000058, 13.616292000000101],
              [22.157020569000053, 13.615639687000055],
              [22.160127640000042, 13.616800309000155],
              [22.160770416999981, 13.617039680000119],
              [22.167932510000071, 13.602840423999965],
              [22.16786384500017, 13.60247039699999],
              [22.167259216000161, 13.599159242000042],
              [22.17009925900004, 13.596200943000156],
              [22.178970337000067, 13.591691017000016],
              [22.18087005700005, 13.58805084200003],
              [22.196189880000077, 13.5816917410001],
              [22.208858491000115, 13.570697786000039],
              [22.213731766000024, 13.566470145000096],
              [22.214746474000151, 13.561774255000046],
              [22.214969635000045, 13.560740472000077],
              [22.221900939000136, 13.549079895000091],
              [22.222240448000093, 13.538251878000096],
              [22.216680527000051, 13.508310317999985],
              [22.221839904000149, 13.487959861000093],
              [22.221378327000082, 13.486519814000076],
              [22.218433380000192, 13.477171898000051],
              [22.216470718000153, 13.470951082000113],
              [22.217214584999965, 13.469207765000021],
              [22.217508316000021, 13.468519210000181],
              [22.221000672000173, 13.451771736000012],
              [22.224079132000156, 13.44719123800013],
              [22.231620790000136, 13.444951058000015],
              [22.23422050400012, 13.442671776999987],
              [22.238260269000136, 13.43649101200009],
              [22.238819122000109, 13.42315960000019],
              [22.238969802000156, 13.42262745],
              [22.240510941000025, 13.417200088000186],
              [22.245641709000154, 13.411169053000094],
              [22.248090744000024, 13.408290863000161],
              [22.254034043000161, 13.407501221000189],
              [22.259609222000051, 13.406759262000151],
              [22.2619457240001, 13.405295373000115],
              [22.264329910000185, 13.403800965000073],
              [22.278518677000079, 13.390570640000078],
              [22.278963088000182, 13.38832664500012],
              [22.279077531000155, 13.387755393000134],
              [22.279249192000066, 13.386890413000117],
              [22.280536652000023, 13.385070800000108],
              [22.282810211000083, 13.381859780000184],
              [22.283159255000044, 13.37378692600015],
              [22.283170699999971, 13.373540879000132],
              [22.283281326000065, 13.371002198000156],
              [22.284210204000033, 13.349691392000068],
              [22.276741027000128, 13.343757629000038],
              [22.274879455000075, 13.342280388000063],
              [22.2617149350001, 13.32854175600005],
              [22.261129380000057, 13.327931405000072],
              [22.260761260000152, 13.327323913999976],
              [22.254398347000119, 13.316861154000094],
              [22.243959428000153, 13.305321693],
              [22.2439060210001, 13.304812432000062],
              [22.243709564000085, 13.302940369000169],
              [22.243005753000034, 13.302586555],
              [22.23576927199997, 13.298960685000054],
              [22.230430604000048, 13.293391229000179],
              [22.226520537999988, 13.28543186100012],
              [22.224945069000114, 13.282220841000026],
              [22.221469878999983, 13.275138856000126],
              [22.218872070000089, 13.274190902000043],
              [22.210479737000185, 13.271129608000081],
              [22.198966979999966, 13.25706958700016],
              [22.194732666000107, 13.251898765000078],
              [22.184240342000066, 13.245129585000086],
              [22.172359466000103, 13.215129852000075],
              [22.168472291000057, 13.209700585000178],
              [22.157123566000166, 13.199002266000093],
              [22.153919219000159, 13.195980072000111],
              [22.146488190000184, 13.192729950000171],
              [22.144384384000034, 13.18748664899999],
              [22.138141632000043, 13.171932220000087],
              [22.136529922000079, 13.170004845000051],
              [22.135852814000032, 13.169197082000096],
              [22.134649276000118, 13.167759895000131],
              [22.12787056000019, 13.164271356000029],
              [22.109132767000176, 13.160450935000028],
              [22.093179703000033, 13.159190178000074],
              [22.088689805000115, 13.160617830000092],
              [22.088462829000036, 13.160690308000028],
              [22.088239671, 13.160760879000122],
              [22.087059021000016, 13.160608292000177],
              [22.082620620000057, 13.16003132000003],
              [22.078647614000147, 13.157011986000157],
              [22.074718475, 13.14670086000001],
              [22.074584960999971, 13.146349906000125],
              [22.07452011100014, 13.146181106000029],
              [22.071729660000074, 13.143170357000031],
              [22.064460754000152, 13.142650605000028],
              [22.051120759000128, 13.138190269000063],
              [22.046419144000026, 13.138330460000077],
              [22.025577545000147, 13.138948442000014],
              [22.020380021000165, 13.139101982000113],
              [22.013599396000188, 13.13628959700003],
              [22.00990867600018, 13.130778314000111],
              [22.008525847999977, 13.118322373000069],
              [22.007488250000108, 13.108889581000085],
              [22.003311157000098, 13.102652551000062],
              [22.000972747000048, 13.101808549000054],
              [22.000436782, 13.101615905000131],
              [21.994190216000106, 13.099370002000171],
              [21.983007430000157, 13.092078208000089],
              [21.982059478000053, 13.091459274000044],
              [21.977476121000166, 13.086460115000023],
              [21.964860917000181, 13.072699547000127],
              [21.964624406000041, 13.072635652000088],
              [21.956420899000136, 13.070420266000099],
              [21.951030731000174, 13.068970680000064],
              [21.947309495000127, 13.067970276000096],
              [21.942600251000044, 13.060791017000156],
              [21.938972473999968, 13.055259705000083],
              [21.932682037000063, 13.050149918000159],
              [21.930843353000171, 13.049291611000115],
              [21.930381774000068, 13.049076081000067],
              [21.925670623000087, 13.046876907000183],
              [21.926727295000148, 13.042304038000054],
              [21.926889420000123, 13.041601179999986],
              [21.933029175, 13.035750389000043],
              [21.931192397000075, 13.03095340699997],
              [21.930751801000042, 13.029799461999971],
              [21.93083000200005, 13.018381120000072],
              [21.930170059000091, 13.017225266000025],
              [21.925550460000181, 13.009129524000059],
              [21.911329269000078, 13.002310754],
              [21.898805619000029, 12.981930734000059],
              [21.898670196000126, 12.981710434000149],
              [21.89110946600016, 12.965940475000139],
              [21.885438918000148, 12.946869850999974],
              [21.885063171000013, 12.945608140000161],
              [21.879390716999978, 12.926531791000116],
              [21.878959657000053, 12.925097466000011],
              [21.878709793000155, 12.924259187000018],
              [21.863506318000134, 12.896280288000071],
              [21.860359193000079, 12.89048862400017],
              [21.858777999000154, 12.888479233000055],
              [21.853429795000125, 12.881681442000115],
              [21.850738525000054, 12.871541024000066],
              [21.85043716500013, 12.857446671000162],
              [21.850280761000192, 12.850151062],
              [21.848339082, 12.836569785000108],
              [21.841251373000034, 12.813920974000098],
              [21.839639664000174, 12.80877113300005],
              [21.839574814000116, 12.808508873000051],
              [21.839149474000124, 12.801671982000016],
              [21.839132308000103, 12.801521301000093],
              [21.838949204000073, 12.798300744000187],
              [21.841018677000136, 12.792433739000103],
              [21.843309402000045, 12.785941125000079],
              [21.847845077000102, 12.779429435000054],
              [21.853319168000155, 12.771571159000189],
              [21.858074188000103, 12.759384156000181],
              [21.859840393000127, 12.754859924000129],
              [21.859701157000131, 12.753326416999982],
              [21.857980729000076, 12.73414993300014],
              [21.865879059000065, 12.719531059000076],
              [21.880279541000164, 12.709111215],
              [21.88266182000001, 12.70542335500005],
              [21.885290146000045, 12.701351166],
              [21.885869980000109, 12.697940827000025],
              [21.887809754000159, 12.686441423000133],
              [21.915460587000155, 12.664669990000107],
              [21.934240342000123, 12.639361382000175],
              [21.941080093000039, 12.635528565000072],
              [21.956829071000129, 12.638689042000181],
              [21.960470199000156, 12.63941955700011],
              [21.982362747000025, 12.638751983000134],
              [22.025186539000117, 12.637434959000018],
              [22.03284072800011, 12.637200355000175],
              [22.044097900000168, 12.640321732000075],
              [22.044628143000068, 12.640468598000155],
              [22.108179093000103, 12.658090592000178],
              [22.136249543000076, 12.665860176000137],
              [22.143230438000103, 12.669057846000044],
              [22.145158768000158, 12.669940949000022],
              [22.164518356000031, 12.678810120000151],
              [22.183940888000166, 12.69297027600004],
              [22.193769455999984, 12.705290794],
              [22.195060728999977, 12.706910134000054],
              [22.19622993400003, 12.724786759000096],
              [22.196739198000046, 12.732600213000183],
              [22.197027206000087, 12.737010955000073],
              [22.197887421000132, 12.750161171000116],
              [22.202217102000134, 12.747774124000046],
              [22.254419326000175, 12.718991280000125],
              [22.263389588000052, 12.712795258000085],
              [22.265691757000184, 12.711203576000059],
              [22.277059554000061, 12.703351975000089],
              [22.285768509000093, 12.699760437000123],
              [22.286674500000174, 12.698021888000085],
              [22.286865234000118, 12.697652818000051],
              [22.287439346000042, 12.69655132400004],
              [22.292139053000142, 12.694260597000152],
              [22.350988388000076, 12.665509224000061],
              [22.351457595000113, 12.665279389000148],
              [22.359439849000125, 12.661378861000173],
              [22.365282058000048, 12.658823014000177],
              [22.38978004400019, 12.64810085400012],
              [22.408878326000149, 12.641060829000025],
              [22.416570664000176, 12.638231278000092],
              [22.451049805000082, 12.631190301000117],
              [22.458379746000162, 12.624129295000103],
              [22.458890915000097, 12.619771004000029],
              [22.450502396000161, 12.593709945000114],
              [22.448036193000121, 12.586048126000094],
              [22.441379547000167, 12.565361977000123],
              [22.437505722000026, 12.555089950000081],
              [22.43704032800008, 12.553858757000114],
              [22.420152664000057, 12.509069444000147],
              [22.414617538000186, 12.498381615000028],
              [22.412969590000102, 12.495201111000085],
              [22.412204742000029, 12.49471664400005],
              [22.410152436000089, 12.493418693000081],
              [22.407449722000081, 12.491708755000161],
              [22.405241013000023, 12.489149093000094],
              [22.395410537000032, 12.477751731000183],
              [22.376163483000028, 12.447769166000171],
              [22.374570846000154, 12.445289612000181],
              [22.372142791999977, 12.441511155000114],
              [22.371814727000128, 12.434686661000114],
              [22.371759414000053, 12.433520317999978],
              [22.373910905000173, 12.42862033900019],
              [22.375080109000123, 12.425951004000069],
              [22.378141403000086, 12.42367363000011],
              [22.383800506000171, 12.423444749000055],
              [22.387969971000075, 12.423279763000096],
              [22.393840789000024, 12.420331001000022],
              [22.401447295000025, 12.410734176000062],
              [22.404020309000089, 12.407487868999965],
              [22.408710480000138, 12.401569366000103],
              [22.421899796000048, 12.387849808000055],
              [22.423830032000012, 12.378898621000076],
              [22.41880989100008, 12.36069965400003],
              [22.418930054000157, 12.360129357000176],
              [22.41978073199999, 12.356109619000108],
              [22.425680161000059, 12.34832859100004],
              [22.425731658000188, 12.340228082000124],
              [22.425739288000102, 12.338681222000048],
              [22.42807579000015, 12.332784653000033],
              [22.429029465000099, 12.330380440000113],
              [22.430658341000026, 12.324311256000158],
              [22.43234062300013, 12.318040847000134],
              [22.434469223000065, 12.310112001000107],
              [22.436174393000101, 12.305654527000058],
              [22.442619324000191, 12.288808823000124],
              [22.443950652000183, 12.272041322],
              [22.465639113000123, 12.22855091100007],
              [22.475509643000066, 12.219589234000125],
              [22.477209091000191, 12.202659608000033],
              [22.482574462000116, 12.192774772000178],
              [22.483060837000096, 12.191880226000023],
              [22.486162185000012, 12.182243347000167],
              [22.488475799000128, 12.169836045000125],
              [22.488763808999977, 12.168287277000047],
              [22.489980699000171, 12.161761284000022],
              [22.492456437000158, 12.148483276999968],
              [22.491113662000032, 12.122508050000079],
              [22.490751267000121, 12.115501404000156],
              [22.490589142000147, 12.112380982000104],
              [22.48710823100015, 12.095097543000122],
              [22.486978531000091, 12.094448090000128],
              [22.486509324000053, 12.092120171000147],
              [22.487335205000079, 12.087684631000116],
              [22.488689423000039, 12.080411912000102],
              [22.48397636499999, 12.071401597000033],
              [22.483568191000188, 12.070623398000066],
              [22.483379364000086, 12.070260049000126],
              [22.48205185, 12.058980943000165],
              [22.471492767000086, 12.029719352000143],
              [22.469690323000123, 12.029300690000127],
              [22.457260132999977, 12.026412009000126],
              [22.466058731000032, 12.022006035000118],
              [22.471317292000037, 12.019371987000113],
              [22.47160339300018, 12.019366264000098],
              [22.478450776000159, 12.019232750000072],
              [22.491670608000106, 12.020500182000092],
              [22.512819289000106, 12.031949996000151],
              [22.517911910000066, 12.037051200000178],
              [22.522829056000091, 12.038716316000091],
              [22.524200440000072, 12.039179802000035],
              [22.531160354000065, 12.044988632000184],
              [22.536497116000191, 12.047096253000063],
              [22.551410675, 12.052980424000111],
              [22.557355881000035, 12.053639413000155],
              [22.558177947000047, 12.053730012000131],
              [22.560934066000073, 12.052404405000118],
              [22.563810348000061, 12.051020622000124],
              [22.565343858000062, 12.051087380000126],
              [22.57711982699999, 12.051601410000046],
              [22.582273484000041, 12.053932191000058],
              [22.586427688000185, 12.055810929000074],
              [22.59577941900011, 12.053791047000118],
              [22.611000062000073, 12.050509453000188],
              [22.620021820000147, 12.053265572000043],
              [22.629846573000066, 12.056266786000037],
              [22.634998322000115, 12.057841302000099],
              [22.638059615000031, 12.055560113000126],
              [22.638147354000068, 12.054883956000026],
              [22.639057159000117, 12.047760964000076],
              [22.635166167000079, 12.027456283000049],
              [22.635040284000013, 12.02679920200012],
              [22.63491249000009, 12.026292801000068],
              [22.622030259000155, 11.974940300000071],
              [22.611085891000187, 11.931264877999979],
              [22.597610475000067, 11.877499581000052],
              [22.596820831000116, 11.873970986000018],
              [22.591909408000049, 11.852020265000078],
              [22.581930160000013, 11.807415962000164],
              [22.577409743000032, 11.787210465000157],
              [22.573867797000048, 11.773531913999989],
              [22.56697463900008, 11.746918678000156],
              [22.562330246000158, 11.728989602000127],
              [22.559616089000031, 11.714137079000011],
              [22.556297302000132, 11.69598102600014],
              [22.554870605000133, 11.69433784600011],
              [22.554689408000115, 11.69412994400011],
              [22.55405044600019, 11.687231063000127],
              [22.566089630000135, 11.620661736000045],
              [22.576290131000121, 11.608321190000026],
              [22.578123092000169, 11.606101036000155],
              [22.59061050400004, 11.590978623000126],
              [22.593660355000054, 11.589399339000181],
              [22.596721648000141, 11.585694313000033],
              [22.596937180000054, 11.585433006000187],
              [22.600261689000035, 11.581411362000154],
              [22.602640153000038, 11.575680733000183],
              [22.609550477000141, 11.568331719000184],
              [22.610818864, 11.566982270000153],
              [22.636310578000177, 11.539871215000062],
              [22.638767243000132, 11.538449288000095],
              [22.642169953000121, 11.53647995100016],
              [22.657535553000116, 11.520358085999987],
              [22.662080766000031, 11.515588760000071],
              [22.662155151999968, 11.515505791000066],
              [22.695951463000029, 11.500019073000146],
              [22.70852088900017, 11.494261743000038],
              [22.713422774000151, 11.493148804000043],
              [22.734640121000155, 11.482735633000061],
              [22.741519928000173, 11.479359627000179],
              [22.754209518000096, 11.475111961000096],
              [22.761341095000148, 11.467885017000015],
              [22.761669160000054, 11.467554092000114],
              [22.773160933999975, 11.455909729000041],
              [22.777099610000107, 11.451926232000062],
              [22.777620315000092, 11.451399804],
              [22.787780761000192, 11.438349722999988],
              [22.789009094000164, 11.403180123000084],
              [22.85775947500008, 11.412948608000022],
              [22.866395950000083, 11.414172173000111],
              [22.871179581000035, 11.414849282000091],
              [22.875022889000093, 11.414911270000061],
              [22.899379730000192, 11.415301323000051],
              [22.921659470000122, 11.416899681000189],
              [22.932004929000186, 11.41764545500007],
              [22.938516617000175, 11.41811466200005],
              [22.945281982000097, 11.417244912000058],
              [22.947843551000062, 11.37750244200015],
              [22.949539184000116, 11.351175308000165],
              [22.951370239000028, 11.322799683000085],
              [22.952272414000106, 11.317783356000064],
              [22.953310013000078, 11.312007905000144],
              [22.959468841000046, 11.310540199],
              [22.963579179000135, 11.309559823000086],
              [22.964485169000113, 11.307683945000122],
              [22.965240479000101, 11.306120872],
              [22.982091904000072, 11.296129226],
              [22.984100343000023, 11.269160270000043],
              [22.984159470000066, 11.268320083000106],
              [22.986078263000081, 11.19960117300019],
              [22.985261917000059, 11.197387697000011],
              [22.981740952000166, 11.187851905000059],
              [22.977962493000064, 11.181841851],
              [22.977659226000128, 11.181361198000104],
              [22.971929550000141, 11.162462235000078],
              [22.966999054000041, 11.146201133000034],
              [22.966409684000098, 11.144256592000147],
              [22.965791702000161, 11.142210006000084],
              [22.962570191000168, 11.136901855000133],
              [22.94499015800011, 11.091020585000138],
              [22.940471649000131, 11.088750839000113],
              [22.938039780000054, 11.087529184000175],
              [22.922281266000027, 11.0431413660001],
              [22.91815948500016, 11.031531335000068],
              [22.911996842000178, 11.014156341000046],
              [22.910469055000135, 11.009848594000061],
              [22.909734726000067, 11.008899689000145],
              [22.907249452000087, 11.005689621000101],
              [22.904090881000172, 10.99256038600015],
              [22.888799667000058, 10.955200195999964],
              [22.888475417000052, 10.951112747000082],
              [22.888160705000132, 10.947150231000137],
              [22.881338120000066, 10.930899620000162],
              [22.865800857000181, 10.932150841000066],
              [22.853239059000146, 10.93117141700003],
              [22.847839355000133, 10.933912278000037],
              [22.847410203000152, 10.934129716000086],
              [22.842361451000045, 10.934184076000122],
              [22.817380905000107, 10.934451103000129],
              [22.775669098000037, 10.945281028000124],
              [22.773868561000029, 10.944973946000118],
              [22.77158737200017, 10.944584846000055],
              [22.768699647000062, 10.944091797000056],
              [22.761779785000044, 10.949159622000025],
              [22.761219024000127, 10.949570657000038],
              [22.759809493000091, 10.952941895000095],
              [22.75616455200003, 10.961634637000088],
              [22.756027221000124, 10.961961747000146],
              [22.744579314000191, 10.971098900000072],
              [22.739841462000015, 10.972665787000096],
              [22.734319686000049, 10.974491120000039],
              [22.710830688000044, 10.971610070000054],
              [22.703140260000055, 10.972260475000155],
              [22.697399139000083, 10.975391389000038],
              [22.695421218000149, 10.975277901000027],
              [22.694759369000167, 10.975240707000182],
              [22.690181733000145, 10.977155687000163],
              [22.682910919000051, 10.980197907000161],
              [22.681877136000026, 10.980172158000073],
              [22.678659440000047, 10.980087281000067],
              [22.655769348000092, 10.979479791000074],
              [22.632049561000088, 10.990389824000147],
              [22.612268447000133, 10.997040749],
              [22.607990264000136, 10.996865273000083],
              [22.604440690000047, 10.996719361000089],
              [22.583770750999975, 10.9902305600001],
              [22.577060699000072, 10.987700463000124],
              [22.576271057000156, 10.987044335000178],
              [22.573028565000072, 10.984350204000123],
              [22.568689347000031, 10.984190941000179],
              [22.560960770000179, 10.987880707000102],
              [22.552772521000065, 10.993650436999985],
              [22.552661896000075, 10.993728638000164],
              [22.542505264999988, 10.998124122000036],
              [22.534772873000065, 11.001470566000023],
              [22.520679475000179, 11.007570267000119],
              [22.509040832000039, 11.007268906000149],
              [22.497200012000178, 11.00306034099998],
              [22.49398040900013, 10.99728870500013],
              [22.490192414000148, 10.998039246000133],
              [22.486600876000182, 10.998749734000114],
              [22.476963044000172, 11.000640870000097],
              [22.47293472299998, 10.999953270000049],
              [22.468589782000151, 10.999210358000028],
              [22.464979171999971, 10.995129586000076],
              [22.464309693000132, 10.994373321000126],
              [22.46142959600013, 10.991119386000094],
              [22.456560135000188, 10.987869264000096],
              [22.444873810000047, 10.988389016000156],
              [22.444395065000094, 10.98840999700019],
              [22.442829132000156, 10.988480568000057],
              [22.442590713000016, 10.988300324000079],
              [22.433330537000188, 10.981289863000086],
              [22.430810929000074, 10.974379539000154],
              [22.42583847100002, 10.971529961000101],
              [22.424320222000119, 10.970660210000176],
              [22.41361045799999, 10.971739768000134],
              [22.402441024000098, 10.970750809000094],
              [22.388589860000138, 10.963974952000171],
              [22.38434982400014, 10.961900712000158],
              [22.381769181000038, 10.96441459700003],
              [22.3771572120001, 10.968909263000171],
              [22.376710891000016, 10.969350816000031],
              [22.37662124700006, 10.969440460000158],
              [22.371274949000053, 10.960243225000056],
              [22.370180130000165, 10.958358766],
              [22.363796234000063, 10.95749187500013],
              [22.355989455000099, 10.956431388000055],
              [22.326900482000099, 10.955780983000182],
              [22.323659897000141, 10.954381942000111],
              [22.313020707000192, 10.944191932000081],
              [22.298187256000062, 10.935820580000041],
              [22.293319701000144, 10.933235169000056],
              [22.289840698000148, 10.931387902000097],
              [22.282169340999985, 10.930648805000089],
              [22.274261475000117, 10.927005767000139],
              [22.265609740000059, 10.923021317000178],
              [22.251310348000118, 10.926220895000029],
              [22.245269775000111, 10.92547130500003],
              [22.236280441000076, 10.917560577000074],
              [22.222370149000085, 10.91283130700009],
              [22.206563950000088, 10.911207198999989],
              [22.204940797000177, 10.911040306000132],
              [22.203866959000152, 10.910757065000041],
              [22.195537567000031, 10.908559800000091],
              [22.182750702000135, 10.900081635000163],
              [22.181095122000102, 10.896741866000127],
              [22.174680709000029, 10.883798599999977],
              [22.158733367000025, 10.880690574000027],
              [22.157218933000138, 10.87967300400004],
              [22.156595229000061, 10.879253387000063],
              [22.156070709000176, 10.878899575000105],
              [22.154842377000136, 10.878070831000116],
              [22.15180015500016, 10.876021385000172],
              [22.1443595880001, 10.878329278000081],
              [22.120580674000109, 10.881461143000138],
              [22.118967056000031, 10.882146835000185],
              [22.116840363000165, 10.88305187200001],
              [22.116004944000053, 10.882610321000186],
              [22.113811492000139, 10.881447792000188],
              [22.11198043800016, 10.88047885900005],
              [22.109542847000171, 10.880541801999982],
              [22.108842849000155, 10.880560875000072],
              [22.10499954200003, 10.880661010000097],
              [22.098699569000019, 10.88291072800007],
              [22.088390351000044, 10.89134121],
              [22.081617355999981, 10.894971848000068],
              [22.079402924000078, 10.895227433000173],
              [22.07760238700007, 10.895434380000154],
              [22.074159621000149, 10.895831109000028],
              [22.07168007000007, 10.894561767000141],
              [22.060029984000153, 10.888599397000121],
              [22.059234619000165, 10.88864135700004],
              [22.048620224000047, 10.889201165000145],
              [22.041679382000098, 10.884321214000067],
              [22.039806366000164, 10.883921623000106],
              [22.036102295000035, 10.88313198100019],
              [22.030151367000087, 10.885312081000052],
              [22.029342651000093, 10.885608674000025],
              [22.027801514000032, 10.885721207000188],
              [22.026008605000129, 10.885850906000144],
              [22.02071952700004, 10.886231423000083],
              [22.016540527000132, 10.885050773000103],
              [22.001750946000072, 10.873440742000071],
              [21.998283386000026, 10.873541832000058],
              [21.996582031000059, 10.87359237700008],
              [21.989412308, 10.873800279000079],
              [21.98712539800016, 10.870428086],
              [21.982519150000087, 10.86363124900015],
              [21.979957580000018, 10.863331796000125],
              [21.978330612999969, 10.8631410590001],
              [21.97084045500003, 10.868371964000062],
              [21.965072631999988, 10.870322228000134],
              [21.962495804000127, 10.871192932000042],
              [21.962209701000177, 10.871289254000033],
              [21.963470459000064, 10.860260964000076],
              [21.962450027000159, 10.859602928000186],
              [21.960229873000173, 10.858171464],
              [21.954307555000071, 10.85739040500016],
              [21.952789306000113, 10.857191086000114],
              [21.951908112000012, 10.857887268000127],
              [21.949039458000186, 10.860151292000069],
              [21.944549560000098, 10.869077682000011],
              [21.941520691000051, 10.869290352],
              [21.94260787900015, 10.859573365000131],
              [21.942800521000095, 10.857851029000074],
              [21.942842483000163, 10.857453347000046],
              [21.943027496000127, 10.855731965000075],
              [21.941953658999978, 10.854482651000069],
              [21.940040589000148, 10.852260589000082],
              [21.933980942000176, 10.853359223000041],
              [21.932640075000165, 10.854757309000092],
              [21.927410125000165, 10.860210419000055],
              [21.923831940000127, 10.866744041000118],
              [21.921020508000026, 10.871879579000108],
              [21.91264152500014, 10.872499465000089],
              [21.908512116000168, 10.866032600000153],
              [21.904348374000108, 10.863239289000035],
              [21.901182175000031, 10.86293125200001],
              [21.899227142000029, 10.862741471000049],
              [21.887300490000143, 10.870229721000044],
              [21.883443832000069, 10.870058061000066],
              [21.880790710000099, 10.869939805000058],
              [21.878250121000121, 10.867860795000126],
              [21.872800827999981, 10.856844902000034],
              [21.871620178, 10.854459763000136],
              [21.871257781000168, 10.854018212000085],
              [21.865169526000102, 10.846598626000116],
              [21.858274460000132, 10.845066070000144],
              [21.856580733000101, 10.844689370000083],
              [21.855552673000091, 10.844930649000048],
              [21.849159240000176, 10.846430779000173],
              [21.846330642999988, 10.847087860000102],
              [21.844430924000108, 10.847529412000029],
              [21.843805312000029, 10.845279695000158],
              [21.84170913600019, 10.837748526999974],
              [21.837919236000118, 10.838471413000093],
              [21.832620620000114, 10.839479446000098],
              [21.825756074000026, 10.831705094000085],
              [21.819299699000055, 10.824391366000157],
              [21.818305969999983, 10.821666718000131],
              [21.818149567000148, 10.821239471000013],
              [21.817417145000093, 10.819254875000127],
              [21.814117431000113, 10.810311318000117],
              [21.809949875000086, 10.809109688000149],
              [21.802591323000172, 10.802130700000021],
              [21.799175263000109, 10.800914766000119],
              [21.791240692000088, 10.798089981000089],
              [21.788488388000076, 10.794610023000075],
              [21.788110732000064, 10.787481309000043],
              [21.790437699000165, 10.778165817],
              [21.790800096000055, 10.776709557000117],
              [21.785850524000125, 10.762859345000095],
              [21.785551072000032, 10.760698318999971],
              [21.784349441000188, 10.752039910000065],
              [21.788442613000086, 10.740360261000149],
              [21.78970909200018, 10.731869698000082],
              [21.789085388000103, 10.730080604000023],
              [21.786329269000021, 10.722181321000051],
              [21.78791999800012, 10.713270187000091],
              [21.787517547000107, 10.711371422000127],
              [21.786851883000111, 10.70823097300007],
              [21.786506652000071, 10.708023071000071],
              [21.78338050800005, 10.706131935000087],
              [21.783245087000125, 10.704373359999977],
              [21.783170701000188, 10.703372002000094],
              [21.786621095000044, 10.698077202000036],
              [21.788829803000169, 10.694689751000169],
              [21.788942336000162, 10.691994667000131],
              [21.789121627000156, 10.687790870000185],
              [21.787122726000064, 10.677660943000035],
              [21.788589477000187, 10.669401168000093],
              [21.786609649000127, 10.667848588000027],
              [21.782360078000181, 10.664519310000117],
              [21.780780791000154, 10.659220696000091],
              [21.770360946000153, 10.653841019000026],
              [21.765338898000095, 10.647559167000054],
              [21.764619827000047, 10.646659850000162],
              [21.758609772000057, 10.642240525000147],
              [21.753030776000116, 10.64264869699997],
              [21.751899720000154, 10.643443107000166],
              [21.748819351000122, 10.645608903000152],
              [21.740589143000022, 10.655880927000055],
              [21.740238189000081, 10.655896187999986],
              [21.736986159000139, 10.656036377000135],
              [21.736169816000029, 10.656070709000119],
              [21.73362731900005, 10.653980256000125],
              [21.733259202000056, 10.64840126100006],
              [21.733003617000122, 10.644499779000057],
              [21.732000351000067, 10.629139901000144],
              [21.733320237000044, 10.61168193899999],
              [21.729867935000073, 10.603801727000075],
              [21.726949691000186, 10.59714126700004],
              [21.727294922000056, 10.593648911000116],
              [21.728990554000177, 10.57646942100007],
              [21.733079911000061, 10.56133174900009],
              [21.733047485000043, 10.559888839000109],
              [21.732919693000156, 10.554208755000161],
              [21.730117797000105, 10.540769578000095],
              [21.729799270000115, 10.539241791000052],
              [21.721300123999981, 10.528821946000107],
              [21.721029281000085, 10.527319908000152],
              [21.717540740000175, 10.507869721000134],
              [21.718290328000023, 10.505550385000049],
              [21.719690323000123, 10.501220703000115],
              [21.732690810000122, 10.477890014000025],
              [21.744039535000013, 10.469809533000159],
              [21.745613098000092, 10.468436241],
              [21.750852584000029, 10.46385955900007],
              [21.752550126000131, 10.4583597190001],
              [21.751449584000056, 10.452601433999973],
              [21.756937027000106, 10.445896150000124],
              [21.758529663000104, 10.443948746000103],
              [21.758434296000132, 10.441287041000066],
              [21.758350372999985, 10.438888549000012],
              [21.756946564000089, 10.434933664000084],
              [21.754089356000122, 10.426890374000038],
              [21.756029129000126, 10.423697472000129],
              [21.758110046000127, 10.420270919000075],
              [21.765470504000177, 10.418850900000052],
              [21.770450592000088, 10.417888642000037],
              [21.770990372000028, 10.411689759000069],
              [21.766630173000124, 10.404029846000185],
              [21.763277054000071, 10.400869370000123],
              [21.74294090300009, 10.381698609000125],
              [21.741329193000126, 10.372490883000182],
              [21.741275788000053, 10.372180938000042],
              [21.740409852000028, 10.36720085200011],
              [21.740472793000151, 10.365118027000165],
              [21.740800858000057, 10.35433101700005],
              [21.728000641000108, 10.356711388000122],
              [21.72400283800016, 10.348773004000179],
              [21.723140718000082, 10.347061158000145],
              [21.723470688000191, 10.342650414000047],
              [21.723880768000186, 10.341814040000088],
              [21.724199295000176, 10.341159819999973],
              [21.729627609000033, 10.33006095900015],
              [21.737379075000149, 10.32024192900019],
              [21.734878540000125, 10.313785554000049],
              [21.734430314000178, 10.312629700000173],
              [21.73169517600013, 10.309876441000029],
              [21.73028946, 10.30846119000006],
              [21.728260040000123, 10.301770211000019],
              [21.724580765000098, 10.297828674000129],
              [21.71949005200014, 10.295721055000115],
              [21.715862274000187, 10.289490701000091],
              [21.709669114000064, 10.278862],
              [21.705507279000074, 10.276290895000045],
              [21.693897247000109, 10.275960922000024],
              [21.688503265, 10.277297973999964],
              [21.682270050000056, 10.27884197200018],
              [21.677022934000036, 10.27817154000013],
              [21.676469803000032, 10.278100967000057],
              [21.674650193000105, 10.274411202000067],
              [21.675611496000158, 10.271199226000022],
              [21.678556441000183, 10.268331527000157],
              [21.681999207000104, 10.264980316999981],
              [21.682638168000153, 10.264370919000044],
              [21.680339812000057, 10.261590958000113],
              [21.675260544000082, 10.259011268000052],
              [21.669540406000181, 10.25805282500005],
              [21.663669586000083, 10.257069589000025],
              [21.660692214000107, 10.253138542000158],
              [21.66304016100014, 10.249480248000111],
              [21.665487289000112, 10.248897554000052],
              [21.668630601000132, 10.248150826000085],
              [21.673789978000059, 10.241990090000172],
              [21.675512314000116, 10.238047600000129],
              [21.675689697000109, 10.237639426000101],
              [21.673929214000054, 10.235318185000153],
              [21.672708512000099, 10.233710289000157],
              [21.668319702000076, 10.231829644000072],
              [21.649490357000104, 10.233961106000038],
              [21.644786834, 10.230234147000147],
              [21.64097023000005, 10.22721004499999],
              [21.636802674000023, 10.226719857000148],
              [21.636219025000173, 10.22681522400012],
              [21.632837296000048, 10.227370262000022],
              [21.628681184000072, 10.230450630000178],
              [21.627939224000102, 10.230999947000043],
              [21.622360230000027, 10.231410980000078],
              [21.619518280000079, 10.228141786000094],
              [21.61088180500019, 10.21821022099999],
              [21.610401153000055, 10.21802425400017],
              [21.597467422000079, 10.213028908000013],
              [21.595178604000068, 10.212877273],
              [21.589349746000039, 10.212491035000028],
              [21.588670731000093, 10.211340904000167],
              [21.584688187000097, 10.214750291000087],
              [21.581417084000066, 10.214984894000054],
              [21.579109191000157, 10.215150833],
              [21.572059631000059, 10.213267327000153],
              [21.56129074100005, 10.210390092000182],
              [21.553819656000144, 10.214229584000066],
              [21.547679901000095, 10.225199701000122],
              [21.54727935700015, 10.225493432000178],
              [21.545846940000047, 10.226537704000066],
              [21.543939591000026, 10.227930069000081],
              [21.536750794000056, 10.226941110000041],
              [21.522939682000072, 10.215540886000042],
              [21.522518157000093, 10.214765549000106],
              [21.511510849000047, 10.194570541000189],
              [21.510158537999985, 10.189986228000066],
              [21.510120391000157, 10.189860345],
              [21.504510880000055, 10.187141420000046],
              [21.503807068000128, 10.187131882000131],
              [21.493837358000121, 10.187007903999984],
              [21.491905212000177, 10.17590522800009],
              [21.491029739000112, 10.170881271000155],
              [21.490980149000109, 10.170788766000044],
              [21.487630843999966, 10.164633750000064],
              [21.481332779000127, 10.163432121000028],
              [21.481159210999976, 10.163398742000027],
              [21.475849152000023, 10.158861160000129],
              [21.468082428000116, 10.152210236000144],
              [21.471139907, 10.148571015000186],
              [21.47128868100009, 10.14722824200004],
              [21.471668244000114, 10.143750191000095],
              [21.470064164000121, 10.139201164000042],
              [21.468982696000126, 10.136131287000126],
              [21.46850776600013, 10.135601045000158],
              [21.462118149000162, 10.128461838000021],
              [21.457349778000093, 10.12742233400013],
              [21.455410004000157, 10.126998902000082],
              [21.452859878000027, 10.125413895000122],
              [21.450120927000114, 10.123709680000104],
              [21.448331833000054, 10.118630410000094],
              [21.449277877000043, 10.116414071000122],
              [21.450969696000129, 10.112460138000131],
              [21.452898025000081, 10.11122036],
              [21.453769683000075, 10.110659599000087],
              [21.454576493000047, 10.107451438000112],
              [21.45475006099997, 10.106760026000075],
              [21.454174042000034, 10.10496902400007],
              [21.452520371000048, 10.099841119000075],
              [21.455009461000145, 10.086999892999984],
              [21.454244613000071, 10.085088730000052],
              [21.453439712000034, 10.083080291000044],
              [21.445089341000028, 10.082969666000054],
              [21.443201065000096, 10.081882477000079],
              [21.441408158000172, 10.080847741000071],
              [21.441022873000179, 10.080028533000132],
              [21.437799454000185, 10.073220254000034],
              [21.438034058000085, 10.065110207000032],
              [21.438102721000178, 10.062790871000118],
              [21.437490463000131, 10.059351922000076],
              [21.43341255100006, 10.054570198000022],
              [21.433139801000038, 10.054250718000048],
              [21.432039261999989, 10.048720361000164],
              [21.429870605000076, 10.04740715000014],
              [21.429729461000136, 10.047321320000151],
              [21.420419693000042, 10.050219536000156],
              [21.416612624000095, 10.048587800000121],
              [21.416030884000122, 10.048338891000071],
              [21.411586761000024, 10.044465064000065],
              [21.407760619000157, 10.041130066000164],
              [21.406877517999988, 10.039688110000043],
              [21.40365028400015, 10.034420014000091],
              [21.403001786000118, 10.030051231000186],
              [21.403501510000126, 10.02710437799999],
              [21.404479981000122, 10.021330833000036],
              [21.403432846000101, 10.019201278000139],
              [21.40221023600003, 10.01671123599999],
              [21.395990373000075, 10.012970924000172],
              [21.393819809000092, 10.001397133000069],
              [21.393789292000122, 10.001232147999986],
              [21.392808914, 10.000163078000185],
              [21.392660142000125, 10.00000095300004],
              [21.38507080100004, 9.99172306000014],
              [21.38045311000019, 9.987872125000138],
              [21.377260208000109, 9.985209465000082],
              [21.376342774000079, 9.984877587000142],
              [21.373329163000051, 9.983792306000169],
              [21.369148255000141, 9.984437944000092],
              [21.364227294000045, 9.987728118000177],
              [21.363065720000179, 9.988508224999976],
              [21.362379075000149, 9.988967896000077],
              [21.360717772999976, 9.986771583000177],
              [21.359399796000105, 9.985029221000104],
              [21.354690552000022, 9.97027397200003],
              [21.35201454200012, 9.968523979999986],
              [21.347549438000158, 9.965603829000088],
              [21.343788147000055, 9.96457004500013],
              [21.340610505000029, 9.963697434000153],
              [21.336420059000091, 9.965262413000062],
              [21.330089570000041, 9.971864700000026],
              [21.32555198700004, 9.971095086000105],
              [21.324300766000135, 9.970883369000092],
              [21.322631834999981, 9.971525192000172],
              [21.320810318000042, 9.972227097000086],
              [21.320383072000027, 9.972640991000048],
              [21.319400787000177, 9.973589898000114],
              [21.317802429000096, 9.981242179000105],
              [21.31695938200005, 9.98528671300005],
              [21.309490204000042, 9.990036011000086],
              [21.307075501000043, 9.981508254000175],
              [21.304790497000113, 9.973442078000119],
              [21.302038193000158, 9.96957779000013],
              [21.301820755000108, 9.969272613000044],
              [21.29788970900006, 9.968084335000128],
              [21.294551849000129, 9.971110344000124],
              [21.294380189000151, 9.971264839000014],
              [21.293342590000179, 9.973823547000166],
              [21.292709351000099, 9.975385667000126],
              [21.288303374000122, 9.976996423000173],
              [21.287820816000192, 9.977172851000034],
              [21.287073136000117, 9.978390693000051],
              [21.286949158000027, 9.978594780000151],
              [21.286483764000025, 9.979351998000084],
              [21.282630921000191, 9.985622407000108],
              [21.272012711000059, 9.989949227000011],
              [21.267858506000152, 9.991641044000119],
              [21.262832641000045, 9.993688585000143],
              [21.261177063000048, 9.993065833000117],
              [21.254049301, 9.990381240000147],
              [21.251310348000175, 9.98644447300012],
              [21.249351500999978, 9.97493267100009],
              [21.24691772500006, 9.972821237],
              [21.242919922000112, 9.969350815000098],
              [21.242500305000135, 9.965439796000055],
              [21.23744964600013, 9.960330009000131],
              [21.234186173000012, 9.957903863000126],
              [21.233997345000034, 9.957763672000169],
              [21.217212678000124, 9.952153206000105],
              [21.214830399000107, 9.951356889000067],
              [21.212549208999974, 9.94765567800016],
              [21.213319778000084, 9.940768242000161],
              [21.212751389, 9.938929559000144],
              [21.211750030000019, 9.935696602000064],
              [21.205505372000118, 9.93227768],
              [21.202760696000041, 9.93077373400007],
              [21.202726364, 9.930700303000151],
              [21.202529907000041, 9.930279731000155],
              [21.200729369999976, 9.926389694000079],
              [21.204399110000054, 9.908731461000173],
              [21.203090668000129, 9.901362419000122],
              [21.198980331000143, 9.89534282700015],
              [21.192617416000076, 9.891141892000064],
              [21.190109253000116, 9.890007019000052],
              [21.18970680200016, 9.890119552000044],
              [21.186969758000146, 9.890885353000101],
              [21.183830261000026, 9.89176273400011],
              [21.183042527000055, 9.890849113000115],
              [21.177217484000096, 9.884085656000138],
              [21.16950988800005, 9.878599167000118],
              [21.152200699000105, 9.866276742000082],
              [21.133068085000104, 9.847903251000162],
              [21.122329712000067, 9.832082749000165],
              [21.117429732000176, 9.818685532000018],
              [21.116369247000137, 9.811780931000101],
              [21.117959977000112, 9.802066803000173],
              [21.118030548000149, 9.801636696],
              [21.118078232000187, 9.801333427999964],
              [21.115310668000177, 9.79194545900009],
              [21.114789963000192, 9.791481972000042],
              [21.107740403000093, 9.785199165000051],
              [21.092540741000107, 9.776757240000109],
              [21.08823204100014, 9.776576996000131],
              [21.085130691000188, 9.776447297000118],
              [21.079069137000147, 9.778908730000126],
              [21.07420921400012, 9.778623581999966],
              [21.06324005100015, 9.765404702000126],
              [21.059616088000041, 9.76617622399999],
              [21.058130264000056, 9.766491891000101],
              [21.049249650000149, 9.772830010000064],
              [21.04040527300009, 9.776181220000069],
              [21.039239883000164, 9.776622773000099],
              [21.030460357000095, 9.77399730799999],
              [21.025419235000072, 9.769343377000155],
              [21.023847580000108, 9.764960289000044],
              [21.02434158300008, 9.761813164000159],
              [21.02485084599999, 9.758535386],
              [21.031883241, 9.749342918000082],
              [21.038270949999969, 9.740992546000143],
              [21.038539886000024, 9.737550735000013],
              [21.036291123000069, 9.732010843000126],
              [21.032909394000114, 9.729948998000168],
              [21.030530930000111, 9.728499413000066],
              [21.028816221999989, 9.728521347000083],
              [21.02103042499999, 9.728619576000142],
              [21.017913819000057, 9.729656220000095],
              [21.010330200999988, 9.732176781000021],
              [20.994989395000061, 9.7470464700001],
              [20.991529465000156, 9.734949113000084],
              [20.993009567000058, 9.727153778000172],
              [21.000789642000143, 9.71575164799998],
              [21.004390717000092, 9.705682754000179],
              [21.001880645000085, 9.70197868300005],
              [20.981588364000174, 9.701302529000031],
              [20.98081016499998, 9.701276780000114],
              [20.973779678000142, 9.705123901000036],
              [20.97351074300019, 9.705271722000077],
              [20.972179413000163, 9.706003189000114],
              [20.971942902000023, 9.705981255000097],
              [20.969400405999977, 9.705741882000098],
              [20.968444823000084, 9.703647614000033],
              [20.967817306000086, 9.702277184000138],
              [20.969730376000143, 9.697703362000084],
              [20.971735, 9.696686745000079],
              [20.974159241, 9.695458412000107],
              [20.982040405000191, 9.694604875000096],
              [20.987609864000149, 9.694002152000053],
              [20.988513946000126, 9.693517684000142],
              [20.993909836, 9.690629006000052],
              [20.998609542000054, 9.684936524000136],
              [20.994970321000096, 9.678920745000028],
              [20.981470109000043, 9.665439606000064],
              [20.98105049100019, 9.661069871000052],
              [20.985960007000131, 9.657446861000153],
              [20.992864608000048, 9.655048371000135],
              [20.99364089900007, 9.654777526000032],
              [20.996240616000136, 9.650213242000177],
              [20.9943447120001, 9.646693230000096],
              [20.993749619000027, 9.645590783000102],
              [20.989360809000118, 9.644083023],
              [20.98890113900012, 9.643924713000104],
              [20.986553192000088, 9.644147873000065],
              [20.982412338000131, 9.644541741000126],
              [20.975189209000121, 9.647904397000048],
              [20.970932007000158, 9.647251129000154],
              [20.970329284000115, 9.647158624000042],
              [20.969915390000153, 9.646548271000086],
              [20.969860077000078, 9.646466255000064],
              [20.96980857900013, 9.646389961000125],
              [20.967149735000191, 9.642526627000052],
              [20.968082429000106, 9.63999176100009],
              [20.968578339000089, 9.638636590000147],
              [20.970016479000094, 9.637347222000017],
              [20.971860886000172, 9.635689737000121],
              [20.983226776000038, 9.634641648000184],
              [20.987400053999977, 9.634256362000087],
              [20.990730285000097, 9.626712799000075],
              [20.98801994300004, 9.620709420000082],
              [20.98346328700012, 9.619034767000187],
              [20.978330612000093, 9.617149353000173],
              [20.969646454000099, 9.61819744200011],
              [20.960939407000069, 9.619248391000099],
              [20.957529067000166, 9.61896610299999],
              [20.954460143000063, 9.618713379000042],
              [20.951843263000114, 9.616663933000154],
              [20.951480866000054, 9.616380692000064],
              [20.950120927000057, 9.613837243000035],
              [20.955310821000126, 9.586784363000049],
              [20.954280852000068, 9.575974464000126],
              [20.953130723000072, 9.572519302000046],
              [20.951599121000129, 9.567901613000174],
              [20.944240569000044, 9.563450814000021],
              [20.936740875, 9.570715905000157],
              [20.931159974000025, 9.572261810000043],
              [20.927738191000117, 9.571154595],
              [20.927467346000185, 9.57106685600013],
              [20.926504135000187, 9.56895065399999],
              [20.924312593000025, 9.564137459000108],
              [20.924381256000117, 9.558553695000114],
              [20.924438476000034, 9.553800582000065],
              [20.919450760000188, 9.54500961299999],
              [20.919040681000126, 9.540640831000019],
              [20.920633316000135, 9.536577225000087],
              [20.921909333000144, 9.53332328800002],
              [20.919420243000047, 9.528468132000114],
              [20.915290832999972, 9.525432588000172],
              [20.911231995000094, 9.526757240000165],
              [20.906457901000124, 9.528314590000036],
              [20.899282455000105, 9.528000833000135],
              [20.897319793000065, 9.527153016000057],
              [20.894910813000024, 9.526110649000032],
              [20.88450050400013, 9.525581360000047],
              [20.877317427999969, 9.525216103000105],
              [20.874763489000145, 9.523474693000082],
              [20.874572754000098, 9.523344995000173],
              [20.874502181000025, 9.523100853000074],
              [20.873680115000013, 9.520115853000164],
              [20.876520156000083, 9.515095711000129],
              [20.876308441000106, 9.514714241000149],
              [20.874477387000127, 9.511395455000184],
              [20.868749619000141, 9.507192613000086],
              [20.867780686000174, 9.5054969790001],
              [20.863990784000123, 9.498864175000051],
              [20.865480423000179, 9.491893769000058],
              [20.866439819000163, 9.487404823000077],
              [20.864645004000067, 9.485899925000126],
              [20.862552644000118, 9.484143258000131],
              [20.861896515000126, 9.48398876300007],
              [20.855390548000116, 9.4824495310001],
              [20.849569320000057, 9.485597611000117],
              [20.844718933000081, 9.484389304999979],
              [20.839450836000026, 9.479961395000089],
              [20.832477569000105, 9.470410347000154],
              [20.826929092000114, 9.462807656000166],
              [20.825473786000146, 9.454923631000042],
              [20.824760438000112, 9.451066018000176],
              [20.827669144000083, 9.440531732000011],
              [20.827329636000059, 9.43042183],
              [20.822788238000157, 9.429312706000076],
              [20.820430755000075, 9.428737641],
              [20.818975449000106, 9.428383828000165],
              [20.817411423000181, 9.428004265000084],
              [20.8044528960001, 9.442530633000104],
              [20.804100037000126, 9.442926407000186],
              [20.804029464000052, 9.443004608000024],
              [20.798240662000183, 9.443394661000014],
              [20.793170930000088, 9.44172573000003],
              [20.790447235000102, 9.43910408000005],
              [20.780359267000108, 9.429391862000045],
              [20.768112183000028, 9.428094864000059],
              [20.767000199, 9.424633027000027],
              [20.768871307000097, 9.418136597000057],
              [20.769899367000107, 9.414561272000071],
              [20.768140792000167, 9.407876969000085],
              [20.760810851000031, 9.401125908000097],
              [20.755485534000059, 9.399687768000092],
              [20.753671647000147, 9.399197578000042],
              [20.748109818000103, 9.399359703000073],
              [20.745069504000128, 9.401847839000027],
              [20.743253708000168, 9.402006149000158],
              [20.740430831000083, 9.402252197999985],
              [20.73354721100003, 9.397342683000147],
              [20.732099533000167, 9.397108077000098],
              [20.730379105000111, 9.396828651000078],
              [20.728939055000126, 9.39659595500018],
              [20.719150544000172, 9.401987076000125],
              [20.717458725000085, 9.402090073000181],
              [20.712890625999989, 9.402367591000029],
              [20.708011627000189, 9.397849084000029],
              [20.705350875000136, 9.395384789000161],
              [20.701179504000038, 9.395560265000086],
              [20.691875458000084, 9.39912033100012],
              [20.68975067100007, 9.399053573000117],
              [20.688550949999978, 9.399017334000064],
              [20.666889190000063, 9.398347855000168],
              [20.663322448000145, 9.3957147590001],
              [20.662769317000141, 9.395306588000153],
              [20.661947251000129, 9.393169403000172],
              [20.652019501000098, 9.367374420999965],
              [20.650209426000174, 9.346905709000168],
              [20.64965057500001, 9.345870971000181],
              [20.64772033700018, 9.342277528000125],
              [20.641567231000181, 9.3400077820001],
              [20.641279220000058, 9.339901924],
              [20.634677886000134, 9.340514183000153],
              [20.630390166999973, 9.340910911000094],
              [20.626249313000017, 9.339021683000112],
              [20.623634338000102, 9.334791184000096],
              [20.623529434000147, 9.334621428999981],
              [20.625200272000029, 9.330965043000163],
              [20.628469467000059, 9.328477859000088],
              [20.632709503000058, 9.327458381000099],
              [20.637519836000081, 9.326299667000171],
              [20.640571594000164, 9.323120117000144],
              [20.638240815000131, 9.318551063000143],
              [20.637859344999981, 9.317803383000069],
              [20.604339599000184, 9.31645584000006],
              [20.602561951000155, 9.317742348000081],
              [20.599660873000118, 9.319841386],
              [20.596019744000046, 9.326251029000048],
              [20.594219206999981, 9.329420090000156],
              [20.588825225000107, 9.332411766000064],
              [20.58815193099997, 9.332785606000073],
              [20.586612700999979, 9.335660935000021],
              [20.584119797000142, 9.340316773000154],
              [20.583812714000032, 9.340549469000052],
              [20.582761765000043, 9.341348649000111],
              [20.580850601000179, 9.342801095000027],
              [20.579317094000032, 9.342907905000118],
              [20.578069688000028, 9.342994691000058],
              [20.575780868000038, 9.341355324],
              [20.571489335000138, 9.333490371000039],
              [20.567369462000045, 9.330220223000026],
              [20.565111161000175, 9.330037117000131],
              [20.560659410000142, 9.32967567500009],
              [20.557226181000033, 9.33184146900004],
              [20.548530579000158, 9.33732795800006],
              [20.543489455000156, 9.333585740000046],
              [20.542463302000044, 9.333486558000175],
              [20.540718078000054, 9.333319665000147],
              [20.534646988000077, 9.33737373299999],
              [20.528640747000054, 9.337065696000025],
              [20.524814605000017, 9.332019807000052],
              [20.52477073600005, 9.331960679000133],
              [20.526147843000047, 9.325470925000161],
              [20.527490616000023, 9.319131851],
              [20.527198792000036, 9.316415786000107],
              [20.526872634000085, 9.313381196000023],
              [20.523937225999987, 9.307601928000054],
              [20.518199920000143, 9.304538726999965],
              [20.50826835700002, 9.303259849000028],
              [20.495782852000048, 9.307106972000099],
              [20.496149062000143, 9.305282594000175],
              [20.496219634999989, 9.304937363000079],
              [20.491239547000077, 9.296830178000164],
              [20.491327286000114, 9.295430183000065],
              [20.491529465000099, 9.292240143000072],
              [20.497730256000011, 9.278537750999988],
              [20.497791291, 9.277329444000145],
              [20.498050688999967, 9.272110940000175],
              [20.48274993900003, 9.241126060000113],
              [20.48206901500015, 9.240036964000069],
              [20.47030067500009, 9.221203804000027],
              [20.469121933000054, 9.217294694000032],
              [20.468048095000029, 9.213738442000135],
              [20.456243515000153, 9.174539564999975],
              [20.455919267000127, 9.17346477500007],
              [20.450252534000128, 9.165579795000099],
              [20.445901870000057, 9.161457062000125],
              [20.443859099000065, 9.159519196000019],
              [20.434232713000085, 9.153878212000109],
              [20.415557861000138, 9.153446197000164],
              [20.410419465000075, 9.153326989000107],
              [20.408199311000089, 9.152976990000184],
              [20.392890931000181, 9.150562287000128],
              [20.388370514000144, 9.14865493700006],
              [20.38783073400009, 9.148427009000045],
              [20.38678169200017, 9.147529602000134],
              [20.381759644000113, 9.143237113000112],
              [20.378763199000048, 9.140666963000115],
              [20.378009796000185, 9.140021324999964],
              [20.356695175000084, 9.129861832000017],
              [20.353200913000137, 9.128197671000123],
              [20.351142884000126, 9.128032684000061],
              [20.346271514000136, 9.127642632000118],
              [20.344074248000027, 9.128933905999986],
              [20.336719513000162, 9.133255005000024],
              [20.331869126000072, 9.132497788000023],
              [20.324569702000076, 9.125506402000099],
              [20.318130493000069, 9.123579980000045],
              [20.304819106000139, 9.12173652600012],
              [20.303358078000031, 9.121535301000051],
              [20.302833558000145, 9.121550560000173],
              [20.281419755, 9.122212411000135],
              [20.275428772, 9.122122764000096],
              [20.273637772000143, 9.122096062000026],
              [20.265220643000134, 9.123887062000051],
              [20.262409209000054, 9.124486924000109],
              [20.250226975000146, 9.130938530000151],
              [20.247709275000034, 9.13227176700002],
              [20.238689423000153, 9.145898817999978],
              [20.229419708000137, 9.147118568000053],
              [20.221712112000091, 9.151529312000093],
              [20.221393585000101, 9.151712418000159],
              [20.22123909000004, 9.151800157000025],
              [20.218351364000057, 9.151558876000081],
              [20.207397461000085, 9.150644303000149],
              [20.201877595000099, 9.146714211000074],
              [20.191190719000133, 9.139106750000053],
              [20.183589936000146, 9.138050080000028],
              [20.179889680000031, 9.137535096000079],
              [20.152761459000146, 9.138971329000128],
              [20.148866653000084, 9.1402311330001],
              [20.147480011000141, 9.140680313000132],
              [20.145175933000075, 9.139945031000082],
              [20.139190675000123, 9.138033867000047],
              [20.130861282000069, 9.138135909000141],
              [20.130638122000107, 9.138138772000104],
              [20.127981185000067, 9.139021873000047],
              [20.117391586000053, 9.142539978000116],
              [20.113180161000059, 9.145693779000169],
              [20.109369278000145, 9.152400970000088],
              [20.108909606000111, 9.153210641000044],
              [20.104930877000186, 9.156367301000103],
              [20.101459502000068, 9.156547547000116],
              [20.100984572000073, 9.156145096000103],
              [20.093469620000178, 9.149770736999983],
              [20.088079453000148, 9.138895035000019],
              [20.0814609520001, 9.133283615000096],
              [20.071300506000171, 9.132445335],
              [20.057750700999975, 9.14166259700005],
              [20.052650452000137, 9.142505646000075],
              [20.05048942600007, 9.141694068999982],
              [20.048320770000032, 9.14088058499999],
              [20.046909333000031, 9.140351296000176],
              [20.037647247, 9.130289079000136],
              [20.035762786000134, 9.128241538000111],
              [20.031049728000085, 9.127266884999983],
              [20.029769898000097, 9.127002715000174],
              [20.025020600000062, 9.127309798999988],
              [20.018199921000132, 9.127750397000057],
              [20.016279221000048, 9.127184868000029],
              [20.012439728000061, 9.12605476300007],
              [20.010629653000137, 9.123731614000121],
              [20.010482789000037, 9.12200260100019],
              [20.010242463000054, 9.119132996000133],
              [20.011280059000114, 9.111565589000122],
              [20.010099411000112, 9.107987404000085],
              [20.009069443000158, 9.104871750000029],
              [20.007089615000154, 9.102895738000143],
              [20.001247406000175, 9.111467362000042],
              [19.999998093000102, 9.113298416000021],
              [19.999950408000132, 9.113369940999974],
              [19.997890472000051, 9.116397858000141],
              [19.987161636000167, 9.096452713000019],
              [19.986454010000102, 9.095990182000037],
              [19.985359192000089, 9.095273018000057],
              [19.983530044000076, 9.094074250000119],
              [19.982702255000049, 9.093531609000081],
              [19.980310440000153, 9.091965676000143],
              [19.966611863000139, 9.090061189000039],
              [19.957490921000044, 9.088792802000114],
              [19.945001603000151, 9.088383675000102],
              [19.941780090000179, 9.088277818000165],
              [19.940746308000087, 9.087752343000091],
              [19.939430237000181, 9.087083817000064],
              [19.93541908200018, 9.084383011000057],
              [19.93503379800012, 9.08366584700002],
              [19.932710647000022, 9.079343797000036],
              [19.931505203000086, 9.072632790000114],
              [19.930660248000095, 9.06793022200003],
              [19.927139281000166, 9.060796738000079],
              [19.919490815000074, 9.063389779000147],
              [19.907447816000058, 9.067461968000032],
              [19.90493965100012, 9.06831073699999],
              [19.901435852000191, 9.066254616000151],
              [19.900049209000144, 9.065442085000143],
              [19.894430161000059, 9.062146186],
              [19.886190415000101, 9.061026573000049],
              [19.886058808000143, 9.061126708000074],
              [19.882911681000053, 9.063551901999972],
              [19.87796020400009, 9.063138962000096],
              [19.871679307000079, 9.065796852000119],
              [19.866720199999975, 9.064924241000085],
              [19.864530562000027, 9.064538956000092],
              [19.856510162000063, 9.06005096500013],
              [19.849449158000084, 9.053737640000122],
              [19.841970443000093, 9.043975830000136],
              [19.835510255000031, 9.043416023000077],
              [19.832710266000106, 9.044979096000191],
              [19.82871055600009, 9.049510002000091],
              [19.827051163000192, 9.053448678000166],
              [19.82246017600005, 9.064341546000151],
              [19.814092636000112, 9.059636115999979],
              [19.812381744000163, 9.058671951000065],
              [19.804330826000125, 9.056708336000042],
              [19.798091889000034, 9.056148530000087],
              [19.789270401000067, 9.058538437000038],
              [19.786033631000123, 9.058566093000025],
              [19.781665802000134, 9.058603287000096],
              [19.780019760000187, 9.058617593000179],
              [19.779771805, 9.058574676000092],
              [19.779499055000031, 9.058528901000159],
              [19.77525711100003, 9.057778359000054],
              [19.772880555000029, 9.057356834000075],
              [19.770956040000044, 9.055945398000176],
              [19.763280868000038, 9.05031681100013],
              [19.760435104000123, 9.049558639000111],
              [19.759601593000184, 9.04933738799997],
              [19.755840302000081, 9.0492229460001],
              [19.743659973000092, 9.048855781000043],
              [19.737411498000142, 9.045623779000152],
              [19.734949112000152, 9.044349670000145],
              [19.72554969700019, 9.044430733000183],
              [19.721458435000102, 9.044466020000186],
              [19.720260619000101, 9.041457176000108],
              [19.719909668000128, 9.040575980000028],
              [19.707374573000095, 9.03149414000012],
              [19.703712464000034, 9.02884101799998],
              [19.701900482000042, 9.027529716000061],
              [19.693021775000034, 9.028363228000103],
              [19.683612824000022, 9.029246330000149],
              [19.671991348000176, 9.020756722000101],
              [19.66667938300003, 9.020425796000041],
              [19.66411590600012, 9.021600724000109],
              [19.658990861000177, 9.023949624000125],
              [19.652204513000186, 9.023949624000125],
              [19.646970750000094, 9.023949624000125],
              [19.645317078000062, 9.023358346000066],
              [19.631368637000151, 9.018365860000131],
              [19.614440918000128, 9.021483421000085],
              [19.602001190000067, 9.019403458],
              [19.585880280000083, 9.03088665100006],
              [19.58182716499999, 9.037035942000046],
              [19.577621460000103, 9.03995132599999],
              [19.577007294000111, 9.039854049000041],
              [19.57463073800011, 9.039477349000151],
              [19.572780609000063, 9.039182663000076],
              [19.568670273000123, 9.036361695000096],
              [19.55919265700004, 9.036660194000035],
              [19.557685851000087, 9.036206245000017],
              [19.554359435000151, 9.035203934000151],
              [19.548194886000033, 9.028864861000102],
              [19.542549134000069, 9.023060799],
              [19.535230636000108, 9.018575669000029],
              [19.532176971000126, 9.01800823300016],
              [19.523019792000127, 9.016304969999965],
              [19.517229080000106, 9.01712608400004],
              [19.51464080900007, 9.019838333000052],
              [19.512714386000141, 9.02801322900018],
              [19.511890410000149, 9.031504630000143],
              [19.510744094000188, 9.032101631000046],
              [19.506759643000123, 9.034178733000147],
              [19.50263214099999, 9.033942222],
              [19.500989914000115, 9.03384781],
              [19.498939514000085, 9.031976700000087],
              [19.497282028000086, 9.021155358000044],
              [19.494087219000164, 9.018344879000097],
              [19.493436813000187, 9.018285751000178],
              [19.493280411, 9.018272400000058],
              [19.492582321000157, 9.01821136500007],
              [19.49086380000017, 9.018059730000061],
              [19.489110948000075, 9.019742012000165],
              [19.488279344000034, 9.020542144000103],
              [19.486999511000079, 9.019331932000171],
              [19.482589722000171, 9.015163422000057],
              [19.480852128000151, 9.014786721000064],
              [19.479139328000144, 9.014415742000153],
              [19.476007461000108, 9.016635896000025],
              [19.473758698000097, 9.018228531000091],
              [19.459211349000043, 9.017752648000055],
              [19.458150864000118, 9.02554512100005],
              [19.454902650000122, 9.025287629000047],
              [19.45075988800005, 9.024958611000045],
              [19.442550659000176, 9.028373718000069],
              [19.441209793000041, 9.028931617000126],
              [19.439090729000043, 9.031192780000083],
              [19.438150405000044, 9.030516624000086],
              [19.434358597000028, 9.02779197600006],
              [19.431560516000047, 9.025779724000188],
              [19.42826652399998, 9.025864601000023],
              [19.428110122000191, 9.025869369000077],
              [19.424266814000191, 9.026844024000127],
              [19.420669556000064, 9.027757645000179],
              [19.414030075000085, 9.02486801200007],
              [19.401060104000123, 9.0259847640001],
              [19.396020888000066, 9.018785477000165],
              [19.395957948000046, 9.018696785000088],
              [19.395669937000093, 9.018297196000162],
              [19.38876915100019, 9.016778945000056],
              [19.379039765000073, 9.017959595000036],
              [19.375011444000108, 9.016487121000068],
              [19.365741729000035, 9.013095855000131],
              [19.362791061000166, 9.013209343000085],
              [19.357879638000156, 9.013397216999977],
              [19.355064392000088, 9.01531124100012],
              [19.35460090700019, 9.015625954000086],
              [19.349020003000078, 9.017196655000134],
              [19.337860108000143, 9.020339011999965],
              [19.321649551000121, 9.011280060000047],
              [19.311662673000171, 9.008485794000137],
              [19.30924034200001, 9.007808685000157],
              [19.309156418000043, 9.007809640000176],
              [19.302310944000112, 9.007898331000149],
              [19.292169572000148, 9.010840417],
              [19.289749146000077, 9.011542320000103],
              [19.275049210000077, 9.011827468000035],
              [19.270458222000173, 9.013333321000062],
              [19.268026353000153, 9.014939309000056],
              [19.263679504000095, 9.017807008000091],
              [19.261779785000044, 9.02075862900017],
              [19.257308961000149, 9.023271562000161],
              [19.252670288000104, 9.025879859000042],
              [19.240877151000177, 9.025899887000094],
              [19.23550987200008, 9.02879238100013],
              [19.225370407000014, 9.027004242000089],
              [19.222360611000056, 9.027639389000171],
              [19.21836090100004, 9.031473161000122],
              [19.192419052000048, 9.02159023300004],
              [19.191041946000155, 9.021565437000106],
              [19.187799454000128, 9.021506309000188],
              [19.180339813999979, 9.024818422000067],
              [19.178400040000099, 9.024700166000059],
              [19.175029755000025, 9.024494172000061],
              [19.169860840000183, 9.017050743000141],
              [19.161809921000099, 9.015040398000167],
              [19.159114837000061, 9.014366150000171],
              [19.156972884000027, 9.013831139000047],
              [19.151876450000032, 9.009744645000126],
              [19.15081977799997, 9.00889778100003],
              [19.141695023000068, 9.007213592000028],
              [19.141149520000113, 9.007114409999986],
              [19.139774323000097, 9.006505966000134],
              [19.135187148000114, 9.004479409000055],
              [19.133028030000162, 9.005130769000118],
              [19.127290726000069, 9.006862640000122],
              [19.124540329000126, 9.005435944000055],
              [19.121051788000102, 9.006978988000185],
              [19.105663300000117, 9.008417131000044],
              [19.104610443000126, 9.008515358000125],
              [19.101116181, 9.009830475000115],
              [19.095619202000023, 9.019604683000068],
              [19.094972611000117, 9.019743919000064],
              [19.091669082000067, 9.020450592000145],
              [19.077680588000135, 9.014912605000177],
              [19.076040269000032, 9.003856659],
              [19.075574875000086, 9.00396824000012],
              [19.073568343000147, 9.004446030000054],
              [19.069770813000162, 9.005352020000032],
              [19.068574905000162, 9.005072594000183],
              [19.066549302000169, 9.004599572000132],
              [19.061668396000073, 8.999135971000044],
              [19.059219361000032, 8.996395112000016],
              [19.058629991000146, 8.99572658600016],
              [19.050729751000176, 8.997648239000057],
              [19.049276352000049, 8.997002601000133],
              [19.043170928999984, 8.994293214000038],
              [19.028318405000164, 8.997233392000112],
              [19.02741813599999, 8.996848106000016],
              [19.025711060000162, 8.996115684000188],
              [19.023279189000107, 8.995074273000057],
              [19.018739701000072, 8.990854263000131],
              [19.01359748800013, 8.981803894000109],
              [19.009738922000054, 8.978056908000099],
              [19.004209518000152, 8.977033615000039],
              [19.003961564000065, 8.977148056000033],
              [19.003812789999984, 8.977215766000143],
              [19.000001908000172, 8.978980064000098],
              [18.998750687000097, 8.979832649],
              [18.995821, 8.979543685000067],
              [18.992729188000055, 8.979239464000045],
              [18.991472244000022, 8.978249550000044],
              [18.987579346000132, 8.975182533],
              [18.98718261800019, 8.974470140000165],
              [18.985788345000174, 8.971972466000068],
              [18.984594344000072, 8.96982669800019],
              [18.98377227800006, 8.968351364000114],
              [18.981552124000075, 8.954309463000129],
              [18.981449127000133, 8.953650474000028],
              [18.977481843000191, 8.948372842000026],
              [18.975940704000095, 8.941511155000057],
              [18.968509675000121, 8.940302849000091],
              [18.966100692000055, 8.935991287000036],
              [18.965888977000191, 8.935866356000133],
              [18.962350844000127, 8.933777808000173],
              [18.961172104000127, 8.93014812500013],
              [18.960351943000035, 8.927617074000068],
              [18.957101821000037, 8.927001953000058],
              [18.952737809000098, 8.928251267],
              [18.948169707000147, 8.930654527000115],
              [18.945625305000078, 8.930829048000078],
              [18.943090439000173, 8.931001663000018],
              [18.938600541000085, 8.928949357000079],
              [18.933040618000064, 8.9264068600001],
              [18.922630310000045, 8.925955772000066],
              [18.922000886000035, 8.925498963000052],
              [18.918630600000029, 8.923056603000077],
              [18.912982941000053, 8.91458034600015],
              [18.91168975800008, 8.912640573000147],
              [18.90533065700015, 8.89784431400011],
              [18.895700454000121, 8.901282311000045],
              [18.888990402000047, 8.900974274000078],
              [18.887250900000026, 8.89883136800006],
              [18.886610030000099, 8.898040771000069],
              [18.887228012000037, 8.897327423000036],
              [18.888639450000142, 8.895695686000124],
              [18.892288208000082, 8.893544198000086],
              [18.892549516000031, 8.892862321000052],
              [18.892730712000059, 8.892383576000157],
              [18.892709731000025, 8.891552925000099],
              [18.892660141000022, 8.88962554900013],
              [18.889274597, 8.886849404000031],
              [18.882299423, 8.881127358000185],
              [18.879749298000149, 8.880956650000087],
              [18.868299484000147, 8.875700952000045],
              [18.865940094000109, 8.871849061000148],
              [18.865659713000127, 8.871391297000116],
              [18.865657806000058, 8.871244431000037],
              [18.865539550000051, 8.865875244000108],
              [18.865909577000025, 8.864991187999976],
              [18.867292405, 8.861700058000167],
              [18.862459183000169, 8.852613450000092],
              [18.863569260000077, 8.850519181000095],
              [18.863965989000121, 8.850431443000105],
              [18.865869523000072, 8.850008010000181],
              [18.86735534800016, 8.849600791000114],
              [18.868619919000082, 8.849253655000041],
              [18.871480941000186, 8.842754365000133],
              [18.874450683000077, 8.841075897000167],
              [18.8790702820001, 8.841201783000088],
              [18.880693435000012, 8.840679168000122],
              [18.881370545000095, 8.840460778000022],
              [18.880750656000032, 8.843692779000151],
              [18.882572174000131, 8.84634494900007],
              [18.883859634000089, 8.848218919000033],
              [18.886327744000027, 8.849855423000065],
              [18.888549803999979, 8.851327897000033],
              [18.893028259000118, 8.851782799000034],
              [18.893375397000057, 8.851818085000105],
              [18.894109726000124, 8.851893426],
              [18.900779724000074, 8.850591660000134],
              [18.904024124000159, 8.848331452000025],
              [18.906240463000131, 8.846788407000133],
              [18.906629563000138, 8.843386650000127],
              [18.906820297000138, 8.841713906000109],
              [18.906734466000046, 8.834134102],
              [18.906587601000069, 8.821494102000088],
              [18.906667710000022, 8.82045936600008],
              [18.906839371000103, 8.818237304000093],
              [18.907119751000039, 8.814584733000118],
              [18.909719468000105, 8.806939125000099],
              [18.916790009000067, 8.802640915999973],
              [18.922979354000176, 8.800428390000093],
              [18.935909270000082, 8.810015679000117],
              [18.94267273000014, 8.812619209000047],
              [18.946599961000118, 8.812758445000043],
              [18.952299118000099, 8.809180260000176],
              [18.953128815000127, 8.806119918999968],
              [18.956560135000132, 8.79345131000008],
              [18.960359573000119, 8.788075448000086],
              [18.960981370000127, 8.787660599000162],
              [18.962860108000143, 8.786409378000087],
              [18.970380783000053, 8.781408311000121],
              [18.977355957000043, 8.778116226000122],
              [18.985229491000098, 8.774397850000128],
              [18.996551514000146, 8.769542695000155],
              [18.997129441000084, 8.769294738000156],
              [19.000001908000172, 8.770380021000165],
              [19.014469147, 8.765705110000113],
              [19.019630433000032, 8.761439323000047],
              [19.027185440000153, 8.747100831000125],
              [19.03329086299999, 8.735512733000178],
              [19.041749955000057, 8.727404595000053],
              [19.059520722000173, 8.715789795999967],
              [19.065361024000083, 8.714216232000183],
              [19.071279526000012, 8.712621690000049],
              [19.07644653300008, 8.711606025000094],
              [19.081249238000169, 8.710661888000061],
              [19.08223915200017, 8.71046829200003],
              [19.099761963000049, 8.699537277000047],
              [19.101142883000136, 8.69932460699999],
              [19.105089188000022, 8.698719026000106],
              [19.104951859000153, 8.693891525000083],
              [19.105701447000172, 8.692384719000131],
              [19.109022140000036, 8.685700416000088],
              [19.115861893000101, 8.678248407000183],
              [19.11708641000007, 8.677615167],
              [19.121448517000147, 8.675362587000166],
              [19.122220993000099, 8.67124557600016],
              [19.120780945000149, 8.668905259000042],
              [19.120649337000089, 8.668691636],
              [19.11879921000002, 8.667670251000175],
              [19.113340376999986, 8.664654732000145],
              [19.109462738000047, 8.663544655000067],
              [19.100479127000085, 8.660973550000051],
              [19.097042083000076, 8.658048630000167],
              [19.09662055900003, 8.65769004800012],
              [19.096216202000051, 8.656810760000042],
              [19.093961716000024, 8.651901244999976],
              [19.090999603000171, 8.649551392000149],
              [19.087141037000151, 8.649358750000033],
              [19.083629607000034, 8.649184227000092],
              [19.08260345400015, 8.64771556900007],
              [19.077779770000177, 8.640813828000034],
              [19.070310593000045, 8.640102387000013],
              [19.065841674000069, 8.637378692000027],
              [19.063449859000116, 8.617356301000086],
              [19.061525344000131, 8.614822387],
              [19.058500290000154, 8.61083793600011],
              [19.055227279000121, 8.607963563000055],
              [19.049419404000162, 8.602863312000011],
              [19.049200059000043, 8.602484704000176],
              [19.047910691000141, 8.600257875000011],
              [19.046060562000093, 8.597061157000042],
              [19.046466828000121, 8.575686456000085],
              [19.046470640999985, 8.575486184000056],
              [19.044752121000101, 8.570112229000131],
              [19.042110444000116, 8.561859131000063],
              [19.042259216000161, 8.554286003000186],
              [19.039573669999982, 8.552749634000179],
              [19.033130646000188, 8.549063683000099],
              [19.031730653000068, 8.537560464000023],
              [19.030633926000178, 8.535464286000092],
              [19.029060363000099, 8.532458304999977],
              [19.027597427000046, 8.531270027000062],
              [19.022699357000135, 8.527289391000068],
              [19.016000748000181, 8.527624131000039],
              [19.014009475000023, 8.523225785000079],
              [19.010845184000118, 8.523668289000113],
              [19.008220673000096, 8.524033548000034],
              [19.006952287000161, 8.52252197200005],
              [19.006490708000058, 8.52197074899999],
              [19.002359391000084, 8.517038345000117],
              [19.000001908000172, 8.515809060000095],
              [18.991670608000049, 8.514759063000156],
              [18.989557267000066, 8.512969972000178],
              [18.989048005000029, 8.510716438999964],
              [18.990760803, 8.507987023000112],
              [18.995538712000041, 8.502119064000169],
              [18.997329711000134, 8.499918938000178],
              [18.98854255700013, 8.489323616000149],
              [18.983438492000175, 8.48801994300004],
              [18.981109618000062, 8.487424850000082],
              [18.977090835000126, 8.4831523900001],
              [18.972900391000167, 8.481640816000038],
              [18.970939637000129, 8.476631163999969],
              [18.972579956000061, 8.467861175999985],
              [18.970891952000159, 8.465025903000139],
              [18.970420837000177, 8.464233398000033],
              [18.957160949000126, 8.459942817000012],
              [18.944402695000178, 8.446910859000184],
              [18.943311692000066, 8.446210861],
              [18.937139510000065, 8.442250253000054],
              [18.932390213000133, 8.436615944000039],
              [18.93190574700003, 8.434659958000054],
              [18.93136978199999, 8.432501794000189],
              [18.931730270000173, 8.427898408000146],
              [18.930290222000053, 8.425864220000051],
              [18.927021026000091, 8.426316261000068],
              [18.925979615000131, 8.426460266000163],
              [18.924310683999977, 8.426691056000095],
              [18.923015594000162, 8.425457001000041],
              [18.921720505000167, 8.424221993000174],
              [18.921398163000163, 8.422794342000088],
              [18.920949937000046, 8.42079162499999],
              [18.921464921000165, 8.418881416000147],
              [18.923070908000113, 8.412931442000058],
              [18.920677184000112, 8.40931034200014],
              [18.917390823000062, 8.406399728],
              [18.918212891000053, 8.405381203000047],
              [18.919847488000187, 8.403354645000036],
              [18.92095947199999, 8.403041841000061],
              [18.926958083000102, 8.401355744000114],
              [18.927391051000029, 8.399732591000031],
              [18.925971985000047, 8.398387910000054],
              [18.923610687000064, 8.39614772800013],
              [18.923759461000145, 8.392695426000159],
              [18.923942566999983, 8.390393258000074],
              [18.919490813999971, 8.387738229000036],
              [18.910709382000107, 8.387020112000073],
              [18.90873527500014, 8.384803771000065],
              [18.908100127000125, 8.384092331000034],
              [18.909425736000117, 8.379665375000172],
              [18.910247803000061, 8.376919747000045],
              [18.909372329000064, 8.376593589000038],
              [18.909273147000022, 8.376557350000155],
              [18.903259278000064, 8.37432289200018],
              [18.900657653000053, 8.371025085999975],
              [18.900409699000136, 8.370710373000179],
              [18.900779724000074, 8.366336823000154],
              [18.899570465000124, 8.364068985000074],
              [18.892000198, 8.366079331000151],
              [18.887399674000051, 8.366644860000122],
              [18.885080338000023, 8.366236686000093],
              [18.883649826000124, 8.364201546000118],
              [18.886732101000177, 8.358628272000146],
              [18.887639999000157, 8.356987953000044],
              [18.885980606000032, 8.354956626999979],
              [18.87977981500012, 8.356246948000091],
              [18.87947845400015, 8.356051446000095],
              [18.876728057000037, 8.354250907000051],
              [18.87399864200006, 8.349454880000167],
              [18.873369217000118, 8.348349571000085],
              [18.87375068700004, 8.344897271000093],
              [18.876201630000082, 8.341163635999976],
              [18.87439537, 8.341363908000062],
              [18.8683795930001, 8.342029572000058],
              [18.857187271000043, 8.347339631000011],
              [18.855741501000125, 8.347629547000054],
              [18.853290559000015, 8.348119735000068],
              [18.850502013000039, 8.34726238200011],
              [18.853647233000061, 8.342211723000105],
              [18.85383987400013, 8.341901779000068],
              [18.85507965, 8.335671424000111],
              [18.855297088000043, 8.335208892000026],
              [18.855520248000062, 8.334740638000028],
              [18.857120513000041, 8.323905944999979],
              [18.854610443000183, 8.314773560000162],
              [18.85092926100009, 8.30543613499998],
              [18.843170167000039, 8.298489571000062],
              [18.84213829100014, 8.297736169000075],
              [18.840120316000025, 8.296262742000124],
              [18.838882446000127, 8.293194771],
              [18.837179184000036, 8.288975716000095],
              [18.831054687000176, 8.284761428000081],
              [18.830360412, 8.284282684000118],
              [18.827836991000027, 8.283039093000127],
              [18.82386970500005, 8.281083108000019],
              [18.823289871000043, 8.280391693000127],
              [18.81987953100014, 8.276322365000056],
              [18.815895081000178, 8.272835732000146],
              [18.809820174000151, 8.267519951000054],
              [18.804410934000032, 8.260712624000178],
              [18.797018052000112, 8.259677888000169],
              [18.78586959900008, 8.254568101000075],
              [18.780490875, 8.249366760000044],
              [18.779195786000059, 8.24895858799999],
              [18.774229049000098, 8.247396470000069],
              [18.763820649000081, 8.245027542000173],
              [18.761343003000036, 8.244342803999984],
              [18.748079301000018, 8.240676880000081],
              [18.74806594800009, 8.23999214299999],
              [18.748060225000074, 8.239755632000083],
              [18.744590760000165, 8.239809037000157],
              [18.740219115000173, 8.239876747000039],
              [18.734880448000126, 8.237200736999967],
              [18.710580826000069, 8.231598855000129],
              [18.696327209000117, 8.225231171000132],
              [18.689689635000036, 8.222266197000124],
              [18.681039810000129, 8.213896752000096],
              [18.67732238799999, 8.213030815000138],
              [18.67362976000004, 8.212171554],
              [18.666620254, 8.205846788000031],
              [18.657699585999978, 8.194954872000153],
              [18.657747268000037, 8.189932822000117],
              [18.657789230000162, 8.185535431000176],
              [18.656801224000105, 8.183814048000102],
              [18.652828217000092, 8.176883698000097],
              [18.64993095400007, 8.173565865000114],
              [18.641340257000081, 8.163732529000072],
              [18.630020141000159, 8.146446229000105],
              [18.628099441000018, 8.141192435999983],
              [18.628639222, 8.131079674000091],
              [18.630798340000126, 8.120944977000079],
              [18.631532670000126, 8.117432595000082],
              [18.637050628000168, 8.090996743000176],
              [18.635091782000131, 8.083215714],
              [18.631988526000043, 8.08109188200018],
              [18.62787056000019, 8.078274727000064],
              [18.62434959300009, 8.073502541000096],
              [18.624246598000184, 8.072587966000185],
              [18.62328910799999, 8.064103126000134],
              [18.62461090100004, 8.059720040000059],
              [18.618049621000182, 8.052468300999976],
              [18.613410950000173, 8.050238609000076],
              [18.604629517000035, 8.048762322000016],
              [18.602628708000111, 8.047426224000162],
              [18.601600647000055, 8.046739578000029],
              [18.594810486000029, 8.046499253000093],
              [18.592102050000108, 8.046402931000159],
              [18.56955909800007, 8.045600891000049],
              [18.550180435000073, 8.044277192000038],
              [18.501979829000049, 8.041069984000046],
              [18.501453399000184, 8.040988922999986],
              [18.488349915000128, 8.038970948000099],
              [18.481889725000087, 8.038373948000071],
              [18.45676994300004, 8.037813187000154],
              [18.453979492000144, 8.036245345999987],
              [18.450067519000015, 8.036529541000164],
              [18.424249649000103, 8.035517694000021],
              [18.423463822000031, 8.035050392000073],
              [18.421230315000059, 8.033721925],
              [18.413150787000177, 8.032687187000022],
              [18.401639937000027, 8.033539771000051],
              [18.342119218000164, 8.028395653000018],
              [18.330369950000033, 8.028557778000049],
              [18.319143296000163, 8.027467726999987],
              [18.315839767000114, 8.027147294000031],
              [18.312454223000145, 8.026835442000163],
              [18.304990768000152, 8.026147842000114],
              [18.274320603000092, 8.0240402230001],
              [18.270978927000044, 8.021558762000041],
              [18.270370483000136, 8.021106721000024],
              [18.259611129, 8.021841050000091],
              [18.253559113000051, 8.022253036999985],
              [18.215440750000141, 8.019504548000043],
              [18.213989259000186, 8.019400595999969],
              [18.191757202000019, 8.017798424000091],
              [18.164072037000039, 8.014719009000146],
              [18.155767441000023, 8.014597893000087],
              [18.129011154000182, 8.013292313000022],
              [18.122339249000106, 8.012966157000051],
              [18.106927872000142, 8.012602805000029],
              [18.04674720700018, 8.011183739000046],
              [18.031982423000102, 8.009303093000028],
              [18.029716492000148, 8.00773334500019],
              [18.023849487000064, 8.00366687900015],
              [18.020135880000055, 8.001297951000083],
              [18.018102645000113, 8.0],
              [18.008701324000015, 7.992681979000054],
              [18.004760742000144, 7.989614010000139],
              [18.000001907000126, 7.985908032000111],
              [17.998546601000101, 7.988205910000147],
              [17.995649338000135, 7.992784024000059],
              [17.991628647000084, 7.990408898000169],
              [17.944908142000031, 7.962803841000095],
              [17.930620193000095, 7.959417820000112],
              [17.921638490000078, 7.958611012000176],
              [17.866821289000143, 7.963479043000177],
              [17.847490310000012, 7.965195179000034],
              [17.797529221000104, 7.97224187900008],
              [17.795919419000086, 7.97232294000014],
              [17.795799255000134, 7.972329140000113],
              [17.772430420000035, 7.973473072000104],
              [17.754390717000035, 7.975778102000049],
              [17.748653411000134, 7.976511001000176],
              [17.746259689000112, 7.976817131000132],
              [17.745250702000078, 7.976945877000105],
              [17.741121293000049, 7.977475167000023],
              [17.733169556000178, 7.978384972000072],
              [17.725055695000037, 7.978481768999984],
              [17.716339112000128, 7.978588103999982],
              [17.713100433000136, 7.977459908000071],
              [17.708213806000174, 7.971302987000058],
              [17.707302094000056, 7.97015380900018],
              [17.705419540000094, 7.969834804000129],
              [17.703380585000048, 7.969491005],
              [17.70171737700008, 7.972178936000148],
              [17.697679520000065, 7.978705883999965],
              [17.69401168800016, 7.981709957000135],
              [17.690248489000112, 7.982532024000022],
              [17.683200837000129, 7.984073163000062],
              [17.680637359000116, 7.983782769000186],
              [17.671670914000117, 7.98276710499999],
              [17.670082092000143, 7.981964112000071],
              [17.668399811000143, 7.981112957000107],
              [17.665973664000035, 7.979886055000179],
              [17.66404914900005, 7.978912831000116],
              [17.663167953000141, 7.978186130000154],
              [17.658260345000144, 7.974132062000081],
              [17.657079696999972, 7.969548225000153],
              [17.657283782000093, 7.968741895000051],
              [17.658880234000037, 7.962421893999988],
              [17.648880006000184, 7.945732117000091],
              [17.645713805000128, 7.943657875000099],
              [17.645410538000021, 7.94345903500016],
              [17.644222260000106, 7.943645001000107],
              [17.633211137000046, 7.945368767000048],
              [17.630081177000079, 7.94311285100008],
              [17.629739762000156, 7.942866802000083],
              [17.629245758000138, 7.941895962000046],
              [17.626949311000089, 7.93737602300007],
              [17.619205475000115, 7.929586888000131],
              [17.616519927000127, 7.926885128000095],
              [17.612087249000183, 7.926280975000111],
              [17.610069275, 7.92600584000013],
              [17.606735229000037, 7.928567887000156],
              [17.605690002000131, 7.92937183500004],
              [17.603208541000072, 7.931282997000096],
              [17.60135078400009, 7.93271493900005],
              [17.601240158, 7.932715894000069],
              [17.598182678000171, 7.932734967000101],
              [17.596517562000088, 7.932744980000166],
              [17.585180283, 7.923862934000113],
              [17.578659058000028, 7.912428856000133],
              [17.57664108300014, 7.910772801000178],
              [17.574491499999965, 7.909009933000164],
              [17.573261260000152, 7.908794881000119],
              [17.569421768000097, 7.908123970000133],
              [17.568122864000088, 7.908518791],
              [17.567119597000101, 7.908824920000029],
              [17.566354751000063, 7.910217762000173],
              [17.564840318000108, 7.912971019000167],
              [17.563959122000028, 7.919631004000109],
              [17.561767579000161, 7.922500133000085],
              [17.561670304000188, 7.922627926000075],
              [17.558755874999974, 7.922774791000052],
              [17.551309586, 7.923150063000037],
              [17.548488617000089, 7.921919823000053],
              [17.547159195000063, 7.921339988999989],
              [17.545406342000092, 7.918959142000119],
              [17.542280198000071, 7.914711953000165],
              [17.538530350000144, 7.903950214000133],
              [17.538690567000117, 7.891098022000051],
              [17.536819458000139, 7.887207984000042],
              [17.532346724999968, 7.886890889000028],
              [17.530832292000184, 7.886784077000073],
              [17.529119492000063, 7.88882112500005],
              [17.528137208000146, 7.889982224000107],
              [17.526939392000145, 7.891397],
              [17.523490905000187, 7.890958787000159],
              [17.515068053000107, 7.86874914200007],
              [17.511144639000065, 7.86762380600004],
              [17.505649567000148, 7.873623848000023],
              [17.485450745000094, 7.885682105],
              [17.478385924, 7.89149284500013],
              [17.476741790000062, 7.892846108000072],
              [17.475992202000043, 7.893202782000117],
              [17.474229812000033, 7.894043922000094],
              [17.472839355000019, 7.894705773000055],
              [17.467769622000048, 7.894046784000125],
              [17.460418702000027, 7.884751797000149],
              [17.45964050300006, 7.883769036000103],
              [17.45549011200012, 7.882186889000025],
              [17.449052810000012, 7.883371830000101],
              [17.447441102000084, 7.878467082000157],
              [17.447170257000153, 7.877645016000031],
              [17.449499131000096, 7.872735024000178],
              [17.449899674000108, 7.871891976000086],
              [17.449731827000164, 7.871017933000132],
              [17.449359894000054, 7.869083882000041],
              [17.449289321000151, 7.868724823000093],
              [17.449190139000109, 7.868223190000094],
              [17.446475981999981, 7.866619110000045],
              [17.445730209000033, 7.866178036000179],
              [17.444646836000175, 7.866130829000042],
              [17.441120148000039, 7.865974904000041],
              [17.438821793000045, 7.867598058000056],
              [17.4360466, 7.874641895000138],
              [17.435649872000056, 7.875648022000178],
              [17.433120728000063, 7.877271175999965],
              [17.427888870000061, 7.876500130000125],
              [17.421129226000119, 7.875504018000129],
              [17.418119430000161, 7.871390820000158],
              [17.418964385000152, 7.868982793000157],
              [17.419250488000102, 7.868169785000021],
              [17.424289704000159, 7.863092899000037],
              [17.423603057000093, 7.858708859000046],
              [17.423570633000054, 7.858507157000076],
              [17.415300368000146, 7.861310006000053],
              [17.411869050000064, 7.866609097000037],
              [17.410177230999977, 7.866360187000055],
              [17.409532548000016, 7.866263868000146],
              [17.408870697000054, 7.866167068000152],
              [17.404449462000116, 7.8579301850001],
              [17.403497695000112, 7.852427959000124],
              [17.404850006, 7.847371102000125],
              [17.402990341000077, 7.843019963000131],
              [17.398380280000083, 7.843736172000149],
              [17.395618438000042, 7.847854138000173],
              [17.393590928000151, 7.850877762000039],
              [17.380210876000092, 7.847582817000159],
              [17.377353668000126, 7.846881867000093],
              [17.376289368000187, 7.846621036999977],
              [17.37017250100007, 7.84906387400008],
              [17.368709565000131, 7.849647999000183],
              [17.366987229000131, 7.849201203000121],
              [17.365249633000076, 7.848750115000144],
              [17.363574982000159, 7.846405030000142],
              [17.359207152000067, 7.840293884000062],
              [17.345399857000075, 7.841062069000145],
              [17.34235572800003, 7.838398934000111],
              [17.34123039200017, 7.837412834000077],
              [17.335849762000123, 7.822298050000029],
              [17.332849502000045, 7.82047891700006],
              [17.328687667000054, 7.820897102000117],
              [17.328010560000052, 7.820964813000103],
              [17.322729110000068, 7.823908806000077],
              [17.314697266000053, 7.82838487600003],
              [17.312051773000064, 7.828097820000096],
              [17.310607910000044, 7.827940942000112],
              [17.30870056100008, 7.827733995000131],
              [17.305057525000109, 7.824144840999963],
              [17.304529189999982, 7.823624135000046],
              [17.303350449000106, 7.817433834999974],
              [17.304231644000083, 7.809395790000053],
              [17.30308532700019, 7.808028220999972],
              [17.300981520999983, 7.805513859000143],
              [17.291109086, 7.804375173000096],
              [17.289051057000165, 7.804135799000164],
              [17.287200928000118, 7.803921224000078],
              [17.284440994000022, 7.803604127000028],
              [17.283929825000087, 7.80354499900011],
              [17.28183937100016, 7.801261902000135],
              [17.283649444000105, 7.793907165000121],
              [17.281560898000123, 7.791395187000092],
              [17.274192811000034, 7.790977955000017],
              [17.27070045500011, 7.785257817000115],
              [17.269041062000042, 7.785462857000027],
              [17.266792297000052, 7.78574085200006],
              [17.264711379000175, 7.784144880000156],
              [17.264064789000145, 7.781186104000142],
              [17.263759612, 7.779789926000092],
              [17.268987655000103, 7.76690578500012],
              [17.269224168000051, 7.764027119000161],
              [17.269420625000066, 7.761624814000072],
              [17.267080307000072, 7.754520894000052],
              [17.264581680000163, 7.750254154000174],
              [17.262659073000179, 7.746973038000135],
              [17.260120391999976, 7.74515199699999],
              [17.254600525000058, 7.746329785000114],
              [17.244079590000069, 7.759473801000183],
              [17.240577698000038, 7.761956215000055],
              [17.237880707000102, 7.763867855000115],
              [17.235317229000088, 7.763661862000049],
              [17.232578278000062, 7.763442040000143],
              [17.228410721000103, 7.758873940000171],
              [17.225595474000102, 7.750598908000086],
              [17.225370408000174, 7.749938012000143],
              [17.22006034899999, 7.74652385700017],
              [17.217039108000051, 7.742181778000031],
              [17.22002029500004, 7.730466842000055],
              [17.220199586000092, 7.729766846000075],
              [17.218572617000177, 7.726562977000071],
              [17.216869355000142, 7.72633123300011],
              [17.213731765000034, 7.725903988000027],
              [17.211738587000127, 7.723742009000091],
              [17.209100723, 7.72087907800011],
              [17.204196931000183, 7.720282076999979],
              [17.198270798000067, 7.719561101000011],
              [17.175659179000093, 7.709696771000097],
              [17.169181823000088, 7.70687103400013],
              [17.165700912000091, 7.702756880999971],
              [17.165737150999973, 7.702204228000028],
              [17.165920257000039, 7.699543953000045],
              [17.17415046800005, 7.688477994000095],
              [17.162830353000174, 7.681193828000119],
              [17.161649705, 7.676381112000172],
              [17.162542344000087, 7.670178889999988],
              [17.160448074000158, 7.666747094000073],
              [17.15635681200007, 7.665662764999979],
              [17.144311905000052, 7.66247415600003],
              [17.141399383000135, 7.662095070000135],
              [17.139240265000183, 7.661815167000157],
              [17.133945464000078, 7.662866117000078],
              [17.132110596000132, 7.663229944000079],
              [17.127410888000099, 7.666389942000023],
              [17.125909805000163, 7.667398929000115],
              [17.125524521999978, 7.668158055000049],
              [17.125040054000067, 7.669116975000179],
              [17.117776871000103, 7.683471202000078],
              [17.115659714, 7.687655926000105],
              [17.115098953000086, 7.687837124000168],
              [17.110599518000186, 7.689291955000044],
              [17.102069855000082, 7.688418866000177],
              [17.101947785000107, 7.688496113000099],
              [17.096569060000149, 7.69189500900012],
              [17.091270447000056, 7.692152977000148],
              [17.091016770000124, 7.691998004000027],
              [17.090394973000116, 7.691620827000179],
              [17.088270186000045, 7.690331936000177],
              [17.087329865000186, 7.686433793000163],
              [17.087713242000177, 7.68132209900017],
              [17.087760925000111, 7.680693150000082],
              [17.088022232000185, 7.679895877000092],
              [17.091840744000081, 7.668275833],
              [17.091409683000052, 7.665481092000164],
              [17.090639115000045, 7.660477162],
              [17.086030961000176, 7.658205986000041],
              [17.085569382000074, 7.657979012999988],
              [17.079120635000038, 7.657555103000107],
              [17.067342758000109, 7.651422023000123],
              [17.06295967, 7.650987148000127],
              [17.049169541000083, 7.655193805000124],
              [17.048583984, 7.654883862000133],
              [17.047838211000055, 7.654489994000073],
              [17.046169281000175, 7.65360307800006],
              [17.044080735000023, 7.650401115000079],
              [17.042409897000141, 7.638241768],
              [17.040319442000111, 7.635039808000101],
              [17.027788162000093, 7.634943008000107],
              [17.005319594000184, 7.634770870000068],
              [16.993560791000107, 7.631848813000147],
              [16.98712921200007, 7.635557175000031],
              [16.981830596000066, 7.634208204000061],
              [16.978939056000058, 7.629586220000078],
              [16.978109359000086, 7.628259182000079],
              [16.975120544000163, 7.62781715400007],
              [16.971557617000144, 7.63014078100008],
              [16.963409423000087, 7.635454177000042],
              [16.961215973000151, 7.635622978000072],
              [16.956970216000116, 7.635949135000146],
              [16.943590164000057, 7.631198883000025],
              [16.937360764000118, 7.628935814000101],
              [16.929426193000097, 7.623518942999965],
              [16.928350448000174, 7.622785091000026],
              [16.926422120000097, 7.620171071000073],
              [16.924640656000179, 7.617754936000097],
              [16.927810670000099, 7.607635021000078],
              [16.926889419000133, 7.60603284900003],
              [16.92477989300005, 7.605823994000048],
              [16.922510146000093, 7.605597973000101],
              [16.917816161000133, 7.60850095700016],
              [16.905290604000186, 7.616250038000146],
              [16.903417586999979, 7.616357803000085],
              [16.900920867000025, 7.616500854000094],
              [16.898609161000024, 7.613988876000065],
              [16.898126601000115, 7.610321999000178],
              [16.898986816000104, 7.608033180000064],
              [16.89930915900004, 7.607172012000035],
              [16.899938583999983, 7.605487823000033],
              [16.905660629000124, 7.597191812000119],
              [16.904478074000053, 7.591917992000049],
              [16.900903701000175, 7.586717129000022],
              [16.895671844000105, 7.579106807000073],
              [16.895656586000086, 7.576976777000027],
              [16.895639420000066, 7.574516774000131],
              [16.899080276000063, 7.571284770000091],
              [16.906091689999982, 7.56969118100011],
              [16.907360076000145, 7.569403172000023],
              [16.907482147000053, 7.569231034000154],
              [16.909650803000034, 7.56617784600013],
              [16.909658432000072, 7.566085816000168],
              [16.910078049000049, 7.561583042999985],
              [16.908235550000086, 7.558535100000142],
              [16.908000946000072, 7.558147906999977],
              [16.901750564000167, 7.552902222],
              [16.898189544000047, 7.551220895000085],
              [16.895980834999989, 7.550178051000103],
              [16.891841889000034, 7.550889015000166],
              [16.889797211000143, 7.552547932000039],
              [16.883590698000091, 7.557590008000091],
              [16.878290177000167, 7.557847978000154],
              [16.874139785000125, 7.556263924000177],
              [16.869739533000029, 7.55146408100012],
              [16.869890213000076, 7.543003083000144],
              [16.86992073, 7.541359901000078],
              [16.869842529000039, 7.541256906000172],
              [16.867839814000149, 7.538616180000133],
              [16.865007401000184, 7.537150861000157],
              [16.863447189000055, 7.536344051000185],
              [16.850780487000065, 7.535261155000114],
              [16.846399307000127, 7.532989026000166],
              [16.843849183000145, 7.529787063000129],
              [16.834890366000081, 7.532817840000178],
              [16.829097747000162, 7.532626151000045],
              [16.824342728999966, 7.532468796000103],
              [16.822910309000122, 7.532422067000027],
              [16.822259903000145, 7.532683849000023],
              [16.818326949000152, 7.534277916],
              [16.814870835000022, 7.535677909000128],
              [16.813745499000163, 7.537236215000121],
              [16.81121063300003, 7.540747167000177],
              [16.810733796000079, 7.543626785000015],
              [16.809652328000141, 7.550168991000078],
              [16.80690193200013, 7.552937985000028],
              [16.803173064000134, 7.554614067000159],
              [16.800699233000046, 7.555726050999965],
            ],
          ],
        },
      },
    ],
  },
  104: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-15.953376770999967, 12.44264698100011],
                [-15.887792587999911, 12.450507164000044],
                [-15.883071898999958, 12.44972515100011],
                [-15.837197303999972, 12.444760322999969],
                [-15.83372783699997, 12.444667816000049],
                [-15.811518668999952, 12.442055703000051],
                [-15.777522087999898, 12.437849044000018],
                [-15.736942290999878, 12.433808327000122],
                [-15.708154677999914, 12.428645135000124],
                [-15.680843352999943, 12.424531937000154],
                [-15.625183104999905, 12.449285507000127],
                [-15.624369620999914, 12.446770669000045],
                [-15.589157103999923, 12.463980676000176],
                [-15.589875219999897, 12.467596055000115],
                [-15.549505232999934, 12.485136985000167],
                [-15.50304126799989, 12.50530529000008],
                [-15.458399771999893, 12.52338314200017],
                [-15.433211325999935, 12.533234596000113],
                [-15.425550459999954, 12.537625312000102],
                [-15.407253264999952, 12.548373223000056],
                [-15.375431060999972, 12.571962356000085],
                [-15.36708927099994, 12.578073502000166],
                [-15.340675354999917, 12.607106209999984],
                [-15.33518123499988, 12.613265039000055],
                [-15.32860755999991, 12.615784645000133],
                [-15.298062323999886, 12.627323150000166],
                [-15.269228934999944, 12.638340951000032],
                [-15.248483657999884, 12.6510896690001],
                [-15.235333441999956, 12.659236908000139],
                [-15.232605933999935, 12.660291672000028],
                [-15.214581488999954, 12.66767597300003],
                [-15.202791213999888, 12.672566415000176],
                [-15.188272475999952, 12.678223610000089],
                [-15.173559188999889, 12.684263230000113],
                [-15.152114868999888, 12.684754371000167],
                [-15.148019789999921, 12.683033943000112],
                [-15.144510267999976, 12.681887627000151],
                [-15.138758658999961, 12.680837632000191],
                [-15.13213062299991, 12.680362701000092],
                [-15.124430656999834, 12.680558205000068],
                [-15.120923042999948, 12.68295192700009],
                [-15.120241165999914, 12.684483528000044],
                [-15.120558737999943, 12.685261727000068],
                [-15.084902762999889, 12.685440063000044],
                [-15.038025855999877, 12.685153962000129],
                [-14.975636481999857, 12.681597711000109],
                [-14.944097518999968, 12.680603982000036],
                [-14.912714958999857, 12.679727555000113],
                [-14.879274368999972, 12.679082870000116],
                [-14.817910193999978, 12.678662301000088],
                [-14.756546020999963, 12.67822551800009],
                [-14.748640059999957, 12.678375245000097],
                [-14.733322142999953, 12.678070068000181],
                [-14.71284198799998, 12.679061890000185],
                [-14.693717956999933, 12.679426194000143],
                [-14.649474143999896, 12.680166244000077],
                [-14.62553596399988, 12.68048858600008],
                [-14.613034247999963, 12.678382873000032],
                [-14.594223976999956, 12.680553437000071],
                [-14.53949069999993, 12.68065929400018],
                [-14.502887726999916, 12.680965424000135],
                [-14.466283798999882, 12.681267738000088],
                [-14.430793760999904, 12.680662155000164],
                [-14.39117240999991, 12.68063068300006],
                [-14.351552009999978, 12.680594444000178],
                [-14.326124191999895, 12.68039131200004],
                [-14.326101302999973, 12.676492691000135],
                [-14.279125213999976, 12.67584228600009],
                [-14.232151030999887, 12.675185203000126],
                [-14.22904109999996, 12.678994178999972],
                [-14.20520305599996, 12.67864608800005],
                [-14.159137726999973, 12.677042962000087],
                [-14.107888221999815, 12.675824165000051],
                [-14.070749281999952, 12.675393105000126],
                [-14.033610343999953, 12.674956322000128],
                [-14.010880469999904, 12.675048829000048],
                [-13.998838424999974, 12.674346924000133],
                [-13.980495452999833, 12.674395560999983],
                [-13.919852256999945, 12.674340248000135],
                [-13.879423141999894, 12.674295426000128],
                [-13.847266197999943, 12.674818038000069],
                [-13.832459448999884, 12.674817086000132],
                [-13.830351829999927, 12.674509049000164],
                [-13.821659087999933, 12.674462319000042],
                [-13.809854506999955, 12.674509049000164],
                [-13.804585456999973, 12.674937249000038],
                [-13.76835250799985, 12.674835206000068],
                [-13.744955062999964, 12.674599648000083],
                [-13.721410749999961, 12.674595833000069],
                [-13.707084655999893, 12.67440891400014],
                [-13.681512832999886, 12.674698830000125],
                [-13.638714789999938, 12.674791337000045],
                [-13.583036422999953, 12.675124167999968],
                [-13.561195372999919, 12.675087927999982],
                [-13.558756827999957, 12.67495727500011],
                [-13.520417213999849, 12.675008774000048],
                [-13.500547407999932, 12.67513275300007],
                [-13.498439788999974, 12.674966813000026],
                [-13.471767424999939, 12.671705247000148],
                [-13.430299758999922, 12.667242051000187],
                [-13.36028194499994, 12.659690857000157],
                [-13.357949255999927, 12.668866157000082],
                [-13.357473373999937, 12.676991463000036],
                [-13.357029914999885, 12.679663658000038],
                [-13.356046676999824, 12.683372499000086],
                [-13.352623938999955, 12.691256523000106],
                [-13.348968504999903, 12.691501617000142],
                [-13.346014021999963, 12.690230370000052],
                [-13.345763206999948, 12.686918260000084],
                [-13.3444566739999, 12.685503959000016],
                [-13.343748092999931, 12.683538437000095],
                [-13.344094276999954, 12.681254387000138],
                [-13.345216751999942, 12.677979469000036],
                [-13.345195770999965, 12.673598291000133],
                [-13.34558868399995, 12.670127868000122],
                [-13.345945358999813, 12.663203239000154],
                [-13.345611572999928, 12.660493852000059],
                [-13.345026015999963, 12.658164978000116],
                [-13.344265937999921, 12.656903266000143],
                [-13.34076786099996, 12.652519226000152],
                [-13.33981800099997, 12.651652337000087],
                [-13.337786673999972, 12.652539254000033],
                [-13.336199759999943, 12.656796456],
                [-13.335200309999834, 12.6577463160001],
                [-13.331726074999949, 12.658585548000076],
                [-13.330063819999907, 12.656795501000033],
                [-13.328951834999941, 12.654550553000092],
                [-13.329019545999927, 12.653058053000052],
                [-13.332262039999875, 12.648773193000068],
                [-13.332081793999976, 12.64504146500002],
                [-13.331652640999948, 12.64442920700003],
                [-13.328639984999882, 12.643958092000048],
                [-13.325525283999923, 12.644340515000124],
                [-13.320089339999925, 12.642052650000096],
                [-13.318453787999943, 12.641984939999986],
                [-13.3150548939999, 12.643269540000063],
                [-13.313191413999903, 12.644420624000077],
                [-13.311060905999966, 12.646243096000092],
                [-13.309204101999967, 12.646331788000111],
                [-13.30789852099997, 12.644903184000043],
                [-13.306381224999825, 12.644448280000063],
                [-13.305093764999981, 12.644742011000119],
                [-13.302393912999946, 12.64647579200016],
                [-13.299199102999921, 12.645637513000167],
                [-13.296569824999892, 12.64539051100013],
                [-13.29158592199991, 12.645516395000072],
                [-13.288295744999914, 12.645969391000108],
                [-13.287779807999925, 12.646340371000065],
                [-13.2901429879999, 12.650668095000185],
                [-13.290036, 12.651589000000115],
                [-13.2887392049999, 12.652900696000074],
                [-13.285574911999959, 12.653946877000067],
                [-13.282541, 12.656015],
                [-13.280650998999931, 12.656445999000084],
                [-13.27841091199997, 12.655572891000133],
                [-13.271441459999892, 12.654442788000154],
                [-13.270092010999974, 12.653909683000165],
                [-13.266715050999892, 12.650995254000179],
                [-13.264307022999958, 12.651739121000162],
                [-13.259366035999903, 12.65270996200013],
                [-13.25645732799984, 12.652654647000077],
                [-13.25332164799994, 12.65209198100007],
                [-13.250857922999899, 12.651128611000104],
                [-13.249839000999884, 12.650052999000081],
                [-13.245626365999954, 12.64675327100008],
                [-13.244277953999926, 12.646129609000184],
                [-13.240147590999868, 12.648407936000126],
                [-13.238241195999933, 12.649895668000113],
                [-13.2353754049999, 12.650346756000147],
                [-13.234314917999882, 12.649363517999973],
                [-13.233634947999974, 12.647830963000104],
                [-13.231663703999914, 12.645215036000081],
                [-13.226529120999885, 12.640985490000105],
                [-13.224980277999975, 12.64132790799999],
                [-13.221951485999966, 12.642699243000038],
                [-13.219758032999835, 12.644502640000155],
                [-13.218937873999892, 12.645961760999967],
                [-13.218230998999957, 12.648298000000125],
                [-13.216924667999876, 12.650892257000066],
                [-13.215593999999896, 12.651470999000026],
                [-13.214003999999875, 12.651307],
                [-13.211868998999876, 12.649837000000105],
                [-13.210930823999888, 12.649731634999966],
                [-13.208063998999933, 12.65294799899999],
                [-13.20691799899987, 12.653707999000176],
                [-13.205462455999964, 12.653825760000075],
                [-13.202752113999964, 12.65308380200014],
                [-13.201920000999962, 12.652383001000146],
                [-13.200705, 12.649645],
                [-13.200692176999894, 12.647565841000187],
                [-13.201943397999969, 12.643632889],
                [-13.201534271999947, 12.642935754000177],
                [-13.19974994599994, 12.642713546000095],
                [-13.198521650999965, 12.643383960000108],
                [-13.196176, 12.643115],
                [-13.194220543999904, 12.642341614000031],
                [-13.191341398999896, 12.64076709800014],
                [-13.188872, 12.639710999000044],
                [-13.186417, 12.638194],
                [-13.182481765999967, 12.634994507000101],
                [-13.179041862999895, 12.633280754000111],
                [-13.177660942999978, 12.629474641000058],
                [-13.17616271999998, 12.629130364000105],
                [-13.175312995999946, 12.629667282000128],
                [-13.174458502999926, 12.631925583],
                [-13.173121452999965, 12.63362884500009],
                [-13.170316695999873, 12.638463020000131],
                [-13.168892859999971, 12.639357567000161],
                [-13.167761, 12.639286999000149],
                [-13.164015770999981, 12.63659477200008],
                [-13.16118335799996, 12.63737964600017],
                [-13.158017092999899, 12.636118950000139],
                [-13.157288550999908, 12.63757801100013],
                [-13.158185946999936, 12.639483430000155],
                [-13.158078999999873, 12.640359000000103],
                [-13.156869888999836, 12.642131806000066],
                [-13.155031999999892, 12.642888001000017],
                [-13.152458, 12.642472000000168],
                [-13.151671409999949, 12.641806603000077],
                [-13.148641585999883, 12.640745164000123],
                [-13.146855353999968, 12.640895844000113],
                [-13.143629000999852, 12.64033],
                [-13.142372131999934, 12.639661788000183],
                [-13.13884258399986, 12.635226250000187],
                [-13.137630255999909, 12.634784861000128],
                [-13.136872291999907, 12.635375977000024],
                [-13.138057708999952, 12.639442443000064],
                [-13.136630458999946, 12.643336574000102],
                [-13.136008999999888, 12.643875999000045],
                [-13.132907999999816, 12.643908000000124],
                [-13.131937979999918, 12.64301586300013],
                [-13.129105567999943, 12.638963700000147],
                [-13.126647949999949, 12.637672425000176],
                [-13.119936942999857, 12.640265464000038],
                [-13.117247047999967, 12.641553148000185],
                [-13.115743, 12.641068000000189],
                [-13.115438, 12.640098],
                [-13.11662, 12.63689600000015],
                [-13.11585, 12.63521900000012],
                [-13.1144961359999, 12.635469312000055],
                [-13.108151128999907, 12.635868992000042],
                [-13.106473979999976, 12.635468951000064],
                [-13.104655, 12.634417],
                [-13.103091999999833, 12.633014000000117],
                [-13.100868, 12.628831],
                [-13.100656509999908, 12.627491950999968],
                [-13.099309999999889, 12.626732],
                [-13.097714, 12.627163],
                [-13.095292, 12.62859200000014],
                [-13.093083131999833, 12.628594037000028],
                [-13.091493606999961, 12.625754357000119],
                [-13.089655875999938, 12.626599311],
                [-13.089114188999872, 12.627743722000105],
                [-13.089035, 12.629668],
                [-13.088488, 12.631228000000135],
                [-13.087341, 12.632078],
                [-13.084810152999921, 12.632123987000057],
                [-13.081676, 12.633999],
                [-13.080209, 12.634531],
                [-13.076633, 12.635201],
                [-13.075768153999888, 12.636426793000112],
                [-13.075873, 12.638313000000153],
                [-13.07483, 12.641245],
                [-13.073194999999885, 12.643239],
                [-13.071542, 12.643987],
                [-13.06664943599992, 12.642288209000128],
                [-13.065425769999933, 12.642586325000138],
                [-13.060589, 12.642868],
                [-13.059229, 12.642569],
                [-13.058545, 12.641678000000184],
                [-13.05901351599988, 12.639572712000074],
                [-13.060697554999933, 12.637028695000026],
                [-13.062135696999917, 12.635394096000027],
                [-13.062634, 12.634024000000181],
                [-13.062423705999947, 12.63263988400007],
                [-13.061638, 12.631884],
                [-13.053500174999954, 12.625940323],
                [-13.050591, 12.625974],
                [-13.048263, 12.624871999000106],
                [-13.047318, 12.623112],
                [-13.047459601999947, 12.620256424000047],
                [-13.047276, 12.61768],
                [-13.047651, 12.61561200000017],
                [-13.049852, 12.61293499900006],
                [-13.050135611999849, 12.610732078000183],
                [-13.047742842999924, 12.608391763000043],
                [-13.04659175899991, 12.604866983000079],
                [-13.044940948999908, 12.602903366000078],
                [-13.04359299899994, 12.602188],
                [-13.043143106999935, 12.598546203000069],
                [-13.043593, 12.59496700000011],
                [-13.044218, 12.593868000000157],
                [-13.044596672999944, 12.591304779999973],
                [-13.042206226999951, 12.586244483000087],
                [-13.041867036999975, 12.584767713000076],
                [-13.041899680999904, 12.580438613000183],
                [-13.041612358999942, 12.578364733],
                [-13.042535998999881, 12.574189001000036],
                [-13.043611, 12.572001],
                [-13.047355, 12.567282],
                [-13.051457, 12.563768],
                [-13.052607535999982, 12.56254768500014],
                [-13.052730560999976, 12.56080436800005],
                [-13.052393912999833, 12.559093475000168],
                [-13.052349018999848, 12.554077385000085],
                [-13.05256462099993, 12.549848557000132],
                [-13.053337, 12.54618500000015],
                [-13.055014, 12.544742],
                [-13.061693999999875, 12.54104800000016],
                [-13.063636779999968, 12.539607047000061],
                [-13.066767691999928, 12.535571098000162],
                [-13.06740570099987, 12.533830644000034],
                [-13.066981999999882, 12.531432000000166],
                [-13.065073999999868, 12.52827400000018],
                [-13.065011978999962, 12.526754380000057],
                [-13.064192999999875, 12.523106000000155],
                [-13.06339359299983, 12.517939567000155],
                [-13.062954902999934, 12.51640892000006],
                [-13.062924, 12.51310100000012],
                [-13.063765998999827, 12.503779000000122],
                [-13.064072999999894, 12.502127],
                [-13.065811999999823, 12.497142],
                [-13.065437, 12.49189600000011],
                [-13.065099730999975, 12.490229672000055],
                [-13.063951, 12.48883],
                [-13.062191961999872, 12.485482216000037],
                [-13.0586682469999, 12.483633457999986],
                [-13.054499, 12.483275],
                [-13.049988999999869, 12.481174],
                [-13.047412871999882, 12.48126411600009],
                [-13.043601021999962, 12.482202249000181],
                [-13.040464335999957, 12.481827041000031],
                [-13.03695, 12.479506],
                [-13.034648895999851, 12.479697227000031],
                [-13.032753, 12.481084],
                [-13.032268, 12.482002],
                [-13.029664005999962, 12.483419474000073],
                [-13.02844, 12.483357],
                [-13.024362884999903, 12.481392988999971],
                [-13.023284911999895, 12.481286049],
                [-13.02003, 12.482464],
                [-13.017745971999886, 12.481733323000071],
                [-13.01538276599996, 12.480360031000146],
                [-13.011357307999958, 12.480050087000052],
                [-13.008164461999911, 12.480596579000178],
                [-12.998982999999896, 12.48033],
                [-12.996396999999888, 12.479416000000128],
                [-12.99484399999983, 12.47794],
                [-12.992449760999875, 12.474876404000042],
                [-12.992055892999872, 12.472243308000088],
                [-12.991086004999943, 12.471657753000045],
                [-12.988363264999975, 12.471447946000012],
                [-12.977057457999877, 12.468181610000158],
                [-12.974657057999934, 12.46820926700019],
                [-12.969353676999901, 12.466677665000134],
                [-12.967577933999962, 12.467875481000135],
                [-12.96638107299998, 12.47037887600004],
                [-12.96359443699987, 12.473892213000056],
                [-12.961783408999963, 12.474908828000025],
                [-12.958352088999902, 12.475035667000157],
                [-12.954040526999961, 12.474786760000086],
                [-12.951223371999845, 12.47622013199998],
                [-12.947322844999974, 12.476299286000142],
                [-12.944613456999946, 12.475898742000027],
                [-12.943133352999894, 12.476872445000083],
                [-12.942028998999888, 12.484030723000103],
                [-12.942019462999838, 12.487492562000114],
                [-12.94132137299988, 12.492123605000188],
                [-12.940298079999934, 12.494718551000119],
                [-12.94067001299993, 12.496483804000093],
                [-12.940078734999929, 12.499137878000113],
                [-12.939124107999817, 12.501280784000187],
                [-12.939206123999952, 12.504779815000063],
                [-12.937940596999965, 12.507742882000173],
                [-12.940308570999946, 12.511909484000114],
                [-12.940896033999877, 12.514363289000187],
                [-12.939708709999934, 12.517978669],
                [-12.939970016999894, 12.518630982000047],
                [-12.939994810999906, 12.52245426100012],
                [-12.942060469999944, 12.524214746000041],
                [-12.94582939199995, 12.526809693000075],
                [-12.946259498999893, 12.528249740000149],
                [-12.945657728999947, 12.531092643000079],
                [-12.94389057099994, 12.533529282000131],
                [-12.942407608999872, 12.53602123200011],
                [-12.938260077999928, 12.538212777000126],
                [-12.936096190999933, 12.538422583999989],
                [-12.932169913999871, 12.539377214000126],
                [-12.928675650999935, 12.541446686000143],
                [-12.927664755999956, 12.542478562000042],
                [-12.924777984999935, 12.544516565000038],
                [-12.922387122999908, 12.544498444],
                [-12.920542716999819, 12.544944763000103],
                [-12.91588687899997, 12.545098305000181],
                [-12.913860320999959, 12.545408249000047],
                [-12.910537720999969, 12.546692849000124],
                [-12.908945083999981, 12.54799080000015],
                [-12.905622482999945, 12.54927539800002],
                [-12.899343490999854, 12.548783302000174],
                [-12.894532202999926, 12.551040650000061],
                [-12.892137527999864, 12.552540778000036],
                [-12.889731407999818, 12.553117753000095],
                [-12.887493132999964, 12.552268983000033],
                [-12.886534690999895, 12.55141162800004],
                [-12.879579544999956, 12.550065040000163],
                [-12.87833595199993, 12.549205780000136],
                [-12.878190039999936, 12.54791164400018],
                [-12.879401206999944, 12.544993400000124],
                [-12.882235526999978, 12.542819978000068],
                [-12.882389068999828, 12.540842057000077],
                [-12.88045978599996, 12.539308548000122],
                [-12.879549980999911, 12.536932945000103],
                [-12.878022193999982, 12.534679414000095],
                [-12.87735939099997, 12.53148460400007],
                [-12.87562370199987, 12.52996158500008],
                [-12.866096495999955, 12.52522182500013],
                [-12.86541271099992, 12.524375916000054],
                [-12.86378955799995, 12.523865700000101],
                [-12.856975555999952, 12.522474289],
                [-12.853365898999982, 12.520673752000164],
                [-12.850065231999906, 12.519382476999965],
                [-12.84847450299992, 12.519333840000115],
                [-12.84562778399993, 12.518705367999985],
                [-12.845426559999964, 12.517501831000175],
                [-12.843175888999895, 12.51595592600006],
                [-12.840467451999928, 12.515374184000052],
                [-12.836324690999959, 12.515855789000057],
                [-12.83416366699987, 12.515802384000153],
                [-12.834076880999874, 12.51400375500009],
                [-12.83280086499991, 12.512537956000074],
                [-12.83218765199996, 12.511178017000077],
                [-12.833865164999963, 12.508606912000062],
                [-12.835767746999977, 12.507600785000022],
                [-12.836495398999887, 12.505311013000096],
                [-12.838560104999942, 12.503664970999978],
                [-12.84022426599995, 12.501500129000078],
                [-12.842460632999973, 12.499077798000144],
                [-12.843543053999895, 12.497261048000098],
                [-12.84389114299995, 12.49520397200007],
                [-12.842250822999915, 12.49335575200007],
                [-12.837235450999913, 12.491552353000031],
                [-12.83312988299997, 12.49084186499999],
                [-12.830937385999903, 12.49013710100013],
                [-12.8258085249999, 12.487592697000139],
                [-12.823731422999913, 12.486292839000043],
                [-12.823430060999954, 12.483840942000143],
                [-12.821557998999936, 12.482216835000145],
                [-12.82024860499996, 12.480435372000045],
                [-12.817475319999915, 12.478876114],
                [-12.81550121299989, 12.477441789000068],
                [-12.81359195799996, 12.476974487000177],
                [-12.812300681999943, 12.476150514000096],
                [-12.80897426599995, 12.475870133000058],
                [-12.800093649999894, 12.47769641900004],
                [-12.798799514999928, 12.478489876000026],
                [-12.797529219999888, 12.478589058000068],
                [-12.795580863999874, 12.479502678000188],
                [-12.792634010999961, 12.482000350000078],
                [-12.790538787999878, 12.483049392000169],
                [-12.78807067899993, 12.48233318300015],
                [-12.785835266999982, 12.480245591000084],
                [-12.781406401999845, 12.47771453900009],
                [-12.779110908999883, 12.475996971000086],
                [-12.773119925999879, 12.472187996000173],
                [-12.770919800999934, 12.470326423000074],
                [-12.768784522999908, 12.47270393500014],
                [-12.766779898999971, 12.473752976000128],
                [-12.764573097999971, 12.473734856000078],
                [-12.755304336999927, 12.468217850000087],
                [-12.751656532999903, 12.464708327999972],
                [-12.753576277999969, 12.458543778000092],
                [-12.753765106999936, 12.456936836000182],
                [-12.75603294299998, 12.452019693000068],
                [-12.755842208999923, 12.450635910000017],
                [-12.757730482999932, 12.447091102],
                [-12.759050369999898, 12.44528579700011],
                [-12.762844084999927, 12.439452172000188],
                [-12.763676643999872, 12.436712266000143],
                [-12.763259886999947, 12.434901238],
                [-12.757684707999886, 12.43312931000014],
                [-12.754654, 12.433736],
                [-12.752309998999976, 12.433672000000115],
                [-12.750937, 12.434049],
                [-12.748272, 12.43392800000015],
                [-12.73820600099998, 12.434603],
                [-12.736686, 12.434933000000171],
                [-12.733585999999889, 12.435070000000167],
                [-12.729459000999952, 12.434954001000051],
                [-12.724689, 12.435881000000109],
                [-12.721783, 12.435838000000103],
                [-12.714542, 12.436319],
                [-12.712792, 12.436729],
                [-12.707716000999937, 12.436677001000135],
                [-12.70366619799995, 12.43716698600008],
                [-12.699172043999965, 12.436984001000155],
                [-12.696105999999872, 12.437455000000114],
                [-12.694628, 12.438192000000186],
                [-12.690638861999957, 12.438077012000065],
                [-12.686589, 12.438513000000114],
                [-12.68307623499993, 12.438274206000074],
                [-12.668533634999903, 12.43338056000016],
                [-12.665378742999906, 12.432621998000116],
                [-12.662528038999938, 12.43259811500019],
                [-12.659119058999977, 12.433115537000162],
                [-12.654388, 12.435085000000129],
                [-12.64811, 12.437223000000131],
                [-12.642297999999869, 12.437696000000187],
                [-12.639164999999878, 12.437425000000133],
                [-12.637546252999982, 12.436779695000098],
                [-12.635610000999918, 12.436996],
                [-12.632584977999898, 12.435940003000098],
                [-12.630798999999854, 12.436123],
                [-12.630070685999897, 12.435411454000132],
                [-12.623672403999933, 12.434308083000076],
                [-12.617905615999916, 12.429486275000102],
                [-12.615002419999882, 12.42783693600012],
                [-12.612842999999884, 12.426207],
                [-12.611871, 12.4259],
                [-12.61079, 12.42439],
                [-12.610295, 12.422217],
                [-12.610325812999918, 12.41792583500012],
                [-12.609725972999911, 12.416112961000067],
                [-12.608737, 12.414659],
                [-12.608366965999949, 12.412938119000103],
                [-12.600815999999895, 12.408164],
                [-12.598791, 12.405426],
                [-12.59630584599995, 12.403796196000144],
                [-12.592968, 12.40383],
                [-12.589944999999886, 12.404716],
                [-12.58533099899995, 12.406608001],
                [-12.58289, 12.408195000000148],
                [-12.581186102999936, 12.40851398100017],
                [-12.580548570999952, 12.408030192000183],
                [-12.581175, 12.402794],
                [-12.582879971999944, 12.3993030850001],
                [-12.582514761999903, 12.39601993600013],
                [-12.581032752999931, 12.392238616999975],
                [-12.580307005999941, 12.388211250000097],
                [-12.57937415799995, 12.386667261000184],
                [-12.577259000999902, 12.386784000000148],
                [-12.574476, 12.385602],
                [-12.57195, 12.38239],
                [-12.570142744999941, 12.377041817000077],
                [-12.567753790999916, 12.373929978000092],
                [-12.56573390899996, 12.370740892000015],
                [-12.56320858, 12.367527961],
                [-12.555709838999917, 12.370352746000037],
                [-12.555093764999924, 12.37033748600004],
                [-12.546553611999968, 12.373496056000079],
                [-12.544185638999977, 12.374070168000173],
                [-12.542783737999969, 12.375594139000043],
                [-12.539576529999977, 12.377435685000023],
                [-12.536567686999888, 12.380761146000054],
                [-12.53421115899988, 12.383965492000186],
                [-12.532047271999886, 12.387307168000063],
                [-12.530786513999942, 12.388470650000102],
                [-12.525650024999948, 12.392146110000056],
                [-12.522607802999914, 12.39500045799997],
                [-12.520456313999887, 12.3979806910001],
                [-12.517002104999904, 12.40170765],
                [-12.51528835199997, 12.404050828000095],
                [-12.511110305999864, 12.405493737000029],
                [-12.506675720999851, 12.404992103999973],
                [-12.501888273999953, 12.404812813000149],
                [-12.498520850999967, 12.405857087000072],
                [-12.495611189999977, 12.407383919000097],
                [-12.491927146999899, 12.408245087000125],
                [-12.487645148999889, 12.40715694500011],
                [-12.484924316999923, 12.407122612000023],
                [-12.48147487499989, 12.406376839000075],
                [-12.476361274999931, 12.404747963000148],
                [-12.473939894999944, 12.404219628000021],
                [-12.471293449999905, 12.403219224],
                [-12.462071418999926, 12.40243721000013],
                [-12.455162046999874, 12.40117073000016],
                [-12.451001166999959, 12.400815964000117],
                [-12.446310996999898, 12.39925384500009],
                [-12.444841385999894, 12.398191453000152],
                [-12.443670272999896, 12.396636010000122],
                [-12.436730385999908, 12.391963006000026],
                [-12.433454512999958, 12.388643265000042],
                [-12.431035995999935, 12.387833595000018],
                [-12.429097175999914, 12.386713028000145],
                [-12.420442579999872, 12.384481431000154],
                [-12.416789053999935, 12.38409423800016],
                [-12.411803244999874, 12.384452819000103],
                [-12.408827780999957, 12.385147094000047],
                [-12.404218672999889, 12.385609626000132],
                [-12.40028381299993, 12.383882523000125],
                [-12.398589133999963, 12.382447243000058],
                [-12.394952772999943, 12.380171776000168],
                [-12.393267631999947, 12.37864685000011],
                [-12.391551970999899, 12.376480103000176],
                [-12.38967418599998, 12.374944686000049],
                [-12.390178679999963, 12.372248650000074],
                [-12.389945983999894, 12.370718002999979],
                [-12.387323378999895, 12.369211197000027],
                [-12.383913993999954, 12.365509987000053],
                [-12.382646560999888, 12.362715721000086],
                [-12.382311820999917, 12.361230851000187],
                [-12.380636214999925, 12.357817651],
                [-12.378726004999976, 12.353100777],
                [-12.374897002999887, 12.346351623999965],
                [-12.37336540199982, 12.344241142000158],
                [-12.37316989999988, 12.341654779000123],
                [-12.368702887999973, 12.339028358000178],
                [-12.367255210999872, 12.338662148000026],
                [-12.364844321999897, 12.33624458300011],
                [-12.363936424999906, 12.332341195000083],
                [-12.36303329499998, 12.329874992000043],
                [-12.362373351999906, 12.326814651000063],
                [-12.36209011099993, 12.323903084000108],
                [-12.36074447599998, 12.318840026999965],
                [-12.35868358599987, 12.314447404000077],
                [-12.356794357999888, 12.308682441000087],
                [-12.355745315999968, 12.307859420000113],
                [-12.352942466999934, 12.308889389000171],
                [-12.34804153499988, 12.311046600000054],
                [-12.344420432999982, 12.313007354000092],
                [-12.342956541999968, 12.313282967000077],
                [-12.33882617899991, 12.314732552],
                [-12.336866378999957, 12.315915108000183],
                [-12.333831786999951, 12.317024232000108],
                [-12.327441214999908, 12.320196151000118],
                [-12.323894500999927, 12.321146012999975],
                [-12.319871902999978, 12.323654175000058],
                [-12.317032813999901, 12.324529649],
                [-12.31329727199983, 12.326859475000049],
                [-12.309118270999932, 12.328489304000186],
                [-12.307537078999928, 12.329548836000072],
                [-12.306165694999947, 12.329734803000122],
                [-12.301958082999874, 12.332285882000065],
                [-12.298626899999931, 12.333744048000085],
                [-12.292669295999872, 12.336792946000116],
                [-12.28996944399995, 12.337452889000076],
                [-12.284041403999936, 12.336715698000091],
                [-12.280778885999894, 12.335834504000047],
                [-12.279553412999917, 12.336210251000125],
                [-12.27784347599993, 12.33809948000004],
                [-12.276017189999891, 12.33960819300006],
                [-12.273715971999934, 12.34267425600018],
                [-12.270579337999834, 12.343862535000028],
                [-12.267754554999954, 12.344241142000158],
                [-12.265151977999835, 12.34628391300015],
                [-12.2622509, 12.346010208000166],
                [-12.26131534599989, 12.347591400000056],
                [-12.259735106999926, 12.34846973499998],
                [-12.256180762999975, 12.348225594000155],
                [-12.253246307999916, 12.350346566000155],
                [-12.249924659999976, 12.351667405000057],
                [-12.246922493999932, 12.353146552000169],
                [-12.244687079999949, 12.352527618000124],
                [-12.241906166999911, 12.351288795000187],
                [-12.241087912999888, 12.350485802000094],
                [-12.238455771999952, 12.34915828800007],
                [-12.233866691999879, 12.350402831000054],
                [-12.22974586399988, 12.351707460000114],
                [-12.228241920999949, 12.353137017000165],
                [-12.226088522999873, 12.356060029000105],
                [-12.22349643699988, 12.3579216010001],
                [-12.221062659999973, 12.35784244500013],
                [-12.219026564999979, 12.358291626000096],
                [-12.214478493999934, 12.358200074000138],
                [-12.21057415099989, 12.355585099000052],
                [-12.207385063999823, 12.355588914000123],
                [-12.202752113999907, 12.356688501000065],
                [-12.200019835999967, 12.357021331000055],
                [-12.196294783999974, 12.359069825000063],
                [-12.193246840999905, 12.358795167000096],
                [-12.19066238299996, 12.359039306000113],
                [-12.188960075999887, 12.360918046000165],
                [-12.185199736999891, 12.360853196000107],
                [-12.183626175999962, 12.359472276000076],
                [-12.181380272999945, 12.359448433000125],
                [-12.17780017799987, 12.360232354000061],
                [-12.17643260899996, 12.361520767000172],
                [-12.176010130999884, 12.363300322999976],
                [-12.176077841999927, 12.365056038000034],
                [-12.17476654099994, 12.366458893000015],
                [-12.170903206999867, 12.368872642000156],
                [-12.165839194999819, 12.371010780000177],
                [-12.16407489699992, 12.371924400000069],
                [-12.16158580799987, 12.372690201000125],
                [-12.159821509999915, 12.373635292000074],
                [-12.157812118999914, 12.37394046900016],
                [-12.154322624999963, 12.375888825000061],
                [-12.152785300999938, 12.376992225000095],
                [-12.150208471999974, 12.379910469000151],
                [-12.14871025199983, 12.384148598000024],
                [-12.144888877999904, 12.384768485999984],
                [-12.142235755999877, 12.385472297000035],
                [-12.140967368999895, 12.385431290000156],
                [-12.137977598999896, 12.386628151000139],
                [-12.133265495999922, 12.38735580500014],
                [-12.131408690999933, 12.388220788000183],
                [-12.130523681999819, 12.389305114000024],
                [-12.128868101999956, 12.39280128500019],
                [-12.126959799999895, 12.395038605000025],
                [-12.125297546999946, 12.395861626],
                [-12.123252868999884, 12.397916795000128],
                [-12.122532844999967, 12.399878502000035],
                [-12.119626043999915, 12.404300689000081],
                [-12.117764471999976, 12.408120156000166],
                [-12.117362022999885, 12.41050052700001],
                [-12.116569518999881, 12.413237573000174],
                [-12.112830162999899, 12.415655136000112],
                [-12.112150191999888, 12.41632557],
                [-12.108633993999831, 12.4175977700001],
                [-12.107091903999958, 12.417553903000112],
                [-12.105129241999919, 12.418688774000088],
                [-12.103167532999976, 12.418396950000101],
                [-12.099918365999883, 12.418849946000137],
                [-12.097911833999945, 12.419939995000163],
                [-12.09453392099988, 12.421267509000074],
                [-12.093641280999918, 12.42137527500006],
                [-12.08872604399994, 12.425324440000054],
                [-12.084374426999943, 12.424952507000114],
                [-12.082396506999885, 12.425129890000164],
                [-12.082127571999933, 12.426219941],
                [-12.075983046999852, 12.429026603000068],
                [-12.072992323999927, 12.428568839000036],
                [-12.069622993999928, 12.42832470000019],
                [-12.067731856999956, 12.428507806000027],
                [-12.0669384, 12.429993631000116],
                [-12.064238548999867, 12.432304382000098],
                [-12.06108665399995, 12.433949471000062],
                [-12.059601783999824, 12.43353652899998],
                [-12.057168006999973, 12.431757925999989],
                [-12.05649948099989, 12.430720330000156],
                [-12.054454802999942, 12.428729057000112],
                [-12.052311896999981, 12.428019523000046],
                [-12.042641638999953, 12.428266525000083],
                [-12.03993511199991, 12.427812576000065],
                [-12.038378714999908, 12.426476479000087],
                [-12.03480243699994, 12.42254447900018],
                [-12.033001899999931, 12.42013931400004],
                [-12.031906128999935, 12.417887688000064],
                [-12.030136107999965, 12.41589927800004],
                [-12.02663803199988, 12.41404533400015],
                [-12.025327682999944, 12.413723946],
                [-12.020472525999935, 12.41150283899998],
                [-12.018474578999928, 12.411119461000055],
                [-12.016323088999968, 12.408006667999985],
                [-12.015809058999878, 12.406339645],
                [-12.013777731999937, 12.404013634000023],
                [-12.011178016999963, 12.403154373000064],
                [-12.009910582999908, 12.403113366000127],
                [-12.006556510999928, 12.403789520000146],
                [-12.004425048999963, 12.404551506000132],
                [-12.000124931999892, 12.405380250000121],
                [-11.995645521999904, 12.405791282000052],
                [-11.994100569999887, 12.405105592000155],
                [-11.990220069999964, 12.40533352000017],
                [-11.987794876999942, 12.406065942],
                [-11.984289169999954, 12.40652370600003],
                [-11.981198309999854, 12.4083671570001],
                [-11.979052543999956, 12.408777237000095],
                [-11.977695465999943, 12.408545493000076],
                [-11.973819731999981, 12.406840326000122],
                [-11.972275733999936, 12.40679550100009],
                [-11.96975231099998, 12.408131599000114],
                [-11.96558761599988, 12.409006120000129],
                [-11.961472510999954, 12.410522461000085],
                [-11.949928284999828, 12.41792583500012],
                [-11.948620795999886, 12.418200493000143],
                [-11.947125435999908, 12.419583321000118],
                [-11.943430899999953, 12.421971322000104],
                [-11.938984871999935, 12.424223900000129],
                [-11.936641691999966, 12.426473618000102],
                [-11.926695823999921, 12.431091310000113],
                [-11.922482490999926, 12.433371544000124],
                [-11.91533279399988, 12.438159943000073],
                [-11.911159515999827, 12.442150116000107],
                [-11.90297794199995, 12.446978569],
                [-11.899466514999915, 12.4494142530001],
                [-11.89806938199996, 12.449371339000095],
                [-11.89605998999997, 12.443757058000187],
                [-11.895546913999965, 12.441267014000061],
                [-11.894470215999888, 12.439105988000108],
                [-11.893682478999892, 12.43653202000013],
                [-11.893682478999892, 12.434228897000082],
                [-11.892378807999933, 12.43183899000013],
                [-11.891859053999951, 12.429990767999982],
                [-11.889945028999932, 12.427142144000186],
                [-11.887753485999951, 12.425987243000122],
                [-11.88574504899998, 12.42557621100002],
                [-11.881209373999923, 12.423817634000102],
                [-11.877470016999894, 12.421471596000174],
                [-11.873685836999869, 12.41981124900019],
                [-11.86933612799993, 12.420130731000143],
                [-11.865456580999933, 12.419535638000184],
                [-11.86298465799996, 12.4187936780001],
                [-11.86200046499988, 12.415756226000099],
                [-11.861443518999977, 12.412398339000049],
                [-11.853875160999962, 12.40456199700003],
                [-11.851405142999965, 12.402908326000045],
                [-11.849625586999878, 12.40272521999998],
                [-11.848269462999951, 12.401616096000055],
                [-11.846961974999942, 12.39881134000018],
                [-11.846690176999914, 12.396965981000164],
                [-11.844869613999833, 12.396366119999982],
                [-11.84346199099997, 12.396503449000079],
                [-11.840425490999905, 12.399168014],
                [-11.838504790999878, 12.399994850000041],
                [-11.836771964999969, 12.401193619000026],
                [-11.834856986999966, 12.400683403000073],
                [-11.833174705999966, 12.401648522000073],
                [-11.829991339999879, 12.404220581000175],
                [-11.827893255999925, 12.40297699000007],
                [-11.826346396999952, 12.402471543000047],
                [-11.822798729999931, 12.403387069000132],
                [-11.821486472999823, 12.402514458000155],
                [-11.817231178999975, 12.402599335000161],
                [-11.814663886999938, 12.402966499000172],
                [-11.812372206999953, 12.402551652000056],
                [-11.811204909999901, 12.401120184999968],
                [-11.81092739099995, 12.399744033000161],
                [-11.809807776999946, 12.399604797],
                [-11.807040213999926, 12.402822495000123],
                [-11.805457114999967, 12.403833390000045],
                [-11.803626060999875, 12.405582429000049],
                [-11.80082034999998, 12.405856132000054],
                [-11.799378394999962, 12.405071258000135],
                [-11.798018455999966, 12.40359211100008],
                [-11.794801712999913, 12.401387215000057],
                [-11.794007300999908, 12.40023136100018],
                [-11.793543815999953, 12.396875381000143],
                [-11.79189968199995, 12.396828652000067],
                [-11.79082393699997, 12.397655488000112],
                [-11.788489341999878, 12.397745132000068],
                [-11.785594938999964, 12.3970069880001],
                [-11.781104088999882, 12.396167756000125],
                [-11.778632162999827, 12.396256447000098],
                [-11.776668547999975, 12.395339012000136],
                [-11.77404880599994, 12.39546966600011],
                [-11.770499228999881, 12.391969681000148],
                [-11.768028258999891, 12.388978004000137],
                [-11.765971183999966, 12.385937691000038],
                [-11.762508391999972, 12.386628151000139],
                [-11.76031875699988, 12.386158944000101],
                [-11.758864401999972, 12.386340142],
                [-11.75465393199994, 12.388132096000106],
                [-11.752454758999875, 12.389875413000027],
                [-11.74984168899988, 12.390982628000074],
                [-11.745732306999969, 12.389732362000188],
                [-11.743765831999951, 12.390512466000075],
                [-11.741566657999897, 12.39009761900013],
                [-11.737643241999876, 12.390916825000033],
                [-11.735205649999898, 12.39119529800007],
                [-11.73352622899995, 12.391057014000012],
                [-11.731654166999874, 12.390176773000121],
                [-11.72969436699998, 12.391092300000082],
                [-11.726791380999941, 12.391048431000115],
                [-11.724592207999876, 12.392836572000022],
                [-11.720151899999962, 12.397523879000119],
                [-11.718563080999957, 12.398994446000074],
                [-11.716827391999857, 12.401158332000023],
                [-11.712893484999881, 12.402763367000034],
                [-11.710090635999961, 12.402114869000059],
                [-11.705650329999912, 12.401645660000042],
                [-11.702712057999975, 12.40154743300019],
                [-11.70111942299991, 12.401130676000037],
                [-11.69943523399985, 12.401362419000122],
                [-11.69616222399992, 12.402324676999967],
                [-11.692654608999874, 12.4043416990001],
                [-11.68909740499987, 12.40668201599999],
                [-11.686333655999931, 12.40944767000002],
                [-11.684319495999887, 12.41109371300007],
                [-11.683433531999867, 12.412888527000064],
                [-11.681982040999856, 12.414361001000032],
                [-11.679683685999919, 12.41739368500015],
                [-11.678144453999948, 12.418494225000074],
                [-11.673645972999964, 12.419870376000176],
                [-11.672152518999951, 12.420831681000038],
                [-11.671362876999979, 12.422997474999988],
                [-11.669205664999936, 12.424281120999979],
                [-11.66812515199996, 12.42543125200001],
                [-11.666583061999916, 12.426072122000107],
                [-11.663222312999949, 12.426707266999983],
                [-11.661352156999897, 12.427813531000083],
                [-11.65947341899988, 12.428177833000177],
                [-11.65722846899996, 12.429134368000177],
                [-11.649325370999918, 12.430734634999965],
                [-11.647040366999875, 12.429766655000037],
                [-11.642874716999927, 12.43151187899997],
                [-11.639128685999935, 12.432792664000033],
                [-11.636093138999968, 12.433107377],
                [-11.631742476999932, 12.434986115000015],
                [-11.62996482899996, 12.435307502000057],
                [-11.626223563999929, 12.434800148000022],
                [-11.623099326999977, 12.431979179000109],
                [-11.622021673999882, 12.430051804000072],
                [-11.62076568599997, 12.42916965500018],
                [-11.619318962999898, 12.428798675000053],
                [-11.616280554999946, 12.42857170200017],
                [-11.608603477999964, 12.432602883000015],
                [-11.605607986999928, 12.432691575000092],
                [-11.601918219999902, 12.43171501200004],
                [-11.599955557999976, 12.431527138000092],
                [-11.597756384999968, 12.433179855000162],
                [-11.595644950999883, 12.43515777500005],
                [-11.592982290999942, 12.437227250000092],
                [-11.588958739999953, 12.439334869000163],
                [-11.582781790999888, 12.439509392000105],
                [-11.5768928519999, 12.440229416000022],
                [-11.574737548999906, 12.439949035000154],
                [-11.569691656999964, 12.441555022999978],
                [-11.563419342999964, 12.441403390000119],
                [-11.557581901999924, 12.44171714800018],
                [-11.55566120199984, 12.442397117000041],
                [-11.55327415499994, 12.442348480000021],
                [-11.550230979999867, 12.443266868000137],
                [-11.543965338999953, 12.443249702000116],
                [-11.540646553999977, 12.444119453000098],
                [-11.53713893899993, 12.44397544900005],
                [-11.534472464999851, 12.44346237200017],
                [-11.531813621999959, 12.44451141400009],
                [-11.527270316999818, 12.44487762599999],
                [-11.524791717999904, 12.445468903000176],
                [-11.521518706999814, 12.445643424000139],
                [-11.512820244999943, 12.446912766000025],
                [-11.507861136999963, 12.446812628999965],
                [-11.504357337999977, 12.44781398800012],
                [-11.49762248999997, 12.44858455700006],
                [-11.495192528999837, 12.448300361000122],
                [-11.490000724999959, 12.449071885000023],
                [-11.486401556999965, 12.44897174800019],
                [-11.477556229999948, 12.450201035000191],
                [-11.473443031999977, 12.450048448000075],
                [-11.46980380899987, 12.447419167000078],
                [-11.468358038999895, 12.445712090000143],
                [-11.465227126999935, 12.443549156000074],
                [-11.461816786999918, 12.443035127000087],
                [-11.458261489999927, 12.443756103000055],
                [-11.45395660399987, 12.443564414000093],
                [-11.452089308999916, 12.44305229300005],
                [-11.449989317999894, 12.441490174000023],
                [-11.447329520999915, 12.440516472000127],
                [-11.445734977999962, 12.440233231000036],
                [-11.44035530099984, 12.439990997000052],
                [-11.43320655899987, 12.441494942000077],
                [-11.431330680999906, 12.441027641000062],
                [-11.429188727999872, 12.43909454500016],
                [-11.424511908999818, 12.438572883000177],
                [-11.42175102199991, 12.437605857999984],
                [-11.41862392299987, 12.437275887000112],
                [-11.414326666999955, 12.435142517000031],
                [-11.413489341999878, 12.433299065000085],
                [-11.412418364999894, 12.432336808000116],
                [-11.411951064999869, 12.430904388999977],
                [-11.407842635999941, 12.427672387000086],
                [-11.405265806999978, 12.426654817000099],
                [-11.405790328999956, 12.42532539399997],
                [-11.404388427999947, 12.423113823000051],
                [-11.40220165299985, 12.4204845430001],
                [-11.397810935999928, 12.418538094000098],
                [-11.394210814999951, 12.418535234000046],
                [-11.385980605999919, 12.41795635200009],
                [-11.383030890999919, 12.418089868000095],
                [-11.381397246999938, 12.41868209800009],
                [-11.377387045999967, 12.41798591600002],
                [-11.3773069369999, 12.416150092],
                [-11.37329482899986, 12.41670131800015],
                [-11.372146605999887, 12.417531967000059],
                [-11.371109008999838, 12.419489861000045],
                [-11.368609428999946, 12.418863297000087],
                [-11.367496489999894, 12.418926240000019],
                [-11.36547088499998, 12.419881822000036],
                [-11.364527702999908, 12.421938896000086],
                [-11.364190101999952, 12.42880725800012],
                [-11.362686156999871, 12.432073593000098],
                [-11.36270141499989, 12.43562221500008],
                [-11.363907813999958, 12.437586784000189],
                [-11.365092276999917, 12.4384698880001],
                [-11.367382049999947, 12.439491273000101],
                [-11.368312835999916, 12.442721367000047],
                [-11.369611738999936, 12.444618226000046],
                [-11.369229315999917, 12.448894501000098],
                [-11.369335173999957, 12.450569153000117],
                [-11.3712263099999, 12.453217507000033],
                [-11.369629859999918, 12.453840256000149],
                [-11.363595008999937, 12.454109193000079],
                [-11.361878393999916, 12.45346736800019],
                [-11.360455512999977, 12.454609872000049],
                [-11.360335349999957, 12.456333160000156],
                [-11.356878279999933, 12.459479332000058],
                [-11.356363295999927, 12.460656164999989],
                [-11.356346129999963, 12.46412658800017],
                [-11.354965209999932, 12.464381217000039],
                [-11.353742599999919, 12.463464737000038],
                [-11.351898193999887, 12.463068008000164],
                [-11.349580764999928, 12.463540078000165],
                [-11.347989082999845, 12.465695381000046],
                [-11.349400519999904, 12.468030930000111],
                [-11.349557876999882, 12.469541549000098],
                [-11.348873137999874, 12.471496583000032],
                [-11.34886360199988, 12.474078179000117],
                [-11.349591255999883, 12.476099969000074],
                [-11.351667402999965, 12.479422569],
                [-11.353461264999908, 12.480410576000168],
                [-11.354541777999941, 12.480502128000069],
                [-11.357880591999901, 12.481681823000088],
                [-11.35781669599993, 12.482831954000119],
                [-11.356476783999938, 12.484572411000158],
                [-11.354384422999885, 12.488261224000098],
                [-11.350674628999911, 12.486000060000038],
                [-11.350358961999973, 12.489088060000142],
                [-11.350297928999908, 12.492424965999987],
                [-11.350559233999945, 12.494411469000113],
                [-11.351577758999952, 12.496597288999965],
                [-11.352089882999962, 12.500242233000108],
                [-11.352799415999925, 12.501877783999987],
                [-11.354178427999898, 12.503555298000038],
                [-11.354513168999972, 12.50703334800005],
                [-11.355141638999953, 12.508225442000139],
                [-11.359191893999935, 12.511053086000118],
                [-11.36099910799993, 12.511782646000086],
                [-11.363774299999932, 12.512136460000079],
                [-11.366939543999933, 12.511636734000092],
                [-11.369049072999928, 12.509293557000092],
                [-11.37050056499993, 12.505332946000067],
                [-11.37325954499994, 12.500284195000063],
                [-11.373818397999912, 12.49799823799998],
                [-11.374816894999924, 12.496010782000042],
                [-11.374212264999926, 12.494305612000176],
                [-11.373037336999971, 12.493867873999989],
                [-11.373251914999969, 12.492555618000154],
                [-11.372950552999953, 12.48922061899998],
                [-11.372146605999887, 12.486897470000088],
                [-11.373103141999877, 12.486732482000093],
                [-11.37524414099994, 12.489676475000067],
                [-11.377944946999946, 12.49175453200013],
                [-11.378274917999875, 12.494336128000043],
                [-11.37685585099996, 12.49898052300017],
                [-11.376662253999939, 12.502349854000158],
                [-11.37546634599994, 12.505228044000091],
                [-11.375432968999917, 12.507088661000125],
                [-11.37486171799992, 12.510280610000166],
                [-11.375063896999961, 12.51240158000013],
                [-11.374667167999974, 12.513605118999976],
                [-11.374881743999879, 12.518817903000183],
                [-11.377166747999922, 12.523328782000078],
                [-11.379759788999934, 12.524676323000108],
                [-11.381919860999915, 12.525391578999972],
                [-11.387637138999935, 12.525605201000076],
                [-11.385520934999818, 12.527079583000045],
                [-11.384644507999951, 12.529445649000024],
                [-11.38462257499998, 12.530959129000053],
                [-11.38309478799988, 12.533850670000106],
                [-11.382735251999975, 12.53781509300012],
                [-11.38379955399995, 12.53964710200006],
                [-11.386001586999953, 12.540991784000141],
                [-11.3875598899999, 12.541039467000076],
                [-11.391248702999974, 12.540443421000134],
                [-11.39339733099996, 12.540684700000156],
                [-11.396809577999932, 12.539638519000164],
                [-11.398240089999888, 12.541872026000135],
                [-11.401437759999965, 12.54393386800001],
                [-11.403964043999906, 12.54409408500004],
                [-11.408289908999905, 12.542655945000092],
                [-11.409743308999964, 12.540774344999988],
                [-11.411060333999956, 12.536789895000027],
                [-11.411148070999957, 12.530977250000035],
                [-11.41063022599991, 12.528584481],
                [-11.410833357999934, 12.526845932000128],
                [-11.411431312999923, 12.52579498300014],
                [-11.413204193999889, 12.525774956000191],
                [-11.414794920999896, 12.526769638000076],
                [-11.416986465999969, 12.527198791000103],
                [-11.420503615999905, 12.527486802000055],
                [-11.42274284399997, 12.528656959000159],
                [-11.423373222999942, 12.530099868999969],
                [-11.423391341999888, 12.537961007000092],
                [-11.422919273999923, 12.542913437000038],
                [-11.422994612999958, 12.544794083000056],
                [-11.423753738999949, 12.546349526000085],
                [-11.426335334999976, 12.549216270000102],
                [-11.427183151999941, 12.550491332000092],
                [-11.429776190999974, 12.551419258000124],
                [-11.432209967999938, 12.55152130100015],
                [-11.434922218999816, 12.550913812000033],
                [-11.43722915699982, 12.549453736000032],
                [-11.438537598999972, 12.547709465000025],
                [-11.439345358999958, 12.545672417000105],
                [-11.440267562999964, 12.545128823000027],
                [-11.442978858999936, 12.548218728000165],
                [-11.44234466499995, 12.550703049000106],
                [-11.441134452999904, 12.552770614000053],
                [-11.440946578999899, 12.554342270000063],
                [-11.439766883999937, 12.556259155000134],
                [-11.438718794999886, 12.557173730000045],
                [-11.437882422999962, 12.55868530400005],
                [-11.438055991999931, 12.564056398000162],
                [-11.437668800999973, 12.564933777000022],
                [-11.434221266999884, 12.565540315000135],
                [-11.433463095999969, 12.566180230000043],
                [-11.427517889999933, 12.56405449],
                [-11.425300598999968, 12.563812256000062],
                [-11.420915602999969, 12.562933923000116],
                [-11.41651630399997, 12.563379288000078],
                [-11.413497924999945, 12.565659523000022],
                [-11.408229828999822, 12.57067966599999],
                [-11.406122206999953, 12.571903228000167],
                [-11.405234335999864, 12.573110580000048],
                [-11.405200958999956, 12.574298858000134],
                [-11.401915549999956, 12.585550308000109],
                [-11.400728224999966, 12.585957527000119],
                [-11.40010738299992, 12.58691883199998],
                [-11.39966297199993, 12.589602469999988],
                [-11.399778364999975, 12.59130096500013],
                [-11.400540350999961, 12.592975616000047],
                [-11.402075767999975, 12.594881057],
                [-11.40521812499992, 12.597925187000044],
                [-11.408813475999978, 12.600561141000185],
                [-11.411986351999929, 12.602639198000077],
                [-11.414259910999931, 12.603292466000084],
                [-11.416469574999894, 12.604538917000127],
                [-11.417954443999918, 12.604908942000122],
                [-11.419225693999863, 12.609775543000126],
                [-11.419316290999973, 12.613163949000011],
                [-11.418548584999883, 12.614621162000049],
                [-11.417015074999938, 12.615982056000064],
                [-11.415792464999925, 12.616516113999978],
                [-11.41221523299987, 12.616846084000144],
                [-11.408435820999898, 12.618850708000082],
                [-11.40639972799994, 12.620926858000075],
                [-11.40487289399988, 12.62309074400008],
                [-11.403733252999928, 12.625388147000024],
                [-11.403286933999937, 12.627684594000016],
                [-11.403509139999926, 12.629533768000101],
                [-11.403397559999917, 12.635521889000017],
                [-11.404473304999954, 12.637115479000101],
                [-11.406137465999961, 12.63794517499997],
                [-11.408501623999939, 12.638645173000157],
                [-11.40828228099997, 12.643095017000121],
                [-11.409061431999874, 12.64657783500013],
                [-11.409794806999969, 12.651194573000055],
                [-11.411623954999982, 12.65325164799998],
                [-11.412447928999939, 12.654926301000103],
                [-11.415568351999923, 12.65941143100008],
                [-11.415865898999868, 12.660754204000114],
                [-11.41948890599997, 12.664834023000083],
                [-11.420795439999893, 12.665488243000027],
                [-11.422985076999964, 12.665435791000107],
                [-11.427237511999977, 12.663159372000166],
                [-11.428782463999937, 12.66260242500016],
                [-11.432759284999975, 12.662542343000155],
                [-11.43495655099997, 12.66341590900015],
                [-11.44058799699991, 12.66403103000016],
                [-11.444704054999931, 12.671514511],
                [-11.444586754999932, 12.673210145000155],
                [-11.443854332999877, 12.67516136200004],
                [-11.442833899999926, 12.676471711000033],
                [-11.439889906999952, 12.677035332],
                [-11.435084341999982, 12.679942131000075],
                [-11.434060096999872, 12.681279183000072],
                [-11.43340778399994, 12.683662415000072],
                [-11.432752609999966, 12.687972069000125],
                [-11.432843206999905, 12.691928865000136],
                [-11.433600424999952, 12.696949005000022],
                [-11.434247969999888, 12.699429512000165],
                [-11.434227943999929, 12.70085716300008],
                [-11.43329238799987, 12.701416017000156],
                [-11.431425094999895, 12.696760179000023],
                [-11.430283546999874, 12.695581436000111],
                [-11.429702759999941, 12.694238663000135],
                [-11.42866420699994, 12.693261147000044],
                [-11.426047325999946, 12.693084717000033],
                [-11.423691749999875, 12.69386577600011],
                [-11.421523093999951, 12.695161819000134],
                [-11.42019081199993, 12.696799278000128],
                [-11.419349669999974, 12.70070266800019],
                [-11.419292449999944, 12.703768730000036],
                [-11.419572829999879, 12.705584527000099],
                [-11.420409201999973, 12.707978249000064],
                [-11.420844076999913, 12.71112346700005],
                [-11.420764922999922, 12.716759681000099],
                [-11.420033453999906, 12.719756126000163],
                [-11.420101164999892, 12.721681594000131],
                [-11.42077255199996, 12.724900245000072],
                [-11.420653342999856, 12.726103784000088],
                [-11.418256758999973, 12.727051736000021],
                [-11.416271209999934, 12.72733306900011],
                [-11.413084983999966, 12.726928710000152],
                [-11.410127639999814, 12.726274492000186],
                [-11.407575607999945, 12.726177215000064],
                [-11.404097556999943, 12.726541518999966],
                [-11.397671698999943, 12.726857186000075],
                [-11.393454550999877, 12.727203370000097],
                [-11.391651153999874, 12.726339340000038],
                [-11.388720511999963, 12.725567819000048],
                [-11.382973669999899, 12.725301743999978],
                [-11.381050109999933, 12.725573538999981],
                [-11.37817955, 12.726660730000162],
                [-11.372273445999895, 12.727620125000044],
                [-11.370066642999916, 12.727805138000178],
                [-11.363557814999979, 12.727606774000094],
                [-11.3614263529999, 12.72815132200003],
                [-11.360198020999974, 12.729018211000096],
                [-11.359751701999926, 12.730172157000027],
                [-11.36005687699992, 12.732067108000024],
                [-11.361404418999882, 12.73463535400009],
                [-11.362558365999973, 12.737849236000045],
                [-11.363879202999897, 12.73975181600008],
                [-11.36598777699993, 12.740999222000085],
                [-11.36908245199993, 12.740186691000133],
                [-11.370860099999959, 12.739993095000102],
                [-11.375307082999825, 12.74068260200005],
                [-11.380151747999946, 12.744661331000145],
                [-11.378275869999982, 12.745984078000106],
                [-11.375197410999874, 12.748740197000132],
                [-11.374115944999858, 12.750595094000062],
                [-11.373865127999977, 12.754136085000027],
                [-11.375180244999967, 12.756292343000098],
                [-11.376194, 12.758616447000179],
                [-11.38097763099995, 12.765232087000072],
                [-11.38432598099996, 12.769324304000179],
                [-11.38646221099998, 12.770500184000127],
                [-11.388008117999902, 12.770524025000043],
                [-11.392555236999897, 12.772114755000075],
                [-11.393648147999897, 12.77394294800007],
                [-11.390348433999918, 12.773873330000185],
                [-11.385710715999892, 12.77445984000019],
                [-11.382261276999941, 12.776618958000142],
                [-11.380702971999881, 12.777277947000073],
                [-11.3794679639999, 12.77865028399998],
                [-11.378896713999893, 12.779996872000027],
                [-11.377878188999944, 12.784173012000053],
                [-11.378049849999911, 12.788402558000087],
                [-11.378387449999877, 12.78944396899999],
                [-11.378833770999961, 12.794499397000152],
                [-11.378480911999873, 12.796216964000052],
                [-11.377319334999981, 12.799240113000167],
                [-11.377109527999835, 12.801113129000044],
                [-11.377747534999969, 12.805074692],
                [-11.378489494999883, 12.8066053390001],
                [-11.380117415999905, 12.808605195000041],
                [-11.381721496999944, 12.809774399000105],
                [-11.382008552999878, 12.810662269000034],
                [-11.384032249999905, 12.81391239100003],
                [-11.381602287999954, 12.81897068000012],
                [-11.3809576029999, 12.819951059000175],
                [-11.379757881999922, 12.823674203],
                [-11.378650664999896, 12.826164245000143],
                [-11.378072737999958, 12.829083442000183],
                [-11.378145218999975, 12.831099511000104],
                [-11.378940580999881, 12.832057954000106],
                [-11.381612778999909, 12.83286952899999],
                [-11.382722853999951, 12.83251857800019],
                [-11.383802413999888, 12.83315086500005],
                [-11.384947777999912, 12.833003045000055],
                [-11.386929512999927, 12.831898689000184],
                [-11.388166427999863, 12.829794885000183],
                [-11.389750479999918, 12.828633308000065],
                [-11.392114638999942, 12.828287125000145],
                [-11.394865034999953, 12.82845401700007],
                [-11.398641586999872, 12.82774448400005],
                [-11.399272918999884, 12.831843376000108],
                [-11.397758482999905, 12.836619377000147],
                [-11.397761344999878, 12.839222909000057],
                [-11.399687765999943, 12.84263515400005],
                [-11.401006697999833, 12.843596459000082],
                [-11.403693198999974, 12.844305038000186],
                [-11.40493011399991, 12.845206260000168],
                [-11.405604362999838, 12.84729766800018],
                [-11.400510786999916, 12.850124360000109],
                [-11.396498678999933, 12.851761817000181],
                [-11.393681526999899, 12.854166032000023],
                [-11.391311645999963, 12.858140946000105],
                [-11.390715597999929, 12.859538079000174],
                [-11.390530586999887, 12.862412453000161],
                [-11.391773224999952, 12.865408897000066],
                [-11.393148421999967, 12.866807937000033],
                [-11.394205093999972, 12.86851692200014],
                [-11.394919394999931, 12.87053775700008],
                [-11.395714759999919, 12.871497154000167],
                [-11.398621558999935, 12.872945785000184],
                [-11.399622917999977, 12.874325752000061],
                [-11.39987182699997, 12.876917839000043],
                [-11.398160933999918, 12.886628151000025],
                [-11.396761893999951, 12.889986038000131],
                [-11.395721434999928, 12.891374588000076],
                [-11.392528532999904, 12.893667221000157],
                [-11.390994070999966, 12.895711899000048],
                [-11.385282515999961, 12.899648666000076],
                [-11.383805273999883, 12.902142525000102],
                [-11.383543968999845, 12.908778191000067],
                [-11.384440421999955, 12.910392762000185],
                [-11.389511108999898, 12.911985397000024],
                [-11.392927168999961, 12.912475587000074],
                [-11.396550178999973, 12.913747787000034],
                [-11.39753437, 12.917318344000137],
                [-11.397099494999907, 12.918303490000085],
                [-11.396212577999904, 12.923353194000128],
                [-11.395279883999933, 12.92677784000017],
                [-11.39402675599996, 12.928520204000051],
                [-11.39093971299991, 12.929698944000108],
                [-11.390552519999915, 12.926351547000138],
                [-11.389943121999977, 12.92431163900011],
                [-11.38913154599993, 12.923307418999968],
                [-11.386795043999882, 12.922561645000087],
                [-11.385464668999873, 12.92266368899999],
                [-11.38247966699987, 12.924180031000049],
                [-11.381471634999969, 12.925141335000035],
                [-11.378885268999852, 12.926200868000024],
                [-11.377398490999951, 12.92629337400001],
                [-11.374175072999947, 12.927280425999982],
                [-11.37076473299993, 12.92797279299998],
                [-11.367059706999896, 12.928310394000107],
                [-11.362770080999894, 12.928389550000077],
                [-11.359504699999889, 12.92891121000008],
                [-11.356846808999876, 12.9295873640001],
                [-11.352099418999956, 12.931834222000191],
                [-11.347912787999917, 12.932395935000045],
                [-11.34677028599998, 12.933004379000124],
                [-11.345094679999818, 12.935018541000147],
                [-11.342520713999932, 12.941237450000187],
                [-11.342825888999926, 12.94476032200015],
                [-11.344306945999904, 12.94735527099999],
                [-11.346357344999944, 12.950140954],
                [-11.347224234999885, 12.9519023900001],
                [-11.35081481899988, 12.957377434000136],
                [-11.351949691999891, 12.96064949000015],
                [-11.350984573999881, 12.967679979000025],
                [-11.350864410999975, 12.972944260000077],
                [-11.35151195599991, 12.977879524000059],
                [-11.353128433999927, 12.981472015],
                [-11.355286597999964, 12.98530674000017],
                [-11.358420371999955, 12.98819351300017],
                [-11.359822272999963, 12.990699768000127],
                [-11.362631796999892, 12.992831230000093],
                [-11.36548614599991, 12.993720054000107],
                [-11.369993209999961, 12.994482040000094],
                [-11.37295818299998, 12.995187760000078],
                [-11.3790149699999, 12.995853424000074],
                [-11.382016182999905, 12.995388032000108],
                [-11.38410091299994, 12.993116378000082],
                [-11.384160040999916, 12.990169525000056],
                [-11.383570670999916, 12.987998008999966],
                [-11.382263183999896, 12.984665870000072],
                [-11.38217544499986, 12.983055114000024],
                [-11.38121700299996, 12.980657577000159],
                [-11.38116836599994, 12.97450733100004],
                [-11.382275581999977, 12.97137737200012],
                [-11.383689880999839, 12.969122887000026],
                [-11.38558292499988, 12.968227387000184],
                [-11.388111113999969, 12.967564583000069],
                [-11.390548704999958, 12.970692635000034],
                [-11.393124580999938, 12.973069191000036],
                [-11.395834922999938, 12.97491073499998],
                [-11.40111350999996, 12.976397515000087],
                [-11.40423107099997, 12.976197243000058],
                [-11.405206679999878, 12.975416183999982],
                [-11.405803679999963, 12.973367691000078],
                [-11.406779287999882, 12.971499443000027],
                [-11.407463072999974, 12.973283769000034],
                [-11.406601904999945, 12.975278855000113],
                [-11.402928352999936, 12.979552270000113],
                [-11.40252971699988, 12.981334686000139],
                [-11.402997015999915, 12.984187127000155],
                [-11.40408420499989, 12.985626220000142],
                [-11.407028197999978, 12.987936973000103],
                [-11.410407065999948, 12.99160766500006],
                [-11.411895752999897, 12.99379062700018],
                [-11.412662505999947, 12.995806694000123],
                [-11.411890029999881, 12.999219894000078],
                [-11.410061836999944, 13.000691413000084],
                [-11.408333778999975, 12.999125480000089],
                [-11.40529918599998, 12.998470307000161],
                [-11.404014585999903, 12.998659133000103],
                [-11.401945113999943, 13.000236512000185],
                [-11.399901390999958, 13.000012399000184],
                [-11.395472525999878, 13.001641273000132],
                [-11.392827033999936, 13.003240585000128],
                [-11.392075538999904, 13.004838943000038],
                [-11.39193153499997, 13.006690025000069],
                [-11.39243221299995, 13.01125335800009],
                [-11.393365859999903, 13.013691902000062],
                [-11.39683342, 13.01678180700003],
                [-11.402261733999921, 13.019445420000011],
                [-11.408195494999973, 13.020478248000074],
                [-11.411588668999912, 13.02081871100006],
                [-11.410326003999955, 13.022348402999967],
                [-11.409099578999928, 13.024622917000045],
                [-11.408959387999971, 13.025778771000148],
                [-11.407354354999939, 13.029221535000033],
                [-11.406773567999949, 13.03221225800013],
                [-11.406828880999967, 13.034593582000184],
                [-11.407753943999978, 13.037329674999967],
                [-11.40950489099987, 13.039233207000109],
                [-11.411451339999928, 13.039844513000048],
                [-11.41557216599989, 13.040226936000181],
                [-11.418111801999885, 13.042917252],
                [-11.419260977999954, 13.044548988000031],
                [-11.42166328299993, 13.04887771600005],
                [-11.4248523699999, 13.05299186700006],
                [-11.4277067189999, 13.055069924000122],
                [-11.430029869999942, 13.05575561600017],
                [-11.431580542999882, 13.05722141200016],
                [-11.432058334999908, 13.058691026000076],
                [-11.431598663999978, 13.061524391000091],
                [-11.428756712999927, 13.064974785000118],
                [-11.427304267999943, 13.06623268200019],
                [-11.422709464999969, 13.071702958],
                [-11.421526908999908, 13.074498177000123],
                [-11.421566963999965, 13.077466011000183],
                [-11.421013832999904, 13.079990387000123],
                [-11.421323776999884, 13.081732751000061],
                [-11.42378425499993, 13.08592319500002],
                [-11.42513942599993, 13.08762359700006],
                [-11.427058220999982, 13.08925914800011],
                [-11.430815696999957, 13.091032029000132],
                [-11.431864738999877, 13.091223718000094],
                [-11.432517051999923, 13.093276977000073],
                [-11.434480665999956, 13.095109940000157],
                [-11.436942100999943, 13.096224785000118],
                [-11.43788433099985, 13.097693444000186],
                [-11.439321517999929, 13.098950385000023],
                [-11.443662643999915, 13.100201606000041],
                [-11.447887419999972, 13.100016595000113],
                [-11.449107168999944, 13.099599838000188],
                [-11.451966285999958, 13.099514009000131],
                [-11.45700931499988, 13.100104332],
                [-11.459895134999897, 13.099872589000086],
                [-11.464642524999874, 13.099158286999966],
                [-11.470669746999931, 13.099737167000114],
                [-11.47343921699985, 13.101299285000039],
                [-11.473144530999889, 13.102593422999973],
                [-11.473413467999933, 13.104631424000104],
                [-11.47434425399996, 13.106309890000091],
                [-11.47539329599988, 13.109393120000107],
                [-11.477807043999917, 13.113740921000044],
                [-11.480436324999971, 13.116329194000059],
                [-11.483163832999935, 13.117866516000106],
                [-11.487979887999927, 13.119403839000029],
                [-11.490753172999973, 13.12007045700011],
                [-11.492113113999949, 13.121183395000173],
                [-11.492974281999977, 13.125127792000114],
                [-11.492673872999887, 13.128583908999985],
                [-11.492752074999885, 13.130559921000042],
                [-11.493678092999914, 13.133296012000073],
                [-11.495964049999884, 13.13672733300018],
                [-11.496508597999934, 13.141771317000064],
                [-11.498084068999958, 13.14481449200008],
                [-11.500942230999954, 13.147052764000023],
                [-11.505060195999874, 13.14795970900019],
                [-11.509525297999915, 13.148341179000113],
                [-11.512065886999892, 13.149049760000025],
                [-11.512314795999885, 13.151717185000052],
                [-11.51305484799991, 13.15372180900016],
                [-11.514452933999962, 13.15500831700001],
                [-11.514739989999896, 13.156987190000052],
                [-11.51414680399995, 13.15866375100012],
                [-11.509530067999947, 13.161891937000064],
                [-11.50775432499995, 13.163924216999987],
                [-11.507736205999947, 13.165158272000042],
                [-11.506010055999866, 13.165323258000058],
                [-11.504262923999875, 13.166090011000165],
                [-11.500697135999928, 13.170660973000167],
                [-11.499211311999886, 13.171839713000168],
                [-11.497763633999853, 13.175294877000056],
                [-11.49605083399996, 13.177820206000035],
                [-11.495244978999892, 13.180620194000028],
                [-11.495109556999978, 13.190096856000082],
                [-11.495596884999884, 13.192631722000044],
                [-11.498023985999964, 13.196665764000045],
                [-11.503772735999917, 13.198718069999984],
                [-11.508565902999862, 13.199513435000028],
                [-11.517843246999973, 13.200337411000135],
                [-11.522426604999964, 13.20092964100013],
                [-11.525632857999938, 13.200847625000108],
                [-11.53151702799994, 13.199946403000126],
                [-11.533404348999909, 13.201234818000046],
                [-11.536074638999878, 13.203973771000108],
                [-11.535820959999967, 13.205996514000049],
                [-11.537738799999943, 13.208946229000105],
                [-11.538470268999959, 13.211651801000187],
                [-11.538311003999922, 13.214368821000107],
                [-11.53721141799997, 13.218204498000091],
                [-11.536674499999947, 13.222210884000106],
                [-11.536617279999916, 13.225870133000114],
                [-11.537035941999932, 13.229731561000051],
                [-11.531791685999906, 13.237125397000057],
                [-11.52935886499995, 13.23833560900016],
                [-11.52658080999987, 13.24245262200003],
                [-11.523659704999943, 13.24787902899999],
                [-11.522500037999919, 13.24780941],
                [-11.521488190999946, 13.249617578000084],
                [-11.518921852999881, 13.252899169000045],
                [-11.518109321999873, 13.256283760000088],
                [-11.518700599999875, 13.259028434000129],
                [-11.520587920999958, 13.261204721000183],
                [-11.523788450999916, 13.26255607600018],
                [-11.527711867999926, 13.264478684000096],
                [-11.530421256999944, 13.265101432999984],
                [-11.533312797999884, 13.26845645800006],
                [-11.53417110499987, 13.269943238000167],
                [-11.537036894999915, 13.273587226000188],
                [-11.538626670999918, 13.27441120200018],
                [-11.537576674999912, 13.277297975000181],
                [-11.53639984199998, 13.28224277400011],
                [-11.5368423459999, 13.283807755000055],
                [-11.538081168999895, 13.286097527000152],
                [-11.540007590999949, 13.28812885300016],
                [-11.543109893999883, 13.29083442700005],
                [-11.547485351999967, 13.292696954],
                [-11.551651953999908, 13.293258668000135],
                [-11.557165144999942, 13.292123795000123],
                [-11.559761046999938, 13.292040826000118],
                [-11.562561034999874, 13.293262483000035],
                [-11.566278456999896, 13.296767235000118],
                [-11.566767692999974, 13.298286438000105],
                [-11.57039165499998, 13.302876473000026],
                [-11.571677207999926, 13.305863381000052],
                [-11.574825287999886, 13.308251380000058],
                [-11.578726767999967, 13.30930614499999],
                [-11.58324813799993, 13.309736252000164],
                [-11.585263251999947, 13.31095504699999],
                [-11.585530281999922, 13.313258172000076],
                [-11.583367347999967, 13.316695213000116],
                [-11.582719802999918, 13.319903374000091],
                [-11.582782745999964, 13.322852135000119],
                [-11.584001539999917, 13.325817108000024],
                [-11.589115141999912, 13.33096408900019],
                [-11.589635848999933, 13.333056450000186],
                [-11.590615272999969, 13.335268974000144],
                [-11.59145164399996, 13.339046478000057],
                [-11.59146880999998, 13.346294403000115],
                [-11.59060573499994, 13.34998130800011],
                [-11.590483665999898, 13.357398986000078],
                [-11.590210913999954, 13.358872415000064],
                [-11.590279578999969, 13.362423896],
                [-11.591444015999969, 13.365363121000087],
                [-11.593074797999975, 13.367497444000037],
                [-11.596012115999827, 13.368447304000142],
                [-11.598473547999902, 13.368426323000051],
                [-11.602121353999905, 13.36691761000003],
                [-11.604331015999946, 13.365591050000035],
                [-11.605827330999944, 13.365362167000058],
                [-11.609720229999937, 13.363254547999986],
                [-11.613368033999961, 13.362291335000123],
                [-11.620315551999965, 13.363945960000024],
                [-11.621905325999933, 13.363847733000114],
                [-11.626252174999877, 13.36455249800008],
                [-11.632730483999978, 13.36465930900016],
                [-11.63371944499994, 13.365688324000132],
                [-11.632178307999936, 13.367940902000157],
                [-11.631487845999914, 13.36841011200005],
                [-11.624793051999973, 13.374439239000026],
                [-11.62403392799996, 13.376118659999975],
                [-11.621733665999955, 13.379727364000132],
                [-11.621407507999947, 13.382828712000048],
                [-11.62073040099989, 13.385097504000044],
                [-11.620720862999917, 13.390861511000082],
                [-11.621947287999944, 13.393643379000025],
                [-11.622730254999908, 13.394725800000174],
                [-11.625011443999824, 13.396450996000056],
                [-11.627431869999896, 13.397066117000065],
                [-11.634647368999879, 13.397322654000106],
                [-11.63941574199987, 13.397115707000125],
                [-11.642673491999972, 13.397158623999985],
                [-11.645809173999965, 13.396623611000052],
                [-11.649873733999982, 13.394776344999968],
                [-11.655754089999903, 13.392563821000067],
                [-11.657139777999816, 13.39246845200006],
                [-11.663155556999982, 13.390659332000155],
                [-11.665911673999972, 13.390550614],
                [-11.667929649999962, 13.390045166000107],
                [-11.671470641999917, 13.39057254800008],
                [-11.674834251999869, 13.391674995000074],
                [-11.678627966999954, 13.392205239000077],
                [-11.683826445999955, 13.39228534800003],
                [-11.686561583999946, 13.390967369000123],
                [-11.689370154999892, 13.390906334000135],
                [-11.69031238499997, 13.393061637000017],
                [-11.690492629999937, 13.395469665000178],
                [-11.689618111999891, 13.39781379700014],
                [-11.689600943999892, 13.401925087000109],
                [-11.69019794399992, 13.403949737000119],
                [-11.692312240999968, 13.406877517000112],
                [-11.694022177999955, 13.409739494000178],
                [-11.696133613999962, 13.411170960000163],
                [-11.699728965999896, 13.414384841000071],
                [-11.70365142899982, 13.416355133000025],
                [-11.706644056999892, 13.416554450000092],
                [-11.709568976999947, 13.416217804000098],
                [-11.712272643999938, 13.416855812000108],
                [-11.715553283999952, 13.417206764000014],
                [-11.726531028999887, 13.418030739000073],
                [-11.728931426999907, 13.417957306000119],
                [-11.730222701999935, 13.417359353000165],
                [-11.733781813999883, 13.416883468000094],
                [-11.739130973999977, 13.413958549000142],
                [-11.742386816999897, 13.412474632],
                [-11.744812010999908, 13.409633636000137],
                [-11.746657371999902, 13.404164315000116],
                [-11.746699333999914, 13.401049614000101],
                [-11.746459007999931, 13.397616388000074],
                [-11.74569129899993, 13.395256042000142],
                [-11.743670463999877, 13.39209461200005],
                [-11.743404386999885, 13.388839722000057],
                [-11.746782302999975, 13.386994363000042],
                [-11.748556135999934, 13.384322167000107],
                [-11.74891853299988, 13.380586625000092],
                [-11.750993727999969, 13.377337455000031],
                [-11.752152441999954, 13.375988007000103],
                [-11.754296302999933, 13.372646333000034],
                [-11.758308410999973, 13.369984628000111],
                [-11.759492873999932, 13.36857318900013],
                [-11.760261534999927, 13.366122247000021],
                [-11.761612892999949, 13.36380004800003],
                [-11.76313495699992, 13.358447075000015],
                [-11.764413833999981, 13.348789215000124],
                [-11.766262053999924, 13.342533112000012],
                [-11.768694876999973, 13.339899063000132],
                [-11.773082732999853, 13.339935303000118],
                [-11.77399444599996, 13.340170861000047],
                [-11.779004097999973, 13.340289116000179],
                [-11.782050132999927, 13.339900971000134],
                [-11.785619734999955, 13.338641167000162],
                [-11.789745330999892, 13.335716247000107],
                [-11.7930440909999, 13.332175254000106],
                [-11.794620514999963, 13.328499794000095],
                [-11.795158386999958, 13.3240156170001],
                [-11.796690941999941, 13.313722611],
                [-11.799804687999881, 13.311239243000102],
                [-11.80094528099994, 13.311067582000021],
                [-11.803519248999919, 13.311581611000179],
                [-11.80816841199993, 13.312074661999986],
                [-11.81223487799997, 13.310890199000141],
                [-11.815636634999862, 13.31054687500017],
                [-11.818675994999978, 13.310585022000055],
                [-11.824571609999907, 13.310387611000124],
                [-11.829092024999909, 13.310930253000095],
                [-11.82918357899996, 13.313209535000055],
                [-11.826452255999982, 13.316253663],
                [-11.824337959999923, 13.320455552000112],
                [-11.824166296999863, 13.321785927000178],
                [-11.824793815999897, 13.32770919700016],
                [-11.825720786999966, 13.330209732000014],
                [-11.828056335999918, 13.332427026000175],
                [-11.830187796999894, 13.334053039000139],
                [-11.834961889999931, 13.334345818000145],
                [-11.835419653999963, 13.336032866999972],
                [-11.836727142999962, 13.337435723000056],
                [-11.838448523999944, 13.340458870000191],
                [-11.839262961999907, 13.345342636000169],
                [-11.840182304999871, 13.34716987600018],
                [-11.841013908999912, 13.350078583000027],
                [-11.842824935999943, 13.352811813000073],
                [-11.843680380999956, 13.35355854100004],
                [-11.84868907899994, 13.355531693000046],
                [-11.85129642499993, 13.358428954000033],
                [-11.857376097999975, 13.361701012000083],
                [-11.860795020999888, 13.365063667000015],
                [-11.86158847799993, 13.36643600400015],
                [-11.863909720999857, 13.368974686000058],
                [-11.865169524999885, 13.371628762000114],
                [-11.867605209999908, 13.378193855000177],
                [-11.869524001999935, 13.380905152000082],
                [-11.87081336999995, 13.38190746400005],
                [-11.87381172299996, 13.383085252000171],
                [-11.876581192999879, 13.38348960900015],
                [-11.879474640999945, 13.383537293000188],
                [-11.881138800999963, 13.383966446000045],
                [-11.882964133999906, 13.385450363000189],
                [-11.886109351999892, 13.384233476000134],
                [-11.886749266999914, 13.384754180000016],
                [-11.888108252999928, 13.387385368000139],
                [-11.888077735999957, 13.389771463000102],
                [-11.887694358999966, 13.392494203000183],
                [-11.88684272799992, 13.395551681000029],
                [-11.885056494999901, 13.39905262000002],
                [-11.883039474999919, 13.402437211000063],
                [-11.880521774999977, 13.408656120000103],
                [-11.879093169999976, 13.411365510000053],
                [-11.877853392999896, 13.41748714400012],
                [-11.878046988999927, 13.420444488000044],
                [-11.877790451999942, 13.422372818000099],
                [-11.876720428999931, 13.424291610000068],
                [-11.8716554639999, 13.429910661000179],
                [-11.866157531999932, 13.433137895000016],
                [-11.864454269999953, 13.439139366],
                [-11.864058493999892, 13.442770959000029],
                [-11.864005088999932, 13.446861268000134],
                [-11.864194869999892, 13.450159073000066],
                [-11.866421698999943, 13.457459450000044],
                [-11.868682859999979, 13.462147712000046],
                [-11.871759413999882, 13.466617584],
                [-11.874894140999913, 13.468784333000144],
                [-11.881799696999906, 13.471402168000111],
                [-11.884552000999918, 13.474049569000044],
                [-11.889867782999943, 13.476047516000108],
                [-11.89307880399997, 13.479155541000125],
                [-11.894887922999885, 13.482928276000052],
                [-11.895750045999932, 13.488052368000012],
                [-11.901387214999943, 13.49221325000002],
                [-11.905075072999978, 13.494418144000065],
                [-11.911553383999888, 13.496316910000132],
                [-11.916750907999813, 13.498539925000102],
                [-11.920087813999942, 13.500967026000126],
                [-11.922601698999927, 13.504179954000051],
                [-11.924060821999944, 13.508062363000022],
                [-11.925647734999814, 13.511150360000158],
                [-11.928045271999963, 13.514361381000185],
                [-11.93092250899997, 13.516442299000062],
                [-11.936191558999951, 13.518604280000147],
                [-11.943250656999851, 13.518855094000116],
                [-11.947435377999966, 13.520386696],
                [-11.94791698499995, 13.522202491000087],
                [-11.952119826999876, 13.52225399100007],
                [-11.95411205299996, 13.524251936999974],
                [-11.956758499999978, 13.527739525000072],
                [-11.959755897999912, 13.530079842000191],
                [-11.965108870999813, 13.532283783000025],
                [-11.969277381999916, 13.53513145400018],
                [-11.971263884999871, 13.537623406000137],
                [-11.971051216999967, 13.541076660000044],
                [-11.971688269999902, 13.543881417000023],
                [-11.97497844599991, 13.549679756000046],
                [-11.97910404199996, 13.55598258900011],
                [-11.983246803999975, 13.560804367000117],
                [-11.986577033999936, 13.563476563000052],
                [-11.989087104999953, 13.56449413300004],
                [-11.994958876999931, 13.565716745000145],
                [-11.997792244999971, 13.567725182000117],
                [-11.998922347999951, 13.571523667000065],
                [-11.997659683999927, 13.578254700000059],
                [-11.996260642999914, 13.582515717000092],
                [-11.996560096999929, 13.5854806910001],
                [-12.000514984999938, 13.591945649000138],
                [-12.004342078999969, 13.59528255500004],
                [-12.013669966999942, 13.602634430000023],
                [-12.019227981999961, 13.608764648999966],
                [-12.02930164399993, 13.614679337000155],
                [-12.037695884999891, 13.62335586600011],
                [-12.038528441999972, 13.63116168900001],
                [-12.037901876999967, 13.638820648000035],
                [-12.036198614999932, 13.647506715000077],
                [-12.03973007299993, 13.662622451],
                [-12.05000591199996, 13.680417062],
                [-12.056921005999982, 13.690764427000033],
                [-12.056874275999917, 13.694532394000134],
                [-12.058172226999943, 13.697016715000075],
                [-12.060786246999953, 13.700555801000178],
                [-12.06774330099995, 13.707526207000114],
                [-12.073179244999949, 13.719285011000125],
                [-12.073455810999917, 13.720353126000134],
                [-12.066867828999875, 13.717649460000075],
                [-12.06562042299987, 13.717848779000121],
                [-12.062598228999946, 13.72059822000017],
                [-12.060828208999965, 13.723148347000063],
                [-12.059500693999894, 13.725700378999989],
                [-12.056475639999974, 13.726492881000013],
                [-12.055371283999818, 13.727875709000045],
                [-12.053542136999909, 13.728428842000085],
                [-12.05087375699992, 13.728757858000108],
                [-12.048706053999979, 13.729906080999967],
                [-12.045118332999948, 13.730779647000134],
                [-12.043023109999979, 13.732340813000178],
                [-12.041694639999946, 13.732252121000101],
                [-12.03831005099994, 13.73355198000013],
                [-12.036723136999967, 13.734848023000154],
                [-12.035066603999951, 13.737604142],
                [-12.034425734999957, 13.738033295000093],
                [-12.028218268999979, 13.739439965000145],
                [-12.026446342999918, 13.741191865000133],
                [-12.023141860999885, 13.742783547000045],
                [-12.020273208999868, 13.74540615],
                [-12.017277717999889, 13.747046472000022],
                [-12.016466139999977, 13.747976303000144],
                [-12.015833854999926, 13.749787330000174],
                [-12.011218071999906, 13.749459267000077],
                [-12.008606911999948, 13.749859811000022],
                [-12.007353782999928, 13.750773429000105],
                [-12.005751609999948, 13.752590179000151],
                [-12.003339767999876, 13.753757477000136],
                [-12.00206375099998, 13.755143166000096],
                [-12.00064277599995, 13.758563996000191],
                [-12.000820159999876, 13.762250900000026],
                [-11.999008178999873, 13.763378144000058],
                [-11.998364448999894, 13.76437950200011],
                [-11.997050284999943, 13.764848709000148],
                [-11.990749359999882, 13.768742561000067],
                [-11.986595152999882, 13.771674156000074],
                [-11.984675407999873, 13.77507591300008],
                [-11.983755111999926, 13.77610015800019],
                [-11.982080458999917, 13.776872635000075],
                [-11.980303762999824, 13.778777124000158],
                [-11.979187010999965, 13.78121566800013],
                [-11.977897642999949, 13.78322887500019],
                [-11.977548598999931, 13.784463883000058],
                [-11.975645064999924, 13.787807465000128],
                [-11.974350928999968, 13.789220811000177],
                [-11.97390556299996, 13.792212485999983],
                [-11.973397254999895, 13.79326152800013],
                [-11.97031402499988, 13.794101715000068],
                [-11.968907356999864, 13.794793129000084],
                [-11.964876174999915, 13.795253753],
                [-11.963280677999876, 13.796181679000028],
                [-11.96210765799998, 13.79773998200011],
                [-11.961664199999973, 13.80056476700014],
                [-11.96183681499997, 13.801794052000162],
                [-11.95968437199997, 13.804797173000054],
                [-11.958775519999904, 13.805309297000122],
                [-11.95320892299992, 13.80575943100007],
                [-11.95003223499998, 13.8068447120001],
                [-11.947988508999913, 13.809043885000051],
                [-11.947833061999916, 13.812830925000071],
                [-11.948725700999887, 13.816028595000148],
                [-11.950008392999962, 13.819048881000072],
                [-11.949650763999955, 13.821162224999966],
                [-11.947695732999932, 13.824407577000102],
                [-11.947540282999967, 13.828099250000093],
                [-11.946926116999919, 13.830693244000145],
                [-11.946695327999919, 13.837754250000103],
                [-11.945456504999925, 13.841444970000168],
                [-11.944477081999878, 13.843242645000089],
                [-11.943940161999876, 13.845058442000152],
                [-11.944032668999967, 13.84728908600016],
                [-11.944708824999964, 13.850075722000156],
                [-11.944880485999875, 13.852423666999982],
                [-11.944683073999954, 13.85599708500007],
                [-11.943891524999856, 13.857267379],
                [-11.942420005999963, 13.858110428999964],
                [-11.94054221999994, 13.861132623000117],
                [-11.940117834999967, 13.863044740000134],
                [-11.93784523099987, 13.883589745000165],
                [-11.936891555999921, 13.887626649000026],
                [-11.936397552999836, 13.89214134299999],
                [-11.930096625999965, 13.923852922000037],
                [-11.930004118999932, 13.924603462000164],
                [-11.938400268999885, 13.997722626000154],
                [-11.962409971999875, 13.997170448000134],
                [-12.004812240999968, 13.994864464000102],
                [-12.003656386999978, 13.996929169000168],
                [-12.001637457999891, 13.998835563000114],
                [-12.001073836999979, 14.000111580000123],
                [-12.001916885999947, 14.006606102000148],
                [-12.002851486999816, 14.009116172000176],
                [-12.003150938999966, 14.011490821000052],
                [-12.004238128999873, 14.014088630000174],
                [-12.005928038999969, 14.017214775000127],
                [-12.006641386999945, 14.019333839000126],
                [-12.010622023999872, 14.023829459000126],
                [-12.012229918999878, 14.02719211699997],
                [-12.01056671099991, 14.027893067000093],
                [-12.006435394999869, 14.030310631000077],
                [-12.004601478999916, 14.032253266000168],
                [-12.0041494379999, 14.034968376000108],
                [-12.004358292999939, 14.039302826999972],
                [-12.00471687199996, 14.040388108000172],
                [-12.004950522999934, 14.044856072000186],
                [-12.005661010999916, 14.049578666000116],
                [-11.999609947999943, 14.051166535000164],
                [-11.998209951999911, 14.052741051000055],
                [-11.996663093999928, 14.05618476800015],
                [-11.994352340999853, 14.058706284000039],
                [-11.993170738999936, 14.061141015000146],
                [-11.992263793999882, 14.062116623000065],
                [-11.991443634999882, 14.064131738000071],
                [-11.991739273999826, 14.067099572000132],
                [-11.99246120499987, 14.069500924000067],
                [-11.993589400999952, 14.071772576000058],
                [-11.99374675799993, 14.073519708000049],
                [-11.994869232999974, 14.075625419000062],
                [-11.99517631499998, 14.082165718000056],
                [-11.993791579999879, 14.084810257000129],
                [-11.991326331999858, 14.08632278500005],
                [-11.987523078999857, 14.08737182700014],
                [-11.986155509999946, 14.08685398],
                [-11.984474180999939, 14.087222100000076],
                [-11.982485770999972, 14.086514474000182],
                [-11.98068046599991, 14.086812020000082],
                [-11.980033874999947, 14.08787441300018],
                [-11.981742857999961, 14.114892006000161],
                [-11.981975555999952, 14.115660667000043],
                [-11.9839715949999, 14.118536950000134],
                [-11.985271453999871, 14.121845245000031],
                [-11.985686302999909, 14.123629571000038],
                [-11.985460281999963, 14.124666214000115],
                [-11.983407020999891, 14.129193306000047],
                [-11.982801437999967, 14.132441521000146],
                [-11.98276710599987, 14.134024620000105],
                [-11.981626509999955, 14.137187004000111],
                [-11.978116035999903, 14.142178535000028],
                [-11.977705000999833, 14.143568040000162],
                [-11.97459602299989, 14.146894456000041],
                [-11.97368335799996, 14.152062416000035],
                [-11.973612784999887, 14.155826569],
                [-11.973974228999964, 14.16113948800006],
                [-11.974535942999978, 14.163585663000049],
                [-11.97397136699982, 14.169485092000116],
                [-11.973381995999887, 14.172479630000055],
                [-11.973275183999931, 14.17512702900018],
                [-11.972710609999865, 14.17762279599998],
                [-11.972053528999936, 14.183951378000131],
                [-11.972939490999977, 14.186833382000032],
                [-11.974357604999966, 14.188267707000136],
                [-11.980505942999855, 14.190093040000079],
                [-11.982140541999911, 14.19119834900016],
                [-11.986307143999966, 14.193240165000134],
                [-11.986460685999873, 14.194062234000057],
                [-11.985612868999965, 14.199907303000089],
                [-11.98589038799986, 14.203133584000113],
                [-11.986472129999925, 14.206713677000096],
                [-11.988303184999893, 14.208732606000126],
                [-11.991370201999928, 14.210103035000088],
                [-11.996185301999901, 14.215147972000125],
                [-11.997419357999945, 14.219389916000125],
                [-11.999846458999968, 14.225642204000167],
                [-12.00493430999984, 14.237290382000083],
                [-12.00707721799995, 14.243404388000044],
                [-12.00899124199998, 14.250943184000107],
                [-12.012115478999931, 14.257973671000173],
                [-12.01566886799992, 14.266279220000172],
                [-12.017201423999893, 14.272818566000183],
                [-12.019219398999894, 14.27927684700012],
                [-12.020017623999934, 14.280991555000071],
                [-12.022438049999948, 14.285092353999971],
                [-12.023200035999935, 14.28563881000008],
                [-12.0243978499999, 14.283746719000135],
                [-12.025152206999962, 14.284144402000095],
                [-12.025726318999943, 14.286960602000022],
                [-12.026675223999973, 14.287315369000169],
                [-12.028548240999953, 14.285722734000103],
                [-12.029346465999879, 14.284188272000165],
                [-12.030329704999815, 14.284100533000071],
                [-12.031979559999968, 14.28682136500015],
                [-12.033927917999904, 14.288607597000123],
                [-12.031956672999968, 14.289223671000116],
                [-12.033411978999936, 14.290587425000183],
                [-12.035693168999956, 14.29102230000018],
                [-12.03807067799994, 14.290492057999984],
                [-12.041107176999901, 14.28917503299999],
                [-12.042819976999965, 14.289196969000045],
                [-12.044651985999906, 14.291251183000043],
                [-12.047216415999912, 14.290841104000094],
                [-12.048194884999873, 14.291096688000096],
                [-12.050844192999875, 14.294552802999988],
                [-12.056114195999896, 14.297408104000056],
                [-12.060185431999969, 14.298406601000181],
                [-12.061151503999952, 14.29961967500003],
                [-12.063846587999933, 14.299571991000164],
                [-12.065407752999874, 14.298471451000069],
                [-12.067279815999882, 14.299054146],
                [-12.067702293999844, 14.297948837000149],
                [-12.072106361999886, 14.298237800000152],
                [-12.073670387999925, 14.297625542000105],
                [-12.075546263999968, 14.297964096999976],
                [-12.077405930999873, 14.299576760000093],
                [-12.078024864999861, 14.301815033000139],
                [-12.07900524099989, 14.301908493000042],
                [-12.079390525999884, 14.303665162000186],
                [-12.080933570999832, 14.304641723000032],
                [-12.081732750999947, 14.305843354000103],
                [-12.082868576999886, 14.30610084600005],
                [-12.086883544999978, 14.305752755000128],
                [-12.089178084999958, 14.305221558000142],
                [-12.092439650999893, 14.30509853400008],
                [-12.0945510869999, 14.306523324000011],
                [-12.09586143499996, 14.30626010899999],
                [-12.09789085299991, 14.306917190000092],
                [-12.099432944999876, 14.307974816000069],
                [-12.100433349999946, 14.30655098],
                [-12.100032805999945, 14.305273057000079],
                [-12.10207176199998, 14.305857658000036],
                [-12.102383613999962, 14.306818961999966],
                [-12.101954460999877, 14.308484078000106],
                [-12.100563048999959, 14.308702468000035],
                [-12.100294112999904, 14.31084823600014],
                [-12.103081703999976, 14.310169220000091],
                [-12.10412693, 14.31097602900013],
                [-12.104104996999979, 14.312727929000118],
                [-12.103606223999918, 14.31336307600003],
                [-12.101482390999877, 14.313571931000126],
                [-12.100515365999911, 14.312368393000042],
                [-12.099028586999907, 14.313461304000157],
                [-12.097398757999883, 14.313441278000084],
                [-12.097059248999926, 14.314638139000067],
                [-12.095572471999958, 14.315731050000181],
                [-12.094408989999863, 14.317631722000044],
                [-12.09355163499987, 14.320167543000025],
                [-12.096481321999931, 14.321395875],
                [-12.095405578999873, 14.322258949],
                [-12.09353160899991, 14.321757317000106],
                [-12.092697142999953, 14.323264123000058],
                [-12.09120750499983, 14.324600220000036],
                [-12.092987060999974, 14.325896264000164],
                [-12.09608840899989, 14.326728821000017],
                [-12.098304747999975, 14.325880051000127],
                [-12.100817679999977, 14.32711124400015],
                [-12.102458, 14.327050210000039],
                [-12.104419708999842, 14.326433182000187],
                [-12.105232239999907, 14.32668685900012],
                [-12.104886055999941, 14.328352929000118],
                [-12.10633659399997, 14.330122947],
                [-12.108449935999943, 14.330708504000086],
                [-12.10868453899991, 14.331904411000153],
                [-12.106153487999961, 14.331394196000133],
                [-12.106125831999975, 14.333543777000102],
                [-12.106679916999951, 14.33514022899999],
                [-12.105524062999905, 14.335685730000137],
                [-12.104722975999948, 14.334565163000036],
                [-12.102839469999935, 14.33477687800007],
                [-12.102495193999857, 14.336371422000184],
                [-12.103219985999942, 14.33765316],
                [-12.101901053999882, 14.337953569000092],
                [-12.101018904999933, 14.336668969000016],
                [-12.102211952999937, 14.333179475000122],
                [-12.101656913999932, 14.331655502999979],
                [-12.10027408499991, 14.331160546000149],
                [-12.09953498799996, 14.331710816000054],
                [-12.100492477999978, 14.333637237000175],
                [-12.099745749999897, 14.334106446000021],
                [-12.098035811999921, 14.333761215000152],
                [-12.096982002999937, 14.332872391000137],
                [-12.096551894999891, 14.33461856800011],
                [-12.098417282999947, 14.335843087000057],
                [-12.098159790999944, 14.337113380000119],
                [-12.096375464999937, 14.336133956000083],
                [-12.09327030199995, 14.33625793400006],
                [-12.092627525999887, 14.335130692],
                [-12.093698501999938, 14.33466530000004],
                [-12.094997404999958, 14.335241317000055],
                [-12.09600257999989, 14.333419799000126],
                [-12.095134733999942, 14.33102512400012],
                [-12.092247008999948, 14.333057404000101],
                [-12.090493201999948, 14.336143494000169],
                [-12.0916957849999, 14.337666513000158],
                [-12.094959257999903, 14.338184356999989],
                [-12.09483909499994, 14.341054917000179],
                [-12.096529006999958, 14.342909814000109],
                [-12.099128723999911, 14.343889237000042],
                [-12.101494788999958, 14.344325065000021],
                [-12.103908537999928, 14.34746170000011],
                [-12.104376791999925, 14.349291802000039],
                [-12.102803230999939, 14.350626945000101],
                [-12.10122013199998, 14.353398323000079],
                [-12.09989738499985, 14.353941918000032],
                [-12.0993089669999, 14.355768204000128],
                [-12.095734596999932, 14.35420703800014],
                [-12.094745635999914, 14.35475444900004],
                [-12.094315527999925, 14.3565015800001],
                [-12.095269202999873, 14.358662605000063],
                [-12.096572875999925, 14.358913421000068],
                [-12.096718787999919, 14.35979175500006],
                [-12.094501494999975, 14.36072158799999],
                [-12.094214438999927, 14.364303588999974],
                [-12.094907760999831, 14.367338181000093],
                [-12.098361967999892, 14.371760368000139],
                [-12.103703497999902, 14.375655175000077],
                [-12.104779242999939, 14.374864578000143],
                [-12.109036445999891, 14.374519348000149],
                [-12.110355376999962, 14.373505592000129],
                [-12.113556861999939, 14.372425079000152],
                [-12.115031241999873, 14.371566772000108],
                [-12.116038322999884, 14.369591714000137],
                [-12.117354392999971, 14.368885994000152],
                [-12.11763095799995, 14.36682033600016],
                [-12.119584083999882, 14.367639542000063],
                [-12.12020778599998, 14.369561195000188],
                [-12.12176132199994, 14.369815827000082],
                [-12.124356270999954, 14.371201515000166],
                [-12.12485694899982, 14.370493889000045],
                [-12.126632689999951, 14.372186661000171],
                [-12.12767028899998, 14.37363529300012],
                [-12.129506111999888, 14.37716197900005],
                [-12.129247664999923, 14.378513337000129],
                [-12.130117415999962, 14.380754472000035],
                [-12.127965926999934, 14.3831129080001],
                [-12.126621246999946, 14.385327339000128],
                [-12.126270292999948, 14.387473107000176],
                [-12.124626158999888, 14.388491629999976],
                [-12.125303267999982, 14.392798423999977],
                [-12.126597403999938, 14.393772125],
                [-12.128253935999965, 14.392436982000106],
                [-12.13038063099998, 14.39206600200015],
                [-12.130228042999875, 14.39095306500019],
                [-12.128938674999858, 14.38966369700006],
                [-12.128070831999821, 14.387178421000158],
                [-12.129869461999931, 14.387119293000069],
                [-12.13086509599998, 14.386101723000024],
                [-12.133966445999931, 14.386292459],
                [-12.136078834999978, 14.387754440000094],
                [-12.138769148999927, 14.38818454799997],
                [-12.140404700999966, 14.387806892000185],
                [-12.141015053999865, 14.385023117000173],
                [-12.142488479999827, 14.384246826000151],
                [-12.144454954999901, 14.384117126999968],
                [-12.149683952999908, 14.384576798000069],
                [-12.153693197999928, 14.384065627000155],
                [-12.15540409099998, 14.384321214000067],
                [-12.16027545899982, 14.386692048000157],
                [-12.16272449399986, 14.387199402000022],
                [-12.164097785999957, 14.38848972400018],
                [-12.163915633999977, 14.3897609710001],
                [-12.164862633999917, 14.392481804000113],
                [-12.169069290999914, 14.395477295000035],
                [-12.168980598999951, 14.396595954000134],
                [-12.169755935999888, 14.39979362400004],
                [-12.170479774999933, 14.400434494000137],
                [-12.172946929999853, 14.399506568000106],
                [-12.174086570999975, 14.399519921000035],
                [-12.175379753999948, 14.400574683000116],
                [-12.177099226999871, 14.400911331000088],
                [-12.178662299999871, 14.39965629500017],
                [-12.179511069999933, 14.39703750699999],
                [-12.18033504399989, 14.396334649000096],
                [-12.183360100999892, 14.39676761700008],
                [-12.185187338999924, 14.3978128440001],
                [-12.187912940999922, 14.398736954000185],
                [-12.190087317999883, 14.40122795100018],
                [-12.192022323999936, 14.40284061400007],
                [-12.196256637999909, 14.404327392000027],
                [-12.19925785099997, 14.40596199099997],
                [-12.201744079999912, 14.406211853000059],
                [-12.201363563999962, 14.408751489000053],
                [-12.201956749999908, 14.411402703000022],
                [-12.201913832999935, 14.414860725000153],
                [-12.202402114999927, 14.417511940000054],
                [-12.20256805299988, 14.420972824000046],
                [-12.202221869999903, 14.423613548000048],
                [-12.200733184999933, 14.42583370200009],
                [-12.19934272699993, 14.428462028000126],
                [-12.194720267999969, 14.43250370000004],
                [-12.190011977999973, 14.438261032000185],
                [-12.189847946999976, 14.441672326000173],
                [-12.192346571999963, 14.44464492800006],
                [-12.197603225999956, 14.44929790600014],
                [-12.203219413999932, 14.454071998000074],
                [-12.20655631999989, 14.457642555000177],
                [-12.206501959999855, 14.46199703200017],
                [-12.205878257999927, 14.463637353000081],
                [-12.200680731999967, 14.473579407000045],
                [-12.199439048999977, 14.476271630000156],
                [-12.199172973999964, 14.478151321000155],
                [-12.199521064999942, 14.479332924000119],
                [-12.200712203999956, 14.48064136500011],
                [-12.20286846199997, 14.482079505000058],
                [-12.211413382999979, 14.484652520000054],
                [-12.215850830999955, 14.48717689600005],
                [-12.218960761999881, 14.489686012000163],
                [-12.220981597999923, 14.492299079000134],
                [-12.221189497999887, 14.495008468000037],
                [-12.22106647499993, 14.504893304000063],
                [-12.21990585299983, 14.510764122000182],
                [-12.217669485999977, 14.515681267000105],
                [-12.215446471999883, 14.519538880000027],
                [-12.21330928899988, 14.526222229000098],
                [-12.213832855999954, 14.532584191000183],
                [-12.214862823999908, 14.537186622000036],
                [-12.21614932999995, 14.540615083000091],
                [-12.217013359999953, 14.548746109000149],
                [-12.215533255999958, 14.551199914000051],
                [-12.213090895999926, 14.553379059000179],
                [-12.208455085999958, 14.556765557000062],
                [-12.206158638999966, 14.556737901000076],
                [-12.199276923999946, 14.556302071000061],
                [-12.193734168999924, 14.555059432000178],
                [-12.1897506699999, 14.55477619200019],
                [-12.18684005699987, 14.55556488100018],
                [-12.185491562999971, 14.557078362000084],
                [-12.18599796199993, 14.564852714000097],
                [-12.185958861999893, 14.567912102000093],
                [-12.183849333999888, 14.572240830000169],
                [-12.180279730999928, 14.57737636600018],
                [-12.176597595999908, 14.58180427600007],
                [-12.172571182999945, 14.584815979000155],
                [-12.171939849999944, 14.586926461000132],
                [-12.172196387999918, 14.595286370000053],
                [-12.17258358099997, 14.599669457000061],
                [-12.172557831999882, 14.603614808000088],
                [-12.17303466699991, 14.609626769000045],
                [-12.173011780999957, 14.611448287000144],
                [-12.17116165199991, 14.615205766000088],
                [-12.169413566999879, 14.616398812],
                [-12.166500090999875, 14.616633414999967],
                [-12.161994933999893, 14.616645813000105],
                [-12.154076575999909, 14.618034363000106],
                [-12.150498390999815, 14.621432305000042],
                [-12.148732185999961, 14.624042512000074],
                [-12.147777557999916, 14.628213883000171],
                [-12.144408225999939, 14.63687801399999],
                [-12.144326208999928, 14.643220901000177],
                [-12.14480018599994, 14.649367332000054],
                [-12.146346090999884, 14.653164864000075],
                [-12.149015425999949, 14.65576267300014],
                [-12.15156078299998, 14.657210351],
                [-12.155914306999932, 14.658276559000115],
                [-12.159647941999935, 14.658996583000032],
                [-12.166694641999982, 14.660837174000164],
                [-12.171122549999893, 14.661499023000147],
                [-12.174991607999914, 14.662490844000047],
                [-12.177383422999924, 14.6651515960001],
                [-12.177536009999926, 14.669472694000035],
                [-12.177099226999871, 14.67108631200017],
                [-12.175694465999982, 14.672554016000106],
                [-12.169853209999928, 14.673967362000155],
                [-12.166087150999886, 14.675744057000145],
                [-12.162730216999876, 14.677996636000103],
                [-12.160409927999922, 14.680533409000134],
                [-12.158926962999885, 14.682606697000097],
                [-12.158897399999944, 14.68490123700002],
                [-12.159490584999958, 14.687270165000086],
                [-12.160785674999943, 14.689040183000031],
                [-12.162096976999976, 14.68946170800018],
                [-12.163834570999938, 14.689145088000089],
                [-12.16864204399991, 14.687247277000154],
                [-12.169963835999965, 14.686925889000065],
                [-12.171986579999952, 14.687045097000123],
                [-12.17413330099987, 14.688259124000126],
                [-12.176650046999896, 14.690988542000184],
                [-12.179771422999977, 14.6940498350001],
                [-12.182190895999895, 14.695698737999976],
                [-12.184346198999947, 14.69631862700004],
                [-12.186896323999918, 14.696404458000131],
                [-12.194894790999967, 14.695528985000067],
                [-12.199163435999822, 14.69563484200006],
                [-12.204421997999873, 14.696454048000135],
                [-12.208327292999968, 14.699038506000136],
                [-12.211125373999948, 14.701501847000088],
                [-12.213039398999967, 14.703630447000023],
                [-12.21445464999988, 14.705753326000035],
                [-12.214329718999863, 14.70683097900013],
                [-12.211618422999948, 14.710685730000137],
                [-12.209622383999942, 14.714981078999983],
                [-12.208662985999865, 14.719180108000046],
                [-12.210476875999916, 14.727767945000153],
                [-12.211910247999981, 14.731384277000075],
                [-12.214320183999973, 14.733789445000127],
                [-12.217329979999931, 14.735552788000064],
                [-12.220202446999963, 14.7365236280001],
                [-12.223002432999976, 14.736298562000172],
                [-12.229742048999924, 14.736124991999986],
                [-12.234000205999905, 14.738149644000032],
                [-12.234804153999903, 14.741850853000074],
                [-12.236612320999939, 14.748843193000084],
                [-12.239056587999926, 14.759916307000026],
                [-12.24115848599996, 14.76439762199999],
                [-12.247150419999855, 14.766473771000108],
                [-12.258981703999893, 14.768869401000075],
                [-12.27576351099998, 14.770560265000029],
                [-12.280482291999931, 14.772961616000032],
                [-12.284031867999943, 14.775349617000131],
                [-12.290923118999899, 14.785240173000147],
                [-12.293501853999942, 14.789394378000054],
                [-12.30361652299996, 14.798611640000104],
                [-12.310735701999874, 14.807865143000015],
                [-12.314101219999884, 14.813448904999973],
                [-12.317016600999978, 14.81683540400013],
                [-12.325899124999978, 14.824906350000049],
                [-12.33801555499997, 14.827874184000109],
                [-12.353865622999933, 14.828056335000156],
                [-12.363076209999974, 14.830989837000061],
                [-12.377021789999901, 14.835775376000129],
                [-12.38325595799995, 14.840049744000169],
                [-12.39127063799998, 14.84252834300014],
                [-12.39624214099996, 14.844440460000158],
                [-12.402779579999958, 14.848833083999978],
                [-12.407196044999978, 14.852693559000102],
                [-12.4133634559999, 14.856510163000053],
                [-12.4209537509999, 14.863109590000136],
                [-12.430123328999912, 14.873311043000172],
                [-12.439319609999927, 14.882455826000182],
                [-12.446781158999897, 14.88747596700017],
                [-12.453158377999898, 14.892865182000094],
                [-12.454345703999934, 14.895241738000095],
                [-12.454070090999949, 14.89783954699999],
                [-12.452046393999922, 14.904553414000134],
                [-12.44931983899994, 14.909013748000064],
                [-12.449016571999948, 14.914092064000158],
                [-12.450070380999932, 14.91753101500018],
                [-12.453172683999924, 14.922410966000086],
                [-12.455554007999979, 14.936236382000061],
                [-12.457280158999879, 14.954957009000111],
                [-12.458539007999946, 14.96257305099999],
                [-12.459788321999952, 14.970975877000171],
                [-12.461545943999965, 14.982006074000026],
                [-12.463133810999977, 14.98451423600011],
                [-12.468446731999904, 14.991390228000057],
                [-12.473773956999878, 14.99708557100007],
                [-12.478163719999941, 15.00224590200014],
                [-12.483904838999877, 15.007159233000152],
                [-12.492325782999956, 15.01367569000007],
                [-12.501320839999892, 15.017182350000155],
                [-12.505366324999954, 15.01722717400014],
                [-12.508732794999958, 15.017657279000105],
                [-12.513699531999976, 15.01967811600008],
                [-12.519210815999884, 15.021310806000145],
                [-12.529038428999968, 15.022860528000137],
                [-12.540330885999879, 15.026128769000081],
                [-12.55093574499989, 15.03056907700011],
                [-12.557760239999936, 15.035361291000129],
                [-12.566315650999854, 15.042139054000074],
                [-12.575567244999888, 15.050229073000025],
                [-12.589221953999925, 15.061237336000033],
                [-12.596944808999979, 15.071496964000175],
                [-12.601842879999936, 15.078141213000038],
                [-12.607657432999929, 15.08277511599999],
                [-12.613164900999891, 15.08539676600003],
                [-12.617262840999956, 15.087529183000186],
                [-12.621185301999958, 15.089311600000144],
                [-12.62743091699997, 15.091640472999984],
                [-12.63313579499993, 15.094311714000128],
                [-12.637299536999933, 15.09525966600006],
                [-12.646873472999971, 15.099852563000184],
                [-12.656673431999934, 15.10500240400006],
                [-12.65987586999995, 15.107125282000027],
                [-12.666311263999944, 15.108584405000045],
                [-12.671151160999955, 15.108460427000068],
                [-12.67474079099992, 15.107975006000174],
                [-12.678519248999919, 15.106620788000043],
                [-12.681586264999964, 15.104737282000144],
                [-12.684666633999825, 15.101634979000039],
                [-12.685956954999824, 15.098340035000092],
                [-12.686718939999878, 15.094169616999977],
                [-12.686742781999897, 15.091905593999968],
                [-12.68641471899997, 15.089117050000027],
                [-12.686798095999961, 15.08685779700005],
                [-12.68934631299993, 15.084286689000123],
                [-12.69153976399997, 15.084322929999985],
                [-12.694196700999896, 15.086725236000177],
                [-12.695528030999924, 15.089205742000047],
                [-12.695214270999884, 15.092255592000186],
                [-12.69621944499994, 15.094960214000139],
                [-12.698816299999862, 15.098737716000073],
                [-12.702322005999918, 15.09995555800009],
                [-12.713870047999933, 15.103335381000079],
                [-12.726017951999893, 15.109389305000036],
                [-12.734910965999916, 15.116840362000062],
                [-12.746048927999936, 15.12988567299999],
                [-12.750100134999911, 15.132392882999966],
                [-12.75529193899996, 15.136693002000072],
                [-12.778841018999856, 15.144040109000173],
                [-12.789874076999865, 15.150918961],
                [-12.79344081899984, 15.157945633],
                [-12.793881416999966, 15.165699006000182],
                [-12.792015076999974, 15.17431163800012],
                [-12.78778648399998, 15.18395996200013],
                [-12.786379814999918, 15.192354203000036],
                [-12.789544105999937, 15.199587822000183],
                [-12.792931556999974, 15.206867218000127],
                [-12.796270370999935, 15.209671022000123],
                [-12.80096244899994, 15.213044167000021],
                [-12.807733535999887, 15.215291023000134],
                [-12.815185546999885, 15.214674949000141],
                [-12.822225569999944, 15.21376800600018],
                [-12.828463554999928, 15.210557938000136],
                [-12.832052230999977, 15.206289291000132],
                [-12.834501266999894, 15.203673363000064],
                [-12.836404799999912, 15.20242977200013],
                [-12.838437079999949, 15.200097083000117],
                [-12.842170714999952, 15.198985101000119],
                [-12.84913444599988, 15.199854852000044],
                [-12.852539062999938, 15.202068328000053],
                [-12.854730605999919, 15.206069946000014],
                [-12.855281828999978, 15.209864616000118],
                [-12.857392310999955, 15.217343331000109],
                [-12.863156317999938, 15.225890160000063],
                [-12.870072364999942, 15.231520653000075],
                [-12.879069328999947, 15.23596668200014],
                [-12.887074470999892, 15.239197731000047],
                [-12.892586706999964, 15.243495942000152],
                [-12.895855902999926, 15.24754238200012],
                [-12.898062705999905, 15.251407623000091],
                [-12.89891624399985, 15.254800796999973],
                [-12.898602485999902, 15.26139259300004],
                [-12.8972854619999, 15.264195442000073],
                [-12.895483969999816, 15.267105103],
                [-12.891682624999873, 15.268905640000071],
                [-12.885886192999976, 15.26930999699999],
                [-12.879207609999924, 15.269304276000184],
                [-12.876083373999961, 15.268470765000018],
                [-12.874632835999932, 15.267288209000071],
                [-12.872714042999917, 15.26485061699998],
                [-12.869187354999951, 15.261416436000161],
                [-12.864221572999952, 15.258301736000021],
                [-12.861307143999966, 15.25682449400017],
                [-12.856103896999969, 15.254544257000077],
                [-12.852436065999939, 15.253784179000093],
                [-12.849388122999926, 15.254145623000113],
                [-12.848051069999883, 15.255749703000163],
                [-12.847051620999878, 15.257970810000188],
                [-12.843973158999972, 15.261398316000054],
                [-12.840026855999952, 15.265374184000052],
                [-12.83536148099995, 15.272188187000154],
                [-12.833628653999938, 15.278750419000062],
                [-12.83513927499996, 15.288244249000059],
                [-12.839167593999889, 15.29498768000019],
                [-12.840393065999876, 15.297427177000145],
                [-12.844340323999972, 15.306438446000072],
                [-12.84697723499994, 15.311865806000071],
                [-12.850083350999967, 15.315380098000105],
                [-12.85373687699996, 15.318029404000072],
                [-12.86266613, 15.322732925000139],
                [-12.878546713999981, 15.33193779100003],
                [-12.882311820999917, 15.33400249499999],
                [-12.889528273999872, 15.336652757000081],
                [-12.895143508999979, 15.33677768800004],
                [-12.908524513999907, 15.336400985000012],
                [-12.91870880099998, 15.337930680000056],
                [-12.923641204999967, 15.339738846999978],
                [-12.93139839099996, 15.344355583000038],
                [-12.935015677999957, 15.348123549999968],
                [-12.940286636999815, 15.353117944000019],
                [-12.943432806999965, 15.356593132000171],
                [-12.947360038999932, 15.363156319000097],
                [-12.94842624599994, 15.367468834000135],
                [-12.947587013999964, 15.377717972000085],
                [-12.947099685999888, 15.389690400000063],
                [-12.947435379999945, 15.40345287299999],
                [-12.948097227999824, 15.408635140000058],
                [-12.947379111999908, 15.414020538000159],
                [-12.947046278999892, 15.417734147000033],
                [-12.944565772999908, 15.4219427110001],
                [-12.93718433399988, 15.428113938000081],
                [-12.928778647999934, 15.43319988400009],
                [-12.925322532999871, 15.438761711999973],
                [-12.925290106999967, 15.441988946000038],
                [-12.927034378999963, 15.445016860000123],
                [-12.929763793999939, 15.445688249000114],
                [-12.933382033999976, 15.446224214000154],
                [-12.939018249999947, 15.444626809000056],
                [-12.941893577999963, 15.44310665100005],
                [-12.94836902599991, 15.443102838000186],
                [-12.948466300999883, 15.442179679999981],
                [-12.951984404999905, 15.441949844000021],
                [-12.957374572999981, 15.442789078000146],
                [-12.961269377999884, 15.446413993000135],
                [-12.962473868999894, 15.45074272100004],
                [-12.962956428999973, 15.454888345000029],
                [-12.961427687999958, 15.459775924000041],
                [-12.960453986999937, 15.462093353],
                [-12.96066379499996, 15.464265824000108],
                [-12.960071563999975, 15.469435692000104],
                [-12.960226058999979, 15.475652695000122],
                [-12.963180541999918, 15.487446786000135],
                [-12.963149070999975, 15.497569085],
                [-12.964253424999981, 15.500452043],
                [-12.967031477999967, 15.501576423000131],
                [-12.968756675999884, 15.501303673000109],
                [-12.97227191899998, 15.499404908000145],
                [-12.974771499999918, 15.497593879000135],
                [-12.982004164999978, 15.495448113000066],
                [-12.992173195999897, 15.495600700000125],
                [-13.00022983599996, 15.494964600000117],
                [-13.009988785999951, 15.488888740999982],
                [-13.016142843999944, 15.483445168000173],
                [-13.020053862999816, 15.480491638000046],
                [-13.022411345999899, 15.479961395000146],
                [-13.036504745999935, 15.478826522000134],
                [-13.047620773999938, 15.477697372000137],
                [-13.052605627999924, 15.477696419000154],
                [-13.065689085999963, 15.48145771000003],
                [-13.077777861999834, 15.488687516000141],
                [-13.087218284999949, 15.492216110000072],
                [-13.092996597999957, 15.493469237999989],
                [-13.099926947999961, 15.495293618000119],
                [-13.10350704299998, 15.49801254300013],
                [-13.104572295999901, 15.502871514000105],
                [-13.104795455999977, 15.505681038000034],
                [-13.10290050499998, 15.512084008000102],
                [-13.101154327999893, 15.52104091699999],
                [-13.099497794999877, 15.525894165000125],
                [-13.095575331999953, 15.534109115000035],
                [-13.09062290199995, 15.541741372000104],
                [-13.08624267599987, 15.551314354000112],
                [-13.08591079599995, 15.555185318000099],
                [-13.086104392999971, 15.559032440000067],
                [-13.087318419999974, 15.563015937000102],
                [-13.092298507999942, 15.568653106000113],
                [-13.094199179999976, 15.571108819000187],
                [-13.09439563799998, 15.573907852000048],
                [-13.092979431999936, 15.57504272500006],
                [-13.089309692999961, 15.576465607000046],
                [-13.087847708999902, 15.577795982000055],
                [-13.087311743999976, 15.579728127000124],
                [-13.08737182699997, 15.581306459000189],
                [-13.089282988999912, 15.582613945000162],
                [-13.09245681799996, 15.58350181700007],
                [-13.099775312999952, 15.58593750099999],
                [-13.108540533999872, 15.589483262000044],
                [-13.129841804999955, 15.598312378000116],
                [-13.142971039999964, 15.603229523999971],
                [-13.156627654999966, 15.609859467000092],
                [-13.160159109999938, 15.6120901110001],
                [-13.16899490399993, 15.617328644],
                [-13.18137073399987, 15.623934746000032],
                [-13.186491011999863, 15.625267982000025],
                [-13.191568374999918, 15.625931740000055],
                [-13.195121765999943, 15.625864982000053],
                [-13.20081710799991, 15.62352085200007],
                [-13.203303336999852, 15.621341705000134],
                [-13.205694198999879, 15.61877918200014],
                [-13.207744597999977, 15.615687370000103],
                [-13.212325096999962, 15.61094284000012],
                [-13.214312552999843, 15.609333039999967],
                [-13.21999359199998, 15.608613968000157],
                [-13.222457884999926, 15.608968735000133],
                [-13.225653648999923, 15.610285759000021],
                [-13.228203773999894, 15.612171173000036],
                [-13.230068205999885, 15.615006448000088],
                [-13.231326102999958, 15.617544174999978],
                [-13.235913276999895, 15.630707741000094],
                [-13.238752365999972, 15.636325837000072],
                [-13.241134644999931, 15.63903522400011],
                [-13.24219226899993, 15.64087295500002],
                [-13.246037481999963, 15.645709037000131],
                [-13.24928188399997, 15.650488853000013],
                [-13.251791000999901, 15.655238151000049],
                [-13.25274658199993, 15.661711694000019],
                [-13.253014563999898, 15.66810798600011],
                [-13.252370833999919, 15.671331405000046],
                [-13.250678061999963, 15.674486160000072],
                [-13.24583721199997, 15.67779445800005],
                [-13.24265384599994, 15.677757263999979],
                [-13.23517799299998, 15.674564362000126],
                [-13.228986739999925, 15.67272091000001],
                [-13.223741531999906, 15.672798156],
                [-13.221236229999931, 15.673331261000158],
                [-13.219184875999929, 15.674261092000108],
                [-13.217816351999943, 15.675383567000154],
                [-13.215223312999967, 15.678384781000148],
                [-13.21163654299994, 15.684399605000067],
                [-13.209340094999902, 15.688838958000133],
                [-13.207972525999935, 15.692775726999969],
                [-13.207950591999975, 15.69532108400017],
                [-13.20881557499996, 15.696546554000122],
                [-13.215668678999975, 15.70136261],
                [-13.219752312999958, 15.70479011499998],
                [-13.224530218999973, 15.709433555000146],
                [-13.22771930599987, 15.712972641000079],
                [-13.2327842709999, 15.717553139000131],
                [-13.245892524999931, 15.72977829100006],
                [-13.250751494999975, 15.73332500500004],
                [-13.263174056999958, 15.741362572000071],
                [-13.275730132999911, 15.751409530000046],
                [-13.28283405399992, 15.758111954000015],
                [-13.284504889999937, 15.759081842000171],
                [-13.28715229099987, 15.761302948000093],
                [-13.29067897899995, 15.764725686000133],
                [-13.291957855999954, 15.766533851000077],
                [-13.29290199299993, 15.769391060000146],
                [-13.29229354899985, 15.773594857000091],
                [-13.292213440999944, 15.778548241000124],
                [-13.290973662999875, 15.782690048000063],
                [-13.289929388999894, 15.78465175700012],
                [-13.28765010899997, 15.7879428870001],
                [-13.285580634999974, 15.789648057000136],
                [-13.283007621999957, 15.790297509000027],
                [-13.28006839699998, 15.789757729000144],
                [-13.277298926999947, 15.78780365],
                [-13.275108336999892, 15.785262109000143],
                [-13.273023605999981, 15.78417396499998],
                [-13.27063846499982, 15.78350448600014],
                [-13.267198562999965, 15.783802033000143],
                [-13.265300749999938, 15.786350250000055],
                [-13.265325545999872, 15.788014413000155],
                [-13.26624202599993, 15.791829109000105],
                [-13.267804146999879, 15.79392624000019],
                [-13.273678779999955, 15.797740936000139],
                [-13.282917974999918, 15.804960252000058],
                [-13.287606239999889, 15.808164597000086],
                [-13.294827460999898, 15.814991952000184],
                [-13.297521591999953, 15.818572045000053],
                [-13.299478530999977, 15.823752403000185],
                [-13.301882743999954, 15.837543488000051],
                [-13.301289558999883, 15.846506119000082],
                [-13.299150465999901, 15.854092599000012],
                [-13.298290252999948, 15.859537124000099],
                [-13.298285484999951, 15.864086152000084],
                [-13.299833297999896, 15.870016098000121],
                [-13.301983833999941, 15.872758866000027],
                [-13.306569098999887, 15.876907348000088],
                [-13.308432577999895, 15.877994537000063],
                [-13.317232132999948, 15.882195473000024],
                [-13.323552131999975, 15.885113715000045],
                [-13.328957557999956, 15.891238212000133],
                [-13.329151153999874, 15.897815705000141],
                [-13.324267387999953, 15.90618038200006],
                [-13.318473815999937, 15.911185264000096],
                [-13.31357097699987, 15.91596698799998],
                [-13.312541960999908, 15.9190578460001],
                [-13.312098502999902, 15.921316147000027],
                [-13.313056944999971, 15.924957275000168],
                [-13.315258025999924, 15.927652359000035],
                [-13.318165778999912, 15.928631782000025],
                [-13.325452803999951, 15.928646088000107],
                [-13.328346251999903, 15.929520605999983],
                [-13.337268828999981, 15.934313773000042],
                [-13.341917991999935, 15.93715000100002],
                [-13.349217414999828, 15.943719864000172],
                [-13.35176086399997, 15.94682407400012],
                [-13.358445166999957, 15.95706367400004],
                [-13.360968589999914, 15.962241172000176],
                [-13.362395286999913, 15.967366219000098],
                [-13.364689826999893, 15.973070144000133],
                [-13.365991592999933, 15.977436066000053],
                [-13.366375922999964, 15.981141090000051],
                [-13.363800047999973, 15.992797851000034],
                [-13.363766670999894, 15.995637894000083],
                [-13.36439323299993, 15.997943878000115],
                [-13.369597434999946, 16.004484176],
                [-13.377448081999887, 16.01333046100018],
                [-13.377920151999888, 16.021022796000125],
                [-13.377063751999913, 16.03151893600011],
                [-13.376584051999941, 16.03497505200005],
                [-13.375415801999964, 16.046602250000035],
                [-13.374959945999933, 16.05325126700012],
                [-13.378868103999878, 16.057483673000036],
                [-13.38652038499987, 16.061429977000046],
                [-13.400849341999901, 16.065700532000164],
                [-13.407836913999972, 16.068553926000163],
                [-13.42798709799996, 16.076210022],
                [-13.438193320999972, 16.08054733300014],
                [-13.444564819999925, 16.08357429500012],
                [-13.450899123999932, 16.09039688199999],
                [-13.45497226699996, 16.09526825],
                [-13.45625591299995, 16.103372575000094],
                [-13.457420349999893, 16.10742759800013],
                [-13.459952353999881, 16.108747482000126],
                [-13.461746214999891, 16.108760835000055],
                [-13.468120575999933, 16.10620498800006],
                [-13.47537231399997, 16.098669052000048],
                [-13.47984409299994, 16.090131760000133],
                [-13.484590529999934, 16.08232498100017],
                [-13.490455627999893, 16.07998466400005],
                [-13.495244979999939, 16.07953834600005],
                [-13.498870849999946, 16.08060264599999],
                [-13.502313613999945, 16.083461762000127],
                [-13.50661277699993, 16.08731842000003],
                [-13.511949539999932, 16.09297371000008],
                [-13.51426029299995, 16.09831047],
                [-13.514597891999927, 16.102796555000054],
                [-13.513742446999913, 16.107950210000013],
                [-13.508625030999895, 16.115907670000126],
                [-13.498984203999896, 16.123252907999984],
                [-13.481878280999865, 16.12821197600016],
                [-13.475982000999977, 16.13098],
                [-13.474905046999936, 16.134321109000155],
                [-13.477701061999937, 16.14061708800017],
                [-13.480761, 16.144943000000183],
                [-13.487671, 16.147203000000104],
                [-13.48922300099997, 16.14631799900019],
                [-13.49265399899997, 16.142636999000047],
                [-13.500607490999926, 16.137670517000174],
                [-13.507763863999969, 16.131862640000065],
                [-13.515410421999832, 16.12647628800005],
                [-13.522043227999916, 16.124429702000043],
                [-13.527992248999908, 16.125183105000076],
                [-13.533035277999886, 16.12665748600017],
                [-13.539442062999854, 16.13010978800014],
                [-13.548572540999828, 16.133220674000142],
                [-13.554871558999935, 16.134416580000163],
                [-13.560623167999836, 16.13512230000009],
                [-13.566520691999926, 16.134609224000087],
                [-13.57580089499993, 16.132972717000143],
                [-13.581755637999947, 16.13117408800008],
                [-13.587342262999982, 16.12748909000004],
                [-13.594968794999943, 16.124416351000093],
                [-13.6105976099999, 16.117391587000043],
                [-13.624201774999847, 16.110410690000037],
                [-13.630687713999862, 16.107189179000045],
                [-13.636444090999817, 16.106262207999976],
                [-13.643763541999931, 16.106262207999976],
                [-13.650264738999965, 16.10772705200003],
                [-13.658381461999909, 16.109157563],
                [-13.66123676299992, 16.108715058000087],
                [-13.66481113499998, 16.107221603000085],
                [-13.66696357599983, 16.10507583700013],
                [-13.66922187699987, 16.101594923999983],
                [-13.673679350999919, 16.09701347399999],
                [-13.678223608999872, 16.094387054000094],
                [-13.681743620999953, 16.095012666],
                [-13.684592246999955, 16.096363067000084],
                [-13.690644263999957, 16.101350785000136],
                [-13.696200370999918, 16.1062870020001],
                [-13.702280043999906, 16.11143112299999],
                [-13.709024429999886, 16.11999511700003],
                [-13.715420721999863, 16.129503250000084],
                [-13.719435691999877, 16.14132499600015],
                [-13.719903946999978, 16.143955232000167],
                [-13.718619346999844, 16.147327423000036],
                [-13.715977667999937, 16.15027046299997],
                [-13.711340902999893, 16.15371513400015],
                [-13.708602904999907, 16.156469346000165],
                [-13.704148292999946, 16.161792754999965],
                [-13.702617644999862, 16.16567993200016],
                [-13.70201301599991, 16.16901016300011],
                [-13.70266056099996, 16.17389679000007],
                [-13.70433235199988, 16.17831993200008],
                [-13.707813262999935, 16.180921554000065],
                [-13.712422370999946, 16.181842804999974],
                [-13.717817306999905, 16.181406021999976],
                [-13.721670149999909, 16.178987502999973],
                [-13.72660732299994, 16.17432212900013],
                [-13.730523109999865, 16.169651031],
                [-13.732828139999981, 16.166191101000095],
                [-13.735873222999942, 16.160903931000178],
                [-13.739485739999964, 16.157155990000092],
                [-13.744957923999948, 16.152067184000032],
                [-13.747321128999829, 16.150049210000077],
                [-13.753898619999973, 16.146858215000123],
                [-13.76015949299989, 16.14462280300006],
                [-13.765889166999955, 16.14345932100008],
                [-13.773232460999964, 16.141231537000124],
                [-13.778367996999975, 16.141021728999988],
                [-13.78843784299994, 16.142992019000133],
                [-13.7917137149999, 16.14348983799999],
                [-13.795735359999867, 16.143213272000025],
                [-13.798214911999935, 16.14233017],
                [-13.803302764999898, 16.13984870900009],
                [-13.812171935999856, 16.135772706000125],
                [-13.820620536999968, 16.131034851000038],
                [-13.826513289999923, 16.126764299],
                [-13.832801819999872, 16.119743347000167],
                [-13.836980819999951, 16.11366844200012],
                [-13.841027259999919, 16.10920524600016],
                [-13.843576431999907, 16.106767654000066],
                [-13.84638023399998, 16.10589408800007],
                [-13.848047256999962, 16.106351852000103],
                [-13.85047721799998, 16.10854721000004],
                [-13.851542472999881, 16.111602783000023],
                [-13.853877066999871, 16.120105744],
                [-13.854156492999891, 16.123352051000097],
                [-13.854958532999945, 16.12586593700013],
                [-13.855840681999894, 16.127305984000145],
                [-13.857469557999934, 16.128105164000033],
                [-13.861063956999885, 16.129020692000097],
                [-13.864662169999974, 16.1293258660001],
                [-13.866586684999959, 16.13027000500017],
                [-13.867320059999884, 16.131744385000104],
                [-13.867220877999955, 16.136156082000014],
                [-13.867754934999823, 16.140104294000025],
                [-13.872353552999982, 16.14723205500013],
                [-13.876714706999962, 16.151479722000147],
                [-13.878014563999955, 16.15330505500009],
                [-13.878570556999875, 16.15522194000016],
                [-13.87882232599992, 16.158857345000115],
                [-13.87845897699998, 16.162200927000185],
                [-13.877690315999871, 16.166481018000184],
                [-13.876131057999885, 16.17101669300007],
                [-13.874734877999856, 16.172779083000023],
                [-13.871724128999972, 16.175657272000024],
                [-13.868060111999853, 16.180324554000038],
                [-13.867578506999962, 16.182306289000167],
                [-13.867787361999945, 16.184770584000034],
                [-13.86912822699992, 16.188270569000167],
                [-13.872497557999907, 16.192018509000093],
                [-13.876405715999965, 16.195667267000033],
                [-13.879090308999878, 16.197528840000132],
                [-13.882053374999828, 16.198711395000032],
                [-13.884744643999909, 16.198694229000182],
                [-13.88772296899998, 16.19727516300003],
                [-13.891760824999949, 16.19442749000001],
                [-13.895455360999904, 16.19211387600012],
                [-13.899453162999919, 16.190820695000127],
                [-13.901713370999971, 16.190532684000175],
                [-13.905633924999904, 16.192018509000093],
                [-13.91013813099994, 16.194223405000116],
                [-13.913379667999891, 16.196775437000042],
                [-13.915401457999849, 16.199123382000096],
                [-13.916872978999834, 16.201702118000128],
                [-13.917957304999845, 16.2070274350001],
                [-13.91931915399988, 16.21824455300009],
                [-13.919672012999968, 16.22177505600007],
                [-13.919392584999912, 16.227512360000105],
                [-13.91972350999987, 16.22921752999997],
                [-13.920617104999906, 16.22999954200003],
                [-13.922658919999833, 16.229980468000065],
                [-13.927841185999966, 16.227376938000134],
                [-13.930746078999903, 16.227151870000114],
                [-13.932480812999927, 16.227609633999975],
                [-13.934762, 16.229145051000103],
                [-13.93725681199993, 16.231430053000167],
                [-13.939222335999943, 16.234159469000076],
                [-13.940486907999912, 16.234973907000096],
                [-13.942437171999927, 16.234804154000187],
                [-13.94823551199994, 16.23244094800009],
                [-13.95294761699995, 16.23081970200002],
                [-13.956643104999898, 16.228326798000182],
                [-13.96604537899998, 16.223230362000038],
                [-13.969825744999866, 16.222173692000183],
                [-13.97194385499995, 16.222297668000124],
                [-13.9740781779999, 16.223621370000046],
                [-13.974028587999953, 16.224449159000073],
                [-13.972611425999958, 16.227628708000168],
                [-13.971177100999967, 16.23309707599998],
                [-13.970708846999912, 16.237310408999974],
                [-13.970529555999917, 16.245733261000055],
                [-13.970203400999935, 16.247781754000187],
                [-13.968466758999966, 16.251483918000076],
                [-13.966191292999952, 16.254615785000112],
                [-13.962464332999957, 16.258831025000177],
                [-13.959239959999934, 16.261871337],
                [-13.952613830999951, 16.267206192000117],
                [-13.951880454999923, 16.269298553000112],
                [-13.95263767299997, 16.27135276900009],
                [-13.954933165999819, 16.273023605000105],
                [-13.960299491999933, 16.27523231600003],
                [-13.966485023999951, 16.27622223000003],
                [-13.97145557399989, 16.276290893000123],
                [-13.97620201199993, 16.275920868000128],
                [-13.982764243999952, 16.274562837000133],
                [-13.986154555999974, 16.274641038000084],
                [-13.987222670999927, 16.27525138900006],
                [-13.988501547999931, 16.276870728],
                [-13.98885059399987, 16.278869629000155],
                [-13.988788603999978, 16.283784867],
                [-13.989160536999918, 16.290853500000026],
                [-13.989101408999943, 16.295228958000052],
                [-13.99091243699985, 16.304170609000096],
                [-13.990175246999968, 16.30598068200004],
                [-13.988888740999926, 16.307641982000177],
                [-13.987137793999977, 16.308673858000134],
                [-13.9824514399999, 16.30983352700008],
                [-13.976204871999926, 16.310916901000155],
                [-13.972862242999952, 16.310863495000035],
                [-13.968561171999966, 16.309335709000095],
                [-13.963780402999873, 16.30716133200019],
                [-13.96111011499994, 16.306394576000173],
                [-13.959795950999933, 16.30668640199997],
                [-13.957930565999845, 16.3082561490001],
                [-13.956826208999928, 16.31092834600014],
                [-13.957347870999968, 16.315307616000098],
                [-13.959071158999961, 16.317300797000087],
                [-13.962751388999948, 16.32405662600013],
                [-13.965208053999959, 16.327638626],
                [-13.9682617199999, 16.331073760000038],
                [-13.972307204999936, 16.334131240999966],
                [-13.981691360999946, 16.336547852000024],
                [-13.985863684999913, 16.33794212400005],
                [-13.988673210999934, 16.339361190999966],
                [-13.991228103999958, 16.34224319600014],
                [-13.994771956999955, 16.345579147000024],
                [-13.999994276999871, 16.347391129000016],
                [-14.003899574999878, 16.348325729000067],
                [-14.010259627999972, 16.34946441700015],
                [-14.017098425999961, 16.34910202000009],
                [-14.02221584299997, 16.349472047000063],
                [-14.028157233999877, 16.350435257000186],
                [-14.03112125299998, 16.35212326100003],
                [-14.032935142999975, 16.354843139000025],
                [-14.035237312999925, 16.362565995000068],
                [-14.037814138999977, 16.367601394000076],
                [-14.041813850999972, 16.372888565000096],
                [-14.04665851599998, 16.37760925300006],
                [-14.05146598899995, 16.382545472],
                [-14.056756018999863, 16.38741302500017],
                [-14.066228865999904, 16.3959560400001],
                [-14.068762778999883, 16.40238952700014],
                [-14.069774626999845, 16.403940202000058],
                [-14.074641226999972, 16.40890884500004],
                [-14.080566406999878, 16.41352653600012],
                [-14.08660507299993, 16.417642594000142],
                [-14.091574667999964, 16.420103074000167],
                [-14.098440169999947, 16.422361375000037],
                [-14.102932929999952, 16.42422104000019],
                [-14.106585501999916, 16.427394866000043],
                [-14.108306884999877, 16.430223466000143],
                [-14.108750342999826, 16.43639564500006],
                [-14.111304282999924, 16.441453935000084],
                [-14.114363670999921, 16.444456101000128],
                [-14.119155882999962, 16.447967530000142],
                [-14.124621391999938, 16.450382233000028],
                [-14.130141257999924, 16.45193481400014],
                [-14.136466026999926, 16.453010559000063],
                [-14.138361930999963, 16.453691483000057],
                [-14.140110014999891, 16.456306457999972],
                [-14.140794754999888, 16.458513259000085],
                [-14.141008376999878, 16.4606380460001],
                [-14.140871047999894, 16.463506698000117],
                [-14.140168189999827, 16.465608597000028],
                [-14.13841915099988, 16.46873474100005],
                [-14.13331222599993, 16.47450256400009],
                [-14.132812499999886, 16.475456238000106],
                [-14.133054733999927, 16.47679710400007],
                [-14.133968351999954, 16.477638245000094],
                [-14.136420249999958, 16.47800636300019],
                [-14.137958526999967, 16.477605819000075],
                [-14.139620781999895, 16.475547790000064],
                [-14.14280700699993, 16.472261429000184],
                [-14.14454746299998, 16.47111892700019],
                [-14.147175788999903, 16.47098732100011],
                [-14.151703833999932, 16.47200775200008],
                [-14.15385341699988, 16.47352218700007],
                [-14.156222342999968, 16.476114274000054],
                [-14.158660888999918, 16.47953224200006],
                [-14.16311931599995, 16.489021301000093],
                [-14.167080878999911, 16.493055343000094],
                [-14.1716794969999, 16.495727538000153],
                [-14.176877974999968, 16.497541428000147],
                [-14.180174827999906, 16.499008179000043],
                [-14.189602851999894, 16.50481414900014],
                [-14.193748473999904, 16.506593705000114],
                [-14.198390005999897, 16.508195877000162],
                [-14.200677870999925, 16.509580612000093],
                [-14.203271864999977, 16.513309479000156],
                [-14.20429897299988, 16.51668548500004],
                [-14.204427718999966, 16.520133972000167],
                [-14.203359603999957, 16.522949219000168],
                [-14.199315071999877, 16.52857589700011],
                [-14.199268341999925, 16.530103684000153],
                [-14.199954031999823, 16.53140068000016],
                [-14.203523635999943, 16.53239250100006],
                [-14.209444045999874, 16.534961700999986],
                [-14.215110778999872, 16.539842605000104],
                [-14.220416067999963, 16.543859481000084],
                [-14.228859900999907, 16.54537964000002],
                [-14.237832068999921, 16.546627046000026],
                [-14.24701595199997, 16.545719148000046],
                [-14.250763892999942, 16.543067932000042],
                [-14.253170012999874, 16.540647508000177],
                [-14.255909918999976, 16.535839080000017],
                [-14.257150649999971, 16.530044556000064],
                [-14.257777213999873, 16.52546310400004],
                [-14.256570814999975, 16.52221870400018],
                [-14.260947226999974, 16.52174568200013],
                [-14.263631820999933, 16.52785110600007],
                [-14.266861914999879, 16.533927918000188],
                [-14.272747992999882, 16.54318809500012],
                [-14.275157927999885, 16.548746110000025],
                [-14.275836944999867, 16.552814483000134],
                [-14.27517223399991, 16.558073044000082],
                [-14.27371597299998, 16.562995910000097],
                [-14.271813391999956, 16.566415788000086],
                [-14.269765852999967, 16.57139968900009],
                [-14.269895553999959, 16.576229095000144],
                [-14.273749351999982, 16.580549240000096],
                [-14.279289244999973, 16.584634782000137],
                [-14.282559394999907, 16.585699082000076],
                [-14.286430358999894, 16.584947586000055],
                [-14.289314268999874, 16.583187104000046],
                [-14.290907858999901, 16.58142280600009],
                [-14.29245471899992, 16.57889175500003],
                [-14.294403074999934, 16.57512092600001],
                [-14.296838759999844, 16.57181358300005],
                [-14.298387526999932, 16.570541381000112],
                [-14.302407264999886, 16.570028305000108],
                [-14.30637550399996, 16.56989860600015],
                [-14.313153265999858, 16.57011413600003],
                [-14.317168236999976, 16.568695070000047],
                [-14.320674895999957, 16.565876008000032],
                [-14.32378005899983, 16.56466674800015],
                [-14.327284812999892, 16.56464767500006],
                [-14.329901695999979, 16.565662385],
                [-14.332222937999916, 16.568170548000126],
                [-14.333733557999949, 16.57103538500013],
                [-14.334524154999883, 16.574153901000045],
                [-14.334540365999828, 16.57744598500011],
                [-14.334179878999919, 16.58063125700005],
                [-14.333251, 16.5834140770001],
                [-14.330198287999906, 16.593984605],
                [-14.328003881999962, 16.600914002000025],
                [-14.32749271299997, 16.60533714400009],
                [-14.327750204999973, 16.611436845000014],
                [-14.327634811999928, 16.617893219999985],
                [-14.327065467999944, 16.63008880699999],
                [-14.32835197399993, 16.634458542000175],
                [-14.329797743999904, 16.637870789000146],
                [-14.332518577999963, 16.64018058700009],
                [-14.336979864999876, 16.64160156300011],
                [-14.34147644099994, 16.641405107000026],
                [-14.351642609999828, 16.63929176300013],
                [-14.35968875899988, 16.636127472000055],
                [-14.36218643199993, 16.634826661000147],
                [-14.366066932999956, 16.633260727999982],
                [-14.369966505999912, 16.633466720000172],
                [-14.374909399999979, 16.63486480800003],
                [-14.378915787999915, 16.636404038000023],
                [-14.38261222899996, 16.63726425300007],
                [-14.386564253999836, 16.637033462000034],
                [-14.389358519999917, 16.636508941999978],
                [-14.393817900999863, 16.63496971100011],
                [-14.396918296999957, 16.633304597000176],
                [-14.399630545999855, 16.632377624000128],
                [-14.402458190999937, 16.631978988000185],
                [-14.40607261599996, 16.632326126000123],
                [-14.409199713999953, 16.63379860000009],
                [-14.41089534699995, 16.63619232300016],
                [-14.412771223999982, 16.640052796000134],
                [-14.413875580999957, 16.643913269000052],
                [-14.413833618999945, 16.647590637],
                [-14.4141712199999, 16.648845673000153],
                [-14.416396141999883, 16.651304246000052],
                [-14.418750761999945, 16.652208328000086],
                [-14.42073535999998, 16.6522140510001],
                [-14.422537803999944, 16.650962830000083],
                [-14.425803184999836, 16.647922517000154],
                [-14.429437637999968, 16.645898820000127],
                [-14.434938430999921, 16.641939164000064],
                [-14.437302588999955, 16.639522553],
                [-14.440514563999898, 16.633373261000145],
                [-14.44251346599998, 16.628326416000107],
                [-14.445081710999943, 16.624782563000053],
                [-14.447649955999907, 16.623235702999978],
                [-14.450509069999953, 16.62270736800008],
                [-14.455131531999882, 16.623159409000095],
                [-14.471604345999936, 16.626140594000105],
                [-14.476013182999964, 16.625980377000076],
                [-14.478694915999881, 16.625102998000045],
                [-14.480957031999822, 16.623239518000048],
                [-14.483892441999956, 16.61994934100005],
                [-14.487285613999973, 16.616661072],
                [-14.491734503999908, 16.614488603000098],
                [-14.4975185399999, 16.613796233000016],
                [-14.501466750999953, 16.614110948000018],
                [-14.506605148999938, 16.61789703300002],
                [-14.5100574499999, 16.621345519999977],
                [-14.512599944999977, 16.622920991000058],
                [-14.516812324999876, 16.624753952000106],
                [-14.52111911899982, 16.626110078000067],
                [-14.52623271899995, 16.627258301000097],
                [-14.529360769999869, 16.628997803000175],
                [-14.532485961999896, 16.631607055000018],
                [-14.534865379999871, 16.635139466],
                [-14.538515090999965, 16.639123917000063],
                [-14.541051864999872, 16.641580582000017],
                [-14.544303892999949, 16.643472670999984],
                [-14.546505927999874, 16.643297196000162],
                [-14.54799747599992, 16.641984939],
                [-14.54912090299996, 16.6391468060001],
                [-14.550029753999922, 16.634962082000072],
                [-14.550070762999951, 16.630928041000175],
                [-14.551916121999966, 16.623907089000113],
                [-14.55275917099982, 16.621425628000054],
                [-14.554564475999882, 16.61856079100005],
                [-14.556211470999926, 16.616979600000093],
                [-14.558073042999922, 16.61647415100009],
                [-14.559932707999963, 16.616928100000052],
                [-14.563183784999921, 16.619176865000043],
                [-14.56628322499995, 16.62184524500003],
                [-14.570041655999887, 16.623622894000164],
                [-14.572244643999909, 16.62371063300003],
                [-14.574492454999927, 16.623420716000112],
                [-14.577090263999935, 16.62258529700017],
                [-14.58130359699993, 16.620700837000015],
                [-14.584752082999955, 16.619781494000108],
                [-14.587609291999968, 16.620040893000123],
                [-14.59309959399991, 16.62316322400011],
                [-14.594909668999946, 16.623418809000043],
                [-14.597027777999926, 16.62209892200019],
                [-14.60061359399998, 16.618194579000146],
                [-14.603080749999947, 16.617189407000126],
                [-14.605776785999979, 16.618402481000146],
                [-14.608350753999957, 16.624086380000108],
                [-14.609807014999944, 16.62605095000015],
                [-14.613249778999943, 16.628318786000023],
                [-14.61710262399987, 16.628694534000033],
                [-14.624213219999888, 16.62856865000009],
                [-14.627422332999913, 16.628320693999967],
                [-14.629027367999925, 16.627586363999967],
                [-14.630298614999958, 16.62645721399997],
                [-14.633637428999918, 16.62055397000006],
                [-14.635628699999927, 16.618064881000066],
                [-14.638811110999882, 16.616716385000075],
                [-14.640905379999879, 16.617370606000122],
                [-14.645852088999902, 16.621883392000086],
                [-14.649257660999865, 16.624799729000074],
                [-14.651807784999903, 16.62881088200004],
                [-14.65199851899996, 16.63134574800017],
                [-14.651036261999934, 16.63303947500009],
                [-14.649490356999934, 16.633619309000096],
                [-14.642188071999954, 16.634292603],
                [-14.640710829999932, 16.635864257000037],
                [-14.63990592999994, 16.638826371000164],
                [-14.639757155999916, 16.642627717000096],
                [-14.640109061999965, 16.6446323400001],
                [-14.641861916999972, 16.647426606000067],
                [-14.64590835599995, 16.649385453000036],
                [-14.650615691999974, 16.649700165000127],
                [-14.659394263999957, 16.64750671500019],
                [-14.66561412699997, 16.646335603000125],
                [-14.672986029999947, 16.645900727000026],
                [-14.679221152999958, 16.64597892900008],
                [-14.68602466599998, 16.645784377000155],
                [-14.689773558999889, 16.645294189000083],
                [-14.694198608999955, 16.644136430000117],
                [-14.699353217999942, 16.64227676500019],
                [-14.704046247999827, 16.64024543800008],
                [-14.710390090999965, 16.638576507000096],
                [-14.713907242999937, 16.638273240000103],
                [-14.717139243999839, 16.639064790000077],
                [-14.720297812999945, 16.640953063000097],
                [-14.722781180999903, 16.643747329000178],
                [-14.724651335999908, 16.646188736000113],
                [-14.725757597999973, 16.64660644600002],
                [-14.727152823999972, 16.646053315000188],
                [-14.727875709999978, 16.645032882000066],
                [-14.727879524999935, 16.642250061000084],
                [-14.72678375299995, 16.63501548800008],
                [-14.727170943999965, 16.633068084000058],
                [-14.72880840299996, 16.631772995000063],
                [-14.731165885999928, 16.631357193000156],
                [-14.734235762999958, 16.63217163200011],
                [-14.736045837999825, 16.632228852000082],
                [-14.73775768299987, 16.630994797000028],
                [-14.737957954999956, 16.628902435999976],
                [-14.736941336999848, 16.62668228200016],
                [-14.73460960299991, 16.624523163000106],
                [-14.73343086299991, 16.62097167999997],
                [-14.733729361999906, 16.619417191000082],
                [-14.735538481999924, 16.618499756000062],
                [-14.738300322999919, 16.618694305000133],
                [-14.741422651999926, 16.619998931000055],
                [-14.749605177999968, 16.624509812000156],
                [-14.752463341999942, 16.626892089000023],
                [-14.753871917999959, 16.630475997000076],
                [-14.75367259899997, 16.63289451600008],
                [-14.752517699999885, 16.635515213000076],
                [-14.752119063999942, 16.638875961000167],
                [-14.752114295999888, 16.6419830320001],
                [-14.753326414999947, 16.64591598600009],
                [-14.754474638999966, 16.647977830000173],
                [-14.75782585099995, 16.651119232000042],
                [-14.761440277999895, 16.653533935000098],
                [-14.765089035999893, 16.65525055000012],
                [-14.767193794999969, 16.655473708999978],
                [-14.769134520999955, 16.655000687000097],
                [-14.771735191999937, 16.653070451000076],
                [-14.776839256999835, 16.647399903000064],
                [-14.785145758999931, 16.630954742000142],
                [-14.787318229999926, 16.629180909000013],
                [-14.789652824999905, 16.629024507000054],
                [-14.792409895999924, 16.630319595000117],
                [-14.794795990999944, 16.632608415000107],
                [-14.797720909999896, 16.636039733000132],
                [-14.804954528999872, 16.645233154000096],
                [-14.807999611999946, 16.649332046000154],
                [-14.810277938999889, 16.651895523000064],
                [-14.813097000999903, 16.653577805000168],
                [-14.81571960399998, 16.653476715000124],
                [-14.818450926999958, 16.652423858000134],
                [-14.820177077999972, 16.650762558],
                [-14.82204818799994, 16.647733688000073],
                [-14.822625159999916, 16.645742417000065],
                [-14.822125433999872, 16.64255523600002],
                [-14.822379112999897, 16.63957786600008],
                [-14.825346946999957, 16.6352863300001],
                [-14.82827567999982, 16.634128571000133],
                [-14.830862045999936, 16.634597778000114],
                [-14.834236143999874, 16.637006761000066],
                [-14.83858108499993, 16.639659882000103],
                [-14.841883659999894, 16.63983726600003],
                [-14.847436904999824, 16.638504028000057],
                [-14.856299399999898, 16.636039733000132],
                [-14.86046314299989, 16.635768890000065],
                [-14.86263275199991, 16.637245179000047],
                [-14.86447906599983, 16.64355850300018],
                [-14.86838912899998, 16.64994239900011],
                [-14.873474119999969, 16.657356262000064],
                [-14.877458571999966, 16.659896851000042],
                [-14.881052969999814, 16.65983009300004],
                [-14.884185791999926, 16.65832328700003],
                [-14.88582420399996, 16.656333924000023],
                [-14.886786460999929, 16.654447556000093],
                [-14.887893675999976, 16.64731216399997],
                [-14.890746116999878, 16.638015748000157],
                [-14.893631934999917, 16.632528306000154],
                [-14.897472381999876, 16.630273820000127],
                [-14.903288841999938, 16.630279541000164],
                [-14.907939909999982, 16.633060456000123],
                [-14.910057066999855, 16.63463974000007],
                [-14.911633490999975, 16.637414932000183],
                [-14.912022590999925, 16.640619278000088],
                [-14.911389350999968, 16.64513015700004],
                [-14.91138839699994, 16.64731216399997],
                [-14.912334440999814, 16.648118974000113],
                [-14.913987160999966, 16.64806556700006],
                [-14.917014122999944, 16.646242141000187],
                [-14.918165206999959, 16.644756316999974],
                [-14.922106743999962, 16.640657425000143],
                [-14.927707670999837, 16.63545608600009],
                [-14.931501388999948, 16.6322822570001],
                [-14.935791969999912, 16.63052368199999],
                [-14.939996719999954, 16.630962372000056],
                [-14.944412231999877, 16.63349914600019],
                [-14.947040556999923, 16.636943818000077],
                [-14.953587531999915, 16.647756577000052],
                [-14.954529762999982, 16.651105881000092],
                [-14.954578399999946, 16.65474128700015],
                [-14.953535078999948, 16.659906387000092],
                [-14.951150894999955, 16.667943955000055],
                [-14.951199531999976, 16.67392349300013],
                [-14.952637671999923, 16.67679405300015],
                [-14.954374312999903, 16.678611755000077],
                [-14.959486007999828, 16.680717469000058],
                [-14.969751358999929, 16.68558693000017],
                [-14.979002, 16.690462113000024],
                [-14.985541343999955, 16.69207191600009],
                [-14.989329338999937, 16.69089698800002],
                [-14.993433950999929, 16.687116624000055],
                [-14.995854376999887, 16.683000566000032],
                [-14.99689006799997, 16.673870086000022],
                [-14.996834754999838, 16.66170120300012],
                [-14.99783897499998, 16.65213966499999],
                [-14.998921393999979, 16.648841858000083],
                [-15.002075195999907, 16.643165588999977],
                [-15.005979538999838, 16.640365601000155],
                [-15.010851859999832, 16.638532640000108],
                [-15.019697188999942, 16.63591384900002],
                [-15.026898383999878, 16.63252067600007],
                [-15.033058165999819, 16.630502701000125],
                [-15.037365912999974, 16.63022995100016],
                [-15.04127120999982, 16.63053894],
                [-15.044652938999945, 16.63240242],
                [-15.046666145999893, 16.632478714000115],
                [-15.04807472199991, 16.631896974000142],
                [-15.051294326999937, 16.627645492000056],
                [-15.054233549999935, 16.628129960000024],
                [-15.055684089999943, 16.63049697800011],
                [-15.057415961999823, 16.634649278000154],
                [-15.058524130999956, 16.636199951000094],
                [-15.064194678999968, 16.643079758000113],
                [-15.066048621999983, 16.646760940000036],
                [-15.068694114999971, 16.656847],
                [-15.07176971399997, 16.664134980000142],
                [-15.077578543999891, 16.672454834000177],
                [-15.086290359999964, 16.676612854000155],
                [-15.094107628999893, 16.677614211000105],
                [-15.097456932999933, 16.67725372400008],
                [-15.099690437999982, 16.67581939700011],
                [-15.104971884999884, 16.66978836200019],
                [-15.110175131999938, 16.66222191000014],
                [-15.115026473999933, 16.65768242000007],
                [-15.118150709999895, 16.65416336000004],
                [-15.1202688219999, 16.644533158000115],
                [-15.118976593999946, 16.637571335000075],
                [-15.11653613999988, 16.634822846000077],
                [-15.113177299999961, 16.633451462000153],
                [-15.10953140199996, 16.632619858000055],
                [-15.107418059999873, 16.63244056700006],
                [-15.09885978699998, 16.63244438200013],
                [-15.094527243999892, 16.63116836500018],
                [-15.091488837999862, 16.628458023000064],
                [-15.088360786999942, 16.62332153400007],
                [-15.085754394999924, 16.62003707800011],
                [-15.083848, 16.616104126000096],
                [-15.083384513999874, 16.612373352000134],
                [-15.083575247999931, 16.611867905000167],
                [-15.083503723999968, 16.60762977700017],
                [-15.085858344999906, 16.602905273000033],
                [-15.089577673999827, 16.59925651700013],
                [-15.095280645999878, 16.595485688000167],
                [-15.099929808999889, 16.59195709200003],
                [-15.111368179999943, 16.581188203000124],
                [-15.115231513999959, 16.579246522000176],
                [-15.121331213999895, 16.57744980000018],
                [-15.128474235999931, 16.577302933],
                [-15.137627600999906, 16.579090119000114],
                [-15.150131225999928, 16.58345794700017],
                [-15.160998344999882, 16.587898255000027],
                [-15.167026519999922, 16.588827133000166],
                [-15.171788215999925, 16.588106157000027],
                [-15.17506027199994, 16.58638000600007],
                [-15.176543235999873, 16.58078193599999],
                [-15.17795562799995, 16.57884407000006],
                [-15.181227683999964, 16.576688767000178],
                [-15.185020445999896, 16.57510757500006],
                [-15.1928310399999, 16.57287597600009],
                [-15.20190334299997, 16.568418502000043],
                [-15.212189673999944, 16.561088563000112],
                [-15.22011661599987, 16.55533790600009],
                [-15.225879668999937, 16.552579880000167],
                [-15.234496116999935, 16.55215263400015],
                [-15.241379736999875, 16.55399894600015],
                [-15.244843481999965, 16.556680679000067],
                [-15.249438284999883, 16.56202697700013],
                [-15.256585121999876, 16.567539216000114],
                [-15.263290405999953, 16.56831932],
                [-15.266831397999908, 16.56731605500005],
                [-15.271245001999944, 16.56694221500004],
                [-15.27855110199988, 16.568403244000024],
                [-15.290547370999832, 16.57201385500008],
                [-15.301886557999921, 16.573890686000027],
                [-15.309081076999973, 16.57403755300004],
                [-15.315456390999884, 16.57276725800017],
                [-15.322807312999942, 16.56860351600011],
                [-15.333298681999906, 16.563241959000095],
                [-15.335089682999978, 16.56199264600002],
                [-15.33917045699991, 16.560636520000116],
                [-15.342064857999958, 16.56012344300018],
                [-15.345303534999971, 16.559835434999968],
                [-15.349227905999953, 16.560102462000145],
                [-15.351548194999964, 16.561040879000075],
                [-15.35616397799987, 16.561748504000093],
                [-15.360713003999876, 16.560411454000132],
                [-15.363455772999885, 16.559097290000125],
                [-15.366243362999967, 16.556798935000074],
                [-15.369446753999966, 16.553367614000138],
                [-15.372369765999963, 16.550519943000154],
                [-15.375253677999922, 16.548328400000116],
                [-15.379127501999903, 16.54600906400003],
                [-15.381942748999961, 16.5445594790001],
                [-15.39087200199998, 16.53964614900019],
                [-15.392571448999831, 16.53893852300007],
                [-15.399332045999927, 16.537279130000172],
                [-15.402280806999897, 16.53751182600007],
                [-15.408382414999835, 16.538284302000022],
                [-15.413236617999928, 16.539331436000168],
                [-15.420363426999927, 16.541877746000182],
                [-15.423793792999902, 16.543710709000038],
                [-15.426950454999883, 16.546310426000105],
                [-15.428292273999944, 16.54891204800009],
                [-15.428466796999942, 16.550344467],
                [-15.429667472999881, 16.553577424000082],
                [-15.430969238999865, 16.565174102000185],
                [-15.431379317999927, 16.56708717400005],
                [-15.434185981999917, 16.573537827000052],
                [-15.435099601999923, 16.575159073000066],
                [-15.439103126999953, 16.58022499000009],
                [-15.444843291999916, 16.58536338800019],
                [-15.449696540999923, 16.58884811400003],
                [-15.451437949999956, 16.58988762000007],
                [-15.45797443399988, 16.592580796000163],
                [-15.465965270999959, 16.59308242800006],
                [-15.469492911999907, 16.59269142200003],
                [-15.473493575999896, 16.591291428000034],
                [-15.47796535599997, 16.58784294200018],
                [-15.478771208999945, 16.586723327000186],
                [-15.479745864999927, 16.584184645999983],
                [-15.482016562999888, 16.581766130000062],
                [-15.48774528399997, 16.57841873300015],
                [-15.491616247999957, 16.57710266100014],
                [-15.496503829999881, 16.575086594000027],
                [-15.500075339999967, 16.573995591000084],
                [-15.504596710999976, 16.572191239],
                [-15.51052188899996, 16.568834304000177],
                [-15.51602458899987, 16.563089372000036],
                [-15.519137382999872, 16.558244705000163],
                [-15.52077198, 16.552368165000132],
                [-15.520792960999813, 16.550029755000082],
                [-15.520352363999962, 16.545898437000062],
                [-15.518980025999895, 16.542734146000157],
                [-15.51331138699993, 16.536754608000138],
                [-15.510684966999975, 16.53332900999999],
                [-15.509331702999873, 16.530241013000023],
                [-15.509778021999978, 16.527677535],
                [-15.511477470999978, 16.526073455000187],
                [-15.515161513999885, 16.52372360200019],
                [-15.518173217999959, 16.522069932000136],
                [-15.531000135999875, 16.51792335600004],
                [-15.533121107999932, 16.517528535000167],
                [-15.537654875999976, 16.51599311800004],
                [-15.544732093999926, 16.51409530600006],
                [-15.55379295399996, 16.512498856000093],
                [-15.561372756999901, 16.51257133500019],
                [-15.568362236999917, 16.51351547200005],
                [-15.575071333999915, 16.51552581800013],
                [-15.57966899899992, 16.518671036000114],
                [-15.58541774799994, 16.523115159000042],
                [-15.591292380999846, 16.52673530600015],
                [-15.594721794999828, 16.528364182000075],
                [-15.598599433999937, 16.529458998999985],
                [-15.601866721999897, 16.52941513100012],
                [-15.61055850899993, 16.527261733000103],
                [-15.612105368999835, 16.5273189560001],
                [-15.613334656999939, 16.5291252130001],
                [-15.619410513999924, 16.529489518000105],
                [-15.623475074999874, 16.528976441000054],
                [-15.62622356299994, 16.527772904000187],
                [-15.626358031999871, 16.525720596000042],
                [-15.629184721999877, 16.52403259199997],
                [-15.630620956999962, 16.522502900000063],
                [-15.632215498999926, 16.51839637800009],
                [-15.63270473499989, 16.514083863000053],
                [-15.63146877399987, 16.50878715500005],
                [-15.629164695999918, 16.50363922100007],
                [-15.628243445999885, 16.50231933700013],
                [-15.62791442799994, 16.499769210000068],
                [-15.628386496999838, 16.496212006000064],
                [-15.629265785999849, 16.494049072000166],
                [-15.633050917999924, 16.490999222000085],
                [-15.636638640999877, 16.48935699499998],
                [-15.640042305999941, 16.48709869400011],
                [-15.644665717999942, 16.485492706000116],
                [-15.649855612999943, 16.48423194900016],
                [-15.688805580999826, 16.480669022000143],
                [-15.693747519999874, 16.478927613],
                [-15.695942877999983, 16.47665405200013],
                [-15.697115897999879, 16.474882126000068],
                [-15.697804449999921, 16.472881317000144],
                [-15.699965475999932, 16.472106934000124],
                [-15.702445984999827, 16.472534179000036],
                [-15.70552158399994, 16.473634720000064],
                [-15.710726737999948, 16.47632598900003],
                [-15.712435722999942, 16.477876664],
                [-15.71401405399996, 16.47800445500019],
                [-15.719969748999972, 16.48202323900017],
                [-15.723242759999891, 16.48447418300009],
                [-15.724514961999887, 16.4851055150001],
                [-15.728184700999975, 16.487884521000012],
                [-15.730438231999813, 16.489151002000085],
                [-15.733730315999878, 16.490234376000046],
                [-15.737686156999814, 16.490911485000026],
                [-15.744717597999909, 16.49117088400004],
                [-15.750698088999968, 16.492437364000068],
                [-15.760941505999881, 16.492963791000022],
                [-15.765058516999943, 16.49362945500019],
                [-15.76899242299993, 16.493789672000048],
                [-15.776949882999816, 16.494865418000018],
                [-15.781847952999897, 16.49601745700005],
                [-15.785343169999976, 16.497150422000118],
                [-15.788518905999922, 16.498464584000146],
                [-15.790432929999952, 16.498954774000026],
                [-15.795673370999907, 16.50164604299999],
                [-15.798985480999875, 16.50310134900019],
                [-15.806613920999837, 16.50720405600009],
                [-15.81091403999983, 16.508848190000037],
                [-15.815873144999898, 16.509340286000054],
                [-15.820824621999975, 16.509185791000164],
                [-15.822456360999979, 16.508779527000115],
                [-15.825882911999884, 16.507097244000136],
                [-15.833654402999855, 16.505517959000088],
                [-15.835577010999941, 16.504793168000106],
                [-15.84029483699993, 16.502397538000082],
                [-15.842100143999915, 16.501304627000025],
                [-15.846669197999972, 16.497503281000093],
                [-15.85477256799993, 16.49286079400008],
                [-15.857669830999953, 16.49202537499997],
                [-15.859194754999919, 16.49226951600002],
                [-15.85985660599988, 16.495229721999976],
                [-15.859142302999942, 16.49950981300003],
                [-15.858851433999916, 16.50273895200013],
                [-15.859150885999952, 16.505344391000108],
                [-15.860782623999853, 16.50898742700008],
                [-15.862100600999895, 16.510864258000026],
                [-15.865509032999853, 16.51326179500012],
                [-15.868490217999977, 16.514354706],
                [-15.870483398999852, 16.514532089000056],
                [-15.876494407999928, 16.515672685000084],
                [-15.87939739199993, 16.515882492000117],
                [-15.881899833999967, 16.516614914000172],
                [-15.887802122999972, 16.517374040000107],
                [-15.89240837099993, 16.51750564600013],
                [-15.898489951999863, 16.516649246000156],
                [-15.900844573999905, 16.515882492000117],
                [-15.904924392999874, 16.515060424000126],
                [-15.913381576999939, 16.514354706],
                [-15.919850349999933, 16.511995315000092],
                [-15.924387931999945, 16.509361267000145],
                [-15.912545204999901, 16.531240464000064],
                [-15.906894682999905, 16.55288505599998],
                [-15.908127784999976, 16.562063217000116],
                [-15.941552161999937, 16.54106140200014],
                [-15.960826874999896, 16.507873535000158],
                [-15.966951369999947, 16.484926224000105],
                [-15.969230650999975, 16.48518371600011],
                [-15.971798896999928, 16.486309052000138],
                [-15.972505569999953, 16.488351822000027],
                [-15.973704337999948, 16.490459441000098],
                [-15.975763319999942, 16.49251937800011],
                [-15.98048210099995, 16.495605470000157],
                [-15.984025001999953, 16.497461318999967],
                [-15.986175537999827, 16.499195097999973],
                [-15.99205875399997, 16.501714706000087],
                [-15.996363639999913, 16.502927780000107],
                [-16.000057220999963, 16.503250123000043],
                [-16.004608153999925, 16.50302314800018],
                [-16.007953642999894, 16.502231598000037],
                [-16.014371871999913, 16.498725891000163],
                [-16.017753599999935, 16.495189668000023],
                [-16.02544212299989, 16.489477158000057],
                [-16.029546737999965, 16.48534965500005],
                [-16.03556251499998, 16.481607437000036],
                [-16.039365767999982, 16.47978401099999],
                [-16.0452594759999, 16.478488923000157],
                [-16.052087783999923, 16.47832489000018],
                [-16.05448722899996, 16.47898292600007],
                [-16.056722640999965, 16.480529786000147],
                [-16.058456419999914, 16.48378181600009],
                [-16.058906555999954, 16.485504151000043],
                [-16.06036949199995, 16.48862075800008],
                [-16.062101362999954, 16.491027833000032],
                [-16.06638717699991, 16.49589729300004],
                [-16.068370818999824, 16.49794006400009],
                [-16.070096969999895, 16.50018501200003],
                [-16.0733623509999, 16.50495338500008],
                [-16.076011657999913, 16.507808686000033],
                [-16.0807971939999, 16.511482239000088],
                [-16.08539581199983, 16.514308929000038],
                [-16.0895767209999, 16.516071319000048],
                [-16.093185424999945, 16.518630981000058],
                [-16.098979948999954, 16.521989823000183],
                [-16.10019683799993, 16.52350425700007],
                [-16.100769042999957, 16.52559089700003],
                [-16.099311828999873, 16.532432556000117],
                [-16.098318099999915, 16.534740449000026],
                [-16.098175048999963, 16.539121629000135],
                [-16.098722456999894, 16.541730881000035],
                [-16.100112914999897, 16.54591369700006],
                [-16.10231780999993, 16.54962158300009],
                [-16.103931425999917, 16.551357270000096],
                [-16.108970641999974, 16.554824830000086],
                [-16.11325836299983, 16.55621338000003],
                [-16.11926460199993, 16.55654525799997],
                [-16.12525939999989, 16.555526733000022],
                [-16.128423690999966, 16.55466079700011],
                [-16.129793167999935, 16.553993226000046],
                [-16.133546828999954, 16.553207397000108],
                [-16.133993148999934, 16.554365158000053],
                [-16.135107038999877, 16.554979324999977],
                [-16.136827469999957, 16.555000306000068],
                [-16.142017364999845, 16.553993226000046],
                [-16.151653289999956, 16.550514221000014],
                [-16.15399932899993, 16.549249649000046],
                [-16.157661437999877, 16.546640396000043],
                [-16.159288406999963, 16.54575348000003],
                [-16.161823272999982, 16.543718339000122],
                [-16.16359519899987, 16.541078568000103],
                [-16.167741775999957, 16.536050797000087],
                [-16.170497894999926, 16.53301811300014],
                [-16.17322349499989, 16.530668258000105],
                [-16.17593383699989, 16.527814864000106],
                [-16.181427001999907, 16.526538849000133],
                [-16.183538435999935, 16.52549362300016],
                [-16.185035705999894, 16.523853300999974],
                [-16.186672210999973, 16.52295494100008],
                [-16.187980651999965, 16.521289826000043],
                [-16.190603256999964, 16.520067215999973],
                [-16.198207855999897, 16.519897461000085],
                [-16.200901031999877, 16.519407273000184],
                [-16.20386695899998, 16.51849365200019],
                [-16.207643507999876, 16.518032073000086],
                [-16.21078300499994, 16.518163682000022],
                [-16.213832855999954, 16.518592835000106],
                [-16.2179317479999, 16.520093918000043],
                [-16.22389602699991, 16.523111344000142],
                [-16.22783470199994, 16.52537155200008],
                [-16.2295722959999, 16.526603700000067],
                [-16.243873595999958, 16.52998733600009],
                [-16.246400833999928, 16.52985572800003],
                [-16.25076293899997, 16.528549194000107],
                [-16.25481414799998, 16.527536393000105],
                [-16.258222580999927, 16.52638817000019],
                [-16.26015853899986, 16.52651596100003],
                [-16.26710700999996, 16.523715972000105],
                [-16.267688751999856, 16.52318573000008],
                [-16.273092269999836, 16.520351410000046],
                [-16.277818678999836, 16.516542436],
                [-16.278467178999847, 16.516609192000033],
                [-16.28022384699989, 16.515396117000137],
                [-16.28277015599997, 16.51265144400014],
                [-16.28496170099993, 16.509426116999975],
                [-16.286560058999953, 16.5075778960001],
                [-16.287662504999957, 16.504686355000047],
                [-16.288688660999924, 16.499057769000103],
                [-16.288745880999954, 16.49504661600014],
                [-16.28837776099988, 16.49395561299997],
                [-16.288228988999833, 16.491130830000145],
                [-16.2877006519999, 16.488426207999964],
                [-16.287517547999926, 16.483844756000167],
                [-16.287860869999918, 16.482133866000027],
                [-16.28849410899994, 16.476320266000016],
                [-16.289552688999947, 16.47520828200004],
                [-16.298772810999935, 16.47180557200005],
                [-16.301097869999865, 16.470510483999988],
                [-16.304052352999975, 16.467565536000052],
                [-16.30597496099989, 16.46429443300002],
                [-16.307706831999838, 16.46202278100003],
                [-16.308830261999958, 16.459917068000152],
                [-16.30980873099992, 16.457286834000115],
                [-16.31095695399989, 16.44954490700019],
                [-16.31185913099995, 16.445829392000178],
                [-16.312105177999968, 16.43680191200002],
                [-16.312671661999957, 16.431453706000184],
                [-16.31239128099992, 16.42725372400008],
                [-16.312425612999903, 16.421998977999976],
                [-16.31212806699989, 16.419309617000124],
                [-16.312223433999975, 16.41268730200011],
                [-16.313072203999923, 16.410097123000128],
                [-16.31459617699994, 16.407527923000032],
                [-16.318168639999897, 16.40778923000005],
                [-16.321519851999824, 16.40772056600008],
                [-16.32420730499996, 16.40680313100006],
                [-16.326408385999912, 16.405334472000163],
                [-16.326995848999957, 16.405820846999973],
                [-16.33006095899998, 16.403844833000107],
                [-16.331546783999897, 16.40213966500005],
                [-16.333711622999942, 16.39896965100013],
                [-16.3350410459999, 16.39646911699998],
                [-16.336248396999963, 16.391887665000183],
                [-16.33674239999982, 16.389080048000153],
                [-16.336688994999918, 16.38556480400007],
                [-16.33687210099987, 16.3821773520001],
                [-16.336799622999933, 16.37799644400002],
                [-16.336463926999954, 16.373205184000085],
                [-16.33641052199988, 16.367471695000063],
                [-16.334049223999898, 16.358865737000144],
                [-16.33370399499995, 16.354940415999977],
                [-16.334215163999943, 16.35151291000011],
                [-16.336551665999934, 16.346641540000064],
                [-16.33982849199998, 16.342279435000023],
                [-16.342145920999883, 16.339923860000113],
                [-16.342914581999878, 16.338043214000095],
                [-16.345275879999974, 16.33638763400006],
                [-16.346698761999903, 16.33591651900008],
                [-16.348773955999945, 16.336355210000022],
                [-16.350502014999904, 16.335105896000073],
                [-16.352144241999895, 16.335294723000118],
                [-16.354190825999865, 16.33462905900012],
                [-16.356285095999965, 16.3331851960001],
                [-16.359567641999945, 16.330247879000183],
                [-16.362535475999834, 16.326396943000077],
                [-16.36497688299994, 16.3236484520001],
                [-16.366270065999913, 16.321439744000145],
                [-16.36931610099998, 16.31391334600005],
                [-16.370227813999918, 16.310258865000094],
                [-16.37147712799998, 16.303810120000037],
                [-16.37149047799994, 16.301313400000026],
                [-16.371095656999955, 16.297794343000135],
                [-16.36967468299997, 16.293951035000077],
                [-16.367334365999966, 16.289905548000092],
                [-16.36488151599997, 16.2876739510001],
                [-16.36035156199995, 16.28441810600009],
                [-16.357294082999942, 16.278364182000132],
                [-16.356565475999957, 16.27425575300009],
                [-16.356523513999946, 16.270721437000077],
                [-16.358085632999973, 16.26774025000003],
                [-16.359628677999922, 16.265884400000175],
                [-16.361362456999927, 16.264900206999982],
                [-16.36522102399988, 16.262237549000133],
                [-16.36720847999993, 16.260183334999965],
                [-16.369333266999888, 16.256052017000115],
                [-16.37019920299997, 16.254840850000164],
                [-16.372051239999962, 16.250854493000134],
                [-16.37299156099988, 16.24682617200017],
                [-16.373620985999935, 16.245109559000127],
                [-16.37450599699997, 16.24051284900014],
                [-16.374561309999933, 16.238100053000153],
                [-16.375320434999935, 16.235960008000063],
                [-16.377353667999955, 16.232542038000076],
                [-16.38034057599998, 16.22882652400017],
                [-16.382883071999856, 16.22526168900015],
                [-16.385805128999948, 16.222398758000168],
                [-16.38789367699991, 16.21980857900013],
                [-16.390565871999968, 16.21750640800019],
                [-16.393289564999975, 16.215772629000185],
                [-16.396389007999915, 16.2148799900001],
                [-16.401411055999972, 16.21420669600019],
                [-16.404201507999915, 16.21511840900007],
                [-16.405454634999955, 16.216518402000133],
                [-16.408123016999923, 16.21822738700007],
                [-16.40887832599998, 16.218011857000022],
                [-16.4108943949999, 16.21971321200016],
                [-16.412799833999827, 16.220600128],
                [-16.418781279999905, 16.221305847000053],
                [-16.42328834599988, 16.219882965000068],
                [-16.427097320999906, 16.218046189000177],
                [-16.430662155999926, 16.217590332000043],
                [-16.436513899999966, 16.214542388999973],
                [-16.44048309399983, 16.211359025000036],
                [-16.445272445999876, 16.20543098500019],
                [-16.447151183999893, 16.20352935800014],
                [-16.44877052199996, 16.201005935000126],
                [-16.45308113199991, 16.19351959200003],
                [-16.454326629999855, 16.190431595000064],
                [-16.45656585699993, 16.188219071000162],
                [-16.457105637999916, 16.18620300400005],
                [-16.458444594999946, 16.185398100999976],
                [-16.45897102299989, 16.183656692000056],
                [-16.458883285999946, 16.180152893000127],
                [-16.459259033999842, 16.178281785000024],
                [-16.459480285999916, 16.17493248100004],
                [-16.459318160999942, 16.171405793000076],
                [-16.458057402999884, 16.16354370199997],
                [-16.457410811999864, 16.161365510000053],
                [-16.45690155099993, 16.157299042000034],
                [-16.45713996899991, 16.154733657000122],
                [-16.45902061499993, 16.153131484000028],
                [-16.4594497679999, 16.151966095000148],
                [-16.459316253999873, 16.14987182600015],
                [-16.458568573999912, 16.146564484000066],
                [-16.458074569999894, 16.139041901000155],
                [-16.45702934199994, 16.137149811000086],
                [-16.456439971999828, 16.13419342200018],
                [-16.45679473799987, 16.132917405],
                [-16.458332062999887, 16.130590438000127],
                [-16.460329055999978, 16.12468147300018],
                [-16.46134757899989, 16.122779847],
                [-16.460664748999875, 16.118259430000023],
                [-16.45782470699993, 16.11498451200015],
                [-16.45346641499998, 16.111381531000177],
                [-16.449115752999887, 16.109060287000148],
                [-16.446899413999915, 16.10540771500007],
                [-16.447290419999945, 16.103008271000135],
                [-16.448286056999905, 16.100360871000134],
                [-16.451255798999966, 16.098207473000116],
                [-16.452480315999935, 16.098194122000166],
                [-16.45549011199995, 16.097114564000037],
                [-16.45995712399997, 16.0942096710001],
                [-16.46202468799987, 16.093095779000123],
                [-16.464284896999914, 16.089239120000173],
                [-16.46489333999989, 16.085210801000187],
                [-16.46556091399998, 16.082527160999973],
                [-16.470325469999864, 16.08070945800017],
                [-16.47211837899988, 16.079381942999987],
                [-16.479335784999932, 16.06842422500017],
                [-16.483106613999894, 16.06569480900015],
                [-16.483434676999877, 16.06215858400003],
                [-16.49070930499994, 16.062168122000116],
                [-16.49765205299991, 16.06185722500004],
                [-16.49983024499994, 16.061903],
                [-16.50993919399997, 16.061662673000114],
                [-16.50986099199997, 16.05133819700012],
                [-16.510259627999915, 16.048488618000135],
                [-16.510826109999982, 16.03718566900011],
                [-16.511150359999874, 16.02387809800007],
                [-16.511257172999933, 16.009412765000036],
                [-16.51368522699994, 15.991595268000083],
                [-16.513229369999976, 15.989543916],
                [-16.513750076999884, 15.983472824000046],
                [-16.513750076999884, 15.974862098000074],
                [-16.513473510999916, 15.974862098000074],
                [-16.513473510999916, 15.967082978],
                [-16.513750076999884, 15.962510109000107],
                [-16.51601409799997, 15.953458785000123],
                [-16.516139983999892, 15.952295303000142],
                [-16.515766143999883, 15.949316026000076],
                [-16.516357421999942, 15.94656181400012],
                [-16.517091750999953, 15.94076061300018],
                [-16.5172080989999, 15.93729210000015],
                [-16.517498014999944, 15.91791629700009],
                [-16.518194197999946, 15.912915230000067],
                [-16.518749236999952, 15.906250953000153],
                [-16.518749236999952, 15.902359963000094],
                [-16.51930618299997, 15.898471833000087],
                [-16.51930618299997, 15.890892029000156],
                [-16.52194213899992, 15.87655353600013],
                [-16.523574829999916, 15.85952949599999],
                [-16.52347946099985, 15.85707664600011],
                [-16.523878096999965, 15.854362489000152],
                [-16.523403166999913, 15.850973130000114],
                [-16.523750305999954, 15.849028587000078],
                [-16.524864197999932, 15.84581470600017],
                [-16.526443481999934, 15.839164734000065],
                [-16.527261734999968, 15.834898948999978],
                [-16.529144285999962, 15.828160286000127],
                [-16.533834457999887, 15.8095550540001],
                [-16.53640747099996, 15.802135467000028],
                [-16.53905105499996, 15.79582595900007],
                [-16.540061949999938, 15.792504311000016],
                [-16.538749695999968, 15.792225837999979],
                [-16.539304731999948, 15.790694238000071],
                [-16.539581299999952, 15.788193703000047],
                [-16.54125022799991, 15.78541565100005],
                [-16.541803358999857, 15.782917976000078],
                [-16.542917250999892, 15.779861451000045],
                [-16.544027327999913, 15.777916909000112],
                [-16.544307708999952, 15.776248931000111],
                [-16.545417785999973, 15.773470879000115],
                [-16.545417785999973, 15.772083283000029],
                [-16.546527862999937, 15.768751145000067],
                [-16.5462493899999, 15.767915726000126],
                [-16.54458427399993, 15.767359735000014],
                [-16.543750762999935, 15.764617921000024],
                [-16.544860839999956, 15.763471603000028],
                [-16.544860839999956, 15.761807442000077],
                [-16.546527862999937, 15.762084008000045],
                [-16.547082900999953, 15.761528016000057],
                [-16.548194885999976, 15.758193970000036],
                [-16.549028396999915, 15.757362366000166],
                [-16.549028396999915, 15.753471374000128],
                [-16.549304962999884, 15.750970842000186],
                [-16.555973052999946, 15.739582063000114],
                [-16.557638168999972, 15.737915993000115],
                [-16.558195114999933, 15.735693931000128],
                [-16.559583664999934, 15.733471872],
                [-16.559583664999934, 15.731805801000121],
                [-16.5606937419999, 15.73124981],
                [-16.56152725299995, 15.729862213000047],
                [-16.56152725299995, 15.728750228000138],
                [-16.5623607629999, 15.726527214000043],
                [-16.562915800999917, 15.726248741],
                [-16.56347274699982, 15.72402667900002],
                [-16.565141677999975, 15.719861031000164],
                [-16.565971373999957, 15.719306946000074],
                [-16.567363738999973, 15.716251373000091],
                [-16.567363738999973, 15.715416908000066],
                [-16.568750381999962, 15.71263790100005],
                [-16.569303512999966, 15.71014022899999],
                [-16.571250915999883, 15.708472253000025],
                [-16.571250915999883, 15.70708370300008],
                [-16.57569503699989, 15.698471069000107],
                [-16.58013915999993, 15.688751221000075],
                [-16.580692290999934, 15.687082290999967],
                [-16.582639694999955, 15.684027672000127],
                [-16.585138319999828, 15.679583549000029],
                [-16.585416792999865, 15.678471565000052],
                [-16.588472365999905, 15.672361373],
                [-16.590694427999892, 15.66874885599998],
                [-16.59124946599991, 15.66708278599998],
                [-16.593471527999895, 15.663071633000186],
                [-16.599306105999858, 15.652959824000106],
                [-16.60157966499986, 15.649267196000096],
                [-16.604305267999962, 15.644305229000167],
                [-16.610685348999823, 15.633242608000103],
                [-16.61133575499997, 15.6315279],
                [-16.613750457999856, 15.627416612000161],
                [-16.615077972999927, 15.62587738000019],
                [-16.61541748099995, 15.62430572600016],
                [-16.617097854999884, 15.62260723200012],
                [-16.618194580999955, 15.619583130000024],
                [-16.620138167999983, 15.61624908400006],
                [-16.621250151999902, 15.615139007000153],
                [-16.62236022899998, 15.61263942700009],
                [-16.62347221399989, 15.611527443000114],
                [-16.623750686999927, 15.610466003],
                [-16.6250438699999, 15.609740257],
                [-16.625247954999907, 15.608195304000162],
                [-16.62652778699993, 15.605972290000068],
                [-16.62763977099985, 15.604860304999988],
                [-16.627916336999817, 15.60375022800008],
                [-16.629028320999964, 15.602638246000083],
                [-16.629304886999932, 15.601528169000176],
                [-16.63152694599995, 15.598750114000097],
                [-16.631805418999818, 15.59764003700019],
                [-16.634027480999976, 15.59486198400009],
                [-16.635414122999975, 15.592082977000075],
                [-16.637638091999975, 15.589027406000127],
                [-16.637916564999898, 15.58791732900005],
                [-16.639028549999978, 15.58680534400014],
                [-16.639305114999956, 15.585695267000062],
                [-16.64182090799983, 15.582568168000023],
                [-16.642412185999888, 15.580972671000097],
                [-16.64347267099987, 15.579859734000081],
                [-16.644027708999886, 15.578472138000052],
                [-16.646249770999816, 15.575170517000174],
                [-16.647363662999965, 15.574028015000124],
                [-16.6501388559999, 15.569862365000063],
                [-16.650417328999936, 15.568750381000086],
                [-16.651527405999957, 15.567637444000127],
                [-16.65180778399997, 15.56652736699999],
                [-16.652917860999878, 15.565415382000083],
                [-16.65319442699996, 15.564305305000175],
                [-16.654306410999936, 15.563195228000097],
                [-16.656805037999902, 15.559582711000076],
                [-16.657360076999908, 15.558195114000114],
                [-16.65902709999989, 15.556248665000055],
                [-16.661527633999924, 15.551527975999988],
                [-16.662361145999967, 15.550971985000103],
                [-16.665138243999877, 15.547081948000027],
                [-16.66597175499993, 15.54485988600004],
                [-16.667360304999818, 15.54236221400015],
                [-16.669860839999956, 15.539584159000071],
                [-16.670415877999858, 15.538195611000106],
                [-16.674581527999976, 15.532082557000024],
                [-16.675695417999975, 15.530973435000078],
                [-16.677639006999925, 15.52819538000017],
                [-16.677639006999925, 15.527639389000115],
                [-16.679582594999886, 15.5248603820001],
                [-16.680416106999928, 15.524304390000111],
                [-16.68124961899997, 15.522638322000091],
                [-16.684583664999877, 15.517640113000027],
                [-16.689584731999958, 15.51097107000004],
                [-16.691528319999918, 15.508749009000098],
                [-16.691806792999955, 15.50763893200019],
                [-16.694860458999926, 15.502917291000074],
                [-16.696250915999883, 15.501805306000165],
                [-16.696250915999883, 15.500972747000105],
                [-16.703195571999913, 15.491250038999965],
                [-16.705139159999874, 15.489027977000148],
                [-16.705972671999916, 15.487361909000128],
                [-16.708194732999914, 15.485415458000148],
                [-16.708471298999882, 15.484305381000013],
                [-16.710138319999885, 15.482360840000183],
                [-16.711250303999975, 15.479582787000083],
                [-16.714305877999948, 15.476529122000102],
                [-16.715414046999967, 15.474861146000137],
                [-16.715414046999967, 15.473472595999965],
                [-16.717636108999955, 15.471806526000137],
                [-16.719306946999893, 15.469584466000128],
                [-16.72041892899989, 15.46680641100005],
                [-16.721527098999957, 15.465971947000128],
                [-16.72180557199988, 15.464859962000048],
                [-16.723472594999976, 15.462362290000158],
                [-16.724582671999883, 15.461250306000181],
                [-16.72442817699988, 15.459839822000163],
                [-16.73104476899988, 15.450485229000037],
                [-16.755401610999968, 15.41482830000001],
                [-16.76974105799991, 15.392131807000169],
                [-16.771347045999846, 15.390936851000049],
                [-16.771158218999915, 15.389890672000092],
                [-16.77814865199997, 15.378828049000049],
                [-16.779024124999978, 15.377606391000029],
                [-16.817064284999844, 15.31782245700009],
                [-16.818878172999973, 15.313287735000074],
                [-16.82175445499996, 15.3104496],
                [-16.824119567999958, 15.30577373500006],
                [-16.826560974999893, 15.302895546000059],
                [-16.827974318999964, 15.299318313000128],
                [-16.83027458199996, 15.297056199000167],
                [-16.83148765599998, 15.293732643000169],
                [-16.83968162499997, 15.28044796099999],
                [-16.842576980999922, 15.276289941000073],
                [-16.849054336999927, 15.266341210000121],
                [-16.85127449099997, 15.264032364000059],
                [-16.856328964999932, 15.256080627000188],
                [-16.860193252999977, 15.25070381200004],
                [-16.8604927049999, 15.249166488000185],
                [-16.861726760999943, 15.246716500000161],
                [-16.86375045799997, 15.245754243000022],
                [-16.864078520999954, 15.244189262000134],
                [-16.865650176999964, 15.243112564000057],
                [-16.866786956999874, 15.241529465000042],
                [-16.867383957999948, 15.239078522000057],
                [-16.868972777999886, 15.237167358000022],
                [-16.872417448999954, 15.231987952999987],
                [-16.877246857999978, 15.22550868899998],
                [-16.881034849999935, 15.219783783000082],
                [-16.884971619999817, 15.215074540000103],
                [-16.893663406999906, 15.203547478000075],
                [-16.900901793999935, 15.193591118000143],
                [-16.90420722899995, 15.190329553000026],
                [-16.904537200999982, 15.18970203400005],
                [-16.90800094599996, 15.185587883000096],
                [-16.90912437399993, 15.183322907000104],
                [-16.920791625999982, 15.168151856000065],
                [-16.923597334999954, 15.164187431000073],
                [-16.92946624699988, 15.157223702000067],
                [-16.930980682999973, 15.154974939000056],
                [-16.932434080999883, 15.153606415000183],
                [-16.9357395159999, 15.148834228999988],
                [-16.937950134999937, 15.146443367000074],
                [-16.94084358299989, 15.142351150000138],
                [-16.943605421999962, 15.139142037000113],
                [-16.94712448199988, 15.134706497000082],
                [-16.951742170999978, 15.128499031000047],
                [-16.95326423699987, 15.12706279800011],
                [-16.954296112999884, 15.125494957000171],
                [-16.962852478999878, 15.115187645000162],
                [-16.964643477999914, 15.11279964500011],
                [-16.966371536999816, 15.110954286000094],
                [-16.970855711999945, 15.105628967000087],
                [-16.974027632999935, 15.101467132000096],
                [-16.97871780499986, 15.095733643000074],
                [-16.98237800699991, 15.091838838000172],
                [-16.98327255299995, 15.090475082000125],
                [-16.98851966899997, 15.08453273800012],
                [-16.990655898999876, 15.081871033000084],
                [-16.992729186999895, 15.079819680000071],
                [-16.993001936999917, 15.07907199900012],
                [-16.99534797699988, 15.076339722000114],
                [-16.997352600999932, 15.074424744000112],
                [-17.001148223999905, 15.070121765000124],
                [-17.00708007899982, 15.062952995000103],
                [-17.01494979799992, 15.05427646700008],
                [-17.017982482999855, 15.050589562000084],
                [-17.02116203399993, 15.047443390000183],
                [-17.02426338199996, 15.043553353000107],
                [-17.02550887999996, 15.042457581000065],
                [-17.02957534799998, 15.037541389000069],
                [-17.031373977999976, 15.03583145100015],
                [-17.036960601999965, 15.02947807300012],
                [-17.04109191799995, 15.025134087000026],
                [-17.043193816999974, 15.023470879000058],
                [-17.043193816999974, 15.022916793000093],
                [-17.049303054999882, 15.016265869000108],
                [-17.054359436999903, 15.011158943000112],
                [-17.057941436999897, 15.006653786000129],
                [-17.06291007999988, 15.001322746000142],
                [-17.066709517999925, 14.997629166000024],
                [-17.072444914999835, 14.991250992000062],
                [-17.074028015999886, 14.990139007000153],
                [-17.077085494999835, 14.987360002000173],
                [-17.07736205999987, 14.986249925000095],
                [-17.07875060999993, 14.985137940000186],
                [-17.0840282449999, 14.979862213000047],
                [-17.085416794999958, 14.977654457000142],
                [-17.089860915999964, 14.973193168000023],
                [-17.090414046999967, 14.972305299000027],
                [-17.096250534999967, 14.965425492],
                [-17.10375404399997, 14.9569664],
                [-17.10450744699989, 14.955739975000029],
                [-17.10736084099989, 14.953193664000139],
                [-17.108472822999886, 14.95041561100004],
                [-17.11015510599998, 14.948572159000094],
                [-17.11131668099989, 14.94625663800008],
                [-17.11420059099987, 14.941757203000066],
                [-17.116992949999883, 14.935157776000153],
                [-17.11768150399996, 14.93433857000008],
                [-17.11923599199997, 14.929308892000108],
                [-17.119619369999953, 14.925144196000133],
                [-17.12125015199996, 14.921527862000062],
                [-17.121805189999975, 14.921249389000025],
                [-17.122917174999884, 14.919027328000084],
                [-17.125972747999924, 14.915139198000077],
                [-17.127361297999983, 14.912638665000031],
                [-17.128194808999922, 14.910416604000147],
                [-17.128749847999927, 14.910416604000147],
                [-17.13680458099998, 14.906529427000123],
                [-17.143192291999867, 14.90319442800012],
                [-17.143749235999962, 14.90319442800012],
                [-17.147918700999867, 14.900693894000028],
                [-17.14958381699995, 14.90013790200004],
                [-17.15097236699995, 14.898473740000156],
                [-17.15351295599993, 14.896805764000021],
                [-17.156528471999934, 14.895695687000057],
                [-17.16124725299983, 14.893193245000134],
                [-17.163473129999943, 14.89236068800011],
                [-17.16374969499992, 14.89180469500019],
                [-17.166805266999972, 14.890138626000123],
                [-17.169582366999975, 14.88902664200009],
                [-17.169860839999842, 14.888472558000103],
                [-17.17513847399988, 14.885972976000062],
                [-17.175418854999975, 14.88541698500012],
                [-17.18166923499996, 14.882187844000043],
                [-17.185781477999967, 14.880484581000076],
                [-17.19118309099997, 14.877908706000028],
                [-17.19241523699992, 14.877150535000055],
                [-17.198760985999968, 14.874737740000171],
                [-17.202802657999882, 14.871887206000054],
                [-17.205972671999916, 14.870140076999974],
                [-17.212915419999888, 14.867362022000066],
                [-17.217082976999905, 14.865416528000083],
                [-17.218471526999963, 14.864304543000173],
                [-17.22208213899995, 14.8623609550001],
                [-17.222639084999912, 14.8623609550001],
                [-17.2287502289999, 14.859305382000173],
                [-17.2337493899999, 14.856250763000105],
                [-17.23430633499993, 14.856250763000105],
                [-17.247638701999904, 14.849583626000083],
                [-17.249305724999886, 14.848471641],
                [-17.252639770999906, 14.847084046000077],
                [-17.264303206999955, 14.84125042],
                [-17.26791763199992, 14.839304924000146],
                [-17.278749466999898, 14.834304810000162],
                [-17.280136108999955, 14.833748817000071],
                [-17.281806944999914, 14.833748817000071],
                [-17.286249160999944, 14.831526757000063],
                [-17.28874969499998, 14.829860687000064],
                [-17.294307708999952, 14.827084542000136],
                [-17.298194885999976, 14.825971604000074],
                [-17.29875183199988, 14.825415611000153],
                [-17.30125045899996, 14.824863435000111],
                [-17.307361602999947, 14.822637558000054],
                [-17.307918548999908, 14.82208442700005],
                [-17.311805725999932, 14.820972443000073],
                [-17.31402778499995, 14.819306375000053],
                [-17.315694807999932, 14.818750381000086],
                [-17.317361830999914, 14.817361831000085],
                [-17.320138930999917, 14.817361831000085],
                [-17.32208251999998, 14.815695763000122],
                [-17.323472976999938, 14.815972328000157],
                [-17.32458305399996, 14.81486225100008],
                [-17.325695036999946, 14.814583778000042],
                [-17.326805113999967, 14.813470841000026],
                [-17.32847213699995, 14.812917710000022],
                [-17.330972671999973, 14.812917710000022],
                [-17.333429336999927, 14.811000823000143],
                [-17.335695267999938, 14.809861184000056],
                [-17.340139388999944, 14.809861184000056],
                [-17.341806411999926, 14.80791664100019],
                [-17.342916488999947, 14.805973053000173],
                [-17.346525192999934, 14.805973053000173],
                [-17.34847259399993, 14.804861068000093],
                [-17.349306105999972, 14.804861068000093],
                [-17.352083205999975, 14.80347347300011],
                [-17.354305266999972, 14.803195],
                [-17.35791587899996, 14.803195],
                [-17.360137937999923, 14.802083015000164],
                [-17.36152648799998, 14.802083015000164],
                [-17.363193511999896, 14.801247596000053],
                [-17.363193511999896, 14.799029350000183],
                [-17.364028930999837, 14.798472406000087],
                [-17.365140914999927, 14.79986000100007],
                [-17.365972518999968, 14.798472406000087],
                [-17.368194580999955, 14.797637939000083],
                [-17.36986160399988, 14.7962503440001],
                [-17.371528624999883, 14.796527862000175],
                [-17.37263870199996, 14.795415877999972],
                [-17.374307631999955, 14.7962503440001],
                [-17.37597274799998, 14.794584274000101],
                [-17.37819480899998, 14.794028283000046],
                [-17.381250381999905, 14.792083741000056],
                [-17.384582518999935, 14.791250230000117],
                [-17.38569450299991, 14.791250230000117],
                [-17.387636183999916, 14.790140153000038],
                [-17.388471602999914, 14.790140153000038],
                [-17.39236068799994, 14.788193702000171],
                [-17.39319419899988, 14.788193702000171],
                [-17.393749237999884, 14.786806107000189],
                [-17.395971297999893, 14.786249161000114],
                [-17.397083281999983, 14.785417557000073],
                [-17.40319442799995, 14.78291702200005],
                [-17.4062519069999, 14.782361031000107],
                [-17.407638548999955, 14.781526567000185],
                [-17.41236114499992, 14.779861451000045],
                [-17.41347312999983, 14.779026985000144],
                [-17.414583206999907, 14.779026985000144],
                [-17.416250229999832, 14.77763939000016],
                [-17.418750761999945, 14.777083395999966],
                [-17.421527861999948, 14.775417328000174],
                [-17.42374992399988, 14.774582864000024],
                [-17.425971984999876, 14.774304390999987],
                [-17.42680549699992, 14.773472787000117],
                [-17.428194046999977, 14.773472787000117],
                [-17.430973052999946, 14.77208232900017],
                [-17.432638167999983, 14.77208232900017],
                [-17.43347167999991, 14.771249771000043],
                [-17.43569374099991, 14.770972252000092],
                [-17.441249846999938, 14.769293786000105],
                [-17.44285392699993, 14.767900468000107],
                [-17.4445838929999, 14.76790523600016],
                [-17.445140838999976, 14.767350197000155],
                [-17.448749542999963, 14.766796112000065],
                [-17.45069299999983, 14.76623900100003],
                [-17.454308, 14.765683000000138],
                [-17.46097, 14.764017000000138],
                [-17.465693000999977, 14.764296000000115],
                [-17.467081000999883, 14.762907000000155],
                [-17.470970000999955, 14.762906],
                [-17.472359000999973, 14.762630000000115],
                [-17.474580998999954, 14.763183999000034],
                [-17.475414, 14.764018],
                [-17.47735999899993, 14.764297],
                [-17.478274999999883, 14.765270001000147],
                [-17.480692, 14.766796],
                [-17.481805999999835, 14.766519],
                [-17.48402399899993, 14.764018],
                [-17.484858, 14.762075001000142],
                [-17.486248, 14.762075001000142],
                [-17.487636999999836, 14.760963001000107],
                [-17.487912998999946, 14.759296999000128],
                [-17.489023, 14.757908999000051],
                [-17.490135, 14.757908999000051],
                [-17.490970999999888, 14.75874000000016],
                [-17.492635999999834, 14.757909999000105],
                [-17.492912, 14.756797001000052],
                [-17.494024, 14.75651899900015],
                [-17.495132, 14.75548700000013],
                [-17.496653, 14.754990001000124],
                [-17.49819, 14.755407999000056],
                [-17.499578, 14.75679700000012],
                [-17.501522, 14.75679700000012],
                [-17.502359, 14.75513],
                [-17.503746, 14.754297],
                [-17.504858, 14.754297],
                [-17.505965999999887, 14.75513100000012],
                [-17.507912, 14.754853],
                [-17.508747, 14.75346500000012],
                [-17.509855, 14.752909000000159],
                [-17.509853, 14.751123000000177],
                [-17.511524, 14.750409],
                [-17.513634, 14.750498000000164],
                [-17.515042999999878, 14.751392],
                [-17.517632999999876, 14.751520000000141],
                [-17.518744, 14.750964000000124],
                [-17.519299999999873, 14.749298000000124],
                [-17.520689, 14.748465000000124],
                [-17.523911999999825, 14.750132],
                [-17.52596499999987, 14.751797000000124],
                [-17.527075, 14.751798],
                [-17.528465, 14.749855],
                [-17.528744, 14.74791],
                [-17.529575, 14.746243000000163],
                [-17.528814, 14.74565900000016],
                [-17.529856, 14.744574],
                [-17.530687, 14.740409],
                [-17.521242, 14.740409],
                [-17.519299999999873, 14.739300000000185],
                [-17.51791, 14.739021],
                [-17.517357, 14.73763200000019],
                [-17.51569, 14.737631],
                [-17.51429899999988, 14.735689],
                [-17.512631999999883, 14.734577000000172],
                [-17.507912, 14.729577000000177],
                [-17.506521224999915, 14.72735405100019],
                [-17.504299163999917, 14.725966453000126],
                [-17.50402450599995, 14.724020005000057],
                [-17.505134582999972, 14.721797943000013],
                [-17.50402450599995, 14.720687866000105],
                [-17.50402450599995, 14.719021798000085],
                [-17.50180244399985, 14.717632293000179],
                [-17.498189926999885, 14.717355728000086],
                [-17.49763679399996, 14.714576722],
                [-17.494857787999877, 14.714299202000177],
                [-17.493469237999932, 14.715130806000047],
                [-17.49152374199997, 14.715130806000047],
                [-17.490413664999892, 14.71402072900014],
                [-17.489301680999972, 14.710965157999965],
                [-17.48874854999997, 14.707633972000053],
                [-17.487915037999926, 14.706291199000077],
                [-17.48541450599987, 14.706242562000057],
                [-17.48457908699993, 14.703742026999976],
                [-17.483192443999883, 14.702910423000105],
                [-17.481805801999883, 14.702911378000124],
                [-17.480136870999843, 14.702076912000166],
                [-17.47763633699998, 14.699297906000083],
                [-17.475971220999952, 14.699298859000066],
                [-17.474580765999917, 14.698188782000159],
                [-17.473749161999876, 14.6960115440001],
                [-17.475971220999952, 14.693188667000072],
                [-17.477914809999845, 14.693188667000072],
                [-17.477128982999943, 14.691511154000068],
                [-17.474859236999976, 14.69013118700002],
                [-17.474859236999976, 14.688744544000144],
                [-17.470136642999933, 14.685965539000165],
                [-17.469276427999887, 14.683460236000087],
                [-17.469860076999964, 14.679021836],
                [-17.469039915999872, 14.6784648900001],
                [-17.470693588999836, 14.677077292999968],
                [-17.470417022999868, 14.674021722000191],
                [-17.46958351199993, 14.674021722000191],
                [-17.46846961999995, 14.675133706],
                [-17.466156005999892, 14.676245689000098],
                [-17.464860915999964, 14.676245689000098],
                [-17.464282988999912, 14.674574853000024],
                [-17.46262931799987, 14.67490100800012],
                [-17.46212577699987, 14.676684379000164],
                [-17.46208381599996, 14.678463935000138],
                [-17.46013832099993, 14.678463935000138],
                [-17.45875167899993, 14.677575111000124],
                [-17.458749770999873, 14.673466683000186],
                [-17.45680809099997, 14.673465728000053],
                [-17.45513915999993, 14.672910690000094],
                [-17.453899383999897, 14.673743249000154],
                [-17.452362059999928, 14.673743249000154],
                [-17.450973509999926, 14.67235374400002],
                [-17.449115752999887, 14.672354699000039],
                [-17.44791793899998, 14.671584130000156],
                [-17.445981978999953, 14.669123650000131],
                [-17.445974349999915, 14.66791057600011],
                [-17.444349288999945, 14.667354584000122],
                [-17.44307708699995, 14.665041924000093],
                [-17.443197249999912, 14.663187981000078],
                [-17.444028853999896, 14.661796569000046],
                [-17.444307326999933, 14.65985584300006],
                [-17.44347381599988, 14.659578323],
                [-17.442085265999935, 14.65791225400011],
                [-17.43856239299987, 14.656243324000059],
                [-17.436767578999934, 14.654622077999989],
                [-17.435419082999942, 14.652033806000077],
                [-17.435419082999942, 14.648465157000146],
                [-17.435140609999905, 14.646799088000023],
                [-17.432086944999924, 14.646243095000102],
                [-17.431251525999983, 14.648188591000178],
                [-17.429864882999937, 14.65013313300011],
                [-17.43069839499998, 14.651800156000093],
                [-17.430419921999942, 14.655134202000113],
                [-17.43236350999996, 14.655134202000113],
                [-17.43347358699998, 14.656521797000096],
                [-17.43347358699998, 14.659577370000022],
                [-17.431806563999885, 14.65985584300006],
                [-17.43142700099986, 14.664299966000158],
                [-17.430974959999958, 14.665410043000065],
                [-17.42930984499992, 14.66707897200007],
                [-17.427640913999937, 14.667078019000087],
                [-17.426139830999887, 14.668415069000048],
                [-17.423639296999966, 14.669299126000055],
                [-17.42125320399998, 14.669298173000072],
                [-17.42125320399998, 14.672631264000074],
                [-17.420696257999907, 14.675410271999965],
                [-17.42347526599997, 14.675410271999965],
                [-17.425050734999957, 14.675868036],
                [-17.42572402899998, 14.674018859000057],
                [-17.426809309999953, 14.674575805000131],
                [-17.42625236599997, 14.675686837000057],
                [-17.426807404999977, 14.677910804000135],
                [-17.428476332999878, 14.678463935000138],
                [-17.42847442599998, 14.680131913000139],
                [-17.431251525999983, 14.680132866000122],
                [-17.432088851999822, 14.68094921100004],
                [-17.432085036999922, 14.682353020000107],
                [-17.429584502999887, 14.682353974000023],
                [-17.429584502999887, 14.68457698900005],
                [-17.430974959999958, 14.686798096000018],
                [-17.432085036999922, 14.686799050000104],
                [-17.43236350999996, 14.685687066000128],
                [-17.43375205999996, 14.685407638000072],
                [-17.43340110899993, 14.68818569299998],
                [-17.430973052999946, 14.687908173000153],
                [-17.429862975999924, 14.689297674999978],
                [-17.428741454999965, 14.689188005000176],
                [-17.42584037699993, 14.683517457000107],
                [-17.424085616999946, 14.68288612399999],
                [-17.42263984699997, 14.68318557800012],
                [-17.42049026399991, 14.682077409000158],
                [-17.41903114299987, 14.682632446000184],
                [-17.419586180999886, 14.683741570000109],
                [-17.41957092299998, 14.685667992000162],
                [-17.422199248999902, 14.687958717000072],
                [-17.421875000999933, 14.689898491000122],
                [-17.422159194999892, 14.69107914100016],
                [-17.42360496499998, 14.693059921000042],
                [-17.42450904899988, 14.694854735999968],
                [-17.42319679299993, 14.697909356000139],
                [-17.420595168999967, 14.70040798299999],
                [-17.41687202399993, 14.701496124000073],
                [-17.417568206999817, 14.704501152000034],
                [-17.41734695399998, 14.7068452850001],
                [-17.41811180199994, 14.707172395000157],
                [-17.419937132999962, 14.706240654000055],
                [-17.42319679299993, 14.70596504100007],
                [-17.424585342999876, 14.707075117999977],
                [-17.4273624409999, 14.707075117999977],
                [-17.42965507599996, 14.709645271000113],
                [-17.43041801399994, 14.7116031650001],
                [-17.430889128999922, 14.715247154000053],
                [-17.429862975999924, 14.71846389700005],
                [-17.42985343999993, 14.719796182000039],
                [-17.429031371999884, 14.721520425000165],
                [-17.425695419999897, 14.725961686],
                [-17.42278099099991, 14.72847080300005],
                [-17.419862746999968, 14.730415344000107],
                [-17.419027327999913, 14.730415344000107],
                [-17.41798400899995, 14.731527329000187],
                [-17.413522719999946, 14.733470917000034],
                [-17.407360075999918, 14.735139848000017],
                [-17.405876158999888, 14.735972405000098],
                [-17.401037216999896, 14.737361908000025],
                [-17.3983383179999, 14.737915993000115],
                [-17.39125061099992, 14.73896312700009],
                [-17.387636183999916, 14.739027978000024],
                [-17.385406494999813, 14.739583969000137],
                [-17.37597274799998, 14.739583969000137],
                [-17.37597274799998, 14.739850999],
                [-17.370342253999866, 14.739824295000119],
                [-17.368194580999955, 14.739306451000061],
                [-17.36420059099993, 14.739306451000061],
                [-17.360137937999923, 14.73875045799997],
                [-17.359582901999943, 14.738194465999982],
                [-17.35652732899996, 14.737638473000061],
                [-17.352773665999905, 14.737361908000025],
                [-17.346286773999907, 14.73569583900013],
                [-17.344861984999966, 14.734862328000133],
                [-17.342638015999967, 14.734583855000096],
                [-17.341249465999965, 14.733750343000054],
                [-17.33991432299996, 14.734303474000114],
                [-17.335971832999974, 14.73236179300011],
                [-17.332891463999943, 14.731802939999966],
                [-17.32847213699995, 14.729305266999972],
                [-17.326812743999938, 14.729026794000163],
                [-17.32125091599994, 14.726529122000045],
                [-17.31986045799988, 14.725694656000144],
                [-17.318479537999963, 14.725694656000144],
                [-17.315416334999895, 14.724582673000043],
                [-17.313194275999876, 14.722916603000044],
                [-17.31208419899997, 14.722916603000044],
                [-17.308195113999886, 14.721247672000061],
                [-17.307083129999967, 14.719860077000078],
                [-17.305414198999927, 14.719860077000078],
                [-17.30486106799998, 14.719306946000074],
                [-17.301527023999824, 14.718194009000115],
                [-17.29763984699997, 14.717363358],
                [-17.293703079999943, 14.716210365999984],
                [-17.29069328399987, 14.71458435000011],
                [-17.289583206999964, 14.713193895000074],
                [-17.287361144999863, 14.712637901000107],
                [-17.285972594999976, 14.71180629700001],
                [-17.282918929999937, 14.711250306000125],
                [-17.28125572199997, 14.710278511000126],
                [-17.281249998999954, 14.71180629700001],
                [-17.279861448999895, 14.712916374000145],
                [-17.278194427999892, 14.712916374000145],
                [-17.276805877999948, 14.712083818000167],
                [-17.270416258999887, 14.71041584],
                [-17.2687473299999, 14.709584236000126],
                [-17.265693664999958, 14.709028245000013],
                [-17.26041602999993, 14.706250192000141],
                [-17.25930595299991, 14.706250192000141],
                [-17.257520674999967, 14.705417633000081],
                [-17.256250381999962, 14.704304696000122],
                [-17.255418777999978, 14.704304696000122],
                [-17.253749847999927, 14.703194618999987],
                [-17.25250816299996, 14.703039169000078],
                [-17.23402786399987, 14.689583779000031],
                [-17.232360839999956, 14.687640190000081],
                [-17.23069763299992, 14.687359809000043],
                [-17.227361678999955, 14.685137750000138],
                [-17.226249693999875, 14.68402767300006],
                [-17.225139616999968, 14.68402767300006],
                [-17.220138549999945, 14.680424690000109],
                [-17.21763992299998, 14.678193092000186],
                [-17.2165298459999, 14.677918434000048],
                [-17.215139388999944, 14.676559448000035],
                [-17.21187019299998, 14.674027442000124],
                [-17.209861755999896, 14.672082901000124],
                [-17.208194732999914, 14.671526910000182],
                [-17.205139159999874, 14.669583321000061],
                [-17.20319366399991, 14.667638778000025],
                [-17.20263862599984, 14.667638778000025],
                [-17.197082519999924, 14.662360191000175],
                [-17.196659087999876, 14.662360191000175],
                [-17.19430541999992, 14.660086631000127],
                [-17.19430541999992, 14.659584045000145],
                [-17.191804884999954, 14.657361984000033],
                [-17.18880271899991, 14.653878212999984],
                [-17.188472746999878, 14.652084351000099],
                [-17.186807632999944, 14.651248932000158],
                [-17.18541526799993, 14.64979648600007],
                [-17.184028625999872, 14.64652919800011],
                [-17.179582594999886, 14.64319515200009],
                [-17.178749083999946, 14.641804695000076],
                [-17.17764091399988, 14.641335488000038],
                [-17.174583435999978, 14.63708210100009],
                [-17.173471450999955, 14.634584426000117],
                [-17.170415877999915, 14.632990837000023],
                [-17.16569518999995, 14.627838135000161],
                [-17.162858963999952, 14.623165131000064],
                [-17.16180610699996, 14.622138023000161],
                [-17.15889740099982, 14.618098259000021],
                [-17.157638548999955, 14.615139008000085],
                [-17.155977248999875, 14.613472939000133],
                [-17.152706145999957, 14.607359886000154],
                [-17.152639389999933, 14.604583740000123],
                [-17.150979994999943, 14.604583740000123],
                [-17.150417327999946, 14.602640152000106],
                [-17.14907836799989, 14.599675178000098],
                [-17.14958381699995, 14.596804619000181],
                [-17.148595809999847, 14.596804619000181],
                [-17.147361754999963, 14.594305039000119],
                [-17.146806716999947, 14.59072876100015],
                [-17.145414351999932, 14.590416909000112],
                [-17.145139693999965, 14.589304924000032],
                [-17.144027708999886, 14.588473320000162],
                [-17.143917083999952, 14.586246491],
                [-17.14310264599993, 14.584074020000116],
                [-17.141544341999918, 14.581174851000128],
                [-17.140630721999912, 14.580233574000147],
                [-17.140054702999976, 14.578273773000092],
                [-17.138927459999934, 14.576861381000128],
                [-17.13700485199996, 14.572338104000039],
                [-17.13531875599989, 14.570659638000052],
                [-17.133481979999942, 14.567625998999972],
                [-17.131160735999913, 14.565072059000045],
                [-17.126895903999923, 14.56301021500019],
                [-17.125774383999953, 14.562139512000158],
                [-17.12365913399998, 14.559311867000076],
                [-17.122116089999963, 14.557971001000112],
                [-17.11862564099988, 14.556782723000026],
                [-17.115606306999837, 14.553828240000087],
                [-17.11280822799995, 14.55236244300005],
                [-17.111804961999894, 14.551253319000125],
                [-17.11174011299994, 14.550167083000133],
                [-17.109651565999968, 14.549544334000018],
                [-17.107185362999928, 14.546245574000125],
                [-17.104959487999963, 14.540431022000064],
                [-17.10541534499987, 14.539304734000154],
                [-17.10319328299994, 14.535139084000093],
                [-17.100227355999948, 14.53153419500012],
                [-17.097524641999883, 14.525390625000057],
                [-17.09658622799998, 14.521672250000108],
                [-17.095235824999975, 14.518702506000068],
                [-17.094306945999904, 14.514861106000012],
                [-17.092370985999878, 14.50984954900008],
                [-17.09124946599991, 14.504098892000059],
                [-17.090974807999828, 14.501250268000092],
                [-17.090974807999828, 14.498193740000147],
                [-17.089376449999918, 14.49469471100008],
                [-17.08684730499982, 14.492142677000118],
                [-17.08585357599992, 14.489914895000027],
                [-17.083889007999915, 14.488169670000104],
                [-17.08046531799988, 14.486437797000065],
                [-17.07904815699993, 14.48367214200016],
                [-17.07791519099993, 14.479861259000018],
                [-17.076347349999935, 14.47752857200004],
                [-17.07381820699993, 14.474907876000088],
                [-17.07233619699997, 14.472480775000065],
                [-17.071002959999873, 14.471272469000098],
                [-17.070375442999932, 14.472082139000122],
                [-17.06857681299988, 14.472243309000135],
                [-17.066833496999948, 14.471783638000034],
                [-17.064239501999907, 14.46957016100015],
                [-17.062831878999873, 14.46761703499999],
                [-17.060564040999907, 14.463163376000182],
                [-17.058195113999943, 14.457170488000145],
                [-17.058195113999943, 14.455416680000155],
                [-17.055601119999892, 14.452905655000109],
                [-17.05322456399989, 14.451502800000128],
                [-17.052270888999942, 14.45152568800006],
                [-17.0501556399999, 14.450580597000112],
                [-17.048892975999877, 14.449440003000063],
                [-17.03789329599988, 14.448180199000092],
                [-17.03513908399998, 14.447084428000153],
                [-17.03252983199991, 14.447412491000023],
                [-17.03065872199994, 14.448174476000077],
                [-17.027935028999877, 14.446842194000169],
                [-17.025058746999946, 14.444087981999985],
                [-17.023086546999934, 14.441393853000136],
                [-17.020431517999896, 14.439926147000165],
                [-17.017299652999952, 14.439749719000133],
                [-17.01323890799995, 14.436801911000146],
                [-17.01158523499987, 14.437917710000022],
                [-17.009424208999974, 14.43778324200008],
                [-17.006698608999955, 14.436315536000109],
                [-17.00242233299997, 14.432624817000033],
                [-16.999963760999947, 14.429866791000109],
                [-16.997358320999922, 14.426160812000148],
                [-16.99636840699992, 14.424339294000049],
                [-16.994827269999973, 14.423064232000058],
                [-16.992660522999927, 14.421862603000022],
                [-16.990207670999894, 14.419850349000171],
                [-16.98775100599994, 14.417295456999966],
                [-16.986000060999856, 14.415819168000155],
                [-16.98390388499996, 14.414752007000061],
                [-16.981679915999962, 14.414771081000026],
                [-16.979856490999907, 14.413159371000063],
                [-16.97760581999995, 14.410128593000138],
                [-16.97515487599992, 14.408319474000109],
                [-16.97041511599997, 14.406867981000175],
                [-16.969085693999943, 14.406064988000082],
                [-16.965997695999874, 14.402837754000018],
                [-16.964380263999885, 14.400885583000047],
                [-16.963533400999893, 14.399266244000103],
                [-16.960865020999904, 14.396373749000134],
                [-16.959804533999943, 14.394349099000124],
                [-16.958662032999882, 14.391037941000093],
                [-16.957670210999822, 14.388944626000182],
                [-16.955837249999945, 14.385910034000062],
                [-16.95443153399998, 14.384294510000188],
                [-16.952201842999898, 14.383703233],
                [-16.95045280499994, 14.382430078000084],
                [-16.947139739999898, 14.377305985000078],
                [-16.94565582299998, 14.37460708600014],
                [-16.945085525999957, 14.372781753000027],
                [-16.944698333999952, 14.369859696000105],
                [-16.94413757199993, 14.367706298000087],
                [-16.94402694699994, 14.365694046000044],
                [-16.941032408999945, 14.362377166000044],
                [-16.93760108899994, 14.359694480000087],
                [-16.936527252999895, 14.358029365000107],
                [-16.933853149999948, 14.352336883000191],
                [-16.932636260999914, 14.348243713999977],
                [-16.932361602999947, 14.34597301500014],
                [-16.93152809099996, 14.343074798999965],
                [-16.93095207199991, 14.338330268999982],
                [-16.930702209999936, 14.333384515000034],
                [-16.93080902, 14.329587937000156],
                [-16.93152809099996, 14.324726104000149],
                [-16.932083129999967, 14.323748588000115],
                [-16.932361602999947, 14.320694923000133],
                [-16.932361602999947, 14.316408157000183],
                [-16.932916640999906, 14.31375026700016],
                [-16.932916640999906, 14.308194162000063],
                [-16.932361602999947, 14.306528091000189],
                [-16.932361602999947, 14.301527024000166],
                [-16.93180656399994, 14.2989292150001],
                [-16.931249617999924, 14.291251183000043],
                [-16.930112837999957, 14.288926124000113],
                [-16.928970336999896, 14.285207750000097],
                [-16.92674827499991, 14.285564424000142],
                [-16.921119690999944, 14.285541535000107],
                [-16.919233322999844, 14.284472465000135],
                [-16.916589735999935, 14.284154893000164],
                [-16.91302299399996, 14.281472207000036],
                [-16.90930366499998, 14.27729988200008],
                [-16.906284332999917, 14.273596763000171],
                [-16.903192520999824, 14.268907548000072],
                [-16.90050697299995, 14.26422119100016],
                [-16.897132873999908, 14.259841919],
                [-16.891181945999847, 14.25453472200013],
                [-16.888935087999926, 14.251806260000137],
                [-16.884647369999982, 14.24584293300012],
                [-16.878324507999878, 14.237623215000156],
                [-16.87712287899984, 14.235192299000119],
                [-16.875562668999976, 14.231205940000052],
                [-16.87190246499989, 14.220577241],
                [-16.87076759399997, 14.215497971000048],
                [-16.87082862899996, 14.21458816600017],
                [-16.86958312899992, 14.211546898000051],
                [-16.869682310999963, 14.209155083000155],
                [-16.86874961899997, 14.202083587000175],
                [-16.86818695099987, 14.196288108000033],
                [-16.86708259599982, 14.190076827000098],
                [-16.86708259599982, 14.185973166999986],
                [-16.86663246099988, 14.184506416000033],
                [-16.8629436499999, 14.183721542000114],
                [-16.859939573999952, 14.181575776000045],
                [-16.858125686999927, 14.180776595999987],
                [-16.85595512499998, 14.178759574000082],
                [-16.85237121499995, 14.173568726000099],
                [-16.851802825999982, 14.172149659000013],
                [-16.84905052199997, 14.166748046000123],
                [-16.84569358899995, 14.16292190500019],
                [-16.844360351999967, 14.162242890000073],
                [-16.840293883999948, 14.1576642980001],
                [-16.838539123999965, 14.155441284],
                [-16.83224296599991, 14.14999866400018],
                [-16.828323364999903, 14.146300316000065],
                [-16.826005935999888, 14.143132210000147],
                [-16.825696944999947, 14.14458370300008],
                [-16.826374053999928, 14.145637512000121],
                [-16.82526969999992, 14.146662713000126],
                [-16.824209212999847, 14.14436626500003],
                [-16.820245741999884, 14.143854141000077],
                [-16.818845748999877, 14.142305374000102],
                [-16.821056364999833, 14.140797615000167],
                [-16.82167053099994, 14.139505387000156],
                [-16.82172584599988, 14.137809753],
                [-16.820884703999923, 14.13673210200011],
                [-16.818443297999977, 14.13539505000017],
                [-16.816352843999937, 14.134732246000056],
                [-16.81406021199996, 14.134546280000109],
                [-16.809696196999937, 14.136002541000039],
                [-16.80810546799995, 14.13703155600001],
                [-16.806993483999975, 14.136972428000092],
                [-16.806528090999905, 14.13541698500012],
                [-16.80513954099996, 14.13458347400018],
                [-16.80327987699991, 14.132729530000063],
                [-16.802083967999977, 14.1320829390001],
                [-16.802421568999932, 14.129549981000139],
                [-16.801713942999925, 14.127996445000065],
                [-16.79771614099991, 14.123010637000164],
                [-16.795692443999883, 14.11939811700006],
                [-16.794824599999913, 14.117405892000136],
                [-16.791805266999972, 14.112191200000154],
                [-16.78958320499987, 14.10740947800008],
                [-16.78958320499987, 14.105973244000097],
                [-16.788473129999943, 14.104861259000018],
                [-16.788194656999906, 14.103472709000073],
                [-16.78708267299993, 14.102083206000145],
                [-16.78569412299987, 14.098685265000086],
                [-16.78569412299987, 14.097361564999972],
                [-16.78458595299992, 14.094584465000082],
                [-16.784025191999888, 14.093935012000088],
                [-16.784025191999888, 14.09208393],
                [-16.78347206099994, 14.091527939000116],
                [-16.78180313099989, 14.085617065000065],
                [-16.78152847299998, 14.083194733000028],
                [-16.780138014999977, 14.07874870300003],
                [-16.780138014999977, 14.077082635000068],
                [-16.77958106899996, 14.075695037000173],
                [-16.77958106899996, 14.071249961000092],
                [-16.7784729, 14.069581986000173],
                [-16.7784729, 14.067915917000107],
                [-16.777082443999916, 14.065416336000112],
                [-16.777082443999916, 14.063750267000046],
                [-16.77541732799989, 14.060695649000081],
                [-16.77541732799989, 14.058749198000044],
                [-16.77486038199993, 14.055971145000115],
                [-16.774305343999913, 14.05541706200006],
                [-16.77376747099993, 14.05303001500016],
                [-16.77399444699995, 14.050712585999975],
                [-16.77347183199987, 14.042649269000094],
                [-16.77347183199987, 14.039028169000176],
                [-16.77263832099993, 14.03492259900014],
                [-16.77263832099993, 14.031249047000074],
                [-16.771900175999974, 14.029026985000087],
                [-16.770696639999926, 14.01970005100003],
                [-16.770696639999926, 14.017915727],
                [-16.76986122099993, 14.01291561100004],
                [-16.76986122099993, 14.01013755800011],
                [-16.769182203999947, 14.007966996000107],
                [-16.7681961049999, 13.998748779000039],
                [-16.768192289999945, 13.993194581000068],
                [-16.767639158999827, 13.986805917000083],
                [-16.767915724999966, 13.985137939000083],
                [-16.767082214999846, 13.982084275000034],
                [-16.767082214999846, 13.978749275000155],
                [-16.766805647999945, 13.977913857000146],
                [-16.766805647999945, 13.97097301500014],
                [-16.766527176999944, 13.97097301500014],
                [-16.766527176999944, 13.965138436000132],
                [-16.765970230999926, 13.960694313000033],
                [-16.765970230999926, 13.95569419899999],
                [-16.76541709999998, 13.953152657000032],
                [-16.76541709999998, 13.949304582000082],
                [-16.764860153999905, 13.947162629000047],
                [-16.764583587999937, 13.944216729000175],
                [-16.764583587999937, 13.939304350999976],
                [-16.76374816899994, 13.935416220999969],
                [-16.763195037999935, 13.930972100000076],
                [-16.76206588799994, 13.929743768000037],
                [-16.761968612999965, 13.928867340000181],
                [-16.75632286099983, 13.92847442599998],
                [-16.75447845499997, 13.926826476000087],
                [-16.7534084319999, 13.926626207000083],
                [-16.75287628199993, 13.918079376000037],
                [-16.75269317599998, 13.911478042000056],
                [-16.752346039999964, 13.903734207000127],
                [-16.752077101999873, 13.889298440000061],
                [-16.752077101999873, 13.862373351000087],
                [-16.752298354999823, 13.834455490000039],
                [-16.752915999999857, 13.833195],
                [-16.754028, 13.833195],
                [-16.755138, 13.834307],
                [-16.755138, 13.837917000000118],
                [-16.75458099999986, 13.838195],
                [-16.754305, 13.842361],
                [-16.755693, 13.845693],
                [-16.755693, 13.847362000000146],
                [-16.756804, 13.849584000000164],
                [-16.757086, 13.851528],
                [-16.756804, 13.855972],
                [-16.756804, 13.860973000000115],
                [-16.755693, 13.863195000000132],
                [-16.754863999999827, 13.863751],
                [-16.754863999999827, 13.86597300000011],
                [-16.755417, 13.868195000000128],
                [-16.75625, 13.868749],
                [-16.755974, 13.870974],
                [-16.755417, 13.871527000000128],
                [-16.755138, 13.874584],
                [-16.75458099999986, 13.875140000000101],
                [-16.75458099999986, 13.876806000000101],
                [-16.755138, 13.88014],
                [-16.755138, 13.882916],
                [-16.755974, 13.884307],
                [-16.755417, 13.885695000000169],
                [-16.755974, 13.886805000000152],
                [-16.755974, 13.88902700000017],
                [-16.757086, 13.89124900000013],
                [-16.758472, 13.890417],
                [-16.759027, 13.88791700000013],
                [-16.759027, 13.882362],
                [-16.759583, 13.877362],
                [-16.760139, 13.875416],
                [-16.759583, 13.87375],
                [-16.760418, 13.873472000000106],
                [-16.760694999999828, 13.871249],
                [-16.760694999999828, 13.866527000000133],
                [-16.76125, 13.865695],
                [-16.76125, 13.864029],
                [-16.760694999999828, 13.863473],
                [-16.760694999999828, 13.85625000000016],
                [-16.760418, 13.853194],
                [-16.759583, 13.851806000000124],
                [-16.759308, 13.84764],
                [-16.759308, 13.842083000000173],
                [-16.759027, 13.842083000000173],
                [-16.758751, 13.8373610000001],
                [-16.758751, 13.827084000000127],
                [-16.759027, 13.827084000000127],
                [-16.759027, 13.819306000000154],
                [-16.758751, 13.819306000000154],
                [-16.758751, 13.809583],
                [-16.757917, 13.808195],
                [-16.757639, 13.806251],
                [-16.756527, 13.804029000000185],
                [-16.754863999999827, 13.804307],
                [-16.75375, 13.805695000000185],
                [-16.754028, 13.809583],
                [-16.75264, 13.810973],
                [-16.75264, 13.813471000000163],
                [-16.75124899999986, 13.814305],
                [-16.75041599999986, 13.816038000000106],
                [-16.745851515999846, 13.809444428000063],
                [-16.74030876099988, 13.809066772999984],
                [-16.717243194999867, 13.802148819000138],
                [-16.701803206999898, 13.79423999800008],
                [-16.700971602999914, 13.793195724000157],
                [-16.698471069999982, 13.787361146000023],
                [-16.697359085999892, 13.786249161000171],
                [-16.697359085999892, 13.785416602999987],
                [-16.695972442999846, 13.784029007000129],
                [-16.695417404999944, 13.782082559],
                [-16.69347190899998, 13.779582977000132],
                [-16.692083358999923, 13.778751373000091],
                [-16.68874931299996, 13.778751373000091],
                [-16.685970306999934, 13.779028893000145],
                [-16.684028625999872, 13.778195381000103],
                [-16.68263816899986, 13.77930545800018],
                [-16.681818007999937, 13.780694961000108],
                [-16.679533004999882, 13.778015138000171],
                [-16.655782699999975, 13.754352569000162],
                [-16.6495571129999, 13.7520856860001],
                [-16.649172, 13.751438000000121],
                [-16.6409, 13.739983],
                [-16.637949, 13.732746],
                [-16.633663, 13.726805000000184],
                [-16.634303999999872, 13.724029],
                [-16.635693, 13.720694],
                [-16.63625, 13.720138000000134],
                [-16.637638, 13.717084],
                [-16.637638, 13.71375],
                [-16.63875, 13.711528],
                [-16.63875, 13.706527000000165],
                [-16.638197, 13.705417000000125],
                [-16.637917, 13.701805],
                [-16.637272, 13.699989],
                [-16.637717999999893, 13.695535000000177],
                [-16.63783999999987, 13.691528],
                [-16.63875, 13.690972000000102],
                [-16.639305, 13.68514],
                [-16.63875, 13.683749],
                [-16.637638, 13.682918],
                [-16.635975, 13.682918],
                [-16.635414, 13.68358],
                [-16.630014, 13.671076],
                [-16.625143, 13.664617000000135],
                [-16.619019, 13.656925000000172],
                [-16.613194, 13.650953],
                [-16.611768999999867, 13.648993],
                [-16.61002, 13.648578000000157],
                [-16.606152999999892, 13.645654],
                [-16.603512, 13.644586],
                [-16.59907, 13.64393600000011],
                [-16.597398999999882, 13.642862],
                [-16.593054, 13.640891],
                [-16.592081, 13.642035],
                [-16.585478, 13.632707],
                [-16.585401999999874, 13.63155],
                [-16.58276, 13.630618],
                [-16.583067, 13.629701],
                [-16.58465, 13.6282],
                [-16.585226, 13.626128000000165],
                [-16.58452199999988, 13.62450500000017],
                [-16.580025, 13.620619],
                [-16.578384, 13.618714000000125],
                [-16.57069799999988, 13.614729],
                [-16.567385, 13.611462000000131],
                [-16.56586099999987, 13.609258],
                [-16.56241199999988, 13.602379],
                [-16.561328999999887, 13.598731000000157],
                [-16.559659999999838, 13.596053],
                [-16.555782, 13.596755],
                [-16.553959, 13.598699000000181],
                [-16.550442, 13.6013650000001],
                [-16.546132999999884, 13.599697000000162],
                [-16.54667499999988, 13.597795000000133],
                [-16.54565, 13.594785000000172],
                [-16.545209999999884, 13.592391000000134],
                [-16.51429939299993, 13.592357636000031],
                [-16.509092330999977, 13.591835021000065],
                [-16.497304999999812, 13.590056],
                [-16.489204, 13.592328],
                [-16.44875144899987, 13.59227562000018],
                [-16.402677534999953, 13.592206955999984],
                [-16.34499168399998, 13.592108727000152],
                [-16.287561415999846, 13.59271812500009],
                [-16.2538299549999, 13.59277057700018],
                [-16.194976806999875, 13.592850686000133],
                [-16.158634185999915, 13.591700555000102],
                [-16.116392134999955, 13.591588020000131],
                [-16.085390091999955, 13.591502191000018],
                [-16.027696608999975, 13.591330528000128],
                [-15.999102592999975, 13.591690064000034],
                [-15.940681457999972, 13.591622354000151],
                [-15.882258415999956, 13.59154129000018],
                [-15.823835372999952, 13.591447831000153],
                [-15.765411377999897, 13.591339111000025],
                [-15.736230850999902, 13.591278075000162],
                [-15.677804947999903, 13.59115028400015],
                [-15.648592948999976, 13.591079711000077],
                [-15.590167045999976, 13.590929986000049],
                [-15.53174209599996, 13.590768814],
                [-15.50252914399988, 13.59068393699999],
                [-15.47307682, 13.590885162000063],
                [-15.471787451999944, 13.600562095000043],
                [-15.470011710999927, 13.609319687999971],
                [-15.468426703999967, 13.61646556800008],
                [-15.467945098999905, 13.620933532000095],
                [-15.46625995699992, 13.629227638000145],
                [-15.465114593999942, 13.63402271300015],
                [-15.464152335999927, 13.638762475000078],
                [-15.462665558999902, 13.644804956000087],
                [-15.461750983999877, 13.649869918000093],
                [-15.45917892499989, 13.65848350500005],
                [-15.456784247999906, 13.66820812300017],
                [-15.452251433999947, 13.681933403000187],
                [-15.448486326999898, 13.690774918000102],
                [-15.447723388999918, 13.693126678],
                [-15.445539473999872, 13.697308541000098],
                [-15.441541671999971, 13.705507278000141],
                [-15.438260077999928, 13.711445809000054],
                [-15.433224676999941, 13.719357492000029],
                [-15.426857948999952, 13.728193283000053],
                [-15.419224738999958, 13.7370224],
                [-15.414619444999857, 13.741511345000106],
                [-15.40404415199987, 13.740077973000155],
                [-15.40405655, 13.747358322000082],
                [-15.400797844999943, 13.748929025000109],
                [-15.398661612999945, 13.749608040000055],
                [-15.397429466999881, 13.750986099000158],
                [-15.39797687499987, 13.753689766000093],
                [-15.39723777799992, 13.754826546],
                [-15.394921301999943, 13.757292747000065],
                [-15.392484664999927, 13.75921154000008],
                [-15.385757444999854, 13.764078140000038],
                [-15.381830215999969, 13.766371727000035],
                [-15.377799986999946, 13.768249513000171],
                [-15.370182037999882, 13.771407128000021],
                [-15.36601352699995, 13.773556709000161],
                [-15.3580074299999, 13.776995660000011],
                [-15.353891371999964, 13.778456689000052],
                [-15.350008009999897, 13.779592514000115],
                [-15.345893859999933, 13.781245232000117],
                [-15.338745116999974, 13.783199311000033],
                [-15.333538055999895, 13.784381866000103],
                [-15.320199965999961, 13.785819054000171],
                [-15.311836243999949, 13.785884857000042],
                [-15.302944181999976, 13.785063743000137],
                [-15.29988098099983, 13.784271241000113],
                [-15.297938346999956, 13.784129142000154],
                [-15.289202690999957, 13.782530785000176],
                [-15.282113075999973, 13.78057575300005],
                [-15.278244971999868, 13.779358864000187],
                [-15.271913527999914, 13.776798249000137],
                [-15.268284797999968, 13.774804115000165],
                [-15.261443137999947, 13.770217895000144],
                [-15.257675170999846, 13.767120362000071],
                [-15.253999710999949, 13.76443004600003],
                [-15.248249053999928, 13.758965493000062],
                [-15.245331764999946, 13.755920410000101],
                [-15.239303589999963, 13.750320435000106],
                [-15.23929977499995, 13.749547003999965],
                [-15.225618360999817, 13.749332429000106],
                [-15.215156555999897, 13.749736786000085],
                [-15.215049743999941, 13.750370025000166],
                [-15.185851097999887, 13.767682076000028],
                [-15.152855873999954, 13.78756236999999],
                [-15.121603964999963, 13.806622506000167],
                [-15.115026473999933, 13.808940887000063],
                [-15.06211471499995, 13.826889991000144],
                [-15.03292846699992, 13.816986084],
                [-15.016253470999914, 13.811056137000094],
                [-14.998460768999905, 13.804383279000092],
                [-14.998933791999946, 13.803668975000107],
                [-14.994648932999894, 13.801991463999968],
                [-14.991775512999936, 13.801671982000187],
                [-14.989088057999936, 13.80047607500012],
                [-14.983244895999917, 13.79877567300008],
                [-14.98084068299994, 13.798223495000059],
                [-14.978108405999933, 13.797258377000162],
                [-14.976457595999932, 13.796154023000042],
                [-14.973441123999919, 13.795832633000146],
                [-14.97052001999998, 13.795097351000038],
                [-14.967315674999895, 13.79551220000019],
                [-14.964725493999822, 13.796296120000022],
                [-14.959117887999923, 13.797585488000095],
                [-14.956573485999968, 13.798507691000111],
                [-14.951061247999974, 13.79989051800004],
                [-14.94818687399993, 13.800810814000158],
                [-14.943380355999864, 13.80067444000008],
                [-14.937396049999961, 13.80067634500017],
                [-14.935227393999924, 13.800124169000128],
                [-14.93244743299988, 13.800399780000077],
                [-14.929148673999975, 13.800216676000048],
                [-14.920853614999942, 13.799112319000074],
                [-14.918401717999927, 13.798560143000032],
                [-14.910436629999879, 13.79778003700011],
                [-14.906780242999957, 13.797173501000032],
                [-14.903905867999981, 13.796988487000021],
                [-14.901596069999925, 13.796159744000079],
                [-14.897730826999975, 13.795377732000077],
                [-14.896787642999982, 13.794411659000161],
                [-14.893300055999816, 13.79372024600002],
                [-14.886511802999905, 13.791604042000074],
                [-14.882787703999895, 13.790958405000083],
                [-14.87780284799993, 13.788664816999983],
                [-14.874315261999925, 13.788020134000021],
                [-14.872053145999814, 13.787144661000184],
                [-14.869506835999971, 13.78553295100005],
                [-14.86040973799993, 13.782032966000088],
                [-14.856214523999824, 13.779683113999965],
                [-14.846926688999872, 13.775538445000166],
                [-14.84456920599996, 13.773695946000032],
                [-14.841034889999833, 13.772084235000023],
                [-14.837074278999864, 13.769688606000102],
                [-14.835619220999888, 13.768059735000065],
                [-14.83298683299995, 13.76701927300013],
                [-14.830912589999969, 13.764945983000189],
                [-14.828791757999966, 13.764023841000096],
                [-14.826434, 13.761722001000066],
                [-14.821202277999873, 13.757668495000075],
                [-14.818750372999943, 13.755963290000125],
                [-14.816886900999862, 13.746740342000123],
                [-14.813953, 13.742719999000087],
                [-14.806917190999968, 13.741823197000144],
                [-14.805173873999877, 13.740119935000052],
                [-14.804468000999918, 13.737733],
                [-14.802344288999905, 13.73556034],
                [-14.801402092999922, 13.733349800000099],
                [-14.80457094299993, 13.724051164000059],
                [-14.802664756999945, 13.721179010000071],
                [-14.794236181999963, 13.717092513000125],
                [-14.793341636999912, 13.71492862700012],
                [-14.792257000999882, 13.709494001000053],
                [-14.790370962999816, 13.704243740000038],
                [-14.790183, 13.702081],
                [-14.789381026999877, 13.700191499000027],
                [-14.788061141999833, 13.69448089600013],
                [-14.787637709999956, 13.691120148999971],
                [-14.787167547999957, 13.682923317000188],
                [-14.786742216999926, 13.67992977300014],
                [-14.793601910999882, 13.6740880750001],
                [-14.79243278499996, 13.668059347999986],
                [-14.784567907999815, 13.667205819000174],
                [-14.784291267999947, 13.664411545000064],
                [-14.783443450999926, 13.66247653900001],
                [-14.782925606999981, 13.656443596000088],
                [-14.782124518999979, 13.65377330900003],
                [-14.780427998999926, 13.651931000000104],
                [-14.77524471199996, 13.64994716700005],
                [-14.770815000999903, 13.647365999000158],
                [-14.76831722199995, 13.646258354000054],
                [-14.766055999999878, 13.644462000000146],
                [-14.762616000999913, 13.64326099900012],
                [-14.760873, 13.641925000000185],
                [-14.75856499899993, 13.640634000000148],
                [-14.756114005999905, 13.63989448600006],
                [-14.754181861999939, 13.637681962000102],
                [-14.751778601999945, 13.636299133000023],
                [-14.744005202999972, 13.632747651000159],
                [-14.741742134999981, 13.630672456000127],
                [-14.73670005799994, 13.62836647100005],
                [-14.734392, 13.626983001000156],
                [-14.731705666999972, 13.625922204],
                [-14.729727743999945, 13.624445915000024],
                [-14.726901053999825, 13.623430252000048],
                [-14.724403381999934, 13.62167740000018],
                [-14.722565650999911, 13.62089443300016],
                [-14.719598, 13.618911],
                [-14.715357907999874, 13.616788938000184],
                [-14.71417903799994, 13.615774155000167],
                [-14.710739998999884, 13.614712000999987],
                [-14.709043501999929, 13.614479065000126],
                [-14.703576999999882, 13.614428],
                [-14.699666022999963, 13.61529922599999],
                [-14.697732924999968, 13.617322923000017],
                [-14.6961765289999, 13.61962413800012],
                [-14.693442343999891, 13.621462822000183],
                [-14.689011573999835, 13.624866486000087],
                [-14.684251, 13.627946001000055],
                [-14.68137599999983, 13.62909500100011],
                [-14.677180288999864, 13.631945611000049],
                [-14.6722774499999, 13.634471894000058],
                [-14.67001342799989, 13.635805129000175],
                [-14.667844771999967, 13.637690545000055],
                [-14.665534018999892, 13.638748170000042],
                [-14.66275215099995, 13.640586853000059],
                [-14.660961151999913, 13.642151832000138],
                [-14.658650397999963, 13.643485069],
                [-14.652286529999913, 13.645916938000084],
                [-14.649269103999927, 13.646882058000187],
                [-14.646722793999913, 13.64872074200008],
                [-14.640545844999963, 13.651659964999965],
                [-14.637717246999955, 13.652530669999976],
                [-14.635123252999904, 13.652988434000065],
                [-14.629983902999868, 13.65459442200006],
                [-14.62621212099998, 13.65560340900015],
                [-14.622723, 13.655737000000101],
                [-14.617631911999979, 13.656329156000027],
                [-14.614377975999957, 13.657062530000132],
                [-14.61159801399998, 13.656596184000136],
                [-14.608863830999951, 13.65677738200003],
                [-14.60363006499989, 13.656493186000148],
                [-14.602734565999981, 13.656125069000154],
                [-14.59905624399994, 13.6561203010001],
                [-14.596509933999982, 13.655563355000027],
                [-14.591654776999917, 13.655787467999971],
                [-14.585903166999913, 13.655086518000076],
                [-14.580952644999911, 13.654941559000065],
                [-14.577888488999974, 13.654338838000058],
                [-14.574871062999875, 13.654195786000116],
                [-14.569261549999908, 13.65299034200001],
                [-14.5664329519999, 13.652755738000167],
                [-14.56072807299995, 13.651641846000189],
                [-14.557616233999966, 13.651776315],
                [-14.553562163999914, 13.65034103500011],
                [-14.552572249999969, 13.650293351000073],
                [-14.547622680999893, 13.648718834000078],
                [-14.545549392999874, 13.647378922000087],
                [-14.540600776999952, 13.644746781000151],
                [-14.537915230999886, 13.643545152000115],
                [-14.53536891899995, 13.64275646200008],
                [-14.533342361999871, 13.641786575000026],
                [-14.530421256999944, 13.63984584900004],
                [-14.527216911999972, 13.638275147000058],
                [-14.526226997999913, 13.637212753000085],
                [-14.521420477999982, 13.634441375000108],
                [-14.518119810999963, 13.633237839],
                [-14.51364517099995, 13.630051612000102],
                [-14.511145591999878, 13.62857341800003],
                [-14.506057737999924, 13.625247002000094],
                [-14.501064300999928, 13.621092797000017],
                [-14.499980925999921, 13.619524002000162],
                [-14.49762630499987, 13.617815971000141],
                [-14.496166229999972, 13.616292955000063],
                [-14.495508193999967, 13.614311219000058],
                [-14.492963791999841, 13.611910820000162],
                [-14.491835593999838, 13.61011219],
                [-14.487596512999914, 13.605405807000068],
                [-14.486279488999912, 13.602777482000135],
                [-14.485574722999957, 13.600748062000036],
                [-14.482892036999942, 13.596367836000184],
                [-14.482233046999966, 13.59576606800016],
                [-14.48039817799986, 13.592814445000101],
                [-14.477387426999883, 13.586313248000124],
                [-14.476163864999876, 13.584607125000105],
                [-14.474945067999954, 13.579490663000058],
                [-14.474003790999973, 13.57732486700013],
                [-14.472126007999918, 13.57151699200017],
                [-14.472082137999962, 13.568843842000149],
                [-14.471708297999896, 13.566124916000035],
                [-14.4702997199999, 13.561468126000022],
                [-14.469643591999954, 13.557966232000012],
                [-14.469038008999973, 13.552943229000164],
                [-14.468852041999924, 13.549994468000023],
                [-14.468811987999914, 13.544696808000083],
                [-14.468438147999962, 13.542162896000036],
                [-14.468582153999876, 13.54055023300009],
                [-14.461421013999882, 13.536343574000114],
                [-14.458406447999948, 13.53481769700005],
                [-14.45628738399995, 13.533245086000079],
                [-14.454120636999903, 13.531213761000117],
                [-14.449598311999978, 13.52848625300004],
                [-14.44357108999992, 13.523543358999973],
                [-14.44192409499982, 13.521143914000163],
                [-14.439758300999927, 13.519665719000159],
                [-14.437686918999873, 13.517402649000132],
                [-14.435708998999928, 13.515924454000128],
                [-14.43203830699997, 13.511171341000022],
                [-14.42997169499995, 13.506236077000096],
                [-14.427290916999937, 13.501670838999985],
                [-14.424699782999937, 13.500281334000022],
                [-14.415792465999857, 13.498002052000118],
                [-14.412446975999899, 13.49698066700006],
                [-14.410327911999957, 13.495409013000028],
                [-14.406277656999976, 13.493049622000171],
                [-14.401896476999923, 13.491289140000163],
                [-14.39690113099988, 13.489987374000066],
                [-14.394498824999971, 13.488828659000035],
                [-14.389365194999925, 13.485960007000187],
                [-14.384560584999974, 13.482860565000067],
                [-14.38178157699997, 13.481288911000036],
                [-14.380039214999954, 13.479900361000091],
                [-14.37735462199987, 13.478420257000039],
                [-14.37575340199993, 13.477218628000173],
                [-14.373166083999877, 13.473939897000037],
                [-14.371659277999981, 13.472461700000053],
                [-14.370485304999931, 13.470155717000125],
                [-14.367800711999962, 13.46876716700018],
                [-14.36407661499993, 13.468111039000064],
                [-14.354226112999982, 13.46716308700013],
                [-14.352152823999859, 13.466743470000154],
                [-14.347579956999937, 13.46622562499999],
                [-14.341171263999911, 13.464318276000029],
                [-14.33858108499993, 13.462837219000164],
                [-14.337165831999982, 13.46287822800008],
                [-14.335234640999886, 13.461905480000041],
                [-14.332643508999979, 13.461023331000149],
                [-14.328546524999922, 13.458662033],
                [-14.325484275999884, 13.457133294000073],
                [-14.323599815999899, 13.456528664000132],
                [-14.321198462999917, 13.455093384000065],
                [-14.319312094999873, 13.455226898000092],
                [-14.317329406999875, 13.456557275000137],
                [-14.313318251999931, 13.458343506],
                [-14.309400557999936, 13.460360528000137],
                [-14.30605030099997, 13.461824417000173],
                [-14.304447172999915, 13.462097169],
                [-14.299161910999942, 13.464063644000078],
                [-14.294067381999866, 13.465523720000135],
                [-14.288310051999929, 13.467719078000016],
                [-14.285810469999944, 13.468264581000142],
                [-14.283308982999927, 13.46927166000006],
                [-14.275854110999944, 13.470908165000026],
                [-14.27321243199998, 13.471730233000187],
                [-14.268022536999979, 13.472773552000092],
                [-14.259532928999931, 13.473946572000159],
                [-14.25415420399986, 13.474852561999967],
                [-14.247928619999982, 13.474511146000168],
                [-14.246373176999896, 13.474828721000051],
                [-14.244959830999903, 13.474225998000179],
                [-14.240619659999822, 13.474305154000149],
                [-14.238969802999918, 13.474069595000117],
                [-14.237459181999895, 13.47452545200008],
                [-14.234904289999974, 13.478065490000063],
                [-14.23197078699991, 13.482619286000102],
                [-14.229796409999949, 13.485192299000062],
                [-14.225164412999959, 13.489510536000068],
                [-14.222137450999867, 13.492957115000024],
                [-14.22020149299982, 13.494149209000113],
                [-14.21533012499998, 13.499618530000077],
                [-14.21329879699988, 13.501363754000067],
                [-14.21164608, 13.502003670000079],
                [-14.206400870999971, 13.506134032999967],
                [-14.200206755999943, 13.51141452800016],
                [-14.19784545799996, 13.512835502000144],
                [-14.195624350999935, 13.514763832000028],
                [-14.192553519999876, 13.516505241000118],
                [-14.191229819999876, 13.517791748999969],
                [-14.188585281999906, 13.519120216000033],
                [-14.186882971999921, 13.520312310000065],
                [-14.1836709989999, 13.522098541000162],
                [-14.177717209999969, 13.525951386000031],
                [-14.174884796999834, 13.527001381000105],
                [-14.16836357099993, 13.530712127000186],
                [-14.166049002999898, 13.531537056000161],
                [-14.16396999299991, 13.532681465000053],
                [-14.162177085999929, 13.532997132000162],
                [-14.158538818999943, 13.534783363000088],
                [-14.150842665999903, 13.536877633000131],
                [-14.148435592999874, 13.537099839000064],
                [-14.14678573599997, 13.536910058000103],
                [-14.14484882399995, 13.537178993000055],
                [-14.143057822999822, 13.536713601000088],
                [-14.137631415999977, 13.536602973000186],
                [-14.135554313999876, 13.537010192000025],
                [-14.125159263999933, 13.541813850000096],
                [-14.122937202999935, 13.54318809400013],
                [-14.11958408299995, 13.544697762000169],
                [-14.11708068799993, 13.545427322000137],
                [-14.109711647999973, 13.548027993000119],
                [-14.107159613999897, 13.549309731000108],
                [-14.104421614999865, 13.549530984],
                [-14.099978446999955, 13.55154228300006],
                [-14.097713470999963, 13.552042007000011],
                [-14.095679282999924, 13.553371429000094],
                [-14.091616629999976, 13.554509162000159],
                [-14.088831901999868, 13.554960251000068],
                [-14.081374167999911, 13.555025101000126],
                [-14.077266692999956, 13.555518150000125],
                [-14.073962210999923, 13.555505754000023],
                [-14.070188520999977, 13.555123329000082],
                [-14.063392639999904, 13.554775237000058],
                [-14.059902191999925, 13.55365657800013],
                [-14.0531282419999, 13.552552223000134],
                [-14.048878669999908, 13.552766801000132],
                [-14.039909361999946, 13.553517341000088],
                [-14.035848616999886, 13.553731918000153],
                [-14.02952289599989, 13.55384635900009],
                [-14.026785849999897, 13.55374431600012],
                [-14.023571968999931, 13.554745674000173],
                [-14.021725654999898, 13.556259155000077],
                [-14.019220352999923, 13.55749511700003],
                [-14.015197753999814, 13.560428620000039],
                [-14.01160430899995, 13.562305450000053],
                [-14.009616852999898, 13.563912393000066],
                [-14.006116865999957, 13.566109658000016],
                [-14.003848074999894, 13.566655159000163],
                [-14.002142904999914, 13.568446160000121],
                [-14.000963211999931, 13.568717956],
                [-13.994529722999914, 13.573148727999978],
                [-13.985445974999891, 13.57696342500003],
                [-13.982734680999897, 13.577666282000052],
                [-13.975898742999902, 13.579003335000095],
                [-13.972579001999918, 13.579532622999977],
                [-13.96586799499994, 13.579902650000122],
                [-13.962328911999975, 13.579562186000032],
                [-13.958977699999878, 13.57885265300007],
                [-13.950969696999948, 13.57542801000011],
                [-13.948297499999853, 13.573599817000115],
                [-13.944969177999894, 13.571741105000115],
                [-13.941030501999876, 13.569889068000123],
                [-13.934297560999937, 13.56718444900008],
                [-13.903964041999927, 13.559295654000039],
                [-13.889764785999887, 13.551755906000096],
                [-13.886375427999951, 13.549299241000142],
                [-13.884013176999872, 13.5466403960001],
                [-13.88237857799993, 13.544463159000031],
                [-13.880015371999946, 13.541938783000035],
                [-13.8765668879999, 13.536363602000165],
                [-13.875263213999972, 13.53454971399998],
                [-13.87085819299989, 13.526140213000076],
                [-13.868403434999834, 13.521272660000136],
                [-13.867154121999931, 13.519965171000024],
                [-13.864493369999877, 13.51616478000011],
                [-13.861175536999895, 13.512461662000135],
                [-13.858810423999955, 13.510137558000054],
                [-13.855306624999969, 13.507534981000049],
                [-13.852228164999872, 13.505992889000026],
                [-13.84632301299996, 13.502485275000083],
                [-13.8441925059999, 13.501606941000034],
                [-13.837918281999976, 13.497970581000061],
                [-13.835810660999982, 13.496126174000153],
                [-13.83358192399993, 13.493005753000148],
                [-13.827081679999878, 13.480824472000108],
                [-13.825431823999907, 13.478096009000012],
                [-13.821917534999898, 13.469580650000069],
                [-13.820114134999926, 13.466263772000048],
                [-13.815888404999953, 13.459454536000123],
                [-13.809823989999927, 13.454615594000131],
                [-13.805811881999944, 13.451576234000186],
                [-13.800867080999978, 13.448641777000148],
                [-13.795144079999943, 13.444166184000096],
                [-13.79276847899996, 13.440864564000151],
                [-13.79155063699983, 13.438544273000105],
                [-13.791533470999923, 13.431103707000148],
                [-13.790927887999885, 13.426516532000107],
                [-13.791215895999926, 13.422332764000146],
                [-13.791962623999893, 13.415472985],
                [-13.792026518999933, 13.413773537000111],
                [-13.792907714999956, 13.411092759000155],
                [-13.79388999899993, 13.406659127000125],
                [-13.795113563999905, 13.402134895000074],
                [-13.796344756999929, 13.395912171000191],
                [-13.799594878999812, 13.384861945000182],
                [-13.80144977599997, 13.381380082000135],
                [-13.801705359999971, 13.380087852000145],
                [-13.803967475999912, 13.376374245000079],
                [-13.807218550999892, 13.372402191000049],
                [-13.80839347899996, 13.370220184000061],
                [-13.810733795999965, 13.367410660000132],
                [-13.815438270999948, 13.360932351000145],
                [-13.816062927999894, 13.359724045000178],
                [-13.818977356999937, 13.35277557400019],
                [-13.820343970999943, 13.351001739000026],
                [-13.824057579999874, 13.346795082000085],
                [-13.826783179999893, 13.344439506000015],
                [-13.832711219999908, 13.338490487000115],
                [-13.835891723999907, 13.335576058000129],
                [-13.838337898999896, 13.333733559000166],
                [-13.851026533999914, 13.32755375],
                [-13.855840681999894, 13.324601174000122],
                [-13.85964489, 13.322502136000026],
                [-13.865664481999943, 13.320495606000065],
                [-13.868796348999922, 13.319975852000084],
                [-13.872059821999926, 13.319077492000133],
                [-13.878518104999955, 13.317939759000069],
                [-13.884789465999972, 13.317117691000078],
                [-13.888160705999951, 13.31691551200015],
                [-13.894332885999916, 13.315911294000045],
                [-13.90109920499998, 13.3160037990001],
                [-13.90443897199998, 13.316344262000143],
                [-13.907957076999935, 13.316323281000109],
                [-13.911952971999881, 13.316647529000079],
                [-13.917352676999883, 13.316860199000132],
                [-13.92064666799996, 13.317254067000022],
                [-13.925351141999897, 13.318226815],
                [-13.933423994999941, 13.319559098000013],
                [-13.939927101999956, 13.320852280999986],
                [-13.945937155999957, 13.320858956000109],
                [-13.948952674999873, 13.321621894000032],
                [-13.950836180999943, 13.32162952499999],
                [-13.951946257999964, 13.321010590000071],
                [-13.956791876999944, 13.31921482100006],
                [-13.961935042999869, 13.315862656999968],
                [-13.96827888599995, 13.312217713000052],
                [-13.97808551799983, 13.30633354300005],
                [-13.992655752999951, 13.297740936000082],
                [-13.996413230999963, 13.29568481399997],
                [-14.002136229999905, 13.292886735000025],
                [-14.003978728999869, 13.291465759000062],
                [-14.010489463999875, 13.29080104799999],
                [-14.012752531999979, 13.290811538000185],
                [-14.028506277999952, 13.289171219000082],
                [-14.03298950299984, 13.288774490000037],
                [-14.039196968999818, 13.288886070000046],
                [-14.039146423999966, 13.286338806000117],
                [-14.039532661999942, 13.282052995000129],
                [-14.042175292999957, 13.281543731000113],
                [-14.044091224999931, 13.283157349000021],
                [-14.046234130999949, 13.281669617000034],
                [-14.053856850999978, 13.284090996000089],
                [-14.05904197599989, 13.285455704000071],
                [-14.065184593999959, 13.285478593000107],
                [-14.064486503999888, 13.292241097000044],
                [-14.074050901999897, 13.291845322000086],
                [-14.08197402899998, 13.281126977000099],
                [-14.096656798999902, 13.278265953000187],
                [-14.102605819999894, 13.284842491],
                [-14.113389967999922, 13.281291962000182],
                [-14.121250153999938, 13.275721551000117],
                [-14.114027022999949, 13.26729106900018],
                [-14.113975524999944, 13.259188651000159],
                [-14.114167213999906, 13.248423577000153],
                [-14.116015433999905, 13.243795395000177],
                [-14.118782997999915, 13.238122939000164],
                [-14.125217436999947, 13.231417655000087],
                [-14.13196277599991, 13.222917557000073],
                [-14.139176368999927, 13.223411560000159],
                [-14.142994879999947, 13.220768927999984],
                [-14.148004531999902, 13.217887878],
                [-14.154265402999954, 13.216712951000034],
                [-14.16189003099987, 13.218532563000167],
                [-14.16386795099993, 13.220183373000168],
                [-14.1696634299999, 13.221398353000154],
                [-14.174386023999944, 13.223955155000112],
                [-14.181855200999962, 13.226521493000178],
                [-14.188112258999865, 13.226690293000104],
                [-14.193308829999864, 13.22446441700015],
                [-14.200790405999896, 13.223291397000082],
                [-14.210270882999964, 13.217639924000082],
                [-14.21653175399996, 13.216611863000026],
                [-14.228591918999939, 13.215303422000034],
                [-14.23439121299998, 13.215470315000118],
                [-14.238657950999936, 13.2174263],
                [-14.242164611999954, 13.218632698000022],
                [-14.246582031999935, 13.221337318000167],
                [-14.254807471999982, 13.226594924000153],
                [-14.256929396999965, 13.231385231000104],
                [-14.26263046399987, 13.240062715000079],
                [-14.273322104999977, 13.237401963000025],
                [-14.288281440999867, 13.236845970000104],
                [-14.307204246999959, 13.237794876000066],
                [-14.331639289999941, 13.23277568900005],
                [-14.347830770999906, 13.22773456499999],
                [-14.348548889999904, 13.22953891700007],
                [-14.357732772999952, 13.22988891599999],
                [-14.361321448999945, 13.231115341000134],
                [-14.365262985999948, 13.233459473000096],
                [-14.36822891199995, 13.233791351000036],
                [-14.37420844999997, 13.23519039200005],
                [-14.38613414799994, 13.239581108000095],
                [-14.389082908999967, 13.240254402],
                [-14.39491653499988, 13.243380548000061],
                [-14.398214339999981, 13.24433517500006],
                [-14.399624824999933, 13.245629311000016],
                [-14.403577803999951, 13.24766635899999],
                [-14.407341002999942, 13.250479698000163],
                [-14.413129805999972, 13.253543854000043],
                [-14.42064285299989, 13.25870323100014],
                [-14.430631637999909, 13.266308785000092],
                [-14.433219909999934, 13.267651558000068],
                [-14.437265396999976, 13.27102470400007],
                [-14.438863753999897, 13.273701669000161],
                [-14.440744399999971, 13.275779724000074],
                [-14.442199707999976, 13.277995110000063],
                [-14.446479796999881, 13.28358077900009],
                [-14.449447632999977, 13.287086485999964],
                [-14.45085144099994, 13.289718629000049],
                [-14.453296660999854, 13.292904854000028],
                [-14.456542014999968, 13.29599952600006],
                [-14.45802402499993, 13.299763680000126],
                [-14.459927558999937, 13.300891876000037],
                [-14.483411788999888, 13.30294704400012],
                [-14.493278503999932, 13.304115296000134],
                [-14.496989250999945, 13.305480003000127],
                [-14.50631141699995, 13.306607246000056],
                [-14.514550208999935, 13.309464455000125],
                [-14.522633551999888, 13.314114570000015],
                [-14.532836912999926, 13.3247079840001],
                [-14.536601066999935, 13.32777595499999],
                [-14.537260054999933, 13.330111504000058],
                [-14.543206213999952, 13.337465287000043],
                [-14.551427841999953, 13.348259925000036],
                [-14.560715674999926, 13.362929345000168],
                [-14.583077429999946, 13.351152421000108],
                [-14.588535307999905, 13.348023414000068],
                [-14.594951628999922, 13.346239090000097],
                [-14.598619460999942, 13.344301224000162],
                [-14.604963302999977, 13.34213447600007],
                [-14.608702659999949, 13.341626167000072],
                [-14.61710452899996, 13.339245797000103],
                [-14.621380806999923, 13.339102746000094],
                [-14.624285697999937, 13.34129619600003],
                [-14.629417418999878, 13.340660096000136],
                [-14.63506698599997, 13.340991974000076],
                [-14.639088631999925, 13.342418671000075],
                [-14.64366817399997, 13.343321801],
                [-14.646883964999915, 13.340966226000091],
                [-14.652344702999926, 13.341942787000107],
                [-14.654361724999944, 13.340496064000092],
                [-14.658789634999948, 13.340950965000161],
                [-14.68383598399987, 13.350465776000021],
                [-14.698689459999969, 13.357757568000125],
                [-14.705488205999927, 13.36062812700004],
                [-14.729209899999887, 13.37443065600013],
                [-14.742181777999917, 13.3873214730001],
                [-14.74783420499989, 13.394820214000163],
                [-14.754391669999961, 13.40642261500011],
                [-14.756744384999934, 13.412088394000023],
                [-14.75942802399993, 13.41766452700017],
                [-14.763524054999891, 13.417208671000083],
                [-14.770820616999913, 13.41560459100009],
                [-14.784520148999945, 13.414927484000088],
                [-14.790733337999939, 13.415624619000141],
                [-14.79619407599995, 13.415536880000047],
                [-14.803112982999892, 13.416234018000011],
                [-14.807168007999962, 13.418050766000079],
                [-14.81146812399993, 13.41970062300004],
                [-14.822790144999885, 13.423573494000152],
                [-14.830698967999922, 13.42813873300014],
                [-14.838089941999954, 13.434314728000174],
                [-14.848777769999913, 13.44486713399999],
                [-14.851556777999974, 13.446203232000073],
                [-14.856168746999856, 13.445930482000051],
                [-14.86219310699994, 13.445105553000133],
                [-14.867699621999918, 13.445615769000028],
                [-14.889586447999932, 13.446825029000081],
                [-14.89848232199995, 13.447705270000029],
                [-14.90455341399985, 13.449410440000065],
                [-14.91283893699989, 13.452223779],
                [-14.918627737999941, 13.454389573000185],
                [-14.926536558999942, 13.457201958000098],
                [-14.937175749999938, 13.462499617999981],
                [-14.94084739699997, 13.463282585000059],
                [-14.947155952999879, 13.466967583000098],
                [-14.953557014999888, 13.469318390000012],
                [-14.962079046999975, 13.473416330000134],
                [-14.973800658999949, 13.478805542000032],
                [-14.980767249999928, 13.482441903000051],
                [-14.98721790299993, 13.486954689000072],
                [-14.994796752999946, 13.490132333000076],
                [-15.001733779999881, 13.49445915300015],
                [-15.012248992999957, 13.50125408200006],
                [-15.022415161999902, 13.508973121000054],
                [-15.031964301999892, 13.517524719000107],
                [-15.040531157999965, 13.5257539750001],
                [-15.049885748999827, 13.535481453000102],
                [-15.059565544999941, 13.54543495200005],
                [-15.066859244999932, 13.554150581000044],
                [-15.075125693999894, 13.56557846100003],
                [-15.081119537999882, 13.578652380999984],
                [-15.08699512499993, 13.595695495000086],
                [-15.088919639999915, 13.598615647000088],
                [-15.1005611409999, 13.594220161000067],
                [-15.1398000719999, 13.581439019000186],
                [-15.142724991999955, 13.571398736000162],
                [-15.170464516999914, 13.546840668000186],
                [-15.175637243999972, 13.542613983000138],
                [-15.18209266699995, 13.535718919000033],
                [-15.185168265999948, 13.533147811000106],
                [-15.189852714999972, 13.53183365000018],
                [-15.195634840999901, 13.528483390000076],
                [-15.204034804999935, 13.48965263299999],
                [-15.206847190999952, 13.476968766000084],
                [-15.21535014999995, 13.44569873800009],
                [-15.219308852999916, 13.43324756700008],
                [-15.222120284999846, 13.42666053900001],
                [-15.227442741999937, 13.418282509000164],
                [-15.240634917999898, 13.40646553],
                [-15.278907775999926, 13.376620294000077],
                [-15.295220374999928, 13.366868972000077],
                [-15.318081855999935, 13.362550735000127],
                [-15.334995268999876, 13.35976028400006],
                [-15.337884901999871, 13.35962295500019],
                [-15.346190452999963, 13.358654977000185],
                [-15.355375288999937, 13.356919288000029],
                [-15.360750197999948, 13.35662460400016],
                [-15.368667601999903, 13.357378960000062],
                [-15.37951373999988, 13.355257988000062],
                [-15.387632369999892, 13.359647751000068],
                [-15.390760421999971, 13.360312462000081],
                [-15.397019385999954, 13.362312316000043],
                [-15.413546561999908, 13.367836000000182],
                [-15.418533325999931, 13.369263649000061],
                [-15.42518234299996, 13.370975495000152],
                [-15.437212943999896, 13.375548364000053],
                [-15.44385909999994, 13.375440599000115],
                [-15.449234961999935, 13.375622750000161],
                [-15.458724021999899, 13.379912376000163],
                [-15.46361732399987, 13.383156778000057],
                [-15.469879149999883, 13.386302948000093],
                [-15.473009108999975, 13.387158395000085],
                [-15.47829246599997, 13.389827728000057],
                [-15.487291335999942, 13.392969132000133],
                [-15.491694449999954, 13.395065308000028],
                [-15.50439739199993, 13.393031120999979],
                [-15.512998579999874, 13.392917633000025],
                [-15.515899657999967, 13.391947747000074],
                [-15.51606655099988, 13.393845559000056],
                [-15.520329475999915, 13.392243386000132],
                [-15.539052008999931, 13.378539086000046],
                [-15.538049698999885, 13.374953271000095],
                [-15.54643249399993, 13.367608071000063],
                [-15.556951521999906, 13.359511375000181],
                [-15.563053130999947, 13.356507302000011],
                [-15.569003104999922, 13.354400635000104],
                [-15.573732375999896, 13.35214710200006],
                [-15.578767776999882, 13.350790024000048],
                [-15.585026739999932, 13.349878311000168],
                [-15.590980528999978, 13.349266052000075],
                [-15.655120849999946, 13.361629486000027],
                [-15.66314220299995, 13.364288331000182],
                [-15.673901557999898, 13.367321968000113],
                [-15.680360794999899, 13.370558740000035],
                [-15.688755035999975, 13.366707802000064],
                [-15.691291809999939, 13.364978791000112],
                [-15.70280551899998, 13.358631134000063],
                [-15.711982726999906, 13.355351448000135],
                [-15.71999073, 13.353223800000137],
                [-15.727022170999874, 13.351673127000026],
                [-15.734836577999943, 13.350502015000075],
                [-15.748906134999913, 13.349503518000176],
                [-15.760827064999944, 13.348894120000182],
                [-15.77802562699992, 13.348650933000044],
                [-15.794639587999939, 13.348790170000143],
                [-15.80206394299995, 13.34800148000005],
                [-15.802248001999885, 13.31658840099999],
                [-15.802564620999817, 13.262451172],
                [-15.802843093999854, 13.228193284000042],
                [-15.802395820999948, 13.193181991000188],
                [-15.802509308999959, 13.181070327999976],
                [-15.851031302999843, 13.18076419900018],
                [-15.893452644999968, 13.181218148000141],
                [-15.892812727999967, 13.172996522000062],
                [-15.892566680999948, 13.167467118000161],
                [-15.894005775999915, 13.167599678999977],
                [-15.927579879999882, 13.169153215000108],
                [-15.951016424999978, 13.16947460200015],
                [-15.975130079999929, 13.166992188000108],
                [-15.99951267299997, 13.164570808000121],
                [-16.024124145999906, 13.16367721600011],
                [-16.039062500999933, 13.163608552000085],
                [-16.058597563999967, 13.163821220000102],
                [-16.091287612999963, 13.164675713000179],
                [-16.105653763999896, 13.164550782000049],
                [-16.124443053999926, 13.163511276],
                [-16.14543914799998, 13.164010049000183],
                [-16.19259261999997, 13.162779808999971],
                [-16.21546363699997, 13.16287994300012],
                [-16.232791900999928, 13.163213729],
                [-16.24247360299995, 13.164224624000099],
                [-16.28321838399995, 13.164425851000146],
                [-16.302730560999862, 13.164710999000079],
                [-16.318231582999886, 13.164750099000116],
                [-16.35428237899987, 13.165253640000117],
                [-16.370229721999976, 13.165571212000145],
                [-16.413530350999963, 13.16450500500008],
                [-16.43016433799994, 13.164783478000118],
                [-16.43706703099997, 13.165331841000125],
                [-16.459800718999873, 13.165502549000053],
                [-16.467453002999946, 13.164988517000154],
                [-16.48985671999992, 13.1657180790001],
                [-16.507097244999898, 13.165428163000115],
                [-16.53533172599998, 13.165133476000165],
                [-16.55115508999984, 13.165491102999965],
                [-16.564729690999968, 13.164693833000115],
                [-16.583541869999976, 13.165287018000072],
                [-16.58642196699998, 13.168073654000068],
                [-16.59512519799995, 13.170315742000128],
                [-16.60283470199994, 13.169789315000173],
                [-16.61101913499982, 13.167442322000056],
                [-16.616216658999974, 13.165650368000115],
                [-16.625759125999934, 13.16581249300009],
                [-16.630590438999832, 13.169193269000061],
                [-16.63792610099989, 13.171155929000065],
                [-16.643499374999976, 13.172440529000141],
                [-16.64994811899993, 13.173432350000041],
                [-16.659208297999953, 13.174176215000045],
                [-16.663705825999955, 13.172367096000187],
                [-16.6715831759999, 13.167779923000182],
                [-16.67949485899993, 13.167693139000107],
                [-16.684143066999923, 13.168377876000022],
                [-16.688650129999928, 13.167914392],
                [-16.690860748999853, 13.165968895000049],
                [-16.689725875999898, 13.160782815000118],
                [-16.691980360999935, 13.159999848000098],
                [-16.69432258599994, 13.15985965700014],
                [-16.698270797999953, 13.162706375000141],
                [-16.70039176999984, 13.16274643],
                [-16.701984404999962, 13.162057877000109],
                [-16.702171325999927, 13.15984249100012],
                [-16.69944191099995, 13.155711175000079],
                [-16.69929695199994, 13.15355205600008],
                [-16.700897216999977, 13.15280914400006],
                [-16.702167510999857, 13.155060769000102],
                [-16.704887390999943, 13.156299592000039],
                [-16.70723342899987, 13.155379296999968],
                [-16.709156035999968, 13.151877403000128],
                [-16.709482193999975, 13.149760246000028],
                [-16.709243774999948, 13.148659706999979],
                [-16.707979200999944, 13.147094726000091],
                [-16.704923629999882, 13.145542146000082],
                [-16.703321457999948, 13.143239021],
                [-16.70392799399997, 13.137630463000107],
                [-16.701431273999958, 13.135243416000094],
                [-16.701290129999904, 13.133625985000094],
                [-16.70368003899995, 13.132380486000159],
                [-16.70489883399989, 13.132707597000149],
                [-16.706314087999942, 13.134496688000127],
                [-16.709226608999927, 13.13421535499998],
                [-16.710399626999845, 13.131775856000047],
                [-16.71072006199995, 13.128645897000126],
                [-16.710195541999894, 13.124925615000166],
                [-16.71108818099998, 13.123446465000029],
                [-16.71301650999993, 13.122159005000071],
                [-16.720115661999955, 13.120330812000077],
                [-16.724090575999924, 13.121829987000183],
                [-16.72564887899989, 13.121677400000067],
                [-16.72596931399994, 13.118692398],
                [-16.725591658999974, 13.11753749900015],
                [-16.724042891999886, 13.116300583000111],
                [-16.72172927799994, 13.11375141100001],
                [-16.72286033599994, 13.10926723599999],
                [-16.72487640299994, 13.108991623],
                [-16.726427077999972, 13.110319138000023],
                [-16.727659225999957, 13.113763808999977],
                [-16.72855377199994, 13.115050317000055],
                [-16.729578017999927, 13.115332603000127],
                [-16.73390197799995, 13.115095139],
                [-16.73516654999986, 13.114028932000167],
                [-16.73676490699995, 13.110348702000124],
                [-16.73727226299991, 13.10653114300004],
                [-16.737033844999928, 13.105239868000012],
                [-16.736143112999912, 13.10450458500003],
                [-16.735059737999904, 13.102667809000081],
                [-16.733133316999954, 13.101524353000173],
                [-16.731815337999933, 13.100194931000146],
                [-16.729555129999824, 13.098718644000087],
                [-16.728946685999915, 13.099784852000028],
                [-16.726739882999937, 13.09712028500013],
                [-16.726642607999963, 13.096288681000033],
                [-16.729883194999957, 13.094124794000152],
                [-16.731292723999957, 13.093935013000191],
                [-16.733818053999926, 13.092461586000013],
                [-16.733917235999968, 13.090896607000104],
                [-16.733253477999938, 13.089518548000058],
                [-16.730804442999897, 13.08620834300018],
                [-16.7296752929999, 13.084136963000049],
                [-16.729106902999945, 13.082071304000067],
                [-16.73083877499988, 13.077928543000041],
                [-16.733650206999982, 13.075161933000118],
                [-16.735158919999947, 13.07470035600005],
                [-16.74069976899989, 13.074374199000147],
                [-16.741870878999976, 13.07455539700004],
                [-16.743274689999964, 13.073542595000106],
                [-16.745712278999974, 13.068708420000064],
                [-16.74666595399998, 13.06612682400015],
                [-16.74808120799986, 13.064463616000182],
                [-16.747482299999945, 13.062076570000045],
                [-16.74511337299998, 13.059516907000102],
                [-16.74460983299997, 13.056740762000175],
                [-16.74362945599995, 13.054984092000097],
                [-16.742851257999916, 13.052413940000065],
                [-16.741231918999972, 13.048289299000146],
                [-16.739828110999895, 13.045451165000145],
                [-16.73796081599994, 13.045395851000023],
                [-16.73740196199998, 13.044586182000103],
                [-16.738008497999886, 13.042277337000087],
                [-16.73755836499987, 13.037196159000132],
                [-16.73685073899992, 13.034760475000041],
                [-16.73605918899989, 13.030291558000044],
                [-16.73597144999991, 13.028593063000073],
                [-16.73597144999991, 13.018750192000084],
                [-16.736284254999873, 13.014027596000119],
                [-16.73708343499993, 13.007638931000088],
                [-16.737638472999947, 13.007082939999975],
                [-16.73791694599987, 13.00338363700007],
                [-16.739582061999897, 12.995415688000094],
                [-16.740249632999962, 12.986741067000082],
                [-16.740232466999885, 12.984436036000034],
                [-16.741907120999883, 12.976696014000026],
                [-16.742965697999978, 12.96997833200004],
                [-16.74401473999984, 12.961768150000182],
                [-16.74482536299996, 12.958983421000028],
                [-16.74575614899993, 12.953824997000083],
                [-16.745983123999963, 12.946501732000172],
                [-16.746383667999908, 12.944601060000139],
                [-16.747404097999947, 12.94228935200016],
                [-16.74958419799998, 12.93652820699998],
                [-16.75124931399995, 12.933471681000071],
                [-16.75124931399995, 12.932084083000177],
                [-16.752639770999906, 12.928749085000106],
                [-16.75347137499989, 12.9248609550001],
                [-16.75347137499989, 12.923012733000121],
                [-16.754304886999932, 12.915314675000161],
                [-16.754581451999968, 12.91430568800007],
                [-16.754581451999968, 12.911222458000054],
                [-16.7550258629999, 12.908472062000044],
                [-16.755693436999877, 12.907917976000022],
                [-16.755693436999877, 12.90625],
                [-16.757638930999917, 12.899305345000187],
                [-16.757638930999917, 12.897919655000123],
                [-16.758346557999914, 12.896049500000174],
                [-16.75968360899998, 12.889532089000113],
                [-16.760810851999906, 12.882813454000143],
                [-16.762638091999918, 12.870972634000111],
                [-16.76347160399996, 12.869304657000043],
                [-16.76347160399996, 12.868194580000079],
                [-16.7643051149999, 12.866805077000151],
                [-16.764583587999937, 12.865181923000137],
                [-16.766805647999945, 12.86013794000013],
                [-16.767082214999846, 12.858456611000065],
                [-16.768293379999932, 12.855137825000043],
                [-16.76930427499991, 12.854027748000135],
                [-16.770139693999965, 12.850972175000038],
                [-16.770139693999965, 12.849753380000152],
                [-16.770984648999956, 12.845526695000103],
                [-16.77186202999991, 12.842741967000109],
                [-16.77212142899998, 12.84023189700008],
                [-16.772802352999975, 12.83880329200008],
                [-16.773195266999892, 12.836528778000172],
                [-16.773195266999892, 12.833944322000036],
                [-16.775543211999945, 12.825633049999965],
                [-16.776548385999945, 12.821423530000061],
                [-16.777210234999927, 12.817148210000141],
                [-16.777778624999883, 12.809618950000129],
                [-16.77755737399997, 12.807654380000145],
                [-16.77791595499997, 12.804041863000123],
                [-16.779443739999977, 12.800659180000082],
                [-16.779979704999903, 12.798283576000188],
                [-16.782630920999907, 12.7923669810001],
                [-16.78499984799987, 12.785436631000096],
                [-16.787916183999982, 12.777756692000139],
                [-16.788654326999847, 12.77463340800017],
                [-16.78986167799991, 12.772915840000167],
                [-16.79075813299994, 12.769398690000116],
                [-16.791076659999874, 12.765532494000183],
                [-16.790281294999886, 12.760453224000173],
                [-16.78853416399994, 12.757550240000171],
                [-16.78735160799988, 12.756473542000037],
                [-16.78724288999996, 12.751042367000025],
                [-16.78896713299997, 12.750361442999974],
                [-16.790199278999864, 12.74886131300002],
                [-16.79039764299995, 12.747571945000118],
                [-16.78969955499997, 12.746627808000085],
                [-16.787372588999915, 12.749423027000148],
                [-16.784461974999886, 12.748086929000067],
                [-16.78197479299996, 12.748104095000087],
                [-16.779895782999915, 12.747033119000093],
                [-16.777807235999944, 12.744539260000067],
                [-16.77625846799998, 12.74027824500007],
                [-16.77637481599993, 12.737158775000069],
                [-16.77608108499993, 12.734652519000178],
                [-16.776044845999877, 12.729365349000034],
                [-16.77718353399996, 12.724544526000045],
                [-16.7784729, 12.722084045000145],
                [-16.779306410999823, 12.718725205000055],
                [-16.780693053999983, 12.715147017999982],
                [-16.78236389199992, 12.712082864000138],
                [-16.782085418999884, 12.711178779000022],
                [-16.783424376999903, 12.708231926],
                [-16.784980773999905, 12.70368003800013],
                [-16.786340712999902, 12.700891495000064],
                [-16.786745071999974, 12.69932937700014],
                [-16.78777122499997, 12.698034286],
                [-16.78823661699994, 12.695386887000097],
                [-16.78705215399998, 12.693903923000107],
                [-16.784128188999887, 12.668241500000022],
                [-16.7845535269999, 12.667176247000043],
                [-16.782213211999874, 12.663430214000186],
                [-16.779968261999954, 12.658225060000063],
                [-16.778129577999948, 12.651797294000119],
                [-16.774656295999932, 12.648633956000026],
                [-16.77637100299995, 12.64713096600019],
                [-16.776504516999978, 12.646384238000053],
                [-16.778322219999836, 12.644846916],
                [-16.77776718099983, 12.639461519],
                [-16.773454666999953, 12.634540558000083],
                [-16.770795821999968, 12.629610061999983],
                [-16.769739149999964, 12.62643051100008],
                [-16.7685470589999, 12.623794555000131],
                [-16.767490386999953, 12.620615006000037],
                [-16.766143798999906, 12.615539550000051],
                [-16.765205382999966, 12.609647752000171],
                [-16.76480865399992, 12.602056504000188],
                [-16.764972686999897, 12.595683098000052],
                [-16.766304015999935, 12.588487625000027],
                [-16.767383574999883, 12.585022925999965],
                [-16.76798057499991, 12.581426622000151],
                [-16.767688750999923, 12.579123497000069],
                [-16.766305923999937, 12.575803756000141],
                [-16.774980545999938, 12.524586678000105],
                [-16.776250839999932, 12.52236080200015],
                [-16.777414321999856, 12.518317222000064],
                [-16.778497695999874, 12.515462876000129],
                [-16.779235839999842, 12.512475013000085],
                [-16.782232284999964, 12.504028320000145],
                [-16.782957076999878, 12.501671792000025],
                [-16.784383773999878, 12.498476030000063],
                [-16.786249160999944, 12.493194580000079],
                [-16.78958320499987, 12.48718643300009],
                [-16.791250227999967, 12.484860420000075],
                [-16.791709899999887, 12.48005580900002],
                [-16.789819717999876, 12.476136208000185],
                [-16.78923797599998, 12.47186946800008],
                [-16.78944206299991, 12.461292267000033],
                [-16.789344788999927, 12.457292557000073],
                [-16.78800010599997, 12.45228481300012],
                [-16.78596687299995, 12.447552681000104],
                [-16.78429412899993, 12.44492054],
                [-16.78260230999996, 12.439711571000032],
                [-16.781965255999978, 12.437207221000165],
                [-16.781440735999922, 12.431041716999971],
                [-16.781007765999902, 12.428332328000067],
                [-16.780445098999962, 12.426844598000116],
                [-16.77695083599997, 12.41995239300013],
                [-16.775138854999966, 12.415327073000071],
                [-16.77541732799989, 12.41430473299999],
                [-16.772600173999876, 12.408998489000112],
                [-16.771738050999943, 12.404055595000045],
                [-16.77090644699996, 12.403382301000079],
                [-16.767314910999914, 12.402728081000134],
                [-16.765441893999935, 12.401113510000073],
                [-16.764253615999905, 12.398748399000056],
                [-16.764028549999978, 12.396805763000032],
                [-16.75968360899998, 12.396134377000124],
                [-16.757669448999934, 12.394045829000163],
                [-16.756620407999947, 12.391815187000134],
                [-16.754610060999937, 12.390200616000016],
                [-16.75143623299988, 12.390153884000085],
                [-16.74796867499998, 12.387125015000038],
                [-16.745695113999943, 12.383194923000076],
                [-16.744306563999828, 12.381251334000126],
                [-16.741804122999895, 12.382239343000094],
                [-16.739229202999923, 12.381507874000022],
                [-16.735826492999934, 12.378324508999981],
                [-16.73163795499994, 12.370243073000154],
                [-16.731338500999925, 12.369193077000148],
                [-16.730058670999938, 12.356898308000098],
                [-16.729541778999874, 12.353955270000085],
                [-16.727602004999937, 12.35240841000001],
                [-16.72579574599996, 12.35031891000017],
                [-16.72481918299991, 12.348697664000099],
                [-16.722949981999875, 12.347489357000086],
                [-16.721487045999822, 12.345329286000151],
                [-16.719194411999922, 12.342631340000025],
                [-16.718069075999892, 12.33945179],
                [-16.716777800999978, 12.338594438000143],
                [-16.688405999999873, 12.358687],
                [-16.66192436299997, 12.358651159999965],
                [-16.656942366999886, 12.35883522100005],
                [-16.650083542999937, 12.357885362000047],
                [-16.638816832999964, 12.356509210000013],
                [-16.622136999999896, 12.351314],
                [-16.604148999999893, 12.346098000000154],
                [-16.543396, 12.36],
                [-16.50995063899984, 12.350044251999975],
                [-16.485464, 12.356951],
                [-16.418966, 12.365433000000166],
                [-16.400228, 12.363838000000158],
                [-16.379072126999915, 12.361931011000024],
                [-16.374723434999908, 12.373483658000112],
                [-16.374155, 12.374676],
                [-16.37217711999989, 12.37619590800017],
                [-16.36860468499998, 12.377199167000128],
                [-16.344013, 12.390261],
                [-16.33978843799997, 12.391222001000074],
                [-16.281237, 12.424426],
                [-16.268646, 12.431598000000122],
                [-16.262686, 12.433741000000111],
                [-16.241260999999895, 12.443089000000157],
                [-16.236362322999867, 12.444440909000036],
                [-16.21093599999989, 12.45693400000016],
                [-16.203131, 12.460178000000155],
                [-16.201256, 12.460594],
                [-16.197492999999895, 12.45934700000015],
                [-16.162189041999966, 12.44799701400018],
                [-16.160643, 12.44771500000013],
                [-16.131807326999876, 12.454851151000184],
                [-16.089224, 12.465384000000142],
                [-16.075964, 12.468611000000124],
                [-16.067876999999896, 12.470512],
                [-16.054363, 12.471198000000129],
                [-16.038877, 12.471331000000134],
                [-16.037792, 12.471516000000179],
                [-16.031935, 12.470064],
                [-16.005130767999844, 12.460023880000165],
                [-15.997961997999823, 12.457381249000093],
                [-15.953376770999967, 12.44264698100011],
              ],
            ],
            [
              [
                [-16.726528000999906, 13.77235999900006],
                [-16.72402800099991, 13.772084],
                [-16.72347299899991, 13.773194],
                [-16.726528000999906, 13.773194],
                [-16.726528000999906, 13.77235999900006],
              ],
            ],
            [
              [
                [-16.653194, 13.645695],
                [-16.653194, 13.648471],
                [-16.65374899999989, 13.649583000000177],
                [-16.65624799999989, 13.649027001000093],
                [-16.655972, 13.647639001000186],
                [-16.654306000999895, 13.64625100100011],
                [-16.653194, 13.643751001],
                [-16.650972, 13.6409719990001],
                [-16.649585999999886, 13.637638],
                [-16.64597099899993, 13.631250000000136],
                [-16.64514, 13.630693001000168],
                [-16.642083998999908, 13.626249],
                [-16.639584000999946, 13.625138999000171],
                [-16.639029, 13.624305000000163],
                [-16.635693000999936, 13.624305000000163],
                [-16.634863, 13.623473],
                [-16.631805000999975, 13.623195000000123],
                [-16.629583, 13.625971000000163],
                [-16.630971999999872, 13.625971000000163],
                [-16.63152699899996, 13.627083000000141],
                [-16.63319200099994, 13.627083000000141],
                [-16.634026998999957, 13.626249],
                [-16.635139, 13.626526999000077],
                [-16.635693000999936, 13.628196],
                [-16.635693000999936, 13.629862],
                [-16.63708300099995, 13.632084001000123],
                [-16.639029, 13.632915999000033],
                [-16.639304998999933, 13.634862],
                [-16.644306, 13.634584001],
                [-16.64485899899995, 13.635138000000154],
                [-16.64458299899991, 13.638472001000025],
                [-16.648195, 13.639304],
                [-16.649303, 13.64069400000011],
                [-16.649303, 13.641528],
                [-16.650972, 13.643472999000096],
                [-16.651808, 13.645139001000075],
                [-16.653194, 13.645695],
              ],
            ],
            [
              [
                [-16.641806, 13.653751],
                [-16.641806, 13.656527],
                [-16.643473, 13.656527],
                [-16.643473, 13.652083],
                [-16.644028, 13.650417],
                [-16.642915999999843, 13.650417],
                [-16.640416999999843, 13.652083],
                [-16.63875, 13.654027],
                [-16.637362, 13.654861],
                [-16.637362, 13.656527],
                [-16.638472, 13.656806],
                [-16.639862, 13.654861],
                [-16.641806, 13.653751],
              ],
            ],
            [
              [
                [-16.63625, 13.678749],
                [-16.638197, 13.679305],
                [-16.639029, 13.680695000000128],
                [-16.640139, 13.680417],
                [-16.640416999999843, 13.677639],
                [-16.640972, 13.676251],
                [-16.64125099999984, 13.673751],
                [-16.64125099999984, 13.668194000000142],
                [-16.641527, 13.66736],
                [-16.643194, 13.665972000000124],
                [-16.642917999999895, 13.663538000000187],
                [-16.643473, 13.662918],
                [-16.644306, 13.659028],
                [-16.640972, 13.660416000000168],
                [-16.639305, 13.662084000000164],
                [-16.63875, 13.662084000000164],
                [-16.637083, 13.663750000000164],
                [-16.635975, 13.665972000000124],
                [-16.635139, 13.666528000000142],
                [-16.633752999999842, 13.668472],
                [-16.632917, 13.670973],
                [-16.632917, 13.673473000000115],
                [-16.633752999999842, 13.674583],
                [-16.634863, 13.674861],
                [-16.636528, 13.677083],
                [-16.63625, 13.678749],
              ],
            ],
            [
              [
                [-16.637917, 13.680971],
                [-16.637917, 13.68013900000011],
                [-16.635975, 13.68013900000011],
                [-16.636806, 13.681527000000187],
                [-16.637917, 13.680971],
              ],
            ],
            [
              [
                [-17.469305, 14.655967],
                [-17.47097, 14.657357000000161],
                [-17.473471, 14.657357000000161],
                [-17.475692999999865, 14.655689000000166],
                [-17.475414, 14.65485800000016],
                [-17.473749, 14.65485800000016],
                [-17.474028, 14.652913000000183],
                [-17.473471, 14.652357000000165],
                [-17.473471, 14.650411],
                [-17.471806, 14.650411],
                [-17.470693999999867, 14.652079],
                [-17.469305, 14.652080000000183],
                [-17.468748, 14.653745],
                [-17.469305, 14.655967],
              ],
            ],
            [
              [
                [-17.397089004999827, 14.668187140999976],
                [-17.398475646999884, 14.66929721800011],
                [-17.398477554999943, 14.671519280000098],
                [-17.399864196999943, 14.671797753000135],
                [-17.401256561999958, 14.669296265000128],
                [-17.40069961599994, 14.666796685000065],
                [-17.399311065999825, 14.664573669000163],
                [-17.39903068599989, 14.66318607400018],
                [-17.397367477999865, 14.662630081000088],
                [-17.39597701999992, 14.664020538000102],
                [-17.396255492999956, 14.667353630000036],
                [-17.397089004999827, 14.668187140999976],
              ],
            ],
            [
              [
                [-17.53929699899993, 14.745131001000061],
                [-17.537632, 14.745410000000163],
                [-17.537632, 14.74707899900011],
                [-17.539576000999944, 14.746522000000141],
                [-17.53985200099993, 14.745409],
                [-17.542353000999924, 14.744852999000159],
                [-17.543184, 14.742632000000185],
                [-17.542075998999906, 14.741244],
                [-17.54040899999984, 14.74291],
                [-17.54068799899983, 14.744300000000123],
                [-17.53929699899993, 14.745131001000061],
              ],
            ],
            [
              [
                [-17.510738, 14.756556000000103],
                [-17.512356, 14.758185],
                [-17.515411, 14.75791],
                [-17.517076, 14.75596400000012],
                [-17.517078, 14.754854000000137],
                [-17.514858, 14.754297],
                [-17.512873, 14.754261],
                [-17.510738, 14.756556000000103],
              ],
            ],
            [
              [
                [-17.475692999999865, 14.768741],
                [-17.476801, 14.770407],
                [-17.479857999999865, 14.769850000000133],
                [-17.480413, 14.767905000000155],
                [-17.479582, 14.767351000000133],
                [-17.475969, 14.768195000000162],
                [-17.475692999999865, 14.768741],
              ],
            ],
          ],
        },
        properties: { GID_0: "SEN", COUNTRY: "Senegal" },
      },
    ],
  },
  105: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [11.155417, -3.957083998999906],
                [11.15569500000015, -3.956806],
                [11.15569500000015, -3.955694],
                [11.15458299900007, -3.955416],
                [11.15458299900007, -3.957362000999979],
                [11.155417, -3.957083998999906],
              ],
            ],
            [
              [
                [11.233287810999968, -3.850769042999957],
                [11.249189000000172, -3.826533],
                [11.251470566000137, -3.811805009999887],
                [11.245481000000154, -3.764861999999823],
                [11.24501037500005, -3.742311953999888],
                [11.249577000000102, -3.718149],
                [11.256214143000136, -3.705950974999894],
                [11.26995, -3.688458],
                [11.302922249000119, -3.657380103999969],
                [11.32222938600006, -3.630593060999956],
                [11.334276198999987, -3.61387801099994],
                [11.345494271000064, -3.603519915999925],
                [11.361526, -3.595228],
                [11.393135071000131, -3.586930035999956],
                [11.407796861000179, -3.587151050999921],
                [11.428418, -3.590132],
                [11.442164, -3.589665],
                [11.458426476, -3.583903073999863],
                [11.48680782300005, -3.556732892999946],
                [11.508086205000097, -3.529798982999864],
                [11.519759178000186, -3.518515109999953],
                [11.529373168, -3.512758016999953],
                [11.53739261600009, -3.513901947999841],
                [11.564666748999969, -3.52953004799997],
                [11.585523606000038, -3.538721083999917],
                [11.609590530000048, -3.551820039999882],
                [11.621282577000159, -3.560555934999968],
                [11.625873565000063, -3.566764115999945],
                [11.63160324099999, -3.570209978999912],
                [11.646289826000043, -3.590678930999957],
                [11.669245720000163, -3.632309913999904],
                [11.677510262000055, -3.644270896999956],
                [11.680949211000154, -3.646797895999953],
                [11.696326256000134, -3.670717953999883],
                [11.702751160000048, -3.677154062999875],
                [11.713526725000179, -3.684278011999936],
                [11.786647796000068, -3.707683085999975],
                [11.7928390510001, -3.712049960999877],
                [11.829981804000113, -3.729270934999931],
                [11.862773896000135, -3.747415065999974],
                [11.876068116, -3.750394104999941],
                [11.88431358300005, -3.747391938999954],
                [11.895748138000158, -3.734263896999948],
                [11.914035798000157, -3.702949046999947],
                [11.915406227000119, -3.699845075999974],
                [11.94783306200003, -3.626283883999918],
                [11.946452142000169, -3.620990990999871],
                [11.9404859550001, -3.615475892999882],
                [11.90449333100014, -3.599407909999854],
                [11.889360427000156, -3.59091091199997],
                [11.87788677200001, -3.576885936999929],
                [11.876952170000152, -3.56492209399994],
                [11.880576133000034, -3.535695074999921],
                [11.887432099000137, -3.522802114999934],
                [11.89818000800011, -3.510824918999958],
                [11.919697761000123, -3.498833894999905],
                [11.943277358000046, -3.48937392199997],
                [11.958836555000175, -3.478311060999886],
                [12.000478744000134, -3.423626899999931],
                [12.007069587000046, -3.414971112999979],
                [12.027865409000128, -3.383651017999966],
                [12.045451164000099, -3.352566002999879],
                [12.048404694000055, -3.339445113999886],
                [12.043340682000064, -3.323575972999947],
                [12.022211074000097, -3.284483909999949],
                [12.001762391000057, -3.24194192899995],
                [11.965310096000167, -3.214373111999976],
                [11.943539620000081, -3.204273938999904],
                [11.936654090000047, -3.193928956999969],
                [11.935043335000103, -3.187948941999821],
                [11.939379693000035, -3.178281068999979],
                [11.947383881000064, -3.171828030999905],
                [11.971418381999968, -3.16627693099997],
                [11.991331100000025, -3.15957999199992],
                [12.002311707000047, -3.153124092999917],
                [12.01076698300011, -3.142299889999947],
                [12.013502121000101, -3.135162115999833],
                [12.018236161000118, -3.087987899999973],
                [12.024854661000063, -3.074404953999931],
                [12.030320167000013, -3.055761097999948],
                [12.042069435000144, -3.029916046999972],
                [12.05062484800004, -3.011096954999914],
                [12.065691947000175, -2.986459969999942],
                [12.068427085000167, -2.977482079999959],
                [12.066580773000169, -2.969432114999961],
                [12.056954384999983, -2.961621998999874],
                [12.043212891000053, -2.956347941999979],
                [12.011150361000034, -2.948796987999913],
                [11.938308715000062, -2.919662952999886],
                [11.894560813000055, -2.900156020999873],
                [11.866622924000126, -2.892132997999966],
                [11.796538352000027, -2.861373900999922],
                [11.760593415000187, -2.853126049999958],
                [11.711589814000035, -2.837754964999931],
                [11.688469886000064, -2.835704087999886],
                [11.6728992460001, -2.829735040999935],
                [11.655950547000145, -2.820775031999858],
                [11.642436028000077, -2.810893057999977],
                [11.630071639000107, -2.805843113999913],
                [11.622739793000108, -2.798945903999879],
                [11.617465973000037, -2.789746999999863],
                [11.616085053000177, -2.784224985999913],
                [11.620648385000095, -2.769037006999952],
                [11.632288933000098, -2.742572068999948],
                [11.637987137000096, -2.721169948999886],
                [11.641394615000138, -2.698163032999844],
                [11.648024558000088, -2.691945074999978],
                [11.656028748000097, -2.688947915999961],
                [11.668389320000188, -2.689167021999879],
                [11.686241151000104, -2.692373989999851],
                [11.696537971000112, -2.691215992999958],
                [11.701567649000083, -2.687067985999931],
                [11.703841209000075, -2.673032997999883],
                [11.702683449000062, -2.662913083999854],
                [11.696257591, -2.647274015999926],
                [11.685250282000084, -2.627727984999979],
                [11.668976784000108, -2.605427025999973],
                [11.661868096000092, -2.591169119999961],
                [11.65336704300006, -2.560580013999868],
                [11.636161804000153, -2.520796059999896],
                [11.637057304000166, -2.504000901999973],
                [11.641609192000033, -2.480072020999955],
                [11.653006554000171, -2.444638012999974],
                [11.653219224000168, -2.430376052999975],
                [11.650920868000071, -2.421405076999974],
                [11.632357596000134, -2.391056059999926],
                [11.61380100200006, -2.367377995999959],
                [11.609674452999968, -2.356797932999939],
                [11.607123374000025, -2.325057029999869],
                [11.608720779000123, -2.322757004999971],
                [11.613064767000026, -2.319992064999894],
                [11.622213363000071, -2.320446013999913],
                [11.648542404000125, -2.339287996999872],
                [11.666390419000095, -2.344563960999949],
                [11.678981781000061, -2.353524923999942],
                [11.69776153600003, -2.372829913999965],
                [11.710824012999979, -2.391912937999962],
                [11.729626656000107, -2.426861047999864],
                [11.740162850000047, -2.437661885999944],
                [11.76191520700013, -2.453516006999962],
                [11.771301269000105, -2.456957100999944],
                [11.775189399000112, -2.456491946999961],
                [11.794379234000075, -2.435539959999971],
                [11.806702613000027, -2.410913942999969],
                [11.820854187000123, -2.387437104999833],
                [11.831138610999972, -2.3807559],
                [11.84760761299998, -2.378669021999826],
                [11.858119965000071, -2.371527909999941],
                [11.867042541000103, -2.371516941999914],
                [11.891985892000037, -2.378163098999949],
                [11.904110908000177, -2.378609894999897],
                [11.914856911000129, -2.374685047999947],
                [11.940225600000133, -2.358325003999937],
                [11.974740982000128, -2.341264008999929],
                [11.996698379000122, -2.339857100999893],
                [12.00539493600013, -2.342375993999951],
                [12.022570611000106, -2.35454606899998],
                [12.048460007000074, -2.379585027999951],
                [12.057619094000188, -2.386017083999946],
                [12.064732552000066, -2.397737980999921],
                [12.069555283000057, -2.410382985999888],
                [12.077589035000074, -2.425324915999966],
                [12.092252731000087, -2.439337014999978],
                [12.09591293300008, -2.43933296199998],
                [12.110090254999989, -2.434252023999875],
                [12.14805603100018, -2.427989005999905],
                [12.176639556000055, -2.421277045999943],
                [12.225689887000158, -2.421308994999947],
                [12.284621238000113, -2.421345948999885],
                [12.299260139000125, -2.419941900999959],
                [12.351439477000042, -2.420353887999966],
                [12.468323707000081, -2.421263932999921],
                [12.49761295400009, -2.423970936999922],
                [12.502870560000076, -2.422353028999964],
                [12.506054879000033, -2.413145064999981],
                [12.501635552000039, -2.379796981999959],
                [12.506184577999989, -2.368058919999896],
                [12.52077865700005, -2.346637963999967],
                [12.529197694000061, -2.326380966999977],
                [12.52666568800015, -2.318794011999898],
                [12.511749269000063, -2.296741007999913],
                [12.497986794000099, -2.27951288099996],
                [12.497048378000102, -2.268013953999969],
                [12.504795075000061, -2.253969906999942],
                [12.528741837000098, -2.221952915999964],
                [12.532154083000137, -2.213896035999881],
                [12.527290344000164, -2.186074971999972],
                [12.51260566700006, -2.164942979999864],
                [12.510764123000115, -2.158734083999946],
                [12.522105217000046, -2.115931987999829],
                [12.523661614000105, -2.095231055999875],
                [12.519983292000063, -2.086726903999931],
                [12.494083404000094, -2.059406996999883],
                [12.482375145000049, -2.038499116999958],
                [12.47524642899998, -2.018961905999959],
                [12.470615386000077, -1.991832970999951],
                [12.47102737300014, -1.97067403899996],
                [12.467311859, -1.943542956999977],
                [12.444073677, -1.874595045999968],
                [12.445437431000073, -1.869990943999881],
                [12.462126731000183, -1.869961021999927],
                [12.471508026000038, -1.87431395099992],
                [12.485028267000018, -1.888546942999938],
                [12.520722389000184, -1.901360987999965],
                [12.535122872000045, -1.900872945999879],
                [12.543574333000038, -1.89648794999988],
                [12.550407410000105, -1.884976027999869],
                [12.556307792000155, -1.866106985999977],
                [12.561779976000139, -1.859199047999937],
                [12.571838379000042, -1.858260035999876],
                [12.590827942000146, -1.864429949999931],
                [12.614400864000118, -1.875424027999941],
                [12.626068116000056, -1.879080056999896],
                [12.650080681000077, -1.882019996999873],
                [12.683674813000039, -1.876664040999856],
                [12.68549823799998, -1.874359965999815],
                [12.722053528000174, -1.864395020999893],
                [12.73325824799997, -1.86483097099989],
                [12.74332714000019, -1.868949054999916],
                [12.763475417000052, -1.88132500699993],
                [12.779511451000133, -1.893707036999899],
                [12.82897472400009, -1.92602300599998],
                [12.841585160000022, -1.939563988999851],
                [12.847337722000134, -1.953119993999962],
                [12.856788635000044, -1.983224986999971],
                [12.864144324000165, -1.997928022999872],
                [12.86944294000017, -2.013556002999849],
                [12.873844146000181, -2.035393953999972],
                [12.875733375000095, -2.058388948999948],
                [12.881966592000083, -2.080452917999878],
                [12.885210037000093, -2.099488019999853],
                [12.9033451090001, -2.122034071999963],
                [12.913217544000133, -2.137190103999956],
                [12.930646895999985, -2.155087947999959],
                [12.966623306000088, -2.180994986999906],
                [12.998681069000156, -2.195175885999959],
                [13.007162094000137, -2.202286004999905],
                [13.025535584000181, -2.229840992999868],
                [13.033831596000027, -2.251672983999811],
                [13.034790038000097, -2.266851901999928],
                [13.026918411999986, -2.314255953999975],
                [13.031063081000184, -2.324595927999894],
                [13.040926933000094, -2.333771942999931],
                [13.047794342000145, -2.336515903999953],
                [13.05717754400007, -2.337412118999907],
                [13.072291375000134, -2.343122958999913],
                [13.088988305000157, -2.342852115999904],
                [13.121731757000077, -2.353807924999899],
                [13.130442620000053, -2.360225914999887],
                [13.15541839600013, -2.373732090999965],
                [13.166412353000169, -2.378763913999933],
                [13.17556476499999, -2.379430054999887],
                [13.18196010700018, -2.376421928999946],
                [13.189031601000124, -2.369271993999973],
                [13.199728012000094, -2.351300954999942],
                [13.209986687000082, -2.339313029999914],
                [13.215930939000032, -2.337915896999959],
                [13.22326278700001, -2.34203600799998],
                [13.231780052000147, -2.359266042999877],
                [13.239811898000028, -2.367753982999886],
                [13.250124931000016, -2.374166965999905],
                [13.263179780000087, -2.379193066999903],
                [13.316273687999967, -2.385943889999908],
                [13.332077979000132, -2.392338989999871],
                [13.336440086000039, -2.396927117999894],
                [13.356147767000039, -2.407222032999925],
                [13.374033927000085, -2.420743940999898],
                [13.384799004, -2.424621104999972],
                [13.391440391, -2.426671981999959],
                [13.39990329699998, -2.426188945999854],
                [13.430522919000055, -2.415285109999957],
                [13.442415237000091, -2.414099931999942],
                [13.453857423000045, -2.414987086999929],
                [13.470807074999982, -2.421144961999971],
                [13.484335899000087, -2.430535077999934],
                [13.492809296000132, -2.43350005099984],
                [13.50309658000009, -2.431169031999957],
                [13.506067277000113, -2.429549931999929],
                [13.53610801700006, -2.38414096799994],
                [13.571192741000061, -2.342164038999897],
                [13.583941459000073, -2.324412106999887],
                [13.591691971000103, -2.316566943999931],
                [13.60925960500009, -2.294848918999946],
                [13.628816604000065, -2.270673988999874],
                [13.638158799000053, -2.261214017999976],
                [13.644985199000189, -2.250611066999966],
                [13.67482566700005, -2.216932057999941],
                [13.691445351000084, -2.1952550399999],
                [13.724718093000092, -2.162252902999967],
                [13.729271889000074, -2.156259059999968],
                [13.74880409200017, -2.120312927999919],
                [13.753583908000053, -2.113856075999934],
                [13.75815486900018, -2.11337995599996],
                [13.77097988100013, -2.118168114999946],
                [13.79047107600013, -2.132359980999979],
                [13.813878060000093, -2.153439997999953],
                [13.83691120200018, -2.196831940999857],
                [13.849785805000067, -2.215728996999928],
                [13.880380630000104, -2.260624885999903],
                [13.886726379000152, -2.304534910999962],
                [13.89224815300014, -2.313257931999885],
                [13.913357735000034, -2.329745054999876],
                [13.916576385000099, -2.333872079999935],
                [13.918901444000028, -2.343755005999981],
                [13.916443824000055, -2.358716964999928],
                [13.904601097000125, -2.372560977999967],
                [13.883179665000114, -2.393569945999957],
                [13.874527930000056, -2.404871939999907],
                [13.870670319, -2.412477015999968],
                [13.868412971000112, -2.420536040999934],
                [13.868673326000078, -2.428817033999962],
                [13.871903419999967, -2.436167000999944],
                [13.885454177000042, -2.45015191899995],
                [13.891647340000077, -2.454037903999961],
                [13.922572136000156, -2.464971065999976],
                [13.93721580499999, -2.464457988999868],
                [13.947727203000056, -2.461200951999956],
                [13.969632149000063, -2.446398018999958],
                [13.977628709000157, -2.443608044999962],
                [13.986778260000051, -2.443115949999935],
                [13.998000146000038, -2.446522951999896],
                [14.018448829000079, -2.468302964999964],
                [14.024641037000094, -2.47172999299994],
                [14.028527259000157, -2.470796108999934],
                [14.044479368999987, -2.455553054999939],
                [14.05019855500018, -2.455533026999945],
                [14.056163789000095, -2.459650991999979],
                [14.069264413000099, -2.474093913999923],
                [14.083021165000048, -2.481632946999866],
                [14.102894784000114, -2.473965882999892],
                [14.119722367000065, -2.4488298899999],
                [14.149820327999976, -2.391139983999949],
                [14.166023254000038, -2.360086916999933],
                [14.178985597000064, -2.342092990999959],
                [14.21363830600012, -2.314126014999943],
                [14.220227242000135, -2.30374693899995],
                [14.221342087000096, -2.296612976999882],
                [14.216944695000052, -2.284900902999937],
                [14.164477349000094, -2.208969114999889],
                [14.15640926399999, -2.194279908999818],
                [14.157983780000052, -2.187603950999971],
                [14.164114952000034, -2.17699909199996],
                [14.174155235000057, -2.1716709129999],
                [14.197225571000047, -2.165369033999923],
                [14.210917473000109, -2.157496927999887],
                [14.215912819000096, -2.149425982999844],
                [14.225195885000062, -2.127079962999915],
                [14.233171463000076, -2.120377063999911],
                [14.244582176000165, -2.114581106999879],
                [14.253233909000016, -2.105577944999936],
                [14.25544452700018, -2.08785796199993],
                [14.246977805000029, -2.033384084999966],
                [14.24737358100009, -2.018662929999891],
                [14.25048255900009, -1.997262],
                [14.257282258000089, -1.983204960999899],
                [14.294162751000044, -1.94603204699996],
                [14.302371978999986, -1.941167950999954],
                [14.304415702000085, -1.937940954999931],
                [14.313302993000093, -1.930775046999884],
                [14.352877618000093, -1.906270025999959],
                [14.386947632000158, -1.885167956999965],
                [14.398568154000088, -1.87569105599988],
                [14.400611877000188, -1.872002959999918],
                [14.403063774000088, -1.858654021999882],
                [14.401856423000083, -1.843711971999937],
                [14.404986382000175, -1.828060983999933],
                [14.41379165700016, -1.803879975999962],
                [14.416766167000048, -1.7532780169999],
                [14.420770644000186, -1.728654979999931],
                [14.426700592000032, -1.719686029999878],
                [14.441952704000073, -1.707285998999907],
                [14.448752403000015, -1.698644994999938],
                [14.451237679000144, -1.692425964999927],
                [14.44753932899999, -1.68324494299992],
                [14.438812257000052, -1.674085020999826],
                [14.421390533000078, -1.662889955999901],
                [14.413838387000112, -1.660853027999963],
                [14.402382850000038, -1.654692053999895],
                [14.391825675000064, -1.644621014999927],
                [14.381245612000157, -1.629948019999915],
                [14.376862526000025, -1.620769977999942],
                [14.374528885000132, -1.609971045999942],
                [14.377006531000177, -1.601683972999922],
                [14.3843002320001, -1.597054958999877],
                [14.410760880000112, -1.585677980999947],
                [14.450209618000088, -1.566200017999904],
                [14.47368812600007, -1.552535056999943],
                [14.480051040000035, -1.544690012999979],
                [14.485257148000187, -1.533630966999965],
                [14.485652924000078, -1.520526049999944],
                [14.48216629100017, -1.507665037999971],
                [14.464203834000102, -1.477162955999972],
                [14.462570190000122, -1.469812988999877],
                [14.466179847000092, -1.459455012999854],
                [14.47186660799997, -1.453681944999914],
                [14.49328231700008, -1.439337015999911],
                [14.498508454000046, -1.432878017999883],
                [14.501670838000052, -1.425276993999887],
                [14.500937461000035, -1.414703963999955],
                [14.495647430000133, -1.406911015999867],
                [14.467678069999977, -1.386569975999976],
                [14.457348823000018, -1.376037954999845],
                [14.452271462000112, -1.365028023999969],
                [14.453075408000018, -1.340885042999958],
                [14.459623337000096, -1.323616027999947],
                [14.472783089000075, -1.303562045999968],
                [14.482973099000105, -1.283519028999933],
                [14.485608100000093, -1.260061979999932],
                [14.480708123000113, -1.237324951999881],
                [14.470749856000111, -1.207947015999935],
                [14.465164184000173, -1.184985041999937],
                [14.46366882400008, -1.178452967999931],
                [14.447578430000135, -1.108101008999938],
                [14.43144226100003, -1.037559030999944],
                [14.422990798000058, -1.000607966999951],
                [14.422028541000088, -0.984574019999968],
                [14.419535636000148, -0.942941008999867],
                [14.410247803000061, -0.908969997999975],
                [14.415636063000022, -0.888037025999949],
                [14.430406571000162, -0.872577011999908],
                [14.440822601000093, -0.852544009999974],
                [14.45862960800008, -0.801229],
                [14.464839934000167, -0.761220990999902],
                [14.470913886, -0.741208015999916],
                [14.486551284000143, -0.716095983999878],
                [14.49872016900008, -0.680893003999927],
                [14.499524116999964, -0.657226024999886],
                [14.497133255000051, -0.633114992999879],
                [14.492943764000074, -0.615900992999968],
                [14.48510169900004, -0.598016976999929],
                [14.482548714000075, -0.588837981999859],
                [14.463206290000187, -0.551707028999942],
                [14.456320763000065, -0.543237983999973],
                [14.42964840000002, -0.518217026999878],
                [14.412258148000035, -0.49058899399995],
                [14.400787353000112, -0.477086006999912],
                [14.386141776999978, -0.46864798699994],
                [14.36488247, -0.460927007999885],
                [14.353691101000152, -0.459134995999932],
                [14.326305390000073, -0.459477006999919],
                [14.314429283000038, -0.45768800299993],
                [14.265050887000086, -0.439054012999975],
                [14.252927779000117, -0.432900010999958],
                [14.228965759000062, -0.432765989999893],
                [14.19202995300003, -0.441637991999869],
                [14.18290424300011, -0.442133008999974],
                [14.17673015600002, -0.439633010999898],
                [14.173010827000041, -0.42310899499995],
                [14.17230415400013, -0.363395004999973],
                [14.171389581000028, -0.363400011999943],
                [14.16811466200005, -0.344121008999934],
                [14.162807464000025, -0.329901009999958],
                [14.150184632000105, -0.312498001999927],
                [14.119737625000084, -0.289189009999916],
                [14.10098266599999, -0.278237998999941],
                [14.082617759000073, -0.269248992999962],
                [14.049769402000095, -0.253170012999817],
                [14.044504165000092, -0.248827992999907],
                [14.022788048, -0.23880399799998],
                [14.01866722200009, -0.235604001999889],
                [13.984849930000166, -0.22309899399994],
                [13.971152305000032, -0.221312001999877],
                [13.96089363200008, -0.22318500199998],
                [13.953620911000087, -0.230561002999821],
                [13.94615936200006, -0.24826900599993],
                [13.93955040000003, -0.25013199499989],
                [13.926512718000026, -0.242598995999913],
                [13.904769897000051, -0.224537],
                [13.895365714000093, -0.212170003999859],
                [13.886833191999983, -0.188088996999909],
                [13.885629652999967, -0.172021000999962],
                [13.88763141700008, -0.158464997999943],
                [13.893280984000057, -0.144438995999906],
                [13.909185408999974, -0.12716299199991],
                [13.915300368000146, -0.116117998999812],
                [13.926809310000067, -0.083476996999934],
                [13.930843354000046, -0.064632996999876],
                [13.934823036000182, -0.032248000999971],
                [13.933109283000078, -0.00241],
                [13.93354034500004, 0.000001000000168],
                [13.949209211999971, 0.004375],
                [13.953517913999974, 0.021369999000115],
                [13.958520889000113, 0.03079],
                [13.95850658500018, 0.041352],
                [13.953937531000179, 0.045706999000117],
                [13.941159248000076, 0.048675000000117],
                [13.934316634000027, 0.047975001000168],
                [13.928844453000124, 0.044752002000052],
                [13.923818588000074, 0.05048599800017],
                [13.921747208000113, 0.061732002000042],
                [13.921730995000075, 0.072984002000055],
                [13.914871216000165, 0.084224001000052],
                [13.914633752000043, 0.090651996000133],
                [13.923282622000102, 0.10283599800016],
                [13.927821160000121, 0.120752],
                [13.926435470000058, 0.131311],
                [13.9170484550001, 0.154489995000063],
                [13.912245750000181, 0.161831008000036],
                [13.905389786000057, 0.168248996000102],
                [13.906060218000107, 0.178127007000171],
                [13.89736652300013, 0.192579],
                [13.888682366000069, 0.202671007000049],
                [13.900766371999964, 0.210036993000074],
                [13.894366265000087, 0.215538994000099],
                [13.893674850000139, 0.22036199199999],
                [13.896401405000063, 0.228403003000153],
                [13.895934106000027, 0.235290006000071],
                [13.897754669000108, 0.238049999000054],
                [13.91257381500003, 0.24610899400011],
                [13.921234131000176, 0.253702999000041],
                [13.926009178000129, 0.26427400200015],
                [13.933995246999984, 0.26428499900004],
                [13.941976548000184, 0.267973006000091],
                [13.936027527000135, 0.278070003000039],
                [13.93579578400005, 0.282662004000088],
                [13.933735847000037, 0.285872995000148],
                [13.93487262800005, 0.288630992000151],
                [13.94491005000009, 0.2897930150001],
                [13.937829019000105, 0.296443998000029],
                [13.949221610000109, 0.307256013000142],
                [13.942140579000181, 0.310690999000087],
                [13.941678047000096, 0.315741002000038],
                [13.947825432000059, 0.325397998000085],
                [13.957404136000036, 0.329315990000055],
                [13.961502076000158, 0.336670994000031],
                [13.967434884000056, 0.336450011000125],
                [13.97129344900003, 0.350926995000179],
                [13.977667808000092, 0.362190008000141],
                [13.982682228000044, 0.365182995000055],
                [13.989515304000179, 0.376217992000136],
                [14.010264396000139, 0.388191015000189],
                [14.022584915000152, 0.390964001000157],
                [14.038085937, 0.404536009000083],
                [14.05131816900007, 0.407081991000041],
                [14.0602073660001, 0.416281014000106],
                [14.066365242000131, 0.419735997000089],
                [14.073198318999971, 0.429850997000131],
                [14.07454490800012, 0.44731101400015],
                [14.085008620999986, 0.461542011000176],
                [14.091638566000142, 0.46662700100012],
                [14.093004228000154, 0.469386995000036],
                [14.09254074100005, 0.474440009000148],
                [14.085684776999983, 0.479943990000152],
                [14.081117631000097, 0.481775999000149],
                [14.072207451000054, 0.489573001999986],
                [14.07083225200006, 0.493936002000055],
                [14.082666398000129, 0.521287024000173],
                [14.10659408500004, 0.549805999000057],
                [14.119593621000035, 0.558323026000153],
                [14.136945724000043, 0.557653010000138],
                [14.152012825000156, 0.555145979000088],
                [14.169598578999967, 0.548735024000166],
                [14.178732872000182, 0.543003024000029],
                [14.183071136000137, 0.543237985000189],
                [14.198813438000059, 0.55083602600007],
                [14.209541321000131, 0.549471021000045],
                [14.215486527000166, 0.540288984000142],
                [14.222796440000081, 0.536391020000053],
                [14.234893799000133, 0.536634983000056],
                [14.257932664000066, 0.548376978000022],
                [14.280994415000123, 0.543577016000086],
                [14.289212227000064, 0.543816984000046],
                [14.28349208899999, 0.555756987000052],
                [14.29374790200012, 0.570011021000084],
                [14.29351329800005, 0.57690298600005],
                [14.295107841000117, 0.580350995000117],
                [14.310846328000139, 0.591624021000086],
                [14.32248783100016, 0.593014000999972],
                [14.338907241000129, 0.610029995000161],
                [14.349862099000063, 0.610732020000057],
                [14.358302117000108, 0.617173016999971],
                [14.365594864000172, 0.629127026000106],
                [14.38453483600017, 0.637416005000148],
                [14.37058925500014, 0.661297024000135],
                [14.359619141, 0.672545017000061],
                [14.368041038000058, 0.698514999000167],
                [14.37853813200013, 0.70312100700005],
                [14.38811111400014, 0.721282006000024],
                [14.392674445000125, 0.724272012000142],
                [14.39335727700012, 0.727259994000065],
                [14.402029991000063, 0.730255006999982],
                [14.415262223000127, 0.742215990000147],
                [14.414565086000096, 0.752554001000135],
                [14.43098640500017, 0.775318026000036],
                [14.438515662999976, 0.780380011000091],
                [14.461113930000067, 0.786831976000087],
                [14.464080810999974, 0.789362013000073],
                [14.472737312000106, 0.812807024000051],
                [14.485060692000104, 0.819943011000078],
                [14.488712310000096, 0.825231016000032],
                [14.485505105000186, 0.835339010000041],
                [14.483668327000089, 0.848664999000164],
                [14.477033615000039, 0.862906992000035],
                [14.47109413200019, 0.864740014000176],
                [14.465838433000101, 0.870711028000187],
                [14.465377806999982, 0.874387026000079],
                [14.48775482300016, 0.876704991000111],
                [14.4916343700001, 0.879922986000167],
                [14.493225097000163, 0.8904960160001],
                [14.500068665000128, 0.898545981000154],
                [14.502346039000088, 0.905901014000051],
                [14.497989655000026, 0.92864900800015],
                [14.493420601000025, 0.932552993000115],
                [14.485577583000122, 0.933382988000176],
                [14.477182388000188, 0.937708021000162],
                [14.467617989000075, 0.951057017000153],
                [14.451645850999967, 0.959365011000102],
                [14.445253372000138, 0.960527004000028],
                [14.437965393000127, 0.969964982000079],
                [14.43229198400013, 0.989275991000113],
                [14.424094200000013, 1.001471996000134],
                [14.420242309000116, 1.016415954000081],
                [14.41369915000007, 1.026447059000077],
                [14.41228294400014, 1.035274030000096],
                [14.399765968000054, 1.057361007000111],
                [14.399086953000108, 1.061728],
                [14.401846885000054, 1.071830988000045],
                [14.398672104000184, 1.084015012000066],
                [14.39868640899999, 1.089761019000036],
                [14.396410943000035, 1.093672037000147],
                [14.38728428000013, 1.099436044000015],
                [14.37178993200007, 1.120839000000103],
                [14.346710206000125, 1.145710945000189],
                [14.344896316000131, 1.153759002000186],
                [14.343799590000174, 1.177430034000167],
                [14.335367202000043, 1.188248991000137],
                [14.329001426000048, 1.204118967000113],
                [14.323985099000026, 1.21033394300008],
                [14.310290337000083, 1.217257978000021],
                [14.309164047000138, 1.226451994000115],
                [14.300945281999986, 1.228767992000087],
                [14.296156883000037, 1.233603954000159],
                [14.295025825000039, 1.241189004000148],
                [14.297554015000173, 1.249227047999966],
                [14.303972243999965, 1.260704994000037],
                [14.30558776900017, 1.269662023000137],
                [14.303557396000144, 1.283225059000017],
                [14.30677509300017, 1.294250011000088],
                [14.305415154000173, 1.299769997000112],
                [14.303814889000023, 1.299541951000094],
                [14.296743392000167, 1.305302978000043],
                [14.287641526000073, 1.326694966000105],
                [14.278985978000094, 1.341192960000058],
                [14.271682740000188, 1.346264004999966],
                [14.258894920999978, 1.351119041000061],
                [14.251597404000051, 1.359174968000048],
                [14.247260094000012, 1.361484051000048],
                [14.237207413000021, 1.362195015000054],
                [14.230134964000115, 1.369564057000162],
                [14.22533988900011, 1.370952010000167],
                [14.216883660000065, 1.370049],
                [14.205465317000062, 1.37466895599999],
                [14.197939873000166, 1.382498026000121],
                [14.19110202700017, 1.393314005000036],
                [14.18722057299999, 1.395620943000154],
                [14.17533779100006, 1.394955039000081],
                [14.170305252000105, 1.391288043000088],
                [14.160021783000047, 1.391536952000024],
                [14.144489288000159, 1.39501500199998],
                [14.129633904000059, 1.394814015000179],
                [14.125289918000021, 1.392524003000062],
                [14.118889808000176, 1.391847014000064],
                [14.109274864999975, 1.380143046000171],
                [14.103097916000081, 1.375558018000049],
                [14.083450318000132, 1.378811956000163],
                [14.074538230000087, 1.378828049000163],
                [14.066542625000125, 1.380682946000036],
                [14.058551788000045, 1.385064006000164],
                [14.04988670300014, 1.397492051000029],
                [14.042577743000038, 1.398651958000187],
                [14.030255317000126, 1.410397053000054],
                [14.028661727999975, 1.413619042000107],
                [14.030270576000078, 1.421429992000128],
                [14.02845192, 1.427638054000056],
                [14.02457523400011, 1.431324006000068],
                [14.017722130000095, 1.433863045000066],
                [14.011556626000129, 1.438241005000123],
                [14.009265899000184, 1.434108972000104],
                [14.004464149000114, 1.432047963000173],
                [13.999420165, 1.420106053000097],
                [13.99598789200013, 1.418272972000068],
                [13.983418465000113, 1.416226984000048],
                [13.977706910000165, 1.417845012000157],
                [13.97200012200011, 1.421993017000091],
                [13.966515541000092, 1.422232033000171],
                [13.948914528000103, 1.417665004000185],
                [13.926289558000065, 1.417243004000113],
                [13.896828652000124, 1.432230949000029],
                [13.892031670000051, 1.432700038000064],
                [13.875802040000167, 1.429507016000173],
                [13.866428375000055, 1.425385953000159],
                [13.861858369000117, 1.425850987000104],
                [13.856384277000132, 1.43367600400012],
                [13.850669860000096, 1.433915020000029],
                [13.839923859000123, 1.428645968000183],
                [13.834210396000174, 1.427736043000095],
                [13.824614524000083, 1.430737972000031],
                [13.817070960000137, 1.427760959000011],
                [13.813871383000162, 1.428683997000178],
                [13.812738419000027, 1.43558299599999],
                [13.810227394000037, 1.437654973000122],
                [13.805885316000115, 1.43697094900017],
                [13.801992416000076, 1.430312039000057],
                [13.800839425000163, 1.422500968000122],
                [13.802436829000158, 1.420428991000165],
                [13.808382988000176, 1.423637986000017],
                [13.807687760000022, 1.417204022000078],
                [13.810422896000034, 1.411911964000069],
                [13.807217598000022, 1.407549977000031],
                [13.799678803, 1.408022047000031],
                [13.79670238600005, 1.404119969000078],
                [13.797154427000066, 1.398831964000181],
                [13.794178963000093, 1.395619036000085],
                [13.790980340000033, 1.394243956000139],
                [13.785725594000098, 1.397011041000042],
                [13.783663750000187, 1.391037941000036],
                [13.789133071000037, 1.379997016000175],
                [13.788893700000187, 1.371036054000172],
                [13.785460472000182, 1.366212965000159],
                [13.778598785000042, 1.361397027000123],
                [13.76808834000002, 1.360725046000027],
                [13.765343666000149, 1.358659984000042],
                [13.762365342000123, 1.352458953000109],
                [13.759166717000028, 1.352923990000079],
                [13.754373550000025, 1.359133959000133],
                [13.750948907000065, 1.360980035000068],
                [13.742495536000092, 1.360530972000163],
                [13.741811751000057, 1.362370968000164],
                [13.745242119000068, 1.366271973000096],
                [13.74433231400019, 1.368113994000169],
                [13.739079476000029, 1.370417952000025],
                [13.731309891000137, 1.36996901100008],
                [13.727423667000096, 1.368366956000102],
                [13.725356102000035, 1.361704946000089],
                [13.70981311900016, 1.354603051000026],
                [13.70775222800006, 1.350926996000055],
                [13.701807976000111, 1.34978699800007],
                [13.69404125300008, 1.349799035999979],
                [13.689933777000078, 1.354861021000033],
                [13.687641143000064, 1.348428964000163],
                [13.683301927000059, 1.349352003000035],
                [13.678736686000093, 1.353955984000038],
                [13.665022850000128, 1.352594972000077],
                [13.654734611000038, 1.345484019000139],
                [13.64765357900012, 1.349401950000186],
                [13.639196395000113, 1.3450469980001],
                [13.633481980000056, 1.344135046000133],
                [13.63256359200011, 1.341146945999981],
                [13.63438034000012, 1.329195976000051],
                [13.629355431000135, 1.330350995000174],
                [13.608331681000038, 1.329457998000066],
                [13.604210853000154, 1.320500971000172],
                [13.59072589900012, 1.316843034000101],
                [13.59048938799998, 1.30741799000009],
                [13.58843135899997, 1.304432988000031],
                [13.577684403000035, 1.298470021000185],
                [13.576078414999984, 1.290429949000043],
                [13.573335647000079, 1.289283038000065],
                [13.55664634700014, 1.278733016000047],
                [13.552076340000099, 1.278280020000068],
                [13.547511101000111, 1.282881023000186],
                [13.541112899000098, 1.284039020000137],
                [13.524428367000041, 1.276013971000054],
                [13.513003349000087, 1.2737300390001],
                [13.511628150000092, 1.271662950000064],
                [13.51528167600003, 1.267292022000049],
                [13.514365196000085, 1.265915035999967],
                [13.51025390600006, 1.266610026000137],
                [13.500892639000085, 1.275123000000178],
                [13.496095657000012, 1.275357963000147],
                [13.494722365000086, 1.269155980000107],
                [13.48855209400017, 1.270081998000137],
                [13.482156754000187, 1.27376496800008],
                [13.47735881900013, 1.274459958000023],
                [13.475079537, 1.279978037000149],
                [13.47005367200012, 1.280673027000091],
                [13.464800835000062, 1.284355999000184],
                [13.457719804000135, 1.285972],
                [13.446301461000132, 1.294028045],
                [13.442647934999968, 1.294721008000067],
                [13.438531875000137, 1.29288697200019],
                [13.436711312000057, 1.300243974000182],
                [13.43145465800012, 1.299559952000038],
                [13.422310829000025, 1.295202018000055],
                [13.413860321000186, 1.297510981000073],
                [13.409742356000095, 1.291082025000151],
                [13.40220165200003, 1.290168047],
                [13.40013980800012, 1.283735990000139],
                [13.397625923000021, 1.282359004000057],
                [13.384148599000127, 1.286739946000182],
                [13.377754212000127, 1.291113019000079],
                [13.375012398000138, 1.290886045000093],
                [13.37112426800013, 1.285601973000041],
                [13.36609363499997, 1.279173017000176],
                [13.366319656000144, 1.275727033000123],
                [13.373170852000158, 1.270434975000114],
                [13.37454032900007, 1.267675043000054],
                [13.3724823, 1.26537704500015],
                [13.351920128000074, 1.266088963000073],
                [13.346659660000057, 1.258507968000174],
                [13.343002318000117, 1.257593990000032],
                [13.340033532000177, 1.258056999000075],
                [13.335008621000156, 1.262886048000041],
                [13.321986198000104, 1.262209059000043],
                [13.321976661000122, 1.249111056000174],
                [13.325402261000079, 1.243132950000074],
                [13.324939727000185, 1.238077998000165],
                [13.323568345000012, 1.235780956000156],
                [13.31466102500002, 1.23946595100017],
                [13.313282013000162, 1.225219011000149],
                [13.309393883000155, 1.221086980000109],
                [13.305740357000047, 1.221318961],
                [13.299574853000081, 1.229827047000185],
                [13.29317760399999, 1.227535009000121],
                [13.287466049000045, 1.229609014000175],
                [13.278097152000157, 1.224791051000068],
                [13.274668694000127, 1.224794984000141],
                [13.271243096000035, 1.227555038000048],
                [13.265303612000139, 1.227789999000038],
                [13.260959626000044, 1.223196984000083],
                [13.25798988400004, 1.223889946000043],
                [13.255480767000165, 1.228256941000154],
                [13.242911338000113, 1.223901987000033],
                [13.24108600500017, 1.224591971000109],
                [13.23651599800013, 1.225975991000041],
                [13.226463316999968, 1.225293994000026],
                [13.21823978399999, 1.226909995000085],
                [13.195616722000068, 1.2179650060001],
                [13.189452171000084, 1.224632978000045],
                [13.181687354000076, 1.228546977000178],
                [13.167752266000093, 1.233152985000061],
                [13.15632915600014, 1.234310984000047],
                [13.156331063000039, 1.239596010000184],
                [13.167986870000163, 1.250617981000062],
                [13.171187400000122, 1.251306058000068],
                [13.175525665000123, 1.24693703600002],
                [13.178954124000029, 1.247393012000089],
                [13.180554391000044, 1.25106895000016],
                [13.177130698000155, 1.259804964000182],
                [13.178275109000083, 1.2641689780001],
                [13.18307495200014, 1.268074036000087],
                [13.183991432000084, 1.271980047000056],
                [13.18285369900019, 1.280022979000137],
                [13.184911728000031, 1.286914944000102],
                [13.188799858000039, 1.292428018000067],
                [13.192914010000095, 1.293805002000113],
                [13.194512368, 1.289206029000184],
                [13.197707177000098, 1.286906004000059],
                [13.21393203800011, 1.291262031000144],
                [13.22032737700016, 1.285279034999974],
                [13.223980903000097, 1.285045982000156],
                [13.229697227000031, 1.290099026000121],
                [13.229018212000085, 1.300439953000137],
                [13.231304169000111, 1.303655982],
                [13.23998737300002, 1.305027963000157],
                [13.25735855, 1.316045047000046],
                [13.26399612400013, 1.336493015000144],
                [13.26308441100008, 1.34063005400003],
                [13.261713982000117, 1.342010976000097],
                [13.257599831000164, 1.341783048000025],
                [13.254172326000173, 1.338109015999976],
                [13.250287056000047, 1.336735011000087],
                [13.245489121000162, 1.340185046000158],
                [13.243893624000066, 1.344782949000035],
                [13.24481010400001, 1.348919035000108],
                [13.254181861000177, 1.356495022000161],
                [13.253955841000106, 1.358795047000115],
                [13.247330665000106, 1.364544987000159],
                [13.246418952999989, 1.367532969000138],
                [13.257171631000062, 1.388900041000056],
                [13.25625801100017, 1.392348052000159],
                [13.249400139000102, 1.391664028000037],
                [13.249178887000085, 1.396489024000118],
                [13.252611160000129, 1.405451059000086],
                [13.248957634000021, 1.411198973000126],
                [13.243701935000104, 1.41120302600001],
                [13.241876602000104, 1.415802956000164],
                [13.237535476000119, 1.418565036000075],
                [13.236622811000188, 1.422013045000142],
                [13.238910675000113, 1.42775702400013],
                [13.234118461000094, 1.438333988000068],
                [13.228864670000178, 1.443393947000118],
                [13.22657680500015, 1.439257026000064],
                [13.221550942000079, 1.443858981000062],
                [13.221325874000058, 1.447998047000056],
                [13.223383903000126, 1.451444029000072],
                [13.221101761000114, 1.456501008000089],
                [13.223159790000125, 1.460176946000104],
                [13.222703934000037, 1.462017060000107],
                [13.21790504400019, 1.462939978000122],
                [13.21630668600011, 1.46570003000005],
                [13.220194816000117, 1.470752954000147],
                [13.220652580000149, 1.473971010000128],
                [13.217913627000087, 1.479257942000061],
                [13.213802337000118, 1.482020974000022],
                [13.208087921000185, 1.481104016000188],
                [13.20603466000017, 1.490071059000059],
                [13.200093269000035, 1.487316965000105],
                [13.194834710000066, 1.487552046000076],
                [13.193921090000117, 1.489621998000132],
                [13.198497772000053, 1.495133042000134],
                [13.19918441700014, 1.498119951000092],
                [13.198269844000038, 1.500419974000067],
                [13.192784309000103, 1.498355032000063],
                [13.195302010000148, 1.508927942000014],
                [13.198277474000122, 1.512141943000131],
                [13.194621085000051, 1.515364052000166],
                [13.191649436000091, 1.515367032000086],
                [13.190055846000064, 1.524099946000035],
                [13.183885575000147, 1.527552963000176],
                [13.178632736000111, 1.535833001000128],
                [13.175433159000136, 1.535146951],
                [13.172917366000092, 1.532158971000058],
                [13.172917366000092, 1.529631973000051],
                [13.169259072000102, 1.529863954000177],
                [13.16377067500008, 1.525500059000137],
                [13.159202576000041, 1.530331969999963],
                [13.152124404000062, 1.543439030000172],
                [13.15441226900009, 1.549643040000149],
                [13.153725624, 1.551481962000025],
                [13.148241044000088, 1.551025988000163],
                [13.145273208000049, 1.555166960000122],
                [13.144589423000014, 1.559991956000033],
                [13.139102936000029, 1.559077978000062],
                [13.137275696000188, 1.561378003000016],
                [13.139561653000044, 1.56344497200007],
                [13.141163825000092, 1.570109010000067],
                [13.140709877, 1.577466012000059],
                [13.138198854000166, 1.581604958000014],
                [13.133171081000114, 1.583217024000021],
                [13.130888939000101, 1.586897016000023],
                [13.132259368000064, 1.591034055000137],
                [13.136601449000068, 1.589650988000187],
                [13.141403199000024, 1.591027022000162],
                [13.145515441999976, 1.58941602699997],
                [13.145975113000077, 1.59240400900012],
                [13.14277458100014, 1.595394015000068],
                [13.14209079800014, 1.59815204099999],
                [13.1455240250001, 1.606884956000044],
                [13.143239975000085, 1.610566020000135],
                [13.145070076000081, 1.61584901800012],
                [13.149184226000159, 1.617455961000132],
                [13.151017189000186, 1.624811053000087],
                [13.149190903999965, 1.628950000000145],
                [13.14621925400013, 1.63125205],
                [13.147134780000044, 1.633779048],
                [13.150793076000014, 1.634925962000068],
                [13.150337220000154, 1.64113402300012],
                [13.145310402000064, 1.640676021000047],
                [13.140057563000028, 1.65286195300007],
                [13.142344475000073, 1.658149958000138],
                [13.14006137900003, 1.663437963000092],
                [13.143036843000175, 1.67263197900013],
                [13.139382362000049, 1.677000999000143],
                [13.13984203300015, 1.684128047000115],
                [13.136416435000058, 1.691946984000026],
                [13.138705253000069, 1.697229981000078],
                [13.137106895000159, 1.701830030000053],
                [13.145111085, 1.70849204100017],
                [13.152660369000102, 1.721359969000105],
                [13.157690047000017, 1.723196983000094],
                [13.158834458000115, 1.725723982000147],
                [13.156550406000179, 1.732851028000084],
                [13.159069062000185, 1.741814971000053],
                [13.171417236000138, 1.745713949000106],
                [13.175074577000146, 1.751000046000058],
                [13.180335046000096, 1.753983021000181],
                [13.182395935000159, 1.756971001000181],
                [13.18148231500004, 1.762027980000141],
                [13.182399750000059, 1.769155027000181],
                [13.179203033000135, 1.773524046000091],
                [13.182407380000143, 1.783409000000177],
                [13.181726456000092, 1.791226029000086],
                [13.183097840000073, 1.79398405500018],
                [13.186298370000031, 1.793290973000069],
                [13.188352583999972, 1.788462044000085],
                [13.195672035000143, 1.791445016000182],
                [13.198645591000115, 1.796270013000139],
                [13.199106216000132, 1.801787972999989],
                [13.201395035000075, 1.806843043000129],
                [13.200360299000067, 1.809453010000141],
                [13.197081566000122, 1.812005042000067],
                [13.195454597000094, 1.816504003000091],
                [13.192267417000153, 1.817103981000173],
                [13.190426826000191, 1.823174953000148],
                [13.195918083000038, 1.829836965000141],
                [13.195006371000147, 1.832597017000069],
                [13.193634034000183, 1.834898950000024],
                [13.193870545000095, 1.849153043000172],
                [13.19204330399998, 1.853752018000137],
                [13.187243462000083, 1.857892990000096],
                [13.172839165000084, 1.858365059000164],
                [13.170781136000073, 1.861354947000109],
                [13.172158240000158, 1.865491986000166],
                [13.17170238400007, 1.869400979000034],
                [13.16324424700008, 1.872856021000132],
                [13.159585953000033, 1.875617981000175],
                [13.157532692000075, 1.881826997000189],
                [13.160735130000035, 1.8866519930001],
                [13.158223152000176, 1.893319965000103],
                [13.161886216000028, 1.901365995000049],
                [13.163485526000045, 1.901823998000168],
                [13.167601586000046, 1.902742029000024],
                [13.172632217, 1.900897980000138],
                [13.181322097000134, 1.907099963000178],
                [13.18475532499997, 1.910545945000024],
                [13.182927132000145, 1.914685011000188],
                [13.183842660000039, 1.91790294700013],
                [13.187732697000115, 1.921810030000017],
                [13.190937995000127, 1.928933979000021],
                [13.193911552000031, 1.931920051000134],
                [13.201228142000048, 1.93375396700003],
                [13.204204559, 1.938120962000141],
                [13.207405089000133, 1.938807011000108],
                [13.216558456000143, 1.946388007000167],
                [13.216786383999988, 1.949836016000063],
                [13.208789826000043, 1.965477944000043],
                [13.2071952820001, 1.975134016000141],
                [13.209712982000042, 1.981801034000057],
                [13.217720032000159, 1.982944965000115],
                [13.218638420000104, 1.987771988000077],
                [13.224813462000157, 1.991215945000022],
                [13.229617120000057, 1.996271014000058],
                [13.232818603, 1.996498942000073],
                [13.235107422000112, 1.998335957999984],
                [13.236025810000115, 2.005002976000071],
                [13.239912033000053, 2.007299901000124],
                [13.237400055000023, 2.010981083000104],
                [13.246322633000034, 2.020400047000123],
                [13.250897407000025, 2.019476891000124],
                [13.2541017530001, 2.024763107000183],
                [13.263019563000057, 2.023375034000139],
                [13.268965720999972, 2.02567005200018],
                [13.273768424000082, 2.02497506200001],
                [13.277891160000138, 2.035089016000029],
                [13.286583900000153, 2.03921890200013],
                [13.288643836000062, 2.043355943000051],
                [13.28110313500008, 2.050950051000086],
                [13.281332969000061, 2.054169893000051],
                [13.28316307100016, 2.05462789600017],
                [13.286819458000082, 2.050945044000116],
                [13.289793014999987, 2.050251961000072],
                [13.290025711000055, 2.05715108000004],
                [13.294371605000151, 2.061285973000167],
                [13.295517921000112, 2.064733982000064],
                [13.29346179900017, 2.068185092000135],
                [13.295294762000026, 2.075081110000156],
                [13.293472290000068, 2.084052085000167],
                [13.29438972500003, 2.088649035],
                [13.297364235000089, 2.091634988000067],
                [13.295994758000063, 2.093936921000022],
                [13.299427031999983, 2.098073007000153],
                [13.297826766000128, 2.100832939000043],
                [13.29895687200019, 2.104372978000129],
                [13.298290253000175, 2.108190059000037],
                [13.293714524000052, 2.108195066000178],
                [13.290975570000057, 2.118314028000043],
                [13.291436195000074, 2.121993065000083],
                [13.29486942300008, 2.125669003000098],
                [13.294412614000066, 2.128890038000066],
                [13.287326812000174, 2.133263111000133],
                [13.291904449000072, 2.139008998000122],
                [13.29716396300006, 2.139235021000104],
                [13.298995971000124, 2.142910958000186],
                [13.293972968000105, 2.154182911000021],
                [13.29122924699999, 2.156485082000131],
                [13.291687011000022, 2.15901494000002],
                [13.296032905000118, 2.159471034000092],
                [13.29809379600016, 2.161767958999974],
                [13.299013137000145, 2.170983553999974],
                [13.295819282000082, 2.17096972500002],
                [13.29315498200009, 2.1713498740001],
                [13.295624733000068, 2.174606323000148],
                [13.297618866000107, 2.177671194000027],
                [13.297426223000116, 2.179680824000172],
                [13.295902251000143, 2.181593418000148],
                [13.291047096000113, 2.184554577000142],
                [13.28819275, 2.184647084000062],
                [13.283910999000057, 2.185311999000078],
                [13.283718110000166, 2.187800407000111],
                [13.284190177000085, 2.191054822000183],
                [13.285138129000131, 2.193927049000024],
                [13.287419319000094, 2.19555664000012],
                [13.291033877000189, 2.196613919000129],
                [13.295498001000055, 2.202361001000099],
                [13.299108999000055, 2.206672001000129],
                [13.303197701000158, 2.20772972900005],
                [13.295292855000127, 2.215281487000141],
                [13.29414844400003, 2.218151331000115],
                [13.295000076000179, 2.221884966000062],
                [13.29509162800008, 2.225521804000039],
                [13.291855812000051, 2.226283790000025],
                [13.28899765, 2.229343176000043],
                [13.286995889000025, 2.232881784000085],
                [13.284608842000125, 2.240439891000051],
                [13.284029007000072, 2.248191357000167],
                [13.280892999000116, 2.244837998999969],
                [13.275380135000148, 2.240333318000012],
                [13.271671296000136, 2.238893271000165],
                [13.269765854000013, 2.241187811000145],
                [13.268906594000157, 2.243962288000091],
                [13.270614624000075, 2.248079776000054],
                [13.2667140960001, 2.247596503000125],
                [13.264809609, 2.248742820000018],
                [13.259478, 2.252660001000038],
                [13.259283085000163, 2.25581805000013],
                [13.262515977000135, 2.257736181000155],
                [13.261276246000136, 2.260510206000049],
                [13.259085656000082, 2.262134551999964],
                [13.255373955000039, 2.262895823000179],
                [13.252990722999982, 2.26739096600005],
                [13.24841976099998, 2.270735025000079],
                [13.241860389000124, 2.266420366000034],
                [13.235201837000034, 2.265072345000135],
                [13.233589171000176, 2.261625051000181],
                [13.231308937000165, 2.259325504000117],
                [13.22712230600007, 2.25912880900006],
                [13.224555015000021, 2.257977248000088],
                [13.222275734000164, 2.255103350000184],
                [13.220562936000022, 2.255292654000186],
                [13.21589660699999, 2.259306430000095],
                [13.21285152400003, 2.259972573000084],
                [13.210000038000032, 2.25757646600016],
                [13.206956863000187, 2.256807090000109],
                [13.203530312000112, 2.258046865999972],
                [13.201812744999984, 2.261777162999977],
                [13.202758789000143, 2.266372204000163],
                [13.200382233000141, 2.264646530000164],
                [13.198387147000062, 2.262729883000134],
                [13.194774627000129, 2.260428667000156],
                [13.191634178000072, 2.260807515000067],
                [13.190394401999981, 2.263581275000036],
                [13.189913750000187, 2.267887354000095],
                [13.186202048000041, 2.268743992000111],
                [13.184200287000067, 2.271516800000029],
                [13.184863090000079, 2.274292947000106],
                [13.188475610000069, 2.27640295100008],
                [13.192661286000146, 2.276599645000033],
                [13.192276955000068, 2.279470205000052],
                [13.184477805000085, 2.27850318000003],
                [13.181911469000056, 2.276585817000125],
                [13.178203583000027, 2.274667262000094],
                [13.174680711000065, 2.27628970100011],
                [13.171539306000113, 2.278295280000066],
                [13.16601944000007, 2.279723882000155],
                [13.160308837000173, 2.281343460000016],
                [13.155840873000159, 2.278849603000026],
                [13.149560929000131, 2.279415607000146],
                [13.140527725000027, 2.276437283000064],
                [13.131589889, 2.272406339000156],
                [13.124078750000024, 2.268951417000096],
                [13.11685085300013, 2.267314910000152],
                [13.118567466000172, 2.264255047],
                [13.117905618000123, 2.261000394000121],
                [13.11572170300019, 2.258030892000079],
                [13.11772728000011, 2.251334666999981],
                [13.114876746000164, 2.248842717000059],
                [13.111451148000071, 2.249508143000014],
                [13.109164238000176, 2.252376318000131],
                [13.106122971000161, 2.250745296000162],
                [13.10165119100003, 2.250835180000138],
                [13.101370811000095, 2.247293949000152],
                [13.099471092000044, 2.244899272000168],
                [13.09671402000015, 2.243555784000023],
                [13.093482971000071, 2.240680695000094],
                [13.087685584000099, 2.236653806000049],
                [13.085878372000138, 2.236651421000147],
                [13.082449914000165, 2.239709139000183],
                [13.075593948000062, 2.244293451000033],
                [13.074348449000126, 2.248036147000107],
                [13.071674347000055, 2.245602370000086],
                [13.068650245000129, 2.243969679000088],
                [13.065341950000061, 2.244107008000185],
                [13.064218521999976, 2.241910457000074],
                [13.061476708000157, 2.239145280000173],
                [13.058100700000068, 2.237299919000122],
                [13.054088592000085, 2.237152814000126],
                [13.050359725000021, 2.235873462000143],
                [13.046488761000035, 2.235655784000073],
                [13.042899131000013, 2.23621726000016],
                [13.04043579000006, 2.235506057000066],
                [13.0362854010001, 2.23380112699999],
                [13.033537864000039, 2.235992432000046],
                [13.033602714999972, 2.240099192],
                [13.032541275000085, 2.244133711000131],
                [13.030363083000168, 2.241369247000023],
                [13.027054786000122, 2.241364717000181],
                [13.023533821000171, 2.242776155000115],
                [13.020152092000046, 2.245249749000152],
                [13.019021034000104, 2.249142409000172],
                [13.017123223, 2.247157336000157],
                [13.014942168000118, 2.246375561000036],
                [13.010226250000187, 2.246015072000148],
                [13.004948617000025, 2.24508738600008],
                [13.00170898500005, 2.246569873000112],
                [12.997978211000088, 2.247414350000099],
                [12.995656967000059, 2.246065855000154],
                [12.994036674000029, 2.24684238399999],
                [12.9912185660001, 2.248821021000026],
                [12.98735237000011, 2.245629549000114],
                [12.98573398700006, 2.244990111000163],
                [12.98502635900013, 2.24767971000017],
                [12.982776641000157, 2.246118783999975],
                [12.978764533, 2.245971680000082],
                [12.97559833500003, 2.2454009060001],
                [12.974403381000116, 2.244266272],
                [12.971028328000045, 2.241925001000027],
                [12.968847275000144, 2.241143226000077],
                [12.967578888000105, 2.24248671600003],
                [12.965113640000027, 2.243545294000057],
                [12.959482194000032, 2.244103670000072],
                [12.956316948000165, 2.242754222000144],
                [12.951670646000082, 2.243526458000076],
                [12.948997498000097, 2.242389680000031],
                [12.946816442999989, 2.241961957000058],
                [12.946390151000116, 2.244580985000027],
                [12.941183089000049, 2.24386572899999],
                [12.937940597000136, 2.247684240000012],
                [12.934414864000189, 2.251927377000186],
                [12.932090758000072, 2.253056765],
                [12.923577309000109, 2.25099158300003],
                [12.918295859000125, 2.252966405000166],
                [12.91639804800019, 2.251264333000052],
                [12.917808534000187, 2.249425412000051],
                [12.919499398000141, 2.248082639000018],
                [12.920840263000116, 2.245677470999965],
                [12.919857025000113, 2.244260072000031],
                [12.914370538000128, 2.241915703000132],
                [12.911064148000094, 2.241061450000075],
                [12.909166336000112, 2.239288807000037],
                [12.903888702000074, 2.238148451000029],
                [12.900654793000115, 2.236232043000086],
                [12.896926880000137, 2.234598399000106],
                [12.89389801100009, 2.235797406000074],
                [12.891364097, 2.236218691000033],
                [12.889325142000132, 2.234658242000137],
                [12.887070656000162, 2.236637115000178],
                [12.886503220000066, 2.239326716000051],
                [12.886709214000064, 2.243150235000144],
                [12.884106637000116, 2.241942883999968],
                [12.882416725000098, 2.242435932000092],
                [12.880443573000093, 2.244698763000031],
                [12.877909660000114, 2.244411945000081],
                [12.877138138000078, 2.242499113000065],
                [12.875877381000123, 2.238532544000066],
                [12.87546157900016, 2.234142543000189],
                [12.874269486, 2.231025697000064],
                [12.874695779000035, 2.228265047999969],
                [12.87237548800016, 2.226987363000035],
                [12.869981765000091, 2.227550269000119],
                [12.868147850000128, 2.230167152000149],
                [12.866529464000166, 2.230164767000076],
                [12.864911080000013, 2.229383468000151],
                [12.86280345900019, 2.227185727000119],
                [12.86012935600013, 2.226898671000186],
                [12.855551719, 2.229157447000034],
                [12.853224755000042, 2.231986047000135],
                [12.852726937000057, 2.235454322000123],
                [12.850407601000029, 2.233610153000029],
                [12.847873687000174, 2.233818770000141],
                [12.84625148800012, 2.236435891000042],
                [12.844347954, 2.238769532000163],
                [12.836042405000171, 2.23946523800015],
                [12.831255913000064, 2.240024329000164],
                [12.826258659000132, 2.239946128000156],
                [12.823722840000016, 2.241429090000111],
                [12.822947501000101, 2.242773055000043],
                [12.823225022000031, 2.244897365000099],
                [12.824132919000135, 2.249712945000113],
                [12.824338914, 2.253252984000028],
                [12.821523667000065, 2.25374436400017],
                [12.819620133000114, 2.255794525000056],
                [12.81934261300006, 2.2528915420001],
                [12.817797660000053, 2.250977517000138],
                [12.81568813400014, 2.249700070000188],
                [12.813860893000026, 2.247856618000071],
                [12.810557366000182, 2.244949102000021],
                [12.808025360000102, 2.244166613000061],
                [12.804574966000075, 2.245364904000155],
                [12.80182743099999, 2.247201443000165],
                [12.799149514000021, 2.249462844000107],
                [12.796897888000103, 2.249459265999974],
                [12.796266556000091, 2.247830152],
                [12.796270371000105, 2.245777131000068],
                [12.794724464000012, 2.243934155000147],
                [12.795577049000144, 2.238767147000033],
                [12.794100762000085, 2.237207652000052],
                [12.791213989000084, 2.238477469000031],
                [12.787982940000177, 2.234295845000077],
                [12.785732270000096, 2.2333719720001],
                [12.78291606800019, 2.234217407000187],
                [12.78151226, 2.231454135000149],
                [12.778771400999972, 2.229397058000018],
                [12.776026725000065, 2.229321957000138],
                [12.773703576000173, 2.229814053000155],
                [12.770751000000132, 2.228039742000021],
                [12.767794609000191, 2.227964401000065],
                [12.764767647000042, 2.229092599000182],
                [12.762656211000092, 2.228735209999968],
                [12.761040688000094, 2.227033853000023],
                [12.75794601500013, 2.225542308000115],
                [12.75604343399999, 2.227309228000138],
                [12.75428676600012, 2.225607633000095],
                [12.752036094000061, 2.22482538200012],
                [12.75027942600002, 2.223123789000113],
                [12.747674942, 2.223615408000057],
                [12.744935036000129, 2.22077941900011],
                [12.743388176000053, 2.219856739000022],
                [12.741554260000044, 2.222473144000048],
                [12.73944282600013, 2.222470046000126],
                [12.736556053000072, 2.223810434000029],
                [12.73310089100005, 2.228548289000059],
                [12.730567932000156, 2.228119611000125],
                [12.727540970000177, 2.228751898000155],
                [12.725216866000096, 2.229739427000027],
                [12.72240448000008, 2.22846078900011],
                [12.71965885100019, 2.229093789000103],
                [12.715085984000098, 2.228378535000047],
                [12.711217881000039, 2.226673603000052],
                [12.709457398000154, 2.227237225000124],
                [12.70621585900011, 2.23041772900001],
                [12.70093440900007, 2.232745410000177],
                [12.698538779000103, 2.234794616000045],
                [12.695369721000077, 2.23641777000006],
                [12.691212653000036, 2.239809036000168],
                [12.68325615000009, 2.242274047000137],
                [12.677205087000118, 2.242122890000132],
                [12.673895837000032, 2.242967129000078],
                [12.671858787000076, 2.240769386000068],
                [12.668833732000166, 2.240693808],
                [12.667567253000072, 2.240196229000162],
                [12.664542198000163, 2.239554167000165],
                [12.661092759000155, 2.24046898000006],
                [12.655111312000031, 2.240530015000047],
                [12.652090073000068, 2.238047601000176],
                [12.649343491000025, 2.239175797000087],
                [12.647229195000023, 2.24108362100003],
                [12.642165185000067, 2.239588976000107],
                [12.639069557000084, 2.239300728000046],
                [12.634772301000169, 2.242054462000169],
                [12.63083171900007, 2.242614508000145],
                [12.622175216000187, 2.243237257000033],
                [12.61992073000016, 2.24479103200008],
                [12.617168426000148, 2.249882936000063],
                [12.615408896000076, 2.250021697000079],
                [12.611538887000052, 2.24980306700013],
                [12.61019802200019, 2.252065898000183],
                [12.609699249000073, 2.255958318000182],
                [12.6032266630001, 2.255522967000161],
                [12.602526665000084, 2.253327370000136],
                [12.600626946000034, 2.253324270000064],
                [12.59703731500008, 2.25345993100018],
                [12.590799330999971, 2.253072023000016],
                [12.58507061, 2.256271363000053],
                [12.583472252000092, 2.25834489000016],
                [12.581966399000123, 2.261456966000083],
                [12.58149051700019, 2.26532578500013],
                [12.573323249000168, 2.268049002000169],
                [12.553546905000076, 2.256690265000088],
                [12.545574189000149, 2.255638600000054],
                [12.534318924000104, 2.253637552000043],
                [12.530003547000035, 2.253535747000058],
                [12.5263443, 2.254001379000044],
                [12.518364906000102, 2.25653576799999],
                [12.514888763000158, 2.25945544199999],
                [12.511885643000028, 2.260394096000027],
                [12.492743493000148, 2.262248515000067],
                [12.483838081000158, 2.258363723000173],
                [12.472964286000092, 2.253531694000174],
                [12.470244407, 2.253338098000143],
                [12.457579613000178, 2.253032922999978],
                [12.45392227200017, 2.252460242000154],
                [12.449792863000027, 2.25358533900004],
                [12.424925805000044, 2.257787944000086],
                [12.401559830000053, 2.261803865000047],
                [12.385890006000068, 2.263945817000035],
                [12.378664969999988, 2.265253783000048],
                [12.325662614000066, 2.279188872000134],
                [12.320090293000021, 2.280144691000032],
                [12.315093041000125, 2.281439782000177],
                [12.309952735000081, 2.281816722000087],
                [12.307167052000068, 2.282197715000109],
                [12.299921035000125, 2.279092312000103],
                [12.290753365000114, 2.275934697000082],
                [12.289605142000028, 2.273951769000121],
                [12.28960800200008, 2.271970988000135],
                [12.288893699000027, 2.268877746000158],
                [12.287219047000065, 2.265444518000095],
                [12.283241271000065, 2.260944129000166],
                [12.278394698999989, 2.258906126000056],
                [12.270140647000062, 2.255750179000131],
                [12.26591587199999, 2.254679441000178],
                [12.26389885000009, 2.254917144000046],
                [12.262071608999975, 2.256411314000047],
                [12.259858131000158, 2.258387804000108],
                [12.258657456000037, 2.25848221900003],
                [12.257702827000173, 2.255291939000017],
                [12.255786895000085, 2.252969503000088],
                [12.25372409800002, 2.25132298599999],
                [12.251419067000029, 2.251463652000098],
                [12.248058320000041, 2.250925779999989],
                [12.245902061000038, 2.248409749000132],
                [12.244076728000095, 2.248502731000144],
                [12.240906715000108, 2.249221325000121],
                [12.237590790000127, 2.250422716000117],
                [12.23547458600018, 2.252109528000062],
                [12.233068465000088, 2.254713535000121],
                [12.230040551000059, 2.255915641000115],
                [12.227542876000086, 2.256055593000156],
                [12.224804877999986, 2.256581784000105],
                [12.222400665000123, 2.258171319000098],
                [12.21984958600018, 2.260968448000142],
                [12.218355177999968, 2.263960600000075],
                [12.21796608000011, 2.266954898000051],
                [12.21671295100009, 2.269222975000048],
                [12.214017867, 2.272019625000155],
                [12.212378503000082, 2.275639535000153],
                [12.205025673000137, 2.278378726000028],
                [12.199556350000137, 2.275952578000045],
                [12.197058678000019, 2.275995970000054],
                [12.193694115000085, 2.277776958000175],
                [12.189755440000113, 2.27781748700005],
                [12.18711471600011, 2.277570725999965],
                [12.183082580000075, 2.27707982000004],
                [12.18010330300001, 2.277894974000162],
                [12.177028656000118, 2.278613567000036],
                [12.173185349000164, 2.279185772],
                [12.168523788000186, 2.28125357600004],
                [12.166702270000087, 2.27955937400003],
                [12.164498329000082, 2.276753187000111],
                [12.159605025000076, 2.274424791000172],
                [12.153656959000159, 2.271031619000098],
                [12.149237632000165, 2.271747590000075],
                [12.14491271900016, 2.273477794000087],
                [12.141930580000064, 2.276080371000035],
                [12.140102386000137, 2.277574062999975],
                [12.138034820000144, 2.278922559000023],
                [12.134238244000073, 2.280557393000095],
                [12.130779267000094, 2.281323194000152],
                [12.128376007000043, 2.282477618000087],
                [12.125825882000186, 2.285274267000091],
                [12.123369217, 2.28884387100004],
                [12.116366386000152, 2.285062074000052],
                [12.112912177000055, 2.283460856999966],
                [12.111527442000181, 2.279690505000133],
                [12.11023521400017, 2.277369261000104],
                [12.108219147000057, 2.276978970000073],
                [12.105673790000083, 2.277263641000047],
                [12.102649689000032, 2.276677609000046],
                [12.096551895000175, 2.276617051000017],
                [12.092660903000137, 2.277092218000178],
                [12.090352058000121, 2.279116153000018],
                [12.088191031000122, 2.279353380000032],
                [12.085551262000081, 2.278961658000128],
                [12.082431794000115, 2.277844191000156],
                [12.080846787000155, 2.27808261000007],
                [12.07642555200016, 2.280150414000104],
                [12.074789048000127, 2.281982660000153],
                [12.070992470000022, 2.283472300000142],
                [12.065757752000025, 2.283944369000039],
                [12.060956002000069, 2.284031153000115],
                [12.052265168000076, 2.283723594000151],
                [12.049717903000044, 2.28521561600013],
                [12.049867630000108, 2.28207635900003],
                [12.047950745000037, 2.280526877000057],
                [12.046462058000031, 2.280523777000155],
                [12.041707038000084, 2.28133487700012],
                [12.037722589, 2.280940294000061],
                [12.03334999100008, 2.282428503000176],
                [12.03003311100008, 2.28411221500005],
                [12.027246475000084, 2.285313846000122],
                [12.023404121000112, 2.28549909700007],
                [12.019562721000057, 2.285539389000178],
                [12.017449379000027, 2.286114454000085],
                [12.015743256000178, 2.288030148000132],
                [12.013633728000059, 2.286566497000081],
                [12.012553213999979, 2.284728289000043],
                [12.009820939000178, 2.282427788000064],
                [12.007257461000165, 2.2808160780001],
                [12.00440216000004, 2.282760621000136],
                [12.001375198000062, 2.284532785000067],
                [11.99943828500011, 2.283610821000139],
                [11.997559547000094, 2.282172679000155],
                [11.994994164000047, 2.281421424000087],
                [11.989006043000074, 2.281580926000117],
                [11.98615169499999, 2.282550097000126],
                [11.984094621000168, 2.284553766000101],
                [11.98072433499999, 2.287242889000083],
                [11.979241371000057, 2.287239791000047],
                [11.977644921000149, 2.286892175000048],
                [11.975595475000034, 2.28528165900002],
                [11.973487854000155, 2.28395772000016],
                [11.971605301000068, 2.28441262400014],
                [11.970459936999987, 2.28653287800006],
                [11.969201088000034, 2.288480759000038],
                [11.968065262000039, 2.286527633000048],
                [11.965160371000081, 2.284628391000126],
                [11.962996482000051, 2.282902718000059],
                [11.960259437000161, 2.28261017900013],
                [11.957177162000107, 2.284037591000129],
                [11.955525397000144, 2.283116341000095],
                [11.953072547000033, 2.283397914000034],
                [11.950673102, 2.285515308000072],
                [11.945317269000157, 2.282807589000129],
                [11.942243577000056, 2.280334235000112],
                [11.938935280000123, 2.280269862000182],
                [11.937005997000085, 2.278950691000034],
                [11.934324264000168, 2.27819562000019],
                [11.932152749000181, 2.278706312000168],
                [11.929411888000175, 2.280306816000064],
                [11.92301178100007, 2.286545992000072],
                [11.921755790000134, 2.28717422599999],
                [11.92107963500007, 2.283272028000113],
                [11.915843010000117, 2.278441907000058],
                [11.91179657000015, 2.27745795300018],
                [11.908542633000138, 2.278770209000186],
                [11.90574932200002, 2.278477431000113],
                [11.90198421499997, 2.278813362000108],
                [11.900156020000168, 2.280702590000146],
                [11.897362709000049, 2.280180216000076],
                [11.895019532000049, 2.282297612000093],
                [11.893825531000118, 2.280803680000133],
                [11.889101029000187, 2.27694988200011],
                [11.886363984000127, 2.276829243000179],
                [11.881856918000096, 2.277450323000039],
                [11.880311013000153, 2.280257941000173],
                [11.878087043000164, 2.28059721000011],
                [11.871242523000149, 2.281041146000064],
                [11.869020461000162, 2.280118465000044],
                [11.86851310700007, 2.277535916000147],
                [11.868691445000081, 2.274037123000085],
                [11.868357658000093, 2.270422698000061],
                [11.866478920000077, 2.268984318000094],
                [11.863740921000101, 2.269265174000054],
                [11.861569404000136, 2.271268368000051],
                [11.859746934000157, 2.270403862000137],
                [11.857009888000164, 2.270397902000184],
                [11.85541152899998, 2.270853281000086],
                [11.85409641200016, 2.272513866000111],
                [11.853292464000106, 2.275265455000124],
                [11.853801728000121, 2.277159691000179],
                [11.853628159000039, 2.27830648500003],
                [11.850780486000076, 2.276751280000042],
                [11.848612785000057, 2.277090788000066],
                [11.845993042000089, 2.275192022000169],
                [11.844400405000044, 2.273352861000149],
                [11.842008590000091, 2.272200584000075],
                [11.84080982200004, 2.272484540000164],
                [11.839096069000107, 2.274029732000088],
                [11.837893485000052, 2.276551008000183],
                [11.835891724000078, 2.278955698000175],
                [11.832695007000154, 2.280841589000147],
                [11.829042435000133, 2.282095433000166],
                [11.826134681000042, 2.281802177000088],
                [11.824827195000012, 2.279791595000177],
                [11.825689316000023, 2.276982785000087],
                [11.826095582000107, 2.273714064999979],
                [11.823990821000109, 2.271185637000031],
                [11.822452546000136, 2.270895481000025],
                [11.820738793000146, 2.272210837000159],
                [11.819022178000125, 2.274845601000038],
                [11.816056252000067, 2.275068521000151],
                [11.811554908000119, 2.273796559000061],
                [11.808077813000125, 2.273272514000155],
                [11.805962563000037, 2.275504829],
                [11.803627967000182, 2.27389359600005],
                [11.800551415000143, 2.273083688000156],
                [11.79820919000008, 2.274856806000116],
                [11.795701028, 2.274506807000023],
                [11.79460811700011, 2.278691767999987],
                [11.79398250500003, 2.278059244000076],
                [11.794844628000021, 2.27519345200011],
                [11.794676780000032, 2.273644209000111],
                [11.793426514000146, 2.271806003000052],
                [11.790913581000098, 2.273406506000072],
                [11.78771686600004, 2.275292159000173],
                [11.78812122300019, 2.272769213000174],
                [11.787500381000143, 2.269727708000119],
                [11.784538270000098, 2.268344403000185],
                [11.781515120999984, 2.269198179000114],
                [11.777179718000014, 2.269876718000035],
                [11.769195556000057, 2.270719290000102],
                [11.766571998000018, 2.270828010000059],
                [11.764010429999985, 2.268872023000142],
                [11.762928962000046, 2.267894507000051],
                [11.758881570000028, 2.267483950000099],
                [11.756200790000094, 2.267879249000032],
                [11.752491951000081, 2.269247533000112],
                [11.750207901000067, 2.270561457000099],
                [11.749520301000018, 2.272108793000029],
                [11.752478600000131, 2.274983407000036],
                [11.752985001000184, 2.278196335000132],
                [11.752976418000117, 2.282096625000065],
                [11.752293586000064, 2.281292200000053],
                [11.752243996000061, 2.278079987000126],
                [11.750596999000152, 2.275323153000102],
                [11.748088837000068, 2.274743796000053],
                [11.747175216000073, 2.275544881000144],
                [11.745973587000037, 2.277262689000168],
                [11.745680808999964, 2.280875683000147],
                [11.743737220000185, 2.283165455000074],
                [11.742418289000057, 2.28625965100008],
                [11.744972228000051, 2.291656971000123],
                [11.74656391100001, 2.29355335200006],
                [11.74633312200001, 2.294757366000056],
                [11.740864753000096, 2.292622566000148],
                [11.734368324, 2.291059018000112],
                [11.731175421999978, 2.290879727000117],
                [11.728833199000064, 2.292996646000177],
                [11.728143692000117, 2.295346499],
                [11.726884843000164, 2.296892166000077],
                [11.723572731000047, 2.299178838000103],
                [11.722032546000037, 2.299634219000041],
                [11.718497275, 2.299625874000185],
                [11.714221955000141, 2.299157142000126],
                [11.711256981000133, 2.299322367000173],
                [11.708459855000172, 2.300692319],
                [11.70594596899997, 2.302923441000075],
                [11.704172134000032, 2.305557490000126],
                [11.702910422000059, 2.308766365000167],
                [11.702161789000058, 2.311976433000041],
                [11.701069831000098, 2.315644502000055],
                [11.682029724000074, 2.314395429000172],
                [11.679920197000058, 2.314447879000056],
                [11.67097282300017, 2.312591552000185],
                [11.667032241000072, 2.312418939000054],
                [11.665208816000074, 2.314828634000037],
                [11.65905380200013, 2.313896418000184],
                [11.655349731000172, 2.312970162000113],
                [11.654328346000113, 2.310730934999981],
                [11.651489258000026, 2.305734635000135],
                [11.649102211000127, 2.302746773000024],
                [11.646194457000092, 2.302797318000046],
                [11.643288613000152, 2.302102090000062],
                [11.639528275000089, 2.300659418000066],
                [11.636392593000153, 2.300652027000069],
                [11.633595467000191, 2.302179576000071],
                [11.63079929300011, 2.303262710000183],
                [11.627725602000055, 2.301190853000037],
                [11.62334060600017, 2.299345256000038],
                [11.620379447, 2.297674895000114],
                [11.614627838000104, 2.294736384999965],
                [11.612522124000122, 2.29341244800014],
                [11.610299110000085, 2.293292523000048],
                [11.608130456000026, 2.293918132000044],
                [11.606245995000165, 2.29557657200013],
                [11.604015351000157, 2.298381568000082],
                [11.600644112000055, 2.301584958999968],
                [11.598247528000172, 2.302726030000144],
                [11.595394134000117, 2.30369424800017],
                [11.593282700000032, 2.304663898000115],
                [11.591514587000063, 2.304831743000023],
                [11.587752341000169, 2.30476522500004],
                [11.582673073000024, 2.307046890000095],
                [11.57993698199999, 2.306753636000167],
                [11.573666572999969, 2.306509019000089],
                [11.569903374000091, 2.30672931700002],
                [11.566656111999976, 2.305746556000145],
                [11.563751221000075, 2.304764510000098],
                [11.560218811000027, 2.30401063000005],
                [11.557826996000074, 2.302743198000144],
                [11.556352615000037, 2.299757481000142],
                [11.554135323000082, 2.296999454000115],
                [11.55100631700003, 2.294640780000179],
                [11.548668862000113, 2.294405700000084],
                [11.545075415999975, 2.295429230000025],
                [11.541309357000046, 2.296853542000122],
                [11.539882660000046, 2.297538281000186],
                [11.536241532000076, 2.294547559000023],
                [11.533733367000082, 2.294369460000098],
                [11.53190803400014, 2.29499578500014],
                [11.527285575000064, 2.296934128000032],
                [11.524657248999972, 2.299278736000019],
                [11.523341178000067, 2.301225186000124],
                [11.522031784000092, 2.300820590000058],
                [11.51873111700013, 2.298346759000083],
                [11.515882493000163, 2.29765152900012],
                [11.51314544700017, 2.29810357100007],
                [11.510862350000025, 2.299130201000082],
                [11.507381440000131, 2.300555230000157],
                [11.504245758000025, 2.30060482000016],
                [11.501852988999985, 2.300082923000048],
                [11.494225501000074, 2.295591355000056],
                [11.489899636000132, 2.293114901000138],
                [11.487792015000139, 2.29242157900012],
                [11.485682488000123, 2.292473794000102],
                [11.483111382000175, 2.294875622000063],
                [11.47917175300006, 2.297733068000071],
                [11.475630760000115, 2.300419331000171],
                [11.472724914000025, 2.299953222000056],
                [11.467595100000096, 2.299596548000068],
                [11.463434220000124, 2.299643517000106],
                [11.460240364000185, 2.300208807000161],
                [11.45641803699999, 2.301346065000132],
                [11.456083298000124, 2.299982309000086],
                [11.458532333000164, 2.299243927000077],
                [11.459962845, 2.297240735000059],
                [11.459797860000151, 2.294717312000103],
                [11.457979202000104, 2.292419196000026],
                [11.455078124000067, 2.289946318000034],
                [11.45177364400007, 2.289479494000148],
                [11.445159912000122, 2.289978982000093],
                [11.44407177100004, 2.292097807000175],
                [11.44440937100012, 2.294105530000081],
                [11.44286441900016, 2.296337843000174],
                [11.439952850000054, 2.298050643000124],
                [11.437046051000152, 2.297928810000087],
                [11.434657097000184, 2.295858623000129],
                [11.429536820000123, 2.29223346800012],
                [11.427599906000069, 2.291769744000135],
                [11.42514896400013, 2.29159164500004],
                [11.420134544, 2.290890932000025],
                [11.414604187000123, 2.291565179000145],
                [11.4140186300001, 2.291966438000031],
                [11.412607194000032, 2.292305469000098],
                [11.409697533000042, 2.293502092000097],
                [11.405019761000062, 2.295153142000117],
                [11.40239238800001, 2.297038555000029],
                [11.399200440000072, 2.297131062000176],
                [11.39611339600009, 2.300678016000177],
                [11.390993118000097, 2.296880723000129],
                [11.38854694400004, 2.29498243400019],
                [11.38563537500005, 2.296981813000173],
                [11.383923530000118, 2.297608137000111],
                [11.380274773999986, 2.298000098000102],
                [11.376516343000162, 2.296901227000035],
                [11.370086668999988, 2.292756320000024],
                [11.368155479000109, 2.290171624000038],
                [11.365482331000067, 2.287756681000133],
                [11.367991448000112, 2.287361622000049],
                [11.370615006000094, 2.286508322000145],
                [11.372956276000025, 2.28502369000006],
                [11.375636101000168, 2.284514428000023],
                [11.379797936000159, 2.28423833800008],
                [11.379521371000124, 2.280854940000097],
                [11.378104210000174, 2.27758336100004],
                [11.381123543000172, 2.278049468000177],
                [11.382894516000022, 2.276391268000111],
                [11.382959367000183, 2.273410083000101],
                [11.382625579000091, 2.270255805000033],
                [11.380519868000022, 2.268874408000045],
                [11.377846718, 2.266517163000117],
                [11.377337455999964, 2.265082360000179],
                [11.380701066000086, 2.264689445000101],
                [11.383665085000132, 2.264754295000103],
                [11.384241105000172, 2.262404920000165],
                [11.382422447000181, 2.259935142000074],
                [11.379465103000086, 2.25757694399999],
                [11.377362252000069, 2.25527834900015],
                [11.377654076000056, 2.252354861000015],
                [11.379995346000157, 2.250411510999982],
                [11.381769181000095, 2.247778415000084],
                [11.382174493000093, 2.245027303000029],
                [11.381380081000088, 2.243649484000173],
                [11.378653525000061, 2.240087747000075],
                [11.376434326000037, 2.238534212000047],
                [11.372961044000078, 2.237092257000029],
                [11.370228768000118, 2.235709429000167],
                [11.367431640000177, 2.237651825000114],
                [11.364111901, 2.243319511000095],
                [11.363429069000176, 2.242744684000058],
                [11.363492965000148, 2.239992620000066],
                [11.362876891000155, 2.235576391000166],
                [11.36186409100003, 2.230413914000167],
                [11.360617638000178, 2.227429629000085],
                [11.357537271000126, 2.228339196000093],
                [11.355093001000057, 2.225638390000086],
                [11.360627175000161, 2.223186732000102],
                [11.362965583, 2.222504616000151],
                [11.36525249500005, 2.219815492000066],
                [11.36388874100004, 2.217920303000028],
                [11.362199784000154, 2.216771364000067],
                [11.359049797000011, 2.2159590710001],
                [11.360309600999983, 2.213496685999985],
                [11.363107682000134, 2.211153031000151],
                [11.361804008000092, 2.208340407000151],
                [11.35901832500008, 2.205466987000079],
                [11.358909607000157, 2.203402520000054],
                [11.359026908000033, 2.201912165000124],
                [11.360793113000057, 2.201916696000069],
                [11.362959861999968, 2.201749802999984],
                [11.363935471000161, 2.198885679000171],
                [11.363826751000033, 2.196706772000084],
                [11.357741355000144, 2.191646337000179],
                [11.356624603000114, 2.18189692500016],
                [11.355341911000039, 2.180869102000088],
                [11.359898567000073, 2.174151897000058],
                [11.353187562000016, 2.171280860000081],
                [11.346166611000058, 2.175209999],
                [11.343955994000055, 2.174952745000041],
                [11.341704369000183, 2.174065590000112],
                [11.337243080000064, 2.172417403000054],
                [11.3344106670001, 2.172394752000059],
                [11.332393645000025, 2.157207966000101],
                [11.335540772000115, 2.119498969000119],
                [11.334224700999982, 2.109430552000049],
                [11.33351993500014, 2.035034896000184],
                [11.333144188000063, 1.995282052999983],
                [11.334543228000143, 1.989539026999978],
                [11.332970620000083, 1.983551026000043],
                [11.335158347000174, 1.909010053000145],
                [11.33568477700004, 1.891106964000187],
                [11.333106994000161, 1.85475695100007],
                [11.334556579000093, 1.837743998000065],
                [11.33366107900008, 1.834290982000027],
                [11.33381557399997, 1.827025056000139],
                [11.33571624800004, 1.827105642000049],
                [11.335883141000068, 1.788937569000098],
                [11.336068152000053, 1.745428682000181],
                [11.333659172000182, 1.745369792000133],
                [11.334771155999988, 1.692863942000031],
                [11.33367157000015, 1.683429957000101],
                [11.334794045000024, 1.638597012000048],
                [11.334736825000107, 1.552366017999987],
                [11.333460808000098, 1.531896949000043],
                [11.333966252000039, 1.490500002000147],
                [11.335135001000026, 1.490509746000043],
                [11.3351449970001, 1.488418699000135],
                [11.337060265000162, 1.489409620000117],
                [11.337203550000027, 1.449395636000133],
                [11.33729839300014, 1.421674609000036],
                [11.337436674000116, 1.381059982000181],
                [11.335202193000043, 1.381040009],
                [11.334218978000138, 1.367509961000167],
                [11.333603859000164, 1.303135992000023],
                [11.334636688000046, 1.27716303],
                [11.333388329000059, 1.152335048000168],
                [11.335099220000131, 1.078559041000062],
                [11.33488464400017, 1.025109054000097],
                [11.334781647000057, 0.999262989000101],
                [11.316747665000094, 0.997108996000122],
                [11.23502159200018, 0.997545005000063],
                [11.202551841000115, 0.997713029000067],
                [11.176981925000064, 0.996212005000075],
                [11.172633171000143, 0.997569979000161],
                [11.159386635000146, 0.997503995000045],
                [11.152984618000062, 0.999310970000067],
                [11.124900817000139, 0.997335015999965],
                [11.121010780000063, 0.999383987000158],
                [11.103890420000141, 0.997460008000189],
                [11.048389434000057, 0.997873009000159],
                [11.02121925400013, 0.996357023000144],
                [11.015730859000143, 0.997707010000056],
                [10.996088981000128, 0.997837007000044],
                [10.996093749000181, 0.996918024000138],
                [10.986266135999983, 0.998266995000108],
                [10.96732044300012, 0.998193980000167],
                [10.947982787000115, 0.998053015000153],
                [10.841751099000021, 0.997277975000145],
                [10.835308075000057, 0.997232974000042],
                [10.799671172000103, 0.998422026000014],
                [10.788943292000113, 0.997444988000041],
                [10.716240883000069, 0.997637987000076],
                [10.59066963100014, 0.997956991000024],
                [10.465098382000178, 0.998257995000074],
                [10.446810722000123, 0.998301030000164],
                [10.442937851000067, 0.996670009000127],
                [10.433341026000107, 0.997763992000102],
                [10.41497612000012, 0.997929991000092],
                [10.339917183000125, 0.998615980000125],
                [10.295157433000043, 0.999019026000042],
                [10.284431458000086, 0.997807027000022],
                [10.214419365000083, 0.997255027000051],
                [10.162705422000045, 0.996841013000051],
                [10.130492210000114, 0.99848198900014],
                [10.124561310000047, 0.997066022000126],
                [10.109482765000052, 0.99812102400017],
                [10.091672898000013, 0.997093975000041],
                [10.07452964800018, 0.999513984000146],
                [10.07134437600007, 0.997887016000163],
                [10.005554200000063, 0.997668028000021],
                [9.996741295000163, 0.997637987000076],
                [9.99902725200019, 0.992448985000124],
                [9.995393754000133, 0.986232997000059],
                [9.99654865299999, 0.982101023000098],
                [9.98994159599999, 0.977486015000181],
                [9.990446090000034, 0.963472008000167],
                [9.992288589000111, 0.958653986000115],
                [9.997092246000136, 0.956600009999988],
                [9.994370461000074, 0.950847984000177],
                [9.995758056000057, 0.946026982000035],
                [9.989844322000124, 0.938887001000182],
                [9.99578952800016, 0.936380029000077],
                [9.996254922000105, 0.934081972000058],
                [9.992161750000037, 0.929246008000177],
                [9.986686706000171, 0.926930010000035],
                [9.980971336000152, 0.928750991000129],
                [9.970697402000042, 0.927798987000074],
                [9.962024688000156, 0.925934018000021],
                [9.956104278, 0.920861006000052],
                [9.94993591399998, 0.921301007000125],
                [9.942612647999965, 0.925411999000119],
                [9.930237771000066, 0.938009025000042],
                [9.908061982000106, 0.944369972000061],
                [9.900512696000021, 0.948019029000136],
                [9.89477920500002, 0.954665006000141],
                [9.892339705000154, 0.965036989000055],
                [9.884882926000046, 0.976706981],
                [9.878223419000108, 0.982411028000115],
                [9.842037201000096, 0.998035014000152],
                [9.805958748000023, 0.997108996000122],
                [9.7901916510001, 0.998383999000168],
                [9.788955688000101, 1.012856007000153],
                [9.786136626000086, 1.024786949000031],
                [9.760634421000077, 1.048063994000131],
                [9.748416901000098, 1.064990998000042],
                [9.743369102000145, 1.068405033000147],
                [9.736286163000045, 1.069049955000025],
                [9.699539185000162, 1.06489598800016],
                [9.671224593999966, 1.06355798300001],
                [9.66530029800009, 1.061178120000022],
                [9.661847009000098, 1.068518980000079],
                [9.65705104000017, 1.067813006000108],
                [9.650671961000057, 1.063194993000025],
                [9.64519300000012, 1.062256],
                [9.642244, 1.0571920000001],
                [9.637233734000063, 1.053267003000144],
                [9.626280785, 1.050701023000102],
                [9.618078, 1.044696],
                [9.608260000000143, 1.043973],
                [9.60188484200006, 1.03866505700006],
                [9.5872888560001, 1.031955004000054],
                [9.58569400000016, 1.030909],
                [9.586527825000132, 1.03013897000011],
                [9.587362289000055, 1.02986097400003],
                [9.587362289000055, 1.026806951000026],
                [9.587083816000018, 1.026806951000026],
                [9.58708190900012, 1.023749948000102],
                [9.58680629800017, 1.023749948000102],
                [9.58680629800017, 1.020694017000153],
                [9.586527825000132, 1.020694017000153],
                [9.586527825000132, 1.017361998000013],
                [9.586250305000078, 1.017361998000013],
                [9.586250305000078, 1.014305950000107],
                [9.585971832000041, 1.014305950000107],
                [9.585971832000041, 1.01124894700007],
                [9.58569431300009, 1.01124894700007],
                [9.58569431300009, 1.008195044000047],
                [9.58514022800017, 1.007917046000159],
                [9.58514022800017, 1.007361055000047],
                [9.584305763000145, 1.006526948000044],
                [9.582916261000094, 1.006526948000044],
                [9.582362175000071, 1.00680494400018],
                [9.581527711000149, 1.00597298200006],
                [9.581250190000162, 1.004860998000083],
                [9.580140113000084, 1.003751040000054],
                [9.579305649000105, 1.003195046999963],
                [9.578195572000027, 1.002082945000154],
                [9.577918051999973, 1.000972986000079],
                [9.577082635000068, 1.000972986000079],
                [9.577082635000068, 0.999859989000072],
                [9.576526642000147, 0.999859989000072],
                [9.576249123000025, 0.999028981000095],
                [9.574860573000024, 0.997915984000088],
                [9.57513904600006, 0.997084021000092],
                [9.574860573000024, 0.996249972999976],
                [9.573751449000099, 0.995971977000067],
                [9.573748589000047, 0.994306029000086],
                [9.573471068000117, 0.993749977000107],
                [9.574583053000026, 0.992362022000123],
                [9.573471068000117, 0.992084025999986],
                [9.572639465000123, 0.991249979000031],
                [9.572639465000123, 0.990140021000173],
                [9.572083473000134, 0.98930597399999],
                [9.572083473000134, 0.987918019000176],
                [9.57152652700006, 0.987918019000176],
                [9.571250916000054, 0.986527027000022],
                [9.57069492300019, 0.986527027000022],
                [9.570417405000114, 0.985418023000136],
                [9.569861412000023, 0.985418023000136],
                [9.569582938999986, 0.98430502400015],
                [9.569582938999986, 0.981805026000018],
                [9.56902885500017, 0.981248976000018],
                [9.56902885500017, 0.979861021000033],
                [9.568472862000078, 0.979305030000148],
                [9.56791687000009, 0.97763902000014],
                [9.56791687000009, 0.975973011000065],
                [9.56736087700017, 0.975417018000144],
                [9.56736087700017, 0.974029004000045],
                [9.566806793000183, 0.973473013000159],
                [9.566806793000183, 0.972916008000141],
                [9.566250800000091, 0.971807004000027],
                [9.565973282000016, 0.970416010000065],
                [9.565416336000112, 0.969305992000045],
                [9.564304351000033, 0.968194009000172],
                [9.564027786000167, 0.967083991000152],
                [9.561528206000105, 0.964583993000076],
                [9.561528206000105, 0.964028001999964],
                [9.56097221300007, 0.963750006000055],
                [9.56097221300007, 0.96319401300002],
                [9.559862138000142, 0.96208399700015],
                [9.559862138000142, 0.961528003000183],
                [9.55930614500005, 0.960693003000188],
                [9.558194160000141, 0.960693003000188],
                [9.557084083000063, 0.960139990000187],
                [9.557084083000063, 0.959304989000088],
                [9.557640076000098, 0.95902699300018],
                [9.557640076000098, 0.958195984999975],
                [9.555693625000117, 0.957082985999989],
                [9.554862022000123, 0.957082985999989],
                [9.554862022000123, 0.955971002000183],
                [9.554027557000097, 0.955138981000175],
                [9.552914620000138, 0.955416977000084],
                [9.552639962000114, 0.953749001000119],
                [9.552083969000023, 0.952916980999987],
                [9.552083969000023, 0.949029028000098],
                [9.55236244200006, 0.948472978000098],
                [9.55152702300012, 0.948194980000039],
                [9.55152702300012, 0.947081984000135],
                [9.550971031000131, 0.947081984000135],
                [9.550971031000131, 0.943472028000031],
                [9.55152702300012, 0.943193972000131],
                [9.551248550000082, 0.942084015000034],
                [9.551248550000082, 0.939584016000026],
                [9.55152702300012, 0.939584016000026],
                [9.55152702300012, 0.937640011000042],
                [9.552083969000023, 0.937362016000066],
                [9.553471565999985, 0.935971975000029],
                [9.553471565999985, 0.935140013000137],
                [9.554027557000097, 0.934306026000172],
                [9.554583550000189, 0.934306026000172],
                [9.554862022000123, 0.933749974000136],
                [9.555418015000043, 0.933749974000136],
                [9.555972098000154, 0.933193028000062],
                [9.555972098000154, 0.932362019000152],
                [9.556528092000121, 0.931249023000078],
                [9.557084083000063, 0.931249023000078],
                [9.557640076000098, 0.930139005000058],
                [9.557640076000098, 0.929027020000149],
                [9.557915687000104, 0.928472996000153],
                [9.558471680000025, 0.928472996000153],
                [9.558471680000025, 0.927639009000188],
                [9.559027672000184, 0.927361011000073],
                [9.559027672000184, 0.926805020000188],
                [9.560416222000129, 0.925417006000089],
                [9.561528206000105, 0.924583017000089],
                [9.56180572400018, 0.923195006000071],
                [9.562359810000032, 0.922639012000104],
                [9.563750268000149, 0.922639012000104],
                [9.564584732000071, 0.922917008000184],
                [9.565416336000112, 0.921805025000083],
                [9.565973282000016, 0.921805025000083],
                [9.566250800000091, 0.921250999000108],
                [9.566806793000183, 0.921250999000108],
                [9.567639350000036, 0.920139014000029],
                [9.56791687000009, 0.919028998000158],
                [9.56791687000009, 0.917916000000105],
                [9.568472862000078, 0.917360008000117],
                [9.568472862000078, 0.914305985000112],
                [9.56902885500017, 0.913194001000136],
                [9.56902885500017, 0.911249996000095],
                [9.569582938999986, 0.910972000000186],
                [9.569305420000035, 0.910138011000015],
                [9.569305420000035, 0.906805993000035],
                [9.56902885500017, 0.905417979000106],
                [9.568751335000115, 0.905417979000106],
                [9.568751335000115, 0.896804988000042],
                [9.56902885500017, 0.896804988000042],
                [9.56902885500017, 0.894860983000058],
                [9.569305420000035, 0.894860983000058],
                [9.569305420000035, 0.892359973000055],
                [9.570138932000077, 0.891529023000089],
                [9.569861412000023, 0.890416027000185],
                [9.570417405000114, 0.890137970000183],
                [9.570417405000114, 0.889028013000086],
                [9.570973396000056, 0.888472020000165],
                [9.570973396000056, 0.887637972000107],
                [9.57152652700006, 0.887637972000107],
                [9.571805, 0.886528015000181],
                [9.571805, 0.883750021000026],
                [9.572360990999982, 0.883194030000084],
                [9.572916985000177, 0.881528021000179],
                [9.572916985000177, 0.880418003000159],
                [9.573471068000117, 0.879584013999988],
                [9.573471068000117, 0.877638995000098],
                [9.574027062000084, 0.876527012],
                [9.574027062000084, 0.874027014000092],
                [9.574583053000026, 0.872916996000129],
                [9.574583053000026, 0.871249020000107],
                [9.574860573000024, 0.871249020000107],
                [9.574860573000024, 0.869583011000032],
                [9.575693130000104, 0.869028986000103],
                [9.576249123000025, 0.866806984000107],
                [9.576249123000025, 0.865137994000065],
                [9.576805115000184, 0.864027978000024],
                [9.577082635000068, 0.862915993000115],
                [9.577082635000068, 0.86124998400004],
                [9.577361108000105, 0.86124998400004],
                [9.577361108000105, 0.857361972000035],
                [9.577918051999973, 0.856249988000059],
                [9.577918051999973, 0.850139022000178],
                [9.578195572000027, 0.848748980999972],
                [9.57874870300003, 0.848470986000166],
                [9.579312324000057, 0.847082972000067],
                [9.579305649000105, 0.84597301499997],
                [9.580140113000084, 0.845139028000176],
                [9.580140113000084, 0.842917025000077],
                [9.580417634000014, 0.842917025000077],
                [9.580417634000014, 0.840138972000034],
                [9.580694199000106, 0.840138972000034],
                [9.580694199000106, 0.837638021000089],
                [9.581527711000149, 0.835138023000013],
                [9.582088470000087, 0.834583998000085],
                [9.582362175000071, 0.833472014000108],
                [9.582362175000071, 0.832361996000088],
                [9.582916261000094, 0.831250011],
                [9.583472251999979, 0.830972016000032],
                [9.583749770000054, 0.829306006000024],
                [9.584305763000145, 0.828750015000139],
                [9.584305763000145, 0.828194022000048],
                [9.58514022800017, 0.827639997000119],
                [9.58514022800017, 0.826806010000155],
                [9.586250305000078, 0.82652801200004],
                [9.586250305000078, 0.824862003000135],
                [9.58708190900012, 0.825693012000045],
                [9.588234902000067, 0.825740993000011],
                [9.588749886000016, 0.825139999000044],
                [9.58847236600019, 0.824027002000037],
                [9.589584351000099, 0.823194980000153],
                [9.590694428000177, 0.823194980000153],
                [9.591250419000062, 0.822916984000074],
                [9.591528892000099, 0.821805],
                [9.592359542999986, 0.821527004000131],
                [9.592359542999986, 0.820694984000056],
                [9.593194962000098, 0.820138991000135],
                [9.593194962000098, 0.819582999000147],
                [9.593750954000143, 0.819305004000171],
                [9.593750954000143, 0.818749010000147],
                [9.594306945000028, 0.818194986000151],
                [9.594306945000028, 0.817083001000071],
                [9.595138549000069, 0.814028978000067],
                [9.595973015000027, 0.813472987000182],
                [9.59652900600014, 0.811803997000084],
                [9.59652900600014, 0.810137988000179],
                [9.597083092000105, 0.808471978000171],
                [9.597704887000134, 0.808499992000179],
                [9.597639083000047, 0.807084024000062],
                [9.597916603999977, 0.806249976000174],
                [9.59847259500009, 0.805693985000119],
                [9.59847259500009, 0.804862023000169],
                [9.599026681000112, 0.804584027000089],
                [9.599026681000112, 0.80347198200019],
                [9.599582672000167, 0.802915991000077],
                [9.599582672000167, 0.802084029000184],
                [9.600138665000088, 0.802084029000184],
                [9.600694655999973, 0.801249981000126],
                [9.600694655999973, 0.797918022000033],
                [9.60097312900001, 0.796527028000014],
                [9.601804733000108, 0.795970976000035],
                [9.601804733000108, 0.79541701200003],
                [9.602639198000134, 0.79458302300003],
                [9.602083206000145, 0.792361022000136],
                [9.602083206000145, 0.790695011000025],
                [9.601804733000108, 0.790695011000025],
                [9.601804733000108, 0.788195015000156],
                [9.602360725000096, 0.787083030000076],
                [9.602639198000134, 0.785695016000147],
                [9.603193283000053, 0.785695016000147],
                [9.603193283000053, 0.784582018000037],
                [9.602639198000134, 0.784029007000072],
                [9.602639198000134, 0.783194006000144],
                [9.603472709000073, 0.782360018000077],
                [9.604861259000018, 0.782638013000053],
                [9.604861259000018, 0.780694008000069],
                [9.605139732000055, 0.780138017000183],
                [9.605971336000152, 0.780138017000183],
                [9.60624885599998, 0.779305995000072],
                [9.606805801000178, 0.779305995000072],
                [9.607361794000099, 0.778750003000084],
                [9.607084274000044, 0.777916014000084],
                [9.607084274000044, 0.776805996000064],
                [9.608750344000043, 0.775139987000159],
                [9.609583855000153, 0.77486199100008],
                [9.60986232800019, 0.773750007000103],
                [9.60986232800019, 0.7712489970001],
                [9.609306335000099, 0.770695984000099],
                [9.608750344000043, 0.769582987000092],
                [9.608750344000043, 0.767916978000187],
                [9.609027862000062, 0.767916978000187],
                [9.609027862000062, 0.76569497600002],
                [9.609306335000099, 0.76569497600002],
                [9.61013984699997, 0.76319497899999],
                [9.61013984699997, 0.76236099],
                [9.61069393200006, 0.762082995000185],
                [9.61069393200006, 0.76097297699999],
                [9.611528397000143, 0.760416985000177],
                [9.611528397000143, 0.759303987000123],
                [9.612360001000184, 0.75680703],
                [9.612915992000069, 0.755693973],
                [9.612915992000069, 0.754306018000023],
                [9.613750458000027, 0.753471970000135],
                [9.61430644900014, 0.752084017000129],
                [9.61430644900014, 0.750137985000038],
                [9.614582062000068, 0.750137985000038],
                [9.614582062000068, 0.747915983000041],
                [9.61430644900014, 0.746806025000069],
                [9.61513805300018, 0.745971978000057],
                [9.61513805300018, 0.744584025000052],
                [9.615694046999977, 0.744305015000066],
                [9.615694046999977, 0.743196011000123],
                [9.61625003800009, 0.742361008000046],
                [9.61625003800009, 0.739027023000176],
                [9.61680603100018, 0.737917006999965],
                [9.617083549000029, 0.736805023000159],
                [9.617083549000029, 0.734583020000059],
                [9.616527558000143, 0.733751000000154],
                [9.616527558000143, 0.732361019000166],
                [9.617083549000029, 0.73180699500017],
                [9.617083549000029, 0.730693996000184],
                [9.615971565000052, 0.729305983000188],
                [9.61625003800009, 0.729027987000109],
                [9.615971565000052, 0.727638007],
                [9.615971565000052, 0.725138009000091],
                [9.614862443000106, 0.72458398399999],
                [9.614862443000106, 0.720139981000102],
                [9.614582062000068, 0.719583989000114],
                [9.61347198500016, 0.718470991000061],
                [9.614027976000102, 0.718192996000028],
                [9.614027976000102, 0.717082977000132],
                [9.61430644900014, 0.716248989000064],
                [9.61347198500016, 0.715695025000059],
                [9.61347198500016, 0.712360979000039],
                [9.612915992000069, 0.710695028000146],
                [9.612084388000028, 0.70986098000003],
                [9.611528397000143, 0.70986098000003],
                [9.610972405000098, 0.709304989000145],
                [9.610972405000098, 0.708751023000161],
                [9.610416412000063, 0.708473028000128],
                [9.610416412000063, 0.70763898000007],
                [9.60986232800019, 0.706251025000029],
                [9.610416412000063, 0.704029024000135],
                [9.610416412000063, 0.702359974000046],
                [9.611528397000143, 0.700694026000065],
                [9.612084388000028, 0.700416028000177],
                [9.612360001000184, 0.699584007000169],
                [9.612360001000184, 0.694584013000167],
                [9.611805915000161, 0.694584013000167],
                [9.611528397000143, 0.693193972000188],
                [9.610972405000098, 0.692915976000108],
                [9.610972405000098, 0.691528023000103],
                [9.611528397000143, 0.690971017000038],
                [9.611528397000143, 0.682083010000099],
                [9.612084388000028, 0.681527019000043],
                [9.612084388000028, 0.680694998000035],
                [9.612915992000069, 0.679583014000059],
                [9.612915992000069, 0.678750991000186],
                [9.61347198500016, 0.678472996000039],
                [9.61347198500016, 0.677637995000111],
                [9.61430644900014, 0.677359998000156],
                [9.61430644900014, 0.676249981000012],
                [9.612915992000069, 0.675971986000036],
                [9.612915992000069, 0.674860001000127],
                [9.612360001000184, 0.674027979000016],
                [9.612360001000184, 0.672083974000032],
                [9.612084388000028, 0.670693994000146],
                [9.611805915000161, 0.670693994000146],
                [9.611805915000161, 0.668471993000082],
                [9.611249924000106, 0.667639971000142],
                [9.610972405000098, 0.665971995000177],
                [9.610416412000063, 0.665418029000136],
                [9.610416412000063, 0.664583981000078],
                [9.60986232800019, 0.664305986000045],
                [9.61013984699997, 0.663470982999968],
                [9.61013984699997, 0.661804974000063],
                [9.60986232800019, 0.661804974000063],
                [9.60986232800019, 0.660139025000149],
                [9.609583855000153, 0.660139025000149],
                [9.609583855000153, 0.655973018000168],
                [9.60986232800019, 0.655973018000168],
                [9.60986232800019, 0.652638971999977],
                [9.609583855000153, 0.652638971999977],
                [9.609583855000153, 0.65097302300012],
                [9.610416412000063, 0.650138021000089],
                [9.610416412000063, 0.649582029000101],
                [9.610972405000098, 0.649028002000023],
                [9.610972405000098, 0.645972013],
                [9.61069393200006, 0.645972013],
                [9.61013984699997, 0.6443060040001],
                [9.61013984699997, 0.641528010000116],
                [9.60986232800019, 0.641528010000116],
                [9.60986232800019, 0.63875001800011],
                [9.61013984699997, 0.63875001800011],
                [9.61013984699997, 0.637084007000055],
                [9.610416412000063, 0.637084007000055],
                [9.610416412000063, 0.633472979000032],
                [9.609583855000153, 0.632916987000044],
                [9.609306335000099, 0.632082998000044],
                [9.609306335000099, 0.63097298200006],
                [9.608750344000043, 0.63097298200006],
                [9.608750344000043, 0.628750980000063],
                [9.608194351000122, 0.62652897900017],
                [9.607640267000136, 0.625972986000079],
                [9.607640267000136, 0.62486100100017],
                [9.607084274000044, 0.62458199200006],
                [9.607084274000044, 0.623472990000153],
                [9.606805801000178, 0.622637987000076],
                [9.60624885599998, 0.622637987000076],
                [9.60624885599998, 0.619584025000165],
                [9.60541534500004, 0.617084027000089],
                [9.60624885599998, 0.616249978999974],
                [9.606249, 0.615694],
                [9.60680600000012, 0.614584],
                [9.607362, 0.614584],
                [9.607362, 0.61375],
                [9.607918, 0.613194],
                [9.608194, 0.612362],
                [9.608194, 0.61125],
                [9.60875, 0.61097100000012],
                [9.609028, 0.609862],
                [9.609862, 0.609583],
                [9.61236, 0.609583],
                [9.61375, 0.609862],
                [9.614582, 0.610696],
                [9.615138, 0.610696],
                [9.615694, 0.611528],
                [9.61736, 0.612084],
                [9.618751, 0.612084],
                [9.619307, 0.611528],
                [9.619307, 0.61097100000012],
                [9.619860000000187, 0.610696],
                [9.620139000000165, 0.609583],
                [9.620973, 0.608749000000103],
                [9.622639, 0.608749000000103],
                [9.622361000000126, 0.608193000000142],
                [9.620695000000126, 0.608193000000142],
                [9.620139000000165, 0.608749000000103],
                [9.619582, 0.608749000000103],
                [9.618751, 0.610418],
                [9.616806, 0.611528],
                [9.61625, 0.610696],
                [9.615694, 0.610693],
                [9.614582, 0.609862],
                [9.614582, 0.60930500000012],
                [9.612916, 0.608193000000142],
                [9.612916, 0.604027],
                [9.613472, 0.602083000000107],
                [9.614028, 0.601527000000146],
                [9.614028, 0.600695],
                [9.615138, 0.599861000000146],
                [9.615138, 0.599305000000129],
                [9.615694, 0.599029],
                [9.615694, 0.598195000000146],
                [9.61625, 0.597917],
                [9.61625, 0.595972],
                [9.616806, 0.595138000000134],
                [9.617084000000148, 0.59375],
                [9.61763800000017, 0.592638],
                [9.61763800000017, 0.591528],
                [9.618194000000187, 0.591528],
                [9.618194000000187, 0.589862],
                [9.618751, 0.589306],
                [9.618751, 0.586806000000138],
                [9.619029000000126, 0.58569400000016],
                [9.618194000000187, 0.584862],
                [9.61763800000017, 0.583749000000182],
                [9.61763800000017, 0.581527000000165],
                [9.61736, 0.581527000000165],
                [9.61736, 0.579583],
                [9.61763800000017, 0.579583],
                [9.61763800000017, 0.577083000000187],
                [9.617916, 0.576527000000169],
                [9.619029000000126, 0.57569500000011],
                [9.619029000000126, 0.575139],
                [9.619860000000187, 0.574861000000169],
                [9.620139000000165, 0.573751000000186],
                [9.621251000000143, 0.57402900000011],
                [9.624029000000121, 0.57402900000011],
                [9.624029000000121, 0.574305],
                [9.62680500000016, 0.574305],
                [9.628195, 0.573751000000186],
                [9.629027000000121, 0.572917],
                [9.629027000000121, 0.572361000000114],
                [9.629861, 0.571529000000169],
                [9.629861, 0.569029000000114],
                [9.629583000000139, 0.568194000000119],
                [9.628749, 0.567638000000102],
                [9.628749, 0.567084000000136],
                [9.627917000000139, 0.56625],
                [9.627917000000139, 0.562084000000141],
                [9.628195, 0.562084000000141],
                [9.628195, 0.559306000000106],
                [9.628749, 0.557918],
                [9.629305, 0.557640000000106],
                [9.63541800000013, 0.557640000000106],
                [9.63625, 0.557362],
                [9.636806, 0.556806],
                [9.636528000000112, 0.55624900000015],
                [9.635138, 0.556527],
                [9.633471000000156, 0.556527],
                [9.633471000000156, 0.556806],
                [9.629583000000139, 0.556806],
                [9.628195, 0.558194000000128],
                [9.627361000000121, 0.558750000000146],
                [9.627083, 0.559306000000106],
                [9.627083, 0.561528000000123],
                [9.62680500000016, 0.561528000000123],
                [9.62680500000016, 0.565138],
                [9.627083, 0.565138],
                [9.627639, 0.566806],
                [9.628749, 0.569029000000114],
                [9.628749, 0.570138],
                [9.627917000000139, 0.571805],
                [9.627361000000121, 0.571805],
                [9.627083, 0.572361000000114],
                [9.626251000000138, 0.572639],
                [9.622361000000126, 0.572639],
                [9.622083, 0.572083],
                [9.620695000000126, 0.572083],
                [9.619860000000187, 0.571805],
                [9.619582, 0.570973],
                [9.619029000000126, 0.570695000000114],
                [9.619029000000126, 0.569029000000114],
                [9.618194000000187, 0.567916],
                [9.61763800000017, 0.567916],
                [9.617084000000148, 0.567084000000136],
                [9.616528000000187, 0.567084000000136],
                [9.61597200000017, 0.565416000000141],
                [9.61597200000017, 0.560694],
                [9.615694, 0.560694],
                [9.615694, 0.546527],
                [9.615416, 0.545973],
                [9.614862000000187, 0.545973],
                [9.614862000000187, 0.544029],
                [9.615416, 0.544029],
                [9.61597200000017, 0.542916000000105],
                [9.61625, 0.541250000000105],
                [9.61597200000017, 0.540694],
                [9.615416, 0.540694],
                [9.615138, 0.540138],
                [9.614582, 0.540138],
                [9.61430600000017, 0.539028],
                [9.613472, 0.538750000000164],
                [9.613472, 0.538194],
                [9.612638000000175, 0.537916000000109],
                [9.61236, 0.536806],
                [9.61236, 0.53514],
                [9.612638000000175, 0.53514],
                [9.612638000000175, 0.533472000000131],
                [9.61236, 0.532084],
                [9.612638000000175, 0.530972],
                [9.613472, 0.530693],
                [9.61375, 0.529027],
                [9.612084, 0.528749000000118],
                [9.611528, 0.528196],
                [9.610694, 0.528196],
                [9.609862, 0.526805],
                [9.609584, 0.526805],
                [9.609584, 0.524583],
                [9.609306000000174, 0.523473],
                [9.60875, 0.523195000000101],
                [9.60875, 0.522083000000123],
                [9.607640000000174, 0.519861000000162],
                [9.607640000000174, 0.518751000000123],
                [9.608194, 0.518751000000123],
                [9.60847200000012, 0.518195000000162],
                [9.607640000000174, 0.517082],
                [9.607640000000174, 0.516529000000162],
                [9.605696, 0.514582000000132],
                [9.604583, 0.513194],
                [9.604583, 0.512638],
                [9.604027, 0.512084],
                [9.602917, 0.512084],
                [9.602639000000124, 0.513194],
                [9.601805, 0.513194],
                [9.601805, 0.51375],
                [9.600695, 0.51375],
                [9.599583, 0.514307000000144],
                [9.599305000000129, 0.51513800000015],
                [9.598751000000107, 0.515416],
                [9.597083000000111, 0.515416],
                [9.596249, 0.516251],
                [9.595417000000111, 0.516251],
                [9.595139, 0.515694000000167],
                [9.594026000000156, 0.51486],
                [9.593751000000111, 0.514028000000167],
                [9.59319500000015, 0.513472000000149],
                [9.592638, 0.513472000000149],
                [9.592082, 0.512916000000132],
                [9.592082, 0.512360000000172],
                [9.59152900000015, 0.511250000000132],
                [9.590972, 0.509584000000132],
                [9.590416, 0.509306],
                [9.590416, 0.507916],
                [9.58986, 0.507362000000171],
                [9.58986, 0.506528],
                [9.589306, 0.50625],
                [9.58875, 0.504584],
                [9.58875, 0.502362000000176],
                [9.588472000000138, 0.501527000000181],
                [9.589306, 0.501249],
                [9.589584000000116, 0.500417],
                [9.590138000000138, 0.500417],
                [9.590138000000138, 0.499583],
                [9.590972, 0.498749],
                [9.590972, 0.497361000000126],
                [9.591250000000116, 0.496249],
                [9.591807, 0.495417],
                [9.591807, 0.494305],
                [9.591250000000116, 0.492639],
                [9.590694000000155, 0.49236100000013],
                [9.590694000000155, 0.491251],
                [9.590138000000138, 0.49069500000013],
                [9.590138000000138, 0.487082000000157],
                [9.590416, 0.487082000000157],
                [9.590416, 0.484028],
                [9.590972, 0.482084000000157],
                [9.592082, 0.48125],
                [9.592082, 0.480694],
                [9.592638, 0.480138],
                [9.592360000000156, 0.479028],
                [9.591807, 0.479028],
                [9.590972, 0.477918],
                [9.590416, 0.477918],
                [9.58791600000012, 0.475418000000161],
                [9.586806000000138, 0.47514],
                [9.585972, 0.476528000000144],
                [9.58486, 0.476806],
                [9.583472000000143, 0.476806],
                [9.58236200000016, 0.475971],
                [9.581806000000142, 0.475971],
                [9.581528, 0.47514],
                [9.580972, 0.47514],
                [9.580694000000165, 0.474583000000166],
                [9.579584000000182, 0.474027000000149],
                [9.578471, 0.473749],
                [9.578196, 0.473195],
                [9.577083000000187, 0.472917000000166],
                [9.577083000000187, 0.472083],
                [9.576249, 0.471805000000188],
                [9.576249, 0.471249000000171],
                [9.574861000000169, 0.469861],
                [9.574861000000169, 0.468473000000188],
                [9.574027000000115, 0.467639],
                [9.574027000000115, 0.466805],
                [9.573471, 0.46625100000017],
                [9.573471, 0.465139],
                [9.572917, 0.465139],
                [9.572917, 0.464305],
                [9.571527000000174, 0.463195],
                [9.570973, 0.46208200000018],
                [9.570417000000191, 0.461529],
                [9.569305, 0.461251000000175],
                [9.569029000000114, 0.460694000000103],
                [9.567917, 0.460694000000103],
                [9.567639, 0.45958400000012],
                [9.566807, 0.459582000000125],
                [9.566526000000124, 0.459028000000103],
                [9.564860000000124, 0.458472],
                [9.563472, 0.457084000000179],
                [9.562916, 0.456806],
                [9.562916, 0.456250000000125],
                [9.56236, 0.455972],
                [9.56236, 0.454862],
                [9.562084, 0.454028000000108],
                [9.56125, 0.453750000000184],
                [9.560694, 0.453194],
                [9.559584, 0.453194],
                [9.559584, 0.452084000000184],
                [9.559028, 0.451806],
                [9.559028, 0.45125000000013],
                [9.558194000000128, 0.450415000000135],
                [9.558194000000128, 0.449306],
                [9.55736, 0.446527000000117],
                [9.557084000000145, 0.445973000000151],
                [9.55625, 0.445973000000151],
                [9.557084000000145, 0.448749000000134],
                [9.557084000000145, 0.450694000000112],
                [9.55736, 0.451528],
                [9.558194000000128, 0.452084000000184],
                [9.558750000000146, 0.453194],
                [9.559862000000123, 0.454306],
                [9.560138, 0.455416000000184],
                [9.56125, 0.456250000000125],
                [9.561528000000123, 0.457084000000179],
                [9.563194000000124, 0.459028000000103],
                [9.56375, 0.459028000000103],
                [9.564860000000124, 0.46041600000018],
                [9.565695000000119, 0.46041600000018],
                [9.566251, 0.460972],
                [9.566807, 0.460972],
                [9.567083, 0.461529],
                [9.568195000000173, 0.461807],
                [9.569029000000114, 0.462917000000175],
                [9.569861000000174, 0.462917000000175],
                [9.570973, 0.464029000000153],
                [9.571251, 0.465139],
                [9.571805, 0.465417],
                [9.571805, 0.46625100000017],
                [9.572361000000114, 0.466805],
                [9.572361000000114, 0.467639],
                [9.572917, 0.46791700000017],
                [9.572917, 0.468473000000188],
                [9.573471, 0.468751],
                [9.573471, 0.469583000000171],
                [9.574027000000115, 0.470139000000188],
                [9.574027000000115, 0.470973],
                [9.574583, 0.471527],
                [9.574861000000169, 0.472639],
                [9.575417000000186, 0.473195],
                [9.575973, 0.473195],
                [9.575973, 0.474027000000149],
                [9.576805, 0.474305],
                [9.576805, 0.47514],
                [9.578471, 0.476806],
                [9.578471, 0.477918],
                [9.579027, 0.478750000000161],
                [9.579027, 0.479862000000139],
                [9.578471, 0.480416000000162],
                [9.578471, 0.48125],
                [9.577361, 0.482084000000157],
                [9.577361, 0.482916],
                [9.576805, 0.483472],
                [9.576805, 0.484584],
                [9.576249, 0.48486000000014],
                [9.576249, 0.48902900000013],
                [9.576805, 0.489582],
                [9.576805, 0.490139000000113],
                [9.577639, 0.490417],
                [9.578196, 0.49236100000013],
                [9.578196, 0.494861000000185],
                [9.576805, 0.495973],
                [9.576805, 0.497083],
                [9.576249, 0.497361000000126],
                [9.575973, 0.498473000000104],
                [9.574861000000169, 0.499583],
                [9.573195000000169, 0.499583],
                [9.571805, 0.49986100000018],
                [9.571251, 0.500417],
                [9.570139, 0.500139000000104],
                [9.56875100000019, 0.498749],
                [9.565973, 0.498749],
                [9.565695000000119, 0.499583],
                [9.565138, 0.499583],
                [9.564028, 0.500139000000104],
                [9.562916, 0.500139000000104],
                [9.562084, 0.499583],
                [9.560972000000106, 0.499583],
                [9.560416000000146, 0.498749],
                [9.559584, 0.498749],
                [9.559028, 0.49819500000018],
                [9.556806, 0.49819500000018],
                [9.556528000000128, 0.497639],
                [9.55430600000011, 0.497083],
                [9.552362, 0.497083],
                [9.551249000000155, 0.496527000000185],
                [9.549305, 0.496527000000185],
                [9.548195, 0.495973],
                [9.547083, 0.495973],
                [9.545695000000137, 0.495417],
                [9.545417, 0.494861000000185],
                [9.544861, 0.494861000000185],
                [9.544029000000137, 0.494027000000131],
                [9.544027, 0.487082000000157],
                [9.543195, 0.484306000000117],
                [9.542639, 0.484028],
                [9.542639, 0.483472],
                [9.541529, 0.48319400000014],
                [9.541251000000159, 0.482638000000122],
                [9.53958200000011, 0.482084000000157],
                [9.53791600000011, 0.482084000000157],
                [9.53736, 0.482362],
                [9.538473000000181, 0.483472],
                [9.539307, 0.48319400000014],
                [9.540417, 0.48319400000014],
                [9.541529, 0.484306000000117],
                [9.542361, 0.48486000000014],
                [9.542917000000159, 0.485972000000118],
                [9.542917000000159, 0.489582],
                [9.542639, 0.489582],
                [9.542639, 0.491529000000185],
                [9.542917000000159, 0.491529000000185],
                [9.542917000000159, 0.493751],
                [9.543473000000176, 0.495139000000108],
                [9.54458300000016, 0.496249],
                [9.545695000000137, 0.496805000000109],
                [9.547083, 0.496805000000109],
                [9.547639, 0.497361000000126],
                [9.549861, 0.497917],
                [9.551805000000115, 0.497917],
                [9.551805000000115, 0.49819500000018],
                [9.553193, 0.498473000000104],
                [9.554584, 0.498473000000104],
                [9.555694, 0.499027000000126],
                [9.557084000000145, 0.499027000000126],
                [9.557916, 0.49986100000018],
                [9.558750000000146, 0.49986100000018],
                [9.559306000000106, 0.500417],
                [9.559306000000106, 0.500971],
                [9.559862000000123, 0.501249],
                [9.559862000000123, 0.501805000000104],
                [9.56125, 0.503193000000181],
                [9.561806, 0.503471000000104],
                [9.565138, 0.503471000000104],
                [9.565416, 0.504306],
                [9.565973, 0.504306],
                [9.566526000000124, 0.504862],
                [9.566526000000124, 0.505972],
                [9.567083, 0.506528],
                [9.567083, 0.510416],
                [9.566526000000124, 0.511528],
                [9.565138, 0.511250000000132],
                [9.564304000000106, 0.511806000000149],
                [9.564304000000106, 0.514028000000167],
                [9.564585, 0.514028000000167],
                [9.564585, 0.515694000000167],
                [9.564860000000124, 0.517082],
                [9.564585, 0.517917],
                [9.564860000000124, 0.518473],
                [9.56375, 0.519027],
                [9.56375, 0.520139],
                [9.563194000000124, 0.521805],
                [9.562638000000106, 0.522083000000123],
                [9.562638000000106, 0.52263900000014],
                [9.562084, 0.522917],
                [9.562084, 0.524027],
                [9.561528000000123, 0.524583],
                [9.56125, 0.525695],
                [9.560694, 0.526805],
                [9.560694, 0.533472000000131],
                [9.56125, 0.53375],
                [9.562638000000106, 0.537084000000164],
                [9.56375, 0.538194],
                [9.564028, 0.539306000000181],
                [9.564585, 0.539584000000104],
                [9.564585, 0.540138],
                [9.566251, 0.541528],
                [9.566807, 0.541528],
                [9.566807, 0.54236],
                [9.567361000000119, 0.54236],
                [9.567639, 0.542916000000105],
                [9.567639, 0.544583000000159],
                [9.568195000000173, 0.545139000000177],
                [9.568195000000173, 0.547361],
                [9.568473, 0.5487490000001],
                [9.56875100000019, 0.548751],
                [9.56875100000019, 0.550695000000133],
                [9.569305, 0.551249000000155],
                [9.569583, 0.552361000000133],
                [9.569583, 0.555139000000167],
                [9.569029000000114, 0.55624900000015],
                [9.569029000000114, 0.557640000000106],
                [9.568473, 0.558472],
                [9.568473, 0.56014],
                [9.568195000000173, 0.560972000000106],
                [9.567639, 0.56125],
                [9.567639, 0.562362],
                [9.567083, 0.562638000000106],
                [9.567083, 0.564028],
                [9.566251, 0.567638000000102],
                [9.565695000000119, 0.567916],
                [9.565695000000119, 0.569582],
                [9.565416, 0.569582],
                [9.565416, 0.571529000000169],
                [9.564860000000124, 0.572361000000114],
                [9.564860000000124, 0.574861000000169],
                [9.564304000000106, 0.575417000000186],
                [9.564028, 0.576251],
                [9.564028, 0.577639],
                [9.563472, 0.578471],
                [9.563472, 0.580139],
                [9.563194000000124, 0.580139],
                [9.562638000000106, 0.581249],
                [9.562638000000106, 0.583193000000165],
                [9.562084, 0.583193000000165],
                [9.56125, 0.583749000000182],
                [9.559862000000123, 0.583749000000182],
                [9.559862000000123, 0.586250000000177],
                [9.559306000000106, 0.587084],
                [9.559306000000106, 0.58875],
                [9.559028, 0.58875],
                [9.559028, 0.592084],
                [9.558472, 0.593194],
                [9.558472, 0.594306],
                [9.558194000000128, 0.595416],
                [9.557640000000106, 0.595416],
                [9.55736, 0.596529000000146],
                [9.556806, 0.597082],
                [9.553725, 0.597085000000106],
                [9.55264000000011, 0.596529000000146],
                [9.551249000000155, 0.596529000000146],
                [9.551249000000155, 0.595694000000151],
                [9.550417, 0.595416],
                [9.550417, 0.59486],
                [9.549583000000155, 0.594582000000173],
                [9.549305, 0.593472000000133],
                [9.548195, 0.592638],
                [9.548195, 0.592084],
                [9.547639, 0.591806000000133],
                [9.547639, 0.587916000000178],
                [9.547361000000137, 0.587916000000178],
                [9.547361000000137, 0.586250000000177],
                [9.547083, 0.585416],
                [9.546527, 0.584862],
                [9.546527, 0.583749000000182],
                [9.545695000000137, 0.582918000000177],
                [9.545695000000137, 0.581249],
                [9.545139000000177, 0.580695000000105],
                [9.545139000000177, 0.579861000000164],
                [9.544305, 0.579305],
                [9.544305, 0.578195000000164],
                [9.543473000000176, 0.57736100000011],
                [9.543473000000176, 0.576527000000169],
                [9.542917000000159, 0.57569500000011],
                [9.542917000000159, 0.573751000000186],
                [9.542361, 0.573195000000169],
                [9.542361, 0.571805],
                [9.541805000000181, 0.570695000000114],
                [9.541805000000181, 0.569029000000114],
                [9.541251000000159, 0.567084000000136],
                [9.540695, 0.566528000000119],
                [9.540695, 0.564306000000101],
                [9.541529, 0.563472],
                [9.541529, 0.562916],
                [9.542639, 0.561528000000123],
                [9.543473000000176, 0.56125],
                [9.544027, 0.560694],
                [9.54458300000016, 0.559584],
                [9.545695000000137, 0.558472],
                [9.546805000000177, 0.558194000000128],
                [9.549027000000137, 0.555971],
                [9.549583000000155, 0.555139000000167],
                [9.550139000000115, 0.555139000000167],
                [9.550696, 0.55458300000015],
                [9.550971, 0.553471000000172],
                [9.550971, 0.551805000000172],
                [9.550696, 0.550973],
                [9.550139000000115, 0.550417],
                [9.550139000000115, 0.549861],
                [9.549583000000155, 0.549583000000155],
                [9.549027000000137, 0.5487490000001],
                [9.545971, 0.5487490000001],
                [9.545971, 0.549027],
                [9.544305, 0.549027],
                [9.543749, 0.548473000000172],
                [9.544027, 0.547917000000155],
                [9.544027, 0.546527],
                [9.543473000000176, 0.545695],
                [9.542361, 0.545695],
                [9.543195, 0.546527],
                [9.543195, 0.549861],
                [9.543749, 0.550139000000172],
                [9.545417, 0.550139000000172],
                [9.546527, 0.549583000000155],
                [9.547917000000155, 0.549583000000155],
                [9.548749, 0.549861],
                [9.550139000000115, 0.551249000000155],
                [9.550139000000115, 0.553471000000172],
                [9.549583000000155, 0.554027000000133],
                [9.549583000000155, 0.554861],
                [9.548749, 0.555693000000133],
                [9.547083, 0.556806],
                [9.545695000000137, 0.557084000000145],
                [9.54458300000016, 0.558194000000128],
                [9.54458300000016, 0.558472],
                [9.543473000000176, 0.559584],
                [9.542917000000159, 0.559584],
                [9.542639, 0.56014],
                [9.542083, 0.56014],
                [9.540973, 0.560972000000106],
                [9.539861, 0.560972000000106],
                [9.538751, 0.56125],
                [9.538473000000181, 0.560694],
                [9.53791600000011, 0.560694],
                [9.537638000000186, 0.56014],
                [9.536528, 0.56014],
                [9.535972000000186, 0.559584],
                [9.534862, 0.559584],
                [9.532916000000114, 0.558750000000146],
                [9.532916000000114, 0.558194000000128],
                [9.532362, 0.557918],
                [9.531250000000114, 0.557918],
                [9.53097200000019, 0.557362],
                [9.530416, 0.557362],
                [9.53014, 0.556806],
                [9.529584000000114, 0.556806],
                [9.52930600000019, 0.55624900000015],
                [9.527918000000113, 0.555693000000133],
                [9.526250000000118, 0.555693000000133],
                [9.524583, 0.555139000000167],
                [9.524027, 0.55458300000015],
                [9.523749000000123, 0.553471000000172],
                [9.523193000000106, 0.553471000000172],
                [9.523193000000106, 0.551527],
                [9.522917, 0.551527],
                [9.522917, 0.547639],
                [9.522361, 0.545973],
                [9.521527000000106, 0.544304000000182],
                [9.520139, 0.543195],
                [9.519861000000105, 0.54236],
                [9.519027, 0.541250000000105],
                [9.517639000000145, 0.53986],
                [9.517361, 0.538750000000164],
                [9.517361, 0.537084000000164],
                [9.517639000000145, 0.537084000000164],
                [9.517639000000145, 0.531528],
                [9.516805, 0.530140000000131],
                [9.516249, 0.530140000000131],
                [9.515973000000145, 0.529305000000136],
                [9.515417000000127, 0.529305000000136],
                [9.514583, 0.528196],
                [9.514027, 0.528196],
                [9.51263900000015, 0.526805],
                [9.512082, 0.525417000000118],
                [9.512082, 0.523473],
                [9.511807, 0.523473],
                [9.511807, 0.521805],
                [9.512082, 0.52097300000014],
                [9.51263900000015, 0.520695],
                [9.51263900000015, 0.519583],
                [9.51319500000011, 0.519305000000145],
                [9.513751000000127, 0.518195000000162],
                [9.513751000000127, 0.517082],
                [9.51430500000015, 0.516529000000162],
                [9.514583, 0.51513800000015],
                [9.513473, 0.514582000000132],
                [9.51263900000015, 0.514582000000132],
                [9.512082, 0.514028000000167],
                [9.510972, 0.514028000000167],
                [9.509306, 0.513472000000149],
                [9.509028000000171, 0.514582000000132],
                [9.510972, 0.514582000000132],
                [9.512082, 0.51486],
                [9.512917, 0.515694000000167],
                [9.512917, 0.517639000000145],
                [9.512360000000172, 0.518195000000162],
                [9.512360000000172, 0.519027],
                [9.511807, 0.519583],
                [9.511807, 0.520417000000123],
                [9.511251, 0.520695],
                [9.510972, 0.521527000000162],
                [9.510972, 0.522917],
                [9.510694000000171, 0.524027],
                [9.511251, 0.524583],
                [9.511807, 0.526249],
                [9.511807, 0.527083000000118],
                [9.512917, 0.528196],
                [9.513751000000127, 0.529305000000136],
                [9.51486100000011, 0.529862],
                [9.516249, 0.530972],
                [9.516529000000105, 0.531528],
                [9.516529000000105, 0.53375],
                [9.516805, 0.53375],
                [9.516805, 0.537362],
                [9.516529000000105, 0.537362],
                [9.516529000000105, 0.539584000000104],
                [9.516805, 0.540416000000164],
                [9.517361, 0.540694],
                [9.517361, 0.541528],
                [9.518195000000105, 0.542082000000164],
                [9.518195000000105, 0.542638000000181],
                [9.519305000000145, 0.543473000000176],
                [9.520417000000123, 0.544583000000159],
                [9.520417000000123, 0.5454170000001],
                [9.52097300000014, 0.545695],
                [9.521527000000106, 0.5470830000001],
                [9.521527000000106, 0.5487490000001],
                [9.521805, 0.548751],
                [9.521805, 0.551249000000155],
                [9.522361, 0.552083],
                [9.522361, 0.554861],
                [9.522917, 0.554861],
                [9.523193000000106, 0.55624900000015],
                [9.523749000000123, 0.55624900000015],
                [9.524027, 0.557362],
                [9.524583, 0.557362],
                [9.524862, 0.558194000000128],
                [9.52597100000014, 0.558750000000146],
                [9.527084, 0.559862000000123],
                [9.529028, 0.559862000000123],
                [9.529862, 0.56014],
                [9.530416, 0.560972000000106],
                [9.530416, 0.565138],
                [9.530694, 0.565138],
                [9.530694, 0.56736],
                [9.52930600000019, 0.567638000000102],
                [9.52930600000019, 0.568194000000119],
                [9.528472, 0.568472],
                [9.528472, 0.570138],
                [9.528194, 0.570138],
                [9.528194, 0.571805],
                [9.529028, 0.572639],
                [9.52875, 0.573195000000169],
                [9.52875, 0.574583],
                [9.527918000000113, 0.575139],
                [9.527918000000113, 0.57569500000011],
                [9.526806, 0.575973],
                [9.526528, 0.576527000000169],
                [9.525415000000123, 0.576805],
                [9.52514, 0.577639],
                [9.524027, 0.577917],
                [9.523193000000106, 0.578749000000187],
                [9.520417000000123, 0.578749000000187],
                [9.519583, 0.579583],
                [9.518195000000105, 0.579583],
                [9.517639000000145, 0.579305],
                [9.515973000000145, 0.577639],
                [9.515417000000127, 0.577639],
                [9.515139, 0.576527000000169],
                [9.515139, 0.574861000000169],
                [9.51486100000011, 0.574861000000169],
                [9.51486100000011, 0.572639],
                [9.51430500000015, 0.571529000000169],
                [9.513473, 0.570973],
                [9.513473, 0.570417],
                [9.51263900000015, 0.569582],
                [9.511251, 0.568750000000136],
                [9.511251, 0.568194000000119],
                [9.510416, 0.567916],
                [9.510138000000154, 0.56625],
                [9.509306, 0.565416000000141],
                [9.509028000000171, 0.564306000000101],
                [9.507916, 0.563472],
                [9.507916, 0.564860000000124],
                [9.508472000000154, 0.565416000000141],
                [9.50875, 0.56625],
                [9.509584000000132, 0.567084000000136],
                [9.509584000000132, 0.568194000000119],
                [9.510416, 0.568472],
                [9.511807, 0.570138],
                [9.512360000000172, 0.570973],
                [9.51319500000011, 0.571529000000169],
                [9.514027, 0.573473],
                [9.514027, 0.576527000000169],
                [9.513473, 0.578749000000187],
                [9.51263900000015, 0.579861000000164],
                [9.51152900000011, 0.580417000000182],
                [9.510972, 0.580417000000182],
                [9.510694000000171, 0.580971],
                [9.509584000000132, 0.581527000000165],
                [9.509028000000171, 0.581527000000165],
                [9.508194, 0.582361000000105],
                [9.507082, 0.582361000000105],
                [9.506806000000154, 0.582918000000177],
                [9.505140000000154, 0.582918000000177],
                [9.50486, 0.583749000000182],
                [9.504306, 0.584306],
                [9.50375, 0.584306],
                [9.50375, 0.585140000000138],
                [9.502916, 0.585416],
                [9.502638000000104, 0.586528],
                [9.501528, 0.586806000000138],
                [9.50125, 0.587916000000178],
                [9.499862, 0.589028000000155],
                [9.499584, 0.590138000000138],
                [9.498749, 0.590416],
                [9.498749, 0.590972],
                [9.498196, 0.591250000000173],
                [9.498196, 0.592084],
                [9.497640000000104, 0.592360000000156],
                [9.497640000000104, 0.592916000000173],
                [9.495973000000163, 0.592916000000173],
                [9.495417, 0.593194],
                [9.495417, 0.59375],
                [9.494583, 0.594028000000151],
                [9.494305, 0.595138000000134],
                [9.493195000000185, 0.595138000000134],
                [9.492917, 0.595416],
                [9.492917, 0.600695],
                [9.497361, 0.600695],
                [9.498196, 0.600139],
                [9.499306000000104, 0.600139],
                [9.500140000000158, 0.599583],
                [9.501528, 0.599583],
                [9.501528, 0.599029],
                [9.502638000000104, 0.599029],
                [9.50375, 0.597917],
                [9.504584, 0.597917],
                [9.505416, 0.597082],
                [9.506528, 0.597082],
                [9.507082, 0.596807],
                [9.507082, 0.596251],
                [9.507916, 0.595972],
                [9.510972, 0.595972],
                [9.51263900000015, 0.595138000000134],
                [9.512917, 0.594306],
                [9.51430500000015, 0.594028000000151],
                [9.515417000000127, 0.592916000000173],
                [9.516249, 0.592638],
                [9.517083000000127, 0.591806000000133],
                [9.518473, 0.591528],
                [9.518473, 0.590972],
                [9.519861000000105, 0.590694000000155],
                [9.520417000000123, 0.589862],
                [9.522361, 0.589862],
                [9.522361, 0.589584000000173],
                [9.524027, 0.589584000000173],
                [9.524583, 0.590138000000138],
                [9.52514, 0.590138000000138],
                [9.525415000000123, 0.590694000000155],
                [9.527918000000113, 0.590694000000155],
                [9.527918000000113, 0.590416],
                [9.530416, 0.590416],
                [9.530416, 0.590138000000138],
                [9.53263800000019, 0.590138000000138],
                [9.533472, 0.590972],
                [9.533472, 0.592084],
                [9.534028, 0.592638],
                [9.534582000000114, 0.594306],
                [9.53486, 0.595694000000151],
                [9.534862, 0.597360000000151],
                [9.535416000000168, 0.598195000000146],
                [9.535416000000168, 0.600139],
                [9.535972000000186, 0.600695],
                [9.535972000000186, 0.604583],
                [9.53625000000011, 0.604583],
                [9.53625000000011, 0.607083000000102],
                [9.536528, 0.607083000000102],
                [9.536804, 0.608471],
                [9.536804, 0.612916],
                [9.535972000000186, 0.61375],
                [9.535972000000186, 0.61430600000017],
                [9.535138, 0.614584],
                [9.534582000000114, 0.615694],
                [9.534306000000186, 0.616806000000111],
                [9.534306000000186, 0.620138000000111],
                [9.534582000000114, 0.620138000000111],
                [9.534582000000114, 0.625139000000161],
                [9.534028, 0.626251000000138],
                [9.533472, 0.626529],
                [9.533472, 0.628473000000156],
                [9.532916000000114, 0.629027000000178],
                [9.532916000000114, 0.629583000000139],
                [9.532362, 0.629861],
                [9.532362, 0.630973],
                [9.531806, 0.631249000000139],
                [9.531806, 0.632083],
                [9.53097200000019, 0.632917000000134],
                [9.53097200000019, 0.634027000000117],
                [9.530416, 0.634861],
                [9.53014, 0.635971],
                [9.529028, 0.635971],
                [9.528472, 0.637084000000129],
                [9.528472, 0.63875000000013],
                [9.527918000000113, 0.64041600000013],
                [9.526806, 0.640972000000147],
                [9.52597100000014, 0.640972000000147],
                [9.524862, 0.641528000000108],
                [9.524862, 0.642084000000125],
                [9.524027, 0.642638000000147],
                [9.523471, 0.642638000000147],
                [9.523193000000106, 0.643194000000108],
                [9.52263900000014, 0.643194000000108],
                [9.522083000000123, 0.644306],
                [9.522083000000123, 0.645972],
                [9.522361, 0.646528000000103],
                [9.523749000000123, 0.647638],
                [9.523471, 0.648750000000121],
                [9.521527000000106, 0.64958200000018],
                [9.521249, 0.650416000000121],
                [9.521249, 0.651529],
                [9.520139, 0.652361],
                [9.519027, 0.653473],
                [9.519027, 0.654305],
                [9.517639000000145, 0.656805],
                [9.517639000000145, 0.657639000000188],
                [9.517083000000127, 0.657917],
                [9.516805, 0.659027],
                [9.516805, 0.660139],
                [9.515973000000145, 0.660417000000166],
                [9.515695, 0.661249],
                [9.514027, 0.662918000000161],
                [9.514027, 0.663471],
                [9.512360000000172, 0.664027],
                [9.510138000000154, 0.664027],
                [9.50875, 0.663749000000166],
                [9.506528, 0.664862],
                [9.505694000000176, 0.664862],
                [9.504584, 0.666528],
                [9.504028000000176, 0.666528],
                [9.50375, 0.667084],
                [9.502084, 0.667084],
                [9.501528, 0.66764],
                [9.500972000000104, 0.66764],
                [9.500691, 0.668242000000134],
                [9.499584, 0.668194],
                [9.498749, 0.66875],
                [9.496805000000109, 0.66875],
                [9.496249, 0.668472000000122],
                [9.496249, 0.667916000000162],
                [9.495139000000108, 0.66764],
                [9.495139000000108, 0.666528],
                [9.495693, 0.666528],
                [9.495693, 0.664584000000161],
                [9.493749, 0.664584000000161],
                [9.492917, 0.664306],
                [9.492639, 0.664862],
                [9.49152700000019, 0.664584000000161],
                [9.490695, 0.664862],
                [9.489305, 0.664862],
                [9.488751, 0.664306],
                [9.488473000000113, 0.663471],
                [9.487361000000135, 0.663471],
                [9.48652600000014, 0.662918000000161],
                [9.48486000000014, 0.662918000000161],
                [9.4837500000001, 0.661805],
                [9.482916, 0.661527000000149],
                [9.48236, 0.660971000000188],
                [9.48152800000014, 0.660971000000188],
                [9.480694, 0.659861000000149],
                [9.479584, 0.659027],
                [9.478750000000105, 0.659027],
                [9.478194000000144, 0.658473],
                [9.476528000000144, 0.658473],
                [9.475972000000127, 0.658749000000171],
                [9.474028, 0.656805],
                [9.473750000000166, 0.656251],
                [9.472640000000126, 0.656251],
                [9.472640000000126, 0.655695],
                [9.46958300000017, 0.655695],
                [9.468749, 0.654861],
                [9.467083, 0.655139],
                [9.467083, 0.655695],
                [9.465695000000153, 0.655973000000188],
                [9.464305, 0.654583000000116],
                [9.464305, 0.652083000000175],
                [9.463195, 0.650973],
                [9.463195, 0.649307],
                [9.462361000000158, 0.648750000000121],
                [9.462361000000158, 0.647638],
                [9.461805, 0.646806],
                [9.461251000000175, 0.647082000000125],
                [9.45958300000018, 0.64708400000012],
                [9.45958300000018, 0.64736],
                [9.45291600000013, 0.64736],
                [9.450972, 0.646806],
                [9.450416000000189, 0.646806],
                [9.449306, 0.645416000000125],
                [9.449306, 0.644028],
                [9.449028000000112, 0.642916],
                [9.448194, 0.642362],
                [9.447638, 0.641528000000108],
                [9.446528, 0.64125],
                [9.444305, 0.64125],
                [9.444027, 0.64041600000013],
                [9.442917, 0.640694],
                [9.441805, 0.640694],
                [9.441527000000121, 0.64125],
                [9.440417000000139, 0.641528000000108],
                [9.43763900000016, 0.641528000000108],
                [9.436529000000121, 0.640972000000147],
                [9.436529000000121, 0.640694],
                [9.427638, 0.640694],
                [9.427638, 0.640972000000147],
                [9.425416, 0.640972000000147],
                [9.425416, 0.64125],
                [9.421528, 0.64125],
                [9.420972, 0.642084000000125],
                [9.419306, 0.642916],
                [9.417915000000107, 0.642916],
                [9.417362, 0.643472],
                [9.416249000000107, 0.643472],
                [9.415417, 0.644306],
                [9.414305000000184, 0.644306],
                [9.413749, 0.644860000000108],
                [9.411805000000129, 0.644860000000108],
                [9.409583000000111, 0.644306],
                [9.409029, 0.644860000000108],
                [9.408473000000129, 0.644860000000108],
                [9.408195, 0.645416000000125],
                [9.407083, 0.645694],
                [9.404582, 0.645694],
                [9.404582, 0.645416000000125],
                [9.40125, 0.645416000000125],
                [9.40041600000012, 0.645138],
                [9.399862000000155, 0.645416000000125],
                [9.399028, 0.644028],
                [9.398472, 0.643750000000125],
                [9.397916, 0.642084000000125],
                [9.39736, 0.642084000000125],
                [9.39652800000016, 0.64041600000013],
                [9.395694, 0.639584],
                [9.395694, 0.63875000000013],
                [9.39486200000016, 0.637640000000147],
                [9.39486200000016, 0.636527],
                [9.394028, 0.635971],
                [9.392918, 0.634861],
                [9.392918, 0.634305],
                [9.391806, 0.633473000000151],
                [9.391249000000187, 0.633473000000151],
                [9.39014, 0.632361000000117],
                [9.389583000000187, 0.631249000000139],
                [9.387639, 0.629861],
                [9.387361000000169, 0.629027000000178],
                [9.386527, 0.629027000000178],
                [9.386527, 0.628473000000156],
                [9.384305, 0.626805000000161],
                [9.382917000000191, 0.625417],
                [9.382361000000174, 0.625139000000161],
                [9.381529, 0.624029000000178],
                [9.380695000000173, 0.623751],
                [9.380695000000173, 0.623194000000183],
                [9.379583, 0.622916],
                [9.379026000000124, 0.62236],
                [9.378195000000119, 0.622082],
                [9.378195000000119, 0.621528000000183],
                [9.377085, 0.62125],
                [9.375416, 0.62125],
                [9.374306, 0.620972000000165],
                [9.37375, 0.620416],
                [9.372638, 0.620138000000111],
                [9.37125, 0.620138000000111],
                [9.37125, 0.619584],
                [9.369862, 0.619306000000165],
                [9.369584, 0.61875],
                [9.368194, 0.618472000000111],
                [9.36514000000011, 0.618472000000111],
                [9.36458400000015, 0.619028],
                [9.360695, 0.619028],
                [9.360695, 0.61875],
                [9.358471, 0.61875],
                [9.358471, 0.618472000000111],
                [9.355695, 0.618472000000111],
                [9.355139, 0.619028],
                [9.353751000000159, 0.619028],
                [9.352917, 0.619306000000165],
                [9.351807, 0.620694],
                [9.351529000000141, 0.621806],
                [9.350416, 0.62236],
                [9.350138000000186, 0.622916],
                [9.349028, 0.62347300000016],
                [9.348194, 0.62347300000016],
                [9.348194, 0.622916],
                [9.347638, 0.622638000000165],
                [9.347638, 0.621806],
                [9.34513800000019, 0.619306000000165],
                [9.344028, 0.619306000000165],
                [9.34347200000019, 0.618472000000111],
                [9.34264, 0.618194000000187],
                [9.340972, 0.618194000000187],
                [9.34014000000019, 0.617916],
                [9.339584000000173, 0.617362],
                [9.337362, 0.61736],
                [9.337362, 0.617084],
                [9.334027000000106, 0.617084],
                [9.334027000000106, 0.61736],
                [9.332361000000105, 0.617362],
                [9.332361000000105, 0.61763800000017],
                [9.330695000000105, 0.61763800000017],
                [9.329861, 0.618472000000111],
                [9.328749, 0.619028],
                [9.328195, 0.619584],
                [9.327639, 0.619584],
                [9.32736100000011, 0.620138000000111],
                [9.32569500000011, 0.620138000000111],
                [9.325417, 0.619584],
                [9.323194000000115, 0.619584],
                [9.322916, 0.61875],
                [9.322085, 0.618194000000187],
                [9.322085, 0.61763800000017],
                [9.321528000000114, 0.61736],
                [9.320972000000154, 0.616528000000187],
                [9.320138, 0.616528000000187],
                [9.319306000000154, 0.615694],
                [9.319306000000154, 0.615138000000115],
                [9.318750000000136, 0.614862000000187],
                [9.318750000000136, 0.61430600000017],
                [9.317916, 0.614028],
                [9.317916, 0.613194],
                [9.316806, 0.612084],
                [9.316528000000176, 0.61125],
                [9.316528000000176, 0.610140000000115],
                [9.317084000000136, 0.60930500000012],
                [9.317084000000136, 0.605417000000102],
                [9.31625, 0.602639000000124],
                [9.315416, 0.602361],
                [9.315138, 0.601805],
                [9.314028, 0.600973000000124],
                [9.313472, 0.600973000000124],
                [9.312918, 0.599583],
                [9.311806, 0.599583],
                [9.311806, 0.598473],
                [9.310971000000109, 0.597917],
                [9.310415, 0.597082],
                [9.309583, 0.597082],
                [9.308193, 0.595694000000151],
                [9.308193, 0.594028000000151],
                [9.307639000000108, 0.593472000000133],
                [9.307917, 0.592638],
                [9.307917, 0.590972],
                [9.307639000000108, 0.589584000000173],
                [9.308193, 0.589028000000155],
                [9.307917, 0.588472000000138],
                [9.307917, 0.58402800000016],
                [9.308749, 0.582361000000105],
                [9.308749, 0.581249],
                [9.308193, 0.580971],
                [9.307917, 0.580139],
                [9.307917, 0.576527000000169],
                [9.308193, 0.576527000000169],
                [9.308193, 0.573751000000186],
                [9.307639000000108, 0.572917],
                [9.307639000000108, 0.569029000000114],
                [9.307917, 0.569029000000114],
                [9.307917, 0.564028],
                [9.307639000000108, 0.564028],
                [9.307639000000108, 0.562362],
                [9.307917, 0.562362],
                [9.307917, 0.560416000000146],
                [9.308474, 0.56014],
                [9.308474, 0.559584],
                [9.309027000000185, 0.559306000000106],
                [9.309027000000185, 0.557918],
                [9.308193, 0.55624900000015],
                [9.307639000000108, 0.55624900000015],
                [9.307639000000108, 0.55458300000015],
                [9.307361000000185, 0.55458300000015],
                [9.307361000000185, 0.550417],
                [9.307917, 0.549583000000155],
                [9.307917, 0.5470830000001],
                [9.308193, 0.5470830000001],
                [9.308193, 0.545139000000177],
                [9.308474, 0.544029],
                [9.309027000000185, 0.543473000000176],
                [9.308749, 0.542638000000181],
                [9.308749, 0.539584000000104],
                [9.309305000000109, 0.539584000000104],
                [9.309305000000109, 0.538750000000164],
                [9.31014, 0.537916000000109],
                [9.31014, 0.533472000000131],
                [9.309583, 0.533194],
                [9.310415, 0.530418],
                [9.310415, 0.529305000000136],
                [9.30986200000018, 0.528196],
                [9.30986200000018, 0.526805],
                [9.309583, 0.525971000000141],
                [9.309027000000185, 0.525695],
                [9.309027000000185, 0.525139],
                [9.308474, 0.524861000000101],
                [9.307639000000108, 0.523749000000123],
                [9.308193, 0.52263900000014],
                [9.308193, 0.521251],
                [9.307361000000185, 0.520417000000123],
                [9.307361000000185, 0.517917],
                [9.307639000000108, 0.517917],
                [9.307639000000108, 0.516251],
                [9.307917, 0.516251],
                [9.309305000000109, 0.51486],
                [9.309305000000109, 0.514307000000144],
                [9.31014, 0.51375],
                [9.310415, 0.512638],
                [9.310971000000109, 0.512638],
                [9.311806, 0.511806000000149],
                [9.311806, 0.511250000000132],
                [9.312362, 0.510694000000171],
                [9.313472, 0.510972],
                [9.31375, 0.510138000000154],
                [9.314584, 0.509306],
                [9.315138, 0.509306],
                [9.316528000000176, 0.507916],
                [9.316528000000176, 0.507084],
                [9.31736, 0.506528],
                [9.31736, 0.505972],
                [9.317916, 0.505418],
                [9.318750000000136, 0.505418],
                [9.319028, 0.505972],
                [9.319582, 0.505972],
                [9.319862000000114, 0.506806000000154],
                [9.320972000000154, 0.507916],
                [9.32125, 0.509028000000171],
                [9.321807000000149, 0.509584000000132],
                [9.323194000000115, 0.509584000000132],
                [9.32402900000011, 0.510694000000171],
                [9.324861, 0.510972],
                [9.325973, 0.510972],
                [9.326251000000127, 0.511528],
                [9.32736100000011, 0.512084],
                [9.328473000000145, 0.512084],
                [9.328749, 0.512638],
                [9.329583000000127, 0.512638],
                [9.330139000000145, 0.513194],
                [9.329861, 0.514582000000132],
                [9.330139000000145, 0.515694000000167],
                [9.330139000000145, 0.517082],
                [9.330417, 0.517917],
                [9.330971, 0.518195000000162],
                [9.331527, 0.519305000000145],
                [9.332361000000105, 0.520417000000123],
                [9.333471000000145, 0.52097300000014],
                [9.335693000000106, 0.521527000000162],
                [9.340418000000113, 0.521527000000162],
                [9.340418000000113, 0.521251],
                [9.344584000000168, 0.521251],
                [9.346257, 0.520709000000124],
                [9.348472000000186, 0.520139],
                [9.348472000000186, 0.519583],
                [9.350138000000186, 0.519583],
                [9.350973000000181, 0.518751000000123],
                [9.352639000000181, 0.518751000000123],
                [9.353195000000142, 0.517917],
                [9.354305000000181, 0.517082],
                [9.355695, 0.51680400000015],
                [9.355695, 0.516251],
                [9.35708300000016, 0.515973000000145],
                [9.35763900000012, 0.51513800000015],
                [9.358751000000154, 0.51513800000015],
                [9.358997, 0.514587],
                [9.360139, 0.514307000000144],
                [9.360417000000155, 0.51375],
                [9.360973000000115, 0.51375],
                [9.362083000000155, 0.513194],
                [9.363779000000136, 0.51208],
                [9.364027, 0.511528],
                [9.36514000000011, 0.511250000000132],
                [9.367638, 0.50875],
                [9.36847200000011, 0.50875],
                [9.36875, 0.50764],
                [9.369306, 0.50764],
                [9.370140000000106, 0.507084],
                [9.370416, 0.50625],
                [9.370972, 0.50625],
                [9.372084, 0.504862],
                [9.372916, 0.504584],
                [9.372916, 0.504028000000176],
                [9.373472000000106, 0.503471000000104],
                [9.374306, 0.503193000000181],
                [9.374582, 0.502362000000176],
                [9.375416, 0.502362000000176],
                [9.375694000000124, 0.501527000000181],
                [9.376529000000119, 0.500417],
                [9.377638, 0.500139000000104],
                [9.377917, 0.499027000000126],
                [9.378473, 0.499027000000126],
                [9.378751, 0.49819500000018],
                [9.379307, 0.49819500000018],
                [9.380417, 0.497083],
                [9.380417, 0.496527000000185],
                [9.381805, 0.494861000000185],
                [9.382639, 0.494583],
                [9.382917000000191, 0.493473000000108],
                [9.383473, 0.493195000000185],
                [9.383749, 0.492083],
                [9.384305, 0.491805000000113],
                [9.384305, 0.490973],
                [9.384861, 0.490973],
                [9.386249, 0.488472],
                [9.386527, 0.486806],
                [9.387639, 0.485972000000118],
                [9.387639, 0.485416000000157],
                [9.388193, 0.485138],
                [9.388193, 0.484306000000117],
                [9.388749, 0.483472],
                [9.389861, 0.482362],
                [9.39014, 0.481528000000139],
                [9.39014, 0.480416000000162],
                [9.390696, 0.479306000000122],
                [9.390696, 0.477918],
                [9.391528000000164, 0.477640000000122],
                [9.391528000000164, 0.476806],
                [9.392084000000125, 0.476806],
                [9.392362, 0.476249000000166],
                [9.392918, 0.476249000000166],
                [9.392918, 0.474862000000144],
                [9.393472, 0.474583000000166],
                [9.393472, 0.473749],
                [9.394584, 0.473471000000188],
                [9.394584, 0.472917000000166],
                [9.395416000000125, 0.472917000000166],
                [9.395416000000125, 0.472361000000149],
                [9.39625, 0.472083],
                [9.39625, 0.471527],
                [9.396806, 0.471249000000171],
                [9.39819400000016, 0.469861],
                [9.399306000000138, 0.469027000000153],
                [9.399584, 0.467083],
                [9.400138, 0.467083],
                [9.400138, 0.46625100000017],
                [9.401528000000155, 0.465695000000153],
                [9.402084000000116, 0.464305],
                [9.402084000000116, 0.463195],
                [9.402638000000138, 0.463195],
                [9.402916, 0.46208200000018],
                [9.403472, 0.46208200000018],
                [9.404860000000156, 0.460972],
                [9.405417, 0.45986],
                [9.405973, 0.459306],
                [9.405973, 0.458472],
                [9.406527, 0.458472],
                [9.406807000000128, 0.457360000000108],
                [9.407361, 0.457084000000179],
                [9.407361, 0.455138],
                [9.407917000000111, 0.455138],
                [9.408195, 0.454028000000108],
                [9.409029, 0.453750000000184],
                [9.409029, 0.45291600000013],
                [9.409583000000111, 0.45264],
                [9.409583000000111, 0.452084000000184],
                [9.410417, 0.451528],
                [9.410973000000183, 0.449862],
                [9.411527, 0.449862],
                [9.411527, 0.448749000000134],
                [9.411805000000129, 0.44764],
                [9.412917000000107, 0.447083000000134],
                [9.413749, 0.445139],
                [9.413749, 0.442639000000156],
                [9.414027, 0.441251],
                [9.415139000000124, 0.439861000000121],
                [9.415139000000124, 0.438751000000138],
                [9.415693, 0.438751000000138],
                [9.415974, 0.437083000000143],
                [9.416527, 0.436529000000121],
                [9.416527, 0.435138000000165],
                [9.417083, 0.434582000000148],
                [9.417083, 0.432916000000148],
                [9.417362, 0.431806000000165],
                [9.417915000000107, 0.430972],
                [9.417915000000107, 0.429862],
                [9.419028, 0.429028000000187],
                [9.419306, 0.42847200000017],
                [9.419306, 0.427362000000187],
                [9.419584, 0.42680600000017],
                [9.420140000000174, 0.42680600000017],
                [9.420972, 0.42625],
                [9.42125, 0.425694],
                [9.42125, 0.424306000000115],
                [9.421806000000174, 0.422084],
                [9.422362, 0.421806000000174],
                [9.422362, 0.420418],
                [9.422916, 0.420418],
                [9.422916, 0.419305000000179],
                [9.423472000000174, 0.419027],
                [9.42375, 0.417361],
                [9.42375, 0.416251000000102],
                [9.424584000000152, 0.415139000000124],
                [9.425972, 0.414583000000107],
                [9.425972, 0.414027000000146],
                [9.42680600000017, 0.413473000000124],
                [9.42680600000017, 0.412639],
                [9.427638, 0.411805000000129],
                [9.427638, 0.410417],
                [9.42847200000017, 0.408473000000129],
                [9.42986, 0.40736],
                [9.42986, 0.406807000000128],
                [9.430416, 0.40625],
                [9.431529000000126, 0.405972000000133],
                [9.432361, 0.405138],
                [9.432361, 0.404584],
                [9.432917, 0.404306000000133],
                [9.432917, 0.403750000000116],
                [9.433473, 0.403472],
                [9.433195000000126, 0.402362],
                [9.432361, 0.401806],
                [9.432361, 0.400972000000138],
                [9.432917, 0.399306000000138],
                [9.434305000000165, 0.399306000000138],
                [9.434583, 0.400416000000178],
                [9.435417000000143, 0.400694],
                [9.436529000000121, 0.400694],
                [9.436529000000121, 0.40014],
                [9.437361, 0.40014],
                [9.438195000000121, 0.398750000000177],
                [9.438195000000121, 0.397362],
                [9.438471, 0.39652800000016],
                [9.439027, 0.395971],
                [9.43930500000016, 0.39486200000016],
                [9.43930500000016, 0.393749],
                [9.439861000000121, 0.393749],
                [9.440139, 0.392639],
                [9.440139, 0.39152700000011],
                [9.440695, 0.39152700000011],
                [9.441527000000121, 0.38986100000011],
                [9.441527000000121, 0.389305],
                [9.442083000000139, 0.389027000000169],
                [9.442083000000139, 0.388473],
                [9.442639, 0.38819500000011],
                [9.442639, 0.386805],
                [9.443471, 0.386251000000186],
                [9.443471, 0.385139],
                [9.444584000000134, 0.384861000000114],
                [9.44514, 0.384305],
                [9.44514, 0.383751],
                [9.445693, 0.383473],
                [9.445696000000112, 0.382639],
                [9.446528, 0.381804000000102],
                [9.447638, 0.381529000000114],
                [9.448472, 0.380694000000119],
                [9.44958400000013, 0.380694000000119],
                [9.45014, 0.37986],
                [9.451528, 0.378472000000102],
                [9.452084000000184, 0.377362000000119],
                [9.452638, 0.377084],
                [9.452638, 0.376528],
                [9.453472, 0.375972],
                [9.453472, 0.375138000000106],
                [9.454306, 0.374584000000141],
                [9.45458200000013, 0.37375],
                [9.45458200000013, 0.372362000000123],
                [9.455694000000108, 0.371250000000146],
                [9.455694000000108, 0.370418],
                [9.456528, 0.369862],
                [9.456528, 0.369028000000128],
                [9.457085, 0.367918000000145],
                [9.457638, 0.36764],
                [9.457638, 0.36708300000015],
                [9.45958300000018, 0.36708300000015],
                [9.460695000000158, 0.366805],
                [9.461529, 0.365971000000172],
                [9.461805, 0.365139],
                [9.462361000000158, 0.365139],
                [9.464305, 0.362917],
                [9.464305, 0.362361],
                [9.465695000000153, 0.361805],
                [9.465971, 0.361249],
                [9.466805, 0.361249],
                [9.467083, 0.360139],
                [9.46791700000017, 0.359861000000137],
                [9.46791700000017, 0.359305000000177],
                [9.469305, 0.358195000000137],
                [9.469305, 0.357361],
                [9.469861, 0.357083000000159],
                [9.469861, 0.355417000000159],
                [9.470696, 0.35486],
                [9.472362, 0.353194],
                [9.472084000000166, 0.352638],
                [9.472915000000171, 0.349028],
                [9.472915000000171, 0.346250000000168],
                [9.472640000000126, 0.346250000000168],
                [9.472640000000126, 0.34264],
                [9.473472, 0.341806],
                [9.474028, 0.341806],
                [9.474028, 0.340971],
                [9.472362, 0.34014],
                [9.472362, 0.337917000000118],
                [9.473472, 0.336527],
                [9.474584, 0.336527],
                [9.475694, 0.335417],
                [9.47625, 0.33513900000014],
                [9.47625, 0.334583000000123],
                [9.476806, 0.334305],
                [9.476806, 0.333195],
                [9.477640000000122, 0.332639],
                [9.477640000000122, 0.331805000000145],
                [9.478194000000144, 0.331527],
                [9.478750000000105, 0.330417],
                [9.478750000000105, 0.329305],
                [9.479028, 0.328195],
                [9.47986200000014, 0.327917000000127],
                [9.480972000000122, 0.325694],
                [9.48125, 0.324860000000172],
                [9.481806, 0.324584],
                [9.481806, 0.322916],
                [9.48236, 0.32236],
                [9.482916, 0.320416],
                [9.482916, 0.317916],
                [9.484029000000135, 0.317084],
                [9.48486000000014, 0.317084],
                [9.48486000000014, 0.316528000000176],
                [9.485973, 0.316528000000176],
                [9.487083, 0.315972000000158],
                [9.48819500000019, 0.313749],
                [9.48819500000019, 0.311527],
                [9.488751, 0.311249],
                [9.489029, 0.310417],
                [9.489029, 0.308749],
                [9.489583, 0.306527000000131],
                [9.490139000000113, 0.306251],
                [9.490139000000113, 0.305139],
                [9.490695, 0.304583],
                [9.490973, 0.303473],
                [9.490973, 0.301807],
                [9.491251, 0.300973000000113],
                [9.491805000000113, 0.300416],
                [9.491805000000113, 0.2995820000001],
                [9.492361, 0.299307000000113],
                [9.492917, 0.2979160000001],
                [9.492917, 0.296806000000117],
                [9.493471000000113, 0.296528],
                [9.494027, 0.295416],
                [9.494027, 0.2945840000001],
                [9.495139000000108, 0.293472000000122],
                [9.495139000000108, 0.292084],
                [9.495693, 0.291528],
                [9.495693, 0.290140000000122],
                [9.494861000000185, 0.289862],
                [9.494305, 0.28875],
                [9.494305, 0.28764],
                [9.493471000000113, 0.286805],
                [9.493471000000113, 0.285693],
                [9.492361, 0.284861000000149],
                [9.492639, 0.284027],
                [9.493471000000113, 0.284027],
                [9.494305, 0.285139],
                [9.494305, 0.286527000000149],
                [9.494861000000185, 0.28764],
                [9.495693, 0.288472000000127],
                [9.495693, 0.289306],
                [9.496527000000185, 0.290140000000122],
                [9.497640000000104, 0.289862],
                [9.49902800000018, 0.288472000000127],
                [9.499584, 0.288193000000149],
                [9.499862, 0.287084],
                [9.500418, 0.287084],
                [9.50069400000018, 0.286527000000149],
                [9.501806000000158, 0.286249],
                [9.501806000000158, 0.285693],
                [9.500418, 0.285417000000166],
                [9.50069400000018, 0.284027],
                [9.501528, 0.284027],
                [9.501806000000158, 0.283471],
                [9.503194, 0.283195000000148],
                [9.504306, 0.283195000000148],
                [9.505138000000159, 0.284027],
                [9.505140000000154, 0.284861000000149],
                [9.507362000000171, 0.285417000000166],
                [9.507916, 0.286249],
                [9.507916, 0.286805],
                [9.50986, 0.288472000000127],
                [9.510138000000154, 0.289306],
                [9.510972, 0.289306],
                [9.51152900000011, 0.290416],
                [9.51152900000011, 0.292084],
                [9.512360000000172, 0.292916000000162],
                [9.512360000000172, 0.294028000000139],
                [9.51152900000011, 0.2945840000001],
                [9.51152900000011, 0.296806000000117],
                [9.512917, 0.298472000000118],
                [9.513473, 0.298472000000118],
                [9.513751000000127, 0.299028000000135],
                [9.514583, 0.299028000000135],
                [9.515695, 0.2995820000001],
                [9.516529000000105, 0.2995820000001],
                [9.517083000000127, 0.299028000000135],
                [9.517083000000127, 0.298472000000118],
                [9.518195000000105, 0.298194],
                [9.518473, 0.29875],
                [9.519861000000105, 0.300138000000118],
                [9.520139, 0.300138000000118],
                [9.520139, 0.302917],
                [9.52097300000014, 0.30319500000013],
                [9.52097300000014, 0.304305000000113],
                [9.521249, 0.304583],
                [9.52263900000014, 0.304583],
                [9.523193000000106, 0.30486100000013],
                [9.524027, 0.305973000000108],
                [9.524027, 0.306527000000131],
                [9.524862, 0.307083],
                [9.52514, 0.307917],
                [9.52514, 0.309027000000185],
                [9.52597100000014, 0.309583],
                [9.52597100000014, 0.310139000000163],
                [9.526528, 0.310417],
                [9.527362, 0.311527],
                [9.527638, 0.312639000000104],
                [9.528194, 0.313193],
                [9.528472, 0.312083],
                [9.527638, 0.310417],
                [9.526806, 0.309861],
                [9.526528, 0.309027000000185],
                [9.526528, 0.307639000000108],
                [9.527362, 0.307361000000185],
                [9.52764000000019, 0.305973000000108],
                [9.528472, 0.305417],
                [9.528472, 0.30486100000013],
                [9.52930600000019, 0.30486100000013],
                [9.53097200000019, 0.30319500000013],
                [9.53097200000019, 0.302639000000113],
                [9.531806, 0.301807],
                [9.532084000000168, 0.300695000000189],
                [9.53263800000019, 0.2995820000001],
                [9.533472, 0.299307000000113],
                [9.533750000000168, 0.298194],
                [9.534862, 0.29736000000014],
                [9.534862, 0.2962500000001],
                [9.535138, 0.29569400000014],
                [9.536804, 0.294028000000139],
                [9.53736, 0.292916000000162],
                [9.53791600000011, 0.292916000000162],
                [9.539307, 0.290972],
                [9.540139000000181, 0.291250000000161],
                [9.540973, 0.290972],
                [9.542639, 0.290972],
                [9.544027, 0.290694000000144],
                [9.546805000000177, 0.289584000000161],
                [9.546805000000177, 0.289028000000144],
                [9.549861, 0.289028000000144],
                [9.549861, 0.289306],
                [9.55264000000011, 0.289306],
                [9.552915000000155, 0.289862],
                [9.554028, 0.290140000000122],
                [9.55430600000011, 0.290972],
                [9.555138, 0.291806000000122],
                [9.555418000000145, 0.293194],
                [9.555418000000145, 0.2945840000001],
                [9.55597200000011, 0.2962500000001],
                [9.555418000000145, 0.296528],
                [9.554584, 0.299307000000113],
                [9.554584, 0.300973000000113],
                [9.555418000000145, 0.301804000000118],
                [9.564028, 0.301804000000118],
                [9.565416, 0.30152900000013],
                [9.565695000000119, 0.300973000000113],
                [9.566526000000124, 0.300416],
                [9.566526000000124, 0.29986],
                [9.567917, 0.29875],
                [9.56875100000019, 0.298472000000118],
                [9.569305, 0.29736000000014],
                [9.569305, 0.2962500000001],
                [9.56875100000019, 0.295416],
                [9.56875100000019, 0.293472000000122],
                [9.569029000000114, 0.292362000000139],
                [9.570139, 0.291528],
                [9.570139, 0.290972],
                [9.572083, 0.28875],
                [9.573195000000169, 0.288472000000127],
                [9.574027000000115, 0.287362000000144],
                [9.574583, 0.287084],
                [9.575693000000115, 0.287084],
                [9.577083000000187, 0.285971000000131],
                [9.577083000000187, 0.285417000000166],
                [9.577918000000182, 0.284861000000149],
                [9.580140000000142, 0.282639000000131],
                [9.580694000000165, 0.282361],
                [9.580694000000165, 0.281805],
                [9.581528, 0.281249],
                [9.581528, 0.280695],
                [9.582084, 0.28041700000017],
                [9.58236200000016, 0.279305],
                [9.582916000000182, 0.279027],
                [9.583194, 0.277917],
                [9.58375, 0.277639],
                [9.58569400000016, 0.275695],
                [9.585972, 0.27458200000018],
                [9.586528, 0.273473],
                [9.588194, 0.271806],
                [9.589584000000116, 0.271528000000103],
                [9.589584000000116, 0.270972],
                [9.590972, 0.270972],
                [9.592360000000156, 0.270694],
                [9.59319500000015, 0.270972],
                [9.593473, 0.270694],
                [9.593473, 0.268750000000125],
                [9.594026000000156, 0.268194000000108],
                [9.594026000000156, 0.267084000000125],
                [9.59486100000015, 0.266250000000184],
                [9.595139, 0.26514],
                [9.595695, 0.264584000000184],
                [9.596529, 0.264306],
                [9.597361, 0.263472],
                [9.599027, 0.263472],
                [9.599583, 0.262916000000189],
                [9.600417000000107, 0.262916000000189],
                [9.600695, 0.262084000000129],
                [9.601527, 0.262084000000129],
                [9.601805, 0.261528000000112],
                [9.602639000000124, 0.261528000000112],
                [9.603193, 0.260971],
                [9.604027, 0.260971],
                [9.604583, 0.26014],
                [9.60514000000012, 0.26014],
                [9.605415000000107, 0.259583000000134],
                [9.606528, 0.259305],
                [9.607084, 0.257917000000134],
                [9.607640000000174, 0.257917000000134],
                [9.60847200000012, 0.256249000000139],
                [9.609028, 0.255973],
                [9.610694, 0.255973],
                [9.61125, 0.255417],
                [9.612084, 0.255139000000156],
                [9.615138, 0.255139000000156],
                [9.615138, 0.255417],
                [9.616806, 0.255417],
                [9.617084000000148, 0.254861],
                [9.618194000000187, 0.254305],
                [9.619029000000126, 0.254305],
                [9.619307, 0.253749],
                [9.619860000000187, 0.253749],
                [9.620695000000126, 0.252917000000139],
                [9.620695000000126, 0.251805000000161],
                [9.621805000000165, 0.250973],
                [9.622917000000143, 0.249861],
                [9.624305, 0.249861],
                [9.624861, 0.249305],
                [9.625695000000121, 0.249305],
                [9.626251000000138, 0.24847300000016],
                [9.628195, 0.24847300000016],
                [9.629027000000121, 0.248195],
                [9.629305, 0.247082000000148],
                [9.629861, 0.247082000000148],
                [9.629861, 0.246251000000143],
                [9.630695000000117, 0.245416000000148],
                [9.631527, 0.245416000000148],
                [9.631805000000156, 0.244860000000187],
                [9.632362, 0.244860000000187],
                [9.63291500000014, 0.244028],
                [9.63291500000014, 0.243472],
                [9.634028, 0.24263800000017],
                [9.634306, 0.241806],
                [9.634862000000112, 0.24125],
                [9.63541800000013, 0.24125],
                [9.635694, 0.240694],
                [9.636528000000112, 0.240694],
                [9.637916, 0.239584],
                [9.637916, 0.239028],
                [9.639028, 0.238750000000152],
                [9.639306, 0.238194],
                [9.639306, 0.237084000000152],
                [9.639862000000107, 0.236806],
                [9.640138, 0.235694],
                [9.640972, 0.235418000000152],
                [9.640972, 0.234862],
                [9.641806, 0.234584],
                [9.642638, 0.233749000000103],
                [9.644028, 0.233749000000103],
                [9.644860000000108, 0.234028],
                [9.645138, 0.234584],
                [9.646529, 0.23514],
                [9.647361000000103, 0.23514],
                [9.647639, 0.235694],
                [9.649861, 0.235694],
                [9.650973, 0.235972000000174],
                [9.651805, 0.236528],
                [9.652917, 0.236528],
                [9.654027, 0.237362],
                [9.654027, 0.237916],
                [9.655139, 0.238194],
                [9.655973000000131, 0.239028],
                [9.656527000000153, 0.239028],
                [9.656805, 0.239584],
                [9.657917, 0.239862000000187],
                [9.658471, 0.240416000000153],
                [9.658471, 0.24097200000017],
                [9.659027, 0.24125],
                [9.659027, 0.241806],
                [9.659584000000166, 0.242084000000148],
                [9.659584000000166, 0.242916],
                [9.660418, 0.243750000000148],
                [9.660693, 0.244860000000187],
                [9.661250000000166, 0.245416000000148],
                [9.662084, 0.245694],
                [9.665140000000122, 0.245694],
                [9.665972, 0.245416000000148],
                [9.666250000000161, 0.244584],
                [9.66736200000014, 0.243750000000148],
                [9.66736200000014, 0.243194000000187],
                [9.66875, 0.242916],
                [9.669306, 0.243472],
                [9.669306, 0.245972000000165],
                [9.66875, 0.245972000000165],
                [9.66875, 0.246804],
                [9.667916000000162, 0.24736],
                [9.66736200000014, 0.248195],
                [9.666806000000122, 0.24847300000016],
                [9.666806000000122, 0.249583000000143],
                [9.667916000000162, 0.248751],
                [9.667916000000162, 0.248195],
                [9.668472000000122, 0.247917000000143],
                [9.668472000000122, 0.24736],
                [9.6695840000001, 0.246251000000143],
                [9.66986, 0.244860000000187],
                [9.670416, 0.244306000000165],
                [9.670416, 0.243472],
                [9.6695840000001, 0.242362],
                [9.667916000000162, 0.242362],
                [9.666528, 0.243750000000148],
                [9.665972, 0.243750000000148],
                [9.665140000000122, 0.244584],
                [9.662918000000161, 0.244584],
                [9.661250000000166, 0.243750000000148],
                [9.660693, 0.243194000000187],
                [9.660140000000126, 0.241528000000187],
                [9.660140000000126, 0.240694],
                [9.657917, 0.238472],
                [9.656527000000153, 0.237916],
                [9.656249, 0.237362],
                [9.65541700000017, 0.237362],
                [9.654305, 0.235972000000174],
                [9.654305, 0.234862],
                [9.654583, 0.234306000000174],
                [9.65541700000017, 0.234028],
                [9.65541700000017, 0.233471000000179],
                [9.656805, 0.233193],
                [9.657361, 0.232640000000174],
                [9.657361, 0.232083000000102],
                [9.657917, 0.231527],
                [9.657917, 0.230695],
                [9.658471, 0.230139000000179],
                [9.658471, 0.229583],
                [9.659027, 0.229305000000124],
                [9.659027, 0.228195],
                [9.659584000000166, 0.226805000000184],
                [9.660140000000126, 0.226805000000184],
                [9.660972, 0.225139000000183],
                [9.660972, 0.224305000000129],
                [9.661528, 0.223195],
                [9.662084, 0.223195],
                [9.662638, 0.222361],
                [9.662638, 0.221805],
                [9.663472000000127, 0.221529],
                [9.664028000000144, 0.220973000000129],
                [9.664028000000144, 0.220417000000111],
                [9.66486, 0.220138000000134],
                [9.665140000000122, 0.219582000000116],
                [9.665972, 0.219307000000128],
                [9.665972, 0.21875],
                [9.666528, 0.218194],
                [9.667916000000162, 0.217638],
                [9.6695840000001, 0.217638],
                [9.6695840000001, 0.217916000000116],
                [9.671528, 0.217916000000116],
                [9.67236000000014, 0.217082],
                [9.672638, 0.215972],
                [9.673751, 0.214862],
                [9.674861000000135, 0.214584000000116],
                [9.675973000000113, 0.21375],
                [9.676251, 0.213194],
                [9.677083, 0.212916000000121],
                [9.677917, 0.212084],
                [9.678473, 0.212084],
                [9.67902700000019, 0.211250000000121],
                [9.680695000000185, 0.211250000000121],
                [9.680973000000108, 0.211806000000138],
                [9.682639000000108, 0.212084],
                [9.68319300000013, 0.21264],
                [9.684583, 0.21264],
                [9.685696, 0.213194],
                [9.685971000000109, 0.214028000000155],
                [9.687084, 0.214862],
                [9.689028, 0.214862],
                [9.689584, 0.215138000000138],
                [9.689584, 0.215972],
                [9.690694, 0.216250000000116],
                [9.691528000000176, 0.214862],
                [9.692084, 0.214862],
                [9.692916, 0.215972],
                [9.693472, 0.215972],
                [9.69375, 0.217082],
                [9.694306000000154, 0.217360000000156],
                [9.694279, 0.217962],
                [9.694824, 0.218263],
                [9.695972000000154, 0.218194],
                [9.696528000000171, 0.21875],
                [9.697916, 0.218472000000133],
                [9.698750000000132, 0.219307000000128],
                [9.699029000000166, 0.220138000000134],
                [9.700417, 0.220138000000134],
                [9.701251000000127, 0.220417000000111],
                [9.702361000000167, 0.221529],
                [9.704583000000127, 0.221529],
                [9.704861, 0.222361],
                [9.707361000000105, 0.222361],
                [9.707361000000105, 0.222639000000129],
                [9.709027000000106, 0.222639000000129],
                [9.709305, 0.223473000000183],
                [9.710417, 0.224305000000129],
                [9.710417, 0.224861],
                [9.712083, 0.226805000000184],
                [9.712083, 0.227639000000124],
                [9.712640000000135, 0.227917],
                [9.712640000000135, 0.229027],
                [9.713196, 0.229305000000124],
                [9.713196, 0.229861],
                [9.714306000000136, 0.230695],
                [9.714584, 0.231805000000179],
                [9.714584, 0.232918],
                [9.71513700000014, 0.233471000000179],
                [9.71513700000014, 0.234306000000174],
                [9.715694, 0.234306000000174],
                [9.715972000000136, 0.234862],
                [9.715972000000136, 0.235972000000174],
                [9.716528, 0.23625],
                [9.716528, 0.237362],
                [9.71680600000019, 0.238194],
                [9.717362, 0.238750000000152],
                [9.71764, 0.239584],
                [9.71764, 0.24097200000017],
                [9.718194, 0.241806],
                [9.71847200000019, 0.242916],
                [9.71847200000019, 0.244028],
                [9.719028, 0.244028],
                [9.719028, 0.245138],
                [9.719584, 0.245416000000148],
                [9.719584, 0.247082000000148],
                [9.720416000000114, 0.247917000000143],
                [9.720972, 0.249029000000121],
                [9.720972, 0.250139000000161],
                [9.721528, 0.250417],
                [9.721528, 0.250973],
                [9.72236, 0.251527],
                [9.722362, 0.252361000000121],
                [9.72375000000011, 0.253473000000156],
                [9.72375000000011, 0.254027000000121],
                [9.724304, 0.254305],
                [9.724304, 0.254861],
                [9.72486, 0.255139000000156],
                [9.725694, 0.256805000000156],
                [9.725694, 0.257917000000134],
                [9.726251000000104, 0.258471000000156],
                [9.726251000000104, 0.259862000000112],
                [9.726529, 0.261250000000189],
                [9.72736, 0.262084000000129],
                [9.72736, 0.264028],
                [9.727917000000105, 0.265972],
                [9.729029, 0.267084000000125],
                [9.728473, 0.264028],
                [9.728473, 0.262362],
                [9.728195, 0.261250000000189],
                [9.72736, 0.259583000000134],
                [9.727639000000181, 0.258749],
                [9.727639000000181, 0.256805000000156],
                [9.72708200000011, 0.256527],
                [9.72708200000011, 0.254861],
                [9.726807, 0.253749],
                [9.726251000000104, 0.253749],
                [9.725972, 0.253195],
                [9.72486, 0.253195],
                [9.724304, 0.252361000000121],
                [9.724028, 0.251251000000138],
                [9.723472000000186, 0.250973],
                [9.723472000000186, 0.249583000000143],
                [9.723194, 0.249029000000121],
                [9.722362, 0.24847300000016],
                [9.721528, 0.245416000000148],
                [9.721528, 0.243750000000148],
                [9.72125, 0.243750000000148],
                [9.72125, 0.24097200000017],
                [9.720972, 0.239862000000187],
                [9.72013800000019, 0.237640000000169],
                [9.719584, 0.237362],
                [9.719584, 0.23625],
                [9.719028, 0.235972000000174],
                [9.718750000000114, 0.23514],
                [9.718750000000114, 0.233749000000103],
                [9.718194, 0.233193],
                [9.717916, 0.232083000000102],
                [9.717916, 0.230971000000125],
                [9.717362, 0.230417000000102],
                [9.717084000000114, 0.228749000000107],
                [9.71625, 0.227917],
                [9.71625, 0.226805000000184],
                [9.715418000000113, 0.226249],
                [9.715418000000113, 0.225417000000107],
                [9.714862, 0.224861],
                [9.714306000000136, 0.223751000000107],
                [9.713749, 0.223751000000107],
                [9.712915000000123, 0.222361],
                [9.7123610000001, 0.221805],
                [9.7123610000001, 0.221251],
                [9.71180500000014, 0.220973000000129],
                [9.71180500000014, 0.220417000000111],
                [9.710971, 0.21986],
                [9.7106950000001, 0.219029],
                [9.7106950000001, 0.217082],
                [9.711249000000123, 0.215972],
                [9.711249000000123, 0.214584000000116],
                [9.71180500000014, 0.213472000000138],
                [9.71180500000014, 0.211806000000138],
                [9.712083, 0.21069400000016],
                [9.712915000000123, 0.209862],
                [9.712915000000123, 0.20875],
                [9.714028, 0.206806000000142],
                [9.71513700000014, 0.20569600000016],
                [9.71514000000019, 0.204861000000164],
                [9.715694, 0.204861000000164],
                [9.717084000000114, 0.203749000000187],
                [9.717084000000114, 0.203195000000164],
                [9.71764, 0.202639000000147],
                [9.71847200000019, 0.202361],
                [9.719584, 0.202361],
                [9.720416000000114, 0.202917],
                [9.721806000000186, 0.202917],
                [9.722362, 0.202361],
                [9.723194, 0.202083000000187],
                [9.724584000000164, 0.202083000000187],
                [9.72486, 0.201527000000169],
                [9.725694, 0.201527000000169],
                [9.72736, 0.200695],
                [9.727639000000181, 0.199861000000169],
                [9.728195, 0.199861000000169],
                [9.729305000000181, 0.199305],
                [9.730139, 0.198195000000169],
                [9.730695, 0.198195000000169],
                [9.731527, 0.197639],
                [9.731805, 0.196529000000169],
                [9.732361, 0.196251],
                [9.732361, 0.195695000000114],
                [9.733471, 0.195139],
                [9.734305000000177, 0.194029000000114],
                [9.734861, 0.194029000000114],
                [9.735139, 0.193473],
                [9.735973000000172, 0.193473],
                [9.736805, 0.192638000000102],
                [9.737639000000172, 0.192638000000102],
                [9.737917, 0.192082],
                [9.738752, 0.192082],
                [9.739862, 0.190972000000102],
                [9.74125000000015, 0.190694000000178],
                [9.741528, 0.190138],
                [9.742638, 0.189860000000124],
                [9.742918000000145, 0.189306000000101],
                [9.74347200000011, 0.189306000000101],
                [9.744028000000128, 0.188472],
                [9.745416, 0.188194000000124],
                [9.745972, 0.18736],
                [9.747362000000123, 0.187084],
                [9.748194, 0.185972000000106],
                [9.749028000000123, 0.185972000000106],
                [9.749306, 0.185416000000146],
                [9.750416, 0.185138],
                [9.751528, 0.185138],
                [9.751804000000106, 0.184584],
                [9.752638, 0.184306000000106],
                [9.754026000000124, 0.184306000000106],
                [9.755139, 0.183750000000146],
                [9.755139, 0.183194000000128],
                [9.756805, 0.182362],
                [9.758473, 0.182362],
                [9.758473, 0.182084000000145],
                [9.760417, 0.182084000000145],
                [9.760417, 0.181806],
                [9.762917000000186, 0.181806],
                [9.76402700000017, 0.181528000000128],
                [9.76486100000011, 0.180972000000111],
                [9.76652700000011, 0.180972000000111],
                [9.76652700000011, 0.180693000000133],
                [9.768474, 0.180693000000133],
                [9.769306, 0.18014],
                [9.770416000000182, 0.18014],
                [9.77125, 0.179862000000128],
                [9.771806, 0.179305],
                [9.772916, 0.178749],
                [9.774306000000138, 0.178471000000116],
                [9.774862000000155, 0.177639],
                [9.775972000000138, 0.177639],
                [9.775972000000138, 0.177083],
                [9.77736, 0.176805000000115],
                [9.778472, 0.175973],
                [9.778750000000116, 0.175417],
                [9.780138, 0.175417],
                [9.781251000000111, 0.175139000000115],
                [9.781251000000111, 0.174583000000155],
                [9.783195, 0.174583000000155],
                [9.784305, 0.174305],
                [9.784861, 0.173751],
                [9.786251000000107, 0.173749],
                [9.787639, 0.173473000000115],
                [9.788473000000124, 0.172917000000155],
                [9.789583000000107, 0.172917000000155],
                [9.789861, 0.172361000000137],
                [9.790695, 0.172083],
                [9.791805000000124, 0.172083],
                [9.792361, 0.171527],
                [9.793196, 0.171527],
                [9.793471000000125, 0.170973],
                [9.79430600000012, 0.170973],
                [9.794584, 0.170417],
                [9.795140000000174, 0.170417],
                [9.798196, 0.169029000000137],
                [9.799584, 0.168751],
                [9.799584, 0.168473000000176],
                [9.801528, 0.168473000000176],
                [9.802916, 0.169583000000159],
                [9.807082, 0.170417],
                [9.808473, 0.170417],
                [9.809861000000183, 0.170695000000137],
                [9.811805, 0.169861],
                [9.813195000000121, 0.169861],
                [9.813751000000138, 0.169029000000137],
                [9.815417000000139, 0.168195],
                [9.816249, 0.168195],
                [9.816805, 0.168751],
                [9.817917, 0.169029000000137],
                [9.819861000000117, 0.169029000000137],
                [9.820971000000156, 0.169583000000159],
                [9.824028000000112, 0.169583000000159],
                [9.824028000000112, 0.169861],
                [9.82875, 0.169861],
                [9.82875, 0.169583000000159],
                [9.834307, 0.169583000000159],
                [9.835139, 0.169305],
                [9.838195, 0.169305],
                [9.838195, 0.169583000000159],
                [9.840417, 0.169583000000159],
                [9.840417, 0.169861],
                [9.841805, 0.170139000000177],
                [9.843473000000188, 0.170139000000177],
                [9.844305, 0.170695000000137],
                [9.84624900000017, 0.170695000000137],
                [9.847083, 0.171251000000154],
                [9.848750000000166, 0.171251000000154],
                [9.849306000000126, 0.171805000000177],
                [9.85014, 0.171805000000177],
                [9.850694, 0.172361000000137],
                [9.853472, 0.172361000000137],
                [9.853750000000161, 0.172917000000155],
                [9.85486200000014, 0.172917000000155],
                [9.855416000000162, 0.173473000000115],
                [9.85652800000014, 0.173473000000115],
                [9.856806, 0.174027000000137],
                [9.857640000000117, 0.174305],
                [9.860416000000157, 0.174305],
                [9.861251, 0.174861],
                [9.863473000000113, 0.175417],
                [9.86569500000013, 0.175417],
                [9.86569500000013, 0.175695000000133],
                [9.86736100000013, 0.175695000000133],
                [9.86736100000013, 0.175973],
                [9.869305, 0.175973],
                [9.869305, 0.176249000000155],
                [9.874027000000126, 0.176249000000155],
                [9.874027000000126, 0.176527],
                [9.875693000000126, 0.176527],
                [9.875693000000126, 0.176805000000115],
                [9.876806, 0.17791700000015],
                [9.87764, 0.17791700000015],
                [9.878472, 0.178749],
                [9.879028000000176, 0.178749],
                [9.880138, 0.179584],
                [9.880416, 0.18014],
                [9.881528, 0.180418000000145],
                [9.8826380000001, 0.180972000000111],
                [9.8826380000001, 0.181528000000128],
                [9.88375, 0.182640000000106],
                [9.885416, 0.183472],
                [9.885972, 0.184028],
                [9.887082, 0.184584],
                [9.887638, 0.184584],
                [9.887916000000132, 0.185138],
                [9.888473, 0.185138],
                [9.889304, 0.18625],
                [9.889861000000167, 0.18625],
                [9.890695, 0.186806],
                [9.890695, 0.184306000000106],
                [9.890973000000145, 0.184306000000106],
                [9.890973000000145, 0.180418000000145],
                [9.891249, 0.180418000000145],
                [9.891249, 0.176249000000155],
                [9.891529000000162, 0.176249000000155],
                [9.891529000000162, 0.172083],
                [9.891805, 0.172083],
                [9.891805, 0.168195],
                [9.892083000000127, 0.168195],
                [9.892083000000127, 0.166251000000159],
                [9.889582000000132, 0.166251000000159],
                [9.888473, 0.165694],
                [9.887363, 0.165416000000164],
                [9.886250000000132, 0.165416000000164],
                [9.885416, 0.165138],
                [9.88486, 0.164582000000109],
                [9.88375, 0.164582000000109],
                [9.883194, 0.164028],
                [9.882362000000171, 0.164028],
                [9.881806000000154, 0.163472],
                [9.879862, 0.162638000000186],
                [9.879306, 0.162638000000186],
                [9.879028000000176, 0.162084000000164],
                [9.877916, 0.162084000000164],
                [9.87764, 0.161528],
                [9.876806, 0.161528],
                [9.876806, 0.160972000000186],
                [9.87514, 0.160416000000168],
                [9.874862, 0.159862],
                [9.874027000000126, 0.159862],
                [9.872639, 0.158750000000168],
                [9.870139000000108, 0.157916000000114],
                [9.869861000000185, 0.157362],
                [9.86902700000013, 0.157362],
                [9.868749, 0.156806],
                [9.867666, 0.156466],
                [9.86736100000013, 0.155972],
                [9.866251, 0.155416000000173],
                [9.865139000000113, 0.155416000000173],
                [9.864861, 0.154862],
                [9.86402900000013, 0.154862],
                [9.863751, 0.154028],
                [9.862917, 0.153193000000101],
                [9.862361000000135, 0.153193000000101],
                [9.86152600000014, 0.152362],
                [9.860694, 0.152362],
                [9.860138, 0.151527000000101],
                [9.859306000000117, 0.151527000000101],
                [9.858750000000157, 0.150971000000141],
                [9.857640000000117, 0.150971000000141],
                [9.85652800000014, 0.150417000000118],
                [9.855416000000162, 0.150139],
                [9.854584, 0.149583],
                [9.853472, 0.14930500000014],
                [9.851806, 0.14930500000014],
                [9.851806, 0.149027],
                [9.847640000000126, 0.149027],
                [9.846527, 0.148471],
                [9.84458300000017, 0.148471],
                [9.843473000000188, 0.148195000000101],
                [9.84291700000017, 0.14763900000014],
                [9.841784, 0.147682000000145],
                [9.84125100000017, 0.147083000000123],
                [9.839583000000175, 0.146805],
                [9.838776, 0.146253000000172],
                [9.837917000000175, 0.146251],
                [9.837108, 0.145419000000118],
                [9.835973, 0.145139],
                [9.835139, 0.144027],
                [9.834003000000166, 0.143799000000172],
                [9.83375100000012, 0.143195000000105],
                [9.833194, 0.143195000000105],
                [9.832360000000108, 0.142361],
                [9.830972, 0.141529000000105],
                [9.829584000000125, 0.140139],
                [9.829306, 0.139582000000132],
                [9.828472, 0.139582000000132],
                [9.828194, 0.139029],
                [9.827638, 0.139029],
                [9.825972, 0.137360000000172],
                [9.825694000000112, 0.136806000000149],
                [9.825694000000112, 0.13486],
                [9.823194, 0.13486],
                [9.823194, 0.134584000000132],
                [9.819305000000156, 0.134584000000132],
                [9.819305000000156, 0.134306],
                [9.814583, 0.134306],
                [9.81430500000016, 0.135416],
                [9.813471, 0.135694000000171],
                [9.813471, 0.136250000000132],
                [9.812917, 0.136806000000149],
                [9.812083000000143, 0.137084],
                [9.811529000000121, 0.137916000000132],
                [9.81097300000016, 0.137916000000132],
                [9.810695, 0.138472000000149],
                [9.809583, 0.138472000000149],
                [9.809305000000165, 0.139029],
                [9.807638, 0.139029],
                [9.80625, 0.138751000000127],
                [9.80625, 0.138472000000149],
                [9.804028000000187, 0.138472000000149],
                [9.804028000000187, 0.138194],
                [9.802362000000187, 0.138194],
                [9.802362000000187, 0.137916000000132],
                [9.800416, 0.137916000000132],
                [9.799862, 0.137360000000172],
                [9.797916, 0.137360000000172],
                [9.797916, 0.137084],
                [9.79597200000012, 0.137084],
                [9.794584, 0.136806000000149],
                [9.793749, 0.136250000000132],
                [9.792361, 0.136250000000132],
                [9.791249000000107, 0.135694000000171],
                [9.789583000000107, 0.135694000000171],
                [9.789583000000107, 0.135416],
                [9.787917000000107, 0.135416],
                [9.787917000000107, 0.135138000000154],
                [9.785417, 0.135138000000154],
                [9.785417, 0.13486],
                [9.777916, 0.13486],
                [9.777916, 0.134584000000132],
                [9.774028, 0.134584000000132],
                [9.774028, 0.134306],
                [9.77014, 0.134306],
                [9.77014, 0.134028000000171],
                [9.767918, 0.134028000000171],
                [9.767918, 0.13375],
                [9.766249000000187, 0.13375],
                [9.765696, 0.133194],
                [9.764305, 0.133194],
                [9.76402700000017, 0.132638],
                [9.763193000000115, 0.132362000000171],
                [9.761805, 0.132362000000171],
                [9.760695000000169, 0.131528],
                [9.760417, 0.130972],
                [9.759861000000114, 0.130972],
                [9.759305, 0.130140000000154],
                [9.758751, 0.130140000000154],
                [9.757361, 0.12875],
                [9.757083, 0.128194],
                [9.757083, 0.125693],
                [9.757361, 0.125693],
                [9.757361, 0.124027],
                [9.757917000000191, 0.122917],
                [9.757917000000191, 0.119305000000168],
                [9.757083, 0.119305000000168],
                [9.756529000000114, 0.118749],
                [9.755695, 0.118749],
                [9.755139, 0.118195000000185],
                [9.754307, 0.118195000000185],
                [9.753751, 0.117639000000167],
                [9.752085, 0.117361],
                [9.751528, 0.116805000000113],
                [9.750694000000124, 0.116805000000113],
                [9.750138000000106, 0.116251],
                [9.749306, 0.116251],
                [9.74875, 0.115695],
                [9.747916000000146, 0.115695],
                [9.747362000000123, 0.115139000000113],
                [9.746528, 0.115139000000113],
                [9.745972, 0.114583],
                [9.745140000000106, 0.114305],
                [9.74347200000011, 0.114305],
                [9.742918000000145, 0.11486100000019],
                [9.742084, 0.116251],
                [9.74125000000015, 0.116251],
                [9.740693, 0.116805000000113],
                [9.738195000000132, 0.117361],
                [9.736527, 0.117361],
                [9.735973000000172, 0.117917],
                [9.735139, 0.118195000000185],
                [9.733471, 0.118195000000185],
                [9.732917, 0.118473000000108],
                [9.732917, 0.119027],
                [9.731527, 0.119583],
                [9.730417000000159, 0.119583],
                [9.730139, 0.120139000000108],
                [9.728473, 0.120139000000108],
                [9.728473, 0.119861000000185],
                [9.726251000000104, 0.120417],
                [9.725138000000186, 0.120417],
                [9.72486, 0.120973000000163],
                [9.724304, 0.120973000000163],
                [9.723194, 0.121527000000185],
                [9.722916, 0.122083],
                [9.720694, 0.122083],
                [9.72013800000019, 0.122639000000163],
                [9.719306, 0.122917],
                [9.718194, 0.122917],
                [9.717362, 0.124027],
                [9.71625, 0.124583],
                [9.714862, 0.124583],
                [9.713749, 0.12486100000018],
                [9.713196, 0.125418],
                [9.7123610000001, 0.125418],
                [9.71180500000014, 0.125971000000163],
                [9.71013900000014, 0.125971000000163],
                [9.709305, 0.126249],
                [9.708749, 0.126806000000158],
                [9.707639, 0.126806000000158],
                [9.707083, 0.127084],
                [9.707083, 0.12764],
                [9.705139000000145, 0.12764],
                [9.704305, 0.128472000000158],
                [9.702639, 0.128472000000158],
                [9.702083, 0.129028000000176],
                [9.700138, 0.129862],
                [9.697916, 0.129862],
                [9.697084000000132, 0.130416],
                [9.695972000000154, 0.130416],
                [9.694862000000171, 0.130694000000176],
                [9.694028, 0.131528],
                [9.692916, 0.131528],
                [9.692084, 0.132084],
                [9.68875, 0.132084],
                [9.68875, 0.132362000000171],
                [9.687084, 0.132362000000171],
                [9.687084, 0.131806000000154],
                [9.685139, 0.131806000000154],
                [9.685139, 0.131528],
                [9.67986100000013, 0.131528],
                [9.67986100000013, 0.131806000000154],
                [9.674583, 0.131806000000154],
                [9.674583, 0.132084],
                [9.6729160000001, 0.132084],
                [9.6729160000001, 0.132362000000171],
                [9.66875, 0.132362000000171],
                [9.668194, 0.131806000000154],
                [9.667084, 0.131806000000154],
                [9.666528, 0.131528],
                [9.666528, 0.130972],
                [9.665694000000144, 0.130694000000176],
                [9.664028000000144, 0.12875],
                [9.664028000000144, 0.128194],
                [9.663472000000127, 0.127916],
                [9.662362000000144, 0.125693],
                [9.661806000000126, 0.125418],
                [9.661806000000126, 0.12486100000018],
                [9.661250000000166, 0.124583],
                [9.660972, 0.123749],
                [9.657361, 0.123749],
                [9.65708300000017, 0.124305000000163],
                [9.65708300000017, 0.125418],
                [9.656805, 0.126528],
                [9.656249, 0.126528],
                [9.656249, 0.12764],
                [9.655693, 0.12764],
                [9.65541700000017, 0.12875],
                [9.653471, 0.12875],
                [9.653471, 0.129028000000176],
                [9.652361, 0.129584000000136],
                [9.652083000000175, 0.130416],
                [9.651251, 0.130416],
                [9.650695, 0.130972],
                [9.649861, 0.134306],
                [9.649861, 0.135972],
                [9.649029, 0.136250000000132],
                [9.649029, 0.136806000000149],
                [9.647361000000103, 0.137916000000132],
                [9.646807, 0.13986],
                [9.646807, 0.144305000000145],
                [9.646251, 0.146527000000106],
                [9.644860000000108, 0.14763900000014],
                [9.643472, 0.147917],
                [9.641806, 0.147917],
                [9.640972, 0.148195000000101],
                [9.64041600000013, 0.148749000000123],
                [9.63875000000013, 0.148749000000123],
                [9.63764, 0.14930500000014],
                [9.637362, 0.149861000000101],
                [9.63625, 0.150139],
                [9.635972, 0.150693],
                [9.633750000000134, 0.151527000000101],
                [9.63291500000014, 0.151527000000101],
                [9.632362, 0.152083000000118],
                [9.631249000000139, 0.152362],
                [9.629305, 0.152362],
                [9.627639, 0.152918000000113],
                [9.627361000000121, 0.153471],
                [9.624029000000121, 0.153471],
                [9.623195, 0.153193000000101],
                [9.622361000000126, 0.152362],
                [9.622361000000126, 0.151805],
                [9.621805000000165, 0.151249],
                [9.620973, 0.149583],
                [9.620973, 0.149027],
                [9.620417, 0.148749000000123],
                [9.620417, 0.148195000000101],
                [9.619307, 0.147361],
                [9.619307, 0.14597300000014],
                [9.619029000000126, 0.144861000000105],
                [9.618472, 0.144861000000105],
                [9.618194000000187, 0.144305000000145],
                [9.61736, 0.144305000000145],
                [9.616806, 0.143751000000123],
                [9.615694, 0.143751000000123],
                [9.615138, 0.143195000000105],
                [9.614862000000187, 0.142361],
                [9.61430600000017, 0.141805],
                [9.613194, 0.141529000000105],
                [9.612916, 0.140973000000145],
                [9.61236, 0.140973000000145],
                [9.612084, 0.140139],
                [9.611528, 0.13986],
                [9.61125, 0.139029],
                [9.61125, 0.137916000000132],
                [9.610694, 0.136806000000149],
                [9.610694, 0.135416],
                [9.61014, 0.134306],
                [9.609584, 0.134306],
                [9.609028, 0.13375],
                [9.608194, 0.13375],
                [9.606528, 0.132638],
                [9.606249, 0.131806000000154],
                [9.605415000000107, 0.131806000000154],
                [9.604861, 0.131250000000136],
                [9.604027, 0.131528],
                [9.602639000000124, 0.131528],
                [9.601527, 0.132638],
                [9.601249, 0.133194],
                [9.599583, 0.133194],
                [9.599305000000129, 0.132638],
                [9.598751000000107, 0.132638],
                [9.597917, 0.131806000000154],
                [9.597083000000111, 0.131806000000154],
                [9.597083000000111, 0.131250000000136],
                [9.596249, 0.131250000000136],
                [9.596249, 0.130694000000176],
                [9.595139, 0.130140000000154],
                [9.594026000000156, 0.130140000000154],
                [9.593473, 0.129862],
                [9.592638, 0.128194],
                [9.592638, 0.127362000000176],
                [9.592082, 0.127362000000176],
                [9.59152900000015, 0.126806000000158],
                [9.59152900000015, 0.126249],
                [9.583194, 0.126249],
                [9.58236200000016, 0.127916],
                [9.58236200000016, 0.12875],
                [9.582916000000182, 0.129862],
                [9.58375, 0.130694000000176],
                [9.58375, 0.132916000000137],
                [9.583472000000143, 0.132916000000137],
                [9.583472000000143, 0.135972],
                [9.583194, 0.137360000000172],
                [9.58236200000016, 0.139307000000144],
                [9.582084, 0.139307000000144],
                [9.581528, 0.140973000000145],
                [9.581528, 0.142083000000127],
                [9.580972, 0.142639000000145],
                [9.580972, 0.143751000000123],
                [9.580418, 0.145139],
                [9.579862, 0.145139],
                [9.579584000000182, 0.146251],
                [9.578749000000187, 0.146527000000106],
                [9.578749000000187, 0.147083000000123],
                [9.577639, 0.148195000000101],
                [9.577639, 0.149027],
                [9.577083000000187, 0.14930500000014],
                [9.577083000000187, 0.151249],
                [9.57652700000017, 0.152083000000118],
                [9.57652700000017, 0.152918000000113],
                [9.575417000000186, 0.152918000000113],
                [9.574305, 0.154306],
                [9.573471, 0.154306],
                [9.572639, 0.15514],
                [9.570695000000114, 0.155694],
                [9.569583, 0.155694],
                [9.568473, 0.155972],
                [9.568195000000173, 0.156806],
                [9.567639, 0.157084000000168],
                [9.565416, 0.157084000000168],
                [9.564585, 0.157362],
                [9.564304000000106, 0.159028],
                [9.563472, 0.159028],
                [9.561528000000123, 0.160972000000186],
                [9.560416000000146, 0.160972000000186],
                [9.559028, 0.161528],
                [9.558750000000146, 0.162084000000164],
                [9.558194000000128, 0.162084000000164],
                [9.557900000000132, 0.161528],
                [9.556806, 0.161528],
                [9.556528000000128, 0.162084000000164],
                [9.55375000000015, 0.162084000000164],
                [9.55375000000015, 0.161806],
                [9.550971, 0.161806],
                [9.550417, 0.16236],
                [9.549583000000155, 0.162638000000186],
                [9.548473000000115, 0.162638000000186],
                [9.547639, 0.163472],
                [9.546527, 0.163472],
                [9.545971, 0.164028],
                [9.542917000000159, 0.164028],
                [9.542361, 0.164582000000109],
                [9.540139000000181, 0.164582000000109],
                [9.53958200000011, 0.164028],
                [9.538473000000181, 0.164306000000181],
                [9.537085, 0.164028],
                [9.535694, 0.164028],
                [9.534862, 0.164306000000181],
                [9.53486, 0.16486],
                [9.53263800000019, 0.16486],
                [9.53263800000019, 0.164582000000109],
                [9.528472, 0.164582000000109],
                [9.527918000000113, 0.165138],
                [9.526806, 0.165138],
                [9.525696, 0.165416000000164],
                [9.524583, 0.166251000000159],
                [9.523749000000123, 0.166251000000159],
                [9.523471, 0.166804],
                [9.522917, 0.166807000000176],
                [9.522361, 0.167361000000142],
                [9.521527000000106, 0.167361000000142],
                [9.520695, 0.168195],
                [9.520139, 0.168195],
                [9.519305000000145, 0.169029000000137],
                [9.519305000000145, 0.169583000000159],
                [9.518473, 0.169861],
                [9.518195000000105, 0.170417],
                [9.517639000000145, 0.170417],
                [9.515417000000127, 0.172639],
                [9.512917, 0.172639],
                [9.512082, 0.171805000000177],
                [9.510972, 0.171527],
                [9.510694000000171, 0.170417],
                [9.510138000000154, 0.170417],
                [9.50986, 0.169861],
                [9.509306, 0.169861],
                [9.508472000000154, 0.169029000000137],
                [9.508472000000154, 0.168195],
                [9.507362000000171, 0.166807000000176],
                [9.506528, 0.166529],
                [9.506528, 0.165973],
                [9.505694000000176, 0.165694],
                [9.505694000000176, 0.16486],
                [9.505140000000154, 0.164582000000109],
                [9.505140000000154, 0.163750000000164],
                [9.504584, 0.163472],
                [9.504306, 0.162638000000186],
                [9.504306, 0.161528],
                [9.50375, 0.160972000000186],
                [9.50375, 0.160138],
                [9.503194, 0.159862],
                [9.503194, 0.156806],
                [9.502916, 0.155972],
                [9.502084, 0.155972],
                [9.501806000000158, 0.155416000000173],
                [9.499862, 0.155416000000173],
                [9.49902800000018, 0.154306],
                [9.49902800000018, 0.153750000000173],
                [9.498196, 0.153750000000173],
                [9.497640000000104, 0.153193000000101],
                [9.496527000000185, 0.152918000000113],
                [9.496249, 0.152083000000118],
                [9.495693, 0.152083000000118],
                [9.494861000000185, 0.151249],
                [9.494027, 0.150971000000141],
                [9.493471000000113, 0.150139],
                [9.493749, 0.149027],
                [9.493749, 0.146805],
                [9.493471000000113, 0.146805],
                [9.493471000000113, 0.143473],
                [9.493749, 0.142639000000145],
                [9.494305, 0.142639000000145],
                [9.494583, 0.140973000000145],
                [9.495417, 0.140139],
                [9.495973000000163, 0.140139],
                [9.496249, 0.139307000000144],
                [9.497083, 0.139029],
                [9.497083, 0.137916000000132],
                [9.497915, 0.136806000000149],
                [9.49902800000018, 0.135972],
                [9.49902800000018, 0.135138000000154],
                [9.499584, 0.134306],
                [9.499584, 0.132916000000137],
                [9.500140000000158, 0.132084],
                [9.500140000000158, 0.130140000000154],
                [9.499862, 0.129028000000176],
                [9.499306000000104, 0.128472000000158],
                [9.49902800000018, 0.127084],
                [9.49902800000018, 0.122917],
                [9.499306000000104, 0.122917],
                [9.499306000000104, 0.120973000000163],
                [9.499584, 0.119583],
                [9.50069400000018, 0.118749],
                [9.50069400000018, 0.118195000000185],
                [9.501528, 0.117639000000167],
                [9.501528, 0.117083],
                [9.502362000000176, 0.116805000000113],
                [9.502916, 0.116251],
                [9.505140000000154, 0.116251],
                [9.505694000000176, 0.115973000000167],
                [9.505694000000176, 0.11486100000019],
                [9.505416, 0.113751],
                [9.504028000000176, 0.11125],
                [9.504028000000176, 0.10819400000014],
                [9.504306, 0.10819400000014],
                [9.504306, 0.105972000000122],
                [9.50486, 0.105416000000105],
                [9.50486, 0.104028],
                [9.505416, 0.103472],
                [9.505694000000176, 0.102362],
                [9.505694000000176, 0.096249000000171],
                [9.505416, 0.096249000000171],
                [9.505416, 0.093749],
                [9.505694000000176, 0.093749],
                [9.505694000000176, 0.092083],
                [9.50625, 0.09125100000017],
                [9.50625, 0.088473],
                [9.505972, 0.087917000000175],
                [9.504584, 0.087917000000175],
                [9.503472000000158, 0.088195],
                [9.502084, 0.089583000000175],
                [9.500418, 0.089861],
                [9.500140000000158, 0.089305],
                [9.49902800000018, 0.089027000000158],
                [9.498749, 0.087917000000175],
                [9.498749, 0.080972],
                [9.49902800000018, 0.080972],
                [9.499306000000104, 0.079584],
                [9.499306000000104, 0.07764],
                [9.499584, 0.07764],
                [9.499584, 0.074584000000129],
                [9.499306000000104, 0.074584000000129],
                [9.499306000000104, 0.072362000000112],
                [9.49902800000018, 0.072362000000112],
                [9.49902800000018, 0.070417000000134],
                [9.498471000000109, 0.069305],
                [9.498471000000109, 0.065973],
                [9.49902800000018, 0.065139],
                [9.49902800000018, 0.063473],
                [9.500140000000158, 0.06097300000016],
                [9.50069400000018, 0.058473],
                [9.50125, 0.058473],
                [9.50125, 0.05513800000017],
                [9.501528, 0.05402800000013],
                [9.500972000000104, 0.053194],
                [9.500140000000158, 0.053194],
                [9.500140000000158, 0.052638],
                [9.499306000000104, 0.05236],
                [9.499306000000104, 0.050694],
                [9.49902800000018, 0.050138000000175],
                [9.498196, 0.050416],
                [9.498196, 0.05569400000013],
                [9.498471000000109, 0.05569400000013],
                [9.498471000000109, 0.059582000000148],
                [9.497915, 0.060139],
                [9.496527000000185, 0.060417000000143],
                [9.496527000000185, 0.059861000000126],
                [9.495973000000163, 0.059582000000148],
                [9.495973000000163, 0.059029],
                [9.495417, 0.058751000000143],
                [9.495417, 0.058194],
                [9.494583, 0.056528],
                [9.494027, 0.056250000000148],
                [9.494027, 0.05569400000013],
                [9.493195000000185, 0.05513800000017],
                [9.492917, 0.054306],
                [9.492361, 0.054306],
                [9.492083, 0.05375],
                [9.490973, 0.05375],
                [9.490417, 0.053194],
                [9.488751, 0.052916000000153],
                [9.488473000000113, 0.05236],
                [9.487361000000135, 0.052084],
                [9.486251, 0.050972],
                [9.485417, 0.049862],
                [9.485138, 0.049862],
                [9.484029000000135, 0.04875],
                [9.484029000000135, 0.048194],
                [9.482916, 0.047362],
                [9.482916, 0.046806000000174],
                [9.48236, 0.046528],
                [9.48236, 0.045418],
                [9.481806, 0.044862],
                [9.481806, 0.043471000000125],
                [9.48152800000014, 0.042639000000179],
                [9.480972000000122, 0.042639000000179],
                [9.480972000000122, 0.041805000000124],
                [9.480138, 0.041805000000124],
                [9.479028, 0.041249000000107],
                [9.478750000000105, 0.040695],
                [9.477640000000122, 0.040695],
                [9.474862000000144, 0.039583000000107],
                [9.474306000000126, 0.040973000000179],
                [9.474862000000144, 0.041527],
                [9.475694, 0.041527],
                [9.47625, 0.042361],
                [9.476806, 0.042639000000179],
                [9.477084000000104, 0.043749],
                [9.477916, 0.044583000000102],
                [9.478472, 0.044583000000102],
                [9.478750000000105, 0.045140000000174],
                [9.47986200000014, 0.045693],
                [9.480138, 0.046250000000157],
                [9.480694, 0.046250000000157],
                [9.48152800000014, 0.047362],
                [9.4820840000001, 0.047362],
                [9.4820840000001, 0.048194],
                [9.482638000000122, 0.049028],
                [9.483472, 0.049306],
                [9.483472, 0.049862],
                [9.484029000000135, 0.050138000000175],
                [9.484029000000135, 0.050694],
                [9.484582, 0.050972],
                [9.485973, 0.05236],
                [9.48652600000014, 0.05236200000013],
                [9.487361000000135, 0.05347200000017],
                [9.488473000000113, 0.05402800000013],
                [9.489305, 0.05402800000013],
                [9.48986100000019, 0.054584000000148],
                [9.490417, 0.054584000000148],
                [9.490973, 0.05513800000017],
                [9.490973, 0.05569400000013],
                [9.491805000000113, 0.056528],
                [9.491805000000113, 0.057360000000131],
                [9.492361, 0.057638],
                [9.492639, 0.058473],
                [9.492639, 0.059582000000148],
                [9.492917, 0.060139],
                [9.493471000000113, 0.060139],
                [9.493471000000113, 0.061529000000121],
                [9.494027, 0.062361],
                [9.494305, 0.063473],
                [9.494305, 0.065695],
                [9.494583, 0.065695],
                [9.494583, 0.067361],
                [9.494861000000185, 0.067361],
                [9.494861000000185, 0.069583],
                [9.495417, 0.070417000000134],
                [9.495417, 0.073193000000117],
                [9.495693, 0.073193000000117],
                [9.495693, 0.074862],
                [9.495417, 0.074862],
                [9.495417, 0.078194],
                [9.495139000000108, 0.078194],
                [9.495139000000108, 0.080416000000184],
                [9.495417, 0.081528000000162],
                [9.495973000000163, 0.081806],
                [9.495973000000163, 0.092083],
                [9.495693, 0.092083],
                [9.495693, 0.094027000000153],
                [9.495139000000108, 0.094305],
                [9.495139000000108, 0.095139000000131],
                [9.494305, 0.095971],
                [9.494305, 0.096805000000131],
                [9.493195000000185, 0.097918],
                [9.492917, 0.098749],
                [9.492083, 0.099862000000144],
                [9.492083, 0.100694],
                [9.490695, 0.102084000000104],
                [9.48986100000019, 0.102084000000104],
                [9.488473000000113, 0.102640000000122],
                [9.48819500000019, 0.103194000000144],
                [9.485417, 0.103194000000144],
                [9.484582, 0.102362],
                [9.4837500000001, 0.103472],
                [9.4837500000001, 0.104862000000139],
                [9.483472, 0.105416000000105],
                [9.48236, 0.105416000000105],
                [9.48152800000014, 0.10625],
                [9.480972000000122, 0.105972000000122],
                [9.478194000000144, 0.105972000000122],
                [9.478194000000144, 0.10625],
                [9.475972000000127, 0.10625],
                [9.474584, 0.106528000000139],
                [9.474028, 0.1070840000001],
                [9.473472, 0.1070840000001],
                [9.473194000000149, 0.107638000000122],
                [9.465139, 0.107638000000122],
                [9.465139, 0.107916],
                [9.463473, 0.107916],
                [9.463195, 0.107362],
                [9.462361000000158, 0.107362],
                [9.458473, 0.103472],
                [9.457638, 0.103472],
                [9.457360000000108, 0.102916],
                [9.45625, 0.102084000000104],
                [9.45625, 0.10125],
                [9.45458200000013, 0.099862000000144],
                [9.453472, 0.098749],
                [9.453472, 0.097639],
                [9.453750000000184, 0.097083],
                [9.45291600000013, 0.096249000000171],
                [9.452362000000107, 0.096249000000171],
                [9.451806, 0.095417],
                [9.451806, 0.094861],
                [9.45125000000013, 0.094583000000171],
                [9.450972, 0.093473000000131],
                [9.45014, 0.09291700000017],
                [9.449862, 0.091805],
                [9.449306, 0.09125100000017],
                [9.449306, 0.090417],
                [9.448472, 0.090139],
                [9.448472, 0.089583000000175],
                [9.447362000000112, 0.089305],
                [9.447362000000112, 0.088751],
                [9.446250000000134, 0.087361000000158],
                [9.44514, 0.086529],
                [9.443749000000139, 0.086529],
                [9.443193000000122, 0.086804],
                [9.443193000000122, 0.088473],
                [9.443471, 0.089583000000175],
                [9.444027, 0.089861],
                [9.444027, 0.090973],
                [9.444584000000134, 0.091805],
                [9.445693, 0.092639],
                [9.446528, 0.092639],
                [9.447362000000112, 0.093473000000131],
                [9.448194, 0.093473000000131],
                [9.448194, 0.094027000000153],
                [9.449028000000112, 0.094305],
                [9.449028000000112, 0.095139000000131],
                [9.44791800000013, 0.095417],
                [9.447362000000112, 0.094583000000171],
                [9.446806, 0.094305],
                [9.44514, 0.094305],
                [9.44541500000014, 0.095971],
                [9.445972, 0.096527],
                [9.446528, 0.096527],
                [9.447362000000112, 0.097361000000149],
                [9.448194, 0.097361000000149],
                [9.449306, 0.097918],
                [9.449306, 0.098749],
                [9.45014, 0.099027000000149],
                [9.45014, 0.099584],
                [9.45125000000013, 0.099862000000144],
                [9.45125000000013, 0.100418000000104],
                [9.453472, 0.101528000000144],
                [9.45458200000013, 0.101806],
                [9.45458200000013, 0.102362],
                [9.455138, 0.102916],
                [9.455972, 0.103194000000144],
                [9.457360000000108, 0.104584],
                [9.458473, 0.105972000000122],
                [9.459861000000103, 0.106528000000139],
                [9.459861000000103, 0.107362],
                [9.460417, 0.107362],
                [9.461251000000175, 0.108472],
                [9.461805, 0.108472],
                [9.462639, 0.109306000000117],
                [9.463473, 0.109306000000117],
                [9.464027000000158, 0.10986000000014],
                [9.465971, 0.10986000000014],
                [9.465971, 0.109584],
                [9.46791700000017, 0.109584],
                [9.46791700000017, 0.109306000000117],
                [9.47124900000017, 0.109306000000117],
                [9.47124900000017, 0.109028],
                [9.472915000000171, 0.109028],
                [9.474306000000126, 0.1087500000001],
                [9.474306000000126, 0.108472],
                [9.475972000000127, 0.108472],
                [9.475972000000127, 0.10819400000014],
                [9.477916, 0.10819400000014],
                [9.478750000000105, 0.109306000000117],
                [9.480138, 0.110138],
                [9.480138, 0.110694],
                [9.481806, 0.1120820000001],
                [9.4820840000001, 0.112638000000118],
                [9.482638000000122, 0.112638000000118],
                [9.483472, 0.113751],
                [9.484029000000135, 0.113751],
                [9.484304000000122, 0.114583],
                [9.48486000000014, 0.11486100000019],
                [9.48486000000014, 0.117917],
                [9.484582, 0.119027],
                [9.484029000000135, 0.119583],
                [9.484029000000135, 0.120417],
                [9.483472, 0.120695],
                [9.483472, 0.121805000000109],
                [9.482916, 0.12319500000018],
                [9.4820840000001, 0.123471000000109],
                [9.48152800000014, 0.124027],
                [9.480694, 0.126528],
                [9.480694, 0.132084],
                [9.480138, 0.132362000000171],
                [9.47986200000014, 0.133194],
                [9.47986200000014, 0.134306],
                [9.479306000000122, 0.13486],
                [9.479306000000122, 0.136528],
                [9.479028, 0.137084],
                [9.478194000000144, 0.137360000000172],
                [9.478194000000144, 0.138751000000127],
                [9.477916, 0.139582000000132],
                [9.47736, 0.139582000000132],
                [9.47736, 0.140417000000127],
                [9.476806, 0.140695],
                [9.476806, 0.141529000000105],
                [9.47625, 0.141529000000105],
                [9.475694, 0.142083000000127],
                [9.475416000000166, 0.143473],
                [9.474584, 0.144027],
                [9.474584, 0.145139],
                [9.474028, 0.145417000000123],
                [9.474028, 0.146251],
                [9.473472, 0.146805],
                [9.473472, 0.147917],
                [9.472915000000171, 0.149027],
                [9.471806, 0.150139],
                [9.47124900000017, 0.150417000000118],
                [9.47124900000017, 0.150971000000141],
                [9.470696, 0.151249],
                [9.470418000000166, 0.152362],
                [9.469861, 0.15264],
                [9.469305, 0.153750000000173],
                [9.469305, 0.154584000000114],
                [9.468749, 0.154862],
                [9.468749, 0.155694],
                [9.468193, 0.155972],
                [9.468193, 0.157084000000168],
                [9.467639, 0.157638],
                [9.467639, 0.158750000000168],
                [9.466527, 0.159584000000109],
                [9.466527, 0.160416000000168],
                [9.465971, 0.160694],
                [9.465971, 0.161250000000109],
                [9.465417, 0.161806],
                [9.464027000000158, 0.164582000000109],
                [9.463473, 0.165138],
                [9.463473, 0.165973],
                [9.462639, 0.166807000000176],
                [9.462639, 0.167361000000142],
                [9.462083, 0.167639],
                [9.462083, 0.168195],
                [9.461251000000175, 0.169861],
                [9.460695000000158, 0.169861],
                [9.460139, 0.170417],
                [9.460139, 0.171251000000154],
                [9.459307, 0.171527],
                [9.458751, 0.172361000000137],
                [9.458751, 0.173195],
                [9.458195000000103, 0.173473000000115],
                [9.458195000000103, 0.174861],
                [9.457638, 0.175139000000115],
                [9.456528, 0.176249000000155],
                [9.456528, 0.177083],
                [9.454306, 0.179305],
                [9.453472, 0.180415],
                [9.452362000000107, 0.180972000000111],
                [9.452353, 0.181297],
                [9.449862, 0.18125],
                [9.448750000000189, 0.180693000000133],
                [9.448472, 0.179862000000128],
                [9.447362000000112, 0.178749],
                [9.446528, 0.178471000000116],
                [9.445696000000112, 0.177639],
                [9.445696000000112, 0.176249000000155],
                [9.44514, 0.175973],
                [9.444584000000134, 0.176527],
                [9.444584000000134, 0.177639],
                [9.443471, 0.178490000000124],
                [9.442917, 0.179305],
                [9.440695, 0.179305],
                [9.43930500000016, 0.178749],
                [9.439027, 0.178196000000128],
                [9.438195000000121, 0.178196000000128],
                [9.438154000000111, 0.176249000000155],
                [9.438751000000138, 0.175417],
                [9.438471, 0.174305],
                [9.438471, 0.172639],
                [9.438751000000138, 0.172639],
                [9.438751000000138, 0.170139000000177],
                [9.438471, 0.170139000000177],
                [9.438471, 0.168473000000176],
                [9.437917, 0.167917000000159],
                [9.437917, 0.167082000000164],
                [9.436805, 0.16486],
                [9.436249, 0.164582000000109],
                [9.43597300000016, 0.163472],
                [9.43597300000016, 0.16236],
                [9.435417000000143, 0.161806],
                [9.435417000000143, 0.160972000000186],
                [9.434861000000126, 0.160416000000168],
                [9.434583, 0.159584000000109],
                [9.434583, 0.158472],
                [9.434027, 0.157362],
                [9.434027, 0.154584000000114],
                [9.433751000000143, 0.154028],
                [9.434027, 0.153193000000101],
                [9.434027, 0.151527000000101],
                [9.433195000000126, 0.149861000000101],
                [9.432917, 0.149861000000101],
                [9.432917, 0.143195000000105],
                [9.432361, 0.140973000000145],
                [9.431807, 0.140695],
                [9.431807, 0.139029],
                [9.430416, 0.137638],
                [9.43013800000017, 0.137084],
                [9.429585, 0.137084],
                [9.42847200000017, 0.135972],
                [9.42736200000013, 0.135416],
                [9.426528, 0.134306],
                [9.425972, 0.134306],
                [9.42486, 0.133194],
                [9.42486, 0.132638],
                [9.424028, 0.131806000000154],
                [9.42264, 0.131250000000136],
                [9.421528, 0.131250000000136],
                [9.421528, 0.131806000000154],
                [9.422916, 0.133472000000154],
                [9.42375, 0.133472000000154],
                [9.42486, 0.134584000000132],
                [9.425972, 0.135138000000154],
                [9.426250000000152, 0.135694000000171],
                [9.42736200000013, 0.135972],
                [9.429304, 0.137916000000132],
                [9.429585, 0.138472000000149],
                [9.430416, 0.138751000000127],
                [9.430416, 0.139307000000144],
                [9.431251, 0.140139],
                [9.431251, 0.141805],
                [9.431529000000126, 0.141805],
                [9.431529000000126, 0.143473],
                [9.432082, 0.144583],
                [9.432082, 0.147083000000123],
                [9.431807, 0.147083000000123],
                [9.431807, 0.151805],
                [9.432082, 0.152918000000113],
                [9.432639000000165, 0.154028],
                [9.432639000000165, 0.157084000000168],
                [9.432917, 0.157084000000168],
                [9.432917, 0.159306000000186],
                [9.433473, 0.159862],
                [9.433473, 0.160972000000186],
                [9.434305000000165, 0.161528],
                [9.434861000000126, 0.162638000000186],
                [9.434861000000126, 0.163750000000164],
                [9.435695, 0.164306000000181],
                [9.43597300000016, 0.165973],
                [9.437083000000143, 0.167917000000159],
                [9.437083000000143, 0.169029000000137],
                [9.43763900000016, 0.169861],
                [9.43763900000016, 0.174305],
                [9.437361, 0.174305],
                [9.437361, 0.180693000000133],
                [9.43763900000016, 0.180693000000133],
                [9.43763900000016, 0.182362],
                [9.437917, 0.183750000000146],
                [9.437361, 0.184306000000106],
                [9.437083000000143, 0.185138],
                [9.437083000000143, 0.187916],
                [9.436805, 0.187916],
                [9.436805, 0.189860000000124],
                [9.43597300000016, 0.190416],
                [9.43597300000016, 0.19125],
                [9.434861000000126, 0.192360000000178],
                [9.434861000000126, 0.192916],
                [9.434305000000165, 0.193195000000173],
                [9.434305000000165, 0.194304000000102],
                [9.433473, 0.195695000000114],
                [9.431807, 0.196251],
                [9.431529000000126, 0.196805],
                [9.43069400000013, 0.196805],
                [9.43013800000017, 0.197639],
                [9.429585, 0.197639],
                [9.42902800000013, 0.198473],
                [9.42902800000013, 0.199861000000169],
                [9.42847200000017, 0.200139],
                [9.42847200000017, 0.200695],
                [9.426528, 0.201805],
                [9.425972, 0.201805],
                [9.425416, 0.202639000000147],
                [9.425416, 0.204305000000147],
                [9.425138000000175, 0.204861000000164],
                [9.424584000000152, 0.204861000000164],
                [9.424028, 0.20569600000016],
                [9.423472000000174, 0.205971000000147],
                [9.421806000000174, 0.205971000000147],
                [9.421528, 0.206527000000165],
                [9.420694, 0.206806000000142],
                [9.418750000000102, 0.206806000000142],
                [9.41764, 0.207084],
                [9.415974, 0.206249],
                [9.415971000000184, 0.20569600000016],
                [9.415139000000124, 0.205139],
                [9.413749, 0.203749000000187],
                [9.412639000000183, 0.202917],
                [9.412639000000183, 0.201805],
                [9.411805000000129, 0.200973000000147],
                [9.411527, 0.199583],
                [9.410973000000183, 0.199583],
                [9.410139000000129, 0.198473],
                [9.409861, 0.197361000000114],
                [9.408195, 0.195695000000114],
                [9.408195, 0.195139],
                [9.407639, 0.194861000000174],
                [9.407639, 0.194029000000114],
                [9.407083, 0.193751],
                [9.407083, 0.193195000000173],
                [9.406527, 0.192638000000102],
                [9.405695, 0.192360000000178],
                [9.405417, 0.191528000000119],
                [9.405417, 0.18875],
                [9.405139000000133, 0.18875],
                [9.405139000000133, 0.186806],
                [9.405695, 0.186528000000123],
                [9.406251000000111, 0.185416000000146],
                [9.406251000000111, 0.184028],
                [9.405417, 0.184028],
                [9.404860000000156, 0.183194000000128],
                [9.404582, 0.181806],
                [9.404582, 0.173195],
                [9.403751, 0.172917000000155],
                [9.402916, 0.171805000000177],
                [9.402084000000116, 0.171527],
                [9.402084000000116, 0.170973],
                [9.40125, 0.169861],
                [9.400694, 0.169583000000159],
                [9.400138, 0.168473000000176],
                [9.399306000000138, 0.167639],
                [9.399028, 0.166807000000176],
                [9.39819400000016, 0.166807000000176],
                [9.39819400000016, 0.167639],
                [9.39875000000012, 0.167917000000159],
                [9.399582, 0.169029000000137],
                [9.399582, 0.169583000000159],
                [9.400138, 0.169861],
                [9.400138, 0.170417],
                [9.400694, 0.170695000000137],
                [9.40125, 0.171805000000177],
                [9.401806, 0.172361000000137],
                [9.402638000000138, 0.174861],
                [9.402916, 0.176249000000155],
                [9.402916, 0.179584],
                [9.402638000000138, 0.179584],
                [9.402638000000138, 0.183194000000128],
                [9.402916, 0.184584],
                [9.40236, 0.185694],
                [9.40236, 0.186806],
                [9.401528000000155, 0.186806],
                [9.400972000000138, 0.18736],
                [9.40041600000012, 0.18736],
                [9.399582, 0.187916],
                [9.399582, 0.188472],
                [9.39875000000012, 0.189028000000178],
                [9.39708400000012, 0.189306000000101],
                [9.396806, 0.190138],
                [9.396806, 0.191807],
                [9.39652800000016, 0.191807],
                [9.395972000000143, 0.192916],
                [9.395972000000143, 0.193751],
                [9.395416000000125, 0.194304000000102],
                [9.39486200000016, 0.195973],
                [9.395138, 0.197083],
                [9.395138, 0.201249],
                [9.395694, 0.202083000000187],
                [9.395694, 0.20569600000016],
                [9.394584, 0.206806000000142],
                [9.394584, 0.20736200000016],
                [9.393472, 0.20736200000016],
                [9.393194000000165, 0.208194],
                [9.391249000000187, 0.208194],
                [9.39014, 0.20764],
                [9.38902700000017, 0.206527000000165],
                [9.38902700000017, 0.205971000000147],
                [9.388193, 0.20569600000016],
                [9.386527, 0.204027],
                [9.385417, 0.203749000000187],
                [9.385139, 0.203195000000164],
                [9.383749, 0.202639000000147],
                [9.382639, 0.202639000000147],
                [9.382639, 0.202083000000187],
                [9.38125100000019, 0.201805],
                [9.380417, 0.200973000000147],
                [9.379583, 0.200973000000147],
                [9.379307, 0.200417000000186],
                [9.378473, 0.200417000000186],
                [9.377360000000124, 0.199861000000169],
                [9.377085, 0.198751000000186],
                [9.376529000000119, 0.198473],
                [9.376529000000119, 0.197361000000114],
                [9.375972, 0.196805],
                [9.375694000000124, 0.195695000000114],
                [9.375694000000124, 0.194583],
                [9.37486, 0.192638000000102],
                [9.374306, 0.191807],
                [9.374028000000123, 0.190694000000178],
                [9.374028000000123, 0.189028000000178],
                [9.374582, 0.188194000000124],
                [9.375138000000106, 0.188194000000124],
                [9.375138000000106, 0.18736],
                [9.375694000000124, 0.187084],
                [9.375416, 0.185972000000106],
                [9.375416, 0.184028],
                [9.37625, 0.183194000000128],
                [9.37625, 0.181806],
                [9.377085, 0.181528000000128],
                [9.377085, 0.176249000000155],
                [9.378195000000119, 0.175139000000115],
                [9.378473, 0.174305],
                [9.378473, 0.173195],
                [9.377085, 0.173195],
                [9.377085, 0.175417],
                [9.375972, 0.175973],
                [9.375972, 0.176527],
                [9.37486, 0.176805000000115],
                [9.373472000000106, 0.176805000000115],
                [9.373472000000106, 0.177361000000133],
                [9.374028000000123, 0.17791700000015],
                [9.37486, 0.178196000000128],
                [9.375416, 0.179027000000133],
                [9.375416, 0.181528000000128],
                [9.375138000000106, 0.181528000000128],
                [9.375138000000106, 0.183194000000128],
                [9.374582, 0.183750000000146],
                [9.374582, 0.184584],
                [9.374028000000123, 0.184584],
                [9.37375, 0.185138],
                [9.372638, 0.185694],
                [9.369306, 0.185694],
                [9.36875, 0.184862000000123],
                [9.367918, 0.184862000000123],
                [9.367638, 0.184306000000106],
                [9.366528, 0.184306000000106],
                [9.36625000000015, 0.183750000000146],
                [9.365694000000133, 0.183750000000146],
                [9.36514000000011, 0.183194000000128],
                [9.364306, 0.183194000000128],
                [9.36291800000015, 0.182362],
                [9.36291800000015, 0.18014],
                [9.362639000000115, 0.179862000000128],
                [9.361527000000137, 0.179862000000128],
                [9.359861000000137, 0.181528000000128],
                [9.359861000000137, 0.182362],
                [9.360417000000155, 0.182918],
                [9.360973000000115, 0.182918],
                [9.361249, 0.183472],
                [9.362361, 0.183750000000146],
                [9.36291800000015, 0.184862000000123],
                [9.363471, 0.184862000000123],
                [9.364027, 0.185694],
                [9.365415000000155, 0.185972000000106],
                [9.365972, 0.186528000000123],
                [9.365972, 0.187916],
                [9.365415000000155, 0.187916],
                [9.36458400000015, 0.18875],
                [9.36458400000015, 0.189306000000101],
                [9.363471, 0.190138],
                [9.363471, 0.191807],
                [9.36458400000015, 0.192360000000178],
                [9.365972, 0.192638000000102],
                [9.366528, 0.193473],
                [9.367362000000128, 0.193751],
                [9.367084, 0.194304000000102],
                [9.367084, 0.203749000000187],
                [9.36680600000011, 0.204305000000147],
                [9.365972, 0.204305000000147],
                [9.365415000000155, 0.203749000000187],
                [9.364027, 0.201249],
                [9.364027, 0.200139],
                [9.363471, 0.199583],
                [9.363471, 0.198751000000186],
                [9.362083000000155, 0.197083],
                [9.361249, 0.197083],
                [9.361249, 0.197639],
                [9.361805, 0.198195000000169],
                [9.362361, 0.198195000000169],
                [9.362361, 0.199583],
                [9.361805, 0.199861000000169],
                [9.361805, 0.201805],
                [9.363471, 0.201805],
                [9.363749000000155, 0.202083000000187],
                [9.364027, 0.203473],
                [9.36458400000015, 0.204583],
                [9.365694000000133, 0.20569600000016],
                [9.36847200000011, 0.20569600000016],
                [9.36875, 0.205418],
                [9.36875, 0.203471],
                [9.36847200000011, 0.202639000000147],
                [9.369028000000128, 0.202361],
                [9.369306, 0.201527000000169],
                [9.370416, 0.201527000000169],
                [9.372084, 0.200973000000147],
                [9.372638, 0.201527000000169],
                [9.374306, 0.202361],
                [9.375138000000106, 0.202361],
                [9.375416, 0.202917],
                [9.379307, 0.202917],
                [9.379861000000119, 0.203195000000164],
                [9.380139, 0.204305000000147],
                [9.381529, 0.204861000000164],
                [9.381529, 0.205418],
                [9.382639, 0.206249],
                [9.382917000000191, 0.206806000000142],
                [9.382917000000191, 0.208472000000143],
                [9.382083, 0.20902800000016],
                [9.382083, 0.210138000000143],
                [9.381805, 0.210972],
                [9.38125100000019, 0.211528],
                [9.38125100000019, 0.212362000000155],
                [9.380695000000173, 0.212916000000121],
                [9.380417, 0.214306],
                [9.380973, 0.214584000000116],
                [9.380973, 0.215138000000138],
                [9.382639, 0.215138000000138],
                [9.382639, 0.215694000000155],
                [9.384583, 0.217082],
                [9.384861, 0.217638],
                [9.385417, 0.217638],
                [9.385417, 0.21875],
                [9.385971, 0.21875],
                [9.386249, 0.219582000000116],
                [9.386805, 0.219582000000116],
                [9.387083, 0.220695],
                [9.387639, 0.220973000000129],
                [9.387639, 0.221529],
                [9.388193, 0.221805],
                [9.388193, 0.223195],
                [9.388473000000147, 0.224027],
                [9.38902700000017, 0.224305000000129],
                [9.38902700000017, 0.226805000000184],
                [9.388749, 0.226805000000184],
                [9.388749, 0.229583],
                [9.38902700000017, 0.229583],
                [9.38902700000017, 0.231805000000179],
                [9.389583000000187, 0.232083000000102],
                [9.389583000000187, 0.234306000000174],
                [9.389305, 0.23514],
                [9.388749, 0.235418000000152],
                [9.388193, 0.236806],
                [9.387639, 0.239028],
                [9.387639, 0.240694],
                [9.387917000000186, 0.240694],
                [9.387917000000186, 0.24236],
                [9.387083, 0.243194000000187],
                [9.386805, 0.244584],
                [9.386249, 0.244860000000187],
                [9.386249, 0.245972000000165],
                [9.385695000000169, 0.246529],
                [9.385417, 0.24736],
                [9.384861, 0.247639],
                [9.382083, 0.247639],
                [9.381805, 0.247082000000148],
                [9.380973, 0.247082000000148],
                [9.380695000000173, 0.246529],
                [9.380139, 0.246529],
                [9.379861000000119, 0.245972000000165],
                [9.379026000000124, 0.245972000000165],
                [9.379861000000119, 0.24736],
                [9.380417, 0.24736],
                [9.380973, 0.247917000000143],
                [9.381529, 0.249029000000121],
                [9.381529, 0.250695000000121],
                [9.381805, 0.250695000000121],
                [9.381805, 0.252361000000121],
                [9.382083, 0.253195],
                [9.380973, 0.253749],
                [9.380973, 0.254305],
                [9.380417, 0.254583000000139],
                [9.380417, 0.256527],
                [9.379861000000119, 0.256805000000156],
                [9.379861000000119, 0.257917000000134],
                [9.378195000000119, 0.258749],
                [9.377917, 0.259583000000134],
                [9.378195000000119, 0.260693000000117],
                [9.378751, 0.261806],
                [9.378751, 0.264028],
                [9.379026000000124, 0.264862000000107],
                [9.379583, 0.26541600000013],
                [9.379583, 0.269028],
                [9.379026000000124, 0.269860000000108],
                [9.378751, 0.270972],
                [9.378751, 0.274861000000158],
                [9.378473, 0.274861000000158],
                [9.378473, 0.276529000000153],
                [9.378195000000119, 0.277917],
                [9.377360000000124, 0.279027],
                [9.377085, 0.279861000000153],
                [9.377085, 0.282639000000131],
                [9.376529000000119, 0.283471],
                [9.376529000000119, 0.284861000000149],
                [9.375972, 0.285971000000131],
                [9.375416, 0.286527000000149],
                [9.375416, 0.287084],
                [9.37486, 0.288193000000149],
                [9.374028000000123, 0.289028000000144],
                [9.373194, 0.289306],
                [9.373194, 0.289862],
                [9.372362000000123, 0.290140000000122],
                [9.372362000000123, 0.290972],
                [9.371806000000106, 0.291250000000161],
                [9.369306, 0.291250000000161],
                [9.36875, 0.290694000000144],
                [9.367638, 0.290694000000144],
                [9.367362000000128, 0.291250000000161],
                [9.368194, 0.292084],
                [9.369584, 0.292916000000162],
                [9.370416, 0.29375],
                [9.370694000000128, 0.294862],
                [9.372362000000123, 0.294862],
                [9.372916, 0.29569400000014],
                [9.374306, 0.295972],
                [9.37486, 0.297638],
                [9.37486, 0.29875],
                [9.375416, 0.2995820000001],
                [9.375416, 0.300416],
                [9.375972, 0.300416],
                [9.37625, 0.300973000000113],
                [9.37625, 0.305139],
                [9.375694000000124, 0.305139],
                [9.375972, 0.306251],
                [9.376804000000106, 0.306251],
                [9.377917, 0.306805],
                [9.378195000000119, 0.307639000000108],
                [9.377638, 0.307917],
                [9.377085, 0.309027000000185],
                [9.37625, 0.309583],
                [9.37625, 0.31069500000018],
                [9.375694000000124, 0.311527],
                [9.37486, 0.311805000000163],
                [9.37486, 0.312639000000104],
                [9.374306, 0.312918],
                [9.373194, 0.31514],
                [9.370416, 0.317916],
                [9.370416, 0.318472],
                [9.369584, 0.31875],
                [9.369584, 0.319584],
                [9.369028000000128, 0.3201380000001],
                [9.36847200000011, 0.3201380000001],
                [9.368194, 0.32125],
                [9.367638, 0.32125],
                [9.367084, 0.322084000000132],
                [9.367084, 0.324028],
                [9.367638, 0.325138],
                [9.367638, 0.327082000000132],
                [9.36680600000011, 0.328473000000145],
                [9.365972, 0.329305],
                [9.365972, 0.329861],
                [9.364306, 0.330973],
                [9.364027, 0.331527],
                [9.36291800000015, 0.332639],
                [9.361805, 0.334027000000106],
                [9.361805, 0.337361000000101],
                [9.360973000000115, 0.338749],
                [9.360417000000155, 0.338749],
                [9.360417000000155, 0.339583000000118],
                [9.35930500000012, 0.340696],
                [9.35930500000012, 0.341806],
                [9.358471, 0.34264],
                [9.35708300000016, 0.343194],
                [9.35708300000016, 0.343750000000114],
                [9.356249, 0.344028],
                [9.35597300000012, 0.345138],
                [9.355139, 0.345972],
                [9.355139, 0.347084000000109],
                [9.35597300000012, 0.34764],
                [9.35597300000012, 0.350138000000186],
                [9.355417000000159, 0.350972],
                [9.355417000000159, 0.352082000000109],
                [9.354861000000142, 0.352638],
                [9.352917, 0.352638],
                [9.352639000000181, 0.352082000000109],
                [9.350973000000181, 0.352082000000109],
                [9.34986, 0.351528],
                [9.348194, 0.34986],
                [9.347638, 0.348750000000109],
                [9.347638, 0.348194],
                [9.345972, 0.346528],
                [9.345694, 0.346528],
                [9.344584000000168, 0.345416000000114],
                [9.344584000000168, 0.344862],
                [9.344028, 0.344306],
                [9.34347200000019, 0.344306],
                [9.342916000000173, 0.343750000000114],
                [9.34264, 0.34264],
                [9.34180600000019, 0.341528],
                [9.340418000000113, 0.340418000000113],
                [9.340418000000113, 0.339583000000118],
                [9.339584000000173, 0.339027000000101],
                [9.339584000000173, 0.338195],
                [9.339028, 0.338195],
                [9.338472, 0.337639],
                [9.338472, 0.337083],
                [9.337915000000123, 0.336527],
                [9.336805, 0.335973],
                [9.336805, 0.335417],
                [9.335971, 0.334583000000123],
                [9.334305, 0.33347300000014],
                [9.334305, 0.332917000000123],
                [9.333471000000145, 0.332361000000105],
                [9.332917000000123, 0.331527],
                [9.331805000000145, 0.331527],
                [9.331527, 0.330973],
                [9.329583000000127, 0.330973],
                [9.329029000000105, 0.330417],
                [9.324861, 0.330417],
                [9.324861, 0.330139000000145],
                [9.322085, 0.330139000000145],
                [9.321528000000114, 0.330695000000105],
                [9.319306000000154, 0.330695000000105],
                [9.318472, 0.331251000000123],
                [9.316806, 0.331251000000123],
                [9.31625, 0.330695000000105],
                [9.316528000000176, 0.330139000000145],
                [9.316528000000176, 0.328473000000145],
                [9.315138, 0.327082000000132],
                [9.315138, 0.326529],
                [9.314306000000158, 0.325694],
                [9.312640000000158, 0.325694],
                [9.312362, 0.325138],
                [9.31152800000018, 0.324860000000172],
                [9.31125, 0.324028],
                [9.31125, 0.322916],
                [9.311806, 0.322084000000132],
                [9.311806, 0.318472],
                [9.31152800000018, 0.318472],
                [9.31152800000018, 0.31625],
                [9.310971000000109, 0.315972000000158],
                [9.310696, 0.314306000000158],
                [9.31125, 0.314027000000181],
                [9.31125, 0.313471000000163],
                [9.310696, 0.313193],
                [9.310415, 0.31236100000018],
                [9.310415, 0.311249],
                [9.31014, 0.31069500000018],
                [9.309027000000185, 0.309861],
                [9.309027000000185, 0.309027000000185],
                [9.308193, 0.308749],
                [9.307639000000108, 0.308195],
                [9.306805000000168, 0.307917],
                [9.302083, 0.307917],
                [9.301251, 0.307083],
                [9.301251, 0.304583],
                [9.301805, 0.304305000000113],
                [9.301805, 0.302639000000113],
                [9.302083, 0.302639000000113],
                [9.302083, 0.300695000000189],
                [9.302639000000113, 0.300416],
                [9.302639000000113, 0.29875],
                [9.303195, 0.297638],
                [9.303195, 0.295138000000122],
                [9.303473000000167, 0.295138000000122],
                [9.303749, 0.29375],
                [9.303749, 0.290972],
                [9.304305000000113, 0.290972],
                [9.304861, 0.289306],
                [9.304861, 0.28764],
                [9.305139000000167, 0.286249],
                [9.305695000000185, 0.285139],
                [9.305695000000185, 0.283471],
                [9.306249, 0.283195000000148],
                [9.306249, 0.281249],
                [9.306805000000168, 0.280139],
                [9.306805000000168, 0.277917],
                [9.307083, 0.277917],
                [9.307083, 0.275417000000175],
                [9.307361000000185, 0.275417000000175],
                [9.307361000000185, 0.273473],
                [9.307917, 0.27291600000018],
                [9.308193, 0.271528000000103],
                [9.308193, 0.269860000000108],
                [9.308474, 0.269860000000108],
                [9.308474, 0.267916000000184],
                [9.309027000000185, 0.267084000000125],
                [9.309027000000185, 0.26541600000013],
                [9.309583, 0.26375000000013],
                [9.30986200000018, 0.26375000000013],
                [9.30986200000018, 0.260418000000129],
                [9.31014, 0.260418000000129],
                [9.31014, 0.258471000000156],
                [9.310415, 0.257083],
                [9.310696, 0.257083],
                [9.310696, 0.255139000000156],
                [9.310971000000109, 0.255139000000156],
                [9.310971000000109, 0.250417],
                [9.311806, 0.249861],
                [9.311806, 0.24847300000016],
                [9.312362, 0.247917000000143],
                [9.312362, 0.243472],
                [9.312918, 0.243472],
                [9.312918, 0.241806],
                [9.31319400000018, 0.241806],
                [9.31319400000018, 0.237362],
                [9.31375, 0.236528],
                [9.31375, 0.234862],
                [9.314028, 0.234862],
                [9.314028, 0.228195],
                [9.314306000000158, 0.228195],
                [9.314306000000158, 0.225417000000107],
                [9.31375, 0.224861],
                [9.31375, 0.223751000000107],
                [9.313472, 0.223195],
                [9.31152800000018, 0.221251],
                [9.31125, 0.219582000000116],
                [9.311806, 0.219029],
                [9.311806, 0.216250000000116],
                [9.312362, 0.215694000000155],
                [9.312084, 0.214306],
                [9.312084, 0.212916000000121],
                [9.312640000000158, 0.212362000000155],
                [9.312640000000158, 0.209862],
                [9.312362, 0.20902800000016],
                [9.312918, 0.20875],
                [9.312640000000158, 0.20764],
                [9.312640000000158, 0.206249],
                [9.312918, 0.205139],
                [9.313472, 0.204583],
                [9.31375, 0.203749000000187],
                [9.313472, 0.202361],
                [9.313472, 0.200417000000186],
                [9.31319400000018, 0.200417000000186],
                [9.31319400000018, 0.198473],
                [9.313472, 0.198473],
                [9.313472, 0.196805],
                [9.31375, 0.196805],
                [9.31375, 0.193195000000173],
                [9.313472, 0.192638000000102],
                [9.314584, 0.191528000000119],
                [9.314306000000158, 0.190694000000178],
                [9.314584, 0.189306000000101],
                [9.314584, 0.187638000000106],
                [9.315138, 0.187084],
                [9.314862000000176, 0.185416000000146],
                [9.315416, 0.184862000000123],
                [9.315416, 0.183750000000146],
                [9.315972000000158, 0.182640000000106],
                [9.315416, 0.182362],
                [9.315416, 0.180693000000133],
                [9.315694, 0.180693000000133],
                [9.315694, 0.179027000000133],
                [9.316528000000176, 0.178749],
                [9.316528000000176, 0.177639],
                [9.317084000000136, 0.177083],
                [9.31736, 0.175417],
                [9.317916, 0.174861],
                [9.317916, 0.173473000000115],
                [9.318750000000136, 0.172639],
                [9.318472, 0.172083],
                [9.318750000000136, 0.171251000000154],
                [9.318750000000136, 0.170139000000177],
                [9.319028, 0.169029000000137],
                [9.319582, 0.168473000000176],
                [9.319582, 0.167917000000159],
                [9.320416000000137, 0.167361000000142],
                [9.320416000000137, 0.165416000000164],
                [9.320694, 0.165416000000164],
                [9.320694, 0.163750000000164],
                [9.320972000000154, 0.16236],
                [9.321528000000114, 0.161250000000109],
                [9.32125, 0.160694],
                [9.32236, 0.160138],
                [9.32236, 0.159306000000186],
                [9.322916, 0.158750000000168],
                [9.32347300000015, 0.156528],
                [9.32347300000015, 0.154584000000114],
                [9.323751, 0.154584000000114],
                [9.323751, 0.147083000000123],
                [9.324582, 0.146251],
                [9.324582, 0.143751000000123],
                [9.324861, 0.143751000000123],
                [9.324861, 0.141805],
                [9.325417, 0.141529000000105],
                [9.325973, 0.140139],
                [9.325973, 0.138194],
                [9.326527, 0.137638],
                [9.326527, 0.136250000000132],
                [9.327083, 0.135972],
                [9.32736100000011, 0.135138000000154],
                [9.32736100000011, 0.134028000000171],
                [9.327917000000127, 0.132916000000137],
                [9.328473000000145, 0.132638],
                [9.329029000000105, 0.129862],
                [9.329861, 0.127916],
                [9.329861, 0.126249],
                [9.330139000000145, 0.126249],
                [9.330139000000145, 0.120695],
                [9.329583000000127, 0.120417],
                [9.329305, 0.119305000000168],
                [9.329305, 0.117639000000167],
                [9.329583000000127, 0.116251],
                [9.330139000000145, 0.115973000000167],
                [9.330417, 0.114583],
                [9.330417, 0.111529000000189],
                [9.331251000000123, 0.110694],
                [9.331251000000123, 0.110138],
                [9.331805000000145, 0.109584],
                [9.331805000000145, 0.106528000000139],
                [9.332083, 0.106528000000139],
                [9.332083, 0.104028],
                [9.332917000000123, 0.103750000000105],
                [9.332917000000123, 0.102084000000104],
                [9.333195, 0.100972000000127],
                [9.333749, 0.100694],
                [9.334027000000106, 0.099306000000126],
                [9.334027000000106, 0.097361000000149],
                [9.335139, 0.096527],
                [9.335139, 0.095139000000131],
                [9.335693000000106, 0.094583000000171],
                [9.335693000000106, 0.09291700000017],
                [9.336249000000123, 0.092361000000153],
                [9.336249000000123, 0.090695000000153],
                [9.336805, 0.088473],
                [9.337362, 0.087639],
                [9.337362, 0.086251000000175],
                [9.33764, 0.08541600000018],
                [9.338196, 0.084860000000162],
                [9.338196, 0.082916],
                [9.338472, 0.082916],
                [9.338472, 0.08125],
                [9.339028, 0.080972],
                [9.339028, 0.077362000000107],
                [9.339306, 0.077362000000107],
                [9.339306, 0.075694000000112],
                [9.339584000000173, 0.074584000000129],
                [9.340138, 0.073750000000189],
                [9.340418000000113, 0.07264],
                [9.340418000000113, 0.070417000000134],
                [9.341250000000173, 0.068749000000139],
                [9.341528, 0.068749000000139],
                [9.341528, 0.066805],
                [9.34180600000019, 0.066805],
                [9.34180600000019, 0.064305000000161],
                [9.341528, 0.062917],
                [9.342084000000114, 0.061805],
                [9.342362, 0.060695],
                [9.342362, 0.058194],
                [9.34264, 0.057082],
                [9.343194, 0.056250000000148],
                [9.343194, 0.054584000000148],
                [9.34347200000019, 0.054584000000148],
                [9.34347200000019, 0.05236200000013],
                [9.344306, 0.051528],
                [9.344028, 0.050138000000175],
                [9.344584000000168, 0.049306],
                [9.34486, 0.047918000000152],
                [9.34486, 0.045140000000174],
                [9.34513800000019, 0.045140000000174],
                [9.34513800000019, 0.042361],
                [9.345416000000114, 0.040973000000179],
                [9.345694, 0.040973000000179],
                [9.345694, 0.038473000000124],
                [9.345972, 0.038473000000124],
                [9.345972, 0.036805000000129],
                [9.346528, 0.036527],
                [9.346806000000186, 0.035695],
                [9.346806000000186, 0.032917000000111],
                [9.346528, 0.032917000000111],
                [9.346528, 0.031251000000111],
                [9.346806000000186, 0.031251000000111],
                [9.346806000000186, 0.028750000000116],
                [9.346250000000168, 0.028472],
                [9.346806000000186, 0.02736],
                [9.346806000000186, 0.025694],
                [9.347082000000114, 0.025694],
                [9.347082000000114, 0.023472],
                [9.347362, 0.023472],
                [9.347362, 0.019306000000142],
                [9.347638, 0.019306000000142],
                [9.347638, 0.017640000000142],
                [9.347362, 0.017640000000142],
                [9.347362, 0.012361000000169],
                [9.347638, 0.012361000000169],
                [9.347638, -0.004305999999872],
                [9.347082000000114, -0.005416],
                [9.347082000000114, -0.010973],
                [9.346528, -0.011251],
                [9.346250000000168, -0.012361],
                [9.346250000000168, -0.015695],
                [9.345694, -0.016804999999863],
                [9.345694, -0.019861],
                [9.34513800000019, -0.020417],
                [9.34486, -0.021249],
                [9.34486, -0.022362],
                [9.344584000000168, -0.023472],
                [9.344028, -0.024306],
                [9.344028, -0.025416],
                [9.34347200000019, -0.02625],
                [9.34347200000019, -0.028749999999832],
                [9.34264, -0.029583999999829],
                [9.342084000000114, -0.031249999999829],
                [9.342084000000114, -0.034306999999899],
                [9.34180600000019, -0.035695],
                [9.341250000000173, -0.036804],
                [9.340694, -0.037361],
                [9.340694, -0.037916999999879],
                [9.339584000000173, -0.040138999999897],
                [9.339028, -0.040695],
                [9.33764, -0.040695],
                [9.336527, -0.040973],
                [9.335417, -0.041527],
                [9.335417, -0.041804999999897],
                [9.334305, -0.042361],
                [9.332083, -0.042916999999875],
                [9.330973, -0.043473],
                [9.330417, -0.043473],
                [9.330139000000145, -0.044027],
                [9.329583000000127, -0.044027],
                [9.329305, -0.044582999999875],
                [9.327639, -0.045416999999873],
                [9.327639, -0.048471],
                [9.32736100000011, -0.049583999999868],
                [9.32680500000015, -0.05014],
                [9.326251000000127, -0.052362],
                [9.326251000000127, -0.054862],
                [9.325973, -0.054862],
                [9.325973, -0.05875],
                [9.326527, -0.05986],
                [9.327083, -0.060138],
                [9.32680500000015, -0.060972],
                [9.32680500000015, -0.063751],
                [9.327083, -0.063751],
                [9.327639, -0.065417],
                [9.327639, -0.067083],
                [9.327297000000158, -0.06708],
                [9.32736100000011, -0.074860999999885],
                [9.327639, -0.074860999999885],
                [9.327639, -0.082916],
                [9.327917000000127, -0.082916],
                [9.327917000000127, -0.089859999999874],
                [9.328195, -0.089859999999874],
                [9.328195, -0.097639],
                [9.328473000000145, -0.097639],
                [9.328473000000145, -0.104028],
                [9.328749, -0.104028],
                [9.328749, -0.11097199999989],
                [9.329029000000105, -0.11097199999989],
                [9.329029000000105, -0.117361],
                [9.328749, -0.117361],
                [9.328749, -0.125970999999879],
                [9.328473000000145, -0.125970999999879],
                [9.328473000000145, -0.131528],
                [9.328195, -0.131528],
                [9.327917000000127, -0.132916],
                [9.327917000000127, -0.137638],
                [9.327639, -0.137638],
                [9.327639, -0.140694],
                [9.32736100000011, -0.140694],
                [9.32736100000011, -0.143750999999895],
                [9.327083, -0.143750999999895],
                [9.327083, -0.146805],
                [9.32680500000015, -0.146805],
                [9.32680500000015, -0.151526999999817],
                [9.326527, -0.152916999999889],
                [9.325973, -0.154026999999815],
                [9.325417, -0.155692999999815],
                [9.325417, -0.159028],
                [9.324861, -0.16014],
                [9.324582, -0.16014],
                [9.324582, -0.161806],
                [9.32402900000011, -0.162638],
                [9.32402900000011, -0.16375],
                [9.323194000000115, -0.166528],
                [9.322638000000154, -0.16736],
                [9.322638000000154, -0.168472],
                [9.322085, -0.169307],
                [9.322085, -0.170417],
                [9.32125, -0.172083],
                [9.32125, -0.173194999999851],
                [9.320138, -0.173194999999851],
                [9.319582, -0.173473],
                [9.318194000000176, -0.175973],
                [9.317916, -0.177078],
                [9.317916, -0.178195],
                [9.317084000000136, -0.181527],
                [9.316806, -0.181527],
                [9.316806, -0.183193],
                [9.316528000000176, -0.184584],
                [9.315972000000158, -0.185415],
                [9.315972000000158, -0.18680599999982],
                [9.315416, -0.187639999999874],
                [9.315138, -0.18875],
                [9.315138, -0.189861999999891],
                [9.314584, -0.190693999999894],
                [9.314584, -0.191805999999872],
                [9.314306000000158, -0.192916],
                [9.31375, -0.19375],
                [9.313472, -0.194859999999892],
                [9.313472, -0.19597199999987],
                [9.312918, -0.196807],
                [9.312084, -0.199583],
                [9.312084, -0.200695],
                [9.311806, -0.201527],
                [9.31014, -0.203195],
                [9.309027000000185, -0.204027],
                [9.307917, -0.204305],
                [9.306249, -0.204305],
                [9.305139000000167, -0.204861],
                [9.305139000000167, -0.206527],
                [9.304861, -0.206527],
                [9.304861, -0.209862],
                [9.304583, -0.209862],
                [9.304583, -0.215972],
                [9.30402700000019, -0.216806],
                [9.30402700000019, -0.219583999999827],
                [9.304305000000113, -0.219583999999827],
                [9.304305000000113, -0.222638],
                [9.30402700000019, -0.222638],
                [9.30402700000019, -0.225972999999897],
                [9.304305000000113, -0.225972999999897],
                [9.304305000000113, -0.230138999999895],
                [9.30402700000019, -0.230138999999895],
                [9.30402700000019, -0.232082999999875],
                [9.304305000000113, -0.232082999999875],
                [9.304305000000113, -0.238471],
                [9.30402700000019, -0.238471],
                [9.30402700000019, -0.249307],
                [9.303749, -0.249307],
                [9.303473000000167, -0.250694],
                [9.303473000000167, -0.25819499999983],
                [9.302917, -0.259026999999833],
                [9.302917, -0.260971],
                [9.303195, -0.260971],
                [9.303195, -0.264305],
                [9.302639000000113, -0.26486199999988],
                [9.302639000000113, -0.265972],
                [9.303473000000167, -0.266806],
                [9.303473000000167, -0.26819399999988],
                [9.302917, -0.26819399999988],
                [9.302917, -0.271527999999876],
                [9.302639000000113, -0.271527999999876],
                [9.302083, -0.272638],
                [9.302083, -0.27375],
                [9.30236100000019, -0.274859999999876],
                [9.301529, -0.275416],
                [9.301529, -0.275972],
                [9.300973000000113, -0.27625],
                [9.300973000000113, -0.280139],
                [9.301251, -0.280139],
                [9.301251, -0.282360999999867],
                [9.301529, -0.282360999999867],
                [9.301529, -0.289583],
                [9.301805, -0.289583],
                [9.301805, -0.294584],
                [9.302083, -0.294584],
                [9.302083, -0.29875],
                [9.30236100000019, -0.29875],
                [9.30236100000019, -0.30236],
                [9.302639000000113, -0.30236],
                [9.302639000000113, -0.306529],
                [9.302917, -0.306529],
                [9.302917, -0.325694],
                [9.302639000000113, -0.325694],
                [9.302639000000113, -0.32986],
                [9.30236100000019, -0.32986],
                [9.30236100000019, -0.333195],
                [9.302083, -0.333195],
                [9.302083, -0.336250999999891],
                [9.301805, -0.336250999999891],
                [9.301805, -0.337916999999891],
                [9.301529, -0.337916999999891],
                [9.301529, -0.341527],
                [9.301251, -0.341527],
                [9.301251, -0.343748999999889],
                [9.300973000000113, -0.343748999999889],
                [9.300973000000113, -0.345971],
                [9.30069500000019, -0.345971],
                [9.30069500000019, -0.348194],
                [9.300417, -0.348202999999899],
                [9.300417, -0.350415999999882],
                [9.300139, -0.350415999999882],
                [9.300139, -0.352638],
                [9.299861, -0.352638],
                [9.299861, -0.354306],
                [9.299583, -0.355694],
                [9.299027, -0.356806],
                [9.299027, -0.358194],
                [9.298751, -0.359582],
                [9.298195, -0.360695],
                [9.297917, -0.362083],
                [9.298195, -0.363473],
                [9.298195, -0.365694999999846],
                [9.297917, -0.365694999999846],
                [9.297917, -0.367917],
                [9.29736000000014, -0.369026999999846],
                [9.296804000000122, -0.371249],
                [9.296804000000122, -0.372918],
                [9.296251, -0.375416],
                [9.29569400000014, -0.37625],
                [9.295416, -0.377639999999815],
                [9.295416, -0.379859999999894],
                [9.29486, -0.380693999999892],
                [9.29486, -0.382359999999892],
                [9.294306, -0.383193999999889],
                [9.293472000000122, -0.386528999999882],
                [9.292638, -0.388473],
                [9.291806000000122, -0.391805],
                [9.291806000000122, -0.392917],
                [9.291528, -0.393749],
                [9.290972, -0.394305],
                [9.290972, -0.395139],
                [9.290416, -0.395695],
                [9.290140000000122, -0.397361],
                [9.289584000000104, -0.397918],
                [9.289584000000104, -0.398749],
                [9.289028000000144, -0.399305999999854],
                [9.289028000000144, -0.400139999999851],
                [9.288472000000127, -0.400694],
                [9.288194, -0.402362],
                [9.287638, -0.402916],
                [9.287638, -0.40375],
                [9.287084, -0.404306],
                [9.287084, -0.405137999999852],
                [9.286528, -0.405694],
                [9.286528, -0.406528],
                [9.285972, -0.407084],
                [9.285972, -0.407916],
                [9.285416, -0.408472],
                [9.285416, -0.409306],
                [9.284584000000109, -0.410416],
                [9.284584000000109, -0.41125],
                [9.284028000000148, -0.411806999999897],
                [9.284028000000148, -0.412638],
                [9.282918000000109, -0.414861],
                [9.282361, -0.415138999999897],
                [9.281527000000153, -0.416804999999897],
                [9.281249, -0.417916999999875],
                [9.280693, -0.418195],
                [9.280693, -0.419027],
                [9.280139, -0.419582999999875],
                [9.280139, -0.420416999999873],
                [9.278471, -0.422082999999873],
                [9.278471, -0.42291699999987],
                [9.277639, -0.423748999999873],
                [9.277083000000175, -0.424861],
                [9.276527000000158, -0.42514],
                [9.276249, -0.426248999999871],
                [9.274861000000158, -0.42764],
                [9.274305, -0.42764],
                [9.272917, -0.429306],
                [9.27208300000018, -0.429862],
                [9.27208300000018, -0.430416],
                [9.271529000000157, -0.430694],
                [9.271251, -0.431806],
                [9.270695000000103, -0.432084],
                [9.270695000000103, -0.432638],
                [9.270138, -0.432916],
                [9.270138, -0.433472],
                [9.269585, -0.434583999999859],
                [9.269029000000103, -0.435138],
                [9.269029000000103, -0.435694],
                [9.268472, -0.436249999999859],
                [9.265694, -0.441805],
                [9.265416, -0.442639],
                [9.265416, -0.443751],
                [9.264860000000112, -0.444305],
                [9.264860000000112, -0.445139],
                [9.264306, -0.445695],
                [9.264028, -0.446805],
                [9.263472, -0.447917],
                [9.263472, -0.448749],
                [9.26264, -0.449305],
                [9.26264, -0.450139],
                [9.262084, -0.451249],
                [9.261528000000112, -0.451526999999828],
                [9.261528000000112, -0.45264],
                [9.260694, -0.453192999999828],
                [9.260694, -0.45402799999988],
                [9.259862000000112, -0.45514],
                [9.259862000000112, -0.455972],
                [9.259306, -0.456249999999898],
                [9.259306, -0.457083999999895],
                [9.258472, -0.458193999999878],
                [9.25791500000014, -0.458472],
                [9.25791500000014, -0.459027999999876],
                [9.257084000000134, -0.459583999999893],
                [9.256249000000139, -0.461249999999893],
                [9.256249000000139, -0.462084],
                [9.255693000000122, -0.462359999999876],
                [9.255693000000122, -0.463472],
                [9.25514, -0.464306],
                [9.254583000000139, -0.464306],
                [9.253749, -0.465416],
                [9.253473, -0.466529],
                [9.252917000000139, -0.467082],
                [9.252917000000139, -0.467639],
                [9.252361000000121, -0.467917],
                [9.251527, -0.469583],
                [9.251527, -0.470139],
                [9.250971, -0.470417],
                [9.250695000000121, -0.471527],
                [9.250139000000104, -0.471805],
                [9.249861, -0.472917],
                [9.249305, -0.473194999999862],
                [9.249305, -0.473749],
                [9.248473000000104, -0.474305],
                [9.246805000000165, -0.476526999999862],
                [9.246805000000165, -0.477083],
                [9.245139000000165, -0.479305],
                [9.244029000000126, -0.48014],
                [9.243195, -0.48125],
                [9.243195, -0.482084],
                [9.24263800000017, -0.482362],
                [9.24263800000017, -0.482916],
                [9.241807000000165, -0.48375],
                [9.24097200000017, -0.48375],
                [9.240694, -0.484584],
                [9.240138, -0.484862],
                [9.23986200000013, -0.48625],
                [9.238194, -0.487916],
                [9.237084000000152, -0.489305999999885],
                [9.23625, -0.489305999999885],
                [9.235972000000174, -0.490137999999831],
                [9.234584, -0.491528],
                [9.234028, -0.491528],
                [9.233750000000157, -0.49236],
                [9.232640000000174, -0.492637999999886],
                [9.232640000000174, -0.493195],
                [9.231806, -0.494028999999898],
                [9.231250000000102, -0.494028999999898],
                [9.230972000000179, -0.494583],
                [9.229862, -0.495417],
                [9.228471000000184, -0.496804999999881],
                [9.227361, -0.497083],
                [9.227083000000107, -0.497638999999879],
                [9.226527, -0.497638999999879],
                [9.225695, -0.498472999999876],
                [9.224305000000129, -0.498472999999876],
                [9.223751000000107, -0.499026999999899],
                [9.223751000000107, -0.499583],
                [9.221805000000188, -0.500972999999874],
                [9.221805000000188, -0.501527],
                [9.220973000000129, -0.502361],
                [9.220139000000188, -0.502638999999874],
                [9.219027, -0.502638999999874],
                [9.218473000000188, -0.502917],
                [9.218195, -0.503749],
                [9.217639000000133, -0.503749],
                [9.217361, -0.504304999999874],
                [9.216804000000138, -0.504304999999874],
                [9.215695, -0.505418],
                [9.215416, -0.506527],
                [9.21486, -0.507084],
                [9.214028, -0.50875],
                [9.213194, -0.509028],
                [9.213194, -0.509584],
                [9.212082, -0.510694],
                [9.211528, -0.510694],
                [9.210416, -0.512084],
                [9.210416, -0.51264],
                [9.20958400000012, -0.513194],
                [9.20958400000012, -0.51375],
                [9.20875, -0.514028],
                [9.20875, -0.51486],
                [9.207916000000125, -0.515416],
                [9.207638, -0.515972],
                [9.207638, -0.51736],
                [9.20736200000016, -0.518194],
                [9.206806000000142, -0.518472],
                [9.205416, -0.521529],
                [9.205416, -0.522361],
                [9.204584000000125, -0.522639],
                [9.204028000000164, -0.523750999999891],
                [9.204028000000164, -0.527916999999889],
                [9.203196, -0.528748999999891],
                [9.202918000000125, -0.529582999999889],
                [9.202918000000125, -0.530695],
                [9.202084, -0.530971],
                [9.202084, -0.531805],
                [9.20152700000017, -0.532917999999881],
                [9.20068, -0.533743],
                [9.20041700000013, -0.53514],
                [9.199861000000169, -0.53514],
                [9.198471, -0.536528],
                [9.198257000000126, -0.537631],
                [9.197639000000152, -0.53764],
                [9.197083, -0.539306],
                [9.196513, -0.53957],
                [9.196249, -0.540694],
                [9.195695, -0.540972],
                [9.19375100000019, -0.542915999999877],
                [9.19375100000019, -0.543472],
                [9.192361, -0.544581999999878],
                [9.190417, -0.546804],
                [9.190417, -0.54736],
                [9.189029, -0.548751],
                [9.188473000000101, -0.548751],
                [9.187638000000106, -0.549861],
                [9.187082, -0.549861],
                [9.187082, -0.550695],
                [9.185972000000106, -0.551805],
                [9.185416, -0.552083],
                [9.185416, -0.552639],
                [9.184306000000106, -0.553749],
                [9.183194000000128, -0.554027],
                [9.182916, -0.554583],
                [9.180418, -0.557083],
                [9.179028, -0.557360999999844],
                [9.178472, -0.558194999999841],
                [9.177916, -0.558194999999841],
                [9.177084, -0.559305],
                [9.176528000000133, -0.559305],
                [9.175971, -0.56014],
                [9.174862000000132, -0.560418],
                [9.174027000000137, -0.561527999999896],
                [9.172361000000137, -0.562916],
                [9.171527, -0.563193999999896],
                [9.170695000000137, -0.562916],
                [9.17013900000012, -0.562361999999894],
                [9.167083, -0.562361999999894],
                [9.167083, -0.562084],
                [9.165139000000124, -0.562084],
                [9.164029000000141, -0.562361999999894],
                [9.163473000000124, -0.562916],
                [9.163473000000124, -0.563472],
                [9.162085, -0.564584],
                [9.161251000000163, -0.564584],
                [9.160694, -0.56514],
                [9.160694, -0.565693999999894],
                [9.159306000000186, -0.567084],
                [9.158194, -0.567916],
                [9.158194, -0.569305999999813],
                [9.159028, -0.569859999999892],
                [9.159028, -0.572916],
                [9.158750000000168, -0.572916],
                [9.158750000000168, -0.576805],
                [9.158194, -0.577360999999883],
                [9.158194, -0.57819499999988],
                [9.15763800000019, -0.578751],
                [9.15736, -0.580139],
                [9.15736, -0.582083],
                [9.156806, -0.582639],
                [9.15625, -0.582639],
                [9.15597200000019, -0.583471],
                [9.154584, -0.583471],
                [9.15430600000019, -0.584026999999878],
                [9.153750000000173, -0.584026999999878],
                [9.153194, -0.584583],
                [9.153194, -0.586249],
                [9.15264000000019, -0.586527],
                [9.152638, -0.58764],
                [9.152362, -0.588193],
                [9.151806, -0.588193],
                [9.151250000000118, -0.589584],
                [9.150696, -0.589862],
                [9.150696, -0.590694],
                [9.149584000000118, -0.591805999999849],
                [9.149306, -0.593471999999849],
                [9.148749000000123, -0.594028],
                [9.148749000000123, -0.595137999999849],
                [9.148193000000106, -0.595137999999849],
                [9.148193000000106, -0.596806],
                [9.14764, -0.597637999999847],
                [9.14764, -0.59875],
                [9.146805, -0.600416],
                [9.145971, -0.600416],
                [9.145139, -0.600972999999897],
                [9.145139, -0.603472999999894],
                [9.144583, -0.604304999999897],
                [9.144583, -0.607361],
                [9.143473, -0.608748999999818],
                [9.142917, -0.60875099999987],
                [9.142639, -0.609304999999893],
                [9.142083000000127, -0.609304999999893],
                [9.141249, -0.609861],
                [9.140973, -0.611248999999816],
                [9.140417000000127, -0.611527],
                [9.140417000000127, -0.612638999999888],
                [9.13930500000015, -0.61347099999989],
                [9.13930500000015, -0.614027],
                [9.137917, -0.615139999999883],
                [9.136805, -0.615139999999883],
                [9.136805, -0.615694],
                [9.135417000000132, -0.615971999999886],
                [9.135138000000154, -0.616528],
                [9.134307000000149, -0.616528],
                [9.133194, -0.61764],
                [9.132082, -0.617916],
                [9.131806000000154, -0.618471999999883],
                [9.131250000000136, -0.618471999999883],
                [9.130694000000119, -0.619306],
                [9.130694000000119, -0.620972],
                [9.130416, -0.621806],
                [9.129306, -0.622916],
                [9.12875, -0.623194],
                [9.127916000000141, -0.62486],
                [9.127638, -0.626529],
                [9.12569400000018, -0.626529],
                [9.12458400000014, -0.62736],
                [9.12458400000014, -0.627916999999854],
                [9.123472000000163, -0.628195],
                [9.122915, -0.629305],
                [9.122084, -0.629305],
                [9.120974, -0.629861],
                [9.119861000000185, -0.630973],
                [9.117083, -0.630973],
                [9.116249, -0.631251],
                [9.115973000000167, -0.631805],
                [9.11486100000019, -0.631805],
                [9.114305000000172, -0.632361],
                [9.113473000000113, -0.632361],
                [9.11319500000019, -0.632917],
                [9.112083, -0.632917],
                [9.111807000000113, -0.633473],
                [9.110695, -0.633751],
                [9.109304000000122, -0.633751],
                [9.107638000000122, -0.635417],
                [9.10736, -0.636249],
                [9.106528, -0.637361],
                [9.105138, -0.638749],
                [9.104584, -0.640417999999897],
                [9.104584, -0.641806],
                [9.105138, -0.643472],
                [9.105972000000122, -0.644583999999895],
                [9.106528, -0.644583999999895],
                [9.106807000000117, -0.645138],
                [9.107638000000122, -0.645415999999898],
                [9.107638000000122, -0.645972],
                [9.108195, -0.646249999999895],
                [9.107916, -0.647361999999873],
                [9.105972000000122, -0.647361999999873],
                [9.104028, -0.649306],
                [9.104028, -0.652359999999874],
                [9.104306000000122, -0.652359999999874],
                [9.104306000000122, -0.655417],
                [9.104584, -0.655417],
                [9.104584, -0.657083],
                [9.104860000000144, -0.657083],
                [9.104860000000144, -0.660973],
                [9.104584, -0.660973],
                [9.104584, -0.663473],
                [9.105138, -0.664305],
                [9.105138, -0.666249],
                [9.105694, -0.66764],
                [9.10625, -0.66764],
                [9.10625, -0.668471],
                [9.107082000000105, -0.669306],
                [9.107082000000105, -0.669862],
                [9.108195, -0.670694],
                [9.108473000000117, -0.671528],
                [9.110139000000117, -0.672362],
                [9.111807000000113, -0.672362],
                [9.111807000000113, -0.673194],
                [9.111251, -0.673472],
                [9.111251, -0.676528],
                [9.111807000000113, -0.676805999999829],
                [9.111807000000113, -0.67736],
                [9.112361, -0.677916],
                [9.112361, -0.680694],
                [9.112639000000172, -0.680694],
                [9.11319500000019, -0.681803999999829],
                [9.11319500000019, -0.682638999999881],
                [9.113751, -0.683751],
                [9.114305000000172, -0.684028999999896],
                [9.114305000000172, -0.684583],
                [9.11486100000019, -0.684860999999898],
                [9.11486100000019, -0.685417],
                [9.115417, -0.685694999999896],
                [9.115417, -0.686526999999899],
                [9.115973000000167, -0.686804999999879],
                [9.115973000000167, -0.687638999999876],
                [9.11652700000019, -0.688472999999874],
                [9.117361, -0.688749],
                [9.117361, -0.689583],
                [9.118195000000185, -0.690417],
                [9.118195000000185, -0.691249],
                [9.119305000000168, -0.693470999999874],
                [9.119305000000168, -0.69625],
                [9.118471000000113, -0.697916],
                [9.118471000000113, -0.698471999999867],
                [9.117917, -0.699028],
                [9.117083, -0.700694],
                [9.117083, -0.70125],
                [9.115695, -0.70125],
                [9.114583, -0.700971999999865],
                [9.114305000000172, -0.700416],
                [9.113473000000113, -0.700416],
                [9.112917, -0.699862],
                [9.111807000000113, -0.700416],
                [9.111251, -0.700416],
                [9.110139000000117, -0.701805999999863],
                [9.109861, -0.702916],
                [9.109029, -0.70375],
                [9.109029, -0.70486],
                [9.108473000000117, -0.70625],
                [9.106807000000117, -0.707916],
                [9.10625, -0.708751],
                [9.105694, -0.708751],
                [9.104860000000144, -0.709583],
                [9.104860000000144, -0.710139],
                [9.104028, -0.710695],
                [9.102916, -0.712083],
                [9.101806, -0.712639],
                [9.101806, -0.713195],
                [9.100138, -0.713750999999831],
                [9.09625, -0.713750999999831],
                [9.09625, -0.713473],
                [9.091805000000136, -0.713473],
                [9.091805000000136, -0.712639],
                [9.091251000000113, -0.712361],
                [9.091251000000113, -0.711805],
                [9.090417, -0.711805],
                [9.089305, -0.710973],
                [9.089305, -0.710417],
                [9.088749, -0.710139],
                [9.088749, -0.709583],
                [9.087917000000175, -0.708473],
                [9.087361000000158, -0.708473],
                [9.087083, -0.707916],
                [9.086527, -0.707916],
                [9.085417, -0.70736],
                [9.084029000000157, -0.70625],
                [9.083751, -0.705137999999863],
                [9.082639, -0.704584],
                [9.08125100000018, -0.704584],
                [9.080973, -0.704028],
                [9.079860000000167, -0.70375],
                [9.077084000000184, -0.70375],
                [9.075972, -0.704306],
                [9.075138, -0.705137999999863],
                [9.074584, -0.705137999999863],
                [9.074028000000112, -0.705694],
                [9.073472, -0.706807],
                [9.072918, -0.70736],
                [9.072918, -0.707916],
                [9.071806, -0.708751],
                [9.070972, -0.710417],
                [9.070972, -0.711250999999834],
                [9.070416, -0.711527],
                [9.070416, -0.712083],
                [9.069028000000117, -0.713195],
                [9.06875, -0.714305],
                [9.068193000000122, -0.714861],
                [9.06764, -0.714861],
                [9.067362000000116, -0.715973],
                [9.066805, -0.716250999999886],
                [9.065971000000104, -0.717361],
                [9.065695, -0.718195],
                [9.065139, -0.718195],
                [9.064583, -0.718748999999832],
                [9.064583, -0.719582999999886],
                [9.064027, -0.720139],
                [9.062917, -0.720696],
                [9.062639000000104, -0.721527],
                [9.062083000000143, -0.721527],
                [9.061805, -0.722362],
                [9.061249, -0.722639999999899],
                [9.061249, -0.723194],
                [9.059027, -0.725415999999882],
                [9.058195000000126, -0.726528],
                [9.057361, -0.726806],
                [9.055139, -0.729028],
                [9.054585, -0.729306],
                [9.054585, -0.730415999999877],
                [9.052363, -0.732083999999873],
                [9.052363, -0.732638],
                [9.051250000000152, -0.733749999999873],
                [9.050138000000175, -0.734581999999875],
                [9.050138000000175, -0.735695],
                [9.049028, -0.736529],
                [9.047916000000157, -0.737639],
                [9.047916000000157, -0.739305],
                [9.047362, -0.740417],
                [9.046806000000174, -0.740695],
                [9.046250000000157, -0.742361],
                [9.046250000000157, -0.744027],
                [9.045694, -0.745139],
                [9.045694, -0.750693999999839],
                [9.045416000000102, -0.751806],
                [9.044862, -0.75264],
                [9.044584000000157, -0.75375],
                [9.043750000000102, -0.755416],
                [9.043472000000179, -0.756527999999889],
                [9.04264, -0.757084],
                [9.041806000000179, -0.758193999999889],
                [9.041806000000179, -0.75875],
                [9.041249000000107, -0.759027999999887],
                [9.040971000000184, -0.760138],
                [9.040140000000179, -0.760416],
                [9.040140000000179, -0.760972],
                [9.039583000000107, -0.761251],
                [9.039861, -0.762359999999887],
                [9.039861, -0.76402899999988],
                [9.040418000000102, -0.764305],
                [9.040418000000102, -0.764860999999883],
                [9.040971000000184, -0.765417],
                [9.040974, -0.766805],
                [9.042084000000102, -0.767917],
                [9.042084000000102, -0.768751],
                [9.043472000000179, -0.76902699999988],
                [9.044306, -0.770139],
                [9.044862, -0.770417],
                [9.045972, -0.770417],
                [9.046806000000174, -0.770973],
                [9.046806000000174, -0.771526999999878],
                [9.047916000000157, -0.771805],
                [9.04875, -0.772917],
                [9.049306, -0.773192999999878],
                [9.050138000000175, -0.774305],
                [9.050694, -0.774305],
                [9.052363, -0.776528],
                [9.053194, -0.777084],
                [9.054307000000108, -0.777362],
                [9.054585, -0.777916],
                [9.055139, -0.777916],
                [9.055417000000148, -0.778472],
                [9.056529000000126, -0.779306],
                [9.057083000000148, -0.780694],
                [9.057083000000148, -0.782916],
                [9.056529000000126, -0.783472],
                [9.056529000000126, -0.784305999999845],
                [9.055973000000108, -0.784584],
                [9.055973000000108, -0.785694],
                [9.055417000000148, -0.78625],
                [9.05486, -0.78625],
                [9.054307000000108, -0.78736],
                [9.052638, -0.788751],
                [9.051528, -0.788751],
                [9.051250000000152, -0.789306999999894],
                [9.050416, -0.789306999999894],
                [9.049306, -0.790417],
                [9.048194, -0.790972999999894],
                [9.047638, -0.790972999999894],
                [9.047362, -0.791529],
                [9.045694, -0.793195],
                [9.044862, -0.793472999999892],
                [9.043196, -0.795417],
                [9.04264, -0.795695],
                [9.041806000000179, -0.796804999999893],
                [9.041528, -0.797916999999813],
                [9.040693, -0.798195],
                [9.040693, -0.798749],
                [9.040140000000179, -0.799027],
                [9.040140000000179, -0.799861],
                [9.039583000000107, -0.800138999999888],
                [9.039027, -0.801248999999814],
                [9.038749, -0.802914999999814],
                [9.037917000000107, -0.803471999999886],
                [9.037917000000107, -0.804305999999883],
                [9.037083, -0.804862],
                [9.036527, -0.805971999999883],
                [9.036527, -0.806805999999881],
                [9.035973000000183, -0.807084],
                [9.035973000000183, -0.807637999999884],
                [9.035417, -0.807916],
                [9.035417, -0.808471999999881],
                [9.034861, -0.809305999999879],
                [9.033749, -0.810137999999881],
                [9.033749, -0.810694],
                [9.032639000000188, -0.811528],
                [9.032639000000188, -0.812084],
                [9.032083, -0.81236],
                [9.032083, -0.812916],
                [9.031251000000111, -0.81375],
                [9.030417, -0.814028],
                [9.030417, -0.81486],
                [9.028473000000133, -0.816804],
                [9.028195, -0.817917],
                [9.027638000000138, -0.818195],
                [9.027638000000138, -0.81875099999985],
                [9.02708200000012, -0.819029],
                [9.02708200000012, -0.819583],
                [9.02625, -0.82041699999985],
                [9.02625, -0.821250999999847],
                [9.02541600000012, -0.821805],
                [9.025138, -0.822916999999848],
                [9.024028, -0.824027],
                [9.02375000000012, -0.824027],
                [9.02375000000012, -0.826248999999848],
                [9.023472, -0.826248999999848],
                [9.023472, -0.828471],
                [9.02375000000012, -0.828471],
                [9.02375000000012, -0.832915999999898],
                [9.024028, -0.832915999999898],
                [9.024028, -0.840138],
                [9.024306000000138, -0.840138],
                [9.024862, -0.841249999999889],
                [9.024862, -0.842081999999891],
                [9.02541600000012, -0.842359999999871],
                [9.02541600000012, -0.844582999999886],
                [9.025138, -0.844582999999886],
                [9.025138, -0.846527],
                [9.025694, -0.846805],
                [9.02625, -0.849027],
                [9.02625, -0.850695],
                [9.025972000000138, -0.851527],
                [9.02541600000012, -0.852083],
                [9.024862, -0.853749],
                [9.024862, -0.854583],
                [9.024306000000138, -0.85514],
                [9.024306000000138, -0.856249],
                [9.02375000000012, -0.857918],
                [9.023194000000103, -0.858472],
                [9.023194000000103, -0.859862],
                [9.022640000000138, -0.860972],
                [9.022640000000138, -0.863194],
                [9.021806, -0.865972],
                [9.020694, -0.867084],
                [9.020694, -0.869028999999898],
                [9.020416000000125, -0.869028999999898],
                [9.020416000000125, -0.872638999999822],
                [9.020138, -0.872638999999822],
                [9.020138, -0.875694999999894],
                [9.020416000000125, -0.875694999999894],
                [9.020416000000125, -0.877917],
                [9.020694, -0.879304999999874],
                [9.02125, -0.880138999999872],
                [9.02125, -0.881804999999872],
                [9.021528000000103, -0.881804999999872],
                [9.021528000000103, -0.886528],
                [9.020694, -0.886806],
                [9.019862000000103, -0.888194],
                [9.017916, -0.890138],
                [9.017916, -0.890416],
                [9.015972000000147, -0.89236],
                [9.015694, -0.892916],
                [9.01514, -0.892916],
                [9.014584, -0.893471999999861],
                [9.014306000000147, -0.894305999999858],
                [9.013749, -0.894305999999858],
                [9.013749, -0.895137999999861],
                [9.013193, -0.895416],
                [9.013193, -0.896529],
                [9.012639, -0.896807],
                [9.012639, -0.901805],
                [9.012361000000169, -0.902917],
                [9.011805000000152, -0.903195],
                [9.011805000000152, -0.903750999999829],
                [9.010971, -0.904583],
                [9.010971, -0.905416999999829],
                [9.010417, -0.906249],
                [9.010417, -0.907916999999827],
                [9.010139000000152, -0.907916999999827],
                [9.009861, -0.909305],
                [9.009861, -0.911528],
                [9.010139000000152, -0.911528],
                [9.010139000000152, -0.913471999999899],
                [9.010417, -0.913471999999899],
                [9.010417, -0.916249999999877],
                [9.010695000000169, -0.91736],
                [9.011249, -0.917637999999897],
                [9.011249, -0.919306],
                [9.010971, -0.919306],
                [9.010971, -0.921807],
                [9.010695000000169, -0.921807],
                [9.010695000000169, -0.925973],
                [9.010971, -0.925973],
                [9.010971, -0.928750999999863],
                [9.011527, -0.929582999999866],
                [9.011527, -0.931248999999866],
                [9.011805000000152, -0.931248999999866],
                [9.011805000000152, -0.932917],
                [9.012361000000169, -0.933473],
                [9.012639, -0.934861],
                [9.014584, -0.936805],
                [9.014584, -0.937362],
                [9.01514, -0.93764],
                [9.015418000000125, -0.93875],
                [9.015972000000147, -0.939028],
                [9.016806, -0.940694],
                [9.016806, -0.94125],
                [9.01736, -0.941527999999835],
                [9.017916, -0.942638],
                [9.017916, -0.94375],
                [9.018472, -0.944027999999832],
                [9.018472, -0.945138],
                [9.019306000000142, -0.947082],
                [9.019862000000103, -0.947638],
                [9.020138, -0.94875],
                [9.020694, -0.949028999999882],
                [9.02125, -0.950138],
                [9.02125, -0.950694999999882],
                [9.02208400000012, -0.950973],
                [9.02208400000012, -0.95152899999988],
                [9.022916, -0.951804],
                [9.023194000000103, -0.952917],
                [9.02375000000012, -0.952917],
                [9.024582, -0.953751],
                [9.024862, -0.954583],
                [9.02541600000012, -0.954583],
                [9.025694, -0.955139],
                [9.025694, -0.956251],
                [9.02625, -0.956805],
                [9.02625, -0.957360999999878],
                [9.026807000000133, -0.957639],
                [9.02736, -0.959026999999878],
                [9.02736, -0.959860999999876],
                [9.027917000000116, -0.960417],
                [9.028751, -0.960417],
                [9.030139000000133, -0.961805],
                [9.030139000000133, -0.962360999999873],
                [9.031527, -0.962639],
                [9.031527, -0.963195999999868],
                [9.032361, -0.963471],
                [9.032361, -0.964306],
                [9.033195, -0.964861999999869],
                [9.033195, -0.965693999999871],
                [9.034029, -0.965972],
                [9.034029, -0.966527999999869],
                [9.034583000000111, -0.967084],
                [9.034861, -0.967916],
                [9.035973000000183, -0.969306],
                [9.037361, -0.969862],
                [9.038749, -0.969862],
                [9.039305000000184, -0.970416],
                [9.040693, -0.970416],
                [9.041806000000179, -0.970972],
                [9.043196, -0.970972],
                [9.044584000000157, -0.97236],
                [9.045416000000102, -0.97236],
                [9.045416000000102, -0.972916],
                [9.045972, -0.973194],
                [9.045972, -0.980695],
                [9.045694, -0.980695],
                [9.045694, -0.982638999999892],
                [9.046250000000157, -0.982917],
                [9.046528, -0.984027],
                [9.046528, -0.985972999999888],
                [9.047082000000103, -0.986249],
                [9.047082000000103, -0.98680499999989],
                [9.047916000000157, -0.987083],
                [9.048472000000174, -0.988195],
                [9.048472000000174, -0.989027],
                [9.049028, -0.989583],
                [9.049028, -0.990418],
                [9.049584000000152, -0.990970999999888],
                [9.049584000000152, -0.992362],
                [9.04986, -0.993471999999883],
                [9.050694, -0.995694],
                [9.051250000000152, -0.995971999999881],
                [9.052638, -0.999028],
                [9.053194, -0.999028],
                [9.053751000000148, -0.99986],
                [9.053751000000148, -1.000416],
                [9.055695, -1.000416],
                [9.055695, -1.000694],
                [9.056805, -1.00125],
                [9.060139, -1.00125],
                [9.060139, -1.001528],
                [9.063195000000121, -1.001528],
                [9.063751000000138, -1.00236],
                [9.064861000000121, -1.002637999999877],
                [9.065417000000139, -1.003471999999874],
                [9.065695, -1.005139],
                [9.066527000000121, -1.005695],
                [9.066527000000121, -1.007083],
                [9.066805, -1.007917],
                [9.067362000000116, -1.008195],
                [9.06764, -1.009305],
                [9.06764, -1.017362],
                [9.067918, -1.017362],
                [9.067918, -1.019028],
                [9.06875, -1.019862],
                [9.069306, -1.019862],
                [9.070416, -1.021249999999895],
                [9.071528, -1.021806],
                [9.072362000000112, -1.02264],
                [9.072918, -1.02264],
                [9.073194, -1.023194],
                [9.074028000000112, -1.023194],
                [9.074028000000112, -1.023749999999893],
                [9.075416000000189, -1.024028],
                [9.075972, -1.024861999999814],
                [9.077084000000184, -1.025138],
                [9.077916, -1.025972],
                [9.078750000000184, -1.025972],
                [9.079028000000108, -1.026527999999814],
                [9.080694000000108, -1.02736],
                [9.081804, -1.027638],
                [9.08291700000018, -1.027638],
                [9.082639, -1.028193999999814],
                [9.082639, -1.029306],
                [9.082085, -1.030138],
                [9.082085, -1.031250999999884],
                [9.081804, -1.031807],
                [9.080694000000108, -1.031807],
                [9.080416000000184, -1.032361],
                [9.079860000000167, -1.032361],
                [9.079582, -1.033473],
                [9.079028000000108, -1.033473],
                [9.077916, -1.034861],
                [9.077360000000112, -1.034861],
                [9.077084000000184, -1.035416999999882],
                [9.075972, -1.035973],
                [9.074306, -1.036248999999884],
                [9.074028000000112, -1.036805],
                [9.06764, -1.036805],
                [9.067637000000104, -1.036527],
                [9.065971000000104, -1.036527],
                [9.065971000000104, -1.036248999999884],
                [9.062917, -1.036248999999884],
                [9.062917, -1.035973],
                [9.060417000000143, -1.035973],
                [9.060139, -1.036527],
                [9.059027, -1.036527],
                [9.058751000000143, -1.037082999999882],
                [9.058195000000126, -1.037082999999882],
                [9.057639000000108, -1.037639],
                [9.057639000000108, -1.038195],
                [9.057083000000148, -1.038473],
                [9.056805, -1.03958299999988],
                [9.056251, -1.040695],
                [9.055695, -1.040971],
                [9.055695, -1.041527],
                [9.05486, -1.043196],
                [9.054307000000108, -1.043471],
                [9.053751000000148, -1.04514],
                [9.053194, -1.04514],
                [9.052916000000153, -1.04625],
                [9.05180600000017, -1.047084],
                [9.052082, -1.048194],
                [9.05486, -1.049306],
                [9.055695, -1.049306],
                [9.055973000000108, -1.050138],
                [9.057083000000148, -1.050138],
                [9.057917, -1.050416],
                [9.058195000000126, -1.050972],
                [9.059583, -1.051527999999848],
                [9.060973000000104, -1.051527999999848],
                [9.060973000000104, -1.052084],
                [9.062083000000143, -1.052362],
                [9.062361, -1.052916],
                [9.064305000000104, -1.05375],
                [9.064861000000121, -1.054306],
                [9.065695, -1.054306],
                [9.065971000000104, -1.054859999999849],
                [9.066527000000121, -1.054859999999849],
                [9.066805, -1.055416],
                [9.067918, -1.05625],
                [9.068471, -1.05625],
                [9.07014, -1.057916],
                [9.070972, -1.058473],
                [9.070694000000117, -1.059583],
                [9.07014, -1.059860999999899],
                [9.07014, -1.060417],
                [9.069584, -1.060694999999896],
                [9.069306, -1.061805],
                [9.06875, -1.062083],
                [9.06875, -1.06263899999982],
                [9.068193000000122, -1.062917],
                [9.068193000000122, -1.063472999999817],
                [9.066527000000121, -1.062917],
                [9.062639000000104, -1.062917],
                [9.062639000000104, -1.06263899999982],
                [9.060973000000104, -1.06263899999982],
                [9.060973000000104, -1.062917],
                [9.059861000000126, -1.063472999999817],
                [9.059305000000109, -1.063472999999817],
                [9.059027, -1.064583],
                [9.058195000000126, -1.065138999999817],
                [9.058195000000126, -1.067083],
                [9.058473, -1.067083],
                [9.058473, -1.068749],
                [9.059027, -1.069583],
                [9.058473, -1.071805999999867],
                [9.057917, -1.072918],
                [9.057361, -1.073471999999867],
                [9.057361, -1.074306],
                [9.056805, -1.074862],
                [9.056805, -1.078472],
                [9.057639000000108, -1.07875],
                [9.057361, -1.079584],
                [9.056529000000126, -1.079584],
                [9.055973000000108, -1.080416],
                [9.055417000000148, -1.080416],
                [9.055417000000148, -1.08125],
                [9.054307000000108, -1.082916],
                [9.053751000000148, -1.083194],
                [9.053751000000148, -1.084306999999853],
                [9.052916000000153, -1.08486],
                [9.052916000000153, -1.085972999999854],
                [9.05180600000017, -1.087083],
                [9.05180600000017, -1.087917],
                [9.050972, -1.088473],
                [9.050694, -1.089583],
                [9.049584000000152, -1.090695],
                [9.049584000000152, -1.092083],
                [9.049028, -1.092639],
                [9.049028, -1.093749],
                [9.048472000000174, -1.094027],
                [9.048472000000174, -1.094583],
                [9.047916000000157, -1.095695],
                [9.046250000000157, -1.097083],
                [9.045694, -1.097083],
                [9.044584000000157, -1.097917999999822],
                [9.041528, -1.097917999999822],
                [9.040418000000102, -1.097361],
                [9.040140000000179, -1.096805],
                [9.039583000000107, -1.096805],
                [9.038749, -1.095971],
                [9.037917000000107, -1.095971],
                [9.036805, -1.095139],
                [9.034861, -1.094305],
                [9.034583000000111, -1.093749],
                [9.034029, -1.093749],
                [9.033749, -1.093195],
                [9.032639000000188, -1.092639],
                [9.031527, -1.092639],
                [9.031251000000111, -1.092083],
                [9.030695, -1.092083],
                [9.030139000000133, -1.091527],
                [9.029305, -1.091527],
                [9.028473000000133, -1.090695],
                [9.02736, -1.090417],
                [9.021528000000103, -1.090417],
                [9.021528000000103, -1.090695],
                [9.019862000000103, -1.090695],
                [9.019306000000142, -1.091249],
                [9.017084000000125, -1.091251],
                [9.01514, -1.092083],
                [9.013474, -1.092083],
                [9.012361000000169, -1.092639],
                [9.010139000000152, -1.092639],
                [9.010139000000152, -1.092917],
                [9.006251000000134, -1.092917],
                [9.006251000000134, -1.093195],
                [9.003195, -1.093195],
                [9.003195, -1.093473],
                [8.997916, -1.093473],
                [8.997916, -1.093749],
                [8.992918, -1.093749],
                [8.99180600000011, -1.093473],
                [8.989584, -1.092361],
                [8.989306, -1.091527],
                [8.98875, -1.091249],
                [8.988472000000115, -1.090139],
                [8.987918, -1.089861],
                [8.987362000000132, -1.088473],
                [8.987362000000132, -1.087083],
                [8.987084, -1.085695],
                [8.986527000000137, -1.084306999999853],
                [8.985696000000132, -1.083472],
                [8.98430500000012, -1.082638],
                [8.983471, -1.081806],
                [8.975695, -1.081806],
                [8.974861000000146, -1.081528],
                [8.974304, -1.080972],
                [8.972916000000168, -1.080694],
                [8.972082, -1.07986],
                [8.972082, -1.079306],
                [8.971528, -1.079028],
                [8.970972, -1.077916],
                [8.970972, -1.077084],
                [8.970416, -1.076528],
                [8.970416, -1.075693999999885],
                [8.96986, -1.07514],
                [8.969584000000168, -1.07375],
                [8.969584000000168, -1.07152699999989],
                [8.969306, -1.07152699999989],
                [8.969306, -1.069304999999872],
                [8.96902800000015, -1.069304999999872],
                [8.96902800000015, -1.067638999999872],
                [8.969584000000168, -1.065694999999892],
                [8.971250000000168, -1.064026999999896],
                [8.971250000000168, -1.063472999999817],
                [8.972360000000151, -1.06263899999982],
                [8.972363, -1.062083],
                [8.973473, -1.061251],
                [8.974861000000146, -1.059860999999899],
                [8.974861000000146, -1.059304],
                [8.976805, -1.05736],
                [8.977361, -1.055694],
                [8.977361, -1.054028],
                [8.977639000000124, -1.053193999999849],
                [8.976805, -1.05125],
                [8.976251, -1.050972],
                [8.976251, -1.050416],
                [8.974861000000146, -1.049306],
                [8.974861000000146, -1.04875],
                [8.973473, -1.04764],
                [8.972916000000168, -1.046528],
                [8.971528, -1.04625],
                [8.970416, -1.045418],
                [8.96847200000019, -1.045418],
                [8.96847200000019, -1.04514],
                [8.96680600000019, -1.04514],
                [8.96680600000019, -1.044862],
                [8.964862, -1.044862],
                [8.964584000000173, -1.044305],
                [8.964028, -1.044305],
                [8.963196, -1.043196],
                [8.96264, -1.042917],
                [8.961250000000177, -1.041527],
                [8.960693000000106, -1.041527],
                [8.959027000000106, -1.039861],
                [8.958471, -1.03958299999988],
                [8.957083000000182, -1.038195],
                [8.956249000000128, -1.037916999999879],
                [8.954305, -1.037916999999879],
                [8.954305, -1.037639],
                [8.952639, -1.037639],
                [8.952639, -1.037361],
                [8.94902900000011, -1.037361],
                [8.948195, -1.037916999999879],
                [8.94736, -1.037916999999879],
                [8.945412000000147, -1.038698999999895],
                [8.944582, -1.03958299999988],
                [8.943472, -1.03958299999988],
                [8.94236, -1.040695],
                [8.94125, -1.04124899999988],
                [8.940138, -1.042361],
                [8.939584, -1.042639],
                [8.938472, -1.042639],
                [8.937362, -1.042917],
                [8.93708400000014, -1.043471],
                [8.935972000000163, -1.044027],
                [8.935416000000146, -1.044027],
                [8.93514, -1.045418],
                [8.933749, -1.045972],
                [8.931527, -1.045972],
                [8.930417, -1.045693],
                [8.929861, -1.04625],
                [8.928193, -1.046806],
                [8.926527, -1.04764],
                [8.923195, -1.048472],
                [8.921805000000177, -1.048472],
                [8.920695, -1.04875],
                [8.91986, -1.049306],
                [8.916528, -1.050972],
                [8.915694, -1.050972],
                [8.915140000000122, -1.051527999999848],
                [8.914306, -1.051527999999848],
                [8.91375, -1.052084],
                [8.912918000000104, -1.052084],
                [8.912084, -1.052916],
                [8.899027, -1.052916],
                [8.896805, -1.051806],
                [8.896527000000162, -1.05125],
                [8.89597300000014, -1.05125],
                [8.895139, -1.050416],
                [8.894861000000162, -1.049584],
                [8.89375100000018, -1.048472],
                [8.892638, -1.048472],
                [8.892360000000167, -1.047916],
                [8.891807, -1.047916],
                [8.891528, -1.047361999999851],
                [8.890972, -1.047361999999851],
                [8.88875, -1.04625],
                [8.887916000000189, -1.045972],
                [8.887362000000167, -1.045418],
                [8.886806, -1.045418],
                [8.886528000000112, -1.044862],
                [8.885694000000171, -1.044584],
                [8.885694000000171, -1.044027],
                [8.882362000000171, -1.039861],
                [8.879584, -1.034305],
                [8.878471000000104, -1.033195],
                [8.877915000000144, -1.033195],
                [8.875139000000104, -1.030415999999889],
                [8.874583000000143, -1.030138],
                [8.872083, -1.027638],
                [8.871527, -1.02736],
                [8.869029000000126, -1.024861999999814],
                [8.868473000000108, -1.024583999999891],
                [8.865138, -1.021249999999895],
                [8.864582, -1.020972],
                [8.86236, -1.018749999999898],
                [8.86236, -1.018193],
                [8.861806, -1.017917999999895],
                [8.861806, -1.017362],
                [8.86125, -1.017082999999843],
                [8.86125, -1.016527],
                [8.859862000000135, -1.015416999999843],
                [8.859028, -1.014305],
                [8.858750000000157, -1.013195],
                [8.858194, -1.012916999999845],
                [8.857916, -1.011805],
                [8.857362, -1.011527],
                [8.857362, -1.010973],
                [8.856806, -1.010695],
                [8.856528, -1.009583],
                [8.85514, -1.008473],
                [8.854584, -1.007361],
                [8.854584, -1.006805],
                [8.852918, -1.005417],
                [8.852918, -1.00486],
                [8.851527, -1.003751],
                [8.850695, -1.002916],
                [8.850139000000183, -1.001528],
                [8.850139000000183, -1.000694],
                [8.849305, -1.000416],
                [8.849305, -0.999584],
                [8.848473000000183, -0.99875],
                [8.848473000000183, -0.997637999999881],
                [8.847917000000166, -0.997637999999881],
                [8.847639, -0.997084],
                [8.846527, -0.996805999999879],
                [8.846527, -0.995694],
                [8.846249, -0.994584],
                [8.845139000000188, -0.992639999999881],
                [8.844861, -0.991528],
                [8.844861, -0.990139999999883],
                [8.844307, -0.989861],
                [8.844307, -0.987361],
                [8.844027, -0.987361],
                [8.844027, -0.98513899999989],
                [8.843473000000188, -0.984583],
                [8.843195, -0.98347299999989],
                [8.843195, -0.982361],
                [8.842639, -0.982638999999892],
                [8.842639, -0.98513899999989],
                [8.842917, -0.98513899999989],
                [8.842917, -0.987083],
                [8.843195, -0.987083],
                [8.843195, -0.989304999999888],
                [8.843751000000111, -0.989861],
                [8.843751000000111, -0.991805999999883],
                [8.844027, -0.991805999999883],
                [8.844027, -0.993471999999883],
                [8.844583, -0.994028],
                [8.844583, -0.995416],
                [8.845139000000188, -0.995694],
                [8.845139000000188, -0.996528],
                [8.845973, -0.997362],
                [8.846527, -0.999028],
                [8.847083000000111, -0.999305999999876],
                [8.847083000000111, -1.000416],
                [8.847639, -1.001528],
                [8.848193, -1.001805999999874],
                [8.848193, -1.00236],
                [8.848749000000112, -1.002637999999877],
                [8.849027, -1.003751],
                [8.850695, -1.005417],
                [8.850695, -1.005973],
                [8.852083000000107, -1.007083],
                [8.852918, -1.008195],
                [8.852918, -1.008751],
                [8.853750000000161, -1.009305],
                [8.853750000000161, -1.009861],
                [8.854306000000179, -1.010973],
                [8.854862, -1.011249],
                [8.85514, -1.012361],
                [8.857084000000157, -1.014305],
                [8.857362, -1.014861],
                [8.858472, -1.015695],
                [8.859862000000135, -1.017082999999843],
                [8.859862000000135, -1.01764],
                [8.860995000000116, -1.018418999999881],
                [8.860972000000174, -1.019028],
                [8.862084000000152, -1.019862],
                [8.862084000000152, -1.020415999999898],
                [8.862916, -1.021528],
                [8.864028, -1.022362],
                [8.864028, -1.022915999999896],
                [8.865694, -1.024583999999891],
                [8.866529, -1.025138],
                [8.867082000000153, -1.025972],
                [8.867917000000148, -1.026527999999814],
                [8.867917000000148, -1.027083999999888],
                [8.869305, -1.028472],
                [8.869583000000148, -1.028472],
                [8.873195, -1.032081999999889],
                [8.873749, -1.032916999999884],
                [8.875695000000121, -1.034861],
                [8.875695000000121, -1.035416999999882],
                [8.877083, -1.036527],
                [8.877083, -1.037082999999882],
                [8.878471000000104, -1.038195],
                [8.878471000000104, -1.038748999999882],
                [8.879862000000116, -1.039861],
                [8.879862000000116, -1.040417],
                [8.88125, -1.041527],
                [8.881528000000117, -1.042083],
                [8.882916, -1.043471],
                [8.882916, -1.044027],
                [8.884306, -1.04514],
                [8.884306, -1.045693],
                [8.885972, -1.047361999999851],
                [8.886806, -1.047916],
                [8.887084, -1.048472],
                [8.887638, -1.048472],
                [8.889860000000112, -1.050693999999851],
                [8.890138, -1.051806],
                [8.890694000000167, -1.052084],
                [8.890972, -1.053193999999849],
                [8.891528, -1.053472],
                [8.891807, -1.054584],
                [8.892360000000167, -1.054859999999849],
                [8.892638, -1.055972],
                [8.893195000000162, -1.05625],
                [8.893195000000162, -1.056807],
                [8.89375100000018, -1.057082],
                [8.894029, -1.058194999999898],
                [8.894585, -1.058473],
                [8.894585, -1.059028999999896],
                [8.895139, -1.059304],
                [8.89541700000018, -1.060417],
                [8.89597300000014, -1.060694999999896],
                [8.89597300000014, -1.061251],
                [8.896529000000157, -1.061526999999899],
                [8.896805, -1.06263899999982],
                [8.897361, -1.062917],
                [8.897361, -1.063472999999817],
                [8.897917, -1.063751],
                [8.897917, -1.06430499999982],
                [8.898751000000118, -1.065417],
                [8.899583, -1.06597099999982],
                [8.899583, -1.066526999999894],
                [8.900417000000118, -1.067083],
                [8.902917, -1.070418],
                [8.902917, -1.070970999999872],
                [8.903751000000113, -1.072084],
                [8.904583, -1.07264],
                [8.904583, -1.073193999999887],
                [8.905418, -1.074306],
                [8.905971000000136, -1.074584],
                [8.905971000000136, -1.075137999999868],
                [8.906527000000153, -1.075416],
                [8.907362000000148, -1.076528],
                [8.907362000000148, -1.077084],
                [8.907918000000109, -1.077362],
                [8.907918000000109, -1.077916],
                [8.908472000000131, -1.078194],
                [8.909306, -1.079306],
                [8.909306, -1.07986],
                [8.909862, -1.080138],
                [8.909862, -1.080694],
                [8.910416, -1.080972],
                [8.91125000000011, -1.082084],
                [8.91125000000011, -1.082638],
                [8.911806000000126, -1.082916],
                [8.912638, -1.084029],
                [8.912638, -1.084582],
                [8.913472000000127, -1.085695],
                [8.914306, -1.086251],
                [8.914306, -1.086804999999856],
                [8.915140000000122, -1.087917],
                [8.915972, -1.088473],
                [8.916806000000122, -1.089583],
                [8.916806000000122, -1.090139],
                [8.918472000000122, -1.091805],
                [8.918472000000122, -1.092361],
                [8.919026, -1.092639],
                [8.919307000000117, -1.093749],
                [8.91986, -1.094027],
                [8.91986, -1.094583],
                [8.9204170000001, -1.094861],
                [8.9204170000001, -1.095417],
                [8.920973000000117, -1.095695],
                [8.921251, -1.096805],
                [8.921805000000177, -1.097083],
                [8.921805000000177, -1.097639999999899],
                [8.922361, -1.097917999999822],
                [8.922361, -1.098471],
                [8.922917, -1.098749],
                [8.923195, -1.099862],
                [8.923751, -1.100139999999897],
                [8.923751, -1.100694],
                [8.924307, -1.100971999999899],
                [8.924307, -1.101528],
                [8.924861, -1.101805999999897],
                [8.924861, -1.102362],
                [8.925417, -1.102639999999894],
                [8.925417, -1.103194],
                [8.925973, -1.103471999999897],
                [8.925973, -1.104028],
                [8.926527, -1.104305999999895],
                [8.926527, -1.104862],
                [8.927083, -1.105137999999897],
                [8.927083, -1.105694],
                [8.927639, -1.105971999999895],
                [8.927639, -1.106528],
                [8.928193, -1.106805999999892],
                [8.928193, -1.107362],
                [8.928749, -1.107637999999895],
                [8.928749, -1.108194],
                [8.929305, -1.108471999999892],
                [8.929305, -1.109028],
                [8.929861, -1.10930599999989],
                [8.929861, -1.10986],
                [8.930417, -1.110137999999893],
                [8.930417, -1.110694],
                [8.930971, -1.11097199999989],
                [8.930971, -1.111529],
                [8.931527, -1.111803999999893],
                [8.931527, -1.11236],
                [8.932083, -1.112639],
                [8.932083, -1.113195],
                [8.932640000000163, -1.113473],
                [8.932640000000163, -1.114029],
                [8.933193, -1.114305],
                [8.933474, -1.115417],
                [8.934028, -1.115695],
                [8.934028, -1.116251],
                [8.934584, -1.116527],
                [8.934584, -1.117083],
                [8.93514, -1.117361],
                [8.93514, -1.117917],
                [8.935694, -1.118195],
                [8.935694, -1.118749],
                [8.93625, -1.119027],
                [8.93708400000014, -1.120139],
                [8.93708400000014, -1.120695],
                [8.937916, -1.121249],
                [8.937916, -1.121805],
                [8.938750000000141, -1.122916999999859],
                [8.939306000000158, -1.123195],
                [8.939306000000158, -1.123748999999862],
                [8.939862000000119, -1.124027],
                [8.939862000000119, -1.124582999999859],
                [8.940416000000141, -1.124862],
                [8.94125, -1.125971],
                [8.94125, -1.126528],
                [8.941806, -1.126806],
                [8.941806, -1.127362],
                [8.94236, -1.12764],
                [8.94236, -1.128194],
                [8.942916, -1.128472],
                [8.942916, -1.129028],
                [8.943472, -1.129306],
                [8.943472, -1.129862],
                [8.944028, -1.13014],
                [8.944028, -1.130694],
                [8.944582, -1.130972],
                [8.944582, -1.131528],
                [8.945138, -1.131806],
                [8.946807, -1.13402799999983],
                [8.946807, -1.134584],
                [8.948473, -1.13625],
                [8.948473, -1.136806],
                [8.94902900000011, -1.137082],
                [8.94902900000011, -1.137638],
                [8.949583000000132, -1.137916],
                [8.949583000000132, -1.138473],
                [8.950139, -1.138751],
                [8.950139, -1.139307],
                [8.95069500000011, -1.139582],
                [8.95069500000011, -1.140139],
                [8.951251000000127, -1.140417],
                [8.951251000000127, -1.140973],
                [8.951805, -1.141250999999897],
                [8.951805, -1.141805],
                [8.95236100000011, -1.142083],
                [8.95236100000011, -1.142639],
                [8.952917000000127, -1.142916999999898],
                [8.952917000000127, -1.143473],
                [8.953473, -1.143750999999895],
                [8.954029000000105, -1.145416999999895],
                [8.954583000000127, -1.145694999999876],
                [8.954861, -1.146805],
                [8.956249000000128, -1.148194999999873],
                [8.956805, -1.149026999999876],
                [8.957083000000182, -1.150139],
                [8.957639, -1.150417],
                [8.957639, -1.150971],
                [8.959027000000106, -1.152918],
                [8.959583000000123, -1.153192999999874],
                [8.959583000000123, -1.15375],
                [8.96014, -1.154818],
                [8.960693000000106, -1.155416],
                [8.960693000000106, -1.155972],
                [8.961250000000177, -1.15625],
                [8.961250000000177, -1.156806],
                [8.961806, -1.157084],
                [8.962084, -1.158193999999867],
                [8.96264, -1.158472],
                [8.96264, -1.159027999999864],
                [8.963196, -1.159306],
                [8.963472, -1.160416],
                [8.964028, -1.160693999999864],
                [8.964028, -1.16125],
                [8.964584000000173, -1.161528],
                [8.964584000000173, -1.162084],
                [8.965138, -1.162359999999865],
                [8.96514000000019, -1.162916],
                [8.965694, -1.163194],
                [8.965694, -1.16375],
                [8.966250000000173, -1.164028],
                [8.966250000000173, -1.164582],
                [8.96680600000019, -1.16486],
                [8.967084, -1.165973],
                [8.967638, -1.167083],
                [8.968194, -1.167361],
                [8.968194, -1.167917],
                [8.96902800000015, -1.169583],
                [8.969584000000168, -1.169861],
                [8.96986, -1.170972999999833],
                [8.970416, -1.171527],
                [8.970694000000151, -1.172638999999833],
                [8.971250000000168, -1.172917],
                [8.971806000000129, -1.174027],
                [8.972082, -1.175138999999888],
                [8.972638, -1.175417],
                [8.972916000000168, -1.176527],
                [8.973473, -1.177638999999886],
                [8.974029, -1.177918],
                [8.974585, -1.179027],
                [8.974585, -1.179584],
                [8.975139, -1.180139999999881],
                [8.976529000000141, -1.182916],
                [8.976529000000141, -1.183471999999881],
                [8.977083000000164, -1.18375],
                [8.98097300000012, -1.191529],
                [8.98097300000012, -1.19236],
                [8.981805, -1.194029],
                [8.98208300000016, -1.194029],
                [8.98263900000012, -1.19513899999987],
                [8.98263900000012, -1.195973],
                [8.983195000000137, -1.196527],
                [8.983471, -1.197639],
                [8.984027, -1.198195],
                [8.98430500000012, -1.199027],
                [8.98430500000012, -1.200139],
                [8.984861000000137, -1.200971],
                [8.984861000000137, -1.202083],
                [8.985417, -1.202917],
                [8.986249, -1.205696],
                [8.986249, -1.206806],
                [8.986805, -1.20764],
                [8.987084, -1.208749999999839],
                [8.987084, -1.212362],
                [8.98763700000012, -1.212638],
                [8.98763700000012, -1.214306],
                [8.987362000000132, -1.214306],
                [8.987362000000132, -1.217081999999891],
                [8.987918, -1.217638],
                [8.988193000000138, -1.218472],
                [8.988193000000138, -1.220695],
                [8.987918, -1.220695],
                [8.987918, -1.222361],
                [8.988472000000115, -1.222361],
                [8.989028000000133, -1.221529],
                [8.989028000000133, -1.220695],
                [8.989862000000187, -1.218750999999884],
                [8.990416, -1.218194],
                [8.990416, -1.215415999999891],
                [8.990694000000133, -1.214583999999888],
                [8.99180600000011, -1.216806],
                [8.992084, -1.218194],
                [8.992084, -1.220416999999884],
                [8.99180600000011, -1.220416999999884],
                [8.99180600000011, -1.225139],
                [8.992362000000128, -1.225139],
                [8.992638, -1.224582999999882],
                [8.992638, -1.219581999999889],
                [8.992362000000128, -1.218472],
                [8.99347200000011, -1.218194],
                [8.99375, -1.218472],
                [8.99375, -1.222082999999884],
                [8.994028000000128, -1.222082999999884],
                [8.994028000000128, -1.224861],
                [8.994306, -1.224861],
                [8.994306, -1.22708299999988],
                [8.994584, -1.22708299999988],
                [8.994584, -1.229027],
                [8.994306, -1.229027],
                [8.994306, -1.232082999999875],
                [8.994028000000128, -1.232082999999875],
                [8.994028000000128, -1.24125],
                [8.994584, -1.24236],
                [8.994860000000187, -1.24375],
                [8.994862000000182, -1.245416],
                [8.99513800000011, -1.245416],
                [8.99513800000011, -1.247082],
                [8.994862000000182, -1.247082],
                [8.994860000000187, -1.248751],
                [8.994584, -1.250139],
                [8.994306, -1.250139],
                [8.994306, -1.254860999999892],
                [8.994584, -1.254860999999892],
                [8.994584, -1.26014],
                [8.99513800000011, -1.262361999999882],
                [8.995694000000128, -1.262916],
                [8.995694000000128, -1.26375],
                [8.996528000000183, -1.265693999999883],
                [8.997360000000128, -1.26652799999988],
                [8.999028, -1.26652799999988],
                [8.999028, -1.26625],
                [9.000695000000178, -1.26625],
                [9.001529, -1.265416],
                [9.004583, -1.265416],
                [9.005417, -1.26486199999988],
                [9.005695000000173, -1.264306],
                [9.005695000000173, -1.263193999999885],
                [9.005139000000156, -1.262361999999882],
                [9.005139000000156, -1.26125],
                [9.004583, -1.26125],
                [9.003473000000156, -1.26069299999989],
                [9.003195, -1.26014],
                [9.002639000000102, -1.26014],
                [9.002085, -1.259304999999813],
                [9.002361000000178, -1.258470999999815],
                [9.002361000000178, -1.256249],
                [9.002639000000102, -1.254860999999892],
                [9.003751, -1.253472999999815],
                [9.004307, -1.253472999999815],
                [9.004583, -1.252917],
                [9.006251000000134, -1.251251],
                [9.006251000000134, -1.250417],
                [9.006805000000156, -1.249860999999896],
                [9.006805000000156, -1.244859999999846],
                [9.007639, -1.244582],
                [9.009027000000174, -1.242916],
                [9.009027000000174, -1.24236],
                [9.010417, -1.24125],
                [9.010971, -1.239861999999846],
                [9.010971, -1.23875],
                [9.010417, -1.238472],
                [9.010417, -1.236806],
                [9.010695000000169, -1.23625],
                [9.011527, -1.235694],
                [9.011249, -1.234862],
                [9.011249, -1.23375],
                [9.011527, -1.233471],
                [9.012639, -1.233471],
                [9.013193, -1.232914999999878],
                [9.013193, -1.232082999999875],
                [9.01402700000017, -1.231248999999878],
                [9.01402700000017, -1.229582999999877],
                [9.015694, -1.22708299999988],
                [9.016528000000164, -1.22708299999988],
                [9.01736, -1.225973],
                [9.01736, -1.225139],
                [9.017916, -1.224582999999882],
                [9.017916, -1.221250999999882],
                [9.018750000000125, -1.220695],
                [9.019306000000142, -1.218750999999884],
                [9.019306000000142, -1.21736],
                [9.018472, -1.216528],
                [9.017916, -1.216528],
                [9.017084000000125, -1.215694],
                [9.01402700000017, -1.215694],
                [9.012918, -1.215415999999891],
                [9.012361000000169, -1.21486],
                [9.011527, -1.21486],
                [9.010695000000169, -1.214306],
                [9.007083, -1.214306],
                [9.004861, -1.213749999999891],
                [9.002917, -1.212083999999891],
                [9.002361000000178, -1.212083999999891],
                [9.001804000000106, -1.211249999999893],
                [9.000416, -1.209862],
                [9.000416, -1.209306],
                [8.999028, -1.208749999999839],
                [8.997916, -1.210694],
                [8.997916, -1.211249999999893],
                [8.996528000000183, -1.214028],
                [8.996528000000183, -1.217638],
                [8.99625, -1.217638],
                [8.99625, -1.223195],
                [8.996806000000106, -1.224027],
                [8.996806000000106, -1.225139],
                [8.997360000000128, -1.226805],
                [8.996806000000106, -1.226805],
                [8.99625, -1.225416999999879],
                [8.99625, -1.224582999999882],
                [8.995694000000128, -1.224027],
                [8.995416, -1.222916999999882],
                [8.995416, -1.220139],
                [8.995694000000128, -1.220139],
                [8.995694000000128, -1.214583999999888],
                [8.995972, -1.213472],
                [8.996806000000106, -1.211528],
                [8.997360000000128, -1.210972],
                [8.997360000000128, -1.210415999999839],
                [8.998472000000106, -1.208194],
                [8.99875, -1.208194],
                [8.99875, -1.205971],
                [8.999307000000101, -1.205417999999838],
                [8.999582, -1.204027],
                [8.999582, -1.202361],
                [9.000416, -1.202083],
                [9.001529, -1.202083],
                [9.002085, -1.201805],
                [9.002917, -1.199305],
                [9.002917, -1.197639],
                [9.002361000000178, -1.197083],
                [9.002361000000178, -1.19513899999987],
                [9.002085, -1.19513899999987],
                [9.002085, -1.19236],
                [9.002639000000102, -1.19236],
                [9.002917, -1.193472999999869],
                [9.002917, -1.195417],
                [9.003751, -1.195695],
                [9.003751, -1.196251],
                [9.004307, -1.196527],
                [9.004583, -1.197361],
                [9.004583, -1.200971],
                [9.003751, -1.202083],
                [9.003751, -1.207915999999841],
                [9.004307, -1.209862],
                [9.005417, -1.210972],
                [9.006527, -1.211528],
                [9.010417, -1.211528],
                [9.011805000000152, -1.211806],
                [9.012083, -1.211249999999893],
                [9.012918, -1.210972],
                [9.01625, -1.210972],
                [9.01736, -1.211528],
                [9.018750000000125, -1.211528],
                [9.02125, -1.212083999999891],
                [9.021528000000103, -1.212638],
                [9.023194000000103, -1.213472],
                [9.024584, -1.21486],
                [9.025138, -1.215138],
                [9.025138, -1.215694],
                [9.025694, -1.215972],
                [9.025694, -1.216528],
                [9.026807000000133, -1.21736],
                [9.02708200000012, -1.217915999999889],
                [9.02708200000012, -1.219029],
                [9.027917000000116, -1.21986],
                [9.027917000000116, -1.222082999999884],
                [9.028195, -1.222082999999884],
                [9.028195, -1.224305],
                [9.029029, -1.225139],
                [9.029029, -1.225695],
                [9.029861, -1.226805],
                [9.029861, -1.227639],
                [9.030417, -1.228195],
                [9.031527, -1.230971],
                [9.031527, -1.232918],
                [9.030973000000188, -1.23375],
                [9.030973000000188, -1.236528],
                [9.031251000000111, -1.237916],
                [9.031807, -1.238194],
                [9.031807, -1.240416],
                [9.032639000000188, -1.240694],
                [9.033195, -1.241806],
                [9.033749, -1.243472],
                [9.033749, -1.250417],
                [9.034029, -1.250973],
                [9.034861, -1.251251],
                [9.034861, -1.251805],
                [9.035417, -1.252083],
                [9.035417, -1.252639],
                [9.035973000000183, -1.253194999999892],
                [9.036527, -1.253194999999892],
                [9.037083, -1.254026999999894],
                [9.037083, -1.254583],
                [9.037639000000183, -1.254860999999892],
                [9.037639000000183, -1.255417],
                [9.038471, -1.256249],
                [9.038471, -1.26069299999989],
                [9.037917000000107, -1.262916],
                [9.037083, -1.263472],
                [9.037083, -1.264027999999883],
                [9.036249000000112, -1.264027999999883],
                [9.035973000000183, -1.26486199999988],
                [9.035139, -1.26514],
                [9.035139, -1.265972],
                [9.034305000000188, -1.266806],
                [9.034305000000188, -1.267916],
                [9.033195, -1.269306],
                [9.031527, -1.269306],
                [9.030973000000188, -1.269028],
                [9.030139000000133, -1.267916],
                [9.028473000000133, -1.267916],
                [9.027638000000138, -1.268472],
                [9.02736, -1.269306],
                [9.02625, -1.270694],
                [9.025694, -1.270694],
                [9.024028, -1.27236],
                [9.023472, -1.272638],
                [9.023472, -1.273195],
                [9.02208400000012, -1.274029],
                [9.021528000000103, -1.274029],
                [9.02125, -1.274583],
                [9.020138, -1.275139],
                [9.019028, -1.275417],
                [9.016528000000164, -1.275417],
                [9.015972000000147, -1.275972999999851],
                [9.014584, -1.276251],
                [9.013474, -1.277638999999851],
                [9.013474, -1.278749],
                [9.01402700000017, -1.279027],
                [9.01402700000017, -1.280138999999849],
                [9.014584, -1.280973],
                [9.01514, -1.280973],
                [9.016806, -1.282639],
                [9.016806, -1.283195],
                [9.018194000000165, -1.284305],
                [9.018750000000125, -1.284305],
                [9.019584, -1.285971],
                [9.019584, -1.289028],
                [9.019862000000103, -1.289028],
                [9.019862000000103, -1.290971999999897],
                [9.020138, -1.291805999999895],
                [9.022640000000138, -1.291805999999895],
                [9.023472, -1.292083999999818],
                [9.02375000000012, -1.292915999999821],
                [9.022916, -1.292915999999821],
                [9.02236, -1.292362],
                [9.020694, -1.292362],
                [9.019862000000103, -1.293194],
                [9.019862000000103, -1.294305999999892],
                [9.019306000000142, -1.295694],
                [9.018472, -1.296249999999873],
                [9.017916, -1.296249999999873],
                [9.01736, -1.297637999999893],
                [9.01736, -1.300695],
                [9.017640000000142, -1.302082999999868],
                [9.016806, -1.302361],
                [9.016806, -1.304027],
                [9.017084000000125, -1.304027],
                [9.017084000000125, -1.307361],
                [9.01736, -1.307361],
                [9.01736, -1.309583],
                [9.017640000000142, -1.310417],
                [9.018194000000165, -1.310695],
                [9.018194000000165, -1.31264],
                [9.018472, -1.313471],
                [9.019028, -1.314028],
                [9.019028, -1.31514],
                [9.019862000000103, -1.315972],
                [9.019862000000103, -1.316806],
                [9.020694, -1.317915999999855],
                [9.020972000000143, -1.319028],
                [9.020972000000143, -1.323194],
                [9.020416000000125, -1.32486],
                [9.019862000000103, -1.325138],
                [9.019862000000103, -1.326251],
                [9.019028, -1.326807],
                [9.018194000000165, -1.326807],
                [9.017640000000142, -1.32736],
                [9.016806, -1.32736],
                [9.01625, -1.327917],
                [9.015418000000125, -1.327917],
                [9.014862000000164, -1.328473],
                [9.013193, -1.328750999999897],
                [9.012918, -1.328194999999823],
                [9.012918, -1.322916],
                [9.012639, -1.321528],
                [9.012083, -1.321528],
                [9.010971, -1.320138],
                [9.010971, -1.319306],
                [9.010417, -1.31875],
                [9.009583, -1.31875],
                [9.009305, -1.318194],
                [9.008473000000151, -1.317915999999855],
                [9.008473000000151, -1.317084],
                [9.007917, -1.316806],
                [9.007639, -1.31514],
                [9.007083, -1.31514],
                [9.007083, -1.313749],
                [9.005973, -1.313193],
                [9.005973, -1.311527],
                [9.004861, -1.311527],
                [9.004307, -1.310695],
                [9.004027000000178, -1.309583],
                [9.004583, -1.309305],
                [9.004027000000178, -1.308473],
                [9.002085, -1.307917],
                [9.001529, -1.307083],
                [9.001529, -1.304583],
                [9.000973000000101, -1.302917],
                [9.000416, -1.302638999999886],
                [9.000416, -1.302082999999868],
                [8.999582, -1.302082999999868],
                [8.999582, -1.300972999999885],
                [8.999028, -1.300695],
                [8.999028, -1.299581999999873],
                [8.998472000000106, -1.299581999999873],
                [8.998194000000183, -1.29875],
                [8.99625, -1.298194],
                [8.99625, -1.295415999999818],
                [8.995694000000128, -1.292915999999821],
                [8.994860000000187, -1.290971999999897],
                [8.994306, -1.290694],
                [8.994306, -1.290139999999894],
                [8.99347200000011, -1.289305999999897],
                [8.992638, -1.286249],
                [8.992084, -1.285696],
                [8.992084, -1.284861],
                [8.991528000000187, -1.283749],
                [8.99014000000011, -1.282639],
                [8.989862000000187, -1.281527],
                [8.989306, -1.280973],
                [8.989306, -1.280138999999849],
                [8.98875, -1.279027],
                [8.988472000000115, -1.277638999999851],
                [8.988193000000138, -1.277638999999851],
                [8.988193000000138, -1.274583],
                [8.987918, -1.272638],
                [8.987084, -1.270972],
                [8.986805, -1.270972],
                [8.98597100000012, -1.269028],
                [8.98597100000012, -1.267916],
                [8.985696000000132, -1.267084],
                [8.984861000000137, -1.266806],
                [8.984027, -1.265972],
                [8.984027, -1.265416],
                [8.983471, -1.26514],
                [8.983195000000137, -1.264306],
                [8.98263900000012, -1.264306],
                [8.98263900000012, -1.26652799999988],
                [8.983195000000137, -1.267638],
                [8.983195000000137, -1.26875],
                [8.983751, -1.269584],
                [8.984583, -1.272082],
                [8.985417, -1.275417],
                [8.985417, -1.276805],
                [8.986249, -1.280138999999849],
                [8.986805, -1.280695],
                [8.98763700000012, -1.280973],
                [8.987918, -1.281804999999849],
                [8.987918, -1.28347099999985],
                [8.988472000000115, -1.284027],
                [8.989584, -1.286527],
                [8.990694000000133, -1.289305999999897],
                [8.992084, -1.292362],
                [8.992084, -1.293194],
                [8.992918, -1.295137999999895],
                [8.99347200000011, -1.295694],
                [8.99347200000011, -1.296528],
                [8.994028000000128, -1.29708399999987],
                [8.994028000000128, -1.297915999999873],
                [8.994584, -1.29847199999989],
                [8.994862000000182, -1.30013799999989],
                [8.995416, -1.300695],
                [8.995416, -1.301529],
                [8.995972, -1.302082999999868],
                [8.995972, -1.302917],
                [8.996528000000183, -1.303473],
                [8.996528000000183, -1.304861],
                [8.997360000000128, -1.305695],
                [8.997916, -1.306805],
                [8.997916, -1.307361],
                [8.998472000000106, -1.307639],
                [8.99875, -1.308749],
                [9.000138000000106, -1.308749],
                [9.000695000000178, -1.309027],
                [9.000695000000178, -1.309861],
                [9.001804000000106, -1.310139],
                [9.002361000000178, -1.310695],
                [9.002917, -1.312361],
                [9.003751, -1.313193],
                [9.004307, -1.313193],
                [9.005417, -1.314583999999854],
                [9.005417, -1.315418],
                [9.006251000000134, -1.315972],
                [9.006251000000134, -1.316528],
                [9.007083, -1.317084],
                [9.007083, -1.31764],
                [9.007917, -1.317915999999855],
                [9.008749, -1.31875],
                [9.008749, -1.319306],
                [9.009305, -1.319584],
                [9.009583, -1.320694],
                [9.010139000000152, -1.320972],
                [9.010695000000169, -1.322084],
                [9.011527, -1.323194],
                [9.011527, -1.324028],
                [9.012083, -1.324582],
                [9.012361000000169, -1.325972],
                [9.012361000000169, -1.327639],
                [9.012639, -1.327639],
                [9.012639, -1.329860999999823],
                [9.012918, -1.330973],
                [9.013749, -1.332082999999898],
                [9.013749, -1.333748999999898],
                [9.014862000000164, -1.334305],
                [9.014862000000164, -1.336805],
                [9.014584, -1.336805],
                [9.014584, -1.338471],
                [9.015418000000125, -1.338748999999893],
                [9.015418000000125, -1.339305],
                [9.015972000000147, -1.339582999999891],
                [9.01625, -1.34125],
                [9.017084000000125, -1.341806],
                [9.017084000000125, -1.343472],
                [9.017916, -1.344306],
                [9.017916, -1.344862],
                [9.018750000000125, -1.345694],
                [9.018750000000125, -1.346528],
                [9.019862000000103, -1.347638],
                [9.019862000000103, -1.348194],
                [9.020694, -1.34875],
                [9.020972000000143, -1.34986],
                [9.021528000000103, -1.34986],
                [9.02208400000012, -1.350416],
                [9.02208400000012, -1.350972],
                [9.022916, -1.351806],
                [9.022916, -1.352638],
                [9.02375000000012, -1.353473],
                [9.024582, -1.353751],
                [9.025138, -1.354861],
                [9.025972000000138, -1.355139],
                [9.025972000000138, -1.356251],
                [9.026529, -1.357361],
                [9.02736, -1.357639],
                [9.02736, -1.358195],
                [9.029029, -1.359861],
                [9.029583000000116, -1.360972999999831],
                [9.030973000000188, -1.362083],
                [9.030973000000188, -1.362638999999831],
                [9.032083, -1.363749],
                [9.032917000000111, -1.364304999999831],
                [9.033195, -1.365417],
                [9.035417, -1.367639999999881],
                [9.035973000000183, -1.367639999999881],
                [9.036527, -1.368471999999883],
                [9.036527, -1.369305999999881],
                [9.039861, -1.372639999999876],
                [9.040140000000179, -1.37375],
                [9.040693, -1.37375],
                [9.041806000000179, -1.374859999999899],
                [9.042084000000102, -1.375693999999896],
                [9.043472000000179, -1.376805999999874],
                [9.043472000000179, -1.377359999999896],
                [9.045140000000174, -1.379028],
                [9.046528, -1.379582],
                [9.046528, -1.380416],
                [9.047082000000103, -1.381529],
                [9.047638, -1.381529],
                [9.049028, -1.382917],
                [9.049306, -1.383472999999867],
                [9.050416, -1.384583],
                [9.050416, -1.385138999999867],
                [9.050972, -1.385417],
                [9.050972, -1.386251],
                [9.052082, -1.386804999999868],
                [9.052082, -1.387361],
                [9.054585, -1.389861],
                [9.055139, -1.389861],
                [9.055973000000108, -1.390695],
                [9.056251, -1.391805],
                [9.056805, -1.391805],
                [9.057639000000108, -1.392918],
                [9.057639000000108, -1.393471],
                [9.058751000000143, -1.394306],
                [9.058751000000143, -1.394862],
                [9.060417000000143, -1.39625],
                [9.060417000000143, -1.396806],
                [9.063471, -1.399862],
                [9.063751000000138, -1.401249999999834],
                [9.064583, -1.401528],
                [9.065695, -1.402638],
                [9.065695, -1.403472],
                [9.066527000000121, -1.404306],
                [9.066527000000121, -1.405138],
                [9.06764, -1.405972],
                [9.067918, -1.407081999999889],
                [9.068471, -1.40736],
                [9.068471, -1.407916999999884],
                [9.070416, -1.409861],
                [9.070416, -1.410695],
                [9.072084000000189, -1.412082999999882],
                [9.072084000000189, -1.412639],
                [9.072638, -1.412916999999879],
                [9.072638, -1.413473],
                [9.073472, -1.414027],
                [9.073472, -1.415139],
                [9.074028000000112, -1.415695],
                [9.074584, -1.415695],
                [9.075138, -1.416527],
                [9.075138, -1.417082999999877],
                [9.07625, -1.417361],
                [9.07625, -1.417916999999875],
                [9.076806, -1.418748999999878],
                [9.077360000000112, -1.418748999999878],
                [9.078750000000184, -1.42014],
                [9.079582, -1.421248999999875],
                [9.079582, -1.421806],
                [9.080138, -1.42208399999987],
                [9.080138, -1.42264],
                [9.08125100000018, -1.423472],
                [9.08125100000018, -1.424584],
                [9.082085, -1.424862],
                [9.082361000000162, -1.425694],
                [9.083751, -1.427084],
                [9.08458300000018, -1.427638],
                [9.08458300000018, -1.428472],
                [9.085417, -1.429028],
                [9.085417, -1.42986],
                [9.086251000000175, -1.430694],
                [9.087083, -1.430972],
                [9.087361000000158, -1.43236],
                [9.088473000000135, -1.433751],
                [9.090417, -1.435417],
                [9.090695000000153, -1.436805],
                [9.091527, -1.437360999999839],
                [9.091527, -1.437917],
                [9.092361000000153, -1.438194999999894],
                [9.092917000000114, -1.439305],
                [9.092917000000114, -1.440139],
                [9.093749, -1.440694999999892],
                [9.094027000000153, -1.441526999999894],
                [9.09514, -1.441805],
                [9.09514, -1.442360999999892],
                [9.095972000000131, -1.442917],
                [9.09625, -1.444305],
                [9.097916, -1.445971],
                [9.097916, -1.44652699999989],
                [9.098472, -1.447083],
                [9.099028, -1.447083],
                [9.100138, -1.44875],
                [9.100972000000127, -1.449306],
                [9.101528000000144, -1.450416],
                [9.102084000000104, -1.450972],
                [9.102084000000104, -1.451806],
                [9.102640000000122, -1.452084],
                [9.102916, -1.453193999999883],
                [9.103472, -1.453472],
                [9.103472, -1.454306],
                [9.104028, -1.454584],
                [9.104028, -1.455138],
                [9.104584, -1.455416],
                [9.104584, -1.455972],
                [9.10625, -1.457638],
                [9.10625, -1.458193999999878],
                [9.107638000000122, -1.459582],
                [9.107638000000122, -1.460138],
                [9.109029, -1.461251],
                [9.109029, -1.461807],
                [9.109861, -1.462639],
                [9.109861, -1.463473],
                [9.111807000000113, -1.465139],
                [9.112083, -1.465695],
                [9.113751, -1.467361],
                [9.113751, -1.467917],
                [9.11486100000019, -1.468472999999847],
                [9.115417, -1.468472999999847],
                [9.115417, -1.469305],
                [9.116249, -1.470138999999847],
                [9.116249, -1.470695],
                [9.117361, -1.471249],
                [9.117361, -1.471804999999847],
                [9.118195000000185, -1.472083],
                [9.118749, -1.473470999999847],
                [9.119305000000168, -1.474027],
                [9.119861000000185, -1.474027],
                [9.120139, -1.474584],
                [9.122915, -1.477362],
                [9.122915, -1.477916],
                [9.12458400000014, -1.479584],
                [9.125972, -1.480694],
                [9.125972, -1.48125],
                [9.127362000000119, -1.482637999999895],
                [9.12875, -1.482916],
                [9.12875, -1.484028],
                [9.129306, -1.48430599999989],
                [9.129306, -1.48486],
                [9.130416, -1.48597199999989],
                [9.130972, -1.48597199999989],
                [9.131806000000154, -1.486806999999885],
                [9.131806000000154, -1.48763799999989],
                [9.133472000000154, -1.489303999999891],
                [9.134029, -1.490417],
                [9.135138000000154, -1.491251],
                [9.13597300000015, -1.491529],
                [9.13597300000015, -1.492361],
                [9.13652900000011, -1.492638999999883],
                [9.13652900000011, -1.493195],
                [9.137361, -1.494027],
                [9.137917, -1.494027],
                [9.138473, -1.494583],
                [9.138751000000127, -1.496249],
                [9.13930500000015, -1.496527],
                [9.13930500000015, -1.497083],
                [9.140139, -1.497917],
                [9.140695, -1.499027],
                [9.141805, -1.500139],
                [9.142639, -1.500693],
                [9.142639, -1.501527],
                [9.143195000000105, -1.501806],
                [9.143473, -1.502917999999852],
                [9.144305, -1.50375],
                [9.144861000000105, -1.50375],
                [9.145417000000123, -1.504306],
                [9.145695, -1.505416],
                [9.147083000000123, -1.506528],
                [9.148749000000123, -1.508194],
                [9.149584000000118, -1.509306],
                [9.149584000000118, -1.50986],
                [9.150415000000123, -1.510694],
                [9.150972, -1.510694],
                [9.151528, -1.51125],
                [9.151806, -1.51236],
                [9.152362, -1.512638],
                [9.152362, -1.513194],
                [9.152918, -1.513472],
                [9.153472, -1.51486],
                [9.15430600000019, -1.515695],
                [9.154584, -1.516804],
                [9.155416000000173, -1.517639],
                [9.156528, -1.519582999999898],
                [9.157084000000168, -1.519582999999898],
                [9.15736, -1.520416999999895],
                [9.158194, -1.520973],
                [9.158194, -1.521526999999821],
                [9.160972000000186, -1.524305],
                [9.161529, -1.524582999999893],
                [9.161529, -1.525139],
                [9.163195, -1.526805],
                [9.163751, -1.527082999999891],
                [9.163751, -1.52764],
                [9.164305, -1.528471],
                [9.165139000000124, -1.529027999999869],
                [9.166251000000159, -1.53014],
                [9.167361000000142, -1.530414999999891],
                [9.167361000000142, -1.531249999999886],
                [9.167917000000159, -1.532362],
                [9.16847300000012, -1.53264],
                [9.16847300000012, -1.533194],
                [9.169305, -1.534028],
                [9.169305, -1.534862],
                [9.169861, -1.535138],
                [9.169861, -1.535694],
                [9.170417, -1.535972],
                [9.170417, -1.536528],
                [9.172083, -1.537916],
                [9.172361000000137, -1.539028],
                [9.173474, -1.53986],
                [9.173474, -1.540694],
                [9.174027000000137, -1.540972],
                [9.17514, -1.542082],
                [9.17514, -1.542639],
                [9.175971, -1.543194999999855],
                [9.17625, -1.544583],
                [9.17764000000011, -1.545695],
                [9.17764000000011, -1.546251],
                [9.178194000000133, -1.546526999999855],
                [9.178194000000133, -1.547083],
                [9.17875, -1.548195],
                [9.180138, -1.549305],
                [9.180138, -1.549861],
                [9.18125, -1.550417],
                [9.18125, -1.550973],
                [9.182084, -1.551805],
                [9.182640000000106, -1.551805],
                [9.183194000000128, -1.552917],
                [9.18375, -1.552917],
                [9.184584, -1.553749],
                [9.184860000000128, -1.554862],
                [9.185416, -1.555139999999824],
                [9.185972000000106, -1.556249],
                [9.185972000000106, -1.556805999999824],
                [9.187082, -1.558194],
                [9.187916, -1.558471999999824],
                [9.187916, -1.559027999999898],
                [9.189029, -1.560139999999876],
                [9.189585, -1.560139999999876],
                [9.190417, -1.560971999999879],
                [9.190417, -1.561527999999896],
                [9.190973, -1.562084],
                [9.191527000000178, -1.562084],
                [9.192083, -1.562637999999879],
                [9.192083, -1.563193999999896],
                [9.192639, -1.563471999999877],
                [9.192639, -1.564027999999894],
                [9.193195000000173, -1.564305999999874],
                [9.193195000000173, -1.564859999999896],
                [9.19375100000019, -1.565137999999877],
                [9.19375100000019, -1.565693999999894],
                [9.194305, -1.565971999999874],
                [9.195139, -1.567082],
                [9.195695, -1.568193999999892],
                [9.195695, -1.568751],
                [9.197361, -1.57013899999987],
                [9.197917, -1.571251],
                [9.197917, -1.572361],
                [9.198749, -1.573195],
                [9.19875100000013, -1.573751],
                [9.199305000000152, -1.574304999999868],
                [9.199861000000169, -1.575417],
                [9.199861000000169, -1.576527],
                [9.20041700000013, -1.578195],
                [9.202084, -1.579583],
                [9.202362000000164, -1.580417],
                [9.202362000000164, -1.581527],
                [9.202918000000125, -1.581804999999861],
                [9.202918000000125, -1.582362],
                [9.203471, -1.58264],
                [9.20375, -1.58375],
                [9.204306, -1.584862],
                [9.205416, -1.585694],
                [9.206528, -1.587084],
                [9.206806000000142, -1.587916],
                [9.20736200000016, -1.587916],
                [9.20736200000016, -1.58875],
                [9.20875, -1.590416],
                [9.20875, -1.590972],
                [9.209306, -1.591249999999832],
                [9.20986, -1.592638],
                [9.20986, -1.59375],
                [9.210416, -1.59486],
                [9.21069400000016, -1.595973],
                [9.21125000000012, -1.596529],
                [9.21125000000012, -1.597361],
                [9.211806000000138, -1.597916999999882],
                [9.211806000000138, -1.599029],
                [9.212362, -1.599305],
                [9.212362, -1.600138999999899],
                [9.213194, -1.600695],
                [9.21375, -1.601804999999899],
                [9.21375, -1.602361],
                [9.215416, -1.604027],
                [9.216251, -1.605695],
                [9.216804000000138, -1.605695],
                [9.217361, -1.606527],
                [9.217361, -1.607361],
                [9.217917, -1.60791799999987],
                [9.217917, -1.609027],
                [9.218473000000188, -1.609862],
                [9.218473000000188, -1.610972],
                [9.219027, -1.61125],
                [9.219027, -1.612083999999868],
                [9.220417000000111, -1.613194],
                [9.220695, -1.614306],
                [9.222083000000111, -1.615415999999868],
                [9.222361, -1.616528],
                [9.222917, -1.616806],
                [9.223195, -1.618194],
                [9.224027, -1.619028],
                [9.224305000000129, -1.61986],
                [9.225417000000107, -1.620694],
                [9.225695, -1.621807],
                [9.226249, -1.621807],
                [9.226527, -1.623195],
                [9.227083000000107, -1.623473],
                [9.227083000000107, -1.630694999999889],
                [9.227639, -1.630973],
                [9.228193, -1.632639],
                [9.228749000000107, -1.632917],
                [9.228749000000107, -1.633473],
                [9.229306000000179, -1.633749],
                [9.229306000000179, -1.634305],
                [9.229862, -1.634583],
                [9.23014, -1.635695999999882],
                [9.230972000000179, -1.636249],
                [9.230972000000179, -1.636806],
                [9.232084000000157, -1.637361999999882],
                [9.232640000000174, -1.638472],
                [9.234306000000174, -1.640416],
                [9.234306000000174, -1.64125],
                [9.235138, -1.642361999999878],
                [9.236806, -1.644027999999878],
                [9.236806, -1.644584],
                [9.237638000000175, -1.645416],
                [9.237638000000175, -1.646527999999876],
                [9.23736, -1.647638],
                [9.237916, -1.647916],
                [9.238472, -1.649307],
                [9.238472, -1.650694999999871],
                [9.239028, -1.651529],
                [9.239028, -1.653195],
                [9.23986200000013, -1.654305],
                [9.23986200000013, -1.656251],
                [9.240416000000153, -1.657083],
                [9.240416000000153, -1.658195],
                [9.240694, -1.659027],
                [9.241529, -1.659861],
                [9.241529, -1.661249],
                [9.24236, -1.661527],
                [9.243473000000165, -1.663471],
                [9.243473000000165, -1.664028],
                [9.244029000000126, -1.66430599999984],
                [9.244029000000126, -1.664862],
                [9.245139000000165, -1.665694],
                [9.245417, -1.666805999999895],
                [9.246251000000143, -1.66763799999984],
                [9.246251000000143, -1.66875],
                [9.246805000000165, -1.669584],
                [9.247361000000126, -1.669584],
                [9.247639, -1.670416],
                [9.247639, -1.67236],
                [9.248473000000104, -1.672637999999893],
                [9.248195, -1.67375],
                [9.248195, -1.675416],
                [9.248749, -1.675416],
                [9.248749, -1.677082],
                [9.248473000000104, -1.677082],
                [9.248473000000104, -1.678751],
                [9.247917000000143, -1.681527],
                [9.247361000000126, -1.683472999999879],
                [9.246805000000165, -1.683749],
                [9.246805000000165, -1.684583],
                [9.246251000000143, -1.685695],
                [9.245695000000126, -1.685972999999876],
                [9.245695000000126, -1.686527],
                [9.245139000000165, -1.686804999999879],
                [9.244861, -1.687638999999876],
                [9.245139000000165, -1.688749],
                [9.245139000000165, -1.691528],
                [9.245695000000126, -1.692084],
                [9.245695000000126, -1.694028],
                [9.246527, -1.694584],
                [9.246805000000165, -1.69514],
                [9.246805000000165, -1.696528],
                [9.247361000000126, -1.698472],
                [9.247917000000143, -1.699028],
                [9.247917000000143, -1.700415999999848],
                [9.248473000000104, -1.701249999999845],
                [9.248473000000104, -1.703473],
                [9.248749, -1.703473],
                [9.248749, -1.705139],
                [9.248473000000104, -1.706250999999895],
                [9.249029000000121, -1.707082999999898],
                [9.249029000000121, -1.708195],
                [9.249305, -1.709027],
                [9.249861, -1.709305],
                [9.249861, -1.710139],
                [9.250695000000121, -1.710973],
                [9.251251000000138, -1.712082999999893],
                [9.251251000000138, -1.714305],
                [9.251805000000104, -1.714582999999891],
                [9.252083, -1.715416999999889],
                [9.252083, -1.717917999999884],
                [9.252639, -1.718472],
                [9.253195, -1.720415999999887],
                [9.252917000000139, -1.721806],
                [9.252917000000139, -1.723749999999882],
                [9.253195, -1.723749999999882],
                [9.253195, -1.728194],
                [9.253749, -1.72986],
                [9.254305, -1.730416],
                [9.254305, -1.730973],
                [9.254861, -1.732083],
                [9.255418000000134, -1.732639],
                [9.255693000000122, -1.733473],
                [9.255693000000122, -1.735139],
                [9.255418000000134, -1.735139],
                [9.255418000000134, -1.737083],
                [9.255971, -1.737639],
                [9.256249000000139, -1.739305],
                [9.257084000000134, -1.739861],
                [9.25764, -1.740971],
                [9.25764, -1.741527],
                [9.258196000000112, -1.742639],
                [9.258750000000134, -1.742918],
                [9.258750000000134, -1.743471],
                [9.259306, -1.743749],
                [9.260138, -1.745694],
                [9.260418, -1.746805999999822],
                [9.260972, -1.747084],
                [9.260972, -1.747639999999819],
                [9.261528000000112, -1.747916],
                [9.261528000000112, -1.748471999999822],
                [9.262084, -1.74875],
                [9.262362, -1.749861999999894],
                [9.263472, -1.749861999999894],
                [9.264028, -1.750416],
                [9.264028, -1.751527999999894],
                [9.266528000000108, -1.751527999999894],
                [9.26736, -1.751805999999874],
                [9.26875100000018, -1.751527999999894],
                [9.269304, -1.750693999999896],
                [9.270695000000103, -1.750416],
                [9.270695000000103, -1.751527999999894],
                [9.270138, -1.751805999999874],
                [9.269304, -1.752916],
                [9.269304, -1.75375],
                [9.269860000000108, -1.754027999999892],
                [9.269860000000108, -1.754584],
                [9.27041700000018, -1.754859999999894],
                [9.272361000000103, -1.754859999999894],
                [9.273751000000175, -1.754305999999872],
                [9.274027000000103, -1.755416],
                [9.275139, -1.755416],
                [9.275695, -1.755693999999892],
                [9.275695, -1.75625],
                [9.269860000000108, -1.75625],
                [9.269304, -1.755971999999872],
                [9.269304, -1.755416],
                [9.268472, -1.754584],
                [9.26736, -1.754584],
                [9.26736, -1.753471999999874],
                [9.267082, -1.752916],
                [9.265437, -1.752914],
                [9.265138, -1.752359999999896],
                [9.263472, -1.752916],
                [9.26375, -1.754584],
                [9.264306, -1.755137999999874],
                [9.264306, -1.755693999999892],
                [9.265138, -1.755971999999872],
                [9.265416, -1.756528999999887],
                [9.265416, -1.757637999999872],
                [9.265694, -1.758473],
                [9.266250000000184, -1.759025999999892],
                [9.266528000000108, -1.759860999999887],
                [9.266528000000108, -1.762361],
                [9.267082, -1.762917],
                [9.26736, -1.763749],
                [9.26736, -1.766249],
                [9.267916000000184, -1.767361],
                [9.267916000000184, -1.769305],
                [9.268194000000108, -1.770139999999856],
                [9.26875100000018, -1.770693],
                [9.26875100000018, -1.772084],
                [9.269304, -1.772916],
                [9.269304, -1.777084],
                [9.269860000000108, -1.778194],
                [9.269860000000108, -1.781528],
                [9.269304, -1.783194],
                [9.269304, -1.784029],
                [9.268194000000108, -1.786250999999879],
                [9.267082, -1.787082999999825],
                [9.267082, -1.788195],
                [9.26736, -1.789304999999899],
                [9.266250000000184, -1.789861],
                [9.266250000000184, -1.791250999999875],
                [9.265694, -1.791804999999897],
                [9.265694, -1.793748999999878],
                [9.264306, -1.794027],
                [9.264306, -1.793470999999897],
                [9.263194000000112, -1.793470999999897],
                [9.26264, -1.794027],
                [9.261528000000112, -1.794027],
                [9.260694, -1.794304999999895],
                [9.260138, -1.795138999999892],
                [9.258750000000134, -1.795693],
                [9.258750000000134, -1.796527],
                [9.259306, -1.796804999999893],
                [9.259584000000189, -1.798193],
                [9.260138, -1.799862],
                [9.260138, -1.801249999999868],
                [9.259862000000112, -1.802362],
                [9.258472, -1.804306],
                [9.25791500000014, -1.804306],
                [9.25764, -1.805416],
                [9.25764, -1.806528],
                [9.258196000000112, -1.808749999999861],
                [9.259028, -1.810415999999862],
                [9.259028, -1.814861],
                [9.258472, -1.815973],
                [9.258472, -1.818194999999832],
                [9.259306, -1.818473],
                [9.259306, -1.819860999999833],
                [9.259028, -1.820417],
                [9.259862000000112, -1.821249],
                [9.259862000000112, -1.822083],
                [9.260694, -1.822639],
                [9.261250000000189, -1.824305],
                [9.261250000000189, -1.825692999999831],
                [9.261528000000112, -1.826527999999883],
                [9.262084, -1.827084],
                [9.262084, -1.830138],
                [9.261528000000112, -1.830416],
                [9.261528000000112, -1.833193999999878],
                [9.261806, -1.833193999999878],
                [9.261806, -1.835416],
                [9.261528000000112, -1.836527999999873],
                [9.261806, -1.837359999999876],
                [9.262362, -1.837916],
                [9.26264, -1.838751],
                [9.26264, -1.842639],
                [9.262916000000189, -1.843751],
                [9.262362, -1.844860999999867],
                [9.262362, -1.846526999999867],
                [9.262916000000189, -1.847917],
                [9.26375, -1.848749],
                [9.26375, -1.849583],
                [9.264584000000184, -1.850418],
                [9.264584000000184, -1.853194],
                [9.264860000000112, -1.853194],
                [9.264860000000112, -1.855694],
                [9.264584000000184, -1.856805999999835],
                [9.264028, -1.857362],
                [9.264028, -1.858471999999836],
                [9.264584000000184, -1.85930599999989],
                [9.264584000000184, -1.85986],
                [9.265138, -1.860137999999836],
                [9.265138, -1.860694],
                [9.265972, -1.86125],
                [9.266250000000184, -1.86263799999989],
                [9.266806, -1.863194],
                [9.267082, -1.864029],
                [9.267082, -1.865417],
                [9.266528000000108, -1.865972999999883],
                [9.265972, -1.865972999999883],
                [9.265694, -1.866529],
                [9.265694, -1.867638999999883],
                [9.266528000000108, -1.868195],
                [9.267082, -1.869304999999883],
                [9.267082, -1.869861],
                [9.267638, -1.870138999999881],
                [9.267638, -1.870972999999879],
                [9.268472, -1.871804999999881],
                [9.269585, -1.872083],
                [9.269860000000108, -1.872917],
                [9.271251, -1.872917],
                [9.27208300000018, -1.873195],
                [9.27208300000018, -1.874861],
                [9.272361000000103, -1.874861],
                [9.272361000000103, -1.876527],
                [9.272639, -1.876527],
                [9.272639, -1.878193],
                [9.272917, -1.878193],
                [9.272917, -1.88125],
                [9.273751000000175, -1.881528],
                [9.274583, -1.882362],
                [9.274583, -1.884028],
                [9.275417000000175, -1.884584],
                [9.275695, -1.885138],
                [9.275695, -1.88625],
                [9.277361, -1.887916],
                [9.277639, -1.889028],
                [9.279027, -1.890138],
                [9.279583, -1.891807],
                [9.280139, -1.892082],
                [9.280693, -1.893195],
                [9.280693, -1.894029],
                [9.281249, -1.894582999999841],
                [9.281249, -1.895139],
                [9.281805, -1.895416999999895],
                [9.281805, -1.896529],
                [9.282361, -1.897361],
                [9.282361, -1.898473],
                [9.28208300000017, -1.899305],
                [9.28264, -1.899582999999893],
                [9.283471, -1.901527],
                [9.283471, -1.902361],
                [9.284028000000148, -1.902916999999889],
                [9.284028000000148, -1.903748999999891],
                [9.285140000000126, -1.904862],
                [9.285140000000126, -1.906249999999886],
                [9.28625000000011, -1.907083999999884],
                [9.28625000000011, -1.907915999999887],
                [9.287084, -1.908472],
                [9.287084, -1.909028],
                [9.287638, -1.909306],
                [9.287638, -1.909862],
                [9.288194, -1.910138],
                [9.288194, -1.910694],
                [9.28986, -1.912362],
                [9.290140000000122, -1.912915999999882],
                [9.290972, -1.913472],
                [9.292084, -1.91486],
                [9.292916000000105, -1.916249999999877],
                [9.293472000000122, -1.916249999999877],
                [9.29375, -1.91708199999988],
                [9.296529, -1.919861],
                [9.296529, -1.920417],
                [9.29736000000014, -1.920973],
                [9.299027, -1.922639],
                [9.299583, -1.923473],
                [9.300417, -1.923473],
                [9.301251, -1.924583],
                [9.301805, -1.924583],
                [9.302083, -1.925139],
                [9.303195, -1.925973],
                [9.303749, -1.925973],
                [9.304305000000113, -1.926527],
                [9.304305000000113, -1.927083],
                [9.305139000000167, -1.928194999999846],
                [9.305695000000185, -1.928473],
                [9.305695000000185, -1.929026999999849],
                [9.306527, -1.929583],
                [9.307361000000185, -1.930692999999849],
                [9.307361000000185, -1.931249],
                [9.308193, -1.931526999999846],
                [9.308474, -1.932361999999898],
                [9.307361000000185, -1.93264],
                [9.308474, -1.934027999999898],
                [9.309583, -1.934306],
                [9.310415, -1.935693999999899],
                [9.31152800000018, -1.936806],
                [9.312084, -1.936806],
                [9.312640000000158, -1.937916],
                [9.312640000000158, -1.938472],
                [9.313472, -1.93875],
                [9.313472, -1.939305999999817],
                [9.314862000000176, -1.940416],
                [9.315138, -1.941527999999892],
                [9.315694, -1.941805999999815],
                [9.315694, -1.942359999999894],
                [9.316528000000176, -1.942916],
                [9.317640000000154, -1.944307],
                [9.318194000000176, -1.944307],
                [9.320138, -1.946251],
                [9.320972000000154, -1.947360999999887],
                [9.32125, -1.948473],
                [9.32236, -1.949304999999868],
                [9.32236, -1.949860999999885],
                [9.32347300000015, -1.950973],
                [9.32402900000011, -1.950973],
                [9.324304000000154, -1.952083],
                [9.324861, -1.952639],
                [9.32569500000011, -1.952917],
                [9.326527, -1.953749],
                [9.327639, -1.954305],
                [9.327639, -1.954861],
                [9.328473000000145, -1.955139],
                [9.329029000000105, -1.955695],
                [9.329305, -1.956805],
                [9.330139000000145, -1.957361],
                [9.330973, -1.957083],
                [9.330417, -1.956527],
                [9.330417, -1.955971],
                [9.329861, -1.955417],
                [9.328749, -1.954861],
                [9.327917000000127, -1.953749],
                [9.32680500000015, -1.952639],
                [9.326527, -1.952083],
                [9.325417, -1.951805],
                [9.32513900000015, -1.951251],
                [9.324582, -1.951251],
                [9.324304000000154, -1.949860999999885],
                [9.323194000000115, -1.948751],
                [9.322916, -1.948194999999885],
                [9.32236, -1.948194999999885],
                [9.322085, -1.947083],
                [9.321528000000114, -1.946803999999816],
                [9.321528000000114, -1.945973],
                [9.320972000000154, -1.945416],
                [9.320138, -1.945416],
                [9.319028, -1.944027999999889],
                [9.319028, -1.943471999999815],
                [9.318194000000176, -1.943471999999815],
                [9.317916, -1.942359999999894],
                [9.316806, -1.942359999999894],
                [9.316806, -1.941805999999815],
                [9.315972000000158, -1.940693999999894],
                [9.314862000000176, -1.939859999999896],
                [9.314584, -1.939027999999894],
                [9.31375, -1.93875],
                [9.31375, -1.937916],
                [9.312918, -1.936806],
                [9.31125, -1.93514],
                [9.310971000000109, -1.93514],
                [9.309583, -1.93375],
                [9.310971000000109, -1.934027999999898],
                [9.312640000000158, -1.935693999999899],
                [9.314028, -1.937361999999894],
                [9.314028, -1.938193999999896],
                [9.316528000000176, -1.940693999999894],
                [9.317084000000136, -1.940971999999817],
                [9.318194000000176, -1.942084],
                [9.318750000000136, -1.942084],
                [9.319582, -1.942916],
                [9.319582, -1.943471999999815],
                [9.320138, -1.94375],
                [9.320416000000137, -1.944582],
                [9.32125, -1.944582],
                [9.32125, -1.94375],
                [9.320694, -1.942637999999818],
                [9.320138, -1.942359999999894],
                [9.320138, -1.941527999999892],
                [9.320694, -1.941527999999892],
                [9.320972000000154, -1.942359999999894],
                [9.321528000000114, -1.942637999999818],
                [9.322085, -1.944027999999889],
                [9.322085, -1.946251],
                [9.322638000000154, -1.945694999999887],
                [9.32347300000015, -1.945694999999887],
                [9.323751, -1.946251],
                [9.324304000000154, -1.946251],
                [9.324304000000154, -1.947360999999887],
                [9.32513900000015, -1.949583],
                [9.32569500000011, -1.949583],
                [9.325973, -1.951251],
                [9.32680500000015, -1.951526999999885],
                [9.327083, -1.952360999999883],
                [9.327917000000127, -1.952360999999883],
                [9.329029000000105, -1.952917],
                [9.329029000000105, -1.954026999999883],
                [9.329861, -1.954305],
                [9.330139000000145, -1.954861],
                [9.331527, -1.956249],
                [9.331805000000145, -1.957361],
                [9.332639, -1.957361],
                [9.332917000000123, -1.958471],
                [9.334027000000106, -1.959584],
                [9.334583000000123, -1.959584],
                [9.334861, -1.960139999999853],
                [9.335417, -1.960139999999853],
                [9.335971, -1.960694],
                [9.335971, -1.961528],
                [9.336527, -1.962084],
                [9.337362, -1.962084],
                [9.337362, -1.962638],
                [9.338472, -1.964028],
                [9.339306, -1.964028],
                [9.339306, -1.964584],
                [9.340418000000113, -1.965416],
                [9.341250000000173, -1.967084],
                [9.342362, -1.968194],
                [9.343194, -1.968194],
                [9.344028, -1.969306],
                [9.344028, -1.96986],
                [9.344584000000168, -1.970138],
                [9.344584000000168, -1.970694],
                [9.34513800000019, -1.970972],
                [9.346528, -1.97236],
                [9.346528, -1.972917],
                [9.347082000000114, -1.973195],
                [9.347082000000114, -1.974029],
                [9.347638, -1.974029],
                [9.348472000000186, -1.975138999999899],
                [9.34875, -1.97625099999982],
                [9.34986, -1.97625099999982],
                [9.350416, -1.977638999999897],
                [9.350973000000181, -1.97791699999982],
                [9.352083000000164, -1.97791699999982],
                [9.352917, -1.978195],
                [9.352917, -1.976804999999899],
                [9.353473, -1.97625099999982],
                [9.354305000000181, -1.975972999999897],
                [9.35597300000012, -1.975972999999897],
                [9.35597300000012, -1.977082999999823],
                [9.355695, -1.977361],
                [9.354583, -1.977361],
                [9.353751000000159, -1.97791699999982],
                [9.353751000000159, -1.979027],
                [9.353473, -1.979861],
                [9.354027, -1.980972999999892],
                [9.354861000000142, -1.981248999999821],
                [9.354861000000142, -1.982082999999875],
                [9.355695, -1.982361],
                [9.355695, -1.982916999999873],
                [9.35708300000016, -1.984304999999893],
                [9.35763900000012, -1.984304999999893],
                [9.35763900000012, -1.985139999999888],
                [9.358471, -1.985970999999893],
                [9.358471, -1.987362],
                [9.358751000000154, -1.988471999999888],
                [9.360695, -1.990415999999868],
                [9.360973000000115, -1.990972],
                [9.361527000000137, -1.990972],
                [9.362639000000115, -1.992084],
                [9.362639000000115, -1.993194],
                [9.363196, -1.994584],
                [9.364027, -1.995138],
                [9.364862, -1.995138],
                [9.36514000000011, -1.995694],
                [9.365694000000133, -1.995694],
                [9.36625000000015, -1.996806],
                [9.367638, -1.998194],
                [9.367638, -1.998750999999857],
                [9.368194, -1.999582],
                [9.369028000000128, -1.999582],
                [9.369584, -2.000139],
                [9.369584, -2.000973],
                [9.370694000000128, -2.002361],
                [9.37125, -2.002361],
                [9.371528, -2.002917],
                [9.372084, -2.002917],
                [9.372362000000123, -2.003473],
                [9.373472000000106, -2.004305],
                [9.373472000000106, -2.004861],
                [9.374028000000123, -2.005139],
                [9.374028000000123, -2.005695],
                [9.374582, -2.005973],
                [9.374582, -2.006527],
                [9.376804000000106, -2.008749],
                [9.377360000000124, -2.008749],
                [9.378195000000119, -2.010417],
                [9.379026000000124, -2.011249],
                [9.379026000000124, -2.012083],
                [9.379583, -2.012361999999826],
                [9.379583, -2.012918],
                [9.380139, -2.01319599999988],
                [9.380139, -2.01375],
                [9.380695000000173, -2.014027999999826],
                [9.380695000000173, -2.01514],
                [9.379861000000119, -2.01514],
                [9.379583, -2.014027999999826],
                [9.379026000000124, -2.014027999999826],
                [9.378473, -2.013471],
                [9.378473, -2.012918],
                [9.377360000000124, -2.011805],
                [9.376804000000106, -2.011805],
                [9.375972, -2.010971],
                [9.375972, -2.010139],
                [9.375416, -2.009861],
                [9.374582, -2.008749],
                [9.374306, -2.007639],
                [9.37375, -2.007361],
                [9.37375, -2.006805],
                [9.372638, -2.005695],
                [9.372638, -2.005139],
                [9.371528, -2.004027],
                [9.370416, -2.002639],
                [9.36986, -2.002639],
                [9.369306, -2.002082999999857],
                [9.369306, -2.001529],
                [9.367918, -2.000416999999857],
                [9.367918, -1.99986],
                [9.366528, -1.998750999999857],
                [9.36625000000015, -1.997916],
                [9.364862, -1.997916],
                [9.364862, -1.99736],
                [9.364306, -1.996806],
                [9.363749000000155, -1.996806],
                [9.363471, -1.99625],
                [9.362639000000115, -1.995972],
                [9.362639000000115, -1.995416],
                [9.362083000000155, -1.994306],
                [9.361527000000137, -1.99264],
                [9.360695, -1.99264],
                [9.360695, -1.992084],
                [9.35930500000012, -1.990972],
                [9.35930500000012, -1.99014],
                [9.358751000000154, -1.99014],
                [9.356805, -1.98764],
                [9.35597300000012, -1.987083999999868],
                [9.35597300000012, -1.986528],
                [9.354583, -1.985417999999868],
                [9.354583, -1.984861],
                [9.354027, -1.984582999999873],
                [9.353751000000159, -1.983470999999895],
                [9.352917, -1.983195],
                [9.352639000000181, -1.982361],
                [9.352083000000164, -1.982082999999875],
                [9.352083000000164, -1.981248999999821],
                [9.351529000000141, -1.980695],
                [9.351529000000141, -1.980138999999895],
                [9.350416, -1.979304999999897],
                [9.34875, -1.977638999999897],
                [9.348472000000186, -1.976527],
                [9.347916000000168, -1.97625099999982],
                [9.34736, -1.974861],
                [9.346528, -1.974861],
                [9.346528, -1.974305],
                [9.345416000000114, -1.973195],
                [9.34486, -1.972082],
                [9.344028, -1.97125],
                [9.34347200000019, -1.97125],
                [9.342916000000173, -1.970416],
                [9.342916000000173, -1.96986],
                [9.342362, -1.96875],
                [9.34180600000019, -1.968194],
                [9.340972, -1.967916],
                [9.340972, -1.96736],
                [9.339862, -1.96625],
                [9.339028, -1.965971999999852],
                [9.339028, -1.965416],
                [9.33764, -1.964305999999851],
                [9.33764, -1.96375],
                [9.336805, -1.962918],
                [9.336249000000123, -1.962916],
                [9.334861, -1.961528],
                [9.334583000000123, -1.960415],
                [9.333195, -1.959862],
                [9.333471000000145, -1.96125],
                [9.334861, -1.962638],
                [9.335417, -1.962916],
                [9.335417, -1.963471999999854],
                [9.336527, -1.964584],
                [9.337362, -1.965137999999854],
                [9.337362, -1.965694],
                [9.338472, -1.966806],
                [9.338750000000118, -1.96736],
                [9.339862, -1.967916],
                [9.339862, -1.968472],
                [9.341528, -1.970138],
                [9.342084000000114, -1.970416],
                [9.34264, -1.971806999999899],
                [9.34347200000019, -1.972082],
                [9.34347200000019, -1.972917],
                [9.34513800000019, -1.974583],
                [9.345972, -1.975695],
                [9.345972, -1.97625099999982],
                [9.347082000000114, -1.977361],
                [9.347362, -1.97791699999982],
                [9.347916000000168, -1.97791699999982],
                [9.349028, -1.979304999999897],
                [9.349304, -1.980416999999875],
                [9.34986, -1.980695],
                [9.350973000000181, -1.981804999999895],
                [9.350973000000181, -1.982361],
                [9.351807, -1.982638999999892],
                [9.352589, -1.984301],
                [9.353195000000142, -1.984304999999893],
                [9.353473, -1.985139999999888],
                [9.354583, -1.985970999999893],
                [9.354583, -1.986528],
                [9.355139, -1.98764],
                [9.355695, -1.98764],
                [9.35597300000012, -1.988471999999888],
                [9.35708300000016, -1.989306],
                [9.358471, -1.990694],
                [9.358471, -1.99125],
                [9.359583, -1.992638],
                [9.360695, -1.993194],
                [9.360695, -1.99375],
                [9.361527000000137, -1.994028],
                [9.361527000000137, -1.995416],
                [9.362083000000155, -1.995694],
                [9.362083000000155, -1.99625],
                [9.362639000000115, -1.996806],
                [9.363196, -1.996806],
                [9.363471, -1.99736],
                [9.364306, -1.997638],
                [9.365972, -1.999582],
                [9.366528, -1.999582],
                [9.367084, -2.000139],
                [9.367084, -2.000695],
                [9.368194, -2.001529],
                [9.36847200000011, -2.002361],
                [9.369306, -2.002639],
                [9.369584, -2.003751],
                [9.370416, -2.004305],
                [9.372362000000123, -2.006805],
                [9.372638, -2.007639],
                [9.373194, -2.007639],
                [9.373194, -2.008473],
                [9.37486, -2.009583],
                [9.375138000000106, -2.010694999999828],
                [9.375972, -2.011527],
                [9.375972, -2.012361999999826],
                [9.376804000000106, -2.01319599999988],
                [9.376804000000106, -2.01375],
                [9.377917, -2.01514],
                [9.379026000000124, -2.01625],
                [9.379307, -2.016806],
                [9.380139, -2.017361999999878],
                [9.380139, -2.017916],
                [9.382083, -2.019859999999881],
                [9.383195, -2.020693999999878],
                [9.383473, -2.021806],
                [9.384305, -2.022359999999878],
                [9.385417, -2.023472],
                [9.385695000000169, -2.024581999999896],
                [9.386527, -2.025138],
                [9.386805, -2.025693999999874],
                [9.388193, -2.027081999999894],
                [9.388193, -2.027639],
                [9.391249000000187, -2.030695],
                [9.391249000000187, -2.031251],
                [9.392640000000142, -2.032361],
                [9.392918, -2.033473],
                [9.394028, -2.034305],
                [9.394028, -2.034860999999864],
                [9.395694, -2.036526999999865],
                [9.39652800000016, -2.037639],
                [9.39652800000016, -2.038192999999865],
                [9.397916, -2.039305],
                [9.397916, -2.039862],
                [9.39875000000012, -2.040418],
                [9.39875000000012, -2.040972],
                [9.399862000000155, -2.041528],
                [9.399862000000155, -2.04264],
                [9.400138, -2.043194],
                [9.400972000000138, -2.04375],
                [9.400972000000138, -2.044306],
                [9.401528000000155, -2.044584],
                [9.401528000000155, -2.045138],
                [9.402084000000116, -2.045694],
                [9.402638000000138, -2.045694],
                [9.402916, -2.04625],
                [9.403751, -2.046528],
                [9.403751, -2.047084],
                [9.405139000000133, -2.048471999999833],
                [9.405973, -2.04875],
                [9.405973, -2.049305999999831],
                [9.406536, -2.049584],
                [9.407083, -2.050416],
                [9.407639, -2.050416],
                [9.408473000000129, -2.051806999999883],
                [9.411527, -2.051806999999883],
                [9.411527, -2.05236],
                [9.414027, -2.05236],
                [9.414027, -2.050971999999831],
                [9.415139000000124, -2.051806999999883],
                [9.415417, -2.052637999999831],
                [9.414861, -2.053195],
                [9.411805000000129, -2.053195],
                [9.411251000000107, -2.053751],
                [9.411251000000107, -2.054583],
                [9.411805000000129, -2.054861],
                [9.411805000000129, -2.055417],
                [9.412639000000183, -2.055695],
                [9.413195, -2.056251],
                [9.413195, -2.056804999999883],
                [9.413749, -2.057361],
                [9.414305000000184, -2.057361],
                [9.416527, -2.059583],
                [9.417083, -2.059583],
                [9.41764, -2.060138999999879],
                [9.41764, -2.060695],
                [9.418196, -2.061249],
                [9.419584, -2.061804999999879],
                [9.419584, -2.062361],
                [9.420140000000174, -2.062638999999876],
                [9.420140000000174, -2.063195],
                [9.422362, -2.064861],
                [9.422362, -2.065418],
                [9.423472000000174, -2.066249],
                [9.425416, -2.068194],
                [9.425972, -2.068471999999872],
                [9.425972, -2.069028],
                [9.42680600000017, -2.069584],
                [9.42847200000017, -2.07125],
                [9.42902800000013, -2.07125],
                [9.429585, -2.072362],
                [9.43013800000017, -2.072916],
                [9.43069400000013, -2.072916],
                [9.430973000000165, -2.073471999999867],
                [9.431529000000126, -2.073471999999867],
                [9.431807, -2.074028],
                [9.432917, -2.074306],
                [9.433195000000126, -2.075137999999868],
                [9.434861000000126, -2.075137999999868],
                [9.435417000000143, -2.074306],
                [9.435695, -2.07264],
                [9.434861000000126, -2.072084],
                [9.434861000000126, -2.071528],
                [9.434305000000165, -2.07125],
                [9.433473, -2.070139999999867],
                [9.432639000000165, -2.070139999999867],
                [9.431529000000126, -2.069584],
                [9.431807, -2.069028],
                [9.433473, -2.06930599999987],
                [9.433751000000143, -2.069862],
                [9.434305000000165, -2.069862],
                [9.435695, -2.071528],
                [9.435695, -2.072084],
                [9.436529000000121, -2.072362],
                [9.436529000000121, -2.07375],
                [9.43597300000016, -2.07486],
                [9.434861000000126, -2.075972],
                [9.434305000000165, -2.07625],
                [9.434305000000165, -2.076806],
                [9.434861000000126, -2.077084],
                [9.434861000000126, -2.077638],
                [9.435417000000143, -2.078194],
                [9.43597300000016, -2.078194],
                [9.436249, -2.07875],
                [9.437361, -2.079307],
                [9.437361, -2.07986],
                [9.438195000000121, -2.080417],
                [9.439861000000121, -2.082083],
                [9.440973, -2.083473],
                [9.440973, -2.084029],
                [9.442083000000139, -2.085139],
                [9.442639, -2.085139],
                [9.442917, -2.086805],
                [9.443471, -2.087361],
                [9.444862, -2.087916999999891],
                [9.446250000000134, -2.087916999999891],
                [9.446806, -2.088473],
                [9.446806, -2.090695],
                [9.447638, -2.091248999999891],
                [9.447638, -2.091805],
                [9.449306, -2.093471],
                [9.449306, -2.094028],
                [9.449862, -2.094306],
                [9.451528, -2.095972],
                [9.451528, -2.096528],
                [9.452362000000107, -2.097362],
                [9.45291600000013, -2.097362],
                [9.453472, -2.097915999999884],
                [9.453472, -2.098472],
                [9.454306, -2.099306],
                [9.45486, -2.099306],
                [9.455694000000108, -2.100138],
                [9.455694000000108, -2.100694],
                [9.456528, -2.100972],
                [9.457085, -2.102083999999877],
                [9.457916, -2.102362],
                [9.458751, -2.103194],
                [9.458751, -2.103749999999877],
                [9.460139, -2.10486],
                [9.461805, -2.106529],
                [9.462083, -2.106529],
                [9.463473, -2.107916999999873],
                [9.463473, -2.108473],
                [9.465417, -2.110417],
                [9.46625100000017, -2.110695],
                [9.46625100000017, -2.111251],
                [9.466805, -2.111527],
                [9.466805, -2.112083],
                [9.468749, -2.113749],
                [9.470139000000131, -2.115695],
                [9.470696, -2.115695],
                [9.47124900000017, -2.116527],
                [9.47124900000017, -2.117083],
                [9.472362, -2.117917],
                [9.473194000000149, -2.118194999999844],
                [9.473194000000149, -2.118749],
                [9.474028, -2.119305],
                [9.475694, -2.120971],
                [9.47625, -2.120971],
                [9.47736, -2.122361999999896],
                [9.47736, -2.122918],
                [9.478194000000144, -2.123193999999842],
                [9.478472, -2.124027999999896],
                [9.480416000000105, -2.125972],
                [9.48125, -2.12625],
                [9.48125, -2.126806],
                [9.482638000000122, -2.127084],
                [9.484304000000122, -2.129306],
                [9.485973, -2.130416],
                [9.485973, -2.130972],
                [9.48652600000014, -2.13125],
                [9.48652600000014, -2.131805999999813],
                [9.487639, -2.132916],
                [9.48819500000019, -2.132916],
                [9.489305, -2.134304],
                [9.48986100000019, -2.134304],
                [9.490139000000113, -2.134860999999887],
                [9.490973, -2.135138999999811],
                [9.490973, -2.135694999999885],
                [9.49152700000019, -2.136251],
                [9.492083, -2.136251],
                [9.493471000000113, -2.137917],
                [9.494583, -2.138473],
                [9.495139000000108, -2.139026999999885],
                [9.495693, -2.139026999999885],
                [9.497915, -2.141249],
                [9.498471000000109, -2.141249],
                [9.49902800000018, -2.14236099999988],
                [9.499584, -2.142639],
                [9.500418, -2.143749],
                [9.500972000000104, -2.14402699999988],
                [9.501528, -2.145139],
                [9.502362000000176, -2.146249],
                [9.503194, -2.146805],
                [9.504584, -2.148192999999878],
                [9.505694000000176, -2.149862],
                [9.50625, -2.15014],
                [9.50625, -2.150694],
                [9.507082, -2.151528],
                [9.507638, -2.151528],
                [9.509028000000171, -2.152916],
                [9.509028000000171, -2.153194],
                [9.512360000000172, -2.156528],
                [9.513473, -2.15736],
                [9.513473, -2.157916],
                [9.514583, -2.159028],
                [9.515139, -2.159306999999899],
                [9.515973000000145, -2.160416],
                [9.516249, -2.161251],
                [9.516805, -2.161251],
                [9.517917, -2.162361],
                [9.517917, -2.162917],
                [9.518751000000123, -2.163751],
                [9.519583, -2.164027],
                [9.519583, -2.164861],
                [9.520139, -2.165417],
                [9.520695, -2.165417],
                [9.521249, -2.165972999999895],
                [9.521249, -2.166527],
                [9.522917, -2.167638999999895],
                [9.524027, -2.168749],
                [9.524027, -2.169027],
                [9.525415000000123, -2.170416999999816],
                [9.525696, -2.170970999999895],
                [9.527084, -2.172361],
                [9.527362, -2.172361],
                [9.528472, -2.173470999999893],
                [9.528472, -2.174027],
                [9.52930600000019, -2.174583999999868],
                [9.530416, -2.175694],
                [9.530694, -2.175694],
                [9.532084000000168, -2.177084],
                [9.53263800000019, -2.178194],
                [9.53263800000019, -2.17875],
                [9.533750000000168, -2.179862],
                [9.534306000000186, -2.179862],
                [9.534582000000114, -2.18125],
                [9.53625000000011, -2.182637999999884],
                [9.536804, -2.183472],
                [9.53736, -2.183472],
                [9.537638000000186, -2.184582],
                [9.538194, -2.18486],
                [9.538194, -2.185416],
                [9.539029, -2.18625],
                [9.53958200000011, -2.18625],
                [9.540973, -2.187638],
                [9.540973, -2.188195],
                [9.542083, -2.189304],
                [9.542639, -2.189304],
                [9.543195, -2.189861],
                [9.543195, -2.190416999999854],
                [9.547361000000137, -2.194582999999852],
                [9.548195, -2.195695],
                [9.548195, -2.196248999999853],
                [9.548749, -2.196527],
                [9.549583000000155, -2.197639],
                [9.549583000000155, -2.198195],
                [9.550417, -2.199027],
                [9.551249000000155, -2.199305],
                [9.551249000000155, -2.199861],
                [9.552362, -2.200971],
                [9.552915000000155, -2.200971],
                [9.554028, -2.202084],
                [9.554028, -2.20264],
                [9.554584, -2.203472],
                [9.555418000000145, -2.20375],
                [9.555418000000145, -2.20514],
                [9.55625, -2.205972],
                [9.55625, -2.206806],
                [9.557084000000145, -2.206806],
                [9.557084000000145, -2.207361999999876],
                [9.557640000000106, -2.207638],
                [9.557640000000106, -2.208193999999821],
                [9.558750000000146, -2.209306],
                [9.558750000000146, -2.209859999999821],
                [9.559306000000106, -2.210138],
                [9.559306000000106, -2.210693999999876],
                [9.559862000000123, -2.211527999999873],
                [9.560416000000146, -2.211527999999873],
                [9.560694, -2.212083999999891],
                [9.561806, -2.213193999999874],
                [9.561806, -2.213750999999888],
                [9.563471795000112, -2.215694904999964],
                [9.563750268000149, -2.217082977999951],
                [9.564304351000033, -2.217082977999951],
                [9.564584732000071, -2.21763896899995],
                [9.565137863000075, -2.21763896899995],
                [9.565137863000075, -2.21847295799995],
                [9.565694808999979, -2.21902704199988],
                [9.565694808999979, -2.219583034999971],
                [9.566250800000091, -2.21986103099988],
                [9.566250800000091, -2.220695018999948],
                [9.56708335900015, -2.22097301499997],
                [9.567639350000036, -2.221527098999957],
                [9.567639350000036, -2.222083091999878],
                [9.568195343000127, -2.223195076999957],
                [9.56902885500017, -2.223470926999937],
                [9.569305420000035, -2.224582909999924],
                [9.570973396000056, -2.225970983999957],
                [9.570973396000056, -2.226804970999865],
                [9.573195458000043, -2.229027984999959],
                [9.573471068000117, -2.230139969999925],
                [9.574027062000084, -2.230694055999948],
                [9.574027062000084, -2.231528043999958],
                [9.574583053000026, -2.232084035999947],
                [9.57513904600006, -2.232084035999947],
                [9.575972558000103, -2.233472107999944],
                [9.575972558000103, -2.234028101999854],
                [9.576805115000184, -2.23458409299991],
                [9.577638626000123, -2.236249923999935],
                [9.577638626000123, -2.237083911999889],
                [9.578471183000033, -2.237915991999842],
                [9.578471183000033, -2.238471984999933],
                [9.579027176000068, -2.239582061999954],
                [9.579584121999972, -2.23986005799992],
                [9.579861640000047, -2.240972994999822],
                [9.580417634000014, -2.241250990999902],
                [9.581250190000162, -2.242917060999901],
                [9.581806184000186, -2.24319505699998],
                [9.582362175000071, -2.24458289099988],
                [9.582362175000071, -2.245417117999978],
                [9.583472251999979, -2.246251106999978],
                [9.583472251999979, -2.24680495299998],
                [9.584028243000091, -2.247082948999889],
                [9.584028243000091, -2.248194932999979],
                [9.584859847000132, -2.249027013999978],
                [9.584859847000132, -2.250416994999966],
                [9.585415840000053, -2.250694989999886],
                [9.585415840000053, -2.252083062999873],
                [9.58514022800017, -2.253470897999875],
                [9.586527825000132, -2.254584072999876],
                [9.586527825000132, -2.255140065999967],
                [9.58569431300009, -2.255418061999876],
                [9.58569431300009, -2.257639885999936],
                [9.586250305000078, -2.258749962999957],
                [9.586250305000078, -2.260416030999977],
                [9.586527825000132, -2.260416030999977],
                [9.586527825000132, -2.262360094999963],
                [9.58708190900012, -2.264306067999939],
                [9.58708190900012, -2.265693902999942],
                [9.586250305000078, -2.26652788999985],
                [9.586250305000078, -2.269582032999949],
                [9.58708190900012, -2.271250963999933],
                [9.587083816000018, -2.27347302499993],
                [9.58763790200004, -2.273751020999839],
                [9.58763790200004, -2.275973081999837],
                [9.588193893000152, -2.27624893299992],
                [9.587916375000077, -2.277360914999917],
                [9.587916375000077, -2.279026984999916],
                [9.58763790200004, -2.279304980999939],
                [9.586250305000078, -2.279304980999939],
                [9.585971832000041, -2.2806959159999],
                [9.58680629800017, -2.281805037999902],
                [9.58680629800017, -2.282639979999885],
                [9.587362289000055, -2.284306047999905],
                [9.587916375000077, -2.284584045999907],
                [9.587916375000077, -2.286528109999892],
                [9.588193893000152, -2.287637948999873],
                [9.588749886000016, -2.288193939999871],
                [9.588749886000016, -2.289861917999872],
                [9.58847236600019, -2.291527985999949],
                [9.58847236600019, -2.29263806299997],
                [9.58680629800017, -2.294306993999953],
                [9.585971832000041, -2.29485988499988],
                [9.585971832000041, -2.296251057999939],
                [9.58569431300009, -2.297082900999953],
                [9.586250305000078, -2.297638892999942],
                [9.58680629800017, -2.299026964999939],
                [9.58680629800017, -2.300972938999962],
                [9.586527825000132, -2.301805019999961],
                [9.585971832000041, -2.30236101099996],
                [9.585971832000041, -2.303749083999946],
                [9.585415840000053, -2.304027079999969],
                [9.58569431300009, -2.305417059999968],
                [9.58569431300009, -2.309305906999839],
                [9.585971832000041, -2.310417890999929],
                [9.586527825000132, -2.310971974999916],
                [9.586527825000132, -2.317359923999902],
                [9.58680629800017, -2.317359923999902],
                [9.58680629800017, -2.319305895999889],
                [9.58708190900012, -2.320693969999979],
                [9.586527825000132, -2.321806907999871],
                [9.586527825000132, -2.323194979999812],
                [9.58708190900012, -2.32375097299996],
                [9.58708190900012, -2.32652711899982],
                [9.58680629800017, -2.32680511399991],
                [9.58680629800017, -2.327917098999876],
                [9.587083816000018, -2.328748940999958],
                [9.58763790200004, -2.329304933999879],
                [9.58763790200004, -2.333194970999955],
                [9.587362289000055, -2.333194970999955],
                [9.587362289000055, -2.336249111999962],
                [9.58680629800017, -2.337362050999957],
                [9.58680629800017, -2.339584112999944],
                [9.587083816000018, -2.340415954999969],
                [9.58763790200004, -2.340693949999945],
                [9.58763790200004, -2.341249942999923],
                [9.588749886000016, -2.342360018999955],
                [9.589028358000178, -2.343194006999966],
                [9.589859961000116, -2.343750000999933],
                [9.590138435000085, -2.34513807299993],
                [9.590971946000025, -2.345694064999861],
                [9.591807364999966, -2.346806048999952],
                [9.592359542999986, -2.347915887999932],
                [9.592359542999986, -2.348751067999842],
                [9.593473434999964, -2.349859951999918],
                [9.59402561200011, -2.352082966999944],
                [9.595138549000069, -2.353194950999978],
                [9.595973015000027, -2.354305027999942],
                [9.596804619000068, -2.354305027999942],
                [9.597639083000047, -2.355695007999827],
                [9.598195077000014, -2.356251001999908],
                [9.598751068000126, -2.356251001999908],
                [9.599582672000167, -2.357083082999907],
                [9.599861145000034, -2.358195064999904],
                [9.601248740000187, -2.358470915999931],
                [9.601248740000187, -2.359860895999873],
                [9.602360725000096, -2.360696076999886],
                [9.602639198000134, -2.361804961999894],
                [9.603193283000053, -2.362082957999974],
                [9.603472709000073, -2.363193034999824],
                [9.604305268000132, -2.363749980999899],
                [9.604305268000132, -2.365139960999954],
                [9.604861259000018, -2.365694045999874],
                [9.605139732000055, -2.366806030999953],
                [9.605695724000043, -2.367084026999976],
                [9.605971336000152, -2.368194103999883],
                [9.606528282000056, -2.368750094999882],
                [9.606528282000056, -2.370137929999885],
                [9.607640267000136, -2.372915982999871],
                [9.608194351000122, -2.373193977999961],
                [9.608194351000122, -2.373749971999871],
                [9.609306335000099, -2.37458205299987],
                [9.60986232800019, -2.376250981999931],
                [9.61069393200006, -2.378195046999963],
                [9.61069393200006, -2.379029035999963],
                [9.611528397000143, -2.379304884999954],
                [9.612915992000069, -2.380695104999973],
                [9.612915992000069, -2.381251095999858],
                [9.61347198500016, -2.381526945999951],
                [9.61347198500016, -2.382082938999929],
                [9.61513805300018, -2.38291692599995],
                [9.61513805300018, -2.384027002999858],
                [9.615971565000052, -2.386249064999959],
                [9.616527558000143, -2.386527060999924],
                [9.617360115000167, -2.388470888999905],
                [9.617360115000167, -2.389305114999957],
                [9.617916108000088, -2.389584063999905],
                [9.617916108000088, -2.39014005699994],
                [9.618472098999973, -2.390692947999924],
                [9.618472098999973, -2.392640113999903],
                [9.619029045000048, -2.392640113999903],
                [9.61985969500006, -2.393193959999906],
                [9.620139122000182, -2.393749951999894],
                [9.620139122000182, -2.394861935999813],
                [9.62069511400017, -2.395416021999893],
                [9.621251107000091, -2.395416021999893],
                [9.621805191000078, -2.396250008999914],
                [9.622082711000132, -2.397360085999935],
                [9.622638702000017, -2.397638080999911],
                [9.622638702000017, -2.398194074999935],
                [9.623194694, -2.398472069999912],
                [9.623194694, -2.399028062999889],
                [9.62430477100014, -2.401527880999879],
                [9.624860764000061, -2.402081966999901],
                [9.625695228000154, -2.402359961999878],
                [9.625695228000154, -2.40291595399998],
                [9.627361298000153, -2.404582976999961],
                [9.627638817000104, -2.406250953999972],
                [9.628748894000069, -2.407361028999958],
                [9.629583358000161, -2.408473013999981],
                [9.630139351000082, -2.408473013999981],
                [9.6304168690001, -2.409583090999888],
                [9.632082940000032, -2.41124892199997],
                [9.632639886000106, -2.41124892199997],
                [9.633749963000014, -2.412082909999867],
                [9.634861945000011, -2.412082909999867],
                [9.635417938000103, -2.413194894999947],
                [9.636249541999973, -2.413470982999968],
                [9.637361527000053, -2.414582967999934],
                [9.63764, -2.414582967999934],
                [9.639862062000134, -2.416805981999858],
                [9.64013767199998, -2.417639970999915],
                [9.640693666000175, -2.417639970999915],
                [9.64124965700006, -2.41847205199997],
                [9.642359734000024, -2.419306038999935],
                [9.643750191000038, -2.419306038999935],
                [9.644860268000116, -2.420416115999956],
                [9.646250724000083, -2.420694111999865],
                [9.646806717000175, -2.421806095999955],
                [9.646806717000175, -2.422362088999932],
                [9.647360801000161, -2.422915935999924],
                [9.647360801000161, -2.4245839109999],
                [9.648472784999967, -2.425971983999943],
                [9.649305344000027, -2.426249981999945],
                [9.649584770000047, -2.426805972999944],
                [9.650417329000106, -2.427360058999852],
                [9.652639388000011, -2.429582117999928],
                [9.653195381000103, -2.429860115999929],
                [9.65486145, -2.431529043999888],
                [9.655973434000032, -2.432917116999931],
                [9.656526565000036, -2.432917116999931],
                [9.656805038000073, -2.433473109999909],
                [9.657638550000115, -2.433751105999932],
                [9.659584046000134, -2.435416935999967],
                [9.660140037000076, -2.435416935999967],
                [9.66041755700013, -2.435972929999878],
                [9.660971641000117, -2.435972929999878],
                [9.662083626000026, -2.436805008999897],
                [9.662918090000119, -2.437916993999977],
                [9.663749694000046, -2.437916993999977],
                [9.664028167000083, -2.438749074999976],
                [9.664584160000175, -2.439305065999974],
                [9.665415764000045, -2.439583063999976],
                [9.665415764000045, -2.440417050999884],
                [9.666250227999967, -2.441248893999955],
                [9.667362213000047, -2.441526889999977],
                [9.667637826000032, -2.442082880999976],
                [9.669305801000121, -2.443748951999964],
                [9.669859885000108, -2.443748951999964],
                [9.670971870000017, -2.444583892999958],
                [9.672081947000152, -2.445971965999945],
                [9.672916413000053, -2.445971965999945],
                [9.675695419000135, -2.448472022999965],
                [9.676528930000075, -2.448472022999965],
                [9.677083016000097, -2.449306011999965],
                [9.678750992000118, -2.450416086999951],
                [9.679026604000171, -2.450972079999872],
                [9.67958259600016, -2.450972079999872],
                [9.680417060000082, -2.452084064999951],
                [9.6818046570001, -2.452359914999931],
                [9.682360648000156, -2.45291590599993],
                [9.682360648000156, -2.453471899999954],
                [9.6837491980001, -2.454583881999952],
                [9.6837491980001, -2.455137967999974],
                [9.68486118300001, -2.455971956999861],
                [9.685138703000064, -2.456528900999842],
                [9.68569564700016, -2.456528900999842],
                [9.685971260000144, -2.457082033999939],
                [9.688194274000011, -2.459305047999862],
                [9.689027786000054, -2.459305047999862],
                [9.69013977000003, -2.460139034999941],
                [9.69013977000003, -2.460695027999918],
                [9.690693856000053, -2.460973023999941],
                [9.690693856000053, -2.461527109999963],
                [9.691528320000032, -2.461527109999963],
                [9.691528320000032, -2.462083100999962],
                [9.692359924000073, -2.462639091999961],
                [9.69291591600006, -2.462639091999961],
                [9.694027900000037, -2.46374893199993],
                [9.694027900000037, -2.464304924999908],
                [9.69597244300013, -2.466248988999951],
                [9.696249962000081, -2.467360972999927],
                [9.696805955000173, -2.467638968999893],
                [9.69736003900016, -2.468749045999914],
                [9.697916030000044, -2.468749045999914],
                [9.698193550000099, -2.469305038999835],
                [9.69874954100004, -2.469305038999835],
                [9.69902896900004, -2.46986198299993],
                [9.70069503700006, -2.471528052999929],
                [9.701251031000027, -2.471528052999929],
                [9.701528549000102, -2.472084044999974],
                [9.702082635000124, -2.472084044999974],
                [9.702361108000161, -2.472640037999895],
                [9.702917099000047, -2.472640037999895],
                [9.704029084000126, -2.474028110999825],
                [9.704860688, -2.474306105999915],
                [9.704860688, -2.474862098999893],
                [9.708748817000128, -2.478749989999869],
                [9.709583282000153, -2.479860066999891],
                [9.71069526600013, -2.480416059999811],
                [9.711805343000037, -2.481528043999958],
                [9.712361336000129, -2.481528043999958],
                [9.712915420000172, -2.482081889999961],
                [9.713471413000093, -2.483194112999968],
                [9.713471413000093, -2.483751058999872],
                [9.716250419000119, -2.486526965999872],
                [9.71680641200004, -2.486526965999872],
                [9.71847152800018, -2.488472937999973],
                [9.719584465000139, -2.489027022999949],
                [9.720137595999972, -2.490139007999972],
                [9.721249581000052, -2.491249084999879],
                [9.721805572000164, -2.492083071999957],
                [9.722361565000028, -2.492083071999957],
                [9.722638131, -2.492917060999957],
                [9.723471642000163, -2.493473051999956],
                [9.723471642000163, -2.494026899999938],
                [9.72569370400015, -2.495692967999958],
                [9.726529121000112, -2.496526956999958],
                [9.726529121000112, -2.49708390099994],
                [9.72847270900013, -2.499027966999847],
                [9.72902870200005, -2.499027966999847],
                [9.72930526800019, -2.499861955999904],
                [9.730973243999983, -2.500972032999925],
                [9.730973243999983, -2.501528023999924],
                [9.73152732900013, -2.501806019999947],
                [9.732083321000118, -2.502916096999854],
                [9.732639312000174, -2.502916096999854],
                [9.733470916000044, -2.504028080999945],
                [9.734026908999965, -2.50430607699991],
                [9.73486137399999, -2.505693911999913],
                [9.73624897100018, -2.507083891999969],
                [9.73652744399999, -2.507083891999969],
                [9.737083435000102, -2.508193968999933],
                [9.738471030000085, -2.50958204199992],
                [9.739027024000052, -2.510694980999972],
                [9.739862443000163, -2.511804102999918],
                [9.740693092000072, -2.512361048999821],
                [9.740970612000126, -2.513473033999901],
                [9.742637635000108, -2.515139101999978],
                [9.744028091000075, -2.516248940999901],
                [9.745415688000037, -2.51791691699998],
                [9.746527672000184, -2.518748998999968],
                [9.746527672000184, -2.519304989999966],
                [9.74958419800015, -2.522361039999964],
                [9.7498598090001, -2.522917985999868],
                [9.751804351999965, -2.524862049999967],
                [9.752637864, -2.526527880999936],
                [9.753473283000119, -2.527084111999955],
                [9.753473283000119, -2.52791595399998],
                [9.755416868999987, -2.529305935999958],
                [9.755972863000181, -2.530416010999943],
                [9.756251336000048, -2.531527994999863],
                [9.757361413000126, -2.532638071999941],
                [9.758194924000065, -2.533194064999975],
                [9.758194924000065, -2.533750056999963],
                [9.758750915000178, -2.53402805199994],
                [9.758750915000178, -2.534584045999964],
                [9.75930500100003, -2.534859895999944],
                [9.759583474000067, -2.535972117999961],
                [9.760972977000165, -2.537081957999931],
                [9.760972977000165, -2.537637948999929],
                [9.762639045000185, -2.539028881999911],
                [9.762639045000185, -2.539582966999944],
                [9.763472557000057, -2.540416955999945],
                [9.764582634000135, -2.54069495199991],
                [9.764861107000172, -2.541527032999909],
                [9.765417100000093, -2.541527032999909],
                [9.765695571000151, -2.54208302499984],
                [9.766527175000022, -2.5423610199999],
                [9.766527175000022, -2.542917013999897],
                [9.767084121000096, -2.543473004999896],
                [9.767640114000187, -2.543473004999896],
                [9.767917633000138, -2.544028997999931],
                [9.769027710000046, -2.545139074999838],
                [9.769862176000174, -2.545139074999838],
                [9.77041626, -2.545695065999894],
                [9.77041626, -2.546248911999896],
                [9.771528244000024, -2.547360896999976],
                [9.77208423500008, -2.547638892999885],
                [9.773194311999987, -2.548748969999906],
                [9.773750306000181, -2.548748969999906],
                [9.774306297000066, -2.549304962999884],
                [9.774306297000066, -2.549860953999882],
                [9.776535034, -2.55151390899988],
                [9.777359962000048, -2.552361963999886],
                [9.778194427000074, -2.553750038999965],
                [9.780138015000148, -2.555416106999928],
                [9.781250954000143, -2.555694102999894],
                [9.782082558000184, -2.556528090999962],
                [9.782082558000184, -2.557084082999893],
                [9.782638550000172, -2.557362079999962],
                [9.782638550000172, -2.557915925999964],
                [9.783473014000151, -2.558471917999952],
                [9.78513908400015, -2.560415983999974],
                [9.78513908400015, -2.560971974999973],
                [9.786805153000046, -2.56236004699997],
                [9.786805153000046, -2.56291604099988],
                [9.787361146000137, -2.56319403599997],
                [9.788194657000076, -2.564306973999862],
                [9.789027214000157, -2.56486010499998],
                [9.789304732000176, -2.56541705099994],
                [9.790416717000085, -2.566251039999941],
                [9.79180526700003, -2.567917107999961],
                [9.79236126000012, -2.567917107999961],
                [9.792915344000164, -2.568751096999961],
                [9.794305801000178, -2.570138931999964],
                [9.795417786000087, -2.570694922999849],
                [9.79625034300011, -2.571527003999961],
                [9.796806336000031, -2.571527003999961],
                [9.79763984699997, -2.572360992999961],
                [9.79791641300011, -2.573194979999926],
                [9.799028397000143, -2.573749065999948],
                [9.799861906999979, -2.574861049999925],
                [9.800971984000114, -2.575695036999946],
                [9.802362442, -2.577083109999933],
                [9.802915573000064, -2.577918051999916],
                [9.80402755700004, -2.578470944999936],
                [9.80402755700004, -2.57874989499993],
                [9.80569362700004, -2.58041596399994],
                [9.806806565000102, -2.581249951999894],
                [9.807362556000044, -2.582361936999973],
                [9.809028626000043, -2.583472013999938],
                [9.809305192000181, -2.584583997999914],
                [9.810694694000063, -2.585416077999923],
                [9.811529159000088, -2.586806057999979],
                [9.812083244000178, -2.587084055999981],
                [9.814305306000165, -2.589306114999886],
                [9.81458282400007, -2.589859962999981],
                [9.815138817000104, -2.589859962999981],
                [9.815973282000186, -2.590972899999883],
                [9.817916870000033, -2.592916963999869],
                [9.818470954000077, -2.592916963999869],
                [9.819582939000156, -2.594028948999949],
                [9.819861411999966, -2.594583034999971],
                [9.82041835800004, -2.594583034999971],
                [9.821249009000155, -2.595417021999879],
                [9.821249009000155, -2.59597301499997],
                [9.822084428000096, -2.596251009999946],
                [9.822084428000096, -2.596805095999969],
                [9.823749541000097, -2.598473071999877],
                [9.824305535000065, -2.598473071999877],
                [9.825415612000029, -2.599860906999936],
                [9.825971603000085, -2.599860906999936],
                [9.827083587000061, -2.600694893999958],
                [9.827918052000086, -2.602082967999877],
                [9.830971717000068, -2.605139969999925],
                [9.832637787000067, -2.606250046999946],
                [9.832637787000067, -2.606806039999867],
                [9.834029198, -2.607916116999945],
                [9.834582329000057, -2.609028100999865],
                [9.835416792999979, -2.609862087999943],
                [9.83597278600007, -2.609862087999943],
                [9.836528778000059, -2.610415933999946],
                [9.836528778000059, -2.610971927999913],
                [9.83708477100015, -2.611527918999911],
                [9.837638854999966, -2.611527918999911],
                [9.837917328, -2.612083911999946],
                [9.839860916000077, -2.613749979999909],
                [9.84041690700019, -2.61486005699993],
                [9.84069538, -2.61486005699993],
                [9.842082978000064, -2.616250037999919],
                [9.842082978000064, -2.616806983999936],
                [9.84348869400003, -2.617615938999961],
                [9.843473435000135, -2.618195055999934],
                [9.845417024000085, -2.619582890999936],
                [9.848750115000087, -2.6229169369999],
                [9.849027633000105, -2.624027013999921],
                [9.84958362700013, -2.624583005999909],
                [9.850139618000014, -2.624583005999909],
                [9.85124969500015, -2.625972985999965],
                [9.852084159000071, -2.626527070999828],
                [9.852084159000071, -2.62708306299993],
                [9.853194235999979, -2.628195046999906],
                [9.85486221400015, -2.629304886999876],
                [9.857084273000055, -2.631526945999895],
                [9.85735988600004, -2.632083891999969],
                [9.858750344000157, -2.633471964999956],
                [9.859306335000042, -2.633749961999968],
                [9.86069393200006, -2.635138034999898],
                [9.86180686900019, -2.635972021999976],
                [9.862639426000044, -2.637084005999952],
                [9.863751411000123, -2.637638091999975],
                [9.864860535000105, -2.639028071999974],
                [9.86541652600016, -2.639028071999974],
                [9.865694999000027, -2.639584063999962],
                [9.866806984000107, -2.640137909999964],
                [9.867638587999977, -2.641249893999941],
                [9.869304656000168, -2.64291596399994],
                [9.870139122000126, -2.644306896999922],
                [9.871249199000033, -2.64513802599987],
                [9.871249199000033, -2.645694969999966],
                [9.871805191000021, -2.646250962999943],
                [9.872361184000113, -2.646250962999943],
                [9.872638702000188, -2.647361039999964],
                [9.873748779000096, -2.64847302499993],
                [9.874027252000133, -2.64930510399995],
                [9.875693320000153, -2.650695083999949],
                [9.87736225100008, -2.652360914999917],
                [9.878195762000075, -2.652638912999919],
                [9.878195762000075, -2.6537489899999],
                [9.87736225100008, -2.654304980999939],
                [9.87736225100008, -2.654860973999917],
                [9.878472328000043, -2.655694960999938],
                [9.879305839000153, -2.657639979999829],
                [9.879305839000153, -2.65902805199994],
                [9.879028321000135, -2.659862040999826],
                [9.87958431200019, -2.660415886999886],
                [9.87958431200019, -2.661250113999927],
                [9.88041591600006, -2.663193939999928],
                [9.880971908000049, -2.663471937999816],
                [9.880971908000049, -2.665138005999893],
                [9.88152790099997, -2.665693996999892],
                [9.882081985000184, -2.666805981999971],
                [9.882637978000105, -2.667360066999891],
                [9.882637978000105, -2.668750047999879],
                [9.883749963000184, -2.6706941139999],
                [9.88402843599999, -2.672359943999879],
                [9.884860040000092, -2.672916887999975],
                [9.885416030999977, -2.675138949999962],
                [9.885416030999977, -2.67708301599987],
                [9.88513755800011, -2.67708301599987],
                [9.88513755800011, -2.679305076999981],
                [9.884860040000092, -2.679305076999981],
                [9.884860040000092, -2.680973052999946],
                [9.88402843599999, -2.681804894999971],
                [9.88402843599999, -2.682638882999925],
                [9.882916451000142, -2.684026955999968],
                [9.882916451000142, -2.685417890999929],
                [9.88319396900016, -2.685971021999933],
                [9.88319396900016, -2.687361954999915],
                [9.883749963000184, -2.688472031999936],
                [9.88430595400007, -2.688472031999936],
                [9.88513755800011, -2.689584015999913],
                [9.885693551000031, -2.689584015999913],
                [9.886249542000144, -2.690416097999957],
                [9.887638092000088, -2.691806077999956],
                [9.88791561100004, -2.693193912999902],
                [9.889028550000035, -2.693193912999902],
                [9.889304160999984, -2.692637919999925],
                [9.890138627000113, -2.692637919999925],
                [9.89041710000015, -2.693193912999902],
                [9.891529082000147, -2.694027899999981],
                [9.89208316800017, -2.694027899999981],
                [9.892360686000018, -2.694583892999901],
                [9.892916680000042, -2.694583892999901],
                [9.893472671000097, -2.695415971999921],
                [9.89402675700012, -2.695693969999923],
                [9.89402675700012, -2.697638033999908],
                [9.895417214000133, -2.697638033999908],
                [9.89624881800006, -2.69791603199991],
                [9.89624881800006, -2.698472975999891],
                [9.897360801000104, -2.700695036999889],
                [9.897916794000025, -2.70125103],
                [9.898470878000012, -2.70125103],
                [9.899582862000045, -2.702083109999933],
                [9.899861335000082, -2.70263910299991],
                [9.900418281000157, -2.70263910299991],
                [9.90291786099999, -2.704860924999878],
                [9.903750420000051, -2.704860924999878],
                [9.904306411000164, -2.706248997999978],
                [9.90291786099999, -2.705972908999968],
                [9.90180587899999, -2.704860924999878],
                [9.901248933000147, -2.704860924999878],
                [9.899861335000082, -2.703195093999909],
                [9.898749351000049, -2.702917098999933],
                [9.898195267000062, -2.702083109999933],
                [9.897360801000104, -2.701529025999889],
                [9.895417214000133, -2.6995821],
                [9.895138741000096, -2.698472975999891],
                [9.893751144000134, -2.698472975999891],
                [9.893751144000134, -2.699028968999926],
                [9.894582748, -2.699304103999907],
                [9.894861221000042, -2.699861049999925],
                [9.89624881800006, -2.700973032999912],
                [9.89624881800006, -2.701529025999889],
                [9.897083282000153, -2.70180511399991],
                [9.897916794000025, -2.702917098999933],
                [9.899861335000082, -2.70402693799997],
                [9.90097141200016, -2.705138920999957],
                [9.901248933000147, -2.705694913999878],
                [9.902361870000107, -2.705972908999968],
                [9.90291786099999, -2.706804990999899],
                [9.904862404000028, -2.707360981999898],
                [9.905694008000125, -2.708472966999977],
                [9.906250000000114, -2.708472966999977],
                [9.907084466000072, -2.709583043999885],
                [9.907637597000075, -2.709583043999885],
                [9.908472061, -2.710692882999979],
                [9.90985965599998, -2.710971115999882],
                [9.910138129000018, -2.711805104999883],
                [9.911527634000151, -2.712918041999956],
                [9.912637711000059, -2.714027881999868],
                [9.913193702000171, -2.714027881999868],
                [9.913472175000038, -2.714584112999887],
                [9.914029121999988, -2.714584112999887],
                [9.914306640000063, -2.715140103999943],
                [9.915138244000104, -2.715693949999945],
                [9.915695190000179, -2.716527938999946],
                [9.9162511830001, -2.716527938999946],
                [9.917638778000082, -2.717639922999922],
                [9.920139313000107, -2.720138072999873],
                [9.921251297000083, -2.720416068999953],
                [9.92152690800009, -2.720972059999951],
                [9.92208290100018, -2.720972059999951],
                [9.92236137399999, -2.721528052999929],
                [9.922917366000036, -2.721528052999929],
                [9.924027443000114, -2.72263789099992],
                [9.925416945000165, -2.723193883999954],
                [9.925971031000188, -2.723750114999973],
                [9.926804543, -2.723750114999973],
                [9.928470611000023, -2.724581955999952],
                [9.929306030000134, -2.725694893999844],
                [9.930139542000177, -2.725694893999844],
                [9.931805610000026, -2.727082967999934],
                [9.932640076000155, -2.728194950999921],
                [9.933194160000141, -2.728194950999921],
                [9.933471680000025, -2.728750943999899],
                [9.934583665000105, -2.729305027999942],
                [9.934862138000142, -2.730139016999942],
                [9.935416221000082, -2.730139016999942],
                [9.937084197000047, -2.731805084999849],
                [9.938194274000125, -2.732639073999906],
                [9.93986034400018, -2.732917069999928],
                [9.93986034400018, -2.733473062999906],
                [9.940693856, -2.734304904999931],
                [9.941806793000183, -2.734582899999907],
                [9.942082406000168, -2.735416888999907],
                [9.942638397000053, -2.735416888999907],
                [9.94375133400007, -2.736526965999929],
                [9.94458293800011, -2.736526965999929],
                [9.946806908000099, -2.738749027999916],
                [9.948195458000043, -2.739027022999892],
                [9.948472976000119, -2.739583968999966],
                [9.949027062000084, -2.739583968999966],
                [9.94930458000016, -2.740139960999898],
                [9.950416564000136, -2.740417955999874],
                [9.952082635000068, -2.742084025999816],
                [9.952638626000123, -2.742362021999895],
                [9.953194619000044, -2.743472098999973],
                [9.953748703000088, -2.744028089999972],
                [9.954861640000047, -2.744306087999973],
                [9.956806183000083, -2.746249913999975],
                [9.957639695000125, -2.746805904999974],
                [9.957639695000125, -2.747361898999941],
                [9.958749772000033, -2.747637986999962],
                [9.95902824500007, -2.748193977999961],
                [9.96014022800017, -2.748471975999962],
                [9.961250304000032, -2.749027966999961],
                [9.962083816000074, -2.750416039999891],
                [9.963193893000152, -2.751250028999948],
                [9.963749886000073, -2.751250028999948],
                [9.964859963000151, -2.751806974999965],
                [9.965140341999984, -2.752360105999969],
                [9.966250419000119, -2.752917051999873],
                [9.96763801600008, -2.754304885999943],
                [9.968194007000136, -2.754304885999943],
                [9.969029426000077, -2.75541710899995],
                [9.97069454199999, -2.755973099999949],
                [9.971804619000125, -2.757082938999929],
                [9.972639083000047, -2.758194923999952],
                [9.973195076000138, -2.758194923999952],
                [9.97402668, -2.759027002999972],
                [9.97569465600003, -2.760138987999937],
                [9.976248742, -2.760972976999938],
                [9.976804733000108, -2.760972976999938],
                [9.977083206000145, -2.761527059999935],
                [9.977639198000134, -2.761527059999935],
                [9.979582786000037, -2.763470888999962],
                [9.98041534500004, -2.763470888999962],
                [9.980971336000152, -2.764305114999957],
                [9.981804848000024, -2.764583110999865],
                [9.982083321000061, -2.765418051999916],
                [9.983470916000044, -2.765418051999916],
                [9.985139846000095, -2.766248940999958],
                [9.985695840000062, -2.767083882999941],
                [9.987084390000064, -2.767083882999941],
                [9.987361908000082, -2.766248940999958],
                [9.988194465000163, -2.766248940999958],
                [9.988472000000115, -2.76764],
                [9.987638, -2.767917999999838],
                [9.986528, -2.767917999999838],
                [9.986806000000115, -2.769028],
                [9.987638, -2.769306],
                [9.987918, -2.769862],
                [9.98875, -2.769862],
                [9.989810000000148, -2.770415999999841],
                [9.990416, -2.771249999999839],
                [9.991528000000187, -2.771806],
                [9.992084, -2.771806],
                [9.99236, -2.772362],
                [9.992916, -2.772362],
                [9.994582, -2.774028],
                [9.995973, -2.775138],
                [9.996529, -2.775138],
                [9.998751, -2.77736],
                [9.999305, -2.777638],
                [10.000417, -2.777638],
                [10.000417, -2.778472],
                [10.002083, -2.780139],
                [10.003749, -2.780973],
                [10.004305, -2.781529],
                [10.004861, -2.781529],
                [10.005139000000156, -2.782361],
                [10.005695000000117, -2.782361],
                [10.007361000000117, -2.784027],
                [10.008193, -2.784027],
                [10.009027000000117, -2.785139],
                [10.009584, -2.785139],
                [10.009862000000112, -2.785695],
                [10.01041800000013, -2.785695],
                [10.010693000000117, -2.786248999999884],
                [10.01125, -2.786250999999879],
                [10.01208400000013, -2.786805],
                [10.012362, -2.787361],
                [10.01208400000013, -2.788748999999882],
                [10.014306000000147, -2.788748999999882],
                [10.014584, -2.789305],
                [10.015694, -2.789861],
                [10.016806, -2.789861],
                [10.01736, -2.790416999999877],
                [10.01736, -2.790971],
                [10.018750000000125, -2.790971],
                [10.019584, -2.791805],
                [10.020694, -2.791805],
                [10.02125, -2.79264],
                [10.02236, -2.792918],
                [10.022638, -2.793471],
                [10.023751000000175, -2.794028],
                [10.024304, -2.794584],
                [10.025417000000175, -2.794862],
                [10.025417000000175, -2.795415999999875],
                [10.025973, -2.795694],
                [10.027639, -2.797362],
                [10.02875100000017, -2.79764],
                [10.029027, -2.798194],
                [10.030693, -2.799028],
                [10.031527, -2.799028],
                [10.031527, -2.799584],
                [10.032361, -2.799584],
                [10.032361, -2.800138],
                [10.031805, -2.800416],
                [10.031805, -2.801528],
                [10.032361, -2.802084],
                [10.032917, -2.802084],
                [10.03374900000017, -2.803194],
                [10.035139, -2.803472],
                [10.035418, -2.804306],
                [10.036527, -2.804582],
                [10.036806000000183, -2.805138],
                [10.037362000000144, -2.805138],
                [10.03764, -2.805693999999846],
                [10.038194, -2.805693999999846],
                [10.038472000000183, -2.806251],
                [10.039028000000144, -2.806251],
                [10.039306, -2.806807],
                [10.039862, -2.806807],
                [10.040972, -2.807639],
                [10.041250000000161, -2.808194999999898],
                [10.04236200000014, -2.809026999999844],
                [10.042916000000162, -2.809026999999844],
                [10.045416, -2.811251],
                [10.045972, -2.811251],
                [10.046250000000157, -2.811805],
                [10.047362000000135, -2.812639],
                [10.047916000000157, -2.812639],
                [10.048194, -2.813194999999894],
                [10.04875, -2.813194999999894],
                [10.04986, -2.814026999999896],
                [10.050138000000118, -2.814583],
                [10.051251, -2.815417],
                [10.051805, -2.815417],
                [10.052083000000152, -2.815971],
                [10.052639000000113, -2.815972999999815],
                [10.05319500000013, -2.816805],
                [10.053751, -2.816805],
                [10.054027, -2.817360999999892],
                [10.055139, -2.818195999999887],
                [10.055695, -2.818195999999887],
                [10.055973000000108, -2.818749],
                [10.056529000000126, -2.818749],
                [10.057639000000108, -2.819584],
                [10.057917, -2.82014],
                [10.059583, -2.82125],
                [10.060139, -2.821806],
                [10.06069500000018, -2.821806],
                [10.060971000000109, -2.822361999999885],
                [10.061527000000126, -2.822361999999885],
                [10.06264, -2.823193999999887],
                [10.062918, -2.823681],
                [10.064028000000121, -2.824584],
                [10.064584, -2.824584],
                [10.064862000000176, -2.825137999999868],
                [10.065416, -2.825137999999868],
                [10.065972, -2.825972],
                [10.066528000000176, -2.825972],
                [10.066806, -2.826527999999883],
                [10.067362, -2.826527999999883],
                [10.068472, -2.82736199999988],
                [10.06875, -2.827916],
                [10.069306, -2.827916],
                [10.0701380000001, -2.828472],
                [10.070416, -2.82902799999988],
                [10.070972, -2.82902799999988],
                [10.07125, -2.829584],
                [10.071806, -2.829584],
                [10.071806, -2.827916],
                [10.073472, -2.828193999999883],
                [10.074028, -2.82875],
                [10.075138, -2.82902799999988],
                [10.076804, -2.830416],
                [10.076804, -2.831528],
                [10.077639, -2.831807],
                [10.078751, -2.832916],
                [10.079305, -2.833751],
                [10.080417, -2.834029],
                [10.081527, -2.835417],
                [10.082083, -2.835417],
                [10.082361000000162, -2.835973],
                [10.082917000000123, -2.835973],
                [10.083195, -2.836805],
                [10.082639, -2.837361],
                [10.084029000000157, -2.838749],
                [10.085112, -2.839297999999815],
                [10.085417, -2.839861],
                [10.085971, -2.839861],
                [10.08680500000014, -2.840972999999849],
                [10.087361000000158, -2.840972999999849],
                [10.087639, -2.841527],
                [10.089583000000118, -2.843195],
                [10.092362, -2.845418],
                [10.092916, -2.845418],
                [10.094028, -2.846249],
                [10.094306000000131, -2.846806],
                [10.094862, -2.846806],
                [10.095138, -2.847362],
                [10.09625, -2.848194],
                [10.096868, -2.848146],
                [10.097084000000109, -2.84875],
                [10.097638000000131, -2.84875],
                [10.097916, -2.849305999999899],
                [10.099028, -2.850139999999897],
                [10.099584, -2.850139999999897],
                [10.09986, -2.850694],
                [10.100694, -2.850694],
                [10.100972000000127, -2.85125],
                [10.101528, -2.85125],
                [10.101806000000181, -2.851805999999897],
                [10.102916, -2.85208399999982],
                [10.103194, -2.852639999999894],
                [10.104304000000127, -2.852916],
                [10.1045850000001, -2.853471999999897],
                [10.105139, -2.853471999999897],
                [10.105417, -2.854028],
                [10.105973000000176, -2.854028],
                [10.1062510000001, -2.854583999999818],
                [10.106807, -2.854583999999818],
                [10.107083, -2.855137999999897],
                [10.107639000000177, -2.855137999999897],
                [10.1079170000001, -2.855694],
                [10.108473, -2.855694],
                [10.108751000000154, -2.856249999999818],
                [10.109305000000177, -2.856249999999818],
                [10.1095830000001, -2.856805999999892],
                [10.110139, -2.856805999999892],
                [10.110417000000155, -2.85736],
                [10.110973000000172, -2.85736],
                [10.1112490000001, -2.857915999999818],
                [10.111805, -2.857915999999818],
                [10.112083000000155, -2.858471999999892],
                [10.112639000000172, -2.858471999999892],
                [10.112917, -2.859029],
                [10.113471, -2.859029],
                [10.114583, -2.85986],
                [10.115139, -2.860695],
                [10.115693, -2.860695],
                [10.115974, -2.861250999999868],
                [10.118193000000133, -2.862916999999868],
                [10.11875, -2.862916999999868],
                [10.119028000000128, -2.863472999999885],
                [10.120694000000128, -2.864582999999868],
                [10.122638, -2.866251],
                [10.123194, -2.866251],
                [10.124028000000123, -2.867361],
                [10.125140000000101, -2.868195],
                [10.125694000000124, -2.868195],
                [10.126528, -2.869305],
                [10.12986, -2.871805],
                [10.130416, -2.871805],
                [10.132083000000137, -2.873471],
                [10.132639, -2.873471],
                [10.132917000000191, -2.874028],
                [10.135139, -2.875694],
                [10.135695, -2.875694],
                [10.137361, -2.877362],
                [10.137917000000186, -2.877362],
                [10.138749000000132, -2.878472],
                [10.139305, -2.878749999999855],
                [10.140417, -2.879862],
                [10.140971, -2.879862],
                [10.141249000000187, -2.880415999999855],
                [10.142361, -2.881528],
                [10.142918, -2.881806],
                [10.144027, -2.882916],
                [10.144584, -2.883194],
                [10.145972, -2.884582],
                [10.147916, -2.885972],
                [10.148472, -2.886807],
                [10.1490280000001, -2.886807],
                [10.150138, -2.888194999999826],
                [10.15125, -2.889028999999823],
                [10.152360000000101, -2.889583],
                [10.152640000000133, -2.890139],
                [10.153750000000173, -2.891250999999897],
                [10.154306000000133, -2.891526999999826],
                [10.155416000000173, -2.892639],
                [10.155972000000133, -2.892639],
                [10.157085, -2.893749],
                [10.157917000000168, -2.894026999999824],
                [10.157917000000168, -2.894582999999898],
                [10.158751, -2.895416999999895],
                [10.160417, -2.896526999999878],
                [10.161805000000129, -2.897916999999893],
                [10.162639, -2.897916999999893],
                [10.162917000000107, -2.898471],
                [10.163473000000124, -2.898471],
                [10.164583000000107, -2.899582999999893],
                [10.164861, -2.90014],
                [10.165417, -2.90014],
                [10.166527, -2.901527999999871],
                [10.167083, -2.901527999999871],
                [10.167361000000142, -2.902084],
                [10.168749, -2.902915999999891],
                [10.169861, -2.904027999999869],
                [10.17014, -2.904584],
                [10.171249000000103, -2.905416],
                [10.171806, -2.905416],
                [10.172084, -2.905972],
                [10.173196, -2.906806],
                [10.17375, -2.906806],
                [10.174028, -2.907362],
                [10.174584, -2.907362],
                [10.175694, -2.907916],
                [10.175694, -2.908472],
                [10.17625, -2.909028],
                [10.176806, -2.909028],
                [10.177084, -2.909584],
                [10.177638000000115, -2.909584],
                [10.178194, -2.910416],
                [10.17875, -2.910416],
                [10.179028000000187, -2.910972],
                [10.17986, -2.910972],
                [10.180416, -2.911806],
                [10.181528, -2.912085],
                [10.181528, -2.912638],
                [10.182082, -2.913193999999862],
                [10.183194, -2.913751],
                [10.184029, -2.913751],
                [10.184304000000111, -2.914304],
                [10.185417, -2.915417],
                [10.18597300000016, -2.915417],
                [10.186807, -2.916251],
                [10.18763900000016, -2.916251],
                [10.18763900000016, -2.916805],
                [10.188751, -2.916805],
                [10.189583, -2.917639],
                [10.190139, -2.917639],
                [10.190417, -2.918195],
                [10.190973000000156, -2.918195],
                [10.191249, -2.919027],
                [10.192083, -2.919027],
                [10.192361, -2.919583],
                [10.194027, -2.920695],
                [10.194861000000174, -2.920695],
                [10.195139, -2.921249],
                [10.195974, -2.921251],
                [10.195974, -2.921805],
                [10.196527000000174, -2.922361],
                [10.197083000000134, -2.922361],
                [10.197362000000112, -2.922917],
                [10.198471, -2.923749],
                [10.199306, -2.923749],
                [10.19958400000013, -2.924305],
                [10.200140000000147, -2.924305],
                [10.200416, -2.925138999999831],
                [10.200972, -2.925138999999831],
                [10.20125000000013, -2.925696],
                [10.202084, -2.925696],
                [10.202362000000107, -2.926249],
                [10.204306, -2.927084],
                [10.205972, -2.92875],
                [10.207638, -2.929028],
                [10.207638, -2.929584],
                [10.208472000000143, -2.929862],
                [10.209306, -2.930971999999883],
                [10.210416, -2.931528],
                [10.212361, -2.933194],
                [10.213195, -2.933194],
                [10.214307, -2.934305999999879],
                [10.215417000000116, -2.934861999999896],
                [10.215973, -2.934861999999896],
                [10.216529, -2.935693999999899],
                [10.217639, -2.936527999999896],
                [10.218749000000116, -2.936805999999876],
                [10.219027, -2.937359999999899],
                [10.220139000000188, -2.937637999999879],
                [10.220139000000188, -2.938193999999896],
                [10.22124900000017, -2.938193999999896],
                [10.221527, -2.93875],
                [10.222083000000111, -2.93875],
                [10.222361, -2.939303999999879],
                [10.222917000000166, -2.939303999999879],
                [10.223749000000112, -2.940137999999877],
                [10.223749000000112, -2.940695],
                [10.224862, -2.940695],
                [10.224862, -2.941251],
                [10.225418000000161, -2.941806999999869],
                [10.225972000000183, -2.941806999999869],
                [10.227084000000161, -2.943195],
                [10.228194, -2.943472999999869],
                [10.229028, -2.944304999999872],
                [10.229028, -2.944861],
                [10.230138, -2.945972999999867],
                [10.231806, -2.947083],
                [10.232084000000157, -2.947917],
                [10.232916, -2.947917],
                [10.23319400000014, -2.948472999999865],
                [10.234862000000135, -2.948472999999865],
                [10.235972000000118, -2.948749],
                [10.237638000000118, -2.950973],
                [10.238751, -2.952083],
                [10.238751, -2.952639],
                [10.239304000000118, -2.953193],
                [10.240417, -2.953749],
                [10.241251000000148, -2.953749],
                [10.24236100000013, -2.954305],
                [10.24236100000013, -2.954862],
                [10.243473000000108, -2.955418],
                [10.244029000000126, -2.955418],
                [10.244305, -2.955972],
                [10.244861, -2.955972],
                [10.245139000000108, -2.956528],
                [10.245695000000126, -2.956528],
                [10.245973, -2.957084],
                [10.246527, -2.957084],
                [10.246805000000109, -2.95764],
                [10.247361000000126, -2.95764],
                [10.247639, -2.958194],
                [10.248195, -2.958194],
                [10.248471000000109, -2.95875],
                [10.249027000000126, -2.95875],
                [10.249305, -2.959306],
                [10.249861, -2.959306],
                [10.250139000000104, -2.959862],
                [10.250693000000126, -2.959862],
                [10.251806, -2.961249999999836],
                [10.252362000000176, -2.961528],
                [10.254862000000116, -2.964028],
                [10.256528000000117, -2.965138],
                [10.2576380000001, -2.965138],
                [10.25875, -2.965972],
                [10.259306, -2.965972],
                [10.260416, -2.966529],
                [10.261528, -2.96736],
                [10.261528, -2.967915999999889],
                [10.262611000000163, -2.968746],
                [10.264585, -2.968750999999884],
                [10.264585, -2.969029],
                [10.266251, -2.969861],
                [10.268195000000162, -2.971250999999882],
                [10.268473, -2.972361],
                [10.269583, -2.973748999999884],
                [10.270139, -2.973750999999879],
                [10.270417000000123, -2.974305],
                [10.271529000000157, -2.974861],
                [10.272083000000123, -2.975416999999879],
                [10.27263900000014, -2.975416999999879],
                [10.272917, -2.975973],
                [10.273751000000118, -2.975973],
                [10.273751000000118, -2.976527],
                [10.27430500000014, -2.976805],
                [10.275973000000135, -2.978471],
                [10.276527000000158, -2.979305],
                [10.277083000000118, -2.979582999999877],
                [10.277083000000118, -2.98014],
                [10.27764, -2.980417999999872],
                [10.278471, -2.981527],
                [10.279028000000153, -2.981527],
                [10.279862, -2.98264],
                [10.280972, -2.982914999999878],
                [10.282084, -2.982914999999878],
                [10.282918000000109, -2.983194],
                [10.283194, -2.98458399999987],
                [10.282918000000109, -2.98514],
                [10.280694000000153, -2.98514],
                [10.279862, -2.984862],
                [10.279306, -2.984306],
                [10.278471, -2.984306],
                [10.278471, -2.984862],
                [10.279862, -2.985694],
                [10.279862, -2.98625],
                [10.280416, -2.986528],
                [10.282638, -2.98875],
                [10.285140000000126, -2.990694],
                [10.285694, -2.990694],
                [10.285972, -2.99125],
                [10.287084, -2.992082],
                [10.287638, -2.992082],
                [10.28791600000011, -2.992638],
                [10.289028, -2.993472],
                [10.28958200000011, -2.993472],
                [10.28986, -2.994028999999841],
                [10.290972000000181, -2.99486],
                [10.291529, -2.99486],
                [10.291804000000127, -2.995417],
                [10.292917, -2.996251],
                [10.293473000000176, -2.996251],
                [10.2937510000001, -2.996805],
                [10.294861, -2.997639],
                [10.2954170000001, -2.997639],
                [10.295695, -2.998194999999839],
                [10.296805000000177, -2.999026999999842],
                [10.297361, -2.999026999999842],
                [10.297639, -2.999583],
                [10.298473000000172, -2.999583],
                [10.299305, -3.000139],
                [10.299583, -3.000694999999894],
                [10.300971, -3.001805],
                [10.301527, -3.002639],
                [10.302639, -3.003749],
                [10.303474, -3.003749],
                [10.30402700000019, -3.004305],
                [10.30458300000015, -3.004305],
                [10.30514, -3.005139],
                [10.305696, -3.005139],
                [10.306806, -3.006526999999892],
                [10.307916, -3.007083],
                [10.308750000000146, -3.007918],
                [10.309306000000163, -3.007918],
                [10.310138, -3.009027999999887],
                [10.311528000000123, -3.010137],
                [10.31208400000014, -3.01014],
                [10.313194000000124, -3.010693999999887],
                [10.313750000000141, -3.01125],
                [10.313750000000141, -3.011806],
                [10.314862000000119, -3.012361999999882],
                [10.315972000000158, -3.013472],
                [10.316528000000119, -3.013472],
                [10.316806, -3.014027999999883],
                [10.318194000000119, -3.014306],
                [10.319029000000114, -3.015138],
                [10.319029000000114, -3.015693999999883],
                [10.319582, -3.01625],
                [10.320695000000114, -3.016806],
                [10.321251000000132, -3.016806],
                [10.321527, -3.017359999999883],
                [10.322639, -3.01819399999988],
                [10.323195, -3.01819399999988],
                [10.324305, -3.019306],
                [10.324861, -3.019582],
                [10.324861, -3.020138],
                [10.32569500000011, -3.020138],
                [10.327083000000187, -3.021529],
                [10.327639, -3.021529],
                [10.328471, -3.022082],
                [10.32902700000011, -3.022917],
                [10.329861, -3.023195],
                [10.329861, -3.023751],
                [10.331805, -3.024861],
                [10.332362, -3.025417],
                [10.333472, -3.025695],
                [10.334584000000177, -3.026527],
                [10.3348620000001, -3.027083],
                [10.335972, -3.027361],
                [10.337362000000155, -3.028749],
                [10.337362000000155, -3.029027],
                [10.338472, -3.029583],
                [10.339584000000173, -3.030695],
                [10.340416, -3.030695],
                [10.340972, -3.031527],
                [10.341528, -3.031527],
                [10.341806000000133, -3.032083],
                [10.342916000000173, -3.032638999999847],
                [10.343472000000133, -3.033193],
                [10.34402800000015, -3.033196],
                [10.34486, -3.034304999999847],
                [10.345973000000129, -3.034583],
                [10.346251, -3.035139999999899],
                [10.347083000000168, -3.035693],
                [10.347639000000129, -3.035693],
                [10.348473, -3.036805999999899],
                [10.349583, -3.037084],
                [10.349861000000146, -3.037639999999897],
                [10.350417000000164, -3.037639999999897],
                [10.350973000000124, -3.038471999999899],
                [10.352083000000164, -3.039028],
                [10.352639000000124, -3.039028],
                [10.352917, -3.039584],
                [10.353471, -3.039584],
                [10.353751000000102, -3.040416],
                [10.354583, -3.040694],
                [10.35597300000012, -3.042084],
                [10.356805, -3.042084],
                [10.358471, -3.04375],
                [10.359027, -3.04375],
                [10.359306, -3.044305999999892],
                [10.359862, -3.044305999999892],
                [10.360696000000132, -3.045416],
                [10.362084, -3.046528],
                [10.363194, -3.04680599999989],
                [10.363472000000115, -3.04736],
                [10.364862000000187, -3.048750999999868],
                [10.365694000000133, -3.048750999999868],
                [10.36680600000011, -3.049581999999816],
                [10.367084, -3.050138999999888],
                [10.368194000000187, -3.050416999999868],
                [10.368194000000187, -3.050972999999885],
                [10.369862000000182, -3.052082999999868],
                [10.370972, -3.053195],
                [10.37236, -3.053472999999883],
                [10.372638, -3.054304999999886],
                [10.375973000000101, -3.057083],
                [10.377361000000178, -3.057361],
                [10.377361000000178, -3.057917],
                [10.377917, -3.058470999999884],
                [10.378473000000156, -3.058470999999884],
                [10.379583, -3.059861],
                [10.380139000000156, -3.059861],
                [10.380971000000102, -3.060970999999881],
                [10.382083, -3.061249],
                [10.384861, -3.064028],
                [10.38541800000013, -3.064028],
                [10.385696, -3.064584],
                [10.386249, -3.064584],
                [10.387640000000147, -3.06625],
                [10.38819400000017, -3.06625],
                [10.38875000000013, -3.066806],
                [10.38875000000013, -3.067362],
                [10.389584, -3.067916],
                [10.391528000000108, -3.069862],
                [10.392640000000142, -3.070138],
                [10.394584, -3.072081999999853],
                [10.394584, -3.07236],
                [10.395972000000143, -3.07375],
                [10.39736, -3.074307],
                [10.398194000000103, -3.075695],
                [10.398751, -3.076251],
                [10.399582, -3.076251],
                [10.400695, -3.077361],
                [10.400695, -3.077917],
                [10.401807, -3.079027],
                [10.402639, -3.079305],
                [10.402639, -3.079861],
                [10.403473, -3.080694999999821],
                [10.404029, -3.080694999999821],
                [10.404861, -3.081250999999895],
                [10.404861, -3.081805],
                [10.405695, -3.082639],
                [10.408471, -3.084860999999819],
                [10.409027, -3.084860999999819],
                [10.409583000000111, -3.085416999999893],
                [10.409583000000111, -3.085973],
                [10.413471, -3.089861999999869],
                [10.413750000000107, -3.089861999999869],
                [10.414862, -3.090972],
                [10.414862, -3.091527999999869],
                [10.415694, -3.092083999999886],
                [10.419584000000157, -3.095972],
                [10.419584000000157, -3.096806],
                [10.420416, -3.097084],
                [10.420972, -3.097916],
                [10.424306, -3.10125],
                [10.42486, -3.101528999999857],
                [10.425694000000135, -3.102917],
                [10.42819500000013, -3.105417],
                [10.42819500000013, -3.105973],
                [10.428751000000148, -3.106251],
                [10.428751000000148, -3.106805],
                [10.429307000000108, -3.107083],
                [10.429307000000108, -3.107639],
                [10.431249, -3.109026999999855],
                [10.431249, -3.109583],
                [10.431805, -3.109861],
                [10.431805, -3.110417],
                [10.434027, -3.112361],
                [10.434583, -3.112361],
                [10.435139, -3.113471],
                [10.435139, -3.114305],
                [10.435695, -3.114305],
                [10.437361, -3.116528],
                [10.439305000000104, -3.118471999999827],
                [10.44014, -3.119584],
                [10.440415000000144, -3.120416],
                [10.440972, -3.120416],
                [10.441528, -3.120971999999824],
                [10.441806, -3.121805999999879],
                [10.443194, -3.123471999999879],
                [10.44375, -3.12375],
                [10.44375, -3.124584],
                [10.444306, -3.124584],
                [10.444584, -3.125693999999896],
                [10.445416, -3.126527999999894],
                [10.446806, -3.127359999999896],
                [10.447638, -3.128471999999874],
                [10.449306, -3.130138999999872],
                [10.449306, -3.130695],
                [10.450416000000189, -3.131804999999872],
                [10.452360000000112, -3.133195],
                [10.452360000000112, -3.133751],
                [10.452916, -3.134027],
                [10.457639, -3.138749],
                [10.458749, -3.139583],
                [10.459305, -3.139583],
                [10.45958300000018, -3.140695],
                [10.460971, -3.141249],
                [10.461527, -3.142362],
                [10.462083, -3.142362],
                [10.463473000000135, -3.14375],
                [10.463749, -3.144584],
                [10.464861, -3.144584],
                [10.465693000000158, -3.145694],
                [10.465693000000158, -3.146528],
                [10.467362, -3.147916],
                [10.468472, -3.149028],
                [10.468472, -3.149584],
                [10.469306000000131, -3.149862],
                [10.469306000000131, -3.150416],
                [10.469862000000148, -3.150694],
                [10.471806, -3.152916],
                [10.472916, -3.153194],
                [10.474584, -3.15486],
                [10.475694, -3.156250999999884],
                [10.475694, -3.156804],
                [10.476806, -3.157361],
                [10.476806, -3.157916999999884],
                [10.47736, -3.158195],
                [10.47736, -3.158750999999882],
                [10.477916, -3.159305],
                [10.478472, -3.160416999999882],
                [10.479304000000127, -3.160972999999899],
                [10.4812510000001, -3.162916999999879],
                [10.4812510000001, -3.163195],
                [10.482361, -3.163748999999882],
                [10.482361, -3.164304999999899],
                [10.4829170000001, -3.164861],
                [10.483473000000117, -3.164861],
                [10.484029, -3.165695],
                [10.484861, -3.16624899999988],
                [10.485139000000117, -3.167361],
                [10.4862490000001, -3.167639],
                [10.4862490000001, -3.168196],
                [10.487361, -3.169027],
                [10.487361, -3.169583999999873],
                [10.48819500000019, -3.169862],
                [10.48819500000019, -3.17041799999987],
                [10.48986100000019, -3.171806],
                [10.490973000000167, -3.173194],
                [10.490973000000167, -3.17375],
                [10.492361, -3.174862],
                [10.493196, -3.175972],
                [10.493749, -3.177083999999866],
                [10.496528, -3.17986],
                [10.497362000000123, -3.180138],
                [10.497362000000123, -3.180972],
                [10.498194, -3.181528],
                [10.498472000000163, -3.182081999999866],
                [10.499028000000123, -3.182081999999866],
                [10.49986, -3.182916],
                [10.49986, -3.183473],
                [10.500972, -3.184861],
                [10.503194, -3.187083],
                [10.503194, -3.187639],
                [10.506804000000159, -3.191249],
                [10.506804000000159, -3.191526999999837],
                [10.508473, -3.193194999999889],
                [10.508473, -3.193749],
                [10.50902600000012, -3.194305],
                [10.509861000000114, -3.194305],
                [10.510695, -3.194860999999889],
                [10.51152900000011, -3.196249],
                [10.512361, -3.196249],
                [10.513473, -3.197084],
                [10.514305, -3.198193999999887],
                [10.51486100000011, -3.198472],
                [10.516805, -3.200416],
                [10.517083000000127, -3.200972],
                [10.517083000000127, -3.202084],
                [10.518474, -3.203472],
                [10.52014, -3.204584],
                [10.52014, -3.205138],
                [10.5206960000001, -3.205416],
                [10.5206960000001, -3.205972],
                [10.521250000000123, -3.20625],
                [10.522918, -3.208193999999878],
                [10.5240280000001, -3.208472],
                [10.525694000000101, -3.210694999999873],
                [10.526806, -3.211805],
                [10.526806, -3.212360999999874],
                [10.527638, -3.212639],
                [10.528472, -3.213751],
                [10.528472, -3.214860999999871],
                [10.52930600000019, -3.215417],
                [10.52930600000019, -3.216249],
                [10.530138, -3.216805],
                [10.530416000000173, -3.217361],
                [10.530416000000173, -3.219027],
                [10.53097200000019, -3.219583],
                [10.53097200000019, -3.220417],
                [10.532085, -3.221249],
                [10.53263800000019, -3.222361],
                [10.534307, -3.224028],
                [10.534307, -3.224584],
                [10.535417, -3.225416],
                [10.535973, -3.227639999999894],
                [10.537083, -3.22875],
                [10.537639, -3.229862],
                [10.537639, -3.231528],
                [10.538195, -3.23236],
                [10.538195, -3.233471999999892],
                [10.539029000000141, -3.23430599999989],
                [10.539029000000141, -3.235137999999893],
                [10.539861, -3.23597199999989],
                [10.539861, -3.236529],
                [10.540417, -3.236803999999893],
                [10.540695000000142, -3.237916999999811],
                [10.541251000000159, -3.238195],
                [10.541251000000159, -3.238751],
                [10.541805000000124, -3.239861],
                [10.542361000000142, -3.240138999999886],
                [10.542361000000142, -3.241527],
                [10.542917000000159, -3.242083],
                [10.542639, -3.243195],
                [10.542639, -3.244861],
                [10.542917000000159, -3.244861],
                [10.542917000000159, -3.248470999999881],
                [10.54347300000012, -3.249304999999879],
                [10.54347300000012, -3.252362],
                [10.543195, -3.252362],
                [10.543195, -3.254306],
                [10.543749, -3.255416],
                [10.54458300000016, -3.25625],
                [10.545693000000142, -3.256806],
                [10.546527, -3.256806],
                [10.54680500000012, -3.257362],
                [10.54791500000016, -3.25764],
                [10.549584, -3.259306],
                [10.550418, -3.259306],
                [10.550972000000115, -3.260138],
                [10.551375, -3.260138],
                [10.552084, -3.260972],
                [10.553194000000133, -3.261249999999848],
                [10.553472, -3.261806],
                [10.554028000000187, -3.261806],
                [10.554584, -3.262638],
                [10.555694000000187, -3.262915999999848],
                [10.55625, -3.263751],
                [10.556806, -3.263751],
                [10.557082, -3.264307],
                [10.557916, -3.264307],
                [10.559585, -3.265973],
                [10.560138, -3.265973],
                [10.560973, -3.266805],
                [10.561807000000101, -3.266805],
                [10.562082, -3.267361],
                [10.562639, -3.267361],
                [10.563473000000101, -3.268473],
                [10.565139000000102, -3.269305],
                [10.565695, -3.269861],
                [10.566249, -3.269861],
                [10.566527000000178, -3.270416999999895],
                [10.567639000000156, -3.270695],
                [10.568195000000173, -3.271527],
                [10.569583, -3.271527],
                [10.570693, -3.272639],
                [10.571527000000174, -3.272916999999893],
                [10.571805, -3.273473],
                [10.572361, -3.273473],
                [10.572639000000152, -3.274027],
                [10.573749, -3.274860999999817],
                [10.57458400000013, -3.274860999999817],
                [10.575415, -3.275693],
                [10.576528, -3.275971],
                [10.576806000000147, -3.276526999999817],
                [10.577918000000125, -3.277083999999888],
                [10.579306, -3.278192999999817],
                [10.580416, -3.278192999999817],
                [10.580972, -3.278472],
                [10.581806000000142, -3.279583999999886],
                [10.582916000000125, -3.280415999999889],
                [10.58375, -3.280415999999889],
                [10.584028000000103, -3.280972],
                [10.58486, -3.280972],
                [10.58625000000012, -3.282362],
                [10.587082, -3.282362],
                [10.587360000000103, -3.282915999999887],
                [10.588473, -3.283472],
                [10.589583, -3.283472],
                [10.589583, -3.284306],
                [10.590417000000116, -3.284862],
                [10.592083000000116, -3.284862],
                [10.593195, -3.285972],
                [10.594583, -3.287084],
                [10.595139000000188, -3.287084],
                [10.595695, -3.287638],
                [10.595971000000134, -3.288472],
                [10.596527, -3.288472],
                [10.597639, -3.289581999999882],
                [10.599027, -3.28986],
                [10.599583, -3.290416],
                [10.600696, -3.290695],
                [10.600971, -3.291804],
                [10.601527, -3.291804],
                [10.601806, -3.292361],
                [10.603472, -3.293473],
                [10.604306000000179, -3.294305],
                [10.605416000000162, -3.294583],
                [10.607084000000157, -3.296249],
                [10.607640000000174, -3.296251],
                [10.608750000000157, -3.297360999999853],
                [10.609306000000174, -3.297360999999853],
                [10.609582000000103, -3.297917],
                [10.610694, -3.298749],
                [10.612084000000152, -3.299305],
                [10.612916, -3.300139],
                [10.614582, -3.300695],
                [10.615138, -3.301526999999851],
                [10.61569500000013, -3.301526999999851],
                [10.615973, -3.302083],
                [10.616526, -3.302083],
                [10.617639, -3.303196],
                [10.618195, -3.303196],
                [10.618473000000108, -3.303749],
                [10.619029000000126, -3.303749],
                [10.620139000000108, -3.30514],
                [10.620695000000126, -3.30514],
                [10.621251000000143, -3.305972],
                [10.622083, -3.305972],
                [10.622639, -3.306806],
                [10.623749, -3.307361999999898],
                [10.624305, -3.307361999999898],
                [10.624583000000143, -3.308472],
                [10.625139000000104, -3.309027999999898],
                [10.625971, -3.309305999999822],
                [10.626249000000143, -3.309861999999896],
                [10.627083, -3.309861999999896],
                [10.627362, -3.310416],
                [10.628471000000104, -3.310971999999822],
                [10.629028, -3.310971999999822],
                [10.629306, -3.311527999999896],
                [10.630972, -3.312359999999899],
                [10.630972, -3.312916],
                [10.631806, -3.31347199999982],
                [10.632362, -3.31347199999982],
                [10.63264, -3.314027999999894],
                [10.633194000000117, -3.314027999999894],
                [10.633472, -3.314584],
                [10.634028, -3.314584],
                [10.634860000000117, -3.31513799999982],
                [10.635138, -3.31625],
                [10.636250000000189, -3.317082],
                [10.637082, -3.317082],
                [10.638194000000112, -3.318194999999889],
                [10.639028000000167, -3.318472999999869],
                [10.639028000000167, -3.319304999999872],
                [10.640416, -3.320694999999887],
                [10.642082, -3.32152699999989],
                [10.642360000000167, -3.322083],
                [10.642917, -3.322083],
                [10.643195000000162, -3.322917],
                [10.644029, -3.323195],
                [10.644029, -3.323749],
                [10.645139, -3.324304999999868],
                [10.646249, -3.325417],
                [10.646529000000157, -3.325970999999868],
                [10.648195000000158, -3.326805],
                [10.648471, -3.327639],
                [10.649305, -3.327639],
                [10.649305, -3.328195],
                [10.650139, -3.329027],
                [10.651527000000158, -3.329861],
                [10.652083000000175, -3.330418],
                [10.652083000000175, -3.330971],
                [10.652639000000136, -3.331249],
                [10.652639000000136, -3.332084],
                [10.653471, -3.332362],
                [10.654027, -3.332918],
                [10.654305000000136, -3.334028],
                [10.655971000000136, -3.335694],
                [10.657084, -3.337362],
                [10.657084, -3.338194],
                [10.657638, -3.338194],
                [10.658194, -3.339584],
                [10.658194, -3.341528],
                [10.65875, -3.34236],
                [10.660416, -3.342916],
                [10.660416, -3.343750999999827],
                [10.66125000000011, -3.344307],
                [10.661528, -3.345416999999827],
                [10.661528, -3.346529],
                [10.662084, -3.346805],
                [10.662084, -3.347639],
                [10.662638, -3.347916999999825],
                [10.66291600000011, -3.349305],
                [10.66291600000011, -3.351248999999825],
                [10.662084, -3.351527],
                [10.662084, -3.353472999999895],
                [10.66291600000011, -3.354304999999897],
                [10.66291600000011, -3.355970999999897],
                [10.66375, -3.356804999999895],
                [10.663472000000127, -3.35791799999987],
                [10.663472000000127, -3.359306],
                [10.66375, -3.359862],
                [10.663472000000127, -3.36125],
                [10.663472000000127, -3.365972],
                [10.66375, -3.365972],
                [10.66375, -3.367638],
                [10.664584000000104, -3.368749999999864],
                [10.664584000000104, -3.36986],
                [10.665138000000127, -3.370415999999864],
                [10.665416, -3.371529],
                [10.665416, -3.376251],
                [10.665694000000144, -3.377361],
                [10.666250000000105, -3.377917],
                [10.666250000000105, -3.379027],
                [10.666528, -3.379861],
                [10.6670850000001, -3.380417],
                [10.667360000000144, -3.381805],
                [10.668194, -3.382360999999833],
                [10.668194, -3.383195999999884],
                [10.6687510000001, -3.383195999999884],
                [10.669029, -3.383749],
                [10.670973000000117, -3.384584],
                [10.6720830000001, -3.384861999999885],
                [10.672917, -3.385972],
                [10.672917, -3.390138],
                [10.671805, -3.390972],
                [10.671805, -3.394027999999878],
                [10.672639000000117, -3.394859999999881],
                [10.674305000000118, -3.395138],
                [10.674861, -3.394584],
                [10.674861, -3.393749999999898],
                [10.67569500000019, -3.393749999999898],
                [10.675417, -3.395138],
                [10.675417, -3.399304],
                [10.675139000000172, -3.400139],
                [10.674583, -3.400694999999871],
                [10.674583, -3.401805],
                [10.674027, -3.402083],
                [10.674027, -3.402639],
                [10.673473000000172, -3.403751],
                [10.672361, -3.404583],
                [10.672361, -3.405139],
                [10.671805, -3.405417],
                [10.671251, -3.407917],
                [10.670695, -3.409026999999867],
                [10.670695, -3.410139],
                [10.6704170000001, -3.410971],
                [10.669861, -3.411249],
                [10.669861, -3.411806],
                [10.669029, -3.412084],
                [10.6687510000001, -3.413194],
                [10.668194, -3.413194],
                [10.6670850000001, -3.414584],
                [10.6670850000001, -3.415139999999838],
                [10.665972, -3.415972],
                [10.665416, -3.417084],
                [10.665694000000144, -3.418194],
                [10.665694000000144, -3.428751],
                [10.665416, -3.428751],
                [10.665416, -3.431249],
                [10.665694000000144, -3.431251],
                [10.665694000000144, -3.432917],
                [10.665972, -3.434027],
                [10.666804000000127, -3.434027],
                [10.667916000000105, -3.434861],
                [10.668194, -3.435417],
                [10.669861, -3.435417],
                [10.670695, -3.434583],
                [10.671805, -3.434027],
                [10.671805, -3.433472999999879],
                [10.672361, -3.433195],
                [10.672639000000117, -3.432083],
                [10.673473000000172, -3.431527],
                [10.674861, -3.431527],
                [10.675139000000172, -3.432083],
                [10.675971000000118, -3.432361],
                [10.675971000000118, -3.433195],
                [10.676527, -3.433749],
                [10.677917, -3.433749],
                [10.678749, -3.432361],
                [10.678749, -3.431804999999883],
                [10.679305000000113, -3.431249],
                [10.679861, -3.430138999999883],
                [10.679861, -3.429583],
                [10.680971000000113, -3.429304999999886],
                [10.680971000000113, -3.428751],
                [10.681527, -3.428195],
                [10.682918, -3.428195],
                [10.68375, -3.429027],
                [10.68375, -3.432361],
                [10.684028, -3.432917],
                [10.684584, -3.432917],
                [10.68486200000018, -3.433472999999879],
                [10.68486200000018, -3.434583],
                [10.685694, -3.435138999999879],
                [10.68652800000018, -3.436249],
                [10.686806, -3.437361],
                [10.68819400000018, -3.437083],
                [10.689862000000119, -3.437083],
                [10.690138, -3.437639999999874],
                [10.690694, -3.437639999999874],
                [10.69125, -3.438196],
                [10.691806, -3.438196],
                [10.692916, -3.439305999999874],
                [10.692916, -3.44125],
                [10.693194000000119, -3.441805999999872],
                [10.692916, -3.442362],
                [10.693472, -3.443471999999872],
                [10.693472, -3.444862],
                [10.693750000000136, -3.445694],
                [10.694307, -3.44625],
                [10.694307, -3.448194],
                [10.694028, -3.448472],
                [10.697083, -3.448472],
                [10.698195, -3.447916],
                [10.699583000000132, -3.447916],
                [10.700417, -3.448472],
                [10.70236100000011, -3.448472],
                [10.703195, -3.447638],
                [10.705139, -3.447638],
                [10.707915000000128, -3.447084],
                [10.708749, -3.446528],
                [10.710418000000118, -3.446528],
                [10.710972, -3.447084],
                [10.712084000000118, -3.447084],
                [10.712916000000178, -3.44736],
                [10.712916000000178, -3.448194],
                [10.713472, -3.44875],
                [10.713472, -3.44986],
                [10.713194000000101, -3.450972],
                [10.713194000000101, -3.452082],
                [10.71014, -3.452082],
                [10.7081960000001, -3.451250999999843],
                [10.7081960000001, -3.450694],
                [10.707361000000105, -3.450416],
                [10.706527, -3.449582],
                [10.70569300000011, -3.449582],
                [10.705139, -3.450138],
                [10.705139, -3.451250999999843],
                [10.70569300000011, -3.45236],
                [10.706249000000128, -3.452916999999843],
                [10.706249000000128, -3.454582999999843],
                [10.705973, -3.455973],
                [10.705417, -3.456805],
                [10.704861, -3.456805],
                [10.704583000000127, -3.457361],
                [10.703749, -3.457639],
                [10.702639, -3.457361],
                [10.701251000000127, -3.457361],
                [10.701251000000127, -3.457916999999895],
                [10.699861, -3.458473],
                [10.698195, -3.458473],
                [10.697083, -3.457916999999895],
                [10.697083, -3.457639],
                [10.690416000000141, -3.457639],
                [10.689862000000119, -3.457082999999841],
                [10.688750000000141, -3.457361],
                [10.68736, -3.457361],
                [10.685972000000163, -3.456527],
                [10.685972000000163, -3.455695],
                [10.68486200000018, -3.455416999999841],
                [10.684584, -3.455973],
                [10.683472, -3.456250999999895],
                [10.682084, -3.455139],
                [10.682084, -3.454305],
                [10.681527, -3.454305],
                [10.680971000000113, -3.453750999999841],
                [10.680139000000167, -3.451807],
                [10.679861, -3.450138],
                [10.67902700000019, -3.448472],
                [10.678193, -3.448194],
                [10.678193, -3.447638],
                [10.677639000000113, -3.44736],
                [10.676251, -3.445972],
                [10.675971000000118, -3.445416],
                [10.675139000000172, -3.444862],
                [10.673473000000172, -3.444584],
                [10.673195, -3.444028],
                [10.670973000000117, -3.444028],
                [10.669861, -3.443471999999872],
                [10.667638, -3.442916],
                [10.665972, -3.442084],
                [10.663194, -3.44125],
                [10.66291600000011, -3.440694],
                [10.662362000000144, -3.440694],
                [10.661528, -3.439862],
                [10.661528, -3.438196],
                [10.662084, -3.437083],
                [10.66125000000011, -3.436249],
                [10.66125000000011, -3.434861],
                [10.661806000000126, -3.434583],
                [10.661806000000126, -3.434027],
                [10.662362000000144, -3.433472999999879],
                [10.662362000000144, -3.432361],
                [10.662084, -3.432083],
                [10.660416, -3.432083],
                [10.660140000000126, -3.431527],
                [10.659584000000109, -3.431527],
                [10.657918000000109, -3.429861],
                [10.657918000000109, -3.429304999999886],
                [10.657084, -3.428195],
                [10.656528, -3.428195],
                [10.656250000000114, -3.427638999999886],
                [10.656250000000114, -3.425972999999885],
                [10.656806000000131, -3.425417],
                [10.656806000000131, -3.424582],
                [10.657362000000148, -3.424306999999885],
                [10.657362000000148, -3.422637999999836],
                [10.657638, -3.422637999999836],
                [10.657638, -3.420694],
                [10.658194, -3.418471999999838],
                [10.65875, -3.417916],
                [10.65875, -3.417084],
                [10.659306, -3.416528],
                [10.659584000000109, -3.415139999999838],
                [10.660140000000126, -3.415139999999838],
                [10.660416, -3.41375],
                [10.660972, -3.412362],
                [10.661806000000126, -3.411806],
                [10.66125000000011, -3.409861],
                [10.66125000000011, -3.407639],
                [10.660694000000149, -3.407639],
                [10.660694000000149, -3.401805],
                [10.660972, -3.400973],
                [10.661528, -3.400694999999871],
                [10.661528, -3.400139],
                [10.66291600000011, -3.399304],
                [10.66291600000011, -3.398194999999873],
                [10.663472000000127, -3.397638],
                [10.66375, -3.396527999999876],
                [10.66375, -3.395415999999898],
                [10.664028000000144, -3.394306],
                [10.664584000000104, -3.394027999999878],
                [10.664584000000104, -3.389584],
                [10.665138000000127, -3.389306],
                [10.665138000000127, -3.387084],
                [10.664584000000104, -3.387084],
                [10.664584000000104, -3.38625],
                [10.664028000000144, -3.385418],
                [10.664028000000144, -3.384026999999833],
                [10.66291600000011, -3.383195999999884],
                [10.66291600000011, -3.381249],
                [10.662638, -3.380139],
                [10.662084, -3.379583],
                [10.662084, -3.377639],
                [10.661528, -3.376251],
                [10.660972, -3.375973],
                [10.660972, -3.374861],
                [10.660140000000126, -3.373751],
                [10.659862, -3.372639],
                [10.65986, -3.371251],
                [10.659306, -3.370694],
                [10.659306, -3.36736],
                [10.659584000000109, -3.36736],
                [10.659584000000109, -3.365415999999868],
                [10.65986, -3.365415999999868],
                [10.659862, -3.357362],
                [10.660972, -3.357362],
                [10.660972, -3.353195],
                [10.659862, -3.353195],
                [10.659862, -3.352638999999897],
                [10.660694000000149, -3.350972999999897],
                [10.660694000000149, -3.349029],
                [10.660972, -3.349027],
                [10.660972, -3.347082999999827],
                [10.660416, -3.346529],
                [10.660416, -3.344307],
                [10.659306, -3.344029],
                [10.658472000000131, -3.344582],
                [10.658472000000131, -3.348750999999879],
                [10.659028000000148, -3.349582999999825],
                [10.65875, -3.350416999999879],
                [10.659028000000148, -3.351527],
                [10.659028000000148, -3.353195],
                [10.657362000000148, -3.353195],
                [10.657362000000148, -3.354027],
                [10.658472000000131, -3.355416999999875],
                [10.658472000000131, -3.36486],
                [10.658194, -3.364862],
                [10.658194, -3.366806],
                [10.657918000000109, -3.366806],
                [10.657918000000109, -3.371251],
                [10.658472000000131, -3.371807],
                [10.658472000000131, -3.373195],
                [10.659584000000109, -3.375417],
                [10.659584000000109, -3.376527],
                [10.660140000000126, -3.376805],
                [10.660694000000149, -3.378195],
                [10.660694000000149, -3.380139],
                [10.66125000000011, -3.380694999999832],
                [10.661528, -3.381805],
                [10.661528, -3.383471],
                [10.662362000000144, -3.384305],
                [10.662362000000144, -3.387916],
                [10.66291600000011, -3.388193999999885],
                [10.66291600000011, -3.390972],
                [10.662362000000144, -3.391806],
                [10.662362000000144, -3.39319399999988],
                [10.661528, -3.395138],
                [10.661528, -3.396807],
                [10.66125000000011, -3.397916],
                [10.660140000000126, -3.399028999999871],
                [10.659862, -3.399860999999874],
                [10.659306, -3.399860999999874],
                [10.659028000000148, -3.400973],
                [10.658194, -3.401805],
                [10.658194, -3.403473],
                [10.657638, -3.403751],
                [10.657362000000148, -3.404860999999869],
                [10.656806000000131, -3.405139],
                [10.656250000000114, -3.406249],
                [10.655971000000136, -3.407360999999867],
                [10.655971000000136, -3.409583],
                [10.65514000000013, -3.409861],
                [10.65514000000013, -3.411806],
                [10.655693, -3.412084],
                [10.655415000000175, -3.413194],
                [10.655415000000175, -3.415694],
                [10.654862, -3.415972],
                [10.654862, -3.416528],
                [10.654305000000136, -3.417638],
                [10.653749000000175, -3.418194],
                [10.653471, -3.419305999999835],
                [10.652639000000136, -3.41986],
                [10.652639000000136, -3.420416],
                [10.652083000000175, -3.420694],
                [10.652083000000175, -3.42125],
                [10.650695, -3.422637999999836],
                [10.650139, -3.422637999999836],
                [10.649583, -3.423194],
                [10.649027, -3.424306999999885],
                [10.648471, -3.42486],
                [10.648471, -3.425417],
                [10.647917, -3.425972999999885],
                [10.647361, -3.428472999999883],
                [10.645973, -3.429861],
                [10.645973, -3.430417],
                [10.644861000000162, -3.431527],
                [10.641529000000162, -3.433195],
                [10.640416, -3.434027],
                [10.640416, -3.435417],
                [10.642082, -3.436804999999879],
                [10.642082, -3.437361],
                [10.643195000000162, -3.438470999999879],
                [10.644029, -3.438749],
                [10.644029, -3.439305999999874],
                [10.644861000000162, -3.439862],
                [10.646805, -3.441805999999872],
                [10.648195000000158, -3.442916],
                [10.648751000000175, -3.442916],
                [10.649027, -3.44375],
                [10.650695, -3.44514],
                [10.652083000000175, -3.445416],
                [10.652083000000175, -3.445972],
                [10.652639000000136, -3.446528],
                [10.653196, -3.446528],
                [10.653471, -3.447084],
                [10.654027, -3.447084],
                [10.654583, -3.447916],
                [10.655693, -3.448194],
                [10.655971000000136, -3.44875],
                [10.657084, -3.449028],
                [10.658472000000131, -3.450138],
                [10.659306, -3.451250999999843],
                [10.660140000000126, -3.451529],
                [10.660416, -3.452082],
                [10.660972, -3.452082],
                [10.661528, -3.452916999999843],
                [10.662638, -3.453473],
                [10.663194, -3.453473],
                [10.664306, -3.454861],
                [10.665694000000144, -3.455416999999841],
                [10.6670850000001, -3.456805],
                [10.668194, -3.456805],
                [10.6687510000001, -3.457639],
                [10.669861, -3.457916999999895],
                [10.670695, -3.458748999999841],
                [10.671805, -3.459305],
                [10.672917, -3.460693],
                [10.673751, -3.460695],
                [10.674305000000118, -3.461527],
                [10.674861, -3.461527],
                [10.675139000000172, -3.462082999999893],
                [10.676805000000172, -3.462639],
                [10.677083, -3.463195],
                [10.677639000000113, -3.463195],
                [10.678193, -3.464027],
                [10.679305000000113, -3.464861999999812],
                [10.680139000000167, -3.464861999999812],
                [10.680417, -3.465417999999886],
                [10.680971000000113, -3.465417999999886],
                [10.681527, -3.465971],
                [10.682640000000163, -3.466527999999812],
                [10.683193, -3.467083999999886],
                [10.683193, -3.46764],
                [10.684306000000163, -3.467917999999884],
                [10.684584, -3.468472],
                [10.68625, -3.469306],
                [10.68708400000014, -3.469306],
                [10.689306000000158, -3.471528],
                [10.690416000000141, -3.471806],
                [10.690694, -3.472362],
                [10.691806, -3.472638],
                [10.692084000000136, -3.473194],
                [10.692916, -3.473472],
                [10.692916, -3.474028],
                [10.693472, -3.474583999999879],
                [10.694307, -3.474583999999879],
                [10.695138, -3.475694],
                [10.697083, -3.476528],
                [10.697917000000132, -3.47736],
                [10.69902900000011, -3.47791599999988],
                [10.699583000000132, -3.478473],
                [10.70013900000015, -3.478473],
                [10.700417, -3.479029],
                [10.700973, -3.479029],
                [10.701251000000127, -3.47958199999988],
                [10.702083, -3.47958199999988],
                [10.703195, -3.480695],
                [10.704305, -3.481529],
                [10.704861, -3.481529],
                [10.705973, -3.482917],
                [10.706805, -3.482917],
                [10.707083, -3.483473],
                [10.707639, -3.483473],
                [10.707915000000128, -3.484305],
                [10.709027000000106, -3.484861],
                [10.7098620000001, -3.485695],
                [10.711250000000177, -3.485973],
                [10.7115280000001, -3.486527],
                [10.712084000000118, -3.486527],
                [10.712362, -3.487083],
                [10.712916000000178, -3.487083],
                [10.713750000000118, -3.487917],
                [10.714860000000101, -3.488473],
                [10.716528, -3.490139],
                [10.717362, -3.490139],
                [10.719028, -3.492084],
                [10.71986, -3.492084],
                [10.720972, -3.493192999999849],
                [10.721529000000146, -3.493192999999849],
                [10.721807, -3.49375],
                [10.722638, -3.494028],
                [10.722638, -3.494584],
                [10.723473, -3.494861999999898],
                [10.724583, -3.49625],
                [10.725139, -3.49625],
                [10.725417000000164, -3.496806],
                [10.727083000000164, -3.497361999999896],
                [10.727639000000124, -3.497916],
                [10.727639000000124, -3.498472],
                [10.729027, -3.49875],
                [10.73097300000012, -3.500693999999896],
                [10.731805, -3.500693999999896],
                [10.731805, -3.50125],
                [10.73263900000012, -3.502084],
                [10.733195000000137, -3.502084],
                [10.73430500000012, -3.502916],
                [10.735696000000132, -3.504306999999869],
                [10.736806000000115, -3.504582],
                [10.737084, -3.505416],
                [10.73763700000012, -3.505416],
                [10.737918, -3.505972999999869],
                [10.738472000000115, -3.505972999999869],
                [10.73875, -3.506528999999887],
                [10.739306, -3.506528999999887],
                [10.739862, -3.507360999999889],
                [10.74125, -3.508751],
                [10.742916, -3.50930499999987],
                [10.743194, -3.509860999999887],
                [10.74375, -3.509860999999887],
                [10.744306, -3.510417],
                [10.744582, -3.511251],
                [10.745416, -3.512083],
                [10.745972, -3.512083],
                [10.74625, -3.512639],
                [10.746804000000111, -3.512639],
                [10.747916, -3.513749],
                [10.748751, -3.514026999999885],
                [10.748751, -3.514583],
                [10.749307000000101, -3.515139],
                [10.750417, -3.515417],
                [10.750417, -3.515971],
                [10.751251, -3.516526999999883],
                [10.752361000000178, -3.517639],
                [10.753751, -3.517918],
                [10.754305000000102, -3.518749],
                [10.754861, -3.518749],
                [10.755139, -3.519306],
                [10.756805, -3.520972],
                [10.757917000000191, -3.521528],
                [10.758749, -3.522639999999853],
                [10.759583, -3.522639999999853],
                [10.760139000000152, -3.523472],
                [10.760695000000169, -3.52375],
                [10.763193, -3.52625],
                [10.76375000000013, -3.52625],
                [10.764028, -3.526805999999851],
                [10.764584, -3.526805999999851],
                [10.765138, -3.527362],
                [10.765138, -3.527916],
                [10.766528000000164, -3.52875],
                [10.767084000000125, -3.52875],
                [10.76736, -3.529306],
                [10.769028, -3.530972],
                [10.769584, -3.530972],
                [10.770138, -3.531807],
                [10.770694, -3.531807],
                [10.77236, -3.533751],
                [10.773472, -3.534583],
                [10.774304000000143, -3.534583],
                [10.77486000000016, -3.535417],
                [10.77541600000012, -3.535695],
                [10.77652600000016, -3.536805],
                [10.77708200000012, -3.537638999999899],
                [10.778195, -3.537916999999823],
                [10.779305, -3.539027],
                [10.779583000000116, -3.539582999999823],
                [10.780139000000133, -3.539582999999823],
                [10.781807000000128, -3.541248999999823],
                [10.782639000000188, -3.541527],
                [10.782639000000188, -3.542082999999821],
                [10.783749, -3.543195],
                [10.784861, -3.543470999999897],
                [10.785417, -3.544304999999895],
                [10.786527, -3.54514],
                [10.787639000000183, -3.545693],
                [10.787639000000183, -3.546248999999875],
                [10.78847100000013, -3.54680599999989],
                [10.789027, -3.54680599999989],
                [10.789305000000184, -3.547362],
                [10.789862, -3.547362],
                [10.790972, -3.54847199999989],
                [10.791806000000179, -3.549583999999868],
                [10.792362, -3.549583999999868],
                [10.79264, -3.55014],
                [10.793194, -3.55014],
                [10.79486, -3.552084],
                [10.795972, -3.55264],
                [10.797638, -3.554306],
                [10.798472000000174, -3.55486],
                [10.799028, -3.555694],
                [10.800138000000175, -3.556528],
                [10.800694, -3.556528],
                [10.802082, -3.557916],
                [10.802638, -3.558194],
                [10.80319500000013, -3.559029],
                [10.803751000000148, -3.559029],
                [10.805417000000148, -3.560973],
                [10.805973000000165, -3.560973],
                [10.806249, -3.561529],
                [10.806805, -3.561529],
                [10.807083000000148, -3.562082999999859],
                [10.808195000000126, -3.563195],
                [10.808751000000143, -3.563195],
                [10.811249, -3.565695],
                [10.811805, -3.565695],
                [10.812639000000104, -3.566527],
                [10.812639000000104, -3.567083],
                [10.813749000000143, -3.567917],
                [10.814305000000104, -3.567917],
                [10.814861000000121, -3.568749],
                [10.816527000000121, -3.570417],
                [10.81764, -3.570695],
                [10.818472, -3.571249],
                [10.820694000000117, -3.573471],
                [10.820972, -3.574027999999828],
                [10.821528, -3.574027999999828],
                [10.821806, -3.574584],
                [10.823472, -3.575693999999828],
                [10.82625, -3.578472],
                [10.827916, -3.579306],
                [10.829307, -3.58069399999988],
                [10.830138, -3.580972],
                [10.830138, -3.581527999999878],
                [10.831805, -3.582638],
                [10.832083, -3.583193999999878],
                [10.834029000000157, -3.584581999999898],
                [10.83458300000018, -3.585415999999896],
                [10.835139, -3.585415999999896],
                [10.838749, -3.589026999999874],
                [10.839305, -3.589305],
                [10.841252, -3.591251],
                [10.842083, -3.591805],
                [10.84264000000013, -3.591805],
                [10.842918, -3.592639],
                [10.843749, -3.593473],
                [10.844862000000148, -3.593749],
                [10.844862000000148, -3.594305],
                [10.84625, -3.595139],
                [10.847362, -3.596249],
                [10.848194000000149, -3.597360999999864],
                [10.84875000000011, -3.597360999999864],
                [10.849584, -3.598193],
                [10.850418000000104, -3.599584],
                [10.851528000000144, -3.599862],
                [10.851806, -3.600971],
                [10.853194000000144, -3.60125],
                [10.853750000000105, -3.602084],
                [10.854860000000144, -3.603194],
                [10.855416000000105, -3.603194],
                [10.855972000000122, -3.60375],
                [10.856529, -3.604862],
                [10.857082000000105, -3.604862],
                [10.859861, -3.607638],
                [10.860139000000117, -3.608194],
                [10.861251, -3.60875],
                [10.861805000000118, -3.609305999999833],
                [10.862917, -3.609584],
                [10.863751, -3.610416],
                [10.863751, -3.610971999999833],
                [10.864305, -3.61125],
                [10.865139000000113, -3.61236],
                [10.865973000000167, -3.612637999999834],
                [10.865973000000167, -3.613195],
                [10.867083, -3.614303999999834],
                [10.867639000000167, -3.614582],
                [10.870418, -3.617361],
                [10.870974, -3.617361],
                [10.87125, -3.618195],
                [10.871806000000163, -3.618195],
                [10.872084, -3.618749],
                [10.873472000000163, -3.620138999999881],
                [10.87402800000018, -3.620417],
                [10.87625, -3.622638999999879],
                [10.877362000000176, -3.623195],
                [10.87986, -3.625696],
                [10.880694000000176, -3.626804999999877],
                [10.882082, -3.627915],
                [10.883194, -3.629305999999872],
                [10.883751000000132, -3.629305999999872],
                [10.884307000000149, -3.630416],
                [10.884861000000114, -3.630971999999872],
                [10.885417000000132, -3.630971999999872],
                [10.88652900000011, -3.632084],
                [10.88652900000011, -3.632637999999872],
                [10.88819500000011, -3.63375],
                [10.889027, -3.634584],
                [10.88930500000015, -3.635416],
                [10.890973000000145, -3.636528],
                [10.891249, -3.637084],
                [10.891805, -3.637084],
                [10.892639000000145, -3.637916],
                [10.892639000000145, -3.638472],
                [10.893195000000105, -3.639029],
                [10.893749000000128, -3.639029],
                [10.894861000000105, -3.640138],
                [10.895139, -3.640695],
                [10.896527000000106, -3.642083],
                [10.89764, -3.642361],
                [10.89764, -3.642917],
                [10.898193000000106, -3.643195],
                [10.898193000000106, -3.643750999999838],
                [10.8990280000001, -3.644027],
                [10.8990280000001, -3.644583],
                [10.899584000000118, -3.644861],
                [10.899584000000118, -3.645416999999839],
                [10.900972, -3.646527],
                [10.902084000000173, -3.646805],
                [10.903194, -3.648195],
                [10.90430600000019, -3.649027],
                [10.90430600000019, -3.649582999999836],
                [10.905138, -3.650416999999891],
                [10.905694, -3.650416999999891],
                [10.906250000000114, -3.650971],
                [10.906250000000114, -3.651527],
                [10.90736, -3.65264],
                [10.907916000000114, -3.65264],
                [10.908472, -3.653471],
                [10.910416000000168, -3.655417999999884],
                [10.911529, -3.656249999999886],
                [10.912917000000164, -3.657638],
                [10.912917000000164, -3.657915999999887],
                [10.914305, -3.659306],
                [10.914583000000164, -3.659306],
                [10.915971, -3.660694],
                [10.915973, -3.660972],
                [10.917361000000142, -3.66236],
                [10.917917000000159, -3.662638],
                [10.917917000000159, -3.663194],
                [10.918749, -3.663472],
                [10.919029000000137, -3.664028],
                [10.920417, -3.66486],
                [10.920417, -3.66541599999988],
                [10.922639, -3.667638],
                [10.922639, -3.667916999999875],
                [10.927916, -3.673195],
                [10.929028, -3.674583],
                [10.929862000000128, -3.674861],
                [10.930138, -3.675973],
                [10.930694, -3.676527],
                [10.931528000000128, -3.676527],
                [10.93236, -3.677361],
                [10.932362, -3.677917],
                [10.933194000000128, -3.679027],
                [10.93375, -3.679305],
                [10.934860000000128, -3.680693],
                [10.93625, -3.682084],
                [10.936529000000178, -3.682084],
                [10.937082, -3.683193999999844],
                [10.942917, -3.689027999999894],
                [10.943195000000173, -3.689584],
                [10.944305, -3.690416],
                [10.944861000000174, -3.691527999999892],
                [10.946249, -3.692916],
                [10.946249, -3.693472],
                [10.947639, -3.694582],
                [10.947639, -3.695138999999813],
                [10.948752, -3.696528999999885],
                [10.950693, -3.698473],
                [10.950974, -3.699026999999887],
                [10.953472000000147, -3.701526999999885],
                [10.954028000000164, -3.701805],
                [10.954306, -3.702917],
                [10.955140000000142, -3.704026999999883],
                [10.956250000000125, -3.705139],
                [10.956806000000142, -3.706249],
                [10.95736200000016, -3.70652699999988],
                [10.95736200000016, -3.707083],
                [10.957916000000125, -3.707362],
                [10.95958400000012, -3.709028],
                [10.95958400000012, -3.709584],
                [10.960416, -3.71014],
                [10.960416, -3.710972],
                [10.960972, -3.711528],
                [10.961528, -3.711528],
                [10.962082, -3.712084],
                [10.962084, -3.712638],
                [10.962638, -3.712916],
                [10.962638, -3.713472],
                [10.96402600000016, -3.714862],
                [10.96486, -3.715972],
                [10.966805, -3.717916],
                [10.966805, -3.718471999999849],
                [10.967361, -3.71875],
                [10.969027, -3.720973],
                [10.969027, -3.721529],
                [10.970695, -3.723195],
                [10.970695, -3.723749],
                [10.972639000000129, -3.725417],
                [10.972917, -3.726527],
                [10.974861, -3.728472999999895],
                [10.975417000000107, -3.728472999999895],
                [10.975695, -3.729583],
                [10.977083000000107, -3.730972999999892],
                [10.977083000000107, -3.731527],
                [10.977640000000179, -3.731804999999895],
                [10.977640000000179, -3.732361],
                [10.97875, -3.733193],
                [10.97875, -3.733748999999818],
                [10.979584000000102, -3.734027],
                [10.980416, -3.735693],
                [10.981806, -3.737083999999868],
                [10.982084, -3.737639999999885],
                [10.982916000000102, -3.737915999999871],
                [10.982916000000102, -3.738471999999888],
                [10.983472, -3.738749999999868],
                [10.983472, -3.739305999999885],
                [10.984306000000174, -3.739584],
                [10.985138, -3.740415999999868],
                [10.985138, -3.740971999999886],
                [10.98736, -3.743194],
                [10.987916, -3.743471999999883],
                [10.987916, -3.744028],
                [10.989582, -3.745137999999884],
                [10.989582, -3.745694],
                [10.990138, -3.74625],
                [10.991251000000148, -3.746807],
                [10.991251000000148, -3.74736],
                [10.991807000000165, -3.747638],
                [10.992082000000153, -3.748473],
                [10.992639, -3.748473],
                [10.992917000000148, -3.749583],
                [10.994305, -3.750973],
                [10.994861, -3.751250999999854],
                [10.995139000000165, -3.752361],
                [10.995695000000126, -3.752916999999854],
                [10.996251000000143, -3.754027],
                [10.996805000000165, -3.754305],
                [10.996805000000165, -3.754861],
                [10.997639, -3.755416999999852],
                [10.99847300000016, -3.757361],
                [10.999305, -3.758195],
                [10.999305, -3.758748999999853],
                [10.999861, -3.759305],
                [11.000417, -3.759305],
                [11.000695000000121, -3.760971],
                [11.001249000000143, -3.760971],
                [11.002361000000121, -3.762362],
                [11.002361000000121, -3.762915],
                [11.002917000000139, -3.763472],
                [11.003749, -3.76375],
                [11.003749, -3.764306],
                [11.005693000000122, -3.76625],
                [11.005693000000122, -3.766806],
                [11.00625, -3.767083999999898],
                [11.006528000000117, -3.767916],
                [11.007916, -3.769306],
                [11.008472, -3.769583999999895],
                [11.009306, -3.770693999999821],
                [11.009306, -3.771249999999895],
                [11.011806, -3.773749999999893],
                [11.011806, -3.774307],
                [11.013194000000112, -3.775416999999891],
                [11.013194000000112, -3.775973],
                [11.01375000000013, -3.776805],
                [11.014304, -3.776805],
                [11.014584000000184, -3.777916999999889],
                [11.015694, -3.778751],
                [11.015694, -3.779305],
                [11.016250000000184, -3.779582999999889],
                [11.016250000000184, -3.780139],
                [11.016807, -3.780695],
                [11.01736, -3.783195],
                [11.018195, -3.783473],
                [11.018195, -3.784583],
                [11.019304, -3.784861],
                [11.019861, -3.786527],
                [11.01708200000013, -3.786527],
                [11.01708200000013, -3.788193],
                [11.016250000000184, -3.789306],
                [11.016250000000184, -3.790418],
                [11.015694, -3.790972],
                [11.015694, -3.792084],
                [11.014860000000112, -3.793193999999858],
                [11.014028, -3.79375],
                [11.01375000000013, -3.795138],
                [11.012916000000189, -3.795972],
                [11.012638, -3.797638],
                [11.01208400000013, -3.798194],
                [11.010972, -3.800416],
                [11.010138, -3.801529],
                [11.010138, -3.80236],
                [11.009584000000189, -3.802638999999829],
                [11.009306, -3.804304999999829],
                [11.008750000000134, -3.804583],
                [11.008750000000134, -3.805972999999881],
                [11.008196000000112, -3.806527],
                [11.008194000000117, -3.810694999999896],
                [11.00764, -3.813470999999879],
                [11.007084000000134, -3.814583],
                [11.007084000000134, -3.817639999999869],
                [11.00764, -3.818471999999872],
                [11.00764, -3.819584],
                [11.007916, -3.820694],
                [11.008472, -3.821528],
                [11.008750000000134, -3.82263799999987],
                [11.008750000000134, -3.82375],
                [11.009306, -3.824584],
                [11.009306, -3.825694],
                [11.01041800000013, -3.827082],
                [11.010694, -3.828473],
                [11.01208400000013, -3.829582],
                [11.01208400000013, -3.830139],
                [11.012638, -3.830417],
                [11.012638, -3.830973],
                [11.014028, -3.832083],
                [11.014028, -3.832639],
                [11.014584000000184, -3.832917],
                [11.014584000000184, -3.833473],
                [11.01541600000013, -3.834583],
                [11.015972, -3.834861],
                [11.015972, -3.835417],
                [11.016528000000108, -3.835695],
                [11.016528000000108, -3.836249],
                [11.01708200000013, -3.836805],
                [11.017916000000184, -3.838471],
                [11.01875100000018, -3.839027],
                [11.019304, -3.840416999999832],
                [11.019304, -3.841527],
                [11.019861, -3.841805],
                [11.02041700000018, -3.842917999999884],
                [11.02041700000018, -3.844862],
                [11.020695000000103, -3.844862],
                [11.020695000000103, -3.846528],
                [11.020973, -3.846528],
                [11.020973, -3.848194],
                [11.021251, -3.848194],
                [11.021251, -3.850415999999882],
                [11.021529000000157, -3.850415999999882],
                [11.021529000000157, -3.855138],
                [11.021251, -3.856529],
                [11.021805, -3.857361],
                [11.021805, -3.858473],
                [11.02208300000018, -3.859029],
                [11.024027000000103, -3.86041699999987],
                [11.024305, -3.861527],
                [11.025973, -3.861805],
                [11.026527000000158, -3.862916999999868],
                [11.027083000000175, -3.862916999999868],
                [11.027361, -3.863473],
                [11.028471, -3.863748999999871],
                [11.029305, -3.864861],
                [11.030418, -3.865417],
                [11.031527000000153, -3.866248999999868],
                [11.03264, -3.867361],
                [11.033472000000131, -3.867639],
                [11.033472000000131, -3.868193],
                [11.035694000000149, -3.87014],
                [11.036250000000166, -3.87014],
                [11.038194, -3.872084],
                [11.039306, -3.872916],
                [11.040140000000122, -3.874027999999839],
                [11.041250000000105, -3.874584],
                [11.041806000000122, -3.874584],
                [11.042084, -3.875138],
                [11.043194, -3.875416],
                [11.044307000000117, -3.876806],
                [11.0454170000001, -3.877084],
                [11.045695, -3.877638],
                [11.046805, -3.87875],
                [11.047917, -3.879306],
                [11.048473, -3.880138],
                [11.049583, -3.88125],
                [11.048751, -3.88125],
                [11.047639000000117, -3.880138],
                [11.047361, -3.88125],
                [11.047917, -3.88125],
                [11.049027, -3.882917],
                [11.050139, -3.883473],
                [11.05069500000019, -3.884028999999885],
                [11.051251, -3.884028999999885],
                [11.053473, -3.886251],
                [11.05402700000019, -3.887083],
                [11.054583, -3.887360999999885],
                [11.056249, -3.889026999999885],
                [11.057640000000163, -3.889583],
                [11.058749, -3.89069499999988],
                [11.059306000000163, -3.890973],
                [11.060972000000163, -3.892639],
                [11.06152800000018, -3.892639],
                [11.061806000000104, -3.893194999999878],
                [11.062362, -3.893194999999878],
                [11.063472000000104, -3.894583],
                [11.064028, -3.894583],
                [11.065138000000104, -3.895971],
                [11.065972000000158, -3.896249],
                [11.065972000000158, -3.896806],
                [11.066806, -3.89764],
                [11.06736, -3.89764],
                [11.068194000000176, -3.89875],
                [11.069028, -3.89875],
                [11.069028, -3.899306],
                [11.069582, -3.899584],
                [11.069582, -3.90014],
                [11.070694, -3.900693999999874],
                [11.071529, -3.901528],
                [11.072639, -3.901806],
                [11.072917000000132, -3.902362],
                [11.07347300000015, -3.902362],
                [11.074583000000132, -3.90375],
                [11.07569500000011, -3.904584],
                [11.076251000000127, -3.904584],
                [11.076527, -3.905138],
                [11.078473000000145, -3.905972],
                [11.079861, -3.905972],
                [11.080695000000105, -3.90625],
                [11.081527, -3.907082],
                [11.082639, -3.907082],
                [11.0848620000001, -3.905972],
                [11.0848620000001, -3.905416],
                [11.085418000000118, -3.905138],
                [11.085693000000106, -3.904306],
                [11.086806, -3.904028],
                [11.087084000000118, -3.903194],
                [11.087084000000118, -3.90125],
                [11.08764, -3.90125],
                [11.08764, -3.902638],
                [11.088472, -3.904306],
                [11.089028, -3.90486],
                [11.090138, -3.905416],
                [11.090694, -3.906528],
                [11.091528, -3.906806],
                [11.091528, -3.908194],
                [11.090972, -3.908194],
                [11.090416000000118, -3.907638],
                [11.090416000000118, -3.906806],
                [11.089862, -3.906528],
                [11.089862, -3.905972],
                [11.089306, -3.905694],
                [11.0865280000001, -3.905694],
                [11.0848620000001, -3.90736],
                [11.084027000000106, -3.90736],
                [11.083471000000145, -3.907916],
                [11.082915000000128, -3.909306999999842],
                [11.082915000000128, -3.910417],
                [11.083471000000145, -3.911251],
                [11.084305, -3.911251],
                [11.084583000000123, -3.911804999999845],
                [11.085693000000106, -3.912083],
                [11.086806, -3.912638999999842],
                [11.086806, -3.913472999999897],
                [11.088196, -3.913751],
                [11.089306, -3.915138999999897],
                [11.089862, -3.915138999999897],
                [11.090416000000118, -3.915972999999895],
                [11.090972, -3.915972999999895],
                [11.092084000000114, -3.917361],
                [11.093194, -3.917638999999895],
                [11.09347200000019, -3.918195],
                [11.094028, -3.918195],
                [11.094306, -3.918749],
                [11.09513800000019, -3.919027],
                [11.09513800000019, -3.919583],
                [11.095972, -3.919583],
                [11.097082000000114, -3.920693],
                [11.097082000000114, -3.920970999999895],
                [11.098194, -3.922083],
                [11.098751000000163, -3.922083],
                [11.099029, -3.922639999999888],
                [11.100139, -3.922917999999811],
                [11.100417000000164, -3.923470999999893],
                [11.101529, -3.923749999999814],
                [11.101805, -3.924583999999811],
                [11.102361, -3.924583999999811],
                [11.103195, -3.925694],
                [11.105139, -3.926528],
                [11.106805, -3.927915999999811],
                [11.108751000000154, -3.92875],
                [11.109583, -3.929584],
                [11.110696000000132, -3.929862],
                [11.111249, -3.930416],
                [11.11291800000015, -3.930694],
                [11.113472000000115, -3.93125],
                [11.114306, -3.931528],
                [11.11458400000015, -3.932637999999884],
                [11.115694000000133, -3.932916],
                [11.115972, -3.933471999999881],
                [11.115972, -3.934582],
                [11.11680600000011, -3.934585],
                [11.11680600000011, -3.935137999999881],
                [11.117918000000145, -3.935973],
                [11.11847200000011, -3.935973],
                [11.11875, -3.936529],
                [11.119306, -3.936529],
                [11.120416, -3.937361],
                [11.120694000000128, -3.937917],
                [11.121250000000146, -3.937917],
                [11.121806000000106, -3.938751],
                [11.122638, -3.938751],
                [11.122916000000146, -3.939305],
                [11.12375, -3.939583],
                [11.124582000000146, -3.940695],
                [11.125138000000106, -3.940695],
                [11.125416, -3.941251],
                [11.125973000000101, -3.941251],
                [11.126251, -3.941805],
                [11.127361, -3.942361],
                [11.128195000000119, -3.943195],
                [11.128751, -3.943195],
                [11.13125100000019, -3.945695],
                [11.132361000000174, -3.946249],
                [11.132917000000191, -3.946249],
                [11.133473, -3.94708299999985],
                [11.134305, -3.94708299999985],
                [11.135417, -3.948471],
                [11.136249, -3.948471],
                [11.136527000000115, -3.949027],
                [11.137083, -3.949027],
                [11.137361000000169, -3.949584],
                [11.13902700000017, -3.950418],
                [11.139862000000164, -3.950418],
                [11.14014, -3.950972],
                [11.141806, -3.951806],
                [11.141806, -3.952362],
                [11.143194000000165, -3.952362],
                [11.144306000000142, -3.95375],
                [11.145138, -3.95375],
                [11.145972000000143, -3.954583999999898],
                [11.14708400000012, -3.954583999999898],
                [11.147640000000138, -3.954306],
                [11.150694, -3.954306],
                [11.150694, -3.955138],
                [11.151529, -3.955972],
                [11.152638000000138, -3.955972],
                [11.152917000000116, -3.954862],
                [11.154583000000116, -3.953472],
                [11.154305, -3.952084],
                [11.154305, -3.950693],
                [11.156527, -3.950693],
                [11.156527, -3.950972],
                [11.159305, -3.950972],
                [11.159861, -3.95125],
                [11.160695, -3.952362],
                [11.162361, -3.95264],
                [11.162917000000107, -3.954306],
                [11.162917000000107, -3.956806],
                [11.163749, -3.957915999999898],
                [11.164583000000107, -3.958194],
                [11.165693, -3.958194],
                [11.166806000000179, -3.957638],
                [11.166806000000179, -3.958472],
                [11.165971, -3.958749999999895],
                [11.164305, -3.958749999999895],
                [11.163195, -3.958472],
                [11.162915000000112, -3.957638],
                [11.161249000000112, -3.957083999999895],
                [11.161249000000112, -3.956249999999898],
                [11.160417, -3.955138],
                [11.159583000000111, -3.955416],
                [11.159583000000111, -3.956528],
                [11.158473000000129, -3.956249999999898],
                [11.157917000000111, -3.955694],
                [11.156527, -3.955972],
                [11.156527, -3.957361999999819],
                [11.155973, -3.957915999999898],
                [11.154305, -3.957915999999898],
                [11.154305, -3.958749999999895],
                [11.153473000000133, -3.958472],
                [11.153473000000133, -3.957915999999898],
                [11.152917000000116, -3.957361999999819],
                [11.151807000000133, -3.957361999999819],
                [11.150972000000138, -3.958194],
                [11.151807000000133, -3.958472],
                [11.152917000000116, -3.959583999999893],
                [11.15402900000015, -3.95986],
                [11.154305, -3.960415999999896],
                [11.154861, -3.960415999999896],
                [11.15569500000015, -3.961249999999893],
                [11.156527, -3.961249999999893],
                [11.156807000000128, -3.961807],
                [11.157917000000111, -3.962081999999896],
                [11.158195, -3.962638],
                [11.158749, -3.962638],
                [11.159305, -3.963473],
                [11.160139000000129, -3.963473],
                [11.160417, -3.964028999999869],
                [11.160973, -3.964028999999869],
                [11.161249000000112, -3.964582999999891],
                [11.162917000000107, -3.965416999999889],
                [11.16347100000013, -3.965973],
                [11.164583000000107, -3.966250999999886],
                [11.164861, -3.966805],
                [11.165418000000102, -3.966805],
                [11.165693, -3.967360999999869],
                [11.166806000000179, -3.967639],
                [11.167084000000102, -3.968195],
                [11.16764000000012, -3.968195],
                [11.167916, -3.968750999999884],
                [11.168472000000179, -3.968750999999884],
                [11.169028, -3.969582999999886],
                [11.169584, -3.969582999999886],
                [11.169862, -3.970139],
                [11.17097200000012, -3.970416999999884],
                [11.17125, -3.970973],
                [11.172362, -3.971251],
                [11.173472000000174, -3.971805],
                [11.175138000000175, -3.973471],
                [11.176528, -3.973748999999884],
                [11.17847200000017, -3.975693],
                [11.179585, -3.975971],
                [11.17986, -3.976527],
                [11.180973000000165, -3.977362],
                [11.182083000000148, -3.977918],
                [11.182639000000165, -3.977918],
                [11.183195000000126, -3.97875],
                [11.184305000000165, -3.979028],
                [11.184583, -3.979584],
                [11.185695, -3.980416],
                [11.188471, -3.981806],
                [11.189027, -3.981806],
                [11.190139, -3.982916],
                [11.190417000000139, -3.983472],
                [11.191527000000121, -3.984027999999853],
                [11.192637000000161, -3.98375],
                [11.193471, -3.982638],
                [11.193471, -3.980972],
                [11.194028000000117, -3.980694],
                [11.19514, -3.980694],
                [11.194862, -3.981527999999855],
                [11.194862, -3.982638],
                [11.194028000000117, -3.982638],
                [11.19375, -3.983193999999855],
                [11.19375, -3.985693999999853],
                [11.194306, -3.98625],
                [11.195416, -3.986528],
                [11.196528, -3.987916],
                [11.19791800000013, -3.988194],
                [11.200138, -3.990417],
                [11.200858, -3.990695],
                [11.204929, -3.978802],
                [11.205005799000105, -3.978806860999953],
                [11.212369918000036, -3.955177068999944],
                [11.21350288400015, -3.932163952999929],
                [11.223579, -3.917664],
                [11.227008819000048, -3.907537935999926],
                [11.22723484, -3.89879488899993],
                [11.22424221, -3.878774880999913],
                [11.227897644000109, -3.858985900999926],
                [11.233287810999968, -3.850769042999957],
              ],
            ],
            [
              [
                [10.67069499900009, -3.389584],
                [10.6704170000001, -3.389027998999893],
                [10.6704170000001, -3.387916000999894],
                [10.670139, -3.386806000999911],
                [10.6687510000001, -3.387084],
                [10.6687510000001, -3.387639998999816],
                [10.667915999, -3.389306000999966],
                [10.667915999, -3.39041599899997],
                [10.667360000000144, -3.390138],
                [10.667360000000144, -3.391527998999891],
                [10.668193999000096, -3.392084000999887],
                [10.668193999000096, -3.394305998999869],
                [10.667638, -3.394305998999869],
                [10.666803999000024, -3.395415999999898],
                [10.666528, -3.396527999999876],
                [10.665972, -3.396527999999876],
                [10.665972, -3.398194998999884],
                [10.666250000000105, -3.398194998999884],
                [10.666250000000105, -3.400694998999938],
                [10.666528, -3.401528999999869],
                [10.668473001000109, -3.401528999999869],
                [10.6687510000001, -3.400417],
                [10.669306999000185, -3.400417],
                [10.669582001000038, -3.399582998999961],
                [10.6687510000001, -3.398751],
                [10.6687510000001, -3.398194998999884],
                [10.669582001000038, -3.397916],
                [10.67069499900009, -3.396806998999978],
                [10.671251, -3.396806998999978],
                [10.671251, -3.395693998999889],
                [10.67069499900009, -3.395138],
                [10.6704170000001, -3.394027999999878],
                [10.6704170000001, -3.392638],
                [10.669582001000038, -3.392084000999887],
                [10.669582001000038, -3.39125],
                [10.6704170000001, -3.390972],
                [10.67069499900009, -3.389584],
              ],
            ],
            [
              [
                [9.15763800000019, -2.074028000999931],
                [9.15763800000019, -2.075137998999935],
                [9.158472001000177, -2.075137998999935],
                [9.159028, -2.074306],
                [9.159028, -2.072084],
                [9.158472001000177, -2.072084],
                [9.15763800000019, -2.074028000999931],
              ],
            ],
            [
              [
                [9.354861000000142, -1.983195],
                [9.354305000000181, -1.982638999999892],
                [9.354027, -1.981248999999821],
                [9.353473, -1.981248999999821],
                [9.353473, -1.982361],
                [9.353751000000159, -1.982916999999873],
                [9.354861000000142, -1.983748999999875],
                [9.35597300000012, -1.985139999999888],
                [9.35763900000012, -1.986805999999888],
                [9.35763900000012, -1.985693],
                [9.356249, -1.984027],
                [9.355695, -1.984027],
                [9.354861000000142, -1.983195],
              ],
            ],
            [
              [
                [10.270139, -2.977916999999877],
                [10.269305000000145, -2.976805],
                [10.268195000000162, -2.976805],
                [10.268195000000162, -2.977639],
                [10.269027, -2.978471],
                [10.269583, -2.978471],
                [10.269861000000162, -2.979027],
                [10.271249, -2.979027],
                [10.271249, -2.978471],
                [10.270695, -2.977916999999877],
                [10.270139, -2.977916999999877],
              ],
            ],
            [
              [
                [9.534862, 0.561528000000123],
                [9.535694, 0.56125],
                [9.536528, 0.562084000000141],
                [9.536804, 0.563194000000124],
                [9.535694, 0.563194000000124],
                [9.535416000000168, 0.562638000000106],
                [9.534028, 0.562362],
                [9.534028, 0.561528000000123],
                [9.534862, 0.561528000000123],
              ],
            ],
            [
              [
                [9.544861, 0.595138000000134],
                [9.544861, 0.598473],
                [9.54458300000016, 0.599305000000129],
                [9.542639, 0.599305000000129],
                [9.542361, 0.598195000000146],
                [9.541805000000181, 0.597917],
                [9.541529, 0.597082],
                [9.541529, 0.595972],
                [9.540695, 0.595416],
                [9.540695, 0.594582000000173],
                [9.540139000000181, 0.593472000000133],
                [9.53958200000011, 0.593194],
                [9.538194, 0.591806000000133],
                [9.538194, 0.590138000000138],
                [9.537638000000186, 0.589862],
                [9.537638000000186, 0.589028000000155],
                [9.537085, 0.588472000000138],
                [9.53736, 0.58764],
                [9.53791600000011, 0.587638],
                [9.538194, 0.587084],
                [9.538194, 0.582361000000105],
                [9.538751, 0.582361000000105],
                [9.539307, 0.581249],
                [9.541805000000181, 0.581249],
                [9.542639, 0.582083000000182],
                [9.542639, 0.583193000000165],
                [9.543195, 0.583749000000182],
                [9.543473000000176, 0.584862],
                [9.543473000000176, 0.586528],
                [9.544027, 0.586528],
                [9.544305, 0.587362000000155],
                [9.544305, 0.589028000000155],
                [9.544861, 0.589306],
                [9.544861, 0.592638],
                [9.545139000000177, 0.592638],
                [9.545139000000177, 0.594582000000173],
                [9.544861, 0.595138000000134],
              ],
            ],
            [
              [
                [9.012361000000169, -1.317084],
                [9.011805000000152, -1.316249999999855],
                [9.011805000000152, -1.315694],
                [9.011249, -1.315418],
                [9.009861, -1.315418],
                [9.009305, -1.316249999999855],
                [9.009305, -1.317084],
                [9.009861, -1.31764],
                [9.012083, -1.31764],
                [9.012361000000169, -1.317084],
              ],
            ],
            [
              [
                [9.006251000000134, -1.302917],
                [9.005417, -1.301804999999888],
                [9.004027000000178, -1.301804999999888],
                [9.003751, -1.302082999999868],
                [9.003751, -1.303473],
                [9.004583, -1.303751],
                [9.005973, -1.303751],
                [9.006527, -1.304304999999886],
                [9.007639, -1.304304999999886],
                [9.007639, -1.303473],
                [9.006805000000156, -1.303473],
                [9.006251000000134, -1.302917],
              ],
            ],
            [
              [
                [9.008193, -1.26819399999988],
                [9.007639, -1.267916],
                [9.007083, -1.267084],
                [9.005695000000173, -1.267359999999883],
                [9.004861, -1.26819399999988],
                [9.004861, -1.26875],
                [9.004027000000178, -1.269028],
                [9.003195, -1.269859999999881],
                [9.003195, -1.270416],
                [9.001804000000106, -1.271528],
                [9.000695000000178, -1.271528],
                [9.000973000000101, -1.270972],
                [9.000973000000101, -1.269859999999881],
                [8.999860000000183, -1.269859999999881],
                [8.999028, -1.270416],
                [8.999028, -1.270972],
                [8.998194000000183, -1.271528],
                [8.997084, -1.273195],
                [8.99625, -1.274029],
                [8.99513800000011, -1.273751],
                [8.99513800000011, -1.272638],
                [8.99625, -1.272638],
                [8.996528000000183, -1.272082],
                [8.99625, -1.270972],
                [8.995416, -1.27125],
                [8.993194000000187, -1.27125],
                [8.992638, -1.270972],
                [8.992084, -1.27125],
                [8.992362000000128, -1.272638],
                [8.992918, -1.272638],
                [8.993194000000187, -1.273751],
                [8.99375, -1.274029],
                [8.99625, -1.279027],
                [8.99625, -1.279583],
                [8.996806000000106, -1.280695],
                [8.998194000000183, -1.281804999999849],
                [8.99875, -1.282917],
                [8.99875, -1.283749],
                [8.999307000000101, -1.284305],
                [8.999582, -1.285971],
                [9.000416, -1.285971],
                [9.000416, -1.286805999999899],
                [9.000973000000101, -1.28791799999982],
                [9.001529, -1.288194],
                [9.001529, -1.289305999999897],
                [9.002361000000178, -1.290139999999894],
                [9.002639000000102, -1.290971999999897],
                [9.003473000000156, -1.291805999999895],
                [9.004307, -1.293749999999818],
                [9.004307, -1.295137999999895],
                [9.004861, -1.295137999999895],
                [9.005139000000156, -1.295971999999892],
                [9.005973, -1.295971999999892],
                [9.006251000000134, -1.296528],
                [9.007083, -1.296528],
                [9.007361000000174, -1.29708399999987],
                [9.008195, -1.29736],
                [9.010139000000152, -1.29736],
                [9.011249, -1.29708399999987],
                [9.013474, -1.295415999999818],
                [9.01402700000017, -1.294305999999892],
                [9.01514, -1.293471999999895],
                [9.015694, -1.292083999999818],
                [9.015694, -1.290416],
                [9.015972000000147, -1.289028],
                [9.016528000000164, -1.28791799999982],
                [9.016806, -1.286805999999899],
                [9.015972000000147, -1.285971],
                [9.015694, -1.284861],
                [9.01514, -1.284583],
                [9.01514, -1.284027],
                [9.01402700000017, -1.283749],
                [9.01402700000017, -1.282917],
                [9.013474, -1.282639],
                [9.012639, -1.281527],
                [9.012639, -1.280973],
                [9.011527, -1.280973],
                [9.010695000000169, -1.281804999999849],
                [9.009861, -1.281804999999849],
                [9.009583, -1.282361],
                [9.008749, -1.282361],
                [9.008749, -1.280973],
                [9.009861, -1.279861],
                [9.010417, -1.279583],
                [9.010417, -1.277638999999851],
                [9.010971, -1.276529],
                [9.010695000000169, -1.275139],
                [9.009861, -1.275139],
                [9.009861, -1.273473],
                [9.009583, -1.273473],
                [9.009583, -1.269584],
                [9.009027000000174, -1.269306],
                [9.008193, -1.26819399999988],
              ],
            ],
            [
              [
                [9.01125100000013, -1.251251],
                [9.010417, -1.250417],
                [9.009305, -1.250139],
                [9.008193, -1.251526999999896],
                [9.008193, -1.252360999999894],
                [9.007639, -1.252917],
                [9.007639, -1.254026999999894],
                [9.008193, -1.254860999999892],
                [9.008193, -1.257083],
                [9.007917, -1.257083],
                [9.007917, -1.25902699999989],
                [9.008473000000151, -1.259304999999813],
                [9.008473000000151, -1.259861999999885],
                [9.009305, -1.260418],
                [9.009305, -1.260971999999867],
                [9.010139000000152, -1.261806],
                [9.010139000000152, -1.265416],
                [9.011249, -1.26652799999988],
                [9.011805000000152, -1.26652799999988],
                [9.012918, -1.267916],
                [9.013193, -1.26875],
                [9.013749, -1.269028],
                [9.013749, -1.269584],
                [9.014306000000147, -1.270138],
                [9.014862000000164, -1.270138],
                [9.01514, -1.270694],
                [9.016528000000164, -1.270972],
                [9.019862000000103, -1.270972],
                [9.020416000000125, -1.270416],
                [9.02236, -1.270416],
                [9.023194000000103, -1.270138],
                [9.023194000000103, -1.269584],
                [9.02375000000012, -1.269028],
                [9.024862, -1.268472],
                [9.025138, -1.267359999999883],
                [9.025138, -1.265416],
                [9.024582, -1.264306],
                [9.023194000000103, -1.26375],
                [9.023194000000103, -1.263193999999885],
                [9.02236, -1.262916],
                [9.021806, -1.262084],
                [9.020416000000125, -1.262084],
                [9.020416000000125, -1.260971999999867],
                [9.019862000000103, -1.260418],
                [9.019306000000142, -1.259304999999813],
                [9.018194000000165, -1.258195999999884],
                [9.017916, -1.257638999999813],
                [9.017084000000125, -1.257083],
                [9.016528000000164, -1.255694999999889],
                [9.016528000000164, -1.254305],
                [9.01625, -1.252917],
                [9.014584, -1.252360999999894],
                [9.012918, -1.252360999999894],
                [9.012083, -1.251526999999896],
                [9.01125100000013, -1.251251],
              ],
            ],
            [
              [
                [9.006527, -1.255972999999813],
                [9.006527, -1.254860999999892],
                [9.005973, -1.254583],
                [9.005695000000173, -1.253472999999815],
                [9.004861, -1.253472999999815],
                [9.004307, -1.254305],
                [9.003195, -1.255138999999815],
                [9.003195, -1.256804999999815],
                [9.002917, -1.256804999999815],
                [9.002917, -1.258749],
                [9.003195, -1.259584],
                [9.003751, -1.26014],
                [9.004861, -1.26069299999989],
                [9.006527, -1.26069299999989],
                [9.006805000000156, -1.260418],
                [9.006251000000134, -1.25902699999989],
                [9.006251000000134, -1.255972999999813],
                [9.006527, -1.255972999999813],
              ],
            ],
            [
              [
                [9.028473000000133, -1.244859999999846],
                [9.028473000000133, -1.244027999999844],
                [9.027638000000138, -1.242638],
                [9.026529, -1.242638],
                [9.025972000000138, -1.243472],
                [9.025972000000138, -1.244306],
                [9.025138, -1.245138],
                [9.025138, -1.247082],
                [9.025972000000138, -1.249860999999896],
                [9.026807000000133, -1.249860999999896],
                [9.026529, -1.250694999999894],
                [9.026529, -1.252083],
                [9.025694, -1.254583],
                [9.025138, -1.254860999999892],
                [9.025138, -1.255417],
                [9.024582, -1.255694999999889],
                [9.024306000000138, -1.256526999999892],
                [9.022640000000138, -1.256526999999892],
                [9.021806, -1.256804999999815],
                [9.021528000000103, -1.257360999999889],
                [9.02236, -1.259304999999813],
                [9.022640000000138, -1.260418],
                [9.023194000000103, -1.260418],
                [9.023194000000103, -1.26125],
                [9.02375000000012, -1.262361999999882],
                [9.024306000000138, -1.262361999999882],
                [9.024306000000138, -1.263193999999885],
                [9.025138, -1.26375],
                [9.025138, -1.264306],
                [9.026529, -1.265972],
                [9.02736, -1.265693999999883],
                [9.029305, -1.26375],
                [9.029305, -1.26264],
                [9.029583000000116, -1.261806],
                [9.030139000000133, -1.261527999999885],
                [9.030139000000133, -1.26014],
                [9.030417, -1.259584],
                [9.030973000000188, -1.259584],
                [9.031527, -1.257917],
                [9.031527, -1.256526999999892],
                [9.031807, -1.255694999999889],
                [9.032361, -1.255138999999815],
                [9.032361, -1.254305],
                [9.031527, -1.254305],
                [9.031527, -1.253472999999815],
                [9.030973000000188, -1.252360999999894],
                [9.030695, -1.252360999999894],
                [9.030695, -1.249305],
                [9.030139000000133, -1.247917],
                [9.029583000000116, -1.247639],
                [9.029029, -1.246528999999896],
                [9.029029, -1.245693999999844],
                [9.028473000000133, -1.244859999999846],
              ],
            ],
            [
              [
                [9.02208400000012, -1.249860999999896],
                [9.02236, -1.249305],
                [9.02236, -1.247917],
                [9.021806, -1.246804],
                [9.02125, -1.247082],
                [9.020138, -1.247082],
                [9.019862000000103, -1.247917],
                [9.019028, -1.248751],
                [9.018472, -1.248751],
                [9.017640000000142, -1.250417],
                [9.017640000000142, -1.251251],
                [9.017084000000125, -1.251805],
                [9.016806, -1.252639],
                [9.016806, -1.254026999999894],
                [9.01736, -1.254860999999892],
                [9.017916, -1.254860999999892],
                [9.018750000000125, -1.254305],
                [9.018750000000125, -1.253749],
                [9.020416000000125, -1.252639],
                [9.021806, -1.250973],
                [9.02208400000012, -1.249860999999896],
              ],
            ],
            [
              [
                [9.029861, -1.241527999999846],
                [9.030139000000133, -1.240972],
                [9.030139000000133, -1.239584],
                [9.029583000000116, -1.238472],
                [9.028473000000133, -1.238472],
                [9.028195, -1.23875],
                [9.028195, -1.24125],
                [9.028751, -1.242084],
                [9.029305, -1.242084],
                [9.029861, -1.241527999999846],
              ],
            ],
            [
              [
                [9.023194000000103, -1.230416999999875],
                [9.023472, -1.230139],
                [9.023472, -1.228473],
                [9.022916, -1.22708299999988],
                [9.021806, -1.228195],
                [9.021806, -1.231248999999878],
                [9.022640000000138, -1.231248999999878],
                [9.022640000000138, -1.230695],
                [9.023194000000103, -1.230416999999875],
              ],
            ],
            [
              [
                [9.017640000000142, -1.213194],
                [9.017084000000125, -1.212638],
                [9.015972000000147, -1.212362],
                [9.015972000000147, -1.211528],
                [9.013193, -1.211528],
                [9.012639, -1.211806],
                [9.012083, -1.212638],
                [9.012083, -1.213472],
                [9.012639, -1.214306],
                [9.013193, -1.214306],
                [9.013474, -1.21486],
                [9.015694, -1.21486],
                [9.01625, -1.214583999999888],
                [9.01625, -1.213749999999891],
                [9.017916, -1.213749999999891],
                [9.017640000000142, -1.213194],
              ],
            ],
            [
              [
                [8.976251, -1.048194],
                [8.976251, -1.04875],
                [8.976805, -1.049027999999851],
                [8.976805, -1.049584],
                [8.977404, -1.049864],
                [8.977639000000124, -1.050972],
                [8.978471, -1.051806],
                [8.978473, -1.052638],
                [8.979027, -1.05375],
                [8.978751000000159, -1.055416],
                [8.978751000000159, -1.057082],
                [8.978471, -1.057638],
                [8.977361, -1.058751],
                [8.976805, -1.058751],
                [8.976529000000141, -1.060139],
                [8.974029, -1.06263899999982],
                [8.973473, -1.062917],
                [8.972363, -1.064026999999896],
                [8.972082, -1.064583],
                [8.970972, -1.065417],
                [8.97013800000019, -1.067083],
                [8.96986, -1.068472999999869],
                [8.96986, -1.070970999999872],
                [8.970416, -1.072084],
                [8.971250000000168, -1.074584],
                [8.971250000000168, -1.075693999999885],
                [8.972082, -1.078194],
                [8.972638, -1.079028],
                [8.973194, -1.079028],
                [8.973473, -1.079584],
                [8.974029, -1.079584],
                [8.974304, -1.080138],
                [8.975139, -1.080138],
                [8.975695, -1.080694],
                [8.976529000000141, -1.080972],
                [8.979305000000124, -1.080972],
                [8.979305000000124, -1.080694],
                [8.983195000000137, -1.080694],
                [8.984583, -1.08125],
                [8.984861000000137, -1.081806],
                [8.985696000000132, -1.082084],
                [8.986805, -1.083194],
                [8.98763700000012, -1.08375],
                [8.98875, -1.086804999999856],
                [8.98875, -1.087917],
                [8.989306, -1.089861],
                [8.989862000000187, -1.090139],
                [8.989862000000187, -1.090695],
                [8.990416, -1.091249],
                [8.99125, -1.091249],
                [8.99180600000011, -1.091805],
                [8.99347200000011, -1.092361],
                [9.004583, -1.092361],
                [9.004583, -1.092083],
                [9.007361000000174, -1.092083],
                [9.007361000000174, -1.091805],
                [9.009305, -1.091805],
                [9.009861, -1.091249],
                [9.010695000000169, -1.090973],
                [9.012639, -1.090973],
                [9.013474, -1.090417],
                [9.016806, -1.090417],
                [9.018750000000125, -1.089861],
                [9.019028, -1.089304999999854],
                [9.02208400000012, -1.089304999999854],
                [9.023472, -1.088751],
                [9.024028, -1.089027],
                [9.02708200000012, -1.089027],
                [9.027638000000138, -1.089583],
                [9.029305, -1.089583],
                [9.030139000000133, -1.089861],
                [9.031527, -1.091249],
                [9.034029, -1.092361],
                [9.034861, -1.092361],
                [9.035417, -1.092917],
                [9.036527, -1.093195],
                [9.036805, -1.093749],
                [9.038195, -1.094305],
                [9.039305000000184, -1.095417],
                [9.039861, -1.095417],
                [9.040693, -1.096527],
                [9.042084000000102, -1.097083],
                [9.043472000000179, -1.097083],
                [9.044306, -1.096805],
                [9.045972, -1.095139],
                [9.046528, -1.095139],
                [9.047916000000157, -1.093749],
                [9.048194, -1.092639],
                [9.048194, -1.090973],
                [9.048472000000174, -1.090417],
                [9.050138000000175, -1.088195],
                [9.050694, -1.087917],
                [9.050694, -1.085972999999854],
                [9.051250000000152, -1.084582],
                [9.05180600000017, -1.084306999999853],
                [9.05180600000017, -1.083194],
                [9.052082, -1.08236],
                [9.052638, -1.081806],
                [9.053194, -1.081806],
                [9.053194, -1.080972],
                [9.053751000000148, -1.080138],
                [9.054585, -1.080138],
                [9.055417000000148, -1.079028],
                [9.055417000000148, -1.077084],
                [9.055695, -1.077084],
                [9.055695, -1.075416],
                [9.056529000000126, -1.074584],
                [9.056529000000126, -1.073193999999887],
                [9.057083000000148, -1.072084],
                [9.057917, -1.071249],
                [9.057917, -1.069026999999892],
                [9.057361, -1.068194999999889],
                [9.057361, -1.065138999999817],
                [9.057639000000108, -1.065138999999817],
                [9.057639000000108, -1.062917],
                [9.058473, -1.062083],
                [9.060695, -1.061526999999899],
                [9.066805, -1.061526999999899],
                [9.067362000000116, -1.060694999999896],
                [9.066527000000121, -1.059583],
                [9.066527000000121, -1.059028999999896],
                [9.065695, -1.058751],
                [9.065139, -1.058194999999898],
                [9.065139, -1.057638],
                [9.064027, -1.05736],
                [9.063195000000121, -1.056528],
                [9.062361, -1.056528],
                [9.061529000000121, -1.055694],
                [9.060973000000104, -1.055694],
                [9.058195000000126, -1.054306],
                [9.057083000000148, -1.054028],
                [9.056529000000126, -1.053472],
                [9.055417000000148, -1.053472],
                [9.054585, -1.052638],
                [9.053751000000148, -1.052638],
                [9.053194, -1.052084],
                [9.052363, -1.051806],
                [9.051250000000152, -1.051806],
                [9.050972, -1.05125],
                [9.050138000000175, -1.050972],
                [9.048194, -1.050972],
                [9.048194, -1.050693999999851],
                [9.044028, -1.050693999999851],
                [9.044028, -1.050972],
                [9.042084000000102, -1.050972],
                [9.040971000000184, -1.051527999999848],
                [9.039305000000184, -1.051527999999848],
                [9.039305000000184, -1.051806],
                [9.038195, -1.052362],
                [9.032639000000188, -1.052362],
                [9.030417, -1.05125],
                [9.028751, -1.05125],
                [9.02736, -1.050693999999851],
                [9.026529, -1.049861999999848],
                [9.025694, -1.049861999999848],
                [9.024582, -1.04875],
                [9.02236, -1.04764],
                [9.020972000000143, -1.047084],
                [9.020694, -1.046528],
                [9.019862000000103, -1.046528],
                [9.019028, -1.045693],
                [9.018472, -1.045693],
                [9.01736, -1.044862],
                [9.016528000000164, -1.043749],
                [9.015694, -1.043749],
                [9.015418000000125, -1.043196],
                [9.014306000000147, -1.042639],
                [9.014306000000147, -1.042083],
                [9.013474, -1.041805],
                [9.012639, -1.040695],
                [9.012083, -1.040695],
                [9.010417, -1.039027],
                [9.010139000000152, -1.038195],
                [9.009583, -1.038195],
                [9.009027000000174, -1.037082999999882],
                [9.009027000000174, -1.036527],
                [9.008193, -1.035973],
                [9.008193, -1.035139],
                [9.007361000000174, -1.034861],
                [9.007083, -1.034027],
                [9.005973, -1.032916999999884],
                [9.005417, -1.032916999999884],
                [9.004583, -1.031807],
                [9.004027000000178, -1.031807],
                [9.003751, -1.030973],
                [9.003195, -1.030973],
                [9.002917, -1.030415999999889],
                [9.001804000000106, -1.030138],
                [9.000695000000178, -1.029306],
                [8.999307000000101, -1.029306],
                [8.999028, -1.028749999999889],
                [8.998472000000106, -1.028749999999889],
                [8.998194000000183, -1.028193999999814],
                [8.997638, -1.028193999999814],
                [8.997084, -1.027638],
                [8.99625, -1.027638],
                [8.994306, -1.026806],
                [8.994306, -1.026527999999814],
                [8.990694000000133, -1.026527999999814],
                [8.989584, -1.028472],
                [8.989584, -1.029306],
                [8.99014000000011, -1.029859999999815],
                [8.99014000000011, -1.030693999999812],
                [8.990694000000133, -1.031250999999884],
                [8.99180600000011, -1.033750999999882],
                [8.99180600000011, -1.037916999999879],
                [8.991528000000187, -1.037916999999879],
                [8.991528000000187, -1.041527],
                [8.990694000000133, -1.042083],
                [8.990416, -1.042639],
                [8.989028000000133, -1.044027],
                [8.988472000000115, -1.044027],
                [8.987918, -1.044584],
                [8.986527000000137, -1.04514],
                [8.985139, -1.04514],
                [8.984583, -1.044584],
                [8.983751, -1.044584],
                [8.983195000000137, -1.044027],
                [8.982361, -1.043749],
                [8.981249, -1.043749],
                [8.980417000000159, -1.043196],
                [8.979305000000124, -1.043196],
                [8.976805, -1.042361],
                [8.976251, -1.041805],
                [8.975417000000164, -1.041805],
                [8.970972, -1.040417],
                [8.96875, -1.039305],
                [8.968194, -1.038751],
                [8.96375, -1.037639],
                [8.962362, -1.037361],
                [8.960693000000106, -1.037361],
                [8.9598620000001, -1.037082999999882],
                [8.959305, -1.038473],
                [8.961806, -1.040971],
                [8.962362, -1.041805],
                [8.962916000000178, -1.041805],
                [8.963196, -1.042361],
                [8.96375, -1.042361],
                [8.964584000000173, -1.043196],
                [8.965416, -1.043196],
                [8.965972, -1.043749],
                [8.967084, -1.044305],
                [8.967916000000173, -1.044305],
                [8.96847200000019, -1.044862],
                [8.972643000000119, -1.044857],
                [8.973194, -1.045693],
                [8.973751000000163, -1.045693],
                [8.974304, -1.046528],
                [8.974861000000146, -1.046528],
                [8.975695, -1.047361999999851],
                [8.975695, -1.047916],
                [8.976251, -1.048194],
              ],
            ],
            [
              [
                [9.024862, -1.044584],
                [9.02375000000012, -1.044584],
                [9.023194000000103, -1.044305000999941],
                [9.02236, -1.044584],
                [9.02236, -1.045139998999957],
                [9.023194000000103, -1.045418000999916],
                [9.023472, -1.045972],
                [9.024582, -1.046806000999936],
                [9.024582, -1.047361999999851],
                [9.025972000000138, -1.048194000999956],
                [9.026528999000107, -1.048194000999956],
                [9.027638000000138, -1.04875],
                [9.027916999000013, -1.049306000999934],
                [9.030416999000124, -1.050415998999938],
                [9.031806999000025, -1.050415998999938],
                [9.032361, -1.049861999999848],
                [9.033473001000175, -1.049861999999848],
                [9.033749, -1.04875],
                [9.033194999000102, -1.048194000999956],
                [9.032639000000188, -1.048194000999956],
                [9.032361, -1.047639998999955],
                [9.030695001000026, -1.047084],
                [9.030139000000133, -1.04625],
                [9.02902899899999, -1.04625],
                [9.028195001000086, -1.045418000999916],
                [9.02625, -1.045414998999945],
                [9.025972000000138, -1.044862],
                [9.024862, -1.044584],
              ],
            ],
            [
              [
                [9.002639000000102, -1.205696],
                [9.002361000000178, -1.204861],
                [9.001804000000106, -1.204861],
                [9.001529, -1.204305],
                [9.000973000000101, -1.204305],
                [9.000695000000178, -1.204861],
                [9.000695000000178, -1.206528],
                [9.001251, -1.208194],
                [9.001804000000106, -1.209306],
                [9.002639000000102, -1.209306],
                [9.002639000000102, -1.208472],
                [9.003195, -1.208472],
                [9.003195, -1.206249],
                [9.002639000000102, -1.205696],
              ],
            ],
            [
              [
                [8.965138, -1.037361],
                [8.966250000000173, -1.037916999999879],
                [8.96736200000015, -1.037916999999879],
                [8.96847200000019, -1.038195],
                [8.96902800000015, -1.038748999999882],
                [8.969584000000168, -1.038751],
                [8.97013800000019, -1.039305],
                [8.971250000000168, -1.039861],
                [8.975695, -1.04124899999988],
                [8.976529000000141, -1.04124899999988],
                [8.977083000000164, -1.041805],
                [8.979583, -1.042639],
                [8.980695, -1.042639],
                [8.981529000000137, -1.043196],
                [8.98263900000012, -1.043196],
                [8.983471, -1.043471],
                [8.984027, -1.044027],
                [8.984861000000137, -1.044027],
                [8.985417, -1.044584],
                [8.986805, -1.044584],
                [8.987918, -1.044027],
                [8.988193000000138, -1.043471],
                [8.98875, -1.043471],
                [8.989862000000187, -1.042361],
                [8.99014000000011, -1.041805],
                [8.990972, -1.04124899999988],
                [8.990972, -1.037639],
                [8.99125, -1.037639],
                [8.99125, -1.034027],
                [8.990694000000133, -1.032916999999884],
                [8.99014000000011, -1.032361],
                [8.99014000000011, -1.031529],
                [8.989584, -1.030973],
                [8.989584, -1.030138],
                [8.989028000000133, -1.029581999999891],
                [8.989028000000133, -1.028193999999814],
                [8.989862000000187, -1.026527999999814],
                [8.990972, -1.025694],
                [8.990972, -1.024861999999814],
                [8.989862000000187, -1.024028],
                [8.989862000000187, -1.023472],
                [8.98875, -1.022083999999893],
                [8.98763700000012, -1.021249999999895],
                [8.98763700000012, -1.020694],
                [8.98597100000012, -1.019306],
                [8.98597100000012, -1.018749999999898],
                [8.982917, -1.015695],
                [8.982917, -1.015139],
                [8.981805, -1.014027],
                [8.981249, -1.014027],
                [8.98097300000012, -1.013471],
                [8.980417000000159, -1.013471],
                [8.980139, -1.012916999999845],
                [8.979583, -1.012916999999845],
                [8.979305000000124, -1.012361],
                [8.978751000000159, -1.012361],
                [8.978471, -1.011805],
                [8.977917, -1.011805],
                [8.977639000000124, -1.011249],
                [8.977083000000164, -1.011249],
                [8.976805, -1.010695],
                [8.975695, -1.010417],
                [8.974585, -1.009861],
                [8.974304, -1.009305],
                [8.973473, -1.008751],
                [8.972916000000168, -1.008751],
                [8.971806000000129, -1.008195],
                [8.971528, -1.007639],
                [8.970972, -1.007639],
                [8.96986, -1.006805],
                [8.96902800000015, -1.005695],
                [8.96847200000019, -1.005695],
                [8.96736200000015, -1.004582],
                [8.96736200000015, -1.004303999999877],
                [8.964584000000173, -1.001528],
                [8.964028, -1.001528],
                [8.96375, -1.000971999999877],
                [8.963196, -1.000971999999877],
                [8.962916000000178, -1.000416],
                [8.961806, -0.99986],
                [8.961250000000177, -0.999305999999876],
                [8.960418, -0.999305999999876],
                [8.959027000000106, -0.997916],
                [8.956527, -0.996805999999879],
                [8.956249000000128, -0.99625],
                [8.955139, -0.99625],
                [8.954029000000105, -0.995971999999881],
                [8.953749, -0.995139999999878],
                [8.952639, -0.994862],
                [8.951527, -0.994862],
                [8.950973, -0.994305999999881],
                [8.950139, -0.994584],
                [8.939862000000119, -0.994584],
                [8.938750000000141, -0.995139999999878],
                [8.93708400000014, -0.995139999999878],
                [8.936528000000123, -0.995694],
                [8.935416000000146, -0.995694],
                [8.934584, -0.995971999999881],
                [8.934028, -0.996528],
                [8.932918, -0.996805999999879],
                [8.931805000000168, -0.996805999999879],
                [8.931527, -0.997362],
                [8.930417, -0.997362],
                [8.929583, -0.997637999999881],
                [8.92902700000019, -0.998194],
                [8.927917, -0.998194],
                [8.927083, -0.999028],
                [8.926527, -0.999028],
                [8.926251, -0.999584],
                [8.924307, -1.001528],
                [8.923195, -1.00236],
                [8.922917, -1.002916],
                [8.9220830000001, -1.003194],
                [8.9220830000001, -1.003751],
                [8.921251, -1.004303999999877],
                [8.921251, -1.005139],
                [8.920695, -1.005417],
                [8.920695, -1.005973],
                [8.920138000000122, -1.006251],
                [8.919582000000105, -1.007361],
                [8.919582000000105, -1.008473],
                [8.91875, -1.009027],
                [8.91875, -1.011805],
                [8.918472000000122, -1.011805],
                [8.917916000000105, -1.014027],
                [8.917916000000105, -1.015139],
                [8.91736, -1.015695],
                [8.91736, -1.016527],
                [8.916806000000122, -1.017082999999843],
                [8.916528, -1.017917999999895],
                [8.916528, -1.019306],
                [8.915972, -1.02014],
                [8.915972, -1.021249999999895],
                [8.915140000000122, -1.022083999999893],
                [8.914306, -1.023749999999893],
                [8.914306, -1.024583999999891],
                [8.913472000000127, -1.025138],
                [8.913472000000127, -1.026249999999891],
                [8.912638, -1.026527999999814],
                [8.912084, -1.027638],
                [8.912638, -1.027915999999891],
                [8.912638, -1.030138],
                [8.912084, -1.030415999999889],
                [8.910694000000149, -1.030415999999889],
                [8.910416, -1.029859999999815],
                [8.910416, -1.028749999999889],
                [8.910694000000149, -1.028193999999814],
                [8.909028000000148, -1.028193999999814],
                [8.908472000000131, -1.028749999999889],
                [8.906249, -1.028749999999889],
                [8.906249, -1.028472],
                [8.904583, -1.028472],
                [8.904027, -1.029581999999891],
                [8.902639000000136, -1.029306],
                [8.897361, -1.029306],
                [8.897361, -1.029581999999891],
                [8.89541700000018, -1.029581999999891],
                [8.894585, -1.029859999999815],
                [8.894029, -1.030415999999889],
                [8.893195000000162, -1.030693999999812],
                [8.890972, -1.030693999999812],
                [8.890138, -1.031529],
                [8.890416, -1.032639],
                [8.891250000000184, -1.032639],
                [8.891250000000184, -1.033473],
                [8.888472, -1.033473],
                [8.887638, -1.034582999999884],
                [8.888194000000112, -1.035139],
                [8.88875, -1.035139],
                [8.889028000000167, -1.035695],
                [8.890694000000167, -1.036527],
                [8.891807, -1.036527],
                [8.892638, -1.037082999999882],
                [8.89375100000018, -1.037082999999882],
                [8.895695, -1.037639],
                [8.896251, -1.038195],
                [8.897917, -1.039027],
                [8.89597300000014, -1.039027],
                [8.89541700000018, -1.038473],
                [8.892360000000167, -1.038473],
                [8.891807, -1.039027],
                [8.890694000000167, -1.03958299999988],
                [8.890138, -1.03958299999988],
                [8.890138, -1.040971],
                [8.891807, -1.041805],
                [8.893473, -1.042361],
                [8.894585, -1.042361],
                [8.89541700000018, -1.042639],
                [8.89597300000014, -1.043196],
                [8.897361, -1.043471],
                [8.898195000000158, -1.044305],
                [8.900139, -1.043749],
                [8.901805, -1.043749],
                [8.902361, -1.044027],
                [8.903471, -1.045415],
                [8.904027, -1.045418],
                [8.904583, -1.04625],
                [8.905971000000136, -1.047361999999851],
                [8.906527000000153, -1.047361999999851],
                [8.906806000000131, -1.047916],
                [8.907918000000109, -1.048472],
                [8.908472000000131, -1.049027999999851],
                [8.909028000000148, -1.049027999999851],
                [8.909862, -1.049861999999848],
                [8.911806000000126, -1.049861999999848],
                [8.912362, -1.049306],
                [8.913194, -1.049306],
                [8.914028, -1.048472],
                [8.914584000000104, -1.048472],
                [8.915140000000122, -1.047916],
                [8.915972, -1.047916],
                [8.916806000000122, -1.047084],
                [8.917916000000105, -1.047084],
                [8.918472000000122, -1.046528],
                [8.919307000000117, -1.046528],
                [8.91986, -1.045693],
                [8.920973000000117, -1.045693],
                [8.9220830000001, -1.04514],
                [8.922361, -1.044584],
                [8.923751, -1.044584],
                [8.925139000000172, -1.043749],
                [8.925139000000172, -1.043196],
                [8.92569500000019, -1.042639],
                [8.928749, -1.042639],
                [8.929583, -1.041805],
                [8.931249, -1.041805],
                [8.931249, -1.041527],
                [8.933474, -1.041527],
                [8.934584, -1.04124899999988],
                [8.934862000000123, -1.040695],
                [8.935972000000163, -1.040695],
                [8.936806, -1.040417],
                [8.938472, -1.038751],
                [8.939306000000158, -1.038748999999882],
                [8.939862000000119, -1.038195],
                [8.940694, -1.038195],
                [8.941528000000119, -1.037361],
                [8.942084000000136, -1.037361],
                [8.94236, -1.036805],
                [8.942916, -1.036805],
                [8.943194000000119, -1.036248999999884],
                [8.943750000000136, -1.036248999999884],
                [8.944028, -1.035695],
                [8.945138, -1.035416999999882],
                [8.946807, -1.035416999999882],
                [8.946807, -1.035139],
                [8.952917000000127, -1.035139],
                [8.952917000000127, -1.035416999999882],
                [8.955695000000105, -1.035973],
                [8.957361000000105, -1.035973],
                [8.957361000000105, -1.036248999999884],
                [8.959305, -1.036248999999884],
                [8.959305, -1.036527],
                [8.960971, -1.036527],
                [8.960971, -1.036805],
                [8.96264, -1.036805],
                [8.965138, -1.037361],
              ],
            ],
            [
              [
                [9.013749, -1.036527],
                [9.013749, -1.035973],
                [9.012918, -1.035973],
                [9.012918, -1.035416999999882],
                [9.011527, -1.034861],
                [9.010971, -1.035416999999882],
                [9.011249, -1.036805],
                [9.012361000000169, -1.037361],
                [9.013474, -1.038751],
                [9.01402700000017, -1.038751],
                [9.014306000000147, -1.039305],
                [9.015418000000125, -1.039861],
                [9.01625, -1.040695],
                [9.018472, -1.040695],
                [9.01514, -1.037361],
                [9.014306000000147, -1.037082999999882],
                [9.013749, -1.036527],
              ],
            ],
            [
              [
                [9.009305, -0.930695],
                [9.009305, -0.929027],
                [9.009027000000174, -0.929027],
                [9.009027000000174, -0.926529],
                [9.008473000000151, -0.924581999999873],
                [9.007083, -0.925139],
                [9.004861, -0.927361],
                [9.004307, -0.927639],
                [9.002917, -0.927639],
                [9.002639000000102, -0.928195],
                [9.002085, -0.928195],
                [9.001804000000106, -0.928750999999863],
                [9.001251, -0.928750999999863],
                [9.000416, -0.929861],
                [8.999307000000101, -0.930139],
                [8.997638, -0.930139],
                [8.99625, -0.930416999999863],
                [8.994862000000182, -0.930973],
                [8.994584, -0.931527],
                [8.994028000000128, -0.931527],
                [8.993194000000187, -0.932361],
                [8.992084, -0.932361],
                [8.99180600000011, -0.932917],
                [8.989862000000187, -0.932917],
                [8.989584, -0.933473],
                [8.98875, -0.933748999999864],
                [8.986805, -0.933748999999864],
                [8.986805, -0.933473],
                [8.984583, -0.933473],
                [8.983195000000137, -0.933195],
                [8.98263900000012, -0.931805],
                [8.981249, -0.931805],
                [8.980695, -0.931248999999866],
                [8.979027, -0.931248999999866],
                [8.977639000000124, -0.930973],
                [8.977639000000124, -0.930695],
                [8.974585, -0.930695],
                [8.974585, -0.931805],
                [8.975139, -0.932639],
                [8.975139, -0.934305],
                [8.975417000000164, -0.934305],
                [8.975417000000164, -0.939028],
                [8.975139, -0.939028],
                [8.974304, -0.941806],
                [8.974304, -0.944027999999832],
                [8.974029, -0.944027999999832],
                [8.974029, -0.952083],
                [8.973473, -0.954305],
                [8.973194, -0.954305],
                [8.973194, -0.958749],
                [8.972916000000168, -0.959860999999876],
                [8.971806000000129, -0.960694999999873],
                [8.970972, -0.962639],
                [8.970972, -0.963749],
                [8.97013800000019, -0.964306],
                [8.97013800000019, -0.964861999999869],
                [8.969306, -0.965418],
                [8.96847200000019, -0.967084],
                [8.96847200000019, -0.969306],
                [8.96902800000015, -0.969584],
                [8.969584000000168, -0.969306],
                [8.971528, -0.969306],
                [8.972638, -0.968472],
                [8.972916000000168, -0.967362],
                [8.973473, -0.967084],
                [8.974585, -0.965972],
                [8.974585, -0.965693999999871],
                [8.975695, -0.964861999999869],
                [8.975695, -0.963471],
                [8.976251, -0.962918],
                [8.977917, -0.962918],
                [8.978195000000142, -0.964026999999874],
                [8.979861000000142, -0.963471],
                [8.98097300000012, -0.963471],
                [8.980695, -0.964584],
                [8.979583, -0.964584],
                [8.978751000000159, -0.96514],
                [8.977361, -0.96514],
                [8.976805, -0.965418],
                [8.975973000000124, -0.966527999999869],
                [8.975417000000164, -0.966527999999869],
                [8.972638, -0.969306],
                [8.971528, -0.970138],
                [8.96986, -0.970138],
                [8.969584000000168, -0.970416],
                [8.96902800000015, -0.972084],
                [8.96902800000015, -0.973194],
                [8.968194, -0.97375],
                [8.96736200000015, -0.97486],
                [8.965972, -0.975416],
                [8.964584000000173, -0.977082],
                [8.964028, -0.977082],
                [8.964028, -0.977917],
                [8.963472, -0.978195],
                [8.963472, -0.978751],
                [8.962916000000178, -0.979029],
                [8.962916000000178, -0.979861],
                [8.9615280000001, -0.980972999999892],
                [8.960971, -0.980972999999892],
                [8.960693000000106, -0.981527],
                [8.959583000000123, -0.981804999999838],
                [8.958749000000182, -0.982361],
                [8.958749000000182, -0.982917],
                [8.957361000000105, -0.98347299999989],
                [8.956249000000128, -0.984304999999893],
                [8.955973, -0.984861],
                [8.954861, -0.985417],
                [8.954305, -0.985972999999888],
                [8.952917000000127, -0.986249],
                [8.951805, -0.987917],
                [8.951805, -0.990139999999883],
                [8.95236100000011, -0.990970999999888],
                [8.953195, -0.991249],
                [8.953195, -0.991805999999883],
                [8.955139, -0.992639999999881],
                [8.955695000000105, -0.992639999999881],
                [8.955973, -0.993194],
                [8.956805, -0.993194],
                [8.957361000000105, -0.99375],
                [8.958471, -0.994028],
                [8.96014, -0.994862],
                [8.962084, -0.996805999999879],
                [8.962916000000178, -0.996805999999879],
                [8.96375, -0.997637999999881],
                [8.965416, -0.998471999999879],
                [8.966250000000173, -0.998471999999879],
                [8.96680600000019, -0.999305999999876],
                [8.967916000000173, -0.999584],
                [8.96847200000019, -1.000416],
                [8.969584000000168, -1.000416],
                [8.96986, -1.000971999999877],
                [8.970972, -1.000971999999877],
                [8.971250000000168, -1.001528],
                [8.971806000000129, -1.001528],
                [8.972082, -1.002085],
                [8.972638, -1.002085],
                [8.972916000000168, -1.002637999999877],
                [8.973473, -1.002637999999877],
                [8.973751000000163, -1.003194],
                [8.974304, -1.003194],
                [8.975695, -1.00486],
                [8.976251, -1.00486],
                [8.976805, -1.005417],
                [8.976805, -1.005973],
                [8.978751000000159, -1.007917],
                [8.978751000000159, -1.008473],
                [8.980417000000159, -1.009305],
                [8.980417000000159, -1.009861],
                [8.981805, -1.010695],
                [8.98263900000012, -1.011527],
                [8.983195000000137, -1.011527],
                [8.983471, -1.012083],
                [8.985139, -1.012916999999845],
                [8.986249, -1.013195],
                [8.986805, -1.013749],
                [8.986805, -1.014305],
                [8.989584, -1.017082999999843],
                [8.990416, -1.018193],
                [8.990416, -1.018749999999898],
                [8.991528000000187, -1.019862],
                [8.992084, -1.02014],
                [8.992084, -1.020694],
                [8.99347200000011, -1.022083999999893],
                [8.99375, -1.022083999999893],
                [8.994860000000187, -1.023194],
                [8.99513800000011, -1.023749999999893],
                [8.997916, -1.025138],
                [8.999028, -1.025138],
                [8.999307000000101, -1.025694],
                [9.002361000000178, -1.025694],
                [9.002361000000178, -1.025972],
                [9.004027000000178, -1.025972],
                [9.004583, -1.026527999999814],
                [9.005417, -1.026527999999814],
                [9.005695000000173, -1.027083999999888],
                [9.006805000000156, -1.027083999999888],
                [9.007083, -1.027638],
                [9.007639, -1.027638],
                [9.008195, -1.028472],
                [9.010417, -1.029581999999891],
                [9.010417, -1.030138],
                [9.011527, -1.030415999999889],
                [9.011527, -1.030973],
                [9.012361000000169, -1.031250999999884],
                [9.012639, -1.031807],
                [9.013474, -1.032081999999889],
                [9.013474, -1.032916999999884],
                [9.014306000000147, -1.032916999999884],
                [9.015418000000125, -1.034027],
                [9.01625, -1.035139],
                [9.016806, -1.035139],
                [9.018194000000165, -1.036805],
                [9.018750000000125, -1.036805],
                [9.019028, -1.037361],
                [9.020972000000143, -1.038195],
                [9.021806, -1.038195],
                [9.022640000000138, -1.039027],
                [9.02375000000012, -1.039027],
                [9.024028, -1.03958299999988],
                [9.024862, -1.03958299999988],
                [9.02541600000012, -1.040139],
                [9.02625, -1.040139],
                [9.026529, -1.040695],
                [9.028751, -1.04124899999988],
                [9.030695, -1.042083],
                [9.031527, -1.042083],
                [9.031807, -1.042639],
                [9.032361, -1.042639],
                [9.032917000000111, -1.043471],
                [9.033749, -1.043471],
                [9.034583000000111, -1.044305],
                [9.035695, -1.044305],
                [9.035695, -1.044862],
                [9.036805, -1.045415],
                [9.037361, -1.045418],
                [9.037639000000183, -1.045972],
                [9.038749, -1.045972],
                [9.039305000000184, -1.046528],
                [9.039861, -1.046528],
                [9.040140000000179, -1.047084],
                [9.042362, -1.04764],
                [9.044028, -1.04764],
                [9.044028, -1.047916],
                [9.048194, -1.047916],
                [9.049306, -1.047361999999851],
                [9.050138000000175, -1.046528],
                [9.050138000000175, -1.045972],
                [9.050972, -1.044862],
                [9.052082, -1.044862],
                [9.052638, -1.044305],
                [9.052638, -1.043749],
                [9.053194, -1.043471],
                [9.053194, -1.042917],
                [9.053751000000148, -1.042639],
                [9.054585, -1.040971],
                [9.055417000000148, -1.039861],
                [9.055417000000148, -1.038751],
                [9.056529000000126, -1.037361],
                [9.056529000000126, -1.036527],
                [9.057361, -1.035416999999882],
                [9.057917, -1.035416999999882],
                [9.058473, -1.034861],
                [9.060417000000143, -1.034305],
                [9.065971000000104, -1.034305],
                [9.065971000000104, -1.034582999999884],
                [9.06764, -1.034582999999884],
                [9.06764, -1.034861],
                [9.069306, -1.034861],
                [9.069306, -1.035139],
                [9.072362000000112, -1.035139],
                [9.072362000000112, -1.034861],
                [9.074306, -1.034861],
                [9.075416000000189, -1.034305],
                [9.076806, -1.034305],
                [9.077360000000112, -1.033750999999882],
                [9.078472, -1.033473],
                [9.078472, -1.032916999999884],
                [9.079306, -1.032639],
                [9.079860000000167, -1.032081999999889],
                [9.079860000000167, -1.030415999999889],
                [9.079306, -1.030138],
                [9.077638, -1.028472],
                [9.076806, -1.028472],
                [9.075138, -1.02736],
                [9.075138, -1.026806],
                [9.073194, -1.024861999999814],
                [9.072638, -1.024861999999814],
                [9.072362000000112, -1.024306],
                [9.071806, -1.024306],
                [9.071528, -1.023749999999893],
                [9.070972, -1.023749999999893],
                [9.07014, -1.02264],
                [9.069028000000117, -1.02264],
                [9.06875, -1.021806],
                [9.068193000000122, -1.021806],
                [9.067918, -1.020972],
                [9.067083000000139, -1.020415999999898],
                [9.065971000000104, -1.019306],
                [9.065971000000104, -1.014305],
                [9.066249, -1.014305],
                [9.066249, -1.012083],
                [9.065695, -1.010973],
                [9.065695, -1.009861],
                [9.064305000000104, -1.007361],
                [9.064305000000104, -1.00486],
                [9.064027, -1.004303999999877],
                [9.063195000000121, -1.004029],
                [9.063195000000121, -1.003471999999874],
                [9.061249, -1.002916],
                [9.059583, -1.002916],
                [9.059583, -1.002637999999877],
                [9.056251, -1.002637999999877],
                [9.055695, -1.002085],
                [9.054585, -1.002085],
                [9.054585, -1.001528],
                [9.05347200000017, -1.00125],
                [9.052916000000153, -1.000694],
                [9.052916000000153, -0.99986],
                [9.052363, -0.99986],
                [9.05180600000017, -0.999028],
                [9.05180600000017, -0.998471999999879],
                [9.050138000000175, -0.996528],
                [9.04986, -0.994862],
                [9.049306, -0.994305999999881],
                [9.04875, -0.992362],
                [9.04875, -0.991249],
                [9.048194, -0.990970999999888],
                [9.047916000000157, -0.989861],
                [9.047362, -0.989583],
                [9.047362, -0.989027],
                [9.046806000000174, -0.988749],
                [9.046806000000174, -0.988195],
                [9.046250000000157, -0.987917],
                [9.046250000000157, -0.987083],
                [9.045416000000102, -0.98513899999989],
                [9.045140000000174, -0.984027],
                [9.045140000000174, -0.97930499999984],
                [9.045416000000102, -0.978195],
                [9.045416000000102, -0.975694],
                [9.044862, -0.975138],
                [9.044862, -0.974028],
                [9.044306, -0.972638],
                [9.042916000000162, -0.971528],
                [9.041528, -0.971528],
                [9.040418000000102, -0.970972],
                [9.038471, -0.970972],
                [9.038471, -0.970694],
                [9.036805, -0.970694],
                [9.035695, -0.970138],
                [9.035417, -0.969584],
                [9.034305000000188, -0.969306],
                [9.033749, -0.96875],
                [9.033749, -0.968193999999869],
                [9.032083, -0.967916],
                [9.032083, -0.967362],
                [9.031527, -0.967084],
                [9.031527, -0.966527999999869],
                [9.030417, -0.965418],
                [9.029305, -0.964584],
                [9.028751, -0.963195999999868],
                [9.027917000000116, -0.962918],
                [9.02736, -0.962360999999873],
                [9.02708200000012, -0.960694999999873],
                [9.025972000000138, -0.960139],
                [9.025972000000138, -0.959305],
                [9.02541600000012, -0.959026999999878],
                [9.025138, -0.958194999999876],
                [9.024582, -0.958194999999876],
                [9.024028, -0.957083],
                [9.024028, -0.95652699999988],
                [9.023472, -0.955694999999878],
                [9.022916, -0.955694999999878],
                [9.022640000000138, -0.95486099999988],
                [9.02208400000012, -0.954305],
                [9.021528000000103, -0.954305],
                [9.02125, -0.95319499999988],
                [9.019862000000103, -0.952083],
                [9.019584, -0.950973],
                [9.019028, -0.950694999999882],
                [9.019028, -0.949859999999887],
                [9.018194000000165, -0.949028999999882],
                [9.018194000000165, -0.948193999999887],
                [9.017640000000142, -0.947916],
                [9.017640000000142, -0.946806],
                [9.017084000000125, -0.945416],
                [9.01625, -0.944584],
                [9.01625, -0.943472],
                [9.015694, -0.942361999999832],
                [9.01514, -0.942084],
                [9.014862000000164, -0.940972],
                [9.013749, -0.94014],
                [9.013474, -0.939306],
                [9.012918, -0.939306],
                [9.012639, -0.938193],
                [9.012083, -0.938193],
                [9.010971, -0.936805],
                [9.010971, -0.935696],
                [9.010417, -0.935418],
                [9.010417, -0.934305],
                [9.009861, -0.933748999999864],
                [9.009583, -0.932361],
                [9.009583, -0.930695],
                [9.009305, -0.930695],
              ],
            ],
            [
              [
                [9.025138, -0.851527],
                [9.02541600000012, -0.850139],
                [9.02541600000012, -0.848748999999884],
                [9.024582, -0.848195],
                [9.024306000000138, -0.849305],
                [9.02375000000012, -0.849583],
                [9.02375000000012, -0.852917],
                [9.024582, -0.852917],
                [9.024584, -0.852083],
                [9.025138, -0.851527],
              ],
            ],
            [
              [
                [9.042362, -0.669028],
                [9.040971000000184, -0.667918],
                [9.039583000000107, -0.667918],
                [9.038471, -0.66819299999986],
                [9.037361, -0.669028],
                [9.036527, -0.669028],
                [9.035973000000183, -0.669584],
                [9.035417, -0.670972],
                [9.035417, -0.672362],
                [9.034861, -0.672916],
                [9.034861, -0.673472],
                [9.034305000000188, -0.67375],
                [9.034305000000188, -0.674306],
                [9.033473, -0.674306],
                [9.032917000000111, -0.675416],
                [9.032917000000111, -0.67625],
                [9.032083, -0.677084],
                [9.030417, -0.677084],
                [9.029029, -0.677362],
                [9.027917000000116, -0.677916],
                [9.02625, -0.677916],
                [9.02625, -0.677638],
                [9.024582, -0.677638],
                [9.024582, -0.677362],
                [9.022640000000138, -0.677362],
                [9.02236, -0.677916],
                [9.02125, -0.678471999999829],
                [9.019862000000103, -0.67986],
                [9.019862000000103, -0.680972999999881],
                [9.019028, -0.681803999999829],
                [9.018750000000125, -0.683194999999898],
                [9.018750000000125, -0.685138999999879],
                [9.019584, -0.685138999999879],
                [9.019584, -0.685694999999896],
                [9.020416000000125, -0.685972999999876],
                [9.02125, -0.686804999999879],
                [9.02125, -0.687360999999896],
                [9.02208400000012, -0.687638999999876],
                [9.022916, -0.688749],
                [9.02375000000012, -0.688749],
                [9.02375000000012, -0.689583],
                [9.024582, -0.690138999999874],
                [9.024584, -0.690695],
                [9.025138, -0.690970999999877],
                [9.025138, -0.691804999999874],
                [9.025694, -0.692083],
                [9.025694, -0.692638999999872],
                [9.02625, -0.692917],
                [9.02625, -0.693749],
                [9.026807000000133, -0.694027],
                [9.026807000000133, -0.69514],
                [9.02736, -0.69625],
                [9.027917000000116, -0.696528],
                [9.027917000000116, -0.697084],
                [9.028473000000133, -0.698194],
                [9.029029, -0.698194],
                [9.029861, -0.699028],
                [9.029861, -0.699584],
                [9.030417, -0.700694],
                [9.031527, -0.701528],
                [9.031527, -0.704028],
                [9.031251000000111, -0.704584],
                [9.031527, -0.705137999999863],
                [9.031527, -0.70625],
                [9.029861, -0.70625],
                [9.029861, -0.706528],
                [9.027917000000116, -0.706528],
                [9.02708200000012, -0.705416],
                [9.025694, -0.705416],
                [9.024862, -0.70486],
                [9.021528000000103, -0.70486],
                [9.021528000000103, -0.704584],
                [9.019862000000103, -0.704584],
                [9.018194000000165, -0.705416],
                [9.018194000000165, -0.707082],
                [9.018472, -0.707638],
                [9.019862000000103, -0.707638],
                [9.020972000000143, -0.708751],
                [9.021528000000103, -0.708751],
                [9.021806, -0.709583],
                [9.021806, -0.712083],
                [9.02125, -0.712639],
                [9.02125, -0.714027],
                [9.020138, -0.715139],
                [9.019584, -0.715416999999832],
                [9.018194000000165, -0.715416999999832],
                [9.018194000000165, -0.717916999999886],
                [9.019028, -0.717916999999886],
                [9.019584, -0.718471],
                [9.019584, -0.719027],
                [9.020138, -0.719305],
                [9.020138, -0.720693],
                [9.018194000000165, -0.722083999999882],
                [9.016806, -0.722083999999882],
                [9.015418000000125, -0.721806],
                [9.014584, -0.721248999999887],
                [9.014862000000164, -0.720417999999881],
                [9.016528000000164, -0.720971],
                [9.017916, -0.720971],
                [9.017916, -0.719861],
                [9.017084000000125, -0.719027],
                [9.017084000000125, -0.718471],
                [9.01625, -0.717639],
                [9.015418000000125, -0.717639],
                [9.014584, -0.716805],
                [9.014584, -0.715695],
                [9.01402700000017, -0.715416999999832],
                [9.013474, -0.714305],
                [9.01125100000013, -0.714305],
                [9.010139000000152, -0.714582999999834],
                [9.009027000000174, -0.715695],
                [9.009305, -0.716805],
                [9.009861, -0.717082999999832],
                [9.009861, -0.717639],
                [9.010695000000169, -0.717639],
                [9.010695000000169, -0.718195],
                [9.011805000000152, -0.718471],
                [9.012083, -0.719027],
                [9.012083, -0.720139],
                [9.012361000000169, -0.720971],
                [9.011805000000152, -0.721248999999887],
                [9.011805000000152, -0.721806],
                [9.010695000000169, -0.722639999999899],
                [9.009861, -0.722639999999899],
                [9.009861, -0.723194],
                [9.009305, -0.723749999999882],
                [9.008473000000151, -0.723749999999882],
                [9.008473000000151, -0.724305999999899],
                [9.007639, -0.724305999999899],
                [9.007361000000174, -0.72514],
                [9.006805000000156, -0.72514],
                [9.006805000000156, -0.725971999999899],
                [9.005695000000173, -0.727083999999877],
                [9.004307, -0.727083999999877],
                [9.003751, -0.728472],
                [9.003751, -0.729862],
                [9.003195, -0.730415999999877],
                [9.003195, -0.732638],
                [9.002361000000178, -0.733194],
                [9.001804000000106, -0.734307],
                [9.001251, -0.73486],
                [9.001251, -0.736250999999868],
                [9.000695000000178, -0.736529],
                [9.000695000000178, -0.73708299999987],
                [8.999307000000101, -0.738195],
                [8.999028, -0.739029],
                [8.998194000000183, -0.739305],
                [8.996806000000106, -0.739029],
                [8.99375, -0.739029],
                [8.99375, -0.739861],
                [8.994306, -0.740139],
                [8.99513800000011, -0.742083],
                [8.99513800000011, -0.743473],
                [8.994306, -0.743473],
                [8.993194000000187, -0.742361],
                [8.992918, -0.741527],
                [8.992362000000128, -0.741527],
                [8.992084, -0.740973],
                [8.991528000000187, -0.740973],
                [8.990694000000133, -0.742083],
                [8.99014000000011, -0.742083],
                [8.99014000000011, -0.744305],
                [8.989862000000187, -0.744305],
                [8.989028000000133, -0.747083],
                [8.989028000000133, -0.748471],
                [8.988472000000115, -0.748749],
                [8.988472000000115, -0.749584],
                [8.987918, -0.75014],
                [8.98763700000012, -0.750972],
                [8.98763700000012, -0.75264],
                [8.987084, -0.753193999999837],
                [8.987084, -0.759306],
                [8.987362000000132, -0.759306],
                [8.987362000000132, -0.761807],
                [8.988193000000138, -0.764305],
                [8.989028000000133, -0.765139],
                [8.989028000000133, -0.766251],
                [8.99014000000011, -0.76736099999988],
                [8.99014000000011, -0.767917],
                [8.99180600000011, -0.769583],
                [8.99180600000011, -0.770139],
                [8.992362000000128, -0.770417],
                [8.992362000000128, -0.770973],
                [8.993194000000187, -0.771249],
                [8.994584, -0.772639],
                [8.994862000000182, -0.773749],
                [8.995416, -0.774026999999876],
                [8.995416, -0.774583],
                [8.995972, -0.774862],
                [8.995972, -0.775696],
                [8.996528000000183, -0.775971],
                [8.997084, -0.77764],
                [8.997084, -0.780416],
                [8.997360000000128, -0.780416],
                [8.997360000000128, -0.782084],
                [8.997916, -0.784028],
                [8.99875, -0.78486],
                [8.99875, -0.785694],
                [8.999582, -0.785971999999845],
                [8.999582, -0.786528],
                [9.000416, -0.787082],
                [9.000973000000101, -0.787082],
                [9.001529, -0.787637999999845],
                [9.001804000000106, -0.788751],
                [9.002361000000178, -0.788751],
                [9.002917, -0.789582],
                [9.002917, -0.790138999999897],
                [9.004027000000178, -0.790972999999894],
                [9.004307, -0.792638999999895],
                [9.005139000000156, -0.793472999999892],
                [9.005139000000156, -0.794304999999895],
                [9.005695000000173, -0.795138999999892],
                [9.006805000000156, -0.795138999999892],
                [9.006805000000156, -0.794583],
                [9.007639, -0.793751],
                [9.007639, -0.792083],
                [9.007917, -0.792083],
                [9.007917, -0.790417],
                [9.009583, -0.788472999999897],
                [9.009861, -0.787637999999845],
                [9.011805000000152, -0.787637999999845],
                [9.011527, -0.788194],
                [9.010695000000169, -0.788194],
                [9.010417, -0.788751],
                [9.009583, -0.789029],
                [9.009583, -0.790417],
                [9.010971, -0.790695],
                [9.011805000000152, -0.791251],
                [9.010971, -0.792083],
                [9.010971, -0.792638999999895],
                [9.009305, -0.793751],
                [9.009305, -0.794583],
                [9.008195, -0.794861],
                [9.007917, -0.795417],
                [9.006805000000156, -0.796249],
                [9.005695000000173, -0.79597299999989],
                [9.004583, -0.794861],
                [9.004583, -0.794304999999895],
                [9.004027000000178, -0.793751],
                [9.002917, -0.793751],
                [9.002639000000102, -0.792917],
                [9.002085, -0.792638999999895],
                [9.000973000000101, -0.792638999999895],
                [9.000416, -0.792361],
                [9.000416, -0.791804999999897],
                [8.999028, -0.791251],
                [8.997916, -0.790138999999897],
                [8.996806000000106, -0.789306999999894],
                [8.996528000000183, -0.788472999999897],
                [8.996528000000183, -0.785694],
                [8.995972, -0.785416],
                [8.995694000000128, -0.784305999999845],
                [8.995694000000128, -0.783194],
                [8.99513800000011, -0.781528],
                [8.994584, -0.780972],
                [8.994028000000128, -0.779028],
                [8.994028000000128, -0.777916],
                [8.99347200000011, -0.776806],
                [8.993194000000187, -0.776806],
                [8.992084, -0.775696],
                [8.992084, -0.77514],
                [8.990972, -0.773749],
                [8.989862000000187, -0.773471],
                [8.98875, -0.772360999999876],
                [8.988472000000115, -0.771249],
                [8.987362000000132, -0.770417],
                [8.987084, -0.769583],
                [8.986527000000137, -0.769583],
                [8.986249, -0.76902699999988],
                [8.985139, -0.767917],
                [8.985417, -0.766528999999878],
                [8.984861000000137, -0.766251],
                [8.984583, -0.765417],
                [8.984583, -0.764305],
                [8.984027, -0.763751],
                [8.984027, -0.762639],
                [8.983751, -0.761807],
                [8.983195000000137, -0.761251],
                [8.982917, -0.760416],
                [8.982917, -0.755416],
                [8.983195000000137, -0.755416],
                [8.983195000000137, -0.75264],
                [8.983471, -0.751527999999837],
                [8.984027, -0.750693999999839],
                [8.984027, -0.748749],
                [8.984583, -0.748193],
                [8.985139, -0.746249],
                [8.985139, -0.744305],
                [8.985696000000132, -0.743473],
                [8.986249, -0.740973],
                [8.986249, -0.737916999999868],
                [8.986527000000137, -0.737916999999868],
                [8.986527000000137, -0.735138],
                [8.985139, -0.733472],
                [8.985139, -0.732638],
                [8.984583, -0.732638],
                [8.98430500000012, -0.732083999999873],
                [8.983195000000137, -0.732083999999873],
                [8.982361, -0.731528],
                [8.978751000000159, -0.731528],
                [8.978195000000142, -0.732083999999873],
                [8.977917, -0.733194],
                [8.977917, -0.737916999999868],
                [8.977639000000124, -0.737916999999868],
                [8.977083000000164, -0.739029],
                [8.977083000000164, -0.739861],
                [8.976529000000141, -0.740973],
                [8.975973000000124, -0.741527],
                [8.975695, -0.742361],
                [8.975695, -0.743749],
                [8.975417000000164, -0.744583],
                [8.974304, -0.745695],
                [8.973473, -0.746249],
                [8.972363, -0.746249],
                [8.971528, -0.746527],
                [8.971250000000168, -0.747083],
                [8.970416, -0.747361],
                [8.96680600000019, -0.747361],
                [8.96680600000019, -0.74764],
                [8.964306, -0.74764],
                [8.964306, -0.747918],
                [8.962084, -0.747918],
                [8.960418, -0.748749],
                [8.9598620000001, -0.749306],
                [8.959583000000123, -0.75014],
                [8.959583000000123, -0.753472],
                [8.959027000000106, -0.754584],
                [8.959027000000106, -0.756527999999889],
                [8.958471, -0.757359999999892],
                [8.958195, -0.758472],
                [8.958471, -0.75986],
                [8.958471, -0.761528999999882],
                [8.958749000000182, -0.761528999999882],
                [8.959583000000123, -0.763473],
                [8.959583000000123, -0.76569499999988],
                [8.960418, -0.766251],
                [8.960418, -0.767917],
                [8.961250000000177, -0.770694999999876],
                [8.961806, -0.771249],
                [8.961806, -0.774026999999876],
                [8.962084, -0.77514],
                [8.96264, -0.77514],
                [8.96264, -0.776528],
                [8.963196, -0.778194],
                [8.96375, -0.77875],
                [8.96375, -0.779862],
                [8.964306, -0.780138],
                [8.964306, -0.781806],
                [8.964862, -0.782084],
                [8.965138, -0.783194],
                [8.96514000000019, -0.784305999999845],
                [8.965694, -0.785416],
                [8.966250000000173, -0.785694],
                [8.966250000000173, -0.786805999999842],
                [8.96736200000015, -0.787916],
                [8.967916000000173, -0.787916],
                [8.96847200000019, -0.788472999999897],
                [8.96847200000019, -0.789303999999845],
                [8.969306, -0.790417],
                [8.970416, -0.792361],
                [8.970416, -0.793751],
                [8.971528, -0.794583],
                [8.971528, -0.79597299999989],
                [8.970416, -0.79597299999989],
                [8.97013800000019, -0.795417],
                [8.96902800000015, -0.795417],
                [8.96847200000019, -0.795695],
                [8.96847200000019, -0.796804999999893],
                [8.969584000000168, -0.799027],
                [8.970694000000151, -0.799861],
                [8.970694000000151, -0.800416999999811],
                [8.971528, -0.801248999999814],
                [8.971806000000129, -0.802084],
                [8.973473, -0.802914999999814],
                [8.974029, -0.80375],
                [8.974585, -0.80375],
                [8.974861000000146, -0.804584],
                [8.975417000000164, -0.804584],
                [8.975695, -0.805139999999881],
                [8.976251, -0.805139999999881],
                [8.976529000000141, -0.805694],
                [8.977083000000164, -0.805694],
                [8.977361, -0.80625],
                [8.978195000000142, -0.806528],
                [8.978195000000142, -0.807084],
                [8.979305000000124, -0.807362],
                [8.980139, -0.808471999999881],
                [8.982361, -0.810694],
                [8.984583, -0.810694],
                [8.984583, -0.810971999999879],
                [8.987084, -0.810971999999879],
                [8.98763700000012, -0.810416],
                [8.98763700000012, -0.809584],
                [8.986805, -0.808471999999881],
                [8.985696000000132, -0.808194],
                [8.985139, -0.807084],
                [8.985139, -0.80625],
                [8.986527000000137, -0.80625],
                [8.987084, -0.806528],
                [8.987084, -0.807084],
                [8.987918, -0.807362],
                [8.987918, -0.807916],
                [8.98875, -0.80875],
                [8.990972, -0.809584],
                [8.99180600000011, -0.809584],
                [8.992362000000128, -0.810137999999881],
                [8.994584, -0.810137999999881],
                [8.994862000000182, -0.810694],
                [8.996806000000106, -0.810694],
                [8.997360000000128, -0.81125],
                [8.998194000000183, -0.811528],
                [8.999307000000101, -0.811528],
                [8.999860000000183, -0.812084],
                [9.000973000000101, -0.812084],
                [9.001529, -0.812637999999879],
                [9.002639000000102, -0.812916],
                [9.002639000000102, -0.813472],
                [9.003473000000156, -0.813472],
                [9.004307, -0.814307],
                [9.005417, -0.81486],
                [9.006527, -0.815138],
                [9.006805000000156, -0.815695],
                [9.009027000000174, -0.816804],
                [9.009861, -0.816804],
                [9.010971, -0.817361],
                [9.01125100000013, -0.817917],
                [9.016806, -0.817917],
                [9.017084000000125, -0.818473],
                [9.017640000000142, -0.818473],
                [9.017916, -0.819027],
                [9.018750000000125, -0.819305],
                [9.018750000000125, -0.819861],
                [9.019862000000103, -0.820695],
                [9.02125, -0.82208299999985],
                [9.022640000000138, -0.823195],
                [9.023194000000103, -0.823195],
                [9.024582, -0.821805],
                [9.024582, -0.820973],
                [9.02541600000012, -0.820139],
                [9.025694, -0.819029],
                [9.02625, -0.81875099999985],
                [9.026529, -0.817639],
                [9.02708200000012, -0.817361],
                [9.02736, -0.816251],
                [9.028473000000133, -0.815416],
                [9.028473000000133, -0.81486],
                [9.029029, -0.814582],
                [9.029305, -0.81375],
                [9.030139000000133, -0.813472],
                [9.030139000000133, -0.812916],
                [9.032361, -0.810694],
                [9.032361, -0.810137999999881],
                [9.032917000000111, -0.809862],
                [9.032917000000111, -0.809305999999879],
                [9.033749, -0.80875],
                [9.034029, -0.808194],
                [9.034029, -0.807084],
                [9.034305000000188, -0.80625],
                [9.034861, -0.805694],
                [9.034861, -0.804028],
                [9.035417, -0.80375],
                [9.035695, -0.802639999999883],
                [9.036249000000112, -0.802362],
                [9.036249000000112, -0.801527],
                [9.037083, -0.800696],
                [9.037083, -0.799861],
                [9.037915000000112, -0.799027],
                [9.037917000000107, -0.798472999999888],
                [9.038471, -0.798195],
                [9.039027, -0.797083],
                [9.039027, -0.796527],
                [9.040140000000179, -0.795417],
                [9.040418000000102, -0.794861],
                [9.041528, -0.793751],
                [9.042084000000102, -0.793751],
                [9.042084000000102, -0.792917],
                [9.043196, -0.792638999999895],
                [9.043196, -0.792083],
                [9.044306, -0.791804999999897],
                [9.044584000000157, -0.790972999999894],
                [9.045140000000174, -0.790972999999894],
                [9.045416000000102, -0.790417],
                [9.045972, -0.790417],
                [9.046528, -0.789861],
                [9.047362, -0.789861],
                [9.047916000000157, -0.788751],
                [9.04875, -0.788751],
                [9.050416, -0.786805999999842],
                [9.050972, -0.786805999999842],
                [9.051250000000152, -0.78625],
                [9.052363, -0.785971999999845],
                [9.052916000000153, -0.78486],
                [9.054029, -0.78375],
                [9.054307000000108, -0.782638],
                [9.05486, -0.782362],
                [9.05486, -0.780138],
                [9.053194, -0.778472],
                [9.052638, -0.778472],
                [9.052082, -0.777916],
                [9.052082, -0.777362],
                [9.050972, -0.777084],
                [9.050416, -0.776528],
                [9.049584000000152, -0.776528],
                [9.04875, -0.775418],
                [9.047916000000157, -0.775418],
                [9.047362, -0.774862],
                [9.046528, -0.774862],
                [9.045972, -0.774305],
                [9.045140000000174, -0.774305],
                [9.044584000000157, -0.773749],
                [9.043750000000102, -0.773749],
                [9.043196, -0.773194999999873],
                [9.04264, -0.773192999999878],
                [9.042084000000102, -0.771805],
                [9.042084000000102, -0.770694999999876],
                [9.041249000000107, -0.770139],
                [9.040140000000179, -0.76902699999988],
                [9.039583000000107, -0.767917],
                [9.039583000000107, -0.76736099999988],
                [9.039027, -0.767083],
                [9.039027, -0.766251],
                [9.038471, -0.76569499999988],
                [9.038471, -0.764583],
                [9.037917000000107, -0.763751],
                [9.037639000000183, -0.762359999999887],
                [9.037639000000183, -0.760138],
                [9.038749, -0.759027999999887],
                [9.039305000000184, -0.759027999999887],
                [9.040140000000179, -0.757638],
                [9.040140000000179, -0.756806],
                [9.040693, -0.75625],
                [9.040693, -0.754861999999889],
                [9.041249000000107, -0.754027999999892],
                [9.041249000000107, -0.75014],
                [9.040971000000184, -0.75014],
                [9.040971000000184, -0.748471],
                [9.040693, -0.748471],
                [9.040693, -0.743473],
                [9.040971000000184, -0.743473],
                [9.040971000000184, -0.741527],
                [9.041528, -0.740417],
                [9.041528, -0.739029],
                [9.042084000000102, -0.738195],
                [9.042084000000102, -0.735973],
                [9.04264, -0.73486],
                [9.043196, -0.734581999999875],
                [9.043196, -0.733749999999873],
                [9.044584000000157, -0.73236],
                [9.045140000000174, -0.73236],
                [9.045694, -0.731806],
                [9.045694, -0.731249999999875],
                [9.046250000000157, -0.730694],
                [9.047638, -0.72986],
                [9.047638, -0.729306],
                [9.048194, -0.728749999999877],
                [9.049028, -0.728472],
                [9.049028, -0.72791599999988],
                [9.050138000000175, -0.727083999999877],
                [9.050694, -0.725971999999899],
                [9.051250000000152, -0.725694],
                [9.051250000000152, -0.72514],
                [9.05180600000017, -0.724862],
                [9.05180600000017, -0.724305999999899],
                [9.052638, -0.723749999999882],
                [9.052638, -0.723194],
                [9.05347200000017, -0.721248999999887],
                [9.054585, -0.719027],
                [9.054585, -0.717916999999886],
                [9.055139, -0.717639],
                [9.055139, -0.716805],
                [9.056251, -0.715695],
                [9.057083000000148, -0.714582999999834],
                [9.057361, -0.713473],
                [9.059027, -0.711805],
                [9.059583, -0.711527],
                [9.059861000000126, -0.710417],
                [9.060973000000104, -0.710139],
                [9.060973000000104, -0.709583],
                [9.061805, -0.709029],
                [9.062083000000143, -0.707638],
                [9.062917, -0.705137999999863],
                [9.063471, -0.70486],
                [9.063471, -0.704028],
                [9.064027, -0.70375],
                [9.064027, -0.701805999999863],
                [9.063195000000121, -0.70125],
                [9.060695, -0.69875],
                [9.060695, -0.698194],
                [9.059583, -0.697362],
                [9.058473, -0.69597199999987],
                [9.058473, -0.69514],
                [9.057917, -0.694862],
                [9.057917, -0.694304999999872],
                [9.057361, -0.693196],
                [9.056805, -0.692917],
                [9.05486, -0.690970999999877],
                [9.05486, -0.690417],
                [9.053751000000148, -0.689583],
                [9.052082, -0.689583],
                [9.050416, -0.687638999999876],
                [9.050694, -0.687083],
                [9.050694, -0.685694999999896],
                [9.04986, -0.684860999999898],
                [9.04986, -0.682917],
                [9.050138000000175, -0.682917],
                [9.050138000000175, -0.681251],
                [9.04986, -0.67986],
                [9.049306, -0.679582],
                [9.04875, -0.678471999999829],
                [9.047916000000157, -0.677362],
                [9.047362, -0.677362],
                [9.046250000000157, -0.676528],
                [9.045972, -0.675972],
                [9.045140000000174, -0.675694],
                [9.045140000000174, -0.673194],
                [9.044584000000157, -0.67264],
                [9.044584000000157, -0.67125],
                [9.044028, -0.670694],
                [9.043472000000179, -0.669584],
                [9.042916000000162, -0.669584],
                [9.042362, -0.669028],
              ],
            ],
            [
              [
                [8.915416, -1.020972],
                [8.915694, -1.02014],
                [8.915694, -1.019028],
                [8.915972, -1.017917999999895],
                [8.915416, -1.01764],
                [8.91375, -1.01764],
                [8.913472000000127, -1.018193],
                [8.912362, -1.019028],
                [8.910694000000149, -1.019862],
                [8.909862, -1.019862],
                [8.909306, -1.020415999999898],
                [8.908472000000131, -1.020694],
                [8.907362000000148, -1.020694],
                [8.906806000000131, -1.021249999999895],
                [8.905971000000136, -1.021249999999895],
                [8.905139, -1.022083999999893],
                [8.904027, -1.022083999999893],
                [8.902917, -1.02264],
                [8.902639000000136, -1.023194],
                [8.902083000000118, -1.023194],
                [8.901805, -1.023749999999893],
                [8.901249, -1.023749999999893],
                [8.900139, -1.024861999999814],
                [8.899861000000158, -1.025415999999893],
                [8.899861000000158, -1.026527999999814],
                [8.900973000000135, -1.027083999999888],
                [8.902639000000136, -1.027083999999888],
                [8.902639000000136, -1.02736],
                [8.905971000000136, -1.02736],
                [8.905971000000136, -1.027083999999888],
                [8.907918000000109, -1.027083999999888],
                [8.907918000000109, -1.026806],
                [8.910140000000126, -1.026806],
                [8.910416, -1.026249999999891],
                [8.910972, -1.026249999999891],
                [8.91125000000011, -1.025694],
                [8.911806000000126, -1.025694],
                [8.912084, -1.025138],
                [8.913194, -1.024861999999814],
                [8.913194, -1.024306],
                [8.914028, -1.023194],
                [8.914584000000104, -1.022915999999896],
                [8.915416, -1.020972],
              ],
            ],
            [
              [
                [8.916528, -0.722362],
                [8.915694, -0.721806],
                [8.914306, -0.721806],
                [8.91375, -0.721248999999887],
                [8.909306, -0.721248999999887],
                [8.908472000000131, -0.722083999999882],
                [8.90764, -0.722083999999882],
                [8.906806000000131, -0.722917999999879],
                [8.905418, -0.722917999999879],
                [8.904305000000136, -0.723749999999882],
                [8.904305000000136, -0.724305999999899],
                [8.903471, -0.724862],
                [8.902639000000136, -0.724583999999879],
                [8.901527000000158, -0.724583999999879],
                [8.900139, -0.723194],
                [8.899861000000158, -0.722639999999899],
                [8.89930500000014, -0.722639999999899],
                [8.898751000000118, -0.722083999999882],
                [8.893195000000162, -0.722083999999882],
                [8.892082, -0.723194],
                [8.892082, -0.724305999999899],
                [8.891528, -0.726249999999879],
                [8.890972, -0.726249999999879],
                [8.890416, -0.726806],
                [8.890416, -0.727362],
                [8.888194000000112, -0.729583999999875],
                [8.887638, -0.731806],
                [8.887084, -0.73236],
                [8.887362000000167, -0.733194],
                [8.887362000000167, -0.73486],
                [8.886806, -0.735695],
                [8.886806, -0.736805],
                [8.887362000000167, -0.73708299999987],
                [8.887362000000167, -0.740417],
                [8.887638, -0.740417],
                [8.887638, -0.742083],
                [8.888472, -0.742917],
                [8.889304, -0.742917],
                [8.890138, -0.743749],
                [8.891250000000184, -0.744305],
                [8.892360000000167, -0.744305],
                [8.892638, -0.744861],
                [8.89375100000018, -0.745139],
                [8.894585, -0.745973],
                [8.896251, -0.745973],
                [8.896805, -0.746527],
                [8.89763900000014, -0.746527],
                [8.89763900000014, -0.747083],
                [8.898751000000118, -0.747083],
                [8.899027, -0.74764],
                [8.899861000000158, -0.74764],
                [8.900695, -0.748749],
                [8.902083000000118, -0.749584],
                [8.902639000000136, -0.75014],
                [8.903195000000153, -0.75014],
                [8.903751000000113, -0.750693999999839],
                [8.903751000000113, -0.75125],
                [8.904583, -0.751527999999837],
                [8.905693, -0.75264],
                [8.905971000000136, -0.753472],
                [8.906806000000131, -0.753472],
                [8.907362000000148, -0.754861999999889],
                [8.907918000000109, -0.754861999999889],
                [8.908194, -0.755693999999892],
                [8.909028000000148, -0.755972],
                [8.909028000000148, -0.756527999999889],
                [8.909862, -0.757638],
                [8.910416, -0.757916],
                [8.910416, -0.758472],
                [8.912084, -0.760138],
                [8.912362, -0.761251],
                [8.912918000000104, -0.761528999999882],
                [8.912918000000104, -0.762082],
                [8.91375, -0.762639],
                [8.915694, -0.762639],
                [8.916250000000105, -0.763194999999882],
                [8.916806000000122, -0.763194999999882],
                [8.916806000000122, -0.764305],
                [8.917084, -0.764860999999883],
                [8.917916000000105, -0.765417],
                [8.917916000000105, -0.766251],
                [8.918472000000122, -0.766251],
                [8.918472000000122, -0.767083],
                [8.919307000000117, -0.76736099999988],
                [8.919582000000105, -0.768194999999878],
                [8.920138000000122, -0.768194999999878],
                [8.920973000000117, -0.770973],
                [8.920973000000117, -0.772917],
                [8.921251, -0.773749],
                [8.921251, -0.775971],
                [8.9204170000001, -0.776806],
                [8.91986, -0.777916],
                [8.91986, -0.779306],
                [8.919029, -0.780138],
                [8.919026, -0.780694],
                [8.917916000000105, -0.781806],
                [8.916806000000122, -0.782362],
                [8.915416, -0.78375],
                [8.912918000000104, -0.78486],
                [8.910694000000149, -0.78486],
                [8.910140000000126, -0.785416],
                [8.909584000000109, -0.786805999999842],
                [8.909584000000109, -0.791529],
                [8.909862, -0.792361],
                [8.910416, -0.792361],
                [8.910416, -0.793195],
                [8.91125000000011, -0.794304999999895],
                [8.912362, -0.794861],
                [8.913194, -0.796527],
                [8.91375, -0.796804999999893],
                [8.91375, -0.797361],
                [8.915416, -0.799027],
                [8.915416, -0.799582999999814],
                [8.916250000000105, -0.799861],
                [8.916528, -0.80097099999989],
                [8.917084, -0.80097099999989],
                [8.91736, -0.801804999999888],
                [8.917916000000105, -0.802362],
                [8.918472000000122, -0.802362],
                [8.919307000000117, -0.803471999999886],
                [8.91986, -0.803471999999886],
                [8.920138000000122, -0.804028],
                [8.921251, -0.804862],
                [8.922361, -0.805416],
                [8.923195, -0.805416],
                [8.923473000000172, -0.805971999999883],
                [8.924307, -0.805971999999883],
                [8.925139000000172, -0.806805999999881],
                [8.925973, -0.806805999999881],
                [8.926527, -0.807362],
                [8.92736100000019, -0.807362],
                [8.92736100000019, -0.807916],
                [8.928473000000167, -0.807916],
                [8.930971, -0.80875],
                [8.931249, -0.809305999999879],
                [8.932640000000163, -0.809584],
                [8.933193, -0.810416],
                [8.934028, -0.810416],
                [8.934028, -0.810971999999879],
                [8.935416000000146, -0.812084],
                [8.935972000000163, -0.812084],
                [8.936528000000123, -0.812637999999879],
                [8.936528000000123, -0.813194],
                [8.937362, -0.813472],
                [8.937362, -0.814582],
                [8.939028, -0.815973],
                [8.939028, -0.816529],
                [8.939862000000119, -0.816804],
                [8.940138, -0.817639],
                [8.941806, -0.819027],
                [8.942916, -0.819583],
                [8.943194000000119, -0.82041699999985],
                [8.943750000000136, -0.820695],
                [8.943750000000136, -0.822916999999848],
                [8.944306000000154, -0.823473],
                [8.944306000000154, -0.824305],
                [8.945138, -0.825139],
                [8.945694, -0.826248999999848],
                [8.945694, -0.829027],
                [8.945972000000154, -0.829027],
                [8.945972000000154, -0.83264],
                [8.946529, -0.83264],
                [8.946529, -0.836527999999817],
                [8.947082000000137, -0.836527999999817],
                [8.947082000000137, -0.837915999999893],
                [8.946529, -0.838749999999891],
                [8.946529, -0.840415999999891],
                [8.946251000000132, -0.840415999999891],
                [8.946251000000132, -0.844304],
                [8.946529, -0.845416999999884],
                [8.946529, -0.847361],
                [8.947082000000137, -0.848195],
                [8.947082000000137, -0.849305],
                [8.947917000000132, -0.850139],
                [8.947917000000132, -0.851527],
                [8.948473, -0.853471],
                [8.950417, -0.855418],
                [8.95069500000011, -0.856527999999855],
                [8.951251000000127, -0.856806],
                [8.951251000000127, -0.857361999999853],
                [8.952083, -0.858193999999855],
                [8.952083, -0.860972],
                [8.95236100000011, -0.862084],
                [8.952917000000127, -0.862916],
                [8.952917000000127, -0.864584],
                [8.953195, -0.865416],
                [8.953749, -0.865972],
                [8.954305, -0.867638],
                [8.954305, -0.869582],
                [8.954861, -0.86986],
                [8.954305, -0.871806999999876],
                [8.954305, -0.873472999999876],
                [8.954583000000127, -0.873472999999876],
                [8.954583000000127, -0.878470999999877],
                [8.954861, -0.878470999999877],
                [8.954861, -0.881804999999872],
                [8.955417000000182, -0.882918],
                [8.955417000000182, -0.885416],
                [8.955695000000105, -0.885416],
                [8.955695000000105, -0.888472],
                [8.956249000000128, -0.889028],
                [8.956249000000128, -0.889862],
                [8.956805, -0.890138],
                [8.957361000000105, -0.89125],
                [8.957639, -0.892916],
                [8.958195, -0.893194],
                [8.958195, -0.89375],
                [8.958749000000182, -0.894305999999858],
                [8.959583000000123, -0.894305999999858],
                [8.96014, -0.89486],
                [8.960693000000106, -0.895973],
                [8.960693000000106, -0.897082],
                [8.961250000000177, -0.89736],
                [8.961250000000177, -0.898195],
                [8.961806, -0.899305],
                [8.96264, -0.900139],
                [8.96264, -0.901527],
                [8.962362, -0.902361],
                [8.96264, -0.903195],
                [8.963196, -0.903750999999829],
                [8.963196, -0.904583],
                [8.96375, -0.905139],
                [8.964028, -0.905973],
                [8.964028, -0.90708299999983],
                [8.964306, -0.907639],
                [8.96680600000019, -0.910139999999899],
                [8.967084, -0.911249999999882],
                [8.968194, -0.912083999999879],
                [8.96847200000019, -0.913194],
                [8.96847200000019, -0.914862],
                [8.96875, -0.914862],
                [8.96875, -0.917637999999897],
                [8.96902800000015, -0.917637999999897],
                [8.96902800000015, -0.91986],
                [8.969306, -0.921249999999873],
                [8.97013800000019, -0.922081999999875],
                [8.97013800000019, -0.922638],
                [8.970694000000151, -0.922915999999873],
                [8.970694000000151, -0.923750999999868],
                [8.971250000000168, -0.924304],
                [8.971250000000168, -0.924861],
                [8.971806000000129, -0.925973],
                [8.972363, -0.926250999999866],
                [8.972363, -0.926805],
                [8.972916000000168, -0.927082999999868],
                [8.973194, -0.928195],
                [8.973751000000163, -0.928473],
                [8.973751000000163, -0.929027],
                [8.974304, -0.929305],
                [8.974304, -0.929861],
                [8.977639000000124, -0.929861],
                [8.977639000000124, -0.930139],
                [8.979027, -0.930416999999863],
                [8.980695, -0.930416999999863],
                [8.981249, -0.930973],
                [8.98263900000012, -0.930973],
                [8.983751, -0.932361],
                [8.984583, -0.932639],
                [8.986805, -0.932639],
                [8.986805, -0.932917],
                [8.98875, -0.932917],
                [8.989584, -0.932639],
                [8.989862000000187, -0.932082999999864],
                [8.99180600000011, -0.932082999999864],
                [8.992084, -0.931527],
                [8.993194000000187, -0.931527],
                [8.994028000000128, -0.930695],
                [8.994584, -0.930695],
                [8.994860000000187, -0.930139],
                [8.99625, -0.929582999999866],
                [8.997638, -0.929305],
                [8.999307000000101, -0.929305],
                [9.000138000000106, -0.929027],
                [9.000416, -0.928195],
                [9.001251, -0.928195],
                [9.002085, -0.927361],
                [9.002639000000102, -0.927361],
                [9.002917, -0.926805],
                [9.004307, -0.926805],
                [9.005695000000173, -0.925695],
                [9.005695000000173, -0.924861],
                [9.006805000000156, -0.924581999999873],
                [9.007083, -0.924029],
                [9.008193, -0.923750999999868],
                [9.008193, -0.922638],
                [9.008473000000151, -0.921528],
                [9.009027000000174, -0.920694],
                [9.009027000000174, -0.914305999999897],
                [9.008749, -0.914305999999897],
                [9.008749, -0.909027],
                [9.009027000000174, -0.909027],
                [9.009027000000174, -0.905695],
                [9.009305, -0.905695],
                [9.009305, -0.904027],
                [9.009861, -0.902917],
                [9.010417, -0.902639],
                [9.010417, -0.901527],
                [9.010971, -0.901249],
                [9.010971, -0.898751],
                [9.011249, -0.898751],
                [9.011249, -0.897082],
                [9.011805000000152, -0.895416],
                [9.012361000000169, -0.895137999999861],
                [9.012361000000169, -0.894305999999858],
                [9.012918, -0.894028],
                [9.014862000000164, -0.892084],
                [9.015694, -0.891528],
                [9.015972000000147, -0.890972],
                [9.017640000000142, -0.889306],
                [9.017640000000142, -0.88875],
                [9.019862000000103, -0.886528],
                [9.020138, -0.885416],
                [9.020138, -0.881249],
                [9.019862000000103, -0.881249],
                [9.019862000000103, -0.879583],
                [9.019306000000142, -0.879026999999894],
                [9.019306000000142, -0.876249],
                [9.019028, -0.876249],
                [9.019028, -0.872360999999898],
                [9.019306000000142, -0.872360999999898],
                [9.019306000000142, -0.868751],
                [9.019584, -0.868751],
                [9.019584, -0.866806],
                [9.019862000000103, -0.866806],
                [9.020416000000125, -0.865694],
                [9.020416000000125, -0.864306],
                [9.020972000000143, -0.863472],
                [9.020972000000143, -0.862084],
                [9.02236, -0.857918],
                [9.022640000000138, -0.856806],
                [9.023194000000103, -0.855971],
                [9.023194000000103, -0.849305],
                [9.023472, -0.848473],
                [9.024028, -0.847917],
                [9.024028, -0.844304],
                [9.023472, -0.843195],
                [9.023472, -0.840138],
                [9.023194000000103, -0.840138],
                [9.023194000000103, -0.834861999999816],
                [9.022916, -0.834861999999816],
                [9.022640000000138, -0.833472],
                [9.022640000000138, -0.830972],
                [9.02208400000012, -0.829862],
                [9.021806, -0.828471],
                [9.021806, -0.825971],
                [9.020138, -0.824027],
                [9.019862000000103, -0.822916999999848],
                [9.019306000000142, -0.822916999999848],
                [9.018750000000125, -0.822361],
                [9.01736, -0.822361],
                [9.016528000000164, -0.82208299999985],
                [9.015418000000125, -0.820695],
                [9.014306000000147, -0.82041699999985],
                [9.01402700000017, -0.819861],
                [9.012918, -0.819583],
                [9.012639, -0.819029],
                [9.011805000000152, -0.819029],
                [9.011527, -0.818473],
                [9.009861, -0.818473],
                [9.009027000000174, -0.817639],
                [9.006251000000134, -0.816251],
                [9.005417, -0.815973],
                [9.005139000000156, -0.815416],
                [9.004583, -0.815416],
                [9.004027000000178, -0.81486],
                [8.997916, -0.81486],
                [8.996806000000106, -0.814307],
                [8.996528000000183, -0.813472],
                [8.995694000000128, -0.813472],
                [8.995416, -0.812916],
                [8.994306, -0.812637999999879],
                [8.993194000000187, -0.812637999999879],
                [8.992918, -0.812084],
                [8.98875, -0.812084],
                [8.98763700000012, -0.812637999999879],
                [8.983751, -0.812637999999879],
                [8.981805, -0.812084],
                [8.981805, -0.81375],
                [8.981529000000137, -0.81375],
                [8.981527000000142, -0.815416],
                [8.98097300000012, -0.817083],
                [8.980417000000159, -0.817361],
                [8.979305000000124, -0.819583],
                [8.979305000000124, -0.821250999999847],
                [8.978471, -0.823195],
                [8.977917, -0.82374899999985],
                [8.976805, -0.824305],
                [8.976251, -0.825417],
                [8.975695, -0.825695],
                [8.975695, -0.826248999999848],
                [8.976529000000141, -0.827083],
                [8.976529000000141, -0.827917999999897],
                [8.977083000000164, -0.828471],
                [8.977083000000164, -0.829306],
                [8.977639000000124, -0.829583999999898],
                [8.977917, -0.831249999999898],
                [8.978471, -0.831806],
                [8.978473, -0.832361999999819],
                [8.979027, -0.83264],
                [8.979027, -0.834583999999893],
                [8.979305000000124, -0.834583999999893],
                [8.979305000000124, -0.837638],
                [8.979583, -0.837638],
                [8.979583, -0.839306],
                [8.980417000000159, -0.839859999999817],
                [8.980417000000159, -0.841529],
                [8.979583, -0.841807],
                [8.978751000000159, -0.840693999999871],
                [8.978471, -0.839583999999888],
                [8.978471, -0.837915999999893],
                [8.978195000000142, -0.837915999999893],
                [8.978195000000142, -0.834861999999816],
                [8.977917, -0.834861999999816],
                [8.977917, -0.833194],
                [8.977083000000164, -0.832915999999898],
                [8.976251, -0.830972],
                [8.976251, -0.83014],
                [8.975417000000164, -0.829306],
                [8.975417000000164, -0.828471],
                [8.974585, -0.827917999999897],
                [8.974585, -0.824305],
                [8.975417000000164, -0.823473],
                [8.976529000000141, -0.820973],
                [8.976529000000141, -0.819861],
                [8.977361, -0.819027],
                [8.977361, -0.818195],
                [8.977917, -0.817083],
                [8.978195000000142, -0.817083],
                [8.978751000000159, -0.815973],
                [8.979027, -0.81486],
                [8.979027, -0.812637999999879],
                [8.978471, -0.812084],
                [8.978471, -0.81125],
                [8.977083000000164, -0.810137999999881],
                [8.977083000000164, -0.809584],
                [8.976251, -0.809584],
                [8.975139, -0.80875],
                [8.974861000000146, -0.808194],
                [8.973751000000163, -0.807916],
                [8.973473, -0.807362],
                [8.972916000000168, -0.807362],
                [8.971806000000129, -0.806805999999881],
                [8.971806000000129, -0.80625],
                [8.970972, -0.80625],
                [8.970694000000151, -0.805694],
                [8.96986, -0.805694],
                [8.969584000000168, -0.805139999999881],
                [8.96875, -0.804862],
                [8.96875, -0.804028],
                [8.96736200000015, -0.802362],
                [8.966250000000173, -0.802084],
                [8.965694, -0.801248999999814],
                [8.964584000000173, -0.801248999999814],
                [8.962916000000178, -0.800416999999811],
                [8.96264, -0.799861],
                [8.962084, -0.799861],
                [8.961806, -0.79930499999989],
                [8.961250000000177, -0.79930499999989],
                [8.960418, -0.798472999999888],
                [8.960418, -0.797916999999813],
                [8.959305, -0.79763899999989],
                [8.957083000000182, -0.795417],
                [8.956249000000128, -0.794861],
                [8.955417000000182, -0.793751],
                [8.955417000000182, -0.792917],
                [8.954029000000105, -0.791804999999897],
                [8.953473, -0.791804999999897],
                [8.952083, -0.790417],
                [8.951805, -0.789029],
                [8.950973, -0.789029],
                [8.950417, -0.788194],
                [8.950417, -0.787082],
                [8.950139, -0.785971999999845],
                [8.949583000000132, -0.785416],
                [8.949583000000132, -0.78375],
                [8.949305, -0.78375],
                [8.949305, -0.780972],
                [8.94902900000011, -0.779584],
                [8.948473, -0.77875],
                [8.948473, -0.775971],
                [8.947917000000132, -0.774305],
                [8.94736, -0.773749],
                [8.94736, -0.772917],
                [8.946529, -0.771805],
                [8.945972000000154, -0.770694999999876],
                [8.945416000000137, -0.770417],
                [8.945416000000137, -0.769305],
                [8.944582, -0.768473],
                [8.944582, -0.76736099999988],
                [8.944028, -0.767083],
                [8.944028, -0.765973],
                [8.943472, -0.76569499999988],
                [8.942640000000154, -0.76402899999988],
                [8.942638000000159, -0.763194999999882],
                [8.941806, -0.762359999999887],
                [8.941806, -0.761807],
                [8.94125, -0.761528999999882],
                [8.94125, -0.760693999999887],
                [8.940694, -0.759584],
                [8.939862000000119, -0.75875],
                [8.939862000000119, -0.757916],
                [8.939306000000158, -0.75625],
                [8.938194000000124, -0.752084],
                [8.937640000000158, -0.751527999999837],
                [8.937640000000158, -0.749306],
                [8.938750000000141, -0.749861999999837],
                [8.940416000000141, -0.749861999999837],
                [8.940694, -0.749027999999839],
                [8.94125, -0.748749],
                [8.94125, -0.745417],
                [8.941528000000119, -0.745417],
                [8.941528000000119, -0.740695],
                [8.940972000000158, -0.738473],
                [8.940416000000141, -0.738195],
                [8.940416000000141, -0.737361],
                [8.938472, -0.733472],
                [8.937916, -0.733472],
                [8.937640000000158, -0.732638],
                [8.93708400000014, -0.73236],
                [8.936806, -0.730694],
                [8.93625, -0.730415999999877],
                [8.93625, -0.729862],
                [8.93514, -0.729306],
                [8.933749, -0.728194],
                [8.933749, -0.727637999999899],
                [8.933193, -0.727083999999877],
                [8.933193, -0.726249999999879],
                [8.932640000000163, -0.725971999999899],
                [8.932640000000163, -0.72514],
                [8.931805000000168, -0.724305999999899],
                [8.930139000000167, -0.724305999999899],
                [8.929861, -0.724583999999879],
                [8.929861, -0.72791599999988],
                [8.929305, -0.729028],
                [8.929305, -0.730138],
                [8.927917, -0.731249999999875],
                [8.927917, -0.731806],
                [8.927083, -0.732638],
                [8.92569500000019, -0.732638],
                [8.92569500000019, -0.730972],
                [8.925973, -0.730972],
                [8.925973, -0.72791599999988],
                [8.92569500000019, -0.727083999999877],
                [8.925139000000172, -0.726806],
                [8.925139000000172, -0.726249999999879],
                [8.924583, -0.725971999999899],
                [8.924583, -0.725415999999882],
                [8.923751, -0.724305999999899],
                [8.923751, -0.723194],
                [8.923195, -0.722083999999882],
                [8.922639000000117, -0.721527],
                [8.921251, -0.721806],
                [8.920973000000117, -0.722362],
                [8.920138000000122, -0.722639999999899],
                [8.917916000000105, -0.722639999999899],
                [8.916528, -0.722362],
              ],
            ],
            [
              [
                [8.714861, -0.620694],
                [8.713749, -0.620694],
                [8.712917000000118, -0.620416],
                [8.712334, -0.619864],
                [8.709861, -0.619862],
                [8.709029000000157, -0.620694],
                [8.708749, -0.621528],
                [8.708749, -0.62375],
                [8.70847300000014, -0.62375],
                [8.70847300000014, -0.626529],
                [8.707917000000123, -0.627082],
                [8.707917000000123, -0.627638],
                [8.707083, -0.628473],
                [8.705417, -0.629029],
                [8.705138, -0.629582999999855],
                [8.704307, -0.629582999999855],
                [8.703472, -0.630416999999852],
                [8.702916, -0.630416999999852],
                [8.702638, -0.630973],
                [8.702084000000184, -0.630973],
                [8.702084000000184, -0.633751],
                [8.70125, -0.634583],
                [8.700694, -0.636249],
                [8.700138, -0.638471],
                [8.700138, -0.640695999999821],
                [8.699862000000167, -0.640695999999821],
                [8.699862000000167, -0.64264],
                [8.699582, -0.64264],
                [8.699582, -0.647083999999893],
                [8.699306, -0.647083999999893],
                [8.699306, -0.650972],
                [8.699028, -0.650972],
                [8.699028, -0.659305],
                [8.699306, -0.659305],
                [8.699306, -0.661805],
                [8.699582, -0.661805],
                [8.699582, -0.66486099999986],
                [8.699862000000167, -0.66486099999986],
                [8.699862000000167, -0.667083],
                [8.700694, -0.667362],
                [8.700694, -0.670694],
                [8.70125, -0.67125],
                [8.70125, -0.672916],
                [8.701528000000167, -0.672916],
                [8.701528000000167, -0.674584],
                [8.702084000000184, -0.676805999999829],
                [8.702916, -0.677916],
                [8.703750000000184, -0.680416],
                [8.703750000000184, -0.681251],
                [8.704307, -0.681803999999829],
                [8.704582, -0.683472999999879],
                [8.705138, -0.684304999999881],
                [8.705138, -0.685694999999896],
                [8.705417, -0.686526999999899],
                [8.705973, -0.687083],
                [8.705973, -0.687917],
                [8.706529, -0.688194999999894],
                [8.706529, -0.689583],
                [8.707361000000162, -0.690417],
                [8.707361000000162, -0.691249],
                [8.708195, -0.691804999999874],
                [8.708195, -0.693196],
                [8.709029000000157, -0.69514],
                [8.709583000000123, -0.695418],
                [8.710971, -0.697084],
                [8.711527, -0.698194],
                [8.711527, -0.69875],
                [8.712361000000158, -0.699305999999865],
                [8.712361000000158, -0.700416],
                [8.713195, -0.70125],
                [8.713195, -0.702362],
                [8.713749, -0.702637999999865],
                [8.713749, -0.703194],
                [8.714305, -0.703471999999863],
                [8.714583000000118, -0.704584],
                [8.715139000000136, -0.70486],
                [8.715139000000136, -0.705416],
                [8.715971, -0.70625],
                [8.717918, -0.707916],
                [8.717918, -0.708473],
                [8.719028, -0.709583],
                [8.719584, -0.710695],
                [8.720137000000136, -0.711250999999834],
                [8.720137000000136, -0.711805],
                [8.720694, -0.712083],
                [8.720972000000131, -0.713195],
                [8.721806, -0.714305],
                [8.722362, -0.714582999999834],
                [8.722362, -0.715139],
                [8.722916, -0.715416999999832],
                [8.722916, -0.715973],
                [8.72375000000011, -0.716527],
                [8.72375000000011, -0.717082999999832],
                [8.724306000000126, -0.717361],
                [8.725138, -0.718748999999832],
                [8.725972000000127, -0.719305],
                [8.72736, -0.720693],
                [8.727916, -0.720971],
                [8.728472000000181, -0.721806],
                [8.730972000000122, -0.724305999999899],
                [8.7312510000001, -0.724862],
                [8.7318590000001, -0.725101],
                [8.731807, -0.725694],
                [8.73236, -0.726806],
                [8.732639000000177, -0.726806],
                [8.733195, -0.72791599999988],
                [8.733473, -0.729028],
                [8.734305000000177, -0.729583999999875],
                [8.7345830000001, -0.730972],
                [8.735417, -0.730972],
                [8.735973000000172, -0.731528],
                [8.736251, -0.732638],
                [8.736805, -0.732915999999875],
                [8.737639000000172, -0.734581999999875],
                [8.73875100000015, -0.735695],
                [8.73875100000015, -0.736250999999868],
                [8.739305000000172, -0.736529],
                [8.739305000000172, -0.73708299999987],
                [8.73986100000019, -0.737361],
                [8.73986100000019, -0.737916999999868],
                [8.74041700000015, -0.738195],
                [8.740693, -0.739305],
                [8.741249, -0.739582999999868],
                [8.741249, -0.740417],
                [8.741805, -0.740417],
                [8.743471, -0.742361],
                [8.744305000000168, -0.744027],
                [8.744305000000168, -0.744583],
                [8.744862, -0.744861],
                [8.744862, -0.745973],
                [8.745693, -0.746805],
                [8.745974, -0.747918],
                [8.747084, -0.749027999999839],
                [8.747084, -0.749861999999837],
                [8.74764, -0.750416],
                [8.74764, -0.750972],
                [8.748194, -0.752084],
                [8.74875, -0.752361999999891],
                [8.748957000000132, -0.753463],
                [8.750140000000158, -0.755693999999892],
                [8.750972, -0.75625],
                [8.750972, -0.757084],
                [8.751528, -0.757359999999892],
                [8.752084, -0.758472],
                [8.752084, -0.759306],
                [8.753194, -0.760138],
                [8.753194, -0.760972],
                [8.75375, -0.762082],
                [8.754306, -0.762359999999887],
                [8.754584000000136, -0.763473],
                [8.755416, -0.764305],
                [8.755416, -0.765417],
                [8.755972, -0.767083],
                [8.756528, -0.767083],
                [8.757082, -0.767639],
                [8.757082, -0.76902699999988],
                [8.757638, -0.76902699999988],
                [8.759307, -0.772360999999876],
                [8.759582000000137, -0.773471],
                [8.760139, -0.773749],
                [8.76131, -0.776224],
                [8.76152900000011, -0.777916],
                [8.76319500000011, -0.779584],
                [8.763473, -0.780694],
                [8.763473, -0.781806],
                [8.763751000000127, -0.782638],
                [8.76486100000011, -0.78486],
                [8.765417000000127, -0.785138],
                [8.766249000000187, -0.78736],
                [8.766249000000187, -0.788194],
                [8.766805, -0.789303999999845],
                [8.767361, -0.789582],
                [8.767917000000182, -0.790695],
                [8.767917000000182, -0.791529],
                [8.768473, -0.792083],
                [8.768473, -0.792917],
                [8.769027, -0.793195],
                [8.769583000000182, -0.794861],
                [8.769583000000182, -0.79597299999989],
                [8.770139, -0.796527],
                [8.770139, -0.79763899999989],
                [8.770971, -0.798472999999888],
                [8.770971, -0.79930499999989],
                [8.771805, -0.800138999999888],
                [8.771805, -0.801527],
                [8.772084000000177, -0.802362],
                [8.77264, -0.802914999999814],
                [8.772918, -0.804584],
                [8.773472, -0.804862],
                [8.773750000000177, -0.80625],
                [8.774584, -0.807916],
                [8.774584, -0.809028],
                [8.77514, -0.809305999999879],
                [8.77514, -0.810416],
                [8.775416000000178, -0.81125],
                [8.77625, -0.811806],
                [8.777638, -0.81486],
                [8.777640000000133, -0.816251],
                [8.778194000000155, -0.816804],
                [8.778194000000155, -0.818473],
                [8.778472, -0.818473],
                [8.778472, -0.820139],
                [8.778750000000173, -0.820139],
                [8.778750000000173, -0.821805],
                [8.779306000000133, -0.822361],
                [8.779306000000133, -0.82374899999985],
                [8.780138, -0.824861],
                [8.780416000000173, -0.825695],
                [8.780416000000173, -0.827083],
                [8.780694, -0.828196],
                [8.78125, -0.828749],
                [8.78125, -0.829583999999898],
                [8.781806, -0.83014],
                [8.781806, -0.831249999999898],
                [8.78236, -0.832083999999895],
                [8.782638000000134, -0.833472],
                [8.782638000000134, -0.834861999999816],
                [8.783194000000151, -0.83514],
                [8.783194000000151, -0.836249999999893],
                [8.783472, -0.837083999999891],
                [8.784307, -0.837638],
                [8.784307, -0.839027999999871],
                [8.784863, -0.840693999999871],
                [8.785416000000168, -0.841249999999889],
                [8.785416000000168, -0.842359999999871],
                [8.785695000000146, -0.843195],
                [8.786251000000163, -0.843750999999884],
                [8.786529, -0.845416999999884],
                [8.787083, -0.845973],
                [8.787361000000146, -0.846805],
                [8.787361000000146, -0.847917],
                [8.787917000000164, -0.848473],
                [8.788195, -0.850139],
                [8.788749, -0.850695],
                [8.788749, -0.852083],
                [8.789583000000164, -0.852639],
                [8.789583000000164, -0.854027],
                [8.790139000000124, -0.854583],
                [8.790417, -0.856249],
                [8.791251000000102, -0.857084],
                [8.791251000000102, -0.85875],
                [8.791805000000124, -0.859862],
                [8.792361000000142, -0.86014],
                [8.792639, -0.86125],
                [8.79347300000012, -0.862084],
                [8.79347300000012, -0.86375],
                [8.794305, -0.865416],
                [8.795417, -0.86625],
                [8.796527, -0.868472],
                [8.796527, -0.869028999999898],
                [8.797083, -0.869306999999822],
                [8.797361000000137, -0.870417],
                [8.798196000000132, -0.870972999999822],
                [8.79847100000012, -0.871528999999896],
                [8.79847100000012, -0.872638999999822],
                [8.799027000000137, -0.874026999999899],
                [8.799584, -0.874583],
                [8.799584, -0.875138999999876],
                [8.800694, -0.876249],
                [8.800972000000115, -0.877360999999894],
                [8.801806, -0.877638999999874],
                [8.802362000000187, -0.878749],
                [8.802916, -0.879304999999874],
                [8.803194000000133, -0.880417],
                [8.80375, -0.880694999999889],
                [8.80375, -0.881249],
                [8.80430600000011, -0.881526999999892],
                [8.804584, -0.88264],
                [8.805138, -0.882918],
                [8.805418, -0.884028],
                [8.80597200000011, -0.88514],
                [8.806528, -0.885416],
                [8.806528, -0.885971999999867],
                [8.80763800000011, -0.886528],
                [8.808194, -0.88764],
                [8.80875, -0.888194],
                [8.80875, -0.88875],
                [8.809304000000111, -0.889028],
                [8.809584, -0.890138],
                [8.810416, -0.890694],
                [8.810416, -0.89180599999986],
                [8.81125, -0.89236],
                [8.812082, -0.893471999999861],
                [8.812360000000183, -0.894582],
                [8.812917, -0.89486],
                [8.812917, -0.895416],
                [8.813473000000101, -0.895694],
                [8.813473000000101, -0.896251],
                [8.814029, -0.896529],
                [8.814029, -0.897082],
                [8.814861000000178, -0.897639],
                [8.814861000000178, -0.898195],
                [8.815417, -0.898473],
                [8.815417, -0.899029],
                [8.815973000000156, -0.899305],
                [8.815973000000156, -0.899861],
                [8.817361, -0.900973],
                [8.817361, -0.901805],
                [8.817928000000109, -0.902074],
                [8.817917, -0.902917],
                [8.819027, -0.904027],
                [8.819305000000156, -0.904861],
                [8.82015100000018, -0.904851999999892],
                [8.821249, -0.906249],
                [8.822361, -0.90874899999983],
                [8.822917, -0.909027],
                [8.823195000000169, -0.910139999999899],
                [8.824027, -0.910139999999899],
                [8.824583, -0.910693],
                [8.824583, -0.911249999999882],
                [8.825140000000147, -0.911805999999899],
                [8.825693, -0.912915999999882],
                [8.825693, -0.913471999999899],
                [8.826249, -0.913749999999879],
                [8.826249, -0.914305999999897],
                [8.82764, -0.91541599999988],
                [8.82764, -0.915971999999897],
                [8.828194, -0.916249999999877],
                [8.828194, -0.916805999999895],
                [8.82875, -0.917083999999875],
                [8.82875, -0.917637999999897],
                [8.829306, -0.917915999999877],
                [8.829306, -0.918471999999895],
                [8.82986, -0.919028],
                [8.830694000000108, -0.919306],
                [8.830972, -0.920137999999895],
                [8.832084, -0.921249999999873],
                [8.832084, -0.922081999999875],
                [8.832916000000125, -0.923750999999868],
                [8.834306, -0.924861],
                [8.834306, -0.925416999999868],
                [8.83486, -0.925695],
                [8.835140000000138, -0.926805],
                [8.836528, -0.927916999999866],
                [8.836528, -0.928473],
                [8.837082, -0.928750999999863],
                [8.837084, -0.929305],
                [8.838194, -0.930139],
                [8.838194, -0.930695],
                [8.839026000000104, -0.931248999999866],
                [8.839307, -0.932361],
                [8.83986, -0.932639],
                [8.840139, -0.933748999999864],
                [8.841529, -0.934861],
                [8.841529, -0.935418],
                [8.842083000000116, -0.935696],
                [8.843195, -0.936805],
                [8.843473000000188, -0.937918],
                [8.844027, -0.938193],
                [8.844307, -0.939306],
                [8.845139000000188, -0.940416],
                [8.846249, -0.941527999999835],
                [8.846527, -0.941527999999835],
                [8.847639, -0.942638],
                [8.848749000000112, -0.944584],
                [8.849861, -0.947082],
                [8.850417000000107, -0.947359999999833],
                [8.850417000000107, -0.948193999999887],
                [8.850971, -0.948472],
                [8.851527, -0.949859999999887],
                [8.852083000000107, -0.950694999999882],
                [8.853193, -0.951804],
                [8.853750000000161, -0.952083],
                [8.854028, -0.95319499999988],
                [8.854584, -0.953473],
                [8.854584, -0.954026999999883],
                [8.85514, -0.954305],
                [8.855443, -0.955668],
                [8.855416000000162, -0.957360999999878],
                [8.855972000000179, -0.957639],
                [8.855972000000179, -0.958473],
                [8.856528, -0.959026999999878],
                [8.856806, -0.959860999999876],
                [8.856806, -0.962639],
                [8.857084000000157, -0.962639],
                [8.857640000000174, -0.963749],
                [8.857640000000174, -0.964306],
                [8.858472, -0.965418],
                [8.858472, -0.966527999999869],
                [8.859028, -0.966806],
                [8.859028, -0.969028],
                [8.859862000000135, -0.969862],
                [8.859862000000135, -0.970694],
                [8.860416000000157, -0.97125],
                [8.860416000000157, -0.974306],
                [8.860694, -0.97486],
                [8.861528000000135, -0.975416],
                [8.861806, -0.975972],
                [8.861806, -0.980417],
                [8.861528000000135, -0.980417],
                [8.861528000000135, -0.982361],
                [8.862084000000152, -0.982638999999892],
                [8.862084000000152, -0.983195],
                [8.862638000000175, -0.983749],
                [8.862916, -0.984861],
                [8.862916, -0.987361],
                [8.863750000000152, -0.987638999999888],
                [8.863750000000152, -0.989304999999888],
                [8.864306000000113, -0.989861],
                [8.864306000000113, -0.990970999999888],
                [8.864582, -0.991249],
                [8.866251000000148, -0.991249],
                [8.866807000000108, -0.991805999999883],
                [8.866807000000108, -0.992362],
                [8.86736100000013, -0.992639999999881],
                [8.86736100000013, -0.99375],
                [8.867639, -0.994305999999881],
                [8.868749, -0.995139999999878],
                [8.868749, -0.995694],
                [8.869583000000148, -0.995971999999881],
                [8.869861, -0.996528],
                [8.870417, -0.996528],
                [8.870695000000126, -0.997084],
                [8.872083, -0.997916],
                [8.871805000000109, -0.99875],
                [8.870973, -0.99875],
                [8.869583000000148, -0.997084],
                [8.869029000000126, -0.997362],
                [8.86736100000013, -0.997362],
                [8.866529, -0.996528],
                [8.866251000000148, -0.995416],
                [8.865694, -0.994862],
                [8.865694, -0.993471999999883],
                [8.865973, -0.992639999999881],
                [8.865694, -0.992362],
                [8.864028, -0.992362],
                [8.863472, -0.991805999999883],
                [8.863472, -0.991249],
                [8.862916, -0.990139999999883],
                [8.86236, -0.989861],
                [8.86236, -0.98847099999989],
                [8.861806, -0.987917],
                [8.861806, -0.98680499999989],
                [8.860972000000174, -0.986249],
                [8.860972000000174, -0.985695],
                [8.860416000000157, -0.985417],
                [8.860416000000157, -0.979029],
                [8.859862000000135, -0.979306999999892],
                [8.859306000000174, -0.980417],
                [8.859306000000174, -0.984583],
                [8.859582, -0.985417],
                [8.860138, -0.985972999999888],
                [8.860138, -0.98680499999989],
                [8.860972000000174, -0.987361],
                [8.860972000000174, -0.98847099999989],
                [8.861806, -0.989304999999888],
                [8.861806, -0.990139999999883],
                [8.86236, -0.990693],
                [8.86236, -0.991528],
                [8.862916, -0.991805999999883],
                [8.862916, -0.992639999999881],
                [8.863472, -0.993194],
                [8.863472, -0.99375],
                [8.864028, -0.994862],
                [8.864582, -0.995139999999878],
                [8.864582, -0.995694],
                [8.865416000000153, -0.996805999999879],
                [8.865973, -0.997084],
                [8.866529, -0.997916],
                [8.86736100000013, -0.997916],
                [8.86736100000013, -0.998471999999879],
                [8.868195, -0.999305999999876],
                [8.869029000000126, -0.999584],
                [8.869029000000126, -1.000416],
                [8.869583000000148, -1.000971999999877],
                [8.870139000000108, -1.000971999999877],
                [8.870417, -1.001528],
                [8.871527, -1.001805999999874],
                [8.872083, -1.00236],
                [8.872083, -1.002916],
                [8.872639, -1.003194],
                [8.875417, -1.005973],
                [8.875695000000121, -1.007083],
                [8.876249000000143, -1.007361],
                [8.876527, -1.008473],
                [8.877083, -1.008751],
                [8.877361000000121, -1.009861],
                [8.877915000000144, -1.010139],
                [8.878196000000116, -1.011249],
                [8.878811, -1.011479],
                [8.879027000000121, -1.012639],
                [8.879584, -1.012916999999845],
                [8.879584, -1.013471],
                [8.88014, -1.013749],
                [8.880418, -1.014861],
                [8.880972, -1.015139],
                [8.880972, -1.015693],
                [8.881528000000117, -1.015971],
                [8.88264, -1.018193],
                [8.883196000000112, -1.019028],
                [8.884862000000112, -1.020694],
                [8.886528000000112, -1.020694],
                [8.887362000000167, -1.020972],
                [8.887916000000189, -1.021806],
                [8.888472, -1.022083999999893],
                [8.890416, -1.022083999999893],
                [8.890416, -1.022362],
                [8.893473, -1.022362],
                [8.897361, -1.021249999999895],
                [8.901249, -1.019306],
                [8.901527000000158, -1.018749999999898],
                [8.902639000000136, -1.018471],
                [8.904305000000136, -1.017082999999843],
                [8.904305000000136, -1.016248999999846],
                [8.906249, -1.014305],
                [8.906249, -1.013749],
                [8.90764, -1.012639],
                [8.90875, -1.012083],
                [8.909028000000148, -1.011249],
                [8.909584000000109, -1.010973],
                [8.909584000000109, -1.010139],
                [8.910416, -1.009027],
                [8.910972, -1.008751],
                [8.910972, -1.008195],
                [8.911528, -1.007917],
                [8.912918000000104, -1.006529],
                [8.91375, -1.006529],
                [8.914028, -1.005973],
                [8.915140000000122, -1.005973],
                [8.915694, -1.005695],
                [8.915972, -1.00486],
                [8.916528, -1.00486],
                [8.917638, -1.003751],
                [8.918194, -1.003471999999874],
                [8.919582000000105, -1.002085],
                [8.920138000000122, -1.00125],
                [8.921805000000177, -0.999584],
                [8.922917, -0.999028],
                [8.923195, -0.998471999999879],
                [8.92569500000019, -0.996528],
                [8.92569500000019, -0.995971999999881],
                [8.926249, -0.995694],
                [8.927083, -0.994028],
                [8.929305, -0.992084],
                [8.929305, -0.991249],
                [8.930139000000167, -0.990418],
                [8.930417, -0.989304999999888],
                [8.930971, -0.988749],
                [8.932083, -0.98847099999989],
                [8.934584, -0.98847099999989],
                [8.934584, -0.989861],
                [8.934306000000163, -0.991249],
                [8.933474, -0.991805999999883],
                [8.930971, -0.991805999999883],
                [8.930971, -0.992916],
                [8.930417, -0.994028],
                [8.932083, -0.994028],
                [8.932918, -0.993194],
                [8.932918, -0.994305999999881],
                [8.937362, -0.994305999999881],
                [8.937362, -0.994028],
                [8.939862000000119, -0.994028],
                [8.940138, -0.992916],
                [8.940138, -0.991528],
                [8.940694, -0.990418],
                [8.939862000000119, -0.989861],
                [8.939862000000119, -0.989304999999888],
                [8.940416000000141, -0.988749],
                [8.945416000000137, -0.988749],
                [8.946251000000132, -0.988195],
                [8.94736, -0.988195],
                [8.947639, -0.987638999999888],
                [8.948195, -0.987638999999888],
                [8.948473, -0.987083],
                [8.949583000000132, -0.98680499999989],
                [8.950139, -0.985972999999888],
                [8.95069500000011, -0.985972999999888],
                [8.952639, -0.98513899999989],
                [8.954305, -0.98347299999989],
                [8.955139, -0.98347299999989],
                [8.955417000000182, -0.982917],
                [8.957361000000105, -0.982083],
                [8.957361000000105, -0.981527],
                [8.958195, -0.981527],
                [8.959583000000123, -0.980417],
                [8.960693000000106, -0.97930499999984],
                [8.961250000000177, -0.979029],
                [8.9615280000001, -0.977917],
                [8.962084, -0.977917],
                [8.962362, -0.97736],
                [8.962916000000178, -0.97736],
                [8.962916000000178, -0.976529],
                [8.96375, -0.975694],
                [8.964028, -0.97486],
                [8.965972, -0.972916],
                [8.965972, -0.97236],
                [8.966528, -0.971806],
                [8.967084, -0.969306],
                [8.967638, -0.96875],
                [8.967638, -0.967084],
                [8.96847200000019, -0.965418],
                [8.97013800000019, -0.963471],
                [8.97013800000019, -0.962639],
                [8.970972, -0.960694999999873],
                [8.971528, -0.960417],
                [8.971528, -0.959860999999876],
                [8.972082, -0.959583],
                [8.972360000000151, -0.958749],
                [8.972363, -0.953473],
                [8.972638, -0.952083],
                [8.973194, -0.951804],
                [8.973194, -0.944027999999832],
                [8.973473, -0.944027999999832],
                [8.973473, -0.941806],
                [8.973751000000163, -0.940694],
                [8.974304, -0.94014],
                [8.974304, -0.934027],
                [8.973751000000163, -0.933473],
                [8.973751000000163, -0.931248999999866],
                [8.973473, -0.930416999999863],
                [8.972916000000168, -0.930139],
                [8.972082, -0.929027],
                [8.972082, -0.928195],
                [8.971250000000168, -0.927361],
                [8.971250000000168, -0.926805],
                [8.970694000000151, -0.926529],
                [8.970416, -0.925416999999868],
                [8.96986, -0.925139],
                [8.96986, -0.923194],
                [8.969306, -0.922081999999875],
                [8.96847200000019, -0.921249999999873],
                [8.968194, -0.91986],
                [8.968194, -0.917637999999897],
                [8.967916000000173, -0.917637999999897],
                [8.967916000000173, -0.914862],
                [8.96736200000015, -0.914305999999897],
                [8.967084, -0.912639999999897],
                [8.965694, -0.911249999999882],
                [8.965138, -0.910139999999899],
                [8.964028, -0.909027],
                [8.963196, -0.908471],
                [8.963196, -0.906805],
                [8.962916000000178, -0.906805],
                [8.962916000000178, -0.905139],
                [8.962362, -0.904583],
                [8.962362, -0.903750999999829],
                [8.961806, -0.903195],
                [8.9615280000001, -0.902361],
                [8.9615280000001, -0.899583],
                [8.960971, -0.899305],
                [8.960693000000106, -0.898195],
                [8.96014, -0.897917],
                [8.96014, -0.89736],
                [8.959583000000123, -0.896807],
                [8.959305, -0.895694],
                [8.957639, -0.894305999999858],
                [8.957361000000105, -0.893194],
                [8.956805, -0.892916],
                [8.956527, -0.89125],
                [8.955973, -0.890694],
                [8.955971, -0.890138],
                [8.955417000000182, -0.889862],
                [8.955417000000182, -0.889028],
                [8.954861, -0.888472],
                [8.954861, -0.885416],
                [8.954583000000127, -0.885416],
                [8.954583000000127, -0.882918],
                [8.954029000000105, -0.881804999999872],
                [8.954029000000105, -0.878472999999872],
                [8.953749, -0.878470999999877],
                [8.953749, -0.875138999999876],
                [8.953473, -0.875138999999876],
                [8.953473, -0.870694999999898],
                [8.952639, -0.870417],
                [8.95236100000011, -0.869028999999898],
                [8.95236100000011, -0.865138],
                [8.951805, -0.862916],
                [8.951251000000127, -0.862084],
                [8.951251000000127, -0.86125],
                [8.95069500000011, -0.860972],
                [8.95069500000011, -0.860416],
                [8.949861, -0.859584],
                [8.94902900000011, -0.856527999999855],
                [8.948473, -0.855971],
                [8.948473, -0.854861],
                [8.947639, -0.854027],
                [8.947639, -0.852917],
                [8.947082000000137, -0.852083],
                [8.947082000000137, -0.850417],
                [8.946807, -0.849861],
                [8.945694, -0.848748999999884],
                [8.945694, -0.846805],
                [8.945416000000137, -0.845695],
                [8.944863, -0.845139],
                [8.944863, -0.844029],
                [8.944306000000154, -0.843473],
                [8.944306000000154, -0.842916999999886],
                [8.945138, -0.842638],
                [8.945138, -0.841529],
                [8.945694, -0.840693999999871],
                [8.945694, -0.838749999999891],
                [8.945972000000154, -0.838749999999891],
                [8.945972000000154, -0.834861999999816],
                [8.945416000000137, -0.834306],
                [8.945416000000137, -0.832083999999895],
                [8.945138, -0.832083999999895],
                [8.944306000000154, -0.830417999999895],
                [8.944582, -0.829862],
                [8.944306000000154, -0.828749],
                [8.943472, -0.827917999999897],
                [8.943472, -0.826805],
                [8.942916, -0.826248999999848],
                [8.942916, -0.825139],
                [8.942084000000136, -0.824582999999848],
                [8.942084000000136, -0.824027],
                [8.94125, -0.823195],
                [8.94125, -0.822361],
                [8.940694, -0.82208299999985],
                [8.940416000000141, -0.820973],
                [8.940416000000141, -0.819583],
                [8.939584, -0.817639],
                [8.939028, -0.817639],
                [8.938750000000141, -0.817083],
                [8.938194000000124, -0.817083],
                [8.937640000000158, -0.816251],
                [8.937640000000158, -0.815695],
                [8.93708400000014, -0.815138],
                [8.93625, -0.815138],
                [8.935972000000163, -0.815973],
                [8.93514, -0.815973],
                [8.934862000000123, -0.815416],
                [8.934306000000163, -0.815416],
                [8.932361000000185, -0.813194],
                [8.931805000000168, -0.812916],
                [8.931249, -0.812084],
                [8.930139000000167, -0.811806],
                [8.930139000000167, -0.81125],
                [8.929583, -0.810694],
                [8.928193, -0.810416],
                [8.928193, -0.809862],
                [8.92736100000019, -0.809305999999879],
                [8.92569500000019, -0.809305999999879],
                [8.92569500000019, -0.809028],
                [8.9204170000001, -0.809028],
                [8.920138000000122, -0.808471999999881],
                [8.919307000000117, -0.80875],
                [8.917084, -0.80875],
                [8.917084, -0.809028],
                [8.914028, -0.809028],
                [8.914028, -0.80875],
                [8.912084, -0.80875],
                [8.910694000000149, -0.808471999999881],
                [8.909584000000109, -0.807916],
                [8.909306, -0.807362],
                [8.90875, -0.807362],
                [8.908472000000131, -0.806805999999881],
                [8.907918000000109, -0.806805999999881],
                [8.90764, -0.80625],
                [8.907084, -0.80625],
                [8.906249, -0.805139999999881],
                [8.905693, -0.805139999999881],
                [8.905139, -0.804305999999883],
                [8.904583, -0.804305999999883],
                [8.904305000000136, -0.802914999999814],
                [8.905139, -0.802914999999814],
                [8.905693, -0.80375],
                [8.906249, -0.80375],
                [8.907084, -0.804862],
                [8.908194, -0.805139999999881],
                [8.908472000000131, -0.805694],
                [8.909584000000109, -0.806528],
                [8.910694000000149, -0.806805999999881],
                [8.910972, -0.807362],
                [8.912918000000104, -0.807362],
                [8.912918000000104, -0.807637999999884],
                [8.917084, -0.807637999999884],
                [8.918472000000122, -0.807084],
                [8.918472000000122, -0.805694],
                [8.917916000000105, -0.805416],
                [8.917916000000105, -0.804862],
                [8.916528, -0.80375],
                [8.915694, -0.803471999999886],
                [8.915416, -0.802639999999883],
                [8.91486, -0.802639999999883],
                [8.914028, -0.801527],
                [8.913472000000127, -0.801527],
                [8.912918000000104, -0.80097099999989],
                [8.911806000000126, -0.79930499999989],
                [8.91125000000011, -0.79930499999989],
                [8.910694000000149, -0.798749],
                [8.910694000000149, -0.798195],
                [8.909862, -0.797916999999813],
                [8.90986, -0.797083],
                [8.909306, -0.796804999999893],
                [8.909306, -0.79597299999989],
                [8.90875, -0.79597299999989],
                [8.908194, -0.794861],
                [8.908194, -0.794304999999895],
                [8.90764, -0.794027],
                [8.90764, -0.793195],
                [8.907084, -0.792638999999895],
                [8.906806000000131, -0.791251],
                [8.905693, -0.790417],
                [8.905418, -0.789582],
                [8.905418, -0.787637999999845],
                [8.905693, -0.786528],
                [8.906249, -0.78625],
                [8.906527000000153, -0.785138],
                [8.90764, -0.784028],
                [8.907918000000109, -0.784028],
                [8.909028000000148, -0.782916],
                [8.909028000000148, -0.782362],
                [8.909584000000109, -0.781806],
                [8.910140000000126, -0.781806],
                [8.912084, -0.779862],
                [8.913472000000127, -0.779584],
                [8.913472000000127, -0.779028],
                [8.915140000000122, -0.778194],
                [8.915140000000122, -0.77764],
                [8.915972, -0.777362],
                [8.916806000000122, -0.77625],
                [8.916806000000122, -0.775418],
                [8.91736, -0.77514],
                [8.91736, -0.774026999999876],
                [8.916806000000122, -0.773471],
                [8.916250000000105, -0.770973],
                [8.915694, -0.770973],
                [8.915416, -0.770417],
                [8.914306, -0.770973],
                [8.913472000000127, -0.770973],
                [8.913472000000127, -0.770417],
                [8.912362, -0.770139],
                [8.91125000000011, -0.770139],
                [8.910416, -0.770417],
                [8.910416, -0.770973],
                [8.90875, -0.771805],
                [8.90764, -0.771805],
                [8.906806000000131, -0.772639],
                [8.905418, -0.773192999999878],
                [8.902917, -0.773192999999878],
                [8.901527000000158, -0.772917],
                [8.89930500000014, -0.771805],
                [8.899027, -0.771249],
                [8.897917, -0.770973],
                [8.89763900000014, -0.770417],
                [8.89597300000014, -0.770417],
                [8.895139, -0.769583],
                [8.894029, -0.769305],
                [8.893473, -0.768751],
                [8.892638, -0.76902699999988],
                [8.888194000000112, -0.76902699999988],
                [8.887916000000189, -0.769583],
                [8.887362000000167, -0.769583],
                [8.885694000000171, -0.771249],
                [8.884306, -0.772360999999876],
                [8.88375, -0.772360999999876],
                [8.882916, -0.773471],
                [8.882916, -0.774026999999876],
                [8.882362000000171, -0.774305],
                [8.882084, -0.775418],
                [8.88125, -0.777362],
                [8.88125, -0.778472],
                [8.881806, -0.778472],
                [8.882362000000171, -0.779028],
                [8.882362000000171, -0.779584],
                [8.883472, -0.780694],
                [8.88375, -0.78125],
                [8.884306, -0.78125],
                [8.884584000000189, -0.782084],
                [8.885694000000171, -0.782638],
                [8.886528000000112, -0.782638],
                [8.887638, -0.783194],
                [8.887916000000189, -0.78375],
                [8.889860000000112, -0.784584],
                [8.890416, -0.784584],
                [8.890694000000167, -0.785138],
                [8.892916000000184, -0.785138],
                [8.892916000000184, -0.78486],
                [8.894861000000162, -0.78486],
                [8.894861000000162, -0.785694],
                [8.891250000000184, -0.786805999999842],
                [8.888472, -0.786805999999842],
                [8.887638, -0.786528],
                [8.887084, -0.785971999999845],
                [8.886250000000189, -0.785694],
                [8.885138, -0.785694],
                [8.884862000000112, -0.785138],
                [8.883194000000117, -0.78486],
                [8.882362000000171, -0.784028],
                [8.882084, -0.782916],
                [8.88125, -0.782084],
                [8.88125, -0.780972],
                [8.880418, -0.780138],
                [8.88014, -0.779028],
                [8.88014, -0.777362],
                [8.880418, -0.77625],
                [8.88125, -0.775418],
                [8.88125, -0.774583],
                [8.881806, -0.774305],
                [8.882084, -0.773192999999878],
                [8.88264, -0.772083],
                [8.883194000000117, -0.771805],
                [8.883194000000117, -0.771249],
                [8.884028000000171, -0.770973],
                [8.885972, -0.769305],
                [8.887084, -0.768751],
                [8.887916000000189, -0.767917],
                [8.892638, -0.767917],
                [8.892638, -0.768194999999878],
                [8.894304, -0.768194999999878],
                [8.89541700000018, -0.768473],
                [8.895695, -0.769305],
                [8.898471, -0.769305],
                [8.898751000000118, -0.769860999999878],
                [8.902639000000136, -0.769860999999878],
                [8.903195000000153, -0.769583],
                [8.903195000000153, -0.76902699999988],
                [8.904027, -0.76902699999988],
                [8.905139, -0.768473],
                [8.906249, -0.76736099999988],
                [8.906527000000153, -0.766526999999883],
                [8.906527000000153, -0.765139],
                [8.906249, -0.76402899999988],
                [8.905693, -0.763473],
                [8.905693, -0.762917],
                [8.904027, -0.762359999999887],
                [8.897361, -0.762359999999887],
                [8.896251, -0.761807],
                [8.891807, -0.761807],
                [8.891807, -0.762082],
                [8.888472, -0.762082],
                [8.888472, -0.762359999999887],
                [8.885694000000171, -0.762359999999887],
                [8.884584000000189, -0.761807],
                [8.883472, -0.761807],
                [8.881806, -0.761251],
                [8.881528000000117, -0.760693999999887],
                [8.880418, -0.760693999999887],
                [8.879862000000116, -0.760416],
                [8.879305, -0.759584],
                [8.878749, -0.759584],
                [8.878471000000104, -0.759027999999887],
                [8.877915000000144, -0.759027999999887],
                [8.876805000000104, -0.757916],
                [8.875417, -0.757359999999892],
                [8.874305, -0.756527999999889],
                [8.873749, -0.755693999999892],
                [8.872083, -0.755693999999892],
                [8.870973, -0.754861999999889],
                [8.869029000000126, -0.754861999999889],
                [8.868473000000108, -0.755416],
                [8.86736100000013, -0.757084],
                [8.86736100000013, -0.758193999999889],
                [8.866251000000148, -0.759584],
                [8.866251000000148, -0.760693999999887],
                [8.865416000000153, -0.762359999999887],
                [8.865416000000153, -0.765417],
                [8.865138, -0.765417],
                [8.865138, -0.768473],
                [8.864863, -0.769583],
                [8.864306000000113, -0.770139],
                [8.864028, -0.770973],
                [8.864028, -0.772917],
                [8.864306000000113, -0.773749],
                [8.863750000000152, -0.774305],
                [8.863750000000152, -0.775696],
                [8.862638000000175, -0.778194],
                [8.861806, -0.779028],
                [8.861806, -0.779862],
                [8.860972000000174, -0.780694],
                [8.860972000000174, -0.78125],
                [8.860416000000157, -0.781528],
                [8.860416000000157, -0.782084],
                [8.859582, -0.782916],
                [8.859306000000174, -0.784305999999845],
                [8.859306000000174, -0.785971999999845],
                [8.859862000000135, -0.787082],
                [8.859862000000135, -0.788751],
                [8.860416000000157, -0.789306999999894],
                [8.860416000000157, -0.790417],
                [8.860694, -0.791251],
                [8.861528000000135, -0.791804999999897],
                [8.861806, -0.792361],
                [8.861806, -0.794027],
                [8.861528000000135, -0.794027],
                [8.861528000000135, -0.796249],
                [8.861806, -0.796249],
                [8.861806, -0.800696],
                [8.860972000000174, -0.802362],
                [8.860416000000157, -0.802362],
                [8.858472, -0.804028],
                [8.858472, -0.804862],
                [8.857916, -0.805139999999881],
                [8.857640000000174, -0.805971999999883],
                [8.856528, -0.806805999999881],
                [8.855696, -0.808471999999881],
                [8.855696, -0.809305999999879],
                [8.85514, -0.809584],
                [8.85514, -0.810971999999879],
                [8.854584, -0.812637999999879],
                [8.854028, -0.812916],
                [8.854028, -0.814028],
                [8.853474, -0.81486],
                [8.853471000000184, -0.815973],
                [8.852918, -0.816804],
                [8.852918, -0.821805],
                [8.853750000000161, -0.822639],
                [8.854306000000179, -0.82374899999985],
                [8.854306000000179, -0.826527],
                [8.852640000000179, -0.828471],
                [8.852362, -0.829583999999898],
                [8.852362, -0.832361999999819],
                [8.852640000000179, -0.832361999999819],
                [8.852640000000179, -0.834861999999816],
                [8.853193, -0.835415999999896],
                [8.853193, -0.836249999999893],
                [8.854306000000179, -0.837083999999891],
                [8.854584, -0.838193999999817],
                [8.855696, -0.839027999999871],
                [8.856806, -0.839583999999888],
                [8.858194, -0.839859999999817],
                [8.866529, -0.839859999999817],
                [8.86736100000013, -0.839306],
                [8.868473000000108, -0.839306],
                [8.871805000000109, -0.838472],
                [8.871805000000109, -0.838193999999817],
                [8.876527, -0.838193999999817],
                [8.877639, -0.838749999999891],
                [8.881528000000117, -0.838749999999891],
                [8.88264, -0.839027999999871],
                [8.882916, -0.839583999999888],
                [8.883472, -0.839583999999888],
                [8.883472, -0.840972],
                [8.88375, -0.842081999999891],
                [8.885694000000171, -0.843750999999884],
                [8.886250000000189, -0.843750999999884],
                [8.886528000000112, -0.844304],
                [8.887638, -0.844582999999886],
                [8.887638, -0.845139],
                [8.88875, -0.845416999999884],
                [8.889028000000167, -0.845973],
                [8.890138, -0.846251],
                [8.891528, -0.847917],
                [8.891528, -0.849027],
                [8.889304, -0.849027],
                [8.888472, -0.850139],
                [8.882084, -0.850139],
                [8.881806, -0.851527],
                [8.880972, -0.852361],
                [8.880693000000122, -0.853195],
                [8.879027000000121, -0.854861],
                [8.878471000000104, -0.854861],
                [8.878196000000116, -0.855418],
                [8.877083, -0.855418],
                [8.875971, -0.855693],
                [8.875971, -0.854861],
                [8.877361000000121, -0.854861],
                [8.879862000000116, -0.852917],
                [8.880137000000104, -0.852361],
                [8.88014, -0.850973],
                [8.880418, -0.850417],
                [8.882084, -0.849305],
                [8.882084, -0.848748999999884],
                [8.88375, -0.848748999999884],
                [8.885138, -0.848473],
                [8.885138, -0.849027],
                [8.885694000000171, -0.849305],
                [8.886806, -0.849305],
                [8.887916000000189, -0.849583],
                [8.888472, -0.849305],
                [8.888472, -0.848195],
                [8.888194000000112, -0.847361],
                [8.886806, -0.847082999999884],
                [8.886250000000189, -0.846527],
                [8.885138, -0.846527],
                [8.88264, -0.845416999999884],
                [8.882362000000171, -0.844861],
                [8.881806, -0.844861],
                [8.880418, -0.843750999999884],
                [8.879584, -0.842638],
                [8.878749, -0.842638],
                [8.878471000000104, -0.842081999999891],
                [8.877915000000144, -0.842081999999891],
                [8.877639, -0.841249999999889],
                [8.877083, -0.841249999999889],
                [8.876805000000104, -0.840693999999871],
                [8.876249000000143, -0.840693999999871],
                [8.875695000000121, -0.840138],
                [8.873473000000104, -0.839859999999817],
                [8.872917000000143, -0.840415999999891],
                [8.871805000000109, -0.840415999999891],
                [8.871527, -0.840972],
                [8.870417, -0.841249999999889],
                [8.86736100000013, -0.844304],
                [8.866251000000148, -0.844304],
                [8.865138, -0.844861],
                [8.864863, -0.845695],
                [8.864306000000113, -0.845695],
                [8.864028, -0.846251],
                [8.862916, -0.846251],
                [8.861806, -0.847082999999884],
                [8.859862000000135, -0.847082999999884],
                [8.859862000000135, -0.847361],
                [8.856528, -0.847361],
                [8.854306000000179, -0.846805],
                [8.854028, -0.846251],
                [8.853471000000184, -0.846251],
                [8.852362, -0.845695],
                [8.852362, -0.845139],
                [8.851805000000184, -0.844861],
                [8.851805000000184, -0.844304],
                [8.850971, -0.844029],
                [8.850417000000107, -0.842916999999886],
                [8.850415000000112, -0.842081999999891],
                [8.849583000000166, -0.841807],
                [8.849583000000166, -0.841249999999889],
                [8.849027, -0.840693999999871],
                [8.848473000000183, -0.839583999999888],
                [8.847917000000166, -0.839306],
                [8.847361, -0.838193999999817],
                [8.846805000000188, -0.837638],
                [8.846249, -0.836527999999817],
                [8.846249, -0.835415999999896],
                [8.845973, -0.834306],
                [8.845417000000111, -0.833472],
                [8.845139000000188, -0.832361999999819],
                [8.845139000000188, -0.828196],
                [8.844861, -0.827083],
                [8.844027, -0.825139],
                [8.842917, -0.824027],
                [8.842083000000116, -0.823473],
                [8.841805, -0.82208299999985],
                [8.840973, -0.821527],
                [8.840139, -0.819583],
                [8.840139, -0.817917],
                [8.83986, -0.816804],
                [8.839307, -0.816804],
                [8.838194, -0.815695],
                [8.837082, -0.815138],
                [8.836806000000138, -0.814582],
                [8.835416, -0.814582],
                [8.83458400000012, -0.813194],
                [8.834306, -0.812084],
                [8.833194, -0.811806],
                [8.832916000000125, -0.81125],
                [8.832084, -0.81125],
                [8.831250000000125, -0.810416],
                [8.829584000000125, -0.809862],
                [8.828194, -0.809862],
                [8.825971000000152, -0.809305999999879],
                [8.823471, -0.808194],
                [8.823471, -0.807637999999884],
                [8.822639000000152, -0.807362],
                [8.822083, -0.806805999999881],
                [8.822083, -0.80625],
                [8.821249, -0.805139999999881],
                [8.820417, -0.805139999999881],
                [8.819027, -0.804028],
                [8.817917, -0.802918],
                [8.817361, -0.802914999999814],
                [8.816527000000178, -0.802084],
                [8.815695, -0.802084],
                [8.814861000000178, -0.800138999999888],
                [8.814029, -0.799582999999814],
                [8.813195000000178, -0.799582999999814],
                [8.812082, -0.799027],
                [8.811529000000178, -0.797916999999813],
                [8.810972000000106, -0.797916999999813],
                [8.810694000000183, -0.797361],
                [8.810138, -0.797361],
                [8.80986, -0.796804999999893],
                [8.80875, -0.79597299999989],
                [8.808194, -0.79597299999989],
                [8.807084, -0.795417],
                [8.80625, -0.794304999999895],
                [8.805138, -0.794027],
                [8.80375, -0.792638999999895],
                [8.803194000000133, -0.791804999999897],
                [8.802362000000187, -0.791804999999897],
                [8.802362000000187, -0.791251],
                [8.800694, -0.789582],
                [8.80013700000012, -0.789306999999894],
                [8.80013700000012, -0.788751],
                [8.799584, -0.788472999999897],
                [8.799584, -0.787916],
                [8.79847100000012, -0.787082],
                [8.79847100000012, -0.785694],
                [8.797918, -0.78486],
                [8.797918, -0.781528],
                [8.797639, -0.781528],
                [8.797639, -0.779862],
                [8.797361000000137, -0.779862],
                [8.797341, -0.777911],
                [8.797918, -0.777084],
                [8.798196000000132, -0.77625],
                [8.798196000000132, -0.774862],
                [8.798749, -0.774583],
                [8.798749, -0.773749],
                [8.799306000000115, -0.773471],
                [8.80013700000012, -0.771526999999878],
                [8.80013700000012, -0.769860999999878],
                [8.800694, -0.768751],
                [8.80125, -0.768473],
                [8.80125, -0.76736099999988],
                [8.801806, -0.766251],
                [8.801806, -0.76402899999988],
                [8.801528000000133, -0.762917],
                [8.80013700000012, -0.761528999999882],
                [8.799584, -0.761528999999882],
                [8.799027000000137, -0.760693999999887],
                [8.798196000000132, -0.760693999999887],
                [8.797918, -0.760138],
                [8.797083, -0.760138],
                [8.796527, -0.759584],
                [8.796527, -0.75875],
                [8.795417, -0.758472],
                [8.793195, -0.75625],
                [8.792917000000102, -0.755693999999892],
                [8.792917000000102, -0.751527999999837],
                [8.79347300000012, -0.75125],
                [8.793749, -0.75014],
                [8.79347300000012, -0.749027999999839],
                [8.79347300000012, -0.747083],
                [8.793749, -0.747083],
                [8.793749, -0.745417],
                [8.79347300000012, -0.745417],
                [8.79347300000012, -0.741805],
                [8.794027000000142, -0.741527],
                [8.794305, -0.740139],
                [8.794305, -0.737361],
                [8.79347300000012, -0.736805],
                [8.79347300000012, -0.735695],
                [8.794305, -0.73541699999987],
                [8.794583000000102, -0.734581999999875],
                [8.795417, -0.733749999999873],
                [8.79680500000012, -0.733472],
                [8.797083, -0.732915999999875],
                [8.79680500000012, -0.73236],
                [8.79680500000012, -0.730138],
                [8.796527, -0.729583999999875],
                [8.795695000000137, -0.729028],
                [8.795695000000137, -0.726528],
                [8.795417, -0.725694],
                [8.793749, -0.725694],
                [8.793749, -0.725971999999899],
                [8.792083, -0.725971999999899],
                [8.791805000000124, -0.724862],
                [8.790971, -0.724305999999899],
                [8.791251000000102, -0.723472],
                [8.792083, -0.723472],
                [8.792083, -0.722917999999879],
                [8.792639, -0.722362],
                [8.793749, -0.722362],
                [8.794029000000137, -0.722917999999879],
                [8.795417, -0.722917999999879],
                [8.796527, -0.721527],
                [8.796527, -0.720417999999881],
                [8.796249000000103, -0.719861],
                [8.795695000000137, -0.719861],
                [8.795417, -0.719305],
                [8.794861, -0.719305],
                [8.794305, -0.720139],
                [8.79347300000012, -0.720139],
                [8.792361000000142, -0.719027],
                [8.792639, -0.718748999999832],
                [8.792361000000142, -0.717639],
                [8.791805000000124, -0.717082999999832],
                [8.790695000000142, -0.717082999999832],
                [8.790695000000142, -0.717639],
                [8.789029000000141, -0.718748999999832],
                [8.787361000000146, -0.718748999999832],
                [8.786529, -0.717639],
                [8.786529, -0.716250999999886],
                [8.786804, -0.715695],
                [8.787917000000164, -0.715416999999832],
                [8.787917000000164, -0.714027],
                [8.786804, -0.713750999999831],
                [8.785973, -0.712916999999834],
                [8.784863, -0.712916999999834],
                [8.784307, -0.712361],
                [8.784307, -0.710139],
                [8.783194000000151, -0.709304],
                [8.782916, -0.708473],
                [8.782916, -0.707082],
                [8.78236, -0.706528],
                [8.782084000000168, -0.705416],
                [8.78125, -0.705137999999863],
                [8.780694, -0.704028],
                [8.780694, -0.703471999999863],
                [8.780138, -0.703194],
                [8.77986200000015, -0.702362],
                [8.77986200000015, -0.700971999999865],
                [8.778194000000155, -0.700971999999865],
                [8.777362, -0.699862],
                [8.777362, -0.697639999999865],
                [8.777084000000173, -0.696528],
                [8.77625, -0.694584],
                [8.77514, -0.694027],
                [8.77514, -0.689583],
                [8.775416000000178, -0.688194999999894],
                [8.775694000000101, -0.688206],
                [8.775696, -0.685417],
                [8.77625, -0.685417],
                [8.776528000000155, -0.684860999999898],
                [8.777362, -0.684860999999898],
                [8.777084000000173, -0.684028999999896],
                [8.777084000000173, -0.682917],
                [8.778194000000155, -0.681803999999829],
                [8.778750000000173, -0.681803999999829],
                [8.779306000000133, -0.681251],
                [8.778750000000173, -0.67986],
                [8.778194000000155, -0.679582],
                [8.778194000000155, -0.679028],
                [8.775696, -0.678194],
                [8.7740280000001, -0.678194],
                [8.772918, -0.677916],
                [8.7723620000001, -0.677362],
                [8.7706960000001, -0.677362],
                [8.770415000000128, -0.676805999999829],
                [8.769305, -0.676528],
                [8.767917000000182, -0.676528],
                [8.767361, -0.675694],
                [8.765139, -0.675694],
                [8.765139, -0.67625],
                [8.764583000000187, -0.676528],
                [8.761805, -0.676528],
                [8.761805, -0.67625],
                [8.759861000000114, -0.67625],
                [8.759861000000114, -0.675972],
                [8.757916000000137, -0.675972],
                [8.75736000000012, -0.67625],
                [8.75736000000012, -0.677084],
                [8.757916000000137, -0.677362],
                [8.757916000000137, -0.678194],
                [8.758473, -0.678471999999829],
                [8.758473, -0.679582],
                [8.757916000000137, -0.680694],
                [8.755972, -0.682638999999881],
                [8.75486, -0.682638999999881],
                [8.754028000000119, -0.683194999999898],
                [8.752638, -0.683472999999879],
                [8.750972, -0.684304999999881],
                [8.750416, -0.684860999999898],
                [8.74986, -0.685972999999876],
                [8.749306, -0.685972999999876],
                [8.749306, -0.686804999999879],
                [8.748472000000163, -0.687083],
                [8.748472000000163, -0.686251],
                [8.749306, -0.685417],
                [8.749306, -0.684860999999898],
                [8.74986, -0.684583],
                [8.749855, -0.684022],
                [8.751528, -0.682361],
                [8.752084, -0.682361],
                [8.752362000000119, -0.681803999999829],
                [8.755138000000159, -0.681803999999829],
                [8.756250000000136, -0.681251],
                [8.756806, -0.680694],
                [8.756806, -0.678194],
                [8.756250000000136, -0.677084],
                [8.755694000000119, -0.676528],
                [8.75514, -0.676528],
                [8.754306, -0.675694],
                [8.752916000000141, -0.675416],
                [8.750416, -0.675416],
                [8.750416, -0.674028],
                [8.750972, -0.673194],
                [8.750972, -0.672084],
                [8.751528, -0.670972],
                [8.752362000000119, -0.670694],
                [8.752638, -0.669862],
                [8.752638, -0.66875],
                [8.753472000000158, -0.667918],
                [8.753472000000158, -0.665139],
                [8.753194, -0.665139],
                [8.753194, -0.662361],
                [8.752638, -0.661249],
                [8.752638, -0.656805],
                [8.752084, -0.656251],
                [8.749028000000123, -0.656251],
                [8.749028000000123, -0.655973],
                [8.746806000000163, -0.655973],
                [8.746806000000163, -0.655695],
                [8.744583, -0.655695],
                [8.74374900000015, -0.655139],
                [8.742361, -0.655139],
                [8.74208300000015, -0.654581999999891],
                [8.74152700000019, -0.654581999999891],
                [8.739583, -0.652638],
                [8.739583, -0.652081999999893],
                [8.737639000000172, -0.652081999999893],
                [8.736805, -0.651249999999891],
                [8.735695, -0.650972],
                [8.735139, -0.650415999999893],
                [8.735139, -0.649583999999891],
                [8.7345830000001, -0.649027999999873],
                [8.734029, -0.649306],
                [8.73236, -0.649306],
                [8.7312510000001, -0.649859999999876],
                [8.729584, -0.649859999999876],
                [8.729584, -0.650138],
                [8.725694, -0.650138],
                [8.725694, -0.649859999999876],
                [8.723472, -0.649859999999876],
                [8.723472, -0.650972],
                [8.724306000000126, -0.652638],
                [8.725138, -0.653472],
                [8.725138, -0.654859999999871],
                [8.724862, -0.655695],
                [8.723472, -0.655417],
                [8.722640000000126, -0.654581999999891],
                [8.722640000000126, -0.654029],
                [8.720137000000136, -0.651527999999871],
                [8.719028, -0.651527999999871],
                [8.718193, -0.651249999999891],
                [8.71764000000013, -0.650693999999874],
                [8.71764000000013, -0.650138],
                [8.716252, -0.649859999999876],
                [8.716252, -0.649306],
                [8.715417, -0.649306],
                [8.714583000000118, -0.648749999999893],
                [8.713195, -0.649306],
                [8.711527, -0.649027999999873],
                [8.710971, -0.649583999999891],
                [8.709305, -0.649859999999876],
                [8.70847300000014, -0.650693999999874],
                [8.707639, -0.650693999999874],
                [8.707917000000123, -0.649859999999876],
                [8.708749, -0.649859999999876],
                [8.709029000000157, -0.649306],
                [8.711251000000118, -0.648749999999893],
                [8.712361000000158, -0.647361999999873],
                [8.713749, -0.647361999999873],
                [8.715139000000136, -0.647915999999896],
                [8.715417, -0.648472],
                [8.716527, -0.648749999999893],
                [8.716805000000136, -0.649306],
                [8.717918, -0.649583999999891],
                [8.719028, -0.650693999999874],
                [8.720418000000109, -0.650693999999874],
                [8.720418000000109, -0.649027999999873],
                [8.719862, -0.648472],
                [8.719028, -0.648193999999876],
                [8.717918, -0.647361999999873],
                [8.717918, -0.646806],
                [8.717083, -0.646806],
                [8.716252, -0.645972],
                [8.714861, -0.645693999999821],
                [8.714861, -0.64514],
                [8.713749, -0.644861999999875],
                [8.712361000000158, -0.643472],
                [8.712083, -0.64264],
                [8.711527, -0.642361999999821],
                [8.711019000000135, -0.640664],
                [8.710695000000158, -0.640695999999821],
                [8.710695000000158, -0.639305],
                [8.710417, -0.637917],
                [8.71013900000014, -0.637917],
                [8.71013900000014, -0.634583],
                [8.710695000000158, -0.634305],
                [8.710695000000158, -0.633751],
                [8.712361000000158, -0.632639],
                [8.712639, -0.632082999999852],
                [8.714027000000158, -0.631529],
                [8.715139000000136, -0.631529],
                [8.715417, -0.632082999999852],
                [8.716527, -0.631529],
                [8.71764000000013, -0.631527],
                [8.718193, -0.631251],
                [8.718471000000136, -0.630416999999852],
                [8.718471000000136, -0.629305],
                [8.716805000000136, -0.629582999999855],
                [8.716805000000136, -0.624584],
                [8.717361000000153, -0.624306],
                [8.717361000000153, -0.623194],
                [8.716805000000136, -0.62236],
                [8.715971, -0.622084],
                [8.715417, -0.621528],
                [8.715417, -0.620972],
                [8.714861, -0.620694],
              ],
            ],
            [
              [
                [8.967638, -0.789582],
                [8.967638, -0.789029],
                [8.96680600000019, -0.788194],
                [8.965694, -0.788194],
                [8.964584000000173, -0.788751],
                [8.964584000000173, -0.790972999999894],
                [8.964862, -0.791529],
                [8.965416, -0.791529],
                [8.965694, -0.792917],
                [8.966528, -0.793751],
                [8.967638, -0.793751],
                [8.967916000000173, -0.794027],
                [8.96902800000015, -0.794027],
                [8.969306, -0.793751],
                [8.969306, -0.792638999999895],
                [8.968194, -0.789861],
                [8.967638, -0.789582],
              ],
            ],
            [
              [
                [8.959305, -0.775696],
                [8.958749000000182, -0.77514],
                [8.958749000000182, -0.774583],
                [8.957915000000128, -0.774026999999876],
                [8.957083000000182, -0.774026999999876],
                [8.956805, -0.774583],
                [8.955695000000105, -0.774862],
                [8.955971, -0.775418],
                [8.955973, -0.780972],
                [8.956527, -0.78125],
                [8.956527, -0.782084],
                [8.957083000000182, -0.782362],
                [8.956527, -0.78375],
                [8.955695000000105, -0.78375],
                [8.955139, -0.78486],
                [8.955139, -0.786528],
                [8.955417000000182, -0.787637999999845],
                [8.955973, -0.788194],
                [8.956527, -0.788194],
                [8.957639, -0.789582],
                [8.957639, -0.790138999999897],
                [8.958749000000182, -0.790417],
                [8.959027000000106, -0.790972999999894],
                [8.960693000000106, -0.790972999999894],
                [8.9615280000001, -0.791804999999897],
                [8.962084, -0.791804999999897],
                [8.964028, -0.793751],
                [8.963472, -0.791251],
                [8.963472, -0.785416],
                [8.962916000000178, -0.783472],
                [8.962362, -0.783194],
                [8.962362, -0.782084],
                [8.961806, -0.781528],
                [8.961806, -0.780694],
                [8.961250000000177, -0.780416],
                [8.960693000000106, -0.779028],
                [8.960693000000106, -0.778194],
                [8.96014, -0.77764],
                [8.96014, -0.776528],
                [8.959583000000123, -0.776528],
                [8.959305, -0.775696],
              ],
            ],
            [
              [
                [8.955139, -0.766805],
                [8.955973, -0.767083],
                [8.955973, -0.765139],
                [8.955417000000182, -0.765139],
                [8.955139, -0.764583],
                [8.954305, -0.764860999999883],
                [8.954305, -0.766251],
                [8.955139, -0.766805],
              ],
            ],
            [
              [
                [8.79680500000012, -0.738195],
                [8.797083, -0.738195],
                [8.797083, -0.736250999999868],
                [8.796527, -0.736529],
                [8.795417, -0.736529],
                [8.795417, -0.739582999999868],
                [8.796249000000103, -0.739582999999868],
                [8.79680500000012, -0.739029],
                [8.79680500000012, -0.738195],
              ],
            ],
            [
              [
                [8.799306000000115, -0.730694],
                [8.798749, -0.730138],
                [8.797918, -0.730415999999877],
                [8.797918, -0.733749999999873],
                [8.798749, -0.733194],
                [8.799027000000137, -0.73236],
                [8.799027000000137, -0.730972],
                [8.799306000000115, -0.730694],
              ],
            ],
            [
              [
                [8.91875, -0.710695],
                [8.918194, -0.709861],
                [8.916806000000122, -0.709861],
                [8.916528, -0.710695],
                [8.916528, -0.713750999999831],
                [8.917084, -0.714582999999834],
                [8.917084, -0.716250999999886],
                [8.91736, -0.716805],
                [8.917916000000105, -0.716805],
                [8.918194, -0.717361],
                [8.919307000000117, -0.716805],
                [8.920138000000122, -0.716805],
                [8.9204170000001, -0.716250999999886],
                [8.920973000000117, -0.716250999999886],
                [8.921529, -0.715695],
                [8.921529, -0.714582999999834],
                [8.921805000000177, -0.713473],
                [8.921251, -0.713195],
                [8.921251, -0.712361],
                [8.9204170000001, -0.712083],
                [8.919307000000117, -0.710695],
                [8.91875, -0.710695],
              ],
            ],
            [
              [
                [9.012639, -0.637083],
                [9.012361000000169, -0.636249],
                [9.011527, -0.635973],
                [9.011249, -0.636527],
                [9.010695000000169, -0.636527],
                [9.009861, -0.637361],
                [9.009027000000174, -0.638749],
                [9.008193, -0.639583],
                [9.007083, -0.639861],
                [9.007083, -0.640417999999897],
                [9.005973, -0.641527999999823],
                [9.005417, -0.641527999999823],
                [9.003473000000156, -0.643472],
                [9.003473000000156, -0.644027999999821],
                [9.002917, -0.644306],
                [9.001529, -0.644306],
                [9.000695000000178, -0.644583999999895],
                [8.999582, -0.644583999999895],
                [8.999028, -0.645138],
                [8.998472000000106, -0.64514],
                [8.996528000000183, -0.647083999999893],
                [8.995694000000128, -0.647361999999873],
                [8.994584, -0.647361999999873],
                [8.99375, -0.64764],
                [8.992918, -0.648749999999893],
                [8.992362000000128, -0.649027999999873],
                [8.992362000000128, -0.649859999999876],
                [8.991528000000187, -0.650138],
                [8.99125, -0.651249999999891],
                [8.990416, -0.652081999999893],
                [8.988193000000138, -0.652915999999891],
                [8.986527000000137, -0.653751],
                [8.985417, -0.654029],
                [8.984027, -0.654029],
                [8.982917, -0.653472],
                [8.978751000000159, -0.653472],
                [8.978471, -0.654029],
                [8.976805, -0.654029],
                [8.976251, -0.654581999999891],
                [8.975417000000164, -0.654859999999871],
                [8.973751000000163, -0.654859999999871],
                [8.973751000000163, -0.655139],
                [8.972638, -0.655695],
                [8.971806000000129, -0.655695],
                [8.971250000000168, -0.656251],
                [8.970416, -0.656529],
                [8.968192000000101, -0.65652],
                [8.967638, -0.657083],
                [8.966528, -0.657361],
                [8.965972, -0.657917],
                [8.965972, -0.658473],
                [8.965416, -0.658751],
                [8.964862, -0.659583],
                [8.964028, -0.659861],
                [8.96264, -0.660973],
                [8.962084, -0.662083],
                [8.961250000000177, -0.66319499999986],
                [8.960971, -0.664583],
                [8.96014, -0.665417],
                [8.959583000000123, -0.665417],
                [8.959305, -0.665971],
                [8.958471, -0.666249],
                [8.957361000000105, -0.667362],
                [8.957083000000182, -0.667362],
                [8.956527, -0.668471],
                [8.956527, -0.67014],
                [8.955973, -0.670418],
                [8.955139, -0.672362],
                [8.955139, -0.674306],
                [8.955417000000182, -0.674306],
                [8.955417000000182, -0.677084],
                [8.954861, -0.677638],
                [8.954305, -0.677638],
                [8.954029000000105, -0.678194],
                [8.954029000000105, -0.680416],
                [8.954861, -0.681251],
                [8.954583000000127, -0.681803999999829],
                [8.953749, -0.680972999999881],
                [8.953195, -0.680972999999881],
                [8.953195, -0.67875],
                [8.953473, -0.677362],
                [8.951251000000127, -0.677362],
                [8.951251000000127, -0.677916],
                [8.949583000000132, -0.678194],
                [8.948748000000137, -0.679028],
                [8.947917000000132, -0.679028],
                [8.947082000000137, -0.679582],
                [8.947082000000137, -0.680137999999829],
                [8.946529, -0.680416],
                [8.946529, -0.680972999999881],
                [8.945416000000137, -0.682082],
                [8.944863, -0.684304999999881],
                [8.944863, -0.688749],
                [8.945416000000137, -0.690138999999874],
                [8.945972000000154, -0.690417],
                [8.945972000000154, -0.690970999999877],
                [8.946529, -0.691526999999894],
                [8.947639, -0.691526999999894],
                [8.947917000000132, -0.691804999999874],
                [8.947917000000132, -0.693196],
                [8.947082000000137, -0.694304999999872],
                [8.947082000000137, -0.69514],
                [8.946529, -0.695693],
                [8.946529, -0.696805999999867],
                [8.945972000000154, -0.697362],
                [8.945972000000154, -0.702084],
                [8.946251000000132, -0.703194],
                [8.946807, -0.70375],
                [8.946807, -0.706528],
                [8.946251000000132, -0.70736],
                [8.946251000000132, -0.710417],
                [8.946807, -0.710973],
                [8.946807, -0.717082999999832],
                [8.947082000000137, -0.717082999999832],
                [8.947082000000137, -0.718748999999832],
                [8.94736, -0.720139],
                [8.948195, -0.720971],
                [8.948748000000137, -0.722083999999882],
                [8.948751, -0.722917999999879],
                [8.949583000000132, -0.723749999999882],
                [8.949861, -0.72514],
                [8.95069500000011, -0.725971999999899],
                [8.951527, -0.727637999999899],
                [8.951527, -0.728194],
                [8.95236100000011, -0.729028],
                [8.95236100000011, -0.72986],
                [8.953195, -0.730972],
                [8.954029000000105, -0.732638],
                [8.954861, -0.733194],
                [8.954861, -0.733749999999873],
                [8.955417000000182, -0.734029],
                [8.955417000000182, -0.734581999999875],
                [8.956527, -0.73486],
                [8.956527, -0.73541699999987],
                [8.957083000000182, -0.735973],
                [8.957915000000128, -0.736250999999868],
                [8.958471, -0.736805],
                [8.958471, -0.737361],
                [8.959305, -0.737639],
                [8.959305, -0.738195],
                [8.96014, -0.738751],
                [8.96014, -0.739305],
                [8.961806, -0.741251],
                [8.962362, -0.741527],
                [8.96264, -0.742639],
                [8.963196, -0.742639],
                [8.963472, -0.743473],
                [8.964584000000173, -0.743749],
                [8.964862, -0.744305],
                [8.966528, -0.744305],
                [8.967084, -0.744861],
                [8.967916000000173, -0.745139],
                [8.969584000000168, -0.745139],
                [8.970416, -0.744861],
                [8.970694000000151, -0.744305],
                [8.971250000000168, -0.744305],
                [8.972082, -0.743473],
                [8.972082, -0.742917],
                [8.972916000000168, -0.742083],
                [8.972916000000168, -0.741251],
                [8.973751000000163, -0.740417],
                [8.973751000000163, -0.739582999999868],
                [8.974304, -0.739305],
                [8.974304, -0.732915999999875],
                [8.974861000000146, -0.732638],
                [8.974861000000146, -0.731528],
                [8.975695, -0.730694],
                [8.975695, -0.728472],
                [8.976529000000141, -0.727637999999899],
                [8.977083000000164, -0.726528],
                [8.977083000000164, -0.725971999999899],
                [8.978195000000142, -0.725694],
                [8.978471, -0.72514],
                [8.98097300000012, -0.72514],
                [8.98208300000016, -0.725415999999882],
                [8.98263900000012, -0.725971999999899],
                [8.98263900000012, -0.726528],
                [8.984027, -0.726528],
                [8.98430500000012, -0.727362],
                [8.985417, -0.727362],
                [8.986805, -0.728749999999877],
                [8.987084, -0.729306],
                [8.988472000000115, -0.730415999999877],
                [8.988472000000115, -0.730972],
                [8.989306, -0.731806],
                [8.99014000000011, -0.732083999999873],
                [8.990416, -0.732915999999875],
                [8.99180600000011, -0.734581999999875],
                [8.992362000000128, -0.73486],
                [8.992362000000128, -0.735695],
                [8.993194000000187, -0.736529],
                [8.993194000000187, -0.737916999999868],
                [8.99347200000011, -0.738195],
                [8.997360000000128, -0.738195],
                [8.998194000000183, -0.737639],
                [8.998194000000183, -0.73708299999987],
                [8.99875, -0.736529],
                [8.999307000000101, -0.736529],
                [9.000138000000106, -0.735695],
                [9.000695000000178, -0.733749999999873],
                [9.001529, -0.733194],
                [9.001804000000106, -0.731528],
                [9.002361000000178, -0.730972],
                [9.002361000000178, -0.728194],
                [9.003195, -0.727362],
                [9.003195, -0.726806],
                [9.003751, -0.726528],
                [9.003751, -0.725971999999899],
                [9.004307, -0.725694],
                [9.004583, -0.724862],
                [9.005139000000156, -0.724305999999899],
                [9.005973, -0.724028],
                [9.007083, -0.722917999999879],
                [9.008193, -0.722083999999882],
                [9.010139000000152, -0.720971],
                [9.010139000000152, -0.720417999999881],
                [9.009583, -0.720139],
                [9.009583, -0.719582999999886],
                [9.008749, -0.719305],
                [9.008193, -0.718748999999832],
                [9.008193, -0.715695],
                [9.009861, -0.714027],
                [9.010971, -0.714027],
                [9.011249, -0.713473],
                [9.012918, -0.713473],
                [9.013474, -0.714027],
                [9.01402700000017, -0.714027],
                [9.01514, -0.715139],
                [9.015418000000125, -0.716527],
                [9.016806, -0.716805],
                [9.016806, -0.715973],
                [9.01736, -0.715416999999832],
                [9.01736, -0.714861],
                [9.017916, -0.714582999999834],
                [9.017916, -0.714027],
                [9.018472, -0.713749],
                [9.019584, -0.712639],
                [9.019584, -0.710695],
                [9.018194000000165, -0.709304],
                [9.018194000000165, -0.708473],
                [9.017640000000142, -0.708195],
                [9.01736, -0.707082],
                [9.01736, -0.705416],
                [9.018194000000165, -0.704584],
                [9.018750000000125, -0.704584],
                [9.019028, -0.704028],
                [9.019584, -0.704028],
                [9.020138, -0.703471999999863],
                [9.02125, -0.70375],
                [9.02375000000012, -0.70375],
                [9.024584, -0.704306],
                [9.026529, -0.704306],
                [9.02708200000012, -0.70486],
                [9.029861, -0.70486],
                [9.030417, -0.704306],
                [9.030139000000133, -0.70375],
                [9.030139000000133, -0.701805999999863],
                [9.029583000000116, -0.700694],
                [9.028195, -0.699028],
                [9.027638000000138, -0.699028],
                [9.026807000000133, -0.697916],
                [9.025972000000138, -0.697639999999865],
                [9.025694, -0.69625],
                [9.024306000000138, -0.694862],
                [9.024306000000138, -0.693749],
                [9.02375000000012, -0.693749],
                [9.023472, -0.692917],
                [9.023472, -0.691526999999894],
                [9.022640000000138, -0.690972999999872],
                [9.02208400000012, -0.690970999999877],
                [9.02125, -0.690138999999874],
                [9.02125, -0.689583],
                [9.020416000000125, -0.688749],
                [9.019862000000103, -0.688749],
                [9.019584, -0.688194999999894],
                [9.018750000000125, -0.687917],
                [9.018750000000125, -0.687083],
                [9.017916, -0.686526999999899],
                [9.017916, -0.684860999999898],
                [9.01736, -0.683194999999898],
                [9.016806, -0.682638999999881],
                [9.016806, -0.681803999999829],
                [9.01625, -0.681251],
                [9.01625, -0.680137999999829],
                [9.015972000000147, -0.679028],
                [9.01625, -0.678471999999829],
                [9.016806, -0.678471999999829],
                [9.017084000000125, -0.677916],
                [9.017640000000142, -0.677916],
                [9.017916, -0.677362],
                [9.018472, -0.67736],
                [9.020972000000143, -0.67625],
                [9.021015000000148, -0.675917],
                [9.02208400000012, -0.675416],
                [9.023194000000103, -0.675416],
                [9.024028, -0.674862],
                [9.02625, -0.67486],
                [9.02625, -0.674584],
                [9.028195, -0.674584],
                [9.028195, -0.674306],
                [9.030139000000133, -0.674306],
                [9.031251000000111, -0.67375],
                [9.032917000000111, -0.672084],
                [9.032917000000111, -0.67014],
                [9.033195, -0.67014],
                [9.033195, -0.668471],
                [9.033473, -0.668471],
                [9.033473, -0.666805],
                [9.034029, -0.665694999999857],
                [9.034029, -0.662917],
                [9.033749, -0.662917],
                [9.033749, -0.660695],
                [9.033195, -0.660417],
                [9.033195, -0.659861],
                [9.032361, -0.659583],
                [9.032083, -0.659027],
                [9.031251000000111, -0.659027],
                [9.029029, -0.657917],
                [9.028195, -0.657639],
                [9.027917000000116, -0.657083],
                [9.026807000000133, -0.657083],
                [9.026529, -0.656529],
                [9.025694, -0.656251],
                [9.024582, -0.656251],
                [9.022640000000138, -0.655695],
                [9.022640000000138, -0.655139],
                [9.021806, -0.654581999999891],
                [9.02125, -0.654581999999891],
                [9.020138, -0.654029],
                [9.019862000000103, -0.653472],
                [9.019306000000142, -0.653472],
                [9.018472, -0.652638],
                [9.017640000000142, -0.652359999999874],
                [9.01736, -0.652915999999891],
                [9.016806, -0.652915999999891],
                [9.016528000000164, -0.653472],
                [9.015418000000125, -0.653472],
                [9.014862000000164, -0.655139],
                [9.015418000000125, -0.655417],
                [9.015694, -0.656529],
                [9.01625, -0.657083],
                [9.01625, -0.657917],
                [9.017084000000125, -0.658751],
                [9.017084000000125, -0.661527],
                [9.016528000000164, -0.662639],
                [9.015972000000147, -0.662639],
                [9.015694, -0.66319499999986],
                [9.014306000000147, -0.663749],
                [9.012083, -0.663749],
                [9.010971, -0.663473],
                [9.010417, -0.662917],
                [9.010417, -0.662361],
                [9.009305, -0.661251],
                [9.008749, -0.660973],
                [9.008473000000151, -0.659305],
                [9.007639, -0.659027],
                [9.007361000000174, -0.658473],
                [9.004861, -0.658473],
                [9.004027000000178, -0.659305],
                [9.003195, -0.659305],
                [9.002917, -0.659861],
                [9.002361000000178, -0.659861],
                [9.002085, -0.660417],
                [9.002085, -0.66319499999986],
                [9.002917, -0.664027],
                [9.002917, -0.664583],
                [9.003751, -0.66486099999986],
                [9.003751, -0.665417],
                [9.004307, -0.665971],
                [9.004583, -0.666805],
                [9.004583, -0.669306],
                [9.004307, -0.669862],
                [9.003195, -0.670694],
                [9.003195, -0.67125],
                [9.002085, -0.672084],
                [9.001529, -0.673194],
                [9.000695000000178, -0.674028],
                [9.000138000000106, -0.674028],
                [8.999307000000101, -0.675694],
                [8.99875, -0.675694],
                [8.998472000000106, -0.67625],
                [8.997084, -0.67625],
                [8.996528000000183, -0.676805999999829],
                [8.995972, -0.676805999999829],
                [8.995972, -0.678471999999829],
                [8.995694000000128, -0.678471999999829],
                [8.995694000000128, -0.682082],
                [8.99625, -0.684027],
                [8.995694000000128, -0.684583],
                [8.99513800000011, -0.683194999999898],
                [8.99513800000011, -0.682082],
                [8.994860000000187, -0.681251],
                [8.994306, -0.680972999999881],
                [8.994306, -0.67875],
                [8.994860000000187, -0.677084],
                [8.99625, -0.675694],
                [8.996806000000106, -0.675416],
                [8.998194000000183, -0.675416],
                [8.999582, -0.674306],
                [8.999582, -0.672916],
                [9.000695000000178, -0.67264],
                [9.000973000000101, -0.671806],
                [9.002361000000178, -0.670694],
                [9.002361000000178, -0.67014],
                [9.002917, -0.669584],
                [9.003473000000156, -0.668471],
                [9.003473000000156, -0.66764],
                [9.002917, -0.667083],
                [9.002361000000178, -0.667083],
                [9.001529, -0.666249],
                [9.000695000000178, -0.664583],
                [9.000695000000178, -0.661249],
                [9.001251, -0.660139],
                [9.001529, -0.660139],
                [9.002917, -0.658751],
                [9.003751, -0.658473],
                [9.004307, -0.657639],
                [9.005417, -0.657639],
                [9.006527, -0.657361],
                [9.007361000000174, -0.657639],
                [9.007639, -0.658195],
                [9.008473000000151, -0.658195],
                [9.008749, -0.658751],
                [9.009861, -0.659305],
                [9.011805000000152, -0.661249],
                [9.012918, -0.662083],
                [9.015694, -0.662083],
                [9.015972000000147, -0.661527],
                [9.015972000000147, -0.659583],
                [9.015694, -0.658473],
                [9.014862000000164, -0.656805],
                [9.014306000000147, -0.656529],
                [9.013474, -0.654581999999891],
                [9.013474, -0.652915999999891],
                [9.013193, -0.652915999999891],
                [9.013193, -0.649859999999876],
                [9.012639, -0.649583999999891],
                [9.012639, -0.649027999999873],
                [9.010417, -0.646806],
                [9.010417, -0.64264],
                [9.010695000000169, -0.641806],
                [9.011249, -0.641527999999823],
                [9.011805000000152, -0.639861],
                [9.011805000000152, -0.638471],
                [9.012361000000169, -0.637917],
                [9.012639, -0.637083],
              ],
            ],
            [
              [
                [9.079582, -0.679582],
                [9.077916, -0.679028],
                [9.075416000000189, -0.679028],
                [9.074862000000167, -0.679582],
                [9.074862000000167, -0.683472999999879],
                [9.075138, -0.683472999999879],
                [9.075138, -0.685972999999876],
                [9.075416000000189, -0.685972999999876],
                [9.075416000000189, -0.688194999999894],
                [9.075972, -0.689304999999877],
                [9.07625, -0.690695],
                [9.07625, -0.692638999999872],
                [9.076528000000167, -0.692638999999872],
                [9.076528000000167, -0.695693],
                [9.07625, -0.695693],
                [9.07625, -0.697639999999865],
                [9.077084000000184, -0.697916],
                [9.077360000000112, -0.698471999999867],
                [9.078194000000167, -0.698194],
                [9.079582, -0.698194],
                [9.080416000000184, -0.697916],
                [9.080973, -0.697362],
                [9.081804, -0.697362],
                [9.082361000000162, -0.696805999999867],
                [9.083195, -0.696528],
                [9.084307, -0.696528],
                [9.084307, -0.69597199999987],
                [9.085417, -0.695693],
                [9.085417, -0.69514],
                [9.086805, -0.693749],
                [9.087361000000158, -0.693749],
                [9.087639, -0.692361],
                [9.088195, -0.691804999999874],
                [9.088195, -0.689860999999894],
                [9.087917000000175, -0.689860999999894],
                [9.087917000000175, -0.686804999999879],
                [9.087639, -0.685694999999896],
                [9.087083, -0.685138999999879],
                [9.087083, -0.683472999999879],
                [9.085695000000158, -0.682361],
                [9.083751, -0.682361],
                [9.082639, -0.681803999999829],
                [9.081529, -0.680694],
                [9.080138, -0.680416],
                [9.079582, -0.679582],
              ],
            ],
            [
              [
                [9.100694, -0.683472999999879],
                [9.099306000000126, -0.683194999999898],
                [9.099028, -0.682638999999881],
                [9.097640000000126, -0.682638999999881],
                [9.096528000000148, -0.682361],
                [9.095693000000153, -0.682638999999881],
                [9.095418000000109, -0.682361],
                [9.094584, -0.683194999999898],
                [9.094027000000153, -0.683194999999898],
                [9.093474, -0.684028999999896],
                [9.093474, -0.687360999999896],
                [9.092917000000114, -0.687360999999896],
                [9.092917000000114, -0.690417],
                [9.093749, -0.691249],
                [9.093749, -0.691804999999874],
                [9.094584, -0.692361],
                [9.094584, -0.693196],
                [9.09514, -0.693196],
                [9.095418000000109, -0.693749],
                [9.096528000000148, -0.693749],
                [9.097362, -0.694304999999872],
                [9.097916, -0.693749],
                [9.097916, -0.692917],
                [9.098472, -0.691804999999874],
                [9.099306000000126, -0.691804999999874],
                [9.099584, -0.690417],
                [9.100138, -0.690417],
                [9.100418000000104, -0.689583],
                [9.100418000000104, -0.688472999999874],
                [9.100972000000127, -0.687917],
                [9.100972000000127, -0.685972999999876],
                [9.10125, -0.684583],
                [9.100694, -0.683472999999879],
              ],
            ],
            [
              [
                [9.017640000000142, -0.681251],
                [9.018194000000165, -0.680416],
                [9.018194000000165, -0.679028],
                [9.017084000000125, -0.679028],
                [9.017084000000125, -0.680416],
                [9.017640000000142, -0.681251],
              ],
            ],
            [
              [
                [9.104028, -0.699584],
                [9.104306000000122, -0.699305999999865],
                [9.104306000000122, -0.697362],
                [9.103750000000105, -0.697916],
                [9.102916, -0.698194],
                [9.102640000000122, -0.699028],
                [9.101528000000144, -0.699028],
                [9.101528000000144, -0.700139999999863],
                [9.101806, -0.700694],
                [9.102916, -0.700971999999865],
                [9.103472, -0.700694],
                [9.104028, -0.699584],
              ],
            ],
            [
              [
                [9.501249999000095, 0.105972000000122],
                [9.500972000000104, 0.107084001000032],
                [9.499584, 0.107084001000032],
                [9.499306000000104, 0.107638000000122],
                [9.498471000000109, 0.107638000000122],
                [9.498196, 0.107084001000032],
                [9.497083, 0.107084001000032],
                [9.496527001000118, 0.10680599900013],
                [9.49597299900006, 0.105972000000122],
                [9.49597299900006, 0.105415999000058],
                [9.495417, 0.105138],
                [9.495139000999984, 0.104306001000054],
                [9.495139000999984, 0.102916001000153],
                [9.49597299900006, 0.102084000000104],
                [9.49597299900006, 0.100972000000127],
                [9.496527001000118, 0.099862000000144],
                [9.497083, 0.099584],
                [9.497083, 0.098748999000122],
                [9.49764000099998, 0.098471000000131],
                [9.497915001000024, 0.097639001000118],
                [9.497915001000024, 0.096248999000068],
                [9.498471000000109, 0.095695000000148],
                [9.498471000000109, 0.094305],
                [9.500418001000185, 0.094305],
                [9.500418001000185, 0.094860999000161],
                [9.500972000000104, 0.095139001000064],
                [9.500972000000104, 0.095695000000148],
                [9.501527999000189, 0.095971],
                [9.50180600100009, 0.096805000000131],
                [9.50180600100009, 0.097918],
                [9.502084, 0.099027001000081],
                [9.502637999000171, 0.099027001000081],
                [9.502637999000171, 0.101249999000117],
                [9.50180600100009, 0.101806001000114],
                [9.50180600100009, 0.103472],
                [9.501249999000095, 0.104027999000152],
                [9.501249999000095, 0.105972000000122],
              ],
            ],
            [
              [
                [9.83569500100009, 0.147639001000186],
                [9.836529, 0.147917],
                [9.836529, 0.149027001000093],
                [9.835416999000188, 0.149583],
                [9.834307001000184, 0.147360999000114],
                [9.83569500100009, 0.147639001000186],
              ],
            ],
            [
              [
                [9.399028, 0.198473],
                [9.398472, 0.199027000000115],
                [9.39819400000016, 0.200417000000186],
                [9.39736, 0.200417000000186],
                [9.396806, 0.199583],
                [9.396806, 0.193473],
                [9.39708400000012, 0.192360000000178],
                [9.398472, 0.19125],
                [9.39875000000012, 0.190138],
                [9.399862000000155, 0.189306000000101],
                [9.400972000000138, 0.189028000000178],
                [9.401806, 0.188194000000124],
                [9.403472, 0.188472],
                [9.403751, 0.189306000000101],
                [9.403751, 0.192082],
                [9.404029, 0.192082],
                [9.404029, 0.194029000000114],
                [9.403751, 0.194583],
                [9.402084000000116, 0.196251],
                [9.401528000000155, 0.196251],
                [9.40125, 0.196805],
                [9.399028, 0.198473],
              ],
            ],
            [
              [
                [9.407361, 0.211250000000121],
                [9.407361, 0.211528],
                [9.406251000000111, 0.212084],
                [9.404860000000156, 0.212084],
                [9.404029, 0.210972],
                [9.401806, 0.210972],
                [9.40125, 0.211528],
                [9.400694, 0.211528],
                [9.400694, 0.21069400000016],
                [9.400138, 0.210416],
                [9.400138, 0.209862],
                [9.399028, 0.20902800000016],
                [9.399028, 0.208472000000143],
                [9.39819400000016, 0.206806000000142],
                [9.397916, 0.206806000000142],
                [9.397916, 0.204305000000147],
                [9.398472, 0.202917],
                [9.398472, 0.201527000000169],
                [9.399028, 0.200973000000147],
                [9.399028, 0.200139],
                [9.399582, 0.199583],
                [9.399862000000155, 0.198195000000169],
                [9.40041600000012, 0.198195000000169],
                [9.400972000000138, 0.197361000000114],
                [9.402638000000138, 0.197083],
                [9.405139000000133, 0.194583],
                [9.405139000000133, 0.195139],
                [9.405973, 0.195417],
                [9.406251000000111, 0.196805],
                [9.407083, 0.197361000000114],
                [9.407361, 0.198473],
                [9.407917000000111, 0.199027000000115],
                [9.408749, 0.199305],
                [9.409583000000111, 0.200139],
                [9.410139000000129, 0.201249],
                [9.410139000000129, 0.201805],
                [9.411251000000107, 0.202639000000147],
                [9.411251000000107, 0.203195000000164],
                [9.411805000000129, 0.203471],
                [9.412083, 0.204583],
                [9.412083, 0.20736200000016],
                [9.411805000000129, 0.209306],
                [9.411251000000107, 0.20958400000012],
                [9.410973000000183, 0.210416],
                [9.409861, 0.21069400000016],
                [9.408749, 0.21069400000016],
                [9.408473000000129, 0.211250000000121],
                [9.407361, 0.211250000000121],
              ],
            ],
            [
              [
                [9.513751000000127, 0.212084],
                [9.513751000000127, 0.214028000000155],
                [9.513473, 0.214584000000116],
                [9.51263900000015, 0.215138000000138],
                [9.50986, 0.215138000000138],
                [9.509584000000132, 0.21375],
                [9.50875, 0.21264],
                [9.508194, 0.211528],
                [9.507638, 0.211250000000121],
                [9.507638, 0.210416],
                [9.508472000000154, 0.210138000000143],
                [9.509028000000171, 0.20958400000012],
                [9.509028000000171, 0.20902800000016],
                [9.510138000000154, 0.208194],
                [9.510138000000154, 0.20764],
                [9.511807, 0.20764],
                [9.512360000000172, 0.208194],
                [9.512360000000172, 0.210416],
                [9.513751000000127, 0.212084],
              ],
            ],
            [
              [
                [9.547083, 0.277639],
                [9.547083, 0.277917],
                [9.54458300000016, 0.27875100000017],
                [9.542639, 0.27875100000017],
                [9.542083, 0.278473],
                [9.540695, 0.277083000000175],
                [9.539861, 0.275973],
                [9.539861, 0.274861000000158],
                [9.540417, 0.27458200000018],
                [9.540417, 0.274029],
                [9.540973, 0.273751000000175],
                [9.540973, 0.269860000000108],
                [9.541251000000159, 0.269028],
                [9.541251000000159, 0.26541600000013],
                [9.541529, 0.26541600000013],
                [9.541529, 0.263194000000112],
                [9.540973, 0.262916000000189],
                [9.540973, 0.260971],
                [9.540695, 0.260971],
                [9.540695, 0.259305],
                [9.541529, 0.259305],
                [9.541805000000181, 0.258749],
                [9.542917000000159, 0.258749],
                [9.543749, 0.259583000000134],
                [9.54458300000016, 0.259583000000134],
                [9.544861, 0.258749],
                [9.545417, 0.258749],
                [9.546251000000154, 0.257917000000134],
                [9.546251000000154, 0.257361000000117],
                [9.547917000000155, 0.257361000000117],
                [9.547917000000155, 0.257917000000134],
                [9.549027000000137, 0.258195],
                [9.549305, 0.258749],
                [9.550139000000115, 0.258749],
                [9.550139000000115, 0.260971],
                [9.550696, 0.261528000000112],
                [9.551805000000115, 0.262084000000129],
                [9.552915000000155, 0.262362],
                [9.55597200000011, 0.262362],
                [9.556528000000128, 0.261806],
                [9.558194000000128, 0.261806],
                [9.558750000000146, 0.262362],
                [9.559584, 0.264028],
                [9.560138, 0.264584000000184],
                [9.560694, 0.265694],
                [9.560694, 0.270972],
                [9.560972000000106, 0.27125000000018],
                [9.559862000000123, 0.272085000000175],
                [9.559028, 0.272085000000175],
                [9.558194000000128, 0.273194000000103],
                [9.557084000000145, 0.273473],
                [9.556806, 0.274029],
                [9.554862000000128, 0.274861000000158],
                [9.554028, 0.274861000000158],
                [9.553472, 0.275417000000175],
                [9.55264000000011, 0.275695],
                [9.549861, 0.277083000000175],
                [9.548195, 0.277639],
                [9.547083, 0.277639],
              ],
            ],
            [
              [
                [9.517083000000127, 0.295416],
                [9.517639000000145, 0.29569400000014],
                [9.517639000000145, 0.297084],
                [9.516805, 0.297084],
                [9.515973000000145, 0.295972],
                [9.515139, 0.29569400000014],
                [9.515139, 0.2945840000001],
                [9.514583, 0.29375],
                [9.513751000000127, 0.29375],
                [9.513751000000127, 0.292638],
                [9.514583, 0.292638],
                [9.515695, 0.293472000000122],
                [9.515695, 0.294028000000139],
                [9.516805, 0.294862],
                [9.517083000000127, 0.295416],
              ],
            ],
            [
              [
                [9.512917, 0.297084],
                [9.51263900000015, 0.295972],
                [9.51263900000015, 0.294028000000139],
                [9.512917, 0.293472000000122],
                [9.513473, 0.293472000000122],
                [9.513473, 0.2945840000001],
                [9.514027, 0.2945840000001],
                [9.514027, 0.29736000000014],
                [9.512917, 0.297084],
              ],
            ],
            [
              [
                [9.575417000000186, 0.53986],
                [9.575417000000186, 0.540416000000164],
                [9.574583, 0.540972000000181],
                [9.573195000000169, 0.540972000000181],
                [9.572361000000114, 0.540138],
                [9.571251, 0.539584000000104],
                [9.570139, 0.539306000000181],
                [9.569861000000174, 0.538750000000164],
                [9.567083, 0.538750000000164],
                [9.565695000000119, 0.538472],
                [9.564585, 0.537916000000109],
                [9.564585, 0.537362],
                [9.564028, 0.537084000000164],
                [9.564028, 0.536528],
                [9.563472, 0.536250000000109],
                [9.563472, 0.535694],
                [9.562916, 0.535416],
                [9.562084, 0.532916000000114],
                [9.562084, 0.530693],
                [9.561806, 0.530693],
                [9.561806, 0.527639000000136],
                [9.56236, 0.526805],
                [9.56236, 0.525971000000141],
                [9.562916, 0.525417000000118],
                [9.562916, 0.524583],
                [9.56375, 0.522917],
                [9.564028, 0.521805],
                [9.565138, 0.519861000000162],
                [9.565138, 0.518751000000123],
                [9.565695000000119, 0.518473],
                [9.565695000000119, 0.517639000000145],
                [9.566251, 0.517639000000145],
                [9.566251, 0.51680400000015],
                [9.566807, 0.515694000000167],
                [9.567361000000119, 0.51513800000015],
                [9.567917, 0.51513800000015],
                [9.568195000000173, 0.514307000000144],
                [9.56875100000019, 0.514307000000144],
                [9.56875100000019, 0.513472000000149],
                [9.569583, 0.513194],
                [9.570973, 0.512082],
                [9.570973, 0.511250000000132],
                [9.571805, 0.510416],
                [9.573195000000169, 0.510416],
                [9.574305, 0.510972],
                [9.574583, 0.511528],
                [9.576249, 0.512638],
                [9.57652700000017, 0.514028000000167],
                [9.577083000000187, 0.514582000000132],
                [9.577361, 0.515416],
                [9.577361, 0.520695],
                [9.57652700000017, 0.521527000000162],
                [9.57652700000017, 0.523195000000101],
                [9.577361, 0.524027],
                [9.577361, 0.527639000000136],
                [9.577639, 0.527639000000136],
                [9.577639, 0.529305000000136],
                [9.577918000000182, 0.529305000000136],
                [9.577918000000182, 0.532362],
                [9.577639, 0.532362],
                [9.577639, 0.536250000000109],
                [9.577361, 0.537362],
                [9.576805, 0.537638000000186],
                [9.576805, 0.538194],
                [9.576249, 0.538472],
                [9.576249, 0.539306000000181],
                [9.575417000000186, 0.53986],
              ],
            ],
          ],
        },
        properties: { GID_0: "GAB", NAME_1: "Gabon" },
      },
    ],
  },
  106: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [18.82259178200013, 4.531794072000082],
              [18.831809997000164, 4.558327198000086],
              [18.839759827000137, 4.570812225000054],
              [18.856149673000175, 4.590260029000149],
              [18.874650955000106, 4.602355958000089],
              [18.884439468000096, 4.614161969000179],
              [18.88987731800006, 4.624557019000122],
              [18.891000748000124, 4.633999824000114],
              [18.89500045800014, 4.639999867000142],
              [18.902591706000123, 4.64512777300007],
              [18.905958176000127, 4.655972003999978],
              [18.918319700999973, 4.659737110000151],
              [18.932043076000127, 4.666050911000184],
              [18.93774032600004, 4.672758103000035],
              [18.947452546000022, 4.696071148000101],
              [18.98530006400017, 4.733613014000014],
              [18.994218827000168, 4.738276005000103],
              [19.010000229000127, 4.752999783000121],
              [19.019147873000065, 4.78654909200003],
              [19.022560120000037, 4.791402817000062],
              [19.03465843200007, 4.801150799000141],
              [19.043254853, 4.820543765000139],
              [19.059400559000096, 4.843897820000052],
              [19.068130493000183, 4.877790928000081],
              [19.076999664000084, 4.894999981000126],
              [19.086000443000103, 4.905000210000026],
              [19.097999573000095, 4.921999932000063],
              [19.10599899300007, 4.928999902000157],
              [19.11300086900019, 4.933000087000096],
              [19.136125565000043, 4.940607071000159],
              [19.148284912000065, 4.941836834000185],
              [19.1606788630001, 4.94145488800001],
              [19.187540054000124, 4.939325809000025],
              [19.202442169999983, 4.942183972000123],
              [19.212039947000164, 4.949840069000061],
              [19.21746826100008, 4.962302208000097],
              [19.22649002100013, 4.988370897000095],
              [19.230998993000185, 4.997000218000096],
              [19.24129295300014, 5.006875039000079],
              [19.256160737000187, 5.01479482700006],
              [19.262582778000137, 5.015986919000113],
              [19.269899368000097, 5.020405770000082],
              [19.284553527000185, 5.026480198000172],
              [19.30945968700013, 5.045741082000063],
              [19.323999406000155, 5.055000306000011],
              [19.337831497000025, 5.060647011000128],
              [19.358869552000158, 5.072974206000026],
              [19.368001938000077, 5.081776141000091],
              [19.378000259000032, 5.098641872000144],
              [19.391918182999973, 5.112765789000036],
              [19.403354644000046, 5.121106624000106],
              [19.41297340500006, 5.123713016000124],
              [19.430002213000023, 5.130020142000092],
              [19.44692039600011, 5.128520966],
              [19.461862564000114, 5.124699115000055],
              [19.475189208000188, 5.12247896100007],
              [19.48965072599998, 5.122334004000038],
              [19.50754356400006, 5.125203134000117],
              [19.518981933000077, 5.132176876000187],
              [19.52665329000007, 5.138528823],
              [19.548000335000154, 5.144999981000069],
              [19.55493927000009, 5.145740032999981],
              [19.565999986000065, 5.144000054000173],
              [19.57699966399997, 5.13899994000019],
              [19.591999054000098, 5.133999826000036],
              [19.60199928300017, 5.131999970000095],
              [19.613000870000178, 5.128000260000079],
              [19.635162354000045, 5.128017426000099],
              [19.681999206000057, 5.132999897000161],
              [19.69599151700004, 5.132999897000161],
              [19.72500038100003, 5.118999958000075],
              [19.74099922100004, 5.1149997710001],
              [19.760999681000044, 5.106999873000063],
              [19.775560377999966, 5.102827073000128],
              [19.792789460000165, 5.100852967000037],
              [19.815999985000133, 5.096000195000158],
              [19.825000762000116, 5.09299993500008],
              [19.847999573000095, 5.07399988200018],
              [19.854740143000072, 5.063684941000133],
              [19.863380433000145, 5.036571980000076],
              [19.87381363000003, 5.017066956000065],
              [19.890420913000185, 5.001276969000173],
              [19.90100097600009, 4.993999958000188],
              [19.914999007, 4.980000019999977],
              [19.930999757, 4.973999978000052],
              [19.941074371000127, 4.972093105999988],
              [19.94934082100019, 4.972138881000149],
              [19.9720001230001, 4.979000093000138],
              [19.988000870000178, 4.977000237000027],
              [20.00419425900003, 4.971285821000095],
              [20.024000167000054, 4.966000081000118],
              [20.036848069000143, 4.958093166000026],
              [20.051374435000184, 4.946218014000124],
              [20.06824302600012, 4.924212933000092],
              [20.08414077800012, 4.913018226000133],
              [20.092199326000014, 4.90638589800011],
              [20.09634018, 4.905257225000071],
              [20.108085632000154, 4.897031784000092],
              [20.118200302000048, 4.893861771000104],
              [20.122999191000133, 4.89400005400006],
              [20.13750839100004, 4.887979030000167],
              [20.156845092000083, 4.875878811000064],
              [20.16261100800017, 4.870155812000178],
              [20.16980743500011, 4.853160858000024],
              [20.196329117000175, 4.827288151000062],
              [20.20301055800013, 4.823178769000037],
              [20.213808059000087, 4.819320203000189],
              [20.239131928000063, 4.804029941000181],
              [20.255016327000135, 4.793059827000036],
              [20.271999359000063, 4.780000210000139],
              [20.281000138000024, 4.775000096000156],
              [20.308000565000043, 4.772999765000066],
              [20.318000794000113, 4.768000126000061],
              [20.343000413000084, 4.750999929000045],
              [20.357860564000134, 4.736951828000088],
              [20.36386299100019, 4.728463172999966],
              [20.37313079800009, 4.709174156000131],
              [20.384450913000137, 4.694499016000066],
              [20.40501213100015, 4.677013397000053],
              [20.430320740000127, 4.647534848000021],
              [20.447000504000187, 4.633999824000114],
              [20.45299911500007, 4.626999856000055],
              [20.46100044200017, 4.605999947000043],
              [20.46100044200017, 4.598999976000016],
              [20.451999665000187, 4.573999882000067],
              [20.44975662300004, 4.564067842000043],
              [20.450311661000114, 4.544967174000078],
              [20.45338249299999, 4.524953842000059],
              [20.45734024100011, 4.512084008000159],
              [20.461969375000137, 4.502899170000148],
              [20.477882386000033, 4.485478879000027],
              [20.489601135000044, 4.480701923000027],
              [20.500610351000034, 4.480524064000122],
              [20.508668899000156, 4.476206780000155],
              [20.532899856000142, 4.44246482900013],
              [20.53933334300018, 4.439733028000148],
              [20.555168152000192, 4.437736035000171],
              [20.5896968830001, 4.411194801000079],
              [20.606241226, 4.403446198000154],
              [20.612672805000102, 4.401634216000161],
              [20.624000550000062, 4.40199995],
              [20.63100051800012, 4.405000210000139],
              [20.637485505000143, 4.416366101000051],
              [20.656015396000043, 4.421731472000147],
              [20.663656234000086, 4.412417413000185],
              [20.667802810000126, 4.413630010000077],
              [20.698999405000052, 4.417000293000115],
              [20.727214814000035, 4.420891284999982],
              [20.735067367000056, 4.420608044000119],
              [20.746559143000184, 4.41513586100001],
              [20.757347107000157, 4.413341999000124],
              [20.78326988100008, 4.412536144000114],
              [20.794500350000135, 4.413967133000142],
              [20.81007576000013, 4.419330120000041],
              [20.8203735350001, 4.42467022000011],
              [20.825990678000096, 4.429017544000089],
              [20.85000801100017, 4.440988064000067],
              [20.85698318500016, 4.445547103000081],
              [20.865467071000126, 4.445813178000151],
              [20.884769440000184, 4.437611104000041],
              [20.910999299000025, 4.439000129000135],
              [20.923999785999968, 4.434999944000026],
              [20.934110642000064, 4.430225850000056],
              [20.946737289000055, 4.428206921000083],
              [20.953382492000173, 4.429615021000075],
              [20.969999314000177, 4.427999973],
              [20.978399277000165, 4.424195767000015],
              [20.988048553999988, 4.421704769000144],
              [21.00851631100005, 4.407977105000157],
              [21.024370194000028, 4.40205812500011],
              [21.03812980600003, 4.401195050000069],
              [21.04700088500016, 4.40199995],
              [21.075000764000038, 4.392000199000165],
              [21.084999085000163, 4.384999753000045],
              [21.08729171700014, 4.381368161000125],
              [21.101095199000042, 4.370374203000154],
              [21.11400032100005, 4.342999935000023],
              [21.118892670000093, 4.33614921700007],
              [21.15200042700019, 4.321000099000173],
              [21.172000884000113, 4.305999756000119],
              [21.174999237000122, 4.302000046000103],
              [21.18199920800015, 4.301000119000037],
              [21.191999436000117, 4.303999900000179],
              [21.201999665000187, 4.296000003000074],
              [21.205999375000033, 4.289999962000024],
              [21.211269379000157, 4.287000179000074],
              [21.218999862000032, 4.287000179000074],
              [21.228225709000185, 4.289619924000022],
              [21.23691940400016, 4.295405865000078],
              [21.241899490000094, 4.311540126000011],
              [21.250999451000098, 4.32899999700004],
              [21.256998062000036, 4.332005978000154],
              [21.269569396000122, 4.332825184000058],
              [21.278999328, 4.337999821000039],
              [21.289060592000112, 4.334508895000056],
              [21.300121308000087, 4.320512772000086],
              [21.313014984, 4.312010766000071],
              [21.31800079300018, 4.305999756000119],
              [21.327493668000045, 4.297826766000128],
              [21.334390641000027, 4.293938161000142],
              [21.351369857000122, 4.289626122000186],
              [21.377330781000182, 4.277523995000081],
              [21.399131775000114, 4.272540092999975],
              [21.417970658, 4.264091015000076],
              [21.427839280000114, 4.260674],
              [21.434259414000053, 4.260927200000083],
              [21.45097732500011, 4.265074254000183],
              [21.459999085000163, 4.269000053000184],
              [21.46863174400005, 4.268878937000125],
              [21.495008468000037, 4.258025169000177],
              [21.540929794000135, 4.248187065000138],
              [21.551940918000128, 4.247075080000059],
              [21.564785005000147, 4.247347833000106],
              [21.57691574100005, 4.254066945000091],
              [21.583549499000185, 4.259153844000082],
              [21.596807480000166, 4.272321224000109],
              [21.60700035100001, 4.276000023000108],
              [21.62400055, 4.284999847000108],
              [21.629999161000114, 4.289999962000024],
              [21.642000198, 4.302999973000112],
              [21.651000977000024, 4.309000015000095],
              [21.65999984700011, 4.309000015000095],
              [21.66779136700012, 4.304789067],
              [21.676767350000034, 4.29561090499999],
              [21.682960510000157, 4.29563379300015],
              [21.692350387000033, 4.299809934000052],
              [21.70404625000009, 4.297544003000098],
              [21.721086502000105, 4.303006171000106],
              [21.73452949599999, 4.303860188000158],
              [21.74440002400013, 4.299747944000103],
              [21.75199127200011, 4.293097019000186],
              [21.76098442100016, 4.277242184000102],
              [21.77178001400017, 4.270139218000054],
              [21.79402542200006, 4.27113294500009],
              [21.80458068900009, 4.270038129000113],
              [21.816755295000064, 4.261767864000149],
              [21.830064773000117, 4.258817196000109],
              [21.84891128600009, 4.245065213000089],
              [21.8592433930001, 4.240032195000026],
              [21.87499999900018, 4.240999698000053],
              [21.912000655000043, 4.233979226000031],
              [21.93540000900009, 4.232670785000039],
              [21.95468330300008, 4.223979950000114],
              [21.96501731900014, 4.225052356000049],
              [21.98216247500011, 4.236952781000127],
              [21.993000031000122, 4.240000249000048],
              [22.006999969000105, 4.238999844000148],
              [22.017950057, 4.230381966000152],
              [22.031276702000184, 4.221673965000036],
              [22.0438652040001, 4.221557616999974],
              [22.05879974400017, 4.220022678000078],
              [22.06890106200018, 4.214875221000057],
              [22.083999635000055, 4.214000225000177],
              [22.09499931300013, 4.211999894000087],
              [22.11899185200008, 4.195984841000097],
              [22.127000810000027, 4.191999912000142],
              [22.149000167999986, 4.168000222000046],
              [22.163967132000096, 4.154032229999984],
              [22.187000274000184, 4.143000126000061],
              [22.19820594900017, 4.135124207000104],
              [22.21908950800014, 4.126432897000029],
              [22.24018859900019, 4.124415875000125],
              [22.266351700000143, 4.11435890100006],
              [22.281259536000107, 4.112094880000029],
              [22.306015016000117, 4.115151883000124],
              [22.340000153000176, 4.113999844000091],
              [22.35942840600012, 4.118048192],
              [22.387401582000052, 4.118349076000072],
              [22.392000198000062, 4.12100029000004],
              [22.39817047200006, 4.121139049000078],
              [22.409393310000155, 4.125539780000111],
              [22.44499969600014, 4.124000073000161],
              [22.45588111900014, 4.127968312000121],
              [22.469882965000124, 4.143411160000142],
              [22.48521804800015, 4.152884961000041],
              [22.493690490000063, 4.159579755000095],
              [22.50146484400011, 4.17041778600003],
              [22.520450592000145, 4.189569951000124],
              [22.531677245000083, 4.195580005000181],
              [22.540603638000164, 4.203426838000098],
              [22.550441742000032, 4.21541976899999],
              [22.556098937000172, 4.234690190000094],
              [22.554689408000115, 4.23858976300005],
              [22.546140671000046, 4.24819994],
              [22.54445075900003, 4.261539936000133],
              [22.546209336000175, 4.270759105000082],
              [22.557973862000097, 4.294488430000115],
              [22.56218910199999, 4.298549175000119],
              [22.56772995000017, 4.316694260000134],
              [22.57118797400011, 4.324621677000152],
              [22.574960708000162, 4.328730107000069],
              [22.598329544000137, 4.341446877000124],
              [22.608629226000062, 4.348139763000063],
              [22.619409561, 4.350470066000014],
              [22.62191963200013, 4.358069897000121],
              [22.623500823000143, 4.372117042000184],
              [22.620019912000146, 4.377645969000184],
              [22.60168075600012, 4.382890225000097],
              [22.58996963400017, 4.389308930000141],
              [22.585590362000175, 4.399888993000047],
              [22.586727142000086, 4.406105995000019],
              [22.597927094000056, 4.424543859000039],
              [22.598344802000156, 4.445724011000152],
              [22.596700667000107, 4.46344709400006],
              [22.599210740000046, 4.47173977000017],
              [22.60264015300004, 4.474967957000047],
              [22.609750748000124, 4.475440026000115],
              [22.614330291000044, 4.480519772000093],
              [22.627159119000112, 4.484691143000021],
              [22.648040771000183, 4.481052877000082],
              [22.662729264000063, 4.48062515200013],
              [22.6691608430001, 4.472812176000105],
              [22.672157288000164, 4.466831208000144],
              [22.680194855000025, 4.461784839000131],
              [22.688219071000105, 4.461340905000157],
              [22.698980332000133, 4.471720219000076],
              [22.707199097000057, 4.491306782000038],
              [22.706468583000174, 4.515705108000077],
              [22.710580826000182, 4.523770809000155],
              [22.721563338000067, 4.538524150000058],
              [22.73026657100013, 4.546138764000034],
              [22.733688354000037, 4.556044101000055],
              [22.73427200400016, 4.594782353000028],
              [22.73568916300013, 4.596829892000073],
              [22.738979339000025, 4.619180202000166],
              [22.742595673000096, 4.62843275099999],
              [22.762409210000158, 4.650710107000123],
              [22.769039153000108, 4.66821813700011],
              [22.77842140200005, 4.682507992000183],
              [22.781379699000126, 4.69033908900002],
              [22.783353806000093, 4.704448223000043],
              [22.7905101770001, 4.714529992000109],
              [22.801969528000086, 4.722605227999964],
              [22.809080124000104, 4.725150108000037],
              [22.821929931000057, 4.724255086000085],
              [22.828590393000127, 4.721275806000108],
              [22.835945130000027, 4.714153767000141],
              [22.845605851000073, 4.701744081000072],
              [22.85753631500006, 4.701303960000189],
              [22.865789414000176, 4.707769871000039],
              [22.870134353000083, 4.713068962000023],
              [22.874477386000137, 4.722514152000088],
              [22.875150680000104, 4.735620976000064],
              [22.876560211000083, 4.739140511000073],
              [22.900810242000034, 4.753407954000068],
              [22.90263938800007, 4.757092952000164],
              [22.901250838000124, 4.763306141000044],
              [22.888612748000185, 4.77502107700019],
              [22.88767814700003, 4.784455777000119],
              [22.89269638000019, 4.802189827000177],
              [22.89521217300006, 4.807028772000024],
              [22.906669617000034, 4.8178658490001],
              [22.911481858000172, 4.819255830000088],
              [22.922500611000146, 4.81812620200003],
              [22.935359954000035, 4.814469815000109],
              [22.941320419000192, 4.815398216000119],
              [22.954610824000156, 4.82636022500003],
              [22.96341133100009, 4.836809635000066],
              [22.967210769000076, 4.837769985000136],
              [22.974784851000038, 4.846968651000054],
              [22.979272842000057, 4.842777252000076],
              [22.988187790000097, 4.824585437000053],
              [22.996431351000183, 4.810245037000129],
              [23.014696122000146, 4.788222790000134],
              [23.03226471, 4.765848160000132],
              [23.04887390100015, 4.757132531000138],
              [23.063077926000062, 4.752264500000138],
              [23.079982758000085, 4.735141753000107],
              [23.09172439600013, 4.725012303000085],
              [23.113351822000084, 4.707963944000028],
              [23.122976303000144, 4.715345860000127],
              [23.142925263000166, 4.724352837000083],
              [23.151409150000177, 4.72942972300001],
              [23.159429551000073, 4.73612022400016],
              [23.17159080600004, 4.738210202000062],
              [23.183067321000067, 4.732010841999966],
              [23.19547271800002, 4.720058919000053],
              [23.208360672000026, 4.692226887000118],
              [23.225351333000162, 4.681663037000021],
              [23.23822021600006, 4.668332100000043],
              [23.258178711000085, 4.666748048000045],
              [23.267597198000146, 4.658013821000111],
              [23.271047592000173, 4.650652886000103],
              [23.275201798000182, 4.630400179999981],
              [23.280490874000122, 4.625576019000107],
              [23.288740158000167, 4.628348827000025],
              [23.29493331800012, 4.627435208000179],
              [23.305725097000163, 4.620084762000033],
              [23.319278716999975, 4.606751919000089],
              [23.331216811000104, 4.60216379200017],
              [23.34656715300008, 4.608949185000142],
              [23.360322952000047, 4.607923984000138],
              [23.371360779000042, 4.602447033000033],
              [23.377107621000164, 4.590943814000127],
              [23.382617950000167, 4.588879109000118],
              [23.40807914800007, 4.590062142000022],
              [23.420932770000093, 4.588926792000052],
              [23.42711639500004, 4.591926097000112],
              [23.429174424000053, 4.600905895999972],
              [23.425010681000117, 4.632205962],
              [23.425914764000026, 4.644638062000126],
              [23.430955887000152, 4.650166989000127],
              [23.449529648000123, 4.661469936000174],
              [23.45731926000019, 4.669529916000045],
              [23.464889527000082, 4.67346000800012],
              [23.496549606000144, 4.672569751000083],
              [23.50068092400005, 4.673269749000099],
              [23.526359558000024, 4.691249847000108],
              [23.53989028899997, 4.696096896000142],
              [23.55135536200015, 4.703475952000133],
              [23.561210633000144, 4.714765071000102],
              [23.57564926200007, 4.728590011000165],
              [23.58206939600018, 4.732971192000036],
              [23.590101242000173, 4.735511780000081],
              [23.608686448000128, 4.73690891300015],
              [23.619928360000188, 4.740602969000065],
              [23.666948318000095, 4.759290220000025],
              [23.67750167800017, 4.763903141000071],
              [23.70915031300018, 4.782345773000145],
              [23.723604203000093, 4.785120010000071],
              [23.728883743000097, 4.784893990000057],
              [23.758720398000094, 4.778704167000115],
              [23.770650863000014, 4.779633998],
              [23.780282974000045, 4.783554077000076],
              [23.806419373000097, 4.811200142000018],
              [23.81583023000013, 4.819029809000142],
              [23.833490372000085, 4.82940006299998],
              [23.842670440000063, 4.823650838000106],
              [23.851171493000095, 4.810767174000091],
              [23.856218337000087, 4.810770989000162],
              [23.87021446300008, 4.816533090000064],
              [23.873420716000112, 4.821829796000088],
              [23.88466072000017, 4.831044197000153],
              [23.89223098700012, 4.832660197999985],
              [23.90715026900017, 4.828755855000168],
              [23.91610717800006, 4.817022800000075],
              [23.930107117000148, 4.811967851000077],
              [23.949842452000155, 4.80944681200009],
              [23.95167923000008, 4.810828210000182],
              [23.955350875000192, 4.822569847000125],
              [23.953731537000124, 4.837528228999986],
              [23.949136734000035, 4.852258206000101],
              [23.950736999000185, 4.866759778000017],
              [23.953487395000025, 4.871825218000083],
              [23.959909439000057, 4.877120971000124],
              [23.96679687400018, 4.876666070000056],
              [23.96978187600007, 4.871373177000123],
              [23.9711608880001, 4.858025074000125],
              [23.976209640000036, 4.856184960000121],
              [23.984931946000188, 4.861023902000113],
              [23.99204635700005, 4.859877111000174],
              [24.008268357000077, 4.869492054000148],
              [24.018169404000105, 4.873670102000176],
              [24.031629562000035, 4.874966623000034],
              [24.03885650600006, 4.877161981000143],
              [24.048721313000158, 4.882689000000141],
              [24.06501007100013, 4.897658826000111],
              [24.074647903000027, 4.903416156000048],
              [24.090251922000107, 4.910327912000128],
              [24.105381013, 4.913465977000158],
              [24.11662674000013, 4.912600994000059],
              [24.131374358000187, 4.900017262000176],
              [24.136205672000074, 4.897509099000047],
              [24.15961074900008, 4.896243095000102],
              [24.168050767000125, 4.901147842000114],
              [24.170806884, 4.906672954000044],
              [24.191089629000032, 4.930727006000041],
              [24.196819305000133, 4.935322286000144],
              [24.229295730000104, 4.956654073000038],
              [24.236320496000076, 4.959544181000069],
              [24.24805069000007, 4.958812237000075],
              [24.253391266000165, 4.95199537200017],
              [24.25047683700012, 4.940402031000076],
              [24.25245285000011, 4.93492317200014],
              [24.25871086000012, 4.934559823000029],
              [24.275110244000018, 4.936875344000043],
              [24.280069351000122, 4.93923902500012],
              [24.28367042499997, 4.949955940000166],
              [24.28234672500008, 4.958325864000074],
              [24.273275375000026, 4.972286225000119],
              [24.274152756000035, 4.980575084000179],
              [24.283130645000085, 4.986013889000105],
              [24.298320771000135, 5.002933979000147],
              [24.31922912600004, 5.007027149000066],
              [24.325679778000165, 5.003839017000075],
              [24.334287644000028, 5.00309181200015],
              [24.34912109400011, 5.00981998400016],
              [24.362035752000168, 5.007524014000069],
              [24.37532425000012, 5.008365154000046],
              [24.38079071100003, 5.010739803000149],
              [24.3918800350001, 5.022600173],
              [24.406141281000032, 5.027311802000043],
              [24.41082000800003, 5.031252861000098],
              [24.412359238000192, 5.03635597300007],
              [24.40920066900003, 5.044571878000113],
              [24.398220062, 5.053537846000154],
              [24.390146256000094, 5.053157807000105],
              [24.374343871000065, 5.045949935000181],
              [24.368850709000128, 5.046181678000096],
              [24.363086700999986, 5.049348832000135],
              [24.357299805000082, 5.059909821000076],
              [24.360933303000024, 5.06798124300019],
              [24.37217140199999, 5.071221352000123],
              [24.376050949000103, 5.073804855000105],
              [24.379732131000026, 5.081677914000068],
              [24.382017136000172, 5.096581935000131],
              [24.389656066000043, 5.10884905000006],
              [24.39581871000013, 5.112595081000109],
              [24.40294837900018, 5.112823964000143],
              [24.40729904300008, 5.110554695000189],
              [24.42985916200007, 5.063469886],
              [24.43417167700011, 5.059178830000064],
              [24.44393921000011, 5.060396194000077],
              [24.447431564000055, 5.064334869000106],
              [24.447799683000028, 5.074522019000085],
              [24.437570572000027, 5.09015703200015],
              [24.439113616000157, 5.097218037],
              [24.454391480000027, 5.104545116000111],
              [24.467243194000048, 5.104602814000089],
              [24.475070954000046, 5.098885060000043],
              [24.485626221000075, 5.09939384400019],
              [24.494356155000105, 5.092774869000095],
              [24.534362792000138, 5.080509187000075],
              [24.53919982900004, 5.07593012000018],
              [24.543844224000168, 5.064443112000106],
              [24.54503059400008, 5.055472851000161],
              [24.548271179000096, 5.049040795000167],
              [24.565311432000044, 5.036230087000149],
              [24.59194946300005, 5.031515121000098],
              [24.610536576000072, 5.032056809000153],
              [24.617677689, 5.02495288900019],
              [24.62254333400017, 5.014156818000117],
              [24.62988090500005, 4.982205868000108],
              [24.632999419000157, 4.974999905000118],
              [24.651998519000074, 4.973999978000052],
              [24.659677506000094, 4.970820904000107],
              [24.672306061000086, 4.968574047000061],
              [24.67599868700006, 4.960000038000032],
              [24.667999267000027, 4.937000275000116],
              [24.658025742000063, 4.928465842000037],
              [24.658065796000187, 4.919487954000033],
              [24.668170930000144, 4.916310788000089],
              [24.676420211000107, 4.919338227000026],
              [24.693162918000098, 4.921020031000069],
              [24.705539703, 4.923604012000112],
              [24.72090911900017, 4.923901081000054],
              [24.725051880000024, 4.921617031000096],
              [24.729692460000138, 4.909667969000111],
              [24.734752655000023, 4.906237126000065],
              [24.748960495000176, 4.909519197000066],
              [24.75975036600005, 4.908874034000064],
              [24.764099122000175, 4.911654949000024],
              [24.77208137400015, 4.92273521400017],
              [24.77735710100012, 4.923216819000118],
              [24.781051637000132, 4.917249204000086],
              [24.779012681000154, 4.91102504700018],
              [24.784543991000078, 4.905525207000039],
              [24.791652679000094, 4.906937123000148],
              [24.799200057000178, 4.911109924000186],
              [24.804460526000128, 4.916423797999983],
              [24.811283111000023, 4.930262089000053],
              [24.818140030000052, 4.93696498800017],
              [24.82294845500013, 4.939287187000161],
              [24.842222213000184, 4.939135075000024],
              [24.873207093000076, 4.936500074000037],
              [24.878480912000043, 4.937671184000124],
              [24.883968354000046, 4.942297935000056],
              [24.888490676000117, 4.957964897000124],
              [24.89077377300015, 4.961657048000177],
              [24.919218064000063, 4.963382244000115],
              [24.928403854000123, 4.960657121000168],
              [24.933681489000094, 4.961138248000054],
              [24.939403534000064, 4.964612961000057],
              [24.944423675000053, 4.971076966000112],
              [24.94942855900007, 4.982834816999969],
              [24.953313826999988, 4.986302853000041],
              [24.98335075400007, 4.993094920999965],
              [25.00032615700013, 4.993161201000135],
              [24.99999809400009, 5.000000954000029],
              [25.136428832000036, 5.000000954000029],
              [25.14756774900019, 5.011443138000118],
              [25.149133682000183, 5.022265910000158],
              [25.154159545000084, 5.028267861000188],
              [25.16058921900003, 5.028292180000165],
              [25.16656875700005, 5.022790910000083],
              [25.167285920000154, 5.01565790300009],
              [25.17994689900013, 5.004887103000101],
              [25.183851242000173, 5.003292084],
              [25.19440078800011, 5.005863189000024],
              [25.197582245000035, 5.013699054000085],
              [25.201698303000057, 5.017395019000105],
              [25.209499359000176, 5.017654896000011],
              [25.223510742000144, 5.01402187400015],
              [25.231311798000092, 5.014282226000034],
              [25.247566222000103, 5.024696827000128],
              [25.25925636300019, 5.027501106000045],
              [25.28449058500013, 5.029662132000169],
              [25.317970276000153, 5.036222933999966],
              [25.32735443100006, 5.043160917000023],
              [25.33053970300017, 5.051917077000098],
              [25.327703475000135, 5.074230195000098],
              [25.329511642000114, 5.081831932000171],
              [25.335899354000162, 5.092441081000175],
              [25.348699569000075, 5.106984138000087],
              [25.351200104000156, 5.114126205000105],
              [25.352283477000185, 5.132311822000133],
              [25.355207444000087, 5.149122239000064],
              [25.35266685500011, 5.153716087000021],
              [25.34119987500003, 5.150222778999989],
              [25.33706855900016, 5.151131154000098],
              [25.332231522000086, 5.156406881000066],
              [25.324361801000066, 5.17548084200007],
              [25.324106216000132, 5.182613849000063],
              [25.320650102000172, 5.187435150000113],
              [25.32199669000005, 5.19503402800018],
              [25.332750321000105, 5.204044818],
              [25.33594322100015, 5.21049976300003],
              [25.34402847300015, 5.260465145000069],
              [25.347898484000154, 5.269683839000095],
              [25.357250214000146, 5.28628778500007],
              [25.371026993000044, 5.285183430000075],
              [25.380411147000075, 5.293038846000059],
              [25.384727477000183, 5.304331780000098],
              [25.381509781000034, 5.308002949000183],
              [25.373489380000137, 5.304522991000169],
              [25.36958122200008, 5.304969788000108],
              [25.366807937000033, 5.310253143000182],
              [25.367708207000078, 5.31554794300007],
              [25.376182557000163, 5.320870877000118],
              [25.39179039000004, 5.320693969000047],
              [25.398197173000142, 5.326698781000061],
              [25.40022277800017, 5.340510845000154],
              [25.404109955000024, 5.342597961000138],
              [25.408487320000063, 5.33962011400007],
              [25.409900664000133, 5.327427865000175],
              [25.41497612000012, 5.321003914000187],
              [25.41980361800006, 5.318947792000074],
              [25.429208756000037, 5.320590972000161],
              [25.445899963000045, 5.340665818000048],
              [25.460329055000045, 5.349000931000035],
              [25.471820831000173, 5.346507074000044],
              [25.48052024800006, 5.351827145000129],
              [25.482780457000104, 5.362649918000045],
              [25.486900330000026, 5.365883827],
              [25.497467040000174, 5.364079000000174],
              [25.502077103000147, 5.358801842000162],
              [25.506708145000175, 5.347311974000036],
              [25.51131248400003, 5.343875408000088],
              [25.52803993200007, 5.352901936000137],
              [25.54754447900018, 5.354577065000115],
              [25.550289155000087, 5.358266830000105],
              [25.546825408000075, 5.363779068000042],
              [25.536699296000165, 5.37180089900005],
              [25.53531074599999, 5.376629830000184],
              [25.53689384500018, 5.382847787000117],
              [25.546062470000038, 5.386097909000114],
              [25.55504608100017, 5.376462937000156],
              [25.56032943800011, 5.375329017000126],
              [25.57364082400011, 5.376751901000034],
              [25.581451416000164, 5.375165939000112],
              [25.587900162000096, 5.369895935000159],
              [25.58655357400005, 5.35884380400006],
              [25.587715149000132, 5.354705811000088],
              [25.597156524000184, 5.345530988000121],
              [25.603580475000115, 5.346932889000186],
              [25.611621857000046, 5.346268178000173],
              [25.622631073000036, 5.347452163000185],
              [25.626779557000077, 5.341482163000023],
              [25.624500275000173, 5.337793828000145],
              [25.612346648000084, 5.334074021000106],
              [25.60181999200006, 5.322762966000084],
              [25.603437423, 5.320238113000187],
              [25.623859405000076, 5.321453095000152],
              [25.627317429000186, 5.317552090000049],
              [25.62804031400003, 5.306969166000158],
              [25.63448524500012, 5.301696777000132],
              [25.639989853000145, 5.302864074000127],
              [25.642040252000072, 5.307241917000113],
              [25.63720131000008, 5.313670158000036],
              [25.640617370000086, 5.322885990000145],
              [25.644960404000074, 5.327041149000138],
              [25.65873146100006, 5.328004837000094],
              [25.662889481000036, 5.3215727810001],
              [25.66086769200018, 5.306149005000066],
              [25.664999008000052, 5.306393147000051],
              [25.66980362000004, 5.311240196000085],
              [25.674390793000043, 5.312173843000153],
              [25.679691314000138, 5.305286885000157],
              [25.686349868000036, 5.304846764000047],
              [25.691410065000127, 5.301411153000117],
              [25.70427131600013, 5.299378873000023],
              [25.712556839000115, 5.292039872000032],
              [25.71397399800003, 5.27961778700012],
              [25.71903610200019, 5.274799824000183],
              [25.720209122000085, 5.266980172000103],
              [25.728479385000185, 5.263553142000035],
              [25.73582077100008, 5.265875816000062],
              [25.745967865000182, 5.251408100000049],
              [25.749679566000054, 5.23784017700018],
              [25.770782470000142, 5.242047787000161],
              [25.775356292000026, 5.247583866000184],
              [25.783378600000162, 5.2515192030001],
              [25.78588867200017, 5.256359100000054],
              [25.782199860000162, 5.260951042000045],
              [25.772108077000098, 5.259540081000125],
              [25.770029067000053, 5.263677120000182],
              [25.787670136000145, 5.27339506300018],
              [25.79249954200003, 5.272717953000097],
              [25.798954010000102, 5.26260996000002],
              [25.800815582000155, 5.253180981000128],
              [25.798992157000157, 5.248573303000057],
              [25.790525437000042, 5.240263940000091],
              [25.796276092000085, 5.236827850000168],
              [25.809797288000084, 5.244001866000133],
              [25.81736183200013, 5.246325017000061],
              [25.820817947000023, 5.242884157999981],
              [25.817850113000134, 5.238273144000061],
              [25.816953658000102, 5.229755878000162],
              [25.808492660000127, 5.219375134000131],
              [25.811489105000135, 5.213631153000165],
              [25.820659637000063, 5.217109203000177],
              [25.821838380000145, 5.206296921000103],
              [25.824850083000058, 5.199631214000078],
              [25.831281662000094, 5.19550705000006],
              [25.83199119600016, 5.189525128000184],
              [25.835897446000104, 5.186776161000182],
              [25.839099884000177, 5.192997933000186],
              [25.8429908760001, 5.195311069000127],
              [25.845720291000077, 5.202452183000162],
              [25.84707260200014, 5.211892128000045],
              [25.861968995000154, 5.219758034000051],
              [25.8837757120001, 5.218669891000161],
              [25.887231827000164, 5.213615894000043],
              [25.880140304, 5.206003190000047],
              [25.890031814999986, 5.199124812999969],
              [25.89599990800008, 5.197991847000026],
              [25.903169631000083, 5.180292130000055],
              [25.902730943, 5.170626164000055],
              [25.905040741000164, 5.165108203999978],
              [25.908487320000177, 5.164886951000085],
              [25.916049957000155, 5.17020178000007],
              [25.91740989600015, 5.174808026000164],
              [25.916698455000187, 5.183321],
              [25.90955925000003, 5.191586972000096],
              [25.90953063900008, 5.20032978100005],
              [25.91780090400016, 5.1978230470001],
              [25.926527023000176, 5.197154997999974],
              [25.937749864000182, 5.20547199300006],
              [25.942337036000083, 5.206636907000188],
              [25.950145722000116, 5.204585076000114],
              [25.959131241000136, 5.192183017000104],
              [25.96624183600005, 5.194501876],
              [25.961170196000126, 5.203924180000172],
              [25.966619492000177, 5.223042011000075],
              [25.977144242000065, 5.235267163000117],
              [25.98516655000003, 5.239661217000048],
              [25.989339829000073, 5.223792076000052],
              [25.992565155000136, 5.220119954000154],
              [26.001029969000115, 5.229805948000035],
              [26.009296417000144, 5.228447914000128],
              [26.01160812400019, 5.222241878000034],
              [26.008651734000182, 5.212797166000144],
              [26.01095771700011, 5.208200933000114],
              [26.01647377000012, 5.205684185000052],
              [26.02771377500011, 5.206864833000054],
              [26.03828430200008, 5.202290058000131],
              [26.041498185000023, 5.202989101000185],
              [26.04652977100011, 5.210825921000037],
              [26.05363273600011, 5.213377953000133],
              [26.053888321000045, 5.202101230000153],
              [26.063102723000043, 5.194300174000034],
              [26.067914963000135, 5.194083214000045],
              [26.070430756000178, 5.198463917000026],
              [26.071094512, 5.207438947000128],
              [26.076818466000077, 5.213206768000134],
              [26.08645629900019, 5.213462829999969],
              [26.092670441000053, 5.206573009000067],
              [26.09955215500014, 5.208202363000055],
              [26.097482681000145, 5.21401596100003],
              [26.101350785000136, 5.22224521600009],
              [26.10088348300019, 5.22523403100007],
              [26.09053230300009, 5.233493806000183],
              [26.092819213000155, 5.237872123000159],
              [26.103147507000074, 5.238820075000092],
              [26.107740402000047, 5.236989975000029],
              [26.112117767000143, 5.231246948000091],
              [26.120145798000124, 5.231956959000115],
              [26.122650146000183, 5.240249157000051],
              [26.128610610000067, 5.243715762000079],
              [26.130659103000028, 5.250165939000056],
              [26.13500595200003, 5.255008221000139],
              [26.1343002320001, 5.262139798000021],
              [26.140951156000085, 5.263076782000098],
              [26.146703720000176, 5.257798195000021],
              [26.16093254200007, 5.258523941000078],
              [26.163463593000188, 5.255999088000124],
              [26.157773971000154, 5.23757505399999],
              [26.160079957000164, 5.232058048000056],
              [26.16422081100012, 5.228847028000132],
              [26.169042587000092, 5.229087829000036],
              [26.174310684000147, 5.23393392600002],
              [26.180042267000033, 5.235788823000121],
              [26.186910629000067, 5.241559983000116],
              [26.187850953000066, 5.233508111000049],
              [26.19153976399997, 5.227070809000168],
              [26.184440613000163, 5.219921112000179],
              [26.190650940000182, 5.217636108000136],
              [26.196830749000185, 5.22179317500013],
              [26.200239181000086, 5.234228135000137],
              [26.208040237000034, 5.237008095000078],
              [26.211265563000097, 5.231953144000102],
              [26.211748123000064, 5.221828937000055],
              [26.218425751000098, 5.214249134000056],
              [26.2097148900001, 5.208476066],
              [26.215925216000073, 5.204117774],
              [26.221227645000113, 5.194007873000089],
              [26.22326469500007, 5.206896782000115],
              [26.23748970000014, 5.208543777000045],
              [26.239564896000104, 5.20555591599998],
              [26.23223686300014, 5.198174000000108],
              [26.236608505000163, 5.19266081000012],
              [26.238233567000123, 5.185300827000162],
              [26.243288041000028, 5.18347310900009],
              [26.245784760000106, 5.195675373000029],
              [26.253808974000037, 5.197995187000117],
              [26.25428390400009, 5.191092014000162],
              [26.260494231999985, 5.185813904000099],
              [26.25386619600016, 5.174982071000102],
              [26.257089615000154, 5.169926644000043],
              [26.282560349000107, 5.170907020000186],
              [26.287397385000133, 5.165392876999988],
              [26.282131196000023, 5.159860134999974],
              [26.283060075000094, 5.155950070000017],
              [26.295211792000032, 5.160809995000079],
              [26.297292709000146, 5.154370785000026],
              [26.301198960000136, 5.15138816800004],
              [26.301681518000123, 5.141724110000155],
              [26.306503296000074, 5.140355586000112],
              [26.311550140000065, 5.144279003000065],
              [26.31266975500006, 5.15256595600016],
              [26.317962646000183, 5.151659011000163],
              [26.31888961900006, 5.142683983000097],
              [26.324415207000186, 5.136023046000105],
              [26.325771331000112, 5.14523220000018],
              [26.333337783000047, 5.149621963000016],
              [26.342060089000142, 5.146880150000129],
              [26.351219176000086, 5.15541505900012],
              [26.361112595000066, 5.14461994100003],
              [26.366397857000038, 5.142790795000053],
              [26.37351036100017, 5.143497944000046],
              [26.379930496000156, 5.148115158000167],
              [26.390939712000147, 5.147447109000041],
              [26.393718720000038, 5.136638165000079],
              [26.390747071000135, 5.132258892000039],
              [26.397182465000185, 5.129049779000184],
              [26.401786803000164, 5.122615815000188],
              [26.40543556200015, 5.132750988000055],
              [26.41415405300006, 5.132999897000161],
              [26.41187858600017, 5.123559952000051],
              [26.41807937700014, 5.12081289300005],
              [26.424064637000072, 5.112999917000025],
              [26.433710099000052, 5.110949516000119],
              [26.44104957600007, 5.113957882000136],
              [26.442901612000185, 5.10567522100007],
              [26.43879127499997, 5.095541001000186],
              [26.444309236000095, 5.090491772000121],
              [26.45095062200005, 5.096258165000165],
              [26.45899009600015, 5.095122814000149],
              [26.45694351300017, 5.086372852000181],
              [26.461778642000127, 5.077178001000107],
              [26.452838898000095, 5.073016643000074],
              [26.455839156000025, 5.065658092999968],
              [26.459970474999977, 5.064056873000027],
              [26.463657380000143, 5.05854129800008],
              [26.47052955700002, 5.06315994400012],
              [26.47857284600002, 5.058573724000098],
              [26.485912323000036, 5.058817863000172],
              [26.48317337000003, 5.052368163000097],
              [26.488008499000102, 5.046394826000153],
              [26.492824554000094, 5.048476219000122],
              [26.494873047, 5.055384159000084],
              [26.502904892000117, 5.053790092000099],
              [26.50383567800003, 5.047808170000053],
              [26.509122848000175, 5.043907642000079],
              [26.515306473000123, 5.05036306400001],
              [26.518989563000048, 5.045308113000033],
              [26.526790618000064, 5.044173717000149],
              [26.533422469000186, 5.056844235000085],
              [26.531803131000117, 5.0630540840001],
              [26.533859252000184, 5.06720113800003],
              [26.54166031000011, 5.067907811000168],
              [26.54374504000009, 5.057324886000174],
              [26.53848457400005, 5.049948215000086],
              [26.544219970000142, 5.049040795000167],
              [26.548795700000028, 5.05710506500003],
              [26.554531098000098, 5.058496953000088],
              [26.55039024400014, 5.064013004],
              [26.554277421000165, 5.067933083000128],
              [26.55563926700006, 5.075070857000071],
              [26.559766769000134, 5.076458932000037],
              [26.560934066000186, 5.06679391900019],
              [26.564386367999987, 5.063580036000076],
              [26.57448005800012, 5.063370229000043],
              [26.57171058600011, 5.073030949000042],
              [26.5751380910001, 5.08086204600005],
              [26.583402634000095, 5.077657223000188],
              [26.585229874000106, 5.082032203000154],
              [26.589588166000112, 5.082500935000041],
              [26.592357636000145, 5.074681758999986],
              [26.599470139000175, 5.075386047],
              [26.601985932000048, 5.081604958000071],
              [26.619413376000182, 5.08808279200008],
              [26.614604951000103, 5.08024788],
              [26.62149620100007, 5.078879833000087],
              [26.621284484000057, 5.067833900000153],
              [26.628870011, 5.063476086000037],
              [26.63344955400015, 5.069005966000134],
              [26.627460480000025, 5.080272198000102],
              [26.63089752200017, 5.08281278700008],
              [26.638240813000152, 5.081444741000041],
              [26.64098358200016, 5.088583945999972],
              [26.645580292000034, 5.084911346000069],
              [26.65039825500014, 5.085609913000042],
              [26.65546417300004, 5.076182842000151],
              [26.66969108699999, 5.077360154000189],
              [26.681152344, 5.084055901000056],
              [26.682979584, 5.087511063000136],
              [26.68136024400019, 5.096255780000092],
              [26.68893051100008, 5.096958160000099],
              [26.687789917000032, 5.090972900000111],
              [26.690553665000095, 5.087525368000172],
              [26.69720649700008, 5.08822584100011],
              [26.709360123000067, 5.093544007000162],
              [26.710948945000098, 5.102520943],
              [26.717840195000065, 5.099542141000143],
              [26.721525193000105, 5.091724873000089],
              [26.725181580000083, 5.100014211000087],
              [26.732534409000095, 5.094736576000116],
              [26.74078369099999, 5.101882936000095],
              [26.74995613100009, 5.103510380000103],
              [26.753871917000083, 5.097306252000124],
              [26.757768631999966, 5.100533963000089],
              [26.766492843000037, 5.098708152000143],
              [26.77337455800017, 5.100330831000179],
              [26.771320344, 5.093653203000145],
              [26.77133178700018, 5.085597993000135],
              [26.781433106000122, 5.083544732000121],
              [26.786479950000057, 5.086083888000076],
              [26.78901290800019, 5.081484795000165],
              [26.78374099600012, 5.075492859000121],
              [26.793153762000145, 5.07251691800019],
              [26.792245865000098, 5.067223072000047],
              [26.797769546000154, 5.058485985000061],
              [26.803735733000053, 5.058725834000086],
              [26.80811119100008, 5.049067973999968],
              [26.821199417000116, 5.047709942000097],
              [26.825782775999983, 5.049788952000142],
              [26.829210282000076, 5.058769226000095],
              [26.845964432000073, 5.054654123000091],
              [26.8501091010001, 5.051208974000019],
              [26.848276138000188, 5.044991970000069],
              [26.85631752000012, 5.039021015000117],
              [26.872159957000065, 5.033754826000063],
              [26.875595093000072, 5.039973260000124],
              [26.884990693000134, 5.045513154000048],
              [26.88429260300012, 5.053336144000184],
              [26.89139175300005, 5.063012124000181],
              [26.88862991400009, 5.068071842000109],
              [26.891138076000118, 5.073600768000176],
              [26.901012421000075, 5.072234631000015],
              [26.90537071300008, 5.073390961000143],
              [26.912244798000188, 5.080076219000148],
              [26.912239075000116, 5.088375568000174],
              [26.927560806000145, 5.117842674000031],
              [26.936048508000056, 5.121307851000154],
              [26.932134628000085, 5.127745628000184],
              [26.935108184000057, 5.13419437500005],
              [26.947952271000077, 5.138586044000078],
              [26.95505714400008, 5.144810200000052],
              [26.96675491200017, 5.148739814000123],
              [26.979589461000103, 5.157503127000041],
              [26.985330582000074, 5.15889597000006],
              [27.001152039000033, 5.166971206000142],
              [27.01880073600006, 5.181035042000076],
              [27.029123307000134, 5.187030793000133],
              [27.050916671000152, 5.193278788999976],
              [27.066740035000066, 5.200892925000119],
              [27.078672409000035, 5.204131127000153],
              [27.112178802000074, 5.206707001000098],
              [27.142936706000057, 5.204216004000159],
              [27.157398223000143, 5.199631691000036],
              [27.181743621000066, 5.184932710000169],
              [27.195999145000087, 5.172063827000045],
              [27.231821060000073, 5.15231323200004],
              [27.257532120000064, 5.143138885999974],
              [27.268554687000176, 5.141079903000048],
              [27.290588379000155, 5.135120869000161],
              [27.30620765600014, 5.12846517600002],
              [27.31814384500018, 5.12640381000017],
              [27.324337006000178, 5.122960091000095],
              [27.333066940000037, 5.114683628000137],
              [27.340650558000107, 5.110777855000038],
              [27.360845566000023, 5.105277062000084],
              [27.373472213000184, 5.100226879000104],
              [27.39413070600017, 5.096104623000088],
              [27.414569854000035, 5.087839127999985],
              [27.43155479500001, 5.077728749000073],
              [27.438222885000187, 5.070831776000034],
              [27.442590714000175, 5.063469886],
              [27.446266174000186, 5.052886963000049],
              [27.44650650000017, 5.047594070000059],
              [27.442150116000164, 5.03769397799999],
              [27.441083909000156, 5.021951198000068],
              [27.44474983300006, 5.017531871000074],
              [27.452085495, 5.01520299900011],
              [27.46342086800007, 5.016152858999988],
              [27.456729889000087, 5.027844906000098],
              [27.45469284100011, 5.035906792000105],
              [27.456640243000095, 5.06466913200012],
              [27.455289840000034, 5.071809769000026],
              [27.44798088000016, 5.081504821000181],
              [27.43285942100016, 5.088696003000109],
              [27.40587997400013, 5.11894512300006],
              [27.40160942099999, 5.143126011000106],
              [27.39660072400011, 5.152579784000068],
              [27.3599605550001, 5.176879883000083],
              [27.356550217000176, 5.187939168000071],
              [27.35014724800004, 5.193483831000094],
              [27.323770524000054, 5.19933414500008],
              [27.31599044700016, 5.207876205000048],
              [27.30091285800006, 5.228180886000075],
              [27.29821014400011, 5.241541863000066],
              [27.297779084000126, 5.250749112000051],
              [27.292778015000124, 5.263652801000035],
              [27.282470704000048, 5.270594119000066],
              [27.275150298000085, 5.278444767000053],
              [27.261249542000144, 5.308409214000108],
              [27.248920441999985, 5.326862813000105],
              [27.245782852000104, 5.347126961000072],
              [27.257070541000132, 5.356984140000066],
              [27.257312775000116, 5.361355782000089],
              [27.24770927500009, 5.373586178000039],
              [27.239929200000176, 5.380977154000163],
              [27.23406028800008, 5.410223007000127],
              [27.233200073000035, 5.426796914000022],
              [27.238340377999975, 5.449327947000029],
              [27.25176048200018, 5.479197979000048],
              [27.258520127000168, 5.506786824000074],
              [27.276519775000168, 5.530196189000094],
              [27.278837204000126, 5.537784100000067],
              [27.277282716000116, 5.550213814000188],
              [27.27182960500005, 5.566340923000098],
              [27.274829864000026, 5.571852208000166],
              [27.283817292000094, 5.581026078000036],
              [27.283390044999976, 5.58701086100001],
              [27.26894950800005, 5.597185135000018],
              [27.259550095000122, 5.600441933000013],
              [27.255180360000168, 5.599764824000033],
              [27.245510102000082, 5.592205047000107],
              [27.24157905500016, 5.586236000000156],
              [27.23790931800005, 5.588778974000036],
              [27.236360551000075, 5.604432105000115],
              [27.230409621999968, 5.61066579900006],
              [27.23526954700003, 5.619162083000163],
              [27.23759079100006, 5.627204895000034],
              [27.23718833900017, 5.643313885000055],
              [27.228040696000107, 5.654850960000147],
              [27.216821670000172, 5.663634777000084],
              [27.212709427000163, 5.670092107000073],
              [27.2182598120001, 5.682038784000099],
              [27.217590331000054, 5.687791824000044],
              [27.2079906460001, 5.701631070000076],
              [27.197929381999984, 5.712936879000154],
              [27.18832969700003, 5.727468968000039],
              [27.159200668000153, 5.740681172000052],
              [27.150709152000104, 5.743930817000091],
              [27.144992829000046, 5.749241828000152],
              [27.14637947200015, 5.754299164999964],
              [27.157411576000072, 5.755641938000167],
              [27.163869858999988, 5.761603832000162],
              [27.16043853800005, 5.767364980000025],
              [27.153097152000157, 5.771763801000134],
              [27.134489060000078, 5.770905018],
              [27.130380630000104, 5.777589799000054],
              [27.12002944900013, 5.7753238680001],
              [27.11890029900013, 5.779469967000182],
              [27.119169234000083, 5.792813777999982],
              [27.107671738000022, 5.786641121000059],
              [27.105869293000012, 5.799758911000083],
              [27.091400146000183, 5.800267219000034],
              [27.080129623000175, 5.797082900000078],
              [27.07205009400019, 5.786296844999981],
              [27.061742783000113, 5.794382096000106],
              [27.05691909700016, 5.794397832000129],
              [27.050928116000136, 5.790275096000073],
              [27.046577453000168, 5.794890880000082],
              [27.047779084000183, 5.80846119000006],
              [27.032161712000118, 5.811273098000186],
              [27.027801513000043, 5.813127994000183],
              [27.02277183500007, 5.820506095000042],
              [27.02532959000007, 5.830389976000106],
              [27.025590897000086, 5.837523938000061],
              [27.01597023100004, 5.848368168000093],
              [27.00267028800016, 5.856691838000074],
              [26.996929169000055, 5.858321190000083],
              [26.98452758800005, 5.857439041000021],
              [26.963119507000044, 5.847153186000128],
              [26.95875930800014, 5.84785795200014],
              [26.948450089000062, 5.857780934000175],
              [26.93376922600015, 5.86472988200012],
              [26.928499222000028, 5.870036126],
              [26.926927566000188, 5.881775856000047],
              [26.92235946700015, 5.890071870000099],
              [26.91593742300006, 5.893540860000087],
              [26.904691696000157, 5.89472580000006],
              [26.891349791000152, 5.892237186000045],
              [26.888589859000035, 5.889716148000161],
              [26.873170852000158, 5.882398128000034],
              [26.86076927100015, 5.886807919000148],
              [26.85136032000014, 5.888677120000182],
              [26.842180252000162, 5.892842770000016],
              [26.835079192000023, 5.89884710399997],
              [26.833019256000114, 5.903914929000166],
              [26.83289718700007, 5.939116955000088],
              [26.828380585000104, 5.962133884000025],
              [26.819240570000147, 5.980794906000028],
              [26.81053161700015, 5.987491130000024],
              [26.80570983800004, 5.987734794000119],
              [26.799259186000143, 5.983613968000043],
              [26.792629243000192, 5.993525983000097],
              [26.78274917499999, 5.99700403300011],
              [26.76943016100006, 5.998653888000092],
              [26.759120940000173, 6.008114814000066],
              [26.75177955600003, 6.01319789900009],
              [26.743280410000068, 6.014373780000142],
              [26.73637962400005, 6.011170864000178],
              [26.731309892000127, 6.006586075000143],
              [26.71982193100007, 6.005927086000042],
              [26.704219818000126, 6.017014981000102],
              [26.695270538000045, 6.01956987300008],
              [26.683538436000106, 6.018910885000082],
              [26.667190552000136, 6.006533147000027],
              [26.66235923800008, 6.004244805000042],
              [26.655008316000078, 6.004955768000173],
              [26.646980285000097, 6.010500908000154],
              [26.63504981900013, 6.016283989000158],
              [26.620109559000127, 6.016785145000085],
              [26.61002731399998, 6.027852059000054],
              [26.605440139000166, 6.02993583600005],
              [26.597169876, 6.029727936000086],
              [26.588659287000098, 6.027221204000171],
              [26.574640273000114, 6.027719975000139],
              [26.560180663000097, 6.033278943000028],
              [26.55423927200013, 6.046404838000058],
              [26.552200317000086, 6.056763173000093],
              [26.543699265000157, 6.060236931000134],
              [26.539119721000134, 6.063929082000186],
              [26.536840439000173, 6.071064949000061],
              [26.538019180000106, 6.081874848000155],
              [26.532518387000152, 6.089019775000054],
              [26.53163909800014, 6.101442814000052],
              [26.52428817800012, 6.105143070000167],
              [26.520870208000133, 6.113433839000038],
              [26.51719093300011, 6.115282058000105],
              [26.497409821000133, 6.108664036000164],
              [26.486589432000017, 6.102707864000081],
              [26.466079711000077, 6.081139088000043],
              [26.46032905600009, 6.077702045000137],
              [26.455730438000103, 6.07932186100004],
              [26.45115280100015, 6.085776806000069],
              [26.450508118000187, 6.102799893000167],
              [26.459070205000103, 6.123713016000068],
              [26.46620941200007, 6.130136014000073],
              [26.483009339000034, 6.138144017000116],
              [26.49084091100019, 6.144334794000145],
              [26.49958992100005, 6.148912907000124],
              [26.50971984800003, 6.157858848000046],
              [26.524017333000188, 6.176911831000041],
              [26.53372955300017, 6.19459915200008],
              [26.535589218000098, 6.205636025000104],
              [26.53539085400007, 6.215067864000048],
              [26.53264808700004, 6.220133781000072],
              [26.527141572000176, 6.224749088000124],
              [26.517719268000178, 6.226383209000062],
              [26.508979796000062, 6.224566937000077],
              [26.49632072500009, 6.216316223000092],
              [26.488729477000106, 6.214496137000083],
              [26.480699539000057, 6.220496178000133],
              [26.475429534, 6.228099822000104],
              [26.472469330000024, 6.237079143000074],
              [26.472719192000113, 6.244668961000116],
              [26.476240157, 6.274098873000128],
              [26.475559235000162, 6.277321816000097],
              [26.466159820000087, 6.284247876000109],
              [26.455587387000094, 6.28588295000003],
              [26.447320936999972, 6.289353848000133],
              [26.443660736000083, 6.294652938000183],
              [26.43722915600017, 6.29949998800015],
              [26.42826843200004, 6.301362038000036],
              [26.396560668000177, 6.305809021000187],
              [26.384622574000048, 6.312276841000085],
              [26.361930848000156, 6.34039306700015],
              [26.3435802460001, 6.356074811000042],
              [26.33439063999998, 6.361618040999986],
              [26.313970566000137, 6.377072811000119],
              [26.29789734000019, 6.387691975000166],
              [26.293550491000133, 6.39299106600015],
              [26.29402732900013, 6.401958942000135],
              [26.298419952000074, 6.409308911000153],
              [26.311319351000066, 6.419629097000154],
              [26.315710067000055, 6.429737091000163],
              [26.30170059300019, 6.438279151000131],
              [26.300559997000107, 6.441041946000041],
              [26.30703163100003, 6.452063084000145],
              [26.308420181000145, 6.459650993000082],
              [26.30360984800012, 6.465410232000124],
              [26.295089721000124, 6.460600852000084],
              [26.286592483000163, 6.462460994000139],
              [26.2914505, 6.477856160000158],
              [26.293079377000026, 6.486361981000187],
              [26.297239304000072, 6.493939877000173],
              [26.323219299000186, 6.493652821000183],
              [26.326908112000126, 6.498013020000087],
              [26.32692909299999, 6.503760815000078],
              [26.320512772000143, 6.512285234000046],
              [26.32743072400001, 6.519855976000144],
              [26.330680846000178, 6.533188821000067],
              [26.335992813000132, 6.54007482600008],
              [26.34612083500008, 6.546030045000123],
              [26.349809647000086, 6.550159931000053],
              [26.349359512000035, 6.555910111000117],
              [26.3528594980001, 6.575219155000127],
              [26.35770034700016, 6.579346181000176],
              [26.377008437000086, 6.576540947000069],
              [26.381839751000143, 6.578828812000097],
              [26.38048935000006, 6.587569237000025],
              [26.381652832000043, 6.594925881000165],
              [26.387420655000028, 6.601350785000079],
              [26.403770446000067, 6.611658096000156],
              [26.410238267000068, 6.623829841000145],
              [26.41510963500008, 6.637384891000124],
              [26.414430618000097, 6.643823147000092],
              [26.407100677000187, 6.651888848000169],
              [26.391471862000117, 6.655836106000095],
              [26.38228988700007, 6.663445949999982],
              [26.37266159100011, 6.676577092000059],
              [26.35750961299999, 6.68903017100007],
              [26.34923934900013, 6.690657140000099],
              [26.335210801000073, 6.689542770000116],
              [26.33061027500014, 6.690702915000088],
              [26.322120667000036, 6.698537827000166],
              [26.313850403, 6.703157903000147],
              [26.284179687000176, 6.702536106000139],
              [26.27222251900008, 6.704172135000022],
              [26.262800218000052, 6.70902204500004],
              [26.258920669000133, 6.719145775000129],
              [26.25939941400003, 6.729493141999967],
              [26.257570268, 6.734095098000068],
              [26.240335465000157, 6.739652158000183],
              [26.224468232000106, 6.741988183000103],
              [26.216190339000093, 6.741086960000018],
              [26.207229614000028, 6.747313977000033],
              [26.209550858000057, 6.754893780000032],
              [26.20841980000006, 6.762944221999987],
              [26.203140260000055, 6.76847600900004],
              [26.176727294999978, 6.786005020000061],
              [26.17374992300006, 6.789919854000175],
              [26.179519653000114, 6.800714017000018],
              [26.176319123000155, 6.807847976000119],
              [26.167589189000125, 6.813154220000058],
              [26.157239914000115, 6.812486171000103],
              [26.114690780000103, 6.815334797000048],
              [26.104589462000092, 6.823863029000108],
              [26.093572616000188, 6.838600160000112],
              [26.08555984500009, 6.854249001000028],
              [26.084890366000025, 6.86551523300011],
              [26.08978080800017, 6.892170907000093],
              [26.089120864000108, 6.904128075000187],
              [26.085439682000185, 6.908273220000069],
              [26.07737731899999, 6.901622773000042],
              [26.067939759000012, 6.900722981000172],
              [26.06059837300012, 6.910393239000086],
              [26.062700271000153, 6.923720836000143],
              [26.0482101450001, 6.922142029000156],
              [26.040851593000184, 6.925146103000031],
              [26.041090011000165, 6.931811810000056],
              [26.049850463999974, 6.938919068000132],
              [26.05492973200012, 6.944888115000083],
              [26.047130584000172, 6.959155084000088],
              [26.04530906600013, 6.969272138000179],
              [26.054090500000143, 6.985346794000066],
              [26.054111481, 6.994310856000084],
              [26.048599243000126, 7.000527860000091],
              [26.030670166000164, 7.007459165000057],
              [26.007659912, 7.008883954000112],
              [25.98557090700018, 7.006859779000024],
              [25.979829788000018, 7.011926174000109],
              [25.979389190000177, 7.018823147000092],
              [25.9835491180001, 7.028237821000118],
              [25.98286819499998, 7.03329801600006],
              [25.97780990600006, 7.036984921],
              [25.929271699000083, 7.042133807000084],
              [25.923759461000145, 7.046740055000157],
              [25.924459458000058, 7.054094792],
              [25.9228591910001, 7.05869579300014],
              [25.899627685000098, 7.061725140000021],
              [25.894111634000126, 7.065642834000187],
              [25.89528083900018, 7.071846962000166],
              [25.90518951300004, 7.080103874000145],
              [25.906570435000106, 7.083087921000072],
              [25.901290893000066, 7.088614940000014],
              [25.893470764000085, 7.090237141000046],
              [25.88911056500018, 7.096910954000066],
              [25.875310898000123, 7.096936225000093],
              [25.862899781000067, 7.107070923000038],
              [25.83460044800006, 7.107582092000143],
              [25.830917358000136, 7.109655856000131],
              [25.816879272000165, 7.110828876000028],
              [25.81229019200009, 7.117044925000187],
              [25.82266807600007, 7.127596855000149],
              [25.824760437000066, 7.142074108000145],
              [25.81809806900003, 7.149209977000169],
              [25.804990768000096, 7.152908803000173],
              [25.773469926000075, 7.154800892000139],
              [25.763120651000065, 7.158037186],
              [25.751159668000128, 7.15897607800008],
              [25.750949860000162, 7.174143791000176],
              [25.741464616000144, 7.177835941000126],
              [25.72886276300011, 7.176938057000086],
              [25.71987915000011, 7.169597150000129],
              [25.71389961200009, 7.173742772000082],
              [25.70564079200011, 7.190305233000117],
              [25.690010070000028, 7.19676399299999],
              [25.68287086500004, 7.192409039000097],
              [25.673200607000183, 7.192193986000177],
              [25.665380478000145, 7.194045068000037],
              [25.655029296000066, 7.200037004000023],
              [25.64792060800005, 7.211537838000027],
              [25.644931793000126, 7.212460996000175],
              [25.63180923500005, 7.211331845000132],
              [25.62813186600016, 7.212255001000131],
              [25.61731910600008, 7.218937874000176],
              [25.604429245000176, 7.21596908600003],
              [25.597988128000054, 7.218735218000063],
              [25.59546279900013, 7.223105907000161],
              [25.59593963700013, 7.235514164000165],
              [25.588350296000044, 7.240582944000153],
              [25.583749771000157, 7.238749981000126],
              [25.578899383000135, 7.228184223000028],
              [25.5690097800001, 7.233026982000069],
              [25.56716918900014, 7.236246108000159],
              [25.570880890000012, 7.250030041000116],
              [25.565130233000048, 7.255551815000104],
              [25.558460236000144, 7.258549213000151],
              [25.553169250000053, 7.256488799000181],
              [25.54943084700011, 7.251894951000054],
              [25.544189454000104, 7.256499767000037],
              [25.534549713000047, 7.273750783000082],
              [25.52650070300018, 7.276978017000147],
              [25.521890640000038, 7.274457930999972],
              [25.51038932800003, 7.278838159000031],
              [25.50991821299999, 7.271255016000112],
              [25.506689072000086, 7.267814160000171],
              [25.49909973100017, 7.267363071000034],
              [25.49266052200005, 7.276332856000124],
              [25.48344993600017, 7.268991948000064],
              [25.477462768000066, 7.269228936000161],
              [25.473789215000124, 7.27888584100009],
              [25.469190597000136, 7.28004217299997],
              [25.465288162000093, 7.287170887000059],
              [25.462528228000167, 7.288094045000094],
              [25.452861785000152, 7.283051013999966],
              [25.45148086500012, 7.288338184000111],
              [25.454959870000152, 7.302118778000079],
              [25.451740265000126, 7.306261064000182],
              [25.446680070000184, 7.30741596300004],
              [25.43792152499998, 7.302832126000112],
              [25.431720734000066, 7.31248998600006],
              [25.425289154999973, 7.31847476900009],
              [25.40413093600006, 7.33274698300005],
              [25.400449754000135, 7.331141950000188],
              [25.395139695000125, 7.324715138000101],
              [25.388010026000075, 7.3247218140001],
              [25.37789917000015, 7.340590955000153],
              [25.366159439, 7.341063022000014],
              [25.361097335000068, 7.344515801000171],
              [25.35353279000003, 7.35808086500009],
              [25.3521499630001, 7.366127014000142],
              [25.34733009400003, 7.376471997000124],
              [25.34642028700017, 7.381989955000165],
              [25.349420548000182, 7.39071607600016],
              [25.343450546000042, 7.397844791000182],
              [25.33609962600019, 7.41026210800004],
              [25.336570740000127, 7.421751023000184],
              [25.323682784000084, 7.427048206000166],
              [25.317470550000166, 7.423378945000081],
              [25.310100555000133, 7.422698975000117],
              [25.306880952000085, 7.428216934000034],
              [25.30689048700009, 7.43510913900019],
              [25.29700088600009, 7.44178295200004],
              [25.29148674000004, 7.451667785000154],
              [25.28343010000009, 7.452597141000126],
              [25.287359237000032, 7.46086406600017],
              [25.279529572000115, 7.465237141000046],
              [25.275630950000107, 7.47535181],
              [25.270328521000067, 7.467544079000163],
              [25.26365280100015, 7.470307826000123],
              [25.251920701000017, 7.472617149000143],
              [25.238790511000047, 7.47216987600018],
              [25.234188080000024, 7.474013805000084],
              [25.226379394000162, 7.484130859000118],
              [25.214870453000117, 7.487131119000026],
              [25.20635032600012, 7.485070228000154],
              [25.2003707880001, 7.488980771000172],
              [25.195770263000043, 7.496569157000067],
              [25.199920655000028, 7.506443977000174],
              [25.197420120000174, 7.536081791000129],
              [25.187091828000064, 7.561133862000077],
              [25.17881012000015, 7.565505982000161],
              [25.17306137100013, 7.573322773000029],
              [25.175369262000174, 7.579291821000084],
              [25.184579848000055, 7.582730769000079],
              [25.188959121000096, 7.591228008000144],
              [25.204849243000183, 7.593966960999978],
              [25.210620881000068, 7.597639085000083],
              [25.213850022000145, 7.604065896000066],
              [25.214311599000098, 7.609579087000157],
              [25.219610215000102, 7.611641884000051],
              [25.23180961600002, 7.610480785000164],
              [25.236890792000167, 7.620812893999982],
              [25.245178223000096, 7.62424993500008],
              [25.25646018900011, 7.62125301399999],
              [25.266370773000176, 7.629282951000107],
              [25.287799835000044, 7.638906955999971],
              [25.291948319000085, 7.643958093000038],
              [25.29219055100009, 7.650847911000085],
              [25.286670685000047, 7.657055855000181],
              [25.288980483000046, 7.6625671380001],
              [25.300489424999967, 7.661634922000076],
              [25.304870605000133, 7.665307046000123],
              [25.305339814000092, 7.672427177000145],
              [25.30166053700009, 7.677023888000065],
              [25.289230347000114, 7.683011056],
              [25.287630082000135, 7.691969871000083],
              [25.28026962200005, 7.697950841000079],
              [25.280279160000134, 7.704152107000141],
              [25.28673935000012, 7.713562965000051],
              [25.281219482000154, 7.724590779000153],
              [25.28837013200007, 7.731246948000148],
              [25.293220519000045, 7.745711804000052],
              [25.292539596999973, 7.755817890000117],
              [25.289100646000122, 7.767765045000147],
              [25.286819459000128, 7.78154897800016],
              [25.292360305999978, 7.792335986000012],
              [25.289600372000052, 7.799688816000185],
              [25.284540177000167, 7.803597927000055],
              [25.27831077500008, 7.796945095000126],
              [25.271860123000124, 7.796491146000108],
              [25.267032624000137, 7.799483776000102],
              [25.266588211000055, 7.812574864000055],
              [25.260599136000167, 7.816713811000113],
              [25.251180649000105, 7.83509779000002],
              [25.229791642000066, 7.856706142000178],
              [25.230947494000134, 7.867730141000038],
              [25.22864913900014, 7.869338989000141],
              [25.213449478000086, 7.867057800000112],
              [25.205860138000048, 7.877859116000138],
              [25.193429948000073, 7.888206006000019],
              [25.18951988200007, 7.895555973000057],
              [25.18008804300007, 7.902913093],
              [25.16741943400001, 7.89902305600009],
              [25.147609711000086, 7.899271012000042],
              [25.139539718000037, 7.89468288400019],
              [25.12709808400018, 7.892168999000091],
              [25.11696052500008, 7.891948224000032],
              [25.100370407000185, 7.884384155000078],
              [25.09322929300015, 7.88369989500012],
              [25.08748054600011, 7.888529778000191],
              [25.080120086000022, 7.904839038000091],
              [25.07621193000017, 7.910352231000047],
              [25.058719635000102, 7.927361966000092],
              [25.03087043800008, 7.944830894000063],
              [25.005308150000133, 7.96138715799998],
              [24.997940063000044, 7.964147092000076],
              [24.971700668000153, 7.983684063],
              [24.965951919000133, 7.99493789700017],
              [24.96575927700019, 8],
              [24.9562301630001, 8.017177582000102],
              [24.94219017000006, 8.031627654000033],
              [24.93458938599997, 8.034838677000039],
              [24.920080184000142, 8.04401016200012],
              [24.90949058500013, 8.047219277000181],
              [24.905809403000035, 8.051576614],
              [24.902120590000095, 8.062816620000092],
              [24.89426040700016, 8.119251252000026],
              [24.888729095000087, 8.137604713000087],
              [24.87812805100009, 8.147464752000076],
              [24.86820983800004, 8.173615455000117],
              [24.86474990900001, 8.18049812300012],
              [24.853229524000085, 8.18370533000018],
              [24.845867157000157, 8.177049636000163],
              [24.831806183000083, 8.190805436000176],
              [24.80508995100007, 8.191248894000125],
              [24.803012848000037, 8.188950539000075],
              [24.77930069000007, 8.181824685000095],
              [24.77054023700009, 8.18456935800009],
              [24.761329650000164, 8.18364143500014],
              [24.75625991800007, 8.186849595000183],
              [24.748870849000014, 8.20267677400011],
              [24.739650727000082, 8.20886230400015],
              [24.729749680000054, 8.209772110000074],
              [24.714090346999967, 8.206543923000083],
              [24.70233917300004, 8.206761361000133],
              [24.688512803000037, 8.21225261800015],
              [24.681819917000155, 8.21958827900005],
              [24.66823005700013, 8.223470689000123],
              [24.647029877000023, 8.226202012000044],
              [24.62192916800018, 8.222955704000015],
              [24.605119705000163, 8.217885017000128],
              [24.56851959300019, 8.203603745000123],
              [24.560691833000135, 8.202904702000126],
              [24.554002762000152, 8.204270364000138],
              [24.51596069200002, 8.223022460000038],
              [24.511110305000102, 8.228061676000095],
              [24.507408142000088, 8.236316681],
              [24.50325965900015, 8.238833427999964],
              [24.493360518000088, 8.237668037000105],
              [24.490819932000193, 8.24064731600015],
              [24.478780747000144, 8.272288324000101],
              [24.475540161000083, 8.275266648000184],
              [24.461719513000162, 8.272717476000082],
              [24.43936920100009, 8.275884628000142],
              [24.430830001000174, 8.281602860000078],
              [24.42460823100015, 8.282279969000058],
              [24.41424179100005, 8.280653001000076],
              [24.4094200130001, 8.27536487600014],
              [24.378120423000098, 8.257857323000053],
              [24.37150001500015, 8.251406670000051],
              [24.360397338000155, 8.251851081000154],
              [24.349559785000054, 8.256416320000142],
              [24.343320847000086, 8.26649665799999],
              [24.338462830000026, 8.271076202000188],
              [24.323949815000105, 8.270811082000137],
              [24.30022811800012, 8.265474320000123],
              [24.271642684000028, 8.274121284999978],
              [24.25502967799997, 8.28210926100013],
              [24.236131667000166, 8.285499573000152],
              [24.222280503000036, 8.293263436000132],
              [24.217430116000116, 8.29784011900017],
              [24.203580857000077, 8.303997994000099],
              [24.19940948400017, 8.312246324000114],
              [24.180219650000083, 8.333300590000079],
              [24.17556953400009, 8.344989778000183],
              [24.160779954000134, 8.3582572950001],
              [24.153371811000113, 8.369937897],
              [24.151929856000095, 8.387605667000116],
              [24.154909134000036, 8.392895698000189],
              [24.16106033400007, 8.416090012000097],
              [24.161930085000165, 8.432388306],
              [24.164880753000034, 8.447315216000163],
              [24.16345024100019, 8.462686538000128],
              [24.17122078000017, 8.48153114400003],
              [24.191919327999983, 8.500181198000064],
              [24.202478408000047, 8.515590667000026],
              [24.20729064800014, 8.525016784000059],
              [24.233259200000077, 8.555845261000059],
              [24.255870820000155, 8.578927995000186],
              [24.268499374000157, 8.586248398],
              [24.26794052000014, 8.592735290000121],
              [24.252119064000055, 8.606533050999985],
              [24.24418067900001, 8.610275269],
              [24.236307145000126, 8.619435311000132],
              [24.23211860700013, 8.631819726000117],
              [24.23257064800015, 8.636642457000107],
              [24.237609864000035, 8.648131370999977],
              [24.255069732000095, 8.673201560000166],
              [24.259891510000102, 8.68377494800012],
              [24.260988235000184, 8.703061104000028],
              [24.25266075200011, 8.711763382000072],
              [24.245500564000054, 8.71289062500017],
              [24.234199524000076, 8.710563661000037],
              [24.224521638000112, 8.704797746000168],
              [24.223379135000187, 8.701352119000092],
              [24.216249466000136, 8.694215775000089],
              [24.20587921200007, 8.68821716400015],
              [24.199638367000148, 8.690265655000076],
              [24.194780350000087, 8.694842339000047],
              [24.17466926500009, 8.706491470000174],
              [24.16451072600006, 8.70783805799999],
              [24.13986015300003, 8.694908142000088],
              [24.13179779100011, 8.692358018000107],
              [24.10153961300017, 8.699379922000048],
              [24.068479538000076, 8.703438760000154],
              [24.060979842, 8.703157424000153],
              [24.037580491000085, 8.70995521500015],
              [24.018890380000073, 8.710119248000126],
              [24.00666999700013, 8.70755100200006],
              [23.99444007900007, 8.707965852000086],
              [23.975469590000102, 8.718916892999971],
              [23.96806526100005, 8.72485733100018],
              [23.9405803680001, 8.730036736000159],
              [23.908069610000098, 8.723482132000129],
              [23.901069641000106, 8.723365784000123],
              [23.889850616000047, 8.719277382000087],
              [23.873479843000098, 8.71645546000019],
              [23.82978057800011, 8.734870910000097],
              [23.818000794000113, 8.736887932],
              [23.812711716000024, 8.734339715000146],
              [23.794019700000092, 8.732424736000041],
              [23.783170699000152, 8.733065605000036],
              [23.73893928400014, 8.716109276000111],
              [23.732711792000032, 8.71677017200011],
              [23.71375083900017, 8.722880363],
              [23.676822663000053, 8.725234986000032],
              [23.637729644000103, 8.742491722000068],
              [23.62964058000017, 8.744518280000023],
              [23.608179092000057, 8.744182587000068],
              [23.57749938900014, 8.740582465000102],
              [23.56991958600014, 8.733885766000128],
              [23.55755043000005, 8.716146470000183],
              [23.544620514000144, 8.717685700000175],
              [23.520107270000153, 8.726968765000095],
              [23.51981925900003, 8.75887203200017],
              [23.509130478000145, 8.768553734000136],
              [23.508220673000096, 8.771820069000114],
              [23.511659621999968, 8.793731691000119],
              [23.508129121000024, 8.806110383000089],
              [23.508539200000087, 8.814837455000088],
              [23.51402092000012, 8.826115608],
              [23.523452759000122, 8.832365036],
              [23.53680992200009, 8.83702755000013],
              [23.546459197000104, 8.84672164900013],
              [23.569599151000148, 8.878985406000027],
              [23.570270538000045, 8.883352280000054],
              [23.581029892000117, 8.902692794000131],
              [23.586019516000135, 8.918561935000184],
              [23.58871078500016, 8.934187890000146],
              [23.588159561, 8.951178551000055],
              [23.585561752000103, 8.961499215000117],
              [23.58846283000014, 8.982407569999964],
              [23.58629989600007, 8.99732494400007],
              [23.57723808200012, 9.007151604000057],
              [23.56961059500003, 9.00825977300019],
              [23.545289992999983, 8.977594376000184],
              [23.531269073000033, 8.96351432900002],
              [23.512739182000132, 8.963488578000124],
              [23.500400544000115, 8.962430954000183],
              [23.482700347000105, 8.974275589000115],
              [23.480377197999985, 8.977705956000023],
              [23.465570449000154, 8.981298447000142],
              [23.463481904000048, 8.984044076000032],
              [23.465009689999988, 8.998518944000068],
              [23.463359833000027, 9.00470924400014],
              [23.450849532000063, 9.010381698000174],
              [23.447809219000135, 9.017023087000155],
              [23.450761795000176, 9.02576637400017],
              [23.46199989400003, 9.040062904000024],
              [23.464511872, 9.045590400000094],
              [23.46323967000012, 9.066479684000115],
              [23.465490341000077, 9.076367378000043],
              [23.475091935000137, 9.09433364900002],
              [23.47528076200001, 9.102532386000064],
              [23.47172927800017, 9.117050171000017],
              [23.476310729999966, 9.141964913000038],
              [23.49003028800007, 9.149990082000102],
              [23.49197959900016, 9.172736168000085],
              [23.505390168000133, 9.173269273000074],
              [23.509510041000055, 9.179946899000129],
              [23.513669968000045, 9.180201530000147],
              [23.52757835500006, 9.17384624499999],
              [23.53982162400007, 9.174368859000083],
              [23.545818329000042, 9.177618027000165],
              [23.57238006700004, 9.197688102000143],
              [23.594610214000056, 9.220809936000137],
              [23.610958099000072, 9.230542184000115],
              [23.614160537000032, 9.237449646000016],
              [23.612499237000065, 9.245709420000082],
              [23.620290757000078, 9.259298323999985],
              [23.63248062100007, 9.271532060000084],
              [23.640108108000106, 9.272947311],
              [23.64683151300011, 9.26976299200004],
              [23.651210786000036, 9.27299976400019],
              [23.655309678000037, 9.285651207000058],
              [23.65365028500014, 9.293912889000069],
              [23.642679214000054, 9.314071656000124],
              [23.639619828000036, 9.323700905000067],
              [23.63911056600017, 9.343262674000073],
              [23.64627075200019, 9.380470275000164],
              [23.65111160300006, 9.383710862000157],
              [23.65082931500018, 9.394046784000182],
              [23.663719177000132, 9.406509400000175],
              [23.67173957800003, 9.421938896000142],
              [23.67744255000008, 9.438735963000056],
              [23.672742844000084, 9.454563142000154],
              [23.667867661, 9.456834793000098],
              [23.661159515000122, 9.456575394000026],
              [23.65168953000017, 9.452392578000115],
              [23.643859864000035, 9.445696831000078],
              [23.634019852000108, 9.469076157000075],
              [23.633529662000058, 9.473898888],
              [23.640560149000123, 9.501958848000186],
              [23.64142036400017, 9.515746116000173],
              [23.646909714000117, 9.527487754000049],
              [23.655660629000067, 9.536487580000028],
              [23.652635575000033, 9.540148735000173],
              [23.628549576000125, 9.542101861000106],
              [23.6326408380001, 9.558429719000173],
              [23.650159836000114, 9.572070123000117],
              [23.654249191000133, 9.586333274000083],
              [23.664451599000188, 9.60038376],
              [23.66794014000004, 9.61814499000019],
              [23.68453979500015, 9.642071724000175],
              [23.697868347000167, 9.667718887999968],
              [23.697799684000074, 9.677516937000064],
              [23.675680160000127, 9.837676049000038],
              [23.668241501000068, 9.891513825000061],
              [23.58691024800015, 10.019100190000131],
              [23.50148964000016, 10.158828736000089],
              [23.487459183000055, 10.178291319999971],
              [23.48648071400015, 10.182830810000041],
              [23.429359435000038, 10.273570060000111],
              [23.426769256, 10.280450822000091],
              [23.390710831000035, 10.33356952700018],
              [23.387409209000054, 10.341820718000122],
              [23.383670807000158, 10.344791412000063],
              [23.350130082000135, 10.400210381000079],
              [23.344520569000053, 10.406149864999975],
              [23.34169006400009, 10.413721085000077],
              [23.32715988100017, 10.435239793000108],
              [23.323150635000104, 10.444409370000017],
              [23.301879884000073, 10.474639892000027],
              [23.270610809000175, 10.489830016000099],
              [23.26383972100001, 10.494611740000153],
              [23.247909545000027, 10.515430451000043],
              [23.241329194000116, 10.526650429000085],
              [23.228029251, 10.536219597000127],
              [23.216701507000153, 10.54067993200016],
              [23.20937728900003, 10.545523643000081],
              [23.19108009400003, 10.571379662000027],
              [23.186420441000053, 10.572961807000127],
              [23.159540176000064, 10.597149850000108],
              [23.136739731000148, 10.634228707000034],
              [23.12995910600017, 10.6399269100001],
              [23.08749961800015, 10.663089752000133],
              [23.077909469000076, 10.67107200700002],
              [23.072975158000077, 10.678391457000089],
              [23.069480896000186, 10.679287911000188],
              [23.054990768000096, 10.690448762000187],
              [23.007379531000083, 10.716089249000106],
              [22.998479844000087, 10.724761010000066],
              [22.99375915500002, 10.733921051999971],
              [22.990640640000038, 10.747000694000064],
              [22.976219176000143, 10.778161050000051],
              [22.972719192000113, 10.780890465000027],
              [22.921520233000024, 10.846261978000143],
              [22.920690537000155, 10.862568855000177],
              [22.913890838000043, 10.86988067700014],
              [22.908519744000046, 10.872141838000061],
              [22.898782730000164, 10.882240296000191],
              [22.891122817999985, 10.887631417000136],
              [22.88526916400008, 10.894701003000137],
              [22.87722969000015, 10.917421339999976],
              [22.881338120000066, 10.930899620000162],
              [22.86580085700018, 10.932150841000066],
              [22.853239059000146, 10.93117141700003],
              [22.847410203000152, 10.934129716000086],
              [22.817380905000107, 10.934451103000129],
              [22.775669098000037, 10.945281028000124],
              [22.768699647000062, 10.944091797000056],
              [22.761219024000127, 10.949570657000038],
              [22.756027221000124, 10.961961747000146],
              [22.74457931400019, 10.971098900000072],
              [22.73431968600005, 10.974491120000039],
              [22.710830688000044, 10.971610070000054],
              [22.703140260000055, 10.972260475000155],
              [22.68291091900005, 10.98019790700016],
              [22.655769348000092, 10.979479791000074],
              [22.632049561000088, 10.990389824000147],
              [22.612268447000133, 10.997040749],
              [22.604440690000047, 10.996719361000089],
              [22.57706069900007, 10.987700463000124],
              [22.56868934700003, 10.984190941000179],
              [22.56096077000018, 10.987880707000102],
              [22.552661896000075, 10.993728638000164],
              [22.52067947500018, 11.00757026700012],
              [22.50904083200004, 11.00726890600015],
              [22.497200012000178, 11.00306034099998],
              [22.49398040900013, 10.99728870500013],
              [22.476963044000172, 11.000640870000097],
              [22.46858978200015, 10.999210358000028],
              [22.46142959600013, 10.991119386000094],
              [22.45656013500019, 10.987869264000096],
              [22.442829132000156, 10.988480568000057],
              [22.433330537000188, 10.981289863000086],
              [22.430810929000074, 10.974379539000154],
              [22.42432022200012, 10.970660210000176],
              [22.41361045799999, 10.971739768000134],
              [22.402441024000098, 10.970750809000094],
              [22.38434982400014, 10.961900712000158],
              [22.37662124700006, 10.969440460000158],
              [22.370180130000165, 10.958358766],
              [22.3559894550001, 10.956431388000055],
              [22.3269004820001, 10.955780983000182],
              [22.32365989700014, 10.954381942000111],
              [22.31302070700019, 10.94419193200008],
              [22.289840698000148, 10.931387902000097],
              [22.282169340999985, 10.93064880500009],
              [22.26560974000006, 10.923021317000178],
              [22.251310348000118, 10.926220895000029],
              [22.24526977500011, 10.92547130500003],
              [22.236280441000076, 10.917560577000074],
              [22.222370149000085, 10.91283130700009],
              [22.204940797000177, 10.911040306000132],
              [22.19553756700003, 10.908559800000091],
              [22.182750702000135, 10.900081635000163],
              [22.17468070900003, 10.883798599999977],
              [22.158733367000025, 10.880690574000027],
              [22.15180015500016, 10.876021385000172],
              [22.1443595880001, 10.87832927800008],
              [22.12058067400011, 10.881461143000138],
              [22.116840363000165, 10.88305187200001],
              [22.11198043800016, 10.88047885900005],
              [22.09869956900002, 10.88291072800007],
              [22.088390351000044, 10.89134121],
              [22.08161735599998, 10.894971848000068],
              [22.07415962100015, 10.895831109000028],
              [22.060029984000153, 10.888599397000121],
              [22.048620224000047, 10.889201165000145],
              [22.041679382000098, 10.884321214000067],
              [22.036102295000035, 10.88313198100019],
              [22.029342651000093, 10.885608674000025],
              [22.01654052700013, 10.885050773000103],
              [22.001750946000072, 10.87344074200007],
              [21.989412308, 10.87380027900008],
              [21.982519150000087, 10.86363124900015],
              [21.97833061299997, 10.8631410590001],
              [21.97084045500003, 10.868371964000062],
              [21.962209701000177, 10.871289254000033],
              [21.963470459000064, 10.860260964000076],
              [21.952789306000113, 10.857191086000114],
              [21.949039458000186, 10.860151292000069],
              [21.944549560000098, 10.869077682000011],
              [21.94152069100005, 10.869290352],
              [21.943027496000127, 10.855731965000075],
              [21.940040589000148, 10.852260589000082],
              [21.933980942000176, 10.85335922300004],
              [21.927410125000165, 10.860210419000055],
              [21.921020508000026, 10.871879579000108],
              [21.91264152500014, 10.87249946500009],
              [21.908512116000168, 10.866032600000153],
              [21.89922714200003, 10.86274147100005],
              [21.887300490000143, 10.870229721000044],
              [21.87825012100012, 10.867860795000126],
              [21.871620178, 10.854459763000136],
              [21.865169526000102, 10.846598626000116],
              [21.8565807330001, 10.844689370000083],
              [21.844430924000108, 10.847529412000029],
              [21.84170913600019, 10.837748526999974],
              [21.832620620000114, 10.839479446000098],
              [21.819299699000055, 10.824391366000157],
              [21.814117431000113, 10.810311318000117],
              [21.809949875000086, 10.809109688000149],
              [21.802591323000172, 10.80213070000002],
              [21.791240692000088, 10.79808998100009],
              [21.788488388000076, 10.794610023000075],
              [21.788110732000064, 10.787481309000043],
              [21.790800096000055, 10.776709557000117],
              [21.785850524000125, 10.762859345000095],
              [21.784349441000188, 10.752039910000065],
              [21.788442613000086, 10.740360261000149],
              [21.78970909200018, 10.731869698000082],
              [21.78632926900002, 10.72218132100005],
              [21.78791999800012, 10.713270187000091],
              [21.783170701000188, 10.703372002000094],
              [21.78882980300017, 10.694689751000169],
              [21.789121627000156, 10.687790870000185],
              [21.787122726000064, 10.677660943000035],
              [21.788589477000187, 10.669401168000093],
              [21.78236007800018, 10.664519310000117],
              [21.780780791000154, 10.65922069600009],
              [21.770360946000153, 10.653841019000026],
              [21.764619827000047, 10.646659850000162],
              [21.758609772000057, 10.642240525000147],
              [21.748819351000122, 10.645608903000152],
              [21.740589143000022, 10.655880927000055],
              [21.73362731900005, 10.653980256000125],
              [21.732000351000067, 10.629139901000144],
              [21.733320237000044, 10.61168193899999],
              [21.726949691000186, 10.59714126700004],
              [21.728990554000177, 10.57646942100007],
              [21.73307991100006, 10.56133174900009],
              [21.732919693000156, 10.554208755000161],
              [21.729799270000115, 10.539241791000052],
              [21.72130012399998, 10.528821946000107],
              [21.717540740000175, 10.507869721000134],
              [21.719690323000123, 10.501220703000115],
              [21.732690810000122, 10.477890014000025],
              [21.75085258400003, 10.46385955900007],
              [21.75255012600013, 10.4583597190001],
              [21.751449584000056, 10.452601433999973],
              [21.758529663000104, 10.443948746000103],
              [21.758350372999985, 10.438888549000012],
              [21.754089356000122, 10.426890374000038],
              [21.758110046000127, 10.420270919000075],
              [21.77045059200009, 10.417888642000037],
              [21.77099037200003, 10.411689759000069],
              [21.766630173000124, 10.404029846000185],
              [21.74294090300009, 10.381698609000125],
              [21.740409852000028, 10.36720085200011],
              [21.740800858000057, 10.35433101700005],
              [21.728000641000108, 10.356711388000122],
              [21.72314071800008, 10.347061158000145],
              [21.72347068800019, 10.342650414000047],
              [21.729627609000033, 10.33006095900015],
              [21.73737907500015, 10.32024192900019],
              [21.73443031400018, 10.312629700000173],
              [21.73028946, 10.30846119000006],
              [21.728260040000123, 10.301770211000019],
              [21.71949005200014, 10.295721055000115],
              [21.709669114000064, 10.278862],
              [21.705507279000074, 10.276290895000045],
              [21.69389724700011, 10.275960922000024],
              [21.682270050000056, 10.27884197200018],
              [21.676469803000032, 10.278100967000057],
              [21.67561149600016, 10.271199226000022],
              [21.682638168000153, 10.264370919000044],
              [21.675260544000082, 10.259011268000052],
              [21.663669586000083, 10.257069589000025],
              [21.660692214000107, 10.253138542000158],
              [21.66304016100014, 10.249480248000111],
              [21.66863060100013, 10.248150826000085],
              [21.67568969700011, 10.2376394260001],
              [21.668319702000076, 10.231829644000072],
              [21.649490357000104, 10.233961106000038],
              [21.64097023000005, 10.22721004499999],
              [21.632837296000048, 10.227370262000022],
              [21.6279392240001, 10.230999947000043],
              [21.622360230000027, 10.231410980000078],
              [21.61088180500019, 10.21821022099999],
              [21.59746742200008, 10.213028908000013],
              [21.58934974600004, 10.212491035000028],
              [21.579109191000157, 10.215150833],
              [21.56129074100005, 10.210390092000182],
              [21.553819656000144, 10.214229584000066],
              [21.547679901000095, 10.225199701000122],
              [21.543939591000026, 10.227930069000081],
              [21.536750794000056, 10.226941110000041],
              [21.52293968200007, 10.215540886000042],
              [21.511510849000047, 10.19457054100019],
              [21.510120391000157, 10.189860345],
              [21.504510880000055, 10.187141420000046],
              [21.49383735800012, 10.187007903999984],
              [21.491029739000112, 10.170881271000155],
              [21.487630843999966, 10.164633750000064],
              [21.481159210999976, 10.163398742000027],
              [21.468082428000116, 10.152210236000144],
              [21.471668244000114, 10.143750191000095],
              [21.468982696000126, 10.136131287000126],
              [21.462118149000162, 10.128461838000021],
              [21.450120927000114, 10.123709680000104],
              [21.448331833000054, 10.118630410000094],
              [21.45475006099997, 10.106760026000075],
              [21.45252037100005, 10.099841119000075],
              [21.455009461000145, 10.086999892999984],
              [21.453439712000034, 10.083080291000044],
              [21.445089341000028, 10.082969666000054],
              [21.441408158000172, 10.08084774100007],
              [21.437799454000185, 10.073220254000034],
              [21.43749046300013, 10.059351922000076],
              [21.429729461000136, 10.047321320000151],
              [21.420419693000042, 10.050219536000156],
              [21.416030884000122, 10.048338891000071],
              [21.407760619000157, 10.041130066000164],
              [21.40365028400015, 10.03442001400009],
              [21.404479981000122, 10.021330833000036],
              [21.40221023600003, 10.01671123599999],
              [21.395990373000075, 10.012970924000172],
              [21.393789292000122, 10.001232147999986],
              [21.38507080100004, 9.99172306000014],
              [21.37726020800011, 9.985209465000082],
              [21.36914825500014, 9.984437944000092],
              [21.36237907500015, 9.988967896000077],
              [21.359399796000105, 9.985029221000104],
              [21.354690552000022, 9.97027397200003],
              [21.347549438000158, 9.965603829000088],
              [21.34061050500003, 9.963697434000153],
              [21.33642005900009, 9.965262413000062],
              [21.33008957000004, 9.971864700000026],
              [21.324300766000135, 9.970883369000092],
              [21.319400787000177, 9.973589898000114],
              [21.31695938200005, 9.98528671300005],
              [21.309490204000042, 9.990036011000086],
              [21.304790497000113, 9.973442078000119],
              [21.29788970900006, 9.968084335000128],
              [21.2927093510001, 9.975385667000126],
              [21.287820816000192, 9.977172851000034],
              [21.28263092100019, 9.985622407000108],
              [21.262832641000045, 9.993688585000143],
              [21.254049301, 9.990381240000147],
              [21.251310348000175, 9.98644447300012],
              [21.24935150099998, 9.97493267100009],
              [21.242919922000112, 9.969350815000098],
              [21.242500305000135, 9.965439796000055],
              [21.233997345000034, 9.95776367200017],
              [21.214830399000107, 9.951356889000067],
              [21.212549208999974, 9.94765567800016],
              [21.21175003000002, 9.935696602000064],
              [21.20276069600004, 9.93077373400007],
              [21.200729369999976, 9.926389694000079],
              [21.204399110000054, 9.908731461000173],
              [21.20309066800013, 9.901362419000122],
              [21.198980331000143, 9.89534282700015],
              [21.190109253000116, 9.890007019000052],
              [21.183830261000026, 9.89176273400011],
              [21.177217484000096, 9.884085656000138],
              [21.152200699000105, 9.866276742000082],
              [21.133068085000104, 9.847903251000162],
              [21.122329712000067, 9.832082749000165],
              [21.117429732000176, 9.818685532000018],
              [21.116369247000137, 9.8117809310001],
              [21.118078232000187, 9.801333427999964],
              [21.115310668000177, 9.79194545900009],
              [21.107740403000093, 9.785199165000051],
              [21.092540741000107, 9.776757240000109],
              [21.085130691000188, 9.776447297000118],
              [21.07420921400012, 9.778623581999966],
              [21.06324005100015, 9.765404702000126],
              [21.058130264000056, 9.7664918910001],
              [21.04924965000015, 9.772830010000064],
              [21.039239883000164, 9.7766227730001],
              [21.030460357000095, 9.77399730799999],
              [21.025419235000072, 9.769343377000155],
              [21.02485084599999, 9.758535386],
              [21.03827094999997, 9.740992546000143],
              [21.03629112300007, 9.732010843000126],
              [21.03053093000011, 9.728499413000065],
              [21.02103042499999, 9.728619576000142],
              [21.010330200999988, 9.732176781000021],
              [20.99498939500006, 9.7470464700001],
              [20.991529465000156, 9.734949113000084],
              [20.99300956700006, 9.727153778000172],
              [21.000789642000143, 9.71575164799998],
              [21.004390717000092, 9.705682754000179],
              [21.001880645000085, 9.70197868300005],
              [20.98081016499998, 9.701276780000114],
              [20.972179413000163, 9.706003189000114],
              [20.967817306000086, 9.702277184000138],
              [20.969730376000143, 9.697703362000084],
              [20.974159241, 9.695458412000107],
              [20.98760986400015, 9.694002152000053],
              [20.993909836, 9.690629006000052],
              [20.998609542000054, 9.684936524000136],
              [20.994970321000096, 9.678920745000028],
              [20.981470109000043, 9.665439606000064],
              [20.98105049100019, 9.661069871000052],
              [20.98596000700013, 9.657446861000153],
              [20.99364089900007, 9.654777526000032],
              [20.996240616000136, 9.650213242000177],
              [20.993749619000027, 9.645590783000102],
              [20.98241233800013, 9.644541741000126],
              [20.97518920900012, 9.647904397000048],
              [20.970329284000115, 9.647158624000042],
              [20.96714973500019, 9.642526627000052],
              [20.971860886000172, 9.63568973700012],
              [20.987400053999977, 9.634256362000087],
              [20.990730285000097, 9.626712799000074],
              [20.98801994300004, 9.620709420000082],
              [20.978330612000093, 9.617149353000173],
              [20.96093940700007, 9.619248391000099],
              [20.954460143000063, 9.618713379000042],
              [20.950120927000057, 9.613837243000035],
              [20.955310821000126, 9.58678436300005],
              [20.954280852000068, 9.575974464000126],
              [20.95159912100013, 9.567901613000174],
              [20.944240569000044, 9.56345081400002],
              [20.936740875, 9.570715905000156],
              [20.927467346000185, 9.57106685600013],
              [20.924312593000025, 9.564137459000108],
              [20.924438476000034, 9.553800582000065],
              [20.91945076000019, 9.54500961299999],
              [20.919040681000126, 9.540640831000019],
              [20.921909333000144, 9.53332328800002],
              [20.915290832999972, 9.525432588000172],
              [20.906457901000124, 9.528314590000036],
              [20.894910813000024, 9.526110649000032],
              [20.87731742799997, 9.525216103000105],
              [20.873680115000013, 9.520115853000163],
              [20.876520156000083, 9.515095711000129],
              [20.86874961900014, 9.507192613000086],
              [20.863990784000123, 9.498864175000051],
              [20.866439819000163, 9.487404823000077],
              [20.862552644000118, 9.484143258000131],
              [20.855390548000116, 9.4824495310001],
              [20.849569320000057, 9.485597611000117],
              [20.84471893300008, 9.48438930499998],
              [20.839450836000026, 9.479961395000089],
              [20.826929092000114, 9.462807656000166],
              [20.824760438000112, 9.451066018000176],
              [20.827669144000083, 9.44053173200001],
              [20.82732963600006, 9.43042183],
              [20.81741142300018, 9.428004265000084],
              [20.804029464000052, 9.443004608000024],
              [20.793170930000088, 9.44172573000003],
              [20.780359267000108, 9.429391862000045],
              [20.768112183000028, 9.428094864000059],
              [20.767000199, 9.424633027000027],
              [20.769899367000107, 9.414561272000071],
              [20.768140792000167, 9.407876969000085],
              [20.76081085100003, 9.401125908000097],
              [20.748109818000103, 9.399359703000073],
              [20.740430831000083, 9.402252197999985],
              [20.73354721100003, 9.397342683000147],
              [20.728939055000126, 9.39659595500018],
              [20.719150544000172, 9.401987076000125],
              [20.71289062599999, 9.40236759100003],
              [20.705350875000136, 9.395384789000161],
              [20.691875458000084, 9.39912033100012],
              [20.666889190000063, 9.398347855000168],
              [20.66276931700014, 9.395306588000153],
              [20.652019501000098, 9.367374420999965],
              [20.650209426000174, 9.346905709000168],
              [20.64772033700018, 9.342277528000125],
              [20.641279220000058, 9.339901924],
              [20.630390166999973, 9.340910911000094],
              [20.623529434000147, 9.33462142899998],
              [20.62846946700006, 9.328477859000088],
              [20.63751983600008, 9.326299667000171],
              [20.640571594000164, 9.323120117000144],
              [20.63785934499998, 9.317803383000069],
              [20.604339599000184, 9.31645584000006],
              [20.59966087300012, 9.319841386],
              [20.59421920699998, 9.329420090000156],
              [20.58815193099997, 9.332785606000073],
              [20.58085060100018, 9.342801095000027],
              [20.575780868000038, 9.341355324],
              [20.571489335000138, 9.333490371000039],
              [20.567369462000045, 9.330220223000026],
              [20.56065941000014, 9.32967567500009],
              [20.548530579000158, 9.33732795800006],
              [20.540718078000054, 9.333319665000147],
              [20.534646988000077, 9.33737373299999],
              [20.528640747000054, 9.337065696000025],
              [20.52477073600005, 9.331960679000133],
              [20.527490616000023, 9.319131851],
              [20.523937225999987, 9.307601928000054],
              [20.518199920000143, 9.304538726999965],
              [20.50826835700002, 9.303259849000028],
              [20.49621963499999, 9.304937363000079],
              [20.491239547000077, 9.296830178000164],
              [20.4915294650001, 9.292240143000072],
              [20.49773025600001, 9.278537750999988],
              [20.498050688999967, 9.272110940000175],
              [20.48274993900003, 9.241126060000113],
              [20.47030067500009, 9.221203804000027],
              [20.455919267000127, 9.17346477500007],
              [20.45025253400013, 9.165579795000099],
              [20.443859099000065, 9.15951919600002],
              [20.434232713000085, 9.153878212000109],
              [20.410419465000075, 9.153326989000107],
              [20.39289093100018, 9.150562287000128],
              [20.38783073400009, 9.148427009000045],
              [20.378009796000185, 9.140021324999964],
              [20.353200913000137, 9.128197671000123],
              [20.346271514000136, 9.127642632000118],
              [20.336719513000162, 9.133255005000024],
              [20.331869126000072, 9.132497788000023],
              [20.324569702000076, 9.125506402000099],
              [20.31813049300007, 9.123579980000045],
              [20.30335807800003, 9.121535301000051],
              [20.273637772000143, 9.122096062000026],
              [20.262409209000054, 9.12448692400011],
              [20.247709275000034, 9.13227176700002],
              [20.238689423000153, 9.145898817999978],
              [20.229419708000137, 9.147118568000053],
              [20.22123909000004, 9.151800157000025],
              [20.207397461000085, 9.15064430300015],
              [20.191190719000133, 9.139106750000053],
              [20.17988968000003, 9.137535096000079],
              [20.152761459000146, 9.138971329000128],
              [20.14748001100014, 9.140680313000132],
              [20.139190675000123, 9.138033867000047],
              [20.130638122000107, 9.138138772000104],
              [20.117391586000053, 9.142539978000116],
              [20.11318016100006, 9.145693779000169],
              [20.10890960600011, 9.153210641000044],
              [20.10145950200007, 9.156547547000116],
              [20.093469620000178, 9.149770736999983],
              [20.088079453000148, 9.138895035000019],
              [20.0814609520001, 9.133283615000096],
              [20.07130050600017, 9.132445335],
              [20.057750700999975, 9.14166259700005],
              [20.052650452000137, 9.142505646000075],
              [20.04690933300003, 9.140351296000176],
              [20.035762786000134, 9.128241538000111],
              [20.029769898000097, 9.127002715000174],
              [20.018199921000132, 9.127750397000057],
              [20.01243972800006, 9.12605476300007],
              [20.010242463000054, 9.119132996000133],
              [20.011280059000114, 9.111565589000122],
              [20.007089615000154, 9.102895738000143],
              [19.99789047200005, 9.116397858000141],
              [19.987161636000167, 9.09645271300002],
              [19.980310440000153, 9.091965676000143],
              [19.957490921000044, 9.088792802000114],
              [19.94178009000018, 9.088277818000165],
              [19.93541908200018, 9.084383011000057],
              [19.93271064700002, 9.079343797000035],
              [19.930660248000095, 9.06793022200003],
              [19.927139281000166, 9.060796738000079],
              [19.90493965100012, 9.06831073699999],
              [19.89443016100006, 9.062146186],
              [19.8861904150001, 9.06102657300005],
              [19.87167930700008, 9.065796852000119],
              [19.864530562000027, 9.064538956000092],
              [19.856510162000063, 9.06005096500013],
              [19.849449158000084, 9.053737640000122],
              [19.841970443000093, 9.043975830000136],
              [19.83551025500003, 9.043416023000077],
              [19.82871055600009, 9.04951000200009],
              [19.82246017600005, 9.06434154600015],
              [19.812381744000163, 9.058671951000065],
              [19.798091889000034, 9.056148530000087],
              [19.789270401000067, 9.058538437000038],
              [19.77288055500003, 9.057356834000075],
              [19.763280868000038, 9.05031681100013],
              [19.743659973000092, 9.048855781000043],
              [19.73494911200015, 9.044349670000145],
              [19.721458435000102, 9.044466020000186],
              [19.719909668000128, 9.040575980000028],
              [19.701900482000042, 9.02752971600006],
              [19.683612824000022, 9.029246330000149],
              [19.671991348000176, 9.020756722000101],
              [19.66667938300003, 9.02042579600004],
              [19.658990861000177, 9.023949624000124],
              [19.646970750000094, 9.023949624000124],
              [19.63136863700015, 9.01836586000013],
              [19.614440918000128, 9.021483421000084],
              [19.602001190000067, 9.019403458],
              [19.585880280000083, 9.03088665100006],
              [19.577621460000103, 9.03995132599999],
              [19.568670273000123, 9.036361695000096],
              [19.55919265700004, 9.036660194000035],
              [19.55435943500015, 9.035203934000151],
              [19.54254913400007, 9.023060799],
              [19.535230636000108, 9.018575669000029],
              [19.523019792000127, 9.016304969999965],
              [19.51464080900007, 9.019838333000052],
              [19.51189041000015, 9.031504630000143],
              [19.506759643000123, 9.034178733000147],
              [19.498939514000085, 9.031976700000087],
              [19.497282028000086, 9.021155358000044],
              [19.494087219000164, 9.018344879000097],
              [19.488279344000034, 9.020542144000103],
              [19.479139328000144, 9.014415742000153],
              [19.473758698000097, 9.01822853100009],
              [19.459211349000043, 9.017752648000055],
              [19.458150864000118, 9.02554512100005],
              [19.45075988800005, 9.024958611000045],
              [19.439090729000043, 9.031192780000083],
              [19.431560516000047, 9.025779724000188],
              [19.420669556000064, 9.027757645000179],
              [19.414030075000085, 9.02486801200007],
              [19.401060104000123, 9.0259847640001],
              [19.395669937000093, 9.018297196000162],
              [19.38876915100019, 9.016778945000056],
              [19.379039765000073, 9.017959595000036],
              [19.365741729000035, 9.013095855000131],
              [19.357879638000156, 9.013397216999977],
              [19.35460090700019, 9.015625954000086],
              [19.337860108000143, 9.020339011999965],
              [19.32164955100012, 9.011280060000047],
              [19.30924034200001, 9.007808685000157],
              [19.302310944000112, 9.007898331000149],
              [19.289749146000077, 9.011542320000103],
              [19.275049210000077, 9.011827468000035],
              [19.263679504000095, 9.01780700800009],
              [19.261779785000044, 9.02075862900017],
              [19.252670288000104, 9.025879859000042],
              [19.240877151000177, 9.025899887000094],
              [19.23550987200008, 9.02879238100013],
              [19.225370407000014, 9.027004242000089],
              [19.21836090100004, 9.031473161000122],
              [19.192419052000048, 9.02159023300004],
              [19.187799454000128, 9.021506309000188],
              [19.18033981399998, 9.024818422000067],
              [19.175029755000025, 9.024494172000061],
              [19.169860840000183, 9.01705074300014],
              [19.156972884000027, 9.013831139000047],
              [19.15081977799997, 9.00889778100003],
              [19.141149520000113, 9.007114409999986],
              [19.135187148000114, 9.004479409000055],
              [19.12729072600007, 9.006862640000122],
              [19.104610443000126, 9.008515358000125],
              [19.101116181, 9.009830475000115],
              [19.095619202000023, 9.019604683000068],
              [19.091669082000067, 9.020450592000145],
              [19.077680588000135, 9.014912605000177],
              [19.076040269000032, 9.003856659],
              [19.06654930200017, 9.004599572000132],
              [19.058629991000146, 8.99572658600016],
              [19.050729751000176, 8.997648239000057],
              [19.043170928999984, 8.994293214000038],
              [19.028318405000164, 8.997233392000112],
              [19.018739701000072, 8.99085426300013],
              [19.01359748800013, 8.981803894000109],
              [19.009738922000054, 8.978056908000099],
              [19.004209518000152, 8.977033615000039],
              [18.998750687000097, 8.979832649],
              [18.992729188000055, 8.979239464000045],
              [18.987579346000132, 8.975182533],
              [18.98377227800006, 8.968351364000114],
              [18.981449127000133, 8.953650474000028],
              [18.97748184300019, 8.948372842000026],
              [18.975940704000095, 8.941511155000057],
              [18.96850967500012, 8.94030284900009],
              [18.962350844000127, 8.933777808000173],
              [18.960351943000035, 8.927617074000068],
              [18.957101821000037, 8.927001953000058],
              [18.948169707000147, 8.930654527000115],
              [18.943090439000173, 8.931001663000018],
              [18.933040618000064, 8.9264068600001],
              [18.922630310000045, 8.925955772000066],
              [18.91863060000003, 8.923056603000077],
              [18.91168975800008, 8.912640573000147],
              [18.90533065700015, 8.89784431400011],
              [18.89570045400012, 8.901282311000045],
              [18.888990402000047, 8.900974274000077],
              [18.8866100300001, 8.898040771000069],
              [18.892288208000082, 8.893544198000086],
              [18.89266014100002, 8.88962554900013],
              [18.882299423, 8.881127358000185],
              [18.868299484000147, 8.875700952000045],
              [18.865659713000127, 8.871391297000116],
              [18.867292405, 8.861700058000167],
              [18.86245918300017, 8.852613450000092],
              [18.868619919000082, 8.849253655000041],
              [18.871480941000186, 8.842754365000133],
              [18.8790702820001, 8.841201783000088],
              [18.88385963400009, 8.848218919000033],
              [18.88854980399998, 8.851327897000033],
              [18.900779724000074, 8.850591660000134],
              [18.90624046300013, 8.846788407000133],
              [18.90711975100004, 8.814584733000117],
              [18.909719468000105, 8.806939125000099],
              [18.922979354000176, 8.800428390000093],
              [18.93590927000008, 8.810015679000117],
              [18.946599961000118, 8.812758445000043],
              [18.9522991180001, 8.809180260000176],
              [18.95656013500013, 8.79345131000008],
              [18.96035957300012, 8.788075448000086],
              [18.970380783000053, 8.78140831100012],
              [18.997129441000084, 8.769294738000156],
              [19.000001908000172, 8.770380021000165],
              [19.014469147, 8.765705110000113],
              [19.01963043300003, 8.761439323000047],
              [19.03329086299999, 8.735512733000178],
              [19.041749955000057, 8.727404595000053],
              [19.059520722000173, 8.715789795999967],
              [19.08223915200017, 8.71046829200003],
              [19.09976196300005, 8.699537277000047],
              [19.105089188000022, 8.698719026000106],
              [19.104951859000153, 8.693891525000083],
              [19.109022140000036, 8.685700416000088],
              [19.1158618930001, 8.678248407000183],
              [19.121448517000147, 8.675362587000166],
              [19.12064933700009, 8.668691636],
              [19.113340376999986, 8.664654732000145],
              [19.100479127000085, 8.660973550000051],
              [19.09099960300017, 8.649551392000149],
              [19.083629607000034, 8.649184227000092],
              [19.077779770000177, 8.640813828000034],
              [19.070310593000045, 8.640102387000013],
              [19.06584167400007, 8.637378692000027],
              [19.063449859000116, 8.617356301000086],
              [19.058500290000154, 8.61083793600011],
              [19.04941940400016, 8.60286331200001],
              [19.046060562000093, 8.597061157000041],
              [19.046470640999985, 8.575486184000056],
              [19.042110444000116, 8.561859131000062],
              [19.04225921600016, 8.554286003000186],
              [19.03313064600019, 8.5490636830001],
              [19.031730653000068, 8.537560464000023],
              [19.0290603630001, 8.532458304999977],
              [19.022699357000135, 8.527289391000068],
              [19.01600074800018, 8.527624131000039],
              [19.014009475000023, 8.52322578500008],
              [19.008220673000096, 8.524033548000034],
              [19.002359391000084, 8.517038345000117],
              [18.99167060800005, 8.514759063000156],
              [18.98904800500003, 8.510716438999964],
              [18.997329711000134, 8.499918938000178],
              [18.98854255700013, 8.48932361600015],
              [18.981109618000062, 8.487424850000082],
              [18.972900391000167, 8.481640816000038],
              [18.97093963700013, 8.476631163999969],
              [18.97257995600006, 8.467861175999985],
              [18.970420837000177, 8.464233398000033],
              [18.957160949000126, 8.459942817000012],
              [18.94440269500018, 8.446910859000184],
              [18.937139510000065, 8.442250253000054],
              [18.932390213000133, 8.436615944000039],
              [18.931730270000173, 8.427898408000146],
              [18.924310683999977, 8.426691056000095],
              [18.920949937000046, 8.42079162499999],
              [18.923070908000113, 8.412931442000058],
              [18.917390823000062, 8.406399728],
              [18.919847488000187, 8.403354645000036],
              [18.926958083000102, 8.401355744000114],
              [18.923610687000064, 8.39614772800013],
              [18.923942566999983, 8.390393258000074],
              [18.91949081399997, 8.387738229000036],
              [18.910709382000107, 8.387020112000073],
              [18.908100127000125, 8.384092331000033],
              [18.91024780300006, 8.376919747000045],
              [18.903259278000064, 8.37432289200018],
              [18.900409699000136, 8.370710373000179],
              [18.899570465000124, 8.364068985000074],
              [18.892000198, 8.36607933100015],
              [18.883649826000124, 8.364201546000118],
              [18.887639999000157, 8.356987953000043],
              [18.87977981500012, 8.356246948000091],
              [18.873369217000118, 8.348349571000085],
              [18.876201630000082, 8.341163635999976],
              [18.8683795930001, 8.342029572000058],
              [18.857187271000043, 8.347339631000011],
              [18.85383987400013, 8.341901779000068],
              [18.85712051300004, 8.323905944999979],
              [18.85092926100009, 8.30543613499998],
              [18.840120316000025, 8.296262742000124],
              [18.837179184000036, 8.288975716000095],
              [18.82386970500005, 8.281083108000018],
              [18.80982017400015, 8.267519951000054],
              [18.804410934000032, 8.260712624000178],
              [18.797018052000112, 8.25967788800017],
              [18.78586959900008, 8.254568101000075],
              [18.780490875, 8.249366760000044],
              [18.74807930100002, 8.24067688000008],
              [18.740219115000173, 8.23987674700004],
              [18.734880448000126, 8.237200736999966],
              [18.71058082600007, 8.23159885500013],
              [18.689689635000036, 8.222266197000124],
              [18.68103981000013, 8.213896752000096],
              [18.67362976000004, 8.212171554],
              [18.666620254, 8.20584678800003],
              [18.65769958599998, 8.194954872000153],
              [18.657789230000162, 8.185535431000176],
              [18.652828217000092, 8.176883698000097],
              [18.64134025700008, 8.163732529000072],
              [18.63002014100016, 8.146446229000105],
              [18.628099441000018, 8.141192435999983],
              [18.628639222, 8.131079674000091],
              [18.637050628000168, 8.090996743000176],
              [18.63509178200013, 8.083215714],
              [18.62787056000019, 8.078274727000064],
              [18.62434959300009, 8.073502541000096],
              [18.62328910799999, 8.064103126000134],
              [18.62461090100004, 8.059720040000059],
              [18.618049621000182, 8.052468300999976],
              [18.604629517000035, 8.048762322000016],
              [18.601600647000055, 8.046739578000029],
              [18.56955909800007, 8.04560089100005],
              [18.50197982900005, 8.041069984000046],
              [18.481889725000087, 8.038373948000071],
              [18.45676994300004, 8.037813187000154],
              [18.450067519000015, 8.036529541000164],
              [18.424249649000103, 8.03551769400002],
              [18.413150787000177, 8.032687187000022],
              [18.401639937000027, 8.03353977100005],
              [18.342119218000164, 8.028395653000018],
              [18.330369950000033, 8.02855777800005],
              [18.304990768000152, 8.026147842000114],
              [18.274320603000092, 8.0240402230001],
              [18.270370483000136, 8.021106721000024],
              [18.25355911300005, 8.022253036999984],
              [18.19175720200002, 8.017798424000091],
              [18.16407203700004, 8.014719009000146],
              [18.122339249000106, 8.01296615700005],
              [18.04674720700018, 8.011183739000046],
              [18.031982423000102, 8.009303093000028],
              [18.018102645000113, 8],
              [18.000001907000126, 7.985908032000111],
              [17.995649338000135, 7.992784024000059],
              [17.94490814200003, 7.962803841000095],
              [17.930620193000095, 7.959417820000112],
              [17.921638490000078, 7.958611012000176],
              [17.847490310000012, 7.965195179000034],
              [17.797529221000104, 7.97224187900008],
              [17.772430420000035, 7.973473072000104],
              [17.733169556000178, 7.978384972000072],
              [17.716339112000128, 7.978588103999982],
              [17.713100433000136, 7.977459908000071],
              [17.707302094000056, 7.97015380900018],
              [17.703380585000048, 7.969491005],
              [17.697679520000065, 7.978705883999965],
              [17.69401168800016, 7.981709957000135],
              [17.68320083700013, 7.984073163000062],
              [17.671670914000117, 7.98276710499999],
              [17.658260345000144, 7.974132062000081],
              [17.657079696999972, 7.969548225000153],
              [17.658880234000037, 7.962421893999988],
              [17.648880006000184, 7.945732117000091],
              [17.64541053800002, 7.94345903500016],
              [17.633211137000046, 7.945368767000048],
              [17.62694931100009, 7.93737602300007],
              [17.616519927000127, 7.926885128000095],
              [17.610069275, 7.92600584000013],
              [17.60135078400009, 7.93271493900005],
              [17.596517562000088, 7.932744980000166],
              [17.585180283, 7.923862934000113],
              [17.578659058000028, 7.912428856000133],
              [17.574491499999965, 7.909009933000164],
              [17.5671195970001, 7.908824920000029],
              [17.56395912200003, 7.919631004000109],
              [17.561670304000188, 7.922627926000075],
              [17.551309586, 7.923150063000037],
              [17.547159195000063, 7.921339988999989],
              [17.54228019800007, 7.914711953000165],
              [17.538530350000144, 7.903950214000133],
              [17.538690567000117, 7.891098022000051],
              [17.53681945800014, 7.887207984000042],
              [17.530832292000184, 7.886784077000073],
              [17.523490905000187, 7.890958787000159],
              [17.515068053000107, 7.86874914200007],
              [17.511144639000065, 7.86762380600004],
              [17.505649567000148, 7.873623848000023],
              [17.485450745000094, 7.885682105],
              [17.476741790000062, 7.892846108000072],
              [17.46776962200005, 7.894046784000125],
              [17.45964050300006, 7.883769036000103],
              [17.449052810000012, 7.883371830000101],
              [17.447170257000153, 7.877645016000031],
              [17.449899674000108, 7.871891976000086],
              [17.445730209000033, 7.866178036000179],
              [17.438821793000045, 7.867598058000056],
              [17.433120728000063, 7.877271175999965],
              [17.42112922600012, 7.875504018000129],
              [17.41811943000016, 7.871390820000158],
              [17.42428970400016, 7.863092899000037],
              [17.423570633000054, 7.858507157000076],
              [17.415300368000146, 7.861310006000053],
              [17.408870697000054, 7.866167068000152],
              [17.404449462000116, 7.8579301850001],
              [17.404850006, 7.847371102000125],
              [17.402990341000077, 7.843019963000131],
              [17.398380280000083, 7.843736172000149],
              [17.39359092800015, 7.850877762000039],
              [17.376289368000187, 7.846621036999977],
              [17.36870956500013, 7.849647999000183],
              [17.365249633000076, 7.848750115000144],
              [17.359207152000067, 7.840293884000062],
              [17.345399857000075, 7.841062069000145],
              [17.34123039200017, 7.837412834000077],
              [17.335849762000123, 7.822298050000029],
              [17.328010560000052, 7.820964813000103],
              [17.314697266000053, 7.82838487600003],
              [17.30870056100008, 7.827733995000131],
              [17.304529189999982, 7.823624135000046],
              [17.303350449000106, 7.817433834999974],
              [17.304231644000083, 7.809395790000053],
              [17.300981520999983, 7.805513859000143],
              [17.283929825000087, 7.80354499900011],
              [17.28183937100016, 7.801261902000135],
              [17.283649444000105, 7.793907165000121],
              [17.281560898000123, 7.791395187000092],
              [17.274192811000034, 7.790977955000017],
              [17.27070045500011, 7.785257817000115],
              [17.264711379000175, 7.784144880000156],
              [17.263759612, 7.779789926000092],
              [17.268987655000103, 7.76690578500012],
              [17.269420625000066, 7.761624814000072],
              [17.26708030700007, 7.754520894000052],
              [17.260120391999976, 7.74515199699999],
              [17.254600525000058, 7.746329785000114],
              [17.24407959000007, 7.759473801000183],
              [17.2378807070001, 7.763867855000115],
              [17.232578278000062, 7.763442040000143],
              [17.228410721000103, 7.758873940000171],
              [17.225370408000174, 7.749938012000143],
              [17.21703910800005, 7.742181778000031],
              [17.220199586000092, 7.729766846000075],
              [17.213731765000034, 7.725903988000027],
              [17.209100723, 7.72087907800011],
              [17.198270798000067, 7.719561101000011],
              [17.169181823000088, 7.70687103400013],
              [17.16592025700004, 7.699543953000045],
              [17.17415046800005, 7.688477994000095],
              [17.162830353000174, 7.681193828000119],
              [17.162542344000087, 7.670178889999988],
              [17.160448074000158, 7.666747094000073],
              [17.139240265000183, 7.661815167000157],
              [17.132110596000132, 7.663229944000079],
              [17.125909805000163, 7.667398929000115],
              [17.115659714, 7.687655926000105],
              [17.110599518000186, 7.689291955000044],
              [17.10206985500008, 7.688418866000177],
              [17.09656906000015, 7.69189500900012],
              [17.088270186000045, 7.690331936000177],
              [17.08776092500011, 7.680693150000082],
              [17.09184074400008, 7.668275833],
              [17.090639115000045, 7.660477162],
              [17.079120635000038, 7.657555103000107],
              [17.06734275800011, 7.651422023000123],
              [17.06295967, 7.650987148000127],
              [17.049169541000083, 7.655193805000124],
              [17.044080735000023, 7.650401115000079],
              [17.04240989700014, 7.638241768],
              [17.04031944200011, 7.635039808000101],
              [17.005319594000184, 7.634770870000068],
              [16.993560791000107, 7.631848813000147],
              [16.98712921200007, 7.635557175000031],
              [16.981830596000066, 7.634208204000061],
              [16.975120544000163, 7.62781715400007],
              [16.963409423000087, 7.635454177000042],
              [16.956970216000116, 7.635949135000146],
              [16.93736076400012, 7.628935814000101],
              [16.928350448000174, 7.622785091000026],
              [16.92464065600018, 7.617754936000097],
              [16.9278106700001, 7.607635021000078],
              [16.922510146000093, 7.605597973000101],
              [16.905290604000186, 7.616250038000146],
              [16.900920867000025, 7.616500854000094],
              [16.898126601000115, 7.610321999000178],
              [16.905660629000124, 7.597191812000119],
              [16.904478074000053, 7.591917992000049],
              [16.895671844000105, 7.579106807000073],
              [16.899080276000063, 7.571284770000091],
              [16.907360076000145, 7.569403172000023],
              [16.91007804900005, 7.561583042999985],
              [16.901750564000167, 7.552902222],
              [16.891841889000034, 7.550889015000166],
              [16.88359069800009, 7.557590008000091],
              [16.874139785000125, 7.556263924000177],
              [16.86973953300003, 7.55146408100012],
              [16.86992073, 7.541359901000078],
              [16.863447189000055, 7.536344051000185],
              [16.850780487000065, 7.535261155000114],
              [16.843849183000145, 7.529787063000129],
              [16.83489036600008, 7.532817840000178],
              [16.82291030900012, 7.532422067000027],
              [16.814870835000022, 7.535677909000128],
              [16.81121063300003, 7.540747167000177],
              [16.80965232800014, 7.550168991000078],
              [16.800699233000046, 7.555726050999965],
              [16.79635047900007, 7.561717987000179],
              [16.797580719999985, 7.577322961000107],
              [16.7939300540001, 7.585377216000097],
              [16.774700165000183, 7.606597900000168],
              [16.76185035800006, 7.617220878000182],
              [16.75152015600014, 7.622326852000015],
              [16.74808883600008, 7.628543853999986],
              [16.738679885000067, 7.633183956000039],
              [16.73366165200008, 7.642620088000115],
              [16.72677993700006, 7.650231839000071],
              [16.711271285000123, 7.65148305800011],
              [16.690179825000087, 7.650875092000092],
              [16.68511963000003, 7.653194904000031],
              [16.6791801440001, 7.663325785999973],
              [16.67250823900008, 7.664967060000095],
              [16.667230606000146, 7.668667794000044],
              [16.66566085800008, 7.675559997000164],
              [16.66666031, 7.693921089000071],
              [16.66535949700011, 7.709308148000162],
              [16.67028808500004, 7.729712964000044],
              [16.66801834099999, 7.73821783000011],
              [16.64965248100009, 7.748178005000113],
              [16.63841056900003, 7.758103847000143],
              [16.62714958099997, 7.762751101999982],
              [16.617689133000056, 7.75866699300019],
              [16.612390519000087, 7.758234023000057],
              [16.594480515000043, 7.768878937000125],
              [16.596809387000178, 7.774837016000049],
              [16.595279693000066, 7.792975903000126],
              [16.59807014600011, 7.798242093000113],
              [16.597869874000082, 7.804899215000091],
              [16.591449738000165, 7.812046051999971],
              [16.59128189000012, 7.825130940000122],
              [16.58371734600007, 7.83388710100013],
              [16.58558082600007, 7.837781906000032],
              [16.58679962200017, 7.851545811000165],
              [16.594449996000094, 7.862063886000101],
              [16.598169328000154, 7.870309830000053],
              [16.59544944800018, 7.878355980000038],
              [16.577438354000094, 7.869952203000082],
              [16.563619614000174, 7.868413926000073],
              [16.560840606000113, 7.864756107000176],
              [16.563110352000137, 7.859466076999979],
              [16.560560226000177, 7.854657174000124],
              [16.55273056100009, 7.854238034000048],
              [16.546520232999967, 7.856791019000184],
              [16.542139053000028, 7.855895042000043],
              [16.53496932900009, 7.848354815999983],
              [16.530349732000047, 7.84631300000018],
              [16.518831253000144, 7.845677853000098],
              [16.516059875000167, 7.842937947000053],
              [16.514139175000025, 7.827339174000031],
              [16.508810042999983, 7.819099903000165],
              [16.49563026499999, 7.808609963000151],
              [16.494659425000123, 7.797135831000048],
              [16.4890995020001, 7.790277957],
              [16.45982933000016, 7.784906865000039],
              [16.45410919200009, 7.792510032000052],
              [16.457359315000133, 7.798690796000017],
              [16.455297470000176, 7.802371979000043],
              [16.438959122000142, 7.805203914000174],
              [16.42387962300006, 7.779338837000125],
              [16.414640426000062, 7.774100781000186],
              [16.412538529000187, 7.767912864000039],
              [16.413909911000133, 7.764235019000125],
              [16.42033958500008, 7.75984478100014],
              [16.426269531000116, 7.746733189000111],
              [16.42417907700019, 7.743299008000065],
              [16.416799544000014, 7.740580083000111],
              [16.415620804000014, 7.734158993000108],
              [16.41743087800006, 7.727723121000167],
              [16.42523002700011, 7.721028805000117],
              [16.430519104000098, 7.718708993000064],
              [16.42587089400007, 7.709088803000043],
              [16.41893958999998, 7.70384216399998],
              [16.410379411000065, 7.694240094000065],
              [16.40665054300007, 7.685763837000138],
              [16.394649506000178, 7.678245067000091],
              [16.39277076800016, 7.671136857000136],
              [16.38471031299997, 7.67117500400019],
              [16.37548065300018, 7.665937901],
              [16.372259140000153, 7.666870117000087],
              [16.360969543000124, 7.66439676300007],
              [16.354070664000176, 7.666954041000054],
              [16.347600937000152, 7.661016940000081],
              [16.34137916500015, 7.661962987000095],
              [16.31185913100012, 7.651078224000059],
              [16.30150794900004, 7.651813031000188],
              [16.29665946900019, 7.647934914000189],
              [16.292230607000022, 7.636933803999966],
              [16.277690888000052, 7.62781715400007],
              [16.2617797850001, 7.623528003000047],
              [16.25114822400019, 7.613244057000031],
              [16.235460282000133, 7.606657983000048],
              [16.233169557000053, 7.608273983000174],
              [16.21108055200017, 7.611354828000117],
              [16.2041893010001, 7.61506080700002],
              [16.196359634000032, 7.615783215000135],
              [16.185531617000038, 7.61445188600004],
              [16.180690765000065, 7.611949921000019],
              [16.172819137000147, 7.602343083000108],
              [16.16357994100008, 7.594577789000084],
              [16.149309157, 7.595788001000017],
              [16.139629363999973, 7.594911100000047],
              [16.131330490000096, 7.590813160000096],
              [16.10461998, 7.59161806100019],
              [16.087810516000047, 7.589165211000079],
              [16.066137315000162, 7.581911088000027],
              [16.05668067900018, 7.577589036000177],
              [16.049278260000108, 7.57073402400016],
              [16.04324913000005, 7.559968948000119],
              [16.039047241000105, 7.543455124000161],
              [16.03900909400005, 7.53381204700014],
              [16.035770417000037, 7.530841828000121],
              [16.027009964000058, 7.529936790000022],
              [16.023080825000136, 7.522858143000065],
              [16.01753997800006, 7.519895077000058],
              [16.010349273000088, 7.505692004000025],
              [15.997890472000165, 7.498627186000078],
              [15.99003124300009, 7.49039220700007],
              [15.98104858500011, 7.489282130000106],
              [15.974808693, 7.483339787000034],
              [15.95110130400019, 7.483665944000109],
              [15.931049347000112, 7.478697777000036],
              [15.918588638000188, 7.470712185000082],
              [15.909370422000052, 7.467075825000165],
              [15.883561134000104, 7.460291862000076],
              [15.863961221000125, 7.453022956000098],
              [15.8457708360001, 7.452176094000038],
              [15.840239524000026, 7.450592042000039],
              [15.83401012500019, 7.445563794000066],
              [15.816740991000074, 7.445404054000164],
              [15.80959034, 7.441069126000116],
              [15.799969673000078, 7.454885005999984],
              [15.785288812000147, 7.461888791000149],
              [15.773529053000118, 7.462793827000041],
              [15.766379356000186, 7.457770824000022],
              [15.759031296000046, 7.463768959999982],
              [15.758139610000171, 7.470891953000034],
              [15.755389213000058, 7.474575043000129],
              [15.746179582000025, 7.476218223000046],
              [15.742970466000088, 7.478527069000108],
              [15.731540681000183, 7.50061416599999],
              [15.727641106000021, 7.504992009000091],
              [15.729861260000064, 7.516184806000126],
              [15.727951050000115, 7.519134044000055],
              [15.660038948000022, 7.517570973000147],
              [15.64806938200013, 7.515510082000048],
              [15.639550210000095, 7.516665936000152],
              [15.622051240000133, 7.514146805000166],
              [15.560359002000098, 7.510505200000011],
              [15.546549797000125, 7.511202812000022],
              [15.507479667000098, 7.527468205000162],
              [15.516850471000055, 7.532179833000043],
              [15.522878648000074, 7.544785976000128],
              [15.527720452000153, 7.546373845000119],
              [15.54065227600006, 7.556890011000178],
              [15.539171218999968, 7.558659554000144],
              [15.52802562700009, 7.55059814599997],
              [15.524147033000133, 7.545909405999964],
              [15.51764392800004, 7.54481268],
              [15.512718200000165, 7.531980991000125],
              [15.503376006999986, 7.527436733],
              [15.497773171000063, 7.526890755000125],
              [15.491051674000175, 7.514957906],
              [15.494228363000047, 7.510744096000053],
              [15.4885482790001, 7.502675057999966],
              [15.478652001000171, 7.496681214000034],
              [15.477466583000137, 7.487847805],
              [15.46763897000011, 7.480542183000068],
              [15.461620330000017, 7.478684903000044],
              [15.463618279000059, 7.471504689000142],
              [15.458147050000093, 7.463573456000177],
              [15.45578575200011, 7.454119682999988],
              [15.442090988000132, 7.451027870000075],
              [15.437719345000176, 7.43605470599999],
              [15.432665826000118, 7.430123806000097],
              [15.426094055000021, 7.427093506000176],
              [15.42622280099999, 7.416947366000045],
              [15.415151595000054, 7.408606052000152],
              [15.402418136000108, 7.396746159000031],
              [15.392184257000054, 7.395236969000052],
              [15.381520272000103, 7.377093316000128],
              [15.37356662700006, 7.374063016000036],
              [15.365748405000147, 7.367374420000147],
              [15.359036446000175, 7.35964965800008],
              [15.348383904000059, 7.35275602300004],
              [15.342020988000058, 7.349999906000164],
              [15.3352394100001, 7.340962886999989],
              [15.328806876999977, 7.337654590000113],
              [15.317393302000028, 7.327240467000081],
              [15.314143182000066, 7.326276779000068],
              [15.299409866000133, 7.314484119000099],
              [15.293948173000047, 7.313452244000075],
              [15.291732789000037, 7.308484078000049],
              [15.285923957000136, 7.305588722000152],
              [15.28066635100015, 7.298828126000103],
              [15.275064467999982, 7.294827938000083],
              [15.269598962000032, 7.287652970000067],
              [15.263583183000094, 7.285240651000038],
              [15.263096810000093, 7.281099797000081],
              [15.254730225000174, 7.275237560000107],
              [15.245120048000047, 7.273379326000168],
              [15.245532037000089, 7.267167092000079],
              [15.24034404800011, 7.260336399000039],
              [15.23895835900015, 7.254953384999965],
              [15.230452538000065, 7.24577808500004],
              [15.224645614999986, 7.245711804000166],
              [15.22007942200014, 7.239281178000113],
              [15.221944808999979, 7.233496189000107],
              [15.21565246700004, 7.229150773000072],
              [15.216755866000085, 7.223145009000177],
              [15.222008705000121, 7.224385262000055],
              [15.234099389000107, 7.21506118800005],
              [15.224349977000031, 7.205195428000138],
              [15.224830627000017, 7.19877624500009],
              [15.216601371000024, 7.188840867000124],
              [15.208509445000175, 7.176489353000079],
              [15.210025788000166, 7.167515755000125],
              [15.211744309000153, 7.146739006000132],
              [15.215404510000042, 7.142112732000157],
              [15.220033645000171, 7.141351224000175],
              [15.223482131000026, 7.126717091000046],
              [15.211247445000083, 7.11961317000015],
              [15.192645073000108, 7.085937977000128],
              [15.191745759000128, 7.083246230999976],
              [15.194022178000068, 7.072960854000144],
              [15.188768387000152, 7.067372322000097],
              [15.176471711000033, 7.072484970000176],
              [15.168316841000149, 7.067725181000071],
              [15.161750793000067, 7.061308383000096],
              [15.158224106000034, 7.051232338000148],
              [15.159811020000063, 7.045778751],
              [15.153799056000025, 7.041225434000126],
              [15.15165424300011, 7.032114505000095],
              [15.144467354000085, 7.027078152],
              [15.141976357000146, 7.016173363000064],
              [15.142663956000092, 7.002920627000094],
              [15.13637542700019, 6.998021603000098],
              [15.136994361000063, 6.987737178000089],
              [15.132983208000041, 6.970620156000109],
              [15.13788032499997, 6.945770263999975],
              [15.138984679000032, 6.943491935000054],
              [15.128622055999983, 6.938318253000091],
              [15.114456177000022, 6.914991857000075],
              [15.107059478000053, 6.890213491000168],
              [15.103950500000053, 6.884209157000043],
              [15.096075058, 6.873788356000148],
              [15.092550278000033, 6.862193108000156],
              [15.081288338000093, 6.83638000600007],
              [15.07520961800003, 6.824922561000051],
              [15.06809520700017, 6.814915180000071],
              [15.063464165000141, 6.784061909000172],
              [15.060701371000164, 6.77840232900013],
              [15.050111770000171, 6.764046191000148],
              [15.040260315000126, 6.761562347000165],
              [15.016115189000061, 6.7693734180001],
              [15.009879112000021, 6.76358890500012],
              [14.964859962000162, 6.751347066000051],
              [14.96163749600015, 6.749874115000125],
              [14.946359635000022, 6.712691785000118],
              [14.92942619400003, 6.673299314000133],
              [14.886278152000045, 6.576564313000063],
              [14.85096263800017, 6.492620468000155],
              [14.82981300400013, 6.4515666960001],
              [14.806182862000185, 6.408302784000114],
              [14.80140209300015, 6.401122093000083],
              [14.8128232950001, 6.364129066000032],
              [14.790207863000091, 6.328779220000115],
              [14.743775367000183, 6.256798267000079],
              [14.739759444000185, 6.250890255000115],
              [14.729298592000077, 6.245419026000093],
              [14.69699096700009, 6.23434353000016],
              [14.59234619200015, 6.197498321000069],
              [14.572061539000174, 6.191383839000025],
              [14.567841531000056, 6.193390846000114],
              [14.563021660000118, 6.19110822600004],
              [14.547831536000047, 6.178898812000057],
              [14.540875435000146, 6.169979573000092],
              [14.52983188700017, 6.164206506000141],
              [14.528028488000075, 6.15871000300001],
              [14.512708665000048, 6.143550394999977],
              [14.508036613000172, 6.128199578000078],
              [14.499671936000027, 6.122964857999989],
              [14.489837646000126, 6.114712716000042],
              [14.486289024000143, 6.114843368000038],
              [14.484085083000139, 6.108608723000089],
              [14.473314286000061, 6.100087166000151],
              [14.472046852000176, 6.095729827000127],
              [14.462276459000066, 6.091096402],
              [14.447148323000022, 6.088802815000065],
              [14.444274903000121, 6.08343839600019],
              [14.429348946000118, 6.079803943000115],
              [14.425871848000043, 6.075980664000099],
              [14.420998573000134, 6.063577653000152],
              [14.419198989999984, 6.055869102000145],
              [14.420143127000074, 6.04970455300014],
              [14.417740822000155, 6.041794300000163],
              [14.418822288000115, 6.03246641200019],
              [14.422510148000072, 6.026907922000021],
              [14.428000450000127, 6.025707246000138],
              [14.430008887000099, 6.011567116000094],
              [14.434177400000124, 6.008223057000066],
              [14.434321404000173, 5.998103620000052],
              [14.440751075000037, 5.994491578000179],
              [14.462123872000177, 5.974475385000176],
              [14.465341568000099, 5.969452382000156],
              [14.467761039000038, 5.958732127000189],
              [14.477214814000035, 5.941987515000108],
              [14.483308792000116, 5.938106061000156],
              [14.485523223000087, 5.932746887000064],
              [14.484189033000177, 5.927786350000076],
              [14.489622116000021, 5.914522648000059],
              [14.49698829600004, 5.91064214700009],
              [14.498599053000191, 5.905349256000079],
              [14.51038456100008, 5.898524284000075],
              [14.513466835000031, 5.894170762000158],
              [14.526581765000174, 5.896595001000094],
              [14.539428712000074, 5.900425435000102],
              [14.548462868000058, 5.901974202000076],
              [14.559507370000176, 5.90037489000008],
              [14.567133903000126, 5.90379858100016],
              [14.571479796000062, 5.910101414000167],
              [14.580976486000168, 5.919424058000061],
              [14.59121322599998, 5.923653603000162],
              [14.599580764999985, 5.922855854000147],
              [14.607014656000047, 5.915287972000158],
              [14.614921569000103, 5.901890279000156],
              [14.620351791000132, 5.889361859000076],
              [14.621029853000152, 5.875891685],
              [14.618490219000137, 5.871332647000088],
              [14.616294861000029, 5.85142755600009],
              [14.616166115000055, 5.843653202000041],
              [14.622875214000146, 5.818124294000029],
              [14.620871543000021, 5.81302976700016],
              [14.62496090000002, 5.801170349000131],
              [14.624363899000116, 5.793261528000073],
              [14.62175846000008, 5.787496566000073],
              [14.624105453000027, 5.779925346000141],
              [14.622778893000088, 5.762365818000149],
              [14.624592780000114, 5.751376153000081],
              [14.623127937000163, 5.739914894000094],
              [14.625205039000093, 5.734889983000073],
              [14.625277520000111, 5.726446152000165],
              [14.628096580000147, 5.713111400000116],
              [14.627564430000177, 5.708017826],
              [14.621889114000055, 5.690522194000153],
              [14.617612838000127, 5.683013440000082],
              [14.60766029400014, 5.659081459000106],
              [14.608201980000047, 5.649364471000126],
              [14.605533600000058, 5.638103485000102],
              [14.59938621599997, 5.627041340000062],
              [14.597789764000026, 5.614709377000168],
              [14.59190845600017, 5.606126785000186],
              [14.591518403000123, 5.59077978300013],
              [14.592393876000187, 5.581263542000102],
              [14.59855079700003, 5.576978684000153],
              [14.605983733000073, 5.564987659],
              [14.607927322000023, 5.559292317000029],
              [14.606462477999969, 5.54930591700014],
              [14.608938217000116, 5.547430992000159],
              [14.612292289000152, 5.532957553000131],
              [14.623267173000158, 5.52378273100004],
              [14.629961967000042, 5.513533593000091],
              [14.6300983430001, 5.509177209000029],
              [14.624015808000024, 5.503208637000057],
              [14.61860370700009, 5.495096207000074],
              [14.618941308000046, 5.489935875000185],
              [14.614863395999976, 5.486582755000029],
              [14.61975288300016, 5.476398945000085],
              [14.615072251000129, 5.474184514000115],
              [14.610397340000134, 5.464396953000062],
              [14.602911949000088, 5.457355023000048],
              [14.605525017000161, 5.450185777000172],
              [14.600517272000104, 5.43785095200019],
              [14.598983765000185, 5.430008889000135],
              [14.600597381000114, 5.416606426000101],
              [14.593250274000127, 5.403935432000083],
              [14.589570999000102, 5.406479359000173],
              [14.580544471000053, 5.40426159000009],
              [14.573728562000156, 5.397554874000093],
              [14.574199676000035, 5.392998220000038],
              [14.572271346000036, 5.378252984000028],
              [14.56578731500008, 5.374428272000102],
              [14.560513496999988, 5.362629414000139],
              [14.561986924000166, 5.359279632000096],
              [14.560858726000049, 5.347550870000077],
              [14.557917596000038, 5.346275330000026],
              [14.55638504100017, 5.339371204000088],
              [14.55144119200014, 5.332799912000098],
              [14.552919389000124, 5.323418617000073],
              [14.547909737000055, 5.316578865999986],
              [14.549457549000067, 5.30310964500012],
              [14.538297654000132, 5.295662404000098],
              [14.53709602300006, 5.292980671000123],
              [14.52714443100001, 5.28379488000013],
              [14.529488564000076, 5.273755075000111],
              [14.53611278600016, 5.266991138000151],
              [14.552031517000046, 5.256347179000045],
              [14.558522224000171, 5.248376370000017],
              [14.562601090000157, 5.247374058000048],
              [14.568621635000113, 5.24127960300018],
              [14.569226266000157, 5.236789703000113],
              [14.575914383999987, 5.233242511000071],
              [14.582064630000104, 5.233246804000032],
              [14.591626168000062, 5.230907440000067],
              [14.595774650000124, 5.225146294000183],
              [14.606002807000039, 5.224281789000145],
              [14.609748840000123, 5.221335411000041],
              [14.620777131000182, 5.224357605000023],
              [14.625592232000031, 5.221612931000095],
              [14.63154220600012, 5.22181749400005],
              [14.639366150000171, 5.216125489000092],
              [14.649661065000146, 5.216399192000097],
              [14.65334034000017, 5.212112426000147],
              [14.654212950999977, 5.205276489000084],
              [14.6641950610001, 5.197608471000137],
              [14.680366516000106, 5.142711639000083],
              [14.692684173000089, 5.102640152000049],
              [14.68994903500004, 5.093054772000073],
              [14.684336662000135, 5.087623596000128],
              [14.674379348000116, 5.085474015000159],
              [14.674250602000143, 5.076359272000161],
              [14.675789834000113, 5.070998669000062],
              [14.681674957000098, 5.064232349000122],
              [14.682881356000166, 5.056659699000079],
              [14.681281088999981, 5.05015802500003],
              [14.684961319000024, 5.040576458000146],
              [14.684430122000037, 5.033338069000138],
              [14.687640190000081, 5.028513908000036],
              [14.687845229000061, 5.02006960000017],
              [14.678689957000188, 5.018456937000053],
              [14.683238982999967, 5.009411335000152],
              [14.678695678000054, 5.007331371000021],
              [14.680636000000163, 5.000162001000149],
              [14.67603, 4.990575000000149],
              [14.680576220000034, 4.987829712000178],
              [14.684389247000126, 4.97751123300003],
              [14.691407998999978, 4.9741629990001],
              [14.69549179000012, 4.959755421000125],
              [14.688548, 4.946683],
              [14.691357613000093, 4.939446927000063],
              [14.682941, 4.932271],
              [14.688223000000164, 4.926241999000126],
              [14.693708001000061, 4.914180999000166],
              [14.697718619000057, 4.910161972000083],
              [14.701337, 4.888717],
              [14.705815, 4.884966],
              [14.706218647000071, 4.880009057000166],
              [14.714641572000062, 4.868751049000025],
              [14.711371999000164, 4.858496000000173],
              [14.705159, 4.856884],
              [14.708502770000166, 4.850183964000053],
              [14.70723571700006, 4.843279662999976],
              [14.712784768000063, 4.836647987000163],
              [14.715465000000165, 4.818553000000179],
              [14.720883, 4.802270000000135],
              [14.716609000000119, 4.799051],
              [14.720488549000038, 4.786184788000071],
              [14.71628199900016, 4.782296],
              [14.713346000000172, 4.771236000000101],
              [14.70813800000019, 4.766677000000186],
              [14.701992, 4.76473],
              [14.704667, 4.759504000000163],
              [14.711014747000092, 4.756423950000055],
              [14.711016256000107, 4.74905479600011],
              [14.718635559, 4.74335813600004],
              [14.71870900000016, 4.727608890999989],
              [14.720981597000161, 4.724660397000093],
              [14.719319, 4.704755],
              [14.721791, 4.703079999000124],
              [14.724066734000075, 4.691956044000165],
              [14.720731735000072, 4.679823876000171],
              [14.723411561000148, 4.660924911000109],
              [14.72034500000018, 4.644705000000158],
              [14.725957000000164, 4.640485],
              [14.731706000000145, 4.629697],
              [14.732311, 4.616427000000101],
              [14.737991, 4.608923],
              [14.742734909000035, 4.606109619000051],
              [14.747279, 4.599878],
              [14.755766000000108, 4.584668],
              [14.763847000000112, 4.585073],
              [14.76759, 4.579645000000141],
              [14.773601532000043, 4.575290680000137],
              [14.7798820000001, 4.565909000000147],
              [14.786963000000128, 4.56061700000015],
              [14.789504, 4.553648000000123],
              [14.799256000000128, 4.546345],
              [14.803797722000184, 4.545341014000087],
              [14.806205, 4.535088000000144],
              [14.811149, 4.528186000000176],
              [14.818227768999975, 4.530130864000171],
              [14.833722959000056, 4.521823934000111],
              [14.830185, 4.516730000000166],
              [14.837131, 4.515056],
              [14.842604172000108, 4.517200433000085],
              [14.844543000000101, 4.513516000000152],
              [14.852690828000163, 4.515125953000052],
              [14.855231000000174, 4.503331000000173],
              [14.860775, 4.499378],
              [14.870725631000141, 4.497636796000109],
              [14.877338, 4.486781000000178],
              [14.885219354000014, 4.480415518000029],
              [14.889295000000118, 4.470697],
              [14.90231704700011, 4.469358921000094],
              [14.91700494200012, 4.46406702400003],
              [14.927894, 4.457164000000148],
              [14.934972764000122, 4.455086708000067],
              [14.940248000000167, 4.450999],
              [14.945591, 4.45106599899998],
              [14.946927070000129, 4.441951752000023],
              [14.953872000000104, 4.438869],
              [14.96195125600019, 4.44208669599999],
              [14.98138300700009, 4.43665898900008],
              [14.985055973000044, 4.428482038000027],
              [14.99467200000015, 4.425935000000152],
              [15.001149178000162, 4.426337720000049],
              [15.004622, 4.423522000000162],
              [15.013101578000033, 4.424728870000024],
              [15.017776, 4.415413000000115],
              [15.018576986000085, 4.40254409400012],
              [15.022579695000047, 4.397724206000078],
              [15.018777000000114, 4.392625],
              [15.024852753, 4.386526107],
              [15.02899200000013, 4.377746000000172],
              [15.030862000000127, 4.369905000000131],
              [15.039871668000046, 4.37512977300014],
              [15.049823709000066, 4.36286635100015],
              [15.045684000000108, 4.357304],
              [15.058101654000097, 4.344970703000058],
              [15.065445000000182, 4.330158],
              [15.071453, 4.324997],
              [15.076126, 4.315413],
              [15.081066, 4.316618],
              [15.090678999000033, 4.300800001000027],
              [15.09468364800017, 4.290076255000031],
              [15.094081878000111, 4.284312725000063],
              [15.095616, 4.273924],
              [15.092077000000131, 4.269501000000162],
              [15.099621000000127, 4.264606999000137],
              [15.096548, 4.251941000000102],
              [15.09888399900018, 4.242623999000102],
              [15.092875000000106, 4.240614000000107],
              [15.094343012000024, 4.235051998000188],
              [15.10015099899999, 4.234178999000164],
              [15.099483, 4.228148],
              [15.10422099900012, 4.223254000000111],
              [15.10649, 4.217155000000162],
              [15.099346, 4.210253],
              [15.101481, 4.202143],
              [15.103950999000176, 4.200601],
              [15.103549, 4.18284],
              [15.112291907000042, 4.178416215000027],
              [15.108353886999964, 4.174260828000058],
              [15.110154954000052, 4.168229193000059],
              [15.110687, 4.152478],
              [15.104478, 4.14577700000018],
              [15.10594600000013, 4.135992],
              [15.112085, 4.129825],
              [15.112685000000113, 4.12272],
              [15.122229576000109, 4.12084198000008],
              [15.125165979000144, 4.112665115000027],
              [15.129637939000133, 4.111860015000048],
              [15.136378, 4.106296],
              [15.136577, 4.096377000000189],
              [15.142582990000165, 4.089070012000093],
              [15.145517, 4.080223000000103],
              [15.147318, 4.069097],
              [15.158799172000045, 4.07532787299999],
              [15.169144630000176, 4.074789525000085],
              [15.179088593000017, 4.070699215000047],
              [15.17882, 4.065203000000111],
              [15.184758848000115, 4.058298912000112],
              [15.170273780000173, 4.05186796300012],
              [15.147914000000185, 4.046041],
              [15.139904022000167, 4.038134099000104],
              [15.12502, 4.035322],
              [15.116805407000072, 4.032306925000114],
              [15.108868840000014, 4.03183895300009],
              [15.098589896000078, 4.028891086000158],
              [15.095387, 4.029495],
              [15.083505630000047, 4.023798943000088],
              [15.076349000000164, 4.021727000000169],
              [15.078199, 4.015439000000185],
              [15.086789999000132, 4.001112000000148],
              [15.091626480000173, 3.996673520000059],
              [15.102635, 3.958969999000033],
              [15.108805756000152, 3.943220409000048],
              [15.120982999000034, 3.924283000000173],
              [15.143999099000155, 3.886495591000085],
              [15.177141916000153, 3.830430882000144],
              [15.22395500000016, 3.751898],
              [15.24889694899997, 3.710672085000056],
              [15.253298759000074, 3.707319975000132],
              [15.28457736900009, 3.674737691000075],
              [15.329457282000078, 3.626600505000113],
              [15.35006236999999, 3.60561490200007],
              [15.391668320000065, 3.56136369800015],
              [15.446805000999973, 3.503700493999986],
              [15.494337083000119, 3.457700253000041],
              [15.501468658000022, 3.448581696000076],
              [15.549921989999973, 3.391386748000116],
              [15.575647353000022, 3.361815930000034],
              [15.623160362000078, 3.306562663000079],
              [15.647681235999983, 3.277393579000147],
              [15.723567962000175, 3.190217734000157],
              [15.746217727999976, 3.163327217000187],
              [15.792780876000108, 3.108941556000104],
              [15.804039001000149, 3.097808124000153],
              [15.81031227200009, 3.106113433000132],
              [15.821909905000155, 3.103423835000172],
              [15.827311516000066, 3.104357958000094],
              [15.838517189000072, 3.111452818000032],
              [15.844582558000184, 3.109906436000188],
              [15.854917527000055, 3.111908675],
              [15.86271381500012, 3.10734510400016],
              [15.865441323000027, 3.100440265000032],
              [15.883375168000043, 3.101229667000155],
              [15.89036941600017, 3.094388009000056],
              [15.900818825000101, 3.074274541000079],
              [15.914134025000124, 3.053957702000162],
              [15.919390678000127, 3.041823388000125],
              [15.930252075000055, 3.037256957000068],
              [15.940037728000163, 3.02116489500014],
              [15.951360702000159, 3.010499715000037],
              [15.958218575000103, 3.000910759000135],
              [15.965948105000166, 2.998558282999966],
              [15.971548080000161, 2.998888493000152],
              [15.977084159000185, 3.002770186000078],
              [15.986350059000188, 3.003030063000153],
              [15.994951249000053, 3.005769729000178],
              [16.006147384000144, 3.004084110000065],
              [16.011077880000073, 3.001533032000168],
              [16.010335922000138, 2.990945579000027],
              [16.017265320000092, 2.989196777000075],
              [16.027193070000123, 2.984362603000136],
              [16.032058715000062, 2.983955861000084],
              [16.034984588000157, 2.97604560800005],
              [16.03491020200005, 2.96726703700017],
              [16.041564941000047, 2.95660567200008],
              [16.04742622300006, 2.950770139000156],
              [16.05394554100019, 2.937964439000041],
              [16.05839347900013, 2.91946482700007],
              [16.062858581000114, 2.919326543000125],
              [16.065187455000057, 2.915169716000037],
              [16.071649551000064, 2.910472631000118],
              [16.078018189000147, 2.880780219000087],
              [16.08280754100008, 2.869316577000177],
              [16.085803986000144, 2.86716938100011],
              [16.08646011400009, 2.855709552000064],
              [16.088788986000054, 2.851351500000078],
              [16.08781814600019, 2.84288811600004],
              [16.091911315000175, 2.840760946000103],
              [16.093032837000067, 2.829769849000115],
              [16.090360642000064, 2.822468041999969],
              [16.077085495000063, 2.807938814000067],
              [16.075281143000154, 2.803182603000096],
              [16.06374740500013, 2.79856944200003],
              [16.057878, 2.794085000000109],
              [16.052013350000095, 2.793554021000091],
              [16.050340652000102, 2.785916091000161],
              [16.054733276000093, 2.779345036000166],
              [16.055589676000068, 2.768219947000091],
              [16.059851264000088, 2.76453148000013],
              [16.05664828099998, 2.759239389000072],
              [16.0621776590001, 2.75722408200005],
              [16.064639963000047, 2.752463862000184],
              [16.06250000800003, 2.743620145000023],
              [16.06575775200008, 2.735575675000177],
              [16.065687, 2.731220000000121],
              [16.059352979000153, 2.72686987899999],
              [16.063344956000094, 2.720969201000116],
              [16.05941009500009, 2.7174880500001],
              [16.05920601000014, 2.711993218000032],
              [16.066801, 2.710713000000169],
              [16.07159614600016, 2.707224369000073],
              [16.079527, 2.707082999000079],
              [16.086786, 2.701515],
              [16.08164787400017, 2.693879844000037],
              [16.08131, 2.690128000000129],
              [16.074306488000047, 2.68075203899997],
              [16.07176780599997, 2.674186946000077],
              [16.070354, 2.658641000000102],
              [16.06514167800009, 2.641021490000071],
              [16.066534043000104, 2.632174730000145],
              [16.07105255000016, 2.617763041999979],
              [16.08109855600003, 2.598656177000123],
              [16.08348465000006, 2.583375216000093],
              [16.081674574999965, 2.570979595000097],
              [16.07833480900007, 2.560260295000091],
              [16.06665420600018, 2.536681652000084],
              [16.06457900900017, 2.524286032000134],
              [16.06936645600007, 2.512018682000132],
              [16.080417634000185, 2.502359867000052],
              [16.083141001000172, 2.492105001000141],
              [16.08319854700011, 2.48051142800017],
              [16.080928803000063, 2.475152254000136],
              [16.072393418000047, 2.464571238000076],
              [16.075052262000156, 2.458270073000165],
              [16.08257675200008, 2.453372001000048],
              [16.08470500100009, 2.448746],
              [16.084697823, 2.438761370000066],
              [16.089824677000024, 2.434401512000136],
              [16.096284930000138, 2.432922029000167],
              [16.10573387100004, 2.416563511000049],
              [16.112779618000104, 2.396789075000129],
              [16.119100571000047, 2.386464119000095],
              [16.13187980600003, 2.371845246000135],
              [16.133340836000173, 2.366885186000047],
              [16.141927719000023, 2.358501672999978],
              [16.148113, 2.346837001000154],
              [16.14944076600017, 2.340335369000059],
              [16.14730453599998, 2.333502054000178],
              [16.147497163000025, 2.326130674000069],
              [16.166585921999967, 2.288053037000168],
              [16.170639000000165, 2.275116993000097],
              [16.168699, 2.264933001000145],
              [16.160429020000038, 2.252542166000183],
              [16.163022995000176, 2.246375084000079],
              [16.17507171699998, 2.23604583700012],
              [16.186988830000075, 2.22786092900003],
              [16.190820695000127, 2.223486901000172],
              [16.195230484000035, 2.223453045000042],
              [16.20875930800014, 2.246668100000136],
              [16.213119507000044, 2.257705927000131],
              [16.265403747000107, 2.356782913000075],
              [16.270908355000074, 2.36988806700009],
              [16.27457428000008, 2.37402391500018],
              [16.286960601999965, 2.399080992000165],
              [16.290401459, 2.402527094000163],
              [16.297969818000126, 2.417928935000077],
              [16.29911995000009, 2.422991038000134],
              [16.33192825200007, 2.487359046000108],
              [16.342250823000143, 2.503676891000112],
              [16.349819183000022, 2.520462990000169],
              [16.417522430000133, 2.650809050000078],
              [16.424858094000115, 2.661839962000101],
              [16.435880661000056, 2.685290099000156],
              [16.460666657000047, 2.73034691800018],
              [16.463190078000025, 2.73264408],
              [16.474441528, 2.754255058000013],
              [16.475820542000065, 2.759772061999968],
              [16.49119949300001, 2.786437988000103],
              [16.49648094300005, 2.798624992000043],
              [16.500379563000024, 2.803450108000106],
              [16.52012825100013, 2.842302084000039],
              [16.524770736000107, 2.880489111000145],
              [16.520889282000155, 2.887860061000026],
              [16.513799667, 2.893393993000132],
              [16.501680374000102, 2.899167061000128],
              [16.490249633000133, 2.909308911000153],
              [16.48958587700008, 2.92288589500015],
              [16.4933795930001, 3.004334926000183],
              [16.503248215000042, 3.021347046000187],
              [16.531459809000182, 3.049602985000149],
              [16.544540405000134, 3.065686941000081],
              [16.54431343200008, 3.070288897000182],
              [16.53883552500008, 3.07950305900016],
              [16.521930695000094, 3.099550963000183],
              [16.512344361000032, 3.120507003000171],
              [16.51099586500004, 3.13592600800007],
              [16.50712013200007, 3.147667885000033],
              [16.505769730000054, 3.159868002000053],
              [16.508989333999978, 3.171135903000049],
              [16.53240585399999, 3.20584511900006],
              [16.53884887600003, 3.22447204700012],
              [16.546884537000096, 3.236886979000076],
              [16.55422973600008, 3.244927883000116],
              [16.558359145000054, 3.253436089000104],
              [16.556549073000042, 3.266324043000111],
              [16.55017280600015, 3.282675029000131],
              [16.554559707000124, 3.305677892000119],
              [16.559379578, 3.313952923000102],
              [16.572921752000127, 3.329579115],
              [16.5770702370001, 3.342689037000071],
              [16.58008956800012, 3.368916988000137],
              [16.580825805000188, 3.396989108000071],
              [16.582920074000185, 3.416088104000039],
              [16.593320846000097, 3.46669697700014],
              [16.596540451000124, 3.477276088000167],
              [16.604812622000054, 3.490149021000093],
              [16.61055183300016, 3.49727106000006],
              [16.64748954700019, 3.529192926000178],
              [16.662851334000152, 3.537679911000112],
              [16.672023774000024, 3.541802884000106],
              [16.687828064000144, 3.542464018000089],
              [16.71304321299999, 3.547940969000024],
              [16.72610282800008, 3.549067020000052],
              [16.745130539000172, 3.556164027000079],
              [16.7545318600001, 3.557066917000157],
              [16.76346015900009, 3.554976941000064],
              [16.77444267300001, 3.549662113000181],
              [16.795049668000047, 3.546169996000117],
              [16.802608490000182, 3.543391944000121],
              [16.820663452000133, 3.521493911999983],
              [16.83349037200003, 3.520318032000034],
              [16.851802825999982, 3.510616065000136],
              [16.860729217000028, 3.511516095000161],
              [16.873359681000068, 3.527137042000106],
              [16.874298095000086, 3.536341906000189],
              [16.86951065, 3.549007892000134],
              [16.86975288400015, 3.553379059],
              [16.87846946700006, 3.559576035000134],
              [16.88900947600007, 3.558402062000084],
              [16.896099091, 3.55194306400017],
              [16.910066605000168, 3.550071954000032],
              [16.932750700999975, 3.552093029000048],
              [16.94605064400008, 3.55804896300009],
              [16.95727920500019, 3.559864998000137],
              [16.960710526000128, 3.55824709],
              [16.976024627000072, 3.540493013000173],
              [16.981748582000023, 3.539098025000044],
              [16.997800827000106, 3.546888113000136],
              [17.004444123000155, 3.546642066000118],
              [17.00923919800016, 3.539036035000095],
              [17.010818482000047, 3.529138088000082],
              [17.029569626000068, 3.513675929000158],
              [17.038963318000185, 3.515034915000172],
              [17.05252075100003, 3.530422926000028],
              [17.058057785000074, 3.548130036000146],
              [17.07937240700005, 3.552911997000138],
              [17.098175047999973, 3.560693027000184],
              [17.11329269400011, 3.559274913000081],
              [17.13209343000011, 3.565442084999972],
              [17.14723014900011, 3.572772026000109],
              [17.157085418000122, 3.575105905000044],
              [17.163457870000173, 3.572552920000135],
              [17.17218780600001, 3.565344095000057],
              [17.176767348000055, 3.565565110000136],
              [17.199235916000134, 3.572181940000121],
              [17.223529816999985, 3.575113058],
              [17.239347458000054, 3.57921505000013],
              [17.24828910700012, 3.583333970000069],
              [17.24945259100008, 3.588623048000159],
              [17.24765968300005, 3.605427981000162],
              [17.257989884000096, 3.614608051000175],
              [17.298809052000138, 3.630148889000111],
              [17.30454063300016, 3.628982067000038],
              [17.31574058500013, 3.619288922000123],
              [17.348693847000163, 3.604933023000058],
              [17.354650498000126, 3.604456902000038],
              [17.36267662000006, 3.606966018000151],
              [17.365892410000015, 3.610179901000038],
              [17.388000489000035, 3.652921916000082],
              [17.394212722000077, 3.661652088000153],
              [17.405231476000154, 3.669445991000089],
              [17.441007615000046, 3.680392981000125],
              [17.451122284, 3.693711995000058],
              [17.461910247000105, 3.699204922000092],
              [17.474060059000124, 3.703082084000073],
              [17.491699218000065, 3.702111006],
              [17.506570815000146, 3.69194102199998],
              [17.533367158000146, 3.689104079],
              [17.543670653999982, 3.685851097000182],
              [17.549390794000033, 3.681000947000143],
              [17.5576000210001, 3.669240951],
              [17.57821083200008, 3.663656949000028],
              [17.587583541000072, 3.658334970000112],
              [17.59260940600018, 3.653487920000032],
              [17.597618104000162, 3.643578053000113],
              [17.606319427000074, 3.641251087000171],
              [17.653280257, 3.639038086000085],
              [17.687660218000076, 3.641463042000169],
              [17.694749833, 3.639141083000027],
              [17.700923920000093, 3.634288073000107],
              [17.7050495150001, 3.634505033000096],
              [17.720657349000078, 3.645271063000052],
              [17.7330493930001, 3.651215076000085],
              [17.738319397000055, 3.650279045000161],
              [17.74517059300007, 3.643124104000151],
              [17.75890541100017, 3.63893795100006],
              [17.787305832000186, 3.634936094000182],
              [17.79575729400011, 3.629153013000121],
              [17.809507370000063, 3.62910795300013],
              [17.81660080000006, 3.62678503900014],
              [17.828977585000132, 3.626744032000033],
              [17.833318711000118, 3.623277902000098],
              [17.840133668000078, 3.605304002000139],
              [17.840303421000044, 3.587124110000104],
              [17.84462928800008, 3.578824997000083],
              [17.84548950200002, 3.563174963000165],
              [17.8479881290001, 3.556952954000053],
              [17.85369491600005, 3.548880101000123],
              [17.8591403960001, 3.532983066000099],
              [17.866701126000066, 3.533188105000079],
              [17.881416320000085, 3.549937010000065],
              [17.89037704500015, 3.557040931000131],
              [17.902301789000035, 3.55930209200011],
              [17.919300080000028, 3.57351493800013],
              [17.92989158600011, 3.589814902000171],
              [17.93267059400017, 3.599011898000128],
              [17.937719346000108, 3.602446079000174],
              [17.943878174000076, 3.599781990999986],
              [17.949340820000145, 3.583077908000178],
              [17.957550050000123, 3.57131290500007],
              [17.969430924000108, 3.560456037999984],
              [17.984981538000113, 3.553497077000031],
              [17.995740890000036, 3.551158905000023],
              [17.99872779899999, 3.553910017000078],
              [18.000001907000126, 3.578872919000162],
              [18.015060425000115, 3.577430964000143],
              [18.024017334, 3.574048043000119],
              [18.048807143999966, 3.56825494900005],
              [18.058399201000043, 3.573163986000054],
              [18.070749282000065, 3.57533192599999],
              [18.087049483000044, 3.571543933000044],
              [18.105150224000113, 3.571455001000118],
              [18.12352180500011, 3.566071034000061],
              [18.133409501000187, 3.561542989000145],
              [18.144697190000045, 3.553342105000183],
              [18.152549743000066, 3.544884921000119],
              [18.163448333000133, 3.527472020000118],
              [18.16625213700013, 3.520126104000156],
              [18.169830322000166, 3.500817061000078],
              [18.176109314000087, 3.488204955000185],
              [18.18049812200013, 3.484095097000136],
              [18.188995361000025, 3.481167078000055],
              [18.214862822999976, 3.485045910000167],
              [18.2246704100001, 3.489495038000086],
              [18.231540679000034, 3.490698099000042],
              [18.24155044600002, 3.50044107400015],
              [18.242889404000152, 3.504595996000035],
              [18.24992370600006, 3.514084100000105],
              [18.259746551000035, 3.549381016999973],
              [18.26262092500002, 3.564367056000151],
              [18.264730454000016, 3.58809208800011],
              [18.267684937000126, 3.591567994000116],
              [18.274547577000078, 3.593231917000026],
              [18.287157058000048, 3.591943979000064],
              [18.300247193000132, 3.588129043000038],
              [18.308731079000097, 3.587042094000083],
              [18.31376075700007, 3.588920117000157],
              [18.3256187450001, 3.596834898000168],
              [18.33336067300013, 3.60356998400016],
              [18.345935822000115, 3.607577086000049],
              [18.359514236000052, 3.59915995700004],
              [18.37327957100007, 3.596267939000029],
              [18.391210555999976, 3.588804007000135],
              [18.40595054700003, 3.577861071000086],
              [18.41053581200009, 3.577896118000069],
              [18.413446426, 3.586895942000069],
              [18.414014817000066, 3.603935003000061],
              [18.419706344000133, 3.609041930000103],
              [18.425907134, 3.607244016000038],
              [18.43718719500015, 3.599730016000081],
              [18.443609239000182, 3.598627090000093],
              [18.448633194000138, 3.601195097000073],
              [18.453859329000068, 3.607678891000035],
              [18.45511817800002, 3.623572111000044],
              [18.461288452000076, 3.626148939000075],
              [18.467895507000037, 3.631721973000026],
              [18.47534942600015, 3.646739006000075],
              [18.481721877000098, 3.65323209800016],
              [18.49089813200004, 3.651686906],
              [18.500133515000073, 3.64162492700018],
              [18.513999940000133, 3.624691011000039],
              [18.535640716000103, 3.61011290499999],
              [18.544219971000018, 3.596131087000117],
              [18.547548294000023, 3.578428983000038],
              [18.55132102900012, 3.563493967999989],
              [18.558300018000182, 3.549038888000041],
              [18.567754746000162, 3.540129901000171],
              [18.57538032600013, 3.530746936000185],
              [18.587972641000135, 3.498836994000101],
              [18.594999313000073, 3.477019071000143],
              [18.60372924700016, 3.472707034000109],
              [18.635000230000173, 3.471093416000031],
              [18.62300872800006, 3.50800299799999],
              [18.617229462000125, 3.542555094000022],
              [18.617828370000097, 3.554758071000094],
              [18.61601066500009, 3.588818073000027],
              [18.61087417700014, 3.632519960000082],
              [18.606109619000108, 3.659189939999976],
              [18.60164070200011, 3.675731897999981],
              [18.59466362000012, 3.690417051000111],
              [18.591363907000016, 3.703744888000188],
              [18.586999893000097, 3.750999927000066],
              [18.59777832100019, 3.803009987000166],
              [18.598388672000112, 3.814524889000097],
              [18.595228196000107, 3.840745924999965],
              [18.596277238000027, 3.854563952000092],
              [18.600482940000063, 3.876234055000168],
              [18.600187301000176, 3.885442019000152],
              [18.601999283, 3.895999909000068],
              [18.60799980200011, 3.908999921000145],
              [18.620101928999986, 3.923798084000168],
              [18.636051179000162, 3.939096689000053],
              [18.647569656000087, 3.961745025000141],
              [18.65200042700019, 3.977999926000109],
              [18.651601790000143, 4.009427072000165],
              [18.649299622000058, 4.043941974000063],
              [18.64782905600009, 4.057974816000126],
              [18.64884948600013, 4.076396942000088],
              [18.645532608000167, 4.091796874000124],
              [18.640829086000167, 4.10903215400009],
              [18.63195991500004, 4.13176012100007],
              [18.626619339000115, 4.14161920600003],
              [18.61800003100018, 4.166999818000022],
              [18.594999313000073, 4.204473019000034],
              [18.57756996100005, 4.238189221000141],
              [18.571079254000097, 4.247351171000048],
              [18.55100059599999, 4.289000035000129],
              [18.54599952699999, 4.302000046000103],
              [18.54599952699999, 4.308000088000028],
              [18.548999787000128, 4.31599998500019],
              [18.561113358000057, 4.328771114],
              [18.578201293000063, 4.344095231000097],
              [18.606779098000175, 4.357643127000131],
              [18.612733841000022, 4.356089116000078],
              [18.617839813000046, 4.351044177],
              [18.625890733000062, 4.347417832000076],
              [18.637359620000154, 4.346580028000062],
              [18.667419434000067, 4.352284908000115],
              [18.67607689000016, 4.353065967000134],
              [18.698888779000185, 4.370223999000132],
              [18.713857650000136, 4.375231743000086],
              [18.73310089199998, 4.386678218999975],
              [18.73333358800005, 4.383333205000156],
              [18.740465164, 4.368898391000073],
              [18.76300048800016, 4.387000084000079],
              [18.774000168000043, 4.397999763000087],
              [18.780139923000092, 4.41958522900012],
              [18.794000625000137, 4.422999859000186],
              [18.799999237000122, 4.439000129000135],
              [18.811000824000132, 4.460999965000042],
              [18.81755828900009, 4.494465828000102],
              [18.819068908000133, 4.511276246000136],
              [18.82259178200013, 4.531794072000082],
            ],
          ],
        },
        properties: { GID_0: "CAF", NAME_1: "Central African Republic" },
      },
    ],
  },
  107: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-4.219584941999926, 5.219860077000021],
                [-4.209305762999918, 5.222084999000117],
                [-4.206249236999895, 5.222084999000117],
                [-4.198194981999961, 5.223751069000116],
                [-4.181528090999905, 5.225139142000103],
                [-4.179583071999957, 5.22597313000017],
                [-4.169029234999869, 5.227083205000099],
                [-4.165694235999979, 5.227917194000099],
                [-4.159584044999974, 5.227917194000099],
                [-4.157361983999977, 5.228751183000099],
                [-4.147638797999889, 5.229304792000164],
                [-4.142917155999953, 5.230694772000049],
                [-4.134027957999933, 5.231804849000184],
                [-4.12819385399996, 5.231804849000184],
                [-4.109861849999959, 5.23486089700009],
                [-4.105415821999827, 5.235970974000168],
                [-4.10069608799995, 5.235970974000168],
                [-4.091528892999918, 5.237639905000151],
                [-4.086250780999933, 5.237639905000151],
                [-4.083471775999954, 5.238749982000059],
                [-4.071804998999937, 5.23958396900008],
                [-4.053750037999976, 5.242362023000055],
                [-4.045692920999954, 5.242640019000135],
                [-4.034026145999917, 5.244306087000155],
                [-4.014861105999955, 5.244306087000155],
                [-4.007916927999872, 5.244584083000063],
                [-4.001527785999883, 5.252360822000185],
                [-3.997639893999974, 5.252360822000185],
                [-3.991249083999946, 5.254860879000034],
                [-3.984026908999852, 5.254304886000114],
                [-3.981528996999941, 5.255138875000114],
                [-3.974859953999953, 5.255138875000114],
                [-3.966805933999979, 5.253472805000115],
                [-3.953473091999911, 5.24736023000014],
                [-3.946527958999923, 5.246528149000142],
                [-3.943471907999935, 5.245416164000062],
                [-3.928195, 5.242916107000042],
                [-3.92458200499982, 5.242916107000042],
                [-3.918194055999834, 5.241528035000158],
                [-3.908195017999958, 5.240416050000079],
                [-3.899861096999871, 5.238749982000059],
                [-3.888472080999975, 5.23708391200006],
                [-3.879582881999909, 5.234304906000148],
                [-3.870415924999918, 5.232360840000069],
                [-3.855139016999885, 5.227639199000123],
                [-3.84597301499997, 5.223195076000025],
                [-3.844028950999814, 5.223195076000025],
                [-3.820694922999962, 5.212917804000028],
                [-3.808471916999963, 5.209584235000136],
                [-3.807084083999826, 5.208470821000049],
                [-3.798748968999917, 5.206805230000157],
                [-3.787359952999964, 5.203751087000171],
                [-3.769026993999887, 5.199861050000095],
                [-3.7651391039999, 5.199583054000186],
                [-3.743473053999878, 5.195415974000184],
                [-3.737638949999962, 5.194584847000044],
                [-3.724584103999916, 5.191527844000177],
                [-3.717916965999848, 5.190971851000086],
                [-3.715416908999941, 5.189583778000042],
                [-3.699584006999885, 5.187915802000077],
                [-3.692082880999976, 5.186528205000116],
                [-3.686250924999911, 5.186528205000116],
                [-3.675693987999864, 5.184584141000187],
                [-3.659305094999979, 5.182083129000034],
                [-3.654027939999878, 5.181805134000058],
                [-3.64374995299994, 5.179861070000129],
                [-3.634582995999949, 5.179027081000129],
                [-3.631526947999873, 5.177917004000051],
                [-3.626528023999981, 5.177917004000051],
                [-3.624583959999882, 5.176805019000142],
                [-3.614305018999914, 5.175972938000086],
                [-3.60708308299985, 5.174028874000044],
                [-3.594027995999966, 5.17291689000001],
                [-3.591805933999979, 5.171250821000115],
                [-3.585417031999896, 5.171528817000024],
                [-3.577361106999831, 5.170138837000138],
                [-3.567637919999925, 5.170138837000138],
                [-3.563750028999948, 5.169307233000041],
                [-3.553750990999959, 5.169303895000155],
                [-3.54597210899982, 5.16819381800002],
                [-3.539027928999815, 5.16819381800002],
                [-3.523195028999965, 5.16652822500015],
                [-3.51791596399994, 5.16652822500015],
                [-3.509305953999956, 5.16486215700013],
                [-3.504861114999926, 5.16486215700013],
                [-3.49958300499992, 5.163472177000074],
                [-3.478194952999957, 5.160694122000166],
                [-3.464859962999981, 5.158194064999975],
                [-3.459306000999902, 5.157915116000083],
                [-3.452918052999905, 5.156249046000085],
                [-3.449027060999867, 5.156249046000085],
                [-3.44625091599994, 5.154860973000098],
                [-3.428750038999908, 5.152916908000066],
                [-3.422638891999895, 5.151526927000077],
                [-3.405139921999933, 5.148472787000173],
                [-3.398749112999894, 5.146804810999981],
                [-3.39291691699998, 5.146804810999981],
                [-3.38458204199992, 5.145695209000053],
                [-3.384305953999899, 5.144861222000031],
                [-3.370417117999978, 5.142917156000124],
                [-3.360972879999963, 5.140416146000121],
                [-3.345417975999908, 5.138472080000042],
                [-3.324584006999942, 5.134027957000114],
                [-3.312083006999899, 5.132083894000118],
                [-3.303472042999943, 5.129861832000131],
                [-3.297640084999955, 5.129861832000131],
                [-3.296806095999955, 5.128470897000057],
                [-3.286804914999948, 5.12708282400007],
                [-3.28597307299998, 5.129026890000148],
                [-3.280694960999881, 5.129026890000148],
                [-3.276527880999879, 5.124026776000164],
                [-3.263470888999905, 5.122639178999975],
                [-3.262917042999845, 5.121805192000181],
                [-3.250416039999948, 5.120139122000182],
                [-3.245693920999884, 5.118751048999968],
                [-3.224581956999941, 5.115138054000113],
                [-3.219305991999931, 5.113471984000114],
                [-3.212918042999945, 5.112915993000172],
                [-3.205972909999957, 5.110971927000094],
                [-3.202639102999967, 5.110971927000094],
                [-3.187639952999916, 5.107361793000166],
                [-3.184582948999946, 5.107361793000166],
                [-3.17569494299994, 5.104862213000104],
                [-3.169306038999878, 5.104028224000103],
                [-3.141249893999941, 5.098195076000138],
                [-3.135138034999955, 5.096527100000117],
                [-3.132083891999969, 5.096527100000117],
                [-3.124027013999978, 5.094028950000052],
                [-3.11073493899994, 5.092082977000075],
                [-3.111514090999947, 5.110734941000032],
                [-3.108917972999961, 5.110159354000075],
                [-3.107888905999914, 5.114978010000186],
                [-3.101673545999915, 5.112374786000089],
                [-3.096382484999936, 5.113307328000133],
                [-3.094298793999883, 5.115367977],
                [-3.090479509999909, 5.113204569000061],
                [-3.085059382999873, 5.111879879000071],
                [-3.077578168999935, 5.113002438000137],
                [-3.072405943999911, 5.113080190000176],
                [-3.071756974999971, 5.119427936000136],
                [-3.068935469999929, 5.121316741000157],
                [-3.062271346999978, 5.121777166000186],
                [-3.059525526999892, 5.1194673870001],
                [-3.051907582999945, 5.115952875000119],
                [-3.048270553999942, 5.120418732000019],
                [-3.041990417999898, 5.116594720000023],
                [-3.038035120999837, 5.11666832800006],
                [-3.034023391999881, 5.118145490000075],
                [-3.030064218999883, 5.116998717000115],
                [-3.025136754999949, 5.117563467000139],
                [-3.021477699999878, 5.119431884999983],
                [-3.018985481999891, 5.115996417000133],
                [-3.011794928999962, 5.112907105000147],
                [-3.004430010999954, 5.112442020000117],
                [-2.993198426999868, 5.10137160000005],
                [-2.99289058099987, 5.100213197000016],
                [-2.986010834999888, 5.094671118000065],
                [-2.984831147999955, 5.092921461000174],
                [-2.976237128999969, 5.087084404999985],
                [-2.971429625999917, 5.086867366000092],
                [-2.965103156999874, 5.087850687000184],
                [-2.953774565999936, 5.08465105800002],
                [-2.949095149999891, 5.084054059000096],
                [-2.941616902999954, 5.093975726000053],
                [-2.930656045999967, 5.09568557700004],
                [-2.929891899999973, 5.099976005000144],
                [-2.93269387, 5.101110507000044],
                [-2.933831367999972, 5.107945361000134],
                [-2.935056612999915, 5.109542399000134],
                [-2.941037674999905, 5.112013684000033],
                [-2.942916767999861, 5.114958057000024],
                [-2.94015600299997, 5.120979006000027],
                [-2.940346628999919, 5.124890871000162],
                [-2.935399526999959, 5.125637657000084],
                [-2.933998205999899, 5.12230304700006],
                [-2.930967165999959, 5.123112608000156],
                [-2.928798399999835, 5.120994068000016],
                [-2.925671548999844, 5.120788554000058],
                [-2.921789754999907, 5.122329234000176],
                [-2.91481570499991, 5.122017679000066],
                [-2.911572881999916, 5.120655223000142],
                [-2.906775008999887, 5.12334651599997],
                [-2.902101288999972, 5.121224106000057],
                [-2.895985681999946, 5.122765473000129],
                [-2.89262864899996, 5.119723694000072],
                [-2.891025971999852, 5.121527511000011],
                [-2.891371324999909, 5.125787871000114],
                [-2.88963090999988, 5.127017275000014],
                [-2.885517867999909, 5.123803532000125],
                [-2.882048386999827, 5.126469242000098],
                [-2.877272965999964, 5.128021416000081],
                [-2.867853853999975, 5.12236225099997],
                [-2.863362719999884, 5.123130041000081],
                [-2.859588432999885, 5.119390373000101],
                [-2.858529663999946, 5.116077324000173],
                [-2.854224009999939, 5.11561576600019],
                [-2.852378830999953, 5.110766446000071],
                [-2.847578483999882, 5.108199183000181],
                [-2.841530780999904, 5.107500671000139],
                [-2.838131448999945, 5.109611392000033],
                [-2.840982997999959, 5.112875621000057],
                [-2.836081782999884, 5.115759614000183],
                [-2.83443409299997, 5.121203863000062],
                [-2.828300551999973, 5.120637220000162],
                [-2.82415858, 5.116062071000101],
                [-2.823458659999858, 5.111283828000069],
                [-2.818613127999925, 5.109230390000164],
                [-2.817947671999946, 5.1058773150001],
                [-2.814775342999951, 5.106739410000103],
                [-2.816706445999841, 5.111028541000167],
                [-2.813517275999914, 5.113406485999974],
                [-2.81135194399991, 5.110668133000161],
                [-2.811003819999883, 5.106511282000042],
                [-2.808255915999894, 5.10646705400012],
                [-2.805350363999935, 5.111427843000172],
                [-2.801397840999925, 5.10765036000015],
                [-2.794569793999926, 5.106836908000162],
                [-2.790510190999953, 5.107093829],
                [-2.787174416999903, 5.116646567000146],
                [-2.784322132999876, 5.115728311000169],
                [-2.785305808999908, 5.112658873000044],
                [-2.784003018999954, 5.109557153000083],
                [-2.780830885999933, 5.112318407000146],
                [-2.776871664999931, 5.110755841000128],
                [-2.773153625999953, 5.111470387000111],
                [-2.768643904999919, 5.108722690000036],
                [-2.765584406999949, 5.108815556000081],
                [-2.765776114999881, 5.105800699000156],
                [-2.763687446999938, 5.103672555000117],
                [-2.760164139999972, 5.103547232000096],
                [-2.758004902999858, 5.101191998000104],
                [-2.756231069999899, 5.111790180000185],
                [-2.753648688999931, 5.116676878000078],
                [-2.750295916999903, 5.115032640999971],
                [-2.747312464999936, 5.116756120000048],
                [-2.742234943999961, 5.113944054000058],
                [-2.740637063999884, 5.115793228000143],
                [-2.740463970999883, 5.124772071000052],
                [-2.735224962999837, 5.132167817000038],
                [-2.732664383999975, 5.134452277000037],
                [-2.729931968999949, 5.134071174000042],
                [-2.726930286999902, 5.137555754000061],
                [-2.726652711999975, 5.144600903000082],
                [-2.730061408999973, 5.148228209000024],
                [-2.731006321999928, 5.151793575000113],
                [-2.733985946999894, 5.148583480000184],
                [-2.738667697999915, 5.150630260000071],
                [-2.740997640999979, 5.157632823000085],
                [-2.740612642999906, 5.161144591000038],
                [-2.745578656999953, 5.166940247000127],
                [-2.743918018999921, 5.168637282000134],
                [-2.741293854999981, 5.176086783000187],
                [-2.742018132999931, 5.18092577499999],
                [-2.74591387199996, 5.184446491000188],
                [-2.745804438999869, 5.187260938000065],
                [-2.749530470999957, 5.196111878000181],
                [-2.74762584399997, 5.202068414000109],
                [-2.75279296199983, 5.202118958000028],
                [-2.757123051999884, 5.200528804000101],
                [-2.760083641999813, 5.206901551000044],
                [-2.760303147999935, 5.212001164000185],
                [-2.763258339999936, 5.213203592000014],
                [-2.763305687999946, 5.218543980000106],
                [-2.75735988799994, 5.218189433000021],
                [-2.759079151999913, 5.22131289400005],
                [-2.757831494999891, 5.225593445000129],
                [-2.760945664999895, 5.229701487000057],
                [-2.7584297329999, 5.235151865000148],
                [-2.764867900999889, 5.234782714000062],
                [-2.765823675999911, 5.236343200000079],
                [-2.762382080999828, 5.245684231000155],
                [-2.75955334199989, 5.245783681000034],
                [-2.758949156999904, 5.241851575000112],
                [-2.754822908999927, 5.242032232000042],
                [-2.754915247999918, 5.244983885000067],
                [-2.760770815999933, 5.249828717000014],
                [-2.757604029999925, 5.250340489000109],
                [-2.753300819999936, 5.248831644000063],
                [-2.754573339999979, 5.253219404000163],
                [-2.757415454999943, 5.25586570300004],
                [-2.764057065999964, 5.255283405000171],
                [-2.766132211999945, 5.260000642000136],
                [-2.768470884999942, 5.257753521000097],
                [-2.773247355999899, 5.25878875300009],
                [-2.775166486999979, 5.264722280000171],
                [-2.778168287999904, 5.263065609000137],
                [-2.779276950999872, 5.26603705500014],
                [-2.774102349999851, 5.267607027],
                [-2.774847578999868, 5.273842536000188],
                [-2.779300805999981, 5.275323939000089],
                [-2.783824309999829, 5.281208073000073],
                [-2.785303540999905, 5.285904959000163],
                [-2.784425497999905, 5.292464306000113],
                [-2.780327049999926, 5.294857160000049],
                [-2.772084801999881, 5.292823227999975],
                [-2.769546555999909, 5.294577471000082],
                [-2.769569363999949, 5.298058445000152],
                [-2.768013417999896, 5.300223983000137],
                [-2.766596163999964, 5.305766642000151],
                [-2.767877604999967, 5.306940580000116],
                [-2.765877873999955, 5.316483581],
                [-2.769699764999928, 5.321157148999987],
                [-2.771762075999902, 5.329006194000101],
                [-2.777398311999889, 5.331526304000079],
                [-2.774210374999882, 5.336362356000166],
                [-2.770661816999905, 5.337019619000103],
                [-2.776137634999941, 5.342089478000105],
                [-2.776384416999917, 5.347083836000138],
                [-2.769736970999873, 5.352162837000037],
                [-2.768382276999944, 5.356993610000131],
                [-2.764687670999876, 5.356292530000189],
                [-2.760373152999875, 5.349849768000013],
                [-2.757911748999959, 5.348107595000045],
                [-2.756751205999933, 5.34408635200009],
                [-2.751847606999945, 5.344993277000128],
                [-2.742151809999825, 5.345137342000044],
                [-2.738374761999864, 5.347231530000101],
                [-2.734942367999906, 5.343346040000142],
                [-2.726620854999965, 5.34093660100018],
                [-2.720592095999962, 5.34591432000019],
                [-2.724164156999905, 5.357456624000065],
                [-2.724473995999972, 5.378109650000056],
                [-2.724189285999955, 5.387764936999986],
                [-2.732280475999914, 5.404102064000028],
                [-2.745637066999962, 5.432136340000056],
                [-2.747811531999957, 5.437892201000125],
                [-2.751500470999929, 5.456155700000181],
                [-2.752964010999961, 5.468928416999972],
                [-2.754753682999876, 5.477143839000121],
                [-2.761034980999909, 5.513439586],
                [-2.762530197999979, 5.524799168000072],
                [-2.76639758399989, 5.545637961000068],
                [-2.7682677869999, 5.554370934000076],
                [-2.76390231399995, 5.581336022000073],
                [-2.76135356799989, 5.599316277000185],
                [-2.767248957999811, 5.601951407000058],
                [-2.770588218999876, 5.602455254000063],
                [-2.772823830999926, 5.604467861000103],
                [-2.776037106999979, 5.603781359000095],
                [-2.7786147399999, 5.608091613000056],
                [-2.776719892999893, 5.609813266000117],
                [-2.775338040999941, 5.615096742000105],
                [-2.776713751999978, 5.616246569000168],
                [-2.780788767999923, 5.614354313000092],
                [-2.785034156999927, 5.6142429410001],
                [-2.78460314299997, 5.619033182000067],
                [-2.787380199999973, 5.620505406000177],
                [-2.786000235999893, 5.623835401000122],
                [-2.789525148999871, 5.626738493000119],
                [-2.79269699799994, 5.627899071000059],
                [-2.792998052999906, 5.624127742000042],
                [-2.802006115999973, 5.621607564000044],
                [-2.803615811999919, 5.61770317200012],
                [-2.807968088999871, 5.624597976000075],
                [-2.810663099999943, 5.625231672000098],
                [-2.813756691999913, 5.628794412000161],
                [-2.815479782999944, 5.626268819000188],
                [-2.819722744999922, 5.627132991999986],
                [-2.822189303999892, 5.626388179000117],
                [-2.823390206999875, 5.629834391000145],
                [-2.828434499999958, 5.631962417000182],
                [-2.835253915999942, 5.63641650400001],
                [-2.836913307999964, 5.640006134000032],
                [-2.839436661999912, 5.639376016000142],
                [-2.843896865999909, 5.643368210000062],
                [-2.845883043999947, 5.642743123000116],
                [-2.848670363999815, 5.648622021000165],
                [-2.851067557999897, 5.647650179000038],
                [-2.856190436999953, 5.649318714000174],
                [-2.857593068999904, 5.652169371000014],
                [-2.856305665999969, 5.655316913000036],
                [-2.859380088999899, 5.656432999],
                [-2.860219617999974, 5.653297374000033],
                [-2.864964312999973, 5.65157865000009],
                [-2.868565354999873, 5.645080551000035],
                [-2.871365075999961, 5.645546254000124],
                [-2.875205728999958, 5.643758834000039],
                [-2.878477292999946, 5.639512506000074],
                [-2.882087827999953, 5.640088360999982],
                [-2.88386316999987, 5.635960092000119],
                [-2.887360268999942, 5.635826302000055],
                [-2.893067651999957, 5.633230505000142],
                [-2.894474870999943, 5.631469029000016],
                [-2.899931091999918, 5.629222158],
                [-2.903102260999845, 5.631270647000122],
                [-2.905380424999976, 5.628962137000087],
                [-2.913149296999961, 5.629035056000021],
                [-2.92074307099989, 5.627993088000096],
                [-2.924433088999933, 5.621235691000095],
                [-2.930551492999939, 5.619480026000076],
                [-2.934470439999927, 5.619763522000085],
                [-2.939347537999936, 5.628084705000163],
                [-2.941831325999942, 5.628820133999966],
                [-2.943094504999976, 5.633098641000061],
                [-2.947559374999855, 5.63662300500016],
                [-2.950884025999869, 5.635381608000046],
                [-2.954438205999963, 5.637307407000094],
                [-2.959641209999973, 5.636681753000062],
                [-2.963480390999905, 5.640467060000162],
                [-2.96665057599995, 5.640613324000185],
                [-2.96102026199992, 5.669650292000029],
                [-2.953330348999941, 5.673831846000155],
                [-2.952731867999887, 5.715983096000059],
                [-2.979789462999918, 5.712870050000049],
                [-3.01194749299998, 5.710292926000136],
                [-3.026076306999869, 5.709726702000182],
                [-3.025787985999898, 5.735231691000081],
                [-3.025232786999879, 5.753539580000108],
                [-3.024094658999957, 5.77371010000013],
                [-3.023197492999884, 5.796137165000062],
                [-3.02144149899982, 5.828480720000016],
                [-3.020514957999978, 5.857092456000032],
                [-3.044058749999977, 5.913414572000022],
                [-3.058112154999947, 5.946375275000094],
                [-3.06817588399997, 5.971877279000182],
                [-3.07400791699996, 5.985276320000082],
                [-3.079394261999937, 6.018553237000162],
                [-3.088580395999884, 6.069578782000065],
                [-3.095631996999884, 6.116412080000146],
                [-3.1022844499999, 6.151936201000183],
                [-3.107696685999827, 6.165100086],
                [-3.115256347999946, 6.178388685000016],
                [-3.121219532999874, 6.190841939000165],
                [-3.130938767999965, 6.208071924000137],
                [-3.141308839999908, 6.22704783800009],
                [-3.147219989999883, 6.23918559700013],
                [-3.154809784999941, 6.252226472000075],
                [-3.174295664999818, 6.251570127000036],
                [-3.173932918999981, 6.259725429000127],
                [-3.171695116999956, 6.264340157000049],
                [-3.171747531999927, 6.276436999000168],
                [-3.170781975999944, 6.286540670000079],
                [-3.170911520999937, 6.295852073000049],
                [-3.173380265999924, 6.301772267000104],
                [-3.174637087999884, 6.310112467000067],
                [-3.180880122999895, 6.315863010000101],
                [-3.185755251999922, 6.325963823000166],
                [-3.186497211999949, 6.342913390000035],
                [-3.190228619999971, 6.348717136000062],
                [-3.193501386999969, 6.360434442000042],
                [-3.218265648999932, 6.462793746000159],
                [-3.236640982999916, 6.538733313000023],
                [-3.234145811999952, 6.596473215000117],
                [-3.243598350999946, 6.600800965000076],
                [-3.254911734999951, 6.609489946000167],
                [-3.255264574999956, 6.615569976000188],
                [-3.262058472999968, 6.617611022000119],
                [-3.260053513999878, 6.621529110000154],
                [-3.260666806999893, 6.62340229300014],
                [-3.259877287999871, 6.629941495000082],
                [-3.257204428999955, 6.632858533000047],
                [-3.25478943, 6.644986427000049],
                [-3.252728085999934, 6.650347249000049],
                [-3.250523872999906, 6.651678528000161],
                [-3.243800369999974, 6.665329303000135],
                [-3.235261282999886, 6.667671724000059],
                [-3.236788613999977, 6.67407154600005],
                [-3.236195285999884, 6.677358101000095],
                [-3.230956539999966, 6.680902803000151],
                [-3.232732644999942, 6.686134862000017],
                [-3.229305734999969, 6.687756265000189],
                [-3.228680848999886, 6.692983721000132],
                [-3.23104176499993, 6.694044450999968],
                [-3.229859062999935, 6.703202586000032],
                [-3.222451958999898, 6.705126654000083],
                [-3.220650312999965, 6.709944689000167],
                [-3.216573752999921, 6.714343961000054],
                [-3.216853836999974, 6.718388815000026],
                [-3.215742422999881, 6.722539922000067],
                [-3.213633514999913, 6.724541959000021],
                [-3.209003874999837, 6.731625200000167],
                [-3.208930455999962, 6.734399289000123],
                [-3.214023536999946, 6.739482796000175],
                [-3.214429877999976, 6.744175833999975],
                [-3.217091798999945, 6.746527884000102],
                [-3.216214256999933, 6.751038903000051],
                [-3.212120993999974, 6.754324183000051],
                [-3.21618856099991, 6.761189780000166],
                [-3.223074047999944, 6.764805380000155],
                [-3.228547688999868, 6.769718625000166],
                [-3.229039686999897, 6.774189269000033],
                [-3.231298314999833, 6.776650794000091],
                [-3.232853598999895, 6.788013098000135],
                [-3.231642685999816, 6.791733654000154],
                [-3.232514410999897, 6.796376935000183],
                [-3.230401852999819, 6.800894338000148],
                [-3.231037167999887, 6.806431082000131],
                [-3.229728589999979, 6.809404696000115],
                [-3.230466738999951, 6.816526335000049],
                [-3.231715636999979, 6.819898083000169],
                [-3.230913279999925, 6.822738516000072],
                [-3.225754753999979, 6.824867935000157],
                [-3.155402084999935, 6.940367046000063],
                [-3.143506938999906, 6.956742582000118],
                [-3.142613415999961, 6.96272453500012],
                [-3.133921555999962, 6.974946833000104],
                [-3.116555622999897, 7.00586911500011],
                [-3.11112531099991, 7.012388470000133],
                [-3.107804730999874, 7.014134508000041],
                [-3.107239792999962, 7.018969720000087],
                [-3.082900777999896, 7.054409142999987],
                [-3.074941962999958, 7.062858703000131],
                [-3.058460109999942, 7.065707153000119],
                [-3.047783169999946, 7.069225208000148],
                [-3.032058177999943, 7.071719040000062],
                [-3.027130632999899, 7.114843959000154],
                [-3.023621307999974, 7.142483154000047],
                [-3.000062848999903, 7.172141675000034],
                [-2.982734487999949, 7.194655988000136],
                [-2.959670242999948, 7.228352745000109],
                [-2.953354945999934, 7.239177758000039],
                [-2.955885247999959, 7.242865592000044],
                [-2.959714175999864, 7.250941820000151],
                [-2.97818101199988, 7.272064758000113],
                [-2.969644629999948, 7.333134752000035],
                [-2.963170022999975, 7.360865537000052],
                [-2.948393718999967, 7.430044467000187],
                [-2.947128918999852, 7.447119967000106],
                [-2.947255384999835, 7.456479852000143],
                [-2.941563575999965, 7.471531489000029],
                [-2.937068489999945, 7.480318876000126],
                [-2.934690950999936, 7.486509849000186],
                [-2.92346035199995, 7.608564250000143],
                [-2.900688430999878, 7.657178041000179],
                [-2.888669873999959, 7.683580759000165],
                [-2.883400464999966, 7.698509855000111],
                [-2.873512632999962, 7.718734194999968],
                [-2.869345463999935, 7.732279455000025],
                [-2.850614539999981, 7.761860051000099],
                [-2.84861725199994, 7.780245562000118],
                [-2.835167841999919, 7.793573924000157],
                [-2.826105192999876, 7.805732423000109],
                [-2.83035254899994, 7.820530837000092],
                [-2.825116282999943, 7.824397196000177],
                [-2.826830886999971, 7.826676699000018],
                [-2.829128007999884, 7.83370936100016],
                [-2.829039013999875, 7.842121757000029],
                [-2.832891643999972, 7.848578627000165],
                [-2.825241142999971, 7.868247737000047],
                [-2.824049185999968, 7.88277332600012],
                [-2.821687263999934, 7.894898966000142],
                [-2.82142, 7.906684674000076],
                [-2.822124764999956, 7.912426458000141],
                [-2.818839312999899, 7.918317909000052],
                [-2.810313619999874, 7.938485528000115],
                [-2.809868458999915, 7.948351587000104],
                [-2.809995648999973, 7.95951291599999],
                [-2.808562474999974, 7.965602956000112],
                [-2.803674553999827, 7.972410662000129],
                [-2.7931364, 7.983739603000174],
                [-2.792619524999964, 7.983299606000117],
                [-2.782018992999952, 7.991414509000151],
                [-2.771733350999966, 7.997129102000031],
                [-2.751604401999884, 8.00179668200002],
                [-2.712707879999812, 8.017160805],
                [-2.695593409999958, 8.029802173000064],
                [-2.692092723999849, 8.029607689000159],
                [-2.661992096999938, 8.042114881000089],
                [-2.652687553999954, 8.042189608000115],
                [-2.636674833999962, 8.054729547000022],
                [-2.631555417999948, 8.05357881600014],
                [-2.63409207199993, 8.05855355500006],
                [-2.636158378999937, 8.066911227000048],
                [-2.634379622999916, 8.081674408000026],
                [-2.634136901999909, 8.09125867299997],
                [-2.632700740999951, 8.100837844000068],
                [-2.630558855999823, 8.109310343000061],
                [-2.629879806999952, 8.11509267700012],
                [-2.625025601999937, 8.124988061000124],
                [-2.619454619999942, 8.13316955800019],
                [-2.614836951999962, 8.137613406000185],
                [-2.61101648399989, 8.14748533200003],
                [-2.608578421999937, 8.15177168800011],
                [-2.601648457999886, 8.153570970000146],
                [-2.597984633999886, 8.152146998999967],
                [-2.589915417999976, 8.152415699000187],
                [-2.580174942999975, 8.148083339000038],
                [-2.577437085999918, 8.144960834000017],
                [-2.572918566999874, 8.146017248000078],
                [-2.569664602999865, 8.145473206000133],
                [-2.565376621999974, 8.149289810000084],
                [-2.560865215999911, 8.146074808000037],
                [-2.555444847999979, 8.14692094000003],
                [-2.544777012999873, 8.175730379000129],
                [-2.517893514999969, 8.190752622000105],
                [-2.508956136999927, 8.193442997000034],
                [-2.496688030999962, 8.202849995000122],
                [-2.494887666999944, 8.205178929999988],
                [-2.495955067999944, 8.212962778000133],
                [-2.497704307999925, 8.24483661700009],
                [-2.499997510999947, 8.256501993000029],
                [-2.500054748999958, 8.271879967000189],
                [-2.501412666999954, 8.30743589399998],
                [-2.504446749999943, 8.334010072000183],
                [-2.512721361999979, 8.369441081000105],
                [-2.516438006999977, 8.38314640100009],
                [-2.516140644999894, 8.39187633000006],
                [-2.517479126999888, 8.401723071000049],
                [-2.524438295999971, 8.44062448699998],
                [-2.533040147999941, 8.480874375000155],
                [-2.539639317999956, 8.500498199000049],
                [-2.542458481999972, 8.515369832000147],
                [-2.546400487999847, 8.528614440000183],
                [-2.548967650999884, 8.542343611000092],
                [-2.56211198699998, 8.61488971000017],
                [-2.56260842599994, 8.62888780000003],
                [-2.565395189999947, 8.641925498000148],
                [-2.567115269999874, 8.659576157000117],
                [-2.573944118999918, 8.690910176000045],
                [-2.573435437999933, 8.700564225999983],
                [-2.575669600999959, 8.708790604000114],
                [-2.587160812999969, 8.77862166600005],
                [-2.593653012999937, 8.788253920000102],
                [-2.604983071999925, 8.78852792400005],
                [-2.615104414999905, 8.786772152000083],
                [-2.618932415999893, 8.78754105300004],
                [-2.624380803999884, 8.790738788],
                [-2.628862161999962, 8.795574609000028],
                [-2.623669629999938, 8.805009779000159],
                [-2.619177886999978, 8.809151354000107],
                [-2.609818139999959, 8.813818024],
                [-2.5974459709999, 8.823030152000172],
                [-2.596298796999918, 8.826844539000035],
                [-2.596417195999948, 8.831317624000064],
                [-2.597690703999888, 8.835061088000145],
                [-2.600952438999968, 8.84066980600005],
                [-2.60765273699991, 8.848408895000091],
                [-2.609647407999944, 8.853872754],
                [-2.617655272999855, 8.870029485000032],
                [-2.621935765999979, 8.875193989000138],
                [-2.623072493999814, 8.879090810000037],
                [-2.621684330999869, 8.885186457000032],
                [-2.620177161999948, 8.896559369000101],
                [-2.617147286999909, 8.905453460000103],
                [-2.615926524999964, 8.914301113000079],
                [-2.617988601999969, 8.92165203500008],
                [-2.619733253999925, 8.92386259400007],
                [-2.624612427999864, 8.926687688000072],
                [-2.627049800999828, 8.92967818700015],
                [-2.630868878999877, 8.930389782000134],
                [-2.645034695999925, 8.936164679],
                [-2.651194536999981, 8.943877326000177],
                [-2.65206577899994, 8.952340887000162],
                [-2.654963382999938, 8.966579014000047],
                [-2.656503288999943, 8.97895774300008],
                [-2.657768206999947, 8.983046621000028],
                [-2.657355936999863, 8.988683306000041],
                [-2.65580656599991, 8.993240841999977],
                [-2.654217037999956, 9.003067482000176],
                [-2.654100900999936, 9.007438922000063],
                [-2.659685626999874, 9.006877857000063],
                [-2.661444404999941, 9.012686597000027],
                [-2.664530328999888, 9.016312540000058],
                [-2.670699473999889, 9.018433776000109],
                [-2.671820873999877, 9.020109470000023],
                [-2.680252769999868, 9.0196687400001],
                [-2.686835742999904, 9.018125893000104],
                [-2.694155461999969, 9.019122458000027],
                [-2.699608855999941, 9.015523877000078],
                [-2.702808248999872, 9.015544898000144],
                [-2.702441280999892, 9.019632787000091],
                [-2.705664749999869, 9.020882551000113],
                [-2.707904609999844, 9.019433859000117],
                [-2.715503437999928, 9.017417393000073],
                [-2.718518933999917, 9.01882781900008],
                [-2.713970294999967, 9.021783488000153],
                [-2.714087408999887, 9.024171819000117],
                [-2.717657432999886, 9.02559343200005],
                [-2.721977375999927, 9.025314209000157],
                [-2.727453565999952, 9.027286108000112],
                [-2.738344919999975, 9.026162913],
                [-2.751605329999961, 9.026543528000161],
                [-2.755531202999975, 9.025265624000099],
                [-2.759631893999824, 9.025342442000067],
                [-2.76426545299995, 9.027565763000098],
                [-2.771869573999936, 9.03243902600019],
                [-2.773513660999925, 9.036847756999975],
                [-2.773578309999834, 9.04479359100003],
                [-2.779938236999897, 9.052617963999978],
                [-2.783376822999912, 9.061550245000035],
                [-2.781889706999891, 9.064858263000133],
                [-2.778151544999957, 9.069753229000185],
                [-2.77124906899985, 9.076063192000106],
                [-2.769033543999854, 9.078911835000156],
                [-2.765713259999927, 9.08650485700008],
                [-2.765557531999946, 9.093531514000176],
                [-2.766916519999938, 9.101752120000185],
                [-2.767001693999873, 9.106678098000089],
                [-2.768619822999881, 9.120418937000068],
                [-2.772079897999845, 9.1269265090001],
                [-2.771978375999879, 9.129500400000154],
                [-2.774492405999979, 9.137076055000023],
                [-2.774130841999977, 9.142384410000034],
                [-2.771934499999873, 9.145525094000163],
                [-2.766792060999933, 9.149291804000086],
                [-2.762054497999884, 9.1516898860001],
                [-2.755988716999923, 9.152430178000031],
                [-2.747194677999971, 9.155677058000094],
                [-2.742469379999875, 9.156162700000152],
                [-2.738011073999871, 9.158462734000011],
                [-2.733704995999915, 9.163222471000097],
                [-2.729816954999933, 9.1706996530001],
                [-2.728237736999915, 9.175363653000147],
                [-2.722930362999875, 9.185724124000046],
                [-2.72109878699996, 9.192706532999978],
                [-2.723922736999953, 9.19809068900014],
                [-2.719495376999817, 9.196486189999973],
                [-2.717951820999929, 9.201505448000148],
                [-2.712860719999924, 9.209316183000112],
                [-2.702334894999979, 9.216160125000044],
                [-2.695620344999952, 9.219822856000178],
                [-2.694488630999956, 9.224510327000132],
                [-2.692092521999939, 9.226241577],
                [-2.681726664999871, 9.230685700000095],
                [-2.661921651999876, 9.240111650000188],
                [-2.656582308999873, 9.255572699000027],
                [-2.671777149999969, 9.274589228000082],
                [-2.676658037999971, 9.28032585900013],
                [-2.685770084999945, 9.287472877000141],
                [-2.693170652999868, 9.284430245000124],
                [-2.701062477999926, 9.285698011000022],
                [-2.70768654099993, 9.288677256000085],
                [-2.715261424999881, 9.296252143],
                [-2.717480009999861, 9.300087127000097],
                [-2.718208971999957, 9.306616109000117],
                [-2.721251601999882, 9.312891539000077],
                [-2.720427554999958, 9.319959319000134],
                [-2.72141007099998, 9.325505785000189],
                [-2.719540118999873, 9.334728762999987],
                [-2.714786005999883, 9.337549535000164],
                [-2.707631066999966, 9.339490797],
                [-2.703130507999958, 9.339276861000087],
                [-2.697980218999817, 9.345298736000132],
                [-2.695682396999928, 9.349767601000167],
                [-2.692774463999967, 9.352944933000174],
                [-2.686570347999975, 9.356336199000111],
                [-2.681103117999953, 9.361343863000172],
                [-2.677656385999967, 9.367500439000139],
                [-2.675166461999936, 9.376250566000124],
                [-2.673562424999886, 9.385395366000068],
                [-2.675238923999871, 9.398140631000103],
                [-2.677158314999872, 9.40707068800009],
                [-2.67971543199991, 9.413839474000042],
                [-2.684338232999949, 9.42028784300004],
                [-2.687547692999942, 9.427501771999971],
                [-2.688578252999946, 9.43247791000016],
                [-2.690050480999958, 9.446935210000163],
                [-2.689373251999882, 9.457211376000032],
                [-2.684504595999954, 9.474549073999981],
                [-2.68409453299995, 9.486262585000077],
                [-2.68707471599987, 9.491439935000187],
                [-2.689924002999931, 9.488793373000078],
                [-2.697669027999893, 9.483942986000102],
                [-2.70721602499998, 9.474797250000108],
                [-2.724808931999974, 9.45102119600017],
                [-2.731300115999886, 9.43963623000019],
                [-2.735869883999897, 9.436561586000153],
                [-2.75339603499998, 9.409569740999984],
                [-2.763180017999957, 9.401107789000093],
                [-2.771070957999939, 9.402104378000047],
                [-2.787198065999974, 9.409834861000093],
                [-2.798727988999815, 9.419262884999966],
                [-2.812664986999948, 9.433238983000138],
                [-2.839153050999812, 9.461215019000122],
                [-2.847218990999977, 9.47093772900007],
                [-2.868674993999889, 9.490051270000151],
                [-2.901225088999979, 9.531004906000078],
                [-2.910804987999882, 9.546441079000147],
                [-2.92548704099994, 9.57372665299999],
                [-2.942409038999927, 9.60855198000013],
                [-2.94334006299988, 9.608530044000076],
                [-2.951240062999943, 9.62102031700016],
                [-2.966125964999947, 9.646689415000026],
                [-2.977535009999883, 9.671972275000087],
                [-2.984721898999908, 9.694582939000043],
                [-2.995203017999813, 9.719653129000108],
                [-3.006691933999946, 9.737351418000173],
                [-3.012835026999937, 9.74297142099999],
                [-3.017246960999955, 9.74334335400016],
                [-3.042778014999954, 9.722829820000186],
                [-3.048254966999878, 9.718810082000118],
                [-3.06009006599993, 9.719489098000167],
                [-3.066194056999848, 9.723270416000048],
                [-3.079849958999887, 9.744599343000061],
                [-3.087779045999923, 9.757998466000174],
                [-3.091551064999919, 9.761137963000124],
                [-3.094314097999927, 9.771194459000185],
                [-3.094959974999881, 9.790949822000073],
                [-3.100509881999926, 9.801179886000057],
                [-3.108095884999898, 9.809299469000166],
                [-3.115108966999912, 9.822943689],
                [-3.120558023999934, 9.839612960000181],
                [-3.125530957999899, 9.844568251999988],
                [-3.137202976999959, 9.848233224000012],
                [-3.145762920999971, 9.847369194000123],
                [-3.165132998999923, 9.842370987000038],
                [-3.184154986999943, 9.842894554000111],
                [-3.186980008999854, 9.844900130000099],
                [-3.192945956999893, 9.852826118999985],
                [-3.19442296099993, 9.856701851000025],
                [-3.194433926999921, 9.868195534000051],
                [-3.193417070999885, 9.885685921000118],
                [-3.194334028999833, 9.896011353],
                [-3.192420006999896, 9.914899826000124],
                [-3.195469616999901, 9.927478790000123],
                [-3.201263903999973, 9.927359582],
                [-3.204051971999832, 9.924702645000025],
                [-3.209424018999869, 9.92442703200004],
                [-3.214313029999914, 9.920919418000096],
                [-3.216167926999901, 9.908700943000156],
                [-3.225028991999977, 9.899109840000165],
                [-3.235666035999884, 9.897533417000034],
                [-3.239795923999907, 9.89515590800005],
                [-3.248394964999875, 9.884193421000077],
                [-3.252650021999955, 9.87629795100014],
                [-3.262531994999961, 9.871747018000121],
                [-3.278863906999902, 9.853049279000174],
                [-3.281157016999941, 9.85162735],
                [-3.288902044999929, 9.85653781800005],
                [-3.296025991999954, 9.853185654000185],
                [-3.311110018999955, 9.853818893000096],
                [-3.311882019999871, 9.853326798000126],
                [-3.318526028999941, 9.859148025000138],
                [-3.319818018999968, 9.865324020000173],
                [-3.311583995999911, 9.88113021800001],
                [-3.310117959999957, 9.888286591000167],
                [-3.310724972999878, 9.894942283000034],
                [-3.315274000999978, 9.901510238000185],
                [-3.320394993999912, 9.902321816000153],
                [-3.334604978999892, 9.894663811999976],
                [-3.343327998999826, 9.890567780000026],
                [-3.349601984999879, 9.891123771000082],
                [-3.356722115999958, 9.898555757000054],
                [-3.366817473999902, 9.90408897399999],
                [-3.397238016999836, 9.916537285000118],
                [-3.40465211899982, 9.916147232000128],
                [-3.420782089999932, 9.911431313000094],
                [-3.433599948999927, 9.914140702000168],
                [-3.460470914999917, 9.923212052000054],
                [-3.469789981999952, 9.92507553200005],
                [-3.479036092999934, 9.92372703600006],
                [-3.492008924999936, 9.922982216000037],
                [-3.503879068999936, 9.9247894290001],
                [-3.523350000999869, 9.924363136000068],
                [-3.549371003999909, 9.926316261000125],
                [-3.568602084999952, 9.925432206000096],
                [-3.579659937999963, 9.9221982950001],
                [-3.592833994999921, 9.920066833000135],
                [-3.599318026999981, 9.919692993000126],
                [-3.608331918999966, 9.928456305999987],
                [-3.619765996999945, 9.94153404300016],
                [-3.632314919999942, 9.95274734600008],
                [-3.64169597699987, 9.957135202000075],
                [-3.648617983999941, 9.9556007380001],
                [-3.654325008999876, 9.951564789000031],
                [-3.673774957999967, 9.940089226000111],
                [-3.680947065999931, 9.939238548000048],
                [-3.69142198499992, 9.94084072100003],
                [-3.708622932999901, 9.942521095000131],
                [-3.711579083999936, 9.939925193000079],
                [-3.719897985999978, 9.938587188000156],
                [-3.726870060999886, 9.939118385000143],
                [-3.751976966999962, 9.931647300000066],
                [-3.772413969999946, 9.922906876000013],
                [-3.777642011999944, 9.918418885000051],
                [-3.78861999499992, 9.91195964799999],
                [-3.809170961999939, 9.888268470000185],
                [-3.814033030999951, 9.887927056000024],
                [-3.835463999999888, 9.89202880900018],
                [-3.855926035999971, 9.894312858000092],
                [-3.882471084999963, 9.898752213000137],
                [-3.900415182999893, 9.90057659199999],
                [-3.904810905999966, 9.892974853000112],
                [-3.918652057999964, 9.87888717800007],
                [-3.928400038999882, 9.879370690000087],
                [-3.93703794399994, 9.871373176000077],
                [-3.944943904999946, 9.872355461999973],
                [-3.952547072999948, 9.870123864000107],
                [-3.958115101999965, 9.870236397000099],
                [-3.961894035999876, 9.873373985000171],
                [-3.964442015999964, 9.873319627000114],
                [-3.968997954999963, 9.869544983000083],
                [-3.972939013999906, 9.86946105900006],
                [-3.9744999399999, 9.866668701000094],
                [-3.973720072999868, 9.862779618000104],
                [-3.978127956999913, 9.852337836000174],
                [-3.976886033999904, 9.848459244000082],
                [-3.972836016999906, 9.843487739000068],
                [-3.975279092999926, 9.83860778800016],
                [-3.980952023999976, 9.83296966600011],
                [-3.987600087999908, 9.829612731999987],
                [-3.993159055999968, 9.82926368700015],
                [-4.000161171999935, 9.831409455000085],
                [-4.008618830999865, 9.836516380000091],
                [-4.017138004999879, 9.833804130000033],
                [-4.030593872999873, 9.834202765999976],
                [-4.035614967999948, 9.830647469999974],
                [-4.044519900999887, 9.818389893000187],
                [-4.042901514999926, 9.80163955800009],
                [-4.063473223999949, 9.799074173000179],
                [-4.066315173999897, 9.801533698000014],
                [-4.078907011999831, 9.80445099000002],
                [-4.092123985999876, 9.814016343000162],
                [-4.109220027999925, 9.820960999000135],
                [-4.123395918999961, 9.831651687000033],
                [-4.130127906999917, 9.831949234000035],
                [-4.135402201999966, 9.829524040000138],
                [-4.140799044999938, 9.82272720400016],
                [-4.145171164999908, 9.812047006],
                [-4.167057037999939, 9.79726028500005],
                [-4.173180101999947, 9.782629967000105],
                [-4.180419920999896, 9.775327683000057],
                [-4.209064005999892, 9.761985780000032],
                [-4.220201967999969, 9.762400626999977],
                [-4.227609157999837, 9.761990548000085],
                [-4.231705188999968, 9.758899688000156],
                [-4.235301017999973, 9.754444122000052],
                [-4.245934008999939, 9.753264426000158],
                [-4.252425192999908, 9.75345516300007],
                [-4.258786200999964, 9.756114960000104],
                [-4.263636112999905, 9.754612922000035],
                [-4.268815993999851, 9.758172989000116],
                [-4.271103857999947, 9.75697231300012],
                [-4.277658939999924, 9.73958683],
                [-4.27667903899993, 9.737310409000031],
                [-4.26717710399987, 9.737292290000084],
                [-4.265703199999962, 9.733647346999987],
                [-4.271297931999925, 9.725017548000153],
                [-4.270020007999904, 9.719760894000103],
                [-4.27104091599989, 9.713760375000163],
                [-4.275557994999929, 9.708601951000048],
                [-4.275143146999938, 9.700569152000014],
                [-4.279508113999896, 9.698859214000151],
                [-4.284428119999916, 9.691164016000073],
                [-4.280090808999944, 9.683908464000126],
                [-4.283846854999979, 9.67600631800019],
                [-4.289483069999847, 9.66921424800006],
                [-4.287248134999913, 9.662596703000133],
                [-4.28962898199984, 9.655418396000073],
                [-4.288808822999897, 9.64992141800019],
                [-4.290851115999885, 9.637924194000163],
                [-4.300446032999901, 9.622078896000062],
                [-4.318694115999961, 9.599603653000031],
                [-4.350810050999883, 9.625996590000057],
                [-4.361241816999893, 9.62621784300012],
                [-4.36741399899995, 9.622632980000105],
                [-4.370800971999813, 9.618877411000142],
                [-4.373189926999942, 9.612159729000155],
                [-4.371354102999931, 9.602774620000162],
                [-4.374915122999937, 9.596488952000186],
                [-4.380186079999874, 9.594067574],
                [-4.38946199399993, 9.594547271000181],
                [-4.394382952999933, 9.596962929000028],
                [-4.404334068999958, 9.60638904600006],
                [-4.41067981599997, 9.620030403000158],
                [-4.417085170999826, 9.64632034300007],
                [-4.420235156999866, 9.652222634000168],
                [-4.427813053999898, 9.659176827000124],
                [-4.433446882999931, 9.66226482400009],
                [-4.437623023999947, 9.662397386000066],
                [-4.450943947999974, 9.657494546000123],
                [-4.466132164999976, 9.653004646000056],
                [-4.475834846999874, 9.651860237000108],
                [-4.493433951999975, 9.651454926000099],
                [-4.500072954999951, 9.648081780000041],
                [-4.507919787999924, 9.646747590000132],
                [-4.510520934999931, 9.648988725000038],
                [-4.514164924999932, 9.656026839999981],
                [-4.518874168999901, 9.659137725999983],
                [-4.519351958999891, 9.66947078700008],
                [-4.521780014999933, 9.67401027700015],
                [-4.521245956999962, 9.68091869400007],
                [-4.523272991999875, 9.688225746000114],
                [-4.5220580099999, 9.705265044999976],
                [-4.509787082999878, 9.71543502899999],
                [-4.50734090799989, 9.719859123000106],
                [-4.505350111999917, 9.733469964000165],
                [-4.50677585699998, 9.739757538000106],
                [-4.509500026999945, 9.74233531900012],
                [-4.519320964999906, 9.746013641000047],
                [-4.552451133999966, 9.74546909300011],
                [-4.559287071999961, 9.74048233000002],
                [-4.560608863999903, 9.737692833999972],
                [-4.554742811999915, 9.724957467000081],
                [-4.547039985999959, 9.722840310000151],
                [-4.545345783999949, 9.719659806000038],
                [-4.547520160999966, 9.713633536999964],
                [-4.556240081999931, 9.700330734000033],
                [-4.56481409099996, 9.690702439000177],
                [-4.570991037999875, 9.687339782000038],
                [-4.580502033999892, 9.687805176000154],
                [-4.590154170999938, 9.694012642000018],
                [-4.591742037999893, 9.702252388000034],
                [-4.593667983999978, 9.705195425999989],
                [-4.599329948999866, 9.709428787000093],
                [-4.603180883999926, 9.715313913000102],
                [-4.609457970999927, 9.716083527000023],
                [-4.612757205999969, 9.71830749600008],
                [-4.614705085999958, 9.722167016000014],
                [-4.61772489599997, 9.722555160000184],
                [-4.619750975999921, 9.719979286000012],
                [-4.619050024999922, 9.713210107000123],
                [-4.622980593999955, 9.71001815700015],
                [-4.625840185999948, 9.70374012000002],
                [-4.628343105999932, 9.701616287999968],
                [-4.631364821999853, 9.702004432000138],
                [-4.635878085999877, 9.706494332000034],
                [-4.644474983999942, 9.707439424000029],
                [-4.648579120999955, 9.714238167000076],
                [-4.652750014999924, 9.714139938000073],
                [-4.657309055999917, 9.711043358000154],
                [-4.659739971999898, 9.706158639000023],
                [-4.659070014999884, 9.697670936000065],
                [-4.665802955999823, 9.68877315400016],
                [-4.668804168999884, 9.688241959000152],
                [-4.674654960999931, 9.690630914],
                [-4.678194998999913, 9.68365192400006],
                [-4.682251453999925, 9.679189683000061],
                [-4.685697077999976, 9.67772579199999],
                [-4.692399979999891, 9.677105905000133],
                [-4.694512843999917, 9.678202629000111],
                [-4.696104048999871, 9.686440468000058],
                [-4.707960128999957, 9.68799019],
                [-4.712289809999902, 9.694330215000093],
                [-4.720468044999961, 9.715970039000013],
                [-4.724789142999896, 9.722071647000064],
                [-4.727530002999913, 9.729820251999968],
                [-4.736941814999909, 9.73557090700018],
                [-4.738680838999926, 9.740357399000118],
                [-4.737690448999956, 9.747047425000119],
                [-4.7410101899999, 9.75018692000009],
                [-4.749420166999869, 9.752739907000034],
                [-4.771463869999877, 9.753817559000026],
                [-4.77272891899986, 9.748730659000103],
                [-4.771039961999975, 9.736820221000187],
                [-4.776823997999941, 9.736215592000121],
                [-4.781280039999956, 9.738406182],
                [-4.785090922999871, 9.742451667000182],
                [-4.786324023999953, 9.754836082000168],
                [-4.790092944999913, 9.757272721000106],
                [-4.800240040999938, 9.755187034000073],
                [-4.800981044999958, 9.75700759900019],
                [-4.799089908999974, 9.764639854000109],
                [-4.800590991999911, 9.769202232999987],
                [-4.803852081999878, 9.769812583999965],
                [-4.806358814999896, 9.768141746000083],
                [-4.809833049999952, 9.768057824000095],
                [-4.818130015999884, 9.784640312000079],
                [-4.816321848999962, 9.786520957000164],
                [-4.809319973999948, 9.78462028500013],
                [-4.79074096599993, 9.78323364300013],
                [-4.788766860999886, 9.78787899100007],
                [-4.793059826999922, 9.792599679000034],
                [-4.796269893999977, 9.80056953500008],
                [-4.7930698399999, 9.811679841000114],
                [-4.794373035999968, 9.817628860000184],
                [-4.793580055999883, 9.822935104000123],
                [-4.786810873999968, 9.830222130000152],
                [-4.78873920399991, 9.833395959000143],
                [-4.795986175999929, 9.835748674000172],
                [-4.804328917999897, 9.844971655999984],
                [-4.808039188999942, 9.84488296500001],
                [-4.813311100999954, 9.842454911000061],
                [-4.820495129999927, 9.842277527000078],
                [-4.829549788999941, 9.85194015500008],
                [-4.829102992999935, 9.862070085000141],
                [-4.831470011999898, 9.863849641000115],
                [-4.845954894999977, 9.868324280000081],
                [-4.851668834999884, 9.864967346000128],
                [-4.85722494099997, 9.864600181000014],
                [-4.864612101999967, 9.872465134000038],
                [-4.880340098999909, 9.870699882000167],
                [-4.88278102899983, 9.875697136000099],
                [-4.886099815999955, 9.878604889000087],
                [-4.894209861999968, 9.87839985000005],
                [-4.913843153999949, 9.893553735000069],
                [-4.919602870999938, 9.892030716000079],
                [-4.928030968999963, 9.886075974000164],
                [-4.930736064999962, 9.88302040100018],
                [-4.931260109999926, 9.876110078000124],
                [-4.934440136999854, 9.87349986999999],
                [-4.939370154999892, 9.875908851000133],
                [-4.940854071999922, 9.879550934000122],
                [-4.956011771999897, 9.882850646000065],
                [-4.967852114999914, 9.892443658000161],
                [-4.972650051999949, 9.907730102000187],
                [-4.97178983699996, 9.91028022900008],
                [-4.964910506999956, 9.913669585000036],
                [-4.96142005899992, 9.92203044900009],
                [-4.960219859999938, 9.929650307000145],
                [-4.964613914999973, 9.938273431000027],
                [-4.961500166999883, 9.943409919000146],
                [-4.958300114999872, 9.945560455000077],
                [-4.949759959999881, 9.947150230999966],
                [-4.94688177199987, 9.952507973000081],
                [-4.948804854999935, 9.955220222000037],
                [-4.959114074999889, 9.959101677000149],
                [-4.963140963999933, 9.96245193600015],
                [-4.9633297929999, 9.96979999500013],
                [-4.966681002999906, 9.973856926],
                [-4.983089923999842, 9.980569840000157],
                [-4.98783016099992, 9.98437023100007],
                [-4.989560126999947, 10.006620408000174],
                [-4.988269805999948, 10.010560036000129],
                [-4.986239908999949, 10.011690141000145],
                [-4.964433191999831, 10.00403118200012],
                [-4.964079857999877, 10.008410455000046],
                [-4.96839523299991, 10.013818741000023],
                [-4.96208381699995, 10.021100999000055],
                [-4.961452006999878, 10.023649216000138],
                [-4.969731807999835, 10.029879571000038],
                [-4.971022128999948, 10.03489875800011],
                [-4.970470904999957, 10.040670396000166],
                [-4.971673965999969, 10.042248725000093],
                [-4.982419967999931, 10.044969558000048],
                [-4.990244863999976, 10.051671029000033],
                [-4.993729591999966, 10.060778619000075],
                [-5.004395961999933, 10.065560340000047],
                [-5.012339591999933, 10.063449859000116],
                [-5.017640112999914, 10.06494998900007],
                [-5.021224975999871, 10.070321083000067],
                [-5.022079943999927, 10.07355976000008],
                [-5.020103930999937, 10.07835102199999],
                [-5.014894008999931, 10.082831382000165],
                [-5.009222983999905, 10.096719743000051],
                [-5.003714084999899, 10.103949547000184],
                [-5.010413168999889, 10.105259895000074],
                [-5.016788959999929, 10.100811005000025],
                [-5.023056983999936, 10.100502015000018],
                [-5.027285099999915, 10.098527908000051],
                [-5.032959937999976, 10.094059943000104],
                [-5.036697863999905, 10.09300136600018],
                [-5.041448115999913, 10.088508606000062],
                [-5.045100211999966, 10.081009864000066],
                [-5.04949998799998, 10.081568718000142],
                [-5.052467822999972, 10.083708763],
                [-5.062983034999945, 10.080730439000149],
                [-5.065199851999978, 10.085149766000143],
                [-5.063231943999938, 10.089710237000077],
                [-5.047349929999939, 10.103830337999966],
                [-5.047760963999906, 10.106140137000068],
                [-5.051681040999881, 10.10715198400004],
                [-5.058930872999952, 10.104790688000037],
                [-5.06628608699998, 10.107950211000059],
                [-5.076529979999975, 10.106579780000118],
                [-5.079954147999956, 10.108959199000083],
                [-5.081654071999822, 10.115669251000156],
                [-5.078672884999889, 10.123881341000185],
                [-5.073860167999953, 10.121239662000164],
                [-5.06637001099989, 10.124050141000112],
                [-5.063138007999896, 10.12329101600011],
                [-5.060229778999883, 10.118620872],
                [-5.057240008999941, 10.117401124000025],
                [-5.054420948999962, 10.118940354000188],
                [-5.047924041999977, 10.118789672000162],
                [-5.044092177999914, 10.12376022300009],
                [-5.049242018999962, 10.131701470000166],
                [-5.068210124999894, 10.134679794000022],
                [-5.079864978999922, 10.142310142000042],
                [-5.081592081999929, 10.14787101700017],
                [-5.080300331999979, 10.153360367000175],
                [-5.081140995999931, 10.157060623000064],
                [-5.077033995999898, 10.163859367000043],
                [-5.073595046999969, 10.181730271000049],
                [-5.07472801299997, 10.182901383000114],
                [-5.095466136999903, 10.179700851000177],
                [-5.105080127999941, 10.17555999700005],
                [-5.110403059999953, 10.176368713000159],
                [-5.114699840999947, 10.18130016400005],
                [-5.111595152999939, 10.184911728000088],
                [-5.098529815999882, 10.187829972000145],
                [-5.091250896999895, 10.20100021400009],
                [-5.088479994999887, 10.210359573000062],
                [-5.089075087999902, 10.219030381000039],
                [-5.094820976999927, 10.226840973000037],
                [-5.102465152999912, 10.217820168000173],
                [-5.122930048999876, 10.20899009599998],
                [-5.117050170999903, 10.223580359999971],
                [-5.108819960999824, 10.228960037000036],
                [-5.107240198999932, 10.237950326000032],
                [-5.113820074999978, 10.2381095880001],
                [-5.119619846999967, 10.242179871000133],
                [-5.120870112999967, 10.24973011000003],
                [-5.118760108999823, 10.256420136000031],
                [-5.113920211999925, 10.25914955200011],
                [-5.106709956999964, 10.261670112000104],
                [-5.100249766999923, 10.26856994600007],
                [-5.099020003999954, 10.272100449000106],
                [-5.10406017299988, 10.27563953400005],
                [-5.120500087999972, 10.278770446000124],
                [-5.120882032999873, 10.282369615000107],
                [-5.117819786999974, 10.285960198000112],
                [-5.1146402359999, 10.291480064000154],
                [-5.111060142999975, 10.30467987000003],
                [-5.114729881999949, 10.304120064000074],
                [-5.124259948999963, 10.298489570000129],
                [-5.128709792999871, 10.307089806000079],
                [-5.131440162999922, 10.307900429000085],
                [-5.13249015699995, 10.301059723000037],
                [-5.13685989399994, 10.300290107000137],
                [-5.147999763999962, 10.287960052000017],
                [-5.157509803999915, 10.283929825000087],
                [-5.170899866999889, 10.285599709000053],
                [-5.174950121999927, 10.29139041999997],
                [-5.187560081999948, 10.284790038000097],
                [-5.191160201999935, 10.290650368000058],
                [-5.187210083999901, 10.298970223000026],
                [-5.196112155999913, 10.304848671],
                [-5.206015109999953, 10.308529853000152],
                [-5.208045959999936, 10.311109544000146],
                [-5.205605982999941, 10.31611061100017],
                [-5.206855773999962, 10.322349548000034],
                [-5.210111141999903, 10.32219123800013],
                [-5.222489356999972, 10.30936050400004],
                [-5.232100009999954, 10.31556034],
                [-5.250242232999938, 10.314359666000144],
                [-5.259325980999961, 10.31319046100009],
                [-5.266370772999949, 10.30989933],
                [-5.270209787999932, 10.314820290000057],
                [-5.278551101999938, 10.315690993999965],
                [-5.280673025999874, 10.314131738000128],
                [-5.281252860999928, 10.30909061400007],
                [-5.286124228999825, 10.309420585000112],
                [-5.29543495199988, 10.318840026000089],
                [-5.300286769999957, 10.31986141200008],
                [-5.304965971999934, 10.318360329000143],
                [-5.308050155999979, 10.31544017900012],
                [-5.30209016699996, 10.312081337],
                [-5.301476955999931, 10.308390617000157],
                [-5.303880215999925, 10.304759979000153],
                [-5.307119844999932, 10.305529595000053],
                [-5.309153079999931, 10.307870865000154],
                [-5.313941001999922, 10.301540375000059],
                [-5.32068109599993, 10.301460267000152],
                [-5.329689979999955, 10.293379783000034],
                [-5.334569453999961, 10.293259620000129],
                [-5.346030235999876, 10.29994964600013],
                [-5.352028369999971, 10.301699638000173],
                [-5.354701041999931, 10.306819916000165],
                [-5.359172821999948, 10.30416107100018],
                [-5.365120887999922, 10.28750038200019],
                [-5.368192196999928, 10.285269738000181],
                [-5.374217033999969, 10.28586101600007],
                [-5.38215112599994, 10.294551850000119],
                [-5.391724109999927, 10.29222869900019],
                [-5.39676284799998, 10.295331002000069],
                [-5.407209872999943, 10.295559883000124],
                [-5.417191027999934, 10.306130410000094],
                [-5.423182010999938, 10.308340071999964],
                [-5.423902987999895, 10.317781449],
                [-5.428198813999927, 10.322942734000094],
                [-5.432850836999876, 10.32258033900007],
                [-5.438035964999926, 10.319238662000032],
                [-5.440806864999956, 10.31998920500007],
                [-5.444407940999895, 10.325131416000033],
                [-5.444709777999947, 10.332500459000187],
                [-5.446682929999952, 10.33784198699999],
                [-5.451479911999911, 10.34138965600016],
                [-5.461451053999895, 10.342068672000039],
                [-5.468103885999938, 10.345900537000091],
                [-5.47260999699995, 10.352211952000175],
                [-5.467190265999932, 10.355772019000085],
                [-5.4676032079999, 10.358079910000185],
                [-5.464990137999962, 10.360779762000107],
                [-5.469902037999873, 10.369630814000061],
                [-5.468659876999936, 10.373279572000172],
                [-5.472764969999957, 10.376818658000047],
                [-5.478808879999974, 10.376488685000027],
                [-5.482824802999915, 10.373590469000021],
                [-5.48807382699988, 10.377849578999985],
                [-5.489180088999945, 10.38039970400007],
                [-5.48652982699997, 10.384710313000141],
                [-5.499718189999953, 10.40822982800006],
                [-5.502954006999971, 10.408991813000114],
                [-5.509521006999876, 10.416731836000054],
                [-5.50910091499992, 10.425230027000055],
                [-5.513797759999875, 10.42303085300017],
                [-5.517509936999886, 10.423339843000122],
                [-5.515540598999849, 10.428360938000026],
                [-5.518918036999878, 10.433259965000104],
                [-5.524500846999956, 10.43292045700008],
                [-5.530447959999947, 10.437191010000163],
                [-5.533916949999934, 10.437958718000118],
                [-5.543715, 10.446911812000167],
                [-5.553559780999819, 10.453561784000044],
                [-5.563094139999976, 10.46389103000007],
                [-5.569808006999949, 10.464960098000063],
                [-5.579412936999916, 10.461480140000106],
                [-5.589561939999953, 10.454341888000101],
                [-5.597033022999938, 10.4526596070001],
                [-5.611876963999976, 10.454130173000067],
                [-5.626298903999896, 10.453280449000147],
                [-5.640603064999937, 10.45819091800007],
                [-5.653420924999978, 10.456391334000045],
                [-5.672576904999914, 10.462539673000094],
                [-5.676299094999877, 10.462869645000069],
                [-5.679665089999958, 10.461158753000063],
                [-5.700797079999916, 10.44452095100013],
                [-5.708201884999937, 10.441040993000115],
                [-5.735237120999955, 10.437451362000047],
                [-5.74580097199987, 10.431400298000142],
                [-5.757822989999909, 10.43144130800016],
                [-5.772508144999961, 10.44025039799999],
                [-5.777385233999894, 10.44036197600019],
                [-5.790522098999873, 10.434419632000186],
                [-5.804190159999962, 10.42527198800002],
                [-5.809579849999977, 10.423081397999965],
                [-5.814229964999925, 10.422940254000025],
                [-5.820330143999911, 10.419175148000079],
                [-5.82512903099996, 10.412820815000032],
                [-5.829511165999975, 10.403249741000025],
                [-5.828927993999969, 10.397709847000101],
                [-5.848290918999965, 10.382701874000134],
                [-5.858805178999944, 10.379241944],
                [-5.864870070999928, 10.378700256000116],
                [-5.870821, 10.380739212000094],
                [-5.884331225999972, 10.37325859100008],
                [-5.888857841999879, 10.367600441000036],
                [-5.888347148999912, 10.358160018999968],
                [-5.893426893999845, 10.347910881000189],
                [-5.896440029999951, 10.336469650000026],
                [-5.895481109999878, 10.326330185000188],
                [-5.906066893999935, 10.31964015900013],
                [-5.909711836999975, 10.311430931000189],
                [-5.911077022999962, 10.30109977700016],
                [-5.905829905999951, 10.284440994000022],
                [-5.909172056999978, 10.27991104199998],
                [-5.9124717709999, 10.277440071000115],
                [-5.924584865999918, 10.27561187800012],
                [-5.940691948999927, 10.28350925500007],
                [-5.957285880999962, 10.278320313000108],
                [-5.964036940999961, 10.277528762999964],
                [-5.970970152999939, 10.279500008000127],
                [-5.976113795999936, 10.27775859899998],
                [-5.97453308199988, 10.2634687420001],
                [-5.976357937999978, 10.253160476000176],
                [-5.983592033999969, 10.239501001000178],
                [-5.987079142999903, 10.227378845000146],
                [-5.990684985999963, 10.221011162000025],
                [-6.011456966999901, 10.203701019000164],
                [-6.023159980999935, 10.198860169],
                [-6.045869826999933, 10.201369286000045],
                [-6.051166057999978, 10.203768731000082],
                [-6.060688971999923, 10.203721047000045],
                [-6.080059051999967, 10.198570253000184],
                [-6.100946902999965, 10.199198723000166],
                [-6.121273040999881, 10.205100059000074],
                [-6.132554053999968, 10.21037197100003],
                [-6.149538039999868, 10.22103977300003],
                [-6.158081054999911, 10.223731995000037],
                [-6.172730921999971, 10.222628593000024],
                [-6.183538913999882, 10.228580474000182],
                [-6.195426940999937, 10.238690376000022],
                [-6.193562030999942, 10.251530647000038],
                [-6.197762964999981, 10.252929688000108],
                [-6.199669836999931, 10.256961822000108],
                [-6.20197391499994, 10.25792789400009],
                [-6.21732902499997, 10.256381035000118],
                [-6.222620964999976, 10.259011268000052],
                [-6.223703860999876, 10.262940407000144],
                [-6.22574090899991, 10.281690598000182],
                [-6.231387137999946, 10.290478707000148],
                [-6.23928403799988, 10.297969818000126],
                [-6.232809066999892, 10.304969789000097],
                [-6.225134849999961, 10.308968544000095],
                [-6.219016075999946, 10.313220025000078],
                [-6.21364402699993, 10.31472873600012],
                [-6.205098150999902, 10.313653945999988],
                [-6.201686858999835, 10.308520318000149],
                [-6.199576854999975, 10.302496910000059],
                [-6.194834232999938, 10.302639962000171],
                [-6.19208812699992, 10.31248188100011],
                [-6.185461998999926, 10.318790436000086],
                [-6.187479017999976, 10.32250785799999],
                [-6.192767142999969, 10.325369835000117],
                [-6.197731017999899, 10.332830429000126],
                [-6.198348044999875, 10.336750031000065],
                [-6.194942950999973, 10.344731332000094],
                [-6.191610813999944, 10.348811150000131],
                [-6.186484813999925, 10.349629402000176],
                [-6.174644946999877, 10.361127853000028],
                [-6.167201995999903, 10.361680984000088],
                [-6.165544987999851, 10.363261223000166],
                [-6.166647911999974, 10.366271974000142],
                [-6.174057006999874, 10.367562294000152],
                [-6.183956145999957, 10.372119903000055],
                [-6.187497138999959, 10.381158829000071],
                [-6.19249296299995, 10.387008668000135],
                [-6.188680171999863, 10.392001152000034],
                [-6.184857844999954, 10.40916919700004],
                [-6.177574156999924, 10.41339874200014],
                [-6.17140197699996, 10.408221245000107],
                [-6.167903900999875, 10.40884971600019],
                [-6.164123058999962, 10.412220955000123],
                [-6.16167306899996, 10.418621064000035],
                [-6.163660049999919, 10.423950196000078],
                [-6.168450831999962, 10.42840957600015],
                [-6.18454408599996, 10.437230111000133],
                [-6.187439918999928, 10.443499564999968],
                [-6.189959048999981, 10.44539165600014],
                [-6.191060065999977, 10.460591316000091],
                [-6.188858032999917, 10.466070175000027],
                [-6.182928084999901, 10.472390174000054],
                [-6.168344020999882, 10.481310844000063],
                [-6.164747237999904, 10.485549928000182],
                [-6.167459009999959, 10.49096107400004],
                [-6.170403957999895, 10.494691849000105],
                [-6.178483008999933, 10.497611046000145],
                [-6.194512844999906, 10.497920990000011],
                [-6.198727129999895, 10.496390343000144],
                [-6.204998968999973, 10.496509552000134],
                [-6.20861101099996, 10.501871110000025],
                [-6.205926894999891, 10.508489608000161],
                [-6.208161829999938, 10.513130188000105],
                [-6.209784983999896, 10.513401031],
                [-6.212522028999899, 10.518619538000053],
                [-6.215240955999889, 10.519479753000098],
                [-6.220252990999882, 10.524871826000094],
                [-6.225763796999956, 10.522559167000168],
                [-6.231937885999912, 10.514451980000047],
                [-6.237792967999951, 10.513991356000076],
                [-6.245985031999908, 10.516388893000169],
                [-6.249825, 10.52198028500004],
                [-6.251119137999979, 10.527301789000148],
                [-6.246397018999971, 10.543080329000077],
                [-6.239521026999967, 10.550079347000064],
                [-6.226181028999918, 10.554651261000174],
                [-6.212160109999957, 10.570490838000069],
                [-6.210649013999898, 10.576439857000139],
                [-6.210281848999898, 10.583330155000056],
                [-6.215881823999894, 10.582289696000032],
                [-6.21531200499993, 10.58780098],
                [-6.210029124999949, 10.596441269000024],
                [-6.210415839999882, 10.600361823000071],
                [-6.212737083999912, 10.60062790000012],
                [-6.219079016999842, 10.597300530000155],
                [-6.221611976999895, 10.598499298000036],
                [-6.225121021999883, 10.609379768000053],
                [-6.22315215999987, 10.615100861000087],
                [-6.218636989999936, 10.620072364000123],
                [-6.215123175999906, 10.621379853000064],
                [-6.203052997999976, 10.620458602000099],
                [-6.198139189999949, 10.621978761000037],
                [-6.190774917999875, 10.629890442000146],
                [-6.18853902799998, 10.633621216000108],
                [-6.19196891799993, 10.640259743000058],
                [-6.200592994999909, 10.639280319000022],
                [-6.204709052999931, 10.642812730000173],
                [-6.213015079999934, 10.646189690000142],
                [-6.215950011999951, 10.65061187800012],
                [-6.216343880999943, 10.654299736000041],
                [-6.222081184999979, 10.658320427000092],
                [-6.230935096999929, 10.657340050000073],
                [-6.235434054999928, 10.665250779000132],
                [-6.234645842999896, 10.670419693000042],
                [-6.225520132999975, 10.684720994000031],
                [-6.233303069999977, 10.699998856999969],
                [-6.226550101999919, 10.704092026000183],
                [-6.219531059999952, 10.711669922000112],
                [-6.214879036999946, 10.722028732000126],
                [-6.217734812999822, 10.726328851],
                [-6.246427057999881, 10.733540536000135],
                [-6.248219966999898, 10.736080170000093],
                [-6.256415843999946, 10.736639977000152],
                [-6.27492284799996, 10.729847908000124],
                [-6.28316879199997, 10.72425079300001],
                [-6.289702892999969, 10.723222733000057],
                [-6.310575008999933, 10.71417045600009],
                [-6.319074153999907, 10.707419395000045],
                [-6.324678896999956, 10.706138612000018],
                [-6.350481033999813, 10.694648744000062],
                [-6.35375976499995, 10.706209183000112],
                [-6.36068391699996, 10.709320069000114],
                [-6.376485824999918, 10.71029090900015],
                [-6.382493018999924, 10.712699890000067],
                [-6.390677929999924, 10.710321426000121],
                [-6.396708011999976, 10.711351394000076],
                [-6.416440963999946, 10.70065879800012],
                [-6.419569968999952, 10.69472885200014],
                [-6.421504973999902, 10.67751026100018],
                [-6.420338154999968, 10.625510216000123],
                [-6.408260821999932, 10.624600411000074],
                [-6.400442122999948, 10.619628906],
                [-6.396594046999951, 10.61358070500006],
                [-6.396480083999961, 10.607139588000166],
                [-6.401051997999957, 10.598481178000156],
                [-6.415155886999912, 10.589988709000124],
                [-6.431512832999886, 10.572569848000114],
                [-6.444196224999928, 10.56542873400008],
                [-6.461877823999942, 10.564829826000107],
                [-6.490705011999978, 10.578440666000063],
                [-6.499557018999838, 10.577679635000038],
                [-6.504370211999913, 10.574844360000043],
                [-6.513088225999866, 10.576779366000096],
                [-6.539867877999882, 10.586931229000072],
                [-6.544927119999954, 10.590009690000159],
                [-6.548801898999955, 10.59421920800014],
                [-6.55282688099993, 10.603030205000039],
                [-6.558588980999957, 10.60589981000004],
                [-6.5652031899999, 10.61291980700014],
                [-6.573492048999981, 10.617439271000137],
                [-6.595581053999979, 10.621898652000084],
                [-6.611220836999905, 10.639731408000102],
                [-6.615974901999891, 10.659831047000068],
                [-6.623500824999894, 10.668010713000058],
                [-6.642239092999887, 10.672245025999985],
                [-6.644699095999954, 10.666091918000177],
                [-6.651793955999892, 10.659311295],
                [-6.658586025999966, 10.643560410000191],
                [-6.65678119699993, 10.640528679999989],
                [-6.650002001999951, 10.64249134000005],
                [-6.646574973999918, 10.639200211000116],
                [-6.649199961999955, 10.635341645000096],
                [-6.65412807499996, 10.632901193000123],
                [-6.667661188999944, 10.630141259000027],
                [-6.67191410099997, 10.626310349000164],
                [-6.673395157999948, 10.621500969000124],
                [-6.672110079999925, 10.615268707000155],
                [-6.676987170999951, 10.602470399000083],
                [-6.677052973999878, 10.598791122000023],
                [-6.675226210999881, 10.59692001400009],
                [-6.669197082999858, 10.596131325000158],
                [-6.666707991999942, 10.592169762000026],
                [-6.67101097099993, 10.572468758000127],
                [-6.6692290299999, 10.554949760000056],
                [-6.670248030999971, 10.549910546000035],
                [-6.663970945999893, 10.536919595000143],
                [-6.665411948999918, 10.53441047699999],
                [-6.674056053999891, 10.531801223000116],
                [-6.679379940999866, 10.533049584000139],
                [-6.68146085799998, 10.5204296120001],
                [-6.677505015999884, 10.507710457000087],
                [-6.684803009999939, 10.48944091800007],
                [-6.682528017999914, 10.486641884000107],
                [-6.676743984999973, 10.485162735000188],
                [-6.67632198299998, 10.482619286000158],
                [-6.681191920999936, 10.470049859000142],
                [-6.679163932999927, 10.46657180900013],
                [-6.674959182999942, 10.467870712000035],
                [-6.66608619799996, 10.457140922000065],
                [-6.658929824999973, 10.454481124999973],
                [-6.653306960999942, 10.457140922000065],
                [-6.648674011999958, 10.456370353000182],
                [-6.644309044999943, 10.453531265000095],
                [-6.643432140999948, 10.450531007],
                [-6.646093845999928, 10.444601058999979],
                [-6.644506931999956, 10.442269325000154],
                [-6.638257980999924, 10.441011429000184],
                [-6.634746073999963, 10.442561149000142],
                [-6.631268023999951, 10.442029954000134],
                [-6.630167006999898, 10.438791275000142],
                [-6.631790160999913, 10.425938607000035],
                [-6.637574196999935, 10.414311409999982],
                [-6.645885943999929, 10.404111862000036],
                [-6.653161049999881, 10.400100709000071],
                [-6.655355929999928, 10.395918845999972],
                [-6.654057026999851, 10.393570900000043],
                [-6.66494703199993, 10.38179874399998],
                [-6.672196863999886, 10.365130425000132],
                [-6.678081034999934, 10.360630989000128],
                [-6.690465927999981, 10.35647106200014],
                [-6.699295996999979, 10.356380463000164],
                [-6.709235191999881, 10.359540939],
                [-6.722694872999966, 10.374489784000104],
                [-6.764007090999883, 10.391502381000066],
                [-6.764727114999971, 10.390131950000068],
                [-6.774264811999956, 10.3893709190001],
                [-6.797387122999965, 10.382391929000164],
                [-6.839323042999922, 10.37549114300009],
                [-6.854406832999928, 10.362389564000068],
                [-6.86532783399997, 10.362339974000065],
                [-6.898900031999972, 10.369778633000124],
                [-6.91075801799991, 10.369279862000155],
                [-6.934069157999943, 10.364589692000038],
                [-6.941224097999964, 10.367700576000061],
                [-6.947463987999981, 10.369640351000044],
                [-6.952394008999931, 10.366501809000056],
                [-6.966618060999906, 10.348550796000097],
                [-6.977698801999964, 10.33837890699999],
                [-6.995326042999977, 10.310601235000092],
                [-6.997068881999837, 10.30303001499999],
                [-6.99498796499995, 10.287818910000055],
                [-6.989507197999899, 10.281761169000049],
                [-6.987092971999971, 10.27275180900017],
                [-6.978427886999953, 10.262491226],
                [-6.970952987999908, 10.250417710000079],
                [-6.9667639729999, 10.236319542000103],
                [-6.965371131999916, 10.221581459000049],
                [-6.966001986999913, 10.211009979000039],
                [-6.969637869999929, 10.201180458000067],
                [-6.974332809999964, 10.19804191600008],
                [-6.975772856999924, 10.19507026700012],
                [-7.016118049999875, 10.169031144000087],
                [-7.03016710299994, 10.161430360000054],
                [-7.040181159999861, 10.159510613000123],
                [-7.047339915999942, 10.161919593000107],
                [-7.063545226999963, 10.180111885000088],
                [-7.069890021999925, 10.190328599000054],
                [-7.077493190999917, 10.209300996000138],
                [-7.082468031999952, 10.217890741000076],
                [-7.089560985999924, 10.2249011990001],
                [-7.11874103599996, 10.230179787000054],
                [-7.136683939999898, 10.241491319000033],
                [-7.15388488799988, 10.240599632000055],
                [-7.161522865999928, 10.24232101500013],
                [-7.168795108999973, 10.253009796000129],
                [-7.178483962999962, 10.257301330000132],
                [-7.201509951999981, 10.255108833000179],
                [-7.216804981999871, 10.257411003000072],
                [-7.226522921999958, 10.259860993000075],
                [-7.233416079999927, 10.265021324000088],
                [-7.244510174999903, 10.268630981000058],
                [-7.25959587199992, 10.269318581000107],
                [-7.281507968999904, 10.264121055000146],
                [-7.285418986999957, 10.266480445000127],
                [-7.293951988999879, 10.286841392000042],
                [-7.29601717099996, 10.288477898000053],
                [-7.318653106999875, 10.281221391000145],
                [-7.332997798999884, 10.269008636000137],
                [-7.345054148999907, 10.27056789400001],
                [-7.3570499409999, 10.276259423000113],
                [-7.366802214999893, 10.276400567000053],
                [-7.375666140999954, 10.274001122000072],
                [-7.378909109999938, 10.27449989300004],
                [-7.384366988999886, 10.28263187400006],
                [-7.370783806999839, 10.306578636999973],
                [-7.363367081999968, 10.337981223000156],
                [-7.36510896599998, 10.362389564000068],
                [-7.373813151999912, 10.368320465000068],
                [-7.384725092999929, 10.371641160000138],
                [-7.392562864999889, 10.375888825000118],
                [-7.402078150999898, 10.376721383000074],
                [-7.419587135999905, 10.387539863000086],
                [-7.425366878999853, 10.389471054000126],
                [-7.43199205499991, 10.397609710000097],
                [-7.437407015999952, 10.409180642000024],
                [-7.448957919999941, 10.463628769000138],
                [-7.452879905999964, 10.465748788000155],
                [-7.458160876999955, 10.470420837000063],
                [-7.465798853999957, 10.472829819000083],
                [-7.480182170999967, 10.474869728000044],
                [-7.48618507399982, 10.477721214000098],
                [-7.50847482699993, 10.479410173000133],
                [-7.526938913999913, 10.47206878600008],
                [-7.533527850999917, 10.466179848000081],
                [-7.551259040999923, 10.44435024300003],
                [-7.558495997999899, 10.441921234000063],
                [-7.565237045999879, 10.441781996999964],
                [-7.584139824999909, 10.453311920000147],
                [-7.5901780129999, 10.45362186400007],
                [-7.606975078999881, 10.449020385999972],
                [-7.615571974999966, 10.449131966000152],
                [-7.620172977999971, 10.452638626000066],
                [-7.621747969999888, 10.473818779000055],
                [-7.627010820999942, 10.480100633000063],
                [-7.631846904999975, 10.483388902000058],
                [-7.641550064999876, 10.488121033000027],
                [-7.64645004199997, 10.486801147000051],
                [-7.651189803999898, 10.479741096000055],
                [-7.654835224999886, 10.4678297050001],
                [-7.664092063999931, 10.453231811000023],
                [-7.676333904999979, 10.441431046000162],
                [-7.686396120999916, 10.436280252000131],
                [-7.718562125999938, 10.429109573000062],
                [-7.728321075999929, 10.425800323000146],
                [-7.730985165999925, 10.419790269000089],
                [-7.735874174999879, 10.417868614000156],
                [-7.743381023999973, 10.400348663000159],
                [-7.743443011999887, 10.395981789000132],
                [-7.741384982999932, 10.39365959200012],
                [-7.732325075999938, 10.393759727000145],
                [-7.728906155999937, 10.388880731000029],
                [-7.728520869999954, 10.383130074],
                [-7.730207918999952, 10.378779412000085],
                [-7.739307880999945, 10.37569141500012],
                [-7.738195895999979, 10.372220040000059],
                [-7.729668140999934, 10.36751079700008],
                [-7.727279186999965, 10.355508804000067],
                [-7.728498936999927, 10.35116100300013],
                [-7.731810091999876, 10.347069741000041],
                [-7.737410068999964, 10.345070840000119],
                [-7.751582145999976, 10.344808578000084],
                [-7.756731986999967, 10.341889381000044],
                [-7.75933790199997, 10.338009834000104],
                [-7.756937981999954, 10.326939583000126],
                [-7.758625983999878, 10.322361946],
                [-7.760964870999942, 10.32102203400018],
                [-7.771411894999972, 10.321391105999965],
                [-7.774236201999884, 10.318669318000047],
                [-7.775709151999934, 10.312939645000142],
                [-7.77367591899997, 10.308771134000096],
                [-7.758831977999876, 10.307419776000017],
                [-7.75656795499998, 10.303018570000063],
                [-7.762427806999938, 10.299189568000145],
                [-7.764836786999979, 10.292780877000041],
                [-7.77137994799989, 10.289651871000103],
                [-7.777256011999896, 10.284671783000022],
                [-7.782148837999898, 10.283351898000149],
                [-7.786382198999888, 10.279500008000127],
                [-7.79174709299997, 10.277737618000117],
                [-7.797430991999931, 10.26953125100016],
                [-7.800940990999948, 10.267510414000185],
                [-7.810098170999879, 10.243029594],
                [-7.815989970999965, 10.236429215000044],
                [-7.826711178999915, 10.233359338000128],
                [-7.839846133999913, 10.223420143000169],
                [-7.846461771999941, 10.214760781000052],
                [-7.855926989999887, 10.204811096000071],
                [-7.887333869999964, 10.199919701000113],
                [-7.895032882999885, 10.197020530000145],
                [-7.911260127999981, 10.18043994900006],
                [-7.926466940999887, 10.170969962000129],
                [-7.937188147999962, 10.167649269000094],
                [-7.948329924999882, 10.167791366000017],
                [-7.966341971999952, 10.175601006000136],
                [-7.98051881899994, 10.174618721000115],
                [-7.985986231999959, 10.16411018299999],
                [-7.988173961999962, 10.15586185400008],
                [-8.005990027999871, 10.122269630000119],
                [-8.011268614999892, 10.107851982000057],
                [-8.016502379999963, 10.097101211],
                [-8.024494171999947, 10.088688851000143],
                [-8.045100211999966, 10.073300362999987],
                [-8.0791568759999, 10.058758736000016],
                [-8.098949432999916, 10.053019525000082],
                [-8.106679915999962, 10.046900749000088],
                [-8.131426809999937, 10.013851167000041],
                [-8.13101005599998, 10.009700775],
                [-8.134982108999907, 10.00774097600015],
                [-8.137299537999922, 9.992203713000038],
                [-8.147809027999926, 9.98635292099999],
                [-8.15062904299998, 9.983396530000107],
                [-8.151833533999877, 9.979731561000165],
                [-8.150271415999953, 9.974886894000122],
                [-8.143604277999941, 9.969975471000112],
                [-8.138520239999934, 9.96853256300011],
                [-8.127287863999868, 9.97644424400005],
                [-8.121494291999966, 9.97614288300008],
                [-8.11738300199994, 9.971033096000156],
                [-8.117781637999883, 9.957699776000027],
                [-8.120606422999913, 9.95428562100011],
                [-8.123629569999935, 9.953634263000026],
                [-8.13008689999998, 9.956472396000095],
                [-8.148256300999947, 9.948757172000171],
                [-8.164013861999933, 9.951137543000016],
                [-8.166845321999915, 9.947263717000112],
                [-8.165946959999928, 9.944725038000115],
                [-8.153031349999935, 9.939047814000048],
                [-8.152356147999967, 9.93719864000019],
                [-8.155405998999981, 9.934477805000029],
                [-8.16309833399987, 9.931352616000027],
                [-8.165474890999974, 9.926783561999969],
                [-8.160779952999974, 9.912240028000099],
                [-8.156414985999959, 9.908967972000085],
                [-8.152935027999888, 9.909156799000186],
                [-8.142172813999878, 9.916839599000127],
                [-8.13335323299998, 9.91742324900008],
                [-8.132238387999848, 9.913961411000173],
                [-8.135073660999979, 9.909626008000032],
                [-8.127927780999869, 9.906088830000101],
                [-8.124513625999896, 9.900987626000074],
                [-8.124798774999874, 9.89711761500007],
                [-8.128684996999937, 9.882415771000069],
                [-8.130764006999868, 9.881077766000146],
                [-8.134020803999874, 9.882250786000043],
                [-8.138599395999904, 9.886904716000174],
                [-8.140703201999884, 9.885552407000091],
                [-8.144094465999899, 9.873867035000103],
                [-8.143448828999965, 9.869721413000093],
                [-8.137931824999896, 9.86597442600015],
                [-8.130285263999895, 9.86565017700002],
                [-8.123060225999836, 9.868549346],
                [-8.117491721999954, 9.868941307000057],
                [-8.113340377999918, 9.867280006000158],
                [-8.109446524999896, 9.863323211000079],
                [-8.107686996999973, 9.855712892000042],
                [-8.109390259999941, 9.849294663000023],
                [-8.108739851999871, 9.84560966600003],
                [-8.112999916999911, 9.838763238000013],
                [-8.11140728, 9.836215018000019],
                [-8.106848715999888, 9.832987785000114],
                [-8.101484298999935, 9.832644463000122],
                [-8.105089187999965, 9.822341918000063],
                [-8.104505538999831, 9.813366890000168],
                [-8.106197357999918, 9.80787181800008],
                [-8.116115569999977, 9.81167125799999],
                [-8.118724822999923, 9.806876183000043],
                [-8.118066786999918, 9.803878785000165],
                [-8.109334944999887, 9.797790528000121],
                [-8.109597206999922, 9.795266152000124],
                [-8.120546339999919, 9.791033746000096],
                [-8.120694159999971, 9.779076575999966],
                [-8.113455772999885, 9.76473522100008],
                [-8.115331650999963, 9.762918473000013],
                [-8.127864837999823, 9.761464118000049],
                [-8.129758834999961, 9.758266448000143],
                [-8.12356376699995, 9.753133775000094],
                [-8.129503249999914, 9.741480827000146],
                [-8.12424755099994, 9.735439301000156],
                [-8.127100944999938, 9.729496955000172],
                [-8.123189926999885, 9.708526612000128],
                [-8.125121116999935, 9.702342988000055],
                [-8.131613730999959, 9.70196342500003],
                [-8.136388777999969, 9.69052600900011],
                [-8.133197784999936, 9.6861181270001],
                [-8.133279799999968, 9.679681778000031],
                [-8.129448889999878, 9.671128273000079],
                [-8.13063239999991, 9.66884231500012],
                [-8.135270117999937, 9.668440819000125],
                [-8.13668346399993, 9.666620254000065],
                [-8.130000113999927, 9.663778305000164],
                [-8.12773704499989, 9.659381867000036],
                [-8.12779426499992, 9.65478515699999],
                [-8.132756232999952, 9.647030831000052],
                [-8.133578300999943, 9.63669490899997],
                [-8.142211912999926, 9.631511688000046],
                [-8.146223067999927, 9.625583647999974],
                [-8.15178394399993, 9.625423431000115],
                [-8.152274131999889, 9.623129845999983],
                [-8.14426517399994, 9.61521434700012],
                [-8.143600462999814, 9.61290836400019],
                [-8.147597312999949, 9.608127595000099],
                [-8.15292358499994, 9.58911132899999],
                [-8.146282195999902, 9.583056450000072],
                [-8.144314765999923, 9.573603630000036],
                [-8.146450996999931, 9.56949329500003],
                [-8.149935721999952, 9.568616867000173],
                [-8.151818275999858, 9.566109657000027],
                [-8.15073108699994, 9.560349464000126],
                [-8.15194129899993, 9.55622577600019],
                [-8.14747714999993, 9.542608261999987],
                [-8.149865150999972, 9.536662102000093],
                [-8.149227141999972, 9.53205490099998],
                [-8.142521857999895, 9.531052589000183],
                [-8.13287353499993, 9.524957658000119],
                [-8.135928153999942, 9.52131557500013],
                [-8.137627600999963, 9.514903069000127],
                [-8.133332251999946, 9.506574631000092],
                [-8.134058952999908, 9.503824235000081],
                [-8.12860965799996, 9.495251656000164],
                [-8.12402439099992, 9.491518974000087],
                [-8.123171805999903, 9.48575878100013],
                [-8.116086005999932, 9.478316307000057],
                [-8.115238188999854, 9.471870423000155],
                [-8.11161041199989, 9.46584892300018],
                [-8.108386992999897, 9.464428902],
                [-8.106612204999976, 9.45820045500011],
                [-8.101552009999978, 9.45560932100011],
                [-8.098880767999901, 9.447069168000041],
                [-8.083587645999955, 9.429637909000064],
                [-8.081108091999965, 9.424321174999989],
                [-8.073525428999972, 9.419859886000097],
                [-8.06032943599996, 9.401763915],
                [-8.047015189999968, 9.40017604799999],
                [-8.011343955999905, 9.401583672000129],
                [-7.999349115999905, 9.397992134000162],
                [-7.987554072999899, 9.396929741000065],
                [-7.983246803999975, 9.394384384000034],
                [-7.968118189999927, 9.395999908000135],
                [-7.942530155999918, 9.406030655000109],
                [-7.915739059999908, 9.419488908000119],
                [-7.894011974999955, 9.416232108000145],
                [-7.891688823999971, 9.416893006000123],
                [-7.88696909, 9.424188614000116],
                [-7.885251044999961, 9.432210922000081],
                [-7.883121966999909, 9.43563175200012],
                [-7.872231959999908, 9.436185837999972],
                [-7.865322112999934, 9.433339119999971],
                [-7.857914923999886, 9.433017730000017],
                [-7.849986076999926, 9.437743186000034],
                [-7.848882197999899, 9.428906442000027],
                [-7.850316047999968, 9.425478936000161],
                [-7.8553957929999, 9.426465035000092],
                [-7.856388091999918, 9.421651840000038],
                [-7.85551881899994, 9.417270662000078],
                [-7.857664108999927, 9.41270160800002],
                [-7.85608005499995, 9.40992260000013],
                [-7.858211039999958, 9.406274796000105],
                [-7.859714984999869, 9.397559166],
                [-7.862562178999895, 9.392539978000059],
                [-7.866493224999829, 9.375352860000135],
                [-7.875637053999924, 9.366510391000077],
                [-7.882791041999951, 9.350515366000138],
                [-7.887479781999957, 9.345977784000013],
                [-7.888453006999953, 9.34231472000016],
                [-7.886446952999961, 9.336542129000122],
                [-7.886536121999939, 9.312405586000125],
                [-7.884795187999885, 9.304109574000108],
                [-7.887444971999912, 9.290613173999986],
                [-7.891354082999953, 9.28052330100013],
                [-7.895327091999889, 9.27758789100011],
                [-7.900828838999928, 9.263639450000028],
                [-7.906465053999966, 9.257275582000148],
                [-7.915543078999974, 9.23556327900019],
                [-7.925725935999878, 9.217308999000068],
                [-7.926014899999814, 9.212946892000048],
                [-7.922934054999871, 9.200954438000053],
                [-7.919879911999885, 9.186896324000031],
                [-7.9148950579999, 9.179017067000075],
                [-7.907806873999959, 9.172949791000065],
                [-7.881877898999903, 9.15766620700009],
                [-7.870246886999894, 9.167483330000152],
                [-7.853001117999838, 9.177837372000113],
                [-7.840034960999844, 9.178590774999975],
                [-7.833143234999909, 9.174825669000029],
                [-7.832059859999959, 9.168835640000111],
                [-7.839198111999963, 9.153072357000156],
                [-7.837841987999923, 9.150527955000143],
                [-7.827221869999903, 9.149012566000067],
                [-7.812060832999919, 9.14054584500002],
                [-7.813024997999946, 9.136293411000054],
                [-7.811964987999886, 9.129154206000123],
                [-7.808087825999905, 9.124964715000146],
                [-7.801414965999982, 9.122576713000058],
                [-7.788000106999903, 9.106080055000064],
                [-7.777646064999942, 9.102260590000185],
                [-7.770548819999874, 9.097109794000119],
                [-7.761540889999935, 9.096526147000077],
                [-7.755170821999911, 9.088856698000086],
                [-7.741600990999871, 9.08384323200005],
                [-7.736526012999889, 9.078054429000076],
                [-7.73726892499991, 9.075231552000048],
                [-7.754243850999956, 9.067871094000111],
                [-7.764904974999922, 9.066169739000145],
                [-7.777565955999876, 9.070468903000062],
                [-7.790026186999967, 9.072699548000116],
                [-7.801530838999895, 9.077215195000122],
                [-7.803812980999965, 9.079542161000063],
                [-7.813032149999913, 9.082187653000119],
                [-7.840923786999952, 9.070823669999982],
                [-7.853785037999955, 9.059268951000092],
                [-7.865118980999966, 9.04033946900006],
                [-7.896024226999941, 9.028087615000118],
                [-7.906313895999972, 9.018338203000098],
                [-7.918282031999922, 9.004012107000108],
                [-7.920782088999943, 8.988648414000124],
                [-7.91711807299987, 8.967002870000101],
                [-7.930364130999976, 8.942352295000035],
                [-7.94588708799995, 8.901185989000112],
                [-7.9548239699999, 8.869362832000036],
                [-7.962561129999926, 8.821668625000086],
                [-7.961309908999965, 8.810397149000039],
                [-7.947044849999884, 8.78655719700015],
                [-7.940433024999948, 8.779353142000161],
                [-7.925774097999977, 8.769294738000156],
                [-7.915886879999903, 8.7654933930001],
                [-7.901103972999977, 8.76531124100012],
                [-7.874420166999869, 8.775312425000038],
                [-7.861224173999972, 8.77767086100016],
                [-7.845528125999977, 8.776785851000113],
                [-7.823948859999973, 8.765941621000081],
                [-7.812397955999927, 8.766023635000124],
                [-7.790362834999883, 8.773322107000013],
                [-7.781601904999945, 8.771828652000181],
                [-7.77265501099987, 8.767118454000183],
                [-7.767868041999861, 8.762003899000035],
                [-7.763576984999929, 8.754365921000101],
                [-7.762087822999888, 8.744467735000057],
                [-7.756485937999912, 8.731071472000167],
                [-7.752632139999889, 8.725507736000054],
                [-7.750665187999971, 8.716753961000109],
                [-7.738080024999874, 8.68970966400019],
                [-7.736093044999961, 8.682564736000188],
                [-7.726826190999816, 8.667281151000111],
                [-7.723919868999928, 8.659662246000096],
                [-7.712813853999933, 8.625978470000177],
                [-7.711536883999884, 8.617461206000144],
                [-7.704162120999968, 8.598988533000181],
                [-7.701663971999949, 8.5957393650001],
                [-7.699306963999959, 8.581697465000161],
                [-7.697017193999955, 8.580290794000064],
                [-7.687553882999964, 8.544791223000061],
                [-7.684360980999941, 8.541992187000119],
                [-7.678079128999968, 8.52812767000006],
                [-7.666007040999944, 8.516023635000181],
                [-7.661066054999935, 8.505851746000076],
                [-7.659418106999851, 8.490671157000179],
                [-7.660181044999888, 8.485399247000089],
                [-7.666257857999938, 8.479278565000072],
                [-7.671687126999871, 8.470165253000118],
                [-7.675747871999874, 8.459425926000165],
                [-7.679962157999967, 8.454888343000164],
                [-7.681429862999892, 8.448476792000179],
                [-7.680149078999875, 8.440652848000127],
                [-7.673109053999951, 8.428236009000102],
                [-7.666027068999938, 8.408997535000083],
                [-7.654603958999871, 8.400579453000091],
                [-7.651432037999882, 8.396403312000132],
                [-7.649696826999843, 8.387881280000045],
                [-7.641070841999976, 8.377433776000032],
                [-7.651725768999938, 8.374546051000095],
                [-7.662782191999952, 8.375390053000103],
                [-7.6768651, 8.374209405000101],
                [-7.683351038999945, 8.372002601000133],
                [-7.689564226999949, 8.373007774000087],
                [-7.695354938999913, 8.37102317800003],
                [-7.716979979999962, 8.374767303000112],
                [-7.745131968999942, 8.37331771800018],
                [-7.751740932999894, 8.37892246299998],
                [-7.764214992999939, 8.411245345000111],
                [-7.780377864999934, 8.443617822000135],
                [-7.788410186999897, 8.46371078600015],
                [-7.793652056999918, 8.468376160000162],
                [-7.799159049999958, 8.47028827700018],
                [-7.803682804999937, 8.477008819000048],
                [-7.808255195999891, 8.480058670000062],
                [-7.815833091999934, 8.480425835000176],
                [-7.822759150999957, 8.48231983300019],
                [-7.827267169999914, 8.49018955200006],
                [-7.831696032999901, 8.486804963000054],
                [-7.8350029, 8.480876922999983],
                [-7.837707996999939, 8.468509674000188],
                [-7.830990791999966, 8.436037064000118],
                [-7.832880972999874, 8.432847023000079],
                [-7.847558020999941, 8.421788217000028],
                [-7.852424143999883, 8.420017244000178],
                [-7.876389026999959, 8.421484948000113],
                [-7.886598109999909, 8.41702461300008],
                [-7.887461185999939, 8.42140293100016],
                [-7.884080886999925, 8.43261241900018],
                [-7.88474512099998, 8.434915542000056],
                [-7.892614841999944, 8.432723046000035],
                [-7.908950804999961, 8.435694696000041],
                [-7.909717082999919, 8.447647096000082],
                [-7.911085127999968, 8.449044229000151],
                [-7.921254158999886, 8.447341919000166],
                [-7.923046112999941, 8.451498986000104],
                [-7.919437885999969, 8.462702751000108],
                [-7.917119979999939, 8.463591576000056],
                [-7.913281917999939, 8.45711040500015],
                [-7.911427020999952, 8.458005906000096],
                [-7.911799907999978, 8.46467018100003],
                [-7.91856908799997, 8.476245881000182],
                [-7.923367022999912, 8.479750633000094],
                [-7.914805888999979, 8.481706619000079],
                [-7.913169859999869, 8.483292579000022],
                [-7.915438173999974, 8.486308098000052],
                [-7.929213046999905, 8.491312980000089],
                [-7.938083172999939, 8.501074792000054],
                [-7.942700863999903, 8.500676156000111],
                [-7.950173853999957, 8.493652344000054],
                [-7.957314968999924, 8.494664192000073],
                [-7.963099002999968, 8.493362428000012],
                [-7.967638969999882, 8.498934747000021],
                [-7.970870971999943, 8.498744965000128],
                [-7.978094099999964, 8.49332618700015],
                [-7.980491161999964, 8.486237527000185],
                [-7.985136031999957, 8.483769416000143],
                [-7.990653990999931, 8.485220909000077],
                [-7.997981071999959, 8.48944664000004],
                [-8.002821921999953, 8.489738464000027],
                [-8.009771346999912, 8.487531662000038],
                [-8.022594452999897, 8.495043755000154],
                [-8.03111839199994, 8.495841980000023],
                [-8.037836074999973, 8.493631363000134],
                [-8.044965743999967, 8.495328903000086],
                [-8.055508612999972, 8.500746727000035],
                [-8.059845923999944, 8.50447463900008],
                [-8.06512546599987, 8.506608009000047],
                [-8.076901434999968, 8.499307632000068],
                [-8.081844328999978, 8.497860909000167],
                [-8.095355034999955, 8.505838395000126],
                [-8.098377228999937, 8.504037858000117],
                [-8.10360336399998, 8.49193096100015],
                [-8.119660376999889, 8.480647087000023],
                [-8.120944976999965, 8.488703727000143],
                [-8.125316618999932, 8.489443779000055],
                [-8.129495619999943, 8.487201692000099],
                [-8.134102820999942, 8.48794460400012],
                [-8.139098166999872, 8.49420833500011],
                [-8.143239975999904, 8.495176316000027],
                [-8.146988868999927, 8.490399362000176],
                [-8.150922775999902, 8.489530564000063],
                [-8.153298377999931, 8.48404693700013],
                [-8.156959533999895, 8.486160278000057],
                [-8.157601356999976, 8.49053096800003],
                [-8.156406402999949, 8.493730545],
                [-8.157759666999937, 8.496274947000018],
                [-8.1621360769999, 8.496789932000127],
                [-8.169572829999936, 8.492283820000125],
                [-8.175254820999953, 8.499244691000115],
                [-8.17823982199991, 8.500430107000113],
                [-8.183788298999957, 8.499576569],
                [-8.187001228999975, 8.500994683000101],
                [-8.196896552999931, 8.502722740000024],
                [-8.198296545999938, 8.501591684000061],
                [-8.196758268999929, 8.49537182],
                [-8.20591831299987, 8.481701852000128],
                [-8.215132712999946, 8.472677231000148],
                [-8.221213339999906, 8.470409393000068],
                [-8.223381041999858, 8.468132019000109],
                [-8.220483780999928, 8.459598541000162],
                [-8.222576140999934, 8.45824527799999],
                [-8.230417250999835, 8.45896339400008],
                [-8.23339080799991, 8.460441590000187],
                [-8.23379039699995, 8.465729713000144],
                [-8.235405921999927, 8.465749742000071],
                [-8.240768433999904, 8.46076107000016],
                [-8.24242973399987, 8.456877709000082],
                [-8.241583823999974, 8.450434684000186],
                [-8.243496893999975, 8.444723129000181],
                [-8.245587348999948, 8.442552567000178],
                [-8.23974990899984, 8.429167748],
                [-8.237839698999949, 8.414218902000187],
                [-8.238370895999935, 8.407796861000065],
                [-8.236800192999851, 8.395055771000102],
                [-8.225819587999979, 8.360679626000035],
                [-8.225660323999875, 8.354936599000098],
                [-8.228771208999888, 8.345328331000076],
                [-8.23797416799988, 8.327757836000103],
                [-8.237603187999923, 8.320175170000141],
                [-8.230691908999972, 8.300341606000075],
                [-8.231749533999846, 8.289104462000068],
                [-8.230238914999973, 8.280359269000087],
                [-8.232859610999924, 8.273270607000143],
                [-8.246381758999917, 8.260346412000047],
                [-8.248765945999935, 8.253716469000096],
                [-8.248055457999953, 8.236024857000018],
                [-8.243995666999922, 8.228629113000068],
                [-8.230306625999901, 8.226033211000015],
                [-8.22508335099991, 8.233358384000098],
                [-8.21908187799994, 8.233978271000183],
                [-8.207397459999868, 8.227416993000134],
                [-8.189421652999954, 8.206546783000135],
                [-8.179585456999973, 8.199774743000035],
                [-8.16671466899993, 8.196411133000083],
                [-8.126380920999964, 8.19617176200012],
                [-8.1109418879999, 8.188751222000064],
                [-8.103737829999886, 8.18398094100013],
                [-8.101742743999921, 8.17752647400016],
                [-8.093734740999935, 8.173064232000058],
                [-8.089082717999872, 8.16710186000006],
                [-8.068925856999897, 8.161513328000012],
                [-8.06451415999993, 8.164445878000038],
                [-8.051420210999936, 8.180358886000022],
                [-8.043329238999888, 8.20207500400005],
                [-8.039601324999978, 8.205472945999986],
                [-8.03477096599994, 8.204495430000122],
                [-8.023674010999969, 8.187602043000084],
                [-8.01792812399998, 8.186383248000027],
                [-8.014179228999865, 8.191390038000065],
                [-8.006985663999899, 8.19543552500005],
                [-8.004182814999979, 8.19861698200009],
                [-7.99841880799994, 8.198545456000033],
                [-7.993649005999828, 8.19274902400008],
                [-7.993298052999933, 8.183559418000186],
                [-7.995921134999946, 8.17624282800017],
                [-8.008975981999924, 8.163776399000142],
                [-8.009973525999953, 8.157361031000107],
                [-8.007619857999885, 8.142176629000176],
                [-8.009333610999931, 8.13370323200013],
                [-8.01543331199997, 8.124595642999964],
                [-8.014341353999953, 8.119530678000046],
                [-8.010151861999873, 8.11482143500001],
                [-8.014452933999962, 8.102265359000114],
                [-8.010642052999913, 8.09395027200003],
                [-7.993225096999936, 8.086839676000011],
                [-7.98707103799984, 8.081708908000053],
                [-7.98111295699988, 8.079339027000174],
                [-7.969590186999938, 8.061969758000032],
                [-7.963332175999881, 8.047196389000078],
                [-7.946579932999953, 8.042387008000105],
                [-7.946622847999947, 8.03917408],
                [-7.950419902999897, 8.030499459],
                [-7.946342944999969, 8.02516555900013],
                [-7.946928976999971, 8.015760423000131],
                [-7.945581913999888, 8.012989043000175],
                [-7.948188780999942, 8.007284166000034],
                [-7.953718184999957, 8.00712680800018],
                [-7.959630011999877, 8.012941361000117],
                [-7.972702979999951, 8.017013549000126],
                [-7.982227800999965, 8.010481835],
                [-7.984292982999932, 8.010965348000184],
                [-7.98604488299992, 8.018104553000114],
                [-7.990667818999896, 8.016785621000054],
                [-7.991803169999969, 8.018180847000053],
                [-7.98725795699994, 8.031206132000136],
                [-7.989054203999956, 8.034902573000181],
                [-8.000283241999853, 8.03917789500008],
                [-8.003287315999899, 8.038529397000048],
                [-8.00981903099995, 8.031952859],
                [-8.024313925999877, 8.033059121000065],
                [-8.029653548999818, 8.029681206000078],
                [-8.040738105999935, 8.03279685900003],
                [-8.047423363999883, 8.02993869800008],
                [-8.057643889999895, 8.022718429000122],
                [-8.063541411999893, 8.011078834000159],
                [-8.05337143, 8],
                [-8.065770148999945, 7.958349227999975],
                [-8.075221060999922, 7.938736916000153],
                [-8.082610129999978, 7.916011811000033],
                [-8.094126701999926, 7.898478032000128],
                [-8.107178688999852, 7.884572983000112],
                [-8.11556625399993, 7.86909294100019],
                [-8.117179869999916, 7.858753204000095],
                [-8.114659308999933, 7.844769955000118],
                [-8.08312988199998, 7.819781780000028],
                [-8.071770666999896, 7.808342934],
                [-8.070261955999968, 7.79953908900012],
                [-8.071351051999955, 7.79022789000004],
                [-8.086001395999915, 7.768569947000117],
                [-8.091284751999979, 7.751001834000078],
                [-8.094035147999932, 7.722039222000149],
                [-8.101334571999928, 7.715865135000115],
                [-8.123680114999956, 7.71079778800015],
                [-8.139862059999928, 7.693283082000107],
                [-8.143695829999956, 7.655525208000086],
                [-8.14891719699989, 7.649341106000179],
                [-8.157246589999943, 7.644204140000056],
                [-8.171907424999972, 7.619952203000082],
                [-8.172627447999957, 7.581657886000187],
                [-8.179969786999948, 7.566683770000054],
                [-8.193576812999936, 7.545011997000074],
                [-8.205040931999918, 7.535750865000125],
                [-8.214912414999844, 7.533728123000117],
                [-8.229873657999974, 7.549842835000106],
                [-8.235571860999926, 7.551939964000155],
                [-8.257283209999969, 7.56757020900011],
                [-8.289409637999881, 7.577037812000015],
                [-8.297672272999932, 7.584838868000134],
                [-8.299714087999917, 7.591577054000084],
                [-8.298646925999833, 7.596747876000165],
                [-8.30277728999988, 7.601425170000084],
                [-8.322503088999895, 7.599445820000085],
                [-8.346479414999976, 7.578856944999984],
                [-8.356881141999963, 7.574762820999979],
                [-8.369343758999946, 7.572749139],
                [-8.379691123999976, 7.579007150000052],
                [-8.381673813999953, 7.597648143000129],
                [-8.38473892299993, 7.606978893000132],
                [-8.393016814999953, 7.612191200000041],
                [-8.405476569999962, 7.611214161000078],
                [-8.417427061999945, 7.608161926000037],
                [-8.440885543999968, 7.586531162000028],
                [-8.47218036699985, 7.554582120000077],
                [-8.464486121999869, 7.53643322],
                [-8.45675086999995, 7.526564122000025],
                [-8.433963776999974, 7.51714515599997],
                [-8.41410636899991, 7.493814945000111],
                [-8.41367054099993, 7.485685826000122],
                [-8.407967567999947, 7.476017951000188],
                [-8.409613607999972, 7.469141006000029],
                [-8.405541419999906, 7.45626401800007],
                [-8.404444693999949, 7.44569778500005],
                [-8.40982818599997, 7.428968907000183],
                [-8.398887632999958, 7.409173011000064],
                [-8.39390754699997, 7.393765927000175],
                [-8.39372444099996, 7.385271074000116],
                [-8.39586067099998, 7.372428894000166],
                [-8.389883040999848, 7.32877922099999],
                [-8.386019706999946, 7.319574833000161],
                [-8.372769356999981, 7.302058220000049],
                [-8.360457419999932, 7.281331063000039],
                [-8.3600311269999, 7.27443885800011],
                [-8.353366851999965, 7.273259163000091],
                [-8.351766584999893, 7.270955087000061],
                [-8.351123809999933, 7.262455941000098],
                [-8.352302549999933, 7.257182122000074],
                [-8.356243132999907, 7.251922130000082],
                [-8.356493948999969, 7.247788906000039],
                [-8.360893249999947, 7.242763996000122],
                [-8.357256889999974, 7.234249115000011],
                [-8.346010207999939, 7.22959709200012],
                [-8.335713385999952, 7.219209195000133],
                [-8.331371306999927, 7.213675023000178],
                [-8.330495833999976, 7.20563697900019],
                [-8.325000762999878, 7.200553893000063],
                [-8.318087576999972, 7.202585221000106],
                [-8.31418609699989, 7.200957775000063],
                [-8.307552337999937, 7.194263936000027],
                [-8.30363845799991, 7.194470883000179],
                [-8.301837920999958, 7.18734407400018],
                [-8.29424762699989, 7.186844826000083],
                [-8.290595053999937, 7.181774140000073],
                [-8.28714656699998, 7.181297779000033],
                [-8.284135817999982, 7.185184003000074],
                [-8.278383253999891, 7.185152054000184],
                [-8.276108741999906, 7.180778027000031],
                [-8.27846527, 7.170228005000183],
                [-8.275782584999945, 7.156208993000178],
                [-8.277879714999926, 7.15139818200015],
                [-8.292897223999887, 7.139307023000072],
                [-8.294301031999964, 7.134953976000077],
                [-8.291633604999959, 7.117716789000156],
                [-8.299383162999902, 7.088827133000109],
                [-8.299416541999904, 7.082396984000184],
                [-8.294875143999946, 7.07204103600003],
                [-8.295619963999911, 7.061708927000041],
                [-8.28769397799988, 7.039164066000126],
                [-8.287084578999895, 7.024003030000131],
                [-8.285034179999968, 7.020547868000051],
                [-8.278603553999915, 7.018907071000115],
                [-8.276085853999973, 7.016595841000026],
                [-8.278174398999965, 7.013391973000125],
                [-8.274739265999926, 7.010618210000075],
                [-8.273611067999923, 7.006707192000135],
                [-8.276395797999953, 7.002130985000122],
                [-8.283550262999938, 6.99780512000001],
                [-8.284750937999888, 6.987936020000063],
                [-8.286833762999947, 6.985879899000167],
                [-8.29305362599996, 6.983847141000013],
                [-8.302541732999941, 6.973333836000109],
                [-8.309666634999871, 6.974290848000067],
                [-8.313365935999968, 6.970864773000017],
                [-8.32164955099995, 6.969761848000189],
                [-8.324889182999868, 6.966106892000084],
                [-8.321476935999897, 6.959425927000041],
                [-8.322193146999837, 6.954607963000171],
                [-8.318329810999899, 6.945860863000121],
                [-8.317929266999897, 6.935064792999981],
                [-8.315238952999891, 6.922190189000048],
                [-8.309087752999972, 6.911592007000138],
                [-8.308884620999947, 6.906307220000088],
                [-8.310747145999926, 6.902413845000126],
                [-8.314672469999891, 6.899450779000063],
                [-8.324563025999964, 6.899043084000027],
                [-8.3261842739999, 6.896751880000181],
                [-8.322995186999947, 6.891225816000144],
                [-8.329915045999883, 6.887129784000024],
                [-8.33202743499993, 6.879560947000186],
                [-8.318128585999887, 6.855370045000029],
                [-8.315994263999926, 6.854325772000038],
                [-8.311660765999932, 6.861076833000084],
                [-8.308433531999981, 6.862207891000026],
                [-8.307359693999956, 6.848193169000069],
                [-8.313154220999877, 6.839955805999978],
                [-8.321476935999897, 6.831730843000059],
                [-8.323805809999953, 6.826461793000021],
                [-8.316763876999858, 6.81103420300002],
                [-8.318146704999947, 6.809663774000057],
                [-8.324123381999925, 6.809924126000112],
                [-8.327170371999955, 6.799376012000096],
                [-8.340554236999878, 6.790719986000113],
                [-8.342883108999956, 6.785217763000048],
                [-8.342911719999904, 6.779934883000124],
                [-8.338418006999973, 6.760616779999964],
                [-8.340051650999953, 6.756031991000157],
                [-8.34280109499997, 6.757884980000085],
                [-8.343696593999937, 6.76202392600004],
                [-8.346223830999975, 6.762495041000079],
                [-8.349453926999956, 6.76090717400001],
                [-8.35312843399987, 6.761844158000088],
                [-8.355898855999953, 6.759562016000132],
                [-8.356844902999967, 6.754511834000084],
                [-8.355738638999924, 6.746468067000023],
                [-8.36083125999994, 6.740064145000076],
                [-8.36448669399988, 6.744447231000152],
                [-8.367707251999889, 6.744005204000075],
                [-8.36981773399998, 6.736206054000149],
                [-8.375125884999875, 6.732789039000124],
                [-8.379266738999945, 6.731891155000085],
                [-8.383195877999981, 6.728238106000049],
                [-8.379996299999902, 6.724775791000013],
                [-8.37471103799993, 6.724287988000071],
                [-8.378195762999951, 6.717644214000131],
                [-8.382557867999935, 6.719046115000083],
                [-8.385758399999872, 6.722050191000108],
                [-8.38897800399991, 6.721836091000171],
                [-8.39106845899994, 6.718173027000148],
                [-8.388105392999933, 6.713103772000181],
                [-8.405841826999904, 6.706994058000021],
                [-8.40747356299994, 6.702638149000109],
                [-8.402459144999966, 6.694572926000092],
                [-8.404552458999888, 6.689989090999973],
                [-8.401832580999837, 6.682393075000164],
                [-8.404377937999925, 6.679420947999972],
                [-8.408985138999924, 6.677836894000052],
                [-8.41500568399988, 6.669598103],
                [-8.418229101999884, 6.668924809000089],
                [-8.42304038999987, 6.672165871],
                [-8.42558097899996, 6.66988515900016],
                [-8.43048095599994, 6.65612602200008],
                [-8.432559965999928, 6.654297829000086],
                [-8.43531513299996, 6.654774188000147],
                [-8.439889906999952, 6.659619808000173],
                [-8.443107604999966, 6.659408093000138],
                [-8.44746780399987, 6.654881001000035],
                [-8.448678015999917, 6.649329186000102],
                [-8.44757461499995, 6.640594006000185],
                [-8.448979377999876, 6.636007786000107],
                [-8.452900886999828, 6.633271218000175],
                [-8.460709571999928, 6.634687900000188],
                [-8.462555883999926, 6.633551121000153],
                [-8.464656829999967, 6.627358914000013],
                [-8.470373152999969, 6.63336181700015],
                [-8.47290325199998, 6.632915019000109],
                [-8.476161956999931, 6.625350952000133],
                [-8.48237323799998, 6.624693871000034],
                [-8.485157965999974, 6.619655133000038],
                [-8.482206344999952, 6.612747192000029],
                [-8.487977027999932, 6.607954025000026],
                [-8.49188137099992, 6.608891011000083],
                [-8.491623878999917, 6.614173889000028],
                [-8.493911742999956, 6.616024017000029],
                [-8.499895095999875, 6.61490488000004],
                [-8.503819464999935, 6.611938001000169],
                [-8.505683899999951, 6.606894017000059],
                [-8.504122733999907, 6.597695828000099],
                [-8.506223677999913, 6.591275215000053],
                [-8.508763313999907, 6.589451790000112],
                [-8.513337135999961, 6.593839168000045],
                [-8.521165847999896, 6.59158277500012],
                [-8.522761344999935, 6.594347954],
                [-8.52111625599997, 6.600999833000174],
                [-8.528667449999944, 6.608160973999986],
                [-8.532823561999976, 6.604506015000027],
                [-8.533297538999932, 6.6015219680001],
                [-8.530358313999955, 6.592089176000172],
                [-8.531127929999911, 6.576700210000183],
                [-8.526328086999968, 6.570931912000049],
                [-8.536975860999917, 6.556972028000132],
                [-8.539738655999884, 6.556066990000034],
                [-8.546603202999961, 6.562304973000096],
                [-8.556248664999941, 6.563961983000013],
                [-8.560628891999954, 6.561457158000167],
                [-8.56365966699991, 6.553202153000086],
                [-8.562984466999978, 6.550899983000022],
                [-8.555407524999964, 6.548793792000083],
                [-8.554272651999952, 6.546033860000023],
                [-8.562594413999875, 6.536886216000028],
                [-8.568662643999971, 6.518766880000101],
                [-8.578317640999899, 6.518816948000108],
                [-8.584719657999926, 6.525510789],
                [-8.58724307999995, 6.52644300500009],
                [-8.591851234999979, 6.5241680150001],
                [-8.59930229199989, 6.505596161000028],
                [-8.592444419999936, 6.498438835000059],
                [-8.581195830999945, 6.495165824000083],
                [-8.570605276999856, 6.498789787000135],
                [-8.565333364999901, 6.496005058000037],
                [-8.547865866999928, 6.495227814000032],
                [-8.540527342999951, 6.492204190000166],
                [-8.527956008999922, 6.478582860000017],
                [-8.515645981999967, 6.458764075999966],
                [-8.504222868999932, 6.445378781000102],
                [-8.490269659999967, 6.432442188000096],
                [-8.483388899999966, 6.429647922000186],
                [-8.481078146999948, 6.432161809000093],
                [-8.473500251999894, 6.430744171000185],
                [-8.469574927999929, 6.434401989000151],
                [-8.467836378999948, 6.449141980000036],
                [-8.466226577999976, 6.452974797000138],
                [-8.458827018999955, 6.461895943000059],
                [-8.456542969999816, 6.471159934000127],
                [-8.457743643999947, 6.48683595600005],
                [-8.455496787999948, 6.488746166000055],
                [-8.45165729599995, 6.488729955],
                [-8.436023712999884, 6.480342865000068],
                [-8.427115440999899, 6.470387936],
                [-8.41405296399995, 6.45977592600002],
                [-8.393641471999956, 6.447529792000182],
                [-8.385976790999962, 6.444933891000062],
                [-8.385993003999943, 6.441737175000071],
                [-8.389858244999914, 6.435997964000137],
                [-8.405879973999959, 6.430952073000185],
                [-8.408246038999891, 6.426960946000065],
                [-8.407353401999842, 6.422129154000174],
                [-8.399072646999912, 6.423234941000089],
                [-8.393416403999879, 6.406662942000139],
                [-8.39256095899998, 6.39448118100006],
                [-8.38275432499995, 6.380414009000162],
                [-8.386001586999953, 6.374683856000104],
                [-8.392895697999961, 6.374493122000104],
                [-8.398206710999943, 6.370155811000132],
                [-8.405100821999952, 6.369962215000101],
                [-8.412014007999971, 6.366779805000078],
                [-8.396906852999962, 6.3549838080001],
                [-8.392355918999897, 6.346687794000047],
                [-8.385924337999938, 6.346192836000114],
                [-8.380352019999862, 6.356503964000069],
                [-8.373231887999964, 6.355776787000025],
                [-8.37181568199992, 6.362892151000096],
                [-8.369277952999937, 6.364717961000167],
                [-8.365842819999898, 6.362401963000025],
                [-8.361738204999938, 6.356406211999968],
                [-8.358522413999935, 6.356160163000141],
                [-8.355044363999866, 6.361886979000076],
                [-8.352512359999878, 6.362792016000071],
                [-8.345626829999958, 6.361145974000124],
                [-8.342596052999909, 6.369171143000017],
                [-8.339359283999954, 6.372831822000137],
                [-8.333408354999904, 6.367971897000132],
                [-8.323554037999884, 6.36286688000007],
                [-8.316470145999972, 6.355475903000183],
                [-8.312108038999895, 6.354531764000171],
                [-8.30818271599992, 6.357957840000097],
                [-8.302970885999912, 6.344604016000119],
                [-8.300684928999942, 6.34229278600003],
                [-8.298377990999938, 6.343660832000069],
                [-8.296744346999958, 6.34824705200009],
                [-8.280437469999981, 6.346780778000095],
                [-8.275197028999969, 6.338479997000093],
                [-8.269449232999932, 6.338678836000156],
                [-8.266937254999903, 6.335679055000185],
                [-8.267189978999966, 6.331775188000052],
                [-8.269267081999942, 6.329717158999983],
                [-8.27571392099992, 6.32745409000006],
                [-8.27964592, 6.323339940000039],
                [-8.276215552999872, 6.319873810000104],
                [-8.259680747999937, 6.318175793000023],
                [-8.252083778999918, 6.320203781000146],
                [-8.246352195999975, 6.31787204900013],
                [-8.24106216499996, 6.318532945000129],
                [-8.216785429999902, 6.303696155000125],
                [-8.212192534999929, 6.303211212000065],
                [-8.206187247999935, 6.308921814000087],
                [-8.201869963999911, 6.300168037000105],
                [-8.198650359999817, 6.300377846000117],
                [-8.195178984999927, 6.304495812000141],
                [-8.188081741999952, 6.299862862000168],
                [-8.18283653199984, 6.292480946000069],
                [-8.174600600999895, 6.285998821000135],
                [-8.171671867999976, 6.27564478000005],
                [-8.167078970999967, 6.274929047000057],
                [-8.158559798999875, 6.278099060000102],
                [-8.142244338999944, 6.27823781900014],
                [-8.133496284999921, 6.280945778000103],
                [-8.123622893999936, 6.279284001000178],
                [-8.118562697999948, 6.280403138000111],
                [-8.108173369999975, 6.289075852000053],
                [-8.100364684999874, 6.288343907000183],
                [-8.096674918999952, 6.290620804000184],
                [-8.09086990399993, 6.301156998000124],
                [-8.080092429999866, 6.296732903000077],
                [-8.073433874999978, 6.295775891000119],
                [-8.065363883999964, 6.300784111000098],
                [-8.05064773499987, 6.302310944],
                [-8.040267943999936, 6.308913231000133],
                [-8.029920577999974, 6.309774877000052],
                [-8.005302429999915, 6.315148831000045],
                [-8.000032424999972, 6.312130927000112],
                [-7.992055891999883, 6.300597192000168],
                [-7.991189003999921, 6.291861057],
                [-7.987754822999932, 6.289543151000089],
                [-7.983585834999929, 6.295262814000012],
                [-7.979455948999942, 6.293629170000031],
                [-7.975123882999981, 6.288321019000023],
                [-7.97119188399995, 6.292202951000093],
                [-7.968204021999839, 6.292874813000083],
                [-7.965281961999892, 6.281139850000159],
                [-7.959087848999957, 6.279493808000041],
                [-7.955172060999928, 6.281540871],
                [-7.949376106999978, 6.290239811000163],
                [-7.94499397199985, 6.292512893000151],
                [-7.940649985999869, 6.289268971000126],
                [-7.932446955999978, 6.276813985000104],
                [-7.926712989999942, 6.275170803000037],
                [-7.921577929999955, 6.288468838000085],
                [-7.917452810999976, 6.286606789000132],
                [-7.915406226999835, 6.283147812000038],
                [-7.913412093999966, 6.270729065000012],
                [-7.90950489099987, 6.270475865000037],
                [-7.906466960999978, 6.279189111000108],
                [-7.901638029999901, 6.279850007000107],
                [-7.899589060999915, 6.277081012000053],
                [-7.894392012999958, 6.261425019000058],
                [-7.889852046999977, 6.252436160000173],
                [-7.881144046999964, 6.24847698200017],
                [-7.876364230999968, 6.240636825000081],
                [-7.862854004999974, 6.232513904000086],
                [-7.854143141999828, 6.229475974000081],
                [-7.820580005999943, 6.193659783000044],
                [-7.818992136999952, 6.190199851000102],
                [-7.82383012899993, 6.187932015000058],
                [-7.836696146999884, 6.188240052000083],
                [-7.840622900999904, 6.184584141000187],
                [-7.834012985999948, 6.1758141520001],
                [-7.835216999999886, 6.166628837000133],
                [-7.831582069999968, 6.159942151],
                [-7.833669184999906, 6.156507014999988],
                [-7.844283104999875, 6.149216176000095],
                [-7.846385002999966, 6.143484115000035],
                [-7.845733164999956, 6.137045860000114],
                [-7.848288059999959, 6.094086170000139],
                [-7.847158908999973, 6.090401172000043],
                [-7.842577934999952, 6.088078976000134],
                [-7.841907977999938, 6.085085868000078],
                [-7.844254015999979, 6.076827049000031],
                [-7.84336709899992, 6.071994782000161],
                [-7.834147929999915, 6.076537133000045],
                [-7.8315410599999, 6.090310098000145],
                [-7.828310965999947, 6.092588902000045],
                [-7.823942183999975, 6.093023777000042],
                [-7.818210124999894, 6.09091806399999],
                [-7.807464121999942, 6.082581043000062],
                [-7.806029796999894, 6.091536045000055],
                [-7.802121161999878, 6.092202187000112],
                [-7.796636104999891, 6.087804795000068],
                [-7.786832809999964, 6.074872972000094],
                [-7.787315844999966, 6.070741176000183],
                [-7.79289102599995, 6.06089305900008],
                [-7.793138980999913, 6.057446958000128],
                [-7.79042005599996, 6.051225186000124],
                [-7.778058051999949, 6.044486046000088],
                [-7.772572039999943, 6.039395810000144],
                [-7.768486022999923, 6.031556130000183],
                [-7.771048067999971, 6.026053907000119],
                [-7.781415938999942, 6.020833015000164],
                [-7.784430979999911, 6.016026020000084],
                [-7.779666899999882, 6.00634384100016],
                [-7.78961086299995, 5.995141983000053],
                [-7.791012763999959, 5.991473199000097],
                [-7.788074970999958, 5.983642102000033],
                [-7.78257989899987, 5.980389119000108],
                [-7.766077041999893, 5.974775792000116],
                [-7.763574122999955, 5.970850945000166],
                [-7.761117934999902, 5.959345818000088],
                [-7.758396147999974, 5.953353882999977],
                [-7.739425180999888, 5.937839986000029],
                [-7.725667953999903, 5.933619023000119],
                [-7.718582153999932, 5.928287983000132],
                [-7.713582037999913, 5.919294834000027],
                [-7.692998885999941, 5.904920101000187],
                [-7.684297084999969, 5.900729179],
                [-7.679932117999954, 5.900931836000154],
                [-7.676928997999823, 5.903440952000096],
                [-7.675990103999936, 5.906882763000056],
                [-7.682087898999896, 5.923240184000179],
                [-7.680682180999952, 5.928287983000132],
                [-7.673748970999952, 5.935140134000108],
                [-7.669539928999939, 5.947296144000177],
                [-7.663312911999981, 5.950706006000189],
                [-7.66056203799991, 5.950230121000175],
                [-7.657867908999947, 5.939868928000067],
                [-7.657680987999981, 5.933201791000045],
                [-7.660293102999958, 5.919657231000087],
                [-7.659627913999884, 5.915746213000148],
                [-7.655264854999928, 5.915030957000113],
                [-7.64905023599988, 5.917288780000149],
                [-7.644927024999959, 5.915655136000169],
                [-7.637619972999971, 5.908486843000105],
                [-7.631875038999965, 5.909139156000151],
                [-7.622931002999962, 5.907014846000038],
                [-7.613739013999975, 5.907417775000113],
                [-7.611505984999951, 5.897292138000125],
                [-7.608983038999952, 5.896815776000153],
                [-7.604601860999878, 5.899086],
                [-7.60236215599997, 5.890569211000127],
                [-7.599617957999897, 5.888253212000052],
                [-7.596158027999877, 5.89075994500007],
                [-7.593575953999959, 5.899477958000148],
                [-7.591958045999945, 5.901075840000033],
                [-7.583921908999912, 5.900796890000038],
                [-7.575214863999975, 5.89729404600007],
                [-7.552627085999973, 5.87347698200017],
                [-7.553341865999926, 5.869345189000171],
                [-7.568706035999924, 5.873350144000142],
                [-7.571949005999954, 5.868772984000145],
                [-7.5699090949999, 5.864623070000164],
                [-7.561192036999955, 5.863189220000152],
                [-7.557756900999948, 5.861327171000028],
                [-7.554386137999927, 5.849585057000127],
                [-7.548686027999906, 5.84288215600003],
                [-7.545751095999833, 5.835051059000023],
                [-7.539782045999971, 5.834782124000071],
                [-7.538380145999952, 5.838451863000046],
                [-7.538081168999895, 5.849483013000054],
                [-7.529809950999891, 5.849430085000108],
                [-7.519185065999977, 5.859015942000042],
                [-7.512544154999944, 5.855987073000165],
                [-7.510745047999933, 5.85022783300002],
                [-7.512146948999941, 5.846100807000141],
                [-7.519526958999961, 5.841320993000068],
                [-7.517938136999931, 5.838781834000031],
                [-7.506226062999872, 5.838477135000176],
                [-7.502563, 5.836615085000119],
                [-7.490516184999876, 5.81700277300007],
                [-7.483890056999883, 5.811672211000087],
                [-7.4756279, 5.810471058000076],
                [-7.473711967999918, 5.822872161000078],
                [-7.469055176999916, 5.832726955000112],
                [-7.471537112999954, 5.839867115000061],
                [-7.468274115999975, 5.847200871000041],
                [-7.468688011999916, 5.853869916],
                [-7.466814994999936, 5.859603882000158],
                [-7.463357924999968, 5.861190796000187],
                [-7.451211927999964, 5.856974124000033],
                [-7.445671080999944, 5.86130714400008],
                [-7.439510821999932, 5.855060102000095],
                [-7.43635177699997, 5.846536160000028],
                [-7.429712772999892, 5.84327697700013],
                [-7.422359943999936, 5.843457223000087],
                [-7.422628878999944, 5.837482930000078],
                [-7.429786203999925, 5.832013130000121],
                [-7.430801868999936, 5.816850186000124],
                [-7.439177988999859, 5.800354003000109],
                [-7.439206122999963, 5.796216965000099],
                [-7.428965090999952, 5.781208991000028],
                [-7.42740011199993, 5.775221825000074],
                [-7.429275034999932, 5.768798827000126],
                [-7.438295840999956, 5.758971214000098],
                [-7.441318034999824, 5.753704072000062],
                [-7.436097144999962, 5.744475842000156],
                [-7.433667182999955, 5.729290008000078],
                [-7.428913115999876, 5.718915940000045],
                [-7.428592205999905, 5.697535038000069],
                [-7.426311968999869, 5.694530964000023],
                [-7.417152881999925, 5.690793037000105],
                [-7.415872096999919, 5.675845146000086],
                [-7.411768912999889, 5.67121982700013],
                [-7.409318924999866, 5.659482957000137],
                [-7.404345989999968, 5.64772510600011],
                [-7.396833896999965, 5.637331962000133],
                [-7.395197867999968, 5.63264990000016],
                [-7.37878608699998, 5.623882771000126],
                [-7.375171184999942, 5.614432810999972],
                [-7.378478049999899, 5.600431920000176],
                [-7.377830981999921, 5.594220162000113],
                [-7.371479034999879, 5.58245515800013],
                [-7.374031066999919, 5.578333855000096],
                [-7.38138198899992, 5.577920913000014],
                [-7.391929149999953, 5.579829217],
                [-7.394239902999914, 5.577314854000065],
                [-7.395647048999933, 5.572957040000119],
                [-7.392786025999897, 5.554316044000132],
                [-7.396749973999874, 5.544686795000018],
                [-7.391973019999966, 5.53821897500012],
                [-7.380094050999958, 5.529174806000071],
                [-7.378513813999916, 5.524569035000127],
                [-7.38037919899989, 5.520442009000078],
                [-7.383607864999874, 5.51816511200002],
                [-7.399655819999964, 5.521488191000117],
                [-7.402887820999922, 5.518978118000177],
                [-7.400843142999975, 5.515747069999975],
                [-7.391920089999928, 5.511089801000026],
                [-7.389421939999977, 5.506708146000108],
                [-7.391508102999921, 5.503733159000092],
                [-7.396790980999981, 5.503537177000055],
                [-7.400020121999887, 5.501028061999989],
                [-7.402625083999965, 5.488401890000148],
                [-7.404708861999893, 5.486345769000138],
                [-7.416204929999935, 5.483661176000112],
                [-7.421059132999915, 5.478634835000037],
                [-7.423173903999953, 5.470602036000173],
                [-7.4218449599999, 5.463234901000021],
                [-7.416398048999952, 5.45377493],
                [-7.41351699899991, 5.43984699300006],
                [-7.415866851999965, 5.429403783000169],
                [-7.418652057999907, 5.424822808000101],
                [-7.424159049999901, 5.425086976000102],
                [-7.433546065999963, 5.428826809000043],
                [-7.440377235999961, 5.437605858000154],
                [-7.443837164999877, 5.435099125000136],
                [-7.446177004999868, 5.427989007000178],
                [-7.444849014999932, 5.420853138000098],
                [-7.434134005999965, 5.409058095000034],
                [-7.428915023999878, 5.399827957000127],
                [-7.424657821999972, 5.383477211000184],
                [-7.41874790199995, 5.374701977000086],
                [-7.403185844999939, 5.367245198000148],
                [-7.398417948999963, 5.359168052000086],
                [-7.394525051999892, 5.358222008000155],
                [-7.391989230999968, 5.359815120000178],
                [-7.387804031999963, 5.36806488100018],
                [-7.386359213999981, 5.37817096800012],
                [-7.381278990999931, 5.382966042000078],
                [-7.376684188999889, 5.38362693800002],
                [-7.374641894999911, 5.379475116000037],
                [-7.379319191999969, 5.366630077000139],
                [-7.378681181999923, 5.358809948000101],
                [-7.380340099999955, 5.351004124000156],
                [-7.37743902099993, 5.338109018000068],
                [-7.372878074999903, 5.333712102000106],
                [-7.365769861999922, 5.332512856000164],
                [-7.365112780999937, 5.327681064000046],
                [-7.372855186999971, 5.315742970000144],
                [-7.379940985999951, 5.306857109000021],
                [-7.382699012999979, 5.305953979000094],
                [-7.386115074999964, 5.310115814000085],
                [-7.390035151999939, 5.307610989000068],
                [-7.389702795999938, 5.288067818000172],
                [-7.392948150999814, 5.283030987000075],
                [-7.400554179999972, 5.278252125000051],
                [-7.403141020999954, 5.26884079000007],
                [-7.407546043999901, 5.261742115000061],
                [-7.415398119999964, 5.254435062000027],
                [-7.42136001699987, 5.255393982000157],
                [-7.422257898999931, 5.258159161000094],
                [-7.418536186999916, 5.266180992000045],
                [-7.425114154999903, 5.27794790400003],
                [-7.422565935999899, 5.281383037000126],
                [-7.417974947999937, 5.281583787000045],
                [-7.413599012999953, 5.283854008000049],
                [-7.410789013999931, 5.292801857000086],
                [-7.41328716299995, 5.296956062000163],
                [-7.417181968999955, 5.297671795000156],
                [-7.423852920999821, 5.295417785000154],
                [-7.435169218999818, 5.284680844000036],
                [-7.444355964999829, 5.283822059000158],
                [-7.449420928999928, 5.281325817000038],
                [-7.456141948999971, 5.271252155000184],
                [-7.467322825999929, 5.281209946000104],
                [-7.471787929999891, 5.265697957000157],
                [-7.462635040999942, 5.26094579700009],
                [-7.459240913999963, 5.253337860000045],
                [-7.459753990999957, 5.244834900000114],
                [-7.463952064999944, 5.234284878000096],
                [-7.470405101999972, 5.230876923000096],
                [-7.475038051999945, 5.224009991],
                [-7.474167823999892, 5.216186047000178],
                [-7.467352866999931, 5.205335141000091],
                [-7.46643686199991, 5.196533204000048],
                [-7.472052096999903, 5.18812084200016],
                [-7.468430994999949, 5.180510998000045],
                [-7.468768120999869, 5.163957120000134],
                [-7.470920086999968, 5.150407792000124],
                [-7.476941108999938, 5.141935825000189],
                [-7.481871127999909, 5.12472391100016],
                [-7.485547064999878, 5.120183946000168],
                [-7.495299816999932, 5.105722904000174],
                [-7.500409125999966, 5.096560954000097],
                [-7.505475997999895, 5.093372822000106],
                [-7.510965822999935, 5.095936775000041],
                [-7.515272139999979, 5.104931831000044],
                [-7.518243788999826, 5.106329919000075],
                [-7.526070117999893, 5.102701187000037],
                [-7.528233051999962, 5.099253178000083],
                [-7.535801886999877, 5.092032909000182],
                [-7.543148040999938, 5.087403775000155],
                [-7.547318934999964, 5.080761908999989],
                [-7.553315162999979, 5.076199055000132],
                [-7.555890082999952, 5.067937850000078],
                [-7.554088115999946, 5.063098908000086],
                [-7.548377990999938, 5.05846500399997],
                [-7.547492026999976, 5.053628922000087],
                [-7.548435210999969, 5.049497128000155],
                [-7.54275989599995, 5.039574146000177],
                [-7.549940109999966, 5.029272079000123],
                [-7.552289009999981, 5.020549775000177],
                [-7.551393984999891, 5.017092228000024],
                [-7.542063235999876, 5.005078792000063],
                [-7.540039062999881, 4.998857021000163],
                [-7.542612075999898, 4.991055012000061],
                [-7.546545982999874, 4.98533296700009],
                [-7.556933879999974, 4.97527885400018],
                [-7.555129049999948, 4.970438004000187],
                [-7.545286178999902, 4.967387199000086],
                [-7.535036085999934, 4.955596924000076],
                [-7.533905982999954, 4.952600003000157],
                [-7.536705016999974, 4.945716858000083],
                [-7.536499976999892, 4.941577912000071],
                [-7.532873154999948, 4.934656143000154],
                [-7.526475906999963, 4.930478096000059],
                [-7.524434088999954, 4.927015782000126],
                [-7.524232864999931, 4.922414779000178],
                [-7.52654886299996, 4.91898012300004],
                [-7.539410113999907, 4.916989804000139],
                [-7.54625892699994, 4.922095776000163],
                [-7.551761149999891, 4.92304802100017],
                [-7.556591988999912, 4.920777797000085],
                [-7.560057163999943, 4.917122841000037],
                [-7.563333986999965, 4.906562806000181],
                [-7.570674895999957, 4.906610966000073],
                [-7.577761172999885, 4.911024095000073],
                [-7.584187983999925, 4.910143853000079],
                [-7.591788767999958, 4.905591965000042],
                [-7.596899032999943, 4.895277024000052],
                [-7.596232890999943, 4.891593934000127],
                [-7.587111948999961, 4.882799150000039],
                [-7.58876705199998, 4.875220775000116],
                [-7.587210178999953, 4.867621899000028],
                [-7.589282035999929, 4.86625719],
                [-7.585186003999979, 4.860712052000054],
                [-7.588468074999923, 4.849463940000021],
                [-7.586681843999941, 4.841863156000102],
                [-7.579640865999977, 4.830781937000097],
                [-7.583797930999936, 4.826439859000061],
                [-7.590011119999872, 4.823260785000059],
                [-7.59255886099993, 4.819365978000121],
                [-7.589808939999955, 4.817080020000162],
                [-7.58986806799993, 4.80969095200004],
                [-7.59104204099998, 4.805328847000169],
                [-7.581933975999903, 4.794233800000086],
                [-7.561553955999955, 4.788815976000024],
                [-7.558362961999933, 4.785808087000078],
                [-7.558862209999916, 4.779368878000128],
                [-7.562828063999973, 4.769046784000125],
                [-7.555007934999935, 4.735654832000023],
                [-7.558094023999956, 4.719118118000097],
                [-7.552484988999936, 4.699306011000147],
                [-7.554377079999881, 4.690808773000185],
                [-7.563095092999959, 4.690402984000116],
                [-7.565420149999909, 4.685359955000024],
                [-7.562932967999927, 4.679595948000156],
                [-7.554972172999953, 4.669196128000124],
                [-7.55659723399998, 4.665987015000042],
                [-7.564630984999894, 4.665115834000176],
                [-7.56854915699995, 4.661922932000152],
                [-7.571111201999884, 4.655727864000028],
                [-7.569067955999969, 4.652036191000036],
                [-7.564500808999981, 4.649249076000103],
                [-7.557167052999887, 4.648512841000184],
                [-7.554884910999817, 4.646196843000041],
                [-7.551966189999973, 4.63675022200016],
                [-7.550923822999891, 4.619956971000022],
                [-7.546407221999914, 4.60911989300007],
                [-7.552700043999948, 4.593292236000082],
                [-7.555929182999876, 4.590323925000064],
                [-7.564187049999873, 4.589685917000054],
                [-7.566722870999968, 4.587862969000014],
                [-7.568356989999927, 4.583502770000166],
                [-7.565873145999944, 4.577276230999985],
                [-7.555388926999967, 4.566631794000102],
                [-7.553367137999885, 4.560180187000185],
                [-7.556631087999961, 4.551922798000021],
                [-7.552793025999904, 4.542010783000137],
                [-7.559020042999919, 4.536990166000123],
                [-7.560882091999872, 4.532402038000157],
                [-7.558598040999925, 4.526431083000034],
                [-7.554677963999836, 4.524174213000151],
                [-7.549981116999959, 4.510217191000152],
                [-7.551938055999869, 4.487846852000075],
                [-7.557445048999966, 4.480113983000024],
                [-7.560943126999973, 4.466660022000156],
                [-7.560109138999962, 4.45386505100015],
                [-7.555603026999904, 4.445620059000078],
                [-7.54042005599996, 4.434569836000151],
                [-7.538608073999967, 4.42877388],
                [-7.540007113999934, 4.424872875000119],
                [-7.554800986999965, 4.40610790300002],
                [-7.557825088999891, 4.398766994000027],
                [-7.557402133999972, 4.393475056000057],
                [-7.553092001999971, 4.386319160000028],
                [-7.546247005999931, 4.380986213000142],
                [-7.524089812999932, 4.367967130000181],
                [-7.520137787999943, 4.362082004000172],
                [-7.511805057999879, 4.36180686900002],
                [-7.51013898899987, 4.362637998000139],
                [-7.484860896999919, 4.362637998000139],
                [-7.483472824999922, 4.362082004000172],
                [-7.475973128999954, 4.362916946000155],
                [-7.464027880999879, 4.366527080000083],
                [-7.454583168999932, 4.367639065000162],
                [-7.452917097999944, 4.371527195000169],
                [-7.448472976999938, 4.371805190000146],
                [-7.439861773999951, 4.375140191000128],
                [-7.434862136999811, 4.375140191000128],
                [-7.43069505699998, 4.377083778000099],
                [-7.413194178999959, 4.380971909000039],
                [-7.409584044999974, 4.38319396899999],
                [-7.404582977999951, 4.383471967000105],
                [-7.398750781999866, 4.38680601100009],
                [-7.394029140999976, 4.394027234000077],
                [-7.387916086999837, 4.395973205000075],
                [-7.38263988499989, 4.399304866000136],
                [-7.361527920999947, 4.406805993000035],
                [-7.356249808999962, 4.409306050000055],
                [-7.354026793999879, 4.415694237000025],
                [-7.350695132999931, 4.418750763000162],
                [-7.341528892999918, 4.422916890000181],
                [-7.334584235999955, 4.425416946999974],
                [-7.330140114999949, 4.426249027000097],
                [-7.319304941999974, 4.430693150000025],
                [-7.319582939999975, 4.432916164000119],
                [-7.313193797999873, 4.437362194000059],
                [-7.312359808999815, 4.442915917000164],
                [-7.308750152999949, 4.447638989000154],
                [-7.301249025999937, 4.452361107000058],
                [-7.292916774999924, 4.456527233000145],
                [-7.287638187999903, 4.457084179000049],
                [-7.280416011999876, 4.459584236000012],
                [-7.268751143999907, 4.46124982900011],
                [-7.259584903999951, 4.464027882000153],
                [-7.24958419799998, 4.466249943000094],
                [-7.2484722129999, 4.472917080000059],
                [-7.247084140999959, 4.475139142000103],
                [-7.243195055999877, 4.477639199000066],
                [-7.245417117999978, 4.482082845000036],
                [-7.24208307299989, 4.487361908000139],
                [-7.238472938999962, 4.490694046000101],
                [-7.235972881999885, 4.491528035000101],
                [-7.22652816699997, 4.498195172000123],
                [-7.217360972999927, 4.502638816000058],
                [-7.214026928999942, 4.503472805000058],
                [-7.198472022999908, 4.51013994200008],
                [-7.194305896999822, 4.510971069000163],
                [-7.1951398839999, 4.514306068000167],
                [-7.190138816999934, 4.517638206000186],
                [-7.186804770999913, 4.518194200000153],
                [-7.184027195999875, 4.521527768000112],
                [-7.176249980999955, 4.523193836000132],
                [-7.16930580099995, 4.523750783000082],
                [-7.160695074999978, 4.525416851000102],
                [-7.131804942999963, 4.52986097400003],
                [-7.123750208999923, 4.530416965000086],
                [-7.117640017999918, 4.53180503800013],
                [-7.100973128999954, 4.533471108000128],
                [-7.085970877999898, 4.535696030000167],
                [-7.072082042999966, 4.536527156000034],
                [-7.069859980999922, 4.537640096000075],
                [-7.062637804999952, 4.537918091000108],
                [-7.05458307299989, 4.539306164000095],
                [-7.05374908399989, 4.543471814000156],
                [-7.045416832999877, 4.545971871000177],
                [-7.034306049999941, 4.547637938999969],
                [-7.030417919999934, 4.549027920000128],
                [-7.030971050999938, 4.554305077000095],
                [-7.02847290099993, 4.557639123000058],
                [-7.025416850999932, 4.559027196000045],
                [-7.025416850999932, 4.564026832000025],
                [-7.024026870999933, 4.566805840000086],
                [-7.019307136999885, 4.570693970000093],
                [-7.014584064999895, 4.571805954000126],
                [-7.013194082999974, 4.577360152000097],
                [-7.007915972999911, 4.580139159999987],
                [-7.001526831999911, 4.581251145000067],
                [-6.999304771999903, 4.584582806000128],
                [-6.999304771999903, 4.587360858000125],
                [-6.992361068999969, 4.591248990000167],
                [-6.985137938999969, 4.592361928000059],
                [-6.981249808999962, 4.595139980000056],
                [-6.98263788099996, 4.598472119000121],
                [-6.98180580199994, 4.60125017200005],
                [-6.970695018999891, 4.607916832000115],
                [-6.965972899999883, 4.611248970000133],
                [-6.961527824999905, 4.617083073000117],
                [-6.960137843999917, 4.620140077000087],
                [-6.957084178999935, 4.622638226000106],
                [-6.951249122999968, 4.62458419799998],
                [-6.944860935999941, 4.628471852000132],
                [-6.942638873999954, 4.628749848000041],
                [-6.934028147999925, 4.632916928000043],
                [-6.927361965999978, 4.635138989000154],
                [-6.921804904999931, 4.640417099000047],
                [-6.916804790999947, 4.643749238000169],
                [-6.919860839999899, 4.647084237000115],
                [-6.921526909999955, 4.653749942000161],
                [-6.919860839999899, 4.657360076000089],
                [-6.915695189999951, 4.66263914100017],
                [-6.907360075999975, 4.665695191000054],
                [-6.897084236999831, 4.668472767000026],
                [-6.889027118999934, 4.669860839000137],
                [-6.883193968999933, 4.669860839000137],
                [-6.875415801999964, 4.671804905000045],
                [-6.859028815999977, 4.674861908000139],
                [-6.854028224999922, 4.674861908000139],
                [-6.848472118999951, 4.676249981000126],
                [-6.838748930999941, 4.677639961000011],
                [-6.832361221999975, 4.677639961000011],
                [-6.826806068999872, 4.679306030000134],
                [-6.812917232999951, 4.68125009500011],
                [-6.802916049999965, 4.683194161000017],
                [-6.792362212999876, 4.684028148000039],
                [-6.780694960999881, 4.6859731670001],
                [-6.764306067999939, 4.687082769000085],
                [-6.753472999999872, 4.688751],
                [-6.745416, 4.689029000000119],
                [-6.742916, 4.690139000000102],
                [-6.732917, 4.690695000000119],
                [-6.727639, 4.690139000000102],
                [-6.719584, 4.690973],
                [-6.717359999999871, 4.693473],
                [-6.708192999999881, 4.693473],
                [-6.70486099999988, 4.695695],
                [-6.702360999999826, 4.699306],
                [-6.702917, 4.706250000000125],
                [-6.700417, 4.708472000000143],
                [-6.694582, 4.711250000000121],
                [-6.67097299999989, 4.716805],
                [-6.66486, 4.717361],
                [-6.667915999999821, 4.720971000000134],
                [-6.673195, 4.722083000000112],
                [-6.677361, 4.725418],
                [-6.679027, 4.728472000000124],
                [-6.685138, 4.733750000000157],
                [-6.685694, 4.73736],
                [-6.680139, 4.735416000000157],
                [-6.676804999999888, 4.73902900000013],
                [-6.669303999999897, 4.730694],
                [-6.66458399999982, 4.732362],
                [-6.665416, 4.729028],
                [-6.661805999999899, 4.722361],
                [-6.661528, 4.719027],
                [-6.655415, 4.719305000000134],
                [-6.648749, 4.721249],
                [-6.639859999999885, 4.722083000000112],
                [-6.635693999999887, 4.725971000000129],
                [-6.631527999999889, 4.726528],
                [-6.626249, 4.730138000000125],
                [-6.626249, 4.731806],
                [-6.622360999999899, 4.734028],
                [-6.612916, 4.736806],
                [-6.617360999999846, 4.738194],
                [-6.620694999999898, 4.744861],
                [-6.610694, 4.745139000000165],
                [-6.612916, 4.740417],
                [-6.608194, 4.74180500000017],
                [-6.608472, 4.744027000000131],
                [-6.603749999999877, 4.746527],
                [-6.599027, 4.750417],
                [-6.600139, 4.753750000000139],
                [-6.599305, 4.75625],
                [-6.595416999999884, 4.758194000000117],
                [-6.588750999999888, 4.759584000000189],
                [-6.589029, 4.76236],
                [-6.584028, 4.76541600000013],
                [-6.577084, 4.765973],
                [-6.57514, 4.767361000000108],
                [-6.566804999999874, 4.769027000000108],
                [-6.559305999999879, 4.769861000000162],
                [-6.556805999999881, 4.772639],
                [-6.547362, 4.773195000000158],
                [-6.532085, 4.776527000000158],
                [-6.529306, 4.776527000000158],
                [-6.51736099999988, 4.778749000000175],
                [-6.512917, 4.778749000000175],
                [-6.50736, 4.780140000000131],
                [-6.500972, 4.780693],
                [-6.493471, 4.782916000000171],
                [-6.483195, 4.784028000000149],
                [-6.480695, 4.785416],
                [-6.474028, 4.785694000000149],
                [-6.472362, 4.787084],
                [-6.466249, 4.78764],
                [-6.460138999999856, 4.78986],
                [-6.457361, 4.78986],
                [-6.452916, 4.792082],
                [-6.444861999999887, 4.794307000000117],
                [-6.442361999999889, 4.796805],
                [-6.444584, 4.799305000000118],
                [-6.445416, 4.802917],
                [-6.443193999999892, 4.806249],
                [-6.438749, 4.810138000000109],
                [-6.431805, 4.812362],
                [-6.415972, 4.815694],
                [-6.412918, 4.815416],
                [-6.407639, 4.817916],
                [-6.402916999999889, 4.818750000000136],
                [-6.39736, 4.821251000000132],
                [-6.396806, 4.823473000000149],
                [-6.385971, 4.82569500000011],
                [-6.380139, 4.82569500000011],
                [-6.378749, 4.826805000000149],
                [-6.370416, 4.82736100000011],
                [-6.367084, 4.829583000000127],
                [-6.362916, 4.82902700000011],
                [-6.36097199999989, 4.830417],
                [-6.349583, 4.831527],
                [-6.347639, 4.833471000000145],
                [-6.342916, 4.83514],
                [-6.340972, 4.837362],
                [-6.338472, 4.837084000000118],
                [-6.33514, 4.839860000000101],
                [-6.325417, 4.842084000000114],
                [-6.320139, 4.844028],
                [-6.319304, 4.845972],
                [-6.308472, 4.848195],
                [-6.305418, 4.848195],
                [-6.302639, 4.850139],
                [-6.298472881999942, 4.850139142000103],
                [-6.291807173999871, 4.851527213999987],
                [-6.285694122999928, 4.854026794000049],
                [-6.284306049999884, 4.856248856000036],
                [-6.279305935999901, 4.857360840000069],
                [-6.277360914999917, 4.859304904000169],
                [-6.268473147999941, 4.861527920000071],
                [-6.249860763999948, 4.864027978000138],
                [-6.239304065999932, 4.869028092000121],
                [-6.235137938999912, 4.870138169000029],
                [-6.235415934999935, 4.873751163],
                [-6.233749865999926, 4.875973225000053],
                [-6.229306220999945, 4.878472805000058],
                [-6.223196028999951, 4.879860877000169],
                [-6.220417021999879, 4.881526948000101],
                [-6.211806772999978, 4.882360935000065],
                [-6.204306125999949, 4.884027005000121],
                [-6.198471067999947, 4.884027005000121],
                [-6.196249008999928, 4.887362004000067],
                [-6.189860819999922, 4.889028072000087],
                [-6.187917232999951, 4.891528129000108],
                [-6.179860115999816, 4.895693779000112],
                [-6.179860115999816, 4.898471832000041],
                [-6.176527976999978, 4.902082921000158],
                [-6.169304846999978, 4.903194905000134],
                [-6.163195132999931, 4.905972958],
                [-6.153471946999957, 4.912639141000113],
                [-6.14958381699995, 4.912639141000113],
                [-6.14791583899995, 4.915418148000128],
                [-6.14486217599989, 4.917362214000036],
                [-6.134582996999882, 4.918471814000156],
                [-6.134305000999973, 4.920971871000177],
                [-6.128471850999972, 4.922083856000086],
                [-6.116527080999958, 4.927360057999977],
                [-6.114583014999937, 4.927360057999977],
                [-6.110972880999952, 4.930417061000071],
                [-6.104860781999946, 4.931527137999979],
                [-6.097640037999895, 4.937917233000178],
                [-6.092916011999932, 4.937917233000178],
                [-6.092639923999968, 4.941527844],
                [-6.089026926999907, 4.943193912000027],
                [-6.084582805999901, 4.943193912000027],
                [-6.082361221999975, 4.946249963000014],
                [-6.079583166999953, 4.947638036000058],
                [-6.08180522899994, 4.950694084000133],
                [-6.08180522899994, 4.953472137],
                [-6.078751087999933, 4.956805230000043],
                [-6.076529026999936, 4.957361221000099],
                [-6.076529026999936, 4.960694790000161],
                [-6.072916029999874, 4.961248876000184],
                [-6.070415972999967, 4.963194847000182],
                [-6.06347084, 4.964304924000089],
                [-6.038472175999971, 4.966804981000053],
                [-6.027916908999941, 4.968750000000171],
                [-6.023194789999877, 4.969027996000079],
                [-6.020693777999952, 4.972084046000134],
                [-6.017362116999891, 4.972084046000134],
                [-6.008471011999916, 4.974306108000178],
                [-6.0054168699999, 4.978472233000161],
                [-5.999304770999913, 4.979028224000047],
                [-5.990972041999953, 4.981529236000029],
                [-5.98597192799997, 4.981807232000165],
                [-5.981526851999888, 4.983750820000182],
                [-5.976251124999919, 4.984028816000091],
                [-5.977083206999907, 4.988472939000189],
                [-5.97402715599992, 4.994305135000104],
                [-5.963471888999948, 4.997916222000185],
                [-5.953471182999977, 5.000693799000089],
                [-5.935138225999936, 5.004305839000153],
                [-5.92791795799991, 5.007359981999969],
                [-5.924304960999962, 5.00708198600006],
                [-5.924582958999963, 5.010138989000154],
                [-5.921526907999976, 5.012083053000083],
                [-5.917360782999936, 5.012083053000083],
                [-5.916251182999872, 5.014027119000161],
                [-5.912360190999891, 5.015417099000047],
                [-5.903192996999906, 5.016527176000182],
                [-5.89736080099982, 5.020139218000054],
                [-5.890695095999945, 5.022640228000057],
                [-5.884027958999923, 5.023471832000098],
                [-5.881249903999958, 5.025417805000075],
                [-5.872917174999884, 5.027083874000027],
                [-5.871527194999828, 5.029027939000173],
                [-5.864305018999914, 5.031527997000126],
                [-5.861250876999975, 5.031527997000126],
                [-5.847083, 5.036251000000164],
                [-5.841526999999871, 5.037083000000109],
                [-5.840139, 5.038473000000181],
                [-5.834581999999898, 5.038751000000104],
                [-5.822361999999828, 5.041527],
                [-5.807916, 5.043473000000176],
                [-5.790138999999897, 5.047083],
                [-5.784583, 5.047918],
                [-5.784861, 5.052362],
                [-5.788472999999897, 5.05625],
                [-5.789861, 5.059306000000106],
                [-5.795971999999892, 5.063751],
                [-5.801249999999868, 5.061529],
                [-5.803194, 5.063195],
                [-5.79541799999987, 5.065695000000119],
                [-5.792361, 5.064861],
                [-5.788750999999877, 5.060972000000106],
                [-5.787638999999899, 5.058194000000128],
                [-5.784305, 5.056528000000128],
                [-5.783751, 5.061529],
                [-5.77986, 5.063473000000101],
                [-5.780138, 5.070973],
                [-5.777637999999854, 5.071249],
                [-5.776806, 5.067917],
                [-5.777916, 5.060694],
                [-5.781251, 5.055972000000111],
                [-5.779585, 5.049306000000172],
                [-5.775971999999854, 5.049306000000172],
                [-5.762083, 5.051806],
                [-5.762360999999885, 5.059582],
                [-5.758473, 5.052362],
                [-5.754582, 5.05208400000015],
                [-5.74014, 5.054862000000128],
                [-5.736805, 5.054862000000128],
                [-5.723749999999882, 5.057084000000145],
                [-5.707916999999895, 5.058472],
                [-5.701804, 5.059582],
                [-5.696528, 5.059582],
                [-5.687918, 5.061529],
                [-5.682917, 5.061807000000101],
                [-5.664862, 5.064583000000141],
                [-5.656526999999869, 5.064861],
                [-5.650973, 5.066527],
                [-5.628749, 5.068749],
                [-5.618194, 5.072083],
                [-5.614306, 5.074305],
                [-5.595416999999827, 5.078472],
                [-5.587084, 5.079028000000164],
                [-5.584584, 5.08236200000016],
                [-5.576527, 5.082638],
                [-5.57347099999987, 5.085138000000143],
                [-5.572917, 5.089304],
                [-5.578749, 5.090139],
                [-5.579306, 5.089029],
                [-5.589584, 5.088751000000116],
                [-5.595416999999827, 5.087638],
                [-5.597361, 5.090139],
                [-5.601527, 5.089861000000155],
                [-5.604582999999877, 5.088194],
                [-5.609306, 5.088473],
                [-5.615138, 5.086806000000138],
                [-5.617081999999869, 5.095695],
                [-5.611806, 5.090973000000133],
                [-5.605970999999897, 5.093195000000151],
                [-5.600972999999897, 5.093195000000151],
                [-5.599305, 5.095695],
                [-5.602361, 5.098471],
                [-5.606248999999878, 5.099027],
                [-5.611806, 5.103472000000124],
                [-5.612915999999871, 5.11013800000012],
                [-5.61486, 5.114028],
                [-5.610417999999868, 5.114307],
                [-5.610694, 5.11013800000012],
                [-5.609306, 5.10375],
                [-5.606806, 5.101527000000147],
                [-5.60374899999988, 5.102084],
                [-5.600972999999897, 5.105694],
                [-5.599305, 5.10986],
                [-5.597082999999827, 5.104306000000179],
                [-5.59125, 5.106250000000102],
                [-5.59236, 5.100971000000129],
                [-5.589028, 5.097083000000112],
                [-5.585972, 5.095139],
                [-5.578471, 5.095417000000111],
                [-5.574861, 5.096805],
                [-5.57347099999987, 5.094305000000134],
                [-5.572083, 5.087082],
                [-5.572639, 5.084306],
                [-5.568472999999869, 5.084306],
                [-5.564303999999822, 5.086528],
                [-5.561805999999876, 5.085416],
                [-5.55264, 5.085416],
                [-5.550971, 5.08402800000016],
                [-5.542917, 5.084584000000177],
                [-5.541805, 5.085416],
                [-5.533749999999884, 5.085972],
                [-5.527915999999891, 5.087638],
                [-5.520416999999895, 5.087638],
                [-5.517083, 5.088751000000116],
                [-5.507916, 5.089304],
                [-5.486250999999811, 5.092361],
                [-5.461529, 5.095417000000111],
                [-5.413749216999918, 5.10069608800012],
                [-5.408195018999891, 5.100971223000045],
                [-5.392084121999972, 5.102918149],
                [-5.386527060999981, 5.103192807000028],
                [-5.344583987999954, 5.107915879000188],
                [-5.317359924999948, 5.111527920000185],
                [-5.273194789999934, 5.116250992000175],
                [-5.258193969999979, 5.117361069000083],
                [-5.232639789999951, 5.120139122000182],
                [-5.229584216999967, 5.120139122000182],
                [-5.210415840999929, 5.122639178999975],
                [-5.20041704199997, 5.122917176000158],
                [-5.193194866999932, 5.124026776000164],
                [-5.178194045999931, 5.124582767000049],
                [-5.171804904999931, 5.125695229000087],
                [-5.158750057999953, 5.125970840000036],
                [-5.145139216999894, 5.127638818000037],
                [-5.127637861999915, 5.128192901000148],
                [-5.121805190999965, 5.129026890000148],
                [-5.112916946999974, 5.129305841000019],
                [-5.098750113999927, 5.130417823000187],
                [-5.094027041999937, 5.130417823000187],
                [-5.08375120099987, 5.131805898000039],
                [-5.072361946999877, 5.132361889000094],
                [-5.063195227999927, 5.132361889000094],
                [-5.055138109999859, 5.133193971000026],
                [-5.048471927999856, 5.133193971000026],
                [-5.041248798999959, 5.134305955000059],
                [-5.029860972999813, 5.134305955000059],
                [-5.019028187999936, 5.135416032000137],
                [-5.00180721299995, 5.135972023000022],
                [-4.990972042999886, 5.137916089000157],
                [-4.980416773999821, 5.137916089000157],
                [-4.975695132999931, 5.139028072000031],
                [-4.955417156999886, 5.139584065000122],
                [-4.948472976999881, 5.140416146000121],
                [-4.929861068999969, 5.140972138000109],
                [-4.923194884999873, 5.142082215000073],
                [-4.914584158999901, 5.142082215000073],
                [-4.90847206199993, 5.143195152000033],
                [-4.890694141999973, 5.14430713600018],
                [-4.882639884999946, 5.14430713600018],
                [-4.878470896999886, 5.145139218000111],
                [-4.867082117999928, 5.145695209000053],
                [-4.857915877999915, 5.145695209000053],
                [-4.851527213999873, 5.146804810999981],
                [-4.845417022999868, 5.146804810999981],
                [-4.840138912999976, 5.147638798000173],
                [-4.832362175999947, 5.147360802000094],
                [-4.824583053999959, 5.148194791000094],
                [-4.82041692599995, 5.149304868000172],
                [-4.81319522799987, 5.149304868000172],
                [-4.803472041999896, 5.150694848000057],
                [-4.796804904999931, 5.150972843999966],
                [-4.789305210999942, 5.152082921000101],
                [-4.784028052999929, 5.152082921000101],
                [-4.74708414, 5.155695915000081],
                [-4.731250762999935, 5.158472060000179],
                [-4.725416183999926, 5.160140038000179],
                [-4.718193053999926, 5.160694122000166],
                [-4.708751200999927, 5.162916183000107],
                [-4.700972078999939, 5.163472177000074],
                [-4.696527957999876, 5.165137768000136],
                [-4.686804769999981, 5.166250230000173],
                [-4.670137881999949, 5.170138837000138],
                [-4.661805153999978, 5.170972824000103],
                [-4.65597295799995, 5.172638894000102],
                [-4.650137901999869, 5.172360898000022],
                [-4.641528128999937, 5.174860955000042],
                [-4.6304168699999, 5.176527024000109],
                [-4.612915992999888, 5.178194999000027],
                [-4.607916830999955, 5.179861070000129],
                [-4.597360133999928, 5.180139065000105],
                [-4.591249942999923, 5.181805134000058],
                [-4.578195095999888, 5.182918073],
                [-4.570137977999934, 5.184584141000187],
                [-4.562084197999923, 5.184862137000096],
                [-4.545138836999968, 5.187637807000101],
                [-4.530693054999915, 5.188471794000066],
                [-4.526804924999908, 5.189583778000042],
                [-4.520695208999939, 5.189583778000042],
                [-4.516529083999956, 5.190415859999973],
                [-4.510416029999874, 5.190415859999973],
                [-4.507915972999967, 5.191249848000098],
                [-4.496805191999897, 5.192083835000062],
                [-4.480137825999918, 5.194305897000049],
                [-4.473749160999887, 5.194584847000044],
                [-4.464028835999955, 5.195972920000088],
                [-4.454028129999927, 5.196250916000167],
                [-4.444304941999917, 5.198194981000142],
                [-4.431528090999962, 5.199028968000164],
                [-4.423470972999894, 5.200139045000071],
                [-4.418472765999979, 5.200139045000071],
                [-4.4106950769999, 5.201527118000058],
                [-4.392639160999977, 5.202639102000092],
                [-4.390971182999977, 5.203473091000035],
                [-4.379305838999869, 5.204027177000057],
                [-4.368196009999906, 5.205695153000079],
                [-4.353194236999968, 5.206527234000077],
                [-4.34958410299987, 5.207639217000178],
                [-4.337082862999978, 5.20819282500014],
                [-4.329307077999943, 5.209584235000136],
                [-4.323472021999862, 5.209584235000136],
                [-4.309861183999885, 5.211527824000086],
                [-4.300972937999973, 5.211805822000031],
                [-4.295693874999927, 5.212639809],
                [-4.287640094999858, 5.212917804000028],
                [-4.286527155999977, 5.21374988600013],
                [-4.277916907999952, 5.214027881000106],
                [-4.271805763999964, 5.215139866000186],
                [-4.232638835999978, 5.21902799500009],
                [-4.226805209999839, 5.219860077000021],
                [-4.219584941999926, 5.219860077000021],
              ],
            ],
            [
              [
                [-5.596805000999893, 5.102362001000188],
                [-5.599861, 5.103471999000021],
                [-5.600695000999963, 5.100139],
                [-5.597917000999928, 5.097639000000129],
                [-5.595138998999914, 5.100139],
                [-5.596805000999893, 5.102362001000188],
              ],
            ],
          ],
        },
        properties: { GID_0: "CIV", NAME_1: "C�te d'Ivoire" },
      },
    ],
  },
};

export default countrygeojson;
