import { React, useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
// import CRGCountrySelect from "../CRGCountrySelect";
import SearchIcon from "@mui/icons-material/Search";
import roadMapIcon from "../../../assets/images/communitypageIcons/roadmap.svg";
import imgUrl from "../../../assets/images/imgUrl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { assessment } from "../../../config/assessment";
import filesUrl from "../../../assets/files/assesment/assesmentFilesUrl";
import { useLanguage } from "../../../contexts/LanguageContext";

function CRGAssessmentDashboardPage() {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // country select section
  const [countryAction, setCountryAction] = useState(10); // Set the initial value to 10 for "Select country"
  const [mainCountries, setMainCountries] = useState(10); // Set the initial value to 10 for "Select country"
  const [queryParameterForCountry, setSelectedCountry] = useState();

  const countries = [
    { name: "Cameroon", logo: imgUrl.cameroonLogo, value: 20 },
    { name: "Benin", logo: imgUrl.beninLogo, value: 30 },
    { name: "RDC", logo: imgUrl.rdcLogo, value: 40 },
    { name: "Congo", logo: imgUrl.congoLogo, value: 50 },
    { name: "Niger", logo: imgUrl.nigerLogo, value: 60 },
    { name: "Burkina Faso", logo: imgUrl.burkinaLogo, value: 70 },
    { name: "Guinée", logo: imgUrl.guineeLogo, value: 80 },
    { name: "Burundi", logo: imgUrl.burundiLogo, value: 90 },
    { name: "Togo", logo: imgUrl.togoLogo, value: 100 },
    { name: "Mali", logo: imgUrl.maliLogo, value: 101 },
    { name: "Mauritanie", logo: imgUrl.mauritanieLogo, value: 102 },
    { name: "Chad", logo: imgUrl.chadLogo, value: 103 },
    { name: "Sénégal", logo: imgUrl.senegalLogo, value: 104 },
    { name: "Gabon", logo: imgUrl.gabonLogo, value: 105 },
    { name: "Centrafrique", logo: imgUrl.centrafriqueLogo, value: 106 },
    { name: "Côte d’Ivoire", logo: imgUrl.coteLogo, value: 107 },
  ];

  useEffect(() => {
    const countryId = searchParams.get("id");
    setSelectedCountry(countryId);
  }, [searchParams]);

  const handleCountryActionChange = (event) => {
    setCountryAction(event.target.value);
    navigate(`/CRGActionPlanDashboard?id=${event.target.value}`);
  };

  const handleMainCountriesChange = (event) => {
    setMainCountries(event.target.value);
    navigate(`/CRGAssessmentDashboard?id=${event.target.value}`);
  };

  const handleClick = (value) => {
    navigate(`/CRGAssessmentDashboard?id=${value}`);
  };

  const extractFileName = (filePath) => {
    let result = filePath.split("/").pop().split(".");
    return `${result[0]}.${result[2]}`;
  };

  const getFiles = () => {
    const countryFiles = filesUrl.find(
      (item) => item.key === queryParameterForCountry
    );
    if (!countryFiles) return null;

    const { value } = countryFiles;
    const reportFile = value.find((item) => item.report)?.report;
    // const englishDoc = value.find((item) => item.english)?.english;
    // const frenchDoc = value.find((item) => item.french)?.french;
    return (
      <>
        {reportFile && (
          <Link href={reportFile} target="_blank" className="reportLink">
            {extractFileName(reportFile)}
          </Link>
        )}
        <br />
        {/* <br />
        {language === 20 && englishDoc && (
          <Link href={englishDoc} className="reportLink">
            {extractFileName(englishDoc)}
          </Link>
        )}
        {language === 30 && frenchDoc && (
          <Link href={frenchDoc} className="reportLink">
            {extractFileName(frenchDoc)}
          </Link>
        )} */}
      </>
    );
  };

  return (
    <div className="commonPageCardSubSection">
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3} className="subCountries-mobile-d-none">
            <Card className="defineCountryHeight">
              <CardContent>
                <div>
                  <Typography className="text-center listCountryHeader">
                    Countries
                  </Typography>
                  <div className="listCountries">
                    <List>
                      {countries.map((country, index) => (
                        <ListItem key={index} disablePadding>
                          <ListItemButton
                            onClick={() => handleClick(country.value)}
                            className={`${
                              parseInt(queryParameterForCountry) ===
                              country.value
                                ? "countryactive"
                                : ""
                            }`}
                          >
                            <ListItemIcon>
                              <img src={country.logo} alt={country.name} />
                            </ListItemIcon>
                            <ListItemText primary={country.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="mt-10px defineReportHeight">
              <CardContent>
                <Typography className="reportHeading">
                  {language == 20
                    ? `To see the details of this report follow the link below:`
                    : `Pour voir les détails de ce rapport, suivez le lien ci-dessous:`}{" "}
                </Typography>
                {getFiles()}
              </CardContent>
            </Card>
          </Grid>

          {/* mobile ui for countries */}
          <Grid item xs={12} className="subCountries-desktop-d-none">
            <div className="countryDropdown mt-0px">
              <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
                <SearchIcon className="searchIcon"></SearchIcon>
                <FormControl fullWidth className="countrySelectField">
                  <Select
                    labelId="demo-mainCountries-select-label"
                    id="demo-mainCountries-select"
                    value={mainCountries}
                    label=""
                    onChange={handleMainCountriesChange}
                  >
                    <MenuItem value={10} disabled>
                      Select Country
                    </MenuItem>
                    {countries.map((country) => (
                      <MenuItem key={country.value} value={country.value}>
                        <div className="d-flex justify-content-start align-items-center">
                          <img
                            src={country.logo}
                            alt={country.name}
                            style={{ marginRight: "10px" }}
                          />
                          <span className="countryName">{country.name}</span>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>{" "}
            <Card className="mt-10px defineReportHeight mobile-DataLink">
              <CardContent>
                <Typography className="reportHeading">
                  {language == 20
                    ? `To see the details of this report follow the link below:`
                    : `Pour voir les détails de ce rapport, suivez le lien ci-dessous:`}{" "}
                </Typography>
                {getFiles()}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={9}>
            <Card className="defineCountryInfoHeight">
              <CardContent>
                <div className="dashboardCountrySection">
                  <Typography className="summaryCountryHeading">
                    {assessment[queryParameterForCountry]?.[language]?.header}
                  </Typography>
                  <div className="summarySection">
                    <p>
                      {
                        assessment[queryParameterForCountry]?.[language]
                          ?.description
                      }
                    </p>
                    {assessment[queryParameterForCountry]?.[
                      language
                    ]?.content.map((e, index) => (
                      <div key={index}>
                        <Typography>{e.key}</Typography>
                        <ul>
                          {e.value.map((v) => (
                            <li>{v}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/* second country select section */}
      <div className="mt-10px">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Grid item xs={1}></Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={roadMapIcon}></img>
                      <Typography className="ml-15px">
                        Go to Country Level Action Plan
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    {" "}
                    <div className="countryDropdown mt-0px">
                      <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
                        <SearchIcon className="searchIcon"></SearchIcon>
                        <FormControl fullWidth className="countrySelectField">
                          {/* <InputLabel id="demo-simple-select-label">
                          Age
                        </InputLabel> */}
                          <Select
                            labelId="demo-countryAction-select-label"
                            id="demo-countryAction-select"
                            value={countryAction}
                            label=""
                            onChange={handleCountryActionChange}
                          >
                            <MenuItem value={10} disabled>
                              Select Country
                              {/* Placeholder text or label for the initial option */}
                            </MenuItem>
                            {countries.map((country) => (
                              <MenuItem
                                key={country.value}
                                value={country.value}
                              >
                                <div className="d-flex justify-content-start align-items-center">
                                  <img
                                    src={country.logo}
                                    alt={country.name}
                                    style={{ marginRight: "10px" }}
                                  />
                                  <span className="countryName">
                                    {country.name}
                                  </span>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CRGAssessmentDashboardPage;
