import React from 'react'
import imgUrl from '../assets/images/imgUrl';

function Footer() {
  return (
    <div className="footer">
      <div className="footerImg">
        <a
          href="https://x.com/draf_tb?t=9ykGHbOkn2Ir2yd48ckAww&s=09"
          target="_blank"
        >
          <img src={imgUrl.twitterIcon}></img>
        </a>
        <a
          href="https://www.facebook.com/DRAFTB?mibextid=ZbWKwL"
          target="_blank"
        >
          <img src={imgUrl.fbIcon}></img>
        </a>
        <a href="http://www.draftb.org/" target="_blank">
          <img src={imgUrl.websiteIcon}></img>
        </a>
        <a
          href="https://www.facebook.com/DRAFTB?mibextid=ZbWKwL"
          target="_blank"
        >
          <img src={imgUrl.instaIcon}></img>
        </a>
      </div>
    </div>
  );
}

export default Footer
